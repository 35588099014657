import { LoadingButton } from "@mui/lab";
import { Box, Button, CircularProgress, Grid, Stack, Tab, Tabs, TextField, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PageData } from "../../../models/Index";
import { BreakElementModel, BreakRatedModel } from "../../../models/breakModel";
import { Path as RoutePath } from "../../../path";
import { ApplicationState, actionCreators, selectors } from "../../../store";
import FetchHelper from "../../FetchHelper";
import AlertDialog from "../../common/AlertDialog";
import DialCharacterGrid from "../DialCharacter/DialCharacterGrid";

// -------------
// FETCH ID
const GET_BREAK_ELEMENT = "GET_BREAK_ELEMENT";
const UPDATE_BREAK_ELEMENT = "UPDATE_BREAK_ELEMENT";
const CREATE_BREAK_ELEMENT = "CREATE_BREAK_ELEMENT";
const DELETE_DIAL_CHARACTER = "DELETE_DIAL_CHARACTER";
const GET_BREAK_ELEMENT_BY_ID = "GET_BREAK_ELEMENT_BY_ID";

// 検索フォーム
export interface SearchItemForm {
    "target": string;
    "limit": number;
    "page": number;
}

// 初期値設定
const initialValue: SearchItemForm = {
    target: '',
    limit: 50,
    page: 1,
}

const initialPageData: PageData = {
    rowFrom: '0',
    rowTo: '0',
    totalRow: '0',
    currPage: '0',
    totalPage: '0',
};

// 初期値設定
const initialValueBreakElementModel: BreakElementModel = {
    lElementID: 0,
    sElementName: "",
    nClassNumber: "",
    Mv3breakBreakRated: {
        lBreakRatedID: 0,
        mv3breakBreakOpeKind: {
            lOpeKindID: 0,
        },
        bIsDefault: false,
        sFixedName: "",
        dFixedValue: "",
        dContiDefValue: "",
        dContiMaxValue: "",
        dContiMiniValue: "",
        dContiWidthValue: "",
    } as BreakRatedModel
}

// -------------
//Tabs------------------
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `oilManagement-tab-${index}`,
        'aria-controls': `oilManagement-tabpanel-${index}`,
    };
}

export type BreakElementEditProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export const BreakElementEdit = (props: BreakElementEditProps) => {
    const {
        userId,
        onSearch, onSuccess, onError,
        onCreate, onCreateError,
        onUpdate, onUpdateError,
        onDeleteDialCharacter, onSuccessDeleteDialCharacter, onErrorDeleteDialCharacter,
        onSearchById,
    } = props;
    const { register, control, handleSubmit, setValue, getValues, formState: {errors} } = useForm<BreakElementModel>({
        mode: "all",
        criteriaMode: "all",
        defaultValues: initialValueBreakElementModel,
    })

    type MyParams = {
        id: string;
        mode: string;
    };
    let { id, mode } = useParams<keyof MyParams>() as MyParams;
    const location = useLocation();
    let lbreakRatedID = location.state ? location.state.lbreakRatedID : undefined;
    let sfixedName = location.state ? location.state.sfixedName : undefined;

    let navigate = useNavigate();
    const [selectTab, setSelectTab] = useState(0);
    const [loadingFlag, setLoadingFlag] = useState(mode === "edit" ? true : false);
    const gridRef = useRef() as any;
    const [colState, setColState] = useState<any>();
    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");
    const [openedDeleteDialog, setOpenedDeleteDialog] = useState<boolean>(false);
    const [mv3breakDialCharacter, setMv3breakDialCharacter] = useState<any>();
    const [idDelete, setIdDelete] = useState<string>("");
    let tab = location.state && location.state.tab;

    const handleSuccess = (data: any) => {
        console.log('>>>> handle Successs');
        setValue("lElementID", data.data.lelementID);
        setValue("nClassNumber", data.data.nclassNumber);
        setValue("sElementName", data.data.selementName);
        setValue("Mv3breakBreakRated.lBreakRatedID", data.data.mv3breakBreakRated.lbreakRatedID);
        setValue("Mv3breakBreakRated.sFixedName", data.data.mv3breakBreakRated.sfixedName);
        setLoadingFlag(false);
        if (data.mv3breakDialCharacterList.length > 0) {
            setMv3breakDialCharacter(data.mv3breakDialCharacterList);
        }
        if (tab !== undefined && tab !== null) {
            setSelectTab(tab)
        }
    }

    // 検索エラー時
    const handleError = (success: boolean, data: any, error: any) => {
        console.log('>>>> handle Error');
        gridRef.current.hideOverlay();
    }

    useEffect(() => {
        if (mode === "add")
            setValue("Mv3breakBreakRated.sFixedName", sfixedName);
    }, [])

    const tabHandleChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectTab(newValue);
        navigate(".", {
            replace: true,
            state: {
                tab: newValue,
                lbreakRatedID: lbreakRatedID,
                sfixedName: sfixedName
            }
        });
    }

    const handleEditSubmit = (data: BreakElementModel) => {
        setLoadingFlag(true);
        if (mode === "edit") {
            let request = {
                lElementID: id,
                sElementName: getValues("sElementName")??null,
                nClassNumber: getValues("nClassNumber")??null,
            }

            const params = {
                "userId": userId,
                "requestPrms": request
            }
            onUpdate(id, params);
        } else if (mode === "add") {
            let request = {
                lElementID: 0,
                sElementName: getValues("sElementName")??null,
                nClassNumber: getValues("nClassNumber")??null,
                lBreakRatedID: lbreakRatedID
            }

            const params = {
                "userId": userId,
                "requestPrms": request
            }

            onCreate(params)
        }
    }

    // Submit後のAPI呼び出し成功時
    const handleSubmitSuccess = (success: boolean, data: any, error: any) => {
        onSuccess();
        // if (lbreakRatedID !== undefined && lbreakRatedID !== null) {
        //     navigate(-1);
        // }
        if(mode ==="add"){
            navigate(RoutePath.BreakElementEdit + `/edit/${ data.data.lelementID}`,{replace:true})
        }
        setLoadingFlag(false);
    }

    //削除の処理
    const handleShowDetailPage = (data: any) => {
        navigate(RoutePath.DialCharacterEdit + `/edit/${data}`,
            {
                state: {
                    lelementID: id,
                    selementName: getValues("sElementName"),
                }
            })
    }

    // Submit後のAPI呼び出しエラー時
    const handleSubmitError = (success: boolean, data: any, error: any) => {
        onError();
        setLoadingFlag(false);
    }

    const handleUpdateSuccess = (success: boolean, data: any, error: any) => {
        onSuccess();
        if (lbreakRatedID !== undefined && lbreakRatedID !== null) {
            navigate(-1);
        }
        setLoadingFlag(false);
    }

    //削除の処理
    const handleDelete = (data: any) => {
        setIdDelete(data);
        const msg = "ID: " + data + "を削除します。"
        setTitle("定格ダイアル情報削除");
        setMessage(msg);
        setOpenedDeleteDialog(true);
    }

    // 削除確認ダイアログでOKボタンのイベントを受け取って、APIに送信する
    const handleDeleteOK = () => {
        setOpenedDeleteDialog(false);
        onDeleteDialCharacter({
            "userId": userId,
            "requestPrms":{
              "id": idDelete
            }
        });
        setIdDelete("");
    }

    // 削除確認ダイアログでCancelボタンのイベントを受け取って、ダイアログを無効にする
    const handleDeleteCancel = () => {
        setIdDelete("");
        setOpenedDeleteDialog(false);
    }

    // キャンセル
    const handleCancel = () => {
        if (location.key !== "default")
            navigate(-1);
        else 
            navigate(RoutePath.BreakElementList);
    }

    const handleSuccessDelete = (success: boolean, data: any, error: any) => {
        console.log(">>>> handledeleteSuccesss");
        onSuccessDeleteDialCharacter();
        const object = {
            "userId": userId,
            "requestPrms":{
                id:id
              }
        }
        onSearchById(object);
    }

    const handleErrorDelete = (success: boolean, data: any, error: any) => {
        onErrorDeleteDialCharacter();
    }

    const handleSuccessGet = (success: boolean, data: any, error: any) => {
        setMv3breakDialCharacter(data.mv3breakDialCharacterList);
        gridRef.current.setRowData(data.mv3breakDialCharacterList);
    }

    const handleErrorGet = (success: boolean, data: any, error: any) => {
        onError();
    }

    //入力欄背景
    const divStyles = {
        backgroundColor: 'white',
        border: '1px solid #bebebe',
        boxShadow: '0 0 5px #fff, 0 0 5px #bebebe, 0 0 1px #aaa',
        padding: '20px 40px',
        marginTop: '40px',
    }
    //分析一覧を表示
    const handleAdd = () => {
        navigate(RoutePath.DialCharacterEdit + `/add`,
            {
                state: {
                    lelementID: id,
                    selementName: getValues("sElementName"),
                }
            })
    }
    //分析一覧を表示
    const resetGrid = () => {
        if (selectTab === 1) {
            gridRef.current.setRowData(mv3breakDialCharacter);
        }
    };
    useEffect(resetGrid, [selectTab]);

    return (
        <>
            {
                mode === "edit" &&
                <FetchHelper
                    fetchId={GET_BREAK_ELEMENT}
                    url={`/master/break_element/get`}     
                    method="POST"
                    json
                    params={{
                        "userId": userId,
                        "requestPrms":{
                          id
                        }
                      }}
                    request={true}
                    onComplete={(success, data, error) => {
                        success ? handleSuccess(data) : handleError(success, data, error);
                    }}
                />
            }
            {loadingFlag && (
                <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1500, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                    <CircularProgress />
                </div>
            )}
            <div style={{ height: "100%", width: "100%", marginTop: "10px" }}>
                <Stack direction="row" justifyContent="flex-start" alignItems="center" sx={{}} style={{ margin: '18px', color: 'MenuText' }}>
                    <Typography variant="h4">
                        要素詳細
                    </Typography>
                </Stack>
                <form onSubmit={handleSubmit(handleEditSubmit)}>
                    <Stack direction='row'>
                        <div style={{ top: "100px", position: "absolute", zIndex: 1200 }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                {
                                    mode === "edit"
                                        ?
                                        <Tabs value={selectTab} onChange={tabHandleChange} aria-label="oilManagementDetailTabs">
                                            <Tab label='要素' {...a11yProps(0)} />
                                            <Tab label='特性' {...a11yProps(1)} />
                                        </Tabs>
                                        :
                                        <Tabs value={selectTab} onChange={tabHandleChange} aria-label="oilManagementDetailTabs">
                                            <Tab label='要素' {...a11yProps(0)} />
                                        </Tabs>
                                }
                            </Box>
                        </div>
                    </Stack>
                    {/* 要素 */}
                    <TabPanel value={selectTab} index={0}>
                        <div style={divStyles}>
                            <Typography variant="h6">
                                要素
                            </Typography>
                            <Stack sx={{ maxHeight: "calc(100vh)", overflow: 'auto' }}>
                                <Grid container spacing={3} direction={'row'}>
                                    <Grid item xs={12} mt={3}>
                                        <Controller
                                            control={control}
                                            name="Mv3breakBreakRated.sFixedName"
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    type="text"
                                                    label="固定可変名称"
                                                    size="small"
                                                    disabled={true}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            control={control}
                                            name="nClassNumber"
                                            rules={{
                                                pattern: {
                                                    value: /^-?\d+(?:\.\d+)?$/,
                                                    message: "数字を入力してください"
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors.nClassNumber?.message}
                                                    helperText={errors.nClassNumber?.message}
                                                    type="text"
                                                    size="small"
                                                    label="階層番号"
                                                   
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            control={control}
                                            name="sElementName"
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    type="text"
                                                    size="small"
                                                    label="要素名称"
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>

                            </Stack>
                        </div>
                    </TabPanel>
                    {/* 要素 */}
                    <TabPanel value={selectTab} index={1}>
                        <div style={divStyles}>
                            <Stack direction="row" display='flex' justifyContent='space-between'>
                                <div><Typography variant="h6" >特性</Typography></div>
                                <div >
                                    <Stack direction='row' display='flex' spacing={2}>
                                        <Stack>
                                            <Button fullWidth color="inherit" variant="contained" onClick={() => handleAdd()}>特性の追加</Button>
                                        </Stack>
                                    </Stack>
                                </div>
                            </Stack>
                            <Stack spacing={2} sx={{ mt: 1 }} >
                                <DialCharacterGrid
                                    ref={gridRef}
                                    colState={colState}
                                    onChangeSearchCondition={() => { }}
                                    keyword={""}
                                    onShowDetails={handleShowDetailPage}
                                    onDelete={handleDelete}
                                    heightForm="365"
                                    checkEdit={true}
                                    mode="edit"
                                />
                                {openedDeleteDialog ?
                                    <AlertDialog title={title} message={message} isOpen={true} onOK={handleDeleteOK} onCancel={handleDeleteCancel} /> :
                                    <div />
                                }
                            </Stack>
                        </div>
                    </TabPanel>
                    <Stack direction='row' justifyContent="flex-end" spacing={2} sx={{ mt: 0, mr: 3 }}>
                        <LoadingButton variant="contained" type="submit" loading={loadingFlag}>保存</LoadingButton>
                        <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                    </Stack>
                </form>
            </div>
            <FetchHelper fetchId={UPDATE_BREAK_ELEMENT}
                onComplete={(success, data, error) =>
                    success ? handleUpdateSuccess(success, data, error)
                        : handleSubmitError(success, data, error)} />
            <FetchHelper fetchId={CREATE_BREAK_ELEMENT}
                onComplete={(success, data, error) =>
                    success ? handleSubmitSuccess(success, data, error)
                        : handleSubmitError(success, data, error)} />
            <FetchHelper fetchId={DELETE_DIAL_CHARACTER}
                onComplete={(success: boolean, data: any, error: any) =>
                    success ? handleSuccessDelete(success, data, error)
                        : handleErrorDelete(success, data, error)} />
            <FetchHelper fetchId={GET_BREAK_ELEMENT_BY_ID}
                onComplete={(success: boolean, data: any, error: any) =>
                    success ? handleSuccessGet(success, data, error)
                        : handleErrorGet(success, data, error)} />
        </>
    )
}

const mapStateToProps = (state: ApplicationState) => ({
    userId: state.app.user?.userId,
    fetchUpdate: selectors.getFormState(
        state,
        GET_BREAK_ELEMENT
    ),
});

const mapDispatchToProps = (dispatch: any) => {
    return {
        onSearch: (params: { [key: string]: any }) => dispatch(actionCreators.fetch(GET_BREAK_ELEMENT, `/master/break_element/gets`, "GET", params)),
        onCreate: (data: any) => dispatch(actionCreators.fetch(CREATE_BREAK_ELEMENT, `/master/break_element/add`, "POST", data, false, true)),
        onSearchById: (params: any) => dispatch(actionCreators.fetch(GET_BREAK_ELEMENT_BY_ID, `/master/break_element/get`, "POST", params,false,true)),
        onDeleteDialCharacter: (params: { [key: string]: any }) => dispatch(actionCreators.fetch(DELETE_DIAL_CHARACTER, `/master/dial_character/delete`, "POST", params, false, true)),
        onUpdate: (id: string, data: any) => dispatch(actionCreators.fetch(UPDATE_BREAK_ELEMENT, `/master/break_element/modify`, "POST", data, false, true)),
        onSuccess: () =>
            dispatch(actionCreators.showMessage({
                type: "info",
                title: "要素更新",
                body: "要素を更新しました。",
            })),
        onError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "検索",
                body: "検索できません。",
            })),
        onCreateError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "要素登録",
                body: "要素を登録出来ませんでした。",
            })),
        onUpdateError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "要素編集",
                body: "要素を更新出来ませんでした。",
            })),
        onSuccessDeleteDialCharacter: () =>
            dispatch(actionCreators.showMessage({
                type: "info",
                title: "定格ダイアル削除",
                body: "定格ダイアルを削除しました。",
            })),
        onErrorDeleteDialCharacter: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "定格ダイアル削除",
                body: "定格ダイアルを削除できませんでした。",
            })),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(BreakElementEdit as any);
