import { ControlModel, MsSourceModel, ProcessMode } from "../models/Index"
import { BeamsNumber } from "../utils/FormatNumber";
import { doGetIkasymDirectMG, doGetIkasymTotalMG, doGetIkpeakDirectMG, doGetIkpeakTotalMG, doGetIksymDirectMG, doGetIksymTotalMG, doGetPerZTotalMG } from "./calcPointFunction";
import { DoCalcDemandCapacity } from "./PowerCalculation";
import { MS_MODE_CALCULATION, MS_MODE_CHECKING, MS_MODE_DRAWING, MS_MODE_HARMONIC, MS_MODE_PERCENT_Z, MS_MODE_POWER_FLOW, MS_MODE_VOLTDROP } from "../statics";
import { BOTTOM_CENTER, DIRECT_NONE, IDS_UNIT_AMPERE, RIGHT_CENTER } from "../models/Constants";
import { doGetTextAdjust, doGetTextCT1Current, doGetTextCT2Current, doGetTextCharacterName } from "./Adjust";
import { isOkSetCurveInfo } from "./infoRead";

export const doGetValueCapacitySource =(control:ControlModel)=>{
  let capacity = control.properties.capacity;
  if(control.properties.symKind === 1){ //SOURCE_SYMBOL_INFINITE
    capacity = 0
  }
  return BeamsNumber(capacity,6)
}

export const doGetValuePercentZSource =(control:ControlModel)=>{
  let dPercentZ = control.properties.percentZ;
  if(control.properties.symKind === 1){ //SOURCE_SYMBOL_INFINITE
    dPercentZ = 0
  }
  return dPercentZ
}

export const doGetValuePercentRr = (dXR:any, dPercentZ:any) => {
  let dPercentR = dPercentZ / Math.sqrt(dXR * dXR + 1);
  return dPercentR;
}

export const doGetValuePercentXr = (dXR:any, dPercentR:any) => {
  let dPercentX = dXR * dPercentR;
  return dPercentX;
}

export const doGetValueLoadCurrent = (control: ControlModel) => {
  if (control.properties.voltage <= 0) {
    return 0;
  }

  return control.properties.loadCapacity / control.properties.voltage / Math.sqrt(3);
}

export const DoGetTextVoltage = (control:ControlModel,bUnit: boolean = true) => {
  let ret = control.properties.voltage; //DLLGetTextByBeams(lVoltage);
  if (bUnit) {
    ret += "V";
  }
  return ret;
}

export const DoGetTextCapacity = (control:ControlModel,bUnit: boolean = true) => {
  let ret = doGetValueCapacitySource(control).toString(); //DLLGetTextByBeamsEx(doGetValueCapacitySource(), 6)
  if (bUnit) {
    ret += "MVA";
  }
  return ret;
}

export const DoGetTextXR = (control:ControlModel) => {
  let ret = control.properties.xr; //DLLGetTextByBeamsEx(doGetValueCapacitySource(), 6)
  if(control.properties.symKind === 1){ //SOURCE_SYMBOL_INFINITE
    ret = 0;
  }
  return BeamsNumber(ret, 5); //DLLGetTextByBeamsEx(dXR, 5);
}

//CEleSource::IsPointFaultSource
export const isPointFaultSource = (props:MsSourceModel) =>
{
	if (props.fault) {
		return true;
	}
	return false;
}

//CEleSource::DoGetTextPointState
export const doGetTextPointState = (props:MsSourceModel) =>
{
	let sText = '';

	if (props.fault) {
		sText = 'Yes';
	}
	else {
    sText = 'No';
	}

	return sText;
}

export const DoGetTextPercentZr = (control:ControlModel, dStdCapacity:any, bUnit: boolean = true ) => {
  let dPercentZ = doGetValuePercentZSource(control) * dStdCapacity/ 10000;
  let sText = BeamsNumber(dPercentZ, 4).toString();
  if (bUnit) {
    sText += "%r";
  }
  return sText;
}

export const DoGetTextPercentRr = (control:ControlModel, dStdCapacity:any, bUnit: boolean = true ) => {
  let dPercentR = doGetValuePercentRr(control.properties.xr, doGetValuePercentZSource(control));
  dPercentR *= dStdCapacity / 10000;
  let sText = BeamsNumber(dPercentR, 4).toString();
  if (bUnit) {
    sText += "%r";
  }
  return sText;
}

export const DoGetTextPercentXr = (control:ControlModel, dStdCapacity:any, bUnit: boolean = true ) => {
  let dPercentR = doGetValuePercentRr(control.properties.xr, doGetValuePercentZSource(control));
  let dPercentX = doGetValuePercentXr(control.properties.xr, dPercentR);
  dPercentX *= dStdCapacity / 10000;
  let sText = BeamsNumber(dPercentX, 4).toString();
  if (bUnit) {
    sText += "%r";
  }
  return sText;
}

//CEleSource::DoGetTextDirectIk3sym
export const doGetTextDirectIk3sym = (control:ControlModel, bUnit: boolean = true ) => {
  let dIsymDirect = doGetIksymDirectMG(control.properties.calcPoint0);
  let sText;
  if (dIsymDirect == 0) {
    sText = '∞';
  } 
  else if (dIsymDirect > 0)
  {
    sText = BeamsNumber(dIsymDirect / 1000,4);
    if (bUnit) {
      sText = sText + 'kAsym';
    }
  } else {
    sText = "????"
  }
  return sText;
}

//CEleSource::DoGetTextContract
export const doGetTextContract = (control:ControlModel, bUnit: boolean = true) => {
  let sText = BeamsNumber(control.properties.contract, 7).toString();
  if (bUnit) {
    sText = sText + 'kW';
  }
  return sText;
}

//CEleSource::DoGetTextPresent
export const doGetTextPresent = (control:ControlModel) => {
  let sText = BeamsNumber(control.properties.present, 5).toString();
  return sText;
}

//CEleSource::DoGetTextTarget
export const doGetTextTarget = (control: ControlModel) => {
  let sText = BeamsNumber(control.properties.target, 5).toString();
  return sText;
}

//CEleSource::DoGetTextDemand
export const doGetTextDemand = (control: ControlModel, diagramData: ControlModel[], bUnit: boolean = true) => {
  let dCapacity = DoCalcDemandCapacity(control.properties.contract, 
                                       control.properties.present, 
                                       control.properties.target, 
                                       diagramData, 
                                       control.id);
  let sText = BeamsNumber(dCapacity, 6).toString();

  if (bUnit) {
    sText = sText + "kvar";
  }
  return sText;
}

//CEleSource::DoGetTextHarmonicCurrentRatioSum
export const doGetTextHarmonicCurrentRatioSum = (control:ControlModel, nHarmonicIndex: number, bUnit: boolean = true) => {
  let props = control.properties as MsSourceModel;
  let sText = "";
  let dCurrent = 0;
  let dCurrentSum = 0;
  let dLoadCurrent = doGetValueLoadCurrent(control);
  if (dLoadCurrent != 0) {
    switch (nHarmonicIndex) {
      case 0:
        dCurrent = props.harmonicCurrent0;
        dCurrentSum += dCurrent * dCurrent;
        dCurrent = props.harmonicCurrent1;
        dCurrentSum += dCurrent * dCurrent;
        break;
      case 1:
        dCurrent = props.harmonicCurrent2;
        dCurrentSum += dCurrent * dCurrent;
        dCurrent = props.harmonicCurrent3;
        dCurrentSum += dCurrent * dCurrent;
        break;
      case 2:
        dCurrent = props.harmonicCurrent4;
        dCurrentSum += dCurrent * dCurrent;
        dCurrent = props.harmonicCurrent5;
        dCurrentSum += dCurrent * dCurrent;
        break;
      case 3:
        dCurrent = props.harmonicCurrent6;
        dCurrentSum += dCurrent * dCurrent;
        dCurrent = props.harmonicCurrent7;
        dCurrentSum += dCurrent * dCurrent;
        dCurrent = props.harmonicCurrent8;
        dCurrentSum += dCurrent * dCurrent;
        dCurrent = props.harmonicCurrent9;
        dCurrentSum += dCurrent * dCurrent;
        break;
      case 4:
        dCurrent = props.harmonicCurrent10;
        dCurrentSum += dCurrent * dCurrent;
        dCurrent = props.harmonicCurrent11;
        dCurrentSum += dCurrent * dCurrent;
        dCurrent = props.harmonicCurrent12;
        dCurrentSum += dCurrent * dCurrent;
        dCurrent = props.harmonicCurrent13;
        dCurrentSum += dCurrent * dCurrent;
        dCurrent = props.harmonicCurrent14;
        dCurrentSum += dCurrent * dCurrent;
        dCurrent = props.harmonicCurrent15;
        dCurrentSum += dCurrent * dCurrent;
        break;
      case 5:
        dCurrent = props.harmonicCurrent1;
        dCurrentSum += dCurrent * dCurrent;
        dCurrent = props.harmonicCurrent2;
        dCurrentSum += dCurrent * dCurrent;
        dCurrent = props.harmonicCurrent3;
        dCurrentSum += dCurrent * dCurrent;
        dCurrent = props.harmonicCurrent4;
        dCurrentSum += dCurrent * dCurrent;
        dCurrent = props.harmonicCurrent5;
        dCurrentSum += dCurrent * dCurrent;
        dCurrent = props.harmonicCurrent6;
        dCurrentSum += dCurrent * dCurrent;
        dCurrent = props.harmonicCurrent7;
        dCurrentSum += dCurrent * dCurrent;
        dCurrent = props.harmonicCurrent8;
        dCurrentSum += dCurrent * dCurrent;
        dCurrent = props.harmonicCurrent9;
        dCurrentSum += dCurrent * dCurrent;
        dCurrent = props.harmonicCurrent10;
        dCurrentSum += dCurrent * dCurrent;
        dCurrent = props.harmonicCurrent11;
        dCurrentSum += dCurrent * dCurrent;
        dCurrent = props.harmonicCurrent12;
        dCurrentSum += dCurrent * dCurrent;
        dCurrent = props.harmonicCurrent13;
        dCurrentSum += dCurrent * dCurrent;
        dCurrent = props.harmonicCurrent14;
        dCurrentSum += dCurrent * dCurrent;
        dCurrent = props.harmonicCurrent15;
        dCurrentSum += dCurrent * dCurrent;
        break;
    }
    dCurrentSum = Math.sqrt(dCurrentSum);
    dCurrent = dCurrentSum / dLoadCurrent * 100;
    sText = BeamsNumber(dCurrent, 5).toString();
  }
  if (bUnit) {
    sText = sText + '%';
  }
  return sText;
}

//CEleSource::DoGetRefV1
export const doGetRefV1 = (mode: any, control:ControlModel, infoCircuit:any,bSociety:boolean, diagramData: ControlModel[]) => {
  
  //CEleSource::DoGetRefV1Left  sRefV1Left
  let labelTexts = [{
      value: control.properties.refNo,
      color: "#f800f8",
  }];

  if (!control.properties.viewResultText)
  {
    return labelTexts;
  }
  
  if (bSociety){
    //TODO
  }

  switch(mode) {
    case ProcessMode.DRAWING:
    case ProcessMode.CHECKING:
      //+= DoGetTextVoltage()
      labelTexts.push({
        value: DoGetTextVoltage(control),
        color: "blue",
      });
      // += DoGetTextCapacity()
      labelTexts.push({
        value: DoGetTextCapacity(control),
        color: "blue",
      });
      // += 'X/R=' + DoGetTextXR()
      labelTexts.push({
        value: 'X/R' + '=' + DoGetTextXR(control),
        color: "blue",
      });
      break;
    case ProcessMode.PERCENT_Z:
      // += 'X/R=' + DoGetTextXR()
      labelTexts.push({
        value: 'X/R' + '=' + DoGetTextXR(control),
        color: "blue"
      });

      // += DoGetTextPercentZr(pDoc->m_InfoCircuit.m_dStdCapacity);
      // += '=' + DoGetTextPercentRr(pDoc->m_InfoCircuit.m_dStdCapacity, False);
      // += '+j' + DoGetTextPercentXr(pDoc->m_InfoCircuit.m_dStdCapacity, False);
      labelTexts.push({
        value: DoGetTextPercentZr(control, infoCircuit.stdCapacity)
               + '='
               + DoGetTextPercentRr(control, infoCircuit.stdCapacity, false)
               + '+j'
               + DoGetTextPercentXr(control, infoCircuit.stdCapacity, false),
        color: "blue"
      });
      break;
    case ProcessMode.CALCULATION:
      //+= DoGetTextVoltage()
      labelTexts.push({
        value: DoGetTextVoltage(control),
        color: "blue",
      });
      if (!control.properties.fault){
        break;
      }
      // += _T(" ")
      labelTexts.push({
        value: ' ',
        color: "blue",
      });
      // += 'I"k3' + '通過' + '=' + DoGetTextDirectIk3sym()
      labelTexts.push({
        value: 'I"k3通過=' + doGetTextDirectIk3sym(control),
        color: "blue",
      });

      break;
    case ProcessMode.VOLTDROP:
      labelTexts.push({
          value: DoGetTextVoltage(control),
          color: "blue"
      });
      break;
    case ProcessMode.IMPROVE_PF:
      if (control.properties.calcImprovePF) {
        labelTexts.push({
            value: '契約電力=' + doGetTextContract(control),
            color: "blue"
        });

        labelTexts.push({
          value: '負荷力率=' + doGetTextPresent(control),
          color: "blue"
        });

        labelTexts.push({
          value: '目標力率=' + doGetTextTarget(control),
          color: "blue"
        });

        labelTexts.push({
          value: 'Qc=' + doGetTextDemand(control, diagramData),
          color: "blue"
        });
      }
      break;
    case ProcessMode.HARMONIC:
      labelTexts.push({
          value: DoGetTextVoltage(control),
          color: "blue"
      });

      labelTexts.push({
        value: 'THD = ' + doGetTextHarmonicCurrentRatioSum(control, 5),
        color: "blue"
      });
      break;
    case ProcessMode.POWER_FLOW:
      break;
    default:
      break;
  }

  return labelTexts;
}

export const doGetRefV2 = (props: MsSourceModel) => {
  let sAdjust: any[] = [];
  
  sAdjust.push(props.refNo);
  sAdjust.push(props.pointText);
  let tmpCT = doGetTextCT1Current(props.details, false)
  let sUnit = IDS_UNIT_AMPERE;
  tmpCT += "(" + sUnit + ")";
  tmpCT += "/";
  tmpCT += doGetTextCT2Current(props.details, false);
  tmpCT += "(" + sUnit + ")";
  sAdjust.push(tmpCT);
  sAdjust.push(props.details.base.typeName);

  sAdjust.push(doGetTextCharacterName(props.details, 0));
  sAdjust = sAdjust.concat(doGetTextAdjust(props.details));

  return sAdjust;
}

//CEleSource::DoGetTextDirectIk3peak
export const doGetTextDirectIk3peak = (props:ControlModel, bUnit: boolean = false) =>
{
	let dIpeakDirect = doGetIkpeakDirectMG(props.properties.calcPoint0);
	let sText;
	if (dIpeakDirect == 0) {
		sText = '∞';
	}
	else if (dIpeakDirect > 0) {
		sText = BeamsNumber(dIpeakDirect / 1000, 4).toString();
		if (bUnit) {
			sText += 'kApeak';
		}
	}
	else {							// 短絡電流が正しく計算されなかったとき
		sText = '????';
	}
	return sText;
}

//CEleSource::DoGetTextDirectIk3asym
export const doGetTextDirectIk3asym = (props:ControlModel, bUnit: boolean = true) =>
{
	let dIasymDirect = doGetIkasymDirectMG(props.properties.calcPoint0);
	let sText;
	if (dIasymDirect == 0) {
		sText = '∞';
	}
	else if (dIasymDirect > 0) {
		sText = BeamsNumber(dIasymDirect / 1000, 4).toString();
		if (bUnit) {
			sText += 'kAasym';
		}
	}
	else {							// 短絡電流が正しく計算されなかったとき
		sText = '????';
	}
	return sText;
}

//CEleSource::DoGetTextTotalIk3sym
export const doGetTextTotalIk3sym = (props:ControlModel, bUnit:boolean = true) =>
{
	let dIsymTotal = doGetIksymTotalMG(props.properties.calcPoint0);
	let sText;
	if (dIsymTotal == 0) {
		sText = '∞';
	}
	else if (dIsymTotal > 0) {
		sText = BeamsNumber(dIsymTotal / 1000, 4).toString();
		if (bUnit) {
			sText += 'kAsym';
		}
	}
	else {							// 短絡電流が正しく計算されなかったとき
		sText = '????';
	}
	return sText;
}

//CEleSource::DoGetTextTotalIk3peak
export const doGetTextTotalIk3peak = (props:MsSourceModel, bUnit:boolean = true) =>
{
	let dIpeakTotal = doGetIkpeakTotalMG(props.calcPoint0);
	let sText = '';
	if (dIpeakTotal == 0) {
		sText = '∞';
	}
	else if (dIpeakTotal > 0) {
		sText = BeamsNumber(dIpeakTotal / 1000, 4).toString();
		if (bUnit) {
			sText += 'kApeak';
		}
	}
	else {							// 短絡電流が正しく計算されなかったとき
		sText = '????';
	}
	return sText;
}

//CEleSource::DoGetTextTotalIk3asym
export const doGetTextTotalIk3asym = (props:MsSourceModel, bUnit:boolean = true) =>
{
	let dIasymTotal = doGetIkasymTotalMG(props.calcPoint0);
	let sText = '';
	if (dIasymTotal == 0) {
		sText = '∞';
	}
	else if (dIasymTotal > 0) {
		sText = BeamsNumber(dIasymTotal / 1000, 4).toString();
		if (bUnit) {
			sText += 'kAasym';
		}
	}
	else {							// 短絡電流が正しく計算されなかったとき
		sText = '????';
	}
	return sText;
}

// CEleSource::DoGetTextTotalPercentZr
export const doGetTextTotalPercentZr = (props:MsSourceModel, bUnit:boolean = true) =>
{
	let dPerZ = doGetPerZTotalMG(props.calcPoint0).dPercentZ;
	let sText = '';
	if (dPerZ >= 0) {
		sText = BeamsNumber(dPerZ, 4).toString();
		if (bUnit) {
			sText += '%r';
		}
	}
	else {							// 短絡電流が正しく計算されなかったとき
		sText = '????';
	}
	return sText;
}

//CEleSource::DoGetTextFlowsResult
export const doGetTextFlowsResult = (props:MsSourceModel, processMode:number, nDirect:number, bSociety:boolean = false) =>
{
	let sRefV1: string[] = [];

	if (bSociety) {
		return sRefV1;
	}

	switch (processMode) {
	case MS_MODE_DRAWING:
	case MS_MODE_CHECKING:
	case MS_MODE_PERCENT_Z:
	case MS_MODE_CALCULATION:
	case MS_MODE_VOLTDROP:
	case MS_MODE_HARMONIC:
		break;
	case MS_MODE_POWER_FLOW:
		if (props.activePower !== 0 && props.reactivePower !== 0) {
			if (nDirect == DIRECT_NONE) {
				sRefV1.push(BeamsNumber(Math.abs(props.activePower),5).toString() + 'kW');
				sRefV1.push(BeamsNumber(Math.abs(props.reactivePower),5).toString() + 'kvar');
			}
			else if (nDirect == BOTTOM_CENTER || nDirect == RIGHT_CENTER) {
				sRefV1.push(BeamsNumber(Math.abs(props.activePower),5).toString() + 'kW');
        let sTemp = '';
				if (props.activePower > 0) {
					if (props.reactivePower < 0) {
						sTemp += "-j";
					}
					else {
						sTemp += "+j";
					}
				}
				else {
					if (props.reactivePower < 0) {
						sTemp += "+j";
					}
					else {
						sTemp += "-j";
					}
				}
				sRefV1.push(sTemp + BeamsNumber(Math.abs(props.reactivePower),5).toString() + 'kvar');
			}
		}
		break;
	}

	return sRefV1;
}