import React from "react";
// material
import { Breakpoint, styled } from "@mui/material/styles";
import useResponsive from "../hooks/useResponsive";
//

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 0; //64;
const APP_BAR_DESKTOP = 0; //92;
const minWidth = {
    width: "calc(100% - 280px)",
    minWidth: "calc(100% - 280px)",
}
const maxWidth = {
    width: "100%",
}
const RootStyle = styled("div")({
    display: "flex",
    // minHeight: "calc(100vh - 70px)",
    overflow: "hidden",
    height: "100%",
    overflowY: "auto",
});

const MainStyle = styled("div")(({ theme }) => ({
    flexGrow: 1,
    overflow: "auto",
    minHeight: "100%",
    marginTop: 0,
    paddingTop: APP_BAR_MOBILE,
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up("lg")]: {
        marginTop: 0,
        paddingTop: APP_BAR_DESKTOP,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        height: "100%",
    },
    [theme.breakpoints.up("xl")]: {
        marginTop: 0,
        paddingTop: APP_BAR_DESKTOP,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        height: "100%",
    },
}));

// ----------------------------------------------------------------------
export type DashboardLayoutProps = {
    children?: React.ReactNode;
};

export const DashboardLayout = (props: DashboardLayoutProps) => {
    const { children } = props;
    const isDesktop = useResponsive({ query: 'up', key: 'lg' as Breakpoint });

    return (
        <>
            <RootStyle style={(!isDesktop) ? maxWidth : minWidth}>
                <MainStyle>{children}</MainStyle>
            </RootStyle>
        </>
    );
};

export default DashboardLayout;
