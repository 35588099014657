export const downloadFile = (data: any) => {
    if (["zip", "csv", "json"].includes(data.dataType)){
        const linkSource = `data:application/${data.dataType};base64,${data.data}`;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = data.fileName;
        downloadLink.click();
    }

    if (["pdf"].includes(data.dataType)){
        var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream;
        var isChrome =
            navigator.userAgent.toLowerCase().indexOf("CriOS") > -1 ||
            navigator.vendor.toLowerCase().indexOf("google") > -1;
        var iOSVersion: any[]= [];
        if (iOS && navigator != null) {
            iOSVersion = (navigator as any).userAgent
            .match(/OS [\d_]+/i)[0]
            .substr(3)
            .split("_")
            .map((n:any) => parseInt(n));
        }
        var attachmentData = data.data;
        var attachmentName = data.fileName;
        var contentType = "application/pdf";

        var binary = atob(attachmentData.replace(/\s/g, ""));
        var len = binary.length;
        var buffer = new ArrayBuffer(len);
        var view = new Uint8Array(buffer);
        for (var i = 0; i < len; i++) {
            view[i] = binary.charCodeAt(i);
        }
        var linkElement = document.createElement("a");
        try {
            var hrefUrl = "";
            if (iOS && !isChrome && iOSVersion[0] <= 12) {
                let blob = "";
                blob = "data:application/pdf;base64," + data.data;
                hrefUrl = blob;
            } else {
                if (iOS && !isChrome) {
                    contentType = "application/octet-stream";
                }
                let blob = new Blob([view], { type: contentType });
                hrefUrl = window.URL.createObjectURL(blob);
            }
            linkElement.setAttribute("href", hrefUrl);
            linkElement.setAttribute("target", "_blank");
            if ((iOS && (iOSVersion[0] > 12 || isChrome)) || !iOS) {
                linkElement.setAttribute("download", attachmentName);
            }
            var clickEvent = new MouseEvent("click", {
                view: window,
                bubbles: true,
                cancelable: false
            });
            linkElement.dispatchEvent(clickEvent);
        } catch (ex) {}
    }
}

export const exportDXF = (fileData: string, fileName: string) => {
    const blob = new Blob([fileData], { type: "text/plain" });
    const linkSource = URL.createObjectURL(blob);
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
}

export const openFileInNewTabByBlob = (blob: any) => {
    const linkSource = URL.createObjectURL(blob);
    var link = window.document.createElement("a");
    link.target = '_blank';
    link.href = linkSource;
    
    var evt = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true
    });
    link.dispatchEvent(evt);
}

export const downloadFileByBlob = (blob: any, fileName: string) => {
    const linkSource = URL.createObjectURL(blob);
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
}
