import { Box, Button, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { Controller } from "react-hook-form";
import * as EKind from "../../models/ElementKind";
import { ArrowDropDown, ArrowDropUp, ArrowLeft, ArrowRight } from "@mui/icons-material";

export type SetupPartProps = {
    elementType: string;
    part: any;
    index: any;
    formSetting: any;
    drawThreeCurve?: boolean;
    m_bModePM: boolean;
    modeViewOnly: boolean;
};

export const SetupPart = (props: SetupPartProps) => {
    const { part, index, formSetting: { control, setValue, getValues }, elementType, drawThreeCurve = false, m_bModePM, modeViewOnly} = props;
    
    const [setLock, setSetLock] = useState(getValues(`parts.${index}.setLock`))
    const [showDrawThreeCurve, setShowDrawThreeCurve] = useState(drawThreeCurve)
    const isMsFUSE = elementType === EKind.MS_FUSE;

    const handleSelectOptionsByButton = (name : string, action : 'next' | 'prev') => {
        let currIndex, incomingIndex = 0;
        switch(name) {
            case 'currentValue':
                currIndex = part.currentList.findIndex((v : any) => v.value === getValues(`parts.${index}.currentValue`)) ?? 0;
                incomingIndex = action === 'next' ? Math.min(currIndex + 1, part.currentList.length - 1) : Math.max(currIndex - 1, 0)
                setValue(`parts.${index}.currentValue`, part.currentList[incomingIndex].value)
                break;
            case 'timeValue':
                currIndex = part.timeList.findIndex((v : any) => v.value === getValues(`parts.${index}.timeValue`)) ?? 0;
                incomingIndex = action === 'next' ? Math.min(currIndex + 1, part.timeList.length - 1) : Math.max(currIndex - 1, 0)
                setValue(`parts.${index}.timeValue`, part.timeList[incomingIndex].value)
                break;
            case 'magniCurrentValue':
                currIndex = part.magniCurrentList.findIndex((v : any) => v.value === getValues(`parts.${index}.magniCurrentValue`)) ?? 0;
                incomingIndex = action === 'next' ? Math.min(currIndex + 1, part.magniCurrentList.length - 1) : Math.max(currIndex - 1, 0)
                setValue(`parts.${index}.magniCurrentValue`, part.magniCurrentList[incomingIndex].value)
                break;
            case 'magniTimeValue':
                currIndex = part.magniTimeList.findIndex((v : any) => v.value === getValues(`parts.${index}.magniTimeValue`)) ?? 0;
                incomingIndex = action === 'next' ? Math.min(currIndex + 1, part.magniTimeList.length - 1) : Math.max(currIndex - 1, 0)
                setValue(`parts.${index}.magniTimeValue`, part.magniTimeList[incomingIndex].value)
                break;
        }
        setValue("callWatchFuction",true);
    }

    const handleChangeCurrentValue = (e: any) => {
        setValue(e.target.name, e.target.value)
        setValue("callWatchFuction",true);
    }

    const handleChangeTimeValue = (e: any) => {
        setValue(e.target.name, e.target.value)
        setValue("callWatchFuction",true);
    }

    
    const handleChangeMagniCurrentValue = (e: any) => {
        setValue(e.target.name, e.target.value)
        setValue("callWatchFuction",true);
    }

    
    const handleChangeMagniTimeValue = (e: any) => {
        setValue(e.target.name, e.target.value)
        setValue("callWatchFuction",true);
    }

    const handleClickSetLock = () => {
        setValue(`parts.${index}.setLock`, !setLock)
        setSetLock(!setLock)
        setValue("callWatchFuction",true);
    }

    const handleClickDrawThreeCurve = () => {
        setShowDrawThreeCurve(!showDrawThreeCurve)
        setValue("drawThreeCurve", !drawThreeCurve)
        setValue("isCallDB", true)
    }

    const handleChangeCharDialID = (e: any) => {
        setValue(e.target.name, e.target.value)
        setValue("isCallDB", true)
    }

    return (
        <>
            <Grid container mt={1}>
                <Grid item xs={6}>
                    <Stack>
                        <FormControl fullWidth size="small">
                            <Controller
                                name={`parts.${index}.charDialID`}
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        disabled={(isMsFUSE && showDrawThreeCurve) || ((m_bModePM || modeViewOnly) && !isMsFUSE)}
                                        sx= {{ height: 32 }}
                                        onChange={handleChangeCharDialID}
                                    >
                                        {part.charDialList?.map((item : any) => (
                                            <MenuItem key={item.value} value={item.value}>
                                                {item.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )}
                            />
                        </FormControl>
                    </Stack>
                </Grid>
                <Grid item xs={6} pl={3}>
                    <Controller
                        name={`parts.${index}.setLock`}
                        control={control}
                        render={({ field }: any) => (
                            <FormControlLabel 
                                {...field} 
                                disabled={!part.useLock || m_bModePM || modeViewOnly}
                                control={<Checkbox size="small" onClick={handleClickSetLock}/>} 
                                checked={field.value} 
                                label="ロック(不動作に設定)"
                            />
                        )}
                    />
                </Grid>
            </Grid>

            <fieldset style={{marginTop:'16px'}}>
                <Stack direction={'row'}>
                    <Stack flex={1}>
                        <Grid container>
                            <Grid item xs={6}>
                                <Typography variant="body2" width="60%">
                                    {part.currentTitle && (part.currentTitle + ':')}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth size="small">
                                    <InputLabel sx={{ color: 'black' }}></InputLabel>
                                    <Controller
                                        name={`parts.${index}.currentValue`}
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                disabled = {part.currentFlag === 1 || m_bModePM || modeViewOnly}
                                                style={{
                                                    backgroundColor: part.currentFlag === 1
                                                        ? "floralwhite" : "white"
                                                }}
                                                sx= {{ height: 32 }}
                                                onChange={handleChangeCurrentValue}
                                            >
                                                {part.currentList?.map((item : any) => (
                                                    <MenuItem key={item.label} value={item.value}>
                                                        {item.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container mt={0.3}>
                            <Grid item xs={6}>
                                <Typography variant="body2" width="60%">
                                    {part.timeTitle && (part.timeTitle + ':')}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth size="small">
                                    <Controller
                                        name={`parts.${index}.timeValue`}
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                disabled = {part.timeFlag === 1 || m_bModePM || modeViewOnly}
                                                style={{
                                                    backgroundColor: part.timeFlag === 1
                                                        ? "floralwhite" : "white"
                                                }}
                                                sx= {{ height: 32 }}
                                                onChange={handleChangeTimeValue}
                                            >
                                                {part.timeList?.map((item : any) => (
                                                    <MenuItem key={item.label} value={item.value}>
                                                        {item.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Stack>
                    <Box width={100} position={'relative'} ml={2}>
                        <Button 
                            disabled={part.currentList.length === 0 || m_bModePM || modeViewOnly}
                            onClick={() => {handleSelectOptionsByButton('currentValue', 'prev')}} size="small" variant={'outlined'} startIcon={<ArrowLeft />}
                            sx={{'& .MuiButton-startIcon': {marginRight: '0px'}, minWidth: 'unset', position: 'absolute', left: '0', top: '50%', transform: 'translateY(-50%)'}}
                        />
                        <Button 
                            disabled={part.currentList.length === 0 || m_bModePM || modeViewOnly}
                            onClick={() => {handleSelectOptionsByButton('currentValue', 'next')}} size="small" variant={'outlined'} startIcon={<ArrowRight />}
                            sx={{'& .MuiButton-startIcon': {marginRight: '0px'}, minWidth: 'unset', position: 'absolute', right: '0', top: '50%', transform: 'translateY(-50%)'}} 
                        />
                        <Button 
                            disabled={part.timeList.length === 0 || m_bModePM || modeViewOnly}
                            onClick={() => {handleSelectOptionsByButton('timeValue', 'next')}} size="small" variant={'outlined'} startIcon={<ArrowDropUp />}
                            sx={{'& .MuiButton-startIcon': {marginRight: '0px'}, minWidth: 'unset', position: 'absolute', left: '50%', top: '0', transform: 'translateX(-50%)'}}
                        />
                        <Button 
                            disabled={part.timeList.length === 0 || m_bModePM || modeViewOnly}
                            onClick={() => {handleSelectOptionsByButton('timeValue', 'prev')}} size="small" variant={'outlined'} startIcon={<ArrowDropDown />}
                            sx={{'& .MuiButton-startIcon': {marginRight: '0px'}, minWidth: 'unset', position: 'absolute', left: '50%', bottom: '0', transform: 'translateX(-50%)'}} 
                        />
                    </Box>
                </Stack>
            </fieldset>

            <fieldset style={{marginTop:'16px'}}>
                <Stack direction={'row'}>
                    <Stack flex={1}>
                        <Grid container>
                            <Grid item xs={6}>
                                <Typography variant="body2" width="60%">
                                    {part.magniCurrentTitle && (part.magniCurrentTitle + ':')}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth size="small">
                                    <Controller
                                        name={`parts.${index}.magniCurrentValue`}
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                label={part.magniCurrentTitle}
                                                disabled = {part.magniCurrentFlag === 1 || m_bModePM || modeViewOnly}
                                                style={{
                                                    backgroundColor: part.magniCurrentFlag === 1
                                                        ? "floralwhite" : "white"
                                                }}
                                                sx= {{ height: 32 }}
                                                onChange={handleChangeMagniCurrentValue}
                                            >
                                                {part.magniCurrentList?.map((item : any) => (
                                                    <MenuItem key={item.label} value={item.value}>
                                                        {item.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container mt={0.3}>
                            <Grid item xs={6}>
                                <Typography variant="body2" width="60%">
                                    {part.magniTimeTitle && (part.magniTimeTitle + ':')}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth size="small">
                                    <Controller
                                        name={`parts.${index}.magniTimeValue`}
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                label={part.magniTimeTitle}
                                                disabled={part.magniTimeFlag === 1 || m_bModePM || modeViewOnly}
                                                style={{
                                                    backgroundColor: part.magniTimeFlag === 1
                                                        ? "floralwhite" : "white"
                                                }}
                                                sx= {{ height: 32 }}
                                                onChange={handleChangeMagniTimeValue}
                                            >
                                                {part.magniTimeList?.map((item : any) => (
                                                    <MenuItem key={item.label} value={item.value}>
                                                        {item.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Stack>
                    <Box width={100} position={'relative'} ml={2}>
                        <Button 
                            disabled={part.magniCurrentList.length === 0 || m_bModePM || modeViewOnly}
                            onClick={() => {handleSelectOptionsByButton('magniCurrentValue', 'prev')}} size="small" variant={'outlined'} startIcon={<ArrowLeft />}
                            sx={{'& .MuiButton-startIcon': {marginRight: '0px'}, minWidth: 'unset', position: 'absolute', left: '0', top: '50%', transform: 'translateY(-50%)'}}
                        />
                        <Button 
                            disabled={part.magniCurrentList.length === 0 || m_bModePM || modeViewOnly}
                            onClick={() => {handleSelectOptionsByButton('magniCurrentValue', 'next')}} size="small" variant={'outlined'} startIcon={<ArrowRight />}
                            sx={{'& .MuiButton-startIcon': {marginRight: '0px'}, minWidth: 'unset', position: 'absolute', right: '0', top: '50%', transform: 'translateY(-50%)'}} 
                        />
                        <Button 
                            disabled={part.magniTimeList.length === 0 || m_bModePM || modeViewOnly}
                            onClick={() => {handleSelectOptionsByButton('magniTimeValue', 'next')}} size="small" variant={'outlined'} startIcon={<ArrowDropUp />}
                            sx={{'& .MuiButton-startIcon': {marginRight: '0px'}, minWidth: 'unset', position: 'absolute', left: '50%', top: '0', transform: 'translateX(-50%)'}}
                        />
                        <Button 
                            disabled={part.magniTimeList.length === 0 || m_bModePM || modeViewOnly}
                            onClick={() => {handleSelectOptionsByButton('magniTimeValue', 'prev')}} size="small" variant={'outlined'} startIcon={<ArrowDropDown />}
                            sx={{'& .MuiButton-startIcon': {marginRight: '0px'}, minWidth: 'unset', position: 'absolute', left: '50%', bottom: '0', transform: 'translateX(-50%)'}} 
                        />
                    </Box>
                </Stack>
            </fieldset>
            
            {isMsFUSE && 
                <FormControlLabel
                    disabled={modeViewOnly}
                    control={
                        <Controller
                            name="drawThreeCurve"
                            control={control}
                            render={({ field }) => (
                                <Checkbox
                                    {...field}
                                    checked={field.value}
                                    size="small"
                                    onClick={handleClickDrawThreeCurve}
                                />
                            )}
                        />
                    }
                    label={<Typography variant="body2">特性曲線を同時描画</Typography>}
                />                                                    
            }
        </>
    );
};
export default SetupPart;


