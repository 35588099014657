import { Box, IconButton, Stack, Typography } from '@mui/material'
import { KeyboardArrowLeft, KeyboardArrowRight, Delete } from '@mui/icons-material';

type NoticeCardProps = {
  cardData: any;
  cardIndex: number;
  quantity: number;
  handleChangeCardIndex: (isNext: boolean) => void;
  handleDeleteNotification: (notification: any) => void;
}

export const NoticeCard = (props: NoticeCardProps) => {
  const {
    cardData,
    cardIndex,
    quantity,
    handleChangeCardIndex,
    handleDeleteNotification,
  } = props

  return (<>
    <Stack>
      {/* pagination */}
      <Box display={'flex'} justifyContent={'flex-end'}>
        <Stack direction={'row'} alignItems={'center'}>
          <Box><Typography>{cardIndex + 1}/{quantity}</Typography></Box>
          <Box>
            <IconButton
              size='small'
              disabled={cardIndex === 0}
              onClick={() => {handleChangeCardIndex(false)}}
            >
              <KeyboardArrowLeft/>
            </IconButton>
          </Box>
          <Box>
            <IconButton
              size='small'
              disabled={cardIndex+1 === quantity}
              onClick={() => {handleChangeCardIndex(true)}}
            >
              <KeyboardArrowRight/>
            </IconButton>
          </Box>
        </Stack>
      </Box>

      {/* content */}
      <Stack height={'30vh'} overflow={'auto'} alignItems={'center'}>
        <Box>
          <Typography variant='body2' fontWeight={'bold'}>
            {cardData.title}
          </Typography>
        </Box>
        <Box my={2} textAlign={'center'} sx={{ whiteSpace: "pre-wrap" }}>
          <Typography variant='body2'>
            {cardData.content}
          </Typography>
        </Box>
      </Stack>

      <Box mt={1} display={'flex'} justifyContent={'center'}>
        <IconButton
          sx={{width:'36px', height:'36px'}}
          onClick={() => handleDeleteNotification(cardData)}
        >
          <Delete style={{fontSize:'28px'}}/>
        </IconButton>
      </Box>
    </Stack>
  </>)
}

export default NoticeCard
