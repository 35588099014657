import { ControlModel, MsEarthModel } from "../models/Index";

//CEleEarth::DoGetTextVoltage
export const doGetTextVoltage = (props:MsEarthModel, bUnit:boolean = true) =>
{
	let sText = props.voltage.toString();
	if (bUnit) {
		sText += 'V';
	}
	return sText;
}

//CEleEarth::DoGetRefV1
export const doGetRefV1 = (props:any) =>
{
	//CEleEarth::DoGetRefV1Left  sRefV1Left
	let labelTexts = [{
		value: props.refNo,
		color: "#f800f8",
	}];

    return labelTexts;
}