import React, { useEffect, useRef, useState } from 'react'
import { EquipTransscottModel } from '../../../models/breakModel'
import { Box, Button, Checkbox, CircularProgress, FormControlLabel, Grid, Stack, Tab, Tabs, TextField, Typography } from '@mui/material'
import { connect } from 'react-redux'
import { ApplicationState, actionCreators, selectors } from '../../../store'
import FetchHelper from '../../FetchHelper'
import { LoadingButton } from '@mui/lab'
import { Controller, useForm } from 'react-hook-form'
import { Path as RoutePath } from "../../../path";
import { useLocation, useNavigate, useParams } from 'react-router-dom'

//-----------
//FETCH ID
const GET_EQUIP_TRANSSCOTT = "GET_EQUIP_TRANSSCOTT"
const UPDATE_EQUIP_TRANSSCOTT = "UPDATE_EQUIP_TRANSSCOTT"
const CREATE_EQUIP_TRANSSCOTT = "CREATE_EQUIP_TRANSSCOTT"

const initialValue: EquipTransscottModel = {
  lTrans1Id: 0,
  bIsDefault: false,
  dCapacity: 0,
  dDampingTimeSeatm: 0,
  dDampingTimeSeatt: 0,
  dPeakValueSeatm: 0,
  dPeakValueSeatt: 0,
  dPerrseatm: 0,
  dPerrseatt: 0,
  dPerxseatm: 0,
  dPerxseatt: 0,
}

// -------------
// Props
export type EquipTransscottEditProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const EquipTransscottEdit = (props: EquipTransscottEditProps) => {
  const {
    userId,
    onCreate, onCreateError,
    onUpdate, onUpdateError,
    onSuccess, onError,
  } = props;

  const { control, formState: { errors }, handleSubmit, setValue, getValues, } = useForm<EquipTransscottModel>({
    criteriaMode: "all",
    defaultValues: initialValue,
    mode: "all",
  })
  type MyParams = {
    id: string;
    mode: string;
  };
  let { id, mode } = useParams<keyof MyParams>() as MyParams;
  const location = useLocation();
  let navigate = useNavigate();
  const [loadingFlag, setLoadingFlag] = useState(mode === "edit" ? true : false);
  const gridRef = useRef() as any;
  const Exp = /^[-+]?\d+(\.\d+)?$/;
  const messageIsNotNumber = "数字を入力してください。";

  const handleSuccess = (data: any) => {
    console.log('>>>> handle Successs');
    // if(data && data.data){
    const obj = data.data;
    setValue("lTrans1Id", obj.lTrans1Id);
    setValue("bIsDefault", obj.bIsDefault);
    setValue("dCapacity", obj.dCapacity??"");
    setValue("dDampingTimeSeatm", obj.dDampingTimeSeatm??"");
    setValue("dDampingTimeSeatt", obj.dDampingTimeSeatt??"");
    setValue("dPeakValueSeatm", obj.dPeakValueSeatm??"");
    setValue("dPeakValueSeatt", obj.dPeakValueSeatt??"");
    setValue("dPerrseatm", obj.dPerrseatm??"");
    setValue("dPerrseatt", obj.dPerrseatt??"");
    setValue("dPerxseatm", obj.dPerxseatm??"");
    setValue("dPerxseatt", obj.dPerxseatt??"");
    // }
    setLoadingFlag(false);
  }

  // 検索エラー時
  const handleError = (success: boolean, data: any, error: any) => {
    console.log('>>>> handle Error');
    gridRef.current.hideOverlay();
  }


  // 検索実行
  const handleEditSubmit = (data: EquipTransscottModel) => {
    setLoadingFlag(true);
    if (mode === "edit") {
      let request = {
        lTrans1Id: getValues("lTrans1Id"),
        bIsDefault: getValues("bIsDefault") ?? null,
        dCapacity: getValues("dCapacity") ?? null,
        dDampingTimeSeatm: getValues("dDampingTimeSeatm") ?? null,
        dDampingTimeSeatt: getValues("dDampingTimeSeatt") ?? null,
        dPeakValueSeatm: getValues("dPeakValueSeatm") ?? null,
        dPeakValueSeatt: getValues("dPeakValueSeatt") ?? null,
        dPerrseatm: getValues("dPerrseatm") ?? null,
        dPerrseatt: getValues("dPerrseatt") ?? null,
        dPerxseatm: getValues("dPerxseatm") ?? null,
        dPerxseatt: getValues("dPerxseatt") ?? null
      } as any
      const object = {
        requestPrms: request,
        userId: userId,
      }
      onUpdate(object);
    } else if (mode === "add") {
      let request = {
        bIsDefault: getValues("bIsDefault") ?? null,
        dCapacity: getValues("dCapacity") ?? null,
        dDampingTimeSeatm: getValues("dDampingTimeSeatm") ?? null,
        dDampingTimeSeatt: getValues("dDampingTimeSeatt") ?? null,
        dPeakValueSeatm: getValues("dPeakValueSeatm") ?? null,
        dPeakValueSeatt: getValues("dPeakValueSeatt") ?? null,
        dPerrseatm: getValues("dPerrseatm") ?? null,
        dPerrseatt: getValues("dPerrseatt") ?? null,
        dPerxseatm: getValues("dPerxseatm") ?? null,
        dPerxseatt: getValues("dPerxseatt") ?? null
      } as any
      const object = {
        requestPrms: request,
        userId: userId,
      }
      onCreate(object);
    }
  }

  // キャンセル
  const handleCancel = () => {
    if (location.key !== "default")
      navigate(-1);
    else
      navigate(RoutePath.EquipTransscottList);
  }
  // Submit後のAPI呼び出しエラー時
  const handleErrorUpdate = (success: boolean, data: any, error: any) => {
    onUpdateError();
    setLoadingFlag(false);
  }

  // Submit後のAPI呼び出し成功時
  const handleSuccessSubmit = (success: boolean, data: any, error: any) => {
    onSuccess();
    if (mode === "add") {
      navigate(RoutePath.EquipTransscottEdit + `/edit/${data.data.lTrans1Id}`, { replace: true })
    }
    setLoadingFlag(false);
  }

  // Submit後のAPI呼び出しエラー時
  const handleErrorSubmit = (success: boolean, data: any, error: any) => {
    onCreateError();
    setLoadingFlag(false)
  }

  //入力欄背景
  const divStyles = {
    backgroundColor: 'white',
    border: '1px solid #bebebe',
    boxShadow: '0 0 5px #fff, 0 0 5px #bebebe, 0 0 1px #aaa',
    padding: '20px 40px',
    marginTop: '40px',
  }

  return (
    <>
      {
        mode === "edit" &&
        <FetchHelper
          fetchId={GET_EQUIP_TRANSSCOTT}
          url={`/master/equip_transscott/get`}
          method="POST"
          request={true}
          params={{
            requestPrms: {
              id: id
            },
            userId: userId,
          }}
          json={true}
          onComplete={(success, data, error) => {
            success ? handleSuccess(data) : handleError(success, data, error);
          }}
        />
      }
      {loadingFlag && (
        <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1500, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
          <CircularProgress />
        </div>
      )}
      <div style={{ height: "100%", width: "100%", marginTop: "10px" }}>
        <Stack direction="row" justifyContent="flex-start" alignItems="center" sx={{}} style={{ margin: '18px', color: 'MenuText' }}>
          <Typography variant="h4">
          スコット結線変圧器詳細
          </Typography>
        </Stack>
        <form onSubmit={handleSubmit(handleEditSubmit)}>
          <div style={divStyles}>
            <Typography variant="h6">
              スコット結線変圧
            </Typography>
            <Stack mt={3} direction={{ xs: 'column', sm: 'row' }} sx={{ maxHeight: "calc(100vh)", overflow: 'auto' }}>
              <Grid container spacing={3} direction={'row'}>
                <Grid item xs={6} mt={1}>
                  <Controller
                    control={control}
                    name="dCapacity"
                    rules={{
                      pattern: {
                        value: Exp,
                        message: messageIsNotNumber,
                      }
                    }}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        {...field}
                        error={!!errors?.dCapacity}
                        helperText={errors?.dCapacity?.message}
                        type="text"
                        size="small"
                        label="容量（kVA）"
                      // InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6} mt={1}>
                  <Controller
                    control={control}
                    name="dDampingTimeSeatm"
                    rules={{
                      pattern: {
                        value: Exp,
                        message: messageIsNotNumber,
                      }
                    }}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        {...field}
                        error={!!errors?.dDampingTimeSeatm}
                        helperText={errors?.dDampingTimeSeatm?.message}
                        type="text"
                        size="small"
                        label="Damping time constant（cycles）(主座変圧器)"


                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name="dDampingTimeSeatt"
                    rules={{
                      pattern: {
                        value: Exp,
                        message: messageIsNotNumber,
                      }
                    }}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        {...field}
                        error={!!errors?.dDampingTimeSeatt}
                        helperText={errors?.dDampingTimeSeatt?.message}
                        type="text"
                        label="Damping time constant（cycles）(T座変圧器)"
                        size="small"

                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name="dPeakValueSeatm"
                    rules={{
                      pattern: {
                        value: Exp,
                        message: messageIsNotNumber,
                      }
                    }}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        {...field}
                        error={!!errors?.dPeakValueSeatm}
                        helperText={errors?.dPeakValueSeatm?.message}
                        type="text"
                        label="Peak value of rush current（times）(主座変圧器)"
                        size="small"

                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name="dPeakValueSeatt"
                    rules={{
                      pattern: {
                        value: Exp,
                        message: messageIsNotNumber,
                      }
                    }}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        {...field}
                        error={!!errors?.dPeakValueSeatt}
                        helperText={errors?.dPeakValueSeatt?.message}
                        type="text"
                        label="Peak value of rush current（times）(T座変圧器)"
                        size="small"

                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name="dPerrseatm"
                    rules={{
                      pattern: {
                        value: Exp,
                        message: messageIsNotNumber,
                      }
                    }}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        {...field}
                        error={!!errors?.dPerrseatm}
                        helperText={errors?.dPerrseatm?.message}
                        type="text"
                        label="主座の%R（%）"
                        size="small"

                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name="dPerrseatt"
                    rules={{
                      pattern: {
                        value: Exp,
                        message: messageIsNotNumber,
                      }
                    }}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        {...field}
                        error={!!errors?.dPerrseatt}
                        helperText={errors?.dPerrseatt?.message}
                        type="text"
                        label="T座の%R（%）"
                        size="small"

                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name="dPerxseatm"
                    rules={{
                      pattern: {
                        value: Exp,
                        message: messageIsNotNumber,
                      }
                    }}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        {...field}
                        error={!!errors?.dPerxseatm}
                        helperText={errors?.dPerxseatm?.message}
                        type="text"
                        label="主座の%X（%）"
                        size="small"

                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name="dPerxseatt"
                    rules={{
                      pattern: {
                        value: Exp,
                        message: messageIsNotNumber,
                      }
                    }}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        {...field}
                        error={!!errors?.dPerxseatt}
                        helperText={errors?.dPerxseatt?.message}
                        type="text"
                        label="T座の%X（%）"
                        size="small"

                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name="bIsDefault"
                    render={({ field }: any) => (
                      <FormControlLabel {...field} control={<Checkbox />} checked={field.value} label="デフォルトの容量値" labelPlacement="start" />
                    )}
                  />
                </Grid>
              </Grid>
            </Stack>
          </div>
          <Stack direction='row' justifyContent="flex-end" spacing={2} sx={{ mt: 1, mr: 2 }}>
            <LoadingButton variant="contained" type="submit" loading={loadingFlag}>保存</LoadingButton>
            <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
          </Stack>
          {/* </TabPanel> */}
        </form>
      </div>
      <FetchHelper fetchId={UPDATE_EQUIP_TRANSSCOTT}
        onComplete={(success: boolean, data: any, error: any) =>
          success ? handleSuccessSubmit(success, data, error) : handleErrorUpdate(success, data, error)} />

      <FetchHelper fetchId={CREATE_EQUIP_TRANSSCOTT}
        onComplete={(success, data, error) => success ?
          handleSuccessSubmit(success, data, error) :
          handleErrorSubmit(success, data, error)} />

    </>
  );
}

const mapStateToProps = (state: ApplicationState) => (
  {
    userId: state.app.user?.userId,
    fetchUpdate: selectors.getFormState(
      state,
      GET_EQUIP_TRANSSCOTT
    ),
  }
)
const mapDispatchToProps = (dispatch: any) => {
  return {
    onCreate: (data: any) => dispatch(actionCreators.fetch(CREATE_EQUIP_TRANSSCOTT, `/master/equip_transscott/add`, "POST", data, false, true)),
    onUpdate: (data: any) => dispatch(actionCreators.fetch(UPDATE_EQUIP_TRANSSCOTT, `/master/equip_transscott/modify`, "POST", data, false, true)),
    onSuccess: () =>
      dispatch(actionCreators.showMessage({
        type: "info",
        title: "スコット結線変圧更新",
        body: "スコット結線変圧を更新しました。",
      })),
    onError: () =>
      dispatch(actionCreators.showMessage({
        type: "error",
        title: "検索",
        body: "検索できません。",
      })),
    onCreateError: () =>
      dispatch(actionCreators.showMessage({
        type: "error",
        title: "スコット結線変圧登録",
        body: "スコット結線変圧を登録出来ませんでした。",
      })),
    onUpdateError: () =>
      dispatch(actionCreators.showMessage({
        type: "error",
        title: "スコット結線変圧編集",
        body: "スコット結線変圧を更新出来ませんでした。",
      })),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EquipTransscottEdit)