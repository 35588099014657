import React from 'react';

export const DefaultLoadingMessage: string = "読み込み中...";

const LoadingOverlay = (props: any) => {
  if (props.loadingMessage !== "")
    return (
      <div
        className="ag-overlay-loading-center"
        style={{ backgroundColor: 'lightsteelblue', height: '9%' }}
      >
        <i className="fas fa-hourglass-half"> {props.loadingMessage} </i>
      </div>
    );
  else return <div
    className="ag-overlay-loading-center"
    style={{ backgroundColor: '#ffffff', height: '9%' }}
  >
    <i className="far fa-frown">データがありません</i>
  </div>
};

export default LoadingOverlay;