import { LoadingButton } from "@mui/lab";
import { Box, Button, Checkbox, CircularProgress, FormControlLabel, Grid, Stack, Tab, Tabs, TextField, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BreakRatedModel } from "../../../models/breakModel";
import { Path as RoutePath } from "../../../path";
import { ApplicationState, actionCreators, selectors } from "../../../store";
import FetchHelper from "../../FetchHelper";
import AlertDialog from "../../common/AlertDialog";
import BreakElementGrid from "../BreakElement/BreakElementGrid";

//-----------
//FETCH ID
const GET_BREAK_RATED = "GET_BREAK_RATED"
const UPDATE_BREAK_RATED = "UPDATE_BREAK_RATED"
const DELETE_BREAK_RATED = "DELETE_BREAK_RATED"
const GET_BREAK_RATED_BY_ID = "GET_BREAK_RATED_BY_ID"
const CREATE_BREAK_RATED = "CREATE_BREAK_RATED"
const DELETE_BREAK_ELEMENT = "DELETE_BREAK_ELEMENT"

const initialValue: BreakRatedModel = {
    lBreakRatedID: 0,
    mv3breakBreakOpeKind: {
        lOpeKindID: 0,
        mv3breakBreakType: {
            lTypeID: 0,
            mv3breakManufacture: {
                lManufactureID: 0,
                bIsDefSource: false,
                bIsDefHVCB: false,
                bIsDefLVCB: false,
                bIsDefThermal: false,
                bIsDefFuse: false,
                bIsDef2E: false,
                sManuName: "",
                sManuKana: ""
            },
            mv3breakBreakGroup: {
                lGroupID: 0,
                nKindFlag: 0,
                sGroupName: "",
                bIsDefault: false,
                mv3breakManufacture: {
                    lManufactureID: 0,       
                    bIsDefSource: false,        
                    bIsDefHVCB: false,         
                    bIsDefLVCB: false,          
                    bIsDefThermal: false,          
                    bIsDefFuse: false,            
                    bIsDef2E: false,           
                    sManuName: '',           
                    sManuKana: ''
            
                }
            },
            bIsDefault: false,
            sTypeName: "",
            bCanUseCT: false,
            hidden: false,
            bCanUseCC: false,
            sOpeKindTitle: "",
            sSeriesName: "",
            sOldNew: "",
            sNewDate: "",
            sUpdateDate: "",
            sMaker: "",
            sNotes: ""
        },
        bIsDefault: false,
        sOpeKindName: "",
        nRatedFlag: 0,
        sRatedTitle: "",
        sRatedUnit: "",
        nMagniFlag: 0,
        sMagniTitle: "",
        sMagniUnit: "",
        bUseOpenTime: false,
        bUseBreakingTime: false
    },
    bIsDefault: false,
    sFixedName: "",
    dFixedValue: "",
    dContiDefValue: "",
    dContiMaxValue: "",
    dContiMiniValue: "",
    dContiWidthValue: "",
}

// -------------
//Tabs------------------
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `oilManagement-tab-${index}`,
        'aria-controls': `oilManagement-tabpanel-${index}`,
    };
}

// -------------
// Props
export type BreakRatedEditProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
export const BreakRatedEdit = (props: BreakRatedEditProps) => {
    const {
        userId,
        onUpdate, onUpdateError,
        onSuccess, onError,
        onCreateError,
        onDeleteElement, onSuccessDeleteElement, onErrorDeleteElement,
        onSearchById,
        onCreate,
    } = props;

    const { control, handleSubmit, setValue, getValues, formState: { errors } } = useForm<BreakRatedModel>({
        mode: "all",
        criteriaMode: "all",
        defaultValues: initialValue,
    })
    type MyParams = {
        id: string;
        mode: string;
    };

    let { id, mode } = useParams<keyof MyParams>() as MyParams;
    const location = useLocation();
    let lOpeKindID = location.state ? location.state.lBreakOpeKindID : undefined;
    let sOpeKindName = location.state ? location.state.sOpeKindName : "";
    const messageErrorNumber = "数字を入力してください";
    let navigate = useNavigate();
    const [selectTab, setSelectTab] = useState(0);
    const [loadingFlag, setLoadingFlag] = useState(mode === "edit" ? true : false);
    const gridRef = useRef() as any;
    const [colState, setColState] = useState<any>();
    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");
    const [openedDeleteDialog, setOpenedDeleteDialog] = useState<boolean>(false);
    const [mv3breakBreakElementList, setMv3breakBreakElementList] = useState<any>();
    const [idDelete, setIdDelete] = useState<string>("");
    let tab = location.state && location.state.tab;

    const handleSuccess = (data: any) => {
        console.log('>>>> handle Successs');
        setValue("bIsDefault", data.data.bisDefault);
        setValue("dContiDefValue", data.data.dcontiDefValue);
        setValue("dContiMaxValue", data.data.dcontiMaxValue);
        setValue("dContiMiniValue", data.data.dcontiMiniValue);
        setValue("dContiWidthValue", data.data.dcontiWidthValue);
        setValue("dFixedValue", data.data.dfixedValue);
        setValue("sFixedName", data.data.sfixedName);
        setValue("lBreakRatedID", data.data.lbreakRatedID);
        setValue("mv3breakBreakOpeKind.lOpeKindID", data.data.mv3breakBreakOpeKind.lopeKindID);
        setValue("mv3breakBreakOpeKind.sOpeKindName", data.data.mv3breakBreakOpeKind.sopeKindName);
        if (data.mv3breakBreakElementList.length > 0) {
            setMv3breakBreakElementList(data.mv3breakBreakElementList);
        }
        if (tab !== undefined && tab !== null) {
            setSelectTab(tab)
        }
        setLoadingFlag(false);
    }

    useEffect(() => {
        if (mode === "add")
            setValue("mv3breakBreakOpeKind.sOpeKindName", sOpeKindName);
    }, [])

    // 検索エラー時
    const handleError = (success: boolean, data: any, error: any) => {
        console.log('>>>> handle Error');
        gridRef.current.hideOverlay();
    }

    const tabHandleChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectTab(newValue);
        navigate(".", {
            replace: true,
            state: {
                tab: newValue,
                lBreakOpeKindID: lOpeKindID,
                sOpeKindName: sOpeKindName
            }
        });
    }

    // 検索実行
    const handleEditSubmit = (data: BreakRatedModel) => {
        setLoadingFlag(true);
        if (mode === "edit") {
            let request = {
                lBreakRatedID: getValues("lBreakRatedID"),
                bIsDefault: getValues("bIsDefault"),
                sFixedName: getValues("sFixedName"),
                dContiDefValue: getValues("dContiDefValue"),
                dContiMaxValue: getValues("dContiMaxValue"),
                dContiMiniValue: getValues("dContiMiniValue"),
                dContiWidthValue: getValues("dContiWidthValue"),
                dFixedValue: getValues("dFixedValue"),
            }

            const param = {
                "userId": userId,
                "requestPrms": request
              }
            onUpdate(id, param);
        } else if (mode === "add") {
            // let request = {
            //     lBreakRatedID: 0,
            //     bIsDefault: getValues("bIsDefault"),
            //     sFixedName: getValues("sFixedName"),
            //     dContiDefValue: getValues("dContiDefValue"),
            //     dContiMaxValue: getValues("dContiMaxValue"),
            //     dContiMiniValue: getValues("dContiMiniValue"),
            //     dContiWidthValue: getValues("dContiWidthValue"),
            //     dFixedValue: getValues("dFixedValue"),
            //     mv3breakBreakOpeKind: {
            //         lOpeKindID: lOpeKindID,
            //     } as BreakOpeKindModel
            // } as BreakRatedModel
            let request = {
                lBreakRatedID: 0,
                bIsDefault: getValues("bIsDefault"),
                sFixedName: getValues("sFixedName"),
                dContiDefValue: getValues("dContiDefValue"),
                dContiMaxValue: getValues("dContiMaxValue"),
                dContiMiniValue: getValues("dContiMiniValue"),
                dContiWidthValue: getValues("dContiWidthValue"),
                dFixedValue: getValues("dFixedValue"),
                lOpeKindID: lOpeKindID,
            } as any
            let object = {
                "userId": userId,
                "requestPrms": request
            }
            onCreate(object);
        }
    }

    // キャンセル
    const handleCancel = () => {
        if (location.key !== "default")
            navigate(-1);
        else
            navigate(RoutePath.BreakRatedList);
    }

    // Submit後のAPI呼び出し成功時
    const handleSuccessUpdate = (success: boolean, data: any, error: any) => {
        onSuccess();
        // if (lOpeKindID !== undefined && lOpeKindID !== null) {
        //     navigate(-1);
        // }
        if(mode==="edit"){
            navigate(RoutePath.BreakRatedEdit+`/edit/${data.data.lbreakRatedID}`,{replace:true})
        }
        setLoadingFlag(false)
    }
    // Submit後のAPI呼び出しエラー時
    const handleErrorUpdate = (success: boolean, data: any, error: any) => {
        onUpdateError();
        setLoadingFlag(false);
    }

    const handleSuccessAdd = (success: boolean, data: any, error: any) => {
        onSuccess();
        // if (lOpeKindID !== undefined && lOpeKindID !== null) {
        //     navigate(-1);
        // }
        if(mode==="add"){
            navigate(RoutePath.BreakRatedEdit+`/edit/${data.data.lbreakRatedID}`,{replace:true})
        }
        setLoadingFlag(false);
    }
    const handleErrorAdd = (success: boolean, data: any, error: any) => {
        onCreateError();
        setLoadingFlag(false);
    }

    //削除の処理
    const handleShowDetailPage = (data: any) => {
        navigate(RoutePath.BreakElementEdit + `/edit/${data}`,
            {
                state: {
                    lbreakRatedID: id,
                }
            })
    }

    //入力欄背景
    const divStyles = {
        backgroundColor: 'white',
        border: '1px solid #bebebe',
        boxShadow: '0 0 5px #fff, 0 0 5px #bebebe, 0 0 1px #aaa',
        padding: '20px 40px',
        marginTop: '40px',
    }
    //分析一覧を表示
    const handleAdd = () => {
        navigate(RoutePath.BreakElementEdit + `/add`,
            {
                state: {
                    lbreakRatedID: getValues("lBreakRatedID"),
                    sfixedName: getValues("sFixedName"),
                }
            })
    }

    //削除の処理
    const handleDelete = (data: any) => {
        setIdDelete(data);
        const msg = "ID: " + data + "を削除します。"
        setTitle("定格ダイアル情報削除");
        setMessage(msg);
        setOpenedDeleteDialog(true);
    }

    // 削除確認ダイアログでOKボタンのイベントを受け取って、APIに送信する
    const handleDeleteOK = () => {
        setOpenedDeleteDialog(false);
        onDeleteElement({
            "userId": userId,
            "requestPrms":{
                "id": idDelete
            }
        });
        setIdDelete("");
    }

    // 削除確認ダイアログでCancelボタンのイベントを受け取って、ダイアログを無効にする
    const handleDeleteCancel = () => {
        setIdDelete("");
        setOpenedDeleteDialog(false);
    }

    const handleSuccessDelete = (success: boolean, data: any, error: any) => {
        console.log(">>>> handledeleteSuccesss");
        onSuccessDeleteElement();
        onSearchById({
            "userId": userId,
            "requestPrms":{
              id
            }
        });
    }

    const handleErrorDelete = (success: boolean, data: any, error: any) => {
        onErrorDeleteElement();
    }

    //分析一覧を表示
    const resetGrid = () => {
        if (selectTab === 1) {
            gridRef.current.setRowData(mv3breakBreakElementList);
        }
    }
    useEffect(resetGrid, [selectTab]);

    const handleSuccessGet = (success: boolean, data: any, error: any) => {
        setMv3breakBreakElementList(data.mv3breakBreakElementList)
        gridRef.current.setRowData(data.mv3breakBreakElementList);
    }
    const handleErrorGet = (success: boolean, data: any, error: any) => {
        onError();
    }

    return (
        <>
            {
                mode === "edit" &&
                <FetchHelper
                    fetchId={GET_BREAK_RATED}
                    url={`/master/break_rated/get`}
                    method="POST"
                    request={true}
                    params={{
                        "userId": userId,
                        "requestPrms":{
                            "id": id
                        }
                      }}
                    json
                    onComplete={(success, data, error) => {
                        success ? handleSuccess(data) : handleError(success, data, error);
                    }}
                />
            }
            {loadingFlag && (
                <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1500, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                    <CircularProgress />
                </div>
            )}
            <div style={{ height: "100%", width: "100%", marginTop: "10px", }}>
                <Stack direction="row" justifyContent="flex-start" alignItems="center" sx={{}} style={{ margin: '18px', color: 'MenuText' }}>
                    <Typography variant="h4">
                        定格ダイアル詳細
                    </Typography>
                </Stack>
                <form onSubmit={handleSubmit(handleEditSubmit)}>
                    <Stack direction='row'>
                        <div style={{ top: "100px", position: "absolute", zIndex: 1200 }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                {
                                    mode === "edit"
                                        ?
                                        <Tabs value={selectTab} onChange={tabHandleChange} aria-label="oilManagementDetailTabs">
                                            <Tab label='定格ダイアル' {...a11yProps(0)} />
                                            <Tab label='要素' {...a11yProps(1)} />
                                        </Tabs>
                                        :
                                        <Tabs value={selectTab} onChange={tabHandleChange} aria-label="oilManagementDetailTabs">
                                            <Tab label='定格ダイアル' {...a11yProps(0)} />
                                        </Tabs>
                                }

                            </Box>
                        </div>
                    </Stack>
                    {/* 定格ダイアル */}
                    <TabPanel value={selectTab} index={0}>
                        <div style={divStyles}>
                            <Typography variant="h6">
                                定格ダイアル
                            </Typography>
                        <Stack sx={{ maxHeight:"calc(100vh)", overflow: 'auto' }}>
                            <Stack direction={{ xs: 'column', sm: 'row'}} mt={3}>
                                <Grid container xs={12} spacing={3} direction={'row'}>
                                    <Grid item xs={12} mt={1}>
                                        <Controller
                                            control={control}
                                            name="mv3breakBreakOpeKind.sOpeKindName"
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    type="text"
                                                    size="small"
                                                    label="動作種類名"
                                                    disabled={true}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Controller
                                            control={control}
                                            name="sFixedName"
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    type="text"
                                                    label="固定可変名称"
                                                    size="small"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            control={control}
                                            name="dFixedValue"
                                            rules={{
                                                pattern: {
                                                    value: /^-?\d+(?:\.\d+)?$/,
                                                    message: messageErrorNumber
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.dFixedValue}
                                                    helperText={errors?.dFixedValue?.message}
                                                    type="text"
                                                    size="small"
                                                    label="定格電流値"
                                                    
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            control={control}
                                            name="dContiDefValue"
                                            rules={{
                                                pattern: {
                                                    value: /^-?\d+(?:\.\d+)?$/,
                                                    message: messageErrorNumber
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.dContiDefValue}
                                                    helperText={errors?.dContiDefValue?.message}
                                                    type="text"
                                                    size="small"
                                                    label="連続可変デフォルト値"
                                                    
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            control={control}
                                            name="dContiWidthValue"
                                            rules={{
                                                pattern: {
                                                    value: /^-?\d+(?:\.\d+)?$/,
                                                    message: messageErrorNumber
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.dContiWidthValue}
                                                    helperText={errors?.dContiWidthValue?.message}
                                                    type="text"
                                                    label="連続可変可変幅"
                                                    size="small"
                                                    
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            control={control}
                                            name="dContiMiniValue"
                                            rules={{
                                                pattern: {
                                                    value: /^-?\d+(?:\.\d+)?$/,
                                                    message: messageErrorNumber
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.dContiMiniValue}
                                                    helperText={errors?.dContiMiniValue?.message}
                                                    type="text"
                                                    label="連続可変最小値"
                                                    size="small"
                                                    
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Stack>
                            <Stack direction={{ xs: 'column', sm: 'row' }} mt={3}>
                                <Grid container xs={12} spacing={3} direction={'row'}>
                                    <Grid item xs={6}>
                                        <Controller
                                            control={control}
                                            name="dContiMaxValue"
                                            rules={{
                                                pattern: {
                                                    value: /^-?\d+(?:\.\d+)?$/,
                                                    message: messageErrorNumber
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.dContiMaxValue}
                                                    helperText={errors?.dContiMaxValue?.message}
                                                    type="text"
                                                    label="連続可変最大値"
                                                    size="small"
                                                    
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Controller
                                            name="bIsDefault"
                                            control={control}
                                            render={({ field }: any) => (
                                                <FormControlLabel {...field} control={<Checkbox />} checked={field.value} label="デフォルト" labelPlacement="start" />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Stack>
                        </Stack>
                        </div>
                    </TabPanel>
                    {/* 定格ダイアル */}
                    <TabPanel value={selectTab} index={1}>
                        <div style={divStyles}>
                            <Stack direction="row" display='flex' justifyContent='space-between'>
                                <div><Typography variant="h6" >要素</Typography></div>
                                <div >
                                    <Stack direction='row' display='flex' spacing={2}>
                                        <Stack>
                                            <Button fullWidth color="inherit" variant="contained" onClick={() => handleAdd()}>要素の追加</Button>
                                        </Stack>
                                    </Stack>
                                </div>
                            </Stack>
                            <Stack spacing={2} sx={{ mt: 1 }} >
                                <BreakElementGrid
                                    ref={gridRef}
                                    colState={colState}
                                    onChangeSearchCondition={() => { }}
                                    keyword={""}
                                    onShowDetails={handleShowDetailPage}
                                    onDelete={handleDelete}
                                    heightForm="365"
                                    checkEdit={true}
                                    mode="edit"
                                />
                                {openedDeleteDialog ?
                                    <AlertDialog title={title} message={message} isOpen={true} onOK={handleDeleteOK} onCancel={handleDeleteCancel} /> :
                                    <div />
                                }
                            </Stack>
                        </div>
                    </TabPanel>
                    <Stack direction='row' justifyContent="flex-end" spacing={1} sx={{ mb: 0, mr: 3 }}>
                        <LoadingButton variant="contained" type="submit" loading={loadingFlag}>保存</LoadingButton>
                        <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                    </Stack>
                </form>
            </div>

            <FetchHelper fetchId={CREATE_BREAK_RATED}
                onComplete={(success, data, error) =>
                    success ? handleSuccessAdd(success, data, error) :
                        handleErrorAdd(success, data, error)} />
            <FetchHelper fetchId={UPDATE_BREAK_RATED}
                onComplete={(success: boolean, data: any, error: any) =>
                    success ? handleSuccessUpdate(success, data, error) : handleErrorUpdate(success, data, error)} />
            <FetchHelper fetchId={DELETE_BREAK_RATED}
                onComplete={(success: boolean, data: any, error: any) =>
                    success ? handleSuccessDelete(success, data, error) : handleErrorDelete(success, data, error)} />
            <FetchHelper fetchId={DELETE_BREAK_ELEMENT}
                onComplete={(success: boolean, data: any, error: any) =>
                    success ? handleSuccessDelete(success, data, error) : handleErrorDelete(success, data, error)} />
            <FetchHelper fetchId={GET_BREAK_RATED_BY_ID}
                onComplete={(success: boolean, data: any, error: any) =>
                    success ? handleSuccessGet(success, data, error) : handleErrorGet(success, data, error)} />
        </>
    );
}

const mapStateToProps = (state: ApplicationState) => (
    {
        userId: state.app.user?.userId,
        fetchUpdate: selectors.getFormState(
            state,
            GET_BREAK_RATED
        ),
    }
)

const mapDispatchToProps = (dispatch: any) => {
    return {
        onCreate: (params: any) => dispatch(actionCreators.fetch(CREATE_BREAK_RATED, `/master/break_rated/add`, "POST", params,false,true)),
        onUpdate: (id: string, param: any) => dispatch(actionCreators.fetch(UPDATE_BREAK_RATED, `/master/break_rated/modify`, "POST", param, false, true)),
        onSearchById: (params: any) => dispatch(actionCreators.fetch(GET_BREAK_RATED_BY_ID, `/master/break_rated/get`, "POST", params, false, true)),
        onDeleteElement: (params: any) => dispatch(actionCreators.fetch(DELETE_BREAK_ELEMENT, `/master/break_element/delete`, "POST", params, false, true)),
        onSuccess: () =>
            dispatch(actionCreators.showMessage({
                type: "info",
                title: "定格ダイアル更新",
                body: "定格ダイアルを更新しました。",
            })),
        onError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "検索",
                body: "検索できません。",
            })),
        onCreateError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "定格ダイアル登録",
                body: "定格ダイアルを登録出来ませんでした。",
            })),
        onUpdateError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "定格ダイアル編集",
                body: "定格ダイアルを更新出来ませんでした。",
            })),
        onSuccessDeleteElement: () =>
            dispatch(actionCreators.showMessage({
                type: "info",
                title: "定格ダイアル削除",
                body: "定格ダイアルを削除しました。",
            })),
        onErrorDeleteElement: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "定格ダイアル削除",
                body: "定格ダイアルを削除できませんでした。",
            })),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BreakRatedEdit as any);