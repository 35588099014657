import { CALC_IK2, CALC_IK2_OUTSIDE_NEUTRAL, CALC_IK2_OUTSIDE_OUTSIDE, CALC_IK2_SEAT_MAIN, CALC_IK2_SEAT_T, CALC_IK3, RS_DIAL_CONTINUE, RS_DIAL_FIXED, RS_DIAL_NONE, RS_KIND_FUSE, RS_KIND_HVCB } from "../../models/Constants";
import { getMsTrans3Label, getMsTrans3Label2 } from "./trans3/CreateTrans3Graph";
import { MS_2E, MS_3WINDING, MS_BUSBAR, MS_BUSDUCT, MS_CAPACITOR, MS_CONTACTOR, MS_DS, MS_GENERATOR, MS_IMPEDANCE, MS_LBS, MS_LVCB, MS_MOTOR, MS_MOTOR_GROUP, MS_MVCB, MS_REACTOR, MS_THERMAL, MS_TRANSCENTER, MS_TRANSSCOTT, MS_WIRE, MS_ZEROCOND } from "../../models/ElementKind";
import { MsTransScottModel, Ms3WindingModel, MsCapacitorModel, MsMotorModel, Ms2EModel, MsThermalModel, MsLVCBModel, CalcPoint } from "../../models/Index";
import { getMsTransscottInrushLabel, getMsTransscottIntensityLabel } from "./transScott/CreateTransScottGraph";
import { getMs3WindingIntensityLabel, getMs3WindingInrushLabel } from "./3winding/Create3WindingGraph";
import { getMsCapacitorInrushLabel } from "./capacitor/CreateCapacitorGraph";
import { getMsMotorLabel, getMsMotorLabel2 } from "./motor/CreateMotorGraph";
import { MS_FUSE, MS_HVCB, MS_SOURCE, MS_THERMAL_CT, MS_TRANS1, MS_TRANS3 } from "../../models/ElementKind";
import { AdjustElements, ControlModel, MsHVCBModel, MsSourceModel, MsTrans1Model, PartDetailsModel,MsTrans3Model, MsFUSEModel, MsThermalCTModel } from "../../models/Index";
import { getMsTrans1InrushLabel, getMsTrans1IntensityLabel } from "./trans1/createTrans1Graph";
import { OptionModel } from "../common/TextFieldOptions";
import { doGetIksymDirectMG } from "../../utils/calcPointFunction";
import { store } from "../..";
import { BeamsNumber } from "../../utils/FormatNumber";


class GraphLabel {

    static getControlGraphLabel(control : ControlModel, lineId : string | number = '') {
        let infos : string[] = []
        let nominalVolt = store.getState().app.diagram.chartData.find(x=>x.tabId === store.getState().app.diagram.currentIDChartTab)?.nominalVolt
        switch (control.type) {
            // ============== CALL DB ====================
            case MS_SOURCE: 
                switch(lineId) {
                    case "CALC_POINT_0":
                        this.getCalcPointLabel(control.properties?.calcPoint0 as CalcPoint, control.properties?.refNo, infos)
                        break;
                    case "CALC_POINT_1":
                        this.getCalcPointLabel(control.properties?.calcPoint1 as CalcPoint, control.properties?.refNo, infos)
                        break;
                    default:
                        this.getMsSourceLabel(control.properties as MsSourceModel, infos)
                        break;
                }
                break;
            case MS_HVCB:
                switch (lineId) {
                    case "CALC_POINT_0":
                        this.getCalcPointLabel(control.properties?.calcPoint0 as CalcPoint, control.properties?.refNo, infos)
                        break;
                    case "CALC_POINT_1":
                        this.getCalcPointLabel(control.properties?.calcPoint1 as CalcPoint, control.properties?.refNo, infos)
                        break;
                    default:
                        this.getMsHVCBLabel(control.properties as MsHVCBModel, infos)
                        break;
                }
                break;
            case MS_LVCB:
                switch(lineId) {
                    case "CALC_POINT_0":
                        this.getCalcPointLabel(control.properties?.calcPoint0 as CalcPoint, control.properties?.refNo, infos)
                        break;
                    case "CALC_POINT_1":
                        this.getCalcPointLabel(control.properties?.calcPoint1 as CalcPoint, control.properties?.refNo, infos)
                        break;
                    default:
                        this.getMsLVCBLabel(control.properties as MsLVCBModel, infos)
                        break;
                }
                break;
            case MS_FUSE:
                switch(lineId) {
                    case "CALC_POINT_0":
                        this.getCalcPointLabel(control.properties?.calcPoint0 as CalcPoint, control.properties?.refNo, infos)
                        break;
                    case "CALC_POINT_1":
                        this.getCalcPointLabel(control.properties?.calcPoint1 as CalcPoint, control.properties?.refNo, infos)
                        break;
                    default:
                        this.getMsFuseLabel(control.properties as MsFUSEModel, infos)
                        break;
                }
                break;
            case MS_THERMAL:
                switch(lineId) {
                    case "CALC_POINT_0":
                        this.getCalcPointLabel(control.properties?.calcPoint0 as CalcPoint, control.properties?.refNo, infos)
                        break;
                    case "CALC_POINT_1":
                        this.getCalcPointLabel(control.properties?.calcPoint1 as CalcPoint, control.properties?.refNo, infos)
                        break;
                    default:
                        this.getMsThermal(control.properties as MsThermalModel,infos);
                        break;
                }
                break;
            case MS_THERMAL_CT:
                switch(lineId) {
                    case "CALC_POINT_0":
                        this.getCalcPointLabel(control.properties?.calcPoint0 as CalcPoint, control.properties?.refNo, infos)
                        break;
                    case "CALC_POINT_1":
                        this.getCalcPointLabel(control.properties?.calcPoint1 as CalcPoint, control.properties?.refNo, infos)
                        break;
                    default:
                        this.getMsThermalCTLabel(control.properties as MsThermalCTModel, infos)
                        break;
                }
                break;
            case MS_2E:
                switch(lineId) {
                    case "CALC_POINT_0":
                        this.getCalcPointLabel(control.properties?.calcPoint0 as CalcPoint, control.properties?.refNo, infos)
                        break;
                    case "CALC_POINT_1":
                        this.getCalcPointLabel(control.properties?.calcPoint1 as CalcPoint, control.properties?.refNo, infos)
                        break;
                    default:
                        this.getMs2ELabel(control.properties as Ms2EModel,infos);
                        break;
                }
                break;

            // ============== NORMAL (NO CALL DB) ====================
            case MS_TRANS1:
                switch (lineId) {
                    case "INRUSH_OUT_IN":
                        getMsTrans1InrushLabel(control.properties as MsTrans1Model, infos, nominalVolt??210, 1) 
                        break;
                    case "INRUSH_OUT_OUT":
                        getMsTrans1InrushLabel(control.properties as MsTrans1Model, infos,  nominalVolt??210, 2) 
                        break;
                    case "INTENSITY_OUT_IN":
                        getMsTrans1IntensityLabel(control.properties as MsTrans1Model, infos,  nominalVolt??210, 1) 
                        break;
                    case "INTENSITY_OUT_OUT":
                        getMsTrans1IntensityLabel(control.properties as MsTrans1Model, infos,  nominalVolt??210, 2) 
                        break;
                    case "CALC_POINT_0":
                        this.getCalcPointLabel(control.properties?.partOutIn?.calcPoint as CalcPoint, control.properties?.refNo, infos)
                        break;
                    case "CALC_POINT_1":
                        this.getCalcPointLabel(control.properties?.partOutOut?.calcPoint as CalcPoint, control.properties?.refNo, infos)
                        break;
                }
                break;
            case MS_TRANS3:
                if(lineId === "INRUSH") {
                    getMsTrans3Label(control.properties as MsTrans3Model, infos, nominalVolt??400) //chen cung m_lNominalVolt = 400
                }
                if(lineId === "INTENSITY"){
                    getMsTrans3Label2(control.properties as MsTrans3Model, infos, nominalVolt??400)
                }
                if(lineId === "CALC_POINT_0"){
                    this.getCalcPointLabel(control.properties?.calcPoint0 as CalcPoint, control.properties?.refNo, infos)
                }
                if(lineId === "CALC_POINT_1"){
                    this.getCalcPointLabel(control.properties?.calcPoint1 as CalcPoint, control.properties?.refNo, infos)
                }
                break;
            case MS_TRANSSCOTT:
                switch(lineId) {
                    case "INRUSH_1":
                        getMsTransscottInrushLabel(control.properties as MsTransScottModel,infos, nominalVolt??210,1);//chen cung m_lNominalVolt = 210
                        break;
                    case "INRUSH_2":
                        getMsTransscottInrushLabel(control.properties as MsTransScottModel,infos, nominalVolt??210,2);//chen cung m_lNominalVolt = 210
                        break;
                    case "INTENSITY_1":
                        getMsTransscottIntensityLabel(control.properties as MsTransScottModel,infos, nominalVolt??210, 1);//chen cung m_lNominalVolt = 210
                        break;
                    case "INTENSITY_2":
                        getMsTransscottIntensityLabel(control.properties as MsTransScottModel,infos, nominalVolt??210, 2);//chen cung m_lNominalVolt = 210
                        break;
                    case "CALC_POINT_0":
                        this.getCalcPointLabel(control.properties?.partSeatM?.calcPoint as CalcPoint, control.properties?.refNo, infos)
                        break;
                    case "CALC_POINT_1":
                        this.getCalcPointLabel(control.properties?.partSeatT?.calcPoint1 as CalcPoint, control.properties?.refNo, infos)
                        break;
                }
                break;
            case MS_3WINDING:
                let sRefNo = '';
                switch(lineId) {
                    case "INRUSH_PART_2":
                        getMs3WindingInrushLabel(control.properties as Ms3WindingModel, infos, nominalVolt??11000, 2, 2);//chen cung 2, 2
                        break;
                    case "INRUSH_PART_3":
                        getMs3WindingInrushLabel(control.properties as Ms3WindingModel, infos, nominalVolt??11000, 3, 3);//chen cung 3, 3
                        break;
                    case "INTENSITY_PART_2":
                        getMs3WindingIntensityLabel(control.properties as Ms3WindingModel, infos, nominalVolt??11000, 2, 2);//chen cung 2, 2
                        break;
                    case "INTENSITY_PART_3":
                        getMs3WindingIntensityLabel(control.properties as Ms3WindingModel, infos, nominalVolt??11000, 3, 3);//chen cung 3, 3
                        break;
                    case "CALC_POINT_0_2":
                        if(control.properties?.partSecondary.fault) {
                            sRefNo = control.properties?.refNo + ' 二次側';
                            this.getCalcPointLabel(control.properties?.partSecondary?.calcPoint0 as CalcPoint, sRefNo, infos)
                        }
                        break;
                    case "CALC_POINT_0_3":
                        if(control.properties?.partThird.fault) {
                            sRefNo = control.properties?.refNo + ' 三次側';
                            this.getCalcPointLabel(control.properties?.partThird?.calcPoint0 as CalcPoint, sRefNo, infos)
                        }
                        break;
                    case "CALC_POINT_1_2":
                        if(control.properties?.partSecondary.fault) {
                            sRefNo = control.properties?.refNo + ' 二次側';
                            this.getCalcPointLabel(control.properties?.partSecondary?.calcPoint1 as CalcPoint, sRefNo, infos)
                        }
                        break;
                    case "CALC_POINT_1_3":
                        if(control.properties?.partThird.fault) {
                            sRefNo = control.properties?.refNo + ' 三次側';
                            this.getCalcPointLabel(control.properties?.partThird?.calcPoint1 as CalcPoint, sRefNo, infos)
                        }
                        break;
                }
                break;
            case MS_CAPACITOR:
                // capacitor has only one inrush
                getMsCapacitorInrushLabel(control.properties as MsCapacitorModel, infos, nominalVolt??400);
                break;
            case MS_MOTOR:
                if (lineId === "INRUSH") {
                    getMsMotorLabel(control.properties as MsMotorModel,false,infos, nominalVolt??400);//false: check top m_bSPhase
                }
                if (lineId === "INRUSH_2") {
                    getMsMotorLabel2(control.properties as MsMotorModel,false,infos, nominalVolt??400);//false: check top m_bSPhase
                }
                break;
            case MS_BUSBAR:
                switch(lineId) {
                    case "CALC_POINT_0":
                        this.getCalcPointLabel(control.properties?.calcPoint0 as CalcPoint, control.properties?.refNo, infos)
                        break;
                    case "CALC_POINT_1":
                        this.getCalcPointLabel(control.properties?.calcPoint1 as CalcPoint, control.properties?.refNo, infos)
                        break;
                }
                break;      
            case MS_BUSDUCT:
                switch(lineId) {
                    case "CALC_POINT_0":
                        this.getCalcPointLabel(control.properties?.calcPoint0 as CalcPoint, control.properties?.refNo, infos)
                        break;
                    case "CALC_POINT_1":
                        this.getCalcPointLabel(control.properties?.calcPoint1 as CalcPoint, control.properties?.refNo, infos)
                        break;
                }
                break;       
            case MS_CONTACTOR:
                switch(lineId) {
                    case "CALC_POINT_0":
                        this.getCalcPointLabel(control.properties?.calcPoint0 as CalcPoint, control.properties?.refNo, infos)
                        break;
                    case "CALC_POINT_1":
                        this.getCalcPointLabel(control.properties?.calcPoint1 as CalcPoint, control.properties?.refNo, infos)
                        break;
                }
                break;       
            case MS_DS:
                switch(lineId) {
                    case "CALC_POINT_0":
                        this.getCalcPointLabel(control.properties?.calcPoint0 as CalcPoint, control.properties?.refNo, infos)
                        break;
                    case "CALC_POINT_1":
                        this.getCalcPointLabel(control.properties?.calcPoint1 as CalcPoint, control.properties?.refNo, infos)
                        break;
                }
                break;   
            case MS_GENERATOR:
                switch(lineId) {
                    case "CALC_POINT_0":
                        this.getCalcPointLabel(control.properties?.calcPoint0 as CalcPoint, control.properties?.refNo, infos)
                        break;
                    case "CALC_POINT_1":
                        this.getCalcPointLabel(control.properties?.calcPoint1 as CalcPoint, control.properties?.refNo, infos)
                        break;
                }
                break;
            case MS_IMPEDANCE:
                switch(lineId) {
                    case "CALC_POINT_0":
                        this.getCalcPointLabel(control.properties?.calcPoint0 as CalcPoint, control.properties?.refNo, infos)
                        break;
                    case "CALC_POINT_1":
                        this.getCalcPointLabel(control.properties?.calcPoint1 as CalcPoint, control.properties?.refNo, infos)
                        break;
                }
                break; 
            case MS_LBS:
                switch(lineId) {
                    case "CALC_POINT_0":
                        this.getCalcPointLabel(control.properties?.calcPoint0 as CalcPoint, control.properties?.refNo, infos)
                        break;
                    case "CALC_POINT_1":
                        this.getCalcPointLabel(control.properties?.calcPoint1 as CalcPoint, control.properties?.refNo, infos)
                        break;
                }
                break;      
            case MS_MOTOR_GROUP:
                switch(lineId) {
                    case "CALC_POINT_0":
                        this.getCalcPointLabel(control.properties?.calcPoint0 as CalcPoint, control.properties?.refNo, infos)
                        break;
                    case "CALC_POINT_1":
                        this.getCalcPointLabel(control.properties?.calcPoint1 as CalcPoint, control.properties?.refNo, infos)
                        break;
                }
                break;   
            case MS_MVCB:
                switch(lineId) {
                    case "CALC_POINT_0":
                        this.getCalcPointLabel(control.properties?.calcPoint0 as CalcPoint, control.properties?.refNo, infos)
                        break;
                    case "CALC_POINT_1":
                        this.getCalcPointLabel(control.properties?.calcPoint1 as CalcPoint, control.properties?.refNo, infos)
                        break;
                }
                break;  
            case MS_REACTOR:
                switch(lineId) {
                    case "CALC_POINT_0":
                        this.getCalcPointLabel(control.properties?.calcPoint0 as CalcPoint, control.properties?.refNo, infos)
                        break;
                    case "CALC_POINT_1":
                        this.getCalcPointLabel(control.properties?.calcPoint1 as CalcPoint, control.properties?.refNo, infos)
                        break;
                }
                break;   
            case MS_TRANSCENTER:
                switch(lineId) {
                    case "CALC_POINT_0":
                        this.getCalcPointLabel(control.properties?.calcPoint0 as CalcPoint, control.properties?.refNo, infos)
                        break;
                    case "CALC_POINT_1":
                        this.getCalcPointLabel(control.properties?.calcPoint1 as CalcPoint, control.properties?.refNo, infos)
                        break;
                }
                break;  
            case MS_WIRE:
                switch(lineId) {
                    case "CALC_POINT_0":
                        this.getCalcPointLabel(control.properties?.calcPoint0 as CalcPoint, control.properties?.refNo, infos)
                        break;
                    case "CALC_POINT_1":
                        this.getCalcPointLabel(control.properties?.calcPoint1 as CalcPoint, control.properties?.refNo, infos)
                        break;
                }
                break;  
            case MS_ZEROCOND:
                switch(lineId) {
                    case "CALC_POINT_0":
                        this.getCalcPointLabel(control.properties?.calcPoint0 as CalcPoint, control.properties?.refNo, infos)
                        break;
                    case "CALC_POINT_1":
                        this.getCalcPointLabel(control.properties?.calcPoint1 as CalcPoint, control.properties?.refNo, infos)
                        break;
                }
                break;           
            default:
                break;
        }

        return infos
    }

    private static getMsSourceLabel(properties : MsSourceModel, infos : string[]) {
        infos.push(properties.refNo)
        infos.push(properties.pointText)

        const ctPrimary = properties.details.base.ctPrimaryList.find(item => item.value === properties.details.base.ctPrimary)?.label || ''
        const ctSecondary = properties.details.base.ctSecondaryList.find(item => item.value === properties.details.base.ctSecondary)?.label || ''
        const ctStr = `${ctPrimary}(A)/${ctSecondary}(A)`
        infos.push(ctStr)

        const typeName = properties.details.base.typeList.find(item => item.value === properties.details.base.typeID)?.label || ''
        infos.push(typeName)

        const charName = properties.details?.parts[0]?.charDialList.find(item => item.value ===  properties.details.parts[0].charDialID)?.label || '-'
        infos.push(charName)

        this.doGetTextAdjust(infos, properties as AdjustElements)
    }
    
    private static getMs2ELabel(properties : Ms2EModel, infos : string[]) {
            const refNo = properties.details.refNo2;
            const pointText = properties.pointText;
            const useCT = properties.details.base.useCT;
            const m_dCT1Current = properties.details.base.ctPrimary
            const m_dCT2Current = properties.details.base.ctSecondary
            infos.push(refNo);
            infos.push(pointText);
            if(useCT){
                infos.push(m_dCT1Current.toString() +`(A)/`+m_dCT2Current +`(A)`);

            }
            const typeName = properties.details.base.typeList.find(item => item.value === properties.details.base.typeID)?.label || ''
            infos.push(typeName);
            
            const ratedValue = properties.details.base.ratedValue
            if (!ratedValue) return

            infos.push(ratedValue.toString())
            if (properties.details.base.readBase.ratedUnit) {
                infos[infos.length - 1] +=  `(${properties.details.base.readBase.ratedUnit})`
            }
            else{
                infos[infos.length - 1] +=  `(A)`
            }

            if (properties.details.base.readBase.magniFlag === RS_DIAL_FIXED || properties.details.base.readBase.magniFlag === RS_DIAL_CONTINUE) {
                infos[infos.length - 1] +=  properties.details.base.magniValue
                if (properties.details.base.readBase.magniUnit) {
                    infos[infos.length - 1] += `(${properties.details.base.readBase.magniUnit})`
                }
            }

            this.doGetTextAdjust(infos, properties as AdjustElements)
    }

    private static getMsThermal(properties: MsThermalModel, infos : string[]) {
        const refNo = properties.details.refNo2;
        const pointText = properties.pointText;

        infos.push(refNo);
        infos.push(pointText);
        const typeName = properties.details.base.typeList.find(item => item.value === properties.details.base.typeID)?.label || ''
            infos.push(typeName);

        const ratedValue = properties.details.base.ratedValue
        if (!ratedValue) return

        infos.push(ratedValue.toString())
        if (properties.details.base.readBase.ratedUnit) {
            infos[infos.length - 1] +=  `(${properties.details.base.readBase.ratedUnit})`
        }
        else
        {
            infos[infos.length - 1] +=  `(A)`
        }

        if (properties.details.base.readBase.magniFlag === RS_DIAL_FIXED || properties.details.base.readBase.magniFlag === RS_DIAL_CONTINUE) {
            infos[infos.length - 1] +=  properties.details.base.magniValue
            if (properties.details.base.readBase.magniUnit) {
                infos[infos.length - 1] += `(${properties.details.base.readBase.magniUnit})`
            }
        }

        this.doGetTextAdjust(infos, properties as AdjustElements)
        properties.details.parts.forEach((item,index,character) =>{
            infos.push(character[index]?.charDialList[index].label)
        })
    }

    private static getMsHVCBLabel(properties : MsHVCBModel, infos : string[]) {
        infos.push(properties.details.refNo2)
        infos.push(properties.pointText)

        const ctPrimary = properties?.details.base?.ctPrimaryList?.find(item => item.value === properties?.details.base?.ctPrimary)?.label || ''
        const ctSecondary = properties?.details.base?.ctSecondaryList?.find(item => item.value === properties?.details.base?.ctSecondary)?.label || ''
        const ctStr = `${ctPrimary}(A)/${ctSecondary}(A)`        
        infos.push(ctStr)

        const typeName = properties.details.base.typeList.find(item => item.value === properties.details.base.typeID)?.label || ''
        infos.push(typeName)

        const charName = properties.details?.parts[0]?.charDialList.find(item => item.value ===  properties.details.parts[0].charDialID)?.label || '-'
        infos.push(charName)   

        this.doGetTextAdjust(infos, properties as AdjustElements)
    }

    private static getMsLVCBLabel(properties : MsLVCBModel, infos : string[])
        {
            infos.push(properties.refNo)
            infos.push(properties.pointText)

            const typeName = properties.details.base.typeList.find(item => item.value === properties.details.base.typeID)?.label || ''
            infos.push(typeName)

            const ratedValue = properties.details.base.ratedValue
            if (!ratedValue) return

            infos.push(ratedValue.toString())
            if (properties.details.base.readBase.ratedUnit) {
                infos[infos.length - 1] +=  `(${properties.details.base.readBase.ratedUnit})`
            }
            else //TODO: BECAUSE of properties reload readBase is null so this else fix temporary
            {
                infos[infos.length - 1] +=  `(A)`
            }

            if (properties.details.base.readBase.magniFlag === RS_DIAL_FIXED || properties.details.base.readBase.magniFlag === RS_DIAL_CONTINUE) {
                infos[infos.length - 1] +=  properties.details.base.magniValue
                if (properties.details.base.readBase.magniUnit) {
                    infos[infos.length - 1] += `(${properties.details.base.readBase.magniUnit})`
                }
            }

            this.doGetTextAdjust(infos, properties as AdjustElements)
        }
   
    private static getCalcPointLabel(calcPoint : CalcPoint, sRefNo:string, infos : string[]) {
        let dValue = doGetIksymDirectMG(calcPoint);
        let temp = store.getState().app.diagram.chartData.find(x => x.tabId === store.getState().app.diagram.currentIDChartTab);
        let dNominalVolt = temp?.nominalVolt ?? 0;
        if(sRefNo.includes(' ')){
            infos.push(sRefNo.split(' ')[0]);
            infos.push(sRefNo.split(' ')[1]);
        }
        else
        {
            infos.push(sRefNo);
        }
    
        switch(calcPoint.calcKind) {
            case CALC_IK3:
                infos.push('I"k3通過');
                break;
            case CALC_IK2:
                infos.push('I"k2通過');
                break;
            case CALC_IK2_OUTSIDE_NEUTRAL:
                infos.push('外線-中性線');
                infos.push('I"k2通過');
                break;
            case CALC_IK2_OUTSIDE_OUTSIDE:
                infos.push('外線-外線');
                infos.push('I"k2通過');
                break;
            case CALC_IK2_SEAT_MAIN:
                infos.push('主座');
                infos.push('I"k2通過');
                break;
            case CALC_IK2_SEAT_T:
                infos.push('T座');
                infos.push('I"k2通過');
                break;
        }
    
        if(calcPoint.voltage == dNominalVolt) {
            infos.push(BeamsNumber(dValue / 1000, 4).toString() + 'kAsym');
        }
        else {
            infos.push(BeamsNumber((dValue * calcPoint.voltage / dNominalVolt), 4).toString() + 'kAsym (' + BeamsNumber(dValue / 1000, 4).toString() + 'kAsym)');
        }
    }

    private static getMsFuseLabel(properties : MsFUSEModel, infos : string[]) {
        infos.push(properties.details.refNo2)
        infos.push(properties.pointText)

        const typeName = properties.details.base.typeList.find(item => item.value === properties.details.base.typeID)?.label || ''
        infos.push(typeName)

        if (properties.details.drawThreeCurve === false) {
            const charName = properties.details?.parts[0]?.charDialList.find(item => item.value ===  properties.details.parts[0].charDialID)?.label || ''
            infos.push(charName)
        }

        const ratedValue = properties.details.base.ratedList?.find(
            (item) => item.value === properties.details.base.ratedID
          )?.label || properties.details.base.fixedName || ""
        if (!ratedValue) return

        infos.push(ratedValue.toString())
        if (properties.details.base.readBase.ratedUnit) {
            infos[infos.length - 1] +=  `(${properties.details.base.readBase.ratedUnit})`
        }
        else
        {
            infos[infos.length - 1] +=  `(A)`
        }

        if (properties.details.base.readBase.magniFlag === RS_DIAL_FIXED || properties.details.base.readBase.magniFlag === RS_DIAL_CONTINUE) {
            infos[infos.length - 1] +=  properties.details.base.magniValue
            if (properties.details.base.readBase.magniUnit) {
                infos[infos.length - 1] += `(${properties.details.base.readBase.magniUnit})`
            }
        }

        this.doGetTextAdjust(infos, properties as AdjustElements)
    }

    private static getMsThermalCTLabel(properties : MsThermalCTModel, infos : string[]) {
        infos.push(properties.details.refNo2)
        infos.push(properties.pointText)

        const ctPrimary = properties.details.base.ctPrimaryList.find(item => item.value === properties.details.base.ctPrimary)?.label || ''
        const ctSecondary = properties.details.base.ctSecondaryList.find(item => item.value === properties.details.base.ctSecondary)?.label || ''
        const ctStr = `${ctPrimary}(A)/${ctSecondary}(A)`        
        infos.push(ctStr)

        const typeName = properties.details.base.typeList.find(item => item.value === properties.details.base.typeID)?.label || ''
        infos.push(typeName)

        const ratedValue = properties.details.base.ratedValue
        if (!ratedValue) return

        infos.push(ratedValue.toString())
        if (properties.details.base.readBase.ratedUnit) {
            infos[infos.length - 1] +=  `(${properties.details.base.readBase.ratedUnit})`
        }
        else
        {
            infos[infos.length - 1] +=  `(A)`
        }

        if (properties.details.base.readBase.magniFlag === RS_DIAL_FIXED || properties.details.base.readBase.magniFlag === RS_DIAL_CONTINUE) {
            infos[infos.length - 1] +=  properties.details.base.magniValue
            if (properties.details.base.readBase.magniUnit) {
                infos[infos.length - 1] += `(${properties.details.base.readBase.magniUnit})`
            }
        }

        this.doGetTextAdjust(infos, properties as AdjustElements)
    }
    private static doGetTextCurrent =(part : PartDetailsModel) =>{
        let result = 0
        if(part.currentList.length > 0){
            part.currentList.forEach((e:any)=>{
                if(e.value == part.currentValue)
                    result = Number(e.label)
            }
        )
        }
        return part.readPart && part.readPart.dispCurrentMulti ? part.currentValue / 100 : result
    }
    private static doGetTextAdjust = (infos : string[], props : AdjustElements) => {
        let nCount = 0;
        const infoBase = props.details.base

        props.details.parts.forEach((part : PartDetailsModel) => {
            if (infoBase.readBase?.kindFlag !== RS_KIND_FUSE) {
                infos.push(part.tabName)
            }
            if (part.setLock === true) {
                infos[infos.length - 1] += ` ロック(不動作)`
                return
            }
            switch(part.currentFlag) {
                case RS_DIAL_FIXED:
                case RS_DIAL_CONTINUE:
                    if (infoBase.readBase?.kindFlag === RS_KIND_HVCB && nCount === 0) {
                        if (part.timeFlag >= RS_DIAL_FIXED) {
                            infos[infos.length - 1] += (" T/L = ")
                        } else {
                            infos[infos.length - 1] += (" Tap = ")
                        }
                        nCount++
                    } else {
                        infos[infos.length - 1] += (" ")
                    }
                    const currentText = this.doGetTextCurrent(part) //TODO NEED TO CHECK
                    infos[infos.length - 1] += currentText
                    if (part.readPart?.currentUnit) {
                        infos[infos.length - 1] += `(${part.readPart?.currentUnit})`
                    }
                    switch(part.magniCurrentFlag) {
                        case RS_DIAL_FIXED:
                        case RS_DIAL_CONTINUE:
                            infos[infos.length - 1] += ` x ${part.magniCurrentValue}`
                            part.readPart?.magniCurrentUnit && (infos[infos.length - 1] += `(${part.readPart?.magniCurrentUnit})`)
                            break;
                    }
                    break;
                case RS_DIAL_NONE:
                    if (part.readPart?.currentNotes) {
                        infos[infos.length - 1] += ` ${part.readPart.currentNotes}`
                    	part.readPart.currentUnit && (infos[infos.length - 1] += `(${part.readPart.currentUnit})`)
                    }
                    break;
            }
            switch (part.timeFlag) {
                case RS_DIAL_FIXED:
                case RS_DIAL_CONTINUE:
                    if (part.currentFlag !== RS_DIAL_FIXED && part.currentFlag !== RS_DIAL_CONTINUE) {
                        infos[infos.length - 1] += " "
                    }else {
                        infos[infos.length - 1] += "/"
                    }
                    part.timeList && part.timeList.length > 0 && part.timeList.find(x=>x.value.toString() === part.timeValue.toString()) !== undefined ? infos[infos.length - 1] += part.timeList.find(x=>x.value.toString() === part.timeValue.toString())?.label : infos[infos.length - 1] += part.timeValue
                    part.readPart?.timeUnit && (infos[infos.length - 1] += `(${part.readPart?.timeUnit})`)
                    switch(part.magniTimeFlag) {
                        case RS_DIAL_FIXED:
                        case RS_DIAL_CONTINUE:
                            infos[infos.length - 1] += ` x ${part.magniTimeValue}`
                            part.readPart?.magniTimeUnit && (infos[infos.length - 1] += `(${part.readPart?.magniTimeUnit})`)
                            break
                    }
                    break;
                case RS_DIAL_NONE:
                    break;
            }
        })
    }
}
    
export default GraphLabel