import * as React from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { actionCreators, ApplicationState, selectors } from "../../store";
import { Box, Stack, Button, Typography, CircularProgress, InputAdornment, IconButton } from "@mui/material";
import useForm from "../../hooks/useForm";
import FetchHelper from "../FetchHelper";
import { CustomTextField } from "../styles";
import { LoadingButton } from "@mui/lab";
import { Auth } from 'aws-amplify';
import { Path as RoutePath } from "../../path";
import ToastContainer from "../common/ToastContainer";
import { Visibility, VisibilityOff } from "@mui/icons-material";
// import { CustomerRoleValue, SmartCustomerRoleValue } from "../../statics";

// FORM ID
const formId = "PASSWORD_EDIT_FORM";
const menusFetchId = 'MENUS_FETCH';

// フォーム定義
interface ChangePasswordForm {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
};

// 初期値設定
const initialValue: ChangePasswordForm = {
  currentPassword: "",
  newPassword: "",
  confirmPassword: ""
};

// 初期パスワード更新 Request
interface ChangePasswordRequest {
  currentPassword: string;
  newPassword: string;
};

export type ChangePasswordProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export const ChangePassword = (props: ChangePasswordProps) => {
  const { formState, isAuthorize, isNewPasswordRequired, loading, onUpdate, onCancel, onError, onSuccess } = props;
  const [isChangeNewPass, setIsChangeNewPass] = React.useState(false);
  const [loadingFlag, setLoadingFlag] = React.useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = React.useState<boolean>(false);
  const [showNewPassword, setShowNewPassword] = React.useState<boolean>(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = React.useState<boolean>(false);

  const handleShowCurrentPassword = () => {
    setShowCurrentPassword((show) => !show);
  };
  const handleShowNewPassPassword = () => {
    setShowNewPassword((show) => !show)
  }
  const handleShowCorfirmNewPassPassword = () => {
    setShowConfirmNewPassword((show) => !show)
  }
  
  let navigate = useNavigate();
  let dispatch = useDispatch();

  React.useEffect(() => {
    if (!isAuthorize && !isNewPasswordRequired) {
      navigate('/login');
    }
  },[])

  React.useEffect(() => {
    // if (isAuthorize && !isNewPasswordRequired && isChangeNewPass) {
    //     navigate("/projects")
    //     setLoadingFlag(true);
    //     return;
    // }
    if (!isAuthorize && !isNewPasswordRequired || isAuthorize && !isNewPasswordRequired && isChangeNewPass) {
        navigate('/login');
        return;
    }
  },[isNewPasswordRequired,isChangeNewPass,isAuthorize])

  React.useEffect(() => {
    if(loading)
      setLoadingFlag(true);
    else
      setLoadingFlag(false);
  },[loading])

  // Submitイベント
  const handleEditSubmit = async (value: ChangePasswordForm) => {
    // 更新後と確認用パスワードで一致しているか検証
    if (formState.value.newPassword !== formState.value.confirmPassword) {
      formState.errors.confirmPassword = "パスワードが一致しません。"
      form.setSubmitting(false);
      return;
    }

    const request = createRequestData(formState.value);

    if (isNewPasswordRequired) {
      setIsChangeNewPass(true);
      // new pass
      onUpdate(request);
    } else {
      setIsChangeNewPass(false);
      // change pass
      const response = await changePassword(formState.value.currentPassword, formState.value.newPassword)
      
      if (response === 'SUCCESS'){
        // logout
        onCancel();
        onSuccess();
        navigate('/login');
      } else {
        onError(response);
      }
    }
    form.setSubmitting(false);
  }

  const showPasswordIcon = (visible: boolean) => {
    if (visible) {
      return <Visibility />
    }
    return <VisibilityOff />;
  };

  // Form値変更時イベント
  const handleFormChanged = (name: string, value: string) => {
    console.log('>>> handleFormChanged');
  }

  // FORM 入力チェック
  const form = useForm({
    formId,
    initialValue,
    validator: (value : any) => {
      const errors: { [key in keyof ChangePasswordForm]?: string } = {};
      if (value.currentPassword === '') {
        errors.currentPassword = "必須項目です。入力してください。";
      }
      if (value.newPassword === '') {
        errors.newPassword = "必須項目です。入力してください。";
      }
      if (value.confirmPassword === '') {
        errors.confirmPassword = "必須項目です。入力してください。";
      }

      return errors;
    },
    onSubmit: handleEditSubmit,
    onChanged: handleFormChanged,
  });

  const changePassword = async (oldPassword: string, newPassword: string) => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const data = await Auth.changePassword(user, oldPassword, newPassword);
      return data;
    } catch(err) {
      return err;
    }
  };

  // FormデータからRequestデータを作成する
  const createRequestData = (formValue: ChangePasswordForm) => {
    let request = {
      currentPassword: formValue.currentPassword,
      newPassword: formValue.newPassword
    } as ChangePasswordRequest;

    return request;
  }

  //Enterキーを防ぐ
  const handleKeyDown = (event: React.KeyboardEvent) => {
    const target = event.target as any;
    if (event.key === 'Enter' && event.shiftKey === false && target.localName != "textarea") {
      event.preventDefault();
    }
  };

  const handleCancelClick = () => {
    if (isNewPasswordRequired)
    {
      onCancel();
      navigate('/login');
    }
    else
    {
      // Go back to the previous page
      navigate(-1)
    }
  };
  
  const handleError = (success: boolean, data: any, error: any) => {
    console.log('>>> handleError');
    setLoadingFlag(false);
  };

  return (
    <>
      {loadingFlag && (
        <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1500, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
          <CircularProgress />
        </div>
      )}
      <form onSubmit={form.handleSubmit} onKeyDown={e => { handleKeyDown(e) }}>
        <Stack justifyContent="center" alignItems="center" style={{ height: '66vh'}}>
          <Typography width="60%" variant="h4" mb={1}>パスワード変更</Typography>
          <div style={{ height: '60%', width: '60%' }}>
            <Stack mt="5px" mb="3px" width="100%" spacing={3} >
              <CustomTextField
                fullWidth
                type={showCurrentPassword ? 'text' : 'password'}
                label={isNewPasswordRequired || loadingFlag ?"初期パスワード":"現在のパスワード"}
                name="currentPassword"
                size="small"
                onBlur={form.handleBlur}
                onChange={form.handleChange}
                value={form.values.currentPassword === null || form.values.currentPassword === undefined ? "" : form.values.currentPassword}
                error={!!form.errors.currentPassword}
                helperText={form.errors.currentPassword}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowCurrentPassword} edge="end">
                        {showPasswordIcon(showCurrentPassword)}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <CustomTextField
                fullWidth
                label="新しいパスワード"
                name="newPassword"
                size="small"
                onBlur={form.handleBlur}
                onChange={form.handleChange}
                type={showNewPassword ? 'text' : 'password'}
                value={form.values.newPassword === null || form.values.newPassword === undefined ? "" : form.values.newPassword}
                error={!!form.errors.newPassword}
                helperText={form.errors.newPassword}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowNewPassPassword} edge="end">
                        {showPasswordIcon(showNewPassword)}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <CustomTextField
                fullWidth
                type={showConfirmNewPassword ? 'text' : 'password'}
                label="新しいパスワード（確認用）"
                name="confirmPassword"
                size="small"
                onBlur={form.handleBlur}
                onChange={form.handleChange}
                value={form.values.confirmPassword === null || form.values.confirmPassword === undefined ? "" : form.values.confirmPassword}
                error={!!form.errors.confirmPassword}
                helperText={form.errors.confirmPassword}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowCorfirmNewPassPassword} edge="end">
                        {showPasswordIcon(showConfirmNewPassword)}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Stack>
            
            <Typography mt={3}>※パスワードは8文字以上で設定してください。</Typography>
            <Typography>※パスワードには、半角英字の大文字・小文字と半角数字を含めてください。</Typography>
            { !loadingFlag &&
              <>
                <Typography mt={2}>パスワードを変更すると自動的にログアウトします。</Typography>
                <Typography>新しいパスワードで再度ログインしてください。</Typography>
              </>
            }
            <Box height="54px" />
            <Stack direction='row' justifyContent="flex-end" spacing={1} >
              <LoadingButton variant="contained" type="submit" loading={formState.submitting} sx={{ padding: '8px 54px' }}>更新</LoadingButton>
              <Button color="inherit" variant="contained" onClick={handleCancelClick}>キャンセル</Button>
            </Stack>
          </div>
        </Stack>
      </form >
      <ToastContainer />
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  formState: selectors.getFormState(
    state,
    formId
  ),
  isAuthorize: selectors.isAuthorize(state),
  isNewPasswordRequired: selectors.isNewPasswordRequired(state),
  loading:state.app.loading,
});

const mapDispatchToProps = {
  onUpdate: (data: ChangePasswordRequest) => actionCreators.changePassword(data.currentPassword, data.newPassword),
  onCancel: () => actionCreators.clearSession(),
  onError: (error: any) =>
    actionCreators.showMessage({
      type: "error",
      title: "パスワード変更",
      body: error.message,
    }
  ),
  onSuccess: () =>
  actionCreators.showMessage({
    type: "info",
    title: "パスワード変更",
    body: "パスワードを変更しました。",
  }),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePassword as any);
