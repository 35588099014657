import { RS_DIAL_CONTINUE, RS_DIAL_FIXED } from "../models/Constants";
import { AdjustModel, PartDetailsModel } from "../models/Index";
import { BeamsNumber } from "./FormatNumber";

export const isOkSetCurveInfo = (props:AdjustModel) =>
{
	if (props.base.manufacturerID <= 0) {
		return false;
	}
	if (props.base.kindID <= 0) {
		return false;
	}
    if (props.base.kindFlag <= 0) {
		return false;
	}
	if (props.base.typeID <= 0) {
		return false;
	}
	if (props.base.opeKindID <= 0) {
		return false;
	}
	if (props.base.ratedID <= 0) {
		return false;
	}
	if (props.base.ratedValue <= 0) {
		return false;
	}
	return true;
}

//CReadPart::DoGetTextCurrent
export const doGetTextCurrent = (props:AdjustModel, partIndex:number, bUnit:boolean, bParenthesis:boolean) =>
{
	let sCurrent = '';
	let part = props.parts[partIndex];
	let dCurrentValue = part.currentValue;
	switch(part.currentFlag) {
		case RS_DIAL_FIXED:
		{
			if (dCurrentValue == null) {
				break;
			}
			sCurrent += part.currentList.find((e: any) => e.value == dCurrentValue)?.label || '';
			if (bUnit == true && part.readPart?.currentUnit) {
				if (bParenthesis == true) {
					sCurrent += "(" + part.readPart?.currentUnit + ")";
				}
				else {
					sCurrent += part.readPart?.currentUnit;
				}
			}
		}
		break;
		case RS_DIAL_CONTINUE:
		{
			if (dCurrentValue == null) {
				break;
			}
			if (part.readPart?.dispCurrentMulti){
				let sValue = BeamsNumber(dCurrentValue / 100, 4).toString();
				sCurrent += sValue;
			} else {
				sCurrent += dCurrentValue.toString();
			}
			if (bUnit == true && part.readPart?.currentUnit) {
				if (bParenthesis == true) {
					sCurrent += "(" + part.readPart?.currentUnit + ")";
				}
				else {
					sCurrent += part.readPart?.currentUnit;
				}
			}
		}
		break;
	}
	return sCurrent;
}

export const doGetTextMagniCurrent = (part: PartDetailsModel, dMagniCurrentValue: any, bUnit = false, bParenthesis = false) => {
  let sMagniCurrent = "";

  switch (part.magniCurrentFlag) {
  case RS_DIAL_FIXED:
  case RS_DIAL_CONTINUE:
    if (dMagniCurrentValue == null || dMagniCurrentValue == "") {
      	break;
    }
    sMagniCurrent += part.magniCurrentList.find((e: any) => e.value == dMagniCurrentValue)?.label || '';
    if (bUnit == true && part.readPart?.currentUnit) {
		if (bParenthesis == true) {
			sMagniCurrent += "(" + part.readPart?.currentUnit + ")";
		}
		else {
			sMagniCurrent += part.readPart?.currentUnit;
		}
    }
    break;
  }
  return sMagniCurrent;
}

export const doGetTextTime = (part: any, dTimeValue: any, bUnit = false, bParenthesis = false) => {
	let sTime = "";
  
	switch (part.timeFlag) {
	case RS_DIAL_FIXED:
	case RS_DIAL_CONTINUE:
		if (dTimeValue == null || dTimeValue == "") {
			break;
		}
		sTime += part.timeList.find((e: any) => e.value == dTimeValue)?.label || '';
		if (bUnit == true && part.readPart.timeUnit) {
			if (bParenthesis == true) {
				sTime += "(" + part.readPart.timeUnit + ")";
			} else {
				sTime += part.readPart.timeUnit;
			}
		}
		break;
	}
	return sTime;
}

export const doGetTextMagniTime = (part: any, dMagniTimeValue: any, bUnit = false, bParenthesis = false) => {
    let sMagniTime = "";

    switch (part.magniTimeFlag) {
    case RS_DIAL_FIXED:
    case RS_DIAL_CONTINUE:
		if (dMagniTimeValue == null || dMagniTimeValue == "") {
            break;
        }
        sMagniTime += part.magniTimeList.find((e: any) => e.value == dMagniTimeValue)?.label || '';
        if (bUnit == true && part.readPart.magniTimeUnit) {
            if (bParenthesis == true) {
                sMagniTime += "(" + part.readPart.magniTimeUnit + ")";
            }
            else {
                sMagniTime += part.readPart.magniTimeUnit;
            }
        }
        break;
    }
    return sMagniTime;
}