import React, { forwardRef, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { EquipTrans1Model, EquipTrans3Model, EquipWirecModel } from '../../../models/breakModel';
import { PageData } from '../../../models/Index';
import PaginationCpn from '../../common/PaginationCpn';
import { AG_GRID_LOCALE_JP } from '../../../themes/localeJP';
import { AgGridReact } from 'ag-grid-react';
import { Grid, IconButton } from '@mui/material';
import NoRowsOverlay, { NoRowsMessage } from '../../common/NoRowsOverlay';
import LoadingOverlay, { DefaultLoadingMessage } from '../../common/LoadingOverlay';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

export type EquipTrans1GridProps = {
    colState: any[];
    pageSize?: number;
    pageData?: PageData;
    onChangeSearchCondition: (name: string, value: any) => void;
    keyword: string;
    onDelete: (data: EquipTrans1Model) => void;
    onShowDetails: (lTrans1Id: string) => void;
    heightForm?: string;
    checkEdit?: boolean;
    mode?:string;
}
const EquipTrans1Grid = forwardRef((props: EquipTrans1GridProps, ref) => {
    const {
        pageSize,
        pageData,
        heightForm,
        keyword,
        checkEdit,
        onChangeSearchCondition,
        onDelete,
        onShowDetails,
        mode
    } = props;
    const gridRef = useRef() as any;
    const [rowData, setRowData] = useState();
    useImperativeHandle(ref, () => ({
        setRowData(value: any) {
            setRowData(value)
        },
        hideOverlay() {
            gridRef.current.api.hideOverlay();
        }
    }));
    // ag-Grid の準備完了後に呼ばれるコールバック関数
    const onGridReady = (grid: any) => {
    };

    // Loading時のメッセージ
    const loadingOverlayComponent = useMemo(() => {
        return LoadingOverlay;
    }, []);
    const loadingOverlayComponentParams = useMemo(() => {
        return {
            loadingMessage: checkEdit ? "" : DefaultLoadingMessage,
        };
    }, []);

    const noRowsOverlayComponent = useMemo(() => {
        return NoRowsOverlay;
    }, []);

    const noRowsOverlayComponentParams = useMemo(() => {
        return {
            noRowsMessageFunc: () => NoRowsMessage,
        };
    }, []);

    // 編集ボタンのセル内表示とボタンイベント
    const cellMessageRenderer = (props: any) => {
        // 詳細
        const handleEdit = () => {
            onShowDetails(props.data.lTrans1Id);
        };

        const handleDelete = () => {
            onDelete(props.data.lTrans1Id);
        };

        return (
            <Grid container direction="row" justifyContent="flex-start" paddingLeft={"-60px"}>
                <IconButton aria-label="edit" onClick={handleEdit} >
                    <EditIcon fontSize="small" />
                </IconButton>
                <IconButton aria-label="delete" onClick={handleDelete} >
                    <DeleteIcon fontSize="small" />
                </IconButton>
            </Grid>
        );
    };
    // 全列に共通の定義
    const defaultColDef = {
        sortable: true, // ソート可
        editable: false, // 編集不可
        resizable: true,
        suppressMovable: true,  // 列の移動を無効にする
        headerClass: "ag-custom-header"
    };

    // 列タイプの定義
    const columnTypes = {
        nonEditableColumn: { editable: false }, // 編集不可
    };
    // 列の個別定義
    const columnDefs = [
        {
            headerName: "単相変圧器情報ID",
            field: "lTrans1Id",
            rowDrag: false,
            flex: 1.5,
            minWidth: 150
        },
        {
            headerName: "デフォルトの容量値",
            field: "bIsDefault",
            rowDrag: false,
            flex: 1.5,
            minWidth: 180
        },
        {
            headerName: "容量（kVA）",
            field: "dCapacity",
            rowDrag: false,
            flex: 1.5,
            minWidth: 150
        },        
        {
            headerName: "Damping time constant（cycles）(外線‐中性線)",
            field: "dDampingTimeOutIn",
            rowDrag: false,
            flex: 1.5,
            minWidth: 330
        },
        {
            headerName: "Damping time constant（cycles）(外線‐外線)",
            field: "dDampingTimeOutOut",
            rowDrag: false,
            flex: 1.5,
            minWidth: 320
        },
        {
            headerName: "Peak value of rush current（times）(外線‐中性線)",
            field: "dPeakValueOutIn",
            rowDrag: false,
            flex: 1.5,
            minWidth: 340
        },
        {
            headerName: "Peak value of rush current（times）(外線‐外線)",
            field: "dPeakValueOutOut",
            rowDrag: false,
            flex: 1.5,
            minWidth: 330
        },
        {
            headerName: "外線-中性線間の%R（%）",
            field: "dPerroutIn",
            rowDrag: false,
            flex: 1.5,
            minWidth: 200
        },
        {
            headerName: "外線間の%R（%）",
            field: "dPerroutOut",
            rowDrag: false,
            flex: 1.5,
            minWidth: 150
        },
        {
            headerName: "外線-中性線間の%X（%）",
            field: "dPerxoutIn",
            rowDrag: false,
            flex: 1.5,
            minWidth: 200
        },
        {
            headerName: "外線間の%X（%）",
            field: "dPerxoutOut",
            rowDrag: false,
            flex: 1.5,
            minWidth: 150
        },
        {
            headerName: "",
            field: "option",
            reseizable: false,
            suppressMovable: true,
            minWidth: 110,
            maxWidth: 110,
            cellRenderer: cellMessageRenderer,
        }
    ];

    const rowHeight = 48;
    gridRef.current?.api?.setQuickFilter(keyword);
    const handleEdit = (props: any) => {
        onShowDetails(props.data.lTrans1Id);
    }

    return (
        <>
            <div>
                <div className="ag-theme-alpine" style={{ height: heightForm ? 'calc(100vh - ' + heightForm + 'px)' : 'calc(100vh - 285px)', width: "100%" }}>
                    <AgGridReact
                        ref={gridRef}
                        defaultColDef={defaultColDef}
                        columnTypes={columnTypes}
                        columnDefs={columnDefs}
                        onGridReady={onGridReady}
                        domLayout={"normal"}
                        rowData={rowData}
                        animateRows={true}
                        rowDragManaged={true}
                        rowHeight={rowHeight}
                        rowSelection={'single'}
                        suppressMovableColumns={false}
                        suppressDragLeaveHidesColumns={false}
                        localeText={AG_GRID_LOCALE_JP}
                        paginationPageSize={pageSize}
                        onCellDoubleClicked={handleEdit}
                        loadingOverlayComponent={loadingOverlayComponent}
                        loadingOverlayComponentParams={loadingOverlayComponentParams}
                        noRowsOverlayComponent={noRowsOverlayComponent}
                        noRowsOverlayComponentParams={noRowsOverlayComponentParams}
                    />
                </div>
                {pageSize && pageData && <PaginationCpn pageData={pageData} onChangeSearchCondition={onChangeSearchCondition} limit={pageSize} />}
            </div>
        </>
    );
})

export default EquipTrans1Grid

