import { Button, Checkbox, Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, Paper, Radio, RadioGroup, Stack, TextField, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { ControlModel, Formula, OptionDataModel, Point, RemModel } from "../../models/Index";
import theme from "../../themes/globalStyles";
import TextFieldOptions from "../common/TextFieldOptions";
import RemFormDialog from "./RemFormDialog";
import UserFormulaDialog from "./UserFormulaDialog";
import { BeamsNumber } from "../../utils/FormatNumber";
import DraggablePaper from "../common/DraggablePaper";
import { doGetCircuitVolt } from "../../utils/element";
import { VOLT_SIDE_SECONDARY } from "../../statics";

export type AddFormDialogFormProps = {
    selectedRow: any,
    properties: any;
    modeViewOnly:boolean;
    onOK: (data: any) => void;
    onCancel: () => void;
    type:string
};

const MIN_OF_POINTS_ARRAY = 9

export const UserCurveDetailDialog = (props: AddFormDialogFormProps) => {

    //#region fields
    const { properties, selectedRow, onOK, onCancel,type, modeViewOnly } = props;
    let controlModel = {properties: properties,type:type} as ControlModel;
    let voltage = doGetCircuitVolt(controlModel,VOLT_SIDE_SECONDARY)
    // 初期値設定
    const initialValue: OptionDataModel = selectedRow ?? {
        userCurveId: -1,
        refNo: properties.refNo,
        name: "ユーザー曲線 - " + (properties.listUser.length + 1),
        noUser: 1,
        notes: "",
        freeOrCircuitVolt: 0,
        voltage: voltage,
        stdVoltage: voltage,
        calcCircuitVolt: false,
        pageIndex: 1,
        listPoint: [],
        formulaProperties: {
            formula: "Sample 10*((0.453/(i-1))+0.147)",
        currentMax: 30,
        currentMin: 1.01,
        checkTime: false,
        checkCurrent: false,
        timeMax: 0,
        timeMin: 0,
        currentInterval: 1,
        currentPartition: 0,
        listFormulaCalcPoints: []
        } as Formula,
        remProperties:{
            ratedPower : 4500,
            ratedVoltage : 3300,
            ratedCurrent : 930,
            startingCurrent : 6.3,
            startingTime : 10,
            puScale : 1.08, //(primary * replayInput / ratedCur * secondary) => fixed(3)
            stallTime : 20,
            weightingFactor : 50,
            priorLoadCurrent : 1,
            ctPrimary : 1000,
            ctSecondary : 5,
            input5Aor1A : 1,
            shortCircuitUse : true,
            shortCircuitCurrent : 3,
            shortOperateTime : 0.05,
            ambientTemperature : 40,
            timeConstant: 1,
            listDrawREM610: [],
            notes: ""
        } as RemModel
    }

    const fillArrayWithDefault = useCallback(
        (initArr : any[]) => {
            return initArr.length <= MIN_OF_POINTS_ARRAY ? [...initArr, ...[...Array(MIN_OF_POINTS_ARRAY - initArr.length)].map(_=>({}))] : [...initArr]
        }
    , [])

    const initPointsArr = [...fillArrayWithDefault(initialValue.listPoint),{}]

    const [selectedPointItem, setSelectedPointItem] = useState<any>({
        index: -1,
        axis: 'x'
    });
    const [openCalcForm, setOpenCalcForm] = useState<boolean>(false);
    const [openRemForm,setOpenRemForm] = useState<boolean>(false);    
    const [array, setArray] = useState<any>(initPointsArr);
    const [errorField, setErrorField] = useState<any[]>([]);
    const [checkedVoltType, setCheckedVoltType] = useState<boolean>(initialValue.freeOrCircuitVolt === 0 ? false : true);
    const { control, handleSubmit, formState: { errors }, setValue, getValues } = useForm<OptionDataModel>({
        mode: "all",
        criteriaMode: "all",
        defaultValues: initialValue,
    })

    // hanlde focus point when click delete
    useEffect(() => {
        if (selectedPointItem.index >= 0) {
            document.querySelector<HTMLInputElement>(`#${selectedPointItem.axis}-point-${selectedPointItem.index}`)?.focus()
        }
    }, [array])
    //#endregion fields

    //#region methods
    // Submitイベント
    const handleEditSubmit = (value: any, event: any) => {
        let arr:any[] = []
        array.map((item:any,index:any) => {
            if(Object.keys(item).length > 0 && (item.x == 0 && item.y != 0) || (item.x != 0 && item.y == 0))
            {
                arr.push(index)
            }
        })
        if(arr.length == 0)
        {
            const request = createRequestData(value);        
            onOK(request);
        }
        else
        {
            setErrorField(arr)
        }
    }

    const handleChangeFormat = (e: any) => {
        setValue(e.target.refNo, e.target.value);
    }

    const voltageOptions = useMemo(() => [
        {
            label: "3000",
            value: 3000,
        },
        {
            label: "3300",
            value: 3300,
        },
        {
            label: "6000",
            value: 6000,
        },
        {
            label: "6600",
            value: 6600,
        },
        {
            label: "10000",
            value: 10000,
        },
        {
            label: "11000",
            value: 11000,
        },
        {
            label: "20000",
            value: 20000,
        },
        {
            label: "22000",
            value: 22000,
        },
        {
            label: "33000",
            value: 33000,
        },
        {
            label: "35000",
            value: 35000,
        },
        {
            label: "66000",
            value: 66000,
        },
        {
            label: "77000",
            value: 77000,
        },
        {
            label: "110000",
            value: 110000,
        },
        {
            label: "154000",
            value: 154000,
        },
        {
            label: "187000",
            value: 187000,
        },
        {
            label: "220000",
            value: 220000,
        },
        {
            label: "275000",
            value: 275000,
        },
        {
            label: "500000",
            value: 500000,
        },
    ]
    , [])
    
    // FormデータからRequestデータを作成する
    const createRequestData = (formValue: any) => {   
        let request = {
            userCurveId: formValue.userCurveId,
            refNo: formValue.refNo,
            name: formValue.name,
            notes: formValue.notes,
            noUser: 1,
            pageIndex: 1,
            freeOrCircuitVolt: Number(formValue.freeOrCircuitVolt),
            voltage: formValue.voltage,
            stdVoltage: formValue.stdVoltage,
            calcCircuitVolt: formValue.calcCircuitVolt,
            listPoint: array.filter((item:any) => Object.keys(item).length > 0 && (item.x != 0 && item.y != 0)),
            remProperties: formValue.remProperties,
            formulaProperties: formValue.formulaProperties,
        } as any;
        return request;
    }
    // キャンセル
    const handleCancel = () => {
        onCancel();
    };

    const handleOnChangeRadio = (e: any) => {
        if (e.target.value === "1") {
            setCheckedVoltType(true);
        } else if (e.target.value === "0") {
            setCheckedVoltType(false);
        }
    }

    const checkDisable = (index: any) => {
        return index !== 0 && (array[index - 1].x === undefined || array[index - 1].y === undefined) && (array[index].y === undefined || array[index].y === undefined) ;
    }

    const handleDeleteItem = () => {
        const index = selectedPointItem.index;
        if(index >= 0){
            array.splice(index,1);
            if(array.length < MIN_OF_POINTS_ARRAY){
                array.push({});
            }
            setArray([...array])
        }
    }
    
    const handleAddItem = () => {
        const index = selectedPointItem.index;
        if(index >= 0)
        {
            array.splice(index, 0, {});
            setArray([...array])
        }
        else
        {
            array.push({});
            setArray([...array])
        }
    }

    const getBeamsNumberAllPoints = (list : Point[]) => {
        return list.map((point : Point) => ({x: BeamsNumber(point.x, 15), y: BeamsNumber(point.y, 15)}))
    }

    const handleEditRem = (data:any) =>{
        const listBeam = getBeamsNumberAllPoints(data.listDrawREM610)
        setArray([...fillArrayWithDefault(listBeam),{}])
        setValue("notes", data.notes)
        setValue("remProperties", data)
        setOpenRemForm(false)
    }
    
    const handleEditFormula = (data:any) =>{   
        const listBeam = getBeamsNumberAllPoints(data.listFormulaCalcPoints)     
        setArray([...fillArrayWithDefault(listBeam),{}])
        delete data.listFormulaCalcPoints
        setValue('formulaProperties', data)
        setOpenCalcForm(false)
    }

    const renderItems = () => {
        return array.map((item: any, index: any) => (
            <div key={index} style={{ marginBottom: "10px" }}>
                <Grid container spacing={1} direction={"row"}>
                    <Grid item xs={1}>
                        <Typography mt={0.5}> {index + 1}:</Typography>
                    </Grid>
                    <Grid item xs={5.3}>
                        <TextField
                            id={`x-point-${index}`}
                            error={errorField.includes(index) && item.x == 0 ? true : false}
                            size="small"
                            value={item.x ? item.x : ""}
                            disabled={checkDisable(index) || modeViewOnly}
                            style={{
                                backgroundColor: (checkDisable(index)) ? "floralwhite" : "white"
                            }}
                            onChange={(e: any) => {
                                // 正規表現で数値と小数点のみを許可
                                const validCharacters = /^[0-9]*\.?[0-9]*$/;
                                if (e.target.value === '' || validCharacters.test(e.target.value)) {
                                    item.x = e.target.value;
                                    if(item.y && item.y != '' && index + 1 == array.length)
                                    {
                                        array.push({})
                                    }
                                    setArray([...array])
                                }
                            }}
                            onFocus={() => {
                                setSelectedPointItem({index, axis: 'x'});
                            }}
                            onBlur={(e:any) => {
                                //item.x = Number(e.target.value);
                                //setArray([...array])
                                if(e.relatedTarget){
                                    if(e.relatedTarget.id !== "deleteButton" && e.relatedTarget.id !== "addButton"){
                                        setSelectedPointItem({index: -1, axis: 'x'});
                                    }
                                }
                            }}
                            InputProps={{
                                sx:{height:32},
                            }}
                        />
                    </Grid>
                    <Grid item xs={5.3}>
                        <TextField
                            id={`y-point-${index}`}
                            error={errorField.includes(index) && item.y == 0 ? true : false}
                            size="small"
                            value={item.y ? item.y : ""}
                            disabled={checkDisable(index) || modeViewOnly}
                            style={{
                                backgroundColor: (checkDisable(index)) ? "floralwhite" : "white"
                            }}
                            onChange={(e: any) => {
                                // 正規表現で数値と小数点のみを許可
                                const validCharacters = /^[0-9]*\.?[0-9]*$/;
                                if (e.target.value === '' || validCharacters.test(e.target.value)) {
                                    item.y = e.target.value;
                                    if(item.x && item.x != '' && index + 1 == array.length)
                                    {
                                        array.push({})
                                    }
                                    setArray([...array])
                                }
                            }}
                            onFocus={() => {
                                setSelectedPointItem({index, axis: 'y'});
                            }}  
                            onBlur={(e:any) => {
                                //item.y = Number(e.target.value);
                                //setArray([...array])
                                if(e.relatedTarget){
                                    if(e.relatedTarget.id !== "deleteButton" && e.relatedTarget.id !== "addButton"){
                                        setSelectedPointItem({index: -1, axis: 'x'});
                                    }
                                }
                            }}
                            InputProps={{
                                sx:{height:32},
                            }}
                        />
                    </Grid>
                </Grid>
            </div>
        ));
    }
    //#endregion methods

    return (
        <>
            <Dialog open={true} fullWidth
                PaperComponent={
                    DraggablePaper
                }
            >
                <DialogTitle 
                    onMouseOver={(e : any) => e.target.style.cursor = 'move'}
                style={{ paddingTop: '2px', paddingBottom: '2px', background: theme.palette.primary.main, color: theme.palette.primary.contrastText }}>ユーザー曲線 [{properties.refNo}]</DialogTitle>
                <DialogContent sx={{ pb: 1.5 }}>
                    <div style={{ height: '100%', width: '100%', margin: "auto" }}>
                        <form onSubmit={handleSubmit(handleEditSubmit)} style={{ width: '100%'}}>
                            <div style={{height:"50vh",overflowY:"auto"}}>
                                <Grid container spacing={1} direction="row" mt={1}>
                                    <Grid item xs={5} mt={1}>
                                        <Stack spacing={0.3}>
                                            <Stack flexDirection='row' alignItems='center'>
                                                <Typography variant="body2" width={"70%"}>デバイス名: </Typography>
                                                <Controller
                                                    name="refNo"
                                                    control={control}
                                                    rules={{
                                                        required: '必須項目です。入力してください。',
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type="text"
                                                            size="small"
                                                            error={!!errors?.refNo}
                                                            helperText={errors?.refNo?.message}
                                                            onBlur={handleChangeFormat}
                                                            onFocus={e => e.target.select()}
                                                            inputProps={{readOnly: modeViewOnly}}
                                                            InputLabelProps={{
                                                                style: { color:'black' },
                                                            }}
                                                            InputProps={{
                                                                sx:{height:32,color: modeViewOnly? 'grey':'black', background:modeViewOnly?'floralwhite':'white'}
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack flexDirection='row' alignItems='center'>
                                                <Typography variant="body2" width={"70%"}>曲線名称: </Typography>
                                                <Controller
                                                    name="name"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type="text"
                                                            size="small"
                                                            onBlur={handleChangeFormat}
                                                            onFocus={e => e.target.select()}
                                                            inputProps={{readOnly: modeViewOnly}}
                                                            InputLabelProps={{
                                                                style: { color: 'black' },
                                                                shrink: true,
                                                            }}
                                                            InputProps={{
                                                                sx:{height:32,color: modeViewOnly? 'grey':'black', background:modeViewOnly?'floralwhite':'white'}
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack flexDirection='row' alignItems='center'>
                                                <Typography variant="body2" width={"65%"}>備考:</Typography>
                                                <Controller
                                                    name="notes"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            onBlur={handleChangeFormat}
                                                            onFocus={e => e.target.select()}
                                                            multiline={true}
                                                            minRows={3}
                                                            InputLabelProps={{
                                                                style: { color: 'black' },
                                                                shrink: true,
                                                            }}
                                                            inputProps={{readOnly: modeViewOnly}}
                                                            InputProps={{
                                                                sx:{color: modeViewOnly? 'grey':'black', background:modeViewOnly?'floralwhite':'white'}
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <fieldset style={{ border: "1px solid #AAAAAA", padding:"4px 4px 4px 4px", borderRadius: "5px" }}>
                                                <legend >電流値の基準となる電圧</legend>
                                                <Stack flexDirection='row' alignItems='center'>
                                                    <Controller
                                                        name="freeOrCircuitVolt"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <RadioGroup
                                                                {...field}
                                                                onChange={(e) => {
                                                                    field.onChange(e)
                                                                    handleOnChangeRadio(e)
                                                                }}
                                                            >
                                                                <Stack spacing={0.3}>
                                                                    <Stack>
                                                                        <FormControlLabel disabled={modeViewOnly} style={{marginLeft: 0}} value={0} control={<Radio style={{height:"20px",padding:"0 0 0 0"}} />} label={<Typography fontSize={"14px"}>回路電圧を基準とする</Typography>} labelPlacement={"end"} />
                                                                    </Stack>
                                                                    {/* 回路電圧(v) */}
                                                                    <Stack flexDirection='row' alignItems='center'>
                                                                        <Typography variant="body2" width={"80%"}>回路電圧(V):</Typography>
                                                                        <Controller
                                                                            name="voltage"
                                                                            control={control}
                                                                            render={({ field }) => (
                                                                                <TextField
                                                                                    {...field}
                                                                                    onBlur={handleChangeFormat}
                                                                                    onFocus={e => e.target.select()}
                                                                                    label=""
                                                                                    fullWidth
                                                                                    size="small"
                                                                                    InputLabelProps={{
                                                                                        style: { color: 'black' },
                                                                                        shrink: true,
                                                                                    }}
                                                                                    disabled={true}
                                                                                    style={{
                                                                                        backgroundColor: "floralwhite"
                                                                                    }}
                                                                                    InputProps={{
                                                                                        sx:{height:32},
                                                                                    }}
                                                                                />
                                                                            )}
                                                                        />
                                                                    </Stack>
                                                                    <Stack>
                                                                        <FormControlLabel disabled={modeViewOnly} style={{marginLeft: 0}} value={1} control={<Radio style={{height:"20px",padding:"0 0 0 0"}} />} label={<Typography fontSize={"14px"}>任意の電圧を基準とする</Typography>} labelPlacement={"end"} />
                                                                    </Stack>
                                                                    <Stack flexDirection='row' alignItems='center'>
                                                                        <Typography variant="body2" width={"80%"}>基準電圧(V):</Typography>
                                                                        <FormControl fullWidth size="small">
                                                                            <Controller
                                                                                name="stdVoltage"
                                                                                control={control}
                                                                                render={({ field }) => (
                                                                                    <TextFieldOptions
                                                                                        {...field}
                                                                                        options={properties.voltageList??voltageOptions}
                                                                                        size="small"
                                                                                        disabled={!checkedVoltType || modeViewOnly}
                                                                                        setValue={(refNo: any, value: any) => {
                                                                                            setValue(refNo, value);
                                                                                        }}
                                                                                        height={32}
                                                                                    />
                                                                                )}
                                                                            />
                                                                        </FormControl>
                                                                    </Stack>
                                                                    <Stack>
                                                                        <Controller
                                                                            name="calcCircuitVolt"
                                                                            control={control}
                                                                            render={({ field }: any) => (
                                                                                <FormControlLabel
                                                                                    {...field}
                                                                                    disabled={!checkedVoltType || modeViewOnly}
                                                                                    style={{
                                                                                        backgroundColor: !checkedVoltType ? "floralwhite" : "white",
                                                                                        margin: 'auto 0'
                                                                                    }}
                                                                                    control={<Checkbox style={{height:"20px",padding:"0 0 0 0"}} />} 
                                                                                    checked={field.value}
                                                                                    label={<Typography variant="body2">電流値の公称換算に回路電圧も考慮する</Typography>}
                                                                                    onClick={handleChangeFormat}
                                                                                />
                                                                            )}
                                                                        />
                                                                    </Stack>
                                                                </Stack>
                                                            </RadioGroup>
                                                        )}
                                                    />

                                                </Stack>
                                                
                                            </fieldset>
                                        </Stack>


                                    </Grid>
                                    <Grid item xs={7}>
                                        <fieldset style={{ borderRadius: "5px", }}>
                                            <legend style={{fontSize:"14px"}}>座標(最大100ポイン卜)</legend>
                                            <Stack>
                                                <Grid container spacing={2} direction={"row"}>
                                                    <Grid item xs={1}>
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <Typography textAlign={"center"}>電流(A)</Typography>
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <Typography textAlign={"center"}>時問(s)</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Stack>
                                            <Stack style={{ maxHeight: "500px", overflowY: "auto" }}>
                                                {renderItems()}
                                            </Stack>
                                            <Stack direction={"row"} justifyContent={"flex-end"} spacing={2} mb={1} mt={1} >
                                                <Button 
                                                variant="contained" 
                                                onClick={handleAddItem} 
                                                fullWidth
                                                disabled={modeViewOnly}
                                                size="small"
                                                id="addButton"
                                                >
                                                    カーソル位置に座標を挿入
                                                </Button>
                                                <Button 
                                                variant="contained" 
                                                onClick={handleDeleteItem} 
                                                onFocus={e => e.currentTarget}
                                                fullWidth
                                                id="deleteButton"
                                                disabled={!(selectedPointItem.index >= 0 && selectedPointItem.index < array.length) || modeViewOnly}
                                                >
                                                    カーソル位置の座標を削除
                                                </Button>
                                            </Stack>
                                            <Stack direction={"row"} justifyContent={"flex-start"} spacing={2} >
                                                <Stack direction={"row"} spacing={2} flex={1}>
                                                    <Button variant="contained" size="small" onClick={() => {
                                                        setOpenCalcForm(true)
                                                    }} sx={{flex: 1}}>計算式入力</Button>
                                                    <Button size="small" variant="contained" onClick={() => {
                                                        setOpenRemForm(true);
                                                    }} sx={{flex: 1}}>REM610</Button>
                                                </Stack>
                                                <div style={{flex: 1}}>
                                                    <Button size="small" variant="contained" disabled={modeViewOnly} onClick={() => {
                                                        setArray(Array(MIN_OF_POINTS_ARRAY).fill(undefined).map(_=>({})))
                                                        setValue("notes", "")
                                                    }} fullWidth>ユーザ曲線座標に変換</Button>
                                                </div>
                                            </Stack>
                                        </fieldset>
                                    </Grid>
                                </Grid>
                            </div>
                            <Stack direction='row' justifyContent="flex-end" spacing={2} mt={2}>
                                <Button variant="contained" type="submit" disabled={modeViewOnly}>OK</Button>
                                <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                            </Stack>
                        </form>
                    </div>
                </DialogContent>
            </Dialog>
            {   
                openRemForm &&
                <RemFormDialog data={getValues("remProperties")} modeViewOnly={modeViewOnly} onOK={handleEditRem} onCancel={() => {setOpenRemForm(false)}}  />
            }
            {
                openCalcForm &&
                <UserFormulaDialog data={getValues("formulaProperties")} modeViewOnly={modeViewOnly} onOK={handleEditFormula} onCancel={() => {setOpenCalcForm(false)}}  />
            }
        </>
    )
}
export default UserCurveDetailDialog;