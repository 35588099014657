import Konva from 'konva';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { Text, Transformer } from 'react-konva';
import { Html } from 'react-konva-utils';
import { connect } from 'react-redux';
import { Coordinates, MsTextModel, ProcessMode, UndoModel } from '../../models/Index';
import { updateSelectControl, updatePropertiesOfControlAction, undo, openDialogEvents, updateControlWidth, updateControlHeight, clearOptionMenu, setRepositionElement, updateElementPosition } from '../../store/Actions';
import OptionMenu from '../contextMenu/OptionMenu';
import { TextEditorDialog } from '../dialogs/TextEditorDialog';
import { getFontStyleValue, getTextDecoration } from '../../utils/formatText';
import { HEIGHT_OF_OPTION_MENU } from '../../models/Constants';
import { convertUndefinedToNull } from '../../utils/DataConverter';
import { getElementKindValue } from '../../utils/ElementFunction';
import { post } from '../CallApi';
import { CircularProgress, Dialog } from '@mui/material';
import { calcContextMenuPosition, getMenuOptionHeight } from '../../utils/PositionCalculation';
import { getTextWidth } from '../../utils/TextUtil';

//#region Props
type CreateTextProps = {
    id: string,
    x: number,
    y: number,
    type: string,
    isSelected: boolean,
    isHidden: boolean;
    parentGroupId: number | null;
    lineDiagramRef: any,
    properties: MsTextModel,
    gridSizeController: {
        scale: number;
        stageOffsetX: number;
        stageOffsetY: number;
  
    };
    isOnShiftKey: boolean;
};

export type PureCreateTextProps = CreateTextProps & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
//#endregion

const CreateText = memo((props: PureCreateTextProps) => {
    //#region Fields
    const { id, x, y, properties, type, isSelected, isHidden, parentGroupId, lineDiagramRef, processMode, m_bModePM, modeViewOnly} = props;
    const {
        userId,
        gridSizeController,
        projectData,
        openDialogData,
        currentTabId,
        clearMenuState,
        isSelectTouchGroup,
        isOnShiftKey,
        diagramData,
        elementGroups,
        tcGroups,
        elementReposition,
        updateElementPosition,
        setRepositionElement,
        updateSelectControl,
        updatePropertiesOfControlAction,
        setUndoData,
        openDialogEvents,
        updateControlWidth,
        updateControlHeight,
        clearOptionMenu,
    } = props;

    const transformRef = useRef<Konva.Transformer>(null);
    const shapeRef = useRef<any>();
    const [isOpenMenu, setIsOpenMenu] = useState(false);
    const [menuPosition, setMenuPosition] = useState<Coordinates>({ x: 0, y: 0 });
    const [isOpenEditorDialog, setIsOpenEditorDialog] = useState(false);
    const [initialValue,setInitialValue] = useState({...properties});
    const [loadingFlag, setLoadingFlag] = useState(false);
    const [isOnOpenMenu, setIsOnOpenMenu] = useState<boolean>(false);
    const [dragStartPosition, setDragStartPosition] = useState({ x: x, y: y});;
    //#endregion

    //#region useEffect
    useEffect(()=>{
        if(isSelected){
          if(isOnOpenMenu) {
            setIsOnOpenMenu(false)
          }
          else
          {
            setIsOpenMenu(false)
          }
        }
      },[clearMenuState])

    useEffect(()=>{
    if(elementReposition?.id)
    {
        const groupsData = currentTabId === 1 ? elementGroups : tcGroups[diagramData.find((x:any)=>x.id == id)?.parentTcId ?? 0]
        const peekGroupId = groupsData?.byEleId[elementReposition.id as any]?.at(-1) ?? -1
        const elementIds = groupsData?.byId[peekGroupId]?.elementIds ?? []
        if(elementIds.includes(id) || diagramData[0].shape.find((item:any)=>(item.isSelected && item.id == id)))
        {
            shapeRef.current.to({
                x: dragStartPosition.x,
                y: dragStartPosition.y,
            });

            updateElementPosition(id, dragStartPosition.x, dragStartPosition.y)
        }
    }
    },[elementReposition])

    useEffect(() => {
        if (isSelected && !parentGroupId) {
            transformRef.current?.nodes([shapeRef.current]);
        }
        else {
            transformRef.current?.nodes([]);
        }
        transformRef.current?.getLayer()?.batchDraw();
    }, [isSelected, parentGroupId]);

    useEffect(() => {
        if (!isSelected)
            setIsOpenMenu(false)
    }, [isSelected]);

    useEffect(() => {
        setInitialValue(properties);
    },[properties])

    useEffect(() => {
        if(openDialogData?.id === id && openDialogData !== undefined) {
            openDialogEvents(({type: "OPEN_DIALOG", data: { }}))
            handleOpenEditorDialog();
        }
    }, [openDialogData]);
    //#endregion

    //#region Method
  const handleContextMenu = (e: any) => {
    e.evt.preventDefault();
    if(e.evt.shiftKey) return
    setIsOnOpenMenu(true)
    updateSelectControl(id);

    const menuHeight = getMenuOptionHeight()
    const clientWidth = lineDiagramRef.current.clientWidth
    const isTransCenter = currentTabId == 1 ? false : true;
    let {posX, posY} = calcContextMenuPosition(
      e, menuHeight, clientWidth, x, y, gridSizeController, isTransCenter
    )
    clearOptionMenu(!clearMenuState)
    
    setMenuPosition({
      x: posX,
      y: posY,
    });
    setIsOpenMenu(true);
  };

    const handleClick = (e: any) => {
        if (e.type === "click") setIsOpenMenu(false);
        if(parentGroupId && parentGroupId > 0) return;
        if (e.evt.shiftKey || isSelectTouchGroup) {
            updateSelectControl(id, true);
        } else {
            updateSelectControl(id);
        }
    };

    // Mobile Touch Events
    var isMoving = false
  var isLongPress = false
  
  const handleTouchStart = useCallback((e:any) => {
    setIsOpenMenu(false)
    isLongPress = true
    isMoving = false
    !isSelectTouchGroup && setTimeout(() => {
      if (isLongPress && !isMoving) {
        handleContextMenu(e)
      }
    }, 1000)
  },[x,y,isSelectTouchGroup])

  const handleTouchMove = useCallback(() => {
    isMoving = true
  },[x,y,isSelectTouchGroup])

  const handleTouchEnd = useCallback((e:any) => {
    isLongPress = false
  },[x,y,isSelectTouchGroup])

    const handleOpenMenu = (value: boolean) => {
        setIsOpenMenu(value);
    }

    const handleOpenEditorDialog = () => {
        setIsOpenEditorDialog(true);
    }

    const handleCancelEditorDialog = () => {
        setIsOpenEditorDialog(false);
    };

    const handleUpdate = async (data: MsTextModel) => {
        const updateData = {...data}
        setIsOpenEditorDialog(false);
        
        let params = convertUndefinedToNull({...updateData})
        const request = { 
            userId: userId,
            projectId: projectData.projectId,
            elementType: getElementKindValue(type),
            elementId: id,
            param: params,
            ownerProject: projectData.ownerId
        }
        setLoadingFlag(true);
        const result = await post("/diagram/set-param", request);
        if (result.success) {
            if(result.data && result.data.resultCode == 0){
                setUndoData({type:"UPDATE_PROPERTIES_ELEMENT", dataUndo:{}} as UndoModel);
		        updatePropertiesOfControlAction(id, updateData);
                setInitialValue({...data})
            }
            const newHeight = data.fontSize * data.text.split("\n").length;
            updateControlHeight(id, newHeight, x, y, 0);
            updateControlWidth(id, getTextWidth(data.text, data.faceName, data.fontSize));
            handleUpdateSuccess(result.data);
        } else {
            handleSubmitError(result.error);
        }
    }

    const handleUpdateSuccess = (data: any) =>{
        console.log(">>>> handle Success");
        setLoadingFlag(false);
    }

    const handleSubmitError = (error: any) =>{
        console.log(">>>> handle Error");
        setLoadingFlag(false);
    }
    //#endregion

    return (
        <>
            <OptionMenu
                controlId={id}
                isOpenMenu={isOpenMenu}
                setIsOpenMenu={handleOpenMenu}
                onProperties={handleOpenEditorDialog}
                x={menuPosition.x}
                y={menuPosition.y}
                isDisabledDelete={false}
                isDisabledCut={false}
                isDisabledCopy={false}
                isDisabledPaste={false}
                isDisabledOpenGraph={false}
                isDisabledRotate={true}
                isDisabledProps={false}
                isDisabledUserCurve={true}
            />
            <Text
                ref={shapeRef}
                id={id}
                x={x}
                y={y}
                type={type}
                text={initialValue.text}
                fill={isSelected ? 'red' : initialValue.colorFont}
                fontSize={initialValue.fontSize}
                fontFamily={initialValue.faceName}
                textDecoration={getTextDecoration(initialValue.underline, initialValue.strikeOut)}
                fontStyle={getFontStyleValue(initialValue.weight, initialValue.italic)}
                draggable={processMode === ProcessMode.DRAWING && !m_bModePM && !modeViewOnly && !isOnShiftKey}
                onDragStart={(e) => {setIsOpenMenu(false); e.target.moveToTop();setDragStartPosition({x:x, y:y})}}
                onDragEnd={(e) => {
                    if (e.target.x() < 0 || e.target.y() < 0){
                        setRepositionElement(id, !(elementReposition?.state??true))
                        e.target.to({
                            x: dragStartPosition.x,
                            y: dragStartPosition.y,
                        });
                    }
                    else
                    {
                        e.target.to({
                            x: e.target.x(),
                            y: e.target.y(),
                        });
                    }
                }}
                onMouseEnter={(e) => {
                    const container = e.target.getStage()?.container();
                    container && (container.style.cursor = (processMode === ProcessMode.DRAWING && !m_bModePM && !modeViewOnly) ? "move" : "crosshair")
                }}
                onMouseLeave={(e: any) => {
                    const container = e.target.getStage().container();
                    container.style.cursor = "default";
                }}
                onClick={handleClick}
                onTap={handleClick}
                onContextMenu={handleContextMenu}
                onDblClick={handleOpenEditorDialog}
                onDblTap={handleOpenEditorDialog}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
            />
            <Transformer
                // onMouseDown={(e: any) => setIndexRef(e.target.index)}
                ref={transformRef}
                rotateEnabled={false}
                resizeEnabled={false}
                keepRatio={false}
                borderDash={[4]}
                anchorStroke='black'
                borderStroke='black'
                anchorFill='black'
            />

            {loadingFlag && (
                <Html>
                    <Dialog open={true} maxWidth={"md"} fullWidth>
                        <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1500, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                            <CircularProgress/>
                        </div>
                    </Dialog>
                </Html>
            )}
            {isOpenEditorDialog && !isHidden &&
                <Html>
                    <TextEditorDialog data={initialValue} onCancel={handleCancelEditorDialog} onOK={handleUpdate} m_bModePM={m_bModePM} modeViewOnly={modeViewOnly}></TextEditorDialog>
                </Html>
            }
        </>
    );
});

const mapStateToProps = (state: any) => {
    return {
        userId: state.app.user?.userId,
        processMode: state.app.diagram.processMode,
        m_bModePM: state.app.diagram.m_bModePM,
        modeViewOnly: state.app.diagram.modeViewOnly,
        projectData: state.app.projectData,
        openDialogData : state.app.diagram.openDialog["OPEN_DIALOG"],
        currentTabId: state.app.diagram.currentIDDiagramTab,
        clearMenuState: state.app.diagram.clearOptionMenu,
        isSelectTouchGroup: state.app.diagram.isSelectTouchGroup,
        tcGroups: state.app.diagram.tcGroups,
        elementGroups: state.app.diagram.elementGroups,
        elementReposition: state.app.diagram.elementReposition,
        diagramData: state.app.diagram.diagramData.filter((r: any) => r.tabId === state.app.diagram.currentIDDiagramTab),
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        updateSelectControl: (controlId: string, isMultiple : boolean = false) => dispatch(updateSelectControl(controlId, isMultiple)),
        updatePropertiesOfControlAction: (id: string, data: object) => dispatch(updatePropertiesOfControlAction(id, data)),
        setUndoData: (data:UndoModel) => dispatch(undo(data)),
        openDialogEvents: (param : any) => dispatch(openDialogEvents(param)),
        updateControlWidth: (controlId: string, width: number) => dispatch(updateControlWidth(controlId, width)),
        updateControlHeight: (
            id: string,
            height: number,
            x: number,
            y: number,
            offsetY: number
          ) => dispatch(updateControlHeight(id, height, x, y, offsetY)),
        clearOptionMenu: (state:boolean) => dispatch(clearOptionMenu(state)),
        setRepositionElement: (id:any, state:boolean) => dispatch(setRepositionElement(id, state)),
        updateElementPosition:(id:any, x:any, y:any) => dispatch(updateElementPosition(id, x, y)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateText);