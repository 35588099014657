import { BeamsNumber } from "./FormatNumber"
import { ControlModel, MsCapacitorModel, ProcessMode } from "../models/Index";
import { store } from "..";
import { MS_MODE_CALCULATION, MS_MODE_CHECKING, MS_MODE_DRAWING, MS_MODE_HARMONIC, MS_MODE_PERCENT_Z, MS_MODE_POWER_FLOW, MS_MODE_VOLTDROP } from "../statics";
import { DIRECT_NONE, LEFT_CENTER, TOP_CENTER } from "../models/Constants";

//CEleCapacitor::DoGetTextCapacity
export const doGetTextCapacity = (props:MsCapacitorModel, bUnit:boolean = true) =>
{
	let sText = BeamsNumber(props.capacity, 6).toString();
	if (bUnit) {
		sText += 'kvar';
	}
	return sText;
}

//CEleCapacitor::DoGetTextReactor
export const doGetTextReactor = (props:MsCapacitorModel, bUnit:boolean = true) =>
{
	let sText = props.reactor.toString();
	if (bUnit) {
		sText += '%';
	}
	return sText;
}

//CEleCapacitor::DoGetValueRatedCurrent
export const doGetValueRatedCurrent = (lVoltage:number, dCapacity:number) =>
{
	let dRatedCurrent;

	if (lVoltage <= 0) {
		dRatedCurrent = 0;
	}
	else {
		dRatedCurrent = dCapacity * 1000 / lVoltage / Math.sqrt(3.0);
	}
	return dRatedCurrent;
}

//CEleCapacitor::DoGetTextVoltage
export const doGetTextVoltage = (props:MsCapacitorModel, bUnit:boolean = true) =>
{
	let sText = props.voltage.toString();
	if (bUnit) {
		sText += 'V';
	}
	return sText;
}

//CEleCapacitor::DoGetTextRatedCurrent
export const doGetTextRatedCurrent = (props:MsCapacitorModel, bUnit:boolean = true) =>
{
	let dRatedCurrent = doGetValueRatedCurrent(props.voltage, props.loadFactor * props.capacity);
	let sText = BeamsNumber(dRatedCurrent, 4).toString();
	if (bUnit) {
		sText += 'A';
	}
	return sText;
}

//CEleCapacitor::DoGetTextPowerFactor
export const doGetTextPowerFactor = (props:MsCapacitorModel) =>
{
	return BeamsNumber(props.powerFactor, 5).toString();
}

//CEleCapacitor::DoGetRefV1
export const doGetRefV1 = (mode:any, props:MsCapacitorModel, bSociety:boolean) =>
{
    let infoCircuit = store.getState().app.diagram.infoCircuit;

	//CEleCapacitor::DoGetRefV1Left  sRefV1Left
	let labelTexts = [{
		value: props.refNo,
		color: "#f800f8",
	}];

	if (!props.viewResultText || (mode != ProcessMode.DRAWING && !props.supply))
    {
      return labelTexts;
    }

    if(bSociety) {
        //Capacity
        labelTexts.push({
            value: doGetTextCapacity(props),
            color: "blue",
        });

        if(props.reactor > 0) {
            //Reactor
            labelTexts.push({
                value: doGetTextReactor(props),
                color: "blue",
            });
        }
        return labelTexts;
    }

    switch(mode) {
        case ProcessMode.DRAWING:
        case ProcessMode.CHECKING:
             //Capacity
            labelTexts.push({
                value: doGetTextCapacity(props),
                color: "blue",
            });

            if(props.reactor > 0) {
                //Reactor
                labelTexts.push({
                    value: doGetTextReactor(props),
                    color: "blue",
                });
            }

            //RatedCurrent
            labelTexts.push({
                value: doGetTextRatedCurrent(props),
                color: "blue",
            });
            break;
        case ProcessMode.PERCENT_Z:
            if(props.reactor > 0) {
                // text=0+jtext
                let sText = BeamsNumber(infoCircuit.stdCapacity / (props.loadFactor * props.capacity) * (props.reactor / 100), 4).toString();
                labelTexts.push({
                    value: sText
                    + '=0+j'
                    + sText,
                    color: "blue",
                });
            }
            break;
        case ProcessMode.CALCULATION:
            break;
        case ProcessMode.VOLTDROP:
            //RatedCurrent
            labelTexts.push({
                value: doGetTextRatedCurrent(props),
                color: "blue",
            });
            
             //力率=doGetTextPowerFactor()
             labelTexts.push({
                value: '力率='
                + doGetTextPowerFactor(props),
                color: "blue",
            });
            break;
        case ProcessMode.IMPROVE_PF:
            //Capacity
            labelTexts.push({
                value: doGetTextCapacity(props),
                color: "blue",
            });

            if(props.reactor > 0) {
                //Reactor
                labelTexts.push({
                    value: doGetTextReactor(props),
                    color: "blue",
                });
            }

            //力率=doGetTextPowerFactor()
            labelTexts.push({
                value: '力率='
                + doGetTextPowerFactor(props),
                color: "blue",
            });
            break;
        case ProcessMode.HARMONIC:
            break;
        case ProcessMode.POWER_FLOW:
            break;
        default:
            break;
    }

    return labelTexts;
}

export const doGetRefV2 = (props: MsCapacitorModel, dNominalVolt: number) => { 
    let infos = []
    const name = props.refNo;
    const m_dCapacity = props.capacity;
    const m_lVoltage = props.voltage;
    const m_dLoadFactor = props.loadFactor;
    const m_dReactor = props.reactor;
    infos.push(name);
    infos.push(m_dCapacity.toString() +`kvar`);
    let dRateCurrent = doGetValueRatedCurrent(m_lVoltage,m_dCapacity * m_dLoadFactor);
    if(m_lVoltage === dNominalVolt){
        infos.push(Math.round(dRateCurrent * 10) /10 +`A`);
    } else {
        infos.push(Math.round(dRateCurrent * m_lVoltage /dNominalVolt).toString()+`A` +`(`+Math.round(dRateCurrent) +`)`);
        
    }
    if(m_dReactor > 0){
        infos.push(Math.round(m_dReactor) +`%`)
    }
    return infos
}

//CEleCapacitor::DoGetTextFlowsResult
export const doGetTextFlowsResult = (props:MsCapacitorModel, processMode:any, nDirect:number, bSociety:boolean = false) =>
{
	let sRefV1:string[] = [];

	if (bSociety) {
		return sRefV1;
	}

	switch (processMode) {
	case MS_MODE_DRAWING:
	case MS_MODE_CHECKING:
	case MS_MODE_PERCENT_Z:
	case MS_MODE_CALCULATION:
	case MS_MODE_VOLTDROP:
	case MS_MODE_HARMONIC:
		break;
	case MS_MODE_POWER_FLOW:
		if (props.activePower !==0 && props.reactivePower !==0) {
			if (nDirect == DIRECT_NONE) {
				sRefV1.push(BeamsNumber(Math.abs(props.activePower),5).toString() + 'kW')
				sRefV1.push(BeamsNumber(Math.abs(props.reactivePower),5).toString() + 'kvar')
			}
			else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
				sRefV1.push(BeamsNumber(Math.abs(props.activePower),5).toString() + 'kW')
				let sTemp = ''
				if (props.reactivePower > 0) {
					sTemp += "+j";
				}
				else {
					sTemp += "-j";
				}
				sRefV1.push(sTemp + BeamsNumber(Math.abs(props.reactivePower),5).toString() + 'kvar')
			}
		}
		break;
	}

	return sRefV1;
}