import { Box, Button, Checkbox, FormControl, FormControlLabel, Grid, Stack, Tab, Tabs, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { MsPowerBoardModel } from '../../models/Index';
import TextFieldOptions from "../common/TextFieldOptions";
import { MS_KW_TO_HORSEPOWER, MS_PI } from "../../models/Constants";
import { BeamsNumber } from "../../utils/FormatNumber";
import * as Constant from "../../models/Constants";
import { MS_MODE_CHECKING } from "../../statics";

export type Image3_5EditDialogFormProps = {
    data: MsPowerBoardModel;
    onOK: (data: MsPowerBoardModel) => void;
    onCancel: () => void;
    setIsConnectTrans1OrTranscott: boolean;
    userRole:string;
    m_bModePM: boolean;
    processMode:number;
    modeViewOnly:boolean;
};

function a11yProps(index: number) {
    return {
        id: `tab-${index}`,
        "aria-controls": `tabpanel-${index}`,
    };
}


//Tabs------------------
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

const checkboxStyle = { padding: '4px 4px 4px 8px' }

export const MsPowerBoardEditDialog = (props: Image3_5EditDialogFormProps) => {
    const { userRole, data, onOK, onCancel,setIsConnectTrans1OrTranscott,m_bModePM,processMode, modeViewOnly } = props;
    const [selectTab, setSelectTab] = useState(0);
    const [isDisableOKBtn, setIsDisableOKBtn] = useState(false);


    // 初期値設定
    const initialValue: MsPowerBoardModel = {
        ...data
    }
    const { control, handleSubmit, formState: { errors }, getValues, setFocus, setValue } = useForm<MsPowerBoardModel>({
        mode: 'all',
        criteriaMode: "all",
        defaultValues: initialValue,
    });
    const [makeDataInput, setMakeDataInput] = useState(initialValue.makeDataInput); // ModePM
    const [isDisabledEditValue, setDisabledEditValue] = useState<boolean>(!initialValue.inputDetail);
    
    // Submitイベント
    const handleEditSubmit = (value: MsPowerBoardModel) => {
        const request = createRequestData(value);
        onOK(request);
    }
    // FormデータからRequestデータを作成する
    const createRequestData = (formValue: MsPowerBoardModel) => {
        if(!formValue.totalCapacity){
            formValue.totalCapacity = 0
        }
        if(!formValue.powerFactor){
            formValue.powerFactor = 0
        }
        if(!formValue.loadFactor){
            formValue.loadFactor = 0
        }
        if(!formValue.freezer){
            formValue.freezer = 0
        }
        if(!formValue.elevator){
            formValue.elevator = 0
        }
        if(!formValue.aircon){
            formValue.aircon = 0
        }
        if(!formValue.hygiene){
            formValue.hygiene = 0
        }
        if(!formValue.capacitor){
            formValue.capacitor = 0
        }
        if(!formValue.other){
            formValue.other = 0
        }
        if(!formValue.freezerPF){
            formValue.freezerPF = 0
        }
        if(!formValue.elevatorPF){
            formValue.elevatorPF = 0
        }
        if(!formValue.airconPF){
            formValue.airconPF = 0
        }
        if(!formValue.hygienePF){
            formValue.hygienePF = 0
        }
        if(!formValue.capacitorPF){
            formValue.capacitorPF = 0
        }
        if(!formValue.otherPF){
            formValue.otherPF = 0
        }
        let request = {
            ...formValue
        } as MsPowerBoardModel;
        return request;
    }

    // キャンセル
    const handleCancel = () => {
        onCancel();
    };

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        if (newValue === 1) {
            if (isNaN(Number(getValues('fullCurrent'))) || Number(getValues('fullCurrent')) === 0) {
                setFocus("fullCurrent");
            } else {
                setSelectTab(newValue);
            }
        } else
            setSelectTab(newValue);
    };
    const handleChangeColor = () => (getValues("inputDetail") && m_bModePM ||processMode > MS_MODE_CHECKING) ?"floralwhite":getValues("inputDetail")? "white" : "floralwhite"
    const handleChangeColorRevert = () => getValues("inputDetail") ? "floralwhite" : "white";
    const handleChangeFormat = (e: any) => {
        
        //click
        if (getValues(e.target.name) === false) {
            setValue(e.target.name, !e.target.value);
            setDisabledEditValue(false)
            doSetValuePowerBoard(e,"",true)

        } else {
            setValue(e.target.name, !e.target.value);
            setValue("totalCapacity", getValues("totalCapacity1"));
            setValue("powerFactor", getValues("powerFactor1"));
            setValue("fullCurrent", getValues("fullCurrent1"));
            setValue("phaseCurrent", getValues("phaseCurrent1"));
            setDisabledEditValue(true)
            doSetValuePowerBoard(e,"",false)

        };
        
    }
    // ModePM
const handleChangeMakeDataInput = (e: any) => {
    setValue(e.target.name, e.target.checked);
    setMakeDataInput(e.target.checked);
}
const handleChangeFormat1 = (e: any) => {
    setValue(e.target.name, e.target.value);
}

const doGetTotalCapacity =(e:any,bIsLead:boolean,inputFDetail:boolean |undefined = undefined) =>{
    setValue(e.target.name,e.target.value)
    let totalCapacity = 0
    let capacitorPF = 0
    let inputDetail = inputFDetail
    if(inputFDetail === undefined){
     inputDetail = getValues("inputDetail")
    }
    if(inputDetail === true){
        
        totalCapacity = doGetValueTotalCapacity(e,capacitorPF,bIsLead)
    }

    else{
        totalCapacity = +getValues("totalCapacity")
    }
    
    setValue("totalCapacity",BeamsNumber(totalCapacity,6))// set capacityUnit
    return totalCapacity
}
//TotalCapacity

const doGetValueTotalCapacity = (e:any,totalCapacityPF:number,bIsLead:boolean,inputFDetail:boolean |undefined = undefined) =>{
    setValue(e.target.name, e.target.value)
    let totalCapacity = 0
    let dP = 0
    let dQ = 0
    let powerFactor = +getValues("powerFactor")
    let totalCapacityUnit = getValues("totalCapacityUnit")
    let inputDetail= inputFDetail
 
    let freezer = +getValues("freezer")
    let freezerPF = +getValues("freezerPF")
    let freezerUnit = getValues("freezerUnit")

    //elevator
    let elevator = +getValues("elevator")
    let elevatorPF = +getValues("elevatorPF")
    let elevatorUnit = getValues("elevatorUnit")
    //aircon
    let aircon  = +getValues("aircon")
    let airconPF  = +getValues("airconPF")
    let airconUnit  = getValues("airconUnit")
    //hygiene
    let hygiene = +getValues("hygiene")
    let hygienePF = +getValues("hygienePF")
    let hygieneUnit = getValues("hygieneUnit")
    //capcitor
    let capacitor = +getValues("capacitor")
    let capacitorPF = +getValues("capacitorPF")
    let capacitorUnit = getValues("capacitorUnit")
    //other
    let other = +getValues("other")
    let otherPF = +getValues("otherPF")
    let otherUnit = getValues("otherUnit")

    //Check input data 
    if( freezerPF < 0 || freezerPF > 1 || elevatorPF < 0 || elevatorPF > 1 || airconPF < 0 || airconPF > 1
        || hygienePF <0 || hygienePF > 1 || capacitorPF > 1 || capacitorPF < 0 || otherPF >1 || otherPF < 0
        || freezer < 0 || elevator < 0 ||aircon < 0 ||hygiene < 0 ||capacitor < 0 ||other <0 || powerFactor > 1 || powerFactor <0){
            // setIsDisableOKBtn(true)
        }
    else {
        if(freezerPF >= 0 && freezerPF <= 1 && elevatorPF >= 0 && elevatorPF <= 1 && airconPF >= 0 && airconPF <= 1
            && hygienePF >=0 && hygienePF <= 1 && capacitorPF <= 1 && capacitorPF >= 0 && otherPF <=1 && otherPF >= 0 && powerFactor >=0 && powerFactor<=1
            && freezer >=0 && elevator >=0 && aircon >=0 && hygiene >=0 && capacitor >=0 &&other >=0){
            // setIsDisableOKBtn(false)
    //freezer
    if(inputDetail === undefined){
        inputDetail = getValues("inputDetail")
    }
    if(freezerPF !== 0){
        if(freezerUnit === "kW"){
            dP = freezer
            dQ = freezer * Math.sqrt(1 - freezerPF * freezerPF) / freezerPF
        }else if(freezerUnit === "kVA"){
            dP = freezer * freezerPF
            dQ = freezer * Math.sqrt(1 - freezerPF * freezerPF)
        }
        else if(freezerUnit === "HP") {
            dP = freezer / MS_KW_TO_HORSEPOWER
            dQ = (freezer/ MS_KW_TO_HORSEPOWER) * Math.sqrt(1 - freezerPF * freezerPF) / freezerPF
        }
    }
    else{
        if(freezerUnit === "kW"){
            dP = 0
            dQ = freezer
        }
        else if(freezerUnit === "kVA"){
            dP = 0;
            dQ = freezer
        }
        else if(freezerUnit === "HP"){
            dP = 0
            dQ = freezer / MS_KW_TO_HORSEPOWER
        }
    }
    //elevator
    if(elevatorPF !== 0) {
        if(elevatorUnit === "kW"){
            dP += elevator
            dQ += elevator * Math.sqrt(1 - elevatorPF * elevatorPF) / elevatorPF
        }
        else if(elevatorUnit === "kVA") {
            dP += elevator * elevatorPF
            dQ += elevator * Math.sqrt(1 - elevatorPF * elevatorPF)
        }
        else if(elevatorUnit === "HP"){
            dP += elevator / MS_KW_TO_HORSEPOWER
            dQ += (elevator / MS_KW_TO_HORSEPOWER) * Math.sqrt(1 - elevatorPF * elevatorPF) / elevatorPF
        }
    }
    else{
        if(elevatorUnit === "kW"){
            dQ += elevator
        }
        else if( elevatorUnit === "kVA"){
            dQ += elevator
        }
        else if( elevatorUnit === "HP"){
            dQ +=  elevator / MS_KW_TO_HORSEPOWER
        }
    }
    //aircon
    if( airconPF !== 0) {
        if( airconUnit === "kW"){
            dP +=  aircon
            dQ +=  aircon * Math.sqrt(1 -  airconPF *  airconPF) /  airconPF
        }
        else if( airconUnit === "kVA"){
            dP +=  aircon *  airconPF
            dQ +=  aircon * Math.sqrt(1 -  airconPF *  airconPF)
        }
        else if(airconUnit === "HP"){
            dP += aircon / MS_KW_TO_HORSEPOWER
            dQ += (aircon / MS_KW_TO_HORSEPOWER) * Math.sqrt(1 - airconPF * airconPF) / airconPF
        }
    }
    else{
        if (airconUnit === "kW"){
            dQ += aircon
        }
        else if(airconUnit === "kVA"){
            dQ += aircon
        }
        else if(airconUnit === "HP"){
            dQ += aircon / MS_KW_TO_HORSEPOWER
        }
    }
     //hygiene
     if(hygienePF !== 0) {
        if(hygieneUnit === "kW"){
            dP += hygiene
            dQ += hygiene * Math.sqrt(1 - hygienePF * hygienePF) / hygienePF
        }
        else if(hygieneUnit === "kVA"){
            dP += hygiene * hygienePF
            dQ += hygiene * Math.sqrt(1 - hygienePF * hygienePF)
        }
        else if(hygieneUnit === "HP"){
            dP += hygiene / MS_KW_TO_HORSEPOWER
            dQ += (hygiene / MS_KW_TO_HORSEPOWER) * Math.sqrt(1 - hygienePF * hygienePF) / hygienePF
        }
    }
    else{
        if(hygieneUnit === "kW"){
            dQ += hygiene
        }
        else if(hygieneUnit === "kVA"){
            dQ += hygiene
        }
        else if(hygieneUnit === "HP"){
            dQ += hygiene / MS_KW_TO_HORSEPOWER
        }
    }
    //capacitor
    if(capacitorPF !== 0) {
        if(capacitorUnit === "kW"){
            dP += capacitor
            dQ -= capacitor * Math.sqrt(1 - capacitorPF * capacitorPF) / capacitorPF
        }
        else if(capacitorUnit === "kVA"){
            dP += capacitor * capacitorPF
            dQ -= capacitor * Math.sqrt(1 - capacitorPF * capacitorPF)
        }
        else if(capacitorUnit === "HP"){
            dP += capacitor / MS_KW_TO_HORSEPOWER
            dQ -= (capacitor / MS_KW_TO_HORSEPOWER) * Math.sqrt(1 - capacitorPF * capacitorPF) / capacitorPF
        }
    }
    else{
        if(capacitorUnit === "kW"){
            dQ -= capacitor
        }
        else if(capacitorUnit === "kVA"){
            dQ -= capacitor
        }
        else if(capacitorUnit === "HP"){
            dQ -= capacitor / MS_KW_TO_HORSEPOWER
        }
    }
    //other
    if(otherPF !== 0) {
        if(otherUnit === "kW"){
            dP += other
            dQ += other * Math.sqrt(1 - otherPF * otherPF) / otherPF
        }
        else if(otherUnit === "kVA"){
            dP += other * otherPF
            dQ += other * Math.sqrt(1 - otherPF * otherPF)
        }
        else if(otherUnit === "HP"){
            dP += other / MS_KW_TO_HORSEPOWER
            dQ += (other / MS_KW_TO_HORSEPOWER) * Math.sqrt(1 - otherPF * otherPF) / otherPF
        }
    }
    else{
        if(otherUnit === "kW"){
            dQ += other
        }
        else if(otherUnit === "kVA"){
            dQ += other
        }
        else if(otherUnit === "HP"){
            dQ += other / MS_KW_TO_HORSEPOWER
        }
    }
    totalCapacity = Math.sqrt(dP * dP + dQ * dQ)
    if(totalCapacity !== 0) {
        totalCapacityPF = dP / totalCapacity //powerFactor 
    }
    else{
        totalCapacityPF = 0
       
    }
    
    if(totalCapacityUnit === "kW"){
        totalCapacity = dP
    }
    else if(totalCapacityUnit === "kVA"){

    }else if (totalCapacityUnit === "HP"){
        totalCapacity = dP * MS_KW_TO_HORSEPOWER
    }
    if(!inputDetail){
        totalCapacity = getValues("totalCapacity")
        totalCapacityPF = powerFactor
    }
    setValue("powerFactor" , BeamsNumber(totalCapacityPF,5))
}
else {
// setIsDisableOKBtn(true)    
}
}
    return totalCapacity

}
const doGetTotalCapacityPF = (e:any,bIsLead:boolean,inputFDetail:boolean|undefined=undefined) => {
    setValue(e.target.name,e.target.value)
    let totalCapacity = 0
    let totalCapacityPF = 0
    let inputDetail = inputFDetail
    
    if(inputDetail === undefined){
        inputDetail = getValues("inputDetail")
    }
    if(inputDetail === true) {
        totalCapacity = doGetValueTotalCapacity(e,totalCapacityPF,bIsLead)
        setValue("totalCapacity",BeamsNumber(totalCapacity,6))
    }
    else{
        totalCapacityPF = +getValues("powerFactor")
    }
    // setValue("totalCapacity",BeamsNumber(totalCapacity,6))
    return totalCapacityPF
}
const doGetValueLoadCurrent = (e:any,m_bSPhase:boolean,bIsLead:boolean,inputFDetail:boolean|undefined = undefined) =>{
    setValue(e.target.name,e.target.value)
    let fullCurrent = 0;
    let voltage = getValues("voltage")
    let totalCapacity = getValues("totalCapacity")
    let inputDetail = inputFDetail
    let loadFactor = getValues("loadFactor")
    let totalCapacityUnit = getValues("totalCapacityUnit")
    if(inputDetail === undefined){
        inputDetail = getValues("inputDetail")
    }
    if(loadFactor > 1 || loadFactor < 0 || voltage <=0 || voltage > 600 ||totalCapacity < 0){
        // setIsDisableOKBtn(true)
    }
    else{
        if(loadFactor >=0&& loadFactor <=1 && voltage>0 && voltage <=600 && totalCapacity >= 0){
            // setIsDisableOKBtn(false)
        }else{
            // setIsDisableOKBtn(true)
        }
    }
    if(voltage <= 0){  
        fullCurrent = 0
    }
    else{
        let totalCapacity = 0
        let capacityPF = 0
        if(inputDetail === true){
            totalCapacity = doGetValueTotalCapacity(e,capacityPF,bIsLead)
            capacityPF = getValues("powerFactor")
            setValue("totalCapacity",BeamsNumber(totalCapacity,6))
        }
        else{
            totalCapacity = getValues("totalCapacity")
            capacityPF = getValues("powerFactor")
         
        }
        let capacity = totalCapacity * 1000 * loadFactor
        if(capacityPF === 0) {
            if(totalCapacityUnit === "kW"){
                fullCurrent = capacity / voltage
            }
            else if(totalCapacityUnit === "kVA"){
                fullCurrent = capacity / voltage
            }
            else if(totalCapacityUnit === "HP"){
                fullCurrent = capacity / voltage / MS_KW_TO_HORSEPOWER
            }
            if(m_bSPhase !== true){
                fullCurrent /=Math.sqrt(3.0)
            }
        }
        else{
            if(totalCapacityUnit === "kW"){
                fullCurrent = capacity / voltage / capacityPF
            }
            else if(totalCapacityUnit === "kVA"){
                fullCurrent = capacity / voltage
            }
            else if(totalCapacityUnit === "HP"){
                fullCurrent = capacity /voltage / capacityPF / MS_KW_TO_HORSEPOWER
            }
           if(m_bSPhase !== true){
                fullCurrent /=Math.sqrt(3.0)
            }
        }
        // setValue("capacitorPF",capacityPF)//-------------
        
        // setValue("totalCapacity",BeamsNumber(+totalCapacity,6))
    }
    
    return fullCurrent
}
const doGetPhaseCurrent = (bIsLead:boolean,inputFDetail:boolean|undefined = undefined) =>{
    let dpowerFactor = 0
    let voltage = getValues("voltage")
    let inputDetail = inputFDetail
    if(inputDetail === undefined){
        inputDetail = getValues("inputDetail")
    }
    if(voltage <= 0){
        dpowerFactor = 0
    }
    else{
        if(inputDetail === true){
            //  doGetValueTotalCapacity(dpowerFactor,bIsLead)//,isLead//k chac
            // setValue("totalCapacity",totalCapacity)
            dpowerFactor = getValues("powerFactor")
        }
        else{
            dpowerFactor = getValues("powerFactor")
        }
    }
    if(dpowerFactor < -1 || dpowerFactor > 1){
        return 0
    }
    let phaseCurrent = BeamsNumber(Math.acos(dpowerFactor) * 180 / MS_PI,4)
    if(!bIsLead){
        phaseCurrent = (-1) * phaseCurrent
    }
    else{
        phaseCurrent = 1 * phaseCurrent
    }

    return phaseCurrent

}

const doSetValuePowerBoard = (e:any, nControl:string,inputFDetail:boolean|undefined = undefined) =>{
    setValue(e.target.name, e.target.value,{shouldValidate:true})
    let phaseCurrent = ""
    const m_bSPhase = getValues("sPhase") // hard code
    const  bIsLead = getValues("isLead") // hard code
    
    //Calc
    // temp variable
    if(nControl === "IDC_TOTAl_CAPACITY"){
        setValue("totalCapacity1",e.target.value)
    }
    if(nControl === "IDC_POWER_FACTOR"){
        setValue("powerFactor1", e.target.value)
    }
    
    // main
    if(nControl !== "IDC_TOTAl_CAPACITY"){
    let totalCapacity = doGetTotalCapacity(e,bIsLead,inputFDetail)
    setValue("totalCapacity",BeamsNumber(totalCapacity,6))
    }
    if(nControl !== "IDC_POWER_FACTOR"){
    let totalCapacityPF = doGetTotalCapacityPF(e,bIsLead)
    setValue("powerFactor",BeamsNumber(totalCapacityPF,5))
    }
    // if(nControl !== "IDC_LOAD_FACTOR"){
    // let loadFactor = getValues("loadFactor")//4
    // }
    // if(nControl !== "IDC_FREEZER"){
    //  let freezer = getValues("freezer")
    // }
    // if(nControl !== "IDC_ELEVATOR"){
    //  let elevator = getValues("elevator")
    // }
    // if(nControl !== "IDC_AIRCON"){
    //  let aircon = getValues("aircon")
    // }
    // if(nControl !== "IDC_HYGIENE"){
    //  let hygiene = getValues("hygiene")
    // }
    // if(nControl !== "IDC_OTHER"){
    //  let other = getValues("other")
    // }
    // if(nControl !== "IDC_FREEZER_PF"){
    //     let freezerPF = getValues("freezerPF")
    // }
    // if(nControl !== "IDC_ELEVATOR_PF"){
    //     let elevatorPF = getValues("elevatorPF")
    // }
    // if(nControl !== "IDC_AIRCON_PF"){
    //     let airconPF = getValues("airconPF")
    // }
    // if(nControl !== "IDC_HYGIENE_PF"){
    //     let hygienePF = getValues("hygienePF")
    // }
    // if(nControl !== "IDC_OTHER_PF"){
    //     let otherPF = getValues("otherPF")
    // } 
    let fullCurrent = doGetValueLoadCurrent(e,m_bSPhase,bIsLead)
    let powerFactor = getValues("powerFactor") 
    if(powerFactor >=0 && powerFactor <= 1){
            phaseCurrent = doGetPhaseCurrent(bIsLead).toString()
    }
    else{
        phaseCurrent = ""
    }
    setValue("fullCurrent",BeamsNumber(fullCurrent,4))
    setValue("phaseCurrent",phaseCurrent)

    // temp variable
    setValue("fullCurrent1",BeamsNumber(fullCurrent,4))
    setValue("phaseCurrent1",phaseCurrent)

}

const doSetTotalCapacityUnit =(value:any)=>{
    let totalCapacityUnitList =[] as any
    initialValue.totalCapacityUnitList.forEach(a =>{
       totalCapacityUnitList.push(a.value)
    })
    if(totalCapacityUnitList.includes(value)){
        return true
    }
    else{

    return false
    }
}


    return (
        <>
            <div style={{ height: "100%", width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={selectTab} onChange={handleChange}
                        variant="scrollable" 
                        scrollButtons 
                        allowScrollButtonsMobile 
                        sx={{
                            '& .MuiButtonBase-root.MuiTabScrollButton-root.Mui-disabled': {
                                display: "none"
                            }
                        }}
                    >
                        <Tab label="基本" {...a11yProps(0)} />
                    </Tabs>
                </Box>
                <form onSubmit={handleSubmit(handleEditSubmit)}>
                    <div style={{ height: "40vh", overflow: "auto", width: '100%', margin: 'auto' }}>
                        <TabPanel value={selectTab} index={0}>
                            <Stack mt={1}>
                                <Grid container>
                                    <Grid item xs={7}>
                                        <Stack spacing={0.3}>
                                            <Stack flexDirection='row' alignItems='center'>
                                                <Typography variant='body2' width='55%'>盤名称:</Typography>
                                                <Controller
                                                    name="refNo"
                                                    control={control}
                                                    rules={{required: '必須項目です。入力してください。'}}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type="text"
                                                            error={!!errors?.refNo}
                                                            helperText={errors?.refNo?.message}
                                                            InputProps={{ sx: { height: 32 ,backgroundColor:m_bModePM || modeViewOnly?'floralwhite':'white', color:modeViewOnly?"grey":"black"} }}
                                                            disabled={m_bModePM}
                                                            inputProps={{readOnly:modeViewOnly}}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack flexDirection='row' alignItems='center'>
                                                <Typography variant='body2' width='55%'>回路電圧(V):</Typography>
                                                <FormControl fullWidth>
                                                    <Controller
                                                        name="voltage"
                                                        control={control}
                                                        rules={{ required: '必須項目です。入力してください。',
                                                        pattern:{
                                                            value: /^[0-9\b]+$/,
                                                            message: '数字を入力してください。'
                                                        },
                                                        validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                        // maxLength: {
                                                        //     value: 3,
                                                        //     message: "最大長さ <= 3 を入力してください。"
                                                        // }
                                                    }}
                                                        render={({ field }) => (
                                                            <TextFieldOptions
                                                                {...field}
                                                                options={initialValue.voltageList}
                                                                setValue={(name: any, value: any) => {                                                               
                                                                    doSetValuePowerBoard({target: {name: name, value: value}},"")
                                                                }}
                                                                height= {32}
                                                                maxLength={3}
                                                                error={!!errors?.voltage}
                                                                helperText={errors?.voltage?.message}
                                                                disabled={getValues("isSourceVoltage")||m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}
                                                            />
                                                        )}
                                                    />
                                                </FormControl>
                                            </Stack>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={5} pl={3}>
                                        {/* Mode SP */}
                                        {(userRole && !(userRole == Constant.ROLE_LP)) &&
                                        <Stack>
                                            <Controller
                                                name="viewFlowResult"
                                                control={control}
                                                render={({ field }: any) => (
                                                    <FormControlLabel
                                                        {...field}
                                                        control={<Checkbox size="small" style={checkboxStyle}/>}
                                                        name='viewFlowResult'
                                                        checked={field.value} 
                                                        label={
                                                            <Typography variant='body2' color={(initialValue.sPhase||m_bModePM)?'grey':'black'}>
                                                                潮流解析結果を表示
                                                            </Typography>
                                                        }
                                                        disabled={initialValue.sPhase||m_bModePM || modeViewOnly}

                                                    />
                                                )}
                                            />
                                        </Stack>
                                        }
                                        
                                        <Stack>
                                            <Controller
                                                name="viewResultText"
                                                control={control}
                                                render={({ field }: any) => (
                                                    <FormControlLabel 
                                                        {...field} 
                                                        control={<Checkbox size="small" style={checkboxStyle}/>} 
                                                        name='viewResultText'
                                                        checked={field.value} 
                                                        label={
                                                            <Typography variant='body2' color={m_bModePM?'grey':'black'}>
                                                                注釈を表示
                                                            </Typography>
                                                        }
                                                        disabled={m_bModePM || modeViewOnly}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                    </Grid>
                                </Grid>
                                
                                <Grid container my={0.3} width={'70%'}>
                                    <Grid item xs={7}>
                                        <Stack flexDirection='row' alignItems='center'>
                                            <Typography variant='body2' width='104%'>負荷容量(kW):</Typography>
                                            <Controller
                                                name="totalCapacity"
                                                control={control}
                                                rules={{ 
                                                    pattern:{
                                                        value: /^[0-9]*[.]?[0-9]+$/,
                                                        message: '数字を入力してください。'
                                                    },
                                                    validate: value => value >= 0 || '数字 >= 0 を入力してください。' ,
                                                    // maxLength: {
                                                    //     value: 6,
                                                    //     message: "最大長さ <= 6 を入力してください。"
                                                    // }
                                                }}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        // style={{
                                                        //     backgroundColor: handleChangeColorRevert()
                                                        // }}
                                                        error={!!errors?.totalCapacity}
                                                        helperText={errors?.totalCapacity?.message}
                                                        InputProps={{ sx: { height: 32 ,backgroundColor:(m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING ||getValues("inputDetail"))?'floralwhite':'white', color:modeViewOnly?"grey":"black"} }}
                                                        onChange={(e) =>doSetValuePowerBoard(e,"IDC_TOTAl_CAPACITY")}
                                                        inputProps={{maxLength:6,readOnly:modeViewOnly}}    
                                                        disabled={(!isDisabledEditValue||m_bModePM)|| processMode > MS_MODE_CHECKING}
                                                        />
                                                )}
                                            />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={5} pl={1}>
                                        <Stack flexDirection='row' alignItems='center'>
                                            <Typography variant='body2' width='40%'>{'(単位:'}</Typography>
                                            <FormControl size="small" fullWidth>
                                                <Controller
                                                    name="totalCapacityUnit"
                                                    control={control}
                                                    rules={
                                                        {
                                                            validate: value => doSetTotalCapacityUnit(value) === true || '単位が存在しません。'
                                                        }
                                                    }
                                                    render={({ field }) => (
                                                        <TextFieldOptions
                                                            {...field}
                                                            options={initialValue.totalCapacityUnitList}
                                                            onChange= {handleChangeFormat1}
                                                            size="small"
                                                            setValue={(name: any, value: any) => {
                                                                // doGetTotalCapacity({target: {name: name, value: value}},false)//bIsLead
                                                                doSetValuePowerBoard({target: {name: name, value: value}},"")
                                                            }}
                                                            height= {32}
                                                            maxLength={5}
                                                            error={!!errors?.totalCapacityUnit}
                                                            helperText={errors?.totalCapacityUnit?.message}
                                                            disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}
                                                        />
                                                    )}
                                                />
                                            </FormControl>
                                            <Typography variant='body2'>{')'}</Typography>

                                        </Stack>
                                    </Grid>
                                </Grid>

                                <Stack spacing={0.3} width={'58.3%'}>
                                    <Stack flexDirection='row' alignItems='center'>
                                        <Typography variant='body2' width='55%'>負荷力率(遅れ):</Typography>
                                        <Controller
                                            name="powerFactor"
                                            control={control}
                                            rules={{ 
                                                pattern:{
                                                    value: /^[0-9]*[.]?[0-9]+$/,
                                                    message: '数字を入力してください。'
                                                },
                                                validate: value => value >= 0 && value <= 1 || '数字 0 ~ 1 を入力してください。' ,
                                                // maxLength: {
                                                //     value: 5
                                                //     message: "最大長さ <= 5 を入力してください。"
                                                // }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    style={{
                                                        background: handleChangeColorRevert()
                                                    }}
                                                    InputProps={{ sx: { height: 32 ,backgroundColor:(m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING ||getValues("inputDetail"))?'floralwhite':'white', color:modeViewOnly?"grey":"black"} }}
                                                    onChange={(e) => doSetValuePowerBoard(e,"IDC_POWER_FACTOR")}
                                                    helperText={errors?.powerFactor?.message}
                                                    error={!!errors?.powerFactor}
                                                    inputProps={{maxLength:5,readOnly:modeViewOnly}}
                                                    disabled={!isDisabledEditValue|| m_bModePM|| processMode > MS_MODE_CHECKING}
                                                />
                                            )}
                                        />
                                    </Stack>
                                    <Stack flexDirection='row' alignItems='center'>
                                        <Typography variant='body2' width='55%'>負荷電流(A):</Typography>
                                        <Controller
                                            name="fullCurrent"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    type="number"
                                                    inputProps={{ readOnly: true }}
                                                    style={{ backgroundColor: 'floralwhite' }}
                                                    InputProps={{ sx: { height: 32,color:'grey',backgroundColor: 'floralwhite' } }}
                                                />
                                            )}
                                        />
                                    </Stack>
                                    <Stack flexDirection='row' alignItems='center'>
                                        <Typography variant='body2' width='55%'>負荷電流位相(ﾟ ):</Typography>
                                        <Controller
                                            name="phaseCurrent"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    inputProps={{ readOnly: true }}
                                                    style={{ backgroundColor: 'floralwhite' }}
                                                    InputProps={{ sx: { height: 32,color:'grey',backgroundColor: 'floralwhite' } }}
                                                />
                                            )}
                                        />
                                    </Stack>
                                    <Stack flexDirection='row' alignItems='center'>
                                        <Typography variant='body2' width='55%'>負荷率:</Typography>
                                        <Controller
                                            name="loadFactor"
                                            control={control}
                                            rules={{ 
                                                pattern:{
                                                    value: /^[0-9]*[.]?[0-9]+$/,
                                                    message: '数字を入力してください。'
                                                },
                                                validate: value => value >= 0 && value <= 1 || '数字 0 ~ 1を入力してください。' ,
                                                // maxLength: {
                                                //     value: 5
                                                //     message: "最大長さ <= 5 を入力してください。"
                                                // }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    InputProps={{ sx: { height: 32 ,backgroundColor:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white', color:modeViewOnly?"grey":"black"} }}
                                                    onChange={(e) => doSetValuePowerBoard(e,"IDC_LOAD_FACTOR")}
                                                    helperText={errors?.loadFactor?.message}
                                                    error={!!errors?.loadFactor}
                                                    inputProps={{maxLength:5,readOnly:modeViewOnly}}    
                                                    disabled={m_bModePM|| processMode > MS_MODE_CHECKING}     
                                                />
                                            )}
                                        />
                                    </Stack>
                                </Stack>
                            </Stack>

                            <Stack mt={1}>
                                <Stack>
                                    <Controller
                                        name="inputDetail"
                                        control={control}
                                        render={({ field }: any) => (
                                            <FormControlLabel
                                                {...field}
                                                control={
                                                    <Checkbox
                                                        name="inputDetail"
                                                        onClick={handleChangeFormat}
                                                        checked={field.value}
                                                        style={{padding:'4px 4px 4px 9px'}}
                                                        size="small"
                                                        disabled = {m_bModePM || modeViewOnly}
                                                    />}
                                                label={<Typography variant='body2' color={m_bModePM|| processMode > MS_MODE_CHECKING?'grey':'black'}>負荷の詳細を入力</Typography>}
                                            />
                                        )}
                                    />
                                </Stack>
                                <fieldset style={{border:'1px solid #AAAA'}}>
                                    <legend><Typography variant='body2'>負荷の種類と容量・力率</Typography></legend>
                                    <Grid container>
                                        <Grid item xs={5}>
                                            <Stack spacing={0.3}>
                                                <Stack flexDirection='row' alignItems='center'>
                                                    <Typography variant='body2' width='75%'>冷凍機:</Typography>
                                                    <Controller
                                                        name="freezer"
                                                        control={control}
                                                        rules={{ 
                                                        pattern:{
                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                            message: '数字を入力してください。'
                                                        },
                                                        validate: value => value >= 0 || '数字 >= 0 を入力してください。' ,
                                                        // maxLength: {
                                                        //     value: 6,
                                                        //     message: "最大長さ <= 6 を入力してください。"
                                                        // }
                                                    }}
                                                        render={
                                                            ({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    type="text"
                                                                    style={
                                                                        { backgroundColor: (getValues("inputDetail") && m_bModePM|| processMode > MS_MODE_CHECKING) || modeViewOnly ?"floralwhite":getValues("inputDetail")? "white" : "floralwhite" }
                                                                    }
                                                                    inputProps={{ readOnly: (isDisabledEditValue || m_bModePM|| processMode > MS_MODE_CHECKING|| modeViewOnly) ,maxLength:6}}
                                                                    InputProps={{ sx: { height: 32,color:(isDisabledEditValue || m_bModePM|| processMode > MS_MODE_CHECKING) || modeViewOnly?'grey':'black' } }}
                                                                    onChange={(e)=>doSetValuePowerBoard(e,"IDC_FREEZER")}
                                                                    error={!!errors?.freezer}
                                                                    helperText={errors?.freezer?.message}
                                                                />
                                                            )
                                                        }
                                                    />
                                                </Stack>
                                                <Stack flexDirection='row' alignItems='center'>
                                                    <Typography variant='body2' width='75%'>エレベータ:</Typography>
                                                    <Controller
                                                        name="elevator"
                                                        control={control}
                                                        rules={{ 
                                                            pattern:{
                                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                                message: '数字を入力してください。'
                                                            },
                                                            validate: value => value >= 0 || '数字 >= 0 を入力してください。' ,
                                                            // maxLength: {
                                                            //     value: 6,
                                                            //     message: "最大長さ <= 6 を入力してください。"
                                                            // }
                                                        }}
                                                        render={
                                                            ({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    type="text"
                                                                    style={
                                                                        { backgroundColor: handleChangeColor() }
                                                                    }
                                                                    inputProps={{ readOnly: (isDisabledEditValue || m_bModePM|| processMode > MS_MODE_CHECKING || modeViewOnly) ,maxLength:6}}
                                                                    InputProps={{ sx: { height: 32,color:(isDisabledEditValue || m_bModePM|| processMode > MS_MODE_CHECKING) || modeViewOnly?'grey':'black' } }}
                                                                    onChange={(e)=>doSetValuePowerBoard(e,"IDC_ELEVATOR")}
                                                                    error={!!errors?.elevator}
                                                                    helperText={errors?.elevator?.message}
                                                                />
                                                            )
                                                        }
                                                    />
                                                </Stack>
                                                <Stack flexDirection='row' alignItems='center'>
                                                    <Typography variant='body2' width='75%'>空調関係:</Typography>
                                                    <Controller
                                                        name="aircon"
                                                        control={control}
                                                        rules={{ 
                                                            pattern:{
                                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                                message: '数字を入力してください。'
                                                            },
                                                            validate: value => value >= 0 || '数字 >= 0 を入力してください。' ,
                                                            // maxLength: {
                                                            //     value: 6,
                                                            //     message: "最大長さ <= 6 を入力してください。"
                                                            // }
                                                        }}
                                                        render={
                                                            ({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    type="text"
                                                                    style={
                                                                        { backgroundColor: handleChangeColor() }
                                                                    }
                                                                    inputProps={{ readOnly: (isDisabledEditValue || m_bModePM|| processMode > MS_MODE_CHECKING || modeViewOnly),maxLength:6 }}
                                                                    InputProps={{ sx: { height: 32,color:(isDisabledEditValue || m_bModePM|| processMode > MS_MODE_CHECKING) || modeViewOnly?'grey':'black' } }}
                                                                    onChange={(e)=>doSetValuePowerBoard(e,"IDC_AIRCON")}
                                                                    error={!!errors?.aircon}
                                                                    helperText={errors?.aircon?.message}
                                                                />
                                                            )
                                                        }
                                                    />
                                                </Stack>
                                                <Stack flexDirection='row' alignItems='center'>
                                                    <Typography variant='body2' width='75%'>衛生関係:</Typography>
                                                    <Controller
                                                        name="hygiene"
                                                        control={control}
                                                        rules={{ 
                                                            pattern:{
                                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                                message: '数字を入力してください。'
                                                            },
                                                            validate: value => value >= 0 || '数字 >= 0 を入力してください。' ,
                                                            // maxLength: {
                                                            //     value: 6,
                                                            //     message: "最大長さ <= 6 を入力してください。"
                                                            // }
                                                        }}
                                                        render={
                                                            ({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    type="text"
                                                                    style={
                                                                        { backgroundColor: handleChangeColor() }
                                                                    }
                                                                    inputProps={{ readOnly: (isDisabledEditValue || m_bModePM|| processMode > MS_MODE_CHECKING|| modeViewOnly) ,maxLength:6}}
                                                                    InputProps={{ sx: { height: 32,color:(isDisabledEditValue || m_bModePM|| processMode > MS_MODE_CHECKING) || modeViewOnly?'grey':'black' } }}
                                                                    onChange={(e)=>doSetValuePowerBoard(e,"IDC_HYGIENE")}
                                                                    error={!!errors?.hygiene}
                                                                    helperText={errors?.hygiene?.message}
                                                                />
                                                            )
                                                        }
                                                    />
                                                </Stack>
                                                <Stack flexDirection='row' alignItems='center'>
                                                    <Typography variant='body2' width='75%'>コンデンサ:</Typography>
                                                    <Controller
                                                        name="capacitor"
                                                        control={control}
                                                        rules={{ 
                                                            pattern:{
                                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                                message: '数字を入力してください。'
                                                            },
                                                            validate: value => value >= 0 || '数字 >= 0 を入力してください。' ,
                                                            // maxLength: {
                                                            //     value: 6
                                                            //     message: "最大長さ <= 6 を入力してください。"
                                                            // }
                                                        }}
                                                        render={
                                                            ({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    type="text"
                                                                    style={
                                                                        { backgroundColor: handleChangeColor() }
                                                                    }
                                                                    inputProps={{ readOnly: (isDisabledEditValue || m_bModePM|| processMode > MS_MODE_CHECKING || modeViewOnly) ,maxLength:6}}
                                                                    InputProps={{ sx: { height: 32,color:(isDisabledEditValue || m_bModePM|| processMode > MS_MODE_CHECKING) || modeViewOnly?'grey':'black' } }}
                                                                    onChange={(e)=>doSetValuePowerBoard(e,"")}
                                                                    error={!!errors?.capacitor}
                                                                    helperText={errors?.capacitor?.message}
                                                                />
                                                            )
                                                        }
                                                    />
                                                </Stack>
                                                <Stack flexDirection='row' alignItems='center'>
                                                    <Typography variant='body2' width='75%'>その他:</Typography>
                                                    <Controller
                                                        name="other"
                                                        control={control}
                                                        rules={{ 
                                                            pattern:{
                                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                                message: '数字を入力してください。'
                                                            },
                                                            validate: value => value >= 0 || '数字 >= 0 を入力してください。' ,
                                                            // maxLength: {
                                                            //     value: 6
                                                            //     message: "最大長さ <= 6 を入力してください。"
                                                            // }
                                                        }}
                                                        render={
                                                            ({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    type="text"
                                                                    style={
                                                                        { backgroundColor: handleChangeColor() }
                                                                    }
                                                                    inputProps={{ readOnly: (isDisabledEditValue || m_bModePM|| processMode > MS_MODE_CHECKING|| modeViewOnly) ,maxLength:6}}
                                                                    InputProps={{ sx: { height: 32,color:(isDisabledEditValue || m_bModePM|| processMode > MS_MODE_CHECKING) || modeViewOnly?'grey':'black' } }}
                                                                    onChange={(e)=>doSetValuePowerBoard(e,"IDC_OTHER")}
                                                                    error={!!errors?.other}
                                                                    helperText={errors?.other?.message}
                                                                />
                                                            )
                                                        }
                                                    />
                                                </Stack>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={3.5} pl={0.5}>
                                            <Stack spacing={0.3}>
                                                <Stack flexDirection='row' alignItems='center'>
                                                    <Typography variant='body2' width='75%'>{'(単位:'}</Typography>
                                                    <Controller
                                                        name="freezerUnit"
                                                        control={control}
                                                        rules={
                                                            {
                                                                validate: value => doSetTotalCapacityUnit(value) === true || '単位が存在しません。'
                                                            }
                                                        }
                                                        render={({ field }) => (
                                                            <TextFieldOptions
                                                                {...field}
                                                                disabled={isDisabledEditValue||m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}
                                                                options={initialValue.totalCapacityUnitList}
                                                                setValue={(name: any, value: any) => {
                                                                    doSetValuePowerBoard({target: {name: name, value: value}},"")
                                                                }}
                                                                height= {32}
                                                                error={!!errors?.freezerUnit}
                                                                helperText={errors?.freezerUnit?.message}
                                                                maxLength={5}
                                                            />
                                                        )}
                                                    />
                                                    <Typography>{')'}</Typography>
                                                </Stack>
                                                <Stack flexDirection='row' alignItems='center'>
                                                    <Typography variant='body2' width='75%'>{'(単位:'}</Typography>
                                                    <Controller
                                                        name="elevatorUnit"
                                                        control={control}
                                                        rules={
                                                            {
                                                                validate: value => doSetTotalCapacityUnit(value) === true || '単位が存在しません。'
                                                            }
                                                        }
                                                        render={({ field }) => (
                                                            <TextFieldOptions
                                                                {...field}
                                                                disabled={isDisabledEditValue||m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}
                                                                options={initialValue.totalCapacityUnitList}
                                                                setValue={(name: any, value: any) => {
                                                                    doSetValuePowerBoard({target: {name: name, value: value}},"")
                                                                }}
                                                                height= {32}
                                                                error={!!errors?.elevatorUnit}
                                                                helperText={errors?.elevatorUnit?.message}
                                                                maxLength={5}
                                                            />
                                                        )}
                                                    />
                                                    <Typography>{')'}</Typography>
                                                </Stack>
                                                <Stack flexDirection='row' alignItems='center'>
                                                    <Typography variant='body2' width='75%'>{'(単位:'}</Typography>
                                                    <Controller
                                                        name="airconUnit"
                                                        control={control}
                                                        rules={
                                                            {
                                                                validate: value => doSetTotalCapacityUnit(value) === true || '単位が存在しません。'
                                                            }
                                                        }
                                                        render={({ field }) => (
                                                            <TextFieldOptions
                                                                {...field}
                                                                disabled={isDisabledEditValue||m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}
                                                                options={initialValue.totalCapacityUnitList}
                                                                setValue={(name: any, value: any) => {
                                                                    doSetValuePowerBoard({target: {name: name, value: value}},"")
                                                                }}
                                                                height= {32}
                                                                error={!!errors?.airconUnit}
                                                                helperText={errors?.airconUnit?.message}
                                                                maxLength={5}
                                                            />
                                                        )}
                                                    />
                                                    <Typography>{')'}</Typography>
                                                </Stack>
                                                <Stack flexDirection='row' alignItems='center'>
                                                    <Typography variant='body2' width='75%'>{'(単位:'}</Typography>
                                                    <Controller
                                                        name="hygieneUnit"
                                                        control={control}
                                                        rules={
                                                            {
                                                                validate: value => doSetTotalCapacityUnit(value) === true || '単位が存在しません。'
                                                            }
                                                        }
                                                        render={({ field }) => (
                                                            <TextFieldOptions
                                                                {...field}
                                                                disabled={isDisabledEditValue||m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}
                                                                options={initialValue.totalCapacityUnitList}
                                                                setValue={(name: any, value: any) => {
                                                                    doSetValuePowerBoard({target: {name: name, value: value}},"")
                                                                }}
                                                                height= {32}
                                                                error={!!errors?.hygieneUnit}
                                                                helperText={errors?.hygieneUnit?.message}
                                                                maxLength={5}
                                                            />
                                                        )}
                                                    />
                                                    <Typography>{')'}</Typography>
                                                </Stack>
                                                <Stack flexDirection='row' alignItems='center'>
                                                    <Typography variant='body2' width='75%'>{'(単位:'}</Typography>
                                                    <Controller
                                                        name="capacitorUnit"
                                                        control={control}
                                                        rules={
                                                            {
                                                                validate: value => doSetTotalCapacityUnit(value) === true || '単位が存在しません。'
                                                            }
                                                        }
                                                        render={({ field }) => (
                                                            <TextFieldOptions
                                                                {...field}
                                                                disabled={isDisabledEditValue||m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}
                                                                options={initialValue.totalCapacityUnitList}
                                                                setValue={(name: any, value: any) => {
                                                                    doSetValuePowerBoard({target: {name: name, value: value}},"")
                                                                }}
                                                                height= {32}
                                                                error={!!errors?.capacitorUnit}
                                                                helperText={errors?.capacitorUnit?.message}
                                                                maxLength={5}
                                                            />
                                                        )}
                                                    />
                                                    <Typography>{')'}</Typography>
                                                </Stack>
                                                <Stack flexDirection='row' alignItems='center'>
                                                    <Typography variant='body2' width='75%'>{'(単位:'}</Typography>
                                                    <Controller
                                                        name="otherUnit"
                                                        control={control}
                                                        rules={
                                                            {
                                                                validate: value => doSetTotalCapacityUnit(value) === true || '単位が存在しません。'
                                                            }
                                                        }
                                                        render={({ field }) => (
                                                            <TextFieldOptions
                                                                {...field}
                                                                disabled={isDisabledEditValue||m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}
                                                                options={initialValue.totalCapacityUnitList}
                                                                setValue={(name: any, value: any) => {
                                                                    doSetValuePowerBoard({target: {name: name, value: value}},"")
                                                                }}
                                                                height= {32}
                                                                error={!!errors?.otherUnit}
                                                                helperText={errors?.otherUnit?.message}
                                                                maxLength={5}
                                                            />
                                                        )}
                                                    />
                                                    <Typography>{')'}</Typography>
                                                </Stack>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={3.5} pl={1}>
                                            <Stack spacing={0.3} pl={1}>
                                                <Stack flexDirection='row' alignItems='center'>
                                                    <Typography variant='body2' width='75%'>力率:</Typography>
                                                    <Controller
                                                        name="freezerPF"
                                                        control={control}
                                                        rules={{ 
                                                            pattern:{
                                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                                message: '数字を入力してください。'
                                                            },
                                                            validate: value => value >= 0 && value <= 1|| '数字 0 ~ 1 を入力してください。' ,
                                                            // maxLength: {
                                                            //     value: 5,
                                                            //     message: "最大長さ <= 5 を入力してください。"
                                                            // }
                                                        }}
                                                        render={
                                                            ({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    type="text"
                                                                    style={
                                                                        { backgroundColor: handleChangeColor() }
                                                                    }
                                                                    inputProps={{ readOnly: (isDisabledEditValue || m_bModePM|| processMode > MS_MODE_CHECKING || modeViewOnly),maxLength:5 }}
                                                                    InputProps={{ sx: { height: 32,color:(isDisabledEditValue || m_bModePM|| processMode > MS_MODE_CHECKING) || modeViewOnly?'grey':'black' } }}
                                                                    onChange={(e)=>{doSetValuePowerBoard(e,"IDC_FREEZER_PF")}}
                                                                    error={!!errors?.freezerPF}
                                                                    helperText={errors?.freezerPF?.message}
                                                                />
                                                            )
                                                        }
                                                    />
                                                </Stack>
                                                <Stack flexDirection='row' alignItems='center'>
                                                    <Typography variant='body2' width='75%'>力率:</Typography>
                                                    <Controller
                                                        name="elevatorPF"
                                                        control={control}
                                                        rules={{ 
                                                            pattern:{
                                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                                message: '数字を入力してください。'
                                                            },
                                                            validate: value => value >= 0 && value <= 1|| '数字 0 ~ 1 を入力してください。' ,
                                                            // maxLength: {
                                                            //     value: 5,
                                                            //     message: "最大長さ <= 5 を入力してください。"
                                                            // }
                                                        }}
                                                        render={
                                                            ({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    type="text"
                                                                    style={
                                                                        { backgroundColor: handleChangeColor() }
                                                                    }
                                                                    inputProps={{ readOnly: (isDisabledEditValue || m_bModePM|| processMode > MS_MODE_CHECKING || modeViewOnly) ,maxLength:5 }}
                                                                    InputProps={{ sx: { height: 32 ,color:(isDisabledEditValue || m_bModePM|| processMode > MS_MODE_CHECKING) || modeViewOnly?'grey':'black'} }}
                                                                    onChange={(e)=>{doSetValuePowerBoard(e,"IDC_ELEVATOR_PF")}}
                                                                    error={!!errors?.elevatorPF}
                                                                    helperText={errors?.elevatorPF?.message}
                                                                />
                                                            )
                                                        }
                                                    />
                                                </Stack>
                                                <Stack flexDirection='row' alignItems='center'>
                                                    <Typography variant='body2' width='75%'>力率:</Typography>
                                                    <Controller
                                                        name="airconPF"
                                                        control={control}
                                                        rules={{ 
                                                            pattern:{
                                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                                message: '数字を入力してください。'
                                                            },
                                                            validate: value => value >= 0 && value <= 1|| '数字 0 ~ 1 を入力してください。' ,
                                                            // maxLength: {
                                                            //     value: 5,
                                                            //     message: "最大長さ <= 5 を入力してください。"
                                                            // }
                                                        }}
                                                        render={
                                                            ({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    type="text"
                                                                    style={
                                                                        { backgroundColor: handleChangeColor() }
                                                                    }
                                                                    inputProps={{ readOnly: (isDisabledEditValue || m_bModePM|| processMode > MS_MODE_CHECKING || modeViewOnly) ,maxLength:5 }}
                                                                    InputProps={{ sx: { height: 32 ,color:(isDisabledEditValue || m_bModePM|| processMode > MS_MODE_CHECKING) || modeViewOnly?'grey':'black'} }}
                                                                    onChange={(e)=>{doSetValuePowerBoard(e,"IDC_AIRCON_PF")}}
                                                                    error={!!errors?.airconPF}
                                                                    helperText={errors?.airconPF?.message}
                                                                />
                                                            )
                                                        }
                                                    />
                                                </Stack>
                                                <Stack flexDirection='row' alignItems='center'>
                                                    <Typography variant='body2' width='75%'>力率:</Typography>
                                                    <Controller
                                                        name="hygienePF"
                                                        control={control}
                                                        rules={{ 
                                                            pattern:{
                                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                                message: '数字を入力してください。'
                                                            },
                                                            validate: value => value >= 0 && value <= 1|| '数字 0 ~ 1 を入力してください。' ,
                                                            // maxLength: {
                                                            //     value: 5,
                                                            //     message: "最大長さ <= 5 を入力してください。"
                                                            // }
                                                        }}
                                                        render={
                                                            ({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    type="text"
                                                                    style={
                                                                        { backgroundColor: handleChangeColor() }
                                                                    }
                                                                    inputProps={{ readOnly: (isDisabledEditValue || m_bModePM|| processMode > MS_MODE_CHECKING || modeViewOnly) ,maxLength:5 }}
                                                                    InputProps={{ sx: { height: 32 ,color:(isDisabledEditValue || m_bModePM|| processMode > MS_MODE_CHECKING) || modeViewOnly?'grey':'black' } }}
                                                                    onChange={(e)=>{doSetValuePowerBoard(e,"IDC_HYGIENE_PF")}}
                                                                    error={!!errors?.hygienePF}
                                                                    helperText={errors?.hygienePF?.message}
                                                                />
                                                            )
                                                        }
                                                    />
                                                </Stack>
                                                <Stack flexDirection='row' alignItems='center'>
                                                    <Typography variant='body2' width='75%'>力率:</Typography>
                                                    <Controller
                                                        name="capacitorPF"
                                                        control={control}
                                                        rules={{ 
                                                            pattern:{
                                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                                message: '数字を入力してください。'
                                                            },
                                                            validate: value => value >= 0 && value <= 1|| '数字 0 ~ 1 を入力してください。' ,
                                                            // maxLength: {
                                                            //     value: 5,
                                                            //     message: "最大長さ <= 5 を入力してください。"
                                                            // }
                                                        }}
                                                        render={
                                                            ({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    type="text"
                                                                    style={
                                                                        { backgroundColor: handleChangeColor() }
                                                                    }
                                                                    inputProps={{ readOnly: (isDisabledEditValue || m_bModePM|| processMode > MS_MODE_CHECKING|| modeViewOnly),maxLength:5 }}
                                                                    InputProps={{ sx: { height: 32 ,color:(isDisabledEditValue || m_bModePM|| processMode > MS_MODE_CHECKING) || modeViewOnly?'grey':'black'} }}
                                                                    onChange={(e)=>{doSetValuePowerBoard(e,"")}}
                                                                    error={!!errors?.capacitorPF}
                                                                    helperText={errors?.capacitorPF?.message}
                                                                />
                                                            )
                                                        }
                                                    />
                                                </Stack>
                                                <Stack flexDirection='row' alignItems='center'>
                                                    <Typography variant='body2' width='75%'>力率:</Typography>
                                                    <Controller
                                                        name="otherPF"
                                                        control={control}
                                                        rules={{ 
                                                            pattern:{
                                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                                message: '数字を入力してください。'
                                                            },
                                                            validate: value => value >= 0 && value <= 1|| '数字 0 ~ 1 を入力してください。' ,
                                                            // maxLength: {
                                                            //     value: 5,
                                                            //     message: "最大長さ <= 5 を入力してください。"
                                                            // }
                                                        }}
                                                        render={
                                                            ({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    type="text"
                                                                    style={
                                                                        { backgroundColor: handleChangeColor() }
                                                                    }
                                                                    inputProps={{ readOnly: (isDisabledEditValue || m_bModePM|| processMode > MS_MODE_CHECKING || modeViewOnly) , maxLength:5}}
                                                                    InputProps={{ sx: { height: 32 ,color:(isDisabledEditValue || m_bModePM|| processMode > MS_MODE_CHECKING) || modeViewOnly?'grey':'black'} }}
                                                                    onChange={(e)=>{doSetValuePowerBoard(e,"IDC_OTHER_PF")}}
                                                                    error={!!errors?.otherPF}
                                                                    helperText={errors?.otherPF?.message}
                                                                />
                                                            )
                                                        }
                                                    />
                                                </Stack>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </fieldset>
                            </Stack>
                            
                            {/* ModePM */}
                            {[Constant.ROLE_PM, Constant.ROLE_ADMIN].includes(userRole) &&
                            <>
                                <Stack mt={1}>
                                    <FormControlLabel
                                        control={
                                            <Controller
                                                name='makeDataInput'
                                                control={control}
                                                render={({ field }) => (
                                                    <Checkbox
                                                        size='small'
                                                        {...field}
                                                        checked={field.value}
                                                        onChange={handleChangeMakeDataInput}
                                                        style={{padding:'4px 4px 4px 9px'}}
                                                        disabled={m_bModePM || modeViewOnly }
                                                    />
                                                )}
                                            />
                                        }
                                        label={<Typography variant="body2"  color={m_bModePM?'grey':'black'}>銘板入力</Typography>}
                                    />
                                </Stack>
                                <Stack>
                                    <fieldset
                                        style={{ 
                                            width: '70%',
                                            color: !makeDataInput ? 'grey' : 'black', 
                                            border:'1px solid #AAAA'
                                        }}
                                    >
                                        <legend><Typography variant="body2"  >銘板</Typography></legend>
                                        <Stack spacing={0.3}>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="35%"  >製造者:</Typography>
                                                <FormControl fullWidth size='small'>
                                                    <Controller
                                                        name='makerName'
                                                        control={control}
                                                        render={({ field }: any) => (
                                                            <TextFieldOptions
                                                                {...field}
                                                                options={initialValue.makerNameList}
                                                                type='string'
                                                                size='small'
                                                                setValue={(name: any, value: any) => {
                                                                    handleChangeFormat({target: {name: name, value: value}})
                                                                }}
                                                                height= {32}
                                                                disabled={!makeDataInput||m_bModePM || modeViewOnly }

                                                            />
                                                        )}
                                                    />
                                                </FormControl>
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="35%"  >形式:</Typography>
                                                <Controller
                                                    name='type'
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type='text'
                                                            size='small'
                                                            onChange={handleChangeFormat}
                                                            inputProps={{
                                                                readOnly: !makeDataInput || m_bModePM || modeViewOnly,
                                                                style: { color: makeDataInput ? 'black' : 'grey' },
                                                            }}
                                                            style={{
                                                                color: 'black',
                                                            }}
                                                            InputProps={{ sx: { height: 32, backgroundColor: (makeDataInput && m_bModePM) || modeViewOnly?'floralwhite':makeDataInput? 'white' : 'floralwhite',color:modeViewOnly?"grey": makeDataInput ? 'black' : 'grey' } }}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="35%"  >製造年月:</Typography>
                                                <Grid container>
                                                    <Grid item xs={7}>
                                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                            <FormControl fullWidth size='small'>
                                                                <Controller
                                                                    name='makeYear'
                                                                    control={control}
                                                                    
                                                                    render={({ field }: any) => (
                                                                        <TextFieldOptions
                                                                            {...field}
                                                                            options={initialValue.makeYearList}
                                                                            type='string'
                                                                            size='small'
                                                                            setValue={(name: any, value: any) => {
                                                                                handleChangeFormat({target: {name: name, value: value}})
                                                                            }}
                                                                            height= {32}
                                                                            maxLength={8}
                                                                            disabled={!makeDataInput||m_bModePM || modeViewOnly}

                                                                        />
                                                                    )}
                                                                />
                                                            </FormControl>
                                                            <Typography variant="body2" pr={1.5} pl={0.5}>年</Typography>
                                                        </Stack>
                                                        
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                            <FormControl fullWidth size='small'>
                                                                <Controller
                                                                    name='makeMonth'
                                                                    control={control}
                                                                    render={({ field }: any) => (
                                                                        <TextFieldOptions
                                                                            {...field}
                                                                            options={initialValue.makeMonthList}
                                                                            type='string'
                                                                            size='small'
                                                                            setValue={(name: any, value: any) => {
                                                                                handleChangeFormat({target: {name: name, value: value}})
                                                                            }}
                                                                            height= {32}
                                                                            maxLength={2}
                                                                            disabled={!makeDataInput||m_bModePM || modeViewOnly }

                                                                        />
                                                                    )}
                                                                />
                                                            </FormControl>
                                                            <Typography variant="body2" pl={0.5}>月</Typography>
                                                        </Stack>
                                                    </Grid>
                                                </Grid>
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="35%"  >製造番号:</Typography>
                                                <Controller
                                                    name='makeNumber'
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type='text'
                                                            size='small'
                                                            onChange={handleChangeFormat}
                                                            inputProps={{
                                                                readOnly: !makeDataInput ||m_bModePM || modeViewOnly,
                                                                style: { color: makeDataInput ? 'black' : 'grey' },
                                                            }}
                                                            style={{
                                                                color:'black',
                                                            }}
                                                            InputProps={{ sx: { height: 32 , backgroundColor: (makeDataInput && m_bModePM) || modeViewOnly?'floralwhite':makeDataInput? 'white' : 'floralwhite',color:modeViewOnly?"grey":makeDataInput ? 'black' : 'grey'} }}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                        </Stack>
                                    </fieldset>
                                </Stack>
                            </>
                            }
                        </TabPanel>
                    </div>
                    <Stack direction='row' justifyContent="flex-end" spacing={2} mb={1} mr={3} mt={1}>
                        <Button variant="contained" type="submit" disabled={isDisableOKBtn || modeViewOnly }>OK</Button>
                        <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                    </Stack>
                </form >
            </div>
        </>
    );
};
export default MsPowerBoardEditDialog;


