import * as Model from "../models/Index";
import { DEFAULT_HEIGHT, ELEMENT_SIZE, POINTER_HEIGHT } from "../models/Constants";
import { elementNotInTransCenter } from "../statics";
import { useDispatch } from "react-redux";
import { getGraphLineData } from "../components/graph/graphMethods";
import { saveChartIntoChartData } from "./Actions";
import { MS_3WINDING, MS_TRANS1, MS_TRANS3, MS_TRANSSCOTT } from "../models/ElementKind";
import { actionCreators } from "./AppStore";


//#region Method
export const handleSaveAllControlsToDiagram = (
  state = [] as Model.DiagramModel[],
  data: Model.DiagramModel
) => {
  let checkNewData = true;
  state.map((item: Model.DiagramModel) => {
    if (item.tabId === data.tabId) {
      checkNewData = false;
      item.shape = data.shape;
      return item;
    }
    return item;
  });

  if (checkNewData) {
    state.push(data);
  }
  return state;
};

export const handleGetGraphLineData = async(dispatch:any, updatedShape:any, isCallDb: boolean, currentIDChartTab: any, id:any) => {
  await getGraphLineData(updatedShape, isCallDb, 0)
    .then((graphLineData : Model.GraphLineData) => {
      const controlCurves = updatedShape.chart.filter((lineInfo : Model.LineInfoModel) => lineInfo.lineType === Model.LineType.CONTROL_CURVE && lineInfo.controlId === id)
      // WARNING: this line doesnot sure to work after save into db and relaunch the web
      if(updatedShape.type === MS_3WINDING || updatedShape.type === MS_TRANS1 || updatedShape.type === MS_TRANS3 || updatedShape.type === MS_TRANSSCOTT)
      {       
        controlCurves.forEach((curve : Model.LineInfoModel) => {
          curve.data = graphLineData.lines.find(x=> x.id === curve.lineId)?.points || []      
        })
      }
      else if(controlCurves.length === graphLineData.lines.length)
      {       
        controlCurves.forEach((curve : Model.LineInfoModel) => {
          curve.data = graphLineData.lines.find(x=> x.id === curve.lineId)?.points || []      
        })
      }
      else
      {
        let props = {...controlCurves[0]}
        let newLines: Model.LineInfoModel[] = []
        graphLineData.lines.map(x=>
          newLines.push({...props, lineId: x.id, data : x.points})
        )
        updatedShape.chart = updatedShape.chart.filter((x:any)=> !(x.lineType === Model.LineType.CONTROL_CURVE && Number(x.controlId) === Number(id)))
        newLines.forEach(x=>  updatedShape.chart.push(x))
        dispatch(saveChartIntoChartData(updatedShape.chart, currentIDChartTab, updatedShape.id))
      }

      graphLineData.raw && (updatedShape.raw = graphLineData.raw)
      dispatch(actionCreators.redrawChart())
    })
    .catch(console.error)
}

export const handleSaveAllControlsToChartData = (
  state = [] as Model.ChartTabModel[],
  data: Model.DiagramModel
) => {
  data.shape.map((item:Model.ControlModel)=>{
    state.length > 1 && state.forEach(chartTab=>{
      const index = chartTab.shape.findIndex(shapes=> shapes.properties && Number(shapes.id) === Number(item.id))
      if(index != undefined && index >= 0)
      {
        item.properties = JSON.parse(JSON.stringify(item.properties))
        let temp = {...chartTab.shape[index].properties}
        temp?.details?.parts.forEach((x:any)=>{
          const data = item.properties?.details?.parts.find((part:any)=>part.partID == x.partID)
          if(data)
            x = {...x,...data}
        })
        let base = temp?.details?.base ? {...temp.details.base} : {}
        base = item.properties?.details?.base ? {...base,...item.properties.details.base, readBase: item.properties.details.base.readBase.ratedFlag !== undefined? {...item.properties.details.base.readBase}: {...base.readBase}} : base
        chartTab.shape[index].properties = temp?.details ? {...temp,...item.properties,
          details:{
            ...temp.details,...item.properties.details,
            base: {...base},
            parts: temp.details?.parts 
          }
        }
        :
        {...temp,...item.properties}
      }

    })
  })

  return state;
};

export const handleSetShapes = (
  state = [] as Model.DiagramModel[],
  controlChange : Model.ControlModel[],
  data: Model.DiagramModel
) => {
  let checkNewData = true;

  state.map((item: Model.DiagramModel) => {
    if (item.tabId === data.tabId) {
      checkNewData = false;
      for(const value of controlChange){
        let controlHaveToChange = data.shape.find((data)=> data.id == value.id);
        controlHaveToChange = value;
      }
      item.shape = data.shape;
      return item;
    }
    return item;
  });

  if (checkNewData) {
    state.push(data);
  }
  return state;
};


export const handleSaveAllControlsToChartTab = (
  state = [] as Model.ChartTabModel[],
  data: Model.ControlModel[],
  appStore: Model.AppStore,
) => {
  data.forEach((updatedShape : Model.ControlModel) => {
    state.forEach((chartTab : Model.ChartTabModel) => {
      const index = chartTab.shape.findIndex((control : Model.ControlModel) => control.properties && Number(control.id) === Number(updatedShape.id) && chartTab.tabId === appStore.currentIDChartTab)
      if (index != undefined && index >= 0) {
        updatedShape.properties = JSON.parse(JSON.stringify(updatedShape.properties))
        let temp = {...chartTab.shape[index].properties}
        temp?.details?.parts.forEach((x:any)=>{
          const data = updatedShape.properties?.details?.parts.find((part:any)=>part.partID == x.partID)
          if(data)
            x = {...x,...data}
        })
        let base = temp?.details?.base ? {...temp.details.base} : {}
        base = updatedShape.properties?.details?.base ? {...base,...updatedShape.properties.details.base} : base
        let properties = temp?.details ? {...temp,...updatedShape.properties,
          details:{
            ...temp.details,...updatedShape.properties.details,
            base: base,
            parts: temp.details?.parts 
          }
        }
        :
        {...temp,...updatedShape.properties}

        chartTab.shape[index] = {...updatedShape,properties: properties}
      } 
      else if (chartTab.tabId === appStore.currentIDChartTab) {
        chartTab.shape.push(updatedShape)
      }
    })
  })
  return [...state];
};

export const handleUpdateLineInfo = (
  chartList : Model.ChartTabModel[],
  currentIDChartTab : number,
  data : Model.LineStyleModel, 
  lineId : string | number, 
  controlId : string
) => {
    chartList.forEach((chartTab : Model.ChartTabModel) => {
      if(chartTab.tabId === currentIDChartTab){
        const foundShape = chartTab.shape.find((control : Model.ControlModel) => control.id === controlId)
        const foundLine = foundShape?.chart.find((lineInfo : Model.LineInfoModel) => lineInfo.lineId === lineId)
        foundLine?.tabMapping.set(currentIDChartTab, data)
      }
    })
  
  return chartList;
};

export const rotations = [
  {
    rotation: 0,
    offsetX: 0,
    offsetY: 0,
  },
  {
    rotation: -90,
    offsetX: 1,
    offsetY: 0,
  },
  {
    rotation: 90,
    offsetX: 0,
    offsetY: 1,
  },
];

export const updateRotation = (shapes: Model.ControlModel[]) => {
  shapes
    .filter(
      (shape) => shape.isSelected === true && shape.isRotationEnabled === true && shape.parentGroupId === null
    )
    .map((item) => {
      let currentRotationIndex = rotations.findIndex(
        (element) => element.rotation === item.rotation
      );
      currentRotationIndex === 2 && (currentRotationIndex = -1);
      item.type === "MS_CONNECT" && currentRotationIndex === 1 && (currentRotationIndex = -1);
      
      item.rotation = rotations[currentRotationIndex + 1].rotation;
      if(item.type === "MS_HVCB"){
        if(currentRotationIndex === 0)
          item.y -= ELEMENT_SIZE;
        else if(currentRotationIndex === 1)
          item.y += ELEMENT_SIZE;
      }
      item.offsetX = rotations[currentRotationIndex + 1].offsetX * item.width;
      item.offsetY = rotations[currentRotationIndex + 1].offsetY * item.height;

      return item;
    });
};

export const handleUpdateRotateForDiagramData = (
  state = [] as Model.DiagramModel[],
  tabId: number
) => {
  state.map((item) => {
    if (item.tabId === tabId) {
      updateRotation(item.shape);
      return item;
    }
    return item;
  });
  return state;
};

export const handleUpdateChartInControl = (
  state = [] as Model.DiagramModel[],
  updatedShape: Model.ControlModel,
  appStore: Model.AppStore
) => {
  state.map((diagram : Model.DiagramModel) => {
    if (diagram.tabId === appStore.currentIDDiagramTab) {
      const oldShape = diagram.shape.find((item) => Number(item.id) === Number(updatedShape.id)); 
      oldShape && (oldShape.chart = updatedShape.chart);
      oldShape && updatedShape.raw && (oldShape.raw = updatedShape.raw)
      return diagram;
    }
    return diagram;
  });

  return state;
};

export const handleDeleteDiagramTabByTabId = (
  state = [] as Model.DiagramModel[],
  tabId: number
) => {
  return state.filter((r) => r.tabId !== tabId);
};

export const handleSavePreStateDiagram = (
  preDiagramData: Model.StateModel[],
  diagramData = [] as Model.DiagramModel[],
  currentTabId: number,
  flag = false as boolean
) => {
  if (flag) {
    const dataOfCurrentTab = [...diagramData].filter(
      (item) => item.tabId === currentTabId
    )[0];
    if (dataOfCurrentTab) {
      const preIndex = preDiagramData.findIndex(
        (item) => item.idState === 1 && item.tabId === currentTabId
      );
      preIndex !== -1
        ? (preDiagramData[preIndex] = {
            ...preDiagramData[preIndex],
            shape: dataOfCurrentTab.shape,
          })
        : preDiagramData.push({
            idState: 1,
            tabId: currentTabId,
            shape: dataOfCurrentTab.shape,
          });
    }
  }
  return preDiagramData;
};

export const handleUndoForDiagram = (
  preDiagramData: Model.StateModel[],
  diagramData: Model.DiagramModel[],
  currentTabId: number
) => {
  const check = preDiagramData.find((item) => item.tabId === currentTabId);
  if (!check || check.shape.length === 0) return diagramData;
  const indexTabDiagramData = diagramData.findIndex(
    (item) => item.tabId === currentTabId
  );
  const indexTabPreDiagramData = preDiagramData.findIndex(
    (item) => item.tabId === currentTabId
  );
  if (indexTabDiagramData !== -1 && indexTabPreDiagramData !== -1) {
    diagramData[indexTabDiagramData].shape =
      preDiagramData[indexTabPreDiagramData].shape;
  }
  return diagramData;
};

export const handleUndoForPreDiagram = (
  preDiagramData: Model.StateModel[],
  currentTabId: number
) => {
  const index = preDiagramData.findIndex((item) => item.tabId === currentTabId);
  index !== -1 &&
    (preDiagramData[index] = {
      idState: 1,
      tabId: currentTabId,
      shape: [],
    });
  return preDiagramData;
};

export const handleSelectControl = (
  diagramList = [] as Model.DiagramModel[],
  tabId: number,
  idControl: string,
  isMultiple: boolean,
) => {
    diagramList.map((diagramItem) => {
        if (diagramItem.tabId === tabId) {
            const clickedShape = diagramItem.shape.find((r) => r.id === idControl)
            if(isMultiple){                                 // press shift && click
                if (clickedShape) {
                    if(clickedShape.isSelected == true)     // if shape is selected -> set shape to not selected
                        clickedShape.isSelected = false;    
                    else                                    // shape is not selected -> set shape to selected
                        clickedShape.isSelected = true;
                }
            }
            else {                                          // only click              
                const clickedShape = diagramItem.shape.find((r) => r.id === idControl && r.isSelected == true);
                if(!clickedShape){
                    diagramItem.shape.forEach((shapeItem) => {
                        if (shapeItem.id === idControl) {
                            shapeItem.isSelected = true;
                        }else{
                            shapeItem.isSelected = false;
                        }
                    });
                }
            }
            return diagramItem;
        }
        return diagramItem;
    });
  return  diagramList;
};

export const handleUnSelectAllControls = (
  diagramList = [] as Model.DiagramModel[],
  tabId: number
) => {
  diagramList.map((diagramItem) => {
    if (diagramItem.tabId === tabId) {
        diagramItem.shape.forEach((shapeItem) => {
          shapeItem.isSelected = false;
        });
      return diagramItem;
    }
    return diagramItem;
  });
  return  diagramList;
};

export const handleGroupInElement = (
  state = [] as Model.DiagramModel[],
  tabId: number,
  {data, newGroupEleIds} : any
) => {
  state.map((item) => {
    if (item.tabId === tabId) {
      item.shape.forEach((shapeItem) => {
        if (newGroupEleIds.includes(shapeItem.id)) {
          shapeItem.parentGroupId === null && (shapeItem.parentGroupId = data.groupId);
        }
      });
      return item;
    }
    return item;
  });
  return state;
};

export const handleGroup = (
  elementGroups : any,
  {data, newGroupEleIds} : any
) => {
  newGroupEleIds.forEach((id : string) => {
    elementGroups.byEleId[id] ? elementGroups.byEleId[id].push(data.groupId) : elementGroups.byEleId[id] = [data.groupId]
  })
  Object.values(elementGroups.byId).forEach((g : any) => {
    if(data.groups && data.groups.length > 0){
      if (data.groups.includes(g.groupId)) {
        g.parentGroupId = data.groupId
      }
    }else{
      g.parentGroupId = g.parentGroupId;
    }
  })
  delete data.groups;
  return {
    ...elementGroups,
    byId: {
      ...elementGroups.byId,
      [data.groupId] : data
    }
  }
};

export const handleUnGroupInElement = (
  state = [] as Model.DiagramModel[],
  tabId: number,
  elementGroups : any,
  toDeleteGroupIds: number[]
) => {
  state.map((item) => {
    if (item.tabId === tabId) {

      for (const toDeleteGroupId of toDeleteGroupIds) { 
        const elementIds = elementGroups.byId[toDeleteGroupId]?.elementIds ?? []
        item.shape.forEach((shapeItem) => {
          if (elementIds.includes(shapeItem.id)) {
            shapeItem.parentGroupId === toDeleteGroupId && (shapeItem.parentGroupId = null);
          }
        });
      }

      return item;
    }
    return item;
  });
  return state;
};

export const handleUnGroup = (
  elementGroups : any,
  toDeleteGroupIds: number[]
) => {
  const newElementGroups = {...elementGroups}

  for (const toDeleteGroupId of toDeleteGroupIds) {
    const deletedGroupData = newElementGroups.byId[toDeleteGroupId]
    const elementIds = deletedGroupData?.elementIds
    Object.values(newElementGroups.byId).forEach((g : any) => {
      if (g.parentGroupId === deletedGroupData.groupId) {
        g.parentGroupId = deletedGroupData.parentGroupId
      }
    })
    delete newElementGroups.byId[toDeleteGroupId]

    elementIds.forEach((elementId : string) => {
      if (newElementGroups.byEleId[elementId].length <= 1) {
        delete newElementGroups.byEleId[elementId]
      } else {
        newElementGroups.byEleId[elementId].pop()
      }
    })
  }

  return newElementGroups
};

export const handleCopy = (
  { elementGroups, tcGroups }: Model.AppStore,
  diagramData = [] as Model.DiagramModel[],
  tabId: number
) => {
  let shapes = diagramData.find((item) => item.tabId === tabId)?.shape ?? [];
  if (shapes.length > 0)
    shapes = shapes.filter((r) => r.isSelected === true)
  let normalizedShapes = [...shapes] as any
  let eleGroups = {} as any
  let tcEleGroups = {} as any
  let diagrams: Model.DiagramModel[] = []
  let tmpElementGroups = {} as any
  if (tabId === 1){
    tmpElementGroups = elementGroups
  } else {
    //copy in transcenter
    let mainDiagramData = diagramData.find((e: any) => e.tabId === 1)!
    let transCenter: any =
            mainDiagramData.shape.find(
                (e: any) => e.properties?.tabId === tabId
            )
    tmpElementGroups = tcGroups[transCenter.id]
  }
  if(shapes.length > 0){
    shapes.forEach((control: Model.ControlModel) => {
      // copy ele groups
      if (
        tmpElementGroups.byEleId[control.id] 
        && tmpElementGroups.byEleId[control.id].length > 0
      ) {
        const groupIds = tmpElementGroups.byEleId[control.id]
        control.parentGroupId = groupIds[0] //smallest group
        for (const groupId of groupIds) {
          eleGroups[groupId] = tmpElementGroups.byId[groupId]
        }
      }

      // transcenter
      if (control.type === 'MS_TRANSCENTER'){
        let tmpDiagram = diagramData.find(
          (e: Model.DiagramModel) => e.tabId === control.properties.tabId
        )
        diagrams.push(tmpDiagram!)
        // copy tc ele groups
        if (
          tcGroups[control.id]
        ){
          tcEleGroups[control.id] = tcGroups[control.id].byId
        }
      }
    })
  };
  return {shapes: normalizedShapes, diagrams, eleGroups, tcEleGroups};
}

// export const handleCut = (
//   diagramData = [] as Model.DiagramModel[],
//   tabId: number
// ) => {
//   const shapes = diagramData.find((item) => item.tabId === tabId)?.shape;
//   return shapes ? shapes.filter((r) => r.isSelected === true) : [];
// };

export const getShapesAfterCutOrRemove = (
  diagramData = [] as Model.DiagramModel[],
  tabId: number
) => {
  const index = diagramData.findIndex((item) => item.tabId === tabId);
  return index === -1
    ? {
        tabId: tabId,
        shape: [],
        maxId: 0,
        typesMaxId: new Map()
      }
    : {
        tabId: tabId,
        shape: diagramData[index].shape.filter((r) => r.isSelected === false),
      };
};

export const getMaxId = (data: Model.ControlModel[]) => {
  return data.length > 0
    ? Math.max(...data.map((element) => Number(element.id)))
    : 0;
};

const getTabMaxId = (data: Model.DiagramModel[]) => {
  return data.length > 0
    ? Math.max(...data.map((e: Model.DiagramModel) => Number(e.tabId)))
    : 0;
};

export const createPasteData = (
  datas: Model.ControlModel[],
  copyDiagramData: Model.DiagramModel[],
  elementMaxId: number,
  tabMaxId: number,
  typesMaxId: Map<string, number>,
  pointerPosition: Model.Coordinates
) => {
  const minX = Math.min(...datas.map((element) => element.x));
  const minY = Math.min(...datas.map((element) => element.y));
  let distance = {
    x: datas[0].x - minX,
    y: datas[0].y - minY,
  };

  let newIds = {} as any;
  let newDiagram: Model.DiagramModel[] = []

  const pasteShapes = datas.map((shape: Model.ControlModel, index) => {
    elementMaxId += 1
    const newId = (elementMaxId).toString();

    let typeNewId = 1
    if (typesMaxId.has(shape.type))
      typeNewId = typesMaxId.get(shape.type)! + 1
    typesMaxId.set(shape.type, typeNewId)
    
    let props = {...shape.properties}
    if (shape.type === 'MS_TRANSCENTER'){
      // TransCenter
      let tmpDiagtam: Model.DiagramModel | undefined = 
        copyDiagramData.find((e) => e.tabId === props.tabId)

      if (tmpDiagtam){
        let newData = {...tmpDiagtam}

        tabMaxId += 1
        newData.tabId = tabMaxId

        newData.shape = newData.shape.map((shape2: Model.ControlModel) => {
          elementMaxId += 1

          let typeNewId = 1
          if (typesMaxId.has(shape2.type))
            typeNewId = typesMaxId.get(shape2.type)! + 1
          typesMaxId.set(shape2.type, typeNewId) 

          return {
            ...shape2,
            id: elementMaxId.toString()
          }
        })

        newDiagram.push(newData)
      }

      props.tabId = tabMaxId;
    }

    newIds = {
      ...newIds,
      [shape.id]: newId
    }
    index !== 0 &&
      (distance = {
        x: datas[index].x - minX,
        y: datas[index].y - minY,
      });
    return {
      ...shape,
      id: newId,
      isSelected: true,
      x: distance.x + pointerPosition.x,
      y:
        distance.y +
        pointerPosition.y +
        (Math.abs(shape.rotation) === 90 ? +POINTER_HEIGHT : 0),
      properties: props
    };
  });

  return { 
    newIds, 
    pasteShapes,
    newDiagram,
    newElementMaxId: elementMaxId,
    newTypesMaxId: typesMaxId
  }
};

export const handlePaste = (
  diagramData: Model.DiagramModel[],
  copyShapes: Model.ControlModel[],
  copyDiagramData: Model.DiagramModel[],
  elementMaxId: number,
  typesMaxId: Map<string, number>,
  pasteInfo: { tabId: number; pointerPosition: Model.Coordinates }
) => {
  const index = diagramData.findIndex(
    (diagram) => diagram.tabId === pasteInfo.tabId
  );
  if (index === -1) {
    return {
      shapesData: {
        tabId: pasteInfo.tabId,
        shape: [],
        maxId: 0,
        typesMaxId: new Map()
      },
      newDiagram: [],
      elementMaxId,
      typesMaxId
    };
  }

  if (copyShapes.length === 0) {
    return {
      shapesData: {
        tabId: pasteInfo.tabId,
        shape: diagramData[index].shape,
      },
      newDiagram: [],
      elementMaxId,
      typesMaxId
    };
  }

  let shapes = [] as Model.ControlModel[];
  diagramData[index].shape.length > 0 && (shapes = diagramData[index].shape);
  const tabMaxId = getTabMaxId(diagramData);

  let tmpCopyShape = [...copyShapes]
  if (pasteInfo.tabId !== 1){
    // TransCenter
    tmpCopyShape = tmpCopyShape.filter((e: any) => !elementNotInTransCenter.includes(e.type))
    if (tmpCopyShape.length === 0) {
      return {
        shapesData: {
          tabId: pasteInfo.tabId,
          shape: diagramData[index].shape,
        },
        newDiagram: [],
        elementMaxId,
        typesMaxId
      };
    }
  }

  const pasteData = createPasteData(
    tmpCopyShape,
    [...copyDiagramData],
    elementMaxId,
    tabMaxId,
    typesMaxId,
    pasteInfo.pointerPosition
  );

  return {
    newIds: pasteData.newIds,
    shapesData: {
      tabId: pasteInfo.tabId,
      shape: [...shapes, ...pasteData.pasteShapes],
    },
    newDiagram: pasteData.newDiagram,
    elementMaxId: pasteData.newElementMaxId,
    typesMaxId: pasteData.newTypesMaxId
  };
};

export const handlePasteGroup = (prevGroupStack: string[][], copyShapes: Model.ControlModel[], newIds: any) => {

  const copyIds = copyShapes.map((control: Model.ControlModel) => control.id)
  const pasteIds = [] as any
  prevGroupStack.forEach((groupIds: string[]) => {
    if (copyIds.some((id: string) => groupIds.includes(id))) {
      pasteIds.push(groupIds);
    }
  })
  
  const updateIds = pasteIds.map((groupIds: any) => {
    return groupIds.map((id: any) => {
      id = newIds[id]
      return id;
    })
  })
   
  return updateIds
} 

export const handleRemove = (
  diagramData = [] as Model.DiagramModel[],
  tabId: number
) => {
  return getShapesAfterCutOrRemove(diagramData, tabId);
};

export const updatePointerPosition = (
  copyShapes: Model.ControlModel[],
  prePointerPosition: Model.Coordinates
) => {
  if (copyShapes.length === 0) return prePointerPosition;
  // get maxId but not use to create anything because just call func createPasteData to get coordinate
  const maxId = getMaxId(copyShapes);
  const {pasteShapes} = createPasteData(copyShapes, [], maxId, 1, new Map(), prePointerPosition);
  const heightShape =
  pasteShapes.find(
      (item) => item.y === Math.max(...pasteShapes.map((element) => element.y))
    )?.height ?? DEFAULT_HEIGHT;
  return {
    x: Math.min(...pasteShapes.map((element) => element.x)),
    y:
      Math.round(
        Math.max(...pasteShapes.map((element) => element.y)) / POINTER_HEIGHT
      ) *
        POINTER_HEIGHT +
      Math.round(heightShape / POINTER_HEIGHT) * POINTER_HEIGHT,
  };
};

export const updatePropertiesOfControl = (
  state = [] as Model.DiagramModel[],
  data: object,
  id: string,
  appStore: Model.AppStore
) => {
  state.map((item: Model.DiagramModel) => {
    if (item.tabId === appStore.currentIDDiagramTab) { // currentIDDiagramTab
      const indexOfTextBox = item.shape.findIndex((shape) => shape.id === id);
      indexOfTextBox >= 0 && (item.shape[indexOfTextBox].properties = data);
      return item;
    }
    else if(item.shape.find((x:Model.ControlModel)=> x.id === id) != undefined)
    {
      const indexOfTextBox = item.shape.findIndex((shape) => shape.id === id);
      indexOfTextBox >= 0 && (item.shape[indexOfTextBox].properties = data);
      return item;
    }
    return item;
  });
  return state;
};

export const updatePropertiesOfChart = (
  state = [] as Model.ChartTabModel[],
  data: object,
  id: string,
  appStore: Model.AppStore
) => {
    state.map((item: Model.ChartTabModel) => {
    //if (item.tabId === appStore.currentIDChartTab) {
      const indexOfTextBox = item.shape.findIndex((shape) => Number(shape.id) === Number(id));
      if(indexOfTextBox >= 0)
      item.shape[indexOfTextBox].properties = data;
      
      return item;
    //}
    //return item;
  });
  return state;
};

export const updatePropertiesOfTransCenter = (
  state = [] as Model.DiagramModel[],
  data: object,
  id: string
) => {
  state.map((item: Model.DiagramModel) => {
    if (item.tabId === 1) {
      const indexOfTextBox = item.shape.findIndex((shape) => shape.id == id);
      item.shape[indexOfTextBox].properties = data;
      return item;
    }
    return item;
  });
  return state;
};

export const addUserCurveOfControl = (
  state = [] as Model.DiagramModel[],
  data: any,
  id: string,
  appStore: Model.AppStore
) => {
  state.forEach((diagram: Model.DiagramModel) => {
    if (diagram.tabId === appStore.currentIDDiagramTab) {
      const shape = diagram.shape.find((shape) => shape.id === id);
      if (shape) {
        shape.properties.listUser = [...shape.properties.listUser, data]
      }
    }
  });
  return state;
};

export const updateUserCurveOfControl = (
  state = [] as Model.DiagramModel[],
  data: any,
  id: string,
  appStore: Model.AppStore
) => {
  state.forEach((diagram: Model.DiagramModel) => {
    if (diagram.tabId === appStore.currentIDDiagramTab) {
      const shape = diagram.shape.find((shape) => shape.id === id);
      if (shape) {
        const foundUserCurve = shape.properties.listUser.findIndex((curve : any) => curve.userCurveId === data.userCurveId || curve.user_curve_id === data.userCurveId)        
        if (foundUserCurve >= 0) { // update
          shape.properties.listUser[foundUserCurve] = data                   
        }
      }
    }
  });
  return state;
};

export const updateUserCurveOfControlChart = (
  state = [] as Model.ChartTabModel[],
  data: any,
  id: string,
  appStore: Model.AppStore
) => {
  state.forEach((chartTab: Model.ChartTabModel) => {
    if (chartTab.shape.find((item:Model.ControlModel)=>item.id === id)) {
      const shape = chartTab.shape.find((shape) => shape.id === id);
      if (shape) {
        const foundUserCurve = shape.properties.listUser.findIndex((curve : any) => curve.userCurveId === data.userCurveId || curve.user_curve_id === data.userCurveId)        
        if (foundUserCurve >= 0) { // update
          shape.properties.listUser[foundUserCurve] = data                   
        }
      }
    }
  });
  return state;
};

export const deleteUserCurveOfControl = (
  state = [] as Model.DiagramModel[],
  data: any,
  id: string,
  appStore: Model.AppStore
) => {
  state.forEach((diagram: Model.DiagramModel) => {
    if (diagram.tabId === appStore.currentIDDiagramTab) {
      const shape = diagram.shape.find((shape) => shape.id === id);
      if (shape) {
        const foundUserCurve = shape.properties.listUser.findIndex((curve : any) => curve.userCurveId === data.userCurveId || curve.user_curve_id === data.userCurveId)        
        if (foundUserCurve >= 0) { // update
          shape.properties.listUser.splice(foundUserCurve, 1)                   
        }
      }
    }
  });
  return state;
};

export const updateControlHeight = (
  state = [] as Model.DiagramModel[],
  height: number,
  id: string,
  x: number,
  y: number,
  offsetY: number,
  appStore: Model.AppStore
) => {
  state.map((item: Model.DiagramModel) => {
    if (item.tabId === appStore.currentIDDiagramTab) {
      const indexOfTextBox = item.shape.findIndex((shape) => shape.id === id);
      item.shape[indexOfTextBox].height = height;
      item.shape[indexOfTextBox].x = x;
      item.shape[indexOfTextBox].y = y;
      item.shape[indexOfTextBox].offsetY = offsetY;
      return item;
    }
    return item;
  });
  return state;
};

export const updateControlWidth = (
  state = [] as Model.DiagramModel[],
  payload: any,
  appStore: Model.AppStore
) => {
  state.map((item: Model.DiagramModel) => {
    if (item.tabId === appStore.currentIDDiagramTab) {
      const indexOfTextBox = item.shape.findIndex((shape) => shape.id === payload.id);
      item.shape[indexOfTextBox].width = payload.width;
      return item;
    }
    return item;
  });
  return state;
};
//#endregion