import { store } from "../..";
import { BOTTOM_CENTER, DIRECT_NONE, LEFT_CENTER, MS_ROTATE_NORMAL, RIGHT_CENTER, TOP_CENTER } from "../../models/Constants";
import { MS_2E, MS_3WINDING, MS_ARRESTOR, MS_BUSBAR, MS_BUSDUCT, MS_CABLEHEAD, MS_CAPACITOR, MS_CONNECT, MS_CONTACTOR, MS_CT, MS_DS, MS_EARTH, MS_FUSE, MS_GENERATOR, MS_HVCB, MS_IMPEDANCE, MS_INV, MS_LBS, MS_LIGHTBOARD, MS_LOAD, MS_LOADCENTER, MS_LVCB, MS_MOTOR, MS_MOTOR_GROUP, MS_MVCB, MS_POWERBOARD, MS_REACTOR, MS_RELAY, MS_RELAY51, MS_SOURCE, MS_TEXT, MS_THERMAL, MS_THERMAL_CT, MS_TRANS1, MS_TRANS3, MS_TRANSCENTER, MS_TRANSSCOTT, MS_VCT, MS_VT, MS_WIRE, MS_ZCT, MS_ZEROCOND } from "../../models/ElementKind";
import { CalcPoint, CdPoint, ControlModel, MsZerocondModel, Point } from "../../models/Index";
import { FLOW_VIEW_EVERY_ELEMENT, FLOW_VIEW_ZEROCOND_ONLY, MS_MODE_POWER_FLOW, VOLT_SIDE_PRIMARY, VOLT_SIDE_SECONDARY } from "../../statics";
import { doGetTextFlowsResult as doGetTextFlowsResult2E } from "../../utils/2EFunction";
import { doGetTextFlowsResult as doGetTextFlowsResultCT } from "../../utils/CTFunction";
import { doGetTextFlowsResult as doGetTextFlowsResultContactor } from "../../utils/ContactorFuction";
import { doGetTextFlowsResult as doGetTextFlowsResultDS } from "../../utils/DSFunction";
import { doGetConnectElement } from "../../utils/ElementList";
import { doGetTextFlowsResult as doGetTextFlowsResultFUSE } from "../../utils/FUSEFunction";
import { doGetTextFlowsResult as doGetTextFlowsResultHVCB } from "../../utils/HVCBFunction";
import { doGetTextFlowsResult as doGetTextFlowsResultINV } from "../../utils/INVFunction";
import { doGetTextFlowsResult as doGetTextFlowsResultLBS } from "../../utils/LBSFunction";
import { doGetTextFlowsResult as doGetTextFlowsResultLVCB } from "../../utils/LVCBFunction";
import { doGetTextFlowsResult as doGetTextFlowsResultLoad } from "../../utils/LoadFunction";
import { doGetTextFlowsResult as doGetTextFlowsResultMVCB } from "../../utils/MVCBFunction";
import { doGetTextFlowsResult as doGetTextFlowsResultMotorGrp } from "../../utils/MotorGrpFunction";
import { doGetTextFlowsResult as doGetTextFlowsResultRELAY } from "../../utils/RELAYFunction";
import { doGetTextFlowsResult as doGetTextFlowsResultTHERMAL } from "../../utils/THERMALFunction";
import { doGetTextFlowsResult as doGetTextFlowsResultThermalCT } from "../../utils/ThermalCTFunction";
import { doGetTextFlowsResult as doGetTextFlowsResultTrans1 } from "../../utils/Trans1Function";
import { doGetTextFlowsResult as doGetTextFlowsResultTrans3 } from "../../utils/Trans3Function";
import { doGetTextFlowsResult as doGetTextFlowsResultTransCenter } from "../../utils/TransCenterFunction";
import { doGetTextFlowsResult as doGetTextFlowsResultVCT } from "../../utils/VCTFunction";
import { doGetTextFlowsResult as doGetTextFlowsResultVT } from "../../utils/VTFunction";
import { doGetTextFlowsResult as doGetTextFlowsResultZCT } from "../../utils/ZCTFunction";
import { doGetTextFlowsResult as doGetTextFlowsResultBusBar } from "../../utils/busbarFunction";
import { doGetTextFlowsResult as doGetTextFlowsResultBusDuct } from "../../utils/busductFunction";
import { doGetIksymDirectMG, doGetValueVoltage } from "../../utils/calcPointFunction";
import { doGetTextFlowsResult as doGetTextFlowsResultCapacitor } from "../../utils/capacitorFunction";
import { doGetSupplyEle, isCanSetViewFlowResult, isCanSwitchOnOff, isSPhaseCircuitEle, isSwitchOff, isViewFlowResultEle, isZeroResultFlowEle } from "../../utils/element";
import { doGetTextFlowsResult as doGetTextFlowsResultGenerator} from "../../utils/generatorFunction";
import { doGetTextFlowsResult as doGetTextFlowsResultImpedance } from "../../utils/impedance";
import { doGetTextFlowsResult as doGetTextFlowsResultLightboard } from "../../utils/lightBoard";
import { doGetTextFlowsResult as doGetTextFlowsResultMotor } from "../../utils/motorFunction";
import { doGetTextFlowsResult as doGetTextFlowsResultPowerboard} from "../../utils/powerBoard";
import { doGetTextFlowsResult as doGetTextFlowsResultReactor } from "../../utils/reactorFunction";
import { doGetTextFlowsResult as doGetTextFlowsResultSource} from "../../utils/source";
import { doGetTextFlowsResult as doGetTextFlowsResultTransscott } from "../../utils/transscottFunction";
import { doGetTextFlowsResult as doGetTextFlowsResult3winding } from "../../utils/windingPartFunction";
import { doGetTextFlowsResult as doGetTextFlowsResultWire } from "../../utils/wireFunction";

//CObjGraph::DoGetScaleYmin
export const doGetScaleYmin = () =>
{
	//TODO Load societyYmin / normalYmin
	let dScalY;
	if (store.getState().app.diagram.chartData.find(x => x.tabId === store.getState().app.diagram.currentIDChartTab)?.isJapaneseElectricMode) {
		dScalY = 0.01;//props.societyYmin;
	}
	else {
		dScalY = store.getState().app.projectData.defYMin;//props.normalYmin;
	}
	return dScalY;
}

//CDCView2::DoSetPosCurvePointDirect
export const doSetPosCurvePointDirect = (pCalcPoint:CalcPoint, calcPoint:number) =>
{
	let dIk3 = doGetIksymDirectMG(pCalcPoint);
	if (dIk3 <= 0) {
		return;
	}

	
	pCalcPoint.pointTopX = dIk3 * doGetValueVoltage(pCalcPoint);
	pCalcPoint.pointTopY = 0.03;

	pCalcPoint.pointBottomX = dIk3 * doGetValueVoltage(pCalcPoint);
	pCalcPoint.pointBottomY =  0.000001 //  store.getState().app.diagram.listGraph.find((x:any)=>x.graphNo == store.getState().app.diagram.currentIDChartTab).normalYMin;

	let points:Point [] = [{x: pCalcPoint.pointTopX, y: pCalcPoint.pointTopY}, {x: pCalcPoint.pointBottomX, y: pCalcPoint.pointBottomY}] ;
	
	return {
		id: calcPoint === 0 ? (pCalcPoint.ele3WindingPartKind == 2? "CALC_POINT_0_2" : (pCalcPoint.ele3WindingPartKind == 3? "CALC_POINT_0_3" : "CALC_POINT_0")) : (pCalcPoint.ele3WindingPartKind == 2? "CALC_POINT_1_2" : (pCalcPoint.ele3WindingPartKind == 3? "CALC_POINT_1_3" : "CALC_POINT_1")),
		points: points
	}
}

//CDCView1::DoDrawFlowArrow
export const doDrawFlowArrow = (pElement:ControlModel, processMode:any, nDirect:number) =>
{
	if (!doGetSupplyEle(pElement) ||
		(isCanSwitchOnOff(pElement) && isSwitchOff(pElement)) ||
		isZeroResultFlowEle(pElement)) {
		return;
	}

	let result = {
		arrowActiveAtTop: false,
		arrowReactiveAtTop: false,
		arrowActiveReactiveAtTop: false,
		arrowActiveReactiveAtRight: false,
		arrowActiveReactiveAtLeft: false,
		arrowActiveAtRight: false,
		arrowActiveAtLeft: false,
		arrowReactiveAtRight: false,
		arrowReactiveAtLeft: false,
		drawActiveNoneDirect: false,
		drawReactiveNoneDirect: false,
		drawOtherDirect: false,
		element: pElement
	}

	let props = pElement.properties

	switch(pElement.type) {
	case MS_SOURCE:			// 電源
		{
			if (nDirect == DIRECT_NONE) {
				if (props.activePower >= 0) {
					result.drawActiveNoneDirect = true;
				}
				else {
					result.drawActiveNoneDirect = true;
					result.arrowActiveAtTop = true;
				}

				if (props.reactivePower >= 0) {
					result.drawReactiveNoneDirect = true;
				}
				else {
					result.drawReactiveNoneDirect = true;
					result.arrowReactiveAtTop = true;
				}
			}
			else if (nDirect == BOTTOM_CENTER || nDirect == RIGHT_CENTER) {
				result.drawOtherDirect = true;
				result.arrowActiveReactiveAtTop = true;
			}
			else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
				result.drawOtherDirect = true;
			}
		}
		break;
	case MS_GENERATOR:		// 発電機
		{
			if (nDirect == DIRECT_NONE) {
				if (props.activePower >= 0) {
					result.drawActiveNoneDirect = true;
				}
				else {
					result.drawActiveNoneDirect = true;
					result.arrowActiveAtTop = true;
				}

				if (props.reactivePower >= 0) {
					result.drawReactiveNoneDirect = true;
				}
				else {
					result.drawReactiveNoneDirect = true;
					result.arrowReactiveAtTop = true;
				}
			}
			else if (nDirect == BOTTOM_CENTER || nDirect == RIGHT_CENTER) {
				result.drawOtherDirect = true;
				result.arrowActiveReactiveAtTop = true;
			}
			else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
				result.drawOtherDirect = true;
			}
		}
		break;
	case MS_TRANS3:			// 三相変圧器
		{
			if (nDirect == DIRECT_NONE) {
				if (props.lineActiveFlow1 >= 0) {
					result.drawActiveNoneDirect = true;
				}
				else if (props.lineActiveFlow2 > 0) {
					result.drawActiveNoneDirect = true;
					result.arrowActiveAtTop = true;
				}

				if (props.lineReactiveFlow1 >= 0) {
					result.drawReactiveNoneDirect = true;
				}
				else if (props.lineReactiveFlow2 > 0) {
					result.drawReactiveNoneDirect = true;
					result.arrowReactiveAtTop = true
				}
			}
			else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
				if (props.lineActiveFlow1 > 0) {
					result.drawOtherDirect = true;
				}
				else {
					result.drawOtherDirect = true;
					result.arrowActiveReactiveAtTop = true;
				}
			}
			else if (nDirect == BOTTOM_CENTER || nDirect == RIGHT_CENTER) {
				if (props.lineActiveFlow2 > 0) {
					result.drawOtherDirect = true;
					result.arrowActiveReactiveAtTop = true;
				}
				else {
					result.drawOtherDirect = true;
				}
			}
		}
		break;
	case MS_TRANSCENTER:	// 変台
		{
			if (nDirect == DIRECT_NONE) {
				if (props.activePower >= 0) {
					result.drawActiveNoneDirect = true;
				}
				else {
					result.drawActiveNoneDirect = true;
					result.arrowActiveAtTop = true
				}
				if (props.reactivePower >= 0) {
					result.drawReactiveNoneDirect = true;
				}
				else {
					result.drawReactiveNoneDirect = true;
					result.arrowReactiveAtTop = true;
				}
			}
			else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
				result.drawOtherDirect = true;
			}
		}
		break;
	case MS_3WINDING:		// 三巻線変圧器
		{
			if (nDirect == DIRECT_NONE) {
				if (props.partPrimary.lineActiveFlow1 >= 0) {
					result.drawActiveNoneDirect = true
				}
				else if (props.partPrimary.lineActiveFlow2 > 0) {
					result.drawActiveNoneDirect = true
					result.arrowActiveAtTop = true
				}
				if (props.partPrimary.lineReactiveFlow1 >= 0) {
					result.drawReactiveNoneDirect = true
				}
				else if (props.partPrimary.lineReactiveFlow2 > 0) {
					result.drawReactiveNoneDirect = true
					result.arrowReactiveAtTop = true
				}

				if (props.partSecondary.lineActiveFlow2 >= 0) {
					result.drawActiveNoneDirect = true
				}
				else if (props.partSecondary.lineActiveFlow1 > 0) {
					result.drawActiveNoneDirect = true
					result.arrowActiveAtTop = true
				}
				if (props.partSecondary.lineReactiveFlow2 >= 0) {
					result.drawReactiveNoneDirect = true
				}
				else if (props.partSecondary.lineReactiveFlow1 > 0) {
					result.drawReactiveNoneDirect = true
					result.arrowReactiveAtTop = true
				}

				if (props.partThird.lineActiveFlow2 >= 0) {
					result.drawActiveNoneDirect = true
				}
				else if (props.partThird.lineActiveFlow1 > 0) {
					result.drawActiveNoneDirect = true
					result.arrowActiveAtTop = true
				}
				if (props.partThird.lineReactiveFlow2 >= 0) {
					result.drawReactiveNoneDirect = true
				}
				else if (props.partThird.lineReactiveFlow1 > 0) {
					result.drawReactiveNoneDirect = true
					result.arrowReactiveAtTop = true
				}
			}
			else if (nDirect == TOP_CENTER) {
				if (props.partPrimary.lineActiveFlow1 > 0 ) {
					result.drawOtherDirect = true
				}
				else {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtTop = true
				}
			}
			else if (nDirect == RIGHT_CENTER) {
				if (props.partSecondary.lineActiveFlow1 > 0 ) {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtTop = true
				}
				else {
					result.drawOtherDirect = true
				}
			}
			else if (nDirect == LEFT_CENTER) {
				if (props.partThird.lineActiveFlow1 > 0 ) {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtTop = true
				}
				else {
					result.drawOtherDirect = true
				}
			}
		}
		break;
	case MS_TRANS1:			// 単相変圧器
		{
			if (nDirect == DIRECT_NONE) {
				result.drawReactiveNoneDirect = true
				result.drawActiveNoneDirect = true
			}
			else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
				result.drawOtherDirect = true
			}
		}
		break;
	case MS_TRANSSCOTT:		// スコット結線変圧器
		{
			if (nDirect == DIRECT_NONE) {
				result.drawActiveNoneDirect = true;
				result.drawReactiveNoneDirect = true
			}
			else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
				result.drawOtherDirect = true;
			}
		}
		break;
	case MS_DS:				// 断路器
		{
			if (nDirect == DIRECT_NONE) {
				if (pElement.rotation == MS_ROTATE_NORMAL) {
					if (props.lineActiveFlow1 >= 0) {
						result.drawActiveNoneDirect = true;
					}
					else if (props.lineActiveFlow2 > 0) {
						result.drawActiveNoneDirect = true;
						result.arrowActiveAtTop = true
					}
					if (props.lineReactiveFlow1 >= 0) {
						result.drawReactiveNoneDirect = true
					}
					else if (props.lineReactiveFlow2 > 0) {
						result.drawReactiveNoneDirect = true
						result.arrowReactiveAtTop = true
					}
				}
				else {
					if (props.lineActiveFlow1 >= 0) {
						result.drawActiveNoneDirect = true;
						result.arrowActiveAtRight = true;
					}
					else if (props.lineActiveFlow2 > 0) {
						result.drawActiveNoneDirect = true;
						result.arrowActiveAtLeft = true
					}
					if (props.lineReactiveFlow1 >= 0) {
						result.drawReactiveNoneDirect = true;
						result.arrowReactiveAtRight = true
					}
					else if (props.lineReactiveFlow2 > 0) {
						result.drawReactiveNoneDirect = true;
						result.arrowReactiveAtLeft = true
					}
				}
			}
			else if (nDirect == TOP_CENTER) {
				if (props.lineActiveFlow1 > 0) {
					result.drawOtherDirect = true
				}
				else {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtTop = true
				}
			}
			else if (nDirect == LEFT_CENTER) {
				if (props.lineActiveFlow1 > 0) {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtRight = true
				}
				else {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtLeft = true
				}
			}
			else if (nDirect == BOTTOM_CENTER) {
				if (props.lineActiveFlow2 > 0) {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtTop = true
				}
				else {
					result.drawOtherDirect = true
				}
			}
			else if (nDirect == RIGHT_CENTER) {
				if (props.lineActiveFlow2 > 0) {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtLeft = true
				}
				else {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtRight = true
				}
			}
		}
		break;
	case MS_LBS:			// 開閉器
		{
			if (nDirect == DIRECT_NONE) {
				if (pElement.rotation == MS_ROTATE_NORMAL) {
					if (props.lineActiveFlow1 >= 0) {
						result.drawActiveNoneDirect = true
					}
					else if (props.lineActiveFlow2 > 0) {
						result.drawActiveNoneDirect = true
						result.arrowActiveAtTop = true
					}
					if (props.lineReactiveFlow1 >= 0) {
						result.drawReactiveNoneDirect = true
					}
					else if (props.lineReactiveFlow2 > 0) {
						result.drawReactiveNoneDirect = true
						result.arrowReactiveAtTop = true
					}
				}
				else {
					if (props.lineActiveFlow1 >= 0) {
						result.drawActiveNoneDirect = true
						result.arrowActiveAtRight = true
					}
					else if (props.lineActiveFlow2 > 0) {
						result.drawActiveNoneDirect = true
						result.arrowActiveAtLeft = true
					}
					if (props.lineReactiveFlow1 >= 0) {
						result.drawReactiveNoneDirect = true;
						result.arrowReactiveAtRight = true
					}
					else if (props.lineReactiveFlow2 > 0) {
						result.drawReactiveNoneDirect = true;
						result.arrowReactiveAtLeft = true
					}
				}
			}
			else if (nDirect == TOP_CENTER) {
				if (props.lineActiveFlow1 > 0) {
					result.drawOtherDirect = true
				}
				else {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtTop = true
				}
			}
			else if (nDirect == LEFT_CENTER) {
				if (props.lineActiveFlow1 > 0) {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtRight = true
				}
				else {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtLeft = true
				}
			}
			else if (nDirect == BOTTOM_CENTER) {
				if (props.lineActiveFlow2 > 0) {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtTop = true
				}
				else {
					result.drawOtherDirect = true
				}
			}
			else if (nDirect == RIGHT_CENTER) {
				if (props.lineActiveFlow2 > 0) {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtLeft = true
				}
				else {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtRight = true
				}
			}
		}
		break;
	case MS_MVCB:			// 遮断器
		{
			if (nDirect == DIRECT_NONE) {
				if (pElement.rotation == MS_ROTATE_NORMAL) {
					if (props.lineActiveFlow1 >= 0) {
						result.drawActiveNoneDirect = true
					}
					else if (props.lineActiveFlow2 > 0) {
						result.drawActiveNoneDirect = true
						result.arrowActiveAtTop = true
					}
					if (props.lineReactiveFlow1 >= 0) {
						result.drawReactiveNoneDirect = true
					}
					else if (props.lineReactiveFlow2 > 0) {
						result.drawReactiveNoneDirect = true
						result.arrowReactiveAtTop = true
					}
				}
				else {
					if (props.lineActiveFlow1 >= 0) {
						result.drawActiveNoneDirect = true
						result.arrowActiveAtRight = true
					}
					else if (props.lineActiveFlow2 > 0) {
						result.drawActiveNoneDirect = true
						result.arrowActiveAtLeft = true
					}
					if (props.lineReactiveFlow1 >= 0) {
						result.drawReactiveNoneDirect = true
						result.arrowReactiveAtRight = true
					}
					else if (props.lineReactiveFlow2 > 0) {
						result.drawReactiveNoneDirect = true
						result.arrowReactiveAtLeft = true
					}
				}
			}
			else if (nDirect == TOP_CENTER) {
				if (props.lineActiveFlow1 > 0) {
					result.drawOtherDirect = true
				}
				else {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtTop = true
				}
			}
			else if (nDirect == LEFT_CENTER) {
				if (props.lineActiveFlow1 > 0) {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtRight = true
				}
				else {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtLeft = true
				}
			}
			else if (nDirect == BOTTOM_CENTER) {
				if (props.lineActiveFlow2 > 0) {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtTop = true
				}
				else {
					result.drawOtherDirect = true
				}
			}
			else if (nDirect == RIGHT_CENTER) {
				if (props.lineActiveFlow2 > 0) {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtLeft = true
				}
				else {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtRight = true
				}
			}
		}
		break;
	case MS_CONTACTOR:		// 電磁接触器
		{
			if (nDirect == DIRECT_NONE) {
				if (pElement.rotation == MS_ROTATE_NORMAL) {
					if (props.lineActiveFlow1 >= 0) {
						result.drawActiveNoneDirect = true
					}
					else if (props.lineActiveFlow2 > 0) {
						result.drawActiveNoneDirect = true
						result.arrowActiveAtTop = true
					}
					if (props.lineReactiveFlow1 >= 0) {
						result.drawReactiveNoneDirect = true
					}
					else if (props.lineReactiveFlow2 > 0) {
						result.drawReactiveNoneDirect = true
						result.arrowReactiveAtTop = true
					}
				}
				else {
					if (props.lineActiveFlow1 >= 0) {
						result.drawActiveNoneDirect = true
						result.arrowActiveAtRight = true
					}
					else if (props.lineActiveFlow2 > 0) {
						result.drawActiveNoneDirect = true
						result.arrowActiveAtLeft = true
					}
					if (props.lineReactiveFlow1 >= 0) {
						result.drawReactiveNoneDirect = true
						result.arrowReactiveAtRight = true
					}
					else if (props.lineReactiveFlow2 > 0) {
						result.drawReactiveNoneDirect = true
						result.arrowReactiveAtLeft = true
					}
				}
			}
			else if (nDirect == TOP_CENTER) {
				if (props.lineActiveFlow1 > 0) {
					result.drawOtherDirect = true
				}
				else {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtTop = true
				}
			}
			else if (nDirect == LEFT_CENTER) {
				if (props.lineActiveFlow1 > 0) {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtRight = true
				}
				else {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtLeft = true
				}
			}
			else if (nDirect == BOTTOM_CENTER) {
				if (props.lineActiveFlow2 > 0) {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtTop = true
				}
				else {
					result.drawOtherDirect = true
				}
			}
			else if (nDirect == RIGHT_CENTER) {
				if (props.lineActiveFlow2 > 0) {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtLeft = true
				}
				else {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtRight = true
				}
			}
		}
		break;
	case MS_HVCB:			// 過電流継電器
		{
			if (nDirect == DIRECT_NONE) {
				if (pElement.rotation == MS_ROTATE_NORMAL) {
					if (props.lineActiveFlow1 >= 0) {
						result.drawActiveNoneDirect = true
					}
					else if (props.lineActiveFlow2 > 0) {
						result.drawActiveNoneDirect = true
						result.arrowActiveAtTop = true
					}
					if (props.lineReactiveFlow1 >= 0) {
						result.drawReactiveNoneDirect = true
					}
					else if (props.lineReactiveFlow2 > 0) {
						result.drawReactiveNoneDirect = true
						result.arrowReactiveAtTop = true
					}
				}
				else {
					if (props.lineActiveFlow1 >= 0) {
						result.drawActiveNoneDirect = true
						result.arrowActiveAtRight = true
					}
					else if (props.lineActiveFlow2 > 0) {
						result.drawActiveNoneDirect = true
						result.arrowActiveAtLeft = true
					}
					if (props.lineReactiveFlow1 >= 0) {
						result.drawReactiveNoneDirect = true
						result.arrowReactiveAtRight = true
					}
					else if (props.lineReactiveFlow2 > 0) {
						result.drawReactiveNoneDirect = true
						result.arrowReactiveAtLeft = true
					}
				}
			}
			else if (nDirect == TOP_CENTER) {
				if (props.lineActiveFlow1 > 0) {
					result.drawOtherDirect = true
				}
				else {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtTop = true
				}
			}
			else if (nDirect == LEFT_CENTER) {
				if (props.lineActiveFlow1 > 0) {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtRight = true
				}
				else {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtLeft = true
				}
			}
			else if (nDirect == BOTTOM_CENTER) {
				if (props.lineActiveFlow2 > 0) {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtTop = true
				}
				else {
					result.drawOtherDirect = true
				}
			}
			else if (nDirect == RIGHT_CENTER) {
				if (props.lineActiveFlow2 > 0) {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtLeft = true
				}
				else {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtRight = true
				}
			}
		}
		break;
	case MS_RELAY51:		// 継電器_51
		{
			if (nDirect == DIRECT_NONE) {
				if (props.lineActiveFlow1 >= 0) {
					result.drawActiveNoneDirect = true
				}
				else if (props.lineActiveFlow2 > 0) {
					result.drawActiveNoneDirect = true
					result.arrowActiveAtTop = true
				}
				if (props.lineReactiveFlow1 >= 0) {
					result.drawReactiveNoneDirect = true
				}
				else if (props.lineReactiveFlow2 > 0) {
					result.drawReactiveNoneDirect = true
					result.arrowReactiveAtTop = true
				}
			}
			else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
				if (props.lineActiveFlow1 > 0) {
					result.drawOtherDirect = true
				}
				else {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtTop = true
				}
			}
			else if (nDirect == BOTTOM_CENTER || nDirect == RIGHT_CENTER) {
				if (props.lineActiveFlow2 > 0) {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtTop = true
				}
				else {
					result.drawOtherDirect = true
				}
			}
		}
		break;
	case MS_FUSE:			// ヒューズ
		{
			if (nDirect == DIRECT_NONE) {
				if (pElement.rotation == MS_ROTATE_NORMAL) {
					if (props.lineActiveFlow1 >= 0) {
						result.drawActiveNoneDirect = true
					}
					else if (props.lineActiveFlow2 > 0) {
						result.drawActiveNoneDirect = true
						result.arrowActiveAtTop = true
					}
					if (props.lineReactiveFlow1 >= 0) {
						result.drawReactiveNoneDirect = true
					}
					else if (props.lineReactiveFlow2 > 0) {
						result.drawReactiveNoneDirect = true
						result.arrowReactiveAtTop = true
					}
				}
				else {
					if (props.lineActiveFlow1 >= 0) {
						result.drawActiveNoneDirect = true
						result.arrowActiveAtRight = true
					}
					else if (props.lineActiveFlow2 > 0) {
						result.drawActiveNoneDirect = true
						result.arrowActiveAtLeft = true
					}
					if (props.lineReactiveFlow1 >= 0) {
						result.drawReactiveNoneDirect = true
						result.arrowReactiveAtRight = true
					}
					else if (props.lineReactiveFlow2 > 0) {
						result.drawReactiveNoneDirect = true
						result.arrowReactiveAtLeft = true
					}
				}
			}
			else if (nDirect == TOP_CENTER) {
				if (props.lineActiveFlow1 > 0) {
					result.drawOtherDirect = true
				}
				else {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtTop = true
				}
			}
			else if (nDirect == LEFT_CENTER) {
				if (props.lineActiveFlow1 > 0) {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtRight = true
				}
				else {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtLeft = true
				}
			}
			else if (nDirect == BOTTOM_CENTER) {
				if (props.lineActiveFlow2 > 0) {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtTop = true
				}
				else {
					result.drawOtherDirect = true
				}
			}
			else if (nDirect == RIGHT_CENTER) {
				if (props.lineActiveFlow2 > 0) {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtLeft = true
				}
				else {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtRight = true
				}
			}
		}
		break;
	case MS_2E:				// ２Ｅリレー
		{
			if (nDirect == DIRECT_NONE) {
				if (props.lineActiveFlow1 >= 0) {
					result.drawActiveNoneDirect = true
				}
				else if (props.lineActiveFlow2 > 0) {
					result.drawActiveNoneDirect = true
					result.arrowActiveAtTop = true
				}
				if (props.lineReactiveFlow1 >= 0) {
					result.drawReactiveNoneDirect = true
				}
				else if (props.lineReactiveFlow2 > 0) {
					result.drawReactiveNoneDirect = true
					result.arrowReactiveAtTop = true
				}
			}
			else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
				if (props.lineActiveFlow1 > 0) {
					result.drawOtherDirect = true
				}
				else {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtTop = true
				}
			}
			else if (nDirect == BOTTOM_CENTER || nDirect == RIGHT_CENTER) {
				if (props.lineActiveFlow2 > 0) {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtTop = true
				}
				else {
					result.drawOtherDirect = true
				}
			}
		}
		break;
	case MS_ZEROCOND:		// 母線
		break;
	case MS_WIRE:			// ワイヤ
		{
			if (nDirect == DIRECT_NONE) {
				if (pElement.rotation == MS_ROTATE_NORMAL) {
					if (props.lineActiveFlow1 >= 0) {
						result.drawActiveNoneDirect = true
					}
					else if (props.lineActiveFlow2 > 0) {
						result.drawActiveNoneDirect = true
						result.arrowActiveAtTop = true
					}
					if (props.lineReactiveFlow1 >= 0) {
						result.drawReactiveNoneDirect = true
					}
					else if (props.lineReactiveFlow2 > 0) {
						result.drawReactiveNoneDirect = true
						result.arrowReactiveAtTop = true
					}
				}
				else {
					if (props.lineActiveFlow1 >= 0) {
						result.drawActiveNoneDirect = true
						result.arrowActiveAtRight = true
					}
					else if (props.lineActiveFlow2 > 0) {
						result.drawActiveNoneDirect = true
						result.arrowActiveAtLeft = true
					}
					if (props.lineReactiveFlow1 >= 0) {
						result.drawReactiveNoneDirect = true
						result.arrowReactiveAtRight = true
					}
					else if (props.lineReactiveFlow2 > 0) {
						result.drawReactiveNoneDirect = true
						result.arrowReactiveAtLeft = true
					}
				}
			}
			else if (nDirect == TOP_CENTER) {
				if (props.lineActiveFlow1 > 0) {
					result.drawOtherDirect = true
				}
				else {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtTop = true
				}
			}
			else if (nDirect == LEFT_CENTER) {
				if (props.lineActiveFlow1 > 0) {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtRight = true
				}
				else {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtLeft = true
				}
			}
			else if (nDirect == BOTTOM_CENTER) {
				if (props.lineActiveFlow2 > 0) {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtTop = true
				}
				else {
					result.drawOtherDirect = true
				}
			}
			else if (nDirect == RIGHT_CENTER) {
				if (props.lineActiveFlow2 > 0) {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtLeft = true
				}
				else {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtRight = true
				}
			}
		}
		break;
	case MS_BUSBAR:			// ブスバー
		{
			if (nDirect == DIRECT_NONE) {
				if (pElement.rotation == MS_ROTATE_NORMAL) {
					if (props.lineActiveFlow1 >= 0) {
						result.drawActiveNoneDirect = true
					}
					else if (props.lineActiveFlow2 > 0) {
						result.drawActiveNoneDirect = true
						result.arrowActiveAtTop = true
					}
					if (props.lineReactiveFlow1 >= 0) {
						result.drawReactiveNoneDirect = true
					}
					else if (props.lineReactiveFlow2 > 0) {
						result.drawReactiveNoneDirect = true
						result.arrowReactiveAtTop = true
					}
				}
				else {
					if (props.lineActiveFlow1 >= 0) {
						result.drawActiveNoneDirect = true
						result.arrowActiveAtRight = true
					}
					else if (props.lineActiveFlow2 > 0) {
						result.drawActiveNoneDirect = true
						result.arrowActiveAtLeft = true
					}
					if (props.lineReactiveFlow1 >= 0) {
						result.drawReactiveNoneDirect = true
						result.arrowReactiveAtRight = true
					}
					else if (props.lineReactiveFlow2 > 0) {
						result.drawReactiveNoneDirect = true
						result.arrowReactiveAtLeft = true
					}
				}
			}
			else if (nDirect == TOP_CENTER) {
				if (props.lineActiveFlow1 > 0) {
					result.drawOtherDirect = true
				}
				else {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtTop = true
				}
			}
			else if (nDirect == LEFT_CENTER) {
				if (props.lineActiveFlow1 > 0) {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtRight = true
				}
				else {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtLeft = true
				}
			}
			else if (nDirect == BOTTOM_CENTER) {
				if (props.lineActiveFlow2 > 0) {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtTop = true
				}
				else {
					result.drawOtherDirect = true
				}
			}
			else if (nDirect == RIGHT_CENTER) {
				if (props.lineActiveFlow2 > 0) {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtLeft = true
				}
				else {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtRight = true
				}
			}
		}
		break;
	case MS_IMPEDANCE:		// インピーダンス
		{
			if (nDirect == DIRECT_NONE) {
				if (pElement.rotation == MS_ROTATE_NORMAL) {
					if (props.lineActiveFlow1 >= 0) {
						result.drawActiveNoneDirect = true
					}
					else if (props.lineActiveFlow2 > 0) {
						result.drawActiveNoneDirect = true
						result.arrowActiveAtTop = true
					}
					if (props.lineReactiveFlow1 >= 0) {
						result.drawReactiveNoneDirect = true
					}
					else if (props.lineReactiveFlow2 > 0) {
						result.drawReactiveNoneDirect = true
						result.arrowReactiveAtTop = true
					}
				}
				else {
					if (props.lineActiveFlow1 >= 0) {
						result.drawActiveNoneDirect = true
						result.arrowActiveAtRight = true
					}
					else if (props.lineActiveFlow2 > 0) {
						result.drawActiveNoneDirect = true
						result.arrowActiveAtLeft = true
					}
					if (props.lineReactiveFlow1 >= 0) {
						result.drawReactiveNoneDirect = true
						result.arrowReactiveAtRight = true
					}
					else if (props.lineReactiveFlow2 > 0) {
						result.drawReactiveNoneDirect = true
						result.arrowReactiveAtLeft = true
					}
				}
			}
			else if (nDirect == TOP_CENTER) {
				if (props.lineActiveFlow1 > 0) {
					result.drawOtherDirect = true
				}
				else {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtTop = true
				}
			}
			else if (nDirect == LEFT_CENTER) {
				if (props.lineActiveFlow1 > 0) {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtRight = true
				}
				else {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtLeft = true
				}
			}
			else if (nDirect == BOTTOM_CENTER) {
				if (props.lineActiveFlow2 > 0) {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtTop = true
				}
				else {
					result.drawOtherDirect = true
				}
			}
			else if (nDirect == RIGHT_CENTER) {
				if (props.lineActiveFlow2 > 0) {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtLeft = true
				}
				else {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtRight = true
				}
			}
		}
		break;
	case MS_BUSDUCT:		// バスダクト
		{
			if (nDirect == DIRECT_NONE) {
				if (pElement.rotation == MS_ROTATE_NORMAL) {
					if (props.lineActiveFlow1 >= 0) {
						result.drawActiveNoneDirect = true
					}
					else if (props.lineActiveFlow2 > 0) {
						result.drawActiveNoneDirect = true
						result.arrowActiveAtTop = true
					}
					if (props.lineReactiveFlow1 >= 0) {
						result.drawReactiveNoneDirect = true
					}
					else if (props.lineReactiveFlow2 > 0) {
						result.drawReactiveNoneDirect = true
						result.arrowReactiveAtTop = true
					}
				}
				else {
					if (props.lineActiveFlow1 >= 0) {
						result.drawActiveNoneDirect = true
						result.arrowActiveAtRight = true
					}
					else if (props.lineActiveFlow2 > 0) {
						result.drawActiveNoneDirect = true
						result.arrowActiveAtLeft = true
					}
					if (props.lineReactiveFlow1 >= 0) {
						result.drawReactiveNoneDirect = true
						result.arrowReactiveAtRight = true
					}
					else if (props.lineReactiveFlow2 > 0) {
						result.drawReactiveNoneDirect = true
						result.arrowReactiveAtLeft = true
					}
				}
			}
			else if (nDirect == TOP_CENTER) {
				if (props.lineActiveFlow1 > 0) {
					result.drawOtherDirect = true
				}
				else {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtTop = true
				}
			}
			else if (nDirect == LEFT_CENTER) {
				if (props.lineActiveFlow1 > 0) {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtRight = true
				}
				else {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtLeft = true
				}
			}
			else if (nDirect == BOTTOM_CENTER) {
				if (props.lineActiveFlow2 > 0) {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtTop = true
				}
				else {
					result.drawOtherDirect = true
				}
			}
			else if (nDirect == RIGHT_CENTER) {
				if (props.lineActiveFlow2 > 0) {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtLeft = true
				}
				else {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtRight = true
				}
			}
		}
		break;
	case MS_REACTOR:		// 限流リアクトル
		{
			if (nDirect == DIRECT_NONE) {
				if (pElement.rotation == MS_ROTATE_NORMAL) {
					if (props.lineActiveFlow1 >= 0) {
						result.drawActiveNoneDirect = true
					}
					else if (props.lineActiveFlow2 > 0) {
						result.drawActiveNoneDirect = true
						result.arrowActiveAtTop = true
					}
					if (props.lineReactiveFlow1 >= 0) {
						result.drawReactiveNoneDirect = true
					}
					else if (props.lineReactiveFlow2 > 0) {
						result.drawReactiveNoneDirect = true
						result.arrowReactiveAtTop = true
					}
				}
				else {
					if (props.lineActiveFlow1 >= 0) {
						result.drawActiveNoneDirect = true
						result.arrowActiveAtRight = true
					}
					else if (props.lineActiveFlow2 > 0) {
						result.drawActiveNoneDirect = true
						result.arrowActiveAtLeft = true
					}
					if (props.lineReactiveFlow1 >= 0) {
						result.drawReactiveNoneDirect = true
						result.arrowReactiveAtRight = true
					}
					else if (props.lineReactiveFlow2 > 0) {
						result.drawReactiveNoneDirect = true
						result.arrowReactiveAtLeft = true
					}
				}
			}
			else if (nDirect == TOP_CENTER) {
				if (props.lineActiveFlow1 > 0) {
					result.drawOtherDirect = true
				}
				else {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtTop = true
				}
			}
			else if (nDirect == LEFT_CENTER) {
				if (props.lineActiveFlow1 > 0) {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtRight = true
				}
				else {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtLeft = true
				}
			}
			else if (nDirect == BOTTOM_CENTER) {
				if (props.lineActiveFlow2 > 0) {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtTop = true
				}
				else {
					result.drawOtherDirect = true
				}
			}
			else if (nDirect == RIGHT_CENTER) {
				if (props.lineActiveFlow2 > 0) {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtLeft = true
				}
				else {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtRight = true
				}
			}
		}
		break;
	case MS_LVCB:			// 低圧遮断器
		{
			if (nDirect == DIRECT_NONE) {
				if (pElement.rotation == MS_ROTATE_NORMAL) {
					if (props.lineActiveFlow1 >= 0) {
						result.drawActiveNoneDirect = true
					}
					else if (props.lineActiveFlow2 > 0) {
						result.drawActiveNoneDirect = true
						result.arrowActiveAtTop = true
					}
					if (props.lineReactiveFlow1 >= 0) {
						result.drawReactiveNoneDirect = true
					}
					else if (props.lineReactiveFlow2 > 0) {
						result.drawReactiveNoneDirect = true
						result.arrowReactiveAtTop = true
					}
				}
				else {
					if (props.lineActiveFlow1 >= 0) {
						result.drawActiveNoneDirect = true
						result.arrowActiveAtRight = true
					}
					else if (props.lineActiveFlow2 > 0) {
						result.drawActiveNoneDirect = true
						result.arrowActiveAtLeft = true
					}
					if (props.lineReactiveFlow1 >= 0) {
						result.drawReactiveNoneDirect = true
						result.arrowReactiveAtRight = true
					}
					else if (props.lineReactiveFlow2 > 0) {
						result.drawReactiveNoneDirect = true
						result.arrowReactiveAtLeft = true
					}
				}
			}
			else if (nDirect == TOP_CENTER) {
				if (props.lineActiveFlow1 > 0) {
					result.drawOtherDirect = true
				}
				else {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtTop = true
				}
			}
			else if (nDirect == LEFT_CENTER) {
				if (props.lineActiveFlow1 > 0) {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtRight = true
				}
				else {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtLeft = true
				}
			}
			else if (nDirect == BOTTOM_CENTER) {
				if (props.lineActiveFlow2 > 0) {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtTop = true
				}
				else {
					result.drawOtherDirect = true
				}
			}
			else if (nDirect == RIGHT_CENTER) {
				if (props.lineActiveFlow2 > 0) {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtLeft = true
				}
				else {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtRight = true
				}
			}
		}
		break;
	case MS_THERMAL:		// サーマルリレー
		{
			if (nDirect == DIRECT_NONE) {
				if (props.lineActiveFlow1 >= 0) {
					result.drawActiveNoneDirect = true
				}
				else if (props.lineActiveFlow2 > 0) {
					result.drawActiveNoneDirect = true
					result.arrowActiveAtTop = true
				}
				if (props.lineReactiveFlow1 >= 0) {
					result.drawReactiveNoneDirect = true
				}
				else if (props.lineReactiveFlow2 > 0) {
					result.drawReactiveNoneDirect = true
					result.arrowReactiveAtTop = true
				}
			}
			else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
				if (props.lineActiveFlow1 > 0) {
					result.drawOtherDirect = true
				}
				else {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtTop = true
				}
			}
			else if (nDirect == BOTTOM_CENTER || nDirect == RIGHT_CENTER) {
				if (props.lineActiveFlow2 > 0) {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtTop = true
				}
				else {
					result.drawOtherDirect = true
				}
			}
		}
		break;
	case MS_THERMAL_CT:		// 過負荷継電器
		{
			if (nDirect == DIRECT_NONE) {
				if (props.lineActiveFlow1 >= 0) {
					result.drawActiveNoneDirect = true
				}
				else if (props.lineActiveFlow2 > 0) {
					result.drawActiveNoneDirect = true
					result.arrowActiveAtTop = true
				}
				if (props.lineReactiveFlow1 >= 0) {
					result.drawReactiveNoneDirect = true
				}
				else if (props.lineReactiveFlow2 > 0) {
					result.drawReactiveNoneDirect = true
					result.arrowReactiveAtTop = true
				}
			}
			else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
				if (props.lineActiveFlow1 > 0) {
					result.drawOtherDirect = true
				}
				else {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtTop = true
				}
			}
			else if (nDirect == BOTTOM_CENTER || nDirect == RIGHT_CENTER) {
				if (props.lineActiveFlow2 > 0) {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtTop = true
				}
				else {
					result.drawOtherDirect = true
				}
			}
		}
		break;
	case MS_MOTOR_GROUP:	// 電動機群
		{
			if (nDirect == DIRECT_NONE) {
				result.drawActiveNoneDirect = true
				result.drawReactiveNoneDirect = true
			}
			else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
				result.drawOtherDirect = true
			}
		}
		break;
	case MS_MOTOR:			// 電動機
		{
			if (nDirect == DIRECT_NONE) {
				result.drawActiveNoneDirect = true
				result.drawReactiveNoneDirect = true
			}
			else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
				result.drawOtherDirect = true
			}
		}
		break;
	case MS_LOAD:			// 負荷
		{
			if (nDirect == DIRECT_NONE) {
				result.drawActiveNoneDirect = true
				result.drawReactiveNoneDirect = true
			}
			else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
				result.drawOtherDirect = true
			}
		}
		break;
	case MS_CAPACITOR:		// コンデンサ
		{
			if (nDirect == DIRECT_NONE) {
				result.drawActiveNoneDirect = true
				if (props.lineReactiveFlow1 < 0) {
					result.drawReactiveNoneDirect = true
					result.arrowReactiveAtTop = true
				}
				else {
					result.drawReactiveNoneDirect = true
				}
			}
			else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
				result.drawOtherDirect = true
			}
		}
		break;
	case MS_LOADCENTER:		// ロードセンタ
		break;
	case MS_VCT:			// 計器用変成器
		{
			if (nDirect == DIRECT_NONE) {
				if (props.lineActiveFlow1 >= 0) {
					result.drawActiveNoneDirect = true
				}
				else if (props.lineActiveFlow2 > 0) {
					result.drawActiveNoneDirect = true
					result.arrowActiveAtTop = true
				}
				if (props.lineReactiveFlow1 >= 0) {
					result.drawReactiveNoneDirect = true
				}
				else if (props.lineReactiveFlow2 > 0) {
					result.drawReactiveNoneDirect = true
					result.arrowReactiveAtTop = true
				}
			}
			else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
				if (props.lineActiveFlow1 > 0) {
					result.drawOtherDirect = true
				}
				else {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtTop = true
				}
			}
			else if (nDirect == BOTTOM_CENTER || nDirect == RIGHT_CENTER) {
				if (props.lineActiveFlow2 > 0) {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtTop = true
				}
				else {
					result.drawOtherDirect = true
				}
			}
		}
		break;
	case MS_INV:			// インバーター
		{
			if (nDirect == DIRECT_NONE) {
				if (props.lineActiveFlow1 >= 0) {
					result.drawActiveNoneDirect = true
				}
				else if (props.lineActiveFlow2 > 0) {
					result.drawActiveNoneDirect = true
					result.arrowActiveAtTop = true
				}
				if (props.lineReactiveFlow1 >= 0) {
					result.drawReactiveNoneDirect = true
				}
				else if (props.lineReactiveFlow2 > 0) {
					result.drawReactiveNoneDirect = true
					result.arrowReactiveAtTop = true
				}
			}
			else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
				if (props.lineActiveFlow1 > 0) {
					result.drawOtherDirect = true
				}
				else {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtTop = true
				}
			}
			else if (nDirect == BOTTOM_CENTER || nDirect == RIGHT_CENTER) {
				if (props.lineActiveFlow2 > 0) {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtTop = true
				}
				else {
					result.drawOtherDirect = true
				}
			}
		}
		break;
	case MS_RELAY:			// リレー
		{
			if (nDirect == DIRECT_NONE) {
				if (props.lineActiveFlow1 >= 0) {
					result.drawActiveNoneDirect = true
				}
				else if (props.lineActiveFlow2 > 0) {
					result.drawActiveNoneDirect = true
					result.arrowActiveAtTop = true
				}
				if (props.lineReactiveFlow1 >= 0) {
					result.drawReactiveNoneDirect = true
				}
				else if (props.lineReactiveFlow2 > 0) {
					result.drawReactiveNoneDirect = true
					result.arrowReactiveAtTop = true
				}
			}
			else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
				if (props.lineActiveFlow1 > 0) {
					result.drawOtherDirect = true
				}
				else {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtTop = true
				}
			}
			else if (nDirect == BOTTOM_CENTER || nDirect == RIGHT_CENTER) {
				if (props.lineActiveFlow2 > 0) {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtTop = true
				}
				else {
					result.drawOtherDirect = true
				}
			}
		}
		break;
	case MS_ARRESTOR:		// 避雷器
		break;
	case MS_EARTH:			// 接地
		break;
	case MS_VT:				// 計器用変圧器
		{
			if (nDirect == DIRECT_NONE) {
				if (props.lineActiveFlow1 >= 0) {
					result.drawActiveNoneDirect = true
				}
				else if (props.lineActiveFlow2 > 0) {
					result.drawActiveNoneDirect = true
					result.arrowActiveAtTop = true
				}
				if (props.lineReactiveFlow1 >= 0) {
					result.drawReactiveNoneDirect = true
				}
				else if (props.lineReactiveFlow2 > 0) {
					result.drawReactiveNoneDirect = true
					result.arrowReactiveAtTop = true
				}
			}
			else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
				if (props.lineActiveFlow1 > 0) {
					result.drawOtherDirect = true
				}
				else {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtTop = true
				}
			}
			else if (nDirect == BOTTOM_CENTER || nDirect == RIGHT_CENTER) {
				if (props.lineActiveFlow2 > 0) {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtTop = true
				}
				else {
					result.drawOtherDirect = true
				}
			}
		}
		break;
	case MS_CABLEHEAD:		// ケーブルヘッド
		break;
	case MS_CT:				// 変流器
		{
			if (nDirect == DIRECT_NONE) {
				if (props.lineActiveFlow1 >= 0) {
					result.drawActiveNoneDirect = true
				}
				else if (props.lineActiveFlow2 > 0) {
					result.drawActiveNoneDirect = true
					result.arrowActiveAtTop = true
				}
				if (props.lineReactiveFlow1 >= 0) {
					result.drawReactiveNoneDirect = true
				}
				else if (props.lineReactiveFlow2 > 0) {
					result.drawReactiveNoneDirect = true
					result.arrowReactiveAtTop = true
				}
			}
			else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
				if (props.lineActiveFlow1 > 0) {
					result.drawOtherDirect = true
				}
				else {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtTop = true
				}
			}
			else if (nDirect == BOTTOM_CENTER || nDirect == RIGHT_CENTER) {
				if (props.lineActiveFlow2 > 0) {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtTop = true
				}
				else {
					result.drawOtherDirect = true
				}
			}
		}
		break;
	case MS_ZCT:			// 零相変流器
		{
			if (nDirect == DIRECT_NONE) {
				if (props.lineActiveFlow1 >= 0) {
					result.drawActiveNoneDirect = true
				}
				else if (props.lineActiveFlow2 > 0) {
					result.drawActiveNoneDirect = true
					result.arrowActiveAtTop = true
				}
				if (props.lineReactiveFlow1 >= 0) {
					result.drawReactiveNoneDirect = true
				}
				else if (props.lineReactiveFlow2 > 0) {
					result.drawReactiveNoneDirect = true
					result.arrowReactiveAtTop = true
				}
			}
			else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
				if (props.lineActiveFlow1 > 0) {
					result.drawOtherDirect = true
				}
				else {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtTop = true
				}
			}
			else if (nDirect == BOTTOM_CENTER || nDirect == RIGHT_CENTER) {
				if (props.lineActiveFlow2 > 0) {
					result.drawOtherDirect = true
					result.arrowActiveReactiveAtTop = true
				}
				else {
					result.drawOtherDirect = true
				}
			}
		}
		break;
	case MS_TEXT:			// 任意文字列
		break;
	case MS_LIGHTBOARD:		// 分電盤
		{
			if (nDirect == DIRECT_NONE) {
				result.drawActiveNoneDirect = true
				result.drawReactiveNoneDirect = true
			}
			else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
				result.drawOtherDirect = true
			}
		}
		break;
	case MS_POWERBOARD:		// 制御盤
		{
			if (nDirect == DIRECT_NONE) {
				result.drawActiveNoneDirect = true
				result.drawReactiveNoneDirect = true
			}
			else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
				result.drawOtherDirect = true
			}
		}
		break;
	}

	return result
}


//CDCView1::DoDrawFlowResultElement
export const doDrawFlowResultElement = (pElement:ControlModel, processMode:any, nDirect:number) =>
{
	if (!doGetSupplyEle(pElement) ||
		(isCanSwitchOnOff(pElement) && isSwitchOff(pElement)) ||
		isZeroResultFlowEle(pElement)) {
		return;
	}

	let nOpposite = 0;
	switch (nDirect) {
		case TOP_CENTER:
			nOpposite = BOTTOM_CENTER;
			break;
		case BOTTOM_CENTER:
			nOpposite = TOP_CENTER;
			break;
		case LEFT_CENTER:
			nOpposite = RIGHT_CENTER;
			break;
		case RIGHT_CENTER:
			nOpposite = LEFT_CENTER;
			break;
	}

	let result:any={
		text: [],
		arrow: {}
	}

	let props = pElement.properties

	switch(pElement.type) {
	case MS_SOURCE:			// 電源
		{
			result.text = doGetTextFlowsResultSource(props, processMode, nDirect, false);
			if (props.activePower < 0) {
				result.arrow = doDrawFlowArrow(pElement, processMode, nDirect);
			}
			else {
				result.arrow = doDrawFlowArrow(pElement, processMode, nOpposite);
			}
		}
		break;
	case MS_GENERATOR:		// 発電機
		{
			result.text = doGetTextFlowsResultGenerator(props, processMode, nDirect, false);
			if (props.activePower < 0) {
				result.arrow = doDrawFlowArrow(pElement, processMode, nDirect);
			}
			else {
				result.arrow = doDrawFlowArrow(pElement, processMode, nOpposite);
			}
		}
		break;
	case MS_TRANS3:			// 三相変圧器
		{
			result.text = doGetTextFlowsResultTrans3(props, processMode, nDirect, false);
			result.arrow = doDrawFlowArrow(pElement, processMode, nDirect);
		}
		break;
	case MS_TRANSCENTER:	// 変台
		{
			result.text = doGetTextFlowsResultTransCenter(props, processMode, nDirect, false);
			result.arrow = doDrawFlowArrow(pElement, processMode, nDirect);
		}
		break;
	case MS_3WINDING:		// 三巻線変圧器
		{
			switch(nDirect) {
			case TOP_CENTER:
				result.text = doGetTextFlowsResult3winding(props.partPrimary, processMode, TOP_CENTER, false);
				break;
			case RIGHT_CENTER:
				result.text = doGetTextFlowsResult3winding(props.partSecondary, processMode, TOP_CENTER, false);
				break;
			case LEFT_CENTER:
				result.text = doGetTextFlowsResult3winding(props.partThird, processMode, TOP_CENTER, false);
				break;
			}
			result.arrow = doDrawFlowArrow(pElement, processMode, nDirect);
		}
		break;
	case MS_TRANS1:			// 単相変圧器
		{
			result.text = doGetTextFlowsResultTrans1(props, processMode, nDirect, false);
			result.arrow = doDrawFlowArrow(pElement, processMode, nDirect);
		}
		break;
	case MS_TRANSSCOTT:		// スコット結線変圧器
		{
			result.text = doGetTextFlowsResultTransscott(props, processMode, nDirect, false);
			result.arrow = doDrawFlowArrow(pElement, processMode, nDirect);
		}
		break;
	case MS_DS:				// 断路器
		{
			result.text = doGetTextFlowsResultDS(props, processMode, nDirect, false);
			result.arrow = doDrawFlowArrow(pElement, processMode, nDirect);
		}
		break;
	case MS_LBS:			// 開閉器
		{
			result.text = doGetTextFlowsResultLBS(props, processMode, nDirect, false);
			result.arrow = doDrawFlowArrow(pElement, processMode, nDirect);
		}
		break;
	case MS_MVCB:			// 遮断器
		{
			
			result.text = doGetTextFlowsResultMVCB(props, processMode, nDirect, false);
			
			result.arrow = doDrawFlowArrow(pElement, processMode, nDirect);
		}
		break;
	case MS_CONTACTOR:		// 電磁接触器
		{
			result.text = doGetTextFlowsResultContactor(props, processMode, nDirect, false);
			result.arrow = doDrawFlowArrow(pElement, processMode, nDirect);
		}
		break;
	case MS_HVCB:			// 過電流継電器
		{
			result.text = doGetTextFlowsResultHVCB(props, processMode, nDirect, false);
			result.arrow = doDrawFlowArrow(pElement, processMode, nDirect);
		}
		break;
	case MS_RELAY51:		// 継電器_51
		{
			// result.text = doGetTextFlowsResult(props, processMode, nDirect, false);
			// result.arrow = doDrawFlowArrow(pElement, processMode, nDirect);
		}
		break;
	case MS_FUSE:			// ヒューズ
		{
			result.text = doGetTextFlowsResultFUSE(props, processMode, nDirect, false);
			result.arrow = doDrawFlowArrow(pElement, processMode, nDirect);
		}
		break;
	case MS_2E:				// 電動機保護リレー
		{
			result.text = doGetTextFlowsResult2E(props, processMode, nDirect, false);
			result.arrow = doDrawFlowArrow(pElement, processMode, nDirect);
		}
		break;
	case MS_ZEROCOND:		// 母線
		break;
	case MS_CONNECT:		// 接続線
		break;
	case MS_WIRE:			// ワイヤ
		{
			result.text = doGetTextFlowsResultWire(props, processMode, nDirect, false);
			result.arrow = doDrawFlowArrow(pElement, processMode, nDirect);
		}
		break;
	case MS_BUSBAR:			// ブスバー
		{
			result.text = doGetTextFlowsResultBusBar(props, processMode, nDirect, false);
			result.arrow = doDrawFlowArrow(pElement, processMode, nDirect);
		}
		break;
	case MS_IMPEDANCE:		// インピーダンス
		{
			result.text = doGetTextFlowsResultImpedance(props, processMode, nDirect, false);
			result.arrow = doDrawFlowArrow(pElement, processMode, nDirect);
		}
		break;
	case MS_BUSDUCT:		// バスダクト
		{
			result.text = doGetTextFlowsResultBusDuct(props, processMode, nDirect, false);
			result.arrow = doDrawFlowArrow(pElement, processMode, nDirect);
		}
		break;
	case MS_REACTOR:		// 限流リアクトル
		{
			result.text = doGetTextFlowsResultReactor(props, processMode, nDirect, false);
			result.arrow = doDrawFlowArrow(pElement, processMode, nDirect);
		}
		break;
	case MS_LVCB:			// 低圧遮断器
		{
			result.text = doGetTextFlowsResultLVCB(props, processMode, nDirect, false);
			result.arrow = doDrawFlowArrow(pElement, processMode, nDirect);
		}
		break;
	case MS_THERMAL:		// サーマルリレー
		{
			result.text = doGetTextFlowsResultTHERMAL(props, processMode, nDirect, false);
			result.arrow = doDrawFlowArrow(pElement, processMode, nDirect);
		}
		break;
	case MS_THERMAL_CT:		// 過負荷継電器
		{
			result.text = doGetTextFlowsResultThermalCT(props, processMode, nDirect, false);
			result.arrow = doDrawFlowArrow(pElement, processMode, nDirect);
		}
		break;
	case MS_MOTOR_GROUP:	// 電動機群
		{
			result.text = doGetTextFlowsResultMotorGrp(props, processMode, nDirect, false);
			result.arrow = doDrawFlowArrow(pElement, processMode, nDirect);
		}
		break;
	case MS_MOTOR:			// 電動機
		{
			result.text = doGetTextFlowsResultMotor(props, processMode, nDirect, false);
			result.arrow = doDrawFlowArrow(pElement, processMode, nDirect);
		}
		break;
	case MS_LOAD:			// 負荷
		{
			result.text = doGetTextFlowsResultLoad(props, processMode, nDirect, false);
			result.arrow = doDrawFlowArrow(pElement, processMode, nDirect);
		}
		break;
	case MS_CAPACITOR:		// コンデンサ
		{
			result.text = doGetTextFlowsResultCapacitor(props, processMode, nDirect, false);
			result.arrow = doDrawFlowArrow(pElement, processMode, nDirect);
		}
		break;
	case MS_LOADCENTER:		// ロードセンタ
		break;
	case MS_VCT:			// 計器用変成器
		{
			result.text = doGetTextFlowsResultVCT(props, processMode, nDirect, false);
			result.arrow = doDrawFlowArrow(pElement, processMode, nDirect);
		}
		break;
	case MS_INV:			// インバーター
		{
			result.text = doGetTextFlowsResultINV(props, processMode, nDirect, false);
			result.arrow = doDrawFlowArrow(pElement, processMode, nDirect);
		}
		break;
	case MS_RELAY:			// リレー
		{
			result.text = doGetTextFlowsResultRELAY(props, processMode, nDirect, false);
			result.arrow = doDrawFlowArrow(pElement, processMode, nDirect);
		}
		break;
	case MS_ARRESTOR:		// 避雷器
		break;
	case MS_EARTH:			// 接地
		break;
	case MS_VT:				// 計器用変圧器
		{
			result.text = doGetTextFlowsResultVT(props, processMode, nDirect, false);
			result.arrow = doDrawFlowArrow(pElement, processMode, nDirect);
		}
		break;
	case MS_CABLEHEAD:		// ケーブルヘッド
		break;
	case MS_CT:				// 変流器
		{
			result.text = doGetTextFlowsResultCT(props, processMode, nDirect, false);
			result.arrow = doDrawFlowArrow(pElement, processMode, nDirect);
		}
		break;
	case MS_ZCT:			// 零相変流器
		{
			result.text = doGetTextFlowsResultZCT(props, processMode, nDirect, false);
			result.arrow = doDrawFlowArrow(pElement, processMode, nDirect);
		}
		break;
	case MS_TEXT:			// 任意文字列
		break;
	case MS_LIGHTBOARD:		// 分電盤
		{
			result.text = doGetTextFlowsResultLightboard(props, processMode, nDirect, false);
			result.arrow = doDrawFlowArrow(pElement, processMode, nDirect);
		}
		break;
	case MS_POWERBOARD:		// 制御盤
		{
			result.text = doGetTextFlowsResultPowerboard(props, processMode, nDirect, false);
			result.arrow = doDrawFlowArrow(pElement, processMode, nDirect);
		}
		break;
	}

	return result
}


//CDCView1::DoDrawFlowResultConnectedZeroCondElement
export const doDrawFlowResultConnectedZeroCondElement = (pStdZeroCond:ControlModel, processMode:any) =>
{
	let diagramEle = store.getState().app.diagram.diagramData.find(x=>x.shape.find(shapes=>shapes.id == pStdZeroCond.id))?.shape
	if(diagramEle == undefined) return
	diagramEle = [...diagramEle]
	let result:any[] = []
	for (;;) {	// 母線の上に接続されている機器
		let pTop = doGetConnectElement(diagramEle as ControlModel[], pStdZeroCond, TOP_CENTER).data;
		if (pTop == null || pTop == undefined) {
			break;
		}
		for (;;) {
			if (isSPhaseCircuitEle(pTop, VOLT_SIDE_SECONDARY).sPhase) {
				break;
			}
			if (pTop.type == MS_ARRESTOR ||
				pTop.type == MS_EARTH) {
				break;
			}
			if (pTop.type == MS_TRANS1 ||
				pTop.type == MS_TRANSSCOTT) {
	//			break;
			}
			if (pTop.type == MS_ZEROCOND) {
				break;
			}
			if (isCanSetViewFlowResult(pTop)) {
				let res = doDrawFlowResultElement(pTop, processMode, BOTTOM_CENTER)
				if(res)
					result.push(res);
				break;
			}
			else {
				pTop = doGetConnectElement(diagramEle as ControlModel[], pStdZeroCond, TOP_CENTER).data;
				if (pTop == null || pTop == undefined) {
					break;
				}
			}
		}
	}
	for (;;) {	// 母線の下に接続されている機器
		let pBottom = doGetConnectElement(diagramEle as ControlModel[], pStdZeroCond, BOTTOM_CENTER).data;
		if (pBottom == null || pBottom == undefined) {
			break;
		}
		for (;;) {
			if (isSPhaseCircuitEle(pBottom, VOLT_SIDE_PRIMARY).sPhase) {
				break;
			}
			if (pBottom.type == MS_ARRESTOR ||
				pBottom.type == MS_EARTH) {
				break;
			}
			if (pBottom.type == MS_TRANS1 ||
				pBottom.type == MS_TRANSSCOTT) {
		//		break;
			}
			if (pBottom.type == MS_ZEROCOND) {
				break;
			}
			if (isCanSetViewFlowResult(pBottom)) {
				let res = doDrawFlowResultElement(pBottom, processMode, TOP_CENTER);
				if(res)
					result.push(res)
				break;
			}
			else {
				pBottom = doGetConnectElement(diagramEle as ControlModel[], pStdZeroCond, BOTTOM_CENTER).data;
				if (pBottom == null || pBottom == undefined) {
					break;
				}
			}
		}
	}

	for (;;) {	// 母線の左に接続されている機器
		let pLeft = doGetConnectElement(diagramEle as ControlModel[], pStdZeroCond, LEFT_CENTER).data;
		if (pLeft == null || pLeft == undefined) {
			break;
		}
		for (;;) {
			if (isSPhaseCircuitEle(pLeft, VOLT_SIDE_PRIMARY).sPhase) {
				break;
			}
			if (pLeft.type == MS_ZEROCOND) {
				break;
			}
			if (isCanSetViewFlowResult(pLeft)) {
				let res = doDrawFlowResultElement(pLeft, processMode, RIGHT_CENTER);
				if(res)
					result.push(res)
				break;
			}
			else {
				pLeft = doGetConnectElement(diagramEle as ControlModel[], pStdZeroCond, LEFT_CENTER).data;
				if (pLeft == null || pLeft == undefined) {
					break;
				}
			}
		}
	}

	for (;;) {	// 母線の右に接続されている機器
		let pRight = doGetConnectElement(diagramEle as ControlModel[], pStdZeroCond, RIGHT_CENTER).data;
		if (pRight == null || pRight == undefined) {
			break;
		}
		for (;;) {
			if (isSPhaseCircuitEle(pRight, VOLT_SIDE_PRIMARY).sPhase) {
				break;
			}
			if (pRight.type == MS_ZEROCOND) {
				break;
			}
			if (isCanSetViewFlowResult(pRight)) {
				let res = doDrawFlowResultElement(pRight, processMode, LEFT_CENTER);
				if(res)
					result.push(res)
				break;
			}
			else {
				pRight = doGetConnectElement(diagramEle as ControlModel[], pStdZeroCond, RIGHT_CENTER).data;
				if (pRight == null || pRight == undefined) {
					break;
				}
			}
		}
	}

	return result
}

//CDCView1::DoDrawSymbolRefV1Element
export const doDrawSymbolRefV1Element = (pElement:ControlModel, processMode:any, bSociety:boolean = true) =>
{
	let props = pElement.properties;
	const flowViewMethod = store.getState().app.projectData.flowViewMethod

	let result:any = {
		text: [],
		arrow: {}
	}
	switch(pElement.type) {
	case MS_SOURCE:			// 電源
		{
			if (processMode == MS_MODE_POWER_FLOW &&
				props.viewFlowResult &&
				flowViewMethod == FLOW_VIEW_EVERY_ELEMENT) {
				result.text = doGetTextFlowsResultSource(props, processMode, DIRECT_NONE, false);
				result.arrow = doDrawFlowArrow(pElement, processMode, DIRECT_NONE);
			}
		}
		break;
	case MS_GENERATOR:		// 発電機
		{
			if (processMode == MS_MODE_POWER_FLOW &&
				props.viewFlowResult &&
				flowViewMethod == FLOW_VIEW_EVERY_ELEMENT) {
				result.text = doGetTextFlowsResultGenerator(props, processMode, DIRECT_NONE, false);
				result.arrow = doDrawFlowArrow(pElement, processMode, DIRECT_NONE);
			}
		}
		break;
	case MS_TRANS3:			// 三相変圧器
		{
			if (processMode == MS_MODE_POWER_FLOW &&
				props.viewFlowResult &&
				flowViewMethod == FLOW_VIEW_EVERY_ELEMENT) {
				result.text = doGetTextFlowsResultTrans3(props, processMode, DIRECT_NONE, false);
				result.arrow = doDrawFlowArrow(pElement, processMode, DIRECT_NONE);
			}
		}
		break;
	case MS_TRANSCENTER:	// 変台
		{
			if (processMode == MS_MODE_POWER_FLOW &&
				props.viewFlowResult &&
				!props.sPhase &&
				flowViewMethod == FLOW_VIEW_EVERY_ELEMENT) {
				result.text = doGetTextFlowsResultTransCenter(props, processMode, DIRECT_NONE, false);
				result.arrow = doDrawFlowArrow(pElement, processMode, DIRECT_NONE);
			}
		}
		break;
	case MS_3WINDING:		// 三巻線変圧器
		{
			if (processMode == MS_MODE_POWER_FLOW &&
				props.viewFlowResult &&
				flowViewMethod == FLOW_VIEW_EVERY_ELEMENT) {
				result.text = doGetTextFlowsResult3winding(props.partPrimary, processMode, DIRECT_NONE, false);
				result.text.push(doGetTextFlowsResult3winding(props.partSecondary, processMode, DIRECT_NONE, false));
				result.text.push(doGetTextFlowsResult3winding(props.partThird, processMode, DIRECT_NONE, false));
				result.arrow = doDrawFlowArrow(pElement, processMode, DIRECT_NONE);
			}
		}
		break;
	case MS_TRANS1:			// 単相変圧器
		{
			if (processMode == MS_MODE_POWER_FLOW &&
				props.viewFlowResult &&
				flowViewMethod == FLOW_VIEW_EVERY_ELEMENT) {
				result.text = doGetTextFlowsResultTrans1(props, processMode, DIRECT_NONE, false);
				result.arrow = doDrawFlowArrow(pElement, processMode, DIRECT_NONE);
			}
		}
		break;
	case MS_TRANSSCOTT:		// スコット結線変圧器
		{
			if (processMode == MS_MODE_POWER_FLOW &&
				props.viewFlowResult &&
				flowViewMethod == FLOW_VIEW_EVERY_ELEMENT) {
				result.text = doGetTextFlowsResultTransscott(props, processMode, DIRECT_NONE, false);
				result.arrow = doDrawFlowArrow(pElement, processMode, DIRECT_NONE);
			}
		}
		break;
	case MS_DS:				// 断路器
		{
			if (processMode == MS_MODE_POWER_FLOW &&
				props.viewFlowResult &&
				!props.isOff &&
				flowViewMethod == FLOW_VIEW_EVERY_ELEMENT) {
				result.text = doGetTextFlowsResultDS(props, processMode, DIRECT_NONE, false);
				result.arrow = doDrawFlowArrow(pElement, processMode, DIRECT_NONE);
			}
		}
		break;
	case MS_LBS:			// 開閉器
		{
			if (processMode == MS_MODE_POWER_FLOW &&
				props.viewFlowResult &&
				!props.isOff &&
				flowViewMethod == FLOW_VIEW_EVERY_ELEMENT) {
				result.text = doGetTextFlowsResultLBS(props, processMode, DIRECT_NONE, false);
				result.arrow = doDrawFlowArrow(pElement, processMode, DIRECT_NONE);
			}
		}
		break;
	case MS_MVCB:			// 遮断器
		{
			if (processMode == MS_MODE_POWER_FLOW &&
				props.viewFlowResult &&
				!props.isOff &&
				!props.sPhase &&
				flowViewMethod == FLOW_VIEW_EVERY_ELEMENT) {
				result.text = doGetTextFlowsResultMVCB(props, processMode, DIRECT_NONE, false);
				result.arrow = doDrawFlowArrow(pElement, processMode, DIRECT_NONE);
			}
		}
		break;
	case MS_CONTACTOR:		// 電磁接触器
		{
			if (processMode == MS_MODE_POWER_FLOW &&
				props.viewFlowResult &&
				!props.isOff &&
				!props.sPhase &&
				flowViewMethod == FLOW_VIEW_EVERY_ELEMENT) {
				result.text = doGetTextFlowsResultContactor(props, processMode, DIRECT_NONE, false);
				result.arrow = doDrawFlowArrow(pElement, processMode, DIRECT_NONE);
			}
		}
		break;
	case MS_HVCB:			// 過電流継電器
		{
			if (processMode == MS_MODE_POWER_FLOW &&
				props.viewFlowResult &&
				!props.isOff &&
				flowViewMethod == FLOW_VIEW_EVERY_ELEMENT) {
				result.text = doGetTextFlowsResultHVCB(props, processMode, DIRECT_NONE, false);
				result.arrow = doDrawFlowArrow(pElement, processMode, DIRECT_NONE);
			}
		}
		break;
	case MS_RELAY51:		// 継電器_51
		// {
		// 	if (processMode == MS_MODE_POWER_FLOW &&
		// 		props.viewFlowResult == true &&
		// 		pDoc->m_nFlowViewMethod == FLOW_VIEW_EVERY_ELEMENT) {
		// 		DoDrawRefV1(&pRelay51->m_RefV1FlowResult, _T(""), pRelay51->DoGetTextFlowsResult(processMode, DIRECT_NONE, false), pRelay51->m_bSymbolSel, pDoc);
		// 		result.arrow = doDrawFlowArrow(pElement, processMode, DIRECT_NONE);
		// 	}
		// }
		break;
	case MS_FUSE:			// ヒューズ
		{
			if (processMode == MS_MODE_POWER_FLOW &&
				props.viewFlowResult &&
				!props.isOff &&
				flowViewMethod == FLOW_VIEW_EVERY_ELEMENT) {
				result.text = doGetTextFlowsResultFUSE(props, processMode, DIRECT_NONE, false);
				result.arrow = doDrawFlowArrow(pElement, processMode, DIRECT_NONE);
			}
		}
		break;
	case MS_2E:				// ２Ｅリレー
		{
			if (processMode == MS_MODE_POWER_FLOW &&
				props.viewFlowResult &&
				!props.isOff &&
				!props.sPhase &&
				flowViewMethod == FLOW_VIEW_EVERY_ELEMENT) {
				result.text = doGetTextFlowsResult2E(props, processMode, DIRECT_NONE, false);
				result.arrow = doDrawFlowArrow(pElement, processMode, DIRECT_NONE);
			}
		}
		break;
	case MS_ZEROCOND:		// 母線
		break;
	case MS_CONNECT:		// 接続線
		break;
	case MS_WIRE:			// ワイヤ
		{
			if (processMode == MS_MODE_POWER_FLOW &&
				props.viewFlowResult &&
				!props.sPhase &&
				flowViewMethod == FLOW_VIEW_EVERY_ELEMENT) {
				result.text = doGetTextFlowsResultWire(props, processMode, DIRECT_NONE, false);
				result.arrow = doDrawFlowArrow(pElement, processMode, DIRECT_NONE);
			}
		}
		break;
	case MS_BUSBAR:			// ブスバー
		{
			if (processMode == MS_MODE_POWER_FLOW &&
				props.viewFlowResult &&
				!props.sPhase &&
				flowViewMethod == FLOW_VIEW_EVERY_ELEMENT) {
				result.text = doGetTextFlowsResultBusBar(props, processMode, DIRECT_NONE, false);
				result.arrow = doDrawFlowArrow(pElement, processMode, DIRECT_NONE);
			}
		}
		break;
	case MS_IMPEDANCE:		// インピーダンス
		{
			if (processMode == MS_MODE_POWER_FLOW &&
				props.viewFlowResult &&
				!props.sPhase &&
				flowViewMethod == FLOW_VIEW_EVERY_ELEMENT) {
				result.text = doGetTextFlowsResultImpedance(props, processMode, DIRECT_NONE, false);
				result.arrow = doDrawFlowArrow(pElement, processMode, DIRECT_NONE);
			}
		}
		break;
	case MS_BUSDUCT:		// バスダクト
		{
			if (processMode == MS_MODE_POWER_FLOW &&
				props.viewFlowResult &&
				!props.sPhase &&
				flowViewMethod == FLOW_VIEW_EVERY_ELEMENT) {
				result.text = doGetTextFlowsResultBusDuct(props, processMode, DIRECT_NONE, false);
				result.arrow = doDrawFlowArrow(pElement, processMode, DIRECT_NONE);
			}
		}
		break;
	case MS_REACTOR:		// 限流リアクトル
		{
			if (processMode == MS_MODE_POWER_FLOW &&
				props.viewFlowResult &&
				flowViewMethod == FLOW_VIEW_EVERY_ELEMENT) {
				result.text = doGetTextFlowsResultReactor(props, processMode, DIRECT_NONE, false);
				result.arrow = doDrawFlowArrow(pElement, processMode, DIRECT_NONE);
			}
		}
		break;
	case MS_LVCB:			// 低圧遮断器
		{
			if (processMode == MS_MODE_POWER_FLOW &&
				props.viewFlowResult &&
				!props.isOff &&
				!props.sPhase &&
				flowViewMethod == FLOW_VIEW_EVERY_ELEMENT) {
				result.text = doGetTextFlowsResultLVCB(props, processMode, DIRECT_NONE, false);
				result.arrow = doDrawFlowArrow(pElement, processMode, DIRECT_NONE);
			}
		}
		break;
	case MS_THERMAL:		// サーマルリレー
		{
			if (processMode == MS_MODE_POWER_FLOW &&
				props.viewFlowResult &&
				!props.isOff &&
				!props.sPhase &&
				flowViewMethod == FLOW_VIEW_EVERY_ELEMENT) {
				result.text = doGetTextFlowsResultTHERMAL(props, processMode, DIRECT_NONE, false);
				result.arrow = doDrawFlowArrow(pElement, processMode, DIRECT_NONE);
			}
		}
		break;
	case MS_THERMAL_CT:		// 過負荷継電器
		{
			if (processMode == MS_MODE_POWER_FLOW &&
				props.viewFlowResult &&
				!props.isOff &&
				!props.sPhase &&
				flowViewMethod == FLOW_VIEW_EVERY_ELEMENT) {
				result.text = doGetTextFlowsResultThermalCT(props, processMode, DIRECT_NONE, false);
				result.arrow = doDrawFlowArrow(pElement, processMode, DIRECT_NONE);
			}
		}
		break;
	case MS_MOTOR_GROUP:	// 電動機群
		{
			if (processMode == MS_MODE_POWER_FLOW &&
				props.viewFlowResult &&
				flowViewMethod == FLOW_VIEW_EVERY_ELEMENT) {
				result.text = doGetTextFlowsResultMotorGrp(props, processMode, DIRECT_NONE, false);
				result.arrow = doDrawFlowArrow(pElement, processMode, DIRECT_NONE);
			}
		}
		break;
	case MS_MOTOR:			// 電動機
		{
			if (processMode == MS_MODE_POWER_FLOW &&
				props.viewFlowResult &&
				!props.sPhase &&
				flowViewMethod == FLOW_VIEW_EVERY_ELEMENT) {
				result.text = doGetTextFlowsResultMotor(props, processMode, DIRECT_NONE, false);
				result.arrow = doDrawFlowArrow(pElement, processMode, DIRECT_NONE);
			}
		}
		break;
	case MS_LOAD:			// 負荷
		{
			if (processMode == MS_MODE_POWER_FLOW &&
				props.viewFlowResult &&
				!props.sPhase &&
				flowViewMethod == FLOW_VIEW_EVERY_ELEMENT) {
				result.text = doGetTextFlowsResultLoad(props, processMode, DIRECT_NONE, false);
				result.arrow = doDrawFlowArrow(pElement, processMode, DIRECT_NONE);
			}
		}
		break;
	case MS_CAPACITOR:		// コンデンサ
		{
			if (processMode == MS_MODE_POWER_FLOW &&
				props.viewFlowResult &&
				flowViewMethod == FLOW_VIEW_EVERY_ELEMENT) {
				result.text = doGetTextFlowsResultCapacitor(props, processMode, DIRECT_NONE, false);
				result.arrow = doDrawFlowArrow(pElement, processMode, DIRECT_NONE);
			}
		}
		break;
	case MS_LOADCENTER:		// ロードセンタ
		break;
	case MS_VCT:			// 計器用変成器
		{
			if (processMode == MS_MODE_POWER_FLOW &&
				props.viewFlowResult &&
				flowViewMethod == FLOW_VIEW_EVERY_ELEMENT) {
				result.text = doGetTextFlowsResultVCT(props, processMode, DIRECT_NONE, false);
				result.arrow = doDrawFlowArrow(pElement, processMode, DIRECT_NONE);
			}
		}
		break;
	case MS_INV:			// インバーター
		{
			if (processMode == MS_MODE_POWER_FLOW &&
				props.viewFlowResult &&
				flowViewMethod == FLOW_VIEW_EVERY_ELEMENT) {
				result.text = doGetTextFlowsResultINV(props, processMode, DIRECT_NONE, false);
				result.arrow = doDrawFlowArrow(pElement, processMode, DIRECT_NONE);
			}
		}
		break;
	case MS_RELAY:			// リレー
		{
			if (processMode == MS_MODE_POWER_FLOW &&
				props.viewFlowResult &&
				flowViewMethod == FLOW_VIEW_EVERY_ELEMENT) {
				result.text = doGetTextFlowsResultRELAY(props, processMode, DIRECT_NONE, false);
				result.arrow = doDrawFlowArrow(pElement, processMode, DIRECT_NONE);
			}
		}
		break;
	case MS_ARRESTOR:		// 避雷器
		break;
	case MS_EARTH:			// 接地
		break;
	case MS_VT:				// 計器用変圧器
		{
			if (processMode == MS_MODE_POWER_FLOW && 
				props.viewFlowResult &&
				flowViewMethod == FLOW_VIEW_EVERY_ELEMENT) {
				result.text = doGetTextFlowsResultVT(props, processMode, DIRECT_NONE, false);
				result.arrow = doDrawFlowArrow(pElement, processMode, DIRECT_NONE);
			}
		}
		break;
	case MS_CABLEHEAD:		// ケーブルヘッド
		break;
	case MS_CT:				// 変流器
		{
			if (processMode == MS_MODE_POWER_FLOW &&
				props.viewFlowResult &&
				!props.sPhase &&
				flowViewMethod == FLOW_VIEW_EVERY_ELEMENT) {
				result.text = doGetTextFlowsResultCT(props, processMode, DIRECT_NONE, false);
				result.arrow = doDrawFlowArrow(pElement, processMode, DIRECT_NONE);
			}
		}
		break;
	case MS_ZCT:			// 零相変流器
		{
			if (processMode == MS_MODE_POWER_FLOW &&
				props.viewFlowResult &&
				flowViewMethod == FLOW_VIEW_EVERY_ELEMENT) {
				result.text = doGetTextFlowsResultZCT(props, processMode, DIRECT_NONE, false);
				result.arrow = doDrawFlowArrow(pElement, processMode, DIRECT_NONE);
			}
		}
		break;
	case MS_TEXT:			// 任意文字列
		break;
	case MS_LIGHTBOARD:		// 分電盤
		{
			if (processMode == MS_MODE_POWER_FLOW &&
				props.viewFlowResult &&
				!props.sPhase &&
				flowViewMethod == FLOW_VIEW_EVERY_ELEMENT) {
				result.text = doGetTextFlowsResultLightboard(props, processMode, DIRECT_NONE, false);
				result.arrow = doDrawFlowArrow(pElement, processMode, DIRECT_NONE);
			}
		}
		break;
	case MS_POWERBOARD:		// 制御盤
		{
			if (processMode == MS_MODE_POWER_FLOW &&
				props.viewFlowResult &&
				!props.sPhase &&
				flowViewMethod == FLOW_VIEW_EVERY_ELEMENT) {
				result.text = doGetTextFlowsResultPowerboard(props, processMode, DIRECT_NONE, false);
				result.arrow = doDrawFlowArrow(pElement, processMode, DIRECT_NONE);
			}
		}
		break;
	}

	return result;
}

//CDCView1::DoDrawSymbolGroup
export const doDrawSymbolGroup = (pElement:ControlModel, processMode:any, bSociety:boolean = true) =>
{
	let result:any
	if (pElement != null) {
		const flowViewMethod = store.getState().app.projectData.flowViewMethod
		result = doDrawSymbolRefV1Element(pElement, processMode, bSociety);
		if ((result.text.length <= 0) && processMode == MS_MODE_POWER_FLOW &&
			flowViewMethod == FLOW_VIEW_ZEROCOND_ONLY &&
			!isSPhaseCircuitEle(pElement, VOLT_SIDE_SECONDARY).sPhase) {
			if (pElement.type == MS_ZEROCOND) {
				result = doDrawFlowResultConnectedZeroCondElement(pElement, processMode);
			}
			else {
				if(isViewFlowResultEle(pElement)) {
					const diagramEle = store.getState().app.diagram.diagramData.find(x=>x.shape.find(shapes=>Number(shapes.id) === Number(pElement.id)))?.shape
					if(diagramEle == undefined) return
					let pTop = doGetConnectElement(diagramEle, pElement, TOP_CENTER, false).data;
					if (pTop != null && pTop != undefined && pTop.type == MS_ZEROCOND) {
						result = doDrawFlowResultElement(pElement, processMode, TOP_CENTER);
					}
					let pBottom = doGetConnectElement(diagramEle, pElement, BOTTOM_CENTER, false).data;
					if (pBottom != null && pBottom != undefined && pBottom.type == MS_ZEROCOND) {
						result = doDrawFlowResultElement(pElement, processMode, BOTTOM_CENTER);
					}
					let pLeft = doGetConnectElement(diagramEle, pElement, LEFT_CENTER, false).data;
					if (pLeft!= null && pLeft != undefined && pLeft.type == MS_ZEROCOND) {
						result = doDrawFlowResultElement(pElement, processMode, LEFT_CENTER);
					}
					let pRight = doGetConnectElement(diagramEle, pElement, RIGHT_CENTER, false).data;
					if (pRight != null && pRight != undefined && pRight.type == MS_ZEROCOND) {
						result = doDrawFlowResultElement(pElement, processMode, RIGHT_CENTER);
					}
				}
			}
		}
	}
	return result
}
