import { Button, ButtonGroup, Checkbox, Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Stack, TextField } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import theme from "../../themes/globalStyles";
import DraggablePaper from "../common/DraggablePaper";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { ArrowLeft, ArrowRight, ArrowDropUp, ArrowDropDown, West, East, North, South} from '@mui/icons-material';
import { DatasetModel, GapLine, LineType, Point, ProjectModel } from "../../models/Index";
import * as math from "mathjs"
import {Point as EndPoint, Line, Segment} from '@flatten-js/core';
import DifferenceDisplayDialog from "./DifferenceDisplayDialog";
import { BeamsNumber } from "../../utils/FormatNumber";
import { MS_3WINDING, MS_SOURCE, MS_TRANS1, MS_TRANSSCOTT } from "../../models/ElementKind";
import { isOkSetCurveInfo } from "../../utils/infoRead";
import { doMakeCurveInrush as doMakeCurveInrush3Winding } from "../graph/3winding/Create3WindingGraph";
import { doMakeCurveInrush as doMakeCurveInrushTrans1 } from "../graph/trans1/createTrans1Part";
import { doGetValueRatedCurrent as doGetValueRatedCurrentTrans1} from "../../utils/Trans1Function";
import { MS_OUTSIDE_NEUTRAL, MS_OUTSIDE_OUTSIDE } from "../../statics";
import { doMakeCurveInrush as doMakeCurveInrushTransScott } from "../graph/transScott/CreateTransScottGraph";
import { doGetValueRatedCurrent as doGetValueRatedCurrentTransScott } from "../../utils/transscottFunction";

const dialogTheme = createTheme({
    components: {
      MuiDialog: {
        styleOverrides: {
          root: {
            pointerEvents: 'none'
          },
          paper: {
            pointerEvents: 'auto'
          }
        },
      },
    },
  });

type InterSectInfo = {
    label : string;
    value : number;
    controlId: string;
}

export type MeasureDifferenceDialogProps = {
    gapLine: GapLine;
    setGapLine: any;
    datasets: DatasetModel[];
    projectData: ProjectModel;
    currentIdChartTab: any;
    isClickedDataset: any;
    chartDataList: any;
    listTextOfLines: any;
    chartRef: any;
    setIntersectionPoint:(data:any) => void,
};

export const MeasureDifferenceDialog = (props: MeasureDifferenceDialogProps) => {

    //#region fields
    const { gapLine, datasets, setGapLine, projectData, currentIdChartTab, isClickedDataset, chartDataList, listTextOfLines, chartRef, setIntersectionPoint } = props;
    const [intersectionData, setIntersectionData] = useState(null)
    const [openDisplayDialog, setOpenDisplayDialog] = useState(false)
    //#endregion fields

    //#region methods
    const handleCalcMeasurement = () => {
        const intersectTime : InterSectInfo[] = []
        const intersectCurrent : InterSectInfo[] = []
        const intersectTimeData : any[] = []
        const intersectCurrentData : any[] = []
        datasets.forEach((dataset : DatasetModel) => {
            const {data, label, lineType} = dataset
            const checkEle = chartDataList.find((x:any)=>x.tabId === currentIdChartTab)?.shape.find((x:any)=>Number(x.id)===Number(dataset.controlId))
            const selectedLine = listTextOfLines.find((x:any)=> Number(x.relatedGraphId) === Number(dataset.relatedGraphId))
            if(checkEle && selectedLine && checkEle.type === MS_3WINDING && !selectedLine.isSelected) 
            {
                return
            }
            else if(checkEle && selectedLine)
            {
                let listPoint:any[] = []
                if(selectedLine.lineId == "INRUSH_PART_2")
                {
                    let dInrushTimes2 = checkEle.properties.partSecondary.inrushTimes;
                    let dInrushDampingTime2 = checkEle.properties.partSecondary.dampingTime;
                    if(doMakeCurveInrush3Winding(checkEle.properties, listPoint, 2, dInrushTimes2, dInrushDampingTime2)) return 
                }
                if(selectedLine.lineId == "INRUSH_PART_3")
                {
                    let dInrushTimes3 = checkEle.properties.partThird.inrushTimes;
                    let dInrushDampingTime3 = checkEle.properties.partThird.dampingTime;
                    if(doMakeCurveInrush3Winding(checkEle.properties, listPoint, 3, dInrushTimes3, dInrushDampingTime3)) return 
                }
            }

            if(checkEle && selectedLine && checkEle.type === MS_TRANS1 && !selectedLine.isSelected)
            {
                return
            }
            else if(checkEle && selectedLine)
            {
                let listPoint:any[] = []
                if(selectedLine.lineId == "INTENSITY_OUT_OUT")
                {
                    let dRatedCurrentOutOut = doGetValueRatedCurrentTrans1(checkEle.properties, MS_OUTSIDE_OUTSIDE, checkEle.properties.voltSecondary, checkEle.properties.capacity) * checkEle.properties.voltSecondary
                    if(!doMakeCurveInrushTrans1(dRatedCurrentOutOut,checkEle.properties,listPoint,2)) return 
                }
                if(selectedLine.lineId == "INTENSITY_OUT_IN")
                {
                    let dRatedCurrentOutIn = doGetValueRatedCurrentTrans1(checkEle.properties, MS_OUTSIDE_NEUTRAL, checkEle.properties.voltSecondary, checkEle.properties.capacity) * (checkEle.properties.voltSecondary / 2)
                    if(!doMakeCurveInrushTrans1(dRatedCurrentOutIn,checkEle.properties,listPoint,1)) return 
                }
            }

            if(checkEle && selectedLine && checkEle.type === MS_TRANSSCOTT && !selectedLine.isSelected)
            {
                return  
            }
            else if(checkEle && selectedLine)
            {
                let listPoint:any[] = []
                if(selectedLine.lineId == "INRUSH_1")
                {
                    let dRatedCurrent1 = doGetValueRatedCurrentTransScott(checkEle.properties.voltSecondary, checkEle.properties.capacity) * checkEle.properties.voltSecondary
                    if(!doMakeCurveInrushTransScott(dRatedCurrent1, checkEle.properties, listPoint, 1)) return 
                }
                if(selectedLine.lineId == "INRUSH_2")
                {
                    let dRatedCurrent2 = doGetValueRatedCurrentTransScott(checkEle.properties.voltSecondary, checkEle.properties.capacity) * checkEle.properties.voltSecondary
                    if(!doMakeCurveInrushTransScott(dRatedCurrent2, checkEle.properties, listPoint, 2)) return 
                }
            }

            if(checkEle && checkEle.type === MS_SOURCE)
            {
                if(!isOkSetCurveInfo(checkEle.properties.details)) return
            }

            if (lineType === LineType.MEASUREMENT_LINE || lineType === LineType.CONNECT_LINE || lineType === LineType.USER_CURVE) return
            data.forEach((point1 : Point, index : number) => {
                if ((index === data.length - 1) || (data[index] === data[index + 1])) return
                const point2 = data[index + 1]
                if (point2.y < projectData.defYMin || point2.y > projectData.defYMax) return
                const ctx = chartRef.current as any
                // Define two lines
                const lineTime = new Line(new EndPoint(ctx?.scales.x.getPixelForValue(projectData.defXMin), ctx?.scales.y.getPixelForValue(gapLine.stdTime)), new EndPoint(ctx?.scales.x.getPixelForValue(projectData.defXMax), ctx?.scales.y.getPixelForValue(gapLine.stdTime)));
                const lineCurrent = new Line(new EndPoint(ctx?.scales.x.getPixelForValue(gapLine.stdCurrent), ctx?.scales.y.getPixelForValue(projectData.defYMin)), new EndPoint(ctx?.scales.x.getPixelForValue(gapLine.stdCurrent), ctx?.scales.y.getPixelForValue(projectData.defYMax)));
                const segmentPoints = new Segment(new EndPoint(ctx?.scales.x.getPixelForValue(point1.x), ctx?.scales.y.getPixelForValue(point1.y)), new EndPoint(ctx?.scales.x.getPixelForValue(point2.x), ctx?.scales.y.getPixelForValue(point2.y)));
                if (gapLine.dispTime) {
                    // Calculate intersectionTime point
                    const intersectionTime = lineTime.intersect(segmentPoints);
                    // add the intersectionTime point
                    if (intersectionTime.length > 0) {
                        intersectTime.push({label, value : ctx?.scales.x.getValueForPixel(intersectionTime[0].x), controlId: dataset.controlId})
                        intersectTimeData.push({label, value : ctx?.scales.x.getValueForPixel(intersectionTime[0].x), controlId: dataset.controlId, lineId: dataset.lineId})
                    }
                }

                if (gapLine.dispCurrent) {
                    // Calculate intersectionCurrent point
                    const intersectionCurrent = lineCurrent.intersect(segmentPoints);
                    // add the intersectionCurrent point
                    if (intersectionCurrent.length > 0) {
                        intersectCurrent.push({label, value : ctx?.scales.y.getValueForPixel(intersectionCurrent[0].y), controlId: dataset.controlId})
                        intersectCurrentData.push({label, value : ctx?.scales.y.getValueForPixel(intersectionCurrent[0].y), controlId: dataset.controlId, lineId: dataset.lineId})
                    }
                }
            })
        })
        setIntersectionData({
            intersectTime, intersectCurrent
        } as any)

        setIntersectionPoint({
            intersectTimeData, intersectCurrentData
        } as any)
    }

    useEffect(() => {
        handleCalcMeasurement()
    }, [gapLine, currentIdChartTab,isClickedDataset]);

    const handleX10 = (action : string, key : keyof GapLine) => {
        let nextValue = 0;
        if (action === 'DECREASE') {
            nextValue = Number(BeamsNumber(+gapLine[key] / 10, 5).toFixed(6))
            if (key === "stdTime" && nextValue < projectData.defYMin) {
                nextValue = projectData.defYMin
            } else if (key === "stdCurrent" && nextValue < projectData.defXMin) {
                nextValue = projectData.defXMin
            }
        }
        else if (action === 'INCREASE') {
            nextValue = Number(BeamsNumber(+gapLine[key] * 10, 5).toFixed(6))
            if (key === "stdTime" && nextValue > projectData.defYMax) {
                nextValue = projectData.defYMax
            } else if (key === "stdCurrent" && nextValue > projectData.defXMax) {
                nextValue = projectData.defXMax
            }
        }
        setGapLine({...gapLine, [key] : nextValue})
    }
    
    const handleLog10 = (action : string, key : keyof GapLine) => {
        const currentValue = +gapLine[key]
        let stepValue = 10 ** (Math.floor(Math.log10(currentValue)) - 1)
        let nextValue = 0;
        if (action === 'DECREASE') {
            if (Number.isInteger(Math.log10(currentValue))) {
                nextValue = Number((currentValue - stepValue / 10).toFixed(6))
            } else {
                nextValue = Number((currentValue - stepValue).toFixed(6))
            }
            if (key === "stdTime" && nextValue < projectData.defYMin) {
                nextValue = projectData.defYMin
            } else if (key === "stdCurrent" && nextValue < projectData.defXMin) {
                nextValue = projectData.defXMin
            }
        }
        else if (action === 'INCREASE') {
            nextValue = Number((currentValue + stepValue).toFixed(6))
            if (key === "stdTime" && nextValue > projectData.defYMax) {
                nextValue = projectData.defYMax
            } else if (key === "stdCurrent" && nextValue > projectData.defXMax) {
                nextValue = projectData.defXMax
            }
        }
        setGapLine({...gapLine, [key] : nextValue})
    }
   
    //#endregion methods

    return (
        <>
        <ThemeProvider theme={dialogTheme}>
            <Dialog open={true} maxWidth={"md"} 
                hideBackdrop
                disableEnforceFocus
                PaperComponent={
                    DraggablePaper
                }
                sx={{
                    '.MuiDialog-root.MuiModal-root' : {
                        pointerEvents: 'none'
                    },
                    '.MuiPaper-root' : {
                        pointerEvents: 'auto'
                    }
                }}
            >
                <DialogTitle 
                    onMouseOver={(e : any) => e.target.style.cursor = 'move'}
                    style={{ paddingTop: '2px', paddingBottom: '2px', background: theme.palette.primary.main, color: theme.palette.primary.contrastText }}
                >
                    電流差/時間差の計測
                </DialogTitle>
                <DialogContent style={{paddingTop: '20px'}}>
                    <div style={{ height: '100%', width: '100%', margin: "auto"}}>
                            <Stack gap={2} direction={"row"}>
                                <Stack direction={"column"}>
                                    <FormControlLabel control={
                                        <Checkbox
                                            checked={gapLine.dispCurrent}
                                            onChange={(e) => {
                                                setGapLine({...gapLine, dispCurrent : e.target.checked})
                                            }}
                                        />
                                    } label="時間値を計測する" />
                                        <TextField
                                            fullWidth
                                            type="text"
                                            size="small"
                                            label='基準となる電流(A):'
                                            InputProps={{readOnly : true}}
                                            value={gapLine.stdCurrent}
                                        />
                                    <FormControlLabel control={
                                        <Checkbox
                                            checked={gapLine.dispTime}
                                            onChange={(e) => {
                                                setGapLine({...gapLine, dispTime : e.target.checked})
                                            }}
                                        />
                                    } label="電流値を計測する" />
                                    <TextField
                                        fullWidth
                                        type="text"
                                        size="small"
                                        label='基準となる時間(s):'
                                        InputProps={{readOnly : true}}
                                        value={gapLine.stdTime}
                                    />

                                </Stack>
                                <Stack alignSelf={"center"}>
                                    <Stack direction={'row'}>
                                       <Stack alignSelf={"center"} style={{height:'32px', width:'78px'}}>
                                            <ButtonGroup size="medium" disabled={!gapLine.dispCurrent}>
                                                <Button onClick={() => handleX10('DECREASE', 'stdCurrent')} sx={{'& .MuiButton-startIcon': {marginRight: '0px'}}} startIcon={<West />} />
                                                <Button onClick={() => handleLog10('DECREASE', 'stdCurrent')} sx={{'& .MuiButton-startIcon': {marginRight: '0px'}}} startIcon={<ArrowLeft />} />
                                            </ButtonGroup>
                                       </Stack>
                                       <Stack direction={"column"} gap={4} style={{width:'40px'}}>
                                            <ButtonGroup size="medium" orientation="vertical" disabled={!gapLine.dispTime}>
                                                <Button onClick={() => handleX10('INCREASE', 'stdTime')} sx={{'& .MuiButton-startIcon': {marginRight: '0px'}}} startIcon={<North />} />
                                                <Button onClick={() => handleLog10('INCREASE', 'stdTime')} sx={{'& .MuiButton-startIcon': {marginRight: '0px'}}} startIcon={<ArrowDropUp />} />
                                            </ButtonGroup>  
                                            <ButtonGroup size="medium" orientation="vertical" disabled={!gapLine.dispTime}>
                                                <Button onClick={() => handleLog10('DECREASE', 'stdTime')} sx={{'& .MuiButton-startIcon': {marginRight: '0px'}}} startIcon={<ArrowDropDown />} />
                                                <Button onClick={() => handleX10('DECREASE', 'stdTime')} sx={{'& .MuiButton-startIcon': {marginRight: '0px'}}} startIcon={<South />} />
                                            </ButtonGroup>
                                       </Stack>
                                       <Stack alignSelf={"center"} style={{height:'32px', width:'78px'}}>
                                            <ButtonGroup size="medium" disabled={!gapLine.dispCurrent}>
                                                <Button onClick={() => handleLog10('INCREASE', 'stdCurrent')} sx={{'& .MuiButton-startIcon': {marginRight: '0px'}}} startIcon={<ArrowRight />} />
                                                <Button onClick={() => handleX10('INCREASE', 'stdCurrent')} sx={{'& .MuiButton-startIcon': {marginRight: '0px'}}} startIcon={<East />} />
                                            </ButtonGroup>
                                       </Stack>
                                     
                                    </Stack>
                                </Stack>
                            </Stack>
                            <Stack direction='row' justifyContent="flex-end" spacing={3} sx={{ mt: 3 }}>
                                <Button variant="contained" onClick={()=>{setOpenDisplayDialog(true)}}>
                                    差分表示
                                </Button>
                                <Button variant="contained" color="inherit" onClick={() => setGapLine({...gapLine, dispGapLine : false})}>キャンセル</Button>
                            </Stack>
                    </div>
                </DialogContent>
            </Dialog>
            {openDisplayDialog && <DifferenceDisplayDialog gapLine={{stdCurrent:gapLine.stdCurrent, stdTime: gapLine.stdTime}} intersectionData={intersectionData} setOpenDisplayDialog={setOpenDisplayDialog} />}
        </ThemeProvider>
        </>
    )
}
export default MeasureDifferenceDialog;