import { Box, Button, Checkbox, Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Paper, Select, Stack, Tab, Tabs, TextField, Typography } from "@mui/material";
import { FormEvent, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { AdjustModel } from '../../models/Index';
import theme from "../../themes/globalStyles";
import * as EKind from "../../models/ElementKind";
import { convertDetailsDataFromBE } from "../../utils/DataConverter";
import { GetDetailsData } from "../../utils/DetailsDataController";
import DraggablePaper from "../common/DraggablePaper";
import TextFieldOptions from "../common/TextFieldOptions";

export type DeviceDetailsFormProps = {
    data: any;
    elementType: string;
    onOK: (data: any) => void;
    onCancel: () => void;
    setLoadingFlag: (flag: boolean) => void;
    m_bModePM: boolean;
    modeViewOnly: boolean;
};

function a11yProps(index: number) {
    return {
        id: `tab-${index}`,
        "aria-controls": `tabpanel-${index}`,
    };
}

//Tabs------------------
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}


export const DeviceDetailsDialog = (props: DeviceDetailsFormProps) => {
    const { data, onOK, onCancel, elementType, setLoadingFlag, m_bModePM, modeViewOnly } = props;

    // 初期値設定
    // const initialValue: AdjustModel = { ...data }
    const [initialValue, setInitialValue] = useState<AdjustModel>(data);
    
    const { control, handleSubmit, formState: { errors }, setValue, getValues } = useForm<AdjustModel>({
        mode: 'all',
        criteriaMode: "all",
        defaultValues: initialValue,
    });
    const [selectTab, setSelectTab] = useState(0);
    const [isDisableBreakingTimeHz, setIsDisableBreakingTimeHz] = useState(initialValue.delaySettings?.breakingTime === true ? false : true);
    const [isDisableCharDial, setIsDisableCharDial] = useState<boolean>(initialValue.drawThreeCurve);
    const [isDisableOKBtn, setIsDisableOKBtn] = useState(false);
    const isMsFUSE = elementType === EKind.MS_FUSE;

    const subTitle = data.base.typeList?.find(
        (item: { value: any; label: any }) =>
            item.value === data.base.typeID
    );

    // Submitイベント
    const handleEditSubmit = (value: AdjustModel) => {
        const request = createRequestData(value);
        onOK(request);
    }

    // FormデータからRequestデータを作成する
    const createRequestData = (formValue: AdjustModel) => {
        let request = {
            ...formValue,
        } as AdjustModel;
        return request;
    }

    // キャンセル
    const handleCancel = () => {
        onCancel();
    };

    const handleChangeFormat = (e: any) => {
        setValue(e.target.name, e.target.value);
    }
    
    const handleChangeRated = (e: any) => {
        setValue(e.target.name, e.target.value);
        const selectedRated = initialValue.base.ratedList.find(rated => rated.value === e.target.value);
        setValue('base.ratedID', selectedRated?.itemId);
        setValue('base.ratedValue', selectedRated?.itemValue);
        setValue('base.fixedName', selectedRated?.label);

        if ([1, 2].includes(initialValue.base.readBase.ratedFlag || -1)){
            DoGetDetailsData('base.ratedID');
        }
    }

    const handleChangeBase = (e: any) => {
        if (e.target.name == 'base.typeID'){
            const selectedType = initialValue.base.typeList.find(e1 => e1.value === e.target.value);
            setValue('base.typeName', selectedType?.label);
        }
        setValue(e.target.name, e.target.value);
        DoGetDetailsData(e.target.name);
    }

    const handleChangeBreakingTime = (e: any) => {
        setValue(e.target.name, e.target.checked);
        setIsDisableBreakingTimeHz(!(e.target.checked));
    }

    const handleChangeDrawThreeCurve = (e: any, index: number) => {
        setValue(e.target.name, e.target.checked);
        setIsDisableCharDial(e.target.checked);
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectTab(newValue);
    };

    const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault(); // prevent default form submission
        event.stopPropagation(); // stop event propagation to parent form
        handleSubmit(handleEditSubmit)(event); // handle child form submission
    };

    const DoGetDetailsData = async (from: any) => {
        let details = getValues()
        
        setLoadingFlag(true)
        let newDetailsData = await GetDetailsData(elementType, details.base, from)
        if (newDetailsData){
            let convertedData = convertDetailsDataFromBE(newDetailsData)
            
            details.base = {...details.base, ...convertedData.base}
            details.parts = convertedData.parts
            details.delaySettings = convertedData.delaySettings
            
            setValue('base', details.base)
            setValue('parts', details.parts)
            setValue('delaySettings', details.delaySettings)

            setInitialValue({...details})
            setIsDisableOKBtn(!details.base.typeID)
        }

        setLoadingFlag(false)
    }

    return (
        <>
            <Dialog open={true} fullWidth PaperComponent={DraggablePaper} >
                <DialogTitle
                    onMouseOver={(e : any) => e.target.style.cursor = 'move'} 
                    style={{ 
                        paddingTop: '2px', 
                        paddingBottom: '2px', 
                        background: theme.palette.primary.main, 
                        color: theme.palette.primary.contrastText 
                    }}
                >
                    {`詳細「${subTitle?.label || ''}」`}
                </DialogTitle>
                
                <DialogContent sx={{ pb: 1.5 }}>
                    <div style={{ height: '100%', width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={selectTab} onChange={handleChange}>
                                <Tab label="形式" {...a11yProps(0)} />
                                {initialValue.parts?.length > 0 &&
                                    initialValue.parts?.map((value, index) => (
                                        <Tab key={index + 1} label={value.tabName} {...a11yProps(index + 1)} />
                                    ))
                                }
                                {initialValue.delaySettings &&
                                    <Tab label="遅延設定" {...a11yProps((initialValue.parts?.length + 1) || 1)} />
                                }
                            </Tabs>
                        </Box>
                        <form onSubmit={onSubmit}>
                            <div style={{ height: '40vh', width: '100%', margin: 'auto', overflow: "auto" }}>
                                {/* tab0 - base */}
                                <TabPanel value={selectTab} index={0}>
                                    <Stack mt={2} spacing={0.3} width={'80%'}>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="65%">製造者:</Typography>
                                            <FormControl fullWidth size="small">
                                                <Controller
                                                    name="base.manufacturerID"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            onChange={handleChangeBase}
                                                            style={{
                                                                backgroundColor: initialValue.base.manufacturerList?.length === 0 ||m_bModePM
                                                                    ? "floralwhite" : "white"
                                                            }}
                                                            disabled = {initialValue.base.manufacturerList?.length === 0 ||m_bModePM || modeViewOnly}
                                                            sx={{height: 32}}
                                                        >
                                                            {initialValue.base.manufacturerList?.map(item => (
                                                                <MenuItem key={item.label} value={item.value}>
                                                                    {item.label}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    )}
                                                />
                                            </FormControl>
                                        </Stack>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="65%">機器種類:</Typography>
                                            <FormControl fullWidth size="small">
                                                <Controller
                                                    name="base.kindID"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            onChange={handleChangeBase}
                                                            style={{
                                                                backgroundColor: initialValue.base.kindList?.length === 0 ||m_bModePM
                                                                    ? "floralwhite" : "white"
                                                            }}
                                                            disabled = {initialValue.base.kindList?.length === 0 ||m_bModePM || modeViewOnly}
                                                            sx={{height: 32}}
                                                        >
                                                            {initialValue.base.kindList?.map(item => (
                                                                <MenuItem key={item.label} value={item.value}>
                                                                    {item.label}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    )}
                                                />
                                            </FormControl>
                                        </Stack>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="65%">形式:</Typography>
                                            <FormControl fullWidth size="small">
                                                <Controller
                                                    name="base.typeID"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextFieldOptions
                                                                {...field}
                                                                options={initialValue.base.typeList}
                                                                // type="number"
                                                                size="small"
                                                                setValue={(name: any, value: any) => {
                                                                    field.onChange({target:{name:name,value:value}});
                                                                    handleChangeBase({target:{name:name,value:value}})
                                                                }}
                                                                notAllowInput={true}
                                                                height={32}
                                                                disabled={initialValue.base.typeList?.length === 0||m_bModePM || modeViewOnly}
                                                                style={{backgroundColor: initialValue.base.typeList?.length === 0  ||m_bModePM
                                                                                ? "floralwhite" : "white"}}
                                                                value={getValues("base.typeName")}
                                                            />
                                                    )}
                                                />
                                            </FormControl>
                                        </Stack>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="65%">
                                                {initialValue.base.opeKindTitle && (initialValue.base.opeKindTitle + ':')}
                                            </Typography>
                                            <FormControl fullWidth size="small">
                                                <Controller
                                                    name="base.opeKindID"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            onChange={handleChangeBase}
                                                            style={{
                                                                backgroundColor: initialValue.base.opeKindList?.length === 0 ||m_bModePM
                                                                    ? "floralwhite" : "white"
                                                            }}
                                                            disabled = {initialValue.base.opeKindList?.length === 0 ||m_bModePM || modeViewOnly}
                                                            sx={{height: 32}}
                                                        >
                                                            {initialValue.base.opeKindList?.map(item => (
                                                                <MenuItem key={item.label} value={item.value}>
                                                                    {item.label}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    )}
                                                />
                                            </FormControl>
                                        </Stack>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="65%">範囲:</Typography>
                                            <FormControl fullWidth size="small">
                                                <Controller
                                                    name="base.ratedRangeID"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            onChange={handleChangeBase}
                                                            style={{
                                                                backgroundColor: initialValue.base.ratedRangeList?.length === 0 ||m_bModePM
                                                                    ? "floralwhite" : "white"
                                                            }}
                                                            disabled = {initialValue.base.ratedRangeList?.length === 0 ||m_bModePM || modeViewOnly}
                                                            sx={{height: 32}}
                                                        >
                                                            {initialValue.base.ratedRangeList?.map(item => (
                                                                <MenuItem key={item.label} value={item.value}>
                                                                    {item.label}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    )}
                                                />
                                            </FormControl>
                                        </Stack>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="65%">
                                                {initialValue.base.ratedTitle && (initialValue.base.ratedTitle + " (A):")}
                                            </Typography>
                                            <FormControl fullWidth size="small">
                                                <Controller
                                                    name="base.ratedSelectionValue"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            onChange={handleChangeRated}
                                                            style={{
                                                                backgroundColor: initialValue.base.ratedList?.length === 0 ||m_bModePM
                                                                    ? "floralwhite" : "white"
                                                            }}
                                                            disabled = {initialValue.base.ratedList?.length === 0 ||m_bModePM || modeViewOnly}
                                                            sx={{height: 32}}
                                                        >
                                                            {initialValue.base.ratedList?.map(item => (
                                                                <MenuItem key={item.label} value={item.value}>
                                                                    {item.label}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    )}
                                                />
                                            </FormControl>
                                        </Stack>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="65%">
                                                {initialValue.base.magniTitle && (initialValue.base.magniTitle + ':')}
                                            </Typography>
                                            <FormControl fullWidth size="small">
                                                <InputLabel sx={{ color: 'black' }}></InputLabel>
                                                <Controller
                                                    name="base.magniValue"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            onChange={handleChangeFormat}
                                                            style={{
                                                                backgroundColor: initialValue.base.magniList?.length === 0 ||m_bModePM
                                                                    ? "floralwhite" : "white"
                                                            }}
                                                            disabled = {initialValue.base.magniList?.length === 0 ||m_bModePM || modeViewOnly}
                                                            sx={{height: 32}}
                                                        >
                                                            {initialValue.base.magniList?.map(item => (
                                                                <MenuItem key={item.label} value={item.value}>
                                                                    {item.label}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    )}
                                                />
                                            </FormControl>
                                        </Stack>
                                    </Stack>

                                    <Stack mt={2} spacing={0.3} width={'80%'}>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="35%">シリーズ名称:</Typography>
                                            <Controller
                                                name="base.series"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        inputProps={{ readOnly: true }}
                                                        style={{ backgroundColor: 'floralwhite' }}
                                                        InputProps={{ sx: { height: 32 ,backgroundColor: 'floralwhite',color:modeViewOnly?"grey":"black"} }}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="35%">生産状況:</Typography>
                                            <Controller
                                                name="base.oldNew"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        inputProps={{ readOnly: true }}
                                                        style={{ backgroundColor: 'floralwhite' }}
                                                        InputProps={{ sx: { height: 32 ,backgroundColor: 'floralwhite',color:modeViewOnly?"grey":"black"} }}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="35%">備考:</Typography>
                                            <Controller
                                                name="base.notes"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        inputProps={{ readOnly: true }}
                                                        style={{ backgroundColor: 'floralwhite' }}
                                                        InputProps={{ sx: { height: 32 ,backgroundColor: 'floralwhite',color:modeViewOnly?"grey":"black"} }}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                    </Stack>
                                </TabPanel>

                                {/* tab of each parts */}
                                {initialValue?.parts?.length > 0 &&
                                    initialValue.parts.map((part, index) => (
                                        <TabPanel key={index + 1} value={selectTab} index={index + 1}>
                                            <Grid container mt={1}>
                                                <Grid item xs={6}>
                                                    <Stack>
                                                        <FormControl fullWidth size="small">
                                                            <Controller
                                                                name={`parts.${index}.charDialID`}
                                                                control={control}
                                                                render={({ field }) => (
                                                                    <Select
                                                                        {...field}
                                                                        onChange={handleChangeFormat}
                                                                        disabled={(isMsFUSE && isDisableCharDial )||m_bModePM || modeViewOnly}
                                                                        sx= {{ height: 32 ,backgroundColor:m_bModePM?'floralwhite':'white'}}
                                                                    >
                                                                        {part.charDialList?.map(item => (
                                                                            <MenuItem key={item.value} value={item.value}>
                                                                                {item.label}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                )}
                                                            />
                                                        </FormControl>
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={6} pl={3}>
                                                    <Controller
                                                        name={`parts.${index}.setLock`}
                                                        control={control}
                                                        render={({ field }: any) => (
                                                            <FormControlLabel 
                                                                {...field}
                                                                disabled={!part.useLock||m_bModePM || modeViewOnly} 
                                                                control={<Checkbox size="small"/>} 
                                                                checked={field.value} 
                                                                label={<Typography variant="body2" color={(!part.useLock||m_bModePM)?'grey':'black'}>ロック(不動作に設定) </Typography>}
                                                                onClick={handleChangeFormat}
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                            </Grid>

                                            <fieldset style={{marginTop:'16px',border:"1px solid #AAAA"}}>
                                                <Grid container>
                                                    <Grid item xs={6}>
                                                        <Typography variant="body2" width="25%">
                                                            {part.currentTitle && (part.currentTitle + ':')}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <FormControl fullWidth size="small">
                                                            <InputLabel sx={{ color: 'black' }}></InputLabel>
                                                            <Controller
                                                                name={`parts.${index}.currentValue`}
                                                                control={control}
                                                                render={({ field }) => (
                                                                    <Select
                                                                        {...field}
                                                                        onChange={handleChangeFormat}
                                                                        disabled = {part.currentFlag === 1 ||m_bModePM || modeViewOnly}
                                                                        style={{
                                                                            backgroundColor: part.currentFlag === 1 ||m_bModePM
                                                                                ? "floralwhite" : "white"
                                                                        }}
                                                                        sx= {{ height: 32 }}
                                                                    >
                                                                        {part.currentList?.map(item => (
                                                                            <MenuItem key={item.label} value={item.value}>
                                                                                {item.label}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                )}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>

                                                <Grid container mt={0.3}>
                                                    <Grid item xs={6}>
                                                        <Typography variant="body2" width="25%">
                                                            {part.timeTitle && (part.timeTitle + ':')}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <FormControl fullWidth size="small">
                                                            <Controller
                                                                name={`parts.${index}.timeValue`}
                                                                control={control}
                                                                render={({ field }) => (
                                                                    <Select
                                                                        {...field}
                                                                        onChange={handleChangeFormat}
                                                                        disabled = {part.timeFlag === 1 ||m_bModePM || modeViewOnly}
                                                                        style={{
                                                                            backgroundColor: part.timeFlag === 1 ||m_bModePM
                                                                                ? "floralwhite" : "white"
                                                                        }}
                                                                        sx= {{ height: 32 }}
                                                                    >
                                                                        {part.timeList?.map(item => (
                                                                            <MenuItem key={item.label} value={item.value}>
                                                                                {item.label}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                )}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </fieldset>

                                            <fieldset style={{marginTop:'16px',border:"1px solid #AAAA"}}>
                                                <Grid container>
                                                    <Grid item xs={6}>
                                                        <Typography variant="body2">
                                                            {part.magniCurrentTitle && (part.magniCurrentTitle + ':')}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <FormControl fullWidth size="small">
                                                            <Controller
                                                                name={`parts.${index}.magniCurrentValue`}
                                                                control={control}
                                                                render={({ field }) => (
                                                                    <Select
                                                                        {...field}
                                                                        label={part.magniCurrentTitle}
                                                                        onChange={handleChangeFormat}
                                                                        disabled = {part.magniCurrentFlag === 1 ||m_bModePM || modeViewOnly}
                                                                        style={{
                                                                            backgroundColor: part.magniCurrentFlag === 1 ||m_bModePM
                                                                                ? "floralwhite" : "white"
                                                                        }}
                                                                        sx= {{ height: 32 }}
                                                                    >
                                                                        {part.magniCurrentList?.map(item => (
                                                                            <MenuItem key={item.label} value={item.value}>
                                                                                {item.label}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                )}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>

                                                <Grid container mt={0.3}>
                                                    <Grid item xs={6}>
                                                        <Typography variant="body2">
                                                            {part.magniTimeTitle && (part.magniTimeTitle + ':')}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <FormControl fullWidth size="small">
                                                            <Controller
                                                                name={`parts.${index}.magniTimeValue`}
                                                                control={control}
                                                                render={({ field }) => (
                                                                    <Select
                                                                        {...field}
                                                                        label={part.magniTimeTitle}
                                                                        onChange={handleChangeFormat}
                                                                        disabled={part.magniTimeFlag === 1 ||m_bModePM || modeViewOnly}
                                                                        style={{
                                                                            backgroundColor: part.magniTimeFlag === 1 ||m_bModePM
                                                                                ? "floralwhite" : "white"
                                                                        }}
                                                                        sx= {{ height: 32 }}
                                                                    >
                                                                        {part.magniTimeList?.map(item => (
                                                                            <MenuItem key={item.label} value={item.value}>
                                                                                {item.label}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                )}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </fieldset>
                                            
                                            {isMsFUSE && 
                                                <FormControlLabel
                                                    control={
                                                        <Controller
                                                            name="drawThreeCurve"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <Checkbox
                                                                    {...field}
                                                                    size="small"
                                                                    checked={field.value}
                                                                    onChange={(e) => {handleChangeDrawThreeCurve(e, index)}}
                                                                    disabled={m_bModePM || modeViewOnly}
                                                                />
                                                            )}
                                                        />
                                                    }
                                                    label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>特性曲線を同時描画</Typography>}
                                                />                                                    
                                            }
                                        </TabPanel>
                                    ))
                                }
                                
                                {/* tab: delay setting */}
                                {initialValue.delaySettings &&
                                    <TabPanel value={selectTab} index={(initialValue.parts?.length + 1) || 1}>
                                        <Grid container mt={2}>
                                            <Stack>
                                                <FormControlLabel
                                                    control={
                                                        <Controller
                                                            name="delaySettings.breakingTime"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <Checkbox
                                                                    {...field}
                                                                    checked={field.value}
                                                                    onChange={handleChangeBreakingTime}
                                                                    disabled={m_bModePM || modeViewOnly}
                                                                />
                                                            )}
                                                        />
                                                    }
                                                    label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>遮断時間(サイクル)</Typography>}
                                                />
                                            </Stack>
                                            <Stack pl={3}>
                                                <FormControl fullWidth size="small">
                                                    <Controller
                                                        name="delaySettings.breakingTimeHzID"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                disabled={isDisableBreakingTimeHz ||m_bModePM || modeViewOnly}
                                                                onChange={handleChangeFormat}
                                                            >
                                                                {initialValue.delaySettings?.breakingTimeHzList?.map(item => (
                                                                    <MenuItem key={item.value} value={item.value}>
                                                                        {item.label}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        )}
                                                    />
                                                </FormControl>
                                            </Stack>
                                        </Grid>
                                    </TabPanel>
                                }
                            </div>
                            <Stack direction='row' justifyContent="flex-end" spacing={2} mt={2}>
                                <Button variant="contained" type="submit" disabled={isDisableOKBtn || modeViewOnly}>OK</Button>
                                <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                            </Stack>
                        </form >
                    </div >
                </DialogContent>
            </Dialog >
        </>
    );
};
export default DeviceDetailsDialog;


