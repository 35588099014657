
import { doDrawCurveFuse } from "./fuse/createFuseGraph";
import { MS_2E,MS_THERMAL, MS_3WINDING, MS_CAPACITOR, MS_SOURCE, MS_TRANS1, MS_TRANS3, MS_TRANSSCOTT,MS_HVCB, MS_MOTOR, MS_FUSE, MS_LVCB, MS_THERMAL_CT, MS_GENERATOR, MS_TRANSCENTER, MS_DS, MS_LBS, MS_MVCB, MS_CONTACTOR, MS_ZEROCOND, MS_WIRE, MS_BUSBAR, MS_IMPEDANCE, MS_BUSDUCT, MS_REACTOR, MS_MOTOR_GROUP } from "../../models/ElementKind";
import { CdPoint, ControlModel, GraphLineData, Ms2EModel,MsHVCBModel, Ms3WindingModel, MsCapacitorModel, MsSourceModel, MsTrans1Model, MsTrans3Model, MsTransScottModel, MsMotorModel, MsFUSEModel, MsLVCBModel, MsThermalCTModel ,MsThermalModel, AdjustElements, MsGeneratorModel, MSTransCenterModel, MsDSModel, MsLBSModel, MsMVCBModel, MsContactorModel, MsZerocondModel, MsWireModel, MsBusbarModel, MsImpedanceModel, MsBusductModel, MsReactorModel, MsMotorGroupModel} from "../../models/Index";
import { doDrawCurve2E } from "./2E/Create2EGraph";
import { doDrawCurveHVCB } from "./hvcb/createHVCBGraph";
import { doDrawCurveLVCB } from "./lvcb/createLVCBGraph";
import { doDrawCurve3Winding } from "./3winding/Create3WindingGraph";
import { doDrawCurrveCapacitor } from "./capacitor/CreateCapacitorGraph";
import { doDrawCurveSource } from "./source/createSourceGraph";
import { doDrawCurveTrans1 } from "./trans1/createTrans1Graph";
import { doDrawCurveTrans3 } from "./trans3/CreateTrans3Graph";
import { doDrawCurveTransScott } from "./transScott/CreateTransScottGraph";
import { doDrawCurveMotor } from "./motor/CreateMotorGraph";
import { doDrawCurveThermal } from "./thermal/CreateThermalGraph";
import { doDrawCurveThermalCT } from "./thermalCT/createThermalCTGraph";
import { VOLT_SIDE_PRIMARY, VOLT_SIDE_SECONDARY, VOLT_SIDE_THIRD } from "../../statics";
import { doDrawCurveGenerator } from "./generator/createGeneratorGraph";
import { doDrawCurveTransCenter } from "./transCenter/createTransCenterGraph";
import { doDrawCurveDS } from "./DS/createDSGraph";
import { doDrawCurveLBS } from "./LBS/createLBSGraph";
import { doDrawCurveMVCB } from "./MVCB/createMVCBGraph";
import { doDrawCurveContactor } from "./contactor/createContactorGraph";
import { doDrawCurveZeroCond } from "./zeroCond/createZeroCondGraph";
import { doDrawCurveWire } from "./wire/createWireGraph";
import { doDrawCurveBusBar } from "./busBar/createBusBarGraph";
import { doDrawCurveImpedance } from "./impedance/createImpedanceGraph";
import { doDrawCurveBusDuct } from "./busDuct/createBusDuctGraph";
import { doDrawCurveReactor } from "./reactor/createReactorGraph";
import { doDrawCurveMotorGrp } from "./motorGrp/createMotorGrpGraph";

const getGraphLineData = async (element : ControlModel, willCallDb : boolean, drawMode: number) => {
    let graphLineData : GraphLineData = { lines: [] }

    switch(element.type) {
        // call db
        case MS_SOURCE:
            graphLineData = await doDrawCurveSource(element.properties as MsSourceModel, element, willCallDb, drawMode)
            break;
        case MS_HVCB:
            graphLineData = await doDrawCurveHVCB(element.properties as MsHVCBModel, element, willCallDb, drawMode)
            break;
        case MS_FUSE:
            graphLineData = await doDrawCurveFuse(element.properties as MsFUSEModel, element, willCallDb, drawMode)
            break;
        case MS_LVCB:
            graphLineData = await doDrawCurveLVCB(element.properties as MsLVCBModel, element, willCallDb, drawMode)
            break;
        case MS_2E:
            graphLineData = await doDrawCurve2E(element.properties as Ms2EModel, element, willCallDb, drawMode)
            break;     
        case MS_THERMAL:
                graphLineData = await doDrawCurveThermal(element.properties as MsThermalModel, element, willCallDb, drawMode)
            break;     
        case MS_THERMAL_CT:
            graphLineData = await doDrawCurveThermalCT(element.properties as MsThermalCTModel, element, willCallDb, drawMode)
            break;        
        // no call db
        case MS_TRANS1:
            graphLineData = doDrawCurveTrans1(element.properties as MsTrans1Model, drawMode)
            break;
        case MS_TRANS3:
            graphLineData = doDrawCurveTrans3(element.properties as MsTrans3Model, drawMode)
            break;
        case MS_TRANSSCOTT:
            graphLineData = doDrawCurveTransScott(element.properties as MsTransScottModel, drawMode)
            break;
        case MS_3WINDING:
            graphLineData = doDrawCurve3Winding(element.properties as Ms3WindingModel, drawMode)
            break;
        case MS_CAPACITOR:
            graphLineData = doDrawCurrveCapacitor(element.properties as MsCapacitorModel,50)//hard code: nFrequency= 50 = hz
            break;
        case MS_MOTOR:
            graphLineData = doDrawCurveMotor(element.properties as MsMotorModel,false)//hard code m_bSPhae = false (parents)
            break;
        case MS_GENERATOR:
            graphLineData = await doDrawCurveGenerator(element.properties as MsGeneratorModel, element, false, drawMode)
            break;
        case MS_TRANSCENTER:
            graphLineData = await doDrawCurveTransCenter(element.properties as MSTransCenterModel, element, false, drawMode)
            break;
        case MS_DS:
            graphLineData = await doDrawCurveDS(element.properties as MsDSModel, element, false, drawMode)
            break;
        case MS_LBS:
            graphLineData = await doDrawCurveLBS(element.properties as MsLBSModel, element, false, drawMode)
            break;
        case MS_MVCB:
            graphLineData = await doDrawCurveMVCB(element.properties as MsMVCBModel, element, false, drawMode)
            break;
        case MS_CONTACTOR:
            graphLineData = await doDrawCurveContactor(element.properties as MsContactorModel, element, false, drawMode)
            break;
        case MS_ZEROCOND:
            graphLineData = await doDrawCurveZeroCond(element.properties as MsZerocondModel, element, false, drawMode)
            break;
        case MS_WIRE:
            graphLineData = await doDrawCurveWire(element.properties as MsWireModel, element, false, drawMode)
            break;
        case MS_BUSBAR:
            graphLineData = await doDrawCurveBusBar(element.properties as MsBusbarModel, element, false, drawMode)
            break;   
        case MS_IMPEDANCE:
            graphLineData = await doDrawCurveImpedance(element.properties as MsImpedanceModel, element, false, drawMode)
            break;   
        case MS_BUSDUCT:
            graphLineData = await doDrawCurveBusDuct(element.properties as MsBusductModel, element, false, drawMode)
            break; 
        case MS_REACTOR:
            graphLineData = await doDrawCurveReactor(element.properties as MsReactorModel, element, false, drawMode)
            break;
        case MS_MOTOR_GROUP:
            graphLineData = await doDrawCurveMotorGrp(element.properties as MsMotorGroupModel, element, false, drawMode)
            break;  
        default:
            break
    }
    
    return graphLineData
}

const getVoltageCombo = (elements : ControlModel[], voltSide : number) => {
    let voltageCombo : number[] = []
    if (elements.length === 0) return voltageCombo
    
    elements.forEach(control => {
        const voltage = doGetCircuitVolt(control, voltSide)
        voltageCombo.push(voltage)
        if (control.type === MS_TRANS1) {
            voltageCombo.push(voltage / 2)
        }
    })
    
    voltageCombo = [...new Set(voltageCombo) as any]
    voltageCombo.sort((a, b) => a-b)
    return voltageCombo
}

const doGetCircuitVolt = (control : ControlModel, voltSide : number) => {
    let voltage = 0
    const properties = control.properties
    switch(control.type) {
        // call db
        case MS_SOURCE:
            voltage = properties.voltage
            break;
        case MS_HVCB:
            voltage = properties.voltage
            break;
        case MS_FUSE:
            voltage = properties.voltage
            break;
        case MS_LVCB:
            voltage = properties.voltage
            break;
        case MS_2E:
            voltage = properties.voltage
            break;     
        case MS_THERMAL:
            voltage = properties.voltage
            break;     
        case MS_THERMAL_CT:
            voltage = properties.voltage
            break;        
        // no call db
        case MS_TRANS1:
            if (voltSide === VOLT_SIDE_PRIMARY) {
                voltage = properties.voltagePrimary
            } else if (voltSide === VOLT_SIDE_SECONDARY) {
                voltage = properties.voltageSecondary
            }
            break;
        case MS_TRANS3:
            if (voltSide === VOLT_SIDE_PRIMARY) {
                voltage = properties.voltagePrimary
            } else if (voltSide === VOLT_SIDE_SECONDARY) {
                voltage = properties.voltageSecondary
            }
            break;
        case MS_TRANSSCOTT:
            if (voltSide === VOLT_SIDE_PRIMARY) {
                voltage = properties.voltagePrimary
            } else if (voltSide === VOLT_SIDE_SECONDARY) {
                voltage = properties.voltageSecondary
            }
            break;
        case MS_3WINDING:
            if (voltSide === VOLT_SIDE_PRIMARY) {
                voltage = properties.partPrimary.voltage
            } else if (voltSide === VOLT_SIDE_SECONDARY) {
                voltage = properties.partSecondary.voltage
            } else if (voltSide === VOLT_SIDE_THIRD) {
                voltage = properties.partThird.voltage
            }
            break;
        case MS_CAPACITOR:
            voltage = properties.voltage
            break;
        case MS_MOTOR:
            voltage = properties.voltageID
            break;
        default:
            voltage = properties.voltage ?? 0
            break
    }
    return voltage
}

const willCallDb = (
    shape : ControlModel,
    data : object
  ) => {
    switch(shape.type) { //make sure these are call db controls graph before checking, otherwise return false by default
      case MS_SOURCE:
      case MS_2E:
      case MS_THERMAL:
      case MS_THERMAL_CT:
      case MS_FUSE:
      case MS_HVCB:
      case MS_LVCB:
      {
        const oldData = shape.properties as AdjustElements
        const newData = data as AdjustElements
        let willCallFlag = false

        // check for each part whether charDialID has been changed
        newData.details.parts.forEach((newPart, index) => {
            if(!oldData.details.parts || (oldData.details.parts && oldData.details.parts.length <= 0))
            {
                willCallFlag = true
            }
            else if (newPart.charDialID !== oldData.details.parts[index]?.charDialID) {
                willCallFlag = true
            }
        })

        // if control didnot cache the raw points, do call db too
        if (!willCallFlag && shape.raw !== undefined && shape.raw.length <= 0) willCallFlag = true

        return willCallFlag
      }
      default:
        return false
    }
}

// const m_lNominalVolt = 1//公称電圧

// // 全ての座標を公称値（論理座標）換算する
// const doConvertNominalList = (plistdPoint : CdPoint[]) => {
//     for( let i = 0; i< plistdPoint.length; ++i )
//     {
//         const pdPoint : CdPoint = plistdPoint[i];
//         doConvertNominal(pdPoint);
//     }
// }

// // 座標を公称値（論理座標）換算する
// const doConvertNominal = (dPoint : CdPoint) => {
//     dPoint.m_dx /= m_lNominalVolt //doConvertNominalX(dPoint.m_dx);
//     // dPoint.m_dy = doConvertNominalY(dPoint.m_dy);
// }

export {
    getGraphLineData,
    getVoltageCombo,
    willCallDb,
}