import { LoadingButton } from "@mui/lab";
import { Button, Checkbox, CircularProgress, FormControlLabel, Grid, Stack, TextField, Typography } from "@mui/material";
import { useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { EquipManufactureModel } from "../../../models/breakModel";
import { Path as RoutePath } from "../../../path";
import { ApplicationState, actionCreators, selectors } from "../../../store";
import FetchHelper from "../../FetchHelper";
//-----------
//FETCH ID
const GET_EQUIP_MANUFACTURE = "GET_EQUIP_MANUFACTURE"
const UPDATE_EQUIP_MANUFACTURE = "UPDATE_EQUIP_MANUFACTURE"
const CREATE_EQUIP_MANUFACTURE = "CREATE_EQUIP_MANUFACTURE"

const initialValue:EquipManufactureModel = {
    lManufacturerId:0,
    sKatakana:"",
    sManuName:"",
    bArrestor:false,
    bBusBar:false,
    bBusDuct:false,
    bCapacitor:false,
    bContactor:false,
    bEarth:false,
    bGenerator:false,
    bInv:false,
    bMotor:false,
    bReactor:false,
    bSwitchboard:false,
    bTrans:false,
    bWire:false,
    bds:false,
    bhvcb:false,
    blbs:false,
    bvct:false,
}
// -------------
// Props
export type EquipManufactureEditProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
export const EquipManufactureEdit = (props: EquipManufactureEditProps) => {
    const {
        userId,
        onUpdate,onUpdateSuccess,onUpdateError,        
        onCreate,onCreateSuccess,onCreateError
    } = props;

    const { control, formState: { errors }, handleSubmit, setValue, } = useForm<EquipManufactureModel>({
        criteriaMode: "all",
        mode:"all",
        defaultValues: initialValue,
    })
    type MyParams = {
        id: string;
        mode: string;
    };
    let { id, mode } = useParams<keyof MyParams>() as MyParams;
    let navigate = useNavigate();
    const [loadingFlag, setLoadingFlag] = useState(mode === "edit" ? true : false);
    const gridRef = useRef() as any;

    const handleSuccess = (data: any) => {
        setLoadingFlag(false);
        if(data && data.resultCode == 0){
            setValue("lManufacturerId",data.data.lManufacturerId);
            setValue("sKatakana",data.data.sKatakana);
            setValue("sManuName",data.data.sManuName);
            setValue("bArrestor",data.data.bArrestor);
            setValue("bBusBar",data.data.bBusBar);
            setValue("bBusDuct",data.data.bBusDuct);
            setValue("bCapacitor",data.data.bCapacitor);
            setValue("bContactor",data.data.bContactor);
            setValue("bEarth",data.data.bEarth);
            setValue("bGenerator",data.data.bGenerator);
            setValue("bInv",data.data.bInv);
            setValue("bMotor",data.data.bMotor);
            setValue("bReactor",data.data.bReactor);
            setValue("bSwitchboard",data.data.bSwitchboard);
            setValue("bTrans",data.data.bTrans);
            setValue("bWire",data.data.bWire);
            setValue("bds",data.data.bds);
            setValue("bhvcb",data.data.bhvcb);
            setValue("blbs",data.data.blbs);
            setValue("bvct",data.data.bvct);
        }
    }

    // 検索エラー時
    const handleError = (success: boolean, data: any, error: any) => {
        setLoadingFlag(false);
        console.log('>>>> handle Error');
        gridRef.current.hideOverlay();
    };

    // 検索実行
    const handleEditSubmit = (data: EquipManufactureModel) => {
        setLoadingFlag(true);
        if(mode === "edit") {
            let request = {
                lManufacturerId:id,
                bArrestor:data.bArrestor,
                bBusBar:data.bBusBar,
                bBusDuct:data.bBusDuct,
                bCapacitor:data.bCapacitor,
                bContactor:data.bContactor,
                bds:data.bds,
                bEarth:data.bEarth,
                bGenerator:data.bGenerator,
                bhvcb:data.bhvcb,
                blbs:data.blbs,
                bMotor:data.bMotor,
                bReactor:data.bReactor,
                bSwitchboard:data.bSwitchboard,
                bTrans:data.bTrans,
                bvct:data.bvct,
                bWire:data.bWire,
                bInv:data.bInv,
                sKatakana:data.sKatakana,
                sManuName:data.sManuName,
            } as any;
            const object = {
                requestPrms:request,
                userId: userId,
            }
            onUpdate(object);
        } else if(mode === "add") {
            let request = {
                bArrestor:data.bArrestor,
                bBusBar:data.bBusBar,
                bBusDuct:data.bBusDuct,
                bCapacitor:data.bCapacitor,
                bContactor:data.bContactor,
                bds:data.bds,
                bEarth:data.bEarth,
                bGenerator:data.bGenerator,
                bhvcb:data.bhvcb,
                blbs:data.blbs,
                bMotor:data.bMotor,
                bReactor:data.bReactor,
                bSwitchboard:data.bSwitchboard,
                bTrans:data.bTrans,
                bvct:data.bvct,
                bWire:data.bWire,
                bInv:data.bInv,
                sKatakana:data.sKatakana,
                sManuName:data.sManuName,
            } as any;
            const object = {
                requestPrms:request,
                userId: userId,
            }
            onCreate(object);
        }
    }

    // キャンセル
    const handleCancel = () => {
        navigate(RoutePath.EquipManufactureList);
    }

    // Submit後のAPI呼び出しエラー時
    const handleErrorUpdate = (success: boolean, data: any, error: any) => {
        onUpdateError();
        setLoadingFlag(false);
    }

    // Submit後のAPI呼び出し成功時
    const handleSuccessSubmit = (success: boolean, data: any, error: any) => {
        if(data.resultCode == 0){
            if (mode === "add") {
                onCreateSuccess();
                navigate(RoutePath.EquipManufactureEdit + `/edit/${data.data.lManufacturerId}`, { replace: true });
            }else if(mode === "edit"){
                onUpdateSuccess();
            }
        }else{
            if (mode === "add") {
                onCreateError();
            }else if(mode === "edit"){
                onUpdateError();
            }
        }
        setLoadingFlag(false)
    }

    // Submit後のAPI呼び出しエラー時
    const handleSubmitError = (success: boolean, data: any, error: any) => {
        onCreateError();
        setLoadingFlag(false);
    }

    //入力欄背景
    const divStyles = {
        backgroundColor: 'white',
        border: '1px solid #bebebe',
        boxShadow: '0 0 5px #fff, 0 0 5px #bebebe, 0 0 1px #aaa',
        padding: '20px 40px',
        marginTop: '40px',
    }

    return (
        <>
            {
                mode === "edit" && 
                <FetchHelper
                    fetchId={GET_EQUIP_MANUFACTURE}
                    url={`/master/equip_manufacture/get`}
                    method="POST"
                    json={true}
                    request={true}
                    params={{
                        requestPrms:{
                            id:id
                        },
                        userId: userId,
                    }}
                    onComplete={(success, data, error) => {
                        success ? handleSuccess(data) : handleError(success, data, error);
                    }}
                />
            }
            {loadingFlag && (
                <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1500, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                    <CircularProgress />
                </div>
            )}
            <div style={{ height: "100%", width: "100%", marginTop: "10px" }}>
                <Stack direction="row" justifyContent="flex-start" alignItems="center" sx={{}} style={{ margin: '18px', color: 'MenuText' }}>
                    <Typography variant="h4">
                        {mode== "edit" ? "メーカー更新" : "メーカー追加"}
                    </Typography>
                </Stack>
                <form onSubmit={handleSubmit(handleEditSubmit)}>
                    <div style={divStyles}>
                        <Typography variant="h6">
                            メーカー
                        </Typography>
                        <Stack mt={2} direction={{ xs: 'column', sm: 'row' }} sx={{maxHeight: "calc(100vh)"}}>
                            <Grid width={"100%"} spacing={1}>
                                <Grid pt={1} spacing={2} container direction={"row"}>
                                    <Grid item xs={6}>
                                        <Controller
                                            control={control}
                                            name="sManuName"
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.sManuName}
                                                    helperText={errors?.sManuName?.message}
                                                    label="メーカー名"
                                                    type="text"
                                                    size="small"

                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            control={control}
                                            name="sKatakana"
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.sKatakana}
                                                    helperText={errors?.sKatakana?.message}
                                                    label="メーカー名のカタカナ"
                                                    type="text"
                                                    size="small"

                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid mt={0} spacing={2} container direction={"row"}>
                                    <Grid item xs={12} md={12}>
                                        <Grid mt={0} container direction={"row"}>
                                            <Grid item xs={4} md={2}>
                                                <Controller
                                                    name="bGenerator"
                                                    control={control}
                                                    render={({ field }: any) => (
                                                        <FormControlLabel {...field} control={<Checkbox />} checked={field.value} label="発電機" labelPlacement="end" />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={4} md={2}>
                                                <Controller
                                                    name="bTrans"
                                                    control={control}
                                                    render={({ field }: any) => (
                                                        <FormControlLabel {...field} control={<Checkbox />} checked={field.value} label="変圧器" labelPlacement="end" />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={4} md={2}>
                                                <Controller
                                                    name="bds"
                                                    control={control}
                                                    render={({ field }: any) => (
                                                        <FormControlLabel {...field} control={<Checkbox />} checked={field.value} label="断路器" labelPlacement="end" />
                                                    )}
                                                />
                                            </Grid>
                                            
                                            <Grid item xs={4} md={2}>
                                                <Controller
                                                    name="blbs"
                                                    control={control}
                                                    render={({ field }: any) => (
                                                        <FormControlLabel {...field} control={<Checkbox />} checked={field.value} label="開閉器" labelPlacement="end" />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={4} md={2}>
                                                <Controller
                                                    name="bhvcb"
                                                    control={control}
                                                    render={({ field }: any) => (
                                                        <FormControlLabel {...field} control={<Checkbox />} checked={field.value} label="遮断器" labelPlacement="end" />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={4} md={2}>
                                                <Controller
                                                    name="bContactor"
                                                    control={control}
                                                    render={({ field }: any) => (
                                                        <FormControlLabel {...field} control={<Checkbox />} checked={field.value} label="電磁接触器" labelPlacement="end" />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid mt={0} spacing={2} container direction={"row"}>
                                    <Grid item xs={12} md={12}>
                                        <Grid mt={0} container direction={"row"}>
                                            <Grid item xs={4} md={2}>
                                                <Controller
                                                    name="bWire"
                                                    control={control}
                                                    render={({ field }: any) => (
                                                        <FormControlLabel {...field} control={<Checkbox />} checked={field.value} label="電線類" labelPlacement="end" />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={4} md={2}>
                                                <Controller
                                                    name="bBusBar"
                                                    control={control}
                                                    render={({ field }: any) => (
                                                        <FormControlLabel {...field} control={<Checkbox />} checked={field.value} label="ブスバー" labelPlacement="end" />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={4} md={2}>
                                                <Controller
                                                    name="bBusDuct"
                                                    control={control}
                                                    render={({ field }: any) => (
                                                        <FormControlLabel {...field} control={<Checkbox />} checked={field.value} label="バスダクト" labelPlacement="end" />
                                                    )}
                                                />
                                            </Grid>
                                            
                                            <Grid item xs={4} md={2}>
                                                <Controller
                                                    name="bReactor"
                                                    control={control}
                                                    render={({ field }: any) => (
                                                        <FormControlLabel {...field} control={<Checkbox />} checked={field.value} label="リアクトル" labelPlacement="end" />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={4} md={2}>
                                                <Controller
                                                    name="bMotor"
                                                    control={control}
                                                    render={({ field }: any) => (
                                                        <FormControlLabel {...field} control={<Checkbox />} checked={field.value} label="電動機" labelPlacement="end" />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={4} md={2}>
                                                <Controller
                                                    name="bCapacitor"
                                                    control={control}
                                                    render={({ field }: any) => (
                                                        <FormControlLabel {...field} control={<Checkbox />} checked={field.value} label="コンデンサ" labelPlacement="end" />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid mt={0} spacing={2} container direction={"row"}>
                                    <Grid item xs={12} md={12}>
                                        <Grid mt={0} container direction={"row"}>
                                            <Grid item xs={4} md={2}>
                                                <Controller
                                                    name="bvct"
                                                    control={control}
                                                    render={({ field }: any) => (
                                                        <FormControlLabel {...field} control={<Checkbox />} checked={field.value} label="計器用変成器" labelPlacement="end" />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={4} md={2}>
                                                <Controller
                                                    name="bArrestor"
                                                    control={control}
                                                    render={({ field }: any) => (
                                                        <FormControlLabel {...field} control={<Checkbox />} checked={field.value} label="避雷器" labelPlacement="end" />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={4} md={2}>
                                                <Controller
                                                    name="bEarth"
                                                    control={control}
                                                    render={({ field }: any) => (
                                                        <FormControlLabel {...field} control={<Checkbox />} checked={field.value} label="接地抵抗器" labelPlacement="end" />
                                                    )}
                                                />
                                            </Grid>
                                            
                                            <Grid item xs={4} md={2}>
                                                <Controller
                                                    name="bSwitchboard"
                                                    control={control}
                                                    render={({ field }: any) => (
                                                        <FormControlLabel {...field} control={<Checkbox />} checked={field.value} label="分電盤または動力盤" labelPlacement="end" />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={4} md={2}>
                                                <Controller
                                                    name="bInv"
                                                    control={control}
                                                    render={({ field }: any) => (
                                                        <FormControlLabel {...field} control={<Checkbox />} checked={field.value} label="インバーター" labelPlacement="end" />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Stack>
                    </div>
                    <Stack direction='row' justifyContent="flex-end" spacing={2} sx={{ mt: 1, mr: 2 }}>
                        <LoadingButton variant="contained" type="submit" loading={loadingFlag}>保存</LoadingButton>
                        <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                    </Stack>
                </form>
            </div>
            <FetchHelper fetchId={UPDATE_EQUIP_MANUFACTURE}
                onComplete={(success: boolean, data: any, error: any) =>
                    success ? handleSuccessSubmit(success, data, error) : handleErrorUpdate(success, data, error)} />
             <FetchHelper fetchId={CREATE_EQUIP_MANUFACTURE}
                onComplete={(success, data, error) => success ?
                    handleSuccessSubmit(success, data, error) :
                    handleSubmitError(success, data, error)} />

        </>
    );
}

const mapStateToProps = (state: ApplicationState) => (
    {
        userId: state.app.user?.userId,
        fetchUpdate: selectors.getFormState(
            state,
            // GET_BREAK_CAPACITY
            UPDATE_EQUIP_MANUFACTURE
        ),
    }
)

const mapDispatchToProps = (dispatch: any) => {
    return {
        onCreate: (data: any) => dispatch(actionCreators.fetch(CREATE_EQUIP_MANUFACTURE, `/master/equip_manufacture/add`, "POST", data, false, true)),
        onUpdate: (data: any) => dispatch(actionCreators.fetch(UPDATE_EQUIP_MANUFACTURE, `/master/equip_manufacture/modify`, "POST", data,false,true)),
        onCreateSuccess: () =>
            dispatch(actionCreators.showMessage({
                type: "info",
                title: "メーカー追加",
                body: "メーカーを登録しました。",
            })),
        onCreateError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "メーカー追加",
                body: "メーカーを登録出来ませんでした。",
            })),
        onUpdateSuccess: () =>
            dispatch(actionCreators.showMessage({
                type: "info",
                title: "メーカー編集",
                body: "メーカーを更新しました。",
            })),
        onUpdateError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "メーカー編集",
                body: "メーカーを更新出来ませんでした。",
            })),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EquipManufactureEdit as any);