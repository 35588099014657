import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Box, Button, CircularProgress, Stack, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import { useForm } from 'react-hook-form';
import { connect } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { PageData } from "../../models/Index";
import { ApplicationState, actionCreators } from "../../store";
import FetchHelper from '../FetchHelper';
import AlertDialog from '../common/AlertDialog';
import ProjectGrid from './ProjectGrid';
import { Input } from "@mui/icons-material";
import { Auth } from "aws-amplify";
import axios from 'axios';
import { store } from "../../";
import useInterval from '../../hooks/useInterval';
import MainHeader from "../../layouts/MainHeader";
import { SessionExpiredAction } from "../../store/AppStore";
import AddNewProjectDialog from "../dialogs/AddNewProjectDialog";
import ImportProjectDialog from "../dialogs/ImportProjectDialog";
import { deleteFileS3, deleteFolderS3, downloadFileFromS3, post, redirectMaintenancePage, uploadFileToS3 } from '../CallApi';
import { downloadFileByBlob } from '../../utils/downloadFile';
import { isShowMaintenanceScreen, isShowMyProject } from '../../store/Actions';
import JSZip from 'jszip';

// -------------
// FETCH ID
const GET_PROJECT_LIST = "GET_PROJECT_LIST";
const COPY_PROJECT = "COPY_PROJECT";
const DELETE_PROJECT = "DELETE_PROJECT";
const CREATE_PROJECT = "CREATE_PROJECT";
const IMPORT_PROJECT = "IMPORT_PROJECT";
const GET_IMPORT_STATUS = "GET_IMPORT_STATUS";
const GET_SHARE_PROJECT_LIST = "GET_SHARE_PROJECT_LIST"
const UPDATE_LOCKED_USER = "UPDATE_LOCKED_USER"

// 検索フォーム
interface SearchItemForm {
    "target": string;
    "limit": number;
    "page": number;
}

// 初期値設定
const initialValue: SearchItemForm = {
    target: '',
    limit: 50,
    page: 1
}

const initialPageData: PageData = {
    rowFrom: '0',
    rowTo: '0',
    totalRow: '0',
    currPage: '0',
    totalPage: '0',
};

// -------------
// Props
type ProjectListProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

// -------------
// Component
export const ProjectList = (props: ProjectListProps) => {

    //#region fields & hooks
    const {
        user,
        isShowProjectStore,
        onLocked,
        onGetProjectList,
        onCopyProject,
        onErrorGet,
        onDelete,
        onDeleteSuccess,
        onDeleteError,
        onCreateProject,
        onErrorCreate,
        onImportProject,
        onSuccessUpload,
        onErrorUpload,
        onGetImportStatus,
        onSuccessCopyProject,
        onErrorCopyProject,
        onErrorExportJson,
        onGetShareProjectList,
        onUpdateLockedUserId,
        isShowMaintenanceScreen,
        setShowMyProject,
    } = props;

    const { control, handleSubmit, setValue, getValues } = useForm<SearchItemForm>({
        criteriaMode: "all",
        defaultValues: initialValue,
    });
    const fileInputRef = React.useRef<HTMLInputElement>(null);
    const fileInputRefMv3c = React.useRef<HTMLInputElement>(null);
    const [message, setMessage] = useState("");
    const [pageData, setPageData] = useState(initialPageData);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [loadingFlag, setLoadingFlag] = useState(false);
    const [keyword, setKeyword] = useState("");
    const [idDelete, setIdDelete] = useState("");
    const [openAddProjectDialog, setOpenAddProjectDialog] = useState(false);
    const [openImportProjectDialog, setOpenImportProjectDialog] = useState(false);
    const [file, setFile] = useState<File | null>(null);
    const [uploading, setUploading] = useState<boolean>(false);
    const [analyzing, setAnalyzing] = useState<boolean>(false);
    const [importingProjectIds, setImportingProjectIds] = useState<Number[]>([]);
    const [hasImportingProject, setHasImportingProject] = useState<boolean>(false);

    const [openCopyProjectDialog, setOpenCopyProjectDialog] = useState(false);
    const [projectSelected, setProjectSelected] = useState<any>(null);
    
    // Import/ Export
    const [openExportJsonDialog, setOpenExportJsonDialog] = useState(false);
    const [isImportJsonFile, setIsImportJsonFile] = useState(false);

    // share project
    const [disableLockprojectPermissionSee, setDisableLockprojectPermissionSee] = useState(false);
    const [isShowMyProject, setIsShowMyProject] = useState(true);
    const [isLocked, setIsLocked] = useState(false);
    const [isOpenLocked, setIsOpenLocked] = useState(false);
    const [listUser, setListUser] = useState<any>();
    const [dataOpenShareProject, setDataOpenShareProject] = useState<any>();

    const gridRef = useRef() as any;
    const navigate = useNavigate();
    //#endregion fields & hooks

    //#region methods
    // 初回検索実行
    React.useEffect(() => {
        setLoadingFlag(true);
        if(isShowProjectStore == false){
            handleDisplayProject(false)
        }
        else{
            handleGetProjectList(getValues());
        }
        // eslint-disable-next-line
    }, []);

    const handleShowDetailPage = (data: any) => {
        navigate(`/projects/setting/${data.projectId}`, { state: { projectData: data,modeShareProject: isShowMyProject ===false?true:false,limitPage:getValues()} })
    }
    const handleShowMaintenanceScreen = (projectId: any,ownerProject:number) => {
        navigate(`/home/${projectId}`,{ state: { data: dataOpenShareProject,ownerProject:ownerProject}})
        isShowMaintenanceScreen(true,'')
    }
    //#endregion methods

    //#region Get Project List
    // 検索実行
    const handleGetProjectList = (value: SearchItemForm,not_show:any = true) => {
        // WARNING: redundant
        const params = new Map<string, any>();
        if (value.limit) params.set("limit", value.limit);
        params.set("page", value.page);
        if(not_show){
            onGetProjectList({
                requestPrms: {
                    userId: user?.userId,
                    limit: value.limit,
                    page: value.page
                }
            });
        }
        else{
            onGetShareProjectList({
                requestPrms: {
                    userId: user?.userId,
                    limit: value.limit,
                    page: value.page
                }
            })
        }
    }

    const handleGetListUser = (data: any) => {
        const result = [] as any[];
        data.user_records?.forEach((element: any) => {
            let userItem = {} as any
            userItem.username = element.Username;
            userItem.custom_management_no = "";
            userItem.custom_company_name = "";
            userItem.custom_zip_code = "";
            userItem.address = "";
            userItem.family_name = "";
            userItem.given_name = "";
            userItem.custom_role = "";
            userItem.email = "";
            userItem.phone_number = "";
            userItem.custom_expiration_date = "";
            userItem.custom_memo = "";
            userItem.custom_mfa = "";
            userItem.custom_mfa_system = "";
            userItem.custom_plan = "";
            userItem.custom_serial_no = "";
            userItem.isSelect = false;
            userItem.sub = "";
            userItem.account_status = element.Enabled;
            
            element.Attributes.forEach((item: any) => {
                if (item.Name === "sub") {
                    userItem.sub = item.Value;
                }
                if (item.Name === "custom:management_no") {
                    userItem.custom_management_no = item.Value;
                }
                if (item.Name === "custom:company_name") {
                    userItem.custom_company_name = item.Value;
                }
                if (item.Name === "custom:zip_code") {
                    userItem.custom_zip_code = item.Value;
                }
                if (item.Name === "address") {
                    userItem.address = item.Value;
                }
                if (item.Name === "family_name") {
                    userItem.family_name = item.Value;
                }
                if (item.Name === "given_name") {
                    userItem.given_name = item.Value;
                }
                if (item.Name === "custom:role") {
                    userItem.custom_role = item.Value;
                }
                if (item.Name === "email") {
                    userItem.email = item.Value;
                }
                if (item.Name === "phone_number") {
                    userItem.phone_number = item.Value;
                }
                if (item.Name === "custom:expiration_date") {
                    userItem.custom_expiration_date = item.Value;
                }
                if (item.Name === "custom:memo") {
                    userItem.custom_memo = item.Value;
                }
                if (item.Name === "custom:mfa") {
                    if(item.Value.toLowerCase() == "true"){
                        userItem.custom_mfa = "true";
                    }else{
                        userItem.custom_mfa = "false";
                    }
                }
                if (item.Name === "custom:mfa_system") {
                    userItem.custom_mfa_system = item.Value;
                }
                if (item.Name === "custom:plan") {
                    userItem.custom_plan = item.Value;
                }
                if (item.Name === "custom:serial_no") {
                    userItem.custom_serial_no = item.Value;
                }
            })
            result.push(userItem);
        });
        return result;
    }

    const handleSuccessGet = (data: any) => {
        console.log('>>> handleSuccessGet');
        if(isShowMyProject){
            if (data && data.data) {
                if (gridRef && gridRef.current) {
                    gridRef.current.setRowData(data.data);
                }

                const totalCount = data.totalCount;
                const pageSize = getValues('limit');
                const page = Number(data.page);
                const totalPage = Math.ceil(totalCount / pageSize);
                const rowFrom = (pageSize * page - pageSize) + 1;
                const rowTo = (pageSize * page) > totalCount ? totalCount : (pageSize * page);

                const updateData: PageData = {
                    rowFrom: rowFrom.toLocaleString(),
                    rowTo: rowTo.toLocaleString(),
                    totalRow: totalCount.toLocaleString(),
                    currPage: page.toLocaleString(),
                    totalPage: totalPage.toLocaleString(),
                };

                const importingRows = data.data.filter((pj: any) => pj.importing && pj.importErrorCode === -1);
                const hasImportData = importingRows.length > 0;
                if (hasImportData) {
                    const ids = importingRows.map((x: any) => { return x.projectId; });
                    setImportingProjectIds(ids);
                }
                setHasImportingProject(hasImportData);
                setPageData(updateData);
            } else {
                if (gridRef && gridRef.current) {
                    gridRef.current.setRowData([]);
                }
            }
        }
        else{
            const listUser = handleGetListUser(data.listUser.data)
            setListUser(listUser)
            let dataListSharedProject:any = []
            let nameOwner =''
            if (data && data.data) {
                if (gridRef && gridRef.current) {
                    data.data.forEach((item:any)=>{
                        listUser.forEach((userList:any) =>{
                            if(userList.sub == item.sub && item.ownerId !== user?.userId){
                                nameOwner = userList.family_name + userList.given_name
                                let nameLocker = ''
                                if(item.lockedUserSub !== null){
                                    const beEditingBy = listUser.filter((locker:any) => locker.sub == item.lockedUserSub)
                                    nameLocker = beEditingBy[0].family_name + beEditingBy[0].given_name
                                }
                                const lastUpdateUser = listUser.filter((lastUser:any) => lastUser.sub == item.lastUpdateUseSub)
                                const nameLastUpdateUser =lastUpdateUser[0].family_name + lastUpdateUser[0].given_name
                                dataListSharedProject.push({importErrorCode:item.importErrorCode,
                                                            importing:item.importing,
                                                            isShared:item.isShared,
                                                            lockedUserId:item.lockedUserId,
                                                            ownerId:item.ownerId,
                                                            projectId:item.projectId,
                                                            projectName:item.projectName,
                                                            permission:item.permission,
                                                            owner:nameOwner,
                                                            nameLastUpdateUser:nameLastUpdateUser,
                                                            lastUpdateDatetime:item.lastUpdateDatetime,
                                                            beEditingBy:nameLocker,
                                                            ownerProjectName: nameOwner,
                                                            userOpenSub: item.userOpenSub
                                                        })
                            }
                            else if(userList.sub == item.sub && item.ownerId == user?.userId){
                                nameOwner = '自分'
                                let nameLocker = ''
                                if(item.lockedUserSub !== null){
                                    const beEditingBy = listUser.filter((locker:any) => locker.sub == item.lockedUserSub)
                                    nameLocker = beEditingBy[0].family_name + beEditingBy[0].given_name
                                }
                                const lastUpdateUser = listUser.filter((lastUser:any) => lastUser.sub == item.lastUpdateUseSub)
                                const nameLastUpdateUser =lastUpdateUser[0].sub ==item.sub?'自分': lastUpdateUser[0].family_name + lastUpdateUser[0].given_name
                                dataListSharedProject.push({importErrorCode:item.importErrorCode,
                                                            importing:item.importing,
                                                            isShared:item.isShared,
                                                            lockedUserId:item.lockedUserId,
                                                            ownerId:item.ownerId,
                                                            projectId:item.projectId,
                                                            projectName:item.projectName,
                                                            permission:item.permission,
                                                            owner:nameOwner,
                                                            nameLastUpdateUser:nameLastUpdateUser,
                                                            lastUpdateDatetime:item.lastUpdateDatetime,
                                                            beEditingBy:nameLocker,
                                                            ownerProjectName: userList.family_name + userList.given_name,
                                                            userOpenSub: item.userOpenSub

                                                        })
                            }
                            })
                    })
                    gridRef.current.setRowData(dataListSharedProject);
                }

                const totalCount = data.totalCount;
                const pageSize = getValues('limit');
                const page = Number(data.page);
                const totalPage = Math.ceil(totalCount / pageSize);
                const rowFrom = (pageSize * page - pageSize) + 1;
                const rowTo = (pageSize * page) > totalCount ? totalCount : (pageSize * page);

                const updateData: PageData = {
                    rowFrom: rowFrom.toLocaleString(),
                    rowTo: rowTo.toLocaleString(),
                    totalRow: totalCount.toLocaleString(),
                    currPage: page.toLocaleString(),
                    totalPage: totalPage.toLocaleString(),
                };

                const importingRows = data.data.filter((pj: any) => pj.importing && pj.importErrorCode === -1);
                const hasImportData = importingRows.length > 0;
                if (hasImportData) {
                    const ids = importingRows.map((x: any) => { return x.projectId; });
                    setImportingProjectIds(ids);
                }
                setHasImportingProject(hasImportData);
                setPageData(updateData);
            } else {
                if (gridRef && gridRef.current) {
                    gridRef.current.setRowData([]);
                }
            }
            setDataOpenShareProject(data.data)
        }

        setLoadingFlag(false);
    };

    // 検索エラー時
    const handleErrorGet = (success: boolean, data: any, error: any) => {
        console.log('>>> handleErrorGet');
        onErrorGet(success, data, error);
        setLoadingFlag(false);
        gridRef.current.hideOverlay();
    };

    // ページ数変更時にAPI検索する
    const handleChangePageData = (name: any, value: any) => {
        // 値変更
        setValue(name, value);
        if (name === 'limit') {
            setValue("page", 1);
        }
        handleGetProjectList(getValues(),isShowMyProject);
    };
    //#endregion Get Project List

    //#region Create Project
    // 追加の処理
    const handleOkAddForm = (data: any) => {
        setLoadingFlag(true);
        const params = {
            requestPrms: {
                userId: user?.userId,
                projectName: data.projectName,
                reportIndex: 1,
                ownerProject: user?.userId
            }
        }
        onCreateProject(params)
        setOpenAddProjectDialog(false)
    }

    const handleSuccessCreateProject = (data: any) => {
        console.log('>>> handleSuccessCreateProject');
        // handleGetProjectList(getValues());
        // data.data ==> original project id
        navigate(`/home/${data.data}`,{ state: { data: dataOpenShareProject,ownerProject:user?.userId}})
    }

    const handleErrorCreateProject = (success: boolean, data: any, error: any) => {
        console.log('>>> handleErrorCreateProject');
        onErrorCreate(success, data, error);
        setLoadingFlag(false);
    }
    //#endregion Create Project

    //#region Delete Project
    //削除の処理
    const handleDelete = (data: any) => {
        setIdDelete(data?.id);
        const msg = "プロジェクト: " + data?.name + "を削除します。"
        setMessage(msg);
        setOpenDeleteDialog(true);
    }

    // 削除確認ダイアログでOKボタンのイベントを受け取って、APIに送信する
    const handleDeleteOK = () => {
        setLoadingFlag(true);
        setOpenDeleteDialog(false);
        onDelete({
            requestPrms: {
                userId: user?.userId,
                projectId: idDelete
            }
        });
        setIdDelete("");
        setProjectSelected(null);
    }

    // 削除確認ダイアログでCancelボタンのイベントを受け取って、ダイアログを無効にする
    const handleDeleteCancel = () => {
        setIdDelete("");
        setOpenDeleteDialog(false);
    }

    const handleSuccessDelete = (data: any) => {
        handleGetProjectList(getValues());
        deleteFolderS3(`${user?.userSub}/${data.data}/`);
        console.log('>>> handleSuccessDelete');
        onDeleteSuccess();
        setLoadingFlag(false);
    };

    // 検索エラー時
    const handleErrorDelete = (success: boolean, data: any, error: any) => {
        setLoadingFlag(false);
        console.log('>>> handleErrorDelete');
        onDeleteError();
    };
    //#endregion Delete Project

    //#region Open Project
    const handleShowEditDiagramPage = (projectId: number,ownerProject:number) => {
        navigate(`/home/${projectId}`,{ state: { data: dataOpenShareProject,ownerProject:ownerProject} })
    }
    //#endregion Open Project

    //#region Import Project
    //ボタン押下でファイル選択
    const handleFileSelect = () => {
        if (isImportJsonFile){
            if (fileInputRefMv3c.current) {
                fileInputRefMv3c.current.click();
            }
        }
        else if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files && event.target.files[0];
        setFile(selectedFile || null);
    };

    const handleUpload = async () => {
        if (isImportJsonFile) {
            handleImportJsonFile();
            return;
        }

        if (!file || !file.name.endsWith(".mv3")) {
            setOpenImportProjectDialog(false);
            onErrorUpload();
            return;
        }
        setUploading(true);

        let token = ""
        try {
            const session = await Auth.currentSession()
            token = session.getIdToken().getJwtToken()
        } catch (error) {
            store.dispatch({
                type: "SESSION_EXPIRED",
            } as SessionExpiredAction);
        }

        var respObjectKey = "";
        try {
            const params = {
                requestPrms: {
                    userId: user?.userId
                }
            };
            // 1. Presigned URLの取得
            const presignedResponse = await axios.post(`${process.env.REACT_APP_APIGW_URL}/project/prepare-import`, params, { headers: { 'Authorization': token } })
            const presignedUrl = presignedResponse.data.data.presignedUrl;
            respObjectKey = presignedResponse.data.data.object_key;

            // 2. ファイルのアップロード
            await axios.put(presignedUrl, file, {
                headers: { 'Content-Type': 'application/octet-stream' }
            });

        } catch (err: any) {
            if (err.response && err.response.status === 503){
                redirectMaintenancePage(err.response);
            }
            console.error("Upload Error:", err);
            onErrorUpload();
            return;
        } finally {
            setOpenImportProjectDialog(false);
            setUploading(false);
        }

        try {
            setAnalyzing(true);
            // 3. 処理結果の取得
            let data = {
                userId: user?.userId,
                projectName: file.name,
                objectKey: respObjectKey,
            }
            handleFetchImport(data);
        } catch (error) {
            console.error("Processing Error:", error);
        } finally {
            setAnalyzing(false);
        }
    };

    const handleSuccessImportProject = (data: any) => {
        console.log('>>> handleSuccessImportProject');
        onSuccessUpload();
        handleGetProjectList(getValues());
    };

    const handleErrorImportProject = (success: boolean, data: any, error: any) => {
        setLoadingFlag(false);
        console.log('>>> handleErrorImportProject');
        onErrorUpload();
    };

    const handleFetchImport = (data: any) => {
        // setLoadingFlag(true);
        const params = {
            requestPrms: {
                userId: user?.userId,
                projectName: data.projectName,
                objectKey: data.objectKey,
            }
        }
        onImportProject(params);
    }
    //#endregion Import Project

    //#region Get Import Status
    const handleGetImportState = (value: SearchItemForm) => {
        const params = new Map<string, any>();
        if (value.limit) params.set("limit", value.limit);
        params.set("page", value.page);

        onGetImportStatus({
            requestPrms: {
                userId: user?.userId,
                limit: value.limit,
                page: value.page
            }
        });
    };

    const handleSuccessGetImportStatus = (data: any) => {
        console.log('>>> handleSuccessGetImportStatus');
        if (data.data) {
            const importingRows = data.data.filter((pj: any) => pj.importing && pj.importErrorCode === -1);
            if (importingRows.length !== importingProjectIds.length) {
                handleGetProjectList(getValues());
            }
        }

    };

    const handleErrorGetImportStatus = (data: any) => {
        console.log('>>> handleErrorGetImportStatus');
    };
    // 周期実行
    const delaySec = 10;
    useInterval(() => {
        handleGetImportState(getValues());
    }, (hasImportingProject) ? delaySec * 1000 : null);

    //#endregion Get Import Status

    //#region Copy Project
    const handleCopyProject = (data: any) => {
        setLoadingFlag(true);
        setOpenCopyProjectDialog(false);
        if (projectSelected) {
            onCopyProject({
                requestPrms: {
                    userId: user?.userId,
                    projectId: projectSelected.projectId,
                    projectName: data.projectName,
                }
            });
        }
    }

    const handleSuccessCopyProject = (data: any) => {
        if (gridRef && gridRef.current && data.data){
            setValue('page', 1)
            handleGetProjectList(getValues());
        }
        setLoadingFlag(false);
        setProjectSelected(null);
        onSuccessCopyProject();
    }

    const handleErrorCopyProject = (error: any) => {
        setLoadingFlag(false);
        setProjectSelected(null);
        onErrorCopyProject(error);
    }
    //#endregion Copy Project

    //#region Import/ Export project (.json)
    const { v4: uuidv4 } = require('uuid');

    const handleExportProjectJson = async () => {
        setOpenExportJsonDialog(false);
        if (projectSelected) {
            setLoadingFlag(true);
            let params: any = {
                requestPrms: {
                    userId: user?.userId,
                    projectId: projectSelected.projectId,
                    version: process.env.REACT_APP_VERSION,
                    downloadMultipartProject: false
                },
            }
            const result = await post("/project/export-json", params);
            if (result.success){
                let data = result.data.data
                if ("continued" in data) { // multi-part project
                    await handleDownloadMultipartProject(data, params)
                }
                else if (data != null) {
                    // download file
                    const resDownloadFile = await downloadFileFromS3(data.download_url)
                    if (resDownloadFile.success){
                        downloadFileByBlob(
                            resDownloadFile.data,
                            data.object_key.split("/").pop()
                        )
                        deleteFileS3(data.object_key)
                    } else {
                        onErrorExportJson(resDownloadFile.error)
                        deleteFolderS3(`import_export_json/${user?.userId}/`)
                    }
                }
            } else {
                onErrorExportJson(result.error)
            }
            setLoadingFlag(false);
        }
    }

    const handleDownloadMultipartProject = async (tmpData: any, tmpParams: any) => {
        let data = tmpData
        let params = tmpParams
        while (data.continued) {
            params.requestPrms["breakPoint"] = data
            const result_tmp = await post("/project/export-json", params);
            if (result_tmp.success){
                data = result_tmp.data.data
            } else {
                deleteFolderS3(`import_export_json/${user?.userId}/`)
                onErrorExportJson(result_tmp.error)
                break
            }
        }
        if (data.continued == false) {
            // get download url
            params.requestPrms.downloadMultipartProject = true
            const result_tmp = await post("/project/export-json", params);
            if (result_tmp.success){
                data = result_tmp.data.data
                if (data !== null) {
                    await downloadAndCompressFiles(data)
                }
            } else {
                deleteFolderS3(`import_export_json/${user?.userId}/`)
                onErrorExportJson(result_tmp.error)
            }
        }
    }

    const downloadAndCompressFiles = async (data: any) => {
        // Download file
        const urls = data.urls
        const zip = new JSZip();
        const contents: any = {};
        for (let i=0; i<urls.length; i++) {
            const url = urls[i]
            const resDownloadFile = await downloadFileFromS3(url)
            if (resDownloadFile.success){
                // Read file
                const zipContent = await zip.loadAsync(resDownloadFile.data);
                const files = Object.keys(zipContent.files);
                for (const fileName of files) {
                    const fileData = await zipContent.file(fileName)!.async("text");
                    const tempData = JSON.parse(JSON.stringify(fileData));
                    contents[fileName] = tempData;
                }
            } else {
                onErrorExportJson(resDownloadFile.error)
                deleteFolderS3(`import_export_json/${user?.userId}/`)
            }
        }

        // Generate the ZIP file and download it
        for (const fileName in contents) {
            zip.file(fileName, contents[fileName]);
        }
        const zipBlob = await zip.generateAsync({
            type: "blob",
            compression: "DEFLATE", /* name of the compression algorithm used */
            compressionOptions: {
                /* compression level ranges from 1 (best speed) to 9 (best compression) */
                level: 9
            }
        });
        downloadFileByBlob(zipBlob, `${data.project_name}.mv3c`)
        deleteFolderS3(`import_export_json/${user?.userId}/`)
    }

    const handleImportProject = (jsonFile: boolean) => {
        setIsImportJsonFile(jsonFile);
        setOpenImportProjectDialog(true);
    }
    
    const handleImportJsonFile = async () => {
        if (!file || !file.name.endsWith(".mv3c")) {
            setOpenImportProjectDialog(false);
            onErrorUpload();
            return;
        }
        setUploading(true);
        setLoadingFlag(true);

        const objectKeys = await extractFileAndUpload()
        if (objectKeys === null){
            setUploading(false);
            setLoadingFlag(false);
            onErrorUpload();
            return;
        }

        // ファイルのアップロード
        let params: any = {
            requestPrms: {
                userId: user?.userId,
                fileType: "mv3c",
                version: process.env.REACT_APP_VERSION,
                breakPoint: {
                    continued: true,
                    order_execution: objectKeys,
                    current_index: -1,
                    get_new_id_flag: false
                }
            }
        };
        let result = await post("/project/import-json", params);
        if (result.success){
            let data = result.data.data;
            while(data.continued) {
                params.requestPrms["breakPoint"] = data;
                result = await post("/project/import-json", params);
                if (result.success){
                    data = result.data.data;
                } else {
                    onDelete({
                        requestPrms: {
                            userId: user?.userId,
                            projectId: data.original_project_id
                        }
                    });
                    deleteFolderS3(`import_export_json/${user?.userId}/`);
                    break
                }
            }
            if (data != null && data.continued == false){
                onSuccessUpload();
                handleGetProjectList(getValues());
            }
        } else {
            // If an error occurs during the process, delete file in S3
            deleteFolderS3(`import_export_json/${user?.userId}/`);
            onErrorUpload();
        }
        
        // setFile(null);
        setOpenImportProjectDialog(false);
        setUploading(false);
        setLoadingFlag(false);
    }

    const extractFileAndUpload = async () => {
        let successFlag = true
        const zip = new JSZip();
        const zipContent = await zip.loadAsync(file!);
        // extract and sort
        const files = Object.keys(zipContent.files);
        const dict: any = {} // auto sort
        files.forEach(filename => {
            let tmpArr = filename.split("part_")
            dict[tmpArr[tmpArr.length-1].split(".")[0]] = filename
        })
        let sortFiles: any[] = Object.values(dict)
        // get upload url
        let objectKeys: any[] = [];
        for (let i = 0; i < sortFiles.length; i++){
            objectKeys.push(uuidv4());
        }
        let params: any = {
            requestPrms: {
                userId: user?.userId,
                objectKeys: objectKeys,
                getUploadUrl: true,
                fileType: "mv3c",
                version: process.env.REACT_APP_VERSION
            }
        };
        let result = await post("/project/import-json", params);
        if (result.success) {
            // upload files to s3
            const urls = result.data.data
            for (let i = 0; i < sortFiles.length; i++){
                const url = urls[i]
                const fileData = await zipContent.file(sortFiles[i])!.async("text");
                const zipUpload = new JSZip();
                zipUpload.file(sortFiles[i], fileData);
                const zipBlob = await zipUpload.generateAsync({
                    type: "blob",
                    compression: "DEFLATE",
                    compressionOptions: {
                        level: 9
                    }
                });
                const resUpload = await uploadFileToS3(url, zipBlob)
                if (resUpload.success == false) {
                    successFlag = false
                    deleteFolderS3(`import_export_json/${user?.userId}/`)
                    onErrorUpload();
                    break
                }
            }
        } else {
            onErrorUpload();
        }
        if (successFlag) return objectKeys
        else return null
    }
    //#endregion Import/ Export project (.json)

    //#region share project
    const styleNormal: React.CSSProperties = {
        width: '100%',
        borderRadius: 0,
        color: 'black'
    }
    const styleSeleted: React.CSSProperties = {
        width: '100%',
        borderRadius: 0,
        backgroundColor: 'rgb(231, 243, 253)'
    }
    const SIDEBAR_WIDTH = '148px'

    const handleDisplayProject = (value: boolean) => {
        setIsShowMyProject(value)
        setShowMyProject(value)
        setLoadingFlag(true)
        // TODO: handle get project display
        setValue("page",1)
        if(!value){
            handleGetProjectList(getValues(),false)
        }
        else{
            handleGetProjectList(getValues())
        }
    }

    const setLockEdit = () => {
        //TODO
        const userLocked = listUser.filter((item:any) => item.sub ==projectSelected.userOpenSub)
        const nameUserLocked = userLocked[0].family_name + userLocked[0].given_name
        onUpdateLockedUserId({
            requestPrms: {
                userOpenId: user?.userId,
                ownerId: projectSelected.ownerId,
                lockedUserId: user?.userId,
                projectId: projectSelected.projectId,
                projectName: projectSelected.projectName,
                ownerProjectName: projectSelected.ownerProjectName,
                userLocked: nameUserLocked

            }
        })
        setLoadingFlag(true)
    }

    const setUnLockEdit = () => {
        const userLocked = listUser.filter((item:any) => item.sub ==projectSelected.userOpenSub)
        const nameUserLocked = userLocked[0].family_name + userLocked[0].given_name
        onUpdateLockedUserId({
            requestPrms: {
                userOpenId: user?.userId,
                ownerId: projectSelected.ownerId,
                lockedUserId: null,
                projectId: projectSelected.projectId,
                projectName: projectSelected.projectName,
                ownerProjectName: projectSelected.ownerProjectName,
                userLocked: nameUserLocked

            }
        })
        setLoadingFlag(true)

    }

    const handleSetProjectSelected =(data:any) =>{
        setIsLocked(data.lockedUserId !==null?true:false)
        setIsOpenLocked(data.lockedUserId !== user?.userId?true:false)
        setProjectSelected(data)
        setDisableLockprojectPermissionSee(data.permission =='view'?true:false)
    }

    const handleUpdateLockedUserSuccess =(data:any)=>{
        if(data.data.result == 0){
            setIsLocked(data.data.locked_user_id !==null?true:false)
            handleGetProjectList(getValues(),false)
        }
        else{
            handleGetProjectList(getValues(),false)
            setIsLocked(true)
            onLocked()
        }
    }

    const handleUpdateLockedUserErrorGet =(success:any,data:any,error:any)=>{
        setLoadingFlag(false)

    }
    //#endregion share project

    return (
        <>
            <Stack>
                <MainHeader user={user} modeProject/>
                <Stack position='relative'>
                    <Stack position='absolute' width={SIDEBAR_WIDTH}>
                        <Box height='calc(100vh - 58px)' sx={{ border: 1 }}>
                            <Box>
                                <Button
                                    style={isShowMyProject?styleSeleted:styleNormal}
                                    onClick={() => {handleDisplayProject(true)}}
                                >マイプロジェクト</Button>
                            </Box>
                            <Box>
                                <Button
                                    style={!isShowMyProject?styleSeleted:styleNormal}
                                    onClick={() => {handleDisplayProject(false)}}
                                >共有プロジェクト</Button>
                            </Box>
                        </Box>
                    </Stack>

                    <Stack position='absolute' width={`calc(100% - ${SIDEBAR_WIDTH})`} marginLeft={SIDEBAR_WIDTH}>
                        <Box ml={2} mt={1}>
                            <Typography fontSize={'20px'}>
                                {isShowMyProject?'マイプロジェクト':'共有プロジェクト'}
                            </Typography>
                        </Box>
                        <Box display='flex' justifyContent='flex-end' px={2}>
                            <Stack direction='row' spacing={2} mb={2}>
                                <input
                                    type="file"
                                    ref={fileInputRef}
                                    style={{ display: "none" }}
                                    onChange={handleFileChange}
                                    accept=".mv3"
                                />
                                <input
                                    type="file"
                                    ref={fileInputRefMv3c}
                                    style={{ display: "none" }}
                                    onChange={handleFileChange}
                                    accept=".mv3c"
                                />
                                {isShowMyProject? 
                                    <>
                                        <Button
                                            variant="contained"
                                            onClick={() => setOpenCopyProjectDialog(true)}
                                            disabled={uploading || analyzing || !projectSelected || user?.expired}
                                        >
                                            プロジェクトをコピーして作成
                                        </Button>
                                        <Button
                                            variant="contained"
                                            onClick={() => handleImportProject(false)}
                                            disabled={uploading || analyzing || user?.expired}
                                            startIcon={<Input />}
                                            sx={{textTransform: 'lowercase'}}
                                        >
                                            インポート（.mv3）
                                        </Button>
                                        <Button
                                            variant="contained"
                                            onClick={() => handleImportProject(true)}
                                            disabled={uploading || analyzing || user?.expired}
                                            sx={{textTransform: 'lowercase'}}
                                        >
                                            インポート（.mv3c）
                                        </Button>
                                        <Button
                                            variant="contained"
                                            onClick={() => setOpenExportJsonDialog(true)}
                                            disabled={uploading || analyzing || !projectSelected || user?.expired}
                                            sx={{textTransform: 'lowercase'}}
                                        >
                                            エクスポート（.mv3c）
                                        </Button>
                                        <Button
                                            variant="contained"
                                            onClick={() => setOpenAddProjectDialog(true)}
                                            disabled={uploading || analyzing || user?.expired}
                                            startIcon={<AddCircleOutlineIcon />}>
                                            プロジェクト追加
                                        </Button>
                                    </>
                                    :
                                    <>
                                        <Button
                                            variant="contained"
                                            onClick={setLockEdit}
                                            disabled={uploading || analyzing || !projectSelected || isLocked ||disableLockprojectPermissionSee || user?.expired}
                                        >
                                            編集ロック取得
                                        </Button>
                                        <Button
                                            variant="contained"
                                            onClick={setUnLockEdit}
                                            disabled={uploading || analyzing || !projectSelected || !isLocked ||isOpenLocked ||disableLockprojectPermissionSee}
                                        >
                                            編集 ロック解除
                                        </Button>
                                    </>
                                }
                            </Stack>
                        </Box>
                        <Box px={2}>
                            {
                                isShowMyProject?
                                <ProjectGrid
                                    ref={gridRef}
                                    pageData={pageData}
                                    keyword={keyword}
                                    isShowMyProject={isShowMyProject}
                                    userExpired={user?.expired}
                                    pageSize={getValues('limit')}
                                    onChangeSearchCondition={handleChangePageData}
                                    onSettingProjectDetails={handleShowDetailPage}
                                    onEditProjectDiagram={handleShowEditDiagramPage}
                                    onDelete={handleDelete}
                                    onProjectSelected={setProjectSelected}
                                    onShowMaintenanceScreen={handleShowMaintenanceScreen}
                                />
                                :
                                <ProjectGrid
                                    ref={gridRef}
                                    pageData={pageData}
                                    keyword={keyword}
                                    isShowMyProject={isShowMyProject}
                                    userExpired={user?.expired}
                                    pageSize={getValues('limit')}
                                    onChangeSearchCondition={handleChangePageData}
                                    onSettingProjectDetails={handleShowDetailPage}
                                    onEditProjectDiagram={handleShowEditDiagramPage}
                                    onDelete={handleDelete}
                                    onProjectSelected={handleSetProjectSelected}
                                    onShowMaintenanceScreen={handleShowMaintenanceScreen}
                                />
                            }
                        </Box>
                    </Stack>
                </Stack>
            </Stack>
            {openExportJsonDialog &&
                <AlertDialog
                    title={"プロジェクトをエクスポート (.mv3c)"}
                    message={`${projectSelected.projectName}プロジェクト(.mv3c)をエクスポートしますか?`}
                    isOpen={true}
                    onOK={handleExportProjectJson}
                    onCancel={() => setOpenExportJsonDialog(false)} 
                />
            }
            {openCopyProjectDialog &&
                <AddNewProjectDialog
                    data={{projectName: `${projectSelected.projectName}コピー`}} title={`プロジェクトをコピーして作成`}
                    onOK={handleCopyProject}
                    onCancel={() => setOpenCopyProjectDialog(false)} 
                />
            }
            {openAddProjectDialog &&
                <AddNewProjectDialog data={{ projectName: '' }} onOK={handleOkAddForm} onCancel={() => setOpenAddProjectDialog(false)} />
            }
            {openImportProjectDialog &&
                <ImportProjectDialog selectedFile={file} handleFileSelect={handleFileSelect} onOK={handleUpload} onCancel={() => setOpenImportProjectDialog(false)} />
            }
            {(loadingFlag || uploading) && (
                <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1500, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                    <CircularProgress />
                </div>
            )}
            {openDeleteDialog &&
                <AlertDialog title={"プロジェクトの削除"} message={message} isOpen={true} onOK={handleDeleteOK} onCancel={handleDeleteCancel} />
            }
            <FetchHelper fetchId={GET_PROJECT_LIST} onComplete={(success, data, error) =>
                success ? handleSuccessGet(data) : handleErrorGet(success, data, error)} />
            <FetchHelper fetchId={DELETE_PROJECT} onComplete={(success, data, error) =>
                success ? handleSuccessDelete(data) : handleErrorDelete(success, data, error)} />
            <FetchHelper fetchId={CREATE_PROJECT} onComplete={(success, data, error) =>
                success ? handleSuccessCreateProject(data) : handleErrorCreateProject(success, data, error)} />
            <FetchHelper fetchId={IMPORT_PROJECT} onComplete={(success, data, error) =>
                success ? handleSuccessImportProject(data) : handleErrorImportProject(success, data, error)} />
            <FetchHelper fetchId={GET_IMPORT_STATUS} onComplete={(success, data, error) =>
                success ? handleSuccessGetImportStatus(data) : handleErrorGetImportStatus(data)} />
            <FetchHelper fetchId={COPY_PROJECT} onComplete={(success, data, error) =>
                success ? handleSuccessCopyProject(data) : handleErrorCopyProject(error)} />

            {/* SHARE PROJECT LIST */}
            <FetchHelper fetchId={GET_SHARE_PROJECT_LIST} onComplete={(success, data, error) =>
                success ? handleSuccessGet(data) : handleErrorGet(success, data, error)} />

            <FetchHelper fetchId={UPDATE_LOCKED_USER} onComplete={(success, data, error) =>
                success ? handleUpdateLockedUserSuccess(data) : handleUpdateLockedUserErrorGet(success, data, error)} />
        </>
    );
};

const mapStateToProps = (state: ApplicationState) => ({
    user: state.app.user,
    isShowProjectStore: state.app.diagram.isShowMyProject,

});

const mapDispatchToProps = (dispatch: any) => {
    return {
        onGetProjectList: (params: any) => dispatch(actionCreators.fetch(GET_PROJECT_LIST, '/project/get', 'POST', params, false, true)),
        onUpdateLockedUserId: (params: any) => dispatch(actionCreators.fetch(UPDATE_LOCKED_USER, '/project/update-locked-user', 'POST', params, false, true)),
        
        //GET SHARE PROJECT LIST
        onGetShareProjectList: (params: any) => dispatch(actionCreators.fetch(GET_SHARE_PROJECT_LIST, '/project/get-share-project', 'POST', params, false, true)),
        
        onCopyProject: (params : any) => dispatch(actionCreators.fetch(COPY_PROJECT, '/project/copy-project', "POST", params, false, true)),
        onDelete: (params: any) => dispatch(actionCreators.fetch(DELETE_PROJECT, `/project/delete`, "POST", params, false, true)),
        onCreateProject: (params: any) => dispatch(actionCreators.fetch(CREATE_PROJECT, `/project/create`, "POST", params, false, true)),
        onImportProject: (params: any) => dispatch(actionCreators.fetch(IMPORT_PROJECT, `/project/import`, "POST", params, false, true)),
        onGetImportStatus: (params: any) => dispatch(actionCreators.fetch(GET_IMPORT_STATUS, '/project/get', 'POST', params, false, true)),

        onLocked: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "プロジェクトロック",
                body: "プロジェクトはロックされています。",
            })),

        onDeleteSuccess: () =>
            dispatch(actionCreators.showMessage({
                type: "info",
                title: "プロジェクトの削除",
                body: "プロジェクトを削除しました。",
            })),
        onErrorGet: (success: boolean, data: any, error: any) =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "検索",
                body: "検索できません。" + error,
            })),
        onErrorCreate: (success: boolean, data: any, error: any) =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "作成",
                body: "プロジェクト作成できません。" + error,
            })),
        onDeleteError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "プロジェクト削除",
                body: "プロジェクトを削除できませんでした。",
            })),
        onSuccessUpload: () =>
            dispatch(actionCreators.showMessage({
                type: "info",
                title: "プロジェクトのインポート",
                body: "プロジェクトファイルをアップロードしました。",
            })),
        onErrorUpload: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "プロジェクトのインポート",
                body: "プロジェクトファイルをアップロードできませんでした。",
            })),
        onSuccessCopyProject: () =>
            dispatch(actionCreators.showMessage({
                type: "info",
                title: "プロジェクトをコピーして作成",
                body: "プロジェクトをコピーして作成しました。",
            })),
        onErrorCopyProject: (error: any) =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "プロジェクトをコピーして作成",
                body: "プロジェクトをコピーして作成できませんでした。\n" + error,
            })),
        onErrorExportJson: (error: any) =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "プロジェクトのエクスポート",
                body: "プロジェクトをエクスポートできませんでした。\n" + error,
            })),
        isShowMaintenanceScreen:(show:boolean,controlId?:string) => dispatch(isShowMaintenanceScreen(show,controlId)),
        setShowMyProject:(show:boolean) => dispatch(isShowMyProject(show)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProjectList);
