export const NoRowsMessage: string = "データがありません";

const NoRowsOverlay = (props: any) => {

  return (
    <div
      className="ag-overlay-loading-center"
      style={{ backgroundColor: '#ffffff', height: '9%' }}
    >
      <i className="far fa-frown"> {props.noRowsMessageFunc()}</i>
    </div>
  );
};

export default NoRowsOverlay;
