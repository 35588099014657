import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import DeleteIcon from '@mui/icons-material/Delete';
import SettingsIcon from '@mui/icons-material/Settings';
import { Checkbox, FormControlLabel, Grid, IconButton } from "@mui/material";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from 'ag-grid-react';
import { forwardRef, useImperativeHandle, useMemo, useRef, useState } from "react";
import { PageData } from '../../models/Index';
import { AG_GRID_LOCALE_JP } from '../../themes/localeJP';
import LoadingOverlay, { DefaultLoadingMessage } from '../common/LoadingOverlay';
import NoRowsOverlay, { NoRowsMessage } from '../common/NoRowsOverlay';
import PaginationCpn from '../common/PaginationCpn';
import { BreakGroupModel } from '../../models/breakModel';
import dayjs from 'dayjs';

//--------------------
// Props
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
export type ShareProjectGridProps = {
    colState: any[];
    onSelect: (data: any) => void;
    heightForm?: string;
    checkEdit?: boolean;
    dataShareProjectList: any;
};

// -------------
// Component
export const ShareProjectGrid = forwardRef((props: ShareProjectGridProps, ref) => {
    const { heightForm, checkEdit ,onSelect,dataShareProjectList} = props;
    const gridRef = useRef() as any;
    const [rowData, setRowData] = useState<any>(dataShareProjectList);
    useImperativeHandle(ref, () => ({
        setRowData(value: any) {
            setRowData(value)
        },
        addData(value: any) {
            setRowData([value,...rowData])
        },

        hideOverlay() {
            gridRef.current.api.hideOverlay();
        }
    }));
    // ag-Grid の準備完了後に呼ばれるコールバック関数
    const onGridReady = (grid: any) => {
    };

    // Loading時のメッセージ
    const loadingOverlayComponent = useMemo(() => {
        return LoadingOverlay;
    }, []);
    const loadingOverlayComponentParams = useMemo(() => {
        return {
            loadingMessage: checkEdit ? "" : DefaultLoadingMessage,
        };
    }, []);

    const noRowsOverlayComponent = useMemo(() => {
        return NoRowsOverlay;
    }, []);

    const noRowsOverlayComponentParams = useMemo(() => {
        return {
            noRowsMessageFunc: () => NoRowsMessage,
        };
    }, []);


    // 全列に共通の定義
    const defaultColDef = {
        sortable: true, // ソート可
        editable: false, // 編集不可
        resizable: true,
        suppressMovable: true,  // 列の移動を無効にする
    };

    // 列タイプの定義
    const columnTypes = {
        nonEditableColumn: { editable: false }, // 編集不可
    };
    const handleChecked =(e:any, props:any) =>{
        props.data.isSelect = e.target.checked
        let rowNode = gridRef.current.api.getRowNode(props.node.id);
        gridRef.current.api.redrawRows({ rowNodes: [rowNode] });

        onSelect(gridRef.current.props.rowData.filter((e:any) => e.isSelect == true))
    }
    const CellCheckRenderer = (props: any) => {
        const { username, isSelect } = props.data;
        let isSelected = isSelect

        return (
            <FormControlLabel
                control={
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        checked={isSelected}
                        onChange={(e) => {handleChecked(e, props)}}
                    />
                }
                label={username}
            />
        );
    };

    const columnDefs = [
        {
            headerName: "ユーザー名",
            field: "username",
            rowDrag: false,
            flex: 1.5,
            cellRenderer: CellCheckRenderer,
            cellStyle: { textAlign: 'left' },
        },
        {
            headerName: "権限",
            field: "permission",
            rowDrag: false,
            flex: 1.5,
            valueFormatter: (params:any) => params.value == 'view'?'参照':'編集'
        },
        {
            headerName: "共有日",
            field: "sharedDate",
            rowDrag: false,
            flex: 1.5,
            valueFormatter: (params:any) => params.value != null? dayjs(params.value.toLocaleString()).format("YYYY/MM/DD"):params.value
        }
    ];
    const rowHeight = 48;
    return (
        <>
            <div>
                <div className="ag-theme-alpine" style={{ height: heightForm ? 'calc(100vh - ' + heightForm + 'px)' : 'calc(100vh - 275px)', width: "100%" }}>
                    <AgGridReact
                        ref={gridRef}
                        defaultColDef={defaultColDef}
                        columnTypes={columnTypes}
                        columnDefs={columnDefs}
                        domLayout={"normal"}
                        rowData={rowData}
                        animateRows={true}
                        rowDragManaged={true}
                        suppressMovableColumns={false}
                        rowSelection={'single'}
                        suppressDragLeaveHidesColumns={false}
                        rowHeight={rowHeight}
                        localeText={AG_GRID_LOCALE_JP}
                        // paginationPageSize={pageSize}
                        loadingOverlayComponent={loadingOverlayComponent}
                        loadingOverlayComponentParams={loadingOverlayComponentParams}
                        noRowsOverlayComponent={noRowsOverlayComponent}
                        noRowsOverlayComponentParams={noRowsOverlayComponentParams}
                    />
                </div>
            </div>
        </>
    );
});
export default ShareProjectGrid;