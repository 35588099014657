import { AgGridReact } from "ag-grid-react";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { AG_GRID_LOCALE_JP } from "../../themes/localeJP";
import { Grid, IconButton } from "@mui/material";
import PaginationCpn from "../common/PaginationCpn";
import { PageData } from "../../models/Index";
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import { formatDatetimeYMDHMS } from "../../utils/DataConverter";

export type MaintainSreenGridFormProps = {
  pageSize: number;
  pageData: PageData;
  handleDownloadFile: (key: any, fileName: any) => void;
  handleOpenDeleteDialog: (data: any) => void;
  handleOpenFileInNewTab: (key: any) => void;
  onChangePaginationCondition: (name: string, value: any) => void;
};

export const MaintainSreenGrid = forwardRef((props: MaintainSreenGridFormProps, ref) => {
  const {
    pageSize,
    pageData,
    handleDownloadFile,
    handleOpenDeleteDialog,
    handleOpenFileInNewTab,
    onChangePaginationCondition,
  } = props;

  const gridRef = useRef() as any;
  
  const [rowData, setRowData] = useState<any[]>([]);
  useImperativeHandle(ref, () => ({
    setRowData(value: any) {
      setRowData(value);
    },
    addData(data: any) {
      // remove old key
      const newData = rowData.filter((e: any) => e.key != data.key);
      setRowData([data, ...newData]);
    },
    deleteData(key: any) {
      const newData = rowData.filter((e: any) => e.key != key);
      setRowData(newData);
    }
  }));

  const disableEndswithFile = (fileName:string) => {
    const split = fileName.split('.')
    const end = split[split.length - 1]
    if(!['png', 'jpg', 'jpeg','pdf','mp4','mov'].includes(end.toLowerCase())){
      return true
    }else{
      return false
    }
  }

  const cellActionRenderer = (props: any) => {
    const openFileInNewTab = () => {
      handleOpenFileInNewTab(props.data.key);
    }
    const downloadFile = () => {
      handleDownloadFile(props.data.key, props.data.fileName);
    }
    const deleteFile = () => {
      handleOpenDeleteDialog(props.data);
    }
    return (
      <Grid
        container
        direction="row"
        justifyContent ="flex-end" 
      >
        {disableEndswithFile(props.data.fileName) == false && 
          <IconButton aria-label="pdf">
            <DescriptionOutlinedIcon fontSize="small" onClick={openFileInNewTab}/>
          </IconButton>
        }
        <IconButton aria-label="download" onClick={downloadFile}>
          <DownloadIcon fontSize="small" />
        </IconButton>
        <IconButton aria-label="delete" onClick={deleteFile}>
          <DeleteIcon fontSize="small" />
        </IconButton>
      </Grid>
    );
  };

  const cellFormatDatetime = (props: any) => {
    return formatDatetimeYMDHMS(props.value);
  }

  // 全列に共通の定義
  const defaultColDef = {
    sortable: true,
    editable: false,
    resizable: true,
    suppressMovable: true, // 列の移動を無効にする
  };

  const columnDefs = [
    {
      headerName: "機器名",
      field: "controlName",
      rowDrag: false,
      flex: 2
    },
    {
      headerName: "ファイル名",
      field: "fileName",
      rowDrag: false,
      flex: 3
    },
    {
      headerName: "更新日時",
      field: "lastUpdateDatetime",
      rowDrag: false,
      flex: 3,
      cellRenderer: cellFormatDatetime,
    },
    {
      headerName: "",
      field: "action",
      reseizable: false,
      suppressMovable: true,
      flex:2,
      cellRenderer: cellActionRenderer,
    },
  ];

  const columnTypes = {
    nonEditableColumn: { editable: false }, // 編集不可
  };
  const rowHeight = 42;

  return (
    <>
      <div>
        <div className="ag-theme-alpine" style={{ height: 'calc(100vh - 335px)', width: "100%" }}>
          <AgGridReact
            ref={gridRef}
            defaultColDef={defaultColDef}
            columnTypes={columnTypes}
            columnDefs={columnDefs}
            domLayout={"normal"}
            rowData={rowData}
            animateRows={true}
            rowDragManaged={true}
            suppressMovableColumns={false}
            suppressDragLeaveHidesColumns={false}
            rowHeight={rowHeight}
            localeText={AG_GRID_LOCALE_JP}
            suppressRowHoverHighlight={true}
            rowSelection={"single"}
          />
        </div>
        <PaginationCpn pageData={pageData} onChangeSearchCondition={onChangePaginationCondition} limit={pageSize} />
      </div>
    </>
  );
});

export default MaintainSreenGrid;
