import { DIRECT_NONE, LEFT_CENTER, TOP_CENTER } from "../models/Constants";
import { ControlModel, MsINVModel, ProcessMode } from "../models/Index";
import { MS_MODE_CALCULATION, MS_MODE_CHECKING, MS_MODE_DRAWING, MS_MODE_HARMONIC, MS_MODE_PERCENT_Z, MS_MODE_POWER_FLOW, MS_MODE_VOLTDROP } from "../statics";
import { BeamsNumber } from "./FormatNumber";

//CEleINV::DoGetTextVoltage
export const doGetTextVoltage = (props:MsINVModel, bUnit:boolean = true) =>
{
	let sText = props.voltage.toString();
	if (bUnit) {
		sText += 'V';
	}
	return sText;
}

//CEleINV::DoGetTextBaseCurrentTotal
export const doGetTextBaseCurrentTotal = (props:MsINVModel, bUnit:boolean = true) =>
{
	let dBaseCurrent = 0;

    for(let item of props.harmonicList) {
        dBaseCurrent += item.baseCurrent; // TODO check baseCurrent not found
    }
	let sText = BeamsNumber(dBaseCurrent, 5).toString();
	if (bUnit) {
		sText += 'pu';
	}
	return sText;
}

//CEleINV::DoGetText6PulseCapacity
export const doGetText6PulseCapacity = (props:MsINVModel, bUnit:boolean = true) =>
{
	let dTotalCapacity = 0;
	let dTotalEquivalent = 0;
    for(let item of props.harmonicList) {
        dTotalCapacity += item.capacity * item.number;
        dTotalEquivalent += item.capacity * item.number * item.coEfficient;
    }
	let sText = BeamsNumber(dTotalEquivalent, 4).toString();
	if (bUnit) {
		sText += 'kVA';
	}
	return sText;
}


//CEleINV::DoGetRefV1
export const doGetRefV1 = (mode:any, props:MsINVModel) =>
{
	//CEleINV::DoGetRefV1Left  sRefV1Left
	let labelTexts = [{
		value: props.refNo,
		color: "#f800f8",
	}];

	if (!props.viewResultText || (mode != ProcessMode.DRAWING && !props.supply))
    {
      return labelTexts;
    }

    switch(mode) {
        case ProcessMode.DRAWING:
        case ProcessMode.CHECKING:
        case ProcessMode.PERCENT_Z:
        case ProcessMode.CALCULATION:
        case ProcessMode.VOLTDROP:
            break;
        case ProcessMode.HARMONIC:
            //CHINESE 
            //  //6ﾊﾟﾙｽ等価容量
            //  labelTexts.push({
            //     value: '6ﾊﾟﾙｽ等価容量',
            //     color: "blue",
            // });

            // //doGetTextBaseCurrentTotal
            // labelTexts.push({
            //     value: doGetTextBaseCurrentTotal(props),
            //     color: "blue",
            // });

            //6ﾊﾟﾙｽ等価容量
            labelTexts.push({
                value: '6ﾊﾟﾙｽ等価容量',
                color: "blue",
            });

             //doGetText6PulseCapacity
             labelTexts.push({
                value: doGetText6PulseCapacity(props),
                color: "blue",
            });
            break;
        default:
            break;
    }

    return labelTexts;
}

//CEleINV::DoGetTextActiveLeft
export const doGetTextActiveLeft = (props:MsINVModel, nDirect:number, bUnit:boolean = true) =>
{
	let sText = '';
	if (nDirect == DIRECT_NONE) {
		if (props.lineActiveFlow1 >= 0) {
			sText = BeamsNumber(props.lineActiveFlow1, 5).toString();
		}
		else {
			sText = BeamsNumber(props.lineActiveFlow2, 5).toString();
		}
	}
	else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
		sText = BeamsNumber(Math.abs(props.lineActiveFlow1), 5).toString();
	}
	else {
		sText = BeamsNumber(Math.abs(props.lineActiveFlow2), 5).toString();
	}

	if (bUnit) {
		sText += 'kW';
	}

	return sText;
}

//CEleINV::DoGetTextReactiveLeft
export const doGetTextReactiveLeft = (props:MsINVModel, nDirect:number, bUnit:boolean = true) =>
{
	let sText = '';
	if (nDirect == DIRECT_NONE) {
		if (props.lineReactiveFlow1 >= 0) {
			sText = BeamsNumber(props.lineReactiveFlow1, 5).toString();
		}
		else {
			sText = BeamsNumber(props.lineReactiveFlow2, 5).toString();
		}
	}
	else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
		sText = BeamsNumber(Math.abs(props.lineReactiveFlow1), 5).toString();
	}
	else {
		sText = BeamsNumber(Math.abs(props.lineReactiveFlow2), 5).toString();
	}

	if (bUnit) {
		sText += 'kvar';
	}

	return sText;
}

//CEleINV::DoGetTextFlowsResult
export const doGetTextFlowsResult = (props:MsINVModel, processMode:any, nDirect:number, bSociety:boolean = false) =>
{
    let sRefV1: string[] = [];

	if (bSociety) {
		return sRefV1;
	}

	switch (processMode) {
	case MS_MODE_DRAWING:
	case MS_MODE_CHECKING:
	case MS_MODE_PERCENT_Z:
	case MS_MODE_CALCULATION:
	case MS_MODE_VOLTDROP:
	case MS_MODE_HARMONIC:
		break;
	case MS_MODE_POWER_FLOW:
		if (props.lineActiveFlow1 !==0 && props.lineActiveFlow2 !==0 && props.lineReactiveFlow1 !==0 && props.lineReactiveFlow2 !==0) {
			if (nDirect == DIRECT_NONE) {
				sRefV1.push(doGetTextActiveLeft(props,nDirect));
				sRefV1.push(doGetTextReactiveLeft(props,nDirect));
			}
			else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
                sRefV1.push(doGetTextActiveLeft(props,nDirect));
                let sTemp = ''
				if (props.lineActiveFlow1 > 0) {
					if (props.lineReactiveFlow1 > 0) {
						sTemp += "+j";
					}
					else {
						sTemp += "-j";
					}
				}
				else {
					if (props.lineReactiveFlow1 > 0) {
						sTemp += "-j";
					}
					else {
						sTemp += "+j";
					}
				}
				sRefV1.push(sTemp + doGetTextReactiveLeft(props,nDirect));
			}
			else {
                sRefV1.push(doGetTextActiveLeft(props,nDirect));
                let sTemp = ''
				if (props.lineActiveFlow2 > 0) {
					if (props.lineReactiveFlow2 > 0) {
						sTemp += "+j";
					}
					else {
						sTemp += "-j";
					}
				}
				else {
					if (props.lineReactiveFlow2 > 0) {
						sTemp += "-j";
					}
					else {
						sTemp += "+j";
					}
				}
				sRefV1.push(sTemp + doGetTextReactiveLeft(props,nDirect));
			}
		}
		break;
	}

	return sRefV1;
}
