import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Checkbox, FormControlLabel, Grid, IconButton } from "@mui/material";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from 'ag-grid-react';
import { forwardRef, useImperativeHandle, useMemo, useRef, useState } from "react";
import { PageData } from '../../../models/Index';
import { ManufactureModel } from '../../../models/breakModel';
import { AG_GRID_LOCALE_JP } from '../../../themes/localeJP';
import LoadingOverlay, { DefaultLoadingMessage } from '../../common/LoadingOverlay';
import NoRowsOverlay, { NoRowsMessage } from '../../common/NoRowsOverlay';
import PaginationCpn from '../../common/PaginationCpn';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

// -------------
// Props
export type ManufactureGridProps = {
    colState: any[];
    pageSize: number;
    pageData: PageData;
    onChangeSearchCondition: (name: string, value: any) => void;
    keyword: string;
    onDelete: (data: ManufactureModel) => void;
    onShowDetails: (lManufactureID: string) => void;
};

// -------------
// Component
export const ManufactureGrid = forwardRef((props: ManufactureGridProps, ref) => {
    const { 
        colState, 
        pageSize, 
        pageData,
        keyword,
        onChangeSearchCondition, 
        onDelete,
        onShowDetails
    } = props;

    const gridRef = useRef() as any;
    const [rowData, setRowData] = useState();
    useImperativeHandle(ref, () => ({
        setRowData(value: any) {
            setRowData(value)
        },

        hideOverlay() {
            gridRef.current.api.hideOverlay();
        }
    }));

    // ag-Grid の準備完了後に呼ばれるコールバック関数
    const onGridReady = (grid: any) => {
    };

    // Loading時のメッセージ
    const loadingOverlayComponent = useMemo(() => {
        return LoadingOverlay;
    }, []);
    const loadingOverlayComponentParams = useMemo(() => {
        return {
            loadingMessage: DefaultLoadingMessage,
        };
    }, []);

    const noRowsOverlayComponent = useMemo(() => {
        return NoRowsOverlay;
    }, []);

    const noRowsOverlayComponentParams = useMemo(() => {
        return {
            noRowsMessageFunc: () => NoRowsMessage,
        };
    }, []);

    // 編集ボタンのセル内表示とボタンイベント
    const cellMessageRenderer = (props: any) => {
        // 詳細
        const handleEdit = () => {
            onShowDetails(props.data.lmanufactureID);
        };

        const handleDelete = () => {
            onDelete(props.data.lmanufactureID);
        };

        return (
        <Grid container direction="row" justifyContent="flex-start" paddingLeft={"-60px"}>
            <IconButton aria-label="edit" onClick={handleEdit} >
                <EditIcon fontSize="small" />
            </IconButton>
            <IconButton aria-label="delete" onClick={handleDelete} >
                <DeleteIcon fontSize="small" />
            </IconButton>
        </Grid>
        );
    };

    // 全列に共通の定義
    const defaultColDef = {
        sortable: true, // ソート可
        editable: false, // 編集不可
        resizable: true,
        suppressMovable: true,  // 列の移動を無効にする
    };

    // 列タイプの定義
    const columnTypes = {
        nonEditableColumn: { editable: false }, // 編集不可
    };

    // 列の個別定義
    const columnDefs = [
        {
            headerName: "メーカーID",
            field: "lmanufactureID",
            rowDrag: false,
            flex: 1.5
        },
        {
            headerName: "メーカー名",
            field: "smanuName",
            rowDrag: false,
            flex: 4
        },
        {
            headerName: "メーカー名カタカナ",
            field: "smanuKana",
            rowDrag: false,
            flex: 4,
            headerComponentParams: { template: '<div style = "white-space: normal;">メーカー名カタカナ<br/></div>' }
        },
        {
            headerName: "電源デフォルト",
            field: "bisDefSource",
            rowDrag: false,
            flex: 1.25,
            headerComponentParams: { template: '<div style = "text-align: center; white-space: normal;">電源<br/>デフォルト</div>' }
        },
        {
            headerName: "HVCBデフォルト",
            field: "bisDefHVCB",
            rowDrag: false,
            flex: 1.25,
            headerComponentParams: { template: '<div style = "text-align: center; white-space: normal;">HVCB<br/>デフォルト</div>' }
        },
        {
            headerName: "LVCBデフォルト",
            field: "bisDefLVCB",
            rowDrag: false,
            flex: 1.25,
            headerComponentParams: { template: '<div style = "text-align: center; white-space: normal;">LVCB<br/>デフォルト</div>' }
        },
        {
            headerName: "サーマルデフォルト",
            field: "bisDefThermal",
            rowDrag: false,
            flex: 1.25,
            headerComponentParams: { template: '<div style = "text-align: center; white-space: normal;">サーマル<br/>デフォルト</div>' }
        },
        {
            headerName: "ヒューズデフォルト",
            field: "bisDefFuse",
            rowDrag: false,
            flex: 1.25,
            headerComponentParams: { template: '<div style = "text-align: center; white-space: normal;">ヒューズ<br/>デフォルト</div>' }
        },
        {
            headerName: "2Eデフォルト",
            field: "bisDef2E",
            rowDrag: false,
            flex: 1.25,
            headerComponentParams: { template: '<div style = "text-align: center; white-space: normal;">2E<br/>デフォルト</div>' }
        },
        {
            headerName: "",
            field: "option",
            reseizable: false,
            suppressMovable: true,
            minWidth: 110,
            maxWidth: 110,
            cellRenderer: cellMessageRenderer,
        }
    ];
    const handleEdit = (data:any) => {
        onShowDetails(data.data.lmanufactureID);
    }

    const rowHeight = 48;
    gridRef.current?.api?.setQuickFilter(keyword)
    return (
        <>
            <div>
                <div className="ag-theme-alpine" style={{ height: 'calc(100vh - 285px)', width: "100%" }}>
                    <AgGridReact
                        ref={gridRef}
                        defaultColDef={defaultColDef}
                        columnTypes={columnTypes}
                        columnDefs={columnDefs}
                        onGridReady={onGridReady}
                        domLayout={"normal"}
                        rowData={rowData}
                        animateRows={true}
                        rowDragManaged={true}
                        suppressMovableColumns={false}
                        suppressDragLeaveHidesColumns={false}
                        rowHeight={rowHeight}
                        localeText={AG_GRID_LOCALE_JP}
                        paginationPageSize={pageSize}
                        onCellDoubleClicked={handleEdit}
                        loadingOverlayComponent={loadingOverlayComponent}
                        loadingOverlayComponentParams={loadingOverlayComponentParams}
                        noRowsOverlayComponent={noRowsOverlayComponent}
                        noRowsOverlayComponentParams={noRowsOverlayComponentParams}
                    />
                </div>
                <PaginationCpn pageData={pageData} onChangeSearchCondition={onChangeSearchCondition} limit={pageSize} />
            </div>
        </>
    );
});

export default ManufactureGrid;