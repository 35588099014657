import * as Element from "../models/ElementKind";
export const POINTER_WIDTH = 20;
export const POINTER_HEIGHT = 20;
export const CANVAS_WIDTH = 50 * 20; // 50 cells
export const CANVAS_HEIGHT = 35 * 20; // 35 cells

export const MIN_X_FRAME_COUNT_TC = 29;
export const MIN_Y_FRAME_COUNT_TC = 15;
export const MAX_WIDTH_TC_INFO = 940; // 47 cells

export const TC_INFO_TABLE_HEIGHT = 168
export const DASH_BOARD_RIGHT_WIDTH = 124

export const TYPE_LIST = [
    Element.MS_SOURCE,
    Element.MS_MOTOR,
    Element.MS_GENERATOR, 
	Element.MS_TRANS3, 
	Element.MS_3WINDING, 
	Element.MS_TRANS1, 
	Element.MS_TRANSSCOTT, 
	Element.MS_DS, 
	Element.MS_LBS, 
	Element.MS_MVCB, 
	Element.MS_CONTACTOR, 
	Element.MS_HVCB, 
	Element.MS_FUSE, 
	Element.MS_2E,
	Element.MS_ZEROCOND, 
	Element.MS_CONNECT, 
	Element.MS_WIRE, 
	Element.MS_BUSBAR, 
	Element.MS_BUSDUCT, 
	Element.MS_REACTOR, 
	Element.MS_IMPEDANCE, 
	Element.MS_LVCB, 
	Element.MS_THERMAL, 
	Element.MS_THERMAL_CT, 
	'image4', 
	Element.MS_MOTOR_GROUP, 
	Element.MS_LOAD, 
	Element.MS_LIGHTBOARD, 
	Element.MS_POWERBOARD, 
	Element.MS_CAPACITOR,
    Element.MS_TRANSCENTER,
    Element.MS_VCT,
    Element.MS_VT,
    Element.MS_ARRESTOR,
    Element.MS_EARTH,
    Element.MS_CABLEHEAD,
    Element.MS_CT,
    Element.MS_ZCT,
    Element.MS_INV,
    Element.MS_RELAY,
    Element.MS_TEXT,
    Element.MS_ELEMENT_ALL
];

export const DEFAULT_HEIGHT = 60;
export const PRESS_COPY_KEY = 'c';
export const PRESS_CUT_KEY = 'x';
export const PRESS_PASTE_KEY = 'v';
export const PRESS_DELETE_KEY = 'Delete';
export const PRESS_UNDO_KEY = 'z';
export const PRESS_SELECT_ALL_KEY = 'a';
export const ELEMENT_SIZE = 20;
export const LABEL_WIDTH = 60;
export const LINE_HEIGHT = 11.5;

export enum MenuOptions {
	FILE = "file",
	FILE_SAVE = "file-save",
	FILE_SAVE_AS = "file-save-as",
	FILE_DXF = "file-dxf",
	FILE_CIRCUIT = "file-circuit",
	FILE_TOOL = "file-tool",
	FILE_TOOL_PASS = "file-tool-pass",
	FILE_TOOL_PASS_DEL = "file-tool-pass-del",
	EDIT = "edit",
	EDIT_UNDO = "edit-undo",
	EDIT_CUT = "edit-cut",
	EDIT_COPY = "edit-copy",
	EDIT_PASTE = "edit-paste",
	EDIT_DELETE = "edit-delete",
	EDIT_SEL_ALL = "edit-sel-all",
	DIAGRAM = "diagram",
	DIAGRAM_SETTING = "diagram-setting",
	DIAGRAM_RE_DEVICE_NAME = "diagram-re-device-name",
	DIAGRAM_RE_POINT_NAME = "diagram-re-point-name",
	EDIT_SETTING_BAR = "edit-setting-bar",
	DISP = "disp",
	DISP_DIAGRAM = "disp-diagram",
	DISP_GRAPH = "disp-gragh",
	DISP_REPORT = "disp-report",
	EDIT_GROUP_LIST = "edit-group-list",
	EDIT_ELE_LIST = "edit-ele-list",
	EDIT_POINT_LIST = "edit-point-list",
	GRAPH = "graph",
	GRAPH_SETTING = "graph-setting",
	GRAPH_DELETE = "graph-delete",
	HELP = "help",
	HELP_HELP = "help-help",
	HELP_VERSION = "help-version",
	FILE_PDF_LINE_DIAGRAM = "file-pdf-line-diagram",
	FILE_PDF_MAIN_DIAGRAM = "file-pdf-main-diagram",
	FILE_PDF_MAIN_AND_LINE_DIAGRAM = "file-pdf-main-and-line-diagram",
	EDIT_HISTORY = "edit-history"
}

export const COLOR_LIST = ["#000000","#00008b","#800000","#008000","#0000ff","#9dff00","#ff0000","#67968a","#a020f0","#808000","#808080","#9bedff","#ff80c5","#ffff00","#c0c0c0"]


// 機器種類フラグ
export const RS_KIND_HVCB  = 1; // 過電流継電器
export const RS_KIND_LVCB = 2; // 低圧遮断器
export const RS_KIND_THERMAL = 3;// サーマル
export const RS_KIND_FUSE = 4;// ヒューズ
export const RS_KIND_2E = 5;// 電動機保護リレー
export const RS_KIND_EPC_OCR = 6;// 電力会社OCR

// ダイアルフラグ 
export const RS_DIAL_NONE  = 1; // 未使用
export const RS_DIAL_FIXED = 2; // 固定可変
export const RS_DIAL_CONTINUE = 3; // 連続可変
export const RS_DIAL_MIX = 4; // 固定可変と連続可変の混合

// 曲線種類
export const RS_CURVE_MAX = 1; // 最大値
export const RS_CURVE_CENTER = 2; // 中心値
export const RS_CURVE_MIN = 3; // 最小値

// 曲線設定基準
export const RS_CURVE_MULTIPLE = 1; // 倍数値で設定
export const RS_CURVE_CURRENT = 2; // 電流値で設定
export const RS_CURVE_VOLTAGE = 3; // 電圧で設定
export const RS_CURVE_12VOLTAGE  = 4; // 1次2次電圧で設定

export const CALC_IK3 = 10
export const CALC_IK2 = 20
export const CALC_IK2_OUTSIDE_NEUTRAL = 30
export const CALC_IK2_OUTSIDE_OUTSIDE = 40
export const CALC_IK2_SEAT_MAIN = 50
export const CALC_IK2_SEAT_T = 60

export const VALUE_NODE_VOLT_MIN = 0.00001
export const MS_KW_TO_HORSEPOWER = 1.341
export const MS_PI = 3.14159265358979
export const MS_XR_MAX = 99999

export const MS_TRANS1 = 22
export const MS_TRANSSCOTT = 24
export const MS_3PHASES = 0
export const MS_OUTSIDE_NEUTRAL = 1
export const MS_OUTSIDE_OUTSIDE = 2
export const MS_SEAT_MAIN = 3
export const MS_SEAT_T = 4

export const RS_3PHASE = 1
export const RS_PHASE_N = 2
export const RS_PHASE_PE = 3

export const BUSBAR_CU = 1
export const BUSBAR_AL = 2

export const BUSBAR_BARE = 1
export const BUSBAR_PAINTED = 2
export const BUSBAR_VERTICAL = 1
export const BUSBAR_HORIZONTAL = 2
export const BUSDUCT_CU = 1
export const BUSDUCT_AL = 2

export const WIRE_CU = 1
export const WIRE_AL = 2

export const BUSBAR_TEMP_COEFFICIENT_CU = 0.00393
export const BUSBAR_TEMP_COEFFICIENT_AL = 0.00403
export const GET_TOP = 1
export const GET_BOTTOM = 2
export const GET_RIGHT = 3
export const GET_LEFT = 4
export const GET_TOP_BOTTOM = 5
export const GET_LEFT_RIGHT = 6
export const GET_TOP_BOTTOM_LEFT_RIGHT = 7

export const MS_VOLTDROP_MOTOR = 0;
export const MS_VOLTDROP_REGULAR = 1;

export const MS_TOPLEFT = 1;
export const MS_BOTTOMRIGHT = 2;

export const DIRECT_NONE = 0;
export const TOP_LEFT = 1;
export const TOP_CENTER = 16;
export const TOP_RIGHT = 2;
export const BOTTOM_LEFT = 8;
export const BOTTOM_CENTER = 64;
export const BTTOM_RIGHT = 4;
export const LEFT_TOP = 256;
export const LEFT_CENTER = 128;
export const LEFT_BOTTOM = 512;
export const RIGHT_TOP = 1024;
export const RIGHT_CENTER = 32;
export const RIGHT_BOTTOM = 2048;

export const MS_ROTATE_NORMAL = 0;
export const MS_ROTATE_LEFT = 1;
export const MS_ROTATE_RIGHT = 2;

export const CALC_PER_Z_TYPE_DIRECT_MG = 0;
export const CALC_PER_Z_TYPE_TOTAL_MG = 1;
export const CALC_PER_Z_TYPE_DIRECT = 2;
export const CALC_PER_Z_TYPE_TOTAL = 3;
export const CALC_PER_Z_TYPE_MOTOR = 4;
export const CALC_PER_Z_TYPE_ZEROCOND = 5

export const COMP_PER_Z_CALC_TYPE_MG = 0;
export const COMP_PER_Z_CALC_TYPE = 1;

export const COMP_ELE_CALC_KIND_MG_1 = 0;
export const COMP_ELE_CALC_KIND_MG_2 = 1;
export const COMP_ELE_CALC_KIND_1 = 2;
export const COMP_ELE_CALC_KIND_2 = 3;

export const IMPEDANCE_INPUT_PERCENT = 1;
export const IMPEDANCE_INPUT_OHM = 2;

export const VALUE_POWER_MIN = 0.00001;

export const HEIGHT_OF_OPTION_MENU = 434.5;

export const ROLE_LP = "LIGHT_PACK"
export const ROLE_SP = "SERVICE_PACK"
export const ROLE_PM = "PLANT_MAINTENANCE_PACK"
export const ROLE_ADMIN = "ADMINISTRATOR" // 管理者モード


// File DXF
export const MS_FRAME_WIDTH = 40
export const MS_FRAME_HEIGHT = 40

export const MS_BASE_WIDTH = 39
export const MS_BASE_HEIGHT = 39

export const MS_DIF_WIDTH = MS_FRAME_WIDTH - MS_BASE_WIDTH;
export const MS_DIF_HEIGHT = MS_FRAME_HEIGHT - MS_BASE_HEIGHT;

export const MS_SYMBOL_SIZE = 27

export const IDS_EXT_DXF = "DXF ファイル (*.dxf)|*.dxf||"

export const IDR_VIEW1_FRAME_PM = "\n図面\nMSSV3\nMSSV3 File (*.mv3)\n.mv3\nMSSV3.Document\nMSSV3 Document"
export const IDR_VIEW1_FRAME_SERVICE = "\n図面\nMSSV3\nMSSV3 File (*.mv3)\n.mv3\nMSSV3.Document\nMSSV3 Document"
export const IDR_VIEW1_FRAME_LIGHT = "\n図面\nMSSV3\nMSSV3 File (*.mv3)\n.mv3\nMSSV3.Document\nMSSV3 Document"

export const NAME_DXF_SKELETON = "DXFSkeleton.dxf"
export const NAME_DXF_GRAPH = "DXFGraph.dxf"
export const NAME_DXF_GRAPH_H = "DXFGraphH.dxf"
export const NAME_DXF_GRAPH_L = "DXFGraphL.dxf"

export const DXF_RECT_TOP = 469.0
export const DXF_RECT_BOTTOM = 4.0
export const DXF_RECT_LEFT = 4.0
export const DXF_RECT_RIGHT = 800.0

export const DXF_LAYER_LINE = 1
export const DXF_LAYER_TITLE = 2
export const DXF_LAYER_SYMBOL = 3
export const DXF_LAYER_REFNAME = 4
export const DXF_LAYER_REFV1 = 5
export const DXF_LAYER_TEXTBOX = 6

export const IDS_STS_NO_DATA = "データなし"

export const IDS_PRJ_DRAWING = "編集"
export const IDS_PRJ_CHEKING = "回路チェック"
export const IDS_PRJ_CALCULATION = "短絡電流解析"
export const IDS_PRJ_DROP_MOTOR = "始動時電圧降下解析"
export const IDS_PRJ_DROP_REGULAR = "定常時電圧降下解析"
export const IDS_PRJ_POWERFACTOR = "力率改善解析"
export const IDS_PRJ_HARMONIC = "高調波解析"
export const IDS_PRJ_POWER_FLOW = "潮流解析"
export const IDS_PRJ_PERCENT_Z = "インピーダンスマップ"

export const IDS_TITLE_MOTOR = "電動機"

export const CONTROL_SCREEN = 'ControlScreen'
export const CONTROL_GRAPH_SCREEN = 'ControlGraphScreen'
export const GRAPH_SCREEN = 'GraphScreen'
export const REPORT_SCREEN = 'ReportScreen'

export const IDS_UNIT_AMPERE = "A"
export const IDS_UNIT_HZ = "Hz"

export const IDS_VIEW2_DELAY = "遅延設定"

export const IDS_STS_LOCK ="ﾛｯｸ(不動作)"
export const IDS_SIGN_MULTIPLE = "x"

export const PS_SOLID = 0
export const PS_DASH = 1
export const PS_DOT = 2
export const PS_DASHDOT = 3
export const PS_DASHDOTDOT = 4

export const GRAPH_SIZE_SOCIETY = 4342.9