import { Button, CircularProgress, Grid, Stack, TextField, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import { useForm } from 'react-hook-form';
import { connect } from "react-redux";
import { PageData } from "../../../models/Index";
import { ApplicationState, FetchState, actionCreators, selectors } from "../../../store";
import FetchHelper from '../../FetchHelper';
import { CurveHeaderModel, CurvePointModel } from '../../../models/breakModel';
import CurvePointGrid from './CurvePointGrid';
import { Path as RoutePath } from "../../../path";
import { useNavigate } from 'react-router-dom';
import AlertDialog from '../../common/AlertDialog';

// -------------
// FETCH ID
const SEARCH_CURVE_POINT = "SEARCH_CURVE_POINT";
const DELETE_CURVE_POINT = "DELETE_CURVE_POINT";

// 検索フォーム
export interface SearchItemForm {
    "target": string;
    "limit": number;
    "page": number;
}

// 初期値設定
const initialValue: SearchItemForm = {
    target: '',
    limit: 50,
    page: 1,
}

const initialPageData: PageData = {
    rowFrom: '0',
    rowTo: '0',
    totalRow: '0',
    currPage: '0',
    totalPage: '0',
};

const initialCurvePoint: CurvePointModel = {
    lPointID: 0,
    mv3curveCurveHeader:{}as any,
    dXPoint: "",
    dYPoint: "",
    bUseInst: false
}

// -------------
// Props
export type CurvePointListProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

// -------------
// Component
export const CurvePointList = (props: CurvePointListProps) => {
    const {
        onSearch,
        onDelete,
        onError,
        onDeleteSuccess,
        onDeleteError
    } = props;

    const { setValue, getValues } = useForm<SearchItemForm>({
        criteriaMode: "all",
        defaultValues: initialValue,
    });
    const [openSearch, setOpenSearch] = React.useState(false);
    const [title, setTitle] = React.useState("");
    const [message, setMessage] = React.useState("");
    const [pageData, setPageData] = React.useState(initialPageData);
    const [listData, setListData] = React.useState<CurvePointModel[]>([]);
    const [openAddDialog, setOpenAddDialog] = React.useState(false);
    const [openEditDialog, setOpenEditDialog] = React.useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
    const [selectData, setSelectData] = React.useState<CurvePointModel>(initialCurvePoint);
    const [disableEdit, setDisableEdit] = React.useState(true);
    const [disableDelete, setDisableDelete] = React.useState(true);
    const [colState, setColState] = useState<any>();
    const gridRef = useRef() as any;
    const [loadingFlag, setLoadingFlag] = React.useState(true);
    const [keyword, setKeyword] = useState("");

    const [idDelete, setIdDelete] = React.useState("")

    let navigate = useNavigate();

    // 検索実行
    const handleSearchSubmit = (value: SearchItemForm) => {

        const params = new Map<string, any>();
        if (value.limit) params.set("limit", value.limit);
        params.set("page", value.page);
        
        setLoadingFlag(true);
        onSearch(params);
    }

    // 初回検索実行
    React.useEffect(() => {
        handleSearchSubmit(getValues());
        // eslint-disable-next-line
    }, []);


    const handleSuccess = (data: any) => {
        console.log('>>> handleSuccess------');
        if (data && data.mv3curveCurvePointList) {
            if (gridRef && gridRef.current) {
                gridRef.current.setRowData(data.mv3curveCurvePointList);
            }
            setListData(data.mv3curveCurvePointList);

            const totalCount = data.totalCount;
            const pageSize = getValues('limit');
            const page = Number(data.page);
            const totalPage = Math.ceil(totalCount / pageSize);
            const rowFrom = (pageSize * page - pageSize) + 1;
            const rowTo = (pageSize * page) > totalCount ? totalCount : (pageSize * page);

            const updateData: PageData = {
                rowFrom: rowFrom.toLocaleString(),
                rowTo: rowTo.toLocaleString(),
                totalRow: totalCount.toLocaleString(),
                currPage: page.toLocaleString(),
                totalPage: totalPage.toLocaleString(),
            };

            setPageData(updateData);
        }else{
            if (gridRef && gridRef.current) {
                gridRef.current.setRowData([]);
            }
            setListData(() => [...[] as any]);
        }

        setLoadingFlag(false);
    };

    // 検索エラー時
    const handleError = (success: boolean, data: any, error: any) => {
        console.log('>>> handleError');
        onError(success, data, error);
        setLoadingFlag(false);
        gridRef.current.hideOverlay();
    };

    const handleSuccessDelete = (data: any) => {
        console.log('>>> handleSuccessDelete');
        onDeleteSuccess()
        handleSearchSubmit(getValues());
    };

    // 検索エラー時
    const handleErrorDelete = (success: boolean, data: any, error: any) => {
        console.log('>>> handleErrorDelete');
        onDeleteError()
    };

    // ページ数変更時にAPI検索する
    const handleChangePageData = (name: any, value: any) => {
        // 値変更
        setValue(name, value);
        if (name === 'limit') {
            setValue("page", 1);
        }
        handleSearchSubmit(getValues());
    };

    //削除の処理
    const handleDelete = (data: any) => {
        setIdDelete(data);
        const msg = "ID: " + data + "を削除します。"
        setTitle("曲線座標情報削除");
        setMessage(msg);
        setOpenDeleteDialog(true);
    }

    // 削除確認ダイアログでOKボタンのイベントを受け取って、APIに送信する
    const handleDeleteOK = () => {
        setOpenDeleteDialog(false);
        onDelete(idDelete);
        setIdDelete("");
    }

    // 削除確認ダイアログでCancelボタンのイベントを受け取って、ダイアログを無効にする
    const handleDeleteCancel = () => {
        setIdDelete("");
        setOpenDeleteDialog(false);
    }

    //削除の処理
    const handleShowDetailPage = (id: any) => {
        navigate(RoutePath.CurvePointEdit + `/edit/${id}`)
    }

    return (
        <>
            {loadingFlag && (
                <div style={{ 
                        top: "0px", left: '0px', position: "fixed", 
                        zIndex: 1500, width: '100%', height: '100%', 
                        padding: '50vh 50% 50% 50%', background: '#00000030' 
                    }}>
                    <CircularProgress />
                </div>
            )}
            {openDeleteDialog &&
                <AlertDialog 
                    title={title} 
                    message={message} 
                    isOpen={true} 
                    onOK={handleDeleteOK} 
                    onCancel={handleDeleteCancel} 
                />
            }
            
            <div style={{ height: '100%', width: '100%', marginTop: '10px' }}>
                <Stack 
                    direction="row" 
                    justifyContent="flex-start" 
                    alignItems="center" 
                    sx={{}} 
                    style={{margin: '18px',color: 'MenuText'}}
                >
                    <Typography variant="h4">
                        曲線座標
                    </Typography>
                </Stack>
                <div style={{ marginBottom: "10px" }}>
                    <Stack direction="row">
                        <div style={{ marginInlineStart: 'auto' }}>
                            <Grid 
                                container 
                                justifyContent="flex-start" 
                                alignItems={"center"} 
                                direction="row" 
                                spacing={1} 
                            >
                                <Grid item>
                                    <TextField
                                        fullWidth
                                        autoComplete="searchStr"
                                        name="keyword"
                                        type="text"
                                        label="フィルター"
                                        size="small"
                                        value={keyword}
                                        onChange={e => setKeyword(e.target.value)}
                                    />
                                </Grid>
                                <Grid item>
                                    <Button variant="outlined" onClick={() => setKeyword("")}>クリア</Button>
                                </Grid>
                            </Grid>
                        </div>
                    </Stack>
                </div>
                <div>
                    <CurvePointGrid
                        ref={gridRef}
                        colState={colState}
                        pageSize={getValues('limit')}
                        pageData={pageData}
                        onChangeSearchCondition={handleChangePageData}
                        keyword={keyword}
                        onShowDetails={handleShowDetailPage}
                        onDelete={handleDelete}
                    />
                </div>
            </div>
            <FetchHelper fetchId={SEARCH_CURVE_POINT} onComplete={(success, data, error) =>
            success ? handleSuccess(data) : handleError(success, data, error)} />
            <FetchHelper fetchId={DELETE_CURVE_POINT} onComplete={(success, data, error) =>
            success ? handleSuccessDelete(data) : handleErrorDelete(success, data, error)} />
        </>
    );
};

const mapStateToProps = (state: ApplicationState) => ({
    fetchState: selectors.getFetchState(
        state,
        SEARCH_CURVE_POINT
      ) as FetchState,
});

const mapDispatchToProps = (dispatch: any) => {
    return {
        onSearch: (params: { [key: string]: any }) => dispatch(actionCreators.fetch(SEARCH_CURVE_POINT, `/api/mv3curve_curve_point`, "GET", params)),
        onDelete: (id: any) => dispatch(actionCreators.fetch(DELETE_CURVE_POINT, `/master/curve_point/delete`, "POST",id, false,true)),
        onDeleteSuccess: () =>
            dispatch(actionCreators.showMessage({
                type: "info",
                title: "曲線座標削除",
                body: "曲線座標を削除しました。",
            })),
        onError: (success: boolean, data: any, error: any) =>
            dispatch(actionCreators.showMessage({
                    type: "error",
                    title: "検索",
                    body: "検索できません。" + error,
                })),
        onDeleteError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "曲線座標削除",
                body: "曲線座標を削除できませんでした。",
            })),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CurvePointList);
