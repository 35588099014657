import { LoadingButton } from "@mui/lab";
import { Box, Button, Checkbox, CircularProgress, FormControlLabel, Grid, Stack, Tab, Tabs, TextField, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BreakGroupModel, ManufactureModel } from "../../../models/breakModel";
import { Path as RoutePath } from "../../../path";
import { ApplicationState, actionCreators, selectors } from "../../../store";
import FetchHelper from "../../FetchHelper";
import BreakGroupGrid from "../BreakGroup/BreakGroupGrid";
import AlertDialog from "../../common/AlertDialog";

// -------------
// FETCH ID
const GET_MANUFACTURE = "GET_MANUFACTURE";
const CREATE_MANUFACTURE = "CREATE_MANUFACTURE";
const UPDATE_MANUFACTURE = "UPDATE_MANUFACTURE";
const DELETE_BREAKGROUP = "DELETE_BREAKGROUP";
const GET_BREAKGROUP_BY_ID = "GET_BREAKGROUP_BY_ID";
const GET_MANUFACTURE_BY_ID = "GET_MANUFACTURE_BY_ID";

// 初期値設定
const initialValue: ManufactureModel = {
    lManufactureID: 0,
    bIsDefSource: false,
    bIsDefHVCB: false,
    bIsDefLVCB: false,
    bIsDefThermal: false,
    bIsDefFuse: false,
    bIsDef2E: false,
    sManuName: "",
    sManuKana: ""
}

// -------------
//Tabs------------------
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `oilManagement-tab-${index}`,
        'aria-controls': `oilManagement-tabpanel-${index}`,
    };
}

// -------------
// Props
export type ManufactureEditProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>


export const ManufactureEdit = (props: ManufactureEditProps) => {
    const {
        userId,
        fetchUpdate,
        onCreate,
        onUpdate,
        onSuccess,
        onDeleteBreakGroup,
        onSuccessDeleteBreakGroup,
        onSearchById,
        onError
    } = props;
    const { control, handleSubmit, setValue, getValues } = useForm<ManufactureModel>({
        criteriaMode: "all",
        defaultValues: initialValue,
    });

    type MyParams = {
        id: string;
        mode: string;
    };

    let { id, mode } = useParams<keyof MyParams>() as MyParams;

    let navigate = useNavigate();

    const location = useLocation();
    let tab = location.state && location.state.tab;
    const object = {
        requestPrms:{
            id:id
        },
        userId: userId,
    }

    const [selectTab, setSelectTab] = useState(0);
    const [loadingFlag, setLoadingFlag] = useState(mode === "edit" ? true : false);
    const gridRef = useRef() as any;
    const [colState, setColState] = useState<any>();

    const [breakGroupList, setMv3breakBreakGroupList] = useState<BreakGroupModel>();
    const [idDelete, setIdDelete] = useState<string>("");
    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");
    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
    const handleSuccess = (data: any) => {
        console.log('>>> handleSuccess');
        setValue("sManuName", data.data.smanuName)
        setValue("sManuKana", data.data.smanuKana)
        setValue("lManufactureID", data.data.lmanufactureID)
        setValue("bIsDefSource", data.data.bisDefSource)
        setValue("bIsDefHVCB", data.data.bisDefHVCB)
        setValue("bIsDefLVCB", data.data.bisDefLVCB)
        setValue("bIsDefThermal", data.data.bisDefThermal)
        setValue("bIsDefFuse", data.data.bisDefFuse)
        setValue("bIsDef2E", data.data.bisDef2E)

        if (data.breakGroupList.length > 0) {
            setMv3breakBreakGroupList(data.breakGroupList)
        }
        setLoadingFlag(false)
        if (tab !== undefined && tab !== null) {
            setSelectTab(tab)
        }
    };

    // 検索エラー時
    const handleError = (success: boolean, data: any, error: any) => {
        console.log('>>> handleError');
        gridRef.current.hideOverlay();
    };

    const tabHandleChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectTab(newValue);
        navigate(".", {
            replace: true,
            state: {
                tab: newValue
            }
        });
    }

    // キャンセル
    const handleCancel = () => {
        if (location.key !== "default")
            navigate(-1);
        else
            navigate(RoutePath.ManufactureList);
    }

    // Submitイベント
    const handleEditSubmit = (value: ManufactureModel) => {
        setLoadingFlag(true)
        if (mode === "edit") {
             onUpdate({
                "userId": userId,
                "requestPrms":{
                    lManufactureID: getValues("lManufactureID"),
                    bIsDef2E: getValues("bIsDef2E"),
                    bIsDefFuse: getValues("bIsDefFuse"),
                    bIsDefHVCB: getValues("bIsDefHVCB"),
                    bIsDefLVCB: getValues("bIsDefLVCB"),
                    bIsDefSource: getValues("bIsDefSource"),
                    bIsDefThermal: getValues("bIsDefThermal"),
                    sManuKana: getValues("sManuKana"),
                    sManuName: getValues("sManuName")
                  }
            }               
            );
        }
        
        else if (mode === "add") {
            onCreate({
                "userId": userId,
                "requestPrms":{
                    lManufactureID: getValues("lManufactureID"),
                    bIsDef2E: getValues("bIsDef2E"),
                    bIsDefFuse: getValues("bIsDefFuse"),
                    bIsDefHVCB: getValues("bIsDefHVCB"),
                    bIsDefLVCB: getValues("bIsDefLVCB"),
                    bIsDefSource: getValues("bIsDefSource"),
                    bIsDefThermal: getValues("bIsDefThermal"),
                    sManuKana: getValues("sManuKana"),
                    sManuName: getValues("sManuName")
                  }
                
            })
        }
        
    }

    // Submit後のAPI呼び出し成功時
    const handleSubmitSuccess = (success: boolean, data: any, error: any) => {
        onSuccess();
        if (mode === "add") {
            navigate(RoutePath.ManufactureEdit + `/edit/${data.data.lmanufactureID}`, {replace: true})
        }
        setLoadingFlag(false)
    }

    // Submit後のAPI呼び出しエラー時
    const handleSubmitError = (success: boolean, data: any, error: any) => {
        onError();
        setLoadingFlag(false);
    }

    //削除の処理
    const handleDelete = (data: any) => {
        setIdDelete(data);
        const msg = "ID: " + data + "を削除します。"
        setTitle("メーカー情報削除");
        setMessage(msg);
        setOpenDeleteDialog(true);
    }

    const handleSuccessRefresh = (data: any) => {
        setMv3breakBreakGroupList(data.breakGroupList);
        gridRef.current.setRowData(data.breakGroupList);
    }
    //削除の処理
    const handleShowDetailPage = (data: any) => {
        navigate(RoutePath.BreakGroupEdit + `/edit/${data}`,
            {
                state: {
                    lManufactureID: id,
                }
            })
    }
    const handleSuccessDelete = (data: any) => {
        console.log('>>> handleSuccessDelete');
        setLoadingFlag(false);
        onSuccessDeleteBreakGroup();
        onSearchById({
            userId: userId,
            requestPrms:{
                id :id
            }
        });
    };
    //入力欄背景
    const divStyles = {
        backgroundColor: 'white',
        border: '1px solid #bebebe',
        boxShadow: '0 0 5px #fff, 0 0 5px #bebebe, 0 0 1px #aaa',
        padding: '20px 40px',
        marginTop: '40px',
    }

    //分析一覧を表示
    const handleAdd = () => {
        navigate(RoutePath.BreakGroupEdit + `/add`,
            {
                state: {
                    lManufactureID: id,
                    sManuName: getValues("sManuName"),
                }
            })
    }

    //分析一覧を表示
    const resetGrid = () => {
        if (selectTab === 1) {
            gridRef.current.setRowData(breakGroupList);
        }
    }
    useEffect(resetGrid, [selectTab]);

    // 削除確認ダイアログでOKボタンのイベントを受け取って、APIに送信する
    const handleDeleteOK = () => {
        setOpenDeleteDialog(false);
        setLoadingFlag(true);
        onDeleteBreakGroup({
            userId: userId,
            requestType: "breakGroupDelete",
            requestPrms:{
              id :idDelete
        }
        });
        setIdDelete("");
    }
    //削除確認ダイアログでCancelボタンのイベントを受け取って、ダイアログを無効にする
    const handleDeleteCancel = () => {
        setIdDelete("");
        setOpenDeleteDialog(false);
    }

    return (
        <>
            {mode === "edit" &&
                <FetchHelper fetchId={GET_MANUFACTURE}
                    url={"/master/manufacture/get"}
                    method="POST"
                    request={true}
                    params={object}
                    json={true}
                    multipart={false}
                    onComplete={(success, data, error) =>
                        success ? handleSuccess(data) : handleError(success, data, error)}
                />}
            {loadingFlag && (
                <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1500, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                    <CircularProgress />
                </div>
            )}
            <div style={{ height: '100%', width: '100%', marginTop: '10px' }}>
                <Stack direction="row" justifyContent="flex-start" alignItems="center" sx={{}} style={{ margin: '18px', color: 'MenuText' }}>
                    <Typography variant="h4">
                        メーカー詳細
                    </Typography>
                </Stack>
                <form onSubmit={handleSubmit(handleEditSubmit)}>
                    <div style={{ height: '100%', width: '100%' }}>
                        <Stack direction='row'>
                            <div style={{ top: "100px", position: "absolute", zIndex: 1200 }}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs value={selectTab} onChange={tabHandleChange} aria-label="oilManagementDetailTabs">
                                        <Tab label='メーカー' {...a11yProps(0)} />
                                        {mode === "edit" && <Tab label='機器グループ' {...a11yProps(1)} />}
                                    </Tabs>
                                </Box>
                            </div>
                        </Stack>

                        {/* メーカー */}
                        <TabPanel value={selectTab} index={0} >
                            <div style={divStyles}>
                                <Typography variant="h6">メーカー</Typography>
                               
                                <Stack spacing={3} mt={3} sx={{maxHeight: "calc(100vh)", overflow: 'auto' }}>
                                    <Stack direction={{ xs: 'column', sm: 'row' }}  pt={1}>
                                        <Controller
                                            name="sManuName"
                                            control={control}
                                            render={({ field }:any) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    label="メーカー名"
                                                    size="small"
                                                />
                                            )}
                                        />
                                    </Stack>
                                    <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3}>
                                        <Controller
                                            name="sManuKana"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    label="メーカー名カタカナ"
                                                    size="small"
                                                />
                                            )}
                                        />
                                    </Stack>
                                    <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3}>
                                        <Grid container spacing={1} direction="row">
                                            <Grid item xs={2}>
                                                <Controller
                                                    name="bIsDefSource"
                                                    control={control}
                                                    render={({ field }: any) => (
                                                        <FormControlLabel {...field} control={<Checkbox />} checked={field.value} label="電源デフォルト" labelPlacement="start" />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Controller
                                                    name="bIsDefHVCB"
                                                    control={control}
                                                    render={({ field }: any) => (
                                                        <FormControlLabel {...field} control={<Checkbox />} checked={field.value} label="HVCBデフォルト" labelPlacement="start" />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Controller
                                                    name="bIsDefLVCB"
                                                    control={control}
                                                    render={({ field }: any) => (
                                                        <FormControlLabel {...field} control={<Checkbox />} checked={field.value} label="LVCBデフォルト" labelPlacement="start" />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Controller
                                                    name="bIsDefThermal"
                                                    control={control}
                                                    render={({ field }: any) => (
                                                        <FormControlLabel {...field} control={<Checkbox />} checked={field.value} label="サーマルデフォルト" labelPlacement="start" />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Controller
                                                    name="bIsDefFuse"
                                                    control={control}
                                                    render={({ field }: any) => (
                                                        <FormControlLabel {...field} control={<Checkbox />} checked={field.value} label="ヒューズデフォルト" labelPlacement="start" />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Controller
                                                    name="bIsDef2E"
                                                    control={control}
                                                    render={({ field }: any) => (
                                                        <FormControlLabel {...field} control={<Checkbox />} checked={field.value} label="2Eデフォルト" labelPlacement="start" />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Stack>
                                </Stack>
                            </div>

                        </TabPanel>

                        {mode === "edit" &&
                            <TabPanel value={selectTab} index={1}>
                                <div style={divStyles}>
                                    <Stack direction="row" display='flex' justifyContent='space-between'>
                                        <div><Typography variant="h6" >機器グループ</Typography></div>
                                        <div >
                                            <Stack direction='row' display='flex' spacing={2}>
                                                <Stack>
                                                    <Button fullWidth color="inherit" variant="contained" onClick={() => handleAdd()}>機器グループの追加</Button>
                                                </Stack>
                                            </Stack>
                                        </div>
                                    </Stack>
                                    <Stack spacing={2} sx={{ mt: 1 }} >
                                        <BreakGroupGrid
                                            ref={gridRef}
                                            colState={colState}
                                            onChangeSearchCondition={() => { }}
                                            keyword={""}
                                            onShowDetails={handleShowDetailPage}
                                            onDelete={handleDelete}
                                            heightForm="365"
                                            checkEdit={true}
                                            mode="edit"
                                        />
                                        {openDeleteDialog ?
                                        <AlertDialog title={title} message={message} isOpen={true} onOK={handleDeleteOK} onCancel={handleDeleteCancel} /> :
                                        <div />
                                    }
                                    </Stack>
                                </div>
                            </TabPanel>
                        }
                        <Stack direction='row' justifyContent="flex-end" spacing={2} sx={{ mt: 0, mr: 3 }}>
                            <LoadingButton variant="contained" type="submit" loading={loadingFlag}>保存</LoadingButton>
                            <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                        </Stack>
                    </div>
                </form>
            </div>
            <FetchHelper fetchId={CREATE_MANUFACTURE} onComplete={(success, data, error) => success ? handleSubmitSuccess(success, data, error) : handleSubmitError(success, data, error)} />
            <FetchHelper fetchId={UPDATE_MANUFACTURE} onComplete={(success, data, error) => success ? handleSubmitSuccess(success, data, error) : handleSubmitError(success, data, error)} />
            <FetchHelper fetchId={DELETE_BREAKGROUP} onComplete={(success, data, error) => success ? handleSuccessDelete(data) : () => {
                setLoadingFlag(false);
                onError();
            }} />
             <FetchHelper fetchId={GET_MANUFACTURE_BY_ID} onComplete={(success, data, error) => success ? handleSuccessRefresh(data) : onError()} />
        </>
    );
};

const mapStateToProps = (state: ApplicationState) => ({
    userId: state.app.user?.userId,
    fetchUpdate: selectors.getFormState(
        state,
        UPDATE_MANUFACTURE
    ),
});

const mapDispatchToProps = (dispatch: any) => {
    return {
        onCreate: (data: any) => dispatch(actionCreators.fetch(CREATE_MANUFACTURE, `/master/manufacture/add`, "POST", data,false,true)),
        onUpdate: (params: any) => dispatch(actionCreators.fetch(UPDATE_MANUFACTURE, `/master/manufacture/modify`, 'POST', params,false,true)),
        onDeleteBreakGroup: (id: any) => dispatch(actionCreators.fetch(DELETE_BREAKGROUP, `/master/break_group/delete`, "POST", id,false,true)),
        onSearchById: (params: any) => dispatch(actionCreators.fetch(GET_MANUFACTURE_BY_ID, `/master/manufacture/get`, 'POST', params,false,true)),
        onSuccess: () =>
            dispatch(actionCreators.showMessage({
                type: "info",
                title: "メーカー更新",
                body: "メーカーを更新しました。",
            })),
        onError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "検索",
                body: "検索できません。",
            })),
        onCreateError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "メーカー登録",
                body: "メーカーを登録出来ませんでした。",
            })),
        onUpdateError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "メーカー編集",
                body: "メーカーを更新出来ませんでした。",
            })),
        onSuccessDeleteBreakGroup: () =>
            dispatch(actionCreators.showMessage({
                type: "info",
                title: "機器グループ削除",
                body: "機器グループを削除しました。",
            })),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ManufactureEdit as any);


