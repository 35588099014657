import { Button, ButtonGroup, Checkbox, Container, Dialog, DialogContent, DialogTitle, FormControlLabel, Grid, Paper, Radio, RadioGroup, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, TextareaAutosize, Typography } from "@mui/material";
import { Controller, useForm, useWatch } from "react-hook-form";
import theme from "../../themes/globalStyles";
import DraggablePaper from "../common/DraggablePaper";
import { InfoCircuit, InfoCover } from "../../models/Index";
import { useState } from "react";
import { BeamsNumber } from "../../utils/FormatNumber";
import { MS_KW_TO_HORSEPOWER } from "../../models/Constants";
import { doGetSubExisting, doGetSubIncrease, doGetSubNew, doGetValuPowerTotal, doGetValueHeatTotal, doGetValueLightTotal, doGetValueSubTotal } from "../../utils/infoCover1";

export type SettingCover1Form = {
    corporation:string,
    titleCorporationAddress:string,
    corporationAddress:string
    titleCorporationTelephone:string,
    corporationTelephone:string,
    titleDateDesigned: string,
    dateDesigned: string,
    application: string,
    titleProject:string,
    project:string,
    titleProjectAddress:string,
    projectAddress: string,
    titleProjectNumber:string,
    projectNumber:string,
    item1 : string,
    item2 : string,
    item3 : string,
    item4 : string,
    item5 : string,
    item6 : string,
    item7 : string,
    item8 : string,
    item9 : string,
    item10 : string,
    item11 : string,
    item12 : string,
    item13 : string,
    item14 : string,
    item15 : string,
    item16 : string,
    item17 : string,
    item18 : string,
    item19 : string,
    item20 : string,
    item21 : string,
    item22 : string,
    item23 : string,
    item24 : string,
    item25 : string,
    item26 : string,
    item27 : string,
    item28 : string,
    item29 : string,
    item30 : string,
    item31 : string,

    content1 : string,
    content2 : string,
    content3 : string,
    content4 : string,
    content5 : string,
    content6 : string,
    content7 : string,
    content8 : string,
    content9 : string,
    content10 : string,
    content11 : string,
    content12 : string,
    content13 : string,
    content14 : string,
    content15 : string,
    content16 : string,
    content17 : string,
    content18 : string,
    content19 : string,
    content20 : string,
    content21 : string,
    content22 : string,
    content23 : string,
    content24 : string,
    content25 : string,
    content26 : string,
    content27 : string,
    content28 : string,
    content29 : string,
    content30 : string,
    content31 : string,

    page1 : string,
    page2 : string,
    page3 : string,
    page4 : string,
    page5 : string,
    page6 : string,
    page7 : string,
    page8 : string,
    page9 : string,
    page10 : string,
    page11 : string,

    page12 : string,
    page13 : string,
    page14 : string,
    page15 : string,
    page16 : string,
    page17 : string,
    page18 : string,
    page19 : string,
    page20 : string,
    page21 : string,
    page22 : string,

    page23 : string,
    page24 : string,
    page25 : string,
    page26 : string,
    page27 : string,
    page28 : string,
    page29 : string,
    page30 : string,
    page31 : string,

    itemDispIndex: number,
    itemBottomIndex: number,
    outline: string,
    titleProjectManager: string,
    projectManager: string,
    titleProjectKind: string,
    projectKind: string,
    titleVoltage: string,
    voltage: string,
    titleEquipCapacity: string,
    powerExisting: number,
    powerIncrease: number,
    powerNew: number,
    heatExisting: number,
    heatIncrease: number,
    heatNew: number,
    lightExisting: number,
    lightIncrease: number,
    lightNew: number,
    titleContractPower: string,
    contractPower: string,
    titlePowerUse: string,
    powerUse: string,
    titlePowerForecast: string,
    powerForecast: string,
    titleUser1: string,
    user1: string,
    titleUser2: string,
    user2: string,
    titleUser3: string,
    user3: string,
    titleUser4: string,
    user4: string,
    titleUser5: string,
    user5: string,
    execution: string,
    titleStandard: string,
    standard: string,
    titlePlace: string,
    placeNormal: boolean,
    placeNormalEdit: string,
    placeDanger: boolean,
    placeDangerEdit: string,
    placeDust: boolean,
    placeDustEdit: string,
    placeCorrosion: boolean,
    placeCorrosionEdit: string,
    placeUser: boolean,
    placeUserText: string,
    titleWire: string,
    wireHigh: boolean,
    wireHighEdit: string,
    wireLow: boolean,
    wireLowEdit: string,
    wireUser: boolean,
    wireUserText: string,
    titleDuct: string,
    ductPvc: boolean,
    ductPvcEdit: string,
    ductGip: boolean,
    ductGipEdit: string,
    ductUser: boolean,
    ductUserText: string,
    titleMeter: string,
    meterExisting: boolean,
    meterExistingEdit: string,
    meterNew: boolean,
    meterNewEdit: string,
    meterUser: boolean,
    meterUserText: string,
    titleSwitch: string,
    switchNormal: boolean,
    switchNormalEdit: string,
    switchBurstProof: boolean,
    switchBurstProofEdit: string,
    switchUser: boolean,
    switchUserText: string,
    titleAppliance: string,
    appliance: string,
    titleOther: string,
    other: string,
    titleInstrument: string,
    instrument1: string,
    instrument2: string,
    instrument3: string,
    instrument4: string,
    instrument5: string,
    instrument6: string,
    instrument7: string,
    instrument8: string,
    instrument9: string,
    instrument10: string,
    instrument11: string,
    instrument12: string,
    instrument13: string,
    instrument14: string,
    instrument15: string,
    instrument16: string,
    instrument17: string,
    instrument18: string,
    instrument19: string,
    instrument20: string,
    instrument21: string,
    corporation1: string,
    corporation2: string,
    corporation3: string,
    corporation4: string,
    corporation5: string,
    corporation6: string,
    corporation7: string,
    corporation8: string,
    corporation9: string,
    corporation10: string,
    corporation11: string,
    corporation12: string,
    corporation13: string,
    corporation14: string,
    corporation15: string,
    corporation16: string,
    corporation17: string,
    corporation18: string,
    corporation19: string,
    corporation20: string,
    corporation21: string,
    instrumentDispIndex: number,
    instrumentBottomIndex: number,
    unitChange: boolean,
    subExisting: number,
    subIncrease: number,
    subNew: number,
    powerTotal:number,
    heatTotal:number,
    lightTotal:number,
    subTotal: number
}

export type SettingCover1FormProps = {
    infoCircuit: InfoCircuit,
    data: InfoCover;
    title: string;
    onOK: (data: any) => void;
    onCancel: () => void;
    modeViewOnly:boolean;
};

export const SettingCover1Dialog = (props: SettingCover1FormProps) => {

    //#region fields
    const {infoCircuit, data, title, onOK, onCancel,modeViewOnly } = props;

    // 初期値設定
    const initialValue : SettingCover1Form = {
        ...data,
        corporation: infoCircuit.corporation,
        project: infoCircuit.project,
        titleProject: infoCircuit.titleProject,
        titleProjectAddress: infoCircuit.titleReference,
        projectAddress: infoCircuit.reference,
    }
    const checkboxStyle = {
        padding: '0px 0px 0px 9px'
    }

    const { control, handleSubmit, formState: { errors }, setValue, getValues,watch } = useForm<SettingCover1Form>({
        mode: "all",
        criteriaMode: "all",
        defaultValues: initialValue,
    })
    //#endregion fields

    let unitChange = watch("unitChange")

    //#region methods
    // Submitイベント
    const handleEditSubmit = (value: any, event: any) => {
        const request = createRequestData(value);
        onOK(request);
    }
    
    // FormデータからRequestデータを作成する
    const createRequestData = (formValue : SettingCover1Form) => {
        if(!formValue.powerExisting){
            formValue.powerExisting = 0
        }    
        if(!formValue.powerIncrease){
            formValue.powerIncrease = 0
        } 
        if(!formValue.powerNew){
            formValue.powerNew = 0
        } 
        if(!formValue.heatExisting){
            formValue.heatExisting = 0
        } 
        if(!formValue.heatIncrease){
            formValue.heatIncrease = 0
        } 
        if(!formValue.heatNew){
            formValue.heatNew = 0
        }   
        if(!formValue.lightExisting){
            formValue.lightExisting = 0
        } 
        if(!formValue.lightIncrease){
            formValue.lightIncrease = 0
        } 
        if(!formValue.lightNew){
            formValue.lightNew = 0
        } 
        if(!formValue.heatNew){
            formValue.heatNew = 0
        } 
        let request = {
            ...formValue,
        } as SettingCover1Form;
        return request;
    }
    const handleReset =() =>{
        if(title == 'プロジェクト概要')
        {
            setValue("outline", 'プロジェクト概要')
            setValue("titleProjectNumber", '工事番号')
            setValue("titleProjectManager", '責任者')
            setValue("titleProjectKind", '工事種類')
            setValue("titleVoltage", '供給電圧')
            setValue("titleContractPower", '契約電力')
            setValue("titlePowerUse", '用途')
            setValue("titlePowerForecast", '将来増設計画')
        }
        else
        {
            setValue("outline",initialValue.outline)
            setValue("titleProjectNumber",initialValue.titleProjectNumber)
            setValue("titleProjectManager",initialValue.titleProjectManager)
            setValue("titleProjectKind",initialValue.titleProjectKind)
            setValue("titleVoltage",initialValue.titleVoltage)
            setValue("titleContractPower",initialValue.titleContractPower)
            setValue("titlePowerUse",initialValue.titlePowerUse)
            setValue("titlePowerForecast",initialValue.titlePowerForecast)
        }
        setValue("titleUser1","")
        setValue("titleUser2","")
        setValue("titleUser3","")
        setValue("titleUser4","")
        setValue("titleUser5","")
    }
    const doSetValuesInfoCover1=(e:any) =>{
        let unitChangeChecked
        if(e.target.name !== 'unitChange'){
            setValue(e.target.name,e.target.value)
        }
        else
        {
            unitChangeChecked = e.target.checked
        }
        // const unitChange = getValues("unitChange")
        const powerExisting = +getValues("powerExisting") 
        const powerIncrease = +getValues("powerIncrease") 
        const powerNew = +getValues("powerNew")
        const heatExisting = +getValues("heatExisting")
        const heatIncrease = +getValues("heatIncrease") 
        let heatNew = +getValues("heatNew")
        let lightExisting = +getValues("lightExisting")
        let lightIncrease = +getValues("lightIncrease")
        let lightNew = +getValues("lightNew")



        const powerTotal = doGetValuPowerTotal(powerExisting,powerIncrease,powerNew)
        const heatTotal = doGetValueHeatTotal(heatExisting,heatIncrease,heatNew)
        const lightTotal = doGetValueLightTotal(lightExisting,lightIncrease,lightNew)
        const subExisting = doGetSubExisting(unitChangeChecked !== undefined? unitChangeChecked : unitChange,powerExisting,heatExisting,lightExisting)
        const subIncrease = doGetSubIncrease(unitChangeChecked !== undefined? unitChangeChecked : unitChange,powerIncrease,heatIncrease,lightIncrease)
        const subNew = doGetSubNew(unitChangeChecked !== undefined? unitChangeChecked : unitChange,powerNew,heatNew,lightNew)
        const subTotal = doGetValueSubTotal(unitChangeChecked !== undefined? unitChangeChecked : unitChange,powerExisting,heatExisting,lightExisting,powerIncrease,heatIncrease,lightIncrease,powerNew,heatNew,lightNew)
        setValue("powerTotal",BeamsNumber(powerTotal,6))
        setValue("heatTotal",BeamsNumber(heatTotal,6))
        setValue("lightTotal",BeamsNumber(lightTotal,6))
        setValue("subExisting",BeamsNumber(subExisting,6))
        setValue("subIncrease",BeamsNumber(subIncrease,6))
        setValue("subNew",BeamsNumber(subNew,6))
        setValue("subTotal",BeamsNumber(subTotal,6))
    }
    const handleChangeUnitChange = (e:any) =>{
        setValue(e.target.name,e.target.checked)
        doSetValuesInfoCover1(e)

    }
    //#endregion methods

    return (
        <>
            <Dialog open={true} maxWidth={"md"} fullWidth
                PaperComponent={
                    DraggablePaper
                }
            >
                <DialogTitle 
                    onMouseOver={(e : any) => e.target.style.cursor = 'move'}
                    style={{ paddingTop: '2px', paddingBottom: '2px', background: theme.palette.primary.main, color: theme.palette.primary.contrastText }}
                >
                    レポートの設定 -［{title}］
                </DialogTitle>
                <DialogContent style={{paddingTop: '10px'}}>
                    <div style={{height:"40vh", width: '100%',marginLeft:'-10px'}}>
                        <form onSubmit={handleSubmit(handleEditSubmit)}>
                            <div style={{height:"calc(40vh - 45px)",overflowY:"auto", width: '100%'}}>
                            <Grid container  >
                                <Grid xs={9.25}>
                                    <Controller
                                         name="corporation"
                                         control={control}
                                        rules={{
                                        }}
                                        render={({ field }) => (
                                             <TextField 
                                                {...field}
                                                fullWidth
                                                type="text"
                                                size="small"
                                                error={!!errors?.corporation}
                                                helperText={errors?.corporation?.message}
                                                disabled={true}
                                                style={{
                                                    backgroundColor: 'floralwhite' ,
                                                }}
                                                InputProps={{sx: { height: 32 }}}
                                                inputProps={{style:{textAlign:"center"}}}

                                            ></TextField>
                                         )}
                                        /> 
                                </Grid>
                                <Grid style={{borderTop:"1px solid black",width:"100%",marginTop:"5px"}}></Grid>
                                <Grid xs={3.75} mt={1}>
                                    <Controller
                                         name="outline"
                                         control={control}
                                        rules={{
                                        }}
                                        render={({ field }) => (
                                             <TextField 
                                                {...field}
                                                fullWidth
                                                type="text"
                                                size="small"
                                                error={!!errors?.outline}
                                                helperText={errors?.outline?.message}
                                                disabled={modeViewOnly}
                                                InputProps={{sx: { height: 32 }}}
                                            ></TextField>
                                         )}
                                        /> 
                                </Grid>
                                <Grid xs={6.25}/>
                                <Grid xs={2} >
                                        <Stack alignItems={'flex-end'} mt={1}>
                                                    <Button  color="inherit" variant="contained" onClick={handleReset} disabled={modeViewOnly}>リセット</Button>
                                    </Stack>

                                    </ Grid>
                                <Grid container mt={1}>
                                    <Grid item xs={0.5} display={"flex"} alignItems={'center'} paddingLeft={0}>1.</Grid>
                                    <Grid item xs={3} display={"flex"} justifyContent={'center'} alignItems={'center'}>
                                        <Controller
                                            name="titleProject"
                                            control={control}
                                            rules={{
                                            }}
                                            render={({ field }) => (
                                                <TextField 
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    size="small"
                                                    error={!!errors?.titleProject}
                                                    helperText={errors?.titleProject?.message}
                                                    disabled={true}
                                                    style={{
                                                        backgroundColor: 'floralwhite' ,
                                                    }}
                                                    InputProps={{sx: { height: 32 }}}
                                                    // value={"プロジェクト"}
                                                ></TextField>
                                            )}
                                            /> 
                                    </Grid>
                                    <Grid item xs={0.25} display={"flex"} justifyContent={'center'} alignItems={'center'}>:</Grid>
                                    <Grid item xs={8.25} display={"flex"} justifyContent={'center'} alignItems={'center'}>
                                        <Controller
                                            name="project"
                                            control={control}
                                            rules={{
                                            }}
                                            render={({ field }) => (
                                                <TextField 
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    size="small"
                                                    error={!!errors?.project}
                                                    helperText={errors?.project?.message}
                                                    disabled={true}
                                                    style={{
                                                        backgroundColor: 'floralwhite' ,
                                                    }}
                                                    InputProps={{sx: { height: 32 }}}
                                                ></TextField>
                                            )}
                                            /> 
                                    </Grid>

                                </Grid>

                                <Grid container mt={1}>
                                    <Grid item xs={0.5} display={"flex"} alignItems={'center'} paddingLeft={0}>2.</Grid>
                                    <Grid item xs={3} display={"flex"} justifyContent={'center'} alignItems={'center'}>
                                        <Controller
                                            name="titleProjectAddress"
                                            control={control}
                                            rules={{
                                            }}
                                            render={({ field }) => (
                                                <TextField 
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    size="small"
                                                    error={!!errors?.titleProjectAddress}
                                                    helperText={errors?.titleProjectAddress?.message}
                                                    disabled={true}
                                                    style={{
                                                        backgroundColor: 'floralwhite' ,
                                                    }}
                                                    InputProps={{sx: { height: 32 }}}
                                                    // value={"回路名称"}
                                                    
                                                ></TextField>
                                            )}
                                            /> 
                                    </Grid>
                                    <Grid item xs={0.25} display={"flex"} justifyContent={'center'} alignItems={'center'}>:</Grid>
                                    <Grid item xs={8.25} display={"flex"} justifyContent={'center'} alignItems={'center'}>
                                        <Controller
                                            name="projectAddress"
                                            control={control}
                                            rules={{
                                            }}
                                            render={({ field }) => (
                                                <TextField 
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    size="small"
                                                    error={!!errors?.projectAddress}
                                                    helperText={errors?.projectAddress?.message}
                                                    disabled={true}
                                                    style={{
                                                        backgroundColor: 'floralwhite' ,
                                                    }}
                                                    InputProps={{sx: { height: 32 }}}
                                                ></TextField>
                                            )}
                                            /> 
                                    </Grid>
                                </Grid>

                                <Grid container mt={1}>
                                    <Grid item xs={0.5} display={"flex"} alignItems={'center'} paddingLeft={0}>3.</Grid>
                                    <Grid item xs={3} display={"flex"} justifyContent={'center'} alignItems={'center'}>
                                        <Controller
                                            name="titleProjectNumber"
                                            control={control}
                                            rules={{
                                            }}
                                            render={({ field }) => (
                                                <TextField 
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    size="small"
                                                    error={!!errors?.titleProjectNumber}
                                                    helperText={errors?.titleProjectNumber?.message}
                                                    InputProps={{sx: { height: 32 }}}
                                                    disabled={modeViewOnly}
                                                ></TextField>
                                            )}
                                            /> 
                                    </Grid>
                                    <Grid item xs={0.25} display={"flex"} justifyContent={'center'} alignItems={'center'}>:</Grid>
                                    <Grid item xs={8.25} display={"flex"} justifyContent={'center'} alignItems={'center'}>
                                        <Controller
                                            name="projectNumber"
                                            control={control}
                                            render={({ field }) => (
                                                <TextareaAutosize
                                                    {...field}
                                                    maxRows={2}
                                                    style={{overflowY:'scroll',resize:'none',fontFamily:'Roboto,Helvetica,Arial,sans-serif',fontSize:'16px',width:'100%',
                                                            borderRadius:'3px',padding:'5px 20px', border:'0.5px solid gray'}}
                                                    disabled={modeViewOnly}
                                                    />
                                                    )}
                                                />
                                    </Grid>
                                </Grid>
                                
                                <Grid container mt={1}>
                                    <Grid item xs={0.5} display={"flex"} alignItems={'center'} paddingLeft={0}>4.</Grid>
                                    <Grid item xs={3} display={"flex"} justifyContent={'center'} alignItems={'center'}>
                                        <Controller
                                            name="titleProjectManager"
                                            control={control}
                                            rules={{
                                            }}
                                            render={({ field }) => (
                                                <TextField 
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    size="small"
                                                    error={!!errors?.titleProjectManager}
                                                    helperText={errors?.titleProjectManager?.message}
                                                    InputProps={{sx: { height: 32 }}}
                                                    disabled={modeViewOnly}
                                                ></TextField>
                                            )}
                                            /> 
                                    </Grid>
                                    <Grid item xs={0.25} display={"flex"} justifyContent={'center'} alignItems={'center'}>:</Grid>
                                    <Grid item xs={8.25} display={"flex"} justifyContent={'center'} alignItems={'center'}>
                                        <Controller
                                            name="projectManager"
                                            control={control}
                                            render={({ field }) => (
                                                <TextareaAutosize
                                                    {...field}
                                                    maxRows={2}
                                                    style={{overflowY:'scroll',resize:'none',fontFamily:'Roboto,Helvetica,Arial,sans-serif',fontSize:'16px',width:'100%',
                                                            borderRadius:'4px',padding:'5px 20px', border:'0.1px solid gray'}}
                                                    disabled={modeViewOnly}
                                                    />
                                                    )}
                                                />
                                    </Grid>
                                </Grid>

                                <Grid container mt={1}>
                                    <Grid item xs={0.5} display={"flex"} alignItems={'center'} paddingLeft={0}>5.</Grid>
                                    <Grid item xs={3} display={"flex"} justifyContent={'center'} alignItems={'center'}>
                                        <Controller
                                            name="titleProjectKind"
                                            control={control}
                                            rules={{
                                            }}
                                            render={({ field }) => (
                                                <TextField 
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    size="small"
                                                    error={!!errors?.titleProjectKind}
                                                    helperText={errors?.titleProjectKind?.message}
                                                    InputProps={{sx: { height: 32 }}}
                                                    disabled={modeViewOnly}
                                                ></TextField>
                                            )}
                                            /> 
                                    </Grid>
                                    <Grid item xs={0.25} display={"flex"} justifyContent={'center'} alignItems={'center'}>:</Grid>
                                    <Grid item xs={8.25} display={"flex"} justifyContent={'center'} alignItems={'center'}>
                                        <Controller
                                            name="projectKind"
                                            control={control}
                                            render={({ field }) => (
                                                <TextareaAutosize
                                                    {...field}
                                                    maxRows={2}
                                                    style={{overflowY:'scroll',resize:'none',fontFamily:'Roboto,Helvetica,Arial,sans-serif',fontSize:'16px',width:'100%',
                                                            borderRadius:'4px',padding:'5px 20px', border:'0.1px solid gray'}}
                                                    disabled={modeViewOnly}
                                                    />
                                                    )}
                                                />
                                    </Grid>
                                </Grid>

                                <Grid container mt={1}>
                                    <Grid item xs={0.5} display={"flex"} alignItems={'center'} paddingLeft={0}>6.</Grid>
                                    <Grid item xs={3} display={"flex"} justifyContent={'center'} alignItems={'center'}>
                                        <Controller
                                            name="titleVoltage"
                                            control={control}
                                            rules={{
                                            }}
                                            render={({ field }) => (
                                                <TextField 
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    size="small"
                                                    error={!!errors?.titleVoltage}
                                                    helperText={errors?.titleVoltage?.message}
                                                    InputProps={{sx: { height: 32 }}}
                                                    disabled={modeViewOnly}
                                                ></TextField>
                                            )}
                                            /> 
                                    </Grid>
                                    <Grid item xs={0.25} display={"flex"} justifyContent={'center'} alignItems={'center'}>:</Grid>
                                    <Grid item xs={8.25} display={"flex"} justifyContent={'center'} alignItems={'center'}>
                                        <Controller
                                            name="voltage"
                                            control={control}
                                            render={({ field }) => (
                                                <TextareaAutosize
                                                    {...field}
                                                    maxRows={2}
                                                    style={{overflowY:'scroll',resize:'none',fontFamily:'Roboto,Helvetica,Arial,sans-serif',fontSize:'16px',width:'100%',
                                                            borderRadius:'4px',padding:'5px 20px', border:'0.1px solid gray'}}
                                                    disabled={modeViewOnly}
                                                    />
                                                    )}
                                                />
                                    </Grid>
                                </Grid>

                                <Grid container mt={1}>
                                    <Grid item xs={0.5} display={"flex"} alignItems={'center'} paddingLeft={0}>7.</Grid>
                                    <Grid item xs={3} display={"flex"} justifyContent={'center'} alignItems={'center'}>
                                        <Controller
                                            name="titleEquipCapacity"
                                            control={control}
                                            rules={{
                                            }}
                                            render={({ field }) => (
                                                <TextField 
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    size="small"
                                                    error={!!errors?.titleEquipCapacity}
                                                    helperText={errors?.titleEquipCapacity?.message}
                                                    InputProps={{sx: { height: 32 }}}
                                                    disabled={true}
                                                    style={{background:'floralwhite'}}
                                                ></TextField>
                                            )}
                                            /> 
                                    </Grid>
                                    <Grid item xs={0.25} display={"flex"} justifyContent={'center'} alignItems={'center'}>:</Grid>
                                    <Grid item xs={8.25} display={"flex"}  alignItems={'center'}>
                                        <FormControlLabel
                                                control={
                                                    <Controller
                                                        name='unitChange'
                                                        control={control}
                                                        render={({ field }) => (
                                                            <Checkbox
                                                                size='small'
                                                                {...field}
                                                                checked={field.value}
                                                                onChange={handleChangeUnitChange}
                                                                disabled={modeViewOnly}
                                                            />
                                                        )}
                                                    />
                                                }
                                                label='1 HP = 1 kW = 1 kVA による統計'
                                            />
                                    </Grid>
                                </Grid>
                                {/* table */}
                                <Grid container mt={1}>
                                    <Grid item xs={0.5} display={"flex"} alignItems={'center'} paddingLeft={0}></Grid>
                                    <Grid item xs={1.5} display={"flex"} justifyContent={'center'} alignItems={'center'}>
                                                <TextField 
                                                    fullWidth
                                                    type="text"
                                                    size="small"
                                                    error={!!errors?.titleEquipCapacity}
                                                    helperText={errors?.titleEquipCapacity?.message}
                                                    InputProps={{sx: { height: 32 }}}
                                                    disabled={true}
                                                    style={{background:'#EEEEEE'}}
                                                ></TextField>
                                    </Grid>
                                    <Grid item xs={2.5} display={"flex"} justifyContent={'center'} alignItems={'center'} pl={0.25}>
                                                <TextField 
                                                    fullWidth
                                                    type="text"
                                                    size="small"
                                                    error={!!errors?.titleEquipCapacity}
                                                    helperText={errors?.titleEquipCapacity?.message}
                                                    InputProps={{sx: { height: 32 }}}
                                                    disabled={true}
                                                    value={"動力(HP)"}
                                                    style={{background:'#EEEEEE'}}
                                                ></TextField>
                                    </Grid>
                                    <Grid item xs={2.5} display={"flex"} justifyContent={'center'} alignItems={'center'} pl={0.25}>
                                                <TextField 
                                                    fullWidth
                                                    type="text"
                                                    size="small"
                                                    error={!!errors?.titleEquipCapacity}
                                                    helperText={errors?.titleEquipCapacity?.message}
                                                    InputProps={{sx: { height: 32 }}}
                                                    disabled={true}
                                                    value={"電熱(kW)"}
                                                    style={{background:'#EEEEEE'}}
                                                ></TextField>
                                    </Grid>
                                    <Grid item xs={2.5} display={"flex"} justifyContent={'center'} alignItems={'center'} pl={0.25}>
                                                <TextField 
                                                    fullWidth
                                                    type="text"
                                                    size="small"
                                                    error={!!errors?.titleEquipCapacity}
                                                    helperText={errors?.titleEquipCapacity?.message}
                                                    InputProps={{sx: { height: 32 }}}
                                                    disabled={true}
                                                    value={"照明(kVA)"}
                                                    style={{background:'#EEEEEE'}}
                                                ></TextField>
                                    </Grid>
                                    <Grid item xs={2.5} display={"flex"} justifyContent={'center'} alignItems={'center'} pl={0.25}>
                                                <TextField 
                                                    fullWidth
                                                    type="text"
                                                    size="small"
                                                    error={!!errors?.titleEquipCapacity}
                                                    helperText={errors?.titleEquipCapacity?.message}
                                                    InputProps={{sx: { height: 32 }}}
                                                    disabled={true}
                                                    value={"小計"}
                                                    style={{background:'#EEEEEE'}}
                                                ></TextField>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={0.5} display={"flex"} alignItems={'center'} paddingLeft={0}></Grid>
                                    <Grid item xs={1.5} display={"flex"} justifyContent={'center'} alignItems={'center'}>
                                                <TextField 
                                                    fullWidth
                                                    type="text"
                                                    size="small"
                                                    error={!!errors?.titleEquipCapacity}
                                                    helperText={errors?.titleEquipCapacity?.message}
                                                    InputProps={{sx: { height: 32 }}}
                                                    disabled={true}
                                                    style={{background:'#EEEEEE'}}
                                                    value={"既設"}
                                                ></TextField>
                                    </Grid>
                                    <Grid item xs={2.5} display={"flex"} justifyContent={'center'} alignItems={'center'} pl={0.25}>
                                            <Controller
                                                    name="powerExisting"
                                                    control={control}
                                                    rules={{
                                                        validate: value => value >= 0 || '数字 >= 0 を入力してください。' ,
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField 
                                                            {...field}
                                                            fullWidth
                                                            type="text"
                                                            size="small"
                                                            error={!!errors?.powerExisting}
                                                            helperText={errors?.powerExisting?.message}
                                                            InputProps={{sx: { height: 32 }}}
                                                            onChange={e =>doSetValuesInfoCover1(e)}
                                                            disabled={modeViewOnly}
                                                        ></TextField>
                                                    )}
                                                    /> 
                                    </Grid>
                                    <Grid item xs={2.5} display={"flex"} justifyContent={'center'} alignItems={'center'} pl={0.25}>
                                            <Controller
                                                    name="heatExisting"
                                                    control={control}
                                                    rules={{
                                                        validate: value => value >= 0 || '数字 >= 0 を入力してください。' ,
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField 
                                                            {...field}
                                                            fullWidth
                                                            type="text"
                                                            size="small"
                                                            error={!!errors?.heatExisting}
                                                            helperText={errors?.heatExisting?.message}
                                                            InputProps={{sx: { height: 32 }}}
                                                            onChange={e =>doSetValuesInfoCover1(e)}
                                                            disabled={modeViewOnly}
                                                        ></TextField>
                                                    )}
                                                    /> 
                                    </Grid>
                                    <Grid item xs={2.5} display={"flex"} justifyContent={'center'} alignItems={'center'} pl={0.25}>
                                            <Controller
                                                    name="lightExisting"
                                                    control={control}
                                                    rules={{
                                                        validate: value => value >= 0 || '数字 >= 0 を入力してください。' ,
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField 
                                                            {...field}
                                                            fullWidth
                                                            type="text"
                                                            size="small"
                                                            error={!!errors?.lightExisting}
                                                            helperText={errors?.lightExisting?.message}
                                                            InputProps={{sx: { height: 32 }}}
                                                            onChange={e =>doSetValuesInfoCover1(e)}
                                                            disabled={modeViewOnly}
                                                        ></TextField>
                                                    )}
                                                    /> 
                                    </Grid>
                                    <Grid item xs={2.5} display={"flex"} justifyContent={'center'} alignItems={'center'} pl={0.25}>
                                            <Controller
                                                    name="subExisting"
                                                    control={control}
                                                    rules={{
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField 
                                                            {...field}
                                                            fullWidth
                                                            type="text"
                                                            size="small"
                                                            error={!!errors?.subExisting}
                                                            helperText={errors?.subExisting?.message}
                                                            InputProps={{sx: { height: 32 }}}
                                                            disabled={true}
                                                            style={{background:'floralwhite'}}
                                                        ></TextField>
                                                    )}
                                                    /> 
                                    </Grid>
                                </Grid>


                                <Grid container>
                                    <Grid item xs={0.5} display={"flex"} alignItems={'center'} paddingLeft={0}></Grid>
                                    <Grid item xs={1.5} display={"flex"} justifyContent={'center'} alignItems={'center'}>
                                                <TextField 
                                                    fullWidth
                                                    type="text"
                                                    size="small"
                                                    error={!!errors?.titleEquipCapacity}
                                                    helperText={errors?.titleEquipCapacity?.message}
                                                    InputProps={{sx: { height: 32 }}}
                                                    disabled={true}
                                                    style={{background:'#EEEEEE'}}
                                                    value={"增設"}
                                                ></TextField>
                                    </Grid>
                                    <Grid item xs={2.5} display={"flex"} justifyContent={'center'} alignItems={'center'} pl={0.25}>
                                            <Controller
                                                    name="powerIncrease"
                                                    control={control}
                                                    rules={{
                                                        validate: value => value >= 0 || '数字 >= 0 を入力してください。' ,
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField 
                                                            {...field}
                                                            fullWidth
                                                            type="text"
                                                            size="small"
                                                            error={!!errors?.powerIncrease}
                                                            helperText={errors?.powerIncrease?.message}
                                                            InputProps={{sx: { height: 32 }}}
                                                            onChange={e =>doSetValuesInfoCover1(e)}
                                                            disabled={modeViewOnly}
                                                        ></TextField>
                                                    )}
                                                    /> 
                                    </Grid>
                                    <Grid item xs={2.5} display={"flex"} justifyContent={'center'} alignItems={'center'} pl={0.25}>
                                            <Controller
                                                    name="heatIncrease"
                                                    control={control}
                                                    rules={{
                                                        validate: value => value >= 0 || '数字 >= 0 を入力してください。' ,
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField 
                                                            {...field}
                                                            fullWidth
                                                            type="text"
                                                            size="small"
                                                            error={!!errors?.heatIncrease}
                                                            helperText={errors?.heatIncrease?.message}
                                                            InputProps={{sx: { height: 32 }}}
                                                            onChange={e =>doSetValuesInfoCover1(e)}
                                                            disabled={modeViewOnly}
                                                        ></TextField>
                                                    )}
                                                    /> 
                                    </Grid>
                                    <Grid item xs={2.5} display={"flex"} justifyContent={'center'} alignItems={'center'} pl={0.25}>
                                            <Controller
                                                    name="lightIncrease"
                                                    control={control}
                                                    rules={{
                                                        validate: value => value >= 0 || '数字 >= 0 を入力してください。' ,
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField 
                                                            {...field}
                                                            fullWidth
                                                            type="text"
                                                            size="small"
                                                            error={!!errors?.lightIncrease}
                                                            helperText={errors?.lightIncrease?.message}
                                                            InputProps={{sx: { height: 32 }}}
                                                            onChange={e =>doSetValuesInfoCover1(e)}
                                                            disabled={modeViewOnly}
                                                        ></TextField>
                                                    )}
                                                    /> 
                                    </Grid>
                                    <Grid item xs={2.5} display={"flex"} justifyContent={'center'} alignItems={'center'} pl={0.25}>
                                            <Controller
                                                    name="subIncrease"
                                                    control={control}
                                                    rules={{
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField 
                                                            {...field}
                                                            fullWidth
                                                            type="text"
                                                            size="small"
                                                            error={!!errors?.subIncrease}
                                                            helperText={errors?.subIncrease?.message}
                                                            InputProps={{sx: { height: 32 }}}
                                                            disabled={true}
                                                            style={{background:'floralwhite'}}
                                                        ></TextField>
                                                    )}
                                                    /> 
                                    </Grid>
                                </Grid>

                                <Grid container>
                                    <Grid item xs={0.5} display={"flex"} alignItems={'center'} paddingLeft={0}></Grid>
                                    <Grid item xs={1.5} display={"flex"} justifyContent={'center'} alignItems={'center'}>
                                                <TextField 
                                                    fullWidth
                                                    type="text"
                                                    size="small"
                                                    error={!!errors?.titleEquipCapacity}
                                                    helperText={errors?.titleEquipCapacity?.message}
                                                    InputProps={{sx: { height: 32 }}}
                                                    disabled={true}
                                                    style={{background:'#EEEEEE'}}
                                                    value={"新設"}
                                                ></TextField>
                                    </Grid>
                                    <Grid item xs={2.5} display={"flex"} justifyContent={'center'} alignItems={'center'} pl={0.25}>
                                            <Controller
                                                    name="powerNew"
                                                    control={control}
                                                    rules={{
                                                        validate: value => value >= 0 || '数字 >= 0 を入力してください。' ,
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField 
                                                            {...field}
                                                            fullWidth
                                                            type="text"
                                                            size="small"
                                                            error={!!errors?.powerNew}
                                                            helperText={errors?.powerNew?.message}
                                                            InputProps={{sx: { height: 32 }}}
                                                            onChange={e =>doSetValuesInfoCover1(e)}
                                                            disabled={modeViewOnly}
                                                        ></TextField>
                                                    )}
                                                    /> 
                                    </Grid>
                                    <Grid item xs={2.5} display={"flex"} justifyContent={'center'} alignItems={'center'} pl={0.25}>
                                            <Controller
                                                    name="heatNew"
                                                    control={control}
                                                    rules={{
                                                        validate: value => value >= 0 || '数字 >= 0 を入力してください。' ,
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField 
                                                            {...field}
                                                            fullWidth
                                                            type="text"
                                                            size="small"
                                                            error={!!errors?.heatNew}
                                                            helperText={errors?.heatNew?.message}
                                                            InputProps={{sx: { height: 32 }}}
                                                            onChange={e =>doSetValuesInfoCover1(e)}
                                                            disabled={modeViewOnly}
                                                        ></TextField>
                                                    )}
                                                    /> 
                                    </Grid>
                                    <Grid item xs={2.5} display={"flex"} justifyContent={'center'} alignItems={'center'} pl={0.25}>
                                            <Controller
                                                    name="lightNew"
                                                    control={control}
                                                    rules={{
                                                        validate: value => value >= 0 || '数字 >= 0 を入力してください。' ,
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField 
                                                            {...field}
                                                            fullWidth
                                                            type="text"
                                                            size="small"
                                                            error={!!errors?.lightNew}
                                                            helperText={errors?.lightNew?.message}
                                                            InputProps={{sx: { height: 32 }}}
                                                            onChange={e =>doSetValuesInfoCover1(e)}
                                                            disabled={modeViewOnly}
                                                        ></TextField>
                                                    )}
                                                    /> 
                                    </Grid>
                                    <Grid item xs={2.5} display={"flex"} justifyContent={'center'} alignItems={'center'} pl={0.25}>
                                            <Controller
                                                    name="subNew"
                                                    control={control}
                                                    rules={{
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField 
                                                            {...field}
                                                            fullWidth
                                                            type="text"
                                                            size="small"
                                                            error={!!errors?.subNew}
                                                            helperText={errors?.subNew?.message}
                                                            InputProps={{sx: { height: 32 }}}
                                                            disabled={true}
                                                            style={{background:'floralwhite'}}
                                                        ></TextField>
                                                    )}
                                                    /> 
                                    </Grid>
                                </Grid>

                                <Grid container>
                                    <Grid item xs={0.5} display={"flex"} alignItems={'center'} paddingLeft={0}></Grid>
                                    <Grid item xs={1.5} display={"flex"} justifyContent={'center'} alignItems={'center'}>
                                                <TextField 
                                                    fullWidth
                                                    type="text"
                                                    size="small"
                                                    error={!!errors?.titleEquipCapacity}
                                                    helperText={errors?.titleEquipCapacity?.message}
                                                    InputProps={{sx: { height: 32 }}}
                                                    disabled={true}
                                                    style={{background:'#EEEEEE'}}
                                                    value={"合計"}
                                                ></TextField>
                                    </Grid>
                                    <Grid item xs={2.5} display={"flex"} justifyContent={'center'} alignItems={'center'} pl={0.25}>
                                            <Controller
                                                    name="powerTotal"
                                                    control={control}
                                                    rules={{
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField 
                                                            {...field}
                                                            fullWidth
                                                            type="text"
                                                            size="small"
                                                            error={!!errors?.powerTotal}
                                                            helperText={errors?.powerTotal?.message}
                                                            InputProps={{sx: { height: 32 }}}
                                                            disabled={true}
                                                            style={{background:'floralwhite'}}
                                                        ></TextField>
                                                    )}
                                                    /> 
                                    </Grid>
                                    <Grid item xs={2.5} display={"flex"} justifyContent={'center'} alignItems={'center'} pl={0.25}>
                                            <Controller
                                                    name="heatTotal"
                                                    control={control}
                                                    rules={{
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField 
                                                            {...field}
                                                            fullWidth
                                                            type="text"
                                                            size="small"
                                                            error={!!errors?.heatTotal}
                                                            helperText={errors?.heatTotal?.message}
                                                            InputProps={{sx: { height: 32 }}}
                                                            disabled={true}
                                                            style={{background:'floralwhite'}}
                                                        ></TextField>
                                                    )}
                                                    /> 
                                    </Grid>
                                    <Grid item xs={2.5} display={"flex"} justifyContent={'center'} alignItems={'center'} pl={0.25}>
                                            <Controller
                                                    name="lightTotal"
                                                    control={control}
                                                    rules={{
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField 
                                                            {...field}
                                                            fullWidth
                                                            type="text"
                                                            size="small"
                                                            error={!!errors?.lightTotal}
                                                            helperText={errors?.lightTotal?.message}
                                                            InputProps={{sx: { height: 32 }}}
                                                            disabled={true}
                                                            style={{background:'floralwhite'}}
                                                        ></TextField>
                                                    )}
                                                    /> 
                                    </Grid>
                                    <Grid item xs={2.5} display={"flex"} justifyContent={'center'} alignItems={'center'} pl={0.25}>
                                            <Controller
                                                    name="subTotal"
                                                    control={control}
                                                    rules={{
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField 
                                                            {...field}
                                                            fullWidth
                                                            type="text"
                                                            size="small"
                                                            error={!!errors?.subTotal}
                                                            helperText={errors?.subTotal?.message}
                                                            InputProps={{sx: { height: 32 }}}
                                                            disabled={true}
                                                            style={{background:'floralwhite'}}
                                                        ></TextField>
                                                    )}
                                                    /> 
                                    </Grid>
                                </Grid>

                                <Grid container mt={1}>
                                    <Grid item xs={0.5} display={"flex"} alignItems={'center'} paddingLeft={0}>8.</Grid>
                                    <Grid item xs={3} display={"flex"} justifyContent={'center'} alignItems={'center'}>
                                        <Controller
                                            name="titleContractPower"
                                            control={control}
                                            rules={{
                                            }}
                                            render={({ field }) => (
                                                <TextField 
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    size="small"
                                                    error={!!errors?.titleContractPower}
                                                    helperText={errors?.titleContractPower?.message}
                                                    InputProps={{sx: { height: 32 }}}
                                                    disabled={modeViewOnly}
                                                ></TextField>
                                            )}
                                            /> 
                                    </Grid>
                                    <Grid item xs={0.25} display={"flex"} justifyContent={'center'} alignItems={'center'}>:</Grid>
                                    <Grid item xs={8.25} display={"flex"}  alignItems={'center'}>
                                        <Controller
                                                name="contractPower"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextareaAutosize
                                                        {...field}
                                                        maxRows={2}
                                                        style={{overflowY:'scroll',resize:'none',fontFamily:'Roboto,Helvetica,Arial,sans-serif',fontSize:'16px',width:'100%',
                                                                borderRadius:'4px',padding:'5px 20px', border:'0.1px solid gray'}}
                                                        disabled={modeViewOnly}
                                                        />
                                                        )}
                                                    />
                                    </Grid>
                                </Grid>
                                
                                <Grid container mt={1}>
                                    <Grid item xs={0.5} display={"flex"} alignItems={'center'} paddingLeft={0}>9.</Grid>
                                    <Grid item xs={3} display={"flex"} justifyContent={'center'} alignItems={'center'}>
                                        <Controller
                                            name="titlePowerUse"
                                            control={control}
                                            rules={{
                                            }}
                                            render={({ field }) => (
                                                <TextField 
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    size="small"
                                                    error={!!errors?.titlePowerUse}
                                                    helperText={errors?.titlePowerUse?.message}
                                                    InputProps={{sx: { height: 32 }}}
                                                    disabled={modeViewOnly}
                                                ></TextField>
                                            )}
                                            /> 
                                    </Grid>
                                    <Grid item xs={0.25} display={"flex"} justifyContent={'center'} alignItems={'center'}>:</Grid>
                                    <Grid item xs={8.25} display={"flex"} justifyContent={'center'} alignItems={'center'}>
                                        <Controller
                                            name="powerUse"
                                            control={control}
                                            render={({ field }) => (
                                                <TextareaAutosize
                                                    {...field}
                                                    maxRows={2}
                                                    style={{overflowY:'scroll',resize:'none',fontFamily:'Roboto,Helvetica,Arial,sans-serif',fontSize:'16px',width:'100%',
                                                            borderRadius:'4px',padding:'5px 20px', border:'0.1px solid gray'}}
                                                    disabled={modeViewOnly}
                                                    />
                                                    )}
                                                />
                                    </Grid>
                                </Grid>

                                <Grid container mt={1}>
                                    <Grid item xs={0.5} display={"flex"} alignItems={'center'} paddingLeft={0}>10.</Grid>
                                    <Grid item xs={3} display={"flex"} justifyContent={'center'} alignItems={'center'}>
                                        <Controller
                                            name="titlePowerForecast"
                                            control={control}
                                            rules={{
                                            }}
                                            render={({ field }) => (
                                                <TextField 
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    size="small"
                                                    error={!!errors?.titlePowerForecast}
                                                    helperText={errors?.titlePowerForecast?.message}
                                                    InputProps={{sx: { height: 32 }}}
                                                    disabled={modeViewOnly}
                                                ></TextField>
                                            )}
                                            /> 
                                    </Grid>
                                    <Grid item xs={0.25} display={"flex"} justifyContent={'center'} alignItems={'center'}>:</Grid>
                                    <Grid item xs={8.25} display={"flex"} justifyContent={'center'} alignItems={'center'}>
                                        <Controller
                                            name="powerForecast"
                                            control={control}
                                            render={({ field }) => (
                                                <TextareaAutosize
                                                    {...field}
                                                    maxRows={2}
                                                    style={{overflowY:'scroll',resize:'none',fontFamily:'Roboto,Helvetica,Arial,sans-serif',fontSize:'16px',width:'100%',
                                                            borderRadius:'4px',padding:'5px 20px', border:'0.1px solid gray'}}
                                                    disabled={modeViewOnly}
                                                    />
                                                    )}
                                                />
                                    </Grid>
                                </Grid>

                                <Grid container mt={1}>
                                    <Grid item xs={0.5} display={"flex"} alignItems={'center'} paddingLeft={0}>11.</Grid>
                                    <Grid item xs={3} display={"flex"} justifyContent={'center'} alignItems={'center'}>
                                        <Controller
                                            name="titleUser1"
                                            control={control}
                                            rules={{
                                            }}
                                            render={({ field }) => (
                                                <TextField 
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    size="small"
                                                    error={!!errors?.titleUser1}
                                                    helperText={errors?.titleUser1?.message}
                                                    InputProps={{sx: { height: 32 }}}
                                                    disabled={modeViewOnly}
                                                ></TextField>
                                            )}
                                            /> 
                                    </Grid>
                                    <Grid item xs={0.25} display={"flex"} justifyContent={'center'} alignItems={'center'}>:</Grid>
                                    <Grid item xs={8.25} display={"flex"} justifyContent={'center'} alignItems={'center'}>
                                        <Controller
                                            name="user1"
                                            control={control}
                                            render={({ field }) => (
                                                <TextareaAutosize
                                                    {...field}
                                                    maxRows={2}
                                                    style={{overflowY:'scroll',resize:'none',fontFamily:'Roboto,Helvetica,Arial,sans-serif',fontSize:'16px',width:'100%',
                                                            borderRadius:'4px',padding:'5px 20px', border:'0.1px solid gray'}}
                                                    disabled={modeViewOnly}
                                                    />
                                                    )}
                                                />
                                    </Grid>
                                </Grid>

                                <Grid container mt={1}>
                                    <Grid item xs={0.5} display={"flex"} alignItems={'center'} paddingLeft={0}>12.</Grid>
                                    <Grid item xs={3} display={"flex"} justifyContent={'center'} alignItems={'center'}>
                                        <Controller
                                            name="titleUser2"
                                            control={control}
                                            rules={{
                                            }}
                                            render={({ field }) => (
                                                <TextField 
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    size="small"
                                                    error={!!errors?.titleUser2}
                                                    helperText={errors?.titleUser2?.message}
                                                    InputProps={{sx: { height: 32 }}}
                                                    disabled={modeViewOnly}
                                                ></TextField>
                                            )}
                                            /> 
                                    </Grid>
                                    <Grid item xs={0.25} display={"flex"} justifyContent={'center'} alignItems={'center'}>:</Grid>
                                    <Grid item xs={8.25} display={"flex"} justifyContent={'center'} alignItems={'center'}>
                                        <Controller
                                            name="user2"
                                            control={control}
                                            render={({ field }) => (
                                                <TextareaAutosize
                                                    {...field}
                                                    maxRows={2}
                                                    style={{overflowY:'scroll',resize:'none',fontFamily:'Roboto,Helvetica,Arial,sans-serif',fontSize:'16px',width:'100%',
                                                            borderRadius:'4px',padding:'5px 20px', border:'0.1px solid gray'}}
                                                    disabled={modeViewOnly}
                                                    />
                                                    )}
                                                />
                                    </Grid>
                                </Grid>

                                <Grid container mt={1}>
                                    <Grid item xs={0.5} display={"flex"} alignItems={'center'} paddingLeft={0}>13.</Grid>
                                    <Grid item xs={3} display={"flex"} justifyContent={'center'} alignItems={'center'}>
                                        <Controller
                                            name="titleUser3"
                                            control={control}
                                            rules={{
                                            }}
                                            render={({ field }) => (
                                                <TextField 
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    size="small"
                                                    error={!!errors?.titleUser1}
                                                    helperText={errors?.titleUser1?.message}
                                                    InputProps={{sx: { height: 32 }}}
                                                    disabled={modeViewOnly}
                                                ></TextField>
                                            )}
                                            /> 
                                    </Grid>
                                    <Grid item xs={0.25} display={"flex"} justifyContent={'center'} alignItems={'center'}>:</Grid>
                                    <Grid item xs={8.25} display={"flex"} justifyContent={'center'} alignItems={'center'}>
                                        <Controller
                                            name="user3"
                                            control={control}
                                            render={({ field }) => (
                                                <TextareaAutosize
                                                    {...field}
                                                    maxRows={2}
                                                    style={{overflowY:'scroll',resize:'none',fontFamily:'Roboto,Helvetica,Arial,sans-serif',fontSize:'16px',width:'100%',
                                                            borderRadius:'4px',padding:'5px 20px', border:'0.1px solid gray'}}
                                                    disabled={modeViewOnly}
                                                    />
                                                    )}
                                                />
                                    </Grid>
                                </Grid>

                                <Grid container mt={1}>
                                    <Grid item xs={0.5} display={"flex"} alignItems={'center'} paddingLeft={0}>14.</Grid>
                                    <Grid item xs={3} display={"flex"} justifyContent={'center'} alignItems={'center'}>
                                        <Controller
                                            name="titleUser4"
                                            control={control}
                                            rules={{
                                            }}
                                            render={({ field }) => (
                                                <TextField 
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    size="small"
                                                    error={!!errors?.titleUser4}
                                                    helperText={errors?.titleUser4?.message}
                                                    InputProps={{sx: { height: 32 }}}
                                                    disabled={modeViewOnly}
                                                ></TextField>
                                            )}
                                            /> 
                                    </Grid>
                                    <Grid item xs={0.25} display={"flex"} justifyContent={'center'} alignItems={'center'}>:</Grid>
                                    <Grid item xs={8.25} display={"flex"} justifyContent={'center'} alignItems={'center'}>
                                        <Controller
                                            name="user4"
                                            control={control}
                                            render={({ field }) => (
                                                <TextareaAutosize
                                                    {...field}
                                                    maxRows={2}
                                                    style={{overflowY:'scroll',resize:'none',fontFamily:'Roboto,Helvetica,Arial,sans-serif',fontSize:'16px',width:'100%',
                                                            borderRadius:'4px',padding:'5px 20px', border:'0.1px solid gray'}}
                                                    disabled={modeViewOnly}
                                                    />
                                                    )}
                                                />
                                    </Grid>
                                </Grid>

                                <Grid container mt={1}>
                                    <Grid item xs={0.5} display={"flex"} alignItems={'center'} paddingLeft={0}>15.</Grid>
                                    <Grid item xs={3} display={"flex"} justifyContent={'center'} alignItems={'center'}>
                                        <Controller
                                            name="titleUser5"
                                            control={control}
                                            rules={{
                                            }}
                                            render={({ field }) => (
                                                <TextField 
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    size="small"
                                                    error={!!errors?.titleUser1}
                                                    helperText={errors?.titleUser1?.message}
                                                    InputProps={{sx: { height: 32 }}}
                                                    disabled={modeViewOnly}
                                                ></TextField>
                                            )}
                                            /> 
                                    </Grid>
                                    <Grid item xs={0.25} display={"flex"} justifyContent={'center'} alignItems={'center'}>:</Grid>
                                    <Grid item xs={8.25} display={"flex"} justifyContent={'center'} alignItems={'center'}>
                                        <Controller
                                            name="user5"
                                            control={control}
                                            render={({ field }) => (
                                                <TextareaAutosize
                                                    {...field}
                                                    maxRows={2}
                                                    style={{overflowY:'scroll',resize:'none',fontFamily:'Roboto,Helvetica,Arial,sans-serif',fontSize:'16px',width:'100%',
                                                            borderRadius:'4px',padding:'5px 20px', border:'0.1px solid gray'}}
                                                    disabled={modeViewOnly}
                                                    />
                                                    )}
                                                />
                                    </Grid>
                                </Grid>

                                
                                
                            </Grid>  
                            </div>
                            <Stack direction='row' justifyContent="flex-end" gap={2} mt={1} paddingBottom={1}>
                                <Button variant="contained" type="submit" disabled={modeViewOnly}>OK</Button>
                                <Button color="inherit" variant="contained" onClick={onCancel}>キャンセル</Button>
                            </Stack>
                        </form>
                    </div>                    
                </DialogContent>
            </Dialog>
        </>
    )
}
export default SettingCover1Dialog;