import { ControlModel, 
        MSTransCenterModel,   
        MsTrans3Model, 
        Ms3WindingModel, 
        MsTrans1Model,
        MsTransScottModel,
        MsWireModel,
        MsBusbarModel,
        MsImpedanceModel,
        MsBusductModel,
        MsReactorModel
} from "../models/Index"
import { MS_MODE_CALCULATION, MS_MODE_CHECKING, MS_MODE_DRAWING, MS_MODE_HARMONIC, MS_MODE_IMPROVE_PF, MS_MODE_PERCENT_Z, MS_MODE_POWER_FLOW, MS_MODE_VOLTDROP, VOLT_SIDE_PRIMARY, VOLT_SIDE_SECONDARY, VOLT_SIDE_THIRD } from "../statics"
import { doGetValueCapacity, doGetValueCapacityFromZero, getTopControl,getBottomControl,getLeftControl,
  getRightControl, 
  doGetCircuitVolt,
  isSPhaseCircuitEle,
  doGetTCName,
  doGetRotating,
  isSwitchOff,
  doGetTransCenterObjectPointer
  } from "./element"
import * as EKind from "../models/ElementKind"
import {doGetValueDropVoltRegular as doGetValueDropVoltRegularTrans3} from "./Trans3Function"
import {doGetValueDropVoltRegular as doGetValueDropVoltRegular3Wind} from "./3windingFunction"
import {doGetValueDropVoltRegular as doGetValueDropVoltRegularTrans1} from "./Trans1Function"
import {doGetValueDropVoltRegular as doGetValueDropVoltRegularTransScott} from "./transscottFunction"
import {doGetValueDropVoltRegular as doGetValueDropVoltRegularWire} from "./wireFunction"
import {doGetValueDropVoltRegular as doGetValueDropVoltRegularBusBar} from "./busbarFunction"
import {doGetValueDropVoltRegular as doGetValueDropVoltRegularImpedance} from "./impedance"
import {doGetValueDropVoltRegular as doGetValueDropVoltRegularBusDuct} from "./busductFunction"
import {doGetValueDropVoltRegular as doGetValueDropVoltRegularReactor, doGetPerZrReactor } from "./reactorFunction"
import { GET_BOTTOM, 
      GET_LEFT, 
      GET_LEFT_RIGHT, 
      GET_RIGHT, 
      GET_TOP, 
      GET_TOP_BOTTOM, 
      GET_TOP_BOTTOM_LEFT_RIGHT, 
      LEFT_CENTER, 
      MS_ROTATE_LEFT, 
      MS_ROTATE_NORMAL, 
      MS_ROTATE_RIGHT, 
      RIGHT_CENTER, 
      TOP_CENTER,
      MS_OUTSIDE_OUTSIDE, 
      MS_OUTSIDE_NEUTRAL,
      MS_TRANSSCOTT,
      MS_SEAT_MAIN,
      MS_SEAT_T,
      ROLE_ADMIN,
      ROLE_PM,
      ROLE_SP,
      ROLE_LP,
      IDR_VIEW1_FRAME_PM,
      IDR_VIEW1_FRAME_SERVICE,
      IDR_VIEW1_FRAME_LIGHT,
      MS_VOLTDROP_MOTOR,
      MS_VOLTDROP_REGULAR,
      IDS_PRJ_DRAWING,
      IDS_PRJ_CHEKING,
      IDS_PRJ_CALCULATION,
      IDS_PRJ_DROP_REGULAR,
      IDS_PRJ_POWERFACTOR,
      IDS_PRJ_HARMONIC,
      IDS_PRJ_POWER_FLOW,
      IDS_PRJ_DROP_MOTOR,
      IDS_PRJ_PERCENT_Z,
      IDS_TITLE_MOTOR
} from "../models/Constants"
import { store } from "..";
import { doGetConnectElement } from "./ElementList"
import { BeamsNumber } from "./FormatNumber"

const typeTopArray = [
    EKind.MS_SOURCE,
    EKind.MS_GENERATOR,
    EKind.MS_TRANS3,
    EKind.MS_TRANS1,
  ]
// ----------- doGetSourceVolt ----------- //
interface SourceVoltageResult {
  voltage: number,
  sPhase: boolean,
  upTransKind: number
}
const typeSourceArray = [
  EKind.MS_SOURCE,
  EKind.MS_GENERATOR,
  EKind.MS_TRANS3,
  EKind.MS_TRANS1,
  EKind.MS_TRANSSCOTT,
  EKind.MS_ZEROCOND,
  EKind.MS_3WINDING
]
const bottomArray =[
  EKind.MS_ZEROCOND,
  EKind.MS_TRANS3,
  EKind.MS_TRANS1,
  EKind.MS_TRANSSCOTT,
  EKind.MS_3WINDING

]

const topTranscenterArray = [
  EKind.MS_INTO_POINT,
  EKind.MS_TRANS3,
  EKind.MS_TRANS1,
  EKind.MS_TRANSSCOTT,
  EKind.MS_ZEROCOND
]
const bottomTranscenterArray =[
  EKind.MS_ZEROCOND,
  EKind.MS_TRANS3,
  EKind.MS_TRANS1,
  EKind.MS_TRANSSCOTT,
  EKind.MS_3WINDING
]
 
export function doGetValueCapacityTandG (target: ControlModel,
    diagramData: ControlModel[])
    {
      let topList: ControlModel[] = []
      diagramData.forEach(e=>{
        topList.push(e)
      })
      let capacity = 0
      for(;;){
        let topControl = getTopControl(target, topList)
        let control: ControlModel = topControl.data
        if(topControl.code !== 0){
            break;
        }

        if(typeTopArray.includes(control.type)){
            capacity += doGetValueCapacity(control,VOLT_SIDE_PRIMARY)
            break;
        }
        if(control.type === EKind.MS_ZEROCOND){
            capacity += doGetValueCapacityFromZero(control,topList)
        }
        target = control
        
    }
  return capacity
  }

  export default function doGetSourceVolt(
    target: ControlModel,
    diagramData: ControlModel[],
    nDirect: number,
    sPhase: boolean|undefined = undefined,
    upTransKind: number|undefined =undefined

  ) {
    let topList: ControlModel[] = []
      diagramData.forEach(e=>{
        topList.push(e)
      })
      let ret
      let voltage:number = 0
      let sPhase_upTransKind ={
        sPhase: sPhase,
        upTransKind: upTransKind
      }

      // get Top
  if(nDirect === GET_TOP || nDirect === GET_TOP_BOTTOM || nDirect === GET_TOP_BOTTOM_LEFT_RIGHT){
     for(;;){
        let topControl = getTopControl(target, topList)
        if(topControl.code !== 0){
          break;
        }
        let control: ControlModel = topControl.data
        if (typeSourceArray.includes(control.type)) {
          voltage = doGetCircuitVolt(control,VOLT_SIDE_SECONDARY)
        if(sPhase_upTransKind.sPhase !== undefined){
          sPhase_upTransKind = isSPhaseCircuitEle(control,VOLT_SIDE_SECONDARY,sPhase_upTransKind.upTransKind)
        }
        break;
      }
      let topControl1 = control
      for(;;){
          let topControl = getTopControl(topControl1,topList)
            topControl1 = topControl.data
          if(topControl.code !== 0){
            break;
          }
          if(typeSourceArray.includes(topControl1.type)){
            voltage = doGetCircuitVolt(topControl1,VOLT_SIDE_SECONDARY)
            if(sPhase_upTransKind.sPhase !== undefined){
              sPhase_upTransKind = isSPhaseCircuitEle(topControl1,VOLT_SIDE_SECONDARY,sPhase_upTransKind.upTransKind)
  
            }
            break
          }
          
      }
      if(voltage !== 0){
        break;
      }
      
     }
     if(voltage !== 0){
      ret = { voltage: voltage, sPhase: sPhase_upTransKind.sPhase, upTransKind: sPhase_upTransKind.upTransKind } as SourceVoltageResult
      return ret
    }
  }

    //get bottom
  if(nDirect === GET_BOTTOM || nDirect === GET_TOP_BOTTOM || nDirect === GET_TOP_BOTTOM_LEFT_RIGHT ){
      let bottomControl1:ControlModel = target
      for(;;){
        let bottomControl = getBottomControl(bottomControl1, topList)
          bottomControl1 = bottomControl.data
        if(bottomControl.code !== 0){
          break;
        }
        if (bottomArray.includes(bottomControl1.type)) {
          voltage = doGetCircuitVolt(bottomControl1,VOLT_SIDE_PRIMARY)
        if(sPhase_upTransKind.sPhase !== undefined){
          sPhase_upTransKind = isSPhaseCircuitEle(bottomControl1,VOLT_SIDE_PRIMARY,sPhase_upTransKind.upTransKind)
        }
        break;
      }
      let bottomControl2:ControlModel = bottomControl1
      for(;;){
          let bottomControl = getBottomControl(bottomControl2,topList)
          bottomControl2 = bottomControl.data
          if(bottomControl.code !== 0){
            break;
          }
          if(bottomArray.includes(bottomControl2.type)){
            voltage = doGetCircuitVolt(bottomControl2,VOLT_SIDE_PRIMARY)
            if(sPhase_upTransKind.sPhase !== undefined){
              sPhase_upTransKind = isSPhaseCircuitEle(bottomControl2,VOLT_SIDE_PRIMARY,sPhase_upTransKind.upTransKind)

            }
            break
          }
      }
      if(voltage !== 0){
        break;
      }

      }
      if(voltage !== 0){
      ret = { voltage: voltage, sPhase: sPhase_upTransKind.sPhase, upTransKind: sPhase_upTransKind.upTransKind } as SourceVoltageResult
      return ret
      }
    }

      //get left 
    if(nDirect === GET_LEFT || nDirect === GET_LEFT_RIGHT ||nDirect === GET_TOP_BOTTOM_LEFT_RIGHT ){
      let leftControl = target
      for(;;){
        let leftControl1 = getLeftControl(leftControl, topList)
        leftControl = leftControl1.data
        if(leftControl1.code !== 0){
          break;
        }
        if(leftControl.type === EKind.MS_ZEROCOND){
          voltage = doGetCircuitVolt(leftControl,VOLT_SIDE_PRIMARY)
          if(sPhase_upTransKind.sPhase !== undefined){
            sPhase_upTransKind = isSPhaseCircuitEle(leftControl,VOLT_SIDE_PRIMARY,sPhase_upTransKind.upTransKind)
          }
          break;
        }
        else if (leftControl.type === EKind.MS_3WINDING){
          voltage = doGetCircuitVolt(leftControl,VOLT_SIDE_SECONDARY)
          if(sPhase_upTransKind.sPhase !== undefined){
            sPhase_upTransKind = isSPhaseCircuitEle(leftControl,VOLT_SIDE_SECONDARY,sPhase_upTransKind.upTransKind)
        }
          break;
      }
    }
    if(voltage !== 0){
      ret = { voltage: voltage, sPhase: sPhase_upTransKind.sPhase, upTransKind: sPhase_upTransKind.upTransKind } as SourceVoltageResult
      return ret
    }
  }

    //get right
  if(nDirect === GET_RIGHT || nDirect === GET_LEFT_RIGHT ||nDirect === GET_TOP_BOTTOM_LEFT_RIGHT ){
    let rightControl = target
      for(;;){
        let rightControl1 = getRightControl(rightControl, topList)
        rightControl = rightControl1.data
        if(rightControl1.code !== 0){
          break;
        }
        if(rightControl.type === EKind.MS_ZEROCOND){
          voltage = doGetCircuitVolt(rightControl,VOLT_SIDE_PRIMARY)
          if(sPhase_upTransKind.sPhase !== undefined){
            sPhase_upTransKind = isSPhaseCircuitEle(rightControl,VOLT_SIDE_PRIMARY,sPhase_upTransKind.upTransKind)
          }
          break;
        }
        else if (rightControl.type === EKind.MS_3WINDING){
          voltage = doGetCircuitVolt(rightControl,VOLT_SIDE_THIRD)
          if(sPhase_upTransKind.sPhase !== undefined){
            sPhase_upTransKind = isSPhaseCircuitEle(rightControl,VOLT_SIDE_THIRD,sPhase_upTransKind.upTransKind)
        }
          break;
      }
    }
    return { voltage: voltage, sPhase: sPhase_upTransKind.sPhase, upTransKind: sPhase_upTransKind.upTransKind } as SourceVoltageResult
  }
  return { voltage: voltage, sPhase: sPhase_upTransKind.sPhase, upTransKind: sPhase_upTransKind.upTransKind } as SourceVoltageResult
}
  
export const doGetTransCenterSourceVolt =(diagramData:ControlModel[], element: ControlModel,nDirect:number,sPhase:boolean|undefined = undefined,upTransKind:number|undefined=undefined,intoPoint:boolean|undefined=undefined) =>{
    let voltage = 0
    let topList: ControlModel[] = []
      diagramData.forEach(e=>{
        topList.push(e)
      })
      let ret
      let sPhase_upTransKind ={
        sPhase: sPhase,
        upTransKind: upTransKind
      }
      let intoPointData = EKind.MS_INTO_POINT

      // if(element.type == intoPointData){
      //   voltage = transcenter?.properties.voltage
      //     if(sPhase !== undefined){
      //       sPhase = transcenter?.properties.sPhase
      //       upTransKind = transcenter?.properties.upTransKind

      //     }
      //     ret = { voltage: voltage, sPhase, upTransKind } as SourceVoltageResult
      //     return ret
      // }

      //getTop
    if(nDirect === GET_TOP || nDirect === GET_TOP_BOTTOM || nDirect === GET_TOP_BOTTOM_LEFT_RIGHT){
      for(;;){
        let topControl = getTopControl(element,topList)
          if(topControl.code !== 0){
            break;
          }
          let control = topControl.data
          if(topTranscenterArray.includes(control.type)){
            voltage = doGetCircuitVolt(control,VOLT_SIDE_SECONDARY)
            if(sPhase !== undefined){
              sPhase_upTransKind = isSPhaseCircuitEle(control,VOLT_SIDE_SECONDARY,upTransKind)
              if(intoPoint !== undefined && control.type === intoPointData){
                  intoPoint = true
              }
              break;
            }
          }
          let topControl1 = control
          for(;;){
              let topControl = getTopControl(topControl1,topList)
              topControl1 = topControl.data
              if(topControl.code !== 0){
                break
              }
              if(topTranscenterArray.includes(topControl1.type)){
                voltage = doGetCircuitVolt(topControl1,VOLT_SIDE_SECONDARY)
                if(sPhase !== undefined){
                  sPhase_upTransKind = isSPhaseCircuitEle(topControl1,VOLT_SIDE_SECONDARY,upTransKind)
                }
                if(intoPoint !== undefined && topControl1.type === intoPointData){
                  intoPoint = true
                }
                break;
              }
          }
          if(voltage !== 0){
            break;
          }
      }
      if(voltage !== 0){
        ret = { voltage: voltage, sPhase: sPhase_upTransKind.sPhase, upTransKind: sPhase_upTransKind.upTransKind } as SourceVoltageResult
      return ret
      }
    }

      //Get bottom
    if(nDirect === GET_BOTTOM || nDirect === GET_TOP_BOTTOM || nDirect === GET_TOP_BOTTOM_LEFT_RIGHT ){
      let bottomControl = element
    for(;;){
        let bottomControl1 = getBottomControl(bottomControl,topList)
        bottomControl = bottomControl1.data
        if(bottomControl1.code !== 0){
          break
        }
        if(bottomTranscenterArray.includes(bottomControl.type)){
          voltage = doGetCircuitVolt(bottomControl,VOLT_SIDE_PRIMARY)
          if(sPhase !== undefined){
            sPhase_upTransKind = isSPhaseCircuitEle(bottomControl,VOLT_SIDE_PRIMARY,upTransKind)
          }
          break;
        }
        let bottomControl2 = bottomControl
          for(;;){
            let bottomControl3 = getBottomControl(bottomControl2,topList)
            bottomControl2 = bottomControl3.data
            if(bottomControl3.code !== 0){
              break;
            }
            if(bottomTranscenterArray.includes(bottomControl2.type)){
              voltage = doGetCircuitVolt(bottomControl2,VOLT_SIDE_PRIMARY)
              if(sPhase !== undefined){
                sPhase_upTransKind = isSPhaseCircuitEle(bottomControl2,VOLT_SIDE_PRIMARY,upTransKind)
              }
              break;
            }
          }
          if(voltage !== 0){
            break;
          }
    }
    if( voltage !== 0){
      ret = { voltage: voltage, sPhase: sPhase_upTransKind.sPhase, upTransKind: sPhase_upTransKind.upTransKind } as SourceVoltageResult
      return ret
    }
  }

    // Get left
    if(nDirect === GET_LEFT || nDirect === GET_LEFT_RIGHT ||nDirect === GET_TOP_BOTTOM_LEFT_RIGHT ){
    let leftControl = element
    for(;;){
      let leftControl1 = getLeftControl(leftControl,topList)
      leftControl = leftControl1.data
      if(leftControl1.code !== 0){
          break;
      }
      if(leftControl.type === EKind.MS_ZEROCOND){
        voltage = doGetCircuitVolt(leftControl,VOLT_SIDE_PRIMARY)
        if(sPhase !== undefined){
          sPhase_upTransKind = isSPhaseCircuitEle(leftControl,VOLT_SIDE_PRIMARY,upTransKind)
        }
        break;
      }
      else if(leftControl.type == EKind.MS_3WINDING){
        voltage = doGetCircuitVolt(leftControl,VOLT_SIDE_SECONDARY);
        if(sPhase !== undefined){
          sPhase_upTransKind = isSPhaseCircuitEle(leftControl,VOLT_SIDE_SECONDARY,upTransKind)
        }
        break;
      }
    }
    if(voltage !== 0){
      ret = { voltage: voltage, sPhase: sPhase_upTransKind.sPhase, upTransKind: sPhase_upTransKind.upTransKind } as SourceVoltageResult
      return ret
    }
    }

    // Get right
    if(nDirect === GET_RIGHT || nDirect === GET_LEFT_RIGHT ||nDirect === GET_TOP_BOTTOM_LEFT_RIGHT ){
    let rightControl = element
    for(;;){
      let rightControl1 = getRightControl(rightControl,topList)
      rightControl = rightControl1.data
      if(rightControl1.code){
        break;
      }
      if(rightControl.type === EKind.MS_ZEROCOND){
        voltage = doGetCircuitVolt(rightControl,VOLT_SIDE_PRIMARY)
        if(sPhase !== undefined){
          sPhase_upTransKind = isSPhaseCircuitEle(rightControl,VOLT_SIDE_PRIMARY,upTransKind)
        }
        break;
      }
      else if(rightControl.type === EKind.MS_3WINDING){
        voltage = doGetCircuitVolt(rightControl,VOLT_SIDE_THIRD)
          if(sPhase !== undefined){
            sPhase_upTransKind = isSPhaseCircuitEle(rightControl,VOLT_SIDE_THIRD,upTransKind)
          }
          break;
        }
      }
      return { voltage: voltage, sPhase: sPhase_upTransKind.sPhase, upTransKind: sPhase_upTransKind.upTransKind }
    }
    return { voltage: voltage, sPhase: sPhase_upTransKind.sPhase, upTransKind: sPhase_upTransKind.upTransKind }
  }

//CMSSV3Doc::DoGetTCPointerByElement
export const doGetTCPointerByElement =(pStdElement: ControlModel) => {
  let listAllElement =[...(store.getState().app.diagram.diagramData.filter((e: any) => e.tabId === 1)[0]).shape];

  let tcControlList = [...listAllElement.filter((e: any) => e.type === EKind.MS_TRANSCENTER && e.properties.refNo == doGetTCName(pStdElement))];
  for(let tcControl of tcControlList)
  {
    let listTCAllElement = [...(store.getState().app.diagram.diagramData.filter((e: any) => e.tabId === tcControl.properties.tabId)[0]).shape];
    
   for(let tcAllElement of listTCAllElement)
   {
    if(tcAllElement.type == pStdElement.type && tcAllElement.id == pStdElement.id)
    {
      return tcControl.properties;
    }
   }
  }
  return null;
}

//CMSSV3Doc::DoGetTextVoltDropRegular
export const doGetTextVoltDropRegular =(pStdElement: ControlModel, nPartKind:number, bUnit:boolean = true) => {
  let stdVoltage ={
    dStdVoltage : 0,
    sStdVoltage : ''
  }
  let listElement;
  let infoCircuit = store.getState().app.diagram.infoCircuit;
  if(doGetTCName(pStdElement) == '') {
    listElement = [...(store.getState().app.diagram.diagramData.filter((e: any) => e.tabId === 1)[0]).shape];
  }
  else {
    let pTransCenter = doGetTCPointerByElement(pStdElement) as MSTransCenterModel;
    listElement = [...(store.getState().app.diagram.diagramData.filter((e: any) => e.tabId === pTransCenter.tabId)[0]).shape];
  }

  let nDirect = 0;
  switch(doGetRotating(pStdElement)) {
    case MS_ROTATE_NORMAL:
      nDirect = TOP_CENTER;
      break;
    case MS_ROTATE_LEFT:
      nDirect = LEFT_CENTER;
      break;
    case MS_ROTATE_RIGHT:
      nDirect = RIGHT_CENTER;
      break;
  }
  let pTop = pStdElement;
  let pZeroCond = null;

  for(;;) {

    let resultTopTop = doGetConnectElement(listElement, pTop, nDirect);
    if (resultTopTop.code != 0) {
      //OLD
      // if(pTop.type != EKind.MS_ZEROCOND) {
      //   break;
      // }
      //NEW
      if(pTop.type !== EKind.MS_ZEROCOND) {
        if (pZeroCond !== null){
          pTop = pZeroCond
          pZeroCond = null
          continue
        }
        else{
          if(pStdElement.type !== EKind.MS_ZEROCOND){
            break
          }
          else{
            pTop = pStdElement
          }
        }
      }
      //end new
      nDirect = TOP_CENTER;
      resultTopTop = doGetConnectElement(listElement, pTop, nDirect);
      if(resultTopTop.code != 0) {
        nDirect = LEFT_CENTER;
        resultTopTop = doGetConnectElement(listElement, pTop, nDirect);
        if (resultTopTop.code != 0) {
          nDirect = RIGHT_CENTER;
          resultTopTop = doGetConnectElement(listElement, pTop, nDirect);
          if (resultTopTop.code != 0) {
            break;
          }
        }
      }
    }
    let pTopTop = resultTopTop.data;

    if(isSwitchOff(pTopTop)) {
      continue;
    }
    pTop = pTopTop;
    if(pTopTop.type == EKind.MS_ZEROCOND) {
      pZeroCond = pTopTop;
      continue;
    }

    if(pTopTop.type == EKind.MS_SOURCE || pTopTop.type == EKind.MS_GENERATOR) {
      //old
      // stdVoltage.dStdVoltage = doGetCircuitVolt(pTopTop, VOLT_SIDE_SECONDARY);
      // break;
      //new
      const tem_voltage = doGetCircuitVolt(pTopTop, VOLT_SIDE_SECONDARY)
      if(stdVoltage.dStdVoltage > tem_voltage && stdVoltage.dStdVoltage !== 0){
        stdVoltage.dStdVoltage = tem_voltage
      }
      if(pZeroCond !== null){
        pTop = pZeroCond
        pZeroCond = null
        continue
      }
      else{
        pTop = pStdElement
      }
      continue
      //end new
    } 
    
    if(pTopTop.type == EKind.MS_TRANS3) {
      let pTrans3 = pTopTop.properties as MsTrans3Model;
      if (pTrans3.dropRegular) {
        stdVoltage.dStdVoltage = doGetValueDropVoltRegularTrans3(pTrans3, infoCircuit.stdCapacity);
      }
      else {
        stdVoltage.dStdVoltage = doGetCircuitVolt(pTopTop, VOLT_SIDE_SECONDARY);
        if (pZeroCond != null) {
          pTop = pZeroCond;
          pZeroCond = null;
          continue;
        }
      }
      break;
    }

    if (pTopTop.type == EKind.MS_3WINDING) {
      if (nDirect == LEFT_CENTER) {
        let p3Winding = pTopTop.properties as (Ms3WindingModel);
        if (p3Winding.partSecondary.dropRegular) {
          stdVoltage.dStdVoltage = doGetValueDropVoltRegular3Wind(p3Winding, infoCircuit.stdCapacity, VOLT_SIDE_SECONDARY);
        }
        else {
          stdVoltage.dStdVoltage = doGetCircuitVolt(pTopTop, VOLT_SIDE_SECONDARY);
          if (pZeroCond != null) {
            pTop = pZeroCond;
            pZeroCond = null;
            continue;
          }
        }
      }
      else if (nDirect == RIGHT_CENTER) {
        let p3Winding = pTopTop.properties as (Ms3WindingModel);
        if (p3Winding.partThird.dropRegular) {
          stdVoltage.dStdVoltage = doGetValueDropVoltRegular3Wind(p3Winding, infoCircuit.stdCapacity, VOLT_SIDE_THIRD);
        }
        else {
          stdVoltage.dStdVoltage = doGetCircuitVolt(pTopTop, VOLT_SIDE_THIRD);
          if (pZeroCond != null) {
            pTop = pZeroCond;
            pZeroCond = null;
            continue;
          }
        }
      }
      break;
    }
    if (pTopTop.type == EKind.MS_TRANS1) {
      let pTrans1 = pTopTop.properties as (MsTrans1Model);
      switch (nPartKind) {
      case MS_OUTSIDE_NEUTRAL:
        //Check ***
        if (pTrans1.partOutIn.dropRegular) {
          stdVoltage.dStdVoltage = doGetValueDropVoltRegularTrans1(pTrans1, infoCircuit.stdCapacity, nPartKind);
        }
        else {
          stdVoltage.dStdVoltage = doGetCircuitVolt(pTopTop, VOLT_SIDE_SECONDARY) / 2;
          if (pZeroCond != null) {
            pTop = pZeroCond;
            pZeroCond = null;
            continue;
          }
        }
        break;
      case MS_OUTSIDE_OUTSIDE:  
        if (pTrans1.partOutOut.dropRegular) {
          stdVoltage.dStdVoltage = doGetValueDropVoltRegularTrans1(pTrans1, infoCircuit.stdCapacity, nPartKind);
        }
        else {
          stdVoltage.dStdVoltage = doGetCircuitVolt(pTopTop,VOLT_SIDE_SECONDARY);
          if (pZeroCond != null) {
            pTop = pZeroCond;
            pZeroCond = null;
            continue;
          }
        }
        break;
      }
      break;
    }
    if (pTopTop.type == MS_TRANSSCOTT) {
      let pTransScott = pTopTop.properties as (MsTransScottModel);
      switch (nPartKind) {
      case MS_SEAT_MAIN:    
        if (pTransScott.partSeatM.dropRegular) {
          stdVoltage.dStdVoltage = doGetValueDropVoltRegularTransScott(pTransScott, infoCircuit.stdCapacity, nPartKind);
        }
        else {
          stdVoltage.dStdVoltage = doGetCircuitVolt(pTopTop, VOLT_SIDE_SECONDARY);
          if (pZeroCond != null) {
            pTop = pZeroCond;
            pZeroCond = null;
            continue;
          }
        }
        break;
      case MS_SEAT_T:    
        if (pTransScott.partSeatT.dropRegular) {
          stdVoltage.dStdVoltage = doGetValueDropVoltRegularTransScott(pTransScott, infoCircuit.stdCapacity, nPartKind);
        }
        else {
          stdVoltage.dStdVoltage = doGetCircuitVolt(pTopTop, VOLT_SIDE_SECONDARY);
          if (pZeroCond != null) {
            pTop = pZeroCond;
            pZeroCond = null;
            continue;
          }
        }
        break;
      }
      break;
    }
    if (pTopTop.type == EKind.MS_WIRE) {
      let pWire = pTopTop.properties as (MsWireModel);
      if (pWire.dropRegular) {
        let temp ={
          dStdVoltage : 0,
          sStdVoltage : ''
        }
        temp = doGetTextVoltDropRegular(pTopTop, nPartKind, false);
        stdVoltage.dStdVoltage = doGetValueDropVoltRegularWire(pWire, infoCircuit.stdCapacity, temp.dStdVoltage, nPartKind);
        break;
      }
      stdVoltage.dStdVoltage = doGetCircuitVolt(pTopTop, VOLT_SIDE_SECONDARY);
      continue;
    }
    if (pTopTop.type == EKind.MS_BUSBAR) {
      let pBusBar = pTopTop.properties as (MsBusbarModel);
      if (pBusBar.dropRegular) {
        let temp ={
          dStdVoltage : 0,
          sStdVoltage : ''
        }
        temp = doGetTextVoltDropRegular(pTopTop, nPartKind, false);
        stdVoltage.dStdVoltage = doGetValueDropVoltRegularBusBar(pBusBar, infoCircuit.stdCapacity, temp.dStdVoltage, nPartKind);
        break;
      }
      stdVoltage.dStdVoltage = doGetCircuitVolt(pTopTop, VOLT_SIDE_SECONDARY);
      continue;
    }
    if (pTopTop.type == EKind.MS_IMPEDANCE) {
      let pImpedance = pTopTop.properties as (MsImpedanceModel);
      if (pImpedance.dropRegular) {
        let temp ={
          dStdVoltage : 0,
          sStdVoltage : ''
        }
        temp = doGetTextVoltDropRegular(pTopTop, nPartKind, false);
        stdVoltage.dStdVoltage = doGetValueDropVoltRegularImpedance(pImpedance, infoCircuit.stdCapacity, temp.dStdVoltage, nPartKind);
        break;
      }
      stdVoltage.dStdVoltage = doGetCircuitVolt(pTopTop, VOLT_SIDE_SECONDARY);
      continue;
    }
    if (pTopTop.type == EKind.MS_BUSDUCT) {
      let pBusDuct =  pTopTop.properties as (MsBusductModel);
      if (pBusDuct.dropRegular) {
        let temp ={
          dStdVoltage : 0,
          sStdVoltage : ''
        }
        temp = doGetTextVoltDropRegular(pTopTop, nPartKind, false);
        stdVoltage.dStdVoltage = doGetValueDropVoltRegularBusDuct(pBusDuct, infoCircuit.stdCapacity, temp.dStdVoltage, nPartKind);
        break;
      }
      stdVoltage.dStdVoltage = doGetCircuitVolt(pTopTop, VOLT_SIDE_SECONDARY);
      continue;
    }
    if (pTopTop.type == EKind.MS_REACTOR) {
      let pReactor =  pTopTop.properties as (MsReactorModel);
      if (pReactor.dropRegular) {
        let temp ={
          dStdVoltage : 0,
          sStdVoltage : ''
        }
        temp = doGetTextVoltDropRegular(pTopTop, nPartKind, false);
        let percentZ = doGetPerZrReactor(pReactor, infoCircuit.stdCapacity);
        stdVoltage.dStdVoltage = doGetValueDropVoltRegularReactor(pReactor, infoCircuit.stdCapacity, temp.dStdVoltage);
        break;
      }
      stdVoltage.dStdVoltage = doGetCircuitVolt(pTopTop, VOLT_SIDE_SECONDARY);
      continue;
    }
// V2.42  
    if (pTopTop.type == EKind.MS_INTO_POINT) {
      let pTransCenter = doGetTransCenterObjectPointer(pTopTop);
      if (pTransCenter) {
        doGetTextVoltDropRegular(pTransCenter, nPartKind, bUnit);
        continue;
      }
      break;
    }
  }

  if (stdVoltage.dStdVoltage <= 0) {
    stdVoltage.dStdVoltage = doGetCircuitVolt(pStdElement, VOLT_SIDE_SECONDARY);
  }
  let sText = BeamsNumber(stdVoltage.dStdVoltage, 5).toString();
  if (bUnit) {
    sText += "V";
  }
  stdVoltage.sStdVoltage = sText;
  return stdVoltage;
}

export const doGetTextOpeMode = (
  m_nOpeMode: number, m_nDropMode: number, listAllElement: ControlModel[]
) => {
  let sMode = "";
  switch (m_nOpeMode) {
  case MS_MODE_DRAWING:
    sMode = IDS_PRJ_DRAWING;
    break;
  case MS_MODE_CHECKING:
    sMode = IDS_PRJ_CHEKING;
    break;
  case MS_MODE_PERCENT_Z:
    sMode = IDS_PRJ_PERCENT_Z;
    break;
  case MS_MODE_CALCULATION:
    sMode = IDS_PRJ_CALCULATION;
    break;
  case MS_MODE_VOLTDROP:
    if (m_nDropMode == MS_VOLTDROP_MOTOR) {
      let bCalcDrop = false;
      let listMotor = listAllElement.filter((e) => e.type === EKind.MS_MOTOR);
      listMotor.forEach((pMotor) => {
        if (pMotor.properties.calcDrop == true){
          sMode = pMotor.properties.refNo + IDS_PRJ_DROP_MOTOR;
          bCalcDrop = true;
        }
      })
      if (!bCalcDrop){
        sMode = IDS_TITLE_MOTOR + IDS_PRJ_DROP_MOTOR;
      }
    }
    else if (m_nDropMode == MS_VOLTDROP_REGULAR) {
      sMode = IDS_PRJ_DROP_REGULAR;
    }
    break;
  case MS_MODE_IMPROVE_PF:
    break;
  case MS_MODE_HARMONIC:
    sMode = IDS_PRJ_HARMONIC;
    break;
  case MS_MODE_POWER_FLOW:
    sMode = IDS_PRJ_POWER_FLOW;
    break;
  }
  return sMode;
}