import { Box, Button, Checkbox, CircularProgress, FormControlLabel, Grid, Stack, Tab, Tabs, TextField, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { EquipWireiModel, ListStdCapacityModel } from '../../../models/breakModel'
import { connect } from 'react-redux'
import { ApplicationState, actionCreators, selectors } from '../../../store'
import { Controller, useForm } from 'react-hook-form'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Path as RoutePath } from "../../../path";
import FetchHelper from '../../FetchHelper'
import { LoadingButton } from '@mui/lab'
//-----------
//FETCH ID
const GET_LIST_CT1CURRENT = "GET_LIST_CT1CURRENT"
const UPDATE_LIST_CT1CURRENT = "UPDATE_LIST_CT1CURRENT"
const CREATE_LIST_CT1CURRENT = "CREATE_LIST_CT1CURRENT"

const initialValue: ListStdCapacityModel = {
    lStdCapacityId: 0,
    bIsDefault: false,
    dStdCapacity: 0,
}
// -------------
// Props
export type ListStdCapacityEditProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
const ListStdCapacityEdit = (props: ListStdCapacityEditProps) => {
    const {
        userId,
        onCreate, 
        onCreateError,
        onUpdate, 
        onUpdateError,
        onSuccess, 
        onError,
    } = props;

    const { control, formState: { errors }, handleSubmit, setValue, getValues, } = useForm<ListStdCapacityModel>({
        criteriaMode: "all",
        defaultValues: initialValue,
        mode: "all",
    })
    type MyParams = {
        id: string;
        mode: string;
    };
    let { id, mode } = useParams<keyof MyParams>() as MyParams;
    const location = useLocation();
    let navigate = useNavigate();
    const [loadingFlag, setLoadingFlag] = useState(mode === "edit" ? true : false);
    const gridRef = useRef() as any;
    let tab = location.state && location.state.tab;
    const Exp = /^[-+]?\d+(\.\d+)?$/;
    const messageIsNotNumber = "数字を入力してください。";

    const handleSuccess = (data: any) => {
        console.log('>>>> handle Successs');
        // if(data && data.data){
        const obj = data.data;
        setValue("lStdCapacityId", obj.lStdCapacityId);
        setValue("bIsDefault", obj.bIsDefault);
        setValue("dStdCapacity", obj.dStdCapacity?? "");
        // }
        setLoadingFlag(false);
    }

    // 検索エラー時
    const handleError = (success: boolean, data: any, error: any) => {
        console.log('>>>> handle Error');
        gridRef.current.hideOverlay();
    }

    // 検索実行
    const handleEditSubmit = (data: ListStdCapacityModel) => {
        setLoadingFlag(true);
        if (mode === "edit") {
            let request = {
                lStdCapacityId: getValues("lStdCapacityId"),
                bIsDefault: getValues("bIsDefault") ?? null,
                dStdCapacity: getValues("dStdCapacity") ?? null
            } as any
            const object = {
                requestPrms: request,
                userId: userId,
            }
            onUpdate(object);
        } else if (mode === "add") {
            let request = {
                bIsDefault: getValues("bIsDefault") ?? null,
                dStdCapacity: getValues("dStdCapacity") ?? null,
            } as any
            const object = {
                requestPrms: request,
                userId: userId,
            }
            onCreate(object);
        }
    }

    // キャンセル
    const handleCancel = () => {
        if (location.key !== "default")
            navigate(-1);
        else
            navigate(RoutePath.ListStdCapacityList);
    }

    // Submit後のAPI呼び出しエラー時
    const handleErrorUpdate = (success: boolean, data: any, error: any) => {
        onUpdateError();
        setLoadingFlag(false);
    }

    // Submit後のAPI呼び出し成功時
    const handleSuccessSubmit = (success: boolean, data: any, error: any) => {
        onSuccess();
        if (mode === "add") {
            navigate(RoutePath.ListStdCapacityEdit + `/edit/${data.data.lStdCapacityId}`, { replace: true })
        }
        setLoadingFlag(false);
    }

    // Submit後のAPI呼び出しエラー時
    const handleErrorSubmit = (success: boolean, data: any, error: any) => {
        onCreateError();
        setLoadingFlag(false)
    }

    //入力欄背景
    const divStyles = {
        backgroundColor: 'white',
        border: '1px solid #bebebe',
        boxShadow: '0 0 5px #fff, 0 0 5px #bebebe, 0 0 1px #aaa',
        padding: '20px 40px',
        marginTop: '40px',
    }

    return (
        <>
            {
                mode === "edit" &&
                <FetchHelper
                    fetchId={GET_LIST_CT1CURRENT}
                    url={`/master/list_stdcapacity/get`}
                    method="POST"
                    request={true}
                    params={{
                        requestPrms: {
                            id: id
                        },
                        userId: userId,
                    }}
                    json={true}
                    onComplete={(success, data, error) => {
                        success ? handleSuccess(data) : handleError(success, data, error);
                    }}
                />
            }
            {loadingFlag && (
                <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1500, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                    <CircularProgress />
                </div>
            )}
            <div style={{ height: "100%", width: "100%", marginTop: "10px" }}>
                <Stack direction="row" justifyContent="flex-start" alignItems="center" sx={{}} style={{ margin: '18px', color: 'MenuText' }}>
                    <Typography variant="h4">
                        基準容量詳細
                    </Typography>
                </Stack>
                <form onSubmit={handleSubmit(handleEditSubmit)}>
                    <div style={divStyles}>
                        <Typography variant="h6">
                            基準容量
                        </Typography>
                        <Stack mt={3} direction={{ xs: 'column', sm: 'row' }} sx={{ maxHeight: "calc(100vh)", overflow: 'auto' }}>
                            <Grid container spacing={3} direction={'row'}>
                                <Grid item xs={6} mt={1} >
                                    <Controller
                                        control={control}
                                        name="dStdCapacity"
                                        rules={{
                                            pattern: {
                                                value: Exp,
                                                message: messageIsNotNumber,
                                            }
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                fullWidth
                                                {...field}
                                                error={!!errors?.dStdCapacity}
                                                helperText={errors?.dStdCapacity?.message}
                                                type="text"
                                                size="small"
                                                label="基準容量値"
                                            // InputLabelProps={{ shrink: true }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6} mt={1}>
                                    <Controller
                                        control={control}
                                        name="bIsDefault"
                                        rules={{
                                            pattern: {
                                                value: Exp,
                                                message: messageIsNotNumber,
                                            }
                                        }}
                                        render={({ field }: any) => (
                                            <FormControlLabel {...field} control={<Checkbox />} checked={field.value} label="デフォルト" labelPlacement="start" />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Stack>
                    </div>
                    <Stack direction='row' justifyContent="flex-end" spacing={2} sx={{ mt: 1, mr: 2 }}>
                        <LoadingButton variant="contained" type="submit" loading={loadingFlag}>保存</LoadingButton>
                        <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                    </Stack>
                </form>
            </div>
            <FetchHelper fetchId={UPDATE_LIST_CT1CURRENT}
                onComplete={(success: boolean, data: any, error: any) =>
                    success ? handleSuccessSubmit(success, data, error) : handleErrorUpdate(success, data, error)} />

            <FetchHelper fetchId={CREATE_LIST_CT1CURRENT}
                onComplete={(success, data, error) => success ?
                    handleSuccessSubmit(success, data, error) :
                    handleErrorSubmit(success, data, error)} />

        </>
    );
}
const mapStateToProps = (state: ApplicationState) => (
    {
        userId: state.app.user?.userId,
    }
)
const mapDispatchToProps = (dispatch: any) => {
    return {
        onCreate: (data: any) => dispatch(actionCreators.fetch(CREATE_LIST_CT1CURRENT, `/master/list_stdcapacity/add`, "POST", data, false, true)),
        onUpdate: (data: any) => dispatch(actionCreators.fetch(UPDATE_LIST_CT1CURRENT, `/master/list_stdcapacity/modify`, "POST", data, false, true)),
        onSuccess: () =>
            dispatch(actionCreators.showMessage({
                type: "info",
                title: "基準容量更新",
                body: "基準容量を更新しました。",
            })),
        onError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "検索",
                body: "検索できません。",
            })),
        onCreateError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "基準容量登録",
                body: "基準容量を登録出来ませんでした。",
            })),
        onUpdateError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "基準容量編集",
                body: "基準容量を更新出来ませんでした。",
            })),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ListStdCapacityEdit as any) 