import './scrollbar.css';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect, useRef, useCallback } from 'react';

type HorizontalScrollbarProps = {
  contentRef: React.RefObject<HTMLDivElement>;
  currentTabId: number;
  widthGrid: number;
  scaleGrid: number;
};

const HorizontalScrollbar = ((props: HorizontalScrollbarProps) => {
  const { contentRef, currentTabId, widthGrid, scaleGrid } = props;

  const scrollTrackRef = useRef<HTMLDivElement>(null);
  const scrollThumbRef = useRef<HTMLDivElement>(null);

  const observer = useRef<ResizeObserver | null>(null);

  const [thumbWidth, setThumbWidth] = useState(20);
  const [scrollStartPosition, setScrollStartPosition] = useState<number>(0);
  const [initialScrollLeft, setInitialScrollLeft] = useState<number>(0);

  const [isDragging, setIsDragging] = useState(false);
  
  const [isHideScrollbar, setIsHideScrollbar] = useState(true);

  const handleResize = useCallback(() => {
    if (contentRef.current && scrollTrackRef.current) {
      const ref = contentRef.current;
      let { clientWidth } = ref;
      const { clientWidth: trackSizeH } = scrollTrackRef.current;

      let scrollWidth = widthGrid;
      const ratioWidth = Math.min((clientWidth / scrollWidth), 1);

      setThumbWidth(Math.max(ratioWidth * trackSizeH, 20));
      setIsHideScrollbar(ratioWidth === 1);
    }
  }, [widthGrid, scaleGrid, currentTabId])

  const handleScrollButton = (direction: 'left' | 'right') => {
    const { current } = contentRef;
    if (current) {
      const scrollAmount = direction === 'right' ? 200 : -200;
      current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  }

  const handleTrackClick = useCallback(
    (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      const { current: trackCurrent } = scrollTrackRef;
      const { current: contentCurrent } = contentRef;
      if (trackCurrent && contentCurrent) {
        const { clientX } = e;
        const target = e.target as HTMLDivElement;
        const rect = target.getBoundingClientRect();
        const trackLeft = rect.left;
        const thumbOffset = -(thumbWidth / 2);
        const clickRatio =
          (clientX - trackLeft + thumbOffset) / trackCurrent.clientWidth;
        const scrollAmount = Math.floor(
          clickRatio * contentCurrent.scrollWidth
        );
        contentCurrent.scrollTo({
          left: scrollAmount,
          behavior: 'smooth',
        });
      }
    },
    [thumbWidth]
  );

  const handleThumbPosition = useCallback(() => {
    if (
      !contentRef.current ||
      !scrollTrackRef.current ||
      !scrollThumbRef.current
    ) {
      return;
    }
    const { scrollLeft: contentLeft, scrollWidth: contentWidth } =
      contentRef.current;
    const { clientWidth: trackWidth } = scrollTrackRef.current;
    let newLeft = (+contentLeft / +contentWidth) * trackWidth;
    newLeft = Math.min(newLeft, trackWidth - thumbWidth);
    const thumb = scrollThumbRef.current;
    thumb.style.left = `${newLeft}px`;
  }, []);

  const handleThumbMousedown = useCallback((e: any) => {
    e.preventDefault();
    setScrollStartPosition(e.clientX);
    if (contentRef.current) setInitialScrollLeft(contentRef.current.scrollLeft);
    setIsDragging(true);
  }, []);
  
  const handleThumbMouseup = useCallback(
    (e: any) => {
      e.preventDefault();
      if (isDragging) {
        setIsDragging(false);
      }
    },
    [isDragging]
  );

  const handleThumbMousemove = useCallback(
    (e: any) => {
      e.preventDefault();
      if (isDragging && contentRef.current) {
        const {
          scrollWidth: contentScrollWidth,
          offsetWidth: contentOffsetWidth,
        } = contentRef.current;
        const deltaX =
          (e.clientX - scrollStartPosition) *
          (contentOffsetWidth / thumbWidth);
        const newScrollLeft = Math.min(
          initialScrollLeft + deltaX,
          contentScrollWidth - contentOffsetWidth
        );

        contentRef.current.scrollLeft = newScrollLeft;
      }
    },
    [isDragging, scrollStartPosition, thumbWidth]
  );

  // region touch event
  const handleThumbTouchstart = useCallback((e: any) => {
    setScrollStartPosition(e.changedTouches[0].clientX);
    if (contentRef.current) setInitialScrollLeft(contentRef.current.scrollLeft);
    setIsDragging(true);
  }, []);
  
  const handleThumbTouchend = useCallback(
    (e: any) => {
      if (e.cancelable){
        if (isDragging) setIsDragging(false);
      }
    },
    [isDragging]
  );

  const handleThumbTouchmove = useCallback(
    (e: any) => {
      if (isDragging && contentRef.current) {
        const {
          scrollWidth: contentScrollWidth,
          offsetWidth: contentOffsetWidth,
        } = contentRef.current;
        const deltaX =
          (e.changedTouches[0].clientX - scrollStartPosition) *
          (contentOffsetWidth / thumbWidth);
        const newScrollLeft = Math.min(
          initialScrollLeft + deltaX,
          contentScrollWidth - contentOffsetWidth
        );

        contentRef.current.scrollLeft = newScrollLeft;
      }
    },
    [isDragging, scrollStartPosition, thumbWidth]
  );

  // If the content and the scrollbar track exist, use a ResizeObserver to adjust height of thumb and listen for scroll event to move the thumb
  useEffect(() => {
    if (contentRef.current) {
      const ref = contentRef.current;
      observer.current = new ResizeObserver(() => {handleResize();});
      observer.current.observe(ref);
      ref.addEventListener('scroll', handleThumbPosition);
      return () => {
        observer.current?.unobserve(ref);
        ref.removeEventListener('scroll', handleThumbPosition);
      };
    }
  },[widthGrid, scaleGrid, currentTabId])

  // Listen for mouse events to handle scrolling by dragging the thumb
  useEffect(() => {
    document.addEventListener('mousemove', handleThumbMousemove);
    document.addEventListener('mouseup', handleThumbMouseup);
    document.addEventListener('mouseleave', handleThumbMouseup);
  
    return () => {
      document.removeEventListener('mousemove', handleThumbMousemove);
      document.removeEventListener('mouseup', handleThumbMouseup);
      document.removeEventListener('mouseleave', handleThumbMouseup);
    };
  }, [handleThumbMousemove, handleThumbMouseup]);

  useEffect(() => {
    document.addEventListener('touchmove', handleThumbTouchmove);
    document.addEventListener('touchend', handleThumbTouchend);
    document.addEventListener('touchcancel', handleThumbTouchend);

    return () => {
      document.removeEventListener('touchmove', handleThumbTouchmove);
      document.removeEventListener('touchend', handleThumbTouchend);
      document.removeEventListener('touchcancel', handleThumbTouchend);
    };
  }, [handleThumbTouchmove, handleThumbTouchend]);

  return (
    <div
      className="custom-scrollbars__scrollbar-horizontal"
      style={{visibility: isHideScrollbar ? 'hidden' : 'visible'}}
    >
      <FontAwesomeIcon 
        icon={faCaretLeft}
        className="custom-scrollbars__button"
        onClick={() => handleScrollButton('left')}
      />
      <div className="custom-scrollbars__track-and-thumb-horizontal">
        <div
          className="custom-scrollbars__track-horizontal"
          ref={scrollTrackRef}
          onClick={handleTrackClick}
        ></div>
        <div
          className="custom-scrollbars__thumb-horizontal"
          ref={scrollThumbRef}
          onMouseDown={handleThumbMousedown}
          onTouchStart={handleThumbTouchstart}
          style={{ width: `${thumbWidth}px` }}
        ></div>
      </div>
      <FontAwesomeIcon 
        icon={faCaretRight}
        className="custom-scrollbars__button"
        onClick={() => handleScrollButton('right')}
      />
    </div>
  );
});

export default HorizontalScrollbar;