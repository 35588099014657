import { Box, Button, Checkbox, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Stack, Tab, Tabs, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as Constant from "../../models/Constants";
import { Iele3WindingPart, InfoCircuit, Ms3WindingModel } from '../../models/Index';
import { MS_MODE_CHECKING, MS_MODE_DRAWING, VOLT_SIDE_PRIMARY, VOLT_SIDE_SECONDARY, VOLT_SIDE_THIRD } from "../../statics";
import { doGetValueVoltDrop, doSetValuesWindingTab1, doSetVoltage3Winding } from "../../utils/3windingFunction";
import { BeamsNumber } from "../../utils/FormatNumber";
import { IsOkPowerFactor } from "../../utils/calcPointFunction";
import { BeamsValue2, doCalcSetValue, doGetTextDampingTime, doGetTextInRushTimes, doGetTextInRushValue, doGetTextIntensityTime, doGetTextIntensityTimes, doGetTextIntensityValue, doGetTextVoltDropPF, doGetValueDropLoadCapacity, doSetValueDropLoadCurrent } from "../../utils/windingPartFunction";
import TextFieldOptions from "../common/TextFieldOptions";

const radioStyles = {
    padding: '0 0 0 9px',
}
const checkBoxStyles = { padding: '4px 4px 4px 8px' }

const fieldsetStyle = {
    border:"1px solid #AAAAAA",
    padding:"auto",
}

export type Ms3WindingEditDialogFormProps = {
    data: any;
    onOK: (data: any) => void;
    onCancel: () => void;
    infoCircuit: InfoCircuit;
    userRole: string;
    m_bModePM: boolean;
    modeViewOnly:boolean;
    dispInputDialog: any;
    processMode: number;
};

function a11yProps(index: number) {
    return {
        id: `tab-${index}`,
        "aria-controls": `tabpanel-${index}`,
    };
}
const checkboxStyle2 = { padding: '4px 4px 4px 9px' }

//Tabs------------------
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

export const Ms3WindingEditDialog = (props: Ms3WindingEditDialogFormProps) => {
    // #region props  
    // 初期値設定
    const { m_bModePM, modeViewOnly, userRole, infoCircuit, data, onOK, onCancel,dispInputDialog,processMode } = props;
    
    const copyData =(data:any,part:string) =>{
        return{
            perRk3:BeamsNumber(data[part].perRk3,4),
            xR: BeamsNumber(data[part].xR,4),
            perZk3: BeamsNumber(data[part].perZk3,4),
            perXk3: BeamsNumber(data[part].perXk3,4)
        }
    }
    const initialValue: Ms3WindingModel = {...data,
        partPrimary:{...data.partPrimary,...copyData(data,"partPrimary")},
        partSecondary:{...data.partSecondary,...copyData(data,"partSecondary")},
        partThird:{...data.partThird,...copyData(data,"partThird")},
    }
    const { control, handleSubmit, formState: { errors }, setValue, getValues, setError,clearErrors} = useForm<Ms3WindingModel>({
        mode: 'all',
        criteriaMode: "all",
        defaultValues: initialValue,
    });  
    const [selectTab, setSelectTab] = useState(0);
    const [isEnableOkButton,setIsEnableOkButton] = useState<boolean>(true);
    const [disabledTab5Flag,setDisabledTab5Flag] = useState<boolean>(!getValues("partSecondary.dropRegular"));
    const [disableTab6Flag,setDisabledTab6Flag] = useState<boolean>(!getValues("partThird.dropRegular"));
    const [makeDataInput,setMakeDataInput] = useState<boolean>(getValues("makeDataInput"));
    const regexDecimal = /^-?([0-9]\d*|0)(\.\d+)?$/;
    const messageErrorNumber = "数字を入力してください。";

    // #endregion props
    
    // #region method
    // Submitイベント
    const handleEditSubmit = (value: Ms3WindingModel) => {
        const request = createRequestData(value);
        onOK(request);
    }

    // FormデータからRequestデータを作成する
    const createRequestData = (formValue: Ms3WindingModel) => {
        if(!/^[0-9]*[.]?[0-9]+$/.test(formValue.partPrimary.xR.toString())){
            formValue.partPrimary.xR = 0
        }
        if(!/^[0-9]*[.]?[0-9]+$/.test(formValue.partPrimary.perRk3.toString())){
            formValue.partPrimary.perRk3 = 0
        }
        if(!/^[0-9]*[.]?[0-9]+$/.test(formValue.partPrimary.perXk3.toString())){
            formValue.partPrimary.perXk3 = 0
        }
        //
        if(!/^[0-9]*[.]?[0-9]+$/.test(formValue.partSecondary.xR.toString())){
            formValue.partSecondary.xR = 0
        }
        if(!/^[0-9]*[.]?[0-9]+$/.test(formValue.partSecondary.perRk3.toString())){
            formValue.partSecondary.perRk3 = 0
        }
        if(!/^[0-9]*[.]?[0-9]+$/.test(formValue.partSecondary.perXk3.toString())){
            formValue.partSecondary.perXk3 = 0
        }
        //
        if(!/^[0-9]*[.]?[0-9]+$/.test(formValue.partThird.xR.toString())){
            formValue.partThird.xR = 0
        }
        if(!/^[0-9]*[.]?[0-9]+$/.test(formValue.partThird.perRk3.toString())){
            formValue.partThird.perRk3 = 0
        }
        if(!/^[0-9]*[.]?[0-9]+$/.test(formValue.partThird.perXk3.toString())){
            formValue.partThird.perXk3 = 0
        }
        if(!/^[0-9]*[.]?[0-9]+$/.test(formValue.partSecondary.calcPoint0.loadCapacity.toString())){
            formValue.partSecondary.calcPoint0.loadCapacity = 0
        }
        if(!/^[0-9]*[.]?[0-9]+$/.test(formValue.partSecondary.calcPoint0.loadCurrent.toString())){
            formValue.partSecondary.calcPoint0.loadCurrent = 0
        }
        if(!/^[0-9]*[.]?[0-9]+$/.test(formValue.partSecondary.calcPoint0.powerFactor.toString())){
            formValue.partSecondary.calcPoint0.powerFactor = 0
        }
        if(!/^[0-9]*[.]?[0-9]+$/.test(formValue.partThird.calcPoint0.loadCapacity.toString())){
            formValue.partThird.calcPoint0.loadCapacity = 0
        }
        if(!/^[0-9]*[.]?[0-9]+$/.test(formValue.partThird.calcPoint0.loadCurrent.toString())){
            formValue.partThird.calcPoint0.loadCurrent = 0
        }
        if(!/^[0-9]*[.]?[0-9]+$/.test(formValue.partThird.calcPoint0.powerFactor.toString())){
            formValue.partThird.calcPoint0.powerFactor = 0
        }
        if(!/^[0-9]*[.]?[0-9]+$/.test(formValue.partSecondary.calcPoint1.loadCapacity.toString())){
            formValue.partSecondary.calcPoint1.loadCapacity = 0
        }
        if(!/^[0-9]*[.]?[0-9]+$/.test(formValue.partSecondary.calcPoint1.loadCurrent.toString())){
            formValue.partSecondary.calcPoint1.loadCurrent = 0
        }
        if(!/^[0-9]*[.]?[0-9]+$/.test(formValue.partSecondary.calcPoint1.powerFactor.toString())){
            formValue.partSecondary.calcPoint1.powerFactor = 0
        }
        if(!/^[0-9]*[.]?[0-9]+$/.test(formValue.partThird.calcPoint1.loadCapacity.toString())){
            formValue.partThird.calcPoint1.loadCapacity = 0
        }
        if(!/^[0-9]*[.]?[0-9]+$/.test(formValue.partThird.calcPoint1.loadCurrent.toString())){
            formValue.partThird.calcPoint1.loadCurrent = 0
        }
        if(!/^[0-9]*[.]?[0-9]+$/.test(formValue.partThird.calcPoint1.powerFactor.toString())){
            formValue.partThird.calcPoint1.powerFactor = 0
        }
        let request = {
            ...formValue
        } as Ms3WindingModel;
        return request;
    }

    // useEffect(()=> {
    //     const partPrimary = getValues("partPrimary");
    //     doSetValuesWindingTab1("",partPrimary);
    //     setValue("partPrimary",partPrimary);
    // },[])

    // キャンセル
    const handleCancel = () => {
        onCancel();
    };

    const handleChangeFormat = (e: any) => {
        setValue(e.target.name, e.target.value);
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        if(newValue >= 0){
            if(selectTab == 0){
                if (getValues('refNo') == "" ||getValues('refNo') == null || getValues('refNo') == undefined ) {
                    setError("refNo",{ type: 'custom', message: '必須項目です。入力してください。' });
                }
                else if (isNaN(Number(getValues('partPrimary.voltage'))) || Number(getValues('partPrimary.voltage')) < 1) {
                    clearErrors("refNo");
                    setError("partPrimary.voltage",{ type: 'custom', message: '数字 >= 1 を入力してください。'});
                }else if (isNaN(Number(getValues('partPrimary.capacity'))) || Number(getValues('partPrimary.capacity')) <= 0) {
                    clearErrors("partPrimary.voltage");
                    setError("partPrimary.capacity",{ type: 'custom', message: '数字 > 0 を入力してください。'});
                }else if (isNaN(Number(getValues('partPrimary.perZk3'))) || Number(getValues('partPrimary.perZk3')) <= 0){
                    clearErrors("partPrimary.capacity");
                    setError("partPrimary.perZk3",{ type: 'custom', message: '数字 > 0 を入力してください。'});
                }else if(isNaN(Number(getValues('partPrimary.perRk3'))) || Number(getValues('partPrimary.perRk3')) < 0){
                    clearErrors("partPrimary.perZk3");
                    setError("partPrimary.perRk3",{ type: 'custom', message: '数字 >= 0 を入力してください。'});
                }
                else if(isNaN(Number(getValues('partPrimary.perXk3'))) || Number(getValues('partPrimary.perXk3')) < 0){
                    clearErrors("partPrimary.perRk3");
                    setError("partPrimary.perXk3",{ type: 'custom', message: '数字 >= 0 を入力してください。'});
                }
                else if(isNaN(Number(getValues('partPrimary.xR'))) || Number(getValues('partPrimary.xR')) < 0){
                    setError("partPrimary.xR",{ type: 'custom', message: '数字 >= 0 を入力してください。'});
                }
                else{
                    clearErrors("refNo");
                    clearErrors("partPrimary.voltage");
                    clearErrors("partPrimary.capacity");
                    clearErrors("partPrimary.xR");
                    clearErrors("partPrimary.perZk3");
                    clearErrors("partPrimary.perRk3");
                    clearErrors("partPrimary.perXk3");
                    setSelectTab(newValue);
                }
            }

            if(selectTab == 1){
                if (isNaN(Number(getValues('partSecondary.voltage'))) || Number(getValues('partSecondary.voltage')) < 1) {
                    setError("partSecondary.voltage",{ type: 'custom', message: '数字 >= 1 を入力してください。'});
                }
                else if (isNaN(Number(getValues('partSecondary.capacity'))) || Number(getValues('partSecondary.capacity')) <= 0) {
                    clearErrors("partSecondary.perZk3");
                    setError("partSecondary.capacity",{ type: 'custom', message: '数字 > 0 を入力してください。'});
                }
                else if (isNaN(Number(getValues('partSecondary.perZk3'))) || Number(getValues('partSecondary.perZk3')) <= 0){
                    clearErrors("partSecondary.voltage");
                    setError("partSecondary.perZk3",{ type: 'custom', message: '数字 > 0 を入力してください。'});
                }
                else if(isNaN(Number(getValues('partSecondary.perRk3'))) || Number(getValues('partSecondary.perRk3')) < 0){
                    clearErrors("partSecondary.perZk3");
                    setError("partSecondary.perRk3",{ type: 'custom', message: '数字 >= 0 を入力してください。'});
                }
                else if(isNaN(Number(getValues('partSecondary.perXk3'))) || Number(getValues('partSecondary.perXk3')) < 0){
                    clearErrors("partSecondary.perRk3");
                    setError("partSecondary.perXk3",{ type: 'custom', message: '数字 >= 0 を入力してください。'});
                }
                else if(isNaN(Number(getValues('partSecondary.xR'))) || Number(getValues('partSecondary.xR')) < 0){
                    setError("partSecondary.xR",{ type: 'custom', message: '数字 >= 0 を入力してください。'});
                }
                else{
                    clearErrors("partSecondary.voltage");
                    clearErrors("partSecondary.capacity");
                    clearErrors("partSecondary.xR");
                    clearErrors("partSecondary.perZk3");
                    clearErrors("partSecondary.perRk3");
                    clearErrors("partSecondary.perXk3");
                    setSelectTab(newValue);                    
                }
            }

            if(selectTab == 2){
                if (isNaN(Number(getValues('partThird.voltage'))) || Number(getValues('partThird.voltage')) < 1) {
                    setError("partThird.voltage",{ type: 'custom', message: '数字 >= 1 を入力してください。'});
                }
                else if (isNaN(Number(getValues('partThird.capacity'))) || Number(getValues('partThird.capacity')) <= 0) {
                    clearErrors("partThird.perZk3");
                    setError("partThird.capacity",{ type: 'custom', message: '数字 > 0 を入力してください。'});
                }
                else if (isNaN(Number(getValues('partThird.perZk3'))) || Number(getValues('partThird.perZk3')) <= 0){
                    clearErrors("partThird.voltage");
                    setError("partThird.perZk3",{ type: 'custom', message: '数字 > 0 を入力してください。'});
                }else if(isNaN(Number(getValues('partThird.perRk3'))) || Number(getValues('partThird.perRk3')) < 0){
                    clearErrors("partThird.perZk3");
                    setError("partThird.perRk3",{ type: 'custom', message: '数字 >= 0 を入力してください。'});
                }
                else if(isNaN(Number(getValues('partThird.perXk3'))) || Number(getValues('partThird.perXk3')) < 0){
                    clearErrors("partThird.perRk3");
                    setError("partThird.perXk3",{ type: 'custom', message: '数字 >= 0 を入力してください。'});
                }
                else if(isNaN(Number(getValues('partThird.xR'))) || Number(getValues('partThird.xR')) < 0){
                    setError("partThird.xR",{ type: 'custom', message: '数字 >= 0 を入力してください。'});
                }
                else{
                    clearErrors("partThird.voltage");
                    clearErrors("partThird.capacity");
                    clearErrors("partThird.xR");
                    clearErrors("partThird.perZk3");
                    clearErrors("partThird.perRk3");
                    clearErrors("partThird.perXk3");
                    setSelectTab(newValue);                    
                }
            }

            if(selectTab == 3){
                if (isNaN(Number(getValues('partSecondary.inrushTimes'))) || Number(getValues('partSecondary.inrushTimes')) <= 0) {
                    setError("partSecondary.inrushTimes",{ type: 'custom', message: '数字 > 0 を入力してください。'});
                }else if(isNaN(Number(getValues('partSecondary.dampingTime'))) || Number(getValues('partSecondary.dampingTime')) <= 0) {
                    clearErrors("partSecondary.inrushTimes");
                    setError("partSecondary.dampingTime",{ type: 'custom', message: '数字 > 0 を入力してください。'});                    
                }else if(isNaN(Number(getValues('partSecondary.intensityTimes'))) || Number(getValues('partSecondary.intensityTimes')) <= 0){
                    clearErrors("partSecondary.dampingTime");
                    setError("partSecondary.intensityTimes",{ type: 'custom', message: '数字 > 0 を入力してください。'});
                }
                else if(isNaN(Number(getValues('partSecondary.intensityTime'))) || Number(getValues('partSecondary.intensityTime')) <= 0){
                    clearErrors("partSecondary.intensityTimes");
                    setError("partSecondary.intensityTime",{ type: 'custom', message: '数字 > 0 を入力してください。'});
                }                
                else{
                    clearErrors("partSecondary.inrushTimes");
                    clearErrors("partSecondary.dampingTime");
                    clearErrors("partSecondary.intensityTimes");
                    clearErrors("partSecondary.intensityTime");
                    setSelectTab(newValue);                    
                }
            }

            if(selectTab == 4){
                if (isNaN(Number(getValues('partThird.inrushTimes'))) || Number(getValues('partThird.inrushTimes')) <= 0) {
                    setError("partThird.inrushTimes",{ type: 'custom', message: '数字 > 0 を入力してください。'});
                }else if(isNaN(Number(getValues('partThird.dampingTime'))) || Number(getValues('partThird.dampingTime')) <= 0) {
                    clearErrors("partThird.inrushTimes");
                    setError("partThird.dampingTime",{ type: 'custom', message: '数字 > 0 を入力してください。'});                    
                }else if(isNaN(Number(getValues('partThird.intensityTimes'))) || Number(getValues('partThird.intensityTimes')) <= 0){
                    clearErrors("partThird.dampingTime");
                    setError("partThird.intensityTimes",{ type: 'custom', message: '数字 > 0 を入力してください。'});
                }
                else if(isNaN(Number(getValues('partThird.intensityTime'))) || Number(getValues('partThird.intensityTime')) <= 0){
                    clearErrors("partThird.intensityTimes");
                    setError("partThird.intensityTime",{ type: 'custom', message: '数字 > 0 を入力してください。'});
                }                
                else{
                    clearErrors("partThird.inrushTimes");
                    clearErrors("partThird.dampingTime");
                    clearErrors("partThird.intensityTimes");
                    clearErrors("partThird.intensityTime");
                    setSelectTab(newValue);
                }
            }

            if(selectTab == 5){
                if (isNaN(Number(getValues('loadCapacity'))) || Number(getValues('loadCapacity')) < 0) {
                    setError("loadCapacity",{ type: 'custom', message: '数字 >= 0 を入力してください。'});
                }else if(isNaN(Number(getValues('loadCurrent'))) || Number(getValues('loadCurrent')) < 0) {
                    clearErrors("loadCapacity");
                    setError("loadCurrent",{ type: 'custom', message: '数字 >= 0 を入力してください。'});                    
                }else if(isNaN(Number(getValues('powerFactor'))) || Number(getValues('powerFactor')) < 0 || Number(getValues('powerFactor')) > 1) {
                    clearErrors("loadCurrent");
                    setError("powerFactor",{ type: 'custom', message: ' 0 =< 数字 <= 1  を入力してください。'});                    
                }                
                else{
                    clearErrors("loadCapacity");
                    clearErrors("loadCurrent");
                    clearErrors("powerFactor");
                    setSelectTab(newValue);
                }
            }

            if(selectTab == 6){
                if (isNaN(Number(getValues('loadCapacityThird'))) || Number(getValues('loadCapacityThird')) < 0) {
                    setError("loadCapacityThird",{ type: 'custom', message: '数字 >= 0 を入力してください。'});
                }else if(isNaN(Number(getValues('loadCurrentThird'))) || Number(getValues('loadCurrentThird')) < 0) {
                    clearErrors("loadCapacityThird");
                    setError("loadCurrentThird",{ type: 'custom', message: '数字 >= 0 を入力してください。'});                    
                }else if(isNaN(Number(getValues('powerFactorThird'))) || Number(getValues('powerFactorThird')) < 0 || Number(getValues('powerFactorThird')) > 1) {
                    clearErrors("loadCurrentThird");
                    setError("powerFactorThird",{ type: 'custom', message: '0 =< 数字 <= 1  を入力してください。'});                    
                }
                else{
                    clearErrors("loadCapacityThird");
                    clearErrors("loadCurrentThird");
                    clearErrors("powerFactorThird");
                    setSelectTab(newValue);
                }
            }

            if(selectTab == 7){
                setSelectTab(newValue);
            }

            switch(newValue){
                case 0:
                    const partPrimary = getValues("partPrimary");
                    // doSetValuesWindingTab1("",partPrimary);
                    setValue("partPrimary",partPrimary);
                    break;
                case 1:
                    const partSecondary = getValues("partSecondary");
                    // doSetValuesWindingTab1("",partSecondary);
                    setValue("partSecondary",partSecondary);
                    break;
                case 2:
                    const partThird = getValues("partThird");
                    // doSetValuesWindingTab1("",partThird);
                    setValue("partThird",partThird);
                    break;
                case 3:
                    doSetValueTab3("");
                    break;
                case 4:
                    doSetValueTab4("");
                    break;
                case 5:
                    doSetValueTab5("");
                    break;
                case 6:
                    doSetValueTab6("");
                    break;
            }
        }else{
            setSelectTab(newValue);
        }        
    };

    const handleEditVoltage = (e:any,idControl:any) => {
        if(e.target.value >= 0 && regexDecimal.test(e.target.value)){
            clearErrors("partSecondary.voltage");
            const partPrimary = getValues("partPrimary");
            const partSecondary = getValues("partSecondary");
            const partThird = getValues("partThird");
            setIsEnableOkButton(true);
            if(idControl == VOLT_SIDE_PRIMARY){
                doSetVoltage3Winding(VOLT_SIDE_PRIMARY,Number(e.target.value),partPrimary,partSecondary,partThird);
                doSetValuesWindingTab1("IDC_VOLTAGE",partPrimary);
                setValue("partPrimary",partPrimary);
            }else if(idControl == VOLT_SIDE_SECONDARY){
                doSetVoltage3Winding(VOLT_SIDE_SECONDARY,Number(e.target.value),partPrimary,partSecondary,partThird);
                doSetValuesWindingTab1("IDC_VOLTAGE",partSecondary);
                setValue("partSecondary",partSecondary);
            }else if(idControl == VOLT_SIDE_THIRD){
                doSetVoltage3Winding(VOLT_SIDE_THIRD,Number(e.target.value),partPrimary,partSecondary,partThird);
                doSetValuesWindingTab1("IDC_VOLTAGE",partThird);
                setValue("partThird",partThird);
            }
        }
    }

    const handleSelectVoltage = (e:any,idControl:any) =>{
        const partPrimary = getValues("partPrimary");
        const partSecondary = getValues("partSecondary");
        const partThird = getValues("partThird");

        if(idControl === VOLT_SIDE_PRIMARY){
            doSetVoltage3Winding(VOLT_SIDE_PRIMARY,Number(e.target.value),partPrimary,partSecondary,partThird);
            doSetValuesWindingTab1("IDC_VOLTAGE",partPrimary);
            setValue("partPrimary",partPrimary);
        }else if(idControl === VOLT_SIDE_SECONDARY){
            doSetVoltage3Winding(VOLT_SIDE_SECONDARY,Number(e.target.value),partPrimary,partSecondary,partThird);
            doSetValuesWindingTab1("IDC_VOLTAGE",partSecondary);
            setValue("partSecondary",partSecondary);
        }else if(idControl === VOLT_SIDE_THIRD){
            doSetVoltage3Winding(VOLT_SIDE_THIRD,Number(e.target.value),partPrimary,partSecondary,partThird);
            doSetValuesWindingTab1("IDC_VOLTAGE",partThird);
            setValue("partThird",partThird);
        }
    }

    const handleEditCapacity = (e:any,idControl:any) => {
        if(e.target.value >= 0 && regexDecimal.test(e.target.value)){
            if(idControl === VOLT_SIDE_PRIMARY){
                const partPrimary = getValues("partPrimary");
                doCalcSetValue(partPrimary,Number(e.target.value),infoCircuit.frequency,data.trans3List,e.target.name);
                BeamsValue2(partPrimary);
                setValue("partPrimary",partPrimary);
            }else if(idControl === VOLT_SIDE_SECONDARY){
                const partSecondary = getValues("partSecondary");
                doCalcSetValue(partSecondary,Number(e.target.value),infoCircuit.frequency,data.trans3List,e.target.name);
                BeamsValue2(partSecondary);
                setValue("partSecondary",partSecondary);
            } else if(idControl === VOLT_SIDE_THIRD){
                const partThird = getValues("partThird");
                doCalcSetValue(partThird,Number(e.target.value),infoCircuit.frequency,data.trans3List,e.target.name);
                BeamsValue2(partThird);
                setValue("partThird",partThird);
            }
            clearErrors3Winding()
        }
    }

    const handleSelectCapacity = (e:any,idControl:any) => {
        if(idControl === VOLT_SIDE_PRIMARY){
            const partPrimary = getValues("partPrimary");
            doCalcSetValue(partPrimary,Number(e.target.value),infoCircuit.frequency,data.trans3List,e.target.name);
            BeamsValue2(partPrimary);
            setValue("partPrimary",partPrimary);
        } else if(idControl === VOLT_SIDE_SECONDARY){
            const partSecondary = getValues("partSecondary");
            doCalcSetValue(partSecondary,Number(e.target.value),infoCircuit.frequency,data.trans3List,e.target.name);
            BeamsValue2(partSecondary);
            setValue("partSecondary",partSecondary);
        } else if(idControl === VOLT_SIDE_THIRD){
            const partThird = getValues("partThird");
            doCalcSetValue(partThird,Number(e.target.value),infoCircuit.frequency,data.trans3List,e.target.name);
            BeamsValue2(partThird);
            setValue("partThird",partThird);
        }
    }

    const handleChangeXr = (e:any,idControl:any) => {
        if(e.target.value >= 0 && regexDecimal.test(e.target.value)){
            if(idControl === VOLT_SIDE_PRIMARY){
                const partPrimary = getValues("partPrimary");
                setValue(e.target.name,parseFloat(e.target.value));
                doCalcSetValue(partPrimary,partPrimary.capacity,infoCircuit.frequency,data.trans3List,e.target.name);
                BeamsValue2(partPrimary);
                setValue("partPrimary",partPrimary);
            }else if(idControl === VOLT_SIDE_SECONDARY){
                const partSecondary = getValues("partSecondary");
                doCalcSetValue(partSecondary,partSecondary.capacity,infoCircuit.frequency,data.trans3List,e.target.name);
                BeamsValue2(partSecondary);
                setValue("partSecondary",partSecondary);
            }else if(idControl === VOLT_SIDE_THIRD){
                const partThird = getValues("partThird");
                doCalcSetValue(partThird,partThird.capacity,infoCircuit.frequency,data.trans3List,e.target.name);
                BeamsValue2(partThird);
                setValue("partThird",partThird);
            }
            clearErrors3Winding()
        }
    }

    const handleChangePerZk3 = (e:any,idControl:any) => {
        if(e.target.value >= 0 && regexDecimal.test(e.target.value)){
            if(idControl === VOLT_SIDE_PRIMARY){
                const partPrimary = getValues("partPrimary");
                doCalcSetValue(partPrimary,partPrimary.capacity,infoCircuit.frequency,data.trans3List,e.target.name);
                BeamsValue2(partPrimary);
                setValue("partPrimary",partPrimary);
            }else if(idControl === VOLT_SIDE_SECONDARY){
                const partSecondary = getValues("partSecondary");
                doCalcSetValue(partSecondary,partSecondary.capacity,infoCircuit.frequency,data.trans3List,e.target.name);
                BeamsValue2(partSecondary);
                setValue("partSecondary",partSecondary);
            }else if(idControl === VOLT_SIDE_THIRD){
                const partThird = getValues("partThird");
                doCalcSetValue(partThird,partThird.capacity,infoCircuit.frequency,data.trans3List,e.target.name);
                BeamsValue2(partThird);
                setValue("partThird",partThird);
            }
            clearErrors3Winding()
        }
    }

    const handleChangePerRk3 = (e:any,idControl:any) =>{
        const reCalcValue = (partWinding:Iele3WindingPart,namePart:any) => {
            if(partWinding.perXk3 == 0){
                partWinding.xR = 0;
                partWinding.perZk3 = partWinding.perRk3;
            }
            if(partWinding.perRk3 >0){
                partWinding.xR = partWinding.perXk3  / partWinding.perRk3;
                partWinding.perZk3 = Math.sqrt(partWinding.perXk3 * partWinding.perXk3 + partWinding.perRk3 * partWinding.perRk3);
            }else{
                partWinding.xR = 99999
                partWinding.perZk3 = partWinding.perXk3;
            }
            BeamsValue2(partWinding);
            setValue(namePart,partWinding);
        }
        if(e.target.value >= 0 && regexDecimal.test(e.target.value)) {
            if(idControl === VOLT_SIDE_PRIMARY){
                const partPrimary = getValues("partPrimary");
                reCalcValue(partPrimary,"partPrimary");
            }else if(idControl === VOLT_SIDE_SECONDARY){
                const partSecondary = getValues("partSecondary");
                reCalcValue(partSecondary,"partSecondary");
            }else if(idControl === VOLT_SIDE_THIRD){
                const partThird = getValues("partThird");
                reCalcValue(partThird,"partThird");
            }
            clearErrors3Winding()
        }
    }
    
    const handleChangePerXk3 = (e:any,idControl:any) => {
        const reCalcValue = (partWinding:Iele3WindingPart,namePart:any) => {
            if(partWinding.perRk3 == 0){
                partWinding.xR = 99999;
                partWinding.perZk3 = partWinding.perXk3;
            }
            else if(partWinding.perXk3 >0){
                partWinding.xR = partWinding.perXk3  / partWinding.perRk3;
                partWinding.perZk3 = Math.sqrt(partWinding.perXk3 * partWinding.perXk3 + partWinding.perRk3 * partWinding.perRk3);
            }else{
                partWinding.xR = 0
                partWinding.perZk3 = partWinding.perRk3;
            }
            BeamsValue2(partWinding);
            setValue(namePart,partWinding);
        }
        if(e.target.value >= 0 && regexDecimal.test(e.target.value)) {
            if(idControl === VOLT_SIDE_PRIMARY){
                const partPrimary = getValues("partPrimary");
                reCalcValue(partPrimary,"partPrimary");
            }else if(idControl === VOLT_SIDE_SECONDARY){
                const partSecondary = getValues("partSecondary");
                reCalcValue(partSecondary,"partSecondary");
            }else if(idControl === VOLT_SIDE_THIRD){
                const partThird = getValues("partThird");
                reCalcValue(partThird,"partThird");
            }
            clearErrors3Winding()
        }
    }

    const handleChangeInRushTimes = (e:any,idControl:any) => {
        setValue(e.target.name,e.target.value)
        if(e.target.value > 0){
            if(idControl === VOLT_SIDE_SECONDARY){
                // setValue("partSecondary.inrushTimes",parseFloat(e.target.value));
                doSetValueTab3(e.target.name);
            }
            else if(idControl === VOLT_SIDE_THIRD){
                // setValue("partThird.inrushTimes",parseFloat(e.target.value));
                doSetValueTab4(e.target.name);
            }
        }
    }

    const handleChangeDampingTime = (e:any,idControl:any) => {
        setValue(e.target.name,e.target.value)

        // if(e.target.value > 0){
        //     if(idControl === VOLT_SIDE_SECONDARY){
        //         setValue("partSecondary.dampingTime",parseFloat(e.target.value));
        //     }else if(idControl === VOLT_SIDE_THIRD){
        //         setValue("partThird.dampingTime",parseFloat(e.target.value));
        //     }
        // }
    }

    const handleChangeIntensityTimes = (e:any,idControl:any) => {
        setValue(e.target.name,e.target.value)
        if(e.target.value > 0){
            if(idControl === VOLT_SIDE_SECONDARY){
                // setValue("partSecondary.intensityTimes",parseFloat(e.target.value));
                doSetValueTab3(e.target.name);
            }else if(idControl === VOLT_SIDE_THIRD){
                // setValue("partThird.intensityTimes",parseFloat(e.target.value));
                doSetValueTab4(e.target.name);
            }
        }
    }

    const handleChangeDispInRush = (e:any,idControl:any) => {
        if(idControl === VOLT_SIDE_SECONDARY){
            const partSecondary = getValues("partSecondary");
            partSecondary.dispInrush = !e.target.value;
        }else if(idControl === VOLT_SIDE_THIRD){
            const partThird = getValues("partThird");
            partThird.dispInrush = !e.target.value;
        }
    }

    const handleChangeDispIntensity = (e:any,idControl:any) => {
        if(idControl === VOLT_SIDE_SECONDARY){
            const partSecondary = getValues("partSecondary");
            partSecondary.dispIntensity = e.target.checked;
            setValue("partSecondary",partSecondary)
        }else if(idControl === VOLT_SIDE_THIRD){
            const partThird = getValues("partThird");
            partThird.dispIntensity = e.target.checked;
            setValue("partThird",partThird);
        }
    }

    const handleChangeIntensityTime = (e:any,idControl:any) => {
        if(idControl === VOLT_SIDE_SECONDARY){
            const partSecondary = getValues("partSecondary");
            partSecondary.intensityTime = e.target.value;
        }else if(idControl === VOLT_SIDE_THIRD){
            const partThird = getValues("partThird");
            partThird.intensityTime = e.target.value;
        }
    }

    const handleChangeDropRegular = (e:any) => {
        setValue("partSecondary.dropRegular",e.target.checked);
        if(e.target.checked){
            setDisabledTab5Flag(false);
        }else if(!e.target.checked){
            setDisabledTab5Flag(true);
        }
    }

    const handleChangeLoadCapacity = (event:any,partKind:any) => {
        if(partKind === VOLT_SIDE_SECONDARY){
            const partSecondary = getValues("partSecondary");
            setValue("partSecondary.inputedCurrent1", false)
            setValue("partSecondary.inputedCapacity1",true)
            if(event.target.value >= 0){
                partSecondary.calcPoint0.loadCapacity = +event.target.value;
                partSecondary.calcPoint1.loadCapacity = +event.target.value;
                const loadCurrent = doSetValueDropLoadCurrent(partSecondary.voltage,partSecondary);
                partSecondary.calcPoint0.loadCurrent = loadCurrent;
                partSecondary.calcPoint1.loadCurrent = loadCurrent;
                setValue("loadCurrent",BeamsNumber(Number(loadCurrent),7));
                setValue("partSecondary",partSecondary);
            }else{
            }
            const Ms3WindingModel = getValues()
            doSetValueVoltDrop(infoCircuit.stdCapacity,MS_MODE_DRAWING,VOLT_SIDE_SECONDARY,Ms3WindingModel)
        }else if(partKind === VOLT_SIDE_THIRD){
            const partThird = getValues("partThird");
            setValue("partThird.inputedCurrent1", false)
            setValue("partThird.inputedCapacity1",true)
            if(event.target.value >= 0){
                partThird.calcPoint0.loadCapacity = +event.target.value;
                partThird.calcPoint1.loadCapacity = +event.target.value;
                const loadCurrent = doSetValueDropLoadCurrent(partThird.voltage,partThird);
                partThird.calcPoint0.loadCurrent = loadCurrent;
                partThird.calcPoint1.loadCurrent = loadCurrent;
                setValue("loadCurrentThird",BeamsNumber(Number(loadCurrent),7));
                setValue("partThird",partThird);
            }else{
            }
            const Ms3WindingModel = getValues();
            doSetValueVoltDrop(infoCircuit.stdCapacity,MS_MODE_DRAWING,VOLT_SIDE_THIRD,Ms3WindingModel)
        }
        clearErrors3Winding()

    }

    const handleChangePowerFactor = (event:any,ID_CONTROL:any) => {
        if(ID_CONTROL === VOLT_SIDE_SECONDARY){
            const partSecondary = getValues("partSecondary");
            setValue("partSecondary.inputedPowerFactor1",true);
            partSecondary.calcPoint0.powerFactor = +event.target.value;
            partSecondary.calcPoint1.powerFactor = +event.target.value;
            if(IsOkPowerFactor(partSecondary.calcPoint0)){
            }else{
            }
            const Ms3WindingModel = getValues()
            doSetValueVoltDrop(infoCircuit.stdCapacity,MS_MODE_DRAWING,VOLT_SIDE_SECONDARY,Ms3WindingModel)
        }else if(ID_CONTROL === VOLT_SIDE_THIRD){
            const partThird = getValues("partThird");
            setValue("partThird.inputedPowerFactor1",true)
            partThird.calcPoint0.powerFactor = +event.target.value;
            partThird.calcPoint1.powerFactor = +event.target.value;
            if(IsOkPowerFactor(partThird.calcPoint0)){
            }else{
            }
            const Ms3WindingModel = getValues()
            doSetValueVoltDrop(infoCircuit.stdCapacity,MS_MODE_DRAWING,VOLT_SIDE_THIRD,Ms3WindingModel)
        }
        clearErrors3Winding()
    }

    const handleChangeLoadCurrent = (event:any,ID_CONTROL:any) => {
        setValue(event.target.name,event.target.value)
        if(ID_CONTROL === VOLT_SIDE_SECONDARY){
            const partSecondary = getValues("partSecondary");
            setValue("partSecondary.inputedCurrent1", true)
            setValue("partSecondary.inputedCapacity1",false)
            if(event.target.value >= 0){
                partSecondary.calcPoint0.loadCurrent = event.target.value;
                partSecondary.calcPoint1.loadCurrent = event.target.value;
                const loadCapacity = doGetValueDropLoadCapacity(partSecondary.voltage,partSecondary);
                partSecondary.calcPoint0.loadCapacity = loadCapacity;
                partSecondary.calcPoint1.loadCapacity = loadCapacity;
                setValue("loadCapacity",BeamsNumber(Number(loadCapacity),7))
                setValue("partSecondary",partSecondary);
            }
            const Ms3WindingModel = getValues()
            doSetValueVoltDrop(infoCircuit.stdCapacity,MS_MODE_DRAWING,VOLT_SIDE_SECONDARY,Ms3WindingModel)
        }
        else if(ID_CONTROL === VOLT_SIDE_THIRD){
            const partThird = getValues("partThird");
            setValue("partThird.inputedCurrent1", true)
            setValue("partThird.inputedCapacity1",false)
            if(event.target.value >= 0){
                partThird.calcPoint0.loadCurrent = event.target.value;
                partThird.calcPoint1.loadCurrent = event.target.value;
                const loadCapacity = doGetValueDropLoadCapacity(partThird.voltage,partThird);
                partThird.calcPoint0.loadCapacity = loadCapacity;
                partThird.calcPoint1.loadCapacity = loadCapacity;
                setValue("loadCapacityThird",BeamsNumber(Number(loadCapacity),7))
                setValue("partThird",partThird);
            }
            const Ms3WindingModel = getValues()
            doSetValueVoltDrop(infoCircuit.stdCapacity,MS_MODE_DRAWING,VOLT_SIDE_THIRD,Ms3WindingModel)
        }        
        clearErrors3Winding()
    }

    const handleClickGetLoadCurrent = (ID_CONTROL:any) => {
        if(ID_CONTROL === VOLT_SIDE_SECONDARY){
            const partSecondary = getValues("partSecondary");
            partSecondary.calcPoint0.loadCurrent = dispInputDialog.load_current_0;
            partSecondary.calcPoint1.loadCurrent = dispInputDialog.load_current_0;
            setValue("loadCurrent",BeamsNumber(partSecondary.calcPoint0.loadCurrent,5));
            const loadCapacity = doGetValueDropLoadCapacity(partSecondary.voltage,partSecondary);
            setValue("loadCapacity",BeamsNumber(Number(loadCapacity),7));
            const Ms3WindingModel = getValues();
            doSetValueVoltDrop(infoCircuit.stdCapacity,MS_MODE_DRAWING,VOLT_SIDE_SECONDARY,Ms3WindingModel);
            partSecondary.calcPoint0.loadCapacity = loadCapacity;
            partSecondary.calcPoint1.loadCapacity = loadCapacity;
            setValue("partSecondary",partSecondary);
        }else if(ID_CONTROL === VOLT_SIDE_THIRD){
            const partThird = getValues("partThird");
            partThird.calcPoint0.loadCurrent = dispInputDialog.load_current_1;
            partThird.calcPoint1.loadCurrent = dispInputDialog.load_current_1;
            setValue("loadCurrentThird",BeamsNumber(partThird.calcPoint0.loadCurrent,5));
            const loadCapacity = doGetValueDropLoadCapacity(partThird.voltage,partThird);
            setValue("loadCapacityThird",BeamsNumber(Number(loadCapacity),7));
            const Ms3WindingModel = getValues();
            doSetValueVoltDrop(infoCircuit.stdCapacity,MS_MODE_DRAWING,VOLT_SIDE_THIRD,Ms3WindingModel);
            partThird.calcPoint0.loadCapacity = loadCapacity;
            partThird.calcPoint1.loadCapacity = loadCapacity;
            setValue("partThird",partThird);
        }
        clearErrors3Winding()

    }

    const handleClickGetLoadPowerFactor = (ID_CONTROL:any) => {
        if(ID_CONTROL === VOLT_SIDE_SECONDARY){
            const partSecondary = getValues("partSecondary");
            partSecondary.calcPoint0.powerFactor = dispInputDialog.power_factor_0;
            partSecondary.calcPoint1.powerFactor = dispInputDialog.power_factor_0;
            partSecondary.calcPoint0.isLead = dispInputDialog.is_lead_0;
            partSecondary.calcPoint1.isLead = dispInputDialog.is_lead_0;
            setValue("isLead",partSecondary.calcPoint0.isLead);
            setValue("powerFactor",doGetTextVoltDropPF(partSecondary.calcPoint0));
            const Ms3WindingModel = getValues()
            doSetValueVoltDrop(infoCircuit.stdCapacity,MS_MODE_DRAWING,VOLT_SIDE_SECONDARY,Ms3WindingModel);
        }else if(ID_CONTROL === VOLT_SIDE_THIRD){
            const partThird = getValues("partThird");
            partThird.calcPoint0.powerFactor = dispInputDialog.power_factor_1;
            partThird.calcPoint1.powerFactor = dispInputDialog.power_factor_1;
            partThird.calcPoint0.isLead = dispInputDialog.is_lead_1;
            partThird.calcPoint1.isLead = dispInputDialog.is_lead_1;
            setValue("isLeadThird",partThird.calcPoint0.isLead);
            setValue("powerFactorThird",doGetTextVoltDropPF(partThird.calcPoint0));
            const Ms3WindingModel = getValues()
            doSetValueVoltDrop(infoCircuit.stdCapacity,MS_MODE_DRAWING,VOLT_SIDE_THIRD,Ms3WindingModel);
        }
        clearErrors3Winding()
    }

    const doSetValueVoltDrop = (capacityCircuit:number,MS_MODE:number,ID_CONTROL:number,Ms3WindingModel:Ms3WindingModel) => {
        if(ID_CONTROL === VOLT_SIDE_SECONDARY){
            const voltDropValues = doGetValueVoltDrop(capacityCircuit,MS_MODE,VOLT_SIDE_SECONDARY,Ms3WindingModel)
            setValue("voltDropDiff",voltDropValues.voltDropDiff);
            setValue("voltDropPerUnit",BeamsNumber(Number(voltDropValues.voltDropPerUnit),4));
        }else if(ID_CONTROL === VOLT_SIDE_THIRD){
            const voltDropValues = doGetValueVoltDrop(capacityCircuit,MS_MODE,VOLT_SIDE_THIRD,Ms3WindingModel)
            setValue("voltDropDiffThird",voltDropValues.voltDropDiff);
            setValue("voltDropPerUnitThird",BeamsNumber(Number(voltDropValues.voltDropPerUnit),4));
        }
    }

    const handlePointRegularThird = (event:any) => {
        setValue("partThird.dropRegular",event.target.checked);
        if(event.target.checked){
            setDisabledTab6Flag(false);
        }else if(!event.target.checked){
            setDisabledTab6Flag(true);
        }
    }

    // 進み位相のクリックによる変更
    const handleClickIsLead = (event:any,ID_CONTROL:number) => {
        if(ID_CONTROL === VOLT_SIDE_SECONDARY){
            const partSecondary = getValues("partSecondary");
            setValue("partSecondary.inputedPowerFactor1", true)
            partSecondary.calcPoint0.isLead = true;
            partSecondary.calcPoint1.isLead = true;
            const Ms3WindingModel = getValues();
            doSetValueVoltDrop(infoCircuit.stdCapacity,MS_MODE_DRAWING,VOLT_SIDE_SECONDARY,Ms3WindingModel);
        }else if(ID_CONTROL === VOLT_SIDE_THIRD){
            const partThird = getValues("partThird");
            setValue("partThird.inputedPowerFactor1", true)
            partThird.calcPoint0.isLead = true;
            partThird.calcPoint1.isLead = true;
            const Ms3WindingModel = getValues();
            doSetValueVoltDrop(infoCircuit.stdCapacity,MS_MODE_DRAWING,VOLT_SIDE_THIRD,Ms3WindingModel)
        }
    }

    // 進み位相のクリックによる変更
    const handleClickIsLag = (event:any,ID_CONTROL:number) => {
        if(ID_CONTROL === VOLT_SIDE_SECONDARY){
            const partSecondary = getValues("partSecondary");
            setValue("partSecondary.inputedPowerFactor1", true)
            partSecondary.calcPoint0.isLead = false;
            partSecondary.calcPoint1.isLead = false;
            const Ms3WindingModel = getValues();
            doSetValueVoltDrop(infoCircuit.stdCapacity,MS_MODE_DRAWING,VOLT_SIDE_SECONDARY,Ms3WindingModel);
        }else if(ID_CONTROL === VOLT_SIDE_THIRD){
            const partThird = getValues("partThird");
            setValue("partThird.inputedPowerFactor1", true)
            partThird.calcPoint0.isLead = false;
            partThird.calcPoint1.isLead = false;
            const Ms3WindingModel = getValues();
            doSetValueVoltDrop(infoCircuit.stdCapacity,MS_MODE_DRAWING,VOLT_SIDE_THIRD,Ms3WindingModel);
        }
    }

    const doSetValueTab3 = (name:any) => {
        const partSecondary = getValues("partSecondary");
        if(name != "partSecondary.inrushTimes"){
            setValue("partSecondary.inrushTimes",doGetTextInRushTimes(partSecondary));
        }
        setValue("inrushValueSecond",doGetTextInRushValue(partSecondary));
        setValue("partSecondary.dampingTime",doGetTextDampingTime(partSecondary));
        if(name != "partSecondary.intensityTimes"){
            setValue("partSecondary.intensityTimes",doGetTextIntensityTimes(partSecondary));
        }
        setValue("intensityValueSecond",doGetTextIntensityValue(partSecondary));
        setValue("partSecondary.intensityTime",doGetTextIntensityTime(partSecondary));
    }

    const doSetValueTab4 = (name:any) => {
        const partThird = getValues("partThird");
        if(name != "partThird.inrushTimes"){
            setValue("partThird.inrushTimes",doGetTextInRushTimes(partThird));
        }
        setValue("inrushValueThird",doGetTextInRushValue(partThird));
        setValue("partThird.dampingTime",doGetTextDampingTime(partThird));
        if(name != "partThird.intensityTimes"){
            setValue("partThird.intensityTimes",doGetTextIntensityTimes(partThird));
        }
        setValue("intensityValueThird",doGetTextIntensityValue(partThird));
        setValue("partThird.intensityTime",doGetTextIntensityTime(partThird));
    }

    const doSetValueTab5 = (name:any) => {
        const capacityCircuit = infoCircuit.stdCapacity;
        const partSecondary = getValues("partSecondary");
        setValue("dropRegular",partSecondary.dropRegular);
        if(partSecondary.calcPoint0.isLead){
            setValue("isLead",true);
        }else{
            setValue("isLead",false);
        }
        setValue("powerFactor",doGetTextVoltDropPF(partSecondary.calcPoint0))
        setValue("loadCurrent",BeamsNumber(partSecondary.calcPoint0.loadCurrent,5))
        const Ms3WindingModel = getValues();
        const voltDropValues = doGetValueVoltDrop(capacityCircuit,MS_MODE_DRAWING,VOLT_SIDE_SECONDARY,Ms3WindingModel)
        setValue("voltDropDiff",voltDropValues.voltDropDiff);
        setValue("voltDropPerUnit",BeamsNumber(Number(voltDropValues.voltDropPerUnit),4));
        setValue("loadCapacity",BeamsNumber(partSecondary.calcPoint0.loadCapacity,7));
    }

    const doSetValueTab6 = (name:any) => {
        const capacityCircuit = infoCircuit.stdCapacity;
        const partThird = getValues("partThird");
        setValue("dropRegularThird",partThird.dropRegular);
        if(partThird.calcPoint0.isLead){
            setValue("isLeadThird",true);
        }else{
            setValue("isLeadThird",false);
        }
        setValue("powerFactorThird",doGetTextVoltDropPF(partThird.calcPoint0));
        setValue("loadCurrentThird",BeamsNumber(partThird.calcPoint0.loadCurrent,5));
        const Ms3WindingModel = getValues();
        const voltDropValues = doGetValueVoltDrop(capacityCircuit,MS_MODE_DRAWING,VOLT_SIDE_THIRD,Ms3WindingModel);
        setValue("voltDropDiffThird",voltDropValues.voltDropDiff);
        setValue("voltDropPerUnitThird",BeamsNumber(Number(voltDropValues.voltDropPerUnit),4));
        setValue("loadCapacityThird",BeamsNumber(partThird.calcPoint0.loadCapacity,7));
    }
    const clearErrors3Winding =() =>{
        if(getValues("partPrimary.xR") >= 0){
            clearErrors("partPrimary.xR")
        }
        if(getValues("partPrimary.perZk3") > 0){
            clearErrors("partPrimary.perZk3")
        }
        if(getValues("partPrimary.perXk3") >= 0){
            clearErrors("partPrimary.perXk3")
        }
        if(getValues("partPrimary.perRk3") >= 0){
            clearErrors("partPrimary.perRk3")
        }
        //partSecond
        if(getValues("partSecondary.xR") >= 0){
            clearErrors("partSecondary.xR")
        }
        if(getValues("partSecondary.perZk3") > 0){
            clearErrors("partSecondary.perZk3")
        }
        if(getValues("partSecondary.perXk3") >= 0){
            clearErrors("partSecondary.perXk3")
        }
        if(getValues("partSecondary.perRk3") >= 0){
            clearErrors("partSecondary.perRk3")
        }
        //
        if(getValues("partThird.xR") >= 0){
            clearErrors("partThird.xR")
        }
        if(getValues("partThird.perZk3") > 0){
            clearErrors("partThird.perZk3")
        }
        if(getValues("partThird.perXk3") >= 0){
            clearErrors("partThird.perXk3")
        }
        if(getValues("partThird.perRk3") >= 0){
            clearErrors("partThird.perRk3")
        }

        ///
        if(getValues("loadCapacity") >= 0){
            clearErrors("loadCapacity")
        }
        if(getValues("loadCurrent") >= 0){
            clearErrors("loadCurrent")
        }
        if(getValues("powerFactor") >= 0){
            clearErrors("powerFactor")
        }       
        //
        if(getValues("loadCapacityThird") >= 0){
            clearErrors("loadCapacityThird")
        }
        if(getValues("loadCurrentThird") >= 0){
            clearErrors("loadCurrentThird")
        }
        if(getValues("powerFactorThird") >= 0){
            clearErrors("powerFactorThird")
        }
    }

    // #endregion method;

    return (
        <>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={selectTab} onChange={handleChange} 
                        variant="scrollable" 
                        scrollButtons 
                        allowScrollButtonsMobile 
                        sx={{
                            '& .MuiButtonBase-root.MuiTabScrollButton-root.Mui-disabled': {
                                display: "none"
                            }
                        }}
                    >
                        <Tab label="基本 - [一次側]" {...a11yProps(0)} />
                        <Tab label="基本 - [二次側]" {...a11yProps(1)} />
                        <Tab label="基本 - [三次側]" {...a11yProps(2)} />
                        <Tab label="保護協調 - [二次側]" {...a11yProps(3)} />
                        <Tab label="保護協調 - [三次側]" {...a11yProps(4)} />
                        <Tab label="電圧降下 - [二次側]" {...a11yProps(5)} />
                        <Tab label="電圧降下 - [三次側]" {...a11yProps(6)} />
                        <Tab label="シンボル" {...a11yProps(7)} />
                    </Tabs>
                </Box>
                <form onSubmit={handleSubmit(handleEditSubmit)} style={{ width: '100%', margin: 'auto' }}>
                    <div style={{height:"40vh",width:"100%", margin: 'auto',overflowY:"auto"}}>
                        {/* tab0 */}
                        <TabPanel value={selectTab} index={0}>
                            <Grid container my={1}>
                                <Grid item xs={7}>
                                    <Stack spacing={0.3}>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="80%">デバイス名:</Typography>
                                            <Controller
                                                name="refNo"
                                                control={control}
                                                rules={{
                                                    required: '必須項目です。入力してください。',
                                                }}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        error={!!errors?.refNo}
                                                        onBlur={handleChangeFormat}
                                                        onFocus={e => e.target.select()}
                                                        inputProps={{readOnly: m_bModePM || modeViewOnly}}
                                                        InputProps={{ 
                                                            sx: { 
                                                                height: 32,
                                                                background:m_bModePM || modeViewOnly?'floralwhite':'white',
                                                                color: m_bModePM || modeViewOnly?'grey':'black',
                                                            }
                                                        }}
                                                        helperText={errors?.refNo?.message}

                                                    />
                                                )}
                                            />
                                        </Stack>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="80%">一次側回路電圧(V):</Typography>
                                            <FormControl fullWidth size="small">
                                            <Controller
                                                name="partPrimary.voltage"
                                                control={control}
                                                rules={{ 
                                                    required: '必須項目です。入力してください。',
                                                    pattern:{
                                                        value:regexDecimal,
                                                        message:messageErrorNumber
                                                    },
                                                    validate: value => value >= 1 || '数字 >= 1 を入力してください。' ,
                                                }}
                                                render={({ field }: any) => (
                                                    <>
                                                        <TextFieldOptions
                                                            {...field}
                                                            options={data.voltHighList}
                                                            size="small"
                                                            setValue={(name:any,value:any) =>{
                                                                field.onChange({target:{name:name,value:value}})
                                                                handleEditVoltage({target:{name:name,value:value}},VOLT_SIDE_PRIMARY);
                                                            }}
                                                            height={32}
                                                            disabled={m_bModePM || modeViewOnly || getValues("isSourceVoltage") || processMode > MS_MODE_CHECKING}
                                                            error={!!errors?.partPrimary?.voltage}
                                                            helperText={errors?.partPrimary?.voltage?.message}
                                                            maxLength={6}
                                                        />
                                                    </>
                                                )}
                                            />
                                            </FormControl>
                                        </Stack>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="80%">一次側容量(kVA):</Typography>
                                            <FormControl fullWidth size="small">
                                                <Controller
                                                    name="partPrimary.capacity"
                                                    control={control}
                                                    rules={{ 
                                                        required: '必須項目です。入力してください。',
                                                        pattern:{
                                                            value: regexDecimal,
                                                            message: messageErrorNumber
                                                        },
                                                        validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                    }}
                                                    render={({ field }: any) => (
                                                        <>
                                                            <TextFieldOptions
                                                                {...field}
                                                                disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}
                                                                options={data.capacityList}
                                                                size="small"
                                                                setValue={(name:any,value:any) =>{
                                                                    field.onChange({target:{name:name,value:value}})
                                                                    handleEditCapacity({target:{name:name,value:value}},VOLT_SIDE_PRIMARY);
                                                                }}
                                                                height={32}
                                                                maxLength={6}
                                                                error={!!errors?.partPrimary?.capacity}
                                                                helperText={errors?.partPrimary?.capacity?.message}
                                                            />
                                                        </>
                                                    )}
                                                />
                                            </FormControl>
                                        </Stack>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="80%">一次側定格電流 It(A):</Typography>
                                            <Controller
                                                name="partPrimary.ratedCurrent"
                                                control={control}
                                                rules={{
                                                    required: '必須項目です。入力してください。',
                                                }}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        InputLabelProps={{
                                                            style: { color: 'black' },
                                                        }}
                                                        inputProps={{
                                                            readOnly: true,
                                                        }}
                                                        style={{
                                                            backgroundColor: 'floralwhite',
                                                        }}
                                                        InputProps={{
                                                            sx:{height:32,color:'grey'},
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                    </Stack>
                                </Grid>
                                <Grid item xs={5} pl={2}>
                                    <Stack>
                                        {/* Mode SP */}
                                        {(userRole && !(userRole == Constant.ROLE_LP)) &&
                                        <Controller
                                            name="viewFlowResult"
                                            control={control}
                                            render={({ field }: any) => (
                                                <FormControlLabel
                                                    {...field}
                                                    disabled={m_bModePM || modeViewOnly}
                                                    control={<Checkbox style={checkBoxStyles} />}
                                                    checked={field.value}
                                                    label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>潮流解析結果を表示</Typography>}
                                                    name='viewFlowResult'
                                                    onClick={handleChangeFormat} />
                                            )}
                                        />
                                        }
                                        <Controller
                                            name="viewResultText"
                                            control={control}
                                            render={({ field }: any) => (
                                                <FormControlLabel
                                                    {...field}
                                                    disabled={m_bModePM || modeViewOnly}
                                                    control={<Checkbox style={checkBoxStyles} />}
                                                    checked={field.value}
                                                    label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>注釈を表示</Typography>}
                                                    name='viewResultText'
                                                    onClick={handleChangeFormat}
                                                />
                                            )}
                                        />
                                    </Stack>
                                </Grid>
                            </Grid>
                            <fieldset style={{border:'1px solid #AAAA',marginTop:"10px"}}>
                                <legend><Typography variant="body2">一次側と二次側間のインピーダンス(機器容量ベース)</Typography></legend>
                                <Stack spacing={0.3}>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="100%">X/R:</Typography>
                                                <Controller
                                                    name="partPrimary.xR"
                                                    control={control}
                                                    rules={{
                                                        pattern:{
                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                            message: '数字を入力してください。'
                                                        },
                                                        validate: value => value >= 0 || '数字 >= 0 を入力してください。' ,
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            // type="text"
                                                            size="small"
                                                            onFocus={e => e.target.select()}
                                                            onChange={(e:any) => {
                                                                field.onChange(e);
                                                                setValue(e.target.name,e.target.value);
                                                                handleChangeXr(e,VOLT_SIDE_PRIMARY);
                                                            }}
                                                            sx={{ width: 200 }}
                                                            inputProps={{readOnly: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING,maxLength:5}}
                                                            InputProps={{ 
                                                                sx: { 
                                                                    height: 32,
                                                                    background:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white',
                                                                    color: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'grey':'black',
                                                                }
                                                            }}   
                                                            error={!!errors?.partPrimary?.xR}
                                                            helperText={errors?.partPrimary?.xR?.message}                                                         
                                                        />
                                                    )}
                                                />      
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width='100%'>%インピーダンス(%):</Typography>
                                                <Controller
                                                    name="partPrimary.perZk3"
                                                    control={control}
                                                    rules={{
                                                        required: '必須項目です。入力してください。',
                                                        pattern:{
                                                            value:regexDecimal,
                                                            message:messageErrorNumber,
                                                        },
                                                        validate: value => value > 0 || "数字 > 0 を入力してください。",
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type="text"
                                                            size="small"
                                                            onFocus={e => e.target.select()}                                                            
                                                            onChange={(e:any) => {
                                                                field.onChange(e);
                                                                setValue(e.target.name,e.target.value);
                                                                if(getValues("partPrimary.capacity") != 0){
                                                                    handleChangePerZk3(e,VOLT_SIDE_PRIMARY);
                                                                }
                                                            }}
                                                            sx={{ width: 200 }}
                                                            inputProps={{readOnly: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING,maxLength:5}}
                                                            InputProps={{
                                                                sx: {
                                                                    height: 32,
                                                                    background:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white',
                                                                    color: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'grey':'black',
                                                                }
                                                            }}
                                                            error={!!errors?.partPrimary?.perZk3}
                                                            helperText={errors?.partPrimary?.perZk3?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={0.5} style={{ marginTop: 'auto', marginBottom: 'auto', textAlign: 'center' }}>
                                            <span>=</span>
                                        </Grid>
                                        <Grid item xs={2.5}>
                                            <Stack>
                                            <Controller
                                                name="partPrimary.perRk3"
                                                control={control}
                                                rules={{
                                                    pattern:{
                                                        value:regexDecimal,
                                                        message:messageErrorNumber
                                                    },
                                                    validate: value => value >= 0 || "数字 >= 0 を入力してください。",
                                                }}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        onFocus={e => e.target.select()}                                                        
                                                        onChange={(e:any)=>{
                                                            field.onChange(e);
                                                            setValue(e.target.name,e.target.value);
                                                            handleChangePerRk3(e,VOLT_SIDE_PRIMARY);
                                                        }}
                                                        inputProps={{readOnly: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING,maxLength:5}}
                                                        InputProps={{ 
                                                            sx: { 
                                                                height: 32,
                                                                background:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white',
                                                                color: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'grey':'black',
                                                            }
                                                        }}
                                                        error={!!errors?.partPrimary?.perRk3}
                                                        helperText={errors?.partPrimary?.perRk3?.message}
                                                    />
                                                )}
                                            /> 
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={0.5} style={{ marginTop: 'auto', marginBottom: 'auto', textAlign: 'center' }}>
                                            <span>+ j</span>
                                        </Grid>
                                        <Grid item xs={2.5}>
                                            <Stack>
                                            <Controller
                                                name="partPrimary.perXk3"
                                                control={control}
                                                rules={{
                                                    pattern:{
                                                        value:regexDecimal,
                                                        message:messageErrorNumber
                                                    },
                                                    validate: value => value >= 0 || "数字 >= 0 を入力してください。",
                                                }}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        onFocus={e => e.target.select()}
                                                        onChange={(e:any)=>{
                                                            field.onChange(e);
                                                            setValue(e.target.name,e.target.value);
                                                            handleChangePerXk3(e,VOLT_SIDE_PRIMARY);
                                                        }}
                                                        inputProps={{readOnly: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING,maxLength:5}}
                                                        InputProps={{ 
                                                            sx: { 
                                                                height: 32,
                                                                background:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white',
                                                                color: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'grey':'black',
                                                            }
                                                        }}
                                                        error={!!errors?.partPrimary?.perXk3}
                                                        helperText={errors?.partPrimary?.perXk3?.message}
                                                    />
                                                )}
                                            />      
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width='100%'>インピーダンス(mΩ):</Typography>
                                                <Controller
                                                    name="partPrimary.ohmZ"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type="text"
                                                            size="small"
                                                            onFocus={e => e.target.select()}
                                                            InputLabelProps={{
                                                                style: { color: 'black' },
                                                            }}
                                                            inputProps={{
                                                                readOnly: true,
                                                            }}
                                                            style={{
                                                                backgroundColor: 'floralwhite',
                                                            }}
                                                            sx={{ width: 200 }}
                                                            InputProps={{sx: { height: 32 ,color:'grey'}}}
                                                        />
                                                    )}
                                                />
                                                            
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={0.5} style={{ marginTop: 'auto', marginBottom: 'auto', textAlign: 'center' }}>
                                            <span>=</span>
                                        </Grid>
                                        <Grid item xs={2.5}>
                                            <Stack>
                                                <Controller
                                                    name="partPrimary.ohmR"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type="text"
                                                            size="small"
                                                            onFocus={e => e.target.select()}
                                                            InputLabelProps={{
                                                                style: { color: 'black' },
                                                            }}
                                                            inputProps={{
                                                                readOnly: true,
                                                            }}
                                                            style={{
                                                                backgroundColor: 'floralwhite',
                                                            }}
                                                            InputProps={{sx: { height: 32 ,color:'grey'}}}
                                                        />
                                                    )}
                                                />
                                                            
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={0.5} style={{ marginTop: 'auto', marginBottom: 'auto', textAlign: 'center' }}>
                                            <span>+ j</span>
                                        </Grid>
                                        <Grid item xs={2.5}>
                                            <Stack>
                                                <Controller
                                                    name="partPrimary.ohmX"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type="text"
                                                            size="small"
                                                            onFocus={e => e.target.select()}
                                                            InputLabelProps={{
                                                                style: { color: 'black' },
                                                            }}
                                                            inputProps={{
                                                                readOnly: true,
                                                            }}
                                                            style={{
                                                                backgroundColor: 'floralwhite',
                                                            }}
                                                            InputProps={{sx: { height: 32 ,color:'grey'}}}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Stack>
                            </fieldset>
                            {/* MODE PM */}
                            {[Constant.ROLE_PM, Constant.ROLE_ADMIN].includes(userRole) && 
                            <Grid container my={1}>
                                <Grid item xs={8}>
                                    <Stack direction='column'>
                                        <Grid container direction="row">
                                            <Grid item xs={12}>
                                                <Stack>
                                                    <FormControlLabel
                                                        control={
                                                            <Controller
                                                                name='makeDataInput'
                                                                control={control}
                                                                render={({ field }) => (
                                                                    <Checkbox
                                                                        {...field}
                                                                        disabled={m_bModePM || modeViewOnly}
                                                                        checked={field.value}
                                                                        onChange={(e)=>{
                                                                            setMakeDataInput(e.target.checked);
                                                                            setValue("makeDataInput",e.target.checked);
                                                                        }}
                                                                        style={checkboxStyle2}

                                                                    />
                                                                )}
                                                            />
                                                        }
                                                        label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>銘板入力</Typography>}
                                                    />
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Stack>
                                                <fieldset
                                                    style={{ 
                                                        width: '100%',
                                                        color: m_bModePM || !makeDataInput ? 'grey' : 'black', 
                                                        border:"1px solid #AAAA",
                                                        // borderColor: !makeDataInput ? 'white' : undefined, 
                                                        // marginTop:'1px'
                                                    }}
                                                >
                                                    <legend><Typography variant="body2" >銘板</Typography></legend>
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <Stack spacing={0.3}>
                                                                <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                                    <Typography variant="body2" width="35%" >製造者:</Typography>
                                                                        <FormControl fullWidth size='small'>
                                                                            <Controller
                                                                                name='makerName'
                                                                                control={control}
                                                                                render={({ field }: any) => (
                                                                                    <TextFieldOptions
                                                                                        {...field}
                                                                                        options={initialValue.makerNameList}
                                                                                        type='string'
                                                                                        disabled={m_bModePM || modeViewOnly || !makeDataInput}
                                                                                        size='small'
                                                                                        setValue={(name: any, value: any) => {
                                                                                            handleChangeFormat({target: {name: name, value: value}})
                                                                                        }}
                                                                                        height={32}
                                                                                    />
                                                                                )}
                                                                            />
                                                                        </FormControl>
                                                                </Stack>
                                                                <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                                    <Typography variant="body2" width="35%" >形式:</Typography>
                                                                    <Controller
                                                                        name='type'
                                                                        control={control}
                                                                        render={({ field }) => (
                                                                            <TextField
                                                                                {...field}
                                                                                fullWidth
                                                                                type='text'
                                                                                size='small'
                                                                                onChange={handleChangeFormat}
                                                                                inputProps={{readOnly: m_bModePM || modeViewOnly || !makeDataInput}}
                                                                                InputProps={{ 
                                                                                    sx: { 
                                                                                        height: 32,
                                                                                        background:m_bModePM || modeViewOnly || !makeDataInput?'floralwhite':'white',
                                                                                        color: m_bModePM || modeViewOnly || !makeDataInput?'grey':'black',
                                                                                    }
                                                                                }}
                                                                            />
                                                                        )}
                                                                    />
                                                                </Stack>
                                                                <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                                    <Typography variant="body2" width="35%"  >製造年月:</Typography>
                                                                    <Grid container>
                                                                        <Grid item xs={7}>
                                                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                                                <FormControl fullWidth size='small'>
                                                                                    <Controller
                                                                                        name='makeYear'
                                                                                        control={control}
                                                                                        
                                                                                        render={({ field }: any) => (
                                                                                            <TextFieldOptions
                                                                                                {...field}
                                                                                                options={initialValue.makeYearList}
                                                                                                type='string'
                                                                                                size='small'
                                                                                                disabled={m_bModePM || modeViewOnly || !makeDataInput}
                                                                                                setValue={(name: any, value: any) => {
                                                                                                    handleChangeFormat({target: {name: name, value: value}})
                                                                                                }}
                                                                                                height= {32}
                                                                                            />
                                                                                        )}
                                                                                    />
                                                                                </FormControl>
                                                                                <Typography variant="body2" pr={1.5} pl={0.5}>年</Typography>
                                                                            </Stack>
                                                                            
                                                                        </Grid>
                                                                        <Grid item xs={5}>
                                                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                                                <FormControl fullWidth size='small'>
                                                                                    <Controller
                                                                                        name='makeMonth'
                                                                                        control={control}
                                                                                        render={({ field }: any) => (
                                                                                            <TextFieldOptions
                                                                                                {...field}
                                                                                                disabled={m_bModePM || modeViewOnly || !makeDataInput}
                                                                                                options={initialValue.makeMonthList}
                                                                                                type='string'
                                                                                                size='small'
                                                                                                setValue={(name: any, value: any) => {
                                                                                                    handleChangeFormat({target: {name: name, value: value}})
                                                                                                }}
                                                                                                height= {32}
                                                                                            />
                                                                                        )}
                                                                                    />
                                                                                </FormControl>
                                                                                <Typography variant="body2" pl={0.5}>月</Typography>
                                                                            </Stack>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Stack>
                                                                <Stack sx={{ flexDirection: "row", alignItems:"center"}}>
                                                                    <Typography variant="body2" width="35%"  >製造番号:</Typography>
                                                                    <Controller
                                                                        name='makeNumber'
                                                                        control={control}
                                                                        render={({ field }) => (
                                                                            <TextField
                                                                                {...field}
                                                                                fullWidth
                                                                                type='text'
                                                                                size='small'
                                                                                onChange={handleChangeFormat}
                                                                                inputProps={{readOnly: m_bModePM || modeViewOnly || !makeDataInput}}
                                                                                InputProps={{ 
                                                                                    sx: { 
                                                                                        height: 32,
                                                                                        background:m_bModePM || modeViewOnly || !makeDataInput?'floralwhite':'white',
                                                                                        color: m_bModePM || modeViewOnly || !makeDataInput?'grey':'black',
                                                                                    }
                                                                                }}
                                                                            />
                                                                        )}
                                                                    />
                                                                </Stack>
                                                            </Stack>
                                                        </Grid>
                                                    </Grid>
                                                </fieldset>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </Stack>
                                </Grid>
                            </Grid>
                            }
                        </TabPanel>

                        {/* tab1 */}
                        <TabPanel value={selectTab} index={1}>
                            <Grid container my={1}>
                                <Grid item xs={7}>
                                    <Stack spacing={0.3}>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="80%">事故点名称:</Typography>
                                            <Controller
                                                    name="partSecondary.pointText"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type="text"
                                                            size="small"
                                                            onBlur={handleChangeFormat}
                                                            onFocus={e => e.target.select()}
                                                            inputProps={{readOnly: m_bModePM || modeViewOnly}}
                                                            InputProps={{ 
                                                                sx: { 
                                                                    height: 32,
                                                                    background:m_bModePM || modeViewOnly?'floralwhite':'white',
                                                                    color: m_bModePM || modeViewOnly?'grey':'black',
                                                                }
                                                            }}
                                                        />
                                                    )}
                                                />
                                        </Stack>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="80%">一次側回路電圧(V):</Typography>
                                            <Controller
                                                name="partPrimary.voltage"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        InputLabelProps={{
                                                            style: { color: 'black' },
                                                        }}
                                                        inputProps={{
                                                            readOnly: true,
                                                        }}
                                                        style={{
                                                            backgroundColor: 'floralwhite',
                                                        }}
                                                        InputProps={{
                                                            sx:{height:32,color:'grey'},
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="80%">二次側回路電圧(V):</Typography>
                                            <FormControl fullWidth size="small">
                                            <Controller
                                                name="partSecondary.voltage"
                                                control={control}
                                                rules={{ 
                                                    required: '必須項目です。入力してください。',
                                                    pattern:{
                                                        value:regexDecimal,
                                                        message:messageErrorNumber
                                                    },
                                                    validate: value => value >= 1 || '数字 >= 1 を入力してください。',
                                                }}
                                                render={({ field }: any) => (
                                                    <> 
                                                        <TextFieldOptions
                                                            {...field}
                                                            size="small"
                                                            disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}
                                                            options={data.allVoltList}
                                                            height={32}
                                                            maxLength={5}
                                                            setValue={(name:any,value) => {
                                                                handleEditVoltage({target:{name:name,value:value}},VOLT_SIDE_SECONDARY);                                                                
                                                                field.onChange({target:{name:name,value:value}})
                                                            }}                                                            
                                                            error={!!errors?.partSecondary?.voltage}
                                                            helperText={errors?.partSecondary?.voltage?.message}
                                                        />
                                                    </>
                                                )}
                                            />
                                            </FormControl>
                                        </Stack>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="80%">二次側容量(kVA):</Typography>
                                            <FormControl fullWidth size="small">
                                                <Controller
                                                    name="partSecondary.capacity"
                                                    control={control}
                                                    rules={{ 
                                                        required: '必須項目です。入力してください。',
                                                        pattern:{
                                                            value:regexDecimal,
                                                            message:messageErrorNumber
                                                        },
                                                        validate: value => value > 0 || '数字 > 0 を入力してください。',
                                                    }}
                                                    render={({ field }: any) => (
                                                        <>
                                                            <TextFieldOptions
                                                                {...field}
                                                                disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}
                                                                options={data.capacityList}
                                                                size="small"
                                                                setValue={(name:any,value) => {
                                                                    field.onChange({target:{name:name,value:value}})
                                                                    handleEditCapacity({target:{name:name,value:value}},VOLT_SIDE_SECONDARY);                                                                
                                                                }}
                                                                height={32}
                                                                maxLength={6}
                                                                error={!!errors?.partSecondary?.capacity}
                                                                helperText={errors?.partSecondary?.capacity?.message}
                                                            />
                                                        </>
                                                    )}
                                                />
                                            </FormControl>
                                        </Stack>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="80%">二次側定格電流 It(A):</Typography>
                                            <Controller
                                                name="partSecondary.ratedCurrent"
                                                control={control}
                                                rules={{
                                                    required: '必須項目です。入力してください。',
                                                }}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        InputLabelProps={{
                                                            style: { color: 'black' },
                                                        }}
                                                        inputProps={{
                                                            readOnly: true,
                                                        }}
                                                        style={{
                                                            backgroundColor: 'floralwhite',
                                                        }}
                                                        InputProps={{
                                                            sx:{height:32,color:'grey'},
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                    </Stack>
                                </Grid>
                                <Grid item xs={4} pl={2}>
                                    <Controller
                                        name="partSecondary.fault"
                                        control={control}
                                        render={({ field }: any) => (
                                            <FormControlLabel
                                                {...field}
                                                disabled={m_bModePM || modeViewOnly}
                                                control={<Checkbox style={checkBoxStyles} />}
                                                checked={field.value}
                                                label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>短絡電流を計算</Typography>}
                                                name='partSecondary.fault'
                                                onClick={handleChangeFormat}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            <fieldset style={fieldsetStyle}>
                                <legend><Typography variant="body2" >二次側と三次側間のインピーダンス(機器容量ベース)</Typography></legend>
                                <Stack spacing={0.3}>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="100%">X/R:</Typography>
                                                <Controller
                                                    name="partSecondary.xR"
                                                    control={control}
                                                    rules={{
                                                        pattern:{
                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                            message: '数字を入力してください。'
                                                        },
                                                        validate: value => value >= 0 || '数字 >= 0 を入力してください。' ,
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            // type="text"
                                                            size="small"
                                                            onFocus={e => e.target.select()}                                                            
                                                            onChange={(e:any) => {
                                                                field.onChange(e);
                                                                setValue(e.target.name,e.target.value);
                                                                handleChangeXr(e,VOLT_SIDE_SECONDARY);
                                                            }}
                                                            sx={{ width: 200 }}
                                                            inputProps={{readOnly: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING, maxLength:5}}
                                                            InputProps={{ 
                                                                sx: { 
                                                                    height: 32,
                                                                    background:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white',
                                                                    color: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'grey':'black',
                                                                }
                                                            }}
                                                            error={!!errors?.partSecondary?.xR}
                                                            helperText={errors?.partSecondary?.xR?.message} 
                                                        />
                                                    )}
                                                />        
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width='100%'>%インピーダンス(%):</Typography>
                                                <Controller
                                                    name="partSecondary.perZk3"
                                                    control={control}
                                                    rules={{
                                                        pattern:{
                                                            value:regexDecimal,
                                                            message:messageErrorNumber
                                                        },
                                                        validate: value => value > 0 || "数字 > 0 を入力してください。",
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type="text"
                                                            size="small"
                                                            onFocus={e => e.target.select()}
                                                            error={!!errors?.partSecondary?.perZk3}
                                                            helperText={errors?.partSecondary?.perZk3?.message}
                                                            onChange={(e:any) => {
                                                                field.onChange(e);
                                                                setValue(e.target.name,e.target.value);
                                                                if(getValues("partSecondary.capacity") != 0){
                                                                    handleChangePerZk3(e,VOLT_SIDE_SECONDARY);
                                                                }
                                                            }}
                                                            sx={{ width: 200 }}
                                                            inputProps={{readOnly: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING,maxLength:5}}
                                                            InputProps={{ 
                                                                sx: { 
                                                                    height: 32,
                                                                    background:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white',
                                                                    color: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'grey':'black',
                                                                }
                                                            }}
                                                        />
                                                    )}
                                                />     
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={0.5} style={{ marginTop: 'auto', marginBottom: 'auto', textAlign: 'center' }}>
                                            <span>=</span>
                                        </Grid>
                                        <Grid item xs={2.5}>
                                            <Stack>
                                                <Controller
                                                    name="partSecondary.perRk3"
                                                    control={control}
                                                    rules={{
                                                        pattern:{
                                                            value:regexDecimal,
                                                            message:messageErrorNumber
                                                        },
                                                        validate: value => value >= 0 || "数字 >= 0 を入力してください。",
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type="text"
                                                            size="small"
                                                            onFocus={e => e.target.select()}                                                            
                                                            onChange={(e:any) => {
                                                                field.onChange(e);
                                                                setValue(e.target.name,e.target.value);
                                                                handleChangePerRk3(e,VOLT_SIDE_SECONDARY);
                                                            }}
                                                            inputProps={{readOnly: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING,maxLength:5}}
                                                            InputProps={{ 
                                                                sx: { 
                                                                    height: 32,
                                                                    background:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white',
                                                                    color: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'grey':'black',
                                                                }
                                                            }}
                                                            error={!!errors?.partSecondary?.perRk3}
                                                            helperText={errors?.partSecondary?.perRk3?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={0.5} style={{ marginTop: 'auto', marginBottom: 'auto', textAlign: 'center' }}>
                                            <span>+ j</span>
                                        </Grid>
                                        <Grid item xs={2.5}>
                                            <Stack>
                                            <Controller
                                                name="partSecondary.perXk3"
                                                control={control}
                                                rules={{
                                                    pattern:{
                                                        value:regexDecimal,
                                                        message:messageErrorNumber
                                                    },
                                                    validate: value => value >= 0 || "数字 >= 0 を入力してください。",
                                                }}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"                                                        
                                                        inputProps={{readOnly: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING,maxLength:5}}
                                                        InputProps={{ 
                                                            sx: { 
                                                                height: 32,
                                                                background:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white',
                                                                color: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'grey':'black',
                                                            }
                                                        }}
                                                        onFocus={e => e.target.select()}                                                        
                                                        onChange={(e:any) => {
                                                            field.onChange(e);
                                                            setValue(e.target.name,e.target.value);
                                                            handleChangePerXk3(e,VOLT_SIDE_SECONDARY);
                                                        }}
                                                        error={!!errors?.partSecondary?.perXk3}
                                                        helperText={errors?.partSecondary?.perXk3?.message}
                                                    />
                                                )}
                                            />   
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width='100%'>インピーダンス(mΩ):</Typography>
                                                <Controller
                                                    name="partSecondary.ohmZ"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type="text"
                                                            size="small"
                                                            onFocus={e => e.target.select()}
                                                            InputLabelProps={{
                                                                style: { color: 'black' },
                                                            }}
                                                            inputProps={{
                                                                readOnly: true,
                                                            }}
                                                            style={{
                                                                backgroundColor: 'floralwhite',
                                                            }}
                                                            sx={{ width: 200 }}
                                                            InputProps={{sx: { height: 32 ,color:'grey'}}}
                                                        />
                                                    )}
                                                />   
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={0.5} style={{ marginTop: 'auto', marginBottom: 'auto', textAlign: 'center' }}>
                                            <span>=</span>
                                        </Grid>
                                        <Grid item xs={2.5}>
                                            <Stack>
                                            <Controller
                                                name="partSecondary.ohmR"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        onFocus={e => e.target.select()}
                                                        InputLabelProps={{
                                                            style: { color: 'black' },
                                                        }}
                                                        inputProps={{
                                                            readOnly: true,
                                                        }}
                                                        style={{
                                                            backgroundColor: 'floralwhite',
                                                        }}
                                                        InputProps={{sx: { height: 32 ,color:'grey'}}}
                                                    />
                                                )}
                                            />         
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={0.5} style={{ marginTop: 'auto', marginBottom: 'auto', textAlign: 'center' }}>
                                            <span>+ j</span>
                                        </Grid>
                                        <Grid item xs={2.5}>
                                            <Stack>
                                                <Controller
                                                    name="partSecondary.ohmX"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type="text"
                                                            size="small"
                                                            onFocus={e => e.target.select()}
                                                            InputLabelProps={{
                                                                style: { color: 'black' },
                                                            }}
                                                            inputProps={{
                                                                readOnly: true,
                                                            }}
                                                            style={{
                                                                backgroundColor: 'floralwhite',
                                                            }}
                                                            InputProps={{sx: { height: 32 ,color:'grey'}}}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Stack>
                            </fieldset>
                        </TabPanel>

                        {/* tab2 */}
                        <TabPanel value={selectTab} index={2}>
                            <Grid container my={1}>
                                <Grid item xs={7}>
                                    <Stack spacing={0.3}>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="80%">事故点名称:</Typography>
                                            <Controller
                                                name="partThird.pointText"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        error={!!errors?.partThird?.pointText}
                                                        helperText={errors?.partThird?.pointText?.message}
                                                        onBlur={handleChangeFormat}
                                                        onFocus={e => e.target.select()}
                                                        inputProps={{readOnly: m_bModePM || modeViewOnly}}
                                                        InputProps={{ 
                                                            sx: { 
                                                                height: 32,
                                                                background:m_bModePM || modeViewOnly?'floralwhite':'white',
                                                                color: m_bModePM || modeViewOnly?'grey':'black',
                                                            }
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="80%">一次側回路電圧(V):</Typography>
                                            <Controller
                                                name="partPrimary.voltage"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        InputLabelProps={{
                                                            style: { color: 'black' },
                                                        }}
                                                        inputProps={{
                                                            readOnly: true,
                                                        }}
                                                        style={{
                                                            backgroundColor: 'floralwhite',
                                                        }}
                                                        InputProps={{
                                                            sx:{height:32,color:'grey'},
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="80%">三次側回路電圧(V):</Typography>
                                            <FormControl fullWidth size="small">
                                            <Controller
                                                name="partThird.voltage"
                                                control={control}
                                                rules={{ 
                                                    required: '必須項目です。入力してください。',
                                                    pattern:{
                                                        value:regexDecimal,
                                                        message:messageErrorNumber
                                                    },
                                                    validate: value => value >= 1 || '数字 >= 1 を入力してください。',
                                                }}
                                                render={({ field }: any) => (
                                                    <>
                                                        <TextFieldOptions
                                                            {...field}
                                                            size="small"
                                                            disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}
                                                            options={data.allVoltList}
                                                            height={32}
                                                            maxLength={5}
                                                            setValue={(name:any,value) => {
                                                                handleEditVoltage({target:{name:name,value:value}},VOLT_SIDE_THIRD);
                                                                field.onChange({target:{name:name,value:value}});
                                                            }}
                                                            error={!!errors?.partThird?.voltage}
                                                            helperText={errors?.partThird?.voltage?.message}
                                                        />
                                                    </>
                                                )}
                                            />
                                            </FormControl>
                                        </Stack>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="80%">三次側容量(kVA):</Typography>
                                            <FormControl fullWidth size="small">
                                                <Controller
                                                    name="partThird.capacity"
                                                    control={control}
                                                    rules={{ 
                                                        required: '必須項目です。入力してください。',
                                                        pattern:{
                                                            value:regexDecimal,
                                                            message:messageErrorNumber
                                                        },
                                                        validate: value => value > 0 || '数字 > 0 を入力してください。',
                                                    }}
                                                    render={({ field }: any) => (
                                                        <>
                                                            <TextFieldOptions
                                                                {...field}
                                                                disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}
                                                                options={data.capacityList}
                                                                size="small"
                                                                height={32}
                                                                maxLength={6}
                                                                error={!!errors?.partThird?.capacity}
                                                                helperText={errors?.partThird?.capacity?.message}
                                                                setValue={(name:any,value) => {
                                                                    field.onChange({target:{name:name,value:value}});
                                                                    handleEditCapacity({target:{name:name,value:value}},VOLT_SIDE_THIRD);
                                                                }}
                                                            />
                                                        </>
                                                    )}
                                                />
                                            </FormControl>
                                        </Stack>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="80%">三次側定格電流 It(A):</Typography>
                                            <Controller
                                                name="partThird.ratedCurrent"
                                                control={control}
                                                rules={{
                                                    required: '必須項目です。入力してください。',
                                                }}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        InputLabelProps={{
                                                            style: { color: 'black' },
                                                        }}
                                                        inputProps={{
                                                            readOnly: true,
                                                        }}
                                                        style={{
                                                            backgroundColor: 'floralwhite',
                                                        }}
                                                        InputProps={{
                                                            sx:{height:32,color:'grey'},
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                    </Stack>
                                </Grid>
                                <Grid item xs={4} pl={2}>
                                    <Controller
                                        name="partThird.fault"
                                        control={control}
                                        render={({ field }: any) => (
                                            <FormControlLabel
                                                {...field}
                                                disabled={m_bModePM || modeViewOnly}
                                                control={<Checkbox style={checkBoxStyles} />}
                                                checked={field.value}
                                                label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>短絡電流を計算</Typography>}
                                                name='partThird.fault'
                                                onClick={handleChangeFormat}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            <fieldset style={fieldsetStyle}>
                                <legend> <Typography variant="body2" width="100%">三次側と一次側間のインピーダンス(機器容量ベース)</Typography></legend>
                                <Stack spacing={0.3}>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="100%">X/R:</Typography>
                                                <Controller
                                                    name="partThird.xR"
                                                    control={control}
                                                    rules={{
                                                        pattern:{
                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                            message: '数字を入力してください。'
                                                        },
                                                        validate: value => value >= 0 || '数字 >= 0 を入力してください。' ,
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            // type="text"
                                                            size="small"
                                                            onFocus={e => e.target.select()}
                                                            onChange={(e:any) => {
                                                                field.onChange(e);
                                                                setValue(e.target.name,e.target.value);
                                                                handleChangeXr(e,VOLT_SIDE_THIRD)
                                                            }}
                                                            sx={{ width: 200 }}
                                                            inputProps={{readOnly: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING,maxLength:5}}
                                                            InputProps={{ 
                                                                sx: {
                                                                    height: 32,
                                                                    background:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white',
                                                                    color: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'grey':'black',
                                                                }
                                                            }}
                                                            error={!!errors?.partThird?.xR}
                                                            helperText={errors?.partThird?.xR?.message}
                                                        />
                                                    )}
                                                />      
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width='100%'>%インピーダンス(%):</Typography>
                                                <Controller
                                                    name="partThird.perZk3"
                                                    control={control}
                                                    rules={{
                                                        pattern:{
                                                            value:regexDecimal,
                                                            message:messageErrorNumber
                                                        },
                                                        validate: value => value > 0 || "数字 > 0 を入力してください。",
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type="text"
                                                            size="small"
                                                            sx={{ width: 200 }}
                                                            inputProps={{readOnly: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING,maxLength:5}}
                                                            InputProps={{
                                                                sx: {
                                                                    height: 32,
                                                                    background:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white',
                                                                    color: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'grey':'black',
                                                                }
                                                            }}
                                                            onFocus={e => e.target.select()}
                                                            onChange={(e:any)=>{
                                                                field.onChange(e);
                                                                setValue(e.target.name,e.target.value);
                                                                if(getValues("partThird.capacity") != 0){
                                                                    handleChangePerZk3(e,VOLT_SIDE_THIRD);
                                                                }
                                                            }}
                                                            error={!!errors?.partThird?.perZk3}
                                                            helperText={errors?.partThird?.perZk3?.message}
                                                        />
                                                    )}
                                                />  
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={0.5} style={{ marginTop: 'auto', marginBottom: 'auto', textAlign: 'center' }}>
                                            <span>=</span>
                                        </Grid>
                                        <Grid item xs={2.5}>
                                            <Stack>
                                                <Controller
                                                    name="partThird.perRk3"
                                                    control={control}
                                                    rules={{
                                                        pattern:{
                                                            value:regexDecimal,
                                                            message:messageErrorNumber
                                                        },
                                                        validate: value => value >= 0 || "数字 >= 0 を入力してください。",
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type="text"
                                                            size="small"                                                            
                                                            inputProps={{readOnly: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING,maxLength:5}}
                                                            InputProps={{ 
                                                                sx: { 
                                                                    height: 32,
                                                                    background:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white',
                                                                    color: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'grey':'black',
                                                                }
                                                            }}
                                                            onFocus={e => e.target.select()}
                                                            onChange={(e:any) => {
                                                                field.onChange(e);
                                                                setValue(e.target.name,e.target.value);
                                                                handleChangePerRk3(e,VOLT_SIDE_THIRD);
                                                            }}
                                                            error={!!errors?.partThird?.perRk3}
                                                            helperText={errors?.partThird?.perRk3?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={0.5} style={{ marginTop: 'auto', marginBottom: 'auto', textAlign: 'center' }}>
                                            <span>+ j</span>
                                        </Grid>
                                        <Grid item xs={2.5}>
                                            <Stack>
                                                <Controller
                                                    name="partThird.perXk3"
                                                    control={control}
                                                    rules={{
                                                        pattern:{
                                                            value:regexDecimal,
                                                            message:messageErrorNumber
                                                        },
                                                        validate: value => value >= 0 || "数字 >= 0 を入力してください。",
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type="text"
                                                            size="small"
                                                            inputProps={{readOnly: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING,maxLength:5}}
                                                            InputProps={{
                                                                sx: {
                                                                    height: 32,
                                                                    background:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white',
                                                                    color: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'grey':'black',
                                                                }
                                                            }}
                                                            onFocus={e => e.target.select()}
                                                            onChange={(e:any) => {
                                                                field.onChange(e);
                                                                setValue(e.target.name,e.target.value);
                                                                handleChangePerXk3(e,VOLT_SIDE_THIRD);
                                                            }}
                                                            error={!!errors?.partThird?.perXk3}
                                                            helperText={errors?.partThird?.perXk3?.message}
                                                        />
                                                    )}
                                                    
                                                />
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width='100%'>インピーダンス(mΩ):</Typography>
                                                <Controller
                                                    name="partThird.ohmZ"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type="text"
                                                            size="small"
                                                            onFocus={e => e.target.select()}
                                                            InputLabelProps={{
                                                                style: { color: 'black' },
                                                            }}
                                                            inputProps={{
                                                                readOnly: true,
                                                            }}
                                                            style={{
                                                                backgroundColor: 'floralwhite',
                                                            }}
                                                            sx={{ width: 200 }}
                                                            InputProps={{sx: { height: 32 ,color:'grey'}}}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={0.5} style={{ marginTop: 'auto', marginBottom: 'auto', textAlign: 'center' }}>
                                            <span>=</span>
                                        </Grid>
                                        <Grid item xs={2.5}>
                                            <Stack>
                                                <Controller
                                                    name="partThird.ohmR"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type="text"
                                                            size="small"
                                                            onFocus={e => e.target.select()}
                                                            InputLabelProps={{
                                                                style: { color: 'black' },
                                                            }}
                                                            inputProps={{
                                                                readOnly: true,
                                                            }}
                                                            style={{
                                                                backgroundColor: 'floralwhite',
                                                            }}
                                                            InputProps={{sx: { height: 32 ,color:'grey'}}}
                                                        />
                                                    )}
                                                />        
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={0.5} style={{ marginTop: 'auto', marginBottom: 'auto', textAlign: 'center' }}>
                                            <span>+ j</span>
                                        </Grid>
                                        <Grid item xs={2.5}>
                                            <Stack>
                                                <Controller
                                                    name="partThird.ohmX"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type="text"
                                                            size="small"
                                                            onFocus={e => e.target.select()}
                                                            InputLabelProps={{
                                                                style: { color: 'black' },
                                                            }}
                                                            inputProps={{
                                                                readOnly: true,
                                                            }}
                                                            style={{
                                                                backgroundColor: 'floralwhite',
                                                            }}
                                                            InputProps={{sx: { height: 32,color:'grey' }}}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Stack>
                            </fieldset>
                        </TabPanel>

                        {/* tab3 */}
                        <TabPanel value={selectTab} index={3}>
                            <Stack mt={1} width={'75%'}>
                                <fieldset style={{border:"1px solid #AAAA"}}>
                                        <legend ><Typography variant="body2">励磁突入電流曲線</Typography></legend>
                                        <Stack spacing={0.3}>
                                            <Stack>
                                                <FormControlLabel
                                                    control={
                                                        <Controller
                                                            name={"partSecondary.dispInrush"}
                                                            control={control}
                                                            render={({ field }) => (
                                                                <Checkbox
                                                                    {...field}
                                                                    size='small'
                                                                    disabled={m_bModePM || modeViewOnly}
                                                                    checked={field.value}
                                                                    onClick={(e:any) => {
                                                                        setValue(e.target.name,e.target.value);
                                                                        handleChangeDispInRush(e,VOLT_SIDE_SECONDARY);
                                                                    }}
                                                                    name='partSecondary.dispInrush'
                                                                    style={checkBoxStyles}
                                                                />
                                                            )}
                                                        />
                                                    }
                                                    label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>グラフに表示する</Typography>}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="75%">定格電流の最大倍率(倍):</Typography>
                                                <Controller
                                                    name={"partSecondary.inrushTimes"}
                                                    control={control}
                                                    rules={{
                                                        required:"必須項目です。入力してください。",
                                                        pattern:{
                                                            value:regexDecimal,
                                                            message:messageErrorNumber
                                                        },
                                                        validate: value => value > 0 || "数字 > 0 を入力してください。",
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            // type="text"
                                                            size="small"
                                                            onFocus={e => e.target.select()}
                                                            onChange={(e:any) => {
                                                                field.onChange(e);
                                                                handleChangeInRushTimes(e,VOLT_SIDE_SECONDARY);
                                                            }}
                                                            inputProps={{readOnly: m_bModePM || modeViewOnly,maxLength:4}}
                                                            InputProps={{ 
                                                                sx: { 
                                                                    height: 32,
                                                                    background:m_bModePM || modeViewOnly?'floralwhite':'white',
                                                                    color: m_bModePM || modeViewOnly?'grey':'black',
                                                                }
                                                            }}
                                                            error={!!errors?.partSecondary?.inrushTimes}
                                                            helperText={errors?.partSecondary?.inrushTimes?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="75%">最大電流(Arms):</Typography>
                                                <Controller
                                                    name={"inrushValueSecond"}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type="text"
                                                            size="small"
                                                            onFocus={e => e.target.select()}
                                                            InputLabelProps={{
                                                                style: { color: 'black' },
                                                            }}
                                                            inputProps={{
                                                                readOnly: true,
                                                            }}
                                                            style={{
                                                                backgroundColor: 'floralwhite',
                                                            }}
                                                            InputProps={{
                                                                sx:{height:32,color:'grey'},
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="75%">減衰時定数(ms):</Typography>
                                                <Controller
                                                    name={"partSecondary.dampingTime"}
                                                    control={control}
                                                    rules={{
                                                        required:"必須項目です。入力してください。",
                                                        pattern:{
                                                            value:regexDecimal,
                                                            message:messageErrorNumber
                                                        },
                                                        validate: value => value > 0 || "数字 > 0 を入力してください。",
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            // type="text"
                                                            size="small"
                                                            label=''
                                                            onFocus={e => e.target.select()}
                                                            
                                                            onChange={(e:any) => {
                                                                field.onChange(e);
                                                                handleChangeDampingTime(e,VOLT_SIDE_SECONDARY);
                                                            }}
                                                            inputProps={{readOnly: m_bModePM || modeViewOnly,maxLength:5}}
                                                            InputProps={{ 
                                                                sx: { 
                                                                    height: 32,
                                                                    background:m_bModePM || modeViewOnly?'floralwhite':'white',
                                                                    color: m_bModePM || modeViewOnly?'grey':'black',
                                                                }
                                                            }}
                                                            error={!!errors?.partSecondary?.dampingTime}
                                                            helperText={errors?.partSecondary?.dampingTime?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                        </Stack>
                                    </fieldset>
                                </Stack>
                                <Stack mt={2} width={'75%'}>
                                    <fieldset style={{border:"1px solid #AAAA"}}>
                                        <legend><Typography variant="body2">熱的機械的強度ポイント</Typography></legend>
                                        <Stack spacing={0.3}>
                                            <Stack>
                                                <FormControlLabel
                                                    control={
                                                        <Controller
                                                            name={"partSecondary.dispIntensity"}
                                                            control={control}
                                                            render={({ field }) => (
                                                                <Checkbox
                                                                    {...field}
                                                                    disabled={m_bModePM || modeViewOnly}
                                                                    size='small'
                                                                    checked={field.value}
                                                                    name='partSecondary.dispIntensity' 
                                                                    onClick={(e:any)=> {
                                                                        setValue(e.target.name,e.target.value);
                                                                        handleChangeDispIntensity(e,VOLT_SIDE_SECONDARY);
                                                                    }}
                                                                    style={checkBoxStyles}
                                                                />
                                                            )}
                                                        />
                                                    }
                                                    label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>グラフに表示する</Typography>}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="75%">定格電流の最大倍率(倍):</Typography>
                                                <Controller
                                                    name={"partSecondary.intensityTimes"}
                                                    control={control}
                                                    rules={{
                                                        required:"必須項目です。入力してください。",
                                                        pattern:{
                                                            value:regexDecimal,
                                                            message:messageErrorNumber
                                                        },
                                                        validate: value => value > 0 || "数字 > 0 を入力してください。",
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            // type="text"
                                                            size="small"
                                                            label=''
                                                            onFocus={e => e.target.select()}
                                                            onChange={(e:any) => {
                                                                field.onChange(e);
                                                                setValue(e.target.name,e.target.value);
                                                                handleChangeIntensityTimes(e,VOLT_SIDE_SECONDARY);
                                                            }}
                                                            inputProps={{readOnly: m_bModePM || modeViewOnly,maxLength:4}}
                                                            InputProps={{ 
                                                                sx: { 
                                                                    height: 32,
                                                                    background:m_bModePM || modeViewOnly?'floralwhite':'white',
                                                                    color: m_bModePM || modeViewOnly?'grey':'black',
                                                                }
                                                            }}
                                                            error={!!errors?.partSecondary?.intensityTimes}
                                                            helperText={errors?.partSecondary?.intensityTimes?.message}

                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="75%">最大電流(Arms):</Typography>
                                                <Controller
                                                    name={"intensityValueSecond"}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type="text"
                                                            size="small"
                                                            label=''
                                                            onFocus={e => e.target.select()}
                                                            InputLabelProps={{
                                                                style: { color: 'black' },
                                                            }}
                                                            inputProps={{
                                                                readOnly: true,
                                                            }}
                                                            style={{
                                                                backgroundColor: 'floralwhite',
                                                            }}
                                                            InputProps={{
                                                                sx:{height:32,color:'grey'},
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="75%">時間(s):</Typography>
                                                <Controller
                                                    name={"partSecondary.intensityTime"}
                                                    control={control}
                                                    rules={{
                                                        required:"必須項目です。入力してください。",
                                                        pattern:{
                                                            value:regexDecimal,
                                                            message:messageErrorNumber
                                                        },
                                                        validate: value => value > 0 || "数字 > 0 を入力してください。",
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            // type="text"
                                                            size="small"
                                                            onFocus={e => e.target.select()}                                                            
                                                            onChange={(e:any) => {
                                                                field.onChange(e);
                                                                setValue(e.target.name,e.target.value);
                                                                handleChangeIntensityTime(e,VOLT_SIDE_THIRD);
                                                            }}
                                                            inputProps={{readOnly: m_bModePM || modeViewOnly,maxLength:4}}
                                                            InputProps={{ 
                                                                sx: { 
                                                                    height: 32,
                                                                    background:m_bModePM || modeViewOnly?'floralwhite':'white',
                                                                    color: m_bModePM || modeViewOnly?'grey':'black',
                                                                }
                                                            }}
                                                            error={!!errors?.partSecondary?.intensityTime}
                                                            helperText={errors?.partSecondary?.intensityTime?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                        </Stack>
                                    </fieldset>
                                </Stack>
                        </TabPanel>

                        {/* tab4 */}
                        <TabPanel value={selectTab} index={4}>
                            <Stack mt={1} width={'75%'}>
                                    <fieldset style={{border:"1px solid #AAAA"}}>
                                        <legend ><Typography variant="body2">励磁突入電流曲線</Typography></legend>
                                        <Stack spacing={0.3}>
                                            <Stack>
                                                <FormControlLabel
                                                    control={
                                                        <Controller
                                                            name={"partThird.dispInrush"}
                                                            control={control}
                                                            render={({ field }) => (
                                                                <Checkbox
                                                                    {...field}
                                                                    disabled={m_bModePM || modeViewOnly}
                                                                    size='small'
                                                                    checked={field.value}
                                                                    onClick={(e:any)=>{
                                                                        setValue(e.target.name,e.target.value);
                                                                        handleChangeDispInRush(e,VOLT_SIDE_THIRD)
                                                                    }}
                                                                    name='partThird.dispInrush'
                                                                    style={checkBoxStyles}
                                                                />
                                                            )}
                                                        />
                                                    }
                                                    label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>グラフに表示する</Typography>}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="75%">定格電流の最大倍率(倍):</Typography>
                                                <Controller
                                                    name={"partThird.inrushTimes"}
                                                    control={control}
                                                    rules={{
                                                        required:"必須項目です。入力してください。",
                                                        pattern:{
                                                            value:regexDecimal,
                                                            message:messageErrorNumber
                                                        },
                                                        validate: value => value > 0 || "数字 > 0 を入力してください。",
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            // type="text"
                                                            size="small"
                                                            onFocus={e => e.target.select()}                                                            
                                                            onChange={(e:any) => {
                                                                field.onChange(e);
                                                                handleChangeInRushTimes(e,VOLT_SIDE_THIRD);
                                                            }}
                                                            inputProps={{readOnly: m_bModePM || modeViewOnly,maxLength:4}}
                                                            InputProps={{ 
                                                                sx: { 
                                                                    height: 32,
                                                                    background:m_bModePM || modeViewOnly?'floralwhite':'white',
                                                                    color: m_bModePM || modeViewOnly?'grey':'black',
                                                                }
                                                            }}
                                                            error={!!errors?.partThird?.inrushTimes}
                                                            helperText={errors?.partThird?.inrushTimes?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="75%">最大電流(Arms):</Typography>
                                                <Controller
                                                    name={"inrushValueThird"}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type="text"
                                                            size="small"
                                                            onFocus={e => e.target.select()}
                                                            InputLabelProps={{
                                                                style: { color: 'black' },
                                                            }}
                                                            inputProps={{
                                                                readOnly: true,
                                                            }}
                                                            style={{
                                                                backgroundColor: 'floralwhite',
                                                            }}
                                                            InputProps={{
                                                                sx:{height:32,color:'grey'},
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="75%">減衰時定数(ms):</Typography>
                                                <Controller
                                                    name={"partThird.dampingTime"}
                                                    control={control}
                                                    rules={{
                                                        required:"必須項目です。入力してください。",
                                                        pattern:{
                                                            value:regexDecimal,
                                                            message:messageErrorNumber
                                                        },
                                                        validate: value => value > 0 || "数字 > 0 を入力してください。",
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            // type="text"
                                                            size="small"
                                                            onFocus={e => e.target.select()}
                                                            
                                                            onChange={(e:any)=>{
                                                                field.onChange(e);
                                                                handleChangeDampingTime(e,VOLT_SIDE_THIRD);
                                                            }}
                                                            inputProps={{readOnly: m_bModePM || modeViewOnly,maxLength:5}}
                                                            InputProps={{ 
                                                                sx: { 
                                                                    height: 32,
                                                                    background:m_bModePM || modeViewOnly?'floralwhite':'white',
                                                                    color: m_bModePM || modeViewOnly?'grey':'black',
                                                                }
                                                            }}
                                                            error={!!errors?.partThird?.dampingTime}
                                                            helperText={errors?.partThird?.dampingTime?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                        </Stack>
                                    </fieldset>
                                </Stack>
                                <Stack mt={2} width={'75%'}>
                                    <fieldset style={{border:"1px solid #AAAA"}}>
                                        <legend ><Typography variant="body2">熱的機械的強度ポイント</Typography></legend>
                                        <Stack spacing={0.3}>
                                            <Stack>
                                                <FormControlLabel
                                                    control={
                                                        <Controller
                                                            name={"partThird.dispIntensity"}
                                                            control={control}
                                                            render={({ field }) => (
                                                                <Checkbox
                                                                    {...field}
                                                                    disabled={m_bModePM || modeViewOnly}
                                                                    size='small'
                                                                    checked={field.value}
                                                                    name='partThird.dispIntensity' 
                                                                    onClick={(e:any)=>{
                                                                        setValue(e.target.name,e.target.value);
                                                                        handleChangeDispIntensity(e,VOLT_SIDE_THIRD)
                                                                    }}
                                                                    style={checkBoxStyles}
                                                                />
                                                            )}
                                                        />
                                                    }
                                                    label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>グラフに表示する</Typography>}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="75%">定格電流の最大倍率(倍):</Typography>
                                                <Controller
                                                    name={"partThird.intensityTimes"}
                                                    control={control}
                                                    rules={{
                                                        required:"必須項目です。入力してください。",
                                                        pattern:{
                                                            value:regexDecimal,
                                                            message:messageErrorNumber
                                                        },
                                                        validate: value => value > 0 || "数字 > 0 を入力してください。",
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            // type="text"
                                                            size="small"
                                                            onFocus={e => e.target.select()}                                                            
                                                            onChange={(e:any)=>{
                                                                field.onChange(e);
                                                                handleChangeIntensityTimes(e,VOLT_SIDE_THIRD);
                                                            }}
                                                            inputProps={{readOnly: m_bModePM || modeViewOnly,maxLength:4}}
                                                            InputProps={{ 
                                                                sx: { 
                                                                    height: 32,
                                                                    background:m_bModePM || modeViewOnly?'floralwhite':'white',
                                                                    color: m_bModePM || modeViewOnly?'grey':'black',
                                                                }
                                                            }}
                                                            error={!!errors?.partThird?.intensityTimes}
                                                            helperText={errors?.partThird?.intensityTimes?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="75%">最大電流(Arms):</Typography>
                                                <Controller
                                                    name={"intensityValueThird"}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type="text"
                                                            size="small"
                                                            onFocus={e => e.target.select()}
                                                            InputLabelProps={{
                                                                style: { color: 'black' },
                                                            }}
                                                            inputProps={{
                                                                readOnly: true,
                                                            }}
                                                            style={{
                                                                backgroundColor: 'floralwhite',
                                                            }}
                                                            InputProps={{
                                                                sx:{height:32,color:'grey'},
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="75%">時間(s):</Typography>
                                                <Controller
                                                    name={"partThird.intensityTime"}
                                                    control={control}
                                                    rules={{
                                                        required:"必須項目です。入力してください。",
                                                        pattern:{
                                                            value:regexDecimal,
                                                            message:messageErrorNumber
                                                        },
                                                        validate: value => value > 0 ||  "数字 > 0 を入力してください。",
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                        {...field}
                                                        fullWidth
                                                        // type="text"
                                                        size="small"
                                                        onFocus={e => e.target.select()}                                                        

                                                        onChange={(e:any)=>{
                                                            field.onChange(e);
                                                            handleChangeIntensityTime(e,VOLT_SIDE_THIRD);
                                                        }}
                                                        inputProps={{readOnly: m_bModePM || modeViewOnly,maxLength:4}}
                                                        InputProps={{ 
                                                            sx: { 
                                                                height: 32,
                                                                background:m_bModePM || modeViewOnly?'floralwhite':'white',
                                                                color: m_bModePM || modeViewOnly?'grey':'black',
                                                            }
                                                        }}
                                                        error={!!errors?.partThird?.intensityTime}
                                                        helperText={errors?.partThird?.intensityTime?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                        </Stack>
                                    </fieldset>
                                </Stack>
                        </TabPanel>

                        {/* tab5 */}
                        <TabPanel value={selectTab} index={5}>
                            <Grid container my={1}>
                                <Grid item xs={10}>
                                    <Controller
                                            name="dropRegular"
                                            control={control}
                                            render={({ field }: any) => (
                                                <FormControlLabel
                                                    {...field}
                                                    disabled={m_bModePM || modeViewOnly}
                                                    control={<Checkbox style={checkBoxStyles} />} 
                                                    checked={field.value} 
                                                    label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>定常時の電圧降下を計算</Typography>} 
                                                    onClick={(e:any) => {
                                                        setValue(e.target.name,e.target.checked);
                                                        handleChangeDropRegular(e);
                                                    }} 
                                                    name='dropRegular'
                                                />
                                            )}
                                        />
                                </Grid>
                                <Grid container>
                                    <Grid item xs={7.5}>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                            <Typography variant="body2" width="90%" ml={5}>負荷容量 (VA):</Typography>  
                                                <Controller
                                                    name="loadCapacity"
                                                    control={control}
                                                    rules={{
                                                        pattern:{
                                                            value:regexDecimal,
                                                            message:messageErrorNumber,
                                                        },
                                                        validate: value => value >= 0 || "数字 >= 0 を入力してください。",
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            // type="text"
                                                            size="small"
                                                            inputProps={{readOnly: m_bModePM || modeViewOnly || disabledTab5Flag}}
                                                            InputProps={{ 
                                                                sx: { 
                                                                    height: 32,
                                                                    background:m_bModePM || modeViewOnly || disabledTab5Flag?'floralwhite':'white',
                                                                    color: m_bModePM || modeViewOnly || disabledTab5Flag?'grey':'black',
                                                                }
                                                            }}
                                                            onFocus={e => e.target.select()}
                                                            onChange={(e:any) => {
                                                                field.onChange(e);
                                                                handleChangeLoadCapacity(e,VOLT_SIDE_SECONDARY);
                                                            }}
                                                            error={!!errors?.loadCapacity}
                                                            helperText={errors?.loadCapacity?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                    </Grid>
                                    <Grid item xs ={7.5}>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                            <Typography variant="body2" width="90%" ml={5}>負荷電流 IL(A):</Typography>  
                                            <Controller
                                                name="loadCurrent"
                                                control={control}
                                                rules={{
                                                    pattern:{
                                                        value:regexDecimal,
                                                        message:messageErrorNumber,
                                                    },
                                                    validate: value => value >= 0 || "数字 >= 0 を入力してください。",
                                                }}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        // type="text"
                                                        size="small"                                                        
                                                        InputLabelProps={{
                                                            style: { color: 'black' },
                                                        }}
                                                        inputProps={{readOnly: m_bModePM || modeViewOnly || disabledTab5Flag,maxLength:7}}
                                                        InputProps={{ 
                                                            sx: { 
                                                                height: 32,
                                                                background:m_bModePM || modeViewOnly || disabledTab5Flag ? 'floralwhite' : 'white',
                                                                color: m_bModePM || modeViewOnly || disabledTab5Flag ? 'grey' : 'black',
                                                            }
                                                        }}
                                                        onFocus={e => e.target.select()}
                                                        onChange={(event:any) => {
                                                            field.onChange(event);
                                                            handleChangeLoadCurrent(event,VOLT_SIDE_SECONDARY);
                                                        }}
                                                        error={!!errors?.loadCurrent}
                                                        helperText={errors?.loadCurrent?.message}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={2.5} ml={1.55}>
                                        <Button 
                                            style={{margin:"0"}}
                                            variant="outlined"
                                            onClick={() => handleClickGetLoadCurrent(VOLT_SIDE_SECONDARY)}
                                            disabled={m_bModePM || modeViewOnly || disabledTab5Flag}
                                        >
                                            概算値
                                        </Button>
                                        {/* <Button variant="outlined" size="small" disabled={disabledTab5Flag} onClick={()=>{handleClickGetLoadCurrent(VOLT_SIDE_SECONDARY)}}>概算値</Button> */}
                                    </Grid>
                                </Grid>
                                <Grid container ml={3}>
                                    <fieldset style={{width:"76%",border:'1px solid #AAAA'}}>
                                        <legend><Typography variant="body2">回路の力率</Typography>  </legend>
                                        <Grid container>
                                            <Grid item xs={5}>
                                                <Controller
                                                    name="isLead"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <RadioGroup {...field} row>
                                                            <FormControlLabel
                                                                value={true}
                                                                control={<Radio style={radioStyles} />}
                                                                label={<Typography variant="body2" style={{color:m_bModePM || disabledTab5Flag?"grey":"black"}}>進み</Typography>}
                                                                disabled={m_bModePM || modeViewOnly || disabledTab5Flag}

                                                            />
                                                            <FormControlLabel
                                                                value={false}
                                                                control={<Radio style={radioStyles} />}
                                                                label={<Typography variant="body2" style={{color:m_bModePM || disabledTab5Flag?"grey":"black"}}>遅れ</Typography>}
                                                                disabled={m_bModePM || modeViewOnly || disabledTab5Flag}

                                                            />
                                                        </RadioGroup>
                                                    )}
                                                />
                                            </Grid>
                                            <Grid xs={1}/>
                                            <Grid xs={2.75} ml={1.5}>
                                                <Controller
                                                    name="powerFactor"
                                                    control={control}
                                                    rules={{
                                                        pattern:{
                                                            value:regexDecimal,
                                                            message:messageErrorNumber,
                                                        },
                                                        validate: value => value >= 0 && value <= 1 || value >= 0 && value <= 1 || ' 0 =< 数字 <= 1  を入力してください。',
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            // type="text"
                                                            size="small"
                                                            error={!!errors?.powerFactor}
                                                            helperText={errors?.powerFactor?.message}
                                                            inputProps={{readOnly: m_bModePM || modeViewOnly || disabledTab5Flag,maxLength:7}}
                                                            InputProps={{ 
                                                                sx: { 
                                                                    height: 32,
                                                                    background:m_bModePM || modeViewOnly || disabledTab5Flag?'floralwhite':'white',
                                                                    color: m_bModePM || modeViewOnly || disabledTab5Flag?'grey':'black',
                                                                }
                                                            }}
                                                            onFocus={e => e.target.select()}
                                                            onChange={(e:any) => {
                                                                field.onChange(e);
                                                                handleChangePowerFactor(e,VOLT_SIDE_SECONDARY);
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={2.5} ml={1.54}>
                                                <Button
                                                    style={{margin:"0"}}
                                                    variant="outlined"
                                                    onClick={() => handleClickGetLoadPowerFactor(VOLT_SIDE_SECONDARY)}
                                                    disabled={m_bModePM || modeViewOnly || disabledTab5Flag}
                                                >
                                                    概算値
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </fieldset>
                                </Grid>
                                <Grid container  mt={0.25}>
                                    <Grid container flexDirection={"row"} ml={6} alignItems={"center"}>
                                        <Grid item xs={4} >
                                            <Typography variant="body2" width="100%" >単体での電圧降下(V):</Typography>  
                                        </Grid>
                                        <Grid item xs={4} ml={-2.6}>
                                            <Controller
                                                name="voltDropDiff"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                            fullWidth
                                                            type="text"
                                                            size="small"
                                                            onFocus={e => e.target.select()}
                                                            InputLabelProps={{
                                                                style: { color: 'black' },
                                                            }}
                                                            inputProps={{
                                                                readOnly: true,
                                                            }}
                                                            style={{
                                                                backgroundColor: 'floralwhite',width:"70%",marginLeft:'20%'
                                                            }}
                                                            InputProps={{
                                                                sx:{height:32,color:'grey'}
                                                            }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container flexDirection={"row"} ml={6} mt={0.25} alignItems={"center"}>
                                        <Grid item xs={4} >
                                            <Typography variant="body2" width="100%" >単体での電圧降下率(%):</Typography>  
                                        </Grid>
                                        <Grid item xs={4} ml={-2.6}>
                                            <Controller
                                                name="voltDropPerUnit"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        onFocus={e => e.target.select()}
                                                        InputLabelProps={{
                                                            style: { color: 'black' },
                                                        }}
                                                        inputProps={{
                                                            readOnly: true,
                                                        }}
                                                        style={{
                                                            backgroundColor: 'floralwhite',width:"70%",marginLeft:'20%'
                                                        }}
                                                        InputProps={{
                                                            sx:{height:32,color:'grey'}
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </TabPanel>

                        {/* tab6 */}
                        <TabPanel value={selectTab} index={6}>
                        <Grid container my={1}>
                                <Grid item xs={10}>
                                    <Controller
                                            name="dropRegular"
                                            control={control}
                                            render={({ field }: any) => (
                                                <FormControlLabel
                                                    {...field}
                                                    disabled={m_bModePM || modeViewOnly}
                                                    control={<Checkbox style={checkBoxStyles} />} 
                                                    checked={field.value} 
                                                    label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>定常時の電圧降下を計算</Typography>} 
                                                    onClick={(e:any) => {
                                                        setValue(e.target.name,e.target.checked);
                                                        handlePointRegularThird(e)
                                                    }}
                                                    name='dropRegular'
                                                />
                                            )}
                                        />
                                </Grid>
                                <Grid container>
                                    <Grid item xs={7.5}>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                            <Typography variant="body2" width="90%" ml={5}>負荷容量 (VA):</Typography>  
                                                <Controller
                                                    name="loadCapacityThird"
                                                    control={control}
                                                    rules={{
                                                        pattern:{
                                                            value:regexDecimal,
                                                            message:messageErrorNumber,
                                                        },
                                                        validate: value => value >= 0|| "数字 >= 0 を入力してください。"
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            // type="text"
                                                            size="small"
                                                            onFocus={e => e.target.select()}
                                                            error={!!errors?.loadCapacityThird}  
                                                            helperText={errors?.loadCapacityThird?.message}
                                                            inputProps={{readOnly: m_bModePM || modeViewOnly || disableTab6Flag}}
                                                            InputProps={{ 
                                                                sx: { 
                                                                    height: 32,
                                                                    background:m_bModePM || modeViewOnly || disableTab6Flag?'floralwhite':'white',
                                                                    color: m_bModePM || modeViewOnly || disableTab6Flag?'grey':'black',
                                                                }
                                                            }}
                                                            onChange={(event:any)=>{
                                                                field.onChange(event);
                                                                handleChangeLoadCapacity(event,VOLT_SIDE_THIRD);
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                    </Grid>
                                    <Grid item xs ={7.5}>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                            <Typography variant="body2" width="90%" ml={5}>負荷電流 IL(A):</Typography>  
                                            <Controller
                                                name="loadCurrentThird"
                                                control={control}
                                                rules={{
                                                    pattern:{
                                                        value:regexDecimal,
                                                        message:messageErrorNumber,
                                                    },
                                                    validate: value => value >= 0|| "数字 >= 0 を入力してください。"
                                                }}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        // type="text"
                                                        size="small"
                                                        error={!!errors?.loadCurrentThird}
                                                        helperText={errors?.loadCurrentThird?.message}
                                                        inputProps={{readOnly: m_bModePM || modeViewOnly || disableTab6Flag,maxLength:7}}
                                                        InputProps={{ 
                                                            sx: { 
                                                                height: 32,
                                                                background:m_bModePM || modeViewOnly || disableTab6Flag?'floralwhite':'white',
                                                                color: m_bModePM || modeViewOnly || disableTab6Flag?'grey':'black',
                                                            }
                                                        }}
                                                        onFocus={e => e.target.select()}
                                                        onChange={(event:any) => {
                                                            field.onChange(event);
                                                            handleChangeLoadCurrent(event,VOLT_SIDE_THIRD)
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={2.5} ml={1.55}>
                                        <Button
                                            style={{margin:"0"}}
                                            variant="outlined"
                                            disabled={m_bModePM || modeViewOnly || disableTab6Flag}
                                            onClick={()=>{handleClickGetLoadCurrent(VOLT_SIDE_THIRD)}}
                                        >概算値</Button>
                                        {/* <Button variant="outlined" size="small" disabled={disabledTab5Flag} onClick={()=>{handleClickGetLoadCurrent(VOLT_SIDE_SECONDARY)}}>概算値</Button> */}
                                    </Grid>
                                </Grid>
                                <Grid container ml={3}>
                                    <fieldset style={{width:"76%" ,border:'1px solid #AAAA'}}>
                                        <legend><Typography variant="body2">回路の力率</Typography></legend>
                                        <Grid container>
                                            <Grid item xs={5}>
                                                <Controller
                                                    name="isLeadThird"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <RadioGroup {...field} row>
                                                            <FormControlLabel
                                                                onClick={(e:any)=>{
                                                                handleClickIsLead(e,VOLT_SIDE_THIRD)}}
                                                                value={true} 
                                                                disabled={m_bModePM || modeViewOnly || disableTab6Flag} 
                                                                control={<Radio style={radioStyles} />}
                                                                label={<Typography variant="body2" style={{color:m_bModePM || disableTab6Flag?"grey":"black"}}>進み</Typography>}

                                                            />
                                                            <FormControlLabel
                                                                onClick={(e:any)=>{
                                                                handleClickIsLag(e,VOLT_SIDE_THIRD);}}
                                                                value={false} 
                                                                disabled={m_bModePM || modeViewOnly || disableTab6Flag}
                                                                control={<Radio style={radioStyles} />}
                                                                label={<Typography variant="body2" style={{color:m_bModePM || disableTab6Flag?"grey":"black"}}>遅れ</Typography>}
                                                            />
                                                        </RadioGroup>
                                                    )}
                                                />
                                            </Grid>
                                            <Grid xs={1}/>
                                            <Grid xs={2.75} ml={1.5}>
                                                <Controller
                                                    name="powerFactorThird"
                                                    control={control}
                                                    rules={{
                                                        pattern:{
                                                            value:regexDecimal,
                                                            message:messageErrorNumber,
                                                        },
                                                        validate: value => value >= 0 && value <= 1 || ' 0 =< 数字 <= 1  を入力してください。'
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            // type="text"
                                                            size="small"
                                                            error={!!errors?.powerFactorThird}
                                                            helperText={errors?.powerFactorThird?.message}
                                                            inputProps={{readOnly: m_bModePM || modeViewOnly || disableTab6Flag,maxLength:7}}
                                                            InputProps={{ 
                                                                sx: { 
                                                                    height: 32,
                                                                    background:m_bModePM || modeViewOnly || disableTab6Flag?'floralwhite':'white',
                                                                    color: m_bModePM || modeViewOnly || disableTab6Flag?'grey':'black',
                                                                }
                                                            }}
                                                            onFocus={e => e.target.select()}
                                                            onChange={(event:any)=> {
                                                                field.onChange(event);
                                                                handleChangePowerFactor(event,VOLT_SIDE_THIRD)
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={2.5} ml={1.54}>
                                                <Button
                                                    style={{margin:"0"}}
                                                    variant="outlined"
                                                    disabled={m_bModePM || modeViewOnly || disableTab6Flag}
                                                    onClick={(event:any)=>{handleClickGetLoadPowerFactor(VOLT_SIDE_THIRD);}}
                                                >概算値</Button>
                                            </Grid>
                                        </Grid>
                                    </fieldset>
                                </Grid>
                                <Grid container  mt={0.25}>
                                    <Grid container flexDirection={"row"} ml={6} alignItems={"center"}>
                                        <Grid item xs={4} >
                                            <Typography variant="body2" width="100%" >単体での電圧降下(V):</Typography>  
                                        </Grid>
                                        <Grid item xs={4} ml={-2.6}>
                                            <Controller
                                                name="voltDropDiffThird"
                                                control={control}
                                                
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                            fullWidth
                                                            type="text"
                                                            size="small"
                                                            onFocus={e => e.target.select()}
                                                            InputLabelProps={{
                                                                style: { color: 'black' },
                                                            }}
                                                            inputProps={{
                                                                readOnly: true,
                                                            }}
                                                            style={{
                                                                backgroundColor: 'floralwhite',width:"70%",marginLeft:'20%'
                                                            }}
                                                            InputProps={{
                                                                sx:{height:32,color:'grey'}
                                                            }}

                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container flexDirection={"row"} ml={6} mt={0.25} alignItems={"center"}>
                                        <Grid item xs={4} >
                                            <Typography variant="body2" width="100%" >単体での電圧降下率(%):</Typography>  
                                        </Grid>
                                        <Grid item xs={4} ml={-2.6}>
                                            <Controller
                                                name="voltDropPerUnitThird"
                                                control={control}
                                                
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        onFocus={e => e.target.select()}
                                                        InputLabelProps={{
                                                            style: { color: 'black' },
                                                        }}
                                                        inputProps={{
                                                            readOnly: true,
                                                        }}
                                                        style={{
                                                            backgroundColor: 'floralwhite',width:"70%",marginLeft:'20%'
                                                        }}
                                                        InputProps={{
                                                            sx:{height:32,color:'grey'}
                                                        }}
                                                        

                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </TabPanel>

                        {/* tab7 */}
                        <TabPanel value={selectTab} index={7}>
                        <Stack mt={1} width={'75%'}>
                            <fieldset style={{border:'1px solid #AAAA'}}>
                                <legend ><Typography variant="body2">単線結線図で表示するシンボルの選択</Typography></legend>
                                <Grid container>
                                    <Grid item xs={5}>
                                        <Controller
                                            name='symKind'
                                            control={control}
                                            render={({ field }) => (
                                                <RadioGroup {...field}>
                                                    <FormControlLabel 
                                                        value='0' 
                                                        disabled={m_bModePM || modeViewOnly}
                                                        control={<Radio size='small'/>} 
                                                        label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>無し</Typography>}
                                                    />
                                                    <FormControlLabel 
                                                        value='1' 
                                                        disabled={m_bModePM || modeViewOnly}
                                                        control={<Radio size='small'/>} 
                                                        label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>Ｙ／Ｙ／△</Typography>}
                                                    />
                                                    <FormControlLabel 
                                                        value='2' 
                                                        disabled={m_bModePM || modeViewOnly}
                                                        control={<Radio size='small'/>} 
                                                        label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>Ｙ／△／Ｙ</Typography>}
                                                    />
                                                    <FormControlLabel 
                                                        value='3' 
                                                        disabled={m_bModePM || modeViewOnly}
                                                        control={<Radio size='small'/>} 
                                                        label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>Ｙ／△／△</Typography>}
                                                    />
                                                    <FormControlLabel 
                                                        value='4' 
                                                        disabled={m_bModePM || modeViewOnly}
                                                        control={<Radio size='small'/>} 
                                                        label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>Ｙ／△／Yo</Typography>}
                                                    />
                                                    <FormControlLabel 
                                                        value='5' 
                                                        disabled={m_bModePM || modeViewOnly}
                                                        control={<Radio size='small'/>} 
                                                        label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>△／△／Ｙ</Typography>}
                                                    />
                                                    <FormControlLabel 
                                                        value='6' 
                                                        disabled={m_bModePM || modeViewOnly}
                                                        control={<Radio size='small'/>} 
                                                        label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>△／△／△</Typography>}
                                                    />
                                                    <FormControlLabel 
                                                        value='7' 
                                                        disabled={m_bModePM || modeViewOnly}
                                                        control={<Radio size='small'/>} 
                                                        label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>△／△／Yo</Typography>}
                                                    />
                                                    
                                                </RadioGroup>
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </fieldset>
                        </Stack>
                        </TabPanel>
                    </div>
                    <Stack direction='row' justifyContent="flex-end" spacing={2} mt={2}>
                        <Button variant="contained" type="submit" disabled={!isEnableOkButton || modeViewOnly}>OK</Button>
                        <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                    </Stack>
                </form >
        </>
    );
};
export default Ms3WindingEditDialog;


