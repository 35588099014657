import { Button, Dialog, DialogContent, DialogTitle, Stack, TextField } from "@mui/material";
import theme from "../../themes/globalStyles";
import DraggablePaper from "../common/DraggablePaper";

export type ImportProjectProps = {
    onOK: () => void;
    onCancel: () => void;
    handleFileSelect: () => void;
    selectedFile: File | null;
};

export const ImportProjectDialog = (props: ImportProjectProps) => {

    const { onOK, onCancel, handleFileSelect, selectedFile } = props;

    return (
        <>
            <Dialog open={true} maxWidth={"sm"} fullWidth
                PaperComponent={
                    DraggablePaper
                }
            >
                <DialogTitle
                    onMouseOver={(e: any) => e.target.style.cursor = 'move'}
                    style={{ paddingTop: '2px', paddingBottom: '2px', background: theme.palette.primary.main, color: theme.palette.primary.contrastText }}
                >
                    {`プロジェクトインポート`}
                </DialogTitle>
                <DialogContent style={{ paddingTop: '10px', height: '150px', display: 'flex', alignItems: 'flex-end' }}>
                    <div style={{ width: '100%' }}>
                        <Stack direction='row' gap={2} sx={{ mt: 4 }}>
                            <TextField
                                fullWidth
                                value={selectedFile ? selectedFile.name : ''}
                                type='text'
                                size='small'
                                InputLabelProps={{ style: { color: 'black' } }}
                                inputProps={{ readOnly: true }}
                                style={{ backgroundColor: 'floralwhite' }}
                            />
                            <Button
                                variant="contained"
                                onClick={handleFileSelect}
                            >
                                選択
                            </Button>
                        </Stack>
                        <Stack direction='row' justifyContent="flex-end" gap={2} sx={{ mt: 4 }}>
                            <Button variant="contained" onClick={onOK} disabled={!selectedFile}>実行</Button>
                            <Button variant="outlined" onClick={onCancel}>キャンセル</Button>
                        </Stack>

                    </div>
                </DialogContent>
            </Dialog>
        </>
    )
}
export default ImportProjectDialog;