import { ControlModel, MsArrestorModel, ProcessMode } from "../models/Index";

//CEleArrestor::DoGetTextVoltage
export const doGetTextVoltage = (props:MsArrestorModel, bUnit:boolean = true) =>
{
	let sText = props.voltage.toString();
	if (bUnit) {
		sText += 'V';
	}
	return sText;
}

//CEleArrestor::DoGetRefV1
export const doGetRefV1 = (mode:any, props:any) =>
{
	//CEleArrestor::DoGetRefV1Left  sRefV1Left
	let labelTexts = [{
		value: props.refNo,
		color: "#f800f8",
	}];

    switch(mode) {
        case ProcessMode.DRAWING:
        case ProcessMode.CHECKING:
        case ProcessMode.PERCENT_Z:
        case ProcessMode.CALCULATION:
        case ProcessMode.VOLTDROP:
        case ProcessMode.HARMONIC:
          break;
    }

    return labelTexts;
}