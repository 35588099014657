export const getFontStyleValue = (weight : number, italic : boolean) => {
    if (weight === 400 && !italic) return `normal`
    if (weight === 400 && italic) return `italic`
    if (weight === 700 && !italic) return `bold`
    if (weight === 700 && italic) return `bold italic`
    return `normal`
}

export const getTextDecoration = (isUnderline: boolean, isLineThrough: boolean) => {
    let textDecorations = [];
    isUnderline && textDecorations.push('underline');
    isLineThrough && textDecorations.push('line-through');
    return textDecorations.join(' ');
}

