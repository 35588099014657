import { Button, CircularProgress, Grid, Stack, TextField, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import { useForm } from 'react-hook-form';
import { connect } from "react-redux";
import { PageData } from "../../../models/Index";
import { ApplicationState, FetchState, actionCreators, selectors } from "../../../store";
import FetchHelper from '../../FetchHelper';
import { ManufactureModel } from '../../../models/breakModel';
import ManufactureGrid from './ManufactureGrid';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Path as RoutePath } from "../../../path";
import { useNavigate } from 'react-router-dom';
import AlertDialog from '../../common/AlertDialog';

// -------------
// FETCH ID
const SEARCH_MANUFACTURE = "SEARCH_MANUFACTURE";
const DELETE_MANUFACTURE = "DELETE_MANUFACTURE";

// 検索フォーム
export interface SearchItemForm {
    "target": string;
    "limit": number;
    "page": number;
}

// 初期値設定
const initialValue: SearchItemForm = {
    target: '',
    limit: 50,
    page: 1,
}

const initialPageData: PageData = {
    rowFrom: '0',
    rowTo: '0',
    totalRow: '0',
    currPage: '0',
    totalPage: '0',
};

const initialManufacture: ManufactureModel = {
    lManufactureID: 0,
    bIsDefSource: false,
    bIsDefHVCB: false,
    bIsDefLVCB: false,
    bIsDefThermal: false,
    bIsDefFuse: false,
    bIsDef2E: false,
    sManuName: '',
    sManuKana: '',
}

// -------------
// Props
export type ManufactureListProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

// -------------
// Component
export const ManufactureList = (props: ManufactureListProps) => {
    const {
        userId,
        onSearch,
        onDelete,
        onError,
        onDeleteSuccess,
        onDeleteError
    } = props;

    const { control, handleSubmit, setValue, getValues } = useForm<SearchItemForm>({
        criteriaMode: "all",
        defaultValues: initialValue,
    });
    const [openSearch, setOpenSearch] = React.useState(false);
    const [title, setTitle] = React.useState("");
    const [message, setMessage] = React.useState("");
    const [pageData, setPageData] = React.useState(initialPageData);
    const [listData, setListData] = React.useState<ManufactureModel[]>([]);
    const [openAddDialog, setOpenAddDialog] = React.useState(false);
    const [openEditDialog, setOpenEditDialog] = React.useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
    const [selectData, setSelectData] = React.useState<ManufactureModel>(initialManufacture);
    const [disableEdit, setDisableEdit] = React.useState(true);
    const [disableDelete, setDisableDelete] = React.useState(true);
    const [colState, setColState] = useState<any>();
    const gridRef = useRef() as any;
    const [loadingFlag, setLoadingFlag] = React.useState(true);
    const [keyword, setKeyword] = useState("");

    const [idDelete, setIdDelete] = React.useState("")

    let navigate = useNavigate();

    // 検索実行
    const handleSearchSubmit = (value: SearchItemForm) => {

        const params = new Map<string, any>();
        if (value.limit) params.set("limit", value.limit);
        params.set("page", value.page);

        setLoadingFlag(true);
        onSearch({
            "userId": userId,
            "requestPrms":{
                limit : value.limit,
                page: value.page,   
                  }
        });
    }

    // 初回検索実行
    React.useEffect(() => {
        handleSearchSubmit(getValues());
        // eslint-disable-next-line
    }, []);


    const handleSuccess = (data: any) => {
        console.log('>>> handleSuccess');
        if (data && data.results) {
            if (gridRef && gridRef.current) {
                gridRef.current.setRowData(data.results);
            }
            setListData(data.results);

            const totalCount = data.totalCount;
            const pageSize = getValues('limit');
            const page = Number(data.page);
            const totalPage = Math.ceil(totalCount / pageSize);
            const rowFrom = (pageSize * page - pageSize) + 1;
            const rowTo = (pageSize * page) > totalCount ? totalCount : (pageSize * page);

            const updateData: PageData = {
                rowFrom: rowFrom.toLocaleString(),
                rowTo: rowTo.toLocaleString(),
                totalRow: totalCount.toLocaleString(),
                currPage: page.toLocaleString(),
                totalPage: totalPage.toLocaleString(),
            };

            setPageData(updateData);
        } else {
            if (gridRef && gridRef.current) {
                gridRef.current.setRowData([]);
            }
            setListData(() => [...[] as any]);
        }

        setLoadingFlag(false);
    };

    // 検索エラー時
    const handleError = (success: boolean, data: any, error: any) => {
        console.log('>>> handleError');
        onError(success, data, error);
        setLoadingFlag(false);
        gridRef.current.hideOverlay();
    };

    

    // ページ数変更時にAPI検索する
    const handleChangePageData = (name: any, value: any) => {
        // 値変更
        setValue(name, value);
        if (name === 'limit') {
            setValue("page", 1);
        }
        handleSearchSubmit(getValues());
    };

    // 追加の処理
    const handleAdd = () => {
        navigate(RoutePath.ManufactureEdit + `/add`)
    }

    //削除の処理
    const handleDelete = (data: any) => {
        setIdDelete(data);
        const msg = "ID: " + data + "を削除します。"
        setTitle("メーカー情報削除");
        setMessage(msg);
        setOpenDeleteDialog(true);
    }

    // 削除確認ダイアログでOKボタンのイベントを受け取って、APIに送信する
    const handleDeleteOK = () => {
        setLoadingFlag(true);
        setOpenDeleteDialog(false);
        onDelete({
            userId: userId,
            requestPrms:{
                    id :idDelete
        }
    });
        setIdDelete("");
    }

    // 削除確認ダイアログでCancelボタンのイベントを受け取って、ダイアログを無効にする
    const handleDeleteCancel = () => {
        setIdDelete("");
        setOpenDeleteDialog(false);
    }

    const handleSuccessDelete = (data: any) => {
        setLoadingFlag(false);
        console.log('>>> handleSuccessDelete');
        onDeleteSuccess()
        handleSearchSubmit(getValues());
    };

    // 検索エラー時
    const handleErrorDelete = (success: boolean, data: any, error: any) => {
        setLoadingFlag(false);
        console.log('>>> handleErrorDelete');
        onDeleteError()
    };

    //削除の処理
    const handleShowDetailPage = (id: any) => {
        navigate(RoutePath.ManufactureEdit + `/edit/${id}`)
    }

    return (
        <>
            {loadingFlag && (
                <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1500, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                    <CircularProgress />
                </div>
            )}
            {openDeleteDialog &&
                <AlertDialog title={title} message={message} isOpen={true} onOK={handleDeleteOK} onCancel={handleDeleteCancel} />
            }
            {/* {openAddDialog ?
                <Dialog open={openAddDialog} maxWidth={"md"} fullWidth style={{ zIndex: 1200 }}>
                    <DialogTitle>{"お客様追加"}</DialogTitle>
                    <DialogContent sx={{ pb: 1.5 }}>
                        <ManufactureEdit
                            manufacture={selectData}
                            mode={strInsert}
                            onOK={handleInsert}
                            onCancel={handleOpenAddDialogCancel}>
                        </ManufactureEdit>
                    </DialogContent>
                </Dialog>
                :
                <div />
            }
            {openEditDialog ?
                <Dialog open={openEditDialog} maxWidth={"md"} fullWidth style={{ zIndex: 1200 }}>
                    <DialogTitle>{"お客様編集"}</DialogTitle>
                    <DialogContent sx={{ pb: 1.5 }}>
                        <ManufactureEdit
                            manufacture={selectData}
                            mode={strUpdate}
                            onOK={handleUpdate}
                            onCancel={handleOpenEditDialogCancel}>
                        </ManufactureEdit>
                    </DialogContent>
                </Dialog>
                :
                <div />
            } */}
            <div style={{ height: '100%', width: '100%', marginTop: '10px' }}>
                <Stack direction="row" justifyContent="flex-start" alignItems="center" sx={{}} style={{ margin: '18px', color: 'MenuText' }}>
                    <Typography variant="h4">
                        メーカー
                    </Typography>
                </Stack>
                <form onSubmit={handleSubmit(handleSearchSubmit)}>
                    {/* <Stack spacing={1} mb="10px" style={{ border: openSearch ? "2px solid gray" : '', borderRadius: "15px" }}>
                        <div style={{ cursor: 'pointer', display: 'inline-block', marginLeft: '10px' }} onClick={() => { handleOpen(setOpenSearch); }}>
                            <div style={{ display: 'inline-block' }}>
                                <Typography variant="h6" >検索条件</Typography>
                            </div>
                            <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                                {
                                    openSearch ? <ArrowDropDownIcon /> : <ArrowRightIcon />
                                }
                            </div>
                        </div>
                        <div style={{ marginLeft: "10px", marginRight: "10px", marginBottom: "10px" }}>
                        </div>
                    </Stack> */}
                </form>
                <div style={{ justifyContent: 'flex-end', display: 'flex' }}>
                    <Stack direction='row-reverse' mb={1}>
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={handleAdd}
                            startIcon={<AddCircleOutlineIcon />}>
                            メーカー追加
                        </Button>
                    </Stack>
                </div>
                <div style={{ marginBottom: "10px" }}>
                    <Stack direction="row">
                        <div style={{ marginInlineStart: 'auto' }}>
                            <Grid container justifyContent="flex-start" alignItems={"center"} direction="row" spacing={1} >
                                <Grid item>
                                    <TextField
                                        fullWidth
                                        autoComplete="searchStr"
                                        name="keyword"
                                        type="text"
                                        label="フィルター"
                                        size="small"
                                        value={keyword}
                                        onChange={e => setKeyword(e.target.value)}
                                    />
                                </Grid>
                                <Grid item>
                                    <Button variant="outlined" onClick={e => setKeyword("")}>クリア</Button>
                                </Grid>
                            </Grid>
                        </div>
                    </Stack>
                </div>
                <div>
                    <ManufactureGrid
                        ref={gridRef}
                        colState={colState}
                        pageSize={getValues('limit')}
                        pageData={pageData}
                        onChangeSearchCondition={handleChangePageData}
                        keyword={keyword}
                        onShowDetails={handleShowDetailPage}
                        onDelete={handleDelete}
                    />
                </div>
            </div>
            <FetchHelper fetchId={SEARCH_MANUFACTURE} onComplete={(success, data, error) =>
                success ? handleSuccess(data) : handleError(success, data, error)} />
            <FetchHelper fetchId={DELETE_MANUFACTURE} onComplete={(success, data, error) =>
                success ? handleSuccessDelete(data) : handleErrorDelete(success, data, error)} />
        </>
    );
};

const mapStateToProps = (state: ApplicationState) => ({
    userId: state.app.user?.userId,
    fetchState: selectors.getFetchState(
        state,
        SEARCH_MANUFACTURE
    ) as FetchState,
});

const mapDispatchToProps = (dispatch: any) => {
    return {
        onSearch: (params: any) => dispatch(actionCreators.fetch(SEARCH_MANUFACTURE, `/master/manufacture/gets`, "POST", params,false,true)),
        onDelete: (id: any) => dispatch(actionCreators.fetch(DELETE_MANUFACTURE, `/master/manufacture/delete`, "POST", id,false,true)),
        onDeleteSuccess: () =>
            dispatch(actionCreators.showMessage({
                type: "info",
                title: "メーカー削除",
                body: "メーカーを削除しました。",
            })),
        onError: (success: boolean, data: any, error: any) =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "検索",
                body: "検索できません。" + error,
            })),
        onDeleteError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "メーカー削除",
                body: "メーカーを削除できませんでした。",
            })),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ManufactureList);
