import Dialog from '@mui/material/Dialog'
import { Box, IconButton, Stack } from '@mui/material'
import { Close } from '@mui/icons-material'

export type PreviewDialogProps = {
  refIframe: any;
  openDialog: boolean;
  setOpenDialog: (value: boolean) => void;
}

export const PreviewDialog = (props: PreviewDialogProps) => {
  const { refIframe, openDialog, setOpenDialog } = props
  
  return (
    <Dialog open={openDialog} maxWidth={"md"} fullWidth>
      <Stack height={"24px"} direction="row" justifyContent={"flex-end"} mt={0.5}>
        <IconButton aria-label="fingerprint" color="error" onClick={()=>setOpenDialog(false)}>
          <Close />
        </IconButton>
      </Stack>
      <Stack style={{height:"80vh"}}>
        <Box sx={{height:"100%"}}>
          <iframe ref={refIframe} height="98%" width="99.5%"></iframe>
        </Box>
      </Stack>
    </Dialog>
  )
}

export default PreviewDialog