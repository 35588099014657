import { Button } from '@mui/material';
import { Html } from 'react-konva-utils';
import { connect } from "react-redux";
import './styles.css';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { useState } from 'react';
import { Coordinates } from '../../models/Index';
import * as EKind from "../../models/ElementKind";

//#region Props
type ControlMenuCoordinates = {
    x: number,
    y: number,
    offsetX: number,
    offsetY: number,
    lineDiagramRef: any,
    onCreate: (key: string) => void,
    onPaste: () => void,
    isDisabledCopy: boolean
};

export type ControlMenuProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & ControlMenuCoordinates;
//#endregion


const ControlMenu = (props: ControlMenuProps) => {
    //#region Fields
    const { x, y, offsetX, offsetY, lineDiagramRef, isDisabledCopy } = props;
    const { onCreate, onPaste } = props;
    const [beyondWidth] = useState<boolean>(x > 200 - offsetX)
    const [beyondHeight] = useState<boolean>(y + 285 > lineDiagramRef.current.clientHeight - offsetY)
    const [position] = useState<Coordinates>({
        x: beyondWidth ? x - 200 : x,
        y: beyondHeight ? y - 235 : y,
    });

    const style = {
        justifyContent: "flex-start",
        padding: '4px 5px',
        "&.Mui-disabled": { color: "#a6a6a6" },
        "&:hover": { backgroundColor: "#e6f7ff" }
    }
    //#endregion

    const onContextMenu = (e: any) => {
        e.preventDefault()
    }

    return (
        <>
            <Html>
                <div
                    onContextMenu={onContextMenu}
                    style={{
                        position: "fixed",
                        top: position.y,
                        left: position.x,
                        backgroundColor: "white",
                        zIndex: 9999,
                        width: '200px',
                        boxShadow: '5px 5px 10px grey',
                        border: "0.7px solid grey",
                        textAlign: 'left'
                    }}
                >
                    <Button onClick={onPaste} disabled = {isDisabledCopy} fullWidth color='inherit' sx={style}>貼り付け</Button>
                    <hr className='menu-option-hr-style' />
                    <Button onClick = {() => onCreate(EKind.MS_TEXT)} fullWidth color='inherit' sx={style}>テキストボックスの配置</Button>
                    <hr className='menu-option-hr-style' />
                    <div style={{ display: 'flex', cursor: 'pointer' }} className='parent'>
                        <div>
                            <Button fullWidth color='inherit' sx={style}>電源機器の配置</Button>
                        </div>
                        <div style={{ marginLeft: 'auto', marginBlockStart: 'auto' }}>
                            <ArrowRightIcon />
                        </div>
                        <div
                            onContextMenu={onContextMenu}
                            style={{
                                position: "fixed",
                                top:  y + 85 > lineDiagramRef.current.clientHeight ? position.y - 100 : position.y + 69,
                                left: x + 700 > lineDiagramRef.current.clientWidth ? position.x - 200 : position.x + 201,
                                backgroundColor: "white",
                                zIndex: 9999,
                                width: '200px',
                                boxShadow: '5px 5px 10px grey',
                                border: "0.7px solid grey",
                                textAlign: 'left'
                            }}
                            className='child'
                        >
                            <Button onClick = {() => onCreate(EKind.MS_SOURCE)} fullWidth color='inherit' sx={style}>電源</Button>
                            <Button onClick = {() => onCreate(EKind.MS_GENERATOR)}fullWidth color='inherit' sx={style}>発電機</Button>
                            <hr className='menu-option-hr-style' />
                            <Button onClick = {() => onCreate(EKind.MS_TRANS3)} fullWidth color='inherit' sx={style}>三相変圧器</Button>
                            <Button onClick = {() => onCreate(EKind.MS_3WINDING)} fullWidth color='inherit' sx={style}>三巻線変圧器</Button>
                            <hr className='menu-option-hr-style' />
                            <Button onClick = {() => onCreate(EKind.MS_TRANS1)} fullWidth color='inherit' sx={style}>単相変圧器</Button>
                            <Button onClick = {() => onCreate(EKind.MS_TRANSSCOTT)} fullWidth color='inherit' sx={style}>スコット結線変圧器</Button>
                        </div>
                    </div>
                    <div style={{ display: 'flex', cursor: 'pointer' }} className='parent'>
                        <div>
                            <Button fullWidth color='inherit' sx={style}>電路機器の配置</Button>
                        </div>
                        <div style={{ marginLeft: 'auto', marginBlockStart: 'auto' }}>
                            <ArrowRightIcon />
                        </div>
                        <div
                            onContextMenu={onContextMenu}
                            style={{
                                position: "fixed",
                                top: y > 540 ? (lineDiagramRef.current.clientHeight > 640 ? position.y - 300  : position.y + 102) : lineDiagramRef.current.clientHeight - 650,
                                left: x > 540 ? position.x - 200 : position.x + 201,
                                backgroundColor: "white",
                                zIndex: 9999,
                                width: '200px',
                                boxShadow: '5px 5px 10px grey',
                                border: "0.7px solid grey",
                                textAlign: 'left'
                            }}
                            className='child'
                        >
                            <Button onClick = {() => onCreate(EKind.MS_DS)} fullWidth color='inherit' sx={style}>断路器</Button>
                            <Button onClick = {() => onCreate(EKind.MS_LBS)} fullWidth color='inherit' sx={style}>開閉器</Button>
                            <Button onClick = {() => onCreate(EKind.MS_MVCB)} fullWidth color='inherit' sx={style}>遮断器</Button>
                            <Button onClick = {() => onCreate(EKind.MS_CONTACTOR)} fullWidth color='inherit' sx={style}>電磁接触器</Button>
                            <hr className='menu-option-hr-style' />
                            <Button onClick = {() => onCreate(EKind.MS_HVCB)} fullWidth color='inherit' sx={style}>過電流継電器</Button>
                            <Button onClick = {() => onCreate(EKind.MS_FUSE)} fullWidth color='inherit' sx={style}>高圧ヒューズ</Button>
                            <Button onClick = {() => onCreate(EKind.MS_2E)} fullWidth color='inherit' sx={style}>電動機保護リレー</Button>
                            <hr className='menu-option-hr-style' />
                            <Button onClick = {() => onCreate(EKind.MS_ZEROCOND)} fullWidth color='inherit' sx={style}>母線</Button>
                            <Button onClick = {() => onCreate(EKind.MS_CONNECT)} fullWidth color='inherit' sx={style}>接続線</Button>
                            <hr className='menu-option-hr-style' />
                            <Button onClick = {() => onCreate(EKind.MS_WIRE)} fullWidth color='inherit' sx={style}>電線</Button>
                            <Button onClick = {() => onCreate(EKind.MS_BUSBAR)} fullWidth color='inherit' sx={style}>ブスバー</Button>
                            <Button onClick = {() => onCreate(EKind.MS_BUSDUCT)} fullWidth color='inherit' sx={style}>バスダクト</Button>
                            <Button onClick = {() => onCreate(EKind.MS_REACTOR)} fullWidth color='inherit' sx={style}>限流リアクトル</Button>
                            <Button onClick = {() => onCreate(EKind.MS_IMPEDANCE)} fullWidth color='inherit' sx={style}>インピーダンス</Button>
                            <hr className='menu-option-hr-style' />
                            <Button onClick = {() => onCreate(EKind.MS_LVCB)} fullWidth color='inherit' sx={style}>低圧遮断器</Button>
                            <Button onClick = {() => onCreate(EKind.MS_THERMAL)} fullWidth color='inherit' sx={style}>サーマルリレー</Button>
                            <Button onClick = {() => onCreate(EKind.MS_THERMAL_CT)} fullWidth color='inherit' sx={style}>過負荷継電器</Button>
                        </div>
                    </div>
                    <div style={{ display: 'flex', cursor: 'pointer' }} className='parent'>
                        <div>
                            <Button fullWidth color='inherit' sx={style}>負荷類機器の配置</Button>
                        </div>
                        <div style={{ marginLeft: 'auto', marginBlockStart: 'auto' }}>
                            <ArrowRightIcon />
                        </div>
                        <div
                            onContextMenu={onContextMenu}
                            style={{
                                position: "fixed",
                                top: y + 410 > lineDiagramRef.current.clientHeight ? position.y - 65 : position.y + 134,
                                left: x + 700 > lineDiagramRef.current.clientWidth ? position.x - 200 : position.x + 201,
                                backgroundColor: "white",
                                zIndex: 9999,
                                width: '200px',
                                boxShadow: '5px 5px 10px grey',
                                border: "0.7px solid grey",
                                textAlign: 'left'
                            }}
                            className='child'
                        >
                            <Button onClick = {() => onCreate(EKind.MS_MOTOR_GROUP)} fullWidth color='inherit' sx={style}>電動機群</Button>
                            <hr className='menu-option-hr-style' />
                            <Button onClick = {() => onCreate(EKind.MS_MOTOR)} fullWidth color='inherit' sx={style}>電動機</Button>
                            <Button onClick = {() => onCreate(EKind.MS_LOAD)} fullWidth color='inherit' sx={style}>負荷</Button>
                            <Button onClick = {() => onCreate(EKind.MS_LIGHTBOARD)} fullWidth color='inherit' sx={style}>分電盤</Button>
                            <Button onClick = {() => onCreate(EKind.MS_POWERBOARD)} fullWidth color='inherit' sx={style}>動力盤</Button>
                            <Button onClick = {() => onCreate(EKind.MS_CAPACITOR)} fullWidth color='inherit' sx={style}>コンデンサ</Button>
                            <hr className='menu-option-hr-style' />
                            <Button onClick = {() => onCreate(EKind.MS_TRANSCENTER)} fullWidth color='inherit' sx={style}>変台</Button>
                        </div>
                    </div>
                    {/* <div style={{ display: 'flex', cursor: 'pointer' }} className='parent'>
                        <div>
                            <Button fullWidth color='inherit' sx={style}>横向き機器の配置</Button>
                        </div>
                        <div style={{ marginLeft: 'auto', marginBlockStart: 'auto' }}>
                            <ArrowRightIcon />
                        </div>
                        <div
                            onContextMenu={onContextMenu}
                            style={{
                                position: "fixed",
                                top: y + 810 > lineDiagramRef.current.clientHeight ? lineDiagramRef.current.clientHeight - 650 : position.y + 166,
                                left: x + 700 > lineDiagramRef.current.clientWidth ? position.x - 200 : position.x + 201,
                                backgroundColor: "white",
                                zIndex: 9999,
                                width: '200px',
                                boxShadow: '5px 5px 10px grey',
                                border: "0.7px solid grey",
                                textAlign: 'left'
                            }}
                            className='child'
                        >
                            <Button fullWidth color='inherit' sx={style}>左向き断路器</Button>
                            <Button fullWidth color='inherit' sx={style}>右向き断路器</Button>
                            <hr className='menu-option-hr-style' />
                            <Button fullWidth color='inherit' sx={style}>左向き開閉器</Button>
                            <Button fullWidth color='inherit' sx={style}>右向き開閉器</Button>
                            <hr className='menu-option-hr-style' />
                            <Button fullWidth color='inherit' sx={style}>左向き遮断器</Button>
                            <Button fullWidth color='inherit' sx={style}>右向き遮断器</Button>
                            <hr className='menu-option-hr-style' />
                            <Button fullWidth color='inherit' sx={style}>左向き電磁接触器</Button>
                            <Button fullWidth color='inherit' sx={style}>右向き電磁接触器</Button>
                            <hr className='menu-option-hr-style' />
                            <Button fullWidth color='inherit' sx={style}>左向き高圧ヒューズ</Button>
                            <Button fullWidth color='inherit' sx={style}>右向き高圧ヒューズ</Button>
                            <hr className='menu-option-hr-style' />
                            <Button fullWidth color='inherit' sx={style}>横向き接続線</Button>
                            <hr className='menu-option-hr-style' />
                            <Button fullWidth color='inherit' sx={style}>横向き電線</Button>
                            <Button fullWidth color='inherit' sx={style}>横向きブスバー</Button>
                            <Button fullWidth color='inherit' sx={style}>横向きバスダクト</Button>
                            <Button fullWidth color='inherit' sx={style}>横向き限流リアクトル</Button>
                            <Button fullWidth color='inherit' sx={style}>横向きインピーダンス</Button>
                            <hr className='menu-option-hr-style' />
                            <Button fullWidth color='inherit' sx={style}>左向き低圧遮断器</Button>
                            <Button fullWidth color='inherit' sx={style}>右向き低圧遮断器</Button>
                        </div>
                    </div> */}
                    <div style={{ display: 'flex', cursor: 'pointer' }} className='parent'>
                        <div>
                            <Button fullWidth color='inherit' sx={style}>オプション機器の配置</Button>
                        </div>
                        <div style={{ marginLeft: 'auto', marginBlockStart: 'auto' }}>
                            <ArrowRightIcon />
                        </div>
                        <div
                            onContextMenu={onContextMenu}
                            style={{
                                position: "fixed",
                                top: y + 470 > lineDiagramRef.current.clientHeight ? position.y - 2: position.y + 140,
                                left: x + 700 > lineDiagramRef.current.clientWidth ? position.x - 200 : position.x + 201,
                                backgroundColor: "white",
                                zIndex: 9999,
                                width: '200px',
                                boxShadow: '5px 5px 10px grey',
                                border: "0.7px solid grey",
                                textAlign: 'left'
                            }}
                            className='child'
                        >
                            <Button onClick = {() => onCreate(EKind.MS_VCT)} fullWidth color='inherit' sx={style}>計器用変成器</Button>
                            <Button onClick = {() => onCreate(EKind.MS_VT)} fullWidth color='inherit' sx={style}>計器用変圧器</Button>
                            <hr className='menu-option-hr-style' />
                            <Button onClick = {() => onCreate(EKind.MS_ARRESTOR)} fullWidth color='inherit' sx={style}>避雷器</Button>
                            <Button onClick = {() => onCreate(EKind.MS_EARTH)} fullWidth color='inherit' sx={style}>接地</Button>
                            <hr className='menu-option-hr-style' />
                            <Button onClick = {() => onCreate(EKind.MS_CABLEHEAD)} fullWidth color='inherit' sx={style}>ケーブルヘッド</Button>
                            <hr className='menu-option-hr-style' />
                            <Button onClick = {() => onCreate(EKind.MS_CT)} fullWidth color='inherit' sx={style}>変流器</Button>
                            <Button onClick = {() => onCreate(EKind.MS_ZCT)} fullWidth color='inherit' sx={style}>零相変流器</Button>
                        </div>
                    </div>
                </div>


            </Html>
        </>
    );
};
const mapStateToProps = (state: any) => {
    return {
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ControlMenu);