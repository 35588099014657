import { ControlModel } from "../models/Index"
import * as EKind from "../models/ElementKind"
import * as CONSTANTS from "../models/Constants"
import { doGetCircuitVolt, isSPhaseCircuitEle } from "./ElementFunction"
import { VOLT_SIDE_PRIMARY, VOLT_SIDE_SECONDARY, VOLT_SIDE_THIRD } from "../statics"

interface SourceVoltageResult {
  voltage: number,
  sPhase?: boolean,
  upTransKind?: string
}

interface ResultData {
  code: number,
  data: any,
  message?: string
}



// ----------- doGetSourceVolt ----------- //
const typeSourceArray = [
  EKind.MS_SOURCE,
  EKind.MS_GENERATOR,
  EKind.MS_TRANS3,
  EKind.MS_TRANS1,
  EKind.MS_TRANSSCOTT,
  EKind.MS_ZEROCOND,
  EKind.MS_3WINDING
]

// CANNOT connect to the top
const notConnectTopArray = [
  EKind.MS_SOURCE,
  EKind.MS_GENERATOR
]

// CANNOT connect to the bottom
const notConnectBottomArray = [
  EKind.MS_MOTOR_GROUP,
  EKind.MS_MOTOR,
  EKind.MS_LOAD,
  EKind.MS_LIGHTBOARD,
  EKind.MS_POWERBOARD,
  EKind.MS_CAPACITOR,
  EKind.MS_TRANSCENTER,
  // EKind.MS_ARRESTOR,
  EKind.MS_EARTH
]

export default function doGetSourceVolt(
  target: ControlModel,
  diagramData: ControlModel[],
  isGetSPhase: boolean = false
) {
  let topControl = getTopControl(target, diagramData)
  while (topControl.code === 0) {
    let control: ControlModel = topControl.data
    if (typeSourceArray.includes(control.type)) {
      return getSourceData(control, VOLT_SIDE_SECONDARY, isGetSPhase)
    }
    
    topControl = getTopControl(control, diagramData)
  }

  let bottomControl = getBottomControl(target, diagramData)
  while (bottomControl.code === 0) {
    let control: ControlModel = bottomControl.data
    if (typeSourceArray.includes(control.type)) {
      return getSourceData(control, VOLT_SIDE_PRIMARY, isGetSPhase)
    }
    
    bottomControl = getBottomControl(control, diagramData)
  }
  
  let leftControl = getLeftControl(target, diagramData)
  while (leftControl.code === 0) {
    let control: ControlModel = leftControl.data
    if (typeSourceArray.includes(control.type)) {
      if (control.type === EKind.MS_3WINDING)
        return getSourceData(control, VOLT_SIDE_SECONDARY, isGetSPhase)

      return getSourceData(control, VOLT_SIDE_PRIMARY, isGetSPhase)
    }
    
    leftControl = getLeftControl(control, diagramData)
  }
  
  let rightControl = getRightControl(target, diagramData)
  while (rightControl.code === 0) {
    let control: ControlModel = rightControl.data
    if (typeSourceArray.includes(control.type)) {
      if (control.type === EKind.MS_3WINDING)
        return getSourceData(control, VOLT_SIDE_THIRD, isGetSPhase)

      return getSourceData(control, VOLT_SIDE_PRIMARY, isGetSPhase)
    }
    
    rightControl = getRightControl(control, diagramData)
  }

  let ret
  if (isGetSPhase)
    ret = { voltage: 0, sPhase: false, upTransKind: EKind.MS_ELEMENT_ALL } as SourceVoltageResult
  else
    ret = { voltage: 0 } as SourceVoltageResult

  return ret
}

const getSourceData = (
  control: ControlModel,
  voltSide: number,
  isGetSPhase: boolean
) => {
  let ret = { voltage: doGetCircuitVolt(control, voltSide) } as SourceVoltageResult

  if (isGetSPhase) {
    let tmpData = isSPhaseCircuitEle(control, voltSide)
    ret = { ...ret, ...tmpData } as SourceVoltageResult
  }
  return ret
}

// ----------- DoCalcDemandCapacity ----------- //
const breakTypeArray = [
  EKind.MS_SOURCE,
  EKind.MS_GENERATOR,
  EKind.MS_TRANS1,
  EKind.MS_TRANS3,
  EKind.MS_3WINDING,
  EKind.MS_TRANSSCOTT,
  EKind.MS_BUSDUCT,
  EKind.MS_IMPEDANCE,
  EKind.MS_BUSBAR,
  EKind.MS_WIRE,
  EKind.MS_REACTOR,

  EKind.MS_MOTOR,
  EKind.MS_MOTOR_GROUP,
  EKind.MS_LOAD,
  EKind.MS_LIGHTBOARD,
  EKind.MS_POWERBOARD,

  EKind.MS_LOADCENTER,
  EKind.MS_CONNECT,
  EKind.MS_ARRESTOR,
  EKind.MS_EARTH,
  EKind.MS_CABLEHEAD
]

export function doCalcCapacitorCapacity(
  target: ControlModel,
  diagramData: ControlModel[]
) {
  let capacitorCapacity: number = 0

  if (diagramData.length > 1) {
    target = roundPosition(target)
    diagramData = diagramData.filter((e) => e.id !== target.id)

    let nextControl = getBottomControl(target, diagramData)
    while (nextControl.code === 0) {
      let control: ControlModel = nextControl.data
      
      if (breakTypeArray.includes(control.type))
        return capacitorCapacity

      if (control.type === EKind.MS_CAPACITOR)
        return Number.parseFloat(control.properties.capacity)

      if (control.type === EKind.MS_ZEROCOND)
        return DoGetValueZerocondCapacity(control, diagramData)

      if (control.type === EKind.MS_TRANSCENTER)
        return DoGetValueZerocondCapacityTransCenter(control, diagramData)

      diagramData = diagramData.filter((e) => e.id !== control.id)
      nextControl = getBottomControl(control, diagramData)
    }
  }

  return capacitorCapacity
}

const DoGetValueZerocondCapacity = (
  target: ControlModel,
  diagramData: ControlModel[]
) => {
  let capacitorCapacity: number = 0

  if (diagramData.length > 1) {
    target = roundPosition(target)
    diagramData = diagramData.filter((e) => e.id !== target.id)

    // --bottom--
    let nextControl = getBottomControl(target, diagramData)
    while (nextControl.code === 0) {
      let continueFlag = true
      let control: ControlModel = nextControl.data
      diagramData = diagramData.filter((e) => e.id !== control.id)

      if (breakTypeArray.includes(control.type))
        continueFlag = false

      if (control.type === EKind.MS_CAPACITOR){
        capacitorCapacity += Number.parseFloat(control.properties.capacity)
        continueFlag = false
      }

      if (control.type === EKind.MS_ZEROCOND) {
        capacitorCapacity += DoGetValueZerocondCapacity(control, diagramData)
        continueFlag = false
      }

      if (control.type === EKind.MS_TRANSCENTER){
        capacitorCapacity += DoGetValueZerocondCapacityTransCenter(control, diagramData)
        continueFlag = false
      }
      
      if (continueFlag){
        let nextControl2 = getBottomControl(control, diagramData)
        let subContinueFlag = true
        while (nextControl2.code === 0 && subContinueFlag) {
          let control2: ControlModel = nextControl2.data
          diagramData = diagramData.filter((e) => e.id !== control2.id)
          
          if (breakTypeArray.includes(control2.type))
            subContinueFlag = false
    
          if (control2.type === EKind.MS_CAPACITOR){
            capacitorCapacity += Number.parseFloat(control2.properties.capacity)
            subContinueFlag = false
          }
    
          if (control2.type === EKind.MS_ZEROCOND) {
            capacitorCapacity += DoGetValueZerocondCapacity(control2, diagramData)
            subContinueFlag = false
          }
    
          if (control2.type === EKind.MS_TRANSCENTER){
            capacitorCapacity += DoGetValueZerocondCapacityTransCenter(control2, diagramData)
            subContinueFlag = false
          }
          
          if (subContinueFlag) nextControl2 = getBottomControl(control2, diagramData)
        }
      }
      
      nextControl = getBottomControl(target, diagramData)
    }

    // --left--
    let leftControl = getLeftControl(target, diagramData)
    let continueLeftFlag = true
    while (leftControl.code === 0 && continueLeftFlag) {
      let control: ControlModel = leftControl.data
      diagramData = diagramData.filter((e) => e.id !== control.id)

      if (breakTypeArray.includes(control.type))
        continueLeftFlag = false

      if (control.type === EKind.MS_ZEROCOND) {
        capacitorCapacity += DoGetValueZerocondCapacity(control, diagramData)
        continueLeftFlag = false
      }

      if (continueLeftFlag) leftControl = getLeftControl(control, diagramData)
    }

    // --right--
    let rightControl = getRightControl(target, diagramData)
    let continueRightFlag = true
    while (rightControl.code === 0 && continueRightFlag) {
      let control: ControlModel = rightControl.data
      diagramData = diagramData.filter((e) => e.id !== control.id)

      if (breakTypeArray.includes(control.type))
        continueRightFlag = false

      if (control.type === EKind.MS_ZEROCOND) {
        capacitorCapacity += DoGetValueZerocondCapacity(control, diagramData)
        continueRightFlag = false
      }
      
      if (continueRightFlag) rightControl = getRightControl(control, diagramData)
    }
  }

  return capacitorCapacity
}

const DoGetValueZerocondCapacityTransCenter = (
  target: ControlModel,
  diagramData: ControlModel[]
) => {
  return 0
  // TODO
  // let capacitorCapacity: number = 0

  // if (diagramData.length > 1) {
  //   target = roundPosition(target)
  //   diagramData = diagramData.filter((e) => e.id !== target.id)

  //   let nextControl = getBottomControl(target, diagramData)
  //   let continueFlag = true
  //   while (nextControl.code === 0 && continueFlag) {
  
  //     let control: ControlModel = nextControl.data
  //     diagramData = diagramData.filter((e) => e.id !== control.id)

  //     if (breakTypeArray.includes(control.type))
  //       continueFlag = false

  //     if (control.type === EKind.MS_CAPACITOR){
  //       capacitorCapacity += Number.parseFloat(control.properties.capacity)
  //       continueFlag = false
  //     }

  //     if (control.type === EKind.MS_ZEROCOND) {
  //       capacitorCapacity += DoGetValueZerocondCapacity(control, diagramData)
  //       continueFlag = false
  //     }

  //     if (continueFlag) nextControl = getBottomControl(control, diagramData)
  //   }
  // }

  // return capacitorCapacity
}

export const getTopControl = (
  target: ControlModel,
  diagramData: ControlModel[]
) => {
  if (target.id === undefined)
    return { code: 1, message: "undefined" } as ResultData

  if (notConnectTopArray.includes(target.type))
    return { code: 2, message: "Not found" } as ResultData

  let res
  diagramData = diagramData.filter((e) => e.id !== target.id)
  if (diagramData.length > 0) {
    target = roundPosition(target)

    diagramData.forEach((e) => {
      e = roundPosition(e)
      let isCollided = checkIsTop(target, e)
      if (isCollided !== 0)
        res = e
    })
  }

  if (res)
    return { code: 0, data: res }
  return { code: 2, message: "Not found" } as ResultData
}

export const getBottomControl = (
  target: ControlModel,
  diagramData: ControlModel[]
) => {
  if (target.id === undefined)
    return { code: 1, message: "undefined" } as ResultData

  if (
    notConnectBottomArray.includes(target.type) ||
    target.type === EKind.MS_3WINDING ||
    (target.type === EKind.MS_ARRESTOR && target.properties.haveEarth)
  )
    return { code: 2, message: "Not found" } as ResultData

  let res
  diagramData = diagramData.filter((e) => e.id !== target.id)
  if (diagramData.length > 0) {
    target = roundPosition(target)

    diagramData.forEach((e) => {
      e = roundPosition(e)
      let isCollided = checkIsBottom(target, e)
      if (isCollided !== 0)
        res = e
    })
  }

  if (res)
    return { code: 0, data: res }
  return { code: 2, message: "Not found" } as ResultData
}

export const getLeftControl = (
  target: ControlModel,
  diagramData: ControlModel[]
) => {
  if (target.id === undefined)
    return { code: 1, message: "undefined" } as ResultData

  let res
  if (diagramData.length > 0) {
    // target = roundPosition(target)
    diagramData = diagramData.filter((e) => e.id !== target.id)

    diagramData.forEach((e) => {
      e = roundPosition(e)
      let isCollided = checkIsLeft(target, e)
      if (isCollided)
        res = e
    })
  }

  if (res)
    return { code: 0, data: res }
  return { code: 2, message: "Not found" } as ResultData
}

export const getRightControl = (
  target: ControlModel,
  diagramData: ControlModel[]
) => {
  if (target.id === undefined)
    return { code: 1, message: "undefined" } as ResultData

  let res
  if (diagramData.length > 0) {
    target = roundPosition(target)
    diagramData = diagramData.filter((e) => e.id !== target.id)

    diagramData.forEach((e) => {
      e = roundPosition(e)
      let isCollided = checkIsRight(target, e)
      if (isCollided)
        res = e
    })
  }

  if (res)
    return { code: 0, data: res }
  return { code: 2, message: "Not found" } as ResultData
}

// =========== collisionDetection ===========
// 0: no collision
// 1: other is bottom
// 2: other is top
// 3: other is left
// 4: other is right
// ===========================================
export const checkIsLeft = (target: ControlModel, other: ControlModel) => {
  if (target.rotation !== 0 && other.rotation !== 0) {
    if (target.y === other.y && target.x === other.x + other.height) return 3
  }

  if (target.type === EKind.MS_3WINDING && other.rotation !== 0) {
    if (
      target.x === other.x + other.height &&
      target.y + target.height === other.y + CONSTANTS.POINTER_HEIGHT
    )
      return 3
  }

  if (other.type === EKind.MS_3WINDING && target.rotation !== 0){
    if (
      target.x === other.x + CONSTANTS.POINTER_WIDTH &&
      target.y === other.y + other.height - CONSTANTS.POINTER_HEIGHT
    )
      return 3
  }

  return 0
}

export const checkIsRight = (target: ControlModel, other: ControlModel) => {
  if (target.rotation !== 0 && other.rotation !== 0) {
    if (target.y === other.y && target.x + target.height === other.x) return 4
  }

  if (target.type === EKind.MS_3WINDING && other.rotation !== 0) {
    if (
      target.x + CONSTANTS.POINTER_WIDTH === other.x &&
      target.y + target.height === other.y + CONSTANTS.POINTER_HEIGHT
    )
      return 4
  }
  
  if (other.type === EKind.MS_3WINDING && target.rotation !== 0){
    if (
      target.x + target.height === other.x &&
      target.y === other.y + other.height - CONSTANTS.POINTER_HEIGHT
    )
      return 4
  }

  return 0
}

export const checkIsTop = (target: ControlModel, other: ControlModel) => {
  if (
    notConnectBottomArray.includes(other.type) ||
    other.type === EKind.MS_3WINDING ||
    (other.type === EKind.MS_ARRESTOR && other.properties.haveEarth)
  )
    return 0

  if (target.rotation === 0 && other.rotation === 0) {
    if (target.x === other.x && target.y === other.y + other.height) return 2
  }

  if (target.type === EKind.MS_ZEROCOND){
    if (other.rotation === 0) {
      if (
        other.x >= target.x &&
        other.x <= target.x + target.height - CONSTANTS.POINTER_WIDTH &&
        other.y + other.height === target.y
      )
        return 2
    }
  }

  if (other.type === EKind.MS_ZEROCOND){
    if (target.rotation === 0) {
      if (
        target.x >= other.x &&
        target.x <= other.x + other.height - CONSTANTS.POINTER_WIDTH &&
        target.y === other.y + CONSTANTS.POINTER_HEIGHT
      )
        return 2
    }
  }

  return 0
}

export const checkIsBottom = (target: ControlModel, other: ControlModel) => {
  if (notConnectTopArray.includes(other.type))
    return 0

  if (target.rotation === 0 && other.rotation === 0) {
    if (target.x === other.x && target.y + target.height === other.y) return 1
  }

  if (target.type === EKind.MS_ZEROCOND){
    if (other.rotation === 0) {
      if (
        other.x >= target.x &&
        other.x <= target.x + target.height - CONSTANTS.POINTER_WIDTH &&
        other.y === target.y + CONSTANTS.POINTER_HEIGHT
      )
        return 1
    }
  }

  if (other.type === EKind.MS_ZEROCOND){
    if (target.rotation === 0) {
      if (
        target.x >= other.x &&
        target.x <= other.x + other.height - CONSTANTS.POINTER_WIDTH &&
        target.y + target.height === other.y
      )
        return 1
    }
  }

  return 0
}

export const roundPosition = (control: ControlModel) => {
  control.x = roundFunc(control.x)
  control.y = roundFunc(control.y)
  return control
}

const roundFunc = (num: number) => {
  return Math.round(num / CONSTANTS.POINTER_HEIGHT) * CONSTANTS.POINTER_HEIGHT
}





