import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import { Stack, TextField, Typography } from '@mui/material'
import { useState } from 'react'

export type MFADialogProps = {
  errorMsg: string;
  openDialog: boolean;
  setOpenDialog: (value: boolean) => void;
  onOK: (code: string) => void;
  onCancle: () => void;
}

export const MFADialog = (props: MFADialogProps) => {
  const { errorMsg, openDialog, setOpenDialog, onOK, onCancle } = props
  const [codeVerify, setCodeVerify] = useState("")

  const handleOK = () => { onOK(codeVerify) }
  const handleCancel = () => {
    setOpenDialog(false)
    setCodeVerify("")
    onCancle()
  }

  const handleChangeText = (e: any) => { setCodeVerify(e.target.value) }

  return (
    <div>
      <Dialog
        open={openDialog}
        maxWidth={"xs"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography>
              登録されているメールアドレスまたは電話番号に送信された認証コードを入力してください。
            </Typography>
            <Stack mt={1.5}>
              <TextField
                value={codeVerify}
                onChange={handleChangeText}
                fullWidth
                type="text"
                size="small"
                label={<Typography variant='body2'>認証コード</Typography>}
              />
            </Stack>
            {errorMsg.length > 0 &&
              <Stack mt={1}>
                <Typography sx={{color: 'red'}}>{errorMsg}</Typography>
              </Stack>
            }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Stack direction='row' width={'100%'} spacing={1} px={2} mb={1}>
            <Button
              onClick={handleCancel}
              color="inherit"
              variant="contained"
              fullWidth
            >
              キャンセル
            </Button>
            <Button
              onClick={handleOK}
              variant="contained"
              fullWidth
            >
              送信
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default MFADialog