import { Box, Button, Checkbox, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Stack, Tab, Tabs, TextField, Typography } from "@mui/material";
import { useMemo, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { HarmonicRegisterModel, InfoCircuit, MsLoadModel } from '../../models/Index';
import TextFieldOptions from "../common/TextFieldOptions";
import HarmonicRegisterDialog from "./HarmonicRegisterDialog";
import AlertDialog from "../common/AlertDialog";
import { MS_PI } from "../../models/Constants";
import { BeamsNumber } from "../../utils/FormatNumber";
import NoRowsOverlay, { NoRowsMessage } from "../common/NoRowsOverlay";
import { AgGridReact } from "ag-grid-react";
import { AG_GRID_LOCALE_JP } from "../../themes/localeJP";
import * as Constant from "../../models/Constants";
import { MS_MODE_CHECKING } from "../../statics";

export type MsLoadEditDialogFormProps = {
    data: MsLoadModel;
    onOK: (data: MsLoadModel) => void;
    onCancel: () => void;
    // harmonic
    changeCombodata: any,
    controlId: string,
    defaultHarmonicInfo: any,
    onHarmonic: () => void;
    onChangeComboData: (data : any, controlId : string) => void;
    setChangeComboData: (a : any) => void;
    getHarmonicByID:(data:any) =>void;
    setIsConnectTrans1OrTranscott: boolean;
    userRole:string;
    m_bModePM: boolean;
    infoCircuit: InfoCircuit;
    processMode: number;
    modeViewOnly:boolean;
};

function a11yProps(index: number) {
    return {
        id: `tab-${index}`,
        "aria-controls": `tabpanel-${index}`,
    };
}

//Tabs------------------
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}
const radioStyles = {
    padding: '0 0 0 9px'
}
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

const checkboxStyle = { padding: '4px 4px 4px 8px' }

export const MsLoadEditDialog = (props: MsLoadEditDialogFormProps) => {
    // const { data, onOK, onCancel } = props;
    const { userRole, controlId, defaultHarmonicInfo, changeCombodata, data, onOK, onCancel, onHarmonic, onChangeComboData, 
        setChangeComboData, getHarmonicByID,setIsConnectTrans1OrTranscott,m_bModePM,infoCircuit,processMode, modeViewOnly} = props;
    const [isDisableOKBtn, setIsDisableOKBtn] = useState(false);
    const [selectTab, setSelectTab] = useState(0);
    const [isCheckedField3, setCheckedField3] = useState<boolean>(true);

    // const [isOpenedDialogAddMsLoadNew, setIsOpenedDialogAddMsLoadNew] = useState(false);
    const [selectedRow, setSetlectedRow] = useState<Number>();
    // const [selectedMsLoadNewDialogId, setSelectedMsLoadNewDialogId] = useState<Number>();
    // const [isOpenedDialogEditMsLoadNew, setIsOpenedDialogEditMsLoadNew] = useState<boolean>(false);
    //const [editDataMsLoadNewModel, setEditDataMsLoadNewModel] = useState<MsLoadNewFormModel>();

    //harmonic
    const [listHarmonic, setListHarmonic] = useState<HarmonicRegisterModel[]>(data.harmonicList?data.harmonicList:[]);
    const [selectedHarmonicDialogId, setSelectedHarmonicDialogId] = useState<Number>();
    const [isOpenedDialogEditHarmonic, setIsOpenedDialogEditHarmonic] = useState<boolean>(false);
    const [isOpenedDialogAddHarmonic, setIsOpenedDialogAddHarmonic] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
    const [editDataHarmonicModel, setEditDataHarmonicModel] = useState<HarmonicRegisterModel>();
    const [isImproveAfterPF, setImproveAfterPF] = useState<boolean>(data.calcImprovePF);
    const [selectItem, setSelectItem] = useState<any>(null);


    // 初期値設定
    const initialValue: MsLoadModel = {
        ...data
    }
    const { control, handleSubmit, formState: { errors }, getValues, setValue,setError,clearErrors } = useForm<MsLoadModel>({
        mode: 'all',
        criteriaMode: "all",
        defaultValues: initialValue,
    });
    const gridRef = useRef() as any;
    const noRowsOverlayComponentParams = useMemo(() => {
        return {
            noRowsMessageFunc: () => NoRowsMessage,
        };
    }, []);

    const noRowsOverlayComponent = useMemo(() => {
        return NoRowsOverlay;
    }, []);

    // 全列に共通の定義
    const defaultColDef = {
        sortable: true, // ソート可
        editable: false, // 編集不可
        resizable: true,
        suppressMovable: true,  // 列の移動を無効にする
    };

    // 列タイプの定義
    const columnTypes = {
        nonEditableColumn: { editable: false }, // 編集不可
    };
// 列の個別定義
const columnDefs = useMemo(() => [
   {
       headerName: "機器名称",
       field: "name",
       rowDrag: false,
       minWidth: 100,
       flex: 1.5,
   }
], [])
    // Submitイベント
    const handleEditSubmit = (value: MsLoadModel) => {
        const request = createRequestData(value);
        onOK(request);
    }
    const handleChangeFormat1 = (e:any) =>{
        setValue(e.target.name, e.target.value);

    }
    const handleChangeFormat = (e: any) => {
        if (e.target.value == 1) {
            setCheckedField3(false);
            setImproveAfterPF(false)
            setValue("calcImprovePF", false);
        } else {
            setCheckedField3(true);
        }
        setValue(e.target.name, e.target.value);
    }
    const handleChangeColor = () => isCheckedField3 ? "white" : "floralwhite";
    const handleChangCalcImprovePF =(e:any) =>{
        setValue(e.target.name, e.target.checked);
        let calcImprovePF = getValues("calcImprovePF")
        if(!calcImprovePF){
            setImproveAfterPF(false)
        }else{
            setImproveAfterPF(true)
        } 
    }
    const handleChangeColorImproveAfterPF = () => (isImproveAfterPF) ? "white" : "floralwhite";

// #region Tab2
const getlastIdHarmonicRegisterModel = () => {
    return initialValue.harmonicList?.length > 0 ?
        Math.max(...initialValue.harmonicList.map((item: any) => item.hamorItemID)) : 0;
}

const [lastIdHamornicNewFormModel, setLastIdHamornicNewFormModel] = useState(getlastIdHarmonicRegisterModel());

const handleOpenAddDialogHarmonicModel = () => {
    onHarmonic()
    setIsOpenedDialogAddHarmonic(true);
}
// Submitイベント
const handleAddHarmonicModel = (data:any) => {
    setIsOpenedDialogAddHarmonic(false);
    const newList = [...listHarmonic];
    newList.push(data);
    setListHarmonic(newList);
    setValue("harmonicList",newList);
    setLastIdHamornicNewFormModel(data.hamorItemID);
}

// キャンセル
const handleCancelAddHarmonicModel = () => {
    setIsOpenedDialogAddHarmonic(false);
}
const handleClickItemInListItem = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number, val: number) => {
    if (selectedRow === index) {
        setSetlectedRow(undefined);
        setSelectedHarmonicDialogId(undefined);
    }
    else {
        setSetlectedRow(index);
        setSelectedHarmonicDialogId(val);
    }
}
const handleOpenEditDialogHarmonicModelById = (data:any) => {
    setIsOpenedDialogEditHarmonic(true);
    setEditDataHarmonicModel(selectItem);
    getHarmonicByID(selectItem)

}
const handleOpenEditDialogHarmonicModel = () => {
    setIsOpenedDialogEditHarmonic(true);
    setEditDataHarmonicModel(selectItem);
    getHarmonicByID(selectItem)

}

// Submitイベント
const handleEditHamornicNewModel = (data: HarmonicRegisterModel) => {
    setIsOpenedDialogEditHarmonic(false);
    const arrOptions = [...listHarmonic];
    const IndexOfHamornicNew = arrOptions.findIndex(item => item.hamorItemID === data.hamorItemID);
    IndexOfHamornicNew !== -1 && (arrOptions[IndexOfHamornicNew] = data);
    setListHarmonic(arrOptions);
    setValue("harmonicList", arrOptions);
}

// キャンセル
const handleCancelEditHarmonicModel = () => {
    setIsOpenedDialogEditHarmonic(false);
}
const handleInput = (e: any) => {
    setValue(e.target.name, e.target.value)
    const isInteger = /^\d*$/.test(getValues("voltage").toString())
    if(getValues("voltage") > 0 && isInteger){
        setIsDisableOKBtn(false);
    }
    else{
        setIsDisableOKBtn(true);
    }
}
// 消去
const handleDeleteHamornicData = () => {
    const harmonicList = [...listHarmonic].filter(options => options.hamorItemID !== selectItem.hamorItemID);
    setListHarmonic(harmonicList);
    setValue("harmonicList", harmonicList);
    setSetlectedRow(undefined);
}
    // FormデータからRequestデータを作成する
    const createRequestData = (formValue: MsLoadModel) => {
        if(!formValue.loadCurrent){
            formValue.loadCurrent = 0
        }
        if(!formValue.powerFactor){
            formValue.powerFactor = 0
        }
        if(!formValue.loadFactor){
            formValue.loadFactor  = 0
        }
        if(!formValue.startingTime){
            formValue.startingTime = 0
        }
        if(!formValue.startingCurrent){
            formValue.startingCurrent = 0
        }
        if(!formValue.improveAfterPF){
            formValue.improveAfterPF = 0
        }
        let request = {
            ...formValue

        } as MsLoadModel;
        return request;
    }

    // キャンセル
    const handleCancel = () => {
        onCancel();
    };

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        if(newValue >=0){
            if (getValues('refNo') == "" ||getValues('refNo') == null || getValues('refNo') == undefined ) {
                setError("refNo",{ type: 'custom', message: '必須項目です。入力してください。' });
            }
            else if (isNaN(Number(getValues('voltage'))) || Number(getValues('voltage')) <= 0 || !/^[0-9\b]+$/.test(getValues('voltage').toString())) {
                setError("voltage",{ type: 'custom', message: '整数 > 0 を入力してください。' });
            }
            else if (isNaN(Number(getValues('loadCurrent'))) || Number(getValues('loadCurrent')) < 0 || !/^[0-9]*[.]?[0-9]+$/.test(getValues('loadCurrent').toString())) {
                setError("loadCurrent",{ type: 'custom', message: '数字 >= 0 を入力してください。' });
            }
            else if (isNaN(Number(getValues('powerFactor'))) || Number(getValues('powerFactor')) < 0 || !/^[0-9]*[.]?[0-9]+$/.test(getValues('powerFactor').toString()) || Number(getValues('powerFactor')) > 1) {
                setError("powerFactor",{ type: 'custom', message: '数字 0 ~ 1 を入力してください。' });
            }
            else if (isNaN(Number(getValues('loadFactor'))) || Number(getValues('loadFactor')) < 0 || !/^[0-9]*[.]?[0-9]+$/.test(getValues('loadFactor').toString()) || Number(getValues('loadFactor')) > 1) {
                setError("loadFactor",{ type: 'custom', message: '数字 0 ~ 1 を入力してください。' });
            }
            else if (isNaN(Number(getValues('startingCurrent'))) || Number(getValues('startingCurrent')) <= 0 ) {
                setError("startingCurrent",{ type: 'custom', message: '数字 > 0 を入力してください。' });
            }
            else if (isNaN(Number(getValues('startingTime'))) || Number(getValues('startingTime')) <= 0 ) {
                setError("startingTime",{ type: 'custom', message: '数字 > 0 を入力してください。' });
            }
            else if (isNaN(Number(getValues('improveAfterPF'))) || Number(getValues('improveAfterPF')) < 0 || !/^[0-9]*[.]?[0-9]+$/.test(getValues('improveAfterPF').toString()) || Number(getValues('improveAfterPF')) > 1) {
                setError("improveAfterPF",{ type: 'custom', message: '数字 0 ~ 1 を入力してください。' });
            }
            else{
                setSelectTab(newValue);

            }
    }
        
    };

    // const handleClickItemInListItem = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number, val: number) => {
    //     if (selectedRow === index) {
    //         setSetlectedRow(undefined);
    //         setSelectedMsLoadNewDialogId(undefined);
    //     }
    //     else {
    //         setSetlectedRow(index);
    //         setSelectedMsLoadNewDialogId(val);
    //     }
    // }
    // const getlastIdMsLoadNewFormModel = () => {
    //     return initialValue.field12.length > 0 ?
    //         Math.max(...initialValue.field12.map((item: any) => item.id)) : 0;
    // }
    // const [lastIdMsLoadNewFormModel, setLastIdMsLoadNewFormModel] = useState(getlastIdMsLoadNewFormModel());
    // const handleOpenAddDialogMsLoadNewModel = () => {
    //     setIsOpenedDialogAddMsLoadNew(true);
    // }

    // const handleAddMsLoadNewModel = (data: any) => {
    //     setIsOpenedDialogAddMsLoadNew(false);
    //     const arrField12Options = [...optionDatas];
    //     arrField12Options.push(data);
    //     setOptionDatas(arrField12Options);
    //     setValue("field12", arrField12Options);
    //     setLastIdMsLoadNewFormModel(data.id);
    // }

    // const handleCancelAddMsLoadNewModel = () => {
    //     setIsOpenedDialogAddMsLoadNew(false);
    // }

    // const handleEditMsLoadNewModel = (data: MsLoadNewFormModel) => {
    //     setIsOpenedDialogEditMsLoadNew(false);
    //     const arrOptions = [...optionDatas];
    //     const IndexOfMsLoadNew = arrOptions.findIndex(item => item.id === data.id);
    //     IndexOfMsLoadNew !== -1 && (arrOptions[IndexOfMsLoadNew] = data);
    //     setOptionDatas(arrOptions);
    //     setValue("field12", arrOptions);
    // }
    // const handleOpenEditDialogMsLoadNewModel = () => {
    //     setIsOpenedDialogEditMsLoadNew(true);
    //     setEditDataMsLoadNewModel([...optionDatas].find(x => x.id === selectedMsLoadNewDialogId));
    // }
    // const handleOpenEditDialogMsLoadNewModelById = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, id: number) => {
    //     setIsOpenedDialogEditMsLoadNew(true);
    //     setEditDataMsLoadNewModel([...optionDatas].find(x => x.id === id));
    // }
    // const handleCancelEditMsLoadNewModel = () => {
    //     setIsOpenedDialogEditMsLoadNew(false);
    // }

    // const handleDeleteMsLoadData = () => {
    //     const arrField12Options = [...optionDatas].filter(options => options.id !== selectedMsLoadNewDialogId);
    //     setOptionDatas(arrField12Options);
    //     setValue("field12", arrField12Options);
    //     setSetlectedRow(undefined);
    // }

    // const initalValueMsLoadNewFormModel: MsLoadNewFormModel = {
    //     id: lastIdMsLoadNewFormModel + 1,
    //     field1: 6600,
    //     field2: 1000,
    //     field3: '',
    //     field4: '',
    //     field5: '',
    //     field6: 0,
    //     field7: 0,
    //     field8: 0,
    //     field9: 0,
    //     field10: 0,
    //     field11: 0,
    //     field12: 0,
    //     field13: 1,
    //     field14: 0,
    //     field15: 0,
    //     field16: 80,
    //     field17: 17.5,
    //     field18: 0,
    //     field19: 11,
    //     field20: 0,
    //     field21: 4.5,
    //     field22: 0,
    //     field23: 3,
    //     field24: 0,
    //     field25: 1.5,
    //     field26: 0,
    //     field27: 1.25,
    //     field28: 0,
    //     field29: 0.75,
    //     field30: 0,
    //     field31: 0.75,
    //     field32: 0
    // }
    const initialValueHarmonicForm: HarmonicRegisterModel = {
        ...defaultHarmonicInfo,
        hamorItemID: lastIdHamornicNewFormModel + 1,
        name: "",
        manufacture: "",
        type: "",
        capacity: 0,
        number: 0,
        totalCapacity: 0,
        convertRated: 1,
        operatingRatio: 80,
        current7: 0,
        current5: 0,
        current13: 0,
        current17: 0,
        current19: 0,
        current11: 0,
        current23: 0,
        current25: 0,
        equipvalentCapacity: 1,
    }
    const doGetPhaseCurrent = (e:any,isLeadOrLag:number,powerFactor:number) =>{
        setValue(e.target.name,e.target.value)
        if(powerFactor < -1 || powerFactor > 1){
            return ""
        }
        let phaseCurrent = BeamsNumber(Math.acos(powerFactor) * 180 / MS_PI,4)
        let phaseCurrent1 =""
        if(isLeadOrLag == 1){
            phaseCurrent1 = "+" + Math.abs(phaseCurrent)
        }
        else if(isLeadOrLag == 0){
            phaseCurrent1 = "-"+ Math.abs(phaseCurrent)
        }
        return phaseCurrent1

    }
    const doSetValuesLoad =(e:any) =>{
        setValue(e.target.name,e.target.value,{shouldValidate: true})
        let isLeadOrLag = getValues("leadOrLag") // hard Code
        let phaseCurrent = ""
        let powerFactor = getValues("powerFactor")
        
        if(powerFactor >=0 && powerFactor <=1){
             phaseCurrent = doGetPhaseCurrent(e,isLeadOrLag,powerFactor)
        }
        else{
            phaseCurrent = ""
        }
          setValue("phaseCurrent",phaseCurrent)
        
    }
    const handleRadioPower =(e:any) =>{
        setValue(e.target.name, e.target.value);
        let phaseCurrent = getValues("phaseCurrent")
        if(e.target.value == 1){ 
               setValue("phaseCurrent", "+" +  Math.abs(+phaseCurrent))        
       }                       
       else if(e.target.value == 0){
            setValue("phaseCurrent", "-" + Math.abs(+phaseCurrent))
                  
       }
   }
   const doReadByLoadCurrent =(loadCurrentList:any) =>{
    const loadCurrent = +getValues("loadCurrent")
    for(const item of loadCurrentList){
        if(loadCurrent <= item.dloadCurrent || item.dloadCurrent === -1){
            setValue("powerFactor",item.dpowerFactor)
            setValue("startingCurrent",item.dstartingCurrent)
            setValue("startingTime",item.dstartingTime)
            break;
        }
    }
}
   const doCalcSetValue = (loadCurrent:number) =>{
    if(loadCurrent === 0 ) {
        setValue("powerFactor" ,0)
        setValue("startingCurrent",0)
        setValue("startingTime",0)
        return;
    }
    doReadByLoadCurrent(getValues("loadCurrentList"))
   }
    const onChangLoadCurrent = (e:any) =>{
        setValue(e.target.name,e.target.value,{shouldValidate: true})
        let loadCurrent = +getValues("loadCurrent")
        if(loadCurrent >= 0){
            setIsDisableOKBtn(false)
        }else{
            setIsDisableOKBtn(true)
        }
        doCalcSetValue(loadCurrent)
        doSetValuesLoad(e)
        if(getValues('startingTime') > 0){
            clearErrors('startingTime')
        }
        if(getValues('startingCurrent') > 0){
            clearErrors('startingCurrent')
        }
    }
    

    return (
        <>
            <div style={{ height: '100%', width: '100%' }}>
                <div>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={selectTab} onChange={handleChange}
                            variant="scrollable" 
                            scrollButtons 
                            allowScrollButtonsMobile 
                            sx={{
                                '& .MuiButtonBase-root.MuiTabScrollButton-root.Mui-disabled': {
                                    display: "none"
                                }
                            }}
                        >
                            <Tab label="基本" {...a11yProps(0)} />
                            <Tab label="高調波発生機器" {...a11yProps(1)} />
                        </Tabs>
                    </Box>
                </div>
                <form onSubmit={handleSubmit(handleEditSubmit)} >
                    <div style={{ height: "40vh", overflow: "auto", width: '100%', margin: 'auto' }}>
                        {/* Tab1 */}
                        <TabPanel value={selectTab} index={0}>
                            <Grid container mt={1}>
                                <Grid item xs={6}>
                                    <Stack spacing={0.3}>
                                        <Stack flexDirection='row' alignItems='center'>
                                            <Typography variant='body2' width='75%'>デバイス名:</Typography>
                                            <Controller
                                                name="refNo"
                                                control={control}
                                                rules={{
                                                    required: '必須項目です。入力してください。',
                                                }}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        error={!!errors?.refNo}
                                                        helperText={errors?.refNo?.message}
                                                        InputProps={{ sx: { height: 32 ,backgroundColor:m_bModePM || modeViewOnly?'floralwhite':'white', color:modeViewOnly?"grey":"black"} }}
                                                        disabled={m_bModePM}
                                                        inputProps={{readOnly:modeViewOnly}}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                        <Stack flexDirection='row' alignItems='center'>
                                            <Typography variant='body2' width='75%'>回路電圧(V):</Typography>
                                            <FormControl fullWidth>
                                                <Controller
                                                    name="voltage"
                                                    control={control}
                                                    rules={{
                                                        required: '必須項目です。入力してください。' ,
                                                        pattern:{
                                                        value: /^[0-9\b]+$/,
                                                        message: '数字を入力してください。'
                                                        },
                                                        validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                    //     maxLength: {
                                                    //     value: 6,
                                                    //     message: "最大長さ <= 6 を入力してください。"
                                                    // }
                                                        }}
                                                    render={({ field }) => (
                                                        <TextFieldOptions
                                                            {...field}
                                                            options={getValues("voltage") <= 600 ? initialValue.voltageList : initialValue.voltageHightList}
                                                            onChange= {handleInput}
                                                            setValue={(name: any, value: any) => {
                                                                field.onChange({target: {name: name, value: value}});
                                                                doSetValuesLoad({target: {name: name, value: value}})
                                                            }}
                                                            height= {32}
                                                            error={!!errors?.voltage}
                                                            helperText={errors?.voltage?.message}
                                                            maxLength={6}
                                                            disabled={getValues("isSourceVoltage")||m_bModePM || modeViewOnly}
                                                        />
                                                    )}
                                                />
                                            </FormControl>
                                        </Stack>
                                        <Stack flexDirection='row' alignItems='center'>
                                            <Typography variant='body2' width='75%'>負荷電流 IL(A):</Typography>
                                            <Controller
                                                name="loadCurrent"
                                                control={control}
                                                rules={{
                                                    pattern:{
                                                    value: /^[0-9]*[.]?[0-9]+$/,
                                                    message: '数字を入力してください。'
                                                    },
                                                    validate: value => value >= 0 || '数字 >= 0 を入力してください。' ,
                                                //     maxLength: {
                                                //     value: 5,
                                                //     message: "最大長さ <= 5 を入力してください。"
                                                // }
                                                    }}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        // type="text"
                                                        onChange={(e)=>{field.onChange(e);onChangLoadCurrent(e)}}
                                                        InputProps={{ sx: { height: 32 ,backgroundColor:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white', color:modeViewOnly?"grey":"black"} }}
                                                        inputProps={{maxLength:5, readOnly: modeViewOnly}}
                                                        error={!!errors?.loadCurrent}
                                                        helperText={errors?.loadCurrent?.message}
                                                        disabled={m_bModePM|| processMode > MS_MODE_CHECKING}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                    </Stack>

                                    <Stack mt={0.5}>
                                        <fieldset style={{border:'1px solid #AAAA',width:'89.6%'}}>
                                            <legend><Typography variant='body2'>負荷の力率</Typography></legend>
                                            <Grid container alignItems='center'>
                                                <Grid item xs={7.5}>
                                                    <Controller
                                                        name="leadOrLag"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <RadioGroup
                                                                {...field}
                                                                row
                                                                onChange={(e)=>{handleChangeFormat(e);
                                                                                handleRadioPower(e)}}
                                                                value={field.value}
                                                                
                                                            >
                                                                <FormControlLabel
                                                                    value={1}
                                                                    control={<Radio style={radioStyles}/>}
                                                                    label={<Typography variant='body2' color={m_bModePM|| processMode > MS_MODE_CHECKING?'grey':'black'}>進み</Typography>}
                                                                    disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}
                                                                />
                                                                <FormControlLabel
                                                                    value={0}
                                                                    control={<Radio style={radioStyles}/>}
                                                                    label={<Typography variant='body2' color={m_bModePM|| processMode > MS_MODE_CHECKING?'grey':'black'}>遅れ</Typography>}
                                                                    disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}
                                                                />
                                                            </RadioGroup>
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid item xs={4.5}>
                                                    <Controller
                                                        name="powerFactor"
                                                        control={control}
                                                        rules={{
                                                            pattern:{
                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                            message: '数字を入力してください。'
                                                            },
                                                            validate: value => value >= 0 && value <=1 || '数字 0 ~ 1 を入力してください。' ,
                                                        //     maxLength: {
                                                        //     value: 5,
                                                        //     message: "最大長さ <= 5 を入力してください。"
                                                        // }
                                                            }}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                fullWidth
                                                                // type="text"
                                                                size="small"
                                                                label=""
                                                                InputProps={{ sx: { height: 32 ,backgroundColor:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?"floralwhite":"white", color:modeViewOnly?"grey":"black"} }}
                                                                onChange={(e) =>{
                                                                    field.onChange(e);

                                                                    doSetValuesLoad(e);}}
                                                                inputProps={{maxLength:5, readOnly: modeViewOnly}}                                                           
                                                                error={!!errors?.powerFactor}
                                                                helperText={errors?.powerFactor?.message}
                                                                disabled={m_bModePM|| processMode > MS_MODE_CHECKING}
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </fieldset>
                                    </Stack>

                                    <Stack mt={1.5} spacing={0.3}>
                                        <Stack flexDirection='row' alignItems='center'>
                                            <Typography variant='body2' width='75%'>電流位相(ﾟ ):</Typography>
                                            <Controller
                                                name="phaseCurrent"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        inputProps={{ readOnly: true }}
                                                        style={{ backgroundColor: 'floralwhite' }}
                                                        InputProps={{ sx: { height: 32,color:'grey' } }}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                        <Stack flexDirection='row' alignItems='center'>
                                            <Typography
                                                variant='body2'
                                                width='75%'
                                                color={initialValue.sPhase?'grey':'black'}
                                            >
                                                負荷率:
                                            </Typography>
                                            <Controller
                                                name="loadFactor"
                                                control={control}
                                                rules={{
                                                    pattern:{
                                                    value: /^[0-9]*[.]?[0-9]+$/,
                                                    message: '数字を入力してください。'
                                                    },
                                                    validate: value => value >= 0 && value <= 1|| '数字  0 ~ 1 を入力してください。' ,
                                                //     maxLength: {
                                                //     value: 5,
                                                //     message: "最大長さ <= 5 を入力してください。"
                                                // }
                                                    }}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        // type="text"
                                                        onChange={(e) =>{
                                                            field.onChange(e)
                                                            handleChangeFormat1(e)
                                                        }}
                                                        InputProps={{ sx: { height: 32, backgroundColor:(initialValue.sPhase ||m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING)?'floralwhite':'white', color:modeViewOnly?"grey":"black"} }}
                                                        inputProps={{maxLength:5, readOnly: modeViewOnly}}                                                        
                                                        error={!!errors?.loadFactor}
                                                        helperText={errors?.loadFactor?.message}
                                                        disabled={initialValue.sPhase||m_bModePM|| processMode > MS_MODE_CHECKING}
                                                        style={{ color:'black'}}
                                                        

                                                    />
                                                )}
                                            />
                                        </Stack>
                                    </Stack>

                                    <Stack mt={0.5}>
                                        <fieldset style={{border:'1px solid #AAAA',width:'89.6%'}}>
                                            <legend><Typography variant='body2'>始動電流</Typography></legend>
                                            <Stack spacing={0.1}>
                                                <Stack flexDirection='row' alignItems='center'>
                                                    <Typography variant='body2' width='125%'>始動電流(ILの倍数):</Typography>
                                                    <Controller
                                                        name="startingCurrent"
                                                        control={control}
                                                        rules={{
                                                            pattern:{
                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                            message: '数字を入力してください。'
                                                            },
                                                            validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                        //     maxLength: {
                                                        //     value: 4,
                                                        //     message: "最大長さ <= 4 を入力してください。"
                                                        // }
                                                            }}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                fullWidth
                                                                // type="text"
                                                                onChange ={(e) => {
                                                                    field.onChange(e);
                                                                    handleChangeFormat1(e)
                                                                }}
                                                                InputProps={{ sx: { height: 32 ,backgroundColor:m_bModePM || modeViewOnly ?"floralwhite":"white", color:modeViewOnly?"grey":"black"} }}
                                                                inputProps={{maxLength:4, readOnly: modeViewOnly}}                                                                
                                                                error={!!errors?.startingCurrent}
                                                                helperText={errors?.startingCurrent?.message}
                                                                disabled={m_bModePM}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                                <Stack flexDirection='row' alignItems='center'>
                                                    <Typography variant='body2' width='125%'>始動時間(s):</Typography>
                                                    <Controller
                                                        name="startingTime"
                                                        control={control}
                                                        rules={{
                                                            pattern:{
                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                            message: '数字を入力してください。'
                                                            },
                                                            validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                        //     maxLength: {
                                                        //     value: 3,
                                                        //     message: "最大長さ <= 3 を入力してください。"
                                                        // }
                                                            }}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                fullWidth
                                                                // type="text"
                                                                onChange ={(e) => {
                                                                    field.onChange(e);
                                                                    handleChangeFormat1(e)
                                                                }}
                                                                InputProps={{ sx: { height: 32 ,backgroundColor:m_bModePM || modeViewOnly ?"floralwhite":"white", color:modeViewOnly?"grey":"black"} }}
                                                                inputProps={{maxLength:3, readOnly: modeViewOnly}}                                                                
                                                                error={!!errors?.startingTime}
                                                                helperText={errors?.startingTime?.message}
                                                                disabled={m_bModePM}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                            </Stack>
                                        </fieldset>
                                    </Stack>
                                </Grid>

                                <Grid item xs={6} pl={2}>
                                    <Stack>
                                        <Controller
                                            name="calcImprovePF"
                                            control={control}
                                            render={({ field }: any) => (
                                                <FormControlLabel
                                                    {...field}
                                                    control={
                                                        <Checkbox
                                                            size="small"
                                                            name="calcImprovePF"
                                                            checked={field.value}
                                                            style={checkboxStyle}
                                                            onChange={(e) => handleChangCalcImprovePF(e)}
                                                        />}
                                                    disabled={!isCheckedField3||setIsConnectTrans1OrTranscott || m_bModePM || modeViewOnly}
                                                    // style={{
                                                    //     backgroundColor: handleChangeColor()
                                                    // }}
                                                    label={<Typography variant='body2' color={(!isCheckedField3||setIsConnectTrans1OrTranscott || m_bModePM)?'grey':'black'}>力率改善を計算</Typography>}
                                                />
                                            )}
                                        />
                                    </Stack>
                                    <Stack flexDirection='row' alignItems='center' pl={3}>
                                        <Typography variant='body2' width='125%' color={(setIsConnectTrans1OrTranscott||!isImproveAfterPF||m_bModePM)?'grey':'black'}>改善後の目標力率:</Typography>
                                        <Controller
                                            name="improveAfterPF"
                                            control={control}
                                            rules={{
                                                pattern:{
                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                message: '数字を入力してください。'
                                                },
                                                validate: value => value >= 0 && value <=1  || '数字 0 ~ 1 を入力してください。' ,
                                            //     maxLength: {
                                            //     value: 5,
                                            //     message: "最大長さ <= 5 を入力してください。"
                                            // }
                                                }}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    style={{
                                                        color: "black"
                                                    }}
                                                    inputProps={{ readOnly: !isImproveAfterPF || modeViewOnly,maxLength:5 }}
                                                    InputProps={{ sx: { height: 32, backgroundColor: setIsConnectTrans1OrTranscott||m_bModePM || modeViewOnly?'floralwhite':handleChangeColorImproveAfterPF(), color:modeViewOnly?"grey":"black"} }}
                                                    onChange ={(e) => {
                                                        field.onChange(e);
                                                        handleChangeFormat1(e)
                                                    }}
                                                    
                                                    error={!!errors?.improveAfterPF}
                                                    helperText={errors?.improveAfterPF?.message}
                                                    disabled={setIsConnectTrans1OrTranscott||m_bModePM}
                                                />
                                            )}
                                        />
                                    </Stack>
                                    {/* Mode SP */}
                                    {(userRole && !(userRole == Constant.ROLE_LP)) &&
                                    <Stack>
                                        <Controller
                                            name="viewFlowResult"
                                            control={control}
                                            render={({ field }: any) => (
                                                <FormControlLabel
                                                    {...field}
                                                    control={
                                                        <Checkbox
                                                            name="viewFlowResult"
                                                            checked={field.value}
                                                            style={checkboxStyle}
                                                            size="small"
                                                        />}
                                                    label={<Typography variant='body2' color={setIsConnectTrans1OrTranscott||m_bModePM?'grey':'black'}>潮流解析結果を表示</Typography>}
                                                    disabled={setIsConnectTrans1OrTranscott||m_bModePM || modeViewOnly}
                                                />
                                            )}
                                        />
                                    </Stack>
                                    }
                                    <Stack>
                                        <Controller
                                            name="viewResultText"
                                            control={control}
                                            render={({ field }: any) => (
                                                <FormControlLabel
                                                    {...field}
                                                    control={
                                                        <Checkbox
                                                            name="viewResultText"
                                                            checked={field.value}
                                                            style={checkboxStyle}
                                                            size="small"
                                                        />}
                                                    label={<Typography variant='body2' color={m_bModePM?'grey':'black'}>注釈を表示</Typography>}
                                                    disabled={m_bModePM || modeViewOnly}
                                                />
                                            )}
                                        />
                                    </Stack>
                                </Grid>
                            </Grid>
                        </TabPanel>
                        {/* Tab2 */}
                        <TabPanel value={selectTab} index={1}>
                            <Stack  mb={1} mt={2} style={{ width: '100%', margin: "auto" }} >
                                <Grid container spacing={2} direction="row" mt={0}>
                                    <Grid item xs={0.5}></Grid>
                                    <Grid item xs={9} >
                                        
                                        <div className="ag-theme-alpine" style={{ height: "300px", width: "100%" }}>
                                            <AgGridReact
                                                    ref={gridRef}
                                                    defaultColDef={defaultColDef}
                                                    columnTypes={columnTypes}
                                                    columnDefs={columnDefs}
                                                    // onGridReady={onGridReady}
                                                    domLayout={"normal"}
                                                    rowData={listHarmonic as any}
                                                    animateRows={true}
                                                    rowDragManaged={true}
                                                    // rowHeight={rowHeight}
                                                    rowSelection={'single'}
                                                    suppressMovableColumns={false}
                                                    suppressDragLeaveHidesColumns={false}
                                                    localeText={AG_GRID_LOCALE_JP}
                                                    // paginationPageSize={pageSize}
                                                    // onCellDoubleClicked={handleEdit}
                                                    onRowClicked={() => setSelectItem(gridRef.current.api.getSelectedRows()[0])}
                                                    onRowDoubleClicked={() => handleOpenEditDialogHarmonicModelById(gridRef.current.api.getSelectedRows()[0])}
                                                    // loadingOverlayComponent={loadingOverlayComponent}
                                                    // loadingOverlayComponentParams={loadingOverlayComponentParams}
                                                    noRowsOverlayComponent={noRowsOverlayComponent}
                                                    noRowsOverlayComponentParams={noRowsOverlayComponentParams}
                                                />
                                                </div>
                                            {/* <Typography variant="body2" component="h3" ml={2} mt={1} mb={1}  >機器名称</Typography>
                                            <List  component="nav" sx={{ maxHeight: '400px',minHeight: '200px', overflow: 'auto',borderTop:"1px solid #babfc7",background:"white"}}>
                                                {listHarmonic.length > 0 &&
                                                    listHarmonic.map((val: HarmonicRegisterModel, index: number) => (
                                                        <ListItem style={{ height: '80%', width: '100%',padding:"0px" }} key={val.hamorItemID} >
                                                            <ListItemButton
                                                                selected={selectedRow === index}
                                                                onClick={(event) => handleClickItemInListItem(event, index, val.hamorItemID)}
                                                            >
                                                                <ListItemText
                                                                    primary={val.name}
                                                                    onDoubleClick={(event) => handleOpenEditDialogHarmonicModelById(event, val.hamorItemID)
                                                                } />
                                                            </ListItemButton>
                                                        </ListItem>
                                                    ))}
                                            </List> */}
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Stack direction={"row"} justifyContent={"flex-end"} mb={1}>
                                            <Button fullWidth={true} color="inherit" variant="contained" onClick={handleOpenAddDialogHarmonicModel} disabled={m_bModePM}>新規 ..</Button>
                                        </Stack>
                                        <Stack direction={"row"} justifyContent={"flex-end"} mb={1}>
                                            <Button fullWidth={true} color="inherit" variant="contained" onClick={handleOpenEditDialogHarmonicModel} disabled={!selectItem}>編集 ..</Button>
                                        </Stack>
                                        <Stack direction={"row"} justifyContent={"flex-end"} mb={1} >
                                            <Button fullWidth={true} color="inherit" variant="contained" onClick={() => setOpenDeleteDialog(true)} disabled={!selectItem||m_bModePM || modeViewOnly}>削除 ..</Button>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Stack>
                        </TabPanel>
                    </div>
                    <Stack direction='row' justifyContent="flex-end" spacing={2} mt={2}>
                        <Button variant="contained" type="submit" disabled={isDisableOKBtn || modeViewOnly}>OK</Button>
                        <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                    </Stack>
                </form >
                 {/* ==================== Harmonic ========================= */}
                 {isOpenedDialogAddHarmonic && defaultHarmonicInfo &&
                    <HarmonicRegisterDialog 
                    setChangeComboData={setChangeComboData} 
                    changeCombodata={changeCombodata} 
                    controlId={controlId} 
                    data={initialValueHarmonicForm} 
                    onChangeComboData={onChangeComboData} 
                    onOK={handleAddHarmonicModel} 
                    onCancel={handleCancelAddHarmonicModel}  
                    m_bModePM={m_bModePM}
                    infoCircuit={infoCircuit}
                    modeViewOnly={modeViewOnly}
                    />
                }
                {isOpenedDialogEditHarmonic &&
                    <HarmonicRegisterDialog setChangeComboData={setChangeComboData} 
                    changeCombodata={changeCombodata} controlId={controlId} 
                    data={editDataHarmonicModel ?? initialValueHarmonicForm} 
                    onChangeComboData={onChangeComboData} 
                    onOK={handleEditHamornicNewModel} 
                    onCancel={handleCancelEditHarmonicModel}
                    m_bModePM={m_bModePM} 
                    infoCircuit={infoCircuit}
                    modeViewOnly={modeViewOnly}
                    />
                }
                {openDeleteDialog && <AlertDialog title={"MSSV3"} message={"このユーザー曲線を削除しますか?"} isOpen={true} onOK={handleDeleteHamornicData} onCancel={() => setOpenDeleteDialog(false)} />}
            </div >
        </>
    );
};
export default MsLoadEditDialog;


