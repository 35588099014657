import { Button, Dialog, DialogContent, DialogTitle, Stack, TextareaAutosize } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { MsTextModel } from '../../models/Index';
import FormatTextDialog from './FormatTextDialog';
import DraggablePaper from '../common/DraggablePaper';
import theme from '../../themes/globalStyles';

export type TextEditorDialogFormProps = {
    data: any;
    onOK: (data: any) => void;
    onCancel: () => void;
    m_bModePM: boolean;
    modeViewOnly: boolean;
};

export const TextEditorDialog = (props: TextEditorDialogFormProps) => {
    const { data, onOK, onCancel,m_bModePM, modeViewOnly } = props;
    const [isOpenFormat, setIsOpenFormat] = useState(false);

    // 初期値設定
    const initialValue: any = {
        ...data
    }

    const { register, control, handleSubmit, formState: { errors }, setValue, getValues, reset } = useForm<any>({
        mode: 'all',
        criteriaMode: "all",
        defaultValues: initialValue,
    });

    // Submitイベント
    const handleEditSubmit = (value: any) => {
        const request = createRequestData(value);
        onOK(request);
    }

    // FormデータからRequestデータを作成する
    const createRequestData = (formValue: any) => {
        let request = {
            ...formValue
        } as MsTextModel;

        return request;
    }

    // キャンセル
    const handleCancel = () => {
        onCancel();
    };

    const handleOKFormatTextForm = (data: any) => {        
        reset(data)
        setIsOpenFormat(false)
    }

    return (
        <>
            <Dialog open={true} maxWidth={"sm"} fullWidth
                PaperComponent={DraggablePaper}
            >
                <DialogTitle onMouseOver={(e : any) => e.target.style.cursor = 'move'} style={{ paddingTop: '2px', paddingBottom: '2px', background: theme.palette.primary.main, color: theme.palette.primary.contrastText }}>テキストボックス</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleSubmit(handleEditSubmit)} style={{ width: '100%', margin: 'auto' }}>
                        <Stack spacing={1} style={{height:"40vh", width: '100%'}}>
                            <Stack direction={{ xs: 'column', sm: 'row' }} mt={3}>
                                <textarea
                                    style={{resize:"none", height:"30vh", width : '100%',marginTop:"16px",overflowY:'scroll'}}
                                    {...register("text",
                                        {
                                            required: '必須項目です。入力してください。',
                                        })
                                    }
                                    required
                                    onFocus={e => e.target.select()}
                                    disabled={m_bModePM || modeViewOnly}
                                />
                            </Stack>
                            <Stack width={"104px"}>
                                <Button fullWidth variant="outlined" onClick={() => setIsOpenFormat(true)} disabled={m_bModePM}>フォント</Button>
                            </Stack>
                        </Stack>
                        <Stack direction='row' justifyContent="flex-end" spacing={2} mt={1}>
                            <Button variant="contained" type="submit" disabled={modeViewOnly}>OK</Button>
                            <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                        </Stack>
                    </form >
                </DialogContent>
            </Dialog>
            {isOpenFormat && 
                <FormatTextDialog modeViewOnly={modeViewOnly} data={getValues()} onOK={handleOKFormatTextForm} onCancel={() => setIsOpenFormat(false)} />
            }
        </>
    );
};
export default TextEditorDialog;


