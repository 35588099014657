import { Box, Button, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, Stack, Tab, Tabs, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { MsSourceModel, AdjustModel } from '../../models/Index';
import { DeviceDetailsDialog } from "../details/DeviceDetailsDialog";
import { msSourceNormalCalculation, msSourceFromRXCalculation, msSourceFromZCalculation, DoCalcDemandCapacity } from "../../utils/PowerCalculation";
import TextFieldOptions from "../common/TextFieldOptions";
import { BeamsNumber } from "../../utils/FormatNumber";
import * as EKind from "../../models/ElementKind";
import { GetDetailsData } from "../../utils/DetailsDataController";
import { convertDetailsDataFromBE } from "../../utils/DataConverter";
import { MS_MODE_CHECKING } from "../../statics";
import { ROLE_LP } from "../../models/Constants";
import { isNumber } from "mathjs";

export type MsSourceEditDialogFormProps = {
    diagramData: any;
    controlID: any;
    data: any;
    userRole: any;
    onOK: (data: any) => void;
    onCancel: () => void;
    setLoadingFlag: (flag: boolean) => void;
    m_bModePM: boolean;
    modeViewOnly: boolean;
    processMode: number;
};

function a11yProps(index: number) {
    return {
        id: `tab-${index}`,
        "aria-controls": `tabpanel-${index}`
    };
}

const radioStyles = {
    padding: '4px 4px 4px 8px',
}
const fieldsetStyle = {
    border: "1px solid #AAAAAA",
    padding:"auto",
}
//Tabs------------------
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

export const MsSourceEditDialog = (props: MsSourceEditDialogFormProps) => {
    const { userRole, m_bModePM, modeViewOnly, diagramData, controlID, data, onOK, onCancel, setLoadingFlag,processMode } = props;
    const [selectTab, setSelectTab] = useState(0);
    const [openDetails, setOpenDetails] = useState(false);

    const [isDisableCalcBtn, setIsDisableCalcBtn] = useState(false);
    const [isDisableOKBtn, setIsDisableOKBtn] = useState(false);
    const [isDisableInput, setIsDisableInput] = useState<boolean>(data.symKind == 1);
    const [nodeKind, setNodeKind] = useState(data.nodeKind); // ModePM
    
    // 初期値設定
    const [initialValue, setInitialValue] = useState<MsSourceModel>({ ...data,
        capacity: BeamsNumber(data.capacity,6),
        xr: BeamsNumber(data.xr,5),
     });

    const { control, handleSubmit, formState: { errors }, setValue, getValues,setError,clearErrors } = useForm<MsSourceModel>({
        mode: 'all',
        criteriaMode: "all",
        defaultValues: initialValue,
    });
    
    const [isCalcPF, setIsCalcPF] = useState(initialValue.calcImprovePF);
    const [isDisableDetailsBtn, setIsDisableDetailsBtn] = useState(!initialValue.details.base.typeID);

    useEffect(() => {
        setValue("details", data.details)
    }, [data])
    
    // Submitイベント
    const handleEditSubmit = (value: MsSourceModel) => {
        const request = createRequestData(value);
        onOK(request);
    }
    // FormデータからRequestデータを作成する
    const createRequestData = (formValue: MsSourceModel) => {
        if(!/^[0-9]*[.]?[0-9]+$/.test(formValue.xr.toString())){
            formValue.xr = 0
        }
        if(!/^[0-9]*[.]?[0-9]+$/.test(formValue.percentR.toString())){
            formValue.percentR = 0
        }
        if(!/^[0-9]*[.]?[0-9]+$/.test(formValue.percentX.toString())){
            formValue.percentX = 0
        }
        if(!Number(formValue.demand)){
            formValue.demand = handleCalcDemandCapacity()
        }
        if(!Number(formValue.flowQMin)){
            formValue.flowQMin = 0
        }
        if(!/^[0-9]*[.]?[0-9]+$/.test(formValue.flowQMax.toString())){
            formValue.flowQMax = 0
        }
        if(!/^[0-9]*[.]?[0-9]+$/.test(formValue.appointVoltage.toString())){
            formValue.appointVoltage = 0
        }
        if(!/^[0-9]*[.]?[0-9]+$/.test(formValue.powerFactor.toString())){
            formValue.powerFactor = 0
        }
        let request = {
            ...formValue,
        } as MsSourceModel;
        return request;
    }

    // キャンセル
    const handleCancel = () => {
        onCancel();
    };

    const handleChangeFormat = (e: any) => {
        setValue(e.target.name, e.target.value);
        if(e.target.name === "flowQMax"){
            if(+getValues("flowQMin") <= +e.target.value){
                clearErrors("flowQMin")
            }
            else if(+getValues("flowQMin") > +e.target.value){
                setError("flowQMin",{type:"custom",message :"数字  <= 「最大」  を入力してください。"})
            }
        }
        else if(e.target.name === "flowQMin"){
            if(+getValues("flowQMin") <= +getValues('flowQMax') && !/^-?[0-9]*[.]?[0-9]+$/.test(getValues('flowQMax').toString())){
                clearErrors("flowQMax")
            }
        }

    }

    const handleChangeRated = (e: any) => {
        setValue(e.target.name, e.target.value);
        const selectedRated = initialValue.details.base.ratedList?.find(rated => rated.value === e.target.value)
        setValue('details.base.ratedID', selectedRated?.itemId)
        setValue('details.base.ratedValue', selectedRated?.itemValue)

        if ([1, 2].includes(initialValue.details.base.readBase.ratedFlag || -1)){
            DoGetDetailsData('details.base.ratedID');
        }
    }

    const handleChangeDetails = (e: any) => {
        setValue(e.target.name, e.target.value);
        
        if (e.target.name === "details.base.typeID"){
            let typeName = initialValue.details.base.typeList?.find(
                (item) => item.value === e.target.value
            )?.label || ''
            setValue("details.base.typeName", typeName)
        } else if (e.target.name === "details.base.ratedID"){
            let fixedName = initialValue.details.base.ratedList?.find(
                (item) => item.value === e.target.value
            )?.label || ''
            setValue("details.base.fixedName", fixedName)
        }
        DoGetDetailsData(e.target.name);
    }

    const handleChangeCheckbox = (e: any) => {
        setValue(e.target.name, e.target.checked);
    }

    const handleChangeCalcImprovePF = (e: any) => {
        setValue(e.target.name, e.target.checked);
        setIsCalcPF(e.target.checked);
    }

    const handleChangeSymKind = (e: any) => {
        setValue(e.target.name, e.target.value);
        setIsDisableInput(e.target.value == 1);
        if (e.target.value == 1){
            setValue('oldCapacity', getValues('capacity'));
            setValue('oldXr', getValues('xr'));
            setValue('xr', 0);
            handleElectricityCalculation({target: {name: 'capacity', value: 0}});
        } else {
            setValue('xr', getValues('oldXr') || 0);
            handleElectricityCalculation({target: {name: 'capacity', value: getValues('oldCapacity') || 0}});
        }
    }

    // ModePM
    const handleChangeNodeKind = (e: any) => {
        setValue(e.target.name, e.target.value);
        setNodeKind(e.target.value);
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        if (newValue >= 0) {
            if (getValues('refNo') == "" ||getValues('refNo') == null || getValues('refNo') == undefined ) {
                setError("refNo",{ type: 'custom', message: '必須項目です。入力してください。' });
            }
            else if (isNaN(Number(getValues('voltage'))) || Number(getValues('voltage')) <= 600 || !/^[0-9\b]+$/.test(getValues('voltage').toString())) {
                setError("voltage",{ type: 'custom', message: '整数 > 600 を入力してください。' });
            }
            else if (isNaN(Number(getValues('xr'))) || Number(getValues('xr'))  < 0 || !/^[0-9]*[.]?[0-9]+$/.test(getValues('xr').toString())) {
                setError("xr",{ type: 'custom', message: '数字 >= 0 を入力してください。' });
            }
            else if (isNaN(Number(getValues('percentZ'))) || Number(getValues('percentZ')) < 0 || (Number(getValues('percentZ')) == 0 && getValues("xr") != 0) || !/^[0-9]*[.]?[0-9]+$/.test(getValues('percentZ').toString())) {
                setError("percentZ",{ type: 'custom', message: '数字 > 0 を入力してください。' });
            }
            else if (isNaN(Number(getValues('percentR'))) || Number(getValues('percentR'))  < 0 || !/^[0-9]*[.]?[0-9]+$/.test(getValues('percentR').toString())) {
                setError("percentR",{ type: 'custom', message: '数字 >= 0 を入力してください。' });
            }
            else if (isNaN(Number(getValues('percentX'))) || Number(getValues('percentX'))  < 0 || !/^[0-9]*[.]?[0-9]+$/.test(getValues('percentX').toString())) {
                setError("percentX",{ type: 'custom', message: '数字 >= 0 を入力してください。' });
            }
            else if(selectTab == 1 && (isNaN(Number(getValues('details.base.ctPrimary'))) || Number(getValues('details.base.ctPrimary')) <= 0 || !/^[0-9\b]+$/.test(getValues('details.base.ctPrimary').toString()))){
                setError("details.base.ctPrimary",{ type: 'custom', message: '整数 > 0 を入力してください。' });
            }
            else if(selectTab == 1 && (isNaN(Number(getValues('details.base.ctSecondary'))) || Number(getValues('details.base.ctSecondary')) <= 0 || !/^[0-9]*[.]?[0-9]+$/.test(getValues('details.base.ctSecondary').toString()))){
                setError("details.base.ctSecondary",{ type: 'custom', message: '数字 > 0 を入力してください。' });
            }
            else if (isNaN(Number(getValues('contract'))) || Number(getValues('contract')) <= 0){
                setError("contract",{ type: 'custom', message: '数字 > 0 を入力してください。' });
            }
            else if (isNaN(Number(getValues('present'))) || Number(getValues('present')) <= 0 || Number(getValues('present')) > 1){
                setError("present",{ type: 'custom', message: '0 < 数字 <= 1 を入力してください。' });
            }
            else if (isNaN(Number(getValues('target'))) || Number(getValues('target')) <= 0 || Number(getValues('target')) > 1){
                setError("target",{ type: 'custom', message: '0 < 数字 <= 1 を入力してください。' });
            }
            else if (isNaN(Number(getValues('powerFactor'))) || Number(getValues('powerFactor')) < 0 || Number(getValues('powerFactor')) > 1 ||!/^[0-9]*[.]?[0-9]+$/.test(getValues('powerFactor').toString())){
                setError("powerFactor",{ type: 'custom', message: '0 <= 数字 <= 1 を入力してください。' });
            }
            else if (isNaN(Number(getValues('appointVoltage'))) || Number(getValues('appointVoltage')) < 0 ||!/^[0-9]*[.]?[0-9]+$/.test(getValues('appointVoltage').toString())){
                setError("appointVoltage",{ type: 'custom', message: '数字 >= 0 を入力してください。' });
            }
            else if (Number(getValues('flowQMin')) > (Number(getValues('flowQMax'))) || !/^-?[0-9]*[.]?[0-9]+$/.test(getValues('flowQMin').toString())){
                setError("flowQMin",{ type: 'custom', message: '数字  <= 「最大」  を入力してください。' });
            }
            else if(!/^[0-9]*[.]?[0-9]+$/.test(getValues('flowQMax').toString())){
                setError("flowQMax",{ type: 'custom', message: '数字を入力してください。' });
            
            }
            else {
                setSelectTab(newValue);
            }
        } 
        else{
            setSelectTab(newValue);
        }
    };

    const handleOpenDetails = () => {
        setOpenDetails(true);
    }

    const handleDetailData = (data: AdjustModel) => {
        setOpenDetails(false);
        setValue("details", data);
        
        let newDetails = {details: {...data}}
        setInitialValue(initialValue => ({...initialValue, ...newDetails}))
    }

    const handleElectricityCalculation = (e: any) => {
        setValue(e.target.name, e.target.value)
        if(e.target.name === "voltage"){
            if(e.target.value > 0){
            clearErrors(e.target.name)
            }
        }
        else if(e.target.name === "capacity"){
            if(e.target.value >= 0){
            clearErrors(e.target.name)
            }
        }
        
        let newValue;
        let voltageValue = Number(getValues("voltage"))
        let capacityValue = Number(getValues("capacity"))

        if (["voltage", "capacity", "xr"].includes(e.target.name)){
            newValue = msSourceNormalCalculation(
                voltageValue,
                capacityValue,
                +getValues("xr")
            )
            if (newValue){
                setValue("percentZ", BeamsNumber(newValue.percentZ, 6))
                setValue("percentR", BeamsNumber(newValue.percentR, 4))
                setValue("percentX", BeamsNumber(newValue.percentX, 4))
                setValue("ohmZ", BeamsNumber(newValue.ohmZ, 4))
                setValue("ohmR", BeamsNumber(newValue.ohmR, 4))
                setValue("ohmX", BeamsNumber(newValue.ohmX, 4))
            }
        } else if (["percentR", "percentX"].includes(e.target.name)){
            newValue = msSourceFromRXCalculation(
                voltageValue,
                +getValues("percentR"),
                +getValues("percentX"),
                +getValues("symKind"),
                e.target.name
            )
            if (newValue){
                setValue("capacity", BeamsNumber(newValue.capacity, 6))
                setValue("xr", BeamsNumber(newValue.xr, 5))
                setValue("percentZ", BeamsNumber(newValue.percentZ, 6))
                setValue("ohmZ", BeamsNumber(newValue.ohmZ, 4))
                setValue("ohmR", BeamsNumber(newValue.ohmR, 4))
                setValue("ohmX", BeamsNumber(newValue.ohmX, 4))
            }
        } else if (e.target.name === "percentZ"){
            newValue = msSourceFromZCalculation(
                voltageValue,
                +getValues("xr"),
                +e.target.value,
                getValues("symKind"),
                e.target.name
            )
            if (newValue){
                setValue("capacity", BeamsNumber(newValue.capacity, 6))
                setValue("percentR", BeamsNumber(newValue.percentR, 4))
                setValue("percentX", BeamsNumber(newValue.percentX, 4))
                setValue("ohmZ", BeamsNumber(newValue.ohmZ, 4))
                setValue("ohmR", BeamsNumber(newValue.ohmR, 4))
                setValue("ohmX", BeamsNumber(newValue.ohmX, 4))
            }
        }
        clearErrorsSource()

    }

    const handleInput = (e: any) => {
        setValue(e.target.name, e.target.value)
        setValue("demand", "")
        
        if (
            Number(getValues("present")) > 1 ||
            Number(getValues("target")) > 1 ||
            Number(getValues("contract")) < 0 ||
            Number(getValues("present")) < 0 ||
            Number(getValues("target")) < 0
        ) {
            setIsDisableCalcBtn(true);
            setIsDisableOKBtn(true);
        } else {
            setIsDisableCalcBtn(false);
            setIsDisableOKBtn(false);
        }
    }

    const handleCalcDemandCapacity = () => {
        let value = DoCalcDemandCapacity(
            Number(getValues("contract")) || 0,
            Number(getValues("present")) || 0,
            Number(getValues("target")) || 0,
            diagramData[0].shape,
            controlID
        )
        setValue("demand", BeamsNumber(value, 6))
        return BeamsNumber(value, 6)
    }

    const DoGetDetailsData = async (name: string) => {
        let elementType = EKind.MS_SOURCE
        let details = getValues('details')

        setLoadingFlag(true)
        let newDetailsData = await GetDetailsData(elementType, details.base, name)
        if (newDetailsData){
            let convertedData = convertDetailsDataFromBE(newDetailsData)
            
            details.base = {...details.base, ...convertedData.base}
            details.parts = convertedData.parts
            details.delaySettings = convertedData.delaySettings
            setValue('details', details)

            setInitialValue(initialValue => ({...initialValue, details: details}))
            setIsDisableDetailsBtn(!details.base.typeID)
        }
    
        setLoadingFlag(false)
    }
    const clearErrorsSource =() =>{
        if(getValues('capacity') >= 0){
            clearErrors("capacity")
        }
        if(getValues("xr") >= 0){
            clearErrors('xr')
        }
        if(getValues('percentZ') > 0){
            clearErrors('percentZ')
        }
        if(getValues('percentR') >= 0){
            clearErrors('percentR')
        }
        if(getValues('percentX') >= 0){
            clearErrors('percentX')
        }
    }
    return (
        <>
            <div>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={selectTab} onChange={handleChange}
                        variant="scrollable" 
                        scrollButtons 
                        allowScrollButtonsMobile 
                        sx={{
                            '& .MuiButtonBase-root.MuiTabScrollButton-root.Mui-disabled': {
                                display: "none"
                            }
                        }}
                    >
                        <Tab label="基本" {...a11yProps(0)}/>
                        <Tab label="保護協調" {...a11yProps(1)} />
                        {isCalcPF && <Tab label="力率改善" {...a11yProps(2)} />}
                        {(isCalcPF && userRole != ROLE_LP) && <Tab label="潮流解析" {...a11yProps(3)} />}
                        {isCalcPF && <Tab label="シンボル" {...a11yProps(4)} />}
                        
                        {(!isCalcPF && userRole != ROLE_LP) && <Tab label="潮流解析" {...a11yProps(2)} />}
                        {!isCalcPF && <Tab label="シンボル" {...a11yProps(3)} />}

                    </Tabs>
                </Box>
                
                <form onSubmit={handleSubmit(handleEditSubmit)}>
                    <div style={{ height: '320px', width: '100%', margin: 'auto', overflow: "auto" }}>
                        {/* tab0 */}
                        <TabPanel value={selectTab} index={0}>
                            <Grid container my={1}>
                                <Grid item xs={7}>
                                    <Stack spacing={0.3}>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="35%">デバイス名:</Typography>
                                            <Controller
                                                name="refNo"
                                                control={control}
                                                rules={{
                                                    required: '必須項目です。入力してください。',
                                                }}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        onChange={(e) =>{field.onChange(e);handleChangeFormat(e)}}
                                                        inputProps={{readOnly: m_bModePM || modeViewOnly}}
                                                        InputProps={{ 
                                                            sx: { 
                                                                height: 32,
                                                                background:m_bModePM || modeViewOnly?'floralwhite':'white',
                                                                color: m_bModePM || modeViewOnly?'grey':'black',
                                                            }
                                                        }}
                                                        error={!!errors?.refNo}
                                                        helperText={errors?.refNo?.message}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                        
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="35%">事故点名称:</Typography>
                                            <Controller
                                                name="pointText"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        onChange={handleChangeFormat}
                                                        inputProps={{readOnly: m_bModePM || modeViewOnly}}
                                                        InputProps={{ 
                                                            sx: { 
                                                                height: 32,
                                                                background:m_bModePM || modeViewOnly?'floralwhite':'white',
                                                                color: m_bModePM || modeViewOnly?'grey':'black',
                                                            }
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Stack>

                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="35%">受電電圧(V):</Typography>
                                            <FormControl fullWidth size="small">
                                                <Controller
                                                    name="voltage"
                                                    control={control}
                                                    rules={{ required: '必須項目です。入力してください。' ,
                                                        pattern:{
                                                            value: /^[0-9\b]+$/,
                                                            message: '数字を入力してください。'
                                                        },
                                                        validate: value => value > 600 || '整数 > 600 を入力してください。' ,
                                                    }}
                                                    render={({ field }: any) => (
                                                        <TextFieldOptions
                                                            {...field}
                                                            disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}
                                                            options={initialValue.voltageList}
                                                            // type="number"
                                                            size="small"
                                                            setValue={(name: any, value: any) => {
                                                                handleElectricityCalculation({target: {name: name, value: value}})
                                                            }}
                                                            height={32}
                                                            maxLength={6}
                                                            error={!!errors?.voltage}
                                                            helperText={errors?.voltage?.message}
                                                        />
                                                    )}
                                                />
                                            </FormControl>
                                        </Stack>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="35%">容量(MVA):</Typography>
                                            <FormControl fullWidth size="small">
                                                <Controller
                                                    name="capacity"
                                                    control={control}
                                                    rules={{ required: '必須項目です。入力してください。',
                                                        pattern:{
                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                            message: '数字を入力してください。'
                                                        },
                                                        validate: value => value >= 0 || '数字 >= 0 を入力してください。' ,
                                                    }}
                                                    render={({ field }: any) => (
                                                        <TextFieldOptions
                                                            {...field}
                                                            options={initialValue.capacityList}
                                                            // type="number"
                                                            size="small"
                                                            setValue={(name: any, value: any) => {
                                                                field.onChange({target: {name: name, value: value}});
                                                                handleElectricityCalculation({target: {name: name, value: value}})
                                                            }}
                                                            height={32}
                                                            maxLength={6}
                                                            error={!!errors?.capacity}
                                                            helperText={errors?.capacity?.message}
                                                            disabled={ modeViewOnly || isDisableInput || processMode > MS_MODE_CHECKING}

                                                        />
                                                    )}
                                                />
                                            </FormControl>
                                        </Stack>
                                    </Stack>
                                </Grid>
                                <Grid item xs={5}>
                                    <Stack pl={3}>
                                        <Stack>
                                            <Controller
                                                name="fault"
                                                control={control}
                                                render={({ field }: any) => (
                                                    <FormControlLabel 
                                                        {...field} 
                                                        disabled={m_bModePM || modeViewOnly}
                                                        control={<Checkbox style={radioStyles} size="small"/>} 
                                                        checked={field.value}
                                                        label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>短絡電流を計算</Typography>} 
                                                        name='fault' 
                                                        onChange={handleChangeCheckbox}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                        <Stack>
                                            <Controller
                                                name="calcImprovePF"
                                                control={control}
                                                render={({ field }: any) => (
                                                    <FormControlLabel 
                                                        {...field} 
                                                        disabled={m_bModePM || modeViewOnly}
                                                        control={<Checkbox style={radioStyles} size="small"/>} 
                                                        checked={field.value} 
                                                        label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>力率改善を計算</Typography>} 
                                                        name='calcImprovePF' 
                                                        onChange={handleChangeCalcImprovePF} 
                                                    />
                                                )}
                                            />
                                        </Stack>
                                        <Stack>
                                            <Controller
                                                name="viewResultText"
                                                control={control}
                                                render={({ field }: any) => (
                                                    <FormControlLabel 
                                                        {...field} 
                                                        disabled={m_bModePM || modeViewOnly}
                                                        control={<Checkbox style={radioStyles} size="small"/>}
                                                        checked={field.value}
                                                        label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>注釈を表示</Typography>}
                                                        name='viewResultText' 
                                                        onChange={handleChangeCheckbox} 
                                                    />
                                                )}
                                            />
                                        </Stack>
                                    </Stack>
                                </Grid>
                            </Grid>
                            <fieldset style={{border:"1px solid #AAAA"}}>
                                <legend ><Typography variant="body2" width="100%">インピーダンス(基準容量10MVAべース)</Typography></legend>
                                <Stack spacing={0.3}>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="100%">X/R:</Typography>
                                                <Controller
                                                    name="xr"
                                                    control={control}
                                                    rules={{
                                                        pattern:{
                                                        value: /^[0-9]*[.]?[0-9]+$/,
                                                        message: '数字を入力してください。'
                                                        },
                                                        validate: value => value >= 0 || '  数字 >= 0 を入力してください。' ,
                                                        }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            // type="number"
                                                            size="small"
                                                            onChange={(e) => {field.onChange(e);handleElectricityCalculation(e)}}
                                                            style={{
                                                                backgroundColor: modeViewOnly || isDisableInput || processMode > MS_MODE_CHECKING ? 'floralwhite' : 'white',
                                                            }}
                                                            sx={{ width: 200 }}
                                                            InputProps={{sx: { height: 32,color: modeViewOnly || isDisableInput || processMode > MS_MODE_CHECKING?'grey':'black' }}}
                                                            inputProps={{maxlength:5, readOnly: modeViewOnly || isDisableInput || processMode > MS_MODE_CHECKING }}
                                                            error={!!errors?.xr}
                                                            helperText={errors?.xr?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width='100%'>%インピーダンス(%):</Typography>
                                                <Controller
                                                    name="percentZ"
                                                    control={control}
                                                    rules={{
                                                        pattern:{
                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                            message: '数字を入力してください。'
                                                        },
                                                        validate: value => (value > 0 || (getValues("xr") == 0 && value == 0)) || '数字 > 0 を入力してください。' ,
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            // type="number"
                                                            size="small"
                                                            onChange={(e) => {field.onChange(e); handleElectricityCalculation(e)}}
                                                            style={{
                                                                backgroundColor: modeViewOnly || isDisableInput || processMode > MS_MODE_CHECKING? 'floralwhite' : 'white',
                                                            }}
                                                            sx={{ width: 200 }}
                                                            InputProps={{sx: { height: 32 ,color:modeViewOnly || isDisableInput || processMode > MS_MODE_CHECKING?'grey':'black' }}}
                                                            inputProps={{maxlength:6, readOnly: modeViewOnly || isDisableInput || processMode > MS_MODE_CHECKING }}
                                                            error={!!errors?.percentZ}
                                                            helperText={errors?.percentZ?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={0.5} style={{ marginTop: 'auto', marginBottom: 'auto', textAlign: 'center' }}>
                                            <span>=</span>
                                        </Grid>
                                        <Grid item xs={2.5}>
                                            <Stack>
                                                <Controller
                                                    name="percentR"
                                                    control={control}
                                                    rules={{
                                                        pattern:{
                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                            message: '数字を入力してください。'
                                                        },
                                                        validate: value => value >= 0 || '数字 >= 0 を入力してください。' ,
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            // type="number"
                                                            size="small"
                                                            onChange={(e) => {
                                                                field.onChange(e);
                                                                handleElectricityCalculation(e)}}
                                                            inputProps={{
                                                                maxlength:6,
                                                                readOnly: modeViewOnly || isDisableInput,
                                                            }}
                                                            style={{
                                                                backgroundColor: modeViewOnly || isDisableInput ? 'floralwhite' : 'white',
                                                            }}
                                                            InputProps={{sx: { height: 32 ,color: modeViewOnly || isDisableInput?'grey':'black' }}}
                                                            error={!!errors?.percentR}
                                                            helperText={errors?.percentR?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={0.5} style={{ marginTop: 'auto', marginBottom: 'auto', textAlign: 'center' }}>
                                            <span>+ j</span>
                                        </Grid>
                                        <Grid item xs={2.5}>
                                            <Stack>
                                                <Controller
                                                    name="percentX"
                                                    control={control}
                                                    rules={{
                                                        pattern:{
                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                            message: '数字を入力してください。'
                                                        },
                                                        validate: value => value >= 0 || '数字 >= 0 を入力してください。' ,
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            // type="number"
                                                            size="small"
                                                            onChange={(e) => handleElectricityCalculation(e)}
                                                            inputProps={{
                                                                maxlength:6,
                                                                readOnly: modeViewOnly || isDisableInput,
                                                            }}
                                                            style={{
                                                                backgroundColor: modeViewOnly || isDisableInput ? 'floralwhite' : 'white',
                                                            }}
                                                            InputProps={{sx: { height: 32 ,color: modeViewOnly || isDisableInput?'grey':'black' }}}
                                                            error={!!errors?.percentX}
                                                            helperText={errors?.percentX?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width='100%'>インピーダンス(mΩ):</Typography>
                                                <Controller
                                                    name="ohmZ"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            type="number"
                                                            size="small"
                                                            inputProps={{ readOnly: true }}
                                                            style={{ backgroundColor: 'floralwhite' }}
                                                            sx={{ width: 200 }}
                                                            InputProps={{sx: { height: 32,color:'grey' }}}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={0.5} style={{ marginTop: 'auto', marginBottom: 'auto', textAlign: 'center' }}>
                                            <span>=</span>
                                        </Grid>
                                        <Grid item xs={2.5}>
                                            <Stack>
                                                <Controller
                                                    name="ohmR"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type="number"
                                                            size="small"
                                                            inputProps={{ readOnly: true }}
                                                            style={{ backgroundColor: 'floralwhite' }}
                                                            InputProps={{sx: { height: 32,color:'grey'  }}}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={0.5} style={{ marginTop: 'auto', marginBottom: 'auto', textAlign: 'center' }}>
                                            <span>+ j</span>
                                        </Grid>
                                        <Grid item xs={2.5}>
                                            <Stack>
                                                <Controller
                                                    name="ohmX"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type="number"
                                                            size="small"
                                                            inputProps={{ readOnly: true }}
                                                            style={{ backgroundColor: 'floralwhite' }}
                                                            InputProps={{sx: { height: 32 ,color:'grey' }}}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Stack>
                            </fieldset>
                        </TabPanel>

                        {/* tab1 */}
                        <TabPanel value={selectTab} index={1}>
                            <Stack my={1} spacing={0.3}>
                                <Grid container>
                                    <Grid item xs={5}>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="50%">CT一次側:</Typography>
                                            <FormControl fullWidth size="small">
                                                <InputLabel sx={{ color: 'black' }}></InputLabel>
                                                <Controller
                                                    name="details.base.ctPrimary"
                                                    control={control}
                                                    rules={{ required: '必須項目です。入力してください。' ,
                                                        pattern:{
                                                            value: /^[0-9\b]+$/,
                                                            message: '数字を入力してください。'
                                                        },
                                                        validate: value => value > 0 || '整数 > 0 を入力してください。' ,
                                                    }}
                                                    render={({ field }: any) => (
                                                        <TextFieldOptions
                                                            {...field}
                                                            disabled={m_bModePM || modeViewOnly}
                                                            options={initialValue.details.base.ctPrimaryList}
                                                            // type="number"
                                                            size="small"
                                                            setValue={(name: any, value: any) => {
                                                                field.onChange({target:{name:name,value:value}});
                                                                handleChangeFormat({target:{name:name,value:value}})
                                                            }}
                                                            height={32}
                                                            maxLength={4}
                                                            error={!!errors?.details?.base?.ctPrimary}
                                                            helperText={errors?.details?.base?.ctPrimary?.message}
                                                        />
                                                    )}
                                                    // // rules={{ required: '必須項目です。入力してください。' }}
                                                    // render={({ field }) => (
                                                    //     <Select
                                                    //         {...field}
                                                    //         disabled={m_bModePM}
                                                    //         error={!!errors?.details?.base?.ctPrimary}
                                                    //         onChange={handleChangeFormat}
                                                    //         sx={{ 
                                                    //             height: 32,
                                                    //             background:m_bModePM?'floralwhite':'white',
                                                    //         }}
                                                    //     >
                                                    //         {initialValue.details.base.ctPrimaryList?.map(item => (
                                                    //             <MenuItem key={item.label} value={item.value}>
                                                    //                 {item.label}
                                                    //             </MenuItem>
                                                    //         ))}
                                                    //     </Select>
                                                    // )}
                                                />
                                            </FormControl>
                                        </Stack>
                                        
                                    </Grid>
                                    <Grid item xs={5} ml={3}>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="50%">CT二次側:</Typography>
                                            
                                            <FormControl fullWidth size="small">
                                                <Controller
                                                    name="details.base.ctSecondary"
                                                    control={control}
                                                    rules={{ required: '必須項目です。入力してください。' ,
                                                        pattern:{
                                                            value: /^[0-9\b]+$/,
                                                            message: '数字を入力してください。'
                                                        },
                                                        validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                    }}
                                                    render={({ field }: any) => (
                                                        <TextFieldOptions
                                                            {...field}
                                                            disabled={m_bModePM || modeViewOnly}
                                                            options={initialValue.details.base.ctSecondaryList}
                                                            // type="number"
                                                            size="small"
                                                            setValue={(name: any, value: any) => {
                                                                field.onChange({target:{name:name,value:value}});
                                                                handleChangeFormat({target:{name:name,value:value}})
                                                            }}
                                                            height={32}
                                                            maxLength={3}
                                                            error={!!errors?.details?.base?.ctSecondary}
                                                            helperText={errors?.details?.base?.ctSecondary?.message}
                                                        />
                                                    )}
                                                    // rules={{ required: '必須項目です。入力してください。' }}
                                                    // render={({ field }) => (
                                                    //     <Select
                                                    //         {...field}
                                                    //         disabled={m_bModePM}
                                                    //         error={!!errors?.details?.base?.ctSecondary}
                                                    //         onChange={handleChangeFormat}
                                                    //         sx={{ height: 32, background:m_bModePM?'floralwhite':'white' }}
                                                    //     >
                                                    //         {initialValue.details.base.ctSecondaryList?.map(item => (
                                                    //             <MenuItem key={item.label} value={item.value}>
                                                    //                 {item.label}
                                                    //             </MenuItem>
                                                    //         ))}
                                                    //     </Select>
                                                    // )}
                                                />
                                            </FormControl>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Stack>

                            <fieldset style={{border:"1px solid #AAAA"}}>
                                <legend><Typography variant="body2">動作特性曲線</Typography></legend>
                                <Stack spacing={0.3}>
                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                        <Typography variant="body2" width="25%">電力会社:</Typography>
                                        <FormControl fullWidth size="small">
                                            <Controller
                                                name="details.base.kindID"
                                                control={control}
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        onChange={handleChangeDetails}
                                                        disabled={ modeViewOnly || m_bModePM || initialValue.details.base.kindList?.length === 0}
                                                        style={{
                                                            backgroundColor:
                                                            modeViewOnly || m_bModePM || initialValue.details.base.kindList?.length === 0
                                                                    ? "floralwhite"
                                                                    : "white",
                                                        }}
                                                        sx={{ height: 32 }}
                                                    >
                                                        {initialValue.details.base.kindList?.map(item => (
                                                            <MenuItem key={item.label} value={item.value}>
                                                                {item.label}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                )}
                                            />
                                        </FormControl>
                                    </Stack>
                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                        <Typography variant="body2" width="25%">形式:</Typography>
                                        <FormControl fullWidth size="small">
                                            <Controller
                                                name="details.base.typeID"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextFieldOptions
                                                        {...field}
                                                        options={initialValue.details.base.typeList}
                                                        // type="number"
                                                        size="small"
                                                        setValue={(name: any, value: any) => {
                                                            field.onChange({target:{name:name,value:value}});
                                                            handleChangeDetails({target:{name:name,value:value}})
                                                        }}
                                                        notAllowInput={true}
                                                        height={32}
                                                        disabled={m_bModePM ||modeViewOnly|| initialValue.details.base.typeList?.length === 0}
                                                        style={{backgroundColor:
                                                                        m_bModePM || initialValue.details.base.typeList?.length === 0
                                                                            ? "floralwhite"
                                                                            : "white"
                                                                        }}
                                                        value={getValues("details.base.typeName")}
                                                    />
                                                )}
                                            />
                                        </FormControl>
                                    </Stack>
                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                        <Typography variant="body2" width="25%">
                                            {initialValue.details.base.opeKindTitle &&
                                            (initialValue.details.base.opeKindTitle + ':')}
                                        </Typography>
                                        <FormControl fullWidth size="small">
                                            <Controller
                                                name="details.base.opeKindID"
                                                control={control}
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        onChange={handleChangeDetails}
                                                        disabled={ modeViewOnly || m_bModePM || initialValue.details.base.opeKindList?.length === 0}
                                                        style={{
                                                            backgroundColor:
                                                            modeViewOnly || m_bModePM || initialValue.details.base.opeKindList?.length === 0
                                                                    ? "floralwhite"
                                                                    : "white",
                                                        }}
                                                        sx={{ height: 32 }}
                                                    >
                                                        {initialValue.details.base.opeKindList?.map(item => (
                                                            <MenuItem key={item.label} value={item.value}>
                                                                {item.label}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                )}
                                            />
                                        </FormControl>
                                    </Stack>
                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                        <Typography variant="body2" width="25%">範囲:</Typography>
                                        <FormControl fullWidth size="small">
                                            <Controller
                                                name="details.base.ratedRangeID"
                                                control={control}
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        onChange={handleChangeDetails}
                                                        disabled={ modeViewOnly || m_bModePM || initialValue.details.base.ratedRangeList?.length === 0}
                                                        style={{
                                                            backgroundColor:
                                                            modeViewOnly || m_bModePM || initialValue.details.base.ratedRangeList?.length === 0
                                                                    ? "floralwhite"
                                                                    : "white",
                                                        }}
                                                        sx={{ height: 32,color:initialValue.details.base.ratedRangeList?.length === 0?'grey':'black' }}
                                                    >
                                                        {initialValue.details.base.ratedRangeList?.map(item => (
                                                            <MenuItem key={item.label} value={item.value}>
                                                                {item.label}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                )}
                                            />
                                        </FormControl>
                                    </Stack>
                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                        <Typography variant="body2" width="25%">
                                            {initialValue.details.base.ratedTitle &&
                                            (initialValue.details.base.ratedTitle + ' (A):')}
                                        </Typography>
                                        <FormControl fullWidth size="small">
                                            <Controller
                                                name="details.base.ratedSelectionValue"
                                                control={control}
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        onChange={handleChangeRated}
                                                        disabled={modeViewOnly || m_bModePM || initialValue.details.base.ratedList?.length === 0}
                                                        style={{
                                                            backgroundColor:
                                                            modeViewOnly || m_bModePM || initialValue.details.base.ratedList?.length === 0
                                                                    ? "floralwhite"
                                                                    : "white",
                                                        }}
                                                        sx={{ height: 32 }}
                                                    >
                                                        {initialValue.details.base.ratedList?.map(item => (
                                                            <MenuItem key={item.label} value={item.value}>
                                                                {item.label}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                )}
                                            />
                                        </FormControl>
                                    </Stack>
                                    <Grid item xs={2} justifyContent="flex-end" display='flex'>
                                        <Button variant="outlined" size="medium" onClick={handleOpenDetails} disabled={isDisableDetailsBtn}>詳細...</Button>
                                    </Grid>
                                </Stack>
                            </fieldset>
                        </TabPanel>

                        {/* tab2 */}
                        {isCalcPF &&
                        <TabPanel value={selectTab} index={2}>
                            <Stack my={1}>
                                <Typography variant="body2">受電端での総負荷容量と力率(測定値)から、目標となる力率に改善するために必要なコンデンサ容量を計算します。</Typography>
                                <Stack spacing={0.5} mt={3}>
                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                        <Typography variant="body2" width="50%">契約電力(kW):</Typography>
                                        <Controller
                                            name="contract"
                                            control={control}
                                            rules={{
                                                required: '必須項目です。入力してください。' ,
                                                pattern:{
                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                message: '数字を入力してください。'
                                                },
                                                validate: value => value > 0 || '  数字 > 0 を入力してください。' ,
                                                }}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    disabled={m_bModePM}
                                                    fullWidth
                                                    // type="number"
                                                    size="small"
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                        handleInput(e);
                                                    }}
                                                    InputProps={{ sx: { height: 32, background:m_bModePM || modeViewOnly?'floralwhite':'white', color: modeViewOnly ?'grey':"black" } }}
                                                    inputProps={{maxlength:7, readOnly: modeViewOnly}}
                                                    error={!!errors?.contract}
                                                    helperText={errors?.contract?.message}
                                                />
                                            )}
                                        />
                                    </Stack>
                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                        <Typography variant="body2" width="50%">電源回路の力率(実測値):</Typography>
                                        <Controller
                                            name="present"
                                            control={control}
                                            rules={{
                                                required: '必須項目です。入力してください。' ,
                                                pattern:{
                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                message: '数字を入力してください。'
                                                },
                                                validate: value => value > 0 && value <= 1|| ' 0 < 数字 <= 1  を入力してください。' ,
                                                }}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    disabled={m_bModePM}
                                                    fullWidth
                                                    // type="number"
                                                    size="small"
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                        handleInput(e);
                                                    }}
                                                    InputProps={{ sx: { height: 32, background:m_bModePM || modeViewOnly?'floralwhite':'white', color: modeViewOnly ?'grey':"black" } }}
                                                    inputProps={{maxlength:5, readOnly: modeViewOnly}}
                                                    error={!!errors?.present}
                                                    helperText={errors?.present?.message}
                                                />
                                            )}
                                        />
                                    </Stack>
                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                        <Typography variant="body2" width="50%">改善後の目標力率:</Typography>
                                        <Controller
                                            name="target"
                                            control={control}
                                            rules={{
                                                required: '必須項目です。入力してください。' ,
                                                pattern:{
                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                message: '数字を入力してください。'
                                                },
                                                validate: value => value > 0 && value <= 1|| ' 0 < 数字 <= 1  を入力してください。' ,
                                                }}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    disabled={m_bModePM}
                                                    fullWidth
                                                    // type="number"
                                                    size="small"
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                        handleInput(e);
                                                    }}
                                                    InputProps={{ sx: { height: 32, background:m_bModePM || modeViewOnly?'floralwhite':'white', color: modeViewOnly ?'grey':"black"} }}
                                                    inputProps={{maxlength:5, readOnly: modeViewOnly}}
                                                    error={!!errors?.target}
                                                    helperText={errors?.target?.message}
                                                />
                                            )}
                                        />
                                    </Stack>
                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                        <Typography variant="body2" width="100%">力率改善に必要なコンデンサ容量(kvar):</Typography>
                                        <Controller
                                            name="demand"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    type="number"
                                                    size="small"
                                                    inputProps={{ readOnly: true }}
                                                    style={{
                                                        backgroundColor: 'floralwhite',
                                                    }}
                                                    InputProps={{ sx: { height: 32,color:'grey' } }}
                                                />
                                            )}
                                        />
                                    </Stack>
                                    <Grid item xs={3} justifyContent="flex-end" display='flex'>
                                        <Button 
                                            variant="outlined" 
                                            size="medium" 
                                            onClick={handleCalcDemandCapacity}
                                            disabled = {isDisableCalcBtn || modeViewOnly}
                                        >
                                            計算
                                        </Button>
                                    </Grid>
                                </Stack>
                            </Stack>
                        </TabPanel>
                        }

                        {/* tab3 -- ModePM */}
                        {userRole != ROLE_LP &&
                        <TabPanel value={selectTab} index={isCalcPF?3:2}>
                            <Grid container mt={1}>
                                <Grid item xs={7.5}>
                                    <fieldset style={fieldsetStyle}>
                                        <legend ><Typography variant="body2">運転モード</Typography></legend>
                                        <Controller
                                            name="nodeKind"
                                            control={control}
                                            render={({ field }) => (
                                                <RadioGroup
                                                    {...field}
                                                    onChange={handleChangeNodeKind}
                                                    row
                                                >
                                                    <FormControlLabel 
                                                        value="0" 
                                                        disabled={m_bModePM || modeViewOnly}
                                                        control={<Radio style={radioStyles} size="small"/>} 
                                                        label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>スラック電源</Typography>}
                                                    />
                                                    <FormControlLabel 
                                                        value="1" 
                                                        disabled={m_bModePM || modeViewOnly}
                                                        control={<Radio style={radioStyles} size="small"/>} 
                                                        label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>PQ指定</Typography>}
                                                    />
                                                    <FormControlLabel 
                                                        value="2" 
                                                        disabled={m_bModePM || modeViewOnly}
                                                        control={<Radio style={radioStyles} size="small"/>} 
                                                        label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>PV指定</Typography>}
                                                    />
                                                </RadioGroup>
                                            )}
                                        />
                                    </fieldset>
                                </Grid>
                                <Grid item xs={4.5} pl={1} mt={2}>
                                    <FormControlLabel
                                        control={
                                            <Controller
                                                name='viewFlowResult'
                                                control={control}
                                                render={({ field }) => (
                                                    <Checkbox
                                                        {...field}
                                                        disabled={m_bModePM || modeViewOnly}
                                                        checked={field.value}
                                                        onChange={handleChangeCheckbox}
                                                        size="small"
                                                        style={{padding:'4px 4px 4px 8px'}}
                                                    />
                                                )}
                                            />
                                        }
                                        label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>潮流解析結果を表示</Typography>}
                                    />
                                </Grid>

                                <Grid item xs={5} mt={2}>
                                    <fieldset 
                                        style={
                                            // color: nodeKind == 0 ? 'grey' : 'black', 
                                            // borderColor: nodeKind == 0 ? 'white' : undefined,
                                            fieldsetStyle
                                        }
                                    >
                                        <legend><Typography variant="body2" style={{color:nodeKind == 0?"grey":"black"}}>電源回路の力率(実測値)</Typography></legend>
                                        <Grid container>
                                            <Grid item xs={6}>
                                                <Controller
                                                    name='leadLag'
                                                    control={control}
                                                    render={({ field }) => (
                                                        <RadioGroup {...field} row onChange={handleChangeFormat}>
                                                            <FormControlLabel 
                                                                value="1"
                                                                control={<Radio style={radioStyles} size="small"/>} 
                                                                label={<Typography variant="body2" style={{color:m_bModePM || nodeKind == 0?"grey":"black"}}>進み</Typography>}
                                                                disabled={m_bModePM || modeViewOnly || nodeKind == 0}
                                                            />
                                                            <FormControlLabel 
                                                                value="0"
                                                                control={<Radio style={radioStyles} size="small"/>} 
                                                                label={<Typography variant="body2" style={{color:m_bModePM || nodeKind == 0?"grey":"black"}}>遅れ</Typography>}
                                                                disabled={m_bModePM || modeViewOnly || nodeKind == 0}
                                                            />
                                                        </RadioGroup>
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={6} mt={1}>
                                                <Controller
                                                    name='powerFactor'
                                                    control={control}
                                                    rules={{
                                                        pattern:{
                                                        value: /^[0-9]*[.]?[0-9]+$/,
                                                        message: '数字を入力してください。'
                                                        },
                                                        validate: value => value >= 0 && value <= 1|| ' 0 =< 数字 <= 1  を入力してください。' ,
                                                        }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            // type='number'
                                                            size='small'
                                                            onChange={(e) =>{field.onChange(e);handleChangeFormat(e)}}
                                                            inputProps={{maxlength:5, readOnly: m_bModePM || modeViewOnly || nodeKind == 0 }}
                                                            style={{
                                                                backgroundColor: m_bModePM || modeViewOnly || nodeKind == 0 ? 'floralwhite' : 'white',
                                                            }}
                                                            InputProps={{ sx: { height: 32,color:m_bModePM || modeViewOnly || nodeKind == 0 ?"grey":"black" } }}
                                                            error={!!errors?.powerFactor}
                                                            helperText={errors?.powerFactor?.message}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                    </fieldset>
                                </Grid>
                                <Grid item xs={7} mt={2} pl={5}>
                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                        <Typography variant="body2" width="40%" style={{color: nodeKind != 1 ? 'black' : 'grey'}}>電圧指定値(pu):</Typography>
                                        <Controller
                                            name='appointVoltage'
                                            control={control}
                                            rules={{
                                                pattern:{
                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                message: '数字を入力してください。'
                                                },
                                                validate: value => value >= 0 || ' 数字  >= 0  を入力してください。' ,
                                                }}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    // fullWidth
                                                    // type='number'
                                                    size='small'
                                                    onChange={(e) =>{field.onChange(e);handleChangeFormat(e)}}
                                                    inputProps={{
                                                        maxlength:5,
                                                        readOnly: m_bModePM || modeViewOnly || nodeKind == 1,
                                                    }}
                                                    style={{
                                                        backgroundColor: m_bModePM || modeViewOnly || nodeKind == 1 ? 'floralwhite' : 'white',
                                                        width:"167px"
                                                    }}
                                                    InputProps={{ sx: { height: 32 ,color:m_bModePM || modeViewOnly || nodeKind == 1 ?"grey":"black",width:167} }}
                                                    error={!!errors?.appointVoltage}
                                                    helperText={errors?.appointVoltage?.message}
                                                />
                                            )}
                                        />
                                    </Stack>
                                    <Stack mt={2}>
                                        <fieldset 
                                            style={
                                                fieldsetStyle
                                                // color: nodeKind != 2 ? 'grey' : 'black', 
                                                // borderColor: nodeKind != 2 ? 'white' : undefined 
                                            }
                                        >
                                            <legend><Typography variant="body2"color={nodeKind != 2 ? 'grey' : 'black'}>無効電力の変動範囲</Typography></legend>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="50%" color={nodeKind != 2 ? 'grey' : 'black'}>最小 (Mvar):</Typography>
                                                <Controller
                                                    name='flowQMin'
                                                    control={control}
                                                    rules={{
                                                        pattern:{
                                                        value: /^-?[0-9]*[.]?[0-9]+$/,
                                                        message: '数字を入力してください。'
                                                        },
                                                        validate: value => value <= +getValues("flowQMax") || '数字  <= 「最大」  を入力してください。' ,
                                                        }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            // type='number'
                                                            size='small'
                                                            onChange={(e) =>{field.onChange(e);handleChangeFormat(e)}}
                                                            inputProps={{maxlength:6, readOnly: m_bModePM || modeViewOnly || nodeKind != 2 }}
                                                            style={{
                                                                backgroundColor: m_bModePM || modeViewOnly || nodeKind != 2 ? 'floralwhite' : 'white',
                                                            }}
                                                            InputProps={{ sx: { height: 32,color:m_bModePM || modeViewOnly || nodeKind != 2 ?"grey":"black" } }}
                                                            error={!!errors?.flowQMin}
                                                            helperText={errors?.flowQMin?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack mt={1} sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="50%" color={nodeKind != 2 ? 'grey' : 'black'}>最大 (Mvar):</Typography>
                                                <Controller
                                                    name='flowQMax'
                                                    control={control}
                                                    rules={{
                                                        pattern:{
                                                        value: /^[0-9]*[.]?[0-9]+$/,
                                                        message: '数字を入力してください。'
                                                        },
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            // type='number'
                                                            size='small'
                                                            onChange={(e) =>{field.onChange(e);handleChangeFormat(e)}}
                                                            inputProps={{
                                                                maxlength:6,
                                                                readOnly: m_bModePM || modeViewOnly || nodeKind != 2,
                                                            }}
                                                            style={{
                                                                backgroundColor: m_bModePM || modeViewOnly || nodeKind != 2 ? 'floralwhite' : 'white',
                                                            }}
                                                            InputProps={{ sx: { height: 32,color:m_bModePM || modeViewOnly || nodeKind != 2 ?"grey":"black" } }}
                                                            error={!!errors?.flowQMax}
                                                            helperText={errors?.flowQMax?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                        </fieldset>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </TabPanel>
                        }
                        {/* tab4 */}
                        <TabPanel value={selectTab} index={(isCalcPF?4:3) - (userRole != ROLE_LP ? 0 : 1)}>
                        <Stack mt={1} width={'75%'}>
                            <fieldset style={{border:"1px solid #AAAA"}}>
                                <legend ><Typography variant="body2">単線結線図で表示するシンボルの選択</Typography></legend>
                                <Grid container>
                                    <Grid item xs={5}>
                                        <Controller
                                            name='symKind'
                                            control={control}
                                            render={({ field }) => (
                                                <RadioGroup {...field} onChange={handleChangeSymKind}>
                                                    <FormControlLabel 
                                                        value='0' 
                                                        disabled={m_bModePM || modeViewOnly}
                                                        control={<Radio size='small'/>} 
                                                        label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>電源</Typography>}
                                                    />
                                                    <FormControlLabel 
                                                        value='1' 
                                                        disabled={m_bModePM || modeViewOnly}
                                                        control={<Radio size='small'/>} 
                                                        label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>無限大母線</Typography>}
                                                    />
                                                </RadioGroup>
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </fieldset>
                        </Stack>
                        </TabPanel>
                    </div>
                    <Stack direction='row' mt={1} justifyContent="flex-end" spacing={2}>
                        <Button variant="contained" type="submit" disabled={isDisableOKBtn || modeViewOnly}>OK</Button>
                        <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                    </Stack>
                </form >
                {openDetails &&
                    <DeviceDetailsDialog 
                        data={getValues("details")}
                        elementType={EKind.MS_SOURCE}
                        onOK={handleDetailData} 
                        onCancel={() => setOpenDetails(false)} 
                        setLoadingFlag={setLoadingFlag}
                        m_bModePM={m_bModePM}
                        modeViewOnly={modeViewOnly}
                    />
                }
            </div >
        </>
    );
};
export default MsSourceEditDialog;


