import { Button, CircularProgress, Grid, Stack, TextField, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import { useForm } from 'react-hook-form';
import { connect } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { PageData } from "../../../models/Index";
import { Path as RoutePath } from "../../../path";
import { ApplicationState, FetchState, actionCreators, selectors } from "../../../store";
import FetchHelper from '../../FetchHelper';
import AlertDialog from '../../common/AlertDialog';
import DialTimeGrid from './DialTimeGrid';
// -------------
// FETCH ID
const SEARCH_DIAL_TIME = "SEARCH_DIAL_TIME";
const DELETE_DIAL_TIME = "DELETE_DIAL_TIME";

// 検索フォーム
export interface SearchItemForm {
    "target": string;
    "limit": number;
    "page": number;
}

// 初期値設定
const initialValue: SearchItemForm = {
    target: '',
    limit: 50,
    page: 1,
}

const initialPageData: PageData = {
    rowFrom: '0',
    rowTo: '0',
    totalRow: '0',
    currPage: '0',
    totalPage: '0',
};
// -------------
// Props
export type DialTimeListProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

// -------------
// Component
export const DialTimeList = (props: DialTimeListProps) => {
    const {
        userId,
        onSearch,
        onError,
        onDeleteSuccess,
        onDeleteError,
        onDelete,
    } = props;

    const { control, handleSubmit, setValue, getValues } = useForm<SearchItemForm>({
        criteriaMode: "all",
        defaultValues: initialValue,
    });
    const [title, setTitle] = React.useState("");
    const [message, setMessage] = React.useState("");
    const [pageData, setPageData] = React.useState(initialPageData);
    const [colState, setColState] = useState<any>();
    const gridRef = useRef() as any;
    const [loadingFlag, setLoadingFlag] = React.useState(true);
    const [keyword, setKeyword] = useState("");
    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
    const [idDelete, setIdDelete] = useState<string>("");
    let navigate = useNavigate();

    // 検索実行
    const handleSearchSubmit = (value: SearchItemForm) => {
        let limit = value.limit || 50;
        let page = value.page || 1;

        const params = {
            userId: userId,
            requestPrms: {
                limit: limit,
                page: page
            }
        }
        
        setLoadingFlag(true);
        onSearch(params);
    }

    // 初回検索実行
    React.useEffect(() => {
        handleSearchSubmit(getValues());
    }, []);

    const handleSuccess = (data: any) => {
        console.log('>>> handleSuccess');
        if (data && data.data) {
            if (gridRef && gridRef.current) {
                gridRef.current.setRowData(data.data);
            }
            const totalCount = data.totalCount;
            const pageSize = getValues('limit');
            const page = Number(data.page);
            const totalPage = Math.ceil(totalCount / pageSize);
            const rowFrom = (pageSize * page - pageSize) + 1;
            const rowTo = (pageSize * page) > totalCount ? totalCount : (pageSize * page);
            const updateData: PageData = {
                rowFrom: rowFrom.toLocaleString(),
                rowTo: rowTo.toLocaleString(),
                totalRow: totalCount.toLocaleString(),
                currPage: page.toLocaleString(),
                totalPage: totalPage.toLocaleString(),
            };
            setPageData(updateData);
        }
        else {
            if (gridRef && gridRef.current) {
                gridRef.current.setRowData([]);
            }
        }
        setLoadingFlag(false);
    };

    // 検索エラー時
    const handleError = (success: boolean, data: any, error: any) => {
        console.log('>>> handleError');
        onError(success, data, error);
        setLoadingFlag(false);
        gridRef.current.hideOverlay();
    };

    // ページ数変更時にAPI検索する
    const handleChangePageData = (name: any, value: any) => {
        // 値変更
        setValue(name, value);
        if (name === 'limit') {
            setValue("page", 1);
        }
        handleSearchSubmit(getValues());
    };

    //削除の処理
    const handleDelete = (data: any) => {
        setIdDelete(data);
        const msg = "ID: " + data + "を削除します。";
        setTitle("情報削除");
        setMessage(msg);
        setOpenDeleteDialog(true);
    }

    //削除の処理
    const handleShowDetailPage = (id: any) => {
        navigate(RoutePath.DialTimeEdit + `/edit/${id}`, { state: 1 })
    }

    // 削除確認ダイアログでOKボタンのイベントを受け取って、APIに送信する
    const handleDeleteOK = () => {
        setOpenDeleteDialog(false);
        const params = {
            userId: userId,
            requestPrms: {
                id: idDelete
            }
        }
        onDelete(params);
        setIdDelete("");
    }

    // 削除確認ダイアログでCancelボタンのイベントを受け取って、ダイアログを無効にする
    const handleDeleteCancel = () => {
        setIdDelete("");
        setOpenDeleteDialog(false);
    }

    const handleSuccessDelete = (data: any) => {
        console.log('>>> handleSuccessDelete');
        onDeleteSuccess();
        handleSearchSubmit(getValues());
    };


    // 検索エラー時
    const handleErrorDelete = (success: boolean, data: any, error: any) => {
        console.log('>>> handleErrorDelete');
        onDeleteError()
    };

    return (
        <>
            {loadingFlag && (
                <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1500, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                    <CircularProgress />
                </div>
            )}
            {openDeleteDialog ?
                <AlertDialog title={title} message={message} isOpen={true} onOK={handleDeleteOK} onCancel={handleDeleteCancel} /> :
                <div />
            }
            <div style={{ height: '100%', width: '100%', marginTop: '10px' }}>
                <Stack direction="row" justifyContent="flex-start" alignItems="center" sx={{}} style={{ margin: '18px', color: 'MenuText' }}>
                    <Typography variant="h4">
                        時間ダイアル
                    </Typography>
                </Stack>
                <form onSubmit={handleSubmit(handleSearchSubmit)}>
                </form>
                <div style={{ marginBottom: "10px" }}>
                    <Stack direction="row">
                        <div style={{ marginInlineStart: 'auto' }}>
                            <Grid container justifyContent="flex-start" alignItems={"center"} direction="row" spacing={1} >
                                <Grid item>
                                    <TextField
                                        fullWidth
                                        autoComplete="searchStr"
                                        name="keyword"
                                        type="text"
                                        label="フィルター"
                                        size="small"
                                        value={keyword}
                                        onChange={e => setKeyword(e.target.value)}
                                    />
                                </Grid>
                                <Grid item>
                                    <Button variant="outlined" onClick={e => setKeyword("")}>クリア</Button>
                                </Grid>
                            </Grid>
                        </div>
                    </Stack>
                </div>
                <div>
                    <DialTimeGrid
                        ref={gridRef}
                        colState={colState}
                        pageSize={getValues('limit')}
                        pageData={pageData}
                        onChangeSearchCondition={handleChangePageData}
                        keyword={keyword}
                        onShowDetails={handleShowDetailPage}
                        onDelete={handleDelete}
                        heightForm='240'
                    />
                </div>
            </div>
            <FetchHelper fetchId={SEARCH_DIAL_TIME} onComplete={(success, data, error) =>
                success ? handleSuccess(data) : handleError(success, data, error)} />
            <FetchHelper fetchId={DELETE_DIAL_TIME} onComplete={(success, data, error) =>
                success ? handleSuccessDelete(data) : handleErrorDelete(success, data, error)} />
        </>
    );
};

const mapStateToProps = (state: ApplicationState) => ({
    userId: state.app.user?.userId,
    fetchState: selectors.getFetchState(
        state,
        SEARCH_DIAL_TIME
    ) as FetchState,
});

const mapDispatchToProps = (dispatch: any) => {
    return {
        onSearch: (params: any) => dispatch(actionCreators.fetch(SEARCH_DIAL_TIME, `/master/dial_time/gets`, "POST", params, false, true)),
        onDelete: (params: any) => dispatch(actionCreators.fetch(DELETE_DIAL_TIME, `/master/dial_time/delete`, "POST", params, false, true)),
        onError: (success: boolean, data: any, error: any) =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "検索",
                body: "検索できません。" + error,
            })),
        onSuccess: () =>
            dispatch(actionCreators.showMessage({
                type: "info",
                title: "時間ダイアル更新",
                body: "時間ダイアルを更新しました。",
            })),
        onDeleteSuccess: () =>
            dispatch(actionCreators.showMessage({
                type: "info",
                title: "時間ダイアル削除",
                body: "時間ダイアルを削除しました。",
            })),
        onDeleteError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "時間ダイアル削除",
                body: "時間ダイアルを削除できませんでした。",
            })),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DialTimeList);
