import { Button, CircularProgress, Grid, Stack, TextField, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import { useForm } from 'react-hook-form';
import { connect } from "react-redux";
import { PageData } from "../../../models/Index";
import { ApplicationState, actionCreators } from "../../../store";
import FetchHelper from '../../FetchHelper';
import AlertDialog from '../../common/AlertDialog';
import NotificationGrid from "./NotificationGrid";
import { Add } from "@mui/icons-material";
import NotificationEdit from "./NotificationEdit";

// -------------
// FETCH ID
const SEARCH_NOTIFICATION = "SEARCH_NOTIFICATION";
const ADD_NOTIFICATION = "ADD_NOTIFICATION";
const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";
const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION"
const SEND_MAIL_TO_USER = "SEND_MAIL_TO_USER"
const SEND_MAIL_AND_ADD_NOTIFY= "SEND_MAIL_AND_ADD_NOTIFY"

// 検索フォーム
interface SearchItemForm {
    "target": string;
    "limit": number;
    "page": number;
}

interface Notification {
    "title": string;
    "target": number;
    "content": string;
    "startDate": string|null;
    "endDate": string|null;
    "emailGreeting": string;
    "emailSignature": string;
    "sendMail": boolean,
    "searchItemForm": SearchItemForm
}
// 初期値設定
const initialValueSearch: SearchItemForm = {
    target: '',
    limit: 50,
    page: 1,
}
const  initialValueNotication : Notification={
    title: '',
    content: '',
    startDate: null,
    endDate: null,
    emailGreeting:'',
    emailSignature:'',
    sendMail:true,
    target:2,
    searchItemForm: initialValueSearch
}

const initialPageData: PageData = {
    rowFrom: '0',
    rowTo: '0',
    totalRow: '0',
    currPage: '0',
    totalPage: '0',
};

type NotificationListProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

export const NotificationList = (props: NotificationListProps) => {
    const {
        onSearch,
        userId,
        userSub,
        userEmail,
        addNotification,
        onError,
        onSearchError,
        onCreateSuccess,
        onCreateError,
        deleteNotification,
        onDeleteSuccess,
        onDeleteError,
        onUpdateSuccess,
        onUpdateError,
        onSendMailError,
        onSendMailSuccess,
        updateNotification,
        sendMailToUser,
    } = props;

    const { setValue, getValues } = useForm<Notification>({
        criteriaMode: "all",
        defaultValues: initialValueNotication,
    });
    const [title, setTitle] = React.useState("");
    const [message, setMessage] = React.useState("");
    const [pageData, setPageData] = React.useState(initialPageData);
    const [listData, setListData] = React.useState<Notification[]>([]);
    const [openEditDialog, setOpenEditDialog] = React.useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);

    const [colState, setColState] = useState<any>();
    const gridRef = useRef() as any;
    const [loadingFlag, setLoadingFlag] = React.useState(false);
    const [keyword, setKeyword] = useState("");

    const [idDelete, setIdDelete] = React.useState("")
    const [data, setData] = React.useState<Notification>()
    const [isAdd, setAdd] = React.useState(false)
    const [listUser, setListUser] = React.useState<any[]>([])
    const [subUserList, setSubUserList] = useState<any>();

    // 検索実行
    const handleSearchSubmit = (value: SearchItemForm) => {
        const params = new Map<string, any>();
        if (value.limit) params.set("limit", value.limit);
        params.set("page", value.page);
        setLoadingFlag(true);
        onSearch({
            "userId": userId,
            "requestPrms":{
                limit : value.limit,
                page: value.page,   
            }
        });
    }
    // 初回検索実行
    React.useEffect(() => {
        handleSearchSubmit(getValues("searchItemForm"));
        // eslint-disable-next-line
    }, []);

    const handleGetListUser = (data: any) => {
        const result = [] as any[];
        data.user_records?.forEach((element: any) => {
            
            let userItem = {} as any
            userItem.username = element.Username;
            userItem.custom_management_no = "";
            userItem.custom_company_name = "";
            userItem.custom_zip_code = "";
            userItem.address = "";
            userItem.family_name = "";
            userItem.given_name = "";
            userItem.custom_role = "";
            userItem.email = "";
            userItem.phone_number = "";
            userItem.custom_expiration_date = "";
            userItem.custom_memo = "";
            userItem.custom_mfa = "";
            userItem.custom_mfa_system = "";
            userItem.custom_plan = "";
            userItem.custom_serial_no = "";
            userItem.isSelect = false;
            userItem.sub = "";
            userItem.account_status = element.Enabled;
            
            element.Attributes.forEach((item: any) => {
                if (item.Name === "sub") {
                    userItem.sub = item.Value;
                }
                if (item.Name === "custom:management_no") {
                    userItem.custom_management_no = item.Value;
                }
                if (item.Name === "custom:company_name") {
                    userItem.custom_company_name = item.Value;
                }
                if (item.Name === "custom:zip_code") {
                    userItem.custom_zip_code = item.Value;
                }
                if (item.Name === "address") {
                    userItem.address = item.Value;
                }
                if (item.Name === "family_name") {
                    userItem.family_name = item.Value;
                }
                if (item.Name === "given_name") {
                    userItem.given_name = item.Value;
                }
                if (item.Name === "custom:role") {
                    userItem.custom_role = item.Value;
                }
                if (item.Name === "email") {
                    userItem.email = item.Value;
                }
                if (item.Name === "phone_number") {
                    userItem.phone_number = item.Value;
                }
                if (item.Name === "custom:expiration_date") {
                    userItem.custom_expiration_date = item.Value;
                }
                if (item.Name === "custom:memo") {
                    userItem.custom_memo = item.Value;
                }
                if (item.Name === "custom:mfa") {
                    if(item.Value.toLowerCase() == "true"){
                        userItem.custom_mfa = "true";
                    }else{
                        userItem.custom_mfa = "false";
                    }
                }
                if (item.Name === "custom:mfa_system") {
                    userItem.custom_mfa_system = item.Value;
                }
                if (item.Name === "custom:plan") {
                    userItem.custom_plan = item.Value;
                }
                if (item.Name === "custom:serial_no") {
                    userItem.custom_serial_no = item.Value;
                }
            })
            result.push(userItem);
        });
        return result;
    }

    const handleGetList = (data: any) => {
        if (data && data.data) {
            const listUser = handleGetListUser(data.listUser.data)
            setListUser(listUser)
            if (gridRef && gridRef.current) {
                gridRef.current.setRowData(data.data);
            }
            setListData(data.data);

            const totalCount = data.totalCount;
            const pageSize = getValues('searchItemForm.limit');
            const page = Number(data.page);
            const totalPage = Math.ceil(totalCount / pageSize);
            const rowFrom = (pageSize * page - pageSize) + 1;
            const rowTo = (pageSize * page) > totalCount ? totalCount : (pageSize * page);

            const updateData: PageData = {
                rowFrom: rowFrom.toLocaleString(),
                rowTo: rowTo.toLocaleString(),
                totalRow: totalCount.toLocaleString(),
                currPage: page.toLocaleString(),
                totalPage: totalPage.toLocaleString(),
            };

            setPageData(updateData);
        } else {
            if (gridRef && gridRef.current) {
                gridRef.current.setRowData([]);
            }
            setListData(() => [...[] as any]);
        }

        setLoadingFlag(false);
    };

    // 検索エラー時
    const handleError = (type: string, data: any, error: any) => {
        console.log('>>> handleError');
        switch (type) {
            case SEARCH_NOTIFICATION:
                onSearchError();
                break;
            case ADD_NOTIFICATION:
                onCreateError();
                break;
            case UPDATE_NOTIFICATION:
                onUpdateError();
                break;
            case DELETE_NOTIFICATION:
                setOpenDeleteDialog(false);
                onDeleteError();
                break;
            case SEND_MAIL_TO_USER:
                onSendMailError(error)
                break;
            case SEND_MAIL_AND_ADD_NOTIFY:
                onCreateError();
                onSendMailError(error);
                break;
            default:
                break;
        }
        
        setLoadingFlag(false);
        gridRef.current.hideOverlay();
    };

    // ページ数変更時にAPI検索する
    const handleChangePageData = (name: any, value: any) => {
        // 値変更
        if (name === 'limit') {
            setValue("searchItemForm.limit", value);
            setValue("searchItemForm.page", 1);
        }
        if(name === 'page'){
            setValue("searchItemForm.page", value);
        }
        handleSearchSubmit(getValues("searchItemForm"));
    };

    // 追加の処理
    const handleAdd = (value:any) => {
        setLoadingFlag(true)
        addNotification(value)
    }

    const handleUpdate =(value:any) =>{
        setLoadingFlag(true)
        updateNotification(value)
    }

    //削除の処理
    const handleDelete = (data: any) => {
        setIdDelete(data);
        const msg = "ID: " + data + "を削除します。"
        setTitle("お知らせ情報削除");
        setMessage(msg);
        setOpenDeleteDialog(true);
        setIdDelete(data);
    }

    // 削除確認ダイアログでOKボタンのイベントを受け取って、APIに送信する
    const handleDeleteOK = () => {
        setLoadingFlag(true);
        setOpenDeleteDialog(false);
        const param = {
            "notificationId": idDelete
        }
        deleteNotification(param)
    }

    // 削除確認ダイアログでCancelボタンのイベントを受け取って、ダイアログを無効にする
    const handleDeleteCancel = () => {
        setIdDelete("");
        setOpenDeleteDialog(false);
    }

    const handleSendMail = (value:any) => {
        setLoadingFlag(true)
        sendMailToUser(value)
    }

    const handleSendMailAndAddNotify = (value:any, state:boolean) => {
        setLoadingFlag(true)
        if(state)
        {
            updateNotification(value)
        }
        else
        {
            addNotification(value)
        }
    }

    const handleShowDetailPage = (data: any) => {
        let subList:any = []

        let listUserChecked: any[] = []
        if (listUser.length > 0) {
            if (data.target == 1) {
                listUserChecked = listUser.map((user:any) =>{
                    user.isSelect = true
                    subList.push(user.sub)
                    return user
                })
            } else {
                listUserChecked = listUser.map((user:any) =>{
                    if(data.sub.includes(user.sub)){
                        user.isSelect = true
                        subList.push(user.sub)
                    } else {
                        user.isSelect = false
                    }
                    return user
                })
            }
        }
        
        setListUser(listUserChecked)
        setData(data)
        setSubUserList(subList)
        setAdd(false)
        setOpenEditDialog(true)
    }

    const handleCancel = () =>{
        setOpenEditDialog(false)
    }

    const handleClickAddButton = () =>{
        const listUserChecked = listUser.map((user:any) => {
            user.isSelect = false
            return user
        })
        setListUser(listUserChecked)
        setData(initialValueNotication)
        setSubUserList([])
        setAdd(true)
        setOpenEditDialog(true)
    }

    const handleSuccess = (data: any, type: string) => {
        if (data && data.resultCode == 0) {
            switch (type) {
                case ADD_NOTIFICATION:
                    setOpenEditDialog(false);
                    setAdd(false)
                    onCreateSuccess();
                    break;
                case UPDATE_NOTIFICATION:
                    setOpenEditDialog(false);
                    onUpdateSuccess();
                    break;
                case DELETE_NOTIFICATION:
                    setOpenDeleteDialog(false);
                    onDeleteSuccess();
                    break;
                case SEND_MAIL_TO_USER:
                    onSendMailSuccess()
                    break;
                case SEND_MAIL_AND_ADD_NOTIFY:
                    setOpenEditDialog(false);
                    setAdd(false)
                    onCreateSuccess();
                    onSendMailSuccess();
                    break;
                default:
                    break;
            }
            handleSearchSubmit(getValues("searchItemForm"));
        }
        else {
            switch (type) {
                case ADD_NOTIFICATION:
                    onCreateError();
                    break;
                case UPDATE_NOTIFICATION:
                    onUpdateError();
                    break;
                case DELETE_NOTIFICATION:
                    setOpenDeleteDialog(false);
                    onDeleteError();
                    break;
                case SEND_MAIL_AND_ADD_NOTIFY:
                    onCreateError();
                    onSendMailError(data? data.message : "");
                    break;
                case SEND_MAIL_TO_USER:
                    onSendMailError(data? data.message : "")
                    break;
                default:
                    break;
            }
        }
        setLoadingFlag(false);
    };

    return (
        <>
            {loadingFlag && (
                <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1500, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                    <CircularProgress />
                </div>
            )}
            {openDeleteDialog &&
                <AlertDialog 
                    title={title}
                    message={message}
                    isOpen={true}
                    onOK={handleDeleteOK}
                    onCancel={handleDeleteCancel}
                />
            }
            {openEditDialog &&
                <NotificationEdit
                    subList={subUserList}
                    listUser={listUser}
                    userEmail={userEmail}
                    isAdd={isAdd}
                    data={data}
                    onCancel={handleCancel}
                    addNotification={handleAdd}
                    updateNotification={handleUpdate}
                    sendMail={handleSendMail}
                    userSub={userSub}
                    sendMailAndAddNotify={handleSendMailAndAddNotify}
                />
            }

            <div style={{ height: '100%', width: '100%', marginTop: '10px' }}>
                <Stack style={{ margin: '18px'}}>
                    <Typography variant="h4">
                        お知らせ設定
                    </Typography>
                </Stack>
                <Grid container justifyContent="flex-end" direction="row" alignItems="center" spacing={1} mb={1}>
                    <Grid item>
                        <TextField
                            fullWidth
                            autoComplete="searchStr"
                            name="keyword"
                            type="text"
                            label="フィルター"
                            size="small"
                            value={keyword}
                            onChange={e => setKeyword(e.target.value)}
                        />
                    </Grid>
                    <Grid item>
                        <Button
                            variant="outlined"
                            onClick={() => setKeyword("")}
                            sx={{height: '40px'}}
                        >クリア</Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            onClick={() => handleClickAddButton()}
                            startIcon={
                                <Add style={{fontSize:'24px', width: 50, paddingLeft:'10px'}}/>
                            }
                            sx={{height: '40px', padding: 0}}
                        ></Button>
                    </Grid>
                </Grid>
                <div>
                    <NotificationGrid
                        ref={gridRef}
                        colState={colState}
                        pageSize={getValues('searchItemForm.limit')}
                        pageData={pageData}
                        onChangeSearchCondition={handleChangePageData}
                        keyword={keyword}
                        onShowDetails={handleShowDetailPage}
                        onDelete={handleDelete}
                        listUser={listUser}
                    />
                </div>
            </div>
            <FetchHelper fetchId={SEARCH_NOTIFICATION} onComplete={(success, data, error) =>
                success ? handleGetList(data) : handleError(SEARCH_NOTIFICATION, data, error)} />
            <FetchHelper fetchId={ADD_NOTIFICATION} onComplete={(success, data, error) =>
                success ? handleSuccess(data,ADD_NOTIFICATION) : handleError(ADD_NOTIFICATION, data, error)} />
            <FetchHelper fetchId={UPDATE_NOTIFICATION} onComplete={(success, data, error) =>
                success ? handleSuccess(data,UPDATE_NOTIFICATION) : handleError(UPDATE_NOTIFICATION, data, error)} />
            <FetchHelper fetchId={DELETE_NOTIFICATION} onComplete={(success, data, error) =>
                success ? handleSuccess(data,DELETE_NOTIFICATION) : handleError(DELETE_NOTIFICATION, data, error)} />
            <FetchHelper fetchId={SEND_MAIL_TO_USER} onComplete={(success, data, error) =>
                success ? handleSuccess(data,SEND_MAIL_TO_USER) : handleError(SEND_MAIL_TO_USER, data, error)} />
            <FetchHelper fetchId={SEND_MAIL_AND_ADD_NOTIFY} onComplete={(success, data, error) =>
                success ? handleSuccess(data,SEND_MAIL_AND_ADD_NOTIFY) : handleError(SEND_MAIL_AND_ADD_NOTIFY, data, error)} />
        </>
    );
};

const mapStateToProps = (state: ApplicationState) => ({
    userId: state.app.user?.userId,
    userSub: state.app.user?.userSub,
    userEmail : state.app.user?.cognit_user.attributes.email,
});

const mapDispatchToProps = (dispatch: any) => {
    return {
        onSearch: (params: any) => dispatch(actionCreators.fetch(SEARCH_NOTIFICATION, `/notification/gets`, "POST", params,false,true)),
        addNotification: (params: any) => dispatch(actionCreators.fetch(ADD_NOTIFICATION, `/notification/add`, "POST", params,false,true)),
        deleteNotification: (params: any) => dispatch(actionCreators.fetch(DELETE_NOTIFICATION, `/notification/delete`, "POST", params,false,true)),
        updateNotification: (params: any) => dispatch(actionCreators.fetch(UPDATE_NOTIFICATION, `/notification/edit`, "POST", params,false,true)),
        sendMailToUser: (params:any) => dispatch(actionCreators.fetch(SEND_MAIL_TO_USER, `/notification/send-mail-to-user`, "POST", params,false,true)),

        onSearchError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "お知らせ情報を受ける",
                body: "お知らせ情報を受けるに失敗しました。",
            })),
        onCreateError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "お知らせ情報追加",
                body: "お知らせ情報が登録出来ませんでした。",
            })),
        
        onCreateSuccess: () =>
            dispatch(actionCreators.showMessage({
                type: "info",
                title: "お知らせ情報追加",
                body: "お知らせ情報を登録しました。",
            })),
        onDeleteSuccess: () =>
            dispatch(actionCreators.showMessage({
                type: "info",
                title: "お知らせ情報削除",
                body: "お知らせ情報を削除しました。",
            })),
        onDeleteError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "お知らせ情報削除",
                body: "お知らせ情報が削除出来ませんでした。",
            })),
        onError: (success: boolean, data: any, error: any) =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "お知らせ情報",
                body: "お知らせ情報を取得できません。" + error,
            }),),
        onUpdateSuccess: () =>
            dispatch(actionCreators.showMessage({
                type: "info",
                title: "お知らせ情報編集",
                body: "お知らせ情報を更新しました。",
            })),
        onUpdateError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "お知らせ情報編集",
                body: "お知らせ情報が更新出来ませんでした。",
            })),
        onSendMailSuccess: () =>
            dispatch(actionCreators.showMessage({
                type: "info",
                title: "お知らせメールを送信する",
                body: "お知らせ情報メールの送信に成功しました。",
            })),
        onSendMailError: (error: any) =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "お知らせメールを送信する",
                body: "お知らせ情報メールを送信できませんでした。" + error,
            })),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NotificationList);
