import { Button, ButtonGroup, Checkbox, Dialog, DialogContent, DialogTitle, FormControlLabel, FormLabel, Grid, Paper, Radio, RadioGroup, Stack, TextField, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import theme from "../../themes/globalStyles";
import { Point } from "../../models/Index";
import * as math from "mathjs"
import { useRef } from "react";
import DraggablePaper from "../common/DraggablePaper";
// import { RemModel } from "../../models/Index";

export type UserFormulaDialogProps = {
    data: any;
    modeViewOnly: boolean;
    onOK: (data: any) => void;
    onCancel: () => void;
}; 

const groupButtonsStyle = {
    borderBottomColor: 'rgba(25, 118, 210, 0.5)',
    marginTop: 0,
    marginLeft: 0
}

export const UserFormulaDialog = (props:UserFormulaDialogProps) => {
    const { data, modeViewOnly, onOK, onCancel } = props;

    const formulaRef = useRef<any>(null)

    // 初期値設定
    const initialValue: any = {
        formula: "Sample 10*((0.453/(i-1))+0.147)",
        currentMax: 30,
        currentMin: 1.01,
        checkTime: false,
        checkCurrent: false,
        timeMax: 0,
        timeMin: 0,
        currentInterval: 1,
        currentPartition: 0,
        listFormulaCalcPoints: [],
        ...data //overide default value (if any)
    }

    const { control, handleSubmit, formState: { errors }, setValue, getValues, watch, trigger } = useForm<any>({
        mode: 'onChange',
        criteriaMode: "all",
        defaultValues: initialValue
    });

    const watchCheckCurrent = watch('checkCurrent')
    const watchCheckTime = watch('checkTime')

    // Submitイベント
    const handleEditSubmit = (value : any) => {
        const request = createRequestData(value);
        onOK(request);
    }

    // FormデータからRequestデータを作成する
    const createRequestData = (formValue: any) => {
        let request = {
            ...formValue,
        } as any;
        return request;
    }

    // キャンセル
    const handleCancel = () => {
        onCancel();
    };

    //#region Calculation methods
    const doClickOkBtn = (event : any) => {
        try {
            const listPoints : Point[] = []

            let formula = getValues("formula")
            formula = convertSqrtSymbol(formula)
            const currentMax = getValues("currentMax")
            const currentMin = getValues("currentMin")
            const checkTime = getValues("checkTime")
            const checkCurrent = getValues("checkCurrent")
            const timeMax = getValues("timeMax")
            const timeMin = getValues("timeMin")
            const currentInterval = getValues("currentInterval")
            const currentPartition = getValues("currentPartition")

            if (!checkCurrent) { //uncheck current
                if (checkTime) {  //checked time

                    for (let dIndex = currentMin; dIndex <= currentMax; dIndex += currentInterval) {
                        // calc f(i) by replace i with dIndex
                        const expression = formula.replaceAll('i', dIndex)
                        const dResult = math.evaluate(expression) 
                        if (dResult < timeMin || dResult > timeMax) {
                            continue
                        }
                        listPoints.push({
                            x: dIndex,
                            y: dResult
                        })
                    }

                } else { // unchecked checkTime

                    for (let dIndex = currentMin; dIndex < currentMax; dIndex += currentInterval) {
                        // calc f(i) by replace i with dIndex
                        const expression = formula.replaceAll('i', dIndex)
                        const dResult = math.evaluate(expression) 
                        listPoints.push({
                            x: dIndex,
                            y: dResult
                        })
                    }

                    // calc f(i) by replace i with currentMax
                    const expression = formula.replaceAll('i', currentMax)
                    const dResult = math.evaluate(expression) 
                    listPoints.push({
                        x: currentMax,
                        y: dResult
                    })
                }
            }
            else { //checked checkCurrent
                if (checkTime) {  //checked time

                    const dPartition = (currentMax - currentMin) / currentPartition
                    for (let dIndex = currentMin; dIndex <= currentMax; dIndex += dPartition) {
                        // calc f(i) by replace i with dIndex
                        const expression = formula.replaceAll('i', dIndex)
                        const dResult = math.evaluate(expression) 
                        if (dResult < timeMin || dResult > timeMax) {
                            continue
                        }
                        listPoints.push({
                            x: dIndex,
                            y: dResult
                        })
                    }

                } else { // unchecked checkTime

                    const dPartition = (currentMax - currentMin) / currentPartition
                    for (let dIndex = currentMin; dIndex < currentMax; dIndex += dPartition) {
                        // calc f(i) by replace i with dIndex
                        const expression = formula.replaceAll('i', dIndex)
                        const dResult = math.evaluate(expression) 
                        listPoints.push({
                            x: dIndex,
                            y: dResult
                        })
                    }

                    // calc f(i) by replace i with currentMax
                    const expression = formula.replaceAll('i', currentMax)
                    const dResult = math.evaluate(expression) 
                    listPoints.push({
                        x: currentMax,
                        y: dResult
                    })
                }
            }
            
            setValue('listFormulaCalcPoints', listPoints)
        } catch (error : any) {
            console.log(error)
            event.preventDefault()
            trigger('formula', { shouldFocus: true })
            formulaRef.current.focus()
        }
    }

    const checkSyntax = (expression : any) => {
        try {
            expression = convertSqrtSymbol(expression)
            math.evaluate(expression)
            return true
        } catch (error) {
            return false
        }
    }

    const checkTime = (v : any) => {
        const timeMax = getValues("timeMax")
        const timeMin = getValues("timeMin")
        const isCheckTime = getValues("checkTime")
        if (isCheckTime && timeMax <= timeMin) return false
        return true
    }

    const checkCurrent = (v : any) => {
        const currentMax = getValues("currentMax")
        const currentMin = getValues("currentMin")
        if (currentMax <= currentMin) return false
        return true
    }

    const handleClickFormulaBtn = (e : any) => {
        const pressedValue = e.target.value
        const currentFormula = getValues("formula")

        const startPos = formulaRef.current.selectionStart
        const endPos = formulaRef.current.selectionEnd

        const newFormula = currentFormula.slice(0, startPos) + pressedValue + currentFormula.slice(endPos)
        setValue('formula', newFormula, {
            shouldValidate: true
        })

        // // dispatch onChange event to validate
        // const event = new Event("change", { bubbles: true });
        // formulaRef.current.dispatchEvent(event);
        
        // NOTE: setSelectionRange does not work for focus element, please setTimeout to window as a trick
        // to solve this problem
        formulaRef.current.focus()        
        window.setTimeout(function() {
            formulaRef.current.setSelectionRange(startPos + pressedValue.length, startPos + pressedValue.length)
        }, 0);    
    }

    const convertSqrtSymbol = (beforeFormular : string) => {

        const arrFormular = beforeFormular.split('') //convert string to arr
        arrFormular.forEach((char : string, index : number) => {
            if (char === '√') { // is sqrt symbol
                if (arrFormular[index + 1] === "(") { // if next char is (, then replace √ with sqrt
                    arrFormular[index] = "sqrt"
                } else {
                    if (!/^\d+$/.test(arrFormular[index + 1])) { // not number
                        arrFormular.splice(index + 2, 0, ')')
                        arrFormular.splice(index, 1, 'sqrt(')
                    } else {
                        // find the end position of number
                        let right = index + 2
                        while (/^\d+$/.test(arrFormular[right])) {
                            right++
                        }
                        arrFormular.splice(right, 0, ')')
                        arrFormular.splice(index, 1, 'sqrt(')
                    }
                }
            }
        })

        return arrFormular.join('')
    }
    //#endregion Calculation methods

    return (
        <>
        <Dialog open={true} fullWidth
            PaperComponent={
                DraggablePaper
            }
        >
            <DialogTitle 
                onMouseOver={(e : any) => e.target.style.cursor = 'move'}
            style={{ paddingTop: '2px', paddingBottom: '2px', background: theme.palette.primary.main, color: theme.palette.primary.contrastText }}>計算式入力</DialogTitle>
            <DialogContent sx={{ pb: 1.5 }}>
                <div style={{ height: '100%', width: '100%' }}>
                    <form 
                        onSubmit={handleSubmit(handleEditSubmit)} 
                        style={{ width: '100%', margin: 'auto' }}
                    >
                        <Stack spacing={2} direction='column' mb={1} mt={2} maxHeight={"calc(100vh - 340px)"} overflow={"auto"}>
                            <Stack>
                                <fieldset style={{borderRadius:"5px",border:"1px solid grey"}}>
                                    <legend style={{fontSize:"14px"}}>計算式</legend>
                                    <Grid container spacing={1} direction={"row"}>
                                        <Grid item xs={12} mt={1}>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                
                                                <Controller
                                                    name="formula"
                                                    control={control}
                                                    rules={{
                                                        required: '必須項目です。入力してください。',
                                                        validate: v => checkSyntax(v) || `計算式に不適切な文字の入力があります。`
                                                    }}
                                                    render={({ field, fieldState }) => (
                                                        <>
                                                            <Typography fontSize={"13px"} width="20%">時間値(s) t = </Typography>
                                                            <TextField
                                                                {...field}
                                                                ref={(e) => {
                                                                    field.ref(e)
                                                                    formulaRef.current = e?.querySelector('input[name=formula]')
                                                                }}
                                                                fullWidth
                                                                type="text"
                                                                size="small"
                                                                error={!!errors?.formula}
                                                                helperText={fieldState.error?.message}
                                                                onFocus={e => e.target.select()}
                                                                InputLabelProps={{
                                                                    style: { color: 'black' },
                                                                }}
                                                                InputProps={{
                                                                    sx:{height:32,color: modeViewOnly? 'grey':'black', background:modeViewOnly?'floralwhite':'white'},
                                                                }}
                                                                inputProps={{readOnly: modeViewOnly}}
                                                            />
                                                        </>
                                                    )}
                                                />
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={4.5}>
                                            <Stack direction={'row'} gap={3} justifyContent={'center'}>
                                                <Stack direction={'column'} gap={1.5} width={"100%"}>
                                                    <Stack>
                                                        <Typography variant="body2">パラメータ</Typography> {/*電流値: */}
                                                        <Typography variant="body2" ml={1}>(電流値)</Typography> {/*: */}
                                                    </Stack>
                                                    <Typography variant="body2">カッコ:</Typography>
                                                    <Typography variant="body2">ルート:</Typography>
                                                    <Typography variant="body2">クリア:</Typography>
                                                </Stack>
                                                <ButtonGroup
                                                    orientation="vertical"
                                                    disabled={modeViewOnly}
                                                >
                                                    <Button style={groupButtonsStyle} value={'i'} onClick={handleClickFormulaBtn}>i</Button>
                                                    <ButtonGroup variant="outlined" disabled={modeViewOnly}>
                                                        <Button style={groupButtonsStyle} value={'('} onClick={handleClickFormulaBtn}>(</Button>
                                                        <Button style={groupButtonsStyle} value={')'} onClick={handleClickFormulaBtn}>)</Button>
                                                    </ButtonGroup>
                                                    <Button style={groupButtonsStyle} value={'√'} onClick={handleClickFormulaBtn}>&radic;</Button>
                                                    <Button style={groupButtonsStyle} onClick={() => setValue('formula', '')}>Clear</Button>
                                                </ButtonGroup>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={4} padding={0}>
                                            <Stack direction={'row'} gap={0} alignItems={'center'} justifyContent={'center'}>
                                                <Typography mr={0.5}>数字:</Typography>
                                                <ButtonGroup orientation="vertical" disabled={modeViewOnly}>
                                                    <ButtonGroup variant="outlined" disabled={modeViewOnly}>
                                                        <Button style={groupButtonsStyle} value={'1'} onClick={handleClickFormulaBtn}>1</Button>
                                                        <Button style={groupButtonsStyle} value={'2'} onClick={handleClickFormulaBtn}>2</Button>
                                                        <Button style={groupButtonsStyle} value={'3'} onClick={handleClickFormulaBtn}>3</Button>
                                                    </ButtonGroup>
                                                    <ButtonGroup variant="outlined" disabled={modeViewOnly}>
                                                        <Button style={groupButtonsStyle} value={'4'} onClick={handleClickFormulaBtn}>4</Button>
                                                        <Button style={groupButtonsStyle} value={'5'} onClick={handleClickFormulaBtn}>5</Button>
                                                        <Button style={groupButtonsStyle} value={'6'} onClick={handleClickFormulaBtn}>6</Button>
                                                    </ButtonGroup>
                                                    <ButtonGroup variant="outlined" disabled={modeViewOnly}>
                                                        <Button style={groupButtonsStyle} value={'7'} onClick={handleClickFormulaBtn}>7</Button>
                                                        <Button style={groupButtonsStyle} value={'8'} onClick={handleClickFormulaBtn}>8</Button>
                                                        <Button style={groupButtonsStyle} value={'9'} onClick={handleClickFormulaBtn}>9</Button>
                                                    </ButtonGroup>
                                                    <ButtonGroup variant="outlined" disabled={modeViewOnly}>
                                                        <Button style={groupButtonsStyle} value={'0'} onClick={handleClickFormulaBtn}>0</Button>
                                                        <Button style={groupButtonsStyle} value={'.'} onClick={handleClickFormulaBtn}>.</Button>
                                                    </ButtonGroup>
                                                </ButtonGroup>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={3.5}>
                                            <Stack direction={'row'} gap={3} justifyContent={'center'}>
                                                <Stack direction={'column'} gap={1.75}>
                                                    <Typography>加算:</Typography> {/*電流値: */}
                                                    <Typography>減算:</Typography>
                                                    <Typography>乗算:</Typography>
                                                    <Typography>除算:</Typography>
                                                    <Typography>べき乗:</Typography>
                                                </Stack>
                                                <ButtonGroup orientation="vertical" disabled={modeViewOnly}>
                                                    <Button style={groupButtonsStyle} value={'+'} onClick={handleClickFormulaBtn}>+</Button>
                                                    <Button style={groupButtonsStyle} value={'-'} onClick={handleClickFormulaBtn}>-</Button>
                                                    <Button style={groupButtonsStyle} value={'*'} onClick={handleClickFormulaBtn}>*</Button>
                                                    <Button style={groupButtonsStyle} value={'/'} onClick={handleClickFormulaBtn}>/</Button>
                                                    <Button style={groupButtonsStyle} value={'^'} onClick={handleClickFormulaBtn}>^</Button>
                                                </ButtonGroup>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </fieldset>
                            </Stack>
                            <Stack>
                                <fieldset style={{borderRadius:"5px",border:"1px solid grey"}}>
                                    <legend style={{fontSize:"14px"}}>電流値 (A) i</legend>
                                    <Grid container spacing={1.5} direction={"row"}>
                                        <Grid item xs={3} mt={1}>
                                            <Stack spacing={0.3}>
                                                <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                    <Typography variant="body2" width="50%">最小:</Typography>
                                                    <Controller
                                                        name="currentMin"
                                                        control={control}
                                                        rules={{
                                                            required: '必須項目です。入力してください。',
                                                            validate: v => checkCurrent(v) || "電流値・最小に 電流値・最大より小さい数値を入力してください。"
                                                        }}
                                                        render={({ field, fieldState }) => (
                                                            <>
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    type="text"
                                                                    size="small"
                                                                    error={!!errors?.currentMin}
                                                                    helperText={fieldState.error?.message}
                                                                    onFocus={e => e.target.select()}
                                                                    InputLabelProps={{
                                                                        style: { color: 'black' },
                                                                    }}
                                                                    InputProps={{
                                                                        sx:{height:32,color: modeViewOnly? 'grey':'black', background:modeViewOnly?'floralwhite':'white'},
                                                                    }}
                                                                    inputProps={{readOnly: modeViewOnly}}
                                                                />
                                                            </>
                                                        )}
                                                    />
                                                </Stack>
                                                <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                    <Typography variant="body2" width="50%">間隔:</Typography>
                                                    <Controller
                                                        name="currentInterval"
                                                        control={control}
                                                        rules={{
                                                            required: '必須項目です。入力してください。',
                                                            validate: v => v > 0 || '電流値・間隔に0より大きい数値を入力してください。'
                                                        }}
                                                        render={({ field, fieldState }) => (
                                                            <>
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    type="text"
                                                                    size="small"
                                                                    error={!!errors?.currentInterval}
                                                                    helperText={fieldState.error?.message}
                                                                    disabled={watchCheckCurrent}
                                                                    onFocus={e => e.target.select()}
                                                                    InputLabelProps={{
                                                                        style: { color: 'black' },
                                                                    }}
                                                                    InputProps={{
                                                                        sx:{height:32,color: modeViewOnly? 'grey':'black', background:modeViewOnly?'floralwhite':'white'},
                                                                    }}
                                                                    inputProps={{readOnly: modeViewOnly}}
                                                                />
                                                            </>
                                                        )}
                                                    />
                                                </Stack>
                                                <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                    <Typography variant="body2" width="50%">最大:</Typography>
                                                    <Controller
                                                        name="currentMax"
                                                        control={control}
                                                        rules={{
                                                            required: '必須項目です。入力してください。',
                                                            validate: v => checkCurrent(v) || "電流値・最小に 電流値・最大より小さい数値を入力してください。"
                                                        }}
                                                        render={({ field, fieldState }) => (
                                                            <>
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    type="text"
                                                                    size="small"
                                                                    error={!!errors?.currentMax}
                                                                    helperText={fieldState.error?.message}
                                                                    onFocus={e => e.target.select()}
                                                                    InputLabelProps={{
                                                                        style: { color: 'black' },
                                                                    }}
                                                                    InputProps={{
                                                                        sx:{height:32,color: modeViewOnly? 'grey':'black', background:modeViewOnly?'floralwhite':'white'},
                                                                    }}
                                                                    inputProps={{readOnly: modeViewOnly}}
                                                                />
                                                            </>
                                                        )}
                                                    />
                                                </Stack>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={3} ml={1}>
                                            <Stack alignItems={'center'} direction={'row'} width={'100%'} height={"100%"}>
                                                <Controller
                                                    name="checkCurrent"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <FormControlLabel disabled={modeViewOnly} {...field} control={<Checkbox style={{padding:"0 0 0 0" }} />} checked={field.value} label={<Typography variant="body2">間隔を対数上で均等にする</Typography>} name='checkCurrent' />
                                                    )}
                                                />         
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={5.8} padding={0}>
                                            <Stack justifyContent={'center'} height={'120%'}>
                                                <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                    <Typography variant="body2" width={"80%"}>目盛間の分割数:</Typography>
                                                    <Controller
                                                        name="currentPartition"
                                                        control={control}
                                                        rules={{
                                                            required: '必須項目です。入力してください。',
                                                            validate: v => v >= 0 || `目盛間の分割数に不正な入力をしました。`
                                                        }}
                                                        render={({ field, fieldState }) => (
                                                            <>
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    type="text"
                                                                    size="small"
                                                                    error={!!errors?.currentPartition}
                                                                    helperText={fieldState.error?.message}
                                                                    disabled={!watchCheckCurrent}
                                                                    onFocus={e => e.target.select()}
                                                                    InputLabelProps={{
                                                                        style: { color: 'black' },
                                                                    }}
                                                                    InputProps={{
                                                                        sx:{height:32,color: modeViewOnly? 'grey':'black', background:modeViewOnly?'floralwhite':'white'},
                                                                    }}
                                                                    inputProps={{readOnly: modeViewOnly}}
                                                                />
                                                            </>
                                                        )}
                                                    />
                                                </Stack>
                                                <Stack>
                                                    <Typography variant="body2">(例えばこの値が4のとき、 10A~20Aを4 分割した間隔がパラメータとなる)</Typography>
                                                </Stack>
                                            </Stack>
                                            
                                        </Grid>
                                    </Grid>
                                </fieldset>
                            </Stack>
                            <Stack>
                                <fieldset style={{borderRadius:"5px",border:"1px solid grey"}}>
                                    <legend style={{fontSize:"14px"}}>時間値(s) t</legend>
                                    <Grid container spacing={2} direction={"row"}>
                                        <Grid item xs={6}>
                                            <Controller
                                                name="checkTime"
                                                control={control}
                                                render={({ field }) => (
                                                    <FormControlLabel disabled={modeViewOnly} {...field} control={<Checkbox style={{ height: '25px',padding:"0 0 0 0" }} />} checked={field.value} label={<Typography variant="body2">計算式の出力範囲を指定する</Typography>} name='checkTime' />
                                                )}
                                            />
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width={"35%"}>最小:</Typography>
                                                <Controller
                                                    name="timeMin"
                                                    control={control}
                                                    rules={{
                                                        required: '必須項目です。入力してください。',
                                                        validate: v => checkTime(v) || `時間値・最小に 時間値・最大より小さい数値を入力してください。`
                                                    }}
                                                    render={({ field, fieldState }) => (
                                                        <>
                                                            <TextField
                                                                {...field}
                                                                fullWidth
                                                                type="text"
                                                                size="small"
                                                                disabled={!watchCheckTime}
                                                                error={!!errors?.timeMin}
                                                                helperText={fieldState.error?.message}
                                                                onFocus={e => e.target.select()}
                                                                InputLabelProps={{
                                                                    style: { color: 'black' },
                                                                }}
                                                                InputProps={{
                                                                    sx:{height:32,color: modeViewOnly? 'grey':'black', background:modeViewOnly?'floralwhite':'white'},
                                                                }}
                                                                inputProps={{readOnly: modeViewOnly}}
                                                            />
                                                        </>
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width={"35%"}>最大:</Typography>
                                                <Controller
                                                    name="timeMax"
                                                    control={control}
                                                    rules={{
                                                        required: '必須項目です。入力してください。',
                                                        validate: v => checkTime(v) || `時間値・最小に 時間値・最大より小さい数値を入力してください。`
                                                    }}
                                                    render={({ field, fieldState }) => (
                                                        <>
                                                            <TextField
                                                                {...field}
                                                                fullWidth
                                                                type="text"
                                                                size="small"
                                                                disabled={!watchCheckTime}
                                                                error={!!errors?.timeMax}
                                                                helperText={fieldState.error?.message}
                                                                onFocus={e => e.target.select()}
                                                                InputLabelProps={{
                                                                    style: { color: 'black' },
                                                                }}
                                                                InputProps={{
                                                                    sx:{height:32,color: modeViewOnly? 'grey':'black', background:modeViewOnly?'floralwhite':'white'},
                                                                }}
                                                                inputProps={{readOnly: modeViewOnly}}
                                                            />
                                                        </>
                                                    )}
                                                />
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Stack gap={3}>
                                                
                                                
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </fieldset>
                            </Stack>
                        </Stack>
                        <Stack direction='row' justifyContent="flex-end" spacing={2} mt={1}>
                            <Button variant="contained" type="submit" onClick={doClickOkBtn} disabled={modeViewOnly}>OK</Button>
                            <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                        </Stack>
                    </form >
                </div >
            </DialogContent>
        </Dialog>
        </>
    )
};
export default UserFormulaDialog;
