import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, CircularProgress, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Stack, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useCSVDownloader, useCSVReader } from 'react-papaparse';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { CurveHeaderModel, CurvePointImportItem } from '../../../models/breakModel';
import { Path as RoutePath } from '../../../path';
import { ApplicationState, actionCreators } from '../../../store';
import FetchHelper from '../../FetchHelper';
// FETCH ID
const ADD_CURVE_POINT = "ADD_CURVE_POINT";

// -------------
// Props
export type AddCurveHeaderListProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

export const AddCurveHeaderList = (props: AddCurveHeaderListProps) => {
    const {
        userId,
        onCreateCurvePoint,
        onSuccess,onError,
    } = props;

    const { handleSubmit } = useForm<CurveHeaderModel>({
        criteriaMode: 'all',
    });

    let navigate = useNavigate();
    const location = useLocation();
    let lCurveHeaderID = location.state ? location.state.lCurveHeaderID : "";
    const { CSVDownloader, Type } = useCSVDownloader();
    const { CSVReader } = useCSVReader();
    const [dataCSV, setDataCSV] = useState<any>([]);
    const [fileName, setFileName] = useState<string>();
    const [filePath, setFilePath] = useState<string>();
    const [errorList, setErrorList] = useState<any>();
    const [selectCSV, setSelectCSV] = useState<boolean>();
    const [runFlag, setRunFlag] = useState<boolean>(false);
    const [loadingFlag,setLoadingFlag] = useState<boolean>(false);
    const [checkError, setCheckError] = useState<boolean>(false);
    const [arrayAdd, setArrayAdd] = useState<CurvePointImportItem[]>([]);
    const headerCSV = "dXPoint,dYPoint,瞬時";

    const messageNull = "必須項目が設定されていません。($1行目、項目：$2)\n";
    const messageValidate = "フォーマットが不正です。($1行目、項目：$2)\n";

    const handleRemoveFile = () => {
        setDataCSV([]);
        setArrayAdd([]);
        setFileName("");
        setFilePath("");
        setErrorList("");
        setSelectCSV(false);
        setCheckError(false);
        setRunFlag(false);
    }

    // キャンセル
    const handleCancel = () => {
        // キャンセル
        if (!runFlag) {
            if (location.key !== "default")
                navigate(-1);
            else
                navigate(RoutePath.CurveHeaderList)
        } else {
            return;
        }
    }
    const handleEditSubmit = (data: any) => {
        setRunFlag(true);
        setLoadingFlag(true);
        setCheckError(false);
        let totalRow = dataCSV.length === 0 ? 0 : dataCSV.length - 1;
        let msg = "";
        let error = "";
        if (totalRow > 0) {
            msg = msg + "ファイルのフォーマットチェックを開始します。\n";
            for (let index = 1; index <= totalRow; index++) {
                // checkError
                let rowData = Object.values(dataCSV[index]).map((val: any) => val.trim());
                error += checkValidation(rowData, index);
                if (error !== "") {
                    setCheckError(true);
                }
            }
            if (error !== "") {
                setRunFlag(false);
                setLoadingFlag(false);
            } else {
                msg += "ファイルのフォーマットチェックが完了しました。\n登録処理を開始します。\n";
                let dataSave = dataCSV.slice(1);
                for (const item of dataSave) {
                    let request = {
                        lpointID: 0,
                        lcurveHeaderID: lCurveHeaderID,
                        dxpoint: item[0],
                        dypoint: item[1],
                        buseInst: item[2] === "1" ? true : false,
                    } as any
                    arrayAdd.push(request);
                }
                const object = {
                    requestPrms:{"mv3curveCurvePoints": (arrayAdd)},
                    userId: userId,
                    // requestType :"importCSV",
                }
                onCreateCurvePoint(object);
            }
            msg = msg + error;
        } else {
            msg += "データフィールドがない.\n";
            setRunFlag(false);
            setLoadingFlag(false);
        }
        setErrorList(errorList + msg);
    }

    const checkDoubleCoordinate = (value: string) => {
        return /^[-+]?\d+(\.\d+)?$/.test(value);
    }

    const checkValidation = (element: any, row: number) => {
        let error = '';
        if (row > 0) {
            // dXPoint
            if (element[0] === "") {
                error += messageNull.replace('$1', row.toString()).replace('$2', "dXPoint")
            } else if (!checkDoubleCoordinate(element[0])) {
                error += messageValidate.replace('$1', row.toString()).replace('$2', "dXPoint")
            }
            // dYPoint
            if (element[1] === "") {
                error += messageNull.replace('$1', row.toString()).replace('$2', "dYPoint")
            } else if (!checkDoubleCoordinate(element[1])) {
                error += messageValidate.replace('$1', row.toString()).replace('$2', "dYPoint")
            }
            // 瞬時
            if (element[2] === "") {
                error += messageNull.replace('$1', row.toString()).replace('$2', "瞬時")
            } else if (element[2] !== "0" && element[2] !== "1") {
                error += messageValidate.replace('$1', row.toString()).replace('$2', "瞬時")
            }
        }
        return error;
    }

    const handleCreateCurvePoint = (data: any) => {
        let error = errorList;
        console.log(">>>handleCreateCurvePointSuccess");
        onSuccess();
        error += "一括登録が完了しました。(合計：$1件)\n".replace("$1", (dataCSV.length - 1).toString());
        setRunFlag(false);
        setLoadingFlag(false);
        setErrorList(error);
        setArrayAdd([]);
        setDataCSV([])
    }

    const handleErrorCreateCurvePoint = (data: any) => {
        console.log(">>>handleCreateCurvePointError");
        onError();
        setErrorList(errorList +
            "登録処理に失敗しました" + "一括登録を中断しました。\n"
        );
        setArrayAdd([]);
        setDataCSV([]);
        setRunFlag(false);
        setLoadingFlag(false);
    }

    return (
        <>
            {loadingFlag && (
                <div style={{ top: '0px', left: '0px', position: 'fixed', zIndex: 1500, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                    <CircularProgress />
                </div>
            )}
            <div style={{ height: '100%', width: '100%' }}>
                <Stack direction='row' justifyContent="flex-start" spacing={1.5} alignItems='center'>
                    <Typography variant="h5">一括登録ファイルのアップロード</Typography>
                </Stack>
                <form onSubmit={handleSubmit(handleEditSubmit)}>
                    <Stack direction='row' justifyContent="flex-end" spacing={2} sx={{ mt: 2, mr: 2 }}>
                        <CSVDownloader
                            type={Type.Link}
                            filename={'曲線座標一括登録'}
                            bom={true}
                            data={headerCSV}
                        >
                            <Button variant="contained" disabled={runFlag}>テンプレートを取得</Button>
                        </CSVDownloader>
                    </Stack>
                    <CSVReader
                        config={{
                            skipEmptyLines: true,
                        }}
                        onUploadAccepted={(results: any, file: any) => {
                            let data = [] as any[]
                            for (let index = 0; index < results.data.length; index++) {
                                let isEmptyRow = results.data[index].find((item: any) => item.trim().length > 0);
                                if (isEmptyRow) {
                                    data.push(results.data[index]);
                                }
                            }
                            setDataCSV(data);
                            setFileName("ファイル[" + file.name + "]を取り込みました。\n");
                            setFilePath(file.name);
                            setErrorList("ファイル[" + file.name + "]を取り込みました。\n");
                            setSelectCSV(true);
                        }}
                    >
                        {({
                            getRootProps,
                            acceptedFile,
                        }: any) => (
                            <>
                                    <Stack direction={{ xs: 'column', sm: 'row' }} mt={3}>
                                        <Grid container spacing={1} >
                                            <Grid item xs={10} style={{ display: 'flex', alignItems: 'center' }}>
                                                <FormControl sx={{ width: "100%" }} size="small" variant="outlined">
                                                    <InputLabel htmlFor="filePath">パス</InputLabel>
                                                    <OutlinedInput
                                                        {...getRootProps()}
                                                        id="filePath"
                                                        type={'text'}
                                                        readOnly={true}
                                                        sx={{ backgroundColor: 'floralwhite' }}
                                                        value={filePath}
                                                        endAdornment={
                                                            <>{selectCSV && <InputAdornment position="end">
                                                                <IconButton onClick={() => { handleRemoveFile(); }} disabled={runFlag}>
                                                                    <Close sx={{ fontSize: "25px", color: 'red', cursor: 'pointer' }} />
                                                                </IconButton>
                                                            </InputAdornment>}
                                                            </>
                                                        }
                                                        label="パス"
                                                        onClick={() => { }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item>
                                                <Button variant="contained" {...getRootProps()} disabled={runFlag}>ファイルを追加</Button>
                                            </Grid>
                                        </Grid>
                                    </Stack>
                            </>
                        )}
                    </CSVReader>
                    <div style={{maxHeight:"calc(100vh - 280px)",overflow:"auto"}}>
                        <Stack direction={{ xs: 'column', sm: 'row' }} mt={3}>
                            <Grid container spacing={1} >
                                <Grid item width={'100%'}>
                                    <TextField
                                        fullWidth
                                        name="result"
                                        label="登録状況表示"
                                        multiline
                                        rows={25}
                                        value={errorList}
                                        inputProps={
                                            { readOnly: true, }
                                        }
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>
                            </Grid>
                        </Stack>
                    </div>
                    <Stack direction='row' justifyContent="flex-end" spacing={2} sx={{ mt: 2, mr: 2,mb:2}}>
                        <LoadingButton variant="contained" type="submit" disabled={!selectCSV} loading={runFlag}>実行</LoadingButton>
                        <Button color="inherit" variant="contained" onClick={handleCancel} disabled={runFlag}>キャンセル</Button>
                    </Stack>
                </form>
            </div>
            <FetchHelper
                fetchId={ADD_CURVE_POINT}
                onComplete={(success, data) => success ? handleCreateCurvePoint(data) : handleErrorCreateCurvePoint(data)}
            />
        </>
    );
};

const mapStateToProps = (state: ApplicationState) => ({
    userId: state.app.user?.userId,
});

const mapDispatchToProps = (dispatch: any) => {
    return {
        onCreateCurvePoint: (data: any) => dispatch(actionCreators.fetch(ADD_CURVE_POINT, `/master/curve_point/import_CSV`, "POST", data, false, true)),
        onSuccess: () =>
            dispatch(actionCreators.showMessage({
                type: 'info',
                title: '動作特性本体更新',
                body: '動作特性本体を更新しました。',
            })),
        onError: () =>
            dispatch(actionCreators.showMessage({
                type: 'error',
                title: '検索',
                body: '検索できません。',
            })),
        onCreateError: () =>
            dispatch(actionCreators.showMessage({
                type: 'error',
                title: '動作特性本体登録',
                body: '動作特性本体を登録出来ませんでした。',
            })),
        onUpdateError: () =>
            dispatch(actionCreators.showMessage({
                type: 'error',
                title: '動作特性本体編集',
                body: '動作特性本体を更新出来ませんでした。',
            })),
        onDeleteCurvePointError: () =>
            dispatch(actionCreators.showMessage({
                type: 'error',
                title: '定格編集',
                body: '定格を更新出来ませんでした。',
            })),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddCurveHeaderList as any);


