import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Checkbox, FormControlLabel} from "@mui/material";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from 'ag-grid-react';
import { forwardRef, useImperativeHandle, useMemo, useRef, useState } from "react";
import { AG_GRID_LOCALE_JP } from '../../../themes/localeJP';
import LoadingOverlay, { DefaultLoadingMessage } from '../../common/LoadingOverlay';
import NoRowsOverlay, { NoRowsMessage } from '../../common/NoRowsOverlay';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type UserGridProps = {
    listUser:any;
    keyword: string;
    onSelect: (data: any) => void;
};

export const UserGrid = forwardRef((props: UserGridProps, ref) => {
    const { 
        listUser,
        keyword,
        onSelect,
    } = props;

    const gridRef = useRef() as any;
    const [rowData, setRowData] = useState(listUser);

    useImperativeHandle(ref, () => ({
        setRowData(value: any) {
            setRowData(value)
        },

        hideOverlay() {
            gridRef.current.api.hideOverlay();
        }
    }));

    // Loading時のメッセージ
    const loadingOverlayComponent = useMemo(() => {
        return LoadingOverlay;
    }, []);
    
    const loadingOverlayComponentParams = useMemo(() => {
        return {
            loadingMessage: DefaultLoadingMessage,
        };
    }, []);

    const noRowsOverlayComponent = useMemo(() => {
        return NoRowsOverlay;
    }, []);

    const noRowsOverlayComponentParams = useMemo(() => {
        return {
            noRowsMessageFunc: () => NoRowsMessage,
        };
    }, []);

    // 全列に共通の定義
    const defaultColDef = {
        sortable: true, // ソート可
        editable: false, // 編集不可
        resizable: true,
        suppressMovable: true,  // 列の移動を無効にする
    };
    
    const handleChecked = (e:any,props:any) =>{
        props.data.isSelect = e.target.checked
        let rowNode = gridRef.current.api.getRowNode(props.node.id);
        gridRef.current.api.redrawRows({ rowNodes: [rowNode] });

        onSelect(gridRef.current.props.rowData.filter((e:any) => e.isSelect == true))
    }

    // 列タイプの定義
    const columnTypes = {
        nonEditableColumn: { editable: false }, // 編集不可
    };

    const CellCheckRenderer = (props: any) => {
        const { username, isSelect } = props.data;
        let isSelected = isSelect

        return (
            <FormControlLabel
                control={
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        checked={isSelected}
                        onChange={(e) => {handleChecked(e, props)}}
                    />
                }
                label={username}
            />
        );
    };

    const CellFullnameRenderer = (props:any) => {
        const { family_name, given_name } = props.data;
        return family_name + given_name;
    }

    const CellPlanRenderer = (props: any) => {
        const { custom_plan } = props.data;
        let result = "";
        switch (custom_plan) {
            case "lp":
                result = "Light Pack";
                break;
            case "sp":
                result = "Service Pack";
                break;
            case "pp":
                result = "PM Pack";
                break;
            case "admin":
                result = "ー";
                break;
        }
        return result;
    }

    const CellExpirationDateRenderer = (props: any) => {
        const { custom_expiration_date, custom_plan } = props.data;
        if (custom_plan == "admin")
            return "無期限";
        if (!custom_expiration_date)
            return "";
        if(typeof custom_expiration_date == 'string' && custom_expiration_date.length == 17) {
            const startDate = custom_expiration_date.substring(0, 4) + "/" + custom_expiration_date.substring(4, 6) + "/" + custom_expiration_date.substring(6, 8);
            const endDate = custom_expiration_date.substring(9, 13) + "/" + custom_expiration_date.substring(13, 15) + "/" + custom_expiration_date.substring(15, 18);
            return startDate + "~" + endDate;
        }
        return "";
    }

    // 列の個別定義
    const columnDefs = [
        {
            headerName: "ユーザー名",
            field: "username",
            rowDrag: false,
            flex: 2,
            cellRenderer: CellCheckRenderer,
            cellStyle: { textAlign: 'left' },
        },
        {
            headerName: "管理No",
            field: "custom_management_no",
            rowDrag: false,
            flex: 1.5
        },
        {
            headerName: "会社名",
            field: "custom_company_name",
            rowDrag: false,
            flex: 2,
        },
        {
            headerName: "ユーザー名",
            field: "family_name",
            cellRenderer: CellFullnameRenderer,
            rowDrag: false,
            flex: 2,
        },
        {
            headerName: "権限",
            field: "custom_role",
            rowDrag: false,
            flex: 1,
        },
        {
            headerName: "有効期限",
            field: "custom_expiration_date",
            cellRenderer: CellExpirationDateRenderer,
            rowDrag: false,
            flex: 1,
        },
        {
            headerName: "バック",
            field: "custom_plan",
            cellRenderer: CellPlanRenderer,
            rowDrag: false,
            flex: 1,
        },
        
    ];

    const rowHeight = 48;
    gridRef.current?.api?.setQuickFilter(keyword)
    return (
        <>
            <div className="ag-theme-alpine" style={{ height: '250px', width: "100%" }}>
                <AgGridReact
                    ref={gridRef}
                    defaultColDef={defaultColDef}
                    columnTypes={columnTypes}
                    columnDefs={columnDefs}
                    domLayout={"normal"}
                    rowData={rowData}
                    animateRows={true}
                    rowDragManaged={true}
                    suppressMovableColumns={false}
                    suppressDragLeaveHidesColumns={false}
                    rowHeight={rowHeight}
                    localeText={AG_GRID_LOCALE_JP}
                    loadingOverlayComponent={loadingOverlayComponent}
                    loadingOverlayComponentParams={loadingOverlayComponentParams}
                    noRowsOverlayComponent={noRowsOverlayComponent}
                    noRowsOverlayComponentParams={noRowsOverlayComponentParams}
                />
            </div>
        </>
    );
});

export default UserGrid;