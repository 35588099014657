import { Button, Dialog, DialogContent, DialogTitle, Stack, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import theme from "../../themes/globalStyles";
import DraggablePaper from "../common/DraggablePaper";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useState } from "react";
import dayjs, { Dayjs } from "dayjs";

export type HistoryProjectAddForm = {
    editHistoryId:number|undefined;
    subject : string;
    content : string;
    editDatetime: any;
    editor: string;
}

export type HistoryProjectAddProps = {
    data: HistoryProjectAddForm;
    onOK: (data: any) => void;
    onCancel: () => void;
    viewOnlySharedProjects:boolean;
};

export const HistoryProjectAddDialog = (props: HistoryProjectAddProps) => {

    //#region fields
    const {onOK,data, onCancel,viewOnlySharedProjects } = props;

    // 初期値設定
    const initialValue : HistoryProjectAddForm = {
        ...data,
    }

    const { control, handleSubmit, formState: { errors }, setValue, getValues } = useForm<HistoryProjectAddForm>({
        mode: "all",
        criteriaMode: "all",
        defaultValues: initialValue,
    })
    
    const [datetime, setDateTime] = useState<Dayjs | null>(dayjs(initialValue.editDatetime));

    //#endregion fields

    //#region methods
    // Submitイベント
    const handleEditSubmit = (value: any, event: any) => {
        const request = createRequestData(value);
        onOK(request);
    }
    
    // FormデータからRequestデータを作成する
    const createRequestData = (formValue : HistoryProjectAddForm) => {       
        let request = {
            ...formValue,
        } as HistoryProjectAddForm;
        return request;
    }


    //#endregion methods

    return (
        <>
            <Dialog open={true} maxWidth={"sm"} fullWidth
                PaperComponent={
                    DraggablePaper
                }
            >
                <DialogTitle 
                    onMouseOver={(e : any) => e.target.style.cursor = 'move'}
                    style={{ paddingTop: '2px', paddingBottom: '2px', background: theme.palette.primary.main, color: theme.palette.primary.contrastText }}
                >
                     編集履歴
                </DialogTitle>
                <DialogContent style={{paddingTop: '20px', display: 'flex', alignItems: 'flex-end'}}>
                    <div style={{width: '100%'}}>
                        <form onSubmit={handleSubmit(handleEditSubmit)}>
                            <Stack gap={3}>
                                <Stack direction={'row'} width={'100%'}>
                                    <Controller
                                        name="subject"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                type="text"
                                                size="small"
                                                label="件名"
                                                InputLabelProps={{
                                                    style: { color: 'black' },
                                                }}
                                                disabled={viewOnlySharedProjects}
                                            />
                                        )}
                                    />
                                </Stack>
                                <Stack>
                                    <Controller
                                        name="content"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                type="text"
                                                size="small"
                                                label="内容"
                                                InputLabelProps={{
                                                    style: { color: 'black' },
                                                }}
                                                multiline
                                                minRows={5}
                                                maxRows={5}
                                                disabled={viewOnlySharedProjects}

                                            />
                                        )}
                                    />
                                </Stack>
                                <Stack mt={3}>
                                    <Controller
                                        name="editDatetime"
                                        control={control}
                                        render={({ field }) => (
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoItem>
                                                    <DateTimePicker
                                                    format="YYYY/MM/DD HH:mm"
                                                    label="時間"
                                                    ampm={false}
                                                    // onChange={(newValue) => handleChangeEndDate(newValue)}
                                                    name="editDatetime"
                                                    value={datetime}
                                                    disabled={viewOnlySharedProjects}

                                                    />
                                                </DemoItem>
                                            </LocalizationProvider>
                                        )}
                                    />
                                </Stack>
                                <Stack direction={'row'} width={'100%'}>
                                    <Controller
                                        name="editor"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                type="text"
                                                size="small"
                                                label="編集者"
                                                InputLabelProps={{
                                                    style: { color: 'black' },
                                                }}
                                                disabled={viewOnlySharedProjects}
                                            />
                                        )}
                                    />
                                </Stack>
                            </Stack>
                            <Stack direction='row' justifyContent="flex-end" gap={2} sx={{ mt: 4 }}>
                                <Button variant="contained" type="submit" disabled={viewOnlySharedProjects}>追加</Button>
                                <Button variant="contained" color="inherit" onClick={onCancel}>キャンセル</Button>
                            </Stack>
                        </form>
                    </div>                    
                </DialogContent>
            </Dialog>
        </>
    )
}
export default HistoryProjectAddDialog;