import { CircularProgress, Divider, Typography } from '@mui/material';
import {
    CategoryScale,
    ChartDataset,
    Chart as ChartJS,
    Legend,
    LineElement,
    LinearScale,
    LogarithmicScale,
    Point,
    PointElement,
    Title,
    Tooltip,
    TooltipItem
} from 'chart.js';
import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { connect } from "react-redux";
import { ChartTabModel, ControlModel, DatasetEditForm, DatasetModel, GraphLineData, LineInfoModel, LineStyleModel, LineType } from '../../models/Index';
import { addCalcPointToChart, addLineToChart, clearOptionMenu, deleteGraphByGraphNo, deleteRelatedGraphById, deleteRelatedGraphByUserCurveDialog, isDownloadDiagramPDF, isShowScreen, openUserCurveEvents, openUserCurvePropertiesEvents, pressContextMenuFunction, publishChartEvents, saveAllControlsToChartTab, saveChartIntoChartData, saveChartIntoControl, saveChartZoom, saveCurrentIdChartTab, saveCurrentIdDiagramTab, saveListGraph, saveMaxIdChartTab, saveNewNominalVolt, saveNominalVoltList, setChartId, setGapLine, setGraphData, toggleJapanElectricMode, unSelectAllControls, undo, updateLineInfo, updatePropertiesOfControlAction, updateSelectControl } from '../../store/Actions';
import GraphLabel from './GraphLabel';
import { getGraphLineData, getVoltageCombo } from './graphMethods';
import { convertToDataset, getDefaultDatasetModel, getDefaultLineInfo, getDefaultLineStyle } from './getLineDataset';
import { ApplicationState } from '../../store';
import DatasetEditDialog from '../dialogs/DatasetEditDialog';
import * as chartHelpers from "chart.js/helpers";
import { BeamsNumber } from '../../utils/FormatNumber';
import { Polygon, point } from '@flatten-js/core';
import { MS_MODE_CALCULATION, VOLT_SIDE_SECONDARY, numberExponent } from '../../statics';
import ChartMenu from '../contextMenu/ChartMenu';
import useOutsideClick from '../../hooks/useOutsideClick';
import MeasureDifferenceDialog from '../dialogs/MeasureDifferenceDialog';
import { MS_2E, MS_3WINDING, MS_BUSBAR, MS_BUSDUCT, MS_CONTACTOR, MS_FUSE, MS_HVCB, MS_IMPEDANCE, MS_LVCB, MS_MVCB, MS_REACTOR, MS_SOURCE, MS_TEXT, MS_THERMAL, MS_THERMAL_CT, MS_TRANS1, MS_TRANS3, MS_TRANSCENTER, MS_TRANSSCOTT, MS_WIRE } from '../../models/ElementKind';
import { useParams } from 'react-router-dom';
import { CREATE_RELATED_GRAPH, DELETE_GRAPH, DELETE_RELATED_GRAPH, DIAGRAM_PDF, GET_GRAPH, GET_GRAPH_DISP_BAND, GET_GRAPH_NAME_GRAPH, GET_RELATED_GRAPH, UNDO_RELATED_GRAPH, UPDATE_GRAPH, UPDATE_GRAPH_DISP_BAND, UPDATE_RELATED_GRAPH, UPDATE_RELATED_GRAPH_TEXT, actionCreators } from '../../store/AppStore';
import FetchHelper from '../FetchHelper';
import { store } from '../..';
import SettingUpGraphScaleDialog, { SettingUpGraphScale } from '../dialogs/SettingUpGraphScaleDialog';
import { getElementKindValue, mapProjectDataFromResponse } from '../../utils/ElementFunction';
import * as Model from "../../models/Index";
import AlertDialog from '../common/AlertDialog';
import TextEditorDialog from '../dialogs/TextEditorDialog';
import { getFontStyleValue, getTextDecoration } from '../../utils/formatText';
import { convertAdjustToCallAPI, convertUndefinedToNull } from '../../utils/DataConverter';
import html2canvas from 'html2canvas';
import { downloadFile } from '../../utils/downloadFile';
import { isNumber } from 'mathjs';

const UPDATE_SCALE_GRAPH = "UPDATE_SCALE_GRAPH";
const CREATE_GRAPH_TEXT = "CREATE_GRAPH_TEXT";
const UPDATE_GRAPH_TEXT = "UPDATE_GRAPH_TEXT";
const GET_GRAPH_TEXT_LIST_BY_GRAPH_NO = "GET_GRAPH_TEXT_LIST_BY_GRAPH_NO";
const DELETE = "DELETE";
const UPDATE_GRAPH_SCALE = "UPDATE_GRAPH_SCALE";
//#region Props
type GraphProps = {
    shapes: ControlModel[],
    drawLineInfo: {
        lineType: LineType,
        lineId: number | string,
        state: boolean
    },
    addNewGraph: boolean,
    isOpenGraphPage: boolean,
    undoCut:boolean,
    isReOpenGraphTemporary: boolean,
    isOpenGraphTemporary: boolean,
    undoGraphData: any,
    listObjectText: any[],
    onFinishDrawLineInfo:(data:any) => void,
    setListObjectText: (data: any[]) => void,
    onDrawLines:(items: any[], userCurve: boolean) => void,
    onSetUndoData:(data:any) => void,
} & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
//#endregion

const CreateGraph = memo((props: GraphProps) => {
    //#region fields
    const { 
        // dispatch & state props
        projectData,
        currentIDChartTab,
        chartDataList,
        diagramDataList,
        chartEvents,
        gapLine,
        nominalVolt,
        zoom,
        isJapaneseElectricMode,
        maxIDChartTab,
        isAddLineToChart,
        isAddCalcPointToChart,
        undoCut,
        processMode,
        addNewGraph,
        isOpenGraphPage,
        isReOpenGraphTemporary,
        isOpenGraphTemporary,
        undoGraphData,
        deleteGraphNo,
        undoData,
        deleteGraphLineText,
        reDrawGraphText,
        currentIDDiagramTab,
        isDownloadPDF,
        listObjectText,
        deleteUserCurveRelatedGraph,
        clearMenuState,
        isSelectTouchGroup,
        onFinishDrawLineInfo,
        deleteRelatedGraphByUserCurveDialog,
        setListObjectText,
        onDrawLines,
        toggleJapanElectricMode,
        saveAllControlsToChartTab,
        saveChartIntoControl,
        updateSelectControl,
        unSelectAllControls,
        setAddLineToChart,
        setAddCalcPointToChart,
        publishChartEvents,
        updateLineInfo,
        saveNominalVoltList,
        saveNewNominalVolt,
        setGapLine,
        updateControlData,
        createRelatedGraph,
        undoRelatedGraph,
        updateGraph,
        getGraph,
        setChartId,
        updateRelatedGraph,
        updateRelatedGraphText,
        saveOpenProjectData,
        openUserCurveEvent,
        openUserCurvePropertiesEvent,
        deleteRelatedGraphById,
        deleteRelatedGraph,
        onCreateGraphText,
        onDeleteGraphText,
        saveCurrentIdChartTab,
        onUpdateGraphText,
        onUpdateGraphTextCoordinate,
        onGetGraphTextListByGraphNo,
        getGraphDispBand,
        updateGraphDispBand,
        getGraphName,
        setGraphData,
        getRelatedGraph,
        saveChartZoom,
        updateElementPost,
        deleteGraph,
        deleteGraphByGraphNo,
        setUndoData,  
        onSetUndoData,
        saveChartIntoChartData,
        saveCurrentIDDiagramTab,
        getPDFReport,
        setDownloadDiagramPDF,
        pressContextMenuFunction,
        saveMaxIdChartTab,
		clearOptionMenu,
		updateGraphScale,
        saveListGraphToStore,
        // component props
        shapes,
        drawLineInfo ,
        m_bModePM,
        modeViewOnly,
        storeProjectId,
        userId,
        contextMenuFunction,
        listGraph,
    } = props; 

    //const { projectId } = useParams(); // original project
    const [loadingFlag,setLoadingFlag] = useState(false);
    const [loadChart, setLoadChart] = useState(true);
    const [selectedDataset, setSelectedDataset] = useState<DatasetEditForm | null>(null);
    const [hoverPoints, setHoverPoints] = useState<{x: number, y: number} | null>(null)
    const [contextMenu, setContextMenu] = useState<any>({ visible: false, mode: false, type: false, x: 0, y: 0 });
    const [focusEvent, setFocusEvent] = useState({} as any);
    const [saveClickedDataset, setSaveClickedDataset] = useState<DatasetModel[]>([]);
    const [openSetUpScaleGraph,setOpenSetUpScaleGraph] = useState(false);
    const [openDeleteDialog,setOpenDeleteDialog] = useState(false);
    const [openDeleteGraphDialog,setOpenDeleteGraphDialog] = useState(false);
    const [textPosition,setTextPosition] = useState<any>({x:75, y:15});
    const [listShowIntersectionPointText, setListShowIntersectionPointText] = useState<any>([]);
    const [listTextOfLinesInTab,setListTextOfLinesInTab] = useState<any[]>([]);
    const [listTextOfLinesInTabStore,setListTextOfLinesInTabStore] = useState<any[]>([]);
    const [currentSelectedId,setCurrentSelectedId] = useState<any>();
    const [dataTransferTouch,setDataTransferTouch] = useState<any>();
    const [isTouchMove,setIsTouchMove] = useState<any>(false);
    const [noText,setNoText] = useState<number>(1);
	const [currentMsText,setCurrentMsText] = useState<any>();
    const [isDeleteGraphText,setIsDeleteGraphText] = useState<any>(false);
    const [openTextEditor,setOpenTextEditor] = useState<boolean>(false);
    const [initialDataTextEditor,setInitialDataTextEditor] = useState<any>();
    const [dataUpdateLine,setDataUpdateLine] = useState<DatasetEditForm>();
    const [reloadHandle, setReloadHandle] = useState(false);
    const [reloadChart, setReloadChart] = useState<boolean>(false);
    const [isAddNewGraph, setIsAddNewGraph] = useState(addNewGraph);
    const [relatedGraphData, setRelatedGraphData] = useState<any[]>([]);
    const [relatedGraphUserCurveData, setRelatedGraphUserCurveData] = useState<any[]>([]);
    const [userCurveToDraw, setUserCurveToDraw] = useState<any[]>([]);
    const [drawConnectLines, setDrawConnectLines] = useState(false);
    const [undoFlag, setUndoFlag] = useState(false);
    const [isGetGraphName, setIsGetGraphName] = useState(false);
    const [intersectionData, setIntersectionData] = useState<any>();
    const [isClickOnChart, setIsClickOnChart] = useState(false);
    const [reloadConnectLines, setReloadConnectLines] = useState(false);
    const [isUndoGraph, setIsUndoGraph] = useState(false);
    const [selectedRelatedGraph, setSelectedRelatedGraph] = useState<any>([]);
    const [nominalVoltStore, setNominalVoltStore] = useState<any>(nominalVolt);
    const [currentGraphData,setCurrentGraphData] = useState<any>(listGraph.find((item:any) => item.graphNo == currentIDChartTab));
    const [isOnOpenMenu, setIsOnOpenMenu] = useState<boolean>(false);
    const [timeDoubleTap, setTimeDoubleTap] = useState(new Date().getTime());
	const divRefs = useRef<(HTMLDivElement | null)[]>([]);
    const divLineRefs = useRef<(HTMLDivElement | null)[]>([]);
    const lineDiagramRef = useRef<any>(null);
    const chartDiagramRef = useRef<any>(null);
    const chartRef = useRef<ChartJS<"line">>(null);
    const chartRefPDF = useRef<ChartJS<"line">>(null);
    let textLineStore:any[] = []

    useOutsideClick(lineDiagramRef, () => {
        setContextMenu({ visible: false, mode: false, type: false, x: 0, y: 0 })
    });

    useEffect(()=>{
        if(isOpenGraphTemporary)
        {
            if(isOnOpenMenu) {
                setIsOnOpenMenu(false)
                }
                else
                {
                setContextMenu({ visible: false, mode: false, type: false, x: 0, y: 0 });
                }
        }
      },[clearMenuState])

    useEffect(()=>{
        if(isDownloadPDF.state == true && document.getElementById('getLineDiagramPDF'))
        { 
            handleDownloadLineDiagramPDF()
        }
    },[isDownloadPDF])

    useEffect(() => {
        setNotSelectListTextOfLinesInTab()
        setListShowIntersectionPointText([])
        setDrawConnectLines(!drawConnectLines)
        if(currentIDChartTab !== undefined && currentIDChartTab !==null && currentIDChartTab >=0 && isOpenGraphPage)
        {
            setLoadingFlag(true);
            if(isOpenGraphPage && storeProjectId){
                setIsGetGraphName(true)
                let param = {
                    userId: userId,
                    projectId: storeProjectId,
                    ownerProject: projectData.createUserId
                }
                getGraphName(param)
            }
            else
            {
                setLoadingFlag(false);
            }
        }
    }, [currentIDChartTab,storeProjectId]);

    useEffect(() => {
        handleDataChart()
    }, [shapes]);

    useEffect(() => {
        setCurrentGraphData(listGraph.find((item:any) => item.graphNo == currentIDChartTab));
    },[currentIDChartTab,listGraph])

    useEffect(() => {
        if(undoCut)
        {
            if(undoData.dataUndo.chartData)
            {
                handleSetTextLinesForUndo(undoData.dataUndo.chartData)
                handleSaveRelatedGraphForUndo(undoData.dataUndo.chartData)
            }
        }
        
    }, [undoCut]);

    useEffect(() => {
        handleCalcToDrawConnectLinesWhenReload()
    }, [reloadConnectLines]);

    useEffect(() => {
        undoGraphData != '' && handleUndo()
        onSetUndoData('')
    }, [undoGraphData]);

    useEffect(() => {
        if(listShowIntersectionPointText.length > 0)
        {
            let res:any[] = []
            listShowIntersectionPointText.map((item:any)=>{
                let data = chartRef.current?.data.datasets.find((x:any)=>Number(x.controlId) === Number(item.controlId) && Number(x.relatedGraphId) === Number(item.id))  
                if(data && !res.includes(data))
                    res.push(data)
            })
            setListShowIntersectionPointText([])
            res && res.length > 0 && handleShowTextMeasureLine(res)
        }
        else if(intersectionData && intersectionData.intersectCurrentData.length > 0){
            let res:any[] = []
            intersectionData.intersectCurrentData.map((item:any)=>{
                let data = chartRef.current?.data.datasets.find((x:any)=>Number(x.controlId) === Number(item.controlId) && x.lineId === item.lineId) as any
                let checkEle = data? chartDataList.find((x:any)=>x.tabId === currentIDChartTab)?.shape.find((x:any)=> Number(x.id) === Number(data.controlId)) : undefined
                if(data && !res.includes(data) && checkEle && (checkEle.type === MS_TRANS1 || checkEle.type === MS_TRANSSCOTT || checkEle.type === MS_3WINDING))
                    res.push(data)
            })
            setListShowIntersectionPointText([])
            res && res.length > 0 && handleShowTextMeasureLine(res)
        }
        else if(intersectionData && intersectionData.intersectTimeData.length > 0){
            let res:any[] = []
            intersectionData.intersectTimeData.map((item:any)=>{
                let data = chartRef.current?.data.datasets.find((x:any)=>Number(x.controlId) === Number(item.controlId) && x.lineId === item.lineId) as any
                let checkEle = data? chartDataList.find((x:any)=>x.tabId === currentIDChartTab)?.shape.find((x:any)=> Number(x.id) === Number(data.controlId)) : undefined
                if(data && !res.includes(data) && checkEle && (checkEle.type === MS_TRANS1 || checkEle.type === MS_TRANSSCOTT || checkEle.type === MS_3WINDING))
                    res.push(data)
            })
            setListShowIntersectionPointText([])
            res && res.length > 0 && handleShowTextMeasureLine(res)
        }
    }, [intersectionData]);

    useEffect(() => {
        if(!gapLine.dispGapLine)
        {
           setListShowIntersectionPointText([])
           setDrawConnectLines(!drawConnectLines)
        }
    }, [gapLine.dispGapLine]);

    const setNotSelectListTextOfLinesInTab = () => {
        if(listTextOfLinesInTab.length > 0){
            listTextOfLinesInTab.forEach((item:any) => {
                item.isSelected = false;
            })
        }
    }

    useEffect(() => {
        switch(chartEvents.event) {
            case "SELECT_CONTROLS":
                // set color for selected line
                setNotSelectListTextOfLinesInTab()
                setListShowIntersectionPointText([])
                if (chartRef.current) {
                    let data = [] as DatasetModel[]
                    let dataIntersection = [] as DatasetModel[]
                    chartRef.current.data.datasets.forEach((dataset : any) => {
                        if (chartEvents.payload.id && chartEvents.payload.id.find((x:any)=> Number(x) === Number(dataset.controlId)) != undefined) {
                            listTextOfLinesInTab.length > 0 && listTextOfLinesInTab.forEach(x=>{
                                if(chartEvents.payload.id.find((item:any)=> Number(item) === Number(x.controlId)) && x.graphNo === currentIDChartTab && Number(x.relatedGraphId) == Number(dataset.relatedGraphId)){
                                    if(chartEvents.payload.state == "all")
                                    {
                                        x.isSelected = true
                                        data.push(dataset)
                                        dataset.backgroundColor = '#f00'
                                        dataset.borderColor = '#f00'
                                        !dataset.lineId.toString().includes("INTENSITY") && !dataset.lineId.toString().includes("CALC_POINT") && dataset.lineType == LineType.CONTROL_CURVE && gapLine.dispGapLine && dataIntersection.push(dataset)
                                        if(dataset.lineId.toString().includes("INTENSITY") || (dataset.lineType == LineType.USER_CURVE && dataset.data.filter((item:any)=>item.x != 0 && item.y !=0).length == 1))
                                        {
                                            dataset.pointBorderColor = '#f00'
                                            setDrawConnectLines(!drawConnectLines)
                                        }
                                    } 
                                    else if(chartEvents.payload.state == "intersect")
                                    {
                                        if(x.lineType != LineType.USER_CURVE)
                                        {
                                            x.isSelected = true
                                            data.push(dataset)
                                            dataset.backgroundColor = '#f00'
                                            dataset.borderColor = '#f00'
                                            !dataset.lineId.toString().includes("INTENSITY") && !dataset.lineId.toString().includes("CALC_POINT") && dataset.lineType == LineType.CONTROL_CURVE && gapLine.dispGapLine && dataIntersection.push(dataset)
                                            if(dataset.lineId.toString().includes("INTENSITY"))
                                            {
                                                dataset.pointBorderColor = '#f00'
                                                setDrawConnectLines(!drawConnectLines)
                                            }
                                        }
                                    }
                                    else if(chartEvents.payload.state == "select" && selectedRelatedGraph && selectedRelatedGraph.find((item:any)=>Number(item.relatedGraphId) == Number(dataset.relatedGraphId) && Number(item.relatedGraphId) == Number(x.relatedGraphId)))
                                    {
                                        x.isSelected = true
                                        !data.includes(dataset) && data.push(dataset)
                                        dataset.backgroundColor = '#f00'
                                        dataset.borderColor = '#f00'
                                        !dataset.lineId.toString().includes("INTENSITY") && !dataset.lineId.toString().includes("CALC_POINT") && dataset.lineType == LineType.CONTROL_CURVE && gapLine.dispGapLine && dataIntersection.push(dataset)
                                        if(dataset.lineId.toString().includes("INTENSITY") || (dataset.lineType == LineType.USER_CURVE && dataset.data.filter((item:any)=>item.x != 0 && item.y !=0).length == 1))
                                        {
                                            dataset.pointBorderColor = '#f00'
                                            setDrawConnectLines(!drawConnectLines)
                                        }
                                    }
                                }
                            })
                            setNotSelectListObjectText()
                        } else {
                            dataset.backgroundColor = dataset.savedColor
                            dataset.borderColor = dataset.savedColor
                            if(dataset.lineId.toString().includes("INTENSITY") || (dataset.lineType == LineType.USER_CURVE && dataset.data.filter((item:any)=>item.x != 0 && item.y !=0).length == 1))
                            {
                                dataset.pointBorderColor = dataset.savedColor
                            }
                            if(chartEvents.payload.id != undefined)
                            {
                                setNotSelectListObjectText()
                            }
                        }
                    })
                    setSaveClickedDataset(data)
                    handleShowTextMeasureLine(dataIntersection)
                    chartRef.current.update('none')
                    setDrawConnectLines(!drawConnectLines)
                }
                else
                {
                    setSaveClickedDataset([])
                }
                break;
        }
        
    }, [chartEvents]) //, currentIDChartTab, chartDataList

    useEffect(() => {
        if(deleteGraphNo && deleteGraphNo.payload && deleteGraphNo.payload.isDelete)
        {
           setOpenDeleteGraphDialog(true)
        }
    }, [deleteGraphNo]);

    useEffect(() => {
        if(deleteGraphLineText.length > 0)
        {
            let arr = [...listTextOfLinesInTab]
            let arrStore = [...listTextOfLinesInTabStore]
            deleteGraphLineText.map((x:any)=>{
                if(listTextOfLinesInTab.filter((text:any)=>Number(text.controlId) === Number(x)).length > 0)
                {
                    listTextOfLinesInTab.filter((text:any)=>Number(text.controlId) === Number(x)).forEach((item:any)=>{
                        arr.splice(arr.indexOf(item), 1); 
                    })
                }
                if(listTextOfLinesInTabStore.filter((text:any)=>Number(text.id) === Number(x)).length > 0)
                {
                    listTextOfLinesInTabStore.filter((text:any)=>Number(text.controlId) === Number(x)).forEach((item:any)=>{
                        arrStore.splice(arrStore.indexOf(item), 1); 
                    })
                }
            })
            setListTextOfLinesInTab(arr)
            setListTextOfLinesInTabStore(arrStore)
            setDrawConnectLines(!drawConnectLines)
        }
    }, [deleteGraphLineText]);

    useEffect(() => {
        if(deleteUserCurveRelatedGraph && deleteUserCurveRelatedGraph.mode)
        {
            if(listTextOfLinesInTab.filter((text:any)=>Number(text.controlId) === Number(deleteUserCurveRelatedGraph.controlId) && text.lineType == LineType.USER_CURVE).length > 0)
            {
                let arr = [...listTextOfLinesInTab]
                const chartData = chartDataList.find((x:any)=>x.tabId == currentIDChartTab)!.shape.find((x:any)=> Number(x.id) === Number(deleteUserCurveRelatedGraph.controlId))!.chart.find((x:any)=> x.lineType == LineType.USER_CURVE && x.data.find((x:any)=>x.userCurveId)?.userCurveId == deleteUserCurveRelatedGraph.data ||  x.data.find((x:any)=>x.user_curve_id)?.user_curve_id == deleteUserCurveRelatedGraph.data)!
                chartData && listTextOfLinesInTab.filter((text:any)=>Number(text.controlId) === Number(deleteUserCurveRelatedGraph.controlId) && Number(chartData.relatedGraphId) == Number(text.relatedGraphId) && text.lineType == LineType.USER_CURVE).forEach((item:any)=>{
                    arr.splice(arr.indexOf(item), 1); 
                })
                setListTextOfLinesInTab(arr)
            }
            if(listTextOfLinesInTabStore.filter((text:any)=>Number(text.id) === Number(deleteUserCurveRelatedGraph.controlId) && text.lineType == LineType.USER_CURVE).length > 0)
            {
                let arr = [...listTextOfLinesInTabStore]
                const chartData = chartDataList.find((x:any)=>x.tabId == currentIDChartTab)!.shape.find((x:any)=> Number(x.id) === Number(deleteUserCurveRelatedGraph.controlId))!.chart.find((x:any)=>x.lineType == LineType.USER_CURVE && x.data.find((x:any)=>x.userCurveId)?.userCurveId == deleteUserCurveRelatedGraph.data ||  x.data.find((x:any)=>x.user_curve_id)?.user_curve_id == deleteUserCurveRelatedGraph.data)!
                chartData && listTextOfLinesInTabStore.filter((text:any)=>Number(text.controlId) === Number(deleteUserCurveRelatedGraph.controlId) && Number(chartData.relatedGraphId) == Number(text.relatedGraphId) && text.lineType == LineType.USER_CURVE).forEach((item:any)=>{
                    arr.splice(arr.indexOf(item), 1); 
                })
                setListTextOfLinesInTabStore(arr)
            }
            deleteRelatedGraphByUserCurveDialog({...deleteUserCurveRelatedGraph,mode:false})
            setDrawConnectLines(!drawConnectLines)
        }
    }, [deleteUserCurveRelatedGraph]);

    useEffect(() => {
        if(reDrawGraphText.length > 0 && !isReOpenGraphTemporary)
        {   
            reDrawGraphText.map((controls:ControlModel)=> {
                let tempData:any[] = [...chartDataList.filter(charts=>charts.shape.find(shapes=>Number(shapes.id) === Number(controls.id)))]
                tempData && tempData.map((temp:any) => {
                    let foundShape =  temp.shape.find((shapes:any)=>Number(shapes.id) === Number(controls.id))
                    foundShape && foundShape.chart.map((charts:any)=>{
                        let infos:any[] = []
                        if(charts.lineType !== LineType.USER_CURVE)
                        {
                            infos = GraphLabel.getControlGraphLabel(foundShape as ControlModel, charts.lineId)
                        }
                        else
                        {
                            const foundUserCurve = foundShape.properties.listUser.find((userCurve : any) => userCurve.userCurveId === charts.data.find((x:any)=>x.userCurveId)?.userCurveId || userCurve.userCurveId === charts.data.find((x:any)=>x.user_curve_id)?.user_curve_id)
                            if (foundUserCurve) {
                                infos = [
                                    foundUserCurve.refNo,
                                    foundUserCurve.name,
                                    ...foundUserCurve.notes.split('\n'),
                                ]
                            }
                        }
                        if(listTextOfLinesInTab.find(x=>Number(x.controlId) === Number(foundShape.id) && x.lineId === charts.lineId && Number(x.relatedGraphId) === Number(charts.relatedGraphId)) !== undefined && !listTextOfLinesInTab.find(x=>Number(x.controlId) === Number(foundShape.id) && x.lineId === charts.lineId && Number(x.relatedGraphId) === Number(charts.relatedGraphId)).isShowTextFree)
                            listTextOfLinesInTab.find(x=>Number(x.controlId) === Number(foundShape.id) && x.lineId === charts.lineId && Number(x.relatedGraphId) === Number(charts.relatedGraphId)).text = infos
                        setListTextOfLinesInTab(listTextOfLinesInTab)
                        if(listTextOfLinesInTabStore.find(x=>Number(x.controlId) === Number(foundShape.id) && x.lineId === charts.lineId && Number(x.relatedGraphId) === Number(charts.relatedGraphId)) !== undefined && !listTextOfLinesInTabStore.find(x=>Number(x.controlId) === Number(foundShape.id) && x.lineId === charts.lineId && Number(x.relatedGraphId) === Number(charts.relatedGraphId)).isShowTextFree)
                            listTextOfLinesInTabStore.find(x=>Number(x.controlId) === Number(foundShape.id) && x.lineId === charts.lineId && Number(x.relatedGraphId) === Number(charts.relatedGraphId)).text = infos
                        setListTextOfLinesInTabStore(listTextOfLinesInTabStore)
                })})
            })
        }
    }, [reDrawGraphText]);

    useEffect( ()=> {
        if(contextMenuFunction && contextMenuFunction > 0) {
            switch (contextMenuFunction) {
                case 1:
                    handleCreateMsText(75,15)
                    break;
                case 2:
                    contextMenuFuntions.onSetUpGraphScale()
                    break;
    
                case 3:
                    contextMenuFuntions.onDiffenceMeasurement()
                    break;
    
                case 4:
                    handleOnJapanElectric()
                    break;
    
                case 5:
                    handleOnChangeGraphDispBand()
                    break;
                
                default:
                    break;
            }
            pressContextMenuFunction(0)
        }
    },[contextMenuFunction])

    
    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend,
        LogarithmicScale
    );

    const labels = useMemo(() => new Array(2000).fill(0).map((_, i) => (i + 1) * 10), [])

    const options = useMemo(() => ({
        animation: false, //true
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            title: {
                display: false,
            },
            legend: {
                display: false,
            },
            tooltip: {
                displayColors: true,
                backgroundColor: 'rgba(242, 242, 242, 0.5)',
                bodyColor: '#1a1a1a',
                borderWidth: 1,
                borderColor: 'rgba(143, 190, 1, 0.7)',
                padding: 15,
                titleFont: {
                    size: 16,
                    weight: 'bold',
                },
                bodyFont: {
                    size: 14,
                },
                titleColor: '#1a1a1a',
                titleFontStyle: 'bold',
                callbacks: {
                    title: function (contextArr: TooltipItem<any>[]) {
                        return contextArr[0].dataset.label
                    },
                    beforeLabel: function (context: any) {
                        //Return value for label
                        return "電流: " + context.parsed.x + " (A)";
                    },
                    afterLabel: function (context: any) {
                        //Return value for label
                        return "時間: " + context.parsed.y + " (s)";
                    },
                    label: function (context: any) {
                        //disable default label
                        return "";
                    },
                    
                },
            },
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: `⎯⎯⎯⎯⎯⎯⎯⟶ 電流（A）at ${nominalVolt??0} V`,
                    // font:{
                    //     size:20
                    // }
                },
                grid: {
                    color: function (tick: any) {
                        if (Number.isInteger(Math.log10(tick.tick.value))) {
                            return 'rgba(77, 77, 77, 1)';
                        } else {
                            return 'rgba(77, 77, 77, 0.5)';
                        }
                    },
                    tickColor: '',
                },
                min: currentGraphData ? !isJapaneseElectricMode ? currentGraphData.normalXMin : '20' : !isJapaneseElectricMode ? projectData.defXMin : '20',
                max: currentGraphData ? !isJapaneseElectricMode ? currentGraphData.normalXMax : currentGraphData.societyXMax : !isJapaneseElectricMode ? projectData.defXMax : '30000',
                type: 'logarithmic',
                autoSkip: false,
                display: true,
                ticks: {
                    maxRotation: 0,
                    stepSize: 100,
                    callback: function (value: any, index: any, ticks: any) {
                        if (Number.isInteger(Math.log10(value))) {
                            return value;
                        } else if ([20, 300000].includes(value) && isJapaneseElectricMode) {
                            return value;
                        }
                        else if (Number.isInteger(Math.log10(value / 1.5))) {
                            return;
                        } else
                            return '';
                    },
                    autoSkip: false,
                },
            },
            y: {
                title: {
                    display: true,
                    text: '⎯⎯⎯⎯⎯⎯⎯⟶ 時間（秒）',
                },
                display: true,
                min: !isJapaneseElectricMode ? (currentGraphData ? currentGraphData.normalYMin : projectData.defYMin) : (currentGraphData ? currentGraphData.societyYMin : "10"),
                max: !isJapaneseElectricMode ? (currentGraphData ? currentGraphData.normalYMax : projectData.defYMax) : (currentGraphData ? currentGraphData.societyYMax : "100000"),
                type: 'logarithmic',
                grid: {
                    color: function (tick: any) {
                        if (Number.isInteger(Math.log10(tick.tick.value))) {
                            return 'rgba(77, 77, 77, 1)';
                        } else {
                            return 'rgba(77, 77, 77, 0.5)';
                        }
                    },
                    tickColor: '',
                },
                ticks: {
                    stepSize: 10000,
                    callback: function (value: any, index: any, ticks: any) {
                        if (Number.isInteger(Math.log10(value))) {
                            return value;
                        } else if (Number.isInteger(Math.log10(value / 1.5))) {
                            return;
                        } else
                            return '';
                    },
                    autoSkip: false,
                    borderWidth: 20,
                },
            },
            yAxes: {
                title: {
                    display: false,
                    text: '',
                },
                display: false,
                min: !isJapaneseElectricMode ? (currentGraphData ? currentGraphData.normalYMin : projectData.defYMin) : (currentGraphData ? currentGraphData.societyYMin : "10"),
                max: !isJapaneseElectricMode ? (currentGraphData ? currentGraphData.normalYMax : projectData.defYMax) : (currentGraphData ? currentGraphData.societyYMax : "100000"),
                type: 'logarithmic',
                grid: {
                    color: function (tick: any) {
                        if (Number.isInteger(Math.log10(tick.tick.value))) {
                            return 'rgba(77, 77, 77, 1)';
                        } else {
                            return 'rgba(77, 77, 77, 0.5)';
                        }
                    },
                    tickColor: '',
                },
                position: "right",
                ticks: {
                    stepSize: 10000,
                    callback: function (value: any, index: any, ticks: any) {
                        if (Number.isInteger(Math.log10(value))) {
                            return value;
                        } else if (Number.isInteger(Math.log10(value / 1.5))) {
                            return;
                        } else
                            return '';
                    },
                    autoSkip: false,
                    borderWidth: 20,
                },
            }
        },
        onHover: function(event: any) {            
            const ctx = chartRef.current as any
            const cursorPos = chartHelpers.getRelativePosition(event, ctx);
            const dataX = ctx.scales.x.getValueForPixel(cursorPos.x);
            const dataY = ctx.scales.y.getValueForPixel(cursorPos.y);

            // if cursor out of grid
            if (dataX < (currentGraphData ? currentGraphData.normalXMin : projectData.defXMin) || dataX > (currentGraphData ? currentGraphData.normalXMax : projectData.defXMax) || dataY < (currentGraphData ? currentGraphData.normalYMin : projectData.defYMin) || dataY > (currentGraphData ? currentGraphData.normalYMax : projectData.defXMax)) {setHoverPoints(null); return}

            // search near line dataset
            if (isHittedCurve({x: cursorPos.x, y: cursorPos.y}) && isHittedCurve({x: cursorPos.x, y: cursorPos.y})?.lineType != LineType.MEASUREMENT_LINE && isHittedCurve({x: cursorPos.x, y: cursorPos.y})?.lineType != LineType.CONNECT_LINE) {                
                event.native.target.style.cursor = 'pointer'
            } else {
                event.native.target.style.cursor = 'default'
            }

            setHoverPoints({x: BeamsNumber(dataX, 6), y: BeamsNumber(dataY, 6)})
        },
    }), [isJapaneseElectricMode, currentGraphData, nominalVolt])

    const optionsPDF = useMemo(() => ({
        animation: false, //true
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            title: {
                display: false,
            },
            legend: {
                display: false,
            },
            tooltip: {
                displayColors: true,
                backgroundColor: 'rgba(242, 242, 242, 0.5)',
                bodyColor: '#1a1a1a',
                borderWidth: 1,
                borderColor: 'rgba(143, 190, 1, 0.7)',
                padding: 15,
                titleFont: {
                    size: 16,
                    weight: 'bold',
                },
                bodyFont: {
                    size: 14,
                },
                titleColor: '#1a1a1a',
                titleFontStyle: 'bold',
                callbacks: {
                    title: function (contextArr: TooltipItem<any>[]) {
                        return contextArr[0].dataset.label
                    },
                    beforeLabel: function (context: any) {
                        //Return value for label
                        return "電流: " + context.parsed.x + " (A)";
                    },
                    afterLabel: function (context: any) {
                        //Return value for label
                        return "時間: " + context.parsed.y + " (s)";
                    },
                    label: function (context: any) {
                        //disable default label
                        return "";
                    },
                    
                },
            },
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: isJapaneseElectricMode? `⎯⎯⎯⎯⎯⎯⎯⮞ 電流（A）                                                                （公称電圧 ${nominalVolt??0}V）` : `⎯⎯⎯⎯⎯⎯⎯⟶ 電流（A）at ${nominalVolt??0} V`,
                    align: isJapaneseElectricMode? 'end' : 'center',
                    color: '#000000',
                    font: {
                        size: 12
                    }
                },
                grid: {
                    color: function (tick: any) {
                        if (Number.isInteger(Math.log10(tick.tick.value))) {
                            return 'rgba(77, 77, 77, 1)';
                        } else {
                            return 'rgba(77, 77, 77, 0.5)';
                        }
                    },
                    tickColor: '',
                },
                min: currentGraphData ? !isJapaneseElectricMode ? currentGraphData.normalXMin : '20' : !isJapaneseElectricMode ? projectData.defXMin : '20',
                max: currentGraphData ? !isJapaneseElectricMode ? currentGraphData.normalXMax : currentGraphData.societyXMax : !isJapaneseElectricMode ? projectData.defXMax : '30000',
                type: 'logarithmic',
                autoSkip: false,
                display: true,
                ticks: {
                    maxRotation: 0,
                    stepSize: 100,
                    callback: function (value: any, index: any, ticks: any) {
                        if(isJapaneseElectricMode && Number.isInteger(value))
                            {
                                if (value < 1) {					
                                    return value
                                }
                                else if (value == 20) {	// 最小のとき
                                    if (value > 2) {
                                        return '2×10' + (numberExponent.find(x=>x.label === Math.log10(value / 2).toString()) !== undefined ? numberExponent.find(x=>x.label === Math.log10(value / 2).toString())!.value : '')
                                    }
                                    else {
                                        return value;
                                    }
                                }
                                else if (value == 300000) {	// 最大のとき
                                    return '3×10' + (numberExponent.find(x=>x.label === Math.log10(value / 3).toString()) !== undefined? numberExponent.find(x=>x.label === Math.log10(value / 3).toString())!.value : '')
                                }
                                else if (value == 1 || value == 3 || value == 5 || value == 30 || value == 50) {
                                    return value;
                                }
                                else if (value % 10 == 0) {		// １０で割り切れるとき
                                    if (value == 10) {
                                        return value;
                                    }
                                    else {
                                        const dMulti = Math.log10(value) % 1;
                                        if (dMulti == 0) {				// １０の乗数のとき
                                            return '1×10' + (numberExponent.find(x=>x.label === Math.log10(value).toString()) !== undefined ? numberExponent.find(x=>x.label === Math.log10(value).toString())!.value : '')
                                        }
                                        else {
                                            const dValue = value / Math.pow(10,  Math.floor(Math.log10(value)));
                                            if (dValue == 3) {
                                                return '3×10' + (numberExponent.find(x=>x.label === Math.log10(value/3).toString()) !== undefined ? numberExponent.find(x=>x.label === Math.log10(value/3).toString())!.value : '')
                                            }
                                            if (dValue == 5) {
                                                return '5×10' + (numberExponent.find(x=>x.label === Math.log10(value/5).toString()) !== undefined ? numberExponent.find(x=>x.label === Math.log10(value/5).toString())!.value : '')
                                            }
                                            else
                                            { 
                                                if (Number.isInteger(Math.log10(value / 1.5))) {
                                                    return;
                                                } else
                                                    return '';
                                            }
                                        }
                                    }
                                }
                                else
                                {
                                    if (Number.isInteger(Math.log10(value / 1.5))) {
                                        return;
                                    } else
                                        return '';
                                }
                            }
                            else
                            {
                                if (Number.isInteger(Math.log10(value))) {
                                    return value;
                                } else if ([20, 300000].includes(value) && isJapaneseElectricMode) {
                                    return value;
                                }
                                else if (Number.isInteger(Math.log10(value / 1.5))) {
                                    return;
                                } else
                                    return '';
                            }
                    },
                    autoSkip: false,
                    color:'#000000'
                },
            },
            y: {
                title: {
                    display: true,
                    text: '⎯⎯⎯⎯⎯⎯⎯⮞ 時間（秒）',
                    color: '#000000',
                    font: {
                        size: 12
                    }
                },
                display: true,
                min: !isJapaneseElectricMode ? (currentGraphData ? currentGraphData.normalYMin : projectData.defYMin) : (currentGraphData ? currentGraphData.societyYMin : "10"),
                max: !isJapaneseElectricMode ? (currentGraphData ? currentGraphData.normalYMax : projectData.defYMax) : (currentGraphData ? currentGraphData.societyYMax : "100000"),
                type: 'logarithmic',
                grid: {
                    color: function (tick: any) {
                        if (Number.isInteger(Math.log10(tick.tick.value))) {
                            return 'rgba(77, 77, 77, 1)';
                        } else {
                            return 'rgba(77, 77, 77, 0.5)';
                        }
                    },
                    tickColor: '',
                },
                ticks: {
                    stepSize: 10000,
                    callback: function (value: any, index: any, ticks: any) {
                        if(isJapaneseElectricMode && isNumber(value))
                        {
                            let res = ''
                            switch (value) {
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                            case 5:					// 小数点以下１桁まで表示する
                                res = value + '.0'
                                break;
                            default:
                                if (value >= 10000) {
                                    res = value / 10000 + ''
                                    Number(res) <= 5 ? (res = res + '×10⁴') : (res = '')
                                }
                                else if (value >= 1000 && value <= 5000) {
                                    res = value / 1000 + ''
                                    Number(res) <= 5 ? (res = res + '×10³') : (res = '')
                                }
                                else if (value >= 100 && value <= 500) {
                                    res = value / 100 + ''
                                    Number(res) <= 5 ? (res = res + '×10²') : (res = '')
                                }
                                else if (value >= 10 && value <= 60) {
                                    res = value / 10 + ''
                                    Number(res) <= 6 && Number(res) % 1 == 0 ? (res = Number(res) * 10 + '') : (res = '')
                                }
                                else if (value >= 0.1 && value <= 0.5) {
                                    res = value * 10 + ''
                                    Number(res) <= 5 && Number(res) % 1 == 0 ? (res = Number(res) / 10 + '') : (res = '')
                                }
                                else if (value >= 0.01 && value <= 0.05) {
                                    res = value * 100 + ''
                                    Number(res) <= 5 && Number(res) % 1 == 0 ? (res = Number(res) / 100 + '') : (res = '')
                                }
                                else if (value >= 0.001 && value <= 0.005) {
                                    res = value * 1000 + ''
                                    Number(res) <= 5 && Number(res) % 1 == 0 ? (res = Number(res) / 1000 + '') : (res = '')
                                }
                                break;
                            }
                            if (Number.isInteger(Math.log10(value / 1.5))) {
                                return;
                            } else
                                return res;
                        }
                        else
                        {
                            if (Number.isInteger(Math.log10(value))) {
                                return value;
                            } else if (Number.isInteger(Math.log10(value / 1.5))) {
                                return;
                            } else
                                return '';
                        }
                    },
                    autoSkip: false,
                    borderWidth: 20,
                    color:'#000000'
                },
            },
            yAxes: {
                title: {
                    display: false,
                    text: '',
                },
                display: true,
                min: !isJapaneseElectricMode ? (currentGraphData ? currentGraphData.normalYMin : projectData.defYMin) : (currentGraphData ? currentGraphData.societyYMin : "10"),
                max: !isJapaneseElectricMode ? (currentGraphData ? currentGraphData.normalYMax : projectData.defYMax) : (currentGraphData ? currentGraphData.societyYMax : "100000"),
                type: 'logarithmic',
                grid: {
                    color: function (tick: any) {
                        if (Number.isInteger(Math.log10(tick.tick.value))) {
                            return 'rgba(77, 77, 77, 1)';
                        } else {
                            return 'rgba(77, 77, 77, 0.5)';
                        }
                    },
                    tickColor: '',
                },
                position: "right",
                ticks: {
                    stepSize: 10000,
                    callback: function (value: any, index: any, ticks: any) {
                        if(isJapaneseElectricMode && isNumber(value))
                            {
                                let res = ''
                                switch (value) {
                                case 1:
                                case 2:
                                case 3:
                                case 4:
                                case 5:					// 小数点以下１桁まで表示する
                                    res = value + '.0'
                                    break;
                                default:
                                    if (value >= 10 && value <= 50) {
                                        res = value / 10 + ''
                                        Number(res) <= 6 && Number(res) % 1 == 0 ? (res = Number(res) * 10 + '') : (res = '')
                                    }
                                    else if (value >= 0.1 && value <= 0.5) {
                                        res = value * 10 + ''
                                        Number(res) <= 5 && Number(res) % 1 == 0 ? (res = Number(res) / 10 + '') : (res = '')
                                    }
                                    else if (value >= 0.01 && value <= 0.05) {
                                        res = value * 100 + ''
                                        Number(res) <= 5 && Number(res) % 1 == 0 ? (res = Number(res) / 100 + '') : (res = '')
                                    }
                                    else if (value >= 0.001 && value <= 0.005) {
                                        res = value * 1000 + ''
                                        Number(res) <= 5 && Number(res) % 1 == 0 ? (res = Number(res) / 1000 + '') : (res = '')
                                    }
                                    break;
                                }
                                if (Number.isInteger(Math.log10(value / 1.5))) {
                                    return;
                                } else
                                    return res;
                            }
                            else
                            {
                                if (Number.isInteger(Math.log10(value))) {
                                    return value;
                                } else if (Number.isInteger(Math.log10(value / 1.5))) {
                                    return;
                                } else
                                    return '';
                            }
                    },
                    autoSkip: false,
                    borderWidth: 20,
                    color:'#000000'
                },
            }
        },
    }), [isJapaneseElectricMode, currentGraphData, nominalVolt])

    const isHittedCurve = (cursorPos : {
        x: number;
        y: number;
    }) => {
        let isHitted = null
        for (let i = 0; i < (chartRef.current?.data.datasets.length || 0); i++) {
            const dataset = chartRef.current?.data.datasets[i] as any
            if (!dataset && (dataset.lineType === LineType.MEASUREMENT_LINE || dataset.lineType === LineType.CONNECT_LINE)) return null
            if (dataset.data.length === 1) {
                if (dataset.data[0]) {
                    const point1 = {
                        x: chartRef.current?.scales.x.getPixelForValue(dataset.data[0].x), 
                        y: chartRef.current?.scales.y.getPixelForValue(dataset.data[0].y),
                    } as Point
                    if (doSearchNearPoint(cursorPos, point1, point1)) 
                    {
                        isHitted = dataset
                        break;
                    }
                }
            }
            for(let index = 0; index < dataset.data.length; index++) {
                const point = dataset.data[index]
                if (!point) return null

                if (index < dataset.data.length - 1) {
                    const point1 = {
                        x: chartRef.current?.scales.x.getPixelForValue(point.x), 
                        y: chartRef.current?.scales.y.getPixelForValue(point.y),
                    } as Point
                    const point2 = {
                        x: chartRef.current?.scales.x.getPixelForValue(dataset.data[index + 1].x),
                        y: chartRef.current?.scales.y.getPixelForValue(dataset.data[index + 1].y),
                    } as Point
                    if (doSearchNearPoint(cursorPos, point1, point2)) 
                    {
                        isHitted = dataset
                        break;
                    }
                }
            }

            if (isHitted) break;
        }
        return isHitted
    }

    const doSearchNearPoint = (cursorPos : Point, point1 : Point, point2: Point) => {
        const OFFSET_CURSOR = 1
        const pointList = []
        if (point1.x === point2.x && point1.y === point2.y) {
            pointList[0] = point(
                point1.x - OFFSET_CURSOR * 2,
                point1.y - OFFSET_CURSOR * 2
            )
            pointList[1] = point(
                point1.x + OFFSET_CURSOR * 2,
                point1.y - OFFSET_CURSOR * 2
            )
            pointList[2] = point(
                point2.x + OFFSET_CURSOR * 2,
                point2.y + OFFSET_CURSOR * 2
            )
            pointList[3] = point(
                point2.x - OFFSET_CURSOR * 2,
                point2.y + OFFSET_CURSOR * 2
            )
        }
        else {
            if (point1.x + OFFSET_CURSOR > point2.x) {
                pointList[0] = point(
                    point1.x - OFFSET_CURSOR * 2,
                    point1.y
                )
                pointList[1] = point(
                    point1.x + OFFSET_CURSOR * 2,
                    point1.y
                )
                pointList[2] = point(
                    point2.x + OFFSET_CURSOR * 2,
                    point2.y
                )
                pointList[3] = point(
                    point2.x - OFFSET_CURSOR * 2,
                    point2.y
                )
            } else if (point1.y + OFFSET_CURSOR > point2.y) {
                pointList[0] = point(
                    point1.x,
                    point1.y - OFFSET_CURSOR * 2
                )
                pointList[1] = point(
                    point1.x,
                    point1.y + OFFSET_CURSOR * 2
                )
                pointList[2] = point(
                    point2.x,
                    point2.y + OFFSET_CURSOR * 2
                )
                pointList[3] = point(
                    point2.x,
                    point2.y - OFFSET_CURSOR * 2
                )
            } else if (point1.x < point2.x && point1.y > point2.y) {
                pointList[0] = point(
                    point1.x - OFFSET_CURSOR,
                    point1.y - OFFSET_CURSOR
                )
                pointList[1] = point(
                    point1.x + OFFSET_CURSOR,
                    point1.y + OFFSET_CURSOR
                )
                pointList[2] = point(
                    point2.x + OFFSET_CURSOR,
                    point2.y + OFFSET_CURSOR
                )
                pointList[3] = point(
                    point2.x - OFFSET_CURSOR,
                    point2.y - OFFSET_CURSOR
                )
            } else {
                pointList[0] = point(
                    point1.x - OFFSET_CURSOR,
                    point1.y + OFFSET_CURSOR
                )
                pointList[1] = point(
                    point1.x + OFFSET_CURSOR,
                    point1.y - OFFSET_CURSOR
                )
                pointList[2] = point(
                    point2.x + OFFSET_CURSOR,
                    point2.y - OFFSET_CURSOR
                )
                pointList[3] = point(
                    point2.x - OFFSET_CURSOR,
                    point2.y + OFFSET_CURSOR
                )
            }
        }

        
        const rgnRange = new Polygon();
        rgnRange.addFace(pointList);
        return rgnRange.contains(point(cursorPos.x, cursorPos.y))
    }

    const handleCreateCalcPointLineWhenChangeMode = async() =>{
        const drawnEleIds: string[] = [];
        let arrayDataToSaveGraph: any[] = [];
        let checkShapes = store.getState().app.diagram.chartData.find((x:any)=>x.tabId === currentIDChartTab )?.shape!
        if(checkShapes){
            for (let index = 0; index < checkShapes.length; index++) {
                let check = checkShapes[index].chart.find(x=>x.lineId === "CALC_POINT_0" || x.lineId === "CALC_POINT_1" || x.lineId === ("CALC_POINT_0_2") || x.lineId === ("CALC_POINT_0_3") || x.lineId === ("CALC_POINT_1_2") || x.lineId === ("CALC_POINT_1_3"))
                if(check === null || check === undefined) {
                    let element = checkShapes[index];
                    await getGraphLineData(element, true, 2).then(x=>{
                        let arrayLineData:any[] = [];
                        x.lines.forEach(line => {
                            const newLineInfo = getDefaultLineInfo(uuidv4(), element, line.points, line.id, currentIDChartTab, element.type)
                            arrayLineData.push(newLineInfo)
                            element.chart.push(newLineInfo) //save to control
        
                            drawnEleIds.push(newLineInfo.controlId) //to draw
                        })
                        
                        arrayDataToSaveGraph.push({data : arrayLineData, element : element})
        
                        if (x.raw !== undefined && element.raw !== undefined) { //controls those call db will have raw, save to cache
                            element.raw = x.raw
                        }
                        saveChartIntoControl(element);
                        handleSaveRelatedGraph(arrayDataToSaveGraph,nominalVolt);
                    })
                }
                else
                {
                    setReDrawLine(!reDrawLine)
                }
           
            }
            const updatedShapes = [...checkShapes].filter(shape => {
                return drawnEleIds.includes(shape.id)
            });
            if(updatedShapes.length > 0)
            {
                saveAllControlsToChartTab(updatedShapes, false);
            }
        }
    }

    const [reDrawLine, setReDrawLine] = useState(false)

    useEffect(()=>{
        if (processMode >= MS_MODE_CALCULATION)
        {
            handleCreateCalcPointLineWhenChangeMode()
        }
        else
        {
            setReDrawLine(!reDrawLine)
        }
    }, [processMode])

    const getPixelForValue = (position:any) => {
        const ctx = chartRef.current as any
        const dataX = ctx?.scales.x.getPixelForValue(position.x);
        const dataY = ctx?.scales.y.getPixelForValue(position.y);
        return {x:dataX,y:dataY};
    }

    const getPixelForValueDivPDF = (position:any) => {
        const ctx = chartRefPDF.current as any
        const dataX = ctx?.scales.x.getPixelForValue(position.x);
        const dataY = ctx?.scales.y.getPixelForValue(position.y);
        return {x:dataX,y:dataY};
    }

    const handleCalcPointMin = (item:any, x:any, y:any) => {
        let offsets = document.getElementById("line_"+item.id)?.getBoundingClientRect();
        let result = { x: x, y: y}
        let sum = -1;
        let markPoint = getPixelForValue({x:item.savedX,y:item.savedY})
        if(offsets){
            [{x:0,y:0},{x:0,y:offsets.height},{x:offsets.width,y:0},{x:offsets.width,y:offsets.height}].forEach(points=>{
                let calc = Math.sqrt((markPoint.x - (x + points.x)) * (markPoint.x - (x + points.x)) + (markPoint.y - (y + points.y)) * (markPoint.y - (y + points.y)))
                if(calc < sum || sum < 0){
                    sum = calc;
                    result.x = x + points.x;
                    result.y = y + points.y;
                }
            })
        }
        return result
    }
  
    const currentChartTabData = useMemo(() => {   
        // const checkEle = document.getElementById('getLineDiagramPDF')
        // checkEle && (checkEle.style.display = 'none')
      
        const datasets = [] as DatasetModel[]
        const currentTabCharData = chartDataList.find((chartTab : ChartTabModel) => chartTab.tabId === currentIDChartTab)
        if (!currentIDChartTab) return {labels, datasets}

        if(listShowIntersectionPointText.length> 0)
        {
            listShowIntersectionPointText.map((list:any) => {
                const ctx = chartRef.current as any
                const dataX = ctx.scales.x.getValueForPixel(ctx.scales.x.getPixelForValue(list.x) + list.segment);
                const dataY =  ctx.scales.y.getValueForPixel(ctx.scales.y.getPixelForValue(list.y) + list.segment + 20);
                datasets.push({
                    label: '',
                    relatedGraphId: '',
                    relatedGraphTextId: '',
                    controlId: '',
                    pointBackgroundColor: 'rgba(0, 0, 0, 0)',
                    pointBorderColor: 'rgba(0, 0, 0, 0)',
                    pointRadius: 1.5,
                    savedColor: '#000000',
                    spanGaps: false,
                    lineId: 'timeMeasureAxis',
                    lineType: LineType.MEASUREMENT_LINE,
                    data: [{x: list.x, y: list.y}, {x: list.type === 'intersectCurrent'? dataX : list.x, y: list.type === 'intersectTime'? dataY : list.y }],
                    backgroundColor: '#000000',
                    borderColor: '#000000',
                    borderWidth: 2,
                    borderDash: [],
                    dispBand: false,
                    dispRefV2: true,
                    dispLinkLine: true,
                    dispTextFree: false,
                    animation: false,
                    colorFont: '#000000',
                    faceName: 'Noto Sans JP',
                    fontSize: 12,
                    italic: false,
                    strikeOut: false,
                    underline: false,
                    weight: 0,
                    textFree: '',
                    text: '',
                })
            });
        }

         // draw measurements lines
         if (gapLine.dispGapLine) {
            // TODO: draw based on dynamic min/max scale factor
            if (gapLine.dispTime) {
                datasets.push({
                    label: '',
                    relatedGraphId: '',
                    relatedGraphTextId: '',
                    controlId: '',
                    pointBackgroundColor: 'rgba(0, 0, 0, 0)',
                    pointBorderColor: 'rgba(0, 0, 0, 0)',
                    pointRadius: 1.5,
                    savedColor: '',
                    spanGaps: false,
                    lineId: 'timeMeasureAxisY',
                    lineType: LineType.MEASUREMENT_LINE,
                    data: [{x: (currentGraphData ? currentGraphData.normalXMin : projectData.defXMin), y: gapLine.stdTime}, {x: (currentGraphData ? currentGraphData.normalXMax : projectData.defXMax), y: gapLine.stdTime}],
                    backgroundColor: '#0000ff',
                    borderColor: '#0000ff',
                    borderWidth: 2,
                    borderDash: [],
                    dispBand: false,
                    dispRefV2: true,
                    dispLinkLine: true,
                    dispTextFree: false,
                    colorFont: '#000000',
                    faceName: 'Noto Sans JP',
                    fontSize: 12,
                    italic: false,
                    strikeOut: false,
                    underline: false,
                    weight: 0,
                    animation: false,
                    textFree: '',
                    text: '',
                })
            }
            if (gapLine.dispCurrent) {
                datasets.push({
                    label: '',
                    relatedGraphId: '',
                    relatedGraphTextId: '',
                    controlId: '',
                    pointBackgroundColor: 'rgba(0, 0, 0, 0)',
                    pointBorderColor: 'rgba(0, 0, 0, 0)',
                    pointRadius: 1.5,
                    savedColor: '',
                    spanGaps: false,
                    lineId: 'timeMeasureAxisX',
                    lineType: LineType.MEASUREMENT_LINE,
                    data: [{x: gapLine.stdCurrent, y: (currentGraphData ? currentGraphData.normalYMin : projectData.defYMin)}, {x: gapLine.stdCurrent, y: (currentGraphData ? currentGraphData.normalYMax : projectData.defYMax)}],
                    backgroundColor: '#0000ff',
                    borderColor: '#0000ff',
                    borderWidth: 2,
                    borderDash: [],
                    dispBand: false,
                    dispRefV2: true,
                    dispLinkLine: true,
                    dispTextFree: false,
                    animation: false,
                    colorFont: '#000000',
                    faceName: 'Noto Sans JP',
                    fontSize: 12,
                    italic: false,
                    strikeOut: false,
                    underline: false,
                    weight: 0,
                    textFree: '',
                    text: '',
                })
            }
        } 
        let listLabels = [...listTextOfLinesInTab]
        const dataElements = currentTabCharData?.shape
        let preDatasetsOfShapes = currentTabCharData?.shape.map((shape : ControlModel) => {
            let result:any = shape.chart.filter((lineInfo : LineInfoModel) => lineInfo?.tabMapping && lineInfo?.tabMapping.has(currentIDChartTab))
            if(shape.type === MS_3WINDING)
            {
                let m_bDispInsush2 = shape.properties.partSecondary.dispInrush;
                let m_bDispInsush3 = shape.properties.partThird.dispInrush;
                    
                let m_bDispIntensity2 = shape.properties.partSecondary.dispIntensity;
                let m_bDispIntensity3 = shape.properties.partThird.dispIntensity;

                if(!m_bDispIntensity2)
                {
                    result = result.filter((lineInfo : LineInfoModel) => lineInfo.lineId.toString() !== 'INTENSITY_PART_2' && lineInfo.controlId === shape.id).concat(result.filter((e:LineInfoModel)=>e.controlId !== shape.id))
                    listLabels = listLabels.filter(e=>e.lineId.toString() !== 'INTENSITY_PART_2' && e.controlId === shape.id).concat(listLabels.filter(e=>e.controlId !== shape.id))
                }
                else
                {
                    if(listLabels.length > 0 && listLabels.find(x=> x.lineId.toString() === 'INTENSITY_PART_2' && x.controlId === shape.id) === undefined)
                    {
                        let tempItem = listTextOfLinesInTabStore.filter(x=>x.lineId.toString() === 'INTENSITY_PART_2' && x.controlId === shape.id)
                        tempItem && listLabels.push(...tempItem)
                    }
                }

                if(!m_bDispIntensity3)
                {
                    result = result.filter((lineInfo : LineInfoModel) => lineInfo.lineId.toString() !== 'INTENSITY_PART_3' && lineInfo.controlId === shape.id).concat(result.filter((e:LineInfoModel)=>e.controlId !== shape.id))
                    listLabels = listLabels.filter(e=>e.lineId.toString() !== 'INTENSITY_PART_3' && e.controlId === shape.id).concat(listLabels.filter(e=>e.controlId !== shape.id))
                }
                else
                {
                    if(listLabels.length > 0 && listLabels.find(x=> x.lineId.toString() === 'INTENSITY_PART_3' && x.controlId === shape.id) === undefined)
                    {
                        let tempItem = listTextOfLinesInTabStore.filter(x=>x.lineId.toString() === 'INTENSITY_PART_3' && x.controlId === shape.id)
                        tempItem && listLabels.push(...tempItem)
                    }
                }

                if(!m_bDispInsush2)
                {
                    result = result.filter((lineInfo : LineInfoModel) => lineInfo.lineId.toString() !== 'INRUSH_PART_2' && lineInfo.controlId === shape.id).concat(result.filter((e:LineInfoModel)=>e.controlId !== shape.id))
                    listLabels = listLabels.filter(e=>e.lineId.toString() !== 'INRUSH_PART_2' && e.controlId === shape.id).concat(listLabels.filter(e=>e.controlId !== shape.id))
                }
                else
                {
                    if(listLabels.length > 0 && listLabels.find(x=>x.lineId.toString() === 'INRUSH_PART_2' && x.controlId === shape.id) === undefined)
                    {
                        let tempItem = listTextOfLinesInTabStore.filter(x=>x.lineId.toString() === 'INRUSH_PART_2' && x.controlId === shape.id)
                        tempItem && listLabels.push(...tempItem)
                    }
                }

                if(!m_bDispInsush3)
                {
                    result = result.filter((lineInfo : LineInfoModel) => lineInfo.lineId.toString() !== 'INRUSH_PART_3' && lineInfo.controlId === shape.id).concat(result.filter((e:LineInfoModel)=>e.controlId !== shape.id))
                    listLabels = listLabels.filter(e=>e.lineId.toString() !== 'INRUSH_PART_3' && e.controlId === shape.id).concat(listLabels.filter(e=>e.controlId !== shape.id))
                }
                else
                {
                    if(listLabels.length > 0 && listLabels.find(x=>x.lineId.toString() === 'INRUSH_PART_3' && x.controlId === shape.id) === undefined)
                    {
                        let tempItem = listTextOfLinesInTabStore.filter(x=>x.lineId.toString() === 'INRUSH_PART_3' && x.controlId === shape.id )
                        tempItem && listLabels.push(...tempItem)
                    }
                }

                return result
            }
            else if(shape.type === MS_TRANS1)
            {
                    let m_bDispInrush = shape.properties.partOutIn.dispInrush;// 保護協調 - [外線‐中性線]
                    let m_bDispInrush2 = shape.properties.partOutOut.dispInrush;//保護協調 - [外線‐外線]

                    let m_bDispIntensity = shape.properties.partOutIn.dispIntensity; // 保護協調 - [外線‐中性線]
                    let m_bDispIntensity2 = shape.properties.partOutOut.dispIntensity;//保護協調 - [外線‐外線]
                    if(!m_bDispInrush)
                    {
                        result = result.filter((lineInfo : LineInfoModel) => lineInfo.lineId.toString() !== 'INRUSH_OUT_IN' && lineInfo.controlId === shape.id).concat(result.filter((e:LineInfoModel)=>e.controlId !== shape.id))
                        listLabels = listLabels.filter(e=>e.lineId.toString() !== 'INRUSH_OUT_IN' && e.controlId === shape.id).concat(listLabels.filter(e=>e.controlId !== shape.id))
                    }
                    else
                    {
                        if(listLabels.length > 0 && listLabels.find(x=> x.lineId.toString() === 'INRUSH_OUT_IN' && x.controlId === shape.id) === undefined)
                        {
                            let tempItem = listTextOfLinesInTabStore.filter(x=>x.lineId.toString() === 'INRUSH_OUT_IN' && x.controlId === shape.id)
                            tempItem && listLabels.push(...tempItem)
                        }
                    }

                    if(!m_bDispInrush2)
                    {
                        result = result.filter((lineInfo : LineInfoModel) => lineInfo.lineId.toString() !== 'INRUSH_OUT_OUT' && lineInfo.controlId === shape.id).concat(result.filter((e:LineInfoModel)=>e.controlId !== shape.id))
                        listLabels = listLabels.filter(e=>e.lineId.toString() !== 'INRUSH_OUT_OUT' && e.controlId === shape.id).concat(listLabels.filter(e=>e.controlId !== shape.id))
                    }
                    else
                    {
                        if(listLabels.length > 0 && listLabels.find(x=> x.lineId.toString() === 'INRUSH_OUT_OUT' && x.controlId === shape.id) === undefined)
                        {
                            let tempItem = listTextOfLinesInTabStore.filter(x=>x.lineId.toString() === 'INRUSH_OUT_OUT' && x.controlId === shape.id)
                            tempItem && listLabels.push(...tempItem)
                        }
                    }

                    if(!m_bDispIntensity)
                    {
                        result = result.filter((lineInfo : LineInfoModel) => lineInfo.lineId.toString() !== 'INTENSITY_OUT_IN' && lineInfo.controlId === shape.id).concat(result.filter((e:LineInfoModel)=>e.controlId !== shape.id))
                        listLabels = listLabels.filter(e=>e.lineId.toString() !== 'INTENSITY_OUT_IN' && e.controlId === shape.id).concat(listLabels.filter(e=>e.controlId !== shape.id))
                    }
                    else
                    {
                        if(listLabels.length > 0 && listLabels.find(x=> x.lineId.toString() === 'INTENSITY_OUT_IN' && x.controlId === shape.id) === undefined)
                        {
                            let tempItem = listTextOfLinesInTabStore.filter(x=>x.lineId.toString() === 'INTENSITY_OUT_IN' && x.controlId === shape.id)
                            tempItem && listLabels.push(...tempItem)
                        }
                    }

                    if(!m_bDispIntensity2)
                    {
                        result = result.filter((lineInfo : LineInfoModel) => lineInfo.lineId.toString() !== 'INTENSITY_OUT_OUT' && lineInfo.controlId === shape.id).concat(result.filter((e:LineInfoModel)=>e.controlId !== shape.id))
                        listLabels = listLabels.filter(e=>e.lineId.toString() !== 'INTENSITY_OUT_OUT' && e.controlId === shape.id).concat(listLabels.filter(e=>e.controlId !== shape.id))
                    }
                    else
                    {
                        if(listLabels.length > 0 && listLabels.find(x=> x.lineId.toString() === 'INTENSITY_OUT_OUT' && x.controlId === shape.id) === undefined)
                        {
                            let tempItem = listTextOfLinesInTabStore.filter(x=>x.lineId.toString() === 'INTENSITY_OUT_OUT' && x.controlId === shape.id)
                            tempItem && listLabels.push(...tempItem)
                        }
                    }

                    return result;
            }
            else if(shape.type === MS_TRANS3)
            {
                let m_bDispInrush = shape.properties.dispInrush;
                let m_bDispIntensity = shape.properties.dispIntensity;
                if(!m_bDispInrush)
                {
                    result = result.filter((lineInfo : LineInfoModel) => lineInfo.lineId.toString() !== 'INRUSH' && lineInfo.controlId === shape.id).concat(result.filter((e:LineInfoModel)=>e.controlId !== shape.id))
                    listLabels = listLabels.filter(e=>e.lineId.toString() !== 'INRUSH' && e.controlId === shape.id).concat(listLabels.filter(e=>e.controlId !== shape.id))
                }
                else
                {
                    if(listLabels.length > 0 && listLabels.find(x=> x.lineId.toString() === 'INRUSH' && x.controlId === shape.id) === undefined)
                    {
                        let tempItem = listTextOfLinesInTabStore.filter(x=>x.lineId.toString() === 'INRUSH' && x.controlId === shape.id)
                        tempItem && listLabels.push(...tempItem)
                    }
                }

                if(!m_bDispIntensity)
                {
                    result = result.filter((lineInfo : LineInfoModel) => lineInfo.lineId.toString() !== 'INTENSITY' && lineInfo.controlId === shape.id).concat(result.filter((e:LineInfoModel)=>e.controlId !== shape.id))
                    listLabels = listLabels.filter(e=>e.lineId.toString() !== 'INTENSITY' && e.controlId === shape.id).concat(listLabels.filter(e=>e.controlId !== shape.id))
                }
                else
                {
                    if(listLabels.length > 0 && listLabels.find(x=> x.lineId.toString() === 'INTENSITY' && x.controlId === shape.id) === undefined)
                    {
                        let tempItem = listTextOfLinesInTabStore.filter(x=>x.lineId.toString() === 'INTENSITY' && x.controlId === shape.id)
                        tempItem && listLabels.push(...tempItem)
                    }
                }

                return result
            }
            else if(shape.type === MS_TRANSSCOTT)
            {
                let m_bDispInrush = shape.properties.partSeatM.dispInrush;
                let m_bDispIntensity = shape.properties.partSeatM.dispIntensity;
                let m_bDispInrush2 = shape.properties.partSeatT.dispInrush;
                let m_bDispIntensity2 = shape.properties.partSeatT.dispIntensity;

                if(!m_bDispInrush)
                {
                    result = result.filter((lineInfo : LineInfoModel) => lineInfo.lineId.toString() !== 'INRUSH_1' && lineInfo.controlId === shape.id).concat(result.filter((e:LineInfoModel)=>e.controlId !== shape.id))
                    listLabels = listLabels.filter(e=>e.lineId.toString() !== 'INRUSH_1' && e.controlId === shape.id).concat(listLabels.filter(e=>e.controlId !== shape.id))
                }
                else
                {
                    if(listLabels.length > 0 && listLabels.find(x=> x.lineId.toString() === 'INRUSH_1' && x.controlId === shape.id) === undefined)
                    {
                        let tempItem = listTextOfLinesInTabStore.filter(x=>x.lineId.toString() === 'INRUSH_1' && x.controlId === shape.id)
                        tempItem && listLabels.push(...tempItem)
                    }
                }
                
                if(!m_bDispInrush2)
                {
                    result = result.filter((lineInfo : LineInfoModel) => lineInfo.lineId.toString() !== 'INRUSH_2' && lineInfo.controlId === shape.id).concat(result.filter((e:LineInfoModel)=>e.controlId !== shape.id))
                    listLabels = listLabels.filter(e=>e.lineId.toString() !== 'INRUSH_2' && e.controlId === shape.id).concat(listLabels.filter(e=>e.controlId !== shape.id))
                }
                else
                {
                    if(listLabels.length > 0 && listLabels.find(x=> x.lineId.toString() === 'INRUSH_2' && x.controlId === shape.id) === undefined)
                    {
                        let tempItem = listTextOfLinesInTabStore.filter(x=>x.lineId.toString() === 'INRUSH_2' && x.controlId === shape.id)
                        tempItem && listLabels.push(...tempItem)
                    }
                }
            
                if(!m_bDispIntensity)
                {
                    result = result.filter((lineInfo : LineInfoModel) => lineInfo.lineId.toString() !== 'INTENSITY_1' && lineInfo.controlId === shape.id).concat(result.filter((e:LineInfoModel)=>e.controlId !== shape.id))
                    listLabels = listLabels.filter(e=>e.lineId.toString() !== 'INTENSITY_1' && e.controlId === shape.id).concat(listLabels.filter(e=>e.controlId !== shape.id))
                }
                else
                {
                    if(listLabels.length > 0 && listLabels.find(x=> x.lineId.toString() === 'INTENSITY_1' && x.controlId === shape.id) === undefined)
                    {
                        let tempItem = listTextOfLinesInTabStore.filter(x=>x.lineId.toString() === 'INTENSITY_1' && x.controlId === shape.id)
                        tempItem && listLabels.push(...tempItem)
                    }
                }
        
                if(!m_bDispIntensity2)
                {
                    result = result.filter((lineInfo : LineInfoModel) => lineInfo.lineId.toString() !== 'INTENSITY_2' && lineInfo.controlId === shape.id).concat(result.filter((e:LineInfoModel)=>e.controlId !== shape.id))
                    listLabels = listLabels.filter(e=>e.lineId.toString() !== 'INTENSITY_2' && e.controlId === shape.id).concat(listLabels.filter(e=>e.controlId !== shape.id))
                }
                else
                {
                    if(listLabels.length > 0 && listLabels.find(x=> x.lineId.toString() === 'INTENSITY_2' && x.controlId === shape.id) === undefined)
                    {
                        let tempItem = listTextOfLinesInTabStore.filter(x=>x.lineId.toString() === 'INTENSITY_2' && x.controlId === shape.id)
                        tempItem && listLabels.push(...tempItem)
                    }
                }

                return result;
            }
            else
            {
                return shape.chart.filter((lineInfo : LineInfoModel) => lineInfo?.tabMapping && lineInfo?.tabMapping.has(currentIDChartTab))
            }
        }) || [] 
    
        if (processMode < MS_MODE_CALCULATION ) {
            preDatasetsOfShapes = preDatasetsOfShapes.map(x => x.filter((e:any) => e.lineId.toString() !== ("CALC_POINT_0") && e.lineId.toString() !== ("CALC_POINT_1") && e.lineId.toString() !== ("CALC_POINT_0_2") && e.lineId.toString() !== ("CALC_POINT_0_3") && e.lineId.toString() !== ("CALC_POINT_1_2") && e.lineId.toString() !== ("CALC_POINT_1_3")))
            if(listLabels.length > 0) 
                listLabels = listLabels.filter(e =>e?.lineId?.toString() !== ("CALC_POINT_0") && e?.lineId?.toString() !== ("CALC_POINT_1") && e.lineId.toString() !== ("CALC_POINT_0_2") && e.lineId.toString() !== ("CALC_POINT_0_3") && e.lineId.toString() !== ("CALC_POINT_1_2") && e.lineId.toString() !== ("CALC_POINT_1_3"))
        }
        else
        {
            preDatasetsOfShapes.map(x => x.filter((e:any) => e.lineId.toString() === ("CALC_POINT_0") || e.lineId.toString() === ("CALC_POINT_1") || e.lineId.toString() === ("CALC_POINT_0_2") || e.lineId.toString() === ("CALC_POINT_0_3") || e.lineId.toString() === ("CALC_POINT_1_2") || e.lineId.toString() === ("CALC_POINT_1_3"))).map((x:any)=>{
                 x.map((item :any)=>{
                    if(!isNaN(Number(item.relatedGraphId)) && listLabels.length > 0 && listTextOfLinesInTabStore.length > 0)
                    {
                        if(listLabels.find(list=> list.id && Number(list.id) === Number(item.relatedGraphId) && Number(item.controlId) === Number(list.controlId) && list.graphNo === currentIDChartTab) === undefined)
                        {
                            let tempItem = listTextOfLinesInTabStore.find(list=> list.graphNo === currentIDChartTab && list.id && Number(list.id) === Number(item.relatedGraphId) && Number(item.controlId) === Number(list.controlId))
                            tempItem && listLabels.push(tempItem)
                        }
                    }
                })
            })
        }
        setListTextOfLinesInTab(listLabels)

        preDatasetsOfShapes.forEach((preDatasetsPerShape : LineInfoModel[]) => {
            datasets.push(...convertToDataset(preDatasetsPerShape, currentIDChartTab, nominalVolt || 1)) 
        })

        //draw connect lines
        if(listTextOfLinesInTab.length> 0)
        {
            listTextOfLinesInTab.forEach((list:any)=>{
                if(list.graphNo === currentIDChartTab && !list.isHideConnectLine){
                    const chartData = chartDataList.find((x:any)=>x.tabId == currentIDChartTab)?.shape
                    let tempData = datasets.filter(x=>x.lineType !== LineType.CONNECT_LINE && x.lineType !== LineType.MEASUREMENT_LINE && Number(x.controlId) === Number(list.controlId) && x.lineType === list.lineType && ((x.lineType == LineType.CONTROL_CURVE && chartData && (chartData.find(item=>Number(item.id) === Number(x.controlId))!.type == MS_FUSE || chartData.find(item=>Number(item.id) === Number(x.controlId))!.type == MS_LVCB || chartData.find(item=>Number(item.id) === Number(x.controlId))!.type == MS_THERMAL || chartData.find(item=>Number(item.id) === Number(x.controlId))!.type == MS_THERMAL_CT)) || x.lineId === list.lineId) && !x.lineId.toString().includes("INRUSH") && !x.lineId.toString().includes("INTENSITY") && !x.lineId.toString().includes("CALC_POINT"))
                    //draw connect lines the label had existed
                    if(tempData !== undefined && tempData.length > 1)
                    {
                        if(!list.isShowTextFree)
                        {
                            let charts = chartDataList.find(x =>x.tabId === currentIDChartTab)?.shape.find(shapes=>shapes.chart.find(charts=> Number(charts.relatedGraphId) === Number(list.relatedGraphId)))
                            if(charts !== undefined) {
                                let infos: string[] = []
                                let line = charts.chart.find(lines=> Number(lines.relatedGraphId) === Number(list.relatedGraphId))
                                if (line?.lineType === LineType.CONTROL_CURVE) {
                                    infos = GraphLabel.getControlGraphLabel(charts, line.lineId)
                                }
                                else {
                                    // find to draw curve in list user curve
                                    const foundUserCurve = charts.properties.listUser.find((userCurve : any) => userCurve.userCurveId === line?.data.find((x:any)=>x.userCurveId)?.userCurveId || userCurve.userCurveId === line?.data.find((x:any)=>x.user_curve_id)?.user_curve_id)
                                    if (foundUserCurve) {
                                        infos = [
                                            foundUserCurve.refNo,
                                            foundUserCurve.name,
                                            ...foundUserCurve.notes.split('\n'),
                                        ]
                                    }
                                }
                                list.text = infos
                            }
                        }
                        tempData.forEach(data=>{
                            if(data.data.length > 0)
                            {
                                const ctx = chartRef.current as any
                                if(data.lineId.toString() === "POINT_CENTER" && (data.data[0].x !== list.savedX || data.data[0].y !== list.savedY))
                                {
                                    let newXPoint = ctx?.scales.x.getPixelForValue(Number(data.data[0].x)) - ctx?.scales.x.getPixelForValue(Number(list.savedX))
                                    let newYPoint = ctx?.scales.y.getPixelForValue(Number(data.data[0].y)) - ctx?.scales.y.getPixelForValue(Number(list.savedY))
                                    if(list.pointConnectLineX && list.pointConnectLineY){
                                        list.pointConnectLineX = ctx?.scales.x.getValueForPixel(ctx?.scales.x.getPixelForValue(Number(list.pointConnectLineX)) + newXPoint)
                                        list.pointConnectLineY = ctx?.scales.y.getValueForPixel(ctx?.scales.y.getPixelForValue(Number(list.pointConnectLineY)) + newYPoint)
                                    }
                                    list.x = ctx?.scales.x.getValueForPixel(ctx?.scales.x.getPixelForValue(Number(list.x)) + newXPoint)
                                    list.y = ctx?.scales.y.getValueForPixel(ctx?.scales.y.getPixelForValue(Number(list.y)) + newYPoint)
                                    list.savedX = data.data[0].x;
                                    list.savedY = data.data[0].y;
                                }
                                else if(data.lineId.toString() === "POINT_MAX" && tempData.find(check => check.lineId.toString() === "POINT_CENTER") === undefined && (data.data[0].x !== list.savedX || data.data[0].y !== list.savedY))
                                {
                                    let newXPoint = ctx?.scales.x.getPixelForValue(Number(data.data[0].x)) - ctx?.scales.x.getPixelForValue(Number(list.savedX))
                                    let newYPoint = ctx?.scales.y.getPixelForValue(Number(data.data[0].y)) - ctx?.scales.y.getPixelForValue(Number(list.savedY))
                                    if(list.pointConnectLineX && list.pointConnectLineY){
                                        list.pointConnectLineX = ctx?.scales.x.getValueForPixel(ctx?.scales.x.getPixelForValue(Number(list.pointConnectLineX)) + newXPoint)
                                        list.pointConnectLineY = ctx?.scales.y.getValueForPixel(ctx?.scales.y.getPixelForValue(Number(list.pointConnectLineY)) + newYPoint)
                                    }
                                    list.x = ctx?.scales.x.getValueForPixel(ctx?.scales.x.getPixelForValue(Number(list.x)) + newXPoint)
                                    list.y = ctx?.scales.y.getValueForPixel(ctx?.scales.y.getPixelForValue(Number(list.y)) + newYPoint)
                                    list.savedX = data.data[0].x;
                                    list.savedY = data.data[0].y;
                                }
                                const minPoint = handleCalcPointMin(list,ctx?.scales.x.getPixelForValue(list.x),ctx?.scales.y.getPixelForValue(list.y))
                                const minPointX = ctx?.scales.x.getValueForPixel(minPoint.x)
                                const minPointY = ctx?.scales.y.getValueForPixel(minPoint.y)
                                if(list.pointConnectLineX && list.pointConnectLineY && (list.pointConnectLineY !== minPointY || list.pointConnectLineX !== minPointX))
                                {
                                    list.pointConnectLineX = minPointX
                                    list.pointConnectLineY = minPointY
                                }
                                datasets.push({
                                    label: '',
                                    relatedGraphId: list.relatedGraphId.toString(),
                                    relatedGraphTextId: list.id.toString(),
                                    controlId: list.controlId.toString(),
                                    pointBackgroundColor: 'rgba(0, 0, 0, 0)',
                                    pointBorderColor: 'rgba(0, 0, 0, 0)',
                                    pointRadius: 1.5,
                                    savedColor: '#000000',
                                    spanGaps: false,
                                    lineId: 'connectLines',
                                    lineType: LineType.CONNECT_LINE,
                                    data: [{x: data.data[0].x, y: data.data[0].y}, {x: list.pointConnectLineX? list.pointConnectLineX : list.x, y: list.pointConnectLineY? list.pointConnectLineY : list.y}],
                                    backgroundColor: '#000000',
                                    borderColor: '#000000',
                                    borderWidth: 0.5,
                                    borderDash: [],
                                    dispBand: false,
                                    dispRefV2: true,
                                    dispLinkLine: true,
                                    dispTextFree: false,
                                    animation: false,
                                    colorFont: '#000000',
                                    faceName: 'Noto Sans JP',
                                    fontSize: 12,
                                    italic: false,
                                    strikeOut: false,
                                    underline: false,
                                    weight: 0,
                                    textFree: '',
                                    text: '',
                                })
                            }
                        })
                    }
                    else //draw new connect lines
                    {
                        let movePointData = datasets.find(x=>Number(x.relatedGraphTextId) === Number(list.id) && Number(x.controlId) === Number(x.controlId) && x.lineId.toString() === list.lineId.toString())
                        const ctx = chartRef.current as any
                        if(movePointData !== undefined && movePointData.data.length > 0 && (movePointData.data[0].x !== list.savedX || movePointData.data[0].y !== list.savedY))
                        {
                            let newXPoint = ctx?.scales.x.getPixelForValue(Number(movePointData.data[0].x)) - ctx?.scales.x.getPixelForValue(Number(list.savedX))
                            let newYPoint = ctx?.scales.y.getPixelForValue(Number(movePointData.data[0].y)) - ctx?.scales.y.getPixelForValue(Number(list.savedY))
                            if(list.pointConnectLineX && list.pointConnectLineY){
                                list.pointConnectLineX = ctx?.scales.x.getValueForPixel(ctx?.scales.x.getPixelForValue(Number(list.pointConnectLineX)) + newXPoint)
                                list.pointConnectLineY = ctx?.scales.y.getValueForPixel(ctx?.scales.y.getPixelForValue(Number(list.pointConnectLineY)) + newYPoint)
                            }
                            list.x = ctx?.scales.x.getValueForPixel(ctx?.scales.x.getPixelForValue(Number(list.x)) + newXPoint)
                            list.y = ctx?.scales.y.getValueForPixel(ctx?.scales.y.getPixelForValue(Number(list.y)) + newYPoint)
                            list.savedX = movePointData.data[0].x;
                            list.savedY = movePointData.data[0].y;
                            if(!list.isShowTextFree)
                            {
                                let charts = chartDataList.find(x =>x.tabId === currentIDChartTab)?.shape.find(shapes=>shapes.chart.find(charts=> Number(charts.relatedGraphId) === Number(list.relatedGraphId)))
                                if(charts !== undefined) {
                                    let infos: string[] = []
                                    let line = charts.chart.find(lines=> Number(lines.relatedGraphId) === Number(list.relatedGraphId))
                                    if (line?.lineType === LineType.CONTROL_CURVE) {
                                        infos = GraphLabel.getControlGraphLabel(charts, line.lineId)
                                    }
                                    else {
                                        // find to draw curve in list user curve
                                        const foundUserCurve = charts.properties.listUser.find((userCurve : any) => userCurve.userCurveId === line?.data[0].userCurveId || userCurve.userCurveId === line?.data[0].user_curve_id)
                                        if (foundUserCurve) {
                                            infos = [
                                                foundUserCurve.refNo,
                                                foundUserCurve.name,
                                                ...foundUserCurve.notes.split('\n'),
                                            ]
                                        }
                                    }
                                    list.text = infos
                                }
                            }
                        }
                        const minPoint = handleCalcPointMin(list,ctx?.scales.x.getPixelForValue(list.x),ctx?.scales.y.getPixelForValue(list.y))
                        const minPointX = ctx?.scales.x.getValueForPixel(minPoint.x)
                        const minPointY = ctx?.scales.y.getValueForPixel(minPoint.y)
                        if(list.pointConnectLineX && list.pointConnectLineY && (list.pointConnectLineY !== minPointY || list.pointConnectLineX !== minPointX))
                        {
                            list.pointConnectLineX = minPointX
                            list.pointConnectLineY = minPointY
                        }
                        
                        datasets.push({
                            label: '',
                            relatedGraphId: list.relatedGraphId.toString(),
                            relatedGraphTextId: list.id.toString(),
                            controlId: list.controlId.toString(),
                            pointBackgroundColor: 'rgba(0, 0, 0, 0)',
                            pointBorderColor: 'rgba(0, 0, 0, 0)',
                            pointRadius: 1.5,
                            savedColor: '#000000',
                            spanGaps: false,
                            lineId: 'connectLines',
                            lineType: LineType.CONNECT_LINE,
                            data: [{x: list.savedX, y: list.savedY}, {x: list.pointConnectLineX? list.pointConnectLineX : list.x, y: list.pointConnectLineY? list.pointConnectLineY : list.y}],
                            backgroundColor: '#000000',
                            borderColor: '#000000',
                            borderWidth: 0.5,
                            borderDash: [],
                            dispBand: false,
                            dispRefV2: true,
                            dispLinkLine: true,
                            dispTextFree: false,
                            animation: false,
                            colorFont: '#000000',
                            faceName: 'Noto Sans JP',
                            fontSize: 12,
                            italic: false,
                            strikeOut: false,
                            underline: false,
                            weight: 0,
                            textFree: '',
                            text: '',
                        })
                    }
                }
            })
            listTextOfLinesInTabStore.forEach(x=>{
                listTextOfLinesInTab.map(e=>{
                    if(Number(x.id) === Number(e.id) && Number(x.relatedGraphId) === Number(e.relatedGraphId) && x.graphNo === e.graphNo){
                        x = e;
                    }
                })
            })
        }

        //set red line
        datasets.forEach(dataset => {
            listTextOfLinesInTab.length> 0 && listTextOfLinesInTab.forEach(x=>{
                if((x.graphNo === currentIDChartTab && x.isSelected && x.lineType === dataset.lineType && Number(x.controlId) === Number(dataset.controlId) && dataset.lineType === LineType.CONTROL_CURVE) || (x.graphNo === currentIDChartTab && x.isSelected && x.lineType === dataset.lineType && Number(x.id) === Number(dataset.relatedGraphTextId) && dataset.lineType === LineType.USER_CURVE))
                {
                    if(dataset.lineId.toString().includes("INRUSH") || dataset.lineId.toString().includes("INTENSITY") || dataset.lineId.toString().includes("CALC_POINT") || (dataset.lineType == LineType.USER_CURVE && dataset.data.filter((item:any)=>item.x != 0 && item.y !=0).length == 1)){
                        if(dataset.lineId.toString() === x.lineId.toString()){
                            dataset.backgroundColor = '#f00'
                            dataset.borderColor = '#f00'
                            if(dataset.lineId.toString().includes("INTENSITY") || (dataset.lineType == LineType.USER_CURVE && dataset.data.filter((item:any)=>item.x != 0 && item.y !=0).length == 1))
                            {
                                dataset.pointBorderColor = '#f00'
                            }
                        }
                    }
                    else if(!dataset.lineId.toString().includes("CALC_POINT") && !x.lineId.toString().includes("CALC_POINT"))
                    {
                        dataset.backgroundColor = '#f00'
                        dataset.borderColor = '#f00'
                    }
                }
                else if(dataset.lineType === LineType.CONNECT_LINE && x.isSelected && Number(x.id) === Number(dataset.relatedGraphTextId) && x.graphNo === currentIDChartTab)
                {
                    dataset.backgroundColor = '#f00'
                    dataset.borderColor = '#f00'
                    
                }
            })
            // if(dataset.lineId.toString().includes('INTENSITY') &&  (dataset.pointBorderColor !== '#f00'))
            // {
            //     dataset.pointBorderColor = dataset.savedColor
            // }
        })

        return {
            dataChart : {
                labels,
                datasets
            },
            dataElements,
        }
    }, [chartDataList, currentIDChartTab, nominalVolt, gapLine, reDrawLine, drawConnectLines])
    //#endregion

    //#region Method
    const handleShowTextMeasureLine = (data:any) =>{
        let arr:any[]=[]
        let arrResult:any[]=[]
        if(intersectionData && intersectionData.intersectCurrentData  && intersectionData.intersectCurrentData.length > 0 )
        {
            intersectionData.intersectCurrentData.forEach((x:any)=>{
                data.map((dataItem:any) =>{
                    if(Number(x.controlId) === Number(dataItem.controlId) && x.lineId == dataItem.lineId)
                        {
                            let param = {
                                id:dataItem.relatedGraphId,
                                y:x.value,
                                x:gapLine.stdCurrent,
                                type:'intersectCurrent',
                                controlId: dataItem.controlId,
                            }
                            arr.push(param)
                        }
                })
            })
        }
        if(intersectionData && intersectionData.intersectTimeData && intersectionData.intersectTimeData.length > 0)
        {
            intersectionData.intersectTimeData.forEach((x:any)=>{
                data.map((dataItem:any)=>{
                    if(Number(x.controlId) === Number(dataItem.controlId) && x.lineId == dataItem.lineId)
                        {
                            let param = {
                                id:dataItem.relatedGraphId,
                                x:x.value,
                                y:gapLine.stdTime,
                                type:'intersectTime',
                                controlId: dataItem.controlId,
                            }
                            arr.push(param)
                        }
                })
            })
        }
        let segmentX = 20
        let segmentY = -40 
        data && arr.length > 0 && arr.forEach((x:any)=>{
            let param= {
                id: x.id,
                graphNo: currentIDChartTab,
                x: x.x,
                y: x.y,
                text: x.type === 'intersectCurrent' ? Number(BeamsNumber(Number(x.y), 4).toFixed(6)) + 's' : Number(BeamsNumber(Number(x.x), 4).toFixed(6)) + 'A',
                controlId: x.controlId,
                type: x.type,
                segment: x.type === 'intersectCurrent' ? segmentX : segmentY,
            }
            x.type === 'intersectCurrent' ? (segmentX+= 50) : (segmentY+= -22)
            arrResult.push(param)
        })
        setListShowIntersectionPointText(arrResult)
        setDrawConnectLines(!drawConnectLines)
    }

    const handleClickOnChart = (event: any, element?:any) => {   
        //if(event.shiftKey) return;
        if(!event.shiftKey && !isSelectTouchGroup) unSelectAllControls()
        let selectedLines:any[] = (event.shiftKey || isSelectTouchGroup) ? selectedRelatedGraph : [];
        const ctx = chartRef.current as any
        const cursorPos = chartHelpers.getRelativePosition(event, ctx);
        let clickedDataset = isHittedCurve({x: cursorPos.x, y: cursorPos.y});

        if(!event.shiftKey || !isSelectTouchGroup)
        {
            setNotSelectListTextOfLinesInTab()
            setListShowIntersectionPointText([])
        }
        if(element && (clickedDataset === null || clickedDataset === undefined)) {
            clickedDataset = chartRef.current?.data.datasets.find((x:any)=> Number(x?.controlId) === Number(element?.controlId) && Number(x?.relatedGraphTextId) === Number(element?.id) && x.lineType != LineType.CONNECT_LINE)
            clickedDataset && listTextOfLinesInTab.length > 0 && listTextOfLinesInTab.forEach(x=>{
                if((Number(x.controlId) === Number(clickedDataset.controlId) && clickedDataset.lineType === LineType.CONTROL_CURVE && x.lineType === LineType.CONTROL_CURVE) || (clickedDataset.lineType === LineType.USER_CURVE && Number(x.id) === Number(clickedDataset.relatedGraphTextId))){
                    if(!selectedLines.find(item=> Number(x.id) == Number(item.id)))
                    {
                        if((x.lineId.toString().includes("INRUSH") || x.lineId.toString().includes("INTENSITY") || x.lineId.toString().includes("CALC_POINT")) && x.graphNo === currentIDChartTab)
                        {
                            if(x.lineId.toString() === clickedDataset.lineId.toString() && x.lineType === clickedDataset.lineType && Number(x.controlId) === Number(clickedDataset.controlId))
                            {
                                x.isSelected = true;
                                selectedLines.push(x)
                            }
                        }
                        else if(!clickedDataset.lineId.toString().includes("CALC_POINT") && x.graphNo === currentIDChartTab && clickedDataset.lineType == x.lineType)
                        {
                            x.isSelected = true
                            selectedLines.push(x)
                        }
                    }
                    else if((event.shiftKey || isSelectTouchGroup) && Number(clickedDataset.relatedGraphTextId) == Number(x.id))
                    {
                        x.isSelected = false;
                        selectedLines.splice(selectedLines.indexOf(x),1)
                    }
                } 
            })
        }
        else if(clickedDataset)
        {
            listTextOfLinesInTab.length > 0 && listTextOfLinesInTab.forEach(x=>{
                if((Number(x.controlId) === Number(clickedDataset.controlId) && clickedDataset.lineType === LineType.CONTROL_CURVE && x.lineType === LineType.CONTROL_CURVE) || (clickedDataset.lineType === LineType.USER_CURVE && Number(x.id) === Number(clickedDataset.relatedGraphTextId))){
                    if(!selectedLines.find(item=> Number(x.id) == Number(item.id)))
                    {
                        if((x.lineId.toString().includes("INRUSH") || x.lineId.toString().includes("INTENSITY") || x.lineId.toString().includes("CALC_POINT")) && x.graphNo === currentIDChartTab)
                        {
                            if(x.lineId.toString() === clickedDataset.lineId.toString())
                            {
                                x.isSelected = true
                                selectedLines.push(x)
                            }
                        }
                        else if(!clickedDataset.lineId.toString().includes("CALC_POINT") && x.graphNo === currentIDChartTab && clickedDataset.lineType == x.lineType)
                        {
                            x.isSelected = true
                            selectedLines.push(x)
                        }
                    }
                    else if((event.shiftKey || isSelectTouchGroup) && Number(clickedDataset.relatedGraphTextId) == Number(x.id))
                    {
                        x.isSelected = false;
                        selectedLines.splice(selectedLines.indexOf(x),1)
                    }
                } 
            })
        }
        setSelectedRelatedGraph(selectedLines)
        // ================= Onclick on line =================
        if (clickedDataset) {       
            const controlId = clickedDataset.controlId
            if(((!(event.shiftKey || isSelectTouchGroup)) || (selectedLines.filter((item:any)=>Number(item.controlId) == Number(clickedDataset.controlId)).length > 0 && !diagramDataList.find(x=>x.tabId == currentIDDiagramTab)?.shape.find(x=>Number(x.id) == Number(clickedDataset.controlId))?.isSelected) || (!(selectedLines.filter((item:any)=>Number(item.controlId) == Number(clickedDataset.controlId)).length > 0) && diagramDataList.find(x=>x.tabId == currentIDDiagramTab)?.shape.find(x=>Number(x.id) == Number(clickedDataset.controlId))?.isSelected)))
            {
                updateSelectControl(controlId, event.shiftKey || isSelectTouchGroup ? true : false )
            }
            publishChartEvents({ event: 'SELECT_CONTROLS', payload: {id:(event.shiftKey || isSelectTouchGroup) && chartEvents.event == "SELECT_CONTROLS"? [...chartEvents.payload.id.filter((x:any)=>x != controlId),controlId] : [controlId],state:"select"}})
            
            let data = [] as DatasetModel[]
            let dataIntersection = [] as DatasetModel[]
            //set line to red
            if (chartRef.current) {
                chartRef.current.data.datasets.forEach((dataset : any, index : number) => {
                    if (dataset.lineType === LineType.MEASUREMENT_LINE) return
                    if (dataset.lineType === LineType.CONNECT_LINE)
                    {
                        dataset.backgroundColor = dataset.savedColor
                        dataset.borderColor = dataset.savedColor
                        listTextOfLinesInTab.length > 0 && listTextOfLinesInTab.forEach(x=>{
                            if(Number(x.controlId) === Number(dataset.controlId) && Number(x.id) === Number(dataset.relatedGraphTextId) && x.isSelected && x.graphNo === currentIDChartTab){
                                dataset.backgroundColor = '#f00'
                                dataset.borderColor = '#f00'
                            }
                        })
                        return
                    }
                    if ( dataset.lineId.toString().includes("INRUSH") || dataset.lineId.toString().includes("INTENSITY") || dataset.lineId.toString().includes("CALC_POINT")) {
                        if(dataset.lineId === clickedDataset.lineId && dataset.lineType === clickedDataset.lineType && clickedDataset.controlId === dataset.controlId)
                        {
                            dataset.backgroundColor = '#f00'
                            dataset.borderColor = '#f00'
                            !dataset.lineId.toString().includes("INTENSITY") && !dataset.lineId.toString().includes("CALC_POINT") && gapLine.dispGapLine && dataset.lineType == LineType.CONTROL_CURVE && dataIntersection.push(dataset)
                            if(dataset.lineId.toString().includes("INTENSITY"))
                            {
                                dataset.pointBorderColor = '#f00'
                                setDrawConnectLines(!drawConnectLines)
                            }
                            data.push(dataset)
                        }
                        else {
                            dataset.backgroundColor = dataset.savedColor
                            dataset.borderColor = dataset.savedColor
                            if(dataset.lineId.toString().includes("INTENSITY"))
                            {
                                dataset.pointBorderColor = dataset.savedColor
                                setDrawConnectLines(!drawConnectLines)
                            }
                        } 
                    }
                    else if(dataset.controlId === controlId && dataset.lineType === clickedDataset.lineType && clickedDataset.lineType !== LineType.USER_CURVE && !clickedDataset.lineId.toString().includes("CALC_POINT"))
                    {
                        dataset.backgroundColor = '#f00'
                        dataset.borderColor = '#f00'
                        data.push(dataset)
                        gapLine.dispGapLine && dataIntersection.push(dataset)
                    }
                    else if(clickedDataset.lineType === LineType.USER_CURVE && dataset.lineId === clickedDataset.lineId)
                    {
                        if(dataset.lineType == LineType.USER_CURVE && dataset.data.filter((item:any)=>item.x != 0 && item.y !=0).length == 1)
                        {
                            dataset.pointBorderColor = '#f00'
                            dataset.backgroundColor = '#f00'
                            dataset.borderColor = '#f00'
                            data.push(dataset)
                            setDrawConnectLines(!drawConnectLines)
                        }
                        else
                        {
                            dataset.backgroundColor = '#f00'
                            dataset.borderColor = '#f00'
                            data.push(dataset)
                        }
                    }
                    else {
                        dataset.backgroundColor = dataset.savedColor
                        dataset.borderColor = dataset.savedColor
                        dataset.pointBorderColor =  dataset.savedColor
                        setDrawConnectLines(!drawConnectLines)
                    }
                })
                chartRef.current.update('none')
            }
            if(event.shiftKey || isSelectTouchGroup)
            {
                data.push(...saveClickedDataset)
                gapLine.dispGapLine && dataIntersection.push(...saveClickedDataset.filter(x=>x.lineType === LineType.CONTROL_CURVE && !x.lineId.toString().includes("INTENSITY") && !x.lineId.toString().includes("CALC_POINT")))
                setSaveClickedDataset(data)
                gapLine.dispGapLine && handleShowTextMeasureLine(dataIntersection)
            }
            else
            {
                setSaveClickedDataset(data)
                gapLine.dispGapLine  && handleShowTextMeasureLine(dataIntersection)
            }
        } else {
        // ================= Onlick outside ==================
            unSelectAllControls()
            publishChartEvents({ event: 'SELECT_CONTROLS', payload: {id:[],state:"select"}})
            chartRef.current?.data.datasets.forEach((dataset : any) => {
                if (dataset.lineType === LineType.MEASUREMENT_LINE) return
                dataset.backgroundColor = dataset.savedColor
                dataset.borderColor = dataset.savedColor
                dataset.pointBorderColor =  dataset.savedColor
                setDrawConnectLines(!drawConnectLines)
            })
            chartRef.current?.update('none')  

            setSaveClickedDataset([])
            setNotSelectListTextOfLinesInTab()
            setListShowIntersectionPointText([])
        }
        // not selected graph_text
        (!event.shiftKey || !isSelectTouchGroup) && setNotSelectListObjectText();
        setIsClickOnChart(!isClickOnChart)
    }

    const handleContextMenu = (event: any, element?:any) => {
        event.preventDefault()
        event.stopPropagation()
        if(event.shiftKey || isSelectTouchGroup) return
        setIsOnOpenMenu(true)

        //store event
        setFocusEvent({event:event, element:element})

        //check is hitted line
        const ctx = chartRef.current as any
        const cursorPos = chartHelpers.getRelativePosition(event, ctx);
        let clickedDataset = isHittedCurve({x: cursorPos.x, y: cursorPos.y})
        if(element && (clickedDataset === null || clickedDataset === undefined)) {
            clickedDataset = chartRef.current?.data.datasets.find((x:any)=> Number(x?.controlId) === Number(element?.controlId) && Number(x?.relatedGraphTextId) === Number(element?.id))
        }

        if(clickedDataset && (clickedDataset.lineType === LineType.MEASUREMENT_LINE || clickedDataset.lineType === LineType.CONNECT_LINE)) clickedDataset = undefined

        handleClickOnChart(event, element)
    
        const scrollLeft = lineDiagramRef.current.scrollLeft;
        const scrollTop = lineDiagramRef.current.scrollTop;
        const BB = lineDiagramRef.current.getBoundingClientRect(),
            offsetX = BB.left,
            offsetY = BB.top;
        const x = event.clientX + scrollLeft - offsetX;
        const y = event.clientY - offsetY > 410 ? event.clientY - offsetY + 65 + scrollTop : event.clientY - offsetY + scrollTop;

        clearOptionMenu(!clearMenuState)

        setTextPosition({x:x,y:y});
        setContextMenu({
            visible: true,
            mode: clickedDataset? true: false,
            type: clickedDataset && clickedDataset.lineType === "USER_CURVE"? true : false,
            x: x,
            y: y,
            isGraphText:false
        });
    }

    const handleOnTouchContextMenu = (event: any, element?:any) => {
        //store event
        setFocusEvent({event:event, element:element})

        //check is hitted line
        const ctx = chartRef.current as any
        const cursorPos = chartHelpers.getRelativePosition(event, ctx);
        let clickedDataset = isHittedCurve({x: cursorPos.x, y: cursorPos.y})
        if(element && (clickedDataset === null || clickedDataset === undefined)) {
            clickedDataset = chartRef.current?.data.datasets.find((x:any)=> Number(x?.controlId) === Number(element?.controlId) && Number(x?.relatedGraphTextId) === Number(element?.id))
        }

        if(clickedDataset && (clickedDataset.lineType === LineType.MEASUREMENT_LINE || clickedDataset.lineType === LineType.CONNECT_LINE)) clickedDataset = undefined

        handleClickOnChart(event, element)
    
        const scrollLeft = lineDiagramRef.current.scrollLeft;
        const scrollTop = lineDiagramRef.current.scrollTop;
        const BB = lineDiagramRef.current.getBoundingClientRect(),
            offsetX = BB.left,
            offsetY = BB.top;
        const x = event.changedTouches[0].screenX + scrollLeft - offsetX;
        const y = event.changedTouches[0].screenY - offsetY > 410 ? event.changedTouches[0].screenY - offsetY + 65 + scrollTop : event.changedTouches[0].screenY - offsetY + scrollTop;;
        setTextPosition({x:x,y:y});
        setContextMenu({
            visible: true,
            mode: clickedDataset? true: false,
            type: clickedDataset && clickedDataset.lineType === "USER_CURVE"? true : false,
            x: x,
            y: y,
            isGraphText:false
        });
    }

    const setPositionForGraphLabel = (position:any) => {
        const chart = chartDataList.find(x=>x.tabId === currentIDChartTab)?.shape.map(shapes=> shapes.chart.find(charts=>Number(charts.relatedGraphId) === Number(position.relatedGraphId) && Number(charts.relatedGraphTextId) === Number(position.relatedGraphTextId))).find(charts=>Number(charts?.relatedGraphId) === Number(position.relatedGraphId) && Number(charts?.relatedGraphTextId) === Number(position.relatedGraphTextId))
        if(chart)
        {
            const lineInfo = chart.tabMapping.get(currentIDChartTab)
            if(lineInfo)
            {
                updateLineInfo(
                    {   
                        ...lineInfo,
                        pointTopLeftX: position.pointTopLeftX,
                        pointTopLeftY: position.pointTopLeftY,
                        pointOldX: position.pointOldX,
                        pointOldY: position.pointOldY,
                        minPointX: position.minPointX,
                        minPointY: position.minPointY,
                    }, 
                    chart.lineId, 
                    chart.controlId, 
                )

                let params = {
                    userId: userId,
                    projectId : Number(storeProjectId),
                    relatedGraphId : Number(position.relatedGraphId),
                    relatedGraphTextId : Number(position.relatedGraphTextId),
                    colorFont : lineInfo.colorFont,
                    weight : lineInfo.weight,
                    italic : lineInfo.italic,
                    underline : lineInfo.underline,
                    strikeOut : lineInfo.strikeOut,
                    fontName : lineInfo.faceName,
                    dispRefV2 : lineInfo.dispRefV2,
                    dispTextFree : lineInfo.dispTextFree,
                    dispLinkLine: lineInfo.dispLinkLine,
                    textFree : lineInfo.textFree??'',
                    fontSize : lineInfo.fontSize,
                    pointTopLeftX : position.pointTopLeftX,
                    pointTopLeftY : position.pointTopLeftY,
                    pointOldX : position.pointOldX,
                    pointOldY : position.pointOldY,
                    ownerProject: projectData.createUserId
                }

                setLoadingFlag(true)
                updateRelatedGraphText([params]);
            }
        }
    }

    const setPositionForListGraphLabel = (arrPosition:any) => {
        let arrResult:any[] = []
        let checkUpdate = false
        arrPosition.map((item:any)=>{
            const chart = chartDataList.find(x=>x.tabId === currentIDChartTab)?.shape.map(shapes=> shapes.chart.find(charts=>Number(charts.relatedGraphId) === Number(item.relatedGraphId) && Number(charts.relatedGraphTextId) === Number(item.relatedGraphTextId))).find(charts=>Number(charts?.relatedGraphId) === Number(item.relatedGraphId) && Number(charts?.relatedGraphTextId) === Number(item.relatedGraphTextId))
            if(chart)
            {
                const lineInfo = chart.tabMapping.get(currentIDChartTab)
                if(lineInfo)
                {
                    updateLineInfo(
                        {   
                            ...lineInfo,
                            pointTopLeftX: item.pointTopLeftX,
                            pointTopLeftY: item.pointTopLeftY,
                            pointOldX: item.pointOldX,
                            pointOldY: item.pointOldY,
                            minPointX: item.minPointX,
                            minPointY: item.minPointY,
                        }, 
                        chart.lineId, 
                        chart.controlId, 
                    )
    
                    let params = {
                        userId: userId,
                        projectId : Number(storeProjectId),
                        relatedGraphId : Number(item.relatedGraphId),
                        relatedGraphTextId : Number(item.relatedGraphTextId),
                        colorFont : lineInfo.colorFont,
                        weight : lineInfo.weight,
                        italic : lineInfo.italic,
                        underline : lineInfo.underline,
                        strikeOut : lineInfo.strikeOut,
                        fontName : lineInfo.faceName,
                        dispRefV2 : lineInfo.dispRefV2,
                        dispTextFree : lineInfo.dispTextFree,
                        dispLinkLine: lineInfo.dispLinkLine,
                        textFree : lineInfo.textFree??'',
                        fontSize : lineInfo.fontSize,
                        pointTopLeftX : item.pointTopLeftX,
                        pointTopLeftY : item.pointTopLeftY,
                        pointOldX : item.pointOldX,
                        pointOldY : item.pointOldY,
                        ownerProject: projectData.createUserId

                    }
                    arrResult.push(params)
                    checkUpdate = true
                }
            }
        })
        checkUpdate && setLoadingFlag(true)
        checkUpdate && updateRelatedGraphText(arrResult);
    }

    const handleDownloadLineDiagramPDF = () => {
        chartRefPDF.current?.data.datasets.forEach((dataset : any) => {
            if (dataset.lineType === LineType.MEASUREMENT_LINE) return
            dataset.backgroundColor = dataset.savedColor
            dataset.borderColor = dataset.savedColor
            dataset.pointBorderColor =  dataset.savedColor
        })
        chartRefPDF.current?.update('none')  

        const canvas = document.getElementById('getLineDiagramPDF')!;

        //canvas.style.display = "block"

        if(isJapaneseElectricMode && isDownloadPDF.type == 2)
        {
            publishChartEvents({ event: 'SELECT_CONTROLS', payload: {id:[],state:"select"}})
            html2canvas(canvas).then((newCanvas:HTMLCanvasElement) => {
                const params = {
                    userId: userId,
                    projectId: Number(projectData.projectId),
                    data: '',
                    imgGraph: newCanvas.toDataURL(),
                    imgElement: isDownloadPDF.image.data,
                    imgElementWidth: isDownloadPDF.image.width,
                    imgElementHeight: isDownloadPDF.image.height,
                    graphNo: currentIDChartTab,
                    reportIndex : 4,
                    ownerProject: projectData.createUserId
                };
                setLoadingFlag(true)
                getPDFReport(params);

                //canvas.style.display = "none";
            }).catch((e:any) =>{
                console.log('error',e)
            });
        }
        else if(isJapaneseElectricMode && isDownloadPDF.type == 1)
        {
            publishChartEvents({ event: 'SELECT_CONTROLS', payload: {id:[],state:"select"}})
            html2canvas(canvas).then((newCanvas:HTMLCanvasElement) => {
                const params = {
                    userId: userId,
                    projectId: Number(projectData.projectId),
                    data: newCanvas.toDataURL(),
                    graphNo: currentIDChartTab,
                    reportIndex : 3,
                    ownerProject: projectData.createUserId
                };
                setLoadingFlag(true)
                getPDFReport(params);
    
                // const link = document.createElement('a');
                // link.download = 'lineDiagramFilePDF.png';
                // link.href = newCanvas.toDataURL()
                // link.click();

                //canvas.style.display = "none";
            }).catch((e:any) =>{
                console.log('error',e)
            });
        }
        else
        {
            html2canvas(canvas).then((newCanvas:HTMLCanvasElement) => {
        
                const params = {
                    userId: userId,
                    projectId: Number(projectData.projectId),
                    data: newCanvas.toDataURL(),
                    graphNo: currentIDChartTab,
                    reportIndex : 1,
                    ownerProject: projectData.createUserId
                };
                setLoadingFlag(true)
                getPDFReport(params);
    
                // const link = document.createElement('a');
                // link.download = 'lineDiagramFilePDF.png';
                // link.href = newCanvas.toDataURL()
                // link.click();

                //canvas.style.display = "none";
            }).catch((e:any) =>{
                console.log('error',e)
            });
        }
    }

    // contextMenuFuntions

    const handleOnOpenUserCurve = () => {
        if(saveClickedDataset.length > 0){
            let checkTab = diagramDataList.find((x:any)=>x.shape.find((item:any)=> Number(item.id) === Number(saveClickedDataset[0].controlId)))?.tabId
            if(checkTab && checkTab !== currentIDDiagramTab)
            {
                saveCurrentIDDiagramTab(checkTab)
            }
            openUserCurveEvent(saveClickedDataset[0].controlId)
       }
    }

    const handleCreateMsText = (x: any | null, y: any | null) => {
        const ctx = chartRef.current as any;
        const dataX = ctx.scales.x.getValueForPixel(x || textPosition.x);
        const dataY = ctx.scales.y.getValueForPixel(y || textPosition.y);
        const params = {
            userId: userId,
            projectId:storeProjectId,
            graphNo:currentIDChartTab,
            colorFont:"#0000ff",
            text:"テキスト" + noText,
            weight:400,
            italic:false,
            underline:false,
            strikeOut:false,
            charSet:128,
            faceName:"Noto Sans JP",
            fontSize:12,
            pointTopLeftX:dataX,
            pointTopLeftY:dataY,
            ownerProject: projectData.createUserId
        }
        setCurrentMsText(params);
        onCreateGraphText(params);
        setLoadingFlag(true);
    }

    const handleOnOpenUserCurveProperties = () => {
        if(saveClickedDataset.length > 0){
            let checkTab = diagramDataList.find((x:any)=>x.shape.find((item:any)=> Number(item.id) === Number(saveClickedDataset[0].controlId)))?.tabId
            if(checkTab && checkTab !== currentIDDiagramTab)
            {
                saveCurrentIDDiagramTab(checkTab)
            }
            let id = listTextOfLinesInTab.find(x=>x.isSelected)!.relatedGraphId
            let selectedItem =  chartDataList.find(x=> x.tabId == currentIDChartTab)!.shape.find(x=> Number(x.id) == Number(saveClickedDataset[0].controlId))!.chart.find(x=>Number(x.relatedGraphId) == Number(id))!.data.find((x:any)=>x.user_curve_id || x.userCurveId) as any
            openUserCurvePropertiesEvent(saveClickedDataset[0].controlId, selectedItem.user_curve_id ?? selectedItem.userCurveId)
        }
    }

    const handleOnJapanElectric = () => {
        toggleJapanElectricMode(currentIDChartTab)
        let temp = store.getState().app.diagram.chartData.find(x => x.tabId === currentIDChartTab);
        let dNominalVolt = temp?.nominalVolt ?? 0;
        let dZoom = temp?.zoom ?? 100;
        let bSociety = temp?.isJapaneseElectricMode ?? false;
        let param = {
            userId: userId,
            projectId : storeProjectId,
            graphNo : currentIDChartTab,
            nominalVolt : dNominalVolt,
            zoom : dZoom,
            society : bSociety,
            ownerProject: projectData.createUserId
        }
        updateGraph(param);
    }
    const handleOnChangeGraphDispBand = () => {
        let params = {
            userId: userId,
            projectId: storeProjectId,
            graphNo : currentIDChartTab,
            ownerProject: projectData.createUserId
        }
        setLoadingFlag(true);
        getGraphDispBand(params);
    }

    const contextMenuFuntions = {
        onOpenUserCurve: () => handleOnOpenUserCurve(),

        onOpenUserCurveProperties: () => handleOnOpenUserCurveProperties(),

        onDeleteLine: () => {
            setOpenDeleteDialog(true)
        },

        onOpenProperties: () => {
            handleDoubleClick(focusEvent.event, focusEvent.element)
        },

        onOpenPropertiesGraphText: () => {
            handleOpenTextEditor(focusEvent.event)
        },

        onJapanElectric: () => handleOnJapanElectric(),

        onDiffenceMeasurement: () => {
            setGapLine({...gapLine, dispGapLine: !gapLine.dispGapLine})
        },

        onSetUpGraphScale: () => {
            setOpenSetUpScaleGraph(true);
        },

        onCreateMsText:() => handleCreateMsText(null, null),

        onChangeGraphDispBand:() => handleOnChangeGraphDispBand(),
    }
    
    const handleDeleteSelectedLine = () =>{
        setIsDeleteGraphText(false);
        chartDiagramRef.current.focus()
        if(saveClickedDataset.length > 0){
            saveClickedDataset.map(x => {
            let temp: any[] = []
            if(chartDataList.find(search => search.shape.find(shapes=>Number(shapes.id) === Number(x.controlId)))?.shape.find(shapes=>Number(shapes.id) === Number(x.controlId))?.type === MS_3WINDING)
            {
                if(x.lineId === 'INRUSH_PART_2')
                {
                    chartDataList.map(diagram => diagram.tabId == currentIDChartTab && temp.push(...diagram.shape.find(shapes => Number(shapes.id) === Number(x.controlId))?.chart.filter((charts:any) => charts.lineId === 'INTENSITY_PART_2' && charts.lineType === x.lineType && charts.lineType !== LineType.USER_CURVE && Number(charts.controlId) === Number(x.controlId) )??[]));
                }
                else if(x.lineId === 'INTENSITY_PART_2')
                {
                    chartDataList.map(diagram => diagram.tabId == currentIDChartTab && temp.push(...diagram.shape.find(shapes => Number(shapes.id) === Number(x.controlId))?.chart.filter((charts:any) => charts.lineId === 'INRUSH_PART_2' && charts.lineType === x.lineType && charts.lineType !== LineType.USER_CURVE && Number(charts.controlId) === Number(x.controlId) )??[]));
                }
                else if(x.lineId === 'INRUSH_PART_3')
                {
                    chartDataList.map(diagram => diagram.tabId == currentIDChartTab && temp.push(...diagram.shape.find(shapes => Number(shapes.id) === Number(x.controlId))?.chart.filter((charts:any) => charts.lineId === 'INTENSITY_PART_3' && charts.lineType === x.lineType && charts.lineType !== LineType.USER_CURVE && Number(charts.controlId) === Number(x.controlId) )??[]));
                }
                else if(x.lineId === 'INTENSITY_PART_3')
                {
                    chartDataList.map(diagram => diagram.tabId == currentIDChartTab && temp.push(...diagram.shape.find(shapes => Number(shapes.id) === Number(x.controlId))?.chart.filter((charts:any) => charts.lineId === 'INRUSH_PART_3' && charts.lineType === x.lineType && charts.lineType !== LineType.USER_CURVE && Number(charts.controlId) === Number(x.controlId) )??[]));
                }
            }
            else if(chartDataList.find(search => search.shape.find(shapes=>Number(shapes.id) === Number(x.controlId)))?.shape.find(shapes=>Number(shapes.id) === Number(x.controlId))?.type === MS_TRANS3)
            {
                if(x.lineId === 'INRUSH')
                {
                    chartDataList.map(diagram => diagram.tabId == currentIDChartTab && temp.push(...diagram.shape.find(shapes => Number(shapes.id) === Number(x.controlId) )?.chart.filter((charts:any) => charts.lineId === 'INTENSITY' && charts.lineType === x.lineType && charts.lineType !== LineType.USER_CURVE && Number(charts.controlId) === Number(x.controlId))??[]));
                }
                else if(x.lineId === 'INTENSITY')
                {
                    chartDataList.map(diagram => diagram.tabId == currentIDChartTab && temp.push(...diagram.shape.find(shapes => Number(shapes.id) === Number(x.controlId) )?.chart.filter((charts:any) => charts.lineId === 'INRUSH' && charts.lineType === x.lineType && charts.lineType !== LineType.USER_CURVE && Number(charts.controlId) === Number(x.controlId))??[]));
                }
            }
            else if(chartDataList.find(search => search.shape.find(shapes=>Number(shapes.id) === Number(x.controlId)))?.shape.find(shapes=>Number(shapes.id) === Number(x.controlId))?.type === MS_TRANS1)
            {
                if(x.lineId === 'INRUSH_OUT_IN')
                {
                    chartDataList.map(diagram => diagram.tabId == currentIDChartTab && temp.push(...diagram.shape.find(shapes => Number(shapes.id) === Number(x.controlId) )?.chart.filter((charts:any) => charts.lineId === 'INTENSITY_OUT_IN' && charts.lineType === x.lineType && charts.lineType !== LineType.USER_CURVE && Number(charts.controlId) === Number(x.controlId))??[]));
                }
                else if(x.lineId === 'INTENSITY_OUT_IN')
                {
                    chartDataList.map(diagram => diagram.tabId == currentIDChartTab && temp.push(...diagram.shape.find(shapes => Number(shapes.id) === Number(x.controlId) )?.chart.filter((charts:any) => charts.lineId === 'INRUSH_OUT_IN' && charts.lineType === x.lineType && charts.lineType !== LineType.USER_CURVE && Number(charts.controlId) === Number(x.controlId))??[]));
                }
                else if(x.lineId === 'INRUSH_OUT_OUT')
                {
                    chartDataList.map(diagram => diagram.tabId == currentIDChartTab && temp.push(...diagram.shape.find(shapes => Number(shapes.id) === Number(x.controlId) )?.chart.filter((charts:any) => charts.lineId === 'INTENSITY_OUT_OUT' && charts.lineType === x.lineType && charts.lineType !== LineType.USER_CURVE && Number(charts.controlId) === Number(x.controlId) )??[]));
                }
                else if(x.lineId === 'INTENSITY_OUT_OUT')
                {
                    chartDataList.map(diagram => diagram.tabId == currentIDChartTab && temp.push(...diagram.shape.find(shapes => Number(shapes.id) === Number(x.controlId) )?.chart.filter((charts:any) => charts.lineId === 'INRUSH_OUT_OUT' && charts.lineType === x.lineType && charts.lineType !== LineType.USER_CURVE && Number(charts.controlId) === Number(x.controlId) )??[]));
                }
            }
            else if(chartDataList.find(search => search.shape.find(shapes=>Number(shapes.id) === Number(x.controlId)))?.shape.find(shapes=>Number(shapes.id) === Number(x.controlId))?.type === MS_TRANSSCOTT)
            {
                if(x.lineId === 'INRUSH_1')
                {
                    chartDataList.map(diagram => diagram.tabId == currentIDChartTab && temp.push(...diagram.shape.find(shapes => Number(shapes.id) === Number(x.controlId) )?.chart.filter((charts:any) => charts.lineId.toString() === 'INTENSITY_1' && charts.lineType === x.lineType && charts.lineType !== LineType.USER_CURVE && Number(charts.controlId) === Number(x.controlId) )??[]));
                }
                else if(x.lineId === 'INTENSITY_1')
                {
                    chartDataList.map(diagram => diagram.tabId == currentIDChartTab && temp.push(...diagram.shape.find(shapes => Number(shapes.id) === Number(x.controlId) )?.chart.filter((charts:any) => charts.lineId.toString() === 'INRUSH_1' && charts.lineType === x.lineType && charts.lineType !== LineType.USER_CURVE && Number(charts.controlId) === Number(x.controlId) )??[]));
                }
                else if(x.lineId === 'INRUSH_2')
                {
                    chartDataList.map(diagram => diagram.tabId == currentIDChartTab && temp.push(...diagram.shape.find(shapes => Number(shapes.id) === Number(x.controlId) )?.chart.filter((charts:any) => charts.lineId.toString() === 'INTENSITY_2' && charts.lineType === x.lineType && charts.lineType !== LineType.USER_CURVE && Number(charts.controlId) === Number(x.controlId) )??[]));
                }
                else if(x.lineId === 'INTENSITY_2')
                {
                    chartDataList.map(diagram => diagram.tabId == currentIDChartTab && temp.push(...diagram.shape.find(shapes => Number(shapes.id) === Number(x.controlId) )?.chart.filter((charts:any) => charts.lineId.toString() === 'INRUSH_2' && charts.lineType === x.lineType && charts.lineType !== LineType.USER_CURVE && Number(charts.controlId) === Number(x.controlId) )??[]));
                }
            }
            else
            {
                chartDataList.map(diagram => temp.push(...diagram.shape.find(shapes => shapes.id === x.controlId && shapes.type !== MS_TRANSSCOTT && shapes.type !== MS_TRANS3 && shapes.type !== MS_TRANS1 )?.chart.filter((charts:any) => charts.tabId == currentIDChartTab && charts.lineType === x.lineType && charts.lineType !== LineType.USER_CURVE && charts.controlId === x.controlId && !charts.lineId.toString().includes("CALC_POINT"))??[]));
            }
            !x.lineId.toString().includes("CALC_POINT") && temp?.map(tempList=>{
                if(saveClickedDataset.find(list=> list.relatedGraphId === tempList.relatedGraphId) === undefined)
                {
                    let item = {...getDefaultDatasetModel(),...tempList};
                    saveClickedDataset.push(item)
                }
              })
            })
            
            let arrRes:any[] = [];
            let arrUndoChart:any[] = [];
            let arrUndoText:any[] = [];
            saveClickedDataset.map((x:any) => {
                if(x.lineType != LineType.CONNECT_LINE && x.lineType != LineType.MEASUREMENT_LINE)
                {
                    let param = {
                        userId: userId,
                        projectId : storeProjectId,
                        relatedGraphId : x.relatedGraphId,
                        relatedGraphTextId : x.relatedGraphTextId,
                        ownerProject: projectData.createUserId
                    }
                    arrRes.push(param)
                    arrUndoChart.push(chartDataList.find(item=>item.tabId === currentIDChartTab)?.shape.find(item=>Number(item.id) === Number(x.controlId))?.chart.find(item=>Number(item.relatedGraphId) === Number(x.relatedGraphId)))
                    deleteRelatedGraphById(x.relatedGraphId)
                    if(listTextOfLinesInTab.find((text:any)=>Number(text.id) === Number(x.relatedGraphTextId) && text.lineId == x.lineId && text.graphNo === currentIDChartTab) != undefined)
                    {
                        arrUndoText.push(listTextOfLinesInTab.find((text:any)=>Number(text.id) === Number(x.relatedGraphTextId) && text.lineId == x.lineId && text.graphNo === currentIDChartTab))
                        listTextOfLinesInTab.splice(listTextOfLinesInTab.indexOf(listTextOfLinesInTab.find((text:any)=>Number(text.id) === Number(x.relatedGraphTextId) && text.lineId == x.lineId && text.graphNo === currentIDChartTab)), 1); 
                    }
                    if(listTextOfLinesInTabStore.find((text:any)=>Number(text.id) === Number(x.relatedGraphTextId) && text.lineId == x.lineId && text.graphNo === currentIDChartTab) != undefined)
                    {
                        listTextOfLinesInTabStore.splice(listTextOfLinesInTabStore.indexOf(listTextOfLinesInTabStore.find((text:any)=>Number(text.id) === Number(x.relatedGraphTextId) && text.lineId == x.lineId && text.graphNo === currentIDChartTab)), 1); 
                    }
                }
            })
            setUndoData({type:"DELETE_RELATED_GRAPH",dataUndo: {chart: arrUndoChart, text: arrUndoText}} as Model.UndoModel)
            deleteRelatedGraph(arrRes)
        }
    }

    const handleDeleteOK = () =>{
        setLoadingFlag(true);
        const element_delete = listObjectText.filter((item:any) => item.graphNo == currentIDChartTab && item.isSelected == true);
        if(element_delete.length > 0) {
            setUndoData({type:"DELETE_GRAPH_TEXT",dataUndo: element_delete} as Model.UndoModel)
            setIsDeleteGraphText(true);
            let params = {
                userId: userId,
                projectId : storeProjectId,
                listGraphTextId: element_delete.map((item:any) => {return item.id}),
                graphNo:currentIDChartTab,
                ownerProject: projectData.createUserId
            }   
            
            onDeleteGraphText(params);
        } else{
            handleDeleteSelectedLine()
        }
        setOpenDeleteDialog(false)
    }

    const handleDeleteCancel = () => {
        setOpenDeleteDialog(false)
    }

    const handleDeleteGraphNoOK = () => {
        const tabId = currentIDChartTab;
        setLoadingFlag(true)
        setOpenDeleteGraphDialog(false)
        let params = {
            userId: userId,
            projectId: projectData.projectId,
            graphNo: tabId,
            ownerProject: projectData.createUserId
        }
        deleteGraph(params)
        
        listTextOfLinesInTab.splice(listTextOfLinesInTab.indexOf(listTextOfLinesInTab.find((text:any)=> text.graphNo === tabId)), 1); 
        listTextOfLinesInTabStore.splice(listTextOfLinesInTabStore.indexOf(listTextOfLinesInTabStore.find((text:any)=> text.graphNo === tabId)), 1); 
        listObjectText.splice(listObjectText.indexOf(listObjectText.find((text:any)=> text.graphNo === tabId)), 1); 
        deleteGraphByGraphNo(tabId)
    }

    const handleDeleteGraphNoCancel = () => {
        setOpenDeleteGraphDialog(false)
    }

    const { v4: uuidv4 } = require('uuid');

    const handleSetTextLines = (shape:ControlModel[], voltageSaved: number, data?:any) =>{
        const arr: any[] = []
        shape.map((x:ControlModel)=>
        {
            let tempChart: any[] = [...x.chart]
            let newPosition: any
            if(x.type === MS_2E || x.type === MS_FUSE || x.type === MS_LVCB || x.type === MS_THERMAL || x.type === MS_THERMAL_CT || x.type === MS_HVCB)
            {
                if(x.type === MS_FUSE)
                {
                    newPosition = x.chart.find(charts=>charts.lineType === LineType.CONTROL_CURVE && charts.lineId === "POINT_MAX")?.tabMapping.get(currentIDChartTab)
                }

                if(x.chart.find(charts=>charts.lineType === LineType.CONTROL_CURVE && charts.lineId === "POINT_CENTER"))
                {
                    tempChart = [...x.chart].filter(charts=> charts.lineId !== "POINT_MAX" && charts.lineId !== "POINT_MIN")
                }
                else
                {
                    tempChart = [...x.chart].filter(charts=> charts.lineId !== "POINT_MIN")
                }
            }
           
            tempChart.map(charts=> {
                let infos: string[] = []
                if(charts.tabMapping.get(currentIDChartTab).dispTextFree)
                {
                    infos = [
                        ...charts.tabMapping.get(currentIDChartTab).textFree.split('\n'),
                    ]
                }
                else if (charts.lineType === LineType.CONTROL_CURVE) {
                    infos = GraphLabel.getControlGraphLabel(x, charts.lineId)
                }
                else {
                    // find to draw curve in list user curve
                    const foundUserCurve = x.properties.listUser.find((userCurve : any) => userCurve.userCurveId === charts.data.find((x:any)=>x.userCurveId)?.userCurveId || userCurve.userCurveId === charts.data.find((x:any)=>x.user_curve_id)?.user_curve_id)
                    if (foundUserCurve) {
                        infos = [
                            foundUserCurve.refNo,
                            foundUserCurve.name,
                            ...foundUserCurve.notes.split('\n'),
                        ]
                    }
                }

                const dataConverted = convertToDataset([charts], currentIDChartTab,!nominalVolt && voltageSaved && voltageSaved !== 0 ? voltageSaved : nominalVolt||1)[0].data;
                // const pointTopLeft = dataConverted.reduce(function(prev:any, current:any) {
                //     return (prev && prev.y > current.y) ? prev : current
                // });
                const pointTopLeft = dataConverted.length == 1 || dataConverted[0].y > dataConverted[dataConverted.length - 1].y ? dataConverted[0] : dataConverted[dataConverted.length - 1]

                const props = charts.tabMapping.get(currentIDChartTab)
                let id = data && ( charts.relatedGraphTextId === undefined || charts.relatedGraphTextId === "") ?  data.find((x:any)=>x.random_temp_id === charts.relatedGraphId) : undefined
                let param : any = {
                    id: id ? id?.related_graph_text_id : charts.relatedGraphTextId,
                    x: data && x.type === MS_FUSE && newPosition? newPosition.pointTopLeftX : props?.pointTopLeftX,
                    y: data && x.type === MS_FUSE && newPosition? newPosition.pointTopLeftY : props?.pointTopLeftY,
                    savedX:pointTopLeft.x,
                    savedY:pointTopLeft.y,
                    graphNo: currentIDChartTab,
                    relatedGraphId: id ? id?.related_graph_id : charts.relatedGraphId,
                    relatedGraphTextId: id ? id?.related_graph_text_id : charts.relatedGraphTextId,
                    controlId: charts.controlId,
                    lineType: charts.lineType,
                    lineId: charts.lineId,
                    fontSize: props?.fontSize,
                    faceName: props?.faceName,
                    colorFont: props?.colorFont, 
                    italic: props?.italic,
                    weight: props?.weight,
                    strikeOut: props?.strikeOut,
                    underline: props?.underline,
                    text: infos,
                    isSelected: id? true : false,
                    isHideText: props.dispRefV2 ? false : true,
                    isHideConnectLine: props.dispLinkLine ? false : true,
                    isShowTextFree : props.dispTextFree ? true : false,
                }
                arr.push(param)
            })
        })

        if(listTextOfLinesInTab.length > 0 && arr.length > 0)
        {
            const checkList = [...listTextOfLinesInTab]
            arr.map((x:any)=>{
                if(checkList.find(list=> Number(list.id) === Number(x.id) && list.graphNo == currentIDChartTab) == undefined && nominalVolt && nominalVoltStore && nominalVolt != nominalVoltStore)
                {
                    x.x = x.x / (nominalVolt / nominalVoltStore)
                }
                if(listTextOfLinesInTab.find(list=> Number(list.id) === Number(x.id)) != undefined && listTextOfLinesInTabStore.find(list=> Number(list.id) === Number(x.id)) != undefined){
                    listTextOfLinesInTab.splice(listTextOfLinesInTab.indexOf(listTextOfLinesInTab.find(list=> Number(list.id) === Number(x.id))),1)
                    listTextOfLinesInTabStore.splice(listTextOfLinesInTabStore.indexOf(listTextOfLinesInTabStore.find(list=> Number(list.id) === Number(x.id))),1)
                }
            })
        }
        setListTextOfLinesInTab(listTextOfLinesInTab.concat(arr)) 
        textLineStore = listTextOfLinesInTab.concat(arr)
        setListTextOfLinesInTabStore(listTextOfLinesInTabStore.concat(arr))
        setDrawConnectLines(!drawConnectLines)
    }

    const handleSetTextLinesForUndo = (dataUndo:any) =>{
        const arr: any[] = []
        dataUndo.forEach((item:any)=>{
            if(chartDataList.find(x=>x.tabId == item.tabId) != undefined)
            {
                let tabId = item.tabId
                let shape = item.data
                shape.map((x:ControlModel)=>
                {
                    let tempChart: any[] = [...x.chart]
                    let newPosition: any
                    if(x.type === MS_2E || x.type === MS_FUSE || x.type === MS_LVCB || x.type === MS_THERMAL || x.type === MS_THERMAL_CT || x.type === MS_HVCB)
                    {
                        if(x.type === MS_FUSE)
                        {
                            newPosition = x.chart.find(charts=>charts.lineType === LineType.CONTROL_CURVE && charts.lineId === "POINT_MAX")?.tabMapping.get(tabId)
                        }
    
                        if(x.chart.find(charts=>charts.lineType === LineType.CONTROL_CURVE && charts.lineId === "POINT_CENTER"))
                        {
                            tempChart = [...x.chart].filter(charts=> charts.lineId !== "POINT_MAX" && charts.lineId !== "POINT_MIN")
                        }
                        else
                        {
                            tempChart = [...x.chart].filter(charts=> charts.lineId !== "POINT_MIN")
                        }
                    }
                
                    tempChart.map(charts=> {
                        let infos: string[] = []
                        if(charts.tabMapping.get(tabId).dispTextFree)
                        {
                            infos = [
                                ...charts.tabMapping.get(tabId).textFree.split('\n'),
                            ]
                        }
                        else if (charts.lineType === LineType.CONTROL_CURVE) {
                            infos = GraphLabel.getControlGraphLabel(x, charts.lineId)
                        }
                        else {
                            // find to draw curve in list user curve
                            const foundUserCurve = x.properties.listUser.find((userCurve : any) => userCurve.userCurveId === charts.data.find((x:any)=>x.userCurveId)?.userCurveId || userCurve.userCurveId === charts.data.find((x:any)=>x.user_curve_id)?.user_curve_id)
                            if (foundUserCurve) {
                                infos = [
                                    foundUserCurve.refNo,
                                    foundUserCurve.name,
                                    ...foundUserCurve.notes.split('\n'),
                                ]
                            }
                        }
    
                        let voltSaved = chartDataList.find(x=>x.tabId == tabId)?.nominalVolt
                        const dataConverted = convertToDataset([charts], tabId, voltSaved? voltSaved : 1)[0].data;
                        // const pointTopLeft = dataConverted.reduce(function(prev:any, current:any) {
                        //     return (prev && prev.y > current.y) ? prev : current
                        // });
                        const pointTopLeft = dataConverted.length == 1 || dataConverted[0].y > dataConverted[dataConverted.length - 1].y ? dataConverted[0] : dataConverted[dataConverted.length - 1]
    
                        const props = charts.tabMapping.get(tabId)
                        let param : any = {
                            id: charts.relatedGraphTextId,
                            x: props?.pointTopLeftX,
                            y: props?.pointTopLeftY,
                            savedX:pointTopLeft.x,
                            savedY:pointTopLeft.y,
                            graphNo: tabId,
                            relatedGraphId: charts.relatedGraphId,
                            relatedGraphTextId: charts.relatedGraphTextId,
                            controlId: charts.controlId,
                            lineType: charts.lineType,
                            lineId: charts.lineId,
                            fontSize: props?.fontSize,
                            faceName: props?.faceName,
                            colorFont: props?.colorFont, 
                            italic: props?.italic,
                            weight: props?.weight,
                            strikeOut: props?.strikeOut,
                            underline: props?.underline,
                            text: infos,
                            isSelected: false,
                            isHideText: props.dispRefV2 ? false : true,
                            isHideConnectLine: props.dispLinkLine ? false : true,
                            isShowTextFree : props.dispTextFree ? true : false,
                        }
                        arr.push(param)
                    })
                })
            }
        })
        
        
        if(listTextOfLinesInTab.length > 0 && arr.length > 0)
        {
            const checkList = [...listTextOfLinesInTab]
            arr.map((x:any)=>{
                if(checkList.find(list=> Number(list.id) === Number(x.id) && list.graphNo == currentIDChartTab) == undefined && nominalVolt && nominalVoltStore && nominalVolt != nominalVoltStore)
                {
                    x.x = x.x / (nominalVolt / nominalVoltStore)
                }
                if(listTextOfLinesInTab.find(list=> Number(list.id) === Number(x.id)) != undefined && listTextOfLinesInTabStore.find(list=> Number(list.id) === Number(x.id)) != undefined){
                    listTextOfLinesInTab.splice(listTextOfLinesInTab.indexOf(listTextOfLinesInTab.find(list=> Number(list.id) === Number(x.id))),1)
                    listTextOfLinesInTabStore.splice(listTextOfLinesInTabStore.indexOf(listTextOfLinesInTabStore.find(list=> Number(list.id) === Number(x.id))),1)
                }
            })
        }
        setListTextOfLinesInTab(()=>listTextOfLinesInTab.concat(arr))  
        setListTextOfLinesInTabStore(listTextOfLinesInTabStore.concat(arr))
        setDrawConnectLines(!drawConnectLines)
    }

    const handleSaveRelatedGraph = (data:any[],norminalVoltage:any) => {
        let arrRes:any[] = [];
        data.map(x => {
            x?.data.map((item : LineInfoModel) => {
                const dataConverted = convertToDataset([item], currentIDChartTab, norminalVoltage || 1)[0].data;
                // const pointTopLeft = dataConverted.reduce(function(prev:any, current:any) {
                //     return (prev && prev.y > current.y) ? prev : current
                // });
                const pointTopLeft = dataConverted.length == 1 || dataConverted[0].y > dataConverted[dataConverted.length - 1].y ? dataConverted[0] : dataConverted[dataConverted.length - 1]
                let checkEle = chartDataList.find(x=>x.tabId === currentIDChartTab)?.shape.find(x=>x.id === item.controlId)?.type
                let lineText
                if(item?.lineType === "CONTROL_CURVE") 
                {
                    lineText = GraphLabel.getControlGraphLabel(x?.element, item?.lineId).join("\n") 
                } 
                else
                {
                    let temp = (item.data.find((x:any)=> x.userCurveId) ? item.data.find((x:any)=> x.userCurveId) : item.data.find((x:any)=> x.user_curve_id)) as any
                    const foundLine = x.element.properties.listUser.find((e:any) => e.userCurveId === temp.userCurveId || temp.user_curve_id);
                    lineText = foundLine !== undefined ? [
                        foundLine.refNo,
                        foundLine.name,
                        ...foundLine.notes.split('\n'),
                    ].join("\n") : '';
                }

                let res = {
                    tempId : item.relatedGraphId,
                    userId: userId,                                                                             
                    elementId : Number(item?.controlId),
                    trans1ParKind : 0,
                    _3windingPartKind : 0,
                    transScottPartKind : 0,
                    calcPointId : 0,
                    userCurveId : 0,
                    projectId : Number(storeProjectId),
                    graphNo : currentIDChartTab,
                    colorNormal : item?.tabMapping.get(currentIDChartTab)?.borderColor,
                    kind : 0,
                    width : item?.tabMapping.get(currentIDChartTab)?.borderWidth,
                    colorFont : item?.tabMapping.get(currentIDChartTab)?.colorFont,
                    textFixed : lineText,
                    weight : 0,
                    italic : false,
                    underline : false,
                    strikeOut : false,
                    charSet : 128,
                    faceName : 'MS UI Gothic',
                    kindLinkLine : 0,
                    dispLinkLine : true,
                    dispRefV2 : true,
                    dispTextFree : false,
                    dispBand: (checkEle === MS_FUSE|| checkEle === MS_2E|| checkEle === MS_THERMAL|| checkEle === MS_LVCB|| checkEle === MS_THERMAL_CT)? true : false,
                    textFree : '',
                    pointOldX : 0,
                    pointOldY : 0,
                    pointTopLeftX : pointTopLeft.x < 10 ? 10.01 : pointTopLeft.x,
                    pointTopLeftY : pointTopLeft.y > 10000? 9999.99 : pointTopLeft.y,
                    fontSize : 12,
                    ownerProject: projectData.createUserId
                }
        
                switch(item?.lineType) {
                    case 'CONTROL_CURVE':
                        res.userCurveId = 0;
                        break;
                    case 'USER_CURVE':
                        res.userCurveId = Number(drawLineInfo.lineId);
                        break;
                    default:
                        break;
                }
        
                switch(item?.lineId) {
                    case 'INRUSH_OUT_IN':
                        res.trans1ParKind = 1;
                        break;
                    case 'INRUSH_OUT_OUT':
                        res.trans1ParKind = 2;
                        break;
                    case 'INRUSH_PART_1':
                        res._3windingPartKind = 1;
                        break;
                    case 'INRUSH_PART_2':
                        res._3windingPartKind = 2;
                        break;
                    case 'INRUSH_PART_3':
                        res._3windingPartKind = 3;
                        break;
                    case 'INRUSH_1':
                        res.transScottPartKind = 1;
                        break;
                    case 'INRUSH_2' :
                        res.transScottPartKind = 2;
                        break;
                    case 'CALC_POINT_0' :
                        res.calcPointId = 1;
                        break;
                    case 'CALC_POINT_0_2' :
                        res.calcPointId = 1;
                        break;
                    case 'CALC_POINT_0_3' :
                        res.calcPointId = 1;
                        break;
                    case 'CALC_POINT_1' :
                        res.calcPointId = 2;
                        break;
                    case 'CALC_POINT_1_2' :
                        res.calcPointId = 2;
                        break;
                    case 'CALC_POINT_1_3' :
                        res.calcPointId = 2;
                        break;
                    default:
                        break;
                }
        
                switch(item?.tabMapping.get(maxIDChartTab)?.borderDash.toString()) {
                    case '':
                        res.kind = 0;
                        break;
                    case '25,5':
                        res.kind = 1;
                        break;
                    case '5,5':
                        res.kind = 2;
                        break;
                    case '10,5,5,5':
                        res.kind = 3;
                        break;
                    case '10,5,5,5,5,5':
                        res.kind = 4;
                        break;
                    default:
                        break;
                }
                arrRes.push(res)
            })
        })
        setLoadChart(true)
        createRelatedGraph(arrRes);
        setReloadHandle(true)
    }

    const handleSaveRelatedGraphForUndo = (dataUndo:any) => {
        let arrRes:any[] = [];
        dataUndo.forEach((element:any) => {
            let norminalVoltage = chartDataList.find(x=>x.tabId == element.tabId)?.nominalVolt
            element.data.map((x:any)=>{
                x.chart.map((item : LineInfoModel) => {
                    let lineText
                    if(item?.lineType === "CONTROL_CURVE") 
                    {
                        lineText = GraphLabel.getControlGraphLabel(x, item?.lineId).join("\n") 
                    } 
                    else
                    {
                        let temp = (item.data.find((x:any)=>x.userCurveId) ? item.data.find((x:any)=>x.userCurveId) : item.data.find((x:any)=>x.user_curve_id)) as any
                        const foundLine = x.properties.listUser.find((e:any) => e.userCurveId === temp.userCurveId || temp.user_curve_id);
                        lineText = foundLine !== undefined ? [
                            foundLine.refNo,
                            foundLine.name,
                            ...foundLine.notes.split('\n'),
                        ].join("\n") : '';
                    }
    
                    let res = {
                        relatedGraphId : Number(item.relatedGraphId),
                        relatedGraphTextId: Number(item.relatedGraphTextId),
                        userId: userId,                                                                             
                        elementId : Number(item?.controlId),
                        trans1ParKind : 0,
                        _3windingPartKind : 0,
                        transScottPartKind : 0,
                        calcPointId : 0,
                        userCurveId : 0,
                        projectId : Number(storeProjectId),
                        graphNo : element.tabId,
                        colorNormal : item?.tabMapping.get(element.tabId)?.borderColor,
                        kind : 0,
                        width : item?.tabMapping.get(element.tabId)?.borderWidth,
                        colorFont : item?.tabMapping.get(element.tabId)?.colorFont,
                        textFixed : lineText,
                        weight : item?.tabMapping.get(element.tabId)?.weight,
                        italic : item?.tabMapping.get(element.tabId)?.italic,
                        underline : item?.tabMapping.get(element.tabId)?.underline,
                        strikeOut : item?.tabMapping.get(element.tabId)?.strikeOut,
                        charSet : 128,
                        faceName : item?.tabMapping.get(element.tabId)?.faceName,
                        kindLinkLine : 0,
                        dispLinkLine : item?.tabMapping.get(element.tabId)?.dispLinkLine,
                        dispRefV2 : item?.tabMapping.get(element.tabId)?.dispRefV2,
                        dispTextFree : item?.tabMapping.get(element.tabId)?.dispTextFree,
                        dispBand: item?.tabMapping.get(element.tabId)?.dispBand,
                        textFree : item?.tabMapping.get(element.tabId)?.textFree,
                        pointOldX : item?.tabMapping.get(element.tabId)?.pointOldX,
                        pointOldY : item?.tabMapping.get(element.tabId)?.pointOldY,
                        pointTopLeftX : item?.tabMapping.get(element.tabId)?.pointTopLeftX,
                        pointTopLeftY : item?.tabMapping.get(element.tabId)?.pointTopLeftY,
                        fontSize : item?.tabMapping.get(element.tabId)?.fontSize,
                        ownerProject: projectData.createUserId
                    }
            
                    switch(item?.lineType) {
                        case 'CONTROL_CURVE':
                            res.userCurveId = 0;
                            break;
                        case 'USER_CURVE':
                            res.userCurveId = Number(item.data.find((x:any)=>x.userCurveId)?.userCurveId ? item.data.find((x:any)=>x.userCurveId)?.userCurveId : item.data.find((x:any)=>x.user_curve_id)?.user_curve_id);
                            break;
                        default:
                            break;
                    }
            
                    switch(item?.lineId) {
                        case 'INRUSH_OUT_IN':
                            res.trans1ParKind = 1;
                            break;
                        case 'INRUSH_OUT_OUT':
                            res.trans1ParKind = 2;
                            break;
                        case 'INRUSH_PART_1':
                            res._3windingPartKind = 1;
                            break;
                        case 'INRUSH_PART_2':
                            res._3windingPartKind = 2;
                            break;
                        case 'INRUSH_PART_3':
                            res._3windingPartKind = 3;
                            break;
                        case 'INRUSH_1':
                            res.transScottPartKind = 1;
                            break;
                        case 'INRUSH_2' :
                            res.transScottPartKind = 2;
                            break;
                        case 'CALC_POINT_0' :
                            res.calcPointId = 1;
                            break;
                        case 'CALC_POINT_0_2' :
                            res.calcPointId = 1;
                            break;
                        case 'CALC_POINT_0_3' :
                            res.calcPointId = 1;
                            break;
                        case 'CALC_POINT_1' :
                            res.calcPointId = 2;
                            break;
                        case 'CALC_POINT_1_2' :
                            res.calcPointId = 2;
                            break;
                        case 'CALC_POINT_1_3' :
                            res.calcPointId = 2;
                            break;
                        default:
                            break;
                    }
            
                    switch(item?.tabMapping.get(element.tabId)?.borderDash.toString()) {
                        case '':
                            res.kind = 0;
                            break;
                        case '25,5':
                            res.kind = 1;
                            break;
                        case '5,5':
                            res.kind = 2;
                            break;
                        case '10,5,5,5':
                            res.kind = 3;
                            break;
                        case '10,5,5,5,5,5':
                            res.kind = 4;
                            break;
                        default:
                            break;
                    }
                    arrRes.push(res)
                })
            })
        })
        setLoadChart(true)
        undoRelatedGraph(arrRes);
    }

    // ===================== BEGIN DRAW LINES ===============================
    // this function only invoke when component mount or shapes change
    const handleDataChart = async () => {   
        setLoadChart(true)
        //clear detail info
        setNotSelectListObjectText()
        setNotSelectListTextOfLinesInTab()

        const drawnEleIds: any[] = [];
        // loop over selected shapes or shapes of current chart tab
        let arrayDataToSaveGraph: any[] = [];
        let drawMode = 0;
        const chart = chartDataList.find(data => data.tabId === currentIDChartTab)?.shape.find(shapes => shapes.chart.find(charts => charts.controlId === isAddLineToChart.payload?.controlId && charts.lineId.toString().includes("CALC_POINT")))

        if(isAddLineToChart.payload?.mode && chart !== undefined) {
            drawMode = 1;
        }
        if(isAddCalcPointToChart.payload?.mode) {
            drawMode = 2;
        }
        if(((!isOpenGraphPage || (drawLineInfo.lineType === LineType.USER_CURVE && drawLineInfo.state == true)) || reloadChart) && !isReOpenGraphTemporary)
        {
            for (let index = 0; index < shapes.length; index++) {
                const element = shapes[index];
                element.properties.dispBand = undefined;
                if (element.type === MS_TEXT) continue;
                if (drawLineInfo.lineType === LineType.CONTROL_CURVE) { //Control curve(s)
                    // const foundLineInfos = [...element.chart.filter((lineInfo : LineInfoModel) => lineInfo.lineType === LineType.CONTROL_CURVE && lineInfo.controlId === element.id)]
                    // if (foundLineInfos.length > 0 && drawMode === 0) { //has drawn control internal chart
                    //     foundLineInfos.forEach((lineInfo : LineInfoModel) => {
                    //         if (!lineInfo.tabMapping.has(currentIDChartTab)) {
                    //             lineInfo.tabMapping.set(currentIDChartTab, getDefaultLineStyle(element.type))
                    //         }
                    //         lineInfo.relatedGraphId = uuidv4();
                    //     })
                    //     arrayDataToSaveGraph.push({data : foundLineInfos, element : element})                
                    //     drawnEleIds.push(...foundLineInfos.map((lineInfo : LineInfoModel) => ({id : lineInfo.controlId, type : lineInfo.lineType})))
                    // } else {
                        let lineData : GraphLineData = {lines: []}
                        lineData = await getGraphLineData(element, true, drawMode)
                        let arrayLineData:any[] = [];
                        lineData.lines.forEach(line => {
                            if(line.points.length > 0)
                            {
                                const newLineInfo = getDefaultLineInfo(uuidv4(), element, line.points, line.id, currentIDChartTab, element.type)
                                arrayLineData.push(newLineInfo)
                                element.chart.push(newLineInfo) //save to control
    
                                drawnEleIds.push({id :newLineInfo.controlId , type: newLineInfo.lineType}) //to draw
                            }
                            else{
                                drawnEleIds.push({id :element.id , type: element.type}) //to draw
                            }
                        })
                        
                        arrayDataToSaveGraph.push({data : arrayLineData, element : element})

                        if (lineData.raw !== undefined && element.raw !== undefined) { //controls those call db will have raw, save to cache
                            element.raw = lineData.raw
                        }
                    //}
                    saveChartIntoControl(element);
                }
                else if (drawLineInfo.lineType === LineType.USER_CURVE) { //User curve
                    // find existing chart
                    const foundLineInfos = [...element.chart.filter((lineInfo : LineInfoModel) => lineInfo.lineId === drawLineInfo.lineId && lineInfo.controlId === element.id)]
                    if (foundLineInfos.length > 0) {
                        foundLineInfos.forEach((lineInfo : LineInfoModel) => {
                            if (!lineInfo.tabMapping.has(currentIDChartTab)) {
                                lineInfo.tabMapping.set(currentIDChartTab, getDefaultLineStyle(element.type))
                            }
                            lineInfo.relatedGraphId = uuidv4();
                        })

                        arrayDataToSaveGraph.push({data : foundLineInfos, element : element})

                        drawnEleIds.push(...foundLineInfos.map((lineInfo : LineInfoModel) => ({ id: lineInfo.controlId, type: lineInfo.lineType})))
                    } else {
                        // find exact curve in list of user curves that user want to draw
                        const foundToDrawCurve = element.properties.listUser.find((userCurve : any) => userCurve.userCurveId === drawLineInfo.lineId) 
                        let arrayLineData:any[] = [];
                        if (foundToDrawCurve) {
                            let listToDrawPoints = [...foundToDrawCurve.listPoint]
                            if(listToDrawPoints && listToDrawPoints.length > 0 && listToDrawPoints[0].y < listToDrawPoints[listToDrawPoints.length - 1].y)
                            {
                                listToDrawPoints = listToDrawPoints.reverse()
                            }
                            // check if freeOrCircuitVolt is true, then re-calc x base on ratio
                            if (foundToDrawCurve.freeOrCircuitVolt) {
                                const ratio = foundToDrawCurve.stdVoltage / foundToDrawCurve.voltage
                                listToDrawPoints = listToDrawPoints.map((point : any) => ({ ...point, x: point.x * ratio}))
                            }

                            //CHECK AGAIN
                            listToDrawPoints = listToDrawPoints.map((point : any) => ({ ...point, x: point.x * foundToDrawCurve.stdVoltage}))// (nominalVolt || 1)}))
                            const newLineInfo = getDefaultLineInfo(uuidv4(), element, listToDrawPoints, foundToDrawCurve.id, currentIDChartTab, foundToDrawCurve.type, LineType.USER_CURVE)
                            arrayLineData.push(newLineInfo)

                            element.chart.push(newLineInfo)
                            drawnEleIds.push({id :newLineInfo.controlId, type: newLineInfo.lineType})
                        }

                        arrayDataToSaveGraph.push({data : arrayLineData, element : element})
                    }
                    saveChartIntoControl(element);
                }
            }

			let voltageSaved = handleDrawChartSuccess(drawnEleIds, arrayDataToSaveGraph);
            
            if(reloadChart) {
                //TODO  maybe set old lines to new lines not match, show review this code
                arrayDataToSaveGraph.map(arr => arr.data.map((x:any) => {
                    let temp : any[] = []
                    if(drawLineInfo.lineType !== LineType.USER_CURVE)
                    {
                        temp = relatedGraphData
                        temp = temp.filter(data => data.element_id === Number(x.controlId) )
                        temp.sort((a,b) => a.related_graph_id - b.related_graph_id);

                        //remove the updated line from the array
                        const index = relatedGraphData.indexOf(temp[0]);
                        if (index > -1) { 
                            relatedGraphData.splice(index, 1); 
                            setRelatedGraphData(relatedGraphData)
                        }
                    }
                    else
                    {
                        temp = relatedGraphUserCurveData
                        temp = temp.filter(data => data.element_id === Number(x.controlId) && (data.user_curve_id === x.data.find((x:any)=>x.user_curve_id)?.user_curve_id || data.user_curve_id === x.data.find((x:any)=>x.userCurveId)?.userCurveId))
                        temp.sort((a,b) => a.related_graph_id - b.related_graph_id);

                        const index = relatedGraphUserCurveData.indexOf(temp[0]);
                        if (index > -1) { 
                            relatedGraphUserCurveData.splice(index, 1); 
                            setRelatedGraphUserCurveData(relatedGraphUserCurveData)
                        }
                    }
                
                   if(temp.length > 0)
                   {
                    //set line id to store
                        setChartId(x?.relatedGraphId, temp[0].related_graph_id, temp[0].related_graph_text_id, currentIDChartTab)

                        x.relatedGraphId = temp[0].related_graph_id;
                        x.relatedGraphTextId = temp[0].related_graph_text_id;
                        x.pointTopLeftX = Number(temp[0].point_top_left_x);
                        x.pointTopLeftY = Number(temp[0].point_top_left_y)
                        x.dispBand = temp[0].disp_band;

                        let kind:any[] = []
                        switch(temp[0].kind) {
                            case 0:
                                kind = [];
                                break;
                            case 1:
                                kind = [25,5];
                                break;
                            case 2:
                                kind = [5,5];
                                break;
                            case 3:
                                kind = [10,5,5,5];
                                break;
                            case 4:
                                kind = [10,5,5,5,5,5];
                                break;
                            default:
                                break;
                        }

                        const foundDataset = chartRef.current?.data.datasets.find((dataset : any) => dataset.lineId === x.lineId) as any
                        if (foundDataset) {
                            foundDataset.backgroundColor = temp[0].color_normal
                            foundDataset.borderColor = temp[0].color_normal
                            foundDataset.savedColor = temp[0].color_normal
                            foundDataset.borderDash = kind
                            foundDataset.borderWidth = temp[0].width
                            foundDataset.dispBand = temp[0].disp_band
                            foundDataset.dispRefV2 = temp[0].disp_ref_v2
                            foundDataset.dispLinkLine = temp[0].disp_link_line
                            foundDataset.dispTextFree = temp[0].disp_text_free
                            foundDataset.fontSize = temp[0].font_size
                            foundDataset.faceName = temp[0].face_name
                            foundDataset.colorFont = temp[0].color_font
                            foundDataset.underline = temp[0].underline
                            foundDataset.strikeOut = temp[0].strike_out
                            foundDataset.italic = temp[0].italic
                            foundDataset.weight = temp[0].weight
                            foundDataset.text = temp[0].disp_text_free && temp[0].text_free !== '' ?temp[0].text_free: temp[0].text_fixed
                            foundDataset.pointTopLeftX = Number(temp[0].point_top_left_x)
                            foundDataset.pointTopLeftY = Number(temp[0].point_top_left_y)
                            foundDataset.pointOldX = 0
                            foundDataset.pointOldY = 0
                            if(foundDataset.lineId.toString().includes('INTENSITY') || (foundDataset.lineType == LineType.USER_CURVE && foundDataset.data.filter((item:any)=>item.x != 0 && item.y !=0).length == 1))
                            {
                                foundDataset.pointBorderColor = temp[0].color_normal
                                foundDataset.pointBorderWidth = temp[0].color_normal
                            }
                            chartRef.current?.update('none')
                        }

                        //update line to store
                        updateLineInfo(
                            {   
                                backgroundColor: temp[0].color_normal,
                                borderColor:  temp[0].color_normal,
                                borderDash: kind,
                                borderWidth: temp[0].width,
                                dispBand: temp[0].disp_band,
                                dispRefV2: temp[0].disp_ref_v2,
                                dispLinkLine: temp[0].disp_link_line,
                                dispTextFree: temp[0].disp_text_free,
                                fontSize: temp[0].font_size,
                                faceName : temp[0].face_name,
                                colorFont : temp[0].color_font,
                                underline : temp[0].underline,
                                strikeOut : temp[0].strike_out,
                                italic : temp[0].italic,
                                weight : temp[0].weight,
                                textFree: temp[0].text_free,
                                text: temp[0].disp_text_free && temp[0].text_free !== '' ?temp[0].text_free: temp[0].text_fixed,
                                pointTopLeftX: Number(temp[0].point_top_left_x),
                                pointTopLeftY: Number(temp[0].point_top_left_y),
                                pointOldX: 0,
                                pointOldY: 0,
                                minPointX: Number(temp[0].point_top_left_x),
                                minPointY: Number(temp[0].point_top_left_y)
                            }, 
                            x.lineId, 
                            x.controlId
                        )
                   }
                }))

                let shape = chartDataList?.find(x=>x.tabId === currentIDChartTab)?.shape
                shape && handleSetTextLines(shape,voltageSaved)
                setReloadConnectLines(!reloadConnectLines)
                let listUpdateLines : any[] = []
                chartDataList.find(x=>x.tabId=== currentIDChartTab)?.shape.map(shapes=> listUpdateLines.push({controlId: shapes.id, properties: shapes.properties, dispBand: shapes.chart.filter(charts => charts.lineType === LineType.CONTROL_CURVE)[0]?.tabMapping.get(currentIDChartTab)?.dispBand}))
                listUpdateLines && listUpdateLines.map(element => {
                    element.dispBand !== undefined && updateControlData(element.controlId, {...element.properties , dispBand : element.dispBand})
                })
            }
            if(userCurveToDraw && userCurveToDraw.length > 0)
            {
                setReloadChart(true);
                let userCurve = userCurveToDraw[0];
                userCurveToDraw.shift()
                setUserCurveToDraw(userCurveToDraw)
                setLoadingFlag(true)
                userCurve.isAddNew && setIsAddNewGraph(true)
                onDrawLines([userCurve],true)
            }
            else
            {
                setReloadChart(false);
                setIsAddNewGraph(false)
                !isReOpenGraphTemporary && onFinishDrawLineInfo(false)
            }

            setLoadingFlag(false)
            setLoadChart(false); 
        }
        else if(isReOpenGraphTemporary)
        {
            setListTextOfLinesInTab([])
            setListTextOfLinesInTabStore([])
            setReloadChart(false)

            let shape = chartDataList?.find(x=>x.tabId === currentIDChartTab)?.shape
            shape && handleSetTextLines(shape, 0)
            setReloadConnectLines(!reloadConnectLines)
            setLoadingFlag(false)
            setLoadChart(false); 
        }
        else
        {
            setLoadingFlag(false)
            setLoadChart(false);  
        }
        !reloadChart && !isReOpenGraphTemporary && onFinishDrawLineInfo(false)
    }

	const handleDrawChartSuccess = (drawnEleIds: any[], arrayDataToSaveGraph: any[]) => {
        // some elements will not be draw, filter them out before save controls to chart tab
      
        const updatedShapes = [...shapes].filter(shape => {
            return drawnEleIds.find(x=>x.id === shape.id)
        }).map(x=>({...x,chart : x.chart.filter(charts=>charts.tabMapping.has(currentIDChartTab))}));

        let newUpdatedShapes = [...updatedShapes].map(shape => ({...shape, chart: shape.chart.filter(charts => {return drawnEleIds.find(x=> x.id === charts.controlId && x.type === charts.lineType)})}))
        //get nominal combox for this tab

        const voltageCombo = getVoltageCombo(updatedShapes || [], VOLT_SIDE_SECONDARY)

        setNominalVoltStore(voltageCombo[0])

        const currentNominalVolt:number = chartDataList.find(x=>x.tabId == currentIDChartTab)!.nominalVolt as number
        (currentNominalVolt == undefined || currentNominalVolt == 0) && saveNewNominalVolt(voltageCombo[0], currentIDChartTab)
        reloadChart && saveChartZoom(relatedGraphData[0]?.zoom??relatedGraphUserCurveData[0]?.zoom, currentIDChartTab);

        setAddLineToChart(false, '');
        setAddCalcPointToChart(false, '');

        // set nominal list to store
        let listVolt = Array.from(new Set([...store.getState().app.diagram.chartData.find(chartTab => chartTab.tabId === currentIDChartTab)?.nominalVoltList??[], ...voltageCombo]))
        saveNominalVoltList(listVolt as number[])
        saveAllControlsToChartTab((isAddNewGraph == addNewGraph? isAddNewGraph : addNewGraph)? newUpdatedShapes : updatedShapes, false);

        !reloadChart && handleSaveRelatedGraph(arrayDataToSaveGraph, voltageCombo[0]);

        return voltageCombo[0]
    }
    // ===================== END DRAW LINES ==================================


    // ===================== BEGIN EDIT DATASET ===============================
    const handleDoubleClick = (event: any, element?:any) => {   
        const ctx = chartRef.current as any
        const cursorPos = chartHelpers.getRelativePosition(event, ctx);

        let clickedDataset = isHittedCurve({x: cursorPos.x, y: cursorPos.y})
        if(clickedDataset && (clickedDataset.lineType === LineType.CONNECT_LINE || clickedDataset.lineType === LineType.MEASUREMENT_LINE)) return
        
        if(element && (clickedDataset === null || clickedDataset === undefined)) {
            clickedDataset = chartRef.current?.data.datasets.find((x:any)=> element?.id && element?.controlId && Number(x?.controlId) === Number(element?.controlId) && Number(x?.relatedGraphTextId) === Number(element?.id))
        }
        setFocusEvent({event:clickedDataset, element:element})
        let textShow;
        if(clickedDataset!==null && clickedDataset!==undefined && clickedDataset?.lineType === "CONTROL_CURVE")
        {
            textShow = chartDataList.find(charts=>charts.tabId === currentIDChartTab)?.shape.find(x =>x.chart.find(e => e.lineId == clickedDataset.lineId && Number(e.controlId) === Number(clickedDataset.controlId)));
            textShow = textShow !== undefined? GraphLabel.getControlGraphLabel(textShow, clickedDataset?.lineId).join("\n") : '';
        }
        else if(clickedDataset!==null && clickedDataset!==undefined)
        {
            const selectedShape = store.getState().app.diagram.chartData.find(chart => chart.tabId === currentIDChartTab)?.shape.find(shapes => shapes.id === clickedDataset.controlId)
            const foundUserCurve = selectedShape?.properties.listUser.find((userCurve : any) => userCurve.userCurveId === clickedDataset.data.find((x:any)=>x.userCurveId)?.userCurveId || userCurve.userCurveId === clickedDataset.data.find((x:any)=>x.user_curve_id)?.user_curve_id)
            if (foundUserCurve) {
                textShow = [
                    foundUserCurve.refNo,
                    foundUserCurve.name,
                    ...foundUserCurve.notes.split('\n'),
                ].join("\n")
            }
        }

        if(clickedDataset)
        {
            let param: DatasetEditForm = {
                relatedGraphId: clickedDataset.relatedGraphId,
                relatedGraphTextId: clickedDataset.relatedGraphTextId,
                refNo: clickedDataset.label,
                controlId: clickedDataset.controlId,
                datasetId: clickedDataset.lineId,
                relatedGraph: {
                    colorNormal: clickedDataset.savedColor, 
                    kind: clickedDataset.borderDash,
                    width: clickedDataset.borderWidth,
                    dispBand: clickedDataset.dispBand,
                },
                relatedGraphText: {
                    fontSize : clickedDataset.fontSize,
                    faceName : clickedDataset.faceName,
                    colorFont : clickedDataset.colorFont,
                    weight : clickedDataset.weight,
                    italic : clickedDataset.italic,
                    underline : clickedDataset.underline,
                    strikeOut : clickedDataset.strikeOut,
                    charSet : 1,
                    dispLinkLine: clickedDataset.dispLinkLine,
                    dispRefV2: clickedDataset.dispRefV2,
                    dispTextFree: clickedDataset.dispTextFree,
                    kindLinkLine: 1, //TODO
                    textFixed: textShow??'',
                    textFree:  clickedDataset.textFree !== '' && clickedDataset.textFree ?clickedDataset.textFree : textShow??'',
                    pointTopLeftX:clickedDataset.pointTopLeftX,
                    pointTopLeftY:clickedDataset.pointTopLeftY,
                    pointOldX:clickedDataset.pointOldX,
                    pointOldY:clickedDataset.pointOldY,
                    minPointX: clickedDataset.pointOldX,
                    minPointY: clickedDataset.pointOldY,
                },
                updateId : -1,
                isCallDB: false,
            }

            let detail = chartDataList.find(x=>x.tabId == currentIDChartTab)?.shape.find(x=>x.id == clickedDataset.controlId)?.properties.details
            detail != undefined && setUndoData({type:"UNDO_UPDATE_RELATED_GRAPH",dataUndo:{...param!, ...detail}} as Model.UndoModel)
            setSelectedDataset(param!);
        }

        setDrawConnectLines(clickedDataset?.dispLinkLine)
    }

    const handleEditSubmit = (data : DatasetEditForm) => {
        switch(data.updateId)
        {
            case 0 : 
                handleUpdateCurveInGraph(data, false);
                break;
            case 1:
                handleUpdateRelatedGraph(data, false);
                break;
            case 2:
                handleUpdateRelatedGraphText(data);
                break;
        }      
    }

    const handleUpdateCurveInGraph = (data : DatasetEditForm, state:boolean) => {
        const { 
            relatedGraphId,
            controlId, 
            datasetId, 
            relatedGraph : { colorNormal, kind, width, dispBand },
            refNo,
            relatedGraphText : {colorFont, dispLinkLine, dispRefV2, dispTextFree, faceName, fontSize, italic, strikeOut, textFixed, textFree, underline, weight},
            isCallDB,
            ...details
        } = data

        let updatedEle = currentChartTabData.dataElements?.find(el => el.id === selectedDataset?.controlId) as ControlModel | undefined
        if(updatedEle == undefined)
        {
            updatedEle = chartDataList.find(x=>x.tabId == currentIDChartTab)?.shape.find(x=> Number(x.id) == Number(controlId))
        }
        if (updatedEle && updatedEle.properties.details ){
            // update properties to store
            updateControlData(controlId, {...updatedEle.properties, details ,dispBand, isCallDB})

            let params:any = {}
            switch(updatedEle.type)
            {
                case MS_LVCB:
                case MS_THERMAL:
                case MS_THERMAL_CT:
                case MS_2E:
                    params = convertUndefinedToNull({
                        ...updatedEle.properties,
                        downTransKind: Number(getElementKindValue(updatedEle.properties.downTransKind)),
                        upTransKind: Number(getElementKindValue(updatedEle.properties.upTransKind))
                    })
                    break;
                case MS_FUSE:
                case MS_HVCB:
                    params = convertUndefinedToNull({
                        ...updatedEle.properties, 
                        downTransKind: Number(getElementKindValue(updatedEle.properties.downTransKind))
                    })
                    break;
                case MS_3WINDING:
                    params = {
                        no3Winding: updatedEle.properties.no3Winding,
                        refNo: updatedEle.properties.refNo,
                        checkNG: updatedEle.properties.checkNG,
                        eleNeutralPointNumber: updatedEle.properties.eleNeutralPointNumber,
                        supply: updatedEle.properties.supply,
                        tcName: updatedEle.properties.tcName,
                        exchangeNumber: updatedEle.properties.symKind,
                        makeDataInput: updatedEle.properties.makeDataInput,
                        makerName: updatedEle.properties.makerName,
                        makeYear: updatedEle.properties.makeYear,
                        makeMonth: updatedEle.properties.makeMonth,
                        makeNumber: updatedEle.properties.makeNumber,
                        type: updatedEle.properties.type,
                        viewFlowResult: updatedEle.properties.viewFlowResult,
                        primarySupply: updatedEle.properties.primarySupply,
                        seriesed: updatedEle.properties.seriesed,
                        viewResultText: updatedEle.properties.viewResultText,
                        partPrimary: {...updatedEle.properties.partPrimary},
                        partSecondary: {...updatedEle.properties.partSecondary},
                        partThird: {...updatedEle.properties.partThird}, 
                    } 
                    break;
                case MS_TRANSCENTER:
                    params = convertUndefinedToNull({
                        ...updatedEle.properties, 
                        upTransKind: Number(getElementKindValue(updatedEle.properties.upTransKind))
                    })
                    break;
                case MS_WIRE:
                    params = {
                    noWire:updatedEle.properties.noWire,
                    refNo:updatedEle.properties.refNo,
                    sPhase:updatedEle.properties.sPhase,
                    rotate:updatedEle.properties.rotate,
                    voltage:updatedEle.properties.voltage,
                    fault:updatedEle.properties.fault,
                    dropRegular:updatedEle.properties.dropRegular,
                    checkNG:updatedEle.properties.checkNG,
                    wireSystem:updatedEle.properties.wireSystem,
                    material:updatedEle.properties.material,
                    insulation:updatedEle.properties.insulation,
                    core:updatedEle.properties.core,
                    method:updatedEle.properties.method,
                    circuitNumber:updatedEle.properties.circuitNumber,
                    ambientTemp:updatedEle.properties.ambientTemp,
                    length:updatedEle.properties.length,
                    dropMotor:updatedEle.properties.dropMotor,
                    eleLineNumber:updatedEle.properties.eleLineNumber,
                    eleTopLeftNumber:updatedEle.properties.eleTopLeftNumber,
                    eleBottomRightNumber:updatedEle.properties.eleBottomRightNumber,
                    perUnitR:updatedEle.properties.perUnitR,
                    perUnitX:updatedEle.properties.perUnitX,
                    perUnitK:updatedEle.properties.perUnitK,
                    lineActiveFlow1:updatedEle.properties.lineActiveFlow1,
                    lineReactiveFlow1:updatedEle.properties.lineReactiveFlow1,
                    lineActiveFlow2:updatedEle.properties.lineActiveFlow2,
                    lineReactiveFlow2:updatedEle.properties.lineReactiveFlow2,
                    exchangeNumber:updatedEle.properties.exchangeNumber,
                    upTransKind:updatedEle.properties.upTransKind,
                    supply:updatedEle.properties.supply,
                    tcName:updatedEle.properties.tcName,
                    makeDataInput:updatedEle.properties.makeDataInput,
                    makerName:updatedEle.properties.makerName,
                    makeYear:updatedEle.properties.makeYear,
                    makeMonth:updatedEle.properties.makeMonth,
                    type:updatedEle.properties.type,
                    voltageMagnitude1:updatedEle.properties.voltageMagnitude1,
                    voltageAngle1:updatedEle.properties.voltageAngle1,
                    voltageMagnitude2:updatedEle.properties.voltageMagnitude2,
                    voltageAngle2:updatedEle.properties.voltageAngle2,
                    pointText:updatedEle.properties.pointText,
                    viewFlowResult:updatedEle.properties.viewFlowResult,
                    inputedCapacity1:updatedEle.properties.inputedCapacity1,
                    inputedCurrent1:updatedEle.properties.inputedCurrent1,
                    inputedPowerFactor1:updatedEle.properties.inputedPowerFactor1,
                    inputedCapacity2:updatedEle.properties.inputedCapacity2,
                    inputedCurrent2:updatedEle.properties.inputedCurrent2,
                    inputedPowerFactor2:updatedEle.properties.inputedPowerFactor2,
                    downTransKind:updatedEle.properties.downTransKind,
                    primarySupply:updatedEle.properties.primarySupply,
                    examineComment:updatedEle.properties.examineComment,
                    examineCommentSPhase:updatedEle.properties.examineCommentSPhase,
                    seriesed:updatedEle.properties.seriesed,
                    viewResultText:updatedEle.properties.viewResultText,
                    voltDropSumRe1:updatedEle.properties.voltDropSumRe1,
                    voltDropSumIm1:updatedEle.properties.voltDropSumIm1,
                    voltDropSumRe2:updatedEle.properties.voltDropSumRe2,
                    voltDropSumIm2:updatedEle.properties.voltDropSumIm2,
                    voltDropRouteRefNo:updatedEle.properties.voltDropRouteRefNo,
                    currentTopLeft:updatedEle.properties.currentTopLeft,
                    flowSusceptance:updatedEle.properties.flowSusceptance,
                    _3Phases:updatedEle.properties._3Phases,
                    PhaseN:updatedEle.properties.PhaseN,
                    PhasePE:updatedEle.properties.PhasePE,
                    calcPoint0:updatedEle.properties.calcPoint0,
                    calcPoint1:updatedEle.properties.calcPoint1,
                    }
                    break;
                case MS_BUSBAR:
                case MS_BUSDUCT:
                case MS_CONTACTOR:
                case MS_REACTOR:
                case MS_IMPEDANCE:
                    params = {...updatedEle.properties, rotation: updatedEle.rotation}
                    break;
                default:
                    params = convertUndefinedToNull({...updatedEle.properties})
                    break;
            }
            if(params.details)
            {
                params = {
                    ...params,
                    details: convertAdjustToCallAPI(structuredClone(params.details))
                  }
            }

            const request = { 
              userId: userId,
              projectId: projectData.projectId,
              elementType: getElementKindValue(updatedEle.type),
              elementId: updatedEle.id,
              param: {...params},
              ownerProject: projectData.createUserId
            }
            setLoadingFlag(true);
            updateElementPost(request, updatedEle.id);
        } 

        handleUpdatePropertiesOfCurve(data)

        if(state)
        {
            handleUpdateRelatedGraph(data, true);
        }
    }

    const handleUpdateRelatedGraph = (data: DatasetEditForm, state: boolean) => {
        const { 
            relatedGraphId,
            controlId, 
            datasetId, 
            relatedGraph : { colorNormal, kind, width, dispBand },
            refNo,
            relatedGraphText : { colorFont, dispLinkLine, dispRefV2, dispTextFree, faceName, fontSize, italic, strikeOut, textFixed, textFree, underline, weight, pointTopLeftX, pointTopLeftY, pointOldX , pointOldY},
            ...details
        } = data

        let checkEle = chartDataList.find(x=>x.tabId === currentIDChartTab)?.shape.find(shapes => shapes.id === controlId)
        
        if(checkEle && (checkEle.type === MS_FUSE || checkEle.type === MS_LVCB || checkEle.type === MS_THERMAL || checkEle.type === MS_THERMAL_CT ) && checkEle.chart.find(x=>Number(x.relatedGraphId) === Number(relatedGraphId))?.lineType !== LineType.USER_CURVE)
        {
            let arr: any[] = []
            checkEle.chart.filter((x:any)=> x.lineType !== LineType.USER_CURVE)
            checkEle.chart.map((x:any)=>{
                let params = {
                    userId: userId,
                    projectId : Number(storeProjectId),
                    relatedGraphId : Number(x.relatedGraphId),
                    dispBand: dispBand,
                    colorNormal : colorNormal,
                    kind : 0,
                    width : width,
                    ownerProject: projectData.createUserId
                }
        
                switch(kind.toString()) {
                    case '':
                        params.kind = 0;
                        break;
                    case '25,5':
                        params.kind = 1;
                        break;
                    case '5,5':
                        params.kind = 2;
                        break;
                    case '10,5,5,5':
                        params.kind = 3;
                        break;
                    case '10,5,5,5,5,5':
                        params.kind = 4;
                        break;
                    default:
                        params.kind = 0;
                        break;
                }

                arr.push(params)
            })
            setLoadingFlag(true)
            updateRelatedGraph(arr);
        }
        else if(checkEle && (checkEle.type === MS_3WINDING || checkEle.type === MS_TRANS1 || checkEle.type === MS_TRANS3 || checkEle.type === MS_TRANSSCOTT))
        {
            let arr: any[] = []
            let tempDataset:string = ''
            if(checkEle.type === MS_3WINDING)
            {
                if(datasetId === 'INRUSH_PART_2')
                {
                    tempDataset = 'INTENSITY_PART_2'
                }
                else if(datasetId === 'INTENSITY_PART_2')
                {
                    tempDataset = 'INRUSH_PART_2'
                }
                else if(datasetId === 'INRUSH_PART_3')
                {
                    tempDataset = 'INTENSITY_PART_3'
                }
                else if(datasetId === 'INTENSITY_PART_3')
                {
                    tempDataset = 'INRUSH_PART_3'
                }
            }

            if(checkEle.type === MS_TRANS1)
            {
                if(datasetId === 'INRUSH_OUT_IN')
                {
                    tempDataset = 'INTENSITY_OUT_IN'
                }
                else if(datasetId === 'INTENSITY_OUT_IN')
                {
                    tempDataset = 'INRUSH_OUT_IN'
                }
                else if(datasetId === 'INRUSH_OUT_OUT')
                {
                    tempDataset = 'INTENSITY_OUT_OUT'
                }
                else if(datasetId === 'INTENSITY_OUT_OUT')
                {
                    tempDataset = 'INRUSH_OUT_OUT'
                }
            }
        
            if(checkEle.type === MS_TRANS3)
            {
                if(datasetId === 'INRUSH')
                {
                    tempDataset = 'INTENSITY'
                }
                else if(datasetId === 'INTENSITY')
                {
                    tempDataset = 'INRUSH'
                }
            }

            if(checkEle.type === MS_TRANSSCOTT)
            {
                if(datasetId === 'INRUSH_1')
                {
                    tempDataset = 'INTENSITY_1'
                }
                else if(datasetId === 'INTENSITY_1')
                {
                    tempDataset = 'INRUSH_1'
                }
                else if(datasetId === 'INRUSH_2')
                {
                    tempDataset = 'INTENSITY_2'
                }
                else if(datasetId === 'INTENSITY_2')
                {
                    tempDataset = 'INRUSH_2'
                }
            }
            checkEle.chart.filter(x=>x.lineId === datasetId || x.lineId === tempDataset).map((x:any)=>{
                let params = {
                    userId: userId,
                    projectId : Number(storeProjectId),
                    relatedGraphId : Number(x.relatedGraphId),
                    dispBand: dispBand,
                    colorNormal : colorNormal,
                    kind : 0,
                    width : width,
                    ownerProject: projectData.createUserId

                }
        
                switch(kind.toString()) {
                    case '':
                        params.kind = 0;
                        break;
                    case '25,5':
                        params.kind = 1;
                        break;
                    case '5,5':
                        params.kind = 2;
                        break;
                    case '10,5,5,5':
                        params.kind = 3;
                        break;
                    case '10,5,5,5,5,5':
                        params.kind = 4;
                        break;
                    default:
                        params.kind = 0;
                        break;
                }

                arr.push(params)
            })
            setLoadingFlag(true)
            updateRelatedGraph(arr);
        }
        else
        {
            let params = {
                userId: userId,
                projectId : Number(storeProjectId),
                relatedGraphId : Number(relatedGraphId),
                dispBand: dispBand,
                colorNormal : colorNormal,
                kind : 0,
                width : width,
                ownerProject: projectData.createUserId
            }
    
            switch(kind.toString()) {
                case '':
                    params.kind = 0;
                    break;
                case '25,5':
                    params.kind = 1;
                    break;
                case '5,5':
                    params.kind = 2;
                    break;
                case '10,5,5,5':
                    params.kind = 3;
                    break;
                case '10,5,5,5,5,5':
                    params.kind = 4;
                    break;
                default:
                    params.kind = 0;
                    break;
            }
            setLoadingFlag(true)
            updateRelatedGraph([params]);
        }

        handleUpdatePropertiesOfCurve(data)

        setDataUpdateLine(data)
        setReloadHandle(true)

        if(state)
        {
            handleUpdateRelatedGraphText(data);
        }
    }

    const handleUpdateRelatedGraphText = (data : DatasetEditForm) => {
        const { 
            relatedGraphId,
            relatedGraphTextId,
            controlId, 
            datasetId, 
            relatedGraph : { colorNormal, kind, width, dispBand },
            refNo,
            relatedGraphText : { colorFont, dispLinkLine, dispRefV2, dispTextFree, faceName, fontSize, italic, strikeOut, textFixed, textFree, underline, weight, pointTopLeftX, pointTopLeftY, pointOldX , pointOldY},
        } = data

        let checkEle = chartDataList.find(x=>x.tabId === currentIDChartTab)?.shape.find(shapes => shapes.id === controlId)
        if(checkEle && (checkEle.type === MS_FUSE || checkEle.type === MS_LVCB || checkEle.type === MS_THERMAL || checkEle.type === MS_THERMAL_CT) && checkEle.chart.find(x=>Number(x.relatedGraphId) === Number(relatedGraphId))?.lineType !== LineType.USER_CURVE)
        {
            let arr: any[] = []
            checkEle.chart.filter((x:any)=> x.lineType !== LineType.USER_CURVE)
            checkEle.chart.map((x:any)=>{
                let params = {
                    userId: userId,
                    projectId : Number(storeProjectId),
                    relatedGraphId : Number(x.relatedGraphId),
                    relatedGraphTextId : Number(x.relatedGraphTextId),
                    colorFont : colorFont,
                    weight : weight,
                    italic : italic,
                    underline : underline,
                    strikeOut : strikeOut,
                    fontName : faceName,
                    dispRefV2 : dispRefV2,
                    dispTextFree : dispTextFree,
                    dispLinkLine: dispLinkLine,
                    textFree : textFree??textFixed,
                    fontSize : fontSize,
                    pointTopLeftX : pointTopLeftX,
                    pointTopLeftY : pointTopLeftY,
                    pointOldX : pointOldX,
                    pointOldY : pointOldY,
                    ownerProject: projectData.createUserId

                }
                arr.push(params)
            })
            setLoadingFlag(true)
            updateRelatedGraphText(arr);
        }
        else
        {
            let params = {
                userId: userId,
                projectId : Number(storeProjectId),
                relatedGraphId : Number(relatedGraphId),
                relatedGraphTextId : Number(relatedGraphTextId),
                colorFont : colorFont,
                weight : weight,
                italic : italic,
                underline : underline,
                strikeOut : strikeOut,
                fontName : faceName,
                dispRefV2 : dispRefV2,
                dispTextFree : dispTextFree,
                dispLinkLine: dispLinkLine,
                textFree : textFree??textFixed,
                fontSize : fontSize,
                pointTopLeftX : pointTopLeftX,
                pointTopLeftY : pointTopLeftY,
                pointOldX : pointOldX,
                pointOldY : pointOldY,
                ownerProject: projectData.createUserId

            }
            setLoadingFlag(true)
            updateRelatedGraphText([params]);
        }
        handleUpdatePropertiesOfCurve(data)
    }

    const handleUpdatePropertiesOfCurve = (data: DatasetEditForm) => {
        const { 
            relatedGraphId,
            controlId, 
            datasetId, 
            relatedGraph : { colorNormal, kind, width, dispBand },
            refNo,
            relatedGraphText : { colorFont, dispLinkLine, dispRefV2, dispTextFree, faceName, fontSize, italic, strikeOut, textFixed, textFree, underline, weight, pointTopLeftX, pointTopLeftY, pointOldX, pointOldY, minPointX, minPointY},
            ...details
        } = data

        let checkEle = chartDataList.find(x=>x.tabId === currentIDChartTab)?.shape.find(shapes => shapes.id === controlId)
        if(checkEle && checkEle.chart.find(x=>Number(x.relatedGraphId) === Number(relatedGraphId))?.lineType !== LineType.USER_CURVE)
        {
            checkEle.chart.filter((x:any)=> x.lineType !== LineType.USER_CURVE)
        }

        if(checkEle && (checkEle.type === MS_FUSE || checkEle.type === MS_LVCB || checkEle.type === MS_THERMAL || checkEle.type === MS_THERMAL_CT) && checkEle.chart.find(x=>Number(x.relatedGraphId) === Number(relatedGraphId))?.lineType !== LineType.USER_CURVE)
        {
            chartRef.current?.data.datasets.forEach((x:any)=> {
                if(x.lineType!== LineType.CONNECT_LINE && x.lineType !== LineType.USER_CURVE && Number(x.controlId) === Number(checkEle!.id)){
                    x.backgroundColor = colorNormal
                    x.borderColor = colorNormal
                    x.savedColor = colorNormal
                    x.borderDash = kind
                    x.borderWidth = width
                    x.dispBand = dispBand
                    x.dispRefV2 = dispRefV2
                    x.dispLinkLine = dispLinkLine
                    x.dispTextFree = dispTextFree
                    x.fontSize = fontSize
                    x.faceName = faceName
                    x.colorFont = colorFont
                    x.underline = underline
                    x.strikeOut = strikeOut
                    x.italic = italic
                    x.weight = weight
                    x.textFree = textFree??textFixed
                    x.text = dispTextFree? textFree??textFixed : textFixed
                    x.pointTopLeftX = pointTopLeftX
                    x.pointTopLeftY = pointTopLeftY
                    x.pointOldX = pointOldX
                    x.pointOldY = pointOldY
                    if(x.lineId.toString().includes("INTENSITY"))
                    {
                        x.pointBorderColor = colorNormal
                        x.pointBorderWidth = width
                    }
                    chartRef.current?.update('none')
            
                    //update line to store
                    updateLineInfo(
                        {   
                            backgroundColor: colorNormal,
                            borderColor: colorNormal,
                            borderDash: kind as any,
                            borderWidth: width,
                            dispBand: dispBand,
                            dispRefV2: dispRefV2,
                            dispLinkLine: dispLinkLine,
                            dispTextFree: dispTextFree,
                            fontSize: fontSize,
                            faceName : faceName,
                            colorFont : colorFont,
                            underline : underline,
                            strikeOut : strikeOut,
                            italic : italic,
                            weight : weight,
                            textFree: textFree??textFixed,
                            text: dispTextFree? textFree??textFixed : textFixed,
                            pointTopLeftX: pointTopLeftX,
                            pointTopLeftY: pointTopLeftY,
                            pointOldX: pointOldX,
                            pointOldY: pointOldY,
                            minPointX: minPointX,
                            minPointY: minPointY
                        }, 
                        x.lineId, 
                        controlId
                    )
    
                    listTextOfLinesInTab.forEach(list=>{
                        if(Number(list.id) === Number(x.relatedGraphId) && list.graphNo === currentIDChartTab) {
                            list.fontSize =  fontSize
                            list.faceName = faceName
                            list.colorFont = colorFont
                            list.underline = underline
                            list.strikeOut = strikeOut
                            list.italic = italic
                            list.weight = weight
                            list.text = dispTextFree? (textFree? textFree.split('\n') : list.text) : (textFixed? textFixed.split('\n') : list.text)
                            list.isHideText = dispRefV2? false : true
                            list.isHideConnectLine = dispRefV2 && dispLinkLine? false : true
                            list.isShowTextFree = dispTextFree ? true : false
                        }
                    })
                }
            })
            listTextOfLinesInTabStore.forEach(x=>{
                listTextOfLinesInTab.map(e=>{
                    if(Number(x.id) === Number(e.id) && Number(x.relatedGraphId) === Number(e.relatedGraphId) && x.graphNo === e.graphNo){
                        x = e;
                    }
                })
            })
        }
        else
        {
            const foundDataset = chartRef.current?.data.datasets.find((dataset : any) => dataset.lineId === datasetId) as any
            if (foundDataset) {
                foundDataset.backgroundColor = colorNormal
                foundDataset.borderColor = colorNormal
                foundDataset.savedColor = colorNormal
                foundDataset.borderDash = kind
                foundDataset.borderWidth = width
                foundDataset.dispBand = dispBand
                foundDataset.dispRefV2 = dispRefV2
                foundDataset.dispLinkLine = dispLinkLine
                foundDataset.dispTextFree = dispTextFree
                foundDataset.fontSize = fontSize
                foundDataset.faceName = faceName
                foundDataset.colorFont = colorFont
                foundDataset.underline = underline
                foundDataset.strikeOut = strikeOut
                foundDataset.italic = italic
                foundDataset.weight = weight
                foundDataset.textFree = textFree??textFixed
                foundDataset.text = dispTextFree? textFree??textFixed : textFixed
                foundDataset.pointTopLeftX = pointTopLeftX
                foundDataset.pointTopLeftY = pointTopLeftY
                foundDataset.pointOldX = pointOldX
                foundDataset.pointOldY = pointOldY
                if(foundDataset.lineId.toString().includes("INTENSITY") || (foundDataset.lineType == LineType.USER_CURVE && foundDataset.data.filter((item:any)=>item.x != 0 && item.y !=0).length == 1))
                {
                    foundDataset.pointBorderColor = colorNormal
                    foundDataset.pointBorderWidth = width
                }
                chartRef.current?.update('none')
            }

            if(foundDataset) 
            {
                let controlEle = chartDataList.find(x=>x.shape.find(shapes=>Number(shapes.id) === Number(foundDataset.controlId)))?.shape.find(shapes=>Number(shapes.id) === Number(foundDataset.controlId));
                let tempDataset:string = ''
                if(controlEle && controlEle.type === MS_3WINDING)
                {
                    if(foundDataset.lineId === 'INRUSH_PART_2')
                    {
                        tempDataset = 'INTENSITY_PART_2'
                    }
                    else if(foundDataset.lineId === 'INTENSITY_PART_2')
                    {
                        tempDataset = 'INRUSH_PART_2'
                    }
                    else if(foundDataset.lineId === 'INRUSH_PART_3')
                    {
                        tempDataset = 'INTENSITY_PART_3'
                    }
                    else if(foundDataset.lineId === 'INTENSITY_PART_3')
                    {
                        tempDataset = 'INRUSH_PART_3'
                    }
                }

                if(controlEle && controlEle.type === MS_TRANS1)
                {
                    if(foundDataset.lineId === 'INRUSH_OUT_IN')
                    {
                        tempDataset = 'INTENSITY_OUT_IN'
                    }
                    else if(foundDataset.lineId === 'INTENSITY_OUT_IN')
                    {
                        tempDataset = 'INRUSH_OUT_IN'
                    }
                    else if(foundDataset.lineId === 'INRUSH_OUT_OUT')
                    {
                        tempDataset = 'INTENSITY_OUT_OUT'
                    }
                    else if(foundDataset.lineId === 'INTENSITY_OUT_OUT')
                    {
                        tempDataset = 'INRUSH_OUT_OUT'
                    }
                }
            
                if(controlEle && controlEle.type === MS_TRANS3)
                {
                    if(foundDataset.lineId === 'INRUSH')
                    {
                        tempDataset = 'INTENSITY'
                    }
                    else if(foundDataset.lineId === 'INTENSITY')
                    {
                        tempDataset = 'INRUSH'
                    }
                }

                if(controlEle && controlEle.type === MS_TRANSSCOTT)
                {
                    if(foundDataset.lineId === 'INRUSH_1')
                    {
                        tempDataset = 'INTENSITY_1'
                    }
                    else if(foundDataset.lineId === 'INTENSITY_1')
                    {
                        tempDataset = 'INRUSH_1'
                    }
                    else if(foundDataset.lineId === 'INRUSH_2')
                    {
                        tempDataset = 'INTENSITY_2'
                    }
                    else if(foundDataset.lineId === 'INTENSITY_2')
                    {
                        tempDataset = 'INRUSH_2'
                    }
                }
                
                if(tempDataset && tempDataset !== '')
                {
                    const changeDataset = chartRef.current?.data.datasets.find((dataset : any) => dataset.lineId === tempDataset) as any
                    if(changeDataset)
                    {
                        changeDataset.pointBorderColor = colorNormal
                        changeDataset.pointBorderWidth = width                
                        changeDataset.backgroundColor = colorNormal
                        changeDataset.borderColor = colorNormal
                        changeDataset.savedColor = colorNormal
                        changeDataset.borderWidth = width
                        foundDataset.borderDash = kind
                        chartRef.current?.update('none')
    
                        updateLineInfo(
                            {   
                                backgroundColor: changeDataset.backgroundColor,
                                borderColor: changeDataset.borderColor,
                                borderDash: changeDataset.borderDash as any,
                                borderWidth: changeDataset.borderWidth,
                                dispBand: changeDataset.dispBand,
                                dispRefV2: changeDataset.dispRefV2,
                                dispLinkLine: changeDataset.dispLinkLine,
                                dispTextFree: changeDataset.dispTextFree,
                                fontSize: changeDataset.fontSize,
                                faceName : changeDataset.faceName,
                                colorFont : changeDataset.colorFont,
                                underline : changeDataset.underline,
                                strikeOut : changeDataset.strikeOut,
                                italic : changeDataset.italic,
                                weight : changeDataset.weight,
                                textFree: changeDataset.textFree,
                                text: changeDataset.text,
                                pointTopLeftX: changeDataset.pointTopLeftX,
                                pointTopLeftY: changeDataset.pointTopLeftY,
                                pointOldX: changeDataset.pointOldX,
                                pointOldY: changeDataset.pointOldY,
                                minPointX: changeDataset.minPointX,
                                minPointY: changeDataset.minPointY,
                            }, 
                            changeDataset.lineId, 
                            changeDataset.controlId
                        )
                    }
                }
            }

            //update line to store
            updateLineInfo(
                {   
                    backgroundColor: colorNormal,
                    borderColor: colorNormal,
                    borderDash: kind as any,
                    borderWidth: width,
                    dispBand: dispBand,
                    dispRefV2: dispRefV2,
                    dispLinkLine: dispLinkLine,
                    dispTextFree: dispTextFree,
                    fontSize: fontSize,
                    faceName : faceName,
                    colorFont : colorFont,
                    underline : underline,
                    strikeOut : strikeOut,
                    italic : italic,
                    weight : weight,
                    textFree: textFree??textFixed,
                    text: dispTextFree? textFree??textFixed : textFixed,
                    pointTopLeftX: pointTopLeftX,
                    pointTopLeftY: pointTopLeftY,
                    pointOldX: pointOldX,
                    pointOldY: pointOldY,
                    minPointX: minPointX,
                    minPointY: minPointY,
                }, 
                datasetId, 
                controlId
            )

            listTextOfLinesInTab.forEach(x=>{
                if(Number(x.id) === Number(relatedGraphId) && x.graphNo === currentIDChartTab) {
                    x.fontSize =  fontSize
                    x.faceName = faceName
                    x.colorFont = colorFont
                    x.underline = underline
                    x.strikeOut = strikeOut
                    x.italic = italic
                    x.weight = weight
                    x.text = dispTextFree? (textFree? textFree.split('\n') : x.text) : (textFixed? textFixed.split('\n') : x.text)
                    x.isHideText = dispRefV2? false : true
                    x.isHideConnectLine = dispRefV2 && dispLinkLine? false : true
                    x.isShowTextFree = dispTextFree ? true : false
                }
            })
            listTextOfLinesInTabStore.forEach(x=>{
                listTextOfLinesInTab.map(e=>{
                    if(Number(x.id) === Number(e.id) && Number(x.relatedGraphId) === Number(e.relatedGraphId) && e.graphNo === x.graphNo){
                        x = e;
                    }
                })
            })
        }
        setDrawConnectLines(dispRefV2 && dispLinkLine)
    }

    const handleSuccessCreateRelatedGraph = (data:any) => {
        if(reloadHandle && !isUndoGraph)
        {
            const shape : ControlModel[] = []
            data?.data.map((x: {random_temp_id:string, related_graph_id:number,related_graph_text_id:number,point_old_x:number,point_old_y:number,point_top_left_x:number,point_top_left_y:number}) => {
                const chart = chartDataList.find(x=>x.tabId === currentIDChartTab)?.shape.map(shapes=> shapes.chart.find(charts=>charts.relatedGraphId === x.random_temp_id.toString())).find(charts=>charts?.relatedGraphId === x.random_temp_id.toString())
                if(chart)
                {
                    const lineInfo = chart?.tabMapping.get(currentIDChartTab)
                    if(lineInfo)
                    {
                        updateLineInfo(
                            {   
                                ...lineInfo,
                                pointTopLeftX: x.point_top_left_x,
                                pointTopLeftY: x.point_top_left_y,
                                pointOldX: x.point_old_x,
                                pointOldY: x.point_old_y
                            }, 
                            chart?.lineId!, 
                            chart?.controlId!, 
                        )
                    }

                }
                setChartId(x.random_temp_id, x.related_graph_id.toString(), x.related_graph_text_id.toString(), currentIDChartTab)

                let temp = chartDataList.find(x=>x.tabId=== currentIDChartTab)?.shape.find(shapes=> shapes.chart.find(charts=>charts.relatedGraphId === x.random_temp_id.toString()))
                if(temp && !shape.find(x=> Number(x.id) === Number(temp?.id))) {
                    shape.push(temp)
                }

            })
            let checkShape = chartDataList.find(x=>x.tabId=== currentIDChartTab)?.shape

            checkShape && checkShape.map((shapes:ControlModel) => {
                if(shape.find(x=> Number(x.id) === Number(shapes.id)) === undefined){
                    shape.push(shapes)
                }
            })
            
            shape && handleSetTextLines(shape, 0, data?.data)
            setReloadConnectLines(!reloadConnectLines)

            if(!isAddNewGraph && data.data)
            {
                setUndoData({type:"ADD_RELATED_GRAPH",dataUndo: data.data} as Model.UndoModel)
            }
            
            publishChartEvents({ event: 'SELECT_CONTROLS', payload: {id:shapes.map((shape : any) => shape.id),state:"select"}})

            //save graph
            let temp = store.getState().app.diagram.chartData.find(x => x.tabId === currentIDChartTab);
    
            let dNominalVolt = temp?.nominalVolt ?? 0;
            let dZoom = temp?.zoom ?? 100;
            let bSociety = temp?.isJapaneseElectricMode ?? false;
            let param = {
                userId: userId,
                projectId : storeProjectId,
                graphNo : currentIDChartTab,
                nominalVolt : dNominalVolt,
                zoom : dZoom,
                society : bSociety,
                ownerProject: projectData.createUserId

            }
            updateGraph(param);
            setReloadHandle(false)
        }
        setIsUndoGraph(false)
        setLoadChart(false)
    }

    const handleFinishUndoRelatedGraph = () => {
        setLoadChart(false)
    }

    const handleErrorCreateRelatedGraph = (success:any, data:any, error:any) => {
        setLoadingFlag(false)
    }

    const handleUpdateScaleGraph = (data:SettingUpGraphScale) => {
        setOpenSetUpScaleGraph(false);
        setLoadingFlag(true)        // TODO: FIX HARD CODE       

        const params = {
            projectId:storeProjectId,
            userId:userId,
            data:{
                ...currentGraphData,
                normalXMin: Number(data.scaleXMin),
                normalXMax: Number(data.scaleXMax),
                normalYMin: Number(data.scaleYMin),
                normalYMax: Number(data.scaleYMax),
            },
            ownerProject: projectData.createUserId
        }
        updateGraphScale(params)
    }

    const handleSuccessUpdateScaleGraph = (data:any) => {
        setLoadingFlag(false);
        if(data && data.resultCode == 0){
            if(undoFlag){
                setUndoData({type:null,dataUndo:null} as Model.UndoModel)
                setUndoFlag(false);
            }else{
                setUndoData({type:"UPDATE_SCALE_GRAPH",dataUndo:{}} as Model.UndoModel)
            }
            const mappedProject = mapProjectDataFromResponse(data.data.project);
            saveOpenProjectData(mappedProject);
        }
    }

    const handleErrorScaleGraph = (success:any, data:any, error:any) => {
        setLoadingFlag(false);
    }

    const handleClickMsText = (e:any) => {
        if(e.shiftKey != true || isSelectTouchGroup != true){
            const newList = listObjectText;
            newList.forEach((item:any) => item.isSelected = false);
            const result = newList.find((obj:any) => {
                return "text_"+obj.id == e.target.id;
            })
            result.isSelected = true;
            setListObjectText([...newList]);
    
            // unselect control
            unSelectAllControls()
            publishChartEvents({ event: 'SELECT_CONTROLS', payload: {id:undefined,state:"select"}})
            chartRef.current?.data.datasets.forEach((dataset : any) => {
                if (dataset.lineType === LineType.MEASUREMENT_LINE || dataset.lineType === LineType.CONNECT_LINE) return
                dataset.backgroundColor = dataset.savedColor
                dataset.borderColor = dataset.savedColor
            })
            chartRef.current?.update('none')     
            
            setNotSelectListTextOfLinesInTab()
            setListShowIntersectionPointText([])
        }else{
            const newList = listObjectText;
            const result = newList.find((obj:any) => {
                return "text_"+obj.id == e.target.id;
            })
            result.isSelected = true;
            setListObjectText([...newList]);
        }
    }

    const handleDragStart =  (event:any, element?:any) => {
        if(isSelectTouchGroup) return
        // unselect control
        unSelectAllControls()
        publishChartEvents({ event: 'SELECT_CONTROLS', payload: {id:[],state:"select"}})
        chartRef.current?.data.datasets.forEach((dataset : any) => {
            if (dataset.lineType === LineType.MEASUREMENT_LINE) return
            dataset.backgroundColor = dataset.savedColor
            dataset.borderColor = dataset.savedColor
        })
        chartRef.current?.update('none')     

        element && !element.type && element.type !== 'graphText' && setUndoData({type:"SET_POSITION_FOR_GRAPH_LABREL",dataUndo:{...element, pointTopLeftX: element.x, pointTopLeftY: element.y, pointOldX: 0, pointOldY: 0}} as Model.UndoModel)

        element && element.type && element.type == 'graphText' && setUndoData({type:"SET_POSITION_FOR_GRAPH_LABREL",dataUndo:{...element, pointTopLeftX: element.pointTopLeftX, pointTopLeftY: element.pointTopLeftX, pointOldX: 0, pointOldY: 0}} as Model.UndoModel)

        element && !element.type && element.type !== 'graphText' && handleClickOnChart(event, element)

        setContextMenu({ visible: false, mode: false, type: false, x: 0, y: 0 });
        setCurrentSelectedId(event.target.id);
        const rect = event.target.getBoundingClientRect();
        // offset top-left div and pointer
        const offsetX = event.clientX - rect.left;
        const offsetY = event.clientY - rect.top;

        event.dataTransfer.setData("text/plain", JSON.stringify({ offsetX, offsetY }));
    }
    
    const handleOnDrop = (event:any) => {
        const data = event.dataTransfer.getData("text/plain");
        // offset top-left div and pointer
        const scrollLeft = lineDiagramRef.current.scrollLeft;
        const scrollTop = lineDiagramRef.current.scrollTop;
        const { offsetX, offsetY } = JSON.parse(data);
        const BB = lineDiagramRef.current.getBoundingClientRect(),
            // offset div vs line
            offsetX1 = BB.left,
            offsetY1 = BB.top;
        const x = event.clientX + scrollLeft - offsetX1 - offsetX;
        const y = event.clientY + scrollTop - offsetY1 - offsetY;
        let newList = listObjectText;

        newList.forEach((item:any) => item.isSelected = false);
        let result = newList.find((obj:any) => {
            return "text_"+obj.id == currentSelectedId;
        })
        if(result === undefined)
        {
            newList = listTextOfLinesInTab;
            result = newList.find((obj:any) => {
                return "line_"+obj.id == currentSelectedId && obj.graphNo === currentIDChartTab;
            })

            const minPoint = handleCalcPointMin(result,x,y)

            const ctx = chartRef.current as any
            const dataX = ctx.scales.x.getValueForPixel(x);
            const dataY = ctx.scales.y.getValueForPixel(y);
            const dataMinX = ctx.scales.x.getValueForPixel(minPoint.x);
            const dataMinY = ctx.scales.y.getValueForPixel(minPoint.y);
            result.x = dataX;
            result.y = dataY;
            result.pointConnectLineX = dataMinX;
            result.pointConnectLineY = dataMinY;
            setPositionForGraphLabel({relatedGraphId: result.relatedGraphId, relatedGraphTextId: result.id, pointTopLeftX: result.x, pointTopLeftY: result.y, pointOldX: 0, pointOldY: 0, minPointX: result.pointConnectLineX, minPointY: result.pointConnectLineY})
            setListTextOfLinesInTab([...newList]);
            listTextOfLinesInTabStore.forEach(x=>{
                newList.map((e:any)=>{
                    if(Number(x.id) === Number(e.id) && Number(x.relatedGraphId) === Number(e.relatedGraphId) && x.graphNo === e.graphNo){
                        x = e;
                    }
                })
            })
            setDrawConnectLines(!drawConnectLines)
        }
        else
        {
            result.isSelected = true;
            const ctx = chartRef.current as any
            const dataX = ctx.scales.x.getValueForPixel(x);
            const dataY = ctx.scales.y.getValueForPixel(y);
            result.x = dataX;
            result.y = dataY;
            result.pointTopLeftX = dataX;
            result.pointTopLeftY = dataY;
            onUpdateGraphText({...result,userId: userId,projectId:storeProjectId,ownerProject: projectData.createUserId});
    
            setListObjectText([...newList]);
        }
    }

    let onlongtouch:any; 
    let timer:any;
    let touchduration = 500;

    const handleOnTouchStart =  (event:any, element?:any, isText?:boolean) => {
        if(!isSelectTouchGroup)
        {
            if (!timer) {
                timer = setTimeout(onlongtouch(event,element), touchduration);
            }
            unSelectAllControls()
            publishChartEvents({ event: 'SELECT_CONTROLS', payload: {id:[],state:"select"}})
            chartRef.current?.data.datasets.forEach((dataset : any) => {
                if (dataset.lineType === LineType.MEASUREMENT_LINE) return
                dataset.backgroundColor = dataset.savedColor
                dataset.borderColor = dataset.savedColor
            })
            chartRef.current?.update('none')     

            element && !element.type && element.type !== 'graphText' && setUndoData({type:"SET_POSITION_FOR_GRAPH_LABREL",dataUndo:{...element, pointTopLeftX: element.x, pointTopLeftY: element.y, pointOldX: 0, pointOldY: 0}} as Model.UndoModel)

            element && element.type && element.type == 'graphText' && setUndoData({type:"SET_POSITION_FOR_GRAPH_LABREL",dataUndo:{...element, pointTopLeftX: element.pointTopLeftX, pointTopLeftY: element.pointTopLeftX, pointOldX: 0, pointOldY: 0}} as Model.UndoModel)

            element && !element.type && element.type !== 'graphText' && handleClickOnChart(event, element)

            setContextMenu({ visible: false, mode: false, type: false, x: 0, y: 0 });
            if(isText)
            {
                setCurrentSelectedId(event.targetTouches[0].target.id);
            }
            else
            {
                setCurrentSelectedId(event.targetTouches[0].target.offsetParent.id);
            }

            const rect = isText? document.getElementById(event.targetTouches[0].target.id)?.getBoundingClientRect() : document.getElementById(event.targetTouches[0].target.offsetParent.id)?.getBoundingClientRect() ?? event.target.getBoundingClientRect();
            // offset top-left div and pointer
            const offsetX = event.targetTouches[0].screenX - rect.left;
            const offsetY = event.targetTouches[0].screenY - rect.top;
            setDataTransferTouch([offsetX,offsetY])
        }
    }

    onlongtouch = function(event:any, element:any) { 
        timer = null;
        handleOnTouchContextMenu(event,element)
    };
    
    const handleOnTouchEnd = (event:any, element?:any) => {
        if(isSelectTouchGroup) return
        if (timer) {
            clearTimeout(timer);
            timer = null;
        }
        if(isTouchMove)
        {
            const scrollLeft = lineDiagramRef.current.scrollLeft;
            const scrollTop = lineDiagramRef.current.scrollTop;
            const offsetX = dataTransferTouch[0]
            const offsetY = dataTransferTouch[1]
            const BB = lineDiagramRef.current.getBoundingClientRect(),
                // offset div vs line
                offsetX1 = BB.left,
                offsetY1 = BB.top;
            const x = event.changedTouches[0].screenX  + scrollLeft - offsetX1 - offsetX;
            const y = event.changedTouches[0].screenY + scrollTop - offsetY1 - offsetY;
            let newList = listObjectText;

            newList.forEach((item:any) => item.isSelected = false);
            let result = newList.find((obj:any) => {
                return "text_"+obj.id == currentSelectedId;
            })
            if(result === undefined)
            {
                newList = listTextOfLinesInTab;
                result = newList.find((obj:any) => {
                    return "line_"+obj.id == currentSelectedId && obj.graphNo === currentIDChartTab;
                })

                const minPoint = handleCalcPointMin(result,x,y)

                const ctx = chartRef.current as any
                const dataX = ctx.scales.x.getValueForPixel(x);
                const dataY = ctx.scales.y.getValueForPixel(y);
                const dataMinX = ctx.scales.x.getValueForPixel(minPoint.x);
                const dataMinY = ctx.scales.y.getValueForPixel(minPoint.y);
                result.x = dataX;
                result.y = dataY;
                result.pointConnectLineX = dataMinX;
                result.pointConnectLineY = dataMinY;
                setPositionForGraphLabel({relatedGraphId: result.relatedGraphId, relatedGraphTextId: result.id, pointTopLeftX: result.x, pointTopLeftY: result.y, pointOldX: 0, pointOldY: 0, minPointX: result.pointConnectLineX, minPointY: result.pointConnectLineY})
                setListTextOfLinesInTab([...newList]);
                listTextOfLinesInTabStore.forEach(x=>{
                    newList.map((e:any)=>{
                        if(Number(x.id) === Number(e.id) && Number(x.relatedGraphId) === Number(e.relatedGraphId) && x.graphNo === e.graphNo){
                            x = e;
                        }
                    })
                })
                setDrawConnectLines(!drawConnectLines)
            }
            else 
            {
                result.isSelected = true;
                const ctx = chartRef.current as any
                const dataX = ctx.scales.x.getValueForPixel(x);
                const dataY = ctx.scales.y.getValueForPixel(y);
                result.x = dataX;
                result.y = dataY;
                result.pointTopLeftX = dataX;
                result.pointTopLeftY = dataY;
                onUpdateGraphText({...result,userId: userId,projectId:storeProjectId,ownerProject: projectData.createUserId});
        
                setListObjectText([...newList]);
            }
            setIsTouchMove(false)
        }
        else
        {
            let now = new Date().getTime();
            let timeSince = now - timeDoubleTap;
            if((timeSince < 500) && (timeSince > 0)){
                if(element)
                {
                    handleDoubleClick(event,element) 
                }
                else
                {
                    handleOpenTextEditor(event)
                }
            }
            else
            {
                setTimeDoubleTap(new Date().getTime());
            }
        }
    }

    const handleOnTouchMove = (event:any) => {
        setIsTouchMove(true)
    }

    const handleCalcToDrawConnectLinesWhenReload = () => {
        let newList = listTextOfLinesInTab
        if(newList.length != textLineStore.length)
        {
            setReDrawLine(!reDrawLine)
            return
        }
        let arrPosition:any[] = []
        for(let i = 0; i < newList.length; i++)
        {
            if(newList[i].graphNo == currentIDChartTab)
            {
                const x = getPixelForValue({x: newList[i].x, y: newList[i].y}).x
                const y = getPixelForValue({x: newList[i].x, y: newList[i].y}).y  
                const minPoint = handleCalcPointMin(newList[i],x,y)
                const ctx = chartRef.current as any
                const dataX = ctx.scales.x.getValueForPixel(x);
                const dataY = ctx.scales.y.getValueForPixel(y);
                const dataMinX = ctx.scales.x.getValueForPixel(minPoint.x);
                const dataMinY = ctx.scales.y.getValueForPixel(minPoint.y);
                newList[i].x = dataX;
                newList[i].y = dataY;
                newList[i].pointConnectLineX = dataMinX;
                newList[i].pointConnectLineY = dataMinY;
                arrPosition.push({relatedGraphId: newList[i].relatedGraphId, relatedGraphTextId: newList[i].id, pointTopLeftX: newList[i].x, pointTopLeftY: newList[i].y, pointOldX: 0, pointOldY: 0, minPointX: newList[i].pointConnectLineX, minPointY: newList[i].pointConnectLineY})
            }
            setListTextOfLinesInTab([...newList])
            let updateList = [...listTextOfLinesInTabStore]
            newList.map((item:any)=>{
                let temp = updateList.find(x=>Number(x.controlId) == Number(item.controlId) && Number(x.relatedGraphId) == Number(item.relatedGraphId) && Number(x.relatedGraphTextId) == Number(item.relatedGraphTextId) && x.lineId == item.lineId && x.lineType == item.lineType)
                temp && (updateList[updateList.indexOf(temp)] = item)
            })
            setListTextOfLinesInTabStore(updateList)

        }
        arrPosition.length > 0 && setPositionForListGraphLabel(arrPosition)
        setDrawConnectLines(!drawConnectLines)
    }

    const handleCreateGraphText = (data:any) => {
        setLoadingFlag(false);
        setUndoData({type:"CREATE_GRAPH_TEXT",dataUndo:data.data} as Model.UndoModel)
        if(data && data.resultCode == 0){
            setNoText(noText + 1);
            const objectText = currentMsText;
            if(objectText){
                objectText.isSelected= true;
                objectText.id = data.data["graphTextId"];
                objectText.graphNo = data.data["graphNo"];
                objectText.text = currentMsText["text"]
                objectText.fontSize = currentMsText["fontSize"]
                objectText.faceName = currentMsText["faceName"]
                objectText.colorFont = currentMsText["colorFont"]
                objectText.underline = currentMsText["underline"]
                objectText.strikeOut = currentMsText["strikeOut"]
                objectText.italic = currentMsText["italic"]
                objectText.weight = currentMsText["weight"]
                objectText.charSet = currentMsText["charSet"]
                objectText.x = currentMsText["pointTopLeftX"]
                objectText.y = currentMsText["pointTopLeftY"]
                const newList = setNotSelectListObjectText();
                setListObjectText([...newList,objectText]);
            }
        }
    }

    useEffect(() => {
        const indexCurrentSelected = listObjectText.findIndex((item:any) => item.isSelected == true);
        divRefs.current[indexCurrentSelected]?.focus();
    },[listObjectText])

    const setNotSelectListObjectText = () => {
        const newList = listObjectText;
        if(newList.length > 0){
            newList.forEach((item:any) => {
                item.isSelected = false;
            })
        }
        return newList;
    }

    const handleErrorCreateGraphText = (success:any,data:any,error:any) => {
        setLoadingFlag(false);
    }

    const handleUndo = () => {
        let params;
        if(undoData.type !== null && undoData.dataUndo !== null){
            setLoadingFlag(true);
            switch(undoData.type){
                case "SET_POSITION_FOR_GRAPH_LABREL":
                    if(undoData.dataUndo.type != 'graphText')
                    {
                        setPositionForGraphLabel(undoData);
                        let newList = listTextOfLinesInTab;
                        let result = newList.find((obj:any) => {
                            return obj.id == undoData.dataUndo.relatedGraphId && obj.graphNo === currentIDChartTab;
                        })
                        if(result)
                        {
                            result.x = undoData.dataUndo.x;
                            result.y = undoData.dataUndo.y;
                            result.pointConnectLineX = undoData.dataUndo.x;
                            result.pointConnectLineY = undoData.dataUndo.y;
                            setListTextOfLinesInTab([...newList]);
                            listTextOfLinesInTabStore.forEach(x=>{
                                newList.map((e:any)=>{
                                    if(Number(x.id) === Number(e.id) && Number(x.relatedGraphId) === Number(e.relatedGraphId) && x.graphNo === e.graphNo){
                                        x = e;
                                    }
                                })
                            })
                            setPositionForGraphLabel(undoData.dataUndo);
                            setDrawConnectLines(!drawConnectLines)
                        }
                    }
                    else
                    {
                        let newList = listObjectText;
                        newList.forEach((item:any) => item.isSelected = false);
                        let result = newList.find((obj:any) => {
                            return obj.id == undoData.dataUndo.id && obj.graphNo === currentIDChartTab;
                        })
                        if(result)
                        {
                            result.isSelected = true;
                            result.x = undoData.dataUndo.x;
                            result.y = undoData.dataUndo.y;
                            result.pointTopLeftX = undoData.dataUndo.x;
                            result.pointTopLeftY = undoData.dataUndo.y;
                            onUpdateGraphText({...result,userId: userId,projectId:storeProjectId,ownerProject: projectData.createUserId});
                    
                            setListObjectText([...newList]);
                        }
                    }
                    setLoadingFlag(false);
                    break;
                case "UPDATE_GRAPH_SCALE":
                    setUndoFlag(true);
                    params = {
                        userId: userId,
                        projectId: storeProjectId,
                        // defXMin: Number(undoData.dataUndo.project.def_x_min),
                        // defXMax: Number(undoData.dataUndo.project.def_x_max),
                        // defYMin: Number(undoData.dataUndo.project.def_y_min),
                        // defYMax: Number(undoData.dataUndo.project.def_y_max)
                        ownerProject: projectData.createUserId,
                        isUndo: true,
                    }
                    updateGraphScale(params);
                    break;
                case "CREATE_GRAPH_TEXT":
                    {
                        const element_delete = listObjectText.filter((item:any) => item.graphNo == undoData.dataUndo.graphNo && item.id == undoData.dataUndo.graphTextId);
                        if(element_delete.length > 0) {
                            const indexDelete = listObjectText.findIndex((item:any) => item.id == undoData.dataUndo.graphTextId);
                            listObjectText.splice(indexDelete,1);
                            setListObjectText(listObjectText);

                            let params = {
                                userId: userId,
                                projectId : storeProjectId,
                                listGraphTextId: element_delete.map((item:any) => {return item.id}),
                                graphNo:undoData.dataUndo.graphNo,
                                ownerProject: projectData.createUserId
                            }   
                            onDeleteGraphText(params);
                        }
                    }
                    setLoadingFlag(false);
                    break;
                case "DELETE_GRAPH_TEXT":
                    {
                        undoData.dataUndo.forEach((x:any)=>{
                            const params = {
                                userId: x.userId,
                                projectId: x.projectId,
                                graphNo: x.graphNo,
                                colorFont: x.colorFont,
                                text: x.text,
                                weight: x.weight,
                                italic: x.italic,
                                underline: x.underline,
                                strikeOut: x.strikeOut,
                                charSet: x.charSet,
                                faceName: x.faceName,
                                fontSize: x.fontSize,
                                pointTopLeftX: x.pointTopLeftX,
                                pointTopLeftY: x.pointTopLeftY,
                                ownerProject: projectData.createUserId
                            }
                            setCurrentMsText(params);
                            onCreateGraphText(params);
                            setLoadingFlag(true);
                        })
                    }
                    setLoadingFlag(false);
                    break;
                case "DELETE_RELATED_GRAPH":
                    {
                        setIsUndoGraph(true)
                        let arrayToSave :any[] = []
                        undoData.dataUndo?.chart.forEach((item:any)=>{
                            let temp = chartDataList.find(x=> x.tabId === currentIDChartTab)?.shape.find(x=>Number(x.id) === Number(item.controlId))?.chart
                            temp && temp.push(item)
                            let chart:any[] = temp && temp.length > 0 ? temp : [item]
                           saveChartIntoChartData(chart, currentIDChartTab, Number(item.controlId))
                           arrayToSave.push({data : chart, element : chartDataList.find(x=> x.tabId === currentIDChartTab)?.shape})
                        })
                        handleSaveRelatedGraph(arrayToSave , nominalVolt)

                        undoData.dataUndo?.text.forEach((item:any)=>{
                            listTextOfLinesInTab.push(item)
                            listTextOfLinesInTabStore.push(item)
                        })

                        setReDrawLine(!reDrawLine)
                    }
                    setLoadingFlag(false);
                    break;
                case "ADD_RELATED_GRAPH":
                    {
                        let arrRes: any[] = []
                        undoData.dataUndo.forEach((item:any)=>{
                            let param = {
                                userId: userId,
                                projectId : storeProjectId,
                                relatedGraphId : item.related_graph_id,
                                relatedGraphTextId : item.related_graph_text_id,
                                ownerProject: projectData.createUserId
                            }
                            arrRes.push(param)
                            deleteRelatedGraphById(item.related_graph_id)
                            if(listTextOfLinesInTab.find((text:any)=>Number(text.id) === Number(item.related_graph_text_id) && text.graphNo === currentIDChartTab) != undefined)
                            {
                                listTextOfLinesInTab.splice(listTextOfLinesInTab.indexOf(listTextOfLinesInTab.find((text:any)=>Number(text.id) === Number(item.related_graph_text_id) && text.graphNo === currentIDChartTab)), 1); 
                            }
                            if(listTextOfLinesInTabStore.find((text:any)=>Number(text.id) === Number(item.related_graph_text_id) && text.graphNo === currentIDChartTab) != undefined)
                            {
                                listTextOfLinesInTabStore.splice(listTextOfLinesInTabStore.indexOf(listTextOfLinesInTabStore.find((text:any)=>Number(text.id) === Number(item.related_graph_text_id) && text.graphNo === currentIDChartTab)), 1); 
                            }
                        })
                        deleteRelatedGraph(arrRes)
                    }
                    setLoadingFlag(false);
                    break;
                case "UNDO_UPDATE_RELATED_GRAPH":
                    {
                        handleUpdateCurveInGraph(undoData.dataUndo, true);
                    }
                    setLoadingFlag(false);
                    break;    
                case "CHANGE_ZOOM":
                    {
                        saveChartZoom(undoData.dataUndo.zoom, undoData.dataUndo.graphNo)
                        let temp = chartDataList.find((x:any) => x.tabId === undoData.dataUndo.graphNo);
                        let dNominalVolt = temp?.nominalVolt ?? 0;
                        let bSociety = temp?.isJapaneseElectricMode ?? false;
                        let param = {
                            userId: userId,
                            projectId : storeProjectId,
                            graphNo : undoData.dataUndo.graphNo,
                            nominalVolt : dNominalVolt,
                            zoom : undoData.dataUndo.zoom,
                            society : bSociety,
                            ownerProject: projectData.createUserId

                        }
                        updateGraph(param)
                        setLoadingFlag(false);
                    }
                    break;
                case "CHANGE_NOMINAL_VOLT":
                    {
                        saveNewNominalVolt(undoData.dataUndo.nominalVolt, undoData.dataUndo.graphNo)
                        let temp = chartDataList.find((x:any) => x.tabId === undoData.dataUndo.graphNo);
                        let dZoom = temp?.zoom ?? 100;
                        let bSociety = temp?.isJapaneseElectricMode ?? false;
                        let param = {
                            userId: userId,
                            projectId : storeProjectId,
                            graphNo : undoData.dataUndo.graphNo,
                            nominalVolt : undoData.dataUndo.nominalVolt,
                            zoom : dZoom,
                            society : bSociety,
                            ownerProject: projectData.createUserId
                        }
                        updateGraph(param)
                        setLoadingFlag(false);
                    }
                    break;
                case "EDIT_GRAPH_TEXT":
                    {
                       setLoadingFlag(true);
                       onUpdateGraphText({...undoData.dataUndo,userId: userId,projectId:storeProjectId,pointTopLeftX:undoData.dataUndo.x,pointTopLeftY:undoData.dataUndo.y,ownerProject: projectData.createUserId});
                    }
                    setLoadingFlag(false);
                    break;
                default:
                    setLoadingFlag(false);
                    break;
            }
        }
    }

    const handleKeyDown = (event:any) => {
        event.preventDefault();
        const currentSelected = listObjectText.find((item:any) => item.isSelected == true);
        switch (event.key) {
            case 'Delete':
                event.preventDefault();
                if(currentSelected && !modeViewOnly){
                  setOpenDeleteDialog(true)
                }
                break;
        }
    }

    const handleKeyDownInGraph = (event:any) => {
        if(!openTextEditor){
            event.preventDefault();
            switch (event.key) {
                case 'Delete':
                    event.preventDefault();
                    !modeViewOnly && saveClickedDataset.length > 0 && setOpenDeleteDialog(true)
                    break;
                case 'z':
                    event.preventDefault();
                    !modeViewOnly && event.ctrlKey && handleUndo();
                    break;
            }
        }
    }

    const handleOpenContextMenu = (event:any) => {
        event.preventDefault()
        event.stopPropagation();
        let newList = setNotSelectListObjectText();
        let currentSelected = newList.find((item:any) => "text_"+item.id == event.target.id);
        setFocusEvent({event:event, element:currentSelected})
        currentSelected.isSelected = true;
        setListObjectText([...newList]);
        const scrollLeft = lineDiagramRef.current.scrollLeft;
        const scrollTop = lineDiagramRef.current.scrollTop;
        const BB = lineDiagramRef.current.getBoundingClientRect(),
            offsetX = BB.left,
            offsetY = BB.top;
        const x = event.clientX - offsetX + scrollLeft;
        const y = event.clientY - offsetY > 360 ? event.clientY - offsetY + 100 + scrollTop : event.clientY - offsetY + scrollTop;
        setTextPosition({x:x,y:y});
        setContextMenu({
            visible: true,
            mode: false,
            type: false,
            x: x,
            y: y,
            isGraphText:true,
        });
    
    }

    const handleDelete = (data:any) => {
        setLoadingFlag(false);
        if(data && data.resultCode == 0){
            if(isDeleteGraphText == true){
                const newList = listObjectText;
                const listIdDeleted = data.list_graph_text_id;
                for( const idDeleted of listIdDeleted){
                    const indexDelete = newList.findIndex((item:any) => item.id == idDeleted);
                    newList.splice(indexDelete,1);
                }
                setListObjectText(newList);
            }else{
                // TODO:
            }
            handleDeleteSelectedLine()
        }
        chartDiagramRef.current.focus()
    }

    const handleErrorDelete = (success:any, data:any, error:any) => {
        setLoadingFlag(false);
    }
    
    const handleOpenTextEditor = (event:any) => {
        const initialDataTextUpdate = listObjectText.find((item:any) => 'text_'+item.id == event.target.id);
        setInitialDataTextEditor(initialDataTextUpdate)
        setUndoData({type:"EDIT_GRAPH_TEXT",dataUndo: {...initialDataTextUpdate}} as Model.UndoModel)
        setOpenTextEditor(true);
    }

    const handleUpdateGraphText = (data:any) => {
        setOpenTextEditor(false);
        setLoadingFlag(true);
        onUpdateGraphText({...data,userId: userId,projectId:storeProjectId,pointTopLeftX:data.x,pointTopLeftY:data.y,ownerProject: projectData.createUserId});
    }

    const handleSuccessUpdateGraphText = (data:any) => {
        setLoadingFlag(false);
        if(data && data.resultCode == 0){
            const newData = data.data;
            const id = newData["graph_text_id"];
            const newList = setNotSelectListObjectText();
            const item = newList.find((item:any) => item.id === id);
            if(item){
                item.isSelected= true;
                item.text = newData["text"]
                item.fontSize = newData["font_size"]
                item.faceName = newData["face_name"]
                item.colorFont = newData["color_font"]
                item.underline = newData["underline"]
                item.strikeOut = newData["strike_out"]
                item.italic = newData["italic"]
                item.weight = newData["weight"]
                item.charSet = newData["char_set"]
                item.x =newData["point_top_left_x"]
                item.y =newData["point_top_left_y"]
                setListObjectText(newList);
            }
        }
    }

    const handleErrorUpdateGraphText = (success:any, data:any, error:any) => {
        setLoadingFlag(false);
    }

    const handleSuccessUpdateRelatedGraph = (data:any) => {
        if(reloadHandle) {
            const { 
                relatedGraphId,
                controlId, 
                datasetId, 
                relatedGraph : { colorNormal, kind, width, dispBand },
                refNo,
                relatedGraphText : { charSet, colorFont, dispLinkLine, dispRefV2, dispTextFree, faceName, fontSize, italic, kindLinkLine, strikeOut, textFixed, textFree, underline, weight},
                ...details
            } = dataUpdateLine as any
            const updatedEle = currentChartTabData.dataElements?.find(el => el.id === selectedDataset?.controlId) as ControlModel
            if (updatedEle?.properties?.details ){
                // update properties to store
                updateControlData(controlId, {...updatedEle.properties, details ,dispBand})
            }

            setReloadHandle(false)
        }
        setLoadingFlag(false)
    }

    const handleSuccessUpdateRelatedGraphText = (data:any) => {
       setLoadingFlag(false)
    }

    const handleErrorUpdateRelatedGraph = (success:any, data:any, error:any) => {
        setLoadingFlag(false);
    }

    const handleErrorUpdateRelatedGraphText = (success:any, data:any, error:any) => {
        setLoadingFlag(false);
    }

    const handleSuccessGetListGraphText = (data:any) => {
        if(data && data.resultCode == 0){
            const result = [];
            for(const item of data.data){
                const newItem = {
                    isSelected: false,
                    id : item["graph_text_id"],
                    graphNo : item["graph_no"],
                    text : item["text"],
                    fontSize : item["font_size"],
                    faceName : item["face_name"],
                    colorFont : item["color_font"],
                    underline : item["underline"],
                    strikeOut : item["strike_out"],
                    italic : item["italic"],
                    weight : item["weight"],
                    charSet : item["char_set"],
                    x :item["point_top_left_x"],
                    y :item["point_top_left_y"]
                }
                result.push(newItem);
            }
            setListObjectText(result);
        }
        setLoadingFlag(false);
    }

    const handleErrorGetListGraphText = (success:any, data:any, error:any)  => {
        setLoadingFlag(false);
    }

    const handleSuccessGetGraphDispBand = (data:any) => {
        setLoadingFlag(false);
        const dispBand: boolean = data.data.disp_band;
        let listRelatedGraph: object[] = []
        let listUpdateLines : any[] = []
        let temp : any[] = []
        chartDataList.find(x=>x.tabId=== currentIDChartTab)?.shape.map(shapes=>shapes.chart.map(charts=> {listRelatedGraph.push({relatedGraphId: Number(charts.relatedGraphId)}); temp.push(charts)}))
        chartDataList.find(x=>x.tabId=== currentIDChartTab)?.shape.map(shapes=> listUpdateLines.push({controlId: shapes.id, properties: shapes.properties}))

        temp.map((chart => {
            //update propertiest
            const foundDataset = chartRef.current?.data.datasets.find((dataset : any) => dataset.lineId === chart.lineId) as any
            if (foundDataset) {
                foundDataset.dispBand = dispBand
                chartRef.current?.update('none')
            }
    
            //update line to store
            updateLineInfo(
                {   
                    ...chart.tabMapping.get(currentIDChartTab),
                    dispBand : dispBand,
                }, 
                chart.lineId, 
                chart.controlId
            )
        }))

        listUpdateLines.map(element => {
            //redraw chart
            updateControlData(element.controlId, {...element.properties , dispBand : dispBand})
        })

        let params = {
            userId: userId,
            projectId : storeProjectId,
            graphNo : currentIDChartTab,
            dispBand : dispBand,
            data : listRelatedGraph,
            ownerProject: projectData.createUserId
        }

        updateGraphDispBand(params)

    }

    const handleErrorGetGraphDispBand = (success:any, data:any, error:any)  => {
        setLoadingFlag(false);
    }

    const handleSuccessGetGraphName = (data:any) => {
        let temp: any[] = []
        data.data.map((x:any)=> temp.push({graphNo: x.graph_no, graphName: x.graph_name, graphLength: data.data.length}))
        if(chartDataList.find(chart => chart.tabId === currentIDChartTab) === undefined && (isOpenGraphPage || isOpenGraphTemporary) && isGetGraphName)
        {
            let params = {
                userId: userId,
                projectId: storeProjectId,
                graphNo: currentIDChartTab,
                ownerProject: projectData.createUserId
            }
            getRelatedGraph(params)
        }
        else if(isGetGraphName && (isOpenGraphPage || isOpenGraphTemporary))
        {
            let shape = chartDataList?.find(x=>x.tabId === currentIDChartTab)?.shape
            shape && handleSetTextLines(shape, 0)
            setReloadConnectLines(!reloadConnectLines)
            const params = {
                userId: userId,
                projectId: storeProjectId,
                graphNo:currentIDChartTab,
                ownerProject: projectData.createUserId
            }
            onGetGraphTextListByGraphNo(params);

            setLoadingFlag(false)
        }
        setIsGetGraphName(false)
        setGraphData({type: "GRAPH_NAME_DB", data: temp})
    }

    const handleErrorGetGraphName = (success:any, data:any, error:any)  => {
    }

    const handleSuccessGetRelatedGraph = (data:any) => {
        setReloadChart(true);
        let listControl: any[] = []
        data.data.map((x:any) => listControl.push({elementId: x.element_id, userCurveId: x.user_curve_id}))

        setReloadChart(true);

        setRelatedGraphData(data.data.filter((x:any)=>x.user_curve_id === 0))

        setRelatedGraphUserCurveData(data.data.filter((x:any)=>x.user_curve_id !== 0))
    
        listControl.filter(x=>x.userCurveId !== 0).map(x=> {
           userCurveToDraw.push({...x, isAddNew : listControl.find((data:any)=>data.elementId === x.elementId && data.userCurveId === 0) === undefined? true : false})
        })
        setUserCurveToDraw(userCurveToDraw)
      
        onDrawLines(listControl, false)
        const params = {
            userId: userId,
            projectId: storeProjectId,
            graphNo:currentIDChartTab,
            ownerProject: projectData.createUserId
        }
        onGetGraphTextListByGraphNo(params);
        setLoadingFlag(false)
    }

    const handleErrorGetRelatedGraph = (success:any, data:any, error:any)  => {
        setLoadingFlag(false)
    }

    const handleUpdateElementSuccess = (data: any) => {
        // if(data && data.resultCode == 0){
        //   mappedDataFromBE(data.data.elements);
        // }
        setLoadingFlag(false)
    }

    const handleUpdateElementError = (error: any) => {
        setLoadingFlag(false);
    }

    const handleDeleteGraphSuccess = (data: any) => {
        if(data && data.resultCode == 0){
            saveListGraphToStore(listGraph.filter((item:any) => item.graphNo != data.graph_no));
        }
        if(isOpenGraphPage)
        {
            if(maxIDChartTab === currentIDChartTab)
            {
                saveCurrentIdChartTab(currentIDChartTab - 1)
                //saveMaxIdChartTab(maxIDChartTab - 1,true)
            }
            else
            {
                saveCurrentIdChartTab(maxIDChartTab)
                //saveMaxIdChartTab(maxIDChartTab - 1,true)
            }
            setLoadingFlag(false);
        }
    }

    const handleDeleteGraphError = (error: any) => {
        setLoadingFlag(false);
    }

    const handleGetDiagramPDFSuccess = (data: any) => {
        if (data.data && isDownloadPDF.state) 
        {
            downloadFile(data.data);
        }
        setDownloadDiagramPDF(false, isDownloadPDF.type, isDownloadPDF.image)
        setLoadingFlag(false)
      };

    const handleGetDiagramPDFError = (error: any) => {
        setLoadingFlag(false)
    };

    const handleSucessUpdateGraphScale = (data:any) => {
        setLoadingFlag(false);
        if(data && data.resultCode == 0){
            const new_list = listGraph.map((item:any) => {
                if(item.graphNo == data.projectData.graphNo){
                    item = data.projectData;
                }
                return item;    
            })
            saveListGraphToStore(new_list);
            if(!undoFlag)
            {
                setUndoData({type:UPDATE_GRAPH_SCALE,dataUndo: {}} as Model.UndoModel)
            }
            else
            {
                setUndoData({type:null,dataUndo: null} as Model.UndoModel)
            }
        }
    };

    const handleErrorUpdateGraphScale = (error:any) => {
        setLoadingFlag(false);
    }

    const handleSucessUpdateGraph = (data:any) => {
        setLoadingFlag(true);
        if(data && data.resultCode == 0){
            let arrPosition:any[] = []
            listTextOfLinesInTab.map((x:any)=>{
                if(x.graphNo == currentIDChartTab && nominalVolt)
                {
                    arrPosition.push({relatedGraphId: x.relatedGraphId, relatedGraphTextId: x.relatedGraphTextId, pointTopLeftX: x.x, pointTopLeftY: x.y, pointOldX: 0, pointOldY: 0, minPointX: x.pointConnectLineX??x.x, minPointY: x.pointConnectLineY??x.y})
                }
            })
            setPositionForListGraphLabel(arrPosition)
        }
    };
    const handleErrorUpdateGraph = (error:any) => {
        setLoadingFlag(false);
    }

    // ===================== END EDIT DATASET ===============================
    //#endregion
    
    return (
        <>
            {
                loadingFlag &&
                <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1200, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                    <CircularProgress />
                </div>
            }
            {   
                <div style={{height: '24px', display: 'flex', justifyContent: 'flex-end', gap: '20px', alignItems: 'flex-end', padding: '4px 26px 0'}}>
                   { hoverPoints && 
                        <>
                            <Typography lineHeight='1' fontSize={'14px'} color={'GrayText'}>{hoverPoints.x}(A)</Typography>
                            <Typography lineHeight='1' fontSize={'14px'} color={'GrayText'}>{hoverPoints.y}(s)</Typography>
                        </>
                   }
                </div>
            }
            {
                loadChart ?
                <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1200, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                    <CircularProgress />
                </div> :
                <>
                 
                    <div ref={lineDiagramRef} onClick={() => setContextMenu({ visible: false, mode: false, type: false, x: 0, y: 0 })} style={{ flex: '1', overflow: 'auto', position: 'relative'}}>
                        <div ref={chartDiagramRef}  style={{width: `${zoom}%`, height: `${zoom}%`}} onDragOver={(event:any) => event.preventDefault()} onDrop={handleOnDrop} tabIndex={1}  onKeyDown={handleKeyDownInGraph}>
                        <div style={{position:'absolute', overflow:'hidden', width:'0px', height:'0px', maxWidth: '0px', maxHeight:'0px'}}>
                           <div id={'getLineDiagramPDF'} style={{position:'relative',  width: '1000px', height: isJapaneseElectricMode? '1235px' : '960px'}}>
                                {isJapaneseElectricMode && 
                                <>
                                    <div  
                                        style={{
                                            position:"absolute",
                                            zIndex:50,
                                            top:'10px',
                                            left:'908px',
                                        }}
                                    >
                                        <svg height="583" width="120">
                                        <line x1="0" y1="27.5" x2="5" y2="27.5" style={{stroke:'#000000',strokeWidth:1, strokeOpacity:10}}/>
                                        <text x='10' y='31' fontSize={12} color='#000000' stroke='#000000' strokeWidth={0.2}>2</text>

                                        <line x1="0" y1="87" x2="5" y2="87" style={{stroke:'#000000',strokeWidth:1, strokeOpacity:10}}/>
                                        <text x='10' y='91' fontSize={12} color='#000000' stroke='#000000' strokeWidth={0.2}>1</text>

                                        <line x1="0" y1="121" x2="5" y2="121" style={{stroke:'#000000',strokeWidth:1, strokeOpacity:10}}/>
                                        <text x='10' y='125' fontSize={12} color='#000000' stroke='#000000' strokeWidth={0.2}>40</text>

                                        <line x1="0" y1="145" x2="5" y2="145" style={{stroke:'#000000',strokeWidth:1, strokeOpacity:10}}/>
                                        <text x='10' y='149' fontSize={12} color='#000000' stroke='#000000' strokeWidth={0.2}>30</text>

                                        <line x1="0" y1="180.5" x2="5" y2="180.5" style={{stroke:'#000000',strokeWidth:1, strokeOpacity:10}}/>
                                        <text x='10' y='184' fontSize={12} color='#000000' stroke='#000000' strokeWidth={0.2}>20</text>

                                        <line x1="0" y1="204.5" x2="5" y2="204.5" style={{stroke:'#000000',strokeWidth:1, strokeOpacity:10}}/>
                                        <text x='10' y='209' fontSize={12} color='#000000' stroke='#000000' strokeWidth={0.2}>15</text>

                                        <line x1="0" y1="239.5" x2="5" y2="239.5" style={{stroke:'#000000',strokeWidth:1, strokeOpacity:10}}/>
                                        <text x='10' y='244' fontSize={12} color='#000000' stroke='#000000' strokeWidth={0.2}>10</text>

                                        <line x1="0" y1="258.5" x2="5" y2="258.5" style={{stroke:'#000000',strokeWidth:1, strokeOpacity:10}}/>
                                        <text x='10' y='262.5' fontSize={12} color='#000000' stroke='#000000' strokeWidth={0.2}>8</text>
                                        
                                        <line x1="0" y1="283" x2="5" y2="283" style={{stroke:'#000000',strokeWidth:1, strokeOpacity:10}}/>
                                        <text x='10' y='287' fontSize={12} color='#000000' stroke='#000000' strokeWidth={0.2}>6</text>

                                        <line x1="0" y1="316.5" x2="5" y2="316.5" style={{stroke:'#000000',strokeWidth:1, strokeOpacity:10}}/>
                                        <text x='10' y='321' fontSize={12} color='#000000' stroke='#000000' strokeWidth={0.2}>4</text>

                                        <line x1="0" y1="375.5" x2="5" y2="375.5" style={{stroke:'#000000',strokeWidth:1, strokeOpacity:10}}/>
                                        <text x='10' y='380' fontSize={12} color='#000000' stroke='#000000' strokeWidth={0.2}>2</text>

                                        <line x1="0" y1="434.5" x2="5" y2="434.5" style={{stroke:'#000000',strokeWidth:1, strokeOpacity:10}}/>
                                        <text x='10' y='439' fontSize={12} color='#000000' stroke='#000000' strokeWidth={0.2}>1</text>
                                        </svg>
                                    </div>

                                    <div  
                                        style={{
                                            position:"absolute",
                                            zIndex:50,
                                            top:'10px',
                                            left:'950px',
                                        }}
                                    >
                                        <svg height="1175" width="120">
                                        <line x1="10" y1="0" x2="10" y2="36"  style={{stroke:'#000000', strokeWidth:1}}/>
                                        <line x1="10" y1="50" x2="10" y2="252"  style={{stroke:'#000000', strokeWidth:1}}/>
                                        <line x1="10" y1="267" x2="10" y2="798"  style={{stroke:'#000000', strokeWidth:1}}/>
                                        <line x1="10" y1="811" x2="10" y2="1175"  style={{stroke:'#000000', strokeWidth:1}}/>

                                        <line x1="7" y1="10" x2="10" y2="0"  style={{stroke:'#000000', strokeWidth:1}}/>
                                        <line x1="10" y1="0" x2="13" y2="10"  style={{stroke:'#000000', strokeWidth:1}}/>
                                        <line x1="0" y1="0" x2="20" y2="0"  style={{stroke:'#000000', strokeWidth:2}}/>

                                        <text x='0' y='47' fontSize={12}>(時)</text>

                                        <line x1="7" y1="77" x2="10" y2="87"  style={{stroke:'#000000', strokeWidth:1}}/>
                                        <line x1="10" y1="87" x2="13" y2="77"  style={{stroke:'#000000', strokeWidth:1}}/>
                                        <line x1="0" y1="87" x2="20" y2="87"  style={{stroke:'#000000', strokeWidth:1.2}}/>
                                        <line x1="7" y1="97" x2="10" y2="87"  style={{stroke:'#000000', strokeWidth:1}}/>
                                        <line x1="10" y1="87" x2="13" y2="97"  style={{stroke:'#000000', strokeWidth:1}}/>

                                        <text x='0' y='264' fontSize={12}>(分)</text>

                                        <line x1="7" y1="424" x2="10" y2="434"  style={{stroke:'#000000', strokeWidth:1}}/>
                                        <line x1="10" y1="434" x2="13" y2="424"  style={{stroke:'#000000', strokeWidth:1}}/>
                                        <line x1="0" y1="434" x2="20" y2="434"  style={{stroke:'#000000', strokeWidth:1.2}}/>
                                        <line x1="7" y1="444" x2="10" y2="434"  style={{stroke:'#000000', strokeWidth:1}}/>
                                        <line x1="10" y1="434" x2="13" y2="444"  style={{stroke:'#000000', strokeWidth:1}}/>

                                        <text x='0' y='808' fontSize={12}>(秒)</text>
                                      
                                        <line x1="7" y1="1165" x2="10" y2="1175"  style={{stroke:'#000000', strokeWidth:1}}/>
                                        <line x1="10" y1="1175" x2="13" y2="1165"  style={{stroke:'#000000', strokeWidth:1}}/>
                                        <line x1="0" y1="1175" x2="20" y2="1175"  style={{stroke:'#000000', strokeWidth:2}}/>
                                        </svg>
                                    </div>

                                    <div  
                                        style={{
                                            position:"absolute",
                                            zIndex:50,
                                            top:'10px',
                                            left:'45px',
                                        }}
                                    >
                                        <svg height="583" width="120">
                                        <line x1="25" y1="0" x2="25" y2="434"  style={{stroke:'#000000', strokeWidth:1}}/>
                                        <line x1="20" y1="0" x2="30" y2="0"  style={{stroke:'#000000', strokeWidth:2}}/>

                                        <line x1="20" y1="27.5" x2="30" y2="27.5" style={{stroke:'#000000',strokeWidth:1, strokeOpacity:10}}/>
                                        <text x='7.5' y='31' fontSize={12} color='#000000' stroke='#000000' strokeWidth={0.2}>2</text>

                                        <line x1="20" y1="87" x2="30" y2="87" style={{stroke:'#000000',strokeWidth:1, strokeOpacity:10}}/>
                                        <text x='7.5' y='91' fontSize={12} color='#000000' stroke='#000000' strokeWidth={0.2}>1</text>

                                        <line x1="20" y1="121" x2="30" y2="121" style={{stroke:'#000000',strokeWidth:1, strokeOpacity:10}}/>
                                        <text x='1' y='125' fontSize={12} color='#000000' stroke='#000000' strokeWidth={0.2}>40</text>

                                        <line x1="20" y1="145" x2="30" y2="145" style={{stroke:'#000000',strokeWidth:1, strokeOpacity:10}}/>
                                        <text x='1' y='149' fontSize={12} color='#000000' stroke='#000000' strokeWidth={0.2}>30</text>

                                        <line x1="20" y1="180.5" x2="30" y2="180.5" style={{stroke:'#000000',strokeWidth:1, strokeOpacity:10}}/>
                                        <text x='1' y='184' fontSize={12} color='#000000' stroke='#000000' strokeWidth={0.2}>20</text>

                                        <line x1="20" y1="204.5" x2="30" y2="204.5" style={{stroke:'#000000',strokeWidth:1, strokeOpacity:10}}/>
                                        <text x='1' y='209' fontSize={12} color='#000000' stroke='#000000' strokeWidth={0.2}>15</text>

                                        <line x1="20" y1="239.5" x2="30" y2="239.5" style={{stroke:'#000000',strokeWidth:1, strokeOpacity:10}}/>
                                        <text x='1' y='244' fontSize={12} color='#000000' stroke='#000000' strokeWidth={0.2}>10</text>

                                        <line x1="20" y1="258.5" x2="30" y2="258.5" style={{stroke:'#000000',strokeWidth:1, strokeOpacity:10}}/>
                                        <text x='7.5' y='262.5' fontSize={12} color='#000000' stroke='#000000' strokeWidth={0.2}>8</text>
                                        
                                        <line x1="20" y1="283" x2="30" y2="283" style={{stroke:'#000000',strokeWidth:1, strokeOpacity:10}}/>
                                        <text x='7.5' y='287' fontSize={12} color='#000000' stroke='#000000' strokeWidth={0.2}>6</text>

                                        <line x1="20" y1="316.5" x2="30" y2="316.5" style={{stroke:'#000000',strokeWidth:1, strokeOpacity:10}}/>
                                        <text x='7.5' y='321' fontSize={12} color='#000000' stroke='#000000' strokeWidth={0.2}>4</text>

                                        <line x1="20" y1="375.5" x2="30" y2="375.5" style={{stroke:'#000000',strokeWidth:1, strokeOpacity:10}}/>
                                        <text x='7.5' y='380' fontSize={12} color='#000000' stroke='#000000' strokeWidth={0.2}>2</text>

                                        <line x1="20" y1="434.5" x2="30" y2="434.5" style={{stroke:'#000000',strokeWidth:1}}/>
                                        <text x='7.5' y='439' fontSize={12} color='#000000' stroke='#000000' strokeWidth={0.2}>1</text>
                                        </svg>
                                    </div>

                                    <div  
                                        style={{
                                            position:"absolute",
                                            zIndex:50,
                                            top:'10px',
                                            left:'17px',
                                        }}
                                    >
                                        <svg height="583" width="120">
                                        <line x1="10" y1="0" x2="10" y2="36"  style={{stroke:'#000000', strokeWidth:1}}/>
                                        <line x1="10" y1="50" x2="10" y2="252"  style={{stroke:'#000000', strokeWidth:1}}/>
                                        <line x1="10" y1="267" x2="10" y2="434"  style={{stroke:'#000000', strokeWidth:1}}/>

                                        <text x='0' y='47' fontSize={12}>(時)</text>

                                        <line x1="7" y1="77" x2="10" y2="87"  style={{stroke:'#000000', strokeWidth:1}}/>
                                        <line x1="10" y1="87" x2="13" y2="77"  style={{stroke:'#000000', strokeWidth:1}}/>
                                        <line x1="5" y1="87" x2="25" y2="87"  style={{stroke:'#000000', strokeWidth:1.2}}/>
                                        <line x1="7" y1="97" x2="10" y2="87"  style={{stroke:'#000000', strokeWidth:1}}/>
                                        <line x1="10" y1="87" x2="13" y2="97"  style={{stroke:'#000000', strokeWidth:1}}/>

                                        <text x='0' y='264' fontSize={12}>(分)</text>

                                        <line x1="7" y1="10" x2="10" y2="0"  style={{stroke:'#000000', strokeWidth:1}}/>
                                        <line x1="10" y1="0" x2="13" y2="10"  style={{stroke:'#000000', strokeWidth:1}}/>
                                        <line x1="5" y1="0" x2="25" y2="0"  style={{stroke:'#000000', strokeWidth:2}}/>

                                        <line x1="7" y1="424" x2="10" y2="434"  style={{stroke:'#000000', strokeWidth:1}}/>
                                        <line x1="10" y1="434" x2="13" y2="424"  style={{stroke:'#000000', strokeWidth:1}}/>
                                        <line x1="5" y1="434" x2="25" y2="434"  style={{stroke:'#000000', strokeWidth:1}}/>
                                        </svg>
                                    </div>
                                </>
                                    }
                                <Line
                                    style={{position:'absolute', marginLeft: isJapaneseElectricMode? '60px' : '0px', marginRight: isJapaneseElectricMode? '55px' : '0px'}}
                                    datasetIdKey={'lineId1'} ref={chartRefPDF}  options={optionsPDF as any} data={currentChartTabData.dataChart?.labels? currentChartTabData.dataChart as any : {labels:[], datasets: []} as any}
                                />
                          
                                {listTextOfLinesInTab.length > 0 &&
                                    <>
                                        {listTextOfLinesInTab.map((element:any,index:any) => {
                                            return(
                                            element.graphNo === currentIDChartTab ?
                                            <div 
                                                style={{
                                                    position:"absolute",
                                                    zIndex:50,
                                                    top:`${getPixelForValueDivPDF({x:element.x,y:element.y}).y}px`,
                                                    left:`${getPixelForValueDivPDF({x:element.x,y:element.y}).x}px`,
                                                    border: "0px",
                                                    borderStyle: 'none',
                                                    display: `${element.isHideText? "none" : ""}`,
                                                    whiteSpace: 'nowrap',
                                                    marginLeft:isJapaneseElectricMode? '60px' : '0px',
                                                }}
                                            >
                                                    {element.text.map((info:string, index:number) => {
                                                       return(
                                                        <>
                                                          <Typography variant='inherit' key={index}>
                                                            <div style={{fontSize: `${isJapaneseElectricMode ? (zoom ? ((element.fontSize) * zoom / 90) + 7 : element.fontSize + 7) : (zoom ? ((element.fontSize) * zoom / 90) + 2 : element.fontSize + 2)}px`, margin: 'auto', fontFamily: `${element.faceName}`, color: `${element.colorFont}`, fontStyle: `${element.italic === false? 'normal' : 'italic'}`, fontWeight: `${element.weight}`, textDecoration: `${element.strikeOut === true? 'line-through' : element.underline === true ? 'underline' : ''}` }}>
                                                                {info}
                                                            </div>
                                                            </Typography>
                                                        </>
                                                       )
                                                    })}  
                                            </div>
                                            :
                                            <></>
                                            )
                                        } )}
                                    </>
                                }
                                {listObjectText.length > 0 &&
                                    <>
                                        {listObjectText.map((element:any,index:any) => {
                                            return(
                                            element.graphNo === currentIDChartTab ?
                                            <div 
                                                style={{
                                                position:"absolute",
                                                zIndex:100,
                                                top:`${getPixelForValueDivPDF({x:element.x,y:element.y}).y}px`,
                                                left:`${getPixelForValueDivPDF({x:element.x,y:element.y}).x}px`,
                                                color: element.colorFont,
                                                border: "0px",
                                                borderStyle:"dashed",
                                                fontSize: isJapaneseElectricMode? (zoom ? ((element.fontSize) * zoom / 90) + 7 : element.fontSize + 7) : (zoom ? ((element.fontSize) * zoom / 90) + 2 : element.fontSize + 2),
                                                background:"white",
                                                width:"auto",
                                                fontFamily:`${element.faceName}`,
                                                textDecoration:`${getTextDecoration(element.underline, element.strikeOut)}`,
                                                fontStyle:`${getFontStyleValue(element.weight, element.italic)}`,
                                                whiteSpace: 'nowrap',
                                                marginLeft:isJapaneseElectricMode? '60px' : '0px'
                                                }}
                                            >
                                                {element.text}
                                            </div>
                                            :
                                            <></>
                                            )
                                        } )}
                                    </>
                                }
                            </div>
                           </div>
                            <div style={{width: `${zoom}%`, height: `${zoom}%`}}>
                                {listTextOfLinesInTab.length > 0 &&
                                    <>
                                        {listTextOfLinesInTab.map((element:any,index:any) => {
                                            return(
                                            element?.graphNo === currentIDChartTab ?
                                            <div 
                                                ref={(el) => (divLineRefs.current[index] = el)}
                                                id={"line_"+element.id}
                                                style={{
                                                position:"absolute",
                                                zIndex:50,
                                                top:`${getPixelForValue({x:element.x,y:element.y}).y}px`,
                                                left:`${getPixelForValue({x:element.x,y:element.y}).x}px`,
                                                border:element.isSelected ? "1px solid #000000" : "0px",
                                                borderStyle: element.isSelected ? 'dashed' : 'none',
                                                display: `${element.isHideText? "none" : ""}`,
                                                cursor: 'pointer',
                                                whiteSpace: 'nowrap'
                                                }}
                                                draggable={isSelectTouchGroup || modeViewOnly ? "false" : "true"}
                                                tabIndex={0} //onKeyDown={handleKeyDown}
                                                onClick={(e:any)=>handleClickOnChart(e,element)}
                                                onDragStart={(e:any) =>handleDragStart(e,element)}
                                                onDrop={(event:any)=>handleOnDrop(event)}
                                                onDoubleClick={(e:any)=>handleDoubleClick(e,element)}
                                                onContextMenu={(e:any)=>handleContextMenu(e,element)}
                                                onTouchStartCapture={(e:any) =>handleOnTouchStart(e,element)}
                                                onTouchEndCapture={(event:any)=>handleOnTouchEnd(event, element)}
                                                onTouchMoveCapture={(e:any)=>handleOnTouchMove(e)}
                                            >
                                                    {element.text.map((info:string, index:number) => {
                                                        return <Typography variant='inherit' key={index}>
                                                            <div style={{ fontSize: `${zoom ? (element.fontSize * zoom / 100) : element.fontSize}px`, margin: 'auto', fontFamily: `${element.faceName}`, color: `${element.isSelected ? "red" : element.colorFont}`, fontStyle: `${element.italic === false? 'normal' : 'italic'}`, fontWeight: `${element.weight}`, textDecoration: `${element.strikeOut === true? 'line-through' : element.underline === true ? 'underline' : ''}` }}>
                                                                {info}
                                                                </div>
                                                        </Typography>
                                                    })}  
                                            </div>
                                            :
                                            <></>
                                            )
                                        } )}
                                    </>
                                }
                                <Line
                                    style={{position: 'absolute'}}
                                    datasetIdKey={'lineId'} ref={chartRef} options={options as any} data={currentChartTabData.dataChart?.labels? currentChartTabData.dataChart as any : {labels:[], datasets: []} as any}
                                    onClick={handleClickOnChart}
                                    onMouseLeave={() => setHoverPoints(null)}
                                    onDoubleClick={handleDoubleClick}
                                    onContextMenu={handleContextMenu}
                                />
                                {listShowIntersectionPointText.length > 0 &&
                                    <>
                                        {listShowIntersectionPointText.map((element:any,index:any) => {
                                            return(
                                            element?.graphNo === currentIDChartTab ?
                                            <div 
                                                id={"point_"+element.id}
                                                style={{
                                                position:"absolute",
                                                zIndex:100,
                                                top:`${element.type == 'intersectCurrent' ? getPixelForValue({x:element.x,y:element.y}).y - 10 : getPixelForValue({x:element.x,y:element.y}).y + element.segment}px`,
                                                left:`${element.type == 'intersectTime' ? getPixelForValue({x:element.x,y:element.y}).x : getPixelForValue({x:element.x,y:element.y}).x + element.segment}px`,
                                                whiteSpace: 'nowrap',
                                                background:"white",
                                                fontSize: `${zoom ? (16 * zoom / 100) : 16}px`,
                                                color: '#000000', 
                                                fontStyle: 'normal', 
                                                fontWeight: 350
                                                }}
                                            >
                                                    {element.text}  
                                            </div>
                                            :
                                            <></>
                                            )
                                        } )}
                                    </>
                                }
                                {listObjectText.length > 0 &&
                                    <>
                                        {listObjectText.map((element:any,index:any) => {
                                            return(
                                            element.graphNo === currentIDChartTab ?
                                            <div 
                                                ref={(el) => (divRefs.current[index] = el)}
                                                id={'text_'+element.id}
                                                
                                                style={{
                                                position:"absolute",
                                                zIndex:100,
                                                top:`${getPixelForValue({x:element.x,y:element.y}).y}px`,
                                                left:`${getPixelForValue({x:element.x,y:element.y}).x}px`,
                                                color:element.isSelected ? "red" : element.colorFont,
                                                border:element.isSelected ? "1px solid #000000" : "0px",
                                                borderStyle:"dashed",
                                                fontSize:zoom ? element.fontSize * zoom / 100 : element.fontSize,
                                                background:"white",
                                                width:"auto",
                                                fontFamily:`${element.faceName}`,
                                                textDecoration:`${getTextDecoration(element.underline, element.strikeOut)}`,
                                                fontStyle:`${getFontStyleValue(element.weight, element.italic)}`,
                                                whiteSpace: 'nowrap',
                                                cursor:'pointer',
                                                }}
                                                draggable= {isSelectTouchGroup || modeViewOnly? "false" : "true"}
                                                tabIndex={0} onKeyDown={handleKeyDown}
                                                onClick={handleClickMsText}
                                                onDragStart={(e:any)=>handleDragStart(e,{...element, type: 'graphText'})}
                                                onDrop={(event:any)=>handleOnDrop(event)}
                                                onDoubleClick={handleOpenTextEditor}
                                                onContextMenu={(e:any)=>handleOpenContextMenu(e)}     
                                                onTouchStartCapture={(e:any) =>handleOnTouchStart(e,element, true)}
                                                onTouchEndCapture={(event:any)=>handleOnTouchEnd(event)}
                                                onTouchMoveCapture={(e:any)=>handleOnTouchMove(e)}   
                                            >
                                                {element.text}
                                            </div>
                                            :
                                            <></>
                                            )
                                        } )}
                                    </>
                                }
                            </div>
                            { contextMenu.visible &&
                                    <ChartMenu
                                        gapLine={gapLine}
                                        handleContextMenuClick={contextMenuFuntions}
                                        isHittedCurve={contextMenu.mode}
                                        isUserCurve={contextMenu.type}
                                        x={contextMenu.x}
                                        y={contextMenu.y}
                                        lineDiagramRef={lineDiagramRef}
                                        isGraphText={contextMenu.isGraphText}
                                        m_bModePM={m_bModePM}
                                        modeViewOnly={modeViewOnly}
                                    />
                            }

                            {
                                openTextEditor && 
                                <TextEditorDialog data={initialDataTextEditor} onOK={(data:any) => {handleUpdateGraphText(data)}} onCancel={() => setOpenTextEditor(false)} m_bModePM={m_bModePM} modeViewOnly={modeViewOnly}/>
                            }
                       
                          
                        </div>
                    </div>
                </>
            }
            {
                selectedDataset &&
                    <DatasetEditDialog 
                        data={selectedDataset}
                        element={currentChartTabData.dataElements?.find(el => el.id === selectedDataset.controlId) as ControlModel} 
                        onCancel={() => {setSelectedDataset(null); handleClickOnChart(focusEvent.event, focusEvent.element); setDrawConnectLines(!drawConnectLines)}} 
                        onOK={handleEditSubmit} 
                        m_bModePM={m_bModePM} 
                        modeViewOnly={modeViewOnly}
                    />  
            }
            {
                gapLine.dispGapLine &&
                <MeasureDifferenceDialog
                    setIntersectionPoint={(data:any)=>{
                        setIntersectionData(data)
                    }} 
                    chartDataList={chartDataList}
                    listTextOfLines={listTextOfLinesInTab}
                    isClickedDataset={isClickOnChart}
                    chartRef={chartRef}
                    currentIdChartTab={currentIDChartTab}
                    gapLine={gapLine} 
                    setGapLine={setGapLine} 
                    datasets={currentChartTabData.dataChart?.datasets as any} 
                    projectData={projectData} 
                />  
            }

            {
                openSetUpScaleGraph &&
                <SettingUpGraphScaleDialog initialData={{ scaleXMax :currentGraphData?.normalXMax,scaleXMin:currentGraphData?.normalXMin,scaleYMin:currentGraphData?.normalYMin,scaleYMax:currentGraphData?.normalYMax}as SettingUpGraphScale} onOK={handleUpdateScaleGraph} onCancel={() => {setOpenSetUpScaleGraph(false)}}
                />
            }

            {openDeleteDialog ?
                <AlertDialog title={"MSSV3"} message={"この曲線または文字列を保護協調図から削除しますか?"} isOpen={true} onOK={handleDeleteOK} onCancel={handleDeleteCancel} />  :
                <></>
            }

            {openDeleteGraphDialog ?
                <AlertDialog title={"MSSV3"} message={"この保護協調図を削除しますか?"} isOpen={true} onOK={handleDeleteGraphNoOK} onCancel={handleDeleteGraphNoCancel} />  :
                <></>
            }

            <FetchHelper
                fetchId={CREATE_RELATED_GRAPH}
                onComplete={(success, data, error) => {
                    success ? handleSuccessCreateRelatedGraph(data) : handleErrorCreateRelatedGraph(success, data, error);
                }}
            />    

            <FetchHelper
                fetchId={UNDO_RELATED_GRAPH}
                onComplete={(success, data, error) => {
                    handleFinishUndoRelatedGraph()
                }}
            />      

            <FetchHelper 
                fetchId={UPDATE_SCALE_GRAPH}
                onComplete={(success, data, error) => {
                    success ? handleSuccessUpdateScaleGraph(data) : handleErrorScaleGraph(success, data, error);
                }}
            />

            <FetchHelper
                fetchId={CREATE_GRAPH_TEXT}
                onComplete={(success, data, error) => {
                    success ? handleCreateGraphText(data) : handleErrorCreateGraphText(success, data, error) 
                }}
            />

            <FetchHelper 
                fetchId={DELETE}
                onComplete={(success, data, error) => {
                    success ? handleDelete(data) : handleErrorDelete(success, data, error) 
                }}
            />

            <FetchHelper 
                fetchId={UPDATE_GRAPH_TEXT}
                onComplete={(success, data, error) => {
                    success ? handleSuccessUpdateGraphText(data) : handleErrorUpdateGraphText(success, data, error) 
                }}
            />

            <FetchHelper 
                fetchId={GET_GRAPH_TEXT_LIST_BY_GRAPH_NO}
                onComplete={(success, data, error) => {
                    success ? handleSuccessGetListGraphText(data) : handleErrorGetListGraphText(success, data, error) 
                }}
            />

            <FetchHelper 
                fetchId={UPDATE_RELATED_GRAPH}
                onComplete={(success, data, error) => {
                    success ? handleSuccessUpdateRelatedGraph(data) : handleErrorUpdateRelatedGraph(success, data, error) 
                }}
            />

            <FetchHelper 
                fetchId={UPDATE_RELATED_GRAPH_TEXT}
                onComplete={(success, data, error) => {
                    success ? handleSuccessUpdateRelatedGraphText(data) : handleErrorUpdateRelatedGraphText(success, data, error) 
                }}
            />

            <FetchHelper 
                fetchId={GET_GRAPH_DISP_BAND}
                onComplete={(success, data, error) => {
                    success ? handleSuccessGetGraphDispBand(data) : handleErrorGetGraphDispBand(success, data, error) 
                }}
            />

            <FetchHelper 
                fetchId={DELETE}
                onComplete={(success, data, error) => {
                    setLoadingFlag(false)
                }}
            />

            <FetchHelper 
                fetchId={DELETE_RELATED_GRAPH}
                onComplete={(success, data, error) => {
                    chartDiagramRef?.current?.focus()
                    setLoadingFlag(false)
                }}
            />

            <FetchHelper 
                fetchId={GET_GRAPH_NAME_GRAPH}
                onComplete={(success, data, error) => {
                    success ? handleSuccessGetGraphName(data) : handleErrorGetGraphName(success, data, error) 
                }}
            />

            <FetchHelper 
                fetchId={GET_RELATED_GRAPH}
                onComplete={(success, data, error) => {
                    success ? handleSuccessGetRelatedGraph(data) : handleErrorGetRelatedGraph(success, data, error) 
                }}
            />

            <FetchHelper
                fetchId={"UPDATE_ELEMENT"}
                onComplete={(success, data, error) =>
                success
                    ? handleUpdateElementSuccess(data)
                    : handleUpdateElementError(error)
                }
            />

            <FetchHelper
                fetchId={DELETE_GRAPH}
                onComplete={(success, data, error) =>
                success
                    ? handleDeleteGraphSuccess(data)
                    : handleDeleteGraphError(error)
                }
            />

            <FetchHelper
                fetchId={DIAGRAM_PDF}
                onComplete={(success, data, error) =>
                success ? handleGetDiagramPDFSuccess(data) : handleGetDiagramPDFError(error)
                }
            />

            <FetchHelper
                fetchId={UPDATE_GRAPH_SCALE}
                onComplete={(success, data, error) =>
                    success ? handleSucessUpdateGraphScale(data) : handleErrorUpdateGraphScale(error)}
            />

            <FetchHelper
                fetchId={UPDATE_GRAPH}
                onComplete={(success, data, error) =>
                    success ? handleSucessUpdateGraph(data) : handleErrorUpdateGraph(error)}
            />
        </>
    )
});

const mapStateToProps = (state: ApplicationState) => {
    return {
        userId: state.app.user?.userId,
        projectData: state.app.projectData,
        currentIDChartTab: state.app.diagram.currentIDChartTab,
        currentIDDiagramTab: state.app.diagram.currentIDDiagramTab,
        chartDataList: state.app.diagram.chartData,
        diagramDataList: state.app.diagram.diagramData,
        chartEvents: state.app.diagram.chartEvents,
        gapLine: state.app.diagram.gapLine,
        nominalVolt: state.app.diagram.chartData.find(chartTab => chartTab.tabId === state.app.diagram.currentIDChartTab)?.nominalVolt,
        zoom: state.app.diagram.chartData.find(chartTab => chartTab.tabId === state.app.diagram.currentIDChartTab)?.zoom,
        isJapaneseElectricMode: state.app.diagram.chartData.find(chartTab => chartTab.tabId === state.app.diagram.currentIDChartTab)?.isJapaneseElectricMode,
        maxIDChartTab: state.app.diagram.maxIDChartTab,
        isAddLineToChart: state.app.diagram.isAddToChart,
        isAddCalcPointToChart: state.app.diagram.isAddCalcPointToChart,
        processMode: state.app.diagram.processMode,
        m_bModePM: state.app.diagram.m_bModePM,
        modeViewOnly: state.app.diagram.modeViewOnly,
        storeProjectId: state.app.projectData.projectId,
        deleteGraphNo: state.app.diagram.deleteGraphNo,
        undoData: state.app.diagram.undoData,
        deleteGraphLineText: state.app.diagram.deleteGraphText,
        reDrawGraphText: state.app.diagram.reDrawGraphText,
        isDownloadPDF: state.app.diagram.isDownloadDiagramPDF,
        deleteUserCurveRelatedGraph: state.app.diagram.deleteUserCurveRelatedGraph,
		clearMenuState: state.app.diagram.clearOptionMenu,
		listGraph: state.app.diagram.listGraph,
		contextMenuFunction : state.app.diagram.contextMenuFunction,
        isSelectTouchGroup: state.app.diagram.isSelectTouchGroup,
    };
};
const mapDispatchToProps = (dispatch: any) => ({
    updateControlData: (id: string, data: object) => dispatch(updatePropertiesOfControlAction(id, data)),
    updateSelectControl: (controlId: string, isMultiple : boolean = false) => dispatch(updateSelectControl(controlId, isMultiple)),
    unSelectAllControls: () => dispatch(unSelectAllControls()),
    saveChartIntoControl: (data : ControlModel) => dispatch(saveChartIntoControl(data)),
    saveAllControlsToChartTab: (data : ControlModel[], flagEdit : boolean) => dispatch(saveAllControlsToChartTab(data, flagEdit)),
    updateLineInfo: (data : LineStyleModel, lineId : string | number, controlId : string) => dispatch(updateLineInfo(data, lineId, controlId)),
    publishChartEvents: (payload: any) => dispatch(publishChartEvents(payload)),
    setGapLine: (payload: any) => dispatch(setGapLine(payload)),
    saveNominalVoltList: (payload: number[]) => dispatch(saveNominalVoltList(payload)),
    saveNewNominalVolt: (nominalVolt : number, currentIDChartTab : number) => dispatch(saveNewNominalVolt(nominalVolt, currentIDChartTab)),
    saveChartZoom: (zoom : number, currentIDChartTab : number) => dispatch(saveChartZoom(zoom, currentIDChartTab)),
    toggleJapanElectricMode: (currentIDChartTab : number) => dispatch(toggleJapanElectricMode(currentIDChartTab)),
    createRelatedGraph: (params:any[]) => dispatch(actionCreators.fetch(CREATE_RELATED_GRAPH,"/graph/create-related-graph","POST",params,false,true)),
    undoRelatedGraph: (params:any[]) => dispatch(actionCreators.fetch(UNDO_RELATED_GRAPH,"/graph/undo-related-graph","POST",params,false,true)),
    updateGraph: (params:any) => dispatch(actionCreators.fetch(UPDATE_GRAPH,"/graph/update-graph","POST",params,false,true)),
    updateRelatedGraph: (params:any) => dispatch(actionCreators.fetch(UPDATE_RELATED_GRAPH,"/graph/update-related-graph","POST",params,false,true)),
    updateRelatedGraphText: (params:any) => dispatch(actionCreators.fetch(UPDATE_RELATED_GRAPH_TEXT,"/graph/update-related-graph-text","POST",params,false,true)),
    deleteRelatedGraph: (params:any[]) => dispatch(actionCreators.fetch(DELETE_RELATED_GRAPH,"/graph/delete-related-graph","POST",params,false,true)),
    getGraph:(params:any) => dispatch(actionCreators.fetch(GET_GRAPH,"/graph/get-param","POST",params,false,true)),
    setChartId: (tempId:string, id:string, textId:string, tabId: number) => dispatch(setChartId(tempId, id, textId, tabId)),
    setAddLineToChart: (isAddLineToChart: boolean, controlId: string) => dispatch(addLineToChart(isAddLineToChart, controlId)),
    setAddCalcPointToChart: (isAddCalcPointToChart: boolean, controlId: string) => dispatch(addCalcPointToChart(isAddCalcPointToChart, controlId)),
    saveOpenProjectData: (data: Model.ProjectModel) => dispatch(actionCreators.saveOpenProjectData(data)),
    openUserCurveEvent: (params: any) => dispatch(openUserCurveEvents({type: "OPEN_USER_CURVE", data: { id: params }})),
    openUserCurvePropertiesEvent: (controlId: any, userCurveId: any) => dispatch(openUserCurvePropertiesEvents({type: "OPEN_USER_CURVE_PROPERTIES", data: { controlId: controlId, userCurveId: userCurveId }})),
    deleteRelatedGraphById: (params: any) => dispatch(deleteRelatedGraphById(params)),
    onCreateGraphText: (params:any) => dispatch(actionCreators.fetch(CREATE_GRAPH_TEXT,`/graph/create-graph-text`,"POST",params,false,true)),
    onDeleteGraphText: (params:any) => dispatch(actionCreators.fetch(DELETE,`/graph/delete-graph-text`,"POST",params,false,true)),
    onUpdateGraphText: (params:any) => dispatch(actionCreators.fetch(UPDATE_GRAPH_TEXT,`/graph/update-graph-text`,"POST",params,false,true)),
    onUpdateGraphTextCoordinate: (params:any) => dispatch(actionCreators.fetch("UPDATE_GRAPH_TEXT_COORDINATE",`/graph/update-graph-text`,"POST",params,false,true)),
    onGetGraphTextListByGraphNo: (params:any) => dispatch(actionCreators.fetch(GET_GRAPH_TEXT_LIST_BY_GRAPH_NO,`/graph/get-list-graph-text-by-graph-no`,"POST",params,false,true)),
	saveCurrentIdChartTab: (params: any) => dispatch(saveCurrentIdChartTab(params)),
    getGraphDispBand: (params: any) => dispatch(actionCreators.fetch(GET_GRAPH_DISP_BAND,"/graph/get-graph-disp-band","POST",params,false,true)),
    updateGraphDispBand: (params: any) => dispatch(actionCreators.fetch(UPDATE_GRAPH_DISP_BAND,"/graph/update-graph-disp-band","POST",params,false,true)),
    getGraphName: (params: any) => dispatch(actionCreators.fetch(GET_GRAPH_NAME_GRAPH,"/graph/get-graph-name","POST",params,false,true)),
    setGraphData: (params : any) => dispatch(setGraphData(params)),
    getRelatedGraph: (params: any) => dispatch(actionCreators.fetch(GET_RELATED_GRAPH,"/graph/get-related-graph","POST",params,false,true)),
    updateElementPost: (data: any, controlId: string) => dispatch(actionCreators.fetch(`UPDATE_ELEMENT`, "/diagram/set-param", "POST", data, false, true)),
    deleteGraph: (data:any) => dispatch(actionCreators.fetch(DELETE_GRAPH, "/graph/delete-graph", "POST", data, false, true)),
    deleteGraphByGraphNo: (params: any) => dispatch(deleteGraphByGraphNo(params)),
    setUndoData: (data:Model.UndoModel) => dispatch(undo(data)),
    saveChartIntoChartData: (charts:any, tabId:number, id:any) => dispatch(saveChartIntoChartData(charts, tabId, id)),
    saveCurrentIDDiagramTab: (tabId: number) => dispatch(saveCurrentIdDiagramTab(tabId)),
    setDownloadDiagramPDF:(state: boolean, type:number, image:any)=> dispatch(isDownloadDiagramPDF(state,type,image)),
    deleteRelatedGraphByUserCurveDialog: (params:any) => dispatch(deleteRelatedGraphByUserCurveDialog(params)),
    pressContextMenuFunction: (param: any) => dispatch(pressContextMenuFunction(param)),
    saveMaxIdChartTab: (maxId: number, flag: boolean) => dispatch(saveMaxIdChartTab(maxId, flag)),
	clearOptionMenu: (state:boolean) => dispatch(clearOptionMenu(state)),
    getPDFReport: (params: any) =>
        dispatch(
          actionCreators.fetch(
            DIAGRAM_PDF,
            "/report/get-graph-pdf",
            "POST",
            params,
            false,
            true
          )
        ),
	updateGraphScale: (params:any) => dispatch(actionCreators.fetch(UPDATE_GRAPH_SCALE,"/graph/update-graph-scale","POST",params,false,true)),
    saveListGraphToStore: (data:any) => dispatch(saveListGraph(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(CreateGraph);