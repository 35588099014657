import { CircularProgress, Dialog, DialogContent, DialogTitle } from "@mui/material";
import Konva from "konva";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { Group, Image, Label, Rect, Text, Transformer } from "react-konva";
import { Html } from "react-konva-utils";
import { connect } from "react-redux";
import * as Constant from "../../models/Constants";
import { Coordinates, LineInfoModel, LineType, MsZerocondModel, OptionModel, ProcessMode, UndoModel } from "../../models/Index";
import {
  updateControlHeight,
  updatePropertiesOfControlAction,
  updateSelectControl,
  setTransformFlag,
  addUserCurve,
  updateUserCurve,
  deleteUserCurve,
  openChart,
  updatePropertiesOfTransCenter,
  openDialogEvents,
  openUserCurveEvents,
  openUserCurvePropertiesEvents,
  undo,
  isDrawPowerArrow,
  isAddDrawPowerArrow,
  deleteRelatedGraphByUserCurveDialog,
  clearOptionMenu,
  setRepositionElement,
  updateElementPosition,
} from "../../store/Actions";
import OptionMenu from "../contextMenu/OptionMenu";
import theme from "../../themes/globalStyles";
import MsZerocondEditDialog from "../dialogs/MsZerocondEditDialog";
import leftArrow from "../../images/control/blue_arrow.svg";
import rightArrow from "../../images/control/red_arrow.svg";
import { addListToData } from "../../utils/DataConverter";
import UserCurvesDialog from "../dialogs/UserCurvesDialog";
import DraggablePaper from "../common/DraggablePaper";
import { doGetValuesTransCenter } from "../../utils/TransCenterFunction";
import lineSvg from "../../images/control/line.svg";
import lineGreenSvg from "../../images/control/line_green.svg";
import lineRedSvg from "../../images/control/line_red.svg";
import imageSvg from "../../images/control/母線.svg";
import imageRedSvg from "../../images/control/母線_red.svg";
import imageGreenSvg from "../../images/control/母線_green.svg";
import { getElementKindValue } from "../../utils/ElementFunction";
import { getBottomControl, getTopControl } from "../../utils/element";
import doGetSourceVolt, { doGetTransCenterSourceVolt } from "../../utils/mssv3Doc";
import { doGetRefV1 } from "../../utils/ZeroCondFunction";
import UserCurveDetailDialog from "../dialogs/UserCurveDetailDialog";
import { getElements, post } from "../CallApi";
import { getSymbolAndBackColor } from "../../utils/drawElement";
import { calcContextMenuPosition, calcDragBoundPosition, calcElementSize, getMenuOptionHeight } from "../../utils/PositionCalculation";
import { actionCreators } from "../../store";
import * as Model from "../../models/Index";
import { doDrawSymbolGroup } from "../graph/DCView";
import { MS_ZEROCOND } from "../../models/ElementKind";
import { doGetConnectElement } from "../../utils/ElementList";

//#region Props
type CreateMsZerocondProps = {
  id: string;
  image: string;
  x: number;
  y: number;
  offsetX: number;
  offsetY: number;
  width: number;
  height: number;
  type: string;
  isSelected: boolean;
  isHidden: boolean;
  rotation: number;
  lineDiagramRef: any;
  parentGroupId: number | null;
  chart: LineInfoModel[];
  properties: MsZerocondModel;
  gridSizeController: {
    width: number;
    height: number;
    scale: number;
    stageOffsetX: number;
    stageOffsetY: number;
  };
  isOnShiftKey: boolean;
};

export type PureCreateMsZerocondProps = CreateMsZerocondProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;
//#endregion

const CONTROL_POS_FACTOR = 2;

const CreateMsZerocond = memo((props: PureCreateMsZerocondProps) => {
  //#region Fields
  const {
    id,
    image,
    x,
    y,
    offsetX,
    offsetY,
    width,
    height,
    type,
    isSelected,
    isHidden,
    rotation,
    lineDiagramRef,
    parentGroupId,
    processMode,
    checkZerocondConnect,
    chart,
    properties,
  } = props;

  const {
    // getElementParam,
    // updateElementPost,
    // addUserCurveDB,
    // updateUserCurveDB,
    // deleteUserCurveDB,
    diagramDataUpdate,
    diagramData,
    mainDiagramData,
    projectData,
    currentTabId,
    gridSizeController,
    openDialogData,
    openUserCurveData,
    userCurveProperties,
    m_bModePM,
    modeViewOnly,
    drawPowerArrow,
    clearMenuState,
    isSelectTouchGroup,
    isOnShiftKey,
    elementGroups,
    tcGroups,
    elementReposition,
    updateElementPosition,
    setRepositionElement,
    mappedDataFromBE,
    infoSkeleton,
    userId,
    userRole,
    updateSelectControl,
    updateControlData,
    updateControlHeight,
    setTransformFlag,
    addUserCurve,
    updateUserCurve,
    deleteUserCurve,
    setOpenChart,
    updateTransCenterProps,
    openDialogEvents,
    openUserCurvePropertiesEvent,
    deleteRelatedGraphByUserCurveDialog,
    openUserCurveEvent,
    setUndoData,
    isDrawPowerArrow,
    isAddDrawPowerArrow,
    clearOptionMenu
} = props;

  const [imageStatus, setImageStatus] = useState(image);
  const [lineStatus, setLineStatus] = useState(lineSvg);

  var imageObj = new window.Image();
  imageObj.src = imageStatus;
  
  var lineObj = new window.Image();
  lineObj.src = lineStatus;

  var leftArrowObj = new window.Image();
  leftArrowObj.src = leftArrow;

  var rightArrowObj = new window.Image();
  rightArrowObj.src = rightArrow;
  
  const [connectedPoints, setConnectedPoints] = useState([])
  const [isOpenUserCurvesDialog, setIsOpenUserCurvesDialog] = useState(false);
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [menuPosition, setMenuPosition] = useState<Coordinates>({ x: 0, y: 0 });
  const [isOpenEditorDialog, setIsOpenEditorDialog] = useState(false);
  const [groupPosition, setGroupPosition] = useState({ x: x, y: y });
  const [dragStartPosition, setDragStartPosition] = useState({ x: x, y: y, labelX: groupPosition.x + Constant.ELEMENT_SIZE, labelY: groupPosition.y + CONTROL_POS_FACTOR * (height / 2) });
  const [isResizing, setIsResizing] = useState(false);
  const [heightTransform, setHeightTransform] = useState(0);
  const [labelPosition, setLabelPosition] = useState({
    x: groupPosition.x + Constant.ELEMENT_SIZE,
    y: groupPosition.y + CONTROL_POS_FACTOR * (height / 2),
  });
  const [arrowPosition, setArrowPosition] = useState({
    x: groupPosition.x,
    y: groupPosition.y + height - Constant.ELEMENT_SIZE,
  });

  const transformRef = useRef<Konva.Transformer>(null);
  const transformMoveRef = useRef<Konva.Transformer>(null);
  const groupRef = useRef<any>();
  const topRef = useRef<any>();
  const bottomRef = useRef<any>();
  const labelRef = useRef<any>();
  const leftArrowRef = useRef<any>();
  const rightArrowRef = useRef<any>();

  const [initialValue, setInitialValue] = useState(properties);
  const [loadingFlag, setLoadingFlag] = useState(false);

  const [isDefaultVoltage, setIsDefaultVoltage] = useState(true);
  const [isConnectTrans1OrTranscott,setIsConectTrans1OrTranscott] = useState(false);
  const [isVoltText,setIsVoltText] = useState('');
  const [listUserCurves, setListUserCurves] = useState(properties.listUser?[...properties.listUser]:[])

  const [isOpenUserCurveProperties, setIsOpenUserCurveProperties] = useState(false)
  const [selectItem, setSelectItem] = useState<any>(null);
  const [rightConnectElement,setRightConnectElement] = useState(doGetConnectElement(diagramData.find((e: any) => e.tabId === currentTabId).shape,
  diagramData.find((e: any) => e.tabId === currentTabId).shape?.find((item:any) => item.id === id),Constant.RIGHT_CENTER,false));

  // if(isDefaultVoltage){
  //     let shapes = diagramData[0].shape
  //     let res = doGetSourceVolt(
  //       {
  //         id: id,
  //         image: image,
  //         x: x,
  //         y: y,
  //         offsetX: offsetX,
  //         offsetY: offsetY,
  //         width: width,
  //         height: height,
  //         type: type,
  //         isSelected: isSelected,
  //         rotation: rotation,
  //         chart: chart,
  //         isRotationEnabled: false,
  //         parentGroupId,
          
  //         properties: properties
  //       },
  //       shapes
  //     )
  //     if(res.voltage > 0){
  //       initialValue.voltage = res.voltage
  //     }
  //     else{
  //       initialValue.voltage = properties.voltage
  //     }
  //     setIsDefaultVoltage(false)
  // }
  // const labelTexts = doGetRefV1(processMode, props as any);
  // const [labelTexts, setLabelTexts] = useState<any>([])
  const [isOnOpenMenu, setIsOnOpenMenu] = useState<boolean>(false);
  const [labelTexts, setLabelTexts] = useState<any>({title:'',body:'',textHeight:0,textWidth:0})

  //#endregion

  //#region useEffect
  useEffect(() => {
    // setLabelTexts(doGetRefV1(processMode, props as any))
    const labelFirst = doGetRefV1(processMode, props as any)
    const title = labelFirst.shift()
    let txt = ""
    if(labelFirst.length > 0){
      labelFirst.map((item:any, index:any) =>{
        txt += item.value +'\n'
      })
      txt = txt.slice(0, -1)
    }
    let labelTexts1 = new Konva.Text({
      text: txt,
      });
      setLabelTexts({title:title?.value,body:labelTexts1.attrs.text,textHeight:labelTexts1.height(),textWidth:labelTexts1.textWidth})
  }, [processMode,diagramDataUpdate])

  useEffect(()=>{
    if(isSelected){
      if(isOnOpenMenu) {
        setIsOnOpenMenu(false)
      }
      else
      {
        setIsOpenMenu(false)
      }
    }
  },[clearMenuState])

  useEffect(()=>{
    if(elementReposition?.id)
    {
      const groupsData = currentTabId === 1 ? elementGroups : tcGroups[diagramData.find((x:any)=>x.id == id)?.parentTcId ?? 0]
      const peekGroupId = groupsData?.byEleId[elementReposition.id as any]?.at(-1) ?? -1
      const elementIds = groupsData?.byId[peekGroupId]?.elementIds ?? []
      if(elementIds.includes(id) || diagramData[0].shape.find((item:any)=>(item.isSelected && item.id == id)))
      {
        setGroupPosition({
          x: dragStartPosition.x,
          y: dragStartPosition.y,
        });

        labelRef.current.to({
          x: dragStartPosition.labelX,
          y: dragStartPosition.labelY,
        });
        
        groupRef.current.to({
          x: dragStartPosition.x,
          y: dragStartPosition.y,
        });

        updateElementPosition(id, dragStartPosition.x, dragStartPosition.y)
      }
    }
  },[elementReposition])

  useEffect(() => {
    if (x >= 0 && y >= 0 && (groupPosition.x !== x || groupPosition.y !== y)) {
      setGroupPosition({x, y})
    }
  }, [x, y])
  
  useEffect(()=>{
    if(userCurveProperties?.controlId === id && userCurveProperties !== undefined) {
      let userCurve = properties.listUser.find(x => x.userCurveId === userCurveProperties.userCurveId)
      userCurve && setSelectItem(userCurve)
      setIsOpenUserCurveProperties(true)
    }
  },[userCurveProperties])

  useEffect(() => {
    setIsOpenMenu(false)
    if(processMode == ProcessMode.POWER_FLOW && projectData.flowViewMethod == 2)
    {
      const pElement = diagramData[0].shape.find((x:any)=>x.id === id)
      let result = doDrawSymbolGroup(pElement,processMode)
      if(pElement && result && diagramData[0].shape.filter((x:any)=>x.type == MS_ZEROCOND).length > 1)
      {
        if(!Array.isArray(result))
          {
            result = [result]
          }
        isAddDrawPowerArrow(result)
      }
      else
      {
        isDrawPowerArrow(result)
      }
    }
    else{
      isDrawPowerArrow([]);
    }
  }, [processMode,projectData.flowViewMethod, properties.viewFlowResult])

  useEffect(() => {
    setInitialValue(properties);
  },[properties])
  
  useEffect(() => {   
    const self = {
      id, x, y, offsetX, offsetY, width, height, type, rotation,
    }
    const connectPoints = []
    let toCheckShapes = diagramData[0].shape

    let bottomControl = getBottomControl(self as any, toCheckShapes,false)
    while (bottomControl.code === 0) {
      connectPoints.push({ x: bottomControl.data.x, direction : 'bottom'})
      toCheckShapes = toCheckShapes.filter((e : any) => e.id !== bottomControl.data.id)
      bottomControl = getBottomControl(self as any, toCheckShapes,false)
    }
    
    let topControl = getTopControl(self as any, toCheckShapes,false)
    while (topControl.code === 0) {
      connectPoints.push({ x: topControl.data.x, direction : 'top'})
      toCheckShapes = toCheckShapes.filter((e : any) => e.id !== topControl.data.id)
      topControl = getTopControl(self as any, toCheckShapes,false)
    }
    
    setConnectedPoints(connectPoints as any)
  },[checkZerocondConnect])

  useEffect(() => {
    if (isSelected && !parentGroupId) {
      transformRef.current?.nodes([groupRef.current]);
    } else {
      transformRef.current?.nodes([]);
    }
    transformRef.current?.getLayer()?.batchDraw();
  }, [isSelected, parentGroupId, heightTransform]);

  useEffect(() => {
    if (!isSelected) setIsOpenMenu(false);
  }, [isSelected]);

    
  useEffect(() => {
    if(openDialogData?.id === id && openDialogData !== undefined) {
      openDialogEvents(({type: "OPEN_DIALOG", data: { }}))
      handleOpenEditorDialog()
    }
  }, [openDialogData]);

  useEffect(() => {
    if(openUserCurveData?.id === id && openUserCurveData !== undefined) {
      setIsOpenUserCurvesDialog(true)
    }
  }, [openUserCurveData]);

  useEffect(() => {
    // check grid size; label, icon position when rotate, move and change height
    // handleGridSize(groupPosition.x, groupPosition.y, height, rotation);
    calcLabelPos();
    calcArrowPos();
    if (diagramData[0].tabId != 1){
      // TransCenter
      doSetValuesTransCenter();
    }
  }, [groupPosition, height, rotation]);

  useEffect(() => {
    switch(getSymbolAndBackColor(infoSkeleton,processMode,properties).symbolColor){
      case "#008040":
        setLineStatus(lineGreenSvg);
        setImageStatus(imageGreenSvg);
        break;
      case "#FF0000":
        setLineStatus(lineRedSvg);
        setImageStatus(imageRedSvg);
        break;
      default:
        setLineStatus(lineSvg);
        setImageStatus(imageSvg);
        break;
    }
  },[getSymbolAndBackColor(infoSkeleton,processMode,properties).symbolColor])

  useEffect(() => {
    setRightConnectElement(doGetConnectElement(diagramData.find((e: any) => e.tabId === currentTabId).shape,
    diagramData.find((e: any) => e.tabId === currentTabId).shape?.find((item:any) => item.id === id),Constant.RIGHT_CENTER,false));
  },[x,y,diagramData.find((e: any) => e.tabId === currentTabId).shape])
  //#endregion

  //#region Method
  const handleOKUserCurveDetailForm = async (data: any) => {
    const foundUserCurve = listUserCurves.find((curve:any) => curve.userCurveId === data.userCurveId)        
    if (foundUserCurve) { // update  
      setLoadingFlag(true);
      const request = { 
        userId: userId,
        projectId: projectData.projectId,
        elementId: parseInt(id),
        params:{...data},
        ownerProject: projectData.ownerId

      }
      const result = await post("/diagram/edit-curve-user", request);
      if (result.success) {
        setLoadingFlag(false);
        handleUpdateCurveUserSuccess(id, result.data);
        updateUserCurve(id,data);
      } else {
        handleSubmitError(result.error);
        setLoadingFlag(false);
      }
        //setOpenChart(true, LineType.USER_CURVE, data.userCurveId??data.user_curve_id)
    } else { // add new
        addUserCurve(id, data)
    }
    setSelectItem(null)
    setIsOpenUserCurveProperties(false)
  }

  const handleCancelUserCurveDetailForm = () => {
    setSelectItem(null)
    setIsOpenUserCurveProperties(false)
    openUserCurvePropertiesEvent('', '')
  }
  
  const doSetValuesTransCenter = () => {
    // TransCenter
    let transCenter = mainDiagramData.shape.find(
      (e: any) => e.properties?.tabId === diagramData[0].tabId
    );

    if (transCenter) {
      let tmpCalc = doGetValuesTransCenter(transCenter.properties, diagramData[0].shape);
      transCenter.properties = tmpCalc.newProps;
      updateTransCenterProps(transCenter.id, transCenter.properties);
    }
  };

  const handleContextMenu = (e: any) => {
    e.evt.preventDefault();
    if(e.evt.shiftKey) return
    setIsOnOpenMenu(true)
    updateSelectControl(id);

    const menuHeight = getMenuOptionHeight()
    const clientWidth = lineDiagramRef.current.clientWidth
    const isTransCenter = currentTabId == 1 ? false : true;
    let {posX, posY} = calcContextMenuPosition(
      e, menuHeight, clientWidth, x, y, gridSizeController, isTransCenter
    )
    clearOptionMenu(!clearMenuState)
    
    setMenuPosition({
      x: posX,
      y: posY,
    });
    setIsOpenMenu(true);
  };

  const handleClick = (e: any) => {
    if (e.type === "click") setIsOpenMenu(false);
    if(parentGroupId && parentGroupId > 0) return;
    if (e.evt.shiftKey || isSelectTouchGroup) {
      updateSelectControl(id, true);
    } else {
      updateSelectControl(id);
    }
  };

  const handleOpenMenu = (value: boolean) => {
    setIsOpenMenu(value);
    updateSelectControl(id);
  };

  const handleOpenEditorDialog = async () => {
    let params ={
      userId: userId,
      projectId: projectData.projectId,
      elementId: id,
      elementType: type,
      data: properties,
      ownerProject: projectData.ownerId

    };
    setLoadingFlag(true);
    // getElementParam(params, id);
    const result = await post("/diagram/get-param", params);
    if (result.success) {
      handleGetSuccess(result.data);
    } else {
      handleSubmitError(result.error);
    }
    openDialogEvents(({type: "OPEN_DIALOG", data: { id: '' }}))
  };

  const handleCancelEditorDialog = () => {
    setIsOpenEditorDialog(false);
  };
  const handleUpdateSuccess = async (data: any) => {
    console.log('>>>> handle Successs');
    if(data && data.resultCode == 0){
      const result = await getElements(userId, projectData.projectId, data.data.element_ids,projectData.ownerId)
      if (result.success) {
        mappedDataFromBE(result.data)
      } else {
        handleSubmitError(result.error)
      }
    }
    setLoadingFlag(false);
  }
  // updateElementPostのAPI呼び出しエラー時
  const handleSubmitError = (error: any) => {
    console.log('>>>> handle Error');
    console.log(error);
    setLoadingFlag(false);
  }

  const handleUpdate = async (data: any) => {
    const updateData = {
      ...data,
    };
    setIsOpenEditorDialog(false);
    // call api
    const request = { 
      userId: userId,
      projectId: projectData.projectId,
      elementType: getElementKindValue(props.type),
      elementId: id,
      param: {...updateData},
      ownerProject: projectData.ownerId
    }
    // updateElementPost(request, id);
    setLoadingFlag(true);
    const result = await post("/diagram/set-param", request);
    if (result.success) {
      handleUpdateSuccess(result.data);
      if(result.data && result.data.resultCode == 0){
        setUndoData({type:"UPDATE_PROPERTIES_ELEMENT", dataUndo:{}} as UndoModel);
        updateControlData(id, updateData);
        setInitialValue(data);
      }
    } else {
      handleSubmitError(result.error);
    }
    openDialogEvents({type: "RELOAD", data: { }});
  };

  const dragBoundFunc = (e: any, mode: string) => {
    // let rect = groupRef.current.getClientRect();
    const newPosition = calcDragBoundPosition(
      e, groupPosition, rotation, height, mode, gridSizeController
    );

    return {
      x: newPosition.newX,
      y: newPosition.newY,
    };
  };

  const calcLabelPos = (e?: any) => {
    let newX = groupPosition.x,
      newY = groupPosition.y;
    if (e?.type === "dragmove") {
      newX = groupRef.current.attrs.x;
      newY = groupRef.current.attrs.y;
    }

    const newPosition = {
      x: newX + Constant.ELEMENT_SIZE,
      y: newY + CONTROL_POS_FACTOR * (height / 2),
    };

    if (rotation !== 0) {
      newPosition.x = newX + CONTROL_POS_FACTOR * (height / 2);
      newPosition.y = newY - Constant.ELEMENT_SIZE;
    }

    setLabelPosition(newPosition);
  };

  const calcArrowPos = (e?: any) => {
    let newX = groupPosition.x,
      newY = groupPosition.y;
    if (e?.type === "dragmove") {
      newX = groupRef.current.attrs.x;
      newY = groupRef.current.attrs.y;
    }

    const newPosition = {
      x: newX,
      y: newY + height - Constant.ELEMENT_SIZE,
    };

    if (rotation !== 0) {
      newPosition.y = newY;
      rotation === -90 &&
        (newPosition.x = newX + height - Constant.ELEMENT_SIZE);
      rotation === 90 && (newPosition.x = newX);
    }

    setArrowPosition(newPosition);
  };

  const handleDragStart = (e: any) => {
    groupRef.current.moveToTop()
    transformRef.current?.moveToTop()
    labelRef.current?.moveToTop()
    leftArrowRef.current?.moveToTop()
    rightArrowRef.current?.moveToTop()
    setDragStartPosition({x: groupPosition.x, y: groupPosition.y, labelX: labelPosition.x, labelY: labelPosition.y});
    setIsOpenMenu(false);
  }

  const handleDragmove = (e: any) => {
    calcLabelPos(e);
    calcArrowPos(e);
  };

  const onDragEnd = (e: any) => {
    if (
      e.currentTarget.attrs.x === groupPosition.x &&
      e.currentTarget.attrs.y === groupPosition.y
    ) {
      return;
    }

    let newX =
      Math.round(e.target.x() / Constant.POINTER_WIDTH) *
      Constant.POINTER_WIDTH;
    let newY =
      Math.round(e.target.y() / Constant.POINTER_HEIGHT) *
      Constant.POINTER_HEIGHT;
         
    // check new position is outside grid
    if (newX < 0 || newY < 0){
      setRepositionElement(id, !(elementReposition?.state??true))
      newX = dragStartPosition.x;
      newY = dragStartPosition.y;
    }
    
    setGroupPosition({
      x: newX,
      y: newY,
    });

    e.target.to({
      x: newX,
      y: newY,
    });
  };

  // Mobile Touch Events
  var isMoving = false
  var isLongPress = false
  
  const handleTouchStart = useCallback((e:any) => {
    setIsOpenMenu(false)
    isLongPress = true
    isMoving = false
    !isSelectTouchGroup && setTimeout(() => {
      if (isLongPress && !isMoving) {
        handleContextMenu(e)
      }
    }, 1000)
  },[x,y,isSelectTouchGroup])

  const handleTouchMove = useCallback(() => {
    isMoving = true
  },[x,y,isSelectTouchGroup])

  const handleTouchEnd = useCallback((e:any) => {
    isLongPress = false
  },[x,y,isSelectTouchGroup])

  const handleMouseDown = (e: any, mode: string) => {
    const container = e.target.getStage().container();
    container.style.cursor = rotation === 0 ? "ns-resize" : "ew-resize";
    setIsResizing(true);
  };

  const handleMouseMove = (e: any, mode: string) => {
    if (!isResizing) return;
    setHeightTransform(e.evt.movementY);
  };

  const handleMouseUp = (e: any, mode: string) => {
    setTransformFlag(true);
    const container = e.target.getStage().container();
    container.style.cursor = "default";

    let newPosX = e.target._lastPos.x // e.evt.offsetX
    let newPosY = e.target._lastPos.y // e.evt.offsetY

    let newEle = calcElementSize(
      newPosX,
      newPosY,
      groupPosition,
      rotation,
      height,
      offsetY,
      mode,
      gridSizeController
    )

    topRef.current.y(0);
    // bottomRef.current.y(newHeight - 7)
    setGroupPosition({ x: newEle.newX, y: newEle.newY });

    updateControlHeight(id, newEle.newHeight, newEle.newX, newEle.newY, newEle.newOffsetY);
    setIsResizing(false);
  };

  const convertDataFromBE = (BEData : any) =>{
    let voltageList: OptionModel[] = [];
    if (BEData.voltageList && BEData.voltageList.length > 0){
      BEData.voltageList.forEach((element: any) => {
        if(element.lvoltage !== null){
          let optionModel: OptionModel = {
              label: element.lvoltage.toString(),
              value: element.lvoltage
          };
          voltageList.push(optionModel)
        }
      });

  }
    return {
      voltageList: voltageList
  }
  }
  const doGetTextVoltage = (sPhase:boolean,partKind:number,voltage:number) =>{
    let text =''
    if(sPhase){
      switch(partKind){
        case Constant.MS_OUTSIDE_NEUTRAL:
          text = (voltage /2).toString()
          break;
        case Constant.MS_OUTSIDE_OUTSIDE:
          text = voltage.toString()
          break;
        case Constant.MS_SEAT_MAIN:
            text = voltage.toString()
            break;
        case Constant.MS_SEAT_T:
          text = voltage.toString()
          break;
      }
    }
    else{
      text = voltage.toString()
    }
    return text
  }
  const handleGetSuccess = (data: any) => {
    let newData = data.data
    let initData = {...initialValue}
    
    initData = addListToData(initData, newData)
    let convertData = convertDataFromBE(initData)
    initData.voltageList = convertData.voltageList
   
    let shapes = diagramData[0].shape
    //Transcenter source voltage
    let result ={} as any 
    if(currentTabId !== 1){
        //inside transcenter
      result = doGetTransCenterSourceVolt(
      shapes,
      {
        id: id,
        image: image,
        x: x,
        y: y,
        offsetX: offsetX,
        offsetY: offsetY,
        width: width,
        height: height,
        type: type,
        isSelected: isSelected,
        rotation: rotation,
        chart: chart,
        isRotationEnabled: false,
        parentGroupId,
        properties: properties
      },
      Constant.GET_TOP_BOTTOM_LEFT_RIGHT,
      false,
      0
    )
    
    }
    else{
        //outside transcenter
      result = doGetSourceVolt(
      {
        id: id,
        image: image,
        x: x,
        y: y,
        offsetX: offsetX,
        offsetY: offsetY,
        width: width,
        height: height,
        type: type,
        isSelected: isSelected,
        rotation: rotation,
        chart: chart,
        isRotationEnabled: false,
        parentGroupId,
        properties: properties
      },
      shapes,
      Constant.GET_TOP_BOTTOM_LEFT_RIGHT,
      false,
      0
    )
    }
    
    if(result.voltage > 0){
      initData.isSourceVoltage = true
    }
    else{
      initData.isSourceVoltage = false
    }

      if(result.sPhase){
        switch(result.upTransKind){
          case Constant.MS_TRANS1:
            initData.calcPoint0.calcKind = Constant.CALC_IK2_OUTSIDE_OUTSIDE;
            // initData.calcPoint0 ={
            //   loadCurrent: initData.calcPoint0.loadCurrent,
            //   isLead: initData.calcPoint0.isLead,
            //   powerFactor: initData.calcPoint0.powerFactor,
            //   calcKind: Constant.CALC_IK2_OUTSIDE_OUTSIDE,
            //   stdCapacity:0,
            //   loadCapacity: initData.calcPoint0.loadCapacity,
            //   voltage:initData.voltage
            // } as CalcPoint
            initData.calcPoint1.calcKind = Constant.CALC_IK2_OUTSIDE_NEUTRAL;
            // initData.calcPoint1 ={
            //     loadCurrent: initData.calcPoint1.loadCurrent,
            //     isLead: initData.calcPoint1.isLead,
            //     powerFactor: initData.calcPoint1.powerFactor,
            //     calcKind: Constant.CALC_IK2_OUTSIDE_NEUTRAL,
            //     stdCapacity:0,
            //     loadCapacity: initData.calcPoint1.loadCapacity,
            //     voltage:initData.voltage
            // } as CalcPoint
            break;
          case Constant.MS_TRANSSCOTT:
            initData.calcPoint0.calcKind = Constant.CALC_IK2_SEAT_T;
            // initData.calcPoint0 ={
            //   loadCurrent: initData.calcPoint0.loadCurrent,
            //   isLead: initData.calcPoint0.isLead,
            //   powerFactor: initData.calcPoint0.powerFactor,
            //   calcKind: Constant.CALC_IK2_SEAT_T,
            //   stdCapacity:0,
            //   loadCapacity: initData.calcPoint0.loadCapacity,
            //   voltage:initData.voltage
            // } as CalcPoint
            initData.calcPoint1.calcKind = Constant.CALC_IK2_SEAT_MAIN;
            // initData.calcPoint1 ={
            //     loadCurrent: initData.calcPoint1.loadCurrent,
            //     isLead: initData.calcPoint1.isLead,
            //     powerFactor: initData.calcPoint1.powerFactor,
            //     calcKind: Constant.CALC_IK2_SEAT_MAIN,
            //     stdCapacity:0,
            //     loadCapacity: initData.calcPoint1.loadCapacity,
            //     voltage:initData.voltage
            // } as CalcPoint
            break;
        }
      }
      else{
        initData.calcPoint0.calcKind = Constant.CALC_IK3;
        // initData.calcPoint0 ={
        //   loadCurrent: initData.calcPoint0.loadCurrent,
        //   isLead: initData.calcPoint0.isLead,
        //   powerFactor: initData.calcPoint0.powerFactor,
        //   calcKind: Constant.CALC_IK3,
        //   stdCapacity:0,
        //   loadCapacity: initData.calcPoint0.loadCapacity,
        //   voltage:initData.voltage
        // } as CalcPoint
        initData.calcPoint1.calcKind = Constant.CALC_IK2;
        // initData.calcPoint1 ={
        //     loadCurrent: initData.calcPoint1.loadCurrent,
        //     isLead: initData.calcPoint1.isLead,
        //     powerFactor: initData.calcPoint1.powerFactor,
        //     calcKind: Constant.CALC_IK2,
        //     stdCapacity:0,
        //     loadCapacity: initData.calcPoint1.loadCapacity,
        //     voltage:initData.voltage
        // } as CalcPoint
      }
      if(result.sPhase){
        let text =''
        if(result.upTransKind === Constant.MS_TRANS1){
          setIsConectTrans1OrTranscott(true)
          initData.sPhase = true
          initData.upTransKind = Constant.MS_TRANS1
          text = doGetTextVoltage(initData.sPhase,Constant.MS_OUTSIDE_OUTSIDE,initData.voltage)
                        + '-' + doGetTextVoltage(initData.sPhase,Constant.MS_OUTSIDE_NEUTRAL,initData.voltage)
          setIsVoltText(text)
        }
        else if(result.upTransKind === Constant.MS_TRANSSCOTT){
          setIsConectTrans1OrTranscott(true)
          initData.sPhase = true
          initData.upTransKind = Constant.MS_TRANSSCOTT
          text = doGetTextVoltage(initData.sPhase,Constant.MS_SEAT_MAIN,initData.voltage)
                        + '-' + doGetTextVoltage(initData.sPhase,Constant.MS_SEAT_T,initData.voltage)
          setIsVoltText(text)
  
        }
    }
    else{
      setIsConectTrans1OrTranscott(false)
      initData.sPhase = false
      initData.upTransKind = 0
    }
    setInitialValue(initData)
    setLoadingFlag(false)
    setIsOpenEditorDialog(true)
  }
  const handleAddUserCurve = async (controlId:string,data:any)=>{
    const request = { 
      userId: userId,
      projectId: projectData.projectId,
      elementId: parseInt(controlId),
      params:{...data},
      ownerProject: projectData.ownerId

    }
    // addUserCurveDB(controlId,request)
    const result = await post("/diagram/add-curve-user", request);
    if (result.success) {
      handleAddCurveUserSuccess(id, result.data);
    } else {
      handleSubmitError(result.error);
    }
  }
  const handleUpdateUserCurve = async (controlId:string,data:any) =>{
    const request = { 
      userId: userId,
      projectId: projectData.projectId,
      elementId: parseInt(controlId),
      params:{...data},
      ownerProject: projectData.ownerId

    }
    // updateUserCurveDB(controlId,request)
    const result = await post("/diagram/edit-curve-user", request);
    if (result.success) {
      handleUpdateCurveUserSuccess(id, result.data);
    } else {
      handleSubmitError(result.error);
    }
  }
  const handleDeleteUserCurve = async (controlId:string,data:any) =>{
    const request = { 
      userId: userId,
      projectId: projectData.projectId,
      elementId: parseInt(controlId),
      userCurveId: data["userCurveId"],
      ownerProject: projectData.ownerId

    }
    // deleteUserCurveDB(controlId,request)
    deleteRelatedGraphByUserCurveDialog({controlId, data: data["userCurveId"], mode : true})
    const result = await post("/diagram/delete-curve-user", request);
    if (result.success) {
      handleDeleteCurveUserSuccess(id, result.data);
    } else {
      handleSubmitError(result.error);
    }
  }
  const handleAddCurveUserSuccess = (controlId:string,data:any) =>{
    addUserCurve(controlId,data)
    setListUserCurves(prev => [...prev, data])
  }
  const handleUpdateCurveUserSuccess =(controlId:string,data:any) =>{
    const foundUserCurve = listUserCurves.findIndex((curve:any) => curve.userCurveId === data["userCurveId"])
    const newList = [...listUserCurves]
    newList[foundUserCurve] = data
    updateUserCurve(controlId,data)
    setListUserCurves(newList)

  }
  const handleDeleteCurveUserSuccess = (controlId:string,data:any) =>{
      const newList = [...listUserCurves]
      newList.splice(newList.findIndex((curve : any) => curve.userCurveId === data), 1)
      let params = {
        userCurveId: data,
        projectId: projectData.projectId,
      }
      deleteUserCurve(controlId,params)
      setListUserCurves([...newList])

  }
  //#endregion
  return (
    <>
      {isOpenMenu &&
        <OptionMenu
          controlId={id}
          isOpenMenu={isOpenMenu}
          setIsOpenMenu={handleOpenMenu}
          onProperties={handleOpenEditorDialog}
          x={menuPosition.x}
          y={menuPosition.y}
          isDisabledDelete={false}
          isDisabledCut={false}
          isDisabledCopy={false}
          isDisabledPaste={false}
          isDisabledOpenGraph={false}
          isDisabledProps={false}
          isDisabledRotate={true}
          onUserCurves={() => setIsOpenUserCurvesDialog(true)}
        />
      }

      <Label
        ref={labelRef}
        listening={false}
        width={Constant.LABEL_WIDTH}
        x={labelPosition.x}
        y={labelPosition.y}
        offsetX={[90, -90].includes(rotation) ? 8 : -5}
        offsetY={
          rotation === 0 ? ((labelTexts.textHeight/12 +1) * Constant.LINE_HEIGHT) / 2 : 0
        }
      >
        <Text
            text={labelTexts.title}
            y={(20)}
            x={rightConnectElement.code != 0 ? 15 : (-height - labelTexts.title.length -30)}
            fill={isSelected ? "red" : '#f800f8'}
          />
        {labelTexts.body.length > 0 &&
          <Text
              y={(Constant.LINE_HEIGHT + 20)}
              text={labelTexts.body}
              fill={isSelected ? "red" : 'blue'}
              x={rightConnectElement.code != 0 ? 15 : (-height - labelTexts.textWidth)}
          />
        }
        {/* {labelTexts.length > 0 &&
         labelTexts.map((item:any, index:any) => {
            
          let content = item.value; 
          let offsetY = index * Constant.LINE_HEIGHT;
          // rotation !== 0 &&
          //   index !== 0 &&
          //   (offsetY = (index - 1) * Constant.LINE_HEIGHT + width + Constant.ELEMENT_SIZE);
          // height reverse for width
          let new_width = - height - 30;
          if(index != 0)
            new_width -= 60;
          return (
            <Text
              y={offsetY+20}  
              x={rightConnectElement.code != 0 ? 15 : new_width}
              key={index}
              text={content}
              fill={isSelected ? "red" : item.color}
            />
          );
        })} */}
      </Label>

      <Group
        ref={groupRef}
        id={id}
        x={groupPosition.x}
        y={groupPosition.y}
        offset={{ x: offsetX, y: offsetY }}
        width={width}
        height={height}
        draggable={processMode === ProcessMode.DRAWING && !m_bModePM && !modeViewOnly && !isOnShiftKey}
        type={type}
        onClick={handleClick}
        onTap={handleClick}
        onContextMenu={handleContextMenu}
        rotation={rotation}
        onDblClick={handleOpenEditorDialog}
        onDblTap={handleOpenEditorDialog}
        onMouseDown={() => transformMoveRef.current?.nodes([groupRef.current])}
        onMouseUp={() => transformMoveRef.current?.nodes([])}
        onDragStart={handleDragStart}
        onDragMove={handleDragmove}
        onDragEnd={onDragEnd}
        onMouseLeave={(e: any) => {
          const container = e.target.getStage().container();
          container.style.cursor = "default";
        }}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <Rect 
          fill={getSymbolAndBackColor(infoSkeleton,processMode,properties).backColor}
          x={0}
          y={0}
          width={width}
          height={height}
        />
        <Image
          fill={getSymbolAndBackColor(infoSkeleton,processMode,properties).backColor}
          image={imageObj}
          width={Constant.ELEMENT_SIZE * 0.2}
          height={height}
          x={Constant.ELEMENT_SIZE / 2 - Constant.ELEMENT_SIZE * 0.2 / 2}
          onMouseEnter={(e: any) => { 
            const container = e.target.getStage().container();
            container.style.cursor = (processMode === ProcessMode.DRAWING && !m_bModePM && !modeViewOnly) ? "move" : "crosshair";
          }}
        />
        {processMode === ProcessMode.DRAWING && !m_bModePM && !modeViewOnly && isSelected && !parentGroupId && (
          <>
            <Rect
              ref={topRef}
              fill="black"
              x={6.75}
              y={0}
              width={7}
              height={7}
              draggable
              onMouseDown={() => setTransformFlag(false)}
              dragBoundFunc={(e: any) => dragBoundFunc(e, "top")}
              onDragStart={(e: any) => handleMouseDown(e, "top")}
              onDragMove={(e: any) => handleMouseMove(e, "top")}
              onDragEnd={(e: any) => handleMouseUp(e, "top")}
              onMouseEnter={(e: any) => {
                const container = e.target.getStage().container();
                container.style.cursor =
                  rotation === 0 ? "ns-resize" : "ew-resize";
              }}
            />
            <Rect
              ref={bottomRef}
              fill="black"
              x={6.75}
              y={height - 7}
              width={7}
              height={7}
              draggable
              onMouseDown={() => setTransformFlag(false)}
              dragBoundFunc={(e: any) => dragBoundFunc(e, "bottom")}
              onDragStart={(e: any) => handleMouseDown(e, "bottom")}
              onDragMove={(e: any) => handleMouseMove(e, "bottom")}
              onDragEnd={(e: any) => handleMouseUp(e, "bottom")}
              onMouseEnter={(e: any) => {
                const container = e.target.getStage().container();
                container.style.cursor =
                  rotation === 0 ? "ns-resize" : "ew-resize";
              }}
            />
          </>
        )}   
        {connectedPoints.length > 0 &&
            connectedPoints.map((point : any, index) => 
              <Image 
                key={index}
                y={point.x - x}
                offsetY={point.direction === 'bottom' ? Constant.ELEMENT_SIZE / 2 : Constant.ELEMENT_SIZE}
                rotation={90}
                image={lineObj}
                height={Constant.ELEMENT_SIZE / 2}  
                width={Constant.ELEMENT_SIZE}
              />            
            )
        }
      </Group>
      {((properties.dropRegular || properties.dropMotor) &&!([Model.ProcessMode.CALCULATION,Model.ProcessMode.IMPROVE_PF,Model.ProcessMode.HARMONIC,Model.ProcessMode.POWER_FLOW].includes(processMode)) && !m_bModePM) && (
        <Image
          ref={leftArrowRef}
          listening={false}
          offsetY={[90, -90].includes(rotation) ? Constant.ELEMENT_SIZE / 4 - 2.5 : -2.5}
          x={arrowPosition.x}
          y={arrowPosition.y}
          image={leftArrowObj}
          width={Constant.ELEMENT_SIZE / 2}
          height={Constant.ELEMENT_SIZE}
        />
      )}
      {(properties.fault && !([Model.ProcessMode.VOLTDROP,Model.ProcessMode.IMPROVE_PF,Model.ProcessMode.HARMONIC,Model.ProcessMode.POWER_FLOW].includes(processMode)) && !m_bModePM) && (
        <>
        <Image
          ref={rightArrowRef}
          listening={false}
          offsetY={[90, -90].includes(rotation) ? Constant.ELEMENT_SIZE / 4 : 0}
          offsetX={-Constant.ELEMENT_SIZE / 2}
          x={arrowPosition.x}
          y={arrowPosition.y}
          image={rightArrowObj}
          width={Constant.ELEMENT_SIZE * 2}
          height={Constant.ELEMENT_SIZE * 2}
        />
        <Text
          text={properties.pointText}
          fontSize={10}
          fill="red"
          offsetY={[90, -90].includes(rotation) ? Constant.ELEMENT_SIZE / 2 : 0}
          offsetX={-Constant.ELEMENT_SIZE / 2}
          x={arrowPosition.x - 15}
          y={arrowPosition.y - 2}
        />
        </>
      )}

      <Transformer
        ref={transformRef}
        rotateEnabled={false}
        resizeEnabled={false}
        keepRatio={false}
        borderDash={[4]}
        anchorStroke="black"
        borderStroke="black"
        anchorFill="black"
      />

      <Transformer
        ref={transformMoveRef}
        rotateEnabled={false}
        resizeEnabled={false}
        keepRatio={false}
        borderEnabled={false}
        anchorStroke="black"
        borderStroke="black"
        anchorFill="black"
      />

      {isOpenEditorDialog && !isHidden && (
        <Html>
          <Dialog open={true} fullWidth
            PaperComponent={DraggablePaper}
          >
            <DialogTitle
              onMouseOver={(e : any) => e.target.style.cursor = 'move'}
              style={{
                paddingTop: "2px",
                paddingBottom: "2px",
                background: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
              }}
            >
              母線
            </DialogTitle>
            <DialogContent>
              <MsZerocondEditDialog
                data={initialValue}
                userRole={userRole}
                onCancel={handleCancelEditorDialog}
                onOK={handleUpdate}
                setIsVoltText ={isVoltText}
                setIsConectTrans1OrTranscott={isConnectTrans1OrTranscott}
                m_bModePM={m_bModePM}
                modeViewOnly={modeViewOnly}
                processMode ={processMode}
              ></MsZerocondEditDialog>
            </DialogContent>
          </Dialog>
        </Html>
      )}

      {/* ============== USER CURVE ============== */}
      {isOpenUserCurvesDialog && !isHidden && (
      <Html>
        <UserCurvesDialog
          type={type}
          addUserCurve={handleAddUserCurve}
          updateUserCurve={handleUpdateUserCurve}
          deleteUserCurve={handleDeleteUserCurve}
          listUserCurves ={listUserCurves}
          controlId={id}
          controlProperties={properties}
          modeViewOnly={modeViewOnly}
          onCancel={() => {setIsOpenUserCurvesDialog(false); openUserCurveEvent('')}}
          onDraw={(data : any) => {   
              setOpenChart(true, LineType.USER_CURVE, data.userCurveId??data.user_curve_id)
              setIsOpenUserCurvesDialog(false)
          }}
        />
      </Html>
      )}

      {isOpenUserCurveProperties && !isHidden &&
        <Html>
            <UserCurveDetailDialog modeViewOnly={modeViewOnly} type={type} selectedRow={selectItem} properties={properties} onOK={handleOKUserCurveDetailForm} onCancel={handleCancelUserCurveDetailForm}></UserCurveDetailDialog>
        </Html>
      }

      {loadingFlag && isSelected && (
        <Html>
          <Dialog open={true} maxWidth={"md"} fullWidth>
          <div id="loadingFlag" style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1500, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
              <CircularProgress />
          </div>
          </Dialog>
        </Html>
      )}
    </>
  );
});

const mapStateToProps = (state: any) => {
  return {
    userId: state.app.user?.userId,
    userRole: state.app.user?.userRole,
    projectData: state.app.projectData,
    processMode: state.app.diagram.processMode,
    checkZerocondConnect: state.app.diagram.checkZerocondConnect,
    diagramData: state.app.diagram.diagramData.filter((r: any) => r.tabId === state.app.diagram.currentIDDiagramTab),
    mainDiagramData: state.app.diagram.diagramData.find((r: any) => r.tabId === 1),
    currentTabId: state.app.diagram.currentIDDiagramTab,
    openDialogData : state.app.diagram.openDialog["OPEN_DIALOG"],
    openUserCurveData: state.app.diagram.openUserCurve["OPEN_USER_CURVE"],
    userCurveProperties: state.app.diagram.openUserCurveProperties["OPEN_USER_CURVE_PROPERTIES"],
    clearMenuState: state.app.diagram.clearOptionMenu,
    m_bModePM: state.app.diagram.m_bModePM,
    modeViewOnly: state.app.diagram.modeViewOnly,
    infoSkeleton: state.app.diagram.infoSkeleton,
    drawPowerArrow: state.app.diagram.isDrawPowerArrow,
    diagramDataUpdate: state.app.diagram.diagramDataUpdate,
    isSelectTouchGroup: state.app.diagram.isSelectTouchGroup,
    tcGroups: state.app.diagram.tcGroups,
    elementGroups: state.app.diagram.elementGroups,
    elementReposition: state.app.diagram.elementReposition,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    updateSelectControl: (controlId: string, isMultiple : boolean = false) =>
      dispatch(updateSelectControl(controlId, isMultiple)),
    updateControlData: (id: string, data: object) =>
      dispatch(updatePropertiesOfControlAction(id, data)),
    updateTransCenterProps: (id: string, data: object) =>
      dispatch(updatePropertiesOfTransCenter(id, data)),
    updateControlHeight: (
      id: string,
      height: number,
      x: number,
      y: number,
      offsetY: number
    ) => dispatch(updateControlHeight(id, height, x, y, offsetY)),
    setTransformFlag: (visible: boolean) => dispatch(setTransformFlag(visible)),
    // updateElementPost: (data: any, controlId: string) => dispatch(actionCreators.fetch(`UPDATE_ZEROCOND${controlId}`, "/diagram/set-param", "POST", data, false, true)),
    // getElementParam: (data: any, controlId: string) => dispatch(actionCreators.fetch(`GET_ZEROCOND${controlId}`, "/diagram/get-param", "POST", data, false, true)),
    addUserCurve: (controlId : string, data : any) => dispatch(addUserCurve(controlId, data)),
    updateUserCurve: (controlId : string, data : any) => dispatch(updateUserCurve(controlId, data)),
    deleteUserCurve: (controlId : string, data : any) => dispatch(deleteUserCurve(controlId, data)),
    setOpenChart: (isOpenChart: boolean, lineType : string, lineId : number) => dispatch(openChart(isOpenChart, lineType, lineId)),
    clearOptionMenu: (state:boolean) => dispatch(clearOptionMenu(state)),
    // addUserCurveDB: (controlId:string,data : any) => dispatch(actionCreators.fetch(`ADD_CURVE_USER_ZEROCOND${controlId}`, "/diagram/add-curve-user", "POST", data, false, true)),
    // updateUserCurveDB: (controlId:string,data : any) => dispatch(actionCreators.fetch(`UPDATE_CURVE_USER_ZEROCOND${controlId}`, "/diagram/edit-curve-user", "POST", data, false, true)),
    // deleteUserCurveDB: (controlId:string,data : any) => dispatch(actionCreators.fetch(`DELETE_CURVE_USER_ZEROCOND${controlId}`, "/diagram/delete-curve-user", "POST", data, false, true)),
    openDialogEvents: (param : any) => dispatch(openDialogEvents(param)),
    openUserCurveEvent: (params: any) => dispatch(openUserCurveEvents({type: "OPEN_USER_CURVE", data: { id: params }})),
    openUserCurvePropertiesEvent: (controlId: any, userCurveId: any) => dispatch(openUserCurvePropertiesEvents({type: "OPEN_USER_CURVE_PROPERTIES", data: { controlId: controlId, userCurveId: userCurveId }})),
    deleteRelatedGraphByUserCurveDialog: (params:any) => dispatch(deleteRelatedGraphByUserCurveDialog(params)),
    mappedDataFromBE: (elements:any) => dispatch(actionCreators.mappedData(elements)),
    setRepositionElement: (id:any, state:boolean) => dispatch(setRepositionElement(id, state)),
    setUndoData: (data:UndoModel) => dispatch(undo(data)),
    isDrawPowerArrow: (data:any) => dispatch(isDrawPowerArrow(data)),
    isAddDrawPowerArrow: (data:any) => dispatch(isAddDrawPowerArrow(data)),
    updateElementPosition:(id:any, x:any, y:any) => dispatch(updateElementPosition(id, x, y)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateMsZerocond);
