import { Box, Button, Checkbox, FormControl, FormControlLabel, Grid, Stack, Tab, Tabs, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {InfoCircuit, MsCapacitorModel } from '../../models/Index';
import TextFieldOptions from "../common/TextFieldOptions";
import { BeamsNumber } from "../../utils/FormatNumber";
import { doGetValueRatedCurrent } from "../graph/trans3/CreateTrans3Graph";
import { MS_PI } from "../../models/Constants";
import * as Constant from "../../models/Constants";
import { MS_MODE_CHECKING } from "../../statics";

export type Image3_6EditDialogFormProps = {
    data: MsCapacitorModel;
    onOK: (data: MsCapacitorModel) => void;
    onCancel: () => void;
    infoCircuit: InfoCircuit;
    userRole: string;
    m_bModePM: boolean;
    processMode:number;
    modeViewOnly: boolean;
};

function a11yProps(index: number) {
    return {
        id: `tab-${index}`,
        "aria-controls": `tabpanel-${index}`,
    };
}

//Tabs------------------
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

const checkboxStyle = {
    padding: '4px 4px 4px 8px',
}

export const MsCapacitorEditDialog = (props: Image3_6EditDialogFormProps) => {
    const { userRole, data, onOK, onCancel,infoCircuit ,m_bModePM,processMode,modeViewOnly} = props;
    const [selectTab, setSelectTab] = useState(0);
    const [isDisableOKBtn, setIsDisableOKBtn] = useState(false);
    // 初期値設定
    const initialValue: MsCapacitorModel = {
        ...data,
        totalOhmR: BeamsNumber(data.totalOhmR,4),
        totalOhmX: BeamsNumber(data.totalOhmX,4),

    }
    const [makeDataInput, setMakeDataInput] = useState(initialValue.makeDataInput); // ModePM
    const handleChangeMakeDataInput = (e: any) => {
        setValue(e.target.name, e.target.checked);
        setMakeDataInput(e.target.checked);
    }
    const { control, handleSubmit, formState: { errors },setValue, getValues, setFocus,setError,clearErrors } = useForm<MsCapacitorModel>({
        mode: 'all',
        criteriaMode: "all",
        defaultValues: initialValue,
    });

    // Submitイベント
    const handleEditSubmit = (value: MsCapacitorModel) => {
        const request = createRequestData(value);
        onOK(request);
    }

    // FormデータからRequestデータを作成する
    const createRequestData = (formValue: MsCapacitorModel) => {
        if(!formValue.totalOhmR){
            formValue.totalOhmR = 0
        }
        if(!formValue.totalOhmX){
            formValue.totalOhmX = 0
        }
        if(!formValue.rushCurrent){
            formValue.rushCurrent = 0
        }
        if(!formValue.peakTime){
            formValue.peakTime = 0
        }
        if(!formValue.reactor){
            formValue.reactor =0
        }
        if(!formValue.loadFactor){
            formValue.loadFactor =0
        }
        if(!formValue.powerFactor){
            formValue.powerFactor = 0
        }
        let request = {
            ...formValue
        } as MsCapacitorModel;
        return request;
    }

    // キャンセル
    const handleCancel = () => {
        onCancel();
    };

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        if (newValue >= 0) {
            if (getValues('refNo') == "" ||getValues('refNo') == null || getValues('refNo') == undefined ) {
                setError("refNo",{ type: 'custom', message: '必須項目です。入力してください。' });
            }
            else if (isNaN(Number(getValues('voltage'))) || Number(getValues('voltage')) <= 0 || !/^[0-9\b]+$/.test(getValues('voltage').toString())) {
                setError("voltage",{ type: 'custom', message: '整数 > 0 を入力してください。' });
            }
            else if (isNaN(Number(getValues('capacity'))) || Number(getValues('capacity')) <= 0 || !/^[0-9]*[.]?[0-9]+$/.test(getValues('capacity').toString())) {
                setError("capacity",{ type: 'custom', message: '数字 > 0 を入力してください。' });
            }
            else if (isNaN(Number(getValues('reactor'))) || Number(getValues('reactor')) < 0 || !/^[0-9\b]+$/.test(getValues('reactor').toString())) {
                setError("reactor",{ type: 'custom', message: '整数 >= 0 を入力してください。' });
            }
            else if (isNaN(Number(getValues('powerFactor'))) || Number(getValues('powerFactor')) < 0 || !/^[0-9]*[.]?[0-9]+$/.test(getValues('powerFactor').toString())|| Number(getValues('powerFactor')) >= 1) {
                setError("powerFactor",{ type: 'custom', message: '0 <= 数字 < 1 を入力してください。' });
            }
            else if (isNaN(Number(getValues('loadFactor'))) || Number(getValues('loadFactor')) < 0 || !/^[0-9]*[.]?[0-9]+$/.test(getValues('loadFactor').toString())|| Number(getValues('loadFactor')) > 1) {
                setError("loadFactor",{ type: 'custom', message: '数字 0 ~ 1 を入力してください。' });
            }
            else if (isNaN(Number(getValues('totalOhmX'))) || Number(getValues('totalOhmX')) < 0 || !/^[0-9]*[.]?[0-9]+$/.test(getValues('totalOhmX').toString())) {
                setError("totalOhmX",{ type: 'custom', message: '数字 >= 0 を入力してください。' });
            }
            else if (isNaN(Number(getValues('totalOhmR'))) || Number(getValues('totalOhmR')) < 0 || !/^[0-9]*[.]?[0-9]+$/.test(getValues('totalOhmR').toString())) {
                setError("totalOhmR",{ type: 'custom', message: '数字 >= 0 を入力してください。' });
            }
            else{
                setSelectTab(newValue);
            }
        }
        // if (newValue === 1) {
        //     if (isNaN(Number(getValues('staticCapacity'))) || Number(getValues('staticCapacity')) === 0) {
        //         setFocus("staticCapacity");
        //     } else {
        //         setSelectTab(newValue);
        //     }
        // } else
        //     setSelectTab(newValue);

    };
    // const handleInput = (e: any) => {
    //     setValue(e.target.name, e.target.value)
    //     const isInteger = /^\d*$/.test(getValues("voltage").toString())
    //     console.log(isInteger); 
    //     if(getValues("voltage") > 0 && isInteger){
    //         // setIsDisableCalcBtn(false);
    //         setIsDisableOKBtn(false);
    //     }
    //     else{
    //         // setIsDisableCalcBtn(true);
    //         setIsDisableOKBtn(true);
    //     }
    // }
    const handleChangeFormat = (e: any) => {
        setValue(e.target.name, e.target.value,{shouldValidate:true});
    }

    const doGetStaticCapacity= (n_Frequency:number,PI:number,lvoltage:number,capacity:number) =>{//4
        let dStaticCpacity = 0
         if(lvoltage <=0 || n_Frequency <=0){
            dStaticCpacity = 0;
         }else{
            dStaticCpacity = capacity * 1000 /lvoltage/lvoltage /(2 * PI * n_Frequency)
         }
         return BeamsNumber(dStaticCpacity,4)
    }
    const doGetRatedCurrent =(lvoltage:number,capacity:number) =>{
           let dRatedCurrent = 0
           if(lvoltage <=0){
            dRatedCurrent = 0;
           }
           else{
            dRatedCurrent = capacity * 1000 / lvoltage / Math.sqrt(3.0)
           }
           return BeamsNumber(dRatedCurrent,4)
    }
    const doGetPhaseCurrent = (powerFactor:number,PI:number) =>{
        let phaseCurrentText =""
           if(powerFactor < -1 || powerFactor > 1){
              phaseCurrentText = "0";
            //   setIsDisableOKBtn(true)
           }
           let dPhaseCurrent = Math.acos(powerFactor) * 180 / PI
            phaseCurrentText ="+"+BeamsNumber(dPhaseCurrent,4)
           
           return phaseCurrentText
    } 
     
    //Change tab1
    //handle chang voltage , capacity , loadFactor
    const doSetValueCapacitor =(e:any) =>{
        setValue(e.target.name,e.target.value,{shouldValidate:true})
        checkInput(e)
       const n_Frequency = infoCircuit.frequency//hard code
       let lvoltage = getValues("voltage")
       let capacity = getValues("capacity")
       let loadFactor = getValues("loadFactor")
       let reactor = getValues("reactor")
       let powerFactor = getValues("powerFactor")
    //    if(loadFactor < 0 || loadFactor > 1|| reactor < 0 || capacity <= 0 || powerFactor < 0 || powerFactor > 1){
    //     setIsDisableOKBtn(true)
    //    }
    //    else {
    //     if(loadFactor >= 0 && loadFactor <= 1 && reactor >= 0 && capacity >0 && powerFactor >= 0 && powerFactor <= 1){
    //         setIsDisableOKBtn(false)
    //     }else{
    //         setIsDisableOKBtn(true)
    //     }
    //    }
       let staticCapacity = doGetStaticCapacity(n_Frequency,MS_PI,lvoltage,capacity*loadFactor)
       let ratedCurrent = doGetRatedCurrent(lvoltage,capacity *loadFactor)
       let phaseCurrent = ""
       if(powerFactor>=0 && powerFactor <=1){
        phaseCurrent  = doGetPhaseCurrent(powerFactor,MS_PI)
       }
       else{
        phaseCurrent ="+90"
       }
       
       setValue("staticCapacity",staticCapacity)
       setValue("ratedCurrent",ratedCurrent)
       setValue("ratedCurrent2",ratedCurrent)

       setValue("phaseCurrent",phaseCurrent)
    
    }
    const doGetValueOhmR = (dOhmZ:number,dXLR:number) =>{
          let result = Math.sqrt(1 + Math.pow(dXLR,2))
          if(result === 0){
            return 0;
          }
          let dOhmR = dOhmZ/ result
          return dOhmR
    }
    const doGetTotalHenryL = (n_Frequency:number,PI:number,lvoltage:number,dRatedCurrent:number,reactor:number,dOhmR:number,dXLR:number) =>{
         let result = 2 * PI *n_Frequency
        if(n_Frequency <=0 || dRatedCurrent <=0){
            return 0
         }
         let dHenryL1 = (lvoltage /Math.sqrt(3.0) / dRatedCurrent*1000) *(reactor /100) / (result)
         let dHenryL2 = dOhmR *dXLR / result
         let dTotalHenryL = dHenryL1 +dHenryL2
         return dTotalHenryL
    }
    const doGetValueConstant = (dStaticCpacity:number,dOhmR:number,dTotalHenryL:number) =>{
        if(dOhmR === 0){
            return 0
        }
        let dConstant = Math.sqrt(4 * dTotalHenryL * 1000 / dStaticCpacity - dOhmR *dOhmR) / dOhmR
        return dConstant
    }
    const doGetPeakValueTime =(n_Frequency:number,PI:number,lvoltage:number,reactor:number,dRatedCurrent:number,dStaticCpacity:number,dOhmZ:number,dXLR:number )=>{
        if(n_Frequency <=0||dStaticCpacity <=0){
            return 0;
        }
        let dOhmR = doGetValueOhmR(dOhmZ,dXLR)
        if(dOhmR === 0){
            return 0
        }
        let dTotalHenryL = doGetTotalHenryL(n_Frequency,PI,lvoltage,dRatedCurrent,reactor,dOhmR,dXLR)
        let dConstant = doGetValueConstant(dStaticCpacity,dOhmR,dTotalHenryL)
        if(dConstant === 0){
            return 0;
        }
        let dPeakTime = 2 * dTotalHenryL / dOhmR / dConstant * Math.atan(dConstant) * 1000
        return dPeakTime
    }
    const doGetPeakValueCurrent = (n_Frequency:number,PI:number,lvoltage:number,reactor:number,dRatedCurrent:number,dStaticCpacity:number,dOhmZ:number,dXLR:number) =>{
        if(n_Frequency <=0 || dStaticCpacity <=0 || lvoltage <=0 || dOhmZ <=0){
            return 0;
        }
        let dEm = Math.sqrt(2.0) * lvoltage / Math.sqrt(3.0)
        let dOhmR = doGetValueOhmR(dOhmZ,dXLR)
        let dTotalHenryL = doGetTotalHenryL(n_Frequency,PI,lvoltage,dRatedCurrent,reactor,dOhmR,dXLR)
        let dConstant = doGetValueConstant(dStaticCpacity,dOhmR,dTotalHenryL)
        if(dConstant === 0 || dTotalHenryL === 0){
            return 0
        }
        let dPeakCurrent = dEm / Math.sqrt(dTotalHenryL * 1000 / dStaticCpacity) * Math.exp(-1 / dConstant * Math.atan(dConstant)) * 1000
        dPeakCurrent /= Math.sqrt(2.0)
        return dPeakCurrent
    }
    const doGetPeakCurrent = (n_Frequency:number,PI:number,lvoltage:number,capacity:number,loadFactor:number) =>{
        let totalOhmR = getValues("totalOhmR")
        let totalOhmX = getValues("totalOhmX") 
        let reactor = getValues("reactor") 

        let dRatedCurrent = doGetValueRatedCurrent(lvoltage,capacity*loadFactor)
         let dStaticCpacity = doGetStaticCapacity(n_Frequency,PI,lvoltage,capacity*loadFactor)
         let dOhmZ = Math.sqrt(totalOhmR * totalOhmR + totalOhmX * totalOhmX)
        
         let rushCurent = doGetPeakValueCurrent(n_Frequency,PI,lvoltage,reactor,dRatedCurrent,dStaticCpacity,dOhmZ,5)
         return BeamsNumber(rushCurent,4)
        }
    const doGetPeakTime = (n_Frequency:number,PI:number,lvoltage:number,capacity:number,loadFactor:number,totalOhmR:number,totalOhmX:number,reactor:number) =>{
        let dRatedCurrent = doGetValueRatedCurrent(lvoltage,capacity*loadFactor)
        let dStaticCpacity = doGetStaticCapacity(n_Frequency,PI,lvoltage,capacity*loadFactor)
        let dOhmZ = Math.sqrt(totalOhmR * totalOhmR + totalOhmX * totalOhmX)
        let dPeakTime = doGetPeakValueTime(n_Frequency,PI,lvoltage,reactor,dRatedCurrent,dStaticCpacity,dOhmZ,5)
        return BeamsNumber(dPeakTime,4)
    }
    //Change tab 2
    const doSetValuesCapacitor1=(e:any) =>{
        setValue(e.target.name,e.target.value,{shouldValidate:true})
        checkInput(e)
        const n_Frequency = infoCircuit.frequency //hard code
        let lvoltage = getValues("voltage")
        let capacity = getValues("capacity")
        let loadFactor = getValues("loadFactor")
        let reactor = getValues("reactor")
        let totalOhmR = getValues("totalOhmR")
        let totalOhmX = getValues("totalOhmX")
        let rushCurrent = doGetPeakCurrent(n_Frequency,MS_PI,lvoltage,capacity,loadFactor)
        let peakTime = doGetPeakTime(n_Frequency,MS_PI,lvoltage,capacity,loadFactor,totalOhmR,totalOhmX,reactor)
        setValue("rushCurrent", rushCurrent)
        setValue("peakTime",peakTime)
    
    }
    const checkInput = (e:any) =>{
        setValue(e.target.name, e.target.value,{shouldValidate:true})
        let voltage = getValues("voltage")
        let loadFactor = getValues("loadFactor")
        let reactor = getValues("reactor")
        let powerFactor = getValues("powerFactor")
        let capacity = getValues("capacity")
        let totalOhmR = getValues("totalOhmR")
        let totalOhmX = getValues("totalOhmX")

        const isInteger = /^\d*$/.test(voltage.toString())
        if(voltage <= 0 && !isInteger){
            // setIsDisableCalcBtn(false);
            setIsDisableOKBtn(true);
        }
        else if(loadFactor < 0 || loadFactor > 1|| reactor < 0 || capacity <= 0 || powerFactor < 0 || powerFactor > 1){
            setIsDisableOKBtn(true)
           }
        else if(totalOhmR < 0 || totalOhmX < 0){
            setIsDisableOKBtn(true)
        }
        else{
            if(voltage > 0 && isInteger && loadFactor>=0 && loadFactor <= 1 && reactor>=0 && capacity > 0 && powerFactor >=0 && powerFactor<=1){
                setIsDisableOKBtn(false)
            }else{
            setIsDisableOKBtn(true)
            }
        }

    }
    return (
        <>
            <div style={{ height: '100%', width: '100%' }}>
                <form onSubmit={handleSubmit(handleEditSubmit)} >
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={selectTab} onChange={handleChange}
                            variant="scrollable" 
                            scrollButtons 
                            allowScrollButtonsMobile 
                            sx={{
                                '& .MuiButtonBase-root.MuiTabScrollButton-root.Mui-disabled': {
                                    display: "none"
                                }
                            }}
                        >
                            <Tab label="基本" {...a11yProps(0)} />
                            <Tab label="保護協調" {...a11yProps(1)} />
                        </Tabs>
                    </Box>
                    <div style={{ height: '40vh', overflow: "auto", width: '100%', margin: 'auto' }}>
                        
                        <TabPanel value={selectTab} index={0}>
                            <Grid container my={1}>
                                <Grid item xs={7}>
                                    <Stack spacing={0.3}>
                                        <Stack flexDirection='row' alignItems='center'>
                                            <Typography variant='body2' width='100%'>デバイス名:</Typography>
                                            <Controller
                                                name="refNo"
                                                control={control}
                                                rules={{
                                                    required: '必須項目です。入力してください。',
                                                }}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        error={!!errors?.refNo}
                                                        helperText={errors?.refNo?.message}
                                                        size="small"
                                                        InputProps={{ sx: { height: 32 ,background:m_bModePM || modeViewOnly?'floralwhite':'white', color:modeViewOnly?"grey":"black"} }}
                                                        inputProps={{readOnly:modeViewOnly}}
                                                        disabled={m_bModePM}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                        <Stack flexDirection='row' alignItems='center'>
                                            <Typography variant='body2' width='100%'>回路電圧(V):</Typography>
                                            <FormControl fullWidth size="small">
                                                <Controller
                                                    name="voltage"
                                                    control={control}
                                                    rules={{ required: '必須項目です。入力してください。',
                                                        pattern:{
                                                            value: /^[0-9\b]+$/,
                                                            message: '数字を入力してください。'
                                                        },
                                                        validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                        // maxLength: {
                                                        //     value: 6,
                                                        //     message: "最大長さ <= 6 を入力してください。"
                                                        // }
                                                    }}
                                                    render={({ field }) => (
                                                        <TextFieldOptions
                                                            {...field}
                                                            options={initialValue.voltageList}
                                                            setValue={(name: any, value: any) => {
                                                                doSetValueCapacitor({target: {name: name, value: value}})
                                                            }}
                                                            height= {32}
                                                            error={!!errors?.voltage}
                                                            helperText={errors?.voltage?.message}
                                                            maxLength={6}
                                                            disabled={getValues("isSourceVoltage")||m_bModePM|| modeViewOnly}
                                                        />
                                                    )}
                                                />
                                            </FormControl>
                                        </Stack>
                                        <Stack flexDirection='row' alignItems='center'>
                                            <Typography variant='body2' width='100%'>設備容量 (kvar):</Typography>
                                            <Controller
                                                name="capacity"
                                                control={control}
                                                rules={{ required: '必須項目です。入力してください。',
                                                        pattern:{
                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                            message: '数字を入力してください。'
                                                        },
                                                        validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                        // maxLength: {
                                                        //     value: 6,
                                                        //     message: "最大長さ <= 6 を入力してください。"
                                                        // }
                                                    }}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        onChange ={doSetValueCapacitor}
                                                        InputProps={{ sx: { height: 32 ,background:m_bModePM|| modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white', color:modeViewOnly?"grey":"black"} }}
                                                        inputProps={{maxlength:6,readOnly:modeViewOnly}}
                                                        error={!!errors?.capacity}
                                                        helperText={errors?.capacity?.message}
                                                        disabled={m_bModePM|| processMode > MS_MODE_CHECKING}

                                                    />
                                                )}
                                            />
                                        </Stack>
                                        <Stack flexDirection='row' alignItems='center'>
                                            <Typography variant='body2' width='100%'>直列リアクトル (%):</Typography>
                                            <FormControl fullWidth size="small">
                                                <Controller
                                                    name="reactor"
                                                    control={control}
                                                    rules={{ 
                                                    pattern:{
                                                        value: /^[0-9\b]+$/,
                                                        message: '数字を入力してください。'
                                                    },
                                                    validate: value => value >= 0 || '数字 >= 0 を入力してください。' ,
                                                    // maxLength: {
                                                    //     value: 2,
                                                    //     message: "最大長さ <= 2 を入力してください。"
                                                    // }
                                                }}
                                                    
                                                    render={({ field }: any) => (
                                                        <TextFieldOptions
                                                                {...field}
                                                                options={initialValue.reactorList}
                                                                // label="回路電圧(V)"
                                                                onChange= {(e) =>handleChangeFormat(e)}
                                                                size="small"
                                                                setValue={(name: any, value: any) => {
                                                                    handleChangeFormat({target:{name:name,value:value}});
                                                                   
                                                                }}
                                                                height={32}
                                                                error={!!errors?.reactor}
                                                                helperText={errors?.reactor?.message}
                                                                maxLength={2}
                                                                disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}

                                                            />
                                                        )}
                                                        // <Select
                                                        //     {...field}
                                                        //     error={!!errors?.reactor}
                                                        //     sx={{ height: 32 }}
                                                        // >
                                                        //     {initialValue.reactorList.map(item => (
                                                        //         <MenuItem key={item.label} value={item.value}>
                                                        //             {item.label}
                                                        //         </MenuItem>
                                                        //     ))}
                                                        // </Select>
                                                  
                                                />
                                            </FormControl>
                                        </Stack>
                                        <Stack flexDirection='row' alignItems='center'>
                                            <Typography variant='body2' width='100%'>静電容量(F):</Typography>
                                            <Controller
                                                name="staticCapacity"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        inputProps={{ readOnly: true }}
                                                        style={{ backgroundColor: 'floralwhite' }}
                                                        InputProps={{ sx: { height: 32,color:'grey',backgroundColor: 'floralwhite' } }}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                        <Stack flexDirection='row' alignItems='center'>
                                            <Typography variant='body2' width='100%'>定格電流 Ic(A):</Typography>
                                            <Controller
                                                name="ratedCurrent"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="number"
                                                        size="small"
                                                        inputProps={{ readOnly: true }}
                                                        style={{ backgroundColor: 'floralwhite' }}
                                                        InputProps={{ sx: { height: 32 ,color:'grey', backgroundColor: 'floralwhite'} }}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                        <Stack flexDirection='row' alignItems='center'>
                                            <Typography variant='body2' width='100%'>コンデンサの力率(進み):</Typography>
                                            <Controller
                                                name="powerFactor"
                                                control={control}
                                                rules={{
                                                    pattern:{
                                                        value: /^[0-9]*[.]?[0-9]+$/,
                                                        message: '数字を入力してください。'
                                                    },
                                                    validate: value => value >= 0 && value < 1 || '0 <= 数字 < 1 を入力してください。' ,
                                                    // maxLength: {
                                                    //     value: 5,
                                                    //     message: "最大長さ <= 3 を入力してください。"
                                                    // }
                                                }}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        onChange={doSetValueCapacitor}
                                                        InputProps={{ sx: { height: 32 ,background:m_bModePM|| modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white', color:modeViewOnly?"grey":"black"} }}
                                                        inputProps={{maxLength:5,readOnly:modeViewOnly}}
                                                        error={!!errors?.powerFactor}
                                                        helperText={errors?.powerFactor?.message}
                                                        disabled={m_bModePM|| processMode > MS_MODE_CHECKING}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                        <Stack flexDirection='row' alignItems='center'>
                                            <Typography variant='body2' width='100%'>電流位相(ﾟ ):</Typography>
                                            <Controller
                                                name="phaseCurrent"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        inputProps={{ readOnly: true }}
                                                        style={{ backgroundColor: 'floralwhite' }}
                                                        InputProps={{ sx: { height: 32 ,color:'grey',backgroundColor: 'floralwhite'} }}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                        <Stack flexDirection='row' alignItems='center'>
                                            <Typography variant='body2' width='100%'>負荷率:</Typography>
                                            <Controller
                                                name="loadFactor"
                                                control={control}
                                                rules={{
                                                    pattern:{
                                                        value: /^[0-9]*[.]?[0-9]+$/,
                                                        message: '数字を入力してください。'
                                                    },
                                                    validate: value => value >= 0 && value <= 1 || '数字 0 ~ 1を入力してください。' ,
                                                    // maxLength: {
                                                    //     value: 5,
                                                    //     message: "最大長さ <= 5 を入力してください。"
                                                    // }
                                                }}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        onChange={doSetValueCapacitor}
                                                        InputProps={{ sx: { height: 32 ,background:m_bModePM|| modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white', color:modeViewOnly?"grey":"black"} }}
                                                        inputProps={{maxlength:5,readOnly:modeViewOnly}}
                                                        error={!!errors?.loadFactor}
                                                        helperText={errors?.loadFactor?.message}
                                                        disabled={m_bModePM|| processMode > MS_MODE_CHECKING}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                    </Stack>
                                </Grid>
                                <Grid item xs={5} pl={3}>
                                    {/* Mode SP */}
                                    {(userRole && !(userRole == Constant.ROLE_LP)) &&
                                    <Stack>
                                        <Controller
                                            name="viewFlowResult"
                                            control={control}
                                            render={({ field }: any) => (
                                                <FormControlLabel 
                                                    {...field} 
                                                    control={<Checkbox style={checkboxStyle}/>} 
                                                    checked={field.value}
                                                    name='viewFlowResult'
                                                    label={<Typography variant='body2' color={m_bModePM?'grey':'black'}>潮流解析結果を表示</Typography>}
                                                    disabled={m_bModePM || modeViewOnly}
                                                />
                                            )}
                                        />
                                    </Stack>
                                    }
                                    
                                    <Stack>
                                        <Controller
                                            name="viewResultText"
                                            control={control}
                                            render={({ field }: any) => (
                                                <FormControlLabel 
                                                    {...field} 
                                                    control={<Checkbox style={checkboxStyle}/>} 
                                                    checked={field.value} 
                                                    name='viewResultText' 
                                                    label={<Typography variant='body2' color={m_bModePM?'grey':'black'}>注釈を表示</Typography>}
                                                    disabled={m_bModePM || modeViewOnly}
                                                />
                                            )}
                                        />
                                    </Stack>
                                </Grid>                
                            </Grid>
                            
                            {/* ModePM */}
                            {[Constant.ROLE_PM, Constant.ROLE_ADMIN].includes(userRole) && 
                            <>
                                <Stack>
                                    <FormControlLabel
                                        control={
                                            <Controller
                                                name='makeDataInput'
                                                control={control}
                                                render={({ field }) => (
                                                    <Checkbox
                                                        size='small'
                                                        {...field}
                                                        checked={field.value}
                                                        onChange={handleChangeMakeDataInput}
                                                        style={{padding:'4px 4px 4px 9px'}}
                                                        disabled={m_bModePM || modeViewOnly}

                                                    />
                                                )}
                                            />
                                        }
                                        label={<Typography variant="body2"   color={m_bModePM?'grey':'black'}>銘板入力</Typography>}
                                    />
                                </Stack>
                                <Stack>
                                    <fieldset
                                        style={{ 
                                            width: '70%',
                                            color: !makeDataInput ? 'grey' : 'black', 
                                            border:'1px solid #AAAA'
                                        }}
                                    >
                                        <legend ><Typography variant="body2"  >銘板</Typography></legend>
                                        <Stack spacing={0.3}>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="35%"  >製造者:</Typography>
                                                <FormControl fullWidth size='small'>
                                                    <Controller
                                                        name='makerName'
                                                        control={control}
                                                        render={({ field }: any) => (
                                                            <TextFieldOptions
                                                                {...field}
                                                                options={initialValue.makerNameList}
                                                                type='string'
                                                                size='small'
                                                                setValue={(name: any, value: any) => {
                                                                    handleChangeFormat({target: {name: name, value: value}})
                                                                }}
                                                                height= {32}
                                                            disabled={!makeDataInput||m_bModePM || modeViewOnly}

                                                            />
                                                        )}
                                                    />
                                                </FormControl>
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="35%"  >形式:</Typography>
                                                <Controller
                                                    name='type'
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type='text'
                                                            size='small'
                                                            onChange={handleChangeFormat}
                                                            inputProps={{
                                                                readOnly: !makeDataInput || modeViewOnly,
                                                                style: { color: makeDataInput ? 'black' : 'grey' },
                                                            }}
                                                            style={{
                                                                color:'black',
                                                            }}
                                                            InputProps={{ sx: { height: 32 , backgroundColor: (makeDataInput && m_bModePM) || modeViewOnly ? 'floralwhite' :makeDataInput? 'white':'floralwhite', color:modeViewOnly?"grey":makeDataInput ? 'black' : 'grey'} }}
                                                            disabled={m_bModePM}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="35%"  >製造年月:</Typography>
                                                <Grid container>
                                                    <Grid item xs={7}>
                                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                            <FormControl fullWidth size='small'>
                                                                <Controller
                                                                    name='makeYear'
                                                                    control={control}
                                                                    render={({ field }: any) => (
                                                                        <TextFieldOptions
                                                                            {...field}
                                                                            options={initialValue.makeYearList}
                                                                            type='string'
                                                                            size='small'
                                                                            setValue={(name: any, value: any) => {
                                                                                handleChangeFormat({target: {name: name, value: value}})
                                                                            }}
                                                                            height= {32}
                                                                            maxLength={8}
                                                                            disabled={!makeDataInput||m_bModePM || modeViewOnly }

                                                                        />
                                                                    )}
                                                                />
                                                            </FormControl>
                                                            <Typography variant="body2" pr={1.5} pl={0.5}>年</Typography>
                                                        </Stack>
                                                        
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                            <FormControl fullWidth size='small'>
                                                                <Controller
                                                                    name='makeMonth'
                                                                    control={control}
                                                                    render={({ field }: any) => (
                                                                        <TextFieldOptions
                                                                            {...field}
                                                                            options={initialValue.makeMonthList}
                                                                            type='string'
                                                                            size='small'
                                                                            setValue={(name: any, value: any) => {
                                                                                handleChangeFormat({target: {name: name, value: value}})
                                                                            }}
                                                                            height= {32}
                                                                            maxLength={2}
                                                                            disabled={!makeDataInput||m_bModePM || modeViewOnly}
                                                                        />
                                                                    )}
                                                                />
                                                            </FormControl>
                                                            <Typography variant="body2" pl={0.5}>月</Typography>
                                                        </Stack>
                                                    </Grid>
                                                </Grid>
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="35%"  >製造番号:</Typography>
                                                <Controller
                                                    name='makeNumber'
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type='text'
                                                            size='small'
                                                            onChange={handleChangeFormat}
                                                            inputProps={{
                                                                readOnly: !makeDataInput || modeViewOnly,
                                                                style: { color: makeDataInput ? 'black' : 'grey' },
                                                            }}
                                                            style={{
                                                                color: 'black',
                                                            }}
                                                            InputProps={{ sx: { height: 32 , backgroundColor: (makeDataInput && m_bModePM) || modeViewOnly ? 'floralwhite' :makeDataInput? 'white':'floralwhite',color:modeViewOnly?"grey":makeDataInput ? 'black' : 'grey'} }}
                                                            disabled={m_bModePM}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                        </Stack>
                                    </fieldset>
                                </Stack>
                            </>
                            }
                        </TabPanel>

                        <TabPanel value={selectTab} index={1}>
                            <Stack mt={1}>
                                <fieldset style={{border:"1px solid #AAAA"}}>
                                    <legend><Typography variant='body2'>充電電流曲線</Typography></legend>
                                    <Grid container>
                                        <Grid item xs={7}>
                                            <Stack spacing={0.3}>
                                                <Stack flexDirection='row' alignItems='center'>
                                                    <Typography variant='body2' width='175%'>定格電流 Ic(A):</Typography>
                                                    <Controller
                                                        name="ratedCurrent2"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                fullWidth
                                                                type="text"
                                                                size="small"
                                                                inputProps={{ readOnly: true }}
                                                                style={{ backgroundColor: 'floralwhite' }}
                                                                InputProps={{ sx: { height: 32,color:'grey', backgroundColor: 'floralwhite' } }}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                                <Stack flexDirection='row' alignItems='center'>
                                                    <Typography variant='body2' width='175%'>上位の総合抵抗(mΩ):</Typography>
                                                    <Controller
                                                        name="totalOhmR"
                                                        control={control}
                                                        rules={{
                                                            pattern:{
                                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                                message: '数字を入力してください。'
                                                            },
                                                            validate: value => value >= 0 || '数字 >= 0 を入力してください。' ,
                                                            // maxLength: {
                                                            //     value: 5,
                                                            //     message: "最大長さ <= 5 を入力してください。"
                                                            // }
                                                        }}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                fullWidth
                                                                type="text"
                                                                size="small"
                                                                onChange={doSetValuesCapacitor1}
                                                                InputProps={{ sx: { height: 32 ,backgroundColor:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white', color:modeViewOnly?"grey":"black"} }}
                                                                inputProps={{maxlength:5,readOnly:modeViewOnly}}
                                                                error={!!errors?.totalOhmR}
                                                                helperText={errors?.totalOhmR?.message}
                                                                disabled={m_bModePM|| processMode > MS_MODE_CHECKING}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                                <Stack flexDirection='row' alignItems='center'>
                                                    <Typography variant='body2' width='175%'>上位の総合リアクタンス(mΩ):</Typography>
                                                    <Controller
                                                        name="totalOhmX"
                                                        control={control}
                                                        rules={{
                                                            pattern:{
                                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                                message: '数字を入力してください。'
                                                            },
                                                            validate: value => value >= 0 || '数字 >= 0 を入力してください。' ,
                                                            // maxLength: {
                                                            //     value: 5,
                                                            //     message: "最大長さ <= 5 を入力してください。"
                                                            // }
                                                        }}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                fullWidth
                                                                type="text"
                                                                size="small"
                                                                onChange={doSetValuesCapacitor1}
                                                                error={!!errors?.totalOhmX}
                                                                helperText={errors?.totalOhmX?.message}
                                                                InputProps={{ sx: { height: 32 ,backgroundColor:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white', color:modeViewOnly?"grey":"black"} }}
                                                                inputProps={{maxlength:5,readOnly:modeViewOnly}}
                                                                disabled={m_bModePM|| processMode > MS_MODE_CHECKING}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                                <Stack flexDirection='row' alignItems='center'>
                                                    <Typography variant='body2' width='175%'>ピーク電流 im/(√2)(A):</Typography>
                                                    <Controller
                                                        name="rushCurrent"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                fullWidth
                                                                type="number"
                                                                size="small"
                                                                inputProps={{ readOnly: true }}
                                                                style={{ backgroundColor: 'floralwhite' }}
                                                                InputProps={{ sx: { height: 32 ,color:'grey', backgroundColor: 'floralwhite'} }}            
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                                <Stack flexDirection='row' alignItems='center'>
                                                    <Typography variant='body2' width='175%'>ピーク時間 t0 (ms):</Typography>
                                                    <Controller
                                                        name="peakTime"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                fullWidth
                                                                type="number"
                                                                size="small"
                                                                inputProps={{ readOnly: true }}
                                                                style={{ backgroundColor: 'floralwhite' }}
                                                                InputProps={{ sx: { height: 32 ,color:'grey', backgroundColor: 'floralwhite'} }}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={5} pl={1}>
                                            <Stack pt={5}>
                                                <Controller
                                                    name="calcOhmR"
                                                    control={control}
                                                    render={({ field }: any) => (
                                                        <FormControlLabel 
                                                            {...field} 
                                                            control={<Checkbox style={checkboxStyle} size="small"/>} 
                                                            checked={field.value} 
                                                            name='calcOhmR'
                                                            label={<Typography variant='body2' color={m_bModePM || processMode > MS_MODE_CHECKING?'grey':'black'}>%Zマップで自動計算する</Typography>}
                                                            disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack pt={1.5}>
                                                <Controller
                                                    name="calcOhmX"
                                                    control={control}
                                                    render={({ field }: any) => (
                                                        <FormControlLabel 
                                                            {...field} 
                                                            control={<Checkbox style={checkboxStyle} size="small"/>} 
                                                            checked={field.value}
                                                            name='calcOhmX' 
                                                            label={<Typography variant='body2' color={m_bModePM|| processMode > MS_MODE_CHECKING?'grey':'black'}>%Zマップで自動計算する</Typography>}
                                                            disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}
                                                        
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </fieldset>
                                
                            </Stack>
                        </TabPanel>
                     </div> 
                    <Stack direction='row' justifyContent="flex-end" spacing={2} mb={2} mt={1}>
                        <Button variant="contained" type="submit" disabled={isDisableOKBtn || modeViewOnly}>OK</Button>
                        <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                    </Stack>
                </form >
            </div>
        </>
    );
};
export default MsCapacitorEditDialog;


