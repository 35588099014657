import { store } from "../..";
import { MS_2E, MS_FUSE, MS_LVCB, MS_THERMAL, MS_THERMAL_CT } from "../../models/ElementKind";
import { ControlModel, DatasetModel, LineInfoModel, LineStyleModel, LineType, Point } from "../../models/Index";

export const getDefaultLineInfo = (id:string, element : ControlModel, linePoints : Point[], lineId : number | string = '', currentChartTabId : number, type: string, lineType = LineType.CONTROL_CURVE) : LineInfoModel => {    
    // let checkExist = store.getState().app.diagram.chartData.find(x=>x.shape.find(shapes => shapes.chart.find(charts => charts.controlId === element.id)));
    // let tabMappingExist
    // if(checkExist !== undefined)
    // {
    //     tabMappingExist = checkExist.shape.find(shapes=> shapes.chart.find(charts => charts.controlId === element.id))
    // }
    // let oldMap = new Map()
    // if(tabMappingExist !== undefined) {
    //    oldMap = tabMappingExist.chart[0].tabMapping
    // }
    return {   
        relatedGraphId: id,
        relatedGraphTextId: '',
        controlId: element.id,
        lineId: lineId || Date.now(),
        lineType,
        label: element.properties.refNo || element.properties.text || element.properties.name,
        data: linePoints,
        pointBorderColor: 'rgba(0, 0, 0, 0)',
        pointBackgroundColor: 'rgba(0, 0, 0, 0)',
        pointRadius: 1.5,
        spanGaps: false, 
        tabMapping: new Map().set(currentChartTabId, getDefaultLineStyle(type))//tabMappingExist !== undefined? oldMap.set(currentChartTabId, getDefaultLineStyle(type)) : new Map().set(currentChartTabId, getDefaultLineStyle(type))
    }
}

export const getDefaultLineStyle = (type: string) : LineStyleModel => {    
    return {   
        borderColor: '#000000',
        backgroundColor: '#000000',
        borderDash: [],
        borderWidth: 3,
        dispBand: type === MS_2E || type === MS_FUSE || type === MS_THERMAL || type === MS_THERMAL_CT || type === MS_LVCB ? true : false,
        dispRefV2: true,
        dispLinkLine: true,
        dispTextFree: false,
        colorFont: '#0000ff',
        faceName: 'Noto Sans JP',
        fontSize: 12,
        italic: false,
        strikeOut: false,
        underline: false,
        weight: 0,
        textFree: '',
        text: '',
        pointTopLeftX:0,
        pointTopLeftY:0,
        pointOldX:0,
        pointOldY:0,
        minPointX:0,
        minPointY:0,
    }
}

export const convertToDataset = (lineInfos : LineInfoModel[], currentChartTabId : number, nominalVolt : number) : DatasetModel[] => {
    return lineInfos.map((lineInfo : LineInfoModel) => {
        const currentLineStyle = lineInfo.tabMapping.get(currentChartTabId) || getDefaultLineStyle('')
        const {relatedGraphId, relatedGraphTextId, controlId, data, label, lineId, lineType, pointBackgroundColor, pointBorderColor, pointRadius, spanGaps } = lineInfo
        // devide by nominalVolt
        const convertNominalData = data.map(point => ({...point, x: point.x / nominalVolt}))// lineType === "USER_CURVE" ? data : data.map(point => ({...point, x: point.x / nominalVolt}))
        if(lineInfo.lineId.toString().includes("INTENSITY") || lineInfo.lineType == LineType.USER_CURVE && lineInfo.data.filter((item:any)=>item.x != 0 && item.y !=0).length == 1)
        {
            return {
                ...currentLineStyle,
                data: convertNominalData,
                relatedGraphId, relatedGraphTextId, controlId, label, lineId, lineType, spanGaps,
                savedColor: currentLineStyle.backgroundColor,
                pointBackgroundColor: 'rgba(0, 0, 0, 0)',
                pointBorderColor: currentLineStyle.backgroundColor,
                pointBorderWidth: currentLineStyle.borderWidth,    
                pointRadius: 3,
                pointHoverRadius: 2,
            }
        }
        else
        {
            return {
                ...currentLineStyle,
                data: convertNominalData,
                relatedGraphId, relatedGraphTextId, controlId, label, lineId, lineType, pointBackgroundColor, pointBorderColor, pointRadius, spanGaps,
                savedColor: currentLineStyle.backgroundColor,
            }
        }
    })
}

export const getDefaultDatasetModel = () : DatasetModel => {    
    return {   
         // custom
        relatedGraphId: '',
        relatedGraphTextId: '',
        controlId: '',
        lineId: '',
        lineType: LineType.CONTROL_CURVE,
        savedColor: 'string',

        // original
        label: '',
        data: [],
        pointBorderColor: '#000000',
        pointBackgroundColor: '#000000',
        pointRadius: 1.5,
        spanGaps: false,
        backgroundColor: '#000000',
        borderColor:  '#000000',
        borderDash: [],
        borderWidth: 3,
        dispBand: false,
        dispRefV2: true,
        dispLinkLine: true,
        dispTextFree: false,
        fontSize: 12,
        faceName : 'MS UI Gothic',
        colorFont : '#0000ff',
        underline : false,
        strikeOut : false,
        italic : false,
        weight : 0,
        textFree: '',
        text: ''
    }
}