import { LoadingButton } from "@mui/lab";
import { Box, Button, Checkbox, CircularProgress, FormControlLabel, Grid, Stack, TextField, Typography } from "@mui/material";
import { useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { EquipHarmoMaxModel } from "../../../models/breakModel";
import { Path as RoutePath } from "../../../path";
import { ApplicationState, actionCreators, selectors } from "../../../store";
import FetchHelper from "../../FetchHelper";
//-----------
//FETCH ID
const GET_EQUIP_HARMOMAX = "GET_EQUIP_HARMOMAX"
const UPDATE_EQUIP_HARMOMAX = "UPDATE_EQUIP_HARMOMAX"
const CREATE_EQUIP_HARMOMAX = "CREATE_EQUIP_HARMOMAX"

const initialValue:EquipHarmoMaxModel = {
    lHarmoMaxId:0,
    dValue5:0,
    dValue7:0,
    dValue11:0,
    dValue13:0,
    dValue17:0,
    dValue19:0,
    dValue23:0,
    dValue25:0,
    lVoltage:0,
}
// -------------
// Props
export type EquipBusductEditProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
export const EquipHarmoEdit = (props: EquipBusductEditProps) => {
    const {
        userId,
        onCreateError,
        onUpdate, onUpdateError,
        onSuccess, onError,
        onCreate
    } = props;

    const { control, formState: { errors }, handleSubmit, setValue, getValues, } = useForm<EquipHarmoMaxModel>({
        criteriaMode: "all",
        mode:"all",
        defaultValues: initialValue,
    })
    type MyParams = {
        id: string;
        mode: string;
    };
    let { id, mode } = useParams<keyof MyParams>() as MyParams;
    let navigate = useNavigate();
    const [loadingFlag, setLoadingFlag] = useState(mode === "edit" ? true : false);
    const gridRef = useRef() as any;
    const Exp = /^[-+]?\d+(\.\d+)?$/;
    const messageIsNotNumber = "数字を入力してください。";

    const handleSuccess = (data: any) => {
        setLoadingFlag(false);
        if(data && data.resultCode == 0){
            setValue("lHarmoMaxId",data.data.lHarmoMaxId)
            setValue("dValue5",data.data.dValue5?? "")
            setValue("dValue7",data.data.dValue7?? "")
            setValue("dValue11",data.data.dValue11?? "")    
            setValue("dValue13",data.data.dValue13?? "")
            setValue("dValue17",data.data.dValue17?? "")
            setValue("dValue19",data.data.dValue19?? "")
            setValue("dValue23",data.data.dValue23?? "")
            setValue("dValue25",data.data.dValue25?? "")
            setValue("lVoltage",data.data.lVoltage?? "")
        }
    }

    // 検索エラー時
    const handleError = (success: boolean, data: any, error: any) => {
        setLoadingFlag(false);
        console.log('>>>> handle Error');
        gridRef.current.hideOverlay();
    };

    // 検索実行
    const handleEditSubmit = (data: EquipHarmoMaxModel) => {
        setLoadingFlag(true);
        if(mode === "edit") {
            let request = {
                lHarmoMaxId:id,
                dValue5:data.dValue5,
                dValue7:data.dValue7,
                dValue11:data.dValue11,
                dValue13:data.dValue13,
                dValue17:data.dValue17,
                dValue19:data.dValue19,
                dValue23:data.dValue23,
                dValue25:data.dValue25,
                lVoltage:data.lVoltage,
            } as any
            const object = {
                requestPrms:request,
                userId: userId,
            }
            onUpdate(object);
        } else if(mode === "add") {
            let request = {
                dValue5:data.dValue5,
                dValue7:data.dValue7,
                dValue11:data.dValue11,
                dValue13:data.dValue13,
                dValue17:data.dValue17,
                dValue19:data.dValue19,
                dValue23:data.dValue23,
                dValue25:data.dValue25,
                lVoltage:data.lVoltage,
            } as any
            const object = {
                requestPrms:request,
                userId: userId,
            }
            onCreate(object);
        }
    }

    // キャンセル
    const handleCancel = () => {
        navigate(RoutePath.EquipHarmoMaxList);
    }
    // Submit後のAPI呼び出しエラー時
    const handleErrorUpdate = (success: boolean, data: any, error: any) => {
        onUpdateError();
        setLoadingFlag(false);
    }

    // Submit後のAPI呼び出し成功時
    const handleSuccessSubmit = (success: boolean, data: any, error: any) => {
        onSuccess();
        if (mode === "add") {
            navigate(RoutePath.EquipHarmoMaxEdit + `/edit/${data.data.lHarmoMaxId}`, { replace: true })
        }
        setLoadingFlag(false)
    }

    // Submit後のAPI呼び出しエラー時
    const handleSubmitError = (success: boolean, data: any, error: any) => {
        onCreateError();
        setLoadingFlag(false)
    }

    //入力欄背景
    const divStyles = {
        backgroundColor: 'white',
        border: '1px solid #bebebe',
        boxShadow: '0 0 5px #fff, 0 0 5px #bebebe, 0 0 1px #aaa',
        padding: '20px 40px',
        marginTop: '40px',
    }

    return (
        <>
            {
                mode === "edit" &&
                <FetchHelper
                fetchId={GET_EQUIP_HARMOMAX}
                url={`/master/equip_harmomax/get`}
                method="POST"
                json={true}
                request={true}
                params={{
                    requestPrms:{
                        id:id
                    },
                    userId: userId,
                }}
                onComplete={(success, data, error) => {
                    success ? handleSuccess(data) : handleError(success, data, error);
                }}
            />
            }
            {loadingFlag && (
                <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1500, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                    <CircularProgress />
                </div>
            )}
            <div style={{ height: "100%", width: "100%", marginTop: "10px" }}>
                <Stack direction="row" justifyContent="flex-start" alignItems="center" sx={{}} style={{ margin: '18px', color: 'MenuText' }}>
                    <Typography variant="h4">
                        {/* FIXME: */}
                        高調波発生機器(MAX)詳細
                    </Typography>
                </Stack>
                <form onSubmit={handleSubmit(handleEditSubmit)}>
                    <div style={divStyles}>
                        <Typography variant="h6">
                            {/* FIXME: */}
                            高調波発生機器(MAX)
                        </Typography>
                        <Stack mt={2} direction={{ xs: 'column', sm: 'row' }} sx={{maxHeight: "calc(100vh)"}}>
                            <Grid width={"100%"} spacing={2}>
                                <Grid pt={1} spacing={2} container direction={"row"}>
                                    <Grid item xs={4}>
                                        <Controller
                                            control={control}
                                            name="dValue5"
                                            rules={{
                                                pattern: {
                                                    value: Exp,
                                                    message: messageIsNotNumber,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.dValue5}
                                                    helperText={errors?.dValue5?.message}
                                                    label="5次（mA/kW）"
                                                    type="text"
                                                    size="small"

                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Controller
                                            control={control}
                                            name="dValue7"
                                            rules={{
                                                pattern: {
                                                    value: Exp,
                                                    message: messageIsNotNumber,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.dValue7}
                                                    helperText={errors?.dValue7?.message}
                                                    label="7次（mA/kW）"
                                                    type="text"
                                                    size="small"

                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Controller
                                            control={control}
                                            name="dValue11"
                                            rules={{
                                                pattern: {
                                                    value: Exp,
                                                    message: messageIsNotNumber,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.dValue11}
                                                    helperText={errors?.dValue11?.message}
                                                    label="11次（mA/kW）"
                                                    type="text"
                                                    size="small"

                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid mt={0} spacing={2} container direction={"row"}>
                                    <Grid item xs={4}>
                                        <Controller
                                            control={control}
                                            name="dValue13"
                                            rules={{
                                                pattern: {
                                                    value: Exp,
                                                    message: messageIsNotNumber,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.dValue13}
                                                    helperText={errors?.dValue13?.message}
                                                    label="13次（mA/kW）"
                                                    type="text"
                                                    size="small"

                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Controller
                                            control={control}
                                            name="dValue17"
                                           rules={{
                                                pattern: {
                                                    value: Exp,
                                                    message: messageIsNotNumber,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.dValue17}
                                                    helperText={errors?.dValue17?.message}
                                                    label="17次（mA/kW）"
                                                    type="text"
                                                    size="small"

                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Controller
                                            control={control}
                                            name="dValue19"
                                            rules={{
                                                pattern: {
                                                    value: Exp,
                                                    message: messageIsNotNumber,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.dValue19}
                                                    helperText={errors?.dValue19?.message}
                                                    label="19次（mA/kW）"
                                                    type="text"
                                                    size="small"

                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid mt={0} spacing={2} container direction={"row"}>
                                <Grid item xs={4}>
                                        <Controller
                                            control={control}
                                            name="dValue23"
                                            rules={{
                                                pattern: {
                                                    value: Exp,
                                                    message: messageIsNotNumber,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.dValue23}
                                                    helperText={errors?.dValue23?.message}
                                                    label="23次（mA/kW）"
                                                    type="text"
                                                    size="small"

                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Controller
                                            control={control}
                                            name="dValue25"
                                            rules={{
                                                pattern: {
                                                    value: Exp,
                                                    message: messageIsNotNumber,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.dValue25}
                                                    helperText={errors?.dValue25?.message}
                                                    label="25次（mA/kW）"
                                                    type="text"
                                                    size="small"

                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Controller
                                            control={control}
                                            name="lVoltage"
                                            rules={{
                                                pattern: {
                                                    value: Exp,
                                                    message: messageIsNotNumber,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.lVoltage}
                                                    helperText={errors?.lVoltage?.message}
                                                    label="受電電圧"
                                                    type="text"
                                                    size="small"

                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Stack>
                    </div>
                    <Stack direction='row' justifyContent="flex-end" spacing={2} sx={{ mt: 1, mr: 2 }}>
                        <LoadingButton variant="contained" type="submit" loading={loadingFlag}>保存</LoadingButton>
                        <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                    </Stack>
                </form>
            </div>
            <FetchHelper fetchId={UPDATE_EQUIP_HARMOMAX}
                onComplete={(success: boolean, data: any, error: any) =>
                    success ? handleSuccessSubmit(success, data, error) : handleErrorUpdate(success, data, error)} />
            
            <FetchHelper fetchId={CREATE_EQUIP_HARMOMAX}
                onComplete={(success, data, error) => success ?
                    handleSuccessSubmit(success, data, error) :
                    handleSubmitError(success, data, error)} />
        </>
    );
}
const mapStateToProps = (state: ApplicationState) => (
    {
        userId: state.app.user?.userId,
        fetchUpdate: selectors.getFormState(
            state,
            // GET_BREAK_CAPACITY
            UPDATE_EQUIP_HARMOMAX
        ),
    }
)
const mapDispatchToProps = (dispatch: any) => {
    return {
        onCreate: (data: any) => dispatch(actionCreators.fetch(CREATE_EQUIP_HARMOMAX, `/master/equip_harmomax/add`, "POST", data, false, true)),
        onUpdate: (data: any) => dispatch(actionCreators.fetch(UPDATE_EQUIP_HARMOMAX, `/master/equip_harmomax/modify`, "POST", data,false,true)),
        onSuccess: () =>
            dispatch(actionCreators.showMessage({
                type: "info",
                title: "高調波発生機器(MAX)更新",
                body: "高調波発生機器(MAX)を更新しました。",
            })),
        onError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "検索",
                body: "検索できません。",
            })),
        onCreateError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "高調波発生機器(MAX)登録",
                body: "高調波発生機器(MAX)を登録出来ませんでした。",
            })),
        onUpdateError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "高調波発生機器(MAX)編集",
                body: "高調波発生機器(MAX)を更新出来ませんでした。",
            })),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(EquipHarmoEdit as any);