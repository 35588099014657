import { LoadingButton } from '@mui/lab';
import { Autocomplete, Box, Button, Checkbox, CircularProgress, FormControl, FormControlLabel, Grid, MenuItem, Stack, Tab, Table, TableCell, TableRow, Tabs, TextField, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { CurveHeaderModel } from '../../../models/breakModel';
import { Path as RoutePath } from '../../../path';
import { curveKindOptions, graphFlagOptions, settingFlagOptions } from '../../../statics';
import { ApplicationState, actionCreators, selectors } from '../../../store';
import FetchHelper from '../../FetchHelper';
import AlertDialog from '../../common/AlertDialog';
import CurvePointGrid from '../CurvePoint/CurvePointGrid';

// -------------
// FETCH ID
const GET_CURVE_HEADER = 'GET_CURVE_HEADER';
const CREATE_CURVE_HEADER = 'CREATE_CURVE_HEADER';
const UPDATE_CURVE_HEADER = 'UPDATE_CURVE_HEADER';
const DELETE_CURVE_POINT = 'DELETE_CURVE_POINT_CURVE_HEADER'

// 初期値設定
const initialCurveHeader: CurveHeaderModel = {
    lCurveHeaderID: 0,
    lManufactureID: null,
    lGroupID: null,
    sCurveName: '',
    sClassName: '',
    nCurveKind: null,
    nSettingFlag: null,
    dStdCurrentValue: "",
    dStdTimeValue: "",
    lStdVoltage: "",
    nGraphFlag: null,
    dXMinimum: "",
    dXWidth: "",
    dXMaximum: "",
    dYMinimum: "",
    dYWidth: "",
    dYMaximum: "",
    bSaveFormula: false,
    sFormula: '',
    dCurrentMini: "",
    dCurrentMax: "",
    dInterval: "",
    bUseLogInput: false,
    nMaxPointValue: "",
    bUseTimeClip: false,
    dClipTimeMini: "",
    dClipTimeMax: "",
    dSaturationTime: "",
    dXErrorPlus: "",
    dXErrorMinus: "",
    dYErrorPlus: "",
    dYErrorMinus: ""
}

// -------------
//Tabs
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

//入力欄背景
const divStyles = {
    backgroundColor: 'white',
    border: '1px solid #bebebe',
    boxShadow: '0 0 5px #fff, 0 0 5px #bebebe, 0 0 1px #aaa',
    padding: '20px 40px',
    marginTop: '40px',
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `oilManagement-tab-${index}`,
        'aria-controls': `oilManagement-tabpanel-${index}`,
    };
}
const checkboxStyle = {
    height: '40px',
    padding: '0px 0px 0px 9px',
}

// -------------
// Props
export type CurveHeaderEditProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

export const CurveHeaderEdit = (props: CurveHeaderEditProps) => {
    const {
        userId,
        onCreate,
        onUpdate,
        onSuccess,onError,
        onDeleteCurvePoint,
    } = props;

    const { control, handleSubmit, setValue, getValues,formState:{errors} } = useForm<CurveHeaderModel>({
        criteriaMode: 'all',
        defaultValues: initialCurveHeader,
        mode: "all",
    });

    type MyParams = {
        id: string;
        mode: string;
    };
    let { id, mode } = useParams<keyof MyParams>() as MyParams;
    const gridRef = useRef() as any;
    let navigate = useNavigate();
    const location = useLocation();
    let tab = location.state && location.state.tab;
    let lStandardID = location.state ? location.state.lStandardID : "";
    const Exp = /^[-+]?\d+(\.\d+)?$/;
    const errorValidateNumber = "数字を入力してください。";
    const [selectTab, setSelectTab] = useState(0);
    const [loadingFlag, setLoadingFlag] = useState(true);
    const [colState, setColState] = useState<any>();
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const [openedDeleteDialog, setOpenedDeleteDialog] = useState<boolean>(false);
    const [idDelete, setIdDelete] = useState<string>('');
    const [mv3curveCurvePointList, setMv3curveCurvePointList] = useState<any[]>([]);
    const [manufactureOptions, setManufactureOptions] = useState<{ label: string, value: number }[]>([]);
    const [groupOptions, setGroupOptions] = useState<{ value: number, manuName: string, groupName: string }[]>([]);

    const handleSuccess = (data: any) => {
        console.log('>>> handleSuccess');
        if(mode === "edit"){
            if (data && data.data) {
                const object = data.data;
                setValue('lCurveHeaderID', object.lcurveHeaderID)
                setValue('lManufactureID', object.lmanufactureID)
                setValue('lGroupID', object.lgroupID)
                setValue('sClassName', object.sclassName ?? '')
                setValue('nCurveKind', object.ncurveKind)
                setValue('nSettingFlag', object.nsettingFlag)
                setValue('dStdCurrentValue', object.dstdCurrentValue ?? '')
                setValue('dStdTimeValue', object.dstdTimeValue ?? '')
                setValue('lStdVoltage', object.lstdVoltage ?? '')
                setValue('bSaveFormula', object.bsaveFormula)
                setValue('sFormula', object.sformula)
                setValue('dCurrentMini', object.dcurrentMini)
                setValue('dCurrentMax', object.dcurrentMax)
                setValue('dInterval', object.dinterval)
                setValue('bUseLogInput', object.buseLogInput)
                setValue('nMaxPointValue', object.nmaxPointValue)
                setValue('bUseTimeClip', object.buseTimeClip)
                setValue('dClipTimeMini', object.dclipTimeMini)
                setValue('dClipTimeMax', object.dclipTimeMax)
                setValue('dXErrorPlus', object.dxerrorPlus)
                setValue('dXErrorMinus', object.dxerrorMinus)
                setValue('dYErrorPlus', object.dyerrorPlus)
                setValue('dYErrorMinus', object.dyerrorMinus)
                setValue('dSaturationTime', object.dsaturationTime)
                setValue('nGraphFlag', object.ngraphFlag)
                setValue('dXMinimum', object.dxminimum)
                setValue('dXWidth', object.dxwidth)
                setValue('dXMaximum', object.dxmaximum)
                setValue('dYMinimum', object.dyminimum)
                setValue('dYWidth', object.dywidth)
                setValue('dYMaximum', object.dymaximum)
                setValue('sCurveName', object.scurveName ?? '')
            }
    
            if (data.mv3breakManufactureList?.length > 0) {
                setManufactureOptions([])
                data.mv3breakManufactureList.forEach((e: { smanuName: any; lmanufactureID: any; }) => {
                    setManufactureOptions(
                        prevArray => [
                            ...prevArray,
                            { label: e.smanuName, value: e.lmanufactureID },
                        ]
                    )
                });
            }
    
            if (data.breakGroupOptionList?.length > 0) {
                setGroupOptions([])
                data.breakGroupOptionList.forEach((e: any) => {
                    groupOptions.push({ groupName: e.sgroupName, value: e.lgroupID, manuName: e.smanuName });
                    setGroupOptions([...groupOptions]);
                });
            }
    
            if (data.mv3curveCurvePointList?.length > 0) {
                setMv3curveCurvePointList(data.mv3curveCurvePointList);
            }
        }else if(mode === "add"){
            if (data.mv3breakManufactureList?.length > 0) {
                setManufactureOptions([])
                data.mv3breakManufactureList.forEach((e: { smanuName: any; lmanufactureID: any; }) => {
                    setManufactureOptions(
                        prevArray => [
                            ...prevArray,
                            { label: e.smanuName, value: e.lmanufactureID },
                        ]
                    )
                });
            }
            if (data.breakGroupOptionList?.length > 0) {
                setGroupOptions([])
                data.breakGroupOptionList.forEach((e: any) => {
                    groupOptions.push({ groupName: e.sgroupName, value: e.lgroupID, manuName: e.smanuName });
                    setGroupOptions([...groupOptions]);
                });
            }
        }
        setLoadingFlag(false)
        if (tab !== undefined && tab !== null) {
            setSelectTab(tab)
        }
    };

    const tabHandleChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectTab(newValue);
        navigate(".", {
            replace: true,
            state: {
                tab: newValue,
                lStandardID: lStandardID
            }
        });
    }

    // 検索エラー時
    const handleError = (success: boolean, data: any, error: any) => {
        console.log('>>> handleError');
        gridRef.current.hideOverlay();
    };

    // キャンセル
    const handleCancel = () => {
        if (location.key !== "default")
            navigate(-1);
        else
            navigate(RoutePath.CurveHeaderList)
    }

    // Submitイベント
    const handleEditSubmit = (value: CurveHeaderModel) => {
        setLoadingFlag(true)
        if (mode === 'edit') {
            let request = {
                lCurveHeaderID:  getValues('lCurveHeaderID'),
                lManufactureID:getValues('lManufactureID'),
                lGroupID: getValues('lGroupID'),
                lStdVoltage: getValues('lStdVoltage'),
                bUseTimeClip: getValues('bUseTimeClip'),
                bSaveFormula: getValues('bSaveFormula'),
                bUseLogInput: getValues('bUseLogInput'),
                nCurveKind: getValues('nCurveKind'),
                nSettingFlag: getValues('nSettingFlag'),
                dStdCurrentValue: getValues('dStdCurrentValue'),
                dStdTimeValue: getValues('dStdTimeValue'),
                dCurrentMini: getValues('dCurrentMini'),
                dCurrentMax: getValues('dCurrentMax'),
                dInterval: getValues('dInterval'),
                dClipTimeMini: getValues('dClipTimeMini'),
                dClipTimeMax: getValues('dClipTimeMax'),
                dXErrorMinus: getValues('dXErrorMinus'),
                dXErrorPlus: getValues('dXErrorPlus'),
                dYErrorMinus: getValues('dYErrorMinus'),
                dYErrorPlus: getValues('dYErrorPlus'),
                dSaturationTime: getValues('dSaturationTime'),
                dXMaximum: getValues('dXMaximum'),
                dXMinimum: getValues('dXMinimum'),
                dXWidth: getValues('dXWidth'),
                dYMinimum: getValues('dYMinimum'),
                dYWidth: getValues('dYWidth'),
                dYMaximum: getValues('dYMaximum'),
                nMaxPointValue: getValues('nMaxPointValue'),
                nGraphFlag: getValues('nGraphFlag'),
                sCurveName: getValues('sCurveName'),
                sClassName: getValues('sClassName'),
                sFormula: getValues('sFormula'),
            } as CurveHeaderModel
            const object ={
                requestPrms:request,
                userId: userId,
            }
            onUpdate(object);
        } else if (mode === 'add') {
            let request = {
                lCurveHeaderID: getValues('lCurveHeaderID'),
                lManufactureID: getValues('lManufactureID')??7,
                lGroupID: getValues('lGroupID')??27,
                sClassName: getValues('sClassName'),
                nCurveKind: getValues('nCurveKind'),
                nSettingFlag: getValues('nSettingFlag'),
                dStdCurrentValue: getValues('dStdCurrentValue'),
                dStdTimeValue: getValues('dStdTimeValue'),
                lStdVoltage: getValues('lStdVoltage'),
                bSaveFormula: getValues('bSaveFormula'),
                sFormula: getValues('sFormula'),
                dCurrentMini: getValues('dCurrentMini'),
                dCurrentMax: getValues('dCurrentMax'),
                dInterval: getValues('dInterval'),
                bUseLogInput: getValues('bUseLogInput'),
                nMaxPointValue: getValues('nMaxPointValue'),
                bUseTimeClip: getValues('bUseTimeClip'),
                dClipTimeMini: getValues('dClipTimeMini'),
                dClipTimeMax: getValues('dClipTimeMax'),
                dXErrorPlus: getValues('dXErrorPlus'),
                dXErrorMinus: getValues('dXErrorMinus'),
                dYErrorPlus: getValues('dYErrorPlus'),
                dYErrorMinus: getValues('dYErrorMinus'),
                dSaturationTime: getValues('dSaturationTime'),
                nGraphFlag: getValues('nGraphFlag'),
                dXMinimum: getValues('dXMinimum'),
                dXWidth: getValues('dXWidth'),
                dXMaximum: getValues('dXMaximum'),
                dYMinimum: getValues('dYMinimum'),
                dYWidth: getValues('dYWidth'),
                dYMaximum: getValues('dYMaximum'),
                sCurveName: getValues("sCurveName"),
            } as CurveHeaderModel
            const object ={
                requestPrms:request,
                userId: userId,
            }
            onCreate(object)
        }
    }

    // Submit後のAPI呼び出し成功時
    const handleSubmitSuccess = (success: boolean, data: any, error: any) => {
        onSuccess();
        if (mode === "add") {
            navigate(RoutePath.CurveHeaderEdit + `/edit/${data.data.l_curve_headerid}`)
        }
        // navigate(-1);
        setLoadingFlag(false)
    }

    // Submit後のAPI呼び出しエラー時
    const handleSubmitError = (success: boolean, data: any, error: any) => {
        console.log('>>> handleError');
        setLoadingFlag(false);
        onError();
    }

    //削除の処理
    const handleShowDetailPage = (data: any) => {
        navigate(RoutePath.CurvePointEdit + `/edit/${data}`,
            {
                state: {
                    lCurveHeaderID: id,
                }
            })
    }

    //削除の処理
    const handleDelete = (data: any) => {
        setIdDelete(data);
        const msg = 'ID: ' + data + 'を削除します。'
        setTitle('定格ダイアル情報削除');
        setMessage(msg);
        setOpenedDeleteDialog(true);
    }

    // 削除確認ダイアログでOKボタンのイベントを受け取って、APIに送信する
    const handleDeleteOK = () => {
        setOpenedDeleteDialog(false);
        setLoadingFlag(true);
        const object = {
            requestPrms:{
                id:JSON.stringify(idDelete),
            },
            userId: userId,
        }
        onDeleteCurvePoint(object);
    }

    // 削除確認ダイアログでCancelボタンのイベントを受け取って、ダイアログを無効にする
    const handleDeleteCancel = () => {
        setIdDelete('');
        setOpenedDeleteDialog(false);
    }

    //分析一覧を表示
    const handleAdd = () => {
        navigate(RoutePath.CurvePointEdit + `/add`,
            {
                state: {
                    lCurveHeaderID: id,
                    sCurveName: getValues("sCurveName")
                }
            })
    }

    const handleAddPlayList = () => {
        navigate(RoutePath.CurvePointAddList, {
            state: {
                lCurveHeaderID: id,
            }
        });
    }

    const handleDeleteSuccess = (success:any, data:any, error:any) =>{
        setLoadingFlag(false);
        onSuccess();
        setMv3curveCurvePointList(mv3curveCurvePointList.filter(item => item.lpointID !== idDelete));
        setIdDelete('');
    }

    const handleDeleteError = (success:any, data:any, error:any) =>{
        setLoadingFlag(false);
        onError();
        setIdDelete('');
    }

    useEffect(() => {
        if (selectTab === 1) {
                gridRef.current.setRowData(mv3curveCurvePointList);
        }
    }, [selectTab,mv3curveCurvePointList]);


    return (
        <>
            {mode === 'edit' &&
                <FetchHelper fetchId={GET_CURVE_HEADER}
                    url={`/master/curve_header/get`}
                    method='POST'
                    request={true}
                    params={{
                        requestPrms:{id:id},
                        userId: userId,
                    }}
                    json={true}
                    onComplete={(success, data, error) =>
                        success ? handleSuccess(data) : handleError(success, data, error)}
                />}
            {mode === 'add' &&
                <FetchHelper fetchId={GET_CURVE_HEADER}
                    url={`/master/curve_header/get_combox_data`}
                    method='POST'
                    request={true}
                    params={{
                        userId: userId,
                        requestPrms:{},
                    }}
                    json={true}
                    onComplete={(success, data, error) =>
                        success ? handleSuccess(data) : handleError(success, data, error)}
                />}
            {loadingFlag && (
                <div style={{ top: '0px', left: '0px', position: 'fixed', zIndex: 1500, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                    <CircularProgress />
                </div>
            )}
            <div style={{ height: '100%', width: '100%', marginTop: '10px' }}>
                <Stack direction='row' justifyContent='flex-start' alignItems='center' sx={{}} style={{ margin: '18px', color: 'MenuText' }}>
                    <Typography variant='h4'>
                        動作特性本体詳細
                    </Typography>
                </Stack>
                <form onSubmit={handleSubmit(handleEditSubmit)}>
                    <div style={{ height: '100%', width: '100%' }}>
                        <Stack direction='row'>
                            <div style={{ top: '100px', position: 'absolute', zIndex: 1200 }}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs value={selectTab} onChange={tabHandleChange} aria-label='oilManagementDetailTabs'>
                                        <Tab label='動作特性本体' {...a11yProps(0)} />
                                        {mode === "edit" && <Tab label='曲線座標' {...a11yProps(1)} />}
                                    </Tabs>
                                </Box>
                            </div>
                        </Stack>

                        {/* 動作特性本体 */}
                        <TabPanel value={selectTab} index={0}>
                            <div style={divStyles}>
                                <Typography variant='h6'>動作特性本体</Typography>
                                <Stack spacing={3} mt={3}  sx={{height: "calc(60vh)", overflow: 'auto'}}> 
                                    <Stack style={{marginTop:"10px"}}>
                                        <FormControl fullWidth size='small'>
                                            <Controller
                                                name='lManufactureID'
                                                control={control}
                                                render={({ field }) => (
                                                    <Controller
                                                        control={control}
                                                        name="lManufactureID"
                                                        render={({ field: { value } }) => (
                                                            <Autocomplete
                                                                autoHighlight
                                                                value={manufactureOptions.find(options => options.value === value)??null}
                                                                options={manufactureOptions}
                                                                getOptionLabel={(option) => option.label}
                                                                fullWidth
                                                                renderInput={(params) => <TextField {...params}  label="ﾒｰｶｰ名" size="small" />}
                                                                onChange={(_, data) => {
                                                                    setValue("lManufactureID", Number(data?.value))
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                )}
                                            />
                                        </FormControl>
                                    </Stack>
                                    <Stack>
                                        <FormControl fullWidth size='small'>
                                            <Controller
                                                control={control}
                                                name="lGroupID"
                                                render={({ field: { value } }) => (
                                                    <Autocomplete
                                                        autoHighlight
                                                        value={groupOptions.find(options => options.value === value)??null}
                                                        options={groupOptions}
                                                        getOptionLabel={(option:any) => `${option.manuName}`}
                                                        fullWidth
                                                        renderInput={(params) => <TextField {...params}  label="機種群" size="small" />}
                                                        renderOption={(props,option) => (
                                                            <MenuItem {...props} key={option.value} value={option.value}>
                                                                    <Table size='small'>
                                                                        <TableRow>
                                                                            <TableCell style={{ borderBottom: "none" }} sx={{ width: '50%' }}>
                                                                                {option.manuName}
                                                                            </TableCell>
                                                                            <TableCell style={{ borderBottom: "none" }} sx={{ width: '50%' }}>
                                                                                {option.groupName}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    </Table>
                                                            </MenuItem>
                                                          )}
                                                        onChange={(_, data) => {
                                                            setValue("lGroupID", Number(data?.value))
                                                        }}
                                                    />
                                                )}
                                            />
                                        </FormControl>
                                    </Stack>
                                    <Stack>
                                        <Controller
                                            name='sCurveName'
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    type='text'
                                                    label='曲線図面名称'
                                                    size='small'
                                                    
                                                />
                                            )}
                                        />
                                    </Stack>
                                    <Stack>
                                        <Controller
                                            name='sClassName'
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    type='text'
                                                    label='要素名称'
                                                    size='small'
                                                    
                                                />
                                            )}
                                        />
                                    </Stack>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <Stack>
                                                <FormControl fullWidth size='small'>
                                                    <Controller
                                                        control={control}
                                                        name="nCurveKind"
                                                        render={({ field: { value } }) => (
                                                            <Autocomplete
                                                                autoHighlight
                                                                value={curveKindOptions.find(options => options.value === value)??null}
                                                                options={curveKindOptions}
                                                                getOptionLabel={(option) => option.label}
                                                                fullWidth
                                                                renderInput={(params) => <TextField {...params} label="曲線種類" size="small" />}
                                                                onChange={(_, data) => {
                                                                    setValue("nCurveKind", Number(data?.value))
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </FormControl>
                                            </Stack>
                                            <Stack mt={3}>
                                                <FormControl fullWidth size='small'>
                                                    <Controller
                                                        control={control}
                                                        name="nSettingFlag"
                                                        render={({ field: { value } }) => (
                                                            <Autocomplete
                                                                autoHighlight
                                                                value={settingFlagOptions.find(options => options.value === value)??null}
                                                                options={settingFlagOptions}
                                                                getOptionLabel={(option) => option.label}
                                                                fullWidth
                                                                renderInput={(params) => <TextField {...params} label="設定基準" size="small" />}
                                                                onChange={(_, data) => {
                                                                    setValue("nSettingFlag", Number(data?.value))
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </FormControl>
                                            </Stack>
                                            <Stack mt={3}>
                                                <Controller
                                                    name='dStdCurrentValue'
                                                    control={control}
                                                    rules={{
                                                        pattern:{
                                                            value:Exp,
                                                            message:errorValidateNumber
                                                        }
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            error={!!errors?.dStdCurrentValue}
                                                            type="text"
                                                            helperText={errors?.dStdCurrentValue?.message}
                                                            label='基準X軸値(A)/(%)'
                                                            size='small'
                                                            
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack mt={3}>
                                                <Controller
                                                    name='dStdTimeValue'
                                                    control={control}
                                                    rules={{
                                                        pattern:{
                                                            value:Exp,
                                                            message:errorValidateNumber
                                                        }
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            label='基準時間値(s)'
                                                            size='small'
                                                            type="text"
                                                            error={!!errors?.dStdTimeValue}
                                                            helperText={errors?.dStdTimeValue?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack mt={3}>
                                                <Controller
                                                    name='lStdVoltage'
                                                    control={control}
                                                    rules={{
                                                        pattern:{
                                                            value:Exp,
                                                            message:errorValidateNumber
                                                        }
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            label='基準電圧値(V)'
                                                            size='small'
                                                            type="text"
                                                            error={!!errors?.lStdVoltage}
                                                            helperText={errors?.lStdVoltage?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack mt={2}>
                                                <Controller
                                                    name='bSaveFormula'
                                                    control={control}
                                                    render={({ field }: any) => (
                                                        <FormControlLabel
                                                            {...field}
                                                            control={<Checkbox style={checkboxStyle} />}
                                                            checked={field.value}
                                                            label='計算式使用'
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack mt={2}>
                                                <Controller
                                                    name='sFormula'
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type='text'
                                                            label='計算式'
                                                            size='small'
                                                            
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={6} pl={3}>
                                            <Stack>
                                                <Controller
                                                    name='dXErrorPlus'
                                                    control={control}
                                                    rules={{
                                                        pattern:{
                                                            value:Exp,
                                                            message:errorValidateNumber
                                                        }
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            label='電流プラス誤差(%)'
                                                            size='small'
                                                            type="text"
                                                            error={!!errors?.dXErrorPlus}
                                                            helperText={errors?.dXErrorPlus?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack mt={3}>
                                                <Controller
                                                    name='dXErrorMinus'
                                                    control={control}
                                                    rules={{
                                                        pattern:{
                                                            value:Exp,
                                                            message:errorValidateNumber
                                                        }
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            label='電流マイナス誤差(%)'
                                                            size='small'
                                                            type="text"
                                                            error={!!errors?.dXErrorMinus}
                                                            helperText={errors?.dXErrorMinus?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack mt={3}>
                                                <Controller
                                                    name='dYErrorPlus'
                                                    control={control}
                                                    rules={{
                                                        pattern:{
                                                            value:Exp,
                                                            message:errorValidateNumber
                                                        }
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            label='時間プラス誤差(%)'
                                                            size='small'
                                                            type="text"
                                                            error={!!errors?.dYErrorPlus}
                                                            helperText={errors?.dYErrorPlus?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack mt={3}>
                                                <Controller
                                                    name='dYErrorMinus'
                                                    control={control}
                                                    rules={{
                                                        pattern:{
                                                            value:Exp,
                                                            message:errorValidateNumber
                                                        }
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            label='時間マイナス誤差(%)'
                                                            size='small'
                                                            type="text"
                                                            error={!!errors?.dYErrorMinus}
                                                            helperText={errors?.dYErrorMinus?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack mt={3}>
                                                <Controller
                                                    name='dSaturationTime'
                                                    control={control}
                                                    rules={{
                                                        pattern:{
                                                            value:Exp,
                                                            message:errorValidateNumber
                                                        }
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            label='飽和時間(s)'
                                                            size='small'
                                                            type="text"
                                                            error={!!errors?.dSaturationTime}
                                                            helperText={errors?.dSaturationTime?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <Stack>
                                                <Controller
                                                    name='dCurrentMini'
                                                    control={control}
                                                    rules={{
                                                        pattern:{
                                                            value:Exp,
                                                            message:errorValidateNumber
                                                        }
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            label='最小電流値'
                                                            size='small'
                                                            type="text"
                                                            error={!!errors?.dCurrentMini}
                                                            helperText={errors?.dCurrentMini?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack mt={3}>
                                                <Controller
                                                    name='dCurrentMax'
                                                    control={control}
                                                    rules={{
                                                        pattern:{
                                                            value:Exp,
                                                            message:errorValidateNumber
                                                        }
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            label='最大電流値'
                                                            size='small'
                                                            type="text"
                                                            error={!!errors?.dCurrentMax}
                                                            helperText={errors?.dCurrentMax?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack mt={3}>
                                                <Controller
                                                    name='dInterval'
                                                    control={control}
                                                    rules={{
                                                        pattern:{
                                                            value:Exp,
                                                            message:errorValidateNumber
                                                        }
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            label='電流間隔'
                                                            size='small'
                                                            type="text"
                                                            error={!!errors?.dInterval}
                                                            helperText={errors?.dInterval?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack mt={3}>
                                                <Controller
                                                    name='bUseLogInput'
                                                    control={control}
                                                    render={({ field }: any) => (
                                                        <FormControlLabel
                                                            {...field}
                                                            control={<Checkbox style={checkboxStyle} />}
                                                            checked={field.value}
                                                            label='対数入力'
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack mt={3}>
                                                <Controller
                                                    name='nMaxPointValue'
                                                    control={control}
                                                    rules={{
                                                        pattern:{
                                                            value:Exp,
                                                            message:errorValidateNumber
                                                        }
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            label='最大座標数'
                                                            size='small'
                                                            type="text"
                                                            error={!!errors?.nMaxPointValue}
                                                            helperText={errors?.nMaxPointValue?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack mt={3}>
                                                <Controller
                                                    name='bUseTimeClip'
                                                    control={control}
                                                    render={({ field }: any) => (
                                                        <FormControlLabel
                                                            {...field}
                                                            control={<Checkbox style={checkboxStyle} />}
                                                            checked={field.value}
                                                            label='時間クリップ'
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack mt={3}>
                                                <Controller
                                                    name='dClipTimeMini'
                                                    control={control}
                                                    rules={{
                                                        pattern:{
                                                            value:Exp,
                                                            message:errorValidateNumber
                                                        }
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            label='最小クリップ時間'
                                                            size='small'
                                                            type="text"
                                                            error={!!errors?.dClipTimeMini}
                                                            helperText={errors?.dClipTimeMini?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack mt={3} mb={3}>
                                                <Controller
                                                    name='dClipTimeMax'
                                                    control={control}
                                                    rules={{
                                                        pattern:{
                                                            value:Exp,
                                                            message:errorValidateNumber
                                                        }
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            label='最大クリップ時間'
                                                            size='small'
                                                            type="text"
                                                            error={!!errors?.dClipTimeMax}
                                                            helperText={errors?.dClipTimeMax?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={6} pl={3}>
                                            <Stack>
                                                <FormControl fullWidth size='small'>
                                                    <Controller
                                                        control={control}
                                                        name="nGraphFlag"
                                                        render={({ field: { value } }) => (
                                                            <Autocomplete
                                                                autoHighlight
                                                                value={graphFlagOptions.find(options => options.value === value)??null}
                                                                options={graphFlagOptions}
                                                                getOptionLabel={(option) => option.label}
                                                                fullWidth
                                                                renderInput={(params) => <TextField {...params} label="グラフ種類" size="small" />}
                                                                onChange={(_, data) => {
                                                                    setValue("nGraphFlag", Number(data?.value))
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </FormControl>
                                            </Stack>
                                            <Stack mt={3}>
                                                <Controller
                                                    name='dXMinimum'
                                                    control={control}
                                                    rules={{
                                                        pattern:{
                                                            value:Exp,
                                                            message:errorValidateNumber
                                                        }
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            label='最小電流値'
                                                            size='small'
                                                            type="text"
                                                            error={!!errors?.dXMinimum}
                                                            helperText={errors?.dXMinimum?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack mt={3}>
                                                <Controller
                                                    name='dXWidth'
                                                    control={control}
                                                    rules={{
                                                        pattern:{
                                                            value:Exp,
                                                            message:errorValidateNumber
                                                        }
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            label='電流目盛幅'
                                                            size='small'
                                                            type="text"
                                                            error={!!errors?.dXWidth}
                                                            helperText={errors?.dXWidth?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack mt={3}>
                                                <Controller
                                                    name='dXMaximum'
                                                    control={control}
                                                    rules={{
                                                        pattern:{
                                                            value:Exp,
                                                            message:errorValidateNumber
                                                        }
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            label='最大電流値'
                                                            size='small'
                                                            type="text"
                                                            error={!!errors?.dXMaximum}
                                                            helperText={errors?.dXMaximum?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack mt={3}>
                                                <Controller
                                                    name='dYMinimum'
                                                    control={control}
                                                    rules={{
                                                        pattern:{
                                                            value:Exp,
                                                            message:errorValidateNumber
                                                        }
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            label='最小時間値'
                                                            size='small'
                                                            type="text"
                                                            error={!!errors?.dYMinimum}
                                                            helperText={errors?.dYMinimum?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack mt={3}>
                                                <Controller
                                                    name='dYWidth'
                                                    control={control}
                                                    rules={{
                                                        pattern:{
                                                            value:Exp,
                                                            message:errorValidateNumber
                                                        }
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            label='時間目盛幅'
                                                            size='small'
                                                            type="text"
                                                            error={!!errors?.dYWidth}
                                                            helperText={errors?.dYWidth?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack mt={3} >
                                                <Controller
                                                    name='dYMaximum'
                                                    control={control}
                                                    rules={{
                                                        pattern:{
                                                            value:Exp,
                                                            message:errorValidateNumber
                                                        }
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            label='最大時間値'
                                                            size='small'
                                                            type="text"
                                                            error={!!errors?.dYMaximum}
                                                            helperText={errors?.dYMaximum?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Stack>
                            </div>
                        </TabPanel>
                        {/* 曲線座標 */}
                        {mode === "edit" &&
                            <TabPanel value={selectTab} index={1}>
                                <div style={divStyles}>
                                    <Stack direction="row" display='flex' justifyContent='space-between'>
                                        <div><Typography variant="h6" >曲線座標</Typography></div>
                                        <div >
                                            <Stack direction='row' display='flex' spacing={2}>
                                                <Stack>
                                                    <Button color='inherit' variant="contained" onClick={() => handleAddPlayList()}>csv取込</Button>
                                                </Stack>
                                                <Stack>
                                                    <Button color='inherit' fullWidth variant="contained" onClick={() => handleAdd()}>曲線座標の追加</Button>
                                                </Stack>
                                            </Stack>
                                        </div>
                                    </Stack>
                                    <Stack spacing={2} sx={{ mt: 1 }} >
                                        <CurvePointGrid
                                            ref={gridRef}
                                            colState={colState}
                                            onChangeSearchCondition={() => { }}
                                            keyword={""}
                                            onShowDetails={handleShowDetailPage}
                                            onDelete={handleDelete}
                                            heightForm="365"
                                            mode ="edit"
                                        />
                                    </Stack>
                                </div>
                            </TabPanel>
                        }
                        <Stack direction='row' justifyContent='flex-end' spacing={2} sx={{ mt: 1, mr: 2 }}>
                            <LoadingButton variant='contained' type='submit' loading={loadingFlag}>保存</LoadingButton>
                            <Button color='inherit' variant='contained' onClick={handleCancel}>キャンセル</Button>
                        </Stack>
                        {openedDeleteDialog &&
                            <AlertDialog title={title} message={message} isOpen={true} onOK={handleDeleteOK} onCancel={handleDeleteCancel} />
                        }
                    </div>
                </form>
            </div>
            <FetchHelper
                fetchId={CREATE_CURVE_HEADER}
                onComplete={(success, data, error) => success ?
                    handleSubmitSuccess(success, data, error)
                    : handleSubmitError(success, data, error)}
            />
            <FetchHelper
                fetchId={UPDATE_CURVE_HEADER}
                onComplete={(success, data, error) => success ?
                    handleSubmitSuccess(success, data, error)
                    : handleSubmitError(success, data, error)}
            />
            <FetchHelper
                fetchId={DELETE_CURVE_POINT}
                onComplete={(success, data, error) => success ?
                    handleDeleteSuccess(success, data, error)
                    : handleDeleteError(success, data, error)}
            />
        </>
    );
};

const mapStateToProps = (state: ApplicationState) => ({
    userId: state.app.user?.userId,
    fetchUpdate: selectors.getFormState(
        state,
        UPDATE_CURVE_HEADER
    ),
});

const mapDispatchToProps = (dispatch: any) => {
    return {
        onCreate: (data: any) => dispatch(actionCreators.fetch(CREATE_CURVE_HEADER, `/master/curve_header/add`, 'POST', data,false,true)),
        onUpdate: (data: any) => dispatch(actionCreators.fetch(UPDATE_CURVE_HEADER, `/master/curve_header/modify`, 'POST', data,false,true)),
        onDeleteCurvePoint: (data: any) => dispatch(actionCreators.fetch(DELETE_CURVE_POINT, `/master/curve_point/delete`, 'POST', data,false,true)),
        onSuccess: () =>
            dispatch(actionCreators.showMessage({
                type: 'info',
                title: '動作特性本体更新',
                body: '動作特性本体を更新しました。',
            })),
        onError: () =>
            dispatch(actionCreators.showMessage({
                type: 'error',
                title: '検索',
                body: '検索できません。',
            })),
        onCreateError: () =>
            dispatch(actionCreators.showMessage({
                type: 'error',
                title: '動作特性本体登録',
                body: '動作特性本体を登録出来ませんでした。',
            })),
        onUpdateError: () =>
            dispatch(actionCreators.showMessage({
                type: 'error',
                title: '動作特性本体編集',
                body: '動作特性本体を更新出来ませんでした。',
            })),
        onDeleteCurvePointError: () =>
            dispatch(actionCreators.showMessage({
                type: 'error',
                title: '定格編集',
                body: '定格を更新出来ませんでした。',
            })),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CurveHeaderEdit as any);


