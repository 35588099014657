import { store } from "..";
import { doGetTextIntensityValue } from "../components/graph/trans3/CreateTrans3Graph";
import { DIRECT_NONE, LEFT_CENTER, MS_VOLTDROP_MOTOR, MS_VOLTDROP_REGULAR, TOP_CENTER } from "../models/Constants";
import { ControlModel, MsTrans3Model, ProcessMode } from "../models/Index";
import { MS_MODE_CALCULATION, MS_MODE_CHECKING, MS_MODE_DRAWING, MS_MODE_HARMONIC, MS_MODE_PERCENT_Z, MS_MODE_POWER_FLOW, MS_MODE_VOLTDROP } from "../statics";
import { BeamsNumber } from "./FormatNumber";
import { doGetValuePerZk3 } from "./PercentZ";
import { doGetIkasymDirectMG, 
        doGetIkpeakDirectMG, doGetIksymDirectMG, doGetIksymTotalMG, isSetDropPerMotor, 
        doGetTextDropVoltMotor as doGetTextDropVoltMotorCalcPoint,
        doGetTextDropVoltMotor2 as doGetTextDropVoltMotor2CalcPoint,
        doGetTextDropPerMotor as doGetTextDropPerMotorCalcPoint,
        doGetTextDropVoltRegular as doGetTextDropVoltRegularCalcPoint,
        doGetTextDropPerRegular as doGetTextDropPerRegularCalcPoint,
        doGetTextDropPerRegular2 as doGetTextDropPerRegular2CalcPoint,
        doGetValueDropVoltRegular as doGetValueDropVoltRegularCalcPoint,
        doGetPerZDirectMG,
        doGetIkpeakTotalMG,
        doGetIkasymTotalMG,
        doGetPerZTotalMG
     } from "./calcPointFunction";
import { doGetLoadCurrentEndSide, doGetLoadCurrentStartSide, isCennectLoadDropElement, isCennectSourceDropElement } from "./element";

//CEleTrans3::DoGetPerZrTrans3
export const doGetPerZrTrans3 = (props: MsTrans3Model, dStdCapacity: number) => {
    let dVoltage = props.voltageSecondary;
    let dVoltageAdjust = props.voltageAdjust;
    dVoltage /= dVoltageAdjust;

    let percentZ = {
        perRk3: props.percentR,
        perXk3: props.percentX
    };

    if (props.capacity == 0) {
        percentZ.perRk3 = 0;
        percentZ.perXk3 = 0;
    } else {
        percentZ.perRk3 *= dStdCapacity / props.capacity * dVoltage * dVoltage;
        percentZ.perXk3 *= dStdCapacity / props.capacity * dVoltage * dVoltage;
    }
    return percentZ;
}

//CEleTrans3::DoGetValueRatedCurrent
export const doGetValueRatedCurrent = (dVoltage: number, dCapacity: number) => {
    let dRatedCurrent = 0;
    if (dVoltage <= 0) {
        dRatedCurrent = 0;
    } else {
        dRatedCurrent = dCapacity * 1000 / dVoltage / Math.sqrt(3); 
    }
    return dRatedCurrent;
}

//CEleTrans3::IsPointFaultTrans3
export const isPointFaultTrans3 = (props: MsTrans3Model) => {
    if (props.fault) {
        return true;
    }
    return false;
}

//CEleTrans3::DoGetTextVoltPrimary
export const doGetTextVoltPrimary = (props: MsTrans3Model, bUnit: boolean = true) => {
    let sText = props.voltagePrimary.toString();
    if (bUnit) {
        sText += 'V';
    }
    return sText;
}

//CEleTrans3::DoGetTextVoltSecondary
export const doGetTextVoltSecondary = (props: MsTrans3Model, bUnit: boolean = true) => {
    let sText = props.voltageSecondary.toString();
    if (bUnit) {
        sText += 'V';
    }
    return sText;
}

//CEleTrans3::DoGetTextPointState
export const doGetTextPointState = (props:MsTrans3Model) =>
{
	let sText = ''; 

	if (props.fault) {
		sText = 'Yes';
	}
	else {
		sText = 'No';
	}

	return sText;
}

//CEleTrans3::DoGetTextCapacity
export const doGetTextCapacity = (props: MsTrans3Model, bUnit: boolean = true) => {
    let sText = BeamsNumber(props.capacity, 6).toString();
    if (bUnit) {
        sText += 'kVA';
    }
    return sText;
}

//CEleTrans3::DoGetTextRatedCurrent
export const doGetTextRatedCurrent = (props: MsTrans3Model, lVoltage: number, bUnit: boolean = true) => {
    let dRatedCurrent = doGetValueRatedCurrent(lVoltage, props.capacity);
    let sText = BeamsNumber(dRatedCurrent, 4).toString();
    if (bUnit) {
        sText += 'A';
    }
    return sText;
}

//CEleTrans3::DoGetTextPercentZe
export const doGetTextPercentZe = (props: MsTrans3Model, bUnit: boolean = true) => {
    let sText = BeamsNumber(props.percentZ, 5).toString(); //TODO: percentZ -> per_z_k3
    if (bUnit) {
        sText += '%e';
    }
    return sText;
}

//CEleTrans3::DoGetTextXR
export const doGetTextXR = (props: MsTrans3Model) => {
    return BeamsNumber(props.xr, 5).toString();
}

//CEleTrans3::DoGetTextPercentZr
export const doGetTextPercentZr = (props: MsTrans3Model, dStdCapacity: number, bUnit: boolean = true) => {
    let percentZ = doGetPerZrTrans3(props, dStdCapacity);
    let dPercentZ = doGetValuePerZk3(percentZ).dPercentZ;
    let sText = BeamsNumber(dPercentZ, 4).toString();
    if (bUnit) {
        sText += '%r';
    }
    return sText;
}

//CEleTrans3::DoGetTextPercentRr
export const doGetTextPercentRr = (props: MsTrans3Model, dStdCapacity: number, bUnit: boolean = true) => {
    let percentZ = doGetPerZrTrans3(props, dStdCapacity);
    let sText = BeamsNumber(percentZ.perRk3, 4).toString();
    if (bUnit) {
        sText += '%r';
    }
    return sText;
}

//CEleTrans3::DoGetTextPercentXr
export const doGetTextPercentXr = (props: MsTrans3Model, dStdCapacity: number, bUnit: boolean = true) => {
    let percentZ = doGetPerZrTrans3(props, dStdCapacity);
    let sText = BeamsNumber(percentZ.perXk3, 4).toString();
    if (bUnit) {
        sText += '%r';
    }
    return sText;
}

//CEleTrans3::DoGetTextDirectIk3peak
export const doGetTextDirectIk3peak = (props:MsTrans3Model, bUnit: boolean = true ) => {
    let dIpeakDirect = doGetIkpeakDirectMG(props.calcPoint0)
    let sText;
    if (dIpeakDirect >= 0) {
        sText = BeamsNumber(dIpeakDirect / 1000,4);
        if (bUnit) {
          sText = sText + 'kApeak';
        }
    } else {
        sText = "????"
    }
    return sText;
}

//CEleTrans3::DoGetTextDirectIk3asym
export const doGetTextDirectIk3asym = (props:MsTrans3Model, bUnit: boolean = true ) => {
    let dIasymDirect = doGetIkasymDirectMG(props.calcPoint0)
    let sText  = '';
    if (dIasymDirect >= 0) {
        sText = BeamsNumber(dIasymDirect / 1000,4).toString();
        if (bUnit) {
          sText = sText + 'kAasym';
        }
    } else {
        sText = "????"
    }
    return sText;
}

//CEleTrans3::DoGetTextDirectIk3sym
export const doGetTextDirectIk3sym = (props:MsTrans3Model, bUnit: boolean = true ) => {
    let dIsymDirect = doGetIksymDirectMG(props.calcPoint0)
    let sText;
    if (dIsymDirect >= 0) {
        sText = BeamsNumber(dIsymDirect / 1000,4);
        if (bUnit) {
          sText = sText + 'kAsym';
        }
    } else {
        sText = "????"
    }
    return sText;
}

//CEleTrans3::DoGetTextTotalIk3sym
export const doGetTextTotalIk3sym = (props:MsTrans3Model, bUnit: boolean = true ) => {
    let dIsymTotal = doGetIksymTotalMG(props.calcPoint0)
    let sText;
    if (dIsymTotal >= 0) {
        sText = BeamsNumber(dIsymTotal / 1000,4);
        if (bUnit) {
          sText = sText + 'kAsym';
        }
    } else {
        sText = "????"
    }
    return sText;
}

//CEleTrans3::DoGetTextDropVoltMotor
export const doGetTextDropVoltMotor = (props:MsTrans3Model, bUnit: boolean = true) => {
    return doGetTextDropVoltMotorCalcPoint(props.calcPoint0, bUnit)
}

//CEleTrans3::DoGetTextDropVoltMotor2
export const doGetTextDropVoltMotor2 = (props:MsTrans3Model, bUnit: boolean = true) => {
    return doGetTextDropVoltMotor2CalcPoint(props.calcPoint0, bUnit)
}

//CEleTrans3::DoGetTextDropPerMotor
export const doGetTextDropPerMotor = (props:MsTrans3Model, bUnit: boolean = true) => {
    return doGetTextDropPerMotorCalcPoint(props.calcPoint0, bUnit)
}

//CEleTrans3::DoGetTextLoadCurrent
export const doGetTextLoadCurrent = (props:MsTrans3Model, bUnit: boolean = true) => {
    let sText = BeamsNumber(props.calcPoint0.loadCurrent, 5).toString();
    if (bUnit) {
        sText += 'A';
    }
    return sText;
}

//CEleTrans3::doGetTextDropVoltPF
export const doGetTextDropVoltPF = (props:MsTrans3Model, bUnit: boolean = true) => {
    let sText = BeamsNumber(props.calcPoint0.powerFactor, 5).toString();
    if (bUnit) {
        let sUnit = '';
        if (props.calcPoint0.isLead) {
            sUnit = '(進み)';
        } else {
            sUnit = '(遅れ)';
        }
        sText += sUnit;
    }
    return sText;
}

//CEleTrans3::DoGetTextDropVoltDiff
export const doGetTextDropVoltDiff = (props:MsTrans3Model, dStdCapacity: number, nOpeMode: number, bUnit: boolean = true) => {
    let dVolt = 0;
    let percentZ = doGetPerZrTrans3(props, dStdCapacity);

    if (nOpeMode == ProcessMode.DRAWING) {
        dVolt = doGetValueDropVoltRegularCalcPoint(props.voltageSecondary, percentZ.perRk3, percentZ.perXk3, props.calcPoint0);
        dVolt = props.voltageSecondary - dVolt;
    } else {
        if (props.supply) {
            dVolt = doGetValueDropVoltRegularCalcPoint(props.voltageSecondary, percentZ.perRk3, percentZ.perXk3, props.calcPoint0);
            dVolt = props.voltageSecondary - dVolt; 
        }
    }

    let sText = BeamsNumber(dVolt, 4).toString();
    if (bUnit) {
        sText += 'V';
    }
    return sText;
}

//CEleTrans3::DoGetTextDropVoltRegular
export const doGetTextDropVoltRegular = (props:MsTrans3Model, dStdCapacity: number, bUnit: boolean = true) => {
    let percentZ = doGetPerZrTrans3(props, dStdCapacity);
    let sText = '';
    if (props.supply) {
        sText = doGetTextDropVoltRegularCalcPoint(props.calcPoint0, props.voltageSecondary, percentZ.perRk3, percentZ.perXk3, bUnit);
    } else {
        sText = doGetTextDropVoltRegularCalcPoint(props.calcPoint0, 0, percentZ.perRk3, percentZ.perXk3, bUnit);
    }
    return sText;
}

//CEleTrans3::DoGetValueDropVoltRegular
export const doGetValueDropVoltRegular = (props:MsTrans3Model, dStdCapacity: number, bUnit: boolean = true) => {
    let percentZ = doGetPerZrTrans3(props, dStdCapacity);
    let dVolt = doGetValueDropVoltRegularCalcPoint(props.voltageSecondary, percentZ.perRk3, percentZ.perXk3, props.calcPoint0);
    return dVolt;
}

//CEleTrans3::DoGetTextDropPerRegular
export const doGetTextDropPerRegular = (props:MsTrans3Model, dStdCapacity: number, bUnit: boolean = true) => {
    let percentZ = doGetPerZrTrans3(props, dStdCapacity);

    let sText = '';
    if (props.supply) {
        sText = doGetTextDropPerRegularCalcPoint(props.calcPoint0, props.voltageSecondary, percentZ.perRk3, percentZ.perXk3, bUnit);
    } else {
        sText = doGetTextDropPerRegularCalcPoint(props.calcPoint0, 0, percentZ.perRk3, percentZ.perXk3, bUnit);
    }
    return sText;
}

//CEleTrans3::DoGetTextDropPerRegular2
export const doGetTextDropPerRegular2 = (props:MsTrans3Model, dStdCapacity: number, bUnit: boolean = true) => {
    let percentZ = doGetPerZrTrans3(props, dStdCapacity);

    let sText = '';
    if (props.supply) {
        sText = doGetTextDropPerRegular2CalcPoint(props.calcPoint0, props.voltageSecondary, percentZ.perRk3, percentZ.perXk3, bUnit);
    } else {
        sText = doGetTextDropPerRegular2CalcPoint(props.calcPoint0, 0, percentZ.perRk3, percentZ.perXk3, bUnit);
    }
    return sText;
}

//CEleTrans3::DoGetRefV1
export const doGetRefV1 = (mode: any, control:ControlModel, bSociety:boolean) => {
    let props = control.properties as MsTrans3Model;
    let infoCircuit = store.getState().app.diagram.infoCircuit;
    let project = store.getState().app.projectData;

    //CEleTrans3::DoGetRefV1Left  sRefV1Left
    let labelTexts = [{
        value: control.properties.refNo,
        color: "#f800f8",
    }];
  
    if (!control.properties.viewResultText || (mode != ProcessMode.DRAWING && !props.supply))
    {
      return labelTexts;
    }
    
    if (bSociety){
        //VoltPrimary / VoltSecondary
        labelTexts.push({
            value: doGetTextVoltPrimary(props)
                + '/'
                + doGetTextVoltSecondary(props)
                ,
            color: "blue",
        });

        //Capacity
        labelTexts.push({
            value: doGetTextCapacity(props),
            color: "blue",
        });
        return labelTexts;
    }

    switch(mode) {
        case ProcessMode.DRAWING:
        case ProcessMode.CHECKING:
            //VoltPrimary / VoltSecondary
            labelTexts.push({
                value: doGetTextVoltPrimary(props)
                    + '/'
                    + doGetTextVoltSecondary(props)
                    ,
                color: "blue",
            });

            //Capacity
            labelTexts.push({
                value: doGetTextCapacity(props),
                color: "blue",
            });

            //RatedCurrent
            labelTexts.push({
                value: doGetTextRatedCurrent(props, props.voltageSecondary)
                       + ','
                       + doGetTextPercentZe(props)
                       ,
                color: "blue",
            });

            //XR
            labelTexts.push({
                value: 'X/R=' + doGetTextXR(props),
                color: "blue",
            });
            break;
        case ProcessMode.PERCENT_Z:
            //XR
            labelTexts.push({
                value: 'X/R=' + doGetTextXR(props),
                color: "blue",
            });

            // Zr = Rr + jXr
            labelTexts.push({
                value: doGetTextPercentZr(props, infoCircuit.stdCapacity)
                     + '='
                     + doGetTextPercentRr(props, infoCircuit.stdCapacity, false)
                     + '+j'
                     + doGetTextPercentXr(props, infoCircuit.stdCapacity, false)
                     ,
                color: "blue",
            });

            break;
        case ProcessMode.CALCULATION:
            //VoltPrimary / VoltSecondary
            labelTexts.push({
                value: doGetTextVoltPrimary(props)
                    + '/'
                    + doGetTextVoltSecondary(props)
                    ,
                color: "blue",
            });

            if (!isPointFaultTrans3(props)) {
                break;
            }

            // += 'I"k3' + '通過' + '=' + DoGetTextDirectIk3peak()
            labelTexts.push({
                value: 'I"k3通過=' + doGetTextDirectIk3peak(props),
                color: "blue",
            });

            // += 'I"k3' + '通過' + '=' + DoGetTextDirectIk3asym()
            labelTexts.push({
                value: 'I"k3通過=' + doGetTextDirectIk3asym(props),
                color: "blue",
            });

            // += 'I"k3' + '通過' + '=' + DoGetTextDirectIk3sym()
            labelTexts.push({
                value: 'I"k3通過=' + doGetTextDirectIk3sym(props),
                color: "blue",
            });

            // += 'I"k3' + '合計' + '=' + DoGetTextTotalIk3sym()
            labelTexts.push({
                value: 'I"k3合計=' + doGetTextTotalIk3sym(props),
                color: "blue",
            });

            break;
        case ProcessMode.VOLTDROP:
            //VoltPrimary / VoltSecondary
            labelTexts.push({
                value: doGetTextVoltPrimary(props)
                    + '/'
                    + doGetTextVoltSecondary(props)
                    ,
                color: "blue",
            });

            if (project.dropMode == MS_VOLTDROP_MOTOR && props.dropMotor && isSetDropPerMotor(props.calcPoint0))
            {
                // Vd = DropVoltMotor(VoltSecondary - DropVoltMotor2)
                let sTemp = 'Vd=' + doGetTextDropVoltMotor(props) + ' (';
                if (props.supply ) {
                    sTemp += doGetTextVoltSecondary(props, false);
                    sTemp += '-';
                    sTemp += doGetTextDropVoltMotor2(props, false);
                } else {
                    sTemp += '0-0'
                }
                sTemp += ')'
                labelTexts.push({
                    value: sTemp,
                    color: "blue",
                });

                //Vp = DropPerMotor (VoltSecondary → DropVoltMotor2)
                sTemp = 'Vp=' + doGetTextDropPerMotor(props) + ' (';
                if (props.supply) {
                    sTemp += doGetTextVoltSecondary(props, false);
                    sTemp += '→';
                    sTemp += doGetTextDropVoltMotor2(props, false);
                } else {
                    sTemp += '0→0'
                }
                sTemp += ')'
                labelTexts.push({
                    value: sTemp,
                    color: "blue",
                });
                break;
            }
            if (isCennectSourceDropElement(control, doGetLoadCurrentStartSide(control)) &&
                isCennectLoadDropElement(control, doGetLoadCurrentEndSide(control)) &&
                project.dropMode == MS_VOLTDROP_REGULAR && props.dropRegular) {
                
                //IL=LoadCurrent,カ率=DropVoltPF
                labelTexts.push({
                    value: 'IL=' 
                         + doGetTextLoadCurrent(props) 
                         + ',カ率='
                         + doGetTextDropVoltPF(props)
                         , 
                    color: "blue",
                });

                //Vd=DropVoltDiff (VoltSecondary-DropVoltRegular)
                let sTemp = 'Vd=';
                sTemp += doGetTextDropVoltDiff(props, infoCircuit.stdCapacity, mode);
                sTemp += ' (';
                if (props.supply) {
                    sTemp += doGetTextVoltSecondary(props, false);
                } else {
                    sTemp += '0';
                }
                sTemp += '-';
                sTemp += doGetTextDropVoltRegular(props, infoCircuit.stdCapacity, false);
                sTemp += ')';
                labelTexts.push({
                    value: sTemp, 
                    color: "blue",
                });

                //Vp=DropPerRegular (DropPerRegular2)
                labelTexts.push({
                    value: 'Vp='
                         + doGetTextDropPerRegular(props, infoCircuit.stdCapacity)
                         + ' ('
                         + doGetTextDropPerRegular2(props, infoCircuit.stdCapacity)
                         + ')'
                         , 
                    color: "blue",
                });
            }
            break;
        case ProcessMode.HARMONIC:
            //VoltPrimary / VoltSecondary
            labelTexts.push({
                value: doGetTextVoltPrimary(props)
                    + '/'
                    + doGetTextVoltSecondary(props)
                    ,
                color: "blue",
            });
            break;
        case ProcessMode.POWER_FLOW:
          break;
        default:
          break;
      }
    
      return labelTexts;
}

export const doGetRefV2_0 = (properties: MsTrans3Model, dNominalVolt: number) => {
    let infos = [];
    const text = properties.refNo;
    const name = properties.pointText;
    const m_dCapacity = properties.capacity;
    const m_lVoltPrimary = properties.voltagePrimary;
    const m_dDampingTime = properties.dampingTime;
    const m_dInrushTimes = properties.inrushTimes;

    infos.push(text);
    infos.push(name);
    infos.push(m_dCapacity+`kVA`);
    if(m_lVoltPrimary === dNominalVolt){
        let dRatedCurrent = Math.round(doGetValueRatedCurrent(m_lVoltPrimary,m_dCapacity));
        infos.push(dRatedCurrent.toString()+`A`);
    }
    else{
        let dRatedCurrent = doGetValueRatedCurrent(m_lVoltPrimary,m_dCapacity);
        dRatedCurrent = Math.round((dRatedCurrent * m_lVoltPrimary)/ dNominalVolt);
        infos.push(dRatedCurrent.toString()+`A`+` (`+ Math.round(doGetValueRatedCurrent(m_lVoltPrimary,m_dCapacity)) +`A)`);

    }
    
    infos.push(m_dInrushTimes +`倍,`+m_dDampingTime+`ms` );
    return infos
}

export const doGetRefV2_1 = (properties: MsTrans3Model, dNominalVolt: number) => {
    let infos = []
    const text = properties.refNo;
    const name = properties.pointText
    const m_dCapacity = properties.capacity;
    const m_lVoltSecondary = properties.voltageSecondary;
    const m_dIntensityTimes = properties.intensityTimes;
    const m_dIntensityTime = properties.intensityTime;
    const intensityValue = doGetTextIntensityValue(m_lVoltSecondary,m_dCapacity,m_dIntensityTimes);
    infos.push(text);
    infos.push(name);
    infos.push(m_dCapacity.toString()+`kVA`);
    infos.push(m_dIntensityTimes.toString() + `倍,`);
    if(properties.voltageSecondary === dNominalVolt) {
        
        infos.push(intensityValue.toString()+`Arms`)
    }
    else{
      let dIntensityValue = 0;
      let dRatedCurrent = doGetValueRatedCurrent(properties.voltageSecondary,m_dCapacity);
      dIntensityValue = m_dIntensityTimes * dRatedCurrent;
      const values = Math.round(dIntensityValue *properties.voltageSecondary / dNominalVolt);
      infos.push(values.toString() +`Arms`);
      infos.push(`(` + intensityValue +`)`)
    }
    infos.push(m_dIntensityTime.toString()+`s`)

    return infos
}

//CEleTrans3::DoGetTextDirectPercentZr
export const doGetTextDirectPercentZr = (props:ControlModel, bUnit:boolean = true) =>
{
	let dPerZ = 0;
	dPerZ = doGetPerZDirectMG(props.properties.calcPoint0).dPercentZ;
	let sText = '';
	if (dPerZ >= 0) {
		sText = BeamsNumber(dPerZ, 4).toString();
		if (bUnit) {
			sText += '%r';
		}
	}
	else {							// 短絡電流が正しく計算されなかったとき
		sText = '????';
	}
	return sText;
}

//CEleTrans3::DoGetTextTotalIk3peak
export const doGetTextTotalIk3peak = (props:MsTrans3Model, bUnit:boolean = true) =>
{
	let dIpeakTotal = doGetIkpeakTotalMG(props.calcPoint0);
	let sText = '';
	if (dIpeakTotal >= 0) {
		sText = BeamsNumber(dIpeakTotal / 1000, 4).toString();
		if (bUnit) {
			sText += 'kApeak';
		}
	}
	else {							// 短絡電流が正しく計算されなかったとき
		sText = '????';
	}
	return sText;
}

//CEleTrans3::DoGetTextTotalIk3asym
export const doGetTextTotalIk3asym = (props:MsTrans3Model, bUnit:boolean = true) =>
{
	let dIasymTotal = doGetIkasymTotalMG(props.calcPoint0);
	let sText = '';
	if (dIasymTotal >= 0) {
		sText = BeamsNumber(dIasymTotal / 1000, 4).toString();
		if (bUnit) {
            sText += 'kAasym';
		}
	}
	else {							// 短絡電流が正しく計算されなかったとき
        sText = '????';
	}
	return sText;
}

//CEleTrans3::DoGetTextTotalPercentZr
export const doGetTextTotalPercentZr = (props:MsTrans3Model, bUnit: boolean = true) =>
{
	let dPerZ = doGetPerZTotalMG(props.calcPoint0).dPercentZ;
	let sText = '';
	if (dPerZ >= 0) {
		sText = BeamsNumber(dPerZ, 4).toString();
		if (bUnit) {
			sText += '%r';
		}
	}
	else {							// 短絡電流が正しく計算されなかったとき
		sText = '????';
	}
	return sText;
}

//CEleTrans3::DoGetTextActiveLeft
export const doGetTextActiveLeft = (props:MsTrans3Model, nDirect:number, bUnit:boolean = true) =>
{
	let sText = "";
	if (nDirect == DIRECT_NONE) {
		if (props.lineActiveFlow1 >= 0) {
			sText = BeamsNumber(props.lineActiveFlow1, 5).toString();
		}
		else {
			sText = BeamsNumber(props.lineActiveFlow2, 5).toString();
		}
	}
	else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
		sText = BeamsNumber(Math.abs(props.lineActiveFlow1), 5).toString();
	}
	else {
		sText = BeamsNumber(Math.abs(props.lineActiveFlow2), 5).toString();
	}

	if (bUnit) {
		sText += 'kW';
	}

	return sText;
}

//CEleTrans3::DoGetTextReactiveLeft
export const doGetTextReactiveLeft = (props:MsTrans3Model, nDirect:number, bUnit:boolean = true) =>
{
	let sText = "";
	if (nDirect == DIRECT_NONE) {
		if (props.lineReactiveFlow1 >= 0) {
			sText = BeamsNumber(props.lineReactiveFlow1, 5).toString();
		}
		else {
			sText = BeamsNumber(props.lineReactiveFlow2, 5).toString();
		}
	}
	else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
		sText = BeamsNumber(Math.abs(props.lineReactiveFlow1), 5).toString();
	}
	else {
		sText = BeamsNumber(Math.abs(props.lineReactiveFlow2), 5).toString();
	}

	if (bUnit) {
		sText += 'kvar';
	}

	return sText;
}

//CEleTrans3::DoGetTextFlowsResult
export const doGetTextFlowsResult = (props:MsTrans3Model, processMode:number, nDirect:number, bSociety:boolean = false) =>
{
	let sRefV1:string[] = [];

	if (bSociety) {
		return sRefV1;
	}

	switch (processMode) {
	case MS_MODE_DRAWING:
	case MS_MODE_CHECKING:
	case MS_MODE_PERCENT_Z:
	case MS_MODE_CALCULATION:
	case MS_MODE_VOLTDROP:
	case MS_MODE_HARMONIC:
		break;
	case MS_MODE_POWER_FLOW:
		if (props.lineActiveFlow1 !== 0 && props.lineActiveFlow2 !== 0 && props.lineReactiveFlow1 !== 0 && props.lineReactiveFlow2 !== 0) {
			if (nDirect == DIRECT_NONE) {
				sRefV1.push(doGetTextActiveLeft(props,nDirect));
				sRefV1.push(doGetTextReactiveLeft(props,nDirect));
			}
			else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
				sRefV1.push(doGetTextActiveLeft(props,nDirect));
                let sTemp = ''
				if (props.lineActiveFlow1 > 0) {
					if (props.lineReactiveFlow1 > 0) {
						sTemp += "+j";
					}
					else {
						sTemp += "-j";
					}
				}
				else {
					if (props.lineReactiveFlow1 > 0) {
						sTemp += "-j";
					}
					else {
						sTemp += "+j";
					}
				}
				sRefV1.push(sTemp + doGetTextReactiveLeft(props,nDirect));
			}
			else {
				sRefV1.push(doGetTextActiveLeft(props,nDirect));
                let sTemp = ''
				if (props.lineActiveFlow2> 0) {
					if (props.lineReactiveFlow2 > 0) {
						sTemp += "+j";
					}
					else {
						sTemp += "-j";
					}
				}
				else {
					if (props.lineReactiveFlow2 > 0) {
						sTemp += "-j";
					}
					else {
						sTemp += "+j";
					}
				}
				sRefV1.push(sTemp + doGetTextReactiveLeft(props,nDirect));
			}
		}
		break;
	}

	return sRefV1;
}