import { Button, Dialog, DialogContent, DialogTitle, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, TextField } from "@mui/material";
import { useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import theme from "../../themes/globalStyles";
import DraggablePaper from "../common/DraggablePaper";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { ArrowLeft, ArrowRight, ArrowDropUp, ArrowDropDown, West, East, North, South} from '@mui/icons-material';
import { DatasetModel, GapLine, Point } from "../../models/Index";
import * as math from "mathjs"
import {Point as EndPoint, Line, Segment} from '@flatten-js/core';
import { groupBy } from "../../utils/groupBy";
import { BeamsNumber } from "../../utils/FormatNumber";
import { useDispatch } from "react-redux";
import { publishChartEvents } from "../../store/Actions";

const dialogTheme = createTheme({
    components: {
      MuiDialog: {
        styleOverrides: {
          root: {
            pointerEvents: 'auto',
          },
        },
      },
    },
  });

type InterSectInfo = {
    label : string;
    value : number;
}

export type DifferenceDisplayDialogProps = {
    gapLine: any;
    intersectionData: any;
    setOpenDisplayDialog: any;
};

export const DifferenceDisplayDialog = (props: DifferenceDisplayDialogProps) => {
    const dispatch = useDispatch()
    //#region fields
    const { gapLine, intersectionData, setOpenDisplayDialog } = props;

    const [timeDifference, setTimeDifference] = useState({first : "", second : "", result: ""});
    const [currentDifference, setCurrentDifference] = useState({first : "", second : "", result: ""});
    const [selectedTime, setSelectedTime] = useState({first : "", second : "", select: ""});
    const [selectedCurrent, setSelectedCurrent] = useState({first : "", second : "", select: ""});
    //#endregion fields

    //#region methods
    const handleCalcTime = (item : any, index : any) => {
        const toSelect = `${item.label}_${index}`
        if (toSelect === selectedTime.select) return
        setSelectedTime(prev => ({...prev, select: toSelect}))
        dispatch(publishChartEvents({ event: 'SELECT_CONTROLS', payload: {id:[item.controlId], state:"intersect"}}))
        const value = BeamsNumber(item.value, 4)
        if (timeDifference.first === "") {
            setTimeDifference(prev => ({...prev, first: ""+value}))
            setSelectedTime(prev => ({...prev, first: toSelect}))
        }
        else if (timeDifference.first !== "" && timeDifference.second === "") {
            const res = ""+BeamsNumber(+timeDifference.first - value, 4)
            setTimeDifference(prev => ({...prev, second: ""+value, result: res}))
            setSelectedTime(prev => ({...prev, second: toSelect}))
        } else {
            setTimeDifference({ first: ""+value, second: "", result: ""})
            setSelectedTime({first : toSelect, second : "", select: toSelect})
        }
    }
    
    const handleCalcCurrent = (item : any, index : any) => {
        const toSelect = `${item.label}_${index}`
        if (toSelect === selectedCurrent.select) return
        setSelectedCurrent(prev => ({...prev, select: toSelect}))
        dispatch(publishChartEvents({ event: 'SELECT_CONTROLS', payload: {id:[item.controlId], state:"intersect"}}))
        const value = BeamsNumber(item.value, 4)
        if (currentDifference.first === "") {
            setCurrentDifference(prev => ({...prev, first: ""+value}))
            setSelectedCurrent(prev => ({...prev, first: toSelect}))
        }
        else if (currentDifference.second === "") {
            const res = ""+BeamsNumber(+currentDifference.first - value, 4)
            setCurrentDifference(prev => ({...prev, second: ""+value, result: res}))
            setSelectedCurrent(prev => ({...prev, second: toSelect}))
        } else {
            setCurrentDifference({ first: ""+value, second: "", result: ""})
            setSelectedCurrent({ first: toSelect, second: "", select: toSelect})
        }
    }

    const setClearCurrentDialog = () => {
        setCurrentDifference({first : "", second : "", result: ""});
        setSelectedCurrent({first : "", second : "", select: ""});
    }

    const setClearTimeDialog = () => {
        setTimeDifference({first : "", second : "", result: ""});
        setSelectedTime({first : "", second : "", select: ""});
    }
    //#endregion methods

    return (
        <>
        <ThemeProvider theme={dialogTheme}>
            <Dialog open={true} maxWidth={"lg"} hideBackdrop
                PaperComponent={
                    DraggablePaper
                }
            >
                <DialogTitle 
                    onMouseOver={(e : any) => e.target.style.cursor = 'move'}
                    style={{ paddingTop: '2px', paddingBottom: '2px', background: theme.palette.primary.main, color: theme.palette.primary.contrastText }}
                >
                    電流差/時間差の表示
                </DialogTitle>
                <DialogContent style={{paddingTop: '20px'}}>
                    <div style={{ height: '100%', width: '100%', margin: "auto"}}>
                        <Stack>
                            <Stack  direction='row' spacing={3} justifyContent="flex-start">
                                <Typography>電流値一覧  </Typography>
                                <Typography>基準時間:{gapLine.stdTime}</Typography>
                            </Stack>
                            <TableContainer sx={{ maxHeight: 200, marginTop: 1 }} component={Paper} variant="outlined" >
                                <Table sx={{ minWidth: 500}} size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell width="20%" sx={{fontWeight: 'bold'}}>選択</TableCell>
                                            <TableCell width="35%" sx={{fontWeight: 'bold'}}>機器名</TableCell>
                                            <TableCell width="45%" sx={{fontWeight: 'bold'}}>電流値</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Object.values(groupBy(intersectionData.intersectTime, 'label')).map(( items: any ) => (
                                            items.map(( item: any, index: number ) => (
                                                <TableRow
                                                    selected={selectedTime.select === `${item.label}_${index}`}
                                                    onClick={() => handleCalcTime(item, index)}
                                                    hover
                                                    key={`${item.label}_${index}`}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
                                                >
                                                    <TableCell>{selectedTime.first === `${item.label}_${index}`? '選択1' : (selectedTime.second === `${item.label}_${index}`? "選択2" : "")}</TableCell>
                                                    <TableCell>{item.label}</TableCell>
                                                    <TableCell>{BeamsNumber(item.value, 4)}</TableCell>
                                                </TableRow>
                                            )
                                        )))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} gap={3} mt={3} width={'550px'}>
                                <TextField label={'選択1'} value={timeDifference.first} size="small" variant={'outlined'} InputProps={{readOnly: true}} InputLabelProps={{shrink: true, sx:{color:'#000000'}}}/>
                                -
                                <TextField label={'選択2'} value={timeDifference.second} size="small" variant={'outlined'} InputProps={{readOnly: true}} InputLabelProps={{shrink: true, sx:{color:'#000000'}}}/>
                                =
                                <TextField value={timeDifference.result} size="small" variant={'outlined'} InputProps={{readOnly: true}}/>

                                <Button style={{marginLeft:30, textTransform:'none'}} size="small" variant="outlined" onClick={setClearTimeDialog}>Clear</Button>
                            </Stack>
                        </Stack>      
                        <Stack sx={{marginTop: 2}}>
                            <Stack direction='row' spacing={3} justifyContent="flex-start">
                                <Typography>時間値一覧</Typography>
                                <Typography>基準電流:{gapLine.stdCurrent}</Typography>
                            </Stack>
                            <TableContainer sx={{ maxHeight: 200, marginTop: 1 }} component={Paper} variant="outlined">
                                <Table sx={{ minWidth: 500 }} size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell width="20%" sx={{fontWeight: 'bold'}}>選択</TableCell>
                                            <TableCell width="35%" sx={{fontWeight: 'bold'}}>機器名</TableCell>
                                            <TableCell width="45%" sx={{fontWeight: 'bold'}}>時間</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Object.values(groupBy(intersectionData.intersectCurrent, 'label')).map(( items: any ) => (
                                            items.map(( item: any, index: number ) => (
                                                <TableRow
                                                    selected={selectedCurrent.select === `${item.label}_${index}`}
                                                    onClick={() => handleCalcCurrent(item, index)}
                                                    hover
                                                    key={`${item.label}_${index}`}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
                                                >
                                                    <TableCell>{selectedCurrent.first === `${item.label}_${index}`? '選択1' : (selectedCurrent.second === `${item.label}_${index}`? "選択2" : "")}</TableCell>
                                                    <TableCell>{item.label}</TableCell>
                                                    <TableCell>{BeamsNumber(item.value, 4)}</TableCell>
                                                </TableRow>
                                            )
                                        )))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} gap={3} mt={3} width={'550px'}>
                                <TextField label={'選択1'} value={currentDifference.first} size="small" variant={'outlined'} InputProps={{readOnly: true}} InputLabelProps={{shrink: true, sx:{color:'#000000'}}}/>
                                -
                                <TextField label={'選択2'} value={currentDifference.second} size="small" variant={'outlined'} InputProps={{readOnly: true}} InputLabelProps={{shrink: true, sx:{color:'#000000'}}}/>
                                =
                                <TextField value={currentDifference.result} size="small" variant={'outlined'} InputProps={{readOnly: true}}/>
                                <Button style={{marginLeft:30, textTransform:'none'}} size="small" variant="outlined" onClick={setClearCurrentDialog}>Clear</Button>
                            </Stack>
                        </Stack>
                         
                    </div>
                   
                </DialogContent>
                <Stack direction='row' justifyContent="flex-end" marginTop={0.5} marginBottom={3} marginRight={3}>
                    <Button variant="contained" color="inherit" onClick={() => {setOpenDisplayDialog(false)}}>閉じる</Button>
                </Stack>   
            </Dialog>
        </ThemeProvider>
        </>
    )
}
export default DifferenceDisplayDialog;