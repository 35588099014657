import { Box, Button, Checkbox, Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Stack, Tab, Tabs} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import theme from "../../themes/globalStyles";
import DraggablePaper from "../common/DraggablePaper";
import { ControlModel, DatasetEditForm, PartDetailsModel } from "../../models/Index";
import { COLOR_LIST } from "../../models/Constants";
import FormatText from "./FormatTextDialog";
import SetupPart from "../graph/SetupPart";
import SetupRated from "../graph/SetupRated";
import ChangeTextFreeDialog from "./ChangeTextFreeDialog";
import { isNumber } from "mathjs";
import { MS_2E, MS_3WINDING, MS_CAPACITOR, MS_FUSE, MS_HVCB, MS_LVCB, MS_MOTOR, MS_SOURCE, MS_THERMAL, MS_THERMAL_CT, MS_TRANS1, MS_TRANS3, MS_TRANSSCOTT } from "../../models/ElementKind";

const colorList = COLOR_LIST.map(color => ({
    label: <div style={{ width: '100%', height: '24px', background: color }} />,
    value: color
}))

const dashList = [
    {
        label: <div style={{ margin: '11px 0', width: '100%', height: '2px', background: 'repeating-linear-gradient(90deg, #000 0 25px)' }} />,
        // value: 0
        value: "[]"
    },
    {
        label: <div style={{ margin: '11px 0', width: '100%', height: '2px', background: 'repeating-linear-gradient(90deg,#000 0 25px, #0000 0 30px)' }} />,
        // value: 1
        value: "[25,5]"
    },
    {
        label: <div style={{ margin: '11px 0', width: '100%', height: '2px', background: 'repeating-linear-gradient(90deg,#000 0 5px, #0000 0 10px)' }} />,
        // value: 2
        value: "[5,5]"
    },
    {
        label: <div style={{ margin: '11px 0', width: '100%', height: '2px', background: 'repeating-linear-gradient(90deg,#000 0 10px, #0000 0 15px, #000 0 20px, #0000 0 25px)' }} />,
        // value: 3
        value: "[10,5,5,5]"
    },
    {
        label: <div style={{ margin: '11px 0', width: '100%', height: '2px', background: 'repeating-linear-gradient(90deg,#000 0 10px, #0000 0 15px, #000 0 20px, #0000 0 25px, #000 0 30px, #0000 0 35px)' }} />,
        // value: 4
        value: "[10,5,5,5,5,5]"
    },
]

const widthList = [
    {
        label: <div style={{ margin: '11.5px 0', width: '100%', height: '1px', background: '#000' }} />,
        value: 1
    },
    {
        label: <div style={{ margin: '11px 0', width: '100%', height: '2px', background: '#000' }} />,
        value: 2
    },
    {
        label: <div style={{ margin: '10.5px 0', width: '100%', height: '3px', background: '#000' }} />,
        value: 3
    },
    {
        label: <div style={{ margin: '10px 0', width: '100%', height: '4px', background: '#000' }} />,
        value: 4
    },
    {
        label: <div style={{ margin: '9.5px 0', width: '100%', height: '5px', background: '#000' }} />,
        value: 5
    },
    {
        label: <div style={{ margin: '9px 0', width: '100%', height: '6px', background: '#000' }} />,
        value: 6
    },
    {
        label: <div style={{ margin: '8.5px 0', width: '100%', height: '7px', background: '#000' }} />,
        value: 7
    },
]

function a11yProps(index: number) {
    return {
        id: `tab-${index}`,
        "aria-controls": `tabpanel-${index}`,
    };
}

//Tabs------------------
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}


export type DatasetEditProps = {
    data: DatasetEditForm;
    element: ControlModel;
    onOK: (data: any) => void;
    onCancel: (event: any) => void;
    m_bModePM:boolean;
    modeViewOnly: boolean,
};

export const DatasetEditDialog = (props: DatasetEditProps) => {
    //#region fields
    const {data, element, onOK, onCancel, m_bModePM, modeViewOnly } = props;
    const [disableDispband, setDisableDispband] = useState((element.type === MS_SOURCE|| element.type === MS_2E|| element.type === MS_HVCB|| element.type === MS_LVCB|| element.type === MS_FUSE) && !isNumber(data.datasetId)? false : true)
    const checkShowTab = element.chart.find(x => x.relatedGraphId === data.relatedGraphId)

    const handleSetDialogTitle= (element: any, data: any) => {
        let title = ''
        if(isNumber(data.datasetId))
        {
            let userCurveId = element.chart.find((x:any)=> Number(x.controlId) === Number(data.controlId) && Number(x.relatedGraphId) === Number(data.relatedGraphId) && x.lineId == data.datasetId)?.data.find((x:any)=>x.user_curve_id || x.userCurveId);
            userCurveId = userCurveId.userCurveId ? userCurveId.userCurveId : userCurveId.user_curve_id
            let titleUser = userCurveId? element.properties.listUser.find((x:any)=> Number(x.userCurveId) === Number(userCurveId)) : undefined
            title = titleUser ? 'ユーザー曲線 [' + element.properties.refNo + '] - [' + titleUser.name + ']' : 'ユーザー曲線' ;
        }
        else
        {
            switch(element.type){
                case MS_SOURCE:
                    {
                        const typeName = element.properties.details.base.typeList.find((item:any) => item.value === element.properties.details.base.typeID)?.label || element.properties?.details.base.typeName
                        title = '動作時間特性 [' + element.properties.refNo + '] - [' + typeName + ']';
                        break;
                    }
                case MS_HVCB:
                    {
                        const typeName = element.properties.details.base.typeList.find((item:any) => item.value === element.properties.details.base.typeID)?.label || element.properties?.details.base.typeName
                        title = '動作時間特性 [' + element.properties.refNo + '] - [' + typeName + ']';
                        break;
                    }
                case MS_LVCB:
                    {
                        const typeName = element.properties.details.base.typeList.find((item:any) => item.value === element.properties.details.base.typeID)?.label || element.properties?.details.base.typeName
                        title = '動作時間特性 [' + element.properties.refNo + '] - [' + typeName + ']';
                        break;
                    }
                case MS_2E:
                    {
                        const typeName = element.properties.details.base.typeList.find((item:any) => item.value === element.properties.details.base.typeID)?.label || element.properties?.details.base.typeName
                        title = '動作時間特性 [' + element.properties.refNo + '] - [' + typeName + ']';
                        break;
                    }
                case MS_FUSE:
                    {
                        const typeName = element.properties.details.base.typeList.find((item:any) => item.value === element.properties.details.base.typeID)?.label || element.properties?.details.base.typeName
                        title = '動作時間特性 [' + element.properties.refNo + '] - [' + typeName + ']';
                        break;
                    }
                case MS_THERMAL:
                    {
                        const typeName = element.properties.details.base.typeList.find((item:any) => item.value === element.properties.details.base.typeID)?.label || element.properties?.details.base.typeName
                        title = '動作時間特性 [' + element.properties.refNo + '] - [' + typeName + ']';
                        break;
                    }
                case MS_THERMAL_CT:
                    {
                        const typeName = element.properties.details.base.typeList.find((item:any) => item.value === element.properties.details.base.typeID)?.label || element.properties?.details.base.typeName
                        title = '動作時間特性 [' + element.properties.refNo + '] - [' + typeName + ']';
                        break;
                    }
                case MS_TRANS3:
                    {
                        switch(data.datasetId)
                        {
                            case 'INRUSH':
                            {
                                title = '励磁突入電流 [' + element.properties.refNo +']';
                                break;
                            }
                            case 'INTENSITY':
                            {
                                title = '熱的機械的強度 [' + element.properties.refNo +']';
                                break;
                            }
                        }
                        break;
                    }
                case MS_3WINDING:
                    {
                        switch(data.datasetId)
                        {
                            case 'INRUSH_PART_2':
                            {
                                title = '励磁突入電流 [' + element.properties.refNo +'] - [二次側]';
                                break;
                            }
                            case 'INRUSH_PART_3':
                            {
                                title = '励磁突入電流 [' + element.properties.refNo +'] - [三次側]';
                                break;
                            }
                            case 'INTENSITY_PART_2':
                            {
                                title = '熱的機械的強度 [' + element.properties.refNo +'] - [二次側]';
                                break;
                            }
                            case 'INTENSITY_PART_3':
                            {
                                title = '熱的機械的強度 [' + element.properties.refNo +'] - [三次側]';
                                break;
                            }
                        }
                        break;
                    }
                case MS_TRANS1:
                    {
                        switch(data.datasetId)
                        {
                            case 'INRUSH_OUT_OUT':
                            {
                                title = '励磁突入電流 [' + element.properties.refNo +'] - [外線-外線]';
                                break;
                            }
                            case 'INRUSH_OUT_IN':
                            {
                                title = '励磁突入電流 [' + element.properties.refNo +'] - [外線-中性線]';
                                break;
                            }
                            case 'INTENSITY_OUT_OUT':
                            {
                                title = '熱的機械的強度 [' + element.properties.refNo +'] - [外線-外線]';
                                break;
                            }
                            case 'INTENSITY_OUT_IN':
                            {
                                title = '熱的機械的強度 [' + element.properties.refNo +'] - [外線-中性線]';
                                break;
                            }
                        }
                        break;
                    }
                case MS_TRANSSCOTT:
                    {
                        switch(data.datasetId)
                        {
                            case 'INRUSH_1':
                            {
                                title = '励磁突入電流 [' + element.properties.refNo +'] - [主座]';
                                break;
                            }
                            case 'INRUSH_2':
                            {
                                title = '励磁突入電流 [' + element.properties.refNo +'] - [T座]';
                                break;
                            }
                            case 'INTENSITY_1':
                            {
                                title = '熱的機械的強度 [' + element.properties.refNo +'] - [主座]';
                                break;
                            }
                            case 'INTENSITY_2':
                            {
                                title = '熱的機械的強度 [' + element.properties.refNo +'] - [T座]';
                                break;
                            }
                        }
                        break;
                    }
                case MS_MOTOR:
                    {
                        title = '始動突入電流 [' + element.properties.refNo + ']';
                        break;
                    }
                case MS_CAPACITOR:
                    {
                        title = '充電電流 [' + element.properties.refNo + ']';
                        break;
                    }
                default:
                    {
                        title = element.properties.refNo + '線種';
                        break;
                    }
            }
        }
        return title;
    }

    const [dialogTitle, setDialogTitle] = useState(handleSetDialogTitle(element, data));
    
    const details = checkShowTab?.lineType === "USER_CURVE" || checkShowTab?.lineId.toString().includes("CALC_POINT") || isNumber(data.datasetId) ? null : element.properties.details
    const base = checkShowTab?.lineType === "USER_CURVE" || checkShowTab?.lineId.toString().includes("CALC_POINT") || isNumber(data.datasetId)? null : details?.base
    const parts = checkShowTab?.lineType === "USER_CURVE" || checkShowTab?.lineId.toString().includes("CALC_POINT") || isNumber(data.datasetId)? null : details?.parts
    const delaySettings = checkShowTab?.lineType === "USER_CURVE" || checkShowTab?.lineId.toString().includes("CALC_POINT") || isNumber(data.datasetId)? null : details?.delaySettings
    // 初期値設定

    const initialValue : any = {
        ...data,
        relatedGraph: {
            ...data.relatedGraph,
            kind: JSON.stringify(data.relatedGraph.kind)
        },
        ...element.properties.details,
    }

    const { control, handleSubmit, formState: { errors }, setValue, getValues, watch } = useForm<any>({
        mode: "all",
        criteriaMode: "all",
        defaultValues: initialValue,
    })

    const [selectTab, setSelectTab] = useState(0);
    const [openTextFormatDialog, setOpenTextFormatDialog] = useState(false);
    const [openTextFreeDialog, setOpenTextFreeDialog] = useState(false);
    const [openTextFreeButton, setOpenTextFreeButton] = useState(getValues('relatedGraphText.dispTextFree'));
    const [breakingTime, setBreakingTime] = useState(getValues('delaySettings.breakingTime'));

    useEffect(() => {
        //TypeScript users 
        const subscription = watch((value, { name, type }) => {
            if(name?.split(".")[0] === "relatedGraph")
            {
                handleSubmit(() => handleEditSubmit({...value, updateId: 1}, type))()
            }
            if((name?.split(".")[0] === "relatedGraphText" && type !== undefined) || name === 'relatedGraphText' )
            {
                handleSubmit(() => handleEditSubmit({...value, updateId: 2}, type))()
            }
            if(name === "callWatchFuction") {
                
                handleSubmit(() => handleEditSubmit({...value, updateId: 0, isCallDB:false}, type))()
            }
            if(name === "isCallDB") {
                
                handleSubmit(() => handleEditSubmit({...value, updateId: 0, isCallDB:true}, type))()
            }
        })
        return () => subscription.unsubscribe();
    }, [handleSubmit, watch]);
    //#endregion fields

    //#region methods
    // Submitイベント
    const handleEditSubmit = (value: any, event: any) => {
        const request = createRequestData(value);
        onOK(request);
    }

    // FormデータからRequestデータを作成する
    const createRequestData = (formValue : any) => {   
        let request = {
            ...formValue,
            relatedGraph: {
                ...formValue.relatedGraph,
                kind: JSON.parse(formValue.relatedGraph.kind)
            }
        } as DatasetEditForm;
        return request;
    }

    const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
        setSelectTab(newValue);
    };

    const handleOKFormatTextForm = (data: any) => {
        setValue('relatedGraphText', 
            {...data, 
                dispLinkLine:getValues('relatedGraphText.dispLinkLine')??initialValue.relatedGraphText.dispLinkLine, 
                dispRefV2:getValues('relatedGraphText.dispRefV2')??initialValue.relatedGraphText.dispRefV2, 
                dispTextFree:getValues('relatedGraphText.dispTextFree')??initialValue.relatedGraphText.dispTextFree, 
                textFree:getValues('relatedGraphText.textFree')??initialValue.relatedGraphText.textFree, 
                textFixed:initialValue.relatedGraphText.textFixed,  
                pointTopLeftX: initialValue.relatedGraphText.pointTopLeftX, 
                pointTopLeftY: initialValue.relatedGraphText.pointTopLeftY, 
                pointOldX: 0, 
                pointOldY: 0 })

        setOpenTextFormatDialog(false) 
    }

    const handleOKTextFreeForm = (data: any) => {
        setValue('relatedGraphText', data)
        setOpenTextFreeDialog(false) 
    }

    const handleClickBreakingTime = (e:any) =>{
       setBreakingTime(!breakingTime)
       setValue("callWatchFuction",true);
    }

    const handleChangeBreakingTime = (e:any) =>{
        breakingTime && setValue(e.target.name,e.target.value)
        setValue("callWatchFuction",true);
    }

    //#endregion methods

    return (
        <>
            <Dialog 
                open={true} maxWidth={"sm"} 
                fullWidth hideBackdrop
                PaperComponent={
                    DraggablePaper
                }
            >
                <DialogTitle 
                    onMouseOver={(e : any) => e.target.style.cursor = 'move'}
                    style={{ paddingTop: '2px', paddingBottom: '2px', background: theme.palette.primary.main, color: theme.palette.primary.contrastText }}
                >
                    {dialogTitle}
                    {/* 
                        ユーザー曲線 ==> user curve
                        動作時間特性 ==> controls that call db
                        励磁突入電流 ==> controls do not call db
                    */}
                </DialogTitle>
                <DialogContent>
                    <div style={{ height: '100%', width: '100%', margin: "auto"}}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            {element.properties.details && details &&
                                <Tabs value={selectTab} onChange={handleChangeTab} aria-label="basic tabs example"
                                    variant="scrollable" 
                                    scrollButtons 
                                    allowScrollButtonsMobile 
                                    sx={{
                                        '& .MuiButtonBase-root.MuiTabScrollButton-root.Mui-disabled': {
                                            display: "none"
                                        }
                                    }}
                                >
                                    <Tab label="定格" {...a11yProps(0)} />
                                    {
                                        parts.map((value : PartDetailsModel, index : number) => (
                                            <Tab key={index + 1} label={value.tabName} {...a11yProps(index + 1)}  />
                                        ))
                                    }
                                    {
                                        delaySettings &&
                                            <Tab label="遅延設定" {...a11yProps((parts.length + 1) || 1)} />
                                    }
                                    <Tab label="線種" 
                                        {...a11yProps(delaySettings ? parts.length + 2 : parts.length + 1)} 
                                    />
                                    <Tab label="注釈" 
                                        {...a11yProps(delaySettings ? parts.length + 3 : parts.length + 2)}  
                                    />
                                </Tabs>

                            }
                            {!details &&
                                <Tabs value={selectTab} onChange={handleChangeTab} aria-label="basic tabs example"
                                    variant="scrollable" 
                                    scrollButtons 
                                    allowScrollButtonsMobile 
                                    sx={{
                                        '& .MuiButtonBase-root.MuiTabScrollButton-root.Mui-disabled': {
                                            display: "none"
                                        }
                                    }}
                                >
                                    <Tab label="線種" {...a11yProps(0)} />
                                    <Tab label="注釈" {...a11yProps(1)} />
                                </Tabs>
                            }
                        </Box>
                        <Box mt={3}>
                            <form>

                                {
                                    details && 
                                    <TabPanel value={selectTab} index={0}>
                                        <SetupRated 
                                            formSetting={{ control, setValue, getValues }}
                                            base={base}
                                            elementType={element.type}
                                            m_bModePM={m_bModePM}
                                            modeViewOnly={modeViewOnly}
                                        />
                                    </TabPanel>
                                }

                                {
                                    details && 
                                    parts.length > 0 &&
                                    parts.map((part : PartDetailsModel, index : number) => (
                                        <TabPanel key={index + 1} value={selectTab} index={index + 1}>
                                            <SetupPart 
                                                formSetting={{ control, setValue, getValues }}
                                                elementType={element.type}
                                                index={index}
                                                part={part}
                                                drawThreeCurve={!!details.drawThreeCurve}
                                                m_bModePM={m_bModePM}
                                                modeViewOnly={modeViewOnly}
                                            />
                                        </TabPanel>
                                    ))
                                }

                                {/* tab: delay setting */}
                                {
                                details && 
                                delaySettings &&
                                    <TabPanel value={selectTab} index={(parts?.length + 1) || 1}>
                                        <Grid container mt={2}>
                                            <Stack>
                                                <FormControlLabel
                                                    control={
                                                        <Controller
                                                            name="delaySettings.breakingTime"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <Checkbox
                                                                    {...field}
                                                                    checked={field.value}
                                                                    onClick={handleClickBreakingTime}
                                                                    disabled={m_bModePM || modeViewOnly}
                                                                />
                                                            )}
                                                        />
                                                    }
                                                    label="遮断時間(サイクル)"
                                                />
                                            </Stack>
                                            <Stack pl={3}>
                                                <FormControl fullWidth size="small">
                                                    <Controller
                                                        name="delaySettings.breakingTimeHzID"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                disabled={!getValues(`delaySettings.breakingTime`) || m_bModePM || modeViewOnly}
                                                                onChange={handleChangeBreakingTime}
                                                            >
                                                                {delaySettings.breakingTimeHzList?.map((item : any) => (
                                                                    <MenuItem key={item.value} value={item.value}>
                                                                        {item.label}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        )}
                                                    />
                                                </FormControl>
                                            </Stack>
                                        </Grid>
                                    </TabPanel>
                                }

                                {/* tab related graph */}
                                <TabPanel value={selectTab} index={details ? delaySettings ? parts.length + 2 : parts.length + 1 : 0}>
                                    <Stack spacing={3}>
                                        <Controller
                                            name='relatedGraph.dispBand'
                                            control={control}
                                            render={({ field }) => (                          
                                                <FormControlLabel 
                                                {...field}
                                                disabled={disableDispband || modeViewOnly} 
                                                control={<Checkbox/>} 
                                                checked={field.value} 
                                                label="バンド表示"
                                            />
                                            )}
                                        />
                                        <FormControl fullWidth size="small">
                                            <InputLabel>色:</InputLabel>
                                            <Controller
                                                name="relatedGraph.colorNormal"
                                                control={control}
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        label="色:"
                                                        disabled={m_bModePM || modeViewOnly}
                                                    >
                                                        {colorList.map((colorItem : any) =>
                                                            <MenuItem key={colorItem.value} value={colorItem.value}>{colorItem.label}</MenuItem>
                                                        )}
                                                    </Select>
                                                )}
                                            />
                                        </FormControl>
                                        <FormControl fullWidth size="small">
                                            <InputLabel>太さ:</InputLabel>
                                            <Controller
                                                name="relatedGraph.width"
                                                control={control}
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        label="太さ:"
                                                        disabled={m_bModePM || modeViewOnly}
                                                    >
                                                        {widthList.map((widthItem : any) =>
                                                            <MenuItem key={widthItem.value} value={widthItem.value}>{widthItem.label}</MenuItem>
                                                        )}
                                                    </Select>
                                                )}
                                            />
                                        </FormControl>
                                        <FormControl fullWidth size="small">
                                            <InputLabel>線種:</InputLabel>
                                            <Controller
                                                name="relatedGraph.kind"
                                                control={control}
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        label="線種:"
                                                        disabled={m_bModePM || modeViewOnly}
                                                    >
                                                        {dashList.map((dashItem : any) =>
                                                            <MenuItem key={dashItem.value} value={dashItem.value}>{dashItem.label}</MenuItem>
                                                        )}
                                                    </Select>
                                                )}
                                            />
                                        </FormControl>
                                    </Stack>
                                </TabPanel>
                                {/* tab related graph text */}
                                <TabPanel value={selectTab} index={details ? delaySettings ? parts.length + 3 : parts.length + 2 : 1}>
                                    <Stack direction={'row'} flexWrap={"wrap"}>
                                        <Stack flex={1}>
                                            <Stack flex={1} direction='row' spacing={20}>
                                                <Controller
                                                    name='relatedGraphText.dispRefV2'
                                                    control={control}
                                                    render={({ field }) => (                          
                                                        <FormControlLabel 
                                                        {...field}
                                                        control={<Checkbox/>} 
                                                        checked={field.value} 
                                                        label="注釈を表示"
                                                        disabled={m_bModePM || modeViewOnly}
                                                    />
                                                    )}
                                                />
                                                <Stack>
                                                    <Button disabled={m_bModePM} size="small" variant="outlined" onClick={() => setOpenTextFormatDialog(true)}>フォント</Button>
                                                </Stack>
                                            </Stack>
                                          
                                            <Stack flex={1} direction='row' spacing={1}>
                                                <Controller
                                                    name='relatedGraphText.dispLinkLine'
                                                    control={control}
                                                    render={({ field }) => (                          
                                                        <FormControlLabel 
                                                        {...field}
                                                        control={<Checkbox/>} 
                                                        checked={field.value} 
                                                        disabled={m_bModePM || modeViewOnly || (getValues('relatedGraphText.dispRefV2') === false? true : false)}
                                                        label="曲線と注釈のリンク線を表示"
                                                    />
                                                    )}
                                                />
                                            </Stack>
                                             
                                            <Stack flex={1} direction='row' spacing={12}>
                                                <Controller
                                                    name='relatedGraphText.dispTextFree'
                                                    control={control}
                                                    render={({ field }) => (                          
                                                        <FormControlLabel 
                                                        {...field}
                                                        control={<Checkbox
                                                            onClick = {()=>setOpenTextFreeButton(!openTextFreeButton)}
                                                        />} 
                                                        checked={field.value} 
                                                        label="任意の注釈文を表示"
                                                        disabled={m_bModePM || modeViewOnly}
                                                    />
                                                    )}
                                                />
                                                <Stack >
                                                    <Button disabled={!m_bModePM? !openTextFreeButton : m_bModePM} size="small" variant="outlined" onClick={() => setOpenTextFreeDialog(true)}>注釈文...</Button>
                                                </Stack>
                                            </Stack>
                                        </Stack>
                                      
                                    </Stack>
                                 
                                </TabPanel>
                                <Stack direction='row' justifyContent="flex-end" spacing={2} sx={{ mt: 2 }}>
                                        <Button onClick={onCancel} color="inherit" variant="contained" >閉じる</Button>
                                </Stack>
                            </form>
                        </Box>
                    </div>
                </DialogContent>
            </Dialog>
            {
                openTextFormatDialog &&
                    <FormatText 
                        data={{
                            fontSize: getValues("relatedGraphText.fontSize"), 
                            charSet : getValues("relatedGraphText.charSet"), 
                            colorFont : getValues("relatedGraphText.colorFont"), 
                            faceName : getValues("relatedGraphText.faceName"), 
                            italic: getValues("relatedGraphText.italic"), 
                            strikeOut : getValues("relatedGraphText.strikeOut"), 
                            underline: getValues("relatedGraphText.underline"), 
                            weight: getValues("relatedGraphText.weight")
                        }} 
                        modeViewOnly={modeViewOnly}
                        onOK={handleOKFormatTextForm} 
                        onCancel={() => setOpenTextFormatDialog(false)} />
            }
            {
                openTextFreeDialog &&
                    <ChangeTextFreeDialog data = {{
                    textFixed: getValues("relatedGraphText.textFixed"),
                    textFree : getValues("relatedGraphText.textFree"),
                    fontSize: getValues("relatedGraphText.fontSize"),
                    charSet : getValues("relatedGraphText.charSet"), 
                    colorFont : getValues("relatedGraphText.colorFont"), 
                    faceName : getValues("relatedGraphText.faceName"), 
                    italic: getValues("relatedGraphText.italic"), 
                    strikeOut : getValues("relatedGraphText.strikeOut"), 
                    underline: getValues("relatedGraphText.underline"), 
                    weight: getValues("relatedGraphText.weight"),
                    dispLinkLine: getValues("relatedGraphText.dispLinkLine"),
                    dispRefV2: getValues("relatedGraphText.dispRefV2"),
                    dispTextFree: getValues("relatedGraphText.dispTextFree"),
                    kindLinkLine: getValues("relatedGraphText.kindLinkLine"),
                    pointOldX: getValues("relatedGraphText.pointOldX"),
                    pointOldY: getValues("relatedGraphText.pointOldY"),
                    minPointX: getValues("relatedGraphText.minPointX"),
                    minPointY: getValues("relatedGraphText.minPointY"),
                    pointTopLeftX: getValues("relatedGraphText.pointTopLeftX"),
                    pointTopLeftY: getValues("relatedGraphText.pointTopLeftY"),
                    }} 
                    modeViewOnly={modeViewOnly}
                    onOK={handleOKTextFreeForm} 
                    onCancel={() => setOpenTextFreeDialog(false)}
                />
            }
        </>
    )
}
export default DatasetEditDialog;