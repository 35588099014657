
export const complexAddComplex = (first:any, second:any) => {
    return {
        re: first.re + second.re,
        im: first.im + second.im
    }
}

export const complexSubtractComplex = (first:any, second:any) => {
    return {
        re: first.re - second.re,
        im: first.im - second.im
    }
}

export const complexMultiNumber = (first:number, second: any) => {
    return {
        re: first * second.re,
        im: first * second.im
    }
}

export const complexMultiComplex = (first:any, second: any) => {
    return {
        re: first.re * second.re - first.im * second.im,
        im: first.im * second.re + first.re * second.im
    }
}

export const complexDevisionComplex = (first:any, second:any) => {
    let dTemporary = second.re * second.re + second.im * second.im;
    return {
        re: (first.re * second.re + first.im * second.im) / dTemporary,
        im: (first.im * second.re - first.re * second.im) / dTemporary
    }
}

export const complexAbs = (srcComplex: any) => {
    return Math.sqrt(srcComplex.re * srcComplex.re + srcComplex.im * srcComplex.im);
}