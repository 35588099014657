import { Close } from '@mui/icons-material';
import { Tab, Tabs } from '@mui/material';
import React, { memo, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { TabModel, UndoModel } from '../../models/Index';
import { deleteChartTabByTabId, saveCurrentIdChartTab, undo } from '../../store/Actions';

//#region Props
export type ChartTabProps = { onClose: () => void;} & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
//#endregion

const ChartTab = memo((props : ChartTabProps) => {
    //#region Fields
    const { currentTabId, graphData, graphDataDB, undoData, setUndoData, onClose } = props;
    const { updateIdTabChart, deleteChartTabByTabId } = props;

    const [tabIndex, setTabIndex] = useState(0);
    const [tabList, setTabList] = useState<TabModel[]>([]);
    let evDeleteTab = false
    //#endregion

    //#region useEffect
    useEffect(() => {
        if(currentTabId > 0){
            if(graphDataDB && graphDataDB.length > 0)
            {
                if(graphDataDB.length + 1 >  tabList.length)
                {
                     //TODO add graphDataDB
                }
            }
            if(graphData && graphData.length > 0)
            {
                let newTabList: any[] =[]
                graphData.map((data:any) => {
                    if(tabList.filter(r=>r.id === data.graphNo).length === 0 && data.graphNo === currentTabId)
                    {
                        newTabList = [...tabList, {id: currentTabId, label: data.graphName }];
                        setTabList(() =>[...newTabList]);
                        setTabIndex(newTabList.length - 1);
                    }
                })
                if(newTabList.length === 0){
                    if(tabList.filter(r=>r.id === currentTabId).length === 0){
                        newTabList = [...tabList, {id: currentTabId, label: "保護協調図" + currentTabId }];
                        setTabList(() =>[...newTabList]);
                        setTabIndex(newTabList.length - 1);
                    }
                }
            }
            else if(tabList.filter(r=>r.id === currentTabId).length === 0){
                const newTabList = [...tabList, {id: currentTabId, label: "保護協調図" + currentTabId }];
                setTabList(() =>[...newTabList]);
                setTabIndex(newTabList.length - 1);
            }
        }
        else{
            setTabList(() => [...[]]);
        }
    }, [currentTabId]);

    useEffect(() => {
       if(graphData !== undefined && graphData.length > 0 && tabList.length > 0)
       {
            graphData.map((data:any) => {
                if(data.graphNo === currentTabId)
                {
                    tabList[tabIndex].label = data.graphName;
                }
            })
            setTabList(tabList)
       }
    }, [graphData]);
    //#endregion

    //#region Method
    const handleTabChange = (event: React.SyntheticEvent, value: number) => {
        if(tabList[value] && !evDeleteTab){
            setUndoData({type:"CHART_TAB_CHANGE",dataUndo: tabIndex} as UndoModel)
            setTabIndex(value);
            updateIdTabChart(tabList[value].id)
            evDeleteTab = false
        }
    };

    const handleTabClose = (index: number) => {
        setUndoData({type:"CHART_TAB_CLOSE",dataUndo: tabList[index]} as UndoModel)
        evDeleteTab = false
        if (tabList.length === 1) {
             deleteChartTabByTabId(tabList[0].id)
             setTabList(() => [...[]]);
             //updateIdTabChart(0)
             onClose()
        }
        else{
            evDeleteTab = false

            let checkTabCurrent = false;
            if(index === tabIndex){
                checkTabCurrent = true;
            }

            const newTabList = [...tabList];
            newTabList.splice(index, 1);
            setTabList(() => [...newTabList]);

            if(!checkTabCurrent){
                if(index < tabIndex){
                    setTabIndex(tabIndex - 1);
                }
                evDeleteTab = true
            }else{
                if(newTabList[index]){
                    updateIdTabChart(newTabList[index].id)
                }else{
                    updateIdTabChart(newTabList[newTabList.length-1].id)
                    setTabIndex(newTabList.findIndex(r=>r.id === newTabList[newTabList.length-1].id));
                }
            }
            deleteChartTabByTabId(tabList[index].id);
        }
    };

    const handleUndo = () => {
        if(undoData.type !== null && undoData.dataUndo !== null){
            switch(undoData.type){
                case "CHART_TAB_CHANGE":
                    {
                        setTabIndex(undoData.dataUndo);
                        updateIdTabChart(tabList[undoData.dataUndo].id)
                        evDeleteTab = false
                    }
                    break;
                case "CHART_TAB_CLOSE":
                    {
                        //TODO
                    }
                    break;
            }
        }
    }

    const handleKeyDown = (event:any) => {
        event.preventDefault();
        switch (event.key) {
            case 'z':
                event.preventDefault();
                event.ctrlKey && handleUndo();
                break;
        }
        
    }
    //#endregion
    
    return (
        <Tabs value={tabIndex} onChange={handleTabChange}
            onKeyDown={handleKeyDown}
            variant="scrollable" 
            scrollButtons 
            allowScrollButtonsMobile 
            sx={{
                minHeight: 'unset',
                height: '30px', 
                borderBottom: '1px solid gray',
                '& .MuiButtonBase-root.MuiTabScrollButton-root.Mui-disabled': {
                    display: "none"
                }
            }}
        >
            {tabList.map((tab, index) => (
                <Tab
                    key={tab.id}
                    label={tab.label}
                    id={`tab-${tab.id}`}
                    icon={<Close sx={{ fontSize: "15px" }} onClick={() => handleTabClose(index)}/>}
                    iconPosition="end"
                    style={{height: '30px', padding: '5px 15px', borderRight: '1px solid gray', minHeight: 'unset'}}
                />
            ))}
        </Tabs>
    );
});

const mapStateToProps = (state: any) => {
    return {
        currentTabId: state.app.diagram.currentIDChartTab,
        graphData :state.app.diagram.graphData["GRAPH_NAME"],
        graphDataDB :state.app.diagram.graphData["GRAPH_NAME_DB"],
        undoData: state.app.diagram.undoData,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        updateIdTabChart: (tabId: number) => dispatch(saveCurrentIdChartTab(tabId)),
        deleteChartTabByTabId: (tabId: number) => dispatch(deleteChartTabByTabId(tabId)),
        setUndoData: (data:UndoModel) => dispatch(undo(data)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ChartTab);
