import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, Button, Card, CardContent, CircularProgress, IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { Auth } from 'aws-amplify';
import { useState } from 'react';
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { store } from "../../";
import { ApplicationState, actionCreators } from "../../store";
import ToastContainer from "../common/ToastContainer";

// -------------
// Props
export type ResetPasswordProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export const ResetPassword = (props: ResetPasswordProps) => {
  const { resetPassUserName, onExitResetPassword } = props;

  const [userName, setUserName] = useState(resetPassUserName);
  const [codeVerify, setCodeVerify] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordAgain, setNewPasswordAgain] = useState("");
  const [loadingFlag, setLoadingFlag] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [step, setStep] = useState(1);

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const showPasswordIcon = (visible: boolean) => {
    if (visible) {
      return <Visibility />
    }
    return <VisibilityOff />;
  };
  let navigate = useNavigate();

  const handleChangeUserName = (e: any) => setUserName(e.target.value);
  const handleChangeCode = (e: any) => setCodeVerify(e.target.value);
  const handleChangeNewPassword = (e: any) => setNewPassword(e.target.value);
  const handleChangeNewPasswordAgain = (e: any) => setNewPasswordAgain(e.target.value);

  const handleCancelClick = () => {
    onExitResetPassword();
    navigate('/login');
  }
  const handleResetClick = () => {
    if (userName === '') {
      store.dispatch(actionCreators.showMessage({
        type: "error",
        title: "パスワード再設定",
        body: "メールアドレスを入力してください。",
      }));
      return;
    }
    forgotPassword(userName);
  };
  const handleChangePasswordClick = () => {
    if (codeVerify === '') {
      store.dispatch(actionCreators.showMessage({
        type: "error",
        title: "パスワード再設定",
        body: "認証コードを入力してください。",
      }));
      return;
    }
    if (newPassword === '') {
      store.dispatch(actionCreators.showMessage({
        type: "error",
        title: "パスワード再設定",
        body: "新しいパスワードを入力してください。",
      }));
      return;
    }
    if (newPassword !== newPasswordAgain) {
      store.dispatch(actionCreators.showMessage({
        type: "error",
        title: "パスワード再設定",
        body: "新しいパスワード（確認用）が一致しません。",
      }));
      return;
    }
    forgotPasswordSubmit(userName, codeVerify, newPassword);
  };

  // Send confirmation code to user's email or phone number
  const forgotPassword = async (username: string) => {
    try {
      setLoadingFlag(true);
      await Auth.forgotPassword(username);
      setStep(2);
    } catch (err) {
      store.dispatch(actionCreators.showMessage({
        type: "error",
        title: "パスワード再設定",
        body: "パスワード再設定に失敗しました。",
      }));
    } finally {
      setLoadingFlag(false)
    }
  }

  // Collect confirmation code and new password
  const forgotPasswordSubmit = async (
    username: string,
    code: string,
    newPassword: string
  ) => {
    setLoadingFlag(true);
    try {
      const data = await Auth.forgotPasswordSubmit(username, code, newPassword);
      if (data === "SUCCESS") {
        store.dispatch(actionCreators.showMessage({
          type: "info",
          title: "パスワード再設定",
          body: "パスワードを再設定しました。",
        }));
        onExitResetPassword();
        // ログイン画面に遷移
        navigate('/login');
      }
      else {
        store.dispatch(actionCreators.showMessage({
          type: "error",
          title: "パスワード再設定",
          body: "パスワード再設定に失敗しました。",
        }));
      }
    } catch (err) {
      store.dispatch(actionCreators.showMessage({
        type: "error",
        title: "パスワード再設定",
        body: "パスワード再設定に失敗しました。",
      }));
    } finally {
      setLoadingFlag(false)
    }
  };

  const step1Content = () => {
    return (
      <CardContent>
        <Typography variant="h5" mb={1}>パスワード再設定</Typography>
        <Typography variant="body2" mt={3}>以下に登録済みのメールアドレスを入力してください。パスワードを再設定するためのメッセージが送信されます。</Typography>
        <Stack mt="10px" mb="3px" width="100%" spacing={3} >
          <TextField
            value={userName}
            onChange={handleChangeUserName}
            fullWidth
            type="text"
            size="small"
            label={<Typography variant='body2'>メールアドレス</Typography>}
          />
        </Stack>
        <Box height="54px" />
        <Stack direction='row' justifyContent="flex-end" spacing={1} >
          <Button variant="contained" onClick={handleResetClick}>次へ</Button>
          <Button color="inherit" variant="contained" onClick={handleCancelClick}>キャンセル</Button>
        </Stack>
      </CardContent>
    );
  };

  const step2Content = () => {
    return (
      <CardContent>
        <Typography variant="h5" mb={1}>パスワード再設定</Typography>
        <Typography variant="body2" mt={3}>パスワードを再設定するには、登録されているメールアドレスまたは電話番号に送信された認証コードを入力してください。</Typography>
        <Stack mt="10px" mb="3px" width="100%" spacing={3} >
          <TextField
            value={codeVerify}
            onChange={handleChangeCode}
            fullWidth
            type="text"
            size="small"
            label={<Typography variant='body2'>認証コード</Typography>}
          />
          <TextField
            fullWidth
            value={newPassword}
            type={showPassword ? 'text' : 'password'}
            label={<Typography variant='body2'>新しいパスワード</Typography>}
            size="small"
            onChange={handleChangeNewPassword}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    {showPasswordIcon(showPassword)}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <TextField
            fullWidth
            value={newPasswordAgain}
            type={showPassword ? 'text' : 'password'}
            label={<Typography variant='body2'>新しいパスワード（確認用）</Typography>}
            size="small"
            onChange={handleChangeNewPasswordAgain}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    {showPasswordIcon(showPassword)}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Stack>

        <Typography variant="body2" mt={3}>※パスワードは8文字以上で設定してください。</Typography>
        <Typography variant="body2">※パスワードには、半角英字の大文字・小文字と半角数字を含めてください。</Typography>
        <Box height="54px" />
        <Stack direction='row' justifyContent="flex-end" spacing={1} >
          <Button variant="contained" onClick={handleChangePasswordClick}>再設定</Button>
          <Button color="inherit" variant="contained" onClick={handleCancelClick}>キャンセル</Button>
        </Stack>
      </CardContent>
    );
  };

  return (
    <>
      {loadingFlag && (
        <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1500, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
          <CircularProgress />
        </div>
      )}

      <Stack style={{ height: '100vh', background: '#F9FAFB' }} justifyContent={'flex-start'}>
        <Stack justifyContent="center" alignItems="center" mt={2}>
          <Card sx={{ width: 400, boxShadow: 5, marginTop: 3 }}>
            {step === 1 ? step1Content() : step2Content()}
          </Card>
        </Stack>
        <Stack mt={2} alignItems={"center"}>
          <Typography>{process.env.REACT_APP_VERSION}</Typography>
        </Stack>
      </Stack >
      <ToastContainer />
    </>
  )
}
const mapStateToProps = (state: ApplicationState) => ({
  userRole: state.app.user?.userRole,
  resetPassUserName: state.app.resetPassUserName,
})

const mapDispatchToProps = {
  onExitResetPassword: () => actionCreators.exitResetPassword(),
};
export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword as any);