import { Button, CircularProgress, Grid, Stack, TextField, Typography } from "@mui/material";
import { PageData } from "../../../models/Index";
import { ApplicationState, actionCreators } from "../../../store";
import React, { useRef } from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import FetchHelper from "../../FetchHelper";
import { useNavigate } from "react-router-dom";
import { Path as RoutePath } from "../../../path";
import EquipMotoreffGrid from "./EquipMotoreffGrid";
import AlertDialog from "../../common/AlertDialog";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const GET_ALL_EQUIP_MOTOREFF = "GET_ALL_EQUIP_MOTOREFF";
const DELETE_EQUIP_MOTOREFF = "DELETE_EQUIP_MOTOREFF";

// 検索フォーム
export interface SearchItemForm {
    "target": string;
    "limit": number;
    "page": number;
}

// 初期値設定
const initialValue: SearchItemForm = {
    target: '',
    limit: 50,
    page: 1,
}

const initialPageData: PageData = {
    rowFrom: '0',
    rowTo: '0',
    totalRow: '0',
    currPage: '0',
    totalPage: '0',
};

export type EquipBusductListProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
// Component
export const EquipMotoreffList = (props: EquipBusductListProps) => {
    const { onSearch, 
        onDelete, 
        userId,
        onError,
        onDeleteSuccess,
        onDeleteError
     } = props;
    const [title, setTitle] = React.useState("");
    const [message, setMessage] = React.useState("");
    const [loadingFlag, setLoadingFlag] = React.useState(false);
    const [keyword, setKeyword] = React.useState("");
    const [colState, setColState] = React.useState<any>();
    const [pageData, setPageData] = React.useState(initialPageData);
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState<boolean>(false);
    const [idDelete, setIdDelete] = React.useState<string>("");
    let navigate = useNavigate();
    const gridRef = useRef() as any;

    const { setValue, getValues } = useForm<SearchItemForm>({
        criteriaMode: "all",
        defaultValues: initialValue,
    });

    // 検索実行
    const handleSearchSubmit = (value: SearchItemForm) => {
        setLoadingFlag(true);
        const params = new Map<string, any>();
        if (value.limit) params.set("limit", value.limit);
        params.set("page", value.page);
        setLoadingFlag(true);
        const object = {
            requestPrms: {
                limit: params.get("limit"),
                page: params.get("page")
            },
            userId: userId,
        }
        onSearch(object);
    }

    React.useEffect(() => {
        handleSearchSubmit(getValues())
    }, [])

    const handleSuccess = (data: any) => {
        setLoadingFlag(false);
        if (data && data.resultCode == 0) {
            if (data.results) {
                if (gridRef && gridRef.current) {
                    gridRef.current.setRowData(data.results);
                }
                const totalCount = data.totalCount;
                const pageSize = getValues('limit');
                const page = Number(data.page);
                const totalPage = Math.ceil(totalCount / pageSize);
                const rowFrom = (pageSize * page - pageSize) + 1;
                const rowTo = (pageSize * page) > totalCount ? totalCount : (pageSize * page);
                const updateData: PageData = {
                    rowFrom: rowFrom.toLocaleString(),
                    rowTo: rowTo.toLocaleString(),
                    totalRow: totalCount.toLocaleString(),
                    currPage: page.toLocaleString(),
                    totalPage: totalPage.toLocaleString(),
                };
                setPageData(updateData);
            }
        }
        else {
            if (gridRef && gridRef.current) {
                gridRef.current.setRowData([]);
            }
        }
        setLoadingFlag(false);
    }

    const handleError = (success: any, data: any, error: any) => {
        setLoadingFlag(false);
    }

    // ページ数変更時にAPI検索する
    const handleChangePageData = (name: any, value: any) => {
        // 値変更
        setValue(name, value);
        if (name === 'limit') {
            setValue("page", 1);
        }
        handleSearchSubmit(getValues());
    };

    //削除の処理
    const handleShowDetailPage = (id: any) => {
        navigate(RoutePath.EquipMotoreffEdit + `/edit/${id}`)
    }

    //削除の処理
    const handleDelete = (data: any) => {
        setIdDelete(data);
        const msg = "ID: " + data + "を削除します。";
        setTitle("電動機(FF)情報削除");
        setMessage(msg);
        setOpenDeleteDialog(true);
    }

    // 削除確認ダイアログでOKボタンのイベントを受け取って、APIに送信する
    const handleDeleteOK = () => {
        setLoadingFlag(true);
        setOpenDeleteDialog(false);
        onDelete({
            "userId": userId,
            "requestPrms": {
                "id": idDelete
            }
        });
        setIdDelete("");
    }

    // 削除確認ダイアログでCancelボタンのイベントを受け取って、ダイアログを無効にする
    const handleDeleteCancel = () => {
        setIdDelete("");
        setOpenDeleteDialog(false);
    }
    const handleSuccessDelete = (data: any) => {
        console.log('>>> handleSuccessDelete');
        onDeleteSuccess();
        handleSearchSubmit(getValues());
    };

    // 検索エラー時
    const handleErrorDelete = (success: boolean, data: any, error: any) => {
        console.log('>>> handleErrorDelete');
        onDeleteError()
    };

    const handleAdd = () => {
        navigate(RoutePath.EquipMotoreffEdit + `/add`)
    }


    return (
        <>
            {loadingFlag && (
                <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1500, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                    <CircularProgress />
                </div>
            )}
            {openDeleteDialog ?
                <AlertDialog title={title} message={message} isOpen={true} onOK={handleDeleteOK} onCancel={handleDeleteCancel} /> :
                <div />
            }
            <div style={{ height: '100%', width: '100%', marginTop: '10px' }}>
                <Stack direction="row" justifyContent="flex-start" alignItems="center" sx={{}} style={{ margin: '18px', color: 'MenuText' }}>
                    {/* FIXME: */}
                    <Typography variant="h4">
                    電動機(FF)
                    </Typography>
                </Stack>
                <div style={{ justifyContent: 'flex-end', display: 'flex' }}>
                    <Stack direction='row-reverse' mb={1}>
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={handleAdd}
                            startIcon={<AddCircleOutlineIcon />}>
                            電動機(FF)追加
                        </Button>
                    </Stack>
                </div>
                <div style={{ marginBottom: "10px" }}>
                    <Stack direction="row">
                        <div style={{ marginInlineStart: 'auto' }}>
                            <Grid container justifyContent="flex-start" alignItems={"center"} direction="row" spacing={1} >
                                <Grid item>
                                    <TextField
                                        fullWidth
                                        autoComplete="searchStr"
                                        name="keyword"
                                        type="text"
                                        label="フィルター"
                                        size="small"
                                        value={keyword}
                                        onChange={e => setKeyword(e.target.value)}
                                    />
                                </Grid>
                                <Grid item>
                                    <Button variant="outlined" onClick={e => setKeyword("")}>クリア</Button>
                                </Grid>
                            </Grid>
                        </div>
                    </Stack>
                </div>
                <div>
                    <EquipMotoreffGrid
                        ref={gridRef}
                        colState={colState}
                        pageSize={getValues('limit')}
                        pageData={pageData}
                        onChangeSearchCondition={handleChangePageData}
                        keyword={keyword}
                        onShowDetails={handleShowDetailPage}
                        onDelete={handleDelete}
                    />
                </div>
            </div>
            <FetchHelper fetchId={GET_ALL_EQUIP_MOTOREFF} onComplete={(success, data, error) =>
                success ? handleSuccess(data) : handleError(success, data, error)} />
            <FetchHelper fetchId={DELETE_EQUIP_MOTOREFF} onComplete={(success, data, error) =>
                success ? handleSuccessDelete(data) : handleErrorDelete(success, data, error)} />

        </>
    )
}

const mapStateToProps = (state: ApplicationState) => ({
    userId: state.app.user?.userId,
});

const mapDispatchToProps = (dispatch: any) => {
    return {
        onSearch: (data: any) => dispatch(actionCreators.fetch(GET_ALL_EQUIP_MOTOREFF, `/master/equip_motoreff/gets`, "POST", data, false, true)),
        onDelete: (data: any) => dispatch(actionCreators.fetch(DELETE_EQUIP_MOTOREFF, `/master/equip_motoreff/delete`, "POST", data, false, true)),
        onError: (success: boolean, data: any, error: any) =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "検索",
                body: "検索できません。" + error,
            })),
        onSuccess: () =>
            dispatch(actionCreators.showMessage({
                type: "info",
                title: "電動機(FF)更新",
                body: "電動機(FF)を更新しました。",
            })),
        onDeleteSuccess: () =>
            dispatch(actionCreators.showMessage({
                type: "info",
                title: "電動機(FF)削除",
                body: "電動機(FF)を削除しました。",
            })),
        onDeleteError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "電動機(FF)削除",
                body: "電動機(FF)を削除できませんでした。",
            })),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EquipMotoreffList);