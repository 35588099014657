import { CdPoint, ControlModel, GraphLineData, MsMotorModel, Point } from "../../../models/Index"

const doDrawCurveMotor= (props:MsMotorModel,m_bSPhae:boolean) =>{
    let m_bUseBaseLoad = props.useBaseLoad;
    let m_bDispBaseLoad = props.dispBaseLoad;
    let graphLineData : GraphLineData = { lines: [] }
    const m_listDrawInrush = [] as CdPoint[]
    const inrushPoints = [] as Point[]
    if(doMakeCurveInrushMotor(m_listDrawInrush,props,m_bSPhae)){
        m_listDrawInrush.forEach(point => inrushPoints.push({x: point.m_dx, y:point.m_dy} as Point))
        graphLineData.lines.push({
            points: inrushPoints,
            id: 'INRUSH'
        });
        
    }
    const m_listDrawInrush2 = [] as CdPoint[]
    const inrushPoints2 = [] as Point[]
    if(m_bUseBaseLoad && m_bDispBaseLoad){
        if(doMakeCurveInrushMotor2(m_listDrawInrush2,props,m_bSPhae)){
            m_listDrawInrush2.forEach(point => inrushPoints2.push({x: point.m_dx, y:point.m_dy} as Point))
            graphLineData.lines.push({
                points: inrushPoints2,
                id: 'INRUSH_2'
            });
        }
    }
    return graphLineData

}
const doGetValueFullCurrent = (props:MsMotorModel,m_bSPhae:boolean) => {
        let m_lVoltage :number = props.voltageID;
        let m_dCapacity :number = props.capacity;
        let m_dLoafFactor :number = props.loadFactor;
        let m_bUseEfficiency : boolean = props.useEfficiency;
        let m_dEfficiency : number = props.efficiency;
        let m_dPowerFactor : number = props.powerFactor;
        // let m_bSPhae :boolean = false;// the top hasn't taken yet
        let dFullCurrent = 0;
    if(m_lVoltage <= 0){
        dFullCurrent = 0;

    }else{
        let dCapacity = m_dCapacity * 1000 * m_dLoafFactor;
        if(m_bUseEfficiency){
            if(m_dEfficiency > 0){
            dCapacity /= m_dEfficiency;
            }
            else{
                dCapacity = 0;
            }
    }
    if(m_dPowerFactor === 0){
        dFullCurrent = dCapacity /m_lVoltage;
        if(!m_bSPhae){
            dFullCurrent /=Math.sqrt(3.0);
        }
    }
    else{
        dFullCurrent = dCapacity/ m_lVoltage / m_dPowerFactor;
        if(!m_bSPhae){
            dFullCurrent /= Math.sqrt(3.0);
        }
    }
}
    return dFullCurrent;
}
const doGetValueLoadCurrent=(props: MsMotorModel, m_bSPhae :boolean) =>{
    let m_bManualLoadCurrent :boolean = props.manualLoadCurrent;
    let dLoadCurrent = 0;
    if(m_bManualLoadCurrent){
        dLoadCurrent = props.loadCurrent;
    }
    else {
        
        dLoadCurrent = doGetValueFullCurrent(props,m_bSPhae);
    }
    return dLoadCurrent;
}
const doMakeCurveInrushMotor =(m_listDrawInrush:Array<CdPoint>,props:MsMotorModel,m_bSPhae:boolean) =>{
    let m_lVoltage = props.voltageID;
    let m_StartingTime = props.startingTime;
    let m_dInrushTimes = props.inrushTimes;
    let m_dStartingTimes = props.startingTimes;
    let m_bManualStartingCurrent = props.manualStartingCurrent;
    let m_dStartingCurrent = props.startingCurrent
    let m_bManulInrushCurrent = props.manualInrushCurrent;
    let m_dInrushCurrent = props.inrushCurrent;
    let m_dStarting600 = props.starting600;
    let m_bInrush20or40 = props.inrush20Or40;
    let dLoadCurrent = doGetValueLoadCurrent(props,m_bSPhae) * m_lVoltage
    if(dLoadCurrent <= 0 ){
        return false;
    }
    const pdPoint: CdPoint ={
        m_dx:0,
        m_dy:0
    }
    pdPoint.m_dx = dLoadCurrent;
    pdPoint.m_dy = 10000;
    m_listDrawInrush.push({...pdPoint});

    pdPoint.m_dx = dLoadCurrent;
    pdPoint.m_dy = m_StartingTime + m_StartingTime / 5;
    m_listDrawInrush.push({...pdPoint});

    pdPoint.m_dx = dLoadCurrent + dLoadCurrent / 50;
    pdPoint.m_dy = m_StartingTime + m_StartingTime / 10;
    m_listDrawInrush.push({...pdPoint});

    pdPoint.m_dx = dLoadCurrent + dLoadCurrent / 10;
    pdPoint.m_dy = m_StartingTime;
    m_listDrawInrush.push({...pdPoint});
    
    let dDifference = m_dInrushTimes - m_dStartingTimes;
    if(dDifference > 4){
        dDifference = 1;
    }
    else{
        dDifference = dDifference / 4;
    }
    let dStartingCurrent = doGetValueStartingCurrent(props,m_bManualStartingCurrent,m_dStartingTimes,m_dStartingCurrent,m_bSPhae)*m_lVoltage;
    let dInrushCurrent = doGetValueInrushCurrent(props,m_bManulInrushCurrent,m_dInrushCurrent,m_dInrushTimes,m_bSPhae) * m_lVoltage;

    pdPoint.m_dx = dStartingCurrent - dStartingCurrent / 10;
    pdPoint.m_dy = m_dStarting600;
    m_listDrawInrush.push({...pdPoint});

    pdPoint.m_dx = dStartingCurrent - dStartingCurrent / 50;
    pdPoint.m_dy = m_dStarting600 - m_dStarting600 /10;
    m_listDrawInrush.push({...pdPoint});

    pdPoint.m_dx = dStartingCurrent;
    pdPoint.m_dy = m_dStarting600 - m_dStarting600 /6;
    m_listDrawInrush.push({...pdPoint});

    pdPoint.m_dx = dStartingCurrent;
    if(m_bInrush20or40){
        pdPoint.m_dy = 0.055;
        m_listDrawInrush.push({...pdPoint});

        pdPoint.m_dx = dStartingCurrent + (dStartingCurrent /100) * dDifference;
        pdPoint.m_dy = 0.052;
        m_listDrawInrush.push({...pdPoint});

        pdPoint.m_dx = dStartingCurrent + (dStartingCurrent /10) * dDifference;
        pdPoint.m_dy = 0.05;
        m_listDrawInrush.push({...pdPoint});

        pdPoint.m_dx = dInrushCurrent - (dInrushCurrent /10) * dDifference;
        pdPoint.m_dy = 0.045;
        m_listDrawInrush.push({...pdPoint});

        pdPoint.m_dx = dInrushCurrent - (dInrushCurrent /50) * dDifference;
        pdPoint.m_dy = 0.043;
        m_listDrawInrush.push({...pdPoint});

        pdPoint.m_dx = dInrushCurrent;
        pdPoint.m_dy = 0.04;
        m_listDrawInrush.push({...pdPoint});
    }
    else{
        pdPoint.m_dy = 0.031;
        m_listDrawInrush.push({...pdPoint});

        pdPoint.m_dx = dStartingCurrent + (dStartingCurrent /100) * dDifference;
        pdPoint.m_dy = 0.029;
        m_listDrawInrush.push({...pdPoint});

        pdPoint.m_dx = dStartingCurrent + (dStartingCurrent /10) * dDifference;
        pdPoint.m_dy = 0.027;
        m_listDrawInrush.push({...pdPoint});

        pdPoint.m_dx = dInrushCurrent - (dInrushCurrent /10) * dDifference;
        pdPoint.m_dy = 0.023;
        m_listDrawInrush.push({...pdPoint});

        pdPoint.m_dx = dInrushCurrent - (dInrushCurrent /50) * dDifference;
        pdPoint.m_dy = 0.021;
        m_listDrawInrush.push({...pdPoint});

        pdPoint.m_dx = dInrushCurrent;
        pdPoint.m_dy = 0.02;
        m_listDrawInrush.push({...pdPoint});

    }
        pdPoint.m_dx = dInrushCurrent;
        pdPoint.m_dy = 0.01;
        m_listDrawInrush.push({...pdPoint});

        pdPoint.m_dx = dInrushCurrent - dInrushCurrent /80;
        pdPoint.m_dy = 0.0095;
        m_listDrawInrush.push({...pdPoint});

        pdPoint.m_dx = dInrushCurrent - dInrushCurrent /20;
        pdPoint.m_dy = 0.009;
        m_listDrawInrush.push({...pdPoint});

        pdPoint.m_dx = dInrushCurrent - dInrushCurrent /10;
        pdPoint.m_dy = 0.0085;
        m_listDrawInrush.push({...pdPoint});

        pdPoint.m_dx = dInrushCurrent - dInrushCurrent /5;
        pdPoint.m_dy = 0.008;
        m_listDrawInrush.push({...pdPoint});

        pdPoint.m_dx = dLoadCurrent /10;
        pdPoint.m_dy = 0.005;
        m_listDrawInrush.push({...pdPoint});

    return true;


 }
 const doGetValueStartingCurrent = (props:MsMotorModel,m_bManualStartingCurrent:boolean,m_dStartingTimes:number,m_dStartingCurrent:number,m_bSPhae:boolean)=>{
    let dStartingCurrent = 0;
    if(m_bManualStartingCurrent){
        dStartingCurrent = m_dStartingCurrent;
    }
    else{
        dStartingCurrent = m_dStartingTimes * doGetValueLoadCurrent(props,m_bSPhae);
    }
    return dStartingCurrent;
 }
 const doGetValueInrushCurrent = (props:MsMotorModel,m_bManulInrushCurrent:boolean,m_dInrushCurrent:number,m_dInrushTimes:number,m_bSPhae:boolean) =>{
    let dInrushCurrent = 0;
    if(m_bManulInrushCurrent){
        dInrushCurrent = m_dInrushCurrent;
    }
    else{
        dInrushCurrent = m_dInrushTimes * doGetValueLoadCurrent(props,m_bSPhae);
    }
    return dInrushCurrent;
 }

 const doMakeCurveInrushMotor2 =(m_listDrawInrush:Array<CdPoint>,props:MsMotorModel,m_bSPhae:boolean) =>{
    let m_lVoltage = props.voltageID;
    let m_dBaseCapacity = props.baseCapacity;
    let m_StartingTime = props.startingTime;
    let m_dInrushTimes = props.inrushTimes;
    let m_dStartingTimes = props.startingTimes;
    let m_bManualStartingCurrent = props.manualStartingCurrent;
    let m_dStartingCurrent = props.startingCurrent
    let m_bManulInrushCurrent = props.manualInrushCurrent;
    let m_dInrushCurrent = props.inrushCurrent;
    let m_dStarting600 = props.starting600;
    let m_bInrush20or40 = props.inrush20Or40;
    let dLoadCurrent = doGetValueLoadCurrent(props,m_bSPhae) * m_lVoltage;
    if(dLoadCurrent <=0){
        return false;
    }
    let dBaseCurrent = doGetValueBaseLoadCurrent(m_lVoltage,m_dBaseCapacity) * m_lVoltage;
    let dStartingCurrent = doGetValueStartingCurrent(props,m_bManualStartingCurrent,m_dStartingTimes,m_dStartingCurrent,m_bSPhae) * m_lVoltage;
    let dInrushCurrent = doGetValueInrushCurrent(props,m_bManulInrushCurrent,m_dInrushCurrent,m_dInrushTimes,m_bSPhae) * m_lVoltage;
    let dDifference = (dBaseCurrent + dStartingCurrent) /(dBaseCurrent + dLoadCurrent);
    if(dDifference > 8) {
        dDifference = 1;
    }
    else{
        dDifference = dDifference / 8;
    }
    const pdPoint:CdPoint ={
        m_dx : 0,
        m_dy : 0
    }
    pdPoint.m_dx = dBaseCurrent + dLoadCurrent;
    pdPoint.m_dy = 10000;
    m_listDrawInrush.push({...pdPoint});

    pdPoint.m_dx = dBaseCurrent + dLoadCurrent;
    pdPoint.m_dy = m_StartingTime + m_StartingTime /5;
    m_listDrawInrush.push({...pdPoint});

    pdPoint.m_dx = dBaseCurrent + dLoadCurrent +((dBaseCurrent +dLoadCurrent) /50)*dDifference;
    pdPoint.m_dy = m_StartingTime + m_StartingTime /10;
    m_listDrawInrush.push({...pdPoint});

    pdPoint.m_dx = dBaseCurrent + dLoadCurrent +((dBaseCurrent +dLoadCurrent) /10)*dDifference;
    pdPoint.m_dy = m_StartingTime;
    m_listDrawInrush.push({...pdPoint});

    let dDifference2 = m_dInrushTimes - m_dStartingTimes;
    if(dDifference2 > 4){
        dDifference2 = 1;
    }
    else{
        dDifference2 = dDifference2 / 4;
    }

    pdPoint.m_dx = dBaseCurrent + dStartingCurrent -(dStartingCurrent /10) * dDifference;
    pdPoint.m_dy = m_dStarting600;
    m_listDrawInrush.push({...pdPoint});

    pdPoint.m_dx = dBaseCurrent + dStartingCurrent -(dStartingCurrent /50) * dDifference;
    pdPoint.m_dy = m_dStarting600 - m_dStarting600 /10;
    m_listDrawInrush.push({...pdPoint});

    pdPoint.m_dx = dBaseCurrent + dStartingCurrent ;
    pdPoint.m_dy = m_dStarting600 - m_dStarting600 /6;
    m_listDrawInrush.push({...pdPoint});

    if(m_bInrush20or40) {

    pdPoint.m_dx = dBaseCurrent + dStartingCurrent;
    pdPoint.m_dy = 0.055;
    m_listDrawInrush.push({...pdPoint});

    pdPoint.m_dx = dBaseCurrent + dStartingCurrent +(dStartingCurrent / 100) * dDifference2;
    pdPoint.m_dy = 0.052;
    m_listDrawInrush.push({...pdPoint});

    pdPoint.m_dx = dBaseCurrent + dStartingCurrent +(dStartingCurrent /10) * dDifference2;
    pdPoint.m_dy = 0.05;
    m_listDrawInrush.push({...pdPoint});

    pdPoint.m_dx = dBaseCurrent + dInrushCurrent - (dInrushCurrent /10) *dDifference2;
    pdPoint.m_dy = 0.045;
    m_listDrawInrush.push({...pdPoint});

    pdPoint.m_dx = dBaseCurrent + dInrushCurrent - (dInrushCurrent /50) * dDifference2;
    pdPoint.m_dy = 0.043;
    m_listDrawInrush.push({...pdPoint});

    pdPoint.m_dx = dBaseCurrent + dInrushCurrent;
    pdPoint.m_dy = 0.04;
    m_listDrawInrush.push({...pdPoint});

    }
    else {

    pdPoint.m_dx = dBaseCurrent + dStartingCurrent;
    pdPoint.m_dy = 0.031;
    m_listDrawInrush.push({...pdPoint});

    pdPoint.m_dx = dBaseCurrent + dStartingCurrent +(dStartingCurrent /100) * dDifference2;
    pdPoint.m_dy = 0.029;
    m_listDrawInrush.push({...pdPoint});

    pdPoint.m_dx = dBaseCurrent + dStartingCurrent + (dStartingCurrent /10) * dDifference2;
    pdPoint.m_dy = 0.028;
    m_listDrawInrush.push({...pdPoint});

    pdPoint.m_dx = dBaseCurrent + dInrushCurrent - (dInrushCurrent /10) * dDifference2;
    pdPoint.m_dy = 0.023;
    m_listDrawInrush.push({...pdPoint});

    pdPoint.m_dx = dBaseCurrent + dInrushCurrent -(dInrushCurrent /50) * dDifference2;
    pdPoint.m_dy = 0.021;
    m_listDrawInrush.push({...pdPoint});
    
    pdPoint.m_dx = dBaseCurrent + dInrushCurrent;
    pdPoint.m_dy = 0.02;
    m_listDrawInrush.push({...pdPoint});

    }

    pdPoint.m_dx = dBaseCurrent + dInrushCurrent;
    pdPoint.m_dy = 0.01;
    m_listDrawInrush.push({...pdPoint});

    pdPoint.m_dx = dBaseCurrent + dInrushCurrent - dInrushCurrent /80;
    pdPoint.m_dy = 0.0095;
    m_listDrawInrush.push({...pdPoint});

    pdPoint.m_dx = dBaseCurrent + dInrushCurrent - dInrushCurrent /20;
    pdPoint.m_dy = 0.009;
    m_listDrawInrush.push({...pdPoint});

    pdPoint.m_dx = dBaseCurrent + dInrushCurrent - dInrushCurrent /10;
    pdPoint.m_dy = 0.0085;
    m_listDrawInrush.push({...pdPoint});

    pdPoint.m_dx = dBaseCurrent + dInrushCurrent - dInrushCurrent /5;
    pdPoint.m_dy = 0.008;
    m_listDrawInrush.push({...pdPoint});

    pdPoint.m_dx = (dBaseCurrent +dLoadCurrent) /10
    pdPoint.m_dy = 0.005;
    m_listDrawInrush.push({...pdPoint});

    return true;

 }
 const doGetValueBaseLoadCurrent =(m_lVoltage:number,m_dBaseCapacity:number) =>{
    let dBaseLoad = 0;
    if(m_lVoltage > 0){
        dBaseLoad = m_dBaseCapacity * 1000.0 / m_lVoltage / Math.sqrt(3.0);
    }
    return dBaseLoad;
 }
 const doGetTextLoadCurrent =(props:MsMotorModel,m_bSPhae:boolean,m_bManulLoadCurrent:boolean,m_dLoadCurrent:number) =>{
    let dLoadCurrent = 0
    if(m_bManulLoadCurrent){
        dLoadCurrent = m_dLoadCurrent;
    }else{
        dLoadCurrent = doGetValueFullCurrent(props,m_bSPhae)//m_bSPhase: check top
    }
    return dLoadCurrent;

 }
const getMsMotorLabel = (props : MsMotorModel,m_bSPhase:boolean, infos : string[],dNominalVolt:number) => { 
        const refNo = props.refNo;
        const uIntHP = props.uIntHP;
        const m_dKiloWattCapacity = props.capacityID;
        const m_dHorsePowerCapacity = props.capacityHpID;
        const m_lVoltage = props.voltageID;
        const m_bManualLoadCurrent = props.manualLoadCurrent
        const m_dLoadCurrent = props.loadCurrent
        const m_dStartingTimes = props.startingTimes;
        const m_dStarting600 = props.starting600;
        const m_dInrushTimes = props.inrushTimes
            infos.push(refNo);
            if(uIntHP == false){//not check HP
                infos.push(m_dKiloWattCapacity.toString()+`kW`);
            }
            else{
                infos.push(m_dHorsePowerCapacity.toString()+`HP`);
            }
            const dloadCurrent = doGetTextLoadCurrent(props,m_bSPhase,m_bManualLoadCurrent,m_dLoadCurrent);

            if(m_lVoltage === dNominalVolt){
                infos.push((Math.round(dloadCurrent * 10) / 10).toString()+`A`)
            }
            else{
                const dLoadCurrent = doGetValueLoadCurrent(props,m_bSPhase);
                infos.push(Math.round(dLoadCurrent *m_lVoltage /dNominalVolt) +`A` +`(` +dloadCurrent+`)`);
            }

            infos.push(m_dStartingTimes +`倍,`+m_dStarting600 +`s`);
            infos.push(m_dInrushTimes+`倍`);

        
 }
 const doGetTextTotalCurrent =(props:MsMotorModel,m_bSPhase:boolean,m_lVoltage:number,m_dBaseCapacity:number) =>{
    const dLoadCurrent = doGetValueLoadCurrent(props,m_bSPhase);
    const dBaseCurrent = doGetValueBaseLoadCurrent(m_lVoltage,m_dBaseCapacity);
    return dLoadCurrent + dBaseCurrent;//Apere
 }
 const getMsMotorLabel2 = (props : MsMotorModel,m_bSPhase:boolean, infos : string[],dNominalVolt:number) => { 
        const refNo = props.refNo;
        const uIntHP = props.uIntHP;
        const m_dKiloWattCapacity = props.capacityID;
        const m_dHorsePowerCapacity = props.capacityHpID;
        const m_dBaseCapacity = props.baseCapacity;
        const m_lVoltage = props.voltageID;
        const m_dStartingTimes = props.startingTimes;
        const m_dStarting600 = props.starting600;
        const m_dInrushTimes = props.inrushTimes;
        infos.push(refNo)
        if(uIntHP == false){
            infos.push(m_dKiloWattCapacity +`kW` +`+` +m_dBaseCapacity +`kVA`)
        }
        else{
            infos.push(m_dHorsePowerCapacity.toString()+`HP`+`+` +m_dBaseCapacity +`kVA`);
        }
        const ampere = doGetTextTotalCurrent(props,m_bSPhase,m_lVoltage,m_dBaseCapacity);
        if(m_lVoltage === dNominalVolt){
            
            infos.push(ampere + `A`)
        }
        else{
            const dLoadCurrent = doGetValueLoadCurrent(props,m_bSPhase);
            const dBaseCurrent = doGetValueBaseLoadCurrent(m_lVoltage,m_dBaseCapacity);
            const result = Math.round((dLoadCurrent + dBaseCurrent) * m_lVoltage/ dNominalVolt);
            infos.push(result +`A` +`(`+ampere +`)`)
        }
        infos.push(m_dStartingTimes +`倍,`+m_dStarting600 +`s`)
        infos.push(m_dInrushTimes+`倍`)



 }
 export {
    doDrawCurveMotor,
    getMsMotorLabel,
    getMsMotorLabel2
 }