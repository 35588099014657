import { Button, ButtonGroup, Checkbox, Container, Dialog, DialogContent, DialogTitle, FormControlLabel, Grid, Paper, Radio, RadioGroup, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, TextareaAutosize, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import theme from "../../themes/globalStyles";
import DraggablePaper from "../common/DraggablePaper";
import { InfoCover } from "../../models/Index";
import { useEffect, useState } from "react";
import { BeamsNumber } from "../../utils/FormatNumber";
import { MS_KW_TO_HORSEPOWER } from "../../models/Constants";
import { doGetSubExisting, doGetSubIncrease, doGetSubNew, doGetValuPowerTotal, doGetValueHeatTotal, doGetValueLightTotal, doGetValueSubTotal } from "../../utils/infoCover1";
import { isNull } from "mathjs";

export type SettingCover3Form = {
    instrument1: string,
    instrument2: string,
    instrument3: string,
    instrument4: string,
    instrument5: string,
    instrument6: string,
    instrument7: string,
    instrument8: string,
    instrument9: string,
    instrument10: string,
    instrument11: string,
    instrument12: string,
    instrument13: string,
    instrument14: string,
    instrument15: string,
    instrument16: string,
    instrument17: string,
    instrument18: string,
    instrument19: string,
    instrument20: string,
    instrument21: string,
    corporation1: string,
    corporation2: string,
    corporation3: string,
    corporation4: string,
    corporation5: string,
    corporation6: string,
    corporation7: string,
    corporation8: string,
    corporation9: string,
    corporation10: string,
    corporation11: string,
    corporation12: string,
    corporation13: string,
    corporation14: string,
    corporation15: string,
    corporation16: string,
    corporation17: string,
    corporation18: string,
    corporation19: string,
    corporation20: string,
    corporation21: string,
}

export type SettingCover3FormProps = {
    diaglogTitle: string;
    data: InfoCover;
    onOK: (data: any) => void;
    onCancel: () => void;
};
const MIN_OF_POINTS_ARRAY = 10

export const SettingCover3Dialog = (props: SettingCover3FormProps) => {

    //#region fields
    const { diaglogTitle, data, onOK, onCancel } = props;

    // 初期値設定
    const initialValue : SettingCover3Form = {
        ...data,
    }
    const checkboxStyle = {
        padding: '0px 0px 0px 9px'
    }

    const { control, handleSubmit, formState: { errors }, setValue, getValues } = useForm<SettingCover3Form>({
        mode: "all",
        criteriaMode: "all",
        defaultValues: initialValue,
    })

    //#endregion fields
    //#region methods
    // Submitイベント
    const handleEditSubmit = (value: any, event: any) => {
        const request = createRequestData(value);
        onOK(request);
    }
    let dataList =[{instrument:initialValue.instrument1,corporation:initialValue.corporation1},
                   {instrument:initialValue.instrument2,corporation:initialValue.corporation2},
                   {instrument:initialValue.instrument3,corporation:initialValue.corporation3},
                   {instrument:initialValue.instrument4,corporation:initialValue.corporation4},
                   {instrument:initialValue.instrument5,corporation:initialValue.corporation5},
                   {instrument:initialValue.instrument6,corporation:initialValue.corporation6},
                   {instrument:initialValue.instrument7,corporation:initialValue.corporation7},
                   {instrument:initialValue.instrument8,corporation:initialValue.corporation8},
                   {instrument:initialValue.instrument9,corporation:initialValue.corporation9},
                   {instrument:initialValue.instrument10,corporation:initialValue.corporation10},
                   {instrument:initialValue.instrument11,corporation:initialValue.corporation11},
                   {instrument:initialValue.instrument12,corporation:initialValue.corporation12},
                   {instrument:initialValue.instrument13,corporation:initialValue.corporation13},
                   {instrument:initialValue.instrument14,corporation:initialValue.corporation14},
                   {instrument:initialValue.instrument15,corporation:initialValue.corporation15},
                   {instrument:initialValue.instrument16,corporation:initialValue.corporation16},
                   {instrument:initialValue.instrument17,corporation:initialValue.corporation17},
                   {instrument:initialValue.instrument18,corporation:initialValue.corporation18},
                   {instrument:initialValue.instrument19,corporation:initialValue.corporation19},
                   {instrument:initialValue.instrument20,corporation:initialValue.corporation20},
                   {instrument:initialValue.instrument21,corporation:initialValue.corporation21}]

    let initPointsArr = dataList.filter((item) => !isNull(item.instrument))
    if(initPointsArr.length <=0){
        initPointsArr =[{instrument:"",corporation:""},{instrument:"",corporation:""},{instrument:"",corporation:""},{instrument:"",corporation:""},{instrument:"",corporation:""},
        {instrument:"",corporation:""},{instrument:"",corporation:""},{instrument:"",corporation:""},{instrument:"",corporation:""},{instrument:"",corporation:""}]
    }
                   
    const [array, setArray] = useState<any>(initPointsArr);
    // FormデータからRequestデータを作成する
    const convertArrToData =(request:any) =>{
        for(var i =0; i< 21;i++){
            request['instrument'+(i+1)] = null
            request['corporation'+(i+1)] = null
        }
        for(var y =0; y< array.length;y++){
            request['instrument'+(y+1)] = array[y].instrument
            request['corporation'+(y+1)] = array[y].corporation
        }

    }
    const createRequestData = (formValue : any) => {
        convertArrToData(formValue)
        let request = {
            ...formValue,
        } as SettingCover3Form;
        return request;
    }
    const checkDisable = (index: any) => {
        return index !== 0 && (array[index - 1].instrument === undefined && array[index - 1].corporation === undefined) && (array[index].instrument === undefined && array[index].corporation === undefined) ;
    }
    const [selectedPointItem, setSelectedPointItem] = useState<any>({
        index: -1,
        axis: 'instrument'
    });
    useEffect(() => {
        if (selectedPointItem.index >= 0) {
            document.querySelector<HTMLInputElement>(`#${selectedPointItem.axis}${selectedPointItem.index}`)?.focus()
        }
    }, [array])
    const handleAddItem = () => {
        const index = selectedPointItem.index;
        if(index >=0){
            array.splice(index,0,{instrument:"",corporation:""})
            setArray([...array])
        }
    }
    const handleDeleteItem = () => {
        const index = selectedPointItem.index;
        if(index >= 0){
            array.splice(index,1);
            if(array.length <= 1){
                array.push({instrument:"",corporation:""});
                setArray([...array])
        }
        }
    }
    //#endregion methods
    const renderItems = () => {
        return array.map((item: any, index: any) => (
            <div key={index} style={{ marginBottom: "10px", marginTop:'5px'}}>
                <Grid container spacing={1} direction={"row"}>
                    <Grid item xs={6}>
                        <TextField
                            id={`instrument${index}`}
                            fullWidth
                            value={item.instrument ? item.instrument : ""}
                            disabled={checkDisable(index)}
                            style={{
                                backgroundColor: (checkDisable(index)) ? "floralwhite" : "white"
                            }}
                            onChange={(e: any) => {
                                item.instrument = e.target.value;
                                // handleChangeFields(e,index,e.target.value,"instrument")
                                setArray([...array])
                            }}
                            name={`instrument${index}`}
                            onFocus={() => {
                                setSelectedPointItem({index, axis: 'instrument'});
                            }}
                            onBlur={(e:any) => {
                                if(e.relatedTarget){
                                    if(e.relatedTarget.id === "deleteButton"){
                                        setSelectedPointItem({index: index, axis: 'instrument'});
                                    }
                                    else if(e.relatedTarget.id === "addButton"){
                                        setSelectedPointItem({index: index, axis: 'instrument'});
                                    }
                                }
                            }}
                            InputProps={{
                                sx:{height:32},
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            id={`corporation${index}`}
                            fullWidth
                            value={item.corporation ? item.corporation : ""}
                            disabled={checkDisable(index)}
                            style={{
                                backgroundColor: (checkDisable(index)) ? "floralwhite" : "white"
                            }}
                            name= {`corporation${index}`}
                            onChange={(e: any) => {
                                item.corporation = e.target.value;
                                // handleChangeFields(e,index,e.target.value,"corporation")
                                setArray([...array])
                            }}
                            onFocus={() => {
                                setSelectedPointItem({index, axis: 'corporation'});
                            }}  
                            onBlur={(e:any) => {
                                if(e.relatedTarget){
                                    if(e.relatedTarget.id !== "deleteButton"){
                                        setSelectedPointItem({index: index, axis: 'corporation'});
                                    }
                                    else if(e.relatedTarget.id === "addButton"){
                                        setSelectedPointItem({index: index, axis: 'corporation'});
                                    }
                                }
                            }}
                            InputProps={{
                                sx:{height:32},
                            }}
                        />
                    </Grid>
                </Grid>
            </div>
        ));
    }

    return (
        <>
            <Dialog open={true} maxWidth={"sm"} fullWidth
                PaperComponent={
                    DraggablePaper
                }
            >
                <DialogTitle 
                    onMouseOver={(e : any) => e.target.style.cursor = 'move'}
                    style={{ paddingTop: '2px', paddingBottom: '2px', background: theme.palette.primary.main, color: theme.palette.primary.contrastText }}
                >
                    {diaglogTitle}
                </DialogTitle>
                <DialogContent style={{paddingTop: '8px'}}>
                    <div style={{ height: '50vh', width: '100%',marginTop:'3px'}}>
                        <form onSubmit={handleSubmit(handleEditSubmit)}>
                            <Grid container>
                                <fieldset style={{width:"100%"}}>
                                    <Stack style={{ height: "calc(50vh - 102px)", overflowY: "auto" }}>
                                        {renderItems()}
                                    </Stack>
                                    <Stack direction={"row"} justifyContent={"flex-end"} spacing={2} mt={1}>
                                        <Button 
                                            variant="contained" 
                                            onClick={handleAddItem} 
                                            fullWidth
                                            size="small"
                                        >
                                            カーソル位置に行を挿入
                                        </Button>
                                        <Button 
                                            variant="contained" 
                                            onClick={handleDeleteItem} 
                                            onFocus={e => e.currentTarget}
                                            fullWidth
                                            id="deleteButton"
                                            disabled={!(selectedPointItem.index >= 0 && selectedPointItem.index < array.length) }
                                        >
                                            カーソル位置の行を削除
                                        </Button>
                                    </Stack>
                                </fieldset>
                            </Grid>
                            <Stack direction='row' justifyContent="flex-end" spacing={2} mt={1}>
                                <Button variant="contained" type="submit">OK</Button>
                                <Button color="inherit" variant="contained" onClick={onCancel}>キャンセル</Button>
                            </Stack>
                        </form>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    )
}
export default SettingCover3Dialog;