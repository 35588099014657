import { LoadingButton } from "@mui/lab";
import { Box, Button, Checkbox, CircularProgress, FormControlLabel, Grid, Stack, TextField, Typography } from "@mui/material";
import { useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { EquipBusbarModel } from "../../../models/breakModel";
import { Path as RoutePath } from "../../../path";
import { ApplicationState, actionCreators, selectors } from "../../../store";
import FetchHelper from "../../FetchHelper";
//-----------
//FETCH ID
const GET_EQUIP_BUSBAR = "GET_EQUIP_BUSBAR"
const UPDATE_EQUIP_BUSBAR = "UPDATE_EQUIP_BUSBAR"
const CREATE_EQUIP_BUSBAR = "CREATE_EQUIP_BUSBAR"

const initialValue: EquipBusbarModel = {
    lBusbarId: 0,
    bIsDefaultN: false,
    bIsDefaultT: false,
    bIsDefaultW: false,
    dAlIzBare: 0,
    dAlIzPainted: 0,
    dCuIzBare: 0,
    dCuIzPainted: 0,
    dProximityk4: 0,
    dSkinEffect: 0,
    ddm: 0,
    dk3bare: 0,
    dk3painted: 0,
    nNumber: 0,
    nThickness: 0,
    nWidth: 0,
}
// -------------
// Props
export type BreakCapacityEditProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
export const BreakCapacityEdit = (props: BreakCapacityEditProps) => {
    const {
        userId,
        onCreateError,
        onUpdate, onUpdateError,
        onSuccess, onError,
        onCreate
    } = props;

    const { control, formState: { errors }, handleSubmit, setValue, getValues, } = useForm<EquipBusbarModel>({
        criteriaMode: "all",
        mode: "all",
        defaultValues: initialValue,
    })
    type MyParams = {
        id: string;
        mode: string;
    };
    let { id, mode } = useParams<keyof MyParams>() as MyParams;
    let navigate = useNavigate();
    const [loadingFlag, setLoadingFlag] = useState(mode === "edit" ? true : false);
    const gridRef = useRef() as any;
    const Exp = /^[-+]?\d+(\.\d+)?$/;
    const messageIsNotNumber = "数字を入力してください。";

    const handleSuccess = (data: any) => {
        setLoadingFlag(false);
        if (data && data.resultCode == 0) {
            setValue("bIsDefaultN", data.data.bIsDefaultN);
            setValue("bIsDefaultT", data.data.bIsDefaultT);
            setValue("bIsDefaultW", data.data.bIsDefaultW);
            setValue("dAlIzBare", data.data.dAlIzBare??"");
            setValue("dAlIzPainted", data.data.dAlIzPainted??"");
            setValue("dCuIzBare", data.data.dCuIzBare??"");
            setValue("dCuIzPainted", data.data.dCuIzPainted??"");
            setValue("dProximityk4", data.data.dProximityk4??"");
            setValue("dSkinEffect", data.data.dSkinEffect??"");
            setValue("ddm", data.data.ddm??"");
            setValue("dk3bare", data.data.dk3bare??"");
            setValue("dk3painted", data.data.dk3painted??"");
            setValue("nNumber", data.data.nNumber??"");
            setValue("nThickness", data.data.nThickness??"");
            setValue("nWidth", data.data.nWidth??"");
        }
    }

    // 検索エラー時
    const handleError = (success: boolean, data: any, error: any) => {
        setLoadingFlag(false);
        console.log('>>>> handle Error');
        gridRef.current.hideOverlay();
    };

    // 検索実行
    const handleEditSubmit = (data: EquipBusbarModel) => {
        setLoadingFlag(true);
        if (mode === "edit") {
            let request = {
                lBusbarId: id,
                bIsDefaultN: data.bIsDefaultN,
                bIsDefaultT: data.bIsDefaultT,
                bIsDefaultW: data.bIsDefaultW,
                dAlIzBare: data.dAlIzBare,
                dAlIzPainted: data.dAlIzPainted,
                dCuIzBare: data.dCuIzBare,
                dCuIzPainted: data.dCuIzPainted,
                dProximityk4: data.dProximityk4,
                dSkinEffect: data.dSkinEffect,
                ddm: data.ddm,
                dk3bare: data.dk3bare,
                dk3painted: data.dk3painted,
                nNumber: data.nNumber,
                nThickness: data.nThickness,
                nWidth: data.nWidth,
            } as any
            const object = {
                requestPrms: request,
                userId: userId,
            }
            onUpdate(object);
        } else if (mode === "add") {
            let request = {
                bIsDefaultN: data.bIsDefaultN,
                bIsDefaultT: data.bIsDefaultT,
                bIsDefaultW: data.bIsDefaultW,
                dAlIzBare: data.dAlIzBare,
                dAlIzPainted: data.dAlIzPainted,
                dCuIzBare: data.dCuIzBare,
                dCuIzPainted: data.dCuIzPainted,
                dProximityk4: data.dProximityk4,
                dSkinEffect: data.dSkinEffect,
                ddm: data.ddm,
                dk3bare: data.dk3bare,
                dk3painted: data.dk3painted,
                nNumber: data.nNumber,
                nThickness: data.nThickness,
                nWidth: data.nWidth,
            } as any
            const object = {
                requestPrms: request,
                userId: userId,
            }
            onCreate(object);
        }
    }

    // キャンセル
    const handleCancel = () => {
        navigate(RoutePath.EquipBusBarList);
    }

    // Submit後のAPI呼び出し成功時
    const handleSubmitSuccess = (success: boolean, data: any, error: any) => {
        onSuccess();
        if (mode === "add") {
            navigate(RoutePath.EquipBusBarEdit + `/edit/${data.data.lBusBarId}`, { replace: true })
        }
        setLoadingFlag(false)
    }
    // Submit後のAPI呼び出しエラー時
    const handleErrorUpdate = (success: boolean, data: any, error: any) => {
        onUpdateError();

        setLoadingFlag(false);
    }

    // Submit後のAPI呼び出しエラー時
    const handleSubmitError = (success: boolean, data: any, error: any) => {
        onCreateError();
        setLoadingFlag(false)
    }

    //入力欄背景
    const divStyles = {
        backgroundColor: 'white',
        border: '1px solid #bebebe',
        boxShadow: '0 0 5px #fff, 0 0 5px #bebebe, 0 0 1px #aaa',
        padding: '20px 40px',
        marginTop: '40px',
    }

    return (
        <>
            {
                mode === "edit" &&
                <FetchHelper
                    fetchId={GET_EQUIP_BUSBAR}
                    url={`/master/equip_busbar/get`}
                    method="POST"
                    json={true}
                    request={true}
                    params={{
                        requestPrms: {
                            id: id
                        },
                        userId: userId,
                    }}
                    onComplete={(success, data, error) => {
                        success ? handleSuccess(data) : handleError(success, data, error);
                    }}
                />
            }
            {loadingFlag && (
                <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1500, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                    <CircularProgress />
                </div>
            )}
            <div style={{ height: "100%", width: "100%", marginTop: "10px" }}>
                <Stack direction="row" justifyContent="flex-start" alignItems="center" sx={{}} style={{ margin: '18px', color: 'MenuText' }}>
                    <Typography variant="h4">
                        {/* FIXME: */}
                        ブスバー詳細
                    </Typography>
                </Stack>
                <form onSubmit={handleSubmit(handleEditSubmit)}>
                    <div style={divStyles}>
                        <Typography variant="h6">
                            {/* FIXME: */}
                            ブスバー
                        </Typography>
                        <Stack mt={2} direction={{ xs: 'column', sm: 'row' }} sx={{ maxHeight: "calc(100vh)", overflow: 'auto' }}>
                            <Grid width={"100%"} spacing={2}>
                                <Grid pt={1} spacing={2} container direction={"row"}>
                                    <Grid item xs={4}>
                                        <Controller
                                            control={control}
                                            name="dAlIzBare"
                                            rules={{
                                                pattern: {
                                                    value: Exp,
                                                    message: messageIsNotNumber,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.dAlIzBare}
                                                    helperText={errors?.dAlIzBare?.message}
                                                    label="アルミで未塗装の基本許容電流Iz"
                                                    type="text"
                                                    size="small"
                                                // InputLabelProps={{ shrink: true }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Controller
                                            control={control}
                                            name="dAlIzPainted"
                                            rules={{
                                                pattern: {
                                                    value: Exp,
                                                    message: messageIsNotNumber,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.dAlIzPainted}
                                                    helperText={errors?.dAlIzPainted?.message}
                                                    label="アルミで塗装済みの基本許容電流Iz"
                                                    type="text"
                                                    size="small"
                                                // InputLabelProps={{ shrink: true }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Controller
                                            control={control}
                                            name="dCuIzBare"
                                            rules={{
                                                pattern: {
                                                    value: Exp,
                                                    message: messageIsNotNumber,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.dCuIzBare}
                                                    helperText={errors?.dCuIzBare?.message}
                                                    label="銅で未塗装の基本許容電流Iz"
                                                    type="text"
                                                    size="small"
                                                // InputLabelProps={{ shrink: true }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid mt={0} spacing={2} container direction={"row"}>
                                    <Grid item xs={4}>
                                        <Controller
                                            control={control}
                                            name="dCuIzPainted"
                                            rules={{
                                                pattern: {
                                                    value: Exp,
                                                    message: messageIsNotNumber,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.dCuIzPainted}
                                                    helperText={errors?.dCuIzPainted?.message}
                                                    label="銅で塗装済みの基本許容電流Iz"
                                                    type="text"
                                                    size="small"
                                                // InputLabelProps={{ shrink: true }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Controller
                                            control={control}
                                            name="dProximityk4"
                                            rules={{
                                                pattern: {
                                                    value: Exp,
                                                    message: messageIsNotNumber,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.dProximityk4}
                                                    helperText={errors?.dProximityk4?.message}
                                                    label="相間の影響k4"
                                                    type="text"
                                                    size="small"
                                                // InputLabelProps={{ shrink: true }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Controller
                                            control={control}
                                            name="dSkinEffect"
                                            rules={{
                                                pattern: {
                                                    value: Exp,
                                                    message: messageIsNotNumber,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.dSkinEffect}
                                                    helperText={errors?.dSkinEffect?.message}
                                                    label="表皮効果ks"
                                                    type="text"
                                                    size="small"
                                                // InputLabelProps={{ shrink: true }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid mt={0} spacing={2} container direction={"row"}>
                                    <Grid item xs={4}>
                                        <Controller
                                            control={control}
                                            name="ddm"
                                            rules={{
                                                pattern: {
                                                    value: Exp,
                                                    message: messageIsNotNumber,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.ddm}
                                                    helperText={errors?.ddm?.message}
                                                    label="dm"
                                                    type="text"
                                                    size="small"
                                                // InputLabelProps={{ shrink: true }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Controller
                                            control={control}
                                            name="dk3bare"
                                            rules={{
                                                pattern: {
                                                    value: Exp,
                                                    message: messageIsNotNumber,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.dk3bare}
                                                    helperText={errors?.dk3bare?.message}
                                                    label="未塗装の水平時の低減率k3"
                                                    type="text"
                                                    size="small"
                                                // InputLabelProps={{ shrink: true }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Controller
                                            control={control}
                                            name="dk3painted"
                                            rules={{
                                                pattern: {
                                                    value: Exp,
                                                    message: messageIsNotNumber,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.dk3painted}
                                                    helperText={errors?.dk3painted?.message}
                                                    label="塗装済みの水平時の低減率k3"
                                                    type="text"
                                                    size="small"
                                                // InputLabelProps={{ shrink: true }}
                                                />
                                            )}
                                        />
                                    </Grid>

                                </Grid>
                                <Grid mt={0} spacing={2} container direction={"row"}>
                                    <Grid item xs={4}>
                                        <Controller
                                            control={control}
                                            name="nNumber"
                                            rules={{
                                                pattern: {
                                                    value: Exp,
                                                    message: messageIsNotNumber,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.nNumber}
                                                    helperText={errors?.nNumber?.message}
                                                    label="ブスバーの枚数n"
                                                    type="text"
                                                    size="small"
                                                // InputLabelProps={{ shrink: true }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Controller
                                            control={control}
                                            name="nThickness"
                                            rules={{
                                                pattern: {
                                                    value: Exp,
                                                    message: messageIsNotNumber,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.nThickness}
                                                    helperText={errors?.nThickness?.message}
                                                    label="厚さd"
                                                    type="text"
                                                    size="small"
                                                // InputLabelProps={{ shrink: true }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Controller
                                            control={control}
                                            name="nWidth"
                                            rules={{
                                                pattern: {
                                                    value: Exp,
                                                    message: messageIsNotNumber,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.nWidth}
                                                    helperText={errors?.nWidth?.message}
                                                    label="幅b"
                                                    type="text"
                                                    size="small"
                                                // InputLabelProps={{ shrink: true }}
                                                />
                                            )}
                                        />
                                    </Grid>

                                </Grid>
                                <Grid mt={0} spacing={2} container direction={"row"}>
                                    <Grid item xs={12} md={6} >
                                        <Grid mt={0} container direction={"row"}>
                                            <Grid item xs={4}>
                                                <Controller
                                                    name="bIsDefaultN"
                                                    control={control}
                                                    render={({ field }: any) => (
                                                        <FormControlLabel {...field} control={<Checkbox />} checked={field.value} label="デフォルトの枚数" labelPlacement="start" />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Controller
                                                    name="bIsDefaultT"
                                                    control={control}
                                                    render={({ field }: any) => (
                                                        <FormControlLabel {...field} control={<Checkbox />} checked={field.value} label="デフォルトの厚さ" labelPlacement="start" />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Controller
                                                    name="bIsDefaultW"
                                                    control={control}
                                                    render={({ field }: any) => (
                                                        <FormControlLabel {...field} control={<Checkbox />} checked={field.value} label="デフォルトの幅" labelPlacement="start" />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Stack>
                    </div>
                    <Stack direction='row' justifyContent="flex-end" spacing={2} sx={{ mt: 1, mr: 2 }}>
                        <LoadingButton variant="contained" type="submit" loading={loadingFlag}>保存</LoadingButton>
                        <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                    </Stack>
                </form>
            </div>
            <FetchHelper fetchId={UPDATE_EQUIP_BUSBAR}
                onComplete={(success: boolean, data: any, error: any) =>
                    success ? handleSubmitSuccess(success, data, error) : handleErrorUpdate(success, data, error)} />
            <FetchHelper fetchId={CREATE_EQUIP_BUSBAR}
                onComplete={(success: boolean, data: any, error: any) =>
                    success ? handleSubmitSuccess(success, data, error) : handleSubmitError(success, data, error)} />
        </>
    );
}
const mapStateToProps = (state: ApplicationState) => (
    {
        userId: state.app.user?.userId,
        fetchUpdate: selectors.getFormState(
            state,
            // GET_BREAK_CAPACITY
            UPDATE_EQUIP_BUSBAR
        ),
    }
)
const mapDispatchToProps = (dispatch: any) => {
    return {
        onUpdate: (data: any) => dispatch(actionCreators.fetch(UPDATE_EQUIP_BUSBAR, `/master/equip_busbar/modify`, "POST", data, false, true)),
        onCreate: (data: any) => dispatch(actionCreators.fetch(CREATE_EQUIP_BUSBAR, `/master/equip_busbar/add`, "POST", data, false, true)),
        onSuccess: () =>
            dispatch(actionCreators.showMessage({
                type: "info",
                title: "ブスバー更新",
                body: "ブスバーを更新しました。",
            })),
        onError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "検索",
                body: "検索できません。",
            })),
        onCreateError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "ブスバー登録",
                body: "ブスバーを登録出来ませんでした。",
            })),
        onUpdateError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "ブスバー編集",
                body: "ブスバーを更新出来ませんでした。",
            })),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(BreakCapacityEdit as any);