import { CircularProgress, Dialog, DialogContent, DialogTitle } from "@mui/material";
import Konva from "konva";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { Group, Image, Label, Rect, Text, Transformer } from "react-konva";
import { Html } from "react-konva-utils";
import { connect } from "react-redux";
import leftArrow from "../../images/control/blue_arrow.svg";
import lineSvg from "../../images/control/line.svg";
import lineGreenSvg from "../../images/control/line_green.svg";
import lineRedSvg from "../../images/control/line_red.svg";
import rightArrow from "../../images/control/red_arrow.svg";
import imageEarth_1 from "../../images/control/接地＿一般.svg";
import imageEarth_1_Red from "../../images/control/接地＿一般_red.svg";
import imageEarth_1_Green from "../../images/control/接地＿一般_green.svg";
import imageEarth_2 from "../../images/control/接地＿外箱に接続.svg";
import imageEarth_2_Green from "../../images/control/接地＿外箱に接続_green.svg";
import imageEarth_2_Red from "../../images/control/接地＿外箱に接続_red.svg";
import imageEarth_4 from "../../images/control/接地＿保護接地.svg";
import imageEarth_4_Red from "../../images/control/接地＿保護接地_red.svg";
import imageEarth_4_Green from "../../images/control/接地＿保護接地_green.svg";
import imageEarth_3 from "../../images/control/接地＿無雑音接地.svg";
import imageEarth_3_Red from "../../images/control/接地＿無雑音接地_red.svg";
import imageEarth_3_Green from "../../images/control/接地＿無雑音接地_green.svg";
import imageEarth_5 from "../../images/control/接地＿等電位接地.svg";
import imageEarth_5_Red from "../../images/control/接地＿等電位接地_red.svg";
import imageEarth_5_Green from "../../images/control/接地＿等電位接地_green.svg";

import * as Constant from "../../models/Constants";
import { Coordinates, LineInfoModel, MsEarthModel, OptionModel, ProcessMode, UndoModel } from "../../models/Index";
import {
  clearOptionMenu,
  openDialogEvents,
  setRepositionElement,
  setTransformFlag,
  undo,
  updateControlHeight,
  updateElementPosition,
  updatePropertiesOfControlAction,
  updateSelectControl,
} from "../../store/Actions";
import theme from "../../themes/globalStyles";
import { addListToData } from "../../utils/DataConverter";
import { doGetRefV1 } from "../../utils/EARTHFunction";
import { getElementKindValue } from "../../utils/ElementFunction";
import doGetSourceVolt, { doGetTransCenterSourceVolt } from "../../utils/mssv3Doc";
import DraggablePaper from "../common/DraggablePaper";
import OptionMenu from "../contextMenu/OptionMenu";
import MsEarthEditDialog from "../dialogs/MsEarthEditDialog";
import { getSymbolAndBackColor } from "../../utils/drawElement";
import { post } from "../CallApi";
import { calcContextMenuPosition, calcDragBoundPosition, calcElementSize, getMenuOptionHeight } from "../../utils/PositionCalculation";


//#region Props
type CreateMsEarthProps = {
  id: string;
  image: string;
  x: number;
  y: number;
  offsetX: number;
  offsetY: number;
  width: number;
  height: number;
  type: string;
  isSelected: boolean;
  isHidden: boolean;
  rotation: number;
  lineDiagramRef: any;
  parentGroupId: number | null;
  properties: MsEarthModel;
  chart: LineInfoModel[];
  gridSizeController: {
    width: number;
    height: number;
    scale: number;
    stageOffsetX: number;
    stageOffsetY: number;
  };
  isOnShiftKey: boolean;
};

export type PureCreateMsEarthProps = CreateMsEarthProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;
//#endregion

const CONTROL_POS_FACTOR = 1;

const CreateMsEarth = memo((props: PureCreateMsEarthProps) => {
  //#region Fields
  const {
    id,
    image,
    x,
    y,
    offsetX,
    offsetY,
    width,
    height,
    type,
    isSelected,
    isHidden,
    rotation,
    lineDiagramRef,
    parentGroupId,
    processMode,
    properties,
    chart
  } = props;

  const {
    // getElementParam,
    // updateElementPost,
    diagramDataUpdate,
    diagramData,
    projectData,
    gridSizeController,
    currentTabId,
    openDialogData,
    userRole,
    m_bModePM,
    modeViewOnly,
    infoSkeleton,
    userId,
    isSelectTouchGroup,
    clearMenuState,
    isOnShiftKey,
    elementGroups,
    tcGroups,
    elementReposition,
    updateElementPosition,
    setRepositionElement,
    updateSelectControl,
    updateControlData,
    updateControlHeight,
    setTransformFlag,
    openDialogEvents,
    setUndoData,
    clearOptionMenu,
  } = props;

  const [imageStatus, setImageStatus] = useState(image);
  const [lineStatus, setLineStatus] = useState(lineSvg);

  var imageObj = new window.Image();
  imageObj.src = imageStatus;

  var lineObj = new window.Image();
  lineObj.src = lineStatus;

  var leftArrowObj = new window.Image();
  leftArrowObj.src = leftArrow;

  var rightArrowObj = new window.Image();
  rightArrowObj.src = rightArrow;
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [menuPosition, setMenuPosition] = useState<Coordinates>({ x: 0, y: 0 });
  const [isOpenEditorDialog, setIsOpenEditorDialog] = useState(false);
  const [groupPosition, setGroupPosition] = useState({ x: x, y: y });
  const [dragStartPosition, setDragStartPosition] = useState({ x: x, y: y, labelX: groupPosition.x + Constant.ELEMENT_SIZE, labelY: groupPosition.y + CONTROL_POS_FACTOR * (height / 2) });
  const [isResizing, setIsResizing] = useState(false);
  const [heightTransform, setHeightTransform] = useState(0);
  const [labelPosition, setLabelPosition] = useState({
    x: groupPosition.x + Constant.ELEMENT_SIZE,
    y: groupPosition.y + CONTROL_POS_FACTOR * (height / 2),
  });
  const transformRef = useRef<Konva.Transformer>(null);
  const transformMoveRef = useRef<Konva.Transformer>(null);
  const groupRef = useRef<any>();
  const topRef = useRef<any>();
  const bottomRef = useRef<any>();
  const labelRef = useRef<any>();

  const [initialValue, setInitialValue] = useState(properties);
  const [loadingFlag, setLoadingFlag] = useState(false);
  const [isOnOpenMenu, setIsOnOpenMenu] = useState<boolean>(false);

  // const labelTexts = doGetRefV1(initialValue);
  const [labelTexts, setLabelTexts] = useState<any>([])

  //#endregion

  //#region useEffect
  useEffect(() => {
    setLabelTexts(doGetRefV1(initialValue))
  }, [diagramDataUpdate])

  useEffect(()=>{
    if(isSelected){
      if(isOnOpenMenu) {
        setIsOnOpenMenu(false)
      }
      else
      {
        setIsOpenMenu(false)
      }
    }
  },[clearMenuState])

  useEffect(()=>{
    if(elementReposition?.id)
    {
      const groupsData = currentTabId === 1 ? elementGroups : tcGroups[diagramData.find((x:any)=>x.id == id)?.parentTcId ?? 0]
      const peekGroupId = groupsData?.byEleId[elementReposition.id as any]?.at(-1) ?? -1
      const elementIds = groupsData?.byId[peekGroupId]?.elementIds ?? []
      if(elementIds.includes(id) || diagramData[0].shape.find((item:any)=>(item.isSelected && item.id == id)))
      {
        setGroupPosition({
          x: dragStartPosition.x,
          y: dragStartPosition.y,
        });

        labelRef.current.to({
          x: dragStartPosition.labelX,
          y: dragStartPosition.labelY,
        });
        
        groupRef.current.to({
          x: dragStartPosition.x,
          y: dragStartPosition.y,
        });

        updateElementPosition(id, dragStartPosition.x, dragStartPosition.y)
      }
    }
  },[elementReposition])

  useEffect(() => {
    setIsOpenMenu(false)
  }, [processMode])

  useEffect(() => {
    setInitialValue(properties);
  },[properties])

  useEffect(() => {
    if (isSelected && !parentGroupId) {
      transformRef.current?.nodes([groupRef.current]);
    } else {
      transformRef.current?.nodes([]);
    }
    transformRef.current?.getLayer()?.batchDraw();
  }, [isSelected, parentGroupId, heightTransform]);

  useEffect(() => {
    if (!isSelected) setIsOpenMenu(false);
  }, [isSelected]);
  
  useEffect(() => {
    if(openDialogData?.id === id && openDialogData !== undefined) {
      openDialogEvents(({type: "OPEN_DIALOG", data: { }}))
      handleOpenEditorDialog()
    }
  }, [openDialogData]);

  // useEffect(()=>{
  //     switch (properties.symKind.toString()) {
  //       case "0":
  //           setImageStatus(imageEarth_1);
  //           break;
  //       case "1":
  //           setImageStatus(imageEarth_2);
  //           break;
  //       case "2":
  //           setImageStatus(imageEarth_3);
  //           break;
  //       case "3":
  //           setImageStatus(imageEarth_4);
  //           break;
  //       case "4":
  //           setImageStatus(imageEarth_5);
  //           break;
  //       }
  // },[properties.symKind])

  useEffect(() => {
    // check grid size; label, icon position when rotate, move and change height
    // handleGridSize(groupPosition.x, groupPosition.y, height, rotation);
    calcLabelPos();
    calcArrowPos();
  }, [groupPosition, height, rotation]);
  
  useEffect(() => {
    switch(getSymbolAndBackColor(infoSkeleton,processMode,properties).symbolColor){
      case "#008040":
        setLineStatus(lineGreenSvg);
        if(initialValue.symKind == 0){
          setImageStatus(imageEarth_1_Green);
        }
        else if(initialValue.symKind == 1){
          setImageStatus(imageEarth_2_Green);
        }
        else if(initialValue.symKind == 2){
          setImageStatus(imageEarth_3_Green);
        }
        else if(initialValue.symKind == 3){
          setImageStatus(imageEarth_4_Green);
        }
        else if(initialValue.symKind == 4){
          setImageStatus(imageEarth_5_Green);
        }
        break;
      case "#FF0000":
        setLineStatus(lineRedSvg);
        if(initialValue.symKind == 0){
          setImageStatus(imageEarth_1_Red);
        }
        else if(initialValue.symKind == 1){
          setImageStatus(imageEarth_2_Red);
        }
        else if(initialValue.symKind == 2){
          setImageStatus(imageEarth_3_Red);
        }
        else if(initialValue.symKind == 3){
          setImageStatus(imageEarth_4_Red);
        }
        else if(initialValue.symKind == 4){
          setImageStatus(imageEarth_5_Red);
        }
        break;
      default:
        setLineStatus(lineSvg);
        if(initialValue.symKind == 0){
          setImageStatus(imageEarth_1);
        }
        else if(initialValue.symKind == 1){
          setImageStatus(imageEarth_2);
        }
        else if(initialValue.symKind == 2){
          setImageStatus(imageEarth_3);
        }
        else if(initialValue.symKind == 3){
          setImageStatus(imageEarth_4);
        }
        else if(initialValue.symKind == 4){
          setImageStatus(imageEarth_5);
        }
        break;
    }
  },[getSymbolAndBackColor(infoSkeleton,processMode,initialValue).symbolColor,initialValue.symKind])
  //#endregion

  //#region Method
  const handleContextMenu = (e: any) => {
    e.evt.preventDefault();
    if(e.evt.shiftKey) return
    setIsOnOpenMenu(true)
    updateSelectControl(id);

    const menuHeight = getMenuOptionHeight()
    const clientWidth = lineDiagramRef.current.clientWidth
    const isTransCenter = currentTabId == 1 ? false : true;
    let {posX, posY} = calcContextMenuPosition(
      e, menuHeight, clientWidth, x, y, gridSizeController, isTransCenter
    )
    clearOptionMenu(!clearMenuState)
    
    setMenuPosition({
      x: posX,
      y: posY,
    });
    setIsOpenMenu(true);
  };

  const handleClick = (e: any) => {
    if (e.type === "click") setIsOpenMenu(false);
    if(parentGroupId && parentGroupId > 0) return;
    if (e.evt.shiftKey || isSelectTouchGroup) {
      updateSelectControl(id, true);
    } else {
      updateSelectControl(id);
    }
  };

  const handleOpenMenu = (value: boolean) => {
    setIsOpenMenu(value);
    updateSelectControl(id);
  };

  const handleOpenEditorDialog = async () => {
    let params ={
      userId: userId,
      projectId: projectData.projectId,
      elementId: id,
      elementType: type,
      data: properties,
      ownerProject: projectData.ownerId

    };
    setLoadingFlag(true);
    // getElementParam(params, id);
    const result = await post("/diagram/get-param", params);
    if (result.success) {
      handleGetSuccess(result.data);
    } else {
      handleSubmitError(result.error);
    }
  };

  const handleCancelEditorDialog = () => {
    setIsOpenEditorDialog(false);
  };
  // updateElementPostのAPI呼び出し成功時
  const handleUpdateSuccess = (data: any) => {
  console.log('>>>> handle Successs');
  setLoadingFlag(false);
}
// updateElementPostのAPI呼び出しエラー時
  const handleSubmitError = (error: any) => {
  console.log('>>>> handle Error');
  console.log(error);
  setLoadingFlag(false);
}
  const handleUpdate = async (data: any) => {
    const updateData = {
      ...data,
    };
    setIsOpenEditorDialog(false);
    // call api
    const request = { 
      userId: userId,
      projectId: projectData.projectId,
      elementType: getElementKindValue(props.type),
      elementId: id,
      param: {...updateData},
      ownerProject: projectData.ownerId

    }
    // updateElementPost(request, id)
    setLoadingFlag(true);
    const result = await post("/diagram/set-param", request);
    if (result.success) {
      handleUpdateSuccess(result.data);
      if(result.data && result.data.resultCode == 0){
        setUndoData({type:"UPDATE_PROPERTIES_ELEMENT", dataUndo:{}} as UndoModel);
        updateControlData(id, updateData);
        setInitialValue(data);  
      }
    } else {
      handleSubmitError(result.error);
    }

    openDialogEvents({type: "RELOAD", data: { }});
  };

  const dragBoundFunc = (e: any, mode: string) => {
    // let rect = groupRef.current.getClientRect();
    const newPosition = calcDragBoundPosition(
      e, groupPosition, rotation, height, mode, gridSizeController
    );

    return {
      x: newPosition.newX,
      y: newPosition.newY,
    };
  };

  const calcLabelPos = (e?: any) => {
    let newX = groupPosition.x,
      newY = groupPosition.y;
    if (e?.type === "dragmove") {
      newX = groupRef.current.attrs.x;
      newY = groupRef.current.attrs.y;
    }

    const newPosition = {
      x: newX + Constant.ELEMENT_SIZE,
      y: newY + CONTROL_POS_FACTOR * (height / 2),
    };

    if (rotation !== 0) {
      newPosition.x = newX + CONTROL_POS_FACTOR * (height / 2) - 5;
      newPosition.y = newY - Constant.ELEMENT_SIZE;
    }

    setLabelPosition(newPosition);
  };

  const calcArrowPos = (e?: any) => {
    let newX = groupPosition.x,
      newY = groupPosition.y;
    if (e?.type === "dragmove") {
      newX = groupRef.current.attrs.x;
      newY = groupRef.current.attrs.y;
    }

    const newPosition = {
      x: newX,
      y: newY + height - Constant.ELEMENT_SIZE,
    };

    if (rotation !== 0) {
      newPosition.y = newY;
      rotation === -90 &&
        (newPosition.x = newX + height - Constant.ELEMENT_SIZE);
      rotation === 90 && (newPosition.x = newX);
    }
  };

  const handleDragStart = (e: any) => {
    groupRef.current.moveToTop()
    transformRef.current?.moveToTop()
    labelRef.current?.moveToTop()
    setDragStartPosition({x: groupPosition.x, y: groupPosition.y, labelX: labelPosition.x, labelY: labelPosition.y});
    setIsOpenMenu(false);
  }

  const handleDragmove = (e: any) => {
    calcLabelPos(e);
    calcArrowPos(e);
  };

  const onDragEnd = (e: any) => {
    if (
      e.currentTarget.attrs.x === groupPosition.x &&
      e.currentTarget.attrs.y === groupPosition.y
    ) {
      return;
    }

    let newX =
      Math.round(e.target.x() / Constant.POINTER_WIDTH) *
      Constant.POINTER_WIDTH;
    let newY =
      Math.round(e.target.y() / Constant.POINTER_HEIGHT) *
      Constant.POINTER_HEIGHT;
         
    // check new position is outside grid
    if (newX < 0 || newY < 0){
      setRepositionElement(id, !(elementReposition?.state??true))
      newX = dragStartPosition.x;
      newY = dragStartPosition.y;
    }
    
    setGroupPosition({
      x: newX,
      y: newY,
    });

    e.target.to({
      x: newX,
      y: newY,
    });
  };

  // Mobile Touch Events
  var isMoving = false
  var isLongPress = false
  
  const handleTouchStart = useCallback((e:any) => {
    setIsOpenMenu(false)
    isLongPress = true
    isMoving = false
    !isSelectTouchGroup && setTimeout(() => {
      if (isLongPress && !isMoving) {
        handleContextMenu(e)
      }
    }, 1000)
  },[x,y,isSelectTouchGroup])

  const handleTouchMove = useCallback(() => {
    isMoving = true
  },[x,y,isSelectTouchGroup])

  const handleTouchEnd = useCallback((e:any) => {
    isLongPress = false
  },[x,y,isSelectTouchGroup])

  const handleMouseDown = (e: any, mode: string) => {
    const container = e.target.getStage().container();
    container.style.cursor = rotation === 0 ? "ns-resize" : "ew-resize";
    setIsResizing(true);
  };

  const handleMouseMove = (e: any, mode: string) => {
    if (!isResizing) return;
    setHeightTransform(e.evt.movementY);
  };

  const handleMouseUp = (e: any, mode: string) => {
    setTransformFlag(true);
    const container = e.target.getStage().container();
    container.style.cursor = "default";

    let newPosX = e.target._lastPos.x // e.evt.offsetX
    let newPosY = e.target._lastPos.y // e.evt.offsetY

    let newEle = calcElementSize(
      newPosX,
      newPosY,
      groupPosition,
      rotation,
      height,
      offsetY,
      mode,
      gridSizeController
    )

    topRef.current.y(0);
    // bottomRef.current.y(newHeight - 7)
    setGroupPosition({ x: newEle.newX, y: newEle.newY });

    updateControlHeight(id, newEle.newHeight, newEle.newX, newEle.newY, newEle.newOffsetY);
    setIsResizing(false);
  };

  const convertDataFromBE = (BEData : any) =>{
    let voltageList: OptionModel[] = [];
    if (BEData.voltageList && BEData.voltageList.length > 0){
      BEData.voltageList.forEach((element: any) => {
        if(element.lvoltage !== null){
          let optionModel: OptionModel = {
              label: element.lvoltage.toString(),
              value: element.lvoltage
          };
          voltageList.push(optionModel)
        }
      });

  }
    return {
      voltageList: voltageList
  }
  }
  
  const handleGetSuccess = (data: any) => {
    let newData = data.data
    let initData = {...initialValue}
    
    initData = addListToData(initData, newData)
    let convertData = convertDataFromBE(initData)
    initData.voltageList = convertData.voltageList
    const makerNameList:any = [];
    newData.makerNameList?.forEach((item:any)=>{
        makerNameList.push({label:item.smanuName.toString(),
            value:item.smanuName} as OptionModel)
    })
    const makeYearList:any = [];
    newData.makeYearList?.forEach((item:any)=>{
        makeYearList.push({label:item.toString(),
            value:item} as OptionModel)
    })
    const makeMonthList:any = [];
    newData.makeMonthList?.forEach((item:any)=>{
        makeMonthList.push({label:item.toString(),
            value:item} as OptionModel)
    })
    initData.makerNameList = makerNameList
    initData.makeMonthList = makeMonthList
    initData.makeYearList = makeYearList

    let shapes = diagramData[0].shape
    //Transcenter source voltage
    let result ={} as any 
    if(currentTabId !== 1){
      //inside transcenter
      result = doGetTransCenterSourceVolt(
      shapes,
      {
        id: id,
        image: image,
        x: x,
        y: y,
        offsetX: offsetX,
        offsetY: offsetY,
        width: width,
        height: height,
        type: type,
        isSelected: isSelected,
        rotation: rotation,
        chart: chart,
        isRotationEnabled: false,
        parentGroupId,
        properties: properties
      },
      Constant.GET_TOP_BOTTOM
    )
    }
    else{
      //outside transcenter
      result = doGetSourceVolt(
      {
        id: id,
        image: image,
        x: x,
        y: y,
        offsetX: offsetX,
        offsetY: offsetY,
        width: width,
        height: height,
        type: type,
        isSelected: isSelected,
        rotation: rotation,
        chart: chart,
        isRotationEnabled: false,
        parentGroupId,
        properties: properties
      },
      shapes,
      Constant.GET_TOP_BOTTOM

    )
    }
    if(result.voltage > 0){
      initData.isSourceVoltage = true
    }
    else{
      initData.isSourceVoltage = false
    }
    setInitialValue(initData)
    setLoadingFlag(false)
    setIsOpenEditorDialog(true)
  }
  //#endregion

  return (
    <>
      {isOpenMenu &&
        <OptionMenu
          controlId={id}
          isOpenMenu={isOpenMenu}
          setIsOpenMenu={handleOpenMenu}
          onProperties={handleOpenEditorDialog}
          x={menuPosition.x}
          y={menuPosition.y}
          isDisabledDelete={false}
          isDisabledCut={false}
          isDisabledCopy={false}
          isDisabledPaste={false}
          isDisabledOpenGraph={false}
          isDisabledProps={false}
          isDisabledUserCurve={true}
        />
      }

      <Label
        ref={labelRef}
        listening={false}
        width={Constant.LABEL_WIDTH}
        x={labelPosition.x}
        y={labelPosition.y}
        offsetX={[90, -90].includes(rotation) ? 8 : -5}
        offsetY={
          rotation === 0 ? (labelTexts.length * Constant.LINE_HEIGHT) / 2 : 0
        }
      >
        {labelTexts.length > 0 &&
         labelTexts.map((item:any, index:any) => {
            
          let content = item.value; 
          let offsetY = index * Constant.LINE_HEIGHT;
          rotation !== 0 &&
            index !== 0 &&
            (offsetY = (index - 1) * Constant.LINE_HEIGHT + width + Constant.ELEMENT_SIZE);

          return (
            <Text
              y={offsetY}
              key={index}
              text={content}
              fill={isSelected ? "red" : item.color}
            />
          );
        })}
      </Label>
      <Group
        ref={groupRef}
        id={id}
        x={groupPosition.x}
        y={groupPosition.y}
        offset={{ x: offsetX, y: offsetY }}
        width={width}
        height={height}
        draggable={processMode === ProcessMode.DRAWING && !m_bModePM && !modeViewOnly && !isOnShiftKey}
        type={type}
        onClick={handleClick}
        onTap={handleClick}
        onContextMenu={handleContextMenu}
        rotation={rotation}
        onDblClick={handleOpenEditorDialog}
        onDblTap={handleOpenEditorDialog}
        onMouseDown={() => transformMoveRef.current?.nodes([groupRef.current])}
        onMouseUp={() => transformMoveRef.current?.nodes([])}
        onDragStart={handleDragStart}
        onDragMove={handleDragmove}
        onDragEnd={onDragEnd}
        onMouseLeave={(e: any) => {
          const container = e.target.getStage().container();
          container.style.cursor = "default";
        }}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <Image
          fill={getSymbolAndBackColor(infoSkeleton,processMode,properties).backColor}
          image={lineObj}
          height={height}
          onMouseEnter={(e: any) => {
            const container = e.target.getStage().container();
            container.style.cursor = (processMode === ProcessMode.DRAWING && !m_bModePM && !modeViewOnly) ? "move" : "crosshair";
          }}
        />
        {
            initialValue.symKind == 1 ?
            <Image
                fill={getSymbolAndBackColor(infoSkeleton,processMode,initialValue).backColor}
                image={imageObj}
                width={Constant.ELEMENT_SIZE}
                height={Constant.ELEMENT_SIZE}
                y={height -Constant.ELEMENT_SIZE}
                x={-1.5}
                onMouseEnter={(e: any) => {
                    const container = e.target.getStage().container();
                    container.style.cursor = (processMode === ProcessMode.DRAWING && !m_bModePM && !modeViewOnly) ? "move" : "crosshair";
                }}
            />
            :
            <Image
                fill={getSymbolAndBackColor(infoSkeleton,processMode,initialValue).backColor}
                image={imageObj}
                width={Constant.ELEMENT_SIZE}
                height={Constant.ELEMENT_SIZE}
                y={height -Constant.ELEMENT_SIZE}
                onMouseEnter={(e: any) => {
                    const container = e.target.getStage().container();
                    container.style.cursor = (processMode === ProcessMode.DRAWING && !m_bModePM && !modeViewOnly) ? "move" : "crosshair";
                }}
            />
        }
        
        {processMode === ProcessMode.DRAWING && !m_bModePM && !modeViewOnly && isSelected && !parentGroupId && (
          <>
            <Rect
              ref={topRef}
              fill="black"
              x={6.75}
              y={0}
              width={7}
              height={7}
              draggable
              onMouseDown={() => setTransformFlag(false)}
              dragBoundFunc={(e: any) => dragBoundFunc(e, "top")}
              onDragStart={(e: any) => handleMouseDown(e, "top")}
              onDragMove={(e: any) => handleMouseMove(e, "top")}
              onDragEnd={(e: any) => handleMouseUp(e, "top")}
              onMouseEnter={(e: any) => {
                const container = e.target.getStage().container();
                container.style.cursor = "ns-resize";
              }}
            />
            <Rect
              ref={bottomRef}
              fill="black"
              x={6.75}
              y={height - 7}
              width={7}
              height={7}
              draggable
              onMouseDown={() => setTransformFlag(false)}
              dragBoundFunc={(e: any) => dragBoundFunc(e, "bottom")}
              onDragStart={(e: any) => handleMouseDown(e, "bottom")}
              onDragMove={(e: any) => handleMouseMove(e, "bottom")}
              onDragEnd={(e: any) => handleMouseUp(e, "bottom")}
              onMouseEnter={(e: any) => {
                const container = e.target.getStage().container();
                container.style.cursor = "ns-resize";
              }}
            />
          </>
        )}
      </Group>

      <Transformer
        ref={transformRef}
        rotateEnabled={false}
        resizeEnabled={false}
        keepRatio={false}
        borderDash={[4]}
        anchorStroke="black"
        borderStroke="black"
        anchorFill="black"
      />

      <Transformer
        ref={transformMoveRef}
        rotateEnabled={false}
        resizeEnabled={false}
        keepRatio={false}
        borderEnabled={false}
        anchorStroke="black"
        borderStroke="black"
        anchorFill="black"
      />

      {isOpenEditorDialog && !isHidden && (
        <Html>
          <Dialog open={true} fullWidth PaperComponent={DraggablePaper}>
            <DialogTitle
              onMouseOver={(e : any) => e.target.style.cursor = 'move'}
              style={{
                paddingTop: "2px",
                paddingBottom: "2px",
                background: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
              }}
            >
              接地
            </DialogTitle>
            <DialogContent>
              <MsEarthEditDialog
                data={initialValue}
                onCancel={handleCancelEditorDialog}
                onOK={handleUpdate}
                userRole={userRole}
                m_bModePM={m_bModePM}
                modeViewOnly={modeViewOnly}
              ></MsEarthEditDialog>
            </DialogContent>
          </Dialog>
        </Html>
      )}
      {loadingFlag && (
        <Html>
            <Dialog open={true} maxWidth={"md"} fullWidth>
                <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1500, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                    <CircularProgress />
                </div>
            </Dialog>
        </Html>
      )}
    </>
  );
});

const mapStateToProps = (state: any) => {
  return {
    userId: state.app.user?.userId,
    projectData: state.app.projectData,
    diagramData: state.app.diagram.diagramData.filter((r: any) => r.tabId === state.app.diagram.currentIDDiagramTab),
    processMode: state.app.diagram.processMode,
    currentTabId: state.app.diagram.currentIDDiagramTab,
    openDialogData : state.app.diagram.openDialog["OPEN_DIALOG"],
    userRole: state.app.user?.userRole,
    m_bModePM: state.app.diagram.m_bModePM,
    modeViewOnly: state.app.diagram.modeViewOnly,
    infoSkeleton:state.app.diagram.infoSkeleton,
    clearMenuState: state.app.diagram.clearOptionMenu,
    diagramDataUpdate: state.app.diagram.diagramDataUpdate,
    isSelectTouchGroup: state.app.diagram.isSelectTouchGroup,
    tcGroups: state.app.diagram.tcGroups,
    elementGroups: state.app.diagram.elementGroups,
    elementReposition: state.app.diagram.elementReposition,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    updateSelectControl: (controlId: string, isMultiple : boolean = false) =>
dispatch(updateSelectControl(controlId, isMultiple)),
    updateControlData: (id: string, data: object) =>
      dispatch(updatePropertiesOfControlAction(id, data)),
    updateControlHeight: (
      id: string,
      height: number,
      x: number,
      y: number,
      offsetY: number
    ) => dispatch(updateControlHeight(id, height, x, y, offsetY)),
    setTransformFlag: (visible: boolean) => dispatch(setTransformFlag(visible)),
    // updateElementPost: (data: any, controlId: string) => dispatch(actionCreators.fetch(`UPDATE_EARTH${controlId}`, "/diagram/set-param", "POST", data, false, true)),
    // getElementParam: (data: any, controlId: string) => dispatch(actionCreators.fetch(`GET_EARTH${controlId}`, "/diagram/get-param", "POST", data, false, true)),
    openDialogEvents: (param : any) => dispatch(openDialogEvents(param)),
    setUndoData: (data:UndoModel) => dispatch(undo(data)),
    clearOptionMenu: (state:boolean) => dispatch(clearOptionMenu(state)),
    setRepositionElement: (id:any, state:boolean) => dispatch(setRepositionElement(id, state)),
    updateElementPosition:(id:any, x:any, y:any) => dispatch(updateElementPosition(id, x, y)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateMsEarth);