import { LoadingButton } from "@mui/lab";
import { Autocomplete, Box, Button, Checkbox, CircularProgress, FormControlLabel, Grid, InputLabel, Stack, Tab, Tabs, TextField, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BreakElementModel, CurveStandardModel, DialCharacterModel, DialCurrentModel, DialMagniCurrentModel, DialMagniTimeModel, DialTimeModel } from "../../../models/breakModel";
import { Path as RoutePath } from "../../../path";
import { ApplicationState, actionCreators, selectors } from "../../../store";
import FetchHelper from "../../FetchHelper";
import AlertDialog from "../../common/AlertDialog";
import DialMagniTimeGrid from "../DialMagniTime/DialMagniTimeGrid";
import DialCurrentGrid from "../DialCurrent/DialCurrentGrid";
import DialTimeGrid from "../DialTime/DialTimeGrid";
import DialMagniCurrentGrid from "../DialMagniCurrent/DialMagniCurrentGrid";
import CurveStandardGrid from "../CurveStandard/CurveStandardGrid";

// -------------------------
// FETCH ID
const GET_DIAL_CHARACTER = "GET_DIAL_CHARACTER";
const CREATE_DIAL_CHARACTER = "CREATE_DIAL_CHARACTER";
const UPDATE_DIAL_CHARACTER = "UPDATE_DIAL_CHARACTER";
const GET_DIAL_CHARACTER_BY_ID = "GET_DIAL_CHARACTER_BY_ID";
const DELETE_CURVE_STANDARD = "DELETE_CURVE_STANDARD";
const DELETE_DIAL_CURRENT = "DELETE_DIAL_CURRENT";
const DELETE_DIAL_TIME = "DELETE_DIAL_TIME;"
const DELETE_DIAL_MAGNI_CURRENT = "DELETE_DIAL_MAGNI_CURRENT";
const DELETE_DIAL_MAGNI_TIME = "DELETE_DIAL_MAGNI_TIME";
// 初期値設定
const initialValue: any = {
    lDialCharID: 0,
    mv3breakBreakElement: {
    } as BreakElementModel,
    bIsDefault: false,
    sCharacterName: "",
    nCurrentFlag: null,
    bIsLink: false,
    nLinkNumber: "",
    bIsCurrent: false,
    bDispCurrentMulti: false,
    sCurrentTitle: "",
    sCurrentUnit: "",
    sCurrentNotes: "",
    nMagniCurrentFlag: null,
    sMagniCurrentTitle: "",
    sMagniCurrentUnit: "",
    sMagniCurrentNotes: "",
    bIsNoneSadamu: false,
    nTimeFlag: null,
    bUseLock: false,
    bUseTimer: false,
    bMCR: false,
    nMagniTimeFlag: null,
    sMagniTimeNotes: "",
    sMagniTimeTitle: "",
    sMagniTimeUnit: "",
    sTimeNotes: "",
    sTimeTitle: "",
    sTimeUnit: "",
}

const flagOptions = [
    {
        label: "未使用",
        value: 1
    },
    {
        label: "固定可変",
        value: 2
    },
    {
        label: "連続可変",
        value: 3
    },
]
// -------------
//Tabs------------------
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
function a11yProps(index: number) {
    return {
        "id": `oilManagement-tab-${index}`,
        "aria-controls": `oilManagement-tabpanel-${index}`
    };
}
// ----------------
// Props
export type DialCharacterEditProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
export const DialCharacterEdit = (props: DialCharacterEditProps) => {
    const {
        userId,
        fetchUpdate,
        onCreate,
        onUpdate,
        onSuccess, onError,
        onDeleteCurveStandard,
        onDeleteDialCurrent,
        onDeleteDialTime, onSuccessDeleteDialDialTime, onErrorDeleteDialDialTime,
        onDeleteDialMagniCurrent, onSuccessDeleteDialMagniCurrent, onErrorDeleteDialMagniCurrent,
        onDeleteDialMagniTime, onErrorDeleteDialMagniTime, onSuccessDeleteDialMagniTime,
        onSearch, onSearchById,
    } = props;
    const { control, handleSubmit, formState: { errors }, setValue, getValues } = useForm<any>({
        criteriaMode: "all",
        mode: "all",
        defaultValues: initialValue
    })
    type MyParams = {
        id: string;
        mode: string;
    };

    let { id, mode } = useParams<keyof MyParams>() as MyParams;
    const location = useLocation();

    let lElementID = location.state ? location.state.lelementID : undefined;
    let sElementName = location.state ? location.state.selementName : "";
    let navigate = useNavigate();
    const [selectTab, setSelectTab] = useState(0);
    const [loadingFlag, setLoadingFlag] = useState(mode === "edit" ? true : false);
    const gridRef = useRef() as any;
    const gridCurveStandardRef = useRef() as any;
    const gridDialCurrentRef = useRef() as any;
    const gridDialTimeRef = useRef() as any;
    const gridDialMagniCurrent = useRef() as any;
    const gridDialMagniTime = useRef() as any;
    const [colState, setColState] = useState<any>();
    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");
    const [openedDeleteDialog, setOpenedDeleteDialog] = useState<boolean>(false);
    const [mv3breakCurveStandardList, setMv3breakCurveStandardList] = useState<CurveStandardModel[]>([]);
    const [mv3breakDialCurrrentList, setMv3breakDialCurrrentList] = useState<DialCurrentModel[]>([]);
    const [mv3breakDialTimeList, setmv3breakDialTimeList] = useState<DialTimeModel[]>([]);
    const [mv3breakDialMagniCurrentList, setMv3breakDialMagniCurrent] = useState<DialMagniCurrentModel[]>([]);
    const [mv3breakDialMagnitimeList, setMv3breakDialMagnitimeList] = useState<DialMagniTimeModel[]>([]);
    const [idDelete, setIdDelete] = useState<string>("");
    let tab = location.state && location.state.tab;

    const handleSuccess = (data: any) => {
        console.log('>>>> handle Successs');
        if(data && data.resultCode == 0){
            setValue("lDialCharID", data.data.ldialCharID);
            setValue("mv3breakBreakElement.lElementID", data.data.mv3breakBreakElement.lelementID);
            setValue("mv3breakBreakElement.sElementName", data.data.mv3breakBreakElement.selementName);    
            setValue("sCharacterName", data.data.scharacterName);
            setValue("nLinkNumber", data.data.nlinkNumber);    
            // Title
            setValue("sCurrentTitle", data.data.scurrentTitle);
            setValue("sTimeTitle", data.data.stimeTitle);
            setValue("sMagniCurrentTitle", data.data.smagniCurrentTitle);
            setValue("sMagniTimeTitle", data.data.smagniTimeTitle);
    
            // Unit
            setValue("sCurrentUnit", data.data.scurrentUnit);
            setValue("sTimeUnit", data.data.stimeUnit);
            setValue("sMagniCurrentUnit", data.data.smagniCurrentUnit);
            setValue("sMagniTimeUnit", data.data.smagniTimeUnit);
            //Notes
            setValue("sCurrentNotes", data.data.scurrentNotes);
            setValue("sTimeNotes", data.data.stimeNotes);
            setValue("sMagniCurrentNotes", data.data.smagniCurrentNotes);
            setValue("sMagniTimeNotes", data.data.smagniTimeNotes);
            // Flag
            if(data.data.ncurrentFlag){
                const object = flagOptions.find((item:any) => item.value == data.data.ncurrentFlag)
                setValue("nCurrentFlag", object);
            }else{
                setValue("nCurrentFlag", null);
            }

            if(data.data.ntimeFlag){
                const object = flagOptions.find((item:any) => item.value == data.data.ntimeFlag)
                setValue("nTimeFlag", object);
            }else{
                setValue("nTimeFlag", null);
            }

            if(data.data.nmagniCurrentFlag){
                const object = flagOptions.find((item:any) => item.value == data.data.nmagniCurrentFlag)
                setValue("nMagniCurrentFlag", object);
            }else{
                setValue("nMagniCurrentFlag", null);
            }

            if(data.data.nmagniTimeFlag){
                const object = flagOptions.find((item:any) => item.value == data.data.nmagniTimeFlag)
                setValue("nMagniTimeFlag", object);
            }else{
                setValue("nMagniTimeFlag", null);
            }
            // Checkbox
            setValue("bIsDefault", data.data.bisDefault);
            setValue("bUseLock", data.data.buseLock);
            setValue("bUseTimer", data.data.buseTimer);
            setValue("bMCR", data.data.bmCR);
            setValue("bIsLink", data.data.bisLink);
            setValue("bIsCurrent", data.data.bisCurrent);
            setValue("bIsNoneSadamu", data.data.bisNoneSadamu);
            setValue("bDispCurrentMulti", data.data.bdispCurrentMulti);
            // Add to grid
            if (data.mv3breakCurveStandardList && data.mv3breakCurveStandardList.length > 0) {
                setMv3breakCurveStandardList(data.mv3breakCurveStandardList);
            }
            if (data.mv3breakDialCurrentList.length > 0) {
                setMv3breakDialCurrrentList(data.mv3breakDialCurrentList);
            }
            if (data.mv3breakDialTimeList.length > 0) {
                setmv3breakDialTimeList(data.mv3breakDialTimeList);
            }
            if (data.mv3breakDialMagniCurrentList.length > 0) {
                setMv3breakDialMagniCurrent(data.mv3breakDialMagniCurrentList);
            }
            if (data.mv3breakDialMagniTimeList.length > 0) {
                setMv3breakDialMagnitimeList(data.mv3breakDialMagniTimeList);
            } 
        }
        if (tab !== undefined && tab !== null) {
            setSelectTab(tab)
        }
        setLoadingFlag(false);
    }

    // 検索エラー時
    const handleError = (success: boolean, data: any, error: any) => {
        console.log('>>>> handle Error');
        gridRef.current.hideOverlay();
    }

    useEffect(() => {
        if (mode === "add")
            setValue("mv3breakBreakElement.sElementName", sElementName);
    }, [])

    const tabHandleChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectTab(newValue);
        navigate(".", {
            replace: true,
            state: {
                tab: newValue,
                lelementID: lElementID,
                selementName: sElementName
            }
        });
    }

    // 検索実行
    const handleEditSubmit = (data: DialCharacterModel) => {
        setLoadingFlag(true);
        if (mode === "edit") {
            let request = {
                lDialCharID: getValues("lDialCharID"),
                bIsDefault: getValues("bIsDefault"),
                sCharacterName: getValues("sCharacterName"),
                nCurrentFlag: getValues("nCurrentFlag") ? getValues("nCurrentFlag").value : null,
                bIsLink: getValues("bIsLink"),
                nLinkNumber: getValues("nLinkNumber"),
                bIsCurrent: getValues("bIsCurrent"),
                bDispCurrentMulti: getValues("bDispCurrentMulti"),
                sCurrentTitle: getValues("sCurrentTitle"),
                sCurrentUnit: getValues("sCurrentUnit"),
                sCurrentNotes: getValues("sCurrentNotes"),
                nMagniCurrentFlag: getValues("nMagniCurrentFlag") ? getValues("nMagniCurrentFlag").value : null,
                sMagniCurrentTitle: getValues("sMagniCurrentTitle"),
                sMagniCurrentUnit: getValues("sMagniCurrentUnit"),
                sMagniCurrentNotes: getValues("sMagniCurrentNotes"),
                bIsNoneSadamu: getValues("bIsNoneSadamu"),
                nTimeFlag: getValues("nTimeFlag") ? getValues("nTimeFlag").value : null,
                bUseLock: getValues("bUseLock"),
                bUseTimer: getValues("bUseTimer"),
                bMCR: getValues("bMCR"),
                nMagniTimeFlag: getValues("nMagniTimeFlag") ? getValues("nMagniTimeFlag").value : null,
                sMagniTimeNotes: getValues("sMagniTimeNotes"),
                sMagniTimeTitle: getValues("sMagniTimeTitle"),
                sMagniTimeUnit: getValues("sMagniTimeUnit"),
                sTimeNotes: getValues("sTimeNotes"),
                sTimeTitle: getValues("sTimeTitle"),
                sTimeUnit: getValues("sTimeUnit"),
            }
            onUpdate(id, {
                "userId": userId,
                "requestPrms": request
            });
        } else if (mode === "add") {
            let request = {
                lDialCharID: getValues("lDialCharID"),
                lElementID: lElementID ?? getValues("mv3breakBreakElement.lElementID"),
                bIsDefault: getValues("bIsDefault"),
                sCharacterName: getValues("sCharacterName"),
                nCurrentFlag: getValues("nCurrentFlag") ? getValues("nCurrentFlag").value : null,
                bIsLink: getValues("bIsLink"),
                nLinkNumber: getValues("nLinkNumber"),
                bIsCurrent: getValues("bIsCurrent"),
                bDispCurrentMulti: getValues("bDispCurrentMulti"),
                sCurrentTitle: getValues("sCurrentTitle"),
                sCurrentUnit: getValues("sCurrentUnit"),
                sCurrentNotes: getValues("sCurrentNotes"),
                nMagniCurrentFlag: getValues("nMagniCurrentFlag") ? getValues("nMagniCurrentFlag").value : null,
                sMagniCurrentTitle: getValues("sMagniCurrentTitle"),
                sMagniCurrentUnit: getValues("sMagniCurrentUnit"),
                sMagniCurrentNotes: getValues("sMagniCurrentNotes"),
                bIsNoneSadamu: getValues("bIsNoneSadamu"),
                nTimeFlag: getValues("nTimeFlag") ? getValues("nTimeFlag").value : null,
                bUseLock: getValues("bUseLock"),
                bUseTimer: getValues("bUseTimer"),
                bMCR: getValues("bMCR"),
                nMagniTimeFlag: getValues("nMagniTimeFlag") ? getValues("nMagniTimeFlag").value : null,
                sMagniTimeNotes: getValues("sMagniTimeNotes"),
                sMagniTimeTitle: getValues("sMagniTimeTitle"),
                sMagniTimeUnit: getValues("sMagniTimeUnit"),
                sTimeNotes: getValues("sTimeNotes"),
                sTimeTitle: getValues("sTimeTitle"),
                sTimeUnit: getValues("sTimeUnit"),
            }

            onCreate({
                "userId": userId,
                "requestPrms": request
            })
        }
    }

    // Submit後のAPI呼び出し成功時
    const handleSubmitSuccess = (success: boolean, data: any, error: any) => {
        onSuccess();
        // if (lElementID !== undefined && lElementID !== null) {
        //     navigate(-1);
        // }
        if(mode ==="add"){
            navigate(RoutePath.DialCharacterEdit+`/edit/${data.data.ldialCharID}`)
        }
        setLoadingFlag(false)
    }

    //削除の処理
    const handleShowDetailPage = (id: any) => {
        switch (selectTab) {
            case 1:
                navigate(RoutePath.CurveStandardEdit + `/edit/${id}`,
                    {
                        state: {
                            lDialCharid: getValues("lDialCharID"),
                        }
                    });
                break;
            case 2:
                navigate(RoutePath.DialCurrentEdit + `/edit/${id}`,
                    {
                        state: {
                            lDialCharid: getValues("lDialCharID"),
                        }
                    });
                break;
            case 3:
                navigate(RoutePath.DialTimeEdit + `/edit/${id}`,
                    {
                        state: {
                            lDialCharid: getValues("lDialCharID"),
                        }
                    });
                break;
            case 4:
                navigate(RoutePath.DialMagniCurrentEdit + `/edit/${id}`,
                    {
                        state: {
                            lDialCharid: getValues("lDialCharID"),
                        }
                    });
                break;
            case 5:
                navigate(RoutePath.DialMagniTimeEdit + `/edit/${id}`,
                    {
                        state: {
                            lDialCharid: getValues("lDialCharID"),
                        }
                    });
                break;
            default:
                break;
        }
    }

    // Submit後のAPI呼び出しエラー時
    const handleSubmitError = (success: boolean, data: any, error: any) => {

    }

    //削除の処理
    const handleDelete = (data: any) => {
        const msg = "ID: " + data + "を削除します。"
        setTitle("特性情報削除");
        setMessage(msg);
        setOpenedDeleteDialog(true);
        setIdDelete(data);
    }

    // 削除確認ダイアログでOKボタンのイベントを受け取って、APIに送信する
    const handleDeleteOK = () => {
        setOpenedDeleteDialog(false);
        setLoadingFlag(true);
        setIdDelete("");
        let object ={
            userId: userId,
            requestPrms: {id:idDelete},
        }
        switch (selectTab) {
            case 1:
                // object.requestType = "curveStandardDelete"
                onDeleteCurveStandard(object);
                break;
            case 2:
                // object.requestType = "dialCurrentDelete"
                onDeleteDialCurrent(object);
                break;
            case 3:
                // object.requestType = "dialTimeDelete"
                onDeleteDialTime(object);
                break;
            case 4:
                // object.requestType = "dialMagniCurrentDelete"
                onDeleteDialMagniCurrent(object);
                break;
            case 5:
                // object.requestType = "dialMagniTimeDelete"
                onDeleteDialMagniTime(object);
                break;
            default:
                onError();
                break;
        }
    }
    // 削除確認ダイアログでCancelボタンのイベントを受け取って、ダイアログを無効にする
    const handleDeleteCancel = () => {
        setIdDelete("");
        setOpenedDeleteDialog(false);
    }

    // キャンセル
    const handleCancel = () => {
        if (location.key !== "default")
            navigate(-1);
        else
            navigate(RoutePath.DialCharacterList);
    }

    const handleSuccessDelete = (data: any) => {
        console.log('>>> handleSuccessDelete');
        onSuccess();
        setLoadingFlag(false);
        onSearchById({
            "userId": userId,
            "requestPrms":{
              "id": id
            }
        });
    };

    const handleSuccessGet = (success: boolean, data: any, error: any) => {
        setMv3breakCurveStandardList(data.mv3breakCurveStandardList);
        setMv3breakDialMagnitimeList(data.mv3breakDialMagniTimeList);
        setMv3breakDialMagniCurrent(data.mv3breakDialMagniCurrentList);
        setMv3breakDialCurrrentList(data.mv3breakDialCurrentList);
        setmv3breakDialTimeList(data.mv3breakDialTimeList);
        switch (selectTab) {
            case 1:
                handleLoadGridAfterDelete(data, data.mv3breakCurveStandardList, gridCurveStandardRef);
                break;
            case 2:
                handleLoadGridAfterDelete(data, data.mv3breakDialCurrentList, gridDialCurrentRef);
                break;
            case 3:
                handleLoadGridAfterDelete(data, data.mv3breakDialTimeList, gridDialTimeRef);
                break;
            case 4:
                handleLoadGridAfterDelete(data, data.mv3breakDialMagniCurrentList, gridDialMagniCurrent);
                break;
            case 5:
                handleLoadGridAfterDelete(data, data.mv3breakDialMagniTimeList, gridDialMagniTime);
                break;
            default:
                break;
        }
    }

    const handleErrorGet = (success: boolean, data: any, error: any) => {
        console.log('>>>> handle Error');
        gridRef.current.hideOverlay();
    }

    const handleLoadGridAfterDelete = (data: any, children: any, gridRef: any) => {
        if (data && children) {
            if (gridRef && gridRef.current) {
                gridRef.current.setRowData(children);
            }
        }
    }
    const handleFirstLoadGrid = (data: any, gridRef: any) => {
        if (data && data.length > 0) {
            if (gridRef && gridRef.current) {
                gridRef.current.setRowData(data);
            }
        } else {
            if (gridRef && gridRef.current) {
                gridRef.current.setRowData([]);
            }
        }
    }
    //入力欄背景
    const divStyles = {
        backgroundColor: 'white',
        border: '1px solid #bebebe',
        boxShadow: '0 0 5px #fff, 0 0 5px #bebebe, 0 0 1px #aaa',
        padding: '20px 40px',
        marginTop: '40px',
    }
    //分析一覧を表示
    const handleAdd = () => {
        switch (selectTab) {
            case 1:
                {
                    navigate(RoutePath.CurveStandardEdit + `/add`,
                        {
                            state: {
                                lDialCharid: getValues("lDialCharID"),
                                sCharacterName: getValues("sCharacterName")
                            }
                        })
                    break;
                }
            case 2:
                {
                    navigate(RoutePath.DialCurrentEdit + `/add`,
                        {
                            state: {
                                lDialCharid: getValues("lDialCharID"),
                                sCharacterName: getValues("sCharacterName")
                            }
                        })
                    break;
                }
            case 3:
                {
                    navigate(RoutePath.DialTimeEdit + `/add`,
                        {
                            state: {
                                lDialCharid: getValues("lDialCharID"),
                                sCharacterName: getValues("sCharacterName")
                            }
                        })
                    break;
                }
            case 4:
                {
                    navigate(RoutePath.DialMagniCurrentEdit + `/add`,
                        {
                            state: {
                                lDialCharid: getValues("lDialCharID"),
                                sCharacterName: getValues("sCharacterName")
                            }
                        })
                    break;
                }
            case 5:
                {
                    navigate(RoutePath.DialMagniTimeEdit + `/add`,
                        {
                            state: {
                                lDialCharid: getValues("lDialCharID"),
                                sCharacterName: getValues("sCharacterName")
                            }
                        })
                    break;
                }
            default:
                break;
        }

    }
    //分析一覧を表示
    const resetGrid = () => {
        handleFirstLoadGrid(mv3breakCurveStandardList, gridCurveStandardRef);
        handleFirstLoadGrid(mv3breakDialCurrrentList, gridDialCurrentRef);
        handleFirstLoadGrid(mv3breakDialTimeList, gridDialTimeRef);
        handleFirstLoadGrid(mv3breakDialMagniCurrentList, gridDialMagniCurrent);
        handleFirstLoadGrid(mv3breakDialMagnitimeList, gridDialMagniTime);
    }
    useEffect(resetGrid, [selectTab]);
    return (
        <>
            {mode === "edit" &&
                <FetchHelper fetchId={GET_DIAL_CHARACTER}
                    url={`/master/dial_character/get`}
                    method="POST"
                    request={true}
                    params={{
                        "userId": userId,
                        "requestPrms":{
                          "id": id
                        }
                    }}
                    json
                    onComplete={(success, data, error) => {
                        success ? handleSuccess(data) : handleError(success, data, error);
                    }}
                />
            }
            {loadingFlag && (
                <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1500, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                    <CircularProgress />
                </div>
            )}
            <div style={{ height: "100%", width: "100%", marginTop: "10px" }}>
                <Stack direction="row" justifyContent="flex-start" alignItems="center" sx={{}} style={{ margin: '18px', color: 'MenuText' }}>
                    <Typography variant="h4">
                        特性詳細
                    </Typography>
                </Stack>
                <form onSubmit={handleSubmit(handleEditSubmit)}>
                    <Stack direction='row'>
                        <div style={{ top: "100px", position: "absolute", zIndex: 1200 }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                {mode === "edit"
                                    ?
                                    <Tabs value={selectTab} onChange={tabHandleChange} aria-label="oilManagementDetailTabs">
                                        <Tab label='特性' {...a11yProps(0)} />
                                        <Tab label='曲線基準' {...a11yProps(1)} />
                                        <Tab label='電流ダイアル' {...a11yProps(2)} />
                                        <Tab label='時間ダイアル' {...a11yProps(3)} />
                                        <Tab label='電流倍率' {...a11yProps(4)} />
                                        <Tab label='時間倍率' {...a11yProps(5)} />
                                    </Tabs>
                                    :
                                    <Tabs value={selectTab} onChange={tabHandleChange} aria-label="oilManagementDetailTabs">
                                        <Tab label='特性' {...a11yProps(0)} />
                                    </Tabs>
                                }
                            </Box>
                        </div>
                    </Stack>
                    {/* 特性 */}
                    <TabPanel value={selectTab} index={0}>
                        <div style={divStyles}>
                            <Typography variant="h6">
                                特性
                            </Typography>
                            <Stack sx={{ height: "calc(50vh)", overflow: 'auto' }}>
                                <Grid container spacing={3} direction={'row'}>
                                    <Grid item xs={12} mt={3}>
                                        <Controller
                                            control={control}
                                            name="mv3breakBreakElement.sElementName"
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    type="text"
                                                    label="要素名称"
                                                    size="small"
                                                    disabled={true}
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Controller
                                            control={control}
                                            name="sCharacterName"
                                            rules={{
                                                required: '必須項目です。入力してください。',                                       
                                                }
                                            }
                                            
                                            render={({ field }) => (
                                                <TextField
                                                    error={!!errors?.sCharacterName}
                                                    helperText={errors?.sCharacterName?.message?.toString()}
                                                    fullWidth
                                                    {...field}
                                                    label="特性名称"
                                                    type="text"
                                                    size="small"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    {/* Flag */}
                                    <Grid container item xs={3} flexDirection={"column"} >
                                        <fieldset style={{height:'310px',border:"1px solid #AAAA"}}>
                                            <legend>電流</legend>
                                                <Grid flexDirection={"row"} ml={-1.5} mb={1}>                                                    
                                                        <Controller
                                                            name="bIsCurrent"
                                                            control={control}
                                                            render={({ field }: any) => (
                                                                <FormControlLabel {...field} control={<Checkbox />} checked={field.value} label="電流値" labelPlacement="start"/>
                                                            )}
                                                        />
                                                        <Controller
                                                            name="bDispCurrentMulti"
                                                            control={control}
                                                            render={({ field }: any) => (
                                                                <FormControlLabel sx={{ml:0.25}} {...field} control={<Checkbox />} checked={field.value}/>
                                                            )}
                                                        />
                                                </Grid>
                                                <Grid>
                                                    <Controller
                                                        control={control}
                                                        name="nCurrentFlag"
                                                        render={({ field: { value } }) => (
                                                            <Autocomplete
                                                                autoHighlight
                                                                value={value}
                                                                options={flagOptions}
                                                                getOptionLabel={(option) => option.label}
                                                                fullWidth
                                                                renderInput={(params) => <TextField {...params} label="可変方式" size="small" />}
                                                                onChange={(_, data) => {
                                                                    setValue("nCurrentFlag", data)
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                            <Grid mt={4}>
                                                <Controller
                                                    control={control}
                                                    name="sCurrentTitle"
                                                    render={({ field }) => (
                                                        <TextField
                                                            fullWidth
                                                            {...field}
                                                            type="text"
                                                            size="small"
                                                            label="ダイアルタイトル"
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid mt={2}> 
                                                <Controller
                                                    control={control}
                                                    name="sCurrentUnit"
                                                    render={({ field }) => (
                                                        <TextField
                                                            fullWidth
                                                            {...field}
                                                            type="text"
                                                            size="small"
                                                            label="単位"
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid mt={2}>
                                                <Controller
                                                    control={control}
                                                    name="sCurrentNotes"
                                                    render={({ field }) => (
                                                        <TextField
                                                            fullWidth
                                                            {...field}
                                                            type="text"
                                                            size="small"
                                                            label="備考"
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                        </fieldset>
                                    </Grid>
                                    <Grid container item xs={3} flexDirection={"column"}>
                                        <fieldset style={{height:'310px',border:"1px solid #AAAA"}}>
                                            <legend>時間</legend>  
                                                <Grid ml={-1.5} mb={1}>
                                                        <Controller
                                                            name="bIsNoneSadamu"
                                                            control={control}
                                                            render={({ field }: any) => (
                                                                <FormControlLabel {...field} control={<Checkbox />} checked={field.value} label="不定則" labelPlacement="start" />
                                                            )}
                                                        />
                                                </Grid >
                                                <Grid>
                                                    <Controller
                                                        control={control}
                                                        name="nTimeFlag"
                                                        render={({ field: { value } }) => (
                                                            <Autocomplete
                                                                autoHighlight
                                                                value={value}
                                                                options={flagOptions}
                                                                getOptionLabel={(option) => option.label}
                                                                fullWidth
                                                                renderInput={(params) => <TextField {...params} label="可変方式" size="small" />}
                                                                onChange={(_, data) => {
                                                                    setValue("nTimeFlag", data)
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                            <Grid mt={4}>
                                                <Controller
                                                    control={control}
                                                    name="sTimeTitle"
                                                    render={({ field }) => (
                                                        <TextField
                                                            fullWidth
                                                            {...field}
                                                            type="text"
                                                            size="small"
                                                            label="ダイアルタイトル"
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid mt={2}>
                                                <Controller
                                                    control={control}
                                                    name="sTimeUnit"
                                                    render={({ field }) => (
                                                        <TextField
                                                            fullWidth
                                                            {...field}
                                                            type="text"
                                                            size="small"
                                                            label="単位"
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid mt={2}>
                                                <Controller
                                                    control={control}
                                                    name="sTimeNotes"
                                                    render={({ field }) => (
                                                        <TextField
                                                            fullWidth
                                                            {...field}
                                                            type="text"
                                                            size="small"
                                                            label="備考"
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                        </fieldset>
                                    </Grid>
                                    <Grid container item xs={3} flexDirection={"column"}>
                                        <fieldset style={{height:'310px',border:"1px solid #AAAA"}}>
                                            <legend>電流倍率</legend>               
                                            <Grid alignSelf={"center"} mt={6}>
                                                <Controller
                                                    control={control}
                                                    name="nMagniCurrentFlag"
                                                    render={({ field: { value } }) => (
                                                        <Autocomplete
                                                            autoHighlight
                                                            value={value}
                                                            options={flagOptions}
                                                            getOptionLabel={(option) => option.label}
                                                            fullWidth
                                                            renderInput={(params) => <TextField {...params} label="可変方式" size="small" />}
                                                            onChange={(_, data) => {
                                                                setValue("nMagniCurrentFlag", data)
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid mt={4}>
                                                <Controller
                                                    control={control}
                                                    name="sMagniCurrentTitle"
                                                    render={({ field }) => (
                                                        <TextField
                                                            fullWidth
                                                            {...field}
                                                            type="text"
                                                            size="small"
                                                            label="ダイアルタイトル"
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid mt={2}>
                                                <Controller
                                                    control={control}
                                                    name="sMagniCurrentUnit"
                                                    render={({ field }) => (
                                                        <TextField
                                                            fullWidth
                                                            {...field}
                                                            type="text"
                                                            size="small"
                                                            label="単位"
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid mt={2}>
                                                <Controller
                                                    control={control}
                                                    name="sMagniCurrentNotes"
                                                    render={({ field }) => (
                                                        <TextField
                                                            fullWidth
                                                            {...field}
                                                            type="text"
                                                            size="small"
                                                            label="備考"
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                        </fieldset>
                                    </Grid>
                                    <Grid container item xs={3} flexDirection={"column"}>
                                        <fieldset style={{height:'310px',border:"1px solid #AAAA"}}>
                                            <legend>時間倍率</legend>               
                                            <Grid alignSelf={"center"} mt={6}>
                                                <Controller
                                                    control={control}
                                                    name="nMagniTimeFlag"
                                                    render={({ field: { value } }) => (
                                                        <Autocomplete
                                                            autoHighlight
                                                            value={value}
                                                            options={flagOptions}
                                                            getOptionLabel={(option) => option.label}
                                                            fullWidth
                                                            renderInput={(params) => <TextField {...params} label="可変方式" size="small" />}
                                                            onChange={(_, data) => {
                                                                setValue("nMagniTimeFlag", data)
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid mt={4}>
                                                <Controller
                                                    control={control}
                                                    name="sMagniTimeTitle"
                                                    render={({ field }) => (
                                                        <TextField
                                                            fullWidth
                                                            {...field}
                                                            type="text"
                                                            size="small"
                                                            label="ダイアルタイトル"
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid mt={2}>
                                                <Controller
                                                    control={control}
                                                    name="sMagniTimeUnit"
                                                    render={({ field }) => (
                                                        <TextField
                                                            fullWidth
                                                            {...field}
                                                            type="text"
                                                            size="small"
                                                            label="単位"
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid mt={2}>
                                                <Controller
                                                    control={control}
                                                    name="sMagniTimeNotes"
                                                    render={({ field }) => (
                                                        <TextField
                                                            fullWidth
                                                            {...field}
                                                            type="text"
                                                            size="small"
                                                            label="備考"
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                        </fieldset>
                                    </Grid>
                                    {/* Checkbox */}
                                    <Grid container spacing={2} direction={'row'} ml={1} mt={0}>
                                        <Grid item xs={3} >
                                            <Controller
                                                name="bIsDefault"
                                                control={control}
                                                render={({ field }: any) => (
                                                    <FormControlLabel {...field} control={<Checkbox />} checked={field.value} label="デフォルト" labelPlacement="end" />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Controller
                                                name="bUseLock"
                                                control={control}
                                                render={({ field }: any) => (
                                                    <FormControlLabel {...field} control={<Checkbox />} checked={field.value} label="ロック設定" labelPlacement="end" />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Controller
                                                name="bUseTimer"
                                                control={control}
                                                render={({ field }: any) => (
                                                    <FormControlLabel {...field} control={<Checkbox />} checked={field.value} label="タイマー設定" labelPlacement="end" />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Controller
                                                name="bMCR"
                                                control={control}
                                                render={({ field }: any) => (
                                                    <FormControlLabel {...field} control={<Checkbox />} checked={field.value} label="MCR" labelPlacement="end" />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Stack direction={'row'} pl={3}>
                                        <Box>
                                            <Controller
                                                name="bIsLink"
                                                control={control}
                                                render={({ field }: any) => (
                                                    <FormControlLabel {...field} control={<Checkbox />} checked={field.value} label="要素連動する?" labelPlacement="end" />
                                                )}
                                            />
                                        </Box>
                                        <Box>
                                            <Controller
                                                control={control}
                                                name="nLinkNumber"
                                                rules={{
                                                    required: '必須項目です。入力してください。',
                                                    pattern: {
                                                        value: /^[0-9\b]+$/,
                                                        message: '数字を入力してください。'
                                                    },
                                                    validate: value => value >= "0" || '数字 >= 0 を入力してください。',
                                                }}
                                                render={({ field }) => (
                                                    <TextField
                                                        error={!!errors?.nLinkNumber}
                                                        helperText={errors?.nLinkNumber?.message?.toString()}
                                                        fullWidth
                                                        {...field}
                                                        label="連動要素（0～）"
                                                        type="number"
                                                        size="small"
                                                    />
                                                )}
                                            />
                                        </Box>
                                    </Stack>
                                </Grid>
                            </Stack>
                        </div>
                    </TabPanel>
                    {
                        <>
                            {/* 曲線基準 */}
                            <TabPanel value={selectTab} index={1}>
                                <div style={divStyles}>
                                    <Stack direction="row" display='flex' justifyContent='space-between'>
                                        <Typography variant="h6" >曲線基準</Typography>
                                        <Stack direction='row' display='flex' spacing={2}>
                                            <Stack>
                                                <Button fullWidth color="inherit" variant="contained" onClick={() => handleAdd()}>曲線基準の追加</Button>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                    <Stack spacing={2} sx={{ mt: 1 }} >
                                        <CurveStandardGrid
                                            ref={gridCurveStandardRef}
                                            colState={colState}
                                            onChangeSearchCondition={() => { }}
                                            keyword={""}
                                            onShowDetails={handleShowDetailPage}
                                            onDelete={handleDelete}
                                            heightForm="365"
                                            checkEdit={true}
                                            mode="edit"
                                        />
                                        {openedDeleteDialog ?
                                            <AlertDialog title={title} message={message} isOpen={true} onOK={handleDeleteOK} onCancel={handleDeleteCancel} /> :
                                            <div />
                                        }
                                    </Stack>
                                </div>
                            </TabPanel>
                            {/* 電流ダイアル */}
                            <TabPanel value={selectTab} index={2}>
                                <div style={divStyles}>
                                    <Stack direction="row" display='flex' justifyContent='space-between'>
                                        <Typography variant="h6" >電流ダイアル</Typography>
                                        <Stack direction='row' display='flex' spacing={2}>
                                            <Stack>
                                                <Button fullWidth color="inherit" variant="contained" onClick={() => handleAdd()}>電流ダイアルの追加</Button>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                    <Stack spacing={2} sx={{ mt: 1 }} >
                                        <DialCurrentGrid
                                            ref={gridDialCurrentRef}
                                            colState={colState}
                                            onChangeSearchCondition={() => { }}
                                            keyword={""}
                                            onShowDetails={handleShowDetailPage}
                                            onDelete={handleDelete}
                                            heightForm="365"
                                            checkEdit={true}
                                            mode="edit"
                                        />
                                        {openedDeleteDialog ?
                                            <AlertDialog title={title} message={message} isOpen={true} onOK={handleDeleteOK} onCancel={handleDeleteCancel} /> :
                                            <div />
                                        }
                                    </Stack>
                                </div>
                            </TabPanel>
                            {/* 時間ダイアル */}
                            <TabPanel value={selectTab} index={3}>
                                <div style={divStyles}>
                                    <Stack direction="row" display='flex' justifyContent='space-between'>
                                        <Typography variant="h6" >時間ダイアル</Typography>
                                        <Stack direction='row' display='flex' spacing={2}>
                                            <Stack>
                                                <Button fullWidth color="inherit" variant="contained" onClick={() => handleAdd()}>時間ダイアルの追加</Button>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                    <Stack spacing={2} sx={{ mt: 1 }} >
                                        <DialTimeGrid
                                            ref={gridDialTimeRef}
                                            colState={colState}
                                            onChangeSearchCondition={() => { }}
                                            keyword={""}
                                            onShowDetails={handleShowDetailPage}
                                            onDelete={handleDelete}
                                            heightForm="365"
                                            checkEdit={true}
                                            mode="edit"
                                        />
                                        {openedDeleteDialog ?
                                            <AlertDialog title={title} message={message} isOpen={true} onOK={handleDeleteOK} onCancel={handleDeleteCancel} /> :
                                            <div />
                                        }
                                    </Stack>
                                </div>
                            </TabPanel>
                            {/* 電流倍率 */}
                            <TabPanel value={selectTab} index={4}>
                                <div style={divStyles}>
                                    <Stack direction="row" display='flex' justifyContent='space-between'>
                                        <Typography variant="h6" >電流倍率</Typography>
                                        <Stack direction='row' display='flex' spacing={2}>
                                            <Stack>
                                                <Button fullWidth color="inherit" variant="contained" onClick={() => handleAdd()}>電流倍率の追加</Button>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                    <Stack spacing={2} sx={{ mt: 1 }} >
                                        <DialMagniCurrentGrid
                                            ref={gridDialMagniCurrent}
                                            colState={colState}
                                            onChangeSearchCondition={() => { }}
                                            keyword={""}
                                            onShowDetails={handleShowDetailPage}
                                            onDelete={handleDelete}
                                            heightForm="365"
                                            checkEdit={true}
                                            mode="edit"
                                        />
                                        {openedDeleteDialog ?
                                            <AlertDialog title={title} message={message} isOpen={true} onOK={handleDeleteOK} onCancel={handleDeleteCancel} /> :
                                            <div />
                                        }
                                    </Stack>
                                </div>
                            </TabPanel>
                            {/* 時間倍率 */}
                            <TabPanel value={selectTab} index={5}>
                                <div style={divStyles}>
                                    <Stack direction="row" display='flex' justifyContent='space-between'>
                                        <Typography variant="h6" >時間倍率</Typography>
                                        <Stack direction='row' display='flex' spacing={2}>
                                            <Stack>
                                                <Button fullWidth color="inherit" variant="contained" onClick={() => handleAdd()}>時間倍率の追加</Button>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                    <Stack spacing={2} sx={{ mt: 1 }} >
                                        <DialMagniTimeGrid
                                            ref={gridDialMagniTime}
                                            colState={colState}
                                            onChangeSearchCondition={() => { }}
                                            keyword={""}
                                            onShowDetails={handleShowDetailPage}
                                            onDelete={handleDelete}
                                            heightForm="365"
                                            checkEdit={true}
                                            mode="edit"
                                        />
                                        {openedDeleteDialog ?
                                            <AlertDialog title={title} message={message} isOpen={true} onOK={handleDeleteOK} onCancel={handleDeleteCancel} /> :
                                            <div />
                                        }
                                    </Stack>
                                </div>
                            </TabPanel>
                        </>
                    }
                    <Stack direction='row' justifyContent="flex-end" spacing={2} sx={{ mt: 0, mr: 3 }}>
                        <LoadingButton variant="contained" type="submit" loading={loadingFlag}>保存</LoadingButton>
                        <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                    </Stack>
                </form>
            </div>
            <FetchHelper fetchId={UPDATE_DIAL_CHARACTER} onComplete={(success, data, error) => success ? handleSubmitSuccess(success, data, error) : handleSubmitError(success, data, error)} />
            <FetchHelper fetchId={CREATE_DIAL_CHARACTER} onComplete={(success, data, error) => success ? handleSubmitSuccess(success, data, error) : handleSubmitError(success, data, error)} />
            <FetchHelper fetchId={GET_DIAL_CHARACTER_BY_ID} onComplete={(success: boolean, data: any, error: any) => success ? handleSuccessGet(success, data, error) : handleErrorGet(success, data, error)} />
            <FetchHelper fetchId={DELETE_DIAL_CURRENT} onComplete={(success, data, error) => success ? handleSuccessDelete(data) : onError()} />
            <FetchHelper fetchId={DELETE_DIAL_TIME} onComplete={(success, data, error) => success ? handleSuccessDelete(data) : onErrorDeleteDialDialTime()} />
            <FetchHelper fetchId={DELETE_DIAL_MAGNI_CURRENT} onComplete={(success, data, error) => success ? handleSuccessDelete(data) : onErrorDeleteDialMagniCurrent()} />
            <FetchHelper fetchId={DELETE_DIAL_MAGNI_TIME} onComplete={(success, data, error) => success ? handleSuccessDelete(data) : onErrorDeleteDialMagniTime()} />
            <FetchHelper fetchId={DELETE_CURVE_STANDARD} onComplete={(success, data, error) => success ? handleSuccessDelete(data) : onError()} />
        </>
    )
}

const mapStateToProps = (state: ApplicationState) => ({
    userId: state.app.user?.userId,
    fetchUpdate: selectors.getFormState(
        state,
        GET_DIAL_CHARACTER,
    ),
});

const mapDispatchToProps = (dispatch: any) => {
    return {
        onCreate: (data: any) => dispatch(actionCreators.fetch(CREATE_DIAL_CHARACTER, `/master/dial_character/add`, "POST", data, false, true)),
        onUpdate: (id: string, data: any) => dispatch(actionCreators.fetch(UPDATE_DIAL_CHARACTER, `/master/dial_character/modify`, "POST", data, false, true)),
        onDeleteCurveStandard: (params: any) => dispatch(actionCreators.fetch(DELETE_CURVE_STANDARD, `/master/curve_standard/delete`, "POST", params,false,true)),
        onDeleteDialCurrent: (params: any) => dispatch(actionCreators.fetch(DELETE_DIAL_CURRENT, `/master/dial_current/delete`, "POST", params,false,true)),
        onDeleteDialTime: (params: any) => dispatch(actionCreators.fetch(DELETE_DIAL_TIME, `/master/dial_time/delete`, "POST", params,false,true)),
        onDeleteDialMagniCurrent: (id: any) => dispatch(actionCreators.fetch(DELETE_DIAL_MAGNI_CURRENT, `/master/dial_magni_current/delete`, "POST", id,false, true)),
        onDeleteDialMagniTime: (params: any) => dispatch(actionCreators.fetch(DELETE_DIAL_MAGNI_TIME, `/master/dial_magni_time/delete`, "POST", params,false,true)),
        onSearchById: (params: { [key: string]: any }) => dispatch(actionCreators.fetch(GET_DIAL_CHARACTER_BY_ID, `/master/dial_character/get`, "POST", params, false, true)),
        onSearch: (params: { [key: string]: any }) => dispatch(actionCreators.fetch(GET_DIAL_CHARACTER, `/master/dial_character/gets`, "POST", params, false, true)),
        onSuccess: () =>
            dispatch(actionCreators.showMessage({
                type: "info",
                title: "特性更新",
                body: "特性を更新しました。",
            })),
        onError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "検索",
                body: "検索できません。",
            })),
        onCreateError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "特性登録",
                body: "特性を登録出来ませんでした。",
            })),
        onUpdateError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "特性編集",
                body: "特性を更新出来ませんでした。",
            })),
        onSuccessDeleteDialDialTime: () =>
            dispatch(actionCreators.showMessage({
                type: "info",
                title: "時間ダイアル削除",
                body: "時間ダイアルを削除しました。",
            })),
        onErrorDeleteDialDialTime: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "時間ダイアル削除",
                body: "時間ダイアルを削除できませんでした。",
            })),
        onSuccessDeleteDialMagniCurrent: () =>
            dispatch(actionCreators.showMessage({
                type: "info",
                title: "電流倍率削除",
                body: "電流倍率を削除しました。",
            })),
        onErrorDeleteDialMagniCurrent: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "電流倍率削除",
                body: "電流倍率を削除できませんでした。",
            })),
        onSuccessDeleteDialMagniTime: () =>
            dispatch(actionCreators.showMessage({
                type: "info",
                title: "時間倍率削除",
                body: "時間倍率を削除しました。",
            })),
        onErrorDeleteDialMagniTime: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "時間倍率削除",
                body: "時間倍率を削除できませんでした。",
            })),
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DialCharacterEdit as any);