import { Button, ButtonGroup, Container, Dialog, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, Stack, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import theme from "../../themes/globalStyles";
import DraggablePaper from "../common/DraggablePaper";
import { useState } from "react";

export type ShareProjetForm = {
    email : string,
    username : string,
    permission: string,
    sub:string;
    sharedUserId:number|undefined;
}

export type ShareProjectProps = {
    mode: "ADD" | "UPDATE",
    data: ShareProjetForm;
    onOK: (data: any) => void;
    onCancel: () => void;
    listUser: any;
    dataShareProjectList: any
};

export const ShareProjectDialog = (props: ShareProjectProps) => {

    //#region fields
    const { mode, onOK,data, onCancel,listUser,dataShareProjectList } = props;

    // 初期値設定
    const initialValue : ShareProjetForm = {
        ...data,
    }

    const { control, handleSubmit, formState: { errors }, setValue, getValues,setError,clearErrors } = useForm<ShareProjetForm>({
        mode: "all",
        criteriaMode: "all",
        defaultValues: initialValue,
    })
    const[sub,setSub] = useState()
    //#endregion fields
    //#region methods
    // Submitイベント
    const handleEditSubmit = (value: any, event: any) => {
        const request = createRequestData(value);
        const existed = dataShareProjectList.filter((item:any) =>item.sub == sub)
        if(existed.length > 0){
            setError('email',{ type: 'custom', message:'ユーザーは既に存在します。'})
        }
        else if(getValues('username') == ''){
            setError('username',{ type: 'custom', message:'確認を押下でユーザー名を取得してください。'})
        }
        else{
            onOK(request);
        }

    }
    
    // FormデータからRequestデータを作成する
    const createRequestData = (formValue : ShareProjetForm) => {       
        let request = {
            ...formValue,
            sub: sub
        } as any;
        return request;
    }

    const handleGetUsernameByEmail = () =>{
        const email = getValues('email')
        let count = 0
        listUser.forEach((e:any) =>{
            if(e.email == email){
                setValue('username',e.family_name + e.given_name)
                setSub(e.sub)
                count += 1
            }
        })
        if(count == 0){
            setError('email',{ type: 'custom', message:'共有先のメールアドレスをもう一度ご確認ください。'})
        }
        clearErrors('username')
    }
    const handleChangeEmail =(e:any) =>{
        setValue(e.target.name,e.target.value)
        if(e.target.value !== ''){
            clearErrors('email')
        }
        setValue('username','')
    }
    //#endregion methods

    return (
        <>
            <Dialog open={true} maxWidth={"sm"} fullWidth
                PaperComponent={
                    DraggablePaper
                }
            >
                <DialogTitle 
                    onMouseOver={(e : any) => e.target.style.cursor = 'move'}
                    style={{ paddingTop: '2px', paddingBottom: '2px', background: theme.palette.primary.main, color: theme.palette.primary.contrastText }}
                >
                    {mode === "ADD" ? "共有先の追加" : "共有先の編集"}
                </DialogTitle>
                <DialogContent style={{paddingTop: '20px', display: 'flex', alignItems: 'flex-end'}}>
                    <div style={{width: '100%'}}>
                        <form onSubmit={handleSubmit(handleEditSubmit)}>
                            <Stack gap={3}>
                                    <Stack direction={'row'} width={'100%'}>
                                        <Controller
                                            name="email"
                                            control={control}
                                            rules={{ required: '必須項目です。入力してください。' ,
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    size="small"
                                                    label="共有先のメールアドレス"
                                                    InputLabelProps={{
                                                        style: { color: 'black' },
                                                    }}
                                                    disabled={mode==="UPDATE"?true:false}
                                                    error={!!errors?.email}
                                                    helperText={errors?.email?.message}
                                                    onChange={(e:any) =>{field.onChange(); handleChangeEmail(e)}}
                                                />
                                            )}
                                        />
                                        {mode==="ADD" &&<Button variant="contained" sx={{ml:'5px'}} onClick={() =>handleGetUsernameByEmail()}>確認</Button>}
                                    </Stack>
                                    <Controller
                                        name="username"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                type="text"
                                                size="small"
                                                label="ユーザー名"
                                                InputLabelProps={{
                                                    style: { color: 'black' },
                                                }}
                                                disabled={true}
                                                error={!!errors?.username}
                                                helperText={errors?.username?.message}
                                            />
                                        )}
                                    />
                                    <Stack>
                                        <FormControl fullWidth size="small">
                                                <InputLabel sx={{ color: 'black' }}>権限</InputLabel>
                                                <Controller
                                                    name="permission"
                                                    control={control}
                                                    render={({ field }: any) => (
                                                        <>
                                                            <Select
                                                                {...field}
                                                                label="権限"
                                                            >
                                                                <MenuItem value={'1'}>参照</MenuItem>
                                                                <MenuItem value={'2'}>編集</MenuItem>
                                                            </Select>
                                                        </>
                                                    )}
                                                />
                                        </FormControl>
                                    </Stack>
                            </Stack>
                            <Stack direction='row' justifyContent="flex-end" gap={2} sx={{ mt: 4 }}>
                                <Button variant="contained" type="submit">追加</Button>
                                <Button variant="contained" color="inherit" onClick={onCancel}>キャンセル</Button>
                            </Stack>
                        </form>
                    </div>                    
                </DialogContent>
            </Dialog>
        </>
    )
}
export default ShareProjectDialog;