import { store } from "..";
import { ControlModel, MsTrans1Model, MsTrans1PartModel, ProcessMode } from "../models/Index";
import { BeamsNumber } from "./FormatNumber";
import { MS_MODE_CALCULATION, MS_MODE_CHECKING, MS_MODE_DRAWING, MS_MODE_HARMONIC, MS_MODE_PERCENT_Z, MS_MODE_POWER_FLOW, MS_MODE_VOLTDROP} from "../statics/index";
import { doGetValuePerZk3 } from "./PercentZ";
import { MS_OUTSIDE_NEUTRAL, MS_OUTSIDE_OUTSIDE, MS_3PHASES, MS_VOLTDROP_REGULAR, DIRECT_NONE, LEFT_CENTER, TOP_CENTER } from "../models/Constants"
import { doGetValueDropVoltRegular as doGetValueDropVoltRegularCalcPoint,
	doGetIkpeakDirectMG,
	doGetIkasymDirectMG,
	doGetIksymDirectMG,
	doGetTextDropVoltRegular as doGetTextDropVoltRegularCalcPoint,
	doGetTextDropPerRegular as doGetTextDropPerRegularCalcPoint,
	doGetTextDropPerRegular2 as doGetTextDropPerRegular2CalcPoint,
	doGetPerZDirectMG
} from "./calcPointFunction";

//CEleTrans1::DoGetValuePercentRe
export const doGetValuePercentRe = (dXR:number, dPercentZ:number) =>
{
	let dPercentR = dPercentZ / Math.sqrt(dXR * dXR + 1);

	return dPercentR;
}

//CEleTrans1::DoGetValuePercentXe
export const doGetValuePercentXe = (dXR:number, dPercentR:number) =>
{
	let dPercentX = dXR * dPercentR;

	return dPercentX;
}

//CEleTrans1::IsPointFaultANDTrans1
export const isPointFaultANDTrans1 = (props:MsTrans1Model) =>
{
	if (props.partOutIn.fault && props.partOutOut.fault) {
		return true;
	}
	return false;
}

// CEleTrans1Part::DoGetTextPointState
export const doGetTextPointState = (props:MsTrans1PartModel) =>
{
	let sText = '';

	if (props.fault) {
		sText = 'Yes';
	}
	else {
		sText = 'No';
	}

	return sText;
}

//CEleTrans1::DoGetPerZrTrans1
export const doGetPerZrTrans1 = (props:MsTrans1Model, dStdCapacity:number, nPartKind:number) =>
{
	let percentZ = {
        perRk3 : 0,
        perXk3 : 0,
        perRk3Difference : 0,
        perXk3Difference : 0
    };

	switch (nPartKind) {
	case MS_OUTSIDE_NEUTRAL:		
		percentZ.perRk3 = doGetValuePercentRe(props.partOutIn.xr, props.partOutIn.percentZ);
		percentZ.perXk3 = doGetValuePercentXe(props.partOutIn.xr, percentZ.perRk3);
		break;
	case MS_OUTSIDE_OUTSIDE:		
		percentZ.perRk3 = doGetValuePercentRe(props.partOutOut.xr, props.partOutOut.percentZ);
		percentZ.perXk3 = doGetValuePercentXe(props.partOutOut.xr, percentZ.perRk3);
		percentZ.perRk3Difference = doGetValuePercentRe(props.partOutIn.xr, props.partOutIn.percentZ);
		percentZ.perXk3Difference = doGetValuePercentXe(props.partOutIn.xr, percentZ.perRk3Difference);
		break;
	}

	if (props.capacity == 0) {
		percentZ.perRk3 = 0;
		percentZ.perXk3 = 0;
	}
	else {
		switch (nPartKind) {
		case MS_OUTSIDE_NEUTRAL:		
            percentZ.perRk3 *= dStdCapacity / (props.capacity  / 2);
			percentZ.perXk3 *= dStdCapacity / (props.capacity  / 2);
			break;
		case MS_OUTSIDE_OUTSIDE:		
            percentZ.perRk3 *= dStdCapacity / props.capacity ;
			percentZ.perXk3 *= dStdCapacity / props.capacity ;
								
			percentZ.perRk3Difference *= dStdCapacity / (props.capacity  / 2);
			percentZ.perXk3Difference *= dStdCapacity / (props.capacity  / 2);
			percentZ.perRk3Difference = percentZ.perRk3 - percentZ.perRk3Difference;
			percentZ.perXk3Difference = percentZ.perXk3 - percentZ.perXk3Difference;
			break;
		}
	}

	return percentZ;
}

//CEleTrans1::DoGetValueDropVoltRegular
export const doGetValueDropVoltRegular = (props:MsTrans1Model, dStdCapacity:number, nPartKind:number) => {
    let percentZ = doGetPerZrTrans1(props, dStdCapacity, nPartKind);
	let dVolt = 0;
	switch (nPartKind) {
	case MS_OUTSIDE_NEUTRAL:		
		dVolt = doGetValueDropVoltRegularCalcPoint(props.voltageSecondary / 2, percentZ.perRk3, percentZ.perXk3, props.partOutIn.calcPoint);
		break;
	case MS_OUTSIDE_OUTSIDE:		
		dVolt = doGetValueDropVoltRegularCalcPoint(props.voltageSecondary, percentZ.perRk3, percentZ.perXk3, props.partOutOut.calcPoint);
		break;
	}

	return dVolt;
}

//CEleTrans1::DoGetTextVoltPrimary
export const doGetTextVoltPrimary = (props:MsTrans1Model, bUnit:boolean = true) =>
{
	let sText = props.voltagePrimary.toString();
	if (bUnit) {
		sText += 'V';
	}
	return sText;
}

//CEleTrans1::DoGetTextVoltSecondary
export const doGetTextVoltSecondary = (props:MsTrans1Model, nPartKind:number, bUnit:boolean = true) =>
{
	let sText = '';
	switch (nPartKind) {
	case MS_OUTSIDE_NEUTRAL:		
		sText = (props.voltageSecondary / 2).toString();
		break;
	case MS_OUTSIDE_OUTSIDE:		
		sText = props.voltageSecondary.toString();
		break;
	}
	if (bUnit) {
		sText += 'V';
	}
	return sText;
}

//CEleTrans1::DoGetTextCapacity
export const doGetTextCapacity = (props:MsTrans1Model, nPartKind:number, bUnit:boolean = true) =>
{
	let sText = '';
	switch (nPartKind) {
	case MS_OUTSIDE_NEUTRAL:		
		sText = BeamsNumber(props.capacity / 2, 6).toString();
		break;
	case MS_OUTSIDE_OUTSIDE:		
		sText = BeamsNumber(props.capacity, 6).toString();
		break;
	}
	if (bUnit) {
		sText += 'kVA';
	}
	return sText;
}

//CEleTrans1::DoGetValueRatedCurrent
export const doGetValueRatedCurrent = (props:MsTrans1Model, nPartKind:number, dVoltSecondary:number, dCapacity:number) =>
{
	let dRatedCurrent = 0;

	if (dVoltSecondary <= 0) {
		return 0;
	}
	else {
		switch (nPartKind) {
		case MS_OUTSIDE_NEUTRAL:		
			dRatedCurrent = (dCapacity / 2) * 1000.0 / (dVoltSecondary / 2);
			break;
		case MS_OUTSIDE_OUTSIDE:		
			dRatedCurrent = dCapacity * 1000.0 / dVoltSecondary;
			break;
		case MS_3PHASES:			
			dRatedCurrent = props.voltagePrimary;
			dRatedCurrent = dCapacity * 1000.0 / dRatedCurrent;		
			break;
		}
	}
	return dRatedCurrent;
}

//CEleTrans1::DoGetTextRatedCurrent
export const doGetTextRatedCurrent = (props:MsTrans1Model, nPartKind:number, bUnit:boolean = true) =>
{
	let dRatedCurrent = doGetValueRatedCurrent(props, nPartKind, props.voltageSecondary, props.capacity);
	let sText = BeamsNumber(dRatedCurrent, 4).toString();
	if (bUnit) {
		sText += 'A';
	}
	return sText;
}

//CEleTrans1::DoGetTextPerZe
export const doGetTextPerZe = (props:MsTrans1Model, nPartKind:number, bUnit:boolean = true) =>
{
	let sText = '';
	switch (nPartKind) {
	case MS_OUTSIDE_NEUTRAL:		
		sText = BeamsNumber(props.partOutIn.percentZ, 5).toString(); //perZk3 not found
		break;
	case MS_OUTSIDE_OUTSIDE:		
		sText = BeamsNumber(props.partOutOut.percentZ, 5).toString(); //perZk3 not found
		break;
	}
	if (bUnit) {
		sText += '%e';
	}
	return sText;
}

//CEleTrans1::DoGetTextXR
export const doGetTextXR = (props:MsTrans1Model, nPartKind:number) =>
{
	let sText = '';
	switch (nPartKind) {
	case MS_OUTSIDE_NEUTRAL:		
		sText = BeamsNumber(props.partOutIn.xr, 5).toString();
		break;
	case MS_OUTSIDE_OUTSIDE:		
		sText = BeamsNumber(props.partOutOut.xr, 5).toString();
		break;
	}
	return sText;
}

//CEleTrans1::DoGetTextPerZr
export const doGetTextPerZr = (props:MsTrans1Model, nPartKind:number, dStdCapacity:number, bUnit:boolean = true) =>
{
	let percentZ = doGetPerZrTrans1(props, dStdCapacity, nPartKind);
	let dPercentZ = doGetValuePerZk3(percentZ).dPercentZ;
	let sText = BeamsNumber(dPercentZ, 4).toString();
	if (bUnit) {
		sText += '%r';
	}
	return sText;
}

//CEleTrans1::DoGetTextPerRr
export const doGetTextPerRr = (props:MsTrans1Model, nPartKind:number, dStdCapacity:number, bUnit:boolean = true) =>
{
	let percentZ = doGetPerZrTrans1(props, dStdCapacity, nPartKind);
	let sText = BeamsNumber(percentZ.perRk3, 4).toString();
	if (bUnit) {
		sText += '%r';
	}
	return sText;
}

//CEleTrans1::DoGetTextPerXr
export const doGetTextPerXr = (props:MsTrans1Model, nPartKind:number, dStdCapacity:number, bUnit:boolean = true) =>
{
	let percentZ = doGetPerZrTrans1(props, dStdCapacity, nPartKind);
	let sText = BeamsNumber(percentZ.perXk3, 4).toString();
	if (bUnit) {
		sText += '%r';
	}
	return sText;
}

//CEleTrans1::DoGetTextDirectIkSpeak
export const doGetTextDirectIkSpeak = (props:MsTrans1Model, nPartKind:number, bUnit:boolean = true) =>
{
	let dIpeakDirect = 0;
	switch (nPartKind) {
	case MS_OUTSIDE_NEUTRAL:		
		dIpeakDirect = doGetIkpeakDirectMG(props.partOutIn.calcPoint);
		break;
	case MS_OUTSIDE_OUTSIDE:		
		dIpeakDirect = doGetIkpeakDirectMG(props.partOutOut.calcPoint);
		break;
	}

	let sText;
	if (dIpeakDirect >= 0) {
		sText = BeamsNumber(dIpeakDirect / 1000, 4).toString();
		if (bUnit) {
			sText += 'kApeak';
		}
	}
	else {							
		sText = '????';
	}
	return sText;
}

//CEleTrans1::DoGetTextDirectIkSasym
export const doGetTextDirectIkSasym = (props:MsTrans1Model, nPartKind:number, bUnit:boolean = true) =>
{
	let dIasymDirect = 0;
	switch (nPartKind) {
	case MS_OUTSIDE_NEUTRAL:		
		dIasymDirect = doGetIkasymDirectMG(props.partOutIn.calcPoint);
		break;
	case MS_OUTSIDE_OUTSIDE:		
		dIasymDirect = doGetIkasymDirectMG(props.partOutOut.calcPoint);
		break;
	}

	let sText;
	if (dIasymDirect >= 0) {
		sText = BeamsNumber(dIasymDirect / 1000, 4).toString();
		if (bUnit) {
			sText += 'kAasym';
		}
	}
	else {							
		sText = '????';
	}
	return sText;
}

//CEleTrans1::DoGetTextDirectIkSsym
export const doGetTextDirectIkSsym = (props:MsTrans1Model, nPartKind:number, bUnit:boolean = true) =>
{
	let dIsymDirect = 0;
	switch (nPartKind) {
	case MS_OUTSIDE_NEUTRAL:		
		dIsymDirect = doGetIksymDirectMG(props.partOutIn.calcPoint);
		break;
	case MS_OUTSIDE_OUTSIDE:		
		dIsymDirect = doGetIksymDirectMG(props.partOutOut.calcPoint);
		break;
	}

	let sText;
	if (dIsymDirect >= 0) {
		sText = BeamsNumber(dIsymDirect / 1000, 4).toString();
		if (bUnit) {
			sText += 'kAsym';
		}
	}
	else {							
		sText = '????';
	}
	return sText;
}

//CEleTrans1::DoGetTextLoadCurrent
export const doGetTextLoadCurrent = (props:MsTrans1Model, nPartKind:number, bUnit:boolean = true) =>
{
	let sText = '';

	switch (nPartKind) {
	case MS_OUTSIDE_NEUTRAL:		
		sText = BeamsNumber(props.partOutIn.calcPoint.loadCurrent, 5).toString();
		break;
	case MS_OUTSIDE_OUTSIDE:		
		sText = BeamsNumber(props.partOutOut.calcPoint.loadCurrent, 5).toString();
		break;
	}
	if (bUnit) {
		sText += 'A';
	}
	return sText;
}

//CEleTrans1::DoGetTextDropVoltPF
export const doGetTextDropVoltPF = (props:MsTrans1Model, nPartKind:number, bUnit:boolean = true) =>
{
	let sText = '';
	switch (nPartKind) {
	case MS_OUTSIDE_NEUTRAL:		
		sText = BeamsNumber(props.partOutIn.calcPoint.powerFactor, 5).toString();
		if (bUnit) {
			if (props.partOutIn.calcPoint.isLead) {
				sText += '(進み)';
			}
			else {
				sText += '(遅れ)';
			}
		}
		break;
	case MS_OUTSIDE_OUTSIDE:		
		sText = BeamsNumber(props.partOutOut.calcPoint.powerFactor, 5).toString();
		if (bUnit) {
			if (props.partOutOut.calcPoint.isLead) {
				sText += '(進み)';
			}
			else {
				sText += '(遅れ)';
			}
		}
		break;
	}

	return sText;
}

//CEleTrans1::DoGetTextDropVoltDiff
export const doGetTextDropVoltDiff = (props:MsTrans1Model, dStdCapacity:number, nOpeMode:number, nPartKind:number, bUnit:boolean = true) =>
{
	let dVolt = 0;
	let percentZ = doGetPerZrTrans1(props, dStdCapacity, nPartKind);

	if (nOpeMode == MS_MODE_DRAWING) {
		switch (nPartKind) {
		case MS_OUTSIDE_NEUTRAL:		
			dVolt = doGetValueDropVoltRegularCalcPoint(props.voltageSecondary / 2, percentZ.perRk3, percentZ.perXk3, props.partOutIn.calcPoint);
			dVolt = props.voltageSecondary / 2 - dVolt;
			break;
		case MS_OUTSIDE_OUTSIDE:		
			dVolt = doGetValueDropVoltRegularCalcPoint(props.voltageSecondary, percentZ.perRk3, percentZ.perXk3, props.partOutOut.calcPoint);
			dVolt = props.voltageSecondary - dVolt;
			break;
		}
	}
	else {
		if (props.supply) {
			switch (nPartKind) {
			case MS_OUTSIDE_NEUTRAL:		
				dVolt = doGetValueDropVoltRegularCalcPoint(props.voltageSecondary / 2, percentZ.perRk3, percentZ.perXk3, props.partOutIn.calcPoint);
				dVolt = props.voltageSecondary / 2 - dVolt;
				break;
			case MS_OUTSIDE_OUTSIDE:		
				dVolt = doGetValueDropVoltRegularCalcPoint(props.voltageSecondary, percentZ.perRk3, percentZ.perXk3, props.partOutOut.calcPoint);
				dVolt = props.voltageSecondary - dVolt;
				break;
			}
		}
	}

	let sText = BeamsNumber(dVolt, 4).toString();
	if (bUnit) {
		sText += 'V';
	}
	return sText;
}

//CEleTrans1::DoGetTextDropVoltRegular
export const doGetTextDropVoltRegular = (props:MsTrans1Model, dStdCapacity:number, nPartKind:number, bUnit:boolean = true) =>
{
	let sText = '';
	let percentZ = doGetPerZrTrans1(props, dStdCapacity, nPartKind);
	switch (nPartKind) {
	case MS_OUTSIDE_NEUTRAL:		
		if (props.supply) {
			sText = doGetTextDropVoltRegularCalcPoint(props.partOutIn.calcPoint, props.voltageSecondary / 2, percentZ.perRk3, percentZ.perXk3, bUnit);
		}
		else {
			sText = doGetTextDropVoltRegularCalcPoint(props.partOutIn.calcPoint, 0, percentZ.perRk3, percentZ.perXk3, bUnit);
		}
		break;
	case MS_OUTSIDE_OUTSIDE:		
		if (props.supply) {
			sText = doGetTextDropVoltRegularCalcPoint(props.partOutOut.calcPoint, props.voltageSecondary, percentZ.perRk3, percentZ.perXk3, bUnit);
		}
		else {
			sText = doGetTextDropVoltRegularCalcPoint(props.partOutOut.calcPoint, 0, percentZ.perRk3, percentZ.perXk3, bUnit);
		}
		break;
	}
	return sText;
}

//CEleTrans1::DoGetTextDropPerRegular
export const doGetTextDropPerRegular = (props:MsTrans1Model, dStdCapacity:number, nPartKind:number, bUnit:boolean = true) =>
{
	let sText = '';
	let percentZ = doGetPerZrTrans1(props, dStdCapacity, nPartKind);
	switch (nPartKind) {
	case MS_OUTSIDE_NEUTRAL:		
		if (props.supply) {
			sText = doGetTextDropPerRegularCalcPoint(props.partOutIn.calcPoint, props.voltageSecondary / 2, percentZ.perRk3, percentZ.perXk3, bUnit);
		}
		else {
			sText = doGetTextDropPerRegularCalcPoint(props.partOutIn.calcPoint, 0, percentZ.perRk3, percentZ.perXk3, bUnit);
		}
		break;
	case MS_OUTSIDE_OUTSIDE:		
		if (props.supply) {
			sText = doGetTextDropPerRegularCalcPoint(props.partOutOut.calcPoint, props.voltageSecondary, percentZ.perRk3, percentZ.perXk3, bUnit);
		}
		else {
			sText = doGetTextDropPerRegularCalcPoint(props.partOutOut.calcPoint, 0, percentZ.perRk3, percentZ.perXk3, bUnit);
		}
		break;
	}
	return sText;
}

//CEleTrans1::DoGetTextDropPerRegular2
export const doGetTextDropPerRegular2 = (props:MsTrans1Model, dStdCapacity:number, nPartKind:number, bUnit:boolean = true) =>
{
	let sText = '';
	let percentZ = doGetPerZrTrans1(props, dStdCapacity, nPartKind);
	switch (nPartKind) {
	case MS_OUTSIDE_NEUTRAL:		
		if (props.supply) {
			sText = doGetTextDropPerRegular2CalcPoint(props.partOutIn.calcPoint, props.voltageSecondary / 2, percentZ.perRk3, percentZ.perXk3, bUnit);
		}
		else {
			sText = doGetTextDropPerRegular2CalcPoint(props.partOutIn.calcPoint, 0, percentZ.perRk3, percentZ.perXk3, bUnit);
		}
		break;
	case MS_OUTSIDE_OUTSIDE:		
		if (props.supply) {
			sText = doGetTextDropPerRegular2CalcPoint(props.partOutOut.calcPoint, props.voltageSecondary, percentZ.perRk3, percentZ.perXk3, bUnit);
		}
		else {
			sText = doGetTextDropPerRegular2CalcPoint(props.partOutOut.calcPoint, 0, percentZ.perRk3, percentZ.perXk3, bUnit);
		}
		break;
	}
	return sText;
}

//CEleTrans1::DoGetRefV1
export const doGetRefV1 = (mode:any, control:ControlModel, bSociety:boolean) =>
{
	let props = control.properties as MsTrans1Model;
    let infoCircuit = store.getState().app.diagram.infoCircuit;
    let project = store.getState().app.projectData;

	//CEleTrans1::DoGetRefV1Left  sRefV1Left
	let labelTexts = [{
		value: control.properties.refNo,
		color: "#f800f8",
	}];

	if (!props.viewResultText || (mode != ProcessMode.DRAWING && !props.supply))
    {
      return labelTexts;
    }
    
    if (bSociety){

		// VoltPrimary / VoltSecondary - VoltSecondary
		labelTexts.push({
			value: doGetTextVoltPrimary(props)
			+ '/'
			+ doGetTextVoltSecondary(props, MS_OUTSIDE_OUTSIDE, false)
			+ '-'
			+ doGetTextVoltSecondary(props, MS_OUTSIDE_NEUTRAL),
			color: "blue",
		});

		// Capacity
		labelTexts.push({
			value: doGetTextCapacity(props, MS_OUTSIDE_OUTSIDE),
			color: "blue",
		});

        return labelTexts;
    }

	switch(mode) {
        case ProcessMode.DRAWING:
        case ProcessMode.CHECKING:
			// VoltPrimary / VoltSecondary - VoltSecondary
			labelTexts.push({
				value: doGetTextVoltPrimary(props)
				+ '/'
				+ doGetTextVoltSecondary(props, MS_OUTSIDE_OUTSIDE, false)
				+ '-'
				+ doGetTextVoltSecondary(props, MS_OUTSIDE_NEUTRAL),
				color: "blue",
			});

			// Capacity
			labelTexts.push({
				value: doGetTextCapacity(props, MS_OUTSIDE_OUTSIDE),
				color: "blue",
			});

			//OUTSIDE_OUTSIDE
			labelTexts.push({
				value: '外線-外線',
				color: "blue",
			});

			// doGetTextRatedCurrent,doGetTextPerZe
            labelTexts.push({
                value: doGetTextRatedCurrent(props, MS_OUTSIDE_OUTSIDE)
                + ','
                + doGetTextPerZe(props, MS_OUTSIDE_OUTSIDE),
                color: "blue",
            });
            
            // X/R=doGetTextXR
            labelTexts.push({
                value: 'X/R='
                + doGetTextXR(props, MS_OUTSIDE_OUTSIDE),
                color: "blue",
            });

			//OUTSIDE_NEUTRAL
			labelTexts.push({
				value: '外線-中性線',
				color: "blue",
			});

			// doGetTextRatedCurrent,doGetTextPerZe
            labelTexts.push({
                value: doGetTextRatedCurrent(props, MS_OUTSIDE_NEUTRAL)
                + ','
                + doGetTextPerZe(props, MS_OUTSIDE_NEUTRAL),
                color: "blue",
            });
            
            // X/R=doGetTextXR
            labelTexts.push({
                value: 'X/R='
                + doGetTextXR(props, MS_OUTSIDE_NEUTRAL),
                color: "blue",
            });
			break;
		case ProcessMode.PERCENT_Z:
			//OUTSIDE_OUTSIDE
			labelTexts.push({
				value: '外線-外線',
				color: "blue",
			});

			 // X/R=doGetTextXR
			 labelTexts.push({
                value: 'X/R='
                + doGetTextXR(props, MS_OUTSIDE_OUTSIDE),
                color: "blue",
            });

			// Zr=Rr+jXr
			labelTexts.push({
				value: doGetTextPerZr(props, MS_OUTSIDE_OUTSIDE, infoCircuit.stdCapacity)
				+ '='
				+ doGetTextPerRr(props, MS_OUTSIDE_OUTSIDE, infoCircuit.stdCapacity, false)
				+ '+j'
				+ doGetTextPerXr(props, MS_OUTSIDE_OUTSIDE, infoCircuit.stdCapacity, false),
				color: "blue",
            });

			//OUTSIDE_NEUTRAL
			labelTexts.push({
				value: '外線-中性線',
				color: "blue",
			});

			 // X/R=doGetTextXR
			 labelTexts.push({
                value: 'X/R='
                + doGetTextXR(props, MS_OUTSIDE_NEUTRAL),
                color: "blue",
            });

			// Zr=Rr+jXr
			labelTexts.push({
				value: doGetTextPerZr(props, MS_OUTSIDE_NEUTRAL, infoCircuit.stdCapacity)
				+ '='
				+ doGetTextPerRr(props, MS_OUTSIDE_NEUTRAL, infoCircuit.stdCapacity, false)
				+ '+j'
				+ doGetTextPerXr(props, MS_OUTSIDE_NEUTRAL, infoCircuit.stdCapacity, false),
				color: "blue",
            });
			break;
		case ProcessMode.CALCULATION:
			// VoltPrimary / VoltSecondary - VoltSecondary
			labelTexts.push({
				value: doGetTextVoltPrimary(props)
				+ '/'
				+ doGetTextVoltSecondary(props, MS_OUTSIDE_OUTSIDE, false)
				+ '-'
				+ doGetTextVoltSecondary(props, MS_OUTSIDE_NEUTRAL),
				color: "blue",
			});

			if(props.partOutOut.fault) {
				//OUTSIDE_OUTSIDE
				labelTexts.push({
					value: '外線-外線',
					color: "blue",
				});

				// += 'I"k2' + '通過' + '=' + doGetTextDirectIkSpeak
				labelTexts.push({
					value: 'I"k2通過='
					+ doGetTextDirectIkSpeak(props, MS_OUTSIDE_OUTSIDE),
					color: "blue",
				});

				// += 'I"k2' + '通過' + '=' + doGetTextDirectIkSasym
				labelTexts.push({
					value: 'I"k2通過='
					+ doGetTextDirectIkSasym(props, MS_OUTSIDE_OUTSIDE),
					color: "blue",
				});

				// += 'I"k2' + '合計' + '=' + doGetTextDirectIkSsym()
				labelTexts.push({
					value: 'I"k2合計='
					+ doGetTextDirectIkSsym(props, MS_OUTSIDE_OUTSIDE),
					color: "blue",
				});
			}

			if(props.partOutIn.fault) {
				//OUTSIDE_NEUTRAL
				labelTexts.push({
					value: '外線-中性線',
					color: "blue",
				});

				// += 'I"k2' + '通過' + '=' + doGetTextDirectIkSpeak
				labelTexts.push({
					value: 'I"k2通過='
					+ doGetTextDirectIkSpeak(props, MS_OUTSIDE_NEUTRAL),
					color: "blue",
				});

				// += 'I"k2' + '通過' + '=' + doGetTextDirectIkSasym
				labelTexts.push({
					value: 'I"k2通過='
					+ doGetTextDirectIkSasym(props, MS_OUTSIDE_NEUTRAL),
					color: "blue",
				});

				// += 'I"k2' + '合計' + '=' + doGetTextDirectIkSsym()
				labelTexts.push({
					value: 'I"k2合計='
					+ doGetTextDirectIkSsym(props, MS_OUTSIDE_NEUTRAL),
					color: "blue",
				});
			}
			break;
		case ProcessMode.VOLTDROP:
			// VoltPrimary / VoltSecondary - VoltSecondary
			labelTexts.push({
				value: doGetTextVoltPrimary(props)
				+ '/'
				+ doGetTextVoltSecondary(props, MS_OUTSIDE_OUTSIDE, false)
				+ '-'
				+ doGetTextVoltSecondary(props, MS_OUTSIDE_NEUTRAL),
				color: "blue",
			});

			if(project.dropMode != MS_VOLTDROP_REGULAR) {
				break;
			}

			if(props.partOutOut.dropRegular) {
				//OUTSIDE_OUTSIDE
				labelTexts.push({
					value: '外線-外線',
					color: "blue",
				});

				 // IL=doGetTextLoadCurrent,力率=doGetTextDropVoltPF
				 labelTexts.push({
                    value: 'IL='
                    + doGetTextLoadCurrent(props, MS_OUTSIDE_OUTSIDE)
                    + ',力率='
                    + doGetTextDropVoltPF(props, MS_OUTSIDE_OUTSIDE),
                    color: "blue",
                });

                //Vd=doGetTextDropVoltDiff (doGetTextVoltage-doGetTextDropVoltRegular)
                let sTemp = 'Vd=';
                sTemp += doGetTextDropVoltDiff(props, infoCircuit.stdCapacity, project.opeMode, MS_OUTSIDE_OUTSIDE);
                sTemp += ' (';
                if(props.supply) {
                    sTemp += doGetTextVoltSecondary(props, MS_OUTSIDE_OUTSIDE, false);
                }
                else {
                    sTemp += '0';
                }
                sTemp += '-';
                sTemp += doGetTextDropVoltRegular(props, infoCircuit.stdCapacity, MS_OUTSIDE_OUTSIDE, false);
                sTemp += ')';
                labelTexts.push({
                    value: sTemp,
                    color: "blue",
                });

                //Vp=doGetTextDropPerRegular (doGetTextDropPerRegular2)
                labelTexts.push({
                    value: 'Vp='
                    + doGetTextDropPerRegular(props, infoCircuit.stdCapacity, MS_OUTSIDE_OUTSIDE)
                    + ' ('
                    + doGetTextDropPerRegular2(props, infoCircuit.stdCapacity, MS_OUTSIDE_OUTSIDE)
                    + ')',
                    color: "blue",
                });
			}

			if(props.partOutIn.dropRegular) {
				//OUTSIDE_NEUTRAL
				labelTexts.push({
					value: '外線-中性線',
					color: "blue",
				});

				 // IL=doGetTextLoadCurrent,力率=doGetTextDropVoltPF
				 labelTexts.push({
                    value: 'IL='
                    + doGetTextLoadCurrent(props, MS_OUTSIDE_NEUTRAL)
                    + ',力率='
                    + doGetTextDropVoltPF(props, MS_OUTSIDE_NEUTRAL),
                    color: "blue",
                });

                //Vd=doGetTextDropVoltDiff (doGetTextVoltage-doGetTextDropVoltRegular)
                let sTemp = 'Vd=';
                sTemp += doGetTextDropVoltDiff(props, infoCircuit.stdCapacity, project.opeMode, MS_OUTSIDE_NEUTRAL);
                sTemp += ' (';
                if(props.supply) {
                    sTemp += doGetTextVoltSecondary(props, MS_OUTSIDE_NEUTRAL, false);
                }
                else {
                    sTemp += '0';
                }
                sTemp += '-';
                sTemp += doGetTextDropVoltRegular(props, infoCircuit.stdCapacity, MS_OUTSIDE_NEUTRAL, false);
                sTemp += ')';
                labelTexts.push({
                    value: sTemp,
                    color: "blue",
                });

                //Vp=doGetTextDropPerRegular (doGetTextDropPerRegular2)
                labelTexts.push({
                    value: 'Vp='
                    + doGetTextDropPerRegular(props, infoCircuit.stdCapacity, MS_OUTSIDE_NEUTRAL)
                    + ' ('
                    + doGetTextDropPerRegular2(props, infoCircuit.stdCapacity, MS_OUTSIDE_NEUTRAL)
                    + ')',
                    color: "blue",
                });
			}
			break;
		case ProcessMode.HARMONIC:
			// VoltPrimary / VoltSecondary - VoltSecondary
			labelTexts.push({
				value: doGetTextVoltPrimary(props)
				+ '/'
				+ doGetTextVoltSecondary(props, MS_OUTSIDE_OUTSIDE, false)
				+ '-'
				+ doGetTextVoltSecondary(props, MS_OUTSIDE_NEUTRAL),
				color: "blue",
			});
			break;
		case ProcessMode.POWER_FLOW:
			break;
	}

	return labelTexts;
}

export const doGetRefV2_0 = (properties: MsTrans1Model, dNominalVolt: number, nPartKind: number) => {
	let infos = []
	infos.push(properties.refNo)
    switch (nPartKind) {
        case 1:        // 外線－中性線間
            {
                infos.push('外線‐中性線,' + properties.partOutIn.pointText.toString())
                infos.push(properties.capacity / 2 + `kVA`)
                if (properties.voltageSecondary / 2 === dNominalVolt)
                {
                    infos.push(
                        BeamsNumber(doGetValueRatedCurrent(properties, nPartKind, properties.voltageSecondary, properties.capacity), 4) 
                        + 'A'
                    )
                }
                else
                {
                    const dRatedCurrent = doGetValueRatedCurrent(properties, nPartKind, properties.voltageSecondary, properties.capacity)
                    infos.push(
                        BeamsNumber(dRatedCurrent * (properties.voltageSecondary / 2) / dNominalVolt, 4)
                        + 'A'
                        + '('
                        + BeamsNumber(doGetValueRatedCurrent(properties, nPartKind, properties.voltageSecondary, properties.capacity), 4)
                        + 'A'
                        + ')'
                    )
                }
                infos.push(properties.partOutIn.inrushTimes + `倍,` + properties.partOutIn.dampingTime + `ms`)
            }

            break;
        case 2:        // 外線間
            {
                infos.push('外線‐外線,' + properties.partOutOut.pointText) 
                infos.push(properties.capacity + `kVA`)
                if (properties.voltageSecondary === dNominalVolt)
                {
                    infos.push(
                        BeamsNumber(doGetValueRatedCurrent(properties, nPartKind, properties.voltageSecondary, properties.capacity), 4) 
                        + 'A'
                    )
                }
                else
                {
                    const dRatedCurrent = doGetValueRatedCurrent(properties, nPartKind, properties.voltageSecondary, properties.capacity)
                    infos.push(
                        BeamsNumber(dRatedCurrent * properties.voltageSecondary / dNominalVolt, 4)
                        + 'A'
                        + '('
                        + BeamsNumber(doGetValueRatedCurrent(properties, nPartKind, properties.voltageSecondary, properties.capacity), 4)
                        + 'A'
                        + ')'
                    )
                }
                infos.push(properties.partOutOut.inrushTimes + `倍,` + properties.partOutOut.dampingTime + `ms`)
            }
            break;
    }
	return infos
}

export const doGetRefV2_1 = (properties: MsTrans1Model, dNominalVolt: number, nPartKind: number) => {
	let infos = []
    infos.push(properties.refNo)
    switch (nPartKind)
    {
        case 1:        // 外線－中性線間
            {
                infos.push('外線‐中性線,' + properties.partOutIn.pointText.toString())
                infos.push(properties.capacity / 2 + `kVA`)
                infos.push(properties.partOutIn.intensityTimes + `倍,`)
                if (properties.voltageSecondary / 2 === dNominalVolt)
                {
                    const dRatedCurrent = doGetValueRatedCurrent(properties, nPartKind, properties.voltageSecondary, properties.capacity)
                    const dIntensityValue = dRatedCurrent === 0 ? 0 : dRatedCurrent * properties.partOutIn.intensityTimes
                    infos.push(
                        BeamsNumber(dIntensityValue, 4) 
                        + 'Arms'
                    )
                }
                else
                {   
                    const dRatedCurrent = doGetValueRatedCurrent(properties, nPartKind, properties.voltageSecondary, properties.capacity)
                    const dIntensityValue = dRatedCurrent === 0 ? 0 : dRatedCurrent * properties.partOutIn.intensityTimes * (properties.voltageSecondary / 2) / dNominalVolt
                    infos.push(
                        BeamsNumber(dIntensityValue, 4) 
                        + 'Arms'
                        + '('
                        +  (dRatedCurrent === 0) ? '0' : dRatedCurrent * properties.partOutIn.intensityTimes
                        + 'Arms'
                        + ')'
                    )
                }
                infos.push(properties.partOutIn.intensityTime + `s`)
            }
            break;
        case 2:        // 外線間
            {
                infos.push('外線‐外線,' + properties.partOutOut.pointText.toString())
                infos.push(properties.capacity + `kVA`)
                infos.push(properties.partOutOut.intensityTimes + `倍,`)
                if (properties.voltageSecondary === dNominalVolt)
                {
                    const dRatedCurrent = doGetValueRatedCurrent(properties, nPartKind, properties.voltageSecondary, properties.capacity)
                    const dIntensityValue = dRatedCurrent === 0 ? 0 : dRatedCurrent * properties.partOutOut.intensityTimes
                    infos.push(
                        BeamsNumber(dIntensityValue, 4) 
                        + 'Arms'
                    )
                }
                else
                {   
                    const dRatedCurrent = doGetValueRatedCurrent(properties, nPartKind, properties.voltageSecondary, properties.capacity)
                    const dIntensityValue = dRatedCurrent === 0 ? 0 : dRatedCurrent * properties.partOutOut.intensityTimes * properties.voltageSecondary / dNominalVolt
                    infos.push(
                        BeamsNumber(dIntensityValue, 4) 
                        + 'Arms'
                        + '('
                        +  (dRatedCurrent === 0) ? '0' : dRatedCurrent * properties.partOutOut.intensityTimes
                        + 'Arms'
                        + ')'
                    )
                }
                infos.push(properties.partOutOut.intensityTime + `s`)
            }
            break;
    }
	return infos
}

//CEleTrans1::DoGetTextDirectPercentZr
export const doGetTextDirectPercentZr = (props:ControlModel, nPartKind:number, bUnit:boolean = true) =>
{
	let dPerZ = 0;
	switch (nPartKind) {
	case MS_OUTSIDE_NEUTRAL:		
		dPerZ = doGetPerZDirectMG(props.properties.partOutIn.calcPoint).dPercentZ;
		break;
	case MS_OUTSIDE_OUTSIDE:		
		dPerZ = doGetPerZDirectMG(props.properties.partOutOut.calcPoint).dPercentZ;
		break;
	}
	let sText = '';
	if (dPerZ >= 0) {
		sText = BeamsNumber(dPerZ, 4).toString();
		if (bUnit == true) {
			sText += '%r';
		}
	}
	else {							
		sText = '????';
	}
	return sText;
}

//CEleTrans1::DoGetTextFlowsResult
export const doGetTextFlowsResult = (props:MsTrans1Model, processMode:any, nDirect:number, bSociety:boolean = false) =>
{
	let sRefV1:string[] = [];

	if (bSociety) {
		return sRefV1;
	}

	switch (processMode) {
	case MS_MODE_DRAWING:
	case MS_MODE_CHECKING:
	case MS_MODE_PERCENT_Z:
	case MS_MODE_CALCULATION:
	case MS_MODE_VOLTDROP:
	case MS_MODE_HARMONIC:
		break;
	case MS_MODE_POWER_FLOW:
		if (props.activePower !==0 && props.reactivePower !==0) {
			if (nDirect == DIRECT_NONE) {
				sRefV1.push(BeamsNumber(Math.abs(props.activePower),5).toString() + 'kW');
				sRefV1.push(BeamsNumber(Math.abs(props.reactivePower),5).toString() + 'kvar');
			}
			else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
				sRefV1.push(BeamsNumber(Math.abs(props.activePower),5).toString() + 'kW');
				let sTemp = ''
				if (props.reactivePower > 0) {
					sTemp += "+j";
				}
				else {
					sTemp += "-j";
				}
				sRefV1.push(sTemp + BeamsNumber(Math.abs(props.reactivePower),5).toString() + 'kvar');
			}
		}
		break;
	}

	return sRefV1;
}