import { Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Grid, IconButton, Stack, Typography } from "@mui/material";
import { connect } from "react-redux";
import ReportGrid from "./ReportGrip";
import { ControlModel, DiagramModel, InfoCircuit, InfoCover, Message, OptionModel, Report } from "../../models/Index";
import { memo, useEffect, useRef, useState } from "react";
import * as EKind from "../../models/ElementKind";
import { getElementKindValue } from "../../utils/ElementFunction";
import { elementTypeENtoJP, reportIndexToName } from "../../statics";
import { actionCreators } from "../../store";
import FetchHelper from "../FetchHelper";
import { downloadFile } from "../../utils/downloadFile";
import SettingReportDialog from "../dialogs/SettingReportDialog";
import SettingCoverDialog from "../dialogs/SettingCoverDialog";
import SettingCover1Dialog from "../dialogs/SettingCover1Dialog";
import SettingCover2Dialog from "../dialogs/SettingCover2Dialog";
import { Close } from "@mui/icons-material";
import * as Constant from "../../models/Constants";
import PreviewDialog from "../dialogs/PreviewDialog";

// Fetch ID
const REPORT_PDF = "REPORT_PDF"
const REPORT_CSV = "REPORT_CSV"
const SAVE_INFO_COVER_DB = "SAVE_INFO_COVER_DB"
const SAVE_REPORT_DB = "SAVE_REPORT_DB"
const GET_REPORT = "GET_REPORT"
type ReportSceenProps = {
  diagramDataList: DiagramModel[];
  projectData:any;
  ownerProject:number
};

export type PureReportSceenProps = ReportSceenProps &
  ReturnType<typeof mapStateToProps> & 
  ReturnType<typeof mapDispatchToProps>

const ReportSceen = memo((props: PureReportSceenProps) => {
  const {
    userId,
    userRole,
    projectData,
    ownerProject,
    modeViewOnly,
    report,
    infoCover,
    infoCircuit,
    infoSkeleton,
    diagramDataList,
    processMode,
    getPDFReport,
    getCSVReport,
    onShowMessage,
    saveReportToDB,
    // saveReport,
    saveInfoCoverToDB,
    saveInfoCover,
    getReport,
    saveInfoCircuit,
  } = props;
  
  const [initData, setInitData]  = useState<any[]>([])
  const [loadingFlag, setLoadingFlag] = useState(false);
  const [listElementKind, setListElementKind] = useState([{ label: "すべて", value: 0 }])
  const [listTransCenter, setListTransCenter] = useState([{ label: "すべて", value: 0 }])
  const [openReportForm, setOpenReportForm] = useState(false);
  const [openMemoForm, setOpenMemoForm] = useState(false);

  const [openCoverForm, setOpenCoverForm] = useState(false);
  const [openCover1Form, setOpenCover1Form] = useState(false);
  const [openCover2Form, setOpenCover2Form] = useState(false);
  const [reportIndex, setReportIndex] = useState(-1);
  const [reportData, setReportData] = useState();
  const [reportEditDialogTitle, setReportEditDialogTitle] = useState('');

  const [openPdfPreview, setOpenPdfPreview] = useState(false);
  const refIframe = useRef<any>();

  useEffect(() => {
    let reportList: any[] = []
    if (userRole && !(userRole == Constant.ROLE_LP)){
      reportList = [
        {target: false, reportIndex: "7", eleKind : null, reportNoTransCenter: null, canExportCSV: false},
        {target: false, reportIndex: "8", eleKind : null, reportNoTransCenter: null, canExportCSV: false},
        {target: false, reportIndex: "9", eleKind : null, reportNoTransCenter: null, canExportCSV: false},
        {target: false, reportIndex: "0", eleKind : "0", reportNoTransCenter: "0", canExportCSV: true},
        {target: false, reportIndex: "1", eleKind : null, reportNoTransCenter: null, canExportCSV: true},
        {target: false, reportIndex: "2", eleKind : "0", reportNoTransCenter: "0", canExportCSV: true},
        {target: false, reportIndex: "3", eleKind : "0", reportNoTransCenter: "0", canExportCSV: true},
        {target: false, reportIndex: "11", eleKind : "0", reportNoTransCenter: "0", canExportCSV: false},
        {target: false, reportIndex: "10", eleKind : "0", reportNoTransCenter: "0", canExportCSV: true},
        {target: false, reportIndex: "17", eleKind : "0", reportNoTransCenter: "0", canExportCSV: false},
        {target: false, reportIndex: "4", eleKind : "0", reportNoTransCenter: "0", canExportCSV: true},
        {target: false, reportIndex: "5", eleKind : null, reportNoTransCenter: null, canExportCSV: true},
        {target: false, reportIndex: "6", eleKind : null, reportNoTransCenter: null, canExportCSV: true},
        {target: false, reportIndex: "16", eleKind : "0", reportNoTransCenter: "0", canExportCSV: true},
        {target: false, reportIndex: "18", eleKind : null, reportNoTransCenter: null, canExportCSV: true},
      ]
    } else {
      reportList = [
        {target: false, reportIndex: "0", eleKind : "0", reportNoTransCenter: "0", canExportCSV: true},
        {target: false, reportIndex: "1", eleKind : null, reportNoTransCenter: null, canExportCSV: true},
        {target: false, reportIndex: "2", eleKind : "0", reportNoTransCenter: "0", canExportCSV: true},
        {target: false, reportIndex: "3", eleKind : "0", reportNoTransCenter: "0", canExportCSV: true},
        {target: false, reportIndex: "11", eleKind : "0", reportNoTransCenter: "0", canExportCSV: false},
        {target: false, reportIndex: "10", eleKind : "0", reportNoTransCenter: "0", canExportCSV: true},
        {target: false, reportIndex: "17", eleKind : "0", reportNoTransCenter: "0", canExportCSV: false},
        {target: false, reportIndex: "4", eleKind : "0", reportNoTransCenter: "0", canExportCSV: true},
        {target: false, reportIndex: "5", eleKind : null, reportNoTransCenter: null, canExportCSV: true},
        {target: false, reportIndex: "16", eleKind : "0", reportNoTransCenter: "0", canExportCSV: true},
        {target: false, reportIndex: "18", eleKind : null, reportNoTransCenter: null, canExportCSV: true},
      ]
    }
    setInitData(reportList)
  },[userRole])

  useEffect(() => {
    let allControl: ControlModel[] = []
    diagramDataList.forEach((e: DiagramModel) => allControl = allControl.concat(e.shape))
    if (allControl.length > 0){
      // element kind
      const dictEleKind: any = elementTypeENtoJP
      let listElementType = allControl.map(e => e.type)
      let uniqElementType = Array.from(new Set(listElementType));
      let newListElementType: OptionModel[] = []
      uniqElementType.map((e) => newListElementType.push({
        label: dictEleKind[e] || e,
        value: getElementKindValue(e).toString()
      }))
      setListElementKind([...listElementKind, ...newListElementType])
      // trans center
      let tmpListTransCenter = allControl.filter(e => e.type === EKind.MS_TRANSCENTER)
      let newListTransCenter: OptionModel[] = []
      tmpListTransCenter.map(e => newListTransCenter.push({
        label: e.properties?.refNo,
        value: e.properties?.noTransCenter.toString()
      }))
      setListTransCenter([...listTransCenter, ...newListTransCenter])
    }
  },[diagramDataList])

  const handleSetSelectAll = (value: boolean) => {
    let newData = [...initData]
    newData.forEach((e: any) => e.target = value)
    setInitData(newData)
  }

  const handleDowloadAllPDF = () => {
    let paramData = structuredClone(initData)
    paramData.forEach((e: any) => delete e.canExportCSV)
    
    const params = {
      userId: userId,
      projectId: parseInt(projectData.projectId),
      processMode: processMode,
      data: paramData,
      userRole: userRole,
      ownerProject: ownerProject
    };

    setLoadingFlag(true)
    getPDFReport(params);
  }
  const handleEditReport =(data:any) =>{
    if(data.reportIndex == 7){
      setReportIndex(7)
      setReportEditDialogTitle(reportIndexToName['7'])
      setOpenCoverForm(true)
    }
    else if(data.reportIndex == 8){
      setReportIndex(8)
      setReportEditDialogTitle(reportIndexToName['8'])
      setOpenCover1Form(true)
    }
    else if(data.reportIndex == 9){
      setReportIndex(9)
      setReportEditDialogTitle(reportIndexToName['9'])
      setOpenCover2Form(true)
    }
    else if(data.reportIndex == 18){
      const params = {
        userId: userId,
        projectId: parseInt(projectData.projectId),
        reportIndex: parseInt(data.reportIndex),
        ownerProject: ownerProject
      };
      getReport(params)
      setReportIndex(18)
      setReportEditDialogTitle(reportIndexToName['18'])
      // setOpenMemoForm(true)
    }
    else{
      const params = {
        userId: userId,
        projectId: parseInt(projectData.projectId),
        reportIndex: parseInt(data.reportIndex),
        ownerProject: ownerProject
      };
      getReport(params)
      setReportIndex(data.reportIndex)
      data.reportIndex >= 0 && data.reportIndex <=18 && setReportEditDialogTitle(reportIndexToName[data.reportIndex as keyof typeof reportIndexToName])
    }

  }

  const handlePreviewPDF = (data: any) => {
    let params = {
      userId: userId,
      projectId: parseInt(projectData.projectId),
      processMode: processMode,
      data: [data],
      userRole: userRole,
      ownerProject: ownerProject
    }
    setLoadingFlag(true)
    setOpenPdfPreview(true);
    getPDFReport(params)
  }

  const handleDowloadPDF = (data: any) => {
    let params = {
      userId: userId,
      projectId: parseInt(projectData.projectId),
      processMode: processMode,
      data: [data],
      userRole: userRole,
      ownerProject: ownerProject

    }
    setLoadingFlag(true)
    getPDFReport(params)
  }
  const handleOKReportFrom =(data: Report) =>{
    setLoadingFlag(true); 
    // saveReport(data);
    // //TODO
    saveReportToDB({
        userId: userId,
        projectId: projectData.projectId,
        ...data,
        reportIndex: reportIndex,
        ownerProject: ownerProject
    });
    saveInfoCircuit({...infoCircuit,printDetailHeadView3: data.printDetailHead})
    setOpenReportForm(false)
  }
  const handleOKMemoFrom =(data: Report) =>{
    setLoadingFlag(true); 
    // saveReport(data);
    // //TODO
    saveReportToDB({
        userId: userId,
        projectId: projectData.projectId,
        ...data,
        reportIndex: reportIndex,
        ownerProject: ownerProject

    });
    saveInfoCircuit({...infoCircuit,printDetailHeadView3: data.printDetailHead})
    setOpenMemoForm(false)
  }
  const handleOKCoverFrom =(data: InfoCover) =>{
    setLoadingFlag(true); 
    saveInfoCover(data);
    //TODO
    saveInfoCoverToDB({
        userId: userId,
        projectId: projectData.projectId,
        ownerProject: ownerProject,
        ...data
    });
    setOpenCoverForm(false)
  }
  const handleOKCover1From =(data: InfoCover) =>{
    setLoadingFlag(true); 
    saveInfoCover(data);
    //TODO
    saveInfoCoverToDB({
        userId: userId,
        projectId: projectData.projectId,
        ownerProject: ownerProject,
        ...data
    });
    setOpenCover1Form(false)
  }
  const handleOKCover2From =(data: InfoCover) =>{
    setLoadingFlag(true); 
    saveInfoCover(data);
    //TODO
    saveInfoCoverToDB({
        userId: userId,
        projectId: projectData.projectId,
        ownerProject: ownerProject,
        ...data
    });
    setOpenCover2Form(false)
  }
  const handleDowloadCSV = (data: any) => {
    let params = {
      userId: userId,
      projectId: parseInt(projectData.projectId),
      processMode: processMode,
      ...data,
      userRole: userRole,
      ownerProject: ownerProject
    }
    setLoadingFlag(true)
    getCSVReport(params)
  }

  const handleGetReportPDFSuccess = (data: any) => {
    if (data.data) 
    {
      if (openPdfPreview) {
        refIframe.current.src = "data:application/pdf;base64," + data.data.data;
      } else {
        downloadFile(data.data);
      }
    }
    setLoadingFlag(false)
  };

  const handleGetReportCSVSuccess = (data: any) => {
    if (data.data) downloadFile(data.data)
    setLoadingFlag(false)
  };
  const handleGetReportSuccess = (data:any) =>{
    if(data.data)
     {
      if(reportIndex === 18){
        setReportData(data.data[0])
        setReportEditDialogTitle(reportIndexToName['18'])
        setOpenMemoForm(true)
      }
      else{
        setReportData(data.data[0])
        setOpenReportForm(true)
      }
     }
  }

  const handleError = (error: any) => {
    console.log('>>> handleError' + error)
    setLoadingFlag(false)
  };
  
  return (
    <>
      {loadingFlag && (
        <div
          style={{
            top: "0px",
            left: "0px",
            position: "fixed",
            zIndex: 1500,
            width: "100%",
            height: "100%",
            padding: "50vh 50% 50% 50%",
            background: "#00000030",
          }}
        >
          <CircularProgress />
        </div>
      )}
      
      <PreviewDialog refIframe={refIframe} openDialog={openPdfPreview} setOpenDialog={setOpenPdfPreview}/>
      {openReportForm &&
        <SettingReportDialog modeViewOnly={modeViewOnly} infoSkeleton={infoSkeleton} infoCircuit={infoCircuit} data={reportData} title={reportEditDialogTitle} onOK={handleOKReportFrom} onCancel={() => setOpenReportForm(false)}  />
      }
      {openMemoForm &&
        <SettingReportDialog modeViewOnly={modeViewOnly} infoSkeleton={infoSkeleton} infoCircuit= {infoCircuit} reportIndexEdit={18} data={reportData} title={reportEditDialogTitle} onOK={handleOKMemoFrom} onCancel={() => setOpenMemoForm(false)}  />
      }
      {openCoverForm &&
          <SettingCoverDialog modeViewOnly={modeViewOnly} infoCircuit={infoCircuit} data={infoCover} title={reportEditDialogTitle} onOK={handleOKCoverFrom} onCancel={() => setOpenCoverForm(false)}  />
        }
      {openCover1Form &&
        <SettingCover1Dialog modeViewOnly={modeViewOnly} infoCircuit={infoCircuit} data={infoCover} title={reportEditDialogTitle} onOK={handleOKCover1From} onCancel={() => setOpenCover1Form(false)}  />
      }
      {openCover2Form &&
          <SettingCover2Dialog modeViewOnly={modeViewOnly} infoCircuit={infoCircuit} data={infoCover} title={reportEditDialogTitle}  onOK={handleOKCover2From} onCancel={() => setOpenCover2Form(false)}  />
        }
      <Stack style={{ background: "#F9FAFB" }}>
        <Stack alignSelf={"center"} height={"80vh"} width={"80%"}>
          <Stack
            py={1}
            sx={{ flexDirection: "row", justifyContent: "flex-end" }}
          >
            <Button
              size="small"
              variant="contained"
              color="inherit"
              onClick={() => handleSetSelectAll(true)}
            >
              全選択
            </Button>
            <Button
              size="small"
              variant="contained"
              color="inherit"
              style={{ marginLeft: "16px", marginRight: "16px" }}
              onClick={() => handleSetSelectAll(false)}
            >
              全解除
            </Button>
            <Button
              size="small"
              variant="contained"
              onClick={handleDowloadAllPDF}
            >
              PDF一括ダウンロード
            </Button>
          </Stack>
          <ReportGrid
            listElementKind={listElementKind}
            listTransCenter={listTransCenter}
            rowData={initData}
            previewPDF={handlePreviewPDF}
            downloadPDF={handleDowloadPDF}
            downloadCSV={handleDowloadCSV}
            editReport = {handleEditReport}
          />
        </Stack>
      </Stack>

      <FetchHelper
        fetchId={REPORT_PDF}
        onComplete={(success, data, error) =>
          success ? handleGetReportPDFSuccess(data) : handleError(error)
        }
      />
      <FetchHelper
        fetchId={REPORT_CSV}
        onComplete={(success, data, error) =>
          success ? handleGetReportCSVSuccess(data) : handleError(error)
        }
      />
      <FetchHelper
        fetchId={GET_REPORT}
        onComplete={(success, data, error) =>
          success ? handleGetReportSuccess(data) : handleError(error)
        }
      />
      <FetchHelper
                fetchId={SAVE_INFO_COVER_DB}
                onComplete={(success, data, error) => {
                    setLoadingFlag(false)
                    success 
                    || onShowMessage({
                        type: "error",
                        title: "単線結線図の設定",
                        body: "単線結線図の設定の保存に失敗しました",
                    });
                }}
            />     
            <FetchHelper
                fetchId={SAVE_REPORT_DB}
                onComplete={(success, data, error) => {
                    setLoadingFlag(false)
                    success 
                    || onShowMessage({
                        type: "error",
                        title: "レポートの設定",
                        body: "レポートの設定の保存に失敗しました",
                    });
                }}
            />     
    </>
  );
});

const mapStateToProps = (state: any) => {
  return {
    userId: state.app.user?.userId,
    processMode: state.app.diagram.processMode,
    report: state.app.diagram.report,
    infoCover: state.app.diagram.infoCover,
    infoCircuit: state.app.diagram.infoCircuit,
    infoSkeleton: state.app.diagram.infoSkeleton,
    userRole: state.app.user?.userRole,
    modeViewOnly: state.app.diagram.modeViewOnly,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    getPDFReport: (params: any) =>
      dispatch(
        actionCreators.fetch(
          REPORT_PDF,
          "/report/get-pdf",
          "POST",
          params,
          false,
          true
        )
      ),
    getCSVReport: (params: any) =>
      dispatch(
        actionCreators.fetch(
          REPORT_CSV,
          "/report/get-csv",
          "POST",
          params,
          false,
          true
        )
      ),

    onShowMessage: (message: Message) => dispatch(actionCreators.showMessage(message)),
    saveReportToDB: (params : any) => dispatch(actionCreators.fetch(SAVE_REPORT_DB, "/diagram/set-report", "POST", params, false, true)),
    // saveReport: (data: Report) => dispatch(actionCreators.saveReport(data)),
    saveInfoCoverToDB: (params : any) => dispatch(actionCreators.fetch(SAVE_INFO_COVER_DB, "/diagram/set-info-cover", "POST", params, false, true)),
    saveInfoCover: (data: InfoCover) => dispatch(actionCreators.saveInfoCover(data)),
    getReport:(params:any)=> dispatch(actionCreators.fetch(GET_REPORT, "/diagram/get-report", "POST", params, false, true)),
    saveInfoCircuit: (data: InfoCircuit) => dispatch(actionCreators.saveInfoCircuit(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ReportSceen);
