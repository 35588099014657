import { LoadingButton } from "@mui/lab";
import { Box, Button, Checkbox, CircularProgress, FormControlLabel, Grid, Stack, Tab, Tabs, TextField, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DialCharacterModel, DialTimeModel } from "../../../models/breakModel";
import { Path as RoutePath } from "../../../path";
import { ApplicationState, actionCreators, selectors } from "../../../store";
import FetchHelper from "../../FetchHelper";
//-----------
//FETCH ID
const GET_DIAL_TIME = "GET_DIAL_TIME"
const UPDATE_DIAL_TIME = "UPDATE_DIAL_TIME"
const CREATE_DIAL_TIME = "CREATE_DIAL_TIME"
const GET_DIAL_TIME_BY_ID = "GET_DIAL_TIME_BY_ID"
const initialValue: DialTimeModel = {
    lDialTimeID: 0,
    dFixedValue: "",
    Mv3breakDialCharacter: {} as DialCharacterModel,
    bIsDefault: false,
    sFixedName: "",
    dContiDefValue: "",
    dContiWidthValue: "",
    dContiMiniValue: "",
    dContiMaxValue: "",
}
// -------------
//Tabs------------------
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `oilManagement-tab-${index}`,
        'aria-controls': `oilManagement-tabpanel-${index}`,
    };
}
// -------------
// Props
export type DialTimeEditProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
export const DialTimeEdit = (props: DialTimeEditProps) => {
    const {
        userId,
        fetchUpdate,
        onCreate,
        onCreateError,
        onUpdate,
        onUpdateError,
        onSuccess,
        onError,
        onSearchById,
    } = props;

    const { control, formState: { errors }, handleSubmit, setValue, getValues, } = useForm<DialTimeModel>({
        criteriaMode: "all",
        defaultValues: initialValue,
        mode: "all",
    })
    type MyParams = {
        id: string;
        mode: string;
    };
    let { id, mode } = useParams<keyof MyParams>() as MyParams;
    const gridRef = useRef() as any;

    const location = useLocation();
    let navigate = useNavigate();

    const [selectTab, setSelectTab] = useState(0);
    const [loadingFlag, setLoadingFlag] = useState(mode === "edit" ? true : false);

    let lDialCharid = location.state ? location.state.lDialCharid : undefined;
    let tab = location.state && location.state.tab;

    useEffect(() => {
        if (mode === "add")
            lDialCharid ? 
                setValue("Mv3breakDialCharacter.lDialCharID", lDialCharid)
                : navigate(RoutePath.DialTimeList);
        else if (mode === "edit") {
            const params = {
                userId: userId,
                requestPrms: {
                    id: id
                }
            }
            
            setLoadingFlag(true);
            onSearchById(params);
        }
    }, [])

    const handleSuccess = (data: any) => {
        console.log('>>>> handle Successs');
        if (data.data){
            setValue("lDialTimeID", data.data.ldialTimeID);
            setValue("bIsDefault", data.data.bisDefault);
            setValue("dContiDefValue", data.data.dcontiDefValue);
            setValue("dContiMaxValue", data.data.dcontiMaxValue);
            setValue("dContiMiniValue", data.data.dcontiMiniValue);
            setValue("dContiWidthValue", data.data.dcontiWidthValue);
            setValue("sFixedName", data.data.sfixedName);
            setValue("dFixedValue", data.data.dfixedValue);
            // setValue('Mv3breakDialCharacter.sCharacterName', data.data.mv3breakDialCharacter.scharacterName);
            setValue("Mv3breakDialCharacter.lDialCharID", data.data.ldialCharID)
        }
        
        if (tab !== undefined && tab !== null) {
            setSelectTab(tab)
        }
        setLoadingFlag(false);
    }

    // 検索エラー時
    const handleError = (success: boolean, data: any, error: any) => {
        console.log('>>>> handle Error');
        gridRef.current.hideOverlay();
        setLoadingFlag(false);
    }

    const tabHandleChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectTab(newValue);
        navigate(".", {
            replace: true,
            state: {
                tab: newValue,
                lDialCharid: lDialCharid,
            }
        });
    }

    // 検索実行
    const handleEditSubmit = (data: DialTimeModel) => {
        setLoadingFlag(true);
        if (mode === "edit") {
            let params = {
                lDialTimeID: getValues("lDialTimeID"),
                lDialCharID: getValues("Mv3breakDialCharacter.lDialCharID"),
                bIsDefault: getValues("bIsDefault"),
                sFixedName: getValues("sFixedName"),
                dFixedValue: getValues("dFixedValue"),
                dContiDefValue: getValues("dContiDefValue"),
                dContiMiniValue: getValues("dContiMiniValue"),
                dContiWidthValue: getValues("dContiWidthValue"),
                dContiMaxValue: getValues("dContiMaxValue"),
            }
            const request = {
                userId: userId,
                requestPrms: params
            }

            onUpdate(request);
        } else if (mode === "add") {
            let params = {
                lDialCharID: lDialCharid,
                bIsDefault: getValues("bIsDefault"),
                sFixedName: getValues("sFixedName"),
                dFixedValue: getValues("dFixedValue"),
                dContiDefValue: getValues("dContiDefValue"),
                dContiMiniValue: getValues("dContiMiniValue"),
                dContiWidthValue: getValues("dContiWidthValue"),
                dContiMaxValue: getValues("dContiMaxValue"),
            }
            
            const request = {
                userId: userId,
                requestPrms: params
            }
            onCreate(request);
        }
        // setLoadingFlag(false);
    }

    // キャンセル
    const handleCancel = () => {
        if (location.key !== "default")
            navigate(-1);
        else
            navigate(RoutePath.DialTimeList);
    }

    // Submit後のAPI呼び出し成功時
    const handleSuccessUpdate = (success: boolean, data: any, error: any) => {
        onSuccess();
        // if (lDialCharid !== undefined && lDialCharid !== null) {
        //     navigate(-1);
        // }
        // if(mode ==="edit"){
        //     navigate(RoutePath.DialTimeEdit+ `/edit/${data.data.lDialTimeID}`,{replace:true})
        // }
        setLoadingFlag(false)
    }
    // Submit後のAPI呼び出しエラー時
    const handleErrorUpdate = (success: boolean, data: any, error: any) => {
        onUpdateError();
        setLoadingFlag(false);
    }

    // Submit後のAPI呼び出し成功時
    const handleSubmitSuccess = (success: boolean, data: any, error: any) => {
        onSuccess();
        // if (lDialCharid !== undefined && lDialCharid !== null) {
        //     navigate(-1);
        // }
        if(mode ==="add"){
            navigate(RoutePath.DialTimeEdit+ `/edit/${data.data.lDialTimeID}`,{replace:true})
        }
        setLoadingFlag(false)
    }

    // Submit後のAPI呼び出しエラー時
    const handleSubmitError = (success: boolean, data: any, error: any) => {
        onCreateError();
        setLoadingFlag(false);
    }

    //入力欄背景
    const divStyles = {
        backgroundColor: 'white',
        border: '1px solid #bebebe',
        boxShadow: '0 0 5px #fff, 0 0 5px #bebebe, 0 0 1px #aaa',
        padding: '20px 40px',
        marginTop: '40px',
    }

    return (
        <>
            {loadingFlag && (
                <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1500, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                    <CircularProgress />
                </div>
            )}
            <div style={{ height: "100%", width: "100%", marginTop: "10px" }}>
                <Stack direction="row" justifyContent="flex-start" alignItems="center" sx={{}} style={{ margin: '18px', color: 'MenuText' }}>
                    <Typography variant="h4">
                        時間ダイアル詳細
                    </Typography>
                </Stack>
                <form onSubmit={handleSubmit(handleEditSubmit)}>
                    <Stack direction='row'>
                        <div style={{ top: "100px", position: "absolute", zIndex: 1200 }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={selectTab} onChange={tabHandleChange} aria-label="oilManagementDetailTabs">
                                    <Tab label='時間ダイアル' {...a11yProps(0)} />
                                </Tabs>
                            </Box>
                        </div>
                    </Stack>
                    {/* 時間ダイアル */}
                    <TabPanel value={selectTab} index={0}>
                        <div style={divStyles}>
                            <Typography variant="h6">
                                時間ダイアル
                            </Typography>
                            <Stack direction={{ xs: 'column', sm: 'row' }}  mt={3} sx={{ maxHeight:"calc(100vh)", overflow: 'auto' }}>
                                <Grid container spacing={3} direction={'row'}>
                                    {/* <Grid item xs={6} mt={1}>
                                        <Controller
                                            control={control}
                                            name="lDialTimeID"
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    label="時間ダイアルID"
                                                    type="text"
                                                    size="small"
                                                    disabled
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                            )}
                                        />
                                    </Grid> */}
                                    <Grid item xs={6} mt={1}>
                                        <Controller
                                            control={control}
                                            name="Mv3breakDialCharacter.lDialCharID"
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    type="text"
                                                    label="特性ID"
                                                    size="small"
                                                    disabled
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Controller
                                            name="bIsDefault"
                                            control={control}
                                            render={({ field }: any) => (
                                                <FormControlLabel 
                                                    {...field} 
                                                    control={<Checkbox />} 
                                                    checked={field.value} 
                                                    label="デフォルト" 
                                                    labelPlacement="start" 
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            control={control}
                                            name="sFixedName"
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.sFixedName}
                                                    helperText={errors?.sFixedName?.message}
                                                    type="text"
                                                    size="small"
                                                    label="固定可変名称"
                                                    
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            control={control}
                                            name="dFixedValue"
                                            rules={{
                                                pattern: {
                                                    value: /^-?\d+(?:\.\d+)?$/,
                                                    message: '数字を入力してください。'
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.dFixedValue}
                                                    helperText={errors?.dFixedValue?.message}
                                                    type="text"
                                                    size="small"
                                                    label="固定可変値"
                                                   
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            control={control}
                                            name="dContiDefValue"
                                            rules={{
                                                pattern: {
                                                    value: /^-?\d+(?:\.\d+)?$/,
                                                    message: '数字を入力してください。'
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.dContiDefValue}
                                                    helperText={errors?.dContiDefValue?.message}
                                                    type="text"
                                                    size="small"
                                                    label="連続可変デフォルト値"
                                                    
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            control={control}
                                            name="dContiMiniValue"
                                            rules={{
                                                pattern: {
                                                    value: /^-?\d+(?:\.\d+)?$/,
                                                    message: '数字を入力してください。'
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.dContiMiniValue}
                                                    helperText={errors?.dContiMiniValue?.message}
                                                    type="text"
                                                    label="連続可変最小値"
                                                    size="small"
                                                    
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            control={control}
                                            name="dContiWidthValue"
                                            rules={{
                                                pattern: {
                                                    value: /^-?\d+(?:\.\d+)?$/,
                                                    message: '数字を入力してください。'
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    helperText={errors?.dContiWidthValue?.message}
                                                    error={!!errors?.dContiWidthValue}
                                                    type="text"
                                                    label="連続可変幅"
                                                    size="small"
                                                   
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            control={control}
                                            name="dContiMaxValue"
                                            rules={{
                                                pattern: {
                                                    value: /^-?\d+(?:\.\d+)?$/,
                                                    message: '数字を入力してください。'
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    helperText={errors?.dContiMaxValue?.message}
                                                    error={!!errors?.dContiMaxValue}
                                                    type="text"
                                                    label="連続可変最大値"
                                                    size="small"
                                                   
                                                />
                                            )}
                                        />
                                    </Grid>
                                    
                                </Grid>
                            </Stack>
                        </div>
                        <Stack direction='row' justifyContent="flex-end" spacing={2} sx={{ mt: 1, mr: 2 }}>
                            <LoadingButton variant="contained" type="submit" loading={loadingFlag}>保存</LoadingButton>
                            <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                        </Stack>
                    </TabPanel>
                </form>
            </div>
            <FetchHelper fetchId={UPDATE_DIAL_TIME}
                onComplete={(success: boolean, data: any, error: any) =>
                    success ? handleSuccessUpdate(success, data, error) : handleErrorUpdate(success, data, error)} />

            <FetchHelper fetchId={CREATE_DIAL_TIME}
                onComplete={(success, data, error) => success ?
                    handleSubmitSuccess(success, data, error) :
                    handleSubmitError(success, data, error)} />
            { mode === "edit" &&
                <FetchHelper fetchId={GET_DIAL_TIME_BY_ID}
                    onComplete={(success, data, error) => {
                        success ? handleSuccess(data) : handleError(success, data, error);
                    }}
                />
            }
        </>
    );
}
const mapStateToProps = (state: ApplicationState) => (
    {
        userId: state.app.user?.userId,
        fetchUpdate: selectors.getFormState(
            state,
            GET_DIAL_TIME
        ),
    }
)
const mapDispatchToProps = (dispatch: any) => {
    return {
        onCreate: (params: any) => dispatch(actionCreators.fetch(CREATE_DIAL_TIME, `/master/dial_time/add`, "POST", params, false, true)),
        onUpdate: (params: any) => dispatch(actionCreators.fetch(UPDATE_DIAL_TIME, `/master/dial_time/modify`, "POST", params, false, true)),
        onSearchById: (params: any) => dispatch(actionCreators.fetch(GET_DIAL_TIME_BY_ID, `/master/dial_time/get`, "POST", params, false, true)),
        onSuccess: () =>
            dispatch(actionCreators.showMessage({
                type: "info",
                title: "時間ダイアル更新",
                body: "時間ダイアルを更新しました。",
            })),
        onError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "検索",
                body: "検索できません。",
            })),
        onCreateError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "時間ダイアル登録",
                body: "時間ダイアルを登録出来ませんでした。",
            })),
        onUpdateError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "時間ダイアル編集",
                body: "時間ダイアルを更新出来ませんでした。",
            })),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(DialTimeEdit as any);