import { store } from "..";
import { ControlModel, InfoCircuit, MsMotorGroupModel, PercentZ, ProcessMode } from "../models/Index";
import { BeamsNumber } from "./FormatNumber";
import { DIRECT_NONE, LEFT_CENTER, MS_PI, TOP_CENTER, VALUE_POWER_MIN } from "../models/Constants"
import { doGetIkasymDirectMG, doGetIkasymTotalMG, doGetIkpeakDirectMG, doGetIkpeakTotalMG, doGetIksymDirectMG, doGetIksymTotalMG, doGetPerZTotalMG } from "./calcPointFunction";
import { MS_MODE_CALCULATION, MS_MODE_CHECKING, MS_MODE_DRAWING, MS_MODE_HARMONIC, MS_MODE_PERCENT_Z, MS_MODE_POWER_FLOW, MS_MODE_VOLTDROP } from "../statics";

//CEleMotorGrp::DoGetTextCapacity
export const doGetTextCapacity = (props:MsMotorGroupModel, bUnit:boolean = true) =>
{
	let sText = BeamsNumber(props.capacity, 6).toString();
	if (bUnit) {
		sText += 'kVA';
	}
	return sText;
}

//CEleMotorGrp::DoGetValueRatedCurrent
export const doGetValueRatedCurrent = (props:MsMotorGroupModel) =>
{
	if (props.voltage <= 0) {
		return 0;
	}
	let dRatedCurrent = props.loadFactor * props.capacity * 1000 / props.voltage / Math.sqrt(3.0);

	return dRatedCurrent;
}

//CEleMotorGrp::DoGetTextRatedCurrent
export const doGetTextRatedCurrent = (props:MsMotorGroupModel, bUnit:boolean = true) =>
{
	let dRatedCurrent = doGetValueRatedCurrent(props);
	let sText = BeamsNumber(dRatedCurrent, 4).toString();
	if (bUnit) {
		sText += 'A';
	}
	return sText;
}

//CEleMotorGrp::DoGetValueSubConstant
export const doGetValueSubConstant = (nFrequency:number, dRotorResistance:number, dSubReactance:number, dExReactance:number) =>
{
	if (nFrequency == 0 || dRotorResistance == 0) {
		return 0;
	}
	let dSubConstant = (dSubReactance + dExReactance) / (2 * MS_PI * nFrequency * dRotorResistance);

	return dSubConstant;
}

//CEleMotorGrp::DoGetValueSubPercentZ
export const doGetValueSubPercentZ = (dRotorResistance:number, dStatorResistance:number, dExResistance:number, dSubReactance:number, dExReactance:number) =>
{
	let dSubPercent = Math.pow(dStatorResistance + dRotorResistance + dExResistance, 2) + Math.pow(dSubReactance + dExReactance, 2);
	dSubPercent = Math.sqrt(dSubPercent);

	return dSubPercent;
}

//CEleMotorGrp::DoGetValueInitCurrent
export const doGetValueInitCurrent = (dRatedCurrent:number, dSubPercent:number) =>
{
	if (dSubPercent == 0) {
		return 0;
	}
	let dInitialCurrent = dRatedCurrent / dSubPercent * 100;

	return dInitialCurrent;
}

//CEleMotorGrp::DoGetValueSymCurrent
export const doGetValueSymCurrent = (nFrequency:number, dInitialCurrent:number, dSubConstant:number) =>
{
	if (nFrequency == 0 || dSubConstant == 0) {
		return 0;
	}
	let dSymmetricalCurrent = dInitialCurrent * Math.exp(-1 / (nFrequency * 2 * dSubConstant));

	return dSymmetricalCurrent;
}

//CEleMotorGrp::DoGetValueIsym
export const doGetValueIsym = (props:MsMotorGroupModel, pInfoCircuit:InfoCircuit, dPercentExR:number, dPercentExX:number) =>
{
	if (dPercentExR < 0) {
		dPercentExR = 0;
		dPercentExX = 0;
	}
	else {
		if (pInfoCircuit.stdCapacity == 0) {
			dPercentExR = 0;
			dPercentExX = 0;
		}
		else {
			dPercentExR *= props.loadFactor * props.capacity / pInfoCircuit.stdCapacity;
			dPercentExX *= props.loadFactor * props.capacity / pInfoCircuit.stdCapacity;
		}
	}

	let dRatedCurrent = doGetValueRatedCurrent(props);
	let dSubConstant = doGetValueSubConstant(pInfoCircuit.frequency, props.rotorResistance, props.subReactance, dPercentExX); 
	let dSubPercent = doGetValueSubPercentZ(props.rotorResistance, props.statorResistance, dPercentExR, props.subReactance, dPercentExX); 
	let dInitialCurrent = doGetValueInitCurrent(dRatedCurrent, dSubPercent);

	if (pInfoCircuit.frequency == 0 || dSubConstant == 0) {
		return 0;
	}
	let dIsym = doGetValueSymCurrent(pInfoCircuit.frequency, dInitialCurrent, dSubConstant);

	return dIsym;
}

//CEleMotorGrp::DoGetValueDirectPercentZe
export const doGetValueDirectPercentZe = (props:MsMotorGroupModel, pInfoCircuit:InfoCircuit, dVoltage:number) =>
{
	let dIsym = doGetValueIsym(props, pInfoCircuit, 0, 0);
	if (dIsym <= 0 || dVoltage <= 0) {
		return 0;
	}

	let dPercentZ = pInfoCircuit.stdCapacity * 1000 * 100 / (Math.sqrt(3.0) * dVoltage * dIsym);

	return dPercentZ;
}

//CEleMotorGrp::DoGetTextDirectPercentZe
export const doGetTextDirectPercentZe = (props:MsMotorGroupModel, pInfoCircuit:InfoCircuit, bUnit:boolean = true) =>
{
	let sText = '';
	let dPercentZ = 0;		
	if (!props.percentZCalculate) { 
		dPercentZ = doGetValueDirectPercentZe(props, pInfoCircuit, props.voltage);
		dPercentZ *= props.loadFactor * props.capacity / pInfoCircuit.stdCapacity;
	}
	else {
		dPercentZ = props.percentZ * props.capacity1 / pInfoCircuit.stdCapacity; 
	}
	sText = BeamsNumber(dPercentZ, 4).toString();

	if (bUnit) {
		sText += '%e';
	}
	return sText;
}

//CEleMotorGrp::DoGetTextDirectPercentZr
export const doGetTextDirectPercentZr =(props:MsMotorGroupModel, pInfoCircuit:InfoCircuit, bUnit:boolean = true) =>
{
	let dPercentZ = 0;
	if (!props.percentZCalculate) { 
		dPercentZ = doGetValueDirectPercentZe(props, pInfoCircuit, props.voltage);
	}
	else {
		dPercentZ = props.percentZ * (pInfoCircuit.stdCapacity / props.capacity1); 
	}
	let sText;
	if (dPercentZ >= 0) {
		sText = BeamsNumber(dPercentZ, 4).toString();
		if (bUnit) {
			sText += '%r';
		}
	}
	else {							
		sText = '????';
	}
	return sText;
}

//CEleMotorGrp::DoGetPercentZrMotorGrp
export const doGetPercentZrMotorGrp = (props:MsMotorGroupModel, dStdCapacity:number) =>
{
	let percentZ = {} as PercentZ;

	if (!props.percentZCalculate) {  
		percentZ.perRk3 = props.rotorResistance + props.statorResistance; 
		percentZ.perXk3 = props.subReactance;
		percentZ.statorPerRk3 = props.statorResistance;

		if (props.capacity == 0) {
			percentZ.perRk3 = 0;
			percentZ.perXk3 = 0;
			percentZ.statorPerRk3 = 0;
		}
		else {
			percentZ.perRk3 *= dStdCapacity / (props.loadFactor * props.capacity);
			percentZ.perXk3 *= dStdCapacity / (props.loadFactor * props.capacity);
			percentZ.statorPerRk3 *= dStdCapacity / (props.loadFactor * props.capacity);
		}
	}
	else {
		if (props.capacity1 == 0) {
			percentZ.perRk3 = 0;  
			percentZ.perXk3 = 0;
			percentZ.statorPerRk3 = 0;
		}
		else {
			let dPercentR = 0;
			if (props.capacity1 != 0) { 
				dPercentR = props.percentZ / Math.sqrt(1 + props.xr * props.xr) * (dStdCapacity / props.capacity1);
			}
			let dXR = 0;
			let dPercentX = 0;
			if (props.xr != 0 && props.capacity1 != 0) { 
				dXR = 1 / props.xr; 
				dPercentX = props.percentZ / Math.sqrt(1 + dXR * dXR) * (dStdCapacity / props.capacity1);  
			}

			percentZ.perRk3 = dPercentR;
			percentZ.perXk3 = dPercentX;
			percentZ.statorPerRk3 = 0;
		}
	}

	return percentZ;
}

//CEleMotorGrp::IsPointFaultMotorGrp
export const isPointFaultMotorGrp = (props:MsMotorGroupModel) =>
{
	if (props.fault) {
		return true;
	}
	return false;
}

//CEleMotorGrp::DoGetTextVoltage
export const doGetTextVoltage = (props:MsMotorGroupModel, bUnit:boolean = true) =>
{
	let sText = props.voltage.toString();
	if (bUnit) {
		sText += 'V';
	}
	return sText;
}

//CEleMotorGrp::DoGetTextBackXR
export const doGetTextBackXR = (props:MsMotorGroupModel) =>
{
	return BeamsNumber(props.xr, 4); 
}

//CEleMotorGrp::DoGetTextXR
export const doGetTextXR = (props:MsMotorGroupModel, dStdCapacity:number) =>
{
	let sText = '';
	if (!props.percentZCalculate) {   
		let percentZ = doGetPercentZrMotorGrp(props, dStdCapacity);
		let dXR = 0;
		if (percentZ.perRk3 > 0) {
			dXR = percentZ.perXk3 / (percentZ.perRk3 - percentZ.statorPerRk3);
		}
		if (percentZ.perRk3 <= 0) {
			sText = BeamsNumber(0, 4).toString();
		}
		else {
			sText = BeamsNumber(dXR, 4).toString();
		}
	}
	else {
		sText = doGetTextBackXR(props).toString();
	}

	return sText;
}

//CEleMotorGrp::DoGetTextDirectPercentRr
export const doGetTextDirectPercentRr = (props:MsMotorGroupModel, pInfoCircuit:InfoCircuit, bUnit:boolean = true) =>
{
	let dXR = 0;
	let dPercentZ = 0;
	let dPercentR = 0;
	if (!props.percentZCalculate) { 
		let percentZ = doGetPercentZrMotorGrp(props, pInfoCircuit.stdCapacity);
		if (percentZ.perRk3 > 0) {
			dXR = percentZ.perXk3 / (percentZ.perRk3 - percentZ.statorPerRk3);
		}								
		dPercentZ = doGetValueDirectPercentZe(props, pInfoCircuit, props.voltage);
		dPercentR = dPercentZ / Math.sqrt(1 + dXR * dXR);
	}
	else {
		if (props.capacity1 != 0) { 
			dPercentR = props.percentZ / Math.sqrt(1 + props.xr * props.xr) * (pInfoCircuit.stdCapacity / props.capacity1); 
		}
	}

	let sText;
	if (dPercentR >= 0) {
		sText = BeamsNumber(dPercentR, 4).toString();
		if (bUnit) {
			sText += '%r';
		}
	}
	else {							
        sText = '????';
	}
	return sText;
}

//CEleMotorGrp::DoGetTextDirectPercentXr
export const doGetTextDirectPercentXr = (props:MsMotorGroupModel, pInfoCircuit:InfoCircuit, bUnit:boolean = true) =>
{
	let dXR = 0;
	let dPercentX = 0;
	if (!props.percentZCalculate) { 
		let percentZ = doGetPercentZrMotorGrp(props, pInfoCircuit.stdCapacity);
		if (percentZ.perRk3 > 0) {
			dXR = percentZ.perXk3 / (percentZ.perRk3 - percentZ.statorPerRk3);
		}
		let dPercentZ = doGetValueDirectPercentZe(props, pInfoCircuit, props.voltage);
		let dPercentR = dPercentZ / Math.sqrt(1 + dXR * dXR);
		dPercentX = dPercentR * dXR;
	}
	else {
		if (props.xr != 0 && props.capacity1 != 0) { 
			dXR = 1 / props.xr; 
			dPercentX = props.percentZ / Math.sqrt(1 + dXR * dXR) * (pInfoCircuit.stdCapacity / props.capacity1);
		}
	}
	let sText;
	if (dPercentX >= 0) {
		sText = BeamsNumber(dPercentX, 4).toString();
		if (bUnit) {
			sText += '%r';
		}
	}
	else {							
        sText = '????';
	}
	return sText;
}

//CEleMotorGrp::DoGetTextDirectIk3sym
export const doGetTextDirectIk3sym = (props:MsMotorGroupModel, bUnit:boolean = true) =>
{
	let dIsymDirect = doGetIksymDirectMG(props.calcPoint0);
	let sText;
	if (dIsymDirect >= 0) {
		sText = BeamsNumber(dIsymDirect / 1000, 4).toString();
		if (bUnit) {
			sText += 'kAsym'
		}
	}
	else {			
        sText = '????';				
	}
	return sText;
}

//CEleMotorGrp::DoGetTextPointState
export const doGetTextPointState = (props:MsMotorGroupModel) =>
{
	let sText = ''; 

	if (props.fault) {
		sText = 'Yes';
	}
	else {
		sText = 'No';
	}

	return sText;
}

//CEleMotorGrp::DoGetTextPowerFactor
export const doGetTextPowerFactor = (props:MsMotorGroupModel) =>
{
	return BeamsNumber(props.powerFactor, 5);

}

//CEleMotorGrp::DoGetTextImproveAfterPF
export const doGetTextImproveAfterPF = (props:MsMotorGroupModel) =>
{
	return BeamsNumber(props.improveAfterPF, 5);
}

//CEleMotorGrp::DoGetTextCapacitorCapacity
export const doGetTextCapacitorCapacity = (props:MsMotorGroupModel, bUnit:boolean = true) =>
{
	let dCapacity = props.loadFactor * props.capacity * 1000 * props.powerFactor;

	let dtanBefore = Math.sqrt(1 - props.powerFactor * props.powerFactor) / props.powerFactor;
	let dtanAfter = 0;
	if (props.improveAfterPF != 0) {
		dtanAfter = Math.sqrt(1 - props.improveAfterPF * props.improveAfterPF) / props.improveAfterPF;
	}

	let dCapacitorCapacity = dCapacity * (dtanBefore - dtanAfter) / 1000;
	if (Math.abs(dCapacitorCapacity) < VALUE_POWER_MIN) {
		dCapacitorCapacity = 0;
	}
								
	let sText = BeamsNumber(dCapacitorCapacity, 5).toString();

	if (bUnit) {
		sText += 'kvar';
	}

	return sText;
}

//CEleMotorGrp::DoGetRefV1
export const doGetRefV1 = (mode:any, control:ControlModel, bSociety:boolean) =>
{
    let props = control.properties as MsMotorGroupModel;
    let infoCircuit = store.getState().app.diagram.infoCircuit;

	//CEleMotorGrp::DoGetRefV1Left  sRefV1Left
	let labelTexts = [{
		value: control.properties.refNo,
		color: "#f800f8",
	}];

	if (!props.viewResultText || (mode != ProcessMode.DRAWING && !props.supply))
    {
      return labelTexts;
    }

    if(bSociety) {
        //Capacity
        labelTexts.push({
            value: doGetTextCapacity(props),
            color: "blue",
        });

        return labelTexts;
    }

    switch(mode) {
        case ProcessMode.DRAWING:
        case ProcessMode.CHECKING:
            //Capacity
            labelTexts.push({
                value: doGetTextCapacity(props),
                color: "blue",
            });

            //RatedCurrent
            labelTexts.push({
                value: doGetTextRatedCurrent(props),
                color: "blue",
            });

            //DirectPercentZe
            labelTexts.push({
                value: doGetTextDirectPercentZe(props, infoCircuit),
                color: "blue",
            });

            //X/R=doGetTextXR
            labelTexts.push({
                value: 'X/R='
                + doGetTextXR(props, infoCircuit.stdCapacity),
                color: "blue",
            });

            break;
        case ProcessMode.PERCENT_Z:
            //X/R=doGetTextXR
            labelTexts.push({
                value: 'X/R='
                + doGetTextXR(props, infoCircuit.stdCapacity),
                color: "blue",
            });

            labelTexts.push({
                value: doGetTextDirectPercentZr(props, infoCircuit)
                + '='
                + doGetTextDirectPercentRr(props, infoCircuit, false)
                + '+j'
                + doGetTextDirectPercentXr(props, infoCircuit, false),
                color: "blue",
            });
            break;
        case ProcessMode.CALCULATION:
            if(!props.fault) {
                break;
            }

            //I"k3通過=doGetTextDirectIk3sym
            labelTexts.push({
                value: 'I"k3通過='
                + doGetTextDirectIk3sym(props),
                color: "blue",
            });
            break;
        case ProcessMode.VOLTDROP:
            //RatedCurrent
            labelTexts.push({
                value: doGetTextRatedCurrent(props),
                color: "blue",
            });

             //力率=doGetTextPowerFactor
             labelTexts.push({
                value: '力率=' 
                + doGetTextPowerFactor(props),
                color: "blue",
            });
            break;    
        case ProcessMode.IMPROVE_PF:
             //Capacity
             labelTexts.push({
                value: doGetTextCapacity(props),
                color: "blue",
            });

            if(props.calcImprovePF) {
                //負荷力率=doGetTextPowerFactor()
                labelTexts.push({
                    value: '負荷力率='
                    + doGetTextPowerFactor(props),
                    color: "blue",
                });

                //目標力率=doGetTextImproveAfterPF()
                labelTexts.push({
                    value: '目標力率='
                    + doGetTextImproveAfterPF(props),
                    color: "blue",
                });

                //Qc=doGetTextCapacitorCapacity()
                labelTexts.push({
                    value: 'Qc='
                    + doGetTextCapacitorCapacity(props),
                    color: "blue",
                });
            }
            else {
                 //力率=doGetTextPowerFactor
                labelTexts.push({
                    value: '力率=' 
                    + doGetTextPowerFactor(props),
                    color: "blue",
                });
            }
            break;
        case ProcessMode.HARMONIC:
            break;
        case ProcessMode.POWER_FLOW:
            break;
        default:
            break;
    }

    return labelTexts;
}

//CEleMotorGrp::DoGetTextDirectIk3peak
export const doGetTextDirectIk3peak = (props: ControlModel, bUnit: boolean = true) =>
{
	let dIpeakDirect =doGetIkpeakDirectMG(props.properties.calcPoint0);
	let sText;
	if (dIpeakDirect >= 0) {
		sText = BeamsNumber(dIpeakDirect / 1000, 4).toString();
		if (bUnit) {
			sText += 'kApeak';
		}
	}
	else {							// 短絡電流が正しく計算されなかったとき
		sText = '????';
	}
	return sText;
}

//CEleMotorGrp::DoGetTextDirectIk3asym
export const doGetTextDirectIk3asym = (props: ControlModel, bUnit: boolean = true) =>
{
	let dIasymDirect = doGetIkasymDirectMG(props.properties.calcPoint0);
	let sText = '';
	if (dIasymDirect >= 0) {
		sText = BeamsNumber(dIasymDirect / 1000, 4).toString();
		if (bUnit) {
			sText += 'kAasym';
		}
	}
	else {							// 短絡電流が正しく計算されなかったとき
		sText = '????';
	}
	return sText;
}

//CEleMotorGrp::DoGetTextTotalIk3sym
export const doGetTextTotalIk3sym = (props:ControlModel, bUnit:boolean = true) =>
{
	let dIsymTotal = doGetIksymTotalMG(props.properties.calcPoint0);
	let sText = '';
	if (dIsymTotal >= 0) {
		sText = BeamsNumber(dIsymTotal / 1000, 4).toString();
		if (bUnit) {
			sText += 'kAsym';
		}
	}
	else {							// 短絡電流が正しく計算されなかったとき
		sText = '????';
	}
	return sText;
}

//CEleMotorGrp::DoGetTextTotalIk3peak
export const doGetTextTotalIk3peak = (props:MsMotorGroupModel, bUnit:boolean = true) =>
{
	let dIpeakTotal = doGetIkpeakTotalMG(props.calcPoint0);
	let sText = '';
	if (dIpeakTotal >= 0) {
		sText = BeamsNumber(dIpeakTotal / 1000, 4).toString();
		if (bUnit) {
			sText += 'kApeak';
		}
	}
	else {							// 短絡電流が正しく計算されなかったとき
		sText = '????';
	}
	return sText;
}

//CEleMotorGrp::DoGetTextTotalIk3asym
export const doGetTextTotalIk3asym = (props:MsMotorGroupModel, bUnit:boolean = true)  =>
{
	let dIasymTotal = doGetIkasymTotalMG(props.calcPoint0);
	let sText = '';
	if (dIasymTotal >= 0) {
		sText = BeamsNumber(dIasymTotal / 1000, 4).toString();
		if (bUnit) {
			sText += 'kAasym';
		}
	}
	else {							// 短絡電流が正しく計算されなかったとき
		sText = '????';
	}
	return sText;
}

//CEleMotorGrp::DoGetTextTotalPercentZr
export const doGetTextTotalPercentZr = (props:MsMotorGroupModel, bUnit:boolean = true) =>
{
	let dPerZ = doGetPerZTotalMG(props.calcPoint0).dPercentZ;
	let sText = '';
	if (dPerZ >= 0) {
		sText = BeamsNumber(dPerZ, 4).toString();
		if (bUnit) {
			sText += '%r';
		}
	}
	else {							// 短絡電流が正しく計算されなかったとき
		sText = '????';
	}
	return sText;
}

//CEleMotorGrp::DoGetTextFlowsResult
export const doGetTextFlowsResult = (props:MsMotorGroupModel, processMode:any, nDirect:number, bSociety:boolean = false) =>
{
	let sRefV1:string[] = [];

	if (bSociety) {
		return sRefV1;
	}

	switch (processMode) {
	case MS_MODE_DRAWING:
	case MS_MODE_CHECKING:
	case MS_MODE_PERCENT_Z:
	case MS_MODE_CALCULATION:
	case MS_MODE_VOLTDROP:
	case MS_MODE_HARMONIC:
		break;
	case MS_MODE_POWER_FLOW:
		if (props.activePower !== 0 && props.reactivePower !== 0) {
			if (nDirect == DIRECT_NONE) {
				sRefV1.push(BeamsNumber(Math.abs(props.activePower),5).toString() + 'kW');
				sRefV1.push(BeamsNumber(Math.abs(props.reactivePower),5).toString() + 'kvar');
			}
			else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
				sRefV1.push(BeamsNumber(Math.abs(props.activePower),5).toString() + 'kW');
				let sTemp = ''
				if (props.reactivePower > 0) {
					sTemp += "+j";
				}
				else {
					sTemp += "-j";
				}
				sRefV1.push(sTemp + BeamsNumber(Math.abs(props.reactivePower),5).toString() + 'kvar');
			}
		}
		break;
	}

	return sRefV1;
}