import Konva from "konva";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { Group, Image, Rect, Transformer } from "react-konva";
import { connect } from "react-redux";
import * as Constant from "../../models/Constants";
import { ConnectModel, Coordinates, ProcessMode } from "../../models/Index";
import {
  clearOptionMenu,
  setRepositionElement,
  setTransformFlag,
  updateControlHeight,
  updateElementPosition,
  updatePropertiesOfControlAction,
  updatePropertiesOfTransCenter,
  updateSelectControl,
} from "../../store/Actions";
import { doGetValuesTransCenter } from "../../utils/TransCenterFunction";
import OptionMenu from "../contextMenu/OptionMenu";
import ImageSvg from "../../images/control/接続線.svg";
import ImageRedSvg from "../../images/control/接続線_red.svg";
import ImageGreenSvg from "../../images/control/接続線_green.svg";
import { calcContextMenuPosition, calcDragBoundPosition, calcElementSize, getMenuOptionHeight } from "../../utils/PositionCalculation";
//#region Props
type CreateMsConnectProps = {
  id: string;
  image: string;
  x: number;
  y: number;
  offsetX: number;
  offsetY: number;
  width: number;
  height: number;
  type: string;
  isSelected: boolean;
  isHidden: boolean;
  rotation: number;
  lineDiagramRef: any;
  parentGroupId: number | null;
  gridSizeController: {
    width: number;
    height: number;
    scale: number;
    stageOffsetX: number;
    stageOffsetY: number;
  };
  isOnShiftKey: boolean;
  properties: ConnectModel;
};

export type PureCreateMsConnectProps = CreateMsConnectProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;
//#endregion

const CONTROL_POS_FACTOR = 1;

const CreateMsConnect = memo((props: PureCreateMsConnectProps) => {
  //#region Fields
  const {
    id,
    image,
    x,
    y,
    offsetX,
    offsetY,
    width,
    height,
    type,
    isSelected,
    isHidden,
    processMode,
    rotation,
    lineDiagramRef,
    parentGroupId,
    properties,
  } = props;

  const {
    updateSelectControl,
    updateControlData,
    updateControlHeight,
    setTransformFlag,
    updateTransCenterProps,
    clearOptionMenu,
    elementGroups,
    tcGroups,
    elementReposition,
    updateElementPosition,
    setRepositionElement,
    diagramData,
    mainDiagramData,
    gridSizeController, 
    m_bModePM,
    modeViewOnly,
    infoSkeleton,
    currentTabId,
    clearMenuState,
    isSelectTouchGroup,
    isOnShiftKey,
  } = props;

  const getSymbolAndBackColor = (infoSkeleton:any,processMode:any,properties:any) => {
    let symbolColor = "";
    let backColor = "";
    if(infoSkeleton.dispStress == true && processMode > ProcessMode.DRAWING){
      if(infoSkeleton.dispStainOrBack != true){
        if(properties.supply != true)
          backColor = "#00FF00"
        else
          backColor = "#FFFFFF"
        symbolColor = "#000000";
      }else{
        if(properties.supply != true)
          symbolColor = "#008040"
        else
          symbolColor = "#FF0000"
        backColor = "#FFFFFF";
      }
    }
    else{
      symbolColor = "#000000";
      backColor = "#FFFFFF";
    }
    return {symbolColor:symbolColor,backColor:backColor}
  }

  const [imageStatus,setImageStatus] = useState(image);

  var imageObj = new window.Image();
  imageObj.src = imageStatus;

  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [menuPosition, setMenuPosition] = useState<Coordinates>({ x: 0, y: 0 });
  const [groupPosition, setGroupPosition] = useState({ x: x, y: y });
  const [dragStartPosition, setDragStartPosition] = useState({ x: x, y: y });
  const [isResizing, setIsResizing] = useState(false);
  const [heightTransform, setHeightTransform] = useState(0);
  const [isOnOpenMenu, setIsOnOpenMenu] = useState<boolean>(false);

  const transformRef = useRef<Konva.Transformer>(null);
  const transformMoveRef = useRef<Konva.Transformer>(null);
  const groupRef = useRef<any>();
  const topRef = useRef<any>();
  const bottomRef = useRef<any>();
  //#endregion

  //#region useEffect
  useEffect(()=>{
    if(isSelected){
      if(isOnOpenMenu) {
        setIsOnOpenMenu(false)
      }
      else
      {
        setIsOpenMenu(false)
      }
    }
  },[clearMenuState])

  useEffect(()=>{
    if(elementReposition?.id)
    {
      const groupsData = currentTabId === 1 ? elementGroups : tcGroups[diagramData.find((x:any)=>x.id == id)?.parentTcId ?? 0]
      const peekGroupId = groupsData?.byEleId[elementReposition.id as any]?.at(-1) ?? -1
      const elementIds = groupsData?.byId[peekGroupId]?.elementIds ?? []
      if(elementIds.includes(id) || diagramData[0].shape.find((item:any)=>(item.isSelected && item.id == id)))
      {
        setGroupPosition({
          x: dragStartPosition.x,
          y: dragStartPosition.y,
        });
        
        groupRef.current.to({
          x: dragStartPosition.x,
          y: dragStartPosition.y,
        });

        updateElementPosition(id, dragStartPosition.x, dragStartPosition.y)
      }
    }
  },[elementReposition])

  useEffect(() => {
    if (x >= 0 && y >= 0 && (groupPosition.x !== x || groupPosition.y !== y)) {
      setGroupPosition({x, y})
    }
  }, [x, y])

  useEffect(() => {
    setIsOpenMenu(false)
  }, [processMode])
  
  useEffect(() => {
    if (isSelected && !parentGroupId) {
      transformRef.current?.nodes([groupRef.current]);
    } else {
      transformRef.current?.nodes([]);
    }
    transformRef.current?.getLayer()?.batchDraw();
  }, [isSelected, parentGroupId, heightTransform]);

  useEffect(() => {
    if (!isSelected) setIsOpenMenu(false);
  }, [isSelected]);

  useEffect(() => {
    // check grid size; label, icon position when rotate, move and change height
    // handleGridSize(groupPosition.x, groupPosition.y, height, rotation);
    calcLabelPos();
    calcArrowPos();
    if (diagramData[0].tabId != 1){
      // TransCenter
      doSetValuesTransCenter();
    }
  }, [groupPosition, height, rotation]);

  useEffect(() => {
    switch(getSymbolAndBackColor(infoSkeleton,processMode,properties).symbolColor){
      case "#008040":
        setImageStatus(ImageGreenSvg);
        break;
      case "#FF0000":
        setImageStatus(ImageRedSvg);
        break;
      default:
        setImageStatus(ImageSvg);
        break;
    }
  },[getSymbolAndBackColor(infoSkeleton,processMode,properties).symbolColor])
  //#endregion

  //#region Method
  const doSetValuesTransCenter = () => {
    // TransCenter
    let transCenter = mainDiagramData.shape.find(
      (e: any) => e.properties?.tabId === diagramData[0].tabId
    );

    if (transCenter) {
      let tmpCalc = doGetValuesTransCenter(transCenter.properties, diagramData[0].shape);
      transCenter.properties = tmpCalc.newProps;
      updateTransCenterProps(transCenter.id, transCenter.properties);
    }
  };

  const handleContextMenu = (e: any) => {
    e.evt.preventDefault();
    if(e.evt.shiftKey) return
    setIsOnOpenMenu(true)
    updateSelectControl(id);

    const menuHeight = getMenuOptionHeight()
    const clientWidth = lineDiagramRef.current.clientWidth
    const isTransCenter = currentTabId == 1 ? false : true;
    let {posX, posY} = calcContextMenuPosition(
      e, menuHeight, clientWidth, x, y, gridSizeController, isTransCenter
    )
    clearOptionMenu(!clearMenuState)
    
    setMenuPosition({
      x: posX,
      y: posY,
    });
    setIsOpenMenu(true);
  };

  const handleClick = (e: any) => {
    if (e.type === "click") setIsOpenMenu(false);
    if(parentGroupId && parentGroupId > 0) return;
    if (e.evt.shiftKey || isSelectTouchGroup) {
      updateSelectControl(id, true);
    } else {
      updateSelectControl(id);
    }
  };

  const handleOpenMenu = (value: boolean) => {
    setIsOpenMenu(value);
    updateSelectControl(id);
  };

  const dragBoundFunc = (e: any, mode: string) => {
    // let rect = groupRef.current.getClientRect();
    const newPosition = calcDragBoundPosition(
      e, groupPosition, rotation, height, mode, gridSizeController
    );

    return {
      x: newPosition.newX,
      y: newPosition.newY,
    };
  };

  const calcLabelPos = (e?: any) => {
    let newX = groupPosition.x,
      newY = groupPosition.y;
    if (e?.type === "dragmove") {
      newX = groupRef.current.attrs.x;
      newY = groupRef.current.attrs.y;
    }

    const newPosition = {
      x: newX + Constant.ELEMENT_SIZE,
      y: newY + CONTROL_POS_FACTOR * (height / 2),
    };

    if (rotation !== 0) {
      newPosition.x = newX + CONTROL_POS_FACTOR * (height / 2);
      newPosition.y = newY - Constant.ELEMENT_SIZE;
    }

  };

  const calcArrowPos = (e?: any) => {
    let newX = groupPosition.x,
      newY = groupPosition.y;
    if (e?.type === "dragmove") {
      newX = groupRef.current.attrs.x;
      newY = groupRef.current.attrs.y;
    }

    const newPosition = {
      x: newX,
      y: newY + height - Constant.ELEMENT_SIZE,
    };

    if (rotation !== 0) {
      newPosition.y = newY;
      rotation === -90 &&
        (newPosition.x = newX + height - Constant.ELEMENT_SIZE);
      rotation === 90 && (newPosition.x = newX);
    }

  };

  const handleDragStart = (e: any) => {
    groupRef.current.moveToTop()
    transformRef.current?.moveToTop()
    setDragStartPosition({x: groupPosition.x, y: groupPosition.y});
    setIsOpenMenu(false);
  }

  const handleDragmove = (e: any) => {
    calcLabelPos(e);
    calcArrowPos(e);
  };

  const onDragEnd = (e: any) => {
    if (
      e.currentTarget.attrs.x === groupPosition.x &&
      e.currentTarget.attrs.y === groupPosition.y
    ) {
      return;
    }

    let newX =
      Math.round(e.target.x() / Constant.POINTER_WIDTH) *
      Constant.POINTER_WIDTH;
    let newY =
      Math.round(e.target.y() / Constant.POINTER_HEIGHT) *
      Constant.POINTER_HEIGHT;
         
    // check new position is outside grid
    if (newX < 0 || newY < 0){
      setRepositionElement(id, !(elementReposition?.state??true))
      newX = dragStartPosition.x;
      newY = dragStartPosition.y;
    }
    
    setGroupPosition({
      x: newX,
      y: newY,
    });

    e.target.to({
      x: newX,
      y: newY,
    });
  };

  // Mobile Touch Events
  var isMoving = false
  var isLongPress = false
  
  const handleTouchStart = useCallback((e:any) => {
    setIsOpenMenu(false)
    isLongPress = true
    isMoving = false
    !isSelectTouchGroup && setTimeout(() => {
      if (isLongPress && !isMoving) {
        handleContextMenu(e)
      }
    }, 1000)
  },[x,y,isSelectTouchGroup])

  const handleTouchMove = useCallback(() => {
    isMoving = true
  },[x,y,isSelectTouchGroup])

  const handleTouchEnd = useCallback((e:any) => {
    isLongPress = false
  },[x,y,isSelectTouchGroup])

  const handleMouseDown = (e: any, mode: string) => {
    const container = e.target.getStage().container();
    container.style.cursor = rotation === 0 ? "ns-resize" : "ew-resize";
    setIsResizing(true);
  };

  const handleMouseMove = (e: any, mode: string) => {
    if (!isResizing) return;
    setHeightTransform(e.evt.movementY);
  };

  const handleMouseUp = (e: any, mode: string) => {
    setTransformFlag(true);
    const container = e.target.getStage().container();
    container.style.cursor = "default";

    let newPosX = e.target._lastPos.x // e.evt.offsetX
    let newPosY = e.target._lastPos.y // e.evt.offsetY

    let newEle = calcElementSize(
      newPosX,
      newPosY,
      groupPosition,
      rotation,
      height,
      offsetY,
      mode,
      gridSizeController
    )

    topRef.current.y(0);
    // bottomRef.current.y(newHeight - 7)
    setGroupPosition({ x: newEle.newX, y: newEle.newY });

    updateControlHeight(id, newEle.newHeight, newEle.newX, newEle.newY, newEle.newOffsetY);
    setIsResizing(false);
  };
  //#endregion

  return (
    <>
      {isOpenMenu &&
        <OptionMenu
          onProperties={()=>{}}
          controlId={id}
          isOpenMenu={isOpenMenu}
          setIsOpenMenu={handleOpenMenu}
          x={menuPosition.x}
          y={menuPosition.y}
          isDisabledDelete={false}
          isDisabledCut={false}
          isDisabledCopy={false}
          isDisabledPaste={false}
          isDisabledOpenGraph={true}
          isDisabledProps={true}
          isDisabledRotate={false}
          isDisabledUserCurve={true}
        />
      }
      <Group
        ref={groupRef}
        id={id}
        x={groupPosition.x}
        y={groupPosition.y}
        offset={{ x: offsetX, y: offsetY }}
        width={width}
        height={height}
        draggable={processMode === ProcessMode.DRAWING && !m_bModePM && !modeViewOnly && !isOnShiftKey}
        type={type}
        onClick={handleClick}
        onTap={handleClick}
        onContextMenu={handleContextMenu}
        rotation={rotation}
        onMouseDown={() => transformMoveRef.current?.nodes([groupRef.current])}
        onMouseUp={() => transformMoveRef.current?.nodes([])}
        onDragStart={handleDragStart}
        onDragMove={handleDragmove}
        onDragEnd={onDragEnd}
        onMouseLeave={(e: any) => {
          const container = e.target.getStage().container();
          container.style.cursor = "default";
        }}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <Rect
          fill={getSymbolAndBackColor(infoSkeleton,processMode,properties).backColor}
          width={width}
          height={height}
          onMouseEnter={(e: any) => {
            const container = e.target.getStage().container();
            container.style.cursor = (processMode === ProcessMode.DRAWING && !m_bModePM && !modeViewOnly) ? "move" : "crosshair";
          }}
        />
        <Image
          fill={"white"}
          image={imageObj}
          width={Constant.ELEMENT_SIZE * 0.1}
          height={height}
          x={Constant.ELEMENT_SIZE / 2 - 1}
          onMouseEnter={(e: any) => {
            const container = e.target.getStage().container();
            container.style.cursor = (processMode === ProcessMode.DRAWING && !m_bModePM && !modeViewOnly) ? "move" : "crosshair";
          }}
        />
        {processMode === ProcessMode.DRAWING && !m_bModePM && !modeViewOnly && isSelected && !parentGroupId && (
          <>
            <Rect
              ref={topRef}
              fill="black"
              x={6.75}
              y={0}
              width={7}
              height={7}
              draggable
              onMouseDown={() => setTransformFlag(false)}
              dragBoundFunc={(e: any) => dragBoundFunc(e, "top")}
              onDragStart={(e: any) => handleMouseDown(e, "top")}
              onDragMove={(e: any) => handleMouseMove(e, "top")}
              onDragEnd={(e: any) => handleMouseUp(e, "top")}
              onMouseEnter={(e: any) => {
                const container = e.target.getStage().container();
                container.style.cursor =
                  rotation === 0 ? "ns-resize" : "ew-resize";
              }}
            />
            <Rect
              ref={bottomRef}
              fill="black"
              x={6.75}
              y={height - 7}
              width={7}
              height={7}
              draggable
              onMouseDown={() => setTransformFlag(false)}
              dragBoundFunc={(e: any) => dragBoundFunc(e, "bottom")}
              onDragStart={(e: any) => handleMouseDown(e, "bottom")}
              onDragMove={(e: any) => handleMouseMove(e, "bottom")}
              onDragEnd={(e: any) => handleMouseUp(e, "bottom")}
              onMouseEnter={(e: any) => {
                const container = e.target.getStage().container();
                container.style.cursor =
                  rotation === 0 ? "ns-resize" : "ew-resize";
              }}
            />
          </>
        )}
      </Group>

      <Transformer
        ref={transformRef}
        rotateEnabled={false}
        resizeEnabled={false}
        keepRatio={false}
        borderDash={[4]}
        anchorStroke="black"
        borderStroke="black"
        anchorFill="black"
      />

      <Transformer
        ref={transformMoveRef}
        rotateEnabled={false}
        resizeEnabled={false}
        keepRatio={false}
        borderEnabled={false}
        anchorStroke="black"
        borderStroke="black"
        anchorFill="black"
      />
    </>
  );
});

const mapStateToProps = (state: any) => {
  return {
    processMode: state.app.diagram.processMode,
    diagramData: state.app.diagram.diagramData.filter((r: any) => r.tabId === state.app.diagram.currentIDDiagramTab),
    mainDiagramData: state.app.diagram.diagramData.find((r: any) => r.tabId === 1),
    m_bModePM: state.app.diagram.m_bModePM,
    modeViewOnly: state.app.diagram.modeViewOnly,
    infoSkeleton:state.app.diagram.infoSkeleton,
    currentTabId: state.app.diagram.currentIDDiagramTab,
    clearMenuState: state.app.diagram.clearOptionMenu,
    isSelectTouchGroup: state.app.diagram.isSelectTouchGroup,
    tcGroups: state.app.diagram.tcGroups,
    elementGroups: state.app.diagram.elementGroups,
    elementReposition: state.app.diagram.elementReposition,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    updateSelectControl: (controlId: string, isMultiple : boolean = false) =>
      dispatch(updateSelectControl(controlId, isMultiple)),
    updateControlData: (id: string, data: object) =>
      dispatch(updatePropertiesOfControlAction(id, data)),
    updateTransCenterProps: (id: string, data: object) =>
      dispatch(updatePropertiesOfTransCenter(id, data)),
    updateControlHeight: (
      id: string,
      height: number,
      x: number,
      y: number,
      offsetY: number
    ) => dispatch(updateControlHeight(id, height, x, y, offsetY)),
    setTransformFlag: (visible: boolean) => dispatch(setTransformFlag(visible)),
    clearOptionMenu: (state:boolean) => dispatch(clearOptionMenu(state)),
    setRepositionElement: (id:any, state:boolean) => dispatch(setRepositionElement(id, state)),
    updateElementPosition:(id:any, x:any, y:any) => dispatch(updateElementPosition(id, x, y)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateMsConnect);
