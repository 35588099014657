import { Box, Button, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Stack, Tab, Tabs, TextField, Typography } from "@mui/material";
import { MsZerocondModel, ProcessMode } from "../../models/Index";
import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import TextFieldOptions from "../common/TextFieldOptions";
import { ROLE_LP } from "../../models/Constants";

export type MsZerocondDialogFormProps = {
    data: any;
    userRole: any;
    onOK: (data: any) => void;
    onCancel: () => void;
    setIsVoltText:string;
    setIsConectTrans1OrTranscott: boolean;
    m_bModePM: boolean;
    modeViewOnly:boolean;
    processMode: number;
};
function a11yProps(index: number) {
    return {
        id: `tab-${index}`,
        "aria-controls": `tabpanel-${index}`,
    };
}
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}
const checkboxStyle = {
    padding: '0px 0px 0px 9px'
}
const checkboxStyles = {
    padding: '4px 4px 4px 8px'
}



const MsZerocondEditDialog = (props: MsZerocondDialogFormProps) => {
    const { userRole, m_bModePM, modeViewOnly, data, onOK, onCancel,setIsConectTrans1OrTranscott,setIsVoltText,processMode } = props;
    const [isDisabledField3To8, setIsDisabledField3To8] = useState<boolean>(data.refNo.length === 0);
    const [isDisabledField78, setIsDisabledField78] = useState<boolean>(!data.calcImprovePF);
    const [isDisableOKBtn, setIsDisableOKBtn] = useState(false);
    const [selectTab, setSelectTab] = useState(0);
    // 初期値設定
    const initialValue: MsZerocondModel = {
        ...data,
    }
    const { control, handleSubmit, formState: { errors }, setValue,getValues,setError } = useForm<MsZerocondModel>({
        mode: 'all',
        criteriaMode: "all",
        defaultValues: initialValue,
    });


    // FormデータからRequestデータを作成する
    const createRequestData = (formValue: MsZerocondModel) => {
        if(!Number(formValue.flowConductance)){
            formValue.flowConductance = 0
        }
        if(!Number(formValue.flowSusceptance)){
            formValue.flowSusceptance = 0
        }
        if(!formValue.afterPF){
            formValue.afterPF = 0
        }
        let request = {
           ...formValue
        };
        return request;
    }
    
    // Submitイベント
    const handleEditSubmit = (value: MsZerocondModel) => {
        const request = createRequestData(value);
        onOK(request);
    }

    const handleCancel = () => {
        onCancel();
    };

    const handleChangeFormat = (e: any) => {
        setValue(e.target.name, e.target.value);
    }

    const handleDisabled3To8 = (e: any) => {
        setIsDisabledField3To8(e.length === 0);
    }

    const handleDisabled78 = (e: any) => {
        setIsDisabledField78(!isDisabledField78);
    }
    const handleDisable =() =>{
        if(processMode > ProcessMode.CHECKING)
            return true
        return false
        
    }
    const handleInput = (e: any) => {
        setValue(e.target.name, e.target.value,{
            shouldValidate:true
        })
        let voltage = getValues("voltage") 
        let improveAfter = getValues("afterPF")
        const isInteger = /^[0-9\b]+$/.test(voltage.toString())
        if(voltage <= 0 || !isInteger || improveAfter > 1 || improveAfter < 0 ){
            // setIsDisableCalcBtn(false);
            setIsDisableOKBtn(true);
        }
        else{
            if(voltage > 0 && isInteger && improveAfter <=1 && improveAfter >=0){
            // setIsDisableCalcBtn(true);
            setIsDisableOKBtn(false);
            }
            else{
            setIsDisableOKBtn(true);
            }
        }
    }
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        if (newValue >= 0) {
            if (getValues('refNo') == "" ||getValues('refNo') == null || getValues('refNo') == undefined ) {
                setError("refNo",{ type: 'custom', message: '必須項目です。入力してください。' });
            }
            else if (isNaN(Number(getValues('voltage'))) || Number(getValues('voltage')) <= 0 || !/^[0-9\b]+$/.test(getValues('voltage').toString())) {
                setError("voltage",{ type: 'custom', message: '整数 > 0 を入力してください。' });
            }
            else{
                setSelectTab(newValue);
            }
        }
        else{
        setSelectTab(newValue);
        }    };
    return (
        <>
               
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={selectTab} onChange={handleChange} aria-label="basic tabs example"
                            variant="scrollable" 
                            scrollButtons 
                            allowScrollButtonsMobile 
                            sx={{
                                '& .MuiButtonBase-root.MuiTabScrollButton-root.Mui-disabled': {
                                    display: "none"
                                }
                            }}
                        >
                            <Tab label="基本" {...a11yProps(0)} />
                            {userRole != ROLE_LP && <Tab label="潮流解析" {...a11yProps(1)} />}
                        </Tabs>
                    </Box>
                    <form onSubmit={handleSubmit(handleEditSubmit)}>
                        <div style={{ height: '40vh', width: '100%', margin: 'auto', overflow: 'auto' }}>

                            <TabPanel value={selectTab} index={0}>
                                <Stack spacing={0.3} style={{ width: '100%', margin: 'auto' }}>
                                    <Grid container mt={2}>
                                        <Grid item xs={6} >
                                                <Stack sx={{ flexDirection: "row", alignItems:"center",mt:"5px"}}>
                                                    <Typography variant="body2" width="50%">デバイス名:</Typography>  
                                                    <Controller
                                                        name="refNo"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                fullWidth
                                                                type="text"
                                                                size="small"
                                                                // label="デバイス名"
                                                                error={!!errors?.refNo}
                                                                helperText={errors?.refNo?.message}
                                                                onBlur={handleChangeFormat}
                                                                onFocus={e => e.target.select()}
                                                                InputLabelProps={{ style: { color: 'black' }}}
                                                                onChange={(e: any) => {
                                                                    field.onChange(e);
                                                                    handleDisabled3To8(e.target.value);
                                                                }}
                                                                inputProps={{readOnly: m_bModePM || modeViewOnly}}
                                                                InputProps={{ 
                                                                    sx: { 
                                                                        height: 32,
                                                                        background:m_bModePM || modeViewOnly?'floralwhite':'white',
                                                                        color: m_bModePM || modeViewOnly?'grey':'black',
                                                                    }
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                                <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                    <Typography variant="body2" width="50%">事故点名称:</Typography>  
                                                    <Controller
                                                        name="pointText"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                fullWidth
                                                                type="text"
                                                                size="small"
                                                                // label="事故点名称"
                                                                onBlur={handleChangeFormat}
                                                                onFocus={e => e.target.select()}
                                                                inputProps={{readOnly: m_bModePM || modeViewOnly}}
                                                                InputProps={{ 
                                                                    sx: { 
                                                                        height: 32,
                                                                        background:m_bModePM || modeViewOnly?'floralwhite':'white',
                                                                        color: m_bModePM || modeViewOnly?'grey':'black',
                                                                    }
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                                <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                    <Typography variant="body2" width="50%">回路電圧(V):</Typography>  
                                                        <FormControl fullWidth size="small">
                                                            <Controller
                                                                        name="voltage"
                                                                        control={control}
                                                                        rules={{ 
                                                                            required: '必須項目です。入力してください。' ,
                                                                            pattern:{
                                                                            value: /^[0-9\b]+$/,
                                                                            message: '数字を入力してください。'
                                                                            },
                                                                            validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                                        }}
                                                                        render={({ field }) => (
                                                                            <TextFieldOptions
                                                                                {...field}
                                                                                options={initialValue.voltageList}
                                                                                size="small"
                                                                                setValue={(name: any, value: any) => {
                                                                                    field.onChange({target: {name: name, value: value}});
                                                                                    handleInput({target: {name: name, value: value}})
                                                                                }}
                                                                                error={!!errors?.voltage}
                                                                                helperText={errors?.voltage?.message}
                                                                                disabled={m_bModePM || modeViewOnly || getValues("isSourceVoltage")}
                                                                                height={32}
                                                                                maxLength={6}
                                                                                value={setIsConectTrans1OrTranscott?setIsVoltText:getValues("voltage")}
                                                                            />
                                                                        )}
                                                                    /> 
                                                        </FormControl>
                                                </Stack>
                                        </Grid>
                                        <Grid item xs={6} pl={1}>
                                            <Stack>
                                                <Controller
                                                    name="fault"
                                                    control={control}
                                                    render={({ field }: any) => (
                                                        <FormControlLabel 
                                                            {...field} 
                                                            disabled={m_bModePM || modeViewOnly || isDisabledField3To8}
                                                            checked={field.value}
                                                            label={<Typography variant="body2" style={{color:m_bModePM || isDisabledField3To8?'grey':'black'}}>短絡電流を計算</Typography>}
                                                            name='fault'
                                                            onClick={handleChangeFormat}
                                                            control={<Checkbox style={checkboxStyles} />} 
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack>
                                                <Controller
                                                    name="dropMotor"
                                                    control={control}
                                                    render={({ field }: any) => (
                                                        <FormControlLabel 
                                                            {...field} 
                                                            disabled={m_bModePM || modeViewOnly || isDisabledField3To8||setIsConectTrans1OrTranscott}
                                                            checked={field.value}
                                                            label={<Typography variant="body2" style={{color:(m_bModePM || isDisabledField3To8||setIsConectTrans1OrTranscott)?'grey':'black'}}>電動機始動時の電圧降下を計算</Typography>}
                                                            name='dropMotor'
                                                            onClick={handleChangeFormat}
                                                            control={<Checkbox style={checkboxStyles} 
                                                           />} 
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack>
                                                <Controller
                                                    name="dropRegular"
                                                    control={control}
                                                    render={({ field }: any) => (
                                                        <FormControlLabel 
                                                            {...field} 
                                                            disabled={m_bModePM || modeViewOnly || isDisabledField3To8}
                                                            checked={field.value}
                                                            label={<Typography variant="body2" style={{color:m_bModePM || isDisabledField3To8?'grey':'black'}}>定常時の電圧降下を計算</Typography>}
                                                            name='dropRegular'
                                                            onClick={handleChangeFormat}
                                                            control={<Checkbox style={checkboxStyles} />} 
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack>
                                                <Controller
                                                    name="calcImprovePF"
                                                    control={control}
                                                    render={({ field }: any) => (
                                                        <FormControlLabel 
                                                            {...field} 
                                                            disabled={m_bModePM || modeViewOnly || isDisabledField3To8 || setIsConectTrans1OrTranscott}
                                                            checked={field.value}
                                                            label={<Typography variant="body2" style={{color:(m_bModePM || isDisabledField3To8||setIsConectTrans1OrTranscott)?'grey':'black'}}>力率改善を計算</Typography>}
                                                            name='calcImprovePF'
                                                            onClick={handleChangeFormat}
                                                            onChange={(e: any) => {
                                                                field.onChange(e);
                                                                handleDisabled78(e);
                                                            }}
                                                            control={<Checkbox style={checkboxStyles} 
                                                            />} 
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack pl={2}>
                                                <Controller
                                                    name="calcRightLeft"
                                                    control={control}
                                                    render={({ field }: any) => (
                                                        <FormControlLabel 
                                                            {...field}
                                                            disabled={m_bModePM || modeViewOnly || isDisabledField78 || isDisabledField3To8 || setIsConectTrans1OrTranscott}
                                                            checked={field.value}
                                                            label={<Typography variant="body2" style={{color:(m_bModePM || isDisabledField78 || isDisabledField3To8 || setIsConectTrans1OrTranscott)?'grey':'black'}}>左右の母線の負荷も考慮</Typography>}
                                                            name='calcRightLeft'
                                                            onClick={handleChangeFormat}
                                                            control={<Checkbox style={checkboxStyles} />} 
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}} pl={2}>
                                                    <Typography variant="body2" width="100%" style={{color:(m_bModePM || isDisabledField78 || isDisabledField3To8 || setIsConectTrans1OrTranscott)?'grey':'black'}}>改善後の目標力率:</Typography>  
                                                <Controller
                                                    name="afterPF"
                                                    control={control}
                                                    rules={{
                                                        validate: value => value >=0 && value <=1 || '数字 >= 0 & <= 1 を入力してください。'
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            type="text"
                                                            size="small"
                                                            onFocus={e => e.target.select()}
                                                            onChange={handleInput}
                                                            InputLabelProps={{ style: { color: 'black' }}}
                                                            error={!!errors?.afterPF}
                                                            helperText={errors?.afterPF?.message}
                                                            InputProps={
                                                                {sx:{height:32}}
                                                            }
                                                            inputProps={{maxLength:5}}
                                                            disabled={m_bModePM || modeViewOnly || isDisabledField78 || isDisabledField3To8 || setIsConectTrans1OrTranscott}
                                                            style={{background:(m_bModePM || modeViewOnly || isDisabledField78 || isDisabledField3To8 || setIsConectTrans1OrTranscott)?'floralwhite':'white', color: modeViewOnly?'grey':'black'}}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack>
                                                <Controller
                                                    name="viewResultText"
                                                    control={control}
                                                    render={({ field }: any) => (
                                                        <FormControlLabel 
                                                            {...field}
                                                            checked={field.value}
                                                            disabled={modeViewOnly}
                                                            label={<Typography variant="body2">注釈を表示</Typography>}
                                                            name='viewResultText'
                                                            onClick={handleChangeFormat}
                                                            control={<Checkbox style={checkboxStyles} />} 
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                     </Grid>
                                </Grid>
                            </Stack>
                    </TabPanel>
                    <TabPanel value={selectTab} index={1}>
                            <Stack spacing={0.5}>
                                <fieldset style={{width:"75%",marginTop:"10px",border:"1px solid #AAAA"}}>
                                    <legend><Typography variant="body2" width="100%">対中性点アドミタンス (基準容量に対する pu値)</Typography> </legend>
                                    <Grid container>
                                        <Grid xs={10}>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" ,mt:"2px"}}>
                                                <Typography variant="body2" width="100%">コンダクタンス G(pu値):</Typography>
                                                    <Controller
                                                        name="flowConductance"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                fullWidth
                                                                type="text"
                                                                size="small"
                                                                // label="コンダクタンス G(pu値)"
                                                                onBlur={handleChangeFormat}
                                                                onFocus={e => e.target.select()}
                                                                inputProps={{maxLength:6, readOnly: (m_bModePM || modeViewOnly ||handleDisable())}}
                                                                InputProps={{ 
                                                                    sx: { 
                                                                        height: 32,
                                                                        background:(m_bModePM || modeViewOnly ||handleDisable())?'floralwhite':'white',
                                                                        color: (m_bModePM || modeViewOnly ||handleDisable())?'grey':'black',
                                                                    }
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" ,mt:"2px"}}>
                                            <Typography variant="body2" width="100%">サセプタンス B(pu値):</Typography>
                                                    <Controller
                                                        name="flowSusceptance"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                fullWidth
                                                                type="text"
                                                                size="small"
                                                                // label="サセプタンス B(pu値)"
                                                                onBlur={handleChangeFormat}
                                                                onFocus={e => e.target.select()}
                                                                inputProps={{maxLength:6, readOnly: (m_bModePM || modeViewOnly ||handleDisable())}}
                                                                InputProps={{ 
                                                                    sx: { 
                                                                        height: 32,
                                                                        background:(m_bModePM || modeViewOnly ||handleDisable())?'floralwhite':'white',
                                                                        color: (m_bModePM || modeViewOnly ||handleDisable())?'grey':'black',
                                                                    }
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                        </Grid>
                                    </Grid>
                                </fieldset>
                                    <Stack>
                                        <Grid container>
                                            <Grid item xs={4.05}/>
                                            <Grid xs={6}>
                                                <Controller
                                                    name="viewFlowResult"        
                                                    control={control}
                                                    render={({ field }: any) => (
                                                        <FormControlLabel 
                                                            {...field} 
                                                            disabled={(m_bModePM || modeViewOnly ||handleDisable())}
                                                            checked={field.value}
                                                            label={<Typography variant="body2" width="100%" color={m_bModePM?'grey':'black'}>潮流解析結果を表示</Typography>}
                                                            name='viewFlowResult'
                                                            onClick={handleChangeFormat}
                                                            control={<Checkbox style={checkboxStyle} />} 
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Stack>
                            </Stack>
                    </TabPanel>
            </div>

                    <Stack direction='row' justifyContent="flex-end" spacing={2} sx={{ mt: 2 }}>
                        <Button variant="contained" type="submit" disabled={isDisableOKBtn || modeViewOnly}>OK</Button>
                        <Button color="inherit" variant="contained" onClick={handleCancel}>
                            キャンセル
                        </Button>
                    </Stack>
                </form>
        </>
    )
}

export default MsZerocondEditDialog;