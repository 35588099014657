import {
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogContent,
	DialogTitle,
	FormControl,
	Grid,
	MenuItem,
	Select,
	Stack,
	TextField,
	Typography
} from '@mui/material'

import { useEffect, useState } from 'react'
import { ControlModel, MSTransCenterModel } from '../../models/Index'
import theme from '../../themes/globalStyles'
import MSTransCenterEditDialog from '../dialogs/MsTransCenterEditDialog'
import { connect } from 'react-redux'
import doGetSourceVolt, { getTopControl } from '../../utils/CollisionDetection'
import { MS_3PHASES, infoLeftOptions, infoRightOptions } from '../../statics'
import DraggablePaper from '../common/DraggablePaper'
import * as EKind from '../../models/ElementKind'

import lineSvg from '../../images/control/line.svg'
import leftArrow from '../../images/control/blue_arrow.svg'
import rightArrow from '../../images/control/red_arrow.svg'
import triangleUp from "../../images/control/電線＿ケーブル_上.svg";
import triangleDown from "../../images/control/電線＿ケーブル_下.svg";
import { doGetTextDirectOhmR, doGetTextDirectOhmX, doGetTextDirectOhmZ, doGetTextDirectPercentRr, doGetTextDirectPercentXr, doGetTextDirectPercentZr, doGetTextDirectXR, doGetValuesTransCenter } from '../../utils/TransCenterFunction'
import { updatePropertiesOfTransCenter } from '../../store/Actions'
import { MS_OUTSIDE_NEUTRAL, MS_OUTSIDE_OUTSIDE, MS_SEAT_MAIN, MS_SEAT_T } from "../../statics";
import { getElementImage, getElementKindValue, getTextVoltageEle } from '../../utils/ElementFunction'
import { actionCreators } from '../../store'
import FetchHelper from '../FetchHelper'
import { convertUndefinedToNull, convertVoltToOptionList } from '../../utils/DataConverter'
import * as Constant from "../../models/Constants";
import './transCenterInfo.css';

interface infoDisplay {
	left1: {id: number, value: string},
	left2: {id: number, value: string},
	left3: {id: number, value: string},
	right1: {id: number, value: string},
	right2: {id: number, value: string},
	right3: {id: number, value: string},
}

const getLeftIdDisplay = (lable: string) => {
	return infoLeftOptions.find((e) => e.label === lable)?.value || 0
}

const getRightIdDisplay = (lable: string) => {
	return infoRightOptions.find((e) => e.label === lable)?.value || 0
}

const onlyTopControlArray = [
    EKind.MS_GENERATOR,
    EKind.MS_SOURCE
]

type TransCenterInfoProps = {
	offsetY: number;
	widthGrid: number;
	setWidthGrid: (width: number) => void;
	setHeightGrid: (height: number) => void;
	loadingFlag: boolean;
	setLoadingFlag: (flag: boolean) => void;
	isOpenLeftSideBar:boolean;
}

export type PureTransCenterInfoProps = 
	TransCenterInfoProps & 
	ReturnType<typeof mapStateToProps> & 
	ReturnType<typeof mapDispatchToProps>

const TransCenterInfo = (props: PureTransCenterInfoProps) => {
	const {
		offsetY,
		mainDiagram,
		transCenterDiagram,
		transCenterData,
		transCenterUpdateTimes,
		projectData,
		userRole,
		widthGrid,
		setWidthGrid,
		setHeightGrid,
		updateTransCenterProps,
		getElementParam,
		updateElementPost,
		setLoadingFlag,
		loadingFlag,
		m_bModePM,
		modeViewOnly,
		userId,
		processMode,
		isOpenLeftSideBar
	} = props
	const [initialValue, setInitialValue] =
		useState<MSTransCenterModel>({...transCenterData?.properties})
	const [connectedElement, setConnectedElement] = useState<ControlModel>()

	// const [loadingFlag, setLoadingFlag] = useState(false);
    const [isOpenEditorDialog, setIsOpenEditorDialog] = useState(false)
	const [haveSourceVoltage, setHaveSourceVoltage] = useState(false)
	const [sourceVoltText, setSourceVoltText] = useState('');
	const [capacityXPowerFactor, setCapacityXPowerFactor] = useState(0);
	const [widthTCInfo, setWidthTCInfo] = useState(Math.min(widthGrid, Constant.MAX_WIDTH_TC_INFO));
	
    const [infoDisplay, setInfoDisplay] = useState<infoDisplay>(
		{
			left1: {id: getLeftIdDisplay(initialValue.left1), value: ''},
			left2: {id: getLeftIdDisplay(initialValue.left2), value: ''},
			left3: {id: getLeftIdDisplay(initialValue.left3), value: ''},
			right1: {id: getRightIdDisplay(initialValue.right1), value: ''},
			right2: {id: getRightIdDisplay(initialValue.right2), value: ''},
			right3: {id: getRightIdDisplay(initialValue.right3), value: ''},
		}
	)

	var preImg = new window.Image()
	preImg.src = connectedElement 
				? getElementImage(
					connectedElement.type,
					connectedElement.properties,
					connectedElement.rotation
				) : ''

	var lineImg = new window.Image()
	lineImg.src = lineSvg

	var leftArrowObj = new window.Image()
	leftArrowObj.src = leftArrow
  
	var rightArrowObj = new window.Image()
	rightArrowObj.src = rightArrow

	var triangleUpObj = new window.Image()
	triangleUpObj.src = triangleUp
  
	var triangleDownObj = new window.Image()
	triangleDownObj.src = triangleDown

	useEffect(() => {
		setWidthTCInfo(Math.min(widthGrid-(isOpenLeftSideBar?0:80), Constant.MAX_WIDTH_TC_INFO))
	}, [widthGrid, isOpenLeftSideBar])

	useEffect(() => {
		if (transCenterData) {
			let props:any = {...initialValue}

			// check source voltage
			let res = doGetSourceVolt(transCenterData, mainDiagram, true)
			setHaveSourceVoltage(res.voltage > 0)
			// sPhase
			if (res.sPhase){
				let text = ''
				if (res.upTransKind === EKind.MS_TRANS1){
					text = getTextVoltageEle(props.voltage, res.sPhase, MS_OUTSIDE_OUTSIDE, false)
					text += '-'
					text += getTextVoltageEle(props.voltage, res.sPhase, MS_OUTSIDE_NEUTRAL, false)
				} else if (res.upTransKind === EKind.MS_TRANSSCOTT){
					text = getTextVoltageEle(props.voltage, res.sPhase, MS_SEAT_MAIN, false)
					text += '-'
					text += getTextVoltageEle(props.voltage, res.sPhase, MS_SEAT_T, false)
				}
				setSourceVoltText(text) 
			}

			props.sPhase = res.sPhase
			props.upTransKind = res.upTransKind

			// get connected element
			let previousControl = getTopControl(transCenterData, mainDiagram)
			if (previousControl.code === 0)
				setConnectedElement(previousControl.data)

			doSetValuesTransCenterData(props);
			setInitialValue({...props});
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [transCenterUpdateTimes,processMode])

	useEffect(() => {
		if (initialValue) {
			let displayData = {
				left1: {
					id: infoDisplay.left1.id, 
					value: getValueDisplay(infoDisplay.left1.id)
				},
				left2: {
					id: infoDisplay.left2.id, 
					value: getValueDisplay(infoDisplay.left2.id)
				},
				left3: {
					id: infoDisplay.left3.id, 
					value: getValueDisplay(infoDisplay.left3.id)
				},
				right1: {
					id: infoDisplay.right1.id, 
					value: getValueDisplay(infoDisplay.right1.id)
				},
				right2: {
					id: infoDisplay.right2.id, 
					value: getValueDisplay(infoDisplay.right2.id)
				},
				right3: {
					id: infoDisplay.right3.id, 
					value: getValueDisplay(infoDisplay.right3.id)
				},
			}
			setInfoDisplay({...displayData})
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialValue])

	const getImageLeftPostion = (element: ControlModel) => {
		let left = null
		if (element?.type === EKind.MS_ZEROCOND) left = "18px"
		else if (element?.type === EKind.MS_LVCB){
			if (element.properties.symKind == 1) left = "3px"
			else if (element.properties.symKind == 3) left = "1px"
		}
		else if ([EKind.MS_HVCB, EKind.MS_THERMAL_CT].includes(element?.type)) left = "6px"
		else if ([EKind.MS_CT, EKind.MS_ZCT].includes(element?.type)) left = "1px"
		else if (element?.type === EKind.MS_CONTACTOR){
			if (element.properties.exchangeNumber != 0) left = "9px"
		}
		else if (element?.type === EKind.MS_FUSE){
			if (element.properties.symKind == 3) left = "4px"
		}
		else if ([EKind.MS_2E, EKind.MS_THERMAL].includes(element?.type)){
			if (element.properties.symKind == 1) left = "9px"
		}
	
		return left
	}

	const getWidthImage = (element: ControlModel) => {
		let width = "20px"
		if (element?.type === EKind.MS_ZEROCOND) width = "4px"
		else if (element?.type === EKind.MS_2E) {
			if (element.properties.symKind == 0) width = "15px"
			else width = "10px"
		}
		else if (element?.type === EKind.MS_CONTACTOR){
			width = "11px"
		}
		else if ([EKind.MS_HVCB, EKind.MS_THERMAL_CT].includes(element?.type)) width = "14px"
		else if (element?.type === EKind.MS_FUSE){
			if (element.properties.symKind == 3) width = "12px"
		}
		else if (element?.type === EKind.MS_THERMAL){
			if (element.properties.symKind == 0) width = "16px"
			else width = "10px"
		}

		return width
	}

	const doSetValuesTransCenterData = (initialData:any) => {
        let data:any = {properties:{}};
        data.properties = initialData;
        if(data.properties.sPhase == true){
            switch(data.properties.upTransKind){
                case getElementKindValue(EKind.MS_TRANS1):
                    data.properties.percentZ =  Number(doGetTextDirectPercentZr(data,processMode,MS_OUTSIDE_OUTSIDE,false));
                    data.properties.percentR =  Number(doGetTextDirectPercentRr(data,processMode,MS_OUTSIDE_OUTSIDE,false));
                    data.properties.percentX =  Number(doGetTextDirectPercentXr(data,processMode,MS_OUTSIDE_OUTSIDE,false));
                    data.properties.xr = Number(doGetTextDirectXR(data,processMode,MS_OUTSIDE_OUTSIDE));
                    data.properties.ohmZ = Number(doGetTextDirectOhmZ(data,processMode,MS_OUTSIDE_OUTSIDE,false));
                    data.properties.ohmR = Number(doGetTextDirectOhmR(data,processMode,MS_OUTSIDE_OUTSIDE,false));
                    data.properties.ohmX = Number(doGetTextDirectOhmX(data,processMode,MS_OUTSIDE_OUTSIDE,false));
                    break
                case getElementKindValue(EKind.MS_TRANSSCOTT):
                    data.properties.percentZ =  Number(doGetTextDirectPercentZr(data,processMode,MS_SEAT_MAIN,false));
                    data.properties.percentR =  Number(doGetTextDirectPercentRr(data,processMode,MS_SEAT_MAIN,false));
                    data.properties.percentX =  Number(doGetTextDirectPercentXr(data,processMode,MS_SEAT_MAIN,false));
                    data.properties.xr = Number(doGetTextDirectXR(data,processMode,MS_SEAT_MAIN));
                    data.properties.ohmZ = Number(doGetTextDirectOhmZ(data,processMode,MS_SEAT_MAIN,false));
                    data.properties.ohmR = Number(doGetTextDirectOhmR(data,processMode,MS_SEAT_MAIN,false));
                    data.properties.ohmX = Number(doGetTextDirectOhmX(data,processMode,MS_SEAT_MAIN,false));
                    break;

            }
        }else{
            data.properties.percentZ =  Number(doGetTextDirectPercentZr(data,processMode,MS_3PHASES,false));
            data.properties.percentR =  Number(doGetTextDirectPercentRr(data,processMode,MS_3PHASES,false));
            data.properties.percentX =  Number(doGetTextDirectPercentXr(data,processMode,MS_3PHASES,false));
            data.properties.xr = Number(doGetTextDirectXR(data,processMode,MS_3PHASES));
            data.properties.ohmZ = Number(doGetTextDirectOhmZ(data,processMode,MS_3PHASES,false));
            data.properties.ohmR = Number(doGetTextDirectOhmR(data,processMode,MS_3PHASES,false));
            data.properties.ohmX = Number(doGetTextDirectOhmX(data,processMode,MS_3PHASES,false));
        }
    }


	const handleChangeInfoDisplay = (e: any) => {
		let displayData: any = {}
		displayData[e.target.name] = {
			id: e.target.value, 
			value: getValueDisplay(e.target.value)
		}
		setInfoDisplay(valueDisplay => ({...valueDisplay, ...displayData}))

		let tmpData: any = {...initialValue}
		if (e.target.name.includes('left')){
			tmpData[e.target.name] = 
				infoLeftOptions.find((element) => 
					element.value == e.target.value)?.label
		} else {
			tmpData[e.target.name] = 
				infoRightOptions.find((element) => 
					element.value == e.target.value)?.label
		}
		setInitialValue({...tmpData})
		updateTransCenterProps(transCenterData.id, tmpData)
	}

	const handleOpenDialog = () => {
		let params = {
			userId: userId,
			projectId: projectData.projectId,
			elementId: transCenterData.id,
			elementType: transCenterData.type,
			data: transCenterData.properties,
			ownerProject: projectData.ownerId
		};
		setLoadingFlag(true);
		getElementParam(params, transCenterData.id);
	}

	const handleCancelEditorDialog = () => {
        setIsOpenEditorDialog(false)
    }

    const handleUpdate = (data: any) => {
		setInitialValue({ ...data })

        updateTransCenterProps(transCenterData.id, { ...data })
        setIsOpenEditorDialog(false)
		
        // call api
		let params = {
			...data,
			upTransKind: Number(getElementKindValue(data.upTransKind))
		}
        params = convertUndefinedToNull({...params})
        const request = {
            userId: userId,
            projectId: projectData.projectId,
            elementType: getElementKindValue(transCenterData.type),
            elementId: transCenterData.id,
            param: params,
			ownerProject: projectData.ownerId
        }
		setLoadingFlag(true);
        updateElementPost(request, transCenterData.id)
		
		let xFrameCount = parseInt(params.xFrameCount)
		if (xFrameCount % 2 === 0) xFrameCount += 1

		let width = xFrameCount * Constant.POINTER_WIDTH;
		let height = params.yFrameCount * Constant.POINTER_HEIGHT;
		setWidthGrid(width);
		setHeightGrid(height);
    }

	const getValueDisplay = (id: number) => {
		if (id === 1) return String(initialValue.togetherLoad)
		if (id === 2) return String(initialValue.powerFactor)
		if (id === 3) return String(initialValue.fullCurrent)
		if (id === 4) return String(initialValue.maxPower)
		if (id === 5) return String(initialValue.demandFactor)
		if (id === 6) return String(initialValue.periodAveragePower)
		if (id === 7) return String(initialValue.periodMaxPower)
		if (id === 8) return String(initialValue.loadFactor)

		if (id === 9) return connectedElement?.properties?.refNo || '未接続'
		if (id === 10) return String(initialValue.sPhase?sourceVoltText:initialValue.voltage)
		if (id === 11) return String(initialValue.xr || '未計算')
		if (id === 12) return String(!isNaN(initialValue.percentZ) ? `${initialValue.percentZ}=${initialValue.percentR}+j${initialValue.percentX}` : '未計算')
		if (id === 13) return String(!isNaN(initialValue.ohmZ) ? `${initialValue.ohmZ}=${initialValue.ohmR}+j${initialValue.ohmX}` : '未計算')
		if (id === 14) return '未計算'
		if (id === 15) return '未計算'
		if (id === 16) return '未計算'
		else return ''
	}

	const handleSubmitError = (error: any) => {
		console.log(">>>> handle Error");
		console.log(error);
		setLoadingFlag(false);
	}
	  
	const handleGetSuccess = (data: any) => {
		let newData = data.data
		let initData = {...initialValue}
		
        let convertData = convertVoltToOptionList(newData.voltageList)
		setInitialValue({...initData, voltageList: convertData})
		
		doSetValuesTransCenter({...initData, voltageList: convertData})
	}

	const doSetValuesTransCenter = (newValues: MSTransCenterModel) => {
		let tmpCalc = doGetValuesTransCenter(
			newValues,
			transCenterDiagram,
		)

		setInitialValue(tmpCalc.newProps)
		setCapacityXPowerFactor(tmpCalc.capacityXPowerFactor)
		
		updateTransCenterProps(transCenterData.id, tmpCalc.newProps)
		
		setLoadingFlag(false);
		setIsOpenEditorDialog(true)
	}
	return (
		<>
			<Stack sx={{ width: widthGrid, height: `calc(${Constant.TC_INFO_TABLE_HEIGHT}px + ${offsetY}px)`, paddingRight:m_bModePM || modeViewOnly?'0px':Constant.DASH_BOARD_RIGHT_WIDTH}}>
				<Grid container mt={1}>
					{/* margin right */}
					<Box width={(widthGrid - widthTCInfo)/2}/>

					{/* column 1 - 2 */}
					<Grid width={widthTCInfo/2 - 30} pl={1}>
						<Grid container>
							<Grid item xs={7}>
								<FormControl fullWidth size='small'>
									<Select 
										name='left1' 
										value={infoDisplay.left1.id} 
										onChange={handleChangeInfoDisplay}
										sx={{height:32}}
									>
										{infoLeftOptions.map((item: { label: string, value: any }) => (
											<MenuItem key={item.label} value={item.value}>
												{item.label}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={5} pl={1}>
								<TextField
									fullWidth
									value={infoDisplay.left1.value}
									type='text'
									size='small'
									InputLabelProps={{style: { color: 'black' }}}
									inputProps={{ readOnly: true }}
									style={{ backgroundColor: 'floralwhite' }}
									InputProps={{ sx: {height: 32} }}
								/>
							</Grid>
						</Grid>
						
						<Grid container my={1}>
							<Grid item xs={7}>
								<FormControl fullWidth size='small'>
									<Select 
										name='left2' 
										value={infoDisplay.left2.id} 
										onChange={handleChangeInfoDisplay}
										sx={{height:32}}
									>
										{infoLeftOptions.map((item: { label: string, value: any }) => (
											<MenuItem key={item.label} value={item.value}>
												{item.label}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={5} pl={1}>
								<TextField
									fullWidth
									value={infoDisplay.left2.value}
									type='text'
									size='small'
									InputLabelProps={{style: { color: 'black' }}}
									inputProps={{ readOnly: true }}
									style={{ backgroundColor: 'floralwhite' }}
									InputProps={{ sx: {height: 32} }}
								/>
							</Grid>
						</Grid>
						
						<Grid container>
							<Grid item xs={7}>
								<FormControl fullWidth size='small'>
									<Select 
										name='left3' 
										value={infoDisplay.left3.id} 
										onChange={handleChangeInfoDisplay}
										sx={{height:32}}
									>
										{infoLeftOptions.map((item: { label: string, value: any }) => (
											<MenuItem key={item.label} value={item.value}>
												{item.label}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={5} pl={1}>
								<TextField
									fullWidth
									value={infoDisplay.left3.value}
									type='text'
									size='small'
									InputLabelProps={{style: { color: 'black' }}}
									inputProps={{ readOnly: true }}
									style={{ backgroundColor: 'floralwhite' }}
									InputProps={{ sx: {height: 32} }}
								/>
							</Grid>
						</Grid>

						
						<Grid container mt={1}>
							<Grid item xs={7}>
								<Button fullWidth variant='outlined' size='small' onClick={handleOpenDialog}>
									変台のプロパティ
								</Button>
							</Grid>
							<Grid item xs={5} justifyContent='center' display='flex'>
								<Typography variant='h6' color='initial'>{initialValue?.refNo}</Typography>
							</Grid>
						</Grid>
					</Grid>

					{/* column 3 - image of previous control */}
					<Grid width={80}>
						<Stack
							sx={{
								position:'relative',
								height: '160px',
								width: '20px',
								marginLeft: '20px',
							}}
						>
							<Box
								component='img'
								src={lineImg.src}
								sx={{
									position: 'absolute',
									height: '80%',
									width: '20px',
									backgroundColor: connectedElement?.properties?.isOff ? '#008040' : null
								}}
							/>

							{connectedElement && connectedElement?.type !== EKind.MS_CONNECT &&
								<>
									<Box
										component='img'
										src={preImg.src}
										sx={{
											position: 'absolute',
											top: onlyTopControlArray.includes(connectedElement?.type)
												? '0' : '35%',
											left: getImageLeftPostion(connectedElement),
											width: getWidthImage(connectedElement),
											height: connectedElement?.type === EKind.MS_ZEROCOND
												? '76px'
												: connectedElement?.type === EKind.MS_LVCB
													? '36px'
														: (connectedElement?.type === EKind.MS_SOURCE && connectedElement?.properties.symKind == 1)
															? '8px' : null,
											rotate: connectedElement?.type === EKind.MS_ZEROCOND
												? '90deg' : '0',
											backgroundColor: 
												connectedElement?.properties?.isOff 
												? '#008040' : 'white',
											transform: (
													connectedElement?.type === EKind.MS_CABLEHEAD
													&& connectedElement?.properties.symKind == 1
												) ? 'scaleY(-1)'
												: null
										}}
									/>
									{(connectedElement?.properties?.dropMotor || 
										connectedElement?.properties?.dropRegular ||
										connectedElement?.properties?.dropRegular1 || 
										connectedElement?.properties?.dropRegular2
										) && (
										<Box
											component='img'
											src={leftArrowObj.src}
											sx={{
												position: 'absolute',
												top: connectedElement?.type === EKind.MS_ZEROCOND
													? '50%' : '65%',
												right: connectedElement?.type === EKind.MS_ZEROCOND
													? '0' : '10px',
												height: '20px',
												width: '10px',
											}}
										/>
									)}
									{(connectedElement?.properties?.fault ||
										connectedElement?.properties?.fault1 ||
										connectedElement?.properties?.fault2) && (
										<Box
											component='img'
											src={rightArrowObj.src}
											sx={{
												position: 'absolute',
												top: connectedElement?.type === EKind.MS_ZEROCOND
													? '48%' : '63%',
												left: connectedElement?.type === EKind.MS_ZEROCOND
													? '20px' : '12px',
												height: '40px',
												width: '40px',
											}}
										/>
									)}
								</>
							}
							{(connectedElement 
							&& connectedElement?.type === EKind.MS_WIRE 
							&& connectedElement?.properties.exchangeNumber === 0)
							&&
								<>
									<Box
										component='img'
										src={triangleUpObj.src}
										sx={{
											position: 'absolute',
											top: '3%',
											left: '5px',
											width: '10px',
											backgroundColor: 
												connectedElement?.properties?.isOff 
												? '#008040' : 'white'
										}}
									/>
									<Box
										component='img'
										src={triangleDownObj.src}
										sx={{
											position: 'absolute',
											top: '65%',
											left: '5px',
											width: '10px',
											backgroundColor: 
												connectedElement?.properties?.isOff 
												? '#008040' : 'white'
										}}
									/>
								</>
							}

							<Box
								component='img'
								src={lineImg.src}
								sx={{
									position: 'absolute',
									top: '80%',
									height: '20%',
									width: '20px',
								}}
							/>
						</Stack>
					</Grid>
					
					{/* column 4 - 5 */}
					<Grid width={widthTCInfo/2 - 50}>
						<Grid container>
							<Grid item xs={7}>
								<FormControl fullWidth size='small'>
									<Select 
										name='right1' 
										value={infoDisplay.right1.id} 
										onChange={handleChangeInfoDisplay}
										sx={{height:32}}
									>
										{infoRightOptions.map((item: { label: string, value: any }) => (
											<MenuItem key={item.label} value={item.value}>
												{item.label}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={5} pl={1}>
								<TextField
									fullWidth
									value={infoDisplay.right1.value}
									type='text'
									size='small'
									InputLabelProps={{style: { color: 'black' }}}
									inputProps={{ readOnly: true }}
									style={{ backgroundColor: 'floralwhite' }}
									InputProps={{ sx: {height: 32} }}
								/>
							</Grid>
						</Grid>
						
						<Grid container my={1}>
							<Grid item xs={7}>
								<FormControl fullWidth size='small'>
									<Select 
										name='right2' 
										value={infoDisplay.right2.id} 
										onChange={handleChangeInfoDisplay}
										sx={{height:32}}
									>
										{infoRightOptions.map((item: { label: string, value: any }) => (
											<MenuItem key={item.label} value={item.value}>
												{item.label}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={5} pl={1}>
								<TextField
									fullWidth
									value={infoDisplay.right2.value}
									type='text'
									size='small'
									InputLabelProps={{style: { color: 'black' }}}
									inputProps={{ readOnly: true }}
									style={{ backgroundColor: 'floralwhite' }}
									InputProps={{ sx: {height: 32} }}
								/>
							</Grid>
						</Grid>
						
						<Grid container>
							<Grid item xs={7}>
								<FormControl fullWidth size='small'>
									<Select 
										name='right3' 
										value={infoDisplay.right3.id} 
										onChange={handleChangeInfoDisplay}
										sx={{height:32}}
									>
										{infoRightOptions.map((item: { label: string, value: any }) => (
											<MenuItem key={item.label} value={item.value}>
												{item.label}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={5} pl={1}>
								<TextField
									fullWidth
									value={infoDisplay.right3.value}
									type='text'
									size='small'
									InputLabelProps={{style: { color: 'black' }}}
									inputProps={{ readOnly: true }}
									style={{ backgroundColor: 'floralwhite' }}
									InputProps={{ sx: {height: 32} }}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Stack>
			{loadingFlag && (
				<Dialog open={true} maxWidth={"md"} fullWidth>
					<div style={{ 
						top: "0px", left: "0px", position: "fixed", 
						zIndex: 1500, 
						width: "100%", height: "100%", 
						padding: "50vh 50% 50% 50%", 
						background: "#00000030" 
					}}>
						<CircularProgress/>
					</div>
				</Dialog>
			)}
			{isOpenEditorDialog && (
				<Dialog open={true} fullWidth PaperComponent={DraggablePaper}>
					<DialogTitle
						onMouseOver={(e : any) => e.target.style.cursor = 'move'}
						style={{
							paddingTop: '2px',
							paddingBottom: '2px',
							background: theme.palette.primary.main,
							color: theme.palette.primary.contrastText,
						}}
					>
						変台
					</DialogTitle>
					<DialogContent sx={{ pb: 1.5 }}>
						<MSTransCenterEditDialog
							modeViewOnly={modeViewOnly}
							transCenterDiagram={transCenterDiagram}
							data={initialValue}
							haveSourceVoltage={haveSourceVoltage}
							sourceVoltText={sourceVoltText}
							capacityXPowerFactor={capacityXPowerFactor}
							setCapacityXPowerFactor={setCapacityXPowerFactor}
							onCancel={handleCancelEditorDialog}
							onOK={handleUpdate}
							userRole={userRole}
							m_bModePM={m_bModePM}
							processMode={processMode}
						/>
					</DialogContent>
				</Dialog>
			)}
			<FetchHelper
				fetchId={"GET_TRANS_CENTER2" + transCenterData.id}
				onComplete={(success, data, error) =>
				success
					? handleGetSuccess(data)
					: handleSubmitError(error)
				}
			/>
		</>
	)
}

const mapStateToProps = (state: any) => {
	return {
		userId: state.app.user?.userId,
		projectData: state.app.projectData,
		mainDiagram: state.app.diagram.diagramData.filter(
			(r: any) => r.tabId === 1
		)[0].shape,
		transCenterDiagram: state.app.diagram.diagramData.filter(
			(r: any) => r.tabId === state.app.diagram.currentIDDiagramTab
		)[0].shape,
		transCenterData: state.app.diagram.diagramData
			.filter((r: any) => r.tabId === 1)[0]
			.shape.find(
				(e: any) =>
				e.properties?.tabId === state.app.diagram.currentIDDiagramTab
			),
		transCenterUpdateTimes: state.app.diagram.transCenterUpdate,
        userRole: state.app.user?.userRole,
		m_bModePM: state.app.diagram.m_bModePM,
		modeViewOnly: state.app.diagram.modeViewOnly,
        processMode: state.app.diagram.processMode,
	};
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        updateTransCenterProps: (id: string, data: object) =>
            dispatch(updatePropertiesOfTransCenter(id, data)),
		getElementParam: (data: any, controlId: string) => 
			dispatch(actionCreators.fetch(
				`GET_TRANS_CENTER2${controlId}`, 
				"/diagram/get-param", 
				"POST", 
				data, 
				false, 
				true
			)
		),
        updateElementPost: (data: any, controlId: string) => 
            dispatch(actionCreators.fetch(
				// `UPDATE_TRANS_CENTER${controlId}`, 
				`UPDATE_TRANS_CENTER`, 
				"/diagram/set-param", 
				"POST", 
				data, 
				false, 
				true
			)
		),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(TransCenterInfo)
