import { ControlModel, Iele3WindingPart, MSIntoPointModel, MSTransCenterModel, Ms2EModel, Ms3WindingModel, MsBusbarModel, MsBusductModel, MsContactorModel, MsDSModel, MsFUSEModel, MsGeneratorModel, MsHVCBModel, MsImpedanceModel, MsLBSModel, MsLVCBModel, MsMVCBModel, MsMotorGroupModel, MsReactorModel, MsSourceModel, MsThermalCTModel, MsThermalModel, MsTrans1Model, MsTrans1PartModel, MsTrans3Model, MsTransScottModel, MsTransScottPartModel, MsWireModel, MsZerocondModel } from "../models/Index";
import { MS_3PHASES, MS_OUTSIDE_NEUTRAL, MS_OUTSIDE_OUTSIDE, MS_SEAT_MAIN, MS_SEAT_T, VOLT_SIDE_PRIMARY, VOLT_SIDE_SECONDARY, VOLT_SIDE_THIRD } from "../statics";
import { DoGetTextVoltage as doGetTextVoltageSource, 
		doGetTextDirectIk3asym as doGetTextDirectIk3asymSource, 
		doGetTextDirectIk3peak as doGetTextDirectIk3peakSource, 
		doGetTextDirectIk3sym as doGetTextDirectIk3symSource, 
		doGetTextPointState as doGetTextPointStateSource, 
		doGetValueCapacitySource, 
		isPointFaultSource, 
		DoGetTextPercentZr as doGetTextPercentZrSource,
		doGetTextTotalIk3sym as doGetTextTotalIk3symSource,
		doGetTextTotalIk3peak as doGetTextTotalIk3peakSource, 
		doGetTextTotalIk3asym as doGetTextTotalIk3asymSource,
		doGetTextTotalPercentZr as doGetTextTotalPercentZrSource } from "./source";
import { store } from "..";
import { BOTTOM_CENTER, ELEMENT_SIZE, LEFT_CENTER, MS_ROTATE_NORMAL, MS_TOPLEFT, MS_TRANS1, MS_TRANSSCOTT, POINTER_HEIGHT, POINTER_WIDTH, RIGHT_CENTER, TOP_CENTER } from "../models/Constants";
import * as EKind from "../models/ElementKind";
import { doGetTextDirectIkasym as doGetTextDirectIkasym2E, 
		doGetTextDirectIkpeak as doGetTextDirectIkpeak2E, 
		doGetTextDirectIksym as doGetTextDirectIksym2E, 
		doGetTextDirectPercentZr as doGetTextDirectPercentZr2E, 
		doGetTextPointState as doGetTextPointState2E, 
		doGetTextTotalIk3asym as doGetTextTotalIk3asym2E, 
		doGetTextTotalIk3peak as doGetTextTotalIk3peak2E, 
		doGetTextTotalIk3sym as doGetTextTotalIk3sym2E, 
		doGetTextTotalPercentZr as doGetTextTotalPercentZr2E, 
		doGetTextVoltage as doGetTextVoltage2E } from "./2EFunction";
import { doGetTextDirectIk3asym as doGetTextDirectIk3asym3winding, 
		doGetTextDirectIk3peak as doGetTextDirectIk3peak3winding, 
		doGetTextDirectIk3sym as doGetTextDirectIk3sym3winding, 
		doGetTextDirectPercentZr as doGetTextDirectPercentZr3winding, 
		doGetTextPointState as doGetTextPointState3Winding, 
		doGetTextTotalIk3asym as doGetTextTotalIk3asym3winding, 
		doGetTextTotalIk3peak as doGetTextTotalIk3peak3winding, 
		doGetTextTotalIk3sym as doGetTextTotalIk3sym3winding, 
		doGetTextTotalPercentZr as doGetTextTotalPercentZr3winding, 
		doGetTextVoltage as doGetTextVoltage3winding } from "./3windingFunction";
import { doGetTextDirectIkasym as doGetTextDirectIkasymContactor, 
		doGetTextDirectIkpeak as doGetTextDirectIkpeakContactor, 
		doGetTextDirectIksym as doGetTextDirectIksymContactor, 
		doGetTextDirectPercentZr as doGetTextDirectPercentZrContactor, 
		doGetTextPointState as doGetTextPointStateContactor, 
		doGetTextTotalIk3asym as doGetTextTotalIk3asymContactor, 
		doGetTextTotalIk3peak as doGetTextTotalIk3peakContactor, 
		doGetTextTotalIk3sym as doGetTextTotalIk3symContactor, 
		doGetTextTotalPercentZr as doGetTextTotalPercentZrContactor, 
		doGetTextVoltage as doGetTextVoltageContactor } from "./ContactorFunction";
import { doGetTextDirectIk3asym as doGetTextDirectIk3asymDS, 
		doGetTextDirectIk3peak as doGetTextDirectIk3peakDS, 
		doGetTextDirectIk3sym as doGetTextDirectIk3symDS, 
		doGetTextDirectPercentZr as doGetTextDirectPercentZrDS, 
		doGetTextPointState as doGetTextPointStateDS, 
		doGetTextTotalIk3asym as doGetTextTotalIk3asymDS, 
		doGetTextTotalIk3peak as doGetTextTotalIk3peakDS, 
		doGetTextTotalIk3sym as doGetTextTotalIk3symDS, 
		doGetTextTotalPercentZr as doGetTextTotalPercentZrDS, 
		doGetTextVoltage as doGetTextVoltageDS } from "./DSFunction";
import { getElementCoordinatesData } from "./ElementFunction";
import { doFindSourceFromZero, doGetTransCenterEnterElement, doGetTransCenterIntoPointPointer, isListCennectLoadDropElement, isListCennectSourceDropElement } from "./ElementList";
import { doGetTextPointState as DoGetTextPointStateFuse, 
		doGetTextDirectIk3asym as doGetTextDirectIk3asymFUSE, 
		doGetTextDirectIk3peak as doGetTextDirectIk3peakFUSE, 
		doGetTextDirectIk3sym as doGetTextDirectIk3symFUSE, 
		doGetTextDirectPercentZr as doGetTextDirectPercentZrFUSE, 
		doGetTextTotalIk3asym as doGetTextTotalIk3asymFUSE, 
		doGetTextTotalIk3peak as doGetTextTotalIk3peakFUSE, 
		doGetTextTotalIk3sym as doGetTextTotalIk3symFUSE, 
		doGetTextTotalPercentZr as doGetTextTotalPercentZrFUSE, 
		doGetTextVoltage as doGetTextVoltageFUSE } from "./FUSEFunction";
import { doGetTextDirectIk3asym as doGetTextDirectIk3asymHVCB, 
		doGetTextDirectIk3peak as doGetTextDirectIk3peakHVCB, 
		doGetTextDirectIk3sym as doGetTextDirectIk3symHVCB, 
		doGetTextDirectPercentZr as doGetTextDirectPercentZrHVCB, 
		doGetTextPointState as doGetTextPointStateHVCB, 
		doGetTextTotalIk3asym as doGetTextTotalIk3asymHVCB, 
		doGetTextTotalIk3peak as doGetTextTotalIk3peakHVCB, 
		doGetTextTotalIk3sym as doGetTextTotalIk3symHVCB, 
		doGetTextTotalPercentZr as doGetTextTotalPercentZrHVCB, 
		doGetTextVoltage as doGetTextVoltageHVCB } from "./HVCBFunction";
import { doGetTextDirectIk3asym as doGetTextDirectIk3asymLBS, 
		doGetTextDirectIk3peak as doGetTextDirectIk3peakLBS, 
		doGetTextDirectIk3sym as doGetTextDirectIk3symLBS, 
		doGetTextDirectPercentZr as doGetTextDirectPercentZrLBS, 
		doGetTextPointState as doGetTextPointStateLBS, 
		doGetTextTotalIk3asym as doGetTextTotalIk3asymLBS, 
		doGetTextTotalIk3peak as doGetTextTotalIk3peakLBS, 
		doGetTextTotalIk3sym as doGetTextTotalIk3symLBS, 
		doGetTextTotalPercentZr as doGetTextTotalPercentZrLBS, 
		doGetTextVoltage as doGetTextVoltageLBS } from "./LBSFunction";
import { doGetTextDirectIkasym as doGetTextDirectIkasymLVCB, 
		doGetTextDirectIkpeak as doGetTextDirectIkpeakLVCB, 
		doGetTextDirectIksym as doGetTextDirectIksymLVCB, 
		doGetTextDirectPercentZr as doGetTextDirectPercentZrLVCB, 
		doGetTextPointState as doGetTextPointStateLVCB, 
		doGetTextTotalIk3asym as doGetTextTotalIk3asymLVCB, 
		doGetTextTotalIk3peak as doGetTextTotalIk3peakLVCB, 
		doGetTextTotalIk3sym as doGetTextTotalIk3symLVCB, 
		doGetTextTotalPercentZr as doGetTextTotalPercentZrLVCB, 
		doGetTextVoltage as doGetTextVoltageLVCB } from "./LVCBFunction";
import { doGetTextDirectIkasym as doGetTextDirectIkasymMVCB, 
		doGetTextDirectIkpeak as doGetTextDirectIkpeakMVCB, 
		doGetTextDirectIksym as doGetTextDirectIksymMVCB, 
		doGetTextDirectPercentZr as doGetTextDirectPercentZrMVCB, 
		doGetTextPointState as doGetTextPointStateMVCB, 
		doGetTextTotalIk3asym as doGetTextTotalIk3asymMVCB, 
		doGetTextTotalIk3peak as doGetTextTotalIk3peakMVCB, 
		doGetTextTotalIk3sym as doGetTextTotalIk3symMVCB, 
		doGetTextTotalPercentZr as doGetTextTotalPercentZrMVCB, 
		doGetTextVoltage as doGetTextVoltageMVCB } from "./MVCBFunction";
import { doGetTextDirectIk3asym as doGetTextDirectIk3asymMotorGrp, 
		doGetTextDirectIk3peak as doGetTextDirectIk3peakMotorGrp, 
		doGetTextDirectIk3sym as doGetTextDirectIk3symMotorGrp, 
		doGetTextDirectPercentZr as doGetTextDirectPercentZrMotorGrp, 
		doGetTextPointState as doGetTextPointStateMotorGrp, 
		doGetTextTotalIk3asym as doGetTextTotalIk3asymMotorGrp, 
		doGetTextTotalIk3peak as doGetTextTotalIk3peakMotorGrp, 
		doGetTextTotalIk3sym as doGetTextTotalIk3symMotorGrp, 
		doGetTextTotalPercentZr as doGetTextTotalPercentZrMotorGrp, 
		doGetTextVoltage as doGetTextVoltageMotorGrp } from "./MotorGrpFunction";
import { doGetTextDirectIkasym as doGetTextDirectIkasymTHERMAL, 
		doGetTextDirectIkpeak as doGetTextDirectIkpeakTHERMAL, 
		doGetTextDirectIksym as doGetTextDirectIksymTHERMAL, 
		doGetTextDirectPercentZr as doGetTextDirectPercentZrTHERMAL, 
		doGetTextPointState as doGetTextPointStateThermal, 
		doGetTextTotalIk3asym as doGetTextTotalIk3asymTHERMAL, 
		doGetTextTotalIk3peak as doGetTextTotalIk3peakTHERMAL, 
		doGetTextTotalIk3sym as doGetTextTotalIk3symTHERMAL, 
		doGetTextTotalPercentZr as doGetTextTotalPercentZrTHERMAL, 
		doGetTextVoltage as doGetTextVoltageTHERMAL } from "./THERMALFunction";
import { doGetTextDirectIkasym as doGetTextDirectIkasymThermalCT, 
		doGetTextDirectIkpeak as doGetTextDirectIkpeakThermalCT, 
		doGetTextDirectIksym as doGetTextDirectIksymThermalCT, 
		doGetTextDirectPercentZr as doGetTextDirectPercentZrThermalCT, 
		doGetTextPointState as doGetTextPointStateThermalCT, 
		doGetTextTotalIk3asym as doGetTextTotalIk3asymThermalCT, 
		doGetTextTotalIk3peak as doGetTextTotalIk3peakThermalCT, 
		doGetTextTotalIk3sym as doGetTextTotalIk3symThermalCT, 
		doGetTextTotalPercentZr as doGetTextTotalPercentZrThermalCT, 
		doGetTextVoltage as doGetTextVoltageThermalCT } from "./ThermalCTFunction";
import { doGetTextDirectIkSasym as doGetTextDirectIkSasymTrans1, 
		doGetTextDirectIkSpeak as doGetTextDirectIkSpeakTrans1, 
		doGetTextDirectIkSsym as doGetTextDirectIkSsymTrans1, 
		doGetTextDirectPercentZr as doGetTextDirectPercentZrTrans1, 
		doGetTextPointState as doGetTextPointStateTrans1, 
		doGetTextVoltSecondary as doGetTextVoltSecondaryTrans1 } from "./Trans1Function";
import { doGetTextDirectIk3asym as doGetTextDirectIk3asymTrans3, 
		doGetTextDirectIk3peak as doGetTextDirectIk3peakTrans3, 
		doGetTextDirectIk3sym as doGetTextDirectIk3symTrans3, 
		doGetTextDirectPercentZr as doGetTextDirectPercentZrTrans3, 
		doGetTextPointState as doGetTextPointStateTrans3, 
		doGetTextTotalIk3asym as doGetTextTotalIk3asymTrans3, 
		doGetTextTotalIk3peak as doGetTextTotalIk3peakTrans3, 
		doGetTextTotalIk3sym as doGetTextTotalIk3symTrans3, 
		doGetTextTotalPercentZr as doGetTextTotalPercentZrTrans3, 
		doGetTextVoltSecondary as doGetTextVoltSecondaryTrans3 } from "./Trans3Function";
import { doGetTextDirectIkasym as doGetTextDirectIkasymTransCenter, 
		doGetTextDirectIkpeak as doGetTextDirectIkpeakTransCenter, 
		doGetTextDirectIksym as doGetTextDirectIksymTransCenter, 
		doGetTextDirectPercentZr as doGetTextDirectPercentZrTransCenter, 
		doGetTextPointState as doGetTextPointStateTransCenter, 
		doGetTextVoltage as doGetTextVoltageTransCenter, 
		isPointFaultTransCenter } from "./TransCenterFunction";
import { doGetTextPointState as doGetTextPointStateZeroCond, 
		doGetTextTotalIkasym as doGetTextTotalIkasymZeroCond, 
		doGetTextTotalIkpeak as doGetTextTotalIkpeakZeroCond, 
		doGetTextTotalIksym as doGetTextTotalIksymZeroCond, 
		doGetTextTotalPercentZr as doGetTextTotalPercentZrZeroCond, 
		doGetTextVoltage as doGetTextVoltageZeroCond, 
		isPointFaultZeroCond } from "./ZeroCondFunction";
import { doGetTextDirectIkasym as doGetTextDirectIkasymBusBar, 
		doGetTextDirectIkpeak as doGetTextDirectIkpeakBusBar, 
		doGetTextDirectIksym as doGetTextDirectIksymBusBar, 
		doGetTextDirectPercentZr as doGetTextDirectPercentZrBusBar, 
		doGetTextPointState as doGetTextPointStateBusbar, 
		doGetTextTotalIk3asym as doGetTextTotalIk3asymBusBar, 
		doGetTextTotalIk3peak as doGetTextTotalIk3peakBusBar, 
		doGetTextTotalIk3sym as doGetTextTotalIk3symBusBar, 
		doGetTextTotalPercentZr as doGetTextTotalPercentZrBusBar, 
		doGetTextVoltage as doGetTextVoltageBusBar } from "./busbarFunction";
import { doGetTextDirectIkasym as doGetTextDirectIkasymBusDuct, 
		doGetTextDirectIkpeak as doGetTextDirectIkpeakBusDuct, 
		doGetTextDirectIksym as doGetTextDirectIksymBusDuct, 
		doGetTextDirectPercentZr as doGetTextDirectPercentZrBusDuct, 
		doGetTextPointState as doGetTextPointStateBusduct, 
		doGetTextTotalIk3asym as doGetTextTotalIk3asymBusDuct, 
		doGetTextTotalIk3peak as doGetTextTotalIk3peakBusDuct, 
		doGetTextTotalIk3sym as doGetTextTotalIk3symBusDuct, 
		doGetTextTotalPercentZr as doGetTextTotalPercentZrBusDuct, 
		doGetTextVoltage as doGetTextVoltageBusDuct } from "./busductFunction";
import { doGetTextDirectIk3asym as doGetTextDirectIk3asymGenerator, 
		doGetTextDirectIk3peak as doGetTextDirectIk3peakGenerator, 
		doGetTextDirectIk3sym as doGetTextDirectIk3symGenerator, 
		doGetTextDirectPercentZr as doGetTextDirectPercentZrGenerator, 
		doGetTextPointState as doGetTextPointStateGenerator, 
		doGetTextTotalIk3asym as doGetTextTotalIk3asymGenerator, 
		doGetTextTotalIk3peak as doGetTextTotalIk3peakGenerator, 
		doGetTextTotalIk3sym as doGetTextTotalIk3symGenerator, 
		doGetTextTotalPercentZr as doGetTextTotalPercentZrGenerator, 
		doGetTextVoltage as doGetTextVoltageGenerator } from "./generatorFunction";
import { doGetTextDirectIkasym as doGetTextDirectIkasymImpedance, 
		doGetTextDirectIkpeak as doGetTextDirectIkpeakImpedance, 
		doGetTextDirectIksym as doGetTextDirectIksymImpedance, 
		doGetTextDirectPercentZr as doGetTextDirectPercentZrImpedance, 
		doGetTextPointState as doGetTextPointStateImpedance, 
		doGetTextTotalIk3asym as doGetTextTotalIk3asymImpedance, 
		doGetTextTotalIk3peak as doGetTextTotalIk3peakImpedance, 
		doGetTextTotalIk3sym as doGetTextTotalIk3symImpedance, 
		doGetTextTotalPercentZr as doGetTextTotalPercentZrImpedance, 
		doGetTextVoltage as doGetTextVoltageImpedance } from "./impedance";
import { doGetTextDirectIk3asym as doGetTextDirectIk3asymReactor, 
		doGetTextDirectIk3peak as doGetTextDirectIk3peakReactor, 
		doGetTextDirectIk3sym as doGetTextDirectIk3symReactor, 
		doGetTextDirectPercentZr as doGetTextDirectPercentZrReactor, 
		doGetTextPointState as doGetTextPointStateReactor, 
		doGetTextTotalIk3asym as doGetTextTotalIk3asymReactor, 
		doGetTextTotalIk3peak as doGetTextTotalIk3peakReactor, 
		doGetTextTotalIk3sym as doGetTextTotalIk3symReactor, 
		doGetTextTotalPercentZr as doGetTextTotalPercentZrReactor, 
		doGetTextVoltage as doGetTextVoltageReactor } from "./reactorFunction";
import { doGetTextDirectIkSasym as doGetTextDirectIkSasymTransscott, 
		doGetTextDirectIkSpeak as doGetTextDirectIkSpeakTransscott, 
		doGetTextDirectIkSsym as doGetTextDirectIkSsymTransscott, 
		doGetTextPointState as doGetTextPointStateTransscott } from "./transscottFunction";
import { doGetTextDirectIkasym as doGetTextDirectIkasymWire, 
		doGetTextDirectIkpeak as doGetTextDirectIkpeakWire, 
		doGetTextDirectIksym as doGetTextDirectIksymWire, 
		doGetTextDirectPercentZr as doGetTextDirectPercentZrWire, 
		doGetTextPointState as doGetTextPointStateWire, 
		doGetTextTotalIk3asym as doGetTextTotalIk3asymWire, 
		doGetTextTotalIk3peak as doGetTextTotalIk3peakWire, 
		doGetTextTotalIk3sym as doGetTextTotalIk3symWire, 
		doGetTextTotalPercentZr as doGetTextTotalPercentZrWire, 
		doGetTextVoltage as doGetTextVoltageWire } from "./wireFunction";
import { doGetTextTotalAsymCoefficient as doGetTextTotalAsymCoefficientCalcPoint, 
		doGetTextTotalPeakCoefficient as doGetTextTotalPeakCoefficientCalcPoint, 
		doGetTextDirectPeakCoefficient as doGetTextDirectPeakCoefficientCalcPoint, 
		doGetTextDirectAsymCoefficient as doGetTextDirectAsymCoefficientCalcPoint} from "./calcPointFunction"
import { getRectBaseLP } from "../components/dxfFile/dataConverter";
const MS_ROTATE_LEFT = -90;
const MS_ROTATE_RIGHT = 90;

//======== calc capacity ===============
const notConnectTopArray = [
    EKind.MS_SOURCE,
    EKind.MS_GENERATOR
  ]
  interface ResultData {
    code: number,
    data: any,
    message?: string
  }
  const typeTopArray1 = [
    EKind.MS_SOURCE,
    EKind.MS_GENERATOR,
    EKind.MS_TRANS3,
    EKind.MS_TRANS1,
    EKind.MS_TRANSSCOTT,
  
  ]
  const typeBottomArray=[
    EKind.MS_TRANS3,
    EKind.MS_TRANSSCOTT,
    EKind.MS_TRANS1,
    EKind.MS_3WINDING,
    EKind.MS_MOTOR_GROUP,
    EKind.MS_MOTOR,
    EKind.MS_LOAD,
    EKind.MS_LIGHTBOARD,
    EKind.MS_POWERBOARD,
    EKind.MS_CAPACITOR,
    EKind.MS_LOADCENTER,
  ]
  const notConnectBottomArray = [
    EKind.MS_MOTOR_GROUP,
    EKind.MS_MOTOR,
    EKind.MS_LOAD,
    EKind.MS_LIGHTBOARD,
    EKind.MS_POWERBOARD,
    EKind.MS_CAPACITOR,
    EKind.MS_TRANSCENTER,
    // EKind.MS_ARRESTOR,
    EKind.MS_EARTH
  ]
  const type0VoltageArray = [
    EKind.MS_CONNECT,
    EKind.MS_TEXT
  ]
  
  const type2VoltageArray = [
    EKind.MS_TRANS1,
    EKind.MS_TRANS3,
    EKind.MS_TRANSSCOTT
  ]
  const typeHave2StateCircuit = [
    EKind.MS_TRANSCENTER,
    EKind.MS_IMPEDANCE,
    EKind.MS_BUSDUCT,
    EKind.MS_BUSBAR,
    EKind.MS_WIRE,
    EKind.MS_MVCB,
    EKind.MS_CONTACTOR,
    EKind.MS_LVCB,
    EKind.MS_2E,
    EKind.MS_THERMAL,
    EKind.MS_MOTOR,
    EKind.MS_LOAD,
    EKind.MS_LOADCENTER,
    EKind.MS_ZEROCOND,
    EKind.MS_CONNECT,
    EKind.MS_THERMAL_CT,
    EKind.MS_CABLEHEAD,
    EKind.MS_CT,
    EKind.MS_LIGHTBOARD,
    EKind.MS_POWERBOARD,
  
    EKind.MS_INTO_POINT,
  ]
  const capacityNormal = [
    EKind.MS_GENERATOR,
    EKind.MS_TRANS1,
    EKind.MS_TRANS3,
    EKind.MS_TRANSCENTER,
    EKind.MS_MOTOR,
    EKind.MS_MOTOR_GROUP,
    EKind.MS_LOAD,
    EKind.MS_CAPACITOR,
    EKind.MS_TRANSSCOTT
  ]
export const doGetValueCapacity =(control:ControlModel,volt:number) =>{
    let capacity = 0
    if(capacityNormal.includes(control.type)){
      capacity = control.properties.capacity
    }
    else if(control.type === EKind.MS_SOURCE){
      capacity = doGetValueCapacitySource(control) * 1000
    }
    else if(control.type === EKind.MS_3WINDING){
      if(volt === VOLT_SIDE_PRIMARY){
        capacity = control.properties.partPrimary.capacity
      }
      else if(volt === VOLT_SIDE_SECONDARY){
        capacity = control.properties.partSecondary.capacity
      }
      else if(volt === VOLT_SIDE_THIRD){
        capacity = control.properties.partThird.capacity
      }
    }
    return capacity
  }

  export const doGetValueCapacityFromZero = (target: ControlModel,
    diagramData: ControlModel[]) =>{
  
      let capacity = 0
      // get top
      for(;;){
        let topControl = getTopControl(target, diagramData)
        let control: ControlModel = topControl.data
        if(topControl.code !== 0){
            break;
        }
        for(;;){
            if(typeTopArray1.includes(control.type)){
                capacity += doGetValueCapacity(control,VOLT_SIDE_PRIMARY)
                break;
            }
            if(control.type ===  EKind.MS_ZEROCOND){
                capacity += doGetValueCapacityFromZero(control,diagramData)
                    break;
            }
            topControl = getTopControl(control,diagramData)
            control = topControl.data
            if(topControl.code !== 0){
                break;
            }
        }
      }
      //get bottom
      for(;;){
        let bottomControl = getBottomControl(target, diagramData)
        let control: ControlModel = bottomControl.data
        if(bottomControl.code !== 0){
            break;
        }
        for(;;){
            if(typeBottomArray.includes(control.type)){
                break;
            }
            if(control.type ===  EKind.MS_ZEROCOND){
                capacity += doGetValueCapacityFromZero(control,diagramData)
                    break;
            }
            bottomControl = getBottomControl(control,diagramData)
            control = bottomControl.data
            if(bottomControl.code !== 0){
                break;
            }
        }
      }
      //get left
      let leftControl =  target
      for(;;){
       let leftContr = getLeftControl(leftControl,diagramData)
       leftControl = leftContr.data
        if(leftContr.code !== 0){
            break;
        }
        if(leftControl.type === EKind.MS_3WINDING){
            capacity += doGetValueCapacity(leftControl,VOLT_SIDE_SECONDARY)
            break;
        }
        if(leftControl.type === EKind.MS_ZEROCOND){
            capacity += doGetValueCapacityFromZero(leftControl,diagramData)
            break;
        }
        
      }
      // get right
      let rightControl = target
      for(;;){
        let rightContr = getRightControl(rightControl,diagramData)
        rightControl = rightContr.data
        if(rightContr.code !== 0){
            break;
        }
        if(rightControl.type === EKind.MS_3WINDING){
            capacity += doGetValueCapacity(rightControl,VOLT_SIDE_THIRD)
            break;
        }
        if(rightControl.type === EKind.MS_ZEROCOND){
            capacity += doGetValueCapacityFromZero(rightControl,diagramData)
            break;
        }

      }
  return capacity;
  }
  export const checkIsBottom = (target: ControlModel, other: ControlModel) => {
    if (notConnectTopArray.includes(other.type))
      return 0
  
    if (target.rotation === 0 && other.rotation === 0) {
      if (target.x === other.x && target.y + target.height === other.y) return 1
    }
  
    if (target.type === EKind.MS_ZEROCOND){
      if (other.rotation === 0) {
        if (
          other.x >= target.x &&
          other.x <= target.x + target.height - POINTER_WIDTH &&
          other.y === target.y + POINTER_HEIGHT
        )
          return 1
      }
    }
  
    if (other.type === EKind.MS_ZEROCOND){
      if (target.rotation === 0) {
        if (
          target.x >= other.x &&
          target.x <= other.x + other.height - POINTER_WIDTH &&
          target.y + target.height === other.y
        )
          return 1
      }
    }
  
    return 0
  }
  export const checkIsTop = (target: ControlModel, other: ControlModel) => {
    if (
      notConnectBottomArray.includes(other.type) ||
      other.type === EKind.MS_3WINDING ||
      (target.type === EKind.MS_ARRESTOR && target.properties.haveEarth)
    ){
      return 0
    }
  
    if (target.rotation === 0 && other.rotation === 0) {
      if (target.x === other.x && target.y === other.y + other.height) 
        return 2
    }
  
    if (target.type === EKind.MS_ZEROCOND){
      if (other.rotation === 0) {
        if (
          other.x >= target.x &&
          other.x <= target.x + target.height - POINTER_WIDTH &&
          other.y + other.height === target.y
        )
          return 2
      }
    }

    if (other.type === EKind.MS_ZEROCOND){
      if (target.rotation === 0) {
        if (
          target.x >= other.x &&
          target.x <= other.x + other.height - POINTER_WIDTH &&
          target.y === other.y + POINTER_HEIGHT
        )
          return 2
      }
    }

    return 0
  }
  export const checkIsLeft = (target: ControlModel, other: ControlModel) => {
    const converted_other = getElementCoordinatesData(other);
    const converted_target = getElementCoordinatesData(target);
    let size_other:any,top_other:any,bottom_other:any,size_target:any,top_target:any,bottom_target:any;
    if(converted_other.xMax !== null && converted_other.yMax !== null && converted_other.xMax !== undefined && converted_other.yMax !== undefined){
        size_other = {
            cx: converted_other.xMax - converted_other["x"],
            cy: converted_other.yMax - converted_other["y"]
        };
        top_other = {
            x: converted_other["x"],
            y: converted_other["y"]
        };
        
        bottom_other = {
            x: converted_other.xMax,
            y: converted_other.yMax
        };
    }
    if(converted_target.xMax !== null && converted_target.yMax !== null && converted_target.xMax !== undefined && converted_target.yMax !== undefined){
        size_target = {
            cx: converted_target.xMax - converted_target["x"],
            cy: converted_target.yMax - converted_target["y"]
        };
        
        top_target = {
            x: converted_target["x"],
            y: converted_target["y"]
        };
        
        bottom_target = {
            x: converted_target.xMax,
            y: converted_target.yMax
        };
    };
    if(target && other && size_other &&  top_other && bottom_other && size_target && top_target && bottom_target){
        if (target.type == EKind.MS_3WINDING) {
            if (other.rotation == MS_ROTATE_RIGHT &&
                other.type == EKind.MS_HVCB &&
                top_target["x"] == top_other["x"] + size_other["cx"] &&
                bottom_target["y"] + ELEMENT_SIZE  == bottom_other["y"]) 
                return 3
            else if (top_other["x"] + size_other["cx"] == top_target["x"] &&
                bottom_other["y"] == bottom_target["y"]) 
                return 3
        }
        else if (other.type == EKind.MS_3WINDING) {
            if (
                top_other["x"] + size_other["cx"] == top_target["x"] &&
                bottom_other["y"] == bottom_target["y"]
            )
                return 3
        }
        else if (target.type == EKind.MS_HVCB && target.rotation == MS_ROTATE_LEFT) {
            if (
                other.rotation == MS_ROTATE_LEFT &&
                bottom_other["y"] == bottom_target["y"] &&
                top_target["x"] == top_other["x"] + size_other["cx"]
            )
                return 3
            else if (
                other.rotation == MS_ROTATE_RIGHT &&
                other.type == EKind.MS_HVCB
            ) {
                if (
                    top_target["x"] == top_other["x"] + size_other["cx"] &&
                    top_target["y"] + size_other["cy"] / 2 == top_other["y"]
                )
                    return 3
            } else if (
                bottom_other["y"] == bottom_target["y"] &&
                top_target["x"] == top_other["x"] + size_other["cx"]
            ) 
                return 3
        }
        else if(target.type === EKind.MS_HVCB && target.rotation === MS_ROTATE_RIGHT){
            if(other.rotation === MS_ROTATE_LEFT){
                if(bottom_target["y"] === bottom_other["y"] + size_target["cy"]
                   && top_target["x"] === top_other["x"] + size_other["cx"])
                   return 3;
            }
            if(other.rotation === MS_ROTATE_RIGHT
               && top_target["x"] === top_other["x"] + size_other["cx"]
               && top_target["y"] === top_other["y"])
               return 3;
            else if(top_target["y"] === top_other["y"] && top_target["x"] === top_other["x"] + size_other["cx"])
                return 3;
        }
        else if(other.type === EKind.MS_HVCB){
            if(other.rotation === MS_ROTATE_LEFT
                && bottom_target["y"] === bottom_other["y"]
                && top_target["x"] === top_other["x"] + size_other["cx"])
                return 3;
            if(other.rotation === MS_ROTATE_RIGHT
                && top_target["x"] === top_other["x"] + size_other["cx"]
                && top_target["y"] === top_other["y"])
                return 3;
        }
        else{
            if(top_target["y"] != top_other["y"])
                return 0
            if(top_target["x"] === top_other["x"] + size_other["cx"])
                return 3
        }
    }
        
    

    
    // if (target.rotation !== 0 && other.rotation !== 0) {
    //   if (target.y === other.y && target.x === other.x + other.height) return 3
    // }
  
    // if (target.type === EKind.MS_3WINDING && other.type === EKind.MS_3WINDING){
    //   if (
    //     target.x === other.x + POINTER_WIDTH &&
    //     target.y + target.height === other.y + other.height
    //   )
    //     return 3
    // }

    // if (target.type === EKind.MS_3WINDING && other.rotation !== 0) {
    //   if (
    //     target.x === other.x + other.height &&
    //     target.y + target.height === other.y + POINTER_HEIGHT
    //   )
    //     return 3
    // }
  
    // if (other.type === EKind.MS_3WINDING && target.rotation !== 0){
    //   if (
    //     target.x === other.x + POINTER_WIDTH &&
    //     target.y === other.y + other.height - POINTER_HEIGHT
    //   )
    //     return 3
    // }

    // if(other.type === EKind.MS_3WINDING){
    //   if(target.type === EKind.MS_HVCB && other.rotation === -90){
    //     if(target.x === other.x + other.width && other.y + other.height === target.y + target.height){
    //       return 3
    //     }
    //   }
    // }

    // if(other.type === EKind.MS_HVCB){
    //   if(other.rotation === -90){
    //     if(target.x === other.x + other.height && target.y === other.y + 1/2 * other.width)
    //       return 3
    //   }
    // }
    // if(target.type === EKind.MS_HVCB){
    //   if(target.rotation === -90){
    //     if(other.x + other.height === target.x  && other.y === target.y + 1/2 * target.width)
    //       return 3
    //   }
    // }
  
    return 0;
  }
  
  export const checkIsRight = (target: ControlModel, other: ControlModel) => {
    if(target.type === EKind.MS_TEXT || other.type === EKind.MS_TEXT)
        return 0
    const converted_other = getElementCoordinatesData(other);
    const converted_target = getElementCoordinatesData(target); 
    let size_other:any,top_other:any,bottom_other:any,size_target:any,top_target:any,bottom_target:any;
    if(converted_other.xMax !== null && converted_other.yMax !== null && converted_other.xMax !== undefined && converted_other.yMax !== undefined){
        size_other = {
        cx: converted_other.xMax - converted_other["x"],
        cy: converted_other.yMax - converted_other["y"]
    };
    top_other = {
        x: converted_other["x"],
        y: converted_other["y"]
    };
    
    bottom_other = {
        x: converted_other.xMax,
        y: converted_other.yMax
    };
    }
    if(converted_target.xMax !== null && converted_target.yMax !== null && converted_target.xMax !== undefined && converted_target.yMax !== undefined){
        size_target = {
            cx: converted_target.xMax - converted_target["x"],
            cy: converted_target.yMax - converted_target["y"]
        };
        
        top_target = {
            x: converted_target["x"],
            y: converted_target["y"]
        };
        
        bottom_target = {
            x: converted_target.xMax,
            y: converted_target.yMax
        };
    };
    // NEW
    if(target.type === EKind.MS_3WINDING){
        if(other.rotation === MS_ROTATE_RIGHT
           && other.type === EKind.MS_HVCB
           && top_other["x"] === top_target["x"] + size_target["cx"]
           && bottom_other["y"] === bottom_target["y"] + ELEMENT_SIZE)
            return 3;
        else if(top_other["x"] === top_target["x"] + size_target["cx"]
                && bottom_other["y"] === bottom_target["y"])
            return 3;
    }
    else if(other.type === EKind.MS_3WINDING){
        if(top_other["x"] === top_target["x"] + size_target["cx"]
           && bottom_other["y"] === bottom_target["y"])
           return 3;
    }
    else if(target.type === EKind.MS_HVCB && target.rotation === MS_ROTATE_LEFT){
        if(other.rotation === MS_ROTATE_RIGHT){
            if(top_target["y"] + size_target["cy"] /2 === top_other["y"]
               && top_other["x"] === top_target["x"] + size_target["cx"])
               return 3;
        }
        else if(other.rotation === MS_ROTATE_LEFT
                && top_other["x"] === top_target["x"] + size_target["cx"]
                && bottom_target["y"] === bottom_other["y"])
            return 3;
        else if(bottom_target["y"] === bottom_other["y"] 
                && top_other["x"] === top_target["x"] + size_target["cx"])
            return 3;
    }
    else if(target.type === EKind.MS_HVCB && target.rotation === MS_ROTATE_RIGHT){
        if(other.rotation === MS_ROTATE_RIGHT
           && top_target["y"] === top_other["y"]
           && top_other["x"] === top_target["x"] + size_target["cx"])
            return 3;
        else if(other.rotation === MS_ROTATE_LEFT){
            if(top_other["x"] === top_target["x"] + size_target["cx"]
               && bottom_other["y"] + size_target["cy"] /2 === bottom_target["y"])
               return 3;
        }
        else if(top_target["y"] === top_other["y"] && top_other["x"] === top_target["x"] + size_target["cx"])
            return 3;
    }
    else if(other.type === EKind.MS_HVCB){
        if(other.rotation === MS_ROTATE_RIGHT
           && top_target["y"] === top_other["y"]
           && top_other["x"] === top_target["x"] + size_target["cx"])
            return 3;
        if(other.rotation === MS_ROTATE_LEFT
            && top_other["x"] === top_target["x"] + size_target["cx"]
            && bottom_target["y"] === bottom_other["y"])
            return 3;
    }
    else{
        if(top_target["y"] != top_other["y"])
            return 0;
        if(top_other["x"] === top_target["x"] + size_target["cx"])
            return 3;
    }



    // ---------OLD---------------------
    // if (target.rotation !== 0 && other.rotation !== 0) {
    //   if (target.y === other.y && target.x + target.height === other.x) return 4
    // }
  
    // if (target.type === EKind.MS_3WINDING && other.type === EKind.MS_3WINDING){
    //   if (
    //     target.x + POINTER_WIDTH === other.x &&
    //     target.y + target.height === other.y + other.height
    //   )
    //     return 3
    // }
  
    // if (target.type === EKind.MS_3WINDING && other.rotation !== 0) {
    //   if (
    //     target.x + POINTER_WIDTH === other.x &&
    //     target.y + target.height === other.y + POINTER_HEIGHT
    //   )
    //     return 4
    // }
    
    // if (other.type === EKind.MS_3WINDING && target.rotation !== 0){
    //   if (
    //     target.x + target.height === other.x &&
    //     target.y === other.y + other.height - POINTER_HEIGHT
    //   )
    //     return 4
    // }

    // if(other.type === EKind.MS_HVCB){
    //   if(other.rotation === -90){
    //     if(target.x + target.height === other.x  && target.y === other.y + 1/2 * other.width)
    //       return 3
    //   }
    // }

    // if(target.type === EKind.MS_HVCB){
    //   if(target.rotation === -90){
    //     if(other.x === target.x + target.height && other.y === target.y + 1/2 * target.width)
    //       return 3
    //   }
    // }
  
    return 0
  }
  export const roundPosition = (control: ControlModel) => {
    control.x = roundFunc(control.x)
    control.y = roundFunc(control.y)
    return control
  }
  
  const roundFunc = (num: number) => {
    return Math.round(num / POINTER_HEIGHT) * POINTER_HEIGHT
  }
  
  export const getTopControl = (
    target: ControlModel,
    diagramData: ControlModel[],
    isRemove:boolean = true
  ) => {
    if (target.id === undefined)
      return { code: 1, message: "undefined" } as ResultData
  
    if (notConnectTopArray.includes(target.type))
      return { code: 2, message: "Not found" } as ResultData
  
    let res
    let findedIndex = -1
    if (diagramData.length > 0) {
      // target = roundPosition(target)
      diagramData.forEach((e,i) => {
        e = roundPosition(e)
        let isCollided = checkIsTop(target, e)
        if (isCollided !== 0){
          res = e
          findedIndex = i}
      })
    }
    if (res){
        if(isRemove){
          diagramData.splice(findedIndex,1)
        }
        return { code: 0, data: res }
    }
    return { code: 2, message: "Not found" } as ResultData
  }

  export const getBottomControl = (
    target: ControlModel,
    diagramData: ControlModel[],
    isRemove :boolean = true
  ) => {
    if (target.id === undefined)
      return { code: 1, message: "undefined" } as ResultData
  
    if (
      notConnectBottomArray.includes(target.type) ||
      target.type === EKind.MS_3WINDING ||
      (target.type === EKind.MS_ARRESTOR && target.properties.haveEarth)
    )
      return { code: 2, message: "Not found" } as ResultData
  
    let res
    let findedIndex = -1
    if (diagramData.length > 0) {
      // target = roundPosition(target)
  
      diagramData.forEach((e,i) => {
        e = roundPosition(e)
        let isCollided = checkIsBottom(target, e)
        if (isCollided !== 0){
          res = e
          findedIndex = i
        }
      })
    }
  
    if (res){
        if(isRemove){
            diagramData.splice(findedIndex,1)
        }
      return { code: 0, data: res }
    }
    return { code: 2, message: "Not found" } as ResultData
  }
  export const getLeftControl = (
    target: ControlModel,
    diagramData: ControlModel[],
    isRemove: boolean = true

  ) => {
    if (target.id === undefined)
      return { code: 1, message: "undefined" } as ResultData
  
    let res
    let findedIndex = -1

    if (diagramData.length > 0) {
      // target = roundPosition(target)  
      diagramData.forEach((e,i) => {
        e = roundPosition(e)
        let isCollided = checkIsLeft(target, e)
        if (isCollided != 0)
        {
            res = e
            findedIndex = i;
        }
      })
    }
  
    if (res){
        if(isRemove){
            diagramData.splice(findedIndex,1)
        }
      return { code: 0, data: res }
    }
    return { code: 2, message: "Not found" } as ResultData
  }
  
  export const getRightControl = (
    target: ControlModel,
    diagramData: ControlModel[],
    isRemove: boolean = true
  ) => {
    if (!target || target.id === undefined)
      return { code: 1, message: "undefined" } as ResultData
  
    let res
    let findedIndex = -1
    if (diagramData.length > 0) {
      // target = roundPosition(target)  
      diagramData.forEach((e,i) => {
        e = roundPosition(e)
        let isCollided = checkIsRight(target, e)
        if (isCollided){
          res = e
          findedIndex = i
        }
      })
    }
  
    if (res){
        if(isRemove){
            diagramData.splice(findedIndex,1)
        }
      return { code: 0, data: res }

    }
    return { code: 2, message: "Not found" } as ResultData
  }
  export const doGetCircuitVolt = (control: ControlModel, voltSide: number) => {
    let voltage :number = 0
    switch(control.type){
      case EKind.MS_SOURCE:
        voltage = control.properties.voltage
        break;
      case EKind.MS_GENERATOR:
        voltage = control.properties.voltage
        break;
      case EKind.MS_TRANS1:
        if(voltSide === VOLT_SIDE_PRIMARY){
          voltage = control.properties.voltagePrimary
        }
        else if( voltSide === VOLT_SIDE_SECONDARY){
          voltage = control.properties.voltageSecondary
        }
        break;
      case EKind.MS_TRANS3:
        if(voltSide === VOLT_SIDE_PRIMARY){
          voltage = control.properties.voltagePrimary
        }
        else if( voltSide === VOLT_SIDE_SECONDARY){
          voltage = control.properties.voltageSecondary
        }
        break;
      case EKind.MS_TRANSCENTER:
        voltage = control.properties.voltage
        break;
      case EKind.MS_3WINDING:
        if(voltSide === VOLT_SIDE_PRIMARY){
          voltage = control.properties.partPrimary.voltage
        }
        else if( voltSide === VOLT_SIDE_SECONDARY){
          voltage = control.properties.partSecondary.voltage
        }
        else if( voltSide === VOLT_SIDE_THIRD){
          voltage = control.properties.partThird.voltage
        }
        break;
      case EKind.MS_TRANSSCOTT:
        if(voltSide === VOLT_SIDE_PRIMARY){
          voltage = control.properties.voltagePrimary
        }
        else if( voltSide === VOLT_SIDE_SECONDARY){
          voltage = control.properties.voltageSecondary
        }
        break;
      case EKind.MS_IMPEDANCE:
        voltage  = control.properties.voltage
        break;
      case EKind.MS_BUSBAR:
        voltage  = control.properties.voltage
        break;
      case EKind.MS_BUSDUCT:
        voltage  = control.properties.voltage
        break;
      case EKind.MS_WIRE:
        voltage  = control.properties.voltage
        break;
      case EKind.MS_REACTOR:
        voltage  = control.properties.voltage
        break;
      case EKind.MS_HVCB:
        voltage  = control.properties.voltage
        break;
      case EKind.MS_RELAY51:
        voltage  = control.properties.voltage
        break;
      case EKind.MS_MVCB:
        voltage  = control.properties.voltage
        break;
      case EKind.MS_CONTACTOR:
        voltage  = control.properties.voltage
        break;
      case EKind.MS_LVCB:
        voltage  = control.properties.voltage
        break;
      case EKind.MS_FUSE:
        voltage  = control.properties.voltage
        break;
      case EKind.MS_2E:
        voltage  = control.properties.voltage
        break;
      case EKind.MS_THERMAL:
        voltage  = control.properties.voltage
        break;
      case EKind.MS_MOTOR:
        voltage  = control.properties.voltageID
        break;
      case EKind.MS_MOTOR_GROUP:
        voltage  = control.properties.voltage
        break;
      case EKind.MS_LOAD:
        voltage  = control.properties.voltage
        break;
      case EKind.MS_CAPACITOR:
        voltage  = control.properties.voltage
        break;
      case EKind.MS_LOADCENTER:
        voltage  = control.properties.voltage
        break;
      case EKind.MS_ZEROCOND:
        voltage  = control.properties.voltage
        break;
      case EKind.MS_CONNECT:
        break;
      case EKind.MS_TEXT:
        break;
      case EKind.MS_DS:
        voltage  = control.properties.voltage
        break;
      case EKind.MS_LBS:
        voltage  = control.properties.voltage
        break;
      case EKind.MS_THERMAL_CT:
        voltage  = control.properties.voltage
        break;
      case EKind.MS_ARRESTOR:
        voltage  = control.properties.voltage
        break;
      case EKind.MS_EARTH:
        voltage  = control.properties.voltage
        break;
      case EKind.MS_CABLEHEAD:
        voltage  = control.properties.voltage
        break;
      case EKind.MS_CT:
        voltage  = control.properties.voltage
        break;
      case EKind.MS_ZCT:
        voltage  = control.properties.voltage
        break;
      case EKind.MS_VT:
        voltage  = control.properties.voltage
        break;
      case EKind.MS_VCT:
        voltage  = control.properties.voltage
        break;
      case EKind.MS_INV:
        voltage  = control.properties.voltage
        break;
      case EKind.MS_RELAY:
        voltage  = control.properties.voltage
        break;
      case EKind.MS_LIGHTBOARD:
        voltage  = control.properties.voltage
        break;
      case EKind.MS_POWERBOARD:
        voltage  = control.properties.voltage
        break;
      case EKind.MS_INTO_POINT:
        voltage  = control.properties.voltage
        break;
    }
    return voltage
  }

  export const isSPhaseCircuitEle = (control: ControlModel, voltSide: number, upTransKind:number|undefined = undefined) => {
    let sPhase = false
    if(upTransKind !== undefined){
      upTransKind = 0
    }
    switch(control.type){
      case EKind.MS_SOURCE:
        break;
      case EKind.MS_GENERATOR:
        break;
      case EKind.MS_TRANS1:
        if(voltSide === VOLT_SIDE_SECONDARY){
          sPhase = true
          if(upTransKind !== undefined){
            upTransKind = EKind.MS_ELEMENT_ALL1.MS_TRANS1
          }
        }
        break;
      case EKind.MS_TRANS3:
        break;
      case EKind.MS_TRANSCENTER:
        sPhase = control.properties.sPhase
        if(upTransKind !== undefined){
          upTransKind = control.properties.upTransKind
        }
        break;
      case EKind.MS_3WINDING:
        break;
      case EKind.MS_TRANSSCOTT:
        if(voltSide === VOLT_SIDE_SECONDARY){
            sPhase = true;
            if(upTransKind !== undefined){
              upTransKind = EKind.MS_ELEMENT_ALL1.MS_TRANSSCOTT
            }
        }
        break;
      case EKind.MS_IMPEDANCE:
        sPhase = control.properties.sPhase
        if(upTransKind !== undefined){
          upTransKind = control.properties.upTransKind
        }
        break;
      case EKind.MS_BUSDUCT:
        sPhase = control.properties.sPhase
        if(upTransKind !== undefined){
          upTransKind = control.properties.upTransKind
        }
        break;
      case EKind.MS_BUSBAR:
        sPhase = control.properties.sPhase
        if(upTransKind !== undefined){
          upTransKind = control.properties.upTransKind
        }
        break;
      case EKind.MS_WIRE:
        sPhase = control.properties.sPhase
        if(upTransKind !== undefined){
          upTransKind = control.properties.upTransKind
        }
        break;
      case EKind.MS_REACTOR:
        break;
      case EKind.MS_HVCB:
        break;
      case EKind.MS_RELAY51:
        break;
      case EKind.MS_MVCB:
        sPhase = control.properties.sPhase
        if(upTransKind !== undefined){
          upTransKind = control.properties.upTransKind
        }
        break;
      case EKind.MS_CONTACTOR:
        sPhase = control.properties.sPhase;
        if(upTransKind !== undefined){
          upTransKind = control.properties.upTransKind
        }
        break;
      case EKind.MS_LVCB:
        sPhase = control.properties.sPhase;
        if(upTransKind !== undefined){
          upTransKind = control.properties.upTransKind
        }
        break;
      case EKind.MS_FUSE:
        break;
      case EKind.MS_2E:
        sPhase = control.properties.sPhase;
        if(upTransKind !== undefined){
          upTransKind = control.properties.upTransKind
        }
        break;
      case EKind.MS_THERMAL:
        sPhase = control.properties.sPhase;
        if(upTransKind !== undefined){
          upTransKind = control.properties.upTransKind
        }
        break;
      case EKind.MS_MOTOR:
        sPhase = control.properties.sPhase;
        if(upTransKind !== undefined){
          upTransKind = control.properties.upTransKind
        }
        break;
      case EKind.MS_MOTOR_GROUP:
        break;
      case EKind.MS_LOAD:
        sPhase = control.properties.sPhase;
        if(upTransKind !== undefined){
          upTransKind = control.properties.upTransKind
        }
        break;
      case EKind.MS_CAPACITOR:
        break;
      case EKind.MS_LOADCENTER:
        sPhase = control.properties.sPhase;
        if(upTransKind !== undefined){
          upTransKind = control.properties.upTransKind
        }
        break;
      case EKind.MS_ZEROCOND:
        sPhase = control.properties.sPhase;
        if(upTransKind !== undefined){
          upTransKind = control.properties.upTransKind
        }
        break;
      case EKind.MS_CONNECT:
        sPhase = control.properties.sPhase;
        if(upTransKind !== undefined){
          upTransKind = control.properties.upTransKind
        }
        break;
      case EKind.MS_TEXT:
        break;
      case EKind.MS_DS:
        break;
      case EKind.MS_LBS:
        break;
      case EKind.MS_THERMAL_CT:
        sPhase = control.properties.sPhase;
        if(upTransKind !== undefined){
          upTransKind = control.properties.upTransKind
        }
        break;
      case EKind.MS_ARRESTOR:
        break;
      case EKind.MS_EARTH:
        break;
      case EKind.MS_CABLEHEAD:
        sPhase = control.properties.sPhase;
        if(upTransKind !== undefined){
          upTransKind = control.properties.upTransKind
        }
        break;
      case EKind.MS_CT:
        sPhase = control.properties.sPhase;
        if(upTransKind !== undefined){
          upTransKind = control.properties.upTransKind
        }
        break;
      case EKind.MS_ZCT:
        break;
      case EKind.MS_VT:
        break;
      case EKind.MS_VCT:
        break;
      case EKind.MS_INV:
        break;
      case EKind.MS_RELAY:
        break;
      case EKind.MS_LIGHTBOARD:
        sPhase = control.properties.sPhase;
        if(upTransKind !== undefined){
          upTransKind = control.properties.upTransKind
        }
        break;
      case EKind.MS_POWERBOARD:
        sPhase = control.properties.sPhase;
        if(upTransKind !== undefined){
          upTransKind = control.properties.upTransKind
        }
        break;
      case EKind.MS_INTO_POINT:
        sPhase = control.properties.sPhase;
        if(upTransKind !== undefined){
          upTransKind = control.properties.upTransKind
        }
        break;
    }
    return { sPhase , upTransKind}

  }
  
  //CObjElement::doGetLoadCurrentStartSide
  export const doGetLoadCurrentStartSide = (control:ControlModel) => {
    let nDirect = 0;
    switch(control.type){
      case EKind.MS_TRANS1:
        nDirect = MS_TOPLEFT;
        break;
      case EKind.MS_TRANS3:
        if (control.properties.currentTopLeft) {
          nDirect = TOP_CENTER;
        } else {
          nDirect = BOTTOM_CENTER;
        }
        break;
      case EKind.MS_TRANSCENTER:
        nDirect = MS_TOPLEFT;
        break;
      case EKind.MS_TRANSSCOTT:
        nDirect = MS_TOPLEFT;
        break;
      case EKind.MS_IMPEDANCE:
      case EKind.MS_BUSDUCT:
      case EKind.MS_BUSBAR:
      case EKind.MS_WIRE:
        if (control.properties.rotate == MS_ROTATE_NORMAL) {
          if (control.properties.currentTopLeft) {
            nDirect = TOP_CENTER;
          } else {
            nDirect = BOTTOM_CENTER;
          }
        } else {
          if (control.properties.currentTopLeft) {
            nDirect = LEFT_CENTER;
          } else {
            nDirect = RIGHT_CENTER;
          }
        }
        break;
      case EKind.MS_REACTOR:
        if (control.properties.currentTopLeft) {
          nDirect = TOP_CENTER;
        } else {
          nDirect = BOTTOM_CENTER;
        }
        break;
      case EKind.MS_INTO_POINT:
        nDirect = TOP_CENTER;
        break;
      default:
        break;
    }
    return nDirect;
  }

  //CObjElement::doGetLoadCurrentEndSide
  export const doGetLoadCurrentEndSide = (control:ControlModel) => {
    let nDirect = 0;
    switch(control.type){
      case EKind.MS_TRANS1:
        nDirect = BOTTOM_CENTER;
        break;
      case EKind.MS_TRANS3:
        if (control.properties.currentTopLeft) {
          nDirect = BOTTOM_CENTER;
        } else {
          nDirect = TOP_CENTER;
        }
        break;
      case EKind.MS_TRANSCENTER:
        nDirect = BOTTOM_CENTER;
        break;
      case EKind.MS_TRANSSCOTT:
        nDirect = BOTTOM_CENTER;
        break;
      case EKind.MS_IMPEDANCE:
      case EKind.MS_BUSDUCT:
      case EKind.MS_BUSBAR:
      case EKind.MS_WIRE:
        if (control.properties.rotate == MS_ROTATE_NORMAL) {
          if (control.properties.currentTopLeft) {
            nDirect = BOTTOM_CENTER;
          } else {
            nDirect = TOP_CENTER;
          }
        } else {
          if (control.properties.currentTopLeft) {
            nDirect = RIGHT_CENTER;
          } else {
            nDirect = LEFT_CENTER;
          }
        }
        break;
      case EKind.MS_REACTOR:
        if (control.properties.currentTopLeft) {
          nDirect = BOTTOM_CENTER;
        } else {
          nDirect = TOP_CENTER;
        }
        break;
      case EKind.MS_INTO_POINT:
        nDirect = BOTTOM_CENTER;
        break;
      default:
        break;
    }
    return nDirect;
  }

  //CObjElement::DoGetTCName
  export const doGetTCName = (control:ControlModel) => {
    let sTCName = '';
    if (control.type == EKind.MS_SOURCE ||
       control.type == EKind.MS_GENERATOR ||
       control.type == EKind.MS_MOTOR_GROUP ||
       control.type == EKind.MS_TRANSCENTER ||
       control.type == EKind.MS_VCT ||
       control.type == EKind.MS_ARRESTOR ||
       control.type == EKind.MS_EARTH 
        ) {
      return sTCName;
    }
    return control.properties.tcName === null ? '' : control.properties.tcName;
  }

  //CObjElement::DoGetSupplyEle
  export const doGetSupplyEle = (control:ControlModel) => {
    let bSupply = false;
    if (control.type == EKind.MS_SOURCE ||
      control.type == EKind.MS_GENERATOR)
    {
      bSupply = true;
    } else if (control.type == EKind.MS_TRANS3 ||
      control.type == EKind.MS_TRANSCENTER ||
      control.type == EKind.MS_3WINDING ||
      control.type == EKind.MS_TRANS1 ||
      control.type == EKind.MS_TRANSSCOTT ||
      control.type == EKind.MS_DS ||
      control.type == EKind.MS_LBS ||
      control.type == EKind.MS_MVCB ||
      control.type == EKind.MS_CONTACTOR ||
      control.type == EKind.MS_HVCB ||
      control.type == EKind.MS_RELAY51 ||
      control.type == EKind.MS_FUSE ||
      control.type == EKind.MS_2E ||
      control.type == EKind.MS_ZEROCOND ||
      control.type == EKind.MS_CONNECT || 
      control.type == EKind.MS_WIRE ||
      control.type == EKind.MS_BUSBAR ||
      control.type == EKind.MS_IMPEDANCE ||
      control.type == EKind.MS_BUSDUCT ||
      control.type == EKind.MS_REACTOR ||
      control.type == EKind.MS_LVCB ||
      control.type == EKind.MS_THERMAL ||
      control.type == EKind.MS_THERMAL_CT ||
      control.type == EKind.MS_MOTOR_GROUP ||
      control.type == EKind.MS_MOTOR ||
      control.type == EKind.MS_LOAD ||
      control.type == EKind.MS_CAPACITOR ||
      control.type == EKind.MS_LOADCENTER ||
      control.type == EKind.MS_VCT ||
      control.type == EKind.MS_INV ||
      control.type == EKind.MS_RELAY ||
      control.type == EKind.MS_ARRESTOR ||
      control.type == EKind.MS_EARTH ||
      control.type == EKind.MS_VT ||
      control.type == EKind.MS_CABLEHEAD ||
      control.type == EKind.MS_CT ||
      control.type == EKind.MS_ZCT ||
      control.type == EKind.MS_LIGHTBOARD ||
      control.type == EKind.MS_POWERBOARD ||
      control.type == EKind.MS_INTO_POINT) {
      bSupply = control.properties.supply;
    }
    return bSupply;
  }

  //CObjElement::DoGetTCObjElementByElement
  export const doGetTCObjElementByElement = (control:ControlModel) => {

    let listAllElement =[...(store.getState().app.diagram.diagramData.filter((e: any) => e.tabId === 1)[0]).shape];

    let tcControlList = [...listAllElement.filter((e: any) => e.type === EKind.MS_TRANSCENTER && e.properties.refNo == doGetTCName(control))];
    for(let tcControl of tcControlList)
    {
      let listTCAllElement = [...(store.getState().app.diagram.diagramData.filter((e: any) => e.tabId === tcControl.properties.tabId)[0]).shape];
      
     for(let tcAllElement of listTCAllElement)
     {
      if(tcAllElement.type == control.type && tcAllElement.id == control.id)
      {
        return tcControl;
      }
     }
    }
    return null;
  }

  //CObjElement::IsCennectSourceDropElement
  export const isCennectSourceDropElement = (control:ControlModel, nDirect:any) => {

    if (doGetTCName(control) == "") {
      let listAllElement = (store.getState().app.diagram.diagramData.filter((e: any) => e.tabId === 1)[0]).shape;
      return isListCennectSourceDropElement(listAllElement, control, nDirect)
    } else {
      let pObjTransCenter = doGetTCObjElementByElement(control);
      if (pObjTransCenter && doGetSupplyEle(pObjTransCenter)) {
        let pTransCenter = pObjTransCenter.properties as MSTransCenterModel;
        let listTCAllElement = (store.getState().app.diagram.diagramData.filter((e: any) => e.tabId === pTransCenter.tabId)[0]).shape;
        return isListCennectSourceDropElement(listTCAllElement, control, nDirect)
      }
    }
    return false;
  }

  //CObjElement::IsCennectLoadDropElement
  export const isCennectLoadDropElement = (control:ControlModel, nDirect:any) => {
    if (!doGetSupplyEle(control)) {
      return false;
    }

    if (doGetTCName(control) == "") {
      let listAllElement = (store.getState().app.diagram.diagramData.filter((e: any) => e.tabId === 1)[0]).shape;
      return isListCennectLoadDropElement(listAllElement, control, nDirect)
    } else {
      let pObjTransCenter = doGetTCObjElementByElement(control);
      if (pObjTransCenter && doGetSupplyEle(pObjTransCenter)) {
        let pTransCenter = pObjTransCenter.properties as MSTransCenterModel;
        let listTCAllElement = (store.getState().app.diagram.diagramData.filter((e: any) => e.tabId === pTransCenter.tabId)[0]).shape;
        return isListCennectLoadDropElement(listTCAllElement, control, nDirect)
      }
    }

    return false;
  }

  //CObjElement::IsCanSwitchOnOff
  export const isCanSwitchOnOff = (control: ControlModel) => {
    const canSwitchOnOffArray = [
        EKind.MS_HVCB,
        EKind.MS_MVCB,
        EKind.MS_CONTACTOR,
        EKind.MS_LVCB,
        EKind.MS_FUSE,
        EKind.MS_2E,
        EKind.MS_THERMAL,
        EKind.MS_DS,
        EKind.MS_LBS,
        EKind.MS_THERMAL_CT
    ]
  
    if (canSwitchOnOffArray.includes(control.type)){
      return true;
    }
    return false;
  }

  //CObjElement::IsSwitchOff
  export const isSwitchOff = (control: ControlModel) => {
    const canSwitchOnOffArray = [
        EKind.MS_HVCB,
        EKind.MS_MVCB,
        EKind.MS_CONTACTOR,
        EKind.MS_LVCB,
        EKind.MS_FUSE,
        EKind.MS_2E,
        EKind.MS_THERMAL,
        EKind.MS_DS,
        EKind.MS_LBS,
        EKind.MS_THERMAL_CT
    ]

    let isOff = false
    if (canSwitchOnOffArray.includes(control.type))
        isOff = control.properties.isOff

    return isOff
  }

  //CObjElement::IsHaveConnectSource
  export const isHaveConnectSource = (pStdElement: ControlModel,pListElement: ControlModel[]) => {
    if (pStdElement.type == EKind.MS_SOURCE ||
      pStdElement.type == EKind.MS_GENERATOR ||
      pStdElement.type == EKind.MS_INTO_POINT) {
      return true;
    }

    let listCopyAllElement = [...pListElement];

    for (;;) {
      let findResult = getTopControl(pStdElement, listCopyAllElement);
      if (findResult.code != 0 ) {
          break;
      }
      let pTop = findResult.data as ControlModel;
      for (;;) {
        if (pTop.type == EKind.MS_SOURCE ||
          pTop.type == EKind.MS_GENERATOR ||
          pTop.type == EKind.MS_INTO_POINT) {
            return true;
        }

        if (pTop.type == EKind.MS_ZEROCOND) {
          if (doFindSourceFromZero(listCopyAllElement, pTop) != null) {
            return true;
          }
          break; 
        }
        
        if (isCanSwitchOnOff(pTop) && isSwitchOff(pTop)) {
          break;
        }
        let findResult = getTopControl(pTop, listCopyAllElement);
        if (findResult.code != 0 ) {
          break;
        }
        pTop = findResult.data as ControlModel;
      }
    }

    for (;;) {
      let findResult = getBottomControl(pStdElement, listCopyAllElement);
      if (findResult.code != 0 ) {
          break;
      }
      let pBottom = findResult.data as ControlModel;
      for (;;) {
        if (pBottom.type == EKind.MS_MOTOR ||
          pBottom.type == EKind.MS_MOTOR_GROUP ||
          pBottom.type == EKind.MS_CAPACITOR ||
          pBottom.type == EKind.MS_LOAD ||
          pBottom.type == EKind.MS_LIGHTBOARD ||
          pBottom.type == EKind.MS_POWERBOARD ||
          pBottom.type == EKind.MS_LOADCENTER) {
          break;
        }
        
        if (pBottom.type == EKind.MS_ZEROCOND ||
          pBottom.type == EKind.MS_3WINDING) {
          if (doFindSourceFromZero(listCopyAllElement, pBottom) != null) {
              return true;
          }
          break; 
        }

        if (pBottom.type == EKind.MS_ARRESTOR ||
          pBottom.type == EKind.MS_EARTH) {
            break;
        }

        if (isCanSwitchOnOff(pBottom) && isSwitchOff(pBottom)) {
          break;
        }

        findResult = getBottomControl(pBottom, listCopyAllElement);
        if (findResult.code != 0 ) {
            break;
        }
        pBottom = findResult.data as ControlModel;
      }
    }

    let pLeft = pStdElement;
    for (;;) {
        let findResult = getLeftControl(pLeft, listCopyAllElement);
        if (findResult.code != 0 ) {
            break;
        }
        pLeft = findResult.data as ControlModel;

        if (pLeft.type == EKind.MS_ZEROCOND ||
          pLeft.type == EKind.MS_3WINDING) {
          if (doFindSourceFromZero(listCopyAllElement, pLeft) != null) {
              return true;
          }
          break; 
        }

        if (isCanSwitchOnOff(pLeft) && isSwitchOff(pLeft)) {
            break;
        }
        
    }

    let pRight = pStdElement;
    for (;;) {
      let findResult = getRightControl(pRight, listCopyAllElement);
      if (findResult.code != 0 ) {
          break;
      }
      pRight = findResult.data as ControlModel;

      if (pRight.type == EKind.MS_ZEROCOND ||
        pRight.type == EKind.MS_3WINDING) {
          if (doFindSourceFromZero(listCopyAllElement, pLeft) != null) {
            return true;
        }
        break; 
      }

      if (isCanSwitchOnOff(pRight) && isSwitchOff(pRight)) {
          break;
      }
    }

    return false;
  }

  //CObjElement::IsConnnectLoadOnZeroCond3Winding
  export const isConnnectLoadOnZeroCond3Winding = (pStdElement: ControlModel,pListElement: ControlModel[]) => {
    if (pStdElement.type == EKind.MS_MOTOR ||
      pStdElement.type == EKind.MS_MOTOR_GROUP ||
      pStdElement.type == EKind.MS_CAPACITOR ||
      pStdElement.type == EKind.MS_LOAD ||
      pStdElement.type == EKind.MS_LIGHTBOARD ||
      pStdElement.type == EKind.MS_POWERBOARD ||
      pStdElement.type == EKind.MS_LOADCENTER) {
      return true;
    }

    let listCopyAllElement = [...pListElement];

    for (;;) {
      let findResult = getTopControl(pStdElement, listCopyAllElement);
      if (findResult.code != 0 ) {
          break;
      }
      let pTop = findResult.data as ControlModel;
      for (;;) {
        if (pTop.type == EKind.MS_SOURCE ||
          pTop.type == EKind.MS_GENERATOR ||
          pTop.type == EKind.MS_INTO_POINT) {
            break;
        }
        if (pTop.type == EKind.MS_ARRESTOR ||
          pTop.type == EKind.MS_EARTH) {
            break;
        }
        if (pTop.type == EKind.MS_ZEROCOND) {
          if (isConnnectLoadOnZeroCond3Winding(pTop, listCopyAllElement)) {
            return true;
          }
          break; 
        }
        if (isCanSwitchOnOff(pTop) && isSwitchOff(pTop)) {
          break;
        }
        let findResult = getTopControl(pTop, listCopyAllElement);
        if (findResult.code != 0 ) {
          break;
        }
        pTop = findResult.data as ControlModel;
      }
    }

    for (;;) {
      let findResult = getBottomControl(pStdElement, listCopyAllElement);
      if (findResult.code != 0 ) {
          break;
      }
      let pBottom = findResult.data as ControlModel;
      for (;;) {
        if (pBottom.type == EKind.MS_MOTOR ||
          pBottom.type == EKind.MS_MOTOR_GROUP ||
          pBottom.type == EKind.MS_CAPACITOR ||
          pBottom.type == EKind.MS_LOAD ||
          pBottom.type == EKind.MS_LIGHTBOARD ||
          pBottom.type == EKind.MS_POWERBOARD ||
          pBottom.type == EKind.MS_LOADCENTER) {
          return true;
        }
        
        if (pBottom.type == EKind.MS_ZEROCOND ||
          pBottom.type == EKind.MS_3WINDING) {
          if (isConnnectLoadOnZeroCond3Winding(pBottom, listCopyAllElement)) {
              return true;
          }
          break; 
        }

        if (pBottom.type == EKind.MS_TRANSCENTER) {
          let pTransCenter = pBottom.properties as MSTransCenterModel;
          let listTCElement = (store.getState().app.diagram.diagramData.filter((e: any) => e.tabId === pTransCenter.tabId)[0]).shape;
          let pTCStdElement = doGetTransCenterEnterElement(listTCElement);
          if (pTCStdElement && isListCennectLoadDropElement(listTCElement, pTCStdElement, BOTTOM_CENTER)) {
              return true;
          }
          break;
        }

        if (pBottom.type == EKind.MS_ARRESTOR ||
          pBottom.type == EKind.MS_EARTH) {
            break;
        }

        if (isCanSwitchOnOff(pBottom) && isSwitchOff(pBottom)) {
          break;
        }
        findResult = getBottomControl(pBottom, listCopyAllElement);
        if (findResult.code != 0 ) {
            break;
        }
        pBottom = findResult.data as ControlModel;
      }
    }

    let pLeft = pStdElement;
    for (;;) {
        let findResult = getLeftControl(pLeft, listCopyAllElement);
        if (findResult.code != 0 ) {
            break;
        }
        pLeft = findResult.data as ControlModel;

        if (pLeft.type == EKind.MS_ZEROCOND ||
          pLeft.type == EKind.MS_3WINDING) {
          if(isConnnectLoadOnZeroCond3Winding(pLeft, listCopyAllElement))
          {
            return true;
          }
          break;
        }

        if (isCanSwitchOnOff(pLeft) && isSwitchOff(pLeft)) {
            break;
        }
        
    }

    let pRight = pStdElement;
    for (;;) {
      let findResult = getRightControl(pRight, listCopyAllElement);
      if (findResult.code != 0 ) {
          break;
      }
      pRight = findResult.data as ControlModel;

      if (pRight.type == EKind.MS_ZEROCOND ||
        pRight.type == EKind.MS_3WINDING) {
        if(isConnnectLoadOnZeroCond3Winding(pRight, listCopyAllElement)) {
          return true;
        }
        break;
      }

      if (isCanSwitchOnOff(pRight) && isSwitchOff(pRight)) {
          break;
      }
    }
    return false;
  }

  export const doGetRotating = (control: ControlModel) => {
    let nRotating = 0;

    switch(control.type) {
    case EKind.MS_SOURCE:			
      break;
    case EKind.MS_GENERATOR:	
      break;
    case EKind.MS_TRANS1:			
      break;
    case EKind.MS_TRANS3:			
      break;
    case EKind.MS_TRANSCENTER:	
      break;
    case EKind.MS_3WINDING:		
      break;
    case EKind.MS_TRANSSCOTT:		
      break;
    case EKind.MS_IMPEDANCE:	
      nRotating = control.rotation;
      break;
    case EKind.MS_BUSDUCT:		
      nRotating = control.rotation;
      break;
    case EKind.MS_BUSBAR:		
      nRotating = control.rotation;
      break;
    case EKind.MS_WIRE:		
      nRotating = control.rotation;
      break;
    case EKind.MS_REACTOR:		
      nRotating = control.rotation;
      break;
    case EKind.MS_HVCB:			
      nRotating = control.rotation;
      break;
    case EKind.MS_RELAY51:		
      break;
    case EKind.MS_MVCB:			
      nRotating = control.rotation;
      break;
    case EKind.MS_CONTACTOR:		
      nRotating = control.rotation;
      break;
    case EKind.MS_LVCB:			
      nRotating = control.rotation;
      break;
    case EKind.MS_FUSE:			
      nRotating = control.rotation;
      break;
    case EKind.MS_2E:				
      break;
    case EKind.MS_THERMAL:		
      break;
    case EKind.MS_MOTOR:			
      break;
    case EKind.MS_MOTOR_GROUP:	
      break;
    case EKind.MS_LOAD:			
      break;
    case EKind.MS_CAPACITOR:		
      break;
    case EKind.MS_LOADCENTER:		
      break;
    case EKind.MS_ZEROCOND:		
      break;
    case EKind.MS_CONNECT:		
      nRotating = control.rotation;
      break;
    case EKind.MS_TEXT:			
      break;
    case EKind.MS_DS:				
      nRotating = control.rotation;
      break;
    case EKind.MS_LBS:			
      nRotating = control.rotation;
      break;
    case EKind.MS_THERMAL_CT:		
      break;
    case EKind.MS_ARRESTOR:		
      break;
    case EKind.MS_EARTH:			
      break;
    case EKind.MS_CABLEHEAD:		
      nRotating = control.rotation;
      break;
    case EKind.MS_CT:				
      break;
    case EKind.MS_ZCT:			
      break;
    case EKind.MS_VT:				
      break;
    case EKind.MS_VCT:			
      break;
    case EKind.MS_INV:			
      break;
    case EKind.MS_RELAY:			
      nRotating = control.rotation;
      break;
    case EKind.MS_LIGHTBOARD:		
      break;
    case EKind.MS_POWERBOARD:		
      break;
    case EKind.MS_INTO_POINT:		
      break;
    }
    return nRotating;
  }

  //CObjElement::DoGetTransCenterObjectPointer
export const doGetTransCenterObjectPointer = (pStdElement : ControlModel) =>
  {
    let listAllElement =[...(store.getState().app.diagram.diagramData.filter((e: any) => e.tabId === 1)[0]).shape];
    if(listAllElement.length <= 0) {
      return null;
    }
  
    let pStdEleIntoPoint = pStdElement.properties;

    let pTransCenter = [...listAllElement.filter((e: any) => e.type === EKind.MS_TRANSCENTER)];
    for(let i = 0; i < pTransCenter.length; i++)
    {
      let listTCAllElement = [...(store.getState().app.diagram.diagramData.filter((e: any) => e.tabId === pTransCenter[i].properties.tabId)[0]).shape];
  
      let pIntoPoint = doGetTransCenterIntoPointPointer(listTCAllElement);
      if (pIntoPoint != null && pIntoPoint.noIntoPoint == pStdEleIntoPoint.noIntoPoint) {
        return pTransCenter[i];
      }
    }
    return null;
  }

  //CObjElement::DoGetRefNoSkeleton
export const doGetRefNoSkeleton = (props:ControlModel, bIsViewTCFlow:boolean = true) =>
{
	let sName = '';
	switch(props.type) {
	case EKind.MS_SOURCE:			
		sName = props.properties.refNo;
		break;
	case EKind.MS_GENERATOR:		
		sName = props.properties.refNo;
		break;
	case EKind.MS_TRANS1:			
		sName = props.properties.refNo;
		break;
	case EKind.MS_TRANS3:			
		sName = props.properties.refNo;
		break;
	case EKind.MS_TRANSCENTER:	
		sName = props.properties.refNo;
		break;
	case EKind.MS_3WINDING:		
		sName = props.properties.refNo;
		break;
	case EKind.MS_TRANSSCOTT:	
		sName = props.properties.refNo;
		break;
	case EKind.MS_IMPEDANCE:		
		sName = props.properties.refNo;
		break;
	case EKind.MS_BUSDUCT:		
		sName = props.properties.refNo;
		break;
	case EKind.MS_BUSBAR:			
		sName = props.properties.refNo;
		break;
	case EKind.MS_WIRE:		
		sName = props.properties.refNo;
		break;
	case EKind.MS_REACTOR:	
		sName = props.properties.refNo;
		break;
	case EKind.MS_HVCB:			
		sName = props.properties.refNo;
		break;
	case EKind.MS_RELAY51:	
		sName = props.properties.refNo;
		break;
	case EKind.MS_MVCB:			
		sName = props.properties.refNo;
		break;
	case EKind.MS_CONTACTOR:		
		sName = props.properties.refNo;
		break;
	case EKind.MS_LVCB:			
		sName = props.properties.refNo;
		break;
	case EKind.MS_FUSE:			
		sName = props.properties.refNo;
		break;
	case EKind.MS_2E:				
		sName = props.properties.refNo;
		break;
	case EKind.MS_THERMAL:		
		sName = props.properties.refNo;
		break;
	case EKind.MS_MOTOR:			
		sName = props.properties.refNo;
		break;
	case EKind.MS_MOTOR_GROUP:	
		sName = props.properties.refNo;
		break;
	case EKind.MS_LOAD:			
		sName = props.properties.refNo;
		break;
	case EKind.MS_CAPACITOR:		
		sName = props.properties.refNo;
		break;
	case EKind.MS_LOADCENTER:	
		sName = props.properties.refNo;
		break;
	case EKind.MS_ZEROCOND:		
		sName = props.properties.refNo;
		break;
	case EKind.MS_CONNECT:		
		break;
	case EKind.MS_TEXT:			
		sName = props.properties.refNo;
		break;
	case EKind.MS_DS:				
		sName = props.properties.refNo;
		break;
	case EKind.MS_LBS:			
		sName = props.properties.refNo;
		break;
	case EKind.MS_THERMAL_CT:		
		sName = props.properties.refNo;
		break;
	case EKind.MS_ARRESTOR:		
		sName = props.properties.refNo;
		break;
	case EKind.MS_EARTH:			
		sName = props.properties.refNo;
		break;
	case EKind.MS_CABLEHEAD:		
		sName = props.properties.refNo;
		break;
	case EKind.MS_CT:				
		sName = props.properties.refNo;
		break;
	case EKind.MS_ZCT:			
		sName = props.properties.refNo;
		break;
	case EKind.MS_VT:				
		sName = props.properties.refNo;
		break;
	case EKind.MS_VCT:			
		sName = props.properties.refNo;
		break;
	case EKind.MS_INV:			
		sName = props.properties.refNo;
		break;
	case EKind.MS_RELAY:			
		sName = props.properties.refNo;
		break;
	case EKind.MS_LIGHTBOARD:		
		sName = props.properties.refNo;
		break;
	case EKind.MS_POWERBOARD:		
		sName = props.properties.refNo;
		break;
	case EKind.MS_INTO_POINT:		
		if (!bIsViewTCFlow) {
			sName = props.properties.refNo;
		}
		else {
			let temp =  props.properties as MSIntoPointModel;
      sName = temp.topEleRefNo
		}
		break;
	}
	return sName;
}

//CObjElement::IsPointFaultAND
// export const isPointFaultAND = (props:ControlModel) =>
// {
// 	let bFault = false;

// 	switch(props.type) {
// 	case EKind.MS_SOURCE:		
// 		bFault = isPointFaultSource(props.properties as MsSourceModel);
// 		break;
// 	case EKind.MS_GENERATOR:	
// 		bFault = isPointFaultGenerator(props.properties as MsGeneratorModel);
// 		break;
// 	case EKind.MS_TRANS1:		
// 		bFault = isPointFaultANDTrans1(props.properties as MsTrans1Model);
// 		break;
// 	case EKind.MS_TRANS3:			
// 		bFault = isPointFaultTrans3(props.properties as MsTrans3Model);
// 		break;
// 	case EKind.MS_TRANSCENTER:	
// 		bFault = isPointFaultTransCenter(props.properties as MSTransCenterModel);
// 		break;
// 	case EKind.MS_3WINDING:		
// 		bFault = isPointFaultAND3Winding(props.properties as Ms3WindingModel);
// 		break;
// 	case EKind.MS_TRANSSCOTT:		
// 		bFault = isPointFaultANDTransScott(props.properties as MsTransScottModel);
// 		break;
// 	case EKind.MS_IMPEDANCE:		
// 		bFault = isPointFaultImpedance(props.properties as MsImpedanceModel);
// 		break;
// 	case EKind.MS_BUSDUCT:		
// 		bFault = isPointFaultBusDuct(props.properties as MsBusductModel);
// 		break;
// 	case EKind.MS_BUSBAR:			
// 		bFault = isPointFaultBusBar(props.properties as MsBusbarModel);
// 		break;
// 	case EKind.MS_WIRE:			
// 		bFault = isPointFaultWire(props.properties as MsWireModel);
// 		break;
// 	case EKind.MS_REACTOR:		
// 		bFault = isPointFaultReactor(props.properties as MsReactorModel);
// 		break;
// 	case EKind.MS_HVCB:			
// 		bFault = isPointFaultHVCB(props.properties as MsHVCBModel);
// 		break;
//     //TODO
// 	// case EKind.MS_RELAY51:	
// 	// 	bFault = m_pRelay51->IsPointFaultRelay51();
// 	// 	break;
// 	case EKind.MS_MVCB:		
// 		bFault = isPointFaultMVCB(props.properties as MsMVCBModel);
// 		break;
// 	case EKind.MS_CONTACTOR:		
// 		bFault = isPointFaultContactor(props.properties as MsContactorModel);
// 		break;
// 	case EKind.MS_LVCB:			
// 		bFault = isPointFaultLVCB(props.properties as MsLVCBModel);
// 		break;
// 	case EKind.MS_FUSE:			
// 		bFault = isPointFaultFuse(props.properties as MsFUSEModel);
// 		break;
// 	case EKind.MS_2E:				
// 		bFault = isPointFault2E(props.properties as Ms2EModel);
// 		break;
// 	case EKind.MS_THERMAL:		
// 		bFault = isPointFaultThermal(props.properties as MsThermalModel);
// 		break;
// 	case EKind.MS_MOTOR:			
// 		break;
// 	case EKind.MS_MOTOR_GROUP:	
// 		bFault = isPointFaultMotorGrp(props.properties as MsMotorGroupModel);
// 		break;
// 	case EKind.MS_LOAD:			
// 		break;
// 	case EKind.MS_CAPACITOR:		
// 		break;
// 	case EKind.MS_LOADCENTER:		
// 		break;
// 	case EKind.MS_ZEROCOND:		
// 		bFault = isPointFaultZeroCond(props.properties as MsZerocondModel);
// 		break;
// 	case EKind.MS_CONNECT:		
// 		break;
// 	case EKind.MS_TEXT:			
// 		break;
// 	case EKind.MS_DS:				
// 		bFault = isPointFaultDS(props.properties as MsDSModel);
// 		break;
// 	case EKind.MS_LBS:			
// 		bFault = isPointFaultLBS(props.properties as MsLBSModel);
// 		break;
// 	case EKind.MS_THERMAL_CT:		
// 		bFault = isPointFaultThermalCT(props.properties as MsThermalCTModel);
// 		break;
// 	case EKind.MS_ARRESTOR:		
// 		break;
// 	case EKind.MS_EARTH:			
// 		break;
// 	case EKind.MS_CABLEHEAD:		
// 		break;
// 	case EKind.MS_CT:				
// 		break;
// 	case EKind.MS_ZCT:			
// 		break;
// 	case EKind.MS_VT:			
// 		break;
// 	case EKind.MS_VCT:			
// 		break;
// 	case EKind.MS_INV:			
// 		break;
// 	case EKind.MS_RELAY:			
// 		break;
// 	case EKind.MS_LIGHTBOARD:		
// 		break;
// 	case EKind.MS_POWERBOARD:	
// 		break;
// 	case EKind.MS_INTO_POINT:		
// 		break;
// 	}
// 	return bFault;
// }

//CObjElement::DoGetTextSidePointTextAndState
export const doGetTextSidePointTextAndState = (props:ControlModel, sPointSide:string) =>
{
  let result = {
    sPointText : '',
    sPointState : '',
    sPointSide : sPointSide
  }

	let sSecondary = '二次側';
	let sThird = '三次側';
	let sOutOut = '外-外';
	let sOutIn = '外-中';
	let sSeatM = '主座';
	let sSeatT = 'T座';

	switch(props.type) {
	case EKind.MS_SOURCE:			
		result.sPointText = props.properties.pointText;
		result.sPointState = doGetTextPointStateSource(props.properties as MsSourceModel);
		break;
	case EKind.MS_GENERATOR:		
		result.sPointText = props.properties.pointText;
		result.sPointState = doGetTextPointStateGenerator(props.properties as MsGeneratorModel);
		break;
	case EKind.MS_TRANS1:			
		if (sPointSide == sOutOut) {
			result.sPointText = props.properties.partOutOut.pointText;
			result.sPointState = doGetTextPointStateTrans1(props.properties.partOutOut as MsTrans1PartModel);
		}
		else if (sPointSide == sOutIn) {
			result.sPointText = props.properties.partOutIn.pointText;
			result.sPointState = doGetTextPointStateTrans1(props.properties.partOutIn as MsTrans1PartModel);
		}
		else {
			result.sPointText = props.properties.partOutOut.pointText;
			result.sPointState = doGetTextPointStateTrans1(props.properties.partOutOut as MsTrans1PartModel);

			let sNextText = props.properties.partOutIn.pointText;
			let nRet = result.sPointText.localeCompare(sNextText);
			if (nRet > 0) {
				result.sPointText = sNextText;
				result.sPointState = doGetTextPointStateTrans1(props.properties.partOutIn as MsTrans1PartModel);
			}
		}
		break;
	case EKind.MS_TRANS3:			
    result.sPointText = props.properties.pointText;
    result.sPointState = doGetTextPointStateTrans3(props.properties as MsTrans3Model);
		break;
	case EKind.MS_TRANSCENTER:	
    result.sPointText = props.properties.pointText;
    result.sPointState = doGetTextPointStateTransCenter(props.properties as MSTransCenterModel);
		break;
	case EKind.MS_3WINDING:		
		if (sPointSide == sSecondary) {
			result.sPointText = props.properties.partSecondary.pointText;
			result.sPointState = doGetTextPointState3Winding(props.properties.partSecondary as Iele3WindingPart);
		}
		else if (sPointSide == sThird) {
			result.sPointText = props.properties.partThird.pointText;
			result.sPointState = doGetTextPointState3Winding(props.properties.partThird as Iele3WindingPart);
		}
		else {
			result.sPointText = props.properties.partSecondary.pointText;
			result.sPointState = doGetTextPointState3Winding(props.properties.partSecondary as Iele3WindingPart);

			let sNextText = props.properties.partThird.pointText;
			let nRet = result.sPointText.localeCompare(sNextText);
			if (nRet > 0) {
				result.sPointText = sNextText;
				result.sPointState = doGetTextPointState3Winding(props.properties.partThird as Iele3WindingPart);
			}
		}
		break;
	case EKind.MS_TRANSSCOTT:		
		if (sPointSide == sSeatM) {
			result.sPointText = props.properties.partSeatM.pointText;
			result.sPointState = doGetTextPointStateTransscott(props.properties.partSeatM as MsTransScottPartModel);
		}
		else if (sPointSide == sSeatT) {
			result.sPointText = props.properties.partSeatT.pointText;
			result.sPointState = doGetTextPointStateTransscott(props.properties.partSeatT as MsTransScottPartModel);
		}
		else {
			result.sPointText = props.properties.partSeatM.pointText;
			result.sPointState = doGetTextPointStateTransscott(props.properties.partSeatM as MsTransScottPartModel);

			let sNextText = props.properties.partSeatT.pointText;
			let nRet = result.sPointText.localeCompare(sNextText);
			if (nRet > 0) {
				result.sPointText = sNextText;
				result.sPointState = doGetTextPointStateTransscott(props.properties.partSeatT as MsTransScottPartModel);
			}
		}
		break;
	case EKind.MS_IMPEDANCE:		
    result.sPointText = props.properties.pointText;
    result.sPointState = doGetTextPointStateImpedance(props.properties as MsImpedanceModel);
		break;
	case EKind.MS_BUSDUCT:		
    result.sPointText = props.properties.pointText;
    result.sPointState = doGetTextPointStateBusduct(props.properties as MsBusductModel);
		break;
	case EKind.MS_BUSBAR:			
    result.sPointText = props.properties.pointText;
    result.sPointState = doGetTextPointStateBusbar(props.properties as MsBusbarModel);
		break;
	case EKind.MS_WIRE:			
    result.sPointText = props.properties.pointText;
    result.sPointState = doGetTextPointStateWire(props.properties as MsWireModel);
		break;
	case EKind.MS_REACTOR:		
    result.sPointText = props.properties.pointText;
    result.sPointState = doGetTextPointStateReactor(props.properties as MsReactorModel);
		break;
	case EKind.MS_HVCB:			
    result.sPointText = props.properties.pointText;
    result.sPointState = doGetTextPointStateHVCB(props.properties as MsHVCBModel);
		break;
    //TODO
	// case EKind.MS_RELAY51:		
  //   result.sPointText = m_pRelay51->m_sPointText;
  //   result.sPointState = m_pRelay51->DoGetTextPointState();
	// 	break;
	case EKind.MS_MVCB:			
    result.sPointText = props.properties.pointText;
    result.sPointState = doGetTextPointStateMVCB(props.properties as MsMVCBModel);
		break;
	case EKind.MS_CONTACTOR:		
    result.sPointText = props.properties.pointText;
    result.sPointState = doGetTextPointStateContactor(props.properties as MsContactorModel);
		break;
	case EKind.MS_LVCB:			
    result.sPointText = props.properties.pointText;
    result.sPointState = doGetTextPointStateLVCB(props.properties as MsLVCBModel);
		break;
	case EKind.MS_FUSE:		
    result.sPointText = props.properties.pointText;
		result.sPointState = DoGetTextPointStateFuse(props.properties as MsFUSEModel);
		break;
	case EKind.MS_2E:				
    result.sPointText = props.properties.pointText;
    result.sPointState = doGetTextPointState2E(props.properties as Ms2EModel);
		break;
	case EKind.MS_THERMAL:		
    result.sPointText = props.properties.pointText;
    result.sPointState = doGetTextPointStateThermal(props.properties as MsThermalModel);
		break;
	case EKind.MS_MOTOR:			
		break;
	case EKind.MS_MOTOR_GROUP:	
    result.sPointText = props.properties.pointText;
    result.sPointState = doGetTextPointStateMotorGrp(props.properties as MsMotorGroupModel);
		break;
	case EKind.MS_LOAD:			
		break;
	case EKind.MS_CAPACITOR:		
		break;
	case EKind.MS_LOADCENTER:		
		break;
	case EKind.MS_ZEROCOND:		
    result.sPointText = props.properties.pointText;
    result.sPointState = doGetTextPointStateZeroCond(props.properties as MsZerocondModel);
		break;
	case EKind.MS_CONNECT:		
		break;
	case EKind.MS_TEXT:			
		break;
	case EKind.MS_DS:				
    result.sPointText = props.properties.pointText;
    result.sPointState = doGetTextPointStateDS(props.properties as MsDSModel);
		break;
	case EKind.MS_LBS:			
    result.sPointText = props.properties.pointText;
    result.sPointState = doGetTextPointStateLBS(props.properties as MsLBSModel);
		break;
	case EKind.MS_THERMAL_CT:		
    result.sPointText = props.properties.pointText;
    result.sPointState = doGetTextPointStateThermalCT(props.properties as MsThermalCTModel);
		break;
	case EKind.MS_ARRESTOR:		
		break;
	case EKind.MS_EARTH:			
		break;
	case EKind.MS_CABLEHEAD:		
		break;
	case EKind.MS_CT:				
		break;
	case EKind.MS_ZCT:			
		break;
	case EKind.MS_VT:				
		break;
	case EKind.MS_VCT:			
		break;
	case EKind.MS_INV:			
		break;
	case EKind.MS_RELAY:			
		break;
	case EKind.MS_LIGHTBOARD:		
		break;
	case EKind.MS_POWERBOARD:		
		break;
	}

  return result;
}

export const isViewFlowResultEle = (element:ControlModel) => {
  	let viewFlowResult  = false;
	if(!element)
		return viewFlowResult;
	if(element.type === EKind.MS_ZEROCOND){
		if(element.properties["refNo"].length !== 0){
			viewFlowResult = element.properties["viewFlowResult"];
		}
	}else if("viewFlowResult" in element.properties){
		viewFlowResult = element.properties["viewFlowResult"];
	}
	return viewFlowResult;
}

export const isCalcImprovePF = (element:ControlModel) => {
  	let bRet = false;

	if(!element)
		return bRet;

  	switch (element.type) {
		case EKind.MS_SOURCE:
		case EKind.MS_MOTOR:
		case EKind.MS_MOTOR_GROUP:
		case EKind.MS_LOAD:
		case EKind.MS_LOADCENTER:
			bRet = element.properties["calcImprovePF"]
			break;
		case EKind.MS_ZEROCOND:
			if (element && element.properties["refNo"].length !== "" ) {
				bRet = element.properties["calcImprovePF"]
			}
			break;
	}
	
  	return bRet;
}

export const isCanSetImprovePF = (element:ControlModel) => {
  	let bRet = false;

	if(!element)
		return bRet;	

	switch (element.type) {
		case EKind.MS_SOURCE:
		case EKind.MS_MOTOR:
		case EKind.MS_MOTOR_GROUP:
		case EKind.MS_LOAD:
		case EKind.MS_LOADCENTER:
			bRet = true;
			break;
		case EKind.MS_ZEROCOND:
			if(element.properties["refNo"] !== ""){
				bRet = true;
				break
			}
			break;
		default:
			break;
	}

	return bRet;
}

export const isCanSetPointFault = (element:ControlModel) => {
	let bret = false;

	if(!element)
		return bret;

	if (element.type === EKind.MS_ZEROCOND) {
		if (element.properties["refNo"] === "") {
			// empty
		} else 
			bret = true;
	} else if (
		element.type === EKind.MS_SOURCE ||
		element.type === EKind.MS_GENERATOR ||
		element.type === EKind.MS_TRANS1 ||
		element.type === EKind.MS_TRANS3 ||
		element.type === EKind.MS_TRANSCENTER ||
		element.type === EKind.MS_3WINDING ||
		element.type === EKind.MS_TRANSSCOTT ||
		element.type === EKind.MS_IMPEDANCE ||
		element.type === EKind.MS_BUSDUCT ||
		element.type === EKind.MS_BUSBAR ||
		element.type === EKind.MS_WIRE ||
		element.type === EKind.MS_REACTOR ||
		element.type === EKind.MS_HVCB ||
		element.type === EKind.MS_RELAY51 ||
		element.type === EKind.MS_MVCB ||
		element.type === EKind.MS_CONTACTOR ||
		element.type === EKind.MS_LVCB ||
		element.type === EKind.MS_FUSE ||
		element.type === EKind.MS_2E ||
		element.type === EKind.MS_THERMAL ||
		element.type === EKind.MS_MOTOR_GROUP ||
		element.type === EKind.MS_DS ||
		element.type === EKind.MS_LBS ||
		element.type === EKind.MS_THERMAL_CT
	)
		bret = true;
	
  	return bret;
}

export const isPointFaultAND = (element:ControlModel) => {
	let fault = false;
	if(!element)
		return fault;
	
	if (element.type === EKind.MS_TRANS1) {
		if (element.properties["partOutIn"]["fault"] === true && element.properties["partOutOut"]["fault"] === true) {
			fault = true;
		}
	} else if (element.type === EKind.MS_3WINDING) {
		if (element.properties["partSecondary"]["fault"] === true && element.properties["partThird"]["fault"] === true) {
			fault = true;
		}
	} else if (element.type === EKind.MS_TRANSSCOTT) {
		if (element.properties["partSeatM"]["fault"] === true && element.properties["partSeatT"]["fault"] === true) {
			fault = true;
		}
	} else if (element.type === EKind.MS_ZEROCOND) {
		if (element.properties["refNo"] === "") {
			// empty, do nothing
		} else {
			fault = element.properties["fault"];
		}
	} else if ("fault" in element.properties) {
		fault = element.properties["fault"];
	}

  	return fault;
}

export const isPointFaultOR = (element:ControlModel) => {
	let fault = false;

	if(!element)
		return fault;

	if (element.type === EKind.MS_TRANS1) {
		if (element.properties["partOutIn"]["fault"] === true || element["properties"]["partOutOut"]["fault"] === true) {
			fault = true;
		}
	} else if (element.type === EKind.MS_3WINDING) {
		if (element.properties["partSecondary"]["fault"] === true || element.properties["partThird"]["fault"] === true) {
			fault = true;
		}
	} else if (element.type === EKind.MS_TRANSSCOTT) {
		if (element.properties["partSeatM"]["fault"] === true || element.properties["partSeatT"]["fault"] === true) {
			fault = true;
		}
	} else if (element.type === EKind.MS_ZEROCOND) {
		if (element.properties["refNo"] === "") {
			// empty, do nothing
		} else {
			fault = element.properties["fault"];
		}
	} else if ("fault" in element.properties ) {
		fault = element.properties["fault"];
	}
  	return fault;
}

export const isCanSetPointDrop = (element:ControlModel) => {
  	let bRet = false;
	if(!element)
		return bRet;
	switch (element.type) {
		case EKind.MS_TRANS1:
		case EKind.MS_TRANS3:
		case EKind.MS_3WINDING:
		case EKind.MS_TRANSSCOTT:
		case EKind.MS_IMPEDANCE:
		case EKind.MS_BUSDUCT:
		case EKind.MS_BUSBAR:
		case EKind.MS_WIRE:
		case EKind.MS_REACTOR:
		case EKind.MS_HVCB:
		case EKind.MS_RELAY51:
		case EKind.MS_MVCB:
		case EKind.MS_CONTACTOR:
		case EKind.MS_LVCB:
		case EKind.MS_FUSE:
		case EKind.MS_2E:
		case EKind.MS_THERMAL:
		case EKind.MS_DS:
		case EKind.MS_LBS:
		case EKind.MS_THERMAL_CT:
			bRet = true;
			break;
		case EKind.MS_ZEROCOND:
			if (element.properties["refNo"].length !== 0) {
				bRet = true;
			}
			break;
	}
  	return bRet;
}

export const isPointDropAND = (element:ControlModel) => {
	let point = false;
	if(!element)
		return point;

	const elementKind = element.type;
	const props = element.properties;

	if (elementKind === EKind.MS_TRANS1) {
		// dropRegular
		if (props["partOutOut"]["dropRegular"] === true && props["partOutIn"]["dropRegular"] === true) {
			point = true;
		}
	} else if (elementKind === EKind.MS_TRANS3
		|| elementKind === EKind.MS_IMPEDANCE
		|| elementKind === EKind.MS_BUSDUCT
		|| elementKind === EKind.MS_BUSBAR
		|| elementKind === EKind.MS_WIRE
		|| elementKind === EKind.MS_REACTOR
		|| elementKind === EKind.MS_HVCB
		|| elementKind === EKind.MS_RELAY51
		|| elementKind === EKind.MS_MVCB
		|| elementKind === EKind.MS_CONTACTOR
		|| elementKind === EKind.MS_LVCB
		|| elementKind === EKind.MS_FUSE
		|| elementKind === EKind.MS_2E
		|| elementKind === EKind.MS_THERMAL
		|| elementKind === EKind.MS_DS
		|| elementKind === EKind.MS_LBS
		|| elementKind === EKind.MS_THERMAL_CT) {
		if (props["dropRegular"] === true && props["dropMotor"] === true) {
			point = true;
		}
	} else if (elementKind === EKind.MS_3WINDING) {
		if (props["partSecondary"]["dropRegular"] === true && props["partThird"]["dropRegular"] === true) {
			point = true;
		}
	} else if (elementKind === EKind.MS_TRANSSCOTT) {
		if (props["partSeatT"]["dropRegular"] === true && props["partSeatM"]["dropRegular"] === true) {
			point = true;
		}
	} else if (elementKind === EKind.MS_ZEROCOND) {
		if (props["refNo"] === "" || props["refNo"] === null) {
			// do nothing
		}
		if (props["dropRegular"] === true && props["dropMotor"] === true) {
			point = true;
		}
	}

  	return point;
}

export const isPointDropOR = (element:ControlModel) => {
  let point = false;
  const elementKind = element ? element.type : null;
  const props = element ? element.properties : null;

  if (elementKind === EKind.MS_TRANS1) {
      // dropRegular
      if (props["partOutOut"]["dropRegular"] === true || props["partOutIn"]["dropRegular"] === true) {
          point = true;
      }
  } else if (elementKind === EKind.MS_TRANS3
      || elementKind === EKind.MS_IMPEDANCE
      || elementKind === EKind.MS_BUSDUCT
      || elementKind === EKind.MS_BUSBAR
      || elementKind === EKind.MS_WIRE
      || elementKind === EKind.MS_REACTOR
      || elementKind === EKind.MS_HVCB
      || elementKind === EKind.MS_RELAY51
      || elementKind === EKind.MS_MVCB
      || elementKind === EKind.MS_CONTACTOR
      || elementKind === EKind.MS_LVCB
      || elementKind === EKind.MS_FUSE
      || elementKind === EKind.MS_2E
      || elementKind === EKind.MS_THERMAL
      || elementKind === EKind.MS_DS
      || elementKind === EKind.MS_LBS
      || elementKind === EKind.MS_THERMAL_CT) {
      if (props["dropRegular"] === true || props["dropMotor"] === true) {
          point = true;
      }
  } else if (elementKind === EKind.MS_3WINDING) {
      if (props["partSecondary"]["dropRegular"] === true || props["partThird"]["dropRegular"] === true) {
          point = true;
      }
  } else if (elementKind === EKind.MS_TRANSSCOTT) {
      if (props["partSeatT"]["dropRegular"] === true || props["partSeatM"]["dropRegular"] === true) {
          point = true;
      }
  } else if (elementKind === EKind.MS_ZEROCOND) {
      if (props["refNo"] === "" || props["refNo"] === null) {
          // do nothing
      }
      if (props["dropRegular"] === true || props["dropMotor"] === true) {
          point = true;
      }
  }

  return point;
}

export const isCanSetViewFlowResult = (element:ControlModel) => {
  	let bRet = false;
	if(!element)
		return bRet;
	switch (element.type) {
		case EKind.MS_SOURCE: 
		case EKind.MS_GENERATOR: 
		case EKind.MS_TRANS1: 
		case EKind.MS_TRANS3: 
		case EKind.MS_3WINDING: 
		case EKind.MS_TRANSSCOTT: 
		case EKind.MS_REACTOR: 
		case EKind.MS_HVCB: 
		case EKind.MS_RELAY51: 
		case EKind.MS_FUSE: 
		case EKind.MS_MOTOR_GROUP: 
		case EKind.MS_CAPACITOR: 
		case EKind.MS_DS: 
		case EKind.MS_LBS: 
		case EKind.MS_ZCT:
		case EKind.MS_VT:		
		case EKind.MS_VCT:	
		case EKind.MS_INV:
		case EKind.MS_RELAY:
		case EKind.MS_LIGHTBOARD: 
		case EKind.MS_POWERBOARD:	
			bRet = true;
			break;
		case EKind.MS_TRANSCENTER: 
		case EKind.MS_IMPEDANCE: 
		case EKind.MS_BUSDUCT:
		case EKind.MS_BUSBAR:  
		case EKind.MS_WIRE: 
		case EKind.MS_MVCB: 
		case EKind.MS_CONTACTOR: 
		case EKind.MS_LVCB: 
		case EKind.MS_2E: 
		case EKind.MS_THERMAL: 
		case EKind.MS_MOTOR: 
		case EKind.MS_LOAD: 
		case EKind.MS_THERMAL_CT: 
		case EKind.MS_CT: 
			if (element.properties["sPhase"] != true) {
				bRet = true;
			}
			break;
		case EKind.MS_ZEROCOND: 
			if (element.properties["refNo"].length === 0) {
				break;
			}
			if (element.properties["sPhase"] !== true) {
				bRet = true;
			}
			break;
	}
	return bRet;
}

//CObjElement::DoGetTextDirectPeakCoefficient
export const doGetTextDirectPeakCoefficient = (props: ControlModel, nPartKind:number) =>
{
	let sText = "";

	switch(props.type) {
	case EKind.MS_SOURCE:			// 電源
		sText = doGetTextDirectPeakCoefficientCalcPoint(props.properties.calcPoint0);
		break;
	case EKind.MS_GENERATOR:		// 発電機
		sText = doGetTextDirectPeakCoefficientCalcPoint(props.properties.calcPoint0);
		break;
	case EKind.MS_TRANS1:			// 単相変圧器
		if (nPartKind == MS_OUTSIDE_OUTSIDE) {
			sText = doGetTextDirectPeakCoefficientCalcPoint(props.properties.partOutOut.calcPoint);
		}
		else if (nPartKind == MS_OUTSIDE_NEUTRAL) {
			sText = doGetTextDirectPeakCoefficientCalcPoint(props.properties.partOutIn.calcPoint);
		}
		break;
	case EKind.MS_TRANS3:			// 三相変圧器
		sText = doGetTextDirectPeakCoefficientCalcPoint(props.properties.calcPoint0);
		break;
	case EKind.MS_TRANSCENTER:	// 変台
		switch (nPartKind) {
		case MS_OUTSIDE_OUTSIDE:
		case MS_OUTSIDE_NEUTRAL:
			sText = doGetTextDirectPeakCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		case MS_SEAT_MAIN:
		case MS_SEAT_T:
			sText = doGetTextDirectPeakCoefficientCalcPoint(props.properties.calcPoint1);
			break;
		default:
			sText = doGetTextDirectPeakCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		}
		break;
	case EKind.MS_3WINDING:		// 三巻線変圧器
		if (nPartKind == VOLT_SIDE_PRIMARY) {
			sText = doGetTextDirectPeakCoefficientCalcPoint(props.properties.partPrimary.calcPoint0);
		}
		else if (nPartKind == VOLT_SIDE_SECONDARY) {
			sText = doGetTextDirectPeakCoefficientCalcPoint(props.properties.partSecondary.calcPoint0);
		}
		else if (nPartKind == VOLT_SIDE_THIRD) {
			sText = doGetTextDirectPeakCoefficientCalcPoint(props.properties.partThird.calcPoint0);
		}
		break;
	case EKind.MS_TRANSSCOTT:		// スコット結線変圧器
		if (nPartKind == MS_SEAT_MAIN) {
			sText = doGetTextDirectPeakCoefficientCalcPoint(props.properties.partSeatM.calcPoint);
		}
		else if (nPartKind == MS_SEAT_T) {
			sText = doGetTextDirectPeakCoefficientCalcPoint(props.properties.partSeatT.calcPoint);
		}
		break;
	case EKind.MS_IMPEDANCE:		// インピーダンス
		switch (nPartKind) {
		case MS_OUTSIDE_OUTSIDE:
		case MS_OUTSIDE_NEUTRAL:
			sText = doGetTextDirectPeakCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		case MS_SEAT_MAIN:
		case MS_SEAT_T:
			sText = doGetTextDirectPeakCoefficientCalcPoint(props.properties.calcPoint1);
			break;
		default:
			sText = doGetTextDirectPeakCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		}
		break;
	case EKind.MS_BUSDUCT:		// バスダクト
		switch (nPartKind) {
		case MS_OUTSIDE_OUTSIDE:
		case MS_OUTSIDE_NEUTRAL:
			sText = doGetTextDirectPeakCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		case MS_SEAT_MAIN:
		case MS_SEAT_T:
			sText = doGetTextDirectPeakCoefficientCalcPoint(props.properties.calcPoint1);
			break;
		default:
			sText = doGetTextDirectPeakCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		}
		break;
	case EKind.MS_BUSBAR:			// ブスバー
		switch (nPartKind) {
		case MS_OUTSIDE_OUTSIDE:
		case MS_OUTSIDE_NEUTRAL:
			sText = doGetTextDirectPeakCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		case MS_SEAT_MAIN:
		case MS_SEAT_T:
			sText = doGetTextDirectPeakCoefficientCalcPoint(props.properties.calcPoint1);
			break;
		default:
			sText = doGetTextDirectPeakCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		}
		break;
	case EKind.MS_WIRE:			// ワイヤ
		switch (nPartKind) {
		case MS_OUTSIDE_OUTSIDE:
		case MS_OUTSIDE_NEUTRAL:
			sText = doGetTextDirectPeakCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		case MS_SEAT_MAIN:
		case MS_SEAT_T:
			sText = doGetTextDirectPeakCoefficientCalcPoint(props.properties.calcPoint1);
			break;
		default:
			sText = doGetTextDirectPeakCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		}
		break;
	case EKind.MS_REACTOR:		// 限流リアクトル
		sText = doGetTextDirectPeakCoefficientCalcPoint(props.properties.calcPoint0);
		break;
	case EKind.MS_HVCB:			// 過電流継電器
		sText = doGetTextDirectPeakCoefficientCalcPoint(props.properties.calcPoint0);
		break;
	case EKind.MS_RELAY51:		// 継電器_51
		sText = doGetTextDirectPeakCoefficientCalcPoint(props.properties.calcPoint0);
		break;
	case EKind.MS_MVCB:			// 遮断器
		switch (nPartKind) {
		case MS_OUTSIDE_OUTSIDE:
		case MS_OUTSIDE_NEUTRAL:
			sText = doGetTextDirectPeakCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		case MS_SEAT_MAIN:
		case MS_SEAT_T:
			sText = doGetTextDirectPeakCoefficientCalcPoint(props.properties.calcPoint1);
			break;
		default:
			sText = doGetTextDirectPeakCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		}
		break;
	case EKind.MS_CONTACTOR:		// 電磁接触器
		switch (nPartKind) {
		case MS_OUTSIDE_OUTSIDE:
		case MS_OUTSIDE_NEUTRAL:
			sText = doGetTextDirectPeakCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		case MS_SEAT_MAIN:
		case MS_SEAT_T:
			sText = doGetTextDirectPeakCoefficientCalcPoint(props.properties.calcPoint1);
			break;
		default:
			sText = doGetTextDirectPeakCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		}
		break;
	case EKind.MS_LVCB:			// 低圧遮断器
		switch (nPartKind) {
		case MS_OUTSIDE_OUTSIDE:
		case MS_OUTSIDE_NEUTRAL:
			sText = doGetTextDirectPeakCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		case MS_SEAT_MAIN:
		case MS_SEAT_T:
			sText = doGetTextDirectPeakCoefficientCalcPoint(props.properties.calcPoint1);
			break;
		default:
			sText = doGetTextDirectPeakCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		}
		break;
	case EKind.MS_FUSE:			// ヒューズ
		sText = doGetTextDirectPeakCoefficientCalcPoint(props.properties.calcPoint0);
		break;
	case EKind.MS_2E:				// 電動機保護リレー
		switch (nPartKind) {
		case MS_OUTSIDE_OUTSIDE:
		case MS_OUTSIDE_NEUTRAL:
			sText = doGetTextDirectPeakCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		case MS_SEAT_MAIN:
		case MS_SEAT_T:
			sText = doGetTextDirectPeakCoefficientCalcPoint(props.properties.calcPoint1);
			break;
		default:
			sText = doGetTextDirectPeakCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		}
		break;
	case EKind.MS_THERMAL:		// サーマルリレー
		switch (nPartKind) {
		case MS_OUTSIDE_OUTSIDE:
		case MS_OUTSIDE_NEUTRAL:
			sText = doGetTextDirectPeakCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		case MS_SEAT_MAIN:
		case MS_SEAT_T:
			sText = doGetTextDirectPeakCoefficientCalcPoint(props.properties.calcPoint1);
			break;
		default:
			sText = doGetTextDirectPeakCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		}
		break;
	case EKind.MS_MOTOR:			// 電動機
		break;
	case EKind.MS_MOTOR_GROUP:	// 電動機群
		sText = doGetTextDirectPeakCoefficientCalcPoint(props.properties.calcPoint0);
		break;
	case EKind.MS_LOAD:			// 負荷
		break;
	case EKind.MS_CAPACITOR:		// コンデンサ
		break;
	case EKind.MS_LOADCENTER:		// ロードセンタ
		break;
	case EKind.MS_ZEROCOND:		// 母線
		switch (nPartKind) {
		case MS_OUTSIDE_OUTSIDE:
		case MS_OUTSIDE_NEUTRAL:
			sText = doGetTextDirectPeakCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		case MS_SEAT_MAIN:
		case MS_SEAT_T:
			sText = doGetTextDirectPeakCoefficientCalcPoint(props.properties.calcPoint1);
			break;
		default:
			sText = doGetTextDirectPeakCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		}
		break;
	case EKind.MS_CONNECT:		// 接続線
		break;
	case EKind.MS_TEXT:			// 任意文字列
		break;
	case EKind.MS_DS:				// 断路器
		sText = doGetTextDirectPeakCoefficientCalcPoint(props.properties.calcPoint0);
		break;
	case EKind.MS_LBS:			// 開閉器
		sText = doGetTextDirectPeakCoefficientCalcPoint(props.properties.calcPoint0);
		break;
	case EKind.MS_THERMAL_CT:		// 過負荷継電器
		switch (nPartKind) {
		case MS_OUTSIDE_OUTSIDE:
		case MS_OUTSIDE_NEUTRAL:
			sText = doGetTextDirectPeakCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		case MS_SEAT_MAIN:
		case MS_SEAT_T:
			sText = doGetTextDirectPeakCoefficientCalcPoint(props.properties.calcPoint1);
			break;
		default:
			sText = doGetTextDirectPeakCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		}
		break;
	case EKind.MS_ARRESTOR:		// 避雷器
		break;
	case EKind.MS_EARTH:			// 接地
		break;
	case EKind.MS_CABLEHEAD:		// ケーブルヘッド
		break;
	case EKind.MS_CT:				// 変流器
		break;
	case EKind.MS_ZCT:			// 零相変流器
		break;
	case EKind.MS_VT:				// 計器用変圧器
		break;
	case EKind.MS_VCT:			// 計器用変成器
		break;
	case EKind.MS_INV:			// インバーター
		break;
	case EKind.MS_RELAY:			// リレー
		break;
	case EKind.MS_LIGHTBOARD:		// 分電盤
		break;
	case EKind.MS_POWERBOARD:		// 制御盤
		break;
	case EKind.MS_INTO_POINT:		// 引込口
		break;
	}
	return sText;
}

//CObjElement::DoGetTextDirectAsymCoefficient
export const doGetTextDirectAsymCoefficient = (props:ControlModel, nPartKind: number) =>
{
	let sText = "";

	switch(props.type) {
	case EKind.MS_SOURCE:			// 電源
		sText = doGetTextDirectAsymCoefficientCalcPoint(props.properties.calcPoint0);
		break;
	case EKind.MS_GENERATOR:		// 発電機
		sText = doGetTextDirectAsymCoefficientCalcPoint(props.properties.calcPoint0);
		break;
	case EKind.MS_TRANS1:			// 単相変圧器
		if (nPartKind == MS_OUTSIDE_OUTSIDE) {
			sText = doGetTextDirectAsymCoefficientCalcPoint(props.properties.partOutOut.calcPoint);
		}
		else if (nPartKind == MS_OUTSIDE_NEUTRAL) {
			sText = doGetTextDirectAsymCoefficientCalcPoint(props.properties.partOutIn.calcPoint);
		}
		break;
	case EKind.MS_TRANS3:			// 三相変圧器
		sText = doGetTextDirectAsymCoefficientCalcPoint(props.properties.calcPoint0);
		break;
	case EKind.MS_TRANSCENTER:	// 変台
		switch (nPartKind) {
		case MS_OUTSIDE_OUTSIDE:
		case MS_OUTSIDE_NEUTRAL:
			sText = doGetTextDirectAsymCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		case MS_SEAT_MAIN:
		case MS_SEAT_T:
			sText = doGetTextDirectAsymCoefficientCalcPoint(props.properties.calcPoint1);
			break;
		default:
			sText = doGetTextDirectAsymCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		}
		break;
	case EKind.MS_3WINDING:		// 三巻線変圧器
		if (nPartKind == VOLT_SIDE_PRIMARY) {
			sText = doGetTextDirectAsymCoefficientCalcPoint(props.properties.partPrimary.calcPoint0);
		}
		else if (nPartKind == VOLT_SIDE_SECONDARY) {
			sText = doGetTextDirectAsymCoefficientCalcPoint(props.properties.partSecondary.calcPoint0);
		}
		else if (nPartKind == VOLT_SIDE_THIRD) {
			sText = doGetTextDirectAsymCoefficientCalcPoint(props.properties.partThird.calcPoint0);
		}
		break;
	case EKind.MS_TRANSSCOTT:		// スコット結線変圧器
		if (nPartKind == MS_SEAT_MAIN) {
			sText = doGetTextDirectAsymCoefficientCalcPoint(props.properties.partSeatM.calcPoint);
		}
		else if (nPartKind == MS_SEAT_T) {
			sText = doGetTextDirectAsymCoefficientCalcPoint(props.properties.partSeatT.calcPoint);
		}
		break;
	case EKind.MS_IMPEDANCE:		// インピーダンス
		switch (nPartKind) {
		case MS_OUTSIDE_OUTSIDE:
		case MS_OUTSIDE_NEUTRAL:
			sText = doGetTextDirectAsymCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		case MS_SEAT_MAIN:
		case MS_SEAT_T:
			sText = doGetTextDirectAsymCoefficientCalcPoint(props.properties.calcPoint1);
			break;
		default:
			sText = doGetTextDirectAsymCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		}
		break;
	case EKind.MS_BUSDUCT:		// バスダクト
		switch (nPartKind) {
		case MS_OUTSIDE_OUTSIDE:
		case MS_OUTSIDE_NEUTRAL:
			sText = doGetTextDirectAsymCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		case MS_SEAT_MAIN:
		case MS_SEAT_T:
			sText = doGetTextDirectAsymCoefficientCalcPoint(props.properties.calcPoint1);
			break;
		default:
			sText = doGetTextDirectAsymCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		}
		break;
	case EKind.MS_BUSBAR:			// ブスバー
		switch (nPartKind) {
		case MS_OUTSIDE_OUTSIDE:
		case MS_OUTSIDE_NEUTRAL:
			sText = doGetTextDirectAsymCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		case MS_SEAT_MAIN:
		case MS_SEAT_T:
			sText = doGetTextDirectAsymCoefficientCalcPoint(props.properties.calcPoint1);
			break;
		default:
			sText = doGetTextDirectAsymCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		}
		break;
	case EKind.MS_WIRE:			// ワイヤ
		switch (nPartKind) {
		case MS_OUTSIDE_OUTSIDE:
		case MS_OUTSIDE_NEUTRAL:
			sText = doGetTextDirectAsymCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		case MS_SEAT_MAIN:
		case MS_SEAT_T:
			sText = doGetTextDirectAsymCoefficientCalcPoint(props.properties.calcPoint1);
			break;
		default:
			sText = doGetTextDirectAsymCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		}
		break;
	case EKind.MS_REACTOR:		// 限流リアクトル
		sText = doGetTextDirectAsymCoefficientCalcPoint(props.properties.calcPoint0);
		break;
	case EKind.MS_HVCB:			// 過電流継電器
		sText = doGetTextDirectAsymCoefficientCalcPoint(props.properties.calcPoint0);
		break;
	case EKind.MS_RELAY51:		// 継電器_51
		sText = doGetTextDirectAsymCoefficientCalcPoint(props.properties.calcPoint0);
		break;
	case EKind.MS_MVCB:			// 遮断器
		switch (nPartKind) {
		case MS_OUTSIDE_OUTSIDE:
		case MS_OUTSIDE_NEUTRAL:
			sText = doGetTextDirectAsymCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		case MS_SEAT_MAIN:
		case MS_SEAT_T:
			sText = doGetTextDirectAsymCoefficientCalcPoint(props.properties.calcPoint1);
			break;
		default:
			sText = doGetTextDirectAsymCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		}
		break;
	case EKind.MS_CONTACTOR:		// 電磁接触器
		switch (nPartKind) {
		case MS_OUTSIDE_OUTSIDE:
		case MS_OUTSIDE_NEUTRAL:
			sText = doGetTextDirectAsymCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		case MS_SEAT_MAIN:
		case MS_SEAT_T:
			sText = doGetTextDirectAsymCoefficientCalcPoint(props.properties.calcPoint1);
			break;
		default:
			sText = doGetTextDirectAsymCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		}
		break;
	case EKind.MS_LVCB:			// 低圧遮断器
		switch (nPartKind) {
		case MS_OUTSIDE_OUTSIDE:
		case MS_OUTSIDE_NEUTRAL:
			sText = doGetTextDirectAsymCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		case MS_SEAT_MAIN:
		case MS_SEAT_T:
			sText = doGetTextDirectAsymCoefficientCalcPoint(props.properties.calcPoint1);
			break;
		default:
			sText = doGetTextDirectAsymCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		}
		break;
	case EKind.MS_FUSE:			// ヒューズ
		sText = doGetTextDirectAsymCoefficientCalcPoint(props.properties.calcPoint0);
		break;
	case EKind.MS_2E:				// 電動機保護リレー
		switch (nPartKind) {
		case MS_OUTSIDE_OUTSIDE:
		case MS_OUTSIDE_NEUTRAL:
			sText =doGetTextDirectAsymCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		case MS_SEAT_MAIN:
		case MS_SEAT_T:
			sText = doGetTextDirectAsymCoefficientCalcPoint(props.properties.calcPoint1);
			break;
		default:
			sText = doGetTextDirectAsymCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		}
		break;
	case EKind.MS_THERMAL:		// サーマルリレー
		switch (nPartKind) {
		case MS_OUTSIDE_OUTSIDE:
		case MS_OUTSIDE_NEUTRAL:
			sText = doGetTextDirectAsymCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		case MS_SEAT_MAIN:
		case MS_SEAT_T:
			sText = doGetTextDirectAsymCoefficientCalcPoint(props.properties.calcPoint1);
			break;
		default:
			sText = doGetTextDirectAsymCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		}
		break;
	case EKind.MS_MOTOR:			// 電動機
		break;
	case EKind.MS_MOTOR_GROUP:	// 電動機群
		sText = doGetTextDirectAsymCoefficientCalcPoint(props.properties.calcPoint0);
		break;
	case EKind.MS_LOAD:			// 負荷
		break;
	case EKind.MS_CAPACITOR:		// コンデンサ
		break;
	case EKind.MS_LOADCENTER:		// ロードセンタ
		break;
	case EKind.MS_ZEROCOND:		// 母線
		switch (nPartKind) {
		case MS_OUTSIDE_OUTSIDE:
		case MS_OUTSIDE_NEUTRAL:
			sText = doGetTextDirectAsymCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		case MS_SEAT_MAIN:
		case MS_SEAT_T:
			sText = doGetTextDirectAsymCoefficientCalcPoint(props.properties.calcPoint1);
			break;
		default:
			sText = doGetTextDirectAsymCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		}
		break;
	case EKind.MS_CONNECT:		// 接続線
		break;
	case EKind.MS_TEXT:			// 任意文字列
		break;
	case EKind.MS_DS:				// 断路器
		sText = doGetTextDirectAsymCoefficientCalcPoint(props.properties.calcPoint0);
		break;
	case EKind.MS_LBS:			// 開閉器
		sText = doGetTextDirectAsymCoefficientCalcPoint(props.properties.calcPoint0);
		break;
	case EKind.MS_THERMAL_CT:		// 過負荷継電器
		switch (nPartKind) {
		case MS_OUTSIDE_OUTSIDE:
		case MS_OUTSIDE_NEUTRAL:
			sText = doGetTextDirectAsymCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		case MS_SEAT_MAIN:
		case MS_SEAT_T:
			sText = doGetTextDirectAsymCoefficientCalcPoint(props.properties.calcPoint1);
			break;
		default:
			sText = doGetTextDirectAsymCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		}
		break;
	case EKind.MS_ARRESTOR:		// 避雷器
		break;
	case EKind.MS_EARTH:			// 接地
		break;
	case EKind.MS_CABLEHEAD:		// ケーブルヘッド
		break;
	case EKind.MS_CT:				// 変流器
		break;
	case EKind.MS_ZCT:			// 零相変流器
		break;
	case EKind.MS_VT:				// 計器用変圧器
		break;
	case EKind.MS_VCT:			// 計器用変成器
		break;
	case EKind.MS_INV:			// インバーター
		break;
	case EKind.MS_RELAY:			// リレー
		break;
	case EKind.MS_LIGHTBOARD:		// 分電盤
		break;
	case EKind.MS_POWERBOARD:		// 制御盤
		break;
	case EKind.MS_INTO_POINT:		// 引込口
		break;
	}

	return sText;
}

//CObjElement::DoGetTextDirectIksymResult
export const doGetTextDirectIksymResult = (props: ControlModel, processMode: any, nPartKind: number, bUnit: boolean = true, bViewZero: boolean = true) =>
{
	let sText = "";
	let sSignMult = '×';
	let sSignRoot = '√';

	switch(props.type) {
	case EKind.MS_SOURCE:			// 電源
		if (isPointFaultSource(props.properties) == true) {
			sText = doGetTextDirectIk3symSource(props, bUnit).toString();
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_GENERATOR:		// 発電機
		if (props.properties.fault == true) {
			sText = doGetTextDirectIk3symGenerator(props, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_TRANS1:			// 単相変圧器
		if (nPartKind == MS_OUTSIDE_OUTSIDE) {
			if (props.properties.partOutOut.fault == true) {
				sText = doGetTextDirectIkSsymTrans1(props.properties, MS_OUTSIDE_OUTSIDE, bUnit);
			}
			else {
				sText = "-";
			}
		}
		else if (nPartKind == MS_OUTSIDE_NEUTRAL) {
			if (props.properties.partOutIn.fault == true) {
				sText = doGetTextDirectIkSsymTrans1(props.properties, MS_OUTSIDE_NEUTRAL, bUnit);
			}
			else {
				sText = "-";
			}
		}
		break;
	case EKind.MS_TRANS3:			// 三相変圧器
		if (props.properties.fault == true) {
			sText = doGetTextDirectIk3symTrans3(props.properties, bUnit).toString();
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_TRANSCENTER:	// 変台
		if (isPointFaultTransCenter(props.properties) == true) {
			sText = doGetTextDirectIksymTransCenter(props.properties, processMode, nPartKind, bUnit, bViewZero);
		}
		else {
			sText = "-"
		}
		break;
	case EKind.MS_3WINDING:		// 三巻線変圧器
		if (nPartKind == VOLT_SIDE_PRIMARY) {
			if (props.properties.partPrimary.fault) {
				sText = doGetTextDirectIk3sym3winding(props.properties, VOLT_SIDE_PRIMARY, bUnit);
			}
			else {
				sText = "-";
			}
		}
		else if (nPartKind == VOLT_SIDE_SECONDARY) {
			if (props.properties.partSecondary.fault) {
				sText = doGetTextDirectIk3sym3winding(props.properties, VOLT_SIDE_SECONDARY, bUnit);
			}
			else {
				sText = "-";
			}
		}
		else if (nPartKind == VOLT_SIDE_THIRD) {
			if (props.properties.partThird.fault) {
				sText = doGetTextDirectIk3sym3winding(props.properties, VOLT_SIDE_THIRD, bUnit);
			}
			else {
				sText = "-";
			}
		}
		break;
	case EKind.MS_TRANSSCOTT:		// スコット結線変圧器
		if (nPartKind == MS_SEAT_MAIN) {
			if (props.properties.partSeatM.fault) {
				sText = doGetTextDirectIkSsymTransscott(props.properties, MS_SEAT_MAIN, bUnit);
			}
			else {
				sText = "-";
			}
		}
		else if (nPartKind == MS_SEAT_T) {
			if (props.properties.partSeatT.fault) {
				sText = doGetTextDirectIkSsymTransscott(props.properties, MS_SEAT_T, bUnit);
			}
			else {
				sText = "-";
			}
		}
		break;
	case EKind.MS_IMPEDANCE:		// インピーダンス
		if (props.properties.fault) {
			sText = doGetTextDirectIksymImpedance(props.properties, nPartKind, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_BUSDUCT:		// バスダクト
		if (props.properties.fault) {
			sText = doGetTextDirectIksymBusDuct(props.properties, nPartKind, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_BUSBAR:			// ブスバー
		if (props.properties.fault) {
			sText = doGetTextDirectIksymBusBar(props.properties, nPartKind, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_WIRE:			// ワイヤ
		if (props.properties.fault) {
			sText = doGetTextDirectIksymWire(props.properties, nPartKind, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_REACTOR:		// 限流リアクトル
		if (props.properties.fault) {
			sText = doGetTextDirectIk3symReactor(props.properties, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_HVCB:			// 過電流継電器
		if (props.properties.fault) {
			sText = doGetTextDirectIk3symHVCB(props.properties, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_RELAY51:		// 継電器_51
		// if (props.properties.fault == true) {
		// 	sText = doGetTextDirectIk3sym(bUnit);
		// }
		// else {
		// 	sText = "-";
		// }
		break;
	case EKind.MS_MVCB:			// 遮断器
		if (props.properties.fault) {
			sText = doGetTextDirectIksymMVCB(props.properties, nPartKind, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_CONTACTOR:		// 電磁接触器
		if (props.properties.fault) {
			sText = doGetTextDirectIksymContactor(props.properties, nPartKind, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_LVCB:			// 低圧遮断器
		if (props.properties.fault) {
			sText = doGetTextDirectIksymLVCB(props.properties, nPartKind, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_FUSE:			// ヒューズ
		if (props.properties.fault == true) {
			sText = doGetTextDirectIk3symFUSE(props.properties, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_2E:				// 電動機保護リレー
		if (props.properties.fault) {
			sText = doGetTextDirectIksym2E(props.properties, nPartKind, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_THERMAL:		// サーマルリレー
		if (props.properties.fault) {
			sText = doGetTextDirectIksymTHERMAL(props.properties, nPartKind, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_MOTOR:			// 電動機
		break;
	case EKind.MS_MOTOR_GROUP:	// 電動機群
		if (props.properties.fault) {
			sText = doGetTextDirectIk3symMotorGrp(props.properties, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_LOAD:			// 負荷
		break;
	case EKind.MS_CAPACITOR:		// コンデンサ
		break;
	case EKind.MS_LOADCENTER:		// ロードセンタ
		break;
	case EKind.MS_ZEROCOND:		// 母線
		break;
	case EKind.MS_CONNECT:		// 接続線
		break;
	case EKind.MS_TEXT:			// 任意文字列
		break;
	case EKind.MS_DS:				// 断路器
		if (props.properties.fault) {
			sText = doGetTextDirectIk3symDS(props.properties, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_LBS:			// 開閉器
		if (props.properties.fault) {
			sText = doGetTextDirectIk3symLBS(props.properties, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_THERMAL_CT:		// 過負荷継電器
		if (props.properties.fault) {
			sText = doGetTextDirectIksymThermalCT(props.properties, nPartKind, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_ARRESTOR:		// 避雷器
		break;
	case EKind.MS_EARTH:			// 接地
		break;
	case EKind.MS_CABLEHEAD:		// ケーブルヘッド
		break;
	case EKind.MS_CT:				// 変流器
		break;
	case EKind.MS_ZCT:			// 零相変流器
		break;
	case EKind.MS_VT:				// 計器用変圧器
		break;
	case EKind.MS_VCT:			// 計器用変成器
		break;
	case EKind.MS_INV:			// インバーター
		break;
	case EKind.MS_RELAY:			// リレー
		break;
	case EKind.MS_LIGHTBOARD:		// 分電盤
		break;
	case EKind.MS_POWERBOARD:		// 制御盤
		break;
	case EKind.MS_INTO_POINT:		// 引込口
		break;
	}

	return sText;
  
}

//CObjElement::DoGetTextDirectIkpeakResult
export const doGetTextDirectIkpeakResult = (props: ControlModel, processMode: any, nPartKind: number, bUnit: boolean = true, bViewZero: boolean = true) =>
{
	let sText = "";

	let sSignMult = "×";
	let sSignRoot = "√";

	switch(props.type) {
	case EKind.MS_SOURCE:			// 電源
		if (isPointFaultSource(props.properties)) {
			sText = doGetTextDirectIk3peakSource(props, bUnit);
		}
		else {
			sText = "-";
  }
		break;
	case EKind.MS_GENERATOR:		// 発電機
		if (props.properties.fault) {
			sText = doGetTextDirectIk3peakGenerator(props, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_TRANS1:			// 単相変圧器
		if (nPartKind == MS_OUTSIDE_OUTSIDE) {
			if (props.properties.partOutOut.fault) {
				sText = doGetTextDirectIkSpeakTrans1(props.properties, MS_OUTSIDE_OUTSIDE, bUnit);
			}
			else {
				sText = "-";
			}
		}
		else if (nPartKind == MS_OUTSIDE_NEUTRAL) {
			if (props.properties.partOutIn.fault) {
				sText = doGetTextDirectIkSpeakTrans1(props.properties, MS_OUTSIDE_NEUTRAL, bUnit);
			}
			else {
				sText = "-";
			}
		}
		break;
	case EKind.MS_TRANS3:			// 三相変圧器
		if (props.properties.fault) {
			sText = doGetTextDirectIk3peakTrans3(props.properties, bUnit).toString();
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_TRANSCENTER:	// 変台
		if (isPointFaultTransCenter(props.properties)) {
			sText = doGetTextDirectIkpeakTransCenter(props.properties, processMode, nPartKind, bUnit, bViewZero);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_3WINDING:		// 三巻線変圧器
		if (nPartKind == VOLT_SIDE_PRIMARY) {
			if (props.properties.partPrimary.fault) {
				sText = doGetTextDirectIk3peak3winding(props.properties, VOLT_SIDE_PRIMARY, bUnit);
			}
			else {
				sText = "-";
			}
		}
		else if (nPartKind == VOLT_SIDE_SECONDARY) {
			if (props.properties.partSecondary.fault) {
				sText = doGetTextDirectIk3peak3winding(props.properties, VOLT_SIDE_SECONDARY, bUnit);
			}
			else {
				sText = "-";
			}
		}
		else if (nPartKind == VOLT_SIDE_THIRD) {
			if (props.properties.partThird.fault) {
				sText = doGetTextDirectIk3peak3winding(props.properties, VOLT_SIDE_THIRD, bUnit);
			}
			else {
				sText = "-";
			}
		}
		break;
	case EKind.MS_TRANSSCOTT:		// スコット結線変圧器
		if (nPartKind == MS_SEAT_MAIN) {
			if (props.properties.partSeatM.fault) {
				sText = doGetTextDirectIkSpeakTransscott(props.properties, MS_SEAT_MAIN, bUnit);
			}
			else {
				sText = "-";
			}
		}
		else if (nPartKind == MS_SEAT_T) {
			if (props.properties.partSeatT.fault) {
				sText = doGetTextDirectIkSpeakTransscott(props.properties, MS_SEAT_T, bUnit);
			}
			else {
				sText = "-";
			}
		}
		break;
	case EKind.MS_IMPEDANCE:		// インピーダンス
		if (props.properties.fault) {
			sText = doGetTextDirectIkpeakImpedance(props.properties, nPartKind, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_BUSDUCT:		// バスダクト
		if (props.properties.fault) {
			sText = doGetTextDirectIkpeakBusDuct(props.properties, nPartKind, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_BUSBAR:			// ブスバー
		if (props.properties.fault) {
			sText = doGetTextDirectIkpeakBusBar(props.properties, nPartKind, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_WIRE:			// ワイヤ
		if (props.properties.fault) {
			sText = doGetTextDirectIkpeakWire(props.properties, nPartKind, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_REACTOR:		// 限流リアクトル
		if (props.properties.fault) {
			sText = doGetTextDirectIk3peakReactor(props.properties, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_HVCB:			// 過電流継電器
		if (props.properties.fault) {
			sText = doGetTextDirectIk3peakHVCB(props.properties, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_RELAY51:		// 継電器_51
		// if (m_pRelay51->m_bFault == true) {
		// 	sText = m_pRelay51->DoGetTextDirectIk3peak(bUnit);
		// }
		// else {
		// 	sText = "-";
		// }
		break;
	case EKind.MS_MVCB:			// 遮断器
		if (props.properties.fault) {
			sText = doGetTextDirectIkpeakMVCB(props.properties, nPartKind, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_CONTACTOR:		// 電磁接触器
		if (props.properties.fault) {
			sText = doGetTextDirectIkpeakContactor(props.properties, nPartKind, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_LVCB:			// 低圧遮断器
		if (props.properties.fault) {
			sText = doGetTextDirectIkpeakLVCB(props.properties, nPartKind, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_FUSE:			// ヒューズ
		if (props.properties.fault) {
			sText = doGetTextDirectIk3peakFUSE(props.properties, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_2E:				// 電動機保護リレー
		if (props.properties.fault) {
			sText = doGetTextDirectIkpeak2E(props.properties, nPartKind, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_THERMAL:		// サーマルリレー
		if (props.properties.fault) {
			sText = doGetTextDirectIkpeakTHERMAL(props.properties, nPartKind, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_MOTOR:			// 電動機
		break;
	case EKind.MS_MOTOR_GROUP:	// 電動機群
		if (props.properties.fault) {
			sText = doGetTextDirectIk3peakMotorGrp(props, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_LOAD:			// 負荷
		break;
	case EKind.MS_CAPACITOR:		// コンデンサ
		break;
	case EKind.MS_LOADCENTER:		// ロードセンタ
		break;
	case EKind.MS_ZEROCOND:		// 母線
		break;
	case EKind.MS_CONNECT:		// 接続線
		break;
	case EKind.MS_TEXT:			// 任意文字列
		break;
	case EKind.MS_DS:				// 断路器
		if (props.properties.fault) {
			sText = doGetTextDirectIk3peakDS(props.properties, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_LBS:			// 開閉器
		if (props.properties.fault) {
			sText = doGetTextDirectIk3peakLBS(props.properties, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_THERMAL_CT:		// 過負荷継電器
		if (props.properties.fault) {
			sText = doGetTextDirectIkpeakThermalCT(props.properties, nPartKind, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_ARRESTOR:		// 避雷器
		break;
	case EKind.MS_EARTH:			// 接地
		break;
	case EKind.MS_CABLEHEAD:		// ケーブルヘッド
		break;
	case EKind.MS_CT:				// 変流器
		break;
	case EKind.MS_ZCT:			// 零相変流器
		break;
	case EKind.MS_VT:				// 計器用変圧器
		break;
	case EKind.MS_VCT:			// 計器用変成器
		break;
	case EKind.MS_INV:			// インバーター
		break;
	case EKind.MS_RELAY:			// リレー
		break;
	case EKind.MS_LIGHTBOARD:		// 分電盤
		break;
	case EKind.MS_POWERBOARD:		// 制御盤
		break;
	case EKind.MS_INTO_POINT:		// 引込口
		break;
	}

	return sText;
}

//CObjElement::DoGetTextDirectIkasymResult
export const doGetTextDirectIkasymResult = (props: ControlModel, processMode: any, nPartKind: number, bUnit: boolean = true, bViewZero: boolean = true) =>
{
	let sText = "";

	let sSignMult = "×";
	let sSignRoot = "√";

	switch(props.type) {
	case EKind.MS_SOURCE:			// 電源
		if (isPointFaultSource(props.properties)) {
			sText = doGetTextDirectIk3asymSource(props, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_GENERATOR:		// 発電機
		if (props.properties.fault) {
			sText = doGetTextDirectIk3asymGenerator(props, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_TRANS1:			// 単相変圧器
		if (nPartKind == MS_OUTSIDE_OUTSIDE) {
			if (props.properties.partOutOut.fault) {
				sText = doGetTextDirectIkSasymTrans1(props.properties, MS_OUTSIDE_OUTSIDE, bUnit);
			}
			else {
				sText = "-";
			}
		}
		else if (nPartKind == MS_OUTSIDE_NEUTRAL) {
			if (props.properties.partOutIn.fault) {
				sText = doGetTextDirectIkSasymTrans1(props.properties, MS_OUTSIDE_NEUTRAL, bUnit);
			}
			else {
				sText = "-";
			}
		}
		break;
	case EKind.MS_TRANS3:			// 三相変圧器
		if (props.properties.fault) {
			sText = doGetTextDirectIk3asymTrans3(props.properties, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_TRANSCENTER:	// 変台
		if (isPointFaultTransCenter(props.properties)) {
			sText = doGetTextDirectIkasymTransCenter(props.properties, processMode, nPartKind, bUnit, bViewZero);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_3WINDING:		// 三巻線変圧器
		if (nPartKind == VOLT_SIDE_PRIMARY) {
			if (props.properties.partPrimary.fault) {
				sText = doGetTextDirectIk3asym3winding(props.properties, VOLT_SIDE_PRIMARY, bUnit);
			}
			else {
				sText = "-";
			}
		}
		else if (nPartKind == VOLT_SIDE_SECONDARY) {
			if (props.properties.partSecondary.fault) {
				sText = doGetTextDirectIk3asym3winding(props.properties, VOLT_SIDE_SECONDARY, bUnit);
			}
			else {
				sText = "-";
			}
		}
		else if (nPartKind == VOLT_SIDE_THIRD) {
			if (props.properties.partThird.fault) {
				sText = doGetTextDirectIk3asym3winding(props.properties, VOLT_SIDE_THIRD, bUnit);
			}
			else {
				sText = "-";
			}
		}
		break;
	case EKind.MS_TRANSSCOTT:		// スコット結線変圧器
		if (nPartKind == MS_SEAT_MAIN) {
			if (props.properties.partSeatM.fault) {
				sText = doGetTextDirectIkSasymTransscott(props.properties, MS_SEAT_MAIN, bUnit);
			}
			else {
				sText = "-";
			}
		}
		else if (nPartKind == MS_SEAT_T) {
			if (props.properties.partSeatT.fault) {
				sText = doGetTextDirectIkSasymTransscott(props.properties, MS_SEAT_T, bUnit);
			}
			else {
				sText = "-";
			}
		}
		break;
	case EKind.MS_IMPEDANCE:		// インピーダンス
    if (props.properties.fault) {
			sText = doGetTextDirectIkasymImpedance(props.properties, nPartKind, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_BUSDUCT:		// バスダクト
    if (props.properties.fault) {
			sText = doGetTextDirectIkasymBusDuct(props.properties, nPartKind, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_BUSBAR:			// ブスバー
    if (props.properties.fault) {
			sText = doGetTextDirectIkasymBusBar(props.properties, nPartKind, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_WIRE:			// ワイヤ
    if (props.properties.fault) {
			sText = doGetTextDirectIkasymWire(props.properties, nPartKind, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_REACTOR:		// 限流リアクトル
    if (props.properties.fault) {
			sText = doGetTextDirectIk3asymReactor(props.properties, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_HVCB:			// 過電流継電器
    if (props.properties.fault) {
			sText = doGetTextDirectIk3asymHVCB(props.properties, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_RELAY51:		// 継電器_51
		// if (m_pRelay51->m_bFault == true) {
		// 	sText = m_pRelay51->DoGetTextDirectIk3asym(bUnit);
		// }
		// else {
		// 	sText = "-";
		// }
		break;
	case EKind.MS_MVCB:			// 遮断器
    if (props.properties.fault) {
			sText = doGetTextDirectIkasymMVCB(props.properties, nPartKind, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_CONTACTOR:		// 電磁接触器
    if (props.properties.fault) {
			sText = doGetTextDirectIkasymContactor(props.properties, nPartKind, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_LVCB:			// 低圧遮断器
    if (props.properties.fault) {
			sText = doGetTextDirectIkasymLVCB(props.properties, nPartKind, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_FUSE:			// ヒューズ
    if (props.properties.fault) {
			sText = doGetTextDirectIk3asymFUSE(props.properties, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_2E:				// 電動機保護リレー
    if (props.properties.fault) {
			sText = doGetTextDirectIkasym2E(props.properties, nPartKind, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_THERMAL:		// サーマルリレー
    if (props.properties.fault) {
			sText = doGetTextDirectIkasymTHERMAL(props.properties, nPartKind, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_MOTOR:			// 電動機
		break;
	case EKind.MS_MOTOR_GROUP:	// 電動機群
    if (props.properties.fault) {
			sText = doGetTextDirectIk3asymMotorGrp(props, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_LOAD:			// 負荷
		break;
	case EKind.MS_CAPACITOR:		// コンデンサ
		break;
	case EKind.MS_LOADCENTER:		// ロードセンタ
		break;
	case EKind.MS_ZEROCOND:		// 母線
		break;
	case EKind.MS_CONNECT:		// 接続線
		break;
	case EKind.MS_TEXT:			// 任意文字列
		break;
	case EKind.MS_DS:				// 断路器
    if (props.properties.fault) {
			sText = doGetTextDirectIk3asymDS(props.properties, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_LBS:			// 開閉器
    if (props.properties.fault) {
			sText = doGetTextDirectIk3asymLBS(props.properties, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_THERMAL_CT:		// 過負荷継電器
    if (props.properties.fault) {
			sText = doGetTextDirectIkasymThermalCT(props.properties, nPartKind, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_ARRESTOR:		// 避雷器
		break;
	case EKind.MS_EARTH:			// 接地
		break;
	case EKind.MS_CABLEHEAD:		// ケーブルヘッド
		break;
	case EKind.MS_CT:				// 変流器
		break;
	case EKind.MS_ZCT:			// 零相変流器
		break;
	case EKind.MS_VT:				// 計器用変圧器
		break;
	case EKind.MS_VCT:			// 計器用変成器
		break;
	case EKind.MS_INV:			// インバーター
		break;
	case EKind.MS_RELAY:			// リレー
		break;
	case EKind.MS_LIGHTBOARD:		// 分電盤
		break;
	case EKind.MS_POWERBOARD:		// 制御盤
		break;
	case EKind.MS_INTO_POINT:		// 引込口
		break;
	}

	return sText;
}

//CObjElement::DoGetTextDirectIksymEquation
export const doGetTextDirectIksymEquation = (props:ControlModel, processMode:any, infoCircuit: any, nPartKind:number) =>
{
	let sText = "";

  let sSignMult = "×";
	let sSignRoot = "√";

	switch(props.type) {
	case EKind.MS_SOURCE:			// 電源
		if (isPointFaultSource(props.properties)) {
			sText = infoCircuit.stdCapacity.toString() + 'kVA';
			sText += sSignMult;
			sText += "100/(";
			sText += sSignRoot;
			sText += "3";
			sText += sSignMult;
			sText += doGetTextVoltageSource(props);
			sText += sSignMult;
			sText += doGetTextPercentZrSource(props, infoCircuit.stdCapacity);
			sText += ")";
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_GENERATOR:		// 発電機
		if (props.properties.fault) {
			sText = infoCircuit.stdCapacity.toString() + 'kVA';
			sText += sSignMult;
			sText += "100/(";
			sText += sSignRoot;
			sText += "3";
			sText += sSignMult;
			sText += doGetTextVoltageGenerator(props);
			sText += sSignMult;
			sText += doGetTextDirectPercentZrGenerator(props, infoCircuit.stdCapacity);
			sText += ")";
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_TRANS1:			// 単相変圧器
		if (nPartKind == MS_OUTSIDE_OUTSIDE) {
			if (props.properties.partOutOut.fault) {
				sText = infoCircuit.stdCapacity.toString() + 'kVA';
				sText += sSignMult;
				sText += "100/(";
				sText += doGetTextVoltSecondaryTrans1(props.properties, MS_OUTSIDE_OUTSIDE);
				sText += sSignMult;
				sText += doGetTextDirectPercentZrTrans1(props, MS_OUTSIDE_OUTSIDE);
				sText += ")";
			}
			else {
				sText = "-";
			}
		}
		else if (nPartKind == MS_OUTSIDE_NEUTRAL) {
			if (props.properties.partOutIn.fault) {
				sText = infoCircuit.stdCapacity.toString() + 'kVA';
				sText += sSignMult;
				sText += "100/(";
				sText += doGetTextVoltSecondaryTrans1(props.properties, MS_OUTSIDE_NEUTRAL);
				sText += sSignMult;
				sText += doGetTextDirectPercentZrTrans1(props, MS_OUTSIDE_NEUTRAL);
				sText += ")";
			}
			else {
				sText = "-";
			}
		}
		break;
	case EKind.MS_TRANS3:			// 三相変圧器
		if (props.properties.fault) {
			sText = infoCircuit.stdCapacity.toString() + 'kVA';
			sText += sSignMult;
			sText += "100/(";
			sText += sSignRoot;
			sText += "3";
			sText += sSignMult;
			sText += doGetTextVoltSecondaryTrans3(props.properties);
			sText += sSignMult;
			sText += doGetTextDirectPercentZrTrans3(props);
			sText += ")";
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_TRANSCENTER:	// 変台
		if (isPointFaultTransCenter(props.properties)) {
			switch (nPartKind) {
			case MS_OUTSIDE_OUTSIDE:
			case MS_OUTSIDE_NEUTRAL:
				sText = infoCircuit.stdCapacity.toString() + 'kVA';
				sText += sSignMult;
				sText += "100/(";
				sText += doGetTextVoltageTransCenter(props.properties, MS_OUTSIDE_OUTSIDE);
				sText += sSignMult;
				sText += doGetTextDirectPercentZrTransCenter(props, processMode, MS_OUTSIDE_OUTSIDE);
				sText += ")";
				break;
			case MS_SEAT_MAIN:
			case MS_SEAT_T:
				sText = doGetTextDirectIksymTransCenter(props.properties, processMode, MS_SEAT_MAIN);
				break;
			default:
				sText = infoCircuit.stdCapacity.toString() + 'kVA';
				sText += sSignMult;
				sText += "100/(";
				sText += sSignRoot;
				sText += "3";
				sText += sSignMult;
				sText += doGetTextVoltageTransCenter(props.properties, MS_3PHASES);
				sText += sSignMult;
				sText += doGetTextDirectPercentZrTransCenter(props, processMode, MS_3PHASES);
				sText += ")";
				break;
			}
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_3WINDING:		// 三巻線変圧器
		if (nPartKind == VOLT_SIDE_PRIMARY) {
			if (props.properties.partPrimary.fault) {
				sText = infoCircuit.stdCapacity.toString() + 'kVA';
				sText += sSignMult;
				sText += "100/((";
				sText += sSignRoot;
				sText += "3)";
				sText += doGetTextVoltage3winding(props.properties, VOLT_SIDE_PRIMARY);
				sText += sSignMult;
				sText += doGetTextDirectPercentZr3winding(props, VOLT_SIDE_PRIMARY);
				sText += ")";
			}
			else {
				sText = "-";
			}
		}
		else if (nPartKind == VOLT_SIDE_SECONDARY) {
			if (props.properties.partSecondary.fault) {
				sText = infoCircuit.stdCapacity.toString() + 'kVA';
				sText += sSignMult;
				sText += "100/((";
				sText += sSignRoot;
				sText += "3)";
				sText += doGetTextVoltage3winding(props.properties, VOLT_SIDE_SECONDARY);
				sText += sSignMult;
				sText += doGetTextDirectPercentZr3winding(props, VOLT_SIDE_SECONDARY);
				sText += ")";
			}
			else {
				sText = "-";
			}
		}
		else if (nPartKind == VOLT_SIDE_THIRD) {
			if (props.properties.partThird.fault) {
				sText = infoCircuit.stdCapacity.toString() + 'kVA';
				sText += sSignMult;
				sText += "100/((";
				sText += sSignRoot;
				sText += "3)";
				sText += doGetTextVoltage3winding(props.properties, VOLT_SIDE_THIRD);
				sText += sSignMult;
				sText += doGetTextDirectPercentZr3winding(props, VOLT_SIDE_THIRD);
				sText += ")";
			}
			else {
				sText = "-";
			}
		}
		break;
	case EKind.MS_TRANSSCOTT:		// スコット結線変圧器
		if (nPartKind == MS_SEAT_MAIN) {
			if (props.properties.partSeatM.fault) {
				sText = doGetTextDirectIkSsymTransscott(props.properties, MS_SEAT_MAIN);
			}
			else {
				sText = "-";
			}
		}
		else if (nPartKind == MS_SEAT_T) {
			if (props.properties.partSeatT.fault) {
				sText = doGetTextDirectIkSsymTransscott(props.properties, MS_SEAT_T);
			}
			else {
				sText = "-";
			}
		}
		break;
	case EKind.MS_IMPEDANCE:		// インピーダンス
		if (props.properties.fault == true) {
			switch (nPartKind) {
			case MS_OUTSIDE_OUTSIDE:
				sText = infoCircuit.stdCapacity.toString() + 'kVA';
				sText += sSignMult;
				sText += "100/(";
				sText += doGetTextVoltageImpedance(props.properties, MS_OUTSIDE_OUTSIDE);
				sText += sSignMult;
				sText += doGetTextDirectPercentZrImpedance(props, MS_OUTSIDE_OUTSIDE);
				sText += ")";
				break;
			case MS_OUTSIDE_NEUTRAL:
				sText = infoCircuit.stdCapacity.toString() + 'kVA';
				sText += sSignMult;
				sText += "100/(";
				sText += doGetTextVoltageImpedance(props.properties, MS_OUTSIDE_NEUTRAL);
				sText += sSignMult;
				sText += doGetTextDirectPercentZrImpedance(props, MS_OUTSIDE_NEUTRAL);
				sText += ")";
				break;
			case MS_SEAT_MAIN:
				sText = doGetTextDirectIksymImpedance(props.properties, MS_SEAT_MAIN);
				break;
			case MS_SEAT_T:
				sText = doGetTextDirectIksymImpedance(props.properties, MS_SEAT_T);
				break;
			default:
				sText = infoCircuit.stdCapacity.toString() + 'kVA';
				sText += sSignMult;
				sText += "100/(";
				sText += sSignRoot;
				sText += "3";
				sText += sSignMult;
				sText += doGetTextVoltageImpedance(props.properties, MS_3PHASES);
				sText += sSignMult;
				sText += doGetTextDirectPercentZrImpedance(props, MS_3PHASES);
				sText += ")";
				break;
			}
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_BUSDUCT:		// バスダクト
		if (props.properties.fault) {
			switch (nPartKind) {
			case MS_OUTSIDE_OUTSIDE:
				sText = infoCircuit.stdCapacity.toString() + 'kVA';
				sText += sSignMult;
				sText += "100/(";
				sText += doGetTextVoltageBusDuct(props.properties, MS_OUTSIDE_OUTSIDE);
				sText += sSignMult;
				sText += doGetTextDirectPercentZrBusDuct(props, MS_OUTSIDE_OUTSIDE);
				sText += ")";
				break;
			case MS_OUTSIDE_NEUTRAL:
				sText = infoCircuit.stdCapacity.toString() + 'kVA';
				sText += sSignMult;
				sText += "100/(";
				sText += doGetTextVoltageBusDuct(props.properties, MS_OUTSIDE_NEUTRAL);
				sText += sSignMult;
				sText += doGetTextDirectPercentZrBusDuct(props, MS_OUTSIDE_NEUTRAL);
				sText += ")";
				break;
			case MS_SEAT_MAIN:
				sText = doGetTextDirectIksymBusDuct(props.properties, MS_SEAT_MAIN);
				break;
			case MS_SEAT_T:
				sText = doGetTextDirectIksymBusDuct(props.properties, MS_SEAT_T);
				break;
			default:
				sText = infoCircuit.stdCapacity.toString() + 'kVA';
				sText += sSignMult;
				sText += "100/(";
				sText += sSignRoot;
				sText += "3";
				sText += sSignMult;
				sText += doGetTextVoltageBusDuct(props.properties, MS_3PHASES);
				sText += sSignMult;
				sText += doGetTextDirectPercentZrBusDuct(props, MS_3PHASES);
				sText += ")";
				break;
			}
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_BUSBAR:			// ブスバー
		if (props.properties.fault) {
			switch (nPartKind) {
			case MS_OUTSIDE_OUTSIDE:
				sText = infoCircuit.stdCapacity.toString() + 'kVA';
				sText += sSignMult;
				sText += "100/(";
				sText += doGetTextVoltageBusBar(props.properties, MS_OUTSIDE_OUTSIDE);
				sText += sSignMult;
				sText += doGetTextDirectPercentZrBusBar(props, MS_OUTSIDE_OUTSIDE);
				sText += ")";
				break;
			case MS_OUTSIDE_NEUTRAL:
				sText = infoCircuit.stdCapacity.toString() + 'kVA';
				sText += sSignMult;
				sText += "100/(";
				sText += doGetTextVoltageBusBar(props.properties, MS_OUTSIDE_NEUTRAL);
				sText += sSignMult;
				sText += doGetTextDirectPercentZrBusBar(props, MS_OUTSIDE_NEUTRAL);
				sText += ")";
				break;
			case MS_SEAT_MAIN:
				sText =doGetTextDirectIksymBusBar(props.properties, MS_SEAT_MAIN);
				break;
			case MS_SEAT_T:
				sText = doGetTextDirectIksymBusBar(props.properties, MS_SEAT_T);
				break;
			default:
				sText = infoCircuit.stdCapacity.toString() + 'kVA';
				sText += sSignMult;
				sText += "100/(";
				sText += sSignRoot;
				sText += "3";
				sText += sSignMult;
				sText += doGetTextVoltageBusBar(props.properties, MS_3PHASES);
				sText += sSignMult;
				sText += doGetTextDirectPercentZrBusBar(props, MS_3PHASES);
				sText += ")";
				break;
			}
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_WIRE:			// ワイヤ
		if (props.properties.fault) {
			switch (nPartKind) {
			case MS_OUTSIDE_OUTSIDE:
				sText = infoCircuit.stdCapacity.toString() + 'kVA';
				sText += sSignMult;
				sText += "100/(";
				sText += doGetTextVoltageWire(props.properties, MS_OUTSIDE_OUTSIDE);
				sText += sSignMult;
				sText += doGetTextDirectPercentZrWire(props, MS_OUTSIDE_OUTSIDE);
				sText += ")";
				break;
			case MS_OUTSIDE_NEUTRAL:
				sText = infoCircuit.stdCapacity.toString() + 'kVA';
				sText += sSignMult;
				sText += "100/(";
				sText += doGetTextVoltageWire(props.properties, MS_OUTSIDE_NEUTRAL);
				sText += sSignMult;
				sText += doGetTextDirectPercentZrWire(props, MS_OUTSIDE_NEUTRAL);
				sText += ")";
				break;
			case MS_SEAT_MAIN:
				sText = doGetTextDirectIksymWire(props.properties, MS_SEAT_MAIN);
				break;
			case MS_SEAT_T:
				sText = doGetTextDirectIksymWire(props.properties, MS_SEAT_T);
				break;
			default:
				sText = infoCircuit.stdCapacity.toString() + 'kVA';
				sText += sSignMult;
				sText += "100/(";
				sText += sSignRoot;
				sText += "3";
				sText += sSignMult;
				sText += doGetTextVoltageWire(props.properties, MS_3PHASES);
				sText += sSignMult;
				sText += doGetTextDirectPercentZrWire(props, MS_3PHASES);
				sText += ")";
				break;
			}
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_REACTOR:		// 限流リアクトル
		if (props.properties.fault) {
			sText = infoCircuit.stdCapacity.toString() + 'kVA';
			sText += sSignMult;
			sText += "100/(";
			sText += sSignRoot;
			sText += "3";
			sText += sSignMult;
			sText += doGetTextVoltageReactor(props.properties);
			sText += sSignMult;
			sText += doGetTextDirectPercentZrReactor(props);
			sText += ")";
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_HVCB:			// 過電流継電器
		if (props.properties.fault) {
			sText = infoCircuit.stdCapacity.toString() + 'kVA';
			sText += sSignMult;
			sText += "100/(";
			sText += sSignRoot;
			sText += "3";
			sText += sSignMult;
			sText += doGetTextVoltageHVCB(props.properties);
			sText += sSignMult;
			sText += doGetTextDirectPercentZrHVCB(props);
			sText += ")";
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_RELAY51:		// 継電器_51
		// if (m_pRelay51->m_bFault ) {
		// 	sText = infoCircuit.stdCapacity.toString() + 'kVA';
		// 	sText += sSignMult;
		// 	sText += "100/(";
		// 	sText += sSignRoot;
		// 	sText += "3";
		// 	sText += sSignMult;
		// 	sText += m_pRelay51->DoGetTextVoltage();
		// 	sText += sSignMult;
		// 	sText += m_pRelay51->DoGetTextDirectPercentZr();
		// 	sText += ")";
		// }
		// else {
		// 	sText = "-";
		// }
		break;
	case EKind.MS_MVCB:			// 遮断器
		if (props.properties.fault ) {
			switch (nPartKind) {
			case MS_OUTSIDE_OUTSIDE:
				sText = infoCircuit.stdCapacity.toString() + 'kVA';
				sText += sSignMult;
				sText += "100/(";
				sText += doGetTextVoltageMVCB(props.properties, MS_OUTSIDE_OUTSIDE);
				sText += sSignMult;
				sText += doGetTextDirectPercentZrMVCB(props, MS_OUTSIDE_OUTSIDE);
				sText += ")";
				break;
			case MS_OUTSIDE_NEUTRAL:
				sText = infoCircuit.stdCapacity.toString() + 'kVA';
				sText += sSignMult;
				sText += "100/(";
				sText += doGetTextVoltageMVCB(props.properties, MS_OUTSIDE_NEUTRAL);
				sText += sSignMult;
				sText += doGetTextDirectPercentZrMVCB(props, MS_OUTSIDE_NEUTRAL);
				sText += ")";
				break;
			case MS_SEAT_MAIN:
				sText = doGetTextDirectIksymMVCB(props.properties, MS_SEAT_MAIN);
				break;
			case MS_SEAT_T:
				sText = doGetTextDirectIksymMVCB(props.properties, MS_SEAT_T);
				break;
			default:
				sText = infoCircuit.stdCapacity.toString() + 'kVA';
				sText += sSignMult;
				sText += "100/(";
				sText += sSignRoot;
				sText += "3";
				sText += sSignMult;
				sText += doGetTextVoltageMVCB(props.properties, MS_3PHASES);
				sText += sSignMult;
				sText += doGetTextDirectPercentZrMVCB(props, MS_3PHASES);
				sText += ")";
				break;
			}
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_CONTACTOR:		// 電磁接触器
		if (props.properties.fault ) {
			switch (nPartKind) {
			case MS_OUTSIDE_OUTSIDE:
				sText = infoCircuit.stdCapacity.toString() + 'kVA';
				sText += sSignMult;
				sText += "100/(";
				sText += doGetTextVoltageContactor(props.properties, MS_OUTSIDE_OUTSIDE);
				sText += sSignMult;
				sText += doGetTextDirectPercentZrContactor(props, MS_OUTSIDE_OUTSIDE);
				sText += ")";
				break;
			case MS_OUTSIDE_NEUTRAL:
				sText = infoCircuit.stdCapacity.toString() + 'kVA';
				sText += sSignMult;
				sText += "100/(";
				sText += doGetTextVoltageContactor(props.properties, MS_OUTSIDE_NEUTRAL);
				sText += sSignMult;
				sText += doGetTextDirectPercentZrContactor(props, MS_OUTSIDE_NEUTRAL);
				sText += ")";
				break;
			case MS_SEAT_MAIN:
				sText = doGetTextDirectIksymContactor(props.properties, MS_SEAT_MAIN);
				break;
			case MS_SEAT_T:
				sText = doGetTextDirectIksymContactor(props.properties, MS_SEAT_T);
				break;
			default:
				sText = infoCircuit.stdCapacity.toString() + 'kVA';
				sText += sSignMult;
				sText += "100/(";
				sText += sSignRoot;
				sText += "3";
				sText += sSignMult;
				sText += doGetTextVoltageContactor(props.properties, MS_3PHASES);
				sText += sSignMult;
				sText += doGetTextDirectPercentZrContactor(props, MS_3PHASES);
				sText += ")";
				break;
			}
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_LVCB:			// 低圧遮断器
		if (props.properties.fault ) {
			switch (nPartKind) {
			case MS_OUTSIDE_OUTSIDE:
				sText = infoCircuit.stdCapacity.toString() + 'kVA';
				sText += sSignMult;
				sText += "100/(";
				sText += doGetTextVoltageLVCB(props.properties, MS_OUTSIDE_OUTSIDE);
				sText += sSignMult;
				sText += doGetTextDirectPercentZrLVCB(props, MS_OUTSIDE_OUTSIDE);
				sText += ")";
				break;
			case MS_OUTSIDE_NEUTRAL:
				sText = infoCircuit.stdCapacity.toString() + 'kVA';
				sText += sSignMult;
				sText += "100/(";
				sText += doGetTextVoltageLVCB(props.properties, MS_OUTSIDE_NEUTRAL);
				sText += sSignMult;
				sText += doGetTextDirectPercentZrLVCB(props, MS_OUTSIDE_NEUTRAL);
				sText += ")";
				break;
			case MS_SEAT_MAIN:
				sText = doGetTextDirectIksymLVCB(props.properties, MS_SEAT_MAIN);
				break;
			case MS_SEAT_T:
				sText = doGetTextDirectIksymLVCB(props.properties, MS_SEAT_T);
				break;
			default:
				sText = infoCircuit.stdCapacity.toString() + 'kVA';
				sText += sSignMult;
				sText += "100/(";
				sText += sSignRoot;
				sText += "3";
				sText += sSignMult;
				sText += doGetTextVoltageLVCB(props.properties, MS_3PHASES);
				sText += sSignMult;
				sText += doGetTextDirectPercentZrLVCB(props, MS_3PHASES);
				sText += ")";
				break;
			}
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_FUSE:			// ヒューズ
		if (props.properties.fault ) {
			sText = infoCircuit.stdCapacity.toString() + 'kVA';
			sText += sSignMult;
			sText += "100/(";
			sText += sSignRoot;
			sText += "3";
			sText += sSignMult;
			sText += doGetTextVoltageFUSE(props.properties);
			sText += sSignMult;
			sText += doGetTextDirectPercentZrFUSE(props);
			sText += ")";
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_2E:				// 電動機保護リレー
		if (props.properties.fault ) {
			switch (nPartKind) {
			case MS_OUTSIDE_OUTSIDE:
				sText = infoCircuit.stdCapacity.toString() + 'kVA';
				sText += sSignMult;
				sText += "100/(";
				sText += doGetTextVoltage2E(props.properties, MS_OUTSIDE_OUTSIDE);
				sText += sSignMult;
				sText += doGetTextDirectPercentZr2E(props, MS_OUTSIDE_OUTSIDE);
				sText += ")";
				break;
			case MS_OUTSIDE_NEUTRAL:
				sText = infoCircuit.stdCapacity.toString() + 'kVA';
				sText += sSignMult;
				sText += "100/(";
				sText += doGetTextVoltage2E(props.properties, MS_OUTSIDE_NEUTRAL);
				sText += sSignMult;
				sText += doGetTextDirectPercentZr2E(props, MS_OUTSIDE_NEUTRAL);
				sText += ")";
				break;
			case MS_SEAT_MAIN:
				sText = doGetTextDirectIksym2E(props.properties, MS_SEAT_MAIN);
				break;
			case MS_SEAT_T:
				sText = doGetTextDirectIksym2E(props.properties, MS_SEAT_T);
				break;
			default:
				sText = infoCircuit.stdCapacity.toString() + 'kVA';
				sText += sSignMult;
				sText += "100/(";
				sText += sSignRoot;
				sText += "3";
				sText += sSignMult;
				sText += doGetTextVoltage2E(props.properties, MS_3PHASES);
				sText += sSignMult;
				sText += doGetTextDirectPercentZr2E(props, MS_3PHASES);
				sText += ")";
				break;
			}
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_THERMAL:		// サーマルリレー
		if (props.properties.fault ) {
			switch (nPartKind) {
			case MS_OUTSIDE_OUTSIDE:
				sText = infoCircuit.stdCapacity.toString() + 'kVA';
				sText += sSignMult;
				sText += "100/(";
				sText += doGetTextVoltageTHERMAL(props.properties, MS_OUTSIDE_OUTSIDE);
				sText += sSignMult;
				sText += doGetTextDirectPercentZrTHERMAL(props, MS_OUTSIDE_OUTSIDE);
				sText += ")";
				break;
			case MS_OUTSIDE_NEUTRAL:
				sText = infoCircuit.stdCapacity.toString() + 'kVA';
				sText += sSignMult;
				sText += "100/(";
				sText += doGetTextVoltageTHERMAL(props.properties, MS_OUTSIDE_NEUTRAL);
				sText += sSignMult;
				sText += doGetTextDirectPercentZrTHERMAL(props, MS_OUTSIDE_NEUTRAL);
				sText += ")";
				break;
			case MS_SEAT_MAIN:
				sText = doGetTextDirectIksymTHERMAL(props.properties, MS_SEAT_MAIN);
				break;
			case MS_SEAT_T:
				sText = doGetTextDirectIksymTHERMAL(props.properties, MS_SEAT_T);
				break;
			default:
				sText = infoCircuit.stdCapacity.toString() + 'kVA';
				sText += sSignMult;
				sText += "100/(";
				sText += sSignRoot;
				sText += "3";
				sText += sSignMult;
				sText += doGetTextVoltageTHERMAL(props.properties, MS_3PHASES);
				sText += sSignMult;
				sText += doGetTextDirectPercentZrTHERMAL(props, MS_3PHASES);
				sText += ")";
				break;
			}
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_MOTOR:			// 電動機
		break;
	case EKind.MS_MOTOR_GROUP:	// 電動機群
		if (props.properties.fault ) {
			sText = infoCircuit.stdCapacity.toString() + 'kVA';
			sText += sSignMult;
			sText += "100/(";
			sText += sSignRoot;
			sText += "3";
			sText += sSignMult;
			sText += doGetTextVoltageMotorGrp(props.properties);
			sText += sSignMult;
			sText += doGetTextDirectPercentZrMotorGrp(props.properties, infoCircuit);
			sText += ")";
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_LOAD:			// 負荷
		break;
	case EKind.MS_CAPACITOR:		// コンデンサ
		break;
	case EKind.MS_LOADCENTER:		// ロードセンタ
		break;
	case EKind.MS_ZEROCOND:		// 母線
		break;
	case EKind.MS_CONNECT:		// 接続線
		break;
	case EKind.MS_TEXT:			// 任意文字列
		break;
	case EKind.MS_DS:				// 断路器
		if (props.properties.fault) {
			sText = infoCircuit.stdCapacity.toString() + 'kVA';
			sText += sSignMult;
			sText += "100/(";
			sText += sSignRoot;
			sText += "3";
			sText += sSignMult;
			sText += doGetTextVoltageDS(props.properties);
			sText += sSignMult;
			sText += doGetTextDirectPercentZrDS(props);
			sText += ")";
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_LBS:			// 開閉器
		if (props.properties.fault == true) {
			sText = infoCircuit.stdCapacity.toString() + 'kVA';
			sText += sSignMult;
			sText += "100/(";
			sText += sSignRoot;
			sText += "3";
			sText += sSignMult;
			sText += doGetTextVoltageLBS(props.properties);
			sText += sSignMult;
			sText += doGetTextDirectPercentZrLBS(props);
			sText += ")";
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_THERMAL_CT:		// 過負荷継電器
		if (props.properties.fault) {
			switch (nPartKind) {
			case MS_OUTSIDE_OUTSIDE:
				sText = infoCircuit.stdCapacity.toString() + 'kVA';
				sText += sSignMult;
				sText += "100/(";
				sText += doGetTextVoltageThermalCT(props.properties, MS_OUTSIDE_OUTSIDE);
				sText += sSignMult;
				sText += doGetTextDirectPercentZrThermalCT(props, MS_OUTSIDE_OUTSIDE);
				sText += ")";
				break;
			case MS_OUTSIDE_NEUTRAL:
				sText = infoCircuit.stdCapacity.toString() + 'kVA';
				sText += sSignMult;
				sText += "100/(";
				sText += doGetTextVoltageThermalCT(props.properties, MS_OUTSIDE_NEUTRAL);
				sText += sSignMult;
				sText += doGetTextDirectPercentZrThermalCT(props, MS_OUTSIDE_NEUTRAL);
				sText += ")";
				break;
			case MS_SEAT_MAIN:
				sText = doGetTextDirectIksymThermalCT(props.properties, MS_SEAT_MAIN);
				break;
			case MS_SEAT_T:
				sText = doGetTextDirectIksymThermalCT(props.properties, MS_SEAT_T);
				break;
			default:
				sText = infoCircuit.stdCapacity.toString() + 'kVA';
				sText += sSignMult;
				sText += "100/(";
				sText += sSignRoot;
				sText += "3";
				sText += sSignMult;
				sText += doGetTextVoltageThermalCT(props.properties, MS_3PHASES);
				sText += sSignMult;
				sText += doGetTextDirectPercentZrThermalCT(props, MS_3PHASES);
				sText += ")";
				break;
			}
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_ARRESTOR:		// 避雷器
		break;
	case EKind.MS_EARTH:			// 接地
		break;
	case EKind.MS_CABLEHEAD:		// ケーブルヘッド
		break;
	case EKind.MS_CT:				// 変流器
		break;
	case EKind.MS_ZCT:			// 零相変流器
		break;
	case EKind.MS_VT:				// 計器用変圧器
		break;
	case EKind.MS_VCT:			// 計器用変成器
		break;
	case EKind.MS_INV:			// インバーター
		break;
	case EKind.MS_RELAY:			// リレー
		break;
	case EKind.MS_LIGHTBOARD:		// 分電盤
		break;
	case EKind.MS_POWERBOARD:		// 制御盤
		break;
	case EKind.MS_INTO_POINT:		// 引込口
		break;
	}

	return sText;
}

//CObjElement::DoGetTextTotalPeakCoefficient
export const doGetTextTotalPeakCoefficient = (props:ControlModel, nPartKind:number) =>
{
	let sText = "";

	switch(props.type) {
	case EKind.MS_SOURCE:			// 電源
		sText = doGetTextTotalPeakCoefficientCalcPoint(props.properties.calcPoint0);
		break;
	case EKind.MS_GENERATOR:		// 発電機
		sText = doGetTextTotalPeakCoefficientCalcPoint(props.properties.calcPoint0);
		break;
	case EKind.MS_TRANS1:			// 単相変圧器
		if (nPartKind == MS_OUTSIDE_OUTSIDE) {
			sText = doGetTextTotalPeakCoefficientCalcPoint(props.properties.partOutOut.calcPoint);
		}
		else if (nPartKind == MS_OUTSIDE_NEUTRAL) {
			sText = doGetTextTotalPeakCoefficientCalcPoint(props.properties.partOutIn.calcPoint);
		}
		break;
	case EKind.MS_TRANS3:			// 三相変圧器
		sText = doGetTextTotalPeakCoefficientCalcPoint(props.properties.calcPoint0);
		break;
	case EKind.MS_TRANSCENTER:	// 変台
		switch (nPartKind) {
		case MS_OUTSIDE_OUTSIDE:
		case MS_OUTSIDE_NEUTRAL:
			sText = doGetTextTotalPeakCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		case MS_SEAT_MAIN:
		case MS_SEAT_T:
			sText = doGetTextTotalPeakCoefficientCalcPoint(props.properties.calcPoint1);
			break;
		default:
			sText = doGetTextTotalPeakCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		}
		break;
	case EKind.MS_3WINDING:		// 三巻線変圧器
		if (nPartKind == VOLT_SIDE_PRIMARY) {
			sText = doGetTextTotalPeakCoefficientCalcPoint(props.properties.partPrimary.calcPoint0);
		}
		else if (nPartKind == VOLT_SIDE_SECONDARY) {
			sText = doGetTextTotalPeakCoefficientCalcPoint(props.properties.partSecondary.calcPoint0);
		}
		else if (nPartKind == VOLT_SIDE_THIRD) {
			sText = doGetTextTotalPeakCoefficientCalcPoint(props.properties.partThird.calcPoint0);
		}
		break;
	case EKind.MS_TRANSSCOTT:		// スコット結線変圧器
		if (nPartKind == MS_SEAT_MAIN) {
			sText = doGetTextTotalPeakCoefficientCalcPoint(props.properties.partSeatM.calcPoint);
		}
		else if (nPartKind == MS_SEAT_T) {
			sText = doGetTextTotalPeakCoefficientCalcPoint(props.properties.partSeatT.calcPoint);
		}
		break;
	case EKind.MS_IMPEDANCE:		// インピーダンス
		switch (nPartKind) {
		case MS_OUTSIDE_OUTSIDE:
		case MS_OUTSIDE_NEUTRAL:
			sText = doGetTextTotalPeakCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		case MS_SEAT_MAIN:
		case MS_SEAT_T:
			sText = doGetTextTotalPeakCoefficientCalcPoint(props.properties.calcPoint1);
			break;
		default:
			sText = doGetTextTotalPeakCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		}
		break;
	case EKind.MS_BUSDUCT:		// バスダクト
		switch (nPartKind) {
		case MS_OUTSIDE_OUTSIDE:
		case MS_OUTSIDE_NEUTRAL:
			sText = doGetTextTotalPeakCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		case MS_SEAT_MAIN:
		case MS_SEAT_T:
			sText = doGetTextTotalPeakCoefficientCalcPoint(props.properties.calcPoint1);
			break;
		default:
			sText = doGetTextTotalPeakCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		}
		break;
	case EKind.MS_BUSBAR:			// ブスバー
		switch (nPartKind) {
		case MS_OUTSIDE_OUTSIDE:
		case MS_OUTSIDE_NEUTRAL:
			sText = doGetTextTotalPeakCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		case MS_SEAT_MAIN:
		case MS_SEAT_T:
			sText = doGetTextTotalPeakCoefficientCalcPoint(props.properties.calcPoint1);
			break;
		default:
			sText = doGetTextTotalPeakCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		}
		break;
	case EKind.MS_WIRE:			// ワイヤ
		switch (nPartKind) {
		case MS_OUTSIDE_OUTSIDE:
		case MS_OUTSIDE_NEUTRAL:
			sText = doGetTextTotalPeakCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		case MS_SEAT_MAIN:
		case MS_SEAT_T:
			sText = doGetTextTotalPeakCoefficientCalcPoint(props.properties.calcPoint1);
			break;
		default:
			sText = doGetTextTotalPeakCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		}
		break;
	case EKind.MS_REACTOR:		// 限流リアクトル
		sText = doGetTextTotalPeakCoefficientCalcPoint(props.properties.calcPoint0);
		break;
	case EKind.MS_HVCB:			// 過電流継電器
		sText = doGetTextTotalPeakCoefficientCalcPoint(props.properties.calcPoint0);
		break;
	case EKind.MS_RELAY51:		// 継電器_51
		sText = doGetTextTotalPeakCoefficientCalcPoint(props.properties.calcPoint0);
		break;
	case EKind.MS_MVCB:			// 遮断器
		switch (nPartKind) {
		case MS_OUTSIDE_OUTSIDE:
		case MS_OUTSIDE_NEUTRAL:
			sText = doGetTextTotalPeakCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		case MS_SEAT_MAIN:
		case MS_SEAT_T:
			sText = doGetTextTotalPeakCoefficientCalcPoint(props.properties.calcPoint1);
			break;
		default:
			sText = doGetTextTotalPeakCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		}
		break;
	case EKind.MS_CONTACTOR:		// 電磁接触器
		switch (nPartKind) {
		case MS_OUTSIDE_OUTSIDE:
		case MS_OUTSIDE_NEUTRAL:
			sText = doGetTextTotalPeakCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		case MS_SEAT_MAIN:
		case MS_SEAT_T:
			sText = doGetTextTotalPeakCoefficientCalcPoint(props.properties.calcPoint1);
			break;
		default:
			sText = doGetTextTotalPeakCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		}
		break;
	case EKind.MS_LVCB:			// 低圧遮断器
		switch (nPartKind) {
		case MS_OUTSIDE_OUTSIDE:
		case MS_OUTSIDE_NEUTRAL:
			sText = doGetTextTotalPeakCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		case MS_SEAT_MAIN:
		case MS_SEAT_T:
			sText = doGetTextTotalPeakCoefficientCalcPoint(props.properties.calcPoint1);
			break;
		default:
			sText = doGetTextTotalPeakCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		}
		break;
	case EKind.MS_FUSE:			// ヒューズ
		sText = doGetTextTotalPeakCoefficientCalcPoint(props.properties.calcPoint0);
		break;
	case EKind.MS_2E:				// 電動機保護リレー
		switch (nPartKind) {
		case MS_OUTSIDE_OUTSIDE:
		case MS_OUTSIDE_NEUTRAL:
			sText = doGetTextTotalPeakCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		case MS_SEAT_MAIN:
		case MS_SEAT_T:
			sText = doGetTextTotalPeakCoefficientCalcPoint(props.properties.calcPoint1);
			break;
		default:
			sText = doGetTextTotalPeakCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		}
		break;
	case EKind.MS_THERMAL:		// サーマルリレー
		switch (nPartKind) {
		case MS_OUTSIDE_OUTSIDE:
		case MS_OUTSIDE_NEUTRAL:
			sText = doGetTextTotalPeakCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		case MS_SEAT_MAIN:
		case MS_SEAT_T:
			sText = doGetTextTotalPeakCoefficientCalcPoint(props.properties.calcPoint1);
			break;
		default:
			sText = doGetTextTotalPeakCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		}
		break;
	case EKind.MS_MOTOR:			// 電動機
		break;
	case EKind.MS_MOTOR_GROUP:	// 電動機群
		sText = doGetTextTotalPeakCoefficientCalcPoint(props.properties.calcPoint0);
		break;
	case EKind.MS_LOAD:			// 負荷
		break;
	case EKind.MS_CAPACITOR:		// コンデンサ
		break;
	case EKind.MS_LOADCENTER:		// ロードセンタ
		break;
	case EKind.MS_ZEROCOND:		// 母線
		switch (nPartKind) {
		case MS_OUTSIDE_OUTSIDE:
		case MS_OUTSIDE_NEUTRAL:
			sText = doGetTextTotalPeakCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		case MS_SEAT_MAIN:
		case MS_SEAT_T:
			sText = doGetTextTotalPeakCoefficientCalcPoint(props.properties.calcPoint1);
			break;
		default:
			sText = doGetTextTotalPeakCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		}
		break;
	case EKind.MS_CONNECT:		// 接続線
		break;
	case EKind.MS_TEXT:			// 任意文字列
		break;
	case EKind.MS_DS:				// 断路器
		sText = doGetTextTotalPeakCoefficientCalcPoint(props.properties.calcPoint0);
		break;
	case EKind.MS_LBS:			// 開閉器
		sText = doGetTextTotalPeakCoefficientCalcPoint(props.properties.calcPoint0);
		break;
	case EKind.MS_THERMAL_CT:		// 過負荷継電器
		switch (nPartKind) {
		case MS_OUTSIDE_OUTSIDE:
		case MS_OUTSIDE_NEUTRAL:
			sText = doGetTextTotalPeakCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		case MS_SEAT_MAIN:
		case MS_SEAT_T:
			sText = doGetTextTotalPeakCoefficientCalcPoint(props.properties.calcPoint1);
			break;
		default:
			sText = doGetTextTotalPeakCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		}
		break;
	case EKind.MS_ARRESTOR:		// 避雷器
		break;
	case EKind.MS_EARTH:			// 接地
		break;
	case EKind.MS_CABLEHEAD:		// ケーブルヘッド
		break;
	case EKind.MS_CT:				// 変流器
		break;
	case EKind.MS_ZCT:			// 零相変流器
		break;
	case EKind.MS_VT:				// 計器用変圧器
		break;
	case EKind.MS_VCT:			// 計器用変成器
		break;
	case EKind.MS_INV:			// インバーター
		break;
	case EKind.MS_RELAY:			// リレー
		break;
	case EKind.MS_LIGHTBOARD:		// 分電盤
		break;
	case EKind.MS_POWERBOARD:		// 制御盤
		break;
	case EKind.MS_INTO_POINT:		// 引込口
		break;
	}
	return sText;
}

//CObjElement::DoGetTextTotalAsymCoefficient
export const doGetTextTotalAsymCoefficient = (props:ControlModel, nPartKind:number) =>
{
	let sText = "";

	switch(props.type) {
	case EKind.MS_SOURCE:			// 電源
		sText = doGetTextTotalAsymCoefficientCalcPoint(props.properties.calcPoint0);
		break;
	case EKind.MS_GENERATOR:		// 発電機
		sText = doGetTextTotalAsymCoefficientCalcPoint(props.properties.calcPoint0);
		break;
	case EKind.MS_TRANS1:			// 単相変圧器
		if (nPartKind == MS_OUTSIDE_OUTSIDE) {
			sText = doGetTextTotalAsymCoefficientCalcPoint(props.properties.partOutOut.calcPoint);
		}
		else if (nPartKind == MS_OUTSIDE_NEUTRAL) {
			sText = doGetTextTotalAsymCoefficientCalcPoint(props.properties.partOutIn.calcPoint);
		}
		break;
	case EKind.MS_TRANS3:			// 三相変圧器
		sText = doGetTextTotalAsymCoefficientCalcPoint(props.properties.calcPoint0);
		break;
	case EKind.MS_TRANSCENTER:	// 変台
		switch (nPartKind) {
		case MS_OUTSIDE_OUTSIDE:
		case MS_OUTSIDE_NEUTRAL:
			sText = doGetTextTotalAsymCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		case MS_SEAT_MAIN:
		case MS_SEAT_T:
			sText = doGetTextTotalAsymCoefficientCalcPoint(props.properties.calcPoint1);
			break;
		default:
			sText = doGetTextTotalAsymCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		}
		break;
	case EKind.MS_3WINDING:		// 三巻線変圧器
		if (nPartKind == VOLT_SIDE_PRIMARY) {
			sText = doGetTextTotalAsymCoefficientCalcPoint(props.properties.partPrimary.calcPoint0);
		}
		else if (nPartKind == VOLT_SIDE_SECONDARY) {
			sText = doGetTextTotalAsymCoefficientCalcPoint(props.properties.partSecondary.calcPoint0);
		}
		else if (nPartKind == VOLT_SIDE_THIRD) {
			sText = doGetTextTotalAsymCoefficientCalcPoint(props.properties.partThird.calcPoint0);
		}
		break;
	case EKind.MS_TRANSSCOTT:		// スコット結線変圧器
		if (nPartKind == MS_SEAT_MAIN) {
			sText = doGetTextTotalAsymCoefficientCalcPoint(props.properties.partSeatM.calcPoint);
		}
		else if (nPartKind == MS_SEAT_T) {
			sText = doGetTextTotalAsymCoefficientCalcPoint(props.properties.partSeatT.calcPoint);
		}
		break;
	case EKind.MS_IMPEDANCE:		// インピーダンス
		switch (nPartKind) {
		case MS_OUTSIDE_OUTSIDE:
		case MS_OUTSIDE_NEUTRAL:
			sText = doGetTextTotalAsymCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		case MS_SEAT_MAIN:
		case MS_SEAT_T:
			sText = doGetTextTotalAsymCoefficientCalcPoint(props.properties.calcPoint1);
			break;
		default:
			sText = doGetTextTotalAsymCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		}
		break;
	case EKind.MS_BUSDUCT:		// バスダクト
		switch (nPartKind) {
		case MS_OUTSIDE_OUTSIDE:
		case MS_OUTSIDE_NEUTRAL:
			sText = doGetTextTotalAsymCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		case MS_SEAT_MAIN:
		case MS_SEAT_T:
			sText = doGetTextTotalAsymCoefficientCalcPoint(props.properties.calcPoint1);
			break;
		default:
			sText = doGetTextTotalAsymCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		}
		break;
	case EKind.MS_BUSBAR:			// ブスバー
		switch (nPartKind) {
		case MS_OUTSIDE_OUTSIDE:
		case MS_OUTSIDE_NEUTRAL:
			sText = doGetTextTotalAsymCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		case MS_SEAT_MAIN:
		case MS_SEAT_T:
			sText = doGetTextTotalAsymCoefficientCalcPoint(props.properties.calcPoint1);
			break;
		default:
			sText = doGetTextTotalAsymCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		}
		break;
	case EKind.MS_WIRE:			// ワイヤ
		switch (nPartKind) {
		case MS_OUTSIDE_OUTSIDE:
		case MS_OUTSIDE_NEUTRAL:
			sText = doGetTextTotalAsymCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		case MS_SEAT_MAIN:
		case MS_SEAT_T:
			sText = doGetTextTotalAsymCoefficientCalcPoint(props.properties.calcPoint1);
			break;
		default:
			sText = doGetTextTotalAsymCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		}
		break;
	case EKind.MS_REACTOR:		// 限流リアクトル
		sText = doGetTextTotalAsymCoefficientCalcPoint(props.properties.calcPoint0);
		break;
	case EKind.MS_HVCB:			// 過電流継電器
		sText = doGetTextTotalAsymCoefficientCalcPoint(props.properties.calcPoint0);
		break;
	case EKind.MS_RELAY51:		// 継電器_51
		sText = doGetTextTotalAsymCoefficientCalcPoint(props.properties.calcPoint0);
		break;
	case EKind.MS_MVCB:			// 遮断器
		switch (nPartKind) {
		case MS_OUTSIDE_OUTSIDE:
		case MS_OUTSIDE_NEUTRAL:
			sText = doGetTextTotalAsymCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		case MS_SEAT_MAIN:
		case MS_SEAT_T:
			sText = doGetTextTotalAsymCoefficientCalcPoint(props.properties.calcPoint1);
			break;
		default:
			sText = doGetTextTotalAsymCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		}
		break;
	case EKind.MS_CONTACTOR:		// 電磁接触器
		switch (nPartKind) {
		case MS_OUTSIDE_OUTSIDE:
		case MS_OUTSIDE_NEUTRAL:
			sText = doGetTextTotalAsymCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		case MS_SEAT_MAIN:
		case MS_SEAT_T:
			sText = doGetTextTotalAsymCoefficientCalcPoint(props.properties.calcPoint1);
			break;
		default:
			sText = doGetTextTotalAsymCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		}
		break;
	case EKind.MS_LVCB:			// 低圧遮断器
		switch (nPartKind) {
		case MS_OUTSIDE_OUTSIDE:
		case MS_OUTSIDE_NEUTRAL:
			sText = doGetTextTotalAsymCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		case MS_SEAT_MAIN:
		case MS_SEAT_T:
			sText = doGetTextTotalAsymCoefficientCalcPoint(props.properties.calcPoint1);
			break;
		default:
			sText = doGetTextTotalAsymCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		}
		break;
	case EKind.MS_FUSE:			// ヒューズ
		sText = doGetTextTotalAsymCoefficientCalcPoint(props.properties.calcPoint0);
		break;
	case EKind.MS_2E:				// 電動機保護リレー
		switch (nPartKind) {
		case MS_OUTSIDE_OUTSIDE:
		case MS_OUTSIDE_NEUTRAL:
			sText = doGetTextTotalAsymCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		case MS_SEAT_MAIN:
		case MS_SEAT_T:
			sText = doGetTextTotalAsymCoefficientCalcPoint(props.properties.calcPoint1);
			break;
		default:
			sText = doGetTextTotalAsymCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		}
		break;
	case EKind.MS_THERMAL:		// サーマルリレー
		switch (nPartKind) {
		case MS_OUTSIDE_OUTSIDE:
		case MS_OUTSIDE_NEUTRAL:
			sText = doGetTextTotalAsymCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		case MS_SEAT_MAIN:
		case MS_SEAT_T:
			sText = doGetTextTotalAsymCoefficientCalcPoint(props.properties.calcPoint1);
			break;
		default:
			sText = doGetTextTotalAsymCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		}
		break;
	case EKind.MS_MOTOR:			// 電動機
		break;
	case EKind.MS_MOTOR_GROUP:	// 電動機群
		sText = doGetTextTotalAsymCoefficientCalcPoint(props.properties.calcPoint0);
		break;
	case EKind.MS_LOAD:			// 負荷
		break;
	case EKind.MS_CAPACITOR:		// コンデンサ
		break;
	case EKind.MS_LOADCENTER:		// ロードセンタ
		break;
	case EKind.MS_ZEROCOND:		// 母線
		switch (nPartKind) {
		case MS_OUTSIDE_OUTSIDE:
		case MS_OUTSIDE_NEUTRAL:
			sText = doGetTextTotalAsymCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		case MS_SEAT_MAIN:
		case MS_SEAT_T:
			sText = doGetTextTotalAsymCoefficientCalcPoint(props.properties.calcPoint1);
			break;
		default:
			sText = doGetTextTotalAsymCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		}
		break;
	case EKind.MS_CONNECT:		// 接続線
		break;
	case EKind.MS_TEXT:			// 任意文字列
		break;
	case EKind.MS_DS:				// 断路器
		sText = doGetTextTotalAsymCoefficientCalcPoint(props.properties.calcPoint0);
		break;
	case EKind.MS_LBS:			// 開閉器
		sText = doGetTextTotalAsymCoefficientCalcPoint(props.properties.calcPoint0);
		break;
	case EKind.MS_THERMAL_CT:		// 過負荷継電器
		switch (nPartKind) {
		case MS_OUTSIDE_OUTSIDE:
		case MS_OUTSIDE_NEUTRAL:
			sText = doGetTextTotalAsymCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		case MS_SEAT_MAIN:
		case MS_SEAT_T:
			sText = doGetTextTotalAsymCoefficientCalcPoint(props.properties.calcPoint1);
			break;
		default:
			sText = doGetTextTotalAsymCoefficientCalcPoint(props.properties.calcPoint0);
			break;
		}
		break;
	case EKind.MS_ARRESTOR:		// 避雷器
		break;
	case EKind.MS_EARTH:			// 接地
		break;
	case EKind.MS_CABLEHEAD:		// ケーブルヘッド
		break;
	case EKind.MS_CT:				// 変流器
		break;
	case EKind.MS_ZCT:			// 零相変流器
		break;
	case EKind.MS_VT:				// 計器用変圧器
		break;
	case EKind.MS_VCT:			// 計器用変成器
		break;
	case EKind.MS_INV:			// インバーター
		break;
	case EKind.MS_RELAY:			// リレー
		break;
	case EKind.MS_LIGHTBOARD:		// 分電盤
		break;
	case EKind.MS_POWERBOARD:		// 制御盤
		break;
	case EKind.MS_INTO_POINT:		// 引込口
		break;
	}

	return sText;
}

//CObjElement::DoGetTextTotalIksymResult
export const doGetTextTotalIksymResult = (props:ControlModel, nPartKind:number, bUnit:boolean = true) =>
{
	let sText = "";

	let sSignMult = '×';
	let sSignRoot = '√';

	switch(props.type) {
	case EKind.MS_SOURCE:			// 電源
		if (isPointFaultSource(props.properties)) {
			sText = doGetTextTotalIk3symSource(props, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_GENERATOR:		// 発電機
		if (props.properties.fault) {
			sText = doGetTextTotalIk3symGenerator(props, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_TRANS1:			// 単相変圧器
		sText = "-";
		break;
	case EKind.MS_TRANS3:			// 三相変圧器
		if (props.properties.fault) {
			sText = doGetTextTotalIk3symTrans3(props.properties, bUnit).toString();
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_TRANSCENTER:	// 変台
		sText = "-";
		break;
	case EKind.MS_3WINDING:		// 三巻線変圧器
		if (nPartKind == VOLT_SIDE_PRIMARY) {
			if (props.properties.partPrimary.fault) {
				sText = doGetTextTotalIk3sym3winding(props.properties, VOLT_SIDE_PRIMARY, bUnit);
			}
			else {
				sText = "-";
			}
		}
		else if (nPartKind == VOLT_SIDE_SECONDARY) {
			if (props.properties.partSecondary.fault) {
				sText = doGetTextTotalIk3sym3winding(props.properties, VOLT_SIDE_SECONDARY, bUnit);
			}
			else {
				sText = "-";
			}
		}
		else if (nPartKind == VOLT_SIDE_THIRD) {
			if (props.properties.partThird.fault) {
				sText = doGetTextTotalIk3sym3winding(props.properties, VOLT_SIDE_THIRD, bUnit);
			}
			else {
				sText = "-";
			}
		}
		break;
	case EKind.MS_TRANSSCOTT:		// スコット結線変圧器
		sText = "-";
		break;
	case EKind.MS_IMPEDANCE:		// インピーダンス
		if (props.properties.fault) {
			if (props.properties.sPhase) {
				sText = "-";
			}
			else {
				sText = doGetTextTotalIk3symImpedance(props.properties, bUnit);
			}
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_BUSDUCT:		// バスダクト
		if (props.properties.fault) {
			if (props.properties.sPhase) {
				sText = "-";
			}
			else {
				sText = doGetTextTotalIk3symBusDuct(props.properties, bUnit);
			}
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_BUSBAR:			// ブスバー
		if (props.properties.fault) {
			if (props.properties.sPhase) {
				sText = "-";
			}
			else {
				sText = doGetTextTotalIk3symBusBar(props.properties, bUnit);
			}
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_WIRE:			// ワイヤ
		if (props.properties.fault) {
			if (props.properties.sPhase) {
				sText = "-";
			}
			else {
				sText = doGetTextTotalIk3symWire(props.properties, bUnit);
			}
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_REACTOR:		// 限流リアクトル
		if (props.properties.fault) {
			sText = doGetTextTotalIk3symReactor(props.properties, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_HVCB:			// 過電流継電器
		if (props.properties.fault) {
			sText = doGetTextTotalIk3symHVCB(props.properties, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_RELAY51:		// 継電器_51
		// if (props.properties.fault) {
		// 	sText = m_pRelay51->DoGetTextTotalIk3sym(bUnit);
		// }
		// else {
		// 	sText = "-";
		// }
		break;
	case EKind.MS_MVCB:			// 遮断器
		if (props.properties.fault) {
			if (props.properties.sPhase) {
				sText = "-";
			}
			else {
				sText = doGetTextTotalIk3symMVCB(props.properties, bUnit);
			}
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_CONTACTOR:		// 電磁接触器
		if (props.properties.fault) {
			if (props.properties.sPhase) {
				sText = "-";
			}
			else {
				sText = doGetTextTotalIk3symContactor(props.properties, bUnit);
			}
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_LVCB:			// 低圧遮断器
		if (props.properties.fault) {
			if (props.properties.sPhase) {
				sText = "-";
			}
			else {
				sText = doGetTextTotalIk3symLVCB(props.properties, bUnit);
			}
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_FUSE:			// ヒューズ
		if (props.properties.fault) {
			sText = doGetTextTotalIk3symFUSE(props.properties, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_2E:				// 電動機保護リレー
		if (props.properties.fault) {
			if (props.properties.sPhase) {
				sText = "-";
			}
			else {
				sText = doGetTextTotalIk3sym2E(props.properties, bUnit);
			}
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_THERMAL:		// サーマルリレー
		if (props.properties.fault) {
			if (props.properties.sPhase) {
				sText = "-";
			}
			else {
				sText = doGetTextTotalIk3symTHERMAL(props.properties, bUnit);
			}
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_MOTOR:			// 電動機
		break;
	case EKind.MS_MOTOR_GROUP:	// 電動機群
		if (props.properties.fault) {
			sText = doGetTextTotalIk3symMotorGrp(props, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_LOAD:			// 負荷
		break;
	case EKind.MS_CAPACITOR:		// コンデンサ
		break;
	case EKind.MS_LOADCENTER:		// ロードセンタ
		break;
	case EKind.MS_ZEROCOND:		// 母線
		if (props.properties.fault) {
			sText = doGetTextTotalIksymZeroCond(props.properties, nPartKind, bUnit);
	//		if (m_pZeroCond->m_bSPhase == true) {
	//			sText = "-";
	//		}
	//		else {					 
	//			sText = m_pZeroCond->DoGetTextTotalIksym(MS_3PHASES, bUnit);
	//		}
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_CONNECT:		// 接続線
		break;
	case EKind.MS_TEXT:			// 任意文字列
		break;
	case EKind.MS_DS:				// 断路器
		if (props.properties.fault) {
			sText = doGetTextTotalIk3symDS(props.properties, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_LBS:			// 開閉器
		if (props.properties.fault) {
			sText = doGetTextTotalIk3symLBS(props.properties, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_THERMAL_CT:		// 過負荷継電器
		if (props.properties.fault) {
			if (props.properties.sPhase) {
				sText = "-";
			}
			else {
				sText = doGetTextTotalIk3symThermalCT(props.properties, bUnit);
			}
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_ARRESTOR:		// 避雷器
		break;
	case EKind.MS_EARTH:			// 接地
		break;
	case EKind.MS_CABLEHEAD:		// ケーブルヘッド
		break;
	case EKind.MS_CT:				// 変流器
		break;
	case EKind.MS_ZCT:			// 零相変流器
		break;
	case EKind.MS_VT:				// 計器用変圧器
		break;
	case EKind.MS_VCT:			// 計器用変成器
		break;
	case EKind.MS_INV:			// インバーター
		break;
	case EKind.MS_RELAY:			// リレー
		break;
	case EKind.MS_LIGHTBOARD:		// 分電盤
		break;
	case EKind.MS_POWERBOARD:		// 制御盤
		break;
	case EKind.MS_INTO_POINT:		// 引込口
		break;
	}

	return sText;
}

//CObjElement::DoGetTextTotalIkpeakResult
export const doGetTextTotalIkpeakResult = (props:ControlModel, nPartKind:number, bUnit:boolean = true) =>
{
	let sText = "";

	let sSignMult = '×';
	let sSignRoot = '√';

	switch(props.type) {
	case EKind.MS_SOURCE:			// 電源
		if (isPointFaultSource(props.properties)) {
			sText = doGetTextTotalIk3peakSource(props.properties, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_GENERATOR:		// 発電機
		if (props.properties.fault) {
			sText = doGetTextTotalIk3peakGenerator(props.properties, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_TRANS1:			// 単相変圧器
		sText = "-";
		break;
	case EKind.MS_TRANS3:			// 三相変圧器
		if (props.properties.fault) {
			sText = doGetTextTotalIk3peakTrans3(props.properties, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_TRANSCENTER:	// 変台
		sText = "-";
		break;
	case EKind.MS_3WINDING:		// 三巻線変圧器
		if (nPartKind == VOLT_SIDE_PRIMARY) {
			if (props.properties.partPrimary.fault) {
				sText = doGetTextTotalIk3peak3winding(props.properties, VOLT_SIDE_PRIMARY, bUnit);
			}
			else {
				sText = "-";
			}
		}
		else if (nPartKind == VOLT_SIDE_SECONDARY) {
			if (props.properties.partSecondary.fault) {
				sText = doGetTextTotalIk3peak3winding(props.properties, VOLT_SIDE_SECONDARY, bUnit);
			}
			else {
				sText = "-";
			}
		}
		else if (nPartKind == VOLT_SIDE_THIRD) {
			if (props.properties.partThird.fault) {
				sText = doGetTextTotalIk3peak3winding(props.properties, VOLT_SIDE_THIRD, bUnit);
			}
			else {
				sText = "-";
			}
		}
		break;
	case EKind.MS_TRANSSCOTT:		// スコット結線変圧器
		sText = "-";
		break;
	case EKind.MS_IMPEDANCE:		// インピーダンス
		if (props.properties.fault) {
			if (props.properties.sPhase) {
				sText = "-";
			}
			else {
				sText = doGetTextTotalIk3peakImpedance(props.properties, bUnit);
			}
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_BUSDUCT:		// バスダクト
		if (props.properties.fault) {
			if (props.properties.sPhase) {
				sText = "-";
			}
			else {
				sText = doGetTextTotalIk3peakBusDuct(props.properties, bUnit);
			}
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_BUSBAR:			// ブスバー
		if (props.properties.fault) {
			if (props.properties.sPhase) {
				sText = "-";
			}
			else {
				sText = doGetTextTotalIk3peakBusBar(props.properties, bUnit);
			}
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_WIRE:			// ワイヤ
		if (props.properties.fault) {
			if (props.properties.sPhase) {
				sText = "-";
			}
			else {
				sText = doGetTextTotalIk3peakWire(props.properties, bUnit);
			}
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_REACTOR:		// 限流リアクトル
		if (props.properties.fault) {
			sText = doGetTextTotalIk3peakReactor(props.properties, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_HVCB:			// 過電流継電器
		if (props.properties.fault) {
			sText = doGetTextTotalIk3peakHVCB(props.properties, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_RELAY51:		// 継電器_51
		// if (m_pRelay51->m_bFault == true) {
		// 	sText = m_pRelay51->DoGetTextTotalIk3peak(bUnit);
		// }
		// else {
		// 	sText = "-";
		// }
		break;
	case EKind.MS_MVCB:			// 遮断器
		if (props.properties.fault) {
			if (props.properties.sPhase) {
				sText = "-";
			}
			else {
				sText = doGetTextTotalIk3peakMVCB(props.properties, bUnit);
			}
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_CONTACTOR:		// 電磁接触器
		if (props.properties.fault) {
			if (props.properties.sPhase) {
				sText = "-";
			}
			else {
				sText = doGetTextTotalIk3peakContactor(props.properties, bUnit);
			}
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_LVCB:			// 低圧遮断器
		if (props.properties.fault) {
			if (props.properties.sPhase) {
				sText = "-";
			}
			else {
				sText = doGetTextTotalIk3peakLVCB(props.properties, bUnit);
			}
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_FUSE:			// ヒューズ
		if (props.properties.fault) {
			sText = doGetTextTotalIk3peakFUSE(props.properties, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_2E:				// 電動機保護リレー
		if (props.properties.fault) {
			if (props.properties.sPhase) {
				sText = "-";
			}
			else {
				sText = doGetTextTotalIk3peak2E(props.properties, bUnit);
			}
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_THERMAL:		// サーマルリレー
		if (props.properties.fault) {
			if (props.properties.sPhase) {
				sText = "-";
			}
			else {
				sText = doGetTextTotalIk3peakTHERMAL(props.properties, bUnit);
			}
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_MOTOR:			// 電動機
		break;
	case EKind.MS_MOTOR_GROUP:	// 電動機群
		if (props.properties.fault) {
			sText = doGetTextTotalIk3peakMotorGrp(props.properties, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_LOAD:			// 負荷
		break;
	case EKind.MS_CAPACITOR:		// コンデンサ
		break;
	case EKind.MS_LOADCENTER:		// ロードセンタ
		break;
	case EKind.MS_ZEROCOND:		// 母線
		if (props.properties.fault) {
			sText = doGetTextTotalIkpeakZeroCond(props.properties, nPartKind, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_CONNECT:		// 接続線
		break;
	case EKind.MS_TEXT:			// 任意文字列
		break;
	case EKind.MS_DS:				// 断路器
		if (props.properties.fault) {
			sText = doGetTextTotalIk3peakDS(props.properties, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_LBS:			// 開閉器
		if (props.properties.fault) {
			sText = doGetTextTotalIk3peakLBS(props.properties, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_THERMAL_CT:		// 過負荷継電器
		if (props.properties.fault) {
			if (props.properties.sPhase) {
				sText = "-";
			}
			else {
				sText = doGetTextTotalIk3peakThermalCT(props.properties, bUnit);
			}
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_ARRESTOR:		// 避雷器
		break;
	case EKind.MS_EARTH:			// 接地
		break;
	case EKind.MS_CABLEHEAD:		// ケーブルヘッド
		break;
	case EKind.MS_CT:				// 変流器
		break;
	case EKind.MS_ZCT:			// 零相変流器
		break;
	case EKind.MS_VT:				// 計器用変圧器
		break;
	case EKind.MS_VCT:			// 計器用変成器
		break;
	case EKind.MS_INV:			// インバーター
		break;
	case EKind.MS_RELAY:			// リレー
		break;
	case EKind.MS_LIGHTBOARD:		// 分電盤
		break;
	case EKind.MS_POWERBOARD:		// 制御盤
		break;
	case EKind.MS_INTO_POINT:		// 引込口
		break;
	}

	return sText;
}

//CObjElement::DoGetTextTotalIkasymResult
export const doGetTextTotalIkasymResult = (props:ControlModel, nPartKind:number, bUnit:boolean = true) =>
{
	let sText = "";

	let sSignMult = "×";
	let sSignRoot = "√";

	switch(props.type) {
	case EKind.MS_SOURCE:			// 電源
		if (isPointFaultSource(props.properties)) {
			sText = doGetTextTotalIk3asymSource(props.properties, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_GENERATOR:		// 発電機
		if (props.properties.fault) {
			sText = doGetTextTotalIk3asymGenerator(props.properties, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_TRANS1:			// 単相変圧器
		sText = "-";
		break;
	case EKind.MS_TRANS3:			// 三相変圧器
		if (props.properties.fault) {
			sText = doGetTextTotalIk3asymTrans3(props.properties, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_TRANSCENTER:	// 変台
		sText = "-";
		break;
	case EKind.MS_3WINDING:		// 三巻線変圧器
		if (nPartKind == VOLT_SIDE_PRIMARY) {
			if (props.properties.partPrimary.fault) {
				sText = doGetTextTotalIk3asym3winding(props.properties, VOLT_SIDE_PRIMARY, bUnit);
			}
			else {
				sText = "-";
			}
		}
		else if (nPartKind == VOLT_SIDE_SECONDARY) {
			if (props.properties.partSecondary.fault) {
				sText = doGetTextTotalIk3asym3winding(props.properties, VOLT_SIDE_SECONDARY, bUnit);
			}
			else {
				sText = "-";
			}
		}
		else if (nPartKind == VOLT_SIDE_THIRD) {
			if (props.properties.partThird.fault) {
				sText = doGetTextTotalIk3asym3winding(props.properties, VOLT_SIDE_THIRD, bUnit);
			}
			else {
				sText = "-";
			}
		}
		break;
	case EKind.MS_TRANSSCOTT:		// スコット結線変圧器
		sText = "-";
		break;
	case EKind.MS_IMPEDANCE:		// インピーダンス
		if (props.properties.fault) {
			if (props.properties.sPhase) {
				sText = "-";
			}
			else {
				sText = doGetTextTotalIk3asymImpedance(props.properties, bUnit);
			}
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_BUSDUCT:		// バスダクト
		if (props.properties.fault) {
			if (props.properties.sPhase) {
				sText = "-";
			}
			else {
				sText = doGetTextTotalIk3asymBusDuct(props.properties, bUnit);
			}
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_BUSBAR:			// ブスバー
	if (props.properties.fault) {
			if (props.properties.sPhase) {
				sText = "-";
			}
			else {
				sText = doGetTextTotalIk3asymBusBar(props.properties, bUnit);
			}
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_WIRE:			// ワイヤ
		if (props.properties.fault) {
			if (props.properties.sPhase) {
				sText = "-";
			}
			else {
				sText = doGetTextTotalIk3asymWire(props.properties, bUnit);
			}
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_REACTOR:		// 限流リアクトル
		if (props.properties.fault) {
			sText = doGetTextTotalIk3asymReactor(props.properties, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_HVCB:			// 過電流継電器
		if (props.properties.fault) {
			sText = doGetTextTotalIk3asymHVCB(props.properties, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_RELAY51:		// 継電器_51
		// if (props.properties.fault) {
		// 	sText = m_pRelay51->DoGetTextTotalIk3asym(bUnit);
		// }
		// else {
		// 	sText = "-";
		// }
		break;
	case EKind.MS_MVCB:			// 遮断器
		if (props.properties.fault) {
			if (props.properties.sPhase) {
				sText = "-";
			}
			else {
				sText = doGetTextTotalIk3asymMVCB(props.properties, bUnit);
			}
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_CONTACTOR:		// 電磁接触器
		if (props.properties.fault) {
			if (props.properties.sPhase) {
				sText = "-";
			}
			else {
				sText = doGetTextTotalIk3asymContactor(props.properties, bUnit);
			}
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_LVCB:			// 低圧遮断器
		if (props.properties.fault) {
			if (props.properties.sPhase) {
				sText = "-";
			}
			else {
				sText = doGetTextTotalIk3asymLVCB(props.properties, bUnit);
			}
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_FUSE:			// ヒューズ
		if (props.properties.fault) {
			sText = doGetTextTotalIk3asymFUSE(props.properties, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_2E:				// 電動機保護リレー
		if (props.properties.fault) {
			if (props.properties.sPhase) {
				sText = "-";
			}
			else {
				sText = doGetTextTotalIk3asym2E(props.properties, bUnit);
			}
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_THERMAL:		// サーマルリレー
		if (props.properties.fault) {
			if (props.properties.sPhase) {
				sText = "-";
			}
			else {
				sText = doGetTextTotalIk3asymTHERMAL(props.properties, bUnit);
			}
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_MOTOR:			// 電動機
		break;
	case EKind.MS_MOTOR_GROUP:	// 電動機群
		if (props.properties.fault) {
			sText = doGetTextTotalIk3asymMotorGrp(props.properties, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_LOAD:			// 負荷
		break;
	case EKind.MS_CAPACITOR:		// コンデンサ
		break;
	case EKind.MS_LOADCENTER:		// ロードセンタ
		break;
	case EKind.MS_ZEROCOND:		// 母線
		if (props.properties.fault) {
			sText = doGetTextTotalIkasymZeroCond(props.properties, nPartKind, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_CONNECT:		// 接続線
		break;
	case EKind.MS_TEXT:			// 任意文字列
		break;
	case EKind.MS_DS:				// 断路器
		if (props.properties.fault) {
			sText = doGetTextTotalIk3asymDS(props.properties, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_LBS:			// 開閉器
		if (props.properties.fault) {
			sText = doGetTextTotalIk3asymLBS(props.properties, bUnit);
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_THERMAL_CT:		// 過負荷継電器
		if (props.properties.fault) {
			if (props.properties.sPhase) {
				sText = "-";
			}
			else {
				sText = doGetTextTotalIk3asymThermalCT(props.properties, bUnit);
			}
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_ARRESTOR:		// 避雷器
		break;
	case EKind.MS_EARTH:			// 接地
		break;
	case EKind.MS_CABLEHEAD:		// ケーブルヘッド
		break;
	case EKind.MS_CT:				// 変流器
		break;
	case EKind.MS_ZCT:			// 零相変流器
		break;
	case EKind.MS_VT:				// 計器用変圧器
		break;
	case EKind.MS_VCT:			// 計器用変成器
		break;
	case EKind.MS_INV:			// インバーター
		break;
	case EKind.MS_RELAY:			// リレー
		break;
	case EKind.MS_LIGHTBOARD:		// 分電盤
		break;
	case EKind.MS_POWERBOARD:		// 制御盤
		break;
	case EKind.MS_INTO_POINT:		// 引込口
		break;
	}

	return sText;
}

//CObjElement::DoGetTextTotalIksymEquation
export const doGetTextTotalIksymEquation = (props:ControlModel, processMode:any, infoCircuit: any, nPartKind:number) =>
{
	let sText = "";

	let sSignMult = "×";
	let sSignRoot = "√";

	switch(props.type) {
	case EKind.MS_SOURCE:			// 電源
		if (isPointFaultSource(props.properties)) {
			sText = infoCircuit.stdCapacity.toString() + 'kVA';
			sText += sSignMult;
			sText += "100/(";
			sText += sSignRoot;
			sText += "3";
			sText += sSignMult;
			sText += doGetTextVoltageSource(props);
			sText += sSignMult;
			sText += doGetTextTotalPercentZrSource(props.properties);
			sText += ")";
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_GENERATOR:		// 発電機
		if (props.properties.fault) {
			sText = infoCircuit.stdCapacity.toString() + 'kVA';
			sText += sSignMult;
			sText += "100/(";
			sText += sSignRoot;
			sText += "3";
			sText += sSignMult;
			sText += doGetTextVoltageGenerator(props);
			sText += sSignMult;
			sText += doGetTextTotalPercentZrGenerator(props.properties);
			sText += ")";
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_TRANS1:			// 単相変圧器
		sText = "-";
		break;
	case EKind.MS_TRANS3:			// 三相変圧器
		if (props.properties.fault) {
			sText = infoCircuit.stdCapacity.toString() + 'kVA';
			sText += sSignMult;
			sText += "100/(";
			sText += sSignRoot;
			sText += "3";
			sText += sSignMult;
			sText += doGetTextVoltSecondaryTrans3(props.properties);
			sText += sSignMult;
			sText += doGetTextTotalPercentZrTrans3(props.properties);
			sText += ")";
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_TRANSCENTER:	// 変台
		sText = "-";
		break;
	case EKind.MS_3WINDING:		// 三巻線変圧器
		if (nPartKind == VOLT_SIDE_PRIMARY) {
			if (props.properties.partPrimary.fault) {
				sText = infoCircuit.stdCapacity.toString() + 'kVA';
				sText += sSignMult;
				sText += "100/((";
				sText += sSignRoot;
				sText += "3)";
				sText += doGetTextVoltage3winding(props.properties, VOLT_SIDE_PRIMARY);
				sText += sSignMult;
				sText += doGetTextTotalPercentZr3winding(props.properties, VOLT_SIDE_PRIMARY);
				sText += ")";
			}
			else {
				sText = "-";
			}
		}
		else if (nPartKind == VOLT_SIDE_SECONDARY) {
			if (props.properties.partSecondary.fault) {
				sText = infoCircuit.stdCapacity.toString() + 'kVA';
				sText += sSignMult;
				sText += "100/((";
				sText += sSignRoot;
				sText += "3)";
				sText += doGetTextVoltage3winding(props.properties, VOLT_SIDE_SECONDARY);
				sText += sSignMult;
				sText += doGetTextTotalPercentZr3winding(props.properties, VOLT_SIDE_SECONDARY);
				sText += ")";
			}
			else {
				sText = "-";
			}
		}
		else if (nPartKind == VOLT_SIDE_THIRD) {
			if (props.properties.partThird.fault) {
				sText = infoCircuit.stdCapacity.toString() + 'kVA';
				sText += sSignMult;
				sText += "100/((";
				sText += sSignRoot;
				sText += "3)";
				sText += doGetTextVoltage3winding(props.properties, VOLT_SIDE_THIRD);
				sText += sSignMult;
				sText += doGetTextTotalPercentZr3winding(props.properties, VOLT_SIDE_THIRD);
				sText += ")";
			}
			else {
				sText = "-";
			}
		}
		break;
	case EKind.MS_TRANSSCOTT:		// スコット結線変圧器
		sText = "-";
		break;
	case EKind.MS_IMPEDANCE:		// インピーダンス
		if (props.properties.fault) {
			switch (nPartKind) {
			case MS_OUTSIDE_OUTSIDE:
			case MS_OUTSIDE_NEUTRAL:
			case MS_SEAT_MAIN:
			case MS_SEAT_T:
				sText = "-";
				break;
			default:
				sText = infoCircuit.stdCapacity.toString() + 'kVA';
				sText += sSignMult;
				sText += "100/(";
				sText += sSignRoot;
				sText += "3";
				sText += sSignMult;
				sText += doGetTextVoltageImpedance(props.properties, MS_3PHASES);
				sText += sSignMult;
				sText += doGetTextTotalPercentZrImpedance(props.properties);
				sText += ")";
				break;
			}
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_BUSDUCT:		// バスダクト
		if (props.properties.fault) {
			switch (nPartKind) {
			case MS_OUTSIDE_OUTSIDE:
			case MS_OUTSIDE_NEUTRAL:
			case MS_SEAT_MAIN:
			case MS_SEAT_T:
				sText = "-";
				break;
			default:
				sText = infoCircuit.stdCapacity.toString() + 'kVA';
				sText += sSignMult;
				sText += "100/(";
				sText += sSignRoot;
				sText += "3";
				sText += sSignMult;
				sText += doGetTextVoltageBusDuct(props.properties, MS_3PHASES);
				sText += sSignMult;
				sText += doGetTextTotalPercentZrBusDuct(props.properties);
				sText += ")";
				break;
			}
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_BUSBAR:			// ブスバー
		if (props.properties.fault) {
			switch (nPartKind) {
			case MS_OUTSIDE_OUTSIDE:
			case MS_OUTSIDE_NEUTRAL:
			case MS_SEAT_MAIN:
			case MS_SEAT_T:
				sText = "-";
				break;
			default:
				sText = infoCircuit.stdCapacity.toString() + 'kVA';
				sText += sSignMult;
				sText += "100/(";
				sText += sSignRoot;
				sText += "3";
				sText += sSignMult;
				sText += doGetTextVoltageBusBar(props.properties, MS_3PHASES);
				sText += sSignMult;
				sText += doGetTextTotalPercentZrBusBar(props.properties);
				sText += ")";
				break;
			}
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_WIRE:			// ワイヤ
		if (props.properties.fault) {
			switch (nPartKind) {
			case MS_OUTSIDE_OUTSIDE:
			case MS_OUTSIDE_NEUTRAL:
			case MS_SEAT_MAIN:
			case MS_SEAT_T:
				sText = "-";
				break;
			default:
				sText = infoCircuit.stdCapacity.toString() + 'kVA';
				sText += sSignMult;
				sText += "100/(";
				sText += sSignRoot;
				sText += "3";
				sText += sSignMult;
				sText += doGetTextVoltageWire(props.properties, MS_3PHASES);
				sText += sSignMult;
				sText += doGetTextTotalPercentZrWire(props.properties);
				sText += ")";
				break;
			}
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_REACTOR:		// 限流リアクトル
		if (props.properties.fault) {
			sText = infoCircuit.stdCapacity.toString() + 'kVA';
			sText += sSignMult;
			sText += "100/(";
			sText += sSignRoot;
			sText += "3";
			sText += sSignMult;
			sText += doGetTextVoltageReactor(props.properties);
			sText += sSignMult;
			sText += doGetTextTotalPercentZrReactor(props.properties);
			sText += ")";
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_HVCB:			// 過電流継電器
		if (props.properties.fault) {
			sText = infoCircuit.stdCapacity.toString() + 'kVA';
			sText += sSignMult;
			sText += "100/(";
			sText += sSignRoot;
			sText += "3";
			sText += sSignMult;
			sText += doGetTextVoltageHVCB(props.properties);
			sText += sSignMult;
			sText += doGetTextTotalPercentZrHVCB(props.properties);
			sText += ")";
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_RELAY51:		// 継電器_51
		// if (props.properties.fault) {
		// 	sText = infoCircuit.stdCapacity.toString() + 'kVA';
		// 	sText += sSignMult;
		// 	sText += "100/(";
		// 	sText += sSignRoot;
		// 	sText += "3";
		// 	sText += sSignMult;
		// 	sText += doGetTextVoltage();
		// 	sText += sSignMult;
		// 	sText += m_pRelay51->DoGetTextTotalPercentZr();
		// 	sText += ")";
		// }
		// else {
		// 	sText = "-";
		// }
		break;
	case EKind.MS_MVCB:			// 遮断器
		if (props.properties.fault) {
			switch (nPartKind) {
			case MS_OUTSIDE_OUTSIDE:
			case MS_OUTSIDE_NEUTRAL:
			case MS_SEAT_MAIN:
			case MS_SEAT_T:
				sText = "-";
				break;
			default:
				sText = infoCircuit.stdCapacity.toString() + 'kVA';
				sText += sSignMult;
				sText += "100/(";
				sText += sSignRoot;
				sText += "3";
				sText += sSignMult;
				sText += doGetTextVoltageMVCB(props.properties, MS_3PHASES);
				sText += sSignMult;
				sText += doGetTextTotalPercentZrMVCB(props.properties);
				sText += ")";
				break;
			}
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_CONTACTOR:		// 電磁接触器
		if (props.properties.fault) {
			switch (nPartKind) {
			case MS_OUTSIDE_OUTSIDE:
			case MS_OUTSIDE_NEUTRAL:
			case MS_SEAT_MAIN:
			case MS_SEAT_T:
				sText = "-";
				break;
			default:
				sText = infoCircuit.stdCapacity.toString() + 'kVA';
				sText += sSignMult;
				sText += "100/(";
				sText += sSignRoot;
				sText += "3";
				sText += sSignMult;
				sText += doGetTextVoltageContactor(props.properties, MS_3PHASES);
				sText += sSignMult;
				sText += doGetTextTotalPercentZrContactor(props.properties);
				sText += ")";
				break;
			}
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_LVCB:			// 低圧遮断器
		if (props.properties.fault) {
			switch (nPartKind) {
			case MS_OUTSIDE_OUTSIDE:
			case MS_OUTSIDE_NEUTRAL:
			case MS_SEAT_MAIN:
			case MS_SEAT_T:
				sText = "-";
				break;
			default:
				sText = infoCircuit.stdCapacity.toString() + 'kVA';
				sText += sSignMult;
				sText += "100/(";
				sText += sSignRoot;
				sText += "3";
				sText += sSignMult;
				sText += doGetTextVoltageLVCB(props.properties, MS_3PHASES);
				sText += sSignMult;
				sText += doGetTextTotalPercentZrLVCB(props.properties);
				sText += ")";
				break;
			}
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_FUSE:			// ヒューズ
		if (props.properties.fault) {
			sText = infoCircuit.stdCapacity.toString() + 'kVA';
			sText += sSignMult;
			sText += "100/(";
			sText += sSignRoot;
			sText += "3";
			sText += sSignMult;
			sText += doGetTextVoltageFUSE(props.properties);
			sText += sSignMult;
			sText += doGetTextTotalPercentZrFUSE(props.properties);
			sText += ")";
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_2E:				// 電動機保護リレー
		if (props.properties.fault) {
			switch (nPartKind) {
			case MS_OUTSIDE_OUTSIDE:
			case MS_OUTSIDE_NEUTRAL:
			case MS_SEAT_MAIN:
			case MS_SEAT_T:
				sText = "-";
				break;
			default:
				sText = infoCircuit.stdCapacity.toString() + 'kVA';
				sText += sSignMult;
				sText += "100/(";
				sText += sSignRoot;
				sText += "3";
				sText += sSignMult;
				sText += doGetTextVoltage2E(props.properties, MS_3PHASES);
				sText += sSignMult;
				sText += doGetTextTotalPercentZr2E(props.properties);
				sText += ")";
				break;
			}
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_THERMAL:		// サーマルリレー
		if (props.properties.fault) {
			switch (nPartKind) {
			case MS_OUTSIDE_OUTSIDE:
			case MS_OUTSIDE_NEUTRAL:
			case MS_SEAT_MAIN:
			case MS_SEAT_T:
				sText = "-";
				break;
			default:
				sText = infoCircuit.stdCapacity.toString() + 'kVA';
				sText += sSignMult;
				sText += "100/(";
				sText += sSignRoot;
				sText += "3";
				sText += sSignMult;
				sText += doGetTextVoltageTHERMAL(props.properties, MS_3PHASES);
				sText += sSignMult;
				sText += doGetTextTotalPercentZrTHERMAL(props.properties);
				sText += ")";
				break;
			}
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_MOTOR:			// 電動機
		break;
	case EKind.MS_MOTOR_GROUP:	// 電動機群
		if (props.properties.fault) {
			sText = infoCircuit.stdCapacity.toString() + 'kVA';
			sText += sSignMult;
			sText += "100/(";
			sText += sSignRoot;
			sText += "3";
			sText += sSignMult;
			sText += doGetTextVoltageMotorGrp(props.properties);
			sText += sSignMult;
			sText += doGetTextTotalPercentZrMotorGrp(props.properties);
			sText += ")";
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_LOAD:			// 負荷
		break;
	case EKind.MS_CAPACITOR:		// コンデンサ
		break;
	case EKind.MS_LOADCENTER:		// ロードセンタ
		break;
	case EKind.MS_ZEROCOND:		// 母線
		if (isPointFaultZeroCond(props.properties)) {
			switch (nPartKind) {
			case MS_OUTSIDE_OUTSIDE:
				sText += infoCircuit.stdCapacity.toString() + 'kVA';
				sText += sSignMult;
				sText += "100/(";
				sText += doGetTextVoltageZeroCond(props.properties, MS_OUTSIDE_OUTSIDE);
				sText += sSignMult;
				sText += doGetTextTotalPercentZrZeroCond(props.properties, MS_OUTSIDE_OUTSIDE);
				sText += ")";
				break;
			case MS_OUTSIDE_NEUTRAL:
				sText += infoCircuit.stdCapacity.toString() + 'kVA';
				sText += sSignMult;
				sText += "100/(";
				sText += doGetTextVoltageZeroCond(props.properties, MS_OUTSIDE_NEUTRAL);
				sText += sSignMult;
				sText += doGetTextTotalPercentZrZeroCond(props.properties, MS_OUTSIDE_NEUTRAL);
				sText += ")";
				break;
			case MS_SEAT_MAIN:
			case MS_SEAT_T:
				sText = "-";
				break;
			default:
				sText += infoCircuit.stdCapacity.toString() + 'kVA';
				sText += sSignMult;
				sText += "100/(";
				sText += sSignRoot;
				sText += "3";
				sText += sSignMult;
				sText += doGetTextVoltageZeroCond(props.properties, MS_3PHASES);
				sText += sSignMult;
				sText += doGetTextTotalPercentZrZeroCond(props.properties, MS_3PHASES);
				sText += ")";
				break;
			}
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_CONNECT:		// 接続線
		break;
	case EKind.MS_TEXT:			// 任意文字列
		break;
	case EKind.MS_DS:				// 断路器
		if (props.properties.fault) {
			sText = infoCircuit.stdCapacity.toString() + 'kVA';
			sText += sSignMult;
			sText += "100/(";
			sText += sSignRoot;
			sText += "3";
			sText += sSignMult;
			sText += doGetTextVoltageDS(props.properties);
			sText += sSignMult;
			sText += doGetTextTotalPercentZrDS(props.properties);
			sText += ")";
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_LBS:			// 開閉器
		if (props.properties.fault) {
			sText = infoCircuit.stdCapacity.toString() + 'kVA';
			sText += sSignMult;
			sText += "100/(";
			sText += sSignRoot;
			sText += "3";
			sText += sSignMult;
			sText += doGetTextVoltageLBS(props.properties);
			sText += sSignMult;
			sText += doGetTextTotalPercentZrLBS(props.properties);
			sText += ")";
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_THERMAL_CT:		// 過負荷継電器
		if (props.properties.fault) {
			switch (nPartKind) {
			case MS_OUTSIDE_OUTSIDE:
			case MS_OUTSIDE_NEUTRAL:
			case MS_SEAT_MAIN:
			case MS_SEAT_T:
				sText = "-";
				break;
			default:
				sText = infoCircuit.stdCapacity.toString() + 'kVA';
				sText += sSignMult;
				sText += "100/(";
				sText += sSignRoot;
				sText += "3";
				sText += sSignMult;
				sText += doGetTextVoltageThermalCT(props.properties, MS_3PHASES);
				sText += sSignMult;
				sText += doGetTextTotalPercentZrThermalCT(props.properties);
				sText += ")";
				break;
			}
		}
		else {
			sText = "-";
		}
		break;
	case EKind.MS_ARRESTOR:		// 避雷器
		break;
	case EKind.MS_EARTH:			// 接地
		break;
	case EKind.MS_CABLEHEAD:		// ケーブルヘッド
		break;
	case EKind.MS_CT:				// 変流器
		break;
	case EKind.MS_ZCT:			// 零相変流器
		break;
	case EKind.MS_VT:				// 計器用変圧器
		break;
	case EKind.MS_VCT:			// 計器用変成器
		break;
	case EKind.MS_INV:			// インバーター
		break;
	case EKind.MS_RELAY:			// リレー
		break;
	case EKind.MS_LIGHTBOARD:		// 分電盤
		break;
	case EKind.MS_POWERBOARD:		// 制御盤
		break;
	case EKind.MS_INTO_POINT:		// 引込口
		break;
	}

	return sText;
}

//CObjElement::DoGetReportFaultDetailComment
export const doGetReportFaultDetailComment = (props:ControlModel, sPointSide: string) =>
{
	let sPrimary = '一次側';
	let sSecondary = '二次側';
	let sThird = '三次側';
	let sOutOut = '外-外';
	let sOutIn = '外-中';
	let sSeatM = '主座';
	let sSeatT = 'T座';

	let sExamineComment = "";

	switch(props.type) {
	case EKind.MS_SOURCE:			// 電源
		sExamineComment = props.properties.examineComment;
		break;
	case EKind.MS_GENERATOR:		// 発電機
		sExamineComment = props.properties.examineComment;
		break;
	case EKind.MS_TRANS1:			// 単相変圧器
		if (sPointSide == sOutOut) {
			sExamineComment = props.properties.partOutOut.examineComment;
		}
		else if (sPointSide == sOutIn) {
			sExamineComment = props.properties.partOutIn.examineComment;
		}
		break;
	case EKind.MS_TRANS3:			// 三相変圧器
		sExamineComment = props.properties.examineComment;
		break;
	case EKind.MS_TRANSCENTER:	// 変台
		if (!props.properties.sPhase) {
			sExamineComment = props.properties.examineComment;
		}
		else {
			if (sPointSide == sOutOut ||
				sPointSide == sSeatT) {
				sExamineComment = props.properties.examineComment;
			}
			else if (sPointSide == sOutIn ||
					sPointSide == sSeatM) {
				sExamineComment = props.properties.examineCommentSPhase;
			}
		}
		break;
	case EKind.MS_3WINDING:		// 三巻線変圧器
		if (sPointSide == sPrimary) {
			sExamineComment = props.properties.partPrimary.examineComment;
		}
		else if (sPointSide == sSecondary) {
			sExamineComment = props.properties.partSecondary.examineComment;
		}
		else if (sPointSide == sThird) {
			sExamineComment = props.properties.partThird.examineComment;
		}
		break;
	case EKind.MS_TRANSSCOTT:		// スコット結線変圧器
		if (sPointSide == sSeatM) {
			sExamineComment = props.properties.partSeatM.examineComment;
		}
		else if (sPointSide == sSeatT) {
			sExamineComment = props.properties.partSeatT.examineComment;
		}
		break;
	case EKind.MS_IMPEDANCE:		// インピーダンス
		if (!props.properties.sPhase ) {
			sExamineComment = props.properties.examineComment;
		}
		else {
			if (sPointSide == sOutOut ||
				sPointSide == sSeatT) {
				sExamineComment = props.properties.examineComment;
			}
			else if (sPointSide == sOutIn ||
					sPointSide == sSeatM) {
				sExamineComment = props.properties.examineCommentSPhase;
			}
		}
		break;
	case EKind.MS_BUSDUCT:		// バスダクト
		if (!props.properties.sPhase) {
			sExamineComment = props.properties.examineComment;
		}
		else {
			if (sPointSide == sOutOut ||
				sPointSide == sSeatT) {
				sExamineComment = props.properties.examineComment;
			}
			else if (sPointSide == sOutIn ||
					sPointSide == sSeatM) {
				sExamineComment = props.properties.examineCommentSPhase;
			}
		}
		break;
	case EKind.MS_BUSBAR:			// ブスバー
		if (!props.properties.sPhase) {
			sExamineComment = props.properties.examineComment;
		}
		else {
			if (sPointSide == sOutOut ||
				sPointSide == sSeatT) {
				sExamineComment = props.properties.examineComment;
			}
			else if (sPointSide == sOutIn ||
					sPointSide == sSeatM) {
				sExamineComment = props.properties.examineCommentSPhase;
			}
		}
		break;
	case EKind.MS_WIRE:			// ワイヤ
		if (!props.properties.sPhase) {
			sExamineComment = props.properties.examineComment;
		}
		else {
			if (sPointSide == sOutOut ||
				sPointSide == sSeatT) {
				sExamineComment = props.properties.examineComment;
			}
			else if (sPointSide == sOutIn ||
					sPointSide == sSeatM) {
				sExamineComment = props.properties.examineCommentSPhase;
			}
		}
		break;
	case EKind.MS_REACTOR:		// 限流リアクトル
		sExamineComment = props.properties.examineComment;
		break;
	case EKind.MS_HVCB:			// 過電流継電器
		sExamineComment = props.properties.examineComment;
		break;
	case EKind.MS_RELAY51:		// 継電器_51
		sExamineComment = props.properties.examineComment;
		break;
	case EKind.MS_MVCB:			// 遮断器
		if (!props.properties.sPhase) {
			sExamineComment = props.properties.examineComment;
		}
		else {
			if (sPointSide == sOutOut ||
				sPointSide == sSeatT) {
				sExamineComment = props.properties.examineComment;
			}
			else if (sPointSide == sOutIn ||
					sPointSide == sSeatM) {
				sExamineComment = props.properties.examineCommentSPhase;
			}
		}
		break;
	case EKind.MS_CONTACTOR:		// 電磁接触器
		if (!props.properties.sPhase) {
			sExamineComment = props.properties.examineComment;
		}
		else {
			if (sPointSide == sOutOut ||
				sPointSide == sSeatT) {
				sExamineComment = props.properties.examineComment;
			}
			else if (sPointSide == sOutIn ||
					sPointSide == sSeatM) {
				sExamineComment = props.properties.examineCommentSPhase;
			}
		}
		break;
	case EKind.MS_LVCB:			// 低圧遮断器
		if (!props.properties.sPhase) {
			sExamineComment = props.properties.examineComment;
		}
		else {
			if (sPointSide == sOutOut ||
				sPointSide == sSeatT) {
				sExamineComment = props.properties.examineComment;
			}
			else if (sPointSide == sOutIn ||
					sPointSide == sSeatM) {
				sExamineComment = props.properties.examineCommentSPhase;
			}
		}
		break;
	case EKind.MS_FUSE:			// ヒューズ
		sExamineComment = props.properties.examineComment;
		break;
	case EKind.MS_2E:				// 電動機保護リレー
		if (!props.properties.sPhase) {
			sExamineComment = props.properties.examineComment;
		}
		else {
			if (sPointSide == sOutOut ||
				sPointSide == sSeatT) {
				sExamineComment = props.properties.examineComment;
			}
			else if (sPointSide == sOutIn ||
					sPointSide == sSeatM) {
				sExamineComment = props.properties.examineCommentSPhase;
			}
		}
		break;
	case EKind.MS_THERMAL:		// サーマルリレー
		if (!props.properties.sPhase) {
			sExamineComment = props.properties.examineComment;
		}
		else {
			if (sPointSide == sOutOut ||
				sPointSide == sSeatT) {
				sExamineComment = props.properties.examineComment;
			}
			else if (sPointSide == sOutIn ||
					sPointSide == sSeatM) {
				sExamineComment = props.properties.examineCommentSPhase;
			}
		}
		break;
	case EKind.MS_MOTOR:			// 電動機
		break;
	case EKind.MS_MOTOR_GROUP:	// 電動機群
		sExamineComment = props.properties.examineComment;
		break;
	case EKind.MS_LOAD:			// 負荷
		break;
	case EKind.MS_CAPACITOR:		// コンデンサ
		break;
	case EKind.MS_LOADCENTER:		// ロードセンタ
		break;
	case EKind.MS_ZEROCOND:		// 母線
		if (!props.properties.sPhase) {
			sExamineComment = props.properties.examineComment;
		}
		else {
			if (sPointSide == sOutOut ||
				sPointSide == sSeatT) {
				sExamineComment = props.properties.examineComment;
			}
			else if (sPointSide == sOutIn ||
					sPointSide == sSeatM) {
				sExamineComment = props.properties.examineCommentSPhase;
			}
		}
		break;
	case EKind.MS_CONNECT:		// 接続線
		break;
	case EKind.MS_TEXT:			// 任意文字列
		break;
	case EKind.MS_DS:				// 断路器
		sExamineComment = props.properties.examineComment;
		break;
	case EKind.MS_LBS:			// 開閉器
		sExamineComment = props.properties.examineComment;
		break;
	case EKind.MS_THERMAL_CT:		// 過負荷継電器
		if (!props.properties.sPhase) {
			sExamineComment = props.properties.examineComment;
		}
		else {
			if (sPointSide == sOutOut ||
				sPointSide == sSeatT) {
				sExamineComment = props.properties.examineComment;
			}
			else if (sPointSide == sOutIn ||
					sPointSide == sSeatM) {
				sExamineComment = props.properties.examineCommentSPhase;
			}
		}
		break;
	case EKind.MS_ARRESTOR:		// 避雷器
		break;
	case EKind.MS_EARTH:			// 接地
		break;
	case EKind.MS_CABLEHEAD:		// ケーブルヘッド
		break;
	case EKind.MS_CT:				// 変流器
		break;
	case EKind.MS_ZCT:			// 零相変流器
		break;
	case EKind.MS_VT:				// 計器用変圧器
		break;
	case EKind.MS_VCT:			// 計器用変成器
		break;
	case EKind.MS_INV:			// インバーター
		break;
	case EKind.MS_RELAY:			// リレー
		break;
	case EKind.MS_LIGHTBOARD:		// 分電盤
		break;
	case EKind.MS_POWERBOARD:		// 制御盤
		break;
	}

	return sExamineComment;
}

export const doGetReportDetailCommentToSave = (props:ControlModel, examineText:string, sPointSide: string, sPointText:string) =>
{
	let sPrimary = '一次側';
	let sSecondary = '二次側';
	let sThird = '三次側';
	let sOutOut = '外-外';
	let sOutIn = '外-中';
	let sSeatM = '主座';
	let sSeatT = 'T座';

	switch(props.type) {
	case EKind.MS_SOURCE:			// 電源
		props.properties.examineComment = examineText;
		props.properties.pointText = sPointText;
		break;
	case EKind.MS_GENERATOR:		// 発電機
		props.properties.examineComment = examineText;
		props.properties.pointText = sPointText;
		break;
	case EKind.MS_TRANS1:			// 単相変圧器
		if (sPointSide == sOutOut) {
			props.properties.partOutOut.examineComment = examineText;
			props.properties.partOutOut.pointText = sPointText;
		}
		else if (sPointSide == sOutIn) {
			props.properties.partOutIn.examineComment = examineText;
			props.properties.partOutIn.pointText = sPointText;
		}
		break;
	case EKind.MS_TRANS3:			// 三相変圧器
		props.properties.examineComment = examineText;
		props.properties.pointText = sPointText;
		break;
	case EKind.MS_TRANSCENTER:	// 変台
		if (!props.properties.sPhase) {
			props.properties.examineComment = examineText;
			props.properties.pointText = sPointText;
		}
		else {
			if (sPointSide == sOutOut ||
				sPointSide == sSeatT) {
				props.properties.examineComment = examineText;
				props.properties.pointText = sPointText;
			}
			else if (sPointSide == sOutIn ||
					sPointSide == sSeatM) {
				props.properties.examineCommentSPhase = examineText;
				props.properties.pointText = sPointText;
			}
		}
		break;
	case EKind.MS_3WINDING:		// 三巻線変圧器
		if (sPointSide == sPrimary) {
			props.properties.partPrimary.examineComment = examineText;
			props.properties.partPrimary.pointText = sPointText;
		}
		else if (sPointSide == sSecondary) {
			props.properties.partSecondary.examineComment = examineText;
			props.properties.partSecondary.pointText = sPointText;
		}
		else if (sPointSide == sThird) {
			props.properties.partThird.examineComment = examineText;
			props.properties.partThird.pointText = sPointText;
		}
		break;
	case EKind.MS_TRANSSCOTT:		// スコット結線変圧器
		if (sPointSide == sSeatM) {
			props.properties.partSeatM.examineComment = examineText;
			props.properties.partSeatM.pointText = sPointText;
		}
		else if (sPointSide == sSeatT) {
			props.properties.partSeatT.examineComment = examineText;
			props.properties.partSeatT.pointText = sPointText;
		}
		break;
	case EKind.MS_IMPEDANCE:		// インピーダンス
		if (!props.properties.sPhase ) {
			props.properties.examineComment = examineText;
			props.properties.pointText = sPointText;
		}
		else {
			if (sPointSide == sOutOut ||
				sPointSide == sSeatT) {
				props.properties.examineComment = examineText;
				props.properties.pointText = sPointText;
			}
			else if (sPointSide == sOutIn ||
					sPointSide == sSeatM) {
				props.properties.examineCommentSPhase = examineText;
				props.properties.pointText = sPointText;
			}
		}
		break;
	case EKind.MS_BUSDUCT:		// バスダクト
		if (!props.properties.sPhase) {
			props.properties.examineComment = examineText;
			props.properties.pointText = sPointText;
		}
		else {
			if (sPointSide == sOutOut ||
				sPointSide == sSeatT) {
				props.properties.examineComment = examineText;
				props.properties.pointText = sPointText;
			}
			else if (sPointSide == sOutIn ||
					sPointSide == sSeatM) {
				props.properties.examineCommentSPhase = examineText;
				props.properties.pointText = sPointText;
			}
		}
		break;
	case EKind.MS_BUSBAR:			// ブスバー
		if (!props.properties.sPhase) {
			props.properties.examineComment = examineText;
			props.properties.pointText = sPointText;
		}
		else {
			if (sPointSide == sOutOut ||
				sPointSide == sSeatT) {
				props.properties.examineComment = examineText;
				props.properties.pointText = sPointText;
			}
			else if (sPointSide == sOutIn ||
					sPointSide == sSeatM) {
				props.properties.examineCommentSPhase = examineText;
				props.properties.pointText = sPointText;
			}
		}
		break;
	case EKind.MS_WIRE:			// ワイヤ
		if (!props.properties.sPhase) {
			props.properties.examineComment = examineText;
			props.properties.pointText = sPointText;
		}
		else {
			if (sPointSide == sOutOut ||
				sPointSide == sSeatT) {
				props.properties.examineComment = examineText;
				props.properties.pointText = sPointText;
			}
			else if (sPointSide == sOutIn ||
					sPointSide == sSeatM) {
				props.properties.examineCommentSPhase = examineText;
				props.properties.pointText = sPointText;
			}
		}
		break;
	case EKind.MS_REACTOR:		// 限流リアクトル
		props.properties.examineComment = examineText;
		props.properties.pointText = sPointText;
		break;
	case EKind.MS_HVCB:			// 過電流継電器
		props.properties.examineComment = examineText;
		props.properties.pointText = sPointText;
		break;
	case EKind.MS_RELAY51:		// 継電器_51
		props.properties.examineComment = examineText;
		props.properties.pointText = sPointText;
		break;
	case EKind.MS_MVCB:			// 遮断器
		if (!props.properties.sPhase) {
			props.properties.examineComment = examineText;
			props.properties.pointText = sPointText;
		}
		else {
			if (sPointSide == sOutOut ||
				sPointSide == sSeatT) {
				props.properties.examineComment = examineText;
				props.properties.pointText = sPointText;
			}
			else if (sPointSide == sOutIn ||
					sPointSide == sSeatM) {
				props.properties.examineCommentSPhase = examineText;
				props.properties.pointText = sPointText;
			}
		}
		break;
	case EKind.MS_CONTACTOR:		// 電磁接触器
		if (!props.properties.sPhase) {
			props.properties.examineComment = examineText;
			props.properties.pointText = sPointText;
		}
		else {
			if (sPointSide == sOutOut ||
				sPointSide == sSeatT) {
				props.properties.examineComment = examineText;
				props.properties.pointText = sPointText;
			}
			else if (sPointSide == sOutIn ||
					sPointSide == sSeatM) {
				props.properties.examineCommentSPhase = examineText;
				props.properties.pointText = sPointText;
			}
		}
		break;
	case EKind.MS_LVCB:			// 低圧遮断器
		if (!props.properties.sPhase) {
			props.properties.examineComment = examineText;
			props.properties.pointText = sPointText;
		}
		else {
			if (sPointSide == sOutOut ||
				sPointSide == sSeatT) {
				props.properties.examineComment = examineText;
				props.properties.pointText = sPointText;
			}
			else if (sPointSide == sOutIn ||
					sPointSide == sSeatM) {
				props.properties.examineCommentSPhase = examineText;
				props.properties.pointText = sPointText;
			}
		}
		break;
	case EKind.MS_FUSE:			// ヒューズ
		props.properties.examineComment = examineText;
		props.properties.pointText = sPointText;
		break;
	case EKind.MS_2E:				// 電動機保護リレー
		if (!props.properties.sPhase) {
			props.properties.examineComment = examineText;
			props.properties.pointText = sPointText;
		}
		else {
			if (sPointSide == sOutOut ||
				sPointSide == sSeatT) {
				props.properties.examineComment = examineText;
				props.properties.pointText = sPointText;
			}
			else if (sPointSide == sOutIn ||
					sPointSide == sSeatM) {
				props.properties.examineCommentSPhase = examineText;
				props.properties.pointText = sPointText;
			}
		}
		break;
	case EKind.MS_THERMAL:		// サーマルリレー
		if (!props.properties.sPhase) {
			props.properties.examineComment = examineText;
			props.properties.pointText = sPointText;
		}
		else {
			if (sPointSide == sOutOut ||
				sPointSide == sSeatT) {
				props.properties.examineComment = examineText;
				props.properties.pointText = sPointText;
			}
			else if (sPointSide == sOutIn ||
					sPointSide == sSeatM) {
				props.properties.examineCommentSPhase = examineText;
				props.properties.pointText = sPointText;
			}
		}
		break;
	case EKind.MS_MOTOR:			// 電動機
		break;
	case EKind.MS_MOTOR_GROUP:	// 電動機群
		props.properties.examineComment = examineText;
		props.properties.pointText = sPointText;
		break;
	case EKind.MS_LOAD:			// 負荷
		break;
	case EKind.MS_CAPACITOR:		// コンデンサ
		break;
	case EKind.MS_LOADCENTER:		// ロードセンタ
		break;
	case EKind.MS_ZEROCOND:		// 母線
		if (!props.properties.sPhase) {
			props.properties.examineComment = examineText;
			props.properties.pointText = sPointText;
		}
		else {
			if (sPointSide == sOutOut ||
				sPointSide == sSeatT) {
				props.properties.examineComment = examineText;
				props.properties.pointText = sPointText;
			}
			else if (sPointSide == sOutIn ||
					sPointSide == sSeatM) {
				props.properties.examineCommentSPhase = examineText;
				props.properties.pointText = sPointText;
			}
		}
		break;
	case EKind.MS_CONNECT:		// 接続線
		break;
	case EKind.MS_TEXT:			// 任意文字列
		break;
	case EKind.MS_DS:				// 断路器
		props.properties.examineComment = examineText;
		props.properties.pointText = sPointText;
		break;
	case EKind.MS_LBS:			// 開閉器
		props.properties.examineComment = examineText;
		props.properties.pointText = sPointText;
		break;
	case EKind.MS_THERMAL_CT:		// 過負荷継電器
		if (!props.properties.sPhase) {
			props.properties.examineComment = examineText;
			props.properties.pointText = sPointText;
		}
		else {
			if (sPointSide == sOutOut ||
				sPointSide == sSeatT) {
				props.properties.examineComment = examineText;
				props.properties.pointText = sPointText;
			}
			else if (sPointSide == sOutIn ||
					sPointSide == sSeatM) {
				props.properties.examineCommentSPhase = examineText;
				props.properties.pointText = sPointText;
			}
		}
		break;
	case EKind.MS_ARRESTOR:		// 避雷器
		break;
	case EKind.MS_EARTH:			// 接地
		break;
	case EKind.MS_CABLEHEAD:		// ケーブルヘッド
		break;
	case EKind.MS_CT:				// 変流器
		break;
	case EKind.MS_ZCT:			// 零相変流器
		break;
	case EKind.MS_VT:				// 計器用変圧器
		break;
	case EKind.MS_VCT:			// 計器用変成器
		break;
	case EKind.MS_INV:			// インバーター
		break;
	case EKind.MS_RELAY:			// リレー
		break;
	case EKind.MS_LIGHTBOARD:		// 分電盤
		break;
	case EKind.MS_POWERBOARD:		// 制御盤
		break;
	}

	return props;
}

// CObjElement::IsZeroResultFlowEle
export const isZeroResultFlowEle = (props:ControlModel) =>
{
	let bFlowZero = false;

	switch(props.type) {
	case EKind.MS_SOURCE:			// 電源
		if (props.properties.activePower == 0 &&
			props.properties.reactivePower == 0) {
			bFlowZero = true;
		}
		break;
	case EKind.MS_GENERATOR:		// 発電機
		if (props.properties.activePower == 0 &&
			props.properties.reactivePower == 0) {
			bFlowZero = true;
		}
		break;
	case EKind.MS_TRANS3:			// 三相変圧器
		if (props.properties.lineActiveFlow1 == 0 &&
			props.properties.lineReactiveFlow1 == 0 &&
			props.properties.lineActiveFlow2 == 0 &&
			props.properties.lineReactiveFlow2 == 0) {
			bFlowZero = true;
		}
		break;
	case EKind.MS_TRANSCENTER:	// 変台
		if (props.properties.activePower == 0 &&
			props.properties.reactivePower == 0) {
			bFlowZero = true;
		}
		break;
	case EKind.MS_3WINDING:		// 三巻線変圧器
		if (props.properties.partPrimary.lineActiveFlow1 == 0 &&
			props.properties.partPrimary.lineActiveFlow2 == 0 &&
			props.properties.partPrimary.lineReactiveFlow1 == 0 &&
			props.properties.partPrimary.lineReactiveFlow2 == 0 &&
			props.properties.partSecondary.lineActiveFlow1 == 0 &&
			props.properties.partSecondary.lineActiveFlow2 == 0 &&
			props.properties.partSecondary.lineReactiveFlow1 == 0 &&
			props.properties.partSecondary.lineReactiveFlow2 == 0 &&
			props.properties.partThird.lineActiveFlow1 == 0 &&
			props.properties.partThird.lineActiveFlow2 == 0 &&
			props.properties.partThird.lineReactiveFlow1 == 0 &&
			props.properties.partThird.lineReactiveFlow2 == 0) {
			bFlowZero = true;
		}
		break;
	case EKind.MS_TRANS1:			// 単相変圧器
		if (props.properties.activePower == 0 &&
			props.properties.reactivePower == 0) {
			bFlowZero = true;
		}
		break;
	case EKind.MS_TRANSSCOTT:		// スコット結線変圧器
		if (props.properties.activePower == 0 &&
			props.properties.reactivePower == 0) {
			bFlowZero = true;
		}
		break;
	case EKind.MS_DS:				// 断路器
		if (props.properties.lineActiveFlow1 == 0 &&
			props.properties.lineReactiveFlow1 == 0 &&
			props.properties.lineActiveFlow2 == 0 &&
			props.properties.lineReactiveFlow2 == 0) {
			bFlowZero = true;
		}
		break;
	case EKind.MS_LBS:			// 開閉器
		if (props.properties.lineActiveFlow1 == 0 &&
			props.properties.lineReactiveFlow1 == 0 &&
			props.properties.lineActiveFlow2 == 0 &&
			props.properties.lineReactiveFlow2 == 0) {
			bFlowZero = true;
		}
		break;
	case EKind.MS_MVCB:			// 遮断器
		if (props.properties.lineActiveFlow1 == 0 &&
			props.properties.lineReactiveFlow1 == 0 &&
			props.properties.lineActiveFlow2 == 0 &&
			props.properties.lineReactiveFlow2 == 0) {
			bFlowZero = true;
		}
		break;
	case EKind.MS_CONTACTOR:		// 電磁接触器
		if (props.properties.lineActiveFlow1 == 0 &&
			props.properties.lineReactiveFlow1 == 0 &&
			props.properties.lineActiveFlow2 == 0 &&
			props.properties.lineReactiveFlow2 == 0) {
			bFlowZero = true;
		}
		break;
	case EKind.MS_HVCB:			// 過電流継電器
		if (props.properties.lineActiveFlow1 == 0 &&
			props.properties.lineReactiveFlow1 == 0 &&
			props.properties.lineActiveFlow2 == 0 &&
			props.properties.lineReactiveFlow2 == 0) {
			bFlowZero = true;
		}
		break;
	case EKind.MS_RELAY51:		// 継電器_51
		if (props.properties.lineActiveFlow1 == 0 &&
			props.properties.lineReactiveFlow1 == 0 &&
			props.properties.lineActiveFlow2 == 0 &&
			props.properties.lineReactiveFlow2 == 0) {
			bFlowZero = true;
		}
		break;
	case EKind.MS_FUSE:			// ヒューズ
		if (props.properties.lineActiveFlow1 == 0 &&
			props.properties.lineReactiveFlow1 == 0 &&
			props.properties.lineActiveFlow2 == 0 &&
			props.properties.lineReactiveFlow2 == 0) {
			bFlowZero = true;
		}
		break;
	case EKind.MS_2E:				// 電動機保護リレー
		if (props.properties.lineActiveFlow1 == 0 &&
			props.properties.lineReactiveFlow1 == 0 &&
			props.properties.lineActiveFlow2 == 0 &&
			props.properties.lineReactiveFlow2 == 0) {
			bFlowZero = true;
		}
		break;
	case EKind.MS_ZEROCOND:		// 母線
		if (props.properties.activePower == 0 &&
			props.properties.reactivePower == 0) {
			bFlowZero = true;
		}
		break;
	case EKind.MS_CONNECT:		// 接続線
		break;
	case EKind.MS_WIRE:			// ワイヤ
		if (props.properties.lineActiveFlow1 == 0 &&
			props.properties.lineReactiveFlow1 == 0 &&
			props.properties.lineActiveFlow2 == 0 &&
			props.properties.lineReactiveFlow2 == 0) {
			bFlowZero = true;
		}
		break;
	case EKind.MS_BUSBAR:			// ブスバー
		if (props.properties.lineActiveFlow1 == 0 &&
			props.properties.lineReactiveFlow1 == 0 &&
			props.properties.lineActiveFlow2 == 0 &&
			props.properties.lineReactiveFlow2 == 0) {
			bFlowZero = true;
		}
		break;
	case EKind.MS_IMPEDANCE:		// インピーダンス
		if (props.properties.lineActiveFlow1 == 0 &&
			props.properties.lineReactiveFlow1 == 0 &&
			props.properties.lineActiveFlow2 == 0 &&
			props.properties.lineReactiveFlow2 == 0) {
			bFlowZero = true;
		}
		break;
	case EKind.MS_BUSDUCT:		// バスダクト
		if (props.properties.lineActiveFlow1 == 0 &&
			props.properties.lineReactiveFlow1 == 0 &&
			props.properties.lineActiveFlow2 == 0 &&
			props.properties.lineReactiveFlow2 == 0) {
			bFlowZero = true;
		}
		break;
	case EKind.MS_REACTOR:		// 限流リアクトル
		if (props.properties.lineActiveFlow1 == 0 &&
			props.properties.lineReactiveFlow1 == 0 &&
			props.properties.lineActiveFlow2 == 0 &&
			props.properties.lineReactiveFlow2 == 0) {
			bFlowZero = true;
		}
		break;
	case EKind.MS_LVCB:			// 低圧遮断器
		if (props.properties.lineActiveFlow1 == 0 &&
			props.properties.lineReactiveFlow1 == 0 &&
			props.properties.lineActiveFlow2 == 0 &&
			props.properties.lineReactiveFlow2 == 0) {
			bFlowZero = true;
		}
		break;
	case EKind.MS_THERMAL:		// サーマルリレー
		if (props.properties.lineActiveFlow1 == 0 &&
			props.properties.lineReactiveFlow1 == 0 &&
			props.properties.lineActiveFlow2 == 0 &&
			props.properties.lineReactiveFlow2 == 0) {
			bFlowZero = true;
		}
		break;
	case EKind.MS_THERMAL_CT:		// 過負荷継電器
		if (props.properties.lineActiveFlow1 == 0 &&
			props.properties.lineReactiveFlow1 == 0 &&
			props.properties.lineActiveFlow2 == 0 &&
			props.properties.lineReactiveFlow2 == 0) {
			bFlowZero = true;
		}
		break;
	case EKind.MS_MOTOR_GROUP:	// 電動機群
		if (props.properties.activePower == 0 &&
			props.properties.reactivePower == 0) {
			bFlowZero = true;
		}
		break;
	case EKind.MS_MOTOR:			// 電動機
		if (props.properties.activePower == 0 &&
			props.properties.reactivePower == 0) {
			bFlowZero = true;
		}
		break;
	case EKind.MS_LOAD:			// 負荷
		if (props.properties.activePower == 0 &&
			props.properties.reactivePower == 0) {
			bFlowZero = true;
		}
		break;
	case EKind.MS_CAPACITOR:		// コンデンサ
		if (props.properties.activePower == 0 &&
			props.properties.reactivePower == 0) {
			bFlowZero = true;
		}
		break;
	case EKind.MS_LOADCENTER:		// ロードセンタ
		break;
	case EKind.MS_VCT:			// 計器用変成器
		if (props.properties.lineActiveFlow1 == 0 &&
			props.properties.lineReactiveFlow1 == 0 &&
			props.properties.lineActiveFlow2 == 0 &&
			props.properties.lineReactiveFlow2 == 0) {
			bFlowZero = true;
		}
		break;
	case EKind.MS_INV:			// インバーター
		if (props.properties.lineActiveFlow1 == 0 &&
			props.properties.lineReactiveFlow1 == 0 &&
			props.properties.lineActiveFlow2 == 0 &&
			props.properties.lineReactiveFlow2 == 0) {
			bFlowZero = true;
		}
		break;
	case EKind.MS_RELAY:			// リレー
		if (props.properties.lineActiveFlow1 == 0 &&
			props.properties.lineReactiveFlow1 == 0 &&
			props.properties.lineActiveFlow2 == 0 &&
			props.properties.lineReactiveFlow2 == 0) {
			bFlowZero = true;
		}
		break;
	case EKind.MS_ARRESTOR:		// 避雷器
		break;
	case EKind.MS_EARTH:			// 接地
		break;
	case EKind.MS_VT:				// 計器用変圧器
		if (props.properties.lineActiveFlow1 == 0 &&
			props.properties.lineReactiveFlow1 == 0 &&
			props.properties.lineActiveFlow2 == 0 &&
			props.properties.lineReactiveFlow2 == 0) {
			bFlowZero = true;
		}
		break;
	case EKind.MS_CABLEHEAD:		// ケーブルヘッド
		break;
	case EKind.MS_CT:				// 変流器
		if (props.properties.lineActiveFlow1 == 0 &&
			props.properties.lineReactiveFlow1 == 0 &&
			props.properties.lineActiveFlow2 == 0 &&
			props.properties.lineReactiveFlow2 == 0) {
			bFlowZero = true;
		}
		break;
	case EKind.MS_ZCT:			// 零相変流器
		if (props.properties.lineActiveFlow1 == 0 &&
			props.properties.lineReactiveFlow1 == 0 &&
			props.properties.lineActiveFlow2 == 0 &&
			props.properties.lineReactiveFlow2 == 0) {
			bFlowZero = true;
		}
		break;
	case EKind.MS_TEXT:			// 任意文字列
		break;
	case EKind.MS_LIGHTBOARD:		// 分電盤
		if (props.properties.activePower == 0 &&
			props.properties.reactivePower == 0) {
			bFlowZero = true;
		}
		break;
	case EKind.MS_POWERBOARD:		// 制御盤
		if (props.properties.activePower == 0 &&
			props.properties.reactivePower == 0) {
			bFlowZero = true;
		}
		break;
	case EKind.MS_INTO_POINT:		// 引込口
		if (props.properties.activePower == 0 &&
			props.properties.reactivePower == 0) {
			bFlowZero = true;
		}
		break;
	}
	return bFlowZero;
}

export const doGetRectSymbol = (control: ControlModel) => {
	let rectBase = {top: 0, left: 0, right: 0, bottom: 0}
	const checkType = [
		EKind.MS_SOURCE,
		EKind.MS_GENERATOR,
		EKind.MS_TRANS1,
		EKind.MS_TRANS3,
		EKind.MS_TRANSCENTER,
		EKind.MS_3WINDING,
		EKind.MS_TRANSSCOTT,
		EKind.MS_IMPEDANCE,
		EKind.MS_BUSDUCT,
		EKind.MS_BUSBAR,
		EKind.MS_WIRE,
		EKind.MS_REACTOR,
		EKind.MS_HVCB,
		EKind.MS_RELAY51,
		EKind.MS_MVCB,
		EKind.MS_CONTACTOR,
		EKind.MS_LVCB,
		EKind.MS_FUSE,
		EKind.MS_2E,
		EKind.MS_THERMAL,
		EKind.MS_MOTOR,
		EKind.MS_MOTOR_GROUP,
		EKind.MS_LOAD,
		EKind.MS_CAPACITOR,
		EKind.MS_LOADCENTER,
		EKind.MS_ZEROCOND,
		EKind.MS_CONNECT,
		EKind.MS_TEXT,
		EKind.MS_DS,
		EKind.MS_LBS,
		EKind.MS_THERMAL_CT,
		EKind.MS_ARRESTOR,
		EKind.MS_EARTH,
		EKind.MS_CABLEHEAD,
		EKind.MS_CT,
		EKind.MS_ZCT,
		EKind.MS_VT,
		EKind.MS_VCT,
		EKind.MS_INV,
		EKind.MS_RELAY,
		EKind.MS_LIGHTBOARD,
		EKind.MS_POWERBOARD,
		EKind.MS_INTO_POINT
	]
	if (checkType.includes(control.type)){
		rectBase = getRectBaseLP(control)
	}
	return rectBase
}

export const isCanConnectBottom = (control: ControlModel) => {
	let ret = false
	const typeFalse = [
		EKind.MS_TRANSCENTER,
		EKind.MS_3WINDING,
		EKind.MS_MOTOR,
		EKind.MS_MOTOR_GROUP,
		EKind.MS_LOAD,
		EKind.MS_CAPACITOR,
		EKind.MS_LOADCENTER,
		EKind.MS_TEXT,
		EKind.MS_EARTH,
		EKind.MS_LIGHTBOARD,
		EKind.MS_POWERBOARD
	]
	if (!typeFalse.includes(control.type)){
		ret = control.rotation == 0
	}
	return ret
}

export const isCanConnectTop = (control: ControlModel) => {
	let ret = false
	const typeFalse = [
		EKind.MS_SOURCE,
		EKind.MS_GENERATOR,
		EKind.MS_TEXT,
		EKind.MS_INTO_POINT
	]
	if (!typeFalse.includes(control.type)){
		ret = control.rotation == 0
	}
	return ret
}