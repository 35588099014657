import { ControlModel } from "../../models/Index";
import {
  BOTTOM_CENTER,
  DXF_RECT_BOTTOM,
  DXF_RECT_LEFT,
  DXF_RECT_RIGHT,
  DXF_RECT_TOP,
  IDS_STS_NO_DATA,
  LEFT_CENTER,
  NAME_DXF_GRAPH,
  NAME_DXF_GRAPH_H,
  NAME_DXF_GRAPH_L,
  NAME_DXF_SKELETON,
  MS_FRAME_HEIGHT,
  MS_FRAME_WIDTH,
  RIGHT_CENTER,
  TOP_CENTER,
  DIRECT_NONE,
  MS_DIF_WIDTH,
  MS_SYMBOL_SIZE,
  MS_ROTATE_NORMAL,
  MS_BASE_HEIGHT,
  MS_BASE_WIDTH,
  MS_DIF_HEIGHT,
  IMPEDANCE_INPUT_PERCENT,
  MS_XR_MAX,
} from "../../models/Constants";
import {
  FLOW_VIEW_EVERY_ELEMENT,
  FLOW_VIEW_ZEROCOND_ONLY,
  MS_MODE_IMPROVE_PF,
  MS_MODE_PERCENT_Z,
  MS_MODE_POWER_FLOW,
  VOLT_SIDE_PRIMARY,
  VOLT_SIDE_SECONDARY
} from "../../statics";
import * as EKind from "../../models/ElementKind";
import { DLLGetTextByBeams, addInfoDisplayLocation, getCenterPoint, getDeflateRect, getRectBaseLP, getRectTotal, getSizeRectTotal } from "./dataConverter";
import { exportDXF } from "../../utils/downloadFile";
import { doGetTextOpeMode } from "../../utils/mssv3Doc";
import { doGetConnectElement } from "../../utils/ElementList";
import { doGetRectSymbol, isCanConnectBottom, isCanConnectTop, isCanSetViewFlowResult, isSPhaseCircuitEle } from "../../utils/element";
import { doGetRefV1 as doGetRefV1Source, doGetTextFlowsResult as doGetTextFlowsResultSource} from "../../utils/source";
import { doGetRefV1 as doGetRefV1Generator, doGetTextFlowsResult as doGetTextFlowsResultGenerator} from "../../utils/generatorFunction";
import { doGetRefV1 as doGetRefV1Trans3, doGetTextFlowsResult as doGetTextFlowsResultTrans3} from "../../utils/Trans3Function";
import { doGetRefV1 as doGetRefV1TC, doGetTextFlowsResult as doGetTextFlowsResultTC} from "../../utils/TransCenterFunction";
import { doGetRefV11 as doGetRefV113Winding, doGetRefV13 as doGetRefV133Winding} from "../../utils/3windingFunction";
import { doGetRefV1 as doGetRefV1Trans1} from "../../utils/Trans1Function";
import { doGetRefV1 as doGetRefV1TransScott} from "../../utils/transscottFunction";
import { doGetRefV1 as doGetRefV1DS, doGetTextFlowsResult as doGetTextFlowsResultDS} from "../../utils/DSFunction";
import { doGetRefV1 as doGetRefV1LBS, doGetTextFlowsResult as doGetTextFlowsResultLBS} from "../../utils/LBSFunction";
import { doGetRefV1 as doGetRefV1MVCB, doGetTextFlowsResult as doGetTextFlowsResultMVCB} from "../../utils/MVCBFunction";
import { doGetRefV1 as doGetRefV1Contactor} from "../../utils/ContactorFunction";
import { doGetRefV1 as doGetRefV1HVCB, doGetTextFlowsResult as doGetTextFlowsResultHVCB} from "../../utils/HVCBFunction";
import { doGetRefV1 as doGetRefV1Fuse, doGetTextFlowsResult as doGetTextFlowsResultFuse} from "../../utils/FUSEFunction";
import { doGetRefV1 as doGetRefV12E, doGetTextFlowsResult as doGetTextFlowsResult2E} from "../../utils/2EFunction";
import { doGetRefV1 as doGetRefV1ZeroCond} from "../../utils/ZeroCondFunction";
import { doGetRefV1 as doGetRefV1Wire, doGetTextFlowsResult as doGetTextFlowsResultWire} from "../../utils/wireFunction";
import { doGetRefV1 as doGetRefV1BusBar, doGetTextFlowsResult as doGetTextFlowsResultBusBar} from "../../utils/busbarFunction";
import { doGetRefV1 as doGetRefV1Impedance, doGetTextFlowsResult as doGetTextFlowsResultImpedance} from "../../utils/impedance";
import { doGetRefV1 as doGetRefV1BusDuct, doGetTextFlowsResult as doGetTextFlowsResultBusDuct} from "../../utils/busductFunction";
import { doGetRefV1 as doGetRefV1Reactor, doGetTextFlowsResult as doGetTextFlowsResultReactor} from "../../utils/reactorFunction";
import { doGetRefV1 as doGetRefV1LVCB, doGetTextFlowsResult as doGetTextFlowsResultLVCB} from "../../utils/LVCBFunction";
import { doGetRefV1 as doGetRefV1Thermal, doGetTextFlowsResult as doGetTextFlowsResultThermal} from "../../utils/THERMALFunction";
import { doGetRefV1 as doGetRefV1ThermalCT, doGetTextFlowsResult as doGetTextFlowsResultThermalCT} from "../../utils/ThermalCTFunction";
import { doGetRefV1 as doGetRefV1MotorGrp, doGetTextFlowsResult as doGetTextFlowsResultMotorGrp} from "../../utils/MotorGrpFunction";
import { doGetRefV1 as doGetRefV1Motor, doGetTextFlowsResult as doGetTextFlowsResultMotor} from "../../utils/motorFunction";
import { doGetRefV1 as doGetRefV1Load, doGetTextFlowsResult as doGetTextFlowsResultLoad} from "../../utils/LoadFunction";
import { doGetRefV1 as doGetRefV1Capacitor, doGetTextFlowsResult as doGetTextFlowsResultCapacitor} from "../../utils/capacitorFunction";
import { doGetRefV1 as doGetRefV1VCT, doGetTextFlowsResult as doGetTextFlowsResultVCT} from "../../utils/VCTFunction";
import { doGetRefV1 as doGetRefV1INV, doGetTextFlowsResult as doGetTextFlowsResultINV} from "../../utils/INVFunction";
import { doGetRefV1 as doGetRefV1RELAY, doGetTextFlowsResult as doGetTextFlowsResultRELAY} from "../../utils/RELAYFunction";
import { doGetRefV1 as doGetRefV1Arrestor} from "../../utils/ArrestorFunction";
import { doGetRefV1 as doGetRefV1Earth} from "../../utils/EARTHFunction";
import { doGetRefV1 as doGetRefV1VT, doGetTextFlowsResult as doGetTextFlowsResultVT} from "../../utils/VTFunction";
import { doGetRefV1 as doGetRefV1CableHead} from "../../utils/CABLEHEADFunction";
import { doGetRefV1 as doGetRefV1CT, doGetTextFlowsResult as doGetTextFlowsResultCT} from "../../utils/CTFunction";
import { doGetRefV1 as doGetRefV1ZCT, doGetTextFlowsResult as doGetTextFlowsResultZCT} from "../../utils/ZCTFunction";
import { doGetRefV1 as doGetRefV1LightBoard, doGetTextFlowsResult as doGetTextFlowsResultLightBoard} from "../../utils/lightBoard";
import { doGetRefV1 as doGetRefV1PowerBoard, doGetTextFlowsResult as doGetTextFlowsResultPowerBoard} from "../../utils/powerBoard";

const DXFSkeleton = require("../../assets/DXFSkeleton.dxf")
const DXFGraphL = require("../../assets/DXFGraphL.dxf")
const DXFGraphH = require("../../assets/DXFGraphH.dxf")
const DXFGraph = require("../../assets/DXFGraph.dxf")
 
const DXF_LAYER_TITLE = 2
const DXF_LAYER_SYMBOL = 3
const DXF_LAYER_REFNAME = 4
const DXF_LAYER_REFV1 = 5
const DXF_LAYER_TEXTBOX = 6

export const doDxfOutToFile = async (
  CDXFFile1: any,
  baseFileName: string,
  saveFile: string,
  params: any
) => {
  let bEntities = false;
  let bHandling = false;
  let sOneLineCode = "";
  let sOneLineValue = "";

  let baseFileContent = await readBaseFile(baseFileName)
  let newFileContent: any[] = []
  if (baseFileContent !== ""){
    const baseFileContentArr = baseFileContent.split("\r\n")
    for (let i = 0; i < baseFileContentArr.length - 1; i += 2) {
      if (i + 1 >= baseFileContentArr.length) {
        break;
      }
      sOneLineCode = baseFileContentArr[i]
      sOneLineValue = baseFileContentArr[i+1]
      if (bEntities == true) {
        if (sOneLineValue.trim() === "ENDSEC") {
          let additionalData = addInfoDisplayLocation([...params.diagramData], params.projectData.opeMode)
          let params2 = {
            CDXFFile1: CDXFFile1,
            pOpenedTransCenter: params.transCenter,
            bSociety: false,
            pDoc: {
              m_listGroup: additionalData,
              m_InfoSkeleton: params.infoSkeleton,
              m_InfoCircuit: params.infoCircuit,
              m_nOpeMode: params.projectData.opeMode,
              m_nDropMode: params.projectData.dropMode,
              m_nFlowViewMethod: params.projectData.flowViewMethod,
              originalListGroup: [...params.diagramData],
            },
          }
          newFileContent = newFileContent.concat(doDxfOutSymbolSymbolAll(params2));
        }
      }
      else if (bHandling == true) {
        sOneLineValue = "     0";
        bHandling = false;
      }
      else {
        if (sOneLineValue.trim() === "ENTITIES") {
          bEntities = true;
        }
        else if (sOneLineValue.trim() === "$HANDLING") {
          bHandling = true;
        }
      }
      newFileContent.push(sOneLineCode);
      newFileContent.push(sOneLineValue);
    }
  }
  
  exportDXF(newFileContent.join("\r\n"), saveFile)
}

const doDxfOutSymbolSymbolAll = (params: any) => {
  let retArr: any[] = [];
  let CDXFFile1 = params.CDXFFile1;
  let pOpenedTransCenter = params.pOpenedTransCenter;
  let bSociety: boolean = params.bSociety;
  let pDoc: any = params.pDoc;

  if (pOpenedTransCenter !== null) {
    let sizeView = {cx: 0, cy: 0};
    sizeView.cx = pOpenedTransCenter.properties.xFrameCount * MS_FRAME_WIDTH;
    sizeView.cy = pOpenedTransCenter.properties.yFrameCount * MS_FRAME_HEIGHT;

    let rectTotal = getRectTotal(0, 0, sizeView.cx, sizeView.cy);
    let sizeRectTotal = getSizeRectTotal(rectTotal);
    CDXFFile1.m_dBaseY = rectTotal.bottom;
    let dReducedX = (DXF_RECT_RIGHT - DXF_RECT_LEFT) / sizeRectTotal.cx;
    let dReducedY = (DXF_RECT_TOP - DXF_RECT_BOTTOM) / sizeRectTotal.cy;
    if (dReducedX > dReducedY) {
      CDXFFile1.m_dReduced = dReducedY;
      CDXFFile1.m_dOffsetX = rectTotal.left * CDXFFile1.m_dReduced + DXF_RECT_LEFT;
      CDXFFile1.m_dOffsetX -= ((DXF_RECT_RIGHT - DXF_RECT_LEFT) - (sizeRectTotal.cx * CDXFFile1.m_dReduced)) / 2;
      CDXFFile1.m_dOffsetY -= DXF_RECT_BOTTOM;
    }
    else {
      CDXFFile1.m_dReduced = dReducedX;
      CDXFFile1.m_dOffsetX = rectTotal.left * CDXFFile1.m_dReduced + DXF_RECT_LEFT;
      CDXFFile1.m_dOffsetY += ((DXF_RECT_TOP - DXF_RECT_BOTTOM) - (sizeRectTotal.cy * CDXFFile1.m_dReduced)) / 2;
      CDXFFile1.m_dOffsetY -= DXF_RECT_BOTTOM;
    }

    let listAllElement = [...pDoc.m_listGroup];
    listAllElement.forEach((pObjElement: any) => {
      retArr = retArr.concat(
        doDxfOutSymbolElement(CDXFFile1, pObjElement, pDoc, bSociety, pOpenedTransCenter)
      );

      // if (pDoc.m_nOpeMode == MS_MODE_POWER_FLOW &&
      //   pDoc.m_nFlowViewMethod == FLOW_VIEW_ZEROCOND_ONLY &&
      //   pObjElement.IsSPhaseCicuitEle(VOLT_SIDE_SECONDARY) != true) {
      //   if(pObjElement.type == EKind.MS_ZEROCOND) {
      //     doDxfOutFlowResultConnectedZeroCondElementInTransCenter(pDoc, pOpenedTransCenter, pObjElement);
      //   }
      //   else {
      //     if(pObjElement.IsSelectingSymboleEle() == true) {
      //       let listElements = [...pDoc.m_listGroup];
      //       let pTop = listElements.DoGetConnectElement(pObjElement, TOP_CENTER);
      //       if (pTop != null && pTop.type == EKind.MS_ZEROCOND) {
      //         doDxfOutFlowResultElement(pDoc, pObjElement, BOTTOM_CENTER);
      //       }
      //       let pBottom = listElements.DoGetConnectElement(pObjElement, BOTTOM_CENTER);
      //       if (pBottom != null && pBottom.type == EKind.MS_ZEROCOND) {
      //         doDxfOutFlowResultElement(pDoc, pObjElement, TOP_CENTER);
      //       }
      //       let pLeft = listElements.DoGetConnectElement(pObjElement, LEFT_CENTER);
      //       if (pLeft!= null && pLeft.type == EKind.MS_ZEROCOND) {
      //         doDxfOutFlowResultElement(pDoc, pObjElement, RIGHT_CENTER);
      //       }
      //       let pRight = listElements.DoGetConnectElement(pObjElement, RIGHT_CENTER);
      //       if (pRight != null && pRight.type == EKind.MS_ZEROCOND) {
      //         doDxfOutFlowResultElement(pDoc, pObjElement, LEFT_CENTER);
      //       }
      //     }
      //   }
      // }
    })
  } else {
    let sizeView = {cx: 0, cy: 0};
    sizeView.cx = pDoc.m_InfoSkeleton.squareCountHlizontal * MS_FRAME_WIDTH;
    sizeView.cy = pDoc.m_InfoSkeleton.squareCountVertical * MS_FRAME_HEIGHT;

    let rectTotal = getRectTotal(0, 0, sizeView.cx, sizeView.cy);
    let sizeRectTotal = getSizeRectTotal(rectTotal);
    CDXFFile1.m_dBaseY = rectTotal.bottom;
    let dReducedX = (DXF_RECT_RIGHT - DXF_RECT_LEFT) / sizeRectTotal.cx;
    let dReducedY = (DXF_RECT_TOP - DXF_RECT_BOTTOM) / sizeRectTotal.cy;
    if (dReducedX > dReducedY) {
      CDXFFile1.m_dReduced = dReducedY;
      CDXFFile1.m_dOffsetX = rectTotal.left * CDXFFile1.m_dReduced + DXF_RECT_LEFT;
      CDXFFile1.m_dOffsetX -= ((DXF_RECT_RIGHT - DXF_RECT_LEFT) - (sizeRectTotal.cx * CDXFFile1.m_dReduced)) / 2;
      CDXFFile1.m_dOffsetY -= DXF_RECT_BOTTOM;
    }
    else {
      CDXFFile1.m_dReduced = dReducedX;
      CDXFFile1.m_dOffsetX = rectTotal.left * CDXFFile1.m_dReduced + DXF_RECT_LEFT;
      CDXFFile1.m_dOffsetY += ((DXF_RECT_TOP - DXF_RECT_BOTTOM) - (sizeRectTotal.cy * CDXFFile1.m_dReduced)) / 2;
      CDXFFile1.m_dOffsetY -= DXF_RECT_BOTTOM;
    }

    let listAllElement = [...pDoc.m_listGroup];
    listAllElement.forEach((pObjElement: ControlModel) => {
      retArr = retArr.concat(
        doDxfOutSymbolElement(CDXFFile1, pObjElement, pDoc, bSociety, pOpenedTransCenter)
      );

      if (pDoc.m_nOpeMode == MS_MODE_POWER_FLOW &&
        pDoc.m_nFlowViewMethod == FLOW_VIEW_ZEROCOND_ONLY &&
        isSPhaseCircuitEle(pObjElement, VOLT_SIDE_SECONDARY).sPhase != true) {
        if(pObjElement.type == EKind.MS_ZEROCOND) {
          retArr = retArr.concat(
            doDxfOutFlowResultConnectedZeroCondElement(CDXFFile1, pDoc, pObjElement)
          );
        }
      //   else {
      //     if(pObjElement.IsSelectingSymboleEle() == true) {
      //       let listAllElement2 = [...pDoc.m_listGroup];
      //       let pTop = listAllElement2.DoGetConnectElement(pObjElement, TOP_CENTER);
      //       if (pTop != null && pTop.type == EKind.MS_ZEROCOND) {
      //         doDxfOutFlowResultElement(pDoc, pObjElement, BOTTOM_CENTER);
      //       }
      //       let pBottom = listAllElement2.DoGetConnectElement(pObjElement, BOTTOM_CENTER);
      //       if (pBottom != null && pBottom.type == EKind.MS_ZEROCOND) {
      //         doDxfOutFlowResultElement(pDoc, pObjElement, TOP_CENTER);
      //       }
      //       let pLeft = listAllElement2.DoGetConnectElement(pObjElement, LEFT_CENTER);
      //       if (pLeft!= null && pLeft.type == EKind.MS_ZEROCOND) {
      //         doDxfOutFlowResultElement(pDoc, pObjElement, RIGHT_CENTER);
      //       }
      //       let pRight = listAllElement2.DoGetConnectElement(pObjElement, RIGHT_CENTER);
      //       if (pRight != null && pRight.type == EKind.MS_ZEROCOND) {
      //         doDxfOutFlowResultElement(pDoc, pObjElement, LEFT_CENTER);
      //       }
      //     }
      //   }
      }
    })
  }

  CDXFFile1.m_nLayerNo = DXF_LAYER_TITLE;
  CDXFFile1.m_dBaseY = -1 * DXF_RECT_BOTTOM;
  CDXFFile1.m_dReduced = 1;
  CDXFFile1.m_dOffsetX = 0;
  CDXFFile1.m_dOffsetY = 0;
  
  retArr = retArr.concat(STRING(CDXFFile1, 8, 546 - 6, 12, pDoc.m_InfoSkeleton.figTitle));
  retArr = retArr.concat(STRING(CDXFFile1, 100, 546 - 3, 6, pDoc.m_InfoSkeleton.figSubTitle));
  
  retArr = retArr.concat(STRING(CDXFFile1, 6, 534 - 4, 8, pDoc.m_InfoCircuit.titleCorporation));
  retArr = retArr.concat(STRING(CDXFFile1, 47, 534 - 4, 8, pDoc.m_InfoCircuit.corporation));
  retArr = retArr.concat(STRING(CDXFFile1, 642, 534 - 4, 8, pDoc.m_InfoSkeleton.revision0));
  
  retArr = retArr.concat(STRING(CDXFFile1, 6, 522 - 4, 8, pDoc.m_InfoCircuit.titleProject));
  retArr = retArr.concat(STRING(CDXFFile1, 47, 522 - 4, 8, pDoc.m_InfoCircuit.project));
  retArr = retArr.concat(STRING(CDXFFile1, 287, 522 - 4, 8, pDoc.m_InfoSkeleton.titleDrawn));
  retArr = retArr.concat(STRING(CDXFFile1, 309, 522 - 4, 8, pDoc.m_InfoSkeleton.drawner));
  retArr = retArr.concat(STRING(CDXFFile1, 492, 522 - 4, 8, pDoc.m_InfoSkeleton.titleDateDrawn));
  retArr = retArr.concat(STRING(CDXFFile1, 514, 522 - 4, 8, pDoc.m_InfoSkeleton.dateDrawn));
  retArr = retArr.concat(STRING(CDXFFile1, 642, 522 - 4, 8, pDoc.m_InfoSkeleton.revision1));
  
  retArr = retArr.concat(STRING(CDXFFile1, 6, 510 - 4, 8, pDoc.m_InfoCircuit.titleReference));
  retArr = retArr.concat(STRING(CDXFFile1, 47, 510 - 4, 8, pDoc.m_InfoCircuit.reference));
  retArr = retArr.concat(STRING(CDXFFile1, 287, 510 - 4, 8, pDoc.m_InfoSkeleton.titleChecked));
  retArr = retArr.concat(STRING(CDXFFile1, 309, 510 - 4, 8, pDoc.m_InfoSkeleton.checker));
  retArr = retArr.concat(STRING(CDXFFile1, 492, 510 - 4, 8, pDoc.m_InfoSkeleton.titleDateChecked));
  retArr = retArr.concat(STRING(CDXFFile1, 514, 510 - 4, 8, pDoc.m_InfoSkeleton.dateChecked));
  retArr = retArr.concat(STRING(CDXFFile1, 642, 510 - 4, 8, pDoc.m_InfoSkeleton.revision2));
  
  retArr = retArr.concat(STRING(CDXFFile1, 6, 498 - 4, 8, pDoc.m_InfoSkeleton.titleNumber));
  retArr = retArr.concat(STRING(CDXFFile1, 47, 498 - 4, 8, pDoc.m_InfoSkeleton.figNumber));
  retArr = retArr.concat(STRING(CDXFFile1, 287, 498 - 4, 8, pDoc.m_InfoSkeleton.titleDesigned));
  retArr = retArr.concat(STRING(CDXFFile1, 309, 498 - 4, 8, pDoc.m_InfoSkeleton.designer));
  retArr = retArr.concat(STRING(CDXFFile1, 492, 498 - 4, 8, pDoc.m_InfoSkeleton.titleDateDesigned));
  retArr = retArr.concat(STRING(CDXFFile1, 514, 498 - 4, 8, pDoc.m_InfoSkeleton.dateDesigned));
  retArr = retArr.concat(STRING(CDXFFile1, 642, 498 - 4, 8, pDoc.m_InfoSkeleton.revision3));
  
  retArr = retArr.concat(STRING(CDXFFile1, 47, 486 - 4, 8, doGetTextOpeMode(pDoc.m_nOpeMode, pDoc.m_nDropMode, pDoc.m_listGroup)));
  retArr = retArr.concat(STRING(CDXFFile1, 287, 486 - 4, 8, pDoc.m_InfoSkeleton.titleApproved));
  retArr = retArr.concat(STRING(CDXFFile1, 309, 486 - 4, 8, pDoc.m_InfoSkeleton.approver));
  retArr = retArr.concat(STRING(CDXFFile1, 492, 486 - 4, 8, pDoc.m_InfoSkeleton.titleDateApproved));
  retArr = retArr.concat(STRING(CDXFFile1, 514, 486 - 4, 8, pDoc.m_InfoSkeleton.dateApproved));
  retArr = retArr.concat(STRING(CDXFFile1, 642, 486 - 4, 8, pDoc.m_InfoSkeleton.revision4));
  
  retArr = retArr.concat(STRING(CDXFFile1, 47, 474 - 4, 8, pDoc.m_InfoSkeleton.comments));

  return retArr;
}

const doDxfOutSymbolElement = (
  CDXFFile1: any,
  pObjElement: any,
  pDoc: any,
  bSociety: boolean,
  pOpenedTransCenter: any,
) => {
  let retArr: any[] = [];
  switch (pObjElement.type) {
    case EKind.MS_SOURCE:
      {
        let pSource = {...pObjElement};
        CDXFFile1.m_nLayerNo = DXF_LAYER_SYMBOL;
        retArr = retArr.concat(doDxfOutSymbolSource(CDXFFile1, pSource, pDoc.m_nOpeMode));

        let sRefV1Left = pSource.properties.refNo;
        let sRefV1_tmp = doGetRefV1Source(
          pDoc.m_nOpeMode, pSource, pDoc.m_InfoCircuit, false, pDoc.m_listGroup
        );
        sRefV1_tmp.shift(); // shift() removes the first item of an array => refNo
        let sRefV1 = sRefV1_tmp.map((e: any) => e.value);
        retArr = retArr.concat(doDxfOutAllRefV1s(CDXFFile1, pSource.m_listRefV1, sRefV1Left, sRefV1));

        if (pSource.properties.fault == true) {
          retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pSource.m_RefV1Point, "", [pSource.properties.pointText]));
        }

        if (pDoc.m_nOpeMode == MS_MODE_POWER_FLOW &&
          pSource.properties.viewFlowResult == true &&
          pDoc.m_nFlowViewMethod == FLOW_VIEW_EVERY_ELEMENT
        ) {
          let sFlowResult = doGetTextFlowsResultSource(pSource.properties, pDoc.m_nOpeMode, DIRECT_NONE, false);
          retArr = retArr.concat(doDxfOutFlowResult(CDXFFile1, pSource.m_RefV1FlowResult, sFlowResult));
          retArr = retArr.concat(doDxfOutFlowArrow(CDXFFile1, pSource));
        }
      }
      break;
    case EKind.MS_GENERATOR:
      {
        let pGenerator = {...pObjElement};
        CDXFFile1.m_nLayerNo = DXF_LAYER_SYMBOL;
        retArr = retArr.concat(doDxfOutSymbolGenerator(CDXFFile1, pGenerator, pDoc.m_nOpeMode));

        let sRefV1Left = pGenerator.properties.refNo;
        let sRefV1_tmp = doGetRefV1Generator(pDoc.m_nOpeMode, pGenerator, pDoc.m_InfoCircuit, false);
        sRefV1_tmp.shift();
        let sRefV1 = sRefV1_tmp.map((e: any) => e.value);
        retArr = retArr.concat(doDxfOutAllRefV1s(CDXFFile1, pGenerator.m_listRefV1, sRefV1Left, sRefV1));

        if (pGenerator.properties.fault == true) {
          retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pGenerator.m_RefV1Point, "", [pGenerator.properties.pointText]));
        }

        if (pDoc.m_nOpeMode == MS_MODE_POWER_FLOW &&
          pGenerator.properties.viewFlowResult == true &&
          pDoc.m_nFlowViewMethod == FLOW_VIEW_EVERY_ELEMENT
        ) {
          let sFlowResult = doGetTextFlowsResultGenerator(pGenerator.properties, pDoc.m_nOpeMode, DIRECT_NONE, false);
          retArr = retArr.concat(doDxfOutFlowResult(CDXFFile1, pGenerator.m_RefV1FlowResult, sFlowResult));
          retArr = retArr.concat(doDxfOutFlowArrow(CDXFFile1, pGenerator));
        }
      }
      break;
    case EKind.MS_TRANS3:
      {
        let pTrans3 = {...pObjElement};
        CDXFFile1.m_nLayerNo = DXF_LAYER_SYMBOL;
        retArr = retArr.concat(doDxfOutSymbolTrans3(CDXFFile1, pTrans3, pDoc.m_nOpeMode));

        let sRefV1Left = pTrans3.properties.refNo;
        let sRefV1_tmp = doGetRefV1Trans3(pDoc.m_nOpeMode, pTrans3, false);
        sRefV1_tmp.shift();
        let sRefV1 = sRefV1_tmp.map((e: any) => e.value);
        retArr = retArr.concat(doDxfOutAllRefV1s(CDXFFile1, pTrans3.m_listRefV1, sRefV1Left, sRefV1));

        if (pTrans3.properties.fault == true) {
          retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pTrans3.m_RefV1Point, "", [pTrans3.properties.pointText]));
        }

        if (pDoc.m_nOpeMode == MS_MODE_POWER_FLOW &&
          pTrans3.properties.viewFlowResult == true &&
          pDoc.m_nFlowViewMethod == FLOW_VIEW_EVERY_ELEMENT
        ) {
          let sFlowResult = doGetTextFlowsResultTrans3(pTrans3.properties, pDoc.m_nOpeMode, DIRECT_NONE, false);
          retArr = retArr.concat(doDxfOutFlowResult(CDXFFile1, pTrans3.m_RefV1FlowResult, sFlowResult));
          retArr = retArr.concat(doDxfOutFlowArrow(CDXFFile1, pTrans3));
        }
      }
      break;
    case EKind.MS_TRANSCENTER:
      {
        let pTransCenter = {...pObjElement};
        CDXFFile1.m_nLayerNo = DXF_LAYER_SYMBOL;
        retArr = retArr.concat(doDxfOutSymbolTransCenter(CDXFFile1, pTransCenter));

        let sRefV1Left = pTransCenter.properties.refNo;
        let sRefV1_tmp = doGetRefV1TC(pDoc.m_nOpeMode, pTransCenter, false);
        sRefV1_tmp.shift();
        let sRefV1 = sRefV1_tmp.map((e: any) => e.value);
        retArr = retArr.concat(doDxfOutAllRefV1s(CDXFFile1, pTransCenter.m_listRefV1, sRefV1Left, sRefV1));

        if (pTransCenter.properties.fault == true) {
          retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pTransCenter.m_RefV1Point, "", [pTransCenter.properties.pointText]));
        }

        if (pDoc.m_nOpeMode == MS_MODE_POWER_FLOW &&
          pTransCenter.properties.viewFlowResult == true &&
          pTransCenter.properties.sPhase != true &&
          pDoc.m_nFlowViewMethod == FLOW_VIEW_EVERY_ELEMENT
        ) {
          let sFlowResult = doGetTextFlowsResultTC(pTransCenter.properties, pDoc.m_nOpeMode, DIRECT_NONE, false);
          retArr = retArr.concat(doDxfOutFlowResult(CDXFFile1, pTransCenter.m_RefV1FlowResult, sFlowResult));
          retArr = retArr.concat(doDxfOutFlowArrow(CDXFFile1, pTransCenter));
        }
      }
      break;
    case EKind.MS_3WINDING:
      {
        let p3Winding = {...pObjElement};
        CDXFFile1.m_nLayerNo = DXF_LAYER_SYMBOL;
        retArr = retArr.concat(doDxfOutSymbol3Winding(CDXFFile1, p3Winding, pDoc.m_nOpeMode));
  
        let sRefV1Left = p3Winding.properties.refNo;

        let sRefV1_tmp = doGetRefV113Winding(pDoc.m_nOpeMode, p3Winding, false);
        sRefV1_tmp.shift();
        let sRefV1 = sRefV1_tmp.map((e: any) => e.value);
        retArr = retArr.concat(doDxfOutAllRefV1s(CDXFFile1, p3Winding.m_listRefV1, sRefV1Left, sRefV1));

        sRefV1_tmp = doGetRefV133Winding(pDoc.m_nOpeMode, p3Winding, false);
        sRefV1 = sRefV1_tmp.map((e: any) => e.value);
        retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, p3Winding.m_RefV1Left, "", sRefV1));
  
        if (p3Winding.properties.partSecondary.fault == true) {
          retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, p3Winding.properties.partSecondary.m_RefV1Point, "", [p3Winding.properties.partSecondary.pointText]));
        }
        if (p3Winding.properties.partThird.fault == true) {
          retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, p3Winding.properties.partThird.m_RefV1Point, "", [p3Winding.properties.partThird.pointText]));
        }
  
        if (pDoc.m_nOpeMode == MS_MODE_POWER_FLOW &&
          p3Winding.properties.viewFlowResult == true &&
          pDoc.m_nFlowViewMethod == FLOW_VIEW_EVERY_ELEMENT
        ) {
          let sFlowResult1 = doGetTextFlowsResultTrans3(p3Winding.properties.partPrimary, pDoc.m_nOpeMode, DIRECT_NONE, false);
          let sFlowResult2 = doGetTextFlowsResultTrans3(p3Winding.properties.partSecondary, pDoc.m_nOpeMode, DIRECT_NONE, false);
          let sFlowResult3 = doGetTextFlowsResultTrans3(p3Winding.properties.partThird, pDoc.m_nOpeMode, DIRECT_NONE, false);
          retArr = retArr.concat(doDxfOutFlowResult(CDXFFile1, p3Winding.properties.partPrimary.m_RefV1FlowResult, sFlowResult1));
          retArr = retArr.concat(doDxfOutFlowResult(CDXFFile1, p3Winding.properties.partSecondary.m_RefV1FlowResult, sFlowResult2));
          retArr = retArr.concat(doDxfOutFlowResult(CDXFFile1, p3Winding.properties.partThird.m_RefV1FlowResult, sFlowResult3));
          retArr = retArr.concat(doDxfOutFlowArrow(CDXFFile1, p3Winding));
        }
      }
      break;
    case EKind.MS_TRANS1:
      {
        let pTrans1 = {...pObjElement};
        CDXFFile1.m_nLayerNo = DXF_LAYER_SYMBOL;
        retArr = retArr.concat(doDxfOutSymbolTrans1(CDXFFile1, pTrans1, pDoc.m_nOpeMode));
  
        let sRefV1Left = pTrans1.properties.refNo;
        let sRefV1_tmp = doGetRefV1Trans1(pDoc.m_nOpeMode, pTrans1, false);
        sRefV1_tmp.shift();
        let sRefV1 = sRefV1_tmp.map((e: any) => e.value);
        retArr = retArr.concat(doDxfOutAllRefV1s(CDXFFile1, pTrans1.m_listRefV1, sRefV1Left, sRefV1));
  
        if (pTrans1.properties.partOutOut.fault == true || pTrans1.properties.partOutIn.fault == true) {
          let sRefV1Fault = "";
          if (pTrans1.properties.partOutOut.fault == true) {
            sRefV1Fault = pTrans1.properties.partOutOut.pointText + ", ";
          } else {
            sRefV1Fault = " -,";
          }
          if (pTrans1.properties.partOutIn.fault == true) {
            sRefV1Fault += pTrans1.properties.partOutIn.pointText;
          } else {
            sRefV1Fault += ",-";
          }
          retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pTrans1.properties.partOutOut.m_RefV1Point, "", [sRefV1Fault]));
        }
      }
      break;
    case EKind.MS_TRANSSCOTT:
      {
        let pTransScott = {...pObjElement};
        CDXFFile1.m_nLayerNo = DXF_LAYER_SYMBOL;
        retArr = retArr.concat(doDxfOutSymbolTransScott(CDXFFile1, pTransScott, pDoc.m_nOpeMode));
  
        let sRefV1Left = pTransScott.properties.refNo;
        let sRefV1_tmp = doGetRefV1TransScott(pDoc.m_nOpeMode, pTransScott, false);
        sRefV1_tmp.shift();
        let sRefV1 = sRefV1_tmp.map((e: any) => e.value);
        retArr = retArr.concat(doDxfOutAllRefV1s(CDXFFile1, pTransScott.m_listRefV1, sRefV1Left, sRefV1));
  
        if (pTransScott.properties.partSeatM.fault == true || pTransScott.properties.partSeatT.fault == true) {
          let sRefV1Fault = "";
          if (pTransScott.properties.partSeatM.fault == true) {
            sRefV1Fault = pTransScott.properties.partSeatM.pointText + ", ";
          }
          else {
            sRefV1Fault = " -,";
          }
          if (pTransScott.properties.partSeatT.fault == true) {
            sRefV1Fault += pTransScott.properties.partSeatT.pointText;
          }
          else {
            sRefV1Fault += ",-";
          }
          retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pTransScott.properties.partSeatM.m_RefV1Point, "", [sRefV1Fault])); 
        }
      }
      break;
    case EKind.MS_DS:
      {
        let pDS = {...pObjElement};
        CDXFFile1.m_nLayerNo = DXF_LAYER_SYMBOL;
        retArr = retArr.concat(doDxfOutSymbolDS(CDXFFile1, pDS));
  
        let sRefV1Left = pDS.properties.refNo;
        let sRefV1_tmp = doGetRefV1DS(pDoc.m_nOpeMode, pDS);
        sRefV1_tmp.shift();
        let sRefV1 = sRefV1_tmp.map((e: any) => e.value);
        retArr = retArr.concat(doDxfOutAllRefV1s(CDXFFile1, pDS.m_listRefV1, sRefV1Left, sRefV1));
  
        if (pDS.properties.fault == true) {
          retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pDS.m_RefV1Point, "", [pDS.properties.pointText]));
        }
  
        if (pDoc.m_nOpeMode == MS_MODE_POWER_FLOW &&
          pDS.properties.viewFlowResult == true &&
          pDS.properties.isOff != true &&
          pDoc.m_nFlowViewMethod == FLOW_VIEW_EVERY_ELEMENT
        ) {
          let sFlowResult = doGetTextFlowsResultDS(pDS.properties, pDoc.m_nOpeMode, DIRECT_NONE, false);
          retArr = retArr.concat(doDxfOutFlowResult(CDXFFile1, pDS.m_RefV1FlowResult, sFlowResult));
          retArr = retArr.concat(doDxfOutFlowArrow(CDXFFile1, pDS));
        }
      }
      break;
    case EKind.MS_LBS:
      {
        let pLBS = {...pObjElement};
        CDXFFile1.m_nLayerNo = DXF_LAYER_SYMBOL;
        retArr = retArr.concat(doDxfOutSymbolLBS(CDXFFile1, pLBS));
  
        let sRefV1Left = pLBS.properties.refNo;
        let sRefV1_tmp = doGetRefV1LBS(pDoc.m_nOpeMode, pLBS);
        sRefV1_tmp.shift();
        let sRefV1 = sRefV1_tmp.map((e: any) => e.value);
        retArr = retArr.concat(doDxfOutAllRefV1s(CDXFFile1, pLBS.m_listRefV1, sRefV1Left, sRefV1));
  
        if (pLBS.properties.fault == true) {
          retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pLBS.m_RefV1Point, "", [pLBS.properties.pointText]));
        }
  
        if (pDoc.m_nOpeMode == MS_MODE_POWER_FLOW &&
          pLBS.properties.viewFlowResult == true &&
          pLBS.properties.isOff != true &&
          pDoc.m_nFlowViewMethod == FLOW_VIEW_EVERY_ELEMENT
        ) {
          let sFlowResult = doGetTextFlowsResultLBS(pLBS.properties, pDoc.m_nOpeMode, DIRECT_NONE, false);
          retArr = retArr.concat(doDxfOutFlowResult(CDXFFile1, pLBS.m_RefV1FlowResult, sFlowResult));
          retArr = retArr.concat(doDxfOutFlowArrow(CDXFFile1, pLBS));
        }
      }
      break;
    case EKind.MS_MVCB:
      {
        let pMVCB = {...pObjElement};
        CDXFFile1.m_nLayerNo = DXF_LAYER_SYMBOL;
        retArr = retArr.concat(doDxfOutSymbolMVCB(CDXFFile1, pMVCB));

        let sRefV1Left = pMVCB.properties.refNo;
        let sRefV1_tmp = doGetRefV1MVCB(pDoc.m_nOpeMode, pMVCB);
        sRefV1_tmp.shift();
        let sRefV1 = sRefV1_tmp.map((e: any) => e.value);
        retArr = retArr.concat(doDxfOutAllRefV1s(CDXFFile1, pMVCB.m_listRefV1, sRefV1Left, sRefV1));

        if (pMVCB.properties.fault == true) {
          retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pMVCB.m_RefV1Point, "", [pMVCB.properties.pointText]));
        }

        if (pDoc.m_nOpeMode == MS_MODE_POWER_FLOW &&
          pMVCB.properties.viewFlowResult == true &&
          pMVCB.properties.isOff != true &&
          pMVCB.properties.sPhase != true &&
          pDoc.m_nFlowViewMethod == FLOW_VIEW_EVERY_ELEMENT
        ) {
          let sFlowResult = doGetTextFlowsResultMVCB(pMVCB.properties, pDoc.m_nOpeMode, DIRECT_NONE, false);
          retArr = retArr.concat(doDxfOutFlowResult(CDXFFile1, pMVCB.m_RefV1FlowResult, sFlowResult));
          retArr = retArr.concat(doDxfOutFlowArrow(CDXFFile1, pMVCB));
        }
      }
      break;
    case EKind.MS_CONTACTOR:
      {
        let pContactor = {...pObjElement};
        CDXFFile1.m_nLayerNo = DXF_LAYER_SYMBOL;
        retArr = retArr.concat(doDxfOutSymbolContactor(CDXFFile1, pContactor));
  
        let sRefV1Left = pContactor.properties.refNo;
        let sRefV1_tmp = doGetRefV1Contactor(pDoc.m_nOpeMode, pContactor);
        sRefV1_tmp.shift();
        let sRefV1 = sRefV1_tmp.map((e: any) => e.value);
        retArr = retArr.concat(doDxfOutAllRefV1s(CDXFFile1, pContactor.m_listRefV1, sRefV1Left, sRefV1));
  
        if (pContactor.properties.fault == true) {
          retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pContactor.m_RefV1Point, "", [pContactor.properties.pointText]));
        }
  
        if (pDoc.m_nOpeMode == MS_MODE_POWER_FLOW &&
          pContactor.properties.viewFlowResult == true &&
          pContactor.properties.isOff != true &&
          pContactor.properties.sPhase != true &&
          pDoc.m_nFlowViewMethod == FLOW_VIEW_EVERY_ELEMENT
        ) {
          let sFlowResult = doGetTextFlowsResultMVCB(pContactor.properties, pDoc.m_nOpeMode, DIRECT_NONE, false);
          retArr = retArr.concat(doDxfOutFlowResult(CDXFFile1, pContactor.m_RefV1FlowResult, sFlowResult));
          retArr = retArr.concat(doDxfOutFlowArrow(CDXFFile1, pContactor));
        }
      }
      break;
    case EKind.MS_HVCB:
      {
        let pHVCB = {...pObjElement};
        CDXFFile1.m_nLayerNo = DXF_LAYER_SYMBOL;
        retArr = retArr.concat(doDxfOutSymbolHVCB(CDXFFile1, pHVCB));
  
        let sRefV1Left = pHVCB.properties.refNo;
        let sRefV1_tmp = doGetRefV1HVCB(pDoc.m_nOpeMode, pHVCB, false);
        sRefV1_tmp.shift();
        let sRefV1 = sRefV1_tmp.map((e: any) => e.value);
        retArr = retArr.concat(doDxfOutAllRefV1s(CDXFFile1, pHVCB.m_listRefV1, sRefV1Left, sRefV1));
  
        if (pHVCB.properties.fault == true) {
          retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pHVCB.m_RefV1Point, "", [pHVCB.properties.pointText]));
        }
  
        if (pDoc.m_nOpeMode == MS_MODE_POWER_FLOW &&
          pHVCB.properties.viewFlowResult == true &&
          pHVCB.properties.isOff != true &&
          pDoc.m_nFlowViewMethod == FLOW_VIEW_EVERY_ELEMENT
        ) {
          let sFlowResult = doGetTextFlowsResultHVCB(pHVCB.properties, pDoc.m_nOpeMode, DIRECT_NONE, false);
          retArr = retArr.concat(doDxfOutFlowResult(CDXFFile1, pHVCB.m_RefV1FlowResult, sFlowResult));
          retArr = retArr.concat(doDxfOutFlowArrow(CDXFFile1, pHVCB));
        }
      }
      break;
    case EKind.MS_FUSE:
      {
        let pFuse = {...pObjElement};
        CDXFFile1.m_nLayerNo = DXF_LAYER_SYMBOL;
        retArr = retArr.concat(doDxfOutSymbolFuse(CDXFFile1, pFuse));

        let sRefV1Left = pFuse.properties.refNo;
        let sRefV1_tmp = doGetRefV1Fuse(pDoc.m_nOpeMode, pFuse, false);
        sRefV1_tmp.shift();
        let sRefV1 = sRefV1_tmp.map((e: any) => e.value);
        retArr = retArr.concat(doDxfOutAllRefV1s(CDXFFile1, pFuse.m_listRefV1, sRefV1Left, sRefV1));

        if (pFuse.properties.fault == true) {
          retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pFuse.m_RefV1Point, "", [pFuse.properties.pointText]));
        }

        if (pDoc.m_nOpeMode == MS_MODE_POWER_FLOW &&
          pFuse.properties.viewFlowResult == true &&
          pFuse.properties.isOff != true &&
          pDoc.m_nFlowViewMethod == FLOW_VIEW_EVERY_ELEMENT
        ) {
          let sFlowResult = doGetTextFlowsResultFuse(pFuse.properties, pDoc.m_nOpeMode, DIRECT_NONE, false);
          retArr = retArr.concat(doDxfOutFlowResult(CDXFFile1, pFuse.m_RefV1FlowResult, sFlowResult));
          retArr = retArr.concat(doDxfOutFlowArrow(CDXFFile1, pFuse));
        }
      }
      break;
    case EKind.MS_2E:
      {
        let p2E = {...pObjElement};
        CDXFFile1.m_nLayerNo = DXF_LAYER_SYMBOL;
        retArr = retArr.concat(doDxfOutSymbol2E(CDXFFile1, p2E));
  
        let sRefV1Left = p2E.properties.refNo;
        let sRefV1_tmp = doGetRefV12E(pDoc.m_nOpeMode, p2E, false);
        sRefV1_tmp.shift();
        let sRefV1 = sRefV1_tmp.map((e: any) => e.value);
        retArr = retArr.concat(doDxfOutAllRefV1s(CDXFFile1, p2E.m_listRefV1, sRefV1Left, sRefV1));
  
        if (p2E.properties.fault == true) {
          retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, p2E.m_RefV1Point, "", [p2E.properties.pointText]));
        }
  
        if (pDoc.m_nOpeMode == MS_MODE_POWER_FLOW &&
          p2E.properties.viewFlowResult == true &&
          p2E.properties.isOff != true &&
          p2E.properties.sPhase != true &&
          pDoc.m_nFlowViewMethod == FLOW_VIEW_EVERY_ELEMENT
        ) {
          let sFlowResult = doGetTextFlowsResult2E(p2E.properties, pDoc.m_nOpeMode, DIRECT_NONE, false);
          retArr = retArr.concat(doDxfOutFlowResult(CDXFFile1, p2E.m_RefV1FlowResult, sFlowResult));
          retArr = retArr.concat(doDxfOutFlowArrow(CDXFFile1, p2E));
        }
      }
      break;
    case EKind.MS_ZEROCOND:
      {
        let pZeroCond = {...pObjElement};
        CDXFFile1.m_nLayerNo = DXF_LAYER_SYMBOL;
        retArr = retArr.concat(doDxfOutSymbolZeroCond(CDXFFile1, pZeroCond, pOpenedTransCenter, pDoc));
  
        let sRefV1Left = pZeroCond.properties.refNo;
        let sRefV1_tmp = doGetRefV1ZeroCond(pDoc.m_nOpeMode, pZeroCond);
        sRefV1_tmp.shift();
        let sRefV1 = sRefV1_tmp.map((e: any) => e.value);
        retArr = retArr.concat(doDxfOutAllRefV1s(CDXFFile1, pZeroCond.m_listRefV1, sRefV1Left, sRefV1));
  
        if (pZeroCond.properties.fault == true) {
          retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pZeroCond.m_RefV1Point, "", [pZeroCond.properties.pointText]));
        }
      }
      break;
    case EKind.MS_CONNECT:
      {
        let pConnect = {...pObjElement};
        CDXFFile1.m_nLayerNo = DXF_LAYER_SYMBOL;
        retArr = retArr.concat(doDxfOutSymbolConnect(CDXFFile1, pConnect));
      }
      break;
    case EKind.MS_WIRE:
      {
        let pWire = {...pObjElement};
        CDXFFile1.m_nLayerNo = DXF_LAYER_SYMBOL;
        retArr = retArr.concat(doDxfOutSymbolWire(CDXFFile1, pWire, pDoc.m_nOpeMode));
  
        let sRefV1Left = pWire.properties.refNo;
        let sRefV1_tmp = doGetRefV1Wire(pDoc.m_nOpeMode, pWire, false);
        sRefV1_tmp.shift();
        let sRefV1 = sRefV1_tmp.map((e: any) => e.value);
        retArr = retArr.concat(doDxfOutAllRefV1s(CDXFFile1, pWire.m_listRefV1, sRefV1Left, sRefV1));
  
        if (pWire.properties.fault == true) {
          retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pWire.m_RefV1Point, "", [pWire.properties.pointText]));
        }
  
        if (pDoc.m_nOpeMode == MS_MODE_POWER_FLOW &&
          pWire.properties.viewFlowResult == true &&
          pWire.properties.sPhase != true &&
          pDoc.m_nFlowViewMethod == FLOW_VIEW_EVERY_ELEMENT
        ) {
          let sFlowResult = doGetTextFlowsResultWire(pWire.properties, pDoc.m_nOpeMode, DIRECT_NONE, false);
          retArr = retArr.concat(doDxfOutFlowResult(CDXFFile1, pWire.m_RefV1FlowResult, sFlowResult));
          retArr = retArr.concat(doDxfOutFlowArrow(CDXFFile1, pWire));
        }
      }
      break;
    case EKind.MS_BUSBAR:
      {
        let pBusBar = {...pObjElement};
        CDXFFile1.m_nLayerNo = DXF_LAYER_SYMBOL;
        retArr = retArr.concat(doDxfOutSymbolBusBar(CDXFFile1, pBusBar, pDoc.m_nOpeMode));
  
        let sRefV1Left = pBusBar.properties.refNo;
        let sRefV1_tmp = doGetRefV1BusBar(pDoc.m_nOpeMode, pBusBar, false);
        sRefV1_tmp.shift();
        let sRefV1 = sRefV1_tmp.map((e: any) => e.value);
        retArr = retArr.concat(doDxfOutAllRefV1s(CDXFFile1, pBusBar.m_listRefV1, sRefV1Left, sRefV1));
  
        if (pBusBar.properties.fault == true) {
          retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pBusBar.m_RefV1Point, "", [pBusBar.properties.pointText]));
        }
  
        if (pDoc.m_nOpeMode == MS_MODE_POWER_FLOW &&
          pBusBar.properties.viewFlowResult == true &&
          pBusBar.properties.sPhase != true &&
          pDoc.m_nFlowViewMethod == FLOW_VIEW_EVERY_ELEMENT
        ) {
          let sFlowResult = doGetTextFlowsResultBusBar(pBusBar.properties, pDoc.m_nOpeMode, DIRECT_NONE, false);
          retArr = retArr.concat(doDxfOutFlowResult(CDXFFile1, pBusBar.m_RefV1FlowResult, sFlowResult));
          retArr = retArr.concat(doDxfOutFlowArrow(CDXFFile1, pBusBar));
        }
      }
      break;
    case EKind.MS_IMPEDANCE:
      {
        let pImpedance = {...pObjElement};
        CDXFFile1.m_nLayerNo = DXF_LAYER_SYMBOL;
        retArr = retArr.concat(doDxfOutSymbolImpedance(CDXFFile1, pImpedance));
  
        let sRefV1Left = pImpedance.properties.refNo;
        let sRefV1_tmp = doGetRefV1Impedance(pDoc.m_nOpeMode, pImpedance, false);
        sRefV1_tmp.shift();
        let sRefV1 = sRefV1_tmp.map((e: any) => e.value);
        retArr = retArr.concat(doDxfOutAllRefV1s(CDXFFile1, pImpedance.m_listRefV1, sRefV1Left, sRefV1));
  
        if (pImpedance.properties.fault == true) {
          retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pImpedance.m_RefV1Point, "", [pImpedance.properties.pointText]));
        }
  
        if (pDoc.m_nOpeMode == MS_MODE_POWER_FLOW &&
          pImpedance.properties.viewFlowResult == true &&
          pImpedance.properties.sPhase != true &&
          pDoc.m_nFlowViewMethod == FLOW_VIEW_EVERY_ELEMENT
        ) {
          let sFlowResult = doGetTextFlowsResultImpedance(pImpedance.properties, pDoc.m_nOpeMode, DIRECT_NONE, false);
          retArr = retArr.concat(doDxfOutFlowResult(CDXFFile1, pImpedance.m_RefV1FlowResult, sFlowResult));
          retArr = retArr.concat(doDxfOutFlowArrow(CDXFFile1, pImpedance));
        }
      }
      break;
    case EKind.MS_BUSDUCT:
      {
        let pBusDuct = {...pObjElement};
        CDXFFile1.m_nLayerNo = DXF_LAYER_SYMBOL;
        retArr = retArr.concat(doDxfOutSymbolBusDuct(CDXFFile1, pBusDuct, pDoc.m_nOpeMode));
  
        let sRefV1Left = pBusDuct.properties.refNo;
        let sRefV1_tmp = doGetRefV1BusDuct(pDoc.m_nOpeMode, pBusDuct, false);
        sRefV1_tmp.shift();
        let sRefV1 = sRefV1_tmp.map((e: any) => e.value);
        retArr = retArr.concat(doDxfOutAllRefV1s(CDXFFile1, pBusDuct.m_listRefV1, sRefV1Left, sRefV1));
  
        if (pBusDuct.properties.fault == true) {
          retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pBusDuct.m_RefV1Point, "", [pBusDuct.properties.pointText]));
        }
  
        if (pDoc.m_nOpeMode == MS_MODE_POWER_FLOW &&
          pBusDuct.properties.viewFlowResult == true &&
          pBusDuct.properties.sPhase != true &&
          pDoc.m_nFlowViewMethod == FLOW_VIEW_EVERY_ELEMENT
        ) {
          let sFlowResult = doGetTextFlowsResultBusDuct(pBusDuct.properties, pDoc.m_nOpeMode, DIRECT_NONE, false);
          retArr = retArr.concat(doDxfOutFlowResult(CDXFFile1, pBusDuct.m_RefV1FlowResult, sFlowResult));
          retArr = retArr.concat(doDxfOutFlowArrow(CDXFFile1, pBusDuct));
        }
      }
      break;
    case EKind.MS_REACTOR:
      {
        let pReactor = {...pObjElement};
        CDXFFile1.m_nLayerNo = DXF_LAYER_SYMBOL;
        retArr = retArr.concat(doDxfOutSymbolReactor(CDXFFile1, pReactor, pDoc.m_nOpeMode));
  
        let sRefV1Left = pReactor.properties.refNo;
        let sRefV1_tmp = doGetRefV1Reactor(pDoc.m_nOpeMode, pReactor, false);
        sRefV1_tmp.shift();
        let sRefV1 = sRefV1_tmp.map((e: any) => e.value);
        retArr = retArr.concat(doDxfOutAllRefV1s(CDXFFile1, pReactor.m_listRefV1, sRefV1Left, sRefV1));
  
        if (pReactor.m_bFault == true) {
          retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pReactor.m_RefV1Point, "", [pReactor.properties.pointText]));
        }
  
        if (pDoc.m_nOpeMode == MS_MODE_POWER_FLOW &&
          pReactor.properties.viewFlowResult == true &&
          pDoc.m_nFlowViewMethod == FLOW_VIEW_EVERY_ELEMENT
        ) {
          let sFlowResult = doGetTextFlowsResultReactor(pReactor.properties, pDoc.m_nOpeMode, DIRECT_NONE, false);
          retArr = retArr.concat(doDxfOutFlowResult(CDXFFile1, pReactor.m_RefV1FlowResult, sFlowResult));
          retArr = retArr.concat(doDxfOutFlowArrow(CDXFFile1, pReactor));
        }
      }
      break;
    case EKind.MS_LVCB:
      {
        let pLVCB = {...pObjElement};
        CDXFFile1.m_nLayerNo = DXF_LAYER_SYMBOL;
        retArr = retArr.concat(doDxfOutSymbolLVCB(CDXFFile1, pLVCB));

        let sRefV1Left = pLVCB.properties.refNo;
        let sRefV1_tmp = doGetRefV1LVCB(pDoc.m_nOpeMode, pLVCB, false);
        sRefV1_tmp.shift();
        let sRefV1 = sRefV1_tmp.map((e: any) => e.value);
        retArr = retArr.concat(doDxfOutAllRefV1s(CDXFFile1, pLVCB.m_listRefV1, sRefV1Left, sRefV1));

        if (pLVCB.properties.fault == true) {
          retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pLVCB.m_RefV1Point, "", [pLVCB.properties.pointText]));
        }

        if (pDoc.m_nOpeMode == MS_MODE_POWER_FLOW &&
          pLVCB.properties.viewFlowResult == true &&
          pLVCB.properties.isOff != true &&
          pLVCB.properties.sPhase != true &&
          pDoc.m_nFlowViewMethod == FLOW_VIEW_EVERY_ELEMENT
        ) {
          let sFlowResult = doGetTextFlowsResultLVCB(pLVCB.properties, pDoc.m_nOpeMode, DIRECT_NONE, false);
          retArr = retArr.concat(doDxfOutFlowResult(CDXFFile1, pLVCB.m_RefV1FlowResult, sFlowResult));
          retArr = retArr.concat(doDxfOutFlowArrow(CDXFFile1, pLVCB));
        }
      }
      break;
    case EKind.MS_THERMAL:
      {
        let pThermal = {...pObjElement};
        CDXFFile1.m_nLayerNo = DXF_LAYER_SYMBOL;
        retArr = retArr.concat(doDxfOutSymbolThermal(CDXFFile1, pThermal));
  
        let sRefV1Left = pThermal.properties.refNo;
        let sRefV1_tmp = doGetRefV1Thermal(pDoc.m_nOpeMode, pThermal, false);
        sRefV1_tmp.shift();
        let sRefV1 = sRefV1_tmp.map((e: any) => e.value);
        retArr = retArr.concat(doDxfOutAllRefV1s(CDXFFile1, pThermal.m_listRefV1, sRefV1Left, sRefV1));
  
        if (pThermal.properties.fault == true) {
          retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pThermal.m_RefV1Point, "", [pThermal.properties.pointText]));
        }
  
        if (pDoc.m_nOpeMode == MS_MODE_POWER_FLOW &&
          pThermal.properties.viewFlowResult == true &&
          pThermal.properties.isOff != true &&
          pThermal.properties.sPhase != true &&
          pDoc.m_nFlowViewMethod == FLOW_VIEW_EVERY_ELEMENT
        ) {
          let sFlowResult = doGetTextFlowsResultThermal(pThermal.properties, pDoc.m_nOpeMode, DIRECT_NONE, false);
          retArr = retArr.concat(doDxfOutFlowResult(CDXFFile1, pThermal.m_RefV1FlowResult, sFlowResult));
          retArr = retArr.concat(doDxfOutFlowArrow(CDXFFile1, pThermal));
        }
      }
      break;
    case EKind.MS_THERMAL_CT:
      {
        let pThermalCT = {...pObjElement};
        CDXFFile1.m_nLayerNo = DXF_LAYER_SYMBOL;
        retArr = retArr.concat(doDxfOutSymbolThermalCT(CDXFFile1, pThermalCT));
  
        let sRefV1Left = pThermalCT.properties.refNo;
        let sRefV1_tmp = doGetRefV1ThermalCT(pDoc.m_nOpeMode, pThermalCT, false);
        sRefV1_tmp.shift();
        let sRefV1 = sRefV1_tmp.map((e: any) => e.value);
        retArr = retArr.concat(doDxfOutAllRefV1s(CDXFFile1, pThermalCT.m_listRefV1, sRefV1Left, sRefV1));
  
        if (pThermalCT.properties.fault == true) {
          retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pThermalCT.m_RefV1Point, "", [pThermalCT.properties.pointText]));
        }
  
        if (pDoc.m_nOpeMode == MS_MODE_POWER_FLOW &&
          pThermalCT.properties.viewFlowResult == true &&
          pThermalCT.properties.isOff != true &&
          pThermalCT.properties.sPhase != true &&
          pDoc.m_nFlowViewMethod == FLOW_VIEW_EVERY_ELEMENT
        ) {
          let sFlowResult = doGetTextFlowsResultThermalCT(pThermalCT.properties, pDoc.m_nOpeMode, DIRECT_NONE, false);
          retArr = retArr.concat(doDxfOutFlowResult(CDXFFile1, pThermalCT.m_RefV1FlowResult, sFlowResult));
          retArr = retArr.concat(doDxfOutFlowArrow(CDXFFile1, pThermalCT));
        }
      }
      break;
    case EKind.MS_MOTOR_GROUP:
      {
        let pMotorGrp = {...pObjElement};
        CDXFFile1.m_nLayerNo = DXF_LAYER_SYMBOL;
        retArr = retArr.concat(doDxfOutSymbolMotorGrp(CDXFFile1, pMotorGrp, pDoc.m_nOpeMode));
  
        let sRefV1Left = pMotorGrp.properties.refNo;
        let sRefV1_tmp = doGetRefV1MotorGrp(pDoc.m_nOpeMode, pMotorGrp, false);
        sRefV1_tmp.shift();
        let sRefV1 = sRefV1_tmp.map((e: any) => e.value);
        retArr = retArr.concat(doDxfOutAllRefV1s(CDXFFile1, pMotorGrp.m_listRefV1, sRefV1Left, sRefV1));
  
        if (pMotorGrp.properties.fault == true) {
          retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pMotorGrp.m_RefV1Point, "", [pMotorGrp.properties.pointText]));
        }
      }
      break;
    case EKind.MS_MOTOR:
      {
        let pMotor = {...pObjElement};
        CDXFFile1.m_nLayerNo = DXF_LAYER_SYMBOL;
        retArr = retArr.concat(doDxfOutSymbolMotor(CDXFFile1, pMotor));
  
        let sRefV1Left = pMotor.properties.refNo;
        let sRefV1_tmp = doGetRefV1Motor(pDoc.m_nOpeMode, pMotor, false);
        sRefV1_tmp.shift();
        let sRefV1 = sRefV1_tmp.map((e: any) => e.value);
        retArr = retArr.concat(doDxfOutAllRefV1s(CDXFFile1, pMotor.m_listRefV1, sRefV1Left, sRefV1));
      }
      break;
    case EKind.MS_LOAD:
      {
        let pLoad = {...pObjElement};
        CDXFFile1.m_nLayerNo = DXF_LAYER_SYMBOL;
        retArr = retArr.concat(doDxfOutSymbolLoad(CDXFFile1, pLoad));
  
        let sRefV1Left = pLoad.properties.refNo;
        let sRefV1_tmp = doGetRefV1Load(pDoc.m_nOpeMode, pLoad, false);
        sRefV1_tmp.shift();
        let sRefV1 = sRefV1_tmp.map((e: any) => e.value);
        retArr = retArr.concat(doDxfOutAllRefV1s(CDXFFile1, pLoad.m_listRefV1, sRefV1Left, sRefV1));
      }
      break;
    case EKind.MS_CAPACITOR:
      {
        let pCapacitor = {...pObjElement};
        CDXFFile1.m_nLayerNo = DXF_LAYER_SYMBOL;
        retArr = retArr.concat(doDxfOutSymbolCapacitor(CDXFFile1, pCapacitor));
  
        let sRefV1Left = pCapacitor.properties.refNo;
        let sRefV1_tmp = doGetRefV1Capacitor(pDoc.m_nOpeMode, pCapacitor, false);
        sRefV1_tmp.shift();
        let sRefV1 = sRefV1_tmp.map((e: any) => e.value);
        retArr = retArr.concat(doDxfOutAllRefV1s(CDXFFile1, pCapacitor.m_listRefV1, sRefV1Left, sRefV1));
      }
      break;
    case EKind.MS_LOADCENTER:
      // {
      //   let pLoadCenter = {...pObjElement};
      //   CDXFFile1.m_nLayerNo = DXF_LAYER_SYMBOL;
      //   retArr = retArr.concat(doDxfOutSymbolLoadCenter(CDXFFile1, pLoadCenter));
  
      //   let sRefV1Left = pLoadCenter.properties.refNo;
      //   let sRefV1_tmp = doGetRefV1LoadCenter(pDoc.m_nOpeMode, pLoadCenter, false);
      //   sRefV1_tmp.shift();
      //   let sRefV1 = sRefV1_tmp.map((e: any) => e.value);
      //   retArr = retArr.concat(doDxfOutAllRefV1s(CDXFFile1, pLoadCenter.m_listRefV1, sRefV1Left, sRefV1));
      // }
      break;
    case EKind.MS_VCT:
      {
        let pVCT = {...pObjElement};
        CDXFFile1.m_nLayerNo = DXF_LAYER_SYMBOL;
        retArr = retArr.concat(doDxfOutSymbolVCT(CDXFFile1, pVCT));

        let sRefV1Left = pVCT.properties.refNo;
        let sRefV1_tmp = doGetRefV1VCT(pDoc.m_nOpeMode, pVCT);
        sRefV1_tmp.shift();
        let sRefV1 = sRefV1_tmp.map((e: any) => e.value);
        retArr = retArr.concat(doDxfOutAllRefV1s(CDXFFile1, pVCT.m_listRefV1, sRefV1Left, sRefV1));

        if (pDoc.m_nOpeMode == MS_MODE_POWER_FLOW &&
          pVCT.properties.viewFlowResult == true &&
          pDoc.m_nFlowViewMethod == FLOW_VIEW_EVERY_ELEMENT
        ) {
          let sFlowResult = doGetTextFlowsResultVCT(pVCT.properties, pDoc.m_nOpeMode, DIRECT_NONE, false);
          retArr = retArr.concat(doDxfOutFlowResult(CDXFFile1, pVCT.m_RefV1FlowResult, sFlowResult));
          retArr = retArr.concat(doDxfOutFlowArrow(CDXFFile1, pVCT));
        }
      }
      break;
    case EKind.MS_INV:
      {
        let pINV = {...pObjElement};
        CDXFFile1.m_nLayerNo = DXF_LAYER_SYMBOL;
        retArr = retArr.concat(doDxfOutSymbolINV(CDXFFile1, pINV));

        let sRefV1Left = pINV.properties.refNo;
        let sRefV1_tmp = doGetRefV1INV(pDoc.m_nOpeMode, pINV);
        sRefV1_tmp.shift();
        let sRefV1 = sRefV1_tmp.map((e: any) => e.value);
        retArr = retArr.concat(doDxfOutAllRefV1s(CDXFFile1, pINV.m_listRefV1, sRefV1Left, sRefV1));

        if (pDoc.m_nOpeMode == MS_MODE_POWER_FLOW &&
          pINV.properties.viewFlowResult == true &&
          pDoc.m_nFlowViewMethod == FLOW_VIEW_EVERY_ELEMENT
        ) {
          let sFlowResult = doGetTextFlowsResultINV(pINV.properties, pDoc.m_nOpeMode, DIRECT_NONE, false);
          retArr = retArr.concat(doDxfOutFlowResult(CDXFFile1, pINV.m_RefV1FlowResult, sFlowResult));
          retArr = retArr.concat(doDxfOutFlowArrow(CDXFFile1, pINV));
        }
      }
      break;
    case EKind.MS_RELAY:
      {
        let pRELAY = {...pObjElement};
        CDXFFile1.m_nLayerNo = DXF_LAYER_SYMBOL;
        retArr = retArr.concat(doDxfOutSymbolRELAY(CDXFFile1, pRELAY));
  
        let sRefV1Left = pRELAY.properties.refNo;
        let sRefV1_tmp = doGetRefV1RELAY(pDoc.m_nOpeMode, pRELAY);
        sRefV1_tmp.shift();
        let sRefV1 = sRefV1_tmp.map((e: any) => e.value);
        retArr = retArr.concat(doDxfOutAllRefV1s(CDXFFile1, pRELAY.m_listRefV1, sRefV1Left, sRefV1));
  
        if (pDoc.m_nOpeMode == MS_MODE_POWER_FLOW &&
          pRELAY.properties.viewFlowResult == true &&
          pDoc.m_nFlowViewMethod == FLOW_VIEW_EVERY_ELEMENT
        ) {
          let sFlowResult = doGetTextFlowsResultRELAY(pRELAY.properties, pDoc.m_nOpeMode, DIRECT_NONE, false);
          retArr = retArr.concat(doDxfOutFlowResult(CDXFFile1, pRELAY.m_RefV1FlowResult, sFlowResult));
          retArr = retArr.concat(doDxfOutFlowArrow(CDXFFile1, pRELAY));
        }
      }
      break;
    case EKind.MS_ARRESTOR:
      {
        let pArrestor = {...pObjElement};
        CDXFFile1.m_nLayerNo = DXF_LAYER_SYMBOL;
        retArr = retArr.concat(doDxfOutSymbolArrestor(CDXFFile1, pArrestor));
  
        let sRefV1Left = pArrestor.properties.refNo;
        let sRefV1_tmp = doGetRefV1Arrestor(pDoc.m_nOpeMode, pArrestor);
        sRefV1_tmp.shift();
        let sRefV1 = sRefV1_tmp.map((e: any) => e.value);
        retArr = retArr.concat(doDxfOutAllRefV1s(CDXFFile1, pArrestor.m_listRefV1, sRefV1Left, sRefV1));
      }
      break;
    case EKind.MS_EARTH:
      {
        let pEarth = {...pObjElement};
        CDXFFile1.m_nLayerNo = DXF_LAYER_SYMBOL;
        retArr = retArr.concat(doDxfOutSymbolEarth(CDXFFile1, pEarth));
  
        let sRefV1Left = pEarth.properties.refNo;
        let sRefV1_tmp = doGetRefV1Earth(pEarth);
        sRefV1_tmp.shift();
        let sRefV1 = sRefV1_tmp.map((e: any) => e.value);
        retArr = retArr.concat(doDxfOutAllRefV1s(CDXFFile1, pEarth.m_listRefV1, sRefV1Left, sRefV1));
      }
      break;
    case EKind.MS_VT:
      {
        let pVT = {...pObjElement};
        CDXFFile1.m_nLayerNo = DXF_LAYER_SYMBOL;
        retArr = retArr.concat(doDxfOutSymbolVT(CDXFFile1, pVT));
  
        let sRefV1Left = pVT.properties.refNo;
        let sRefV1_tmp = doGetRefV1VT(pDoc.m_nOpeMode, pVT);
        sRefV1_tmp.shift();
        let sRefV1 = sRefV1_tmp.map((e: any) => e.value);
        retArr = retArr.concat(doDxfOutAllRefV1s(CDXFFile1, pVT.m_listRefV1, sRefV1Left, sRefV1));
  
        if (pDoc.m_nOpeMode == MS_MODE_POWER_FLOW && 
          pVT.properties.viewFlowResult == true &&
          pDoc.m_nFlowViewMethod == FLOW_VIEW_EVERY_ELEMENT
        ) {
          let sFlowResult = doGetTextFlowsResultVT(pVT.properties, pDoc.m_nOpeMode, DIRECT_NONE, false);
          retArr = retArr.concat(doDxfOutFlowResult(CDXFFile1, pVT.m_RefV1FlowResult, sFlowResult));
          retArr = retArr.concat(doDxfOutFlowArrow(CDXFFile1, pVT));
        }
      }
      break;
    case EKind.MS_CABLEHEAD:
      {
        let pCableHead = {...pObjElement};
        CDXFFile1.m_nLayerNo = DXF_LAYER_SYMBOL;
        retArr = retArr.concat(doDxfOutSymbolCableHead(CDXFFile1, pCableHead));
  
        let sRefV1Left = pCableHead.properties.refNo;
        let sRefV1_tmp = doGetRefV1CableHead(pCableHead);
        sRefV1_tmp.shift();
        let sRefV1 = sRefV1_tmp.map((e: any) => e.value);
        retArr = retArr.concat(doDxfOutAllRefV1s(CDXFFile1, pCableHead.m_listRefV1, sRefV1Left, sRefV1));
      }
      break;
    case EKind.MS_CT:
      {
        let pCT = {...pObjElement};
        CDXFFile1.m_nLayerNo = DXF_LAYER_SYMBOL;
        retArr = retArr.concat(doDxfOutSymbolCT(CDXFFile1, pCT));
  
        let sRefV1Left = pCT.properties.refNo;
        let sRefV1_tmp = doGetRefV1CT(pDoc.m_nOpeMode, pCT);
        sRefV1_tmp.shift();
        let sRefV1 = sRefV1_tmp.map((e: any) => e.value);
        retArr = retArr.concat(doDxfOutAllRefV1s(CDXFFile1, pCT.m_listRefV1, sRefV1Left, sRefV1));
  
        if (pDoc.m_nOpeMode == MS_MODE_POWER_FLOW &&
          pCT.properties.viewFlowResult == true &&
          pCT.properties.sPhase != true &&
          pDoc.m_nFlowViewMethod == FLOW_VIEW_EVERY_ELEMENT
        ) {
          let sFlowResult = doGetTextFlowsResultCT(pCT.properties, pDoc.m_nOpeMode, DIRECT_NONE, false);
          retArr = retArr.concat(doDxfOutFlowResult(CDXFFile1, pCT.m_RefV1FlowResult, sFlowResult));
          retArr = retArr.concat(doDxfOutFlowArrow(CDXFFile1, pCT));
        }
      }
      break;
    case EKind.MS_ZCT:
      {
        let pZCT = {...pObjElement};
        CDXFFile1.m_nLayerNo = DXF_LAYER_SYMBOL;
        retArr = retArr.concat(doDxfOutSymbolZCT(CDXFFile1, pZCT));
  
        let sRefV1Left = pZCT.properties.refNo;
        let sRefV1_tmp = doGetRefV1ZCT(pDoc.m_nOpeMode, pZCT);
        sRefV1_tmp.shift();
        let sRefV1 = sRefV1_tmp.map((e: any) => e.value);
        retArr = retArr.concat(doDxfOutAllRefV1s(CDXFFile1, pZCT.m_listRefV1, sRefV1Left, sRefV1));
  
        if (pDoc.m_nOpeMode == MS_MODE_POWER_FLOW &&
          pZCT.properties.viewFlowResult == true &&
          pDoc.m_nFlowViewMethod == FLOW_VIEW_EVERY_ELEMENT
        ) {
          let sFlowResult = doGetTextFlowsResultZCT(pZCT.properties, pDoc.m_nOpeMode, DIRECT_NONE, false);
          retArr = retArr.concat(doDxfOutFlowResult(CDXFFile1, pZCT.m_RefV1FlowResult, sFlowResult));
          retArr = retArr.concat(doDxfOutFlowArrow(CDXFFile1, pZCT));
        }
      }
      break;
    case EKind.MS_TEXT:
      {
        let pText = {...pObjElement};
        CDXFFile1.m_nLayerNo = DXF_LAYER_TEXTBOX;
        retArr = retArr.concat(doDxfOutTextBox(CDXFFile1, pText));
      }
      break;
    case EKind.MS_LIGHTBOARD:
      {
        let pLightBoard = {...pObjElement};
        CDXFFile1.m_nLayerNo = DXF_LAYER_SYMBOL;
        retArr = retArr.concat(doDxfOutSymbolLightBoard(CDXFFile1, pLightBoard));
  
        let sRefV1Left = pLightBoard.properties.refNo;
        let sRefV1_tmp = doGetRefV1LightBoard(pDoc.m_nOpeMode, pLightBoard, false);
        sRefV1_tmp.shift();
        let sRefV1 = sRefV1_tmp.map((e: any) => e.value);
        retArr = retArr.concat(doDxfOutAllRefV1s(CDXFFile1, pLightBoard.m_listRefV1, sRefV1Left, sRefV1));
      }
      break;
    case EKind.MS_POWERBOARD:
      {
        let pPowerBoard = {...pObjElement};
        CDXFFile1.m_nLayerNo = DXF_LAYER_SYMBOL;
        retArr = retArr.concat(doDxfOutSymbolPowerBoard(CDXFFile1, pPowerBoard));
  
        let sRefV1Left = pPowerBoard.properties.refNo;
        let sRefV1_tmp = doGetRefV1PowerBoard(pDoc.m_nOpeMode, pPowerBoard, false);
        sRefV1_tmp.shift();
        let sRefV1 = sRefV1_tmp.map((e: any) => e.value);
        retArr = retArr.concat(doDxfOutAllRefV1s(CDXFFile1, pPowerBoard.m_listRefV1, sRefV1Left, sRefV1));
      }
      break;
  }

  return retArr;
}

const doDxfOutFlowResultConnectedZeroCondElement = (
  CDXFFile1: any, pDoc: any, tmpStdZeroCond: ControlModel
) => {
  // Konva: 1 cell = 20, file dxf: 1 cell = 40 
  // function doGetConnectElement will be wrong if use converted data
  let retArr: any[] = [];
  let listAllElement = [...pDoc.originalListGroup];
  let pStdZeroCond = listAllElement.find((e: any) => e.id === tmpStdZeroCond.id);
  while(true) {
    let tmpTop = doGetConnectElement(listAllElement, pStdZeroCond, TOP_CENTER);
    if (tmpTop.code != 0) {
      break;
    }
    let pTop: any = pDoc.m_listGroup.find((e: any) => e.id === tmpTop.data.id);
    while(true) {
      if (isSPhaseCircuitEle(pTop, VOLT_SIDE_SECONDARY).sPhase == true) {
        break;
      }
      if (pTop.type == EKind.MS_ARRESTOR || pTop.type == EKind.MS_EARTH) {
        break;
      }
      if (pTop.type == EKind.MS_TRANS1 || pTop.type == EKind.MS_TRANSSCOTT) {
        break;
      }
      if (pTop.type == EKind.MS_ZEROCOND) {
        break;
      }
      if (isCanSetViewFlowResult(pTop) == true) {
        retArr = retArr.concat(doDxfOutFlowResultElement(CDXFFile1, pDoc, pTop, BOTTOM_CENTER));
        break;
      }
      else {
        tmpTop = doGetConnectElement(listAllElement, tmpTop.data, TOP_CENTER);
        if (tmpTop.code != 0) {
          break;
        }
        pTop = pDoc.m_listGroup.find((e: any) => e.id === tmpTop.data.id);
      }
    }
  }
  
  while(true) {
    let tmpBottom = doGetConnectElement(listAllElement, pStdZeroCond, BOTTOM_CENTER);
    if (tmpBottom.code != 0) {
      break;
    }
    let pBottom: any = pDoc.m_listGroup.find((e: any) => e.id === tmpBottom.data.id);
    while(true) {
      if (isSPhaseCircuitEle(pBottom, VOLT_SIDE_PRIMARY).sPhase == true) {
        break;
      }
      if (pBottom.type == EKind.MS_ARRESTOR || pBottom.type == EKind.MS_EARTH) {
        break;
      }
      if (pBottom.type == EKind.MS_TRANS1 || pBottom.type == EKind.MS_TRANSSCOTT) {
        break;
      }
      if (pBottom.type == EKind.MS_ZEROCOND) {
        break;
      }
      if (isCanSetViewFlowResult(pBottom) == true) {
        retArr = retArr.concat(doDxfOutFlowResultElement(CDXFFile1, pDoc, pBottom, TOP_CENTER));
        break;
      }
      else {
        tmpBottom = doGetConnectElement(listAllElement, tmpBottom.data, BOTTOM_CENTER);
        if (tmpBottom.code != 0) {
          break;
        }
        pBottom = pDoc.m_listGroup.find((e: any) => e.id === tmpBottom.data.id);
      }
    }
  }
  
  while(true) {
    let tmpLeft = doGetConnectElement(listAllElement, pStdZeroCond, LEFT_CENTER);
    if (tmpLeft.code != 0) {
      break;
    }
    let pLeft: any = pDoc.m_listGroup.find((e: any) => e.id === tmpLeft.data.id);
    while(true) {
      if (isSPhaseCircuitEle(pLeft, VOLT_SIDE_PRIMARY).sPhase == true) {
        break;
      }
      if (pLeft.type == EKind.MS_ZEROCOND) {
        break;
      }
      if (isCanSetViewFlowResult(pLeft) == true) {
        retArr = retArr.concat(doDxfOutFlowResultElement(CDXFFile1, pDoc, pLeft, RIGHT_CENTER));
        break;
      }
      else {
        tmpLeft = doGetConnectElement(listAllElement, tmpLeft.data, LEFT_CENTER);
        if (tmpLeft.code != 0) {
          break;
        }
        pLeft = pDoc.m_listGroup.find((e: any) => e.id === tmpLeft.data.id);
      }
    }
  }
  
  while(true) {
    let tmpRight = doGetConnectElement(listAllElement, pStdZeroCond, RIGHT_CENTER);
    if (tmpRight.code != 0) {
      break;
    }
    let pRight: any = pDoc.m_listGroup.find((e: any) => e.id === tmpRight.data.id);
    while(true) {
      if (isSPhaseCircuitEle(pRight, VOLT_SIDE_PRIMARY).sPhase == true) {
        break;
      }
      if (pRight.type == EKind.MS_ZEROCOND) {
        break;
      }
      if (isCanSetViewFlowResult(pRight) == true) {
        retArr = retArr.concat(doDxfOutFlowResultElement(CDXFFile1, pDoc, pRight, LEFT_CENTER));
        break;
      }
      else {
        tmpRight = doGetConnectElement(listAllElement, tmpRight.data, RIGHT_CENTER);
        if (tmpRight.code != 0) {
          break;
        }
        pRight = pDoc.m_listGroup.find((e: any) => e.id === tmpRight.data.id);
      }
    }
  }

  return retArr;
}

const doDxfOutFlowResultElement = (
  CDXFFile1: any, pDoc: any, pElement: any, nDirect: number
) => {
  let retArr: any[] = [];
  switch(pElement.type) {
    case EKind.MS_SOURCE:
      {
        let pSource = {...pElement};
        let sFlowResult = doGetTextFlowsResultSource(pSource.properties, pDoc.m_nOpeMode, nDirect, false);
        retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pSource.m_RefV1FlowResultBottom, "", sFlowResult));
        retArr = retArr.concat(doDxfOutFlowArrow(CDXFFile1, pElement, nDirect));
      }
      break;
    case EKind.MS_GENERATOR:
      {
        let pGenerator = {...pElement};
        let sFlowResult = doGetTextFlowsResultGenerator(pGenerator.properties, pDoc.m_nOpeMode, nDirect, false);
        retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pGenerator.m_RefV1FlowResultBottom, "", sFlowResult));
        retArr = retArr.concat(doDxfOutFlowArrow(CDXFFile1, pElement, nDirect));
      }
      break;
    case EKind.MS_TRANS3:
      {
        let pTrans3 = {...pElement};
        let sFlowResult = doGetTextFlowsResultTrans3(pTrans3.properties, pDoc.m_nOpeMode, nDirect, false);
        if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
          retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pTrans3.m_RefV1FlowResultTop, "", sFlowResult));
        }
        else {
          retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pTrans3.m_RefV1FlowResultBottom, "", sFlowResult));
        }
        retArr = retArr.concat(doDxfOutFlowArrow(CDXFFile1, pElement, nDirect));
      }
      break;
    case EKind.MS_TRANSCENTER:
      {
        let pTransCenter = {...pElement};
        let sFlowResult = doGetTextFlowsResultTC(pTransCenter.properties, pDoc.m_nOpeMode, nDirect, false);
        retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pTransCenter.m_RefV1FlowResultTop, "", sFlowResult));
        retArr = retArr.concat(doDxfOutFlowArrow(CDXFFile1, pElement, nDirect));
      }
      break;
    case EKind.MS_3WINDING:
      {
        let p3Winding = {...pElement};
        let sFlowResult;
        switch(nDirect) {
        case TOP_CENTER:
          sFlowResult = doGetTextFlowsResultTrans3(p3Winding.properties.partPrimary, pDoc.m_nOpeMode, TOP_CENTER, false);
          retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, p3Winding.properties.partPrimary.m_RefV1FlowResultTop, "", sFlowResult));
          break;
        case RIGHT_CENTER:
          sFlowResult = doGetTextFlowsResultTrans3(p3Winding.properties.partSecondary, pDoc.m_nOpeMode, TOP_CENTER, false);
          retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, p3Winding.properties.partSecondary.m_RefV1FlowResultTop, "", sFlowResult));
          break;
        case LEFT_CENTER:
          sFlowResult = doGetTextFlowsResultTrans3(p3Winding.properties.partThird, pDoc.m_nOpeMode, TOP_CENTER, false);
          retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, p3Winding.properties.partThird.m_RefV1FlowResultTop, "", sFlowResult));
          break;
        }
        retArr = retArr.concat(doDxfOutFlowArrow(CDXFFile1, pElement, nDirect));
      }
      break;
    case EKind.MS_TRANS1:
      break;
    case EKind.MS_TRANSSCOTT:
      break;
    case EKind.MS_DS:
      {
        let pDS = {...pElement};
        let sFlowResult = doGetTextFlowsResultDS(pDS.properties, pDoc.m_nOpeMode, nDirect, false);
        if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
          retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pDS.m_RefV1FlowResultTop, "", sFlowResult));
        }
        else {
          retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pDS.m_RefV1FlowResultBottom, "", sFlowResult));
        }
        retArr = retArr.concat(doDxfOutFlowArrow(CDXFFile1, pElement, nDirect));
      }
      break;
    case EKind.MS_LBS:
      {
        let pLBS = {...pElement};
        let sFlowResult = doGetTextFlowsResultLBS(pLBS.properties, pDoc.m_nOpeMode, nDirect, false);
        if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
          retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pLBS.m_RefV1FlowResultTop, "", sFlowResult));
        }
        else {
          retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pLBS.m_RefV1FlowResultBottom, "", sFlowResult));
        }
        retArr = retArr.concat(doDxfOutFlowArrow(CDXFFile1, pElement, nDirect));
      }
      break;
    case EKind.MS_MVCB:
      {
        let pMVCB = {...pElement};
        let sFlowResult = doGetTextFlowsResultMVCB(pMVCB.properties, pDoc.m_nOpeMode, nDirect, false);
        if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
          retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pMVCB.m_RefV1FlowResultTop, "", sFlowResult));
        }
        else {
          retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pMVCB.m_RefV1FlowResultBottom, "", sFlowResult));
        }
        retArr = retArr.concat(doDxfOutFlowArrow(CDXFFile1, pElement, nDirect));
      }
      break;
    case EKind.MS_CONTACTOR:
      {
        let pContactor = {...pElement};
        let sFlowResult = doGetTextFlowsResultMVCB(pContactor.properties, pDoc.m_nOpeMode, nDirect, false);
        if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
          retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pContactor.m_RefV1FlowResultTop, "", sFlowResult));
        }
        else {
          retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pContactor.m_RefV1FlowResultBottom, "", sFlowResult));
        }
        retArr = retArr.concat(doDxfOutFlowArrow(CDXFFile1, pElement, nDirect));
      }
      break;
    case EKind.MS_HVCB:
      {
        let pHVCB = {...pElement};
        let sFlowResult = doGetTextFlowsResultHVCB(pHVCB.properties, pDoc.m_nOpeMode, nDirect, false);
        if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
          retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pHVCB.m_RefV1FlowResultTop, "", sFlowResult));
        }
        else {
          retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pHVCB.m_RefV1FlowResultBottom, "", sFlowResult));
        }
        retArr = retArr.concat(doDxfOutFlowArrow(CDXFFile1, pElement, nDirect));
      }
      break;
    case EKind.MS_RELAY51:
      // {
      //   let pRelay51 = {...pElement};
      //   let sFlowResult = doGetTextFlowsResult(pRelay51.properties, pDoc.m_nOpeMode, nDirect, false);
      //   if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
      //     retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pRelay51.m_RefV1FlowResultTop, "", pRelay51.DoGetTextFlowsResult(pDoc.m_nOpeMode, nDirect, FALSE)));
      //   }
      //   else {
      //     retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pRelay51.m_RefV1FlowResultBottom, "", pRelay51.DoGetTextFlowsResult(pDoc.m_nOpeMode, nDirect, FALSE)));
      //   }
      //   retArr = retArr.concat(doDxfOutFlowArrow(CDXFFile1, pElement, nDirect));
      // }
      break;
    case EKind.MS_FUSE:
      {
        let pFuse = {...pElement};
        let sFlowResult = doGetTextFlowsResultFuse(pFuse.properties, pDoc.m_nOpeMode, nDirect, false);
        if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
          retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pFuse.m_RefV1FlowResultTop, "", sFlowResult));
        }
        else {
          retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pFuse.m_RefV1FlowResultBottom, "", sFlowResult));
        }
        retArr = retArr.concat(doDxfOutFlowArrow(CDXFFile1, pElement, nDirect));
      }
      break;
    case EKind.MS_2E:
      {
        let p2E = {...pElement};
        let sFlowResult = doGetTextFlowsResult2E(p2E.properties, pDoc.m_nOpeMode, nDirect, false);
        if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
          retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, p2E.m_RefV1FlowResultTop, "", sFlowResult));
        }
        else {
          retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, p2E.m_RefV1FlowResultBottom, "", sFlowResult));
        }
        retArr = retArr.concat(doDxfOutFlowArrow(CDXFFile1, pElement, nDirect));
      }
      break;
    case EKind.MS_ZEROCOND:
      break;
    case EKind.MS_CONNECT:
      break;
    case EKind.MS_WIRE:
      {
        let pWire = {...pElement};
        let sFlowResult = doGetTextFlowsResultWire(pWire.properties, pDoc.m_nOpeMode, nDirect, false);
        if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
          retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pWire.m_RefV1FlowResultTop, "", sFlowResult));
        }
        else {
          retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pWire.m_RefV1FlowResultBottom, "", sFlowResult));
        }
        retArr = retArr.concat(doDxfOutFlowArrow(CDXFFile1, pElement, nDirect));
      }
      break;
    case EKind.MS_BUSBAR:
      {
        let pBusBar = {...pElement};
        let sFlowResult = doGetTextFlowsResultBusBar(pBusBar.properties, pDoc.m_nOpeMode, nDirect, false);
        if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
          retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pBusBar.m_RefV1FlowResultTop, "", sFlowResult));
        }
        else {
          retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pBusBar.m_RefV1FlowResultBottom, "", sFlowResult));
        }
        retArr = retArr.concat(doDxfOutFlowArrow(CDXFFile1, pElement, nDirect));
      }
      break;
    case EKind.MS_IMPEDANCE:
      {
        let pImpedance = {...pElement};
        let sFlowResult = doGetTextFlowsResultImpedance(pImpedance.properties, pDoc.m_nOpeMode, nDirect, false);
        if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
          retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pImpedance.m_RefV1FlowResultTop, "", sFlowResult));
        }
        else {
          retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pImpedance.m_RefV1FlowResultBottom, "", sFlowResult));
        }
        retArr = retArr.concat(doDxfOutFlowArrow(CDXFFile1, pElement, nDirect));
      }
      break;
    case EKind.MS_BUSDUCT:
      {
        let pBusDuct = {...pElement};
        let sFlowResult = doGetTextFlowsResultBusDuct(pBusDuct.properties, pDoc.m_nOpeMode, nDirect, false);
        if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
          retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pBusDuct.m_RefV1FlowResultTop, "", sFlowResult));
        }
        else {
          retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pBusDuct.m_RefV1FlowResultBottom, "", sFlowResult));
        }
        retArr = retArr.concat(doDxfOutFlowArrow(CDXFFile1, pElement, nDirect));
      }
      break;
    case EKind.MS_REACTOR:
      {
        let pReactor = {...pElement};
        let sFlowResult = doGetTextFlowsResultReactor(pReactor.properties, pDoc.m_nOpeMode, nDirect, false);
        if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
          retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pReactor.m_RefV1FlowResultTop, "", sFlowResult));
        }
        else {
          retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pReactor.m_RefV1FlowResultBottom, "", sFlowResult));
        }
        retArr = retArr.concat(doDxfOutFlowArrow(CDXFFile1, pElement, nDirect));
      }
      break;
    case EKind.MS_LVCB:
      {
        let pLVCB = {...pElement};
        let sFlowResult = doGetTextFlowsResultLVCB(pLVCB.properties, pDoc.m_nOpeMode, nDirect, false);
        if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
          retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pLVCB.m_RefV1FlowResultTop, "", sFlowResult));
        }
        else {
          retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pLVCB.m_RefV1FlowResultBottom, "", sFlowResult));
        }
        retArr = retArr.concat(doDxfOutFlowArrow(CDXFFile1, pElement, nDirect));
      }
      break;
    case EKind.MS_THERMAL:
      {
        let pThermal = {...pElement};
        let sFlowResult = doGetTextFlowsResultThermal(pThermal.properties, pDoc.m_nOpeMode, nDirect, false);
        if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
          retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pThermal.m_RefV1FlowResultTop, "", sFlowResult));
        }
        else {
          retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pThermal.m_RefV1FlowResultBottom, "", sFlowResult));
        }
        retArr = retArr.concat(doDxfOutFlowArrow(CDXFFile1, pElement, nDirect));
      }
      break;
    case EKind.MS_THERMAL_CT:
      {
        let pThermalCT = {...pElement};
        let sFlowResult = doGetTextFlowsResultThermalCT(pThermalCT.properties, pDoc.m_nOpeMode, nDirect, false);
        if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
          retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pThermalCT.m_RefV1FlowResultTop, "", sFlowResult));
        }
        else {
          retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pThermalCT.m_RefV1FlowResultBottom, "", sFlowResult));
        }
        retArr = retArr.concat(doDxfOutFlowArrow(CDXFFile1, pElement, nDirect));
      }
      break;
    case EKind.MS_MOTOR_GROUP:
      {
        let pMotorGrp = {...pElement};
        let sFlowResult = doGetTextFlowsResultMotorGrp(pMotorGrp.properties, pDoc.m_nOpeMode, nDirect, false);
        retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pMotorGrp.m_RefV1FlowResultTop, "", sFlowResult));
        retArr = retArr.concat(doDxfOutFlowArrow(CDXFFile1, pElement, nDirect));
      }
      break;
    case EKind.MS_MOTOR:
      {
        let pMotor = {...pElement};
        let sFlowResult = doGetTextFlowsResultMotor(pMotor.properties, pDoc.m_nOpeMode, nDirect, false);
        retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pMotor.m_RefV1FlowResultTop, "", sFlowResult));
        retArr = retArr.concat(doDxfOutFlowArrow(CDXFFile1, pElement, nDirect));
      }
      break;
    case EKind.MS_LOAD:
      {
        let pLoad = {...pElement};
        let sFlowResult = doGetTextFlowsResultLoad(pLoad.properties, pDoc.m_nOpeMode, nDirect, false);
        retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pLoad.m_RefV1FlowResultTop, "", sFlowResult));
        retArr = retArr.concat(doDxfOutFlowArrow(CDXFFile1, pElement, nDirect));
      }
      break;
    case EKind.MS_CAPACITOR:
      {
        let pCapacitor = {...pElement};
        let sFlowResult = doGetTextFlowsResultCapacitor(pCapacitor.properties, pDoc.m_nOpeMode, nDirect, false);
        retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pCapacitor.m_RefV1FlowResultTop, "", sFlowResult));
        retArr = retArr.concat(doDxfOutFlowArrow(CDXFFile1, pElement, nDirect));
      }
      break;
    case EKind.MS_LOADCENTER:
      break;
    case EKind.MS_VCT:
      {
        let pVCT = {...pElement};
        let sFlowResult = doGetTextFlowsResultVCT(pVCT.properties, pDoc.m_nOpeMode, nDirect, false);
        if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
          retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pVCT.m_RefV1FlowResultTop, "", sFlowResult));
        }
        else {
          retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pVCT.m_RefV1FlowResultBottom, "", sFlowResult));
        }
        retArr = retArr.concat(doDxfOutFlowArrow(CDXFFile1, pElement, nDirect));
      }
      break;
    case EKind.MS_INV:
      {
        let pINV = {...pElement};
        let sFlowResult = doGetTextFlowsResultINV(pINV.properties, pDoc.m_nOpeMode, nDirect, false);
        if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
          retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pINV.m_RefV1FlowResultTop, "", sFlowResult));
        }
        else {
          retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pINV.m_RefV1FlowResultBottom, "", sFlowResult));
        }
        retArr = retArr.concat(doDxfOutFlowArrow(CDXFFile1, pElement, nDirect));
      }
      break;
    case EKind.MS_RELAY:
      {
        let pRELAY = {...pElement};
        let sFlowResult = doGetTextFlowsResultRELAY(pRELAY.properties, pDoc.m_nOpeMode, nDirect, false);
        if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
          retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pRELAY.m_RefV1FlowResultTop, "", sFlowResult));
        }
        else {
          retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pRELAY.m_RefV1FlowResultBottom, "", sFlowResult));
        }
        retArr = retArr.concat(doDxfOutFlowArrow(CDXFFile1, pElement, nDirect));
      }
      break;
    case EKind.MS_ARRESTOR:
      break;
    case EKind.MS_EARTH:
      break;
    case EKind.MS_VT:
      {
        let pVT = {...pElement};
        let sFlowResult = doGetTextFlowsResultVT(pVT.properties, pDoc.m_nOpeMode, nDirect, false);
        if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
          retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pVT.m_RefV1FlowResultTop, "", sFlowResult));
        }
        else {
          retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pVT.m_RefV1FlowResultBottom, "", sFlowResult));
        }
        retArr = retArr.concat(doDxfOutFlowArrow(CDXFFile1, pElement, nDirect));
      }
      break;
    case EKind.MS_CABLEHEAD:
      break;
    case EKind.MS_CT:
      {
        let pCT = {...pElement};
        let sFlowResult = doGetTextFlowsResultCT(pCT.properties, pDoc.m_nOpeMode, nDirect, false);
        if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
          retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pCT.m_RefV1FlowResultTop, "", sFlowResult));
        }
        else {
          retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pCT.m_RefV1FlowResultBottom, "", sFlowResult));
        }
        retArr = retArr.concat(doDxfOutFlowArrow(CDXFFile1, pElement, nDirect));
      }
      break;
    case EKind.MS_ZCT:
      {
        let pZCT = {...pElement};
        let sFlowResult = doGetTextFlowsResultZCT(pZCT.properties, pDoc.m_nOpeMode, nDirect, false);
        if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
          retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pZCT.m_RefV1FlowResultTop, "", sFlowResult));
        }
        else {
          retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pZCT.m_RefV1FlowResultBottom, "", sFlowResult));
        }
        retArr = retArr.concat(doDxfOutFlowArrow(CDXFFile1, pElement, nDirect));
      }
      break;
    case EKind.MS_TEXT:
      break;
    case EKind.MS_LIGHTBOARD:
      {
        let pLightBoard = {...pElement};
        let sFlowResult = doGetTextFlowsResultLightBoard(pLightBoard.properties, pDoc.m_nOpeMode, nDirect, false);
        retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pLightBoard.m_RefV1FlowResultTop, "", sFlowResult));
        retArr = retArr.concat(doDxfOutFlowArrow(CDXFFile1, pElement, nDirect));
      }
      break;
    case EKind.MS_POWERBOARD:
      {
        let pPowerBoard = {...pElement};
        let sFlowResult = doGetTextFlowsResultPowerBoard(pPowerBoard.properties, pDoc.m_nOpeMode, nDirect, false);
        retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pPowerBoard.m_RefV1FlowResultTop, "", sFlowResult));
        retArr = retArr.concat(doDxfOutFlowArrow(CDXFFile1, pElement, nDirect));
      }
      break;
  }

  return retArr;
}

const doDxfOutSymbolSource = (
  CDXFFile1: any,
  pSource: any,
  nOpeMode: number
) => {
  let retArr: any[] = [];
  let rectBaseLP = getRectBaseLP(pSource);
  let rectCenter = {...rectBaseLP};
  rectCenter.bottom = rectCenter.top + MS_FRAME_WIDTH * 2 + MS_DIF_WIDTH;
  let Center = getCenterPoint(rectCenter);

  switch (pSource.properties.symKind) {
  case 0:
  {
    let rect = {top: 0, left: 0, right: 0, bottom: 0};
    rect.left = Center.x - MS_SYMBOL_SIZE / 2;
    rect.right = Center.x + MS_SYMBOL_SIZE / 2;
    rect.top = Center.y - MS_SYMBOL_SIZE - MS_SYMBOL_SIZE / 5;
    rect.bottom = Center.y - MS_SYMBOL_SIZE / 5;
    retArr = retArr.concat(CIRCLE(CDXFFile1, rect));

    rect.left = Center.x - MS_SYMBOL_SIZE / 3 + 1;
    rect.top = Center.y - MS_SYMBOL_SIZE / 3 - MS_SYMBOL_SIZE / 2;
    rect.right = Center.x + 1;
    rect.bottom = Center.y - MS_SYMBOL_SIZE / 2;
    let point = {x: 0, y: 0};
    point.x = rect.left;
    point.y = Center.y - MS_SYMBOL_SIZE / 6 - MS_SYMBOL_SIZE / 2;
    let Center2 = {...Center};
    Center2.y -= MS_SYMBOL_SIZE / 6 + MS_SYMBOL_SIZE / 2;
    retArr = retArr.concat(ARC_Top(CDXFFile1, rect, Center2, point));

    rect.left = Center.x;
    rect.top = Center.y - MS_SYMBOL_SIZE / 3 - MS_SYMBOL_SIZE / 2;
    rect.right = Center.x + MS_SYMBOL_SIZE / 3;
    rect.bottom = Center.y - MS_SYMBOL_SIZE / 2;
    point.x = rect.right;
    point.y = Center.y - MS_SYMBOL_SIZE / 6 - MS_SYMBOL_SIZE / 2;
    let Center3 = {...Center};
    Center3.y -= MS_SYMBOL_SIZE / 6 + MS_SYMBOL_SIZE / 2;
    retArr = retArr.concat(ARC_Bottom(CDXFFile1, rect, Center3, point));

    if (nOpeMode != MS_MODE_PERCENT_Z) {
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.bottom, Center.x, Center.y - MS_SYMBOL_SIZE / 4));
    }
    else {
      let nYdiff = MS_SYMBOL_SIZE;
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, Center.y - MS_SYMBOL_SIZE / 4 - 1, Center.x, Center.y - 30 + nYdiff));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, Center.y - 30 + nYdiff, Center.x + 10, Center.y - 25 + nYdiff));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 10, Center.y - 25 + nYdiff, Center.x - 10, Center.y - 15 + nYdiff));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 10, Center.y - 15 + nYdiff, Center.x + 10, Center.y - 5 + nYdiff));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 10, Center.y - 5 + nYdiff, Center.x - 10, Center.y + 5 + nYdiff));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 10, Center.y + 5 + nYdiff, Center.x + 10, Center.y + 15 + nYdiff));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 10, Center.y + 15 + nYdiff, Center.x - 10, Center.y + 25 + nYdiff));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 10, Center.y + 25 + nYdiff, Center.x - 0, Center.y + 30 + nYdiff));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 0, Center.y + 30 + nYdiff, Center.x, rectBaseLP.bottom + 1));
    }
  }
    break;
  case 1:
  {
    let rect = {top: 0, left: 0, right: 0, bottom: 0};
    rect.left = rectBaseLP.left - 1;
    rect.top = rectBaseLP.top + MS_SYMBOL_SIZE / 2;
    rect.right = rectBaseLP.right + 1;
    rect.bottom = rect.top + MS_SYMBOL_SIZE / 4 + 1;

    SOLID(
      CDXFFile1,
      rect.left, rect.top,
      rect.right, rect.top,
      rect.left, rect.bottom,
      rect.right, rect.bottom);

    if (nOpeMode != MS_MODE_PERCENT_Z) {
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.bottom, Center.x, rect.bottom - 1));
    }
    else {
      let nYdiff = MS_SYMBOL_SIZE;
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, rect.bottom - 1, Center.x, Center.y - 30 + nYdiff));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, Center.y - 30 + nYdiff, Center.x + 10, Center.y - 25 + nYdiff));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 10, Center.y - 25 + nYdiff, Center.x - 10, Center.y - 15 + nYdiff));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 10, Center.y - 15 + nYdiff, Center.x + 10, Center.y - 5 + nYdiff));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 10, Center.y - 5 + nYdiff, Center.x - 10, Center.y + 5 + nYdiff));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 10, Center.y + 5 + nYdiff, Center.x + 10, Center.y + 15 + nYdiff));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 10, Center.y + 15 + nYdiff, Center.x - 10, Center.y + 25 + nYdiff));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 10, Center.y + 25 + nYdiff, Center.x - 0, Center.y + 30 + nYdiff));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 0, Center.y + 30 + nYdiff, Center.x, rectBaseLP.bottom + 1));
    }
  }
    break;
  }

  return retArr
}

const doDxfOutSymbolGenerator = (
  CDXFFile1: any,
  pGenerator: any,
  nOpeMode: number
) => {
  let retArr: any[] = [];
  let rectBaseLP = getRectBaseLP(pGenerator);
  let rectCenter = {...rectBaseLP};
  rectCenter.bottom = rectCenter.top + MS_FRAME_WIDTH * 2 + MS_DIF_WIDTH;
  let Center = getCenterPoint(rectCenter);

  let rect = {top: 0, left: 0, right: 0, bottom: 0};
  rect.left = Center.x - MS_SYMBOL_SIZE / 2;
  rect.right = Center.x + MS_SYMBOL_SIZE / 2;
  rect.top = Center.y - MS_SYMBOL_SIZE - MS_SYMBOL_SIZE / 5;
  rect.bottom = Center.y - MS_SYMBOL_SIZE / 5;
  retArr = retArr.concat(CIRCLE(CDXFFile1, rect));

  if (nOpeMode != MS_MODE_PERCENT_Z) {
    retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.bottom, Center.x, Center.y - MS_SYMBOL_SIZE / 4));
  }
  else {
    let nYdiff = MS_SYMBOL_SIZE;
    retArr = retArr.concat(LINE(CDXFFile1, Center.x, Center.y - MS_SYMBOL_SIZE / 4 - 1, Center.x, Center.y - 30 + nYdiff));
    retArr = retArr.concat(LINE(CDXFFile1, Center.x, Center.y - 30 + nYdiff, Center.x + 10, Center.y - 25 + nYdiff));
    retArr = retArr.concat(LINE(CDXFFile1, Center.x + 10, Center.y - 25 + nYdiff, Center.x - 10, Center.y - 15 + nYdiff));
    retArr = retArr.concat(LINE(CDXFFile1, Center.x - 10, Center.y - 15 + nYdiff, Center.x + 10, Center.y - 5 + nYdiff));
    retArr = retArr.concat(LINE(CDXFFile1, Center.x + 10, Center.y - 5 + nYdiff, Center.x - 10, Center.y + 5 + nYdiff));
    retArr = retArr.concat(LINE(CDXFFile1, Center.x - 10, Center.y + 5 + nYdiff, Center.x + 10, Center.y + 15 + nYdiff));
    retArr = retArr.concat(LINE(CDXFFile1, Center.x + 10, Center.y + 15 + nYdiff, Center.x - 10, Center.y + 25 + nYdiff));
    retArr = retArr.concat(LINE(CDXFFile1, Center.x - 10, Center.y + 25 + nYdiff, Center.x - 0, Center.y + 30 + nYdiff));
    retArr = retArr.concat(LINE(CDXFFile1, Center.x - 0, Center.y + 30 + nYdiff, Center.x, rectBaseLP.bottom + 1));
  }

  let rectCenterPoint = getCenterPoint(rect);
  retArr = retArr.concat(STRING(CDXFFile1, rectCenterPoint.x - 4, rectCenterPoint.y, 10, "G"));

  return retArr;
}

const doDxfOutSymbolTrans3 = (
  CDXFFile1: any,
  pTrans3: any,
  nOpeMode: number
) => {
  let retArr: any[] = [];
  let rectBaseLP = getRectBaseLP(pTrans3);
  let rectCenter = {...rectBaseLP};
  let Center = getCenterPoint(rectCenter);

  if (nOpeMode != MS_MODE_PERCENT_Z) {
    retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.top - 1, Center.x, Center.y - MS_BASE_HEIGHT + MS_SYMBOL_SIZE * 33 / 50));

    let rect = {top: 0, left: 0, right: 0, bottom: 0};
    rect.left = Center.x - MS_SYMBOL_SIZE / 2;
    rect.right = Center.x + MS_SYMBOL_SIZE / 2;
    rect.top = Center.y - MS_BASE_HEIGHT + MS_SYMBOL_SIZE * 33 / 50;
    rect.bottom = Center.y - MS_BASE_HEIGHT + MS_SYMBOL_SIZE + MS_SYMBOL_SIZE * 31 / 50;
    retArr = retArr.concat(CIRCLE(CDXFFile1, rect));

    rect.left = Center.x - MS_SYMBOL_SIZE / 2;
    rect.right = Center.x + MS_SYMBOL_SIZE / 2;
    rect.top = Center.y + MS_BASE_HEIGHT - MS_SYMBOL_SIZE - MS_SYMBOL_SIZE * 31 / 50;
    rect.bottom = Center.y + MS_BASE_HEIGHT - MS_SYMBOL_SIZE * 33 / 50;
    retArr = retArr.concat(CIRCLE(CDXFFile1, rect));

    retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.bottom + 1, Center.x, Center.y + MS_BASE_HEIGHT - MS_SYMBOL_SIZE * 34 / 50 + 1));

    let yy = Math.floor(MS_SYMBOL_SIZE / 20);

    switch (pTrans3.properties.symKind) {
    case 0:
      break;
    case 1:
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 5 * yy, Center.y - 17 * yy, Center.x, Center.y - 12 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, Center.y - 12 * yy, Center.x, Center.y - 6 * yy));
    
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 5 * yy, Center.y - 17 * yy, Center.x, Center.y - 12 * yy));
    
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, Center.y + 6 * yy, Center.x - 5 * yy, Center.y + 16 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 5 * yy, Center.y + 16 * yy, Center.x + 5 * yy, Center.y + 16 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 5 * yy, Center.y + 16 * yy, Center.x, Center.y + 6 * yy));
      break;
    case 2:
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, Center.y - 18 * yy, Center.x - 5 * yy, Center.y - 8 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 5 * yy, Center.y - 8 * yy, Center.x + 5 * yy, Center.y - 8 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 5 * yy, Center.y - 8 * yy, Center.x, Center.y - 18 * yy));

      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 5 * yy, Center.y + 6 * yy, Center.x, Center.y + 11 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, Center.y + 11 * yy, Center.x, Center.y + 17 * yy));
    
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 5 * yy, Center.y + 6 * yy, Center.x, Center.y + 11 * yy));
      break;
    case 3:
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 5 * yy, Center.y - 17 * yy, Center.x, Center.y - 12 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, Center.y - 12 * yy, Center.x, Center.y - 6 * yy));
    
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 5 * yy, Center.y - 17 * yy, Center.x, Center.y - 12 * yy));

      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 5 * yy, Center.y + 6 * yy, Center.x, Center.y + 11 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, Center.y + 11 * yy, Center.x, Center.y + 17 * yy));
    
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 5 * yy, Center.y + 6 * yy, Center.x, Center.y + 11 * yy));
      break;
    case 4:
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, Center.y - 18 * yy, Center.x - 5 * yy, Center.y - 8 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 5 * yy, Center.y - 8 * yy, Center.x + 5 * yy, Center.y - 8 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 5 * yy, Center.y - 8 * yy, Center.x, Center.y - 18 * yy));
    
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, Center.y + 6 * yy, Center.x - 5 * yy, Center.y + 16 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 5 * yy, Center.y + 16 * yy, Center.x + 5 * yy, Center.y + 16 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 5 * yy, Center.y + 16 * yy, Center.x, Center.y + 6 * yy));
      break;
    }

    if (pTrans3.m_bHaveTab == true) {
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 18 * yy, Center.y + 6 * yy, Center.x + 18 * yy, Center.y - 6 * yy));
      retArr = retArr.concat(SOLID(
        CDXFFile1,
        Center.x + 18 * yy, Center.y - 6 * yy,
        Center.x + 12 * yy, Center.y - 6 * yy,
        Center.x + 13 * yy, Center.y - 2 * yy,
        Center.x + 18 * yy, Center.y - 6 * yy
      ));
    }
  }
  else {
    retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.top - 1, Center.x, Center.y - 30));
    retArr = retArr.concat(LINE(CDXFFile1, Center.x, Center.y - 30, Center.x + 10, Center.y - 25));
    retArr = retArr.concat(LINE(CDXFFile1, Center.x + 10, Center.y - 25, Center.x - 10, Center.y - 15));
    retArr = retArr.concat(LINE(CDXFFile1, Center.x - 10, Center.y - 15, Center.x + 10, Center.y - 5));
    retArr = retArr.concat(LINE(CDXFFile1, Center.x + 10, Center.y - 5, Center.x - 10, Center.y + 5));
    retArr = retArr.concat(LINE(CDXFFile1, Center.x - 10, Center.y + 5, Center.x + 10, Center.y + 15));
    retArr = retArr.concat(LINE(CDXFFile1, Center.x + 10, Center.y + 15, Center.x - 10, Center.y + 25));
    retArr = retArr.concat(LINE(CDXFFile1, Center.x - 10, Center.y + 25, Center.x - 0, Center.y + 30));
    retArr = retArr.concat(LINE(CDXFFile1, Center.x - 0, Center.y + 30, Center.x, rectBaseLP.bottom + 1));
  }

  return retArr;
}

const doDxfOutSymbolTransCenter = (
  CDXFFile1: any,
  pTransCenter: any
) => {
  let retArr: any[] = [];
  let rectBaseLP = getRectBaseLP(pTransCenter);
  let rectCenter = {...rectBaseLP};
  let Center = getCenterPoint(rectCenter);

  retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.top - 1, Center.x, rectBaseLP.bottom - 5 * MS_SYMBOL_SIZE / 4));

  retArr = retArr.concat(SOLID(
    CDXFFile1, 
    Center.x - MS_SYMBOL_SIZE / 2, rectBaseLP.bottom - 5 * MS_SYMBOL_SIZE / 4,
    Center.x + MS_SYMBOL_SIZE / 2, rectBaseLP.bottom - 5 * MS_SYMBOL_SIZE / 4,
    Center.x, rectBaseLP.bottom - MS_SYMBOL_SIZE / 4,
    Center.x - MS_SYMBOL_SIZE / 2, rectBaseLP.bottom - 5 * MS_SYMBOL_SIZE / 4
  ));

  return retArr;
}

const doDxfOutSymbol3Winding = (
  CDXFFile1: any,
  p3Winding: any,
  nOpeMode: number
) => {
  {
    let retArr: any[] = [];
    let rectBaseLP = getRectBaseLP(p3Winding);
    let rectCenter = {...rectBaseLP};
    let Center = getCenterPoint(rectCenter);
  
    if (nOpeMode != MS_MODE_PERCENT_Z) {
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.top - 1, Center.x, Center.y + MS_BASE_HEIGHT - MS_SYMBOL_SIZE * 3));
  
      let rect = {top: 0, left: 0, right: 0, bottom: 0};
      rect.left = Center.x - MS_SYMBOL_SIZE / 2;
      rect.right = Center.x + MS_SYMBOL_SIZE / 2;
      rect.top = Center.y + (MS_BASE_HEIGHT - MS_SYMBOL_SIZE) - MS_SYMBOL_SIZE * 2;
      rect.bottom = Center.y + MS_BASE_HEIGHT - MS_SYMBOL_SIZE * 2;
      retArr = retArr.concat(CIRCLE(CDXFFile1, rect));
  
      rect.left = Center.x - MS_SYMBOL_SIZE * 3 / 4;
      rect.right = Center.x + MS_SYMBOL_SIZE / 4;
      rect.top = Center.y + (MS_BASE_HEIGHT - MS_SYMBOL_SIZE) - MS_SYMBOL_SIZE - MS_SYMBOL_SIZE / 2;
      rect.bottom = Center.y + MS_BASE_HEIGHT - MS_SYMBOL_SIZE - MS_SYMBOL_SIZE / 2;
      retArr = retArr.concat(CIRCLE(CDXFFile1, rect));

      let rectSize = getSizeRectTotal(rect)
      retArr = retArr.concat(LINE(CDXFFile1, rect.left + rectSize.cx / 2, rect.bottom, rect.left + rectSize.cx / 2, rectBaseLP.bottom - MS_FRAME_HEIGHT / 2));
      retArr = retArr.concat(LINE(CDXFFile1, rect.left + rectSize.cx / 2, rectBaseLP.bottom - MS_FRAME_HEIGHT / 2, rectBaseLP.left - MS_DIF_WIDTH, rectBaseLP.bottom - MS_FRAME_HEIGHT / 2));
  
      rect.left = Center.x - MS_SYMBOL_SIZE / 4;
      rect.right = Center.x + MS_SYMBOL_SIZE * 3 / 4;
      rect.top = Center.y + (MS_BASE_HEIGHT - MS_SYMBOL_SIZE) - MS_SYMBOL_SIZE - MS_SYMBOL_SIZE / 2;
      rect.bottom = Center.y + MS_BASE_HEIGHT - MS_SYMBOL_SIZE - MS_SYMBOL_SIZE / 2;
      retArr = retArr.concat(CIRCLE(CDXFFile1, rect));
  
      retArr = retArr.concat(LINE(CDXFFile1, rect.left + rectSize.cx / 2, rect.bottom, rect.left + rectSize.cx / 2, rectBaseLP.bottom - MS_FRAME_HEIGHT / 2));
      retArr = retArr.concat(LINE(CDXFFile1, rect.left + rectSize.cx / 2, rectBaseLP.bottom - MS_FRAME_HEIGHT / 2, rectBaseLP.right + 1, rectBaseLP.bottom - MS_FRAME_HEIGHT / 2));
  
      let yy = Math.floor(MS_SYMBOL_SIZE / 20);
      Center.y -= MS_BASE_HEIGHT / 2;
  
      switch (p3Winding.properties.symKind) {
      case 0:
        break;
      case 1:
        retArr = retArr.concat(LINE(CDXFFile1, Center.x - 5 * yy, Center.y - 18 * yy, Center.x, Center.y - 13 * yy));
        retArr = retArr.concat(LINE(CDXFFile1, Center.x, Center.y - 13 * yy, Center.x, Center.y - 7 * yy));
      
        retArr = retArr.concat(LINE(CDXFFile1, Center.x + 5 * yy, Center.y - 18 * yy, Center.x, Center.y - 13 * yy));
      
        retArr = retArr.concat(LINE(CDXFFile1, Center.x + 10 * yy, Center.y + 2 * yy, Center.x + 5 * yy, Center.y + 12 * yy));
        retArr = retArr.concat(LINE(CDXFFile1, Center.x + 5 * yy, Center.y + 12 * yy, Center.x + 15 * yy, Center.y + 12 * yy));
        retArr = retArr.concat(LINE(CDXFFile1, Center.x + 15 * yy, Center.y + 12 * yy, Center.x + 10 * yy, Center.y + 2 * yy));
  
        retArr = retArr.concat(LINE(CDXFFile1, Center.x - 17 * yy, Center.y + 2 * yy, Center.x - 11 * yy, Center.y + 8 * yy));
        retArr = retArr.concat(LINE(CDXFFile1, Center.x - 11 * yy, Center.y + 8 * yy, Center.x - 11 * yy, Center.y + 16 * yy));
      
        retArr = retArr.concat(LINE(CDXFFile1, Center.x - 5 * yy, Center.y + 2 * yy, Center.x - 11 * yy, Center.y + 8 * yy));
        break;
      case 7:
        retArr = retArr.concat(LINE(CDXFFile1, Center.x - 5 * yy, Center.y - 18 * yy, Center.x, Center.y - 13 * yy));
        retArr = retArr.concat(LINE(CDXFFile1, Center.x, Center.y - 13 * yy, Center.x, Center.y - 7 * yy));    
        retArr = retArr.concat(LINE(CDXFFile1, Center.x + 5 * yy, Center.y - 18 * yy, Center.x, Center.y - 13 * yy));
      
        retArr = retArr.concat(LINE(CDXFFile1, Center.x + 7 * yy, Center.y + 4 * yy, Center.x + 11 * yy, Center.y + 8 * yy));
        retArr = retArr.concat(LINE(CDXFFile1, Center.x + 11 * yy, Center.y + 8 * yy, Center.x + 11 * yy, Center.y + 14 * yy));    
        retArr = retArr.concat(LINE(CDXFFile1, Center.x + 15 * yy, Center.y + 4 * yy, Center.x + 11 * yy, Center.y + 8 * yy));
      
        retArr = retArr.concat(LINE(CDXFFile1, Center.x -  10 * yy, Center.y + 2 * yy, Center.x - 15 * yy, Center.y + 12 * yy));
        retArr = retArr.concat(LINE(CDXFFile1, Center.x - 15 * yy, Center.y + 12 * yy, Center.x - 5 * yy, Center.y + 12 * yy));
        retArr = retArr.concat(LINE(CDXFFile1, Center.x - 5 * yy, Center.y + 12 * yy, Center.x -  10 * yy, Center.y + 2 * yy));
        break;
      case 2:
        retArr = retArr.concat(LINE(CDXFFile1, Center.x - 5 * yy, Center.y - 18 * yy, Center.x, Center.y - 13 * yy));
        retArr = retArr.concat(LINE(CDXFFile1, Center.x, Center.y - 13 * yy, Center.x, Center.y - 7 * yy));
      
        retArr = retArr.concat(LINE(CDXFFile1, Center.x + 5 * yy, Center.y - 18 * yy, Center.x, Center.y - 13 * yy));
      
        retArr = retArr.concat(LINE(CDXFFile1, Center.x + 10 * yy, Center.y + 2 * yy, Center.x + 5 * yy, Center.y + 12 * yy));
        retArr = retArr.concat(LINE(CDXFFile1, Center.x + 5 * yy, Center.y + 12 * yy, Center.x + 15 * yy, Center.y + 12 * yy));
        retArr = retArr.concat(LINE(CDXFFile1, Center.x + 15 * yy, Center.y + 12 * yy, Center.x + 10 * yy, Center.y + 2 * yy));
      
        retArr = retArr.concat(LINE(CDXFFile1, Center.x -  10 * yy, Center.y + 2 * yy, Center.x - 15 * yy, Center.y + 12 * yy));
        retArr = retArr.concat(LINE(CDXFFile1, Center.x - 15 * yy, Center.y + 12 * yy, Center.x - 5 * yy, Center.y + 12 * yy));
        retArr = retArr.concat(LINE(CDXFFile1, Center.x - 5 * yy, Center.y + 12 * yy, Center.x -  10 * yy, Center.y + 2 * yy));
        break;
      case 3:
        retArr = retArr.concat(LINE(CDXFFile1, Center.x - 5 * yy, Center.y - 18 * yy, Center.x, Center.y - 13 * yy));
        retArr = retArr.concat(LINE(CDXFFile1, Center.x, Center.y - 13 * yy, Center.x, Center.y - 7 * yy));
      
        retArr = retArr.concat(LINE(CDXFFile1, Center.x + 5 * yy, Center.y - 18 * yy, Center.x, Center.y - 13 * yy));
      
        retArr = retArr.concat(LINE(CDXFFile1, Center.x + 10 * yy, Center.y + 2 * yy, Center.x + 5 * yy, Center.y + 12 * yy));
        retArr = retArr.concat(LINE(CDXFFile1, Center.x + 5 * yy, Center.y + 12 * yy, Center.x + 15 * yy, Center.y + 12 * yy));
        retArr = retArr.concat(LINE(CDXFFile1, Center.x + 15 * yy, Center.y + 12 * yy, Center.x + 10 * yy, Center.y + 2 * yy));
  
        retArr = retArr.concat(LINE(CDXFFile1, Center.x - 17 * yy, Center.y + 2 * yy, Center.x - 11 * yy, Center.y + 8 * yy));
        retArr = retArr.concat(LINE(CDXFFile1, Center.x - 11 * yy, Center.y + 8 * yy, Center.x - 11 * yy, Center.y + 16 * yy));
      
        retArr = retArr.concat(LINE(CDXFFile1, Center.x - 5 * yy, Center.y + 2 * yy, Center.x - 11 * yy, Center.y + 8 * yy));
  
        retArr = retArr.concat(LINE(CDXFFile1, Center.x - 11 * yy, Center.y + 8 * yy, Center.x - 6 * yy, Center.y + 8 * yy));
        break;
      case 4:
        retArr = retArr.concat(LINE(CDXFFile1, Center.x, Center.y - 21 * yy, Center.x - 5 * yy, Center.y - 11 * yy));
        retArr = retArr.concat(LINE(CDXFFile1, Center.x - 5 * yy, Center.y - 11 * yy, Center.x + 5 * yy, Center.y - 11 * yy));
        retArr = retArr.concat(LINE(CDXFFile1, Center.x + 5 * yy, Center.y - 11 * yy, Center.x, Center.y - 21 * yy));
      
        retArr = retArr.concat(LINE(CDXFFile1, Center.x + 10 * yy, Center.y + 2 * yy, Center.x + 5 * yy, Center.y + 12 * yy));
        retArr = retArr.concat(LINE(CDXFFile1, Center.x + 5 * yy, Center.y + 12 * yy, Center.x + 15 * yy, Center.y + 12 * yy));
        retArr = retArr.concat(LINE(CDXFFile1, Center.x + 15 * yy, Center.y + 12 * yy, Center.x + 10 * yy, Center.y + 2 * yy));
  
        retArr = retArr.concat(LINE(CDXFFile1, Center.x - 17 * yy, Center.y + 2 * yy, Center.x - 11 * yy, Center.y + 8 * yy));
        retArr = retArr.concat(LINE(CDXFFile1, Center.x - 11 * yy, Center.y + 8 * yy, Center.x - 11 * yy, Center.y + 16 * yy));
      
        retArr = retArr.concat(LINE(CDXFFile1, Center.x - 5 * yy, Center.y + 2 * yy, Center.x - 11 * yy, Center.y + 8 * yy));
        break;
      case 5:
        retArr = retArr.concat(LINE(CDXFFile1, Center.x, Center.y - 21 * yy, Center.x - 5 * yy, Center.y - 11 * yy));
        retArr = retArr.concat(LINE(CDXFFile1, Center.x - 5 * yy, Center.y - 11 * yy, Center.x + 5 * yy, Center.y - 11 * yy));
        retArr = retArr.concat(LINE(CDXFFile1, Center.x + 5 * yy, Center.y - 11 * yy, Center.x, Center.y - 21 * yy));
      
        retArr = retArr.concat(LINE(CDXFFile1, Center.x + 10 * yy, Center.y + 2 * yy, Center.x + 5 * yy, Center.y + 12 * yy));
        retArr = retArr.concat(LINE(CDXFFile1, Center.x + 5 * yy, Center.y + 12 * yy, Center.x + 15 * yy, Center.y + 12 * yy));
        retArr = retArr.concat(LINE(CDXFFile1, Center.x + 15 * yy, Center.y + 12 * yy, Center.x + 10 * yy, Center.y + 2 * yy));
      
        retArr = retArr.concat(LINE(CDXFFile1, Center.x -  10 * yy, Center.y + 2 * yy, Center.x - 15 * yy, Center.y + 12 * yy));
        retArr = retArr.concat(LINE(CDXFFile1, Center.x - 15 * yy, Center.y + 12 * yy, Center.x - 5 * yy, Center.y + 12 * yy));
        retArr = retArr.concat(LINE(CDXFFile1, Center.x - 5 * yy, Center.y + 12 * yy, Center.x -  10 * yy, Center.y + 2 * yy));
        break;
      case 6:
        retArr = retArr.concat(LINE(CDXFFile1, Center.x, Center.y - 21 * yy, Center.x - 5 * yy, Center.y - 11 * yy));
        retArr = retArr.concat(LINE(CDXFFile1, Center.x - 5 * yy, Center.y - 11 * yy, Center.x + 5 * yy, Center.y - 11 * yy));
        retArr = retArr.concat(LINE(CDXFFile1, Center.x + 5 * yy, Center.y - 11 * yy, Center.x, Center.y - 21 * yy));
      
        retArr = retArr.concat(LINE(CDXFFile1, Center.x + 10 * yy, Center.y + 2 * yy, Center.x + 5 * yy, Center.y + 12 * yy));
        retArr = retArr.concat(LINE(CDXFFile1, Center.x + 5 * yy, Center.y + 12 * yy, Center.x + 15 * yy, Center.y + 12 * yy));
        retArr = retArr.concat(LINE(CDXFFile1, Center.x + 15 * yy, Center.y + 12 * yy, Center.x + 10 * yy, Center.y + 2 * yy));
  
        retArr = retArr.concat(LINE(CDXFFile1, Center.x - 17 * yy, Center.y + 2 * yy, Center.x - 11 * yy, Center.y + 8 * yy));
        retArr = retArr.concat(LINE(CDXFFile1, Center.x - 11 * yy, Center.y + 8 * yy, Center.x - 11 * yy, Center.y + 16 * yy));
      
        retArr = retArr.concat(LINE(CDXFFile1, Center.x - 5 * yy, Center.y + 2 * yy, Center.x - 11 * yy, Center.y + 8 * yy));
  
        retArr = retArr.concat(LINE(CDXFFile1, Center.x - 11 * yy, Center.y + 8 * yy, Center.x - 6 * yy, Center.y + 8 * yy));
        break;
      }
    }
    else {
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.top - 1, Center.x, Center.y - 68));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, Center.y - 68, Center.x + 8, Center.y - 64));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 8, Center.y - 64, Center.x - 8, Center.y - 56));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 8, Center.y - 56, Center.x + 8, Center.y - 48));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 8, Center.y - 48, Center.x - 8, Center.y - 40));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 8, Center.y - 40, Center.x + 8, Center.y - 32));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 8, Center.y - 32, Center.x - 8, Center.y - 24));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 8, Center.y - 24, Center.x, Center.y - 20));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, Center.y - 20, Center.x, Center.y));
  
      
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, Center.y, Center.x + 10, Center.y + 10));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 10, Center.y + 10, Center.x + 10, Center.y + 20));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 10, Center.y + 20, Center.x + 18, Center.y + 24));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 18, Center.y + 24, Center.x + 2, Center.y + 32));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 2, Center.y + 32, Center.x + 18, Center.y + 40));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 18, Center.y + 40, Center.x + 2, Center.y + 48));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 2, Center.y + 48, Center.x + 18, Center.y + 56));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 18, Center.y + 56, Center.x + 2, Center.y + 64));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 2, Center.y + 64, Center.x + 10, Center.y + 68));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 10, Center.y + 68, Center.x + 10, rectBaseLP.bottom - MS_FRAME_HEIGHT / 2));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 10, rectBaseLP.bottom - MS_FRAME_HEIGHT / 2, rectBaseLP.right, rectBaseLP.bottom - MS_FRAME_HEIGHT / 2));
      
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, Center.y, Center.x - 10, Center.y + 10));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 10, Center.y + 10, Center.x - 10, Center.y + 20));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 10, Center.y + 20, Center.x - 2, Center.y + 24));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 2, Center.y + 24, Center.x - 18, Center.y + 32));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 18, Center.y + 32, Center.x - 2, Center.y + 40));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 2, Center.y + 40, Center.x - 18, Center.y + 48));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 18, Center.y + 48, Center.x - 2, Center.y + 56));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 2, Center.y + 56, Center.x - 18, Center.y + 64));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 18, Center.y + 64, Center.x - 10, Center.y + 68));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 10, Center.y + 68, Center.x - 10, rectBaseLP.bottom - MS_FRAME_HEIGHT / 2));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 10, rectBaseLP.bottom - MS_FRAME_HEIGHT / 2, rectBaseLP.left - MS_DIF_WIDTH, rectBaseLP.bottom - MS_FRAME_HEIGHT / 2));
    }
  
    return retArr;
  }
}

const doDxfOutSymbolTrans1 = (
  CDXFFile1: any,
  pTrans1: any,
  nOpeMode: number
) => {
  let retArr: any[] = [];

  let rectBaseLP = getRectBaseLP(pTrans1);
  let rectCenter = {...rectBaseLP};
  let Center = getCenterPoint(rectCenter);

  if (nOpeMode != MS_MODE_PERCENT_Z) {
    retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.top - 1, Center.x, Center.y - MS_BASE_HEIGHT + MS_SYMBOL_SIZE * 33 / 50));

    let rect = {top: 0, left: 0, right: 0, bottom: 0};
    rect.left = Center.x - MS_SYMBOL_SIZE / 2;
    rect.right = Center.x + MS_SYMBOL_SIZE / 2;
    rect.top = Center.y - MS_BASE_HEIGHT + MS_SYMBOL_SIZE * 33 / 50;
    rect.bottom = Center.y - MS_BASE_HEIGHT + MS_SYMBOL_SIZE + MS_SYMBOL_SIZE * 31 / 50;
    retArr = retArr.concat(CIRCLE(CDXFFile1, rect));

    rect.left = Center.x - MS_SYMBOL_SIZE / 2;
    rect.right = Center.x + MS_SYMBOL_SIZE / 2;
    rect.top = Center.y + MS_BASE_HEIGHT - MS_SYMBOL_SIZE - MS_SYMBOL_SIZE * 31 / 50;
    rect.bottom = Center.y + MS_BASE_HEIGHT - MS_SYMBOL_SIZE * 33 / 50;
    retArr = retArr.concat(CIRCLE(CDXFFile1, rect));

    retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.bottom + 1, Center.x, Center.y + MS_BASE_HEIGHT - MS_SYMBOL_SIZE * 34 / 50 + 1));

    let yy = Math.floor(MS_SYMBOL_SIZE / 20);
    switch (pTrans1.properties.symKind) {
      case 0:
        retArr = retArr.concat(LINE(CDXFFile1, Center.x, rect.top, Center.x, rect.top - MS_BASE_HEIGHT / 8));
        retArr = retArr.concat(LINE(CDXFFile1, Center.x, rect.top - MS_BASE_HEIGHT / 8, Center.x + MS_BASE_WIDTH / 2 - 1, rect.top - MS_BASE_HEIGHT / 8));
        break;
      case 1:
        retArr = retArr.concat(LINE(CDXFFile1, Center.x - 5 * yy, Center.y - 10 * yy, Center.x + 5 * yy, Center.y - 10 * yy));
      
        retArr = retArr.concat(LINE(CDXFFile1, Center.x - 5 * yy, Center.y + 6 * yy, Center.x + 5 * yy, Center.y + 6 * yy));
        retArr = retArr.concat(LINE(CDXFFile1, Center.x - 5 * yy, Center.y + 10 * yy, Center.x + 5 * yy, Center.y + 10 * yy));
        retArr = retArr.concat(LINE(CDXFFile1, Center.x - 5 * yy, Center.y + 14 * yy, Center.x + 5 * yy, Center.y + 14 * yy));
        break;
    }
  } else {
    retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.top - 1, Center.x, Center.y - 30));
    retArr = retArr.concat(LINE(CDXFFile1, Center.x, Center.y - 30, Center.x + 10, Center.y - 25));
    retArr = retArr.concat(LINE(CDXFFile1, Center.x + 10, Center.y - 25, Center.x - 10, Center.y - 15));
    retArr = retArr.concat(LINE(CDXFFile1, Center.x - 10, Center.y - 15, Center.x + 10, Center.y - 5));
    retArr = retArr.concat(LINE(CDXFFile1, Center.x + 10, Center.y - 5, Center.x - 10, Center.y + 5));
    retArr = retArr.concat(LINE(CDXFFile1, Center.x - 10, Center.y + 5, Center.x + 10, Center.y + 15));
    retArr = retArr.concat(LINE(CDXFFile1, Center.x + 10, Center.y + 15, Center.x - 10, Center.y + 25));
    retArr = retArr.concat(LINE(CDXFFile1, Center.x - 10, Center.y + 25, Center.x - 0, Center.y + 30));
    retArr = retArr.concat(LINE(CDXFFile1, Center.x - 0, Center.y + 30, Center.x, rectBaseLP.bottom + 1));
  }

  return retArr;
}

const doDxfOutSymbolTransScott = (
  CDXFFile1: any,
  pTransScott: any,
  nOpeMode: number
) => {
  let retArr: any[] = [];

  let rectBaseLP = getRectBaseLP(pTransScott);
  let rectCenter = {...rectBaseLP};
  let Center = getCenterPoint(rectCenter);

  if (nOpeMode != MS_MODE_PERCENT_Z) {
    let yy = Math.floor(MS_SYMBOL_SIZE / 2);

    retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.top - 1, Center.x, Center.y - MS_BASE_HEIGHT + MS_SYMBOL_SIZE * 33 / 50));

    let rect = {top: 0, left: 0, right: 0, bottom: 0};
    rect.left = Center.x - yy;
    rect.right = Center.x + yy;
    rect.top = Center.y - MS_BASE_HEIGHT + MS_SYMBOL_SIZE * 33 / 50;
    rect.bottom = Center.y - MS_BASE_HEIGHT + MS_SYMBOL_SIZE + MS_SYMBOL_SIZE * 31 / 50;
    retArr = retArr.concat(CIRCLE(CDXFFile1, rect));

    rect.left = Center.x - yy;
    rect.right = Center.x + yy;
    rect.top = Center.y + MS_BASE_HEIGHT - MS_SYMBOL_SIZE - MS_SYMBOL_SIZE * 31 / 50;
    rect.bottom = Center.y + MS_BASE_HEIGHT - MS_SYMBOL_SIZE * 33 / 50;
    retArr = retArr.concat(CIRCLE(CDXFFile1, rect));

    retArr = retArr.concat(LINE(CDXFFile1, Center.x,  Center.y - MS_BASE_HEIGHT + MS_SYMBOL_SIZE * 40 / 50, Center.x, Center.y - MS_BASE_HEIGHT + MS_SYMBOL_SIZE * 63 / 50 - 2));
    retArr = retArr.concat(LINE(CDXFFile1, Center.x - yy / 2, Center.y - MS_BASE_HEIGHT + MS_SYMBOL_SIZE * 63 / 50 - 2, Center.x + yy / 2 + 1, Center.y - MS_BASE_HEIGHT + MS_SYMBOL_SIZE * 63 / 50 - 2));
    retArr = retArr.concat(LINE(CDXFFile1, Center.x - yy / 2 + 1, Center.y - MS_BASE_HEIGHT + MS_SYMBOL_SIZE * 85 / 50, Center.x - yy / 2 + 1, Center.y - MS_BASE_HEIGHT + 2 * MS_SYMBOL_SIZE));
    retArr = retArr.concat(LINE(CDXFFile1, Center.x - yy / 2 + 1, Center.y - MS_BASE_HEIGHT + 2 * MS_SYMBOL_SIZE, Center.x + yy / 2 - 1, Center.y - MS_BASE_HEIGHT + 2 * MS_SYMBOL_SIZE));
    retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.bottom + 1, Center.x, Center.y + MS_BASE_HEIGHT - MS_SYMBOL_SIZE * 34 / 50 + 1));
  }
  else {
    retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.top - 1, Center.x, Center.y - 30));
    retArr = retArr.concat(LINE(CDXFFile1, Center.x, Center.y - 30, Center.x + 10, Center.y - 25));
    retArr = retArr.concat(LINE(CDXFFile1, Center.x + 10, Center.y - 25, Center.x - 10, Center.y - 15));
    retArr = retArr.concat(LINE(CDXFFile1, Center.x - 10, Center.y - 15, Center.x + 10, Center.y - 5));
    retArr = retArr.concat(LINE(CDXFFile1, Center.x + 10, Center.y - 5, Center.x - 10, Center.y + 5));
    retArr = retArr.concat(LINE(CDXFFile1, Center.x - 10, Center.y + 5, Center.x + 10, Center.y + 15));
    retArr = retArr.concat(LINE(CDXFFile1, Center.x + 10, Center.y + 15, Center.x - 10, Center.y + 25));
    retArr = retArr.concat(LINE(CDXFFile1, Center.x - 10, Center.y + 25, Center.x - 0, Center.y + 30));
    retArr = retArr.concat(LINE(CDXFFile1, Center.x - 0, Center.y + 30, Center.x, rectBaseLP.bottom + 1));
  }

  return retArr;
}

const doDxfOutSymbolDS = (CDXFFile1: any, pDS: any) => {
  let retArr: any[] = [];

  let rectBaseLP = getRectBaseLP(pDS);
  let rectCenter = {...rectBaseLP};
  let Center = getCenterPoint(rectCenter);
  let point = {...Center};

  let yy = 0;
  switch (pDS.properties.exchangeNumber) {
  case 0:
    yy = Math.floor(MS_SYMBOL_SIZE / 20);
    switch (pDS.rotation) {
    case 0:
      point.y = Center.y - MS_BASE_HEIGHT + 13 * yy;

      retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.top - 1, Center.x, point.y));
      retArr = retArr.concat(LINE(CDXFFile1, point.x - 6 * yy, point.y, point.x + 6 * yy + 1, point.y));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 17 * yy, point.y, Center.x, Center.y + MS_BASE_HEIGHT - 22 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.bottom + 1, Center.x, Center.y +  MS_BASE_HEIGHT - 22 * yy - 1));
      break;
    case -90:
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, Center.y, Center.x - MS_BASE_WIDTH + 13 * yy, Center.y));

      point.x = Center.x - MS_BASE_HEIGHT + 13 * yy;

      retArr = retArr.concat(LINE(CDXFFile1, point.x, point.y - 6 * yy, point.x, point.y + 6 * yy + 1));
      retArr = retArr.concat(LINE(CDXFFile1, point.x, Center.y + 17 * yy, Center.x + MS_BASE_WIDTH - 22 * yy, Center.y));
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.right + 1, Center.y, Center.x + MS_BASE_HEIGHT - 22 * yy - 1, Center.y));
      break;
    case 90:
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.right + 1, Center.y, Center.x + MS_BASE_WIDTH - MS_SYMBOL_SIZE / 2, Center.y));

      point.x = Center.x + MS_BASE_HEIGHT - MS_SYMBOL_SIZE / 2;

      retArr = retArr.concat(LINE(CDXFFile1, point.x, point.y - MS_SYMBOL_SIZE / 4, point.x, point.y + MS_SYMBOL_SIZE / 4 + 1));
      retArr = retArr.concat(LINE(CDXFFile1, point.x, Center.y - 17 * yy, Center.x - MS_BASE_WIDTH + 22 * yy, Center.y));
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, Center.y, Center.x - MS_BASE_HEIGHT + 22 * yy + 1, Center.y));
      break;
    }
    break;
  case 1:
    yy = Math.floor(MS_SYMBOL_SIZE / 20);
    switch (pDS.rotation) {
    case 0:
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.top - 1, Center.x, Center.y - MS_BASE_HEIGHT + 13 * yy));

      point.y = Center.y - MS_BASE_HEIGHT + 13 * yy;

      retArr = retArr.concat(LINE(CDXFFile1, point.x - 6 * yy, point.y, point.x + 6 * yy + 1, point.y));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 17 * yy, point.y, Center.x, Center.y + MS_BASE_HEIGHT - 22 * yy));

      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 17 * yy, Center.y - 10 * yy, Center.x - 8 * yy, Center.y + 12 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 8 * yy, Center.y + 12 * yy, Center.x + 2 * yy, Center.y + 7 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 2 * yy, Center.y + 7 * yy, Center.x - 6 * yy, Center.y - 15 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 6 * yy, Center.y - 15 * yy, Center.x - 17 * yy, Center.y - 10 * yy));

      retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.bottom + 1, Center.x, Center.y +  MS_BASE_HEIGHT - 22 * yy - 1));
      break;
    case -90:
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, Center.y, Center.x - MS_BASE_WIDTH + 13 * yy, Center.y));

      point.x = Center.x - MS_BASE_HEIGHT + 13 * yy;

      retArr = retArr.concat(LINE(CDXFFile1, point.x, point.y - 6 * yy, point.x, point.y + 6 * yy + 1));
      retArr = retArr.concat(LINE(CDXFFile1, point.x, Center.y + 17 * yy, Center.x + MS_BASE_WIDTH - 22 * yy, Center.y));

      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 11 * yy, Center.y + 17 * yy, Center.x + 11 * yy, Center.y + 9 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 11 * yy, Center.y + 9 * yy, Center.x + 7 * yy, Center.y - 2 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 7 * yy, Center.y - 2 * yy, Center.x - 15 * yy, Center.y + 6 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 15 * yy, Center.y + 6 * yy, Center.x - 11 * yy, Center.y + 17 * yy));

      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.right + 1, Center.y, Center.x + MS_BASE_HEIGHT - 22 * yy - 1, Center.y));
      break;
    case 90:
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.right + 1, Center.y, Center.x + MS_BASE_WIDTH - MS_SYMBOL_SIZE / 2, Center.y));

      point.x = Center.x + MS_BASE_HEIGHT - MS_SYMBOL_SIZE / 2;

      retArr = retArr.concat(LINE(CDXFFile1, point.x, point.y - MS_SYMBOL_SIZE / 4, point.x, point.y + MS_SYMBOL_SIZE / 4 + 1));
      retArr = retArr.concat(LINE(CDXFFile1, point.x, Center.y - 17 * yy, Center.x - MS_BASE_WIDTH + 22 * yy, Center.y));

      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 11 * yy, Center.y - 17 * yy, Center.x - 11 * yy, Center.y - 9 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 11 * yy, Center.y - 9 * yy, Center.x - 7 * yy, Center.y + 2 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 7 * yy, Center.y + 2 * yy, Center.x + 15 * yy, Center.y - 6 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 15 * yy, Center.y - 6 * yy, Center.x + 11 * yy, Center.y - 17 * yy));

      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, Center.y, Center.x - MS_BASE_HEIGHT + 22 * yy + 1, Center.y));
      break;
    }
    break;
  case 2:
    yy = Math.floor(MS_SYMBOL_SIZE / 20);
    switch (pDS.rotation) {
    case 0:
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.top - 1, Center.x, Center.y - 26 * yy));

      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 1, Center.y - 21 * yy, Center.x - 1, Center.y + 21 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, Center.y - 21 * yy, Center.x, Center.y + 21 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 1, Center.y - 21 * yy, Center.x + 1, Center.y + 21 * yy));
      {
        let rect = {top: 0, left: 0, right: 0, bottom: 0};
        
        rect.left = Center.x - 6 * yy;
        rect.top = Center.y - 26 * yy;
        rect.right = Center.x + 6 * yy;
        rect.bottom = Center.y - 13 * yy;
        let point = {x: 0, y: 0};
        point.x = Center.x + 6 * yy;
        point.y = Center.y - 20 * yy;
        let Center2 = {x: 0, y: 0};
        Center2.x = Center.x - 6 * yy;
        Center2.y = Center.y - 20 * yy;
        retArr = retArr.concat(ARC_Top(CDXFFile1, rect, point, Center2));

        rect.left = Center.x - 6 * yy;
        rect.top = Center.y + 13 * yy;
        rect.right = Center.x + 6 * yy;
        rect.bottom = Center.y + 26 * yy;
        point.x = Center.x - 6 * yy;
        point.y = Center.y + 20 * yy;
        Center2.x = Center.x + 6 * yy;
        Center2.y = Center.y + 20 * yy;
        retArr = retArr.concat(ARC_Bottom(CDXFFile1, rect, point, Center2));
      }
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.bottom + 1, Center.x, Center.y +  26 * yy));
      break;
    case -90:
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, Center.y, Center.x - 26 * yy, Center.y));

      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 21 * yy, Center.y - 1, Center.x + 21 * yy, Center.y - 1));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 21 * yy, Center.y, Center.x + 21 * yy, Center.y));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 21 * yy, Center.y + 1, Center.x + 21 * yy, Center.y + 1));

      {
        let rect = {top: 0, left: 0, right: 0, bottom: 0};
        
        rect.left = Center.x - 26 * yy;
        rect.top = Center.y - 6 * yy;
        rect.right = Center.x - 13 * yy;
        rect.bottom = Center.y + 6 * yy;
        let point = {x: 0, y: 0};
        point.x = Center.x - 20 * yy;
        point.y = Center.y - 6 * yy;
        let Center2 = {x: 0, y: 0};
        Center2.x = Center.x - 20 * yy;
        Center2.y = Center.y + 6 * yy;
        retArr = retArr.concat(ARC_Left(CDXFFile1, rect, point, Center2));

        rect.left = Center.x + 13 * yy;
        rect.top = Center.y - 6 * yy;
        rect.right = Center.x + 26 * yy;
        rect.bottom = Center.y + 6 * yy;
        point.x = Center.x + 20 * yy;
        point.y = Center.y + 6 * yy;
        Center2.x = Center.x + 20 * yy;
        Center2.y = Center.y - 6 * yy;
        retArr = retArr.concat(ARC_Right(CDXFFile1, rect, point, Center2));
      }
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.right + 1, Center.y, Center.x + 26 * yy, Center.y));
      break;
    case 90:
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, Center.y, Center.x - 26 * yy, Center.y));

      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 21 * yy, Center.y - 1, Center.x + 21 * yy, Center.y - 1));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 21 * yy, Center.y, Center.x + 21 * yy, Center.y));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 21 * yy, Center.y + 1, Center.x + 21 * yy, Center.y + 1));
      {
        let rect = {top: 0, left: 0, right: 0, bottom: 0};
        
        rect.left = Center.x - 26 * yy;
        rect.top = Center.y - 6 * yy;
        rect.right = Center.x - 13 * yy;
        rect.bottom = Center.y + 6 * yy;
        let point = {x: 0, y: 0};
        point.x = Center.x - 20 * yy;
        point.y = Center.y - 6 * yy;
        let Center2 = {x: 0, y: 0};
        Center2.x = Center.x - 20 * yy;
        Center2.y = Center.y + 6 * yy;
        retArr = retArr.concat(ARC_Left(CDXFFile1, rect, point, Center2));

        rect.left = Center.x + 13 * yy;
        rect.top = Center.y - 6 * yy;
        rect.right = Center.x + 26 * yy;
        rect.bottom = Center.y + 6 * yy;
        point.x = Center.x + 20 * yy;
        point.y = Center.y + 6 * yy;
        Center2.x = Center.x + 20 * yy;
        Center2.y = Center.y - 6 * yy;
        retArr = retArr.concat(ARC_Right(CDXFFile1, rect, point, Center2));
      }
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.right + 1, Center.y, Center.x + 26 * yy, Center.y));
      break;
    }
    break;
  }

  return retArr;
}

const doDxfOutSymbolLBS = (CDXFFile1: any, pLBS: any) => {
  let retArr: any[] = [];

  let rectBaseLP = getRectBaseLP(pLBS);
  let rectCenter = {...rectBaseLP};
  let Center = getCenterPoint(rectCenter);
  let point = {...Center};
  let rect = {top: 0, left: 0, right: 0, bottom: 0};

  let yy = Math.floor(MS_SYMBOL_SIZE / 20);
  switch (pLBS.properties.exchangeNumber) {
  case 0:
    switch (pLBS.rotation) {
    case 0:
      point.y = Center.y - MS_BASE_HEIGHT + 13 * yy;

      retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.top - 1, Center.x, point.y));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 17 * yy, point.y, Center.x, Center.y + MS_BASE_HEIGHT - 22 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.bottom + 1, Center.x, Center.y +  MS_BASE_HEIGHT - 22 * yy - 1));
      break;
    case -90:
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, Center.y, Center.x - MS_BASE_WIDTH + 13 * yy, Center.y));

      point.x = Center.x - MS_BASE_HEIGHT + 13 * yy;

      retArr = retArr.concat(LINE(CDXFFile1, point.x, Center.y + 17 * yy, Center.x + MS_BASE_WIDTH - 22 * yy, Center.y));
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.right + 1, Center.y, Center.x + MS_BASE_HEIGHT - 22 * yy - 1, Center.y));
      break;
    case 90:
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.right + 1, Center.y, Center.x + MS_BASE_WIDTH - MS_SYMBOL_SIZE / 2, Center.y));

      point.x = Center.x + MS_BASE_HEIGHT - MS_SYMBOL_SIZE / 2;

      retArr = retArr.concat(LINE(CDXFFile1, point.x, Center.y - 17 * yy, Center.x - MS_BASE_WIDTH + 22 * yy, Center.y));
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, Center.y, Center.x - MS_BASE_HEIGHT + 22 * yy + 1, Center.y));
      break;
    }
    break;
  case 1:
    switch (pLBS.rotation) {
    case 0:
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.top - 1, Center.x, Center.y - MS_BASE_HEIGHT + 13 * yy));

      point.y = Center.y - MS_BASE_HEIGHT + 13 * yy;

      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 17 * yy, point.y, Center.x, Center.y + MS_BASE_HEIGHT - 22 * yy));

      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 17 * yy, Center.y - 10 * yy, Center.x - 8 * yy, Center.y + 12 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 8 * yy, Center.y + 12 * yy, Center.x + 2 * yy, Center.y + 7 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 2 * yy, Center.y + 7 * yy, Center.x - 6 * yy, Center.y - 15 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 6 * yy, Center.y - 15 * yy, Center.x - 17 * yy, Center.y - 10 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.bottom + 1, Center.x, Center.y +  MS_BASE_HEIGHT - 22 * yy - 1));
      break;
    case -90:
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, Center.y, Center.x - MS_BASE_WIDTH + 13 * yy, Center.y));

      point.x = Center.x - MS_BASE_HEIGHT + 13 * yy;

      retArr = retArr.concat(LINE(CDXFFile1, point.x, Center.y + 17 * yy, Center.x + MS_BASE_WIDTH - 22 * yy, Center.y));

      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 11 * yy, Center.y + 17 * yy, Center.x + 11 * yy, Center.y + 9 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 11 * yy, Center.y + 9 * yy, Center.x + 7 * yy, Center.y - 2 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 7 * yy, Center.y - 2 * yy, Center.x - 15 * yy, Center.y + 6 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 15 * yy, Center.y + 6 * yy, Center.x - 11 * yy, Center.y + 17 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.right + 1, Center.y, Center.x + MS_BASE_HEIGHT - 22 * yy - 1, Center.y));
      break;
    case 90:
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.right + 1, Center.y, Center.x + MS_BASE_WIDTH - MS_SYMBOL_SIZE / 2, Center.y));

      point.x = Center.x + MS_BASE_HEIGHT - MS_SYMBOL_SIZE / 2;

      retArr = retArr.concat(LINE(CDXFFile1, point.x, Center.y - 17 * yy, Center.x - MS_BASE_WIDTH + 22 * yy, Center.y));

      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 11 * yy, Center.y - 17 * yy, Center.x - 11 * yy, Center.y - 9 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 11 * yy, Center.y - 9 * yy, Center.x - 7 * yy, Center.y + 2 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 7 * yy, Center.y + 2 * yy, Center.x + 15 * yy, Center.y - 6 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 15 * yy, Center.y - 6 * yy, Center.x + 11 * yy, Center.y - 17 * yy));

      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, Center.y, Center.x - MS_BASE_HEIGHT + 22 * yy + 1, Center.y));
      break;
    }
    break;
  case 2:
    switch (pLBS.rotation) {
    case 0:
      point.y = Center.y - MS_BASE_HEIGHT + 13 * yy;

      retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.top - 1, Center.x, point.y));
      retArr = retArr.concat(LINE(CDXFFile1, point.x - 6 * yy, point.y, point.x + 6 * yy + 1, point.y));
      
      rect.left = point.x - 4 * yy;
      rect.right = point.x + 4 * yy + 1;
      rect.top = point.y;
      rect.bottom = point.y + 8 * yy;
      retArr = retArr.concat(CIRCLE(CDXFFile1, rect));

      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 17 * yy, point.y, Center.x, Center.y + MS_BASE_HEIGHT - 22 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.bottom + 1, Center.x, Center.y +  MS_BASE_HEIGHT - 22 * yy - 1));
      break;
    case -90:
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, Center.y, Center.x - MS_BASE_WIDTH + 13 * yy, Center.y));

      point.x = Center.x - MS_BASE_HEIGHT + 13 * yy;

      retArr = retArr.concat(LINE(CDXFFile1, point.x, point.y - 6 * yy, point.x, point.y + 6 * yy + 1));

      rect.left = point.x;
      rect.top = point.y - 4 * yy;
      rect.right = point.x + 8 * yy;
      rect.bottom = point.y + 4 * yy + 1;
      retArr = retArr.concat(CIRCLE(CDXFFile1, rect));

      retArr = retArr.concat(LINE(CDXFFile1, point.x, Center.y + 17 * yy, Center.x + MS_BASE_WIDTH - 22 * yy, Center.y));
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.right + 1, Center.y, Center.x + MS_BASE_HEIGHT - 22 * yy - 1, Center.y));
      break;
    case 90:
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.right + 1, Center.y, Center.x + MS_BASE_WIDTH - MS_SYMBOL_SIZE / 2, Center.y));

      point.x = Center.x + MS_BASE_HEIGHT - MS_SYMBOL_SIZE / 2;

      retArr = retArr.concat(LINE(CDXFFile1, point.x, point.y - MS_SYMBOL_SIZE / 4, point.x, point.y + MS_SYMBOL_SIZE / 4 + 1));

      rect.left = point.x - 8 * yy;
      rect.top = point.y - 4 * yy;
      rect.right = point.x;
      rect.bottom = point.y + 4 * yy + 1;
      retArr = retArr.concat(CIRCLE(CDXFFile1, rect));

      retArr = retArr.concat(LINE(CDXFFile1, point.x, Center.y - 17 * yy, Center.x - MS_BASE_WIDTH + 22 * yy, Center.y));
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, Center.y, Center.x - MS_BASE_HEIGHT + 22 * yy + 1, Center.y));
      break;
    }
    break;
  case 3:
    switch (pLBS.rotation) {
    case 0:
      point.y = Center.y - MS_BASE_HEIGHT + 13 * yy;

      retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.top - 1, Center.x, point.y));
      retArr = retArr.concat(LINE(CDXFFile1, point.x - 6 * yy, point.y, point.x + 6 * yy + 1, point.y));
      
      rect.left = point.x - 4 * yy;
      rect.right = point.x + 4 * yy + 1;
      rect.top = point.y;
      rect.bottom = point.y + 8 * yy;
      retArr = retArr.concat(CIRCLE(CDXFFile1, rect));

      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 17 * yy, point.y, Center.x, Center.y + MS_BASE_HEIGHT - 22 * yy));
      
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 17 * yy, Center.y - 10 * yy, Center.x - 8 * yy, Center.y + 12 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 8 * yy, Center.y + 12 * yy, Center.x + 2 * yy, Center.y + 7 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 2 * yy, Center.y + 7 * yy, Center.x - 6 * yy, Center.y - 15 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 6 * yy, Center.y - 15 * yy, Center.x - 17 * yy, Center.y - 10 * yy));

      retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.bottom + 1, Center.x, Center.y +  MS_BASE_HEIGHT - 22 * yy - 1));
      break;
    case -90:
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, Center.y, Center.x - MS_BASE_WIDTH + 13 * yy, Center.y));

      point.x = Center.x - MS_BASE_HEIGHT + 13 * yy;

      retArr = retArr.concat(LINE(CDXFFile1, point.x, point.y - 6 * yy, point.x, point.y + 6 * yy + 1));

      rect.left = point.x;
      rect.top = point.y - 4 * yy;
      rect.right = point.x + 8 * yy;
      rect.bottom = point.y + 4 * yy + 1;
      retArr = retArr.concat(CIRCLE(CDXFFile1, rect));

      retArr = retArr.concat(LINE(CDXFFile1, point.x, Center.y + 17 * yy, Center.x + MS_BASE_WIDTH - 22 * yy, Center.y));

      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 11 * yy, Center.y + 17 * yy, Center.x + 11 * yy, Center.y + 9 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 11 * yy, Center.y + 9 * yy, Center.x + 7 * yy, Center.y - 2 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 7 * yy, Center.y - 2 * yy, Center.x - 15 * yy, Center.y + 6 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 15 * yy, Center.y + 6 * yy, Center.x - 11 * yy, Center.y + 17 * yy));

      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.right + 1, Center.y, Center.x + MS_BASE_HEIGHT - 22 * yy - 1, Center.y));
      break;
    case 90:
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.right + 1, Center.y, Center.x + MS_BASE_WIDTH - MS_SYMBOL_SIZE / 2, Center.y));

      point.x = Center.x + MS_BASE_HEIGHT - MS_SYMBOL_SIZE / 2;

      retArr = retArr.concat(LINE(CDXFFile1, point.x, point.y - MS_SYMBOL_SIZE / 4, point.x, point.y + MS_SYMBOL_SIZE / 4 + 1));

      rect.left = point.x - 8 * yy;
      rect.top = point.y - 4 * yy;
      rect.right = point.x;
      rect.bottom = point.y + 4 * yy + 1;
      retArr = retArr.concat(CIRCLE(CDXFFile1, rect));

      retArr = retArr.concat(LINE(CDXFFile1, point.x, Center.y - 17 * yy, Center.x - MS_BASE_WIDTH + 22 * yy, Center.y));

      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 11 * yy, Center.y - 17 * yy, Center.x - 11 * yy, Center.y - 9 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 11 * yy, Center.y - 9 * yy, Center.x - 7 * yy, Center.y + 2 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 7 * yy, Center.y + 2 * yy, Center.x + 15 * yy, Center.y - 6 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 15 * yy, Center.y - 6 * yy, Center.x + 11 * yy, Center.y - 17 * yy));

      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, Center.y, Center.x - MS_BASE_HEIGHT + 22 * yy + 1, Center.y));
      break;
    }
    break;
  }

  return retArr;
}

const doDxfOutSymbolMVCB = (CDXFFile1: any, pMVCB: any) => {
  let retArr: any[] = [];

  let rectBaseLP = getRectBaseLP(pMVCB);
  let rectCenter = {...rectBaseLP};
  let Center = getCenterPoint(rectCenter);

  let point = {...Center};
  let rect = {top: 0, left: 0, right: 0, bottom: 0};
  let Center2 = {x: 0, y: 0};
  let yy = Math.floor(MS_SYMBOL_SIZE / 20);

  switch (pMVCB.properties.exchangeNumber) {
  case 0:
    switch (pMVCB.rotation) {
    case 0:
      point.y = Center.y - MS_BASE_HEIGHT + 13 * yy;

      retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.top - 1, Center.x, point.y));
      retArr = retArr.concat(LINE(CDXFFile1, point.x - MS_SYMBOL_SIZE / 6, point.y - MS_SYMBOL_SIZE / 6, point.x + MS_SYMBOL_SIZE / 6 + 1, point.y + MS_SYMBOL_SIZE / 6 + 1));
      retArr = retArr.concat(LINE(CDXFFile1, point.x - MS_SYMBOL_SIZE / 6, point.y + MS_SYMBOL_SIZE / 6, point.x + MS_SYMBOL_SIZE / 6 + 1, point.y - MS_SYMBOL_SIZE / 6 - 1));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 17 * (MS_SYMBOL_SIZE / 20), point.y, Center.x, Center.y + MS_BASE_HEIGHT - 22 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.bottom + 1, Center.x, Center.y +  MS_BASE_HEIGHT - 22 * yy - 1));
      break;
    case -90:
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, Center.y, Center.x - MS_BASE_WIDTH + MS_SYMBOL_SIZE / 2, Center.y));
      
      point.x = Center.x - MS_BASE_HEIGHT + MS_SYMBOL_SIZE / 2;

      retArr = retArr.concat(LINE(CDXFFile1, point.x - MS_SYMBOL_SIZE / 6, point.y - MS_SYMBOL_SIZE / 6, point.x + MS_SYMBOL_SIZE / 6 + 1, point.y + MS_SYMBOL_SIZE / 6 + 1));
      retArr = retArr.concat(LINE(CDXFFile1, point.x - MS_SYMBOL_SIZE / 6, point.y + MS_SYMBOL_SIZE / 6, point.x + MS_SYMBOL_SIZE / 6 + 1, point.y - MS_SYMBOL_SIZE / 6 - 1));
      
      retArr = retArr.concat(LINE(CDXFFile1, point.x, Center.y + 17 * (MS_SYMBOL_SIZE / 20), Center.x + MS_BASE_WIDTH - 22 * yy, Center.y));

      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.right + 1, Center.y, Center.x + MS_BASE_WIDTH - 22 * yy - 1, Center.y));
      break;
    case 90:
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.right + 1, Center.y, Center.x + MS_BASE_WIDTH - MS_SYMBOL_SIZE / 2, Center.y));
      
      point.x = Center.x + MS_BASE_HEIGHT - MS_SYMBOL_SIZE / 2;
      retArr = retArr.concat(LINE(CDXFFile1, point.x - MS_SYMBOL_SIZE / 6, point.y - MS_SYMBOL_SIZE / 6, point.x + MS_SYMBOL_SIZE / 6 + 1, point.y + MS_SYMBOL_SIZE / 6 + 1));
      retArr = retArr.concat(LINE(CDXFFile1, point.x - MS_SYMBOL_SIZE / 6, point.y + MS_SYMBOL_SIZE / 6, point.x + MS_SYMBOL_SIZE / 6 + 1, point.y - MS_SYMBOL_SIZE / 6 - 1));
      retArr = retArr.concat(LINE(CDXFFile1, point.x, Center.y - 17 * (MS_SYMBOL_SIZE / 20), Center.x - MS_BASE_WIDTH + 22 * yy, Center.y));
      
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, Center.y, Center.x - MS_BASE_WIDTH + 22 * yy + 1, Center.y));
      break;
    }
    break;
  case 1:
    switch (pMVCB.rotation) {
    case 0:
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.top - 1, Center.x, Center.y - 26 * yy));
      
      point.y = Center.y - MS_BASE_HEIGHT + MS_SYMBOL_SIZE / 2;

      retArr = retArr.concat(LINE(CDXFFile1, point.x - MS_SYMBOL_SIZE / 6, point.y - MS_SYMBOL_SIZE / 6, point.x + MS_SYMBOL_SIZE / 6 + 1, point.y + MS_SYMBOL_SIZE / 6 + 1));
      retArr = retArr.concat(LINE(CDXFFile1, point.x + MS_SYMBOL_SIZE / 6, point.y - MS_SYMBOL_SIZE / 6, point.x - MS_SYMBOL_SIZE / 6 - 1, point.y + MS_SYMBOL_SIZE / 6 + 1));
      
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - MS_SYMBOL_SIZE / 2, point.y, Center.x + 1, Center.y + 1));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 6 * yy, Center.y - 13 * yy, Center.x, Center.y - 13 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 3 * yy, Center.y - 13 * yy, Center.x + 9 * yy, Center.y - 13 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 13 * yy, Center.y - 13 * yy, rectBaseLP.right - 2, Center.y - 13 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.right - 2, Center.y - 13 * yy, rectBaseLP.right - 2, Center.y - 9 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.right - 2, Center.y - 6 * yy, rectBaseLP.right - 2, Center.y + 1 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.right - 2, Center.y + 4 * yy, rectBaseLP.right - 2, Center.y + 11 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.right - 2, Center.y + 15 * yy, rectBaseLP.right - 2, Center.y + 18 * yy)); 
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.right - 2, Center.y + 18 * yy, Center.x + 14 * yy, Center.y + 18 * yy));

      rect.left = Center.x - 15 * yy;
      rect.top = Center.y + 3 * yy;
      rect.right = Center.x + 15 * yy;
      rect.bottom = Center.y + 33 * yy;
      retArr = retArr.concat(CIRCLE(CDXFFile1, rect));

      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 3 * yy, Center.y + 9 * yy, Center.x + 8 * yy, Center.y + 9 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 8 * yy, Center.y + 9 * yy, Center.x + 8 * yy, Center.y + 19 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 8 * yy, Center.y + 19 * yy, Center.x + 2 * yy, Center.y + 19 * yy));
      
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 8 * yy, Center.y + 14 * yy, Center.x + 4 * yy, Center.y + 14 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 8 * yy, Center.y + 23 * yy, Center.x - 8 * yy, Center.y + 28 * yy));
      
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.bottom + 1, Center.x, Center.y - 1));
      break;
    case -90:
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, Center.y, Center.x - MS_BASE_WIDTH + MS_SYMBOL_SIZE / 2, Center.y));
      
      point.x = Center.x - MS_BASE_HEIGHT + MS_SYMBOL_SIZE / 2;
      
      retArr = retArr.concat(LINE(CDXFFile1, point.x - MS_SYMBOL_SIZE / 6, point.y - MS_SYMBOL_SIZE / 6, point.x + MS_SYMBOL_SIZE / 6 + 1, point.y + MS_SYMBOL_SIZE / 6 + 1));
      retArr = retArr.concat(LINE(CDXFFile1, point.x - MS_SYMBOL_SIZE / 6, point.y + MS_SYMBOL_SIZE / 6, point.x + MS_SYMBOL_SIZE / 6 + 1, point.y - MS_SYMBOL_SIZE / 6 - 1));
      
      retArr = retArr.concat(LINE(CDXFFile1, point.x, Center.y + MS_SYMBOL_SIZE / 2, Center.x + 1, Center.y - 1));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 13 * yy, Center.y + 6 * yy, Center.x - 13 * yy, Center.y));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 13 * yy, Center.y - 3 * yy, Center.x - 13 * yy, Center.y - 9 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 13 * yy, Center.y - 13 * yy, Center.x - 13 * yy, rectBaseLP.top + 2));
      
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 13 * yy, rectBaseLP.top + 2, Center.x - 9 * yy, rectBaseLP.top + 2));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 6 * yy, rectBaseLP.top + 2, Center.x + 1 * yy, rectBaseLP.top + 2));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 4 * yy, rectBaseLP.top + 2, Center.x + 11 * yy, rectBaseLP.top + 2));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 15 * yy, rectBaseLP.top + 2, Center.x + 18 * yy, rectBaseLP.top + 2));
      
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 18 * yy, rectBaseLP.top + 2, Center.x + 18 * yy, Center.y - 14 * yy));

      rect.left = Center.x + 3 * yy;
      rect.top = Center.y - 15 * yy;
      rect.right = Center.x + 33 * yy;
      rect.bottom = Center.y + 15 * yy;
      retArr = retArr.concat(CIRCLE(CDXFFile1, rect));

      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 9 * yy, Center.y - 3 * yy, Center.x + 9 * yy, Center.y - 8 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 9 * yy, Center.y - 8 * yy, Center.x + 19 * yy, Center.y - 8 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 19 * yy, Center.y - 8 * yy, Center.x + 19 * yy, Center.y - 2 * yy));
      
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 14 * yy, Center.y - 8 * yy, Center.x + 14 * yy, Center.y - 4 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 23 * yy, Center.y - 8 * yy, Center.x + 28 * yy, Center.y + 8 * yy));
      
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.right + 1, Center.y, Center.x, Center.y));
      break;
    case 90:
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.right + 1, Center.y, Center.x + MS_BASE_WIDTH - MS_SYMBOL_SIZE / 2, Center.y));
      
      point.x = Center.x + MS_BASE_HEIGHT - MS_SYMBOL_SIZE / 2;
      
      retArr = retArr.concat(LINE(CDXFFile1, point.x + MS_SYMBOL_SIZE / 6, point.y - MS_SYMBOL_SIZE / 6, point.x - MS_SYMBOL_SIZE / 6 - 1, point.y + MS_SYMBOL_SIZE / 6 + 1));
      retArr = retArr.concat(LINE(CDXFFile1, point.x + MS_SYMBOL_SIZE / 6, point.y + MS_SYMBOL_SIZE / 6, point.x - MS_SYMBOL_SIZE / 6 - 1, point.y - MS_SYMBOL_SIZE / 6 - 1));
      
      retArr = retArr.concat(LINE(CDXFFile1, point.x, Center.y - MS_SYMBOL_SIZE / 2, Center.x - 1, Center.y + 1));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 13 * yy, Center.y - 6 * yy, Center.x + 13 * yy, Center.y));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 13 * yy, Center.y + 3 * yy, Center.x + 13 * yy, Center.y + 9 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 13 * yy, Center.y + 13 * yy, Center.x + 13 * yy, rectBaseLP.bottom - 2));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 13 * yy, rectBaseLP.bottom - 2, Center.x + 9 * yy, rectBaseLP.bottom - 2));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 6 * yy, rectBaseLP.bottom - 2, Center.x - 1 * yy, rectBaseLP.bottom - 2));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 4 * yy, rectBaseLP.bottom - 2, Center.x - 11 * yy, rectBaseLP.bottom - 2));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 15 * yy, rectBaseLP.bottom - 2, Center.x - 18 * yy, rectBaseLP.bottom - 2));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 18 * yy, rectBaseLP.bottom - 2, Center.x - 18 * yy, Center.y + 14 * yy));

      rect.left = Center.x - 33 * yy;
      rect.top = Center.y - 15 * yy;
      rect.right = Center.x - 3 * yy;
      rect.bottom = Center.y + 15 * yy;
      retArr = retArr.concat(CIRCLE(CDXFFile1, rect));

      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 9 * yy, Center.y + 3 * yy, Center.x - 9 * yy, Center.y + 8 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 9 * yy, Center.y + 8 * yy, Center.x - 19 * yy, Center.y + 8 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 19 * yy, Center.y + 8 * yy, Center.x - 19 * yy, Center.y + 2 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 14 * yy, Center.y + 8 * yy, Center.x - 14 * yy, Center.y + 4 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 23 * yy, Center.y + 8 * yy, Center.x - 28 * yy, Center.y - 8 * yy));
      
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, Center.y, Center.x, Center.y));
      break;
    }
    break;
  case 2:
    switch (pMVCB.rotation) {
    case 0:
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.top - 1, Center.x, Center.y - 35 * yy));

      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 1, Center.y - 30 * yy, Center.x - 1, Center.y - 20 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, Center.y - 30 * yy, Center.x, Center.y - 20 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 1, Center.y - 30 * yy, Center.x + 1, Center.y - 20 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, Center.y - 20 * yy, Center.x, Center.y - 10 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 4 * yy, Center.y - 14 * yy, Center.x + 4 * yy + 1, Center.y - 6 * yy + 1));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 4 * yy, Center.y - 14 * yy, Center.x - 4 * yy - 1, Center.y - 6 * yy + 1));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, Center.y + 10 * yy, Center.x - 10 * yy - 1, Center.y - 10 * yy - 1));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, Center.y + 20 * yy, Center.x, Center.y + 10 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 1, Center.y + 30 * yy, Center.x - 1, Center.y + 20 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, Center.y + 30 * yy, Center.x, Center.y + 20 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 1, Center.y + 30 * yy, Center.x + 1, Center.y + 20 * yy));

      retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.bottom + 1, Center.x, Center.y +  35 * yy - 1));

        rect.left = Center.x - 6 * yy;
        rect.top = Center.y - 35 * yy;
        rect.right = Center.x + 6 * yy;
        rect.bottom = Center.y - 22 * yy;
        point.x = Center.x + 6 * yy;
        point.y = Center.y - 29 * yy;
        Center2.x = Center.x - 6 * yy;
        Center2.y = Center.y - 29 * yy;
        retArr = retArr.concat(ARC_Top(CDXFFile1, rect, point, Center2));

        rect.left = Center.x - 6 * yy;
        rect.top = Center.y + 22 * yy;
        rect.right = Center.x + 6 * yy;
        rect.bottom = Center.y + 35 * yy;
        point.x = Center.x - 6 * yy;
        point.y = Center.y + 29 * yy;
        Center2.x = Center.x + 6 * yy;
        Center2.y = Center.y + 29 * yy;
        retArr = retArr.concat(ARC_Bottom(CDXFFile1, rect, point, Center2));
      break;
    case -90:
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, Center.y, Center.x - 35 * yy, Center.y));

      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 30 * yy, Center.y - 1, Center.x - 20 * yy, Center.y - 1));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 30 * yy, Center.y, Center.x - 20 * yy, Center.y));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 30 * yy, Center.y + 1, Center.x - 20 * yy, Center.y + 1));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 20 * yy, Center.y, Center.x - 10 * yy, Center.y));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 14 * yy, Center.y + 4 * yy, Center.x - 6 * yy + 1, Center.y - 4 * yy - 1));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 14 * yy, Center.y - 4 * yy, Center.x - 6 * yy + 1, Center.y + 4 * yy + 1));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 10 * yy, Center.y, Center.x - 10 * yy - 1, Center.y + 10 * yy + 1));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 20 * yy, Center.y, Center.x + 10 * yy, Center.y));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 30 * yy, Center.y - 1, Center.x + 20 * yy, Center.y - 1));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 30 * yy, Center.y, Center.x + 20 * yy, Center.y));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 30 * yy, Center.y + 1, Center.x + 20 * yy, Center.y + 1));

      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.right + 1, Center.y, Center.x + 35 * yy, Center.y));

        rect.left = Center.x - 35 * yy;
        rect.top = Center.y - 6 * yy;
        rect.right = Center.x - 22 * yy;
        rect.bottom = Center.y + 6 * yy;
        point.x = Center.x - 29 * yy;
        point.y = Center.y - 6 * yy;
        Center2.x = Center.x - 29 * yy;
        Center2.y = Center.y + 6 * yy;
        retArr = retArr.concat(ARC_Left(CDXFFile1, rect, point, Center2));

        rect.left = Center.x + 22 * yy;
        rect.top = Center.y - 6 * yy;
        rect.right = Center.x + 35 * yy;
        rect.bottom = Center.y + 6 * yy;
        point.x = Center.x + 29 * yy;
        point.y = Center.y + 6 * yy;
        Center2.x = Center.x + 29 * yy;
        Center2.y = Center.y - 6 * yy;
        retArr = retArr.concat(ARC_Right(CDXFFile1, rect, point, Center2));
      break;
    case 90:
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, Center.y, Center.x - 35 * yy, Center.y));

      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 30 * yy, Center.y - 1, Center.x + 20 * yy, Center.y - 1));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 30 * yy, Center.y, Center.x + 20 * yy, Center.y));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 30 * yy, Center.y + 1, Center.x + 20 * yy, Center.y + 1));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 20 * yy, Center.y, Center.x + 10 * yy, Center.y));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 14 * yy, Center.y + 4 * yy, Center.x + 6 * yy + 1, Center.y - 4 * yy - 1));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 14 * yy, Center.y - 4 * yy, Center.x + 6 * yy + 1, Center.y + 4 * yy + 1));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 10 * yy, Center.y, Center.x + 10 * yy + 1, Center.y - 10 * yy - 1));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 20 * yy, Center.y, Center.x - 10 * yy, Center.y));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 30 * yy, Center.y - 1, Center.x - 20 * yy, Center.y - 1));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 30 * yy, Center.y, Center.x - 20 * yy, Center.y));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 30 * yy, Center.y + 1, Center.x - 20 * yy, Center.y + 1));

      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.right + 1, Center.y, Center.x + 35 * yy, Center.y));

        rect.left = Center.x + 22 * yy;
        rect.top = Center.y - 6 * yy;
        rect.right = Center.x + 35 * yy;
        rect.bottom = Center.y + 6 * yy;
        point.x = Center.x + 29 * yy;
        point.y = Center.y + 6 * yy;
        Center2.x = Center.x + 29 * yy;
        Center2.y = Center.y - 6 * yy;
        retArr = retArr.concat(ARC_Right(CDXFFile1, rect, point, Center2));

        rect.left = Center.x - 35 * yy;
        rect.top = Center.y - 6 * yy;
        rect.right = Center.x - 22 * yy;
        rect.bottom = Center.y + 6 * yy;
        point.x = Center.x - 29 * yy;
        point.y = Center.y - 6 * yy;
        Center2.x = Center.x - 29 * yy;
        Center2.y = Center.y + 6 * yy;
        retArr = retArr.concat(ARC_Left(CDXFFile1, rect, point, Center2));
      break;
    }
    break;
  }

  return retArr;
}

const doDxfOutSymbolContactor = (CDXFFile1: any, pContactor: any) => {
  let retArr: any[] = [];

  let rectBaseLP = getRectBaseLP(pContactor);
  let rectCenter = {...rectBaseLP};
  let Center = getCenterPoint(rectCenter);

  let point = {...Center};
  let point1 = {x: 0, y: 0};
  let Center2 = {x: 0, y: 0};
  let rect = {top: 0, left: 0, right: 0, bottom: 0};

  let yy = Math.floor(MS_SYMBOL_SIZE / 20);

  switch (pContactor.properties.exchangeNumber) {
  case 0:
    switch (pContactor.rotation) {
    case 0:
      point.y = Center.y - MS_BASE_HEIGHT + 18 * yy;

      retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.top - 1, Center.x, point.y));

      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 17 * yy, point.y, Center.x, Center.y + MS_BASE_HEIGHT - 22 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.bottom + 1, Center.x, Center.y +  MS_BASE_HEIGHT - 22 * yy - 1));

        rect.left = point.x - 4 * yy;
        rect.top = point.y - 10 * yy;
        rect.right = point.x + 5 * yy;
        rect.bottom = point.y;
        point1.x = point.x;
        point1.y = point.y - 10 * yy;
        Center2.x = point.x;
        Center2.y = point.y;
        retArr = retArr.concat(ARC_Left(CDXFFile1, rect, point1, Center2));
      break;
    case -90:
      point.x = Center.x - MS_BASE_WIDTH + 18 * yy;

      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, Center.y, point.x, Center.y));

      retArr = retArr.concat(LINE(CDXFFile1, point.x, Center.y + 17 * (MS_SYMBOL_SIZE / 20), Center.x + MS_BASE_WIDTH - 21 * yy, Center.y));
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.right + 1, Center.y, Center.x + MS_BASE_WIDTH - 22 * yy, Center.y));

        rect.left = point.x - 10 * yy;
        rect.top = point.y + 5 * yy;
        rect.right = point.x;
        rect.bottom = point.y - 5 * yy;
        point1.x = point.x - 10 * yy;
        point1.y = point.y + 1 * yy;
        Center2.x = point.x;
        Center2.y = point.y + 1 * yy;
        retArr = retArr.concat(ARC_Bottom(CDXFFile1, rect, point1, Center2));
      break;
    case 90:
      point.x = Center.x + MS_BASE_WIDTH - 18 * yy;

      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.right + 1, Center.y, point.x, Center.y));

      retArr = retArr.concat(LINE(CDXFFile1, point.x, Center.y - 17 * (MS_SYMBOL_SIZE / 20), Center.x - MS_BASE_WIDTH + 21 * yy, Center.y));
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, Center.y, Center.x - MS_BASE_WIDTH + 22 * yy, Center.y));

        rect.left = point.x + 11 * yy;
        rect.top = point.y + 5 * yy;
        rect.right = point.x + 1 * yy;
        rect.bottom = point.y - 4 * yy;
        point1.x = point.x + 11 * yy;
        point1.y = point.y + 1 * yy;
        Center2.x = point.x + 1 * yy;
        Center2.y = point.y + 1 * yy;
        retArr = retArr.concat(ARC_Top(CDXFFile1, rect, point1, Center2));
      break;
    }
    break;
  case 1:
    switch (pContactor.rotation) {
    case 0:
      point.y = Center.y - MS_BASE_HEIGHT + 18 * yy;

      retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.top - 1, Center.x, point.y));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, point.y, Center.x + 17 * yy, point.y));

      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 13 * yy, point.y - 5 * yy, Center.x, Center.y + MS_BASE_HEIGHT - 22 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.bottom + 1, Center.x, Center.y +  MS_BASE_HEIGHT - 22 * yy - 1));

        rect.left = point.x - 4 * yy;
        rect.top = point.y - 10 * yy;
        rect.right = point.x + 5 * yy;
        rect.bottom = point.y;
        point1.x = point.x;
        point1.y = point.y;
        Center2.x = point.x;
        Center2.y = point.y - 10 * yy;
        retArr = retArr.concat(ARC_Right(CDXFFile1, rect, point1, Center2));
      break;
    case -90:
      point.x = Center.x - MS_BASE_WIDTH + 18 * yy;

      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, Center.y, point.x, Center.y));
      retArr = retArr.concat(LINE(CDXFFile1, point.x, Center.y, point.x, Center.y - MS_BASE_WIDTH + 20 * yy));

      retArr = retArr.concat(LINE(CDXFFile1, point.x - 5 * yy, Center.y - 13 * yy, Center.x + MS_BASE_WIDTH - 21 * yy, Center.y));
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.right + 1, Center.y, Center.x + MS_BASE_WIDTH - 22 * yy, Center.y));

        rect.left = point.x;
        rect.top = point.y + 5 * yy;
        rect.right = point.x - 10 * yy;
        rect.bottom = point.y - 4 * yy;
        point1.x = point.x;
        point1.y = point.y + 1 * yy;
        Center2.x = point.x - 10 * yy;
        Center2.y = point.y + 1 * yy;
        retArr = retArr.concat(ARC_Top(CDXFFile1, rect, point1, Center2));
      break;
    case 90:
      point.x = Center.x + MS_BASE_WIDTH - 18 * yy;

      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.right + 1, Center.y, point.x, Center.y));
      retArr = retArr.concat(LINE(CDXFFile1, point.x, Center.y, point.x, Center.y + MS_BASE_WIDTH - 20 * yy));

      retArr = retArr.concat(LINE(CDXFFile1, point.x + 5 * yy, Center.y + 13 * yy, Center.x - MS_BASE_WIDTH + 21 * yy, Center.y));
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, Center.y, Center.x - MS_BASE_WIDTH + 22 * yy, Center.y));

        rect.left = point.x + 1 * yy;
        rect.top = point.y + 5 * yy;
        rect.right = point.x + 11 * yy;
        rect.bottom = point.y - 5 * yy;
        point1.x = point.x + 1 * yy;
        point1.y = point.y + 1 * yy;
        Center2.x = point.x + 11 * yy;
        Center2.y = point.y + 1 * yy;
        retArr = retArr.concat(ARC_Bottom(CDXFFile1, rect, point1, Center2));
      break;
    }
    break;
  }

  return retArr;
}

const doDxfOutSymbolHVCB = (CDXFFile1: any, pHVCB: any) => {
  let retArr: any[] = [];

  let rectBaseLP = getRectBaseLP(pHVCB);
  let rectCenter = {...rectBaseLP};
  let Center = getCenterPoint(rectCenter);

  switch (pHVCB.properties.symKind) {
  case 0:
    {
      switch (pHVCB.rotation) {
      case 0:
      {
        retArr = retArr.concat(LINE(CDXFFile1, Center.x - MS_FRAME_WIDTH / 2, rectBaseLP.top - 1, Center.x - MS_FRAME_WIDTH / 2, Center.y - MS_BASE_HEIGHT + MS_SYMBOL_SIZE / 3));
        
        let point = {...Center};
        point.y = Center.y - MS_FRAME_HEIGHT + MS_SYMBOL_SIZE / 3;
        
        retArr = retArr.concat(LINE(CDXFFile1, point.x - MS_FRAME_WIDTH / 2 - MS_SYMBOL_SIZE / 6, point.y - MS_SYMBOL_SIZE / 6, point.x - MS_FRAME_WIDTH / 2 + MS_SYMBOL_SIZE / 6 + 1, point.y + MS_SYMBOL_SIZE / 6 + 1));
        retArr = retArr.concat(LINE(CDXFFile1, point.x - MS_FRAME_WIDTH / 2 - MS_SYMBOL_SIZE / 6, point.y + MS_SYMBOL_SIZE / 6, point.x - MS_FRAME_WIDTH / 2 + MS_SYMBOL_SIZE / 6 + 1, point.y - MS_SYMBOL_SIZE / 6 - 1));
        retArr = retArr.concat(LINE(CDXFFile1, Center.x - MS_FRAME_WIDTH / 2 - MS_SYMBOL_SIZE / 2, point.y, Center.x - MS_FRAME_WIDTH / 2, Center.y));
        retArr = retArr.concat(LINE(CDXFFile1, Center.x - MS_FRAME_WIDTH / 2, rectBaseLP.bottom + 1, Center.x - MS_FRAME_WIDTH / 2, Center.y - 2));
        
        let rect = {top: 0, left: 0, right: 0, bottom: 0};
        rect.left = Center.x - MS_FRAME_WIDTH / 2 - MS_SYMBOL_SIZE / 4;
        rect.right = Center.x - MS_FRAME_WIDTH / 2 + MS_SYMBOL_SIZE / 4 + 1;
        rect.top = Center.y + MS_FRAME_HEIGHT / 6;
        rect.bottom = Center.y + MS_FRAME_HEIGHT / 6 + MS_SYMBOL_SIZE / 2;
        retArr = retArr.concat(CIRCLE(CDXFFile1, rect));

        retArr = retArr.concat(LINE(CDXFFile1, Center.x - MS_FRAME_WIDTH / 2 + MS_SYMBOL_SIZE / 4, Center.y + MS_BASE_HEIGHT / 6 + MS_SYMBOL_SIZE / 4, Center.x, Center.y + MS_BASE_HEIGHT / 6 + MS_SYMBOL_SIZE / 4));
        
        rect.left = Center.x;
        rect.right = Center.x + MS_SYMBOL_SIZE;
        rect.top = Center.y + MS_BASE_HEIGHT / 2 - MS_SYMBOL_SIZE / 2;
        rect.bottom = Center.y + MS_BASE_HEIGHT / 2 + MS_SYMBOL_SIZE / 14;
        
        retArr = retArr.concat(LINE(CDXFFile1, rect.left, rect.top, rect.right, rect.top));
        retArr = retArr.concat(LINE(CDXFFile1, rect.right, rect.top, rect.right, rect.bottom));
        retArr = retArr.concat(LINE(CDXFFile1, rect.right, rect.bottom, rect.left, rect.bottom));
        retArr = retArr.concat(LINE(CDXFFile1, rect.left, rect.bottom, rect.left, rect.top));

        retArr = retArr.concat(STRING(CDXFFile1, rect.left + 9, rect.top + 7, 8, "I >"));

        retArr = retArr.concat(LINE(CDXFFile1, Center.x + MS_SYMBOL_SIZE / 2, Center.y + MS_BASE_HEIGHT / 2 - MS_SYMBOL_SIZE / 2, Center.x + MS_SYMBOL_SIZE / 2, Center.y + MS_BASE_HEIGHT / 2 - MS_SYMBOL_SIZE / 2 - MS_SYMBOL_SIZE / 4));
        retArr = retArr.concat(LINE(CDXFFile1, Center.x + MS_SYMBOL_SIZE / 2, Center.y + MS_BASE_HEIGHT / 2 - MS_SYMBOL_SIZE / 2 - (MS_SYMBOL_SIZE / 4 * 2), Center.x + MS_SYMBOL_SIZE / 2, Center.y + MS_BASE_HEIGHT / 2 - MS_SYMBOL_SIZE / 2 - (MS_SYMBOL_SIZE / 4 * 3)));
        retArr = retArr.concat(LINE(CDXFFile1, Center.x + MS_SYMBOL_SIZE / 2, Center.y - MS_SYMBOL_SIZE / 2, Center.x + MS_SYMBOL_SIZE / 2 - MS_SYMBOL_SIZE / 4, Center.y - MS_SYMBOL_SIZE / 2));
        retArr = retArr.concat(LINE(CDXFFile1, Center.x + MS_SYMBOL_SIZE / 2 - (MS_SYMBOL_SIZE / 4 * 2), Center.y - MS_SYMBOL_SIZE / 2, Center.x + MS_SYMBOL_SIZE / 2 - (MS_SYMBOL_SIZE / 4 * 3), Center.y - MS_SYMBOL_SIZE / 2));
        retArr = retArr.concat(LINE(CDXFFile1, Center.x + MS_SYMBOL_SIZE / 2 - (MS_SYMBOL_SIZE / 4 * 4), Center.y - MS_SYMBOL_SIZE / 2, Center.x + MS_SYMBOL_SIZE / 2 - (MS_SYMBOL_SIZE / 4 * 5), Center.y - MS_SYMBOL_SIZE / 2));
        retArr = retArr.concat(LINE(CDXFFile1, Center.x + MS_SYMBOL_SIZE / 2 - (MS_SYMBOL_SIZE / 4 * 6), Center.y - MS_SYMBOL_SIZE / 2, Center.x - MS_SYMBOL_SIZE + MS_SYMBOL_SIZE / 8, Center.y - MS_SYMBOL_SIZE / 2));
        break;
      }
      case -90:
      {
        retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left + 1, Center.y + MS_FRAME_HEIGHT / 2, Center.x - MS_BASE_WIDTH + MS_SYMBOL_SIZE / 3, Center.y + MS_FRAME_HEIGHT / 2));
        
        let point = {...Center};
        point.x = Center.x - MS_FRAME_WIDTH + MS_SYMBOL_SIZE / 3;
        
        retArr = retArr.concat(LINE(CDXFFile1, point.x - MS_SYMBOL_SIZE / 6   , point.y + MS_FRAME_HEIGHT / 2 - MS_SYMBOL_SIZE / 6, point.x + MS_SYMBOL_SIZE / 6 + 1, point.y + MS_FRAME_HEIGHT / 2 + MS_SYMBOL_SIZE / 6 + 1));
        retArr = retArr.concat(LINE(CDXFFile1, point.x + MS_SYMBOL_SIZE / 6    , point.y + MS_FRAME_HEIGHT / 2 - MS_SYMBOL_SIZE / 6, point.x - MS_SYMBOL_SIZE / 6 - 1, point.y + MS_FRAME_HEIGHT / 2 + MS_SYMBOL_SIZE / 6 + 1));
        retArr = retArr.concat(LINE(CDXFFile1, point.x, Center.y + MS_FRAME_HEIGHT / 2 + MS_SYMBOL_SIZE / 2, Center.x, Center.y + MS_FRAME_HEIGHT / 2));
        retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.right + 1, Center.y + MS_FRAME_HEIGHT / 2, Center.x - 2, Center.y + MS_FRAME_HEIGHT / 2));
        
        let rect = {top: 0, left: 0, right: 0, bottom: 0};
        rect.left   = Center.x + MS_FRAME_WIDTH / 6;
        rect.right  = Center.x + MS_FRAME_WIDTH / 6 + MS_SYMBOL_SIZE / 2;
        rect.top  = Center.y + MS_FRAME_HEIGHT / 2 - MS_SYMBOL_SIZE / 4;
        rect.bottom = Center.y + MS_FRAME_HEIGHT / 2 + MS_SYMBOL_SIZE / 4 - 1;
        retArr = retArr.concat(CIRCLE(CDXFFile1, rect));

        retArr = retArr.concat(LINE(CDXFFile1, Center.x + MS_BASE_HEIGHT / 6 + MS_SYMBOL_SIZE / 4, Center.y + MS_FRAME_WIDTH / 2 - MS_SYMBOL_SIZE / 4, Center.x + MS_BASE_HEIGHT / 6 + MS_SYMBOL_SIZE / 4, Center.y));
        
        rect.left   = Center.x + MS_BASE_WIDTH / 2 - MS_SYMBOL_SIZE / 2;
        rect.right  = Center.x + MS_BASE_HEIGHT / 2 + MS_SYMBOL_SIZE / 14;
        rect.top    = Center.y - MS_SYMBOL_SIZE;
        rect.bottom = Center.y;
        
        retArr = retArr.concat(LINE(CDXFFile1, rect.left, rect.top, rect.right, rect.top));
        retArr = retArr.concat(LINE(CDXFFile1, rect.right, rect.top, rect.right, rect.bottom));
        retArr = retArr.concat(LINE(CDXFFile1, rect.right, rect.bottom, rect.left, rect.bottom));
        retArr = retArr.concat(LINE(CDXFFile1, rect.left, rect.bottom, rect.left, rect.top));

        retArr = retArr.concat(STRING(CDXFFile1, rect.left + 11, rect.bottom - 9, 8, "I >", 90));

        retArr = retArr.concat(LINE(CDXFFile1, Center.x + MS_BASE_WIDTH / 2 - MS_SYMBOL_SIZE / 2 , Center.y - MS_SYMBOL_SIZE / 2, Center.x + MS_BASE_WIDTH / 2 - MS_SYMBOL_SIZE / 2 - MS_SYMBOL_SIZE / 4 , Center.y  - MS_SYMBOL_SIZE / 2));
        retArr = retArr.concat(LINE(CDXFFile1, Center.x + MS_BASE_WIDTH / 2 - MS_SYMBOL_SIZE / 2 - (MS_SYMBOL_SIZE / 4 * 2) , Center.y - MS_SYMBOL_SIZE / 2, Center.x + MS_BASE_WIDTH / 2 - MS_SYMBOL_SIZE / 2 - (MS_SYMBOL_SIZE / 4 * 3) , Center.y - MS_SYMBOL_SIZE / 2));
        retArr = retArr.concat(LINE(CDXFFile1, Center.x - MS_SYMBOL_SIZE / 2 , Center.y - MS_SYMBOL_SIZE / 2, Center.x - MS_SYMBOL_SIZE / 2 , Center.y - MS_SYMBOL_SIZE / 2 + MS_SYMBOL_SIZE / 4));
        retArr = retArr.concat(LINE(CDXFFile1, Center.x - MS_SYMBOL_SIZE / 2 , Center.y - MS_SYMBOL_SIZE / 2 + (MS_SYMBOL_SIZE / 4 * 2), Center.x - MS_SYMBOL_SIZE / 2 , Center.y - MS_SYMBOL_SIZE / 2 + (MS_SYMBOL_SIZE / 4 * 3)));
        retArr = retArr.concat(LINE(CDXFFile1, Center.x - MS_SYMBOL_SIZE / 2 , Center.y - MS_SYMBOL_SIZE / 2 + (MS_SYMBOL_SIZE / 4 * 4), Center.x - MS_SYMBOL_SIZE / 2 , Center.y - MS_SYMBOL_SIZE / 2 + (MS_SYMBOL_SIZE / 4 * 5)));
        retArr = retArr.concat(LINE(CDXFFile1, Center.x - MS_SYMBOL_SIZE / 2 , Center.y - MS_SYMBOL_SIZE / 2 + (MS_SYMBOL_SIZE / 4 * 6), Center.x - MS_SYMBOL_SIZE / 2 , Center.y + MS_SYMBOL_SIZE / 2 - MS_SYMBOL_SIZE / 8));
        break;
      }
      case 90:
      {
        retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.right + 1, Center.y - MS_FRAME_HEIGHT / 2, Center.x + MS_BASE_WIDTH - MS_SYMBOL_SIZE / 3, Center.y - MS_FRAME_HEIGHT / 2));
        
        let point = {...Center};
        point.x = Center.x + MS_FRAME_WIDTH - MS_SYMBOL_SIZE / 3;
        
        retArr = retArr.concat(LINE(CDXFFile1, point.x - MS_SYMBOL_SIZE / 6    , point.y - MS_FRAME_HEIGHT / 2 - MS_SYMBOL_SIZE / 6, point.x + MS_SYMBOL_SIZE / 6 + 1, point.y - MS_FRAME_HEIGHT / 2 + MS_SYMBOL_SIZE / 6 + 1));
        retArr = retArr.concat(LINE(CDXFFile1, point.x + MS_SYMBOL_SIZE / 6    , point.y - MS_FRAME_HEIGHT / 2 - MS_SYMBOL_SIZE / 6, point.x - MS_SYMBOL_SIZE / 6 - 1, point.y - MS_FRAME_HEIGHT / 2 + MS_SYMBOL_SIZE / 6 + 1));
        retArr = retArr.concat(LINE(CDXFFile1, point.x, Center.y - MS_FRAME_HEIGHT / 2 - MS_SYMBOL_SIZE / 2,Center.x, Center.y - MS_FRAME_HEIGHT / 2));
        retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left + 1, Center.y - MS_FRAME_HEIGHT / 2, Center.x + 2, Center.y - MS_FRAME_HEIGHT / 2));
        
        let rect = {top: 0, left: 0, right: 0, bottom: 0};
        rect.left   = Center.x - MS_FRAME_HEIGHT / 6- MS_SYMBOL_SIZE / 2;
        rect.right  = Center.x - MS_FRAME_HEIGHT / 6; 
        rect.top  = Center.y - MS_FRAME_WIDTH / 2 - MS_SYMBOL_SIZE / 4;
        rect.bottom = Center.y - MS_FRAME_HEIGHT / 2 + MS_SYMBOL_SIZE / 4 + 1;
        retArr = retArr.concat(CIRCLE(CDXFFile1, rect));

        retArr = retArr.concat(LINE(CDXFFile1, Center.x - MS_BASE_WIDTH / 6 - MS_SYMBOL_SIZE / 4, Center.y - MS_FRAME_HEIGHT / 2 + MS_SYMBOL_SIZE / 4,Center.x - MS_BASE_WIDTH / 6 - MS_SYMBOL_SIZE / 4, Center.y));
        
        rect.left = Center.x - MS_BASE_WIDTH / 2 + MS_SYMBOL_SIZE / 2;
        rect.right = Center.x - MS_BASE_WIDTH / 2 - MS_SYMBOL_SIZE / 14;
        rect.top = Center.y;
        rect.bottom = Center.y + MS_SYMBOL_SIZE;
        
        retArr = retArr.concat(LINE(CDXFFile1, rect.left, rect.top, rect.right, rect.top));
        retArr = retArr.concat(LINE(CDXFFile1, rect.right, rect.top, rect.right, rect.bottom));
        retArr = retArr.concat(LINE(CDXFFile1, rect.right, rect.bottom, rect.left, rect.bottom));
        retArr = retArr.concat(LINE(CDXFFile1, rect.left, rect.bottom, rect.left, rect.top));

        retArr = retArr.concat(STRING(CDXFFile1, rect.left - 11, rect.top + 4, 8,"I >", 270));

        retArr = retArr.concat(LINE(CDXFFile1, Center.x - MS_BASE_WIDTH / 2 + MS_SYMBOL_SIZE / 2, Center.y + MS_SYMBOL_SIZE / 2, Center.x - MS_BASE_WIDTH / 2 + MS_SYMBOL_SIZE / 2 + MS_SYMBOL_SIZE / 4 , Center.y  + MS_SYMBOL_SIZE / 2));
        retArr = retArr.concat(LINE(CDXFFile1, Center.x - MS_BASE_WIDTH / 2 + MS_SYMBOL_SIZE / 2 + (MS_SYMBOL_SIZE / 4 * 2), Center.y + MS_SYMBOL_SIZE / 2, Center.x - MS_BASE_WIDTH / 2 + MS_SYMBOL_SIZE / 2 + (MS_SYMBOL_SIZE / 4 * 3), Center.y + MS_SYMBOL_SIZE / 2));
        retArr = retArr.concat(LINE(CDXFFile1, Center.x + MS_SYMBOL_SIZE / 2 , Center.y + MS_SYMBOL_SIZE / 2,                            Center.x + MS_SYMBOL_SIZE / 2 , Center.y + MS_SYMBOL_SIZE / 2 - MS_SYMBOL_SIZE / 4));
        retArr = retArr.concat(LINE(CDXFFile1, Center.x + MS_SYMBOL_SIZE / 2 , Center.y + MS_SYMBOL_SIZE / 2 - (MS_SYMBOL_SIZE / 4 * 2), Center.x + MS_SYMBOL_SIZE / 2 , Center.y + MS_SYMBOL_SIZE / 2 - (MS_SYMBOL_SIZE / 4 * 3)));
        retArr = retArr.concat(LINE(CDXFFile1, Center.x + MS_SYMBOL_SIZE / 2 , Center.y + MS_SYMBOL_SIZE / 2 - (MS_SYMBOL_SIZE / 4 * 4), Center.x + MS_SYMBOL_SIZE / 2 , Center.y + MS_SYMBOL_SIZE / 2 - (MS_SYMBOL_SIZE / 4 * 5)));
        retArr = retArr.concat(LINE(CDXFFile1, Center.x + MS_SYMBOL_SIZE / 2 , Center.y + MS_SYMBOL_SIZE / 2 - (MS_SYMBOL_SIZE / 4 * 6), Center.x + MS_SYMBOL_SIZE / 2 , Center.y - MS_SYMBOL_SIZE / 2 + MS_SYMBOL_SIZE / 8));
        break;
      }
    }
    break;
  }
  case 1:
    break;
  case 2:
    {
      switch (pHVCB.rotation) {
        case 0:
        {
          retArr = retArr.concat(LINE(CDXFFile1, Center.x - MS_FRAME_WIDTH / 2, rectBaseLP.top - 1, Center.x - MS_FRAME_WIDTH / 2, rectBaseLP.bottom + 1));
          
          let point = {...Center};
          point.y = Center.y - MS_FRAME_HEIGHT + MS_SYMBOL_SIZE / 3;
          
          let rect = {top: 0, left: 0, right: 0, bottom: 0};
          rect.left = Center.x - MS_FRAME_WIDTH / 2 - MS_SYMBOL_SIZE / 4;
          rect.right = Center.x - MS_FRAME_WIDTH / 2 + MS_SYMBOL_SIZE / 4 + 1;
          rect.top = Center.y + MS_FRAME_HEIGHT / 6;
          rect.bottom = Center.y + MS_FRAME_HEIGHT / 6 + MS_SYMBOL_SIZE / 2;
          retArr = retArr.concat(CIRCLE(CDXFFile1, rect));

          retArr = retArr.concat(LINE(CDXFFile1, Center.x - MS_FRAME_WIDTH / 2 + MS_SYMBOL_SIZE / 4, Center.y + MS_BASE_HEIGHT / 6 + MS_SYMBOL_SIZE / 4, Center.x, Center.y + MS_BASE_HEIGHT / 6 + MS_SYMBOL_SIZE / 4));
          
          rect.left = Center.x;
          rect.right = Center.x + MS_SYMBOL_SIZE;
          rect.top = Center.y + MS_BASE_HEIGHT / 2 - MS_SYMBOL_SIZE / 2;
          rect.bottom = Center.y + MS_BASE_HEIGHT / 2 + MS_SYMBOL_SIZE / 14;
          
          retArr = retArr.concat(LINE(CDXFFile1, rect.left, rect.top, rect.right, rect.top));
          retArr = retArr.concat(LINE(CDXFFile1, rect.right, rect.top, rect.right, rect.bottom));
          retArr = retArr.concat(LINE(CDXFFile1, rect.right, rect.bottom, rect.left, rect.bottom));
          retArr = retArr.concat(LINE(CDXFFile1, rect.left, rect.bottom, rect.left, rect.top));

          retArr = retArr.concat(STRING(CDXFFile1, rect.left + 9, rect.top + 7, 8, "I >"));

          retArr = retArr.concat(LINE(CDXFFile1, Center.x + MS_SYMBOL_SIZE / 2, Center.y + MS_BASE_HEIGHT / 2 - MS_SYMBOL_SIZE / 2, Center.x + MS_SYMBOL_SIZE / 2, Center.y + MS_BASE_HEIGHT / 2 - MS_SYMBOL_SIZE / 2 - MS_SYMBOL_SIZE / 4));
          retArr = retArr.concat(LINE(CDXFFile1, Center.x + MS_SYMBOL_SIZE / 2, Center.y + MS_BASE_HEIGHT / 2 - MS_SYMBOL_SIZE / 2 - (MS_SYMBOL_SIZE / 4 * 2), Center.x + MS_SYMBOL_SIZE / 2, Center.y + MS_BASE_HEIGHT / 2 - MS_SYMBOL_SIZE / 2 - (MS_SYMBOL_SIZE / 4 * 3)));
          retArr = retArr.concat(LINE(CDXFFile1, Center.x + MS_SYMBOL_SIZE / 2, Center.y + MS_BASE_HEIGHT / 2 - MS_SYMBOL_SIZE / 2 - (MS_SYMBOL_SIZE / 4 * 4), Center.x + MS_SYMBOL_SIZE / 2, Center.y + MS_BASE_HEIGHT / 2 - MS_SYMBOL_SIZE / 2 - (MS_SYMBOL_SIZE / 4 * 5)));
          break;
        }
        case -90:
        {
            retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left, Center.y + MS_FRAME_HEIGHT / 2, rectBaseLP.right, Center.y + MS_FRAME_HEIGHT / 2));

            let point = {...Center};
            point.x = Center.x + MS_FRAME_WIDTH - MS_SYMBOL_SIZE / 3;

            let rect = {top: 0, left: 0, right: 0, bottom: 0};
            rect.left   = Center.x + MS_FRAME_WIDTH / 6;
            rect.right  = Center.x + MS_FRAME_WIDTH / 6 + MS_SYMBOL_SIZE / 2;
            rect.top  = Center.y + MS_FRAME_HEIGHT / 2 + MS_SYMBOL_SIZE / 4;
            rect.bottom = Center.y + MS_FRAME_HEIGHT / 2 - MS_SYMBOL_SIZE / 4 + 1;
            retArr = retArr.concat(CIRCLE(CDXFFile1, rect));

            retArr = retArr.concat(LINE(CDXFFile1, Center.x + MS_BASE_HEIGHT / 6 + MS_SYMBOL_SIZE / 4, Center.y + MS_FRAME_WIDTH / 2 - MS_SYMBOL_SIZE / 4, Center.x + MS_BASE_HEIGHT / 6 + MS_SYMBOL_SIZE / 4, Center.y ));

            rect.left = Center.x + MS_BASE_WIDTH / 2 - MS_SYMBOL_SIZE / 2;
            rect.right = Center.x + MS_BASE_WIDTH/ 2 + MS_SYMBOL_SIZE / 14;
            rect.top = Center.y - MS_SYMBOL_SIZE;
            rect.bottom = Center.y;
            retArr = retArr.concat(LINE(CDXFFile1, rect.left, rect.top, rect.right, rect.top));
            retArr = retArr.concat(LINE(CDXFFile1, rect.right, rect.top, rect.right, rect.bottom));
            retArr = retArr.concat(LINE(CDXFFile1, rect.right, rect.bottom, rect.left, rect.bottom));
            retArr = retArr.concat(LINE(CDXFFile1, rect.left, rect.bottom, rect.left, rect.top));

            retArr = retArr.concat(STRING(CDXFFile1, rect.left + 11, rect.bottom - 9 , 8, "I >", 90));

            retArr = retArr.concat(LINE(CDXFFile1, Center.x + MS_BASE_WIDTH / 2 - MS_SYMBOL_SIZE / 2, Center.y - MS_SYMBOL_SIZE / 2, Center.x + MS_BASE_WIDTH / 2 - MS_SYMBOL_SIZE / 2 - MS_SYMBOL_SIZE / 4 , Center.y  - MS_SYMBOL_SIZE / 2));
            retArr = retArr.concat(LINE(CDXFFile1, Center.x + MS_BASE_WIDTH / 2 - MS_SYMBOL_SIZE / 2 - (MS_SYMBOL_SIZE / 4 * 2), Center.y - MS_SYMBOL_SIZE / 2, Center.x + MS_BASE_WIDTH / 2 - MS_SYMBOL_SIZE / 2 - (MS_SYMBOL_SIZE / 4 * 3), Center.y - MS_SYMBOL_SIZE / 2));

            retArr = retArr.concat(LINE(CDXFFile1, Center.x + MS_BASE_WIDTH / 2 - MS_SYMBOL_SIZE / 2 - (MS_SYMBOL_SIZE / 4 * 4), Center.y - MS_SYMBOL_SIZE / 2, Center.x + MS_BASE_WIDTH / 2 - MS_SYMBOL_SIZE / 2 - (MS_SYMBOL_SIZE / 4 * 5), Center.y - MS_SYMBOL_SIZE / 2));
            break;
        }
        case 90:
        {
            retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.right, Center.y - MS_FRAME_HEIGHT / 2, rectBaseLP.left, Center.y - MS_FRAME_HEIGHT / 2));

            let point = {...Center};
            point.x = Center.x - MS_FRAME_WIDTH + MS_SYMBOL_SIZE / 3;

            let rect = {top: 0, left: 0, right: 0, bottom: 0};
            rect.left   = Center.x - MS_FRAME_HEIGHT / 6 - MS_SYMBOL_SIZE / 2;
            rect.right  = Center.x - MS_FRAME_HEIGHT / 6;
            rect.top  = Center.y - MS_FRAME_WIDTH / 2 + MS_SYMBOL_SIZE / 4 - 1;
            rect.bottom   = Center.y - MS_FRAME_HEIGHT / 2 - MS_SYMBOL_SIZE / 4;
            retArr = retArr.concat(CIRCLE(CDXFFile1, rect));

            retArr = retArr.concat(LINE(CDXFFile1, Center.x - MS_BASE_HEIGHT / 6 - MS_SYMBOL_SIZE / 4, Center.y - MS_FRAME_WIDTH / 2 + MS_SYMBOL_SIZE / 4, Center.x - MS_BASE_HEIGHT / 6 - MS_SYMBOL_SIZE / 4, Center.y ));

            rect.left   = Center.x - MS_BASE_WIDTH / 2 + MS_SYMBOL_SIZE / 2;
            rect.right  = Center.x - MS_BASE_WIDTH / 2 - MS_SYMBOL_SIZE / 14;
            rect.top    = Center.y;
            rect.bottom = Center.y + MS_SYMBOL_SIZE;
            retArr = retArr.concat(LINE(CDXFFile1, rect.left, rect.top, rect.right, rect.top));
            retArr = retArr.concat(LINE(CDXFFile1, rect.right, rect.top, rect.right, rect.bottom));
            retArr = retArr.concat(LINE(CDXFFile1, rect.right, rect.bottom, rect.left, rect.bottom));
            retArr = retArr.concat(LINE(CDXFFile1, rect.left, rect.bottom, rect.left, rect.top));

            retArr = retArr.concat(STRING(CDXFFile1, rect.left - 11, rect.top + 4, 8, "I >", 270));
            
            retArr = retArr.concat(LINE(CDXFFile1, Center.x - MS_BASE_WIDTH / 2 + MS_SYMBOL_SIZE / 2, Center.y + MS_SYMBOL_SIZE / 2, Center.x - MS_BASE_WIDTH / 2 + MS_SYMBOL_SIZE / 2 + MS_SYMBOL_SIZE / 4 , Center.y  + MS_SYMBOL_SIZE / 2));
            retArr = retArr.concat(LINE(CDXFFile1, Center.x - MS_BASE_WIDTH / 2 + MS_SYMBOL_SIZE / 2 + (MS_SYMBOL_SIZE / 4 * 2), Center.y + MS_SYMBOL_SIZE / 2, Center.x - MS_BASE_WIDTH / 2 + MS_SYMBOL_SIZE / 2 + (MS_SYMBOL_SIZE / 4 * 3), Center.y + MS_SYMBOL_SIZE / 2));

            retArr = retArr.concat(LINE(CDXFFile1, Center.x - MS_BASE_WIDTH / 2 + MS_SYMBOL_SIZE / 2 + (MS_SYMBOL_SIZE / 4 * 4), Center.y + MS_SYMBOL_SIZE / 2, Center.x - MS_BASE_WIDTH / 2 + MS_SYMBOL_SIZE / 2 + (MS_SYMBOL_SIZE / 4 * 5), Center.y + MS_SYMBOL_SIZE / 2));
            break;
        }
      }
    }
    break;
  }
  
  return retArr;
}

const doDxfOutSymbolFuse = (CDXFFile1: any, pFuse: any) => {
  let retArr: any[] = [];

  let rectBaseLP = getRectBaseLP(pFuse);
  let rectCenter = {...rectBaseLP};
  let Center = getCenterPoint(rectCenter);

  let point = {...Center};
  let rect = {top: 0, left: 0, right: 0, bottom: 0};

  let yy = Math.floor(MS_SYMBOL_SIZE / 20);

  switch (pFuse.properties.symKind) {
  case 0:
    switch (pFuse.rotation) {
    case 0:
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.top - 1, Center.x, Center.y - MS_BASE_HEIGHT + 13 * yy));

      point.y = Center.y - MS_BASE_HEIGHT + 13 * yy;

      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 17 * yy, point.y, Center.x, Center.y + MS_BASE_HEIGHT - 22 * yy));

      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 17 * yy, Center.y - 10 * yy, Center.x - 8 * yy, Center.y + 12 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 8 * yy, Center.y + 12 * yy, Center.x + 2 * yy, Center.y + 7 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 2 * yy, Center.y + 7 * yy, Center.x - 6 * yy, Center.y - 15 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 6 * yy, Center.y - 15 * yy, Center.x - 17 * yy, Center.y - 10 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.bottom + 1, Center.x, Center.y +  MS_BASE_HEIGHT - 22 * yy - 1));
      break;
    case -90:
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, Center.y, Center.x - MS_BASE_WIDTH + 13 * yy, Center.y));

      point.x = Center.x - MS_BASE_HEIGHT + 13 * yy;

      retArr = retArr.concat(LINE(CDXFFile1, point.x, Center.y + 17 * yy, Center.x + MS_BASE_WIDTH - 22 * yy, Center.y));

      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 11 * yy, Center.y + 17 * yy, Center.x + 11 * yy, Center.y + 9 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 11 * yy, Center.y + 9 * yy, Center.x + 7 * yy, Center.y - 2 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 7 * yy, Center.y - 2 * yy, Center.x - 15 * yy, Center.y + 6 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 15 * yy, Center.y + 6 * yy, Center.x - 11 * yy, Center.y + 17 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.right + 1, Center.y, Center.x + MS_BASE_HEIGHT - 22 * yy - 1, Center.y));
      break;
    case 90:
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.right + 1, Center.y, Center.x + MS_BASE_WIDTH - MS_SYMBOL_SIZE / 2, Center.y));

      point.x = Center.x + MS_BASE_HEIGHT - MS_SYMBOL_SIZE / 2;

      retArr = retArr.concat(LINE(CDXFFile1, point.x, Center.y - 17 * yy, Center.x - MS_BASE_WIDTH + 22 * yy, Center.y));

      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 11 * yy, Center.y - 17 * yy, Center.x - 11 * yy, Center.y - 9 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 11 * yy, Center.y - 9 * yy, Center.x - 7 * yy, Center.y + 2 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 7 * yy, Center.y + 2 * yy, Center.x + 15 * yy, Center.y - 6 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 15 * yy, Center.y - 6 * yy, Center.x + 11 * yy, Center.y - 17 * yy));

      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, Center.y, Center.x - MS_BASE_HEIGHT + 22 * yy + 1, Center.y));
      break;
    }
    break;
  case 1:
    switch (pFuse.rotation) {
    case 0:
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.top - 1, Center.x, Center.y - MS_BASE_HEIGHT + 13 * yy));

      point.y = Center.y - MS_BASE_HEIGHT + 13 * yy;

      retArr = retArr.concat(LINE(CDXFFile1, point.x - 6 * yy, point.y, point.x + 6 * yy + 1, point.y));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 17 * yy, point.y, Center.x, Center.y + MS_BASE_HEIGHT - 22 * yy));

      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 17 * yy, Center.y - 10 * yy, Center.x - 8 * yy, Center.y + 12 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 8 * yy, Center.y + 12 * yy, Center.x + 2 * yy, Center.y + 7 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 2 * yy, Center.y + 7 * yy, Center.x - 6 * yy, Center.y - 15 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 6 * yy, Center.y - 15 * yy, Center.x - 17 * yy, Center.y - 10 * yy));

      retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.bottom + 1, Center.x, Center.y +  MS_BASE_HEIGHT - 22 * yy - 1));
      break;
    case -90:
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, Center.y, Center.x - MS_BASE_WIDTH + 13 * yy, Center.y));

      point.x = Center.x - MS_BASE_HEIGHT + 13 * yy;

      retArr = retArr.concat(LINE(CDXFFile1, point.x, point.y - 6 * yy, point.x, point.y + 6 * yy + 1));
      retArr = retArr.concat(LINE(CDXFFile1, point.x, Center.y + 17 * yy, Center.x + MS_BASE_WIDTH - 22 * yy, Center.y));

      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 11 * yy, Center.y + 17 * yy, Center.x + 11 * yy, Center.y + 9 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 11 * yy, Center.y + 9 * yy, Center.x + 7 * yy, Center.y - 2 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 7 * yy, Center.y - 2 * yy, Center.x - 15 * yy, Center.y + 6 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 15 * yy, Center.y + 6 * yy, Center.x - 11 * yy, Center.y + 17 * yy));

      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.right + 1, Center.y, Center.x + MS_BASE_HEIGHT - 22 * yy - 1, Center.y));
      break;
    case 90:
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.right + 1, Center.y, Center.x + MS_BASE_WIDTH - MS_SYMBOL_SIZE / 2, Center.y));

      point.x = Center.x + MS_BASE_HEIGHT - MS_SYMBOL_SIZE / 2;

      retArr = retArr.concat(LINE(CDXFFile1, point.x, point.y - MS_SYMBOL_SIZE / 4, point.x, point.y + MS_SYMBOL_SIZE / 4 + 1));
      retArr = retArr.concat(LINE(CDXFFile1, point.x, Center.y - 17 * yy, Center.x - MS_BASE_WIDTH + 22 * yy, Center.y));

      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 11 * yy, Center.y - 17 * yy, Center.x - 11 * yy, Center.y - 9 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 11 * yy, Center.y - 9 * yy, Center.x - 7 * yy, Center.y + 2 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 7 * yy, Center.y + 2 * yy, Center.x + 15 * yy, Center.y - 6 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 15 * yy, Center.y - 6 * yy, Center.x + 11 * yy, Center.y - 17 * yy));

      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, Center.y, Center.x - MS_BASE_HEIGHT + 22 * yy + 1, Center.y));
      break;
    }
    break;
  case 2:
    switch (pFuse.rotation) {
    case 0:
      point.y = Center.y - MS_BASE_HEIGHT + 13 * yy;

      retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.top - 1, Center.x, point.y));
      retArr = retArr.concat(LINE(CDXFFile1, point.x - 6 * yy, point.y, point.x + 6 * yy + 1, point.y));
      
      rect.left = point.x - 4 * yy;
      rect.right = point.x + 4 * yy + 1;
      rect.top = point.y;
      rect.bottom = point.y + 8 * yy;
      retArr = retArr.concat(CIRCLE(CDXFFile1, rect));

      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 17 * yy, point.y, Center.x, Center.y + MS_BASE_HEIGHT - 22 * yy));
      
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 17 * yy, Center.y - 10 * yy, Center.x - 8 * yy, Center.y + 12 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 8 * yy, Center.y + 12 * yy, Center.x + 2 * yy, Center.y + 7 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 2 * yy, Center.y + 7 * yy, Center.x - 6 * yy, Center.y - 15 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 6 * yy, Center.y - 15 * yy, Center.x - 17 * yy, Center.y - 10 * yy));

      retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.bottom + 1, Center.x, Center.y +  MS_BASE_HEIGHT - 22 * yy - 1));
      break;
    case -90:
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, Center.y, Center.x - MS_BASE_WIDTH + 13 * yy, Center.y));

      point.x = Center.x - MS_BASE_HEIGHT + 13 * yy;

      retArr = retArr.concat(LINE(CDXFFile1, point.x, point.y - 6 * yy, point.x, point.y + 6 * yy + 1));

      rect.left = point.x;
      rect.top = point.y - 4 * yy;
      rect.right = point.x + 8 * yy;
      rect.bottom = point.y + 4 * yy + 1;
      retArr = retArr.concat(CIRCLE(CDXFFile1, rect));

      retArr = retArr.concat(LINE(CDXFFile1, point.x, Center.y + 17 * yy, Center.x + MS_BASE_WIDTH - 22 * yy, Center.y));

      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 11 * yy, Center.y + 17 * yy, Center.x + 11 * yy, Center.y + 9 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 11 * yy, Center.y + 9 * yy, Center.x + 7 * yy, Center.y - 2 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 7 * yy, Center.y - 2 * yy, Center.x - 15 * yy, Center.y + 6 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 15 * yy, Center.y + 6 * yy, Center.x - 11 * yy, Center.y + 17 * yy));

      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.right + 1, Center.y, Center.x + MS_BASE_HEIGHT - 22 * yy - 1, Center.y));
      break;
    case 90:
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.right + 1, Center.y, Center.x + MS_BASE_WIDTH - MS_SYMBOL_SIZE / 2, Center.y));

      point.x = Center.x + MS_BASE_HEIGHT - MS_SYMBOL_SIZE / 2;

      retArr = retArr.concat(LINE(CDXFFile1, point.x, point.y - MS_SYMBOL_SIZE / 4, point.x, point.y + MS_SYMBOL_SIZE / 4 + 1));

      rect.left = point.x - 8 * yy;
      rect.top = point.y - 4 * yy;
      rect.right = point.x;
      rect.bottom = point.y + 4 * yy + 1;
      retArr = retArr.concat(CIRCLE(CDXFFile1, rect));

      retArr = retArr.concat(LINE(CDXFFile1, point.x, Center.y - 17 * yy, Center.x - MS_BASE_WIDTH + 22 * yy, Center.y));

      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 11 * yy, Center.y - 17 * yy, Center.x - 11 * yy, Center.y - 9 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 11 * yy, Center.y - 9 * yy, Center.x - 7 * yy, Center.y + 2 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 7 * yy, Center.y + 2 * yy, Center.x + 15 * yy, Center.y - 6 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 15 * yy, Center.y - 6 * yy, Center.x + 11 * yy, Center.y - 17 * yy));

      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, Center.y, Center.x - MS_BASE_HEIGHT + 22 * yy + 1, Center.y));
      break;
    }
    break;
  case 3:
    switch (pFuse.rotation) {
    case 0:
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.top - 1, Center.x, rectBaseLP.bottom + 2));

      point.y = Center.y - MS_BASE_HEIGHT + MS_SYMBOL_SIZE / 2;
      
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - MS_SYMBOL_SIZE / 6, Center.y - MS_SYMBOL_SIZE, Center.x - MS_SYMBOL_SIZE / 6, Center.y + MS_SYMBOL_SIZE));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - MS_SYMBOL_SIZE / 6, Center.y + MS_SYMBOL_SIZE, Center.x + MS_SYMBOL_SIZE / 6, Center.y + MS_SYMBOL_SIZE));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + MS_SYMBOL_SIZE / 6, Center.y + MS_SYMBOL_SIZE, Center.x + MS_SYMBOL_SIZE / 6, Center.y - MS_SYMBOL_SIZE));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + MS_SYMBOL_SIZE / 6, Center.y - MS_SYMBOL_SIZE, Center.x - MS_SYMBOL_SIZE / 6, Center.y - MS_SYMBOL_SIZE));
      break;
    case -90:
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, Center.y, rectBaseLP.right + 2, Center.y));

      point.y = Center.y - MS_BASE_HEIGHT + MS_SYMBOL_SIZE / 2;
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - MS_SYMBOL_SIZE, Center.y + MS_SYMBOL_SIZE / 6, Center.x + MS_SYMBOL_SIZE, Center.y + MS_SYMBOL_SIZE / 6));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + MS_SYMBOL_SIZE, Center.y + MS_SYMBOL_SIZE / 6, Center.x + MS_SYMBOL_SIZE, Center.y - MS_SYMBOL_SIZE / 6));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + MS_SYMBOL_SIZE, Center.y - MS_SYMBOL_SIZE / 6, Center.x - MS_SYMBOL_SIZE, Center.y - MS_SYMBOL_SIZE / 6));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - MS_SYMBOL_SIZE, Center.y - MS_SYMBOL_SIZE / 6, Center.x - MS_SYMBOL_SIZE, Center.y + MS_SYMBOL_SIZE / 6));
      break;
    case 90:
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, Center.y, rectBaseLP.right + 2, Center.y));

      point.y = Center.y - MS_BASE_HEIGHT + MS_SYMBOL_SIZE / 2;
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - MS_SYMBOL_SIZE, Center.y + MS_SYMBOL_SIZE / 6, Center.x + MS_SYMBOL_SIZE, Center.y + MS_SYMBOL_SIZE / 6));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + MS_SYMBOL_SIZE, Center.y + MS_SYMBOL_SIZE / 6, Center.x + MS_SYMBOL_SIZE, Center.y - MS_SYMBOL_SIZE / 6));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + MS_SYMBOL_SIZE, Center.y - MS_SYMBOL_SIZE / 6, Center.x - MS_SYMBOL_SIZE, Center.y - MS_SYMBOL_SIZE / 6));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - MS_SYMBOL_SIZE, Center.y - MS_SYMBOL_SIZE / 6, Center.x - MS_SYMBOL_SIZE, Center.y + MS_SYMBOL_SIZE / 6));
      break;
    }
    break;
  }

  return retArr;
}

const doDxfOutSymbol2E = (CDXFFile1: any, p2E: any) => {
  let retArr: any[] = [];

  let rectBaseLP = getRectBaseLP(p2E);
  let rectCenter = {...rectBaseLP};
  let Center = getCenterPoint(rectCenter);

  let point = {...Center};
  let rect = {top: 0, left: 0, right: 0, bottom: 0};
  let point1 = {x: 0, y: 0};
  let Center2 = {x: 0, y: 0};

  let yy = Math.floor(MS_SYMBOL_SIZE / 20);
  switch (p2E.properties.symKind) {
  case 0:
    point.y = Center.y - MS_BASE_HEIGHT + 18 * yy;

    retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.top - 1, Center.x, point.y));

        rect.left = point.x - 4 * yy;
        rect.top = point.y - 10 * yy;
        rect.right = point.x + 5 * yy;
        rect.bottom = point.y;
        point1.x = point.x;
        point1.y = point.y - 10 * yy;
        Center2.x = point.x;
        Center2.y = point.y;
        retArr = retArr.concat(ARC_Left(CDXFFile1, rect, point1, Center2));

    retArr = retArr.concat(LINE(CDXFFile1, Center.x - 17 * yy, point.y, Center.x, Center.y + MS_BASE_HEIGHT - 22 * yy));

    retArr = retArr.concat(SOLID(
      CDXFFile1, 
      Center.x - 10, Center.y - 6,
      Center.x - 6, Center.y + 4,
      Center.x - 14, Center.y + 8,
      Center.x - 14, Center.y + 8));
    retArr = retArr.concat(SOLID(
      CDXFFile1, 
      Center.x - 18, Center.y - 1,
      Center.x - 10, Center.y - 6,
      Center.x - 14, Center.y + 8,
      Center.x - 14, Center.y + 8));

    retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.bottom + 1, Center.x, Center.y +  MS_BASE_HEIGHT - 22 * yy - 1));
    break;
  case 1:
    point.y = Center.y - MS_BASE_HEIGHT + 18 * yy;

    retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.top - 1, Center.x, point.y));
    retArr = retArr.concat(LINE(CDXFFile1, Center.x, point.y, Center.x + 17 * yy, point.y));

        rect.left = point.x - 4 * yy;
        rect.top = point.y - 10 * yy;
        rect.right = point.x + 5 * yy;
        rect.bottom = point.y;
        point1.x = point.x;
        point1.y = point.y;
        Center2.x = point.x;
        Center2.y = point.y - 10 * yy;
        retArr = retArr.concat(ARC_Right(CDXFFile1, rect, point1, Center2));

    retArr = retArr.concat(LINE(CDXFFile1, Center.x + 13 * yy, point.y - 5 * yy, Center.x, Center.y + MS_BASE_HEIGHT - 22 * yy));
    retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.bottom + 1, Center.x, Center.y +  MS_BASE_HEIGHT - 22 * yy - 1));
    break;
  }

  let pObjAdjust = p2E.properties.details
  if (pObjAdjust != null) {
    if (pObjAdjust.base.useCT == true) {
        rect.left = Center.x - 9 * yy;
        rect.top = Center.y - 9 * yy + MS_BASE_HEIGHT;
        rect.right = Center.x + 9;
        rect.bottom = Center.y + 9 * yy + MS_BASE_HEIGHT;
        retArr = retArr.concat(CIRCLE(CDXFFile1, rect));
        
        retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.right - 1, Center.y + MS_BASE_HEIGHT, Center.x + 9 * yy, Center.y + MS_BASE_HEIGHT));
    }
  }

  return retArr;
}

const doDxfOutSymbolZeroCond = (
  CDXFFile1: any, pZeroCond: any, pOpenedTransCenter: any, pDoc: any
) => {
  let retArr: any[] = [];

  let rectBaseLP = getRectBaseLP(pZeroCond);
  let rectCenter = {...rectBaseLP};
  let Center = getCenterPoint(rectCenter);

  let rect = {top: 0, left: 0, right: 0, bottom: 0};
  rect.left = rectBaseLP.left - 1;
  rect.top = Center.y - MS_SYMBOL_SIZE / 8;
  rect.right = rectBaseLP.right + 1;
  rect.bottom = Center.y + MS_SYMBOL_SIZE / 8;

  retArr = retArr.concat(SOLID(
    CDXFFile1, 
    rect.left, rect.top,
    rect.right, rect.top,
    rect.left, rect.bottom,
    rect.right, rect.bottom));

  let sizeZero = getSizeRectTotal(rectBaseLP);
  let topZero = {x: rectBaseLP.left, y: rectBaseLP.top};
  let bottomZero = {x: rectBaseLP.right, y: rectBaseLP.bottom}

  // let listElement = []
  // if (pOpenedTransCenter != null){
  //   listElement = [...pOpenedTransCenter.m_listTCGroup];
  // } else {
  //   listElement = [...pDoc.m_listGroup];
  // }

  let listElement = [...pDoc.m_listGroup]
  listElement.forEach((pElement: any) => {
    let rectElement = doGetRectSymbol(pElement);
    if (isCanConnectBottom(pElement) && pElement.type != EKind.MS_ZEROCOND){
      let bottomElement = {x: rectElement.right, y: rectElement.bottom};
      let topElement = {x: rectElement.left, y: rectElement.top};
      if (bottomZero.y == bottomElement.y + sizeZero.cy + MS_DIF_HEIGHT) {
        for (let i = 0; i < (sizeZero.cx + MS_DIF_WIDTH) / MS_FRAME_WIDTH; i++) {
          let x = topZero.x + MS_FRAME_WIDTH * i;
          if (x == topElement.x) {
            retArr = retArr.concat(LINE(CDXFFile1, x + MS_BASE_WIDTH / 2, Center.y, x + MS_BASE_WIDTH / 2, topZero.y - MS_DIF_HEIGHT - 2));
          }
        }
      }
    }
    if (isCanConnectTop(pElement) && pElement.type != EKind.MS_ZEROCOND) {
      let topElement = {x: rectElement.left, y: rectElement.top};
      if (topZero.y == topElement.y - sizeZero.cy - MS_DIF_HEIGHT) {
        for (let i = 0; i < (sizeZero.cx + MS_DIF_WIDTH) / MS_FRAME_WIDTH; i++) {
          let x = topZero.x + MS_FRAME_WIDTH * i;
          if (x == topElement.x) {
            retArr = retArr.concat(LINE(CDXFFile1, x + MS_FRAME_WIDTH / 2 - MS_DIF_WIDTH, Center.y, x + MS_FRAME_WIDTH / 2 - MS_DIF_WIDTH, bottomZero.y + 1));
          }
        }
      }
    }
  })
  
  return retArr;
}

const doDxfOutSymbolConnect = (CDXFFile1: any, pConnect: any) => {
  let retArr: any[] = [];

  let rectBaseLP = getRectBaseLP(pConnect);
  let rectCenter = {...rectBaseLP};
  let Center = getCenterPoint(rectCenter);

  switch (pConnect.rotation) {
  case 0:
    retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.top - 1, Center.x, rectBaseLP.bottom + 1));
    break;
  case -90:
    retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, Center.y, rectBaseLP.right + 1, Center.y));
    break;
  case 90:
    retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, Center.y, rectBaseLP.right + 1, Center.y));
    break;
  }

  return retArr;
}

const doDxfOutSymbolWire = (
  CDXFFile1: any,
  pWire: any,
  nOpeMode: number
) => {
  let retArr: any[] = [];

  let rectBaseLP = getRectBaseLP(pWire);
  let rectCenter = {...rectBaseLP};
  let Center = getCenterPoint(rectCenter);

  if (nOpeMode == MS_MODE_POWER_FLOW) {
    let pointDrawCenter = {x: 0, y: 0};

    switch (pWire.rotation) {
    case 0:
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.top - 1, Center.x, Center.y - 60));

      retArr = retArr.concat(ANGLE_ARC(CDXFFile1, Center.x, Center.y - 50, 10, 230, 220));
      retArr = retArr.concat(ANGLE_ARC(CDXFFile1, Center.x, Center.y - 35, 10, 230, 260));
      retArr = retArr.concat(ANGLE_ARC(CDXFFile1, Center.x, Center.y - 20, 10, 270, 220));

      retArr = retArr.concat(LINE(CDXFFile1, Center.x, Center.y - 10, Center.x, Center.y + 10));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, Center.y + 10, Center.x + 10, Center.y + 15));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 10, Center.y + 15, Center.x - 10, Center.y + 25));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 10, Center.y + 25, Center.x + 10, Center.y + 35));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 10, Center.y + 35, Center.x - 10, Center.y + 45));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 10, Center.y + 45, Center.x + 10, Center.y + 55));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 10, Center.y + 55, Center.x - 10, Center.y + 65));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 10, Center.y + 65, Center.x, Center.y + 70));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, Center.y + 70, Center.x, rectBaseLP.bottom + 1));
      break;
    case -90:
      pointDrawCenter.x = Center.x - 40;
      pointDrawCenter.y = Center.y;

      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, pointDrawCenter.y, pointDrawCenter.x - 30, pointDrawCenter.y));

      retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x - 30, pointDrawCenter.y, pointDrawCenter.x - 25, pointDrawCenter.y - 10));
      retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x - 25, pointDrawCenter.y - 10, pointDrawCenter.x - 15, pointDrawCenter.y + 10));
      retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x - 15, pointDrawCenter.y + 10, pointDrawCenter.x - 5, pointDrawCenter.y - 10));
      retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x - 5, pointDrawCenter.y - 10, pointDrawCenter.x + 5, pointDrawCenter.y + 10));
      retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x + 5, pointDrawCenter.y + 10, pointDrawCenter.x + 15, pointDrawCenter.y - 10));
      retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x + 15, pointDrawCenter.y - 10, pointDrawCenter.x + 25, pointDrawCenter.y + 10));
      retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x + 25, pointDrawCenter.y + 10, pointDrawCenter.x + 30, pointDrawCenter.y));

      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 10, pointDrawCenter.y, Center.x + 10, pointDrawCenter.y));

      pointDrawCenter.x = Center.x + 35;
      pointDrawCenter.y = Center.y;

      retArr = retArr.concat(ANGLE_ARC(CDXFFile1, pointDrawCenter.x - 15, pointDrawCenter.y, 10, 320, 220));
      retArr = retArr.concat(ANGLE_ARC(CDXFFile1, pointDrawCenter.x, pointDrawCenter.y, 10, 320, 260));
      retArr = retArr.concat(ANGLE_ARC(CDXFFile1, pointDrawCenter.x + 15, pointDrawCenter.y, 10, 0, 220));
      
      retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x + 25, pointDrawCenter.y, rectBaseLP.right + 1, pointDrawCenter.y));
      break;
    case 90:
      pointDrawCenter.x = Center.x - 40;
      pointDrawCenter.y = Center.y;

      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, pointDrawCenter.y, pointDrawCenter.x - 30, pointDrawCenter.y));

      retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x - 30, pointDrawCenter.y, pointDrawCenter.x - 25, pointDrawCenter.y - 10));
      retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x - 25, pointDrawCenter.y - 10, pointDrawCenter.x - 15, pointDrawCenter.y + 10));
      retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x - 15, pointDrawCenter.y + 10, pointDrawCenter.x - 5, pointDrawCenter.y - 10));
      retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x - 5, pointDrawCenter.y - 10, pointDrawCenter.x + 5, pointDrawCenter.y + 10));
      retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x + 5, pointDrawCenter.y + 10, pointDrawCenter.x + 15, pointDrawCenter.y - 10));
      retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x + 15, pointDrawCenter.y - 10, pointDrawCenter.x + 25, pointDrawCenter.y + 10));
      retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x + 25, pointDrawCenter.y + 10, pointDrawCenter.x + 30, pointDrawCenter.y));

      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 10, pointDrawCenter.y, Center.x + 10, pointDrawCenter.y));

      pointDrawCenter.x = Center.x + 35;
      pointDrawCenter.y = Center.y;

      retArr = retArr.concat(ANGLE_ARC(CDXFFile1, pointDrawCenter.x - 15, pointDrawCenter.y, 10, 320, 220));
      retArr = retArr.concat(ANGLE_ARC(CDXFFile1, pointDrawCenter.x, pointDrawCenter.y, 10, 320, 260));
      retArr = retArr.concat(ANGLE_ARC(CDXFFile1, pointDrawCenter.x + 15, pointDrawCenter.y, 10, 0, 220));
      
      retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x + 25, pointDrawCenter.y, rectBaseLP.right + 1, pointDrawCenter.y));
      break;
    }
  }
  else if (nOpeMode == MS_MODE_PERCENT_Z) {
    switch (pWire.rotation) {
    case 0:
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.top - 1, Center.x, Center.y - 30));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, Center.y - 30, Center.x + 10, Center.y - 25));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 10, Center.y - 25, Center.x - 10, Center.y - 15));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 10, Center.y - 15, Center.x + 10, Center.y - 5));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 10, Center.y - 5, Center.x - 10, Center.y + 5));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 10, Center.y + 5, Center.x + 10, Center.y + 15));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 10, Center.y + 15, Center.x - 10, Center.y + 25));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 10, Center.y + 25, Center.x, Center.y + 30));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, Center.y + 30, Center.x, rectBaseLP.bottom + 1));
      break;
    case -90:
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, Center.y, Center.x - 30, Center.y));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 30, Center.y, Center.x - 25, Center.y - 10));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 25, Center.y - 10, Center.x - 15, Center.y + 10));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 15, Center.y + 10, Center.x - 5, Center.y - 10));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 5, Center.y - 10, Center.x + 5, Center.y + 10));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 5, Center.y + 10, Center.x + 15, Center.y - 10));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 15, Center.y - 10, Center.x + 25, Center.y + 10));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 25, Center.y + 10, Center.x + 30, Center.y));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 30, Center.y, rectBaseLP.right + 1, Center.y));
      break;
    case 90:
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, Center.y, Center.x - 30, Center.y));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 30, Center.y, Center.x - 25, Center.y - 10));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 25, Center.y - 10, Center.x - 15, Center.y + 10));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 15, Center.y + 10, Center.x - 5, Center.y - 10));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 5, Center.y - 10, Center.x + 5, Center.y + 10));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 5, Center.y + 10, Center.x + 15, Center.y - 10));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 15, Center.y - 10, Center.x + 25, Center.y + 10));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 25, Center.y + 10, Center.x + 30, Center.y));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 30, Center.y, rectBaseLP.right + 1, Center.y));
      break;
    }
  }
  else {
    switch (pWire.rotation) {
    case 0:
      if (pWire.properties.symKind == 0) {
        retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.top - 1, Center.x, rectBaseLP.bottom + 1));
      }
      else {
        let yy = Math.floor(MS_SYMBOL_SIZE / 2);

        retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.top - 1, Center.x, rectBaseLP.top + yy / 2));

        retArr = retArr.concat(LINE(CDXFFile1, Center.x - yy / 2, rectBaseLP.top + yy / 2, Center.x + yy / 2, rectBaseLP.top + yy / 2));
        retArr = retArr.concat(LINE(CDXFFile1, Center.x + yy / 2, rectBaseLP.top + yy / 2, Center.x, rectBaseLP.top + 3 * yy / 2));
        retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.top + 3 * yy / 2, Center.x - yy / 2, rectBaseLP.top + yy / 2));

        retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.top + 3 * yy / 2, Center.x, rectBaseLP.bottom - 3 * yy / 2));

        retArr = retArr.concat(LINE(CDXFFile1, Center.x - yy / 2, rectBaseLP.bottom - yy / 2, Center.x + yy / 2, rectBaseLP.bottom - yy / 2));
        retArr = retArr.concat(LINE(CDXFFile1, Center.x + yy / 2, rectBaseLP.bottom - yy / 2, Center.x, rectBaseLP.bottom - 3 * yy / 2));
        retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.bottom - 3 * yy / 2, Center.x - yy / 2, rectBaseLP.bottom - yy / 2));

        retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.bottom + 1, Center.x, rectBaseLP.bottom - yy / 2));
      }
      break;
    case -90:
      if (pWire.properties.symKind == 0) {
        retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, Center.y, rectBaseLP.right + 1, Center.y));
      }
      else {
        let yy = Math.floor(MS_SYMBOL_SIZE / 2);

        retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, Center.y, rectBaseLP.left + yy / 2, Center.y));

        retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left + yy / 2, Center.y - yy / 2, rectBaseLP.left + yy / 2, Center.y + yy / 2));
        retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left + yy / 2, Center.y + yy / 2, rectBaseLP.left + 3 * yy / 2, Center.y));
        retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left + 3 * yy / 2, Center.y, rectBaseLP.left + yy / 2, Center.y - yy / 2));

        retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left + 3 * yy / 2, Center.y, rectBaseLP.right - 3 * yy / 2, Center.y));

        retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.right - yy / 2, Center.y - yy / 2, rectBaseLP.right - yy / 2, Center.y + yy / 2));
        retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.right - yy / 2, Center.y + yy / 2, rectBaseLP.right - 3 * yy / 2, Center.y));
        retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.right - 3 * yy / 2, Center.y, rectBaseLP.right - yy / 2, Center.y - yy / 2));

        retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.right + 1, Center.y, rectBaseLP.right - yy / 2, Center.y));
      }
      break;
    case 90:
      if (pWire.properties.symKind == 0) {
        retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, Center.y, rectBaseLP.right + 1, Center.y));
      }
      else {
        let yy = Math.floor(MS_SYMBOL_SIZE / 2);

        retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, Center.y, rectBaseLP.left + yy / 2, Center.y));

        retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left + yy / 2, Center.y - yy / 2, rectBaseLP.left + yy / 2, Center.y + yy / 2));
        retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left + yy / 2, Center.y + yy / 2, rectBaseLP.left + 3 * yy / 2, Center.y));
        retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left + 3 * yy / 2, Center.y, rectBaseLP.left + yy / 2, Center.y - yy / 2));

        retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left + 3 * yy / 2, Center.y, rectBaseLP.right - 3 * yy / 2, Center.y));

        retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.right - yy / 2, Center.y - yy / 2, rectBaseLP.right - yy / 2, Center.y + yy / 2));
        retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.right - yy / 2, Center.y + yy / 2, rectBaseLP.right - 3 * yy / 2, Center.y));
        retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.right - 3 * yy / 2, Center.y, rectBaseLP.right - yy / 2, Center.y - yy / 2));

        retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.right + 1, Center.y, rectBaseLP.right - yy / 2, Center.y));
      }
      break;
    }
  }
  return retArr;
}

const doDxfOutSymbolBusBar = (
  CDXFFile1: any,
  pBusBar: any,
  nOpeMode: number
) => {
  let retArr: any[] = [];

  let rectBaseLP = getRectBaseLP(pBusBar);
  let rectCenter = {...rectBaseLP};
  let Center = getCenterPoint(rectCenter);

  if (nOpeMode == MS_MODE_POWER_FLOW) {
    let pointDrawCenter = {x: 0, y: 0};

    switch (pBusBar.rotation) {
    case 0:
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.top - 1, Center.x, Center.y - 60));

      retArr = retArr.concat(ANGLE_ARC(CDXFFile1, Center.x, Center.y - 50, 10, 230, 220));
      retArr = retArr.concat(ANGLE_ARC(CDXFFile1, Center.x, Center.y - 35, 10, 230, 260));
      retArr = retArr.concat(ANGLE_ARC(CDXFFile1, Center.x, Center.y - 20, 10, 270, 220));

      retArr = retArr.concat(LINE(CDXFFile1, Center.x, Center.y - 10, Center.x, Center.y + 10));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, Center.y + 10, Center.x + 10, Center.y + 15));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 10, Center.y + 15, Center.x - 10, Center.y + 25));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 10, Center.y + 25, Center.x + 10, Center.y + 35));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 10, Center.y + 35, Center.x - 10, Center.y + 45));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 10, Center.y + 45, Center.x + 10, Center.y + 55));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 10, Center.y + 55, Center.x - 10, Center.y + 65));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 10, Center.y + 65, Center.x, Center.y + 70));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, Center.y + 70, Center.x, rectBaseLP.bottom + 1));
      break;
    case -90:
      pointDrawCenter.x = Center.x - 40;
      pointDrawCenter.y = Center.y;

      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, pointDrawCenter.y, pointDrawCenter.x - 30, pointDrawCenter.y));

      retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x - 30, pointDrawCenter.y, pointDrawCenter.x - 25, pointDrawCenter.y - 10));
      retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x - 25, pointDrawCenter.y - 10, pointDrawCenter.x - 15, pointDrawCenter.y + 10));
      retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x - 15, pointDrawCenter.y + 10, pointDrawCenter.x - 5, pointDrawCenter.y - 10));
      retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x - 5, pointDrawCenter.y - 10, pointDrawCenter.x + 5, pointDrawCenter.y + 10));
      retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x + 5, pointDrawCenter.y + 10, pointDrawCenter.x + 15, pointDrawCenter.y - 10));
      retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x + 15, pointDrawCenter.y - 10, pointDrawCenter.x + 25, pointDrawCenter.y + 10));
      retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x + 25, pointDrawCenter.y + 10, pointDrawCenter.x + 30, pointDrawCenter.y));

      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 10, pointDrawCenter.y, Center.x + 10, pointDrawCenter.y));

      pointDrawCenter.x = Center.x + 35;
      pointDrawCenter.y = Center.y;

      retArr = retArr.concat(ANGLE_ARC(CDXFFile1, pointDrawCenter.x - 15, pointDrawCenter.y, 10, 320, 220));
      retArr = retArr.concat(ANGLE_ARC(CDXFFile1, pointDrawCenter.x, pointDrawCenter.y, 10, 320, 260));
      retArr = retArr.concat(ANGLE_ARC(CDXFFile1, pointDrawCenter.x + 15, pointDrawCenter.y, 10, 0, 220));
      
      retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x + 25, pointDrawCenter.y, rectBaseLP.right + 1, pointDrawCenter.y));
      break;
    case 90:
      pointDrawCenter.x = Center.x - 40;
      pointDrawCenter.y = Center.y;

      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, pointDrawCenter.y, pointDrawCenter.x - 30, pointDrawCenter.y));

      retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x - 30, pointDrawCenter.y, pointDrawCenter.x - 25, pointDrawCenter.y - 10));
      retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x - 25, pointDrawCenter.y - 10, pointDrawCenter.x - 15, pointDrawCenter.y + 10));
      retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x - 15, pointDrawCenter.y + 10, pointDrawCenter.x - 5, pointDrawCenter.y - 10));
      retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x - 5, pointDrawCenter.y - 10, pointDrawCenter.x + 5, pointDrawCenter.y + 10));
      retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x + 5, pointDrawCenter.y + 10, pointDrawCenter.x + 15, pointDrawCenter.y - 10));
      retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x + 15, pointDrawCenter.y - 10, pointDrawCenter.x + 25, pointDrawCenter.y + 10));
      retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x + 25, pointDrawCenter.y + 10, pointDrawCenter.x + 30, pointDrawCenter.y));

      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 10, pointDrawCenter.y, Center.x + 10, pointDrawCenter.y));

      pointDrawCenter.x = Center.x + 35;
      pointDrawCenter.y = Center.y;

      retArr = retArr.concat(ANGLE_ARC(CDXFFile1, pointDrawCenter.x - 15, pointDrawCenter.y, 10, 320, 220));
      retArr = retArr.concat(ANGLE_ARC(CDXFFile1, pointDrawCenter.x, pointDrawCenter.y, 10, 320, 260));
      retArr = retArr.concat(ANGLE_ARC(CDXFFile1, pointDrawCenter.x + 15, pointDrawCenter.y, 10, 0, 220));
      
      retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x + 25, pointDrawCenter.y, rectBaseLP.right + 1, pointDrawCenter.y));
      break;
    }
  }
  else if (nOpeMode == MS_MODE_PERCENT_Z) {
    switch (pBusBar.rotation) {
    case 0:
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.top - 1, Center.x, Center.y - 30));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, Center.y - 30, Center.x + 10, Center.y - 25));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 10, Center.y - 25, Center.x - 10, Center.y - 15));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 10, Center.y - 15, Center.x + 10, Center.y - 5));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 10, Center.y - 5, Center.x - 10, Center.y + 5));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 10, Center.y + 5, Center.x + 10, Center.y + 15));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 10, Center.y + 15, Center.x - 10, Center.y + 25));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 10, Center.y + 25, Center.x, Center.y + 30));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, Center.y + 30, Center.x, rectBaseLP.bottom + 1));
      break;
    case -90:
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, Center.y, Center.x - 30, Center.y));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 30, Center.y, Center.x - 25, Center.y - 10));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 25, Center.y - 10, Center.x - 15, Center.y + 10));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 15, Center.y + 10, Center.x - 5, Center.y - 10));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 5, Center.y - 10, Center.x + 5, Center.y + 10));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 5, Center.y + 10, Center.x + 15, Center.y - 10));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 15, Center.y - 10, Center.x + 25, Center.y + 10));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 25, Center.y + 10, Center.x + 30, Center.y));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 30, Center.y, rectBaseLP.right + 1, Center.y));
      break;
    case 90:
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, Center.y, Center.x - 30, Center.y));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 30, Center.y, Center.x - 25, Center.y - 10));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 25, Center.y - 10, Center.x - 15, Center.y + 10));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 15, Center.y + 10, Center.x - 5, Center.y - 10));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 5, Center.y - 10, Center.x + 5, Center.y + 10));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 5, Center.y + 10, Center.x + 15, Center.y - 10));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 15, Center.y - 10, Center.x + 25, Center.y + 10));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 25, Center.y + 10, Center.x + 30, Center.y));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 30, Center.y, rectBaseLP.right + 1, Center.y));
      break;
    }
  }
  else {
    switch (pBusBar.rotation) {
    case 0:
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.top - 1, Center.x, rectBaseLP.bottom + 1));
      break;
    case -90:
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, Center.y, rectBaseLP.right + 1, Center.y));
      break;
    case 90:
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, Center.y, rectBaseLP.right + 1, Center.y));
      break;
    }
  }

  return retArr;
}

const doDxfOutSymbolImpedance = (CDXFFile1: any, pImpedance: any) => {
  let retArr: any[] = [];

  let rectBaseLP = getRectBaseLP(pImpedance);
  let rectCenter = {...rectBaseLP};
  let Center = getCenterPoint(rectCenter);

  let pointDrawCenter = {x: 0, y: 0};

  switch (pImpedance.rotation) {
  case 0:
    if (pImpedance.properties.inputPercentZ == IMPEDANCE_INPUT_PERCENT) {
      if (pImpedance.properties.percentZ != 0) {
        if (pImpedance.properties.xr == MS_XR_MAX) {
          pointDrawCenter.x = Center.x;
          pointDrawCenter.y = Center.y;

          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x, rectBaseLP.top - 1, pointDrawCenter.x, pointDrawCenter.y - 25));
          
          retArr = retArr.concat(ANGLE_ARC(CDXFFile1, Center.x, Center.y + 20, 10, 230, 220));
          retArr = retArr.concat(ANGLE_ARC(CDXFFile1, Center.x, Center.y + 35, 10, 230, 260));
          retArr = retArr.concat(ANGLE_ARC(CDXFFile1, Center.x, Center.y + 50, 10, 270, 220));

          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x, pointDrawCenter.y + 25, pointDrawCenter.x, rectBaseLP.bottom + 1));
        }
        else if (pImpedance.properties.xr == 0) {
          pointDrawCenter.x = Center.x;
          pointDrawCenter.y = Center.y;

          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x, rectBaseLP.top - 1, pointDrawCenter.x, pointDrawCenter.y - 30));

          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x, pointDrawCenter.y - 30, pointDrawCenter.x + 10, pointDrawCenter.y - 25));
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x + 10, pointDrawCenter.y - 25, pointDrawCenter.x - 10, pointDrawCenter.y - 15));
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x - 10, pointDrawCenter.y - 15, pointDrawCenter.x + 10, pointDrawCenter.y - 5));
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x + 10, pointDrawCenter.y - 5, pointDrawCenter.x - 10, pointDrawCenter.y + 5));
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x - 10, pointDrawCenter.y + 5, pointDrawCenter.x + 10, pointDrawCenter.y + 15));
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x + 10, pointDrawCenter.y + 15, pointDrawCenter.x - 10, pointDrawCenter.y + 25));
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x - 10, pointDrawCenter.y + 25, pointDrawCenter.x, pointDrawCenter.y + 30));

          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x, pointDrawCenter.y + 30, pointDrawCenter.x, rectBaseLP.bottom + 1));
        }
        else {
          pointDrawCenter.x = Center.x;
          pointDrawCenter.y = Center.y - 40;

          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x, rectBaseLP.top - 1, pointDrawCenter.x, pointDrawCenter.y - 30));

          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x, pointDrawCenter.y - 30, pointDrawCenter.x + 10, pointDrawCenter.y - 25));
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x + 10, pointDrawCenter.y - 25, pointDrawCenter.x - 10, pointDrawCenter.y - 15));
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x - 10, pointDrawCenter.y - 15, pointDrawCenter.x + 10, pointDrawCenter.y - 5));
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x + 10, pointDrawCenter.y - 5, pointDrawCenter.x - 10, pointDrawCenter.y + 5));
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x - 10, pointDrawCenter.y + 5, pointDrawCenter.x + 10, pointDrawCenter.y + 15));
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x + 10, pointDrawCenter.y + 15, pointDrawCenter.x - 10, pointDrawCenter.y + 25));
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x - 10, pointDrawCenter.y + 25, pointDrawCenter.x, pointDrawCenter.y + 30));

          retArr = retArr.concat(LINE(CDXFFile1, Center.x, Center.y - 10, Center.x, Center.y + 10));

          pointDrawCenter.x = Center.x;
          pointDrawCenter.y = Center.y + 35;

          retArr = retArr.concat(ANGLE_ARC(CDXFFile1, Center.x, Center.y + 20, 10, 230, 220));
          retArr = retArr.concat(ANGLE_ARC(CDXFFile1, Center.x, Center.y + 35, 10, 230, 260));
          retArr = retArr.concat(ANGLE_ARC(CDXFFile1, Center.x, Center.y + 50, 10, 270, 220));

          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x, pointDrawCenter.y + 25, pointDrawCenter.x, rectBaseLP.bottom + 1));
        }
      }
      else {
        retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.top - 1, Center.x, rectBaseLP.bottom + 1));
      }
    }
    else {
      if (pImpedance.properties.ohmR != 0 || pImpedance.properties.ohmX != 0) {
        if (pImpedance.properties.ohmR == 0) {
          pointDrawCenter.x = Center.x;
          pointDrawCenter.y = Center.y;

          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x, rectBaseLP.top - 1, pointDrawCenter.x, pointDrawCenter.y - 25));
          
          retArr = retArr.concat(ANGLE_ARC(CDXFFile1, Center.x, Center.y + 20, 10, 230, 220));
          retArr = retArr.concat(ANGLE_ARC(CDXFFile1, Center.x, Center.y + 35, 10, 230, 260));
          retArr = retArr.concat(ANGLE_ARC(CDXFFile1, Center.x, Center.y + 50, 10, 270, 220));

          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x, pointDrawCenter.y + 25, pointDrawCenter.x, rectBaseLP.bottom + 1));
        }
        else if (pImpedance.properties.ohmX == 0) {
          pointDrawCenter.x = Center.x;
          pointDrawCenter.y = Center.y;

          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x, rectBaseLP.top - 1, pointDrawCenter.x, pointDrawCenter.y - 30));

          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x, pointDrawCenter.y - 30, pointDrawCenter.x + 10, pointDrawCenter.y - 25));
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x + 10, pointDrawCenter.y - 25, pointDrawCenter.x - 10, pointDrawCenter.y - 15));
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x - 10, pointDrawCenter.y - 15, pointDrawCenter.x + 10, pointDrawCenter.y - 5));
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x + 10, pointDrawCenter.y - 5, pointDrawCenter.x - 10, pointDrawCenter.y + 5));
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x - 10, pointDrawCenter.y + 5, pointDrawCenter.x + 10, pointDrawCenter.y + 15));
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x + 10, pointDrawCenter.y + 15, pointDrawCenter.x - 10, pointDrawCenter.y + 25));
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x - 10, pointDrawCenter.y + 25, pointDrawCenter.x, pointDrawCenter.y + 30));

          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x, pointDrawCenter.y + 30, pointDrawCenter.x, rectBaseLP.bottom + 1));
        }
        else {
          pointDrawCenter.x = Center.x;
          pointDrawCenter.y = Center.y - 40;

          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x, rectBaseLP.top - 1, pointDrawCenter.x, pointDrawCenter.y - 30));

          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x, pointDrawCenter.y - 30, pointDrawCenter.x + 10, pointDrawCenter.y - 25));
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x + 10, pointDrawCenter.y - 25, pointDrawCenter.x - 10, pointDrawCenter.y - 15));
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x - 10, pointDrawCenter.y - 15, pointDrawCenter.x + 10, pointDrawCenter.y - 5));
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x + 10, pointDrawCenter.y - 5, pointDrawCenter.x - 10, pointDrawCenter.y + 5));
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x - 10, pointDrawCenter.y + 5, pointDrawCenter.x + 10, pointDrawCenter.y + 15));
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x + 10, pointDrawCenter.y + 15, pointDrawCenter.x - 10, pointDrawCenter.y + 25));
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x - 10, pointDrawCenter.y + 25, pointDrawCenter.x, pointDrawCenter.y + 30));

          retArr = retArr.concat(LINE(CDXFFile1, Center.x, Center.y - 10, Center.x, Center.y + 10));

          pointDrawCenter.x = Center.x;
          pointDrawCenter.y = Center.y + 35;

          retArr = retArr.concat(ANGLE_ARC(CDXFFile1, Center.x, Center.y + 20, 10, 230, 220));
          retArr = retArr.concat(ANGLE_ARC(CDXFFile1, Center.x, Center.y + 35, 10, 230, 260));
          retArr = retArr.concat(ANGLE_ARC(CDXFFile1, Center.x, Center.y + 50, 10, 270, 220));

          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x, pointDrawCenter.y + 25, pointDrawCenter.x, rectBaseLP.bottom + 1));
        }
      }
      else {
        retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.top - 1, Center.x, rectBaseLP.bottom + 1));
      }
    }
    break;
  case -90:
    if (pImpedance.properties.inputPercentZ == IMPEDANCE_INPUT_PERCENT) {
      if (pImpedance.properties.percentZ != 0) {
        if (pImpedance.properties.xr == MS_XR_MAX) {
          pointDrawCenter.x = Center.x;
          pointDrawCenter.y = Center.y;

          retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, pointDrawCenter.y, pointDrawCenter.x - 25, pointDrawCenter.y));

          retArr = retArr.concat(ANGLE_ARC(CDXFFile1, pointDrawCenter.x - 15, pointDrawCenter.y, 10, 320, 220));
          retArr = retArr.concat(ANGLE_ARC(CDXFFile1, pointDrawCenter.x, pointDrawCenter.y, 10, 320, 260));
          retArr = retArr.concat(ANGLE_ARC(CDXFFile1, pointDrawCenter.x + 15, pointDrawCenter.y, 10, 0, 220));
          
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x + 25, pointDrawCenter.y, rectBaseLP.right + 1, pointDrawCenter.y));
        }
        else if (pImpedance.properties.xr == 0) {
          pointDrawCenter.x = Center.x;
          pointDrawCenter.y = Center.y;

          retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, pointDrawCenter.y, pointDrawCenter.x - 30, pointDrawCenter.y));

          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x - 30, pointDrawCenter.y, pointDrawCenter.x - 25, pointDrawCenter.y - 10));
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x - 25, pointDrawCenter.y - 10, pointDrawCenter.x - 15, pointDrawCenter.y + 10));
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x - 15, pointDrawCenter.y + 10, pointDrawCenter.x - 5, pointDrawCenter.y - 10));
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x - 5, pointDrawCenter.y - 10, pointDrawCenter.x + 5, pointDrawCenter.y + 10));
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x + 5, pointDrawCenter.y + 10, pointDrawCenter.x + 15, pointDrawCenter.y - 10));
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x + 15, pointDrawCenter.y - 10, pointDrawCenter.x + 25, pointDrawCenter.y + 10));
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x + 25, pointDrawCenter.y + 10, pointDrawCenter.x + 30, pointDrawCenter.y));
          
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x + 30, pointDrawCenter.y, rectBaseLP.right + 1, pointDrawCenter.y));
        }
        else {
          pointDrawCenter.x = Center.x - 40;
          pointDrawCenter.y = Center.y;

          retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, pointDrawCenter.y, pointDrawCenter.x - 30, pointDrawCenter.y));

          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x - 30, pointDrawCenter.y, pointDrawCenter.x - 25, pointDrawCenter.y - 10));
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x - 25, pointDrawCenter.y - 10, pointDrawCenter.x - 15, pointDrawCenter.y + 10));
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x - 15, pointDrawCenter.y + 10, pointDrawCenter.x - 5, pointDrawCenter.y - 10));
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x - 5, pointDrawCenter.y - 10, pointDrawCenter.x + 5, pointDrawCenter.y + 10));
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x + 5, pointDrawCenter.y + 10, pointDrawCenter.x + 15, pointDrawCenter.y - 10));
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x + 15, pointDrawCenter.y - 10, pointDrawCenter.x + 25, pointDrawCenter.y + 10));
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x + 25, pointDrawCenter.y + 10, pointDrawCenter.x + 30, pointDrawCenter.y));

          retArr = retArr.concat(LINE(CDXFFile1, Center.x - 10, pointDrawCenter.y, Center.x + 10, pointDrawCenter.y));

          pointDrawCenter.x = Center.x + 35;
          pointDrawCenter.y = Center.y;

          retArr = retArr.concat(ANGLE_ARC(CDXFFile1, pointDrawCenter.x - 15, pointDrawCenter.y, 10, 320, 220));
          retArr = retArr.concat(ANGLE_ARC(CDXFFile1, pointDrawCenter.x, pointDrawCenter.y, 10, 320, 260));
          retArr = retArr.concat(ANGLE_ARC(CDXFFile1, pointDrawCenter.x + 15, pointDrawCenter.y, 10, 0, 220));
          
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x + 25, pointDrawCenter.y, rectBaseLP.right + 1, pointDrawCenter.y));
        }
      }
      else {
        retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, Center.y, rectBaseLP.right + 1, Center.y));
      }
    }
    else {
      if (pImpedance.properties.ohmR != 0 || pImpedance.properties.ohmX != 0) {
        if (pImpedance.properties.ohmR == 0) {
          pointDrawCenter.x = Center.x;
          pointDrawCenter.y = Center.y;

          retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, pointDrawCenter.y, pointDrawCenter.x - 25, pointDrawCenter.y));

          retArr = retArr.concat(ANGLE_ARC(CDXFFile1, pointDrawCenter.x - 15, pointDrawCenter.y, 10, 320, 220));
          retArr = retArr.concat(ANGLE_ARC(CDXFFile1, pointDrawCenter.x, pointDrawCenter.y, 10, 320, 260));
          retArr = retArr.concat(ANGLE_ARC(CDXFFile1, pointDrawCenter.x + 15, pointDrawCenter.y, 10, 0, 220));
          
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x + 25, pointDrawCenter.y, rectBaseLP.right + 1, pointDrawCenter.y));
        }
        else if (pImpedance.properties.ohmX == 0) {
          pointDrawCenter.x = Center.x;
          pointDrawCenter.y = Center.y;

          retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, pointDrawCenter.y, pointDrawCenter.x - 30, pointDrawCenter.y));

          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x - 30, pointDrawCenter.y, pointDrawCenter.x - 25, pointDrawCenter.y - 10));
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x - 25, pointDrawCenter.y - 10, pointDrawCenter.x - 15, pointDrawCenter.y + 10));
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x - 15, pointDrawCenter.y + 10, pointDrawCenter.x - 5, pointDrawCenter.y - 10));
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x - 5, pointDrawCenter.y - 10, pointDrawCenter.x + 5, pointDrawCenter.y + 10));
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x + 5, pointDrawCenter.y + 10, pointDrawCenter.x + 15, pointDrawCenter.y - 10));
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x + 15, pointDrawCenter.y - 10, pointDrawCenter.x + 25, pointDrawCenter.y + 10));
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x + 25, pointDrawCenter.y + 10, pointDrawCenter.x + 30, pointDrawCenter.y));
          
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x + 30, pointDrawCenter.y, rectBaseLP.right + 1, pointDrawCenter.y));
        }
        else {
          pointDrawCenter.x = Center.x - 40;
          pointDrawCenter.y = Center.y;

          retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, pointDrawCenter.y, pointDrawCenter.x - 30, pointDrawCenter.y));

          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x - 30, pointDrawCenter.y, pointDrawCenter.x - 25, pointDrawCenter.y - 10));
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x - 25, pointDrawCenter.y - 10, pointDrawCenter.x - 15, pointDrawCenter.y + 10));
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x - 15, pointDrawCenter.y + 10, pointDrawCenter.x - 5, pointDrawCenter.y - 10));
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x - 5, pointDrawCenter.y - 10, pointDrawCenter.x + 5, pointDrawCenter.y + 10));
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x + 5, pointDrawCenter.y + 10, pointDrawCenter.x + 15, pointDrawCenter.y - 10));
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x + 15, pointDrawCenter.y - 10, pointDrawCenter.x + 25, pointDrawCenter.y + 10));
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x + 25, pointDrawCenter.y + 10, pointDrawCenter.x + 30, pointDrawCenter.y));

          retArr = retArr.concat(LINE(CDXFFile1, Center.x - 10, pointDrawCenter.y, Center.x + 10, pointDrawCenter.y));

          pointDrawCenter.x = Center.x + 35;
          pointDrawCenter.y = Center.y;

          retArr = retArr.concat(ANGLE_ARC(CDXFFile1, pointDrawCenter.x - 15, pointDrawCenter.y, 10, 320, 220));
          retArr = retArr.concat(ANGLE_ARC(CDXFFile1, pointDrawCenter.x, pointDrawCenter.y, 10, 320, 260));
          retArr = retArr.concat(ANGLE_ARC(CDXFFile1, pointDrawCenter.x + 15, pointDrawCenter.y, 10, 0, 220));
          
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x + 25, pointDrawCenter.y, rectBaseLP.right + 1, pointDrawCenter.y));
        }
      }
      else {
        retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, Center.y, rectBaseLP.right + 1, Center.y));
      }
    }
    break;
  case 90:
    if (pImpedance.properties.inputPercentZ == IMPEDANCE_INPUT_PERCENT) {
      if (pImpedance.properties.percentZ != 0) {
        if (pImpedance.properties.xr == MS_XR_MAX) {
          pointDrawCenter.x = Center.x;
          pointDrawCenter.y = Center.y;

          retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, pointDrawCenter.y, pointDrawCenter.x - 25, pointDrawCenter.y));

          retArr = retArr.concat(ANGLE_ARC(CDXFFile1, pointDrawCenter.x - 15, pointDrawCenter.y, 10, 320, 220));
          retArr = retArr.concat(ANGLE_ARC(CDXFFile1, pointDrawCenter.x, pointDrawCenter.y, 10, 320, 260));
          retArr = retArr.concat(ANGLE_ARC(CDXFFile1, pointDrawCenter.x + 15, pointDrawCenter.y, 10, 0, 220));
          
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x + 25, pointDrawCenter.y, rectBaseLP.right + 1, pointDrawCenter.y));
        }
        else if (pImpedance.properties.xr == 0) {
          pointDrawCenter.x = Center.x;
          pointDrawCenter.y = Center.y;

          retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, pointDrawCenter.y, pointDrawCenter.x - 30, pointDrawCenter.y));

          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x - 30, pointDrawCenter.y, pointDrawCenter.x - 25, pointDrawCenter.y - 10));
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x - 25, pointDrawCenter.y - 10, pointDrawCenter.x - 15, pointDrawCenter.y + 10));
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x - 15, pointDrawCenter.y + 10, pointDrawCenter.x - 5, pointDrawCenter.y - 10));
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x - 5, pointDrawCenter.y - 10, pointDrawCenter.x + 5, pointDrawCenter.y + 10));
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x + 5, pointDrawCenter.y + 10, pointDrawCenter.x + 15, pointDrawCenter.y - 10));
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x + 15, pointDrawCenter.y - 10, pointDrawCenter.x + 25, pointDrawCenter.y + 10));
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x + 25, pointDrawCenter.y + 10, pointDrawCenter.x + 30, pointDrawCenter.y));
          
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x + 30, pointDrawCenter.y, rectBaseLP.right + 1, pointDrawCenter.y));
        }
        else {
          pointDrawCenter.x = Center.x - 40;
          pointDrawCenter.y = Center.y;

          retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, pointDrawCenter.y, pointDrawCenter.x - 30, pointDrawCenter.y));

          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x - 30, pointDrawCenter.y, pointDrawCenter.x - 25, pointDrawCenter.y - 10));
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x - 25, pointDrawCenter.y - 10, pointDrawCenter.x - 15, pointDrawCenter.y + 10));
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x - 15, pointDrawCenter.y + 10, pointDrawCenter.x - 5, pointDrawCenter.y - 10));
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x - 5, pointDrawCenter.y - 10, pointDrawCenter.x + 5, pointDrawCenter.y + 10));
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x + 5, pointDrawCenter.y + 10, pointDrawCenter.x + 15, pointDrawCenter.y - 10));
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x + 15, pointDrawCenter.y - 10, pointDrawCenter.x + 25, pointDrawCenter.y + 10));
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x + 25, pointDrawCenter.y + 10, pointDrawCenter.x + 30, pointDrawCenter.y));

          retArr = retArr.concat(LINE(CDXFFile1, Center.x - 10, pointDrawCenter.y, Center.x + 10, pointDrawCenter.y));

          pointDrawCenter.x = Center.x + 35;
          pointDrawCenter.y = Center.y;

          retArr = retArr.concat(ANGLE_ARC(CDXFFile1, pointDrawCenter.x - 15, pointDrawCenter.y, 10, 320, 220));
          retArr = retArr.concat(ANGLE_ARC(CDXFFile1, pointDrawCenter.x, pointDrawCenter.y, 10, 320, 260));
          retArr = retArr.concat(ANGLE_ARC(CDXFFile1, pointDrawCenter.x + 15, pointDrawCenter.y, 10, 0, 220));
          
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x + 25, pointDrawCenter.y, rectBaseLP.right + 1, pointDrawCenter.y));
        }
      }
      else {
        retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, Center.y, rectBaseLP.right + 1, Center.y));
      }
    }
    else {
      if (pImpedance.properties.ohmR != 0 || pImpedance.properties.ohmX != 0) {
        if (pImpedance.properties.ohmR == 0) {
          pointDrawCenter.x = Center.x;
          pointDrawCenter.y = Center.y;

          retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, pointDrawCenter.y, pointDrawCenter.x - 25, pointDrawCenter.y));

          retArr = retArr.concat(ANGLE_ARC(CDXFFile1, pointDrawCenter.x - 15, pointDrawCenter.y, 10, 320, 220));
          retArr = retArr.concat(ANGLE_ARC(CDXFFile1, pointDrawCenter.x, pointDrawCenter.y, 10, 320, 260));
          retArr = retArr.concat(ANGLE_ARC(CDXFFile1, pointDrawCenter.x + 15, pointDrawCenter.y, 10, 0, 220));
          
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x + 25, pointDrawCenter.y, rectBaseLP.right + 1, pointDrawCenter.y));
        }
        else if (pImpedance.properties.ohmX == 0) {
          pointDrawCenter.x = Center.x;
          pointDrawCenter.y = Center.y;

          retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, pointDrawCenter.y, pointDrawCenter.x - 30, pointDrawCenter.y));

          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x - 30, pointDrawCenter.y, pointDrawCenter.x - 25, pointDrawCenter.y - 10));
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x - 25, pointDrawCenter.y - 10, pointDrawCenter.x - 15, pointDrawCenter.y + 10));
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x - 15, pointDrawCenter.y + 10, pointDrawCenter.x - 5, pointDrawCenter.y - 10));
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x - 5, pointDrawCenter.y - 10, pointDrawCenter.x + 5, pointDrawCenter.y + 10));
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x + 5, pointDrawCenter.y + 10, pointDrawCenter.x + 15, pointDrawCenter.y - 10));
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x + 15, pointDrawCenter.y - 10, pointDrawCenter.x + 25, pointDrawCenter.y + 10));
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x + 25, pointDrawCenter.y + 10, pointDrawCenter.x + 30, pointDrawCenter.y));
          
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x + 30, pointDrawCenter.y, rectBaseLP.right + 1, pointDrawCenter.y));
        }
        else {
          pointDrawCenter.x = Center.x - 40;
          pointDrawCenter.y = Center.y;

          retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, pointDrawCenter.y, pointDrawCenter.x - 30, pointDrawCenter.y));

          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x - 30, pointDrawCenter.y, pointDrawCenter.x - 25, pointDrawCenter.y - 10));
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x - 25, pointDrawCenter.y - 10, pointDrawCenter.x - 15, pointDrawCenter.y + 10));
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x - 15, pointDrawCenter.y + 10, pointDrawCenter.x - 5, pointDrawCenter.y - 10));
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x - 5, pointDrawCenter.y - 10, pointDrawCenter.x + 5, pointDrawCenter.y + 10));
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x + 5, pointDrawCenter.y + 10, pointDrawCenter.x + 15, pointDrawCenter.y - 10));
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x + 15, pointDrawCenter.y - 10, pointDrawCenter.x + 25, pointDrawCenter.y + 10));
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x + 25, pointDrawCenter.y + 10, pointDrawCenter.x + 30, pointDrawCenter.y));

          retArr = retArr.concat(LINE(CDXFFile1, Center.x - 10, pointDrawCenter.y, Center.x + 10, pointDrawCenter.y));

          pointDrawCenter.x = Center.x + 35;
          pointDrawCenter.y = Center.y;

          retArr = retArr.concat(ANGLE_ARC(CDXFFile1, pointDrawCenter.x - 15, pointDrawCenter.y, 10, 320, 220));
          retArr = retArr.concat(ANGLE_ARC(CDXFFile1, pointDrawCenter.x, pointDrawCenter.y, 10, 320, 260));
          retArr = retArr.concat(ANGLE_ARC(CDXFFile1, pointDrawCenter.x + 15, pointDrawCenter.y, 10, 0, 220));
          
          retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x + 25, pointDrawCenter.y, rectBaseLP.right + 1, pointDrawCenter.y));
        }
      }
      else {
        retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, Center.y, rectBaseLP.right + 1, Center.y));
      }
    }
    break;
  }

  return retArr;
}

const doDxfOutSymbolBusDuct = (CDXFFile1: any, pBusDuct: any, nOpeMode: number) => {
  let retArr: any[] = [];
  let rectBaseLP = getRectBaseLP(pBusDuct);
  let rectCenter = {...rectBaseLP};
  let Center = getCenterPoint(rectCenter);

  if (nOpeMode == MS_MODE_POWER_FLOW) {
    let pointDrawCenter = {x: 0, y: 0};

    switch (pBusDuct.rotation) {
    case 0:
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.top - 1, Center.x, Center.y - 60));

      retArr = retArr.concat(ANGLE_ARC(CDXFFile1, Center.x, Center.y - 50, 10, 230, 220));
      retArr = retArr.concat(ANGLE_ARC(CDXFFile1, Center.x, Center.y - 35, 10, 230, 260));
      retArr = retArr.concat(ANGLE_ARC(CDXFFile1, Center.x, Center.y - 20, 10, 270, 220));

      retArr = retArr.concat(LINE(CDXFFile1, Center.x, Center.y - 10, Center.x, Center.y + 10));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, Center.y + 10, Center.x + 10, Center.y + 15));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 10, Center.y + 15, Center.x - 10, Center.y + 25));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 10, Center.y + 25, Center.x + 10, Center.y + 35));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 10, Center.y + 35, Center.x - 10, Center.y + 45));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 10, Center.y + 45, Center.x + 10, Center.y + 55));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 10, Center.y + 55, Center.x - 10, Center.y + 65));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 10, Center.y + 65, Center.x, Center.y + 70));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, Center.y + 70, Center.x, rectBaseLP.bottom + 1));
      break;
    case -90:
      pointDrawCenter.x = Center.x - 40;
      pointDrawCenter.y = Center.y;

      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, pointDrawCenter.y, pointDrawCenter.x - 30, pointDrawCenter.y));

      retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x - 30, pointDrawCenter.y, pointDrawCenter.x - 25, pointDrawCenter.y - 10));
      retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x - 25, pointDrawCenter.y - 10, pointDrawCenter.x - 15, pointDrawCenter.y + 10));
      retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x - 15, pointDrawCenter.y + 10, pointDrawCenter.x - 5, pointDrawCenter.y - 10));
      retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x - 5, pointDrawCenter.y - 10, pointDrawCenter.x + 5, pointDrawCenter.y + 10));
      retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x + 5, pointDrawCenter.y + 10, pointDrawCenter.x + 15, pointDrawCenter.y - 10));
      retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x + 15, pointDrawCenter.y - 10, pointDrawCenter.x + 25, pointDrawCenter.y + 10));
      retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x + 25, pointDrawCenter.y + 10, pointDrawCenter.x + 30, pointDrawCenter.y));

      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 10, pointDrawCenter.y, Center.x + 10, pointDrawCenter.y));

      pointDrawCenter.x = Center.x + 35;
      pointDrawCenter.y = Center.y;

      retArr = retArr.concat(ANGLE_ARC(CDXFFile1, pointDrawCenter.x - 15, pointDrawCenter.y, 10, 320, 220));
      retArr = retArr.concat(ANGLE_ARC(CDXFFile1, pointDrawCenter.x, pointDrawCenter.y, 10, 320, 260));
      retArr = retArr.concat(ANGLE_ARC(CDXFFile1, pointDrawCenter.x + 15, pointDrawCenter.y, 10, 0, 220));
      
      retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x + 25, pointDrawCenter.y, rectBaseLP.right + 1, pointDrawCenter.y));
      break;
    case 90:
      pointDrawCenter.x = Center.x - 40;
      pointDrawCenter.y = Center.y;

      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, pointDrawCenter.y, pointDrawCenter.x - 30, pointDrawCenter.y));

      retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x - 30, pointDrawCenter.y, pointDrawCenter.x - 25, pointDrawCenter.y - 10));
      retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x - 25, pointDrawCenter.y - 10, pointDrawCenter.x - 15, pointDrawCenter.y + 10));
      retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x - 15, pointDrawCenter.y + 10, pointDrawCenter.x - 5, pointDrawCenter.y - 10));
      retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x - 5, pointDrawCenter.y - 10, pointDrawCenter.x + 5, pointDrawCenter.y + 10));
      retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x + 5, pointDrawCenter.y + 10, pointDrawCenter.x + 15, pointDrawCenter.y - 10));
      retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x + 15, pointDrawCenter.y - 10, pointDrawCenter.x + 25, pointDrawCenter.y + 10));
      retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x + 25, pointDrawCenter.y + 10, pointDrawCenter.x + 30, pointDrawCenter.y));

      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 10, pointDrawCenter.y, Center.x + 10, pointDrawCenter.y));

      pointDrawCenter.x = Center.x + 35;
      pointDrawCenter.y = Center.y;

      retArr = retArr.concat(ANGLE_ARC(CDXFFile1, pointDrawCenter.x - 15, pointDrawCenter.y, 10, 320, 220));
      retArr = retArr.concat(ANGLE_ARC(CDXFFile1, pointDrawCenter.x, pointDrawCenter.y, 10, 320, 260));
      retArr = retArr.concat(ANGLE_ARC(CDXFFile1, pointDrawCenter.x + 15, pointDrawCenter.y, 10, 0, 220));
      
      retArr = retArr.concat(LINE(CDXFFile1, pointDrawCenter.x + 25, pointDrawCenter.y, rectBaseLP.right + 1, pointDrawCenter.y));
      break;
    }
  }
  else if (nOpeMode == MS_MODE_PERCENT_Z) {
    switch (pBusDuct.rotation) {
    case 0:
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.top - 1, Center.x, Center.y - 30));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, Center.y - 30, Center.x + 10, Center.y - 25));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 10, Center.y - 25, Center.x - 10, Center.y - 15));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 10, Center.y - 15, Center.x + 10, Center.y - 5));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 10, Center.y - 5, Center.x - 10, Center.y + 5));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 10, Center.y + 5, Center.x + 10, Center.y + 15));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 10, Center.y + 15, Center.x - 10, Center.y + 25));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 10, Center.y + 25, Center.x, Center.y + 30));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, Center.y + 30, Center.x, rectBaseLP.bottom + 1));
      break;
    case -90:
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, Center.y, Center.x - 30, Center.y));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 30, Center.y, Center.x - 25, Center.y - 10));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 25, Center.y - 10, Center.x - 15, Center.y + 10));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 15, Center.y + 10, Center.x - 5, Center.y - 10));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 5, Center.y - 10, Center.x + 5, Center.y + 10));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 5, Center.y + 10, Center.x + 15, Center.y - 10));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 15, Center.y - 10, Center.x + 25, Center.y + 10));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 25, Center.y + 10, Center.x + 30, Center.y));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 30, Center.y, rectBaseLP.right + 1, Center.y));
      break;
    case 90:
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, Center.y, Center.x - 30, Center.y));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 30, Center.y, Center.x - 25, Center.y - 10));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 25, Center.y - 10, Center.x - 15, Center.y + 10));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 15, Center.y + 10, Center.x - 5, Center.y - 10));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 5, Center.y - 10, Center.x + 5, Center.y + 10));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 5, Center.y + 10, Center.x + 15, Center.y - 10));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 15, Center.y - 10, Center.x + 25, Center.y + 10));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 25, Center.y + 10, Center.x + 30, Center.y));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 30, Center.y, rectBaseLP.right + 1, Center.y));
      break;
    }
  }
  else {
    switch (pBusDuct.rotation) {
    case 0:
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.top - 1, Center.x, rectBaseLP.bottom + 1));
      break;
    case -90:
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, Center.y, rectBaseLP.right + 1, Center.y));
      break;
    case 90:
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, Center.y, rectBaseLP.right + 1, Center.y));
      break;
    }
  }
  return retArr;
}

const doDxfOutSymbolReactor = (CDXFFile1: any, pReactor: any, nOpeMode: number) => {
  let retArr: any[] = [];
  let rectBaseLP = getRectBaseLP(pReactor);
  let rectCenter = {...rectBaseLP};
  let Center = getCenterPoint(rectCenter);

  if (nOpeMode != MS_MODE_PERCENT_Z) {
    let rectArc = {top: 0, left: 0, right: 0, bottom: 0};
    let pointStart = {x: 0, y: 0};
    let pointEnd = {x: 0, y: 0};

    switch (pReactor.rotation) {
    case 0:
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.top - 1, Center.x, Center.y - 31));

      rectArc.top = Center.y - 32;
      rectArc.bottom = Center.y - 17;
      rectArc.left = Center.x - 20;
      rectArc.right = Center.x + 10;
      pointStart.x = Center.x;
      pointStart.y = Center.y - 18;
      pointEnd.x = Center.x;
      pointEnd.y = Center.y - 32;
      retArr = retArr.concat(ARC_Right(CDXFFile1, rectArc, pointStart, pointEnd));

      rectArc.top = Center.y - 20;
      rectArc.bottom = Center.y - 5;
      rectArc.left = Center.x - 20;
      rectArc.right = Center.x + 10;
      pointStart.x = Center.x;
      pointStart.y = Center.y - 6;
      pointEnd.x = Center.x;
      pointEnd.y = Center.y - 20;
      retArr = retArr.concat(ARC_Right(CDXFFile1, rectArc, pointStart, pointEnd));

      rectArc.top = Center.y - 8;
      rectArc.bottom = Center.y + 7;
      rectArc.left = Center.x - 20;
      rectArc.right = Center.x + 10;
      pointStart.x = Center.x;
      pointStart.y = Center.y + 6;
      pointEnd.x = Center.x;
      pointEnd.y = Center.y - 8;
      retArr = retArr.concat(ARC_Right(CDXFFile1, rectArc, pointStart, pointEnd));

      rectArc.top = Center.y + 4;
      rectArc.bottom = Center.y + 19;
      rectArc.left = Center.x - 20;
      rectArc.right = Center.x + 10;
      pointStart.x = Center.x;
      pointStart.y = Center.y + 18;
      pointEnd.x = Center.x;
      pointEnd.y = Center.y + 4;
      retArr = retArr.concat(ARC_Right(CDXFFile1, rectArc, pointStart, pointEnd));

      retArr = retArr.concat(LINE(CDXFFile1, Center.x, Center.y + 18, Center.x, rectBaseLP.bottom + 1));
      break;
    case -90:
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, Center.y, Center.x - 32, Center.y));

      rectArc.top = Center.y + 21;
      rectArc.bottom = Center.y - 9;
      rectArc.left = Center.x - 32;
      rectArc.right = Center.x - 17;
      pointStart.x = Center.x - 18;
      pointStart.y = Center.y;
      pointEnd.x = Center.x - 32;
      pointEnd.y = Center.y;
      retArr = retArr.concat(ARC_Top(CDXFFile1, rectArc, pointStart, pointEnd));

      rectArc.top = Center.y + 21;
      rectArc.bottom = Center.y - 9;
      rectArc.left = Center.x - 18;
      rectArc.right = Center.x - 3;
      pointStart.x = Center.x - 6;
      pointStart.y = Center.y;
      pointEnd.x = Center.x - 20;
      pointEnd.y = Center.y;
      retArr = retArr.concat(ARC_Top(CDXFFile1, rectArc, pointStart, pointEnd));

      rectArc.top = Center.y + 21;
      rectArc.bottom = Center.y - 9;
      rectArc.left = Center.x - 4;
      rectArc.right = Center.x + 10;
      pointStart.x = Center.x + 6;
      pointStart.y = Center.y;
      pointEnd.x = Center.x - 8;
      pointEnd.y = Center.y;
      retArr = retArr.concat(ARC_Top(CDXFFile1, rectArc, pointStart, pointEnd));

      rectArc.top = Center.y + 21;
      rectArc.bottom = Center.y - 9;
      rectArc.left = Center.x + 10;
      rectArc.right = Center.x + 25;
      pointStart.x = Center.x + 18;
      pointStart.y = Center.y;
      pointEnd.x = Center.x + 4;
      pointEnd.y = Center.y;
      retArr = retArr.concat(ARC_Top(CDXFFile1, rectArc, pointStart, pointEnd));

      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 24, Center.y, rectBaseLP.right + 1, Center.y));
      break;
    case 90:
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, Center.y, Center.x - 32, Center.y));

      rectArc.top = Center.y + 21;
      rectArc.bottom = Center.y - 9;
      rectArc.left = Center.x - 32;
      rectArc.right = Center.x - 17;
      pointStart.x = Center.x - 18;
      pointStart.y = Center.y;
      pointEnd.x = Center.x - 32;
      pointEnd.y = Center.y;
      retArr = retArr.concat(ARC_Bottom(CDXFFile1, rectArc, pointStart, pointEnd));

      rectArc.top = Center.y + 21;
      rectArc.bottom = Center.y - 9;
      rectArc.left = Center.x - 18;
      rectArc.right = Center.x - 3;
      pointStart.x = Center.x - 6;
      pointStart.y = Center.y;
      pointEnd.x = Center.x - 20;
      pointEnd.y = Center.y;
      retArr = retArr.concat(ARC_Bottom(CDXFFile1, rectArc, pointStart, pointEnd));

      rectArc.top = Center.y + 21;
      rectArc.bottom = Center.y - 9;
      rectArc.left = Center.x - 4;
      rectArc.right = Center.x + 10;
      pointStart.x = Center.x + 6;
      pointStart.y = Center.y;
      pointEnd.x = Center.x - 8;
      pointEnd.y = Center.y;
      retArr = retArr.concat(ARC_Bottom(CDXFFile1, rectArc, pointStart, pointEnd));

      rectArc.top = Center.y + 21;
      rectArc.bottom = Center.y - 9;
      rectArc.left = Center.x + 10;
      rectArc.right = Center.x + 25;
      pointStart.x = Center.x + 18;
      pointStart.y = Center.y;
      pointEnd.x = Center.x + 4;
      pointEnd.y = Center.y;
      retArr = retArr.concat(ARC_Bottom(CDXFFile1, rectArc, pointStart, pointEnd));

      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 24, Center.y, rectBaseLP.right + 1, Center.y));
      break;
    }
  } else {
    switch (pReactor.rotation) {
    case 0:
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.top - 1, Center.x, Center.y - 30));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, Center.y - 30, Center.x + 10, Center.y - 25));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 10, Center.y - 25, Center.x - 10, Center.y - 15));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 10, Center.y - 15, Center.x + 10, Center.y - 5));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 10, Center.y - 5, Center.x - 10, Center.y + 5));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 10, Center.y + 5, Center.x + 10, Center.y + 15));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 10, Center.y + 15, Center.x - 10, Center.y + 25));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 10, Center.y + 25, Center.x, Center.y + 30));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, Center.y + 30, Center.x, rectBaseLP.bottom + 1));
      break;
    case -90:
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, Center.y, Center.x - 30, Center.y));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 30, Center.y, Center.x - 25, Center.y - 10));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 25, Center.y - 10, Center.x - 15, Center.y + 10));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 15, Center.y + 10, Center.x - 5, Center.y - 10));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 5, Center.y - 10, Center.x + 5, Center.y + 10));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 5, Center.y + 10, Center.x + 15, Center.y - 10));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 15, Center.y - 10, Center.x + 25, Center.y + 10));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 25, Center.y + 10, Center.x + 30, Center.y));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 30, Center.y, rectBaseLP.right + 1, Center.y));
      break;
    case 90:
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, Center.y, Center.x - 30, Center.y));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 30, Center.y, Center.x - 25, Center.y - 10));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 25, Center.y - 10, Center.x - 15, Center.y + 10));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 15, Center.y + 10, Center.x - 5, Center.y - 10));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 5, Center.y - 10, Center.x + 5, Center.y + 10));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 5, Center.y + 10, Center.x + 15, Center.y - 10));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 15, Center.y - 10, Center.x + 25, Center.y + 10));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 25, Center.y + 10, Center.x + 30, Center.y));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 30, Center.y, rectBaseLP.right + 1, Center.y));
      break;
    }
  }
  return retArr;
}

const doDxfOutSymbolLVCB = (CDXFFile1: any, pLVCB: any) => {
  let retArr: any[] = [];
  let rectBaseLP = getRectBaseLP(pLVCB);
  let rectCenter = {...rectBaseLP};
  let Center = getCenterPoint(rectCenter);

  let point = {...Center};
  let rect = {top: 0, left: 0, right: 0, bottom: 0};
  let yy = Math.floor(MS_SYMBOL_SIZE / 20);
  
  let size = {cx: 11, cy: 11};

  switch (pLVCB.properties.symKind) {
  case 0:
    switch (pLVCB.rotation) {
    case 0:
      point.y = Center.y - MS_BASE_HEIGHT + 13 * yy;

      retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.top - 1, Center.x, point.y));
      retArr = retArr.concat(LINE(CDXFFile1, point.x - MS_SYMBOL_SIZE / 6, point.y - MS_SYMBOL_SIZE / 6, point.x + MS_SYMBOL_SIZE / 6 + 1, point.y + MS_SYMBOL_SIZE / 6 + 1));
      retArr = retArr.concat(LINE(CDXFFile1, point.x - MS_SYMBOL_SIZE / 6, point.y + MS_SYMBOL_SIZE / 6, point.x + MS_SYMBOL_SIZE / 6 + 1, point.y - MS_SYMBOL_SIZE / 6 - 1));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 17 * (MS_SYMBOL_SIZE / 20), point.y, Center.x, Center.y + MS_BASE_HEIGHT - 22 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.bottom + 1, Center.x, Center.y +  MS_BASE_HEIGHT - 22 * yy - 1));
      break;
    case -90:
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, Center.y, Center.x - MS_BASE_WIDTH + MS_SYMBOL_SIZE / 2, Center.y));
      
      point.x = Center.x - MS_BASE_HEIGHT + MS_SYMBOL_SIZE / 2;

      retArr = retArr.concat(LINE(CDXFFile1, point.x - MS_SYMBOL_SIZE / 6, point.y - MS_SYMBOL_SIZE / 6, point.x + MS_SYMBOL_SIZE / 6 + 1, point.y + MS_SYMBOL_SIZE / 6 + 1));
      retArr = retArr.concat(LINE(CDXFFile1, point.x - MS_SYMBOL_SIZE / 6, point.y + MS_SYMBOL_SIZE / 6, point.x + MS_SYMBOL_SIZE / 6 + 1, point.y - MS_SYMBOL_SIZE / 6 - 1));
      
      retArr = retArr.concat(LINE(CDXFFile1, point.x, Center.y + 17 * (MS_SYMBOL_SIZE / 20), Center.x + MS_BASE_WIDTH - 22 * yy, Center.y));

      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.right + 1, Center.y, Center.x + MS_BASE_WIDTH - 22 * yy - 1, Center.y));
      break;
    case 90:
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.right + 1, Center.y, Center.x + MS_BASE_WIDTH - MS_SYMBOL_SIZE / 2, Center.y));
      
      point.x = Center.x + MS_BASE_HEIGHT - MS_SYMBOL_SIZE / 2;
      retArr = retArr.concat(LINE(CDXFFile1, point.x - MS_SYMBOL_SIZE / 6, point.y - MS_SYMBOL_SIZE / 6, point.x + MS_SYMBOL_SIZE / 6 + 1, point.y + MS_SYMBOL_SIZE / 6 + 1));
      retArr = retArr.concat(LINE(CDXFFile1, point.x - MS_SYMBOL_SIZE / 6, point.y + MS_SYMBOL_SIZE / 6, point.x + MS_SYMBOL_SIZE / 6 + 1, point.y - MS_SYMBOL_SIZE / 6 - 1));
      retArr = retArr.concat(LINE(CDXFFile1, point.x, Center.y - 17 * (MS_SYMBOL_SIZE / 20), Center.x - MS_BASE_WIDTH + 22 * yy, Center.y));
      
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, Center.y, Center.x - MS_BASE_WIDTH + 22 * yy + 1, Center.y));
      break;
    }
    break;
  case 2:
    switch (pLVCB.rotation) {
    case 0:
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.top - 1, Center.x, Center.y - 26 * yy));
      
      point.y = Center.y - MS_BASE_HEIGHT + MS_SYMBOL_SIZE / 2;

      retArr = retArr.concat(LINE(CDXFFile1, point.x - MS_SYMBOL_SIZE / 6, point.y - MS_SYMBOL_SIZE / 6, point.x + MS_SYMBOL_SIZE / 6 + 1, point.y + MS_SYMBOL_SIZE / 6 + 1));
      retArr = retArr.concat(LINE(CDXFFile1, point.x + MS_SYMBOL_SIZE / 6, point.y - MS_SYMBOL_SIZE / 6, point.x - MS_SYMBOL_SIZE / 6 - 1, point.y + MS_SYMBOL_SIZE / 6 + 1));
      
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - MS_SYMBOL_SIZE / 2, point.y, Center.x + 1, Center.y + 1));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 6 * yy, Center.y - 13 * yy, Center.x, Center.y - 13 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 3 * yy, Center.y - 13 * yy, Center.x + 9 * yy, Center.y - 13 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 13 * yy, Center.y - 13 * yy, rectBaseLP.right - 2, Center.y - 13 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.right - 2, Center.y - 13 * yy, rectBaseLP.right - 2, Center.y - 9 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.right - 2, Center.y - 6 * yy, rectBaseLP.right - 2, Center.y + 1 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.right - 2, Center.y + 4 * yy, rectBaseLP.right - 2, Center.y + 11 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.right - 2, Center.y + 15 * yy, rectBaseLP.right - 2, Center.y + 18 * yy)); 
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.right - 2, Center.y + 18 * yy, Center.x + 14 * yy, Center.y + 18 * yy));

      rect.left = Center.x - 15 * yy;
      rect.top = Center.y + 3 * yy;
      rect.right = Center.x + 15 * yy;
      rect.bottom = Center.y + 33 * yy;
      retArr = retArr.concat(CIRCLE(CDXFFile1, rect));

      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 3 * yy, Center.y + 9 * yy, Center.x + 8 * yy, Center.y + 9 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 8 * yy, Center.y + 9 * yy, Center.x + 8 * yy, Center.y + 19 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 8 * yy, Center.y + 19 * yy, Center.x + 2 * yy, Center.y + 19 * yy));
      
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 8 * yy, Center.y + 14 * yy, Center.x + 4 * yy, Center.y + 14 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 8 * yy, Center.y + 23 * yy, Center.x - 8 * yy, Center.y + 28 * yy));
      
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.bottom + 1, Center.x, Center.y - 1));
      break;
    case -90:
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, Center.y, Center.x - MS_BASE_WIDTH + MS_SYMBOL_SIZE / 2, Center.y));
      
      point.x = Center.x - MS_BASE_HEIGHT + MS_SYMBOL_SIZE / 2;
      
      retArr = retArr.concat(LINE(CDXFFile1, point.x - MS_SYMBOL_SIZE / 6, point.y - MS_SYMBOL_SIZE / 6, point.x + MS_SYMBOL_SIZE / 6 + 1, point.y + MS_SYMBOL_SIZE / 6 + 1));
      retArr = retArr.concat(LINE(CDXFFile1, point.x - MS_SYMBOL_SIZE / 6, point.y + MS_SYMBOL_SIZE / 6, point.x + MS_SYMBOL_SIZE / 6 + 1, point.y - MS_SYMBOL_SIZE / 6 - 1));
      
      retArr = retArr.concat(LINE(CDXFFile1, point.x, Center.y + MS_SYMBOL_SIZE / 2, Center.x + 1, Center.y - 1));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 13 * yy, Center.y + 6 * yy, Center.x - 13 * yy, Center.y));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 13 * yy, Center.y - 3 * yy, Center.x - 13 * yy, Center.y - 9 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 13 * yy, Center.y - 13 * yy, Center.x - 13 * yy, rectBaseLP.top + 2));
      
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 13 * yy, rectBaseLP.top + 2, Center.x - 9 * yy, rectBaseLP.top + 2));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 6 * yy, rectBaseLP.top + 2, Center.x + 1 * yy, rectBaseLP.top + 2));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 4 * yy, rectBaseLP.top + 2, Center.x + 11 * yy, rectBaseLP.top + 2));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 15 * yy, rectBaseLP.top + 2, Center.x + 18 * yy, rectBaseLP.top + 2));
      
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 18 * yy, rectBaseLP.top + 2, Center.x + 18 * yy, Center.y - 14 * yy));

      rect.left = Center.x + 3 * yy;
      rect.top = Center.y - 15 * yy;
      rect.right = Center.x + 33 * yy;
      rect.bottom = Center.y + 15 * yy;
      retArr = retArr.concat(CIRCLE(CDXFFile1, rect));

      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 9 * yy, Center.y - 3 * yy, Center.x + 9 * yy, Center.y - 8 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 9 * yy, Center.y - 8 * yy, Center.x + 19 * yy, Center.y - 8 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 19 * yy, Center.y - 8 * yy, Center.x + 19 * yy, Center.y - 2 * yy));
      
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 14 * yy, Center.y - 8 * yy, Center.x + 14 * yy, Center.y - 4 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 23 * yy, Center.y - 8 * yy, Center.x + 28 * yy, Center.y + 8 * yy));
      
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.right + 1, Center.y, Center.x, Center.y));
      break;
    case 90:
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.right + 1, Center.y, Center.x + MS_BASE_WIDTH - MS_SYMBOL_SIZE / 2, Center.y));
      
      point.x = Center.x + MS_BASE_HEIGHT - MS_SYMBOL_SIZE / 2;
      
      retArr = retArr.concat(LINE(CDXFFile1, point.x + MS_SYMBOL_SIZE / 6, point.y - MS_SYMBOL_SIZE / 6, point.x - MS_SYMBOL_SIZE / 6 - 1, point.y + MS_SYMBOL_SIZE / 6 + 1));
      retArr = retArr.concat(LINE(CDXFFile1, point.x + MS_SYMBOL_SIZE / 6, point.y + MS_SYMBOL_SIZE / 6, point.x - MS_SYMBOL_SIZE / 6 - 1, point.y - MS_SYMBOL_SIZE / 6 - 1));
      
      retArr = retArr.concat(LINE(CDXFFile1, point.x, Center.y - MS_SYMBOL_SIZE / 2, Center.x - 1, Center.y + 1));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 13 * yy, Center.y - 6 * yy, Center.x + 13 * yy, Center.y));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 13 * yy, Center.y + 3 * yy, Center.x + 13 * yy, Center.y + 9 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 13 * yy, Center.y + 13 * yy, Center.x + 13 * yy, rectBaseLP.bottom - 2));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 13 * yy, rectBaseLP.bottom - 2, Center.x + 9 * yy, rectBaseLP.bottom - 2));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 6 * yy, rectBaseLP.bottom - 2, Center.x - 1 * yy, rectBaseLP.bottom - 2));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 4 * yy, rectBaseLP.bottom - 2, Center.x - 11 * yy, rectBaseLP.bottom - 2));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 15 * yy, rectBaseLP.bottom - 2, Center.x - 18 * yy, rectBaseLP.bottom - 2));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 18 * yy, rectBaseLP.bottom - 2, Center.x - 18 * yy, Center.y + 14 * yy));

      rect.left = Center.x - 33 * yy;
      rect.top = Center.y - 15 * yy;
      rect.right = Center.x - 3 * yy;
      rect.bottom = Center.y + 15 * yy;
      retArr = retArr.concat(CIRCLE(CDXFFile1, rect));

      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 9 * yy, Center.y + 3 * yy, Center.x - 9 * yy, Center.y + 8 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 9 * yy, Center.y + 8 * yy, Center.x - 19 * yy, Center.y + 8 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 19 * yy, Center.y + 8 * yy, Center.x - 19 * yy, Center.y + 2 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 14 * yy, Center.y + 8 * yy, Center.x - 14 * yy, Center.y + 4 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 23 * yy, Center.y + 8 * yy, Center.x - 28 * yy, Center.y - 8 * yy));
      
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, Center.y, Center.x, Center.y));
      break;
    }
    break;
  case 1:
    switch (pLVCB.rotation) {
    case 0:
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.top - 1, Center.x, Center.y - 25 * yy));

      rect.left = Center.x - 5;
      rect.top = Center.y - 25;
      rect.right = rect.left + size.cx;
      rect.bottom = rect.top + size.cy;
      retArr = retArr.concat(CIRCLE(CDXFFile1, rect));

      rect.top = Center.y + 15;
      rect.bottom = rect.top + size.cy;
      retArr = retArr.concat(CIRCLE(CDXFFile1, rect));

      retArr = retArr.concat(ANGLE_ARC(CDXFFile1, Center.x - 12, Center.y,
        25, 310, 100));

      retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.bottom + 1, Center.x, Center.y + 25));
      break;
    case -90:
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, Center.y, Center.x - 25, Center.y));

      rect.left = Center.x - 25;
      rect.top = Center.y - 5;
      rect.right = rect.left + size.cx;
      rect.bottom = rect.top + size.cy;
      retArr = retArr.concat(CIRCLE(CDXFFile1, rect));

      rect.left = Center.x + 15;
      rect.right = rect.left + size.cx;
      retArr = retArr.concat(CIRCLE(CDXFFile1, rect));

      retArr = retArr.concat(ANGLE_ARC(CDXFFile1, Center.x, Center.y + 12,
        25, 40, 100));

      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.right + 1, Center.y, Center.x + 25, Center.y));
      break;
    case 90:
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, Center.y, Center.x - 25, Center.y));

      rect.left = Center.x - 25;
      rect.top = Center.y - 5;
      rect.right = rect.left + size.cx;
      rect.bottom = rect.top + size.cy;
      retArr = retArr.concat(CIRCLE(CDXFFile1, rect));

      rect.left = Center.x + 15;
      rect.right = rect.left + size.cx;
      retArr = retArr.concat(CIRCLE(CDXFFile1, rect));

      retArr = retArr.concat(ANGLE_ARC(CDXFFile1, Center.x, Center.y - 12,
        25, 220, 100));

      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.right + 1, Center.y, Center.x + 25, Center.y));
      break;
    }
    break;    
  case 3:
    switch (pLVCB.rotation) {
    case 0:
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.top - 1, Center.x, Center.y - 35 * yy)); // .b
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, Center.y - 35 * yy, Center.x - 10 * yy, Center.y - 30 * yy)); // .c
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, Center.y - 35 * yy, Center.x + 10 * yy, Center.y - 30 * yy)); // .d

      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 10 * yy, Center.y - 26 * yy, Center.x, Center.y - 30 * yy)); // .e
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, Center.y - 30 * yy, Center.x + 10 * yy, Center.y - 26 * yy)); // .g

      rect.left = Center.x - 5;
      rect.top = Center.y - 25;
      rect.right = rect.left + size.cx;
      rect.bottom = rect.top + size.cy;
      retArr = retArr.concat(CIRCLE(CDXFFile1, rect));

      rect.top = Center.y + 15;
      rect.bottom = rect.top + size.cy;
      retArr = retArr.concat(CIRCLE(CDXFFile1, rect));

      rect.left = Center.x - 2;
      rect.top = Center.y - 20;
      rect.right = rect.left + 17;
      rect.bottom = Center.y + 20;

      retArr = retArr.concat(ANGLE_ARC(CDXFFile1, Center.x - 12, Center.y,
        25, 310, 100));

      retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.bottom + 1, Center.x, Center.y + 35 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, Center.y + 35 * yy, Center.x - 10 * yy, Center.y + 30 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, Center.y + 35 * yy, Center.x + 10 * yy, Center.y + 30 * yy));

      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 10 * yy, Center.y + 26 * yy, Center.x, Center.y + 30 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, Center.y + 30 * yy,Center.x + 10 * yy, Center.y + 26 * yy));
      break;
    case -90:
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, Center.y, Center.x - 35 * yy, Center.y));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 35 * yy, Center.y, Center.x - 30 * yy, Center.y - 10 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 35 * yy, Center.y, Center.x - 30 * yy, Center.y + 10 * yy));

      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 26 * yy, Center.y - 10 * yy, Center.x - 30 * yy, Center.y));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 30 * yy, Center.y, Center.x - 26 * yy, Center.y + 10 * yy));

      rect.left = Center.x - 25;
      rect.top = Center.y - 5;
      rect.right = rect.left + size.cx;
      rect.bottom = rect.top + size.cy;
      retArr = retArr.concat(CIRCLE(CDXFFile1, rect));

      rect.left = Center.x + 15;
      rect.right = rect.left + size.cx;
      retArr = retArr.concat(CIRCLE(CDXFFile1, rect));

      retArr = retArr.concat(ANGLE_ARC(CDXFFile1, Center.x, Center.y + 12,
        25, 40, 100));

      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.right + 1, Center.y, Center.x + 35 * yy, Center.y)); // .n    
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 35 * yy, Center.y, Center.x + 30 * yy, Center.y - 10 * yy)); // .p
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 35 * yy, Center.y, Center.x + 30 * yy, Center.y + 10 * yy)); // .o

      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 26 * yy, Center.y - 10 * yy, Center.x + 30 * yy, Center.y)); // .l
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 30 * yy, Center.y, Center.x + 26 * yy, Center.y + 10 * yy)); // .k
      break;
    case 90:
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, Center.y, Center.x - 35 * yy, Center.y));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 35 * yy, Center.y, Center.x - 30 * yy, Center.y - 10 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 35 * yy, Center.y, Center.x - 30 * yy, Center.y + 10 * yy));

      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 26 * yy, Center.y - 10 * yy, Center.x - 30 * yy, Center.y));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 30 * yy, Center.y, Center.x - 26 * yy, Center.y + 10 * yy));

      rect.left = Center.x - 25;
      rect.top = Center.y - 5;
      rect.right = rect.left + size.cx;
      rect.bottom = rect.top + size.cy;
      retArr = retArr.concat(CIRCLE(CDXFFile1, rect));

      rect.left = Center.x + 15;
      rect.right = rect.left + size.cx;
      retArr = retArr.concat(CIRCLE(CDXFFile1, rect));

      retArr = retArr.concat(ANGLE_ARC(CDXFFile1, Center.x, Center.y - 12,
        25, 220, 100));

      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.right + 1, Center.y, Center.x + 35 * yy, Center.y)); // .n    
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 35 * yy, Center.y, Center.x + 30 * yy, Center.y - 10 * yy)); // .p
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 35 * yy, Center.y, Center.x + 30 * yy, Center.y + 10 * yy)); // .o

      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 26 * yy, Center.y - 10 * yy, Center.x + 30 * yy, Center.y)); // .l
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 30 * yy, Center.y, Center.x + 26 * yy, Center.y + 10 * yy)); // .k
      break;
    }
    break;
  }
  return retArr;
}

const doDxfOutSymbolThermal = (CDXFFile1: any, pThermal: any) => {
  let retArr: any[] = [];
  let rectBaseLP = getRectBaseLP(pThermal);
  let rectCenter = {...rectBaseLP};
  let Center = getCenterPoint(rectCenter)

  let point = {...Center};
  let rect = {top: 0, left: 0, right: 0, bottom: 0};
  let point1 = {x: 0, y: 0};
  let Center2 = {x: 0, y: 0};
  let yy = Math.floor(MS_SYMBOL_SIZE / 20);
  switch (pThermal.properties.symKind) {
  case 0:
    point.y = Center.y - MS_BASE_HEIGHT + 18 * yy;

    retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.top - 1, Center.x, point.y));

        rect.left = point.x - 4 * yy;
        rect.top = point.y - 10 * yy;
        rect.right = point.x + 5 * yy;
        rect.bottom = point.y;
        point1.x = point.x;
        point1.y = point.y - 10 * yy;
        Center2.x = point.x;
        Center2.y = point.y;
        retArr = retArr.concat(ARC_Left(CDXFFile1, rect, point1, Center2));

    retArr = retArr.concat(LINE(CDXFFile1, Center.x - 17 * yy, point.y, Center.x, Center.y + MS_BASE_HEIGHT - 22 * yy));
    
    retArr = retArr.concat(SOLID(
      CDXFFile1, 
      Center.x - 10, Center.y - 6,
      Center.x - 6, Center.y + 4,
      Center.x - 14, Center.y + 8,
      Center.x - 14, Center.y + 8));
    retArr = retArr.concat(SOLID(
      CDXFFile1, 
      Center.x - 18, Center.y - 1,
      Center.x - 10, Center.y - 6,
      Center.x - 14, Center.y + 8,
      Center.x - 14, Center.y + 8));

    {
      let y = Math.floor(point.y + MS_BASE_HEIGHT + 28 * yy);
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.bottom + 1, Center.x, y));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, y, Center.x + MS_SYMBOL_SIZE / 2, y));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + MS_SYMBOL_SIZE / 2, y, Center.x + MS_SYMBOL_SIZE / 2, y - MS_BASE_HEIGHT / 3));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + MS_SYMBOL_SIZE / 2, y - MS_BASE_HEIGHT / 3, Center.x, y - MS_BASE_HEIGHT / 3));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, y - MS_BASE_HEIGHT / 3, Center.x, Center.y + MS_BASE_HEIGHT - 22 * yy - 1));
    }
    break;
  case 1:
    point.y = Center.y - MS_BASE_HEIGHT + 18 * yy;

    retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.top - 1, Center.x, point.y));
    retArr = retArr.concat(LINE(CDXFFile1, Center.x, point.y, Center.x + 17 * yy, point.y));
      
        rect.left = point.x - 4 * yy;
        rect.top = point.y - 10 * yy;
        rect.right = point.x + 5 * yy;
        rect.bottom = point.y;
        point1.x = point.x;
        point1.y = point.y;
        Center2.x = point.x;
        Center2.y = point.y - 10 * yy;
        retArr = retArr.concat(ARC_Right(CDXFFile1, rect, point1, Center2));

    retArr = retArr.concat(LINE(CDXFFile1, Center.x + 13 * yy, point.y - 5 * yy, Center.x, Center.y + MS_BASE_HEIGHT - 22 * yy));

    {
      let y = Math.floor(point.y + MS_BASE_HEIGHT + 28 * yy);
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.bottom + 1, Center.x, y));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, y, Center.x + MS_SYMBOL_SIZE / 2, y));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + MS_SYMBOL_SIZE / 2, y, Center.x + MS_SYMBOL_SIZE / 2, y - MS_BASE_HEIGHT / 3));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + MS_SYMBOL_SIZE / 2, y - MS_BASE_HEIGHT / 3, Center.x, y - MS_BASE_HEIGHT / 3));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, y - MS_BASE_HEIGHT / 3, Center.x, Center.y + MS_BASE_HEIGHT - 22 * yy - 1));
    }
    break;
  }

  return retArr;
}

const doDxfOutSymbolThermalCT = (CDXFFile1: any, pThermalCT: any) => {
  let retArr: any[] = [];
  let rectBaseLP = getRectBaseLP(pThermalCT);
  let rectCenter = {...rectBaseLP};
  let Center = getCenterPoint(rectCenter)

  retArr = retArr.concat(LINE(CDXFFile1, Center.x - MS_FRAME_WIDTH / 2, rectBaseLP.top - 1, Center.x - MS_FRAME_WIDTH / 2, Center.y - MS_BASE_HEIGHT + MS_SYMBOL_SIZE / 3));
  
  let point = {...Center};
  point.y = Center.y - MS_FRAME_HEIGHT + MS_SYMBOL_SIZE / 3;
  
  retArr = retArr.concat(LINE(CDXFFile1, point.x - MS_FRAME_WIDTH / 2 - MS_SYMBOL_SIZE / 6, point.y - MS_SYMBOL_SIZE / 6, point.x - MS_FRAME_WIDTH / 2 + MS_SYMBOL_SIZE / 6 + 1, point.y + MS_SYMBOL_SIZE / 6 + 1));
  retArr = retArr.concat(LINE(CDXFFile1, point.x - MS_FRAME_WIDTH / 2 - MS_SYMBOL_SIZE / 6, point.y + MS_SYMBOL_SIZE / 6, point.x - MS_FRAME_WIDTH / 2 + MS_SYMBOL_SIZE / 6 + 1, point.y - MS_SYMBOL_SIZE / 6 - 1));
  retArr = retArr.concat(LINE(CDXFFile1, Center.x - MS_FRAME_WIDTH / 2 - MS_SYMBOL_SIZE / 2, point.y, Center.x - MS_FRAME_WIDTH / 2, Center.y));
  retArr = retArr.concat(LINE(CDXFFile1, Center.x - MS_FRAME_WIDTH / 2, rectBaseLP.bottom + 1, Center.x - MS_FRAME_WIDTH / 2, Center.y - 2));
  
  let rect = {top: 0, left: 0, right: 0, bottom: 0};
  rect.left = Center.x - MS_FRAME_WIDTH / 2 - MS_SYMBOL_SIZE / 4;
  rect.right = Center.x - MS_FRAME_WIDTH / 2 + MS_SYMBOL_SIZE / 4 + 1;
  rect.top = Center.y + MS_FRAME_HEIGHT / 6;
  rect.bottom = Center.y + MS_FRAME_HEIGHT / 6 + MS_SYMBOL_SIZE / 2;
  retArr = retArr.concat(CIRCLE(CDXFFile1, rect));

  retArr = retArr.concat(LINE(CDXFFile1, Center.x - MS_FRAME_WIDTH / 2 + MS_SYMBOL_SIZE / 4, Center.y + MS_BASE_HEIGHT / 6 + MS_SYMBOL_SIZE / 4, Center.x, Center.y + MS_BASE_HEIGHT / 6 + MS_SYMBOL_SIZE / 4));
  
  rect.left = Center.x;
  rect.right = Center.x + MS_SYMBOL_SIZE;
  rect.top = Center.y + MS_BASE_HEIGHT / 2 - MS_SYMBOL_SIZE / 2;
  rect.bottom = Center.y + MS_BASE_HEIGHT / 2 + MS_SYMBOL_SIZE / 14;
  
  retArr = retArr.concat(LINE(CDXFFile1, rect.left, rect.top, rect.right, rect.top));
  retArr = retArr.concat(LINE(CDXFFile1, rect.right, rect.top, rect.right, rect.bottom));
  retArr = retArr.concat(LINE(CDXFFile1, rect.right, rect.bottom, rect.left, rect.bottom));
  retArr = retArr.concat(LINE(CDXFFile1, rect.left, rect.bottom, rect.left, rect.top));

  
  retArr = retArr.concat(LINE(CDXFFile1, Center.x + MS_SYMBOL_SIZE / 2 - MS_SYMBOL_SIZE / 6, Center.y + MS_BASE_HEIGHT / 4 - 1, Center.x + MS_SYMBOL_SIZE / 2 - MS_SYMBOL_SIZE / 6, Center.y + MS_BASE_HEIGHT / 4 + 1));
  retArr = retArr.concat(LINE(CDXFFile1, Center.x + MS_SYMBOL_SIZE / 2 - MS_SYMBOL_SIZE / 6, Center.y + MS_BASE_HEIGHT / 4 + 1, Center.x + MS_SYMBOL_SIZE / 2 + MS_SYMBOL_SIZE / 6, Center.y + MS_BASE_HEIGHT / 4 + 1));
  retArr = retArr.concat(LINE(CDXFFile1, Center.x + MS_SYMBOL_SIZE / 2 + MS_SYMBOL_SIZE / 6, Center.y + MS_BASE_HEIGHT / 4 + 1, Center.x + MS_SYMBOL_SIZE / 2 + MS_SYMBOL_SIZE / 6, Center.y + MS_BASE_HEIGHT / 2 - 2));
  retArr = retArr.concat(LINE(CDXFFile1, Center.x + MS_SYMBOL_SIZE / 2 + MS_SYMBOL_SIZE / 6, Center.y + MS_BASE_HEIGHT / 2 - 2, Center.x + MS_SYMBOL_SIZE / 2 - MS_SYMBOL_SIZE / 6, Center.y + MS_BASE_HEIGHT / 2 - 2));
  retArr = retArr.concat(LINE(CDXFFile1, Center.x + MS_SYMBOL_SIZE / 2 - MS_SYMBOL_SIZE / 6, Center.y + MS_BASE_HEIGHT / 2 - 2, Center.x + MS_SYMBOL_SIZE / 2 - MS_SYMBOL_SIZE / 6, Center.y + MS_BASE_HEIGHT / 2));

  retArr = retArr.concat(LINE(CDXFFile1, Center.x + MS_SYMBOL_SIZE / 2, Center.y + MS_BASE_HEIGHT / 2 - MS_SYMBOL_SIZE / 2, Center.x + MS_SYMBOL_SIZE / 2, Center.y + MS_BASE_HEIGHT / 2 - MS_SYMBOL_SIZE / 2 - MS_SYMBOL_SIZE / 4));
  retArr = retArr.concat(LINE(CDXFFile1, Center.x + MS_SYMBOL_SIZE / 2, Center.y + MS_BASE_HEIGHT / 2 - MS_SYMBOL_SIZE / 2 - (MS_SYMBOL_SIZE / 4 * 2), Center.x + MS_SYMBOL_SIZE / 2, Center.y + MS_BASE_HEIGHT / 2 - MS_SYMBOL_SIZE / 2 - (MS_SYMBOL_SIZE / 4 * 3)));
  retArr = retArr.concat(LINE(CDXFFile1, Center.x + MS_SYMBOL_SIZE / 2, Center.y - MS_SYMBOL_SIZE / 2, Center.x + MS_SYMBOL_SIZE / 2 - MS_SYMBOL_SIZE / 4, Center.y - MS_SYMBOL_SIZE / 2));
  retArr = retArr.concat(LINE(CDXFFile1, Center.x + MS_SYMBOL_SIZE / 2 - (MS_SYMBOL_SIZE / 4 * 2), Center.y - MS_SYMBOL_SIZE / 2, Center.x + MS_SYMBOL_SIZE / 2 - (MS_SYMBOL_SIZE / 4 * 3), Center.y - MS_SYMBOL_SIZE / 2));
  retArr = retArr.concat(LINE(CDXFFile1, Center.x + MS_SYMBOL_SIZE / 2 - (MS_SYMBOL_SIZE / 4 * 4), Center.y - MS_SYMBOL_SIZE / 2, Center.x + MS_SYMBOL_SIZE / 2 - (MS_SYMBOL_SIZE / 4 * 5), Center.y - MS_SYMBOL_SIZE / 2));
  retArr = retArr.concat(LINE(CDXFFile1, Center.x + MS_SYMBOL_SIZE / 2 - (MS_SYMBOL_SIZE / 4 * 6), Center.y - MS_SYMBOL_SIZE / 2, Center.x - MS_SYMBOL_SIZE + MS_SYMBOL_SIZE / 8, Center.y - MS_SYMBOL_SIZE / 2));
  return retArr;
}

const doDxfOutSymbolMotorGrp = (CDXFFile1: any, pMotorGrp: any, nOpeMode: number) => {
  let retArr: any[] = [];
  let rectBaseLP = getRectBaseLP(pMotorGrp);
  let rectCenter = {...rectBaseLP};
  let Center = getCenterPoint(rectCenter);

  let rect = {top: 0, left: 0, right: 0, bottom: 0};
  rect.left = Center.x - MS_SYMBOL_SIZE / 2;
  rect.right = Center.x + MS_SYMBOL_SIZE / 2;
  rect.top = rectBaseLP.bottom - MS_SYMBOL_SIZE - MS_SYMBOL_SIZE / 4;
  rect.bottom = rectBaseLP.bottom - MS_SYMBOL_SIZE / 4;
  retArr = retArr.concat(CIRCLE(CDXFFile1, rect));

  if (nOpeMode != MS_MODE_PERCENT_Z) {
    retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.top - 1, Center.x, rectBaseLP.bottom - MS_SYMBOL_SIZE - MS_SYMBOL_SIZE / 4));
  }
  else {
    let nYdiff = Math.floor(-MS_SYMBOL_SIZE / 2);
    retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.top - 1, Center.x, Center.y - 30 + nYdiff));
    retArr = retArr.concat(LINE(CDXFFile1, Center.x, Center.y - 30 + nYdiff, Center.x + 10, Center.y - 25 + nYdiff));
    retArr = retArr.concat(LINE(CDXFFile1, Center.x + 10, Center.y - 25 + nYdiff, Center.x - 10, Center.y - 15 + nYdiff));
    retArr = retArr.concat(LINE(CDXFFile1, Center.x - 10, Center.y - 15 + nYdiff, Center.x + 10, Center.y - 5 + nYdiff));
    retArr = retArr.concat(LINE(CDXFFile1, Center.x + 10, Center.y - 5 + nYdiff, Center.x - 10, Center.y + 5 + nYdiff));
    retArr = retArr.concat(LINE(CDXFFile1, Center.x - 10, Center.y + 5 + nYdiff, Center.x + 10, Center.y + 15 + nYdiff));
    retArr = retArr.concat(LINE(CDXFFile1, Center.x + 10, Center.y + 15 + nYdiff, Center.x - 10, Center.y + 25 + nYdiff));
    retArr = retArr.concat(LINE(CDXFFile1, Center.x - 10, Center.y + 25 + nYdiff, Center.x - 0, Center.y + 30 + nYdiff));
    retArr = retArr.concat(LINE(CDXFFile1, Center.x - 0, Center.y + 30 + nYdiff, Center.x, rectBaseLP.bottom - MS_SYMBOL_SIZE - MS_SYMBOL_SIZE / 4));
  }

  rect = getDeflateRect(rect, Math.floor(MS_SYMBOL_SIZE / 8), Math.floor(MS_SYMBOL_SIZE / 8));
  retArr = retArr.concat(CIRCLE(CDXFFile1, rect));

  let rectCenterPoint = getCenterPoint(rect);
  retArr = retArr.concat(STRING(CDXFFile1, rectCenterPoint.x - 6, rectCenterPoint.y, 7, "MG"));
  return retArr;
}

const doDxfOutSymbolMotor = (CDXFFile1: any, pMotor: any) => {
  let retArr: any[] = [];
  let rectBaseLP = getRectBaseLP(pMotor);
  let rectCenter = {...rectBaseLP};
  let Center = getCenterPoint(rectCenter);

  let rect = {top: 0, left: 0, right: 0, bottom: 0};
  rect.left = Center.x - MS_SYMBOL_SIZE / 2;
  rect.right = Center.x + MS_SYMBOL_SIZE / 2;
  rect.top = rectBaseLP.bottom - MS_SYMBOL_SIZE - MS_SYMBOL_SIZE / 4;
  rect.bottom = rectBaseLP.bottom - MS_SYMBOL_SIZE / 4;
  retArr = retArr.concat(CIRCLE(CDXFFile1, rect));

  retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.top - 1, Center.x, rectBaseLP.bottom - MS_SYMBOL_SIZE - MS_SYMBOL_SIZE / 4));

  let rectCenterPoint = getCenterPoint(rect);
  retArr = retArr.concat(STRING(CDXFFile1, rectCenterPoint.x - 4, rectCenterPoint.y, 10, "M"));

  return retArr;
}

const doDxfOutSymbolLoad = (CDXFFile1: any, pLoad: any) => {
  let retArr: any[] = [];
  let rectBaseLP = getRectBaseLP(pLoad);
  let rectCenter = {...rectBaseLP};
  let Center = getCenterPoint(rectCenter);

  retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.top - 1, Center.x, rectBaseLP.bottom - MS_SYMBOL_SIZE - MS_SYMBOL_SIZE / 4));

  retArr = retArr.concat(LINE(CDXFFile1, Center.x - MS_SYMBOL_SIZE / 2, rectBaseLP.bottom - MS_SYMBOL_SIZE / 4, Center.x + MS_SYMBOL_SIZE / 2, rectBaseLP.bottom - MS_SYMBOL_SIZE / 4));
  retArr = retArr.concat(LINE(CDXFFile1, Center.x + MS_SYMBOL_SIZE / 2, rectBaseLP.bottom - MS_SYMBOL_SIZE / 4, Center.x + MS_SYMBOL_SIZE / 2, rectBaseLP.bottom - MS_SYMBOL_SIZE - MS_SYMBOL_SIZE / 4));
  retArr = retArr.concat(LINE(CDXFFile1, Center.x + MS_SYMBOL_SIZE / 2, rectBaseLP.bottom - MS_SYMBOL_SIZE - MS_SYMBOL_SIZE / 4, Center.x - MS_SYMBOL_SIZE / 2, rectBaseLP.bottom - MS_SYMBOL_SIZE - MS_SYMBOL_SIZE / 4));
  retArr = retArr.concat(LINE(CDXFFile1, Center.x - MS_SYMBOL_SIZE / 2, rectBaseLP.bottom - MS_SYMBOL_SIZE - MS_SYMBOL_SIZE / 4, Center.x - MS_SYMBOL_SIZE / 2, rectBaseLP.bottom - MS_SYMBOL_SIZE / 4));

  let rect = {top: 0, left: 0, right: 0, bottom: 0};
  rect.left = Center.x - MS_SYMBOL_SIZE / 2;
  rect.right = Center.x + MS_SYMBOL_SIZE / 2 + MS_DIF_WIDTH;
  rect.top = rectBaseLP.bottom - MS_SYMBOL_SIZE - MS_SYMBOL_SIZE / 6;
  rect.bottom = rectBaseLP.bottom - MS_SYMBOL_SIZE / 6 - MS_DIF_HEIGHT;

  let rectCenterPoint = getCenterPoint(rect);
  retArr = retArr.concat(STRING(CDXFFile1, rectCenterPoint.x - 3, rectCenterPoint.y, 10, "L"));

  return retArr;
}

const doDxfOutSymbolCapacitor = (CDXFFile1: any, pCapacitor: any) => {
  let retArr: any[] = [];
  let rectBaseLP = getRectBaseLP(pCapacitor);
  let rectCenter = {...rectBaseLP};
  let Center = getCenterPoint(rectCenter);

  if (pCapacitor.properties.reactor > 0) {
    retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.top - 1, Center.x, Center.y - 46));

    let rectArc = {top: 0, left: 0, right: 0, bottom: 0};
    let pointStart = {x: 0, y: 0};
    let pointEnd = {x: 0, y: 0};
    rectArc.top = Center.y - 47;
    rectArc.bottom = Center.y - 32;
    rectArc.left = Center.x - 20;
    rectArc.right = Center.x + 10;
    pointStart.x = Center.x;
    pointStart.y = Center.y - 33;
    pointEnd.x = Center.x;
    pointEnd.y = Center.y - 47;
    retArr = retArr.concat(ARC_Right(CDXFFile1, rectArc, pointStart, pointEnd));

    rectArc.top = Center.y - 35;
    rectArc.bottom = Center.y - 20;
    rectArc.left = Center.x - 20;
    rectArc.right = Center.x + 10;
    pointStart.x = Center.x;
    pointStart.y = Center.y - 21;
    pointEnd.x = Center.x;
    pointEnd.y = Center.y - 35;
    retArr = retArr.concat(ARC_Right(CDXFFile1, rectArc, pointStart, pointEnd));

    rectArc.top = Center.y - 23;
    rectArc.bottom = Center.y - 8;
    rectArc.left = Center.x - 20;
    rectArc.right = Center.x + 10;
    pointStart.x = Center.x;
    pointStart.y = Center.y - 9;
    pointEnd.x = Center.x;
    pointEnd.y = Center.y - 23;
    retArr = retArr.concat(ARC_Right(CDXFFile1, rectArc, pointStart, pointEnd));

    rectArc.top = Center.y - 11;
    rectArc.bottom = Center.y + 4;
    rectArc.left = Center.x - 20;
    rectArc.right = Center.x + 10;
    pointStart.x = Center.x;
    pointStart.y = Center.y + 3;
    pointEnd.x = Center.x;
    pointEnd.y = Center.y - 11;
    retArr = retArr.concat(ARC_Right(CDXFFile1, rectArc, pointStart, pointEnd));

    retArr = retArr.concat(LINE(CDXFFile1, Center.x, Center.y + 3, Center.x, rectBaseLP.bottom - MS_SYMBOL_SIZE - MS_SYMBOL_SIZE / 4));
  } else {
    retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.top - 1, Center.x, rectBaseLP.bottom - MS_SYMBOL_SIZE - MS_SYMBOL_SIZE / 4));
  }

  retArr = retArr.concat(LINE(CDXFFile1, Center.x - MS_SYMBOL_SIZE / 2, rectBaseLP.bottom - MS_SYMBOL_SIZE / 4, Center.x + MS_SYMBOL_SIZE / 2, rectBaseLP.bottom - MS_SYMBOL_SIZE / 4));
  retArr = retArr.concat(LINE(CDXFFile1, Center.x + MS_SYMBOL_SIZE / 2, rectBaseLP.bottom - MS_SYMBOL_SIZE / 4, Center.x + MS_SYMBOL_SIZE / 2, rectBaseLP.bottom - MS_SYMBOL_SIZE - MS_SYMBOL_SIZE / 4));
  retArr = retArr.concat(LINE(CDXFFile1, Center.x + MS_SYMBOL_SIZE / 2, rectBaseLP.bottom - MS_SYMBOL_SIZE - MS_SYMBOL_SIZE / 4, Center.x - MS_SYMBOL_SIZE / 2, rectBaseLP.bottom - MS_SYMBOL_SIZE - MS_SYMBOL_SIZE / 4));
  retArr = retArr.concat(LINE(CDXFFile1, Center.x - MS_SYMBOL_SIZE / 2, rectBaseLP.bottom - MS_SYMBOL_SIZE - MS_SYMBOL_SIZE / 4, Center.x - MS_SYMBOL_SIZE / 2, rectBaseLP.bottom - MS_SYMBOL_SIZE / 4));
  
  let rect = {top: 0, left: 0, right: 0, bottom: 0};
  rect.left = Center.x - MS_SYMBOL_SIZE / 2;
  rect.right = Center.x + MS_SYMBOL_SIZE / 2;
  rect.top = rectBaseLP.bottom - MS_SYMBOL_SIZE - MS_SYMBOL_SIZE / 4;
  rect.bottom = rectBaseLP.bottom - MS_SYMBOL_SIZE / 4;

  let point = getCenterPoint(rect);

  retArr = retArr.concat(LINE(CDXFFile1, point.x - MS_SYMBOL_SIZE / 3, point.y - MS_SYMBOL_SIZE / 10, point.x + MS_SYMBOL_SIZE / 3 + 1, point.y - MS_SYMBOL_SIZE / 10));
  retArr = retArr.concat(LINE(CDXFFile1, point.x, point.y - MS_SYMBOL_SIZE / 3, point.x, point.y - MS_SYMBOL_SIZE / 10));

  retArr = retArr.concat(LINE(CDXFFile1, point.x - MS_SYMBOL_SIZE / 3, point.y + MS_SYMBOL_SIZE / 10, point.x + MS_SYMBOL_SIZE / 3 + 1, point.y + MS_SYMBOL_SIZE / 10));
  retArr = retArr.concat(LINE(CDXFFile1, point.x, point.y + MS_SYMBOL_SIZE / 3, point.x, point.y + MS_SYMBOL_SIZE / 10));

  return retArr;
}

const doDxfOutSymbolVCT = (CDXFFile1: any, pVCT: any) => {
  let retArr: any[] = [];
  let rectBaseLP = getRectBaseLP(pVCT);
  let rectCenter = {...rectBaseLP};
  let Center = getCenterPoint(rectCenter);
  let yy = Math.floor(MS_SYMBOL_SIZE / 20);

  retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.top - 1, Center.x, Center.y - 11 * yy));

  retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.bottom + 1, Center.x, Center.y + 11 * yy));

  retArr = retArr.concat(LINE(CDXFFile1, Center.x - 18 * yy, Center.y - 11 * yy, Center.x + 18 * yy, Center.y - 11 * yy));
  retArr = retArr.concat(LINE(CDXFFile1, Center.x + 18 * yy, Center.y - 11 * yy, Center.x + 18 * yy, Center.y + 11 * yy));
  retArr = retArr.concat(LINE(CDXFFile1, Center.x + 18 * yy, Center.y + 11 * yy, Center.x - 18 * yy, Center.y + 11 * yy));
  retArr = retArr.concat(LINE(CDXFFile1, Center.x - 18 * yy, Center.y + 11 * yy, Center.x - 18 * yy, Center.y - 11 * yy));

  let rect = {top: 0, left: 0, right: 0, bottom: 0};
  rect.top = Center.y - 13 * yy;
  rect.bottom = Center.y + 15 * yy;
  rect.left = Center.x - 16 * yy;
  rect.right = Center.x + 19 * yy;

  let rectCenterPoint = getCenterPoint(rect);
  switch (pVCT.properties.symKind) {
  case 0:
    retArr = retArr.concat(STRING(CDXFFile1, rectCenterPoint.x - 15, rectCenterPoint.y, 10, "VCT"));
    break;
  case 1:
    retArr = retArr.concat(STRING(CDXFFile1, rectCenterPoint.x - 15, rectCenterPoint.y, 10, "PCT"));
    break;
  case 2:
    retArr = retArr.concat(STRING(CDXFFile1, rectCenterPoint.x - 15, rectCenterPoint.y, 10, "MOF"));
    break;
  }
  return retArr;
}

const doDxfOutSymbolINV = (CDXFFile1: any, pINV: any) => {
  let retArr: any[] = [];
  let rectBaseLP = getRectBaseLP(pINV);
  let rectCenter = {...rectBaseLP};
  let Center = getCenterPoint(rectCenter);
  let yy = Math.floor(MS_SYMBOL_SIZE / 20);

  retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.top - 1, Center.x, Center.y - 11 * yy));

  retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.bottom + 1, Center.x, Center.y + 11 * yy));

  retArr = retArr.concat(LINE(CDXFFile1, Center.x - 18 * yy, Center.y - 11 * yy, Center.x + 18 * yy, Center.y - 11 * yy));
  retArr = retArr.concat(LINE(CDXFFile1, Center.x + 18 * yy, Center.y - 11 * yy, Center.x + 18 * yy, Center.y + 11 * yy));
  retArr = retArr.concat(LINE(CDXFFile1, Center.x + 18 * yy, Center.y + 11 * yy, Center.x - 18 * yy, Center.y + 11 * yy));
  retArr = retArr.concat(LINE(CDXFFile1, Center.x - 18 * yy, Center.y + 11 * yy, Center.x - 18 * yy, Center.y - 11 * yy));

  let rect = {top: 0, left: 0, right: 0, bottom: 0};
  rect.top = Center.y - 13 * yy;
  rect.bottom = Center.y + 15 * yy;
  rect.left = Center.x - 16 * yy;
  rect.right = Center.x + 19 * yy;

  let rectCenterPoint = getCenterPoint(rect);
  switch (pINV.properties.exchangeNumber) {
  case 0:
    retArr = retArr.concat(STRING(CDXFFile1, rectCenterPoint.x - 15, rectCenterPoint.y, 10, "INV"));
    break;
  case 1:
    retArr = retArr.concat(STRING(CDXFFile1, rectCenterPoint.x - 15, rectCenterPoint.y, 10, "PCT"));
    break;
  case 2:
    retArr = retArr.concat(STRING(CDXFFile1, rectCenterPoint.x - 15, rectCenterPoint.y, 10, "MOF"));
    break;
  }
  return retArr;
}

const doDxfOutSymbolRELAY = (CDXFFile1: any, pRELAY: any) => {
  let retArr: any[] = [];
  let rectBaseLP = getRectBaseLP(pRELAY);
  let rectCenter = {...rectBaseLP};
  let Center = getCenterPoint(rectCenter);
  let yy = Math.floor(MS_SYMBOL_SIZE / 20);

  switch (pRELAY.rotation)
  {
    case 0:
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.top - 1, Center.x, Center.y - 9 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.bottom + 1, Center.x, Center.y + 19 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 18 * yy, Center.y - 9 * yy, Center.x + 18 * yy, Center.y - 9 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 18 * yy, Center.y - 9 * yy, Center.x + 18 * yy, Center.y + 9 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 18 * yy, Center.y + 9 * yy, Center.x - 18 * yy, Center.y + 9 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 18 * yy, Center.y + 9 * yy, Center.x - 18 * yy, Center.y - 9 * yy));
      break;
    case -90:
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, Center.y, Center.x - 18 * yy, Center.y));
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.right + 1, Center.y, Center.x + 18 * yy, Center.y));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 18 * yy, Center.y - 9 * yy, Center.x + 19 * yy, Center.y - 9 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 18 * yy, Center.y - 9 * yy, Center.x + 19 * yy, Center.y + 9 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 18 * yy, Center.y + 9 * yy, Center.x - 19 * yy, Center.y + 9 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 18 * yy, Center.y + 9 * yy, Center.x - 19 * yy, Center.y - 9 * yy));
      break;
    case 90:
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, Center.y, Center.x - 18 * yy, Center.y));
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.right + 1, Center.y, Center.x + 18 * yy, Center.y));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 18 * yy, Center.y - 9 * yy, Center.x + 18 * yy, Center.y - 9 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 18 * yy, Center.y - 9 * yy, Center.x + 18 * yy, Center.y + 9 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 18 * yy, Center.y + 9 * yy, Center.x - 18 * yy, Center.y + 9 * yy));
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 18 * yy, Center.y + 9 * yy, Center.x - 18 * yy, Center.y - 9 * yy));
      break;
  }

    let rect = {top: 0, left: 0, right: 0, bottom: 0};
    rect.top = Center.y - 13 * yy;
    rect.bottom = Center.y + 15 * yy;
    rect.left = Center.x - 16 * yy;
    rect.right = Center.x + 19 * yy;

  let rectCenterPoint = getCenterPoint(rect);
  switch (pRELAY.properties.symKind) {
  case 0:
    retArr = retArr.concat(STRING(CDXFFile1, rectCenterPoint.x - 10, rectCenterPoint.y, 10, "Ry"));
    break;
  case 1:
    retArr = retArr.concat(STRING(CDXFFile1, rectCenterPoint.x - 10, rectCenterPoint.y, 10, "I >"));
    break;
  case 2:
    retArr = retArr.concat(STRING(CDXFFile1, rectCenterPoint.x - 11, rectCenterPoint.y, 10, pRELAY.properties.subText));
    break;
  }
  return retArr;
}

const doDxfOutSymbolArrestor = (CDXFFile1: any, pArrestor: any) => {
  let retArr: any[] = [];
  let rectBaseLP = getRectBaseLP(pArrestor);
  let rectCenter = {...rectBaseLP};
  let Center = getCenterPoint(rectCenter);

  let nEarth_Y = 0;
  if ((rectBaseLP.bottom - rectBaseLP.top) > MS_FRAME_HEIGHT * 2) {
    nEarth_Y = 18;
    Center.y -= MS_FRAME_HEIGHT / 2;
  } else {
    nEarth_Y = 8;
    Center.y -= 2;
  }
  let point = {...Center};

  let yy = Math.floor(MS_SYMBOL_SIZE / 20);

  switch (pArrestor.properties.symKind) {
  case 0:
    retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.top - 1, Center.x, Center.y - 16 * yy)); // a.b
            
    retArr = retArr.concat(LINE(CDXFFile1, Center.x - 6 * yy, Center.y - 26 * yy, Center.x + 6 * yy, Center.y - 26 * yy));
    retArr = retArr.concat(LINE(CDXFFile1, Center.x + 6 * yy, Center.y - 26 * yy, Center.x + 6 * yy, Center.y + 26 * yy));
    retArr = retArr.concat(LINE(CDXFFile1, Center.x + 6 * yy, Center.y + 26 * yy, Center.x - 6 * yy, Center.y + 26 * yy));
    retArr = retArr.concat(LINE(CDXFFile1, Center.x - 6 * yy, Center.y + 26 * yy, Center.x - 6 * yy, Center.y - 26 * yy)); // f.c

    retArr = retArr.concat(SOLID(CDXFFile1, 
      Center.x - 2 * yy, Center.y - 20 * yy,
      Center.x + 2 * yy, Center.y - 20 * yy,
      Center.x, Center.y - 10 * yy,
      Center.x, Center.y - 10 * yy));

    retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.bottom - nEarth_Y, Center.x, Center.y +  26 * yy));  // h.i
    break;
  case 1:
    retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.top - 1, Center.x, Center.y - 16 * yy)); // a.b
            
    retArr = retArr.concat(LINE(CDXFFile1, Center.x - 6 * yy, Center.y - 26 * yy, Center.x + 6 * yy, Center.y - 26 * yy));
    retArr = retArr.concat(LINE(CDXFFile1, Center.x + 6 * yy, Center.y - 26 * yy, Center.x + 6 * yy, Center.y + 26 * yy));
    retArr = retArr.concat(LINE(CDXFFile1, Center.x + 6 * yy, Center.y + 26 * yy, Center.x - 6 * yy, Center.y + 26 * yy));
    retArr = retArr.concat(LINE(CDXFFile1, Center.x - 6 * yy, Center.y + 26 * yy, Center.x - 6 * yy, Center.y - 26 * yy)); // f.c

    retArr = retArr.concat(SOLID(CDXFFile1, 
      Center.x - 2 * yy, Center.y - 20 * yy,
      Center.x + 2 * yy, Center.y - 20 * yy,
      Center.x, Center.y - 10 * yy,
      Center.x, Center.y - 10 * yy));

    retArr = retArr.concat(SOLID(CDXFFile1, 
      Center.x - 2 * yy, Center.y + 20 * yy,
      Center.x + 2 * yy, Center.y + 20 * yy,
      Center.x, Center.y + 10 * yy,
      Center.x, Center.y + 10 * yy));

    retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.bottom - nEarth_Y, Center.x, Center.y +  16 * yy));  // h.i
    break;
  case 2:
    point = Center;
    point.x -= 9 * yy;

    retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.top - 1, Center.x, Center.y - 32 * yy)); // a.b
            
    retArr = retArr.concat(LINE(CDXFFile1, Center.x - 9 * yy, Center.y - 32 * yy, Center.x + 9 * yy, Center.y - 32 * yy));
    retArr = retArr.concat(LINE(CDXFFile1, Center.x + 9 * yy, Center.y - 32 * yy, Center.x + 9 * yy, Center.y - 3 * yy));  // d.p
            
    retArr = retArr.concat(LINE(CDXFFile1, Center.x + 4 * yy, Center.y - 3 * yy, Center.x + 15 * yy, Center.y - 3 * yy));  // n.o
            
    retArr = retArr.concat(LINE(CDXFFile1, Center.x - 9 * yy, Center.y + 32 * yy, Center.x + 9 * yy, Center.y + 32 * yy));
    retArr = retArr.concat(LINE(CDXFFile1, Center.x + 9 * yy, Center.y + 32 * yy, Center.x + 9 * yy, Center.y + 3 * yy));  // j.q
            
    retArr = retArr.concat(LINE(CDXFFile1, Center.x + 4 * yy, Center.y + 3 * yy, Center.x + 15 * yy, Center.y + 3 * yy));  // s.t
            
    retArr = retArr.concat(LINE(CDXFFile1, Center.x - 9 * yy, Center.y - 32 * yy, Center.x - 9 * yy, Center.y - 10 * yy)); // c.u
            
    retArr = retArr.concat(LINE(CDXFFile1, point.x - 6 * yy, Center.y - 26 * yy, point.x + 6 * yy, Center.y - 26 * yy));
    retArr = retArr.concat(LINE(CDXFFile1, point.x + 6 * yy, Center.y - 26 * yy, point.x + 6 * yy, Center.y + 26 * yy));
    retArr = retArr.concat(LINE(CDXFFile1, point.x + 6 * yy, Center.y + 26 * yy, point.x - 6 * yy, Center.y + 26 * yy));
    retArr = retArr.concat(LINE(CDXFFile1, point.x - 6 * yy, Center.y + 26 * yy, point.x - 6 * yy, Center.y - 26 * yy)); // h.e
            
    retArr = retArr.concat(LINE(CDXFFile1, Center.x - 9 * yy, Center.y + 32 * yy, Center.x - 9 * yy, Center.y + 10 * yy)); // i.v

    retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.bottom - nEarth_Y, Center.x, Center.y +  32 * yy));  // m.k

    retArr = retArr.concat(SOLID(CDXFFile1, 
      point.x - 2 * yy, Center.y - 20 * yy,
      point.x + 2 * yy, Center.y - 20 * yy,
      point.x, Center.y - 10 * yy,
      point.x, Center.y - 10 * yy));

    retArr = retArr.concat(SOLID(CDXFFile1, 
      point.x - 2 * yy, Center.y + 20 * yy,
      point.x + 2 * yy, Center.y + 20 * yy,
      point.x, Center.y + 10 * yy,
      point.x, Center.y + 10 * yy));
    break;
  }

  if (pArrestor.properties.haveEarth == true) {
    retArr = retArr.concat(LINE(CDXFFile1, Center.x - 8 * yy, rectBaseLP.bottom - nEarth_Y, Center.x + 8 * yy + 1, rectBaseLP.bottom - nEarth_Y));
    retArr = retArr.concat(LINE(CDXFFile1, Center.x - 6 * yy, rectBaseLP.bottom - nEarth_Y + 4, Center.x + 6 * yy + 1, rectBaseLP.bottom - nEarth_Y + 4));
    retArr = retArr.concat(LINE(CDXFFile1, Center.x - 4 * yy, rectBaseLP.bottom - nEarth_Y + 8, Center.x + 4 * yy + 1, rectBaseLP.bottom - nEarth_Y + 8));
  } else {
    retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.bottom + 1 * yy, Center.x, rectBaseLP.bottom - nEarth_Y));
  }
  return retArr;
}

const doDxfOutSymbolEarth = (CDXFFile1: any, pEarth: any) => {
  let retArr: any[] = [];
  let rectBaseLP = getRectBaseLP(pEarth);
  let rectCenter = {...rectBaseLP};
  let Center = getCenterPoint(rectCenter);

  let rect = {top: 0, left: 0, right: 0, bottom: 0};
  let point1 = {x: 0, y: 0};
  let Center2 = {x: 0, y: 0};

  let yy = Math.floor(MS_SYMBOL_SIZE / 20);

  switch (pEarth.properties.symKind) {
  case 0:
    retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.top - 1, Center.x, rectBaseLP.bottom - 18 * yy));
    retArr = retArr.concat(LINE(CDXFFile1, Center.x - 8 * yy, rectBaseLP.bottom - 18 * yy, Center.x + 8 * yy + 1, rectBaseLP.bottom - 18 * yy));
    retArr = retArr.concat(LINE(CDXFFile1, Center.x - 6 * yy, rectBaseLP.bottom - 14 * yy, Center.x + 6 * yy + 1, rectBaseLP.bottom - 14 * yy));
    retArr = retArr.concat(LINE(CDXFFile1, Center.x - 4 * yy, rectBaseLP.bottom - 10 * yy, Center.x + 4 * yy + 1, rectBaseLP.bottom - 10 * yy));
    break;
  case 1:
    retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.top - 1, Center.x, rectBaseLP.bottom - 18 * yy));
    retArr = retArr.concat(LINE(CDXFFile1, Center.x - 8 * yy, rectBaseLP.bottom - 18 * yy, Center.x + 8 * yy + 1, rectBaseLP.bottom - 18 * yy));
    retArr = retArr.concat(LINE(CDXFFile1, Center.x - 8 * yy, rectBaseLP.bottom - 18 * yy, Center.x - 16 * yy - 1, rectBaseLP.bottom - 9 * yy));
    retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.bottom - 18 * yy, Center.x - 8 * yy - 1, rectBaseLP.bottom - 9 * yy)); 
    retArr = retArr.concat(LINE(CDXFFile1, Center.x + 8 * yy, rectBaseLP.bottom - 18 * yy, Center.x - 1, rectBaseLP.bottom - 9 * yy));
    break;
  case 2:
    retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.top - 1, Center.x, rectBaseLP.bottom - 18 * yy));
    retArr = retArr.concat(LINE(CDXFFile1, Center.x - 8 * yy, rectBaseLP.bottom - 18 * yy, Center.x + 8 * yy + 1, rectBaseLP.bottom - 18 * yy));
    retArr = retArr.concat(LINE(CDXFFile1, Center.x - 6 * yy, rectBaseLP.bottom - 14 * yy, Center.x + 6 * yy + 1, rectBaseLP.bottom - 14 * yy));
    retArr = retArr.concat(LINE(CDXFFile1, Center.x - 4 * yy, rectBaseLP.bottom - 10 * yy, Center.x + 4 * yy + 1, rectBaseLP.bottom - 10 * yy)); // g.h
      
        rect.left = Center.x - 14 * yy;
        rect.top = rectBaseLP.bottom - 24 * yy;
        rect.right = Center.x + 14 * yy;
        rect.bottom = rectBaseLP.bottom + 4 * yy;
        point1.x = Center.x + 14 * yy;
        point1.y = rectBaseLP.bottom - 9 * yy;
        Center2.x = Center.x - 14 * yy;
        Center2.y = rectBaseLP.bottom - 9 * yy;
        retArr = retArr.concat(ARC_Top(CDXFFile1, rect, point1, Center2));

    break;
  case 3:
    retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.top - 1, Center.x, rectBaseLP.bottom - 18 * yy));
    retArr = retArr.concat(LINE(CDXFFile1, Center.x - 8 * yy, rectBaseLP.bottom - 18 * yy, Center.x + 8 * yy + 1, rectBaseLP.bottom - 18 * yy));
    retArr = retArr.concat(LINE(CDXFFile1, Center.x - 6 * yy, rectBaseLP.bottom - 14 * yy, Center.x + 6 * yy + 1, rectBaseLP.bottom - 14 * yy));
    retArr = retArr.concat(LINE(CDXFFile1, Center.x - 4 * yy, rectBaseLP.bottom - 10 * yy, Center.x + 4 * yy + 1, rectBaseLP.bottom - 10 * yy)); // g.h
      
    rect.left = Center.x - 14 * yy;
    rect.top = rectBaseLP.bottom - 31 * yy;
    rect.right = Center.x + 14 * yy;
    rect.bottom = rectBaseLP.bottom - 2 * yy;
    retArr = retArr.concat(CIRCLE(CDXFFile1, rect));
    break;
  case 4:
    retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.top - 1, Center.x, rectBaseLP.bottom - 18 * yy));  // a.b

    retArr = retArr.concat(LINE(CDXFFile1, Center.x - 9 * yy, rectBaseLP.bottom - 18 * yy, Center.x + 9 * yy, rectBaseLP.bottom - 18 * yy));
    retArr = retArr.concat(LINE(CDXFFile1, Center.x + 9 * yy, rectBaseLP.bottom - 18 * yy, Center.x, rectBaseLP.bottom - 9 * yy));
    retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.bottom - 9 * yy, Center.x - 9 * yy, rectBaseLP.bottom - 18 * yy)); // e.c
    break;
  }

  return retArr;
}

const doDxfOutSymbolVT = (CDXFFile1: any, pVT: any) => {
  let retArr: any[] = [];
  let rectBaseLP = getRectBaseLP(pVT);
  let rectCenter = {...rectBaseLP};
  let Center = getCenterPoint(rectCenter);

  let rect = {top: 0, left: 0, right: 0, bottom: 0};
  let point1 = {x: 0, y: 0};
  let Center2 = {x: 0, y: 0};

  let yy = Math.floor(MS_SYMBOL_SIZE / 20);

  retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.top - 1, Center.x, Center.y - 9 * yy));  // a->b

  rect.left = Center.x - 20 * yy;
  rect.top = Center.y - 15 * yy;
  rect.right = Center.x - 10 * yy;
  rect.bottom = Center.y - 5 * yy;
  point1.x = Center.x - 20 * yy;
  point1.y = Center.y - 10 * yy;
  Center2.x = Center.x - 10 * yy;
  Center2.y = Center.y - 10 * yy;
  retArr = retArr.concat(ARC_Bottom(CDXFFile1, rect, point1, Center2));

  rect.left = Center.x - 10 * yy;
  rect.top = Center.y - 15 * yy;
  rect.right = Center.x - 0 * yy;
  rect.bottom = Center.y - 5 * yy;
  point1.x = Center.x - 10 * yy;
  point1.y = Center.y - 10 * yy;
  Center2.x = Center.x - 0 * yy;
  Center2.y = Center.y - 10 * yy;
  retArr = retArr.concat(ARC_Bottom(CDXFFile1, rect, point1, Center2));

  rect.left = Center.x - 0 * yy;
  rect.top = Center.y - 15 * yy;
  rect.right = Center.x + 10 * yy;
  rect.bottom = Center.y - 5 * yy;
  point1.x = Center.x - 0 * yy;
  point1.y = Center.y - 10 * yy;
  Center2.x = Center.x + 10 * yy;
  Center2.y = Center.y - 10 * yy;
  retArr = retArr.concat(ARC_Bottom(CDXFFile1, rect, point1, Center2));

  rect.left = Center.x + 10 * yy;
  rect.top = Center.y - 15 * yy;
  rect.right = Center.x + 20 * yy;
  rect.bottom = Center.y - 5 * yy;
  point1.x = Center.x + 10 * yy;
  point1.y = Center.y - 10 * yy;
  Center2.x = Center.x + 20 * yy;
  Center2.y = Center.y - 10 * yy;
  retArr = retArr.concat(ARC_Bottom(CDXFFile1, rect, point1, Center2));

  rect.left = Center.x - 20 * yy;
  rect.top = Center.y + 5 * yy;
  rect.right = Center.x - 10 * yy;
  rect.bottom = Center.y + 15 * yy;
  point1.x = Center.x - 10 * yy;
  point1.y = Center.y + 10 * yy;
  Center2.x = Center.x - 20 * yy;
  Center2.y = Center.y + 10 * yy;
  retArr = retArr.concat(ARC_Top(CDXFFile1, rect, point1, Center2));

  rect.left = Center.x - 10 * yy;
  rect.top = Center.y + 5 * yy;
  rect.right = Center.x - 0 * yy;
  rect.bottom = Center.y + 15 * yy;
  point1.x = Center.x - 0 * yy;
  point1.y = Center.y + 10 * yy;
  Center2.x = Center.x - 10 * yy;
  Center2.y = Center.y + 10 * yy;
  retArr = retArr.concat(ARC_Top(CDXFFile1, rect, point1, Center2));

  rect.left = Center.x - 0 * yy;
  rect.top = Center.y + 5 * yy;
  rect.right = Center.x + 10 * yy;
  rect.bottom = Center.y + 15 * yy;
  point1.x = Center.x + 10 * yy;
  point1.y = Center.y + 10 * yy;
  Center2.x = Center.x - 0 * yy;
  Center2.y = Center.y + 10 * yy;
  retArr = retArr.concat(ARC_Top(CDXFFile1, rect, point1, Center2));

  rect.left = Center.x + 10 * yy;
  rect.top = Center.y + 5 * yy;
  rect.right = Center.x + 20 * yy;
  rect.bottom = Center.y + 15 * yy;
  point1.x = Center.x + 20 * yy;
  point1.y = Center.y + 10 * yy;
  Center2.x = Center.x + 10 * yy;
  Center2.y = Center.y + 10 * yy;
  retArr = retArr.concat(ARC_Top(CDXFFile1, rect, point1, Center2));

  retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.bottom + 1, Center.x, Center.y + 9 * yy));
  
  return retArr;
}

const doDxfOutSymbolCableHead = (CDXFFile1: any, pCableHead: any) => {
  let retArr: any[] = [];
  let rectBaseLP = getRectBaseLP(pCableHead);
  let rectCenter = {...rectBaseLP};
  let Center = getCenterPoint(rectCenter);

  let yy = Math.floor(MS_SYMBOL_SIZE / 20);

  switch (pCableHead.properties.symKind) {
  case 0:
    switch (pCableHead.rotation) {
    case 0:
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.top - 1, Center.x, Center.y - 5 * yy));  // a.b
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 9 * yy, Center.y - 5 * yy, Center.x + 9 * yy, Center.y - 5 * yy)); 
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 9 * yy, Center.y - 5 * yy, Center.x, Center.y + 4 * yy));  
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, Center.y + 4 * yy, Center.x - 9 * yy, Center.y - 5 * yy));  // e.c
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.bottom + 1, Center.x, Center.y + 4 * yy)); // f.e
      break;
    case -90:
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, Center.y, Center.x - 5 * yy, Center.y)); // a.b
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 5 * yy, Center.y - 9 * yy, Center.x - 5 * yy, Center.y + 9 * yy)); 
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 5 * yy, Center.y + 9 * yy, Center.x + 4 * yy, Center.y));  
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 4 * yy, Center.y, Center.x - 5 * yy, Center.y - 9 * yy));  // e.c
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.right + 1, Center.y, Center.x + 4 * yy, Center.y));  // f.e
      break;
    case 90:
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.right+ 1, Center.y, Center.x + 5 * yy, Center.y)); // a.b
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 5 * yy, Center.y - 9 * yy, Center.x + 5 * yy, Center.y + 9 * yy)); 
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 5 * yy, Center.y + 9 * yy, Center.x - 4 * yy, Center.y));  
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 4 * yy, Center.y, Center.x + 5 * yy, Center.y - 9 * yy));  // e.c
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, Center.y, Center.x - 4 * yy, Center.y)); // f.e
      break;
    }
    break;
  case 1:
    switch (pCableHead.rotation) {
    case 0:
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.bottom + 1, Center.x, Center.y + 5 * yy)); // a.b
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 9 * yy, Center.y + 5 * yy, Center.x + 9 * yy, Center.y + 5 * yy)); 
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 9 * yy, Center.y + 5 * yy, Center.x, Center.y - 4 * yy));  
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, Center.y - 4 * yy, Center.x - 9 * yy, Center.y + 5 * yy));  // e.c
      retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.top - 1, Center.x, Center.y - 4 * yy));  // f.e
      break;
    case -90:
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.right+ 1, Center.y, Center.x + 5 * yy, Center.y)); // a.b
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 5 * yy, Center.y - 9 * yy, Center.x + 5 * yy, Center.y + 9 * yy)); 
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 5 * yy, Center.y + 9 * yy, Center.x - 4 * yy, Center.y));  
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 4 * yy, Center.y, Center.x + 5 * yy, Center.y - 9 * yy));  // e.c
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, Center.y, Center.x - 4 * yy, Center.y)); // f.e
      break;
    case 90:
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.left - 1, Center.y, Center.x - 5 * yy, Center.y)); // a.b
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 5 * yy, Center.y - 9 * yy, Center.x - 5 * yy, Center.y + 9 * yy)); 
      retArr = retArr.concat(LINE(CDXFFile1, Center.x - 5 * yy, Center.y + 9 * yy, Center.x + 4 * yy, Center.y));  
      retArr = retArr.concat(LINE(CDXFFile1, Center.x + 4 * yy, Center.y, Center.x - 5 * yy, Center.y - 9 * yy));  // e.c
      retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.right + 1, Center.y, Center.x + 4 * yy, Center.y));  // f.e
      break;
    }
    break;
  }

  return retArr;
}

const doDxfOutSymbolCT = (CDXFFile1: any, pCT: any) => {
  let retArr: any[] = [];
  let rectBaseLP = getRectBaseLP(pCT);
  let rectCenter = {...rectBaseLP};
  let Center = getCenterPoint(rectCenter);

  let yy = Math.floor(MS_SYMBOL_SIZE / 20);

  retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.top - 1, Center.x, rectBaseLP.bottom + 1));

  let rect = {top: 0, left: 0, right: 0, bottom: 0};
  rect.left = Center.x - 9 * yy;
  rect.top = Center.y - 9 * yy;
  rect.right = Center.x + 9 * yy;
  rect.bottom = Center.y + 9 * yy;
  retArr = retArr.concat(CIRCLE(CDXFFile1, rect));

  retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.right - 1, Center.y, Center.x + 9 * yy, Center.y));  // c->d
  
  return retArr;
}

const doDxfOutSymbolZCT = (CDXFFile1: any, pZCT: any) => {
  let retArr: any[] = [];
  let rectBaseLP = getRectBaseLP(pZCT);
  let rectCenter = {...rectBaseLP};
  let Center = getCenterPoint(rectCenter);

  let yy = Math.floor(MS_SYMBOL_SIZE / 20);

  retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.top - 1, Center.x, rectBaseLP.bottom + 1));

  let rect = {top: 0, left: 0, right: 0, bottom: 0};
  rect.left = Center.x - 9 * yy;
  rect.top = Center.y - 9 * yy;
  rect.right = Center.x + 9 * yy;
  rect.bottom = Center.y + 9 * yy;
  retArr = retArr.concat(CIRCLE(CDXFFile1, rect));

  retArr = retArr.concat(LINE(CDXFFile1, rectBaseLP.right - 1, Center.y, Center.x + 9 * yy, Center.y));  // c->d

  rect.top = Center.y - 2;
  rect.bottom = Center.y + 2;
  rect.left = Center.x + 2;
  rect.right = Center.x + 9;

  retArr = retArr.concat(STRING(CDXFFile1, rect.left, rect.top, 5, "3"));

  retArr = retArr.concat(LINE(CDXFFile1, Center.x + 5 * yy, Center.y + 1 * yy, Center.x - 5 * yy, Center.y + 6 * yy)); // e->f
  return retArr;
}

const doDxfOutSymbolLightBoard = (CDXFFile1: any, pLightBoard: any) => {
  let retArr: any[] = [];
  let rectBaseLP = getRectBaseLP(pLightBoard);
  let rectCenter = {...rectBaseLP};
  let Center = getCenterPoint(rectCenter);
  
  Center.y += MS_SYMBOL_SIZE / 5;

  retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.top - 1, Center.x, rectBaseLP.bottom - MS_SYMBOL_SIZE / 2 - MS_SYMBOL_SIZE / 4));

  retArr = retArr.concat(LINE(CDXFFile1, Center.x - MS_SYMBOL_SIZE / 2, rectBaseLP.bottom - MS_SYMBOL_SIZE / 4, Center.x + MS_SYMBOL_SIZE / 2, rectBaseLP.bottom - MS_SYMBOL_SIZE / 4));
  retArr = retArr.concat(LINE(CDXFFile1, Center.x + MS_SYMBOL_SIZE / 2, rectBaseLP.bottom - MS_SYMBOL_SIZE / 4, Center.x + MS_SYMBOL_SIZE / 2, rectBaseLP.bottom - MS_SYMBOL_SIZE / 2 - MS_SYMBOL_SIZE / 4));
  retArr = retArr.concat(LINE(CDXFFile1, Center.x + MS_SYMBOL_SIZE / 2, rectBaseLP.bottom - MS_SYMBOL_SIZE / 2 - MS_SYMBOL_SIZE / 4, Center.x - MS_SYMBOL_SIZE / 2, rectBaseLP.bottom - MS_SYMBOL_SIZE / 2 - MS_SYMBOL_SIZE / 4));
  retArr = retArr.concat(LINE(CDXFFile1, Center.x - MS_SYMBOL_SIZE / 2, rectBaseLP.bottom - MS_SYMBOL_SIZE / 2 - MS_SYMBOL_SIZE / 4, Center.x - MS_SYMBOL_SIZE / 2, rectBaseLP.bottom - MS_SYMBOL_SIZE / 4));

  retArr = retArr.concat(SOLID(CDXFFile1, 
    Center.x + MS_SYMBOL_SIZE / 2, rectBaseLP.bottom - MS_SYMBOL_SIZE / 4,
    Center.x + MS_SYMBOL_SIZE / 2, rectBaseLP.bottom - MS_SYMBOL_SIZE / 2 - MS_SYMBOL_SIZE / 4,
    Center.x - MS_SYMBOL_SIZE / 2, rectBaseLP.bottom - MS_SYMBOL_SIZE / 4,
    Center.x + MS_SYMBOL_SIZE / 2, rectBaseLP.bottom - MS_SYMBOL_SIZE / 4));
  return retArr;
}

const doDxfOutSymbolPowerBoard = (CDXFFile1: any, pPowerBoard: any) => {
  let retArr: any[] = [];
  let rectBaseLP = getRectBaseLP(pPowerBoard);
  let rectCenter = {...rectBaseLP};
  let Center = getCenterPoint(rectCenter);
  
  Center.y += MS_SYMBOL_SIZE / 5;

  retArr = retArr.concat(LINE(CDXFFile1, Center.x, rectBaseLP.top - 1, Center.x, rectBaseLP.bottom - MS_SYMBOL_SIZE / 2 - MS_SYMBOL_SIZE / 4));

  retArr = retArr.concat(LINE(CDXFFile1, Center.x - MS_SYMBOL_SIZE / 2, rectBaseLP.bottom - MS_SYMBOL_SIZE / 4, Center.x + MS_SYMBOL_SIZE / 2, rectBaseLP.bottom - MS_SYMBOL_SIZE / 4));
  retArr = retArr.concat(LINE(CDXFFile1, Center.x + MS_SYMBOL_SIZE / 2, rectBaseLP.bottom - MS_SYMBOL_SIZE / 4, Center.x + MS_SYMBOL_SIZE / 2, rectBaseLP.bottom - MS_SYMBOL_SIZE / 2 - MS_SYMBOL_SIZE / 4));
  retArr = retArr.concat(LINE(CDXFFile1, Center.x + MS_SYMBOL_SIZE / 2, rectBaseLP.bottom - MS_SYMBOL_SIZE / 2 - MS_SYMBOL_SIZE / 4, Center.x - MS_SYMBOL_SIZE / 2, rectBaseLP.bottom - MS_SYMBOL_SIZE / 2 - MS_SYMBOL_SIZE / 4));
  retArr = retArr.concat(LINE(CDXFFile1, Center.x - MS_SYMBOL_SIZE / 2, rectBaseLP.bottom - MS_SYMBOL_SIZE / 2 - MS_SYMBOL_SIZE / 4, Center.x - MS_SYMBOL_SIZE / 2, rectBaseLP.bottom - MS_SYMBOL_SIZE / 4));

  retArr = retArr.concat(SOLID(CDXFFile1, 
    Center.x - MS_SYMBOL_SIZE / 2, rectBaseLP.bottom - MS_SYMBOL_SIZE / 4,
    Center.x - MS_SYMBOL_SIZE / 2, rectBaseLP.bottom - MS_SYMBOL_SIZE / 2 - MS_SYMBOL_SIZE / 4,
    Center.x, rectBaseLP.bottom - MS_SYMBOL_SIZE / 4 - MS_SYMBOL_SIZE / 4,
    Center.x - MS_SYMBOL_SIZE / 2, rectBaseLP.bottom - MS_SYMBOL_SIZE / 4));
  
  retArr = retArr.concat(SOLID(CDXFFile1, 
    Center.x + MS_SYMBOL_SIZE / 2, rectBaseLP.bottom - MS_SYMBOL_SIZE / 4,
    Center.x, rectBaseLP.bottom - MS_SYMBOL_SIZE / 4 - MS_SYMBOL_SIZE / 4,
    Center.x + MS_SYMBOL_SIZE / 2, rectBaseLP.bottom - MS_SYMBOL_SIZE / 2 - MS_SYMBOL_SIZE / 4,
    Center.x + MS_SYMBOL_SIZE / 2, rectBaseLP.bottom - MS_SYMBOL_SIZE / 4));

  return retArr;
}

const doDxfOutAllRefV1s = (
  CDXFFile1: any,
  pRefV1List: any[],
  sRefName: string,
  sRefV1: string[]
) =>{
  let retArr: any[] = [];
  pRefV1List.forEach((pObjRefV1: any) => {
    retArr = retArr.concat(doDxfOutAllRefV1(CDXFFile1, pObjRefV1, sRefName, sRefV1));
  })
  return retArr;
}

const doDxfOutAllRefV1 = (
  CDXFFile1: any,
  pRefV1: any,
  sRefName: string,
  sRefV1: string[]
) =>{
  let retArr: any[] = [];
  let dHight: number = Math.abs(pRefV1.m_LogFont.lfHeight) / 1.8;

  let lLeft: number = pRefV1.m_rectLeftLP.left;
  let lTop: number = pRefV1.m_rectLeftLP.top + Math.floor(dHight);

  CDXFFile1.m_nLayerNo = DXF_LAYER_REFNAME;
  retArr = retArr.concat(STRING(CDXFFile1, lLeft, lTop, Math.floor(dHight), sRefName));

  CDXFFile1.m_nLayerNo = DXF_LAYER_REFV1;
  lLeft = pRefV1.m_rectRightLP.left;
  lTop = pRefV1.m_rectRightLP.top + Math.floor(dHight);
  let sRemain = [...sRefV1]
  if (sRemain.length > 0) {
    sRemain.forEach((sOneLine: string) => {
      sOneLine = sOneLine.trim()
      if (sOneLine.length > 0){
        retArr = retArr.concat(STRING(CDXFFile1, lLeft, lTop, Math.floor(dHight), sOneLine));
      }
      lTop += 20;
    })
  }

  return retArr;
}

const doDxfOutTextBox = (CDXFFile1: any, pText: any) => {
  let retArr: any[] = [];
  let dHight = Math.abs(pText.m_LogFont.lfHeight) / 1.1;

  let rectBaseLP = getRectBaseLP(pText);
  let lLeft = rectBaseLP.left;
  let lTop = rectBaseLP.top + (dHight / 2);
  
  let sRemain = pText.properties.text.split("\n");
  if (sRemain.length > 0) {
    sRemain.forEach((sOneLine: string) => {
      sOneLine = sOneLine.trim();
      if (sOneLine.length > 0){
        retArr = retArr.concat(STRING(CDXFFile1, lLeft, lTop, Math.floor(dHight), sOneLine));
      }
      lTop += (dHight + dHight / 8.0);
    })
  }

  return retArr;
}

const doDxfOutFlowResult = (CDXFFile1: any, pRefV1: any, sFlowResult: string[]) => {
  let retArr: any[] = [];
  let dHight = Math.abs(pRefV1.m_LogFont.lfHeight) / 2.0;

  CDXFFile1.m_nLayerNo = DXF_LAYER_REFV1;
  let lLeft = pRefV1.m_rectRightLP.left + pRefV1.m_LogFont.lfHeight;
  let lTop = pRefV1.m_rectRightLP.bottom - Math.floor(dHight);
  let sRemain = [...sFlowResult];
  if (sRemain.length > 0) {
    sRemain.forEach((sOneLine: string) => {
      sOneLine = sOneLine.trim()
      if (sOneLine.length > 0){
        retArr = retArr.concat(STRING(CDXFFile1, lLeft, lTop, Math.floor(dHight), sOneLine, 90));
      }
      lLeft += 40;
    })
  }

  return retArr;
}

const doDxfOutFlowArrow = (CDXFFile1: any, pElement: any, nDirect: number = DIRECT_NONE) => {
  let retArr: any[] = [];
  let rectFlowLP = getRectTotal(0, 0, 0, 0);

  switch(pElement.type) {
  case EKind.MS_SOURCE:
    {
      let pSource = {...pElement};
      if (nDirect == DIRECT_NONE) {
        rectFlowLP = pSource.m_RefV1FlowResult.m_rectRightLP;
        if (pSource.properties.activePower >= 0) {
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pSource.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pSource.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pSource.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pSource.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5, rectFlowLP.bottom - 8));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pSource.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pSource.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5, rectFlowLP.bottom - 8));
        }
        else {
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pSource.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pSource.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pSource.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pSource.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5, rectFlowLP.top + 8));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pSource.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pSource.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5, rectFlowLP.top + 8));
        }

        if (pSource.properties.reactivePower >= 0) {
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pSource.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pSource.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pSource.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pSource.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5, rectFlowLP.bottom - 8));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pSource.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pSource.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5, rectFlowLP.bottom - 8));
        }
        else {
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pSource.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pSource.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pSource.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pSource.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5, rectFlowLP.top + 8));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pSource.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pSource.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5, rectFlowLP.top + 8));
        }
      }
      else if (nDirect == BOTTOM_CENTER || nDirect == RIGHT_CENTER) {
        rectFlowLP = pSource.m_RefV1FlowResultBottom.m_rectRightLP;
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 - 5, rectFlowLP.top + 8));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 + 5, rectFlowLP.top + 8));
      }
    }
    break;
  case EKind.MS_GENERATOR:
    {
      let pGenerator = {...pElement};
      if (nDirect == DIRECT_NONE) {
        rectFlowLP = pGenerator.m_RefV1FlowResult.m_rectRightLP;
        if (pGenerator.properties.activePower >= 0) {
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pGenerator.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pGenerator.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pGenerator.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pGenerator.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5, rectFlowLP.bottom - 8));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pGenerator.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pGenerator.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5, rectFlowLP.bottom - 8));
        }
        else {
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pGenerator.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pGenerator.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pGenerator.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pGenerator.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5, rectFlowLP.top + 8));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pGenerator.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pGenerator.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5, rectFlowLP.top + 8));
        }

        if (pGenerator.properties.reactivePower >= 0) {
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pGenerator.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pGenerator.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pGenerator.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pGenerator.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5, rectFlowLP.bottom - 8));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pGenerator.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pGenerator.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5, rectFlowLP.bottom - 8));
        }
        else {
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pGenerator.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pGenerator.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pGenerator.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pGenerator.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5, rectFlowLP.top + 8));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pGenerator.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pGenerator.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5, rectFlowLP.top + 8));
        }
      }
      else if (nDirect == BOTTOM_CENTER || nDirect == RIGHT_CENTER) {
        rectFlowLP = pGenerator.m_RefV1FlowResultBottom.m_rectRightLP;
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 - 5, rectFlowLP.top + 8));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 + 5, rectFlowLP.top + 8));
      }
    }
    break;
  case EKind.MS_TRANS3:
    {
      let pTrans3 = {...pElement};
      if (nDirect == DIRECT_NONE) {
        rectFlowLP = pTrans3.m_RefV1FlowResult.m_rectRightLP;
        if (pTrans3.properties.lineActiveFlow1 >= 0) {
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pTrans3.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pTrans3.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pTrans3.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pTrans3.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5, rectFlowLP.bottom - 8));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pTrans3.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pTrans3.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5, rectFlowLP.bottom - 8));
        }
        else if (pTrans3.properties.lineActiveFlow2 > 0) {
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pTrans3.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pTrans3.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pTrans3.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pTrans3.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5, rectFlowLP.top + 8));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pTrans3.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pTrans3.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5, rectFlowLP.top + 8));
        }

        if (pTrans3.properties.lineReactiveFlow1 >= 0) {
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pTrans3.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pTrans3.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pTrans3.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pTrans3.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5, rectFlowLP.bottom - 8));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pTrans3.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pTrans3.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5, rectFlowLP.bottom - 8));
        }
        else if (pTrans3.properties.lineReactiveFlow2 > 0) {
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pTrans3.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pTrans3.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pTrans3.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pTrans3.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5, rectFlowLP.top + 8));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pTrans3.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pTrans3.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5, rectFlowLP.top + 8));
        }
      }
      else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
        rectFlowLP = pTrans3.m_RefV1FlowResultTop.m_rectRightLP;
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 - 5, rectFlowLP.bottom - 8));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 + 5, rectFlowLP.bottom - 8));
      }
      else if (nDirect == BOTTOM_CENTER || nDirect == RIGHT_CENTER) {
        rectFlowLP = pTrans3.m_RefV1FlowResultBottom.m_rectRightLP;
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 - 5, rectFlowLP.top + 8));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 + 5, rectFlowLP.top + 8));
      }
    }
    break;
  case EKind.MS_TRANSCENTER:
    {
      let pTransCenter = {...pElement};
      if (nDirect == DIRECT_NONE) {
        rectFlowLP = pTransCenter.m_RefV1FlowResult.m_rectRightLP;
        if (pTransCenter.properties.activePower >= 0) {
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pTransCenter.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pTransCenter.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pTransCenter.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pTransCenter.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5, rectFlowLP.bottom - 8));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pTransCenter.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pTransCenter.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5, rectFlowLP.bottom - 8));
        }
        else {
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pTransCenter.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pTransCenter.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pTransCenter.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pTransCenter.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5, rectFlowLP.top + 8));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pTransCenter.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pTransCenter.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5, rectFlowLP.top + 8));
        }
        if (pTransCenter.properties.reactivePower >= 0) {
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pTransCenter.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pTransCenter.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pTransCenter.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pTransCenter.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5, rectFlowLP.bottom - 8));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pTransCenter.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pTransCenter.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5, rectFlowLP.bottom - 8));
        }
        else {
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pTransCenter.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pTransCenter.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pTransCenter.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pTransCenter.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5, rectFlowLP.top + 8));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pTransCenter.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pTransCenter.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5, rectFlowLP.top + 8));
        }
      }
      else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
        rectFlowLP = pTransCenter.m_RefV1FlowResultTop.m_rectRightLP;
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 - 5, rectFlowLP.bottom + 8));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 + 5, rectFlowLP.bottom + 8));
      }
    }
    break;
  case EKind.MS_3WINDING:
    {
      let p3Winding = {...pElement};
      if (nDirect == DIRECT_NONE) {
        rectFlowLP = p3Winding.properties.partPrimary.m_RefV1FlowResult.m_rectRightLP;
        if (p3Winding.properties.partPrimary.lineActiveFlow1 >= 0) {
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + p3Winding.properties.partPrimary.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + p3Winding.properties.partPrimary.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + p3Winding.properties.partPrimary.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + p3Winding.properties.partPrimary.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5, rectFlowLP.bottom - 8));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + p3Winding.properties.partPrimary.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + p3Winding.properties.partPrimary.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5, rectFlowLP.bottom - 8));
        }
        else if (p3Winding.properties.partPrimary.lineActiveFlow2 > 0) {
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + p3Winding.properties.partPrimary.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + p3Winding.properties.partPrimary.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + p3Winding.properties.partPrimary.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + p3Winding.properties.partPrimary.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5, rectFlowLP.top + 8));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + p3Winding.properties.partPrimary.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + p3Winding.properties.partPrimary.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5, rectFlowLP.top + 8));
        }
        if (p3Winding.properties.partPrimary.lineReactiveFlow1 >= 0) {
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + p3Winding.properties.partPrimary.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + p3Winding.properties.partPrimary.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + p3Winding.properties.partPrimary.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + p3Winding.properties.partPrimary.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5, rectFlowLP.bottom - 8));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + p3Winding.properties.partPrimary.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + p3Winding.properties.partPrimary.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5, rectFlowLP.bottom - 8));
        }
        else if (p3Winding.properties.partPrimary.lineReactiveFlow2 > 0) {
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + p3Winding.properties.partPrimary.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + p3Winding.properties.partPrimary.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + p3Winding.properties.partPrimary.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + p3Winding.properties.partPrimary.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5, rectFlowLP.top + 8));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + p3Winding.properties.partPrimary.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + p3Winding.properties.partPrimary.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5, rectFlowLP.top + 8));
        }

        rectFlowLP = p3Winding.properties.partSecondary.m_RefV1FlowResult.m_rectRightLP;
        if (p3Winding.properties.partSecondary.lineActiveFlow1 >= 0) {
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + p3Winding.properties.partSecondary.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + p3Winding.properties.partSecondary.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + p3Winding.properties.partSecondary.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + p3Winding.properties.partSecondary.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5, rectFlowLP.bottom - 8));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + p3Winding.properties.partSecondary.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + p3Winding.properties.partSecondary.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5, rectFlowLP.bottom - 8));
        }
        else if (p3Winding.properties.partSecondary.lineActiveFlow2 > 0) {
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + p3Winding.properties.partSecondary.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + p3Winding.properties.partSecondary.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + p3Winding.properties.partSecondary.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + p3Winding.properties.partSecondary.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5, rectFlowLP.top + 8));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + p3Winding.properties.partSecondary.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + p3Winding.properties.partSecondary.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5, rectFlowLP.top + 8));
        }
        if (p3Winding.properties.partSecondary.lineReactiveFlow1 >= 0) {
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + p3Winding.properties.partSecondary.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + p3Winding.properties.partSecondary.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + p3Winding.properties.partSecondary.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + p3Winding.properties.partSecondary.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5, rectFlowLP.bottom - 8));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + p3Winding.properties.partSecondary.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + p3Winding.properties.partSecondary.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5, rectFlowLP.bottom - 8));
        }
        else if (p3Winding.properties.partSecondary.lineReactiveFlow2 > 0) {
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + p3Winding.properties.partSecondary.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + p3Winding.properties.partSecondary.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + p3Winding.properties.partSecondary.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + p3Winding.properties.partSecondary.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5, rectFlowLP.top + 8));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + p3Winding.properties.partSecondary.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + p3Winding.properties.partSecondary.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5, rectFlowLP.top + 8));
        }

        rectFlowLP = p3Winding.properties.partThird.m_RefV1FlowResult.m_rectRightLP;
        if (p3Winding.properties.partThird.lineActiveFlow1 >= 0) {
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + p3Winding.properties.partThird.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + p3Winding.properties.partThird.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + p3Winding.properties.partThird.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + p3Winding.properties.partThird.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5, rectFlowLP.bottom - 8));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + p3Winding.properties.partThird.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + p3Winding.properties.partThird.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5, rectFlowLP.bottom - 8));
        }
        else if (p3Winding.properties.partThird.lineActiveFlow2 > 0) {
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + p3Winding.properties.partThird.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + p3Winding.properties.partThird.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + p3Winding.properties.partThird.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + p3Winding.properties.partThird.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5, rectFlowLP.top + 8));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + p3Winding.properties.partThird.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + p3Winding.properties.partThird.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5, rectFlowLP.top + 8));
        }
        if (p3Winding.properties.partThird.lineReactiveFlow1 >= 0) {
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + p3Winding.properties.partThird.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + p3Winding.properties.partThird.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + p3Winding.properties.partThird.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + p3Winding.properties.partThird.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5, rectFlowLP.bottom - 8));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + p3Winding.properties.partThird.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + p3Winding.properties.partThird.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5, rectFlowLP.bottom - 8));
        }
        else if (p3Winding.properties.partThird.lineReactiveFlow2 > 0) {
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + p3Winding.properties.partThird.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + p3Winding.properties.partThird.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + p3Winding.properties.partThird.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + p3Winding.properties.partThird.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5, rectFlowLP.top + 8));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + p3Winding.properties.partThird.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + p3Winding.properties.partThird.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5, rectFlowLP.top + 8));
        }
      }
      else if (nDirect == TOP_CENTER) {
        rectFlowLP = p3Winding.properties.partPrimary.m_RefV1FlowResultTop.m_rectRightLP;
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 - 5, rectFlowLP.bottom - 8));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 + 5, rectFlowLP.bottom - 8));
      }
      else if (nDirect == RIGHT_CENTER) {
        rectFlowLP = p3Winding.properties.partSecondary.m_RefV1FlowResultTop.m_rectRightLP;
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 - 5, rectFlowLP.top + 8));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 + 5, rectFlowLP.top + 8));
      }
      else if (nDirect == LEFT_CENTER) {
        rectFlowLP = p3Winding.properties.partThird.m_RefV1FlowResultTop.m_rectRightLP;
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right + MS_FRAME_WIDTH / 4 + 2, rectFlowLP.bottom, rectFlowLP.right + MS_FRAME_WIDTH / 4 + 2, rectFlowLP.top));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right + MS_FRAME_WIDTH / 4 + 2, rectFlowLP.top, rectFlowLP.right + MS_FRAME_WIDTH / 4 + 2 - 5, rectFlowLP.top + 8));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right + MS_FRAME_WIDTH / 4 + 2, rectFlowLP.top, rectFlowLP.right + MS_FRAME_WIDTH / 4 + 2 + 5, rectFlowLP.top + 8));
      }
    }
    break;
  case EKind.MS_TRANS1:
    {
      let pTrans1 = {...pElement};
      if (nDirect == DIRECT_NONE) {
        rectFlowLP = pTrans1.m_RefV1FlowResult.m_rectRightLP;
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pTrans1.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pTrans1.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pTrans1.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pTrans1.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5, rectFlowLP.bottom - 8));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pTrans1.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pTrans1.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5, rectFlowLP.bottom - 8));

        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pTrans1.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pTrans1.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pTrans1.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pTrans1.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5, rectFlowLP.bottom - 8));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pTrans1.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pTrans1.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5, rectFlowLP.bottom - 8));
      }
      else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
        rectFlowLP = pTrans1.m_RefV1FlowResultTop.m_rectRightLP;
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 - 5, rectFlowLP.bottom - 8));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 + 5, rectFlowLP.bottom - 8));
      }
    }
    break;
  case EKind.MS_TRANSSCOTT:
    {
      let pTransScott = {...pElement};
      if (nDirect == DIRECT_NONE) {
        rectFlowLP = pTransScott.m_RefV1FlowResult.m_rectRightLP;
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pTransScott.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pTransScott.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pTransScott.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pTransScott.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5, rectFlowLP.bottom - 8));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pTransScott.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pTransScott.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5, rectFlowLP.bottom - 8));

        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pTransScott.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pTransScott.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pTransScott.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pTransScott.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5, rectFlowLP.bottom - 8));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pTransScott.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pTransScott.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5, rectFlowLP.bottom - 8));
      }
      else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
        rectFlowLP = pTransScott.m_RefV1FlowResultTop.m_rectRightLP;
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 - 5, rectFlowLP.bottom - 8));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 + 5, rectFlowLP.bottom - 8));
      }
    }
    break;
  case EKind.MS_DS:
    {
      let pDS = {...pElement};
      if (nDirect == DIRECT_NONE) {
        rectFlowLP = pDS.m_RefV1FlowResult.m_rectRightLP;
        if (pDS.rotation == MS_ROTATE_NORMAL) {
          if (pDS.properties.lineActiveFlow1 >= 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pDS.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pDS.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pDS.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pDS.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5, rectFlowLP.bottom - 8));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pDS.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pDS.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5, rectFlowLP.bottom - 8));
          }
          else if (pDS.properties.lineActiveFlow2 > 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pDS.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pDS.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pDS.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pDS.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5, rectFlowLP.top + 8));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pDS.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pDS.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5, rectFlowLP.top + 8));
          }
          if (pDS.properties.lineReactiveFlow1 >= 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pDS.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pDS.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pDS.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pDS.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5, rectFlowLP.bottom - 8));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pDS.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pDS.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5, rectFlowLP.bottom - 8));
          }
          else if (pDS.properties.lineReactiveFlow2 > 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pDS.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pDS.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pDS.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pDS.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5, rectFlowLP.top + 8));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pDS.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pDS.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5, rectFlowLP.top + 8));
          }
        }
        else {
          if (pDS.properties.lineActiveFlow1 >= 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left, rectFlowLP.top + pDS.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.right, rectFlowLP.top + pDS.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right, rectFlowLP.top + pDS.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.right - 8, rectFlowLP.top + pDS.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right, rectFlowLP.top + pDS.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.right - 8, rectFlowLP.top + pDS.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5));
          }
          else if (pDS.properties.lineActiveFlow2 > 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right, rectFlowLP.top + pDS.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.left, rectFlowLP.top + pDS.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left, rectFlowLP.top + pDS.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.left + 8, rectFlowLP.top + pDS.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left, rectFlowLP.top + pDS.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.left + 8, rectFlowLP.top + pDS.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5));
          }
          if (pDS.properties.lineReactiveFlow1 >= 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left, rectFlowLP.top + pDS.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.right, rectFlowLP.top + pDS.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right, rectFlowLP.top + pDS.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.right - 8, rectFlowLP.top + pDS.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right, rectFlowLP.top + pDS.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.right - 8, rectFlowLP.top + pDS.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5));
          }
          else if (pDS.properties.lineReactiveFlow2 > 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right, rectFlowLP.top + pDS.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.left, rectFlowLP.top + pDS.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left, rectFlowLP.top + pDS.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.left + 8, rectFlowLP.top + pDS.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left, rectFlowLP.top + pDS.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.left + 8, rectFlowLP.top + pDS.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5));
          }
        }
      }
      else if (nDirect == TOP_CENTER) {
        rectFlowLP = pDS.m_RefV1FlowResultTop.m_rectRightLP;
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 - 5, rectFlowLP.bottom - 8));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 + 5, rectFlowLP.bottom - 8));
      }
      else if (nDirect == LEFT_CENTER) {
        rectFlowLP = pDS.m_RefV1FlowResultTop.m_rectRightLP;
        let center = getCenterPoint(rectFlowLP);
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH, center.y, rectFlowLP.left - MS_DIF_WIDTH * 3, center.y));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_DIF_WIDTH * 3, center.y, rectFlowLP.left - MS_DIF_WIDTH * 3 - 8, center.y - 5));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_DIF_WIDTH * 3, center.y, rectFlowLP.left - MS_DIF_WIDTH * 3 - 8, center.y + 5));
      }
      else if (nDirect == BOTTOM_CENTER) {
        rectFlowLP = pDS.m_RefV1FlowResultBottom.m_rectRightLP;
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 - 5, rectFlowLP.top + 8));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 + 5, rectFlowLP.top + 8));
      }
      else if (nDirect == RIGHT_CENTER) {
        rectFlowLP = pDS.m_RefV1FlowResultBottom.m_rectRightLP;
        let center = getCenterPoint(rectFlowLP);
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right + MS_FRAME_WIDTH, center.y, rectFlowLP.right + MS_DIF_WIDTH * 3, center.y));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right + MS_DIF_WIDTH * 3, center.y, rectFlowLP.right + MS_DIF_WIDTH * 3 + 8, center.y - 5));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right + MS_DIF_WIDTH * 3, center.y, rectFlowLP.right + MS_DIF_WIDTH * 3 + 8, center.y + 5));
      }
    }
    break;
  case EKind.MS_LBS:
    {
      let pLBS = {...pElement};
      if (nDirect == DIRECT_NONE) {
        rectFlowLP = pLBS.m_RefV1FlowResult.m_rectRightLP;
        if (pLBS.rotation == MS_ROTATE_NORMAL) {
          if (pLBS.properties.lineActiveFlow1 >= 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pLBS.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pLBS.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pLBS.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pLBS.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5, rectFlowLP.bottom - 8));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pLBS.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pLBS.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5, rectFlowLP.bottom - 8));
          }
          else if (pLBS.properties.lineActiveFlow2 > 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pLBS.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pLBS.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pLBS.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pLBS.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5, rectFlowLP.top + 8));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pLBS.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pLBS.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5, rectFlowLP.top + 8));
          }
          if (pLBS.properties.lineReactiveFlow1 >= 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pLBS.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pLBS.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pLBS.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pLBS.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5, rectFlowLP.bottom - 8));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pLBS.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pLBS.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5, rectFlowLP.bottom - 8));
          }
          else if (pLBS.properties.lineReactiveFlow2 > 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pLBS.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pLBS.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pLBS.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pLBS.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5, rectFlowLP.top + 8));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pLBS.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pLBS.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5, rectFlowLP.top + 8));
          }
        }
        else {
          if (pLBS.properties.lineActiveFlow1 >= 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left, rectFlowLP.top + pLBS.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.right, rectFlowLP.top + pLBS.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right, rectFlowLP.top + pLBS.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.right - 8, rectFlowLP.top + pLBS.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right, rectFlowLP.top + pLBS.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.right - 8, rectFlowLP.top + pLBS.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5));
          }
          else if (pLBS.properties.lineActiveFlow2 > 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right, rectFlowLP.top + pLBS.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.left, rectFlowLP.top + pLBS.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left, rectFlowLP.top + pLBS.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.left + 8, rectFlowLP.top + pLBS.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left, rectFlowLP.top + pLBS.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.left + 8, rectFlowLP.top + pLBS.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5));
          }
          if (pLBS.properties.lineReactiveFlow1 >= 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left, rectFlowLP.top + pLBS.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.right, rectFlowLP.top + pLBS.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right, rectFlowLP.top + pLBS.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.right - 8, rectFlowLP.top + pLBS.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right, rectFlowLP.top + pLBS.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.right - 8, rectFlowLP.top + pLBS.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5));
          }
          else if (pLBS.properties.lineReactiveFlow2 > 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right, rectFlowLP.top + pLBS.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.left, rectFlowLP.top + pLBS.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left, rectFlowLP.top + pLBS.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.left + 8, rectFlowLP.top + pLBS.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left, rectFlowLP.top + pLBS.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.left + 8, rectFlowLP.top + pLBS.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5));
          }
        }
      }
      else if (nDirect == TOP_CENTER) {
        rectFlowLP = pLBS.m_RefV1FlowResultTop.m_rectRightLP;
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 - 5, rectFlowLP.bottom - 8));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 + 5, rectFlowLP.bottom - 8));
      }
      else if (nDirect == LEFT_CENTER) {
        rectFlowLP = pLBS.m_RefV1FlowResultTop.m_rectRightLP;
        let center = getCenterPoint(rectFlowLP);
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH, center.y, rectFlowLP.left - MS_DIF_WIDTH * 3, center.y));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_DIF_WIDTH * 3, center.y, rectFlowLP.left - MS_DIF_WIDTH * 3 - 8, center.y - 5));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_DIF_WIDTH * 3, center.y, rectFlowLP.left - MS_DIF_WIDTH * 3 - 8, center.y + 5));
      }
      else if (nDirect == BOTTOM_CENTER) {
        rectFlowLP = pLBS.m_RefV1FlowResultBottom.m_rectRightLP;
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 - 5, rectFlowLP.top + 8));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 + 5, rectFlowLP.top + 8));
      }
      else if (nDirect == RIGHT_CENTER) {
        rectFlowLP = pLBS.m_RefV1FlowResultBottom.m_rectRightLP;
        let center = getCenterPoint(rectFlowLP);
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right + MS_FRAME_WIDTH, center.y, rectFlowLP.right + MS_DIF_WIDTH * 3, center.y));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right + MS_DIF_WIDTH * 3, center.y, rectFlowLP.right + MS_DIF_WIDTH * 3 + 8, center.y - 5));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right + MS_DIF_WIDTH * 3, center.y, rectFlowLP.right + MS_DIF_WIDTH * 3 + 8, center.y + 5));
      }
    }
    break;
  case EKind.MS_MVCB:
    {
      let pMVCB = {...pElement};
      if (nDirect == DIRECT_NONE) {
        rectFlowLP = pMVCB.m_RefV1FlowResult.m_rectRightLP;
        if (pMVCB.rotation == MS_ROTATE_NORMAL) {
          if (pMVCB.properties.lineActiveFlow1 >= 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pMVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pMVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pMVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pMVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5, rectFlowLP.bottom - 8));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pMVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pMVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5, rectFlowLP.bottom - 8));
          }
          else if (pMVCB.properties.lineActiveFlow2 > 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pMVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pMVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pMVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pMVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5, rectFlowLP.top + 8));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pMVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pMVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5, rectFlowLP.top + 8));
          }
          if (pMVCB.properties.lineReactiveFlow1 >= 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pMVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pMVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pMVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pMVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5, rectFlowLP.bottom - 8));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pMVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pMVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5, rectFlowLP.bottom - 8));
          }
          else if (pMVCB.properties.lineReactiveFlow2 > 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pMVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pMVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pMVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pMVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5, rectFlowLP.top + 8));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pMVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pMVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5, rectFlowLP.top + 8));
          }
        }
        else {
          if (pMVCB.properties.lineActiveFlow1 >= 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left, rectFlowLP.top + pMVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.right, rectFlowLP.top + pMVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right, rectFlowLP.top + pMVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.right - 8, rectFlowLP.top + pMVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right, rectFlowLP.top + pMVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.right - 8, rectFlowLP.top + pMVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5));
          }
          else if (pMVCB.properties.lineActiveFlow2 > 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right, rectFlowLP.top + pMVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.left, rectFlowLP.top + pMVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left, rectFlowLP.top + pMVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.left + 8, rectFlowLP.top + pMVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left, rectFlowLP.top + pMVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.left + 8, rectFlowLP.top + pMVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5));
          }
          if (pMVCB.properties.lineReactiveFlow1 >= 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left, rectFlowLP.top + pMVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.right, rectFlowLP.top + pMVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right, rectFlowLP.top + pMVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.right - 8, rectFlowLP.top + pMVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right, rectFlowLP.top + pMVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.right - 8, rectFlowLP.top + pMVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5));
          }
          else if (pMVCB.properties.lineReactiveFlow2 > 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right, rectFlowLP.top + pMVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.left, rectFlowLP.top + pMVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left, rectFlowLP.top + pMVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.left + 8, rectFlowLP.top + pMVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left, rectFlowLP.top + pMVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.left + 8, rectFlowLP.top + pMVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5));
          }
        }
      }
      else if (nDirect == TOP_CENTER) {
        rectFlowLP = pMVCB.m_RefV1FlowResultTop.m_rectRightLP;
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 - 5, rectFlowLP.bottom - 8));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 + 5, rectFlowLP.bottom - 8));
      }
      else if (nDirect == LEFT_CENTER) {
        rectFlowLP = pMVCB.m_RefV1FlowResultTop.m_rectRightLP;
        let center = getCenterPoint(rectFlowLP);
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH, center.y, rectFlowLP.left - MS_DIF_WIDTH * 3, center.y));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_DIF_WIDTH * 3, center.y, rectFlowLP.left - MS_DIF_WIDTH * 3 - 8, center.y - 5));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_DIF_WIDTH * 3, center.y, rectFlowLP.left - MS_DIF_WIDTH * 3 - 8, center.y + 5));
      }
      else if (nDirect == BOTTOM_CENTER) {
        rectFlowLP = pMVCB.m_RefV1FlowResultBottom.m_rectRightLP;
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 - 5, rectFlowLP.top + 8));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 + 5, rectFlowLP.top + 8));
      }
      else if (nDirect == RIGHT_CENTER) {
        rectFlowLP = pMVCB.m_RefV1FlowResultBottom.m_rectRightLP;
        let center = getCenterPoint(rectFlowLP);
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right + MS_FRAME_WIDTH, center.y, rectFlowLP.right + MS_DIF_WIDTH * 3, center.y));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right + MS_DIF_WIDTH * 3, center.y, rectFlowLP.right + MS_DIF_WIDTH * 3 + 8, center.y - 5));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right + MS_DIF_WIDTH * 3, center.y, rectFlowLP.right + MS_DIF_WIDTH * 3 + 8, center.y + 5));
      }
    }
    break;
  case EKind.MS_CONTACTOR:
    {
      let pContactor = {...pElement};
      if (nDirect == DIRECT_NONE) {
        rectFlowLP = pContactor.m_RefV1FlowResult.m_rectRightLP;
        if (pContactor.rotation == MS_ROTATE_NORMAL) {
          if (pContactor.properties.lineActiveFlow1 >= 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pContactor.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pContactor.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pContactor.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pContactor.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5, rectFlowLP.bottom - 8));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pContactor.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pContactor.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5, rectFlowLP.bottom - 8));
          }
          else if (pContactor.properties.lineActiveFlow2 > 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pContactor.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pContactor.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pContactor.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pContactor.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5, rectFlowLP.top + 8));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pContactor.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pContactor.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5, rectFlowLP.top + 8));
          }
          if (pContactor.properties.lineReactiveFlow1 >= 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pContactor.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pContactor.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pContactor.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pContactor.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5, rectFlowLP.bottom - 8));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pContactor.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pContactor.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5, rectFlowLP.bottom - 8));
          }
          else if (pContactor.properties.lineReactiveFlow2 > 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pContactor.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pContactor.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pContactor.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pContactor.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5, rectFlowLP.top + 8));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pContactor.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pContactor.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5, rectFlowLP.top + 8));
          }
        }
        else {
          if (pContactor.properties.lineActiveFlow1 >= 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left, rectFlowLP.top + pContactor.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.right, rectFlowLP.top + pContactor.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right, rectFlowLP.top + pContactor.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.right - 8, rectFlowLP.top + pContactor.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right, rectFlowLP.top + pContactor.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.right - 8, rectFlowLP.top + pContactor.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5));
          }
          else if (pContactor.properties.lineActiveFlow2 > 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right, rectFlowLP.top + pContactor.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.left, rectFlowLP.top + pContactor.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left, rectFlowLP.top + pContactor.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.left + 8, rectFlowLP.top + pContactor.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left, rectFlowLP.top + pContactor.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.left + 8, rectFlowLP.top + pContactor.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5));
          }
          if (pContactor.properties.lineReactiveFlow1 >= 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left, rectFlowLP.top + pContactor.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.right, rectFlowLP.top + pContactor.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right, rectFlowLP.top + pContactor.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.right - 8, rectFlowLP.top + pContactor.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right, rectFlowLP.top + pContactor.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.right - 8, rectFlowLP.top + pContactor.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5));
          }
          else if (pContactor.properties.lineReactiveFlow2 > 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right, rectFlowLP.top + pContactor.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.left, rectFlowLP.top + pContactor.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left, rectFlowLP.top + pContactor.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.left + 8, rectFlowLP.top + pContactor.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left, rectFlowLP.top + pContactor.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.left + 8, rectFlowLP.top + pContactor.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5));
          }
        }
      }
      else if (nDirect == TOP_CENTER) {
        rectFlowLP = pContactor.m_RefV1FlowResultTop.m_rectRightLP;
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 - 5, rectFlowLP.bottom - 8));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 + 5, rectFlowLP.bottom - 8));
      }
      else if (nDirect == LEFT_CENTER) {
        rectFlowLP = pContactor.m_RefV1FlowResultTop.m_rectRightLP;
        let center = getCenterPoint(rectFlowLP);
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH, center.y, rectFlowLP.left - MS_DIF_WIDTH * 3, center.y));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_DIF_WIDTH * 3, center.y, rectFlowLP.left - MS_DIF_WIDTH * 3 - 8, center.y - 5));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_DIF_WIDTH * 3, center.y, rectFlowLP.left - MS_DIF_WIDTH * 3 - 8, center.y + 5));
      }
      else if (nDirect == BOTTOM_CENTER) {
        rectFlowLP = pContactor.m_RefV1FlowResultBottom.m_rectRightLP;
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 - 5, rectFlowLP.top + 8));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 + 5, rectFlowLP.top + 8));
      }
      else if (nDirect == RIGHT_CENTER) {
        rectFlowLP = pContactor.m_RefV1FlowResultBottom.m_rectRightLP;
        let center = getCenterPoint(rectFlowLP);
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right + MS_FRAME_WIDTH, center.y, rectFlowLP.right + MS_DIF_WIDTH * 3, center.y));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right + MS_DIF_WIDTH * 3, center.y, rectFlowLP.right + MS_DIF_WIDTH * 3 + 8, center.y - 5));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right + MS_DIF_WIDTH * 3, center.y, rectFlowLP.right + MS_DIF_WIDTH * 3 + 8, center.y + 5));
      }
    }
    break;
  case EKind.MS_HVCB:
    {
      let pHVCB = {...pElement};
      if (nDirect == DIRECT_NONE) {
        rectFlowLP = pHVCB.m_RefV1FlowResult.m_rectRightLP;
        if (pHVCB.properties.lineActiveFlow1 >= 0) {
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pHVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pHVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pHVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pHVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5, rectFlowLP.bottom - 8));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pHVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pHVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5, rectFlowLP.bottom - 8));
        }
        else if (pHVCB.properties.lineActiveFlow2 > 0) {
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pHVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pHVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pHVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pHVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5, rectFlowLP.top + 8));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pHVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pHVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5, rectFlowLP.top + 8));
        }

        if (pHVCB.properties.lineReactiveFlow1 >= 0) {
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pHVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pHVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pHVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pHVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5, rectFlowLP.bottom - 8));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pHVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pHVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5, rectFlowLP.bottom - 8));
        }
        else if (pHVCB.properties.lineReactiveFlow2 > 0) {
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pHVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pHVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pHVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pHVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5, rectFlowLP.top + 8));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pHVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pHVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5, rectFlowLP.top + 8));
        }
      }
      else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
        rectFlowLP = pHVCB.m_RefV1FlowResultTop.m_rectRightLP;
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 - 5, rectFlowLP.bottom - 8));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 + 5, rectFlowLP.bottom - 8));
      }
      else if (nDirect == BOTTOM_CENTER || nDirect == RIGHT_CENTER) {
        rectFlowLP = pHVCB.m_RefV1FlowResultBottom.m_rectRightLP;
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 - 5, rectFlowLP.top + 8));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 + 5, rectFlowLP.top + 8));
      }
    }
    break;
  case EKind.MS_RELAY51:
    {
      let pRelay51 = {...pElement};
      if (nDirect == DIRECT_NONE) {
        rectFlowLP = pRelay51.m_RefV1FlowResult.m_rectRightLP;
        if (pRelay51.properties.lineActiveFlow1 >= 0) {
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pRelay51.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pRelay51.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pRelay51.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pRelay51.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5, rectFlowLP.bottom - 8));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pRelay51.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pRelay51.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5, rectFlowLP.bottom - 8));
        }
        else if (pRelay51.properties.lineActiveFlow2 > 0) {
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pRelay51.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pRelay51.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pRelay51.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pRelay51.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5, rectFlowLP.top + 8));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pRelay51.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pRelay51.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5, rectFlowLP.top + 8));
        }

        if (pRelay51.properties.lineReactiveFlow1 >= 0) {
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pRelay51.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pRelay51.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pRelay51.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pRelay51.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5, rectFlowLP.bottom - 8));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pRelay51.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pRelay51.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5, rectFlowLP.bottom - 8));
        }
        else if (pRelay51.properties.lineReactiveFlow2 > 0) {
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pRelay51.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pRelay51.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pRelay51.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pRelay51.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5, rectFlowLP.top + 8));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pRelay51.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pRelay51.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5, rectFlowLP.top + 8));
        }
      }
      else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
        rectFlowLP = pRelay51.m_RefV1FlowResultTop.m_rectRightLP;
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 - 5, rectFlowLP.bottom - 8));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 + 5, rectFlowLP.bottom - 8));
      }
      else if (nDirect == BOTTOM_CENTER || nDirect == RIGHT_CENTER) {
        rectFlowLP = pRelay51.m_RefV1FlowResultBottom.m_rectRightLP;
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 - 5, rectFlowLP.top + 8));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 + 5, rectFlowLP.top + 8));
      }
    }
    break;
  case EKind.MS_FUSE:
    {
      let pFuse = {...pElement};
      if (nDirect == DIRECT_NONE) {
        rectFlowLP = pFuse.m_RefV1FlowResult.m_rectRightLP;
        if (pFuse.rotation == MS_ROTATE_NORMAL) {
          if (pFuse.properties.lineActiveFlow1 >= 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pFuse.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pFuse.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pFuse.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pFuse.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5, rectFlowLP.bottom - 8));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pFuse.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pFuse.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5, rectFlowLP.bottom - 8));
          }
          else if (pFuse.properties.lineActiveFlow2 > 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pFuse.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pFuse.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pFuse.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pFuse.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5, rectFlowLP.top + 8));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pFuse.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pFuse.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5, rectFlowLP.top + 8));
          }
          if (pFuse.properties.lineReactiveFlow1 >= 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pFuse.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pFuse.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pFuse.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pFuse.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5, rectFlowLP.bottom - 8));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pFuse.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pFuse.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5, rectFlowLP.bottom - 8));
          }
          else if (pFuse.properties.lineReactiveFlow2 > 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pFuse.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pFuse.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pFuse.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pFuse.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5, rectFlowLP.top + 8));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pFuse.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pFuse.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5, rectFlowLP.top + 8));
          }
        }
        else {
          if (pFuse.properties.lineActiveFlow1 >= 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left, rectFlowLP.top + pFuse.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.right, rectFlowLP.top + pFuse.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right, rectFlowLP.top + pFuse.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.right - 8, rectFlowLP.top + pFuse.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right, rectFlowLP.top + pFuse.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.right - 8, rectFlowLP.top + pFuse.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5));
          }
          else if (pFuse.properties.lineActiveFlow2 > 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right, rectFlowLP.top + pFuse.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.left, rectFlowLP.top + pFuse.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left, rectFlowLP.top + pFuse.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.left + 8, rectFlowLP.top + pFuse.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left, rectFlowLP.top + pFuse.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.left + 8, rectFlowLP.top + pFuse.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5));
          }
          if (pFuse.properties.lineReactiveFlow1 >= 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left, rectFlowLP.top + pFuse.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.right, rectFlowLP.top + pFuse.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right, rectFlowLP.top + pFuse.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.right - 8, rectFlowLP.top + pFuse.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right, rectFlowLP.top + pFuse.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.right - 8, rectFlowLP.top + pFuse.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5));
          }
          else if (pFuse.properties.lineReactiveFlow2 > 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right, rectFlowLP.top + pFuse.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.left, rectFlowLP.top + pFuse.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left, rectFlowLP.top + pFuse.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.left + 8, rectFlowLP.top + pFuse.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left, rectFlowLP.top + pFuse.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.left + 8, rectFlowLP.top + pFuse.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5));
          }
        }
      }
      else if (nDirect == TOP_CENTER) {
        rectFlowLP = pFuse.m_RefV1FlowResultTop.m_rectRightLP;
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 - 5, rectFlowLP.bottom - 8));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 + 5, rectFlowLP.bottom - 8));
      }
      else if (nDirect == LEFT_CENTER) {
        rectFlowLP = pFuse.m_RefV1FlowResultTop.m_rectRightLP;
        let center = getCenterPoint(rectFlowLP);
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH, center.y, rectFlowLP.left - MS_DIF_WIDTH * 3, center.y));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_DIF_WIDTH * 3, center.y, rectFlowLP.left - MS_DIF_WIDTH * 3 - 8, center.y - 5));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_DIF_WIDTH * 3, center.y, rectFlowLP.left - MS_DIF_WIDTH * 3 - 8, center.y + 5));
      }
      else if (nDirect == BOTTOM_CENTER) {
        rectFlowLP = pFuse.m_RefV1FlowResultBottom.m_rectRightLP;
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 - 5, rectFlowLP.top + 8));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 + 5, rectFlowLP.top + 8));
      }
      else if (nDirect == RIGHT_CENTER) {
        rectFlowLP = pFuse.m_RefV1FlowResultBottom.m_rectRightLP;
        let center = getCenterPoint(rectFlowLP);
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right + MS_FRAME_WIDTH, center.y, rectFlowLP.right + MS_DIF_WIDTH * 3, center.y));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right + MS_DIF_WIDTH * 3, center.y, rectFlowLP.right + MS_DIF_WIDTH * 3 + 8, center.y - 5));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right + MS_DIF_WIDTH * 3, center.y, rectFlowLP.right + MS_DIF_WIDTH * 3 + 8, center.y + 5));
      }
    }
    break;
  case EKind.MS_2E:
    {
      let p2E = {...pElement};
      if (nDirect == DIRECT_NONE) {
        rectFlowLP = p2E.m_RefV1FlowResult.m_rectRightLP;
        if (p2E.properties.lineActiveFlow1 >= 0) {
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + p2E.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + p2E.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + p2E.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + p2E.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5, rectFlowLP.bottom - 8));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + p2E.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + p2E.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5, rectFlowLP.bottom - 8));
        }
        else if (p2E.properties.lineActiveFlow2 > 0) {
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + p2E.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + p2E.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + p2E.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + p2E.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5, rectFlowLP.top + 8));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + p2E.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + p2E.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5, rectFlowLP.top + 8));
        }

        if (p2E.properties.lineReactiveFlow1 >= 0) {
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + p2E.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + p2E.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + p2E.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + p2E.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5, rectFlowLP.bottom - 8));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + p2E.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + p2E.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5, rectFlowLP.bottom - 8));
        }
        else if (p2E.properties.lineReactiveFlow2 > 0) {
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + p2E.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + p2E.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + p2E.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + p2E.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5, rectFlowLP.top + 8));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + p2E.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + p2E.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5, rectFlowLP.top + 8));
        }
      }
      else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
        rectFlowLP = p2E.m_RefV1FlowResultTop.m_rectRightLP;
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 - 5, rectFlowLP.bottom - 8));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 + 5, rectFlowLP.bottom - 8));
      }
      else if (nDirect == BOTTOM_CENTER || nDirect == RIGHT_CENTER) {
        rectFlowLP = p2E.m_RefV1FlowResultBottom.m_rectRightLP;
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 - 5, rectFlowLP.top + 8));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 + 5, rectFlowLP.top + 8));
      }
    }
    break;
  case EKind.MS_ZEROCOND:
    break;
  case EKind.MS_CONNECT:
    break;
  case EKind.MS_WIRE:
    {
      let pWire = {...pElement};
      if (nDirect == DIRECT_NONE) {
        rectFlowLP = pWire.m_RefV1FlowResult.m_rectRightLP;
        if (pWire.rotation == MS_ROTATE_NORMAL) {
          if (pWire.properties.lineActiveFlow1 >= 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pWire.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pWire.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pWire.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pWire.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5, rectFlowLP.bottom - 8));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pWire.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pWire.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5, rectFlowLP.bottom - 8));
          }
          else if (pWire.properties.lineActiveFlow2 > 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pWire.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pWire.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pWire.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pWire.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5, rectFlowLP.top + 8));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pWire.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pWire.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5, rectFlowLP.top + 8));
          }
          if (pWire.properties.lineReactiveFlow1 >= 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pWire.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pWire.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pWire.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pWire.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5, rectFlowLP.bottom - 8));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pWire.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pWire.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5, rectFlowLP.bottom - 8));
          }
          else if (pWire.properties.lineReactiveFlow2 > 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pWire.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pWire.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pWire.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pWire.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5, rectFlowLP.top + 8));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pWire.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pWire.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5, rectFlowLP.top + 8));
          }
        }
        else {
          if (pWire.properties.lineActiveFlow1 >= 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left, rectFlowLP.top + pWire.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.right, rectFlowLP.top + pWire.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right, rectFlowLP.top + pWire.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.right - 8, rectFlowLP.top + pWire.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right, rectFlowLP.top + pWire.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.right - 8, rectFlowLP.top + pWire.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5));
          }
          else if (pWire.properties.lineActiveFlow2 > 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right, rectFlowLP.top + pWire.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.left, rectFlowLP.top + pWire.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left, rectFlowLP.top + pWire.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.left + 8, rectFlowLP.top + pWire.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left, rectFlowLP.top + pWire.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.left + 8, rectFlowLP.top + pWire.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5));
          }
          if (pWire.properties.lineReactiveFlow1 >= 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left, rectFlowLP.top + pWire.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.right, rectFlowLP.top + pWire.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right, rectFlowLP.top + pWire.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.right - 8, rectFlowLP.top + pWire.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right, rectFlowLP.top + pWire.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.right - 8, rectFlowLP.top + pWire.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5));
          }
          else if (pWire.properties.lineReactiveFlow2 > 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right, rectFlowLP.top + pWire.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.left, rectFlowLP.top + pWire.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left, rectFlowLP.top + pWire.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.left + 8, rectFlowLP.top + pWire.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left, rectFlowLP.top + pWire.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.left + 8, rectFlowLP.top + pWire.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5));
          }
        }
      }
      else if (nDirect == TOP_CENTER) {
        rectFlowLP = pWire.m_RefV1FlowResultTop.m_rectRightLP;
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 - 5, rectFlowLP.bottom - 8));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 + 5, rectFlowLP.bottom - 8));
      }
      else if (nDirect == LEFT_CENTER) {
        rectFlowLP = pWire.m_RefV1FlowResultTop.m_rectRightLP;
        let center = getCenterPoint(rectFlowLP);
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH, center.y, rectFlowLP.left - MS_DIF_WIDTH * 3, center.y));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_DIF_WIDTH * 3, center.y, rectFlowLP.left - MS_DIF_WIDTH * 3 - 8, center.y - 5));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_DIF_WIDTH * 3, center.y, rectFlowLP.left - MS_DIF_WIDTH * 3 - 8, center.y + 5));
      }
      else if (nDirect == BOTTOM_CENTER) {
        rectFlowLP = pWire.m_RefV1FlowResultBottom.m_rectRightLP;
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 - 5, rectFlowLP.top + 8));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 + 5, rectFlowLP.top + 8));
      }
      else if (nDirect == RIGHT_CENTER) {
        rectFlowLP = pWire.m_RefV1FlowResultBottom.m_rectRightLP;
        let center = getCenterPoint(rectFlowLP);
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right + MS_FRAME_WIDTH, center.y, rectFlowLP.right + MS_DIF_WIDTH * 3, center.y));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right + MS_DIF_WIDTH * 3, center.y, rectFlowLP.right + MS_DIF_WIDTH * 3 + 8, center.y - 5));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right + MS_DIF_WIDTH * 3, center.y, rectFlowLP.right + MS_DIF_WIDTH * 3 + 8, center.y + 5));
      }
    }
    break;
  case EKind.MS_BUSBAR:
    {
      let pBusBar = {...pElement};
      if (nDirect == DIRECT_NONE) {
        rectFlowLP = pBusBar.m_RefV1FlowResult.m_rectRightLP;
        if (pBusBar.rotation == MS_ROTATE_NORMAL) {
          if (pBusBar.properties.lineActiveFlow1 >= 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pBusBar.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pBusBar.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pBusBar.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pBusBar.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5, rectFlowLP.bottom - 8));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pBusBar.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pBusBar.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5, rectFlowLP.bottom - 8));
          }
          else if (pBusBar.properties.lineActiveFlow2 > 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pBusBar.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pBusBar.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pBusBar.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pBusBar.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5, rectFlowLP.top + 8));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pBusBar.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pBusBar.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5, rectFlowLP.top + 8));
          }
          if (pBusBar.properties.lineReactiveFlow1 >= 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pBusBar.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pBusBar.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pBusBar.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pBusBar.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5, rectFlowLP.bottom - 8));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pBusBar.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pBusBar.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5, rectFlowLP.bottom - 8));
          }
          else if (pBusBar.properties.lineReactiveFlow2 > 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pBusBar.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pBusBar.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pBusBar.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pBusBar.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5, rectFlowLP.top + 8));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pBusBar.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pBusBar.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5, rectFlowLP.top + 8));
          }
        }
        else {
          if (pBusBar.properties.lineActiveFlow1 >= 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left, rectFlowLP.top + pBusBar.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.right, rectFlowLP.top + pBusBar.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right, rectFlowLP.top + pBusBar.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.right - 8, rectFlowLP.top + pBusBar.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right, rectFlowLP.top + pBusBar.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.right - 8, rectFlowLP.top + pBusBar.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5));
          }
          else if (pBusBar.properties.lineActiveFlow2 > 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right, rectFlowLP.top + pBusBar.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.left, rectFlowLP.top + pBusBar.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left, rectFlowLP.top + pBusBar.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.left + 8, rectFlowLP.top + pBusBar.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left, rectFlowLP.top + pBusBar.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.left + 8, rectFlowLP.top + pBusBar.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5));
          }
          if (pBusBar.properties.lineReactiveFlow1 >= 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left, rectFlowLP.top + pBusBar.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.right, rectFlowLP.top + pBusBar.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right, rectFlowLP.top + pBusBar.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.right - 8, rectFlowLP.top + pBusBar.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right, rectFlowLP.top + pBusBar.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.right - 8, rectFlowLP.top + pBusBar.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5));
          }
          else if (pBusBar.properties.lineReactiveFlow2 > 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right, rectFlowLP.top + pBusBar.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.left, rectFlowLP.top + pBusBar.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left, rectFlowLP.top + pBusBar.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.left + 8, rectFlowLP.top + pBusBar.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left, rectFlowLP.top + pBusBar.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.left + 8, rectFlowLP.top + pBusBar.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5));
          }
        }
      }
      else if (nDirect == TOP_CENTER) {
        rectFlowLP = pBusBar.m_RefV1FlowResultTop.m_rectRightLP;
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 - 5, rectFlowLP.bottom - 8));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 + 5, rectFlowLP.bottom - 8));
      }
      else if (nDirect == LEFT_CENTER) {
        rectFlowLP = pBusBar.m_RefV1FlowResultTop.m_rectRightLP;
        let center = getCenterPoint(rectFlowLP);
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH, center.y, rectFlowLP.left - MS_DIF_WIDTH * 3, center.y));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_DIF_WIDTH * 3, center.y, rectFlowLP.left - MS_DIF_WIDTH * 3 - 8, center.y - 5));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_DIF_WIDTH * 3, center.y, rectFlowLP.left - MS_DIF_WIDTH * 3 - 8, center.y + 5));
      }
      else if (nDirect == BOTTOM_CENTER) {
        rectFlowLP = pBusBar.m_RefV1FlowResultBottom.m_rectRightLP;
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 - 5, rectFlowLP.top + 8));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 + 5, rectFlowLP.top + 8));
      }
      else if (nDirect == RIGHT_CENTER) {
        rectFlowLP = pBusBar.m_RefV1FlowResultBottom.m_rectRightLP;
        let center = getCenterPoint(rectFlowLP);
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right + MS_FRAME_WIDTH, center.y, rectFlowLP.right + MS_DIF_WIDTH * 3, center.y));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right + MS_DIF_WIDTH * 3, center.y, rectFlowLP.right + MS_DIF_WIDTH * 3 + 8, center.y - 5));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right + MS_DIF_WIDTH * 3, center.y, rectFlowLP.right + MS_DIF_WIDTH * 3 + 8, center.y + 5));
      }
    }
    break;
  case EKind.MS_IMPEDANCE:
    {
      let pImpedance = {...pElement};
      if (nDirect == DIRECT_NONE) {
        rectFlowLP = pImpedance.m_RefV1FlowResult.m_rectRightLP;
        if (pImpedance.rotation == MS_ROTATE_NORMAL) {
          if (pImpedance.properties.lineActiveFlow1 >= 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pImpedance.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pImpedance.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pImpedance.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pImpedance.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5, rectFlowLP.bottom - 8));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pImpedance.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pImpedance.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5, rectFlowLP.bottom - 8));
          }
          else if (pImpedance.properties.lineActiveFlow2 > 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pImpedance.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pImpedance.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pImpedance.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pImpedance.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5, rectFlowLP.top + 8));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pImpedance.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pImpedance.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5, rectFlowLP.top + 8));
          }
          if (pImpedance.properties.lineReactiveFlow1 >= 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pImpedance.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pImpedance.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pImpedance.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pImpedance.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5, rectFlowLP.bottom - 8));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pImpedance.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pImpedance.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5, rectFlowLP.bottom - 8));
          }
          else if (pImpedance.properties.lineReactiveFlow2 > 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pImpedance.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pImpedance.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pImpedance.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pImpedance.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5, rectFlowLP.top + 8));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pImpedance.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pImpedance.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5, rectFlowLP.top + 8));
          }
        }
        else {
          if (pImpedance.properties.lineActiveFlow1 >= 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left, rectFlowLP.top + pImpedance.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.right, rectFlowLP.top + pImpedance.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right, rectFlowLP.top + pImpedance.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.right - 8, rectFlowLP.top + pImpedance.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right, rectFlowLP.top + pImpedance.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.right - 8, rectFlowLP.top + pImpedance.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5));
          }
          else if (pImpedance.properties.lineActiveFlow2 > 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right, rectFlowLP.top + pImpedance.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.left, rectFlowLP.top + pImpedance.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left, rectFlowLP.top + pImpedance.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.left + 8, rectFlowLP.top + pImpedance.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left, rectFlowLP.top + pImpedance.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.left + 8, rectFlowLP.top + pImpedance.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5));
          }
          if (pImpedance.properties.lineReactiveFlow1 >= 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left, rectFlowLP.top + pImpedance.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.right, rectFlowLP.top + pImpedance.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right, rectFlowLP.top + pImpedance.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.right - 8, rectFlowLP.top + pImpedance.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right, rectFlowLP.top + pImpedance.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.right - 8, rectFlowLP.top + pImpedance.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5));
          }
          else if (pImpedance.properties.lineReactiveFlow2 > 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right, rectFlowLP.top + pImpedance.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.left, rectFlowLP.top + pImpedance.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left, rectFlowLP.top + pImpedance.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.left + 8, rectFlowLP.top + pImpedance.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left, rectFlowLP.top + pImpedance.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.left + 8, rectFlowLP.top + pImpedance.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5));
          }
        }
      }
      else if (nDirect == TOP_CENTER) {
        rectFlowLP = pImpedance.m_RefV1FlowResultTop.m_rectRightLP;
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 - 5, rectFlowLP.bottom - 8));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 + 5, rectFlowLP.bottom - 8));
      }
      else if (nDirect == LEFT_CENTER) {
        rectFlowLP = pImpedance.m_RefV1FlowResultTop.m_rectRightLP;
        let center = getCenterPoint(rectFlowLP);
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH, center.y, rectFlowLP.left - MS_DIF_WIDTH * 3, center.y));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_DIF_WIDTH * 3, center.y, rectFlowLP.left - MS_DIF_WIDTH * 3 - 8, center.y - 5));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_DIF_WIDTH * 3, center.y, rectFlowLP.left - MS_DIF_WIDTH * 3 - 8, center.y + 5));
      }
      else if (nDirect == BOTTOM_CENTER) {
        rectFlowLP = pImpedance.m_RefV1FlowResultBottom.m_rectRightLP;
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 - 5, rectFlowLP.top + 8));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 + 5, rectFlowLP.top + 8));
      }
      else if (nDirect == RIGHT_CENTER) {
        rectFlowLP = pImpedance.m_RefV1FlowResultBottom.m_rectRightLP;
        let center = getCenterPoint(rectFlowLP);
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right + MS_FRAME_WIDTH, center.y, rectFlowLP.right + MS_DIF_WIDTH * 3, center.y));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right + MS_DIF_WIDTH * 3, center.y, rectFlowLP.right + MS_DIF_WIDTH * 3 + 8, center.y - 5));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right + MS_DIF_WIDTH * 3, center.y, rectFlowLP.right + MS_DIF_WIDTH * 3 + 8, center.y + 5));
      }
    }
    break;
  case EKind.MS_BUSDUCT:
    {
      let pBusDuct = {...pElement};
      if (nDirect == DIRECT_NONE) {
        rectFlowLP = pBusDuct.m_RefV1FlowResult.m_rectRightLP;
        if (pBusDuct.rotation == MS_ROTATE_NORMAL) {
          if (pBusDuct.properties.lineActiveFlow1 >= 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pBusDuct.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pBusDuct.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pBusDuct.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pBusDuct.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5, rectFlowLP.bottom - 8));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pBusDuct.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pBusDuct.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5, rectFlowLP.bottom - 8));
          }
          else if (pBusDuct.properties.lineActiveFlow2 > 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pBusDuct.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pBusDuct.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pBusDuct.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pBusDuct.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5, rectFlowLP.top + 8));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pBusDuct.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pBusDuct.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5, rectFlowLP.top + 8));
          }
          if (pBusDuct.properties.lineReactiveFlow1 >= 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pBusDuct.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pBusDuct.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pBusDuct.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pBusDuct.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5, rectFlowLP.bottom - 8));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pBusDuct.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pBusDuct.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5, rectFlowLP.bottom - 8));
          }
          else if (pBusDuct.properties.lineReactiveFlow2 > 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pBusDuct.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pBusDuct.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pBusDuct.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pBusDuct.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5, rectFlowLP.top + 8));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pBusDuct.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pBusDuct.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5, rectFlowLP.top + 8));
          }
        }
        else {
          if (pBusDuct.properties.lineActiveFlow1 >= 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left, rectFlowLP.top + pBusDuct.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.right, rectFlowLP.top + pBusDuct.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right, rectFlowLP.top + pBusDuct.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.right - 8, rectFlowLP.top + pBusDuct.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right, rectFlowLP.top + pBusDuct.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.right - 8, rectFlowLP.top + pBusDuct.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5));
          }
          else if (pBusDuct.properties.lineActiveFlow2 > 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right, rectFlowLP.top + pBusDuct.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.left, rectFlowLP.top + pBusDuct.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left, rectFlowLP.top + pBusDuct.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.left + 8, rectFlowLP.top + pBusDuct.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left, rectFlowLP.top + pBusDuct.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.left + 8, rectFlowLP.top + pBusDuct.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5));
          }
          if (pBusDuct.properties.lineReactiveFlow1 >= 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left, rectFlowLP.top + pBusDuct.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.right, rectFlowLP.top + pBusDuct.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right, rectFlowLP.top + pBusDuct.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.right - 8, rectFlowLP.top + pBusDuct.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right, rectFlowLP.top + pBusDuct.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.right - 8, rectFlowLP.top + pBusDuct.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5));
          }
          else if (pBusDuct.properties.lineReactiveFlow2 > 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right, rectFlowLP.top + pBusDuct.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.left, rectFlowLP.top + pBusDuct.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left, rectFlowLP.top + pBusDuct.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.left + 8, rectFlowLP.top + pBusDuct.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left, rectFlowLP.top + pBusDuct.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.left + 8, rectFlowLP.top + pBusDuct.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5));
          }
        }
      }
      else if (nDirect == TOP_CENTER) {
        rectFlowLP = pBusDuct.m_RefV1FlowResultTop.m_rectRightLP;
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 - 5, rectFlowLP.bottom - 8));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 + 5, rectFlowLP.bottom - 8));
      }
      else if (nDirect == LEFT_CENTER) {
        rectFlowLP = pBusDuct.m_RefV1FlowResultTop.m_rectRightLP;
        let center = getCenterPoint(rectFlowLP);
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH, center.y, rectFlowLP.left - MS_DIF_WIDTH * 3, center.y));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_DIF_WIDTH * 3, center.y, rectFlowLP.left - MS_DIF_WIDTH * 3 - 8, center.y - 5));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_DIF_WIDTH * 3, center.y, rectFlowLP.left - MS_DIF_WIDTH * 3 - 8, center.y + 5));
      }
      else if (nDirect == BOTTOM_CENTER) {
        rectFlowLP = pBusDuct.m_RefV1FlowResultBottom.m_rectRightLP;
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 - 5, rectFlowLP.top + 8));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 + 5, rectFlowLP.top + 8));
      }
      else if (nDirect == RIGHT_CENTER) {
        rectFlowLP = pBusDuct.m_RefV1FlowResultBottom.m_rectRightLP;
        let center = getCenterPoint(rectFlowLP);
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right + MS_FRAME_WIDTH, center.y, rectFlowLP.right + MS_DIF_WIDTH * 3, center.y));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right + MS_DIF_WIDTH * 3, center.y, rectFlowLP.right + MS_DIF_WIDTH * 3 + 8, center.y - 5));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right + MS_DIF_WIDTH * 3, center.y, rectFlowLP.right + MS_DIF_WIDTH * 3 + 8, center.y + 5));
      }
    }
    break;
  case EKind.MS_REACTOR:
    {
      let pReactor = {...pElement};
      if (nDirect == DIRECT_NONE) {
        rectFlowLP = pReactor.m_RefV1FlowResult.m_rectRightLP;
        if (pReactor.rotation == MS_ROTATE_NORMAL) {
          if (pReactor.properties.lineActiveFlow1 >= 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pReactor.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pReactor.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pReactor.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pReactor.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5, rectFlowLP.bottom - 8));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pReactor.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pReactor.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5, rectFlowLP.bottom - 8));
          }
          else if (pReactor.properties.lineActiveFlow2 > 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pReactor.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pReactor.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pReactor.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pReactor.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5, rectFlowLP.top + 8));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pReactor.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pReactor.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5, rectFlowLP.top + 8));
          }
          if (pReactor.properties.lineReactiveFlow1 >= 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pReactor.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pReactor.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pReactor.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pReactor.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5, rectFlowLP.bottom - 8));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pReactor.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pReactor.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5, rectFlowLP.bottom - 8));
          }
          else if (pReactor.properties.lineReactiveFlow2 > 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pReactor.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pReactor.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pReactor.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pReactor.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5, rectFlowLP.top + 8));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pReactor.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pReactor.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5, rectFlowLP.top + 8));
          }
        }
        else {
          if (pReactor.properties.lineActiveFlow1 >= 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left, rectFlowLP.top + pReactor.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.right, rectFlowLP.top + pReactor.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right, rectFlowLP.top + pReactor.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.right - 8, rectFlowLP.top + pReactor.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right, rectFlowLP.top + pReactor.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.right - 8, rectFlowLP.top + pReactor.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5));
          }
          else if (pReactor.properties.lineActiveFlow2 > 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right, rectFlowLP.top + pReactor.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.left, rectFlowLP.top + pReactor.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left, rectFlowLP.top + pReactor.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.left + 8, rectFlowLP.top + pReactor.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left, rectFlowLP.top + pReactor.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.left + 8, rectFlowLP.top + pReactor.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5));
          }
          if (pReactor.properties.lineReactiveFlow1 >= 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left, rectFlowLP.top + pReactor.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.right, rectFlowLP.top + pReactor.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right, rectFlowLP.top + pReactor.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.right - 8, rectFlowLP.top + pReactor.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right, rectFlowLP.top + pReactor.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.right - 8, rectFlowLP.top + pReactor.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5));
          }
          else if (pReactor.properties.lineReactiveFlow2 > 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right, rectFlowLP.top + pReactor.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.left, rectFlowLP.top + pReactor.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left, rectFlowLP.top + pReactor.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.left + 8, rectFlowLP.top + pReactor.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left, rectFlowLP.top + pReactor.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.left + 8, rectFlowLP.top + pReactor.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5));
          }
        }
      }
      else if (nDirect == TOP_CENTER) {
        rectFlowLP = pReactor.m_RefV1FlowResultTop.m_rectRightLP;
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 - 5, rectFlowLP.bottom - 8));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 + 5, rectFlowLP.bottom - 8));
      }
      else if (nDirect == LEFT_CENTER) {
        rectFlowLP = pReactor.m_RefV1FlowResultTop.m_rectRightLP;
        let center = getCenterPoint(rectFlowLP);
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH, center.y, rectFlowLP.left - MS_DIF_WIDTH * 3, center.y));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_DIF_WIDTH * 3, center.y, rectFlowLP.left - MS_DIF_WIDTH * 3 - 8, center.y - 5));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_DIF_WIDTH * 3, center.y, rectFlowLP.left - MS_DIF_WIDTH * 3 - 8, center.y + 5));
      }
      else if (nDirect == BOTTOM_CENTER) {
        rectFlowLP = pReactor.m_RefV1FlowResultBottom.m_rectRightLP;
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 - 5, rectFlowLP.top + 8));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 + 5, rectFlowLP.top + 8));
      }
      else if (nDirect == RIGHT_CENTER) {
        rectFlowLP = pReactor.m_RefV1FlowResultBottom.m_rectRightLP;
        let center = getCenterPoint(rectFlowLP);
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right + MS_FRAME_WIDTH, center.y, rectFlowLP.right + MS_DIF_WIDTH * 3, center.y));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right + MS_DIF_WIDTH * 3, center.y, rectFlowLP.right + MS_DIF_WIDTH * 3 + 8, center.y - 5));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right + MS_DIF_WIDTH * 3, center.y, rectFlowLP.right + MS_DIF_WIDTH * 3 + 8, center.y + 5));
      }
    }
    break;
  case EKind.MS_LVCB:
    {
      let pLVCB = {...pElement};
      if (nDirect == DIRECT_NONE) {
        rectFlowLP = pLVCB.m_RefV1FlowResult.m_rectRightLP;
        if (pLVCB.rotation == MS_ROTATE_NORMAL) {
          if (pLVCB.properties.lineActiveFlow1 >= 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pLVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pLVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pLVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pLVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5, rectFlowLP.bottom - 8));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pLVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pLVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5, rectFlowLP.bottom - 8));
          }
          else if (pLVCB.properties.lineActiveFlow2 > 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pLVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pLVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pLVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pLVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5, rectFlowLP.top + 8));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pLVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pLVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5, rectFlowLP.top + 8));
          }
          if (pLVCB.properties.lineReactiveFlow1 >= 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pLVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pLVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pLVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pLVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5, rectFlowLP.bottom - 8));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pLVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pLVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5, rectFlowLP.bottom - 8));
          }
          else if (pLVCB.properties.lineReactiveFlow2 > 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pLVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pLVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pLVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pLVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5, rectFlowLP.top + 8));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pLVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pLVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5, rectFlowLP.top + 8));
          }
        }
        else {
          if (pLVCB.properties.lineActiveFlow1 >= 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left, rectFlowLP.top + pLVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.right, rectFlowLP.top + pLVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right, rectFlowLP.top + pLVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.right - 8, rectFlowLP.top + pLVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right, rectFlowLP.top + pLVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.right - 8, rectFlowLP.top + pLVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5));
          }
          else if (pLVCB.properties.lineActiveFlow2 > 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right, rectFlowLP.top + pLVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.left, rectFlowLP.top + pLVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left, rectFlowLP.top + pLVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.left + 8, rectFlowLP.top + pLVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left, rectFlowLP.top + pLVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.left + 8, rectFlowLP.top + pLVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5));
          }
          if (pLVCB.properties.lineReactiveFlow1 >= 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left, rectFlowLP.top + pLVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.right, rectFlowLP.top + pLVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right, rectFlowLP.top + pLVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.right - 8, rectFlowLP.top + pLVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right, rectFlowLP.top + pLVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.right - 8, rectFlowLP.top + pLVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5));
          }
          else if (pLVCB.properties.lineReactiveFlow2 > 0) {
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right, rectFlowLP.top + pLVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.left, rectFlowLP.top + pLVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left, rectFlowLP.top + pLVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.left + 8, rectFlowLP.top + pLVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5));
            retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left, rectFlowLP.top + pLVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.left + 8, rectFlowLP.top + pLVCB.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5));
          }
        }
      }
      else if (nDirect == TOP_CENTER) {
        rectFlowLP = pLVCB.m_RefV1FlowResultTop.m_rectRightLP;
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 - 5, rectFlowLP.bottom - 8));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 + 5, rectFlowLP.bottom - 8));
      }
      else if (nDirect == LEFT_CENTER) {
        rectFlowLP = pLVCB.m_RefV1FlowResultTop.m_rectRightLP;
        let center = getCenterPoint(rectFlowLP);
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH, center.y, rectFlowLP.left - MS_DIF_WIDTH * 3, center.y));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_DIF_WIDTH * 3, center.y, rectFlowLP.left - MS_DIF_WIDTH * 3 - 8, center.y - 5));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_DIF_WIDTH * 3, center.y, rectFlowLP.left - MS_DIF_WIDTH * 3 - 8, center.y + 5));
      }
      else if (nDirect == BOTTOM_CENTER) {
        rectFlowLP = pLVCB.m_RefV1FlowResultBottom.m_rectRightLP;
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 - 5, rectFlowLP.top + 8));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 + 5, rectFlowLP.top + 8));
      }
      else if (nDirect == RIGHT_CENTER) {
        rectFlowLP = pLVCB.m_RefV1FlowResultBottom.m_rectRightLP;
        let center = getCenterPoint(rectFlowLP);
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right + MS_FRAME_WIDTH, center.y, rectFlowLP.right + MS_DIF_WIDTH * 3, center.y));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right + MS_DIF_WIDTH * 3, center.y, rectFlowLP.right + MS_DIF_WIDTH * 3 + 8, center.y - 5));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.right + MS_DIF_WIDTH * 3, center.y, rectFlowLP.right + MS_DIF_WIDTH * 3 + 8, center.y + 5));
      }
    }
    break;
  case EKind.MS_THERMAL:
    {
      let pThermal = {...pElement};
      if (nDirect == DIRECT_NONE) {
        rectFlowLP = pThermal.m_RefV1FlowResult.m_rectRightLP;
        if (pThermal.properties.lineActiveFlow1 >= 0) {
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pThermal.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pThermal.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pThermal.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pThermal.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5, rectFlowLP.bottom - 8));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pThermal.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pThermal.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5, rectFlowLP.bottom - 8));
        }
        else if (pThermal.properties.lineActiveFlow2 > 0) {
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pThermal.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pThermal.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pThermal.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pThermal.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5, rectFlowLP.top + 8));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pThermal.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pThermal.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5, rectFlowLP.top + 8));
        }

        if (pThermal.properties.lineReactiveFlow1 >= 0) {
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pThermal.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pThermal.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pThermal.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pThermal.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5, rectFlowLP.bottom - 8));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pThermal.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pThermal.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5, rectFlowLP.bottom - 8));
        }
        else if (pThermal.properties.lineReactiveFlow2 > 0) {
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pThermal.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pThermal.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pThermal.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pThermal.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5, rectFlowLP.top + 8));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pThermal.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pThermal.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5, rectFlowLP.top + 8));
        }
      }
      else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
        rectFlowLP = pThermal.m_RefV1FlowResultTop.m_rectRightLP;
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 - 5, rectFlowLP.bottom - 8));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 + 5, rectFlowLP.bottom - 8));
      }
      else if (nDirect == BOTTOM_CENTER || nDirect == RIGHT_CENTER) {
        rectFlowLP = pThermal.m_RefV1FlowResultBottom.m_rectRightLP;
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 - 5, rectFlowLP.top + 8));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 + 5, rectFlowLP.top + 8));
      }
    }
    break;
  case EKind.MS_THERMAL_CT:
    {
      let pThermalCT = {...pElement};
      if (nDirect == DIRECT_NONE) {
        rectFlowLP = pThermalCT.m_RefV1FlowResult.m_rectRightLP;
        if (pThermalCT.properties.lineActiveFlow1 >= 0) {
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pThermalCT.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pThermalCT.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pThermalCT.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pThermalCT.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5, rectFlowLP.bottom - 8));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pThermalCT.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pThermalCT.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5, rectFlowLP.bottom - 8));
        }
        else if (pThermalCT.properties.lineActiveFlow2 > 0) {
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pThermalCT.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pThermalCT.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pThermalCT.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pThermalCT.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5, rectFlowLP.top + 8));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pThermalCT.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pThermalCT.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5, rectFlowLP.top + 8));
        }

        if (pThermalCT.properties.lineReactiveFlow1 >= 0) {
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pThermalCT.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pThermalCT.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pThermalCT.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pThermalCT.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5, rectFlowLP.bottom - 8));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pThermalCT.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pThermalCT.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5, rectFlowLP.bottom - 8));
        }
        else if (pThermalCT.properties.lineReactiveFlow2 > 0) {
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pThermalCT.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pThermalCT.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pThermalCT.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pThermalCT.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5, rectFlowLP.top + 8));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pThermalCT.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pThermalCT.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5, rectFlowLP.top + 8));
        }
      }
      else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
        rectFlowLP = pThermalCT.m_RefV1FlowResultTop.m_rectRightLP;
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 - 5, rectFlowLP.bottom - 8));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 + 5, rectFlowLP.bottom - 8));
      }
      else if (nDirect == BOTTOM_CENTER || nDirect == RIGHT_CENTER) {
        rectFlowLP = pThermalCT.m_RefV1FlowResultBottom.m_rectRightLP;
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 - 5, rectFlowLP.top + 8));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 + 5, rectFlowLP.top + 8));
      }
    }
    break;
  case EKind.MS_MOTOR_GROUP:
    {
      let pMotorGrp = {...pElement};
      if (nDirect == DIRECT_NONE) {
        rectFlowLP = pMotorGrp.m_RefV1FlowResult.m_rectRightLP;
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pMotorGrp.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pMotorGrp.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pMotorGrp.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pMotorGrp.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5, rectFlowLP.bottom - 8));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pMotorGrp.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pMotorGrp.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5, rectFlowLP.bottom - 8));

        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pMotorGrp.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pMotorGrp.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pMotorGrp.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pMotorGrp.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5, rectFlowLP.bottom - 8));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pMotorGrp.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pMotorGrp.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5, rectFlowLP.bottom - 8));
      }
      else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
        if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
          rectFlowLP = pMotorGrp.m_RefV1FlowResultTop.m_rectRightLP;
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 - 5, rectFlowLP.bottom + 8));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 + 5, rectFlowLP.bottom + 8));
        }
      }
    }
    break;
  case EKind.MS_MOTOR:
    {
      let pMotor = {...pElement};
      if (nDirect == DIRECT_NONE) {
        rectFlowLP = pMotor.m_RefV1FlowResult.m_rectRightLP;
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pMotor.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pMotor.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pMotor.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pMotor.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5, rectFlowLP.bottom - 8));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pMotor.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pMotor.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5, rectFlowLP.bottom - 8));

        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pMotor.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pMotor.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pMotor.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pMotor.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5, rectFlowLP.bottom - 8));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pMotor.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pMotor.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5, rectFlowLP.bottom - 8));
      }
      else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
        rectFlowLP = pMotor.m_RefV1FlowResultTop.m_rectRightLP;
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 - 5, rectFlowLP.bottom + 8));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 + 5, rectFlowLP.bottom + 8));
      }
    }
    break;
  case EKind.MS_LOAD:
    {
      let pLoad = {...pElement};
      if (nDirect == DIRECT_NONE) {
        rectFlowLP = pLoad.m_RefV1FlowResult.m_rectRightLP;
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pLoad.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pLoad.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pLoad.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pLoad.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5, rectFlowLP.bottom - 8));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pLoad.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pLoad.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5, rectFlowLP.bottom - 8));

        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pLoad.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pLoad.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pLoad.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pLoad.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5, rectFlowLP.bottom - 8));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pLoad.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pLoad.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5, rectFlowLP.bottom - 8));
      }
      else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
        rectFlowLP = pLoad.m_RefV1FlowResultTop.m_rectRightLP;
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 - 5, rectFlowLP.bottom + 8));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 + 5, rectFlowLP.bottom + 8));
      }
    }
    break;
  case EKind.MS_CAPACITOR:
    {
      let pCapacitor = {...pElement};
      if (nDirect == DIRECT_NONE) {
        rectFlowLP = pCapacitor.m_RefV1FlowResult.m_rectRightLP;
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pCapacitor.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pCapacitor.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pCapacitor.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pCapacitor.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5, rectFlowLP.bottom - 8));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pCapacitor.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pCapacitor.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5, rectFlowLP.bottom - 8));

        if (pCapacitor.properties.lineReactiveFlow1 < 0) {
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pCapacitor.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pCapacitor.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pCapacitor.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pCapacitor.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5, rectFlowLP.bottom - 8));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pCapacitor.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pCapacitor.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5, rectFlowLP.bottom - 8));
        }
        else {
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pCapacitor.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pCapacitor.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pCapacitor.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pCapacitor.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5, rectFlowLP.top + 8));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pCapacitor.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pCapacitor.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5, rectFlowLP.top + 8));
        }
      }
      else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
        rectFlowLP = pCapacitor.m_RefV1FlowResultTop.m_rectRightLP;
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 - 5, rectFlowLP.bottom + 8));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 + 5, rectFlowLP.bottom + 8));
      }
    }
    break;
  case EKind.MS_LOADCENTER:
    break;
  case EKind.MS_VCT:
    {
      let pVCT = {...pElement};
      if (nDirect == DIRECT_NONE) {
        rectFlowLP = pVCT.m_RefV1FlowResult.m_rectRightLP;
        if (pVCT.properties.lineActiveFlow1 >= 0) {
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pVCT.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pVCT.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pVCT.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pVCT.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5, rectFlowLP.bottom - 8));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pVCT.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pVCT.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5, rectFlowLP.bottom - 8));
        }
        else if (pVCT.properties.lineActiveFlow2 > 0) {
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pVCT.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pVCT.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pVCT.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pVCT.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5, rectFlowLP.top + 8));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pVCT.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pVCT.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5, rectFlowLP.top + 8));
        }

        if (pVCT.properties.lineReactiveFlow1 >= 0) {
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pVCT.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pVCT.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pVCT.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pVCT.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5, rectFlowLP.bottom - 8));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pVCT.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pVCT.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5, rectFlowLP.bottom - 8));
        }
        else if (pVCT.properties.lineReactiveFlow2 > 0) {
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pVCT.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pVCT.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pVCT.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pVCT.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5, rectFlowLP.top + 8));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pVCT.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pVCT.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5, rectFlowLP.top + 8));
        }
      }
      else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
        rectFlowLP = pVCT.m_RefV1FlowResultTop.m_rectRightLP;
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 - 5, rectFlowLP.bottom - 8));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 + 5, rectFlowLP.bottom - 8));
      }
      else if (nDirect == BOTTOM_CENTER || nDirect == RIGHT_CENTER) {
        rectFlowLP = pVCT.m_RefV1FlowResultBottom.m_rectRightLP;
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 - 5, rectFlowLP.top + 8));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 + 5, rectFlowLP.top + 8));
      }
    }
    break;
  case EKind.MS_INV:
    {
      let pINV = {...pElement};
      if (nDirect == DIRECT_NONE) {
        rectFlowLP = pINV.m_RefV1FlowResult.m_rectRightLP;
        if (pINV.properties.lineActiveFlow1 >= 0) {
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pINV.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pINV.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pINV.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pINV.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5, rectFlowLP.bottom - 8));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pINV.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pINV.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5, rectFlowLP.bottom - 8));
        }
        else if (pINV.properties.lineActiveFlow2 > 0) {
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pINV.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pINV.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pINV.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pINV.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5, rectFlowLP.top + 8));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pINV.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pINV.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5, rectFlowLP.top + 8));
        }

        if (pINV.properties.lineReactiveFlow1 >= 0) {
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pINV.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pINV.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pINV.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pINV.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5, rectFlowLP.bottom - 8));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pINV.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pINV.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5, rectFlowLP.bottom - 8));
        }
        else if (pINV.properties.lineReactiveFlow2 > 0) {
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pINV.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pINV.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pINV.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pINV.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5, rectFlowLP.top + 8));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pINV.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pINV.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5, rectFlowLP.top + 8));
        }
      }
      else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
        rectFlowLP = pINV.m_RefV1FlowResultTop.m_rectRightLP;
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 - 5, rectFlowLP.bottom - 8));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 + 5, rectFlowLP.bottom - 8));
      }
      else if (nDirect == BOTTOM_CENTER || nDirect == RIGHT_CENTER) {
        rectFlowLP = pINV.m_RefV1FlowResultBottom.m_rectRightLP;
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 - 5, rectFlowLP.top + 8));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 + 5, rectFlowLP.top + 8));
      }
    }
    break;
  case EKind.MS_RELAY:
    {
      let pRELAY = {...pElement};
      if (nDirect == DIRECT_NONE) {
        rectFlowLP = pRELAY.m_RefV1FlowResult.m_rectRightLP;
        if (pRELAY.properties.lineActiveFlow1 >= 0) {
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pRELAY.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pRELAY.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pRELAY.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pRELAY.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5, rectFlowLP.bottom - 8));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pRELAY.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pRELAY.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5, rectFlowLP.bottom - 8));
        }
        else if (pRELAY.properties.lineActiveFlow2 > 0) {
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pRELAY.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pRELAY.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pRELAY.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pRELAY.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5, rectFlowLP.top + 8));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pRELAY.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pRELAY.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5, rectFlowLP.top + 8));
        }

        if (pRELAY.properties.lineReactiveFlow1 >= 0) {
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pRELAY.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pRELAY.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pRELAY.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pRELAY.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5, rectFlowLP.bottom - 8));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pRELAY.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pRELAY.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5, rectFlowLP.bottom - 8));
        }
        else if (pRELAY.properties.lineReactiveFlow2 > 0) {
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pRELAY.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pRELAY.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pRELAY.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pRELAY.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5, rectFlowLP.top + 8));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pRELAY.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pRELAY.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5, rectFlowLP.top + 8));
        }
      }
      else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
        rectFlowLP = pRELAY.m_RefV1FlowResultTop.m_rectRightLP;
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 - 5, rectFlowLP.bottom - 8));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 + 5, rectFlowLP.bottom - 8));
      }
      else if (nDirect == BOTTOM_CENTER || nDirect == RIGHT_CENTER) {
        rectFlowLP = pRELAY.m_RefV1FlowResultBottom.m_rectRightLP;
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 - 5, rectFlowLP.top + 8));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 + 5, rectFlowLP.top + 8));
      }
    }
    break;
  case EKind.MS_ARRESTOR:
    break;
  case EKind.MS_EARTH:
    break;
  case EKind.MS_VT:
    {
      let pVT = {...pElement};
      if (nDirect == DIRECT_NONE) {
        rectFlowLP = pVT.m_RefV1FlowResult.m_rectRightLP;
        if (pVT.properties.lineActiveFlow1 >= 0) {
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pVT.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pVT.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pVT.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pVT.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5, rectFlowLP.bottom - 8));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pVT.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pVT.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5, rectFlowLP.bottom - 8));
        }
        else if (pVT.properties.lineActiveFlow2 > 0) {
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pVT.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pVT.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pVT.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pVT.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5, rectFlowLP.top + 8));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pVT.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pVT.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5, rectFlowLP.top + 8));
        }

        if (pVT.properties.lineReactiveFlow1 >= 0) {
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pVT.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pVT.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pVT.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pVT.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5, rectFlowLP.bottom - 8));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pVT.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pVT.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5, rectFlowLP.bottom - 8));
        }
        else if (pVT.properties.lineReactiveFlow2 > 0) {
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pVT.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pVT.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pVT.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pVT.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5, rectFlowLP.top + 8));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pVT.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pVT.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5, rectFlowLP.top + 8));
        }
      }
      else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
        rectFlowLP = pVT.m_RefV1FlowResultTop.m_rectRightLP;
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 - 5, rectFlowLP.bottom - 8));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 + 5, rectFlowLP.bottom - 8));
      }
      else if (nDirect == BOTTOM_CENTER || nDirect == RIGHT_CENTER) {
        rectFlowLP = pVT.m_RefV1FlowResultBottom.m_rectRightLP;
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 - 5, rectFlowLP.top + 8));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 + 5, rectFlowLP.top + 8));
      }
    }
    break;
  case EKind.MS_CABLEHEAD:
    break;
  case EKind.MS_CT:
    {
      let pCT = {...pElement};
      if (nDirect == DIRECT_NONE) {
        rectFlowLP = pCT.m_RefV1FlowResult.m_rectRightLP;
        if (pCT.properties.lineActiveFlow1 >= 0) {
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pCT.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pCT.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pCT.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pCT.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5, rectFlowLP.bottom - 8));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pCT.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pCT.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5, rectFlowLP.bottom - 8));
        }
        else if (pCT.properties.lineActiveFlow2 > 0) {
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pCT.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pCT.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pCT.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pCT.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5, rectFlowLP.top + 8));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pCT.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pCT.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5, rectFlowLP.top + 8));
        }

        if (pCT.properties.lineReactiveFlow1 >= 0) {
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pCT.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pCT.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pCT.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pCT.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5, rectFlowLP.bottom - 8));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pCT.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pCT.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5, rectFlowLP.bottom - 8));
        }
        else if (pCT.properties.lineReactiveFlow2 > 0) {
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pCT.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pCT.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pCT.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pCT.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5, rectFlowLP.top + 8));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pCT.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pCT.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5, rectFlowLP.top + 8));
        }
      }
      else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
        rectFlowLP = pCT.m_RefV1FlowResultTop.m_rectRightLP;
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 - 5, rectFlowLP.bottom - 8));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 + 5, rectFlowLP.bottom - 8));
      }
      else if (nDirect == BOTTOM_CENTER || nDirect == RIGHT_CENTER) {
        rectFlowLP = pCT.m_RefV1FlowResultBottom.m_rectRightLP;
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 - 5, rectFlowLP.top + 8));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 + 5, rectFlowLP.top + 8));
      }
    }
    break;
  case EKind.MS_ZCT:
    {
      let pZCT = {...pElement};
      if (nDirect == DIRECT_NONE) {
        rectFlowLP = pZCT.m_RefV1FlowResult.m_rectRightLP;
        if (pZCT.properties.lineActiveFlow1 >= 0) {
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pZCT.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pZCT.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pZCT.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pZCT.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5, rectFlowLP.bottom - 8));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pZCT.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pZCT.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5, rectFlowLP.bottom - 8));
        }
        else if (pZCT.properties.lineActiveFlow2 > 0) {
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pZCT.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pZCT.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pZCT.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pZCT.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5, rectFlowLP.top + 8));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pZCT.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pZCT.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5, rectFlowLP.top + 8));
        }

        if (pZCT.properties.lineReactiveFlow1 >= 0) {
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pZCT.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pZCT.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pZCT.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pZCT.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5, rectFlowLP.bottom - 8));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pZCT.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pZCT.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5, rectFlowLP.bottom - 8));
        }
        else if (pZCT.properties.lineReactiveFlow2 > 0) {
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pZCT.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pZCT.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pZCT.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pZCT.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5, rectFlowLP.top + 8));
          retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pZCT.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pZCT.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5, rectFlowLP.top + 8));
        }
      }
      else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
        rectFlowLP = pZCT.m_RefV1FlowResultTop.m_rectRightLP;
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 - 5, rectFlowLP.bottom - 8));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 + 5, rectFlowLP.bottom - 8));
      }
      else if (nDirect == BOTTOM_CENTER || nDirect == RIGHT_CENTER) {
        rectFlowLP = pZCT.m_RefV1FlowResultBottom.m_rectRightLP;
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 - 5, rectFlowLP.top + 8));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 + 5, rectFlowLP.top + 8));
      }
    }
    break;
  case EKind.MS_TEXT:
    break;
  case EKind.MS_LIGHTBOARD:
    {
      let pLightBoard = {...pElement};
      if (nDirect == DIRECT_NONE) {
        rectFlowLP = pLightBoard.m_RefV1FlowResult.m_rectRightLP;
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pLightBoard.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pLightBoard.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pLightBoard.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pLightBoard.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5, rectFlowLP.bottom - 8));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pLightBoard.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pLightBoard.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5, rectFlowLP.bottom - 8));

        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pLightBoard.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pLightBoard.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pLightBoard.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pLightBoard.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5, rectFlowLP.bottom - 8));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pLightBoard.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pLightBoard.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5, rectFlowLP.bottom - 8));
      }
      else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
        rectFlowLP = pLightBoard.m_RefV1FlowResultTop.m_rectRightLP;
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 - 5, rectFlowLP.bottom + 8));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 + 5, rectFlowLP.bottom + 8));
      }
    }
    break;
  case EKind.MS_POWERBOARD:
    {
      let pPowerBoard = {...pElement};
      if (nDirect == DIRECT_NONE) {
        rectFlowLP = pPowerBoard.m_RefV1FlowResult.m_rectRightLP;
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pPowerBoard.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.top, rectFlowLP.left + pPowerBoard.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pPowerBoard.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pPowerBoard.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 - 5, rectFlowLP.bottom - 8));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pPowerBoard.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2, rectFlowLP.bottom, rectFlowLP.left + pPowerBoard.m_RefV1FlowResult.m_LogFont.lfHeight * 3 / 2 + 5, rectFlowLP.bottom - 8));

        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pPowerBoard.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.top, rectFlowLP.left + pPowerBoard.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pPowerBoard.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pPowerBoard.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 - 5, rectFlowLP.bottom - 8));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left + pPowerBoard.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3, rectFlowLP.bottom, rectFlowLP.left + pPowerBoard.m_RefV1FlowResult.m_LogFont.lfHeight * 7 / 2 + 3 + 5, rectFlowLP.bottom - 8));
      }
      else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
        rectFlowLP = pPowerBoard.m_RefV1FlowResultTop.m_rectRightLP;
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.top, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 - 5, rectFlowLP.bottom + 8));
        retArr = retArr.concat(LINE(CDXFFile1, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2, rectFlowLP.bottom, rectFlowLP.left - MS_FRAME_WIDTH / 4 - 2 + 5, rectFlowLP.bottom + 8));
      }
    }
    break;
  }

  return retArr;
}

const STRING = (
  CDXFFile1: any,
  lLeft: number,
  lTop: number,
  nHight: number,
  sText: string,
  nSweepAngle = 0
) => {
  if (sText && sText.length <= 0) {
    return null;
  }
  let retArr: any[] = []
  retArr.push("0");
  retArr.push("TEXT");

  retArr.push("8");
  retArr.push(DLLGetTextByBeams(CDXFFile1.m_nLayerNo));

  retArr.push("10");
  retArr.push(DLLGetTextByBeams(lLeft * CDXFFile1.m_dReduced - CDXFFile1.m_dOffsetX));

  retArr.push("20");
  retArr.push(
    DLLGetTextByBeams(
      Math.abs(
        (lTop + nHight / 2 - CDXFFile1.m_dBaseY - DXF_RECT_BOTTOM) *
          CDXFFile1.m_dReduced -
          CDXFFile1.m_dOffsetY
      )
    )
  );

  retArr.push("40");
  retArr.push(DLLGetTextByBeams(nHight * CDXFFile1.m_dReduced));

  retArr.push("50");
  retArr.push(DLLGetTextByBeams(nSweepAngle));

  sText.replace("�u", "m2"); // TODO m² . m2
  retArr.push("1");
  retArr.push(sText);
  
  return retArr;
}

const CIRCLE = (CDXFFile1: any, rect: any) => {
  let point = getCenterPoint(rect);
  let retArr: any[] = [];

  retArr.push("0");
  retArr.push("CIRCLE");
  
  retArr.push("8");
  retArr.push(DLLGetTextByBeams(CDXFFile1.m_nLayerNo));
  
  retArr.push("6");
  retArr.push("CONTINUOUS");
  
  retArr.push("62");
  retArr.push("0");
  retArr.push("10");
  retArr.push(DLLGetTextByBeams(point.x * CDXFFile1.m_dReduced - CDXFFile1.m_dOffsetX));
  
  retArr.push("20");
  retArr.push(DLLGetTextByBeams(Math.abs((point.y - CDXFFile1.m_dBaseY - DXF_RECT_BOTTOM) * CDXFFile1.m_dReduced - CDXFFile1.m_dOffsetY)));
  
  retArr.push("40");
  retArr.push(DLLGetTextByBeams((rect.right - rect.left) / 2 * CDXFFile1.m_dReduced));
  
  return retArr;
}

const LINE = (
  CDXFFile1: any,
  dX1: number,
  dY1: number,
  dX2: number,
  dY2: number
) =>{
  let retArr: any[] = [];

  retArr.push("0");
  retArr.push("LINE");
  
  retArr.push("8");
  retArr.push(DLLGetTextByBeams(CDXFFile1.m_nLayerNo));
  
  retArr.push("6");
  retArr.push("CONTINUOUS");
  
  retArr.push("62");
  retArr.push("0");
  retArr.push("10");
  retArr.push(DLLGetTextByBeams(dX1 * CDXFFile1.m_dReduced - CDXFFile1.m_dOffsetX));
  
  retArr.push("20");
  retArr.push(DLLGetTextByBeams(Math.abs((dY1 - CDXFFile1.m_dBaseY - DXF_RECT_BOTTOM) * CDXFFile1.m_dReduced - CDXFFile1.m_dOffsetY)));
  
  retArr.push("11");
  retArr.push(DLLGetTextByBeams(dX2 * CDXFFile1.m_dReduced - CDXFFile1.m_dOffsetX));
  
  retArr.push("21");
  retArr.push(DLLGetTextByBeams(Math.abs((dY2 - CDXFFile1.m_dBaseY - DXF_RECT_BOTTOM) * CDXFFile1.m_dReduced - CDXFFile1.m_dOffsetY)));
  
  return retArr;
}

const ARC_Top = (CDXFFile1: any, rect: any, pointStart: any, pointEnd: any) => {
  let retArr: any[] = [];
  let point = getCenterPoint(rect);

  retArr.push("0");
  retArr.push("ARC");
  
  retArr.push("8");
  retArr.push(DLLGetTextByBeams(CDXFFile1.m_nLayerNo));
  
  retArr.push("6");
  retArr.push("CONTINUOUS");
  
  retArr.push("62");
  retArr.push("0");
  retArr.push("10");
  retArr.push(DLLGetTextByBeams(point.x * CDXFFile1.m_dReduced - CDXFFile1.m_dOffsetX));
  
  retArr.push("20");
  retArr.push(DLLGetTextByBeams(Math.abs((pointStart.y - CDXFFile1.m_dBaseY - DXF_RECT_BOTTOM) * CDXFFile1.m_dReduced - CDXFFile1.m_dOffsetY)));
  
  retArr.push("40");
  retArr.push(DLLGetTextByBeams((pointStart.x - pointEnd.x) / 2 * CDXFFile1.m_dReduced));
  
  retArr.push("50");
  retArr.push(DLLGetTextByBeams(0));
  
  retArr.push("51");
  retArr.push(DLLGetTextByBeams(180));
  
  return retArr;
}

const ARC_Bottom = (CDXFFile1: any, rect: any, pointStart: any, pointEnd: any) => {
  let retArr: any[] = [];
  let point = getCenterPoint(rect);

  retArr.push("0");
  retArr.push("ARC");
  
  retArr.push("8");
  retArr.push(DLLGetTextByBeams(CDXFFile1.m_nLayerNo));
  
  retArr.push("6");
  retArr.push("CONTINUOUS");
  
  retArr.push("62");
  retArr.push("0");
  retArr.push("10");
  retArr.push(DLLGetTextByBeams(point.x * CDXFFile1.m_dReduced - CDXFFile1.m_dOffsetX));
  
  retArr.push("20");
  retArr.push(DLLGetTextByBeams(Math.abs((pointStart.y - CDXFFile1.m_dBaseY - DXF_RECT_BOTTOM) * CDXFFile1.m_dReduced - CDXFFile1.m_dOffsetY)));

  retArr.push("40");
  retArr.push(DLLGetTextByBeams((pointEnd.x - pointStart.x) / 2 * CDXFFile1.m_dReduced));
  
  retArr.push("50");
  retArr.push(DLLGetTextByBeams(180));
  
  retArr.push("51");
  retArr.push(DLLGetTextByBeams(0));
  
  return retArr;
}

const ARC_Left = (CDXFFile1: any, rect: any, pointStart: any, pointEnd: any) => {
  let retArr: any[] = [];
  let point = getCenterPoint(rect);

  retArr.push("0");
  retArr.push("ARC");
  
  retArr.push("8");
  retArr.push(DLLGetTextByBeams(CDXFFile1.m_nLayerNo));
  
  retArr.push("6");
  retArr.push("CONTINUOUS");
  
  retArr.push("62");
  retArr.push("0");
  retArr.push("10");
  retArr.push(DLLGetTextByBeams(pointStart.x * CDXFFile1.m_dReduced - CDXFFile1.m_dOffsetX));
  
  retArr.push("20");
  retArr.push(DLLGetTextByBeams(Math.abs((point.y + 1 - CDXFFile1.m_dBaseY - DXF_RECT_BOTTOM) * CDXFFile1.m_dReduced - CDXFFile1.m_dOffsetY)));
  
  retArr.push("40");
  retArr.push(DLLGetTextByBeams((pointEnd.y - pointStart.y - 2) / 2 * CDXFFile1.m_dReduced));
  
  retArr.push("50");
  retArr.push(DLLGetTextByBeams(90));
  
  retArr.push("51");
  retArr.push(DLLGetTextByBeams(270));
  
  return retArr;
}

const ARC_Right = (CDXFFile1: any, rect: any, pointStart: any, pointEnd: any) => {
  let retArr: any[] = [];
  let point = getCenterPoint(rect);

  retArr.push("0");
  retArr.push("ARC");
  
  retArr.push("8");
  retArr.push(DLLGetTextByBeams(CDXFFile1.m_nLayerNo));
  
  retArr.push("6");
  retArr.push("CONTINUOUS");
  
  retArr.push("62");
  retArr.push("0");
  retArr.push("10");
  retArr.push(DLLGetTextByBeams(pointStart.x * CDXFFile1.m_dReduced - CDXFFile1.m_dOffsetX));
  
  retArr.push("20");
  retArr.push(DLLGetTextByBeams(Math.abs((point.y + 1 - CDXFFile1.m_dBaseY - DXF_RECT_BOTTOM) * CDXFFile1.m_dReduced - CDXFFile1.m_dOffsetY)));
  
  retArr.push("40");
  retArr.push(DLLGetTextByBeams((pointStart.y - pointEnd.y - 2) / 2 * CDXFFile1.m_dReduced));
  
  retArr.push("50");
  retArr.push(DLLGetTextByBeams(270));
  
  retArr.push("51");
  retArr.push(DLLGetTextByBeams(90));
  
  return retArr;
}

const SOLID = (
  CDXFFile1: any,
  dX1: number,
  dY1: number,
  dX2: number,
  dY2: number,
  dX3: number,
  dY3: number,
  dX4: number,
  dY4: number
) => {
  let retArr: any[] = [];

  retArr.push("0");
  retArr.push("SOLID");
  
  retArr.push("8");
  retArr.push(DLLGetTextByBeams(CDXFFile1.m_nLayerNo));
  
  retArr.push("6");
  retArr.push("CONTINUOUS");
  
  retArr.push("62");
  retArr.push("0");
  retArr.push("10");
  retArr.push(DLLGetTextByBeams(dX1 * CDXFFile1.m_dReduced - CDXFFile1.m_dOffsetX));
  
  retArr.push("20");
  retArr.push(DLLGetTextByBeams(Math.abs((dY1 - CDXFFile1.m_dBaseY - DXF_RECT_BOTTOM) * CDXFFile1.m_dReduced - CDXFFile1.m_dOffsetY)));
  
  retArr.push("11");
  retArr.push(DLLGetTextByBeams(dX2 * CDXFFile1.m_dReduced - CDXFFile1.m_dOffsetX));
  
  retArr.push("21");
  retArr.push(DLLGetTextByBeams(Math.abs((dY2 - CDXFFile1.m_dBaseY - DXF_RECT_BOTTOM) * CDXFFile1.m_dReduced - CDXFFile1.m_dOffsetY)));
  
  retArr.push("12");
  retArr.push(DLLGetTextByBeams(dX3 * CDXFFile1.m_dReduced - CDXFFile1.m_dOffsetX));
  
  retArr.push("22");
  retArr.push(DLLGetTextByBeams(Math.abs((dY3 - CDXFFile1.m_dBaseY - DXF_RECT_BOTTOM) * CDXFFile1.m_dReduced - CDXFFile1.m_dOffsetY)));
  
  retArr.push("13");
  retArr.push(DLLGetTextByBeams(dX4 * CDXFFile1.m_dReduced - CDXFFile1.m_dOffsetX));
  
  retArr.push("23");
  retArr.push(DLLGetTextByBeams(Math.abs((dY4 - CDXFFile1.m_dBaseY - DXF_RECT_BOTTOM) * CDXFFile1.m_dReduced - CDXFFile1.m_dOffsetY)));
  
  return retArr;
}

const ANGLE_ARC = (
  CDXFFile1: any,
  nX: number,
  nY: number,
  nRadius: number,
  nStartAngle: number,
  nSweepAngle: number
) => {
  let retArr: any[] = [];
  
  retArr.push("0");
  retArr.push("ARC");
  
  retArr.push("8");
  retArr.push(DLLGetTextByBeams(CDXFFile1.m_nLayerNo));
  
  retArr.push("6");
  retArr.push("CONTINUOUS");
  
  retArr.push("62");
  retArr.push("0");
  retArr.push("10");
  retArr.push(DLLGetTextByBeams(nX * CDXFFile1.m_dReduced - CDXFFile1.m_dOffsetX));
  
  retArr.push("20");
  retArr.push(DLLGetTextByBeams(Math.abs((nY - CDXFFile1.m_dBaseY - DXF_RECT_BOTTOM) * CDXFFile1.m_dReduced - CDXFFile1.m_dOffsetY)));
  
  retArr.push("40");
  retArr.push(DLLGetTextByBeams(nRadius * CDXFFile1.m_dReduced));
  
  retArr.push("50");
  retArr.push(DLLGetTextByBeams(nStartAngle));
  
  retArr.push("51");
  retArr.push(DLLGetTextByBeams(nStartAngle + nSweepAngle));
  
  return retArr;
}

// ------------- sup func
const readBaseFile = async (baseFileName: string) => {
  let baseFile;
  let baseFileContent = "";
  if (baseFileName === NAME_DXF_SKELETON)
    baseFile = DXFSkeleton
  else if (baseFileName === NAME_DXF_GRAPH)
    baseFile = DXFGraph
  else if (baseFileName === NAME_DXF_GRAPH_H)
    baseFile = DXFGraphH
  else if (baseFileName === NAME_DXF_GRAPH_L)
    baseFile = DXFGraphL

  await fetch(baseFile)
    .then((r) => r.text())
    .then((text) => {
      baseFileContent = text;
    })
    .catch((e) => {console.log(e)})
  return baseFileContent
}