import { CircularProgress, Dialog, DialogContent, DialogTitle } from "@mui/material";
import Konva from "konva";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { Group, Image, Rect, Transformer, Text, Label } from "react-konva";
import { Html, useImage } from "react-konva-utils";
import { connect } from "react-redux";
import lineSvg from "../../images/control/line.svg";
import lineGreenSvg from "../../images/control/line_green.svg";
import lineRedSvg from "../../images/control/line_red.svg";
import lineOhmSvg from "../../images/control/インピーダンス＿オーム＿R.svg";
import lineOhmRedSvg from "../../images/control/インピーダンス＿オーム＿R_red.svg";
import lineOhmGreenSvg from "../../images/control/インピーダンス＿オーム＿R_green.svg";
import imageSvg from "../../images/control/電動機群.svg";
import imageRedSvg from "../../images/control/電動機群_red.svg";
import imageGreenSvg from "../../images/control/電動機群_green.svg";
import * as Constant from "../../models/Constants";
import { Coordinates, LineInfoModel, LineType, MsMotorGroupModel, OptionModel, ProcessMode, UndoModel } from "../../models/Index";
import {
  addUserCurve,
  clearOptionMenu,
  deleteRelatedGraphByUserCurveDialog,
  deleteUserCurve,
  openChart,
  openDialogEvents,
  openUserCurveEvents,
  openUserCurvePropertiesEvents,
  setRepositionElement,
  setTransformFlag,
  undo,
  updateControlHeight,
  updateElementPosition,
  updatePropertiesOfControlAction,
  updateSelectControl,
  updateUserCurve,
} from "../../store/Actions";
import theme from "../../themes/globalStyles";
import OptionMenu from "../contextMenu/OptionMenu";
import MsMotorGroupEditDialog from "../dialogs/MsMotorGroupEditDialog";
import rightArrow from "../../images/control/red_arrow.svg";
import { addListToData } from "../../utils/DataConverter";
import UserCurvesDialog from "../dialogs/UserCurvesDialog";
import DraggablePaper from "../common/DraggablePaper";
import doGetSourceVolt from "../../utils/mssv3Doc";
import { BeamsNumber } from "../../utils/FormatNumber";
import { getElementKindValue } from "../../utils/ElementFunction";
import { doGetRefV1 } from "../../utils/MotorGrpFunction";
import UserCurveDetailDialog from "../dialogs/UserCurveDetailDialog";
import { getElements, post } from "../CallApi";
import { getSymbolAndBackColor } from "../../utils/drawElement";
import { calcContextMenuPosition, calcDragBoundPosition, calcElementSize, getMenuOptionHeight } from "../../utils/PositionCalculation";
import { actionCreators } from "../../store";
import { doDrawSymbolGroup } from "../graph/DCView";
import * as Model from "../../models/Index";

//#region Props
type CreateMsMotorGroupProps = {
  id: string;
  image: string;
  x: number;
  y: number;
  offsetX: number;
  offsetY: number;
  width: number;
  height: number;
  type: string;
  isSelected: boolean;
  isHidden: boolean;
  rotation: number;
  lineDiagramRef: any;
  parentGroupId: number | null;
  chart: LineInfoModel[];
  properties: MsMotorGroupModel;
  gridSizeController: {
    width: number;
    height: number;
    scale: number;
    stageOffsetX: number;
    stageOffsetY: number;
  };
  isOnShiftKey: boolean;
};

export type PureCreateMsMotorGroupProps = CreateMsMotorGroupProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;
//#endregion

const CONTROL_POS_FACTOR = 2;

const CreateMsMotorGroup = memo((props: PureCreateMsMotorGroupProps) => {
  //#region Fields
  const {
    id,
    image,
    x,
    y,
    offsetX,
    offsetY,
    width,
    height,
    type,
    isSelected,
    isHidden,
    rotation,
    lineDiagramRef,
    parentGroupId,
    processMode,
    chart,
    properties,
  } = props;

  const {
    // getElementParam,
    // updateElementPost,
    // addUserCurveDB,
    // updateUserCurveDB,
    // deleteUserCurveDB,
    diagramDataUpdate,
    diagramData,
    projectData,
    gridSizeController, 
    openDialogData,
    openUserCurveData,
    infoCircuit,
    userCurveProperties,
    userRole,
    m_bModePM,
    modeViewOnly,
    infoSkeleton,
    currentTabId,
    drawPowerArrow,
    clearMenuState,
    isSelectTouchGroup,
    isOnShiftKey,
    elementGroups,
    tcGroups,
    elementReposition,
    updateElementPosition,
    setRepositionElement,
    mappedDataFromBE,
    userId,
    updateSelectControl, 
    updateControlData, 
    updateControlHeight, 
    setTransformFlag,
    addUserCurve,
    updateUserCurve,
    deleteUserCurve,
    setOpenChart, 
    openDialogEvents,
    openUserCurvePropertiesEvent,
    deleteRelatedGraphByUserCurveDialog,
    openUserCurveEvent,
    setUndoData,
    clearOptionMenu,
  } = props;

  const [imageStatus, setImageStatus] = useState(image);
  const [lineStatus, setLineStatus] = useState(lineSvg);
  const [lineOhmStatus, setLineOhmStatus] = useState(lineOhmSvg);

  const [showPowerInfo, setShowPowerInfo] = useState(false)
  const [showPowerDirectInfo, setShowPowerDirectInfo] = useState(false)
  const [activePower, setActivePower] = useState("?????kW")
  const [reactivePower, setReactivePower] = useState("?????kvar")
  const [svgHeight, setSvgHeight] = useState('')
  const [svgWidth, setSvgWidth] = useState('')
  const [svgLine, setSvgLine] = useState('')
  const [svgArrowActive, setSvgArrowActive] = useState('')
  const [svgArrowReactive, setSvgArrowReactive] = useState('')
  const [svgTextX, setSvgTextX] = useState('')
  const [svgTextY, setSvgTextY] = useState('')
  const [svgTextRotation, setSvgTextRotation] = useState('')
  const [svgTextX1, setSvgTextX1] = useState('')
  const [svgTextY1, setSvgTextY1] = useState('')
  const [svgPosX1, setSvgPosX1] = useState(0)
  const [svgPosX2, setSvgPosX2] = useState(0)
  const [svgPosX3, setSvgPosX3] = useState(0)
  const [svgPosY1, setSvgPosY1] = useState(0)
  const [svgPosY2, setSvgPosY2] = useState(0)
  const [svgPosY3, setSvgPosY3] = useState(0)

  var imageObj = new window.Image();
  imageObj.src = imageStatus;

  var lineObj = new window.Image();
  lineObj.src = lineStatus;
  
  var rightArrowObj = new window.Image();
  rightArrowObj.src = rightArrow;

  var lineOhmObj = new window.Image();
  lineOhmObj.src = lineOhmStatus;

  const svgToURL = (s:any) =>{
    const uri = window.btoa(unescape(encodeURIComponent(s)));
    return "data:image/svg+xml;base64," + uri;
  }

    const svgActivePower =
    `<svg height="${svgHeight}" width="${svgWidth}"  xmlns="http://www.w3.org/2000/svg">` +
    `<path  d="${svgLine}" stroke="${isSelected? 'red' : 'blue'}"  stroke-width="1" fill="none"/>` +
    `<path  d="${svgArrowActive}" stroke="${isSelected? 'red' : 'blue'}" stroke-width="1"  fill="none"/>` +
    `<text x="${svgTextX}" y="${svgTextY}" text-anchor="middle" font-size="10" fill="${isSelected? 'red' : 'blue'}" transform="translate(15,15) rotate(${svgTextRotation})">${activePower}</text>` +
    '</svg>' ;

  const urlActivePower = svgToURL(svgActivePower);
  const [imageSVGActivePower] = useImage(urlActivePower);

  const svgReactivePower =
  `<svg height="${svgHeight}" width="${svgWidth}"  xmlns="http://www.w3.org/2000/svg">` +
  `<path  d="${svgLine}" stroke="${isSelected? 'red' : 'blue'}"  stroke-width="1" fill="none"/>` +
  `<path  d="${svgArrowReactive}" stroke="${isSelected? 'red' : 'blue'}" stroke-width="1"  fill="none"/>` +
  `<text x="${svgTextX}" y="${svgTextY}" text-anchor="middle" font-size="10" fill="${isSelected? 'red' : 'blue'}" transform="translate(15,15) rotate(${svgTextRotation})">${reactivePower}</text>` +
  '</svg>' ;

  const urlReactivePower = svgToURL(svgReactivePower);
  const [imageSVGReactivePower] = useImage(urlReactivePower);

  
    const svgActiveReactivePower =
    `<svg height="${svgHeight}" width="${svgWidth}"  xmlns="http://www.w3.org/2000/svg">` +
    `<path  d="${svgLine}" stroke="${isSelected? 'red' : 'blue'}"  stroke-width="1" fill="none"/>` +
    `<path  d="${svgArrowActive}" stroke="${isSelected? 'red' : 'blue'}" stroke-width="1"  fill="none"/>` +
    `<text x="${svgTextX}" y="${svgTextY}" text-anchor="middle" font-size="10" fill="${isSelected? 'red' : 'blue'}" >${activePower}</text>` +
    `<text x="${svgTextX1}" y="${svgTextY1}" text-anchor="middle" font-size="10" fill="${isSelected? 'red' : 'blue'}" >${reactivePower}</text>` +
    '</svg>' ;
  
  const urlActiveReactivePower = svgToURL(svgActiveReactivePower);
  const [imageSVGActiveReactivePower] = useImage(urlActiveReactivePower);

  const [isOpenUserCurvesDialog, setIsOpenUserCurvesDialog] = useState(false);
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [menuPosition, setMenuPosition] = useState<Coordinates>({ x: 0, y: 0 });
  const [isOpenEditorDialog, setIsOpenEditorDialog] = useState(false);
  const [groupPosition, setGroupPosition] = useState({ x: x, y: y });
  const [dragStartPosition, setDragStartPosition] = useState({ x: x, y: y, labelX: groupPosition.x + Constant.ELEMENT_SIZE, labelY: groupPosition.y + CONTROL_POS_FACTOR * (height / 2) });
  const [isResizing, setIsResizing] = useState(false);
  const [heightTransform, setHeightTransform] = useState(0);
  const [labelPosition, setLabelPosition] = useState({
    x: groupPosition.x + Constant.ELEMENT_SIZE,
    y: groupPosition.y + CONTROL_POS_FACTOR * (height / 2),
  });
  const [arrowPosition, setArrowPosition] = useState({
    x: groupPosition.x,
    y: groupPosition.y,
  });

  const transformRef = useRef<Konva.Transformer>(null);
  const transformMoveRef = useRef<Konva.Transformer>(null);
  const groupRef = useRef<any>();
  const topRef = useRef<any>();
  const bottomRef = useRef<any>();
  const labelRef = useRef<any>();
  const rightArrowRef = useRef<any>();
  
  const [loadingFlag, setLoadingFlag] = useState(false);
  const [initialValue, setInitialValue] = useState(properties);
  const [isDefaultVoltage, setIsDefaultVoltage] = useState(true);
  const [listUserCurves, setListUserCurves] = useState(properties.listUser?[...properties.listUser]:[])

  const [isOpenUserCurveProperties, setIsOpenUserCurveProperties] = useState(false)
  const [selectItem, setSelectItem] = useState<any>(null);

  const doGetSubConstant =(n_frequency:number,rotorResistance:number,subReactance:number,exReactance:number) =>{
    if(n_frequency === 0 || rotorResistance === 0) {
        return 0;
    }
    const subConstant = (subReactance + exReactance) / (2 * Constant.MS_PI * n_frequency * rotorResistance)
  
    return subConstant
  
  }
  const doGetSubPercentZ =(rotorResistance:number,statorResistance:number,exResistance:number,subReactance:number,exReactance:number) =>{//4
    let subPercent = Math.pow(statorResistance +rotorResistance +exReactance,2) + Math.pow(subReactance + exReactance, 2)
    subPercent = Math.sqrt(subPercent)

    return subPercent
}
const doGetValueSymCurrent = (n_frequency:number,initialCurrent:number,subConstant:number) => {
  if(n_frequency === 0 || subConstant === 0){
      return 0
  }
  const symmetricalCurrent = initialCurrent * Math.exp(-1 / (n_frequency * 2 * subConstant) )

  return symmetricalCurrent
}
  //Calc label
  let ratedCurrent = BeamsNumber(initialValue.loadFactor * initialValue.capacity * 1000 /initialValue.voltage / Math.sqrt(3.0),4)
  const doGetPercentZrMotorGrp = (stdCapacity:number) =>{
        let percentZ ={
          perRk3: 0,
          perXk3: 0,
          statorPerRk3 :0,
        }
        if(initialValue.percentZCalculate === 0){
          percentZ.perRk3 = initialValue.rotorResistance + initialValue.statorResistance
          percentZ.perXk3 = initialValue.subReactance
          percentZ.statorPerRk3 = initialValue.statorResistance
          if(initialValue.capacity === 0){
            percentZ.perRk3 = 0
            percentZ.perXk3 = 0
            percentZ.statorPerRk3 = 0
          }
          else{
            percentZ.perRk3 *= stdCapacity /(initialValue.loadFactor * initialValue.capacity)
            percentZ.perXk3 *= stdCapacity /(initialValue.loadFactor * initialValue.capacity)
            percentZ.statorPerRk3 *= stdCapacity / (initialValue.loadFactor * initialValue.capacity)
          }

        }
        else{
          if(initialValue.capacity1 === 0){
            percentZ.perRk3 = 0
            percentZ.perXk3 = 0
            percentZ.statorPerRk3 = 0
          }
          else{
            let percentR = 0
            if(initialValue.capacity1 !== 0){
                percentR = initialValue.percentZ /Math.sqrt(1 + initialValue.xr * initialValue.xr) * (stdCapacity/ initialValue.capacity1)
            }
            let xr = 0
            let percentX = 0
            if(initialValue.xr !== 0 && initialValue.capacity1 !== 0){
              xr = 1/ initialValue.capacity1
              percentX = initialValue.percentZ / Math.sqrt(1 + xr * xr) * (stdCapacity / initialValue.capacity1)
            }
            percentZ.perRk3 = percentR
            percentZ.perXk3 = percentX
            percentZ.statorPerRk3 = 0
          }
        }
        return percentZ
  }
  const doGetTextXR = () =>{
    const stdCapacity = infoCircuit.stdCapacity // hard code
    let xr = 0
    if(initialValue.percentZCalculate === 0){
      let percentZ = doGetPercentZrMotorGrp(stdCapacity)
      if(percentZ.perRk3 > 0){
        xr = percentZ.perXk3 /(percentZ.perRk3 - percentZ.statorPerRk3)
      }
      if(percentZ.perRk3 <= 0){
        xr = 0
      }
      else{
        xr = BeamsNumber(xr,4)
      }
    }
    else{
        xr = initialValue.xr
    }
    return xr
  }
  const doGetValueIsym = (stdCapacity:number,percentExR:number, percentExX:number) =>{
    let n_frequency = infoCircuit.frequency // hard code
      if(percentExR < 0){
        percentExR = 0
        percentExX =0
      }
      else{
        if(stdCapacity === 0){
          percentExR = 0
          percentExR = 0

        }
        else{
          percentExR *= initialValue.loadFactor * initialValue.capacity / stdCapacity
          percentExX *= initialValue.loadFactor * initialValue.capacity / stdCapacity
        }
      }
      // let ratedCurrent = doGetValueRatedCurrent(initialValue)
      let ratedCurrent = initialValue.loadFactor * initialValue.capacity * 1000 /initialValue.voltage / Math.sqrt(3.0)
      let subConstant = doGetSubConstant(n_frequency,initialValue.rotorResistance,initialValue.subReactance,percentExX)
      let subPercent = doGetSubPercentZ(initialValue.rotorResistance,initialValue.statorResistance,percentExR,initialValue.subReactance,percentExX)
      let initialCurrent = doGetValueInitCurrent1(ratedCurrent,subPercent)

      if(n_frequency === 0 || subConstant === 0){
        return 0
      }
      let isym  = doGetValueSymCurrent(n_frequency,initialCurrent,subConstant)
      return isym
    }
    const doGetValueInitCurrent1 = (ratedCurrent: number,subPercent: number)=>{
        if(subPercent === 0){
          return 0
        }
        let initialCurrent = ratedCurrent / subPercent *  100
        return initialCurrent
    }
  const doGetValueDirectPercentZe = (stdCapacity:number) =>{
    let isym = doGetValueIsym(stdCapacity,0,0)
    if(isym <= 0 || initialValue.voltage <= 0){
      return 0
    }
    let percentZ = stdCapacity * 1000 * 100 / (Math.sqrt(3.0) * initialValue.voltage * isym)
    return percentZ
  }
  const doGetTextDirectPercentZe =() =>{
    const stdCapacity = infoCircuit.stdCapacity // hard code
    let percentZ = 0
    if(initialValue.percentZCalculate === 0) {
      percentZ = doGetValueDirectPercentZe(stdCapacity)
      percentZ *= initialValue.loadFactor * initialValue.capacity / stdCapacity
    }
    else{
      percentZ = initialValue.percentZ * initialValue.capacity1 / stdCapacity
    }
    return BeamsNumber(percentZ,4)
  }

  const [isOnOpenMenu, setIsOnOpenMenu] = useState<boolean>(false);

  // const labelTexts = doGetRefV1(processMode, props as any, false);
  const [labelTexts, setLabelTexts] = useState<any>({title:'',body:'',textHeight:0})

  //#endregion

  //#region useEffect
  useEffect(() => {
    const labelFirst = doGetRefV1(processMode, props as any, false)
    const title = labelFirst.shift()
    let txt = ""
    if(labelFirst.length > 0){
      labelFirst.map((item:any, index:any) =>{
        txt += item.value +'\n'
      })
      txt = txt.slice(0, -1)
    }
    let labelTexts1 = new Konva.Text({
      text: txt,
      });
      setLabelTexts({title:title?.value,body:labelTexts1.attrs.text,textHeight:labelTexts1.textHeight})
  }, [processMode,diagramDataUpdate])

  useEffect(()=>{
    if(isSelected){
      if(isOnOpenMenu) {
        setIsOnOpenMenu(false)
      }
      else
      {
        setIsOpenMenu(false)
      }
    }
  },[clearMenuState])

  useEffect(()=>{
    if(elementReposition?.id)
    {
      const groupsData = currentTabId === 1 ? elementGroups : tcGroups[diagramData.find((x:any)=>x.id == id)?.parentTcId ?? 0]
      const peekGroupId = groupsData?.byEleId[elementReposition.id as any]?.at(-1) ?? -1
      const elementIds = groupsData?.byId[peekGroupId]?.elementIds ?? []
      if(elementIds.includes(id) || diagramData[0].shape.find((item:any)=>(item.isSelected && item.id == id)))
      {
        setGroupPosition({
          x: dragStartPosition.x,
          y: dragStartPosition.y,
        });

        labelRef.current.to({
          x: dragStartPosition.labelX,
          y: dragStartPosition.labelY,
        });
        
        groupRef.current.to({
          x: dragStartPosition.x,
          y: dragStartPosition.y,
        });

        updateElementPosition(id, dragStartPosition.x, dragStartPosition.y)
      }
    }
  },[elementReposition])

  useEffect(()=>{
    if(userCurveProperties?.controlId === id && userCurveProperties !== undefined) {
      let userCurve = properties.listUser.find(x => x.userCurveId === userCurveProperties.userCurveId)
      userCurve && setSelectItem(userCurve)
      setIsOpenUserCurveProperties(true)
    }
  },[userCurveProperties])

  useEffect(() => {
     setIsOpenMenu(false)
    
    if(processMode == ProcessMode.POWER_FLOW && drawPowerArrow.payload.length > 0 && id)
    {
      let result = drawPowerArrow.payload.find((arrows:any) => arrows.arrow?.element?.id && Number(arrows.arrow?.element?.id) == Number(id))
      if(result && result.arrow && result.text.length > 0){
        setPowerFlowArrow(result)
      }
    }
  }, [drawPowerArrow])

  useEffect(() => {
    if(processMode == ProcessMode.POWER_FLOW)
    {
      const pElement = diagramData[0].shape.find((x:any)=>x.id === id)
      let result = doDrawSymbolGroup(pElement,processMode)
      if(result && result.arrow && result.text.length > 0){
        setPowerFlowArrow(result)
      }else{
        setActivePower("")
        setReactivePower("")
        setSvgArrowReactive("")
        setSvgArrowActive("")
        setSvgLine("");
      }
    }
    else if(showPowerInfo == true || showPowerDirectInfo == true)
    {
      setShowPowerInfo(false);
      setShowPowerDirectInfo(false);
    }
  },[processMode, projectData.flowViewMethod, properties.viewFlowResult])

  useEffect(() => {
    setInitialValue(properties);
  },[properties])

  useEffect(() => {
    if (isSelected && !parentGroupId) {
      transformRef.current?.nodes([groupRef.current]);
    } else {
      transformRef.current?.nodes([]);
    }
    transformRef.current?.getLayer()?.batchDraw();
  }, [isSelected, parentGroupId, heightTransform]);

  useEffect(() => {
    if (!isSelected) setIsOpenMenu(false);
  }, [isSelected]);

  useEffect(() => {
    if(openDialogData?.id === id && openDialogData !== undefined) {
      openDialogEvents(({type: "OPEN_DIALOG", data: { }}))
      handleOpenEditorDialog()
    }
  }, [openDialogData]);

  useEffect(() => {
    if(openUserCurveData?.id === id && openUserCurveData !== undefined) {
      setIsOpenUserCurvesDialog(true)
    }
  }, [openUserCurveData]);

  useEffect(() => {
    // check grid size; label, icon position when rotate, move and change height
    // handleGridSize(groupPosition.x, groupPosition.y, height, rotation);
    calcLabelPos();
    calcArrowPos();
  }, [groupPosition, height, rotation]);

  useEffect(() => {
    switch(getSymbolAndBackColor(infoSkeleton,processMode,properties).symbolColor){
      case "#008040":
        setLineStatus(lineGreenSvg);
        setImageStatus(imageGreenSvg);
        setLineOhmStatus(lineOhmGreenSvg);
        break;
      case "#FF0000":
        setLineStatus(lineRedSvg);
        setImageStatus(imageRedSvg);
        setLineOhmStatus(lineOhmRedSvg);
        break;
      default:
        setLineStatus(lineSvg);
        setImageStatus(imageSvg);
        setLineOhmStatus(lineOhmSvg);
        break;
    }
  },[getSymbolAndBackColor(infoSkeleton,processMode,properties).symbolColor])
  //#endregion

  //#region Method
  const setPowerFlowArrow = (data:any) => {
    if(data.arrow.drawActiveNoneDirect){
      setShowPowerInfo(true)
      setShowPowerDirectInfo(false)
      if(data.arrow.arrowActiveAtTop){
          setSvgHeight('70')
          setSvgWidth('30')
          setSvgLine('M 20 50 L 20 0')
          setSvgArrowActive('M 16 5 L 20 0 L 24 5')
          setSvgTextX('-10')
          setSvgTextY('-5')
          setSvgTextRotation('270')
          setActivePower(data.text[0])
          setSvgPosX1(-55)
          setSvgPosY1(-30)
      }
      else if(data.arrow.arrowActiveAtRight) {
          setSvgHeight('30')
          setSvgWidth('70')
          setSvgLine('M 50 20 L 0 20')
          setSvgArrowActive('M 5 16  L 0 20 L 5 24')
          setSvgTextX('7')
          setSvgTextY('3')
          setSvgTextRotation('0')
          setActivePower(data.text[0])
          setSvgPosX1(-40)
          setSvgPosY1(20)
          
      }
      else if(data.arrow.arrowActiveAtLeft){
          setSvgHeight('30')
          setSvgWidth('70')
          setSvgLine('M 50 20 L 0 20')
          setSvgArrowActive('M 45 16 L 50 20 L 45 24')
          setSvgTextX('7')
          setSvgTextY('3')
          setSvgTextRotation('0')
          setActivePower(data.text[0])
          setSvgPosX1(-55)
          setSvgPosY1(-30)
          setSvgPosX1(-40)
          setSvgPosY1(30)
      }
      else {
          setSvgHeight('70')
          setSvgWidth('30')
          setSvgLine('M 20 50 L 20 0')
          setSvgArrowActive('M 16 45 L 20 50 L 24 45')
          setSvgTextX('-10')
          setSvgTextY('-5')
          setSvgTextRotation('270')
          setActivePower(data.text[0])
          setSvgPosX1(-55)
          setSvgPosY1(-30)
      }
    }
    if(data.arrow.drawReactiveNoneDirect){
      setShowPowerInfo(true)
      setShowPowerDirectInfo(false)
      if(data.arrow.arrowReactiveAtTop){
          setSvgHeight('70')
          setSvgWidth('30')
          setSvgLine('M 20 50 L 20 0')
          setSvgArrowReactive('M 16 5  L 20 0 L 24 5')
          setSvgTextX('-10')
          setSvgTextY('-5')
          setSvgTextRotation('270')
          setReactivePower(data.text[1])
          setSvgPosX2(-30)
          setSvgPosY2(-30)
      }
      else if(data.arrow.arrowReactiveAtRight) {
          setSvgHeight('30')
          setSvgWidth('70')
          setSvgLine('M 50 20 L 0 20')
          setSvgArrowReactive('M 5 16  L 0 20 L 5 24')
          setSvgTextX('7')
          setSvgTextY('3')
          setSvgTextRotation('0')
          setReactivePower(data.text[1])
          setSvgPosX2(-40)
          setSvgPosY2(40)
      }
      else if(data.arrow.arrowReactiveAtLeft){
          setSvgHeight('30')
          setSvgWidth('70')
          setSvgLine('M 50 20 L 0 20')
          setSvgArrowReactive('M 45 16 L 50 20 L 45 24')
          setSvgTextX('7')
          setSvgTextY('3')
          setSvgTextRotation('0')
          setReactivePower(data.text[1])
          setSvgPosX2(-40)
          setSvgPosY2(40)
      }
      else {
          setSvgHeight('70')
          setSvgWidth('30')
          setSvgLine('M 20 50 L 20 0')
          setSvgArrowReactive('M 16 45 L 20 50 L 24 45')
          setSvgTextX('-10')
          setSvgTextY('-5')
          setSvgTextRotation('270')
          setReactivePower(data.text[1])
          setSvgPosX2(-30)
          setSvgPosY2(-30)
      }
    }
    if(data.arrow.drawOtherDirect){
      setShowPowerInfo(false)
      setShowPowerDirectInfo(true)
      if(data.arrow.arrowActiveReactiveAtTop){
          setSvgHeight('50')
          setSvgWidth('70')
          setSvgLine('M 10 50 L 10 20')
          setSvgArrowActive('M 6 25 L 10 20 L 14 25')
          setSvgTextX('40')
          setSvgTextY('30')
          setSvgTextX1('40')
          setSvgTextY1('40')
          setSvgTextRotation('0')
          setActivePower(data.text[0])
          setReactivePower(data.text[1])
          setSvgPosX3(20)
          setSvgPosY3(-50)
      }
      else if(data.arrow.arrowActiveReactiveAtRight) {
          setSvgHeight('70')
          setSvgWidth('50')
          setSvgLine('M 50 10 L 20 10')
          setSvgArrowActive('M 25 6 L 20 10 L 25 14')
          setSvgTextX('25')
          setSvgTextY('20')
          setSvgTextX1('25')
          setSvgTextY1('30')
          setSvgTextRotation('0')
          setActivePower(data.text[0])
          setReactivePower(data.text[1])
          setSvgPosX3(-30)
          setSvgPosY3(30)
      }
      else if(data.arrow.arrowActiveReactiveAtLeft){
          setSvgHeight('70')
          setSvgWidth('50')
          setSvgLine('M 50 10 L 20 10')
          setSvgArrowActive('M 45 6 L 50 10 L 45 14')
          setSvgTextX('25')
          setSvgTextY('20')
          setSvgTextX1('25')
          setSvgTextY1('30')
          setSvgTextRotation('0')
          setActivePower(data.text[0])
          setReactivePower(data.text[1])
          setSvgPosX3(-30)
          setSvgPosY3(30)
      }
      else {
          setSvgHeight('50')
          setSvgWidth('70')
          setSvgLine('M 10 50 L 10 20')
          setSvgArrowActive('M 6 45 L 10 50 L 14 45')
          setSvgTextX('40')
          setSvgTextY('30')
          setSvgTextX1('40')
          setSvgTextY1('40')
          setSvgTextRotation('0')
          setActivePower(data.text[0])
          setReactivePower(data.text[1])
          setSvgPosX3(20)
          setSvgPosY3(-50)
      }
    }
  }

  const handleOKUserCurveDetailForm = async (data: any) => {
    const foundUserCurve = listUserCurves.find((curve:any) => curve.userCurveId === data.userCurveId)        
    if (foundUserCurve) { // update  
      setLoadingFlag(true);
      const request = { 
        userId: userId,
        projectId: projectData.projectId,
        elementId: parseInt(id),
        params:{...data},
        ownerProject: projectData.ownerId

      }
      const result = await post("/diagram/edit-curve-user", request);
      if (result.success) {
        setLoadingFlag(false);
        handleUpdateCurveUserSuccess(id, result.data);
        updateUserCurve(id,data);
      } else {
        handleSubmitError(result.error);
        setLoadingFlag(false);
      }  
        //setOpenChart(true, LineType.USER_CURVE, data.userCurveId??data.user_curve_id)
    } else { // add new
        addUserCurve(id, data)
    }
    setSelectItem(null)
    setIsOpenUserCurveProperties(false)
  }

  const handleCancelUserCurveDetailForm = () => {
    setSelectItem(null)
    setIsOpenUserCurveProperties(false)
    openUserCurvePropertiesEvent('', '')
  }

  const handleContextMenu = (e: any) => {
    e.evt.preventDefault();
    if(e.evt.shiftKey) return
    setIsOnOpenMenu(true)
    updateSelectControl(id);

    const menuHeight = getMenuOptionHeight()
    const clientWidth = lineDiagramRef.current.clientWidth
    const isTransCenter = currentTabId == 1 ? false : true;
    let {posX, posY} = calcContextMenuPosition(
      e, menuHeight, clientWidth, x, y, gridSizeController, isTransCenter
    )
    clearOptionMenu(!clearMenuState)

    setMenuPosition({
      x: posX,
      y: posY,
    });
    setIsOpenMenu(true);
  };

  const handleClick = (e: any) => {
    if (e.type === "click") setIsOpenMenu(false);
    if(parentGroupId && parentGroupId > 0) return;
    if (e.evt.shiftKey || isSelectTouchGroup) {
      updateSelectControl(id, true);
    } else {
      updateSelectControl(id);
    }
  };

  const handleOpenMenu = (value: boolean) => {
    setIsOpenMenu(value);
    updateSelectControl(id);
  };

  const handleOpenEditorDialog = async () => {
    // setIsOpenEditorDialog(true);
    let params ={
      userId: userId,
      projectId: projectData.projectId,
      elementId: id,
      elementType: type,
      data: properties,
      ownerProject: projectData.ownerId

    }
    setLoadingFlag(true);
    // getElementParam(params,id);
    const result = await post("/diagram/get-param", params);
    if (result.success) {
      handleGetSuccess(result.data);
    } else {
      handleSubmitError(result.error);
    }
  };

  const handleCancelEditorDialog = () => {
    setIsOpenEditorDialog(false);
  };

  const handleUpdate = async (data: any) => {
    const updateData = {
      ...data,
    };
    
    setIsOpenEditorDialog(false);
    // call api
    const request = { 
      userId: userId,
      projectId: projectData.projectId,
      elementType: getElementKindValue(props.type),
      elementId: id,
      param: {...updateData},
      ownerProject: projectData.ownerId
    }
    // updateElementPost(request, id);
    setLoadingFlag(true);
    const result = await post("/diagram/set-param", request);
    if (result.success) {
      handleUpdateSuccess(result.data);
      if(result.data && result.data.resultCode == 0){
        setUndoData({type:"UPDATE_PROPERTIES_ELEMENT", dataUndo:{}} as UndoModel);
        updateControlData(id, updateData);
        setInitialValue(data);
      }
    } else {
      handleSubmitError(result.error);
    }

    openDialogEvents({type: "RELOAD", data: { }});
  };

  const dragBoundFunc = (e: any, mode: string) => {
    // let rect = groupRef.current.getClientRect();
    const newPosition = calcDragBoundPosition(
      e, groupPosition, rotation, height, mode, gridSizeController
    );

    return {
      x: newPosition.newX,
      y: newPosition.newY,
    };
  };

  const calcLabelPos = (e?: any) => {
    let newX = groupPosition.x,
      newY = groupPosition.y;
    if (e?.type === "dragmove") {
      newX = groupRef.current.attrs.x;
      newY = groupRef.current.attrs.y;
    }

    const newPosition = {
      x: newX + Constant.ELEMENT_SIZE,
      y: newY + CONTROL_POS_FACTOR * (height / 2),
    };

    if (rotation !== 0) {
      newPosition.x = newX + CONTROL_POS_FACTOR * (height / 2);
      newPosition.y = newY - Constant.ELEMENT_SIZE;
    }

    setLabelPosition(newPosition);
  };

  const calcArrowPos = (e?: any) => {
    let newX = groupPosition.x,
      newY = groupPosition.y;
    if (e?.type === "dragmove") {
      newX = groupRef.current.attrs.x;
      newY = groupRef.current.attrs.y;
    }

    const newPosition = {
      x: newX,
      y: newY,
    };

    if (rotation !== 0) {
      newPosition.y = newY;
      rotation === -90 &&
        (newPosition.x = newX + height - Constant.ELEMENT_SIZE - 10);
      rotation === 90 && (newPosition.x = newX + 10);
    }

    setArrowPosition(newPosition);
  };

  const handleDragStart = (e: any) => {
    groupRef.current.moveToTop()
    transformRef.current?.moveToTop()
    labelRef.current?.moveToTop()
    rightArrowRef.current?.moveToTop()
    setDragStartPosition({x: groupPosition.x, y: groupPosition.y, labelX: labelPosition.x, labelY: labelPosition.y});
    setIsOpenMenu(false);
  }

  const handleDragmove = (e: any) => {
    calcLabelPos(e);
    calcArrowPos(e);
  };

  const onDragEnd = (e: any) => {
    if (
      e.currentTarget.attrs.x === groupPosition.x &&
      e.currentTarget.attrs.y === groupPosition.y
    ) {
      return;
    }

    let newX =
      Math.round(e.target.x() / Constant.POINTER_WIDTH) *
      Constant.POINTER_WIDTH;
    let newY =
      Math.round(e.target.y() / Constant.POINTER_HEIGHT) *
      Constant.POINTER_HEIGHT;
         
    // check new position is outside grid
    if (newX < 0 || newY < 0){
      setRepositionElement(id, !(elementReposition?.state??true))
      newX = dragStartPosition.x;
      newY = dragStartPosition.y;
    }
    
    setGroupPosition({
      x: newX,
      y: newY,
    });

    e.target.to({
      x: newX,
      y: newY,
    });
  };

  // Mobile Touch Events
  var isMoving = false
  var isLongPress = false
  
  const handleTouchStart = useCallback((e:any) => {
    setIsOpenMenu(false)
    isLongPress = true
    isMoving = false
    !isSelectTouchGroup && setTimeout(() => {
      if (isLongPress && !isMoving) {
        handleContextMenu(e)
      }
    }, 1000)
  },[x,y,isSelectTouchGroup])

  const handleTouchMove = useCallback(() => {
    isMoving = true
  },[x,y,isSelectTouchGroup])

  const handleTouchEnd = useCallback((e:any) => {
    isLongPress = false
  },[x,y,isSelectTouchGroup])

  const handleMouseDown = (e: any, mode: string) => {
    const container = e.target.getStage().container();
    container.style.cursor = "ns-resize";
    setIsResizing(true);
  };

  const handleMouseMove = (e: any, mode: string) => {
    if (!isResizing) return;
    setHeightTransform(e.evt.movementY);
  };

  const handleMouseUp = (e: any, mode: string) => {
    setTransformFlag(true);
    const container = e.target.getStage().container();
    container.style.cursor = "default";

    let newPosX = e.target._lastPos.x // e.evt.offsetX
    let newPosY = e.target._lastPos.y // e.evt.offsetY

    let newEle = calcElementSize(
      newPosX,
      newPosY,
      groupPosition,
      rotation,
      height,
      offsetY,
      mode,
      gridSizeController
    )

    topRef.current.y(0);
    // bottomRef.current.y(newHeight - 7)
    setGroupPosition({ x: newEle.newX, y: newEle.newY });

    updateControlHeight(id, newEle.newHeight, newEle.newX, newEle.newY, newEle.newOffsetY);
    setIsResizing(false);
  };

  const handleUpdateSuccess = async (data:any) =>{
    console.log(">>>> handle Success");
    if(data && data.resultCode == 0){
      const result = await getElements(userId, projectData.projectId, data.data.element_ids,projectData.ownerId)
      if (result.success) {
        mappedDataFromBE(result.data)
      } else {
        handleSubmitError(result.error)
      }
    }
    setLoadingFlag(false);
  }
const handleSubmitError = (data: any) =>{
    console.log(">>>> handle Error");
    setLoadingFlag(false);
  }
  const convertDataFromBE = (data :any) =>{
    let voltageList: OptionModel[] =[];
    if(data.voltageList && data.voltageList.length > 0){
      data.voltageList.forEach((element: any) =>{
        if(element.lvoltage !== null){
        let optionModel: OptionModel = {
          label: element.lvoltage.toString(),
          value: element.lvoltage
        };
        voltageList.push(optionModel)}
      });
    }
    return {
      voltageList: voltageList
    }

  }
  
const doGetConstant = (n_frequency:number,statorResistance:number,subReactance:number) =>{//4
  if(n_frequency === 0 || statorResistance === 0) {
      return 0
  }
  let constant = subReactance / (2 * Constant.MS_PI * n_frequency * statorResistance)

  return constant
}

const doGetValueRatedCurrent =(initData:any) =>{
  let voltage = +initData.voltage
  let loadFactor = +initData.loadFactor
  let capacity = +initData.capacity
 
  if(voltage <=0){
      return 0
  }
  let ratedCurrent = loadFactor * capacity * 1000 /voltage / Math.sqrt(3.0)
  return ratedCurrent

}
const doGetValueInitCurrent = (ratedCurrent:number,subPerCent:number) =>{
  const initialCurrent  = subPerCent === 0 ? 0 : ratedCurrent / subPerCent * 100
  return initialCurrent
} 
const doGetInitialCurrent = (initData:any,rotorResistance:number,statorResistance:number,exResistance:number,subReactance:number,exReactance:number) =>{
  let ratedCurrent = doGetValueRatedCurrent(initData)
  let subPerCent = doGetSubPercentZ(rotorResistance,statorResistance,exResistance,subReactance,exReactance)
  let initialCurrent  = doGetValueInitCurrent(ratedCurrent,subPerCent)

  return initialCurrent/1000

}

const doGetSymCurrent = (initData:any,n_frequency:number,rotorResistance:number,statorResistance:number,exResistance:number,subReactance:number,exReactance:number) =>{
  let ratedCurrent = doGetValueRatedCurrent(initData)
  let subConstant = doGetSubConstant(n_frequency,rotorResistance,subReactance,exReactance)
  let subPerCent = doGetSubPercentZ(rotorResistance,statorResistance,exResistance,subReactance,exReactance)
  let initialCurrent  = doGetValueInitCurrent(ratedCurrent,subPerCent)
  let symmetricalCurrent = doGetValueSymCurrent(n_frequency,initialCurrent,subConstant)
  
  return symmetricalCurrent/1000
}
const doGetValuePeakValue = (n_frequency:number, initialCurrent:number, symmetricalCurrent:number,constant:number) =>{
  let peakValue = 0
  if(n_frequency === 0 || constant === 0){
      peakValue = Math.sqrt(2.0) * symmetricalCurrent
  }
  else{
      peakValue = Math.sqrt(2.0) * symmetricalCurrent + Math.sqrt(2.0) * initialCurrent * Math.exp(-1 / (n_frequency * 2 * constant))
  }
  return peakValue
} 
const doGetPeakValue =(initData:any,n_frequency:number,rotorResistance:number,statorResistance:number,exResistance:number,subReactance:number,exReactance:number) =>{
  let ratedCurrent = doGetValueRatedCurrent(initData)
  let subConstant = doGetSubConstant(n_frequency,rotorResistance,subReactance,exReactance)
  let constant = doGetConstant(n_frequency,statorResistance,subReactance)
  let subPerCent = doGetSubPercentZ(rotorResistance,statorResistance,exResistance,subReactance,exReactance)
  let initialCurrent  = doGetValueInitCurrent(ratedCurrent,subPerCent)
  let symmetricalCurrent = doGetValueSymCurrent(n_frequency,initialCurrent,subConstant)
  let peakValue = doGetValuePeakValue(n_frequency, initialCurrent, symmetricalCurrent,constant)

  return peakValue / 1000
}
const doGetBackPercentRe = (capacity1:number,xr:number,percentZ:number) =>{
  let percentR = 0
  if(capacity1 !== 0) {
      percentR = percentZ / Math.sqrt(1 + xr * xr)
  }
  return percentR
}
const doGetBackPercentXe = (capacity1:number,xr:number,percentZ:number) =>{
  let percentX = 0
  if(xr !== 0 && capacity1 !== 0){
      const xr1 = 1/ xr
      percentX = percentZ / Math.sqrt(1 + xr1 * xr1)
  }
  return percentX
}
const doGetValueOhmR =(voltage:number,capacity1:number,percentR:number) =>{
  if(capacity1 === 0 || percentR === 0){
      return 0
  }
  const ohmR = voltage * voltage / capacity1 / 1000 * percentR * 10
  return ohmR
}
const doGetBackOhmZ = (percentZ:number,xr:number,voltage:number,capacity1:number) =>{
  let percentR = 0
  if(capacity1 !== 0){
      percentR = percentZ / Math.sqrt(1 + xr * xr)
  }
  let ohmR = doGetValueOhmR(voltage,capacity1,percentR)
  let percentX = 0
  if(xr !== 0 && capacity1 !== 0) {
      const xr1 = 1 / xr
      percentX = percentZ / Math.sqrt(1 + xr1 * xr1)
  }
  let ohmX = doGetValueOhmR(voltage,capacity1,percentX)//OhmR 同じ
  let ohmZ  = Math.sqrt(ohmR * ohmR + ohmX * ohmX)

  return ohmZ
}
const doGetBackOhmR = (percentZ:number,xr:number,voltage:number,capacity1:number) =>{
  let percentR = 0
  if(capacity1 !== 0) {
      percentR = percentZ / Math.sqrt(1 + xr * xr)
  }
  const ohmR = doGetValueOhmR(voltage,capacity1,percentR)

  return ohmR
}
const doGetBackOhmX = (percentZ:number,xr:number,voltage:number,capacity1:number) =>{
  let percentX = 0
  if(xr !== 0 && capacity1 !== 0){
      const xr1 = 1 / xr
      percentX = percentZ / Math.sqrt(1 + xr1 * xr1)
  }
  const ohmX = doGetValueOhmR(voltage,capacity1,percentX)//doGetValueOhmR = doGetValueOhmX

  return ohmX
}
  const doSetValuesMotorGrp2 =(initData:any,nControl:string) =>{
    const n_frequency = infoCircuit.frequency // hard code
    let voltage = +initData.voltage
    let capacity = +initData.capacity
    let capacity1 = +initData.capacity1
    let subReactance = +initData.subReactance
    let rotorResistance = +initData.rotorResistance
    let statorResistance = +initData.statorResistance
    let xr = +initData.xr
    let percentZ = +initData.percentZ

   

    // Calc
    let subImpedance  = doGetSubPercentZ(rotorResistance,statorResistance,0,subReactance,0)
    let constant = doGetConstant(n_frequency,statorResistance,subReactance)
    let subConstant = doGetSubConstant(n_frequency,rotorResistance,subReactance,0)
    let initialCurrent = doGetInitialCurrent(initData,rotorResistance,statorResistance,0,subReactance,0)
    let symmetricalCurrent = doGetSymCurrent(initData,n_frequency,rotorResistance,statorResistance,0,subReactance,0)
    let peakValue = doGetPeakValue (initData,n_frequency,rotorResistance,statorResistance,0,subReactance,0)

    let percentR = 0
    if(nControl !== "IDC_PERCENT_R") {
        percentR = doGetBackPercentRe(capacity1,xr,percentZ)
    }
    let percentX = 0
    if(nControl !== "IDC_PERCENT_X") {
        percentX = doGetBackPercentXe(capacity1,xr,percentZ)
    }
    let ohmZ = doGetBackOhmZ(percentZ,xr,voltage,capacity1)
    let ohmR = doGetBackOhmR(percentZ,xr,voltage,capacity1)
    let ohmX = doGetBackOhmX(percentZ,xr,voltage,capacity1)

    initData.subImpedance  = BeamsNumber(subImpedance,4)
    initData.constant  = BeamsNumber(constant,4)
    initData.subConstant  = BeamsNumber(subConstant,4)
    initData.initialCurrent  = BeamsNumber(initialCurrent,4)
    initData.symmetricalCurrent  = BeamsNumber(symmetricalCurrent,4)
    initData.peakValue  = BeamsNumber(peakValue,4)
    initData.percentR  = BeamsNumber(percentR,4)
    initData.percentX  = BeamsNumber(percentX,4)
    initData.ohmZ  = BeamsNumber(ohmZ,4)
    initData.ohmR  = BeamsNumber(ohmR,4)
    initData.ohmX  = BeamsNumber(ohmX,4)

}

  const handleGetSuccess =(data: any) =>{
    
    let newData  = data.data
    // initialValue.subReactance = newData.subReactance
    // initialValue.rotorResistance = newData.rotorResistance
    // initialValue.statorResistance = newData.statorResistance
    initialValue.capacityList = newData.capacityList
    let initData = {...initialValue}
    initData = addListToData(initData,newData)
    let convertData = convertDataFromBE(initData)
    initData.voltageList = convertData.voltageList;
    initData.capacityList = newData.capacityList;
    let shapes = diagramData[0].shape
    let res = doGetSourceVolt(
        {
          id: id,
          image: image,
          x: x,
          y: y,
          offsetX: offsetX,
          offsetY: offsetY,
          width: width,
          height: height,
          type: type,
          isSelected: isSelected,
          rotation: rotation,
          chart: chart,
          isRotationEnabled: false,
          parentGroupId,
          properties: properties
        },
        shapes,
        Constant.GET_TOP

      )
      if(res.voltage > 0){
        initData.isSourceVoltage = true
      }
      else{
        initData.isSourceVoltage = false
      }
      doSetValuesMotorGrp2(initData,'')
      // let n_frequency = 50
      // let constant = 0
      // let percentR = 0
      // let subConstant = 0
      // let percentX = 0
      // let symmetricalCurrent = 0
      let ratedCurrent = initData.loadFactor * initData.capacity * 1000 /initData.voltage / Math.sqrt(3.0)
      
      // //subImpedance
      // let subImpedance = Math.pow(newData.statorResistance + newData.rotorResistance + 0,2) + Math.pow(newData.subReactance + 0, 2)
      //     subImpedance = Math.sqrt(subImpedance)
      
      // //constant
      // if(n_frequency === 0 || newData.statorResistance === 0) {
      //     constant = 0
      //   }
      // else{
      //  constant = BeamsNumber(newData.subReactance / (2 * Constant.MS_PI * n_frequency * newData.statorResistance),4)
      // }

      // //subConstant
      // if(n_frequency === 0 || newData.rotorResistance === 0) {
      //   subConstant = 0;
      // }
      // else{
      //   subConstant = (newData.subReactance + 0) / (2 * Constant.MS_PI * n_frequency * newData.rotorResistance)
      // }
      // //percentZ
      // if(initData.capacity1 !== 0) {
      //   percentR = initData.percentZ / Math.sqrt(1 + initData.xr * initData.xr)
      // }

      // //percentX
      // if(initData.xr !== 0 && initData.capacity1 !== 0){
      //       const xr1 = 1/ initData.xr
      //       percentX = initData.percentZ / Math.sqrt(1 + xr1 * xr1)
      // }
      // //omhZ
      //   let ohmR = doGetValueOhmR(initData.voltage,initData.capacity1,percentR)
      //   let ohmX = doGetValueOhmR(initData.voltage,initData.capacity1,percentX)//OhmR 同じ
      //   let ohmZ  = Math.sqrt(ohmR * ohmR + ohmX * ohmX)

      // //initialCurrent
      // let initialCurrent  = subImpedance === 0 ? 0 : ratedCurrent / subImpedance * 100
      
      // //symmetricalCurrent
      // if(n_frequency === 0 || subConstant === 0){
      //   symmetricalCurrent = 0
      // }else{
      //  symmetricalCurrent = (initialCurrent * Math.exp(-1 / (n_frequency * 2 * subConstant)))
      // }

      // //peakValue
      // let peakValue = 0
      //   if(n_frequency === 0 || constant === 0){
      //       peakValue = Math.sqrt(2.0) * symmetricalCurrent
      //   }
      //   else{
      //       peakValue = Math.sqrt(2.0) * symmetricalCurrent + Math.sqrt(2.0) * initialCurrent * Math.exp(-1 / (n_frequency * 2 * constant))
      //   }
      
      initData.ratedCurrent = BeamsNumber(ratedCurrent,4)
      // initData.subImpedance = BeamsNumber(subImpedance,4)
      // initData.constant = constant
      // initData.subConstant = BeamsNumber(subConstant,4)
      // initData.percentR = BeamsNumber(percentR,4)
      // initData.percentX = BeamsNumber(percentX,4)
      // initData.ohmZ = BeamsNumber(ohmZ,4)
      // initData.ohmR = BeamsNumber(ohmR,4)
      // initData.ohmX = BeamsNumber(ohmX,4)
      // initData.initialCurrent = BeamsNumber(initialCurrent/1000,4)
      // initData.symmetricalCurrent = BeamsNumber(symmetricalCurrent/1000,4)
      // initData.peakValue = BeamsNumber(peakValue/1000,4)



    //   initData.calcPoint0 ={
    //     loadCurrent: 0,
    //     isLead: false,
    //     powerFactor: 1,
    //     calcKind: Constant.CALC_IK3,
    //     stdCapacity:0,
    //     loadCapacity: 0,
    //     voltage:initData.voltage
    // } as CalcPoint

    // initData.calcPoint1 ={
    //     loadCurrent: 0,
    //     isLead: false,
    //     powerFactor: 1,
    //     calcKind: Constant.CALC_IK2,
    //     stdCapacity:0,
    //     loadCapacity: 0,
    //     voltage:initData.voltage

    // } as CalcPoint
    
    setInitialValue(initData)
    setLoadingFlag(false)
    setIsOpenEditorDialog(true)
  }
  const handleAddUserCurve = async  (controlId:string,data:any)=>{
    const request = { 
      userId: userId,
      projectId: projectData.projectId,
      elementId: parseInt(controlId),
      params:{...data},
      ownerProject: projectData.ownerId

    }
    // addUserCurveDB(controlId,request)
    const result = await post("/diagram/add-curve-user", request);
    if (result.success) {
      handleAddCurveUserSuccess(id, result.data);
    } else {
      handleSubmitError(result.error);
  }
}
  const handleUpdateUserCurve = async (controlId:string,data:any) =>{
    const request = { 
      userId: userId,
      projectId: projectData.projectId,
      elementId: parseInt(controlId),
      params:{...data},
      ownerProject: projectData.ownerId

    }
    // updateUserCurveDB(controlId,request)
    const result = await post("/diagram/edit-curve-user", request);
    if (result.success) {
      handleUpdateCurveUserSuccess(id, result.data);
    } else {
      handleSubmitError(result.error);
    }
  }
  const handleDeleteUserCurve = async (controlId:string,data:any) =>{
    const request = { 
      userId: userId,
      projectId: projectData.projectId,
      elementId: parseInt(controlId),
      userCurveId: data["userCurveId"],
      ownerProject: projectData.ownerId

    }
    // deleteUserCurveDB(controlId,request)
    deleteRelatedGraphByUserCurveDialog({controlId, data: data["userCurveId"], mode : true})
    const result = await post("/diagram/delete-curve-user", request);
    if (result.success) {
      handleDeleteCurveUserSuccess(id, result.data);
    } else {
      handleSubmitError(result.error);
    }
  }
  const handleAddCurveUserSuccess = (controlId:string,data:any) =>{
    addUserCurve(controlId,data)
    setListUserCurves(prev => [...prev, data])
  }
  const handleUpdateCurveUserSuccess =(controlId:string,data:any) =>{
    const foundUserCurve = listUserCurves.findIndex((curve:any) => curve.userCurveId === data["userCurveId"])
    const newList = [...listUserCurves]
    newList[foundUserCurve] = data
    updateUserCurve(controlId,data)
    setListUserCurves(newList)

  }
  const handleDeleteCurveUserSuccess = (controlId:string,data:any) =>{
      const newList = [...listUserCurves]
      newList.splice(newList.findIndex((curve : any) => curve.userCurveId === data), 1)
      let params = {
        userCurveId: data,
        projectId: projectData.projectId,
      }
      deleteUserCurve(controlId,params)
      setListUserCurves([...newList])

  }
  //#endregion
  return (
    <>
      {isOpenMenu &&
        <OptionMenu
          controlId={id}
          isOpenMenu={isOpenMenu}
          setIsOpenMenu={handleOpenMenu}
          onProperties={handleOpenEditorDialog}
          x={menuPosition.x}
          y={menuPosition.y}
          isDisabledDelete={false}
          isDisabledCut={false}
          isDisabledCopy={false}
          isDisabledPaste={false}
          isDisabledOpenGraph={false}
          isDisabledProps={false}
          isDisabledUnGroup={false}
          onUserCurves={() => setIsOpenUserCurvesDialog(true)}
        />
      }
        <Label
        ref={labelRef}
        listening={false}
        width={Constant.LABEL_WIDTH}
        x={labelPosition.x}
        y={labelPosition.y}
        offsetX={[90, -90].includes(rotation) ? 8 : -5}
        offsetY={
          rotation === 0 ? (labelTexts.length * Constant.LINE_HEIGHT) / 2 : 0
        }
      >
        {/* {labelTexts.length > 0 &&
         labelTexts.map((item:any, index:any) => {
            
          let content = item.value; 
          let offsetY = index * Constant.LINE_HEIGHT;
          rotation !== 0 &&
            index !== 0 &&
            (offsetY = (index - 1) * Constant.LINE_HEIGHT + width + Constant.ELEMENT_SIZE);

          return (
            <Text
              y={offsetY}
              key={index}
              text={content}
              fill={isSelected ? "red" : item.color}
            />
          );
        })} */}
          <Text
              text={labelTexts.title}
              y={-28}
              fill={isSelected ? "red" : '#f800f8'}
          />
          {labelTexts.body.length > 0 &&
            <Text
                  y={Constant.LINE_HEIGHT - 29}
                  text={labelTexts.body}
                  fill={isSelected ? "red" : 'blue'}
            />
          }
      </Label>
      <Group
        ref={groupRef}
        id={id}
        x={groupPosition.x}
        y={groupPosition.y}
        width={width}
        height={height}
        draggable={processMode === ProcessMode.DRAWING && !m_bModePM && !modeViewOnly && !isOnShiftKey}
        type={type}
        onClick={handleClick}
        onTap={handleClick}
        onContextMenu={handleContextMenu}
        rotation={rotation}
        onDblClick={handleOpenEditorDialog}
        onDblTap={handleOpenEditorDialog}
        onMouseLeave={(e: any) => {
          const container = e.target.getStage().container();
          container.style.cursor = "default";
        }}
        onMouseUp={() => transformMoveRef.current?.nodes([])}
        onMouseDown={() => transformMoveRef.current?.nodes([groupRef.current])}
        onDragStart={handleDragStart}
        onDragMove={handleDragmove}
        onDragEnd={onDragEnd}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        {processMode !== ProcessMode.PERCENT_Z && 
          <Image
            fill={getSymbolAndBackColor(infoSkeleton,processMode,properties).backColor}
            image={lineObj}
            height={height}
            onMouseEnter={(e: any) => {
              const container = e.target.getStage().container();
              container.style.cursor = (processMode === ProcessMode.DRAWING && !m_bModePM && !modeViewOnly) ? "move" : "crosshair";
            }}
          />
        }

        {processMode === ProcessMode.PERCENT_Z && 
          <>
            <Image
                fill={getSymbolAndBackColor(infoSkeleton,processMode,properties).backColor}
                image={lineObj}
                height={height - 34 - Constant.ELEMENT_SIZE}
                onMouseEnter={(e: any) => {
                  const container = e.target.getStage().container();
                  container.style.cursor = "crosshair";
                }}
            />
            <Image
              fill={getSymbolAndBackColor(infoSkeleton,processMode,properties).backColor}
              image={lineOhmObj}
              height={34}
              width={13}
              x={3.5}
              y={6}
              onMouseEnter={(e: any) => {
                const container = e.target.getStage().container();
                container.style.cursor = "crosshair";
              }}
            />
            
          </>
        }
        
        <Image
          image={imageObj}
          fill={getSymbolAndBackColor(infoSkeleton,processMode,properties).backColor}
          width={Constant.ELEMENT_SIZE}
          height={Constant.ELEMENT_SIZE}
          y={height - Constant.ELEMENT_SIZE}
          onMouseEnter={(e: any) => {
            const container = e.target.getStage().container();
            container.style.cursor = (processMode === ProcessMode.DRAWING && !m_bModePM && !modeViewOnly) ? "move" : "crosshair";
          }}
        />
        {processMode === ProcessMode.DRAWING && !m_bModePM && !modeViewOnly && isSelected && !parentGroupId && (
          <>
            <Rect
              ref={topRef}
              fill="black"
              x={6.75}
              y={0}
              width={7}
              height={7}
              draggable
              onMouseDown={() => setTransformFlag(false)}
              dragBoundFunc={(e: any) => dragBoundFunc(e, "top")}
              onDragStart={(e: any) => handleMouseDown(e, "top")}
              onDragMove={(e: any) => handleMouseMove(e, "top")}
              onDragEnd={(e: any) => handleMouseUp(e, "top")}
              onMouseEnter={(e: any) => {
                const container = e.target.getStage().container();
                container.style.cursor = "ns-resize";
              }}
            />
            <Rect
              ref={bottomRef}
              fill="black"
              x={6.75}
              y={height - 7}
              width={7}
              height={7}
              onMouseDown={() => setTransformFlag(false)}
              draggable
              dragBoundFunc={(e: any) => dragBoundFunc(e, "bottom")}
              onDragStart={(e: any) => handleMouseDown(e, "bottom")}
              onDragMove={(e: any) => handleMouseMove(e, "bottom")}
              onDragEnd={(e: any) => handleMouseUp(e, "bottom")}
              onMouseEnter={(e: any) => {
                const container = e.target.getStage().container();
                container.style.cursor = "ns-resize";
              }}
            />
          </>
        )}
      </Group>
      { showPowerInfo &&
        <>
        <Image  
            image={imageSVGActivePower} 
            listening={false}
            x={arrowPosition.x + svgPosX1}
            y={arrowPosition.y + svgPosY1 + 40}
          />
 
          <Image  
            image={imageSVGReactivePower} 
            listening={false}
            x={arrowPosition.x + svgPosX2}
            y={arrowPosition.y + svgPosY2 + 40}
          />
        </>
           
      }
      { showPowerDirectInfo &&
        <>
          <Image  
            image={imageSVGActiveReactivePower} 
            listening={false}
            x={arrowPosition.x + svgPosX3}
            y={arrowPosition.y + svgPosY3 + 25}
          />
        </>
           
      }
      {(properties.fault && !([Model.ProcessMode.VOLTDROP,Model.ProcessMode.IMPROVE_PF,Model.ProcessMode.HARMONIC,Model.ProcessMode.POWER_FLOW].includes(processMode)) && !m_bModePM) && (
        <>
        <Image
          ref={rightArrowRef}
          listening={false}
          offsetY={[90, -90].includes(rotation) ? Constant.ELEMENT_SIZE / 2 : 0}
          offsetX={-Constant.ELEMENT_SIZE / 2}
          x={arrowPosition.x}
          y={arrowPosition.y}
          image={rightArrowObj}
          width={Constant.ELEMENT_SIZE * 2}
          height={Constant.ELEMENT_SIZE * 2}
        />
        <Text
          text={properties.pointText}
          fontSize={10}
          fill="red"
          offsetY={[90, -90].includes(rotation) ? Constant.ELEMENT_SIZE / 2 : 0}
          offsetX={-Constant.ELEMENT_SIZE / 2}
          x={arrowPosition.x + 10}
          y={arrowPosition.y}
        />
        </>
      )}

      <Transformer
        ref={transformRef}
        rotateEnabled={false}
        resizeEnabled={false}
        keepRatio={false}
        borderDash={[4]}
        anchorStroke="black"
        borderStroke="black"
        anchorFill="black"
      />

      <Transformer
        ref={transformMoveRef}
        rotateEnabled={false}
        resizeEnabled={false}
        keepRatio={false}
        borderEnabled={false}
        anchorStroke="black"
        borderStroke="black"
        anchorFill="black"
      />

      {isOpenEditorDialog && !isHidden && (
        <Html>
          <Dialog open={true} fullWidth
            PaperComponent={DraggablePaper}
          >
            <DialogTitle
              onMouseOver={(e : any) => e.target.style.cursor = 'move'}
              style={{
                paddingTop: "2px",
                paddingBottom: "2px",
                background: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
              }}
            >
             電動機群
            </DialogTitle>
            <DialogContent sx={{ pb: 1.5 }}>
              <MsMotorGroupEditDialog
                data={initialValue}
                onCancel={handleCancelEditorDialog}
                onOK={handleUpdate}
                infoCircuit ={infoCircuit}
                userRole={userRole}
                m_bModePM={m_bModePM}
                processMode={processMode}
                modeViewOnly={modeViewOnly}
              ></MsMotorGroupEditDialog>
            </DialogContent>
          </Dialog>
        </Html>
      )}

      {/* ============== USER CURVE ============== */}
      {isOpenUserCurvesDialog && !isHidden && (
      <Html>
        <UserCurvesDialog
          type={type}
         addUserCurve={handleAddUserCurve}
         updateUserCurve={handleUpdateUserCurve}
         deleteUserCurve={handleDeleteUserCurve}
         listUserCurves ={listUserCurves}
        controlId={id}
        controlProperties={properties}
        modeViewOnly={modeViewOnly}
        onCancel={() => {setIsOpenUserCurvesDialog(false); openUserCurveEvent('')}}
        onDraw={(data : any) => {   
          setOpenChart(true, LineType.USER_CURVE, data.userCurveId??data.user_curve_id)
          setIsOpenUserCurvesDialog(false)
        }}
        />
      </Html>
      )}

      {isOpenUserCurveProperties && !isHidden &&
        <Html>
            <UserCurveDetailDialog modeViewOnly={modeViewOnly} type={type} selectedRow={selectItem} properties={properties} onOK={handleOKUserCurveDetailForm} onCancel={handleCancelUserCurveDetailForm}></UserCurveDetailDialog>
        </Html>
      }
      {loadingFlag && (
        <Html>
          <Dialog open={true} maxWidth={"md"} fullWidth>
            <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1500, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
              <CircularProgress />
            </div>
          </Dialog>
        </Html>
      )}
    </>
  );
});

const mapStateToProps = (state: any) => {
  return {
    userId: state.app.user?.userId,
    projectData: state.app.projectData,
    processMode: state.app.diagram.processMode,
    diagramData: state.app.diagram.diagramData.filter((r: any) => r.tabId === state.app.diagram.currentIDDiagramTab),
    openDialogData : state.app.diagram.openDialog["OPEN_DIALOG"],
    openUserCurveData: state.app.diagram.openUserCurve["OPEN_USER_CURVE"],
    infoCircuit: state.app.diagram.infoCircuit,
    userCurveProperties: state.app.diagram.openUserCurveProperties["OPEN_USER_CURVE_PROPERTIES"],
    clearMenuState: state.app.diagram.clearOptionMenu,
    userRole: state.app.user?.userRole,
    m_bModePM: state.app.diagram.m_bModePM,
    modeViewOnly: state.app.diagram.modeViewOnly,
    infoSkeleton: state.app.diagram.infoSkeleton,
    drawPowerArrow: state.app.diagram.isDrawPowerArrow,
    isSelectTouchGroup: state.app.diagram.isSelectTouchGroup,
    diagramDataUpdate: state.app.diagram.diagramDataUpdate,
    currentTabId: state.app.diagram.currentIDDiagramTab,
    tcGroups: state.app.diagram.tcGroups,
    elementGroups: state.app.diagram.elementGroups,
    elementReposition: state.app.diagram.elementReposition,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateSelectControl: (controlId: string, isMultiple : boolean = false) =>
dispatch(updateSelectControl(controlId, isMultiple)),
    updateControlData: (id: string, data: object) =>
      dispatch(updatePropertiesOfControlAction(id, data)),
    updateControlHeight: (id: string, height: number, x: number, y: number, offsetY: number) =>
      dispatch(updateControlHeight(id, height, x, y, offsetY)),
    setTransformFlag: (visible: boolean) => dispatch(setTransformFlag(visible)),
    // updateElementPost: (data: any, controlId: string) => dispatch(actionCreators.fetch(`UPDATE_MOTOR_GROUP${controlId}`, "/diagram/set-param", "POST", data, false, true)),
    // getElementParam: (data: any, controlId: string) => dispatch(actionCreators.fetch(`GET_MOTOR_GROUP${controlId}`, "/diagram/get-param", "POST", data, false, true)),
    addUserCurve: (controlId : string, data : any) => dispatch(addUserCurve(controlId, data)),
    updateUserCurve: (controlId : string, data : any) => dispatch(updateUserCurve(controlId, data)),
    deleteUserCurve: (controlId : string, data : any) => dispatch(deleteUserCurve(controlId, data)),
    setOpenChart: (isOpenChart: boolean, lineType : string, lineId : number) => dispatch(openChart(isOpenChart, lineType, lineId)),
    clearOptionMenu: (state:boolean) => dispatch(clearOptionMenu(state)),
    // addUserCurveDB: (controlId:string,data : any) => dispatch(actionCreators.fetch(`ADD_CURVE_USER_MOTOR_GROUP${controlId}`, "/diagram/add-curve-user", "POST", data, false, true)),
    // updateUserCurveDB: (controlId:string,data : any) => dispatch(actionCreators.fetch(`UPDATE_CURVE_USER_MOTOR_GROUP${controlId}`, "/diagram/edit-curve-user", "POST", data, false, true)),
    // deleteUserCurveDB: (controlId:string,data : any) => dispatch(actionCreators.fetch(`DELETE_CURVE_USER_MOTOR_GROUP${controlId}`, "/diagram/delete-curve-user", "POST", data, false, true)),
    openDialogEvents: (param : any) => dispatch(openDialogEvents(param)),
    openUserCurveEvent: (params: any) => dispatch(openUserCurveEvents({type: "OPEN_USER_CURVE", data: { id: params }})),
    openUserCurvePropertiesEvent: (controlId: any, userCurveId: any) => dispatch(openUserCurvePropertiesEvents({type: "OPEN_USER_CURVE_PROPERTIES", data: { controlId: controlId, userCurveId: userCurveId }})),
    deleteRelatedGraphByUserCurveDialog: (params:any) => dispatch(deleteRelatedGraphByUserCurveDialog(params)),
    mappedDataFromBE: (elements:any) => dispatch(actionCreators.mappedData(elements)),
    setRepositionElement: (id:any, state:boolean) => dispatch(setRepositionElement(id, state)),
    setUndoData: (data:UndoModel) => dispatch(undo(data)),
    updateElementPosition:(id:any, x:any, y:any) => dispatch(updateElementPosition(id, x, y)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateMsMotorGroup);
