import { MS_KW_TO_HORSEPOWER } from "../models/Constants";

export const doGetValuPowerTotal =(powerExisting:number,powerIncrease:number,powerNew:number) =>{
    const powerTotal = powerExisting + powerIncrease + powerNew;
    return powerTotal
}
export const doGetValueHeatTotal =(heatExisting: number,heatIncrease: number,heatNew: number) =>{
    const heatTotal = heatExisting + heatIncrease + heatNew
    return heatTotal
}
export const doGetValueLightTotal=(lightExisting: number,lightIncrease: number,lightNew: number) =>{
    const lightTotal = lightExisting + lightIncrease + lightNew
    return lightTotal
}
export const doGetSubExisting = (unitChange:boolean,powerExisting:number,heatExisting:number,lightExisting:number) =>{
    let subExisting = 0
    if(unitChange){
        subExisting = powerExisting + heatExisting + lightExisting
    }
    else{
        subExisting = powerExisting/MS_KW_TO_HORSEPOWER + heatExisting + lightExisting
    }
    return subExisting
}
export const doGetSubIncrease = (unitChange:boolean, powerIncrease: number,heatIncrease : number,lightIncrease: number ) =>{
    let subIncrease = 0
    if(unitChange){
        subIncrease = powerIncrease + heatIncrease+ lightIncrease
    }
    else{
        subIncrease = powerIncrease / MS_KW_TO_HORSEPOWER + heatIncrease+ lightIncrease
    }
    return subIncrease
}
export const doGetSubNew = (unitChange:boolean,powerNew:number,heatNew:number,lightNew:number) =>{
    let subNew = 0
    if(unitChange){
        subNew = powerNew + heatNew + lightNew
    }
    else{
        subNew = powerNew/ MS_KW_TO_HORSEPOWER + heatNew + lightNew
    }
    return subNew
}
export const doGetValueSubTotal =(unitChange:boolean,powerExisting:number,heatExisting:number,lightExisting:number,powerIncrease:number,heatIncrease:number,
    lightIncrease:number,powerNew:number,heatNew:number,lightNew:number) =>{
    let existingSubTotal = 0
    let increaseSubtotal = 0
    let newSubTotal = 0
    if(unitChange){
        existingSubTotal = powerExisting + heatExisting + lightExisting
        increaseSubtotal = powerIncrease + heatIncrease + lightIncrease
        newSubTotal = powerNew + heatNew + lightNew

    }
    else{
        existingSubTotal = powerExisting/ MS_KW_TO_HORSEPOWER + heatExisting + lightExisting
        increaseSubtotal = powerIncrease/ MS_KW_TO_HORSEPOWER + heatIncrease + lightIncrease
        newSubTotal = powerNew/ MS_KW_TO_HORSEPOWER + heatNew + lightNew
    }
    const subTotal = existingSubTotal + increaseSubtotal + newSubTotal
    return subTotal
}