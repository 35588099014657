import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Box, CircularProgress, Dialog, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import theme from '../themes/globalStyles';

import menu_1 from "../images/icon/menu_1.png";
import menu_2 from "../images/icon/menu_2.png";
import menu_3 from "../images/icon/menu_3.png";
import menu_4 from "../images/icon/menu_4.png";
import menu_5 from "../images/icon/menu_5.png";
import menu_6 from "../images/icon/menu_6.png";
import menu_7 from "../images/icon/menu_7.png";
import menu_8 from "../images/icon/menu_8.png";
import menu_9 from "../images/icon/menu_9.png";
import menu_10 from "../images/icon/保護協調.png";
import menu_11 from "../images/icon/編集履歴.svg";
import { changeModePM, changeProcessMode, saveAllControlsToDiagram, saveListGraph, saveListGraphName, undo } from '../store/Actions';
import { connect } from 'react-redux';
import { Message, ProcessMode } from '../models/Index';
import MainHeader from './MainHeader';
import { MenuOptions } from '../models/Constants';
import { ApplicationState, actionCreators } from '../store';
import FetchHelper from '../components/FetchHelper';
import ModeCheckingDialog from '../components/dialogs/ModeCheckingDialog';
import { mapElementsResponseToLocal, mapProjectDataFromResponse } from '../utils/ElementFunction';
import { groupBy } from '../utils/groupBy';
import * as Element from "../models/ElementKind";
import * as Model from "../models/Index";
import DraggablePaper from '../components/common/DraggablePaper';
import { PowerFlowDialog } from '../components/dialogs/PowerFlowDialog';
import * as Constant from "../models/Constants";
import WarningDialog from '../components/dialogs/WarningDialog';
import { getElements, post } from '../components/CallApi';
import { MS_MODE_DRAWING, MS_MODE_POWER_FLOW } from '../statics';
import { GET_GRAPH_NAME } from '../store/AppStore';
import ShowMessModePowerFlow from '../components/dialogs/ShowMessModePowerFlow';
import { useNavigate } from "react-router-dom";
import { Path as RoutePath } from '../path';
import logo from "../images/icon/MSSV3_logo.png";

// fetch id
const MODE_DRAWING_REQ = "MODE_DRAWING_REQ"
const MODE_CHECK_REQ = "MODE_CHECK_REQ"
const MODE_PER_Z_REQ = "MODE_PER_Z_REQ"
const MODE_POWER_FLOW = "MODE_POWER_FLOW"
const MODE_CACULATION = "MODE_CACULATION"
const MODE_VOLT_DROP = "MODE_VOLT_DROP"
const MODE_IMPROVE_PF = "MODE_IMPROVE_PF"
const MODE_HARMONIC = "MODE_HARMONIC"
const MODE_PM = "MODE_PM"

type DashboardMainTopNavbarProps = {
    modeHome: boolean;
    userId: any;
    ownerProject?:number;
    showGraphScreen?: boolean;
    showReportSceen?: boolean;
    showMaintenanceScreen?: boolean;
    showHistoryDialog?: boolean;
    setShowReportSceen?: (data: any) => void;
    handleDisplayGraph?: () => void;
    handleDisplayDiagram?: () => void;
    handleMenuOptions?: (key : MenuOptions) => void;
    handleCheckCopyData?: () => void;
    handleOpenHistoryDialog?: () => void;
} & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

const DashboardMainTopNavbar = (props: DashboardMainTopNavbarProps) => {
    //#region Fields
    const {
        userId,
        ownerProject,
        modeHome,
        showReportSceen,
        showMaintenanceScreen,
        processMode,
        projectData,
        diagramDataList,
        userRole,
        user,
        m_bModePM,
        modeViewOnly,
        copyShapes,
        diagramData,
        isDeleteGraphScreen,
        currentIDChartTab,
        undoData,
        listGraphName,
        showGraphScreen,
        isShowMaintenanceScreen,
        showHistoryDialog,
        // preDiagramData,
        setShowReportSceen,
        changeProcessMode,
        handleMenuOptions,
        saveAllControlsToDiagram,
        onShowMessage,
        sendModePMRequest,
        changeModePM,
        setUndoData,
        saveListGraphNameToStore,
        handleDisplayGraph,
        handleDisplayDiagram,
        saveListGraphToStore,
        handleCheckCopyData,
        handleOpenHistoryDialog,
    } = props
    
    const { sendDrawingRequest, sendCheckRequest, sendPercentZRequest, sendModeCaculationRequest, sendModeVoltDropRequest, 
        sendModeImprovePFRequest, sendModeHarmonicRequest, sendModePowerFlowRequest, saveProjectData, getGraphName } = props //api

    const scrollElement = useRef<any>(null)
    const scrollHeader = useRef<any>(null)
    const refDiv = useRef<any>(null)
    const refDivHeader = useRef<any>(null)
    const [hasOverflow, setHasOverflow] = useState(false);
    const [hasScrollHeader, setHasScrollHeader] = useState(false);
    const [loadingFlag, setLoadingFlag] = useState(false);
    const [isOpenPowerFlowDialog,setOpenPowerFlowDialog] = useState(false);
    const [modeCheckingDialogInfo, setModeCheckingDialogInfo] = useState({
        isOpen: false,
        message: "",
    });
    const [showMessModePowerFlow, setShowMessModePowerFlow] = useState({
        isOpen: false,
        message: "",
        title: ""
    });
    const [hasSelectedElement, setHasSelectedElement] = useState(false);
    const [hasOpenGraphScreen, setOpenGraphScreen] = useState(false);
    const [hasCopyShapes, setHasCopyShapes] = useState(false);
    const [openOpenPercentZDialog,setOpenPercentZModeDialog] = useState<boolean>(false);
    const [canOpenUpdateGraphName, setCanOpenUpdateGraphName] = useState<boolean>(false);
    //#endregion


    //#region Style
    const styleIcon = {
        width: '100px',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column' as any,
    }
    const styleIcon2 = {
        width: '116px',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column' as any,
    }
    const styleSelect = {
        width: '100px',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column' as any,
        boxShadow: 'inset 0 0 5px rgba(0, 0, 0, 0.5)'
    }
    const styleSelect2 = {
        width: '116px',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column' as any,
        boxShadow: 'inset 0 0 5px rgba(0, 0, 0, 0.5)'
    }
    const styleDisabled = {
        ...styleIcon,
        color: '#666',
        opacity: '0.2',
        pointerEvents: 'none',
    }

    const styleImpedanceMap = {
        width: '130px',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column' as any,
    }
    const styleImpedanceMapSelect = {
        width: '130px',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column' as any,
        boxShadow: 'inset 0 0 5px rgba(0, 0, 0, 0.5)'
    }
    const styleDisabledImpedance = {
        ...styleImpedanceMap,
        color: '#666',
        opacity: '0.2',
        pointerEvents: 'none',
    }

    const navigate = useNavigate();
    //#endregion

    //#region UseEffect
    useEffect(() => {
        const selected = diagramData[0].shape.filter(
            (shape: any) => shape.isSelected === true
        );
        selected.length > 0 
        ? setHasSelectedElement(true)
        :  setHasSelectedElement(false)
    }, [diagramData])

    useEffect(() => {
       if(isDeleteGraphScreen && isDeleteGraphScreen.payload && isDeleteGraphScreen.payload.isOpenGraphScreen && currentIDChartTab > 0){
        setOpenGraphScreen(true)
       }
       else
       {
        setOpenGraphScreen(false)
       }

    }, [isDeleteGraphScreen])
    
    useEffect(() => {
        copyShapes.length > 0 
        ? setHasCopyShapes(true)
        :  setHasCopyShapes(false)
    }, [copyShapes])

    useEffect(() => {
        if(projectData.projectId){
            setLoadingFlag(true);
            // getGraphName
            let param = {
                userId: userId,
                projectId: projectData.projectId,
                ownerProject: projectData.createUserId
            }
            getGraphList(param);
        }
    },[projectData.projectId])

    const getGraphList = async (param:any) => {
        const result = await post(`/graph/get-graph-list`,param);
        if (result.success) {
            const graphList = result.data;
            const projectList = [];
            for(const item of graphList.data){
                let project = {
                    approver:item.approver,
                    checker:item.checker,
                    colorInFrame:item.color_in_frame,
                    comments:item.comments,
                    dateApproved:item.date_approved,
                    dateChecked:item.date_checked,
                    dateDesigned:item.date_designed,
                    dateDrawn:item.date_drawn,
                    designer:item.designer,   
                    dispBand:item.disp_band,    
                    drawner:item.drawner, 
                    figNumber:item.fig_number,      
                    figSubTitle:item.fig_sub_title,           
                    figTitle:item.fig_title,    
                    graphKind:item.graph_kind,      
                    graphName:item.graph_name,      
                    graphNo:item.graph_no,
                    kindInFrame:item.kind_in_frame,
                    marginBottom:item.margin_bottom,
                    marginLeft:item.margin_left,
                    marginRight:item.margin_right,
                    marginTop:item.margin_top,
                    nominalVolt:item.nominal_volt,
                    normalXMax:item.normal_x_max,
                    normalXMin:item.normal_x_min,
                    normalYMax:item.normal_y_max,
                    normalYMin:item.normal_y_min,
                    orientation:item.orientation,
                    ownerId:item.owner_id,
                    printDetailHead:item.print_detail_head_view2,
                    projectId:item.project_id,
                    reference:item.reference,
                    revision1:item.revision1,
                    revision2:item.revision2,                    
                    revision3:item.revision3,
                    revision4:item.revision4,
                    revision5:item.revision5,
                    society:item.society,
                    societyXMax:item.society_x_max,
                    societyXMin:item.society_x_min,
                    societyYMax:item.society_y_max,
                    societyYMin:item.society_y_min,
                    thickInFrame:item.thick_in_frame,
                    titleApproved:item.title_approved,
                    titleChecked:item.title_checked,
                    titleComment:item.title_comment,
                    titleDateApproved:item.title_date_approved,
                    titleDateChecked:item.title_date_checked,
                    titleDateDesigned:item.title_date_designed,
                    titleDateDrawn:item.title_date_drawn,
                    titleDesigned:item.title_designed,
                    titleDrawn:item.title_drawn,
                    titleMode:item.title_mode,
                    titleNumber:item.title_number,
                    titleReference:item.title_reference,
                    zoom:item.zoom,
                };
                projectList.push(project);
            }
            saveListGraphToStore(projectList);
        } else {
            
        }
        // get graphName
        getGraphName(param);
    }

    useEffect(() => {
        if(modeHome && showReportSceen !== true && showMaintenanceScreen !== true){
            const container = scrollElement.current;
            const resizeObserver = new ResizeObserver(entries => {
                for (const entry of entries) {
                    const { scrollWidth } = entry.target;
                    const hasOverflowX = refDiv.current.clientWidth < scrollWidth;
                    setHasOverflow(hasOverflowX);
                }
            });
            resizeObserver.observe(container);
            return () => resizeObserver.unobserve(container);
        }
    }, [showReportSceen, showMaintenanceScreen]);

    useEffect(() => {
        if(modeHome && showReportSceen !== true && showMaintenanceScreen !== true){
            const containerHeader = scrollHeader.current;
            const resizeObserverHeader = new ResizeObserver(entries => {
                for (const entry of entries) {
                    const { scrollWidth } = entry.target;
                    const hasOverflowXHeader = refDivHeader.current.clientWidth < scrollWidth;
                    setHasScrollHeader(hasOverflowXHeader);
                }
            });
            resizeObserverHeader.observe(containerHeader);
            return () => resizeObserverHeader.unobserve(containerHeader);
        }
    }, [showReportSceen, showMaintenanceScreen]);
    //#endregion

    //#region Other Methods
    const scrollLeft = () => {
        scrollElement.current.scrollLeft -= 120
    }

    const scrollRight = () => {
        scrollElement.current.scrollLeft += 120
    }

    const scrollHeaderLeft = () => {
        scrollHeader.current.scrollLeft -= 120
    }

    const scrollHeaderRight = () => {
        scrollHeader.current.scrollLeft += 120
    }


    const getStyleOfMenu = (menuId : number) => {
        switch(menuId) {
            case 1:
                if (processMode === ProcessMode.DRAWING) return styleSelect
                return styleIcon
            case 2:
                if(modeViewOnly) return styleDisabled
                else if (processMode === ProcessMode.CHECKING) return styleSelect
                else if (processMode >= ProcessMode.PERCENT_Z) return styleDisabled
                else return styleIcon
            case 3:
                if(modeViewOnly) return styleDisabled
                else if (processMode === ProcessMode.PERCENT_Z) return styleImpedanceMapSelect
                else if (processMode === ProcessMode.DRAWING) return styleDisabledImpedance
                else return styleImpedanceMap
            case 4:
                if(modeViewOnly) return styleDisabled
                else if (processMode === ProcessMode.CALCULATION) return styleSelect
                if (processMode <= ProcessMode.CHECKING) return styleDisabled
                else return styleIcon
            case 5:
                if(modeViewOnly) return styleDisabled
                else if (processMode === ProcessMode.VOLTDROP) return styleSelect
                if (processMode <= ProcessMode.CHECKING) return styleDisabled
                else return styleIcon
            case 6:
                if(modeViewOnly) return styleDisabled
                else if (processMode === ProcessMode.IMPROVE_PF) return styleSelect
                if (processMode <= ProcessMode.CHECKING) return styleDisabled
                else return styleIcon
            case 7:
                if(modeViewOnly) return styleDisabled
                else if (processMode === ProcessMode.HARMONIC) return styleSelect
                if (processMode <= ProcessMode.CHECKING) return styleDisabled
                else return styleIcon
            case 8:
                if(modeViewOnly) return styleDisabled
                else if (processMode === ProcessMode.POWER_FLOW) return styleSelect
                if (processMode <= ProcessMode.CHECKING) return styleDisabled
                else return styleIcon
            default:
                return styleIcon
        }
    }
    //#endregion

    //#region HANDLE PROCESS MODE
    const onModeDrawing = () => {
        if(processMode != Model.ProcessMode.DRAWING){
            setLoadingFlag(true)
            sendDrawingRequest({
                userId: userId,
                projectId: projectData.projectId,
                ownerProject: ownerProject
            })
        }
    }

    const handleModeDrawingResponse = (data:any) => {
        setLoadingFlag(false);
        if(data && data.resultCode == 0){
            changeProcessMode(MS_MODE_DRAWING);
            setUndoData({type:"CHANGE_MODE", dataUndo:{}} as Model.UndoModel)
        }
    }

    const onModeChecking = () => {
        if(processMode != Model.ProcessMode.CHECKING){
            setLoadingFlag(true)
            sendCheckRequest({
                userId: userId,
                projectId: projectData.projectId,
                ownerProject:ownerProject,
                step: 1
            })
        }
    }
    
    const onModePercentZ = () => {
        if(processMode != Model.ProcessMode.PERCENT_Z){
            setLoadingFlag(true)
            sendPercentZRequest({
                userId: userId,
                projectId: projectData.projectId,
                opeMode: projectData.opeMode,
                ownerProject: ownerProject
            })
        }
    }

    const handleModeCheckingResponse = async (data: any) => {
        if (data && data.element_ids){
            const result = await getElements(userId, projectData.projectId.toString(), data.element_ids,projectData.createUserId)
            if (result.success) {
                handleModeCheckingResponseSub(data, result.data);
            }
            else {
                handleGetElementsError(result.error);
            }
        }

        setUndoData({type:"CHANGE_MODE", dataUndo:{}} as Model.UndoModel)
    }

    const handleModeCheckingResponseSub = (data: any, elementsData: any) => {
        const mappedElements = mapElementsResponseToLocal(elementsData, diagramDataList);
        
        // TransCenter -- Add tab per transCenter, edit props.tabId and add children to transCenter's subTab
        let transCenterList = mappedElements.filter((e) => e.type === Element.MS_TRANSCENTER)
        let eleOfTransCenter = mappedElements.filter((e) => e.parentTcID !== null)
        let groupByTcID = groupBy(eleOfTransCenter, 'parentTcID')
        if (transCenterList.length > 0){
            transCenterList.forEach((transCenter: any) => {
                const tabId = diagramDataList.find(d => d.tabId === 1)?.shape.find(e => e.id === transCenter.id)?.properties.tabId || 0
                let shape = []
                if (groupByTcID[transCenter.id] !== undefined)
                    shape = groupByTcID[transCenter.id]
                saveAllControlsToDiagram({ tabId, shape } as Model.DiagramModel, false)
                transCenter.properties.tabId = tabId
            })
        }

        // remove eleOfTransCenter in mappedElements
        const mainDiagramShape = mappedElements.filter((e) => !eleOfTransCenter.map(e2 => e2.id).includes(e.id))
        saveAllControlsToDiagram(
            {
                tabId: 1, 
                shape: mainDiagramShape,
            } as Model.DiagramModel, 
            false
        )
        
        setLoadingFlag(false)
        const actionButtons : any = {}
        switch (data.current_step) {
            case 1:
                {
                    actionButtons.onYES = () => {
                        setModeCheckingDialogInfo(prev => ({...prev, isOpen: false}))
                    }
                    actionButtons.onNO = () => {
                        setModeCheckingDialogInfo(prev => ({...prev, isOpen: false}))
                        setLoadingFlag(true)
                        sendCheckRequest({
                            userId: userId,
                            projectId: projectData.projectId,
                            ownerProject:ownerProject,
                            step: 2
                        })
                    }
                    break;
                }
            case 2:
                actionButtons.onOK = () => {
                    setModeCheckingDialogInfo(prev => ({...prev, isOpen: false}))
                }
                break;
            case 3:
                {
                    actionButtons.onYES = () => {
                        setModeCheckingDialogInfo(prev => ({...prev, isOpen: false}))
                    }
                    actionButtons.onNO = () => {
                        setModeCheckingDialogInfo(prev => ({...prev, isOpen: false}))
                        setLoadingFlag(true)
                        sendCheckRequest({
                            userId: userId,
                            projectId: projectData.projectId,
                            ownerProject:ownerProject,
                            step: 3,
                            recent_checked_ids: data.recent_checked_ids,
                            b_check_1: data.b_check_1,
                            n_ele_kind: data.n_ele_kind
                        })
                    }
                    actionButtons.onCANCEL = () => {
                        setModeCheckingDialogInfo(prev => ({...prev, isOpen: false}))
                        setLoadingFlag(true)
                        sendCheckRequest({
                            userId: userId,
                            projectId: projectData.projectId,
                            ownerProject:ownerProject,
                            step: 4,
                            b_check_1: data.b_check_1,
                            n_ele_kind: data.n_ele_kind
                        })
                    }
                    break;
                }
            case 4:
                { 
                    if (!data.b_check_1) {
                        actionButtons.onOK = () => {
                            setModeCheckingDialogInfo(prev => ({...prev, isOpen: false}))
                        }
                    } else if (!data.b_check_2 || !data.b_check_3) {
                        actionButtons.onYES = () => {
                            setModeCheckingDialogInfo(prev => ({...prev, isOpen: false}))
                        }
                        actionButtons.onNO = () => {
                            setModeCheckingDialogInfo(prev => ({...prev, isOpen: false}))
                            setLoadingFlag(true)
                            sendCheckRequest({
                                userId: userId,
                                projectId: projectData.projectId,
                                ownerProject:ownerProject,
                                step: 6,
                                // b_check_1: data.b_check_1,
                                // b_check_2: data.b_check_2,
                                // b_check_3: data.b_check_3,
                            })
                        }
                    }
                    break;
                }
            case 5:
                {
                // changeProcessMode(Model.ProcessMode.CHECKING)
                const mappedProject = mapProjectDataFromResponse(data.project_data);
                actionButtons.onOK = () => {
                    setModeCheckingDialogInfo(prev => ({...prev, isOpen: false}))
                }
                saveProjectData(mappedProject)
                changeProcessMode(mappedProject.opeMode);
                break;
            }
            case 6:
                {
                // changeProcessMode(Model.ProcessMode.CHECKING)
                const mappedProject = mapProjectDataFromResponse(data.project_data);
                saveProjectData(mappedProject)
                changeProcessMode(mappedProject.opeMode);
                break;
                }
        }

        if (data.current_step !== 6) {
            setModeCheckingDialogInfo({
                isOpen: true,
                message: data.message,
                ...actionButtons
            })
        }
    }
    
    const handleModePercentZResponse = async (data: any) => {
        if(data && data.resultCode == 0){
            const result = await getElements(userId, projectData.projectId.toString(), data.data.element_ids,projectData.createUserId)
            if (result.success) {
                handleModePercentZResponseSub(data.data.project_data,result.data)
            } else {
                handleGetElementsError(result.error)
            }
        }

        setUndoData({type:"CHANGE_MODE", dataUndo:{}} as Model.UndoModel)
    }

    const handleModePercentZResponseSub = (projectData:any,elementsData: any) => {
        const mappedElements = mapElementsResponseToLocal(elementsData, diagramDataList);
        const mappedProject = mapProjectDataFromResponse(projectData);
        // TransCenter -- Add tab per transCenter, edit props.tabId and add children to transCenter's subTab
        let transCenterList = mappedElements.filter((e) => e.type === Element.MS_TRANSCENTER)
        let eleOfTransCenter = mappedElements.filter((e) => e.parentTcID !== null)
        let groupByTcID = groupBy(eleOfTransCenter, 'parentTcID')
        if (transCenterList.length > 0){
            transCenterList.forEach((transCenter: any) => {
                const tabId = diagramDataList.find(d => d.tabId === 1)?.shape.find(e => e.id === transCenter.id)?.properties.tabId || 0
                let shape = []
                if (groupByTcID[transCenter.id] !== undefined)
                    shape = groupByTcID[transCenter.id]
                saveAllControlsToDiagram({ tabId, shape } as Model.DiagramModel, false)
                transCenter.properties.tabId = tabId
            })
        }

        // remove eleOfTransCenter in mappedElements
        const mainDiagramShape = mappedElements.filter((e) => !eleOfTransCenter.map(e2 => e2.id).includes(e.id))
        saveAllControlsToDiagram(
            {
                tabId: 1, 
                shape: mainDiagramShape,
            } as Model.DiagramModel, 
            false
        )
        setLoadingFlag(false)
        saveProjectData(mappedProject)
        changeProcessMode(mappedProject.opeMode);
        // changeProcessMode(Model.ProcessMode.PERCENT_Z)
        setOpenPercentZModeDialog(true);
    }

    const handleOpenPowerFlowDialog = () => {
        setOpenPowerFlowDialog(true);
    }

    const onModePowerFlow = (data:any) => {
        setLoadingFlag(true);
        sendModePowerFlowRequest({
            userId: userId,
            projectId: projectData.projectId,
            data:data,
            ownerProject: ownerProject
        })
    }

    const handleModePowerFlowResponse = async (data:any) => {
        if(data && data.resultCode == 0){
            // changeProcessMode(MS_MODE_POWER_FLOW);
            const result = await getElements(userId, projectData.projectId.toString(), data.data.element_ids,projectData.createUserId)
            if (result.success) {
                handleModePowerFlowResponseSub(data.data.project_data, result.data)
            } else {
                handleGetElementsError(result.error)
            }
            if(data.data.message !== ""){
                setShowMessModePowerFlow({isOpen: true,message: data.data.message,title:data.data.title})
            }
        }

        setUndoData({type:"CHANGE_MODE", dataUndo:{}} as Model.UndoModel)
    }

    const handleModePowerFlowResponseSub = (project_data: any, elementsData: any) => {
        // changeProcessMode(Model.ProcessMode.POWER_FLOW)
        const mappedElements = mapElementsResponseToLocal(elementsData, diagramDataList);
        const mappedProject = mapProjectDataFromResponse(project_data);
        // TransCenter -- Add tab per transCenter, edit props.tabId and add children to transCenter's subTab
        let transCenterList = mappedElements.filter((e) => e.type === Element.MS_TRANSCENTER)
        let eleOfTransCenter = mappedElements.filter((e) => e.parentTcID !== null)
        let groupByTcID = groupBy(eleOfTransCenter, 'parentTcID')
        if (transCenterList.length > 0){
            transCenterList.forEach((transCenter: any) => {
                const tabId = diagramDataList.find(d => d.tabId === 1)?.shape.find(e => e.id === transCenter.id)?.properties.tabId || 0
                let shape = []
                if (groupByTcID[transCenter.id] !== undefined)
                    shape = groupByTcID[transCenter.id]
                saveAllControlsToDiagram({ tabId, shape } as Model.DiagramModel, false)
                transCenter.properties.tabId = tabId
            })
        }

        // remove eleOfTransCenter in mappedElements
        const mainDiagramShape = mappedElements.filter((e) => !eleOfTransCenter.map(e2 => e2.id).includes(e.id))
        saveAllControlsToDiagram(
            {
                tabId: 1, 
                shape: mainDiagramShape,
            } as Model.DiagramModel, 
            false
        )
        saveProjectData(mappedProject)
        changeProcessMode(mappedProject.opeMode)
        setLoadingFlag(false);
        setOpenPowerFlowDialog(false);
    }

    const onModeCaculation = () => {
        if(processMode != Model.ProcessMode.CALCULATION){
            setLoadingFlag(true)
            sendModeCaculationRequest({
                userId: userId,
                projectId: projectData.projectId,
                ownerProject:ownerProject
            })
        }
    }

    const handleModeCaculationResponse = async (data: any) => {
        if(data && data.resultCode == 0){
            const result = await getElements(userId, projectData.projectId.toString(), data.data.element_ids,projectData.createUserId)
            if (result.success) {
                handleModeCaculationResponseSub(data.data.project_data,result.data)
            } else {
                handleGetElementsError(result.error)
            }
        }
        setUndoData({type:"CHANGE_MODE", dataUndo:{}} as Model.UndoModel)
    }

    const handleModeCaculationResponseSub = (projectData:any,elementsData: any) => {
        const mappedElements = mapElementsResponseToLocal(elementsData, diagramDataList);
        const mappedProject = mapProjectDataFromResponse(projectData);
        // TransCenter -- Add tab per transCenter, edit props.tabId and add children to transCenter's subTab
        let transCenterList = mappedElements.filter((e) => e.type === Element.MS_TRANSCENTER)
        let eleOfTransCenter = mappedElements.filter((e) => e.parentTcID !== null)
        let groupByTcID = groupBy(eleOfTransCenter, 'parentTcID')
        if (transCenterList.length > 0){
            transCenterList.forEach((transCenter: any) => {
                const tabId = diagramDataList.find(d => d.tabId === 1)?.shape.find(e => e.id === transCenter.id)?.properties.tabId || 0
                let shape = []
                if (groupByTcID[transCenter.id] !== undefined)
                    shape = groupByTcID[transCenter.id]
                saveAllControlsToDiagram({ tabId, shape } as Model.DiagramModel, false)
                transCenter.properties.tabId = tabId
            })
        }
        // remove eleOfTransCenter in mappedElements
        const mainDiagramShape = mappedElements.filter((e) => !eleOfTransCenter.map(e2 => e2.id).includes(e.id))
        saveAllControlsToDiagram(
            {
                tabId: 1, 
                shape: mainDiagramShape,
            } as Model.DiagramModel, 
            false
        ) 
        saveProjectData(mappedProject)
        changeProcessMode(mappedProject.opeMode);
        // changeProcessMode(Model.ProcessMode.CALCULATION)
        setLoadingFlag(false);
    }

    const onModeVoltDrop = () => {
        if(processMode != Model.ProcessMode.VOLTDROP){
            setLoadingFlag(true)
            sendModeVoltDropRequest({
                userId: userId,
                projectId: projectData.projectId,
                ownerProject: ownerProject
            })
        }
    }

    const handleModeVoltDropResponse = async (data:any) => {
        if(data && data.resultCode == 0){
            const result = await getElements(userId, projectData.projectId.toString(), data.data.element_ids,projectData.createUserId)
            if (result.success) {
                handleModeVoltDropResponseSub(data.data.project_data,result.data)
            } else {
                handleGetElementsError(result.error)
            }
        }

        setUndoData({type:"CHANGE_MODE", dataUndo:{}} as Model.UndoModel)
    }

    const handleModeVoltDropResponseSub = (projectData:any,elementsData: any) => {
        const mappedElements = mapElementsResponseToLocal(elementsData, diagramDataList);
        const mappedProject = mapProjectDataFromResponse(projectData);
        // TransCenter -- Add tab per transCenter, edit props.tabId and add children to transCenter's subTab
        let transCenterList = mappedElements.filter((e) => e.type === Element.MS_TRANSCENTER)
        let eleOfTransCenter = mappedElements.filter((e) => e.parentTcID !== null)
        let groupByTcID = groupBy(eleOfTransCenter, 'parentTcID')
        if (transCenterList.length > 0){
            transCenterList.forEach((transCenter: any) => {
                const tabId = diagramDataList.find(d => d.tabId === 1)?.shape.find(e => e.id === transCenter.id)?.properties.tabId || 0
                let shape = []
                if (groupByTcID[transCenter.id] !== undefined)
                    shape = groupByTcID[transCenter.id]
                saveAllControlsToDiagram({ tabId, shape } as Model.DiagramModel, false)
                transCenter.properties.tabId = tabId
            })
        }
        // remove eleOfTransCenter in mappedElements
        const mainDiagramShape = mappedElements.filter((e) => !eleOfTransCenter.map(e2 => e2.id).includes(e.id))
        saveAllControlsToDiagram(
            {
                tabId: 1, 
                shape: mainDiagramShape,
            } as Model.DiagramModel, 
            false
        )
        saveProjectData(mappedProject)
        changeProcessMode(mappedProject.opeMode);
        // changeProcessMode(Model.ProcessMode.VOLTDROP)
        setLoadingFlag(false);
    }

    const onModeImprovePF = () => {
        if(processMode != Model.ProcessMode.IMPROVE_PF){
            setLoadingFlag(true)
            sendModeImprovePFRequest({
                userId: userId,
                projectId: projectData.projectId,
                ownerProject: ownerProject
            })
        }
    }
    
    const handleModeImprovePFResponse = async (data: any) => {
        if(data && data.resultCode == 0){
            const result = await getElements(userId, projectData.projectId.toString(), data.data.element_ids,projectData.createUserId)
            if (result.success) {
                handleModeImprovePFResponseSub(data.data.project_data,result.data)
            } else {
                handleGetElementsError(result.error)
            }
        }

        setUndoData({type:"CHANGE_MODE", dataUndo:{}} as Model.UndoModel)
    }

    const handleModeImprovePFResponseSub = (projectData:any,elementsData: any) => {
        const mappedElements = mapElementsResponseToLocal(elementsData, diagramDataList);
        const mappedProject = mapProjectDataFromResponse(projectData);
        // TransCenter -- Add tab per transCenter, edit props.tabId and add children to transCenter's subTab
        let transCenterList = mappedElements.filter((e) => e.type === Element.MS_TRANSCENTER)
        let eleOfTransCenter = mappedElements.filter((e) => e.parentTcID !== null)
        let groupByTcID = groupBy(eleOfTransCenter, 'parentTcID')
        if (transCenterList.length > 0){
            transCenterList.forEach((transCenter: any) => {
                const tabId = diagramDataList.find(d => d.tabId === 1)?.shape.find(e => e.id === transCenter.id)?.properties.tabId || 0
                let shape = []
                if (groupByTcID[transCenter.id] !== undefined)
                    shape = groupByTcID[transCenter.id]
                saveAllControlsToDiagram({ tabId, shape } as Model.DiagramModel, false)
                transCenter.properties.tabId = tabId
            })
        }
        // remove eleOfTransCenter in mappedElements
        const mainDiagramShape = mappedElements.filter((e) => !eleOfTransCenter.map(e2 => e2.id).includes(e.id))
        saveAllControlsToDiagram(
            {
                tabId: 1, 
                shape: mainDiagramShape,
            } as Model.DiagramModel, 
            false
        )
        saveProjectData(mappedProject)
        changeProcessMode(mappedProject.opeMode);
        // changeProcessMode(Model.ProcessMode.IMPROVE_PF)
        setLoadingFlag(false);
    }

    const onModeHarmonic = () => {
        if(processMode != Model.ProcessMode.HARMONIC){
            setLoadingFlag(true)
            sendModeHarmonicRequest({
                userId: userId,
                projectId: projectData.projectId,
                ownerProject: ownerProject
            })
        }
    }

    const handleModeHarmonicResponse = async (data:any) => {
        if(data && data.resultCode == 0){
            const result = await getElements(userId, projectData.projectId.toString(), data.data.element_ids,projectData.createUserId)
            if (result.success) {
                handleModeHarmonicResponseSub(data.data.project_data,result.data)
            } else {
                handleGetElementsError(result.error)
            }
        }
        
        setUndoData({type:"CHANGE_MODE", dataUndo:{}} as Model.UndoModel)
    }

    const handleModeHarmonicResponseSub = (projectData:any,elementsData: any) => {
        const mappedElements = mapElementsResponseToLocal(elementsData, diagramDataList);
        const mappedProject = mapProjectDataFromResponse(projectData);
        // TransCenter -- Add tab per transCenter, edit props.tabId and add children to transCenter's subTab
        let transCenterList = mappedElements.filter((e) => e.type === Element.MS_TRANSCENTER)
        let eleOfTransCenter = mappedElements.filter((e) => e.parentTcID !== null)
        let groupByTcID = groupBy(eleOfTransCenter, 'parentTcID')
        if (transCenterList.length > 0){
            transCenterList.forEach((transCenter: any) => {
                const tabId = diagramDataList.find(d => d.tabId === 1)?.shape.find(e => e.id === transCenter.id)?.properties.tabId || 0
                let shape = []
                if (groupByTcID[transCenter.id] !== undefined)
                    shape = groupByTcID[transCenter.id]
                saveAllControlsToDiagram({ tabId, shape } as Model.DiagramModel, false)
                transCenter.properties.tabId = tabId
            })
        }
        // remove eleOfTransCenter in mappedElements
        const mainDiagramShape = mappedElements.filter((e) => !eleOfTransCenter.map(e2 => e2.id).includes(e.id))
        saveAllControlsToDiagram(
            {
                tabId: 1, 
                shape: mainDiagramShape,
            } as Model.DiagramModel, 
            false
        )
        saveProjectData(mappedProject)
        changeProcessMode(mappedProject.opeMode);
        // changeProcessMode(Model.ProcessMode.HARMONIC)
        setLoadingFlag(false);
    }

    const handleChangeModePM = () => {
        setLoadingFlag(true)
        sendModePMRequest({
            modePM: !m_bModePM,
            userId: userId,
            projectId: projectData.projectId,
            ownerProject: projectData.createUserId
        })
    }

    const handleModePMSuccess = (data: any) => {
        setUndoData({ type:"MODE_PM",dataUndo:m_bModePM} as Model.UndoModel)
        changeModePM(data.data)
        setLoadingFlag(false)
    }

    const handleGetElementsError = (error: any) => {
        setOpenPowerFlowDialog(false);
        setLoadingFlag(false);
        onShowMessage({ 
            type: "error",
            title: "要素の読み込み",
            body: "要素の読み込みに失敗しました。",
        });
    }
    //#endregion HANDLE PROCESS MODE

    // #region graph name

    const handleGetSuccessGraphName = (data:any) => {
        setLoadingFlag(false);
        if(data && data.resultCode == 0){
            saveListGraphNameToStore(projectData.projectId,data.data);
            if(data.data.length > 0){
                setCanOpenUpdateGraphName(true);
            }else{
                setCanOpenUpdateGraphName(false);
            }
        }
        else{
            saveListGraphNameToStore(projectData.projectId,[]);
        }
    }

    useEffect(() => {
        if(listGraphName?.list?.length > 0){
            setCanOpenUpdateGraphName(true);
        }else{
            setCanOpenUpdateGraphName(false);
        }
    },[listGraphName])

    const handleGetErrorGraphName = (error:any) => {
        setLoadingFlag(false);
    }
    // #endregion
    
    return (
        <>
            {loadingFlag && (
                <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1500, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                    <CircularProgress />
                </div>
            )}
            <div ref={refDivHeader} style={{width:"100%",display:"flex", flexWrap:"nowrap", alignItems: 'center', backgroundColor: theme.palette.secondary.light, borderBottom: '1px solid #cccfcd'}}>
                {modeHome && 
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',width:"100px", marginLeft:"4px", height:"50px",backgroundColor: theme.palette.secondary.light}}>
                        <img src={logo} style={{width: '100px', height: '40px', cursor: 'pointer'}} onClick={() => navigate(RoutePath.projects)}/>
                    </div>}
                {hasScrollHeader && <ArrowLeftIcon onClick={scrollHeaderLeft} style={{height:"50px",backgroundColor: theme.palette.secondary.light}}/>}
                <div style={{width:"100%", overflow: 'hidden', whiteSpace: 'nowrap'}} ref={scrollHeader}>
                    <MainHeader 
                        user={user}
                        modeHome={modeHome}
                        handleMenuOptions={handleMenuOptions} 
                        m_bModePM={m_bModePM}
                        modeViewOnly={modeViewOnly}
                        hasSelectedElement={hasSelectedElement}
                        hasCopyShapes={hasCopyShapes}
                        hasOpenGraphScreen={hasOpenGraphScreen}
                        hasUndo={undoData.type !== null}
                        hasOpenEditGraphName={canOpenUpdateGraphName}
                        handleCheckCopyData={handleCheckCopyData}
                    />
                </div>
                {hasScrollHeader && <ArrowRightIcon onClick={scrollHeaderRight} style={{height:"50px",backgroundColor: theme.palette.secondary.light}}/>}
            </div>
          
            {(modeHome && showReportSceen !== true && showMaintenanceScreen !== true) && (
                <div ref={refDiv} style={{ width: '100%', height: '70px', maxHeight: '70px', borderBottom: '1px solid #cccfcd', display: 'flex', alignItems: 'center', backgroundColor: theme.palette.secondary.light}}>
                    {hasOverflow && <ArrowLeftIcon onClick={scrollLeft} />}
                    <div style={{ display: 'flex', overflow: 'hidden', whiteSpace: 'nowrap', marginLeft: '5px' }} ref={scrollElement}>
                        <Box>
                            <Stack direction="row" alignItems="center" justifyContent="center" style={getStyleOfMenu(1)} onClick={onModeDrawing}>
                                <img src={menu_1} style={{width: '40px', height: '35px', marginTop: '15px'}}/>
                                <Typography variant="caption">編集</Typography>
                            </Stack>
                        </Box>
                        <Box >
                            <Stack direction="row" alignItems="center" justifyContent="center" style={getStyleOfMenu(2)} onClick={onModeChecking}>
                            <img src={menu_2} style={{width: '40px', height: '35px', marginTop: '15px'}}/>
                                <Typography variant="caption">回路チェック</Typography>
                            </Stack>
                        </Box>
                        <Box>
                            <Stack direction="row" alignItems="center" justifyContent="center" style={getStyleOfMenu(3)} onClick={onModePercentZ}>
                            <img src={menu_3} style={{width: '40px', height: '35px', marginTop: '15px'}}/>
                                <Typography variant="caption">インピーダンスマップ</Typography>
                            </Stack>
                        </Box>
                        <Box >
                            <Stack direction="row" alignItems="center" justifyContent="center" style={getStyleOfMenu(4)} onClick={onModeCaculation}>
                                <img src={menu_4} style={{width: '40px', height: '35px', marginTop: '15px'}}/>
                                <Typography variant="caption">短絡電流</Typography>
                            </Stack>
                        </Box>
                        <Box >
                            <Stack direction="row" alignItems="center" justifyContent="center" style={getStyleOfMenu(5)} onClick={onModeVoltDrop}>
                            <img src={menu_5} style={{width: '40px', height: '35px', marginTop: '15px'}}/>
                                <Typography variant="caption">電圧降下</Typography>
                            </Stack>
                        </Box>
                        <Box >
                            <Stack direction="row" alignItems="center" justifyContent="center" style={getStyleOfMenu(6)} onClick={onModeImprovePF}>
                                <img src={menu_6} style={{width: '40px', height: '35px', marginTop: '15px'}}/>
                                <Typography variant="caption">力率改善</Typography>
                            </Stack>
                        </Box>
                        <Box >
                            <Stack direction="row" alignItems="center" justifyContent="center" style={getStyleOfMenu(7)} onClick={onModeHarmonic}>
                                <img src={menu_7} style={{width: '40px', height: '35px', marginTop: '15px'}}/>
                                <Typography variant="caption">高調波</Typography>
                            </Stack>
                        </Box>
                        {(userRole && !(userRole == Constant.ROLE_LP)) &&
                            <Box >
                                <Stack direction="row" alignItems="center" justifyContent="center" style={getStyleOfMenu(8)} onClick={handleOpenPowerFlowDialog}>
                                    <img src={menu_8} style={{width: '40px', height: '35px', marginTop: '15px'}}/>
                                    <Typography variant="caption">潮流解析</Typography>
                                </Stack>
                            </Box>
                        }
                        <Box sx={{ width: 12, borderLeft: 0.5, borderColor: 'grey.400' }}/>
                        <Box>
                            <Stack 
                                direction="row"
                                alignItems="center"
                                justifyContent="center"
                                style={showGraphScreen?styleSelect:styleIcon}
                                onClick={showGraphScreen?handleDisplayDiagram:handleDisplayGraph}
                            >
                                <img src={menu_10} style={{width: '40px', height: '35px', marginTop: '15px'}}/>
                                <Typography variant="caption">保護協調</Typography>
                            </Stack>
                        </Box>
                        {(userRole && [Constant.ROLE_PM, Constant.ROLE_ADMIN].includes(userRole)) &&
                            <Box>
                                <Stack 
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="center"
                                    style={modeViewOnly? styleDisabled : (m_bModePM?styleSelect2:styleIcon2)}
                                    onClick={handleChangeModePM}
                                >
                                    <img 
                                        src={menu_9} 
                                        style={{width: '40px', height: '35px', marginTop: '15px'}}
                                    />
                                    <Typography variant="caption">メンテナンスモード</Typography>
                                </Stack>
                            </Box>
                        }
                        <Box>
                            <Stack 
                                direction="row"
                                alignItems="center"
                                justifyContent="center"
                                style={showHistoryDialog?styleSelect:styleIcon}
                                onClick={handleOpenHistoryDialog}
                            >
                                <img src={menu_11} style={{width: '40px', height: '35px', marginTop: '15px'}}/>
                                <Typography variant="caption">編集履歴</Typography>
                            </Stack>
                        </Box>
                    </div>
                    {hasOverflow && <ArrowRightIcon onClick={scrollRight} />}
                </div>
            )}
            {modeCheckingDialogInfo.isOpen &&
                <ModeCheckingDialog title={"回路チェック"} {...modeCheckingDialogInfo} /> 
            }
            {
                openOpenPercentZDialog &&
                <WarningDialog title="インピーダンスマップ" message={"事故点ごとの合成インピーダンス計算は終了しました。"} isOpen={true} onOK={() => setOpenPercentZModeDialog(false)} />
            }
            {showMessModePowerFlow.isOpen &&
                <ShowMessModePowerFlow {...showMessModePowerFlow} onOK={() => setShowMessModePowerFlow({isOpen:false,message:"",title:""})}/> 
            }
            {
                isOpenPowerFlowDialog &&
                <div>
                    <Dialog open={true} fullWidth PaperComponent={DraggablePaper}>
                        <DialogTitle
                        onMouseOver={(e : any) => e.target.style.cursor = 'move'}
                        style={{
                            paddingTop: "2px",
                            paddingBottom: "2px",
                            background: theme.palette.primary.main,
                            color: theme.palette.primary.contrastText,
                        }}
                        >
                        潮流解析方法の設定
                        </DialogTitle>
                        <DialogContent>
                        <PowerFlowDialog
                            data={{powerFlowMethod:projectData.powerFlowMethod,
                                maxIteration:projectData.maxIteration,
                                precision:projectData.precision.toFixed(8),
                                accelerationFactor:projectData.accelerationFactor,
                                flowViewMethod:projectData.flowViewMethod}}
                            onCancel={() => setOpenPowerFlowDialog(false)}
                            onOK={onModePowerFlow}
                        ></PowerFlowDialog>
                        </DialogContent>
                    </Dialog>
                </div>
            }

            {/* FETCH HELPERS */}
            <FetchHelper
                fetchId={MODE_DRAWING_REQ}
                onComplete={(success, data, error) =>
                    success
                        ? handleModeDrawingResponse(data)
                        : (() => { setOpenPowerFlowDialog(false); setLoadingFlag(false); onShowMessage({ type: "error", title: "編集", body: "編集に失敗しました。"}); })()
                }
            />

            <FetchHelper
                fetchId={MODE_CHECK_REQ}
                onComplete={(success, data, error) =>
                    success
                        ? handleModeCheckingResponse(data.data)
                        : (() => { setOpenPowerFlowDialog(false); setLoadingFlag(false); onShowMessage({ type: "error", title: "回路チェック", body: "回路チェックに失敗しました。"}); })()
                }
            />
            <FetchHelper
                fetchId={MODE_PER_Z_REQ}
                onComplete={(success, data, error) =>
                    success
                        ? handleModePercentZResponse(data)
                        : (() => { setLoadingFlag(false); onShowMessage({ type: "error", title: "インピーダンスマップ", body: "インピーダンスマップに失敗しました。"}); })()
                }
            />
            <FetchHelper
                fetchId={MODE_CACULATION}
                onComplete={(success, data, error) =>
                    success
                        ? handleModeCaculationResponse(data)
                        : (() => { setLoadingFlag(false); onShowMessage({ type: "error", title: "短絡電流", body: "短絡電流に失敗しました。"}); })()
                }
            />
            <FetchHelper
                fetchId={MODE_VOLT_DROP}
                onComplete={(success, data, error) =>
                    success
                        ? handleModeVoltDropResponse(data)
                        : (() => { setLoadingFlag(false); onShowMessage({ type: "error", title: "電圧降下", body: "電圧降下に失敗しました。"}); })()
                }
            />
            <FetchHelper
                fetchId={MODE_IMPROVE_PF}
                onComplete={(success, data, error) =>
                    success
                        ? handleModeImprovePFResponse(data)
                        : (() => { setLoadingFlag(false); onShowMessage({ type: "error", title: "力率改善", body: "力率改善に失敗しました。"}); })()
                }
            />
            <FetchHelper
                fetchId={MODE_HARMONIC}
                onComplete={(success, data, error) =>
                    success
                        ? handleModeHarmonicResponse(data)
                        : (() => { setLoadingFlag(false); onShowMessage({ type: "error", title: "高調波", body: "高調波に失敗しました。"}); })()
                }
            />
            <FetchHelper
                fetchId={MODE_POWER_FLOW}
                onComplete={(success, data, error) =>
                    success
                        ? handleModePowerFlowResponse(data)
                        : (() => { setOpenPowerFlowDialog(false); setLoadingFlag(false); onShowMessage({ type: "error", title: "潮流解析", body: "潮流解析に失敗しました。"}); })()
                }
            />
            <FetchHelper
                fetchId={MODE_PM}
                onComplete={(success, data, error) =>
                    success
                        ? handleModePMSuccess(data)
                        : (() => { setLoadingFlag(false); onShowMessage({ type: "error", title: "保護協調", body: "保護協調に失敗しました。"}); })()
                }
            />
            <FetchHelper
                fetchId={GET_GRAPH_NAME}
                onComplete={(success, data, error) =>
                success
                    ? handleGetSuccessGraphName(data)
                    : handleGetErrorGraphName(error)
                }
            />
        </>
    );
};


const mapStateToProps = (state: ApplicationState) => {
    return {
        processMode: state.app.diagram.processMode,
        projectData: state.app.projectData,
        diagramDataList: state.app.diagram.diagramData,
        userRole: state.app.user?.userRole,
        user: state.app.user,
        m_bModePM: state.app.diagram.m_bModePM,
        modeViewOnly: state.app.diagram.modeViewOnly,
        
        copyShapes: state.app.diagram.copyShapes,
        diagramData: state.app.diagram.diagramData.filter((r: any) => r.tabId === state.app.diagram.currentIDDiagramTab),
        isDeleteGraphScreen : state.app.diagram.deleteGraphNo,
        currentIDChartTab: state.app.diagram.currentIDChartTab,
        undoData: state.app.diagram.undoData,
        listGraphName: state.app.diagram.listGraphName,
        isShowMaintenanceScreen: state.app.diagram.isShowMaintenanceScreen,
        // preDiagramData: state.app.diagram.preDiagramData,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        // api
        sendDrawingRequest: (params : any) => dispatch(actionCreators.fetch(MODE_DRAWING_REQ, "/check/drawing", "POST", params, false, true)),
        sendCheckRequest: (params : any) => dispatch(actionCreators.fetch(MODE_CHECK_REQ, "/check/check-circuit", "POST", params, false, true)),
        sendPercentZRequest: (params : any) => dispatch(actionCreators.fetch(MODE_PER_Z_REQ, "/check/percent-z", "POST", params, false, true)),
        sendModeCaculationRequest: (params:any) => dispatch(actionCreators.fetch(MODE_CACULATION,"/check/caculation","POST",params,false,true)),
        sendModeVoltDropRequest: (params:any) => dispatch(actionCreators.fetch(MODE_VOLT_DROP,"/check/volt-drop","POST",params,false,true)),
        sendModeImprovePFRequest: (params:any) => dispatch(actionCreators.fetch(MODE_IMPROVE_PF,"/check/improve-pf","POST",params,false,true)),
        sendModeHarmonicRequest: (params:any) => dispatch(actionCreators.fetch(MODE_HARMONIC,"/check/harmonic","POST",params,false,true)),
        sendModePowerFlowRequest: (params:any) => dispatch(actionCreators.fetch(MODE_POWER_FLOW, "/check/power-flow", "POST",params, false, true)),
        sendModePMRequest: (params:any) => dispatch(actionCreators.fetch(MODE_PM, "/diagram/set-mode-pm", "POST",params, false, true)),
        getGraphName: (params: any) => dispatch(actionCreators.fetch(GET_GRAPH_NAME,"/graph/get-graph-name","POST",params,false,true)),
        // others
        saveProjectData: (data: Model.ProjectModel) => dispatch(actionCreators.saveOpenProjectData(data)),
        changeProcessMode: (mode : number) => dispatch(changeProcessMode(mode)),
        saveAllControlsToDiagram: (data: Model.DiagramModel, flag: boolean) => dispatch(saveAllControlsToDiagram(data, flag)),
        onShowMessage: (message: Message) => dispatch(actionCreators.showMessage(message)),
        changeModePM: (data: Boolean) => dispatch(changeModePM(data)),
        setUndoData: (data:Model.UndoModel) => dispatch(undo(data)),
        saveListGraphNameToStore: (projectId:any,data:any) => dispatch(saveListGraphName(projectId,data)),
        saveListGraphToStore: (data:any) => dispatch(saveListGraph(data))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(DashboardMainTopNavbar);