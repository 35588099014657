import { store } from "..";
import { ControlModel, MsLBSModel, ProcessMode } from "../models/Index";
import { BeamsNumber } from "./FormatNumber";
import { MS_3PHASES, MS_VOLTDROP_REGULAR, MS_TRANS1, MS_VOLTDROP_MOTOR, TOP_CENTER, BOTTOM_CENTER, LEFT_CENTER, RIGHT_CENTER, DIRECT_NONE } from "../models/Constants"
import {
	doGetIkpeakDirectMG,
	doGetIkasymDirectMG,
	doGetIksymDirectMG,
    doGetIksymTotalMG,
    doGetTextDropVoltMotor as doGetTextDropVoltMotorCalPoint,
    doGetTextDropVoltMotor2 as DoGetTextDropVoltMotor2CalcPoint,
    doGetTextDropPerMotor as doGetTextDropPerMotorCalcPoint,
	doGetTextDropVoltRegular as doGetTextDropVoltRegularCalcPoint,
	doGetTextDropPerRegular as doGetTextDropPerRegularCalcPoint,
    isSetDropPerMotor,
    doGetPerZDirectMG,
    doGetIkpeakTotalMG,
    doGetIkasymTotalMG,
    doGetPerZTotalMG
} from "./calcPointFunction";
import { isCennectLoadDropElement } from "./element"
import { doGetTextVoltDropRegular } from "./mssv3Doc"
import { MS_MODE_CALCULATION, MS_MODE_CHECKING, MS_MODE_DRAWING, MS_MODE_HARMONIC, MS_MODE_PERCENT_Z, MS_MODE_POWER_FLOW, MS_MODE_VOLTDROP } from "../statics";

//CEleDS::DoGetTextOnOff
export const doGetTextOnOff = (props:MsLBSModel, bUseOn:boolean = true) =>
{
	let sText;
	if (props.isOff) {
		sText = '開放';
	}
	else {
		if (bUseOn) {
			sText = '投入';
		}
		else {
			sText = '';
		}
	}
	return sText;
}

//CEleLBS::IsPointFaultLBS
export const isPointFaultLBS = (props:MsLBSModel) =>
{
	if (props.fault) {
		return true;
	}
	return false;
}

//CEleLBS::DoGetTextPointState
export const doGetTextPointState = (props:MsLBSModel) =>
{
	let sText = ''; 

	if (props.fault) {
		sText = 'Yes';
	}
	else {
		sText = 'No';
	}

	return sText;
}

//CEleLBS::DoGetTextDirectIk3peak
export const doGetTextDirectIk3peak = (props:MsLBSModel, bUnit:boolean = true) =>
{
	let dIpeakDirect = 0;
	if (props.downTransKind == MS_TRANS1) {
		dIpeakDirect = doGetIkpeakDirectMG(props.calcPoint1);
	}
	else {
		dIpeakDirect = doGetIkpeakDirectMG(props.calcPoint0);
	}
	let sText;
	if (dIpeakDirect == 0) {
		if (props.primarySupply) {
			sText = '∞';
		}
		else {
			sText = '0';
		}
	}
	else if (dIpeakDirect > 0) {
		sText = BeamsNumber(dIpeakDirect / 1000, 4).toString();
        if(bUnit)
        {
            sText += 'kApeak';
        }
	}
	else {							
        sText = '????';
	}
	return sText;
}

//CEleLBS::DoGetTextDirectIk3asym
export const doGetTextDirectIk3asym = (props:MsLBSModel, bUnit:boolean = true) =>
{
	let dIasymDirect = 0;
	if (props.downTransKind == MS_TRANS1) {
		dIasymDirect = doGetIkasymDirectMG(props.calcPoint1);
	}
	else {
		dIasymDirect = doGetIkasymDirectMG(props.calcPoint0);
	}
	let sText;
	if (dIasymDirect == 0) {
		if (props.primarySupply) {
			sText = '∞';
       }
       else {
        sText = '0';
       }
	}
    else if(dIasymDirect > 0) {
        sText = BeamsNumber(dIasymDirect / 1000, 4).toString();
        if(bUnit) {
            sText += 'kAasym';
        }
    }
    else {
        sText = '????';
    }
	return sText;
}

//CEleLBS::DoGetTextDirectIk3sym
export const doGetTextDirectIk3sym = (props:MsLBSModel, bUnit:boolean = true) =>
{
	let dIsymDirect = 0;
	if (props.downTransKind == MS_TRANS1) {
		dIsymDirect = doGetIksymDirectMG(props.calcPoint1);
	}
	else {
		dIsymDirect = doGetIksymDirectMG(props.calcPoint0);
	}
	let sText;
	if (dIsymDirect == 0) {
		if(props.primarySupply) {
            sText = '∞';
        }
        else {
         sText = '0';
        }
    }
    else if(dIsymDirect > 0) {
        sText = BeamsNumber(dIsymDirect / 1000, 4).toString();
        if(bUnit) {
            sText += 'kAsym';
        }
    }
    else {
        sText = '????';
    }
	return sText;
}

//CEleLBS::DoGetTextTotalIk3sym
export const doGetTextTotalIk3sym = (props:MsLBSModel, bUnit:boolean = true) =>
{
	let dIsymTotal = 0;
	if (props.downTransKind == MS_TRANS1) {
		dIsymTotal = doGetIksymTotalMG(props.calcPoint1);
	}
	else {
		dIsymTotal = doGetIksymTotalMG(props.calcPoint0);
	}
	let sText;
	if (dIsymTotal == 0) {
		if (props.primarySupply) {
            sText = '∞';
        }
        else {
         sText = '0';
        }
    }
    else if(dIsymTotal > 0) {
        sText = BeamsNumber(dIsymTotal / 1000, 4).toString();
        if(bUnit) {
            sText += 'kAsym';
        }
    }
    else {
        sText = '????';
    }
	return sText;
}

//CEleLBS::DoGetTextDropVoltMotor
export const doGetTextDropVoltMotor = (props:MsLBSModel, bUnit:boolean = true) =>
{
	return doGetTextDropVoltMotorCalPoint(props.calcPoint0, bUnit);
}

//CEleLBS::DoGetTextDropVoltMotor2
export const doGetTextDropVoltMotor2 = (props:MsLBSModel, bUnit:boolean = true) =>
{
	return DoGetTextDropVoltMotor2CalcPoint(props.calcPoint0, bUnit);
}

//CEleLBS::DoGetTextDropPerMotor
export const doGetTextDropPerMotor = (props:MsLBSModel, bUnit:boolean = true) =>
{
	return doGetTextDropPerMotorCalcPoint(props.calcPoint0, bUnit);
}

//CEleLBS::DoGetTextDropVoltRegular
export const doGetTextDropVoltRegular = (props:MsLBSModel, dStdVoltage:number, bUnit:boolean = true) =>
{
	return doGetTextDropVoltRegularCalcPoint(props.calcPoint0, dStdVoltage, 0, 0, bUnit);
}

//CEleLBS::DoGetTextDropPerRegular
export const doGetTextDropPerRegular = (props:MsLBSModel, dStdVoltage:number, bUnit:boolean = true) =>
{								
	return doGetTextDropPerRegularCalcPoint(props.calcPoint0, dStdVoltage, 0, 0, bUnit);
}

//CEleLBS::DoGetTextVoltage
export const doGetTextVoltage = (props:MsLBSModel, bUnit:boolean = true) =>
{
	let sText = props.voltage.toString();
	if (bUnit) {
		sText += 'V';
	}
	return sText;
}

//CEleLBS::DoGetRefV1
export const doGetRefV1 = (mode:any, control:ControlModel) =>
{
    let props = control.properties as MsLBSModel;
    let project = store.getState().app.projectData;

	//CEleDS::DoGetRefV1Left  sRefV1Left
	let labelTexts = [{
		value: control.properties.refNo,
		color: "#f800f8",
	}];

	if (!props.viewResultText || (mode != ProcessMode.DRAWING && !props.supply))
    {
      return labelTexts;
    }

	switch(mode) {
        case ProcessMode.DRAWING:
        case ProcessMode.CHECKING:
        case ProcessMode.PERCENT_Z:
            if(props.isOff) {
                //OnOff
                labelTexts.push({
                    value: doGetTextOnOff(props, false),
                    color: "blue",
                });
            }
            break;
        case ProcessMode.CALCULATION:  
            if(props.isOff) {
                //OnOff
                labelTexts.push({
                    value: doGetTextOnOff(props, false),
                    color: "blue",
                });
                break;
            }

            if(!isPointFaultLBS(props) ) {
                break;
            }
            if(props.downTransKind == MS_TRANS1) {
                // += 'I"k2' + '通過' + '=' + doGetTextDirectIk3peak
                labelTexts.push({
                    value: 'I"k2通過='
                    + doGetTextDirectIk3peak(props),
                    color: "blue",
                });

                // += 'I"k2' + '通過' + '=' + doGetTextDirectIk3asym
                labelTexts.push({
                    value: 'I"k2通過='
                    + doGetTextDirectIk3asym(props),
                    color: "blue",
                });

                // += 'I"k2' + '通過' + '=' + doGetTextDirectIk3sym
                labelTexts.push({
                    value: 'I"k2通過='
                    + doGetTextDirectIk3sym(props),
                    color: "blue",
                });

                // += 'I"k2' + '合計' + '=' + doGetTextTotalIk3sym
                labelTexts.push({
                    value: 'I"k2合計='
                    + doGetTextTotalIk3sym(props),
                    color: "blue",
                });
            }
            else {
                  // += 'I"k3' + '通過' + '=' + doGetTextDirectIk3peak
                  labelTexts.push({
                    value: 'I"k3通過='
                    + doGetTextDirectIk3peak(props),
                    color: "blue",
                });

                // += 'I"k3' + '通過' + '=' + doGetTextDirectIk3asym
                labelTexts.push({
                    value: 'I"k3通過='
                    + doGetTextDirectIk3asym(props),
                    color: "blue",
                });

                // += 'I"k3' + '通過' + '=' + doGetTextDirectIk3sym
                labelTexts.push({
                    value: 'I"k3通過='
                    + doGetTextDirectIk3sym(props),
                    color: "blue",
                });

                // += 'I"k3' + '合計' + '=' + doGetTextTotalIk3sym
                labelTexts.push({
                    value: 'I"k3合計='
                    + doGetTextTotalIk3sym(props),
                    color: "blue",
                });
            }
            break;
        case ProcessMode.VOLTDROP:   
            if (props.isOff) {
                 //OnOff
                 labelTexts.push({
                    value: doGetTextOnOff(props, false),
                    color: "blue",
                });
                break;
            }

            if (project.dropMode == MS_VOLTDROP_MOTOR && props.dropMotor && isSetDropPerMotor(props.calcPoint0)) {
                 // Vd = DropVoltMotor(VoltSecondary - DropVoltMotor2)
                 let sTemp = 'Vd=' + doGetTextDropVoltMotor(props) + ' (';
                 if (props.supply ) {
                     sTemp += doGetTextVoltage(props, false);
                     sTemp += '-';
                     sTemp += doGetTextDropVoltMotor2(props, false);
                 } else {
                     sTemp += '0-0'
                 }
                 sTemp += ')';
                 labelTexts.push({
                     value: sTemp,
                     color: "blue",
                 });

                //Vp = DoGetTextDropPerMotor (VoltSecondary → DropVoltMotor2)
                sTemp = 'Vp=' + doGetTextDropPerMotor(props) + ' (';
                if (props.supply) {
                    sTemp += doGetTextVoltage(props, false);
                    sTemp += '→';
                    sTemp += doGetTextDropVoltMotor2(props, false);
                } else {
                    sTemp += '0→0';
                }
                sTemp += ')';
                labelTexts.push({
                    value: sTemp,
                    color: "blue",
                });
                break;
            }

            if (isCennectLoadDropElement(control, TOP_CENTER | BOTTOM_CENTER | LEFT_CENTER | RIGHT_CENTER) && project.dropMode == MS_VOLTDROP_REGULAR && props.dropRegular) {
                let stdVoltage = {
                    dStdVoltage : 0,
			        sStdVoltage : ''
                };
                if(props.supply) {
                    stdVoltage = doGetTextVoltDropRegular(control, MS_3PHASES, false);
                }

                //Vp=doGetTextDropPerRegular (doGetTextVoltage→doGetTextDropVoltRegular)
                let sTemp = 'Vp=' + doGetTextDropPerRegular(props, stdVoltage.dStdVoltage);
                sTemp += ' (';
                if(props.supply) {
                    sTemp += doGetTextVoltage(props, false);
                }
                else {
                    sTemp += stdVoltage.sStdVoltage;
                }
                sTemp += '→';
                sTemp += doGetTextDropVoltRegular(props, stdVoltage.dStdVoltage, false);
                sTemp += ')';
                labelTexts.push({
                    value: sTemp,
                    color: "blue",
                });
                break;
            }
            break;
        case ProcessMode.IMPROVE_PF:
        case ProcessMode.HARMONIC:
        case ProcessMode.POWER_FLOW:
            if (props.isOff) {
                //OnOff
                labelTexts.push({
                   value: doGetTextOnOff(props, false),
                   color: "blue",
               });
           }
            break;
    }

    return labelTexts;
}

//CEleLBS::DoGetTextDirectPercentZr
export const doGetTextDirectPercentZr = (props:ControlModel, bUnit:boolean = true) =>
{
	let dPerZ = 0;
	if (props.properties.downTransKind == MS_TRANS1) {
		dPerZ = doGetPerZDirectMG(props.properties.calcPoint1).dPercentZ;
	}
	else {
		dPerZ = doGetPerZDirectMG(props.properties.calcPoint0).dPercentZ;
	}
	let sText = '';
	if (dPerZ >= 0) {
		sText = BeamsNumber(dPerZ, 4).toString();
		if (bUnit) {
			sText += '%r';
		}
	}
	else {							// 短絡電流が正しく計算されなかったとき
		sText = '????';
	}
	return sText;
}

//CEleLBS::DoGetTextTotalIk3peak 
export const doGetTextTotalIk3peak = (props:MsLBSModel, bUnit:boolean = true) =>
{
	let dIpeakTotal = 0;
	if (props.downTransKind == MS_TRANS1) {
		dIpeakTotal = doGetIkpeakTotalMG(props.calcPoint1);
	}
	else {
		dIpeakTotal = doGetIkpeakTotalMG(props.calcPoint0);
	}
	let sText = '';
	if (dIpeakTotal == 0) {
		if (props.primarySupply) {
			sText = '∞';
		}
		else {
			sText = "0";
		}
	}
	else if (dIpeakTotal > 0) {
		sText = BeamsNumber(dIpeakTotal / 1000, 4).toString();
		if (bUnit) {
			sText += 'kApeak';
		}
	}
	else {							// 短絡電流が正しく計算されなかったとき
		sText = '????';
	}
	return sText;
}

//CEleLBS::DoGetTextTotalIk3asym
export const doGetTextTotalIk3asym = (props:MsLBSModel, bUnit:boolean = true) =>
{
	let dIasymTotal = 0;
	if (props.downTransKind == MS_TRANS1) {
		dIasymTotal = doGetIkasymTotalMG(props.calcPoint1);
	}
	else {
		dIasymTotal = doGetIkasymTotalMG(props.calcPoint0);
	}
	let sText = '';
	if (dIasymTotal == 0) {
		if (props.primarySupply) {
			sText = '∞';
		}
		else {
			sText = "0";
		}
	}
	else if (dIasymTotal > 0) {
		sText = BeamsNumber(dIasymTotal / 1000, 4).toString();
		if (bUnit) {
			sText += 'kAasym';
		}
	}
	else {							// 短絡電流が正しく計算されなかったとき
		sText = '????';
	}
	return sText;
}

//CEleLBS::DoGetTextTotalPercentZr
export const doGetTextTotalPercentZr = (props:MsLBSModel, bUnit:boolean = true) =>
{
	let dPerZ = 0;
	if (props.downTransKind == MS_TRANS1) {
		dPerZ = doGetPerZTotalMG(props.calcPoint1).dPercentZ;
	}
	else {
		dPerZ = doGetPerZTotalMG(props.calcPoint0).dPercentZ;
	}
	let sText = '';
	if (dPerZ >= 0) {
		sText = BeamsNumber(dPerZ, 4).toString();
		if (bUnit) {
			sText += '%r';
		}
	}
	else {							// 短絡電流が正しく計算されなかったとき
		sText = '????';
	}
	return sText;
}

//CEleLBS::DoGetTextActiveLeft
export const doGetTextActiveLeft = (props:MsLBSModel, nDirect:number, bUnit:boolean = true) =>
{
    let sText = '';
	if (nDirect == DIRECT_NONE) {
		if (props.lineActiveFlow1 >= 0) {
			sText = BeamsNumber(props.lineActiveFlow1, 5).toString();
		}
		else {
			sText = BeamsNumber(props.lineActiveFlow2, 5).toString();
		}
	}
	else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
		sText = BeamsNumber(Math.abs(props.lineActiveFlow1), 5).toString();
	}
	else {
		sText = BeamsNumber(Math.abs(props.lineActiveFlow2), 5).toString();
	}

	if (bUnit) {
		sText += 'kW';
	}

	return sText;
}

//CEleLBS::DoGetTextReactiveLeft
export const doGetTextReactiveLeft = (props:MsLBSModel, nDirect:number, bUnit:boolean = true) =>
{
    let sText = '';
	if (nDirect == DIRECT_NONE) {
		if (props.lineReactiveFlow1 >= 0) {
			sText = BeamsNumber(props.lineReactiveFlow1, 5).toString();
		}
		else {
			sText = BeamsNumber(props.lineReactiveFlow2, 5).toString();
		}
	}
	else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
		sText = BeamsNumber(Math.abs(props.lineReactiveFlow1), 5).toString();
	}
	else {
		sText = BeamsNumber(Math.abs(props.lineReactiveFlow2), 5).toString();
	}

	if (bUnit) {
		sText += 'kvar';
	}

	return sText;
}

//CEleLBS::DoGetTextFlowsResult
export const doGetTextFlowsResult = (props:MsLBSModel, processMode:any, nDirect:number, bSociety:boolean = true) =>
{
	let sRefV1: string[] = [];

	if (bSociety) {
		return sRefV1;
	}

	switch (processMode) {
	case MS_MODE_DRAWING:
	case MS_MODE_CHECKING:
	case MS_MODE_PERCENT_Z:
	case MS_MODE_CALCULATION:
	case MS_MODE_VOLTDROP:
	case MS_MODE_HARMONIC:
		break;
	case MS_MODE_POWER_FLOW:
		if (props.lineActiveFlow1 !==0 && props.lineActiveFlow2 !==0 && props.lineReactiveFlow1 !==0 && props.lineReactiveFlow2 !==0) {
			if (nDirect == DIRECT_NONE) {
				sRefV1.push(doGetTextActiveLeft(props,nDirect));
                sRefV1.push(doGetTextReactiveLeft(props,nDirect));
			}
			else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
				sRefV1.push(doGetTextActiveLeft(props,nDirect));
				let sTemp = ''
				if (props.lineActiveFlow1 > 0) {
					if (props.lineReactiveFlow1 > 0) {
						sTemp += "+j";
					}
					else {
						sTemp += "-j";
					}
				}
				else {
					if (props.lineReactiveFlow1 > 0) {
						sTemp += "-j";
					}
					else {
						sTemp += "+j";
					}
				}
				sRefV1.push(doGetTextReactiveLeft(props, nDirect));
			}
			else {
				sRefV1.push(doGetTextActiveLeft(props,nDirect));
				let sTemp = ''
				if (props.lineActiveFlow2 > 0) {
					if (props.lineReactiveFlow2 > 0) {
						sTemp += "+j";
					}
					else {
						sTemp += "-j";
					}
				}
				else {
					if (props.lineReactiveFlow2 > 0) {
						sTemp += "-j";
					}
					else {
						sTemp += "+j";
					}
				}
				sRefV1.push(sTemp + doGetTextReactiveLeft(props,nDirect));
			}
		}
		break;
	}

	return sRefV1;
}