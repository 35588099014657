import { store } from "..";
import { BeamsNumber } from "./FormatNumber";

export const doCalcCoverRated = (b3Phase:boolean,capacity:number,number:number) => {
    let infoCircuit = store.getState().app.diagram.infoCircuit;
    const voltage = infoCircuit.voltage
    if(voltage <= 0)
        return 0;
    let convertRated = 0;

    if(b3Phase)
        convertRated = capacity * number * 1000 * 1000 / voltage / Math.sqrt(3.0);
    else
        convertRated = capacity * number * 1000 * 1000 / voltage;
    return BeamsNumber(convertRated,4);
}

export const handleCurrent = (percent:number,t_phases:boolean,capacity:number,number:number,operating_ratio:number) => {
    const convertRated = doCalcCoverRated(t_phases,capacity,number);
    const result = BeamsNumber(percent * convertRated * operating_ratio / 10000,0)
    return result
}
export const harmonicListMap = (harmonicList:any) => {
    const harmonic = harmonicList.map((harmonic:any) => {
        return {
                hamorItemID: harmonic.harmonic_id,
                name: harmonic.name,
                manufacture: harmonic.manufacture,
                type: harmonic.type,
                b3Phases: harmonic.t_phases,
                capacity: harmonic.capacity,
                number: harmonic.number,
                kindCode: harmonic.kind_code,
                detailCode: harmonic.detail_code,
                coEfficient: harmonic.coefficient,
                operatingRatio: harmonic.operating_ratio,
                percent5: harmonic.percent5,
                percent7: harmonic.percent7,
                percent11: harmonic.percent11,
                percent13: harmonic.percent13,
                percent17: harmonic.percent17,
                percent19: harmonic.percent19,
                percent23: harmonic.percent23,
                percent25: harmonic.percent25,
                voltage: harmonic.voltage,
                electricPower: harmonic.volt_source,
                current5: handleCurrent(harmonic.percent5,harmonic.t_phases,harmonic.capacity,harmonic.number,harmonic.operating_ratio),
                current7: handleCurrent(harmonic.percent7,harmonic.t_phases,harmonic.capacity,harmonic.number,harmonic.operating_ratio),
                current11: handleCurrent(harmonic.percent11,harmonic.t_phases,harmonic.capacity,harmonic.number,harmonic.operating_ratio),
                current13: handleCurrent(harmonic.percent13,harmonic.t_phases,harmonic.capacity,harmonic.number,harmonic.operating_ratio),
                current17: handleCurrent(harmonic.percent17,harmonic.t_phases,harmonic.capacity,harmonic.number,harmonic.operating_ratio),
                current19: handleCurrent(harmonic.percent19,harmonic.t_phases,harmonic.capacity,harmonic.number,harmonic.operating_ratio),
                current23: handleCurrent(harmonic.percent23,harmonic.t_phases,harmonic.capacity,harmonic.number,harmonic.operating_ratio),
                current25: handleCurrent(harmonic.percent25,harmonic.t_phases,harmonic.capacity,harmonic.number,harmonic.operating_ratio),
                harmoListId: harmonic.harmo_list_id,
                kindList: harmonic.kindList,
                totalCapacity: harmonic.capacity * harmonic.number,
                detailName: harmonic.detailName,
                kindName: harmonic.kindName,
                convertRated:doCalcCoverRated(harmonic.t_phases,harmonic.capacity,harmonic.number)
        }
        })
    return harmonic
}