import { LoadingButton } from "@mui/lab";
import {Autocomplete, Box, Button, Checkbox, CircularProgress, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Stack, Tab, Tabs, TextField, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BreakRatedModel, BreakOpeKindModel, BreakTypeModel, BreakMagniModel, BreakCapacityModel } from "../../../models/breakModel";
import { Path as RoutePath } from "../../../path";
import { ApplicationState, actionCreators, selectors } from "../../../store";
import FetchHelper from "../../FetchHelper";
import BreakRatedGrid from "../BreakRated/BreakRatedGrid";
import { ratedFlagOptions, magniFlagOptions } from "../../../statics";
import AlertDialog from "../../common/AlertDialog";
import BreakMagniGrid from "../BreakMagni/BreakMagniGrid";
import BreakCapacityGrid from "../BreakCapacity/BreakCapacityGrid";
import { Height } from "@mui/icons-material";

// -------------
// FETCH ID
const GET_BREAK_OPEKIND = "GET_BREAK_OPEKIND";
const CREATE_BREAK_OPEKIND = "CREATE_BREAK_OPEKIND";
const UPDATE_BREAK_OPEKIND = "UPDATE_BREAK_OPEKIND";
const DELETE_BREAK_RATED = "DELETE_BREAK_RATED_BREAK_OPEKIND";
const DELETE_BREAK_MAGNI = "DELETE_BREAK_MAGNI";
const DELETE_BREAK_CAPACITY = "DELETE_BREAK_CAPACITY";
const GET_BREAK_OPE_KIND_BY_ID = "GET_BREAK_OPE_KIND_BY_ID";

// 初期値設定
const initialValue: BreakOpeKindModel = {
    lOpeKindID: 0,
    bIsDefault: false,
    sOpeKindName: '',
    nRatedFlag: null,
    sRatedTitle: '',
    sRatedUnit: '',
    nMagniFlag: null,
    sMagniTitle: '',
    sMagniUnit: '',
    bUseOpenTime: false,
    bUseBreakingTime: false,
    mv3breakBreakType: {} as BreakTypeModel
}

// -------------
//Tabs
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

//入力欄背景
const divStyles = {
    backgroundColor: 'white',
    border: '1px solid #bebebe',
    boxShadow: '0 0 5px #fff, 0 0 5px #bebebe, 0 0 1px #aaa',
    padding: '20px 40px',
    marginTop: '40px',
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `oilManagement-tab-${index}`,
        'aria-controls': `oilManagement-tabpanel-${index}`,
    };
}

// -------------
// Props
export type BreakOpeKindEditProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

export const BreakOpeKindEdit = (props: BreakOpeKindEditProps) => {
    const {
        userId,
        onCreate,
        onUpdate,
        onSuccess, onError,
        onDeleteBreakRated, onDeleteBreakRatedError,
        onDeleteBreakMagni,
        onDeleteBreakCapacity,
        onSearchById
    } = props;

    const { control, handleSubmit, setValue, getValues, formState: {errors} } = useForm<BreakOpeKindModel>({
        mode: "all",
        criteriaMode: "all",
        defaultValues: initialValue,
    });

    type MyParams = {
        id: string;
        mode: string;
    };
    let { id, mode } = useParams<keyof MyParams>() as MyParams;

    const gridRef = useRef() as any;
    let navigate = useNavigate();
    const location = useLocation();
    let lTypeID = location.state ? location.state.lTypeID : undefined;
    let sTypeName = location.state ? location.state.sTypeName : "";
    useEffect(() => {
        if (sTypeName !== undefined) {
            setValue("mv3breakBreakType.sTypeName", sTypeName);
        }
    }, [])
    const object = {
        requestPrms:{
            id:id
            
        },
        userId: userId,
    }
    let tab = location.state && location.state.tab;
    const [selectTab, setSelectTab] = useState(0);
    const [loadingFlag, setLoadingFlag] = useState(mode === "edit" ? true : false);
    const [colState, setColState] = useState<any>();
    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");
    const [openedDeleteDialog, setOpenedDeleteDialog] = useState<boolean>(false);
    const [idDelete, setIdDelete] = useState<string>("");
    const [mv3breakBreakRatedList, setMv3breakBreakRatedList] = useState<BreakRatedModel>();
    const [mv3breakBreakMagniList, setMv3breakBreakMagniList] = useState<BreakMagniModel>();
    const [mv3breakBreakCapacityList, setMv3breakBreakCapacityList] = useState<BreakCapacityModel>();

    const handleSuccess = (data: any) => {
        console.log('>>> handleSuccess');
        setValue("lOpeKindID", data.data.lopeKindID);
        setValue("mv3breakBreakType.lTypeID", data.data.ltypeID);
        setValue("mv3breakBreakType.sTypeName", data.data.stypeName);

        setValue("bIsDefault", data.data.bisDefault);
        setValue("sOpeKindName", data.data.sopeKindName);
        setValue("nRatedFlag", data.data.nratedFlag);
        setValue("sRatedTitle", data.data.sratedTitle);
        setValue("sRatedUnit", data.data.sratedUnit);
        setValue("nMagniFlag", data.data.nmagniFlag);
        setValue("sMagniTitle", data.data.smagniTitle);
        setValue("sMagniUnit", data.data.smagniUnit);
        setValue("bUseOpenTime", data.data.buseOpenTime);
        setValue("bUseBreakingTime", data.data.buseBreakingTime);

        if (data.breakRatedList.length > 0) {
            setMv3breakBreakRatedList(data.breakRatedList)
        }
        if (data.breakMagniList.length > 0) {
            setMv3breakBreakMagniList(data.breakMagniList)
        }
        if (data.breakCapacityList.length > 0) {
            setMv3breakBreakCapacityList(data.breakCapacityList)
        }
        setLoadingFlag(false)
        if (tab !== undefined && tab !== null) {
            setSelectTab(tab)
        }
    };

    const tabHandleChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectTab(newValue);
        switch (selectTab) {
            case 1:
                gridRef.current.setRowData(mv3breakBreakRatedList);
                break;
            case 2:
                gridRef.current.setRowData(mv3breakBreakMagniList);
                break;
            case 3:
                gridRef.current.setRowData(mv3breakBreakCapacityList);
                break;
            default:
                break;
        }

        navigate(".", {
            replace: true,
            state: {
                tab: newValue,
                lTypeID: lTypeID,
                sTypeName: sTypeName
            }
        });
    }

    const handleChangeFormat = (e: any) => {
        setValue(e.target.name, e.target.value);
    }

    // 検索エラー時
    const handleError = (success: boolean, data: any, error: any) => {
        console.log('>>> handleError');
        gridRef.current.hideOverlay();
    };

    // キャンセル
    const handleCancel = () => {
        if (location.key !== "default")
            navigate(-1);
        else
            navigate(RoutePath.BreakOpeKindList);
    }

    //分析一覧を表示
    const handleAdd = () => {
        switch (selectTab) {
            case 1:
                navigate(RoutePath.BreakRatedEdit + `/add`,
                    {
                        state: {
                            lBreakOpeKindID: id,
                            sOpeKindName: getValues("sOpeKindName"),
                        }
                    });
                break;
            case 2:
                navigate(RoutePath.BreakMagniEdit + `/add`,
                    {
                        state: {
                            lBreakOpeKindID: id,
                            sOpeKindName: getValues("sOpeKindName"),
                        }
                    });
                break;
            case 3:
                navigate(RoutePath.BreakCapacityEdit + `/add`,
                    {
                        state: {
                            lBreakOpeKindID: id,
                            sOpeKindName: getValues("sOpeKindName"),
                        }
                    });
                break;
            default:
                break;
        }
    }

    // Submitイベント
    const handleEditSubmit = (value: BreakOpeKindModel) => {
        setLoadingFlag(true)
        if (mode === "edit") {
            console.log(getValues());
        
            onUpdate({
                userId: userId,
                requestPrms:{
                    lOpeKindID: getValues("lOpeKindID"),
                    bIsDefault: getValues("bIsDefault"),
                    sOpeKindName: getValues("sOpeKindName"),
                    nRatedFlag: getValues("nRatedFlag") ||null,
                    sRatedTitle: getValues("sRatedTitle"),
                    sRatedUnit: getValues("sRatedUnit"),
                    nMagniFlag: getValues("nMagniFlag") ||null,
                    sMagniTitle: getValues("sMagniTitle"),
                    sMagniUnit: getValues("sMagniUnit"),
                    bUseOpenTime: getValues("bUseOpenTime"),
                    bUseBreakingTime: getValues("bUseBreakingTime"),
                    lTypeID: getValues("mv3breakBreakType.lTypeID")
            }
            });
        } else if (mode === "add") {

            onCreate({
                userId: userId,
                requestPrms:{
                    lOpeKindID: getValues("lOpeKindID"),
                    bIsDefault: getValues("bIsDefault"),
                    sOpeKindName: getValues("sOpeKindName"),
                    nRatedFlag: getValues("nRatedFlag") ||null,
                    sRatedTitle: getValues("sRatedTitle"),
                    sRatedUnit: getValues("sRatedUnit"),
                    nMagniFlag: getValues("nMagniFlag") ||null,
                    sMagniTitle: getValues("sMagniTitle"),
                    sMagniUnit: getValues("sMagniUnit"),
                    bUseOpenTime: getValues("bUseOpenTime"),
                    bUseBreakingTime: getValues("bUseBreakingTime"),
                    lTypeID: lTypeID,
                }
            })
        }
    }

    // Submit後のAPI呼び出し成功時
    const handleSubmitSuccess = (success: boolean, data: any, error: any) => {
        onSuccess();
        // if (lTypeID !== undefined) {
        //     navigate(-1);
        // }
        if(mode==="add"){
            navigate(RoutePath.BreakOpeKindEdit+`/edit/${data.data.lopeKindID}`,{replace:true})
        }
        setLoadingFlag(false)
    }

    // Submit後のAPI呼び出しエラー時
    const handleSubmitError = (success: boolean, data: any, error: any) => {
        console.log('>>> handleError');
        setLoadingFlag(false)
    }

    //削除の処理
    const handleShowDetailPage = (data: any) => {
        switch (selectTab) {
            case 1:
                navigate(RoutePath.BreakRatedEdit + `/edit/${data}`,
                    {
                        state: {
                            lBreakOpeKindID: id,
                        }
                    })
                break;
            case 2:
                navigate(RoutePath.BreakMagniEdit + `/edit/${data}`,
                    {
                        state: {
                            lBreakOpeKindID: id,
                        }
                    })
                break;
            case 3:
                navigate(RoutePath.BreakCapacityEdit + `/edit/${data}`,
                    {
                        state: {
                            lBreakOpeKindID: id,
                        }
                    })
                break;
            default:
                break;
        }

    }

    //削除の処理
    const handleDelete = (data: any) => {
        switch (selectTab) {
            case 1:
                setTitle("定格ダイアル情報削除");
                break;
            case 2:
                setTitle("定格倍率情報削除");
                break;
            case 3:
                setTitle("BREAKCAPACITY情報削除");
                break;
            default:
                break;
        }
        setIdDelete(data);
        const msg = "ID: " + data + "を削除します。"
        setMessage(msg);
        setOpenedDeleteDialog(true);
    }

    // 削除確認ダイアログでOKボタンのイベントを受け取って、APIに送信する
    const handleDeleteOK = () => {
        setOpenedDeleteDialog(false);
        setLoadingFlag(true);
        switch (selectTab) {
            case 1:
                onDeleteBreakRated({
                    userId: userId,
                    requestPrms:{
                            id :idDelete
                }}
                );
                break;
            case 2:
                onDeleteBreakMagni({
                        userId: userId,
                        requestPrms:{
                                id :idDelete
            }
        });
                break;
            case 3:
                onDeleteBreakCapacity({
                    userId: userId,
                    requestPrms:{
                                id :idDelete
            }
                });
                break;
            default:
                break;
        }
        setIdDelete("");
    }

    // 削除確認ダイアログでCancelボタンのイベントを受け取って、ダイアログを無効にする
    const handleDeleteCancel = () => {
        setIdDelete("");
        setOpenedDeleteDialog(false);
    }


    const handleSuccessDelete = (data: any) => {
        console.log('>>> handleSuccessDelete');
        onSuccess();
        setLoadingFlag(false);
        onSearchById({
            userId: userId,
            requestPrms:{
                id:id
            }
        });
    };

    const handleErrorDelete = (success: boolean, data: any, error: any) => {
        onError();
        setLoadingFlag(false);
    }

    const handleSuccessRefresh = (success: boolean, data: any, error: any) => {
        setMv3breakBreakRatedList(data.breakRatedList);
        setMv3breakBreakMagniList(data.breakMagniList);
        setMv3breakBreakCapacityList(data.breakCapacityList);
        switch (selectTab) {
            case 1:
                gridRef.current.setRowData(data.breakRatedList);
                break;
            case 2:
                gridRef.current.setRowData(data.breakMagniList);
                break;
            case 3:
                gridRef.current.setRowData(data.breakCapacityList);
                break;
            default:
                break;
        }
    }

    //分析一覧を表示
    const resetGrid = () => {
        switch (selectTab) {
            case 1:
                gridRef.current.setRowData(mv3breakBreakRatedList);
                break;
            case 2:
                gridRef.current.setRowData(mv3breakBreakMagniList);
                break;
            case 3:
                gridRef.current.setRowData(mv3breakBreakCapacityList);
                break;
            default:
                break;
        }
    }
    useEffect(resetGrid, [selectTab]);
    useEffect(() => {
        if (mode === "add") {
            setValue("mv3breakBreakType.lTypeID", lTypeID);
        }
    }, [])
    return (
        <>
            {mode === "edit" &&
                <FetchHelper fetchId={GET_BREAK_OPEKIND}
                    url={`/master/break_ope_kind/get`}
                    method="POST"
                    params={object}
                    json={true}
                    multipart={false}
                    request={true}
                    onComplete={(success, data, error) =>
                        success ? handleSuccess(data) : handleError(success, data, error)}
                />}
            {/* {loadingFlag && (
                <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1500, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                    <CircularProgress />
                </div>
            )} */}
            <div style={{ height: '100%', width: '100%' }}>
                <Stack direction="row" justifyContent="flex-start" alignItems="center" sx={{}} style={{ margin: '18px', color: 'MenuText' }}>
                    <Typography variant="h4">
                    動作種類詳細
                    </Typography>
                </Stack>
                <form onSubmit={handleSubmit(handleEditSubmit)}>
                    <div style={{ height: '100%', width: '100%' }}>
                        <Stack direction='row'>
                            <div style={{ top: "100px", position: "absolute", zIndex: 1200 }}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs value={selectTab} onChange={tabHandleChange} aria-label="oilManagementDetailTabs">
                                        <Tab label='動作種類' {...a11yProps(0)} />
                                        {mode === "edit" && <Tab label='定格ダイアル' {...a11yProps(1)} />}
                                        {mode === "edit" && <Tab label='定格倍率' {...a11yProps(2)} />}
                                        {mode === "edit" && <Tab label='遮断容量' {...a11yProps(3)} />}
                                    </Tabs>
                                </Box>
                            </div>
                        </Stack>

                        {/* 動作種類 */}
                        <TabPanel value={selectTab} index={0}>
                            <div style={divStyles} >
                                <Typography variant="h6">動作種類</Typography>
                                <Stack spacing={3} mt={3} sx={{ height: "calc(60vh)", overflow: 'auto' }}>
                                    {/* <Stack mt={2}>
                                        <Controller
                                            name="sOpeKindName"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    disabled
                                                    fullWidth
                                                    type="text"
                                                    label="動作種類名"
                                                    size="small"
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                            )}
                                        />
                                    </Stack > */}
                                    <Stack pt={1}>
                                        <Controller
                                            name="mv3breakBreakType.sTypeName"
                                            control={control}
                                            render={({ field }:any) => (
                                                <TextField
                                                    {...field}
                                                    disabled
                                                    fullWidth
                                                    type="text"
                                                    label="型式名"
                                                    size="small"
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                            )}
                                        />
                                    </Stack>
                                    <Stack>
                                        <Controller
                                            name="bIsDefault"
                                            control={control}
                                            render={({ field }: any) => (
                                                <FormControlLabel
                                                    {...field}
                                                    control={<Checkbox />}
                                                    checked={field.value}
                                                    label="デフォルト"
                                                />
                                            )}
                                        />
                                    </Stack>
                                    <Stack>
                                        <Controller
                                            name="sOpeKindName"
                                            control={control}
                                            render={({ field }:any) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    label="動作種類名"
                                                    size="small"
                                                />
                                            )}
                                        />
                                    </Stack>
                                    <Stack>
                                    <Controller
                                            control={control}
                                            name="nRatedFlag"
                                            render={({ field: { value } }:any) => (
                                                <Autocomplete
                                                    autoHighlight
                                                    value={ratedFlagOptions.find(options => options.value == value)??null}
                                                    options={ratedFlagOptions}
                                                    getOptionLabel={(option) => option.label}
                                                    fullWidth
                                                    renderInput={(params) => <TextField {...params} label="定格可変方法" size="small" />}
                                                    onChange={(_, data) => {
                                                        setValue("nRatedFlag", Number(data?.value))
                                                    }}
                                                />
                                            )}
                                        />
                                        {/* <FormControl fullWidth size="small">
                                            <InputLabel>定格可変方法</InputLabel>
                                            <Controller
                                                name="nRatedFlag"
                                                control={control}
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        label="定格可変方法"
                                                         onChange={handleChangeFormat}
                                                    >
                                                        {ratedFlagOptions.map(item => (
                                                            <MenuItem key={item.label} value={item.value}>
                                                                {item.label}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                )}
                                            />
                                        </FormControl> */}
                                    </Stack>
                                    <Stack>
                                        <Controller
                                            name="sRatedTitle"
                                            control={control}
                                            render={({ field }:any) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    label="定格ダイアルタイトル"
                                                    size="small"
                                                />
                                            )}
                                        />
                                    </Stack>
                                    <Stack>
                                        <Controller
                                            name="sRatedUnit"
                                            control={control}
                                            rules={{
                                                maxLength: {
                                                    value: 16,
                                                    message: "文字列よりが長すきて編集できません。"
                                                }
                                            }}
                                            render={({ field }:any) => (
                                                <TextField
                                                    error={!!errors.sRatedUnit?.message}
                                                    helperText={errors.sRatedUnit?.message}
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    label="定格单位"
                                                    size="small"
                                                />
                                            )}
                                        />
                                    </Stack>
                                    <Stack>
                                    <Controller
                                            control={control}
                                            name="nMagniFlag"
                                            render={({ field: { value } }:any) => (
                                                <Autocomplete
                                                    autoHighlight
                                                    value={magniFlagOptions.find(options => options.value == value)??null}
                                                    options={magniFlagOptions}
                                                    getOptionLabel={(option) => option.label}
                                                    fullWidth
                                                    renderInput={(params) => <TextField {...params} label="可变方法" size="small" />}
                                                    onChange={(_, data) => {
                                                        setValue("nMagniFlag", Number(data?.value))
                                                    }}
                                                />
                                            )}
                                        />
                                        {/* <FormControl fullWidth size="small">
                                            <InputLabel >可变方法</InputLabel>
                                            <Controller
                                                name="nMagniFlag"
                                                control={control}
                                                
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        label="可变方法"
                                                        onChange={handleChangeFormat}
                                                        
                                                    >
                                                      
                                                        {magniFlagOptions.map(item => (
                                                            <MenuItem key={item.label} value={item.value}>
                                                                {item.label}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                )}
                                            />
                                        </FormControl> */}
                                    </Stack>
                                    <Stack>
                                        <Controller
                                            name="sMagniTitle"
                                            control={control}
                                            render={({ field }:any) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    label="定格倍率タイトル"
                                                    size="small"
                                                />
                                            )}
                                        />
                                    </Stack>
                                    <Stack>
                                        <Controller
                                            name="sMagniUnit"
                                            control={control}
                                            render={({ field }:any) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    label="定格倍率単位"
                                                    size="small"
                                                />
                                            )}
                                        />
                                    </Stack>
                                    <Stack>
                                        <Grid container spacing={1} direction="row">
                                            <Grid item xs={2}>
                                                <Controller
                                                    name="bUseOpenTime"
                                                    control={control}
                                                    render={({ field }: any) => (
                                                        <FormControlLabel
                                                            {...field}
                                                            control={<Checkbox />}
                                                            checked={field.value}
                                                            label="開極時間設定"
                                                            labelPlacement="start"
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Controller
                                                    name="bUseBreakingTime"
                                                    control={control}
                                                    render={({ field }: any) => (
                                                        <FormControlLabel
                                                            {...field}
                                                            control={<Checkbox />}
                                                            checked={field.value}
                                                            label="遮断時間設定"
                                                            labelPlacement="start"
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Stack>
                                </Stack>
                            </div>
                        </TabPanel>
                        {mode === "edit" &&
                            <>
                                <TabPanel value={selectTab} index={1}>
                                    <div style={divStyles}>
                                        <Stack direction="row" display='flex' justifyContent='space-between'>
                                            <div><Typography variant="h6" >定格ダイアル</Typography></div>
                                            <div >
                                                <Stack direction='row' display='flex' spacing={2}>
                                                    <Stack>
                                                        <Button fullWidth color="inherit" variant="contained" onClick={() => handleAdd()}>定格ダイアルの追加</Button>
                                                    </Stack>
                                                </Stack>
                                            </div>
                                        </Stack>
                                        <Stack spacing={2} sx={{ mt: 1 }} >
                                            <BreakRatedGrid
                                                ref={gridRef}
                                                colState={colState}
                                                onChangeSearchCondition={() => { }}
                                                keyword={""}
                                                onShowDetails={handleShowDetailPage}
                                                onDelete={handleDelete}
                                                heightForm="365"
                                                checkEdit={true}
                                                mode ="edit"
                                            />
                                            {openedDeleteDialog &&
                                                <AlertDialog
                                                    title={title}
                                                    message={message}
                                                    isOpen={true}
                                                    onOK={handleDeleteOK}
                                                    onCancel={handleDeleteCancel} />
                                            }
                                        </Stack>
                                    </div>
                                </TabPanel>
                                <TabPanel value={selectTab} index={2}>
                                    <div style={divStyles}>
                                        <Stack direction="row" display='flex' justifyContent='space-between'>
                                            <div><Typography variant="h6" >定格倍率</Typography></div>
                                            <div >
                                                <Stack direction='row' display='flex' spacing={2}>
                                                    <Stack>
                                                        <Button fullWidth color="inherit" variant="contained" onClick={() => handleAdd()}>定格倍率の追加</Button>
                                                    </Stack>
                                                </Stack>
                                            </div>
                                        </Stack>
                                        <Stack spacing={2} sx={{ mt: 1 }} >
                                            <BreakMagniGrid
                                                ref={gridRef}
                                                colState={colState}
                                                onChangeSearchCondition={() => { }}
                                                keyword={""}
                                                onShowDetails={handleShowDetailPage}
                                                onDelete={handleDelete}
                                                heightForm="365"
                                                checkEdit={true}
                                                mode ="edit"

                                            />
                                            {openedDeleteDialog &&
                                                <AlertDialog
                                                    title={title}
                                                    message={message}
                                                    isOpen={true}
                                                    onOK={handleDeleteOK}
                                                    onCancel={handleDeleteCancel} />
                                            }
                                        </Stack>
                                    </div>
                                </TabPanel>
                                <TabPanel value={selectTab} index={3}>
                                    <div style={divStyles}>
                                        <Stack direction="row" display='flex' justifyContent='space-between'>
                                            <div><Typography variant="h6" >遮断容量</Typography></div>
                                            <div >
                                                <Stack direction='row' display='flex' spacing={2}>
                                                    <Stack>
                                                        <Button fullWidth color="inherit" variant="contained" onClick={() => handleAdd()}>遮断容量の追加</Button>
                                                    </Stack>
                                                </Stack>
                                            </div>
                                        </Stack>
                                        <Stack spacing={2} sx={{ mt: 1 }} >
                                            <BreakCapacityGrid
                                                ref={gridRef}
                                                colState={colState}
                                                onChangeSearchCondition={() => { }}
                                                keyword={""}
                                                onShowDetails={handleShowDetailPage}
                                                onDelete={handleDelete}
                                                heightForm="365"
                                                checkEdit={true}
                                                mode ="edit"


                                            />
                                            {openedDeleteDialog &&
                                                <AlertDialog
                                                    title={title}
                                                    message={message}
                                                    isOpen={true}
                                                    onOK={handleDeleteOK}
                                                    onCancel={handleDeleteCancel} />
                                            }
                                        </Stack>
                                    </div>
                                </TabPanel>
                            </>
                        }

                        {/* TODO: Break Magni && Break Capacity */}
                    </div>
                    <Stack direction='row' justifyContent="flex-end" spacing={2} sx={{ mt: 0, mr: 3 }}>
                        <LoadingButton variant="contained" type="submit" loading={loadingFlag}>保存</LoadingButton>
                        <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                    </Stack>
                </form>
            </div>
            <FetchHelper
                fetchId={CREATE_BREAK_OPEKIND}
                onComplete={(success, data, error) => success ?
                    handleSubmitSuccess(success, data, error)
                    : handleSubmitError(success, data, error)}
            />
            <FetchHelper
                fetchId={UPDATE_BREAK_OPEKIND}
                onComplete={(success, data, error) => success ?
                    handleSubmitSuccess(success, data, error)
                    : handleSubmitError(success, data, error)}
            />
            <FetchHelper
                fetchId={DELETE_BREAK_RATED}
                onComplete={(success, data, error) => success ?
                    handleSuccessDelete(success)
                    : handleErrorDelete(success, data, error)}
            />
            <FetchHelper
                fetchId={DELETE_BREAK_MAGNI}
                onComplete={(success, data, error) => success ?
                    handleSuccessDelete(success)
                    : handleErrorDelete(success, data, error)}
            />
            <FetchHelper
                fetchId={DELETE_BREAK_CAPACITY}
                onComplete={(success, data, error) => success ?
                    handleSuccessDelete(success)
                    : handleErrorDelete(success, data, error)}
            />
            <FetchHelper
                fetchId={GET_BREAK_OPE_KIND_BY_ID}
                onComplete={(success, data, error) => success ?
                    handleSuccessRefresh(success, data, error)
                    : onError()}
            />
        </>
    );
};

const mapStateToProps = (state: ApplicationState) => ({
    userId: state.app.user?.userId,
    fetchUpdate: selectors.getFormState(
        state,
        UPDATE_BREAK_OPEKIND
    ),
});

const mapDispatchToProps = (dispatch: any) => {
    return {
        onCreate: (data: any) => dispatch(actionCreators.fetch(CREATE_BREAK_OPEKIND, `/master/break_ope_kind/add`, 'POST', data,false,true)),
        onUpdate: (data: any) => dispatch(actionCreators.fetch(UPDATE_BREAK_OPEKIND, `/master/break_ope_kind/modify`, 'POST', data,false, true)),
        onDeleteBreakRated: (id: any) => dispatch(actionCreators.fetch(DELETE_BREAK_RATED, `/master/break_rated/delete`, "POST", id,false,true)),
        onDeleteBreakMagni: (id: any) => dispatch(actionCreators.fetch(DELETE_BREAK_MAGNI, `/master/break_magni/delete`, "POST", id,false,true)),
        onDeleteBreakCapacity: (id: any) => dispatch(actionCreators.fetch(DELETE_BREAK_CAPACITY, `/master/break_capacity/delete`, "POST", id,false,true)),
        onSearchById: (id: any) => dispatch(actionCreators.fetch(GET_BREAK_OPE_KIND_BY_ID, `/master/break_ope_kind/get`, "POST", id, false,true)),
        onSuccess: () =>
            dispatch(actionCreators.showMessage({
                type: "info",
                title: "動作種類更新",
                body: "動作種類を更新しました。",
            })),
        onError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "検索",
                body: "検索できません。",
            })),
        onCreateError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "動作種類登録",
                body: "動作種類を登録出来ませんでした。",
            })),
        onUpdateError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "動作種類編集",
                body: "動作種類を更新出来ませんでした。",
            })),
        onDeleteBreakRatedError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "定格編集",
                body: "定格を更新出来ませんでした。",
            })),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BreakOpeKindEdit as any);


