import { Box, Button, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, Stack, Tab, Tabs, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { MsTrans1Model } from '../../models/Index';
import TextFieldOptions from '../common/TextFieldOptions';
import { 
    msTrans1GetDropPerUnit, 
    msTrans1GetDropVoltDiff,
    msTrans1GetInrushValue, 
    msTrans1GetIntensityValue, 
    msTrans1ScottGetLoadCapacity, 
    msTrans1ScottGetLoadCurrent, 
    msTrans1GetRatedCurrent, 
    msTransFromXR_ZCalculation, 
    msTransNormalCalculation, 
    doCalcSetValuesTrans1,
    beamsNumberTrans1
} from '../../utils/PowerCalculation';
import { BeamsNumber } from '../../utils/FormatNumber';
import { MS_MODE_CHECKING, MS_OUTSIDE_NEUTRAL, MS_OUTSIDE_OUTSIDE, MS_XR_MAX, VOLT_SIDE_LOW_MAX } from '../../statics';
import * as Constant from "../../models/Constants";
import { isNumber } from 'mathjs';

const radioStyles = { padding: '4px 4px 4px 8px' }
const checkBoxStyles = { padding: '4px 4px 4px 8px' }
const checkboxStyle = {
    padding: '0px 0px 0px 9px'
}
const radioStyles1 = {
    padding: '0 0 0 9px'
}
export type MsTrans1EditDialogFormProps = {
    data: any;
    onOK: (data: any) => void;
    onCancel: () => void;
    setIsSourceVoltage: boolean;
    userRole:string;
    m_bModePM:boolean;
    modeViewOnly: boolean;
    dispInputDialog:any;
    trans1List: any;
    processMode:number;
};

function a11yProps(index: number) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}

//Tabs------------------
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}
//------------- trans1Parts[0] --> [外線‐外線] / outside -------------//
//------------- trans1Parts[1] --> [外線‐中性線] / neutral -------------//
export const MsTrans1EditDialog = (props: MsTrans1EditDialogFormProps) => {
                const { m_bModePM, modeViewOnly, userRole, data, onOK, onCancel,setIsSourceVoltage,dispInputDialog ,trans1List,processMode} = props;
    const [selectTab, setSelectTab] = useState(0);

    // 初期値設定
    const initialValue: MsTrans1Model = { ...data,
        partOutIn:{...data.partOutIn,
                    calcPoint: {...data.partOutIn.calcPoint,loadCapacity: BeamsNumber(data.partOutIn.calcPoint.loadCapacity,7),
                        loadCurrent: BeamsNumber(data.partOutIn.calcPoint.loadCurrent,5)
                    },
                    percentR: BeamsNumber(data.partOutIn.percentR,5),
                    percentX: BeamsNumber(data.partOutIn.percentX,5)
        },
        partOutOut:{...data.partOutOut,
            calcPoint: {...data.partOutOut.calcPoint,loadCapacity: BeamsNumber(data.partOutOut.calcPoint.loadCapacity,7),
                loadCurrent: BeamsNumber(data.partOutOut.calcPoint.loadCurrent,5)
            },
            percentR: BeamsNumber(data.partOutOut.percentR,5),
            percentX: BeamsNumber(data.partOutOut.percentX,5)
}
     }
    const { control, handleSubmit, formState: { errors }, setValue, getValues,setError,clearErrors } = useForm<MsTrans1Model>({
        mode: 'all',
        criteriaMode: 'all',
        defaultValues: initialValue,
    });
    
    const [dropRegular1, setDropRegular1] = useState<boolean>(initialValue.partOutOut.dropRegular);
    const [dropRegular2, setDropRegular2] = useState<boolean>(initialValue.partOutIn.dropRegular);
    const [makeDataInput, setMakeDataInput] = useState(initialValue.makeDataInput); // ModePM

    // Submitイベント
    const handleEditSubmit = (value: MsTrans1Model) => {
        const request = createRequestData(value);
        onOK(request);
    }

    // FormデータからRequestデータを作成する
    const createRequestData = (formValue: MsTrans1Model) => {
        if(!Number(formValue.loadFactor)){
            formValue.loadFactor = 0
        }
        if(!Number(formValue.partOutIn.xr)){
            formValue.partOutIn.xr = 0
        }
        if(!Number(formValue.partOutIn.percentR)){
            formValue.partOutIn.percentR = 0
        }
        if(!Number(formValue.partOutIn.percentX)){
            formValue.partOutIn.percentX = 0
        }
        if(!Number(formValue.partOutOut.xr)){
            formValue.partOutOut.xr = 0
        }
        if(!Number(formValue.partOutOut.percentR)){
            formValue.partOutOut.percentR = 0
        }
        if(!Number(formValue.partOutOut.percentX)){
            formValue.partOutOut.percentX = 0
        }
        if(!/^[0-9]*[.]?[0-9]+$/.test(formValue.partOutIn.calcPoint.powerFactor.toString())){
            formValue.partOutIn.calcPoint.powerFactor = 0
        }
        if(!/^[0-9]*[.]?[0-9]+$/.test(formValue.partOutOut.calcPoint.powerFactor.toString())){
            formValue.partOutOut.calcPoint.powerFactor = 0
        }
        let request = { ...formValue } as MsTrans1Model;
        return request;
    }

    // キャンセル
    const handleCancel = () => {
        onCancel();
    };
    
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        if(newValue >= 0){
            if (getValues('refNo') == "" ||getValues('refNo') == null || getValues('refNo') == undefined ) {
                setError("refNo",{ type: 'custom', message: '必須項目です。入力してください。' });
            }
            else if (isNaN(Number(getValues('voltagePrimary'))) || Number(getValues('voltagePrimary')) <= 0) {
                setError("voltagePrimary",{ type: 'custom', message: '数字 > 0 を入力してください。' });
            }
            else if (isNaN(Number(getValues('voltageSecondary'))) || Number(getValues('voltageSecondary')) <= 0) {
                setError("voltageSecondary",{ type: 'custom', message: '数字 > 0 を入力してください。' });
            }
            else if (isNaN(Number(getValues('capacity'))) || Number(getValues('capacity')) <= 0) {
                setError("capacity",{ type: 'custom', message: '数字 > 0 を入力してください。' });
            }
            else if (Number(getValues('loadFactor')) < 0 || Number(getValues('loadFactor')) > 1 || !/^[0-9]*[.]?[0-9]+$/.test(getValues('loadFactor').toString())) {
                setError("loadFactor",{ type: 'custom', message: '数字 0 ~ 1 を入力してください。' });
            }
            else if (isNaN(Number(getValues('powerFactor'))) || Number(getValues('powerFactor')) <= 0 || Number(getValues('powerFactor')) > 1 || !/^[0-9]*[.]?[0-9]+$/.test(getValues('powerFactor').toString())) {
                setError("powerFactor",{ type: 'custom', message: '0 < 数字 <= 1 を入力してください。' });
            }
            else if (selectTab == 1 && (Number(getValues('partOutOut.xr')) < 0 || !/^[0-9]*[.]?[0-9]+$/.test(getValues('partOutOut.xr').toString()))){
                setError("partOutOut.xr",{ type: 'custom', message: ' 数字 >= 0 を入力してください。' });
            }
            else if (selectTab == 1 && (isNaN(Number(getValues('partOutOut.percentZ'))) || Number(getValues('partOutOut.percentZ')) <= 0 || !/^[0-9]*[.]?[0-9]+$/.test(getValues('partOutOut.percentZ').toString()))) {
                setError("partOutOut.percentZ",{ type: 'custom', message: '数字 >0 を入力してください。' });
            }
            else if (selectTab == 1 && (Number(getValues('partOutOut.percentR')) < 0 || !/^[0-9]*[.]?[0-9]+$/.test(getValues('partOutOut.percentR').toString()))) {
                setError("partOutOut.percentR",{ type: 'custom', message: ' 数字 >= 0 を入力してください。' });
            }
            else if (selectTab == 1 && (Number(getValues('partOutOut.percentX')) < 0 || !/^[0-9]*[.]?[0-9]+$/.test(getValues('partOutOut.percentX').toString()))) {
                setError("partOutOut.percentX",{ type: 'custom', message: ' 数字 >= 0 を入力してください。' });
            }
            else if (selectTab == 2 && (Number(getValues('partOutIn.xr')) < 0 || !/^[0-9]*[.]?[0-9]+$/.test(getValues('partOutIn.xr').toString()))) {
                setError("partOutIn.xr",{ type: 'custom', message: ' 数字 >= 0 を入力してください。' });
            }
            else if (selectTab == 2 && (isNaN(Number(getValues('partOutIn.percentZ'))) || Number(getValues('partOutIn.percentZ')) <= 0 || !/^[0-9]*[.]?[0-9]+$/.test(getValues('partOutIn.percentZ').toString()))) {
                setError("partOutIn.percentZ",{ type: 'custom', message: '数字 >0 を入力してください。' });
            }
            else if (selectTab == 2 && (Number(getValues('partOutIn.percentR')) < 0 || !/^[0-9]*[.]?[0-9]+$/.test(getValues('partOutIn.percentR').toString()))) {
                setError("partOutIn.percentR",{ type: 'custom', message: ' 数字 >= 0 を入力してください。' });
            }
            else if (selectTab == 2 && (Number(getValues('partOutIn.percentX')) < 0 || !/^[0-9]*[.]?[0-9]+$/.test(getValues('partOutIn.percentX').toString()))) {
                setError("partOutIn.percentX",{ type: 'custom', message: ' 数字 >= 0 を入力してください。' });
            }
            else if (isNaN(Number(getValues('partOutOut.inrushTimes'))) || Number(getValues('partOutOut.inrushTimes')) <= 0) {
                setError("partOutOut.inrushTimes",{ type: 'custom', message: '数字 > 0 を入力してください。' });
            }
            else if (isNaN(Number(getValues('partOutOut.dampingTime'))) || Number(getValues('partOutOut.dampingTime')) <= 0) {
                setError("partOutOut.dampingTime",{ type: 'custom', message: '数字 > 0 を入力してください。' });
            }
            else if (isNaN(Number(getValues('partOutIn.inrushTimes'))) || Number(getValues('partOutIn.inrushTimes')) <= 0) {
                setError("partOutIn.inrushTimes",{ type: 'custom', message: '数字 > 0 を入力してください。' });
            }
            else if (isNaN(Number(getValues('partOutIn.dampingTime'))) || Number(getValues('partOutIn.dampingTime')) <= 0) {
                setError("partOutIn.dampingTime",{ type: 'custom', message: '数字 > 0 を入力してください。' });
            }
            else if (isNaN(Number(getValues('partOutOut.intensityTimes'))) || Number(getValues('partOutOut.intensityTimes')) <= 0) {
                setError("partOutOut.intensityTimes",{ type: 'custom', message: '数字 > 0 を入力してください。' });
            }
            else if (isNaN(Number(getValues('partOutOut.intensityTime'))) || Number(getValues('partOutOut.intensityTime')) <= 0) {
                setError("partOutOut.intensityTime",{ type: 'custom', message: '数字 > 0 を入力してください。' });
            }
            else if (isNaN(Number(getValues('partOutIn.intensityTimes'))) || Number(getValues('partOutIn.intensityTimes')) <= 0) {
                setError("partOutIn.intensityTimes",{ type: 'custom', message: '数字 > 0 を入力してください。' });
            }
            else if (isNaN(Number(getValues('partOutIn.intensityTime'))) || Number(getValues('partOutIn.intensityTime')) <= 0) {
                setError("partOutIn.intensityTime",{ type: 'custom', message: '数字 > 0 を入力してください。' });
            }
            else if (Number(getValues('partOutIn.calcPoint.loadCapacity')) < 0 || !/^[0-9]*[.]?[0-9]+$/.test(getValues('partOutIn.calcPoint.loadCapacity').toString())) {
                setError("partOutIn.calcPoint.loadCapacity",{ type: 'custom', message: '数字 >= 0 を入力してください。' });
            }
            else if (Number(getValues('partOutIn.calcPoint.loadCurrent')) < 0 || !/^[0-9]*[.]?[0-9]+$/.test(getValues('partOutIn.calcPoint.loadCurrent').toString())) {
                setError("partOutIn.calcPoint.loadCurrent",{ type: 'custom', message: '数字 >= 0 を入力してください。' });
            }
            else if (Number(getValues('partOutOut.calcPoint.loadCapacity')) < 0 || !/^[0-9]*[.]?[0-9]+$/.test(getValues('partOutOut.calcPoint.loadCapacity').toString())) {
                setError("partOutOut.calcPoint.loadCapacity",{ type: 'custom', message: '数字 >= 0 を入力してください。' });
            }
            else if (Number(getValues('partOutOut.calcPoint.loadCurrent')) < 0 || !/^[0-9]*[.]?[0-9]+$/.test(getValues('partOutOut.calcPoint.loadCurrent').toString())) {
                setError("partOutOut.calcPoint.loadCurrent",{ type: 'custom', message: '数字 >= 0 を入力してください。' });
            }
            else if (Number(getValues('partOutOut.calcPoint.powerFactor')) < 0 ||Number(getValues('partOutOut.calcPoint.powerFactor')) > 1 || !/^[0-9]*[.]?[0-9]+$/.test(getValues('partOutOut.calcPoint.powerFactor').toString()))  {
                setError("partOutOut.calcPoint.powerFactor",{ type: 'custom', message: '数字 0 ~ 1 を入力してください。' });
            }
            else if (Number(getValues('partOutIn.calcPoint.powerFactor')) < 0 || Number(getValues('partOutIn.calcPoint.powerFactor')) > 1 || !/^[0-9]*[.]?[0-9]+$/.test(getValues('partOutIn.calcPoint.powerFactor').toString())) {
                setError("partOutIn.calcPoint.powerFactor",{ type: 'custom', message: '数字 0 ~ 1 を入力してください。' });
            }
            else{
                setSelectTab(newValue);
            }
        }
        else{
            setSelectTab(newValue);
        }
    }

    // ModePM
    const handleChangeMakeDataInput = (e: any) => {
        setValue(e.target.name, e.target.checked);
        setMakeDataInput(e.target.checked);
    }

    const handleChangeFormat = (e: any) => {
        setValue(e.target.name, e.target.value);
    }
    
    const handleChangeCheckbox = (e: any) => {
        setValue(e.target.name, e.target.checked);
    }
    
    const handleChangeVoltagePrimary = (e: any) => {
        setValue(e.target.name, e.target.value);
        if(e.target.value > 0){
            clearErrors(e.target.namae)
        }
        doCalcRatedCurrent();
    }
    
    const handleChangeVoltageSecondary = (e: any) => {
        setValue(e.target.name, e.target.value);
        if(e.target.value > 0){
            clearErrors(e.target.name)
        }
        setValue('voltage1', Math.trunc(e.target.value / 2));
        
        doCalcRatedCurrent1();
        
        doCalcPowerNormal(true);
        doCalcPowerNormal(false);
        
        doCalcInrushValue1();
        doCalcInrushValue2();

        doCalcIntensityValue1();
        doCalcIntensityValue2();

        doCalcVoltageDrop1(true);
        doCalcVoltageDrop1(false);
        
        doCalcVoltageDrop(true);
        doCalcVoltageDrop(false);
    }
    
    const handleChangeCapacity = (e: any) => {
        setValue(e.target.name, e.target.value);
        if(e.target.value > 0){
            clearErrors(e.target.name)
        }
        setValue('capacity1', e.target.value / 2);
        doCalcSetValuesTrans1(+e.target.value,trans1List,getValues("partOutIn"),getValues("partOutOut"),"")
        beamsNumberTrans1(getValues("partOutIn"))
        beamsNumberTrans1(getValues("partOutOut"))

        doCalcRatedCurrent();
        doCalcRatedCurrent1();

        doCalcPowerNormal(true);
        doCalcPowerNormal(false);
        
        doCalcInrushValue1();
        doCalcInrushValue2();

        doCalcIntensityValue1();
        doCalcIntensityValue2();

        doCalcVoltageDrop1(true);
        doCalcVoltageDrop1(false);
        
        doCalcVoltageDrop(true);
        doCalcVoltageDrop(false);
    }
    const clearErrorsTrans1 = (part:string) =>{
        if(part === "partOutOut"){
            if(getValues('partOutOut.percentZ') > 0){
                clearErrors('partOutOut.percentZ')
            }
            if(getValues('partOutOut.percentR') >= 0){
                clearErrors('partOutOut.percentR')
            }
            if(getValues('partOutOut.percentX') >= 0){
                clearErrors('partOutOut.percentX')
            }
            if(getValues('partOutOut.xr') >= 0){
                clearErrors('partOutOut.xr')
            }
        }
        else{
            if(getValues('partOutIn.percentZ') > 0){
                clearErrors('partOutIn.percentZ')
            }
            if(getValues('partOutIn.percentR') >= 0){
                clearErrors('partOutIn.percentR')
            }
            if(getValues('partOutIn.percentX') >= 0){
                clearErrors('partOutIn.percentX')
            }
            if(getValues('partOutIn.xr') >= 0){
                clearErrors('partOutIn.xr')
            }
        }

    }
    const handleChangeXR_Z = (e: any) => {
        setValue(e.target.name, e.target.value);
        doCalcSetValuesTrans1(+getValues("capacity"),trans1List,getValues("partOutIn"),getValues("partOutOut"),e.target.name.split('.')[1])
        beamsNumberTrans1(getValues("partOutIn"))
        beamsNumberTrans1(getValues("partOutOut"))
        if (['partOutOut.xr', 'partOutOut.percentZ'].includes(e.target.name)){
            doCalcPowerFromXR_Z(false,e.target.name.split('.')[1]);
                clearErrorsTrans1('partOutOut')
        }
        else{
            doCalcPowerFromXR_Z(true,e.target.name.split('.')[1]);
            clearErrorsTrans1('partOutIn')

        }
    }

    const handleChangeR_X = (e: any) => {
        setValue(e.target.name, e.target.value);

        if (['partOutOut.percentX', 'partOutOut.percentR'].includes(e.target.name)){
            doCalcXr(false);
            doCalcPowerNormal(false);
            clearErrorsTrans1('partOutOut')
        } else {
            doCalcXr(true);
            doCalcPowerNormal(true);
            clearErrorsTrans1('partOutIn')
        }
    }

    const handleChangeInrushTimes = (e: any) => {
        setValue(e.target.name, e.target.value);
        if (e.target.name === 'partOutOut.inrushTimes')
            doCalcInrushValue2();
        else
            doCalcInrushValue1();
    }

    const handleChangeIntensityTimes = (e: any) => {
        setValue(e.target.name, e.target.value);

        if (e.target.name === 'partOutOut.intensityTimes')
            doCalcIntensityValue2();
        else
            doCalcIntensityValue1();
    }
        
    const hanDleChangedropRegular = (e: any) => {
        setValue(e.target.name, e.target.checked);
        if (e.target.name === 'partOutOut.dropRegular')
            setDropRegular1(e.target.checked);
        else
            setDropRegular2(e.target.checked);
    }

    const handleChangeLoadCapacity = (e: any) => {
        setValue(e.target.name, e.target.value);
        if (e.target.name === 'partOutOut.calcPoint.loadCapacity'){
            doCalcLoadCurrent2();
            if(+getValues("partOutOut.calcPoint.loadCurrent") >=0){
                clearErrors('partOutOut.calcPoint.loadCurrent')
            }
        }
        else{
            doCalcLoadCurrent1();
            if(+getValues("partOutIn.calcPoint.loadCurrent") >=0){
                clearErrors('partOutIn.calcPoint.loadCurrent')
            }
        }
    }

    const handleChangeLoadCurrent = (e: any) => {
        setValue(e.target.name, e.target.value);
        if (e.target.name === 'partOutOut.calcPoint.loadCurrent'){
            doCalcLoadCapacity2();
            if(+getValues("partOutOut.calcPoint.loadCapacity") >=0){
                clearErrors('partOutOut.calcPoint.loadCapacity')
            }
            doCalcVoltageDrop1(false);
            doCalcVoltageDrop(false);
        }
        else{
            doCalcLoadCapacity1();
            if(+getValues("partOutIn.calcPoint.loadCapacity") >=0){
                clearErrors('partOutIn.calcPoint.loadCapacity')
            }
            doCalcVoltageDrop1(true);
            doCalcVoltageDrop(true);
        }
    }
    
    const handleChangeCalcVoltDrop = (e: any) => {
        if (e.target.name.includes('calcPoint.isLead'))
            setValue(e.target.name, e.target.value == 'true'); // RadioGroup bool true => string 'true' ??
        else
            setValue(e.target.name, e.target.value);
        if (['partOutOut.calcPoint.powerFactor', 'partOutOut.calcPoint.isLead'].includes(e.target.name)){
            doCalcVoltageDrop1(false);
            doCalcVoltageDrop(false);
        } else {
            doCalcVoltageDrop1(true);
            doCalcVoltageDrop(true);
        }
    }
    const doSetValueDropLoadCapacity =(voltage:number,nCalcKind:number,loadCurrent:number) =>{
        let loadCapacity = voltage * loadCurrent
        if(nCalcKind == Constant.CALC_IK3){
            loadCapacity *= Math.sqrt(3.0)
        }
        
        return BeamsNumber(loadCapacity,7)
    }
    const resetCurrentTopLeft = (partIndex: number) => {
        if (partIndex === 1){
            setValue("partOutIn.inputedCapacity1",false);
            setValue("partOutIn.inputedCurrent1",false);
            setValue('partOutIn.calcPoint.loadCurrent', BeamsNumber(dispInputDialog.load_current_0,5));
            setValue('partOutIn.calcPoint.loadCapacity', doSetValueDropLoadCapacity(getValues("voltageSecondary")/2,getValues("partOutIn.calcPoint.calcKind"),dispInputDialog.load_current_0));
            clearErrors('partOutIn.calcPoint.loadCurrent')
            clearErrors('partOutIn.calcPoint.loadCapacity')
            doCalcVoltageDrop1(true);
            doCalcVoltageDrop(true);
        } else {
            setValue("partOutOut.inputedCapacity1",false);
            setValue("partOutOut.inputedCurrent1",false);
            setValue('partOutOut.calcPoint.loadCurrent', BeamsNumber(dispInputDialog.load_current_1,5));
            setValue('partOutOut.calcPoint.loadCapacity', doSetValueDropLoadCapacity(getValues("voltageSecondary"),getValues("partOutOut.calcPoint.calcKind"),dispInputDialog.load_current_1));
            clearErrors('partOutOut.calcPoint.loadCurrent')
            clearErrors('partOutOut.calcPoint.loadCapacity')
            doCalcVoltageDrop1(false);
            doCalcVoltageDrop(false);
        }
    }

    const resetLeadLag = (partIndex: number) => {
        if (partIndex === 1){
            setValue('partOutIn.inputedPowerFactor1', false);
            setValue('partOutIn.calcPoint.isLead', dispInputDialog.is_lead_1);
            setValue('partOutIn.calcPoint.powerFactor', BeamsNumber(dispInputDialog.power_factor_1,5));
            clearErrors('partOutIn.calcPoint.powerFactor')
            doCalcVoltageDrop1(true);
            doCalcVoltageDrop(true);
        } else {
            setValue('partOutOut.inputedPowerFactor1', false);
            setValue('partOutOut.calcPoint.isLead', dispInputDialog.is_lead_1);
            setValue('partOutOut.calcPoint.powerFactor', BeamsNumber(dispInputDialog.power_factor_1,5));
            clearErrors('partOutOut.calcPoint.powerFactor');
            doCalcVoltageDrop1(false);
            doCalcVoltageDrop(false);
        }
    }
    
    const doCalcRatedCurrent = () => {
        let ratedCurrent = msTrans1GetRatedCurrent(
            getValues('capacity'),
            getValues('voltagePrimary')
        );
        setValue('ratedCurrent', BeamsNumber(ratedCurrent, 4));
    }
    
    const doCalcRatedCurrent1 = () => {
        let ratedCurrent1 = msTrans1GetRatedCurrent(
            getValues('capacity'),
            getValues('voltageSecondary')
        );
        setValue('ratedCurrent1', BeamsNumber(ratedCurrent1, 4));
    }

    const doCalcInrushValue1 = () => {
        let inrushValue1 = msTrans1GetInrushValue(
            getValues('partOutIn.inrushTimes'),
            getValues('capacity'),
            getValues('voltageSecondary')
        );
        setValue('partOutIn.inrushValue', BeamsNumber(inrushValue1, 4));
    }

    const doCalcInrushValue2 = () => {
        let inrushValue2 = msTrans1GetInrushValue(
            getValues('partOutOut.inrushTimes'),
            getValues('capacity'),
            getValues('voltageSecondary')
        );
        setValue('partOutOut.inrushValue', BeamsNumber(inrushValue2, 4));
    }
    
    const doCalcIntensityValue1 = () => {
        let intensityValue1 = msTrans1GetIntensityValue(
            getValues('partOutIn.intensityTimes'),
            getValues('capacity'),
            getValues('voltageSecondary')
        );
        setValue('partOutIn.intensityValue', BeamsNumber(intensityValue1, 4));
    }

    const doCalcIntensityValue2 = () => {
        let intensityValue2 = msTrans1GetIntensityValue(
            getValues('partOutOut.intensityTimes'),
            getValues('capacity'),
            getValues('voltageSecondary')
        );
        setValue('partOutOut.intensityValue', BeamsNumber(intensityValue2, 4));
    }

    const doCalcLoadCurrent1 = () => {
        let loadCurrent1 = msTrans1ScottGetLoadCurrent(
            Math.trunc(getValues('voltageSecondary') / 2),
            getValues('partOutIn.calcPoint.loadCapacity')
        );
        setValue('partOutIn.calcPoint.loadCurrent', BeamsNumber(loadCurrent1, 7));

        doCalcVoltageDrop1(true);
        doCalcVoltageDrop(true);
    }
    
    const doCalcLoadCurrent2 = () => {
        let loadCurrent2 = msTrans1ScottGetLoadCurrent(
            getValues('voltageSecondary'),
            getValues('partOutOut.calcPoint.loadCapacity')
        );
        setValue('partOutOut.calcPoint.loadCurrent', BeamsNumber(loadCurrent2, 7));

        doCalcVoltageDrop1(false);
        doCalcVoltageDrop(false);
    }
    
    const doCalcLoadCapacity1 = () => {
        let loadCapacity1 = msTrans1ScottGetLoadCapacity(
            Math.trunc(getValues('voltageSecondary') / 2),
            getValues('partOutIn.calcPoint.loadCurrent')
        );
        setValue('partOutIn.calcPoint.loadCapacity', BeamsNumber(loadCapacity1, 7));
    }
    
    const doCalcLoadCapacity2 = () => {
        let loadCapacity2 = msTrans1ScottGetLoadCapacity(
            getValues('voltageSecondary'),
            getValues('partOutOut.calcPoint.loadCurrent')
        );
        setValue('partOutOut.calcPoint.loadCapacity', BeamsNumber(loadCapacity2, 7));
    }

    const doCalcVoltageDrop1 = (isNeutral: boolean) => {
        let calcData = {
            isNeutral: isNeutral, // Neutral aka 1 aka Inside
            xr1: getValues('partOutIn.xr'),
            percentZ1: getValues('partOutIn.percentZ'),
            xr2: getValues('partOutOut.xr'),
            percentZ2: getValues('partOutOut.percentZ'),
            capacity: getValues('capacity'),
            voltageSecondary: getValues('voltageSecondary'),
            loadCurrent: 
                isNeutral 
                ? getValues('partOutIn.calcPoint.loadCurrent') 
                : getValues('partOutOut.calcPoint.loadCurrent'),
            powerFactor: 
                isNeutral 
                ? getValues('partOutIn.calcPoint.powerFactor') 
                : getValues('partOutOut.calcPoint.powerFactor'),
            leadLag: 
                isNeutral 
                ? getValues('partOutIn.calcPoint.isLead') 
                : getValues('partOutOut.calcPoint.isLead'),
        }

        let voltageDrop1 = msTrans1GetDropVoltDiff(calcData);
        if (isNeutral)
            setValue('partOutIn.voltageDrop1', BeamsNumber(voltageDrop1, 4));
        else
            setValue('partOutOut.voltageDrop1', BeamsNumber(voltageDrop1, 4));
    }

    const doCalcVoltageDrop = (isNeutral: boolean) => {
        let calcData = {
            isNeutral: isNeutral, // Neutral aka 1 aka Inside
            xr1: getValues('partOutIn.xr'),
            percentZ1: getValues('partOutIn.percentZ'),
            xr2: getValues('partOutOut.xr'),
            percentZ2: getValues('partOutOut.percentZ'),
            capacity: getValues('capacity'),
            voltageSecondary: getValues('voltageSecondary'),
            loadCurrent: 
                isNeutral 
                ? getValues('partOutIn.calcPoint.loadCurrent') 
                : getValues('partOutOut.calcPoint.loadCurrent'),
            powerFactor: 
                isNeutral 
                ? getValues('partOutIn.calcPoint.powerFactor') 
                : getValues('partOutOut.calcPoint.powerFactor'),
            leadLag: 
                isNeutral 
                ? getValues('partOutIn.calcPoint.isLead')
                : getValues('partOutOut.calcPoint.isLead'),
        }

        let voltageDrop = msTrans1GetDropPerUnit(calcData,1);
        if (isNeutral)
            setValue('partOutIn.voltageDrop', BeamsNumber(voltageDrop, 4));
        else
            setValue('partOutOut.voltageDrop', BeamsNumber(voltageDrop, 4));
    }

    const doCalcXr = (isNeutral: boolean) => {
        let xr = 0
        if (isNeutral){
            let percentX = Number(getValues('partOutIn.percentX'));
            let percentR = Number(getValues('partOutIn.percentR'));
            if (percentX == 0)
                xr = 0
            else if (percentR == 0)
                xr = MS_XR_MAX
            else
                xr = percentX / percentR

            setValue('partOutIn.xr', BeamsNumber(xr, 5));
        } else {
            let percentX = Number(getValues('partOutOut.percentX'));
            let percentR = Number(getValues('partOutOut.percentR'));
            if (percentX == 0)
                xr = 0
            else if (percentR == 0)
                xr = MS_XR_MAX
            else
                xr = percentX / percentR
            
            setValue('partOutOut.xr', BeamsNumber(xr, 5));
        }
    }

    const doCalcPowerNormal = (isNeutral: boolean) => {
        let voltageSecondary = getValues('voltageSecondary');
        let calcData = {
            voltageSecondary: isNeutral ? voltageSecondary / 2 : voltageSecondary,
            capacity: isNeutral ? getValues('capacity') / 2 : getValues('capacity'),
            percentR: 
                isNeutral 
                ? getValues('partOutIn.percentR') 
                : getValues('partOutOut.percentR'),
            percentX: 
                isNeutral 
                ? getValues('partOutIn.percentX') 
                : getValues('partOutOut.percentX'),
        }
        let newPower = msTransNormalCalculation(calcData);

        if (isNeutral){
            setValue('partOutIn.percentZ', BeamsNumber(newPower.percentZ, 5));
            setValue('partOutIn.ohmZ', BeamsNumber(newPower.ohmZ, 4));
            setValue('partOutIn.ohmR', BeamsNumber(newPower.ohmR, 4));
            setValue('partOutIn.ohmX', BeamsNumber(newPower.ohmX, 4));
        } else {
            setValue('partOutOut.percentZ', BeamsNumber(newPower.percentZ, 5));
            setValue('partOutOut.ohmZ', BeamsNumber(newPower.ohmZ, 4));
            setValue('partOutOut.ohmR', BeamsNumber(newPower.ohmR, 4));
            setValue('partOutOut.ohmX', BeamsNumber(newPower.ohmX, 4));
        }
    }

    const DoGetValueOhmR = ( dVoltSecondary:number,  dCapacity:number,  dPercentR:number) =>
    {
        if (dCapacity == 0 || dPercentR == 0) {
            return 0;
        }
         const dOhmR = dVoltSecondary * dVoltSecondary / dCapacity / 1000 * dPercentR * 10;
    
        return dOhmR;
    }
    const DoGetValueOhmX = ( dVoltSecondary:number,  dCapacity:number,  dPercentX:number) =>
    {
        if (dCapacity == 0 || dPercentX == 0) {
            return 0;
        }
        const dOhmX = dVoltSecondary * dVoltSecondary / dCapacity / 1000  * dPercentX * 10;

        return dOhmX;
    }
    const DoGetValueOhmZ = ( dOhmR:number,  dOhmX:number) =>
    {
	    return Math.sqrt(dOhmR * dOhmR + dOhmX * dOhmX);
    }
   const DoGetTextOhmR =(nPartKind:number) =>
    {
        let dOhmR = 0;
        switch (nPartKind) {
        case MS_OUTSIDE_NEUTRAL:		// 外線－中性線間
            dOhmR = DoGetValueOhmR(+getValues("voltageSecondary") / 2, +getValues("capacity") / 2, +getValues("partOutIn.percentR"));
            break;
        case MS_OUTSIDE_OUTSIDE:		// 外線間
            dOhmR = DoGetValueOhmR(+getValues("voltageSecondary"), +getValues("capacity"), +getValues("partOutOut.percentR"));
            break;
        }

        return BeamsNumber(dOhmR,4);
    }
    const DoGetTextOhmX = (nPartKind:number) =>
    {
        let dOhmX = 0;
        switch (nPartKind) {
        case MS_OUTSIDE_NEUTRAL:		// 外線－中性線間
            dOhmX = DoGetValueOhmX(+getValues("voltageSecondary") / 2, +getValues("capacity") / 2, +getValues("partOutIn.percentX"));
            break;
        case MS_OUTSIDE_OUTSIDE:		// 外線間
            dOhmX = DoGetValueOhmX(+getValues("voltageSecondary"), +getValues("capacity"), +getValues("partOutOut.percentX"));
            break;
        }

        return BeamsNumber(dOhmX,4);
    }
    const DoGetTextOhmZ = (nPartKind:number) =>
    {
        let dOhmR = 0;
        let dOhmX = 0;
        switch (nPartKind) {
        case MS_OUTSIDE_NEUTRAL:		// 外線－中性線間
            dOhmR = DoGetValueOhmR(+getValues("voltageSecondary") / 2, +getValues("capacity") / 2, +getValues("partOutIn.percentR"));
            dOhmX = DoGetValueOhmX(+getValues("voltageSecondary") / 2, +getValues("capacity") / 2, +getValues("partOutIn.percentX"));
            break;
        case MS_OUTSIDE_OUTSIDE:		// 外線間
            dOhmR = DoGetValueOhmR(+getValues("voltageSecondary"), +getValues("capacity"), +getValues("partOutOut.percentR"));
            dOhmX = DoGetValueOhmX(+getValues("voltageSecondary"), +getValues("capacity"), +getValues("partOutOut.percentX"));
            break;
        }
        let dOhmZ = DoGetValueOhmZ(dOhmR, dOhmX);
        return BeamsNumber(dOhmZ,4);
    }
    const doCalcPowerFromXR_Z = (isNeutral: boolean,nControl:string) => {
        // let voltageSecondary = getValues('voltageSecondary');
        if (isNeutral) {
            if(nControl !=='xr'){
                setValue('partOutIn.xr',+getValues('partOutIn.xr'))

            }
            if(nControl !=='percentR'){
                setValue('partOutIn.percentR',+getValues('partOutIn.percentR'))

            }
            if(nControl !=='percentX'){
                setValue('partOutIn.percentX',+getValues('partOutIn.percentX'))

            }
            if(nControl !=='percentZ'){
                setValue('partOutIn.percentZ',+getValues('partOutIn.percentZ'))

            }
            setValue('partOutIn.ohmZ', DoGetTextOhmZ(MS_OUTSIDE_NEUTRAL));
            setValue('partOutIn.ohmR', DoGetTextOhmR(MS_OUTSIDE_NEUTRAL));
            setValue('partOutIn.ohmX', DoGetTextOhmX(MS_OUTSIDE_NEUTRAL));
        } else {
            if(nControl !=='xr'){
                setValue('partOutOut.xr',+getValues('partOutOut.xr'))

            }
            if(nControl !=='percentR'){
                setValue('partOutOut.percentR',+getValues('partOutOut.percentR'))

            }
            if(nControl !=='percentX'){
                setValue('partOutOut.percentX',+getValues('partOutOut.percentX'))

            }
            if(nControl !=='percentZ'){
                setValue('partOutOut.percentZ',+getValues('partOutOut.percentZ'))

            }
            setValue('partOutOut.ohmZ', DoGetTextOhmZ(MS_OUTSIDE_OUTSIDE));
            setValue('partOutOut.ohmR', DoGetTextOhmR(MS_OUTSIDE_OUTSIDE));
            setValue('partOutOut.ohmX', DoGetTextOhmX(MS_OUTSIDE_OUTSIDE));
        }
        // let newPower = msTransFromXR_ZCalculation(
        //     isNeutral ? voltageSecondary / 2 : voltageSecondary,
        //     isNeutral 
        //         ? getValues('partOutIn.percentZ') 
        //         : getValues('partOutOut.percentZ'),
        //     isNeutral 
        //         ? getValues('capacity') / 2 
        //         : getValues('capacity'),
        //     isNeutral 
        //         ? getValues('partOutIn.xr') 
        //         : getValues('partOutOut.xr')
        // );

        // if (isNeutral) {
        //     if (newPower.percentR !== undefined)
        //         setValue('partOutIn.percentR', BeamsNumber(newPower.percentR, 5));
        //     if (newPower.percentX !== undefined)
        //         setValue('partOutIn.percentX', BeamsNumber(newPower.percentX, 5));
        //     setValue('partOutIn.ohmZ', BeamsNumber(newPower.ohmZ, 4));
        //     setValue('partOutIn.ohmR', BeamsNumber(newPower.ohmR, 4));
        //     setValue('partOutIn.ohmX', BeamsNumber(newPower.ohmX, 4));
        // } else {
        //     if (newPower.percentR !== undefined)
        //         setValue('partOutOut.percentR', BeamsNumber(newPower.percentR, 5));
        //     if (newPower.percentX !== undefined)
        //         setValue('partOutOut.percentX', BeamsNumber(newPower.percentX, 5));
        //     setValue('partOutOut.ohmZ', BeamsNumber(newPower.ohmZ, 4));
        //     setValue('partOutOut.ohmR', BeamsNumber(newPower.ohmR, 4));
        //     setValue('partOutOut.ohmX', BeamsNumber(newPower.ohmX, 4));
        // }
    }

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={selectTab} onChange={handleChange}
                    variant="scrollable" 
                    scrollButtons 
                    allowScrollButtonsMobile 
                    sx={{
                        '& .MuiButtonBase-root.MuiTabScrollButton-root.Mui-disabled': {
                            display: "none"
                        }
                    }}
                >
                    <Tab label='基本' {...a11yProps(0)} />
                    <Tab label='基本 - [外線‐外線]' {...a11yProps(1)} />
                    <Tab label='基本 - [外線‐中性線]' {...a11yProps(2)} />
                    <Tab label='保護協調 - [外線‐外線]' {...a11yProps(3)} />
                    <Tab label='保護協調 - [外線‐中性線]' {...a11yProps(4)} />
                    <Tab label='電圧降下 - [外線‐外線]' {...a11yProps(5)} />
                    <Tab label='電圧降下 - [外線‐中性線]' {...a11yProps(6)} />
                    <Tab label='シンボル' {...a11yProps(7)} />
                </Tabs>
            </Box>
            <form onSubmit={handleSubmit(handleEditSubmit)}>
                <div style={{ height: '40vh', width: '100%', margin: 'auto', overflow: 'auto' }}>

                    {/* tab0 - 基本 */}
                    <TabPanel value={selectTab} index={0}>
                        <Grid container mt={1}>
                            <Grid item xs={7}>
                                <Stack spacing={0.3}>
                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                        <Typography variant="body2" width="100%">デバイス名:</Typography>
                                        <Controller
                                            name='refNo'
                                            control={control}
                                            rules={{
                                                required: '必須項目です。入力してください。',
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    type='text'
                                                    size='small'
                                                    error={!!errors?.refNo}
                                                    helperText={errors?.refNo?.message}
                                                    onChange={handleChangeFormat}
                                                    inputProps={{readOnly: m_bModePM || modeViewOnly}}
                                                    InputProps={{ 
                                                        sx: { 
                                                            height: 32,
                                                            background:m_bModePM || modeViewOnly?'floralwhite':'white',
                                                            color: m_bModePM || modeViewOnly?'grey':'black',
                                                        }
                                                    }}
                                                />
                                            )}
                                        />
                                    </Stack>
                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                        <Typography variant="body2" width="100%">一次側回路電圧(V):</Typography>
                                        <FormControl fullWidth size='small'>
                                            <Controller
                                                name='voltagePrimary'
                                                control={control}
                                                rules={{ required: '必須項目です。入力してください。' ,
                                                    pattern:{
                                                        value: /^[0-9\b]+$/,
                                                        message: '数字を入力してください。'
                                                    },
                                                    validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                }}
                                                render={({ field }: any) => (
                                                    <TextFieldOptions
                                                        {...field}
                                                        options={initialValue.voltagePrimary > VOLT_SIDE_LOW_MAX ?initialValue.voltagePrimaryList:initialValue.voltageSecondaryList}
                                                        // type='number'
                                                        size='small'
                                                        setValue={(name: any, value: any) => {
                                                            handleChangeVoltagePrimary({target: {name: name, value: value}})
                                                        }}
                                                        height= {32}
                                                        maxLength={5}
                                                        disabled={m_bModePM || modeViewOnly || setIsSourceVoltage || processMode > MS_MODE_CHECKING}
                                                        error={!!errors?.voltagePrimary}
                                                        helperText={errors?.voltagePrimary?.message}
                                                    />
                                                )}
                                            />
                                        </FormControl>
                                    </Stack>
                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                        <Typography variant="body2" width="100%">二次側外線間電圧(V):</Typography>
                                        <FormControl fullWidth size='small'>
                                            <Controller
                                                name='voltageSecondary'
                                                control={control}
                                                rules={{ required: '必須項目です。入力してください。' ,
                                                    pattern:{
                                                        value: /^[0-9\b]+$/,
                                                        message: '数字を入力してください。'
                                                    },
                                                    validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                }}
                                                render={({ field }: any) => (
                                                    <TextFieldOptions
                                                        {...field}
                                                        disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}
                                                        options={initialValue.voltageSecondaryList}
                                                        // type='number'
                                                        size='small'
                                                        setValue={(name: any, value: any) => {
                                                            handleChangeVoltageSecondary({target: {name: name, value: value}})
                                                        }}
                                                        maxLength={5}
                                                        height= {32}
                                                        error={!!errors?.voltageSecondary}
                                                        helperText={errors?.voltageSecondary?.message}
                                                    />
                                                )}
                                            />
                                        </FormControl>
                                    </Stack>
                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                        <Typography variant="body2" width="100%">容量(kVA):</Typography>
                                        <FormControl fullWidth size='small'>
                                            <Controller
                                                name='capacity'
                                                control={control}
                                                rules={{ required: '必須項目です。入力してください。' ,
                                                    pattern:{
                                                        value: /^[0-9]*[.]?[0-9]+$/,
                                                        message: '数字を入力してください。'
                                                    },
                                                    validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                }}
                                                render={({ field }: any) => (
                                                    <TextFieldOptions
                                                        {...field}
                                                        disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}
                                                        options={initialValue.capacityList}
                                                        // type='number'
                                                        size='small'
                                                        setValue={(name: any, value: any) => {
                                                            field.onChange();
                                                            handleChangeCapacity({target: {name: name, value: value}});
                                                        }}
                                                        maxLength={6}
                                                        height= {32}
                                                        error={!!errors?.capacity}
                                                        helperText={errors?.capacity?.message}
                                                    />
                                                )}
                                            />
                                        </FormControl>
                                    </Stack>
                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                        <Typography variant="body2" width="100%">一次側定格電流(A):</Typography>
                                        <Controller
                                            name='ratedCurrent'
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    type='number'
                                                    size='small'
                                                    inputProps={{ readOnly: true }}
                                                    style={{ backgroundColor: 'floralwhite' }}
                                                    InputProps={{ sx: { height: 32,color:'grey' } }}
                                                />
                                            )}
                                        />
                                    </Stack>
                                </Stack>
                            </Grid>
                            <Grid item xs={5} pl={3}>
                                {/* Mode SP */}
                                {(userRole && !(userRole == Constant.ROLE_LP)) && <>
                                <Stack>
                                    <FormControlLabel
                                        control={
                                            <Controller
                                                name='viewFlowResult'
                                                control={control}
                                                render={({ field }) => (
                                                    <Checkbox
                                                        {...field}
                                                        disabled={m_bModePM || modeViewOnly}
                                                        size='small'
                                                        checked={field.value}
                                                        onChange={handleChangeCheckbox}
                                                        style={{padding:'4px 4px 4px 8px'}}
                                                    />
                                                )}
                                            />
                                        }
                                        label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>潮流解析用の値を表示</Typography>}
                                    />
                                </Stack>
                                <Stack pl={2} sx={{ flexDirection: "row", alignItems:"center" }}>
                                    <Typography variant="body2" width="75%">負荷率:</Typography>
                                    <Controller
                                        name='loadFactor'
                                        control={control}
                                        rules={{
                                            pattern:{
                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                message: '数字を入力してください。'
                                            },
                                            validate: value => value >= 0 && value <= 1 || '数字 0 ~ 1 を入力してください。' ,
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                // type='number'
                                                size='small'
                                                inputProps={{readOnly: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}}
                                                InputProps={{ 
                                                    sx: { 
                                                        height: 32,
                                                        background:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white',
                                                        color: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'grey':'black',
                                                    }
                                                }}
                                                error={!!errors?.loadFactor}
                                                helperText={errors?.loadFactor?.message}
                                            />
                                        )}
                                    />
                                </Stack>
                                <Stack mt={0.3} pl={2} sx={{ flexDirection: "row", alignItems:"center" }}>
                                    <Typography variant="body2" width="75%">力率(遅れ):</Typography>
                                    <Controller
                                        name='powerFactor'
                                        control={control}
                                        rules={{ required: '必須項目です。入力してください。',
                                            pattern:{
                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                message: '数字を入力してください。'
                                            },
                                            validate: value => value > 0 && value <= 1|| '0 < 数字 <= 1 を入力してください。' ,
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                // type='number'
                                                size='small'
                                                inputProps={{readOnly: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}}
                                                InputProps={{ 
                                                    sx: { 
                                                        height: 32,
                                                        background:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white',
                                                        color: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'grey':'black',
                                                    }
                                                }}
                                                error={!!errors?.powerFactor}
                                                helperText={errors?.powerFactor?.message}
                                            />
                                        )}
                                    />
                                </Stack>
                                </>}
                                {/* END ModeSP */}
                                <Stack>
                                    <FormControlLabel
                                        control={
                                            <Controller
                                                name='viewResultText'
                                                control={control}
                                                render={({ field }) => (
                                                    <Checkbox
                                                        {...field}
                                                        disabled={m_bModePM || modeViewOnly}
                                                        size='small'
                                                        checked={field.value}
                                                        onChange={handleChangeCheckbox}
                                                        style={{padding:'4px 4px 4px 8px'}}

                                                    />
                                                )}
                                            />
                                        }
                                        label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>注釈を表示</Typography>}
                                    />
                                </Stack>
                            </Grid>
                        </Grid>

                        {/* ModePM */}
                        {[Constant.ROLE_PM, Constant.ROLE_ADMIN].includes(userRole) &&
                        <>
                            <Stack mt={1}>
                                <FormControlLabel
                                    control={
                                        <Controller
                                            name='makeDataInput'
                                            control={control}
                                            render={({ field }) => (
                                                <Checkbox
                                                    size='small'
                                                    {...field}
                                                    disabled={m_bModePM || modeViewOnly}
                                                    checked={field.value}
                                                    onChange={handleChangeMakeDataInput}
                                                    style={{padding: '4px 4px 4px 9px'}}
                                                />
                                            )}
                                        />
                                    }
                                    label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>銘板入力</Typography>}
                                />
                            </Stack>
                            <Stack>
                                <fieldset
                                    style={{ 
                                        width: '60%',
                                        color: !makeDataInput ? 'grey' : 'black', 
                                        border:'1px solid #AAAA'
                                    }}
                                >
                                    <legend><Typography variant="body2"  >銘板</Typography></legend>
                                    <Stack spacing={0.3}>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="35%" >製造者:</Typography>
                                            <FormControl fullWidth size='small'>
                                                <Controller
                                                    name='makerName'
                                                    control={control}
                                                    render={({ field }: any) => (
                                                        <TextFieldOptions
                                                            {...field}
                                                            disabled={m_bModePM || modeViewOnly || !makeDataInput}
                                                            options={initialValue.makerNameList}
                                                            type='string'
                                                            size='small'
                                                            setValue={(name: any, value: any) => {
                                                                handleChangeFormat({target: {name: name, value: value}})
                                                            }}
                                                            height= {32}
                                                        />
                                                    )}
                                                />
                                            </FormControl>
                                        </Stack>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="35%" >形式:</Typography>
                                            <Controller
                                                name='type'
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type='text'
                                                        size='small'
                                                        onChange={handleChangeFormat}
                                                        inputProps={{readOnly: m_bModePM || modeViewOnly || !makeDataInput}}
                                                        InputProps={{ 
                                                            sx: { 
                                                                height: 32,
                                                                background:m_bModePM || modeViewOnly || !makeDataInput?'floralwhite':'white',
                                                                color: m_bModePM || modeViewOnly || !makeDataInput?'grey':'black',
                                                            }
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="35%"  >製造年月:</Typography>
                                            <Grid container>
                                                <Grid item xs={7}>
                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                        <FormControl fullWidth size='small'>
                                                            <Controller
                                                                name='makeYear'
                                                                control={control}
                                                                render={({ field }: any) => (
                                                                    <TextFieldOptions
                                                                        {...field}
                                                                        disabled={m_bModePM || modeViewOnly || !makeDataInput}
                                                                        options={initialValue.makeYearList}
                                                                        type='string'
                                                                        size='small'
                                                                        setValue={(name: any, value: any) => {
                                                                            handleChangeFormat({target: {name: name, value: value}})
                                                                        }}
                                                                        maxLength={8}
                                                                        height= {32}
                                                                    />
                                                                )}
                                                            />
                                                        </FormControl>
                                                        <Typography variant="body2" pr={1.5} pl={0.5}>年</Typography>
                                                    </Stack>
                                                    
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                        <FormControl fullWidth size='small'>
                                                            <Controller
                                                                name='makeMonth'
                                                                control={control}
                                                                render={({ field }: any) => (
                                                                    <TextFieldOptions
                                                                        {...field}
                                                                        disabled={m_bModePM || modeViewOnly || !makeDataInput}
                                                                        options={initialValue.makeMonthList}
                                                                        type='string'
                                                                        size='small'
                                                                        setValue={(name: any, value: any) => {
                                                                            handleChangeFormat({target: {name: name, value: value}})
                                                                        }}
                                                                        maxLength={2}
                                                                        height= {32}
                                                                    />
                                                                )}
                                                            />
                                                        </FormControl>
                                                        <Typography variant="body2" pl={0.5}>月</Typography>
                                                    </Stack>
                                                </Grid>
                                            </Grid>
                                            
                                            
                                        </Stack>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="35%"  >製造番号:</Typography>
                                            <Controller
                                                name='makeNumber'
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type='text'
                                                        size='small'
                                                        onChange={handleChangeFormat}
                                                        inputProps={{readOnly: m_bModePM || modeViewOnly || !makeDataInput}}
                                                        InputProps={{ 
                                                            sx: { 
                                                                height: 32,
                                                                background:m_bModePM || modeViewOnly || !makeDataInput?'floralwhite':'white',
                                                                color: m_bModePM || modeViewOnly || !makeDataInput?'grey':'black',
                                                            }
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                    </Stack>
                                </fieldset>
                            </Stack>
                        </>
                        }
                        {/* End ModePM */}
                    </TabPanel>

                    {/* tab of each parts: [外線‐外線] & [外線‐中性線] */}
                    
                            {/* tab1-2 - 基本 */}
                            <TabPanel value={selectTab} index={1}>
                                <Grid container my={1}>
                                    <Grid item xs={7}>
                                        <Stack spacing={0.3}>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="75%">事故点名称:</Typography>
                                                <Controller
                                                    name={"partOutOut.pointText"}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type='text'
                                                            size='small'
                                                            inputProps={{readOnly: m_bModePM || modeViewOnly}}
                                                            InputProps={{ 
                                                                sx: { 
                                                                    height: 32,
                                                                    background:m_bModePM || modeViewOnly?'floralwhite':'white',
                                                                    color: m_bModePM || modeViewOnly?'grey':'black',
                                                                }
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="75%">電圧(V):</Typography>
                                                <Controller
                                                    name={"voltageSecondary"}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type='number'
                                                            size='small'
                                                            inputProps={{ readOnly: true }}
                                                            style={{ backgroundColor: 'floralwhite' }}
                                                            InputProps={{ sx: { height: 32 ,color:'grey'} }}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="75%">容量(kVA):</Typography>
                                                <Controller
                                                    name={"capacity"}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type='number'
                                                            size='small'
                                                            inputProps={{ readOnly: true }}
                                                            style={{ backgroundColor: 'floralwhite' }}
                                                            InputProps={{ sx: { height: 32  ,color:'grey'} }}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="75%">二次側定格電流(A):</Typography>
                                                <Controller
                                                    name='ratedCurrent1'
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type='number'
                                                            size='small'
                                                            inputProps={{ readOnly: true }}
                                                            style={{ backgroundColor: 'floralwhite' }}
                                                            InputProps={{ sx: { height: 32 ,color:'grey' } }}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={5} pl={3}>
                                        <Stack>
                                            <FormControlLabel
                                                control={
                                                    <Controller
                                                        name={"partOutOut.fault"}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <Checkbox
                                                                {...field}
                                                                disabled={m_bModePM || modeViewOnly}
                                                                size='small'
                                                                checked={field.value}
                                                                onChange={handleChangeCheckbox}
                                                                style={checkBoxStyles}
                                                            />
                                                        )}
                                                    />
                                                }
                                                label= {<Typography variant='body2' color={m_bModePM?'grey':'black'}>短絡電流を計算</Typography>}
                                            />
                                        </Stack>
                                    </Grid>
                                </Grid>
                                <fieldset style={{border:'1px solid #AAAA'}}>
                                    <legend><Typography variant="body2">インピーダンス(機器容量ベース)</Typography></legend>
                                    <Stack spacing={0.3}>
                                        <Grid container>
                                            <Grid item xs={6}>
                                                <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                    <Typography variant="body2" width="100%">X/R:</Typography>
                                                    <Controller
                                                        name={"partOutOut.xr"}
                                                        control={control}
                                                        rules={{
                                                            pattern:{
                                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                                message: '数字を入力してください。'
                                                            },
                                                            validate: value => value >= 0 || '数字 >= 0 を入力してください。' ,
                                                        }}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                // type="number"
                                                                size="small"
                                                                onChange={(e) =>{field.onChange(e);handleChangeXR_Z(e)}}
                                                                sx={{ width: 200 }}
                                                                inputProps={{maxlength:5,readOnly: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}}
                                                                InputProps={{ 
                                                                    sx: { 
                                                                        height: 32,
                                                                        background:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white',
                                                                        color: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'grey':'black',
                                                                    }
                                                                }}
                                                            error={!!errors?.partOutOut?.xr}
                                                            helperText={errors?.partOutOut?.xr?.message}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid item xs={6}>
                                                <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                    <Typography variant="body2" width='100%'>%インピーダンス(%):</Typography>
                                                    <Controller
                                                        name={"partOutOut.percentZ"}
                                                        control={control}
                                                        rules={{ 
                                                            required: '必須項目です。入力してください。',
                                                            pattern:{
                                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                                message: '数字を入力してください。'
                                                            },
                                                            validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                        }}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                // type="text"
                                                                size="small"
                                                                onChange={(e) => {field.onChange(e);handleChangeXR_Z(e)}}
                                                                sx={{ width: 200 }}
                                                                inputProps={{maxlength:5,readOnly: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}}
                                                                InputProps={{ 
                                                                    sx: { 
                                                                        height: 32,
                                                                        background:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white',
                                                                        color: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'grey':'black',
                                                                    }
                                                                }}
                                                                error={!!errors?.partOutOut?.percentZ}
                                                                helperText={errors?.partOutOut?.percentZ?.message}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={0.5} style={{ marginTop: 'auto', marginBottom: 'auto', textAlign: 'center' }}>
                                                <span>=</span>
                                            </Grid>
                                            <Grid item xs={2.5}>
                                                <Stack>
                                                    <Controller
                                                        name={"partOutOut.percentR"}
                                                        control={control}
                                                        rules={{
                                                            pattern:{
                                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                                message: '数字を入力してください。'
                                                            },
                                                            validate: value => value >= 0 || '数字 >= 0 を入力してください。' ,
                                                        }}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                fullWidth
                                                                // type="text"
                                                                size="small"
                                                                onChange={(e) =>{field.onChange(e);handleChangeR_X(e)}}
                                                                inputProps={{maxlength:5,readOnly: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}}
                                                                InputProps={{ 
                                                                    sx: { 
                                                                        height: 32,
                                                                        background:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white',
                                                                        color: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'grey':'black',
                                                                    }
                                                                }}
                                                                error={!!errors?.partOutOut?.percentR}
                                                                helperText={errors?.partOutOut?.percentR?.message}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={0.5} style={{ marginTop: 'auto', marginBottom: 'auto', textAlign: 'center' }}>
                                                <span>+ j</span>
                                            </Grid>
                                            <Grid item xs={2.5}>
                                                <Stack>
                                                    <Controller
                                                        name={"partOutOut.percentX"}
                                                        control={control}
                                                        rules={{
                                                            pattern:{
                                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                                message: '数字を入力してください。'
                                                            },
                                                            validate: value => value >= 0 || '数字 >= 0 を入力してください。' ,
                                                        }}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                fullWidth
                                                                // type="text"
                                                                size="small"
                                                                onChange={(e) =>{field.onChange(e);handleChangeR_X(e)}}
                                                                inputProps={{maxlength:5,readOnly: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}}
                                                                InputProps={{ 
                                                                    sx: { 
                                                                        height: 32,
                                                                        background:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white',
                                                                        color: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'grey':'black',
                                                                    }
                                                                }}
                                                                error={!!errors?.partOutOut?.percentX}
                                                                helperText={errors?.partOutOut?.percentX?.message}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid item xs={6}>
                                                <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                    <Typography variant="body2" width='100%'>インピーダンス(mΩ):</Typography>
                                                    <Controller
                                                        name={"partOutOut.ohmZ"}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                type="text"
                                                                size="small"
                                                                inputProps={{ readOnly: true }}
                                                                style={{ backgroundColor: 'floralwhite' }}
                                                                sx={{ width: 200 }}
                                                                InputProps={{sx: { height: 32 ,color:'grey'}}}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={0.5} style={{ marginTop: 'auto', marginBottom: 'auto', textAlign: 'center' }}>
                                                <span>=</span>
                                            </Grid>
                                            <Grid item xs={2.5}>
                                                <Stack>
                                                    <Controller
                                                        name={"partOutOut.ohmR"}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                fullWidth
                                                                type="text"
                                                                size="small"
                                                                inputProps={{ readOnly: true }}
                                                                style={{ backgroundColor: 'floralwhite' }}
                                                                InputProps={{sx: { height: 32,color:'grey' }}}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={0.5} style={{ marginTop: 'auto', marginBottom: 'auto', textAlign: 'center' }}>
                                                <span>+ j</span>
                                            </Grid>
                                            <Grid item xs={2.5}>
                                                <Stack>
                                                    <Controller
                                                        name={"partOutOut.ohmX"}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                fullWidth
                                                                type="text"
                                                                size="small"
                                                                inputProps={{ readOnly: true }}
                                                                style={{ backgroundColor: 'floralwhite' }}
                                                                InputProps={{sx: { height: 32 ,color:'grey'}}}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </Stack>
                                </fieldset>
                            </TabPanel>
                            {/* partOutIn */}
                            <TabPanel value={selectTab} index={2}>
                                <Grid container my={1}>
                                    <Grid item xs={7}>
                                        <Stack spacing={0.3}>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="75%">事故点名称:</Typography>
                                                <Controller
                                                    name={"partOutIn.pointText"}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type='text'
                                                            size='small'
                                                            inputProps={{readOnly: m_bModePM || modeViewOnly}}
                                                            InputProps={{ 
                                                                sx: { 
                                                                    height: 32,
                                                                    background:m_bModePM || modeViewOnly?'floralwhite':'white',
                                                                    color: m_bModePM || modeViewOnly?'grey':'black',
                                                                }
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="75%">電圧(V):</Typography>
                                                <Controller
                                                    name={"voltage1"}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type='number'
                                                            size='small'
                                                            inputProps={{ readOnly: true }}
                                                            style={{ backgroundColor: 'floralwhite' }}
                                                            InputProps={{ sx: { height: 32 ,color:'grey'} }}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="75%">容量(kVA):</Typography>
                                                <Controller
                                                    name={"capacity1"}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type='number'
                                                            size='small'
                                                            inputProps={{ readOnly: true }}
                                                            style={{ backgroundColor: 'floralwhite' }}
                                                            InputProps={{ sx: { height: 32  ,color:'grey'} }}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="75%">二次側定格電流(A):</Typography>
                                                <Controller
                                                    name='ratedCurrent1'
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type='number'
                                                            size='small'
                                                            inputProps={{ readOnly: true }}
                                                            style={{ backgroundColor: 'floralwhite' }}
                                                            InputProps={{ sx: { height: 32  ,color:'grey'} }}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={5} pl={3}>
                                        <Stack>
                                            <FormControlLabel
                                                control={
                                                    <Controller
                                                        name={"partOutIn.fault"}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <Checkbox
                                                                {...field}
                                                                disabled={m_bModePM || modeViewOnly}
                                                                size='small'
                                                                checked={field.value}
                                                                onChange={handleChangeCheckbox}
                                                                style={checkBoxStyles}
                                                            />
                                                        )}
                                                    />
                                                }
                                                label={<Typography variant='body2' color={m_bModePM?'grey':'black'}>短絡電流を計算</Typography>}
                                            />
                                        </Stack>
                                    </Grid>
                                </Grid>
                                <fieldset style={{border:'1px solid #AAAA'}}>
                                    <legend><Typography variant="body2">インピーダンス(機器容量ベース)</Typography></legend>
                                    <Stack spacing={0.3}>
                                        <Grid container>
                                            <Grid item xs={6}>
                                                <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                    <Typography variant="body2" width="100%">X/R:</Typography>
                                                    <Controller
                                                        name={"partOutIn.xr"}
                                                        control={control}
                                                        rules={{
                                                            pattern:{
                                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                                message: '数字を入力してください。'
                                                            },
                                                            validate: value => value >= 0 || '数字 >= 0 を入力してください。' ,
                                                        }}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                // type="number"
                                                                size="small"
                                                                onChange={(e) =>{field.onChange(e);handleChangeXR_Z(e)}}
                                                                sx={{ width: 200 }}
                                                                inputProps={{maxlength:5,readOnly: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}}
                                                                InputProps={{ 
                                                                    sx: { 
                                                                        height: 32,
                                                                        background:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white',
                                                                        color: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'grey':'black',
                                                                    }
                                                                }}
                                                            error={!!errors?.partOutIn?.xr}
                                                            helperText={errors?.partOutIn?.xr?.message}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid item xs={6}>
                                                <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                    <Typography variant="body2" width='100%'>%インピーダンス(%):</Typography>
                                                    <Controller
                                                        name={"partOutIn.percentZ"}
                                                        control={control}
                                                        rules={{ 
                                                            required: '必須項目です。入力してください。',
                                                            pattern:{
                                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                                message: '数字を入力してください。'
                                                            },
                                                            validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                        }}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                // type="text"
                                                                size="small"
                                                                onChange={(e) =>{field.onChange(e);handleChangeXR_Z(e)}}
                                                                sx={{ width: 200 }}
                                                                inputProps={{maxlength:5,readOnly: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}}
                                                                InputProps={{ 
                                                                    sx: { 
                                                                        height: 32,
                                                                        background:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white',
                                                                        color: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'grey':'black',
                                                                    }
                                                                }}
                                                            error={!!errors?.partOutIn?.percentZ}
                                                            helperText={errors?.partOutIn?.percentZ?.message}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={0.5} style={{ marginTop: 'auto', marginBottom: 'auto', textAlign: 'center' }}>
                                                <span>=</span>
                                            </Grid>
                                            <Grid item xs={2.5}>
                                                <Stack>
                                                    <Controller
                                                        name={"partOutIn.percentR"}
                                                        control={control}
                                                        rules={{
                                                            pattern:{
                                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                                message: '数字を入力してください。'
                                                            },
                                                            validate: value => value >= 0 || '数字 >= 0 を入力してください。' ,
                                                        }}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                fullWidth
                                                                // type="text"
                                                                size="small"
                                                                onChange={(e) =>{field.onChange(e);handleChangeR_X(e)}}
                                                                inputProps={{maxlength:5,readOnly: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}}
                                                                InputProps={{ 
                                                                    sx: { 
                                                                        height: 32,
                                                                        background:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white',
                                                                        color: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'grey':'black',
                                                                    }
                                                                }}
                                                                error={!!errors?.partOutIn?.percentR}
                                                                helperText={errors?.partOutIn?.percentR?.message}   
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={0.5} style={{ marginTop: 'auto', marginBottom: 'auto', textAlign: 'center' }}>
                                                <span>+ j</span>
                                            </Grid>
                                            <Grid item xs={2.5}>
                                                <Stack>
                                                    <Controller
                                                        name={"partOutIn.percentX"}
                                                        control={control}
                                                        rules={{
                                                            pattern:{
                                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                                message: '数字を入力してください。'
                                                            },
                                                            validate: value => value >= 0 || '数字 >= 0 を入力してください。' ,
                                                        }}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                fullWidth
                                                                type="text"
                                                                size="small"
                                                                onChange={(e) =>{field.onChange(e);handleChangeR_X(e)}}
                                                                inputProps={{maxlength:5,readOnly: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}}
                                                                InputProps={{ 
                                                                    sx: { 
                                                                        height: 32,
                                                                        background:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white',
                                                                        color: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'grey':'black',
                                                                    }
                                                                }}
                                                                error={!!errors?.partOutIn?.percentX}
                                                                helperText={errors?.partOutIn?.percentX?.message}   
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid item xs={6}>
                                                <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                    <Typography variant="body2" width='100%'>インピーダンス(mΩ):</Typography>
                                                    <Controller
                                                        name={"partOutIn.ohmZ"}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                type="text"
                                                                size="small"
                                                                inputProps={{ readOnly: true }}
                                                                style={{ backgroundColor: 'floralwhite' }}
                                                                sx={{ width: 200 }}
                                                                InputProps={{sx: { height: 32,color:'grey' }}}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={0.5} style={{ marginTop: 'auto', marginBottom: 'auto', textAlign: 'center' }}>
                                                <span>=</span>
                                            </Grid>
                                            <Grid item xs={2.5}>
                                                <Stack>
                                                    <Controller
                                                        name={"partOutIn.ohmR"}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                fullWidth
                                                                type="text"
                                                                size="small"
                                                                inputProps={{ readOnly: true }}
                                                                style={{ backgroundColor: 'floralwhite' }}
                                                                InputProps={{sx: { height: 32 ,color:'grey'}}}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={0.5} style={{ marginTop: 'auto', marginBottom: 'auto', textAlign: 'center' }}>
                                                <span>+ j</span>
                                            </Grid>
                                            <Grid item xs={2.5}>
                                                <Stack>
                                                    <Controller
                                                        name={"partOutIn.ohmX"}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                fullWidth
                                                                type="text"
                                                                size="small"
                                                                inputProps={{ readOnly: true }}
                                                                style={{ backgroundColor: 'floralwhite' }}
                                                                InputProps={{sx: { height: 32,color:'grey' }}}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </Stack>
                                </fieldset>
                            </TabPanel>

                            {/* tab3-4 - 保護協調 */}
                            <TabPanel value={selectTab} index={3}>
                                <Stack mt={1} width={'75%'}>
                                    <fieldset style={{border:"1px solid #AAAA"}}>
                                        <legend><Typography variant="body2">励磁突入電流曲線</Typography></legend>
                                        <Stack spacing={0.3}>
                                            <Stack>
                                                <FormControlLabel
                                                    control={
                                                        <Controller
                                                            name={"partOutOut.dispInrush"}
                                                            control={control}
                                                            render={({ field }) => (
                                                                <Checkbox
                                                                    {...field}
                                                                    disabled={m_bModePM || modeViewOnly}
                                                                    size='small'
                                                                    checked={field.value}
                                                                    onChange={handleChangeCheckbox}
                                                                    style={checkBoxStyles}
                                                                />
                                                            )}
                                                        />
                                                    }
                                                    label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>グラフに表示する</Typography>}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="75%">定格電流の最大倍率(倍):</Typography>
                                                <Controller
                                                    name={"partOutOut.inrushTimes"}
                                                    control={control}
                                                    rules={{ required: '必須項目です。入力してください。',
                                                        pattern:{
                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                            message: '数字を入力してください。'
                                                        },
                                                        validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            // type='number'
                                                            size='small'
                                                            onChange={(e) =>{field.onChange(e);handleChangeInrushTimes(e)}}
                                                            inputProps={{maxlength:4,readOnly: m_bModePM || modeViewOnly}}
                                                            InputProps={{ 
                                                                sx: { 
                                                                    height: 32,
                                                                    background:m_bModePM || modeViewOnly?'floralwhite':'white',
                                                                    color: m_bModePM || modeViewOnly?'grey':'black',
                                                                }
                                                            }}
                                                            error={!!errors?.partOutOut?.inrushTimes}
                                                            helperText={errors?.partOutOut?.inrushTimes?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="75%">最大電流(Arms):</Typography>
                                                <Controller
                                                    name={"partOutOut.inrushValue"}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type='number'
                                                            size='small'
                                                            inputProps={{ readOnly: true }}
                                                            style={{ backgroundColor: 'floralwhite' }}
                                                            InputProps={{ sx: { height: 32 ,color:'grey'} }}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="75%">減衰時定数(ms):</Typography>
                                                <Controller
                                                    name={"partOutOut.dampingTime"}
                                                    control={control}
                                                    rules={{ required: '必須項目です。入力してください。',
                                                        pattern:{
                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                            message: '数字を入力してください。'
                                                        },
                                                        validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            // type='number'
                                                            size='small'
                                                            onChange={(e) =>{field.onChange(e);handleChangeFormat(e)}}
                                                            inputProps={{maxlength:5,readOnly: m_bModePM || modeViewOnly}}
                                                            InputProps={{ 
                                                                sx: { 
                                                                    height: 32,
                                                                    background:m_bModePM || modeViewOnly?'floralwhite':'white',
                                                                    color: m_bModePM || modeViewOnly?'grey':'black',
                                                                }
                                                            }}
                                                            error={!!errors?.partOutOut?.dampingTime}
                                                            helperText={errors?.partOutOut?.dampingTime?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                        </Stack>
                                    </fieldset>
                                </Stack>
                                <Stack mt={2} width={'75%'}>
                                    <fieldset style={{border:"1px solid #AAAA"}}>
                                        <legend ><Typography variant="body2">熱的機械的強度ポイント</Typography></legend>
                                        <Stack spacing={0.3}>
                                            <Stack>
                                                <FormControlLabel
                                                    control={
                                                        <Controller
                                                            name={"partOutOut.dispIntensity"}
                                                            control={control}
                                                            
                                                            render={({ field }) => (
                                                                <Checkbox
                                                                    {...field}
                                                                    disabled={m_bModePM || modeViewOnly}
                                                                    size='small'
                                                                    checked={field.value}
                                                                    onChange={handleChangeCheckbox}
                                                                    style={checkBoxStyles}
                                                                />
                                                            )}
                                                        />
                                                    }
                                                    label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>グラフに表示する</Typography>}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="75%">定格電流の最大倍率(倍):</Typography>
                                                <Controller
                                                    name={"partOutOut.intensityTimes"}
                                                    control={control}
                                                    rules={{ required: '必須項目です。入力してください。',
                                                        pattern:{
                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                            message: '数字を入力してください。'
                                                        },
                                                        validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            // type='number'
                                                            size='small'
                                                            onChange={(e) =>{field.onChange(e);handleChangeIntensityTimes(e)}}
                                                            inputProps={{maxlength:4,readOnly: m_bModePM || modeViewOnly}}
                                                            InputProps={{ 
                                                                sx: { 
                                                                    height: 32,
                                                                    background:m_bModePM || modeViewOnly?'floralwhite':'white',
                                                                    color: m_bModePM || modeViewOnly?'grey':'black',
                                                                }
                                                            }}
                                                            error={!!errors?.partOutOut?.intensityTimes}
                                                            helperText={errors?.partOutOut?.intensityTimes?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="75%">最大電流(Arms):</Typography>
                                                <Controller
                                                    name={"partOutOut.intensityValue"}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type='number'
                                                            size='small'
                                                            inputProps={{ readOnly: true }}
                                                            style={{ backgroundColor: 'floralwhite' }}
                                                            InputProps={{ sx: { height: 32 ,color:'grey'} }}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="75%">時間(s):</Typography>
                                                <Controller
                                                    name={"partOutOut.intensityTime"}
                                                    control={control}
                                                    rules={{ required: '必須項目です。入力してください。',
                                                        pattern:{
                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                            message: '数字を入力してください。'
                                                        },
                                                        validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            // type='number'
                                                            size='small'
                                                            onChange={(e) =>{field.onChange(e);handleChangeFormat(e)}}
                                                            inputProps={{maxlength:4,readOnly: m_bModePM || modeViewOnly}}
                                                            InputProps={{ 
                                                                sx: { 
                                                                    height: 32,
                                                                    background:m_bModePM || modeViewOnly?'floralwhite':'white',
                                                                    color: m_bModePM || modeViewOnly?'grey':'black',
                                                                }
                                                            }}
                                                            error={!!errors?.partOutOut?.intensityTime}
                                                            helperText={errors?.partOutOut?.intensityTime?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                        </Stack>
                                    </fieldset>
                                </Stack>
                            </TabPanel>

                            {/* partOutIn */}
                            <TabPanel value={selectTab} index={4}>
                                <Stack mt={1} width={'75%'}>
                                <fieldset style={{border:"1px solid #AAAA"}}>
                                        <legend><Typography variant="body2">励磁突入電流曲線</Typography></legend>
                                        <Stack spacing={0.3}>
                                            <Stack>
                                                <FormControlLabel
                                                    control={
                                                        <Controller
                                                            name={"partOutIn.dispInrush"}
                                                            control={control}
                                                            render={({ field }) => (
                                                                <Checkbox
                                                                    {...field}
                                                                    disabled={m_bModePM || modeViewOnly}
                                                                    size='small'
                                                                    checked={field.value}
                                                                    onChange={handleChangeCheckbox}
                                                                    style={checkBoxStyles}
                                                                />
                                                            )}
                                                        />
                                                    }
                                                    label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>グラフに表示する</Typography>}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="75%">定格電流の最大倍率(倍):</Typography>
                                                <Controller
                                                    name={"partOutIn.inrushTimes"}
                                                    control={control}
                                                    rules={{ required: '必須項目です。入力してください。',
                                                        pattern:{
                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                            message: '数字を入力してください。'
                                                        },
                                                        validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            // type='number'
                                                            size='small'
                                                            onChange={(e) =>{field.onChange(e);handleChangeInrushTimes(e)}}
                                                            inputProps={{maxlength:4,readOnly: m_bModePM || modeViewOnly}}
                                                            InputProps={{ 
                                                                sx: { 
                                                                    height: 32,
                                                                    background:m_bModePM || modeViewOnly?'floralwhite':'white',
                                                                    color: m_bModePM || modeViewOnly?'grey':'black',
                                                                }
                                                            }}
                                                            error={!!errors?.partOutIn?.inrushTimes}
                                                            helperText={errors?.partOutIn?.inrushTimes?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="75%">最大電流(Arms):</Typography>
                                                <Controller
                                                    name={"partOutIn.inrushValue"}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type='number'
                                                            size='small'
                                                            inputProps={{ readOnly: true }}
                                                            style={{ backgroundColor: 'floralwhite' }}
                                                            InputProps={{ sx: { height: 32 ,color:'grey'} }}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="75%">減衰時定数(ms):</Typography>
                                                <Controller
                                                    name={"partOutIn.dampingTime"}
                                                    control={control}
                                                    rules={{ required: '必須項目です。入力してください。',
                                                        pattern:{
                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                            message: '数字を入力してください。'
                                                        },
                                                        validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            // type='number'
                                                            size='small'
                                                            onChange={(e) =>{field.onChange(e);handleChangeFormat(e)}}
                                                            inputProps={{maxlength:5,readOnly: m_bModePM || modeViewOnly}}
                                                            InputProps={{ 
                                                                sx: { 
                                                                    height: 32,
                                                                    background:m_bModePM || modeViewOnly?'floralwhite':'white',
                                                                    color: m_bModePM || modeViewOnly?'grey':'black',
                                                                }
                                                            }}
                                                            error={!!errors?.partOutIn?.dampingTime}
                                                            helperText={errors?.partOutIn?.dampingTime?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                        </Stack>
                                    </fieldset>
                                </Stack>
                                <Stack mt={2} width={'75%'}>
                                    <fieldset style={{border:"1px solid #AAAA"}}>
                                        <legend ><Typography variant="body2">熱的機械的強度ポイント</Typography></legend>
                                        <Stack spacing={0.3}>
                                            <Stack>
                                                <FormControlLabel
                                                    control={
                                                        <Controller
                                                            name={"partOutIn.dispIntensity"}
                                                            control={control}
                                                            render={({ field }) => (
                                                                <Checkbox
                                                                    {...field}
                                                                    disabled={m_bModePM || modeViewOnly}
                                                                    size='small'
                                                                    checked={field.value}
                                                                    onChange={handleChangeCheckbox}
                                                                    style={checkBoxStyles}
                                                                />
                                                            )}
                                                        />
                                                    }
                                                    label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>グラフに表示する</Typography>}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="75%">定格電流の最大倍率(倍):</Typography>
                                                <Controller
                                                    name={"partOutIn.intensityTimes"}
                                                    control={control}
                                                    rules={{ required: '必須項目です。入力してください。',
                                                        pattern:{
                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                            message: '数字を入力してください。'
                                                        },
                                                        validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            // type='number'
                                                            size='small'
                                                            onChange={(e) =>{field.onChange(e);handleChangeIntensityTimes(e)}}
                                                            inputProps={{maxlength:4,readOnly: m_bModePM || modeViewOnly}}
                                                            InputProps={{ 
                                                                sx: { 
                                                                    height: 32,
                                                                    background:m_bModePM || modeViewOnly?'floralwhite':'white',
                                                                    color: m_bModePM || modeViewOnly?'grey':'black',
                                                                }
                                                            }}
                                                            error={!!errors?.partOutIn?.intensityTimes}
                                                            helperText={errors?.partOutIn?.intensityTimes?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="75%">最大電流(Arms):</Typography>
                                                <Controller
                                                    name={"partOutIn.intensityValue"}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type='number'
                                                            size='small'
                                                            inputProps={{ readOnly: true }}
                                                            style={{ backgroundColor: 'floralwhite' }}
                                                            InputProps={{ sx: { height: 32,color:'grey' } }}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="75%">時間(s):</Typography>
                                                <Controller
                                                    name={"partOutIn.intensityTime"}
                                                    control={control}
                                                    rules={{ required: '必須項目です。入力してください。',
                                                        pattern:{
                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                            message: '数字を入力してください。'
                                                        },
                                                        validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            // type='number'
                                                            size='small'
                                                            onChange={(e) =>{field.onChange(e);handleChangeFormat(e)}}
                                                            inputProps={{maxlength:4,readOnly: m_bModePM || modeViewOnly}}
                                                            InputProps={{ 
                                                                sx: { 
                                                                    height: 32,
                                                                    background:m_bModePM || modeViewOnly?'floralwhite':'white',
                                                                    color: m_bModePM || modeViewOnly?'grey':'black',
                                                                }
                                                            }}
                                                            error={!!errors?.partOutIn?.intensityTime}
                                                            helperText={errors?.partOutIn?.intensityTime?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                        </Stack>
                                    </fieldset>
                                </Stack>
                            </TabPanel>

                            {/* tab5-6 - 電圧降下 */}
                            <TabPanel value={selectTab} index={5}>
                                <Grid container>
                                    <Grid item xs={10}>
                                        <Controller
                                                name="partOutOut.dropRegular"
                                                control={control}
                                                render={({ field }: any) => (
                                                    <FormControlLabel
                                                        {...field}
                                                        disabled={m_bModePM || modeViewOnly}
                                                        checked={field.value}
                                                        label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>定常時の電圧降下を計算</Typography> }
                                                        name='partOutOut.dropRegular'
                                                        onChange={hanDleChangedropRegular}
                                                        control={<Checkbox style={checkBoxStyles} />}
                                                    />
                                                )}
                                            />
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={7.5}>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                <Typography variant="body2" width="90%" ml={5}>負荷容量 (VA):</Typography>  
                                                    <Controller
                                                        name="partOutOut.calcPoint.loadCapacity"
                                                        control={control}
                                                        rules={{
                                                            pattern:{
                                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                                message: '数字を入力してください。'
                                                            },
                                                            validate: value => value >= 0 || '数字 >= 0 を入力してください。' ,
                                                        }}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                fullWidth
                                                                // type='number'
                                                                size='small'
                                                                onChange={(e) =>{field.onChange(e);handleChangeLoadCapacity(e)}}
                                                                inputProps={{readOnly: m_bModePM || modeViewOnly || !dropRegular1}}
                                                                InputProps={{ 
                                                                    sx: { 
                                                                        height: 32,
                                                                        background:m_bModePM || modeViewOnly || !dropRegular1?'floralwhite':'white',
                                                                        color: m_bModePM || modeViewOnly || !dropRegular1?'grey':'black',
                                                                    }
                                                                }}
                                                                error={!!errors?.partOutOut?.calcPoint?.loadCapacity}
                                                                helperText={errors?.partOutOut?.calcPoint?.loadCapacity?.message}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                        </Grid>
                                        <Grid item xs ={7.5}>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                <Typography variant="body2" width="90%" ml={5}>負荷電流 IL(A):</Typography>  
                                                <Controller
                                                    name="partOutOut.calcPoint.loadCurrent"
                                                    control={control}
                                                    rules={{
                                                        pattern:{
                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                            message: '数字を入力してください。'
                                                        },
                                                        validate: value => value >= 0 || '数字 >= 0 を入力してください。' ,
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            // type='number'
                                                            size='small'
                                                            onChange={(e) =>{field.onChange(e);handleChangeLoadCurrent(e)}}
                                                            inputProps={{maxlength:7,readOnly: m_bModePM || modeViewOnly || !dropRegular1}}
                                                            InputProps={{ 
                                                                sx: { 
                                                                    height: 32,
                                                                    background:m_bModePM || modeViewOnly || !dropRegular1?'floralwhite':'white',
                                                                    color: m_bModePM || modeViewOnly || !dropRegular1?'grey':'black',
                                                                }
                                                            }}
                                                            error={!!errors?.partOutOut?.calcPoint?.loadCurrent}
                                                            helperText={errors?.partOutOut?.calcPoint?.loadCurrent?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                    <Grid container ml={6.5}>
                                        <fieldset style={{border:"1px solid #AAAA",width:"53%"}}>
                                            <legend><Typography variant="body2" color={'grey'}>負荷電流方向</Typography>  </legend>
                                            <Controller
                                                    name="partOutOut.currentTopLeft"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <RadioGroup {...field}>
                                                                <FormControlLabel 
                                                                    disabled
                                                                    value='0'
                                                                    control={<Radio size='small' style={radioStyles1} />}
                                                                    label={<Typography variant="body2" style={{color:"grey"}}>上→下 or 左→右</Typography>}
                                                                />
                                                                <FormControlLabel
                                                                    disabled
                                                                    value='1'
                                                                    control={<Radio size='small' style={radioStyles1} />}
                                                                    label={<Typography variant="body2" style={{color:"grey"}}>下→上 or 右→左</Typography>}
                                                                />
                                                            </RadioGroup>
                                                    )}
                                                />
                                            </fieldset>
                                            <Stack ml={1.2}>
                                                <Typography color="common.white">nothing</Typography>
                                                <Typography color="common.white">nothing</Typography>
                                                <Button
                                                    variant="outlined"
                                                    onClick={() => resetCurrentTopLeft(0)}
                                                    disabled={m_bModePM || modeViewOnly || !dropRegular1}
                                                >概算値</Button>
                                            </Stack>
                                    </Grid>
                                    <Grid container ml={3}>
                                        <fieldset style={{width:"76%",border:"1px solid #AAAA"}}>
                                            <legend>{<Typography variant="body2">回路の力率</Typography>}</legend>
                                            <Grid container>
                                                <Grid item xs={5}>
                                                    <Controller
                                                        name="partOutOut.calcPoint.isLead"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <RadioGroup {...field} row onChange={handleChangeCalcVoltDrop}>
                                                                <FormControlLabel 
                                                                    value={true}
                                                                    control={<Radio style={radioStyles1} />}
                                                                    label={<Typography style ={{color:m_bModePM || !dropRegular1?"grey":"black"}} variant="body2">進み</Typography>}
                                                                    disabled={m_bModePM || modeViewOnly || !dropRegular1}
                                                                />
                                                                <FormControlLabel 
                                                                    value={false}
                                                                    control={<Radio style={radioStyles1} />}
                                                                    label={<Typography style ={{color:m_bModePM || !dropRegular1?"grey":"black"}} variant="body2">遅れ</Typography>}
                                                                    disabled={m_bModePM || modeViewOnly || !dropRegular1}
                                                                />
                                                            </RadioGroup>
                                                        
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid xs={1}/>
                                                <Grid xs={2.75} ml={1.4}>
                                                    <Controller
                                                        name="partOutOut.calcPoint.powerFactor"
                                                        control={control}
                                                        rules={{
                                                            pattern:{
                                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                                message: '数字を入力してください。'
                                                            },
                                                            validate: value => value >= 0 && value <= 1 || '数字 0 ~ 1 を入力してください。' ,
                                                        }}
                                                        render={({ field }) => (
                                                            <TextField
                                                            {...field}
                                                            fullWidth
                                                            // type='number'
                                                            size='small'
                                                            onChange={(e) =>{field.onChange(e);handleChangeCalcVoltDrop(e)}}
                                                            inputProps={{maxlength:7,readOnly: m_bModePM || modeViewOnly || !dropRegular1}}
                                                            InputProps={{ 
                                                                sx: { 
                                                                    height: 32,
                                                                    background:m_bModePM || modeViewOnly || !dropRegular1?'floralwhite':'white',
                                                                    color: m_bModePM || modeViewOnly || !dropRegular1?'grey':'black',
                                                                }
                                                            }}
                                                            error={!!errors?.partOutOut?.calcPoint?.powerFactor}
                                                            helperText={errors?.partOutOut?.calcPoint?.powerFactor?.message}
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid item xs={2.5} ml={1.64}>
                                                    <Button
                                                        style={{margin:"0"}}
                                                        variant="outlined"
                                                        onClick={() => resetLeadLag(0)}
                                                        disabled={m_bModePM || modeViewOnly || !dropRegular1}
                                                    >概算値</Button>
                                                </Grid>
                                            </Grid>
                                        </fieldset>
                                    </Grid>
                                    <Grid container  mt={0.25}>
                                        <Grid container flexDirection={"row"} ml={6} alignItems={"center"}>
                                            <Grid item xs={4} >
                                                <Typography variant="body2" width="100%" >単体での電圧降下(V):</Typography>  
                                            </Grid>
                                            <Grid item xs={4} ml={-2.6}>
                                                <Controller
                                                    name="partOutOut.voltageDrop1"
                                                    control={control}
                                                    
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type='number'
                                                            size='small'
                                                            inputProps={{ readOnly: true }}
                                                            style={{ backgroundColor: 'floralwhite',width:"70%",marginLeft:'20%' }}
                                                            InputProps={{ sx: { height: 32,color:'grey' } }}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container flexDirection={"row"} ml={6} mt={0.25} alignItems={"center"}>
                                            <Grid item xs={4} >
                                                <Typography variant="body2" width="100%" >単体での電圧降下率(%):</Typography>  
                                            </Grid>
                                            <Grid item xs={4} ml={-2.6}>
                                                <Controller
                                                    name="partOutOut.voltageDrop"
                                                    control={control}
                                                    
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type='number'
                                                            size='small'
                                                            inputProps={{ readOnly: true }}
                                                            style={{ backgroundColor: 'floralwhite',width:"70%",marginLeft:'20%' }}
                                                            InputProps={{ sx: { height: 32 ,color:'grey'} }}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </TabPanel>

                            {/* partOutIn */}
                            <TabPanel value={selectTab} index={6}>
                                <Grid container>
                                    <Grid item xs={10}>
                                        <Controller
                                                name="partOutIn.dropRegular"
                                                control={control}
                                                render={({ field }: any) => (
                                                    <FormControlLabel
                                                        {...field}
                                                        checked={field.value}
                                                        disabled={m_bModePM || modeViewOnly}
                                                        label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>定常時の電圧降下を計算</Typography> }
                                                        name='partOutIn.dropRegular'
                                                        onChange={hanDleChangedropRegular}
                                                        control={<Checkbox style={checkBoxStyles}/>}
                                                    />
                                                )}
                                            />
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={7.5}>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                <Typography variant="body2" width="90%" ml={5}>負荷容量 (VA):</Typography>  
                                                    <Controller
                                                        name="partOutIn.calcPoint.loadCapacity"
                                                        control={control}
                                                        rules={{
                                                            pattern:{
                                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                                message: '数字を入力してください。'
                                                            },
                                                            validate: value => value >= 0  || '数字 >= 0 を入力してください。' ,
                                                        }}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                fullWidth
                                                                // type='number'
                                                                size='small'
                                                                onChange={(e) =>{field.onChange(e);handleChangeLoadCapacity(e)}}
                                                                inputProps={{readOnly: m_bModePM || modeViewOnly || !dropRegular2}}
                                                                InputProps={{ 
                                                                    sx: { 
                                                                        height: 32,
                                                                        background:m_bModePM || modeViewOnly || !dropRegular2?'floralwhite':'white',
                                                                        color: m_bModePM || modeViewOnly || !dropRegular2?'grey':'black',
                                                                    }
                                                                }}
                                                                error={!!errors?.partOutIn?.calcPoint?.loadCapacity}
                                                                helperText={errors?.partOutIn?.calcPoint?.loadCapacity?.message}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                        </Grid>
                                        <Grid item xs ={7.5}>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                <Typography variant="body2" width="90%" ml={5}>負荷電流 IL(A):</Typography>  
                                                <Controller
                                                    name="partOutIn.calcPoint.loadCurrent"
                                                    control={control}
                                                    rules={{
                                                        pattern:{
                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                            message: '数字を入力してください。'
                                                        },
                                                        validate: value => value >= 0  || '数字 >= 0 を入力してください。' ,
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            // type='number'
                                                            size='small'
                                                            onChange={(e) =>{field.onChange(e);handleChangeLoadCurrent(e)}}
                                                            inputProps={{maxlength:7,readOnly: m_bModePM || modeViewOnly || !dropRegular2}}
                                                            InputProps={{ 
                                                                sx: { 
                                                                    height: 32,
                                                                    background:m_bModePM || modeViewOnly || !dropRegular2?'floralwhite':'white',
                                                                    color: m_bModePM || modeViewOnly || !dropRegular2?'grey':'black',
                                                                }
                                                            }}
                                                            error={!!errors?.partOutIn?.calcPoint?.loadCurrent}
                                                            helperText={errors?.partOutIn?.calcPoint?.loadCurrent?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                    <Grid container ml={6.5}>
                                        <fieldset style={{border:"1px solid #AAAA",width:"53%"}}>
                                            <legend><Typography variant="body2" color={'grey'}>負荷電流方向</Typography>  </legend>
                                            <Controller
                                                    name="partOutIn.currentTopLeft"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <RadioGroup {...field}>
                                                                <FormControlLabel 
                                                                    disabled
                                                                    value='0'
                                                                    control={<Radio size='small' style={radioStyles1} />}
                                                                    label={<Typography variant="body2" style={{color:"grey"}}>上→下 or 左→右</Typography>}
                                                                />
                                                                <FormControlLabel
                                                                    disabled
                                                                    value='1'
                                                                    control={<Radio size='small' style={radioStyles1} />}
                                                                    label={<Typography variant="body2" style={{color:"grey"}}>下→上 or 右→左</Typography>}
                                                                />
                                                            </RadioGroup>
                                                    )}
                                                />
                                            </fieldset>
                                            <Stack ml={1.2}>
                                                <Typography color="common.white">nothing</Typography>
                                                <Typography color="common.white">nothing</Typography>
                                                <Button
                                                    variant="outlined"
                                                    disabled={m_bModePM || modeViewOnly || !dropRegular2}
                                                    onClick={() => resetCurrentTopLeft(1)}
                                                >概算値</Button>
                                            </Stack>
                                    </Grid>
                                    <Grid container ml={3}>
                                        <fieldset style={{width:"76%",border:"1px solid #AAAA"}}>
                                            <legend>{<Typography variant="body2">回路の力率</Typography>}</legend>
                                            <Grid container>
                                                <Grid item xs={5}>
                                                    <Controller
                                                        name="partOutIn.calcPoint.isLead"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <RadioGroup {...field} row onChange={handleChangeCalcVoltDrop}>
                                                                <FormControlLabel 
                                                                    value={true}
                                                                    control={<Radio style={radioStyles1} />}
                                                                    label={<Typography style={{color:m_bModePM || !dropRegular2?"grey":"black"}} variant="body2">進み</Typography>}
                                                                    disabled={m_bModePM || modeViewOnly || !dropRegular2}
                                                                />
                                                                <FormControlLabel 
                                                                    value={false}
                                                                    control={<Radio style={radioStyles1} />}
                                                                    label={<Typography variant="body2" style={{color:m_bModePM || !dropRegular2?"grey":"black"}}>遅れ</Typography>}
                                                                    disabled={m_bModePM || modeViewOnly || !dropRegular2}
                                                                />
                                                            </RadioGroup>
                                                        
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid xs={1}/>
                                                <Grid xs={2.75} ml={1.4}>
                                                    <Controller
                                                        name="partOutIn.calcPoint.powerFactor"
                                                        control={control}
                                                        rules={{
                                                            pattern:{
                                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                                message: '数字を入力してください。'
                                                            },
                                                            validate: value => value >= 0 && value <=1 || '数字 0 ~ 1 を入力してください。' ,
                                                        }}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                fullWidth
                                                                // type='number'
                                                                size='small'
                                                                onChange={(e) =>{field.onChange(e);handleChangeCalcVoltDrop(e)}}
                                                                inputProps={{maxlength:7,readOnly: m_bModePM || modeViewOnly || !dropRegular2}}
                                                                InputProps={{ 
                                                                    sx: { 
                                                                        height: 32,
                                                                        background:m_bModePM || modeViewOnly || !dropRegular2?'floralwhite':'white',
                                                                        color: m_bModePM || modeViewOnly || !dropRegular2?'grey':'black',
                                                                    }
                                                                }}
                                                                error={!!errors?.partOutIn?.calcPoint?.powerFactor}
                                                            helperText={errors?.partOutIn?.calcPoint?.powerFactor?.message}
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid item xs={2.5} ml={1.64}>
                                                    <Button
                                                        style={{margin:"0"}}
                                                        variant="outlined"
                                                        disabled={m_bModePM || modeViewOnly || !dropRegular2}
                                                        onClick={() => resetLeadLag(1)}
                                                    >概算値</Button>
                                                </Grid>
                                            </Grid>
                                        </fieldset>
                                    </Grid>
                                    <Grid container  mt={0.25}>
                                        <Grid container flexDirection={"row"} ml={6} alignItems={"center"}>
                                            <Grid item xs={4} >
                                                <Typography variant="body2" width="100%" >単体での電圧降下(V):</Typography>  
                                            </Grid>
                                            <Grid item xs={4} ml={-2.6}>
                                                <Controller
                                                    name="partOutIn.voltageDrop1"
                                                    control={control}
                                                    
                                                    render={({ field }) => (
                                                        <TextField
                                                        {...field}
                                                            fullWidth
                                                            type='number'
                                                            size='small'
                                                            inputProps={{ readOnly: true }}
                                                            style={{ backgroundColor: 'floralwhite' ,width:"70%",marginLeft:'20%' }}
                                                            InputProps={{ sx: { height: 32 ,color:'grey'} }}
                                                        
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container flexDirection={"row"} ml={6} mt={0.25} alignItems={"center"}>
                                            <Grid item xs={4} >
                                                <Typography variant="body2" width="100%" >単体での電圧降下率(%):</Typography>  
                                            </Grid>
                                            <Grid item xs={4} ml={-2.6}>
                                                <Controller
                                                    name="partOutIn.voltageDrop"
                                                    control={control}
                                                    
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type='number'
                                                            size='small'
                                                            inputProps={{ readOnly: true }}
                                                            style={{ backgroundColor: 'floralwhite' ,width:"70%",marginLeft:'20%' }}
                                                            InputProps={{ sx: { height: 32 ,color:'grey'} }}

                                                        />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </TabPanel>

                    {/* tab7 - シンボル */}
                    <TabPanel value={selectTab} index={7}>
                    <Stack mt={1} width={'75%'}>
                            <fieldset style={{border:'1px solid #AAAA'}}>
                                <legend><Typography variant="body2">単線結線図で表示するシンボルの選択</Typography></legend>
                                <Grid container>
                                    <Grid item xs={5}>
                                        <Controller
                                            name='symKind'
                                            control={control}
                                            render={({ field }) => (
                                                <RadioGroup {...field}>
                                                    <FormControlLabel 
                                                        value='0' 
                                                        disabled={m_bModePM || modeViewOnly}
                                                        control={<Radio size='small'/>} 
                                                        label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>無し</Typography>}
                                                    />
                                                    <FormControlLabel 
                                                        value='1' 
                                                        disabled={m_bModePM || modeViewOnly}
                                                        control={<Radio size='small'/>} 
                                                        label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>一／三</Typography>}
                                                    />
                                                </RadioGroup>
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </fieldset>
                        </Stack>
                    </TabPanel>
                </div>
                <Stack direction='row' justifyContent='flex-end' spacing={2} mt={2}>
                    <Button variant='contained' type='submit' disabled={modeViewOnly}>OK</Button>
                    <Button color='inherit' variant='contained' onClick={handleCancel}>キャンセル</Button>
                </Stack>
            </form >
        </>
    );
};
export default MsTrans1EditDialog;