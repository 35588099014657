import { ControlModel } from "../../models/Index"
import * as EKind from "../../models/ElementKind";
import { MS_MODE_DRAWING, MS_MODE_IMPROVE_PF } from "../../statics";
import { IDS_STS_NO_DATA } from "../../models/Constants";
import { BeamsNumber } from "../../utils/FormatNumber";

const listDisplayBelow = [
  EKind.MS_TRANSCENTER,
  EKind.MS_MOTOR_GROUP,
  EKind.MS_MOTOR,
  EKind.MS_LOAD,
  EKind.MS_LIGHTBOARD,
  EKind.MS_POWERBOARD,
  EKind.MS_CAPACITOR,
]
export const controlCanRotate = [
  EKind.MS_DS,
  EKind.MS_LBS,
  EKind.MS_MVCB,
  EKind.MS_CONTACTOR,
  EKind.MS_HVCB,
  EKind.MS_FUSE,
  EKind.MS_LVCB,
  EKind.MS_BUSBAR,
  EKind.MS_BUSDUCT,
  EKind.MS_WIRE,
  EKind.MS_REACTOR,
  EKind.MS_IMPEDANCE,

]
const controlHaveFlowResultTop = [
  EKind.MS_TRANS3,
  EKind.MS_3WINDING,
  EKind.MS_DS,
  EKind.MS_LBS,
  EKind.MS_MVCB,
  EKind.MS_CONTACTOR,
  EKind.MS_HVCB,
  EKind.MS_RELAY51,
  EKind.MS_FUSE,
  EKind.MS_2E,
  EKind.MS_WIRE,
  EKind.MS_BUSBAR,
  EKind.MS_IMPEDANCE,
  EKind.MS_BUSDUCT,
  EKind.MS_REACTOR,
  EKind.MS_LVCB,
  EKind.MS_THERMAL,
  EKind.MS_THERMAL_CT,
  EKind.MS_MOTOR_GROUP,
  EKind.MS_MOTOR,
  EKind.MS_LOAD,
  EKind.MS_CAPACITOR,
  EKind.MS_VCT,
  EKind.MS_INV,
  EKind.MS_RELAY,
  EKind.MS_VT,
  EKind.MS_ZCT,
  EKind.MS_LIGHTBOARD,
  EKind.MS_POWERBOARD,
]
export const addInfoDisplayLocation = (diagramData: ControlModel[], opeMode: number = 1) => {
  return diagramData.map((pObjElement: ControlModel) => {
    // Konva: 1 cell = 20, file dxf: 1 cell = 40 
    let newObjElement: any = {
      ...pObjElement,
      x: pObjElement.x * 2,
      y: pObjElement.y * 2,
      width: pObjElement.rotation == 0 ? pObjElement.width * 2 : pObjElement.height * 2,
      height: pObjElement.rotation == 0 ? pObjElement.height * 2 : pObjElement.width * 2,
      offsetX: pObjElement.offsetX * 2,
      offsetY: pObjElement.offsetY * 2,
    }
    
    if (listDisplayBelow.includes(newObjElement.type)){
      newObjElement["m_listRefV1"] = [{
        m_LogFont: {lfHeight: 21},
        m_rectLeftLP: {
          left: (newObjElement.x + newObjElement.width) + 3,
          top: (newObjElement.y + newObjElement.height) - 39
        },
        m_rectRightLP: {
          left: (newObjElement.x + newObjElement.width) + 3,
          top: (newObjElement.y + newObjElement.height) - 19
        },
      }]
      newObjElement["m_RefV1Point"] = {
        m_LogFont: {lfHeight: 21},
        m_rectLeftLP: {
          left: newObjElement.x + 1,
          top: newObjElement.y + 1
        },
        m_rectRightLP: {
          left: (newObjElement.x + newObjElement.width) + 1,
          top: newObjElement.y + 4
        },
      }
    } else if (newObjElement.type == EKind.MS_3WINDING) {
      if (opeMode >= MS_MODE_IMPROVE_PF){
        newObjElement["m_listRefV1"] = [{
          m_LogFont: {lfHeight: 21},
          m_rectLeftLP: {
            left: (newObjElement.x + newObjElement.width) + 6,
            top: newObjElement.y + newObjElement.height/2 - 20
          },
          m_rectRightLP: {
            left: (newObjElement.x + newObjElement.width) + 6,
            top: newObjElement.y + newObjElement.height/2
          },
        }]
      } else {
        newObjElement["m_listRefV1"] = [{
          m_LogFont: {lfHeight: 21},
          m_rectLeftLP: {
            left: (newObjElement.x + newObjElement.width) + 6,
            top: newObjElement.y + newObjElement.height/2 - 110
          },
          m_rectRightLP: {
            left: (newObjElement.x + newObjElement.width) + 6,
            top: newObjElement.y + newObjElement.height/2 - 90
          },
        }]
      }

      newObjElement["m_RefV1Left"] = {
        m_LogFont: {lfHeight: 21},
        m_rectLeftLP: {
          left: newObjElement.x + 1,
          top: newObjElement.y + 1
        },
        m_rectRightLP: {
          left: newObjElement.x - 85,
          top: newObjElement.y + newObjElement.height/2 - 89
        },
      }
      
      newObjElement.properties.partSecondary["m_RefV1Point"] = {
        m_LogFont: {lfHeight: 21},
        m_rectLeftLP: {
          left: newObjElement.x + 1,
          top: newObjElement.y + 1
        },
        m_rectRightLP: {
          left: (newObjElement.x + newObjElement.width) + 6,
          top: (newObjElement.y + newObjElement.height) - 60
        },
      }
      newObjElement.properties.partThird["m_RefV1Point"] = {
        m_LogFont: {lfHeight: 21},
        m_rectLeftLP: {
          left: newObjElement.x + 1,
          top: newObjElement.y + 1
        },
        m_rectRightLP: {
          left: newObjElement.x - 37,
          top: (newObjElement.y + newObjElement.height) - 60
        },
      }

      newObjElement.properties.partPrimary["m_RefV1FlowResult"] = {
        m_LogFont: {lfHeight: 21},
        m_rectRightLP: {
          left: (newObjElement.x + newObjElement.width) + 3,
          right: (newObjElement.x + newObjElement.width) + 99,
          top: newObjElement.y + newObjElement.height/2 - 114,
          bottom: newObjElement.y + newObjElement.height/2 - 22,
        },
      }
      newObjElement.properties.partSecondary["m_RefV1FlowResult"] = {
        m_LogFont: {lfHeight: 21},
        m_rectRightLP: {
          left: (newObjElement.x + newObjElement.width) + 3,
          right: (newObjElement.x + newObjElement.width) + 99,
          top: newObjElement.y + newObjElement.height/2,
          bottom: newObjElement.y + newObjElement.height/2 + 92,
        },
      }
      newObjElement.properties.partThird["m_RefV1FlowResult"] = {
        m_LogFont: {lfHeight: 21},
        m_rectRightLP: {
          left: newObjElement.x - 98,
          right: newObjElement.x - 2,
          top: newObjElement.y + newObjElement.height/2,
          bottom: newObjElement.y + newObjElement.height/2 + 92,
        },
      }

      newObjElement.properties.partPrimary["m_RefV1FlowResultTop"] = {
        m_LogFont: {lfHeight: 21},
        m_rectRightLP: {
          left: (newObjElement.x + newObjElement.width) + 10,
          right: (newObjElement.x + newObjElement.width) + 106,
          top: newObjElement.y + 3,
          bottom: newObjElement.y + 95,
        },
        m_rectLeftLP: {
          left: newObjElement.x + 1,
          top: newObjElement.y + 1
        }
      }
      newObjElement.properties.partSecondary["m_RefV1FlowResultTop"] = {
        m_LogFont: {lfHeight: 21},
        m_rectRightLP: {
          left: (newObjElement.x + newObjElement.width) + 10,
          right: (newObjElement.x + newObjElement.width) + 106,
          top: newObjElement.y + newObjElement.height - 132,
          bottom: newObjElement.y + newObjElement.height - 40,
        },
        m_rectLeftLP: {
          left: newObjElement.x + 1,
          top: newObjElement.y + 1
        }
      }
      newObjElement.properties.partThird["m_RefV1FlowResultTop"] = {
        m_LogFont: {lfHeight: 21},
        m_rectRightLP: {
          left: newObjElement.x - 108,
          right: newObjElement.x - 12,
          top: newObjElement.y + newObjElement.height - 132,
          bottom: newObjElement.y + newObjElement.height - 40,
        },
        m_rectLeftLP: {
          left: newObjElement.x + 1,
          top: newObjElement.y + 1
        }
      }
      
    } else if ([EKind.MS_TRANS1, EKind.MS_TRANSSCOTT].includes(newObjElement.type)) {
      if (opeMode < MS_MODE_IMPROVE_PF){
        newObjElement["m_listRefV1"] = [{
          m_LogFont: {lfHeight: 21},
          m_rectLeftLP: {
            left: (newObjElement.x + newObjElement.width) + 1,
            top: newObjElement.y + 1
          },
          m_rectRightLP: {
            left: (newObjElement.x + newObjElement.width) + 3,
            top: newObjElement.y + 21
          },
        }]
      } else {
        newObjElement["m_listRefV1"] = [{
          m_LogFont: {lfHeight: 21},
          m_rectLeftLP: {
            left: (newObjElement.x + newObjElement.width) + 1,
            top: newObjElement.y + newObjElement.height/2 - 20
          },
          m_rectRightLP: {
            left: (newObjElement.x + newObjElement.width) + 3,
            top: newObjElement.y + newObjElement.height/2
          },
        }]
      }
      let partName = newObjElement.type == EKind.MS_TRANS1 ? "partOutOut": "partSeatM";
      newObjElement.properties[partName]["m_RefV1Point"] = {
        m_LogFont: {lfHeight: 21},
        m_rectLeftLP: {
          left: newObjElement.x + 1,
          top: newObjElement.y + 1
        },
        m_rectRightLP: {
          left: (newObjElement.x + newObjElement.width) + 3,
          top: (newObjElement.y + newObjElement.height) - 23
        },
      }
    } else if (controlCanRotate.includes(newObjElement.type)) {
      if (newObjElement.rotation != 0){
        newObjElement["m_listRefV1"] = [{
          m_LogFont: {lfHeight: 21},
          m_rectLeftLP: {
            left: newObjElement.x + newObjElement.width/2 - 11,
            top: newObjElement.y - 21
          },
          m_rectRightLP: {
            left: newObjElement.x + newObjElement.width/2 + 10,
            top: newObjElement.y - 1
          },
        }]
      } else {
        newObjElement["m_listRefV1"] = [{
          m_LogFont: {lfHeight: 21},
          m_rectLeftLP: {
            left: (newObjElement.x + newObjElement.width) + 1,
            top: newObjElement.y + 1
          },
          m_rectRightLP: {
            left: (newObjElement.x + newObjElement.width) + 3,
            top: newObjElement.y + 21
          },
        }]
      }
      newObjElement["m_RefV1Point"] = {
        m_LogFont: {lfHeight: 21},
        m_rectLeftLP: {
          left: newObjElement.x + 1,
          top: newObjElement.y + 1
        },
        m_rectRightLP: {
          left: (newObjElement.x + newObjElement.width) + 3,
          top: (newObjElement.y + newObjElement.height) - 23
        },
      }
    } else {
      newObjElement["m_listRefV1"] = [{
        m_LogFont: {lfHeight: 21},
        m_rectLeftLP: {
          left: (newObjElement.x + newObjElement.width) + 1,
          top: newObjElement.y + 1
        },
        m_rectRightLP: {
          left: (newObjElement.x + newObjElement.width) + 3,
          top: newObjElement.y + 21
        },
      }]
      newObjElement["m_RefV1Point"] = {
        m_LogFont: {lfHeight: 21},
        m_rectLeftLP: {
          left: newObjElement.x + 1,
          top: newObjElement.y + 1
        },
        m_rectRightLP: {
          left: (newObjElement.x + newObjElement.width) + 3,
          top: (newObjElement.y + newObjElement.height) - 23
        },
      }
    }
    if (newObjElement.type == EKind.MS_TEXT){
      newObjElement["m_LogFont"] = {lfHeight: 21}
    }
    
    newObjElement["m_RefV1FlowResult"] = {
      m_LogFont: {lfHeight: 21},
      m_rectRightLP: {
        left: newObjElement.x - 98,
        right: newObjElement.x - 2,
        top: newObjElement.y + newObjElement.height/2 - 46,
        bottom: newObjElement.y + newObjElement.height/2 + 46,
      },
    }
    newObjElement["m_RefV1FlowResultBottom"] = {
      m_LogFont: {lfHeight: 21},
      m_rectRightLP: {
        left: newObjElement.x + newObjElement.width + 23,
        right: newObjElement.x + newObjElement.width + 129,
        top: newObjElement.y + newObjElement.height - 43,
        bottom: newObjElement.y + newObjElement.height - 3,
      },
      m_rectLeftLP: {
        left: newObjElement.x + 1,
        top: newObjElement.y + 1
      }
    }
    newObjElement["m_RefV1FlowResultTop"] = {
      m_LogFont: {lfHeight: 21},
      m_rectRightLP: {
        left: newObjElement.x + newObjElement.width + 23,
        right: newObjElement.x + newObjElement.width + 129,
        top: newObjElement.y + 4,
        bottom: newObjElement.y + 44,
      },
      m_rectLeftLP: {
        left: newObjElement.x + 1,
        top: newObjElement.y + 1
      }
    }

    return newObjElement
  })
}

export const getRectBaseLP = (control: ControlModel) => {
  return {
    top: control.y + 1,
    bottom: control.y + control.height,
    left: control.x + 1,
    right: control.x + control.width,
  }
}

export const getCenterPoint = (rect: any) => {
  return {
    x: Math.floor((rect.left + rect.right) / 2),
    y: Math.floor((rect.top + rect.bottom) / 2)
  }
}

export const getDeflateRect = (rect: any, x: number, y: number) => {
  return {
    left: rect.left + x,
    top: rect.top + y,
    right: rect.right - x,
    bottom: rect.bottom - y
  }
}

export const getRectTotal = (l: number, t: number, r: number, b: number) => {
  return {
    left: l,
    top: t,
    right: r,
    bottom: b
  }
}

export const getSizeRectTotal = (rectTotal: any) => {
  return {
    cx: rectTotal.right - rectTotal.left,
    cy: rectTotal.bottom - rectTotal.top
  }
}

export const DLLGetTextByBeams = (dValue: number, bUseNodata: boolean = false) => {
  let sValue = "";

  if (dValue < 0 && bUseNodata == true) {
    sValue = IDS_STS_NO_DATA;
  }
  else {
    sValue = BeamsNumber(dValue, 0).toString();
  }
  return sValue;
}
