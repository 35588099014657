import { store } from "..";
import { ControlModel, MsContactorModel, ProcessMode } from "../models/Index";
import { BeamsNumber } from "./FormatNumber";
import { MS_MODE_CALCULATION, MS_MODE_CHECKING, MS_MODE_DRAWING, MS_MODE_HARMONIC, MS_MODE_PERCENT_Z, MS_MODE_POWER_FLOW, MS_MODE_VOLTDROP} from "../statics/index";
import { doGetValuePerZk3 } from "./PercentZ";
import { MS_OUTSIDE_NEUTRAL, 
    MS_OUTSIDE_OUTSIDE, 
    MS_3PHASES, 
    MS_VOLTDROP_REGULAR, 
    MS_TRANS1, 
    MS_VOLTDROP_MOTOR, 
    TOP_CENTER, 
    BOTTOM_CENTER, 
    LEFT_CENTER, 
    RIGHT_CENTER,
    MS_SEAT_MAIN,
    MS_SEAT_T,
    MS_TRANSSCOTT,
    DIRECT_NONE
} from "../models/Constants"
import { doGetValueDropVoltRegular as doGetValueDropVoltRegularCalcPoint,
	doGetIkpeakDirectMG,
	doGetIkasymDirectMG,
	doGetIksymDirectMG,
    doGetIksymTotalMG,
    doGetTextDropVoltMotor as doGetTextDropVoltMotorCalPoint,
    doGetTextDropVoltMotor2 as DoGetTextDropVoltMotor2CalcPoint,
    doGetTextDropPerMotor as doGetTextDropPerMotorCalcPoint,
	doGetTextDropVoltRegular as doGetTextDropVoltRegularCalcPoint,
	doGetTextDropPerRegular as doGetTextDropPerRegularCalcPoint,
	doGetTextDropPerRegular2 as doGetTextDropPerRegular2CalcPoint,
    isSetDropPerMotor
} from "./calcPointFunction";
import { isCennectLoadDropElement } from "./element"
import { doGetTextVoltDropRegular } from "./mssv3Doc"

//CEleContactor::DoGetTextOnOff
export const doGetTextOnOff = (props:MsContactorModel, bUseOn:boolean = true) =>
{
	let sText = '';
	if (props.isOff) {
		sText = '開放';
	}
	else {
		if (bUseOn) {
			sText = '投入';
		}
		else {
			sText = '';
		}
	}
	return sText;
}

//CEleContactor::DoGetTextDirectIkpeak
export const doGetTextDirectIkpeak = (props:MsContactorModel, nPartKind:number, bUnit:boolean = true) =>
{
	let dIpeakDirect = 0;
	if (props.sPhase) {		
		switch (nPartKind) {
		case MS_OUTSIDE_NEUTRAL:	
		case MS_SEAT_MAIN:			
			dIpeakDirect = doGetIkpeakDirectMG(props.calcPoint1);
			break;
		case MS_OUTSIDE_OUTSIDE:	
		case MS_SEAT_T:				
			dIpeakDirect = doGetIkpeakDirectMG(props.calcPoint0);
			break;
		}
	}
	else {							
		if (props.downTransKind == MS_TRANS1) {
			dIpeakDirect = doGetIkpeakDirectMG(props.calcPoint1);
		}
		else {
			dIpeakDirect = doGetIkpeakDirectMG(props.calcPoint0);
		}
	}

	let sText;
	if (dIpeakDirect == 0) {
		if (props.primarySupply) {
			sText = '∞';
		}
		else {
			sText = '0';
		}
	}
	else if (dIpeakDirect > 0) {
		sText = BeamsNumber(dIpeakDirect / 1000, 4).toString();
        if(bUnit)
        {
            sText += 'kApeak';
        }
	}
	else {							
		sText = '????';
	}
	return sText;
}

//CEleContactor::DoGetTextDirectIkasym
export const doGetTextDirectIkasym = (props:MsContactorModel, nPartKind:number, bUnit:boolean = true) =>
{
	let dIasymDirect = 0;
	if (props.sPhase) {		
		switch (nPartKind) {
		case MS_OUTSIDE_NEUTRAL:	
		case MS_SEAT_MAIN:			
			dIasymDirect = doGetIkasymDirectMG(props.calcPoint1);
			break;
		case MS_OUTSIDE_OUTSIDE:	
		case MS_SEAT_T:				
			dIasymDirect = doGetIkasymDirectMG(props.calcPoint0);
			break;
		}
	}
	else {							
		if (props.downTransKind == MS_TRANS1) {
			dIasymDirect = doGetIkasymDirectMG(props.calcPoint1);
		}
		else {
			dIasymDirect = doGetIkasymDirectMG(props.calcPoint0);
		}
	}

	let sText;
	if (dIasymDirect == 0) {
		if (props.primarySupply) {
			sText = '∞';
       }
       else {
        sText = '0';
       }
	}
    else if(dIasymDirect > 0) {
        sText = BeamsNumber(dIasymDirect / 1000, 4).toString();
        if(bUnit) {
            sText += 'kAasym';
        }
    }
	else {							
		sText = '????';
	}
	return sText;
}

//CEleContactor::DoGetTextDirectIksym
export const doGetTextDirectIksym = (props:MsContactorModel, nPartKind:number, bUnit:boolean  = true) =>
{
	let dIsymDirect = 0;
	if (props.sPhase) {		
		switch (nPartKind) {
		case MS_OUTSIDE_NEUTRAL:	
		case MS_SEAT_MAIN:			
			dIsymDirect = doGetIksymDirectMG(props.calcPoint1);
			break;
		case MS_OUTSIDE_OUTSIDE:	
		case MS_SEAT_T:				
			dIsymDirect = doGetIksymDirectMG(props.calcPoint0);
			break;
		}
	}
	else {							
		if (props.downTransKind == MS_TRANS1) {
			dIsymDirect = doGetIksymDirectMG(props.calcPoint1);
		}
		else {
			dIsymDirect = doGetIksymDirectMG(props.calcPoint0);
		}
	}

	let sText;
	if (dIsymDirect == 0) {
		if(props.primarySupply) {
            sText = '∞';
        }
        else {
         sText = '0';
        }
    }
    else if(dIsymDirect > 0) {
        sText = BeamsNumber(dIsymDirect / 1000, 4).toString();
        if(bUnit) {
            sText += 'kAsym';
        }
    }
	else {							
        sText = '????';
	}
	return sText;
}

//CEleContactor::DoGetTextTotalIk3sym
export const doGetTextTotalIk3sym = (props:MsContactorModel, bUnit:boolean = true) =>
{
	let dIsymTotal = 0;
	if (props.downTransKind == MS_TRANS1) {
		dIsymTotal = doGetIksymTotalMG(props.calcPoint1);
	}
	else {
		dIsymTotal = doGetIksymTotalMG(props.calcPoint0);
	}
	let sText;
	if (dIsymTotal == 0) {
		if (props.primarySupply) {
			sText = '∞';
        }
        else {
         sText = '0';
        }
    }
    else if(dIsymTotal > 0) {
        sText = BeamsNumber(dIsymTotal / 1000, 4).toString();
        if(bUnit) {
            sText += 'kAsym';
        }
    }
    else {
        sText = '????';
    }
	return sText;
}

//CEleContactor::DoGetTextDropVoltMotor
export const doGetTextDropVoltMotor = (props:MsContactorModel, bUnit:boolean = true) =>
{
    return doGetTextDropVoltMotorCalPoint(props.calcPoint0, bUnit);
}

//CEleContactor::DoGetTextDropVoltMotor2
export const doGetTextDropVoltMotor2 = (props:MsContactorModel, bUnit:boolean = true) =>
{
	return DoGetTextDropVoltMotor2CalcPoint(props.calcPoint0, bUnit);
}

//CEleContactor::DoGetTextDropPerMotor
export const doGetTextDropPerMotor = (props:MsContactorModel, bUnit:boolean = true) =>
{
	return doGetTextDropPerMotorCalcPoint(props.calcPoint0, bUnit);
}

//CEleContactor::DoGetTextDropVoltRegular
export const doGetTextDropVoltRegular = (props:MsContactorModel, dStdVoltage:number, nPartKind:number, bUnit:boolean = true) =>
{
	let sText = '';
	if (props.sPhase) {		
		switch (nPartKind) {
		case MS_OUTSIDE_NEUTRAL:	
		case MS_SEAT_MAIN:		
			sText = doGetTextDropVoltRegularCalcPoint(props.calcPoint1, dStdVoltage, 0, 0, bUnit);
			break;
		case MS_OUTSIDE_OUTSIDE:
		case MS_SEAT_T:				
			sText = doGetTextDropVoltRegularCalcPoint(props.calcPoint0, dStdVoltage, 0, 0, bUnit);
			break;
		}
	}
	else {						
		sText = doGetTextDropVoltRegularCalcPoint(props.calcPoint0, dStdVoltage, 0, 0, bUnit);
	}
	return sText;
}

//CEleContactor::DoGetTextDropPerRegular
export const doGetTextDropPerRegular = (props:MsContactorModel, dStdVoltage:number, nPartKind:number, bUnit:boolean = true) =>
{
	let sText = '';
	if (props.sPhase) {		
		switch (nPartKind) {
		case MS_OUTSIDE_NEUTRAL:	
		case MS_SEAT_MAIN:			
			sText = doGetTextDropPerRegularCalcPoint(props.calcPoint1, dStdVoltage, 0, 0, bUnit);
			break;
		case MS_OUTSIDE_OUTSIDE:	
		case MS_SEAT_T:				
			sText = doGetTextDropPerRegularCalcPoint(props.calcPoint0, dStdVoltage, 0, 0, bUnit);
			break;
		}
	}
	else {							
		sText = doGetTextDropPerRegularCalcPoint(props.calcPoint0, dStdVoltage, 0, 0, bUnit);
	}

	return sText;
}

//CEleContactor::DoGetTextVoltage
export const doGetTextVoltage = (props:MsContactorModel, nPartKind:number, bUnit:boolean = true) =>
{
	let sText;
	if (props.sPhase) {
		switch (nPartKind) {
		case MS_OUTSIDE_NEUTRAL:	
			sText = (props.voltage / 2).toString();
			break;
		case MS_OUTSIDE_OUTSIDE:	
			sText = props.voltage.toString();
			break;
		case MS_SEAT_MAIN:			
			sText = props.voltage.toString();
			break;
		case MS_SEAT_T:				
			sText = props.voltage.toString();
			break;
		}
	}
	else {
		sText = props.voltage.toString();
	}
	if (bUnit) {
		sText += 'V';
	}
	return sText;
}

//CEleContactor::DoGetRefV1
export const doGetRefV1 = (mode:any, control:ControlModel, bSociety:boolean) =>
{
    let props = control.properties as MsContactorModel;
    let project = store.getState().app.projectData;

	//CEleDS::DoGetRefV1Left  sRefV1Left
	let labelTexts = [{
		value: control.properties.refNo,
		color: "#f800f8",
	}];

	if (!props.viewResultText || (mode != ProcessMode.DRAWING && !props.supply))
    {
      return labelTexts;
    }

	switch(mode) {
        case ProcessMode.DRAWING:
        case ProcessMode.CHECKING:
        case ProcessMode.PERCENT_Z:
            if (props.isOff) {
                 //OnOff
                 labelTexts.push({
                    value: doGetTextOnOff(props, false),
                    color: "blue",
                });
            }
            break;
        case ProcessMode.CALCULATION: 
            if(props.isOff) {
                //OnOff
                labelTexts.push({
                    value: doGetTextOnOff(props, false),
                    color: "blue",
                });
                break;
            }

            if(!props.fault) {
                break;
            }

            if(props.sPhase) {
                switch(props.upTransKind) {
                    case MS_TRANS1:
                        //OUTSIDE_OUTSIDE
                        labelTexts.push({
                            value: '外線-外線',
                            color: "blue",
                        });

                        // += 'I"k2' + '通過' + '=' + DoGetTextDirectIkpeak()
                        labelTexts.push({
                            value: 'I"k2通過='
                            + doGetTextDirectIkpeak(props, MS_OUTSIDE_OUTSIDE),
                            color: "blue",
                        });

                        // += 'I"k2' + '通過' + '=' + DoGetTextDirectIkasym()
                        labelTexts.push({
                            value: 'I"k2通過='
                            + doGetTextDirectIkasym(props, MS_OUTSIDE_OUTSIDE),
                            color: "blue",
                        });

                        // += 'I"k2' + '通過' + '=' + DoGetTextDirectIksym()
                        labelTexts.push({
                            value: 'I"k2通過='
                            + doGetTextDirectIksym(props, MS_OUTSIDE_OUTSIDE),
                            color: "blue",
                        });

                        //OUTSIDE_NEUTRAL
                        labelTexts.push({
                            value: '外線-中性線',
                            color: "blue",
                        });

                        // += 'I"k2' + '通過' + '=' + DoGetTextDirectIkpeak()
                        labelTexts.push({
                            value: 'I"k2通過='
                            + doGetTextDirectIkpeak(props, MS_OUTSIDE_NEUTRAL),
                            color: "blue",
                        });

                        // += 'I"k2' + '通過' + '=' + DoGetTextDirectIkasym()
                        labelTexts.push({
                            value: 'I"k2通過='
                            + doGetTextDirectIkasym(props, MS_OUTSIDE_NEUTRAL),
                            color: "blue",
                        });

                        // += 'I"k2' + '通過' + '=' + DoGetTextDirectIksym()
                        labelTexts.push({
                            value: 'I"k2通過='
                            + doGetTextDirectIksym(props, MS_OUTSIDE_NEUTRAL),
                            color: "blue",
                        });
                        break;
                    case MS_TRANSSCOTT:
                        //SEAT_MAIN
                        labelTexts.push({
                            value: '主座',
                            color: "blue",
                        });

                        // += 'I"k2' + '通過' + '=' + DoGetTextDirectIkpeak()
                        labelTexts.push({
                            value: 'I"k2通過='
                            + doGetTextDirectIkpeak(props, MS_SEAT_MAIN),
                            color: "blue",
                        });

                        // += 'I"k2' + '通過' + '=' + DoGetTextDirectIkasym()
                        labelTexts.push({
                            value: 'I"k2通過='
                            + doGetTextDirectIkasym(props, MS_SEAT_MAIN),
                            color: "blue",
                        });

                        // += 'I"k2' + '通過' + '=' + DoGetTextDirectIksym()
                        labelTexts.push({
                            value: 'I"k2通過='
                            + doGetTextDirectIksym(props, MS_SEAT_MAIN),
                            color: "blue",
                        });

                        //SEAT_T
                        labelTexts.push({
                            value: 'T座',
                            color: "blue",
                        });

                        // += 'I"k2' + '通過' + '=' + DoGetTextDirectIkpeak()
                        labelTexts.push({
                            value: 'I"k2通過='
                            + doGetTextDirectIkpeak(props, MS_SEAT_T),
                            color: "blue",
                        });

                        // += 'I"k2' + '通過' + '=' + DoGetTextDirectIkasym()
                        labelTexts.push({
                            value: 'I"k2通過='
                            + doGetTextDirectIkasym(props, MS_SEAT_T),
                            color: "blue",
                        });

                        // += 'I"k2' + '通過' + '=' + DoGetTextDirectIksym()
                        labelTexts.push({
                            value: 'I"k2通過='
                            + doGetTextDirectIksym(props, MS_SEAT_T),
                            color: "blue",
                        });
                        break;
                }
            }
            else {
                if(props.downTransKind == MS_TRANS1) {
                    // += 'I"k2' + '通過' + '=' + DoGetTextDirectIkpeak()
                    labelTexts.push({
                        value: 'I"k2通過='
                        + doGetTextDirectIkpeak(props, MS_3PHASES),
                        color: "blue",
                    });

                    // += 'I"k2' + '通過' + '=' + DoGetTextDirectIkasym()
                    labelTexts.push({
                        value: 'I"k2通過='
                        + doGetTextDirectIkasym(props, MS_3PHASES),
                        color: "blue",
                    });

                    // += 'I"k2' + '通過' + '=' + DoGetTextDirectIksym()
                    labelTexts.push({
                        value: 'I"k2通過='
                        + doGetTextDirectIksym(props, MS_3PHASES),
                        color: "blue",
                    });

                    // += 'I"k2' + '合計' + '=' + DoGetTextDirectIksym()
                    labelTexts.push({
                        value: 'I"k2合計='
                        + doGetTextTotalIk3sym(props),
                        color: "blue",
                    });
                }
                else {
                    // += 'I"k3' + '通過' + '=' + DoGetTextDirectIkpeak()
                    labelTexts.push({
                        value: 'I"k3通過='
                        + doGetTextDirectIkpeak(props, MS_3PHASES),
                        color: "blue",
                    });

                    // += 'I"k3' + '通過' + '=' + DoGetTextDirectIkasym()
                    labelTexts.push({
                        value: 'I"k3通過='
                        + doGetTextDirectIkasym(props, MS_3PHASES),
                        color: "blue",
                    });

                    // += 'I"k3' + '通過' + '=' + DoGetTextDirectIksym()
                    labelTexts.push({
                        value: 'I"k3通過='
                        + doGetTextDirectIksym(props, MS_3PHASES),
                        color: "blue",
                    });

                    // += 'I"k3' + '合計' + '=' + DoGetTextDirectIksym()
                    labelTexts.push({
                        value: 'I"k3合計='
                        + doGetTextTotalIk3sym(props),
                        color: "blue",
                    });
                }
            }

            break;
        case ProcessMode.VOLTDROP:
            if(props.isOff) {
                //OnOff
                labelTexts.push({
                   value: doGetTextOnOff(props, false),
                   color: "blue",
               });
               break;
           }

           if (project.dropMode == MS_VOLTDROP_MOTOR && props.dropMotor && !props.sPhase && isSetDropPerMotor(props.calcPoint0)) {
               // Vd = DropVoltMotor(VoltSecondary - DropVoltMotor2)
               let sTemp = 'Vd=' + doGetTextDropVoltMotor(props) + ' (';
               if (props.supply) {
                   sTemp += doGetTextVoltage(props, MS_3PHASES, false);
                   sTemp += '-';
                   sTemp += doGetTextDropVoltMotor2(props, false);
               } else {
                   sTemp += '0-0'
               }
               sTemp += ')';
               labelTexts.push({
                   value: sTemp,
                   color: "blue",
               });

               //Vp = DoGetTextDropPerMotor (VoltSecondary → DropVoltMotor2)
               sTemp = 'Vp=' + doGetTextDropPerMotor(props) + ' (';
               if (props.supply) {
                   sTemp += doGetTextVoltage(props, MS_3PHASES, false);
                   sTemp += '→';
                   sTemp += doGetTextDropVoltMotor2(props, false);
               } else {
                   sTemp += '0→0';
               }
               sTemp += ')';
               labelTexts.push({
                   value: sTemp,
                   color: "blue",
               });
           }

           if (isCennectLoadDropElement(control, TOP_CENTER | BOTTOM_CENTER | LEFT_CENTER | RIGHT_CENTER) && project.dropMode == MS_VOLTDROP_REGULAR && props.dropRegular) {
               let stdVoltage = {
                   dStdVoltage : 0,
                   sStdVoltage : '0'
               };
               if(props.sPhase) {
                   switch(props.upTransKind) {
                       case MS_TRANS1:
                           if(props.supply) {
                              stdVoltage = doGetTextVoltDropRegular(control, MS_OUTSIDE_OUTSIDE, false);
                           }

                            //OUTSIDE_OUTSIDE
                           labelTexts.push({
                               value: '外線-外線',
                               color: "blue",
                           });
                           
                            //Vp=DoGetTextDropPerRegular (DoGetTextVoltage→DoGetTextDropVoltRegular)
                           let sTemp = 'Vp=';
                           sTemp += doGetTextDropPerRegular(props, stdVoltage.dStdVoltage, MS_OUTSIDE_OUTSIDE);
                           sTemp += ' (';
                           if(props.supply) {
                               sTemp += doGetTextVoltage(props, MS_OUTSIDE_OUTSIDE, false);
                           }
                           else {
                               sTemp += stdVoltage.sStdVoltage;
                           }
                           sTemp += '→';
                           sTemp += doGetTextDropVoltRegular(props, stdVoltage.dStdVoltage, MS_OUTSIDE_OUTSIDE, false);
                           sTemp += ')';
                           labelTexts.push({
                               value: sTemp,
                               color: "blue",
                           });

                           if(props.supply) {
                               stdVoltage = doGetTextVoltDropRegular(control, MS_OUTSIDE_NEUTRAL, false);
                            }

                           //OUTSIDE_NEUTRAL
                           labelTexts.push({
                               value: '外線-中性線',
                               color: "blue",
                           });
                            
                           //Vp=DoGetTextDropPerRegular (DoGetTextVoltage→DoGetTextDropVoltRegular)
                           sTemp = 'Vp=';
                           sTemp += doGetTextDropPerRegular(props, stdVoltage.dStdVoltage, MS_OUTSIDE_NEUTRAL);
                           sTemp += ' (';
                           if(props.supply) {
                               sTemp += doGetTextVoltage(props, MS_OUTSIDE_NEUTRAL, false);
                           }
                           else {
                               sTemp += stdVoltage.sStdVoltage;
                           }
                           sTemp += '→';
                           sTemp += doGetTextDropVoltRegular(props, stdVoltage.dStdVoltage, MS_OUTSIDE_NEUTRAL, false);
                           sTemp += ')';
                           labelTexts.push({
                               value: sTemp,
                               color: "blue",
                           });
                           
                           break;
                       case MS_TRANSSCOTT:
                           if(props.supply) {
                               stdVoltage = doGetTextVoltDropRegular(control, MS_SEAT_MAIN, false);
                           }
                           
                            //SEAT_MAIN
                            labelTexts.push({
                               value: '主座',
                               color: "blue",
                           });
                            
                           //Vp=DoGetTextDropPerRegular (DoGetTextVoltage→DoGetTextDropVoltRegular)
                           sTemp = 'Vp=';
                           sTemp += doGetTextDropPerRegular(props, stdVoltage.dStdVoltage, MS_SEAT_MAIN);
                           sTemp += ' (';
                           if(props.supply) {
                               sTemp += doGetTextVoltage(props, MS_SEAT_MAIN, false);
                           }
                           else {
                               sTemp += stdVoltage.sStdVoltage;
                           }
                           sTemp += '→';
                           sTemp += doGetTextDropVoltRegular(props, stdVoltage.dStdVoltage, MS_SEAT_MAIN, false);
                           sTemp += ')';
                           labelTexts.push({
                               value: sTemp,
                               color: "blue",
                           });

                           if(props.supply) {
                               stdVoltage = doGetTextVoltDropRegular(control, MS_SEAT_T, false);
                           }
                           
                           //SEAT_T
                           labelTexts.push({
                               value: 'T座',
                               color: "blue",
                           });
                            
                           //Vp=DoGetTextDropPerRegular (DoGetTextVoltage→DoGetTextDropVoltRegular)
                           sTemp = 'Vp=';
                           sTemp += doGetTextDropPerRegular(props, stdVoltage.dStdVoltage, MS_SEAT_T);
                           sTemp += ' (';
                           if(props.supply) {
                               sTemp += doGetTextVoltage(props, MS_SEAT_T, false);
                           }
                           else {
                               sTemp += stdVoltage.sStdVoltage;
                           }
                           sTemp += '→';
                           sTemp += doGetTextDropVoltRegular(props, stdVoltage.dStdVoltage, MS_SEAT_T, false);
                           sTemp += ')';
                           labelTexts.push({
                               value: sTemp,
                               color: "blue",
                           });
                           
                           break; 
                   }
               }
               else {
                   if(props.supply) {
                       stdVoltage = doGetTextVoltDropRegular(control, MS_3PHASES, false);
                   }

                   //Vp=DoGetTextDropPerRegular (DoGetTextVoltage→DoGetTextDropVoltRegular)
                   let sTemp = 'Vp=';
                   sTemp += doGetTextDropPerRegular(props, stdVoltage.dStdVoltage, MS_3PHASES);
                   sTemp += ' (';
                   if(props.supply) {
                       sTemp += doGetTextVoltage(props, MS_3PHASES, false);
                   }
                   else {
                       sTemp += stdVoltage.sStdVoltage;
                   }
                   sTemp += '→';
                   sTemp += doGetTextDropVoltRegular(props, stdVoltage.dStdVoltage, MS_3PHASES, false);
                   sTemp += ')';
                   labelTexts.push({
                       value: sTemp,
                       color: "blue",
                   });
               }
           }
           break;
        case ProcessMode.IMPROVE_PF:
        case ProcessMode.HARMONIC:
            if(props.isOff) {
                //OnOff
                labelTexts.push({
                    value: doGetTextOnOff(props, false),
                    color: "blue",
                });
            }
            break;
        case ProcessMode.POWER_FLOW:
            break;
        default:
            break;
    }

    return labelTexts;
}

//CEleContactor::DoGetTextActiveLeft
export const doGetTextActiveLeft = (props:MsContactorModel, nDirect:number, bUnit:boolean = true) =>
{
    let sText = '';
	if (nDirect == DIRECT_NONE) {
		if (props.lineActiveFlow1 >= 0) {
			sText = BeamsNumber(props.lineActiveFlow1, 5).toString();
		}
		else {
			sText = BeamsNumber(props.lineActiveFlow2, 5).toString();
		}
	}
	else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
		sText = BeamsNumber(Math.abs(props.lineActiveFlow1), 5).toString();
	}
	else {
		sText = BeamsNumber(Math.abs(props.lineActiveFlow2), 5).toString();
	}

	if (bUnit) {
		sText += 'kW';
	}

	return sText;
}

//CEleContactor::DoGetTextReactiveLeft
export const doGetTextReactiveLeft = (props:MsContactorModel, nDirect:number, bUnit:boolean = true) =>
{
	let sText = '';
	if (nDirect == DIRECT_NONE) {
		if (props.lineReactiveFlow1 >= 0) {
			sText = BeamsNumber(props.lineReactiveFlow1, 5).toString();
		}
		else {
			sText = BeamsNumber(props.lineReactiveFlow2, 5).toString();
		}
	}
	else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
		sText = BeamsNumber(Math.abs(props.lineReactiveFlow1), 5).toString();
	}
	else {
		sText = BeamsNumber(Math.abs(props.lineReactiveFlow2), 5).toString();
	}

	if (bUnit) {
		sText += 'kvar';
	}

	return sText;
}

//CEleContactor::DoGetTextFlowsResult
export const doGetTextFlowsResult = (props:MsContactorModel, processMode:any, nDirect:number, bSociety:boolean = false) =>
{
	let sRefV1:string[] = [];

	if (bSociety) {
		return sRefV1;
	}

	switch (processMode) {
	case MS_MODE_DRAWING:
	case MS_MODE_CHECKING:
	case MS_MODE_PERCENT_Z:
	case MS_MODE_CALCULATION:
	case MS_MODE_VOLTDROP:
	case MS_MODE_HARMONIC:
		break;
	case MS_MODE_POWER_FLOW:
		if (!props.sPhase && props.lineActiveFlow1 !==0 && props.lineActiveFlow2 !==0 && props.lineReactiveFlow1 !==0 && props.lineReactiveFlow2 !==0) {
			if (nDirect == DIRECT_NONE) {
				sRefV1.push(doGetTextActiveLeft(props,nDirect));
				sRefV1.push(doGetTextReactiveLeft(props,nDirect));
			}
			else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
				sRefV1.push(doGetTextActiveLeft(props,nDirect));
                let sTemp = ''
				if (props.lineActiveFlow1 > 0) {
					if (props.lineReactiveFlow1 > 0) {
						sTemp += "+j";
					}
					else {
						sTemp += "-j";
					}
				}
				else {
					if (props.lineReactiveFlow1 > 0) {
						sTemp += "-j";
					}
					else {
						sTemp += "+j";
					}
				}
				sRefV1.push(sTemp + doGetTextReactiveLeft(props,nDirect));
			}
			else {
				sRefV1.push(doGetTextActiveLeft(props,nDirect));
                let sTemp = ''
				if (props.lineActiveFlow2 > 0) {
					if (props.lineReactiveFlow2> 0) {
						sTemp += "+j";
					}
					else {
						sTemp += "-j";
					}
				}
				else {
					if (props.lineReactiveFlow2 > 0) {
						sTemp += "-j";
					}
					else {
						sTemp += "+j";
					}
				}
				sRefV1.push(sTemp + doGetTextReactiveLeft(props,nDirect));
			}
		}
		break;
	}

	return sRefV1;
}
