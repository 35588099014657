import { Button, Dialog, DialogContent, DialogTitle, FormControl, Stack, TextareaAutosize } from "@mui/material";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { AdjustNotes } from '../../models/Index';
import DraggablePaper from '../common/DraggablePaper';
import theme from "../../themes/globalStyles";

export type ChangeTextFreeProps = {
    data: AdjustNotes;
    modeViewOnly: boolean;
    onOK: (data: any) => void;
    onCancel: () => void;
};

type TextEditorForm = {
    textFixed: string,
    textFree: string
}

export const ChangeTextFreeDialog = (props: ChangeTextFreeProps) => {
    const { data, modeViewOnly, onOK, onCancel  } = props;
    const [textFixed, setTextFixed] = useState(data.textFixed);

    // 初期値設定
    const initialValue : TextEditorForm = {
        ...data
    }

    const { control, handleSubmit, formState: { errors }, setValue, getValues } = useForm<TextEditorForm>({
        mode: 'all',
        criteriaMode: "all",
        defaultValues: initialValue,
    });

    // Submitイベント
    const handleEditSubmit = (value: TextEditorForm) => {
        const request = createRequestData(value);
        onOK(request);
    }

    // FormデータからRequestデータを作成する
    const createRequestData = (formValue: TextEditorForm) => {
        const { ...request  } = formValue
        return request as AdjustNotes;
    }

    // キャンセル
    const handleCancel = () => {
        onCancel();
    };

    const handleResetText = () => {
        setValue("textFree",textFixed)
    };

    return (
        <Dialog open={true} maxWidth={"sm"} fullWidth
            PaperComponent={
                DraggablePaper
            }
        >
            <DialogTitle 
                onMouseOver={(e : any) => e.target.style.cursor = 'move'}
                style={{ paddingTop: '2px', paddingBottom: '2px', background: theme.palette.primary.main, color: theme.palette.primary.contrastText }}
            >
                注釈文
            </DialogTitle>
            <DialogContent style={{paddingTop: '20px'}}>
                <div style={{ height: '100%', width: '100%', margin: "auto"}}>
                    <form onSubmit={handleSubmit(handleEditSubmit)}>
                        <Stack spacing={3}>
                            <Stack spacing={2} direction={'row'} style={{ marginBottom: '10px' }}>
                                <FormControl fullWidth size="small">
                                    <Controller
                                        name="textFree"
                                        control={control}
                                        disabled={modeViewOnly}
                                        render={({ field }) => (
                                            <TextareaAutosize
                                                {...field}
                                                style={{width : '100%', borderRadius: '8px', borderBottomRightRadius: '0'}}
                                                minRows={4}
                                                required
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Stack>
                            <Stack  direction='row' justifyContent={'flex-start'}>
                                <Button disabled={modeViewOnly} size="small" variant="outlined" onClick={handleResetText}>リセット</Button>
                            </Stack>
                            <Stack direction='row' justifyContent="flex-end" spacing={2} sx={{ mt: 2, mr: 2 }}>
                                <Button disabled={modeViewOnly} variant="contained" type="submit" sx={{mr: 2}}>OK</Button>
                                <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                            </Stack>
                        </Stack>
                    </form>
                </div>
            </DialogContent>
        </Dialog>
    );
};
export default ChangeTextFreeDialog;


