import { CALC_IK2,
     CALC_IK2_OUTSIDE_NEUTRAL,
     CALC_IK2_OUTSIDE_OUTSIDE,
     CALC_IK2_SEAT_MAIN, 
     CALC_IK2_SEAT_T, 
     CALC_IK3, 
     MS_TRANS1, 
     MS_TRANSSCOTT,
     MS_OUTSIDE_NEUTRAL,
     MS_OUTSIDE_OUTSIDE,
     MS_SEAT_MAIN,
     MS_SEAT_T,
     IMPEDANCE_INPUT_PERCENT,
	 MS_3PHASES,
	 IMPEDANCE_INPUT_OHM,
	 MS_VOLTDROP_MOTOR,
	 MS_VOLTDROP_REGULAR,
     DIRECT_NONE,
     TOP_CENTER,
     LEFT_CENTER
} from "../models/Constants";
import { CalcPoint, MsImpedanceModel, ControlModel, ProcessMode } from "../models/Index";
import { store } from "..";
import { BeamsNumber } from "./FormatNumber";
import { doGetValuePerZk3 } from "./PercentZ";
import {
	 doGetValueDropVoltRegular as doGetValueDropVoltRegularCalcPoint,
	 doGetIkpeakDirectMG,
	 doGetIkasymDirectMG,
	 doGetIksymTotalMG,
	 doGetIksymDirectMG,
	 doGetTextDropVoltMotor as doGetTextDropVoltMotorCalcPoint,
	 doGetTextDropVoltMotor2 as doGetTextDropVoltMotor2CalcPoint,
	 doGetTextDropPerMotor as doGetTextDropPerMotorCalcPoint,
	 doGetTextDropVoltRegular as doGetTextDropVoltRegularCalcPoint,
	 doGetTextDropPerRegular as doGetTextDropPerRegularCalcPoint,
	 isSetDropPerMotor,
     doGetPerZDirectMG,
     doGetIkpeakTotalMG,
     doGetIkasymTotalMG,
     doGetPerZTotalMG
} from "./calcPointFunction";
import { doGetLoadCurrentEndSide, doGetLoadCurrentStartSide, isCennectLoadDropElement, isCennectSourceDropElement } from "./element";
import { doGetTextVoltDropRegular } from "./mssv3Doc";
import { MS_MODE_CALCULATION, MS_MODE_CHECKING, MS_MODE_DRAWING, MS_MODE_HARMONIC, MS_MODE_PERCENT_Z, MS_MODE_POWER_FLOW, MS_MODE_VOLTDROP } from "../statics";

export const doSetStateCicuitImpedance = (sPhase:boolean,upTransKind:number,calcPoint0 :CalcPoint,calcPoint1:CalcPoint) =>{
    if(sPhase){
        switch(upTransKind){
            case MS_TRANS1:
                calcPoint0.calcKind = CALC_IK2_OUTSIDE_OUTSIDE
                calcPoint1.calcKind = CALC_IK2_OUTSIDE_NEUTRAL
                break;
            case MS_TRANSSCOTT:
                calcPoint0.calcKind = CALC_IK2_SEAT_T
                calcPoint1.calcKind = CALC_IK2_SEAT_MAIN
                break;

        }
    }else{
        calcPoint0.calcKind = CALC_IK3
        calcPoint1.calcKind = CALC_IK2
    }
}

//CEleImpedance::DoGetValueOhmR
export const doGetValueOhmR = (props:MsImpedanceModel, dVoltage:number, dEleStdCapacity:number, dPercentR:number) =>
{
	let dOhmR = 0;

    //check ******
	if (props.inputPercentZ == IMPEDANCE_INPUT_PERCENT) {
		if (dEleStdCapacity == 0 || dPercentR == 0) {
			return 0;
		}
		dOhmR = dVoltage * dVoltage / dEleStdCapacity / 1000  * dPercentR * 10;
	}
	else {
        //check
		dOhmR = props.ohmR;
	}

	return dOhmR;
}

//CEleImpedance::DoGetValueOhmX
export const doGetValueOhmX = (props:MsImpedanceModel, dVoltage:number, dEleStdCapacity:number, dPercentX:number) =>
{
	let dOhmX = 0;

	if (props.inputPercentZ == IMPEDANCE_INPUT_PERCENT) {
		if (dEleStdCapacity == 0 || dPercentX == 0) {
			return 0;
		}
		dOhmX = dVoltage * dVoltage / dEleStdCapacity / 1000  * dPercentX * 10;
	}
	else {
		dOhmX = props.ohmX;
	}

	return dOhmX;
}

//CEleImpedance::DoGetPerZrImpedance
export const doGetPerZrImpedance = (props:MsImpedanceModel, dStdCapacity:number, nPartKind:number, bTwoWays:boolean) =>
{
	let percentZ = {
        perRk3 : 0,
        perXk3 : 0,
        perRk3Difference : 0,
        perXk3Difference : 0
    };
    //check
	let dOhmR = doGetValueOhmR(props, props.voltage, props.stdCapacity, props.percentR);
	let dOhmX = doGetValueOhmX(props, props.voltage, props.stdCapacity, props.percentX);

	if (props.voltage <= 0) {
	 	percentZ.perRk3 = 0;
		percentZ.perXk3 = 0;
	}
	else {
		if (props.sPhase) {
			switch (nPartKind) {
			case MS_OUTSIDE_NEUTRAL:		
				percentZ.perRk3 = dStdCapacity * dOhmR / (props.voltage / 2) / (props.voltage / 2) * 100;
				percentZ.perXk3 = dStdCapacity * dOhmX / (props.voltage / 2) / (props.voltage / 2) * 100;
				if (bTwoWays) {
					percentZ.perRk3 *= 2;
					percentZ.perXk3 *= 2;
				}
				break;
			case MS_OUTSIDE_OUTSIDE:			
				percentZ.perRk3 = dStdCapacity * dOhmR / props.voltage / props.voltage * 100;
				percentZ.perXk3 = dStdCapacity * dOhmX / props.voltage / props.voltage * 100;
				if (bTwoWays) {
					percentZ.perRk3 *= 2;
					percentZ.perXk3 *= 2;
				}
										
				percentZ.perRk3Difference = dStdCapacity * dOhmR / (props.voltage / 2) / (props.voltage / 2) * 100;
				percentZ.perXk3Difference = dStdCapacity * dOhmX / (props.voltage / 2) / (props.voltage / 2) * 100;
				if (bTwoWays) {
					percentZ.perRk3Difference *= 2;
					percentZ.perXk3Difference *= 2;
				}
			    percentZ.perRk3Difference = percentZ.perRk3 - percentZ.perRk3Difference;
				percentZ.perXk3Difference = percentZ.perXk3 - percentZ.perXk3Difference;
				break;
			case MS_SEAT_MAIN:		
			case MS_SEAT_T:			
				percentZ.perRk3 = dStdCapacity * dOhmR / props.voltage / props.voltage * 100;
				percentZ.perXk3 = dStdCapacity * dOhmX / props.voltage / props.voltage * 100;
				if (bTwoWays) {
					percentZ.perRk3 *= 2;
					percentZ.perXk3 *= 2;
				}
				break;
			}
		}
		else {
			percentZ.perRk3 = dStdCapacity * dOhmR / props.voltage / props.voltage * 100;
			percentZ.perXk3 = dStdCapacity * dOhmX / props.voltage / props.voltage * 100;
		}
	}

	return percentZ;
}

//CEleImpedance::DoGetValueDropVoltRegular
export const doGetValueDropVoltRegular = (props:MsImpedanceModel, dStdCapacity:number, dStdVoltage:number, nPartKind:number) =>
{
	let percentZ = doGetPerZrImpedance(props, dStdCapacity, nPartKind, false);
	let dVolt = 0;
	if (props.sPhase) {	
		switch (nPartKind) {
		case MS_OUTSIDE_NEUTRAL:	
		case MS_SEAT_MAIN:		
			dVolt = doGetValueDropVoltRegularCalcPoint(dStdVoltage, percentZ.perRk3, percentZ.perXk3, props.calcPoint1);
			break;
		case MS_OUTSIDE_OUTSIDE:	
		case MS_SEAT_T:				
			dVolt = doGetValueDropVoltRegularCalcPoint(dStdVoltage, percentZ.perRk3, percentZ.perXk3, props.calcPoint0);
			break;
		}
	}
	else {
		if (props.downTransKind == MS_TRANS1) {
			dVolt = doGetValueDropVoltRegularCalcPoint(dStdVoltage, percentZ.perRk3, percentZ.perXk3, props.calcPoint1);
		}
		else {
			dVolt = doGetValueDropVoltRegularCalcPoint(dStdVoltage, percentZ.perRk3, percentZ.perXk3, props.calcPoint0);
		}
	}
	return dVolt;
}

//CEleImpedance::DoGetTextVoltage
export const doGetTextVoltage = (props:MsImpedanceModel, nPartKind:number, bUnit:boolean = true) =>
{
	let sText = '';
	if (props.sPhase) {
		switch (nPartKind) {
		case MS_OUTSIDE_NEUTRAL:
			sText = (props.voltage / 2).toString();
			break;
		case MS_OUTSIDE_OUTSIDE:
			sText = props.voltage.toString();
			break;
		case MS_SEAT_MAIN:
			sText = props.voltage.toString();
			break;
		case MS_SEAT_T:
			sText = props.voltage.toString();
			break;
		}
	}
	else {
		sText = props.voltage.toString();
	}
	if (bUnit) { 
		sText += 'V';
	}
	return sText;
}

//CEleImpedance::DoGetValueOhmZ
export const doGetValueOhmZ = (dOhmR:number, dOhmX:number) =>
{
	return Math.sqrt(dOhmR * dOhmR + dOhmX * dOhmX);
}

//CEleImpedance::DoGetTextOhmZ
export const doGetTextOhmZ = (props:MsImpedanceModel, bUnit:boolean = true) =>
{
	let dOhmR = doGetValueOhmR(props, props.voltage, props.stdCapacity, props.percentR);
	let dOhmX = doGetValueOhmX(props, props.voltage, props.stdCapacity, props.percentX);
	let dOhmZ = doGetValueOhmZ(dOhmR, dOhmX);
	let sText = BeamsNumber(dOhmZ, 4).toString();
	if (bUnit) {
		sText += 'mΩ';
	}
	return sText;
}

//CEleImpedance::DoGetTextOhmR
export const doGetTextOhmR = (props:MsImpedanceModel, bUnit:boolean = true) =>
{
	let dOhmR = doGetValueOhmR(props, props.voltage, props.stdCapacity, props.percentR);

	let sText = BeamsNumber(dOhmR, 4).toString();
	if (bUnit) {
		sText += 'mΩ';
	}
	return sText;
}

//CEleImpedance::DoGetTextOhmX
export const doGetTextOhmX = (props:MsImpedanceModel, bUnit:boolean = true) =>
{
	let dOhmX = doGetValueOhmX(props, props.voltage, props.stdCapacity, props.percentX);

	let sText = BeamsNumber(dOhmX, 4).toString();
	if (bUnit) {
		sText += 'mΩ';
	}
	return sText;
}

//CEleImpedance::DoGetTextPercentZr
export const doGetTextPercentZr = (props:MsImpedanceModel, nPartKind:number, dStdCapacity:number, bUnit:boolean = true) =>
{
	let percentZ = doGetPerZrImpedance(props, dStdCapacity, nPartKind, bUnit);
	let dPercentZ = doGetValuePerZk3(percentZ).dPercentZ;
	let sText = BeamsNumber(dPercentZ, 4, true).toString();
	if (bUnit) {
		sText += '%r';
	}
	return sText;
}

//CEleImpedance::DoGetTextPercentRr
export const doGetTextPercentRr = (props:MsImpedanceModel, nPartKind:number, dStdCapacity:number, bUnit:boolean = true) =>
{
	let percentZ = doGetPerZrImpedance(props, dStdCapacity, nPartKind, bUnit);
	let sText = BeamsNumber(percentZ.perRk3, 4, true).toString();
	if (bUnit ) {
		sText += '%r';
	}
	
	return sText;
}

//CEleImpedance::DoGetTextPercentXr
export const doGetTextPercentXr = (props:MsImpedanceModel, nPartKind:number, dStdCapacity:number, bUnit:boolean = true) =>
{
	let percentZ = doGetPerZrImpedance(props, dStdCapacity, nPartKind, bUnit);
	let sText = BeamsNumber(percentZ.perXk3, 4, true).toString();
	if (bUnit) {
		sText += '%r';
	}
	return sText;
}

//CEleImpedance::IsPointFaultImpedance
export const isPointFaultImpedance = (props:MsImpedanceModel) =>
{
	if (props.fault) {
		return true;
	}
	return false;
}

//CEleImpedance::DoGetTextDirectIkpeak
export const doGetTextDirectIkpeak = (props:MsImpedanceModel, nPartKind:number, bUnit:boolean = true) =>
{
	let dIpeakDirect = 0;
	if (props.sPhase) {		
		switch (nPartKind) {
		case MS_OUTSIDE_NEUTRAL:	
		case MS_SEAT_MAIN:			
			dIpeakDirect = doGetIkpeakDirectMG(props.calcPoint1);
			break;
		case MS_OUTSIDE_OUTSIDE:	
		case MS_SEAT_T:				
			dIpeakDirect = doGetIkpeakDirectMG(props.calcPoint0);
			break;
		}
	}
	else {							
		if (props.downTransKind == MS_TRANS1) {
			dIpeakDirect = doGetIkpeakDirectMG(props.calcPoint1);
		}
		else {
			dIpeakDirect = doGetIkpeakDirectMG(props.calcPoint0);
		}
	}

	let sText;
	if (dIpeakDirect == 0) {
		if (props.primarySupply) {
			sText = '∞';
		} 
		else {
		 sText = '0';
		}
	 }
	 else if(dIpeakDirect > 0) {
		 sText = BeamsNumber(dIpeakDirect / 1000, 4).toString();
		 if(bUnit)
		 {
			 sText += 'kApeak';
		 }
	 }
	 else {
		 sText = '????';
	 }
	return sText;
}

//CEleImpedance::DoGetTextDirectIkasym
export const doGetTextDirectIkasym = (props:MsImpedanceModel, nPartKind:number, bUnit:boolean = true) =>
{
	let dIasymDirect = 0;
    if(props.sPhase) {
        switch(nPartKind) {
            case MS_OUTSIDE_NEUTRAL:
            case MS_SEAT_MAIN:
                dIasymDirect = doGetIkasymDirectMG(props.calcPoint1);
                break;
            case MS_OUTSIDE_OUTSIDE:
            case MS_SEAT_T:
                dIasymDirect = doGetIkasymDirectMG(props.calcPoint0);
                break;
        }
    }
    else {
        if(props.downTransKind == MS_TRANS1)
        {
            dIasymDirect = doGetIkasymDirectMG(props.calcPoint1);
        }
        else
        {
            dIasymDirect = doGetIkasymDirectMG(props.calcPoint0);
        }
    }
    let sText;
    if (dIasymDirect == 0) {
       if(props.primarySupply) {
        sText = '∞';
       }
       else {
        sText = '0';
       }
    }
    else if(dIasymDirect > 0) {
        sText = BeamsNumber(dIasymDirect / 1000, 4).toString();
        if(bUnit) {
            sText += 'kAasym';
        }
    }
    else {
        sText = '????';
    }
    return sText;
}

//CEleImpedance::DoGetTextDirectIksym
export const doGetTextDirectIksym = (props:MsImpedanceModel, nPartKind:number, bUnit:boolean = true) =>
{
	let dIsymDirect = 0;
    if(props.sPhase) {
        switch(nPartKind) {
            case MS_OUTSIDE_NEUTRAL:
            case MS_SEAT_MAIN:
                dIsymDirect = doGetIksymDirectMG(props.calcPoint1);
                break;
            case MS_OUTSIDE_OUTSIDE:
            case MS_SEAT_T:
                dIsymDirect = doGetIksymDirectMG(props.calcPoint0);
                break;
        }
    }
    else {
        if(props.downTransKind === MS_TRANS1) {
            dIsymDirect = doGetIksymDirectMG(props.calcPoint1);
        }
        else {
            dIsymDirect = doGetIksymDirectMG(props.calcPoint0);
        }
    }
    let sText;
    if (dIsymDirect == 0) {
        if(props.primarySupply) {
            sText = '∞';
        }
        else {
         sText = '0';
        }
    }
    else if(dIsymDirect > 0) {
        sText = BeamsNumber(dIsymDirect / 1000, 4).toString();
        if(bUnit) {
            sText += 'kAsym';
        }
    }
    else {
        sText = '????';
    }
    return sText;
}

//CEleImpedance::DoGetTextTotalIk3sym
export const doGetTextTotalIk3sym = (props:MsImpedanceModel, bUnit:boolean = true) =>
{
	let dIsymTotal = 0;
    if(props.downTransKind == MS_TRANS1) {
        dIsymTotal = doGetIksymTotalMG(props.calcPoint1);
    }
    else {
        dIsymTotal = doGetIksymTotalMG(props.calcPoint0);
    }
    let sText;
    if (dIsymTotal == 0) {
        if(props.primarySupply) {
            sText = '∞';
        }
        else {
         sText = '0';
        }
    }
    else if(dIsymTotal > 0) {
        sText = BeamsNumber(dIsymTotal / 1000, 4).toString();
        if(bUnit) {
            sText += 'kAsym';
        }
    }
    else {
        sText = '????';
    }
    return sText;
}

//CEleImpedance::DoGetTextDropVoltMotor
export const doGetTextDropVoltMotor = (props:MsImpedanceModel, bUnit:boolean = true) =>
{
	return doGetTextDropVoltMotorCalcPoint(props.calcPoint0, bUnit);
}

//CEleImpedance::DoGetTextDropVoltMotor2
export const doGetTextDropVoltMotor2 = (props:MsImpedanceModel, bUnit:boolean = true) =>
{
	return doGetTextDropVoltMotor2CalcPoint(props.calcPoint0, bUnit)
}

//CEleImpedance::DoGetTextDropPerMotor
export const doGetTextDropPerMotor = (props:MsImpedanceModel, bUnit:boolean = true) =>
{
	return doGetTextDropPerMotorCalcPoint(props.calcPoint0, bUnit)
}

//CEleImpedance::DoGetTextDropVoltRegular
export const doGetTextDropVoltRegular = (props:MsImpedanceModel, dStdCapacity:number, dStdVoltage:number, nPartKind:number, bUnit:boolean = true) =>
{
	let sText = '';
	let percentZ = doGetPerZrImpedance(props, dStdCapacity, nPartKind, false);
	if(props.sPhase) {
        switch(nPartKind) {
            case MS_OUTSIDE_NEUTRAL:
            case MS_SEAT_MAIN:
                sText = doGetTextDropVoltRegularCalcPoint(props.calcPoint1, dStdVoltage, percentZ.perRk3, percentZ.perXk3, bUnit);
                break;
            case MS_OUTSIDE_OUTSIDE:
            case MS_SEAT_T:
                sText = doGetTextDropVoltRegularCalcPoint(props.calcPoint0, dStdVoltage, percentZ.perRk3, percentZ.perXk3, bUnit);
                break;
        }
    } 
    else {
        if(props.downTransKind == MS_TRANS1) {
            sText = doGetTextDropVoltRegularCalcPoint(props.calcPoint1, dStdVoltage, percentZ.perRk3, percentZ.perXk3, bUnit);
        }
        else {
            sText = doGetTextDropVoltRegularCalcPoint(props.calcPoint0, dStdVoltage, percentZ.perRk3, percentZ.perXk3, bUnit);
        }
    }
	return sText;
}

//CEleImpedance::DoGetTextDropPerRegular
export const doGetTextDropPerRegular = (props:MsImpedanceModel, dStdCapacity:number, dStdVoltage:number, nPartKind:number, bUnit:boolean = true) =>
{
	let sText = '';
	let percentZ = doGetPerZrImpedance(props, dStdCapacity, nPartKind, false);
	if(props.sPhase) {
        switch(nPartKind) {
            case MS_OUTSIDE_NEUTRAL:
            case MS_SEAT_MAIN:
                sText = doGetTextDropPerRegularCalcPoint(props.calcPoint1, dStdVoltage, percentZ.perRk3, percentZ.perXk3, bUnit);
                break;
            case MS_OUTSIDE_OUTSIDE:
            case MS_SEAT_T:
                sText = doGetTextDropPerRegularCalcPoint(props.calcPoint0, dStdVoltage, percentZ.perRk3, percentZ.perXk3, bUnit);
                break;
        }
    }
    else {
        if(props.downTransKind == MS_TRANS1) {
            sText = doGetTextDropPerRegularCalcPoint(props.calcPoint1, dStdVoltage, percentZ.perRk3, percentZ.perXk3, bUnit);
        }
        else {
            sText = doGetTextDropPerRegularCalcPoint(props.calcPoint0, dStdVoltage, percentZ.perRk3, percentZ.perXk3, bUnit);
        }
    }

	return sText;
}

//CEleImpedance::DoGetTextDropVoltPF
export const doGetTextDropVoltPF = (props:MsImpedanceModel, nPartKind:number, bUnit:boolean = true) =>
{
	let sText = '';
    if(props.sPhase) {
        switch(nPartKind) {
            case MS_OUTSIDE_NEUTRAL:
            case MS_SEAT_MAIN:
                sText = BeamsNumber(props.calcPoint1.powerFactor, 5).toString();
                if(bUnit) {
                    if(props.calcPoint1.isLead) {
                        sText+= '(進み)';
                    }
                    else {
                        sText += '(遅れ)';
                    }
                }
                break;
            case MS_OUTSIDE_OUTSIDE:
            case MS_SEAT_T:
                sText = BeamsNumber(props.calcPoint0.powerFactor, 5).toString();
                if(bUnit) {
                    if(props.calcPoint0.isLead) {
                        sText+= '(進み)';
                    }
                    else {
                        sText += '(遅れ)';
                    }
                }
                break;
        }
    }
    else {
        if(props.downTransKind == MS_TRANS1) {
            sText = BeamsNumber(props.calcPoint1.powerFactor, 5).toString();
            if(bUnit) {
                if(props.calcPoint1.isLead) {
                    sText+= '(進み)';
                }
                else {
                    sText += '(遅れ)';
                }
            }
        }
        else {
            sText = BeamsNumber(props.calcPoint0.powerFactor, 5).toString();
            if(bUnit) {
                if(props.calcPoint0.isLead) {
                    sText+= '(進み)';
                }
                else {
                    sText += '(遅れ)';
                }
            }
        }
    }

	return sText;
}

//CEleImpedance::DoGetTextDropVoltDiff
export const doGetTextDropVoltDiff = (props:MsImpedanceModel, dStdCapacity:number, dStdVoltage:number, nPartKind:number, bUnit:boolean = true) =>
{
	let percentZ = doGetPerZrImpedance(props, dStdCapacity, nPartKind, false);
	let dVolt = 0;
	if(props.sPhase) {
        switch(nPartKind) {
            case MS_OUTSIDE_NEUTRAL:
            case MS_SEAT_MAIN:
                dVolt = doGetValueDropVoltRegularCalcPoint(dStdCapacity, percentZ.perRk3, percentZ.perXk3, props.calcPoint1);
                break;
            case MS_OUTSIDE_OUTSIDE:
            case MS_SEAT_T:
                dVolt = doGetValueDropVoltRegularCalcPoint(dStdCapacity, percentZ.perRk3, percentZ.perXk3, props.calcPoint0);
                break;
        }
        dVolt = dStdVoltage - dVolt;
    }
    else {
        if(props.downTransKind == MS_TRANS1) {
            dVolt = doGetValueDropVoltRegularCalcPoint(dStdVoltage, percentZ.perRk3, percentZ.perXk3, props.calcPoint1);
        }
        else {
            dVolt = doGetValueDropVoltRegularCalcPoint(dStdVoltage, percentZ.perRk3, percentZ.perXk3, props.calcPoint0);
        }
        dVolt = dStdVoltage - dVolt;
    }

    let sText = BeamsNumber(dVolt, 4).toString();
    if(bUnit) {
        sText += 'V';
    }
	return sText;
}

//CEleImpedance::DoGetTextXR
export const doGetTextXR = (props:MsImpedanceModel) =>
{
	let sText = '';
	if (props.inputPercentZ == IMPEDANCE_INPUT_PERCENT) {
		sText = BeamsNumber(props.xr, 5).toString();
	}
	else if (props.inputPercentZ == IMPEDANCE_INPUT_OHM) {
		sText = BeamsNumber(props.ohmX / props.ohmR, 5).toString();
	}
	return sText;
}

//CEleImpedance::DoGetTextLoadCurrent
export const doGetTextLoadCurrent = (props:MsImpedanceModel, nPartKind:number, bUnit:boolean = true) =>
{
	let sText = '';
	if (props.sPhase) {
		switch(nPartKind) {
            case MS_OUTSIDE_NEUTRAL:
            case MS_SEAT_MAIN:
                sText = BeamsNumber(props.calcPoint1.loadCurrent, 5).toString();
                break;
            case MS_OUTSIDE_OUTSIDE:
            case MS_SEAT_T:
                sText = BeamsNumber(props.calcPoint0.loadCurrent, 5).toString();
                break;
        }
    }
    else {
        if(props.downTransKind == MS_TRANS1) {
            sText = BeamsNumber(props.calcPoint1.loadCurrent, 5).toString();
        }
        else {
            sText = BeamsNumber(props.calcPoint0.loadCapacity, 5).toString();
        }
    }
    if(bUnit) {
        sText += 'A';
    }
	return sText;
}

//CEleImpedance::DoGetValuePercentZ
export const doGetValuePercentZ = (props:MsImpedanceModel, dVoltage:number, dEleStdCapacity:number) =>
{
	let dPerZk3 = 0;
	if (props.inputPercentZ == IMPEDANCE_INPUT_PERCENT) {
		dPerZk3 = props.percentZ;
	}
	else {
		let dPerRk3 = 0;
		if (dVoltage == 0 || props.ohmR == 0) {
			dPerRk3 = 0;
		}
		else {
			dPerRk3 = props.ohmX * (dEleStdCapacity * 1000) / (dVoltage * dVoltage * 10);
		}
		let dPerXk3 = 0;
		if (dVoltage == 0 || props.ohmX == 0) {
			dPerXk3 = 0;
		}
		else {
			dPerXk3 = props.ohmX * (dEleStdCapacity * 1000) / (dVoltage * dVoltage * 10);
		}
		dPerZk3 = Math.sqrt(dPerRk3 * dPerRk3 + dPerXk3 * dPerXk3);
	}

	return dPerZk3;
}

//CEleImpedance::DoGetTextPercentZe
export const doGetTextPercentZe = (props:MsImpedanceModel, dEleStdCapacity:number, bUnit:boolean = true) =>
{
	let dPerZk3 = doGetValuePercentZ(props, props.voltage, dEleStdCapacity);
	let sText = BeamsNumber(dPerZk3, 6).toString();
	if (bUnit) {
		sText += '%e';
	}
	return sText;
}

//CEleImpedance::DoGetValuePercentR
export const doGetValuePercentR = (props:MsImpedanceModel, dVoltage:number, dEleStdCapacity:number) =>
{
	let dPerRk3 = 0;
	if (props.inputPercentZ == IMPEDANCE_INPUT_PERCENT) {
		dPerRk3 = props.percentR;
	}
	else {
		if (dVoltage == 0 || props.ohmR == 0) {
			dPerRk3 = 0;
		}
		else {
			dPerRk3 = props.ohmX * dEleStdCapacity * 1000 / (dVoltage * dVoltage * 10);
		}
	}

	return dPerRk3;
}

//CEleImpedance::DoGetTextPercentRe
export const doGetTextPercentRe = (props:MsImpedanceModel, dEleStdCapacity:number, bUnit:boolean = true) =>
{
	let dPerRk3 = doGetValuePercentR(props, props.voltage, dEleStdCapacity);
	let sText = BeamsNumber(dPerRk3, 4).toString();
	if (bUnit) {
		sText += '%e';
	}
	return sText;
}

//CEleImpedance::DoGetTextPointState
export const doGetTextPointState = (props:MsImpedanceModel) =>
{
	let sText = ''; 

	if (props.fault) {
		sText = 'Yes';
	}
	else {
		sText = 'No';
	}

	return sText;
}

//CEleImpedance::DoGetValuePercentX
export const doGetValuePercentX = (props:MsImpedanceModel, dVoltage:number, dEleStdCapacity:number) =>
{
	let dPerXk3 = 0;
	if (props.inputPercentZ == IMPEDANCE_INPUT_PERCENT) {
		dPerXk3 = props.percentX;
	}
	else {
		let dPerXk3 = 0;
		if (dVoltage == 0 || props.ohmX == 0) {
			dPerXk3 = 0;
		}
		else {
			dPerXk3 = props.ohmX * (dEleStdCapacity * 1000) / (dVoltage * dVoltage * 10);
		}
	}

	return dPerXk3;
}

//CEleImpedance::DoGetTextPercentXe
export const doGetTextPercentXe = (props:MsImpedanceModel, dEleStdCapacity:number, bUnit:boolean = true) =>
{
	let dPerXk3 = doGetValuePercentX(props, props.voltage, dEleStdCapacity);
	let sText = BeamsNumber(dPerXk3, 4).toString();
	if (bUnit) {
		sText += '%e';
	}
	return sText;
}

//CEleImpedance::DoGetRefV1
export const doGetRefV1 = (mode:any, control:ControlModel, bSociety:boolean) =>
{
	let props = control.properties as MsImpedanceModel;
	let infoCircuit = store.getState().app.diagram.infoCircuit;
    let project = store.getState().app.projectData;

	//CEleReactor::DoGetRefV1Left sRefV1Left
	let labelTexts = [{
		value: control.properties.refNo,
		color: "#f800f8",
	}];

	if (!props.viewResultText || (mode != ProcessMode.DRAWING && !props.supply))
    {
      return labelTexts;
    }

	if (bSociety) {
		return labelTexts;
	}

    switch(mode) {
        case ProcessMode.DRAWING:
        case ProcessMode.CHECKING:
			if(props.sPhase) {
				switch(props.upTransKind) {
					case MS_TRANS1:
						//TextVoltage
						labelTexts.push({
							value: doGetTextVoltage(props, MS_OUTSIDE_OUTSIDE),
							color: "blue",
						});
						break;
					case MS_TRANSSCOTT:
						//TextVoltage
						labelTexts.push({
							value: doGetTextVoltage(props, MS_SEAT_MAIN),
							color: "blue",
						});
						break;
				}
			}
			else
			{
				//TextVoltage
				labelTexts.push({
					value: doGetTextVoltage(props, MS_3PHASES),
					color: "blue",
				});
			}
			 //OhmZ / OhmR / OhmX
			 labelTexts.push({
				value: doGetTextOhmZ(props)
				+ '='
				+ doGetTextOhmR(props, false)
				+ '+j'
				+ doGetTextOhmX(props, false),
				color: "blue",
			});
			break;
        case ProcessMode.PERCENT_Z:
			if(props.sPhase)
            {
                switch(props.upTransKind) {
                    case MS_TRANS1:
                        //XR
                        labelTexts.push({
                            value: 'X/R=' 
                            + doGetTextXR(props),
                            color: "blue",
                        });

                        //OUTSIDE_OUTSIDE
                        labelTexts.push({
                            value: '外線-外線',
                            color: "blue",
                        });
                        
                        //Zr = Rr + jXr
                        labelTexts.push({
                            value: doGetTextPercentZr(props, MS_OUTSIDE_OUTSIDE, infoCircuit.stdCapacity)
                            + '='
                            + doGetTextPercentRr(props, MS_OUTSIDE_OUTSIDE, infoCircuit.stdCapacity, false)
                            + '+j'
                            + doGetTextPercentXr(props, MS_OUTSIDE_OUTSIDE, infoCircuit.stdCapacity, false)
                            ,
                            color: "blue",
                        });

                        //OUTSIDE_NEUTRAL
                        labelTexts.push({
                            value: '外線-中性線',
                            color: "blue",
                        });

                         //Zr = Rr + jXr
                         labelTexts.push({
                            value: doGetTextPercentZr(props, MS_OUTSIDE_NEUTRAL, infoCircuit.stdCapacity)
                            + '='
                            + doGetTextPercentRr(props, MS_OUTSIDE_NEUTRAL, infoCircuit.stdCapacity, false)
                            + '+j'
                            + doGetTextPercentXr(props, MS_OUTSIDE_NEUTRAL, infoCircuit.stdCapacity, false)
                            ,
                            color: "blue",
                        });
                        break;
                    case MS_TRANSSCOTT:
                         //XR
                         labelTexts.push({
                            value: 'X/R='
                            + doGetTextXR(props),
                            color: "blue",
                        });

                        //SEAT_MAIN
                        labelTexts.push({
                            value: '主座',
                            color: "blue",
                        });

                        //Zr = Rr + jXr
                        labelTexts.push({
                            value: doGetTextPercentZr(props, MS_SEAT_MAIN, infoCircuit.stdCapacity)
                            + '='
                            + doGetTextPercentRr(props, MS_SEAT_MAIN, infoCircuit.stdCapacity, false)
                            + '+j'
                            + doGetTextPercentXr(props, MS_SEAT_MAIN, infoCircuit.stdCapacity, false)
                            ,
                            color: "blue",
                        });

                        //SEAT_T
                        labelTexts.push({
                            value: 'T座',
                            color: "blue",
                        });

                         //Zr = Rr + jXr
                         labelTexts.push({
                            value: doGetTextPercentZr(props, MS_SEAT_T, infoCircuit.stdCapacity)
                            + '='
                            + doGetTextPercentRr(props, MS_SEAT_T, infoCircuit.stdCapacity, false)
                            + '+j'
                            + doGetTextPercentXr(props, MS_SEAT_T, infoCircuit.stdCapacity, false)
                            ,
                            color: "blue",
                        });
                        break;
                }
            }
            else {
                 //XR
                 labelTexts.push({
                    value: 'X/R='
                    + doGetTextXR(props),
                    color: "blue",
                });

                //Zr = Rr + jXr
                labelTexts.push({
                    value: doGetTextPercentZr(props, MS_3PHASES, infoCircuit.stdCapacity)
                    + '='
                    + doGetTextPercentRr(props, MS_3PHASES, infoCircuit.stdCapacity, false)
                    + '+j'
                    + doGetTextPercentXr(props, MS_3PHASES, infoCircuit.stdCapacity, false)
                    ,
                    color: "blue",
                });
            }
            break;
        case ProcessMode.CALCULATION:
			if(!isPointFaultImpedance(props)) {
                break;
            }

            if(props.sPhase) {
                switch(props.upTransKind) {
                    case MS_TRANS1:
                        //OUTSIDE_OUTSIDE
                        labelTexts.push({
                            value: '外線-外線',
                            color: "blue",
                        });

                        // += 'I"k2' + '通過' + '=' + DoGetTextDirectIkpeak()
                        labelTexts.push({
                            value: 'I"k2通過='
                            + doGetTextDirectIkpeak(props, MS_OUTSIDE_OUTSIDE),
                            color: "blue",
                        });

                        // += 'I"k2' + '通過' + '=' + DoGetTextDirectIkasym()
                        labelTexts.push({
                            value: 'I"k2通過='
                            + doGetTextDirectIkasym(props, MS_OUTSIDE_OUTSIDE),
                            color: "blue",
                        });

                         // += 'I"k2' + '通過' + '=' + DoGetTextDirectIksym()
                        labelTexts.push({
                            value: 'I"k2通過='
                            + doGetTextDirectIksym(props, MS_OUTSIDE_OUTSIDE),
                            color: "blue",
                        });
                        
                         //OUTSIDE_NEUTRAL
                         labelTexts.push({
                            value: '外線-中性線',
                            color: "blue",
                        });

                        // += 'I"k2' + '通過' + '=' + DoGetTextDirectIkpeak()
                        labelTexts.push({
                            value: 'I"k2通過='
                            + doGetTextDirectIkpeak(props, MS_OUTSIDE_NEUTRAL),
                            color: "blue",
                        });

                        // += 'I"k2' + '通過' + '=' + DoGetTextDirectIkasym()
                        labelTexts.push({
                            value: 'I"k2通過='
                            + doGetTextDirectIkasym(props, MS_OUTSIDE_NEUTRAL),
                            color: "blue",
                        });

                         // += 'I"k2' + '通過' + '=' + DoGetTextDirectIksym()
                        labelTexts.push({
                            value: 'I"k2通過='
                            + doGetTextDirectIksym(props, MS_OUTSIDE_NEUTRAL),
                            color: "blue",
                        });
                        break;
                    case MS_TRANSSCOTT:
                         //SEAT_MAIN
                         labelTexts.push({
                            value: '主座',
                            color: "blue",
                        });

                        // += 'I"k2' + '通過' + '=' + DoGetTextDirectIkpeak()
                        labelTexts.push({
                            value: 'I"k2通過='
                            + doGetTextDirectIkpeak(props, MS_SEAT_MAIN),
                            color: "blue",
                        });

                        // += 'I"k2' + '通過' + '=' + DoGetTextDirectIkasym()
                        labelTexts.push({
                            value: 'I"k2通過='
                            + doGetTextDirectIkasym(props, MS_SEAT_MAIN),
                            color: "blue",
                        });

                         // += 'I"k2' + '通過' + '=' + DoGetTextDirectIksym()
                        labelTexts.push({
                            value: 'I"k2通過='
                            + doGetTextDirectIksym(props, MS_SEAT_MAIN),
                            color: "blue",
                        });

                        //SEAT_T
                        labelTexts.push({
                            value: 'T座',
                            color: "blue",
                        });

                        // += 'I"k2' + '通過' + '=' + DoGetTextDirectIkpeak()
                        labelTexts.push({
                            value: 'I"k2通過='
                            + doGetTextDirectIkpeak(props, MS_SEAT_T),
                            color: "blue",
                        });

                        // += 'I"k2' + '通過' + '=' + DoGetTextDirectIkasym()
                        labelTexts.push({
                            value: 'I"k2通過='
                            + doGetTextDirectIkasym(props, MS_SEAT_T),
                            color: "blue",
                        });

                        // += 'I"k2' + '通過' + '=' + DoGetTextDirectIksym()
                        labelTexts.push({
                            value: 'I"k2通過='
                            + doGetTextDirectIksym(props, MS_SEAT_T),
                            color: "blue",
                        });
                        break;
                }
            }
            else {
                if(props.downTransKind === MS_TRANS1) {
                     // += 'I"k2' + '通過' + '=' + DoGetTextDirectIkpeak()
                     labelTexts.push({
                        value: 'I"k2通過='
                        + doGetTextDirectIkpeak(props, MS_3PHASES),
                        color: "blue",
                    });

                    // += 'I"k2' + '通過' + '=' + DoGetTextDirectIkasym()
                    labelTexts.push({
                        value: 'I"k2通過='
                        + doGetTextDirectIkasym(props, MS_3PHASES),
                        color: "blue",
                    });

                    // += 'I"k2' + '通過' + '=' + DoGetTextDirectIksym()
                    labelTexts.push({
                        value: 'I"k2通過='
                        + doGetTextDirectIksym(props, MS_3PHASES),
                        color: "blue",
                    });

                    // += 'I"k2' + '合計' + '=' + DoGetTextDirectIksym()
                    labelTexts.push({
                        value: 'I"k2合計='
                        + doGetTextTotalIk3sym(props),
                        color: "blue",
                    });
                }
                else {
                    // += 'I"k3' + '通過' + '=' + DoGetTextDirectIkpeak()
                    labelTexts.push({
                        value: 'I"k3通過='
                        + doGetTextDirectIkpeak(props, MS_3PHASES),
                        color: "blue",
                    });

                    // += 'I"k3' + '通過' + '=' + DoGetTextDirectIkasym()
                    labelTexts.push({
                        value: 'I"k3通過='
                        + doGetTextDirectIkasym(props, MS_3PHASES),
                        color: "blue",
                    });

                    // += 'I"k3' + '通過' + '=' + DoGetTextDirectIksym()
                    labelTexts.push({
                        value: 'I"k3通過='
                        + doGetTextDirectIksym(props, MS_3PHASES),
                        color: "blue",
                    });

                    // += 'I"k3' + '合計' + '=' + DoGetTextDirectIksym()
                    labelTexts.push({
                        value: 'I"k3合計='
                        + doGetTextTotalIk3sym(props),
                        color: "blue",
                    });
                }
            }
            break;
        case ProcessMode.VOLTDROP:
			if(project.dropMode == MS_VOLTDROP_MOTOR && props.dropMotor && !props.sPhase && isSetDropPerMotor(props.calcPoint0)) {
                // Vd = DropVoltMotor(VoltSecondary - DropVoltMotor2)
                let sTemp = 'Vd=' + doGetTextDropVoltMotor(props) + ' (';
                if (props.supply ) {
                    sTemp += doGetTextVoltage(props, MS_3PHASES, false);
                    sTemp += '-';
                    sTemp += doGetTextDropVoltMotor2(props, false);
                } else {
                    sTemp += '0-0'
                }
                sTemp += ')';
                labelTexts.push({
                    value: sTemp,
                    color: "blue",
                });

                //Vp = DoGetTextDropPerMotor (VoltSecondary → DropVoltMotor2)
                sTemp = 'Vp=' + doGetTextDropPerMotor(props) + ' (';
                if (props.supply) {
                    sTemp += doGetTextVoltage(props, MS_3PHASES, false);
                    sTemp += '→';
                    sTemp += doGetTextDropVoltMotor2(props, false);
                } else {
                    sTemp += '0→0';
                }
                sTemp += ')';
                labelTexts.push({
                    value: sTemp,
                    color: "blue",
                });
                break;
            }
            if(isCennectSourceDropElement(control, doGetLoadCurrentStartSide(control)) 
            && isCennectLoadDropElement(control, doGetLoadCurrentEndSide(control))
            && project.dropMode == MS_VOLTDROP_REGULAR && props.dropRegular) {
                let stdVoltage = {
                    dStdVoltage : 0,
                    sStdVoltage : '0'
                };
                if(props.sPhase) {
                    switch(props.upTransKind) {
                        case MS_TRANS1:
                            if(props.supply) {
                               stdVoltage = doGetTextVoltDropRegular(control, MS_OUTSIDE_OUTSIDE, false);
                            }

                             //OUTSIDE_OUTSIDE
                            labelTexts.push({
                                value: '外線-外線',
                                color: "blue",
                            });

                             //IL=DoGetTextLoadCurrent,力率=DoGetTextDropVoltPF
                            labelTexts.push({
                                value: 'IL='
                                + doGetTextLoadCurrent(props, MS_OUTSIDE_OUTSIDE)
                                + ',力率='
                                + doGetTextDropVoltPF(props, MS_OUTSIDE_OUTSIDE),
                                color: "blue",
                            });

                            //Vd=DoGetTextDropVoltDiff (sStdVoltage-DoGetTextDropVoltRegular)
                            labelTexts.push({
                                value: 'Vd='
                                + doGetTextDropVoltDiff(props, infoCircuit.stdCapacity, props.voltage, MS_OUTSIDE_OUTSIDE)
                                + ' ('
                                + stdVoltage.sStdVoltage
                                + '-'
                                + doGetTextDropVoltRegular(props, infoCircuit.stdCapacity, stdVoltage.dStdVoltage, MS_OUTSIDE_OUTSIDE, false)
                                + ')',
                                color: "blue",
                            });

                            //Vp=DoGetTextDropPerRegular (DoGetTextVoltage→doGetTextDropVoltRegular)
                            let sTemp = 'Vp=';
                            sTemp += doGetTextDropPerRegular(props, infoCircuit.stdCapacity, stdVoltage.dStdVoltage, MS_OUTSIDE_OUTSIDE);
                            sTemp += ' (';
                            if(props.supply) {
                                sTemp += doGetTextVoltage(props, MS_OUTSIDE_OUTSIDE, false);
                            } 
                            else {
                                sTemp += stdVoltage.sStdVoltage;
                            }
                            sTemp += '→';
                            sTemp += doGetTextDropVoltRegular(props, infoCircuit.stdCapacity, stdVoltage.dStdVoltage, MS_OUTSIDE_OUTSIDE, false);
                            sTemp += ')';
                            labelTexts.push({
                                value: sTemp,
                                color: "blue",
                            });
                            
                            if(props.supply) {
                                stdVoltage = doGetTextVoltDropRegular(control, MS_OUTSIDE_NEUTRAL, false);
                            }

                            //OUTSIDE_NEUTRAL
                            labelTexts.push({
                                value: '外線-中性線',
                                color: "blue",
                            });

                            //IL=DoGetTextLoadCurrent,力率=DoGetTextDropVoltPF
                            labelTexts.push({
                                value: 'IL='
                                + doGetTextLoadCurrent(props, MS_OUTSIDE_NEUTRAL)
                                + ',力率='
                                + doGetTextDropVoltPF(props, MS_OUTSIDE_NEUTRAL),
                                color: "blue",
                            });

                             //Vd=DoGetTextDropVoltDiff (sStdVoltage-DoGetTextDropVoltRegular)
                             labelTexts.push({
                                value: 'Vd='
                                + doGetTextDropVoltDiff(props, infoCircuit.stdCapacity, props.voltage / 2, MS_OUTSIDE_NEUTRAL)
                                + ' ('
                                + stdVoltage.sStdVoltage
                                + '-'
                                + doGetTextDropVoltRegular(props, infoCircuit.stdCapacity, stdVoltage.dStdVoltage, MS_OUTSIDE_NEUTRAL, false)
                                + ')',
                                color: "blue",
                            });

                            //Vp=DoGetTextDropPerRegular (DoGetTextVoltage→DoGetTextDropVoltRegular)
                            sTemp = 'Vp=';
                            sTemp += doGetTextDropPerRegular(props, infoCircuit.stdCapacity, stdVoltage.dStdVoltage, MS_OUTSIDE_NEUTRAL);
                            sTemp += ' (';
                            if(props.supply) {
                                sTemp += doGetTextVoltage(props, MS_OUTSIDE_NEUTRAL, false);
                            }
                            else {
                                sTemp += stdVoltage.sStdVoltage;
                            }
                            sTemp += '→';
                            sTemp += doGetTextDropVoltRegular(props, infoCircuit.stdCapacity, stdVoltage.dStdVoltage, MS_OUTSIDE_NEUTRAL, false);
                            sTemp += ')';
                            labelTexts.push({
                                value: sTemp,
                                color: "blue",
                            });
                            break;
                        case MS_TRANSSCOTT:
                            if(props.supply) {
                                stdVoltage = doGetTextVoltDropRegular(control, MS_SEAT_MAIN, false);
                            }
                            
                            //SEAT_MAIN
                            labelTexts.push({
                                value: '主座',
                                color: "blue",
                            });

                            //IL=DoGetTextLoadCurrent,力率=DoGetTextDropVoltPF
                            labelTexts.push({
                                value: 'IL='
                                + doGetTextLoadCurrent(props, MS_SEAT_MAIN)
                                + ',力率='
                                + doGetTextDropVoltPF(props, MS_SEAT_MAIN),
                                color: "blue",
                            });

                            //Vd=DoGetTextDropVoltDiff (sStdVoltage-DoGetTextDropVoltRegular)
                            labelTexts.push({
                                value: 'Vd='
                                + doGetTextDropVoltDiff(props, infoCircuit.stdCapacity, props.voltage, MS_SEAT_MAIN)
                                + ' ('
                                + stdVoltage.sStdVoltage
                                + '-'
                                + doGetTextDropVoltRegular(props, infoCircuit.stdCapacity, stdVoltage.dStdVoltage, MS_SEAT_MAIN, false)
                                + ')',
                                color: "blue",
                            });

                            //Vp=DoGetTextDropPerRegular (DoGetTextVoltage→DoGetTextDropVoltRegular)
                            sTemp = 'Vp=';
                            sTemp += doGetTextDropPerRegular(props, infoCircuit.stdCapacity, stdVoltage.dStdVoltage, MS_SEAT_MAIN);
                            sTemp += ' (';
                            if(props.supply) {
                                sTemp += doGetTextVoltage(props, MS_SEAT_MAIN, false);
                            }
                            else {
                                sTemp += stdVoltage.sStdVoltage;
                            }
                            sTemp += '→';
                            sTemp += doGetTextDropVoltRegular(props, infoCircuit.stdCapacity, stdVoltage.dStdVoltage, MS_SEAT_MAIN, false);
                            sTemp += ')';
                            labelTexts.push({
                                value: sTemp,
                                color: "blue",
                            });

                            if(props.supply) {
                                stdVoltage = doGetTextVoltDropRegular(control, MS_SEAT_T, false);
                            }

                            //SEAT_T
                            labelTexts.push({
                                value: 'T座',
                                color: "blue",
                            });

                            //IL=DoGetTextLoadCurrent,力率=DoGetTextDropVoltPF
                            labelTexts.push({
                                value: 'IL='
                                + doGetTextLoadCurrent(props, MS_SEAT_T)
                                + ',力率='
                                + doGetTextDropVoltPF(props, MS_SEAT_T),
                                color: "blue",
                            });

                            //Vd=DoGetTextDropVoltDiff (sStdVoltage-DoGetTextDropVoltRegular)
                            labelTexts.push({
                                value: 'Vd='
                                + doGetTextDropVoltDiff(props, infoCircuit.stdCapacity, props.voltage, MS_SEAT_T)
                                + ' ('
                                + stdVoltage.sStdVoltage
                                + '-'
                                + doGetTextDropVoltRegular(props, infoCircuit.stdCapacity, stdVoltage.dStdVoltage, MS_SEAT_T, false)
                                + ')',
                                color: "blue",
                            });

                            //Vp=DoGetTextDropPerRegular (DoGetTextVoltage→DoGetTextDropVoltRegular)
                            sTemp = 'Vp=';
                            sTemp += doGetTextDropPerRegular(props, infoCircuit.stdCapacity, stdVoltage.dStdVoltage, MS_SEAT_T);
                            sTemp += ' (';
                            if(props.supply) {
                                sTemp += doGetTextVoltage(props, MS_SEAT_T, false);
                            }
                            else {
                                sTemp += stdVoltage.sStdVoltage;
                            }
                            sTemp += '→';
                            sTemp += doGetTextDropVoltRegular(props, infoCircuit.stdCapacity, stdVoltage.dStdVoltage, MS_SEAT_T, false);
                            sTemp += ')';
                            labelTexts.push({
                                value: sTemp,
                                color: "blue",
                            });
                            break;
                    }
                }
                else {
                    if(props.supply) {
                        stdVoltage = doGetTextVoltDropRegular(control, MS_3PHASES, false);
                    }

                    //IL=DoGetTextLoadCurrent,力率=DoGetTextDropVoltPF
                    labelTexts.push({
                        value: 'IL='
                        + doGetTextLoadCurrent(props, MS_3PHASES)
                        + ',力率='
                        + doGetTextDropVoltPF(props, MS_3PHASES),
                        color: "blue",
                    });

                    //Vd=DoGetTextDropVoltDiff (sStdVoltage-DoGetTextDropVoltRegular)
                    labelTexts.push({
                        value: 'Vd='
                        + doGetTextDropVoltDiff(props, infoCircuit.stdCapacity, props.voltage, MS_3PHASES)
                        + ' ('
                        + stdVoltage.sStdVoltage
                        + '-'
                        + doGetTextDropVoltRegular(props, infoCircuit.stdCapacity, stdVoltage.dStdVoltage, MS_3PHASES, false)
                        + ')',
                        color: "blue",
                    });

                    //Vp=DoGetTextDropPerRegular (DoGetTextVoltage→DoGetTextDropVoltRegular)
                    let sTemp = 'Vp=';
                    sTemp += doGetTextDropPerRegular(props, infoCircuit.stdCapacity, stdVoltage.dStdVoltage, MS_3PHASES);
                    sTemp += ' (';
                    if(props.supply) {
                        sTemp += doGetTextVoltage(props, MS_3PHASES, false);
                    }
                    else {
                        sTemp += stdVoltage.sStdVoltage;
                    }
                    sTemp += '→';
                    sTemp += doGetTextDropVoltRegular(props, infoCircuit.stdCapacity, stdVoltage.dStdVoltage, MS_3PHASES, false);
                    sTemp += ')';
                    labelTexts.push({
                        value: sTemp,
                        color: "blue",
                    });
                    break;
                }
            }
            break;
        case ProcessMode.HARMONIC:
			break;
        case ProcessMode.POWER_FLOW:
			break;
	}

	return labelTexts;
}

//CEleImpedance::DoGetTextDirectPercentZr
export const doGetTextDirectPercentZr = (props:ControlModel, nPartKind:number, bUnit:boolean = true) =>
{
	let dPerZ = 0;
	if (props.properties.sPhase) {		// 単相回路のとき
		switch (nPartKind) {
		case MS_OUTSIDE_NEUTRAL:	// 外線－中性線間
		case MS_SEAT_MAIN:			// 主座変圧器
			dPerZ = doGetPerZDirectMG(props.properties.calcPoint1).dPercentZ;
			break;
		case MS_OUTSIDE_OUTSIDE:	// 外線間
		case MS_SEAT_T:				// Ｔ座変圧器
			dPerZ = doGetPerZDirectMG(props.properties.calcPoint0).dPercentZ;
			break;
		}
	}
	else {							// 三相回路のとき
		if (props.properties.downTransKind == MS_TRANS1) {
			dPerZ = doGetPerZDirectMG(props.properties.calcPoint1).dPercentZ;
		}
		else {
			dPerZ = doGetPerZDirectMG(props.properties.calcPoint0).dPercentZ;
		}
	}

	let sText = '';
	if (dPerZ >= 0) {
		sText = BeamsNumber(dPerZ, 4).toString();
		if (bUnit) {
			sText += '%r';
		}
	}
	else {							// 短絡電流が正しく計算されなかったとき
		sText = '????';
	}
	return sText;
}

//CEleImpedance::DoGetTextTotalIk3peak
export const doGetTextTotalIk3peak = (props:MsImpedanceModel, bUnit:boolean = true) =>
{
	let dIpeakTotal = 0;
	if (props.downTransKind == MS_TRANS1) {
		dIpeakTotal = doGetIkpeakTotalMG(props.calcPoint1);
	}
	else {
		dIpeakTotal = doGetIkpeakTotalMG(props.calcPoint0);
	}
	let sText = '';
	if (dIpeakTotal == 0) {
		if (props.primarySupply) {
			sText = '∞';
		}
		else {
			sText = "0";
		}
	}
	else if (dIpeakTotal > 0) {
		sText = BeamsNumber(dIpeakTotal / 1000, 4).toString();
		if (bUnit) {
			sText += 'kApeak';
		}
	}
	else {							// 短絡電流が正しく計算されなかったとき
		sText = '????';
	}
	return sText;
}

//CEleImpedance::DoGetTextTotalIk3asym
export const doGetTextTotalIk3asym = (props:MsImpedanceModel, bUnit:boolean = true) =>
{
	let dIasymTotal = 0;
	if (props.downTransKind == MS_TRANS1) {
		dIasymTotal = doGetIkasymTotalMG(props.calcPoint1);
	}
	else {
		dIasymTotal = doGetIkasymTotalMG(props.calcPoint0);
	}
	let sText = '';
	if (dIasymTotal == 0) {
		if (props.primarySupply) {
			sText = '∞';
		}
		else {
			sText = "0";
		}
	}
	else if (dIasymTotal > 0) {
		sText = BeamsNumber(dIasymTotal / 1000, 4).toString();
		if (bUnit) {
			sText += 'kAasym';
		}
	}
	else {							// 短絡電流が正しく計算されなかったとき
		sText = '????';
	}
	return sText;
}

//CEleImpedance::DoGetTextTotalPercentZr
export const doGetTextTotalPercentZr = (props:MsImpedanceModel, bUnit:boolean = true) =>
{
	let dPerZ = 0;
	if (props.downTransKind == MS_TRANS1) {
		dPerZ = doGetPerZTotalMG(props.calcPoint1).dPercentZ;
	}
	else {
		dPerZ = doGetPerZTotalMG(props.calcPoint0).dPercentZ;
	}
	let sText = '';
	if (dPerZ >= 0) {
		sText = BeamsNumber(dPerZ, 4).toString();
		if (bUnit) {
			sText += '%r';
		}
	}
	else {							// 短絡電流が正しく計算されなかったとき
		sText = '????';
	}
	return sText;
}

// CEleImpedance::DoGetTextActiveLeft
export const doGetTextActiveLeft = (props:MsImpedanceModel, nDirect:number, bUnit:boolean = true) =>
{
	let sText = '';
	if (nDirect == DIRECT_NONE) {
		if (props.lineActiveFlow1 >= 0) {
			sText = BeamsNumber(props.lineActiveFlow1, 5).toString();
		}
		else {
			sText = BeamsNumber(props.lineActiveFlow2, 5).toString();
		}
	}
	else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
		sText = BeamsNumber(Math.abs(props.lineActiveFlow1), 5).toString();
	}
	else {
		sText = BeamsNumber(Math.abs(props.lineActiveFlow2), 5).toString();
	}

	if (bUnit) {
		sText += 'kW';
	}

	return sText;
}

//CEleImpedance::DoGetTextReactiveLeft
export const doGetTextReactiveLeft = (props:MsImpedanceModel, nDirect:number, bUnit:boolean = true) =>
{
	let sText = '';
	if (nDirect == DIRECT_NONE) {
		if (props.lineReactiveFlow1 >= 0) {
			sText = BeamsNumber(props.lineReactiveFlow1, 5).toString();
		}
		else {
			sText = BeamsNumber(props.lineReactiveFlow2, 5).toString();
		}
	}
	else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
		sText = BeamsNumber(Math.abs(props.lineReactiveFlow1), 5).toString();
	}
	else {
		sText = BeamsNumber(Math.abs(props.lineReactiveFlow2), 5).toString();
	}

	if (bUnit) {
		sText += 'kvar';
	}

	return sText;
}

//CEleImpedance::DoGetTextFlowsResult
export const doGetTextFlowsResult = (props:MsImpedanceModel, processMode:any, nDirect:number, bSociety:boolean = false) =>
{
	let sRefV1:string[] = [];

	if (bSociety) {
		return sRefV1;
	}

	switch (processMode) {
	case MS_MODE_DRAWING:
	case MS_MODE_CHECKING:
	case MS_MODE_PERCENT_Z:
	case MS_MODE_CALCULATION:
	case MS_MODE_VOLTDROP:
	case MS_MODE_HARMONIC:
		break;
	case MS_MODE_POWER_FLOW:
		if (!props.sPhase && props.lineActiveFlow1 !==0 && props.lineActiveFlow2 !==0 && props.lineReactiveFlow1 !==0 && props.lineReactiveFlow2 !==0) {
			if (nDirect == DIRECT_NONE) {
				sRefV1.push(doGetTextActiveLeft(props,nDirect));
				sRefV1.push(doGetTextReactiveLeft(props,nDirect));
			}
			else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
                sRefV1.push(doGetTextActiveLeft(props,nDirect));
                let sTemp = ''
				if (props.lineActiveFlow1 > 0) {
					if (props.lineReactiveFlow1 > 0) {
						sTemp += "+j";
					}
					else {
						sTemp += "-j";
					}
				}
				else {
					if (props.lineReactiveFlow1 > 0) {
						sTemp += "-j";
					}
					else {
						sTemp += "+j";
					}
				}
				sRefV1.push(sTemp + doGetTextReactiveLeft(props,nDirect));
			}
			else {
                sRefV1.push(doGetTextActiveLeft(props,nDirect));
                let sTemp = ''
				if (props.lineActiveFlow2 > 0) {
					if (props.lineReactiveFlow2 > 0) {
						sTemp += "+j";
					}
					else {
						sTemp += "-j";
					}
				}
				else {
					if (props.lineReactiveFlow2 > 0) {
						sTemp += "-j";
					}
					else {
						sTemp += "+j";
					}
				}
				sRefV1.push(sTemp + doGetTextReactiveLeft(props,nDirect));
			}
		}
		break;
	}

	return sRefV1;
}