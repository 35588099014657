import { Button, Dialog, DialogContent, DialogTitle, FormControl, Grid, Paper, Stack } from "@mui/material";
import theme from "../../themes/globalStyles";
import { MsSourceModel } from "../../models/Index";
import { useEffect, useMemo, useRef, useState } from "react";
import UserCurveDetailDialog from "./UserCurveDetailDialog";
import { AgGridReact } from "ag-grid-react";
import { AG_GRID_LOCALE_JP } from '../../themes/localeJP';
import NoRowsOverlay, { NoRowsMessage } from "../common/NoRowsOverlay";
import AlertDialog from "../common/AlertDialog";
import DraggablePaper from "../common/DraggablePaper";
import { store } from "../..";

export type UserCurvesDialogProps = {
    controlId: string,
    controlProperties: any;
    onDraw: (data: any) => void;
    onCancel: () => void;
    addUserCurve: (controlId : string, data : any) => void;
    updateUserCurve: (controlId : string,data : any) => void;
    deleteUserCurve: (controlId : string, data : any) => void;
    listUserCurves:any;
    type:string;
    modeViewOnly:boolean;
}

export const UserCurvesDialogProps = (props: UserCurvesDialogProps) => {
    const { controlProperties, controlId, onDraw, onCancel, addUserCurve, updateUserCurve, deleteUserCurve, listUserCurves, type, modeViewOnly } = props;
    const [openUserCurveDetailDialog, setUserCurveDetailDialog] = useState<boolean>(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
    const [selectItem, setSelectItem] = useState<any>(null);
    const [isAbleToDraw, setIsAbleToDraw] = useState(true);
   
    // const [listUserCurvesAfter, setListUserCurvesAfter] = useState([...listUserCurves])
    // キャンセル
    
    const handleCheckAbleToDraw = (item: any) =>{
        const chartDataList = store.getState().app.diagram.chartData.find(data => data.tabId === store.getState().app.diagram.currentIDChartTab)?.shape.find(shapes => shapes.chart.find(charts => charts.lineType === "USER_CURVE" && item.refNo === charts.label ))
        let temp = [] as any;
        chartDataList?.chart.map(x=> x.tabMapping.has(store.getState().app.diagram.currentIDChartTab) && temp.push(x.data.find((x:any)=>x.userCurveId || x.user_curve_id)))
        const isShow = temp.find((x:any) => (x?.userCurveId === item.userCurveId || x?.user_curve_id === item.userCurveId))
        if(isShow === undefined || isShow === null) {
             setIsAbleToDraw(false)
        }
        else
        {
        setIsAbleToDraw(true)
        }
    }

    const handleCancel = () => {
        onCancel();
    };

    const handleOpenAddForm = () => {
        setSelectItem(null)
        setUserCurveDetailDialog(true);
    }
    
    const handleOpenUpdateForm = () => {
        setUserCurveDetailDialog(true);
    }
    
    const handleOpenDeleteDialog = () => {
        setOpenDeleteDialog(true);
    }

    const handleOKAddForm = (data : any) => {
        const foundUserCurve = listUserCurves.find((curve:any) => curve.userCurveId === data.userCurveId)        
        if (foundUserCurve) { // update
            // const newList = [...listUserCurves]
            // newList[foundUserCurve] = data       
            updateUserCurve(controlId,data)   
            // setListUserCurves(newList)
        } else { // add new
            addUserCurve(controlId, data)
            // setListUserCurves(prev => [...prev, data])
        }
        setSelectItem(null)
        setUserCurveDetailDialog(false)
    }

    const handleDeleteOK = () => {
        // const newList = [...listUserCurves]
        // newList.splice(newList.findIndex((curve : any) => curve.id === selectItem.id), 1)
        // setListUserCurves([...newList])
        deleteUserCurve(controlId, selectItem)
        setOpenDeleteDialog(false)
    }

    const handleCancelAddForm = () => {
        setSelectItem(null)
        setUserCurveDetailDialog(false);
    }

    const handleDraw = () => {
        onDraw(selectItem) 
    }

    //#region GRID DEFINITIONS
        const gridRef = useRef() as any;
        const noRowsOverlayComponentParams = useMemo(() => {
            return {
                noRowsMessageFunc: () => NoRowsMessage,
            };
        }, []);

        const noRowsOverlayComponent = useMemo(() => {
            return NoRowsOverlay;
        }, []);

        // 全列に共通の定義
        const defaultColDef = {
            sortable: true, // ソート可
            editable: false, // 編集不可
            resizable: true,
            suppressMovable: true,  // 列の移動を無効にする
        };

        // 列タイプの定義
        const columnTypes = {
            nonEditableColumn: { editable: false }, // 編集不可
};

        // 列の個別定義
        const columnDefs = useMemo(() => [
            {
                headerName: "デバイス名",
                field: "refNo",
                rowDrag: false,
                minWidth: 100,
                flex: 1.5,
            },
            {
                headerName: "曲線名称",
                field: "name",
                rowDrag: false,
                minWidth: 150,
                flex: 1.5
            },
            {
                headerName: "備考",
                field: "notes",
                rowDrag: false,
                minWidth: 250,
                flex: 3
            },
            // {
            //     headerName: "",
            //     field: "option",
            //     reseizable: false,
            //     suppressMovable: true,
            //     minWidth: 110,
            //     maxWidth: 110,
            //     cellRenderer: cellMessageRenderer,
            // }
        ], [])
    //#endregion GRID DEFINITIONS
    
    return (
        <>
            <Dialog open={true} fullWidth
                PaperComponent={
                    DraggablePaper
                }
            >
                <DialogTitle onMouseOver={(e : any) => e.target.style.cursor = 'move'} style={{ paddingTop: '2px', paddingBottom: '2px', background: theme.palette.primary.main, color: theme.palette.primary.contrastText }}>ユーザー曲線リスト [{controlProperties.refNo}]</DialogTitle>
                <DialogContent sx={{ pb: 1.5 }}>
                    <div style={{ height: '100%', width: '100%' }}>
                        <div style={{ width: '100%', margin: 'auto' }}>
                            <Stack spacing={2} direction="column" mb={1} mt={2}>
                                <div className="ag-theme-alpine" style={{ height: "300px", width: "100%" }}>
                                    <AgGridReact
                                        ref={gridRef}
                                        defaultColDef={defaultColDef}
                                        columnTypes={columnTypes}
                                        columnDefs={columnDefs}
                                        // onGridReady={onGridReady}
                                        domLayout={"normal"}
                                        rowData={listUserCurves}
                                        animateRows={true}
                                        rowDragManaged={true}
                                        // rowHeight={rowHeight}
                                        rowSelection={'single'}
                                        suppressMovableColumns={false}
                                        suppressDragLeaveHidesColumns={false}
                                        localeText={AG_GRID_LOCALE_JP}
                                        // paginationPageSize={pageSize}
                                        // onCellDoubleClicked={handleEdit}
                                        onRowClicked={() => {setSelectItem(gridRef.current.api.getSelectedRows()[0]); handleCheckAbleToDraw(gridRef.current.api.getSelectedRows()[0])}}
                                        // loadingOverlayComponent={loadingOverlayComponent}
                                        // loadingOverlayComponentParams={loadingOverlayComponentParams}
                                        noRowsOverlayComponent={noRowsOverlayComponent}
                                        noRowsOverlayComponentParams={noRowsOverlayComponentParams}
                                    />
                                </div>
                                <Stack>
                                    <Grid container spacing={1} direction="row">
                                        <Grid item xs={2.25}>
                                            <Stack direction='row' mb={1} ml={1}>
                                                <Button color="inherit" variant="contained" onClick={handleOpenAddForm} disabled={modeViewOnly} >新規...</Button>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={2.25}>
                                            <Stack direction='row' mb={1} ml={1}>
                                                <Button color="inherit" variant="contained" disabled={!selectItem} onClick={handleOpenUpdateForm}>編集...</Button>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={2.25}>
                                            <Stack direction='row' mb={1} ml={1}>
                                                <Button color="inherit" variant="contained" disabled={!selectItem || modeViewOnly} onClick={handleOpenDeleteDialog}>削除...</Button>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Stack direction='row' mb={1} ml={1}>
                                                <Button color="inherit" variant="contained" disabled={isAbleToDraw || modeViewOnly} onClick={handleDraw}>グラフに表示</Button>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={2.25}>
                                            <Stack direction='row' mb={1} ml={1} flex={"end"}>
                                                <Button onClick={handleCancel} color="inherit" variant="contained" >閉じる</Button>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Stack>
                            </Stack>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>

            {openDeleteDialog && <AlertDialog title={"MSSV3"} message={"このユーザー曲線を削除しますか?"} isOpen={true} onOK={handleDeleteOK} onCancel={() => setOpenDeleteDialog(false)} />}
            {openUserCurveDetailDialog &&
                <>
                    <UserCurveDetailDialog modeViewOnly={modeViewOnly} type={type} selectedRow={selectItem} properties={controlProperties} onOK={handleOKAddForm} onCancel={handleCancelAddForm}></UserCurveDetailDialog>
                </>
            }
        </>
    )
}

export default UserCurvesDialogProps;