import { Box, Button, Checkbox, FormControl, FormControlLabel, Grid, Stack, Tab, Tabs, TextField, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { MsCTModel } from '../../models/Index';
import { useState } from "react";
import TextFieldOptions from "../common/TextFieldOptions";
import * as Constant from "../../models/Constants";

export type MsCTEditDialogFormProps = {
    data: MsCTModel;
    onOK: (data: any) => void;
    onCancel: () => void;
    setIsVoltText: string;
    setIsConnectTrans1OrTranscott:boolean;
    userRole: string;
    m_bModePM: boolean;
    modeViewOnly:boolean;
};
function a11yProps(index: number) {
    return {
        id: `tab-${index}`,
        "aria-controls": `tabpanel-${index}`,
    };
}
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}
const radioStyles = { padding: '4px 4px 4px 8px' }

export const MsCTEditDialog = (props: MsCTEditDialogFormProps) => {
    const { userRole, data, onOK, onCancel ,setIsVoltText,setIsConnectTrans1OrTranscott,m_bModePM,modeViewOnly} = props;
    const [isDisableOKBtn, setIsDisableOKBtn] = useState(false);
    // 初期値設定
    const initialValue: MsCTModel = {
        ...data
    }
    const [makeDataInput, setMakeDataInput] = useState(initialValue.makeDataInput); // ModePM
    const handleChangeMakeDataInput = (e: any) => {
        setValue(e.target.name, e.target.checked);
        setMakeDataInput(e.target.checked);
    }
    const { control, handleSubmit, formState: { errors }, setValue,getValues } = useForm<MsCTModel>({
        mode: 'all',
        criteriaMode: "all",
        defaultValues: initialValue,
    });
    const [selectTab, setSelectTab] = useState(0);

    // Submitイベント
    const handleEditSubmit = (value: MsCTModel) => {
        const request = createRequestData(value);
        onOK(request);
    }

    // FormデータからRequestデータを作成する
    const createRequestData = (formValue: MsCTModel) => {
        let request = {
            ...formValue
        } as MsCTModel;
        return request;
    }

    // キャンセル
    const handleCancel = () => {
        onCancel();
    };

    const handleChangeFormat = (e: any) => {
        setValue(e.target.name, e.target.value,{shouldValidate:true});
    }
    const handleInput = (e: any) => {
        setValue(e.target.name, e.target.value,{shouldValidate:true})
        const isInteger = /^\d*$/.test(getValues("voltage").toString())
        if(getValues("voltage") > 0 && isInteger){
            // setIsDisableCalcBtn(false);
            setIsDisableOKBtn(false);
        }
        else{
            // setIsDisableCalcBtn(true);
            setIsDisableOKBtn(true);
        }
    }
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectTab(newValue);
    };
    return (
        <>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={selectTab} onChange={handleChange} aria-label="basic tabs example"
                                variant="scrollable" 
                                scrollButtons 
                                allowScrollButtonsMobile 
                                sx={{
                                    '& .MuiButtonBase-root.MuiTabScrollButton-root.Mui-disabled': {
                                        display: "none"
                                    }
                                }}
                            >
                                <Tab label="基本" {...a11yProps(0)} />
                            </Tabs>
                        </Box>
            <div style={{ height: '100%', width: '100%' }}>
                <form onSubmit={handleSubmit(handleEditSubmit)}>
                    <div style={{ height: '300px', width: '100%', margin: 'auto', overflow: "auto" }}>
                    <Stack spacing={0.3}>
                                <Grid container mt={2}>
                                    <Grid item xs={5.5}>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center",marginTop:"5px" }}>
                                            <Typography variant="body2" width="50%">デバイス名:</Typography>
                                            <Controller
                                                name="refNo"
                                                control={control}
                                                rules={{
                                                    required: '必須項目です。入力してください。',
                                                }}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        // label="デバイス名"
                                                        error={!!errors?.refNo}
                                                        helperText={errors?.refNo?.message}
                                                        onBlur={handleChangeFormat}
                                                        onFocus={e => e.target.select()}
                                                        InputLabelProps={{
                                                            style: { color: 'black' },
                                                        }}
                                                        InputProps={{sx: { height: 32 ,fontSize:'14px',background:m_bModePM || modeViewOnly?'floralwhite':'white', color:modeViewOnly?"grey":"black"}}}
                                                        disabled={m_bModePM}
                                                        inputProps={{readOnly:modeViewOnly}}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                        
                                        <Stack sx={{ flexDirection: "row", alignItems:"center", marginTop:"2px"}}>
                                            <Typography variant="body2" width="50%">回路電圧(V):</Typography>
                                                <FormControl fullWidth size="small">
                                                    <Controller
                                                        name="voltage"
                                                        control={control}
                                                        rules={{
                                                            required: '必須項目です。入力してください。' ,
                                                            pattern:{
                                                                value: /^[0-9\b]+$/,
                                                                message: '数字を入力してください。'
                                                            },
                                                            validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                            // maxLength: {
                                                            //     value: 6,
                                                            //     message: "最大長さ <= 6 を入力してください。"
                                                            // }
                                                                }}
                                                        render={({ field }) => (
                                                            <TextFieldOptions
                                                                    {...field}
                                                                    options={initialValue.voltageList}
                                                                    // label="回路電圧(V)"
                                                                    // onChange= {handleChangeFormat}
                                                                    size="small"

                                                                    setValue={(name: any, value: any) => {
                                                                        // handleChangeFormat({target: {name: name, value: value}});
                                                                        handleInput({target: {name: name, value: value}})
                                                                    }}
                                                                    maxLength={6}
                                                                    error={!!errors?.voltage}
                                                                    helperText={errors?.voltage?.message}
                                                                    disabled={getValues("isSourceVoltage")||m_bModePM || modeViewOnly}
                                                                    height={32}
                                                                />
                                                        )}
                                                    />
                                            </FormControl>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={6.5} pl={2} >
                                        {/* Mode SP */}
                                        {(userRole && !(userRole == Constant.ROLE_LP)) &&
                                        <Stack>
                                            <Controller
                                                name="viewFlowResult"
                                                control={control}
                                                disabled={initialValue.sPhase}
                                                render={({ field }: any) => (
                                                    <FormControlLabel
                                                        {...field}
                                                        control={<Checkbox style={radioStyles} />}
                                                        checked={field.value}
                                                        label={
                                                            <Typography
                                                                variant="body2"
                                                                color={(initialValue.sPhase||m_bModePM)?'grey':'black'}
                                                            >
                                                                潮流解析結果を表示
                                                            </Typography>
                                                        }
                                                        name='viewFlowResult'
                                                        onClick={handleChangeFormat}
                                                        disabled={m_bModePM || modeViewOnly}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                        }
                                        
                                        <Stack>
                                            <Controller
                                                name="viewResultText"
                                                control={control}
                                                render={({ field }: any) => (
                                                    <FormControlLabel {...field} control={<Checkbox style={radioStyles} />} checked={field.value} label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>注釈を表示</Typography>}  name='viewResultText' 
                                                    onClick={handleChangeFormat} disabled={m_bModePM || modeViewOnly}/>
                                                )}
                                            />
                                        </Stack>
                                    </Grid>
                                    {/* ModePM */}
                                    {[Constant.ROLE_PM, Constant.ROLE_ADMIN].includes(userRole) &&
                                    <>
                                        <Stack mt={1}>
                                                <FormControlLabel
                                                    control={
                                                        <Controller
                                                            name='makeDataInput'
                                                            control={control}
                                                            render={({ field }) => (
                                                                <Checkbox
                                                                    size='small'
                                                                    {...field}
                                                                    checked={field.value}
                                                                    onChange={handleChangeMakeDataInput}
                                                                    style={{ padding: '20px 4px 4px 9px'}}
                                                                    disabled={m_bModePM || modeViewOnly}
                                                                />
                                                            )}
                                                        />
                                                    }
                                                    label={<Typography variant="body2" style={{paddingTop:'15px'}} color={m_bModePM?'grey':'black'}>銘板入力</Typography>}
                                                />
                                        </Stack>
                                        <Stack>
                                            <fieldset
                                                style={{ 
                                                    width: '70%',
                                                    color: !makeDataInput ? 'grey' : 'black', 
                                                    border:'1px solid #AAAA'
                                                }}
                                            >
                                                <legend><Typography variant="body2" >銘板</Typography></legend>
                                                <Stack spacing={0.3}>
                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                        <Typography variant="body2" width="35%"  >製造者:</Typography>
                                                        <FormControl fullWidth size='small'>
                                                            <Controller
                                                                name='makerName'
                                                                control={control}
                                                                
                                                                render={({ field }: any) => (
                                                                    <TextFieldOptions
                                                                        {...field}
                                                                        options={initialValue.makerNameList}
                                                                        type='string'
                                                                        size='small'
                                                                        setValue={(name: any, value: any) => {
                                                                            handleChangeFormat({target: {name: name, value: value}})
                                                                        }}
                                                                        height= {32}
                                                                        disabled={!makeDataInput||m_bModePM || modeViewOnly}
                                                                    />
                                                                )}
                                                            />
                                                        </FormControl>
                                                    </Stack>
                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                        <Typography variant="body2" width="35%"  >形式:</Typography>
                                                        <Controller
                                                            name='type'
                                                            control={control}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    type='text'
                                                                    size='small'
                                                                    onChange={handleChangeFormat}
                                                                    InputLabelProps={{
                                                                        style: { color: makeDataInput ? 'black' : 'grey' },
                                                                    }}
                                                                    InputProps={{
                                                                        readOnly: !makeDataInput || modeViewOnly,
                                                                        style:{color:!makeDataInput ?'grey' : 'black'},
                                                                        sx: { height: 32,
                                                                            backgroundColor: (makeDataInput && m_bModePM) || modeViewOnly ? 'floralwhite' :makeDataInput? 'white':'floralwhite',
                                                                            color:modeViewOnly?"grey":makeDataInput ? 'black' : 'grey'
                                                                         }
                                                                    }}
                                                                    style={{
                                                                        color: 'black',
                                                                    }}
                                                                    disabled={m_bModePM}
                                                                />
                                                            )}
                                                        />
                                                    </Stack>
                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                        <Typography variant="body2" width="35%"  >製造年月:</Typography>
                                                        <Grid container>
                                                            <Grid item xs={7}>
                                                                <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                                    <FormControl fullWidth size='small'>
                                                                        <Controller
                                                                            name='makeYear'
                                                                            control={control}
                                                                            rules={{
                                                                                // maxLength: {
                                                                                //     value: 8,
                                                                                //     message: "最大長さ <= 8 を入力してください。"
                                                                                // }
                                                                                    }}
                            
                                                                            render={({ field }: any) => (
                                                                                <TextFieldOptions
                                                                                    {...field}
                                                                                    options={initialValue.makeYearList}
                            
                                                                                    type='string'
                                                                                    size='small'
                                                                                    setValue={(name: any, value: any) => {
                                                                                        handleChangeFormat({target: {name: name, value: value}})
                                                                                    }}
                                                                                    error={!!errors?.makeYear}
                                                                                    helperText={errors?.makeYear?.message}
                                                                                    disabled={!makeDataInput||m_bModePM || modeViewOnly}
                                                                                    height={32}
                                                                                    maxLength={8}

                                                                                />
                                                                            )}
                                                                        />
                                                                    </FormControl>
                                                                    <Typography variant="body2" pr={0.5} pl={0.5}>年</Typography>
                                                                </Stack>
                                                            </Grid>
                                                            <Grid item xs={5}>
                                                                <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                                    <FormControl fullWidth size='small'>
                                                                        <Controller
                                                                        name='makeMonth'
                                                                        control={control}
                                                                        rules={{
                                                                            // maxLength:{
                                                                            //     value:2,
                                                                            //     message:'最大長さ <= 2 を入力してください'
                                                                            // }
                                                                        }}
                                                                        render={({ field }: any) => (
                                                                            <TextFieldOptions
                                                                                {...field}
                                                                                options={initialValue.makeMonthList}
                                                                                // label='製造者'
                                                                                type='string'
                                                                                size='small'
                                                                                // onChange={handleChangeFormat}
                                                                                setValue={(name: any, value: any) => {
                                                                                    handleChangeFormat({target: {name: name, value: value}})
                        
                                                                                }} 
                                                                                error={!!errors?.makeMonth}
                                                                                helperText={errors?.makeMonth?.message}
                                                                                disabled={!makeDataInput||m_bModePM || modeViewOnly}
                                                                                height={32}
                                                                                maxLength={2}
                        
                                                                            />
                                                                        )}
                                                                            
                                                                        />
                                                                    </FormControl>
                                                                    <Typography variant="body2" pl={0.5}>月</Typography>
                                                                </Stack>
                                                            </Grid>
                                                        </Grid>
                                                    </Stack>
                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                        <Typography variant="body2" width="35%" >製造番号:</Typography>
                                                        <Controller
                                                            name='makeNumber'
                                                            control={control}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    type='text'
                                                                    size='small'
                                                                    onChange={handleChangeFormat}
                                                                    InputLabelProps={{
                                                                        style: { color: makeDataInput ? 'black' : 'grey' },
                                                                    }}
                                                                    InputProps={{
                                                                        readOnly: !makeDataInput || modeViewOnly,
                                                                        style:{color:!makeDataInput ?'grey' : 'black'},
                                                                        sx: { height: 32,
                                                                            backgroundColor: (makeDataInput && m_bModePM) || modeViewOnly ? 'floralwhite' :makeDataInput? 'white':'floralwhite',
                                                                            color:modeViewOnly?"grey": makeDataInput ? 'black' : 'grey'
                                                                         }
                                                                    }}
                                                                    style={{
                                                                        color: 'black',
                                                                    }}
                                                                    disabled={m_bModePM}
                                                                />
                                                            )}
                                                        />
                                                    </Stack>
                                                </Stack>
                                            </fieldset>
                                        </Stack>
                                    </>
                                    }
                                </Grid>
                            </Stack>
                    </div>
                    <Stack direction='row' justifyContent="flex-end" spacing={2} mt={2}>
                        <Button variant="contained" type="submit" disabled={isDisableOKBtn || modeViewOnly}>OK</Button>
                        <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                    </Stack>
                </form >
            </div>
        </>
    );
};
export default MsCTEditDialog;


