
import DeleteIcon from '@mui/icons-material/Delete';
import { Grid, IconButton } from "@mui/material";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from 'ag-grid-react';
import { forwardRef, useImperativeHandle, useMemo, useRef, useState } from "react";
import { PageData } from '../../models/Index';
import { AG_GRID_LOCALE_JP } from '../../themes/localeJP';
import LoadingOverlay, { DefaultLoadingMessage } from '../common/LoadingOverlay';
import NoRowsOverlay, { NoRowsMessage } from '../common/NoRowsOverlay';
import PaginationCpn from '../common/PaginationCpn';
import { BreakGroupModel } from '../../models/breakModel';
import EditIcon from '@mui/icons-material/Edit';
import dayjs from 'dayjs';

//--------------------
// Props
export type HistoryProjectGridProps = {
    colState: any[];
    pageSize?: number;
    pageData?: PageData;
    onShowDetails: (data:any) => void;
    onDelete: (data: BreakGroupModel) => void;
    heightForm?: string;
    checkEdit?: boolean;
    dataHisotyProjectList:any;
    viewOnlySharedProjects?: any;
};

// -------------
// Component
export const HistoryProjectGrid = forwardRef((props: HistoryProjectGridProps, ref) => {
    const {heightForm, checkEdit, onDelete, onShowDetails,dataHisotyProjectList ,viewOnlySharedProjects} = props;
    const gridRef = useRef() as any;
    const [rowData, setRowData] = useState<any>(dataHisotyProjectList);

    useImperativeHandle(ref, () => ({
        setRowData(value: any) {
            setRowData(value)
        },
        addRowData(value: any) {
            setRowData([value,...rowData])
        },

        hideOverlay() {
            gridRef.current.api.hideOverlay();
        }
    }));


    // ag-Grid の準備完了後に呼ばれるコールバック関数
    const onGridReady = (grid: any) => {
    };

    // Loading時のメッセージ
    const loadingOverlayComponent = useMemo(() => {
        return LoadingOverlay;
    }, []);
    const loadingOverlayComponentParams = useMemo(() => {
        return {
            loadingMessage: checkEdit ? "" : DefaultLoadingMessage,
        };
    }, []);

    const noRowsOverlayComponent = useMemo(() => {
        return NoRowsOverlay;
    }, []);

    const noRowsOverlayComponentParams = useMemo(() => {
        return {
            noRowsMessageFunc: () => NoRowsMessage,
        };
    }, []);

    // 編集ボタンのセル内表示とボタンイベント
    const cellMessageRenderer = (props: any) => {
        // 詳細
        const handleEdit = () => {
            onShowDetails(props.data);
        };

        const handleDelete = () => {
            onDelete(props.data);
        };

        return (
            <Grid container direction="row" justifyContent="flex-start" paddingLeft={"-60px"}>
                <IconButton aria-label="edit" onClick={handleEdit} >
                    <EditIcon fontSize="small" />
                </IconButton>
                <IconButton aria-label="delete" onClick={handleDelete} disabled={viewOnlySharedProjects}>
                    <DeleteIcon fontSize="small" />
                </IconButton>
            </Grid>
        );
    };

    // 全列に共通の定義
    const defaultColDef = {
        sortable: true, // ソート可
        editable: false, // 編集不可
        resizable: true,
        suppressMovable: true,  // 列の移動を無効にする
    };

    // 列タイプの定義
    const columnTypes = {
        nonEditableColumn: { editable: false }, // 編集不可
    };

    const columnDefs = [
        {
            headerName: "件名",
            field: "subject",
            rowDrag: false,
            flex: 1.5,
        },
        {
            headerName: "内容",
            field: "content",
            rowDrag: false,
            flex: 1.5,
        },
        {
            headerName: "時間",
            field: "editDatetime",
            rowDrag: false,
            flex: 1.5,
            valueFormatter: (params:any) =>  dayjs(params.value.toLocaleString()).format("YYYY/MM/DD")
        },
        {
            headerName: "編集者",
            field: "editor",
            rowDrag: false,
            flex: 1.5,
        },
        {
            headerName: "",
            field: "option",
            reseizable: false,
            suppressMovable: true,
            flex: 2,
            cellRenderer: cellMessageRenderer,
        }
    ]

    const rowHeight = 48;
    return (
        <>
            <div>
                <div className="ag-theme-alpine" style={{ height: heightForm ? 'calc(100vh - ' + heightForm + 'px)' : 'calc(100vh - 275px)', width: "100%" }}>
                    <AgGridReact
                        ref={gridRef}
                        defaultColDef={defaultColDef}
                        columnTypes={columnTypes}
                        rowData={rowData}
                        onGridReady={onGridReady}
                        columnDefs={columnDefs}
                        domLayout={"normal"}
                        animateRows={true}
                        rowDragManaged={true}
                        suppressMovableColumns={false}
                        rowSelection={'single'}
                        suppressDragLeaveHidesColumns={false}
                        rowHeight={rowHeight}
                        localeText={AG_GRID_LOCALE_JP}
                        loadingOverlayComponent={loadingOverlayComponent}
                        loadingOverlayComponentParams={loadingOverlayComponentParams}
                        noRowsOverlayComponent={noRowsOverlayComponent}
                        noRowsOverlayComponentParams={noRowsOverlayComponentParams}
                    />
                </div>
            </div>
        </>
    );
});
export default HistoryProjectGrid;