import { FormControl, MenuItem, Select, Typography, Box, Stack } from '@mui/material';
import theme from '../themes/globalStyles';
import { connect, useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../store';
import { addLineToChart, createChartTab, openChart, pressContextMenuFunction, saveChartZoom, saveCurrentIdChartTab, saveMaxIdChartTab, saveNewNominalVolt, setGraphData, undo } from '../store/Actions';

import { useParams } from 'react-router-dom';
import { UPDATE_GRAPH, actionCreators } from '../store/AppStore';
import { store } from '..';
import { useEffect, useRef, useState } from 'react';
import { TabModel, UndoModel } from '../models/Index';
import * as EKind from "../models/ElementKind";
import * as Constant from "../models/Constants";

import nav2_menu_1 from "../images/icon/nav2_menu_1.png";
import nav2_menu_2 from "../images/icon/nav2_menu_2.png";
import nav2_menu_3 from "../images/icon/nav2_menu_3.png";
import nav2_menu_4 from "../images/icon/nav2_menu_4.png";
import nav2_menu_5 from "../images/icon/nav2_menu_5.png";
import nav2_menu_6 from "../images/icon/nav2_menu_6.png";
import nav2_menu_7 from "../images/icon/nav2_menu_7.png";
import nav2_menu_8 from "../images/icon/nav2_menu_8.png";
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { post } from '../components/CallApi';
import { GRAPH_SCREEN } from '../models/Constants';

export type DashboardSecondaryTopNavbarProps = {
    isOpenGraphScreen: boolean,
    modeViewOnly: boolean,
    onClose: () => void;
    userId: any;
} & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;


function DashboardSecondaryTopNavbar(props: DashboardSecondaryTopNavbarProps) {
    const {
        userId,
        isOpenGraphScreen,
        gapLine,
        isJapaneseElectricMode,
		currentIDChartTab,
        isShowScreen,
        projectData,
        currentChartTab,
        chartDataList,
        diagramDataList,
        currentTabId,
        isOpenChart,
        storeProjectId,
        modeViewOnly,
        onClose,
        pressContextMenuFunction,
        createChartTab,
        getGraphName,
        createGraph,
        setGraphData,
        saveCurrentIdChartTab,
        setLineToChart,
        setOpenChart
    } = props;
    // const controlId = useSelector((state: ApplicationState) => state.app.diagram.diagramData.find(diagramData => diagramData.shape.find(shape => shape.isSelected === true)?.isSelected === true))
    const maxIDChartTab = useSelector((state: ApplicationState) => state.app.diagram.maxIDChartTab)
    const graphData = useSelector((state: ApplicationState) => state.app.diagram.graphData["GRAPH_NAME"])
    const graphDataDB = useSelector((state: ApplicationState) => state.app.diagram.graphData["GRAPH_NAME_DB"])
	const screen = useSelector((state: ApplicationState)=> state.app.diagram.isShowScreen)
    const [checkAbleToShowLineToChart, setCheckAbleToShowLineToChart] = useState(true);
    const [isDisableCreateGraph, setIsDisableCreateGraph] = useState(true);
    const controlSelected = useSelector((state: ApplicationState) => state.app.diagram.diagramData.find(diagramData => diagramData.shape.find(shape => shape.isSelected === true)?.isSelected === true)?.shape.filter(shape => shape.isSelected === true))
    const controlId = useSelector((state: ApplicationState) => state.app.diagram.diagramData.find(diagramData => diagramData.shape.find(shape => shape.isSelected === true)?.isSelected === true)?.shape.find(shape => shape.isSelected === true)?.id)
    const dispatch = useDispatch()
    //const { projectId } = useParams()
    const [tabList, setTabList] = useState<TabModel[]>([]);
    const [tabIndex, setTabIndex] = useState(0);
    const [zoom, setZoom] = useState(currentChartTab?.zoom ?? 100)
    const [nominalVolt, setNominalVolt] = useState(currentChartTab?.nominalVolt ?? 0)
    const undoData = useSelector((state: ApplicationState) => state.app.diagram.undoData)
    const [hasOverFlow, setHasOverflow] = useState(false)
    const refDiv = useRef<any>(null)
    const scrollElement = useRef<any>(null)


    const styleIcon = {
        width: '130px',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column' as any,
    }
    const styleDisabled = {
        ...styleIcon,
        color: '#666',
        opacity: '0.2',
        pointerEvents: 'none',
    }
    useEffect(() => {
        if (currentChartTab?.zoom) {
            setZoom(currentChartTab?.zoom)
        }
        if (currentChartTab?.nominalVolt) {
            setNominalVolt(currentChartTab?.nominalVolt)
        }
    }, [currentChartTab?.zoom, currentChartTab?.nominalVolt])

    useEffect(() => {
        if (graphData !== undefined && graphData.length > 0 && isOpenGraphScreen && tabList.length > 0) {
            tabList.map(tab => {
                graphData.map((data: any) => {
                    if (data.graphNo === tab.id + 1) {
                        tab.label = data.graphName
                    }
                })
            })
            setTabList(tabList)
        }
    }, [graphData]);

    useEffect(() => {
        if (graphDataDB !== undefined && graphDataDB.length > 0 && isOpenGraphScreen && isShowScreen == GRAPH_SCREEN) {
            let max: number = 0;
            graphDataDB.map((x:any)=>{x.graphNo > max && (max = x.graphNo)})
            if (Number(maxIDChartTab) !== Number(max) && chartDataList.filter((x:any)=>x.tabId != 0).length != maxIDChartTab && chartDataList.find((x:any)=>x.tabId === maxIDChartTab) == undefined && chartDataList.find((x:any)=>x.tabId > max) == undefined && chartDataList.length == max) {
                dispatch(saveMaxIdChartTab(max, true))
            }
            let newTabList: any[] = []
            for (let i = 0; i < max; i++) {
                if (graphDataDB.find((x: any) => x.graphNo === i + 1)) {
                    newTabList = [...newTabList, { id: i, label: graphDataDB.find((x: any) => x.graphNo === i + 1)?.graphName }];
                }
            }
            setTabList(() => [...newTabList]);
            setTabIndex(newTabList.findIndex(x => x.id === currentIDChartTab - 1))
        }
        else if (graphDataDB !== undefined && graphDataDB.length === 0 && isOpenGraphScreen) {
            setTabList(() => [...[]]);
        }
    }, [graphDataDB]);

    useEffect(() => {
        const container = scrollElement.current;
        if (!container) return

        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                const { scrollWidth } = entry.target;
                const hasOverflowX = refDiv.current.clientWidth < scrollWidth;

                setHasOverflow(hasOverflowX);

            }
        });
        resizeObserver.observe(container);
        return () => resizeObserver.unobserve(container);
    }, [])

    useEffect(() => {
        handleCheckAbleToShowLine()
    }, [chartDataList, currentIDChartTab]) 

    useEffect(()=> {
        handleCheckAbleToShowLine()
        if(controlId) {
            setIsDisableCreateGraph(false)
        } else {
            setIsDisableCreateGraph(true)
        }
    }, [controlId])   

    const handleCheckAbleToShowLine = () => {
        if(diagramDataList.find(data => data.tabId === currentTabId)!.shape.filter((x:any)=>x.isSelected).length > 1)
        {
            let checkState = true
            const arr = diagramDataList.find(data => data.tabId === currentTabId)!.shape.filter((x:any)=>x.isSelected)
            for(let i=0; i < arr.length; i++)
            {
                const checkIsShow = chartDataList.find(data => data.tabId === currentIDChartTab)?.shape.find(shapes => shapes.chart.find(charts => (charts.lineType === "USER_CURVE"? false : charts.controlId === arr[i].id) && !charts.lineId.toString().includes("CALC_POINT")))
                const checkEle = diagramDataList.find(data => data.tabId === currentTabId)?.shape.find(shapes => shapes.id === arr[i].id && (shapes.type === EKind.MS_TRANS3 || shapes.type === EKind.MS_3WINDING || shapes.type === EKind.MS_TRANS1 || shapes.type === EKind.MS_TRANSSCOTT || shapes.type === EKind.MS_HVCB || shapes.type === EKind.MS_FUSE || shapes.type === EKind.MS_2E || shapes.type === EKind.MS_LVCB || shapes.type === EKind.MS_THERMAL || shapes.type === EKind.MS_THERMAL_CT || shapes.type === EKind.MS_MOTOR || (shapes.type === EKind.MS_CAPACITOR && (Number(shapes.properties.totalOhmR) > 0 || Number(shapes.properties.totalOhmR) > 0)) || shapes.type === EKind.MS_SOURCE))
                if(checkEle && checkIsShow === undefined && (isOpenChart.payload?.mode || currentIDChartTab > 0)) {
                    checkState = false
                    break
                }
            }
            setCheckAbleToShowLineToChart(checkState)
        }
        else
        {
            const isShow = chartDataList.find(data => data.tabId === currentIDChartTab)?.shape.find(shapes => shapes.chart.find(charts => (charts.lineType === "USER_CURVE" ? false : charts.controlId === controlId) && !charts.lineId.toString().includes("CALC_POINT")))
            const isCheckEle = diagramDataList.find(data => data.tabId === currentTabId)?.shape.find(shapes => shapes.id === controlId && (shapes.type === EKind.MS_TRANS3 || shapes.type === EKind.MS_3WINDING || shapes.type === EKind.MS_TRANS1 || shapes.type === EKind.MS_TRANSSCOTT || shapes.type === EKind.MS_HVCB || shapes.type === EKind.MS_FUSE || shapes.type === EKind.MS_2E || shapes.type === EKind.MS_LVCB || shapes.type === EKind.MS_THERMAL || shapes.type === EKind.MS_THERMAL_CT || shapes.type === EKind.MS_MOTOR || (shapes.type === EKind.MS_CAPACITOR && (Number(shapes.properties.totalOhmR) > 0 || Number(shapes.properties.totalOhmR) > 0)) || shapes.type === EKind.MS_SOURCE))
            if (isCheckEle && isShow === undefined && (isOpenChart.payload?.mode || currentIDChartTab > 0)) {
                setCheckAbleToShowLineToChart(false)
            } else {
                setCheckAbleToShowLineToChart(true)
            }
        }
    }

    const scrollLeft = () => {
        scrollElement.current.scrollLeft -= 120
    }

    const scrollRight = () => {
        scrollElement.current.scrollLeft += 120
    }

    const handleUndo = () => {
        if (undoData.type !== null && undoData.dataUndo !== null) {
            switch (undoData.type) {
                case "CHANGE_ZOOM":
                    {
                        dispatch(saveChartZoom(undoData.dataUndo.zoom, undoData.dataUndo.graphNo))
                        let temp = store.getState().app.diagram.chartData.find(x => x.tabId === undoData.dataUndo.graphNo);
                        let dNominalVolt = temp?.nominalVolt ?? 0;
                        let bSociety = temp?.isJapaneseElectricMode ?? false;
                        let param = {
                            userId: userId,
                            projectId: storeProjectId,
                            graphNo: undoData.dataUndo.graphNo,
                            nominalVolt: dNominalVolt,
                            zoom: undoData.dataUndo.zoom,
                            society: bSociety,
                            ownerProject: projectData.createUserId
                        }
                        dispatch(actionCreators.fetch(UPDATE_GRAPH, "/graph/update-graph", "POST", param, false, true))
                    }
                    break;
                case "CHANGE_NOMINAL_VOLT":
                    {
                        dispatch(saveNewNominalVolt(undoData.dataUndo.nominalVolt, undoData.dataUndo.graphNo))
                        let temp = store.getState().app.diagram.chartData.find(x => x.tabId === undoData.dataUndo.graphNo);
                        let dZoom = temp?.zoom ?? 100;
                        let bSociety = temp?.isJapaneseElectricMode ?? false;
                        let param = {
                            userId: userId,
                            projectId: storeProjectId,
                            graphNo: undoData.dataUndo.graphNo,
                            nominalVolt: undoData.dataUndo.nominalVolt,
                            zoom: dZoom,
                            society: bSociety,
                            ownerProject: projectData.createUserId
                        }
                        dispatch(actionCreators.fetch(UPDATE_GRAPH, "/graph/update-graph", "POST", param, false, true))
                    }
                    break;
            }
        }
    }

    const handleKeyDown = (event: any) => {
        event.preventDefault();
        switch (event.key) {
            case 'z':
                event.preventDefault();
                event.ctrlKey && handleUndo();
                break;
        }

    }

    const handleTabChange = (e: any) => {
        if (tabList[e.target.value]) {
            setTabIndex(e.target.value);
            saveCurrentIdChartTab(tabList[e.target.value].id + 1)
        }
    };

    const handleChangeNominal = (e: any) => {
        dispatch(undo({ type: "CHANGE_NOMINAL_VOLT", dataUndo: { nominalVolt: nominalVolt, graphNo: currentIDChartTab } } as UndoModel))
        dispatch(saveNewNominalVolt(e.target.value, currentIDChartTab))
        let temp = store.getState().app.diagram.chartData.find(x => x.tabId === currentIDChartTab);
        let dZoom = temp?.zoom ?? 100;
        let bSociety = temp?.isJapaneseElectricMode ?? false;
        let param = {
            userId: userId,
            projectId: storeProjectId,
            graphNo: currentIDChartTab,
            nominalVolt: e.target.value,
            zoom: dZoom,
            society: bSociety,
            ownerProject: projectData.createUserId

        }
        dispatch(actionCreators.fetch(UPDATE_GRAPH, "/graph/update-graph", "POST", param, false, true))
    }

    const handleChangeZoom = (e: any) => {
        dispatch(undo({ type: "CHANGE_ZOOM", dataUndo: { zoom: zoom, graphNo: currentIDChartTab } } as UndoModel))
        dispatch(saveChartZoom(e.target.value, currentIDChartTab))
        let temp = store.getState().app.diagram.chartData.find(x => x.tabId === currentIDChartTab);
        let dNominalVolt = temp?.nominalVolt ?? 0;
        let bSociety = temp?.isJapaneseElectricMode ?? false;
        let param = {
            userId: userId,
            projectId: storeProjectId,
            graphNo: currentIDChartTab,
            nominalVolt: dNominalVolt,
            zoom: e.target.value,
            society: bSociety,
            ownerProject: projectData.createUserId
        }
        dispatch(actionCreators.fetch(UPDATE_GRAPH, "/graph/update-graph", "POST", param, false, true))
    }

    // const handleOpenProtectionDiagram = () => {
    // }
    // const handleCloseGraph = () => {
    //     onClose()
    // }
    // const handleAddNewGraph = async () => {
    //     const params = {
    //         userId: userId,
    //         projectId: projectData.projectId,
    //         graphNo: Number(maxIDChartTab) + 1,
    //     }
    //     await createGraph(params)
    //     const maxId = Number(maxIDChartTab) + 1
    //     saveCurrentIdChartTab(maxId)
    //     dispatch(saveMaxIdChartTab(maxId,true))
    // }
    // const handleAddLineToChart = () => {
    //     dispatch(addLineToChart(true, controlId??''))
    // }
    const handleAddNewGraph = () => {
        setOpenChart(true)
    }

    const handleAddLineToChart = () => {
        setLineToChart(true, controlId??'')        
    }

    return (
        <div ref={refDiv} id="djashdjkashd" style={{ width: '100%', height: '90px', backgroundColor: theme.palette.secondary.light, display: 'flex', alignItems: 'center' }} onKeyDown={handleKeyDown}>
            {hasOverFlow && <ArrowLeftIcon onClick={scrollLeft} />}
            <div style={{ width: "94%", display: 'flex', overflow: 'hidden', whiteSpace: 'nowrap', marginLeft: "15px" }} ref={scrollElement}>

                <Stack display={"flex"} flexDirection={'row'} width={'100%'}>
                    {isOpenGraphScreen && <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center', flex: '1', minWidth: '50px' }}>
                        <FormControl sx={{ flex: '1' }} variant="standard">
                            <Select
                                size='small'
                                value={tabIndex}
                                onChange={handleTabChange}
                            >
                                {tabList.map((tab, index) => (
                                    <MenuItem key={index} value={index}>{tab.label}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>}
                    <Box sx={{ display: 'flex', flex: '2', gap: '10px', alignItems: 'center', minWidth: '150px' }}>
                        <Typography variant='body2'>公称電圧(V):</Typography>
                        <FormControl sx={{ flex: '1' }} variant="standard" disabled={modeViewOnly}>
                            {/* <InputLabel>公称電圧(V)</InputLabel> */}
                            <Select
                                size='small'
                                value={nominalVolt}
                                label="公称電圧(V)"
                                onChange={handleChangeNominal}
                            >
                                {currentChartTab?.nominalVoltList.map(nominal => (
                                    <MenuItem key={nominal} value={nominal}>{nominal}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box sx={{ display: 'flex', flex: '2', gap: '10px', alignItems: 'center', minWidth: '150px' }}>
                        <Typography variant='body2'>ズーム:</Typography>
                        <FormControl sx={{ flex: '1' }} variant="standard">
                            {/* <InputLabel>ズーム</InputLabel> */}
                            <Select
                                size='small'
                                value={zoom}
                                label="ズーム"
                                onChange={handleChangeZoom}
                            >
                                <MenuItem value={200}>{200}%</MenuItem>
                                <MenuItem value={175}>{175}%</MenuItem>
                                <MenuItem value={150}>{150}%</MenuItem>
                                <MenuItem value={140}>{140}%</MenuItem>
                                <MenuItem value={130}>{130}%</MenuItem>
                                <MenuItem value={120}>{120}%</MenuItem>
                                <MenuItem value={110}>{110}%</MenuItem>
                                <MenuItem value={100}>{100}%</MenuItem>
                                <MenuItem value={90}>{90}%</MenuItem>
                                <MenuItem value={80}>{80}%</MenuItem>
                                <MenuItem value={70}>{70}%</MenuItem>
                                <MenuItem value={60}>{60}%</MenuItem>
                                <MenuItem value={50}>{50}%</MenuItem>
                                <MenuItem value={25}>{25}%</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Box sx={{ display: 'flex', flex: '7', gap: '10px', alignItems: 'center' }}>
                        <div style={{ width: "100%", display: 'flex', alignItems: 'center', backgroundColor: theme.palette.secondary.light, justifyContent: "space-between" }}>
                            <div style={{ display: 'flex', overflow: 'hidden', whiteSpace: 'nowrap', marginLeft: '5px', flex: '1' }} >
                                {isShowScreen != GRAPH_SCREEN ?
                                <>
                                    <Box >
                                        <Stack direction="row" alignItems="center" justifyContent="center" style={(isDisableCreateGraph || screen !== Constant.CONTROL_GRAPH_SCREEN || modeViewOnly)? styleDisabled: styleIcon} onClick = {()=> handleAddNewGraph()}   >
                                            <img src={nav2_menu_4} style={{ width: '35px', height: '30px', marginTop: '15px' }} />
                                            <Typography variant="caption">保護協調図の作成</Typography>
                                        </Stack>
                                    </Box>
                                    <Box >
                                        <Stack direction="row" alignItems="center" justifyContent="center" style={(checkAbleToShowLineToChart || screen !== Constant.CONTROL_GRAPH_SCREEN || modeViewOnly)? styleDisabled:styleIcon } onClick = {()=> handleAddLineToChart()} >
                                            <img src={nav2_menu_6} style={{ width: '35px', height: '30px', marginTop: '15px' }} />
                                            <Typography variant="caption">特性曲線の追加</Typography>
                                        </Stack>
                                    </Box>
                                </>
                                :
                                <Box >
                                    <Stack direction="row" alignItems="center" justifyContent="center" style={currentIDChartTab && currentIDChartTab != 0 && isShowScreen == GRAPH_SCREEN ? styleIcon : styleDisabled} onClick={() => pressContextMenuFunction(6)}>
                                        <img src={nav2_menu_7} style={{ width: '35px', height: '30px', marginTop: '15px' }} />
                                        <Typography variant="caption">表示の分割</Typography>
                                    </Stack>
                                </Box>
                                }
                                <Box >
                                    <Stack direction="row" alignItems="center" justifyContent="center" style={currentIDChartTab && currentIDChartTab != 0 && !modeViewOnly ? styleIcon : styleDisabled} onClick={() => pressContextMenuFunction(1)}>
                                        <img src={nav2_menu_2} style={{ width: '35px', height: '30px', marginTop: '15px' }} />
                                        <Typography variant="caption">テキストボックス</Typography>
                                    </Stack>
                                </Box>
                                <Box>
                                    <Stack direction="row" alignItems="center" justifyContent="center" style={(isJapaneseElectricMode || !(currentIDChartTab && currentIDChartTab != 0 )) ? styleDisabled : styleIcon} onClick={() => pressContextMenuFunction(2)}>
                                        <img src={nav2_menu_1} style={{ width: '35px', height: '30px', marginTop: '15px' }} />
                                        <Typography variant="caption">グラフスケールの調整</Typography>
                                    </Stack>
                                </Box>
                                <Box >
                                    <Stack direction="row" alignItems="center" justifyContent="center" style={(isJapaneseElectricMode || !(currentIDChartTab && currentIDChartTab != 0)) ? styleDisabled : styleIcon} onClick={() => pressContextMenuFunction(3)}>
                                        <img src={nav2_menu_8} style={{ width: '35px', height: '30px', marginTop: '15px' }} />
                                        <Typography variant="caption">電流差／時間差の計測</Typography>
                                    </Stack>
                                </Box>
                                <Box>
                                    <Stack direction="row" alignItems="center" justifyContent="center" style={(gapLine.dispGapLine || !(currentIDChartTab && currentIDChartTab != 0)) ? styleDisabled : styleIcon} onClick={() => pressContextMenuFunction(4)} >
                                        <img src={nav2_menu_5} style={{ width: '35px', height: '30px', marginTop: '15px' }} />
                                        <Typography variant="caption">日本電気協会</Typography>
                                    </Stack>
                                </Box>
                               
                                <Box >
                                    <Stack
                                        direction="row"
                                        alignItems="center"
                                        justifyContent="center"
                                        style={!(currentIDChartTab && currentIDChartTab != 0 ) || modeViewOnly? styleDisabled : styleIcon}
                                        onClick={() => pressContextMenuFunction(5)}
                                    >
                                        <img src={nav2_menu_3} style={{ width: '35px', height: '30px', marginTop: '15px' }} />
                                        <Typography variant="caption">バンド／中心値の表示</Typography>
                                    </Stack>
                                </Box>
                                {/* {(userRole && [Constant.ROLE_PM, Constant.ROLE_ADMIN].includes(userRole)) &&
                            <Box>
                                <Stack 
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="center"
                                    style={m_bModePM?styleSelect2:styleIcon2}
                                    onClick={handleChangeModePM}
                                >
                                    <img 
                                        src={menu_9} 
                                        style={{width: '35px', height: '30px', marginTop: '15px'}}
                                    />
                                    <Typography variant="caption">メンテナンスモード</Typography>
                                </Stack>
                            </Box>
                        } */}
                            </div>
                            {/* {hasOverflow && <ArrowRightIcon onClick={scrollRight} />} */}
                        </div>
                    </Box>
                </Stack>
                {/* {
                !isOpenGraphScreen &&
                <Button
                
                    endIcon={<Close sx={{ fontSize: "10px" }} />}
                    onClick={handleCloseGraph}
                >
                </Button>
            } */}
            </div>
            {hasOverFlow && <ArrowRightIcon onClick={scrollRight} />}
        </div>
    );
};

const mapStateToProps = (state: ApplicationState) => {
    return {
        isJapaneseElectricMode: state.app.diagram.chartData.find(chartTab => chartTab.tabId === state.app.diagram.currentIDChartTab)?.isJapaneseElectricMode,
        currentIDChartTab: state.app.diagram.currentIDChartTab,
        isShowScreen: state.app.diagram.isShowScreen,
        gapLine: state.app.diagram.gapLine,
        projectData: state.app.projectData,
        currentChartTab: state.app.diagram.chartData.find(chartTab => chartTab.tabId === state.app.diagram.currentIDChartTab),
        chartDataList: state.app.diagram.chartData,
        diagramDataList: state.app.diagram.diagramData,
        currentTabId: state.app.diagram.currentIDDiagramTab,
        isOpenChart: state.app.diagram.isOpenChart,
        storeProjectId: state.app.projectData.projectId,
    };
};

const mapDispatchToProps = (dispatch: any) => ({
    pressContextMenuFunction: (param: any) => dispatch(pressContextMenuFunction(param)),
    createChartTab: (param: any) => dispatch(createChartTab(param)),
    createGraph: (params: any) => post("/graph/create-graph", params),
    getGraphName: (params: any) => post("/graph/get-graph-name", params),
    setGraphData: (params: any) => dispatch(setGraphData(params)),
    saveCurrentIdChartTab: (param: any) => dispatch(saveCurrentIdChartTab(param)),
    setLineToChart: (isAddToChart: boolean, controlId: string) => dispatch(addLineToChart(isAddToChart, controlId)),
    setOpenChart: (isOpenChart: boolean) => dispatch(openChart(isOpenChart)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DashboardSecondaryTopNavbar)
