import { LoadingButton } from '@mui/lab';
import { Box, Button, Checkbox, CircularProgress, FormControlLabel, Stack, Tab, Tabs, TextField, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { CurveHeaderModel, CurvePointModel } from '../../../models/breakModel';
import { Path as RoutePath } from '../../../path';
import { ApplicationState, actionCreators, selectors } from '../../../store';
import FetchHelper from '../../FetchHelper';

// -------------
// FETCH ID
const GET_CURVE_POINT = 'GET_CURVE_POINT';
const CREATE_CURVE_POINT = 'CREATE_CURVE_POINT';
const UPDATE_CURVE_POINT = 'UPDATE_CURVE_POINT';

// 初期値設定
const initialCurvePoint: CurvePointModel = {
    lPointID: 0,
    mv3curveCurveHeader:{}as CurveHeaderModel,
    dXPoint: "",
    dYPoint: "",
    bUseInst: false
}

// -------------
//Tabs
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

//入力欄背景
const divStyles = {
    backgroundColor: 'white',
    border: '1px solid #bebebe',
    boxShadow: '0 0 5px #fff, 0 0 5px #bebebe, 0 0 1px #aaa',
    padding: '20px 40px',
    marginTop: '40px',
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `oilManagement-tab-${index}`,
        'aria-controls': `oilManagement-tabpanel-${index}`,
    };
}

// -------------
// Props
export type CurvePointEditProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

export const CurvePointEdit = (props: CurvePointEditProps) => {
    const {
        userId,
        onCreate,
        onUpdate,
        onSuccess,
    } = props;
    
    const { control, handleSubmit, setValue, getValues,formState:{errors} } = useForm<CurvePointModel>({
        criteriaMode: 'all',
        defaultValues: initialCurvePoint,
        mode: "all",
    });

    type MyParams = {
        id: string;
        mode: string;
    };
    let { id, mode } = useParams<keyof MyParams>() as MyParams;
    const gridRef = useRef() as any;
    let navigate = useNavigate();
    const location = useLocation();
    let tab = location.state && location.state.tab;
    let lCurveHeaderID = location.state ? location.state.lCurveHeaderID : "";
    let sCurveName = location.state ? location.state.sCurveName : undefined;
    const [selectTab, setSelectTab] = useState(0);
    const [loadingFlag, setLoadingFlag] = useState(mode === 'edit' ? true : false);
    const Exp = /^[-+]?\d+(\.\d+)?$/;
    const errorValidateNumber = "数字を入力してください。";

    const handleSuccess = (data: any) => {
        console.log('>>> handleSuccess');
        // if (data && data.data){
            const object =data.data;
            setValue("mv3curveCurveHeader.lCurveHeaderID", object.mv3curveCurveHeaderEntity.lcurveHeaderID);
            setValue("mv3curveCurveHeader.sCurveName",object.mv3curveCurveHeaderEntity.scurveName);
            setValue('lPointID', object.lpointID);
            setValue('dXPoint', object.dxpoint ?? '');
            setValue('dYPoint', object.dypoint ?? '');
            setValue('bUseInst', object.buseInst);
        // }
        setLoadingFlag(false)
        if(tab !== undefined && tab !== null){
            setSelectTab(tab);
        }
    };

    useEffect(() => {
        if (mode === "add")
        {
            setValue("mv3curveCurveHeader.lCurveHeaderID", lCurveHeaderID);
            setValue("mv3curveCurveHeader.sCurveName",sCurveName);
        }
            
    }, [])

    const tabHandleChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectTab(newValue);
        navigate(".", {
            replace: true,
            state: {
                tab: newValue,
                lCurveHeaderID: lCurveHeaderID,
                sCurveName: sCurveName
            }
        });
    }

    // 検索エラー時
    const handleError = (success: boolean, data: any, error: any) => {
        console.log('>>> handleError');
        gridRef.current.hideOverlay();
    };

    // キャンセル
    const handleCancel = () => {
        if (location.key !== "default")
            navigate(-1);
        else 
            navigate(RoutePath.CurvePointList)
        
    }

    // Submitイベント
    const handleEditSubmit = (value: CurvePointModel) => {
        setLoadingFlag(true)
        if(mode === 'edit'){
            let request = {
                lPointID: getValues("lPointID"),
                bUseInst: getValues("bUseInst"),
                dXPoint: getValues("dXPoint"),
                dYPoint: getValues("dYPoint"),
                lCurveHeaderID: getValues("mv3curveCurveHeader.lCurveHeaderID"),
            } as any
            const object = {
                requestPrms:request,
                userId: userId,
            }
            onUpdate(object);
        }else if(mode === 'add'){
            let request = {
                lPointID: getValues("lPointID"),
                bUseInst: getValues("bUseInst"),
                dXPoint: getValues("dXPoint"),
                dYPoint: getValues("dYPoint"),
                lCurveHeaderID: getValues("mv3curveCurveHeader.lCurveHeaderID"),
            } as any
            const object = {
                requestPrms:request,
                userId: userId,
            }
            onCreate(object);
        }
        
    }

    // Submit後のAPI呼び出し成功時
    const handleSubmitSuccess = (success: boolean, data: any, error: any) => {
        onSuccess();
        if(mode === "add"){
            // navigate(RoutePath.CurvePointEdit + `/edit/${data.data.lpointID}`)
        }
        // navigate(-1);
        setLoadingFlag(false)
    }

    // Submit後のAPI呼び出しエラー時
    const handleSubmitError = (success: boolean, data: any, error: any) => {
        console.log('>>> handleError');
    }

    return (
        <>
            {mode === 'edit' &&
                <FetchHelper fetchId={GET_CURVE_POINT}
                    url={`/master/curve_point/get`}
                    method='POST'
                    request={true}
                    params={{
                        requestPrms:{id:id},
                        userId: userId,
                    }}
                    json={true}
                    onComplete={(success, data, error) =>
                        success ? handleSuccess(data) : handleError(success, data, error)}
            />}
            {loadingFlag && (
                <div style={{ top: '0px', left: '0px', position: 'fixed', zIndex: 1500, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                    <CircularProgress />
                </div>
            )}
            <div style={{ height: '100%', width: '100%', marginTop: '10px' }}>
                <Stack direction='row' justifyContent='flex-start' alignItems='center' sx={{}} style={{margin: '18px',color: 'MenuText'}}>
                    <Typography variant='h4'>
                        曲線座標詳細
                    </Typography>
                </Stack>
                <form onSubmit={handleSubmit(handleEditSubmit)}>
                    <div style={{ height: '100%', width: '100%' }}>
                        <Stack direction='row'>
                            <div style={{ top: '100px', position: 'absolute', zIndex: 1200 }}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs value={selectTab} onChange={tabHandleChange} aria-label='oilManagementDetailTabs'>
                                        <Tab label='曲線座標' {...a11yProps(0)} />
                                    </Tabs>
                                </Box>
                            </div>
                        </Stack>

                        {/* 曲線座標 */}
                        <TabPanel value={selectTab} index={0}>
                            <div style={divStyles}>
                                <Typography variant='h6'>曲線座標</Typography>
                                <Stack pt={5} spacing={3}  sx={{ maxHeight:"calc(100vh)", overflow: 'auto'}}> 
                                    <Stack mt={5}>
                                        <Controller
                                            name="mv3curveCurveHeader.sCurveName"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    disabled
                                                    fullWidth
                                                    type='text'
                                                    label='曲線図面名称'
                                                    size='small'
                                                    InputLabelProps={{shrink:true}}
                                                   
                                                />
                                            )}
                                        />
                                    </Stack>
                                    <Stack>
                                        <Controller
                                            name='dXPoint'
                                            control={control}
                                            rules={{
                                                pattern:{
                                                    value:Exp,
                                                    message:errorValidateNumber
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    label='X軸値(A)/(%)'
                                                    size='small'
                                                    
                                                    error={!!errors?.dXPoint}
                                                    helperText={errors?.dXPoint?.message}
                                                />
                                            )}
                                        />
                                    </Stack>
                                    <Stack>
                                        <Controller
                                            name='dYPoint'
                                            control={control}
                                            rules={{
                                                pattern:{
                                                    value:Exp,
                                                    message:errorValidateNumber
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    label='時間値(s)'
                                                    size='small'
                                                    
                                                    error={!!errors?.dYPoint}
                                                    helperText={errors?.dYPoint?.message}
                                                />
                                            )}
                                        />
                                    </Stack>
                                    <Stack>
                                        <Controller
                                            name='bUseInst'
                                            control={control}
                                            render={({ field }: any) => (
                                                <FormControlLabel 
                                                    {...field} 
                                                    control={<Checkbox />} 
                                                    checked={field.value} 
                                                    label='瞬時値'
                                                />
                                            )}
                                        />
                                    </Stack>
                                </Stack>
                            </div>
                        </TabPanel>
                        
                        <Stack direction='row' justifyContent='flex-end' spacing={2} sx={{ mt: 1, mr: 2 }}>
                            <LoadingButton variant='contained' type='submit' loading={loadingFlag}>保存</LoadingButton>
                            <Button color='inherit' variant='contained' onClick={handleCancel}>キャンセル</Button>
                        </Stack>
                    </div>
                </form>
            </div>
            <FetchHelper 
                fetchId={CREATE_CURVE_POINT} 
                onComplete={(success, data, error) => success ? 
                    handleSubmitSuccess(success, data, error) 
                    : handleSubmitError(success, data, error)} 
            />
            <FetchHelper 
                fetchId={UPDATE_CURVE_POINT} 
                onComplete={(success, data, error) => success ? 
                    handleSubmitSuccess(success, data, error)
                    : handleSubmitError(success, data, error)}
            />
        </>
    );
};

const mapStateToProps = (state: ApplicationState) => ({
    userId: state.app.user?.userId,
    fetchUpdate: selectors.getFormState(
        state,
        UPDATE_CURVE_POINT
    ),
});

const mapDispatchToProps = (dispatch: any) => {
    return {
        onCreate: (data: any) => dispatch(actionCreators.fetch(CREATE_CURVE_POINT, `/master/curve_point/add`, 'POST', data,false,true)),
        onUpdate: (data: any) => dispatch(actionCreators.fetch(UPDATE_CURVE_POINT, `/master/curve_point/modify`, 'POST', data,false,true)),
        onSuccess: () =>
            dispatch(actionCreators.showMessage({
                type: 'info',
                title: '曲線座標更新',
                body: '曲線座標を更新しました。',
            })),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CurvePointEdit as any);


