import { Button, Checkbox, Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, MenuItem, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import { Control, Controller, useForm } from "react-hook-form";
import theme from "../../themes/globalStyles";
import DraggablePaper from "../common/DraggablePaper";
import { getElementKindLabel, getElementKindValue } from "../../utils/ElementFunction";
import { MS_3PHASES, MS_OUTSIDE_NEUTRAL, MS_OUTSIDE_OUTSIDE, MS_SEAT_MAIN, RS_3PHASES, VOLT_SIDE_PRIMARY, VOLT_SIDE_SECONDARY, VOLT_SIDE_THIRD, elementTypeENtoJP } from "../../statics";
import { IMPEDANCE_INPUT_OHM, IMPEDANCE_INPUT_PERCENT, MS_TRANS1 as MS_TRANS1_CONST, MS_TRANSSCOTT as MS_TRANSSCOTT_CONST } from "../../models/Constants";
import { store } from "../..";
import { useMemo, useRef, useState } from "react";
import { AG_GRID_LOCALE_JP } from '../../themes/localeJP';
import NoRowsOverlay, { NoRowsMessage } from "../common/NoRowsOverlay";
import { AgGridReact } from "ag-grid-react";
import { ControlModel, DiagramModel, Ms3WindingModel, MsTrans1Model, MsTransScottModel, OptionModel } from "../../models/Index";
import { 
    MS_2E, 
    MS_3WINDING, 
    MS_ARRESTOR, 
    MS_BUSBAR, 
    MS_BUSDUCT, 
    MS_CABLEHEAD, 
    MS_CAPACITOR, 
    MS_CONNECT, 
    MS_CONTACTOR, 
    MS_CT, 
    MS_DS, 
    MS_EARTH, 
    MS_FUSE, 
    MS_GENERATOR, 
    MS_HVCB, 
    MS_IMPEDANCE, 
    MS_INTO_POINT, 
    MS_INV, 
    MS_LBS, 
    MS_LIGHTBOARD, 
    MS_LOAD, 
    MS_LOADCENTER, 
    MS_LVCB, 
    MS_MOTOR, 
    MS_MOTOR_GROUP, 
    MS_MVCB, 
    MS_POWERBOARD, 
    MS_REACTOR, 
    MS_RELAY, 
    MS_RELAY51, 
    MS_SOURCE, 
    MS_TEXT, 
    MS_THERMAL, 
    MS_THERMAL_CT, 
    MS_TRANS1, 
    MS_TRANS3, 
    MS_TRANSCENTER, 
    MS_TRANSSCOTT, 
    MS_VCT, 
    MS_VT, 
    MS_WIRE, 
    MS_ZCT, 
    MS_ZEROCOND 
} from "../../models/ElementKind";
import { doGetRefNoSkeleton, doGetReportDetailCommentToSave, doGetTextSidePointTextAndState, isPointFaultAND, isSPhaseCircuitEle } from "../../utils/element";
import { useDispatch, useSelector } from "react-redux";
import { updatePropertiesOfControlAction, updateSelectControl } from "../../store/Actions";
import ShortCircuitCurrentReviewDialog from "./ShortCircuitCurrentReviewDialog";
import { convertAdjustToCallAPI, convertUndefinedToNull } from "../../utils/DataConverter";
import { actionCreators } from "../../store";

export const doGetInfoElement = (control:ControlModel) => 
{
    if (control.type == MS_INTO_POINT || control.type == MS_CONNECT) {
        return;
    }
	
    let sRefNo = doGetRefNoSkeleton(control);
    let project = store.getState().app.projectData;
    let id = control.id;
    let result = {
        sPointText : '',
        sPointState : '',
        sPointSide : '二次側'
    }

    let arrRes: any[] = [];

    switch (control.type) {
        case MS_SOURCE:			
        case MS_GENERATOR:		
        case MS_TRANS3:			
        case MS_DS:				
        case MS_LBS:			
        case MS_HVCB:			
        case MS_RELAY51:		
        case MS_FUSE:			
        case MS_REACTOR:		
            {
                if (project.viewPointTextOnly && !isPointFaultAND(control)) {
                    break;
                }
                result.sPointText = '';
                result.sPointState = '';
                result.sPointSide = '二次側';
                
                result = doGetTextSidePointTextAndState(control, result.sPointSide);

                arrRes.push({id: id, sRefNo: sRefNo,...result});
            }
            break;
        case MS_2E:				
        case MS_BUSBAR:			
        case MS_BUSDUCT:		
        case MS_CONTACTOR:		
        case MS_IMPEDANCE:		
        case MS_LVCB:			
        case MS_MVCB:			
        case MS_THERMAL:		
        case MS_THERMAL_CT:		
        case MS_TRANSCENTER:	
        case MS_WIRE:			
        case MS_ZEROCOND:		
            {
                if (project.viewPointTextOnly && !isPointFaultAND(control)) {
                    break;
                }
                let nUpTransKind = 0;
                let temp = isSPhaseCircuitEle(control, VOLT_SIDE_SECONDARY, nUpTransKind)
                nUpTransKind = temp.upTransKind !== undefined? temp.upTransKind : 0;
                if (temp.sPhase) {
                    if (nUpTransKind == MS_TRANS1_CONST) {
                        result.sPointText = '';
                        result.sPointState = '';
                        result.sPointSide = '外-外';
                        result = doGetTextSidePointTextAndState(control, result.sPointSide);
                        arrRes.push({id: id, sRefNo: sRefNo,...result});

                        result.sPointSide = '外-中';
                        result=doGetTextSidePointTextAndState(control, result.sPointSide);
                        arrRes.push({id: id, sRefNo: sRefNo,...result});
                    }
                    else {
                        result.sPointText = '';
                        result.sPointState = '';
                        result.sPointSide = '主座';
                        result = doGetTextSidePointTextAndState(control, result.sPointSide);
                        arrRes.push({id: id, sRefNo: sRefNo,...result});
                       
                        result.sPointSide = 'T座';
                        result = doGetTextSidePointTextAndState(control, result.sPointSide);
                        arrRes.push({id: id, sRefNo: sRefNo,...result});
                    }
                }
                else {
                    result.sPointText = '';
                    result.sPointState = '';
                    result.sPointSide = '二次側';
                    result = doGetTextSidePointTextAndState(control, result.sPointSide);

                    arrRes.push({id: id, sRefNo: sRefNo,...result});
                }
            }
            break;
        case MS_MOTOR_GROUP:	
            {
                if (project.viewPointTextOnly && !isPointFaultAND(control)) {
                    break;
                }
                result.sPointText = '';
                result.sPointState = '';
                result.sPointSide = '一次側';
            
                result = doGetTextSidePointTextAndState(control, result.sPointSide);

                arrRes.push({id: id, sRefNo: sRefNo,...result});
            }
            break;
        case MS_3WINDING:		
            {
                if (project.viewPointTextOnly) {
                    result.sPointText = '';
                    result.sPointState = '';
                    result.sPointSide = '';
                    let props = control.properties as Ms3WindingModel;
                    if (props.partSecondary.fault) {
                        result.sPointSide = '二次側';
                        result = doGetTextSidePointTextAndState(control, result.sPointSide);

                        arrRes.push({id: id, sRefNo: sRefNo,...result});
                    }
                    if (props.partThird.fault) {						
                        result.sPointSide = '三次側';
                        result = doGetTextSidePointTextAndState(control, result.sPointSide);

                        arrRes.push({id: id, sRefNo: sRefNo,...result});
                    }
                }
                else {
                    result.sPointText = '';
                    result.sPointState = '';
                    result.sPointSide = '二次側';
                    result = doGetTextSidePointTextAndState(control, result.sPointSide);

                    arrRes.push({id: id, sRefNo: sRefNo,...result});
                    
                    result.sPointSide = '三次側';
                    result = doGetTextSidePointTextAndState(control, result.sPointSide);

                    arrRes.push({id: id, sRefNo: sRefNo,...result});
                }
            }
            break;
        case MS_TRANS1:			
            {
                if (project.viewPointTextOnly) {
                    result.sPointText = '';
                    result.sPointState = '';
                    result.sPointSide = '';
                    let props = control.properties as MsTrans1Model;
                    if (props.partOutOut.fault) {
                        result.sPointSide = '外-外';
                        result = doGetTextSidePointTextAndState(control, result.sPointSide);

                        arrRes.push({id: id, sRefNo: sRefNo,...result});
                    }
                    if (props.partOutIn.fault) {
                        result.sPointSide = '外-中';
                        result = doGetTextSidePointTextAndState(control, result.sPointSide);

                        arrRes.push({id: id, sRefNo: sRefNo,...result});
                    }
                }
                else {
                    result.sPointText = '';
                    result.sPointState = '';
                    result.sPointSide = '外-外';
                    result = doGetTextSidePointTextAndState(control, result.sPointSide);

                    arrRes.push({id: id, sRefNo: sRefNo,...result})
                    
                    result.sPointSide = '外-中';
                    result = doGetTextSidePointTextAndState(control, result.sPointSide);

                    arrRes.push({id: id, sRefNo: sRefNo,...result})
                }
            }
            break;
        case MS_TRANSSCOTT:		
            {
                if (project.viewPointTextOnly) {
                    result.sPointText = '';
                    result.sPointState = '';
                    result.sPointSide = '';
                    let props = control.properties as MsTransScottModel;
                    if (props.partSeatM.fault) {
                        result.sPointSide = '主座';
                        result = doGetTextSidePointTextAndState(control, result.sPointSide);

                        arrRes.push({id: id, sRefNo: sRefNo, ...result});
                    }
                    if (props.partSeatT.fault) {
                        result.sPointSide = 'T座';
                        result = doGetTextSidePointTextAndState(control, result.sPointSide);

                        arrRes.push({id: id, sRefNo: sRefNo, ...result});
                    }
                }
                else {
                    result.sPointText = '';
                    result.sPointState = '';
                    result.sPointSide = '主座';
                    result = doGetTextSidePointTextAndState(control, result.sPointSide);

                    arrRes.push({id: id, sRefNo: sRefNo, ...result});
                    
                    result.sPointSide = 'T座';
                    result = doGetTextSidePointTextAndState(control, result.sPointSide);

                    arrRes.push({id: id, sRefNo: sRefNo, ...result});
                }
            }
            break;
        case MS_CONNECT:		
        case MS_MOTOR:			
        case MS_LOAD:			
        case MS_CAPACITOR:		
        case MS_LOADCENTER:		
        case MS_VCT:			
        case MS_INV:			
        case MS_RELAY:			
        case MS_ARRESTOR:		
        case MS_EARTH:			
        case MS_VT:				
        case MS_CABLEHEAD:		
        case MS_CT:				
        case MS_ZCT:			
        case MS_LIGHTBOARD:		
        case MS_POWERBOARD:		
        case MS_INTO_POINT:		
            break;
    }

    return arrRes;
}

export const handleInitElementListDialog = (elementType:number, transCenter:number, fault:boolean) => {
    let allControl: ControlModel[] = []
   store.getState().app.diagram.diagramData.forEach((e: DiagramModel) => allControl = allControl.concat(e.shape))
   if (allControl.length > 0){
       // element kind
       const dictEleKind: any = elementTypeENtoJP
       let listElementType = allControl.map(e => e.type)
       let uniqElementType = Array.from(new Set(listElementType));
       let checkArr = [MS_MOTOR_GROUP, MS_3WINDING, MS_TRANS1, MS_TRANSSCOTT, MS_SOURCE, MS_GENERATOR, MS_TRANS3, MS_DS, MS_LBS, MS_HVCB, MS_RELAY51, MS_FUSE, MS_REACTOR, MS_2E, MS_BUSBAR, MS_BUSDUCT, MS_CONTACTOR, MS_IMPEDANCE, MS_LVCB, MS_MVCB, MS_THERMAL, MS_THERMAL_CT, MS_TRANSCENTER, MS_WIRE, MS_ZEROCOND];
       uniqElementType = uniqElementType.filter( x => checkArr.includes(x) );
       let newListElementType: OptionModel[] = []
       uniqElementType.map((e) => e !== MS_INTO_POINT && e !== MS_CONNECT && newListElementType.push({
           label: dictEleKind[e] || e,
           value: getElementKindValue(e).toString() || ''
       }))
       //element trans center
       let tmpListTransCenter = allControl.filter(e => e.type === MS_TRANSCENTER)
       let newListTransCenter: OptionModel[] = []
       tmpListTransCenter.map(e => newListTransCenter.push({
           label: e.properties?.refNo,
           value: e.properties?.noTransCenter.toString()
       }))

       //element in trans center
       let  newListEleTransCenter: any[] = [];
       if(transCenter !== 0) {
           let tmpListEleTransCenter = [...allControl.filter((e: any) => e.type === MS_TRANSCENTER)]; 
           let targetTranscenter = [...tmpListEleTransCenter.filter(x => x.properties.noTransCenter === transCenter)];
           let listTCAllElement = [...(store.getState().app.diagram.diagramData.filter((e: any) => e.tabId === targetTranscenter[0]?.properties.tabId)[0]).shape];
           if(elementType !== 0 ) {
               listTCAllElement = [...listTCAllElement.filter(e => e.type === getElementKindLabel(elementType))];   
           }
           if(fault) {
                listTCAllElement = [...listTCAllElement.filter(e => isPointFaultAND(e))];
           }
           listTCAllElement.map(e => {
            let val = doGetInfoElement(e);
            val?.map((x) => {
                e.type !== MS_INTO_POINT && e.type !== MS_CONNECT && newListEleTransCenter.push({
                    id : x?.id,
                    pointText : x?.sPointText,
                    refNo : x?.sRefNo,
                    pointSide : x?.sPointSide,
                    pointState : x?.sPointState
                })
            })
           })
       
       }
   
       // element by type
       let listElement= allControl;
       if(elementType !== 0 ) {
           listElement = [...allControl.filter(e => e.type === getElementKindLabel(elementType))];
       }

       if(fault) {
         listElement = [...listElement.filter(e => isPointFaultAND(e))];
       }
       let newListElement: any[] = []
       listElement.map((e) => {
        let val = doGetInfoElement(e);

        val?.map((x) => {
            e.type !== MS_INTO_POINT && e.type !== MS_CONNECT && newListElement.push({
                id : x?.id,
                pointText : x?.sPointText,
                refNo : x?.sRefNo,
                pointSide : x?.sPointSide,
                pointState : x?.sPointState
            })
        })
       })

       return {
           listElementType : newListElementType,
           listTransCenter : newListTransCenter,
           listEleTransCenter : newListEleTransCenter,
           listElement : newListElement
       }
   }
}

export type ShowPointListProps = {
    title?: string;
    elementGroups:any;
	tcGroups:any;
	transCenterSelected:any;
    onCancel: () => void;
    setLoadingFlag: (flag: boolean) => void;
    scrollToControl: (id: any) => void;
    onSelectGroup: (data: any) => void;
    userId: any,
};

export const ShowPointListDialog = (props: ShowPointListProps) => {
    const {title, elementGroups, tcGroups, transCenterSelected, onCancel, setLoadingFlag, scrollToControl, onSelectGroup, userId} = props;

    const [openShortCircuitCurrentReviewDialog, setOpenShortCircuitCurrentReviewDialog] = useState(false)
    const [selectedElement, setSelectedElement] = useState({})
    const [showList, setShowList] = useState(handleInitElementListDialog(0, 0, false));
    const [checkBox, setCheckBox] = useState(store.getState().app.projectData.viewPointTextOnly);
    const [disableTranscenterCB, setDisableTranscenterCB] = useState(false)
    const [transcenterCB, setTranscenterCB] = useState(0)
	const gridRef = useRef() as any;
    const dispatch = useDispatch();
    const processMode = useSelector((state:any) => state.app.diagram.processMode);
    const diagramData = useSelector((state:any) => state.app.diagram.diagramData);
    const projectId = useSelector((state:any) => state.app.projectData.projectId);
    const projectData = useSelector((state:any) => state.app.projectData);


	const noRowsOverlayComponentParams = useMemo(() => {
		return {
			noRowsMessageFunc: () => NoRowsMessage,
		};
	}, []);

	const noRowsOverlayComponent = useMemo(() => {
		return NoRowsOverlay;
	}, []);

	// 全列に共通の定義
	const defaultColDef = {
		sortable: true, // ソート可
		editable: false, // 編集不可
		resizable: true,
		suppressMovable: true,  // 列の移動を無効にする
	};

	 // 列タイプの定義
	 const columnTypes = {
		nonEditableColumn: { editable: false }, // 編集不可
	};

	 // 列の個別定義
	 const columnDefs = [
        {
            headerName: "事故点名称",
            field: "pointText",
            rowDrag: false,
            flex: 2
        },
        {
            headerName: "デバイス名",
            field: "refNo",
            rowDrag: false,
           flex: 3
        },
        {
            headerName: "サイド",
            field: "pointSide",
            rowDrag: false,
            flex: 2,
        },
        {
            headerName: "事故点設定",
            field: "pointState",
            rowDrag: false,
            flex: 2,
        }
    ];

    const handleElementTypeChange = (e:any) => {
        let type = Number(e.target.value)
        if(getElementKindLabel(type) === MS_GENERATOR || getElementKindLabel(type) === MS_ARRESTOR || getElementKindLabel(type) === MS_SOURCE || getElementKindLabel(type) === MS_EARTH || getElementKindLabel(type) === MS_VCT || getElementKindLabel(type) === MS_TRANSCENTER || getElementKindLabel(type) === MS_MOTOR_GROUP)
		{
			setDisableTranscenterCB(true)
			setValue('transcenter', 0);
			setTranscenterCB(0)
		}
		else
		{
			setDisableTranscenterCB(false)
		}
        setValue('type', type);
        DoGetDetailsData();
    };

    const handleTransCenterChange = (e:any) => {
        setValue('transcenter', e.target.value);
        DoGetDetailsData();
    };

    const handleCheckFault = (e:any) => {
        setCheckBox(!checkBox);
        let temp = store.getState().app.projectData;
        temp.viewPointTextOnly =  !temp.viewPointTextOnly;
        dispatch({type :'SAVE_PROJECT_DATA', payload: {...temp}})
        setValue('fault', !checkBox);
        DoGetDetailsData();
    };

    const DoGetDetailsData = () => {
        let type = getValues('type') === undefined? 0 : getValues('type');
        let transcenter = getValues('transcenter') === undefined? 0 : getValues('transcenter');
        let fault = getValues('fault') === undefined? false : getValues('false');
        setLoadingFlag(true)

        setShowList(handleInitElementListDialog(Number(type), Number(transcenter), fault));

        setLoadingFlag(false)
    }

	const handleSelectRow = (e:any) => {
        let groupsData = store.getState().app.diagram.currentIDDiagramTab === 1 ? elementGroups : tcGroups[transCenterSelected?.id ?? 1]
		if(store.getState().app.diagram.currentIDDiagramTab === 1) {
			let checkTabTranscenter = store.getState().app.diagram.diagramData.find(x => x.shape.find((shapes:any) => shapes.id === e.id))
			if(checkTabTranscenter && checkTabTranscenter?.tabId !== 1) {
				let transcenter = store.getState().app.diagram.diagramData.find(x => x.tabId === 1)?.shape.find((shapes:ControlModel) => shapes.type === MS_TRANSCENTER && shapes.properties.tabId === checkTabTranscenter?.tabId)
				if(transcenter){
                    const peekGroupId = groupsData?.byEleId[e.id as any]?.at(-1) ?? -1
                    if(peekGroupId != -1)
                    {
                        onSelectGroup(transcenter.id)
                    }
                    else
                    {
                        dispatch(updateSelectControl(transcenter.id))
                        onSelectGroup(-1)
                    }
                    scrollToControl(transcenter.id)
                }

			}
			else
			{
                const peekGroupId = groupsData?.byEleId[e.id as any]?.at(-1) ?? -1
				if(peekGroupId != -1)
                {
                    onSelectGroup(e.id)
                }
                else
                {
                    dispatch(updateSelectControl(e.id))
                    onSelectGroup(-1)
                }
                scrollToControl(e.id)
			}
		}
		else
		{
            const peekGroupId = groupsData?.byEleId[e.id as any]?.at(-1) ?? -1
			if(peekGroupId != -1)
            {
                onSelectGroup(e.id)
            }
            else
            {
                dispatch(updateSelectControl(e.id))
                onSelectGroup(-1)
            }
            scrollToControl(e.id)

		}
	}

    const { formState: { errors }, setValue, getValues } = useForm({
        mode: "all",
        criteriaMode: "all",
    })

    const handleShowShortCircuitCurrentReviewDialog = (e:any) =>{
        if(processMode === 4 && e.pointState === "Yes")
        {
            setSelectedElement(e)
            setOpenShortCircuitCurrentReviewDialog(true)
        }
    }

    const handleOKShortCircuitCurrentReview = (data:any) =>{
        setOpenShortCircuitCurrentReviewDialog(false)
        if(data.isUpdate)
        {
            let updatedEle = diagramData.find((x:any)=>x.shape.find((shapes:any)=> Number(shapes.id) === data.id))?.shape.find((shapes:any)=> Number(shapes.id) === data.id)
            if(updatedEle)
            {
                updatedEle = doGetReportDetailCommentToSave(updatedEle as ControlModel, data.examineComment, data.pointSide, data.pointText)
                dispatch(updatePropertiesOfControlAction(updatedEle.id, updatedEle.properties))
                DoGetDetailsData();
                let params:any = {}
                switch(updatedEle.type)
                {
                    case MS_LVCB:
                    case MS_THERMAL:
                    case MS_THERMAL_CT:
                    case MS_2E:
                        params = convertUndefinedToNull({
                            ...updatedEle.properties,
                            downTransKind: Number(getElementKindValue(updatedEle.properties.downTransKind)),
                            upTransKind: Number(getElementKindValue(updatedEle.properties.upTransKind))
                        })
                        break;
                    case MS_FUSE:
                    case MS_HVCB:
                        params = convertUndefinedToNull({
                            ...updatedEle.properties, 
                            downTransKind: Number(getElementKindValue(updatedEle.properties.downTransKind))
                        })
                        break;
                    case MS_3WINDING:
                        params = {
                            no3Winding: updatedEle.properties.no3Winding,
                            refNo: updatedEle.properties.refNo,
                            checkNG: updatedEle.properties.checkNG,
                            eleNeutralPointNumber: updatedEle.properties.eleNeutralPointNumber,
                            supply: updatedEle.properties.supply,
                            tcName: updatedEle.properties.tcName,
                            exchangeNumber: updatedEle.properties.symKind,
                            makeDataInput: updatedEle.properties.makeDataInput,
                            makerName: updatedEle.properties.makerName,
                            makeYear: updatedEle.properties.makeYear,
                            makeMonth: updatedEle.properties.makeMonth,
                            makeNumber: updatedEle.properties.makeNumber,
                            type: updatedEle.properties.type,
                            viewFlowResult: updatedEle.properties.viewFlowResult,
                            primarySupply: updatedEle.properties.primarySupply,
                            seriesed: updatedEle.properties.seriesed,
                            viewResultText: updatedEle.properties.viewResultText,
                            partPrimary: {...updatedEle.properties.partPrimary},
                            partSecondary: {...updatedEle.properties.partSecondary},
                            partThird: {...updatedEle.properties.partThird}, 
                          } 
                          break;
                    case MS_TRANSCENTER:
                        params = convertUndefinedToNull({
                            ...updatedEle.properties, 
                            upTransKind: Number(getElementKindValue(updatedEle.properties.upTransKind))
                        })
                        break;
                    case MS_WIRE:
                        params = {
                        noWire:updatedEle.properties.noWire,
                        refNo:updatedEle.properties.refNo,
                        sPhase:updatedEle.properties.sPhase,
                        rotate:updatedEle.properties.rotate,
                        voltage:updatedEle.properties.voltage,
                        fault:updatedEle.properties.fault,
                        dropRegular:updatedEle.properties.dropRegular,
                        checkNG:updatedEle.properties.checkNG,
                        wireSystem:updatedEle.properties.wireSystem,
                        material:updatedEle.properties.material,
                        insulation:updatedEle.properties.insulation,
                        core:updatedEle.properties.core,
                        method:updatedEle.properties.method,
                        circuitNumber:updatedEle.properties.circuitNumber,
                        ambientTemp:updatedEle.properties.ambientTemp,
                        length:updatedEle.properties.length,
                        dropMotor:updatedEle.properties.dropMotor,
                        eleLineNumber:updatedEle.properties.eleLineNumber,
                        eleTopLeftNumber:updatedEle.properties.eleTopLeftNumber,
                        eleBottomRightNumber:updatedEle.properties.eleBottomRightNumber,
                        perUnitR:updatedEle.properties.perUnitR,
                        perUnitX:updatedEle.properties.perUnitX,
                        perUnitK:updatedEle.properties.perUnitK,
                        lineActiveFlow1:updatedEle.properties.lineActiveFlow1,
                        lineReactiveFlow1:updatedEle.properties.lineReactiveFlow1,
                        lineActiveFlow2:updatedEle.properties.lineActiveFlow2,
                        lineReactiveFlow2:updatedEle.properties.lineReactiveFlow2,
                        exchangeNumber:updatedEle.properties.exchangeNumber,
                        upTransKind:updatedEle.properties.upTransKind,
                        supply:updatedEle.properties.supply,
                        tcName:updatedEle.properties.tcName,
                        makeDataInput:updatedEle.properties.makeDataInput,
                        makerName:updatedEle.properties.makerName,
                        makeYear:updatedEle.properties.makeYear,
                        makeMonth:updatedEle.properties.makeMonth,
                        type:updatedEle.properties.type,
                        voltageMagnitude1:updatedEle.properties.voltageMagnitude1,
                        voltageAngle1:updatedEle.properties.voltageAngle1,
                        voltageMagnitude2:updatedEle.properties.voltageMagnitude2,
                        voltageAngle2:updatedEle.properties.voltageAngle2,
                        pointText:updatedEle.properties.pointText,
                        viewFlowResult:updatedEle.properties.viewFlowResult,
                        inputedCapacity1:updatedEle.properties.inputedCapacity1,
                        inputedCurrent1:updatedEle.properties.inputedCurrent1,
                        inputedPowerFactor1:updatedEle.properties.inputedPowerFactor1,
                        inputedCapacity2:updatedEle.properties.inputedCapacity2,
                        inputedCurrent2:updatedEle.properties.inputedCurrent2,
                        inputedPowerFactor2:updatedEle.properties.inputedPowerFactor2,
                        downTransKind:updatedEle.properties.downTransKind,
                        primarySupply:updatedEle.properties.primarySupply,
                        examineComment:updatedEle.properties.examineComment,
                        examineCommentSPhase:updatedEle.properties.examineCommentSPhase,
                        seriesed:updatedEle.properties.seriesed,
                        viewResultText:updatedEle.properties.viewResultText,
                        voltDropSumRe1:updatedEle.properties.voltDropSumRe1,
                        voltDropSumIm1:updatedEle.properties.voltDropSumIm1,
                        voltDropSumRe2:updatedEle.properties.voltDropSumRe2,
                        voltDropSumIm2:updatedEle.properties.voltDropSumIm2,
                        voltDropRouteRefNo:updatedEle.properties.voltDropRouteRefNo,
                        currentTopLeft:updatedEle.properties.currentTopLeft,
                        flowSusceptance:updatedEle.properties.flowSusceptance,
                        _3Phases:updatedEle.properties._3Phases,
                        PhaseN:updatedEle.properties.PhaseN,
                        PhasePE:updatedEle.properties.PhasePE,
                        calcPoint0:updatedEle.properties.calcPoint0,
                        calcPoint1:updatedEle.properties.calcPoint1,
                        }
                        break;
                    case MS_BUSBAR:
                    case MS_BUSDUCT:
                    case MS_CONTACTOR:
                    case MS_REACTOR:
                    case MS_IMPEDANCE:
                        params = {...updatedEle.properties, rotation: updatedEle.rotation}
                        break;
                    default:
                        params = convertUndefinedToNull({...updatedEle.properties})
                        break;
                }
                
                if(params.details)
                {
                    params = {
                        ...params,
                        details: convertAdjustToCallAPI(structuredClone(params.details))
                    }
                }

                const request = { 
                    userId: userId,
                    projectId: projectId,
                    elementType: getElementKindValue(updatedEle.type),
                    elementId: updatedEle.id,
                    param: {...params},
                    ownerProject: projectData.ownerId
                }
                dispatch(actionCreators.fetch(`UPDATE_ELEMENT`, "/diagram/set-param", "POST", request, false, true))
            }
        }
    }

    return (
        <>
            <Dialog open={true} maxWidth={"sm"} fullWidth
                hideBackdrop 
                PaperComponent={
                    DraggablePaper
                }
            >
                <DialogTitle 
                    onMouseOver={(e : any) => e.target.style.cursor = 'move'}
                    style={{ paddingTop: '2px', paddingBottom: '2px', background: theme.palette.primary.main, color: theme.palette.primary.contrastText }}
                >
                    {title || `事故点リｽト`}
                </DialogTitle>
                <DialogContent >
                    <div style={{width: '100%', height: '500px'}}>
                        <Grid container  mt={1} columnSpacing={2}>
                            <Grid item xs={4} mt={1} >
                                <FormControl fullWidth size="small">
                                    <Select
                                        defaultValue={0}
                                        onChange={handleElementTypeChange}
                                    >
                                        <MenuItem
                                        key={'すべて'}
                                        value={0}
                                        >
                                        {'すべて'}
                                        </MenuItem>
                                        {
                                        showList?.listElementType.map((e) => (
                                        <MenuItem
                                            key={e.label}
                                            value={e.value}
                                        
                                        >
                                            {e.label}
                                        </MenuItem>
                                        ))
                                    }
                                    </Select>
                                </FormControl> 
                            </Grid>
                            <Grid item xs={4} mt={1} >
                                <FormControl fullWidth size="small">
                                    <Select
                                        defaultValue={0}
                                        onChange={handleTransCenterChange}
                                        disabled={disableTranscenterCB}
                                        value={transcenterCB}
                                    >
                                        <MenuItem
                                            key={'すべて'}
                                            value={0}
                                            >
                                            {'すべて'}
                                        </MenuItem>
                                        {
                                            showList?.listTransCenter.map((e) => (
                                                <MenuItem
                                                  key={e.label}
                                                  value={e.value}
                                                
                                                >
                                                  {e.label}
                                                </MenuItem>
                                              ))
                                        }
                                    </Select>
                                </FormControl>
                                
                            </Grid>
                            <Grid item xs={4} mt={1} >
                                <FormControl size="small">
                                    <FormControlLabel control={<Checkbox checked = {checkBox}  onClick={handleCheckFault}/>} label="事故点のみ表示" />
                                </FormControl>
                            </Grid>
                                
                        </Grid>
						<div className="ag-theme-alpine" style={{ height: "370px", width: "100%", marginTop: "15px" }}>
							<AgGridReact
							  ref={gridRef}
							  defaultColDef={defaultColDef}
							  columnTypes={columnTypes}
							  columnDefs={columnDefs}
							  domLayout={"normal"}
							  rowData={getValues('transcenter') === undefined || getValues('transcenter') === 0? showList?.listElement : showList?.listEleTransCenter}
							  animateRows={true}
							  rowDragManaged={true}
							  rowSelection={'single'}
							  suppressMovableColumns={false}
							  suppressDragLeaveHidesColumns={false}
							  localeText={AG_GRID_LOCALE_JP}
							  noRowsOverlayComponent={noRowsOverlayComponent}
							  noRowsOverlayComponentParams={noRowsOverlayComponentParams}
							  onRowClicked={() => {handleSelectRow(gridRef.current.api.getSelectedRows()[0]); setSelectedElement(gridRef.current.api.getSelectedRows()[0])}}
                              onRowDoubleClicked={() => handleShowShortCircuitCurrentReviewDialog(gridRef.current.api.getSelectedRows()[0])}
							/> 
                        </div>
                            
                        <Stack direction='row' justifyContent="flex-end" gap={2} sx={{ mt: 3 }}>
                            <Button onClick={onCancel} color="inherit" variant="contained" >閉じる</Button>
                        </Stack>
                    </div>                    
                </DialogContent>
            </Dialog>

            {openShortCircuitCurrentReviewDialog &&
                <ShortCircuitCurrentReviewDialog element={selectedElement} onOK={(data:any)=> handleOKShortCircuitCurrentReview(data)} onCancel={() => setOpenShortCircuitCurrentReviewDialog(false)} setLoadingFlag={setLoadingFlag}/>
            }
        </>
    )
}
export default ShowPointListDialog;