import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from 'ag-grid-react';
import { forwardRef, useImperativeHandle, useMemo, useRef, useState } from "react";
import { PageData } from '../../../models/Index';
import { AG_GRID_LOCALE_JP } from '../../../themes/localeJP';
import LoadingOverlay, { DefaultLoadingMessage } from '../../common/LoadingOverlay';
import NoRowsOverlay, { NoRowsMessage } from '../../common/NoRowsOverlay';
import PaginationCpn from '../../common/PaginationCpn';
import { formatDatetimeYMDHMS2 } from "../../../utils/DataConverter";

type loginHistoryGridProps = {
    pageSize?: number;
    pageData?: PageData;
    keyword: string;
    openSearch: boolean;
    onChangeSearchCondition: (name: string, value: any) => void;
};

export const loginHistoryGrid = forwardRef((props: loginHistoryGridProps, ref) => {
    const {
        pageSize,
        pageData,
        keyword,
        openSearch,
        onChangeSearchCondition,
    } = props;

    const gridRef = useRef() as any;
    const [rowData, setRowData] = useState();

    useImperativeHandle(ref, () => ({
        setRowData(value: any) {
            setRowData(value)
        }
    }));

    // Loading時のメッセージ
    const loadingOverlayComponent = useMemo(() => {
        return LoadingOverlay;
    }, []);

    const loadingOverlayComponentParams = useMemo(() => {
        return {
            loadingMessage: DefaultLoadingMessage,
        };
    }, []);

    const noRowsOverlayComponent = useMemo(() => {
        return NoRowsOverlay;
    }, []);

    const noRowsOverlayComponentParams = useMemo(() => {
        return {
            noRowsMessageFunc: () => NoRowsMessage,
        };
    }, []);

    const cellRenderHistoryType = (props: any) => {
        return props.data.type == "login"
          ? "ログイン"
          : props.data.type == "logout"
            ? "ログアウト"
            : "";
    }
    const cellRenderDateTime = (props: any) => {
        return formatDatetimeYMDHMS2(props.data.date_time)
    }

    // 全列に共通の定義
    const defaultColDef = {
        sortable: true, // ソート可
        editable: false, // 編集不可
        resizable: true,
        suppressMovable: true,  // 列の移動を無効にする
    };

    // 列タイプの定義
    const columnTypes = {
        nonEditableColumn: { editable: false }, // 編集不可
    };

    // 列の個別定義
    const columnDefs = [
        {
            headerName: "日時",
            field: "date_time",
            rowDrag: false,
            minWidth: 180,
            flex: 1.5,
            cellRenderer: cellRenderDateTime
        },
        {
            headerName: "メールアドレス",
            field: "email",
            rowDrag: false,
            minWidth: 180,
            flex: 1.5,
        },
        {
            headerName: "会社名",
            field: "custom_company_name",
            rowDrag: false,
            minWidth: 180,
            flex: 1.5,
        },
        {
            headerName: "ユーザー名",
            field: "username",
            rowDrag: false,
            minWidth: 180,
            flex: 1.5,
        },
        {
            headerName: "種別",
            field: "type",
            rowDrag: false,
            minWidth: 120,
            flex: 1,
            cellRenderer: cellRenderHistoryType
        },
    ];
    const rowHeight = 48;
    gridRef.current?.api?.setQuickFilter(keyword);

    return (
        <>
            <div>
                <div className="ag-theme-alpine" style={{ height: openSearch ? 'calc(100vh - 400px)' : 'calc(100vh - 300px)', width: "100%" }}>
                    <AgGridReact
                        ref={gridRef}
                        defaultColDef={defaultColDef}
                        columnTypes={columnTypes}
                        columnDefs={columnDefs}
                        domLayout={"normal"}
                        rowData={rowData}
                        rowHeight={rowHeight}
                        rowSelection={'single'}
                        suppressMovableColumns={false}
                        suppressDragLeaveHidesColumns={false}
                        localeText={AG_GRID_LOCALE_JP}
                        paginationPageSize={pageSize}
                        loadingOverlayComponent={loadingOverlayComponent}
                        loadingOverlayComponentParams={loadingOverlayComponentParams}
                        noRowsOverlayComponent={noRowsOverlayComponent}
                        noRowsOverlayComponentParams={noRowsOverlayComponentParams}
                    />
                </div>
                {pageSize && pageData && <PaginationCpn pageData={pageData} onChangeSearchCondition={onChangeSearchCondition} limit={pageSize} />}
            </div>
        </>
    );
});

export default loginHistoryGrid;
