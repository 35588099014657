import { ButtonBase, Checkbox, Grid, Stack, styled, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { isMobile, isTablet } from "react-device-detect";
import icon1 from "../images/icon/Icon_Operation/open_grap.png";
import icon2 from "../images/icon/Icon_Operation/show_line_chart.png";
import icon3 from "../images/icon/Icon_Operation/add_fault.png";
import icon4 from "../images/icon/Icon_Operation/user_curve.png";
import icon5 from "../images/icon/Icon_Operation/rotating_symbols.png";
import icon6 from "../images/icon/Icon_Operation/grouping_symbols.png";
import icon7 from "../images/icon/Icon_Operation/delete_group.png";
import icon8 from "../images/icon/Icon_Operation/turn_on_main_circuit.png";
import icon9 from "../images/icon/Icon_Operation/open_main_circuit.png";
import icon10 from "../images/icon/Icon_Operation/fault_on.png";
import icon11 from "../images/icon/Icon_Operation/fault_off.png";
import icon12 from "../images/icon/Icon_Operation/drop_on.png";
import icon13 from "../images/icon/Icon_Operation/drop_off.png";
import icon14 from "../images/icon/Icon_Operation/improve_on.png";
import icon15 from "../images/icon/Icon_Operation/improve_off.png";
import icon16 from "../images/icon/Icon_Operation/flow_result_on.png";
import icon17 from "../images/icon/Icon_Operation/flow_result_off.png";
import TouchAppRoundedIcon from '@mui/icons-material/TouchAppRounded';
import TouchAppOutlinedIcon from '@mui/icons-material/TouchAppOutlined';
import * as EKind from "../models/ElementKind";
import { ControlModel } from "../models/Index";
import { ApplicationState } from "../store";
import { doGetSupplyEle, isPointFaultAND } from "../utils/element";
import { onCanEditFlowResultOff, onCanEditFlowResultOn, onCanUpdateEditDropOff, onCanUpdateEditDropOn, onCanUpdateEditFaultOff, onCanUpdateEditFaultOn, onCanUpdateEditImproveOff, onCanUpdateEditImproveOn, onCanUpdateEditOff, onCanUpdateEditOn } from "../utils/Mssv3View1";
import { controlCanRotate } from "../components/dxfFile/dataConverter";

export interface DashboardRightProps{
    lineDiagramRef: any;
    onUpdateQuickProperties: (field:string,value:boolean) => any;
    onGroup: () => any;
    onUnGroup: () => any;
    onRotate: () => any;
    onSetLineToChart: (value:boolean,controlId:any) => any;
    onOpenChart: (value:boolean) => any;
    onOpenUserCurve: (controlId:any) => any;
    onSetCalcPointToChart: (isAddCalcPointToChart: boolean, controlId: any) => any;
    onSelectTouchGroup: () => any;
}

const DashboardRight = (props: DashboardRightProps) => {
    //#region Fields
    const { lineDiagramRef,onUpdateQuickProperties,onGroup,onUnGroup,onRotate,onSetLineToChart,onOpenChart,onOpenUserCurve,onSetCalcPointToChart,onSelectTouchGroup } = props;

    let currentIDDiagramTab = useSelector((state:ApplicationState) => state.app.diagram.currentIDDiagramTab);
    let chartEvent = useSelector((state:ApplicationState) => state.app.diagram.chartEvents);
    let diagram = useSelector((state:ApplicationState) => state.app.diagram);
    let diagramDataUpdate = useSelector((state:ApplicationState) => state.app.diagram.diagramDataUpdate);
    const [editFaultOnFlag,setEditFaultOnFlag] = useState<boolean>(false);
    const [editFaultOffFlag,setEditFaultOffFlag] = useState<boolean>(false);
    const [editDropOnFlag,setEditDropOnFlag] = useState<boolean>(false);
    const [editDropOffFlag,setEditDropOffFlag] = useState<boolean>(false);
    const [editImproveOnFlag,setEditImproveOnFlag] = useState<boolean>(false);
    const [editImproveOffFlag,setEditImproveOffFlag] = useState<boolean>(false);
    const [editFlowResutOnFlag,setEditFlowResultOnFlag] = useState<boolean>(false);
    const [editFlowResutOffFlag,setEditFlowResultOffFlag] = useState<boolean>(false);
    const [editUserCurve,setEditUserCurve] = useState<boolean>(false);
    const [checkAbleToShowLineToChart, setCheckAbleToShowLineToChart] = useState(true);
    const [checkAbleToShowCalcPointToChart, setCheckAbleToShowCalcPointToChart] = useState(true);
    const [isDisabledRotate,setIsDisabledRotate] = useState<boolean>(true);
    const [enableEditSwitchOnFlag,setEnableEditSwitchOnFlag] = useState<boolean>(false);
    const [enableEditSwitchOffFlag,setEnableEditSwitchOffFlag] = useState<boolean>(false);
    const [isDisplayName,setIsDisplayName] = useState<boolean>(false);
    const [canGroup,setCanGroup] = useState<boolean>(false);
    const [isSelectTouchGroup,setIsSelectTouchGroup] = useState<boolean>(false);
    const [canUnGroup,setCanUnGroup] = useState<boolean>(false);
    const [currentShape,setCurrentShape] = useState<any>(diagram.diagramData.find(item => item.tabId === currentIDDiagramTab)?.shape.filter((item) => item.isSelected === true));
    const [controlId,setControlId] = useState(currentShape && currentShape.length > 0 ? currentShape[0].id:null);

    const currentTabId = diagram.currentIDDiagramTab
    const currentIDChartTab = diagram.currentIDChartTab;
    const isOpenChart = diagram.isOpenChart;
    const processMode = diagram.processMode;
    const elementGroups=  diagram.elementGroups;
    const tcGroups= diagram.tcGroups;
    const shapes = useSelector((state:ApplicationState) => state.app.diagram.diagramData.filter((r: any) => r.tabId === state.app.diagram.currentIDDiagramTab)[0].shape)

    useEffect(() => {
        //showLineChart
        handleCheckAbleToShowLine();
        handleCheckAbleToShowCalcPoint();
        setEditFaultOnFlag(onCanUpdateEditFaultOn(currentIDDiagramTab,currentShape))
        setEditFaultOffFlag(onCanUpdateEditFaultOff(currentIDDiagramTab,currentShape))
        setEditDropOnFlag(onCanUpdateEditDropOn(currentIDDiagramTab,currentShape))
        setEditDropOffFlag(onCanUpdateEditDropOff(currentIDDiagramTab,currentShape))
        setEditImproveOnFlag(onCanUpdateEditImproveOn(currentIDDiagramTab,currentShape))
        setEditImproveOffFlag(onCanUpdateEditImproveOff(currentIDDiagramTab,currentShape))
        setEditFlowResultOnFlag(onCanEditFlowResultOn(currentIDDiagramTab,currentShape))
        setEditFlowResultOffFlag(onCanEditFlowResultOff(currentIDDiagramTab,currentShape))
        const type = currentShape?.length ? currentShape[0]["type"]: "";
        //Set user curve
        if(["MS_CONNECT","MS_CABLEHEAD","MS_EARTH","MS_TEXT",""].includes(type)){
            setEditUserCurve(false);
        }
        else{
            setEditUserCurve(true)
        }    
        //set rotate
        if(currentShape && currentShape.length > 0 && currentShape.filter((item:any) => item.parentGroupId == null).map((item:any) => item.type).some((type:any) => controlCanRotate.includes(type))){
            setIsDisabledRotate(false)
        }
        else{
            setIsDisabledRotate(true)
        }
        setEnableEditSwitchOnFlag(onCanUpdateEditOn(currentTabId,currentShape))
        setEnableEditSwitchOffFlag(onCanUpdateEditOff(currentTabId,currentShape))
        
    }, [currentShape])


    useEffect(() => {
        const diagramDataList = diagram.diagramData;
        const mainDiagramData = diagramDataList.find((e: any) => e.tabId === 1);
        const transCenter = mainDiagramData?.shape.find((e: any) => e.properties?.tabId === currentTabId);
        const groupsData = currentTabId === 1 ? elementGroups : tcGroups[transCenter?.id ?? ""]
        setCanGroup(isAbleToGroup(groupsData));
        setCanUnGroup(isAbleToUnGroup(groupsData));
    },[tcGroups,elementGroups,currentShape,isSelectTouchGroup])

    useEffect(() => {
        switch(chartEvent.event) {
            case "SELECT_CONTROLS":
                if(!chartEvent.payload.id || chartEvent.payload.id.length == 0)
                {
                    if (currentShape && currentShape.length > 0) {
                        setCurrentShape([]);
                        setControlId(null)
                    }
                }
                else
                {                
                    // const selectedItems = shapes.filter((item) => chartEvent.payload.id.includes(item.id));
                    // setCurrentShape(selectedItems);
                    // setControlId(selectedItems && selectedItems.length > 0 ? selectedItems[0].id:null)

                    const selectedItems = shapes.filter((item) => chartEvent.payload.id.includes(item.id));
                    if (JSON.stringify(selectedItems) !== JSON.stringify(currentShape)) {
                        setCurrentShape(selectedItems);
                        setControlId(selectedItems && selectedItems.length > 0 ? selectedItems[0].id:null)
                    }
                }
        }
    }, [chartEvent,diagramDataUpdate])

	const handleCheckAbleToShowLine = () =>{
        const diagramDataList = diagram.diagramData;
        const chartDataList = diagram.chartData;

        if(currentShape && currentShape.length > 1)
        {
            const arr = [...currentShape];
            for(let i=0; i < arr.length; i++)
            {
                const checkIsShow = chartDataList.find(data => data.tabId === currentIDChartTab)?.shape.find(shapes => shapes.chart.find(charts => (charts.lineType === "USER_CURVE"? false : charts.controlId === arr[i].id) && !charts.lineId.toString().includes("CALC_POINT")))
                const checkEle = diagramDataList.find(data => data.tabId === currentTabId)?.shape.find(shapes => shapes.id === arr[i].id && (shapes.type === EKind.MS_TRANS3 || shapes.type === EKind.MS_3WINDING || shapes.type === EKind.MS_TRANS1 || shapes.type === EKind.MS_TRANSSCOTT || shapes.type === EKind.MS_HVCB || shapes.type === EKind.MS_FUSE || shapes.type === EKind.MS_2E || shapes.type === EKind.MS_LVCB || shapes.type === EKind.MS_THERMAL || shapes.type === EKind.MS_THERMAL_CT || shapes.type === EKind.MS_MOTOR || (shapes.type === EKind.MS_CAPACITOR && (Number(shapes.properties.totalOhmR) > 0 || Number(shapes.properties.totalOhmR) > 0)) || shapes.type === EKind.MS_SOURCE))
                if(checkEle && checkIsShow === undefined && (isOpenChart.payload?.mode || currentIDChartTab > 0)) {
                    setCheckAbleToShowLineToChart(false)
                    break;
                }else{
                    setCheckAbleToShowLineToChart(true);
                }
            }
        }
        
        else
        {
            const isShow = chartDataList.find(data => data.tabId === currentIDChartTab)?.shape.find(shapes => shapes.chart.find(charts => (charts.lineType === "USER_CURVE"? false : charts.controlId === controlId) && !charts.lineId.toString().includes("CALC_POINT")))
            const isCheckEle = diagramDataList.find(data => data.tabId === currentTabId)?.shape.find(shapes => shapes.id === controlId && (shapes.type === EKind.MS_TRANS3 || shapes.type === EKind.MS_3WINDING || shapes.type === EKind.MS_TRANS1 || shapes.type === EKind.MS_TRANSSCOTT || shapes.type === EKind.MS_HVCB || shapes.type === EKind.MS_FUSE || shapes.type === EKind.MS_2E || shapes.type === EKind.MS_LVCB || shapes.type === EKind.MS_THERMAL || shapes.type === EKind.MS_THERMAL_CT || shapes.type === EKind.MS_MOTOR || (shapes.type === EKind.MS_CAPACITOR && (Number(shapes.properties.totalOhmR) > 0 || Number(shapes.properties.totalOhmR) > 0)) || shapes.type === EKind.MS_SOURCE))
            if(isCheckEle && isShow === undefined && (isOpenChart.payload?.mode || currentIDChartTab > 0)) {
                setCheckAbleToShowLineToChart(false)
            }else{
                setCheckAbleToShowLineToChart(true);
            }
        }
    }

    const handleCheckAbleToShowCalcPoint = () =>{
        const chartDataList = diagram.chartData;
        const diagramDataList = diagram.diagramData;
        const chart = chartDataList.find(data => data.tabId === currentIDChartTab)?.shape.find(shapes => shapes.chart.find(charts => charts.controlId === controlId))
        const chooseShape = shapes.filter((item: ControlModel) => item.isSelected === true)[0];
        const isCheckEle = diagramDataList.find(data => data.tabId === currentTabId)?.shape.find(shapes => shapes.id === controlId)
        if(chart === undefined && (isOpenChart.payload?.mode || currentIDChartTab > 0) && processMode > 3 && isPointFaultAND(chooseShape)) {
            if(isCheckEle && doGetSupplyEle(isCheckEle))
            {
                setCheckAbleToShowCalcPointToChart(true)
                //setCheckAbleToShowLineToChart(false)
            }else{
                setCheckAbleToShowCalcPointToChart(false);
            }
        }else{
            setCheckAbleToShowCalcPointToChart(false);
        }
    }

    const ImageButton = styled(ButtonBase)(({ theme }) => ({
        position: 'relative',
        height: 31,
        width: 31,
        ":disabled":{
            opacity: 0.4
        }
    }));

    const ImageSrc = styled('span')({
        position: 'absolute',
        left: 8,
        right: 8,
        top: 8,
        bottom: 8,
        backgroundSize: 'cover',
    });
    const isAbleToGroup = (groupsData:any) => {
        const peekGroupId = groupsData && controlId ? groupsData?.byEleId[controlId as any]?.at(-1) ?? -1 : -1
        const elementIds = groupsData && controlId ? groupsData?.byId[peekGroupId]?.elementIds ?? [] : [];
       
        if (
        shapes.filter((item:any)=> item.isSelected === true).length > 1 &&
        !shapes.filter((item:any)=> item.isSelected === true).every((el: any) => {
            return elementIds.includes(el.id);
        })
        )
        return true;
        return false;
    };

    const isAbleToUnGroup = (groupsData:any) => {
        return groupsData && controlId ? groupsData?.byEleId[controlId as any] && groupsData?.byEleId[controlId as any].length > 0 : false;
    };

    const isAbleToDrawUserCurve = () => {
        return currentShape && currentShape.length === 1 ? true : false;
    }
    const disableOnClick = (disable:any,iconOperation:number) => {
        if(!disable){
            lineDiagramRef?.current.focus()
            switch(iconOperation){
                case 1:
                    setIsSelectTouchGroup(!isSelectTouchGroup)
                    onSelectTouchGroup()
                    break;
                case 2:
                    onSetLineToChart(true,controlId);
                    break;
                case 3:
                    onSetCalcPointToChart(true,controlId);
                    break;
                case 4:
                    onOpenUserCurve(controlId)
                    break;
                case 5:
                    onRotate();
                    break;
                case 6:
                    onGroup();
                    break;
                case 7:
                    onUnGroup();
                    break;
                case 8:
                    onUpdateQuickProperties("switch",true);;
                    break;
                case 9:
                    onUpdateQuickProperties("switch",false);
                    break;
                case 10:
                    onUpdateQuickProperties("fault",true);
                    break;
                case 11:
                    onUpdateQuickProperties("fault",false);
                    break;
                case 12:
                    onUpdateQuickProperties("drop",true);
                    break;
                case 13:
                    onUpdateQuickProperties("drop",false);
                    break;
                case 14:
                    onUpdateQuickProperties("improve",true);
                    break;
                case 15:
                    onUpdateQuickProperties("improve",false);
                    break;
                case 16:
                    onUpdateQuickProperties("view_flow_result",true);
                    break;
                case 17:
                    onUpdateQuickProperties("view_flow_result",false);
                    break;
            }
        }

    }
    const handleCheckDisplayNameDevice = (e:any) =>{
        setIsDisplayName(e.target.checked)
    }
    const handleChangeDisplayNameDevice =(e:any) =>{
        if(isDisplayName) {
            setIsDisplayName(false)
        }
        else {
            setIsDisplayName(true)
        }
    }
return (
        <Grid>
            <Grid mt={1}>
                <Stack sx={{ flexDirection: "row"}}>
                    <Stack style={{width:"64px", paddingLeft:"8px",cursor:'pointer'}} onClick={(e) =>handleChangeDisplayNameDevice(e)}>
                        <Typography variant="caption" style={{paddingLeft:"5px"}}>操作の</Typography>
                        <Typography variant="caption">説明表示</Typography>
                    </Stack>
                    <Checkbox style={{padding:'0'}} checked={isDisplayName} onChange={(e) =>handleCheckDisplayNameDevice(e)}/>
                </Stack>
            </Grid>
            {
                (isMobile || isTablet) &&
                    <>
                    <Grid onClick={() =>{disableOnClick(false,1)}}  title="複数選択" ml={5}>
                    <ImageButton  style={{marginLeft:"5px", marginTop:"3px"}}>
                        {
                            isSelectTouchGroup?
                            <TouchAppRoundedIcon style={{width:'100%',height:'100%', color:"blue"}}/>
                            :
                            <TouchAppOutlinedIcon style={{width:'100%',height:'100%', color:"blue"}}/>
                        }
                    </ImageButton>
                    {isDisplayName &&
                        <Stack>
                            <Typography variant="caption" style={{marginLeft:'-5px',marginTop:'5px',cursor:'pointer'}}>複数選択</Typography>
                        </Stack>
                    }
                    </Grid>
                    </>
            }
            <Grid onClick={() =>{onOpenChart(true)}}  title="保護協調図を追加" ml={5}>
            <ImageButton disabled={isSelectTouchGroup}>
                <ImageSrc style={{width:'80%',height:'80%', backgroundImage: `url(${icon1})`}} />
            </ImageButton>
            {isDisplayName &&
                <Stack>
                    <Typography variant="caption" style={{marginLeft:'-15px',marginTop:'5px',cursor:'pointer'}}>保護協調図を</Typography>
                    <Typography variant="caption" style={{marginLeft:'5px'}}>追加</Typography>
                </Stack>
            }
            </Grid>
            
            <Grid onClick={() => {disableOnClick(checkAbleToShowLineToChart,2)}} title="特性曲線を追加" ml={5}>
              <ImageButton disabled={checkAbleToShowLineToChart || isSelectTouchGroup} >
                    <ImageSrc style={{width:'80%',height:'80%', backgroundImage: `url(${icon2})`}} />
                </ImageButton>
            {isDisplayName &&
                <Stack>
                    <Typography variant="caption" style={{marginLeft:'-25px',marginTop:'5px',color:checkAbleToShowLineToChart?'#666':'',
                        cursor:checkAbleToShowLineToChart?'auto':'pointer'}}>特性曲線を追加</Typography>
                </Stack>
            }
            </Grid>
            <Grid onClick={() => {disableOnClick(!checkAbleToShowCalcPointToChart,3)}} title="短絡電流を追加" ml={5}>
                <ImageButton disabled={!checkAbleToShowCalcPointToChart || isSelectTouchGroup} >
                    <ImageSrc style={{width:'80%',height:'80%', backgroundImage: `url(${icon3})`}} />
                </ImageButton>
            {isDisplayName &&
                <Stack>
                    <Typography variant="caption" style={{marginLeft:'-25px',marginTop:'5px',
                        color:!checkAbleToShowCalcPointToChart?'#666':'',cursor:!checkAbleToShowCalcPointToChart?'auto':'pointer'}}>短絡電流を追加</Typography>
                </Stack>
            }
            </Grid>
            <Grid onClick={() => {disableOnClick(!editUserCurve,4)}}  title="ユーザー曲線" ml={5}>
                <ImageButton disabled={(!editUserCurve ? true : !isAbleToDrawUserCurve()) || isSelectTouchGroup} >
                    <ImageSrc style={{width:'80%',height:'80%', backgroundImage: `url(${icon4})`}} />
                </ImageButton>
            {isDisplayName &&
                <Stack>
                    <Typography variant="caption" style={{marginLeft:'-20px',marginTop:'5px',color:!editUserCurve?'#666':'',cursor:!editUserCurve?'auto':'pointer'}}>ユーザー曲線</Typography>
                </Stack>
            }
            </Grid>
            <Grid  onClick={() => {disableOnClick(isDisabledRotate,5)}} title="シンボルの回転" ml={5}>
                <ImageButton disabled={isDisabledRotate || isSelectTouchGroup || processMode > 1} >
                    <ImageSrc style={{width:'80%',height:'80%', backgroundImage: `url(${icon5})`}} />
                </ImageButton>
            {isDisplayName &&
                <Stack>
                    <Typography variant="caption" style={{marginLeft:'-25px',marginTop:'5px',color:isDisabledRotate?'#666':'',cursor:isDisabledRotate?'auto':'pointer'}}>シンボルの回転</Typography>
                </Stack>
            }
            </Grid>
            <Grid onClick={() => {disableOnClick(!canGroup,6)}} title="シンボルのグループ化" ml={5}>
                <ImageButton disabled={!canGroup || isSelectTouchGroup} >
                    <ImageSrc style={{width:'80%',height:'80%', backgroundImage: `url(${icon6})`}} />
                </ImageButton>
            {isDisplayName &&
                <Stack>
                    <Typography variant="caption" style={{marginLeft:'-10px',marginTop:'5px',color:!canGroup?'#666':'',cursor:!canGroup?'auto':'pointer'}}>シンボルの</Typography>
                    <Typography variant="caption" style={{marginLeft:'-10px',marginTop:'5px',color:!canGroup?'#666':'',cursor:!canGroup?'auto':'pointer'}}>グループ化</Typography>
                </Stack>
            }
            </Grid>
            <Grid onClick={() => {disableOnClick(!canUnGroup,7)}}  title="グループ化の解除" ml={5}>
                <ImageButton disabled={!canUnGroup || isSelectTouchGroup} >
                    <ImageSrc style={{width:'80%',height:'80%', backgroundImage: `url(${icon7})`}} />
                </ImageButton>
            {isDisplayName &&
                <Stack color = {!canUnGroup?'#666':''} style={{cursor:!canUnGroup?'auto':'pointer'}}>
                    <Typography variant="caption" style={{marginLeft:'-10px',marginTop:'5px'}}>グループ化の</Typography>
                    <Typography variant="caption" style={{marginLeft:'5px'}}>解除</Typography>
                </Stack>
            }
            </Grid>
            <Grid onClick={() => {disableOnClick(!enableEditSwitchOnFlag,8)}} title="主回路を投入" ml={5}>
                <ImageButton disabled={!enableEditSwitchOnFlag || isSelectTouchGroup} >
                    <ImageSrc style={{width:'80%',height:'80%', backgroundImage: `url(${icon8})`}} />
                </ImageButton>
            {isDisplayName &&
                <Stack>
                    <Typography variant="caption" style={{marginLeft:'-20px',marginTop:'5px',color:!enableEditSwitchOnFlag?'#666':'',cursor:!enableEditSwitchOnFlag?'auto':'pointer'}}>主回路を投入</Typography>
                </Stack>
            }
            </Grid>
            <Grid onClick={() => {disableOnClick(!enableEditSwitchOffFlag,9)}} title="主回路を開放" ml={5}>
                <ImageButton disabled={!enableEditSwitchOffFlag || isSelectTouchGroup} >
                    <ImageSrc style={{width:'80%',height:'80%', backgroundImage: `url(${icon9})`}} />
                </ImageButton>
            {isDisplayName &&
                <Stack>
                    <Typography variant="caption" style={{marginLeft:'-20px',marginTop:'5px',color:!enableEditSwitchOffFlag?'#666':'',cursor:!enableEditSwitchOffFlag?'auto':'pointer'}}>主回路を開放</Typography>
                </Stack>
            }
            </Grid>
            <Grid onClick={() => {disableOnClick(!editFaultOnFlag,10)}} title="事故点に設定" ml={5}>
                <ImageButton disabled={!editFaultOnFlag || isSelectTouchGroup} >
                    <ImageSrc style={{width:'80%',height:'80%', backgroundImage: `url(${icon10})`}} />
                </ImageButton>
            {isDisplayName &&
                <Stack>
                    <Typography variant="caption" style={{marginLeft:'-20px',marginTop:'5px',color:!editFaultOnFlag?'#666':'',cursor:!editFaultOnFlag?'auto':'pointer'}}>事故点に設定</Typography>
                </Stack>
            }
            </Grid>
            <Grid onClick={() => {disableOnClick(!editFaultOffFlag,11)}} title="事故点を解除" ml={5}>
                <ImageButton disabled={!editFaultOffFlag || isSelectTouchGroup} >
                    <ImageSrc style={{width:'80%',height:'80%', backgroundImage: `url(${icon11})`}} />
                </ImageButton>
            {isDisplayName &&
                <Stack>
                    <Typography variant="caption" style={{marginLeft:'-20px',marginTop:'5px',color:!editFaultOffFlag?'#666':'',cursor:!editFaultOffFlag?'auto':'pointer'}}>事故点を解除</Typography>
                </Stack>
            }
            </Grid>
            <Grid onClick={() => {disableOnClick(!editDropOnFlag,12)}}  title="電圧降下計算点に設定" ml={5}>
                <ImageButton disabled={!editDropOnFlag || isSelectTouchGroup} >
                    <ImageSrc style={{width:'80%',height:'80%', backgroundImage: `url(${icon12})`}} />
                </ImageButton>
            {isDisplayName &&
                <Stack color ={!editDropOnFlag?'#666':''} style={{cursor:!editDropOnFlag?'auto':'pointer'}}>
                    <Typography variant="caption" style={{marginLeft:'-5px',marginTop:'5px'}}>電圧降下</Typography>
                    <Typography variant="caption" style={{marginLeft:'-20px'}}>計算点に設定</Typography>
                </Stack>
            }
            </Grid>
            <Grid onClick={() => {disableOnClick(!editDropOffFlag,13)}} title="電圧降下計算点を解除" ml={5}>
                <ImageButton disabled={!editDropOffFlag || isSelectTouchGroup} >
                    <ImageSrc style={{width:'80%',height:'80%', backgroundImage: `url(${icon13})`}} />
                </ImageButton>
            {isDisplayName &&
                <Stack color ={!editDropOffFlag?'#666':''} style={{cursor:!editDropOffFlag?'auto':'pointer'}}>
                    <Typography variant="caption" style={{marginLeft:'-5px',marginTop:'5px'}}>電圧降下</Typography>
                    <Typography variant="caption" style={{marginLeft:'-20px'}}>計算点を解除</Typography>

                </Stack>
            }
            </Grid>
            <Grid onClick={() => {disableOnClick(!editImproveOnFlag,14)}} title="力率改善計算点に設定" ml={5}>
                <ImageButton disabled={!editImproveOnFlag || isSelectTouchGroup} >
                    <ImageSrc style={{width:'80%',height:'80%', backgroundImage: `url(${icon14})`}} />
                </ImageButton>
            {isDisplayName &&
                <Stack color ={!editImproveOnFlag?'#666':''} style={{cursor:!editImproveOnFlag?'auto':'pointer'}}>
                    <Typography variant="caption" style={{marginLeft:'-5px',marginTop:'5px'}}>力率改善</Typography>
                    <Typography variant="caption" style={{marginLeft:'-20px'}}>計算点に設定</Typography>

                </Stack>
            }
            </Grid>
            <Grid onClick={() => {disableOnClick(!editImproveOffFlag,15)}} title="力率改善計算点を解除" ml={5}>
                <ImageButton disabled={!editImproveOffFlag || isSelectTouchGroup} >
                    <ImageSrc style={{width:'80%',height:'80%', backgroundImage: `url(${icon15})`}} />
                </ImageButton>
            {isDisplayName &&
                <Stack color ={!editImproveOffFlag?'#666':''} style={{cursor:!editImproveOffFlag?'auto':'pointer'}}>
                    <Typography variant="caption" style={{marginLeft:'-5px',marginTop:'5px'}}>力率改善</Typography>
                    <Typography variant="caption" style={{marginLeft:'-20px'}}>計算点を解除</Typography>

                </Stack>
            }
            </Grid>
            <Grid onClick={() => {disableOnClick(!editFlowResutOnFlag,16)}}  title="潮流解析結果を表示" ml={5}>
                <ImageButton disabled={!editFlowResutOnFlag || isSelectTouchGroup} >
                    <ImageSrc style={{width:'80%',height:'80%', backgroundImage: `url(${icon16})`}} />
                </ImageButton>
            {isDisplayName &&
                <Stack color ={!editFlowResutOnFlag?'#666':''} style={{cursor:!editFlowResutOnFlag?'auto':'pointer'}}>
                    <Typography variant="caption" style={{marginLeft:'-5px',marginTop:'5px'}}>潮流解析</Typography>
                    <Typography variant="caption" style={{marginLeft:'-10px'}}>結果を表示</Typography>
                </Stack>
            }
            </Grid>
            <Grid onClick={() => {disableOnClick(!editFlowResutOffFlag,17)}} title="潮流解析結果を非表示" ml={5}>
                <ImageButton  disabled={!editFlowResutOffFlag || isSelectTouchGroup} >
                    <ImageSrc style={{width:'80%',height:'80%', backgroundImage: `url(${icon17})`}} />
                </ImageButton>
            {isDisplayName &&
                <Stack color ={!editFlowResutOffFlag?'#666':''} style={{cursor:!editFlowResutOffFlag?'auto':'pointer'}}>
                    <Typography variant="caption" style={{marginLeft:'-5px',marginTop:'5px'}}>潮流解析</Typography>
                    <Typography variant="caption" style={{marginLeft:'-20px'}}>結果を非表示</Typography>
                </Stack>
            }
            </Grid>
        </Grid>
)
}

export default DashboardRight;