import { store } from "..";
import { ELEMENT_SIZE, POINTER_HEIGHT, TC_INFO_TABLE_HEIGHT } from "../models/Constants";
import { ProcessMode } from "../models/Index";

export function calcContextMenuPosition(
    menu: any,
    menuHeight: number,
    clientWidth: number,
    elementX: number,
    elementY: number,
    gridSizeCtrl: any,
    isTransCenter: boolean
) {
    let posX, posY;
    if (menu.type === "contextmenu"){
        // posX = menu.evt.clientX + 220 > clientWidth
        //     ? menu.evt.offsetX - gridSizeCtrl.stageOffsetX
        //     : menu.evt.offsetX - gridSizeCtrl.stageOffsetX;
        posX = menu.evt.clientX && menu.evt.clientX + 250 > window.innerWidth ? menu.evt.offsetX - gridSizeCtrl.stageOffsetX - 250 : menu.evt.offsetX - gridSizeCtrl.stageOffsetX;
        
        posY = (isTransCenter? menu.evt.clientY - 90 - TC_INFO_TABLE_HEIGHT <= menuHeight :  menu.evt.clientY - 90 <= menuHeight)
            ? ( menu.evt.clientY ? (isTransCenter ?  menu.evt.offsetY - gridSizeCtrl.stageOffsetY - menu.evt.clientY + 150 + TC_INFO_TABLE_HEIGHT : menu.evt.offsetY - gridSizeCtrl.stageOffsetY - menu.evt.clientY + 150) : menu.evt.offsetY - gridSizeCtrl.stageOffsetY )
            : menu.evt.offsetY - gridSizeCtrl.stageOffsetY - (menuHeight * 0.9 * gridSizeCtrl.scale);
    
        // if (gridSizeCtrl.scale > 1){
        //     posX /= gridSizeCtrl.scale;
        //     posY /= gridSizeCtrl.scale;
        // }
       
        posX /= gridSizeCtrl.scale;
        posY /= gridSizeCtrl.scale;
        
    } else {
        // touchend
        if('changedTouches' in menu.evt)
        {
            if(isTransCenter ? menu.evt.changedTouches[0].clientY > ((window.innerHeight + TC_INFO_TABLE_HEIGHT) / 2) :  menu.evt.changedTouches[0].clientY > (window.innerHeight / 2))
            {
                posX = menu.evt.changedTouches[0].clientX && menu.evt.changedTouches[0].clientX + 250 > window.innerWidth ? elementX + 15 - 250 : elementX + 15
                posY = !isTransCenter ? (menu.evt.changedTouches[0].clientY - 150 > menuHeight? elementY + 15 - menuHeight : elementY + 15 - menuHeight + (menuHeight -  (menu.evt.changedTouches[0].clientY - 150)))
                    : (menu.evt.changedTouches[0].clientY - 150 - TC_INFO_TABLE_HEIGHT > menuHeight? elementY + 15 - menuHeight : elementY + 15 - menuHeight + (menuHeight -  (menu.evt.changedTouches[0].clientY - 150 - TC_INFO_TABLE_HEIGHT)))
            }
            else
            {
                posX = menu.evt.changedTouches[0].clientX && menu.evt.changedTouches[0].clientX + 250 > window.innerWidth ? elementX + 15 - 250 : elementX + 15
                posY = window.innerHeight - menu.evt.changedTouches[0].clientY > menuHeight ? elementY + 15 : elementY + 15 - (menuHeight - ( window.innerHeight - menu.evt.changedTouches[0].clientY))
            }
        }
        else
        {
            posX = elementX + 15
            posY = elementY + 15
        }
    }

    return {posX, posY}
}

export function calcDragBoundPosition(
    target: any,
    grpPos: any,
    rotation: number,
    height: number,
    mode: string,
    gridSizeCtrl: any,
    isHVCB: boolean = false,
) {
    let newX = 0;
    let newY = 0;
    let endPosition = 0;
    let scalePosition = 0; // position in grid
    let minHeight = ELEMENT_SIZE;
    let tmpHeight = height;
    // vertical
    if (rotation === 0) {
        scalePosition = target.y / gridSizeCtrl.scale;
        newX = (grpPos.x + gridSizeCtrl.stageOffsetX + 6.75) * gridSizeCtrl.scale;
        if (mode === "top") {
            endPosition = grpPos.y + gridSizeCtrl.stageOffsetY + height;
            tmpHeight = Math.max(endPosition - scalePosition, minHeight);
            newY = (endPosition - tmpHeight) * gridSizeCtrl.scale;
        } else {
            tmpHeight = Math.max(scalePosition - (grpPos.y + gridSizeCtrl.stageOffsetY), minHeight - 7);
            newY = (grpPos.y + gridSizeCtrl.stageOffsetY + tmpHeight) * gridSizeCtrl.scale;
        }
    }
    // horizontal
    else if (rotation === -90) {
        scalePosition = target.x / gridSizeCtrl.scale;
        if (isHVCB) {
            newY = (grpPos.y + gridSizeCtrl.stageOffsetY + 33.5) * gridSizeCtrl.scale;
        } else {
            newY = (grpPos.y + gridSizeCtrl.stageOffsetY + 13.5) * gridSizeCtrl.scale;
        }

        if (mode === "top") {
            endPosition = grpPos.x + gridSizeCtrl.stageOffsetX + height;
            tmpHeight = Math.max(endPosition - scalePosition, minHeight);
            newX = (endPosition - tmpHeight) * gridSizeCtrl.scale;
        } else {
            tmpHeight = Math.max(scalePosition - (grpPos.x + gridSizeCtrl.stageOffsetX), minHeight - 7);
            newX = (grpPos.x + gridSizeCtrl.stageOffsetX + tmpHeight) * gridSizeCtrl.scale;
        }
    } else if (rotation === 90) {
        scalePosition = target.x / gridSizeCtrl.scale;
        newY = (grpPos.y + gridSizeCtrl.stageOffsetY + 6.75) * gridSizeCtrl.scale;
        if (mode === "top") {
            tmpHeight = Math.max(scalePosition - (grpPos.x + gridSizeCtrl.stageOffsetX), minHeight);
            newX = (grpPos.x + gridSizeCtrl.stageOffsetX + tmpHeight) * gridSizeCtrl.scale;
        } else {
            endPosition = grpPos.x + gridSizeCtrl.stageOffsetX + height;
            tmpHeight = Math.max(endPosition - scalePosition, minHeight - 7);
            newX = (endPosition - tmpHeight) * gridSizeCtrl.scale;
        }
    }
    return {newX, newY}
}

export function calcElementSize(
    newPosX: number,
    newPosY: number,
    grpPos: any,
    rotation: number,
    height: number,
    offsetY: number,
    mode: string,
    gridSizeCtrl: any
) {
    let newHeight = height;
    let newX = grpPos.x;
    let newY = grpPos.y;
    let newOffsetY = offsetY;
    let endPosition = 0;
    let scalePostion = 0;

    if (mode === "top"){
        // vertical
        if (rotation === 0){
            scalePostion = (newPosY - gridSizeCtrl.stageOffsetY) / gridSizeCtrl.scale;
            endPosition = grpPos.y + height;
            newHeight = roundFunc(Math.max(endPosition - scalePostion, ELEMENT_SIZE));
            newY = endPosition - newHeight;
        } 
        // horizontal
        else if (rotation === -90) {
            scalePostion = (newPosX - gridSizeCtrl.stageOffsetX) / gridSizeCtrl.scale;
            endPosition = grpPos.x + height;
            newHeight = roundFunc(Math.max(endPosition - scalePostion, ELEMENT_SIZE));
            newX = endPosition - newHeight;
        } else if (rotation === 90) {
            scalePostion = (newPosX - gridSizeCtrl.stageOffsetX) / gridSizeCtrl.scale;
            newHeight = roundFunc(Math.max(scalePostion - grpPos.x, ELEMENT_SIZE));
            newOffsetY = newHeight;
        }
    } else {
        //--- bottom ---//
        // vertical
        if (rotation === 0){
            scalePostion = (newPosY - gridSizeCtrl.stageOffsetY) / gridSizeCtrl.scale;
            newHeight = roundFunc(Math.max(scalePostion - grpPos.y, ELEMENT_SIZE));
        } 
        // horizontal
        else if (rotation === -90) {
            scalePostion = (newPosX - gridSizeCtrl.stageOffsetX) / gridSizeCtrl.scale;
            newHeight = roundFunc(Math.max(scalePostion - grpPos.x, ELEMENT_SIZE));
        } else if (rotation === 90) {
            scalePostion = (newPosX - gridSizeCtrl.stageOffsetX) / gridSizeCtrl.scale;
            endPosition = grpPos.x + height;
            newHeight = roundFunc(Math.max(endPosition - scalePostion, ELEMENT_SIZE));
            newX = endPosition - newHeight;
            newOffsetY = newHeight;
        }
    }
    return {newHeight, newX, newY, newOffsetY}
}

export function getMenuOptionHeight() {
    const processMode = store.getState().app.diagram.processMode
    const m_bModePM = store.getState().app.diagram.m_bModePM
    const modeViewOnly = store.getState().app.diagram.modeViewOnly
    let menuHeight = 0
    switch(processMode){
        case ProcessMode.DRAWING:
            menuHeight = m_bModePM || modeViewOnly ? 70 : 436
            break;
        case ProcessMode.CHECKING:
        case ProcessMode.PERCENT_Z:
            menuHeight = m_bModePM || modeViewOnly ? 304: 504
            break;
        case ProcessMode.CALCULATION:
        case ProcessMode.IMPROVE_PF:
            menuHeight = m_bModePM || modeViewOnly ? 170 : 302
            break;
        case ProcessMode.VOLTDROP:
            menuHeight = m_bModePM || modeViewOnly ? 272 : 404
            break;
        case ProcessMode.HARMONIC:
            menuHeight = m_bModePM || modeViewOnly ? 33 : 235
            break;
        case ProcessMode.POWER_FLOW:
                menuHeight = m_bModePM || modeViewOnly ? 33 : 369
    }
    return menuHeight;
}

const roundFunc = (num: number) => {
    return Math.round(num / POINTER_HEIGHT) * POINTER_HEIGHT; 
}