import { LoadingButton } from "@mui/lab";
import { Box, Button, Checkbox, CircularProgress, FormControlLabel, Grid, Stack, TextField, Typography } from "@mui/material";
import { useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { EquipLoadModel } from "../../../models/breakModel";
import { Path as RoutePath } from "../../../path";
import { ApplicationState, actionCreators, selectors } from "../../../store";
import FetchHelper from "../../FetchHelper";
//-----------
//FETCH ID
const GET_EQUIP_LOAD = "GET_EQUIP_LOAD"
const UPDATE_EQUIP_LOAD = "UPDATE_EQUIP_LOAD"
const CREATE_EQUIP_LOAD = "CREATE_EQUIP_LOAD"

const initialValue:EquipLoadModel = {
    lLoadId:0,
    dLoadCurrent:0,
    dPowerFactor:0,
    dStartingCurrent:0,
    dStartingTime:0,
}
// -------------
// Props
export type EquipBusductEditProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
export const EquipLoadEdit = (props: EquipBusductEditProps) => {
    const {
        userId,
        onCreateError,
        onUpdate, onUpdateError,
        onSuccess, onError,
        onCreate
    } = props;

    const { control, formState: { errors }, handleSubmit, setValue, getValues, } = useForm<EquipLoadModel>({
        criteriaMode: "all",
        mode:"all",
        defaultValues: initialValue,
    })
    type MyParams = {
        id: string;
        mode: string;
    };
    let { id, mode } = useParams<keyof MyParams>() as MyParams;
    let navigate = useNavigate();
    const [loadingFlag, setLoadingFlag] = useState(mode === "edit" ? true : false);
    const gridRef = useRef() as any;
    const Exp = /^[-+]?\d+(\.\d+)?$/;
    const messageIsNotNumber = "数字を入力してください。";

    const handleSuccess = (data: any) => {
        setLoadingFlag(false);
        if(data && data.resultCode == 0){
            setValue("lLoadId",data.data.lLoadId);
            setValue("dLoadCurrent",data.data.dLoadCurrent?? "");
            setValue("dPowerFactor",data.data.dPowerFactor?? "");
            setValue("dStartingCurrent",data.data.dStartingCurrent?? "");
            setValue("dStartingTime",data.data.dStartingTime?? "");
        }
    }

    // 検索エラー時
    const handleError = (success: boolean, data: any, error: any) => {
        setLoadingFlag(false);
        console.log('>>>> handle Error');
        gridRef.current.hideOverlay();
    };

    // 検索実行
    const handleEditSubmit = (data: EquipLoadModel) => {
        setLoadingFlag(true);
        if(mode === "edit") {
            let request = {
                lLoadId:id,
                dLoadCurrent:data.dLoadCurrent,
                dPowerFactor:data.dPowerFactor,
                dStartingCurrent:data.dStartingCurrent,
                dStartingTime:data.dStartingTime,
            } as any
            const object = {
                requestPrms:request,
                userId: userId,
            }
            onUpdate(object);
        } else if(mode === "add") {
            let request = {
                dLoadCurrent:data.dLoadCurrent,
                dPowerFactor:data.dPowerFactor,
                dStartingCurrent:data.dStartingCurrent,
                dStartingTime:data.dStartingTime,
            } as any
            const object = {
                requestPrms:request,
                userId: userId,
            }
            onCreate(object);
        }
    }

    // キャンセル
    const handleCancel = () => {
        navigate(RoutePath.EquipLoadList);
    }

    // Submit後のAPI呼び出しエラー時
    const handleErrorUpdate = (success: boolean, data: any, error: any) => {
        onUpdateError();
        setLoadingFlag(false);
    }

    // Submit後のAPI呼び出し成功時
    const handleSuccessSubmit = (success: boolean, data: any, error: any) => {
        onSuccess();
        if (mode === "add") {
            navigate(RoutePath.EquipLoadEdit + `/edit/${data.data.lLoadId}`, { replace: true })
        }
        setLoadingFlag(false)
    }

    // Submit後のAPI呼び出しエラー時
    const handleSubmitError = (success: boolean, data: any, error: any) => {
        onCreateError();
        setLoadingFlag(false);
    }

    //入力欄背景
    const divStyles = {
        backgroundColor: 'white',
        border: '1px solid #bebebe',
        boxShadow: '0 0 5px #fff, 0 0 5px #bebebe, 0 0 1px #aaa',
        padding: '20px 40px',
        marginTop: '40px',
    }

    return (
        <>
            {
                mode === "edit" && 
                <FetchHelper
                fetchId={GET_EQUIP_LOAD}
                url={`/master/equip_load/get`}
                method="POST"
                json={true}
                request={true}
                params={{
                    requestPrms:{
                        id:id
                    },
                    userId: userId,
                }}
                onComplete={(success, data, error) => {
                    success ? handleSuccess(data) : handleError(success, data, error);
                }}
            />
            }
            {loadingFlag && (
                <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1500, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                    <CircularProgress />
                </div>
            )}
            <div style={{ height: "100%", width: "100%", marginTop: "10px" }}>
                <Stack direction="row" justifyContent="flex-start" alignItems="center" sx={{}} style={{ margin: '18px', color: 'MenuText' }}>
                    <Typography variant="h4">
                        {/* FIXME: */}
                        負荷詳細
                    </Typography>
                </Stack>
                <form onSubmit={handleSubmit(handleEditSubmit)}>
                    <div style={divStyles}>
                        <Typography variant="h6">
                            {/* FIXME: */}
                            負荷
                        </Typography>
                        <Stack mt={2} direction={{ xs: 'column', sm: 'row' }} sx={{maxHeight: "calc(100vh)"}}>
                            <Grid width={"100%"} spacing={1}>
                                <Grid pt={1} spacing={2} container direction={"row"}>
                                    <Grid item xs={6}>
                                        <Controller
                                            control={control}
                                            name="dLoadCurrent"
                                            rules={{
                                                pattern: {
                                                    value: Exp,
                                                    message: messageIsNotNumber,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.dLoadCurrent}
                                                    helperText={errors?.dLoadCurrent?.message}
                                                    label="Load current IL（A）"
                                                    type="text"
                                                    size="small"

                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            control={control}
                                            name="dPowerFactor"
                                            rules={{
                                                pattern: {
                                                    value: Exp,
                                                    message: messageIsNotNumber,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.dPowerFactor}
                                                    helperText={errors?.dPowerFactor?.message}
                                                    label="力率（%）"
                                                    type="text"
                                                    size="small"

                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid mt={0} spacing={2} container direction={"row"}>
                                    <Grid item xs={6}>
                                        <Controller
                                            control={control}
                                            name="dStartingCurrent"
                                            rules={{
                                                pattern: {
                                                    value: Exp,
                                                    message: messageIsNotNumber,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.dStartingCurrent}
                                                    helperText={errors?.dStartingCurrent?.message}
                                                    label="始動電流(ILの倍数）"
                                                    type="text"
                                                    size="small"

                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            control={control}
                                            name="dStartingTime"
                                            rules={{
                                                pattern: {
                                                    value: Exp,
                                                    message: messageIsNotNumber,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.dStartingTime}
                                                    helperText={errors?.dStartingTime?.message}
                                                    label="始動時間"
                                                    type="text"
                                                    size="small"

                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Stack>
                    </div>
                    <Stack direction='row' justifyContent="flex-end" spacing={2} sx={{ mt: 1, mr: 2 }}>
                        <LoadingButton variant="contained" type="submit" loading={loadingFlag}>保存</LoadingButton>
                        <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                    </Stack>
                </form>
            </div>
            <FetchHelper fetchId={UPDATE_EQUIP_LOAD}
                onComplete={(success: boolean, data: any, error: any) =>
                    success ? handleSuccessSubmit(success, data, error) : handleErrorUpdate(success, data, error)} />
             <FetchHelper fetchId={CREATE_EQUIP_LOAD}
                onComplete={(success, data, error) => success ?
                    handleSuccessSubmit(success, data, error) :
                    handleSubmitError(success, data, error)} />

        </>
    );
}
const mapStateToProps = (state: ApplicationState) => (
    {
        userId: state.app.user?.userId,
        fetchUpdate: selectors.getFormState(
            state,
            // GET_BREAK_CAPACITY
            UPDATE_EQUIP_LOAD
        ),
    }
)
const mapDispatchToProps = (dispatch: any) => {
    return {
        onCreate: (data: any) => dispatch(actionCreators.fetch(CREATE_EQUIP_LOAD, `/master/equip_load/add`, "POST", data, false, true)),
        onUpdate: (data: any) => dispatch(actionCreators.fetch(UPDATE_EQUIP_LOAD, `/master/equip_load/modify`, "POST", data,false,true)),
        onSuccess: () =>
            dispatch(actionCreators.showMessage({
                type: "info",
                title: "負荷更新",
                body: "負荷を更新しました。",
            })),
        onError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "検索",
                body: "検索できません。",
            })),
        onCreateError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "負荷登録",
                body: "負荷を登録出来ませんでした。",
            })),
        onUpdateError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "負荷編集",
                body: "負荷を更新出来ませんでした。",
            })),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(EquipLoadEdit as any);