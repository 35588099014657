import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionCreators, ApplicationState, selectors } from "../store";

export type FormErrors<T> = {
    [key in keyof T]?: string;
};

export type FormTouched<T> = {
    [key in keyof T]?: boolean;
};

export interface FormOptions<T> {
    formId: string;
    initialValue: T;
    validator: (value: T) => FormErrors<T>;
    onSubmit: (value: T) => void;
    onChanged?: (name: string, value: string) => void;
}

export interface FormContext<T> {
    values: T;
    errors: FormErrors<T>;
    touches: FormTouched<T>;
    isValid: boolean;
    isSubmitting: boolean;
    isValidating: boolean;
    handleBlur: React.FocusEventHandler<any>;
    handleChange: React.ChangeEventHandler<any>;
    handleSubmit: React.FormEventHandler<HTMLFormElement>;
    handleReset: (e: React.SyntheticEvent<any>) => void;
    setValue: (name: string, newValue: any) => void;
    setTouched: (name: string) => void;
    setSubmitting: (submitting: boolean) => void;
    resetValues: () => void;
    initValues: (formId: string, values: any) => void;
}

export function useForm<T>(option: FormOptions<T>): FormContext<T> {
    const { formId, initialValue, validator, onSubmit, onChanged } = option;
    const dispatch = useDispatch();

    const handleInitialize = () => {
        dispatch(actionCreators.initForm(formId, initialValue));
        return () => {
            dispatch(actionCreators.discardForm(formId));
        };
    };
    // eslint-disable-next-line
    React.useEffect(handleInitialize, []);

    const formState = useSelector((state: ApplicationState) =>
        selectors.getFormState(state, formId)
    );

    const handleValidating = () => {
        if (formState.validating) {
            dispatch(actionCreators.validateForm(formId, validator(formState.value)));
        }
    };
    // eslint-disable-next-line
    React.useEffect(handleValidating, [formState.validating]);

    const handleSubmit = () => {
        if (formState.submitting) {
            onSubmit && onSubmit(formState.value);
        }
    };
    // eslint-disable-next-line
    React.useEffect(handleSubmit, [formState.submitting]);

    return {
        values: formState.value || initialValue,
        errors: formState.errors as any,
        touches: formState.touched,
        isValid: formState.valid,
        isSubmitting: formState.submitting,
        isValidating: formState.validating,
        handleBlur: (e) => {
            dispatch(actionCreators.touchForm(formId, e.target.name));
            onChanged && onChanged(e.target.name, e.target.value);
        },
        handleChange: (e) => {
            dispatch(
                actionCreators.changeForm(formId, e.target.name, e.target.value)
            );
        },
        handleSubmit: (e) => {
            e.preventDefault();
            dispatch(actionCreators.submitForm(formId));
        },
        handleReset: (e) => {
            e.preventDefault();
            dispatch(actionCreators.resetForm(formId));
        },
        setValue: (name, newValue) => {
            dispatch(actionCreators.changeForm(formId, name, newValue));
            onChanged && onChanged(name, newValue);
        },
        setTouched: (name) => {
            dispatch(actionCreators.touchForm(formId, name));
        },
        setSubmitting: (submitting) => {
            if (submitting) {
                dispatch(actionCreators.submitForm(formId));
            } else {
                dispatch(actionCreators.submissionComplete(formId));
            }
        },
        resetValues: () => {
            dispatch(actionCreators.resetForm(formId));
        },
        initValues: (formId, values) => {
            dispatch(actionCreators.initForm(formId, values));
            return () => {
                dispatch(actionCreators.discardForm(formId));
            };
        }
    };
}

export default useForm;
