import { CircularProgress, Dialog, DialogContent, DialogTitle } from "@mui/material";
import Konva from "konva";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { Group, Image, Label, Rect, Text, Transformer } from "react-konva";
import { Html, useImage } from "react-konva-utils";
import { connect } from "react-redux";
import leftArrow from "../../images/control/blue_arrow.svg";
import lineSvg from "../../images/control/line.svg";
import lineGreenSvg from "../../images/control/line_green.svg";
import lineRedSvg from "../../images/control/line_red.svg";
import rightArrow from "../../images/control/red_arrow.svg";
import lineOhmSvg from "../../images/control/インピーダンス＿オーム＿R.svg";
import lineOhmGreenSvg from "../../images/control/インピーダンス＿オーム＿R_green.svg";
import lineOhmRedSvg from "../../images/control/インピーダンス＿オーム＿R_red.svg";
import lineOhmPercentZ from "../../images/control/インピーダンス＿オーム＿X＿R.svg";
import lineOhmPercentZRed from "../../images/control/インピーダンス＿オーム＿X＿R_red.svg";
import lineOhmPercentZGreen from "../../images/control/インピーダンス＿オーム＿X＿R_green.svg";
import * as Constant from "../../models/Constants";
import * as Model from "../../models/Index";
import { CComplex, Coordinates, LineInfoModel, LineType, MsBusbarModel, OptionModel } from "../../models/Index";
import {
  addUserCurve,
  clearOptionMenu,
  deleteRelatedGraphByUserCurveDialog,
  deleteUserCurve,
  openChart,
  openDialogEvents,
  openUserCurveEvents,
  openUserCurvePropertiesEvents,
  setRepositionElement,
  setTransformFlag,
  undo,
  updateControlHeight,
  updateElementPosition,
  updatePropertiesOfControlAction,
  updatePropertiesOfTransCenter,
  updateSelectControl,
  updateUserCurve,
} from "../../store/Actions";
import theme from "../../themes/globalStyles";
import { addListToData } from "../../utils/DataConverter";
import { getElementKindValue } from "../../utils/ElementFunction";
import { BeamsNumber } from "../../utils/FormatNumber";
import { doGetValuesTransCenter } from "../../utils/TransCenterFunction";
import { doGetRefV1 } from "../../utils/busbarFunction";
import { getSymbolAndBackColor } from "../../utils/drawElement";
import doGetSourceVolt, { doGetTransCenterSourceVolt } from "../../utils/mssv3Doc";
import DraggablePaper from "../common/DraggablePaper";
import OptionMenu from "../contextMenu/OptionMenu";
import MsBusbarEditDialog from "../dialogs/MsBusbarEditDialog";
import UserCurveDetailDialog from "../dialogs/UserCurveDetailDialog";
import { getElements, post } from "../CallApi";
import UserCurvesDialog from "../dialogs/UserCurvesDialog";
import { calcContextMenuPosition, calcDragBoundPosition, calcElementSize, getMenuOptionHeight } from "../../utils/PositionCalculation";
import { actionCreators } from "../../store";
import { doDrawSymbolGroup } from "../graph/DCView";
import { MS_MODE_POWER_FLOW } from "../../statics";

//#region Props
type CreateMsBusbarProps = {
  id: string;
  image: string;
  x: number;
  y: number;
  offsetX: number;
  offsetY: number;
  width: number;
  height: number;
  type: string;
  isSelected: boolean;
  isHidden: boolean;
  rotation: number;
  lineDiagramRef: any;
  parentGroupId: number | null;
  chart: LineInfoModel[];
  properties: MsBusbarModel;
  gridSizeController: {
    width: number;
    height: number;
    scale: number;
    stageOffsetX: number;
    stageOffsetY: number;
  };
  isOnShiftKey: boolean;
};

export type PureCreateMsBusbarProps = CreateMsBusbarProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;
//#endregion

const CONTROL_POS_FACTOR = 1;

const CreateMsBusbar = memo((props: PureCreateMsBusbarProps) => {
  //#region Fields
  const {
    id,
    image,
    x,
    y,
    offsetX,
    offsetY,
    width,
    height,
    type,
    isSelected,
    isHidden,
    rotation,
    lineDiagramRef,
    parentGroupId,
    processMode,
    chart,
    properties,
  } = props;

  const {
    // getElementParam,
    // updateElementPost,
    // addUserCurveDB,
    // updateUserCurveDB,
    // deleteUserCurveDB,
    diagramDataUpdate,
    diagramData,
    projectData,
    mainDiagramData,
    currentTabId,
    gridSizeController,
    openDialogData,
    openUserCurveData,
    infoCircuit,
    userCurveProperties,
    userRole,
    m_bModePM,
    modeViewOnly,
    drawPowerArrow,
    clearMenuState,
    isSelectTouchGroup,
    isOnShiftKey,
    elementGroups,
    tcGroups,
    elementReposition,
    updateElementPosition,
    setRepositionElement,
    mappedDataFromBE,
    infoSkeleton,
    userId,
    updateSelectControl,
    updateControlData,
    updateControlHeight,
    setTransformFlag,
    addUserCurve,
    updateUserCurve,
    deleteUserCurve,
    setOpenChart,
    updateTransCenterProps,
    openDialogEvents,
    openUserCurvePropertiesEvent,
    deleteRelatedGraphByUserCurveDialog,
    openUserCurveEvent,
    setUndoData,
    clearOptionMenu,
  } = props;

  const [lineStatus,setLineStatus] = useState(image);
  const [lineOhmZStatus,setLineOhmZStatus] = useState(lineOhmSvg);
  const [lineOhmPercentZStatus,setLineOhmPercentZStatus] = useState(lineOhmPercentZ);
  const [showPowerInfo, setShowPowerInfo] = useState(false)
  const [showPowerDirectInfo, setShowPowerDirectInfo] = useState(false)
  const [activePower, setActivePower] = useState("?????kW")
  const [reactivePower, setReactivePower] = useState("?????kvar")
  const [svgHeight, setSvgHeight] = useState('')
  const [svgWidth, setSvgWidth] = useState('')
  const [svgLine, setSvgLine] = useState('')
  const [svgArrowActive, setSvgArrowActive] = useState('')
  const [svgArrowReactive, setSvgArrowReactive] = useState('')
  const [svgTextX, setSvgTextX] = useState('')
  const [svgTextY, setSvgTextY] = useState('')
  const [svgTextRotation, setSvgTextRotation] = useState('')
  const [svgTextX1, setSvgTextX1] = useState('')
  const [svgTextY1, setSvgTextY1] = useState('')
  const [svgPosX1, setSvgPosX1] = useState(0)
  const [svgPosX2, setSvgPosX2] = useState(0)
  const [svgPosX3, setSvgPosX3] = useState(0)
  const [svgPosY1, setSvgPosY1] = useState(0)
  const [svgPosY2, setSvgPosY2] = useState(0)
  const [svgPosY3, setSvgPosY3] = useState(0)
  
  var leftArrowObj = new window.Image();
  leftArrowObj.src = leftArrow;

  var rightArrowObj = new window.Image();
  rightArrowObj.src = rightArrow;

  var lineOhmObj = new window.Image();
  lineOhmObj.src = lineOhmZStatus;

  var lineObj = new window.Image();
  lineObj.src = lineStatus;

  var lineOhmPercentZObj = new window.Image();
  lineOhmPercentZObj.src = lineOhmPercentZStatus;

  const svgToURL = (s:any) =>{
    const uri = window.btoa(unescape(encodeURIComponent(s)));
    return "data:image/svg+xml;base64," + uri;
  }

    const svgActivePower =
    `<svg height="${svgHeight}" width="${svgWidth}"  xmlns="http://www.w3.org/2000/svg">` +
    `<path  d="${svgLine}" stroke="${isSelected? 'red' : 'blue'}"  stroke-width="1" fill="none"/>` +
    `<path  d="${svgArrowActive}" stroke="${isSelected? 'red' : 'blue'}" stroke-width="1"  fill="none"/>` +
    `<text x="${svgTextX}" y="${svgTextY}" text-anchor="middle" font-size="10" fill="${isSelected? 'red' : 'blue'}" transform="translate(15,15) rotate(${svgTextRotation})">${activePower}</text>` +
    '</svg>' ;

  const urlActivePower = svgToURL(svgActivePower);
  const [imageSVGActivePower] = useImage(urlActivePower);

  const svgReactivePower =
  `<svg height="${svgHeight}" width="${svgWidth}"  xmlns="http://www.w3.org/2000/svg">` +
  `<path  d="${svgLine}" stroke="${isSelected? 'red' : 'blue'}"  stroke-width="1" fill="none"/>` +
  `<path  d="${svgArrowReactive}" stroke="${isSelected? 'red' : 'blue'}" stroke-width="1"  fill="none"/>` +
  `<text x="${svgTextX}" y="${svgTextY}" text-anchor="middle" font-size="10" fill="${isSelected? 'red' : 'blue'}" transform="translate(15,15) rotate(${svgTextRotation})">${reactivePower}</text>` +
  '</svg>' ;

  const urlReactivePower = svgToURL(svgReactivePower);
  const [imageSVGReactivePower] = useImage(urlReactivePower);

  
    const svgActiveReactivePower =
    `<svg height="${svgHeight}" width="${svgWidth}"  xmlns="http://www.w3.org/2000/svg">` +
    `<path  d="${svgLine}" stroke="${isSelected? 'red' : 'blue'}"  stroke-width="1" fill="none"/>` +
    `<path  d="${svgArrowActive}" stroke="${isSelected? 'red' : 'blue'}" stroke-width="1"  fill="none"/>` +
    `<text x="${svgTextX}" y="${svgTextY}" text-anchor="middle" font-size="10" fill="${isSelected? 'red' : 'blue'}" >${activePower}</text>` +
    `<text x="${svgTextX1}" y="${svgTextY1}" text-anchor="middle" font-size="10" fill="${isSelected? 'red' : 'blue'}" >${reactivePower}</text>` +
    '</svg>' ;
  
  const urlActiveReactivePower = svgToURL(svgActiveReactivePower);
  const [imageSVGActiveReactivePower] = useImage(urlActiveReactivePower);

  const [isOpenUserCurvesDialog, setIsOpenUserCurvesDialog] = useState(false);
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [menuPosition, setMenuPosition] = useState<Coordinates>({ x: 0, y: 0 });
  const [isOpenEditorDialog, setIsOpenEditorDialog] = useState(false);
  const [groupPosition, setGroupPosition] = useState({ x: x, y: y });
  const [dragStartPosition, setDragStartPosition] = useState({ x: x, y: y, labelX: groupPosition.x + Constant.ELEMENT_SIZE, labelY: groupPosition.y + CONTROL_POS_FACTOR * (height / 2) });;
  const [isResizing, setIsResizing] = useState(false);
  const [heightTransform, setHeightTransform] = useState(0);
  const [labelPosition, setLabelPosition] = useState({
    x: groupPosition.x + Constant.ELEMENT_SIZE,
    y: groupPosition.y + CONTROL_POS_FACTOR * (height / 2),
  });
  const [arrowPosition, setArrowPosition] = useState({
    x: groupPosition.x,
    y: groupPosition.y + height - Constant.ELEMENT_SIZE,
  });

  const transformRef = useRef<Konva.Transformer>(null);
  const transformMoveRef = useRef<Konva.Transformer>(null);
  const groupRef = useRef<any>();
  const topRef = useRef<any>();
  const bottomRef = useRef<any>();
  const [initialValue, setInitialValue] = useState(properties);
  const [loadingFlag, setLoadingFlag] = useState(false);
  const labelRef = useRef<any>();
  const leftArrowRef = useRef<any>();
  const rightArrowRef = useRef<any>();
  const [isDefaultVoltage, setIsDefaultVoltage] = useState(true);
  const [isDisplayTabConnectTran1,setIsDisplayTabConnectTran1] = useState(false);
  const [isDisplayTabConnectTranscott,setIsDisplayTabConnectTranscott] = useState(false);
  const [isVoltText,setIsVoltText] = useState('');
  const [listUserCurves, setListUserCurves] = useState(properties.listUser?[...properties.listUser]:[])

  const [isOpenUserCurveProperties, setIsOpenUserCurveProperties] = useState(false)
  const [selectItem, setSelectItem] = useState<any>(null);
  const [dispInputDialog, setDispInputDialog] = useState<any>(null);
  const [isOnOpenMenu, setIsOnOpenMenu] = useState<boolean>(false);

  // const labelTexts = doGetRefV1(processMode, props as any, false);
  // const [labelTexts, setLabelTexts] = useState<any>([])
  const [labelTexts, setLabelTexts] = useState<any>({title:'',body:'',textHeight:0})


  //#endregion
 
  //#region 
  useEffect(() => {
    // setLabelTexts(doGetRefV1(processMode, props as any, false))
    const labelFirst = doGetRefV1(processMode, props as any,false)
    const title = labelFirst.shift()
    let txt = ""
    if(labelFirst.length > 0){
      labelFirst.map((item:any, index:any) =>{
        txt += item.value +'\n'
      })
      txt = txt.slice(0, -1)
    }
    let labelTexts1 = new Konva.Text({
      text: txt,
      });
      setLabelTexts({title:title?.value,body:labelTexts1.attrs.text,textHeight:labelTexts1.height()})
  }, [processMode,diagramDataUpdate])

  useEffect(()=>{
    if(isSelected){
      if(isOnOpenMenu) {
        setIsOnOpenMenu(false)
      }
      else
      {
        setIsOpenMenu(false)
      }
    }
  },[clearMenuState])

  useEffect(()=>{
    if(elementReposition?.id)
    {
      const groupsData = currentTabId === 1 ? elementGroups : tcGroups[diagramData.find((x:any)=>x.id == id)?.parentTcId ?? 0]
      const peekGroupId = groupsData?.byEleId[elementReposition.id as any]?.at(-1) ?? -1
      const elementIds = groupsData?.byId[peekGroupId]?.elementIds ?? []
      if(elementIds.includes(id) || diagramData[0].shape.find((item:any)=>(item.isSelected && item.id == id)))
      {
        setGroupPosition({
          x: dragStartPosition.x,
          y: dragStartPosition.y,
        });

        labelRef.current.to({
          x: dragStartPosition.labelX,
          y: dragStartPosition.labelY,
        });
        
        groupRef.current.to({
          x: dragStartPosition.x,
          y: dragStartPosition.y,
        });

        updateElementPosition(id, dragStartPosition.x, dragStartPosition.y)
      }
    }
  },[elementReposition])
  
  useEffect(() => {
    if (x >= 0 && y >= 0 && (groupPosition.x !== x || groupPosition.y !== y)) {
      setGroupPosition({x, y})
    }
  }, [x, y])

  useEffect(()=>{
    if(userCurveProperties?.controlId === id && userCurveProperties !== undefined) {
      let userCurve = properties.listUser.find(x => x.userCurveId === userCurveProperties.userCurveId)
      userCurve && setSelectItem(userCurve)
      setIsOpenUserCurveProperties(true)
    }
  },[userCurveProperties])

  useEffect(() => {
    setIsOpenMenu(false)

    if(processMode == MS_MODE_POWER_FLOW)
    {
      const pElement = diagramData[0].shape.find((x:any)=>x.id === id)
      let result = doDrawSymbolGroup(pElement,processMode)
      if(result && result.arrow && result.text.length > 0){
        setPowerFlowArrow(result)
      }
    }
    else
    {
      setShowPowerInfo(false)
      setShowPowerDirectInfo(false)
    }
  }, [processMode])

  useEffect(() => {
     setIsOpenMenu(false)
    
    if(processMode == MS_MODE_POWER_FLOW && drawPowerArrow.payload.length > 0 && id)
    {
      let result = drawPowerArrow.payload.find((arrows:any) => arrows.arrow?.element?.id && Number(arrows.arrow?.element?.id) == Number(id))
      if(result && result.arrow && result.text.length > 0){
        setPowerFlowArrow(result)
      }
    }
  }, [drawPowerArrow])

  useEffect(() => {
    setInitialValue(properties);
  },[properties])

  useEffect(() => {
    if (isSelected && !parentGroupId) {
      transformRef.current?.nodes([groupRef.current]);
    } else {
      transformRef.current?.nodes([]);
    }
    transformRef.current?.getLayer()?.batchDraw();
  }, [isSelected, parentGroupId, heightTransform]);

  useEffect(() => {
    if (!isSelected) setIsOpenMenu(false);
  }, [isSelected]);

  useEffect(() => {
    if(openDialogData?.id === id && openDialogData !== undefined) {
      openDialogEvents(({type: "OPEN_DIALOG", data: { }}))
      handleOpenEditorDialog()
    }
  }, [openDialogData]);

  useEffect(() => {
    if(openUserCurveData?.id === id && openUserCurveData !== undefined) {
      setIsOpenUserCurvesDialog(true)
    }
  }, [openUserCurveData]);

  useEffect(() => {
    // check grid size; label, icon position when rotate, move and change height
    // handleGridSize(groupPosition.x, groupPosition.y, height, rotation);
    calcLabelPos();
    calcArrowPos();
    if (diagramData[0].tabId != 1){
      // TransCenter
      doSetValuesTransCenter();
    }
  }, [groupPosition, height, rotation]);

  useEffect(() => {
    switch(getSymbolAndBackColor(infoSkeleton,processMode,properties).symbolColor){
      case "#008040":
        setLineStatus(lineGreenSvg);
        setLineOhmZStatus(lineOhmGreenSvg)
        setLineOhmPercentZStatus(lineOhmPercentZGreen)
        break;
      case "#FF0000":
        setLineStatus(lineRedSvg);
        setLineOhmZStatus(lineOhmRedSvg)
        setLineOhmPercentZStatus(lineOhmPercentZRed)
        break;
      default:
        setLineStatus(lineSvg);
        setLineOhmZStatus(lineOhmSvg)
        setLineOhmPercentZStatus(lineOhmPercentZ)
        break;
    }
  },[getSymbolAndBackColor(infoSkeleton,processMode,properties).symbolColor])
  //#endregion

  //#region Method
  const setPowerFlowArrow = (data:any) => {
    if(data.arrow.drawActiveNoneDirect){
      setShowPowerInfo(true)
      setShowPowerDirectInfo(false)
      if(data.arrow.arrowActiveAtTop){
          setSvgHeight('70')
          setSvgWidth('30')
          setSvgLine('M 20 50 L 20 0')
          setSvgArrowActive('M 16 5 L 20 0 L 24 5')
          setSvgTextX('-10')
          setSvgTextY('-5')
          setSvgTextRotation('270')
          setActivePower(data.text[0])
          setSvgPosX1(-55)
          setSvgPosY1(-30)
      }
      else if(data.arrow.arrowActiveAtRight) {
          setSvgHeight('30')
          setSvgWidth('70')
          setSvgLine('M 50 20 L 0 20')
          setSvgArrowActive('M 5 16  L 0 20 L 5 24')
          setSvgTextX('7')
          setSvgTextY('3')
          setSvgTextRotation('0')
          setActivePower(data.text[0])
          setSvgPosX1(-40)
          setSvgPosY1(20)
          
      }
      else if(data.arrow.arrowActiveAtLeft){
          setSvgHeight('30')
          setSvgWidth('70')
          setSvgLine('M 50 20 L 0 20')
          setSvgArrowActive('M 45 16 L 50 20 L 45 24')
          setSvgTextX('7')
          setSvgTextY('3')
          setSvgTextRotation('0')
          setActivePower(data.text[0])
          setSvgPosX1(-55)
          setSvgPosY1(-30)
          setSvgPosX1(-40)
          setSvgPosY1(30)
      }
      else {
          setSvgHeight('70')
          setSvgWidth('30')
          setSvgLine('M 20 50 L 20 0')
          setSvgArrowActive('M 16 45 L 20 50 L 24 45')
          setSvgTextX('-10')
          setSvgTextY('-5')
          setSvgTextRotation('270')
          setActivePower(data.text[0])
          setSvgPosX1(-55)
          setSvgPosY1(-30)
      }
    }
    if(data.arrow.drawReactiveNoneDirect){
      setShowPowerInfo(true)
      setShowPowerDirectInfo(false)
      if(data.arrow.arrowReactiveAtTop){
          setSvgHeight('70')
          setSvgWidth('30')
          setSvgLine('M 20 50 L 20 0')
          setSvgArrowReactive('M 16 5  L 20 0 L 24 5')
          setSvgTextX('-10')
          setSvgTextY('-5')
          setSvgTextRotation('270')
          setReactivePower(data.text[1])
          setSvgPosX2(-30)
          setSvgPosY2(-30)
      }
      else if(data.arrow.arrowReactiveAtRight) {
          setSvgHeight('30')
          setSvgWidth('70')
          setSvgLine('M 50 20 L 0 20')
          setSvgArrowReactive('M 5 16  L 0 20 L 5 24')
          setSvgTextX('7')
          setSvgTextY('3')
          setSvgTextRotation('0')
          setReactivePower(data.text[1])
          setSvgPosX2(-40)
          setSvgPosY2(40)
      }
      else if(data.arrow.arrowReactiveAtLeft){
          setSvgHeight('30')
          setSvgWidth('70')
          setSvgLine('M 50 20 L 0 20')
          setSvgArrowReactive('M 45 16 L 50 20 L 45 24')
          setSvgTextX('7')
          setSvgTextY('3')
          setSvgTextRotation('0')
          setReactivePower(data.text[1])
          setSvgPosX2(-40)
          setSvgPosY2(40)
      }
      else {
          setSvgHeight('70')
          setSvgWidth('30')
          setSvgLine('M 20 50 L 20 0')
          setSvgArrowReactive('M 16 45 L 20 50 L 24 45')
          setSvgTextX('-10')
          setSvgTextY('-5')
          setSvgTextRotation('270')
          setReactivePower(data.text[1])
          setSvgPosX2(-30)
          setSvgPosY2(-30)
      }
    }
    if(data.arrow.drawOtherDirect){
      setShowPowerInfo(false)
      setShowPowerDirectInfo(true)
      if(data.arrow.arrowActiveReactiveAtTop){
          setSvgHeight('50')
          setSvgWidth('70')
          setSvgLine('M 10 50 L 10 20')
          setSvgArrowActive('M 6 25 L 10 20 L 14 25')
          setSvgTextX('40')
          setSvgTextY('30')
          setSvgTextX1('40')
          setSvgTextY1('40')
          setSvgTextRotation('0')
          setActivePower(data.text[0])
          setReactivePower(data.text[1])
          setSvgPosX3(20)
          setSvgPosY3(-50)
      }
      else if(data.arrow.arrowActiveReactiveAtRight) {
          setSvgHeight('70')
          setSvgWidth('50')
          setSvgLine('M 50 10 L 20 10')
          setSvgArrowActive('M 25 6 L 20 10 L 25 14')
          setSvgTextX('25')
          setSvgTextY('20')
          setSvgTextX1('25')
          setSvgTextY1('30')
          setSvgTextRotation('0')
          setActivePower(data.text[0])
          setReactivePower(data.text[1])
          setSvgPosX3(-30)
          setSvgPosY3(30)
      }
      else if(data.arrow.arrowActiveReactiveAtLeft){
          setSvgHeight('70')
          setSvgWidth('50')
          setSvgLine('M 50 10 L 20 10')
          setSvgArrowActive('M 45 6 L 50 10 L 45 14')
          setSvgTextX('25')
          setSvgTextY('20')
          setSvgTextX1('25')
          setSvgTextY1('30')
          setSvgTextRotation('0')
          setActivePower(data.text[0])
          setReactivePower(data.text[1])
          setSvgPosX3(-30)
          setSvgPosY3(30)
      }
      else {
          setSvgHeight('50')
          setSvgWidth('70')
          setSvgLine('M 10 50 L 10 20')
          setSvgArrowActive('M 6 45 L 10 50 L 14 45')
          setSvgTextX('40')
          setSvgTextY('30')
          setSvgTextX1('40')
          setSvgTextY1('40')
          setSvgTextRotation('0')
          setActivePower(data.text[0])
          setReactivePower(data.text[1])
          setSvgPosX3(20)
          setSvgPosY3(-50)
      }
    }
  }
  
  const handleOKUserCurveDetailForm = async (data: any) => {
    const foundUserCurve = listUserCurves.find((curve:any) => curve.userCurveId === data.userCurveId)        
    if (foundUserCurve) { // update  
      setLoadingFlag(true);
      const request = { 
        userId: userId,
        projectId: projectData.projectId,
        elementId: parseInt(id),
        params:{...data},
        ownerProject: projectData.ownerId

      }
      const result = await post("/diagram/edit-curve-user", request);
      if (result.success) {
        setLoadingFlag(false);
        handleUpdateCurveUserSuccess(id, result.data);
        updateUserCurve(id,data);
      } else {
        handleSubmitError(result.error);
        setLoadingFlag(false);
      }
        //setOpenChart(true, LineType.USER_CURVE, data.userCurveId??data.user_curve_id)
    } else { // add new
        addUserCurve(id, data)
    }
    setSelectItem(null)
    setIsOpenUserCurveProperties(false)
  }

  const handleCancelUserCurveDetailForm = () => {
    setSelectItem(null)
    setIsOpenUserCurveProperties(false)
    openUserCurvePropertiesEvent('', '')
  }
  
  const doSetValuesTransCenter = () => {
    // TransCenter
    let transCenter = mainDiagramData.shape.find(
      (e: any) => e.properties?.tabId === diagramData[0].tabId
    );

    if (transCenter) {
      let tmpCalc = doGetValuesTransCenter(transCenter.properties, diagramData[0].shape);
      transCenter.properties = tmpCalc.newProps;
      updateTransCenterProps(transCenter.id, transCenter.properties);
    }
  };

  const handleContextMenu = (e: any) => {
    e.evt.preventDefault();
    if(e.evt.shiftKey) return
    setIsOnOpenMenu(true)
    updateSelectControl(id);

    const menuHeight = getMenuOptionHeight()
    const clientWidth = lineDiagramRef.current.clientWidth
    const isTransCenter = currentTabId == 1 ? false : true;
    let {posX, posY} = calcContextMenuPosition(
      e, menuHeight, clientWidth, x, y, gridSizeController, isTransCenter
    )
    clearOptionMenu(!clearMenuState)
    
    setMenuPosition({
      x: posX,
      y: posY,
    });
    setIsOpenMenu(true);
  };

  const handleClick = (e: any) => {
    if (e.type === "click") setIsOpenMenu(false);
    if(parentGroupId && parentGroupId > 0) return;
    if (e.evt.shiftKey || isSelectTouchGroup) {
      updateSelectControl(id, true);
    } else {
      updateSelectControl(id);
    }
  };

  const handleOpenMenu = (value: boolean) => {
    setIsOpenMenu(value);
    updateSelectControl(id);
  };

  const handleOpenEditorDialog = async () => {
    // if (e.evt.button === 0 && e.evt.buttons === 0) setIsOpenEditorDialog(true);
    let params ={
      userId: userId,
      projectId: projectData.projectId,
      elementId: id,
      elementType: type,
      data: properties,
      ownerProject: projectData.ownerId

    };
    setLoadingFlag(true);
    // getElementParam(params, id);
    const result = await post("/diagram/get-param", params);
    if (result.success) {
      handleGetSuccess(result.data);
    } else {
      handleSubmitError(result.error);
    }
  
  };

  const handleCancelEditorDialog = () => {
    setIsOpenEditorDialog(false);
  };
  const checkOldValue = (newData: any, oldData: any) => {
    if(newData.calcPoint0.loadCurrent == BeamsNumber(oldData.calcPoint0.loadCurrent,5)){
      newData.calcPoint0.loadCurrent = oldData.calcPoint0.loadCurrent
    }
    if(newData.calcPoint0.loadCapacity == BeamsNumber(oldData.calcPoint0.loadCapacity,7)){
      newData.calcPoint0.loadCapacity = oldData.calcPoint0.loadCapacity
    }
    if(newData.calcPoint1.loadCapacity == BeamsNumber(oldData.calcPoint1.loadCapacity,7)){
      newData.calcPoint1.loadCapacity = oldData.calcPoint1.loadCapacity
    }
    if(newData.calcPoint1.loadCurrent == BeamsNumber(oldData.calcPoint1.loadCurrent,5)){
      newData.calcPoint1.loadCurrent = oldData.calcPoint1.loadCurrent
    }
    return newData
  }
  const handleUpdate = async (data: any) => {
    let updateData = {...data};
    // set the old value if field is unchanged
    updateData = checkPhaseOldValue(data, initialValue, "m_3Phase")
    updateData = checkPhaseOldValue(data, initialValue, "phaseN")
    updateData = checkPhaseOldValue(data, initialValue, "phaseNPE")
    updateData = checkOldValue(data, initialValue)

    setIsOpenEditorDialog(false);
    // call api
    const request = { 
      userId: userId,
      projectId: projectData.projectId,
      elementType: getElementKindValue(props.type),
      elementId: id,
      param: {...updateData,rotation},
      ownerProject: projectData.ownerId
    }
    // updateElementPost(request, id)
    setLoadingFlag(true);
    const result = await post("/diagram/set-param", request);
    if (result.success) {
      handleUpdateSuccess(result.data);
      if(result.data && result.data.resultCode == 0){
        setUndoData({type:"UPDATE_PROPERTIES_ELEMENT", dataUndo:{}} as Model.UndoModel);
        updateControlData(id, updateData);
        setInitialValue(data);
      }
    } else {
      handleSubmitError(result.error);
    }

    openDialogEvents({type: "RELOAD", data: { }});
  };
  

  const checkPhaseOldValue = (newData: any, oldData: any, phase: string) => {
    if (newData[phase].ohmRPerM == BeamsNumber(oldData[phase].ohmRPerM, 4)){
      newData[phase].ohmRPerM = oldData[phase].ohmRPerM
    }
    if (newData[phase].ohmXPerM == BeamsNumber(oldData[phase].ohmXPerM, 4)){
      newData[phase].ohmXPerM = oldData[phase].ohmXPerM
    }
    return newData
  }

  const dragBoundFunc = (e: any, mode: string) => {
    // let rect = groupRef.current.getClientRect();
    const newPosition = calcDragBoundPosition(
      e, groupPosition, rotation, height, mode, gridSizeController
    );

    return {
      x: newPosition.newX,
      y: newPosition.newY,
    };
  };

  const calcLabelPos = (e?: any) => {
    let newX = groupPosition.x,
      newY = groupPosition.y;
    if (e?.type === "dragmove") {
      newX = groupRef.current.attrs.x;
      newY = groupRef.current.attrs.y;
    }

    const newPosition = {
      x: newX + Constant.ELEMENT_SIZE,
      y: newY + CONTROL_POS_FACTOR * (height / 2),
    };

    if (rotation !== 0) {
      newPosition.x = newX + CONTROL_POS_FACTOR * (height / 2);
      newPosition.y = newY - Constant.ELEMENT_SIZE;
    }

    setLabelPosition(newPosition);
  };

  const calcArrowPos = (e?: any) => {
    let newX = groupPosition.x,
      newY = groupPosition.y;
    if (e?.type === "dragmove") {
      newX = groupRef.current.attrs.x;
      newY = groupRef.current.attrs.y;
    }

    const newPosition = {
      x: newX,
      y: newY + height - Constant.ELEMENT_SIZE,
    };

    if (rotation !== 0) {
      newPosition.y = newY;
      rotation === -90 &&
        (newPosition.x = newX + height - Constant.ELEMENT_SIZE);
      rotation === 90 && (newPosition.x = newX);
    }

    setArrowPosition(newPosition);
  };

  const handleDragStart = (e: any) => {
    groupRef.current.moveToTop()
    transformRef.current?.moveToTop()
    labelRef.current?.moveToTop()
    rightArrowRef.current?.moveToTop()
    leftArrowRef.current?.moveToTop()
    setDragStartPosition({x: groupPosition.x, y: groupPosition.y, labelX: labelPosition.x, labelY: labelPosition.y});
    setIsOpenMenu(false);
  }

  const handleDragmove = (e: any) => {
    calcLabelPos(e);
    calcArrowPos(e);
  };

  const onDragEnd = (e: any) => {
    if (
      e.currentTarget.attrs.x === groupPosition.x &&
      e.currentTarget.attrs.y === groupPosition.y
    ) {
      return;
    }

    let newX =
      Math.round(e.target.x() / Constant.POINTER_WIDTH) *
      Constant.POINTER_WIDTH;
    let newY =
      Math.round(e.target.y() / Constant.POINTER_HEIGHT) *
      Constant.POINTER_HEIGHT;
         
    // check new position is outside grid
    if (newX < 0 || newY < 0){
      setRepositionElement(id, !(elementReposition?.state??true))
      newX = dragStartPosition.x;
      newY = dragStartPosition.y;
    }
    
    setGroupPosition({
      x: newX,
      y: newY,
    });

    e.target.to({
      x: newX,
      y: newY,
    });
  };

  // Mobile Touch Events
  var isMoving = false
  var isLongPress = false
  
  const handleTouchStart = useCallback((e:any) => {
    setIsOpenMenu(false)
    isLongPress = true
    isMoving = false
    !isSelectTouchGroup && setTimeout(() => {
      if (isLongPress && !isMoving) {
        handleContextMenu(e)
      }
    }, 1000)
  },[x,y,isSelectTouchGroup])

  const handleTouchMove = useCallback(() => {
    isMoving = true
  },[x,y,isSelectTouchGroup])

  const handleTouchEnd = useCallback((e:any) => {
    isLongPress = false
  },[x,y,isSelectTouchGroup])

  const handleMouseDown = (e: any, mode: string) => {
    const container = e.target.getStage().container();
    container.style.cursor = rotation === 0 ? "ns-resize" : "ew-resize";
    setIsResizing(true);
  };

  const handleMouseMove = (e: any, mode: string) => {
    if (!isResizing) return;
    setHeightTransform(e.evt.movementY);
  };

  const handleMouseUp = (e: any, mode: string) => {
    setTransformFlag(true);
    const container = e.target.getStage().container();
    container.style.cursor = "default";

    let newPosX = e.target._lastPos.x // e.evt.offsetX
    let newPosY = e.target._lastPos.y // e.evt.offsetY

    let newEle = calcElementSize(
      newPosX,
      newPosY,
      groupPosition,
      rotation,
      height,
      offsetY,
      mode,
      gridSizeController
    )

    topRef.current.y(0);
    // bottomRef.current.y(newHeight - 7)
    setGroupPosition({ x: newEle.newX, y: newEle.newY });

    updateControlHeight(id, newEle.newHeight, newEle.newX, newEle.newY, newEle.newOffsetY);
    setIsResizing(false);
  };

  const convertDataFromBE = (BEData : any) =>{
    let voltageList: OptionModel[] = [];
    if (BEData.voltageList && BEData.voltageList.length > 0){
      BEData.voltageList.forEach((element: any) => {
        if(element.lvoltage !=null){
          let optionModel: OptionModel = {
              label: element.lvoltage.toString(),
              value: element.lvoltage
          };
          voltageList.push(optionModel)
        }
      });

  }
    return {
      voltageList: voltageList
  }
  }

 
  const doGetPerZrBusBar =(initData:any,m_dStdCapacity:number,wireSystem:number,partKind:number,bTwoWay:boolean) =>{
    let percentZ ={
        m_dPerRk3 : 0,
        m_dPerXk3 : 0,
        m_dPerRk3Difference: 0,
        m_dPerXk3Difference: 0
    }
    let lenght = +initData.lenght
    let m_3PhaseohmRPer = +initData.m_3Phase.ohmRPerM
    let m_3PhaseohmXPer = +initData.m_3Phase.ohmXPerM
    let voltage = initData.voltage
    if(voltage <=0){
        percentZ.m_dPerRk3 =0
        percentZ.m_dPerXk3 = 0

    }
    else{
        switch(wireSystem){
            case Constant.RS_3PHASE:
                if(initData.sPhase){
                    switch(partKind){
                        case Constant.MS_OUTSIDE_NEUTRAL:
                            percentZ.m_dPerRk3 = m_dStdCapacity *  m_3PhaseohmRPer * lenght /(voltage / 2)/ (voltage / 2) * 100
                            percentZ.m_dPerXk3 = m_dStdCapacity *  m_3PhaseohmXPer * lenght /(voltage / 2)/ (voltage / 2) * 100
                            if(bTwoWay){
                                percentZ.m_dPerRk3 *= 2
                                percentZ.m_dPerXk3 *= 2

                            }
                            break
                        case Constant.MS_OUTSIDE_OUTSIDE:
                            percentZ.m_dPerRk3 = m_dStdCapacity *  m_3PhaseohmRPer * lenght /voltage/ voltage  * 100
                            percentZ.m_dPerXk3 = m_dStdCapacity *  m_3PhaseohmXPer * lenght /voltage / voltage  * 100
                            if(bTwoWay){
                                percentZ.m_dPerRk3 *= 2
                                percentZ.m_dPerXk3 *= 2

                            }
                            percentZ.m_dPerRk3Difference = m_dStdCapacity *  m_3PhaseohmRPer * lenght /(voltage / 2)/ (voltage / 2) * 100
                            percentZ.m_dPerXk3Difference = m_dStdCapacity *  m_3PhaseohmXPer * lenght /(voltage / 2)/ (voltage / 2) * 100
                            if(bTwoWay){
                                percentZ.m_dPerRk3Difference *= 2
                                percentZ.m_dPerXk3Difference *= 2
                            }
                            percentZ.m_dPerRk3Difference = percentZ.m_dPerRk3 - percentZ.m_dPerRk3Difference
                            percentZ.m_dPerXk3Difference = percentZ.m_dPerXk3 - percentZ.m_dPerXk3Difference
                            break
                        case Constant.MS_SEAT_MAIN:
                        case Constant.MS_SEAT_T:
                            percentZ.m_dPerRk3 = m_dStdCapacity *  m_3PhaseohmRPer * lenght /voltage/ voltage  * 100
                            percentZ.m_dPerXk3 = m_dStdCapacity *  m_3PhaseohmXPer * lenght /voltage / voltage  * 100
                            if(bTwoWay){
                                percentZ.m_dPerRk3 *= 2
                                percentZ.m_dPerXk3 *= 2

                            }
                            break;

                    }
                }
                else{
                    percentZ.m_dPerRk3 = m_dStdCapacity *  m_3PhaseohmRPer * lenght /voltage/ voltage  * 100
                    percentZ.m_dPerXk3 = m_dStdCapacity *  m_3PhaseohmXPer * lenght /voltage / voltage  * 100
                }
                break
            case Constant.RS_PHASE_N:
                percentZ.m_dPerRk3 = m_dStdCapacity *  m_3PhaseohmRPer * lenght /voltage/ voltage  * 100
                percentZ.m_dPerXk3 = m_dStdCapacity *  m_3PhaseohmXPer * lenght /voltage / voltage  * 100
                break
            case Constant.RS_PHASE_PE:
                percentZ.m_dPerRk3 = m_dStdCapacity *  m_3PhaseohmRPer * lenght /voltage/ voltage  * 100
                percentZ.m_dPerXk3 = m_dStdCapacity *  m_3PhaseohmXPer * lenght /voltage / voltage  * 100
                break
        }
    }
    return percentZ
}
const doGetValuePerRegular = (voltage:number,m_dStdCapacity:number,m_dPerRk3:number,m_dPerXk3:number,nCalcKind:number,powerFactor:number,leadOrLag:number,loadCurrent:number) =>{
  if(powerFactor < 0 || m_dStdCapacity <=0){
      return 0
  }
  let dcapacity = voltage * loadCurrent
  switch(nCalcKind){
      case Constant.CALC_IK3:
          dcapacity *= Math.sqrt(3.0)
          break
      case Constant.CALC_IK2:
          dcapacity *= 2
          break
      case Constant.CALC_IK2_OUTSIDE_NEUTRAL:
          break
      case Constant.CALC_IK2_OUTSIDE_OUTSIDE:
          dcapacity *= 2
          break
      case Constant.CALC_IK2_SEAT_MAIN:
          dcapacity *= 2
          break
      case Constant.CALC_IK2_SEAT_T:
          dcapacity *= 2
          break
  }
  let dPercent = 0
  if(powerFactor * powerFactor <= 1){
      if(leadOrLag === 1){
          dPercent = m_dPerRk3 * powerFactor - m_dPerXk3 * Math.sqrt(1- powerFactor * powerFactor)
      }
      else{
          dPercent = m_dPerRk3 * powerFactor + m_dPerXk3 * Math.sqrt(1- powerFactor * powerFactor)

      }
  }
  dPercent *= dcapacity
  dPercent /= m_dStdCapacity * 1000
  dPercent /= 100
  if(Math.abs(dPercent) < Constant.VALUE_NODE_VOLT_MIN){
      dPercent = 0

  }
  return dPercent


}
const doGetValueVoltRegular = (voltage:number,m_dStdCapacity:number,m_dPerRk3:number, m_dPerXk3:number,nCalcKind:number,powerFactor:number,leadOrLag:number,loadCurrent:number) =>{
  let percent = doGetValuePerRegular(voltage,m_dStdCapacity,m_dPerRk3,m_dPerXk3,nCalcKind,powerFactor,leadOrLag,loadCurrent)
  let dDropVolt = voltage - voltage * percent

  if(Math.abs(dDropVolt) < Constant.VALUE_NODE_VOLT_MIN){
      dDropVolt = 0
  }
  return dDropVolt
}
  const doGetDropVoltDiff = (initData:any,m_dStdCapacity:number,voltage:number, partKind:number,powerFactor:number,leadOrLag:number,loadCurrent:number)=>{
    let m_nDownTransKind = initData.downTransKind
    let percentZ = doGetPerZrBusBar(initData,m_dStdCapacity,Constant.RS_3PHASE,partKind,false)

    let volt = 0
    if(initData.sPhase){
        switch(partKind){
            case Constant.MS_OUTSIDE_NEUTRAL:
            case Constant.MS_SEAT_MAIN:
                let nCalcKind1 = initData.calcPoint1.calcKind
                volt = doGetValueVoltRegular(voltage,m_dStdCapacity,percentZ.m_dPerRk3, percentZ.m_dPerXk3,nCalcKind1,powerFactor,leadOrLag,loadCurrent)
                break;
            case Constant.MS_OUTSIDE_OUTSIDE:
            case Constant.MS_SEAT_T:
                let nCalcKind0 = initData.calcPoint0.calcKind
                volt = doGetValueVoltRegular(voltage,m_dStdCapacity,percentZ.m_dPerRk3, percentZ.m_dPerXk3,nCalcKind0,powerFactor,leadOrLag,loadCurrent)
                 break
        }
        volt = voltage - volt
    }
    else{
        if(m_nDownTransKind === Constant.MS_TRANS1){
                let nCalcKind1 = initData.calcPoint1.calcKind

                volt = doGetValueVoltRegular(voltage,m_dStdCapacity,percentZ.m_dPerRk3, percentZ.m_dPerXk3,nCalcKind1,powerFactor,leadOrLag,loadCurrent)

        }
        else{
            let nCalcKind0 = initData.calcPoint0.calcKind
            volt = doGetValueVoltRegular(voltage,m_dStdCapacity,percentZ.m_dPerRk3, percentZ.m_dPerXk3,nCalcKind0,powerFactor,leadOrLag,loadCurrent)

        }
        volt = voltage - volt
    }

    return BeamsNumber(volt,4)
}
const doGetDropPerUnit = (initData:any,voltage:number,m_dStdCapacity:number,partKind:number,powerFactor:number,leadOrLag:number,loadCurrent:number) =>{
  let m_nDownTransKind = initData.downTransKind

  let percentZ = doGetPerZrBusBar(initData,m_dStdCapacity,Constant.RS_3PHASE,partKind,false)
  let volt = 0
  let percent = 0
  if(initData.sPhase) {
      switch(partKind){
          case Constant.MS_OUTSIDE_NEUTRAL:
              initData.calcPoint1.stdCapacity = m_dStdCapacity
              volt = doGetValueVoltRegular(voltage/2,m_dStdCapacity, percentZ.m_dPerRk3,percentZ.m_dPerXk3,initData.calcPoint1.calcKind,powerFactor,leadOrLag,loadCurrent)
              percent = 1- volt /(voltage/2)
              break
          case Constant.MS_SEAT_MAIN:
              initData.calcPoint1.stdCapacity = m_dStdCapacity
              volt = doGetValueVoltRegular(voltage,m_dStdCapacity, percentZ.m_dPerRk3,percentZ.m_dPerXk3,initData.calcPoint1.calcKind,powerFactor,leadOrLag,loadCurrent)
              percent = 1- volt /voltage
              break
          case Constant.MS_OUTSIDE_OUTSIDE:
              initData.calcPoint0.stdCapacity = m_dStdCapacity
              volt = doGetValueVoltRegular(voltage,m_dStdCapacity, percentZ.m_dPerRk3,percentZ.m_dPerXk3,initData.calcPoint0.calcKind,powerFactor,leadOrLag,loadCurrent)
              percent = 1- volt /voltage
              break
          case Constant.MS_SEAT_T:
              initData.calcPoint0.stdCapacity = m_dStdCapacity
              volt = doGetValueVoltRegular(voltage,m_dStdCapacity, percentZ.m_dPerRk3,percentZ.m_dPerXk3,initData.calcPoint0.calcKind,powerFactor,leadOrLag,loadCurrent)
              percent = 1- volt /voltage
              break
      }
  }
  else{
      if(m_nDownTransKind === Constant.MS_TRANS1){
              initData.calcPoint1.stdCapacity = m_dStdCapacity
          volt = doGetValueVoltRegular(voltage,m_dStdCapacity, percentZ.m_dPerRk3,percentZ.m_dPerXk3,initData.calcPoint1.calcKind,powerFactor,leadOrLag,loadCurrent)
      }
      else{
              initData.calcPoint0.stdCapacity = m_dStdCapacity
          volt = doGetValueVoltRegular(voltage,m_dStdCapacity, percentZ.m_dPerRk3,percentZ.m_dPerXk3,initData.calcPoint0.calcKind,powerFactor,leadOrLag,loadCurrent)

      }
      percent = 1- volt / voltage
  }
  percent *= 100
return BeamsNumber(percent,4)

}
  const doSetValueVoltDrop = (initData:any,voltage:number,isConnectTrans1OrTranscott:boolean,sPhase:boolean,upTransKind:number) =>{
    const m_dStdCapacity = infoCircuit.stdCapacity// hard code
    
    if(isConnectTrans1OrTranscott){
        let voltageDrop1_2 = 0
        let voltageDrop2 = 0
    if(sPhase){
        switch(upTransKind){
            case Constant.MS_TRANS1:
                voltageDrop1_2 = doGetDropVoltDiff(initData,m_dStdCapacity,voltage/2, Constant.MS_OUTSIDE_NEUTRAL,+initData.calcPoint1.powerFactor,+initData.leadOrLag2,+initData.calcPoint1.loadCurrent)
                voltageDrop2 = doGetDropPerUnit(initData,voltage,m_dStdCapacity,Constant.MS_OUTSIDE_NEUTRAL,+initData.calcPoint1.powerFactor,+initData.leadOrLag2,+initData.calcPoint1.loadCurrent)
                break;
            case Constant.MS_TRANSSCOTT:
                voltageDrop1_2 = doGetDropVoltDiff(initData,m_dStdCapacity,voltage, Constant.MS_SEAT_MAIN,+initData.calcPoint1.powerFactor,+initData.leadOrLag2,+initData.calcPoint1.loadCurrent)
                voltageDrop2 = doGetDropPerUnit(initData,voltage,m_dStdCapacity,Constant.MS_SEAT_MAIN,+initData.calcPoint1.powerFactor,+initData.leadOrLag2,+initData.calcPoint1.loadCurrent)
                break;
        }
    }
    else{
        voltageDrop1_2 = doGetDropVoltDiff(initData,m_dStdCapacity,voltage, Constant.MS_3PHASES,+initData.calcPoint1.powerFactor,+initData.leadOrLag2,+initData.calcPoint1.loadCurrent)
        voltageDrop2 = doGetDropPerUnit(initData,voltage,m_dStdCapacity,Constant.MS_3PHASES,+initData.calcPoint1.powerFactor,+initData.leadOrLag2,+initData.calcPoint1.loadCurrent)
    }
        initData.voltageDrop_1_2 = voltageDrop1_2
        initData.voltageDrop2 = voltageDrop2
}
else{
    let voltageDrop1 =0
    let voltageDrop = 0
    if(sPhase){
        switch(upTransKind){
            case Constant.MS_TRANS1:
                voltageDrop1 = doGetDropVoltDiff(initData,m_dStdCapacity,voltage, Constant.MS_OUTSIDE_OUTSIDE,+initData.calcPoint0.powerFactor,+initData.leadOrLag1,+initData.calcPoint0.loadCurrent)
                voltageDrop = doGetDropPerUnit(initData,voltage,m_dStdCapacity,Constant.MS_OUTSIDE_OUTSIDE,+initData.calcPoint0.powerFactor,+initData.leadOrLag1,+initData.calcPoint0.loadCurrent)
                break;
            case Constant.MS_TRANSSCOTT:
                voltageDrop1 = doGetDropVoltDiff(initData,m_dStdCapacity,voltage, Constant.MS_SEAT_T,+initData.calcPoint0.powerFactor,+initData.leadOrLag1,+initData.calcPoint0.loadCurrent)
                voltageDrop = doGetDropPerUnit(initData,voltage,m_dStdCapacity,Constant.MS_SEAT_T,+initData.calcPoint0.powerFactor,+initData.leadOrLag1,+initData.calcPoint0.loadCurrent)
                break;
        }
    }
    else{
        voltageDrop1 = doGetDropVoltDiff(initData,m_dStdCapacity,voltage, Constant.MS_3PHASES,+initData.calcPoint0.powerFactor,+initData.leadOrLag1,+initData.calcPoint0.loadCurrent)
        voltageDrop = doGetDropPerUnit(initData,voltage,m_dStdCapacity,Constant.MS_3PHASES,+initData.calcPoint0.powerFactor,+initData.leadOrLag1,+initData.calcPoint0.loadCurrent)
    }
    initData.voltageDrop_1 = voltageDrop1
    initData.voltageDrop = voltageDrop
}
}
const doGetTextVoltage = (sPhase:boolean,partKind:number,voltage:number) =>{
    let text =''
    if(sPhase){
      switch(partKind){
        case Constant.MS_OUTSIDE_NEUTRAL:
          text = (voltage /2).toString()
          break;
        case Constant.MS_OUTSIDE_OUTSIDE:
          text = voltage.toString()
          break;
        case Constant.MS_SEAT_MAIN:
            text = voltage.toString()
            break;
        case Constant.MS_SEAT_T:
          text = voltage.toString()
          break;
      }
    }
    else{
      text = voltage.toString()
    }
    return text
}
const doSetFilterByLimit = (initdata:any,width:number,dLimitWidth:number,dLimitThickness:number = 0) =>{
  const busBarList = initdata.busBarList
  let filter = []
  if(width > 0 && dLimitWidth > 0 && dLimitThickness > 0){
      for(const item of busBarList){
          if(item.nwidth >= dLimitWidth && item.nthickness >= dLimitThickness ){
              filter.push(item)
          }
      }
  }
  else if(width > 0 && dLimitWidth > 0){
      for(const item of busBarList){
          if(item.nwidth >= dLimitWidth){
              filter.push(item)
          }
      }
  }
  else if(width > 0 && dLimitThickness > 0){
      for(const item of busBarList){
          if(item.nwidth == width && item.nthickness >= dLimitThickness){
              filter.push(item)
          }
      }
  }
  else if(dLimitThickness > 0 && dLimitWidth > 0){
      for(const item of busBarList){
          if(item.nthickness >= dLimitThickness && item.nwidth >= dLimitWidth){
              filter.push(item)
          }
      }
  }
  else if(width > 0){
      for(const item of busBarList){
          if(item.nwidth == width){
              filter.push(item)
          }
      }
  }
  else if(dLimitWidth > 0){
      for(const item of busBarList){
          if(item.nwidth >= dLimitWidth){
              filter.push(item)
          }
      }
  }
  else if(dLimitThickness > 0){
      for(const item of busBarList){
          if(item.nthickness >= dLimitThickness){
              filter.push(item)
          }
      }
  }
  return filter
}
const doInitComboThickness = (initData:any,kindPhase:number,dLimitThickness:number = 0) =>{
  if(kindPhase === 1){
      let filterValue = doSetFilterByLimit(initData,initData.m_3Phase.width,0,dLimitThickness)
      let thicknessList:any = [];
              filterValue?.forEach((item:any)=>{
          if(!thicknessList?.map((o:any) => o.value).includes(item.nthickness)){
              thicknessList.push({label:item.nthickness.toString(),
                  value:item.nthickness} as OptionModel)
              }
          })
          initData.m_3Phase.thicknessList = thicknessList

  }
  else if(kindPhase === 2 ){
      let limitThickness = (initData.m_3Phase.width * initData.m_3Phase.thickness * initData.m_3Phase.numberPhase / 2) /
                  (initData.phaseN.width * initData.phaseN.numberPhase);
      let filterValue = doSetFilterByLimit(initData,initData.phaseN.width,0,limitThickness)
      let thicknessList:any = [];
              filterValue?.forEach((item:any)=>{
          if(!thicknessList?.map((o:any) => o.value).includes(item.nthickness)){
              thicknessList.push({label:item.nthickness.toString(),
                  value:item.nthickness} as OptionModel)
              }
          })
          initData.phaseN.thicknessList = thicknessList

  }
  else if(kindPhase === 3 ){
      let limitThickness = (initData.m_3Phase.width * initData.m_3Phase.thickness * initData.m_3Phase.numberPhase / 2) /
                  (initData.phaseNPE.width * initData.phaseNPE.numberPhase);
      let filterValue = doSetFilterByLimit(initData,initData.phaseNPE.width,0,limitThickness)
      let thicknessList:any = [];
              filterValue?.forEach((item:any)=>{
          if(!thicknessList?.map((o:any) => o.value).includes(item.nthickness)){
              thicknessList.push({label:item.nthickness.toString(),
                  value:item.nthickness} as OptionModel)
              }
          })
          initData.phaseNPE.thicknessList = thicknessList

  }
}
const doSetFilterByValue = (initData:any,width:number,thickness:number,dLimitNumber:number = 0) =>{
  const busBarList = initData.busBarList
  let filter = []
  if(width > 0 && thickness > 0 && dLimitNumber > 0){
      for(const item of busBarList){
          if(item.nwidth == width && item.nthickness == thickness && item.nnumber == dLimitNumber ){
              filter.push(item)
          }
      }
  }
  else if(width > 0 && thickness > 0){
      for(const item of busBarList){
          if(item.nwidth == width && item.nthickness == thickness){
              filter.push(item)
          }
      }
  }
  else if(width > 0 && dLimitNumber > 0){
      for(const item of busBarList){
          if(item.nwidth == width && item.nnumber == dLimitNumber){
              filter.push(item)
          }
      }
  }
  else if(thickness > 0 && dLimitNumber > 0){
      for(const item of busBarList){
          if(item.nthickness == thickness && item.nnumber == dLimitNumber){
              filter.push(item)
          }
      }
  }
  else if(width > 0){
      for(const item of busBarList){
          if(item.nwidth == width){
              filter.push(item)
          }
      }
  }
  else if(thickness > 0){
      for(const item of busBarList){
          if(item.nthickness == thickness){
              filter.push(item)
          }
      }
  }
  else if(dLimitNumber > 0){
      for(const item of busBarList){
          if(item.nnumber == dLimitNumber){
              filter.push(item)
          }
      }
  }
  return filter
}
const doInitComboNumber = (initData:any,width:number,kindPhase:number) =>{
  if(kindPhase === 1){
      let filterValue = doSetFilterByValue(initData,width,initData.m_3Phase.thickness,0)
      let numberPhaseList:any = [];
      filterValue?.forEach((item:any)=>{
          if(!numberPhaseList?.map((o:any) => o.value).includes(item.nnumber)){
                  numberPhaseList.push({label:item.nnumber.toString(),
                                  value:item.nnumber} as OptionModel)
          }
      })
      initData.m_3Phase.numberPhaseList = numberPhaseList.sort((number1:any,number2:any)=>number1.value - number2.value)
  }
  else if(kindPhase === 2){
      const dLimitNumber = (initData.m_3Phase.width * initData.m_3Phase.thickness * initData.m_3Phase.numberPhase / 2) /
      (initData.phaseN.width * initData.phaseN.thickness);
      let filterValue = doSetFilterByValue(initData,width,+initData.phaseN.thickness,0)
      let numberPhaseList:any = [];
      filterValue?.forEach((item:any)=>{
          if(!numberPhaseList?.map((o:any) => o.value).includes(item.nnumber)){
            if(item.nnumber >= dLimitNumber){
                  numberPhaseList.push({label:item.nnumber.toString(),
                                  value:item.nnumber} as OptionModel)
            }
          }
      })
      initData.phaseN.numberPhaseList =numberPhaseList.sort((number1:any,number2:any)=>number1.value - number2.value)
  }
  else if(kindPhase === 3){
      const dLimitNumber = (initData.m_3Phase.width* initData.m_3Phase.thickness * initData.m_3Phase.numberPhase / 2) /
      (initData.phaseNPE.width * initData.phaseNPE.thickness);
      let filterValue = doSetFilterByValue(initData,width,+initData.phaseNPE.thickness,0)
      let numberPhaseList:any = [];
      filterValue?.forEach((item:any)=>{
          if(!numberPhaseList?.map((o:any) => o.value).includes(item.nnumber)){
            if(item.nnumber >= dLimitNumber){
                  numberPhaseList.push({label:item.nnumber.toString(),
                                  value:item.nnumber} as OptionModel)
            }
          }
      })
      initData.phaseNPE.numberPhaseList = numberPhaseList.sort((number1:any,number2:any)=>number1.value - number2.value)
  }
}
const doInitComboWidth = (initData:any,kindPhase:number) =>{
  if(kindPhase == 2){
  const limitWidth = (initData.m_3Phase.width * initData.m_3Phase.thickness * initData.m_3Phase.numberPhase / 2) /
            (initData.phaseN.thickness * initData.phaseN.numberPhase)
    let filterValue = doSetFilterByLimit (initData,0,limitWidth,0)
    let widthList:any = [];
    filterValue?.forEach((item:any)=>{
          if(!widthList?.map((o:any) => o.value).includes(item.nwidth)){
              widthList.push({label:item.nwidth.toString(),
                  value:item.nwidth} as OptionModel)
        }
    })
    initData.phaseN.widthList = widthList
  }
  else if(kindPhase == 3){
    const limitWidth = (initData.m_3Phase.width * initData.m_3Phase.thickness * initData.m_3Phase.numberPhase / 2) /
            (initData.phaseNPE.thickness * initData.phaseNPE.numberPhase)
    let filterValue = doSetFilterByLimit (initData,0,limitWidth,0)
    let widthList:any = [];
    filterValue?.forEach((item:any)=>{
          if(!widthList?.map((o:any) => o.value).includes(item.nwidth)){
              widthList.push({label:item.nwidth.toString(),
                  value:item.nwidth} as OptionModel)
        }
    })
    initData.phaseNPE.widthList = widthList
  }
  
}
  const handleGetSuccess = (data: any) => {
    let newData = data.data
    setDispInputDialog(data.dispInputDialog)
    // const thicknessList = [
    //   {label:'5',value:5},
    //   {label:'10',value:10}
    // ]
    // const numberPhaseList = [
    //   {label:'1',value:1},
    //   {label:'2',value:2},
    //   {label:'3',value:3},
    //   {label:'4',value:4}
    // ]
    let initData = {...initialValue}
    
    // initData = addListToData(initData, newData)
    // let convertData = convertDataFromBE(initData)
    // initData.voltageList = convertData.voltageList;
    const voltageList = [];
    if(newData.voltageList){
      for(const item of newData.voltageList){
        voltageList.push({label:item.lvoltage,value:item.lvoltage}as OptionModel)
      }
    }
    initData.voltageList = voltageList;
    
    const makerNameList:any = [];
    newData.makerNameList?.forEach((item:any)=>{
        makerNameList.push({label:item.smanuName.toString(),
            value:item.smanuName} as OptionModel)
    })
    const makeYearList:any = [];
    newData.makeYearList?.forEach((item:any)=>{
        makeYearList.push({label:item.toString(),
            value:item} as OptionModel)
    })
    const makeMonthList:any = [];
    newData.makeMonthList?.forEach((item:any)=>{
        makeMonthList.push({label:item.toString(),
            value:item} as OptionModel)
    })
    initData.busBarList  = newData.busBarList;
    initData.makerNameList = makerNameList
    initData.makeMonthList = makeMonthList
    initData.makeYearList = makeYearList
    doInitComboThickness(initData,1,0)
    doInitComboThickness(initData,2,0)
    doInitComboThickness(initData,3,0)
    doInitComboNumber(initData,initData.m_3Phase.width,1)
    doInitComboNumber(initData,initData.phaseN.width,2)
    doInitComboNumber(initData,initData.phaseNPE.width,3)
    
    // initData.m_3Phase.thicknessList = thicknessList
    // initData.phaseN.thicknessList = thicknessList
    // initData.phaseNPE.thicknessList = thicknessList

    // initData.m_3Phase.numberPhaseList= numberPhaseList
    // initData.phaseN.numberPhaseList= numberPhaseList
    // initData.phaseNPE.numberPhaseList= numberPhaseList

    let widthList :OptionModel[] =[]
    initData.busBarList?.forEach((item:any)=>{
        if(!widthList.map(o => o.value).includes(item.nwidth)){
          if(item.nwidth !== null){
            let optionModel :OptionModel ={
                label: item.nwidth.toString(),
                value: item.nwidth
            };
            widthList.push(optionModel)
          }
        }
    })
    initData.m_3Phase.widthList = widthList
    doInitComboWidth(initData,2)
    doInitComboWidth(initData,3)


    // let widthListPhaseNAndPhaseNPE: OptionModel[] =[]
    // widthList.forEach(item =>{
    //     if(item.value >=50){
    //         widthListPhaseNAndPhaseNPE.push(item)
    //     }
    // })
    // initData.phaseN.widthList = widthListPhaseNAndPhaseNPE
    // initData.phaseNPE.widthList = widthListPhaseNAndPhaseNPE


    let capacity  = initData.m_3Phase.basicCapacityM
        if(initData.m_3Phase.tempK2M > 0){
            capacity *= initData.m_3Phase.tempK2M
        }
        if(initData.m_3Phase.horizontalK3M > 0){
            capacity *= initData.m_3Phase.horizontalK3M
        }
        if(initData.m_3Phase.proximityK4M > 0){
            capacity *= initData.m_3Phase.proximityK4M
        }
        
      initData.m_3Phase.capacity = BeamsNumber(capacity,4)

      // phaseN
      let capacityPhaseN  = initData.phaseN.basicCapacityM
      if(initData.phaseN.tempK2M > 0){
        capacityPhaseN *= initData.phaseN.tempK2M
      }
      if(initData.phaseN.horizontalK3M > 0){
        capacityPhaseN *= initData.phaseN.horizontalK3M
      }
      if(initData.phaseN.proximityK4M > 0){
        capacityPhaseN *= initData.phaseN.proximityK4M
      }
      let capacityPhaseN1 = BeamsNumber(capacityPhaseN,4)
      initData.phaseN.capacity = capacityPhaseN1
      //PhaseNPE
      let capacityPhaseNPE  = initData.phaseNPE.basicCapacityM
      if(initData.phaseNPE.tempK2M > 0){
        capacityPhaseNPE *= initData.phaseNPE.tempK2M
      }
      if(initData.phaseNPE.horizontalK3M > 0){
        capacityPhaseNPE *= initData.phaseNPE.horizontalK3M
      }
      if(initData.phaseNPE.proximityK4M > 0){
        capacityPhaseNPE *= initData.phaseNPE.proximityK4M
      }
      let capacityPhaseNPE1 = BeamsNumber(capacityPhaseNPE,4)
      initData.phaseNPE.capacity = capacityPhaseNPE1

  initData.cComplex = {
    voltDropSumRe: -99999,
    voltDropSumIm:-99999,
    voltDropSumRe2: -99999,
    voltDropSumIm2:-99999

} as CComplex
  

    let shapes = diagramData[0].shape
    let result ={} as any 
    if(currentTabId !== 1){
      //inside transcenter
      result = doGetTransCenterSourceVolt(
      shapes,
      {
        id: id,
        image: image,
        x: x,
        y: y,
        offsetX: offsetX,
        offsetY: offsetY,
        width: width,
        height: height,
        type: type,
        isSelected: isSelected,
        rotation: rotation,
        chart: chart,
        isRotationEnabled: false,
        parentGroupId,
        properties: properties
      },
      Constant.GET_TOP_BOTTOM_LEFT_RIGHT,
      false,
      0
    )
    }
    else{
      //outside transcenter
      result = doGetSourceVolt(
        {
          id: id,
          image: image,
          x: x,
          y: y,
          offsetX: offsetX,
          offsetY: offsetY,
          width: width,
          height: height,
          type: type,
          isSelected: isSelected,
          rotation: rotation,
          chart: chart,
          isRotationEnabled: false,
          parentGroupId,
          properties: properties
        },
        shapes,
        Constant.GET_TOP_BOTTOM_LEFT_RIGHT,
        false,
        0
      )
    }
    
    
    if( result.voltage > 0){
      initData.isSourceVoltage = true
    }
    else{
      initData.isSourceVoltage = false
    }
    let isModeSPhases:boolean = false;
    if(result.sPhase){
        let text =''
        if(result.upTransKind ===  Constant.MS_TRANS1){
          setIsDisplayTabConnectTran1(true)
          setIsDisplayTabConnectTranscott(false)
          // initData.wireSystem = 0
          isModeSPhases = true;
          initData.sPhase = true
          initData.upTransKind = Constant.MS_TRANS1
          text = doGetTextVoltage(initData.sPhase,Constant.MS_OUTSIDE_OUTSIDE,initData.voltage)
                        + '-' + doGetTextVoltage(initData.sPhase,Constant.MS_OUTSIDE_NEUTRAL,initData.voltage)
          setIsVoltText(text)
        }
        else if(result.upTransKind ===  Constant.MS_TRANSSCOTT){
          setIsDisplayTabConnectTranscott(true)
          setIsDisplayTabConnectTran1(false)
          // initData.wireSystem = 0
          isModeSPhases = true
          initData.sPhase = true
          initData.upTransKind = Constant.MS_TRANSSCOTT
          text = doGetTextVoltage(initData.sPhase,Constant.MS_SEAT_MAIN,initData.voltage)
                        + '-' + doGetTextVoltage(initData.sPhase,Constant.MS_SEAT_T,initData.voltage)
          setIsVoltText(text)
        }
    }
    else{
      setIsDisplayTabConnectTran1(false)
      setIsDisplayTabConnectTranscott(false)
      // initData.wireSystem = 1
      isModeSPhases = false
      initData.sPhase = false
      initData.upTransKind = 0
    }
    initData.isModeSPhases = isModeSPhases
    if(result.sPhase){
      switch(result.upTransKind){
        case Constant.MS_TRANS1:
            initData.calcPoint0.calcKind = Constant.CALC_IK2_OUTSIDE_OUTSIDE;
            // initData.calcPoint0 ={
            //   loadCurrent: initData.calcPoint0.loadCurrent,
            //   isLead: initData.calcPoint0.isLead,
            //   powerFactor: initData.calcPoint0.powerFactor,
            //   calcKind: Constant.CALC_IK2_OUTSIDE_OUTSIDE,
            //   stdCapacity:0,
            //   loadCapacity: initData.calcPoint0.loadCapacity,
            //   voltage:initData.voltage
            // } as CalcPoint
            initData.calcPoint1.calcKind = Constant.CALC_IK2_OUTSIDE_NEUTRAL;
            // initData.calcPoint1 ={
            //     loadCurrent: initData.calcPoint1.loadCurrent,
            //     isLead: initData.calcPoint1.isLead,
            //     powerFactor: initData.calcPoint1.powerFactor,
            //     calcKind: Constant.CALC_IK2_OUTSIDE_NEUTRAL,
            //     stdCapacity:0,
            //     loadCapacity: initData.calcPoint1.loadCapacity,
            //     voltage:initData.voltage
            // } as CalcPoint
          break;
        case Constant.MS_TRANSSCOTT:
          initData.calcPoint0.calcKind = Constant.CALC_IK2_SEAT_T;
          // initData.calcPoint0 ={
          //   loadCurrent: initData.calcPoint0.loadCurrent,
          //   isLead: initData.calcPoint0.isLead,
          //   powerFactor: initData.calcPoint0.powerFactor,
          //   calcKind: Constant.CALC_IK2_SEAT_T,
          //   stdCapacity:0,
          //   loadCapacity: initData.calcPoint0.loadCapacity,
          //   voltage:initData.voltage
          // } as CalcPoint
          initData.calcPoint1.calcKind = Constant.CALC_IK2_SEAT_MAIN;
          // initData.calcPoint1 ={
          //     loadCurrent: initData.calcPoint1.loadCurrent,
          //     isLead: initData.calcPoint1.isLead,
          //     powerFactor: initData.calcPoint1.powerFactor,
          //     calcKind: Constant.CALC_IK2_SEAT_MAIN,
          //     stdCapacity:0,
          //     loadCapacity: initData.calcPoint1.loadCapacity,
          //     voltage:initData.voltage
          // } as CalcPoint
          break;
      }
    }
    else{
      initData.calcPoint0.calcKind = Constant.CALC_IK3;
      // initData.calcPoint0 ={
      //   loadCurrent: initData.calcPoint0.loadCurrent,
      //   isLead: initData.calcPoint0.isLead,
      //   powerFactor: initData.calcPoint0.powerFactor,
      //   calcKind: Constant.CALC_IK3,
      //   stdCapacity:0,
      //   loadCapacity: initData.calcPoint0.loadCapacity,
      //   voltage:initData.voltage
      // } as CalcPoint
      initData.calcPoint1.calcKind = Constant.CALC_IK2;
      // initData.calcPoint1 ={
      //     loadCurrent: initData.calcPoint1.loadCurrent,
      //     isLead: initData.calcPoint1.isLead,
      //     powerFactor: initData.calcPoint1.powerFactor,
      //     calcKind: Constant.CALC_IK2,
      //     stdCapacity:0,
      //     loadCapacity: initData.calcPoint1.loadCapacity,
      //     voltage:initData.voltage
      // } as CalcPoint
    }
    
    initData.m_3Phase.ohmR = BeamsNumber(initData.lenght * initData.m_3Phase.ohmRPerM,4)
    initData.m_3Phase.ohmX = BeamsNumber(initData.lenght * initData.m_3Phase.ohmXPerM,4)
  
    initData.phaseN.ohmR = BeamsNumber(initData.lenght * initData.phaseN.ohmRPerM,4)
    initData.phaseN.ohmX = BeamsNumber(initData.lenght * initData.phaseN.ohmXPerM,4)
  
    initData.phaseNPE.ohmR = BeamsNumber(initData.lenght * initData.phaseNPE.ohmRPerM,4)
    initData.phaseNPE.ohmX = BeamsNumber(initData.lenght * initData.phaseNPE.ohmXPerM,4)
  
    doSetValueVoltDrop(initData,initData.voltage,true,initData.sPhase,initData.upTransKind)
    doSetValueVoltDrop(initData,initData.voltage,false,initData.sPhase,initData.upTransKind)
    setInitialValue(initData)
    setLoadingFlag(false)
    setIsOpenEditorDialog(true)
  }
  const handleUpdateSuccess = async (data: any) => {
    console.log('>>>> handle Successs');
    if(data && data.resultCode == 0){
      const result = await getElements(userId, projectData.projectId, data.data.element_ids,projectData.ownerId)
      if (result.success) {
        mappedDataFromBE(result.data)
      } else {
        handleSubmitError(result.error)
      }
    }
    setLoadingFlag(false);
  }
  // updateElementPostのAPI呼び出しエラー時
  const handleSubmitError = (error: any) => {
    console.log('>>>> handle Error');
    console.log(error);
    setLoadingFlag(false);
  }
  const handleAddUserCurve = async (controlId:string,data:any)=>{
    const request = { 
      userId: userId,
      projectId: projectData.projectId,
      elementId: parseInt(controlId),
      params:{...data},
      ownerProject: projectData.ownerId

    }
    // addUserCurveDB(controlId,request)
    const result = await post("/diagram/add-curve-user", request);
    if (result.success) {
      handleAddCurveUserSuccess(id, result.data);
    } else {
      handleSubmitError(result.error);
    }
  }
  const handleUpdateUserCurve = async (controlId:string,data:any) =>{
    const request = { 
      userId: userId,
      projectId: projectData.projectId,
      elementId: parseInt(controlId),
      params:{...data},
      ownerProject: projectData.ownerId

    }
    // updateUserCurveDB(controlId,request)
    const result = await post("/diagram/edit-curve-user", request);
    if (result.success) {
      handleUpdateCurveUserSuccess(id, result.data);
    } else {
      handleSubmitError(result.error);
    }
  }
  const handleDeleteUserCurve = async (controlId:string,data:any) =>{
    const request = { 
      userId: userId,
      projectId: projectData.projectId,
      elementId: parseInt(controlId),
      userCurveId: data["userCurveId"],
      ownerProject: projectData.ownerId

    }
    // deleteUserCurveDB(controlId,request)
    deleteRelatedGraphByUserCurveDialog({controlId, data: data["userCurveId"], mode : true})
    const result = await post("/diagram/delete-curve-user", request);
    if (result.success) {
      handleDeleteCurveUserSuccess(id, result.data);
    } else {
      handleSubmitError(result.error);
    }
  }
  const handleAddCurveUserSuccess = (controlId:string,data:any) =>{
    addUserCurve(controlId,data)
    setListUserCurves(prev => [...prev, data])
  }
  const handleUpdateCurveUserSuccess =(controlId:string,data:any) =>{
    const foundUserCurve = listUserCurves.findIndex((curve:any) => curve.userCurveId === data["userCurveId"])
    const newList = [...listUserCurves]
    newList[foundUserCurve] = data
    updateUserCurve(controlId,data)
    setListUserCurves(newList)

  }
  const handleDeleteCurveUserSuccess = (controlId:string,data:any) =>{
      const newList = [...listUserCurves]
      newList.splice(newList.findIndex((curve : any) => curve.userCurveId === data), 1)
      let params = {
        userCurveId: data,
        projectId: projectData.projectId,
      }
      deleteUserCurve(controlId,params)
      setListUserCurves([...newList])

  }
  //#endregion

  return (
    <>
      {isOpenMenu &&
        <OptionMenu
          controlId={id}
          isOpenMenu={isOpenMenu}
          setIsOpenMenu={handleOpenMenu}
          onProperties={handleOpenEditorDialog}
          x={menuPosition.x}
          y={menuPosition.y}
          isDisabledDelete={false}
          isDisabledCut={false}
          isDisabledCopy={false}
          isDisabledPaste={false}
          isDisabledOpenGraph={false}
          isDisabledProps={false}
          isDisabledRotate={false}
          onUserCurves={() => setIsOpenUserCurvesDialog(true)}
        />
      }

      <Label
        ref={labelRef}
        listening={false}
        width={Constant.LABEL_WIDTH}
        x={labelPosition.x}
        y={labelPosition.y}
        offsetX={[90, -90].includes(rotation) ? 8 : -5}
        offsetY={
          rotation === 0 ? ((labelTexts.textHeight/12 +1) * Constant.LINE_HEIGHT) / 2 : 0
        }
      >
        <Text
            text={labelTexts.title}
            y={(rotation !== 0? 4:  -10)}
            fill={isSelected ? "red" : '#f800f8'}
          />
        {labelTexts.body.length > 0 &&
          <Text
              y={(rotation !== 0? width + Constant.ELEMENT_SIZE: Constant.LINE_HEIGHT - 10)}
              text={labelTexts.body}
              fill={isSelected ? "red" : 'blue'}
          />
        }
        {/* {labelTexts.length > 0 &&
         labelTexts.map((item:any, index:any) => {
            
          let content = item.value; 
          let offsetY = rotation !== 0? (index * Constant.LINE_HEIGHT + 4) : (index * Constant.LINE_HEIGHT - 10);
          rotation !== 0 &&
            index !== 0 &&
            (offsetY = (index - 1) * Constant.LINE_HEIGHT + width + Constant.ELEMENT_SIZE);

          return (
            <Text
              y={offsetY}
              key={index}
              text={content}
              fill={isSelected ? "red" : item.color}
            />
          );
        })} */}
      </Label>

      <Group
        ref={groupRef}
        id={id}
        x={groupPosition.x}
        y={groupPosition.y}
        offset={{ x: offsetX, y: offsetY }}
        width={width}
        height={height}
        draggable={processMode === Model.ProcessMode.DRAWING && !m_bModePM && !modeViewOnly && !isOnShiftKey}
        type={type}
        onClick={handleClick}
        onTap={handleClick}
        onContextMenu={handleContextMenu}
        rotation={rotation}
        onDblClick={handleOpenEditorDialog}
        onDblTap={handleOpenEditorDialog}
        onMouseDown={() => transformMoveRef.current?.nodes([groupRef.current])}
        onMouseUp={() => transformMoveRef.current?.nodes([])}
        onDragStart={handleDragStart}
        onDragMove={handleDragmove}
        onDragEnd={onDragEnd}
        onMouseLeave={(e: any) => {
          const container = e.target.getStage().container();
          container.style.cursor = "default";
        }}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <Image
          image={lineObj}
          height={height}
          width={width}
          onMouseEnter={(e: any) => {
            const container = e.target.getStage().container();
            container.style.cursor = (processMode === Model.ProcessMode.DRAWING && !m_bModePM && !modeViewOnly) ? "move" : "crosshair";
          }}
        />
        {
          (processMode === Model.ProcessMode.PERCENT_Z) &&
          <>
            
            <Image
              fill={getSymbolAndBackColor(infoSkeleton,processMode,properties).backColor}
              image={lineOhmObj}
              height={34}
              width={13}
              x={3.5}
              y={height / 2 - 17 }
              onMouseEnter={(e: any) => {
                const container = e.target.getStage().container();
                container.style.cursor = "crosshair";
              }}
            />
            
          </>
        }
         {
          (processMode === Model.ProcessMode.POWER_FLOW) &&
          <>
            <Image
              fill={getSymbolAndBackColor(infoSkeleton,processMode,properties).backColor}
              image={lineOhmPercentZObj}
              height={Constant.ELEMENT_SIZE * 3}
              width={Constant.ELEMENT_SIZE}
              y={height / 2 - 30 }
              onMouseEnter={(e: any) => {
                const container = e.target.getStage().container();
                container.style.cursor = "crosshair";
              }}
            />
          </>
        }
        { (processMode !== Model.ProcessMode.PERCENT_Z && processMode !== Model.ProcessMode.POWER_FLOW) &&
          <Image
            fill={getSymbolAndBackColor(infoSkeleton,processMode,properties).backColor}
            image={lineObj}
            height={height}
            onMouseEnter={(e: any) => {
              const container = e.target.getStage().container();
              container.style.cursor = (processMode === Model.ProcessMode.DRAWING && !m_bModePM && !modeViewOnly) ? "move" : "crosshair";
            }}
          />
        }
        {processMode === Model.ProcessMode.DRAWING && !m_bModePM && !modeViewOnly && isSelected && !parentGroupId && (
          <>
            <Rect
              ref={topRef}
              fill="black"
              x={6.75}
              y={0}
              width={7}
              height={7}
              draggable
              onMouseDown={() => setTransformFlag(false)}
              dragBoundFunc={(e: any) => dragBoundFunc(e, "top")}
              onDragStart={(e: any) => handleMouseDown(e, "top")}
              onDragMove={(e: any) => handleMouseMove(e, "top")}
              onDragEnd={(e: any) => handleMouseUp(e, "top")}
              onMouseEnter={(e: any) => {
                const container = e.target.getStage().container();
                container.style.cursor =
                  rotation === 0 ? "ns-resize" : "ew-resize";
              }}
            />
            <Rect
              ref={bottomRef}
              fill="black"
              x={6.75}
              y={height - 7}
              width={7}
              height={7}
              draggable
              onMouseDown={() => setTransformFlag(false)}
              dragBoundFunc={(e: any) => dragBoundFunc(e, "bottom")}
              onDragStart={(e: any) => handleMouseDown(e, "bottom")}
              onDragMove={(e: any) => handleMouseMove(e, "bottom")}
              onDragEnd={(e: any) => handleMouseUp(e, "bottom")}
              onMouseEnter={(e: any) => {
                const container = e.target.getStage().container();
                container.style.cursor =
                  rotation === 0 ? "ns-resize" : "ew-resize";
              }}
            />
          </>
        )}
      </Group>
      {((properties.dropRegular|| properties.dropMotor) &&!([Model.ProcessMode.CALCULATION,Model.ProcessMode.IMPROVE_PF,Model.ProcessMode.HARMONIC,Model.ProcessMode.POWER_FLOW].includes(processMode)) && !m_bModePM) && (
        <Image
          ref={leftArrowRef}
          listening={false}
          offsetY={[90, -90].includes(rotation) ? Constant.ELEMENT_SIZE / 4 - 2.5 : -2.5}
          x={arrowPosition.x}
          y={arrowPosition.y}
          image={leftArrowObj}
          width={Constant.ELEMENT_SIZE / 2}
          height={Constant.ELEMENT_SIZE}
        />
      )}
      
      { showPowerInfo &&
        <>
        <Image  
            image={imageSVGActivePower} 
            listening={false}
            x={arrowPosition.x + svgPosX1}
            y={arrowPosition.y + svgPosY1}
          />
 
          <Image  
            image={imageSVGReactivePower} 
            listening={false}
            x={arrowPosition.x + svgPosX2}
            y={arrowPosition.y + svgPosY2}
          />
        </>
           
      }
      { showPowerDirectInfo &&
        <>
          <Image  
            image={imageSVGActiveReactivePower} 
            listening={false}
            x={arrowPosition.x + svgPosX3}
            y={arrowPosition.y + svgPosY3 + 25}
          />
        </>
           
      }
      {(properties.fault && !([Model.ProcessMode.VOLTDROP,Model.ProcessMode.IMPROVE_PF,Model.ProcessMode.HARMONIC,Model.ProcessMode.POWER_FLOW].includes(processMode)) && !m_bModePM) && (
        <>
        <Image
          ref={rightArrowRef}
          listening={false}
          offsetY={[90, -90].includes(rotation) ? Constant.ELEMENT_SIZE / 4 : 0}
          offsetX={-Constant.ELEMENT_SIZE / 2}
          x={arrowPosition.x}
          y={arrowPosition.y}
          image={rightArrowObj}
          width={Constant.ELEMENT_SIZE * 2}
          height={Constant.ELEMENT_SIZE * 2}
        />
        <Text
          text={properties.pointText}
          fontSize={10}
          fill="red"
          offsetY={[90, -90].includes(rotation) ? Constant.ELEMENT_SIZE / 4 : 0}
          offsetX={-Constant.ELEMENT_SIZE / 2}
          x={arrowPosition.x + 10}
          y={arrowPosition.y}
        />
        </>
      )}

      <Transformer
        ref={transformRef}
        rotateEnabled={false}
        resizeEnabled={false}
        keepRatio={false}
        borderDash={[4]}
        anchorStroke="black"
        borderStroke="black"
        anchorFill="black"
      />

      <Transformer
        ref={transformMoveRef}
        rotateEnabled={false}
        resizeEnabled={false}
        keepRatio={false}
        borderEnabled={false}
        anchorStroke="black"
        borderStroke="black"
        anchorFill="black"
      />

      {isOpenEditorDialog && !isHidden && (
        <Html>
          <Dialog open={true} fullWidth
            PaperComponent={DraggablePaper}
          >
            <DialogTitle
              onMouseOver={(e : any) => e.target.style.cursor = 'move'}
              style={{
                paddingTop: "2px",
                paddingBottom: "2px",
                background: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
              }}
            >
              ブスバー
            </DialogTitle>
            <DialogContent>
              <MsBusbarEditDialog
                data={initialValue}
                onCancel={handleCancelEditorDialog}
                onOK={handleUpdate}
                setIsDisplayTabConnectTran1 ={isDisplayTabConnectTran1}
                setIsDisplayTabConnectTranscott={isDisplayTabConnectTranscott}
                setIsVoltText={isVoltText}
                currentTabId={currentTabId !== 1?true:false}
                infoCircuit ={infoCircuit}
                userRole={userRole}
                m_bModePM={m_bModePM}
                modeViewOnly={modeViewOnly}
                dispInputDialog={dispInputDialog}
                processMode={processMode}
              ></MsBusbarEditDialog>
            </DialogContent>
          </Dialog>
        </Html>
      )}

      {/* ============== USER CURVE ============== */}
      {isOpenUserCurvesDialog && !isHidden && (
      <Html>
        <UserCurvesDialog
          type={type}
          addUserCurve={handleAddUserCurve}
          updateUserCurve={handleUpdateUserCurve}
          deleteUserCurve={handleDeleteUserCurve}
          listUserCurves ={listUserCurves}
          controlId={id}
          controlProperties={properties}
          modeViewOnly={modeViewOnly}
          onCancel={() => {setIsOpenUserCurvesDialog(false); openUserCurveEvent('')}}
          onDraw={(data : any) => {   
            setOpenChart(true, LineType.USER_CURVE, data.userCurveId??data.user_curve_id)
              setIsOpenUserCurvesDialog(false)
        }}
        />
      </Html>
      )}

      {isOpenUserCurveProperties && !isHidden &&
        <Html>
            <UserCurveDetailDialog modeViewOnly={modeViewOnly} type={type} selectedRow={selectItem} properties={properties} onOK={handleOKUserCurveDetailForm} onCancel={handleCancelUserCurveDetailForm}></UserCurveDetailDialog>
        </Html>
      }
      {loadingFlag && isSelected && (
        <Html>
          <Dialog open={true} maxWidth={"md"} fullWidth>
          <div id="loadingFlag" style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1500, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
              <CircularProgress />
          </div>
          </Dialog>
        </Html>
      )}
  </>
  );
});

const mapStateToProps = (state: any) => {
  return {
    userId: state.app.user?.userId,
    projectData:  state.app.projectData,
    processMode: state.app.diagram.processMode,
    diagramData: state.app.diagram.diagramData.filter((r: any) => r.tabId === state.app.diagram.currentIDDiagramTab),
    mainDiagramData: state.app.diagram.diagramData.find((r: any) => r.tabId === 1),
    currentTabId: state.app.diagram.currentIDDiagramTab,
    openDialogData : state.app.diagram.openDialog["OPEN_DIALOG"],
    openUserCurveData: state.app.diagram.openUserCurve["OPEN_USER_CURVE"],
    infoCircuit: state.app.diagram.infoCircuit,
    userCurveProperties: state.app.diagram.openUserCurveProperties["OPEN_USER_CURVE_PROPERTIES"],
    clearMenuState: state.app.diagram.clearOptionMenu,
    userRole: state.app.user?.userRole,
    m_bModePM: state.app.diagram.m_bModePM,
    modeViewOnly: state.app.diagram.modeViewOnly,
    infoSkeleton:state.app.diagram.infoSkeleton,
    drawPowerArrow: state.app.diagram.isDrawPowerArrow,
    diagramDataUpdate: state.app.diagram.diagramDataUpdate,
    isSelectTouchGroup: state.app.diagram.isSelectTouchGroup,
    tcGroups: state.app.diagram.tcGroups,
    elementGroups: state.app.diagram.elementGroups,
    elementReposition: state.app.diagram.elementReposition,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    updateSelectControl: (controlId: string, isMultiple : boolean = false) =>
      dispatch(updateSelectControl(controlId, isMultiple)),
    updateControlData: (id: string, data: object) =>
      dispatch(updatePropertiesOfControlAction(id, data)),
    updateTransCenterProps: (id: string, data: object) =>
      dispatch(updatePropertiesOfTransCenter(id, data)),
    updateControlHeight: (
      id: string,
      height: number,
      x: number,
      y: number,
      offsetY: number
    ) => dispatch(updateControlHeight(id, height, x, y, offsetY)),
    setTransformFlag: (visible: boolean) => dispatch(setTransformFlag(visible)),
    addUserCurve: (controlId : string, data : any) => dispatch(addUserCurve(controlId, data)),
    // updateElementPost: (data: any, controlId: string) => dispatch(actionCreators.fetch(`UPDATE_BUSBAR${controlId}`, "/diagram/set-param", "POST", data, false, true)),
    // getElementParam: (data: any, controlId: string) => dispatch(actionCreators.fetch(`GET_BUSBAR${controlId}`, "/diagram/get-param", "POST", data, false, true)),
    // addUserCurveDB: (controlId:string,data : any) => dispatch(actionCreators.fetch(`ADD_CURVE_USER_BUSBAR${controlId}`, "/diagram/add-curve-user", "POST", data, false, true)),
    // updateUserCurveDB: (controlId:string,data : any) => dispatch(actionCreators.fetch(`UPDATE_CURVE_USER_BUSBAR${controlId}`, "/diagram/edit-curve-user", "POST", data, false, true)),
    // deleteUserCurveDB: (controlId:string,data : any) => dispatch(actionCreators.fetch(`DELETE_CURVE_USER_BUSBAR${controlId}`, "/diagram/delete-curve-user", "POST", data, false, true)),
    updateUserCurve: (controlId : string, data : any) => dispatch(updateUserCurve(controlId, data)),
    deleteUserCurve: (controlId : string, data : any) => dispatch(deleteUserCurve(controlId, data)),
    setOpenChart: (isOpenChart: boolean, lineType : string, lineId : number) => dispatch(openChart(isOpenChart, lineType, lineId)),
    clearOptionMenu: (state:boolean) => dispatch(clearOptionMenu(state)),
    openDialogEvents: (param : any) => dispatch(openDialogEvents(param)),
    openUserCurveEvent: (params: any) => dispatch(openUserCurveEvents({type: "OPEN_USER_CURVE", data: { id: params }})),
    openUserCurvePropertiesEvent: (controlId: any, userCurveId: any) => dispatch(openUserCurvePropertiesEvents({type: "OPEN_USER_CURVE_PROPERTIES", data: { controlId: controlId, userCurveId: userCurveId }})),
    deleteRelatedGraphByUserCurveDialog: (params:any) => dispatch(deleteRelatedGraphByUserCurveDialog(params)),
    mappedDataFromBE: (elements:any) => dispatch(actionCreators.mappedData(elements)),
    setRepositionElement: (id:any, state:boolean) => dispatch(setRepositionElement(id, state)),
    setUndoData: (data:Model.UndoModel) => dispatch(undo(data)),
    updateElementPosition:(id:any, x:any, y:any) => dispatch(updateElementPosition(id, x, y)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateMsBusbar);
