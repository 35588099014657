import { store } from "../../..";
import { AdjustElements, CdPoint, ControlModel, GraphLineData, MsLVCBModel, MsSourceModel, MsThermalModel, Point, keyable } from "../../../models/Index";
import { MS_MODE_CALCULATION } from "../../../statics";
import { isPointFaultThermal } from "../../../utils/THERMALFunction";
import { doSetPosCurvePointDirect } from "../DCView";
import { doDrawCurveAdjustAll, doReadDataFromDB } from "../adjust";


const doDrawCurveThermal = async (props : MsThermalModel, element : ControlModel, willCallDb : boolean, drawMode: number) => {
    let graphLineData : GraphLineData = { lines: [] }
    let listInfo : any[] = []
    
    if (willCallDb || element.raw === undefined) { // read data from db
        listInfo = await doReadDataFromDB(props as AdjustElements, element, drawMode === 2 ? true : false)
        graphLineData.raw = listInfo
    } else if (element.raw) { // read data from raw (saved in control when first read)
        listInfo = element.raw
    }

    // draw curve adjust
    const pAdjustObj = doDrawCurveAdjustAll(props as AdjustElements, listInfo, 50,true)
    // pAdjustObj.m_listPointCenter.length > 0 && graphLineData.lines.push(pAdjustObj.m_listPointCenter.map((point : CdPoint) => ({x: point.m_dx, y: point.m_dy})))
    // pAdjustObj.m_listPointMax.length > 0 && graphLineData.lines.push(pAdjustObj.m_listPointMax.map((point : CdPoint) => ({x: point.m_dx, y: point.m_dy})))
    // pAdjustObj.m_listPointMin.length > 0 && graphLineData.lines.push(pAdjustObj.m_listPointMin.map((point : CdPoint) => ({x: point.m_dx, y: point.m_dy})))

    if(drawMode === 0 || drawMode === 1) {
        graphLineData = doDrawCurveAdjustAll(props as AdjustElements, listInfo, 0,true)
    }
    
    if(drawMode === 0 || drawMode === 2) {
         // get data draw calcpoint
        let mode = store.getState().app.diagram.processMode;
        if (mode >= MS_MODE_CALCULATION && isPointFaultThermal(props)) {
            let temp = doSetPosCurvePointDirect(props.calcPoint0, 0);
            if(temp !== undefined) {
                graphLineData.lines.push(temp);
            }

            temp = doSetPosCurvePointDirect(props.calcPoint1, 1);
            if(temp !== undefined) {
                graphLineData.lines.push(temp)
            }
        }
    }

    return graphLineData
}

export {
    doDrawCurveThermal
}