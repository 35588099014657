import * as AppStore from './AppStore';

export type FormState<T = any> = {
    value: T | undefined;
    valid: boolean;
    errors: { [key in keyof T]?: string };
    submitting: boolean;
}

export type FetchState<T = any> = {
    requested: boolean;
    loading: boolean;
    data?: T;
    success?: boolean;
    error?: any;
}

export type AppState = AppStore.AppState
export type ApplicationState = {
    app: AppState
}
export const actionCreators = AppStore.actionCreators
export const selectors = AppStore.selectors
export const reducers = {
    app: AppStore.reducer
}
export const middlewares = [AppStore.middleware]
