import { MS_OUTSIDE_NEUTRAL, MS_OUTSIDE_OUTSIDE, MS_SEAT_MAIN, MS_SEAT_T } from "../models/Constants";
import { ControlModel, MsCableHeadModel } from "../models/Index";

//CEleCableHead::DoGetTextVoltage
export const doGetTextVoltage = (props:MsCableHeadModel, nPartKind:number, bUnit:boolean = true) =>
{
	let sText = '';
	if (props.sPhase) {
		switch (nPartKind) {
		case MS_OUTSIDE_NEUTRAL:	
			sText = (props.voltage / 2).toString();
			break;
		case MS_OUTSIDE_OUTSIDE:	
			sText = props.voltage.toString();
			break;
		case MS_SEAT_MAIN:			
			sText = props.voltage.toString();
			break;
		case MS_SEAT_T:				
			sText = props.voltage.toString();
			break;
		}
	}
	else {
		sText = props.voltage.toString();
	}
	if (bUnit) {
		sText += 'V';
	}
	return sText;
}

//CEleCableHead::DoGetRefV1
export const doGetRefV1 = (props:any) =>
{
	//CEleCableHead::DoGetRefV1Left  sRefV1Left
	let labelTexts = [{
		value: props.refNo,
		color: "#f800f8",
	}];

    return labelTexts;
}