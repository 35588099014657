import { CognitoUser } from 'amazon-cognito-identity-js';
import { Auth } from "aws-amplify";
import dayjs from "dayjs";
import { Node, NodeConfig } from "konva/lib/Node";
import { Action, Middleware, Reducer } from "redux";
import { ApplicationState } from ".";
import { getUser } from "../components/CallApi";
import { getVoltageCombo, willCallDb } from "../components/graph/graphMethods";
import * as Constants from "../models/Constants";
import * as Element from "../models/ElementKind";
import * as Model from "../models/Index";
import { AppStore, Message, UserDetail } from "../models/Index";
import { yyyymmdd2Date } from "../utils/DataConverter";
import { getElementCoordinatesData, getElementKindValue, mapElementsResponseToLocal } from "../utils/ElementFunction";
import { groupBy } from "../utils/groupBy";
import { ADD_CALC_POINT_TO_CHART, ADD_LINE_TO_CHART, ADD_UNDO_DATA_TO_CHART, CLEAR_OPTION_MENU, DELETE_GRAPH_SCREEN, DELETE_RELATED_GRAPH_BY_USER_CURVE_DIALOG, IS_ADD_DRAW_POWER_ARROW, IS_DRAW_POWER_ARROW, IS_SELECT_TOUCH_GROUP, SAVE_CHART_INTO_CHARTDATA, SET_CHART_ID, SET_GRAPH_DATA, UPDATE_ELEMENT_POSITION, addLineToChart, deleteRelatedGraphById, reDrawGraphText, saveAllControlsToDiagram, setDiagramDataUpdate } from "./Actions";
import * as Method from "./Method";
import { VOLT_SIDE_SECONDARY } from '../statics';

//#region Constants
export const RESET_DIAGRAM_PROJECT_DATA = "RESET_DIAGRAM_PROJECT_DATA";
export const SAVE_PROJECT_DATA = "SAVE_PROJECT_DATA";
export const SAVE_INFO_CIRCUIT = "SAVE_INFO_CIRCUIT";
export const SAVE_ELEMENT_GROUPS = "SAVE_ELEMENT_GROUPS";
export const SAVE_TC_GROUPS = "SAVE_TC_GROUPS";
export const SAVE_INFO_SKELETON = "SAVE_INFO_SKELETON";
export const SAVE_INFO_COVER = "SAVE_INFO_COVER"
export const SAVE_REPORT = "SAVE_REPORT"

export const SAVE_ELEMENT_TEMPLATES = "SAVE_ELEMENT_TEMPLATES";
export const SAVE_ALL_CONTROLS_TO_DIAGRAM = "SAVE_ALL_CONTROLS_TO_DIAGRAM";
export const DIAGRAM_DATA_UPDATE = "DIAGRAM_DATA_UPDATE";
export const SAVE_SHAPES = "SAVE_SHAPES";
export const SAVE_CHART_INTO_CONTROL = "SAVE_CHART_INTO_CONTROL";
export const DELETE_DIAGRAM_BY_TABID = "DELETE_DIAGRAM_BY_TABID";
export const SAVE_CURRENT_ID_DIAGRAM_TAB = "SAVE_CURRENT_ID_DIAGRAM_TAB";
export const SAVE_ALL_CONTROLS_TO_CHART_TAB = "SAVE_ALL_CONTROLS_TO_CHART_TAB";
export const UPDATE_LINE_INFO = "UPDATE_LINE_INFO";
export const REDRAW_CHART = "REDRAW_CHART";
export const CHECK_ZEROCOND_CONNECT = "CHECK_ZEROCOND_CONNECT";
export const DELETE_CHART_TAB_BY_TABID = "DELETE_CHART_TAB_BY_TABID";
export const DELETE_GRAPH_TEXT_BY_RELATED_GRAPH = "DELETE_GRAPH_TEXT_BY_RELATED_GRAPH";
export const REDRAW_GRAPH_TEXT = "REDRAW_GRAPH_TEXT";
export const DELETE_RELATED_GRAPH_BY_ID = "DELETE_RELATED_GRAPH_BY_ID";
export const DELETE_GRAPH_BY_GRAPH_NO = "DELETE_GRAPH_BY_GRAPH_NO";
export const IS_SHOW_SCREEN = "IS_SHOW_SCREEN";
export const SET_REPOSITION_ELEMENT = "SET_REPOSITION_ELEMENT";
export const IS_DOWNLOAD_DIAGRAM_PDF = "IS_DOWNLOAD_DIAGRAM_PDF";
export const SAVE_CURRENT_ID_CHART_TAB = "SAVE_CURRENT_ID_CHART_TAB";
export const SAVE_MAXID_CHART_TAB = "SAVE_MAXID_CHART_TAB";
export const CREATE_CHART_TAB = "CREATE_CHART_TAB";
export const PUBLISH_CHART_EVENTS = "PUBLISH_CHART_EVENTS";
export const OPEN_DIALOG_EVENTS = "OPEN_DIALOG_EVENTS";
export const OPEN_USER_CURVE_EVENTS = "OPEN_USER_CURVE_EVENTS";
export const OPEN_USER_CURVE_PROPERTIES_EVENTS = "OPEN_USER_CURVE_PROPERTIES_EVENTS";
export const SET_GAP_LINE = "SET_GAP_LINE";
export const SAVE_NOMINAL_VOLT_LIST = "SAVE_NOMINAL_VOLT_LIST";
export const SAVE_NEW_NOMINAL_VOLT = "SAVE_NEW_NOMINAL_VOLT";
export const TOGGLE_JP_ELECTRIC_MODE = "TOGGLE_JP_ELECTRIC_MODE";
export const SAVE_CHART_ZOOM = "SAVE_CHART_ZOOM";
export const ELEMENT_MAX_ID = "ELEMENT_MAX_ID";
export const TYPES_MAX_ID = "TYPES_MAX_ID";
export const SET_DATA_UNDO = "SET_DATA_UNDO";
export const OPEN_CHART = "OPEN_CHART";
export const ROTATE = "ROTATE";
export const UPDATE_SELECT_CONTROL = "UPDATE_SELECT_CONTROL";
export const UNSELECT_ALL_CONTROLS = "UNSELECT_ALL_CONTROLS";
export const SET_COPY_DATA = "SET_COPY_DATA";
export const COPY_SHAPES = "COPY_SHAPES";
export const CUT_SHAPES = "CUT_SHAPES";
export const PASTE_SHAPES = "PASTE_SHAPES";
export const REMOVE_SHAPES = "REMOVE_SHAPES";
export const GROUP_SHAPES = "GROUP_SHAPES";
export const TC_GROUP_SHAPES = "TC_GROUP_SHAPES";
export const ADD_NEW_TC_GROUP = "ADD_NEW_TC_GROUP";
export const UN_GROUP_SHAPES = "UN_GROUP_SHAPES";
export const TC_UN_GROUP_SHAPES = "TC_UN_GROUP_SHAPES";
export const SET_POINTER_POSITION = "SET_POINTER_POSITION";
export const UPDATE_PROPERTIES_OF_CONTROL = "UPDATE_PROPERTIES_OF_CONTROL";
export const UPDATE_PROPERTIES_OF_TRANS_CENTER = "UPDATE_PROPERTIES_OF_TRANS_CENTER";
export const UPDATE_CONTROL_HEIGHT = "UPDATE_CONTROL_HEIGHT";
export const UPDATE_CONTROL_WIDTH = "UPDATE_CONTROL_WIDTH";
export const SET_TRANSFORM_DATA = "SET_TRANSFORM_DATA";
export const SET_TRANSFORM_FLAG = "SET_TRANSFORM_FLAG";
export const ADD_USER_CURVE = "ADD_USER_CURVE"
export const UPDATE_USER_CURVE = "UPDATE_USER_CURVE"
export const DELETE_USER_CURVE = "DELETE_USER_CURVE"
export const CHANGE_PROCESS_MODE = "CHANGE_PROCESS_MODE";
export const SAVE_GROUP_LIST = "SAVE_GROUP_LIST";
export const UPDATE_STD_CAPACITY = "UPDATE_STD_CAPACITY"
export const UPDATE_FREQUENCY = "UPDATE_FREQUENCY"
export const UPDATE_QUICK_PROPERTIES = "UPDATE_QUICK_PROPERTIES";
export const CREATE_RELATED_GRAPH = "CREATE_RELATED_GRAPH";
export const UNDO_RELATED_GRAPH = "UNDO_RELATED_GRAPH";
export const UPDATE_RELATED_GRAPH = "UPDATE_RELATED_GRAPH";
export const UPDATE_RELATED_GRAPH_TEXT = "UPDATE_RELATED_GRAPH_TEXT";
export const DELETE_RELATED_GRAPH = "DELETE_RELATED_GRAPH";
export const UPDATE_GRAPH = "UPDATE_GRAPH";
export const GET_GRAPH = "GET_GRAPH";
export const GET_GRAPH2 = "GET_GRAPH2";
export const GET_GRAPH_DISP_BAND = "GET_GRAPH_DISP_BAND";
export const GET_GRAPH_NAME = "GET_GRAPH_NAME";
export const GET_GRAPH_NAME_GRAPH = "GET_GRAPH_NAME_GRAPH";
export const GET_RELATED_GRAPH = "GET_RELATED_GRAPH";
export const UPDATE_GRAPH_DISP_BAND = "UPDATE_GRAPH_DISP_BAND";
export const DELETE_GRAPH = "DELETE_GRAPH";
export const DIAGRAM_PDF = "DIAGRAM_PDF";
export const MAIN_DIAGRAM_PDF = "MAIN_DIAGRAM_PDF";
export const MAPPED_DATA = "MAPPED_DATA";
export const CHANGE_MODE_PM = "CHANGE_MODE_PM";
export const CHANGE_MODE_VIEW_ONLY = "CHANGE_MODE_VIEW_ONLY";
export const SAVE_LIST_GRAPH_NAME = "SAVE_LIST_GRAPH_NAME";
export const PRESS_CONTEXT_MENU_FUNCTION = "PRESS_CONTEXT_MENU_FUNCTION";
export const DELETE_TC_GROUP = "DELETE_TC_GROUP";
export const SAVE_LIST_GRAPH = "SAVE_LIST_GRAPH";
export const DISPLAY_DEVICE_NAME = "DISPLAY_DEVICE_NAME"
export const IS_SHOW_MAINTENANCE_SCREEN = "IS_SHOW_MAINTENANCE_SCREEN"
export const SET_NOTIFICATION_LIST = "SET_NOTIFICATION_LIST"
export const IS_SHOW_MY_PROJECT = "IS_SHOW_MY_PROJECT"


//#endregion

interface FetchState {
  requested: boolean;
  loading: boolean;
  success?: boolean;
  data?: any;
  error?: any;
}

interface FormState<T = any> {
  initialValue: T;
  value: T;
  error: boolean;
  pristine: boolean;
  errors: {
    [fieldName in keyof T]?: string;
  };
  touched: {
    [fieldName in keyof T]?: boolean;
  };
  submitting: boolean;
  validationNeeded: boolean;
}

export interface AppState {
  loading: boolean;
  initAppLoading: boolean;
  messages: Message[];
  user: UserDetail | null;
  resetPassUserName: string;
  isPasswordResetRequired: boolean;
  fetch: {
    [url: string]: FetchState;
  };
  forms: {
    [url: string]: FormState;
  };
  container: {
    producerCode: number;
    date: string;
  };
  searchParams: string;
  pageSize: number;
  diagram: AppStore;
  projectData: Model.ProjectModel;
  notificationList: Model.NotificationModel[] | null;
}

const generateRandomUUID = () => {
  // UUIDs have 16 byte values
  const bytes = new Uint8Array(16);

  // Seed bytes with cryptographically random values
  crypto.getRandomValues(bytes);
  // Set required fields for an RFC 4122 random UUID
  bytes[6] = (bytes[6] & 0x0f) | 0x40;
  bytes[8] = (bytes[8] & 0x3f) | 0x80;
  // Convert bytes to hex and format appropriately
  const uuid = Array.prototype.map
    .call(bytes, (b, i) => {
      // Left-pad single-character values with 0,
      // Convert to hexadecimal,
      // Add dashes
      return (
        (b < 16 ? "0" : "") +
        b.toString(16) +
        (i % 2 && i < 10 && i > 2 ? "-" : "")
      );
    })
    .join("");
  // Return the string
  return uuid;
};

//---------------------------
// ログイン
//---------------------------
// コマンド
export interface ChangePassAction {
  type: "CHANGE_PASS";
  payload: {
    currentPassword: string;
    newPassword: string;
  };
}
export interface LoginAction {
  type: "LOGIN";
  payload: {
    credential: Model.Credential;
  };
}
export interface verificationCodeAction {
  type: "VERIFICATION_CODE";
  payload: {
    code: string;
  };
}
export interface cancleVerificationCodeAction {
  type: "CANCLE_VERIFICATION_CODE";
}
export interface InitAppAction {
  type: "INIT_APP";
}
export interface ClearSessionAction {
  type: "CLEAR_SESSION";
}
// イベント
export interface AppLoadedAction {
  type: "APP_LOADED";
}
export interface AppLoadingAction {
  type: "APP_LOADING";
}
export interface SessionFetchedAction {
  type: "SESSION_FETCHED";
  payload: { cognitoUser: CognitoUser };
}
export interface SessionExpiredAction {
  type: "SESSION_EXPIRED";
}
export interface AuthorizeAction {
  type: "AUTHORIZE";
  payload: { userProfile: UserDetail };
}
export interface ResetPassAction {
  type: "RESET_PASS";
  payload: {
    resetPassUserName: string;
  };
}
export interface ExitResetPassAction {
  type: "EXIT_RESET_PASS";
}
//---------------------------
// メッセージ表示
//---------------------------
// コマンド
export interface ShowMessageAction {
  type: "SHOW_MESSAGE";
  payload: {
    message: Message;
    dissmissAfterMs: number;
  };
}
export interface DismissMessageAction {
  type: "DISMISS_MESSAGE";
  payload: { messageId: string };
}
// イベント
export interface MessageAddedAction {
  type: "MESSAGE_ADDED";
  payload: Message;
}
export interface MessageRemovedAction {
  type: "MESSAGE_REMOVED";
  payload: Message;
}

//---------------------------
// データ取得
//---------------------------
// コマンド
type HttpMethod = "GET" | "POST" | "PUT" | "DELETE";
export interface FetchAction {
  type: "FETCH";
  payload: {
    fetchId: string;
    url: string;
    method: HttpMethod;
    params?: { [key: string]: any };
    multipart: boolean;
    json: boolean;
    retry: number;
  };
}
export interface InitFetchAction {
  type: "INIT_FETCH";
  payload: { fetchId: string };
}
export interface DiscardFetchAction {
  type: "DISCARD_FETCH";
  payload: { fetchId: string };
}
export interface DownloadFileAction {
  type: "DOWNLOAD_FILE";
  payload: {
    fetchId: string;
    title: string;
    expectedFilename: string;
    expectedMimeType: string;
    url: string;
    params?: { [key: string]: any };
    method?: string;
    searchParams?: boolean;
  };
}
// イベント
export interface FetchCompletedAction {
  type: "FETCH_COMPLETED";
  payload: { fetchId: string; success: boolean; data?: any; error?: any };
}

export interface SetContainerAction {
  type: "SET_CONTAINER";
  payload: { producerCode: number; date: string };
}

export interface SetSearchParamsAction {
  type: "SET_SEARCH_PARAMS";
  payload: { params: string };
}

export interface SetPageSizeAction {
  type: "SET_PAGE_SIZE";
  payload: { pageSize: number };
}

//---------------------------
// データ編集
//---------------------------
// コマンド
export interface InitFormAction {
  type: "INIT_FORM";
  payload: { formId: string; initialValue: { [key: string]: any } };
}
export interface TouchFormAction {
  type: "TOUCH_FORM";
  payload: { formId: string; key: string };
}
export interface ChangeFormAction {
  type: "CHANGE_FORM";
  payload: { formId: string; name: string; newValue: any };
}
export interface ValidateFormAction {
  type: "VALIDATE_FORM";
  payload: { formId: string; errors: FormState["errors"] };
}
export interface SubmitFormAction {
  type: "SUBMIT_FORM";
  payload: { formId: string };
}
export interface ResetFormAction {
  type: "RESET_FORM";
  payload: { formId: string };
}
export interface DiscardFormAction {
  type: "DISCARD_FORM";
  payload: { formId: string };
}
// イベント
export interface FormSubmittedAction {
  type: "FORM_SUBMITTED";
  payload: { formId: string };
}
export interface FormSubmissionCompletedAction {
  type: "FORM_SUBMISSION_COMPLETED";
  payload: { formId: string; success: boolean; returnData?: any; error?: any };
}
// Control action
export interface UpdateControlPropertiesAction {
  type: "UPDATE_PROPERTIES_OF_CONTROL",
  payload: object,
  id: string
}

export interface UpdateControlHeightAction {
  type: "UPDATE_CONTROL_HEIGHT",
  payload: any,
  id: string,
  x: any,
  y: any,
}

export interface SaveShapesAction {
  type: "SAVE_SHAPES",
  payload: any,
  controlChange:Model.ControlModel[],
  flagSavePreStateDiagram: any,
}
export interface RotateControlAction {
  type: "ROTATE",
  payload:any,
}
export interface RemoveControlAction {
  type: "REMOVE_SHAPES",
  payload: number,
  id: string
}
export interface CutControlAction {
  type: "CUT_SHAPES",
  payload: number,
  id: string
}
export interface UpdateUserCurveAction {
  type: "UPDATE_USER_CURVE",
  payload: {
    controlId: string,
    data: any
  },
}
export interface DeleteUserCurveAction {
  type: "DELETE_USER_CURVE",
  payload: {
    controlId: string,
    data: any
  },
}

export interface UpdateQuickPropertiesAction{
    type: "UPDATE_QUICK_PROPERTIES";
    payload: { field:string, value:boolean};
}

export interface MappedDataAction{
  type: "MAPPED_DATA"
  payload:{
    elements:any;
  }
}

export interface SetUndoDataAction{
  type: "SET_UNDO_DATA",
  payload: {
    data : Model.UndoModel
  }
}

export interface SaveListGraphNameAction{
  type: "SAVE_LIST_GRAPH_NAME",
  payload: {
    data : any
  }
}

export interface DeleteTcGroupAction{
  type: "DELETE_TC_GROUP",
  payload:{
    tc_id:any
  }
}

export interface SaveListGraphAction{
  type: "SAVE_LIST_GRAPH",
  payload:{
    projectData:any,
  }
}
  
type KnownAction =
  | LoginAction
  | verificationCodeAction
  | cancleVerificationCodeAction
  | ChangePassAction
  | InitAppAction
  | AppLoadedAction
  | AppLoadingAction
  | ClearSessionAction
  | SessionFetchedAction
  | SessionExpiredAction
  | ShowMessageAction
  | DismissMessageAction
  | MessageAddedAction
  | MessageRemovedAction
  | FetchAction
  | InitFetchAction
  | DiscardFetchAction
  | DownloadFileAction
  | FetchCompletedAction
  | InitFormAction
  | TouchFormAction
  | ChangeFormAction
  | ValidateFormAction
  | SubmitFormAction
  | ResetFormAction
  | DiscardFormAction
  | FormSubmittedAction
  | FormSubmissionCompletedAction
  | SetContainerAction
  | SetSearchParamsAction
  | SetPageSizeAction
  | UpdateControlPropertiesAction
  | RemoveControlAction
  | CutControlAction
  | UpdateUserCurveAction
  | DeleteUserCurveAction
  | UpdateControlHeightAction
  | RotateControlAction
  | SaveShapesAction
  | UpdateQuickPropertiesAction
  | MappedDataAction
  | SetUndoDataAction
  | SaveListGraphNameAction
  | ExitResetPassAction
  | DeleteTcGroupAction
  | SaveListGraphAction

// アクションクリエーター
export const actionCreators = {
  login: (credential: Model.Credential) =>
  ({
    type: "LOGIN",
    payload: {credential}
  } as LoginAction),
  verificationCode: (code: string) =>
  ({
    type: "VERIFICATION_CODE",
    payload: {code}
  } as verificationCodeAction),
  cancleVerificationCode: () =>
  ({
    type: "CANCLE_VERIFICATION_CODE"
  } as cancleVerificationCodeAction),
  changePassword: (currentPassword: string, newPassword: string) =>
  ({
    type: "CHANGE_PASS",
    payload: {
      currentPassword, newPassword
    }
  } as ChangePassAction),
  exitResetPassword: () =>
  ({
    type: "EXIT_RESET_PASS",
  } as ExitResetPassAction),
  init: () =>
  ({
    type: "INIT_APP",
  } as InitAppAction),
  clearSession: () =>
  ({
    type: "CLEAR_SESSION",
  } as ClearSessionAction),
  showMessage: (message: Message, dissmissAfterMs: number = 5000) =>
  ({
    type: "SHOW_MESSAGE",
    payload: {
      message,
      dissmissAfterMs,
    },
  } as ShowMessageAction),
  dismissMessage: (messageId: string) =>
  ({
    type: "DISMISS_MESSAGE",
    payload: {
      messageId,
    },
  } as DismissMessageAction),
  fetch: (
    fetchId: string,
    url: string,
    method: HttpMethod,
    params: object | null,
    multipart: boolean = false,
    json: boolean = false,
    retry: number = 0,
  ) =>
  ({
    type: "FETCH",
    payload: {
      fetchId,
      url,
      method,
      params,
      multipart,
      json,
      retry,
    },
  } as FetchAction),
  
  changeModePM:(
    data: Boolean
  ) =>
  ({
    type: CHANGE_MODE_PM,
    payload: data,
  }),
  changeModeViewOnly:(
    data: Boolean
  ) =>
  ({
    type: CHANGE_MODE_VIEW_ONLY,
    payload: data,
  }),
  isShowMaintenanceScreen:(show:boolean,controlId?:number) =>({
    type: IS_SHOW_MAINTENANCE_SCREEN,
    payload:{show:show,controlId:controlId}
  }),
 isShowMyProject:(show:boolean) =>({
    type: IS_SHOW_MY_PROJECT,
    payload: show
}),
  setNotificationList:(notificationList:Model.NotificationModel[]) =>({
    type: SET_NOTIFICATION_LIST,
    payload: notificationList
  }),
  resetDiagramAndProjectData:() =>
  ({
    type: RESET_DIAGRAM_PROJECT_DATA,
  }),

  saveOpenProjectData:(
    projectData: object
  ) =>
  ({
    type: SAVE_PROJECT_DATA,
    payload: projectData,
  }),
  
  saveInfoCircuit:(
    infoCircuit: object
  ) =>
  ({
    type: SAVE_INFO_CIRCUIT,
    payload: infoCircuit,
  }),
  
  saveElementGroups:(
    elementGroups: object
  ) =>
  ({
    type: SAVE_ELEMENT_GROUPS,
    payload: elementGroups,
  }),
  
  saveTCGroups:(
    tcGroups: object
  ) =>
  ({
    type: SAVE_TC_GROUPS,
    payload: tcGroups,
  }),

  saveInfoSkeleton:(
    infoSkeleton: object
  ) =>
  ({
    type: SAVE_INFO_SKELETON,
    payload: infoSkeleton,
  }),
  saveReport :(
    report: object
   ) =>
  ({
    type: SAVE_REPORT,
    payload: report,
  }),

  saveInfoCover:(
    infoCover: object
   ) =>
  ({
    type: SAVE_INFO_COVER,
    payload: infoCover,
  }),
  
  saveElementTemplates:(
    templates: object
  ) =>
  ({
    type: SAVE_ELEMENT_TEMPLATES,
    payload: templates,
  }),  

  saveAllControlsToDiagram:(
    data: Model.DiagramModel, flagSavePreStateDiagram: boolean,bDiagramUpdate?:boolean
  ) =>
  ({
        type: SAVE_ALL_CONTROLS_TO_DIAGRAM,
        payload: data,
        flagSavePreStateDiagram: flagSavePreStateDiagram,
        bDiagramUpdate: bDiagramUpdate
  }),

  setShapes:(
    data: Model.DiagramModel, flagSavePreStateDiagram: boolean
  ) =>
  ({
        type: SAVE_SHAPES,
        payload: data,
        flagSavePreStateDiagram: flagSavePreStateDiagram
  }),

  saveChartIntoControl:(
    data: Model.ControlModel[]
  ) =>
  ({
      type: SAVE_CHART_INTO_CONTROL,
      payload: data,
  }),


  deleteDiagramByTabId:(
    data: number
  ) =>
  ({
    type: DELETE_DIAGRAM_BY_TABID,
    payload: data,
  }),


  saveCurrentIdDiagramTab:(
    data: Number
  ) =>
  ({
    type: SAVE_CURRENT_ID_DIAGRAM_TAB,
    payload: data,
  }),

  addUndoDataToChart:(
    data: any
  ) =>
  ({
    type: ADD_UNDO_DATA_TO_CHART,
    payload: data,
  }),

  saveGroupList:(
    data: Model.GroupListModel[]
  ) =>
  ({
    type: SAVE_GROUP_LIST,
    payload: data,
  }),

  updateStdCapacity:(
    data: any
  ) =>
  ({
    type: UPDATE_STD_CAPACITY,
    payload: data,
  }),

  updateFrequency:(
    data: any
  ) =>
  ({
    type: UPDATE_FREQUENCY,
    payload: data,
  }),

  saveAllControlsToChartTab:(
    data: Model.ControlModel[], flagEdit: boolean
  ) =>
  ({
    type: SAVE_ALL_CONTROLS_TO_CHART_TAB,
    payload: data,
    flagEdit: flagEdit
  }),
  
  updateLineInfo: (data : Model.LineStyleModel, lineId : string | number, controlId : number) => {
    return {
        type: UPDATE_LINE_INFO,
        payload: {
            data,
            lineId,
            controlId
        },
    };
  },

  checkZerocondConnect:() => ({
    type: CHECK_ZEROCOND_CONNECT,
  }),

  redrawChart:() => ({
    type: REDRAW_CHART,
  }),

  deleteGraphTextByRelatedGraph:(
    data: any
  ) =>
  ({
    type: DELETE_GRAPH_TEXT_BY_RELATED_GRAPH,
    payload: data,
  }),

  reDrawGraphText:(
    data: any
  ) =>
  ({
    type: REDRAW_GRAPH_TEXT,
    payload: data,
  }),

  deleteRelatedGraphByUserCurveDialog:(
    data: number
  ) =>
  ({
    type: DELETE_RELATED_GRAPH_BY_USER_CURVE_DIALOG,
    payload: data,
  }),

  deleteChartTabByTabId:(
    data: number
  ) =>
  ({
    type: DELETE_CHART_TAB_BY_TABID,
    payload: data,
  }),

  deleteRelatedGraphById:(
    data: number
  ) =>
  ({
    type: DELETE_RELATED_GRAPH_BY_ID,
    payload: data,
  }),

  deleteGraphByGraphNo:(
    data: number
  ) =>
  ({
    type: DELETE_GRAPH_BY_GRAPH_NO,
    payload: data,
  }),

  isShowScreen:(
    screen:string,
  ) =>
  ({
    type: IS_SHOW_SCREEN,
    payload: screen,
  }),

  setRepositionElement:(
    id:any,
    state:boolean
  ) =>
  ({
    type: SET_REPOSITION_ELEMENT,
    payload: {
      id,
      state
    },
  }),

  isDownloadLineDiagramPDF:(
    state:boolean,
    type:number,
    image:any
  ) =>
  ({
    type: IS_DOWNLOAD_DIAGRAM_PDF,
    payload: {
      state,
      type,
      image
    },
  }),

  saveCurrentIdChartTab:(
    data: Number
  ) =>
  ({
    type: SAVE_CURRENT_ID_CHART_TAB,
    payload: data,
  }),


  saveMaxIdChartTab:(
  ) =>
  ({
    type: SAVE_MAXID_CHART_TAB,
  }),

  createChartTab:(
  ) =>
  ({
    type: CREATE_CHART_TAB,
  }),
  
  publishChartEvents:(
  ) =>
  ({
    type: PUBLISH_CHART_EVENTS,
  }),

  openDialogEvents:(
    ) =>
    ({
      type: OPEN_DIALOG_EVENTS,
    }),

  setGraphData:(
    ) =>
    ({
      type: SET_GRAPH_DATA,
    }),
  
  setGapLine:(
  ) =>
  ({
    type: SET_GAP_LINE,
  }),

  saveElementMaxId:() => ({ type: ELEMENT_MAX_ID, }),

  saveTypesMaxId:() => ({ type: TYPES_MAX_ID, }),

  setChartId:() => ({type: SET_CHART_ID}),

  undo:(
    data: Model.UndoModel
  ) =>
  ({
    type: SET_DATA_UNDO,
    payload: data,
  }),


  openChart:(
    isOpenChart: boolean
  ) =>
  ({
    type: OPEN_CHART,
    payload: isOpenChart,
  }),

  addToChart:(
    isAddToChart: boolean
  ) =>
  ({
    type: ADD_LINE_TO_CHART,
    payload: isAddToChart,
  }),

  isDrawPowerArrow:(
    data: any
  ) =>
  ({
    type: IS_DRAW_POWER_ARROW,
    payload: data,
  }),

  isAddDrawPowerArrow:(
    data: any
  ) =>
  ({
    type: IS_ADD_DRAW_POWER_ARROW,
    payload: data,
  }),

  deleteGraphScreen:(
    isOpenGraphScreen: boolean,
    isDelete: boolean
  ) =>
  ({
    type: DELETE_GRAPH_SCREEN,
    payload: {
      isOpenGraphScreen,
      isDelete
    }
  }),

  addCalcPointToChart:(
    isAddCalcPointToChart: boolean
  ) =>
  ({
    type: ADD_CALC_POINT_TO_CHART,
    payload: isAddCalcPointToChart,
  }),

  rotate:(
    tabId: number
  ) =>
  ({
    type: ROTATE,
    payload: tabId,
  }),

  updateSelectControl:(
    tabId: string
  ) =>
  ({
    type: UPDATE_SELECT_CONTROL,
    payload: tabId,
  }),

  isSelectTouchGroup:(
    state: boolean
  ) =>
  ({
    type: IS_SELECT_TOUCH_GROUP,
    payload: state,
  }),

  clearOptionMenu:(
    state: boolean
  ) =>
  ({
    type: CLEAR_OPTION_MENU,
    payload: state,
  }),
  
  unSelectAllControls:(
    tabId: string
  ) =>
  ({
    type: UNSELECT_ALL_CONTROLS,
    payload: tabId,
  }),

  setCopyData:(data: any) =>
  ({
    type: COPY_SHAPES,
    payload: data,
  }),

  copyAction:(
    tabId: number
  ) =>
  ({
    type: COPY_SHAPES,
    payload: tabId,
  }),


  cutAction:(
    tabId: number
  ) =>
  ({
    type: CUT_SHAPES,
    payload: tabId,
  }),

  pasteAction:(
    data: { tabId: number, pointerPosition: Model.Coordinates }
  ) =>
  ({
    type: PASTE_SHAPES,
    payload: data,
  }),

  removeAction:(
    tabId: number
  ) =>
  ({
    type: REMOVE_SHAPES,
    payload: tabId,
  }),

  groupAction:(
    groupingIds: string[]
  ) =>
  ({
    type: GROUP_SHAPES,
    payload: groupingIds,
  }),

  unGroupAction: (newGroupStack: string[][], groupIds: string[]) => {
    return {
        type: GROUP_SHAPES,
        payload: {
            newGroupStack, 
            groupIds
        },
    };
},

  setPointerPositionAction:(
    pointerPosition: Model.Coordinates
  ) =>
  ({
    type: SET_POINTER_POSITION,
    payload: pointerPosition,
  }),


  updatePropertiesOfControlAction:(
    id: string, data: object
  ) =>
  ({
    type: UPDATE_PROPERTIES_OF_CONTROL,
    payload: data,
    id: id
  }),
  
  updatePropertiesOfTransCenter:(
    id: string, data: object
  ) =>
  ({
    type: UPDATE_PROPERTIES_OF_TRANS_CENTER,
    payload: data,
    id: id
  }),

  updateControlHeight:(
    id: string, data: number, x: number, y: number, offsetY: number
  ) =>
  ({
    type: UPDATE_CONTROL_HEIGHT,
    payload: data,
    id: id,
    x: x,
    y: y,
    offsetY: offsetY
  }),

  updateElementPosition:(
    id: string, x: number, y: number
  ) =>
  ({
    type: UPDATE_ELEMENT_POSITION,
    payload: {
    id: id,
    x: x,
    y: y}
  }),

  updateControlWidth: (id: string, width: number) =>
  ({
    type: UPDATE_CONTROL_WIDTH,
    payload: {id, width}
  }),

  mappedData:(elements:any) => ({
    type: MAPPED_DATA,
    payload:elements
  }),

  initFetch: (fetchId: string) =>
  ({
    type: "INIT_FETCH",
    payload: {
      fetchId,
    },
  } as InitFetchAction),
  discardFetch: (fetchId: string) =>
  ({
    type: "DISCARD_FETCH",
    payload: {
      fetchId,
    },
  } as DiscardFetchAction),
  initForm: (formId: string, initialValue: any) =>
  ({
    type: "INIT_FORM",
    payload: {
      formId,
      initialValue: initialValue,
    },
  } as InitFormAction),
  touchForm: (formId: string, key: string) =>
  ({
    type: "TOUCH_FORM",
    payload: {
      formId,
      key,
    },
  } as TouchFormAction),
  changeForm: (formId: string, name: string, newValue: any) =>
  ({
    type: "CHANGE_FORM",
    payload: {
      formId,
      name,
      newValue,
    },
  } as ChangeFormAction),
  validateForm: (formId: string, errors: FormState["errors"] = {}) =>
  ({
    type: "VALIDATE_FORM",
    payload: {
      formId,
      errors,
    },
  } as ValidateFormAction),
  submitForm: (formId: string) =>
  ({
    type: "SUBMIT_FORM",
    payload: {
      formId,
    },
  } as SubmitFormAction),
  submissionComplete: (formId: string) =>
  ({
    type: "FORM_SUBMISSION_COMPLETED",
    payload: {
      formId,
    },
  } as FormSubmissionCompletedAction),
  resetForm: (formId: string) =>
  ({
    type: "RESET_FORM",
    payload: {
      formId,
    },
  } as ResetFormAction),
  discardForm: (formId: string) =>
  ({
    type: "DISCARD_FORM",
    payload: {
      formId,
    },
  } as DiscardFormAction),
  downloadFile: (
    fetchId: string,
    title: string,
    expectedFilename: string,
    expectedMimeType: string,
    url: string,
    params?: any,
    method?: string,
    searchParams: boolean = false
  ) =>
  ({
    type: "DOWNLOAD_FILE",
    payload: {
      fetchId,
      title,
      expectedFilename,
      expectedMimeType,
      url,
      params,
      method,
      searchParams,
    },
  } as DownloadFileAction),
  setContainer: (producerCode: number, date: string) =>
  ({
    type: "SET_CONTAINER",
    payload: {
      producerCode,
      date,
    },
  } as SetContainerAction),
  setSearchParams: (params: string) =>
  ({
    type: "SET_SEARCH_PARAMS",
    payload: {
      params,
    },
  } as SetSearchParamsAction),
  setPageSize: (pageSize: number) =>
  ({
    type: "SET_PAGE_SIZE",
    payload: {
      pageSize,
    },
  } as SetPageSizeAction),
  setTransformData: (shapes: Node<NodeConfig>[]) => ({
    type: SET_TRANSFORM_DATA,
    payload: shapes,
  }),
  changeProcessMode: (mode: Model.ProcessMode) => ({
    type: CHANGE_PROCESS_MODE,
    payload: mode,
  }),
  setTransformFlag: (visible: boolean) => ({
    type: SET_TRANSFORM_FLAG,
    payload: visible,
  }),
};

export const selectors = {
  isAuthorize: (state: ApplicationState) => {
    if (state.app.user) {
      const cognit_user: any = state.app.user.cognit_user
      if (cognit_user.signInUserSession) return true;
    }
    return false;
  },
  isNewPasswordRequired: (state: ApplicationState) => {
    if (state.app.user) {
      const cognit_user: any = state.app.user.cognit_user
      if (cognit_user.signInUserSession) return false;
      if(cognit_user.challengeName === 'NEW_PASSWORD_REQUIRED') return true;
    } else{
      return false;
    }
  },
  isMFARequired: (state: ApplicationState) => {
    if (state.app.user) {
      const cognit_user: any = state.app.user.cognit_user
      if (cognit_user.signInUserSession) return false;
      if(cognit_user.challengeName === 'CUSTOM_CHALLENGE') return true;
    } else{
      return false;
    }
  },
  isPasswordResetRequired: (state: ApplicationState) => {
    if (state.app.user) {
      const cognit_user: any = state.app.user.cognit_user
      if (cognit_user.signInUserSession) return false;
    } else{
      return state.app.isPasswordResetRequired;
    }
  },
  getFormState: (state: ApplicationState, formId: string) => {
    if (!(state.app.forms && state.app.forms[formId])) {
      return {
        value: undefined,
        valid: false,
        errors: {},
        touched: {},
        error: false,
        submitting: false,
        validating: false,
      };
    }
    const {
      value,
      submitting,
      error,
      pristine,
      errors,
      touched,
      validationNeeded,
    } = state.app.forms[formId];
    let retErrors: { [fieldName: string]: string | undefined } = {};
    if (!pristine) {
      retErrors = errors;
    } else if (errors && touched) {
      for (let prop in touched) {
        retErrors[prop] = errors[prop];
      }
    }
    return {
      value: value,
      valid: !validationNeeded && !error,
      errors: retErrors,
      touched: touched,
      error: error,
      submitting: submitting,
      validating: validationNeeded,
    };
  },
  getFetchState: (state: ApplicationState, fetchId: string): any => {
    if (!(state.app.fetch && state.app.fetch[fetchId])) {
      return {
        loading: false,
      };
    }
    return state.app.fetch[fetchId];
  },
};

export const middleware: Middleware<{}, ApplicationState> = ({
  dispatch,
  getState,
}) => (next) => (incomingAction: Action) => {
  const action = incomingAction as KnownAction;
  if (
    ![
      "UPDATE_PROPERTIES_OF_CONTROL",
      "REMOVE_SHAPES",
      "CUT_SHAPES"
    ]
    .includes(action.type)
  ) next(action);
  
  if (action.type === "LOGIN") {
    const { credential } = action.payload;
    // サインイン
    Auth.signIn(credential.username, credential.password)
      .then((cognitoUser: any) => {
        const signInUserSession = cognitoUser.signInUserSession;
        if (signInUserSession) {
          // サインイン成功
          dispatch({
            type: "SESSION_FETCHED",
            payload: { 
              cognitoUser: cognitoUser
            }
          } as SessionFetchedAction);
        } else {
          // 強制パスワード変更またはMFA認証が必要
          const user = {
            name:cognitoUser.username,
            company_name:"",
            cognit_user: cognitoUser,
            cognito_username: credential.username
          } as UserDetail;
          dispatch({
            type: "AUTHORIZE",
            payload: { userProfile: user }
          } as AuthorizeAction);
        }
      }).catch((err: any) => {
        if (err.code == "PasswordResetRequiredException") {
          // 管理者によるパスワードリセット要求中
          dispatch(
            actionCreators.showMessage({
              type: "error",
              title: "ログイン",
              body: "現在のパスワードは無効になりました。\nパスワードを再設定してください。",
            },8000)
          );
          dispatch({
            type: "RESET_PASS",
            payload: { resetPassUserName: credential.username }
          } as ResetPassAction);
        } else {
          dispatch(
            actionCreators.showMessage({
              type: "error",
              title: "ログイン",
              body: "ログインに失敗しました。",
            })
          );
        }
      });
  }

  if (action.type === "VERIFICATION_CODE") {
    const { code } = action.payload;
    const cognitUser = getState().app.user?.cognit_user;
    Auth.sendCustomChallengeAnswer(cognitUser, code)
    .then((cognitoUser: any) => {
      const success = cognitoUser.preferredMFA === 'NOMFA'
      if(success) {
        dispatch({
          type: "SESSION_FETCHED",
          payload: { 
            cognitoUser: cognitoUser
          }
        } as SessionFetchedAction);
      } else{
        dispatch(
          actionCreators.showMessage({
            type: "error",
            title: "MFA認証",
            body: "MFA認証に失敗しました。",
          })
        );
      }
    })
    .catch((err: any) => {
      dispatch(
        actionCreators.showMessage({
          type: "error",
          title: "MFA認証",
          body: "MFA認証に失敗しました。",
        })
      );
    })
  }

  if (action.type === "CHANGE_PASS") {
    const { currentPassword, newPassword } = action.payload;
    const user = getState().app.user;
    // サインイン
    Auth.signIn(user?.cognito_username || '', currentPassword)
      .then(() => {
        // パスワード変更
        Auth.completeNewPassword(user?.cognit_user, newPassword, {})
          .then((cognitoUser) => {
            dispatch(actionCreators.clearSession())
            dispatch(
            actionCreators.showMessage({
              type: "info",
              title: "パスワード変更",
              body: "パスワードを変更しました。",
            }));
            dispatch({type: "APP_LOADED",} as AppLoadedAction);
          })
          .catch((err: any) => {
            dispatch(
              actionCreators.showMessage({
                type: "error",
                title: "パスワード変更",
                body: "パスワード変更に失敗しました。",
              })
            );
            dispatch({type: "APP_LOADED",} as AppLoadedAction);
          });
      })
      .catch((err: any) => {
        dispatch(
          actionCreators.showMessage({
            type: "error",
            title: "パスワード変更",
            body: "パスワード変更に失敗しました。",
          })
        );
        dispatch({type: "APP_LOADED",} as AppLoadedAction);
      })
  }
  if (action.type === "SESSION_FETCHED") {
    const { cognitoUser } = action.payload;
    const licenseMap = new Map([
      ["admin", Constants.ROLE_ADMIN],
      ["pp", Constants.ROLE_PM],
      ["sp", Constants.ROLE_SP],
      ["lp", Constants.ROLE_LP]
    ]);
    // ユーザ情報取得
    Auth.userAttributes(cognitoUser)
      .then(async (userAttr: any) => {
        const expirationDate = userAttr.find((e: any) => e.Name === "custom:expiration_date")
        let expiredUser = false
        if (expirationDate) {
          if (expirationDate.Value.length == 17) {
            const startDateStr = expirationDate.Value.substring(0, 8);
            const endDateStr = expirationDate.Value.substring(9, 18);
            const currentDateStr = dayjs(new Date()).format("YYYYMMDD");
            if(yyyymmdd2Date(endDateStr) < yyyymmdd2Date(currentDateStr) || yyyymmdd2Date(currentDateStr) < yyyymmdd2Date(startDateStr)){
              expiredUser = true
              dispatch(
                actionCreators.showMessage({
                  type: "warning",
                  title: "認証",
                  body: "このアカウントは現在ご利用になれません。",
                })
              );
            }
          } else {
            const endDateStr = expirationDate.Value.substring(0, 8);
            const currentDateStr = dayjs(new Date()).format("YYYYMMDD");
            if(yyyymmdd2Date(endDateStr) < yyyymmdd2Date(currentDateStr)){
              expiredUser = true
              dispatch(
                actionCreators.showMessage({
                  type: "warning",
                  title: "認証",
                  body: "このアカウントは現在ご利用になれません。",
                })
              );
            }
          }
        }
        
        const licenseAttr = userAttr.find((e: any) => e.Name === "custom:plan")
        const subAttr = userAttr.find((e: any) => e.Name === "sub")
        
        // get user id
        const result = await getUser(subAttr.Value, cognitoUser.getUsername())
        if (result.success) {
          let user: any = {
            userId: result.data.data.id,
            name: cognitoUser.getUsername(),
            cognit_user: cognitoUser,
            userRole: licenseMap.get(licenseAttr.Value),
            cognito_uername: cognitoUser.getUsername(),
            userSub: subAttr.Value,
            expired: expiredUser,
          }

          dispatch({
            type: "AUTHORIZE",
            payload: { userProfile: user }
          } as AuthorizeAction);
        } else {
          dispatch(
            actionCreators.showMessage({
              type: "error",
              title: "認証",
              body: "認証に失敗しました。",
            })
          );
        }
      }).catch((err: any) => {
        dispatch(
          actionCreators.showMessage({
            type: "error",
            title: "認証",
            body: "認証に失敗しました。",
          })
        );
      }).finally(() => {
        dispatch({
          type: "INIT_APP_COMPLETED",
        } as any);
      });
  }
  if (action.type === "INIT_APP") {
    // セッション情報の取得
    sessionStorage.setItem("prevLocation", window.location.pathname);
    Auth.currentAuthenticatedUser()
      .then((cognitoUser) => {
        dispatch({
          type: "SESSION_FETCHED",
          payload: {cognitoUser:cognitoUser},
        } as SessionFetchedAction);
      })
      .catch(() => {
        dispatch({
          type: "CLEAR_SESSION",
        } as ClearSessionAction);
        dispatch({
          type: "INIT_APP_COMPLETED",
        } as any);
      }).finally(() => {
        dispatch({
          type: "APP_LOADED",
        } as AppLoadedAction);
      });
  }
  if (action.type === "CLEAR_SESSION") {
    Auth.signOut().finally(() => {
      window.sessionStorage.clear();
    });
  }
  if (action.type === "SHOW_MESSAGE") {
    const appState = getState();
    const message = appState.app.messages.find(
      (m) => m.id === action.payload.message.id
    );
    if (!message) {
      const messageId = action.payload.message.id || generateRandomUUID();
      dispatch({
        type: "MESSAGE_ADDED",
        payload: {
          id: messageId,
          ...action.payload.message,
        },
      } as MessageAddedAction);
      if (action.payload.dissmissAfterMs > 0) {
        setTimeout(() => {
          dispatch({
            type: "DISMISS_MESSAGE",
            payload: {
              messageId: messageId,
            },
          } as DismissMessageAction);
        }, action.payload.dissmissAfterMs);
      }
    }
  }
  if (action.type === "FETCH") {
    Auth.currentSession().then((session) => {
      const { fetchId, url, method, params, multipart, json } = action.payload;
      const fullUrl = process.env.REACT_APP_APIGW_URL + url;
      const appState = getState();
      const dataState = appState.app.fetch[fetchId];
      const token = session.getIdToken().getJwtToken();
      let headers = new Headers();
      headers.append("Authorization", token);

      if (!dataState || dataState.loading) {
        let body: any = undefined;
        if (params) {
          if (json) {
            body = JSON.stringify(params);
          } else {
            if (multipart) {
              body = new FormData();
            } else {
              body = new URLSearchParams();
            }
            if (method === "GET") {
              params.forEach((value: any, key: string) => {
                body.set(key, value);
              });
            }
            else {
              for (let prop in params) {
                if (typeof params[prop] === 'object' && params[prop] !== null) {
                  for (let propChill in params[prop]) {
                    body.set(prop + "." + propChill, params[prop][propChill]);
                  }
                } else {
                  body.set(prop, params[prop]);
                }
              }
            }
          }
        }
        let fetchTask;
        if (method === "GET" || method === "DELETE") {
          let requestUrl;
          if (body) {
            requestUrl =
              fullUrl.indexOf("?") > -1
                ? fullUrl + "&" + body.toString()
                : fullUrl + "?" + body.toString();
          } else {
            requestUrl = fullUrl;
          }
          fetchTask = fetch(requestUrl, { headers: headers, credentials: 'omit', method: method });
        } else {
          if (json) {
            headers.append("Content-Type", "application/json");
            fetchTask = fetch(fullUrl, {
              headers: headers,
              credentials: 'omit',
              method: method,
              body: body,
            });
          } else {
            fetchTask = fetch(fullUrl, {
              headers: headers,
              credentials: 'omit',
              method: method,
              body: body,
            });
          }
        }
        fetchTask
          .then((res) => {
            if (res.status === 503) {
              // redirect
              const location = res.headers.get("location") || "/";
              const XMaintenanceStatus = res.headers.get("x-maintenance-status");
              if (XMaintenanceStatus && XMaintenanceStatus == "Active"){
                window.location.replace(location);
              }
            }
            if (res.status === 401 || res.status === 403) {
              dispatch({
                type: "SESSION_EXPIRED",
              } as SessionExpiredAction);
            }
            const contentType = res.headers.get("content-type");
            if (contentType && contentType.indexOf("application/json") !== -1) {
              res
                .json()
                .then((data) => {
                  dispatch({
                    type: "FETCH_COMPLETED",
                    payload: {
                      fetchId: fetchId,
                      success: res.ok || res.status === 204,
                      data: data,
                    },
                  } as FetchCompletedAction);
                })
                .catch((err) => {
                  dispatch({
                    type: "FETCH_COMPLETED",
                    payload: {
                      fetchId: fetchId,
                      success: false,
                      data: null,
                      error: err,
                    },
                  } as FetchCompletedAction);
                });
            } else {
              res
                .blob()
                .then((data) => {
                  dispatch({
                    type: "FETCH_COMPLETED",
                    payload: {
                      fetchId: fetchId,
                      success: res.ok || res.status === 204,
                      data: data,
                    },
                  } as FetchCompletedAction);
                })
                .catch((err) => {
                  dispatch({
                    type: "FETCH_COMPLETED",
                    payload: {
                      fetchId: fetchId,
                      success: false,
                      data: null,
                      error: err,
                    },
                  } as FetchCompletedAction);
                });
            }
          })
          .catch((err) => {
            dispatch({
              type: "FETCH_COMPLETED",
              payload: {
                fetchId: fetchId,
                success: false,
                data: null,
                error: err,
              },
            } as FetchCompletedAction);
          });
      }
    }).catch((err) => {
      dispatch({
        type: "SESSION_EXPIRED",
      } as SessionExpiredAction);
    });
  }
  if (action.type === "DOWNLOAD_FILE") {
    const {
      fetchId,
      title,
      expectedFilename,
      expectedMimeType,
      url,
      params,
      method,
      searchParams,
    } = action.payload;
    let fetchTask;
    let body = undefined;
    if (method === "POST") {
      if (searchParams) {
        body = new URLSearchParams();
        if (params) {
          for (let prop in params) {
            body.set(prop, params[prop]);
          }
        }
        fetchTask = fetch(url, {
          method: method,
          body: body,
        });
      }
      else {
        if (params) {
          body = JSON.stringify(params);
        }
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        fetchTask = fetch(url, {
          headers: headers,
          method: method,
          body: body,
        });
      }
    }
    else {
      if (params) {
        body = new URLSearchParams();
        for (let prop in params) {
          body.set(prop, params[prop]);
        }
      }
      let requestUrl;
      if (body) {
        requestUrl =
          url.indexOf("?") > -1
            ? url + "&" + body.toString()
            : url + "?" + body.toString();
      } else {
        requestUrl = url;
      }
      fetchTask = fetch(requestUrl);
    }
    let filename: string;
    fetchTask
      .then((res) => {
        if (res.status === 403) {
          dispatch({
            type: "SESSION_EXPIRED",
          } as SessionExpiredAction);
        }
        if (res.ok) {
          filename = expectedFilename;
          return res
            .blob()
            .then((data) => {
              if (data.type !== expectedMimeType) {
                dispatch(
                  actionCreators.showMessage({
                    type: "error",
                    title: title,
                    body: "ダウンロードに失敗しました。",
                  })
                );
                return;
              }
              let link = document.createElement("a");
              link.download = filename;
              link.href = URL.createObjectURL(data);
              link.click();
              URL.revokeObjectURL(link.href);
            })
            .catch((err) => {
              dispatch(
                actionCreators.showMessage({
                  type: "error",
                  title: title,
                  body: "ダウンロードに失敗しました。",
                  detail: err,
                })
              );
            });
        } else {
          dispatch(
            actionCreators.showMessage({
              type: "error",
              title: title,
              body: "ダウンロードに失敗しました。",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(
          actionCreators.showMessage({
            type: "error",
            title: title,
            body: "ダウンロードに失敗しました。",
            detail: err,
          })
        );
      })
      .finally(() => {
        dispatch({
          type: "FETCH_COMPLETED",
          payload: {
            fetchId: fetchId,
            success: true,
          },
        } as FetchCompletedAction);
      });
  }
  if (action.type === "DISMISS_MESSAGE") {
    const appState = getState();
    const message = appState.app.messages.find(
      (m) => m.id === action.payload.messageId
    );
    if (message) {
      dispatch({
        type: "MESSAGE_REMOVED",
        payload: message,
      } as MessageRemovedAction);
    }
  }
  if (action.type === "SESSION_EXPIRED") {
    dispatch(
      actionCreators.showMessage({
        type: "error",
        title: "セッションタイムアウト",
        body: "セッションの継続時間が切れました。再度ログインしてください。",
      })
    );
    dispatch(actionCreators.init());
  }
  if(action.type === "SAVE_SHAPES") {
    const control = action.controlChange;
    const isUndo = action.flagSavePreStateDiagram;
    const userId = getState().app.user?.userId;
    const project = getState().app.projectData;
    let params = {
      userId: userId,
      projectId: project.projectId,
      ownerProject: project.createUserId,
      data: [] as any,
      isUndo: isUndo

    };
    if(isUndo){
      for(const item of control){
        params.data.push(item);
      }
      dispatch(actionCreators.fetch(`UPDATE_SHAPE`,"/diagram/set-shape","POST",params,false,true));
    }else{
      for(const item of control){
        const newItem = {x:item.x,y:item.y,height:item.height,width:item.width,rotation:item.rotation,type:item.type,properties:item.properties} as any
        const data:any = getElementCoordinatesData(newItem);
        data.elementId = item.id;
        data.elementType = getElementKindValue(item?.type);
        params.data.push(data);
      } 
      dispatch(actionCreators.fetch(`UPDATE_SHAPE`,"/diagram/set-shape","POST",params,false,true));
    }
  }
  if(action.type === "UPDATE_CONTROL_HEIGHT"){
    const currentDiagramTabId = getState().app.diagram.currentIDDiagramTab;
    const height = action.payload;
    const elementId = action.id;
    const diagramData = getState().app.diagram.diagramData.find((r: any) => r.tabId === currentDiagramTabId)
    let controlChangeHeight = [] as any
    controlChangeHeight = diagramData?.shape.find((item) => item.id === elementId);
    if(controlChangeHeight?.y <0 || controlChangeHeight?.x <0){
      return;
    }
    const userId = getState().app.user?.userId;
    const project = getState().app.projectData;
    const newItem = {x:controlChangeHeight?.x,y:controlChangeHeight?.y,height:height,width:controlChangeHeight?.width,rotation:controlChangeHeight?.rotation,type:controlChangeHeight?.type,properties:controlChangeHeight?.properties} as any
    const data:any = getElementCoordinatesData(newItem);
    data.elementId = elementId;
    data.elementType = controlChangeHeight?.type;

    let params = {
      userId: userId,
      projectId: project.projectId,
      data:[data],
      ownerProject: project.createUserId,
      isUndo: false
    };
    dispatch(actionCreators.fetch(`UPDATE_SHAPE`,"/diagram/set-shape","POST",params,false,true));
  }

  if(action.type === "ROTATE"){
    const userId = getState().app.user?.userId;
    const project = getState().app.projectData;
    const tabId = action.payload;
    const shapes = getState().app.diagram.diagramData.find((r: any) => r.tabId === tabId)?.shape || [];
    const controlsRotate = shapes.filter((shape) => shape.isSelected === true && shape.isRotationEnabled === true && !shape.parentGroupId);
    const params = {
      userId: userId,
      projectId: project.projectId,
      data: [] as any,
      ownerProject: project.createUserId,
      isUndo: false
    };
    for(const item of controlsRotate){
      const newItem = {x:item.x,y:item.y,height:item.height,width:item.width,rotation:item.rotation,type:item.type,properties:item.properties} as any
      const data:any = getElementCoordinatesData(newItem);
      data.elementId = item.id;
      data.elementType = getElementKindValue(item.type);
      params.data.push(data);
    }
    dispatch(actionCreators.fetch(`UPDATE_SHAPE`,"/diagram/set-shape","POST",params,false,true));
  }

  if(action.type === "REMOVE_SHAPES"){
    const currentIDDiagramTab  = getState().app.diagram.currentIDDiagramTab;
    let isMainDiagram = currentIDDiagramTab == 1 ? true : false;
    const tabId = action.payload;
    const shapes = getState().app.diagram.diagramData.find((r: any) => r.tabId === tabId)?.shape || [];
    const controlsDeleted = shapes.filter((shape) => shape.isSelected === true);
    let result:any = [];
    if(isMainDiagram){
      const elementGroups = getState().app.diagram.elementGroups.byEleId;
      controlsDeleted.forEach((item) => {
        if(elementGroups[item.id])
          result.push(...elementGroups[item.id]);
      })
    }
    else{
      const allTransCenterControl = getState().app.diagram.diagramData.find((r: any) => r.tabId === 1)?.shape.filter((item:any) => item.type === "MS_TRANSCENTER").map((item)=>item) || [];
      const currentTransCenterId = allTransCenterControl.find((item:any) => item.properties.tabId === currentIDDiagramTab);
      let elementTCGroups:any;
      if(currentTransCenterId)
        elementTCGroups = getState().app.diagram.tcGroups[currentTransCenterId.id]?.byEleId;
      controlsDeleted.forEach((item) => {
        if(elementTCGroups[item.id])
          result.push(...elementTCGroups[item.id]);
      })
    }
    result = new Set(result);
    const userId = getState().app.user?.userId;
    const project = getState().app.projectData;
    const data={
      userId: userId,
      projectId: project.projectId,
      params:[],
      listParrentGroupId:Array.from(result),
      isMainDiagram:isMainDiagram,
      ownerProject: project.createUserId
    } as any
    for(const item of controlsDeleted){
      let params = {
        elementId: item?.id,
        elementType: getElementKindValue(item?.type),
      };
      data.params.push(params);
    }
    dispatch(actionCreators.fetch(`DELETE_CONTROL`,"/diagram/delete-ele","POST",data,false,true));
  }
  if (action.type === "UPDATE_PROPERTIES_OF_CONTROL") {
    const appStore = getState().app.diagram as Model.AppStore
    const currentIDChartTab =  appStore.currentIDChartTab
    //const currentDiagramTab = appStore.diagramData.find(tab => tab.tabId === appStore.currentIDDiagramTab)
    const currentchartData = appStore.chartData.find(tab => tab.tabId === currentIDChartTab)
    const data = action.payload as any
    const id = action.id
    
    //const preUpdateShape = currentDiagramTab?.shape.find(control => control.id === id)
    const preUpdateChart = currentchartData?.shape.find(control => control.id === id)
    const checkUpdateTab = [...appStore.chartData].filter(x=>x.shape.find(shapes => shapes.id === id)).filter(x=>x.shape.length > 0)
    if(checkUpdateTab.length > 1)
    {
      let isCallDB = false
      //TODO check again because call api for each graph for same times
      checkUpdateTab.map(tab=> {
        if (!data.isDrawCalcPoint && tab.shape[0] && tab.shape[0].chart && tab.shape[0].chart.length > 0) {
          const updatedShape = appStore.chartData.find(x => x.tabId === tab.tabId)?.shape.find(control => Number(control.id) === Number(id)) as Model.ControlModel
          if(updatedShape.chart.find(x=>x.lineType === Model.LineType.CONTROL_CURVE) != undefined)
          {
            if((data.isCallDB? true : willCallDb(updatedShape, data)))
              isCallDB = true
            next(action)
    
            Method.handleGetGraphLineData(dispatch, updatedShape, isCallDB, tab.tabId, id)
          }
        } else {
          const updatedShape = appStore.chartData.find(x => x.tabId === tab.tabId)?.shape.find(control => control.id === id) as Model.ControlModel
          if(updatedShape && updatedShape.chart.find(x=>x.lineType === Model.LineType.CONTROL_CURVE) != undefined)
            {
              const isCallDb = data.isCallDB? true : willCallDb(updatedShape, data)
              // continue update properties
              next(action)
              updatedShape && (isCallDb || updatedShape.chart.length <= 0) && dispatch(addLineToChart(true, updatedShape.id))
              dispatch(actionCreators.redrawChart())
            }
            else
            {
              // continue update properties
              next(action)
            }
        }
      })
    }
    else
    {
      if(preUpdateChart?.chart.find(x=>x.lineType === Model.LineType.CONTROL_CURVE) != undefined)
      {
        // if hasnot draw chart yet, do not thing, else check properties to redraw chart
        if (!data.isDrawCalcPoint && preUpdateChart && preUpdateChart.chart && preUpdateChart.chart.length > 0) {
          // check if this control will call db or not
          
          const isCallDb = data.isCallDB? true : willCallDb(preUpdateChart, data)
          // continue update properties
          next(action)
          const updatedShape = currentchartData?.shape.find(control => control.id === id) as Model.ControlModel
          // get data chart line
          Method.handleGetGraphLineData(dispatch, updatedShape, isCallDb, currentIDChartTab, id)
        } else {
          if(preUpdateChart)
          {
            const isCallDb = data.isCallDB? true : willCallDb(preUpdateChart, data)
            // continue update properties
            next(action)
            isCallDb && dispatch(addLineToChart(true, preUpdateChart.id))
            dispatch(actionCreators.redrawChart())
          }
          else
          {
            // continue update properties
            next(action)
          }
        }
      }
    }
    next(action)
  }
  if (["REMOVE_SHAPES", "CUT_SHAPES", "ROTATE", "UPDATE_CONTROL_HEIGHT"].includes(action.type)) {
    // check zerocond connect
    dispatch(actionCreators.checkZerocondConnect())
  }
  if (action.type === "REMOVE_SHAPES" || action.type === "CUT_SHAPES") {
    const appStore = getState().app.diagram as Model.AppStore
    const currentDiagramTab = appStore.diagramData.find(tab => tab.tabId === action.payload)

    // get delete shapes that have drawn chart
    const deletedShapes = currentDiagramTab?.shape.filter(control => control.isSelected === true)
    // continue remove action
    next(action)
  
    if (deletedShapes && deletedShapes.length > 0) {
      const toDeleteChartIds = deletedShapes.map(control => control.id)
      const chartList = appStore.chartData

      chartList.forEach((chartTab) => {
        chartTab.shape = chartTab.shape.filter(control => !toDeleteChartIds.includes(control.id))
      })
      dispatch(actionCreators.deleteGraphTextByRelatedGraph(toDeleteChartIds))
      
      dispatch(actionCreators.redrawChart())
    }
  }
  if (action.type === "UPDATE_USER_CURVE") {
      const appStore = getState().app.diagram as Model.AppStore
      //const currentDiagramTab = appStore.diagramData.find((diagram : Model.DiagramModel) => diagram.tabId === appStore.currentIDDiagramTab)
      const currentChartTab = appStore.chartData.find((chart : Model.DiagramModel) => chart.tabId === appStore.currentIDChartTab)

      const controlId = action.payload.controlId
      const userCurve = action.payload.data

      const control = currentChartTab?.shape.find((shape : Model.ControlModel) => shape.id === controlId);
      const allChartTab = appStore.chartData.filter((chart : Model.DiagramModel) => chart.tabId != 0);
      const all_control:any = [];
      allChartTab.forEach((chart : Model.DiagramModel) => {
        const suitable_item = chart.shape.find((item:any) => item.id == controlId);
        if(suitable_item)
          all_control.push(suitable_item);
      });

      for(const item of all_control){
        const currentChart = item.chart.find((lineInfo : Model.LineInfoModel) => lineInfo.lineType === "USER_CURVE" && ((lineInfo.data.find((x:any)=> x.userCurveId) && userCurve.userCurveId === lineInfo.data.find((x:any)=> x.userCurveId)?.userCurveId) || ( lineInfo.data.find((x:any)=> x.user_curve_id) && userCurve.userCurveId === lineInfo.data.find((x:any)=> x.user_curve_id)?.user_curve_id)))
        let listToDrawPoints = [...userCurve.listPoint]
        if(listToDrawPoints && listToDrawPoints.length > 0 && listToDrawPoints[0].y < listToDrawPoints[listToDrawPoints.length - 1].y)
        {
          listToDrawPoints = listToDrawPoints.reverse()
        }
        // check if freeOrCircuitVolt is true, then re-calc x base on ratio
        if (userCurve.freeOrCircuitVolt > 0 && userCurve.stdVoltage !== userCurve.voltage) {
          const ratio = userCurve.stdVoltage;
          listToDrawPoints = listToDrawPoints.map((point : any) => ({ ...point, x: point.x * ratio}))
        }
        else
        {
          const ratio = userCurve.voltage;
          listToDrawPoints = listToDrawPoints.map((point : any) => ({ ...point, x: point.x * ratio}))
        }
  
        // WARNING: this line doesnot sure to work after save into db and relaunch the web
        currentChart && (currentChart.data = listToDrawPoints) //update directly in store
  
        // dispatch redraw action
      }
      if(all_control.length > 0 )
      {
        dispatch(actionCreators.redrawChart())
        dispatch(actionCreators.reDrawGraphText(all_control))
      }
  }
  if (action.type === "DELETE_USER_CURVE") {
      const appStore = getState().app.diagram as Model.AppStore
      //const currentDiagramTab = appStore.diagramData.find((diagram : Model.DiagramModel) => diagram.tabId === appStore.currentIDDiagramTab)
      const currentChartTab = appStore.chartData.find((diagram : Model.DiagramModel) => diagram.tabId === appStore.currentIDChartTab)

      const controlId = action.payload.controlId
      const userCurve = action.payload.data

      const control = currentChartTab?.shape.find((shape : Model.ControlModel) => shape.id === controlId)

      if (control) {
        const userId = getState().app.user?.userId;
        const currentChart = control?.chart.find((lineInfo : Model.LineInfoModel) => lineInfo.lineType === "USER_CURVE" && ((lineInfo.data.find((x:any)=> x.userCurveId) && userCurve.userCurveId === lineInfo.data.find((x:any)=> x.userCurveId)?.userCurveId) || (lineInfo.data.find((x:any)=> x.user_curve_id) && userCurve.userCurveId === lineInfo.data.find((x:any)=> x.user_curve_id)?.user_curve_id)))
        if(currentChart){
          let param : any[] = [{
            userId: userId,
            projectId : userCurve.projectId,
            relatedGraphId : currentChart.relatedGraphId,
            relatedGraphTextId : currentChart.relatedGraphTextId,
            ownerProject: getState().app.projectData.createUserId
          }]
          dispatch(deleteRelatedGraphById(currentChart.relatedGraphId))
          dispatch(actionCreators.fetch(DELETE_RELATED_GRAPH,"/graph/delete-related-graph","POST",param,false,true))
  
          // dispatch redraw action
          dispatch(actionCreators.redrawChart())
        }
      }
  }
  if(action.type === UPDATE_QUICK_PROPERTIES){
    const diagram = getState().app.diagram;
    const userId = getState().app.user?.userId;
    const project = getState().app.projectData;
    const currentIDDiagramTab = diagram.currentIDDiagramTab;

    // get all selected element_id
    const currentShape = diagram.diagramData.find(item => item.tabId === currentIDDiagramTab)?.shape.filter((item) => item.isSelected === true).map((item) => item.id);
    let mainDiagramData = getState().app.diagram.diagramData.find((e: any) => e.tabId === 1);
    let transCenter = mainDiagramData?.shape.find(
    (e: any) => e.properties?.tabId === currentIDDiagramTab
    );
    const params = {
        userId: userId,
        projectId: project.projectId,
        shapesIDSelected:currentShape,
        field:action.payload["field"],
        inTranscenter: currentIDDiagramTab != 1,
        transCenterId:transCenter ? transCenter.id : null,
        newValue: action.payload["value"],
        ownerProject: project.createUserId
    }
    dispatch(actionCreators.fetch(UPDATE_QUICK_PROPERTIES,`/check/edit-properties`,"POST",params,false,true));
  }
  if(action.type === MAPPED_DATA){
    const diagramDataList = getState().app.diagram.diagramData;
    const mappedElements = mapElementsResponseToLocal(action.payload as any, diagramDataList);
    const shapeList =  getState().app.diagram.chartData.find(x=>x.tabId ===  getState().app.diagram.currentIDChartTab)!.shape;
    // TransCenter -- Add tab per transCenter, edit props.tabId and add children to transCenter's subTab
    let transCenterList = mappedElements.filter((e) => e.type === Element.MS_TRANSCENTER)
    let eleOfTransCenter = mappedElements.filter((e) => e.parentTcID !== null)
    let groupByTcID = groupBy(eleOfTransCenter, 'parentTcID')
    if (transCenterList.length > 0){
        transCenterList.forEach((transCenter: any) => {
            const tabId = diagramDataList.find(d => d.tabId === 1)?.shape.find(e => e.id === transCenter.id)?.properties.tabId || 0
            let shape = []
            if (groupByTcID[transCenter.id] !== undefined)
              shape = groupByTcID[transCenter.id]
            shape.forEach((e:any)=>{
              if(shapeList.find((x:any)=>Number(x.id) === Number(e.id)) !== undefined)
              {
                e.chart && e.chart.length == 0 && (e.chart = shapeList.find((x:any)=>Number(x.id) === Number(e.id))!.chart)
                e.properties.details.base.readBase.ratedFlag == undefined && (e.properties.details.base.readBase = shapeList.find((x:any)=>Number(x.id) === Number(e.id))!.properties.details.base.readBase)
              }
            })
            dispatch(saveAllControlsToDiagram({ tabId, shape } as Model.DiagramModel, false))
            transCenter.properties.tabId = tabId
        })
    }
    // remove eleOfTransCenter in mappedElements
    const mainDiagramShape = mappedElements.filter((e) => !eleOfTransCenter.map(e2 => e2.id).includes(e.id))
    mainDiagramShape.forEach((e:any)=>{
      if(shapeList.find((x:any)=>Number(x.id) === Number(e.id)) !== undefined)
      {
        e.chart && e.chart.length == 0 && (e.chart = shapeList.find((x:any)=>Number(x.id) === Number(e.id))!.chart)
        e.properties?.details?.base?.readBase && e.properties.details.base.readBase.ratedFlag == undefined && (e.properties.details.base.readBase = shapeList.find((x:any)=>Number(x.id) === Number(e.id))!.properties.details.base.readBase)
      }
    })
    dispatch(saveAllControlsToDiagram(
        {
            tabId: 1, 
            shape: mainDiagramShape,
        } as Model.DiagramModel, 
        false
    ))

    //update nominalVolt list in chart
    const voltageShape = [...mainDiagramShape] as Model.ControlModel[]
    const voltageCombo = Array.from(new Set([...getVoltageCombo(voltageShape||[], VOLT_SIDE_SECONDARY)]))
    const appStore = getState().app.diagram as Model.AppStore
    const chartTab = appStore.chartData.find((x:any)=>x.tabId == appStore.currentIDChartTab)
    if(chartTab && chartTab && voltageCombo !== chartTab.nominalVoltList)
    {
      if(voltageCombo.includes(chartTab.nominalVolt))
      {
        let newChartData = appStore.chartData.find((x:any) => x.tabId === chartTab.tabId)
        newChartData && (newChartData.nominalVoltList = voltageCombo)
      }
      else
      {
        const index = chartTab.nominalVoltList.indexOf(chartTab.nominalVolt)
        let newNominalVolt = index > voltageCombo.length - 1 ? voltageCombo[0] : voltageCombo[index]
        let newChartData = appStore.chartData.find((x:any) => x.tabId === chartTab.tabId)
        if(newChartData)
        {
          newChartData.nominalVolt = newNominalVolt
          newChartData.nominalVoltList = voltageCombo
        }
      }
    }

    setTimeout(()=>{
      dispatch(setDiagramDataUpdate())
    },1000)
    dispatch(reDrawGraphText(mainDiagramShape))
  }
};

const initialDiagram : AppStore = {
  elementTemplates: {},
  elementGroups: {byId: {}, byEleId: {}},
  tcGroups: {},
  transformFlag: true,
  transformData: [],
  currentIDDiagramTab: 1,
  transCenterUpdate: 0,
  diagramData: [{ tabId: 1, shape: [] }],
  chartData: [],
  currentIDChartTab: 0,
  maxIDChartTab: 0 as number,
  elementMaxId: 0,
  typesMaxId: new Map(),
  preDiagramData: [],
  isOpenChart: false,
  isAddToChart: false,
  isAddCalcPointToChart: false,
  copyShapes: [],
  copyEleGroups: {},
  copyTCEleGroups: {},
  copyDiagramData: [],
  deleteGraphNo: {},
  chartEvents: {},
  openDialog: {},
  openUserCurve: {},
  openUserCurveProperties: {},
  graphData: {},
  checkZerocondConnect: {},
  pointerPosition: { x: 20, y: 20 },
  processMode: Model.ProcessMode.DRAWING,
  gapLine : { dispCurrent: true, dispTime: true, stdCurrent: 110, stdTime: 0.11, dispGapLine: false } as Model.GapLine,
  infoCircuit: {} as Model.InfoCircuit,
  infoSkeleton:{} as Model.InfoSkeleton,
  infoCover:{} as Model.InfoCover,
  report:{} as Model.Report,
  groupList: [],
  m_bModePM: false,
  undoData:{
    type:null,
    dataUndo:null
  },
  deleteGraphText: {},
  reDrawGraphText: {},
  isDrawPowerArrow: {},
  listGraphName: [],
  isShowScreen: Constants.CONTROL_SCREEN,
  isDownloadDiagramPDF: {state:false,type:0, image:''},
  deleteUserCurveRelatedGraph:{},
  contextMenuFunction: null,
  clearOptionMenu: false,
  listGraph:[],
  isSelectTouchGroup: false,
  diagramDataUpdate:0,
  isShowMaintenanceScreen: {show:false,controlId: null},
  isShowMyProject:true,
  elementReposition:null,
  modeViewOnly: false,
}

export const reducer: Reducer<AppState> = (state, incomingAction: Action) => {
  const action = incomingAction as any;
  if (state === undefined) {
    return {
        loading: true,
        initAppLoading: true,
        messages: [],
        user: null,
        resetPassUserName: "",
        isPasswordResetRequired: false,
        fetch: {},
        forms: {},
        container: {
            producerCode: 0,
            date: "",
        },
        searchParams: "",
        pageSize: 100,
        diagram: JSON.parse(JSON.stringify(initialDiagram)) as AppStore,
        projectData: {} as Model.ProjectModel,
        notificationList: null,
    };
  }
  switch (action.type) {
    case "INIT_APP":
      return {
        ...state,
        loading: true,
        initAppLoading: true,
      };
    case "INIT_APP_COMPLETED":
      return {
        ...state,
        initAppLoading: false,
      };
    case "APP_LOADED":
      return {
        ...state,
        loading: false,
      };
    case "APP_LOADING":
      return {
        ...state,
        loading:true,
      }
    case "CLEAR_SESSION":
      return {
        ...state,
        user: null,
      };
    case "SESSION_FETCHED":
      return {
        ...state,
        user: action.payload.userProfile,
        resetPassUserName: "",
        isPasswordResetRequired: false,
      };
    case "SESSION_EXPIRED":
      return {
        ...state,
        user: null,
      };
    case "AUTHORIZE":
      return {
        ...state,
        user: action.payload.userProfile,
        notificationList: null,
      };
    case "CANCLE_VERIFICATION_CODE":
      return {
        ...state,
        user: null,
      };
    case "MESSAGE_ADDED":
      return {
        ...state,
        messages: [...state.messages, action.payload],
      };
    case "MESSAGE_REMOVED":
      return {
        ...state,
        messages: state.messages.filter((m) => m !== action.payload),
      };
    case "RESET_PASS":
      const { resetPassUserName } = action.payload;
        return {
          ...state,
          resetPassUserName: resetPassUserName,
          isPasswordResetRequired: true,
        };
    case "EXIT_RESET_PASS":
            return {
              ...state,
              resetPassUserName: "",
              isPasswordResetRequired: false,
            };
    case "INIT_FORM": {
      const { formId, initialValue } = action.payload;
      return {
        ...state,
        forms: {
          ...state.forms,
          [formId]: {
            pristine: true,
            initialValue: initialValue,
            value: initialValue,
            validationNeeded: true,
            errors: {},
            touched: {},
            error: false,
            submitting: false,
          },
        },
      };
    }
    case "DISCARD_FORM": {
      const newState = { ...state };
      delete newState.forms[action.payload.formId];
      return newState;
    }
    case "TOUCH_FORM":
      {
        const { formId, key } = action.payload;
        const currentForm = state.forms[formId];
        if (currentForm) {
          const { touched, pristine } = currentForm;
          if (!pristine) {
            return state;
          }
          return {
            ...state,
            forms: {
              ...state.forms,
              [formId]: {
                ...currentForm,
                touched: { ...touched, [key]: true },
              },
            },
          };
        }
      }
      break;
    case "CHANGE_FORM":
      {
        const { formId, name, newValue } = action.payload;
        const currentForm = state.forms[formId];
        if (currentForm) {
          const touched = { ...currentForm.touched };
          currentForm.touched[name] = true;
          return {
            ...state,
            forms: {
              ...state.forms,
              [formId]: {
                ...currentForm,
                value: { ...currentForm.value, [name]: newValue },
                touched: touched,
                validationNeeded: true,
              },
            },
          };
        }
      }
      break;
    case "VALIDATE_FORM":
      {
        const { formId, errors } = action.payload;
        const currentForm = state.forms[formId];
        if (currentForm) {
          let hasError = false;
          for (let prop in errors) {
            if (errors[prop]) {
              hasError = true;
              break;
            }
          }
          return {
            ...state,
            forms: {
              ...state.forms,
              [formId]: {
                ...currentForm,
                errors,
                error: hasError,
                validationNeeded: false,
              },
            },
          };
        }
      }
      break;
    case "SUBMIT_FORM":
      {
        const { formId } = action.payload;
        const currentForm = state.forms[formId];
        if (currentForm) {
          const { pristine, error } = currentForm;
          if (pristine || !error) {
            return {
              ...state,
              forms: {
                ...state.forms,
                [formId]: {
                  ...currentForm,
                  pristine: false,
                  submitting: !error,
                },
              },
            };
          }
        }
      }
      break;
    case "FORM_SUBMITTED":
      {
        const { formId } = action.payload;
        const currentForm = state.forms[formId];
        if (currentForm && !currentForm.error) {
          return {
            ...state,
            forms: {
              ...state.forms,
              [formId]: {
                ...currentForm,
                submitting: true,
              },
            },
          };
        }
      }
      break;
    case "FORM_SUBMISSION_COMPLETED":
      {
        const { formId } = action.payload;
        const currentForm = state.forms[formId];
        if (currentForm) {
          return {
            ...state,
            forms: {
              ...state.forms,
              [formId]: {
                ...currentForm,
                submitting: false,
              },
            },
          };
        }
      }
      break;
    case "RESET_FORM":
      {
        const { formId } = action.payload;
        const currentForm = state.forms[formId];
        if (currentForm) {
          return {
            ...state,
            forms: {
              ...state.forms,
              [formId]: {
                pristine: true,
                initialValue: currentForm.initialValue,
                value: currentForm.initialValue,
                validationNeeded: true,
                errors: {},
                touched: {},
                error: false,
                submitting: false,
              },
            },
          };
        }
      }
      break;
    case "INIT_FETCH":
      {
        const { fetchId } = action.payload;
        const currentData = state.fetch[fetchId];
        if (!currentData || !currentData.loading) {
          return {
            ...state,
            fetch: {
              ...state.fetch,
              [fetchId]: {
                requested: false,
                loading: false,
              },
            },
          };
        }
      }
      break;
    case "DISCARD_FETCH": {
      const { fetchId } = action.payload;
      const newState = { ...state };
      delete newState.fetch[fetchId];
      return newState;
    }
    case "FETCH":
    case "DOWNLOAD_FILE":
      {
        const { fetchId } = action.payload;
        const currentData = state.fetch[fetchId];
        if (currentData) {
          return {
            ...state,
            fetch: {
              ...state.fetch,
              [fetchId]: {
                ...currentData,
                requested: true,
                loading: true,
              },
            },
          };
        }
      }
      break;
    case "FETCH_COMPLETED":
      {
        const { fetchId, success, data, error } = action.payload;
        const currentData = state.fetch[fetchId];
        if (currentData) {
          return {
            ...state,
            fetch: {
              ...state.fetch,
              [fetchId]: {
                ...currentData,
                loading: false,
                success,
                data,
                error,
              },
            },
          };
        }
      }
      break;
    case "SET_CONTAINER": {
      const { producerCode, date } = action.payload;
      const currentData = state.container;
      return {
        ...state,
        container: {
          ...currentData,
          producerCode: producerCode,
          date: date,
        },
      };
    }
    case "SET_SEARCH_PARAMS": {
      const { params } = action.payload;
      return {
        ...state,
        searchParams: params,
      };
    }
    case "SET_PAGE_SIZE": {
      const { pageSize } = action.payload;
      return {
        ...state,
        pageSize: pageSize,
      };
    }
    case CHANGE_MODE_PM:
        return {
            ...state,
            diagram:{
              ...state?.diagram,
              m_bModePM: action.payload !== null ? action.payload : state.diagram.m_bModePM
            }
        }
    case CHANGE_MODE_VIEW_ONLY:
        return {
            ...state,
            diagram:{
              ...state?.diagram,
              modeViewOnly: action.payload !== null ? action.payload : state.diagram.modeViewOnly
            }
        }
    case RESET_DIAGRAM_PROJECT_DATA:
      return {
        ...state,
        diagram: {
          ...JSON.parse(JSON.stringify(initialDiagram)),
          elementTemplates: state.diagram.elementTemplates
        },
        projectData: {} as Model.ProjectModel
      }
    case SAVE_PROJECT_DATA:
      return {
        ...state,
        projectData: action.payload
      }
    case SAVE_INFO_CIRCUIT:
      return {
        ...state,
        diagram:{
          ...state?.diagram,
          infoCircuit: {
            ...state.diagram.infoCircuit,
            ...action.payload
          },
        }
      }
    case SAVE_ELEMENT_GROUPS:
      return {
        ...state,
        diagram:{
          ...state?.diagram,
          elementGroups: action.payload
        }
      }
    case SAVE_TC_GROUPS:
      return {
        ...state,
        diagram:{
          ...state?.diagram,
          tcGroups: action.payload
        }
      }
    case SAVE_INFO_SKELETON:
      return {
        ...state,
        diagram:{
          ...state?.diagram,
          infoSkeleton: {
            ...state.diagram.infoSkeleton,
            ...action.payload
          },
        }
      }
    case SAVE_REPORT:
      return {
        ...state,
        diagram:{
          ...state?.diagram,
          report: {
            ...state.diagram.report,
            ...action.payload
          },
        }
      }
    case SAVE_INFO_COVER:
      return {
        ...state,
        diagram:{
          ...state?.diagram,
          infoCover: {
            ...state.diagram.infoCover,
            ...action.payload
          },
        }
      }
    case SAVE_ELEMENT_TEMPLATES:
      return {
        ...state,
        diagram:{
          ...state?.diagram,
          elementTemplates: action.payload,
        }
      }
    case SAVE_ALL_CONTROLS_TO_DIAGRAM: {
        return {
            ...state,
            diagram:{
              ...state?.diagram, 
              preDiagramData: Method.handleSavePreStateDiagram(state.diagram.preDiagramData, [...state.diagram.diagramData], action.payload.tabId, action.flagSavePreStateDiagram),
              diagramData: Method.handleSaveAllControlsToDiagram(state.diagram.diagramData, action.payload),
              chartData: Method.handleSaveAllControlsToChartData(state.diagram.chartData, action.payload),
              diagramDataUpdate : action.bDiagramUpdate == false ? state.diagram.diagramDataUpdate : state.diagram.diagramDataUpdate + 1
            }
        };
    }
    case DIAGRAM_DATA_UPDATE: {
        return {
          ...state,
          diagram:{
            ...state?.diagram, 
            diagramDataUpdate : state.diagram.diagramDataUpdate + 1
          }
      };
    }
    case SAVE_SHAPES:{
      return {
        ...state,
        diagram:{
          ...state?.diagram, 
          preDiagramData: Method.handleSavePreStateDiagram(state.diagram.preDiagramData, [...state.diagram.diagramData], action.payload.tabId, action.flagSavePreStateDiagram),
          diagramData: Method.handleSetShapes(state.diagram.diagramData,action.controlChange,action.payload),
          diagramDataUpdate : state.diagram.diagramDataUpdate + 1
        }
    };
    }
   
    case SAVE_CHART_INTO_CONTROL:
        return {
            ...state,
            diagram:{
              ...state?.diagram,
              // preDiagramData: Method.handleSavePreStateDiagram(state.diagram.preDiagramData, [...state.diagram.diagramData], action.payload.tabId),
              diagramData: Method.handleUpdateChartInControl(state.diagram.diagramData, action.payload, state.diagram),
              diagramDataUpdate : state.diagram.diagramDataUpdate + 1
            }
        };
    case DELETE_DIAGRAM_BY_TABID:
        return {
            ...state,
            diagram:{
              ...state?.diagram,
              preDiagramData: state.diagram.preDiagramData.filter((r) => r.tabId !== action.payload),
              diagramData: state.diagram.diagramData.filter((r) => r.tabId !== action.payload),
              diagramDataUpdate : state.diagram.diagramDataUpdate + 1

            }
        };
    case SAVE_CURRENT_ID_DIAGRAM_TAB:
        return {
            ...state,
            diagram:{
              ...state?.diagram,
              currentIDDiagramTab: action.payload !== null ? action.payload : state.diagram.currentIDDiagramTab,
              diagramDataUpdate : state.diagram.diagramDataUpdate + 1
            }
        }
    case ADD_UNDO_DATA_TO_CHART:
        return {
            ...state,
            diagram:{
              ...state?.diagram,
              chartData: state.diagram.chartData.map((item:any)=>{
                if(action.payload.find((x:any)=>x.tabId == item.tabId) != undefined)
                {
                  item.shape.push(...action.payload.find((x:any)=>x.tabId == item.tabId).data)
                  return item
                }
                else
                {
                  return item
                }
              })
            }
        }
    case SAVE_GROUP_LIST:
        return {
            ...state,
            diagram:{
              ...state?.diagram,
              groupList: action.payload !== null ? action.payload : []
            }
        }
    case UPDATE_STD_CAPACITY:
        return {
            ...state,
            diagram:{
              ...state?.diagram,
              infoCircuit: {
                ...state.diagram.infoCircuit,
                stdCapacity: action.payload
              }
            }
        }
      case UPDATE_FREQUENCY:
        return {
          ...state,
          diagram:{
            ...state?.diagram,
            infoCircuit: {
              ...state.diagram.infoCircuit,
              frequency: action.payload
            }
          }
        }
    case SAVE_ALL_CONTROLS_TO_CHART_TAB:
        return {
            ...state,
            diagram:{
              ...state?.diagram,
              chartData: Method.handleSaveAllControlsToChartTab(state.diagram.chartData, action.payload, state.diagram)
            }
        };
    case UPDATE_LINE_INFO:
        return {
            ...state,
            diagram:{
              ...state?.diagram,
              chartData: Method.handleUpdateLineInfo(state.diagram.chartData, state.diagram.currentIDChartTab, action.payload.data, action.payload.lineId, action.payload.controlId)
            }
        };
    case REDRAW_CHART:
        return {
            ...state,
            diagram:{
              ...state?.diagram,
              chartData: [...state.diagram.chartData]
            }
        };
    case DELETE_CHART_TAB_BY_TABID:
        return {
            ...state,
            diagram:{
              ...state?.diagram,
              // chartData: state.diagram.chartData.filter((r) => r.tabId !== action.payload)
            }
        };
    case DELETE_RELATED_GRAPH_BY_USER_CURVE_DIALOG:
        return {
            ...state,
            diagram:{
              ...state?.diagram,
              deleteUserCurveRelatedGraph: action.payload
            }
        };
    case DELETE_GRAPH_TEXT_BY_RELATED_GRAPH:
        return {
            ...state,
            diagram:{
              ...state?.diagram,
              deleteGraphText: action.payload
            }
        };
    case REDRAW_GRAPH_TEXT:
        return {
            ...state,
            diagram:{
              ...state?.diagram,
              reDrawGraphText: action.payload
            }
        };
    case DELETE_RELATED_GRAPH_BY_ID:
      return {
          ...state,
          diagram:{
            ...state?.diagram,
            chartData: state.diagram.chartData.map(chartTab => ({...chartTab,
              shape : chartTab.shape.map(shapes => ({...shapes, 
                chart : shapes.chart.filter(charts => 
                  Number(charts.relatedGraphId) !== Number(action.payload)
                )
              }))
            })),
            diagramData: state.diagram.diagramData.map(diagram => ({...diagram,
              shape : diagram.shape.map(shapes => shapes.type !== "MS_TEXT" && shapes.type !== "MS_INTO_POINT" ? ({...shapes, 
                chart : shapes.chart.filter(charts => 
                  Number(charts.relatedGraphId) !== Number(action.payload)
                )
              }) : shapes)
            })),
            diagramDataUpdate : state.diagram.diagramDataUpdate + 1
          }
      };
    case DELETE_GRAPH_BY_GRAPH_NO:
      return {
          ...state,
          diagram:{
            ...state?.diagram,
            chartData: state.diagram.chartData.filter(chartTab => 
                chartTab.tabId !== action.payload
              ),
            diagramData: state.diagram.diagramData.map(diagram => ({...diagram,
              shape : diagram.shape.map(shapes => shapes.type !== "MS_TEXT" && shapes.type !== "MS_INTO_POINT" ? ({...shapes, 
                chart : shapes.chart.filter((charts:any) => 
                  charts?.tabId !== action.payload
                )
              }) : shapes)
            })),
            diagramDataUpdate : state.diagram.diagramDataUpdate + 1
          }
      };
    case IS_SHOW_SCREEN:
        return {
            ...state,
            diagram:{
              ...state?.diagram,
              isShowScreen: action.payload
            }
        };
    case SET_REPOSITION_ELEMENT:
      return {
          ...state,
          diagram:{
            ...state?.diagram,
            elementReposition: action.payload
          }
      };
    case IS_DOWNLOAD_DIAGRAM_PDF:
          return {
              ...state,
              diagram:{
                ...state?.diagram,
                isDownloadDiagramPDF: {
                  state : action.payload.state,
                  type : action.payload.type,
                  image: action.payload.image
                }
              }
          };
    case SAVE_CURRENT_ID_CHART_TAB:
        return {
            ...state,
            diagram:{
              ...state?.diagram,
              currentIDChartTab: action.payload !== null ? action.payload : state.diagram.currentIDChartTab,
              chartData: state.diagram.chartData.map((item:Model.ChartTabModel)=>{
                if(action.payload && item.tabId == action.payload)
                {
                  const voltageCombo = Array.from(new Set([...getVoltageCombo(item.shape||[], VOLT_SIDE_SECONDARY)]))
                  if(voltageCombo !== item.nominalVoltList)
                  {
                    if(voltageCombo.includes(item.nominalVolt))
                    {
                      return {...item,nominalVoltList: voltageCombo}
                    }
                    else
                    {
                      const index = item.nominalVoltList.indexOf(item.nominalVolt)
                      let newNominalVolt = index > voltageCombo.length - 1 ? voltageCombo[0] : voltageCombo[index]
                      return {...item,nominalVolt:newNominalVolt,nominalVoltList:voltageCombo}
                    }
                  }
                }
                else
                {
                  return item
                }
              })
            }
        }
    case SAVE_MAXID_CHART_TAB:
        const newChartTab : Model.ChartTabModel = {
          diagramId: state.diagram.currentIDDiagramTab,
          tabId: action.payload.flag? action.payload.maxId : 0,
          shape: [],
          nominalVolt: 0,
          nominalVoltList: [],
          zoom: 100,
          isJapaneseElectricMode: false
        }
        
        return {
            ...state,
            diagram:{
              ...state?.diagram,
              maxIDChartTab: action.payload.maxId || 0,
              chartData: [...state.diagram.chartData, newChartTab]
            }
        }
    case CREATE_CHART_TAB:
        const chartTab : Model.ChartTabModel = {
          diagramId: state.diagram.currentIDDiagramTab,
          tabId: action.payload,
          shape: [],
          nominalVolt: 0,
          nominalVoltList: [],
          zoom: 100,
          isJapaneseElectricMode: false
        }
        
        return {
            ...state,
            diagram:{
              ...state?.diagram,
              chartData: [...state.diagram.chartData, chartTab]
            }
        }

    case PUBLISH_CHART_EVENTS:
        return {
          ...state,
          diagram: {
            ...state.diagram,
            chartEvents: action.payload
          }
        }

    case OPEN_DIALOG_EVENTS:
      return {
        ...state,
        diagram: {
          ...state.diagram,
          openDialog: {
            ...state.diagram.openDialog,
            [action.payload.type]: action.payload.data
          }
        }
      }

    case SET_GRAPH_DATA:
      return {
        ...state,
        diagram: {
          ...state.diagram,
          graphData: {
            ...state.diagram.graphData,
            [action.payload.type]: action.payload.data
          }
        }
      }

    case OPEN_USER_CURVE_EVENTS:
      return {
        ...state,
        diagram: {
          ...state.diagram,
          openUserCurve: {
            ...state.diagram.openUserCurve,
            [action.payload.type]: action.payload.data
          }
        }
      }

    case OPEN_USER_CURVE_PROPERTIES_EVENTS:
      return {
        ...state,
        diagram: {
          ...state.diagram,
          openUserCurveProperties: {
            ...state.diagram.openUserCurveProperties,
            [action.payload.type]: action.payload.data
          }
        }
      }
        
    case SET_GAP_LINE:
        return {
          ...state,
          diagram: {
            ...state.diagram,
            gapLine: action.payload
          }
        }

    case CHECK_ZEROCOND_CONNECT:
        return {
          ...state,
          diagram: {
            ...state.diagram,
            checkZerocondConnect: {}
          }
        }

    case SAVE_NOMINAL_VOLT_LIST:
      {
        return {
          ...state,
          diagram: {
            ...state.diagram,
            chartData: state.diagram.chartData.map(chartTab => chartTab.tabId === state.diagram.currentIDChartTab ? {...chartTab, nominalVoltList : action.payload } : chartTab)
          }
        }
     }   
    case SAVE_NEW_NOMINAL_VOLT:
      {
        return {
          ...state,
          diagram: {
            ...state.diagram,
            chartData: state.diagram.chartData.map(chartTab => chartTab.tabId === action.payload.currentIDChartTab ? {...chartTab, nominalVolt : action.payload.nominalVolt } : chartTab)
          }
        }
      }
    case TOGGLE_JP_ELECTRIC_MODE:
      {
        return {
          ...state,
          diagram: {
            ...state.diagram,
            chartData: state.diagram.chartData.map(chartTab => chartTab.tabId === action.payload.currentIDChartTab ? {...chartTab, isJapaneseElectricMode : !chartTab.isJapaneseElectricMode } : chartTab)
          }
        }
      }
    case SAVE_CHART_ZOOM:
      {
        return {
          ...state,
          diagram: {
            ...state.diagram,
            chartData: state.diagram.chartData.map(chartTab => chartTab.tabId === action.payload.currentIDChartTab ? {...chartTab, zoom : action.payload.zoom } : chartTab)
          }
        }
      }
    case SAVE_CHART_INTO_CHARTDATA:
        {
          return {
            ...state,
            diagram: {
              ...state.diagram,
              chartData: state.diagram.chartData.map(chartTab => chartTab.tabId === action.payload.tabId? ({...chartTab,
              shape : chartTab.shape.map(shapes => 
                Number(shapes.id) === Number(action.payload.controlId) ? {...shapes, chart: action.payload.data} : shapes
              )
            }) : chartTab)
            }
          }
        }
    case ELEMENT_MAX_ID:
      return {
        ...state,
        projectData: {
          ...state.projectData,
          noFault: action.payload || 1
        }
      }
      
    case SET_CHART_ID:
      {
        return {
          ...state,
          diagram: {
            ...state.diagram,
            chartData: state.diagram.chartData.map(chartTab => ({...chartTab,
              shape : chartTab.shape.map(shapes => ({...shapes, 
                chart : shapes.chart.map(charts => 
                  charts.relatedGraphId === action.payload.tempId ? {...charts, relatedGraphId : action.payload.id, relatedGraphTextId : action.payload.textId} : charts
                )
              }))
            })),
            diagramData: state.diagram.diagramData.map(diagram => ({...diagram,
              shape : diagram.shape.map(shapes => shapes.type !== "MS_TEXT" && shapes.type !== "MS_INTO_POINT" ? {...shapes, 
                chart : shapes.chart.map(charts => 
                  charts.relatedGraphId === action.payload.tempId ? {...charts, relatedGraphId : action.payload.id, relatedGraphTextId : action.payload.textId, tabId: action.payload.tabId} : charts
                )
              } : shapes)
            })),
            diagramDataUpdate : state.diagram.diagramDataUpdate + 1
          }
        }
      }

    case TYPES_MAX_ID:
      return {
        ...state,
        projectData: {
          ...state.projectData,
          [action.payload.elementNoKey] : action.payload.typeMaxId
        }
      }

    case SET_DATA_UNDO:
        return {
            ...state,
            diagram:{
              ...state.diagram,
              undoData:{
                type:action.payload.type,
                dataUndo:action.payload.dataUndo
              } as Model.UndoModel
            }
        }
        case OPEN_CHART:
            return {
                ...state,
                diagram:{
                  ...state?.diagram,
                  isOpenChart: action,
                }
            }
        case ADD_LINE_TO_CHART:
          return {
              ...state,
              diagram:{
                ...state?.diagram,
                isAddToChart: action,
              }
        }
        case IS_DRAW_POWER_ARROW:
          return {
              ...state,
              diagram:{
                ...state?.diagram,
                isDrawPowerArrow: action,
              }
        }
        case IS_ADD_DRAW_POWER_ARROW:
          return {
              ...state,
              diagram:{
                ...state?.diagram,
                isDrawPowerArrow: {
                  type: IS_DRAW_POWER_ARROW,
                  payload: state.diagram.isDrawPowerArrow && state.diagram.isDrawPowerArrow.hasOwnProperty('payload') && state.diagram.isDrawPowerArrow.payload.length > 0 && action.payload.length > 0 ? [...state.diagram.isDrawPowerArrow.payload,...action.payload] : action?.payload
                },
              }
        }
        case DELETE_GRAPH_SCREEN:
          return {
              ...state,
              diagram:{
                ...state?.diagram,
                deleteGraphNo: action,
              }
        }
        case ADD_CALC_POINT_TO_CHART:
          return {
              ...state,
              diagram:{
                ...state?.diagram,
                isAddCalcPointToChart: action,
              }
        }
        case ROTATE:
            return {
                ...state,
                diagram:{
                  ...state?.diagram,
                  // preDiagramData: handleSavePreStateDiagram(state.diagram.preDiagramData, [...state.diagram.diagramData], action.payload.tabId), //khi nao co method undo thi kiem tra lai
                  diagramData: Method.handleUpdateRotateForDiagramData(state.diagram.diagramData, action.payload),
                  diagramDataUpdate : state.diagram.diagramDataUpdate + 1


                }
            }
        case UPDATE_SELECT_CONTROL:
            return {
                ...state,
                diagram:{
                  ...state?.diagram,
                  diagramData: Method.handleSelectControl(state.diagram.diagramData, state.diagram.currentIDDiagramTab, action.payload.controlId, action.payload.isMultiple),
                  // diagramDataUpdate : state.diagram.diagramDataUpdate + 1

                }
            }
        case IS_SELECT_TOUCH_GROUP:
            return {
                ...state,
                diagram:{
                  ...state?.diagram,
                  isSelectTouchGroup: action.payload
                }
            }
        case CLEAR_OPTION_MENU:
            return {
                ...state,
                diagram:{
                  ...state?.diagram,
                  clearOptionMenu: action.payload
                }
            }
        case UNSELECT_ALL_CONTROLS:
            return {
                ...state,
                diagram:{
                  ...state?.diagram,
                  diagramData: Method.handleUnSelectAllControls(state.diagram.diagramData, state.diagram.currentIDDiagramTab),
                  // diagramDataUpdate : state.diagram.diagramDataUpdate + 1

                }
            }
        case SET_COPY_DATA:
          {
            const copyData = action.payload;
            return {
              ...state,
              diagram:{
                ...state?.diagram,
                copyShapes: copyData.shapes,
                copyEleGroups: copyData.eleGroups,
                copyTCEleGroups: copyData.tcEleGroups,
                copyDiagramData: copyData.diagrams,
              }
            }
          }
        case COPY_SHAPES:
            let copyData = Method.handleCopy(state.diagram, [...state.diagram.diagramData], action.payload)
            try {
              navigator.clipboard.writeText(JSON.stringify(copyData));
            } catch (err) { }
            return {
                ...state,
                diagram:{
                  ...state?.diagram,
                  copyShapes: copyData.shapes,
                  copyEleGroups: copyData.eleGroups,
                  copyTCEleGroups: copyData.tcEleGroups,
                  copyDiagramData: copyData.diagrams,
                }
            }
        case CUT_SHAPES:
            let cutData = Method.handleCopy(state.diagram, [...state.diagram.diagramData], action.payload)
            try {
              navigator.clipboard.writeText(JSON.stringify(cutData));
            } catch (err) { }
            return {
                ...state,
                diagram:{
                  ...state?.diagram,
                  copyShapes: cutData.shapes,
                  copyEleGroups: cutData.eleGroups,
                  copyTCEleGroups: cutData.tcEleGroups,
                  copyDiagramData: cutData.diagrams,
                  // diagramData: Method.handleSaveAllControlsToDiagram(state.diagram.diagramData, Method.getShapesAfterCutOrRemove(state.diagram.diagramData, action.payload)),
              }
            }
        case PASTE_SHAPES:
            const pasteData = Method.handlePaste(
              state.diagram.diagramData, 
              state.diagram.copyShapes, 
              state.diagram.copyDiagramData,
              state.diagram.elementMaxId,
              state.diagram.typesMaxId,
              action.payload
            )
            return {
                ...state,
                diagram:{
                  ...state?.diagram,
                  diagramData: [
                    ...Method.handleSaveAllControlsToDiagram(
                      state.diagram.diagramData, 
                      pasteData.shapesData
                    ),
                    ...pasteData.newDiagram
                  ],
                  elementMaxId: pasteData.elementMaxId,
                  typesMaxId: pasteData.typesMaxId,
                  pointerPosition: Method.updatePointerPosition(state.diagram.copyShapes, state.diagram.pointerPosition),
                  diagramDataUpdate : state.diagram.diagramDataUpdate + 1

                  // TODO:
                  // groupStack: [...state.diagram.groupStack, ...Method.handlePasteGroup(state.diagram.groupStack, state.diagram.copyShapes, pasteData.newIds)]
                }
            }
        case REMOVE_SHAPES:
            return {
                ...state,
                diagram:{
                  ...state?.diagram,
                  diagramData: Method.handleSaveAllControlsToDiagram(state.diagram.diagramData, Method.handleRemove(state.diagram.diagramData, action.payload)),
                  diagramDataUpdate : state.diagram.diagramDataUpdate + 1

                }
            }
        case GROUP_SHAPES:
            return {
                ...state,
                diagram:{
                  ...state?.diagram,
                  diagramData: Method.handleGroupInElement(state.diagram.diagramData,  state.diagram.currentIDDiagramTab, action.payload),
                  elementGroups: Method.handleGroup(state.diagram.elementGroups, action.payload),
                  // diagramDataUpdate : state.diagram.diagramDataUpdate + 1

                }
            }
        case TC_GROUP_SHAPES:
            return {
                ...state,
                diagram:{
                  ...state?.diagram,
                  diagramData: Method.handleGroupInElement(state.diagram.diagramData,  state.diagram.currentIDDiagramTab, action.payload),
                  tcGroups: {
                    ...state.diagram.tcGroups,
                    [action.payload.tcId] : Method.handleGroup(state.diagram.tcGroups[action.payload.tcId], action.payload)
                  },
                  // diagramDataUpdate : state.diagram.diagramDataUpdate + 1

                }
            }
        case ADD_NEW_TC_GROUP:
            return {
                ...state,
                diagram:{
                  ...state?.diagram,
                  tcGroups: {
                    ...state.diagram.tcGroups,
                    [action.payload] : {byId: {
                      0: {
                        groupId:0,
                        groupName:null,
                        explan:null,
                        parentGroupId:null,
                        elementIds:[]
                      }
                    }, byEleId: {}}
                  }
                }
            }
        case UN_GROUP_SHAPES:
            return {
                ...state,
                diagram:{
                  ...state?.diagram,
                  diagramData: Method.handleUnGroupInElement(state.diagram.diagramData, state.diagram.currentIDDiagramTab, state.diagram.elementGroups, action.payload),
                  elementGroups: Method.handleUnGroup(state.diagram.elementGroups, action.payload),
                  // diagramDataUpdate : state.diagram.diagramDataUpdate + 1

                }
            }
        case TC_UN_GROUP_SHAPES:
            return {
                ...state,
                diagram:{
                  ...state?.diagram,
                  diagramData: Method.handleUnGroupInElement(state.diagram.diagramData, state.diagram.currentIDDiagramTab, state.diagram.tcGroups[action.payload.tcId], action.payload.peekTCGroupIds),
                  // diagramDataUpdate : state.diagram.diagramDataUpdate + 1,
                  tcGroups: {
                    ...state.diagram.tcGroups,
                    [action.payload.tcId]: Method.handleUnGroup(state.diagram.tcGroups[action.payload.tcId], action.payload.peekTCGroupIds)
                  }
                }
            }
        case SET_POINTER_POSITION:
            return {
                ...state,
                diagram:{
                  ...state?.diagram,
                  pointerPosition: action.payload,
                }
            }
        case UPDATE_PROPERTIES_OF_CONTROL:
            return {
                ...state,
                diagram:{
                  ...state?.diagram,
                  diagramData: Method.updatePropertiesOfControl(state.diagram.diagramData, action.payload, action.id, state.diagram), //diagramData
                  diagramDataUpdate : state.diagram.diagramDataUpdate + 1,
                  chartData: Method.updatePropertiesOfChart(state.diagram.chartData, action.payload, action.id, state.diagram)
                }
            }
            
        case UPDATE_PROPERTIES_OF_TRANS_CENTER:
          return {
              ...state,
              diagram:{
                ...state?.diagram,
                diagramData: Method.updatePropertiesOfTransCenter(state.diagram.diagramData, action.payload, action.id),
                diagramDataUpdate : state.diagram.diagramDataUpdate + 1,

                transCenterUpdate: state.diagram.transCenterUpdate + 1
              }
          }
        case ADD_USER_CURVE:
            return {
                ...state,
                diagram:{
                  ...state?.diagram,
                  diagramData: Method.addUserCurveOfControl(state.diagram.diagramData, action.payload.data, action.payload.controlId, state.diagram),
                  diagramDataUpdate : state.diagram.diagramDataUpdate + 1,

                }
            }
        case UPDATE_USER_CURVE:
            return {
                ...state,
                diagram:{
                  ...state?.diagram,
                  diagramData: Method.updateUserCurveOfControl(state.diagram.diagramData, action.payload.data, action.payload.controlId, state.diagram),
                  chartData: Method.updateUserCurveOfControlChart(state.diagram.chartData, action.payload.data, action.payload.controlId, state.diagram),
                  diagramDataUpdate : state.diagram.diagramDataUpdate + 1,

                }
            }
        case DELETE_USER_CURVE:
            return {
                ...state,
                diagram:{
                  ...state?.diagram,
                  diagramData: Method.deleteUserCurveOfControl(state.diagram.diagramData, action.payload.data, action.payload.controlId, state.diagram),
                  diagramDataUpdate : state.diagram.diagramDataUpdate + 1,

                }
            }
        case UPDATE_CONTROL_HEIGHT:
            return {
                ...state,
                diagram:{
                  ...state?.diagram,
                  diagramData: Method.updateControlHeight(state.diagram.diagramData, action.payload, action.id, action.x, action.y, action.offsetY, state.diagram),
                  diagramDataUpdate : state.diagram.diagramDataUpdate + 1,

                }
            }
        case UPDATE_ELEMENT_POSITION:
          return {
              ...state,
              diagram:{
                ...state?.diagram,
                diagramData: state?.diagram?.diagramData.map((item:Model.DiagramModel) =>(
                  {...item,
                    shape: item?.shape.map((e:Model.ControlModel)=>
                        (e.id == action.payload.id?
                          {...e,x:action.payload.x,y:action.payload.y} : {...e}
                        )
                      )
                  })
                ),
                diagramDataUpdate: state.diagram.diagramDataUpdate + 1
              }
          }
        case UPDATE_CONTROL_WIDTH:
          return {
            ...state,
            diagram:{
              ...state?.diagram,
              diagramData: Method.updateControlWidth(state.diagram.diagramData, action.payload, state.diagram),
              diagramDataUpdate : state.diagram.diagramDataUpdate + 1,

            }
          }
        case SET_TRANSFORM_DATA: {
          const shapes = action.payload
          return {
            ...state,
            diagram: {
              ...state?.diagram,
              transformData: shapes
            }
          }
        }
        case CHANGE_PROCESS_MODE: {
          return {
            ...state,
            diagram: {
              ...state?.diagram,
              processMode: action.payload
            }
          }
        }
        case SET_TRANSFORM_FLAG: {
          return {
            ...state,
            diagram: {
              ...state?.diagram,
              transformFlag: action.payload
            }
          }
        }
        case UPDATE_QUICK_PROPERTIES:
        {
            return {
                ...state,
                loading:true
            }
        }
        case IS_SHOW_MAINTENANCE_SCREEN:
        {
          return {
            ...state,
            diagram:{
              ...state?.diagram,
              isShowMaintenanceScreen: {show: action.payload.show, controlId: action.payload.controlId}
            }
          }
        }
        case IS_SHOW_MY_PROJECT:
        {
          return {
            ...state,
            diagram:{
              ...state?.diagram,
              isShowMyProject: action.payload
            }
          }
        }
        case SET_NOTIFICATION_LIST:
        {
          return {
            ...state,
            notificationList:action.payload
          }
        }
        case MAPPED_DATA:
        {
          return {
            ...state
          }
        }
        case SAVE_LIST_GRAPH_NAME:
          return {
            ...state,
            diagram:{
              ...state?.diagram,
              listGraphName:{list: action.payload.data, projectId: action.payload.projectId}
            }
          }
        case "CHANGE_PASS":
          return {
            ...state,
            loading:true,
          }
        case PRESS_CONTEXT_MENU_FUNCTION:
          return {
            ...state,
            diagram: {
              ...state?.diagram,
              contextMenuFunction: action.payload.data
            }
        }
        case DELETE_TC_GROUP:
          const tc_id = action.payload.tc_id;
          const filteredObject = Object.fromEntries(
            Object.entries(state.diagram.tcGroups).filter(([key]) => key != tc_id)
          );
          return{
            ...state,
            diagram:{
              ...state?.diagram,
              tcGroups:filteredObject,
            }
          }
        case SAVE_LIST_GRAPH:
          return {
            ...state,
            diagram:{
              ...state.diagram,
              listGraph:action.payload.projectList
            }
          }
  }
  return state;
};