import { CircularProgress, Dialog, DialogContent, DialogTitle } from '@mui/material';
import Konva from 'konva';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { Group, Image, Label, Rect, Text, Transformer } from 'react-konva';
import { Html, useImage } from 'react-konva-utils';
import { connect } from 'react-redux';
import lineSvg from "../../images/control/line.svg";
import lineRedSvg from "../../images/control/line_red.svg";
import rightArrow from "../../images/control/red_arrow.svg";
import lineOhmSvg from "../../images/control/インピーダンス＿オーム＿R.svg";
import lineOhmRedSvg from "../../images/control/インピーダンス＿オーム＿R_red.svg";
import imageGeneratorSvg from "../../images/control/発電機＿Equip_Generator.svg";
import imageGeneratorRedSvg from "../../images/control/発電機＿Equip_Generator_red.svg";
import * as Constant from "../../models/Constants";
import { Coordinates, LineType, MsGeneratorModel, OptionModel, ProcessMode, UndoModel } from '../../models/Index';
import { MAKE_YEAR_MAX, MAKE_YEAR_MIN } from '../../statics';
import { actionCreators } from '../../store';
import { addUserCurve, clearOptionMenu, deleteRelatedGraphByUserCurveDialog, deleteUserCurve, openChart, openDialogEvents, openUserCurveEvents, openUserCurvePropertiesEvents, setRepositionElement, setTransformFlag, undo, updateControlHeight, updateElementPosition, updatePropertiesOfControlAction, updateSelectControl, updateUserCurve } from '../../store/Actions';
import theme from '../../themes/globalStyles';
import { getElementKindValue } from '../../utils/ElementFunction';
import { BeamsNumber } from '../../utils/FormatNumber';
import { doGetRefV1 } from '../../utils/generatorFunction';
import { getElements, post } from "../CallApi";
import DraggablePaper from "../common/DraggablePaper";
import OptionMenu from '../contextMenu/OptionMenu';
import MsGeneratorEditDialog from '../dialogs/MsGeneratorEditDialog';
import UserCurveDetailDialog from '../dialogs/UserCurveDetailDialog';
import UserCurvesDialog from "../dialogs/UserCurvesDialog";
import { calcContextMenuPosition, calcDragBoundPosition, calcElementSize, getMenuOptionHeight } from '../../utils/PositionCalculation';
import { doDrawSymbolGroup } from '../graph/DCView';
import * as Model from "../../models/Index";

//#region Props
type CreateMsGeneratorProps = {
    id: string,
    image: string,
    x: number,
    y: number,
    offsetX: number;
    offsetY: number;
    width: number,
    height: number,
    type: string,
    isSelected: boolean,
    isHidden: boolean;
    rotation: number,
    lineDiagramRef: any,
    parentGroupId: number | null;
    properties: MsGeneratorModel,
    gridSizeController: {
      width: number;
      height: number;
      scale: number;
      stageOffsetX: number;
      stageOffsetY: number;
    }
    isOnShiftKey: boolean;
};

export type PureCreateMsGeneratorProps = CreateMsGeneratorProps & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
//#endregion

const CONTROL_POS_FACTOR = 0

const CreateMsGenerator = memo((props: PureCreateMsGeneratorProps) => {
    //#region Fields
    const { processMode, id, image, x, y, offsetX, offsetY, width, height, type, isSelected, isHidden, rotation, lineDiagramRef, parentGroupId, properties, infoCircuit, projectID} = props;
    const { 
        // getElementParam, 
        // updateElementPost, 
        // addUserCurveDB,
        // updateUserCurveDB,
        // deleteUserCurveDB,
        diagramDataUpdate,
        gridSizeController,
        projectId,
        openDialogData,
        openUserCurveData,
        userCurveProperties,
        userRole,
        m_bModePM,
        modeViewOnly,
        infoSkeleton,
        userId,
        diagramData,
        drawPowerArrow,
        projectData,
        currentTabId,
        clearMenuState,
        isSelectTouchGroup,
        isOnShiftKey,
        elementGroups,
        tcGroups,
        elementReposition,
        updateElementPosition,
        setRepositionElement,
        updateSelectControl, 
        updateControlData, 
        updateControlHeight, 
        setTransformFlag, 
        addUserCurve,
        mappedDataFromBE,
        updateUserCurve,
        deleteUserCurve,
        setOpenChart,
        openDialogEvents,
        openUserCurvePropertiesEvent,
        deleteRelatedGraphByUserCurveDialog,
        openUserCurveEvent,
        setUndoData,
        clearOptionMenu,
    } = props;

    const getSymbolAndBackColor = (infoSkeleton:any,processMode:any,properties:any) => {
        let symbolColor = "";
        let backColor = "";
        if(properties.checkNG == true){
            backColor = '#ff00ff';
            symbolColor = "#000000";
        }
        else{
            if(infoSkeleton.dispStress == true && processMode > ProcessMode.DRAWING){
                if(infoSkeleton.dispStainOrBack != true){
                    backColor = "#FFFFFF"
                    symbolColor = "#000000";
                }else{
                    symbolColor = "#FF0000"
                    backColor = "#FFFFFF";
                }
            }
            else{
                symbolColor = "#000000";
                backColor = "#FFFFFF";
            }
        }
        return {symbolColor:symbolColor,backColor:backColor}
    }

    const [imageStatus, setImageStatus] = useState(image);
    const [lineStatus, setLineStatus] = useState(lineSvg);
    const [lineOhmStatus, setLineOhmStatus] = useState(lineOhmSvg)
    const [showPowerInfo, setShowPowerInfo] = useState(false)
    const [showPowerDirectInfo, setShowPowerDirectInfo] = useState(false)
    const [activePower, setActivePower] = useState("?????kW")
    const [reactivePower, setReactivePower] = useState("?????kvar")
    const [svgHeight, setSvgHeight] = useState('')
    const [svgWidth, setSvgWidth] = useState('')
    const [svgLine, setSvgLine] = useState('')
    const [svgArrowActive, setSvgArrowActive] = useState('')
    const [svgArrowReactive, setSvgArrowReactive] = useState('')
    const [svgTextX, setSvgTextX] = useState('')
    const [svgTextY, setSvgTextY] = useState('')
    const [svgTextRotation, setSvgTextRotation] = useState('')

    var imageObj = new window.Image();
    imageObj.src = imageStatus;

    var lineObj = new window.Image();
    lineObj.src = lineStatus;

    var lineOhmObj = new window.Image();
    lineOhmObj.src = lineOhmStatus;

    var rightArrowObj = new window.Image();
    rightArrowObj.src = rightArrow;

    const svgToURL = (s:any) =>{
      const uri = window.btoa(unescape(encodeURIComponent(s)));
      return "data:image/svg+xml;base64," + uri;
    }
  
      const svgActivePower =
      `<svg height="${svgHeight}" width="${svgWidth}"  xmlns="http://www.w3.org/2000/svg">` +
      `<path  d="${svgLine}" stroke="${isSelected? 'red' : 'blue'}"  stroke-width="1" fill="none"/>` +
      `<path  d="${svgArrowActive}" stroke="${isSelected? 'red' : 'blue'}" stroke-width="1"  fill="none"/>` +
      `<text x="${svgTextX}" y="${svgTextY}" text-anchor="middle" font-size="10" fill="${isSelected? 'red' : 'blue'}" transform="translate(15,15) rotate(${svgTextRotation})">${activePower}</text>` +
      '</svg>' ;
  
    const urlActivePower = svgToURL(svgActivePower);
    const [imageSVGActivePower] = useImage(urlActivePower);
  
    const svgReactivePower =
    `<svg height="${svgHeight}" width="${svgWidth}"  xmlns="http://www.w3.org/2000/svg">` +
    `<path  d="${svgLine}" stroke="${isSelected? 'red' : 'blue'}"  stroke-width="1" fill="none"/>` +
    `<path  d="${svgArrowReactive}" stroke="${isSelected? 'red' : 'blue'}" stroke-width="1"  fill="none"/>` +
    `<text x="${svgTextX}" y="${svgTextY}" text-anchor="middle" font-size="10" fill="${isSelected? 'red' : 'blue'}" transform="translate(15,15) rotate(${svgTextRotation})">${reactivePower}</text>` +
    '</svg>' ;
  
    const urlReactivePower = svgToURL(svgReactivePower);
    const [imageSVGReactivePower] = useImage(urlReactivePower);
  
    
    const svgActiveReactivePower =
      `<svg height="${svgHeight}" width="${svgWidth}"  xmlns="http://www.w3.org/2000/svg">` +
      `<path  d="${svgLine}" stroke="${isSelected? 'red' : 'blue'}"  stroke-width="1" fill="none"/>` +
      `<path  d="${svgArrowActive}" stroke="${isSelected? 'red' : 'blue'}" stroke-width="1"  fill="none"/>` +
      `<text x="${svgTextX}" y="${svgTextY}" text-anchor="middle" font-size="10" fill="${isSelected? 'red' : 'blue'}" >${activePower}</text>` +
      `<text x="${svgTextX}" y="${svgTextX}" text-anchor="middle" font-size="10" fill="${isSelected? 'red' : 'blue'}" >${reactivePower}</text>` +
      '</svg>' ;
    
    const urlActiveReactivePower = svgToURL(svgActiveReactivePower);
    const [imageSVGActiveReactivePower] = useImage(urlActiveReactivePower);

    const [isOpenUserCurvesDialog, setIsOpenUserCurvesDialog] = useState(false);
    const [initialValue, setInitialValue] = useState(properties);
    const [loadingFlag,setLoadingFlag] = useState<boolean>(false);
    const [isOpenMenu, setIsOpenMenu] = useState(false);
    const [menuPosition, setMenuPosition] = useState<Coordinates>({ x: 0, y: 0 });
    const [isOpenEditorDialog, setIsOpenEditorDialog] = useState(false);
    const [groupPosition, setGroupPosition] = useState({ x: x, y: y });
    const [dragStartPosition, setDragStartPosition] = useState({ x: x, y: y, labelX: groupPosition.x + Constant.ELEMENT_SIZE, labelY: groupPosition.y + CONTROL_POS_FACTOR * (height / 2) });
    const [isResizing, setIsResizing] = useState(false);
    const [heightTransform, setHeightTransform] = useState(0);
    const [labelPosition, setLabelPosition] = useState({
        x: groupPosition.x + Constant.ELEMENT_SIZE,
        y: groupPosition.y + CONTROL_POS_FACTOR * (height / 2),
    });
    const [arrowPosition, setArrowPosition] = useState({
        x: groupPosition.x,
        y: groupPosition.y + height - Constant.ELEMENT_SIZE - 10,
    });

    const transformRef = useRef<Konva.Transformer>(null);
    const transformMoveRef = useRef<Konva.Transformer>(null);
    const groupRef = useRef<any>();
    const topRef = useRef<any>();
    const bottomRef = useRef<any>();
    const labelRef = useRef<any>();
    const rightArrowRef = useRef<any>();
    const [listUserCurves, setListUserCurves] = useState(properties.listUser?[...properties.listUser]:[])
    const [isOpenUserCurveProperties, setIsOpenUserCurveProperties] = useState(false)
    const [selectItem, setSelectItem] = useState<any>(null);
    const [isOnOpenMenu, setIsOnOpenMenu] = useState<boolean>(false);
    
    // const labelTexts = doGetRefV1(processMode, props as any, infoCircuit, false);
    const [labelTexts, setLabelTexts] = useState<any>({title:'',body:'',textHeight:0})
    //#endregion

    //#region useEffect
    useEffect(() => {
      // setLabelTexts(doGetRefV1(processMode, props as any, infoCircuit, false))
    const labelFirst = doGetRefV1(processMode, props as any, infoCircuit, false)
    const title = labelFirst.shift()
    let txt = ""
    if(labelFirst.length > 0){
      labelFirst.map((item:any, index:any) =>{
        txt += item.value +'\n'
      })
      txt = txt.slice(0, -1)
    }
    let labelTexts1 = new Konva.Text({
      text: txt,
      });
      setLabelTexts({title:title?.value,body:labelTexts1.attrs.text,textHeight:labelTexts1.textHeight})
    }, [processMode,infoCircuit,diagramDataUpdate])

    useEffect(()=>{
      if(isSelected){
        if(isOnOpenMenu) {
          setIsOnOpenMenu(false)
        }
        else
        {
          setIsOpenMenu(false)
        }
      }
    },[clearMenuState])

    useEffect(()=>{
      if(elementReposition?.id)
      {
        const groupsData = currentTabId === 1 ? elementGroups : tcGroups[diagramData.find((x:any)=>x.id == id)?.parentTcId ?? 0]
        const peekGroupId = groupsData?.byEleId[elementReposition.id as any]?.at(-1) ?? -1
        const elementIds = groupsData?.byId[peekGroupId]?.elementIds ?? []
        if(elementIds.includes(id) || diagramData[0].shape.find((item:any)=>(item.isSelected && item.id == id)))
        {
          setGroupPosition({
            x: dragStartPosition.x,
            y: dragStartPosition.y,
          });

          labelRef.current.to({
            x: dragStartPosition.labelX,
            y: dragStartPosition.labelY,
          });
          
          groupRef.current.to({
            x: dragStartPosition.x,
            y: dragStartPosition.y,
          });

          updateElementPosition(id, dragStartPosition.x, dragStartPosition.y)
        }
      }
    },[elementReposition])

    useEffect(()=>{
      if(userCurveProperties?.controlId === id && userCurveProperties !== undefined) {
        let userCurve = properties.listUser.find(x => x.userCurveId === userCurveProperties.userCurveId)
        userCurve && setSelectItem(userCurve)
        setIsOpenUserCurveProperties(true)
      }
    },[userCurveProperties])

    useEffect(() => {
      setIsOpenMenu(false)
      if(processMode == ProcessMode.POWER_FLOW && drawPowerArrow.payload.length > 0 && id)
      {
        let result = drawPowerArrow.payload.find((arrows:any) => arrows.arrow?.element?.id && Number(arrows.arrow?.element?.id) == Number(id))
        if(result && result.arrow && result.text.length > 0){
          setPowerFlowArrow(result)
        }
      }
    }, [drawPowerArrow])

    useEffect(() => {
        if(processMode == ProcessMode.POWER_FLOW)
        {
          const pElement = diagramData[0].shape.find((x:any)=>x.id === id)
          let result = doDrawSymbolGroup(pElement,processMode)
          if(result && result.arrow && result.text.length > 0){
            setPowerFlowArrow(result)
          }else{
            setActivePower("")
            setReactivePower("")
            setSvgArrowReactive("")
            setSvgArrowActive("")
            setSvgLine("");
          }
        }
        else if(showPowerInfo == true || showPowerDirectInfo == true)
        {
          setShowPowerInfo(false);
          setShowPowerDirectInfo(false);
        }
    },[processMode, projectData.flowViewMethod, properties.viewFlowResult])

    //useEffect(() => {
    //  lineDiagramRef?.current?.focus()
    //}, [])

    useEffect(() => {
      setInitialValue(properties);
    },[properties])

    useEffect(() => {
      if (x >= 0 && y >= 0 && (groupPosition.x !== x || groupPosition.y !== y)) {
        setGroupPosition({x, y})
      }
    }, [x, y])
    useEffect(() => {
        if (isSelected && !parentGroupId) {
          transformRef.current?.nodes([groupRef.current]);
        } else {
          transformRef.current?.nodes([]);
        }
        transformRef.current?.getLayer()?.batchDraw();
      }, [isSelected, parentGroupId, heightTransform]);

    useEffect(() => {
        if (!isSelected)
            setIsOpenMenu(false)
    }, [isSelected]);
    
    
    useEffect(() => {
      if(openDialogData?.id === id && openDialogData !== undefined) {
        openDialogEvents(({type: "OPEN_DIALOG", data: { }}))
        handleOpenEditorDialog()
      }
    }, [openDialogData]);

    useEffect(() => {
      if(openUserCurveData?.id === id && openUserCurveData !== undefined) {
        setIsOpenUserCurvesDialog(true)
      }
    }, [openUserCurveData]);

    useEffect(() => {
        // check grid size; label, icon position when rotate, move and change height
        // handleGridSize(groupPosition.x, groupPosition.y, height, rotation);
        calcLabelPos();
        calcArrowPos();
      }, [groupPosition, height, rotation]);

    useEffect(() => {
        switch(getSymbolAndBackColor(infoSkeleton,processMode,properties).symbolColor){
            case "#FF0000":
                setLineStatus(lineRedSvg);
                setImageStatus(imageGeneratorRedSvg);
                setLineOhmStatus(lineOhmRedSvg);
                break;
            default:
                setLineStatus(lineSvg);
                setImageStatus(imageGeneratorSvg);
                setLineOhmStatus(lineOhmSvg);
                break;
        }
    },[getSymbolAndBackColor(infoSkeleton,processMode,properties).symbolColor])
    //#endregion

    //#region Method
    const setPowerFlowArrow = (data:any) => {
      if(data.arrow.drawActiveNoneDirect){
        setShowPowerInfo(true)
        setShowPowerDirectInfo(false)
        if(data.arrow.arrowActiveAtTop){
            setSvgHeight('50')
            setSvgWidth('30')
            setSvgLine('M 20 50 L 20 0')
            setSvgArrowActive('M 16 5 L 20 0 L 24 5')
            setSvgTextX('-10')
            setSvgTextY('-5')
            setSvgTextRotation('270')
            setActivePower(data.text[0])
            setReactivePower(data.text[1])
        }
        else if(data.arrow.arrowActiveAtRight) {
            setSvgHeight('30')
            setSvgWidth('50')
            setSvgLine('M 50 20 L 0 20')
            setSvgArrowActive('M 5 16  L 0 20 L 5 24')
            setSvgTextX('7')
            setSvgTextY('3')
            setSvgTextRotation('0')
            setActivePower(data.text[0])
            setReactivePower(data.text[1])
        }
        else if(data.arrow.arrowActiveAtLeft){
            setSvgHeight('30')
            setSvgWidth('50')
            setSvgLine('M 50 20 L 0 20')
            setSvgArrowActive('M 45 16 L 50 20 L 45 24')
            setSvgTextX('7')
            setSvgTextY('3')
            setSvgTextRotation('0')
            setActivePower(data.text[0])
            setReactivePower(data.text[1])
        }
        else {
            setSvgHeight('50')
            setSvgWidth('30')
            setSvgLine('M 20 50 L 20 0')
            setSvgArrowActive('M 16 45 L 20 50 L 24 45')
            setSvgTextX('-10')
            setSvgTextY('-5')
            setSvgTextRotation('270')
            setActivePower(data.text[0])
            setReactivePower(data.text[1])
        }
      }
      if(data.arrow.drawReactiveNoneDirect){
        setShowPowerInfo(true)
        setShowPowerDirectInfo(false)
        if(data.arrow.arrowReactiveAtTop){
            setSvgHeight('50')
            setSvgWidth('30')
            setSvgLine('M 20 50 L 20 0')
            setSvgArrowReactive('M 16 5  L 20 0 L 24 5')
            setSvgTextX('-10')
            setSvgTextY('-5')
            setSvgTextRotation('270')
            setActivePower(data.text[0])
            setReactivePower(data.text[1])
        }
        else if(data.arrow.arrowReactiveAtRight) {
            setSvgHeight('30')
            setSvgWidth('50')
            setSvgLine('M 50 20 L 0 20')
            setSvgArrowReactive('M 5 16  L 0 20 L 5 24')
            setSvgTextX('7')
            setSvgTextY('3')
            setSvgTextRotation('0')
            setActivePower(data.text[0])
            setReactivePower(data.text[1])
        }
        else if(data.arrow.arrowReactiveAtLeft){
            setSvgHeight('30')
            setSvgWidth('50')
            setSvgLine('M 50 20 L 0 20')
            setSvgArrowReactive('M 45 16 L 50 20 L 45 24')
            setSvgTextX('7')
            setSvgTextY('3')
            setSvgTextRotation('0')
            setActivePower(data.text[0])
            setReactivePower(data.text[1])
        }
        else {
            setSvgHeight('50')
            setSvgWidth('30')
            setSvgLine('M 20 50 L 20 0')
            setSvgArrowReactive('M 16 45 L 20 50 L 24 45')
            setSvgTextX('-10')
            setSvgTextY('-5')
            setSvgTextRotation('270')
            setActivePower(data.text[0])
            setReactivePower(data.text[1])
        }
      }
      if(data.arrow.drawOtherDirect){
        setShowPowerInfo(false)
        setShowPowerDirectInfo(true)
        if(data.arrow.arrowActiveReactiveAtTop){
            setSvgHeight('50')
            setSvgWidth('70')
            setSvgLine('M 10 50 L 10 20')
            setSvgArrowActive('M 6 25 L 10 20 L 14 25')
            setSvgTextX('40')
            setSvgTextY('30')
            setSvgTextRotation('0')
            setActivePower(data.text[0])
            setReactivePower(data.text[1])
        }
        else if(data.arrow.arrowActiveReactiveAtRight) {
            setSvgHeight('70')
            setSvgWidth('50')
            setSvgLine('M 50 10 L 20 10')
            setSvgArrowActive('M 25 6 L 20 10 L 25 14')
            setSvgTextX('-10')
            setSvgTextY('-5')
            setSvgTextRotation('0')
            setActivePower(data.text[0])
            setReactivePower(data.text[1])
        }
        else if(data.arrow.arrowActiveReactiveAtLeft){
            setSvgHeight('70')
            setSvgWidth('50')
            setSvgLine('M 50 10 L 20 10')
            setSvgArrowActive('M 45 6 L 50 10 L 45 14')
            setSvgTextX('-10')
            setSvgTextY('-5')
            setSvgTextRotation('0')
            setActivePower(data.text[0])
            setReactivePower(data.text[1])
        }
        else {
            setSvgHeight('50')
            setSvgWidth('70')
            setSvgLine('M 10 50 L 10 20')
            setSvgArrowActive('M 6 45 L 10 50 L 14 45')
            setSvgTextX('40')
            setSvgTextY('30')
            setSvgTextRotation('0')
            setActivePower(data.text[0])
            setReactivePower(data.text[1])
        }
      }
    }

    const handleOKUserCurveDetailForm = async (data: any) => {
      const foundUserCurve = listUserCurves.find((curve:any) => curve.userCurveId === data.userCurveId)        
      if (foundUserCurve) { // update  
        setLoadingFlag(true);
        const request = { 
          userId: userId,
          projectId: projectData.projectId,
          elementId: parseInt(id),
          params:{...data},
          ownerProject: projectData.ownerId

        }
        const result = await post("/diagram/edit-curve-user", request);
        if (result.success) {
          setLoadingFlag(false);
          handleUpdateCurveUserSuccess(id, result.data);
          updateUserCurve(id,data);
        } else {
          handleSubmitError(result.error);
          setLoadingFlag(false);
        } 
          //setOpenChart(true, LineType.USER_CURVE, data.userCurveId??data.user_curve_id)
      } else { // add new
          addUserCurve(id, data)
      }
      setSelectItem(null)
      setIsOpenUserCurveProperties(false)
    }
  
    const handleCancelUserCurveDetailForm = () => {
      setSelectItem(null)
      setIsOpenUserCurveProperties(false)
      openUserCurvePropertiesEvent('', '')
    }

    const handleContextMenu = (e: any) => {
      e.evt.preventDefault();
      setIsOnOpenMenu(true)
      updateSelectControl(id);
  
      const menuHeight = getMenuOptionHeight()
      const clientWidth = lineDiagramRef.current.clientWidth
      const isTransCenter = currentTabId == 1 ? false : true;
      let {posX, posY} = calcContextMenuPosition(
        e, menuHeight, clientWidth, x, y, gridSizeController, isTransCenter
      )
      clearOptionMenu(!clearMenuState)
      
      setMenuPosition({
        x: posX,
        y: posY,
      });
      setIsOpenMenu(true);
    };

    const handleClick = (e: any) => {
      if (e.type === "click") setIsOpenMenu(false);
      if(parentGroupId && parentGroupId > 0) return;
      if (e.evt.shiftKey || isSelectTouchGroup) {
        updateSelectControl(id, true);
      } else {
        updateSelectControl(id);
      }
    };

    const handleOpenMenu = (value: boolean) => {
        setIsOpenMenu(value);
        updateSelectControl(id)
    }

    const handleOpenEditorDialog = async () => {
        setLoadingFlag(true);
        let params = {
          userId: userId,
          projectId: projectID,
          elementId: id,
          elementType: type,
          data: properties,
          ownerProject: projectData.ownerId

        };
        // getElementParam(params,id);
        const result = await post("/diagram/get-param", params);
        if (result.success) {
          handleGetSuccess(result.data);
        } else {
          handleSubmitError(result.error);
        }

        // setIsOpenEditorDialog(true);
    }

    const handleCancelEditorDialog = () => {
        setIsOpenEditorDialog(false);
    };

    const handleUpdate = async (data: any) => {
      const updateData = { ...data };
      
      setIsOpenEditorDialog(false);
      const dataSend = {
        noGenerator: updateData.noGenerator,
        refNo: updateData.refNo,
        voltage: updateData.voltage,
        capacity: updateData.capacity,
        reactance: Number(updateData.reactance),
        subReactance: Number(updateData.subReactance),
        constant: updateData.constant,
        subConstant: updateData.subConstant,
        fault: updateData.fault,
        checkNG: updateData.checkNG,
        powerFactor: updateData.powerFactor,
        nodeNumber: updateData.nodeNumber,
        perUnitR: updateData.perUnitR,
        perUnitX: updateData.perUnitX,
        perUnitK: updateData.perUnitK,
        perUnitP: updateData.perUnitP,
        perUnitQ: updateData.perUnitQ,
        perUnitV: updateData.perUnitV,
        nodeKind: updateData.nodeKind,
        isLead: updateData.isLead,
        voltageMagnitude: updateData.voltageMagnitude,
        voltageAngle: updateData.voltageAngle,
        activePower: updateData.activePower,
        reactivePower: updateData.reactivePower,
        Qmax: updateData.Qmax,
        Qmin: updateData.Qmin,
        makeDataInput: updateData.makeDataInput,
        makerName: updateData.makerName,
        makeYear: updateData.makeYear,
        makeMonth: updateData.makeMonth,
        makeNumber: updateData.makeNumber,
        type: updateData.type,
        pointText: updateData.pointText,
        viewFlowResult: updateData.viewFlowResult,
        examineComment: updateData.examineComment,
        seriesed: updateData.seriesed,
        viewResultText: updateData.viewResultText,
        appointVoltage: updateData.appointVoltage,
        calcPoint0:updateData.calcPoint0,
        calcPoint1:updateData.calcPoint1,
      }

      // call api
      const request = { 
        userId: userId,
        projectId: projectId,
        elementType: getElementKindValue(props.type),
        elementId: id,
        param: dataSend,
        ownerProject: projectData.ownerId
      }
      setLoadingFlag(true);
      // updateElementPost(request, id);
      const result = await post("/diagram/set-param", request);
      if (result.success) {
        handleSuccessUpdate(result.data);
        if(result.data && result.data.resultCode == 0){
          setUndoData({type:"UPDATE_PROPERTIES_ELEMENT",dataUndo:{}} as UndoModel)
          updateControlData(id, updateData);
          setInitialValue(updateData);
        }
      } else {
        handleSubmitError(result.error);
      }
      openDialogEvents({type: "RELOAD", data: { }});
    }

    const dragBoundFunc = (e: any, mode: string) => {
      // let rect = groupRef.current.getClientRect();
      const newPosition = calcDragBoundPosition(
        e, groupPosition, rotation, height, mode, gridSizeController
      );
  
      return {
        x: newPosition.newX,
        y: newPosition.newY,
      };
    };

    const calcLabelPos = (e?: any) => {
        let newX = groupPosition.x,
          newY = groupPosition.y;
        if (e?.type === "dragmove") {
          newX = groupRef.current.attrs.x;
          newY = groupRef.current.attrs.y;
        }
    
        const newPosition = {
          x: newX + Constant.ELEMENT_SIZE,
          y: newY + CONTROL_POS_FACTOR * (height / 2),
        };
    
        if (rotation !== 0) {
          newPosition.x = newX + CONTROL_POS_FACTOR * (height / 2);
          newPosition.y = newY - Constant.ELEMENT_SIZE;
        }
    
        setLabelPosition(newPosition);
    };
      
    const calcArrowPos = (e?: any) => {
        let newX = groupPosition.x,
            newY = groupPosition.y;
        if (e?.type === "dragmove") {
            newX = groupRef.current.attrs.x;
            newY = groupRef.current.attrs.y;
        }
    
        const newPosition = {
            x: newX,
            y: newY + height - Constant.ELEMENT_SIZE - 5,
        };
    
        if (rotation !== 0) {
            newPosition.y = newY;
            rotation === -90 &&
                (newPosition.x = newX + height - Constant.ELEMENT_SIZE - 10);
            rotation === 90 && (newPosition.x = newX + 10);
        }
    
        setArrowPosition(newPosition);
      };

    const handleDragmove = (e: any) => {
        calcLabelPos(e);
        calcArrowPos(e)
    };

    const onDragEnd = (e: any) => {
        if (
          e.currentTarget.attrs.x === groupPosition.x &&
          e.currentTarget.attrs.y === groupPosition.y
        ) {
          return;
        }
    
        let newX =
          Math.round(e.target.x() / Constant.POINTER_WIDTH) *
          Constant.POINTER_WIDTH;
        let newY =
          Math.round(e.target.y() / Constant.POINTER_HEIGHT) *
          Constant.POINTER_HEIGHT;
             
        // check new position is outside grid
        if (newX < 0 || newY < 0){
          setRepositionElement(id, !(elementReposition?.state??true))
          newX = dragStartPosition.x;
          newY = dragStartPosition.y;
        }
        
        setGroupPosition({
          x: newX,
          y: newY,
        });
    
        e.target.to({
          x: newX,
          y: newY,
        });
    };

    // Mobile Touch Events
    var isMoving = false
  var isLongPress = false
  
  const handleTouchStart = useCallback((e:any) => {
    setIsOpenMenu(false)
    isLongPress = true
    isMoving = false
    !isSelectTouchGroup && setTimeout(() => {
      if (isLongPress && !isMoving) {
        handleContextMenu(e)
      }
    }, 1000)
  },[x,y,isSelectTouchGroup])

  const handleTouchMove = useCallback(() => {
    isMoving = true
  },[x,y,isSelectTouchGroup])

  const handleTouchEnd = useCallback((e:any) => {
    isLongPress = false
  },[x,y,isSelectTouchGroup])
    
    const handleMouseDown = (e: any, mode: string) => {
        const container = e.target.getStage().container();
        container.style.cursor = "ns-resize";
        setIsResizing(true);
    };
        
    const handleMouseMove = (e: any, mode: string) => {
        if (!isResizing) return;
        setHeightTransform(e.evt.movementY);
    };

    const handleMouseUp = (e: any, mode: string) => {
      setTransformFlag(true);
      const container = e.target.getStage().container();
      container.style.cursor = "default";
  
      let newPosX = e.target._lastPos.x // e.evt.offsetX
      let newPosY = e.target._lastPos.y // e.evt.offsetY
  
      let newEle = calcElementSize(
        newPosX,
        newPosY,
        groupPosition,
        rotation,
        height,
        offsetY,
        mode,
        gridSizeController
      )
  
      topRef.current.y(0);
      // bottomRef.current.y(newHeight - 7)
      setGroupPosition({ x: newEle.newX, y: newEle.newY });
  
      updateControlHeight(id, newEle.newHeight, newEle.newX, newEle.newY, newEle.newOffsetY);
      setIsResizing(false);
    };

    const doGetValueStatorOhmR = (initialValue:any, frequency:number) => {
      if(frequency === 0 || initialValue.constant === 0)
        return 0;
      return initialValue.subReactance/( 2 * Math.PI * frequency * initialValue.constant);
    }
    const doGetValueSubCurrentIkd = (initialValue:any) => {
      if(initialValue.statorResistance === 0 && initialValue.subReactance === 0)
          return 0;
      return (initialValue.ratedCurrent * 100 / Math.sqrt(Math.pow(initialValue.statorResistance,2) + Math.pow(initialValue.subReactance,2)));
    }
    const doGetValueCurrentIkd = (initialValue:any) => {
      if(initialValue.statorResistance === 0 && initialValue.reactance === 0)
          return 0;
      return (initialValue.ratedCurrent * 100 /  Math.sqrt(Math.pow(initialValue.statorResistance,2) + Math.pow(initialValue.reactance,2)));
    }
  const doGetValueIacNoLoad = (frequency:number,initialValue:any) =>{
    let diacNoLoad;
    if(frequency === 0 || initialValue.subConstant === 0)
        diacNoLoad = initialValue.currentIkd;
    else 
        diacNoLoad = (initialValue.subCurrentIkd - initialValue.currentIkd) * Math.exp(-1 / (2 * frequency * initialValue.subConstant)) + initialValue.currentIkd;
    return diacNoLoad;
  }
const doGetValuePeakValue = (frequency:number,initialValue:any) => {
  let peakValue;
  if(frequency == 0 || initialValue.constant == 0)
      peakValue = Math.sqrt(2.0) * initialValue.iacNoLoad;
  else
      peakValue = Math.sqrt(2.0) * initialValue.iacNoLoad + Math.sqrt(2.0) * initialValue.subCurrentIkd * Math.exp(-1 / (2 * frequency * initialValue.constant));
  return peakValue;
}
const doGetValueRatedCurremt = (initialValue:any) => {
  let dratedCurrent;
  if(initialValue.voltage <= 0)
      dratedCurrent = 0;
  else
      dratedCurrent = initialValue.capacity * 1000 / initialValue.voltage / Math.sqrt(3.0);
  return dratedCurrent;
}
  const handleGetSuccess = (data:any) => {
    let newData = data.data
    const results = {...initialValue};
    // initialValue.reactance = newData.reactance;
    // initialValue.subReactance = newData.subReactance;
    const capacityList = [];
    for(const item of newData.generatorList){
      capacityList.push({label:item.lcapacity,value:item.lcapacity} as OptionModel)
    }
    results.capacityList = capacityList;
    // results.constant = newData.constant;
    // results.subConstant = newData.subConstant;
    const voltageList = [];
    for(const item of newData.voltageList)
    {
      voltageList.push({label:item.lvoltage,value:item.lvoltage} as OptionModel)
    }
    results.voltageList = voltageList;
    results.generatorList = newData.generatorList;
    results.ratedCurrent = BeamsNumber(doGetValueRatedCurremt(results),4)
    results.statorResistance = BeamsNumber(doGetValueStatorOhmR(results,infoCircuit.frequency),4)
    results.subCurrentIkd = BeamsNumber(doGetValueSubCurrentIkd(results)/1000,4)
    results.currentIkd = BeamsNumber(doGetValueCurrentIkd(results)/1000,4)
    results.iacNoLoad = BeamsNumber(doGetValueIacNoLoad(infoCircuit.frequency,results),4)
    results.iacLoad = BeamsNumber((1.1 * results.iacNoLoad),4)
    results.peakValue = BeamsNumber(doGetValuePeakValue(infoCircuit.frequency,results),4)
    let makerNameList: OptionModel[] = [];
    if (newData.makerNameList){
        newData.makerNameList.forEach((element: any) => {
            let optionModel: OptionModel = {
                label: element.smanuName,
                value: element.smanuName
            };
            makerNameList.push(optionModel)
        });
    }
 
    let makeYearList: OptionModel[] = [];
    for (let i = MAKE_YEAR_MAX; i >= MAKE_YEAR_MIN; i--){
        let optionModel: OptionModel = {
            label: i.toString(),
            value: i
        };
        makeYearList.push(optionModel)
    }
    let makeMonthList: OptionModel[] = [];
    for (let i = 1; i <= 12; i++){
        let optionModel: OptionModel = {
            label: i.toString(),
            value: i
        };
        makeMonthList.push(optionModel)
    }

    results.makeYearList = makeYearList;
    results.makeMonthList = makeMonthList;
    results.makerNameList = makerNameList;
    setInitialValue(results);
    setLoadingFlag(false);
    setIsOpenEditorDialog(true);
  }
  const handleSubmitError = (error:any) => {
    setLoadingFlag(false);
  } 
  const handleAddUserCurve = async (controlId:string,data:any)=>{
    const request = { 
      userId: userId,
      projectId: projectId,
      elementId: parseInt(controlId),
      params:{...data},
      ownerProject: projectData.ownerId

    }
    // addUserCurveDB(controlId,request)
    const result = await post("/diagram/add-curve-user", request);
    if (result.success) {
      handleAddCurveUserSuccess(id, result.data);
    } else {
      handleSubmitError(result.error);
    }
  }
  const handleUpdateUserCurve = async (controlId:string,data:any) =>{
    const request = { 
      userId: userId,
      projectId: projectId,
      elementId: parseInt(controlId),
      params:{...data},
      ownerProject: projectData.ownerId

    }
    // updateUserCurveDB(controlId,request)
    const result = await post("/diagram/edit-curve-user", request);
    if (result.success) {
      handleUpdateCurveUserSuccess(id, result.data);
    } else {
      handleSubmitError(result.error);
    }
  }
  const handleDeleteUserCurve = async (controlId:string,data:any) =>{
    const request = { 
      userId: userId,
      projectId: projectId,
      elementId: parseInt(controlId),
      userCurveId: data["userCurveId"],
      ownerProject: projectData.ownerId

    }
    // deleteUserCurveDB(controlId,request)
    deleteRelatedGraphByUserCurveDialog({controlId, data: data["userCurveId"], mode : true})
    const result = await post("/diagram/delete-curve-user", request);
    if (result.success) {
      handleDeleteCurveUserSuccess(id, result.data);
    } else {
      handleSubmitError(result.error);
    }
  }
  const handleAddCurveUserSuccess = (controlId:string,data:any) =>{
    addUserCurve(controlId,data)
    setListUserCurves(prev => [...prev, data])
  }
  const handleUpdateCurveUserSuccess =(controlId:string,data:any) =>{
    const foundUserCurve = listUserCurves.findIndex((curve:any) => curve.userCurveId === data["userCurveId"])
    const newList = [...listUserCurves]
    newList[foundUserCurve] = data
    updateUserCurve(controlId,data)
    setListUserCurves(newList)
  }
  const handleDeleteCurveUserSuccess = (controlId:string,data:any) =>{
      const newList = [...listUserCurves]
      newList.splice(newList.findIndex((curve : any) => curve.userCurveId === data), 1)
      let params = {
        userCurveId: data,
        projectId: projectId,
      }
      deleteUserCurve(controlId,params)
      setListUserCurves([...newList])
  }

  // updateElementPostのAPI呼び出し成功時
  const handleSuccessUpdate = async (data:any) => {
    if(data && data.resultCode == 0){
      const result = await getElements(userId, projectId, data.data.element_ids,projectData.ownerId)
      if (result.success) {
        setLoadingFlag(false);
        mappedDataFromBE(result.data)
      } else {
        setLoadingFlag(false);
        handleSubmitError(result.error)
      }
    }
  }

  // updateElementPostのAPI呼び出しエラー時
  const handleErrorUpdate = (error:any) => {
    setLoadingFlag(false);
  }
    //#endregion

    return (
        <>
            {isOpenMenu &&
              <OptionMenu
               controlId={id}
               isOpenMenu              = {isOpenMenu}
               setIsOpenMenu           = {handleOpenMenu}
               onProperties            = {handleOpenEditorDialog}
               x                       = {menuPosition.x}
               y                       = {menuPosition.y}
               isDisabledDelete        = {false}
               isDisabledCut           = {false}
               isDisabledCopy          = {false}
               isDisabledPaste         = {false}
               isDisabledOpenGraph     = {false}
               isDisabledProps         = {false}
               onUserCurves={() => setIsOpenUserCurvesDialog(true)}
               />
            }
            <Label
                ref={labelRef}
                width={Constant.LABEL_WIDTH}
                x={labelPosition.x}
                y={labelPosition.y}
                offsetX={[90, -90].includes(rotation) ? 8 : -5}
            >
                {/* {labelTexts.length > 0 &&
                  labelTexts.map((item:any, index:any) => {
                    
                    let content = item.value;
                    let offsetY = index * Constant.LINE_HEIGHT;
                    rotation !== 0 &&
                      index !== 0 &&
                      (offsetY = (index - 1) * Constant.LINE_HEIGHT + width + Constant.ELEMENT_SIZE);

                    return (
                      <Text
                        y={offsetY - 12}
                        key={index}
                        text={content}
                        fill={isSelected ? "red" : item.color}
                      />
                    );
                  })} */}
                  <Text
                    text={labelTexts.title}
                    y={labelTexts.textHeight - 24}
                    fill={isSelected ? "red" : '#f800f8'}
                  />
                  {labelTexts.body.length > 0 &&
                    <Text
                        y={labelTexts.textHeight - 14}
                        text={labelTexts.body}
                        fill={isSelected ? "red" : 'blue'}
                    />
                  }
            </Label>
            <Group
                ref                     = {groupRef}
                id                      = {id}
                x                       = {groupPosition.x}
                y                       = {groupPosition.y}
                width                   = {width}
                height                  = {height}
                draggable={processMode === ProcessMode.DRAWING && !m_bModePM && !modeViewOnly && !isOnShiftKey}
                onDragStart             = {() => {
                  setIsOpenMenu(false); 
                  groupRef.current.moveToTop();
                  transformRef.current?.moveToTop();
                  labelRef.current?.moveToTop();
                  rightArrowRef.current?.moveToTop();
                  setDragStartPosition({x: groupPosition.x, y: groupPosition.y, labelX: labelPosition.x, labelY: labelPosition.y});
                }}
                type                    = {type}
                onClick                 = {handleClick}
                onTap                   = {handleClick}
                onContextMenu           = {handleContextMenu}
                rotation                = {rotation}
                onDblClick              = {handleOpenEditorDialog}
                onDblTap                = {handleOpenEditorDialog}
                onMouseDown             = {() => transformMoveRef.current?.nodes([groupRef.current])}
                onMouseUp               = {() => transformMoveRef.current?.nodes([])}
                onDragMove={(e: any) => handleDragmove(e)}
                onDragEnd               = {onDragEnd}
                onMouseLeave            = {(e: any) => {
                    const container = e.target.getStage().container();
                    container.style.cursor = "default";
                }}
                onTouchStart            = {handleTouchStart}
                onTouchMove             = {handleTouchMove}
                onTouchEnd              = {handleTouchEnd}
            >
                { processMode !== ProcessMode.PERCENT_Z &&
                  <Image
                    fill={getSymbolAndBackColor(infoSkeleton,processMode,properties).backColor}
                    image           = {lineObj}
                    height          = {height - 6}
                    y               = {6}
                    onMouseEnter    = {(e: any) => {
                        const container = e.target.getStage().container();
                        container.style.cursor = (processMode === ProcessMode.DRAWING && !m_bModePM && !modeViewOnly) ? "move" : "crosshair";
                    }}
                  />
                }
                {processMode === ProcessMode.PERCENT_Z  &&
                  <>
                    <Image
                      fill={getSymbolAndBackColor(infoSkeleton,processMode,properties).backColor}
                      image={lineOhmObj}
                      height={34}
                      width={13}
                      x={3.5}
                      y={Constant.ELEMENT_SIZE}
                      onMouseEnter={(e: any) => {
                        const container = e.target.getStage().container();
                        container.style.cursor = "crosshair";
                      }}
                    />
                    <Image
                        fill={getSymbolAndBackColor(infoSkeleton,processMode,properties).backColor}
                        image={lineObj}
                        height={height - 34 - Constant.ELEMENT_SIZE}
                        y={Constant.ELEMENT_SIZE + 34}
                        onMouseEnter={(e: any) => {
                          const container = e.target.getStage().container();
                          container.style.cursor = "crosshair";
                        }}
                    />
                  </>
                }
                <Image
                   fill={getSymbolAndBackColor(infoSkeleton,processMode,properties).backColor}
                    image           = {imageObj}
                    width           = {Constant.ELEMENT_SIZE}
                    height          = {Constant.ELEMENT_SIZE}
                    onMouseEnter    = {(e: any) => {
                        const container = e.target.getStage().container();
                        container.style.cursor = (processMode === ProcessMode.DRAWING && !m_bModePM && !modeViewOnly) ? "move" : "crosshair";
                    }}
                />
                {processMode === ProcessMode.DRAWING && !m_bModePM && !modeViewOnly && isSelected && !parentGroupId && (
                    <>
                        <Rect
                            ref             = {topRef}
                            fill            = 'black'
                            x               = {6.75}
                            y               = {0}
                            width           = {7}
                            height          = {7}
                            draggable
                            onMouseDown={() => setTransformFlag(false)}
                            dragBoundFunc   = {(e:any) => dragBoundFunc(e, "top")}
                            onDragStart     = {(e:any) => handleMouseDown(e, "top")}
                            onDragMove      = {(e:any) => handleMouseMove(e, "top")}
                            onDragEnd       = {(e:any) => handleMouseUp(e, "top")}
                            onMouseEnter    = {(e: any) => {
                                const container = e.target.getStage().container();
                                container.style.cursor = "ns-resize";
                            }}
                        />
                        <Rect
                            ref             = {bottomRef}
                            fill            = 'black'
                            x               = {6.75}
                            y               = {height - 7}
                            width           = {7}
                            height          = {7}
                            draggable
                            onMouseDown={() => setTransformFlag(false)}
                            dragBoundFunc   = {(e:any) => dragBoundFunc(e, "bottom")}
                            onDragStart     = {(e:any) => handleMouseDown(e, "bottom")}
                            onDragMove      = {(e:any) => handleMouseMove(e, "bottom")}
                            onDragEnd       = {(e:any) => handleMouseUp(e, "bottom")}
                            onMouseEnter    = {(e: any) => {
                                const container = e.target.getStage().container();
                                container.style.cursor = "ns-resize";
                            }}
                        />
                    </>
                )}
            </Group>
            { showPowerInfo &&
              <>
                <Image  
                  image={imageSVGActivePower} 
                  listening={false}
                  x={arrowPosition.x - 55}
                  y={arrowPosition.y - 30}
                />
      
                <Image  
                  image={imageSVGReactivePower} 
                  listening={false}
                  x={arrowPosition.x - 30}
                  y={arrowPosition.y - 30}
                />
              </>
                
            }
            { showPowerDirectInfo &&
              <>
                <Image  
                  image={imageSVGActiveReactivePower} 
                  listening={false}
                  x={arrowPosition.x + 20}
                  y={arrowPosition.y - 30}
                />
              </>
                
            }
                  
            {(properties.fault && !([Model.ProcessMode.VOLTDROP,Model.ProcessMode.IMPROVE_PF,Model.ProcessMode.HARMONIC,Model.ProcessMode.POWER_FLOW].includes(processMode)) && !m_bModePM) && (
              <>
                <Image
                    ref={rightArrowRef}
                    offsetY={[90, -90].includes(rotation) ? Constant.ELEMENT_SIZE / 2 : 0}
                    offsetX={-Constant.ELEMENT_SIZE / 2}
                    x={arrowPosition.x}
                    y={arrowPosition.y + 8}
                    image={rightArrowObj}
                    width={Constant.ELEMENT_SIZE * 2}
                    height={Constant.ELEMENT_SIZE * 2}
                />
                <Text
                  text={properties.pointText}
                  fontSize={10}
                  fill="red"
                  offsetY={[90, -90].includes(rotation) ? Constant.ELEMENT_SIZE / 2 : 0}
                  offsetX={-Constant.ELEMENT_SIZE / 2}
                  x={arrowPosition.x + 10}
                  y={arrowPosition.y + 8}
                />
              </>
            )}

            <Transformer
                ref                 = {transformRef}
                rotateEnabled       = {false}
                resizeEnabled       = {false}
                keepRatio           = {false}
                borderDash          = {[4]}
                anchorStroke        = "black"
                borderStroke        = "black"
                anchorFill          = "black"
            />

            <Transformer
                ref                 = {transformMoveRef}
                rotateEnabled       = {false}
                resizeEnabled       = {false}
                keepRatio           = {false}
                borderEnabled       = {false}
                anchorStroke        = "black"
                borderStroke        = "black"
                anchorFill          = "black"
            />
            {loadingFlag && isSelected && (
                
                    <Html>
                      <Dialog open={true} maxWidth={"md"} fullWidth>
                      <div id="loadingFlag" style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1500, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                          <CircularProgress />
                      </div>
                      </Dialog>
                    </Html>
            )}
            {isOpenEditorDialog && !isHidden && 
                <Html>
                    <Dialog open={true} fullWidth PaperComponent={DraggablePaper}>
                        <DialogTitle onMouseOver={(e : any) => e.target.style.cursor = 'move'} style={{ paddingTop: '2px', paddingBottom: '2px', background: theme.palette.primary.main, color: theme.palette.primary.contrastText }}>発電機</DialogTitle>
                        <DialogContent sx={{ pb: 1.5 }}>
                            <MsGeneratorEditDialog 
                              infoCircuit={infoCircuit}
                              data={initialValue}
                              onCancel={handleCancelEditorDialog}
                              onOK={handleUpdate}
                              userRole={userRole}
                              m_bModePM={m_bModePM}
                              modeViewOnly={modeViewOnly}
                              processMode={processMode}
                            />
                        </DialogContent>
                    </Dialog>
                </Html>
            }
            
            {/* ============== USER CURVE ============== */}
            {isOpenUserCurvesDialog && !isHidden && (
              <Html>
                <UserCurvesDialog
                  type={type}
                  addUserCurve={handleAddUserCurve}
                  updateUserCurve={handleUpdateUserCurve}
                  deleteUserCurve={handleDeleteUserCurve}
                  listUserCurves ={listUserCurves}
                  controlId={id}
                  controlProperties={properties}
                  modeViewOnly={modeViewOnly}
                  onCancel={() => {setIsOpenUserCurvesDialog(false); openUserCurveEvent('')}}
                  onDraw={(data : any) => {   
                    setOpenChart(true, LineType.USER_CURVE, data.userCurveId??data.user_curve_id)
                    setIsOpenUserCurvesDialog(false)
                  }}
                />
              </Html>
            )}

          {isOpenUserCurveProperties && !isHidden &&
            <Html>
                <UserCurveDetailDialog modeViewOnly={modeViewOnly} type={type} selectedRow={selectItem} properties={properties} onOK={handleOKUserCurveDetailForm} onCancel={handleCancelUserCurveDetailForm}></UserCurveDetailDialog>
            </Html>
          }
        </>       
        
    );
});

const mapStateToProps = (state: any) => {
    return {
      userId: state.app.user?.userId,
      processMode: state.app.diagram.processMode,
      projectId: state.app.projectData.projectId,
      infoCircuit: state.app.diagram.infoCircuit,
      openDialogData : state.app.diagram.openDialog["OPEN_DIALOG"],
      openUserCurveData: state.app.diagram.openUserCurve["OPEN_USER_CURVE"],
      projectID : state.app.projectData.projectId,
      userCurveProperties: state.app.diagram.openUserCurveProperties["OPEN_USER_CURVE_PROPERTIES"],
      clearMenuState: state.app.diagram.clearOptionMenu,
      userRole: state.app.user?.userRole,
      m_bModePM: state.app.diagram.m_bModePM,
      modeViewOnly: state.app.diagram.modeViewOnly,
      diagramData: state.app.diagram.diagramData.filter((r: any) => r.tabId === state.app.diagram.currentIDDiagramTab),
      infoSkeleton:state.app.diagram.infoSkeleton,
      drawPowerArrow: state.app.diagram.isDrawPowerArrow,
      isSelectTouchGroup: state.app.diagram.isSelectTouchGroup,
      projectData: state.app.projectData,
      diagramDataUpdate: state.app.diagram.diagramDataUpdate,
      currentTabId: state.app.diagram.currentIDDiagramTab,
      tcGroups: state.app.diagram.tcGroups,
      elementGroups: state.app.diagram.elementGroups,
      elementReposition: state.app.diagram.elementReposition,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
      updateSelectControl: (controlId: string, isMultiple : boolean = false) =>
      dispatch(updateSelectControl(controlId, isMultiple)),
        updateControlData: (id: string, data: object) => dispatch(updatePropertiesOfControlAction(id, data)),
        updateControlHeight: (id: string, height: number, x: number, y: number, offsetY: number) => dispatch(updateControlHeight(id, height, x, y, offsetY)),
        setTransformFlag: (visible: boolean) => dispatch(setTransformFlag(visible)),
        // getElementParam: (data: any,id:any) => dispatch(actionCreators.fetch(`GET_GENERATOR_${id}`, "/diagram/get-param", "POST", data, false, true)),
        // updateElementPost: (data: any, id: string) => dispatch(actionCreators.fetch(`UPDATE_GENERATOR_${id}`, "/diagram/set-param", "POST", data, false, true)),
        addUserCurve: (controlId : string, data : any) => dispatch(addUserCurve(controlId, data)),
        updateUserCurve: (controlId : string, data : any) => dispatch(updateUserCurve(controlId, data)),
        deleteUserCurve: (controlId : string, data : any) => dispatch(deleteUserCurve(controlId, data)),
        setOpenChart: (isOpenChart: boolean, lineType : string, lineId : number) => dispatch(openChart(isOpenChart, lineType, lineId)),
      clearOptionMenu: (state:boolean) => dispatch(clearOptionMenu(state)),
        // addUserCurveDB: (controlId:string,data : any) => dispatch(actionCreators.fetch(`ADD_CURVE_USER_GENERATOR${controlId}`, "/diagram/add-curve-user", "POST", data, false, true)),
        // updateUserCurveDB: (controlId:string,data : any) => dispatch(actionCreators.fetch(`UPDATE_CURVE_USER_GENERATOR${controlId}`, "/diagram/edit-curve-user", "POST", data, false, true)),
        // deleteUserCurveDB: (controlId:string,data : any) => dispatch(actionCreators.fetch(`DELETE_CURVE_USER_GENERATOR${controlId}`, "/diagram/delete-curve-user", "POST", data, false, true)),
        openDialogEvents: (param : any) => dispatch(openDialogEvents(param)),
        mappedDataFromBE: (elements:any) => dispatch(actionCreators.mappedData(elements)),
        openUserCurveEvent: (params: any) => dispatch(openUserCurveEvents({type: "OPEN_USER_CURVE", data: { id: params }})),
        openUserCurvePropertiesEvent: (controlId: any, userCurveId: any) => dispatch(openUserCurvePropertiesEvents({type: "OPEN_USER_CURVE_PROPERTIES", data: { controlId: controlId, userCurveId: userCurveId }})),
      deleteRelatedGraphByUserCurveDialog: (params:any) => dispatch(deleteRelatedGraphByUserCurveDialog(params)),
        setUndoData: (data:UndoModel) => dispatch(undo(data)),
        setRepositionElement: (id:any, state:boolean) => dispatch(setRepositionElement(id, state)),
        updateElementPosition:(id:any, x:any, y:any) => dispatch(updateElementPosition(id, x, y)),
      };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateMsGenerator);

