import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Grid, IconButton } from "@mui/material";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from 'ag-grid-react';
import { forwardRef, useImperativeHandle, useMemo, useRef, useState } from "react";
import { PageData } from '../../../models/Index';
import { BreakCapacityModel } from "../../../models/breakModel";
import { AG_GRID_LOCALE_JP } from '../../../themes/localeJP';
import LoadingOverlay, { DefaultLoadingMessage } from '../../common/LoadingOverlay';
import NoRowsOverlay, { NoRowsMessage } from '../../common/NoRowsOverlay';
import PaginationCpn from '../../common/PaginationCpn';
//----
//Props
export type EquipBusductGridProps = {
    colState: any[];
    pageSize?: number;
    pageData?: PageData;
    onChangeSearchCondition: (name: string, value: any) => void;
    keyword: string;
    onDelete: (data: BreakCapacityModel) => void;
    onShowDetails: (lMagniTimeID: string) => void;
    heightForm?: string;
    checkEdit?: boolean;
    mode?:string;
}
// ------------
// Component
export const EquipBusductGrid = forwardRef((props: EquipBusductGridProps, ref) => {
    const {
        pageSize,
        pageData,
        heightForm,
        keyword,
        checkEdit,
        onChangeSearchCondition,
        onDelete,
        onShowDetails,
        mode
    } = props;
    const gridRef = useRef() as any;
    const [rowData, setRowData] = useState();
    useImperativeHandle(ref, () => ({
        setRowData(value: any) {
            setRowData(value)
        },
        hideOverlay() {
            gridRef.current.api.hideOverlay();
        }
    }));
    // ag-Grid の準備完了後に呼ばれるコールバック関数
    const onGridReady = (grid: any) => {
    };

    // Loading時のメッセージ
    const loadingOverlayComponent = useMemo(() => {
        return LoadingOverlay;
    }, []);
    const loadingOverlayComponentParams = useMemo(() => {
        return {
            loadingMessage: checkEdit ? "" : DefaultLoadingMessage,
        };
    }, []);

    const noRowsOverlayComponent = useMemo(() => {
        return NoRowsOverlay;
    }, []);

    const noRowsOverlayComponentParams = useMemo(() => {
        return {
            noRowsMessageFunc: () => NoRowsMessage,
        };
    }, []);

    // 編集ボタンのセル内表示とボタンイベント
    const cellMessageRenderer = (props: any) => {
        // 詳細
        const handleEdit = () => {
            onShowDetails(props.data.lBusDuctId);
        };

        const handleDelete = () => {
            onDelete(props.data.lBusDuctId);
        };

        return (
            <Grid container direction="row" justifyContent="flex-start" paddingLeft={"-60px"}>
                <IconButton aria-label="edit" onClick={handleEdit} >
                    <EditIcon fontSize="small" />
                </IconButton>
                <IconButton aria-label="delete" onClick={handleDelete} >
                    <DeleteIcon fontSize="small" />
                </IconButton>
            </Grid>
        );
    };
    // 全列に共通の定義
    const defaultColDef = {
        sortable: true, // ソート可
        editable: false, // 編集不可
        resizable: true,
        suppressMovable: true,  // 列の移動を無効にする
        headerClass: "ag-custom-header"
    };

    // 列タイプの定義
    const columnTypes = {
        nonEditableColumn: { editable: false }, // 編集不可
    };
    // 列の個別定義
    const columnDefs = [
        {
            headerName: "ID",
            field: "lBusDuctId",
            rowDrag: false,
            flex: 1,
            minWidth: 70
        },
        {
            headerName: "デフォルトの定格電流",
            field: "bIsDefault",
            rowDrag: false,
            flex: 1.5,
            minWidth: 170
        },
        {
            headerName: "抵抗（mohm/m）",
            field: "dOhmr",
            rowDrag: false,
            flex: 1.5,
            minWidth: 150
        },
        {
            headerName: "50Hzのリアクタンス（mohm/m）",
            field: "dOhmx50",
            rowDrag: false,
            flex: 1.5,
            minWidth: 250
        },
        {
            headerName: "60Hzのリアクタンス（mohm/m）",
            field: "dOhmx60",
            rowDrag: false,
            flex: 1.5,
            minWidth: 250
        },        
        {
            headerName: "定格電流（A）",
            field: "dRatedCurrent",
            rowDrag: false,
            flex: 1.5,
            minWidth: 150
        },
        {
            headerName: "材質",
            field: "nMaterial",
            rowDrag: false,
            flex: 1.5,
            minWidth: 150,
            valueFormatter: (params: any) => {
                return params.value === 1 ? 'Cu' : params.value === 2 ? 'Al' : params.value;
            }
        },
        {
            headerName: "バスダクトの枚数",
            field: "nNumber",
            rowDrag: false,
            flex: 1.5,
            minWidth: 150
        },
        {
            headerName: "厚さ（mm）",
            field: "nThickness",
            rowDrag: false,
            flex: 1.5,
            minWidth: 150
        },
        {
            headerName: "幅（mm）",
            field: "nWidth",

            rowDrag: false,
            flex: 1.5,
            minWidth: 150
        },   
        {
            headerName: "",
            field: "option",
            reseizable: false,
            suppressMovable: true,
            minWidth: 110,
            maxWidth: 110,
            cellRenderer: cellMessageRenderer,
        }
    ];

    const rowHeight = 48;
    gridRef.current?.api?.setQuickFilter(keyword);
    const handleEdit = (props: any) => {
        onShowDetails(props.data.lBusDuctId);
    }

    return (
        <>
            <div>
                <div className="ag-theme-alpine" style={{ height: heightForm ? 'calc(100vh - ' + heightForm + 'px)' : 'calc(100vh - 285px)', width: "100%" }}>
                    <AgGridReact
                        ref={gridRef}
                        defaultColDef={defaultColDef}
                        columnTypes={columnTypes}
                        columnDefs={columnDefs}
                        onGridReady={onGridReady}
                        domLayout={"normal"}
                        rowData={rowData}
                        animateRows={true}
                        rowDragManaged={true}
                        rowHeight={rowHeight}
                        rowSelection={'single'}
                        suppressMovableColumns={false}
                        suppressDragLeaveHidesColumns={false}
                        localeText={AG_GRID_LOCALE_JP}
                        paginationPageSize={pageSize}
                        onCellDoubleClicked={handleEdit}
                        loadingOverlayComponent={loadingOverlayComponent}
                        loadingOverlayComponentParams={loadingOverlayComponentParams}
                        noRowsOverlayComponent={noRowsOverlayComponent}
                        noRowsOverlayComponentParams={noRowsOverlayComponentParams}
                    />
                </div>
                {pageSize && pageData && <PaginationCpn pageData={pageData} onChangeSearchCondition={onChangeSearchCondition} limit={pageSize} />}
            </div>
        </>
    );
})
export default EquipBusductGrid;

