import { Box, Grid, IconButton, Stack, Typography } from '@mui/material'
import { NotificationModel } from '../../models/Index';
import { convertNotiDatetime } from '../../utils/DataConverter';
import DeleteIcon from '@mui/icons-material/Delete';

type NoticeListProps = {
  cardList: NotificationModel[];
  handleSelectCard: (id: number) => void;
  handleDeleteNotification: (notification: any) => void;
}

export const NoticeList = (props: NoticeListProps) => {
  const { cardList, handleSelectCard, handleDeleteNotification } = props

  return (<>
    <Stack height={'40vh'}>
      {cardList.map((card) =>
        <Box
          key={card.notificationId}
          mt={1}
          p={1}
          border={'1px solid #1976d2'}
          borderRadius={'4px'}
          style={{cursor: 'pointer'}}
          bgcolor={card.unread ? 'white' : '#E6E6E6'}
        >
          <Grid container>
            <Grid item xs={11} onClick={()=>{handleSelectCard(card.notificationId)}}>
              <Box>
                <Typography fontSize={12} fontWeight={card.unread ? 'bold' : 'normal'}>
                  {convertNotiDatetime(card.startDate)}
                </Typography>
              </Box>
              <Box mt={1}>
                <Typography fontWeight={card.unread ? 'bold' : 'normal'}>
                  {card.title}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={1}>
              <IconButton onClick={() => handleDeleteNotification(card)}>
                <DeleteIcon/>
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      )}
    </Stack>
  </>)
}

export default NoticeList