import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { DialogTitle, FormControl, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material'
import { useState } from 'react'

export type UploadFileDiaglogProps = {
  openDialog: boolean;
  setOpenDialog: (value: boolean) => void;
  listAllControls: any[];
  controlId: string;
  setControlId: (value: string) => void;
  onOK: (file: any) => void;
}

export const UploadFileDiaglog = (props: UploadFileDiaglogProps) => {
  const {
    openDialog,
    setOpenDialog,
    listAllControls,
    controlId,
    setControlId,
    onOK
  } = props
  
  const [file, setFile] = useState<File | null>(null);

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files && event.target.files[0];
    setFile(selectedFile || null);
  };

  const handleOK = () => {
    setOpenDialog(false)
    onOK(file)
    setFile(null)
  }

  const handleCancel = () => {
    setOpenDialog(false)
    setFile(null)
  }

  return (
    <div>
      <Dialog open={openDialog} maxWidth={"xs"}>
        <DialogTitle style={{fontWeight:"bold"}}>
          メンテナンスファイルをアップロードする
        </DialogTitle>
        <DialogContent>
          <Stack mt={1.5} width={'100%'}>
            <FormControl size='small'>
              <InputLabel>機器名</InputLabel>
              <Select
                label='機器名'
                onChange={(e) => {setControlId(e.target.value)}}
                value={controlId}
              >
                {listAllControls.map((item: any) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
          <Stack mt={1} display={"flex"} flexDirection={"row"} alignItems={"center"}>
            <Button
              style={{marginRight:8}}
              size="medium"
              variant="contained"
              startIcon={<AttachFileIcon />}
              component="label"
            >
              ファイル追加
              <input type="file" hidden onChange={handleFileChange}/>
            </Button>
            <Typography maxWidth={'236px'} noWrap>{file && file.name}</Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="inherit" variant="contained">
            キャンセル
          </Button>
          <Button onClick={handleOK} variant="contained" disabled={!file || !controlId}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default UploadFileDiaglog