import { store } from "..";
import { ControlModel, MsThermalCTModel, MsThermalModel, ProcessMode } from "../models/Index";
import { BeamsNumber } from "./FormatNumber";
import { MS_OUTSIDE_NEUTRAL, 
    MS_OUTSIDE_OUTSIDE, 
    MS_3PHASES, 
    MS_VOLTDROP_REGULAR, 
    MS_TRANS1, 
    MS_VOLTDROP_MOTOR, 
    TOP_CENTER, 
    BOTTOM_CENTER, 
    LEFT_CENTER, 
    RIGHT_CENTER,
    MS_SEAT_MAIN,
    MS_SEAT_T,
    MS_TRANSSCOTT,
    DIRECT_NONE,
    RS_DIAL_FIXED,
    RS_DIAL_CONTINUE
} from "../models/Constants"
import {
	doGetIkpeakDirectMG,
	doGetIkasymDirectMG,
	doGetIksymDirectMG,
    doGetIksymTotalMG,
    doGetTextDropVoltMotor as doGetTextDropVoltMotorCalPoint,
    doGetTextDropVoltMotor2 as DoGetTextDropVoltMotor2CalcPoint,
    doGetTextDropPerMotor as doGetTextDropPerMotorCalcPoint,
	doGetTextDropVoltRegular as doGetTextDropVoltRegularCalcPoint,
	doGetTextDropPerRegular as doGetTextDropPerRegularCalcPoint,
	doGetTextDropPerRegular2 as doGetTextDropPerRegular2CalcPoint,
    isSetDropPerMotor,
    doGetPerZDirectMG,
    doGetIkpeakTotalMG,
    doGetIkasymTotalMG,
    doGetPerZTotalMG
} from "./calcPointFunction";
import { isCennectLoadDropElement } from "./element"
import { doGetTextVoltDropRegular } from "./mssv3Doc"
import { MS_TRANS1 as MS_TRANS1_STRING } from "../models/ElementKind";
import { doGetTextAdjust, doGetTextCT1Current, doGetTextCT2Current, doGetTextCurrentName as doGetTextCurrentNameAdjust } from "./Adjust";
import { MS_MODE_CALCULATION, MS_MODE_CHECKING, MS_MODE_DRAWING, MS_MODE_HARMONIC, MS_MODE_PERCENT_Z, MS_MODE_POWER_FLOW, MS_MODE_VOLTDROP } from "../statics";

//CEleThermalCT::DoGetTextTypeName
export const doGetTextTypeName = (props:MsThermalCTModel) =>
{
    return props.details.base.typeName;
}

//TODO
//CEleThermalCT::DoGetTextRatedName
export const doGetTextRatedName = (props:MsThermalCTModel, bUnit:boolean = true) =>
{
	// CObjAdjust* pObjAdjust = m_listAdjust.DoGetPointerAdjust(0);
	// if (pObjAdjust == NULL) {
	// 	return _T("");
	// }

	// CMSSV3App* pMSSV3App = (CMSSV3App*)AfxGetApp();
	// CDaoDatabase* pdbMSSBreak = pMSSV3App->m_pdbMSSBreak;
	// pObjAdjust->m_ReadBase.DoReadReadBase(pdbMSSBreak, &pObjAdjust->m_InfoBase, TRUE);

	// int nIndex = 0;
	// if (pObjAdjust->m_ReadBase.m_DialRated.m_nFlag == RS_DIAL_FIXED) {
	// 	nIndex = pObjAdjust->m_ReadBase.m_DialRated.DoGetIndexByDialID(pObjAdjust->m_InfoBase.m_lBreakRatedID);
	// }
	// else {
	// 	nIndex = pObjAdjust->m_ReadBase.m_DialRated.DoGetIndexByValue(pObjAdjust->m_InfoBase.m_dRatedValue);
	// }
	// if (nIndex < 0) {
	// 	return _T("");
	// }
	// CString sText = pObjAdjust->m_ReadBase.m_DialRated.DoGetValueByIndex(nIndex)->m_sValueName;
	// if (bUnit == TRUE && pObjAdjust->m_ReadBase.m_DialRated.m_sUnit.IsEmpty() == 0) {
	// 	sText += pObjAdjust->m_ReadBase.m_DialRated.m_sUnit;
	// }
    if (!props.details.base.fixedName) {
		return '';
	}

	let sText = props.details.base.ratedValue + '';

    sText += props.details.base.readBase.ratedUnit === undefined ? 'A' : props.details.base.readBase.ratedUnit;
    
	return sText;
}

//TODO
//CEleThermalCT::DoGetTextCurrentName
export const doGetTextCurrentName = (props:MsThermalCTModel, nIndex:number) =>
{
	// CObjAdjust* pObjAdjust = m_listAdjust.DoGetPointerAdjust(0);
	// if (pObjAdjust == NULL) {
	// 	return _T("-");
	// }
 
	// CString sText = pObjAdjust->DoGetTextCurrentName(nIndex);
    let sText = doGetTextCurrentNameAdjust(props.details, nIndex); 
	return sText;
}

//CEleThermalCT::DoGetTextOnOff
export const doGetTextOnOff = (props:MsThermalCTModel, bUseOn:boolean = true) =>
{
	let sText;
	if (props.isOff) {
		sText = '開放';
	}
	else {
		if (bUseOn) {
			sText = '投入';
		}
		else {
			sText = '';
		}
	}
	return sText;
}

//CEleThermalCT::IsPointFaultThermalCT
export const isPointFaultThermalCT = (props:MsThermalCTModel) =>
{
	if (props.fault) {
		return true;
	}
	return false;
}

//CEleThermalCT::DoGetTextDirectIkpeak
export const doGetTextDirectIkpeak = (props:MsThermalCTModel, nPartKind:number, bUnit:boolean = true) =>
{
	let dIpeakDirect = 0;
	if (props.sPhase) {		
		switch (nPartKind) {
		case MS_OUTSIDE_NEUTRAL:	
		case MS_SEAT_MAIN:			
			dIpeakDirect = doGetIkpeakDirectMG(props.calcPoint1);
			break;
		case MS_OUTSIDE_OUTSIDE:	
		case MS_SEAT_T:				
			dIpeakDirect = doGetIkpeakDirectMG(props.calcPoint0);
			break;
		}
	}
	else {							
		if (props.downTransKind == MS_TRANS1_STRING) {
			dIpeakDirect = doGetIkpeakDirectMG(props.calcPoint1);
		}
		else {
			dIpeakDirect = doGetIkpeakDirectMG(props.calcPoint0);
		}
	}

	let sText;
	if (dIpeakDirect == 0) {
		if (props.primarySupply) {
			sText = '∞';
		}
		else {
			sText = '0';
		}
	}
	else if (dIpeakDirect > 0) {
		sText = BeamsNumber(dIpeakDirect / 1000, 4).toString();
        if(bUnit)
        {
            sText += 'kApeak';
        }
	}
	else {							
		sText = '????';
	}
	return sText;
}

//CEleThermalCT::DoGetTextDirectIkasym
export const doGetTextDirectIkasym = (props:MsThermalCTModel, nPartKind:number, bUnit:boolean = true) =>
{
	let dIasymDirect = 0;
	if (props.sPhase) {	
		switch (nPartKind) {
		case MS_OUTSIDE_NEUTRAL:	
		case MS_SEAT_MAIN:			
			dIasymDirect = doGetIkasymDirectMG(props.calcPoint1);
			break;
		case MS_OUTSIDE_OUTSIDE:	
		case MS_SEAT_T:				
			dIasymDirect = doGetIkasymDirectMG(props.calcPoint0);
			break;
		}
	}
	else {							
		if (props.downTransKind == MS_TRANS1_STRING) {
			dIasymDirect = doGetIkasymDirectMG(props.calcPoint1);
		}
		else {
			dIasymDirect = doGetIkasymDirectMG(props.calcPoint0);
		}
	}

	let sText;
	if (dIasymDirect == 0) {
		if (props.primarySupply) {
			sText = '∞';
       }
       else {
        sText = '0';
       }
	}
    else if(dIasymDirect > 0) {
        sText = BeamsNumber(dIasymDirect / 1000, 4).toString();
        if(bUnit) {
            sText += 'kAasym';
        }
    }
	else {							
		sText = '????';
	}
	return sText;
}

//CEleThermalCT::DoGetTextDirectIksym
export const doGetTextDirectIksym = (props:MsThermalCTModel, nPartKind:number, bUnit:boolean = true) =>
{
	let dIsymDirect = 0;
	if (props.sPhase) {		
		switch (nPartKind) {
		case MS_OUTSIDE_NEUTRAL:	
		case MS_SEAT_MAIN:			
			dIsymDirect = doGetIksymDirectMG(props.calcPoint1);
			break;
		case MS_OUTSIDE_OUTSIDE:	
		case MS_SEAT_T:				
			dIsymDirect = doGetIksymDirectMG(props.calcPoint0);
			break;
		}
	}
	else {						
		if (props.downTransKind == MS_TRANS1_STRING) {
			dIsymDirect = doGetIksymDirectMG(props.calcPoint1);
		}
		else {
			dIsymDirect = doGetIksymDirectMG(props.calcPoint0);
		}
	}

	let sText;
	if (dIsymDirect == 0) {
		if(props.primarySupply) {
            sText = '∞';
        }
        else {
         sText = '0';
        }
    }
    else if(dIsymDirect > 0) {
        sText = BeamsNumber(dIsymDirect / 1000, 4).toString();
        if(bUnit) {
            sText += 'kAsym';
        }
    }
	else {							
        sText = '????';
	}
	return sText;
}

//CEleThermalCT::DoGetTextTotalIk3sym
export const doGetTextTotalIk3sym = (props:MsThermalCTModel, bUnit:boolean = true) =>
{
	let dIsymTotal = 0;
	if (props.downTransKind == MS_TRANS1_STRING) {
		dIsymTotal = doGetIksymTotalMG(props.calcPoint1);
	}
	else {
		dIsymTotal = doGetIksymTotalMG(props.calcPoint0);
	}
	let sText;
	if (dIsymTotal == 0) {
		if(props.primarySupply) {
            sText = '∞';
        }
        else {
         sText = '0';
        }
    }
    else if(dIsymTotal > 0) {
        sText = BeamsNumber(dIsymTotal / 1000, 4).toString();
        if(bUnit) {
            sText += 'kAsym';
        }
    }
	else {							
        sText = '????';
	}
	return sText;
}

//CEleThermalCT::DoGetTextDropVoltMotor
export const doGetTextDropVoltMotor = (props:MsThermalCTModel, bUnit:boolean = true) =>
{
	return doGetTextDropVoltMotorCalPoint(props.calcPoint0, bUnit);
}

//CEleThermalCT::DoGetTextDropVoltMotor2
export const doGetTextDropVoltMotor2 = (props:MsThermalCTModel, bUnit:boolean = true) =>
{
	return DoGetTextDropVoltMotor2CalcPoint(props.calcPoint0, bUnit);
}

//CEleThermalCT::DoGetTextDropPerMotor
export const doGetTextDropPerMotor = (props:MsThermalCTModel, bUnit:boolean = true) =>
{
	return doGetTextDropPerMotorCalcPoint(props.calcPoint0, bUnit);
}

//CEleThermalCT::DoGetTextDropVoltRegular
export const doGetTextDropVoltRegular = (props:MsThermalCTModel, dStdVoltage:number, nPartKind:number, bUnit:boolean = true) =>
{
	let sText = '';
	if (props.sPhase) {		
		switch (nPartKind) {
		case MS_OUTSIDE_NEUTRAL:	
		case MS_SEAT_MAIN:		
			sText = doGetTextDropVoltRegularCalcPoint(props.calcPoint1, dStdVoltage, 0, 0, bUnit);
			break;
		case MS_OUTSIDE_OUTSIDE:
		case MS_SEAT_T:				
			sText = doGetTextDropVoltRegularCalcPoint(props.calcPoint0, dStdVoltage, 0, 0, bUnit);
			break;
		}
	}
	else {						
		sText = doGetTextDropVoltRegularCalcPoint(props.calcPoint0, dStdVoltage, 0, 0, bUnit);
	}
	return sText;
}

//CEleThermalCT::DoGetTextDropPerRegular
export const doGetTextDropPerRegular = (props:MsThermalCTModel, dStdVoltage:number, nPartKind:number, bUnit:boolean = true) =>
{
	let sText = '';
	if (props.sPhase) {		
		switch (nPartKind) {
		case MS_OUTSIDE_NEUTRAL:	
		case MS_SEAT_MAIN:			
			sText = doGetTextDropPerRegularCalcPoint(props.calcPoint1, dStdVoltage, 0, 0, bUnit);
			break;
		case MS_OUTSIDE_OUTSIDE:	
		case MS_SEAT_T:				
			sText = doGetTextDropPerRegularCalcPoint(props.calcPoint0, dStdVoltage, 0, 0, bUnit);
			break;
		}
	}
	else {							
		sText = doGetTextDropPerRegularCalcPoint(props.calcPoint0, dStdVoltage, 0, 0, bUnit);
	}

	return sText;
}

//CEleThermalCT::DoGetTextVoltage
export const doGetTextVoltage = (props:MsThermalCTModel, nPartKind:number, bUnit:boolean = true) =>
{
	let sText = '';
	if (props.sPhase) {
		switch (nPartKind) {
		case MS_OUTSIDE_NEUTRAL:	
			sText = (props.voltage / 2).toString();
			break;
		case MS_OUTSIDE_OUTSIDE:	
			sText = props.voltage.toString();
			break;
		case MS_SEAT_MAIN:			
			sText = props.voltage.toString();
			break;
		case MS_SEAT_T:				
			sText = props.voltage.toString();
			break;
		}
	}
	else {
		sText = props.voltage.toString();
	}
	if (bUnit) {
		sText += 'V';
	}
	return sText;
}

//CEleThermalCT::DoGetTextPointState
export const doGetTextPointState = (props:MsThermalCTModel) =>
{
    let sText = ''; 

	if (props.fault) {
		sText = 'Yes';
	}
	else {
		sText = 'No';
	}

	return sText;
}

//CEleThermalCT::DoGetRefV1
export const doGetRefV1 = (mode:any, control:ControlModel, bSociety:boolean) =>
{
    let props = control.properties as MsThermalCTModel;
    let project = store.getState().app.projectData;

	//CEleThermalCT::DoGetRefV1Left  sRefV1Left
	let labelTexts = [{
		value: control.properties.refNo,
		color: "#f800f8",
	}];

	if (!props.viewResultText || (mode != ProcessMode.DRAWING && !props.supply))
    {
      return labelTexts;
    }

    if(bSociety) {
        //TypeName
        labelTexts.push({
            value: doGetTextCT1Current(props.details)
            + '/'
            + doGetTextCT2Current(props.details),
            color: "blue",
        });

        //TypeName
        labelTexts.push({
            value: doGetTextTypeName(props),
            color: "blue",
        });

        //RatedName
        labelTexts.push({
            value: doGetTextRatedName(props),
            color: "blue",
        });

        let sName = doGetTextCurrentName(props, 0);
        if(sName != '-')
        {
            //sName
            labelTexts.push({
                value: sName,
                color: "blue",
            });
        }
        return labelTexts;
    }

	switch(mode) {
        case ProcessMode.DRAWING:
        case ProcessMode.CHECKING:
            if(props.isOff) {
                //OnOff
               labelTexts.push({
                   value: doGetTextOnOff(props, false),
                   color: "blue",
               });
            }

             //TypeName
            labelTexts.push({
                value: doGetTextCT1Current(props.details)
                + '/'
                + doGetTextCT2Current(props.details),
                color: "blue",
            });

            //TypeName
            labelTexts.push({
                value: doGetTextTypeName(props),
                color: "blue",
            });

            //RatedName
            labelTexts.push({
                value: doGetTextRatedName(props),
                color: "blue",
            });

            let sName = doGetTextCurrentName(props, 0);
            if(sName != '-')
            {
                //sName
                labelTexts.push({
                    value: sName,
                    color: "blue",
                });
            }

            break;
        case ProcessMode.PERCENT_Z:
            break;
        case ProcessMode.CALCULATION:
            if(props.isOff) {
                //OnOff
                labelTexts.push({
                    value: doGetTextOnOff(props, false),
                    color: "blue",
                });
                break;
            }

            if(!isPointFaultThermalCT(props)) {
                break;
            }

            if(props.sPhase) {
                switch(props.upTransKind) {
                    case MS_TRANS1:
                        //OUTSIDE_OUTSIDE
                        labelTexts.push({
                            value: '外線-外線',
                            color: "blue",
                        });

                        // += 'I"k2' + '通過' + '=' + DoGetTextDirectIkpeak()
                        labelTexts.push({
                            value: 'I"k2通過='
                            + doGetTextDirectIkpeak(props, MS_OUTSIDE_OUTSIDE),
                            color: "blue",
                        });

                        // += 'I"k2' + '通過' + '=' + DoGetTextDirectIkasym()
                        labelTexts.push({
                            value: 'I"k2通過='
                            + doGetTextDirectIkasym(props, MS_OUTSIDE_OUTSIDE),
                            color: "blue",
                        });

                         // += 'I"k2' + '通過' + '=' + DoGetTextDirectIksym()
                        labelTexts.push({
                            value: 'I"k2通過='
                            + doGetTextDirectIksym(props, MS_OUTSIDE_OUTSIDE),
                            color: "blue",
                        });

                        //OUTSIDE_NEUTRAL
                        labelTexts.push({
                            value: '外線-中性線',
                            color: "blue",
                        });

                        // += 'I"k2' + '通過' + '=' + DoGetTextDirectIkpeak()
                        labelTexts.push({
                            value: 'I"k2通過='
                            + doGetTextDirectIkpeak(props, MS_OUTSIDE_NEUTRAL),
                            color: "blue",
                        });

                        // += 'I"k2' + '通過' + '=' + DoGetTextDirectIkasym()
                        labelTexts.push({
                            value: 'I"k2通過='
                            + doGetTextDirectIkasym(props, MS_OUTSIDE_NEUTRAL),
                            color: "blue",
                        });

                         // += 'I"k2' + '通過' + '=' + DoGetTextDirectIksym()
                        labelTexts.push({
                            value: 'I"k2通過='
                            + doGetTextDirectIksym(props, MS_OUTSIDE_NEUTRAL),
                            color: "blue",
                        });

                        break;

                    case MS_TRANSSCOTT:
                        //SEAT_MAIN
                        labelTexts.push({
                            value: '主座',
                            color: "blue",
                        });

                        // += 'I"k2' + '通過' + '=' + DoGetTextDirectIkpeak()
                        labelTexts.push({
                            value: 'I"k2通過='
                            + doGetTextDirectIkpeak(props, MS_SEAT_MAIN),
                            color: "blue",
                        });

                        // += 'I"k2' + '通過' + '=' + DoGetTextDirectIkasym()
                        labelTexts.push({
                            value: 'I"k2通過='
                            + doGetTextDirectIkasym(props, MS_SEAT_MAIN),
                            color: "blue",
                        });

                         // += 'I"k2' + '通過' + '=' + DoGetTextDirectIksym()
                        labelTexts.push({
                            value: 'I"k2通過='
                            + doGetTextDirectIksym(props, MS_SEAT_MAIN),
                            color: "blue",
                        });

                        //SEAT_T
                        labelTexts.push({
                            value: 'T座',
                            color: "blue",
                        });

                        // += 'I"k2' + '通過' + '=' + DoGetTextDirectIkpeak()
                        labelTexts.push({
                            value: 'I"k2通過='
                            + doGetTextDirectIkpeak(props, MS_SEAT_T),
                            color: "blue",
                        });

                        // += 'I"k2' + '通過' + '=' + DoGetTextDirectIkasym()
                        labelTexts.push({
                            value: 'I"k2通過='
                            + doGetTextDirectIkasym(props, MS_SEAT_T),
                            color: "blue",
                        });

                        // += 'I"k2' + '通過' + '=' + DoGetTextDirectIksym()
                        labelTexts.push({
                            value: 'I"k2通過='
                            + doGetTextDirectIksym(props, MS_SEAT_T),
                            color: "blue",
                        });

                        break;
                }
            }
            else {
                if(props.downTransKind == MS_TRANS1_STRING) {
                     // += 'I"k2' + '通過' + '=' + DoGetTextDirectIkpeak()
                     labelTexts.push({
                        value: 'I"k2通過='
                        + doGetTextDirectIkpeak(props, MS_3PHASES),
                        color: "blue",
                    });

                    // += 'I"k2' + '通過' + '=' + DoGetTextDirectIkasym()
                    labelTexts.push({
                        value: 'I"k2通過='
                        + doGetTextDirectIkasym(props, MS_3PHASES),
                        color: "blue",
                    });

                    // += 'I"k2' + '通過' + '=' + DoGetTextDirectIksym()
                    labelTexts.push({
                        value: 'I"k2通過='
                        + doGetTextDirectIksym(props, MS_3PHASES),
                        color: "blue",
                    });

                    // += 'I"k2' + '合計' + '=' + DoGetTextDirectIksym()
                    labelTexts.push({
                        value: 'I"k2合計='
                        + doGetTextTotalIk3sym(props),
                        color: "blue",
                    });
                }
                else {
                     // += 'I"k3' + '通過' + '=' + DoGetTextDirectIkpeak()
                     labelTexts.push({
                        value: 'I"k3通過='
                        + doGetTextDirectIkpeak(props, MS_3PHASES),
                        color: "blue",
                    });

                    // += 'I"k3' + '通過' + '=' + DoGetTextDirectIkasym()
                    labelTexts.push({
                        value: 'I"k3通過='
                        + doGetTextDirectIkasym(props, MS_3PHASES),
                        color: "blue",
                    });

                    // += 'I"k3' + '通過' + '=' + DoGetTextDirectIksym()
                    labelTexts.push({
                        value: 'I"k3通過='
                        + doGetTextDirectIksym(props, MS_3PHASES),
                        color: "blue",
                    });

                    // += 'I"k3' + '合計' + '=' + DoGetTextDirectIksym()
                    labelTexts.push({
                        value: 'I"k3合計='
                        + doGetTextTotalIk3sym(props),
                        color: "blue",
                    });
                }
            }
            break;
        case ProcessMode.VOLTDROP:
            if(props.isOff) {
                //OnOff
                labelTexts.push({
                    value: doGetTextOnOff(props, false),
                    color: "blue",
                });
                break;
            }

            if (project.dropMode == MS_VOLTDROP_MOTOR && props.dropMotor && !props.sPhase && isSetDropPerMotor(props.calcPoint0)) {
                // Vd = DropVoltMotor(VoltSecondary - DropVoltMotor2)
                let sTemp = 'Vd=' + doGetTextDropVoltMotor(props) + ' (';
                if (props.supply) {
                    sTemp += doGetTextVoltage(props, MS_3PHASES, false);
                    sTemp += '-';
                    sTemp += doGetTextDropVoltMotor2(props, false);
                } else {
                    sTemp += '0-0'
                }
                sTemp += ')';
                labelTexts.push({
                    value: sTemp,
                    color: "blue",
                });

                //Vp = DoGetTextDropPerMotor (VoltSecondary → DropVoltMotor2)
                sTemp = 'Vp=' + doGetTextDropPerMotor(props) + ' (';
                if (props.supply) {
                    sTemp += doGetTextVoltage(props, MS_3PHASES, false);
                    sTemp += '→';
                    sTemp += doGetTextDropVoltMotor2(props, false);
                } else {
                    sTemp += '0→0';
                }
                sTemp += ')';
                labelTexts.push({
                    value: sTemp,
                    color: "blue",
                });
            }

            if (project.dropMode == MS_VOLTDROP_REGULAR && props.dropRegular) {
                let stdVoltage = {
                    dStdVoltage : 0,
                    sStdVoltage : '0'
                };
                if(props.sPhase) {
                    switch(props.upTransKind) {
                        case MS_TRANS1:
                            if(props.supply) {
                               stdVoltage = doGetTextVoltDropRegular(control, MS_OUTSIDE_OUTSIDE, false);
                            }

                            //OUTSIDE_OUTSIDE
                            labelTexts.push({
                                value: '外線-外線',
                                color: "blue",
                            });
                            
                             //Vp=DoGetTextDropPerRegular (DoGetTextVoltage→DoGetTextDropVoltRegular)
                            let sTemp = 'Vp=';
                            sTemp += doGetTextDropPerRegular(props, stdVoltage.dStdVoltage, MS_OUTSIDE_OUTSIDE);
                            sTemp += ' (';
                            if(props.supply) {
                                sTemp += doGetTextVoltage(props, MS_OUTSIDE_OUTSIDE, false);
                            }
                            else {
                                sTemp += stdVoltage.sStdVoltage;
                            }
                            sTemp += '→';
                            sTemp += doGetTextDropVoltRegular(props, stdVoltage.dStdVoltage, MS_OUTSIDE_OUTSIDE, false);
                            sTemp += ')';
                            labelTexts.push({
                                value: sTemp,
                                color: "blue",
                            });

                            if(props.supply) {
                                stdVoltage = doGetTextVoltDropRegular(control, MS_OUTSIDE_NEUTRAL, false);
                             }
 
                            //OUTSIDE_NEUTRAL
                            labelTexts.push({
                                value: '外線-中性線',
                                color: "blue",
                            });
                             
                            //Vp=DoGetTextDropPerRegular (DoGetTextVoltage→DoGetTextDropVoltRegular)
                            sTemp = 'Vp=';
                            sTemp += doGetTextDropPerRegular(props, stdVoltage.dStdVoltage, MS_OUTSIDE_NEUTRAL);
                            sTemp += ' (';
                            if(props.supply) {
                                sTemp += doGetTextVoltage(props, MS_OUTSIDE_NEUTRAL, false);
                            }
                            else {
                                sTemp += stdVoltage.sStdVoltage;
                            }
                            sTemp += '→';
                            sTemp += doGetTextDropVoltRegular(props, stdVoltage.dStdVoltage, MS_OUTSIDE_NEUTRAL, false);
                            sTemp += ')';
                            labelTexts.push({
                                value: sTemp,
                                color: "blue",
                            });
                            
                            break;
                        case MS_TRANSSCOTT:
                            if(props.supply) {
                                stdVoltage = doGetTextVoltDropRegular(control, MS_SEAT_MAIN, false);
                            }
                            
                             //SEAT_MAIN
                             labelTexts.push({
                                value: '主座',
                                color: "blue",
                            });
                             
                            //Vp=DoGetTextDropPerRegular (DoGetTextVoltage→DoGetTextDropVoltRegular)
                            sTemp = 'Vp=';
                            sTemp += doGetTextDropPerRegular(props, stdVoltage.dStdVoltage, MS_SEAT_MAIN);
                            sTemp += ' (';
                            if(props.supply) {
                                sTemp += doGetTextVoltage(props, MS_SEAT_MAIN, false);
                            }
                            else {
                                sTemp += stdVoltage.sStdVoltage;
                            }
                            sTemp += '→';
                            sTemp += doGetTextDropVoltRegular(props, stdVoltage.dStdVoltage, MS_SEAT_MAIN, false);
                            sTemp += ')';
                            labelTexts.push({
                                value: sTemp,
                                color: "blue",
                            });

                            if(props.supply) {
                                stdVoltage = doGetTextVoltDropRegular(control, MS_SEAT_T, false);
                            }
                            
                            //SEAT_T
                            labelTexts.push({
                                value: 'T座',
                                color: "blue",
                            });
                             
                            //Vp=DoGetTextDropPerRegular (DoGetTextVoltage→DoGetTextDropVoltRegular)
                            sTemp = 'Vp=';
                            sTemp += doGetTextDropPerRegular(props, stdVoltage.dStdVoltage, MS_SEAT_T);
                            sTemp += ' (';
                            if(props.supply) {
                                sTemp += doGetTextVoltage(props, MS_SEAT_T, false);
                            }
                            else {
                                sTemp += stdVoltage.sStdVoltage;
                            }
                            sTemp += '→';
                            sTemp += doGetTextDropVoltRegular(props, stdVoltage.dStdVoltage, MS_SEAT_T, false);
                            sTemp += ')';
                            labelTexts.push({
                                value: sTemp,
                                color: "blue",
                            });
                            
                            break; 
                    }
                }
                else {
                    if(props.supply) {
                        stdVoltage = doGetTextVoltDropRegular(control, MS_3PHASES, false);
                    }

                    //Vp=DoGetTextDropPerRegular (DoGetTextVoltage→DoGetTextDropVoltRegular)
                    let sTemp = 'Vp=';
                    sTemp += doGetTextDropPerRegular(props, stdVoltage.dStdVoltage, MS_3PHASES);
                    sTemp += ' (';
                    if(props.supply) {
                        sTemp += doGetTextVoltage(props, MS_3PHASES, false);
                    }
                    else {
                        sTemp += stdVoltage.sStdVoltage;
                    }
                    sTemp += '→';
                    sTemp += doGetTextDropVoltRegular(props, stdVoltage.dStdVoltage, MS_3PHASES, false);
                    sTemp += ')';
                    labelTexts.push({
                        value: sTemp,
                        color: "blue",
                    });
                }
            }

            break;
        case ProcessMode.IMPROVE_PF:
        case ProcessMode.HARMONIC:
            if(props.isOff) {
                //OnOff
                labelTexts.push({
                    value: doGetTextOnOff(props, false),
                    color: "blue",
                });
            }
            break;
        case ProcessMode.POWER_FLOW:
            break;
    }

    return labelTexts;
}

export const doGetRefV2 = (properties: MsThermalCTModel) => {
    let infos = []
    infos.push(properties.details.refNo2)
    infos.push(properties.pointText)
    
    const ctPrimary = properties.details.base.ctPrimaryList.find(item => item.value === properties.details.base.ctPrimary)?.label || ''
    const ctSecondary = properties.details.base.ctSecondaryList.find(item => item.value === properties.details.base.ctSecondary)?.label || ''
    const ctStr = `${ctPrimary}(A)/${ctSecondary}(A)`        
    infos.push(ctStr)
    
    const typeName = properties.details.base.typeList.find(item => item.value === properties.details.base.typeID)?.label || ''
    infos.push(typeName)
    
    const ratedValue = properties.details.base.ratedValue
    if (!ratedValue) return infos
    
    infos.push(ratedValue.toString())
    if (properties.details.base.readBase.ratedUnit) {
        infos[infos.length - 1] +=  `(${properties.details.base.readBase.ratedUnit})`
    }
    
    if (properties.details.base.readBase.magniFlag === RS_DIAL_FIXED || properties.details.base.readBase.magniFlag === RS_DIAL_CONTINUE) {
        infos[infos.length - 1] +=  properties.details.base.magniValue
        if (properties.details.base.readBase.magniUnit) {
            infos[infos.length - 1] += `(${properties.details.base.readBase.magniUnit})`
        }
    }
    
    infos = infos.concat(doGetTextAdjust(properties.details))

    return infos
}

//CEleThermalCT::DoGetTextDirectPercentZr
export const doGetTextDirectPercentZr = (props:ControlModel, nPartKind:number, bUnit:boolean = true) =>
{
	let dPerZ = 0;
	if (props.properties.sPhase) {		// 単相回路のとき
		switch (nPartKind) {
		case MS_OUTSIDE_NEUTRAL:	// 外線－中性線間
		case MS_SEAT_MAIN:			// 主座変圧器
			dPerZ = doGetPerZDirectMG(props.properties.calcPoint1).dPercentZ;
			break;
		case MS_OUTSIDE_OUTSIDE:	// 外線間
		case MS_SEAT_T:				// Ｔ座変圧器
			dPerZ = doGetPerZDirectMG(props.properties.calcPoint0).dPercentZ;
			break;
		}
	}
	else {							// 三相回路のとき
		if (props.properties.downTransKind == MS_TRANS1) {
			dPerZ = doGetPerZDirectMG(props.properties.calcPoint1).dPercentZ;
		}
		else {
			dPerZ = doGetPerZDirectMG(props.properties.calcPoint0).dPercentZ;
		}
	}

	let sText = '';
	if (dPerZ >= 0) {
		sText = BeamsNumber(dPerZ, 4).toString();
		if (bUnit) {
			sText += '%r';
		}
	}
	else {							// 短絡電流が正しく計算されなかったとき
		sText = '????';
	}
	return sText;
}

//CEleThermalCT::DoGetTextTotalIk3peak
export const doGetTextTotalIk3peak = (props:MsThermalCTModel, bUnit:boolean = true) =>
{
	let dIpeakTotal = 0;
	if (props.downTransKind == MS_TRANS1_STRING) {
		dIpeakTotal = doGetIkpeakTotalMG(props.calcPoint1);
	}
	else {
		dIpeakTotal = doGetIkpeakTotalMG(props.calcPoint0);
	}
	let sText = '';
	if (dIpeakTotal == 0) {
		if (props.primarySupply) {
			sText = '∞';
		}
		else {
			sText = "0";
		}
	}
	else if (dIpeakTotal > 0) {
		sText = BeamsNumber(dIpeakTotal / 1000, 4).toString();
		if (bUnit) {
			sText += 'kApeak';
		}
	}
	else {							// 短絡電流が正しく計算されなかったとき
		sText = '????';
	}
	return sText;
}

//CEleThermalCT::DoGetTextTotalIk3asym
export const doGetTextTotalIk3asym = (props:MsThermalCTModel, bUnit:boolean = true) =>
{
	let dIasymTotal = 0;
	if (props.downTransKind == MS_TRANS1_STRING) {
		dIasymTotal = doGetIkasymTotalMG(props.calcPoint1);
	}
	else {
		dIasymTotal = doGetIkasymTotalMG(props.calcPoint0);
	}
	let sText = '';
	if (dIasymTotal == 0) {
		if (props.primarySupply) {
			sText = '∞';
		}
		else {
			sText = "0";
		}
	}
	else if (dIasymTotal > 0) {
		sText = BeamsNumber(dIasymTotal / 1000, 4).toString();
		if (bUnit) {
			sText += 'kAasym';
		}
	}
	else {							// 短絡電流が正しく計算されなかったとき
		sText = '????';
	}
	return sText;
}

//CEleThermalCT::DoGetTextTotalPercentZr
export const doGetTextTotalPercentZr = (props:MsThermalCTModel, bUnit:boolean = true) =>
{
	let dPerZ = 0;
	if (props.downTransKind == MS_TRANS1_STRING) {
		dPerZ = doGetPerZTotalMG(props.calcPoint1).dPercentZ;
	}
	else {
		dPerZ = doGetPerZTotalMG(props.calcPoint0).dPercentZ;
	}
	let sText = '';
	if (dPerZ >= 0) {
		sText = BeamsNumber(dPerZ, 4).toString();
		if (bUnit) {
			sText += '%r';
		}
	}
	else {							// 短絡電流が正しく計算されなかったとき
		sText = '????';
	}
	return sText;
}

//CEleThermalCT::DoGetTextActiveLeft
export const doGetTextActiveLeft = (props:MsThermalCTModel, nDirect:number, bUnit:boolean = true) =>
{
	let sText = '';
	if (nDirect == DIRECT_NONE) {
		if (props.lineActiveFlow1 >= 0) {
			sText = BeamsNumber(props.lineActiveFlow1, 5).toString();
		}
		else {
			sText = BeamsNumber(props.lineActiveFlow2, 5).toString();
		}
	}
	else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
		sText = BeamsNumber(Math.abs(props.lineActiveFlow1), 5).toString();
	}
	else {
		sText = BeamsNumber(Math.abs(props.lineActiveFlow2), 5).toString();
	}

	if (bUnit) {
		sText += 'kW';
	}

	return sText;
}

//CEleThermalCT::DoGetTextReactiveLeft
export const doGetTextReactiveLeft = (props:MsThermalCTModel, nDirect:number, bUnit:boolean = true) =>
{
	let sText = '';
	if (nDirect == DIRECT_NONE) {
		if (props.lineReactiveFlow1 >= 0) {
			sText = BeamsNumber(props.lineReactiveFlow1, 5).toString();
		}
		else {
			sText = BeamsNumber(props.lineReactiveFlow2, 5).toString();
		}
	}
	else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
		sText = BeamsNumber(Math.abs(props.lineReactiveFlow1), 5).toString();
	}
	else {
		sText = BeamsNumber(Math.abs(props.lineReactiveFlow2), 5).toString();
	}

	if (bUnit) {
		sText += 'kvar';
	}

	return sText;
}

//CEleThermalCT::DoGetTextFlowsResult
export const doGetTextFlowsResult = (props:MsThermalCTModel, processMode:any, nDirect:number, bSociety:boolean = false) =>
{
	let sRefV1:string[] = [];

	if (bSociety) {
		return sRefV1;
	}

	switch (processMode) {
	case MS_MODE_DRAWING:
	case MS_MODE_CHECKING:
	case MS_MODE_PERCENT_Z:
	case MS_MODE_CALCULATION:
	case MS_MODE_VOLTDROP:
	case MS_MODE_HARMONIC:
		break;
	case MS_MODE_POWER_FLOW:
		if (!props.sPhase && props.lineActiveFlow1 !==0 && props.lineActiveFlow2 !==0 && props.lineReactiveFlow1 !==0 && props.lineReactiveFlow2 !==0) {
			if (nDirect == DIRECT_NONE) {
				sRefV1.push(doGetTextActiveLeft(props,nDirect));
				sRefV1.push(doGetTextReactiveLeft(props,nDirect));
			}
			else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
                sRefV1.push(doGetTextActiveLeft(props,nDirect));
                let sTemp = ''
				if (props.lineActiveFlow1 > 0) {
					if (props.lineReactiveFlow1 > 0) {
						sTemp += "+j";
					}
					else {
						sTemp += "-j";
					}
				}
				else {
					if (props.lineReactiveFlow1 > 0) {
						sTemp += "-j";
					}
					else {
						sTemp += "+j";
					}
				}
				sRefV1.push(sTemp + doGetTextReactiveLeft(props,nDirect));
			}
			else {
                sRefV1.push(doGetTextActiveLeft(props,nDirect));
                let sTemp = ''
				if (props.lineActiveFlow2 > 0) {
					if (props.lineReactiveFlow2 > 0) {
						sTemp += "+j";
					}
					else {
						sTemp += "-j";
					}
				}
				else {
					if (props.lineReactiveFlow2 > 0) {
						sTemp += "-j";
					}
					else {
						sTemp += "+j";
					}
				}
				sRefV1.push(sTemp + doGetTextReactiveLeft(props,nDirect));
			}
		}
		break;
	}

	return sRefV1;
}
