import { DIRECT_NONE, LEFT_CENTER, MS_KW_TO_HORSEPOWER, MS_OUTSIDE_NEUTRAL, MS_OUTSIDE_OUTSIDE, MS_SEAT_MAIN, MS_SEAT_T, TOP_CENTER } from "../models/Constants"
import { BeamsNumber } from "./FormatNumber"
import { ControlModel, MsLightBoardModel, ProcessMode } from "../models/Index";
import { MS_MODE_CALCULATION, MS_MODE_CHECKING, MS_MODE_DRAWING, MS_MODE_HARMONIC, MS_MODE_PERCENT_Z, MS_MODE_POWER_FLOW, MS_MODE_VOLTDROP } from "../statics";

const doGetValueTotalCapacity = (properties:any,totalCapacityPF:number,inputDetailTemp:boolean|undefined = undefined) =>{
    let totalCapacity = 0
    let dP = 0
    let dQ = 0
    let inputDetail = inputDetailTemp
    let totalCapacityUnit = properties.total_capacity_unit
    
    //light
    let light = +properties.light
    let lightPF = +properties.light_pf
    let lightUnit = properties.light_unit
    //soket
    let soket = +properties.soket
    let soketPF = +properties.soket_pf
    let soketUnit = properties.soket_unit
    //FCU
    let FCU = +properties.fcu
    let FCUPF = +properties.fcu_pf
    let FCUUnit = properties.fcu_unit
    //other
    let other = +properties.other
    let otherPF = +properties.other_pf
    let otherUnit = properties.other_unit

    //check input data
    
    //calc
    //light
    if(lightPF !== 0){
        if(lightUnit === "kW"){
            dP = light
            dQ = light * Math.sqrt(1 - lightPF * lightPF) / lightPF

        }
        else if(lightUnit === "kVA"){
            dP = light * lightPF
            dQ = light * Math.sqrt(1 - lightPF * lightPF)
        }
        else if(lightUnit === "HP"){
            dP = light / MS_KW_TO_HORSEPOWER
            dQ = (light / MS_KW_TO_HORSEPOWER) * Math.sqrt(1 - lightPF * lightPF) / lightPF
        }
    }
    else{
        if(lightUnit === "kW"){
            dP = 0
            dQ = light
        }else if( lightUnit === "kVA"){
            dP = 0
            dQ = light
        }
        else if(lightUnit === "HP"){
            dP = 0
            dQ = light / MS_KW_TO_HORSEPOWER
        }
    }
    //soket
    if(soketPF !==0 ){
        if(soketUnit === "kW"){
            dP += soket
            dQ += soket * Math.sqrt(1 - soketPF * soketPF) / soketPF
        }else if(soketUnit === "kVA"){
            dP += soket * soketPF
            dQ += soket * Math.sqrt(1 - soketPF * soketPF)
        }else if(soketUnit === "HP"){
            dP += soket / MS_KW_TO_HORSEPOWER
            dQ +=(soket / MS_KW_TO_HORSEPOWER) * Math.sqrt(1 - soketPF * soketPF) / soketPF
        }
    }
    else{
        if(soketUnit === "kW"){
            dQ += soket
        }else if(soketUnit === "kVA"){
            dQ += soket
        }
        else if(soketUnit === "HP"){
            dQ += soket / MS_KW_TO_HORSEPOWER
        }
    }
    //FCU
    if(FCUPF !==0 ){
        if(FCUUnit === "kW"){
            dP += FCU
            dQ += FCU * Math.sqrt(1 - FCUPF * FCUPF) / FCUPF
        }else if(FCUUnit === "kVA"){
            dP += FCU * FCUPF
            dQ += FCU * Math.sqrt(1 - FCUPF * FCUPF)
        }else if(FCUUnit === "HP"){
            dP += FCU / MS_KW_TO_HORSEPOWER
            dQ +=(FCU / MS_KW_TO_HORSEPOWER) * Math.sqrt(1 - FCUPF * FCUPF) / FCUPF
        }
    }
    else{
        if(FCUUnit === "kW"){
            dQ += FCU
        }else if(FCUUnit === "kVA"){
            dQ += FCU
        }
        else if(FCUUnit === "HP"){
            dQ += FCU / MS_KW_TO_HORSEPOWER
        }
    }
    //other
    if(otherPF !==0 ){
        if(otherUnit === "kW"){
            dP += other
            dQ += other * Math.sqrt(1 - otherPF * otherPF) / otherPF
        }else if(otherUnit === "kVA"){
            dP += other * otherPF
            dQ += other * Math.sqrt(1 - otherPF * otherPF)
        }else if(otherUnit === "HP"){
            dP += other / MS_KW_TO_HORSEPOWER
            dQ +=(other / MS_KW_TO_HORSEPOWER) * Math.sqrt(1 - otherPF * otherPF) / otherPF
        }
    }
    else{
        if(otherUnit === "kW"){
            dQ += other
        }else if(otherUnit === "kVA"){
            dQ += other
        }
        else if(otherUnit === "HP"){
            dQ += other / MS_KW_TO_HORSEPOWER
        }
    }

    totalCapacity = Math.sqrt(dP*dP + dQ*dQ)
    if(totalCapacity !== 0){
        totalCapacityPF = dP / totalCapacity
    }
    else{
        totalCapacityPF = 0
    }
    if(totalCapacityUnit === "kW"){
        totalCapacity = dP
    }else if(totalCapacityUnit === "kVA"){

    }
    else if(totalCapacityUnit === "HP"){
        totalCapacity = dP * MS_KW_TO_HORSEPOWER
    }
    if(!inputDetail){
        totalCapacity = +properties.totalCapacity
        totalCapacityPF = +properties.powerFactor
    }
    properties.powerFactor = BeamsNumber(totalCapacityPF,5)//note
    return BeamsNumber(totalCapacity,6)  
}
export const doGetLoadCurrent = (properties:any,m_bSPhase:boolean,inputDetailTemp:boolean | undefined = undefined) =>{
    let fullCurrent = 0
    let inputDetail = inputDetailTemp
    let voltage = properties.voltage
    let loadFactor = +properties.load_factor
    let totalCapacityUnit = properties.total_capacity_unit
    
    if(inputDetail === undefined){
        inputDetail = properties.input_detail
    }
    
    if(voltage <= 0) {
        fullCurrent = 0
    }
    else{
        let totalCapacity = 0
        let totalCapacityPF = 0
        if(inputDetail === true){
            totalCapacity = doGetValueTotalCapacity(properties,totalCapacityPF,inputDetail)
            totalCapacityPF = properties.powerFactor
            properties.total_capacity = BeamsNumber(totalCapacity,6)
        }
        else{
            totalCapacity = properties.total_capacity
            totalCapacityPF = properties.power_factor
        }
        let capacity = totalCapacity * 1000 * loadFactor
        if(totalCapacity === 0){
            if(totalCapacityUnit === "kW"){
                fullCurrent = capacity / voltage
            }
            else if (totalCapacityUnit === "kVA"){
                fullCurrent = capacity / voltage
            }
            else if(totalCapacityUnit === "HP"){
                fullCurrent = capacity / voltage / MS_KW_TO_HORSEPOWER
            }
            if(!m_bSPhase){
                fullCurrent /= Math.sqrt(3.0)
            }
        }
        else {
            if(totalCapacityUnit === "kW"){
                fullCurrent = capacity / voltage / totalCapacityPF
            }
            else if( totalCapacityUnit === "kVA"){
                fullCurrent = capacity / voltage
            }
            else if (totalCapacityUnit === "HP"){
                fullCurrent = capacity / voltage / totalCapacityPF /MS_KW_TO_HORSEPOWER
            }
            if(!m_bSPhase){
                fullCurrent /= Math.sqrt(3.0)
            }
        }
    }
    return BeamsNumber(+fullCurrent,4)
}

//CEleLightBoard::DoGetTextTotalCapacity
export const doGetTextTotalCapacity = (props:MsLightBoardModel, bUnit:boolean = true) =>
{
	let dTotalCapacity = 0;
	let dTotalCapacityPF = 0;

	if (props.inputDetail) {
		dTotalCapacity = doGetValueTotalCapacity(props, dTotalCapacityPF, props.inputDetail);
	}
	else {
		dTotalCapacity = props.totalCapacity;
	}
	let sText = BeamsNumber(dTotalCapacity, 6).toString();
	if (bUnit) {
		sText += props.totalCapacityUnit;
	}
	return sText;
}

//CEleLightBoard::DoGetTextVoltage
export const doGetTextVoltage = (props:MsLightBoardModel, nPartKind:number, bUnit:boolean = true) =>
{
	let sText = '';
	if (props.sPhase) {
		switch (nPartKind) {
		case MS_OUTSIDE_NEUTRAL:	
			sText = (props.voltage / 2).toString();
			break;
		case MS_OUTSIDE_OUTSIDE:	
			sText = props.voltage.toString();
			break;
		case MS_SEAT_MAIN:			
			sText = props.voltage.toString();
			break;
		case MS_SEAT_T:				
			sText = props.voltage.toString();
			break;
		}
	}
	else {
		sText = props.voltage.toString();
	}
	if (bUnit) {
		sText += 'V';
	}
	return sText;
}

//CEleLightBoard::DoGetValueFullCurrent
export const doGetValueFullCurrent = (props:MsLightBoardModel) =>
{
	let dFullCurrent = 0;

	let sTextHP = 'HP';
	let sTextkVA = 'kVA';
	let sTextkW = 'kW';

	if (props.voltage <= 0) {
		dFullCurrent = 0;
	}
	else {
		let dTotalCapacity = 0;
		let dTotalCapacityPF = 0;

		if (props.inputDetail) {
			dTotalCapacity = doGetValueTotalCapacity(props, dTotalCapacity, props.inputDetail);
		}
		else {
			dTotalCapacity = props.totalCapacity;
			dTotalCapacityPF = props.powerFactor;
		}
		let dCapacity = dTotalCapacity * 1000 * props.loadFactor;
		if (dTotalCapacityPF == 0) {
			if (props.totalCapacityUnit == sTextkW) {
				dFullCurrent = dCapacity / props.voltage;
			}
			else if (props.totalCapacityUnit == sTextkVA) {
				dFullCurrent = dCapacity / props.voltage;
			}
			else if (props.totalCapacityUnit == sTextHP) {
				dFullCurrent = dCapacity / props.voltage / MS_KW_TO_HORSEPOWER;
			}

			if (!props.sPhase) {
				dFullCurrent /= Math.sqrt(3.0);
			}
		}
		else {
			if (props.totalCapacityUnit == sTextkW) {
				dFullCurrent = dCapacity / props.voltage / dTotalCapacityPF;
			}
			else if (props.totalCapacityUnit == sTextkVA) {
				dFullCurrent = dCapacity / props.voltage;
			}
			else if (props.totalCapacityUnit == sTextHP) {
				dFullCurrent = dCapacity / props.voltage / dTotalCapacityPF / MS_KW_TO_HORSEPOWER;
			}

			if (!props.sPhase) {
				dFullCurrent /= Math.sqrt(3.0);
			}
		}
	}
	return dFullCurrent;
}

//CEleLightBoard::DoGetTextLoadCurrent
export const doGetTextLoadCurrent = (props:MsLightBoardModel, bUnit:boolean = true) =>
{
	let dLoadCurrent = doGetValueFullCurrent(props);
	let sText = BeamsNumber(dLoadCurrent, 4).toString();
	if (bUnit) {
		sText += 'A';
	}
	return sText;
}

//CEleLightBoard::DoGetTextTotalCapacityPF
export const doGetTextTotalCapacityPF = (props:MsLightBoardModel) =>
{
	let dTotalCapacity = 0;
	let dTotalCapacityPF = 0;

	if (props.inputDetail) {
		dTotalCapacity = doGetValueTotalCapacity(props, dTotalCapacity, props.inputDetail);
	}
	else {
		dTotalCapacityPF = props.powerFactor;
	}
	let sText = BeamsNumber(dTotalCapacityPF, 5).toString();

	return sText;
}

//CEleLightBoard::DoGetTextFullCurrent
export const doGetTextFullCurrent = (props:MsLightBoardModel, bUnit:boolean = true) =>
{
	let dFullCurrent = doGetValueFullCurrent(props);
	let sText = BeamsNumber(dFullCurrent, 4).toString();
	if (bUnit) {
		sText += 'A';
	}
	return sText;
}

//CEleLightBoard::DoGetRefV1
export const doGetRefV1 = (mode:any, props:MsLightBoardModel, bSociety:boolean) =>
{
	//CEleLightBoard::DoGetRefV1Left  sRefV1Left
	let labelTexts = [{
		value: props.refNo,
		color: "#f800f8",
	}];

	if (!props.viewResultText || (mode != ProcessMode.DRAWING && !props.supply))
    {
      return labelTexts;
    }

    if(bSociety) {
        return labelTexts;
    }

    switch(mode) {
        case ProcessMode.DRAWING:
        case ProcessMode.CHECKING:
            //TotalCapacity
            labelTexts.push({
                value: doGetTextTotalCapacity(props),
                color: "blue",
            });

            //LoadCurrent
            labelTexts.push({
                value: doGetTextLoadCurrent(props),
                color: "blue",
            });
            break;
        case ProcessMode.PERCENT_Z:
        case ProcessMode.CALCULATION:
            break;
        case ProcessMode.VOLTDROP:
             //FullCurrent
             labelTexts.push({
                value: doGetTextFullCurrent(props),
                color: "blue",
            });

            //力率=doGetTextTotalCapacityPF()
            labelTexts.push({
                value: '力率='
                + doGetTextTotalCapacityPF(props),
                color: "blue",
            });

           break;
        case ProcessMode.IMPROVE_PF:
             //力率=doGetTextTotalCapacityPF()
             labelTexts.push({
                value: '力率='
                + doGetTextTotalCapacityPF(props),
                color: "blue",
            });
            break;
        case ProcessMode.HARMONIC:
            break;
        case ProcessMode.POWER_FLOW:
            break;
        default:
            break;
    }

    return labelTexts;
}

//CEleLightBoard::DoGetTextFlowsResult
export const doGetTextFlowsResult = (props:MsLightBoardModel, processMode:any, nDirect:number, bSociety:boolean = false) =>
{
	let sRefV1:string[] = [];

	if (bSociety) {
		return sRefV1;
	}

	switch (processMode) {
	case MS_MODE_DRAWING:
	case MS_MODE_CHECKING:
	case MS_MODE_PERCENT_Z:
	case MS_MODE_CALCULATION:
	case MS_MODE_VOLTDROP:
	case MS_MODE_HARMONIC:
		break;
	case MS_MODE_POWER_FLOW:
		if (props.activePower !==0 && props.reactivePower !==0) {
			if (nDirect == DIRECT_NONE) {
				sRefV1.push(BeamsNumber(Math.abs(props.activePower),5).toString() + 'kW');
				sRefV1.push(BeamsNumber(Math.abs(props.reactivePower),5).toString() + 'kvar');
			}
			else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
				sRefV1.push(BeamsNumber(Math.abs(props.activePower),5).toString() + 'kW');
				let sTemp = ''
				if (props.reactivePower > 0) {
					sTemp += "+j";
				}
				else {
					sTemp += "-j";
				}
				sRefV1.push(sTemp + BeamsNumber(Math.abs(props.reactivePower),5).toString() + 'kvar');
			}
		}
		break;
	}

	return sRefV1;
}
