import { LoadingButton } from "@mui/lab";
import { Box, Button, Checkbox, CircularProgress, FormControlLabel, Grid, Stack, TextField, Typography } from "@mui/material";
import { useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { EquipMotorModel } from "../../../models/breakModel";
import { Path as RoutePath } from "../../../path";
import { ApplicationState, actionCreators, selectors } from "../../../store";
import FetchHelper from "../../FetchHelper";
//-----------
//FETCH ID
const GET_EQUIP_MOTOR = "GET_EQUIP_MOTOR"
const UPDATE_EQUIP_MOTOR = "UPDATE_EQUIP_MOTOR"
const CREATE_EQUIP_MOTOR = "CREATE_EQUIP_MOTOR"

const initialValue:EquipMotorModel = {
    lMotorId:0,
    bIsDefault:false,
    dCapacity:0,
    dEfficiency:0,
    dInrushCurrent:0,
    dPowerFactor:0,
    dStartTime:0,
    dStartingCurrent:0,
    dStartingTime:0,
}
// -------------
// Props
export type EquipMotorEditProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
export const EquipLoadEdit = (props: EquipMotorEditProps) => {
    const {
        userId,
        onCreateError,
        onUpdate, onUpdateError,
        onSuccess, onError,
        onCreate
    } = props;

    const { control, formState: { errors }, handleSubmit, setValue, getValues, } = useForm<EquipMotorModel>({
        criteriaMode: "all",
        mode:"all",
        defaultValues: initialValue,
    })
    type MyParams = {
        id: string;
        mode: string;
    };
    let { id, mode } = useParams<keyof MyParams>() as MyParams;
    let navigate = useNavigate();
    const [loadingFlag, setLoadingFlag] = useState(mode === "edit" ? true : false);
    const gridRef = useRef() as any;
    const Exp = /^[-+]?\d+(\.\d+)?$/;
    const messageIsNotNumber = "数字を入力してください。";

    const handleSuccess = (data: any) => {
        setLoadingFlag(false);
        if(data && data.resultCode == 0){
            setValue("lMotorId",data.data.lMotorId);
            setValue("bIsDefault",data.data.bIsDefault);
            setValue("dCapacity",data.data.dCapacity?? "");
            setValue("dEfficiency",data.data.dEfficiency?? "");
            setValue("dInrushCurrent",data.data.dInrushCurrent?? "");
            setValue("dPowerFactor",data.data.dPowerFactor?? "");
            setValue("dStartTime",data.data.dStartTime?? "");
            setValue("dStartingCurrent",data.data.dStartingCurrent?? "");
            setValue("dStartingTime",data.data.dStartingTime?? "");
        }
    }

    // 検索エラー時
    const handleError = (success: boolean, data: any, error: any) => {
        setLoadingFlag(false);
        console.log('>>>> handle Error');
        gridRef.current.hideOverlay();
    };

    // 検索実行
    const handleEditSubmit = (data: EquipMotorModel) => {
        setLoadingFlag(true);
        if(mode === "edit") {
            let request = {
                lMotorId:id,
                bIsDefault:data.bIsDefault,
                dCapacity:data.dCapacity,
                dEfficiency:data.dEfficiency,
                dInrushCurrent:data.dInrushCurrent,
                dPowerFactor:data.dPowerFactor,
                dStartTime:data.dStartTime,
                dStartingCurrent:data.dStartingCurrent,
                dStartingTime:data.dStartingTime,
            } as any
            const object = {
                requestPrms:request,
                userId: userId,
            }
            onUpdate(object);
        } else if (mode === "add") {
            let request = {
                bIsDefault:data.bIsDefault,
                dCapacity:data.dCapacity,
                dEfficiency:data.dEfficiency,
                dInrushCurrent:data.dInrushCurrent,
                dPowerFactor:data.dPowerFactor,
                dStartTime:data.dStartTime,
                dStartingCurrent:data.dStartingCurrent,
                dStartingTime:data.dStartingTime,
            } as any
            const object = {
                requestPrms:request,
                userId: userId,
            }
            onCreate(object);
        }
    }

    // キャンセル
    const handleCancel = () => {
        navigate(RoutePath.EquipMotorList);
    }

    // Submit後のAPI呼び出しエラー時
    const handleErrorUpdate = (success: boolean, data: any, error: any) => {
        onUpdateError();
        setLoadingFlag(false);
    }

    // Submit後のAPI呼び出し成功時
    const handleSuccessSubmit = (success: boolean, data: any, error: any) => {
        onSuccess();
        if (mode === "add") {
            navigate(RoutePath.EquipMotorEdit + `/edit/${data.data.lMotorId}`, { replace: true })
        }
        setLoadingFlag(false);
    }

    // Submit後のAPI呼び出しエラー時
    const handleSubmitError = (success: boolean, data: any, error: any) => {
        onCreateError();
        setLoadingFlag(false);
    }

    //入力欄背景
    const divStyles = {
        backgroundColor: 'white',
        border: '1px solid #bebebe',
        boxShadow: '0 0 5px #fff, 0 0 5px #bebebe, 0 0 1px #aaa',
        padding: '20px 40px',
        marginTop: '40px',
    }

    return (
        <>
           {mode === "edit" &&
            <FetchHelper
            fetchId={GET_EQUIP_MOTOR}
            url={`/master/equip_motor/get`}
            method="POST"
            json={true}
            request={true}
            params={{
                requestPrms:{
                    id:id
                },
                userId: userId,
            }}
            onComplete={(success, data, error) => {
                success ? handleSuccess(data) : handleError(success, data, error);
            }}
        />}
            {loadingFlag && (
                <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1500, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                    <CircularProgress />
                </div>
            )}
            <div style={{ height: "100%", width: "100%", marginTop: "10px" }}>
                <Stack direction="row" justifyContent="flex-start" alignItems="center" sx={{}} style={{ margin: '18px', color: 'MenuText' }}>
                    <Typography variant="h4">
                        {/* FIXME: */}
                        電動機詳細
                    </Typography>
                </Stack>
                <form onSubmit={handleSubmit(handleEditSubmit)}>
                    <div style={divStyles}>
                        <Typography variant="h6">
                            {/* FIXME: */}
                            電動機
                        </Typography>
                        <Stack mt={2} direction={{ xs: 'column', sm: 'row' }} sx={{maxHeight: "calc(100vh)"}}>
                            <Grid width={"100%"} spacing={1}>
                                <Grid pt={1} spacing={2} container direction={"row"}>
                                    <Grid item xs={4}>
                                        <Controller
                                            control={control}
                                            name="dCapacity"
                                            rules={{
                                                pattern: {
                                                    value: Exp,
                                                    message: messageIsNotNumber,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.dCapacity}
                                                    helperText={errors?.dCapacity?.message}
                                                    label="容量（kW）"
                                                    type="text"
                                                    size="small"

                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Controller
                                            control={control}
                                            name="dEfficiency"
                                            rules={{
                                                pattern: {
                                                    value: Exp,
                                                    message: messageIsNotNumber,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.dEfficiency}
                                                    helperText={errors?.dEfficiency?.message}
                                                    label="効率(EPAct 4極 60Hz 230Vまたは460V)"
                                                    type="text"
                                                    size="small"

                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Controller
                                            control={control}
                                            name="dInrushCurrent"
                                            rules={{
                                                pattern: {
                                                    value: Exp,
                                                    message: messageIsNotNumber,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.dInrushCurrent}
                                                    helperText={errors?.dInrushCurrent?.message}
                                                    label="Starting inrush current times of Im（s）"
                                                    type="text"
                                                    size="small"

                                                />
                                            )}
                                        />
                                    </Grid>                                 
                                </Grid>
                                <Grid mt={0} spacing={2} container direction={"row"}>
                                    <Grid item xs={4}>
                                        <Controller
                                            control={control}
                                            name="dPowerFactor"
                                            rules={{
                                                pattern: {
                                                    value: Exp,
                                                    message: messageIsNotNumber,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.dPowerFactor}
                                                    helperText={errors?.dPowerFactor?.message}
                                                    label="力率(一般値)"
                                                    type="text"
                                                    size="small"

                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Controller
                                            control={control}
                                            name="dStartTime"
                                            rules={{
                                                pattern: {
                                                    value: Exp,
                                                    message: messageIsNotNumber,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.dStartTime}
                                                    helperText={errors?.dStartTime?.message}
                                                    type="text"
                                                    size="small"
                                                    label="Start Time"


                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Controller
                                            control={control}
                                            name="dStartingCurrent"
                                            rules={{
                                                pattern: {
                                                    value: Exp,
                                                    message: messageIsNotNumber,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.dStartingCurrent}
                                                    helperText={errors?.dStartingCurrent?.message}
                                                    label="Starting current times of Im（s）"
                                                    type="text"
                                                    size="small"

                                                />
                                            )}
                                        />
                                    </Grid>         
                                </Grid>
                                <Grid mt={0} spacing={2} container direction={"row"}>
                                    <Grid item xs={4}>
                                        <Controller
                                            control={control}
                                            name="dStartingTime"
                                            rules={{
                                                pattern: {
                                                    value: Exp,
                                                    message: messageIsNotNumber,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.dStartingTime}
                                                    helperText={errors?.dStartingTime?.message}
                                                    label="Starting time（s）"
                                                    type="text"
                                                    size="small"


                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Controller
                                            name="bIsDefault"
                                            control={control}
                                            render={({ field }: any) => (
                                                <FormControlLabel {...field} control={<Checkbox />} checked={field.value} label="デフォルトの容量値" labelPlacement="start" />
                                            )}
                                        />
                                    </Grid>                                      
                                </Grid>
                            </Grid>
                        </Stack>
                    </div>
                    <Stack direction='row' justifyContent="flex-end" spacing={2} sx={{ mt: 1, mr: 2 }}>
                        <LoadingButton variant="contained" type="submit" loading={loadingFlag}>保存</LoadingButton>
                        <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                    </Stack>
                </form>
            </div>
            <FetchHelper fetchId={UPDATE_EQUIP_MOTOR}
                onComplete={(success: boolean, data: any, error: any) =>
                    success ? handleSuccessSubmit(success, data, error) : handleErrorUpdate(success, data, error)} />
        
            <FetchHelper fetchId={CREATE_EQUIP_MOTOR}
                onComplete={(success, data, error) => success ?
                    handleSuccessSubmit(success, data, error) :
                    handleSubmitError(success, data, error)} />
        </>
    );
}

const mapStateToProps = (state: ApplicationState) => (
    {
        userId: state.app.user?.userId,
        fetchUpdate: selectors.getFormState(
            state,
            // GET_BREAK_CAPACITY
            UPDATE_EQUIP_MOTOR
        ),
    }
)

const mapDispatchToProps = (dispatch: any) => {
    return {
        onCreate: (data: any) => dispatch(actionCreators.fetch(CREATE_EQUIP_MOTOR, `/master/equip_motor/add`, "POST", data, false, true)),
        onUpdate: (data: any) => dispatch(actionCreators.fetch(UPDATE_EQUIP_MOTOR, `/master/equip_motor/modify`, "POST", data,false,true)),
        onSuccess: () =>
            dispatch(actionCreators.showMessage({
                type: "info",
                title: "電動機更新",
                body: "電動機を更新しました。",
            })),
        onError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "検索",
                body: "検索できません。",
            })),
        onCreateError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "電動機登録",
                body: "電動機を登録出来ませんでした。",
            })),
        onUpdateError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "電動機編集",
                body: "電動機を更新出来ませんでした。",
            })),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(EquipLoadEdit as any);