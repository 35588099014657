import {
    isCalcImprovePF,
    isCanSetImprovePF,
    isCanSetPointDrop,
    isCanSetPointFault,
    isCanSetViewFlowResult,
    isCanSwitchOnOff,
    isPointDropAND,
    isPointDropOR,
    isPointFaultAND,
    isPointFaultOR,
    isSwitchOff,
    isViewFlowResultEle
} from "./element";
import * as CONSTANTS from "../models/Constants"
import * as dxfFile1 from "../components/dxfFile/dxfFile1";

export const onCanUpdateEditFaultOn = (currentIDDiagramTab:any,currentSelectedShape:any) => {
    let result = false;
    if(currentIDDiagramTab !== 1){
        if(currentSelectedShape){
            if(currentSelectedShape.length > 0){
                for(const item of currentSelectedShape){
                    if(isCanSetPointFault(item) === true && isPointFaultAND(item) !== true){
                        result = true;
                        break
                    }
                }
            }
        }
    }
    else{
        if(currentSelectedShape){
            if(currentSelectedShape.length > 0){
                for(const item of currentSelectedShape){
                    if(isCanSetPointFault(item) === true && isPointFaultAND(item) !== true){
                        result = true;
                        break
                    }
                }
            }
        }
    }
    return result;
}

export const onCanUpdateEditFaultOff = (currentIDDiagramTab:any,currentSelectedShape:any) => {
    let result = false;
    if(currentIDDiagramTab !== 1){
        if(currentSelectedShape){
        if(currentSelectedShape.length > 0){
            for(const item of currentSelectedShape){
                if(isCanSetPointFault(item) === true && isPointFaultOR(item) === true){
                    result = true;
                    break
                }
            }
        }
        }
    }
    else{
        if(currentSelectedShape){
            if(currentSelectedShape.length > 0){
                for(const item of currentSelectedShape){
                    if(isCanSetPointFault(item) === true && isPointFaultOR(item) === true){
                        result = true;
                    break
                    }
                }
            }
        }
    }
    return result;
}

export const onCanUpdateEditDropOn = (currentIDDiagramTab:any,currentSelectedShape:any) => {
    let result = false;
    if(currentIDDiagramTab !== 1){
        if(currentSelectedShape){
            if(currentSelectedShape.length > 0){
                for(const item of currentSelectedShape){
                    if(isCanSetPointDrop(item) === true && isPointDropAND(item) !== true){
                        result = true;
                        break
                    }
                }
            }
        }
    }
    else{
        if(currentSelectedShape){
            if(currentSelectedShape.length > 0){
                for(const item of currentSelectedShape){
                    if(isCanSetPointDrop(item) === true && isPointDropAND(item) !== true){
                        result = true;
                        break
                    }
                }
            }
        }   
    }
    return result;
}

export const onCanUpdateEditImproveOn = (currentIDDiagramTab:any,currentSelectedShape:any) => {
    let result = false;
    if(currentIDDiagramTab !== 1){
        if(currentSelectedShape){
            if(currentSelectedShape.length > 0){
                for(const item of currentSelectedShape){
                    if(isCanSetImprovePF(item) === true && isCalcImprovePF(item) !== true){
                        result = true;
                        break
                    }
                }
            }
        }
    }
    else{
        if(currentSelectedShape){
            if(currentSelectedShape.length > 0){
                for(const item of currentSelectedShape){
                    if(isCanSetImprovePF(item) === true && isCalcImprovePF(item) !== true){
                        result = true;
                        break
                    }
                }
            }
        }   
    }
    return result;
}

export const onCanUpdateEditImproveOff = (currentIDDiagramTab:any,currentSelectedShape:any) => {
    let result = false;
    if(currentIDDiagramTab !== 1){
      if(currentSelectedShape){
          if(currentSelectedShape.length > 0){
              for(const item of currentSelectedShape){
                  if(isCalcImprovePF(item) === true){
                        result = true;
                        break
                    }
              }
          }
      }
    }
    else{
        if(currentSelectedShape){
            if(currentSelectedShape.length > 0){
                for(const item of currentSelectedShape){
                    if(isCalcImprovePF(item) === true){
                        result = true;
                        break
                    }
                }
            }
        }   
    }
    return result
}

export const onCanUpdateEditDropOff = (currentIDDiagramTab:any,currentSelectedShape:any) => {
    let result = false;
    if(currentIDDiagramTab !== 1){
        if(currentSelectedShape){
            if(currentSelectedShape.length > 0){
                for(const item of currentSelectedShape){
                    if(isCanSetPointDrop(item) === true && isPointDropOR(item) === true){
                        result = true;
                        break
                    }
                }
            }
        }
    }
    else{
        if(currentSelectedShape){
            if(currentSelectedShape.length > 0){
                for(const item of currentSelectedShape){
                    if(isCanSetPointDrop(item) === true && isPointDropOR(item) === true){
                        result = true;
                        break
                    }
                }
            
            }
        }
    }
    return result
} 

export const onCanEditFlowResultOn = (currentIDDiagramTab:any,currentSelectedShape:any) => {
    let result = false;
    if(currentIDDiagramTab !== 1){
        if(currentSelectedShape){
            if(currentSelectedShape.length > 0){
                for(const item of currentSelectedShape){
                    if(isCanSetViewFlowResult(item) === true && isViewFlowResultEle(item) !== true){
                        result = true;
                        break;
                    }
                }
            }
        }
    }
    else{
        if(currentSelectedShape){
            if(currentSelectedShape.length > 0){
                for(const item of currentSelectedShape){
                    if(isCanSetViewFlowResult(item) === true && isViewFlowResultEle(item) !== true){
                        result = true;
                        break;
                    }
                }
            }
        }   
    }
    return result
}

export const onCanEditFlowResultOff = (currentIDDiagramTab:any,currentSelectedShape:any) => {
    let result = false;
    if(currentIDDiagramTab !== 1){
        if(currentSelectedShape){
            if(currentSelectedShape.length > 0){
                for(const item of currentSelectedShape){
                    if(isCanSetViewFlowResult(item) === true && isViewFlowResultEle(item) == true){
                        result = true;
                        break
                    }
                }
            }
        }
    }
    else{
        if(currentSelectedShape){
            if(currentSelectedShape.length > 0){
                for(const item of currentSelectedShape){
                    if(isCanSetViewFlowResult(item) === true && isViewFlowResultEle(item) == true){
                        result = true;
                        break
                    }
                }
            }
        }   
    }
    return result
}

export const onCanUpdateEditOff = (currentIDDiagramTab:any,currentSelectedShape:any) => {
    let result = false;
    if(currentIDDiagramTab !== 1){
        if(currentSelectedShape){
            if(currentSelectedShape.length > 0){
                for(const item of currentSelectedShape){
                    if(isCanSwitchOnOff(item) == true && isSwitchOff(item) != true){
                        result = true;
                        break
                    }
                }
            }
        }
    }
    else{
        if(currentSelectedShape){
            if(currentSelectedShape.length > 0){
                for(const item of currentSelectedShape){
                    if(isCanSwitchOnOff(item) == true && isSwitchOff(item) != true){
                        result = true;
                        break
                    }
                }
            }
        }   
    }
    return result
}

export const onCanUpdateEditOn = (currentIDDiagramTab:any,currentSelectedShape:any) => {
    let result = false;
    if(currentIDDiagramTab !== 1){
        if(currentSelectedShape){
            if(currentSelectedShape.length > 0){
                for(const item of currentSelectedShape){
                    if(isCanSwitchOnOff(item) == true && isSwitchOff(item) == true){
                        result = true;
                        break
                    }
                }
            }
        }
    }
    else{
        if(currentSelectedShape){
            if(currentSelectedShape.length > 0){
                for(const item of currentSelectedShape){
                    if(isCanSwitchOnOff(item) == true && isSwitchOff(item) == true){
                        result = true;
                        break
                    }
                }
            }
        }   
    }
    return result
}

// const params = {
//     fileName: string,
//     user: UserDetail,
//     currentTabId: number,
//     diagramData: ControlModel[],
//     infoSkeleton: InfoSkeleton,
//     infoCircuit: InfoCircuit,
//     projectData: ProjectModel,
//     transCenter: ControlModel | null,
// }
export const onFileDxfOutPut = (params: any) => {
    let fileName = params.fileName
    
    let saveFile = fileName + ".dxf"
    let baseFileName = CONSTANTS.NAME_DXF_SKELETON
    let CDXFFile1 = {
        m_nLayerNo: CONSTANTS.DXF_LAYER_LINE,
        m_dBaseY: 0,
        m_dReduced: 0,
        m_dOffsetX: 0,
        m_dOffsetY: 0
    }
    dxfFile1.doDxfOutToFile(CDXFFile1, baseFileName, saveFile, params)
}