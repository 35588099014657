import { connect } from "react-redux";
import { ApplicationState } from "../../../store";
import { Controller, useForm } from "react-hook-form";
import { Button, CircularProgress, Stack, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import { downloadFileFromS3, getDownloadFileUrlS3, getUploadFileUrlS3, uploadFileToS3 } from "../../CallApi";
import * as Model from "../../../models/Index";
import { actionCreators } from "../../../store";
import { openFileInNewTabByBlob } from "../../../utils/downloadFile";

interface MaintenanceNoticeScreenModel {
    maintenance_message:string;
    maintenance_date:string;
    maintenance_text:string;
}
// 初期値設定
const initialValue: MaintenanceNoticeScreenModel = {
    maintenance_message:"",
    maintenance_date:"",
    maintenance_text:"",
}
type ManufactureListProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

// Component
export const MaintenanceNoticeScreen = (props: ManufactureListProps) => {
    const { onShowMessage } = props;
    const navigate = useNavigate();
    const { control, handleSubmit, setValue, getValues } = useForm<MaintenanceNoticeScreenModel>({
        criteriaMode: "all",
        defaultValues:initialValue
    });

    const [loadingFlag,setLoadingFlag] = React.useState<boolean>(false);
    const [htmlContentEdit,setHtmlContentEdit] = React.useState<any>();

    useEffect(() => {
        handleGetDatafile();
    },[])

    const handleGetDatafile = async () => {
        const key = process.env.REACT_APP_MAINTENANCE_NOTICE_KEY_NAME || "";
        if (key == "") {
            onShowMessage({
                type: "error",
                title: "プリサインドURLを取得",
                body: "無効なパス",
            });
            return
        }

        setLoadingFlag(true);
        const resGetUrl = await getDownloadFileUrlS3(key, true);
        if (resGetUrl.success) {
            const resDownloadFile = await downloadFileFromS3(resGetUrl.data)
            if (resDownloadFile.success){
                const reader = new FileReader();
                reader.onload = () => {
                    const htmlContent:any = reader.result;
                    setHtmlContentEdit(htmlContent)
                    const parser = new DOMParser();
                    const doc = parser.parseFromString(htmlContent, 'text/html');
                    const maintenanceMessage = doc.querySelector('.maintenance-message');
                    const maintenanceDate = doc.querySelector('.maintenance-date');
                    const maintenanceText = doc.querySelector('.maintenance-text');
                    if(maintenanceMessage && maintenanceDate && maintenanceText){
                        setValue("maintenance_message",maintenanceMessage?.innerHTML.replaceAll('&nbsp;',' ')??"")
                        setValue("maintenance_date",maintenanceDate?.innerHTML.replaceAll('&nbsp;',' ')??"")
                        setValue("maintenance_text",maintenanceText?.innerHTML.replaceAll('&nbsp;',' ').replaceAll(/<br\s*[\/]?>/gi, "\n")??"")
                    }
                };
                reader.readAsText(resDownloadFile.data, "UTF-8")
            } else {
                onShowMessage({
                    type: "error",
                    title: "ダウンロードファイル",
                    body: resDownloadFile.error,
                });
            }
        } else {
            onShowMessage({
                type: "error",
                title: "プリサインドURLを取得",
                body: resGetUrl.error.message,
            });
        }
    
        setLoadingFlag(false);
    }
    
    const doUpdateData = () =>{
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContentEdit, 'text/html');

        const maintenance_message = doc.getElementsByClassName("maintenance-message")[0];
        maintenance_message.innerHTML = getValues('maintenance_message');
    
        const maintenance_date = doc.getElementsByClassName("maintenance-date")[0];
        maintenance_date.innerHTML = getValues('maintenance_date');
    
        const maintenance_text = doc.getElementsByClassName("maintenance-text")[0];
        maintenance_text.textContent = getValues('maintenance_text').replace(/\n/g, '<br/>')
        maintenance_text.innerHTML = maintenance_text.textContent

        const htmlString = new XMLSerializer().serializeToString(doc);
        setHtmlContentEdit(htmlString)
        return htmlString
    }
    
    const handleClickPreview = () =>{
        const htmlContent = doUpdateData()
        let pdfBlob = new Blob(
            [htmlContent],
            {type: 'text/html'}
        );
        openFileInNewTabByBlob(pdfBlob)
    }

    const handleAddFile = async () => {
        const key = process.env.REACT_APP_MAINTENANCE_NOTICE_KEY_NAME || "";
        if (key == "") {
            onShowMessage({
                type: "error",
                title: "プリサインドURLを取得",
                body: "無効なパス",
            });
            return
        }
        setLoadingFlag(true);
        const htmlContent = doUpdateData()
        const resGetUrl = await getUploadFileUrlS3(key, 'text/html', true);
        if (resGetUrl.success) {
            const uploadFileUrlS3 = resGetUrl.data;
            const blob = new Blob([htmlContent], { type: "text/html" });
            const resUpload = await uploadFileToS3(uploadFileUrlS3, blob)
            if (resUpload.success) {
                onShowMessage({
                    type: 'info',
                    title: 'ファイル追加',
                    body: 'ファイル追加しました',
                });
            } else {
                onShowMessage({
                    type: "error",
                    title: "ファイル追加",
                    body: resUpload.error.message,
                });
            }
        } else {
            onShowMessage({
                type: "error",
                title: "プリサインドURLを取得",
                body: resGetUrl.error.message,
            });
        }

        setLoadingFlag(false);
    }
    
    return (
        <>
            {loadingFlag && (
                <div style={{top: "0px",left: "0px",position: "fixed",zIndex: 1500,width: "100%",height: "100%",padding: "50vh 50% 50% 50%",background: "#00000030",}}>
                    <CircularProgress />
                </div>
            )}
            <div style={{ height: '100%', width: '100%', marginTop: '10px' }}>
                <Stack mx={2} style={{ color: 'MenuText' }}>
                    <Typography variant="h4">
                        メンテナンス中画面設定
                    </Typography>
                </Stack>
                <Stack sx={{border: 1}} px={2} pt={3} pb={1} mt={2} mx={2}>
                    <Stack mb={2} mr={2}>
                        <Controller
                            name="maintenance_message"
                            control={control}
                            rules={{ }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    fullWidth
                                    type="text"
                                    size="small"
                                    label="メッセージ"
                                />
                            )}
                        />
                    </Stack>
                    <Stack mb={2} mr={2}>
                        <Controller
                            name="maintenance_date"
                            control={control}
                            rules={{ }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    fullWidth
                                    type="text"
                                    size="small"
                                    label="メンテナンス期間"
                                />
                            )}
                        />
                    </Stack>
                    <Stack mb={1} mr={2}>
                        <Controller
                            name="maintenance_text"
                            control={control}
                            rules={{ }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    fullWidth
                                    type="text"
                                    size="small"
                                    label="メッセージ詳細"
                                    multiline
                                    minRows={10}
                                    maxRows={10}
                                />
                            )}
                        />
                    </Stack>
                    <Stack direction='row' justifyContent="space-between" gap={2} my={2} mr={2}>
                        <Button variant="contained" type="submit" onClick={handleClickPreview}>プレビュー表示</Button>
                        <Stack direction='row' gap={2} justifyContent="flex-end">
                            <Button variant="contained" type="submit" onClick={handleAddFile}>保存</Button>
                            <Button color="inherit" variant="contained" onClick={() => {navigate(-1)}}>キャンセル</Button>
                        </Stack>
                    </Stack>
                </Stack>
            </div>
        </>
    );
};

const mapStateToProps = (state: ApplicationState) => ({});

const mapDispatchToProps = (dispatch: any) => {
    return {
        onShowMessage: (message: Model.Message) => dispatch(actionCreators.showMessage(message)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceNoticeScreen);