import DashboardMainTopNavbar from "../../layouts/DashboardMainTopNavbar";
import DashboardSidebar from "../../layouts/DashboardSidebar";
import DashboardLayout from "../../layouts/DashboardLayout";
import { Outlet } from "react-router-dom";

export const Settings = () => {
    return (
        <div style={{ width: '100%', height: '100%' }}>
            <div style={{ width: '100%' }}>
                <DashboardMainTopNavbar modeHome={false} userId={null}/>
            </div>
            <div style={{ display: 'flex', width: '100%' }} >
                <DashboardSidebar/>
                <DashboardLayout>
                    <Outlet />
                </DashboardLayout>
            </div>
        </div>
    );
};

export default Settings;