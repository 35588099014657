import React, { useEffect, useRef, useState } from 'react'
import { EquipWirezModel } from '../../../models/breakModel'
import { Box, Button, Checkbox, CircularProgress, FormControlLabel, Grid, Stack, Tab, Tabs, TextField, Typography } from '@mui/material'
import { ApplicationState, actionCreators, selectors } from '../../../store'
import { connect } from 'react-redux'
import { Controller, useForm } from 'react-hook-form'
import { Path as RoutePath } from "../../../path";
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import FetchHelper from '../../FetchHelper'
import { LoadingButton } from '@mui/lab'
//-----------
//FETCH ID
const GET_EQUIP_WIREZ = "GET_EQUIP_WIREZ"
const UPDATE_EQUIP_WIREZ = "UPDATE_EQUIP_WIREZ"
const CREATE_EQUIP_WIREZ = "CREATE_EQUIP_WIREZ"

const initialValue: EquipWirezModel = {
  lWirezId: 0,
  bIsDefault: false,
  dCoefficient: 0,
  dCoreSize: 0,
  dOutsizeDe: 0,
  dThickness: 0,
}
// -------------
//Tabs------------------
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
// -------------
// Props
export type EquipWirezEditProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
const EquipWirezEdit = (props: EquipWirezEditProps) => {
  const {
    userId,
    onCreate, 
    onCreateError,
    onUpdate, 
    onUpdateError,
    onSuccess, 
    onError,
  } = props;

  const { control, formState: { errors }, handleSubmit, setValue, getValues, } = useForm<EquipWirezModel>({
    criteriaMode: "all",
    defaultValues: initialValue,
    mode: "all",
  })
  type MyParams = {
    id: string;
    mode: string;
  };
  let { id, mode } = useParams<keyof MyParams>() as MyParams;
  const location = useLocation();
  let navigate = useNavigate();
  const [loadingFlag, setLoadingFlag] = useState(mode === "edit" ? true : false);
  const gridRef = useRef() as any;
  const Exp = /^[-+]?\d+(\.\d+)?$/;
  const messageIsNotNumber = "数字を入力してください。";

  const handleSuccess = (data: any) => {
    console.log('>>>> handle Successs');
    // if(data && data.data){
    const obj = data.data;
    setValue("lWirezId", obj.lWirezId);
    setValue("bIsDefault", obj.bIsDefault);
    setValue("dCoefficient", obj.dCoefficient?? "");
    setValue("dCoreSize", obj.dCoreSize?? "");
    setValue("dOutsizeDe", obj.dOutsizeDe?? "");
    setValue("dThickness", obj.dThickness?? "");
    // }

    setLoadingFlag(false);
  }

  // 検索エラー時
  const handleError = (success: boolean, data: any, error: any) => {
    console.log('>>>> handle Error');
    gridRef.current.hideOverlay();
  }

  // 検索実行
  const handleEditSubmit = (data: EquipWirezModel) => {
    setLoadingFlag(true);
    if (mode === "edit") {
      let request = {
        lWirezId: getValues("lWirezId"),
        bIsDefault: getValues("bIsDefault") ?? null,
        dCoefficient: getValues("dCoefficient") ?? null,
        dCoreSize: getValues("dCoreSize") ?? null,
        dOutsizeDe: getValues("dOutsizeDe") ?? null,
        dThickness: getValues("dThickness") ?? null
      } as any
      const object = {
        requestPrms: request,
        userId: userId,
      }
      onUpdate(object);
    } else if (mode === "add") {
      let request = {
        bIsDefault: getValues("bIsDefault") ?? null,
        dCoefficient: getValues("dCoefficient") ?? null,
        dCoreSize: getValues("dCoreSize") ?? null,
        dOutsizeDe: getValues("dOutsizeDe") ?? null,
        dThickness: getValues("dThickness") ?? null
      } as any
      const object = {
        requestPrms: request,
        userId: userId,
      }
      onCreate(object);
    }
  }
  // キャンセル
  const handleCancel = () => {
    if (location.key !== "default")
      navigate(-1);
    else
      navigate(RoutePath.EquipWirezList);
  }

  // Submit後のAPI呼び出しエラー時
  const handleErrorUpdate = (success: boolean, data: any, error: any) => {
    onUpdateError();
    setLoadingFlag(false);
  }

  // Submit後のAPI呼び出し成功時
  const handleSuccessSubmit = (success: boolean, data: any, error: any) => {
    onSuccess();
    if (mode === "add") {
      navigate(RoutePath.EquipWirezEdit + `/edit/${data.data.lWirezId}`, { replace: true })
    }
    setLoadingFlag(false);
  }

  // Submit後のAPI呼び出しエラー時
  const handleErrorSubmit = (success: boolean, data: any, error: any) => {
    onCreateError();
    setLoadingFlag(false)
  }

  //入力欄背景
  const divStyles = {
    backgroundColor: 'white',
    border: '1px solid #bebebe',
    boxShadow: '0 0 5px #fff, 0 0 5px #bebebe, 0 0 1px #aaa',
    padding: '20px 40px',
    marginTop: '40px',
  }

  return (
    <>
      {
        mode === "edit" &&
        <FetchHelper
          fetchId={GET_EQUIP_WIREZ}
          url={`/master/equip_wirez/get`}
          method="POST"
          request={true}
          params={{
            requestPrms: {
              id: id
            },
            userId: userId,
          }}
          json={true}
          onComplete={(success, data, error) => {
            success ? handleSuccess(data) : handleError(success, data, error);
          }}
        />
      }
      {loadingFlag && (
        <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1500, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
          <CircularProgress />
        </div>
      )}
      <div style={{ height: "100%", width: "100%", marginTop: "10px" }}>
        <Stack direction="row" justifyContent="flex-start" alignItems="center" sx={{}} style={{ margin: '18px', color: 'MenuText' }}>
          <Typography variant="h4">
            電線(Z)詳細
          </Typography>
        </Stack>
        <form onSubmit={handleSubmit(handleEditSubmit)}>
          <div style={divStyles}>
            <Typography variant="h6">
              電線(Z)
            </Typography>
            <Stack mt={3} direction={{ xs: 'column', sm: 'row' }} sx={{ maxHeight: "calc(100vh)", overflow: 'auto' }}>
              <Grid container spacing={3} direction={'row'}>
                <Grid item xs={6} mt={1}>
                  <Controller
                    control={control}
                    name="dCoefficient"
                    rules={{
                      pattern: {
                        value: Exp,
                        message: messageIsNotNumber,
                      }
                    }}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        {...field}
                        error={!!errors?.dCoefficient}
                        helperText={errors?.dCoefficient?.message}
                        type="text"
                        size="small"
                        label="半径計算用係数"
                      // InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6} mt={1}>
                  <Controller
                    control={control}
                    name="dCoreSize"
                    rules={{
                      pattern: {
                        value: Exp,
                        message: messageIsNotNumber,
                      }
                    }}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        {...field}
                        error={!!errors?.dCoreSize}
                        helperText={errors?.dCoreSize?.message}
                        type="text"
                        size="small"
                        label="断面積"


                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name="dOutsizeDe"
                    rules={{
                      pattern: {
                        value: Exp,
                        message: messageIsNotNumber,
                      }
                    }}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        {...field}
                        error={!!errors?.dOutsizeDe}
                        helperText={errors?.dOutsizeDe?.message}
                        type="text"
                        label="E方式の外形（直径）"
                        size="small"

                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name="dThickness"
                    rules={{
                      pattern: {
                        value: Exp,
                        message: messageIsNotNumber,
                      }
                    }}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        {...field}
                        error={!!errors?.dThickness}
                        helperText={errors?.dThickness?.message}
                        type="text"
                        label="絶縁皮膜厚さT"
                        size="small"

                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="bIsDefault"
                    render={({ field }: any) => (
                      <FormControlLabel {...field} control={<Checkbox />} checked={field.value} label="デフォルトの断面積" labelPlacement="start" />
                    )}
                  />
                </Grid>
              </Grid>
            </Stack>
          </div>
          <Stack direction='row' justifyContent="flex-end" spacing={2} sx={{ mt: 1, mr: 2 }}>
            <LoadingButton variant="contained" type="submit" loading={loadingFlag}>保存</LoadingButton>
            <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
          </Stack>
          {/* </TabPanel> */}
        </form>
      </div>
      <FetchHelper fetchId={UPDATE_EQUIP_WIREZ}
        onComplete={(success: boolean, data: any, error: any) =>
          success ? handleSuccessSubmit(success, data, error) : handleErrorUpdate(success, data, error)} />

      <FetchHelper fetchId={CREATE_EQUIP_WIREZ}
        onComplete={(success, data, error) => success ?
          handleSuccessSubmit(success, data, error) :
          handleErrorSubmit(success, data, error)} />

    </>
  );
}
const mapStateToProps = (state: ApplicationState) => (
  {
    userId: state.app.user?.userId,
    fetchUpdate: selectors.getFormState(
      state,
      GET_EQUIP_WIREZ
    ),
  }
)
const mapDispatchToProps = (dispatch: any) => {
  return {
    onCreate: (data: any) => dispatch(actionCreators.fetch(CREATE_EQUIP_WIREZ, `/master/equip_wirez/add`, "POST", data, false, true)),
    onUpdate: (data: any) => dispatch(actionCreators.fetch(UPDATE_EQUIP_WIREZ, `/master/equip_wirez/modify`, "POST", data, false, true)),
    onSuccess: () =>
      dispatch(actionCreators.showMessage({
        type: "info",
        title: "電線(Z)更新",
        body: "電線(Z)を更新しました。",
      })),
    onError: () =>
      dispatch(actionCreators.showMessage({
        type: "error",
        title: "検索",
        body: "検索できません。",
      })),
    onCreateError: () =>
      dispatch(actionCreators.showMessage({
        type: "error",
        title: "電線(Z)登録",
        body: "電線(Z)を登録出来ませんでした。",
      })),
    onUpdateError: () =>
      dispatch(actionCreators.showMessage({
        type: "error",
        title: "電線(Z)編集",
        body: "電線(Z)を更新出来ませんでした。",
      })),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EquipWirezEdit as any) 