import { 
  NAME_DXF_GRAPH,
  NAME_DXF_GRAPH_H,
  NAME_DXF_GRAPH_L,
  NAME_DXF_SKELETON,
  PS_DASH,
  PS_DASHDOT,
  PS_DASHDOTDOT,
  PS_DOT,
  PS_SOLID,
  RS_KIND_FUSE
} from "../../models/Constants"
import * as EKind from "../../models/ElementKind";
import { exportDXF } from "../../utils/downloadFile"
import { isOkSetCurveInfo } from "../../utils/infoRead";
import { doGetTextOpeMode } from "../../utils/mssv3Doc";
import { DLLGetTextByBeams } from "./dataConverter"
import { BeamsNumber } from "../../utils/FormatNumber";
import { CdPoint, OneCurve } from "../../models/Index";
import { doCalcSetNominal, doConvertLinkLinePoint, doGetRefV1Left } from "../../utils/Adjust";
import { doConvertNominal, doConvertNominalX, doConvertNominalY } from "../../utils/Graph";
import { MS_MODE_CALCULATION } from "../../statics";
import { isPointFaultHVCB } from "../../utils/HVCBFunction";
import { isPointFaultZeroCond } from "../../utils/ZeroCondFunction";
import { doGetIksymTotalMG, doGetTextFaultDirect } from "../../utils/calcPointFunction";
import { doGetRefV2 as doGetRefV2Source } from "../../utils/source";
import { doGetRefV2 as doGetRefV2HVCB } from "../../utils/HVCBFunction";
import { doGetRefV2 as doGetRefV2FUSE } from "../../utils/FUSEFunction";
import { doGetRefV2 as doGetRefV22E } from "../../utils/2EFunction";
import { doGetRefV2 as doGetRefV2LVCB } from "../../utils/LVCBFunction";
import { doGetRefV2 as doGetRefV2Thermal } from "../../utils/THERMALFunction";
import { doGetRefV2 as doGetRefV2ThermalCT } from "../../utils/ThermalCTFunction";
import { doGetRefV2_0 as doGetRefV2_0Trans3, doGetRefV2_1 as doGetRefV2_1Trans3 } from "../../utils/Trans3Function";
import { doGetRefV2_0 as doGetRefV2_03Winding, doGetRefV2_1 as doGetRefV2_13Winding } from "../../utils/3windingFunction";
import { doGetRefV2_0 as doGetRefV2_0Trans1, doGetRefV2_1 as doGetRefV2_1Trans1 } from "../../utils/Trans1Function";
import { doGetRefV2_0 as doGetRefV2_0TransScott, doGetRefV2_1 as doGetRefV2_1TransScott } from "../../utils/transscottFunction";
import { doGetRefV2_0 as doGetRefV2_0Motor, doGetRefV2_1 as doGetRefV2_1Motor } from "../../utils/motorFunction";
import { doGetRefV2 as doGetRefV2Capacitor } from "../../utils/capacitorFunction";

const DXFSkeleton = require("../../assets/DXFSkeleton.dxf")
const DXFGraphL = require("../../assets/DXFGraphL.dxf")
const DXFGraphH = require("../../assets/DXFGraphH.dxf")
const DXFGraph = require("../../assets/DXFGraph.dxf")

const DXF_LAYER_TITLE = 4
const DXF_LAYER_ADJUST = 5
const DXF_LAYER_POINT = 6
const DXF_LAYER_INRUSH = 7
const DXF_LAYER_INTENSITY = 8
const DXF_LAYER_USER = 9
const DXF_LAYER_TEXTBOX = 10

export const doDxfOutToFile = async (
  CDXFFile2: any,
  baseFileName: string,
  saveFile: string,
  params: any
) => {
  let bEntities = false;
  let bHandling = false;
  let sOneLineCode = "";
  let sOneLineValue = "";

  let baseFileContent = await readBaseFile(baseFileName)
  let newFileContent: any[] = []
  if (baseFileContent !== ""){
    const baseFileContentArr = baseFileContent.split("\r\n")
    for (let i = 0; i < baseFileContentArr.length - 1; i += 2) {
      if (i + 1 >= baseFileContentArr.length) {
        break;
      }
      sOneLineCode = baseFileContentArr[i]
      sOneLineValue = baseFileContentArr[i+1]
      if (bEntities == true) {
        if (sOneLineValue.trim() === "ENDSEC") {
          let params2 = {
            pObjGraph: params.pObjGraph,
            CDXFFile2: CDXFFile2,
            listTextGraph: params.listTextGraph,
            chartData: params.chartData,
            pDoc: {
              m_listGroup: [...params.diagramData],
              m_InfoCircuit: params.infoCircuit,
              m_nOpeMode: params.projectData.opeMode,
              m_nDropMode: params.projectData.dropMode,
              originalListGroup: [...params.diagramData],
            },
          }
          newFileContent = newFileContent.concat(doDxfOutCurveGroupAll(params2));
        }
      }
      else if (bHandling == true) {
        sOneLineValue = "     0";
        bHandling = false;
      }
      else {
        if (sOneLineValue.trim() === "ENTITIES") {
          bEntities = true;
        }
        else if (sOneLineValue.trim() === "$HANDLING") {
          bHandling = true;
        }
      }
      newFileContent.push(sOneLineCode);
      newFileContent.push(sOneLineValue);
    }
  }
  
  exportDXF(newFileContent.join("\r\n"), saveFile)
}

const doDxfOutCurveGroupAll = (params: any) => {
  let retArr: any[] = [];

  let pDoc = params.pDoc;
  let pObjGraph = params.pObjGraph;
  let CDXFFile2 = params.CDXFFile2;
  let listTextGraph: any[] = params.listTextGraph || [];
  let chartData = structuredClone(params.chartData);

  let nCurveCount = chartData.length;

  if (nCurveCount > 0) {
    chartData.forEach((pObjElement: any) => {
      retArr = retArr.concat(doDxfOutCurveElement(CDXFFile2, pDoc, pObjElement, pObjGraph));
    })
  }

  if (listTextGraph.length > 0) {
    listTextGraph.forEach((textGraph: any) => {
      let sRefV2 = textGraph.text.split("\n");
      let pObjRefV2 = convertRefV2TextGraph(textGraph)
      retArr = retArr.concat(doDxfOutRefV2(CDXFFile2, pObjRefV2, DXF_LAYER_TEXTBOX, pObjGraph, sRefV2));
    })
  }

  retArr = retArr.concat(STRING(DXF_LAYER_TITLE, 20 + CDXFFile2.m_dOffsetX, 103, 5, pObjGraph.figTitle));
  retArr = retArr.concat(STRING(DXF_LAYER_TITLE, 70 + CDXFFile2.m_dOffsetX, 103, 3, pObjGraph.figSubTitle));

  retArr = retArr.concat(STRING(DXF_LAYER_TITLE, 19 + CDXFFile2.m_dOffsetX, 96, 4, pDoc.m_InfoCircuit.titleCorporation));
  retArr = retArr.concat(STRING(DXF_LAYER_TITLE, 39 + CDXFFile2.m_dOffsetX, 96, 4, pDoc.m_InfoCircuit.corporation));
  retArr = retArr.concat(STRING(DXF_LAYER_TITLE, 19 + CDXFFile2.m_dOffsetX, 89, 4, pDoc.m_InfoCircuit.titleProject));
  retArr = retArr.concat(STRING(DXF_LAYER_TITLE, 39 + CDXFFile2.m_dOffsetX, 89, 4, pDoc.m_InfoCircuit.project));

  retArr = retArr.concat(STRING(DXF_LAYER_TITLE, 118 + CDXFFile2.m_dOffsetX, 89, 4, pObjGraph.titleDrawn));
  retArr = retArr.concat(STRING(DXF_LAYER_TITLE, 129 + CDXFFile2.m_dOffsetX, 89, 4, pObjGraph.drawner));
  retArr = retArr.concat(STRING(DXF_LAYER_TITLE, 179 + CDXFFile2.m_dOffsetX, 89, 4, pObjGraph.titleDateDrawn));
  retArr = retArr.concat(STRING(DXF_LAYER_TITLE, 190 + CDXFFile2.m_dOffsetX, 89, 4, pObjGraph.dateDrawn));

  retArr = retArr.concat(STRING(DXF_LAYER_TITLE, 19 + CDXFFile2.m_dOffsetX, 82, 4, pObjGraph.titleReference));
  retArr = retArr.concat(STRING(DXF_LAYER_TITLE, 39 + CDXFFile2.m_dOffsetX, 82, 4, pObjGraph.reference));
  retArr = retArr.concat(STRING(DXF_LAYER_TITLE, 118 + CDXFFile2.m_dOffsetX, 82, 4, pObjGraph.titleChecked));
  retArr = retArr.concat(STRING(DXF_LAYER_TITLE, 129 + CDXFFile2.m_dOffsetX, 82, 4, pObjGraph.checker));
  retArr = retArr.concat(STRING(DXF_LAYER_TITLE, 179 + CDXFFile2.m_dOffsetX, 82, 4, pObjGraph.titleDateChecked));
  retArr = retArr.concat(STRING(DXF_LAYER_TITLE, 190 + CDXFFile2.m_dOffsetX, 82, 4, pObjGraph.dateChecked));

  retArr = retArr.concat(STRING(DXF_LAYER_TITLE, 19 + CDXFFile2.m_dOffsetX, 75, 4, pObjGraph.titleNumber));
  retArr = retArr.concat(STRING(DXF_LAYER_TITLE, 39 + CDXFFile2.m_dOffsetX, 75, 4, pObjGraph.figNumber));
  retArr = retArr.concat(STRING(DXF_LAYER_TITLE, 118 + CDXFFile2.m_dOffsetX, 75, 4, pObjGraph.titleDesigned));
  retArr = retArr.concat(STRING(DXF_LAYER_TITLE, 129 + CDXFFile2.m_dOffsetX, 75, 4, pObjGraph.designer));
  retArr = retArr.concat(STRING(DXF_LAYER_TITLE, 179 + CDXFFile2.m_dOffsetX, 75, 4, pObjGraph.titleDateDesigned));
  retArr = retArr.concat(STRING(DXF_LAYER_TITLE, 190 + CDXFFile2.m_dOffsetX, 75, 4, pObjGraph.dateDesigned));

  retArr = retArr.concat(STRING(DXF_LAYER_TITLE, 39 + CDXFFile2.m_dOffsetX, 68, 4, doGetTextOpeMode(pDoc.m_nOpeMode, pDoc.m_nDropMode, pDoc.m_listGroup)));
  retArr = retArr.concat(STRING(DXF_LAYER_TITLE, 118 + CDXFFile2.m_dOffsetX, 68, 4, pObjGraph.titleApproved));
  retArr = retArr.concat(STRING(DXF_LAYER_TITLE, 129 + CDXFFile2.m_dOffsetX, 68, 4, pObjGraph.approver));
  retArr = retArr.concat(STRING(DXF_LAYER_TITLE, 179 + CDXFFile2.m_dOffsetX, 68, 4, pObjGraph.titleDateApproved));
  retArr = retArr.concat(STRING(DXF_LAYER_TITLE, 190 + CDXFFile2.m_dOffsetX, 68, 4, pObjGraph.dateApproved));

  retArr = retArr.concat(STRING(DXF_LAYER_TITLE, 39 + CDXFFile2.m_dOffsetX, 61, 4, pObjGraph.comments));

  return retArr;
}

const doDxfOutCurveElement = (CDXFFile2: any, pDoc: any, pElement: any, pObjGraph: any) => {
  let retArr: any[] = [];
  switch(pElement.type) {
    case EKind.MS_SOURCE:
      {
        let pSource = {...pElement}
        retArr = doDxfOutCurveSource(CDXFFile2, pSource, pObjGraph);
      }
      break;
    case EKind.MS_HVCB:
      {
        let pHVCB = {...pElement}
        retArr = doDxfOutCurveHVCB(CDXFFile2, pHVCB, pObjGraph, pDoc.m_nOpeMode);
      }
      break;
    case EKind.MS_FUSE:
      {
        let pFuse = {...pElement}
        retArr = doDxfOutCurveFuse(CDXFFile2, pFuse, pObjGraph, pDoc.m_nOpeMode);
      }
      break;
    case EKind.MS_2E:
      {
        let p2E = {...pElement}
        retArr = doDxfOutCurve2E(CDXFFile2, p2E, pObjGraph, pDoc.m_nOpeMode);
      }
      break;
    case EKind.MS_LVCB:
      {
        let pLVCB = {...pElement}
        retArr = doDxfOutCurveLVCB(CDXFFile2, pLVCB, pObjGraph, pDoc.m_nOpeMode);
      }
      break;
    case EKind.MS_THERMAL:
      {
        let pThermal = {...pElement}
        retArr = doDxfOutCurveThermal(CDXFFile2, pThermal, pObjGraph, pDoc.m_nOpeMode);
      }
      break;
    case EKind.MS_THERMAL_CT:
      {
        let pThermalCT = {...pElement}
        retArr = doDxfOutCurveThermalCT(CDXFFile2, pThermalCT, pObjGraph, pDoc.m_nOpeMode);
      }
      break;
    case EKind.MS_GENERATOR:
      {
        let pGenerator = {...pElement}
        retArr = doDxfOutCurveGenerator(CDXFFile2, pGenerator, pObjGraph, pDoc.m_nOpeMode);
      }
      break;
    case EKind.MS_TRANS3:
      {
        let pTrans3 = {...pElement}
        retArr = doDxfOutCurveTrans3(CDXFFile2, pTrans3, pObjGraph, pDoc.m_nOpeMode);
      }
      break;
    case EKind.MS_3WINDING:
      {
        let p3Winding = {...pElement}
        retArr = doDxfOutCurve3Winding(CDXFFile2, p3Winding, pObjGraph, pDoc.m_nOpeMode);
      }
      break;
    case EKind.MS_TRANS1:
      {
        let pTrans1 = {...pElement}
        retArr = doDxfOutCurveTrans1(CDXFFile2, pTrans1, pObjGraph, pDoc.m_nOpeMode);
      }
      break;
    case EKind.MS_TRANSSCOTT:
      {
        let pTransScott = {...pElement}
        retArr = doDxfOutCurveTransScott(CDXFFile2, pTransScott, pObjGraph, pDoc.m_nOpeMode);
      }
      break;
    case EKind.MS_MOTOR:
      {
        let pMotor = {...pElement}
        retArr = doDxfOutCurveMotor(CDXFFile2, pMotor, pObjGraph);
      }
      break;
    case EKind.MS_CAPACITOR:
      {
        let pCapacitor = {...pElement}
        retArr = doDxfOutCurveCapacitor(CDXFFile2, pCapacitor, pObjGraph);
      }
      break;
    case EKind.MS_DS:
      {
        let pDS = {...pElement}
        retArr = doDxfOutCurveDS(CDXFFile2, pDS, pObjGraph, pDoc.m_nOpeMode);
      }
      break;
    case EKind.MS_LBS:
      {
        let pLBS = {...pElement}
        retArr = doDxfOutCurveLBS(CDXFFile2, pLBS, pObjGraph, pDoc.m_nOpeMode);
      }
      break;
    case EKind.MS_MVCB:
      {
        let pMVCB = {...pElement}
        retArr = doDxfOutCurveMVCB(CDXFFile2, pMVCB, pObjGraph, pDoc.m_nOpeMode);
      }
      break;
    case EKind.MS_CONTACTOR:
      {
        let pContactor = {...pElement}
        retArr = doDxfOutCurveContactor(CDXFFile2, pContactor, pObjGraph, pDoc.m_nOpeMode);
      }
      break;
    case EKind.MS_RELAY51:
      {
        // let pRelay51 = {...pElement}
        // retArr = doDxfOutCurveRelay51(pDoc, pRelay51, pObjGraph, pDoc.m_nOpeMode);
      }
      break;
    case EKind.MS_CONNECT:
      break;
    case EKind.MS_ZEROCOND:
      {
        let pZeroCond = {...pElement}
        retArr = doDxfOutCurveZeroCond(CDXFFile2, pZeroCond, pObjGraph, pDoc.m_nOpeMode);
      }
      break;
    case EKind.MS_WIRE:
      {
        let pWire = {...pElement}
        retArr = doDxfOutCurveWire(CDXFFile2, pWire, pObjGraph, pDoc.m_nOpeMode);
      }
      break;
    case EKind.MS_BUSBAR:
      {
        let pBusBar = {...pElement}
        retArr = doDxfOutCurveBusBar(CDXFFile2, pBusBar, pObjGraph, pDoc.m_nOpeMode);
      }
      break;
    case EKind.MS_IMPEDANCE:
      break;
    case EKind.MS_BUSDUCT:
      {
        let pBusDuct = {...pElement}
        retArr = doDxfOutCurveBusDuct(CDXFFile2, pBusDuct, pObjGraph, pDoc.m_nOpeMode);
      }
      break;
    case EKind.MS_REACTOR:
      {
        let pReactor = {...pElement}
        retArr = doDxfOutCurveReactor(CDXFFile2, pReactor, pObjGraph, pDoc.m_nOpeMode);
      }
      break;
    case EKind.MS_MOTOR_GROUP:
      {
        let pMotorGrp = {...pElement}
        retArr = doDxfOutCurveMotorGrp(CDXFFile2, pMotorGrp, pObjGraph, pDoc.m_nOpeMode);
      }
      break;
    case EKind.MS_LOAD:
      {
        let pLoad = {...pElement}
        retArr = doDxfOutCurveLoad(CDXFFile2, pLoad, pObjGraph);
      }
      break;
    case EKind.MS_LOADCENTER:
      {
        let pLoadCenter = {...pElement}
        retArr = doDxfOutCurveLoadCenter(CDXFFile2, pLoadCenter, pObjGraph);
      }
      break;
    case EKind.MS_VCT:
      {
        let pVCT = {...pElement}
        retArr = doDxfOutCurveVCT(CDXFFile2, pVCT, pObjGraph);
      }
      break;
    case EKind.MS_INV:
      {
        let pINV = {...pElement}
        retArr = doDxfOutCurveINV(CDXFFile2, pINV, pObjGraph);
      }
      break;
    case EKind.MS_RELAY:
      {
        let pRELAY = {...pElement}
        retArr = doDxfOutCurveRELAY(CDXFFile2, pRELAY, pObjGraph);
      }
      break;
    case EKind.MS_ARRESTOR:
      {
        let pArrestor = {...pElement}
        retArr = doDxfOutCurveArrestor(CDXFFile2, pArrestor, pObjGraph);
      }
      break;
    case EKind.MS_EARTH:
      break;
    case EKind.MS_VT:
      {
        let pVT = {...pElement}
        retArr = doDxfOutCurveVT(CDXFFile2, pVT, pObjGraph);
      }
      break;
    case EKind.MS_CABLEHEAD:
      break;
    case EKind.MS_CT:
      {
        let pCT = {...pElement}
        retArr = doDxfOutCurveCT(CDXFFile2, pCT, pObjGraph);
      }
      break;
    case EKind.MS_ZCT:
      {
        let pZCT = {...pElement}
        retArr = doDxfOutCurveZCT(CDXFFile2, pZCT, pObjGraph);
      }
      break;
    case EKind.MS_TEXT:
      break;
  }
  return retArr;
}

// #region All Element
const doDxfOutCurveSource = (CDXFFile2: any, pSource: any, pObjGraph: any) => {
  let retArr: any[] = [];
  let pObjAdjust = pSource.properties.details;
  if (pObjAdjust != null) {
    if (isOkSetCurveInfo(pObjAdjust)) {
      let sRefV2 = doGetRefV2Source(pSource.properties);
      let tmpControl = getControlCurve(pSource);
      retArr = doDxfOutCurveAdjustAll(
        CDXFFile2, tmpControl, pObjGraph, sRefV2
      );
    }
  }
  
  retArr = retArr.concat(doDxfOutCurveUserAll(CDXFFile2, pSource, pObjGraph));

  return retArr;
}

const doDxfOutCurveHVCB = (CDXFFile2: any, pHVCB: any, pObjGraph: any, nOpeMode: number) => {
  let retArr: any[] = [];

  let pObjAdjust = pHVCB.properties.details;
  if (pObjAdjust != null) {
    if (isOkSetCurveInfo(pObjAdjust) == true) {
      let sRefV2 = doGetRefV2HVCB(pHVCB.properties);
      let tmpControl = getControlCurve(pHVCB);
      retArr = doDxfOutCurveAdjustAll(CDXFFile2, tmpControl, pObjGraph, sRefV2);
    }
  }

  if (nOpeMode >= MS_MODE_CALCULATION &&
    isPointFaultHVCB(pHVCB.properties) == true
  ) {
    let refV1Left = doGetRefV1Left(pHVCB.properties, pObjGraph.society);
    let m_listdPoint0 = doGetListPointCalcPoint(pHVCB.chart, 0);
    let m_listdPoint1 = doGetListPointCalcPoint(pHVCB.chart, 1);
    pHVCB.properties.calcPoint0.m_listdPoint = m_listdPoint0;
    pHVCB.properties.calcPoint1.m_listdPoint = m_listdPoint1;
    retArr = retArr.concat(doDxfOutCurvePoint(
      CDXFFile2, pHVCB.properties.calcPoint0, refV1Left, pObjGraph
    ));
    retArr = retArr.concat(doDxfOutCurvePoint(
      CDXFFile2, pHVCB.properties.calcPoint1, refV1Left, pObjGraph
    ));
  }
  
  retArr = retArr.concat(doDxfOutCurveUserAll(CDXFFile2, pHVCB, pObjGraph));
  
  return retArr;
}

const doDxfOutCurveFuse = (CDXFFile2: any, pFuse: any, pObjGraph: any, nOpeMode: number) => {
  let retArr: any[] = [];

  let pObjAdjust = pFuse.properties.details;
  if (pObjAdjust != null) {
    if (isOkSetCurveInfo(pObjAdjust) == true) {
      let sRefV2 = doGetRefV2FUSE(pFuse.properties);
      let tmpControl = getControlCurve(pFuse);
      retArr = doDxfOutCurveAdjustAll(CDXFFile2, tmpControl, pObjGraph, sRefV2);
    }
  }

  if (nOpeMode >= MS_MODE_CALCULATION &&
    isPointFaultHVCB(pFuse.properties) == true
  ) {
    let refV1Left = doGetRefV1Left(pFuse.properties, pObjGraph.society);
    let m_listdPoint0 = doGetListPointCalcPoint(pFuse.chart, 0);
    let m_listdPoint1 = doGetListPointCalcPoint(pFuse.chart, 1);
    pFuse.properties.calcPoint0.m_listdPoint = m_listdPoint0;
    pFuse.properties.calcPoint1.m_listdPoint = m_listdPoint1;
    retArr = retArr.concat(doDxfOutCurvePoint(
      CDXFFile2, pFuse.properties.calcPoint0, refV1Left, pObjGraph
    ));
    retArr = retArr.concat(doDxfOutCurvePoint(
      CDXFFile2, pFuse.properties.calcPoint1, refV1Left, pObjGraph
    ));
  }
  
  retArr = retArr.concat(doDxfOutCurveUserAll(CDXFFile2, pFuse, pObjGraph));

  return retArr;
}

const doDxfOutCurve2E = (CDXFFile2: any, p2E: any, pObjGraph: any, nOpeMode: number) => {
  let retArr: any[] = [];

  let pObjAdjust = p2E.properties.details;
  if (pObjAdjust != null) {
    if (isOkSetCurveInfo(pObjAdjust) == true) {
      let sRefV2 = doGetRefV22E(p2E.properties);
      let tmpControl = getControlCurve(p2E);
      retArr = doDxfOutCurveAdjustAll(CDXFFile2, tmpControl, pObjGraph, sRefV2);
    }
  }

  if (nOpeMode >= MS_MODE_CALCULATION &&
    isPointFaultHVCB(p2E.properties) == true
  ) {
    let refV1Left = doGetRefV1Left(p2E.properties, pObjGraph.society);
    let m_listdPoint0 = doGetListPointCalcPoint(p2E.chart, 0);
    let m_listdPoint1 = doGetListPointCalcPoint(p2E.chart, 1);
    p2E.properties.calcPoint0.m_listdPoint = m_listdPoint0;
    p2E.properties.calcPoint1.m_listdPoint = m_listdPoint1;
    retArr = retArr.concat(doDxfOutCurvePoint(
      CDXFFile2, p2E.properties.calcPoint0, refV1Left, pObjGraph
    ));
    retArr = retArr.concat(doDxfOutCurvePoint(
      CDXFFile2, p2E.properties.calcPoint1, refV1Left, pObjGraph
    ));
  }
  
  retArr = retArr.concat(doDxfOutCurveUserAll(CDXFFile2, p2E, pObjGraph));

  return retArr;
}

const doDxfOutCurveLVCB = (CDXFFile2: any, pLVCB: any, pObjGraph: any, nOpeMode: number) => {
  let retArr: any[] = [];

  let pObjAdjust = pLVCB.properties.details;
  if (pObjAdjust != null) {
    if (isOkSetCurveInfo(pObjAdjust) == true) {
      let sRefV2 = doGetRefV2LVCB(pLVCB.properties);
      let tmpControl = getControlCurve(pLVCB);
      retArr = doDxfOutCurveAdjustAll(CDXFFile2, tmpControl, pObjGraph, sRefV2);
    }
  }

  if (nOpeMode >= MS_MODE_CALCULATION &&
    isPointFaultHVCB(pLVCB.properties) == true
  ) {
    let refV1Left = [pLVCB.properties.refNo];
    let m_listdPoint0 = doGetListPointCalcPoint(pLVCB.chart, 0);
    let m_listdPoint1 = doGetListPointCalcPoint(pLVCB.chart, 1);
    pLVCB.properties.calcPoint0.m_listdPoint = m_listdPoint0;
    pLVCB.properties.calcPoint1.m_listdPoint = m_listdPoint1;
    retArr = retArr.concat(doDxfOutCurvePoint(
      CDXFFile2, pLVCB.properties.calcPoint0, refV1Left, pObjGraph
    ));
    retArr = retArr.concat(doDxfOutCurvePoint(
      CDXFFile2, pLVCB.properties.calcPoint1, refV1Left, pObjGraph
    ));
  }
  
  retArr = retArr.concat(doDxfOutCurveUserAll(CDXFFile2, pLVCB, pObjGraph));

  return retArr;
}

const doDxfOutCurveThermal = (CDXFFile2: any, pThermal: any, pObjGraph: any, nOpeMode: number) => {
  let retArr: any[] = [];

  let pObjAdjust = pThermal.properties.details;
  if (pObjAdjust != null) {
    if (isOkSetCurveInfo(pObjAdjust) == true) {
      let sRefV2 = doGetRefV2Thermal(pThermal.properties);
      let tmpControl = getControlCurve(pThermal);
      retArr = doDxfOutCurveAdjustAll(CDXFFile2, tmpControl, pObjGraph, sRefV2);
    }
  }

  if (nOpeMode >= MS_MODE_CALCULATION &&
    isPointFaultHVCB(pThermal.properties) == true
  ) {
    let refV1Left = doGetRefV1Left(pThermal.properties, pObjGraph.society);
    let m_listdPoint0 = doGetListPointCalcPoint(pThermal.chart, 0);
    let m_listdPoint1 = doGetListPointCalcPoint(pThermal.chart, 1);
    pThermal.properties.calcPoint0.m_listdPoint = m_listdPoint0;
    pThermal.properties.calcPoint1.m_listdPoint = m_listdPoint1;
    retArr = retArr.concat(doDxfOutCurvePoint(
      CDXFFile2, pThermal.properties.calcPoint0, refV1Left, pObjGraph
    ));
    retArr = retArr.concat(doDxfOutCurvePoint(
      CDXFFile2, pThermal.properties.calcPoint1, refV1Left, pObjGraph
    ));
  }
  
  retArr = retArr.concat(doDxfOutCurveUserAll(CDXFFile2, pThermal, pObjGraph));

  return retArr;
}

const doDxfOutCurveThermalCT = (CDXFFile2: any, pThermalCT: any, pObjGraph: any, nOpeMode: number) => {
  let retArr: any[] = [];

  let pObjAdjust = pThermalCT.properties.details;
  if (pObjAdjust != null) {
    if (isOkSetCurveInfo(pObjAdjust) == true) {
      let sRefV2 = doGetRefV2ThermalCT(pThermalCT.properties);
      let tmpControl = getControlCurve(pThermalCT);
      retArr = doDxfOutCurveAdjustAll(CDXFFile2, tmpControl, pObjGraph, sRefV2);
    }
  }

  if (nOpeMode >= MS_MODE_CALCULATION &&
    isPointFaultHVCB(pThermalCT.properties) == true
  ) {
    let refV1Left = doGetRefV1Left(pThermalCT.properties, pObjGraph.society);
    let m_listdPoint0 = doGetListPointCalcPoint(pThermalCT.chart, 0);
    let m_listdPoint1 = doGetListPointCalcPoint(pThermalCT.chart, 1);
    pThermalCT.properties.calcPoint0.m_listdPoint = m_listdPoint0;
    pThermalCT.properties.calcPoint1.m_listdPoint = m_listdPoint1;
    retArr = retArr.concat(doDxfOutCurvePoint(
      CDXFFile2, pThermalCT.properties.calcPoint0, refV1Left, pObjGraph
    ));
    retArr = retArr.concat(doDxfOutCurvePoint(
      CDXFFile2, pThermalCT.properties.calcPoint1, refV1Left, pObjGraph
    ));
  }
  
  retArr = retArr.concat(doDxfOutCurveUserAll(CDXFFile2, pThermalCT, pObjGraph));

  return retArr;
}

const doDxfOutCurveGenerator = (CDXFFile2: any, pGenerator: any, pObjGraph: any, nOpeMode: number) => {
  let retArr: any[] = [];

  if (nOpeMode >= MS_MODE_CALCULATION &&
    isPointFaultHVCB(pGenerator.properties) == true
  ) {
    let refV1Left = [pGenerator.properties.refNo];
    let m_listdPoint0 = doGetListPointCalcPoint(pGenerator.chart, 0);
    let m_listdPoint1 = doGetListPointCalcPoint(pGenerator.chart, 1);
    pGenerator.properties.calcPoint0.m_listdPoint = m_listdPoint0;
    pGenerator.properties.calcPoint1.m_listdPoint = m_listdPoint1;
    retArr = retArr.concat(doDxfOutCurvePoint(
      CDXFFile2, pGenerator.properties.calcPoint0, refV1Left, pObjGraph
    ));
    retArr = retArr.concat(doDxfOutCurvePoint(
      CDXFFile2, pGenerator.properties.calcPoint1, refV1Left, pObjGraph
    ));
  }
  
  retArr = retArr.concat(doDxfOutCurveUserAll(CDXFFile2, pGenerator, pObjGraph));

  return retArr;
}

const doDxfOutCurveTrans3 = (CDXFFile2: any, pTrans3: any, pObjGraph: any, nOpeMode: number) => {
  let retArr: any[] = [];
  doConvertListPointTrans3(pTrans3);
  let m_listRefV2 = [convertRefV2(pTrans3.m_listDrawInrush.tabMapping, pObjGraph.graphNo)];
  m_listRefV2.push(convertRefV2(pTrans3.m_listDrawIntensity.tabMapping, pObjGraph.graphNo));

  if (pTrans3.properties.dispInrush == true && pTrans3.m_listDrawInrush.data.length > 0) {
    let InfoLine = convertInfoLine(pTrans3.m_listDrawInrush.tabMapping, pObjGraph.graphNo);

    // doMakeCurveInrushTrans3(pTrans3, pObjGraph);
    doConvertNominal(pObjGraph, pTrans3.m_listDrawInrush.data);
    doConvertLinkLinePoint3WindingANDTrans(pTrans3);
    doSetLineProperty(pTrans3.m_listDrawInrush, InfoLine.m_colorNormal, InfoLine.m_nKind, InfoLine.m_nWidth);
    retArr = retArr.concat(doDxfOutPointAll(CDXFFile2, pTrans3.m_listDrawInrush.data, DXF_LAYER_INRUSH, InfoLine.m_nKind));

    let sRefV2 = doGetRefV2_0Trans3(pTrans3.properties, pObjGraph.nominalVolt);
    retArr = retArr.concat(doDxfOutRefV2All(
    CDXFFile2, m_listRefV2, 0, DXF_LAYER_INRUSH, pObjGraph, sRefV2));

    let pdPoint = pTrans3.m_listDrawInrush.data[0];
    retArr = retArr.concat(doDxfOutLinkLineRefV2All(CDXFFile2, m_listRefV2, 0, pdPoint, pObjGraph, DXF_LAYER_INRUSH, InfoLine.m_nKind));
  }

  if (pTrans3.properties.dispIntensity == true && pTrans3.m_listDrawIntensity.data.length > 0) {
    let InfoLine = convertInfoLine(pTrans3.m_listDrawIntensity.tabMapping, pObjGraph.graphNo);

    // doMakeCurveIntensityTrans3(pTrans3, pObjGraph);
    doConvertNominal(pObjGraph, pTrans3.m_listDrawIntensity.data);
    doConvertLinkLinePoint3WindingANDTrans(pTrans3);
    doSetLineProperty(pTrans3.m_listDrawIntensity, InfoLine.m_colorNormal, InfoLine.m_nKind, InfoLine.m_nWidth);
    retArr = retArr.concat(doDxfOutPointAll(CDXFFile2, pTrans3.m_listDrawIntensity.data, DXF_LAYER_INTENSITY, InfoLine.m_nKind));

    let sRefV2 = doGetRefV2_1Trans3(pTrans3.properties, pObjGraph.nominalVolt);
    retArr = retArr.concat(doDxfOutRefV2All(CDXFFile2, m_listRefV2, 1, DXF_LAYER_INTENSITY, pObjGraph, sRefV2));

    let pdPoint = pTrans3.m_listDrawIntensity.data[0];
    retArr = retArr.concat(doDxfOutLinkLineRefV2All(CDXFFile2, m_listRefV2, 1, pdPoint, pObjGraph, DXF_LAYER_INTENSITY, InfoLine.m_nKind));
  }

  if (nOpeMode >= MS_MODE_CALCULATION &&
    isPointFaultHVCB(pTrans3.properties) == true
  ) {
    let refV1Left = doGetRefV1Left(pTrans3.properties, pObjGraph.society);
    let m_listdPoint0 = doGetListPointCalcPoint(pTrans3.chart, 0);
    let m_listdPoint1 = doGetListPointCalcPoint(pTrans3.chart, 1);
    pTrans3.properties.calcPoint0.m_listdPoint = m_listdPoint0;
    pTrans3.properties.calcPoint1.m_listdPoint = m_listdPoint1;
    retArr = retArr.concat(doDxfOutCurvePoint(
      CDXFFile2, pTrans3.properties.calcPoint0, refV1Left, pObjGraph
    ));
    retArr = retArr.concat(doDxfOutCurvePoint(
      CDXFFile2, pTrans3.properties.calcPoint1, refV1Left, pObjGraph
    ));
  }
  
  retArr = retArr.concat(doDxfOutCurveUserAll(CDXFFile2, pTrans3, pObjGraph));

  return retArr;
}

const doDxfOutCurve3Winding = (CDXFFile2: any, p3Winding: any, pObjGraph: any, nOpeMode: number) => {
  let retArr: any[] = [];
  doConvertListPoint3Winding(p3Winding);
  {// partPrimary
    let pPart = p3Winding.properties.partPrimary;

    if (pPart.m_listDrawInrush && pPart.m_listDrawInrush.data.length > 0) {
      let m_listRefV2 = [convertRefV2(pPart.m_listDrawInrush.tabMapping, pObjGraph.graphNo)]
      retArr = retArr.concat(doDxfOutCurve3WindingInrush(CDXFFile2, pPart, pObjGraph, m_listRefV2));

      let sRefV2 = doGetRefV2_03Winding(p3Winding.properties, pObjGraph.nominalVolt, 1, 1);
      retArr = retArr.concat(doDxfOutRefV2All(CDXFFile2, m_listRefV2, 0, DXF_LAYER_INRUSH, pObjGraph, sRefV2));
    }
    if (pPart.m_listDrawIntensity && pPart.m_listDrawIntensity.data.length > 0) {
      let m_listRefV2 = [convertRefV2(pPart.m_listDrawIntensity.tabMapping, pObjGraph.graphNo)]
      retArr = retArr.concat(doDxfOutCurve3WindingIntensity(CDXFFile2, pPart, pObjGraph, m_listRefV2));

      let sRefV2 = doGetRefV2_13Winding(p3Winding.properties, pObjGraph.nominalVolt, 1, 1);
      retArr = retArr.concat(doDxfOutRefV2All(CDXFFile2, m_listRefV2, 0, DXF_LAYER_INTENSITY, pObjGraph, sRefV2));
    }
    if (nOpeMode >= MS_MODE_CALCULATION &&
      pPart.fault == true) {
      let refV1Left = [p3Winding.properties.refNo];
      retArr = retArr.concat(doDxfOutCurvePoint(CDXFFile2, pPart.calcPoint0, refV1Left, pObjGraph));
      retArr = retArr.concat(doDxfOutCurvePoint(CDXFFile2, pPart.calcPoint1, refV1Left, pObjGraph));
    }
  }
  {// partSecondary
    let pPart = p3Winding.properties.partSecondary;

    if (pPart.m_listDrawInrush && pPart.m_listDrawInrush.data.length > 0) {
      let m_listRefV2 = [convertRefV2(pPart.m_listDrawInrush.tabMapping, pObjGraph.graphNo)]
      retArr = retArr.concat(doDxfOutCurve3WindingInrush(CDXFFile2, pPart, pObjGraph, m_listRefV2));

      let sRefV2 = doGetRefV2_03Winding(p3Winding.properties, pObjGraph.nominalVolt, 2, 2);
      retArr = retArr.concat(doDxfOutRefV2All(CDXFFile2, m_listRefV2, 0, DXF_LAYER_INRUSH, pObjGraph, sRefV2));
    }
    if (pPart.m_listDrawIntensity && pPart.m_listDrawIntensity.data.length > 0) {
      let m_listRefV2 = [convertRefV2(pPart.m_listDrawIntensity.tabMapping, pObjGraph.graphNo)]
      retArr = retArr.concat(doDxfOutCurve3WindingIntensity(CDXFFile2, pPart, pObjGraph, m_listRefV2));

      let sRefV2 = doGetRefV2_13Winding(p3Winding.properties, pObjGraph.nominalVolt, 2, 2);
      retArr = retArr.concat(doDxfOutRefV2All(CDXFFile2, m_listRefV2, 0, DXF_LAYER_INTENSITY, pObjGraph, sRefV2));
    }
    if (nOpeMode >= MS_MODE_CALCULATION &&
      pPart.fault == true) {
      let refV1Left = [p3Winding.properties.refNo];
      retArr = retArr.concat(doDxfOutCurvePoint(CDXFFile2, pPart.calcPoint0, refV1Left, pObjGraph));
      retArr = retArr.concat(doDxfOutCurvePoint(CDXFFile2, pPart.calcPoint1, refV1Left, pObjGraph));
    }
  }
  {// partThird
    let pPart = p3Winding.properties.partThird;

    if (pPart.m_listDrawInrush && pPart.m_listDrawInrush.data.length > 0) {
      let m_listRefV2 = [convertRefV2(pPart.m_listDrawInrush.tabMapping, pObjGraph.graphNo)]
      retArr = retArr.concat(doDxfOutCurve3WindingInrush(CDXFFile2, pPart, pObjGraph, m_listRefV2));

      let sRefV2 = doGetRefV2_03Winding(p3Winding.properties, pObjGraph.nominalVolt, 3, 3);
      retArr = retArr.concat(doDxfOutRefV2All(CDXFFile2, m_listRefV2, 0, DXF_LAYER_INRUSH, pObjGraph, sRefV2));
    }
    if (pPart.m_listDrawIntensity && pPart.m_listDrawIntensity.data.length > 0) {
      let m_listRefV2 = [convertRefV2(pPart.m_listDrawIntensity.tabMapping, pObjGraph.graphNo)]
      retArr = retArr.concat(doDxfOutCurve3WindingIntensity(CDXFFile2, pPart, pObjGraph, m_listRefV2));

      let sRefV2 = doGetRefV2_13Winding(p3Winding.properties, pObjGraph.nominalVolt, 3, 3);
      retArr = retArr.concat(doDxfOutRefV2All(CDXFFile2, m_listRefV2, 0, DXF_LAYER_INTENSITY, pObjGraph, sRefV2));
    }
    if (nOpeMode >= MS_MODE_CALCULATION &&
      pPart.fault == true) {
      let refV1Left = [p3Winding.properties.refNo];
      retArr = retArr.concat(doDxfOutCurvePoint(CDXFFile2, pPart.calcPoint0, refV1Left, pObjGraph));
      retArr = retArr.concat(doDxfOutCurvePoint(CDXFFile2, pPart.calcPoint1, refV1Left, pObjGraph));
    }
  }

  retArr = retArr.concat(doDxfOutCurveUserAll(CDXFFile2, p3Winding, pObjGraph));

  return retArr;
}

const doDxfOutCurve3WindingInrush = (CDXFFile2: any, pPart: any, pObjGraph: any, m_listRefV2: any[]) => {
  let retArr: any[] = [];
  
  let InfoLine = convertInfoLine(pPart.m_listDrawInrush.tabMapping, pObjGraph.graphNo);
  doConvertNominal(pObjGraph, pPart.m_listDrawInrush.data);
  doConvertLinkLinePoint3WindingANDTrans(pPart);
  doSetLineProperty(pPart.m_listDrawInrush, InfoLine.m_colorNormal, InfoLine.m_nKind, InfoLine.m_nWidth);
  retArr = doDxfOutPointAll(CDXFFile2, pPart.m_listDrawInrush.data, DXF_LAYER_INRUSH, InfoLine.m_nKind);

  let pdPoint = pPart.m_listDrawInrush.data[0];
  retArr = retArr.concat(doDxfOutLinkLineRefV2All(CDXFFile2, m_listRefV2, 0, pdPoint, pObjGraph, DXF_LAYER_INRUSH, InfoLine.m_nKind));
  
  return retArr;
}

const doDxfOutCurve3WindingIntensity = (CDXFFile2: any, pPart: any, pObjGraph: any, m_listRefV2: any[]) => {
  let retArr: any[] = [];
  
  let InfoLine = convertInfoLine(pPart.m_listDrawIntensity.tabMapping, pObjGraph.graphNo);
  doConvertNominal(pObjGraph, pPart.m_listDrawIntensity.data);
  doConvertLinkLinePoint3WindingANDTrans(pPart);
  doSetLineProperty(pPart.m_listDrawIntensity, InfoLine.m_colorNormal, InfoLine.m_nKind, InfoLine.m_nWidth);
  retArr = doDxfOutPointAll(CDXFFile2, pPart.m_listDrawIntensity.data, DXF_LAYER_INTENSITY, InfoLine.m_nKind);

  let pdPoint = pPart.m_listDrawIntensity.data[0];
  retArr = retArr.concat(doDxfOutLinkLineRefV2All(CDXFFile2, m_listRefV2, 0, pdPoint, pObjGraph, DXF_LAYER_INTENSITY, InfoLine.m_nKind));
  
  return retArr;
}

const doDxfOutCurveTrans1 = (CDXFFile2: any, pTrans1: any, pObjGraph: any, nOpeMode: number) => {
  let retArr: any[] = [];
  doConvertListPointTrans1(pTrans1);
  {// partOutIn
    let pPart = pTrans1.properties.partOutIn;

    if (pPart.m_listDrawInrush && pPart.m_listDrawInrush.data.length > 0) {
      let m_listRefV2 = [convertRefV2(pPart.m_listDrawInrush.tabMapping, pObjGraph.graphNo)]
      retArr = retArr.concat(doDxfOutCurveTrans1Inrush(CDXFFile2, pPart, pObjGraph, m_listRefV2));

      let sRefV2 = doGetRefV2_0Trans1(pTrans1.properties, pObjGraph.nominalVolt, 1);
      retArr = retArr.concat(doDxfOutRefV2All(CDXFFile2, m_listRefV2, 0, DXF_LAYER_INRUSH, pObjGraph, sRefV2));
    }
    if (pPart.m_listDrawIntensity && pPart.m_listDrawIntensity.data.length > 0) {
      let m_listRefV2 = [convertRefV2(pPart.m_listDrawIntensity.tabMapping, pObjGraph.graphNo)]
      retArr = retArr.concat(doDxfOutCurveTrans1Intensity(CDXFFile2, pPart, pObjGraph, m_listRefV2));

      let sRefV2 = doGetRefV2_1Trans1(pTrans1.properties, pObjGraph.nominalVolt, 1);
      retArr = retArr.concat(doDxfOutRefV2All(CDXFFile2, m_listRefV2, 0, DXF_LAYER_INTENSITY, pObjGraph, sRefV2));
    }
    if (nOpeMode >= MS_MODE_CALCULATION &&
      pPart.fault == true) {
      let refV1Left = [pTrans1.properties.refNo];
      retArr = retArr.concat(doDxfOutCurvePoint(CDXFFile2, pPart.calcPoint0, refV1Left, pObjGraph));
      retArr = retArr.concat(doDxfOutCurvePoint(CDXFFile2, pPart.calcPoint1, refV1Left, pObjGraph));
    }
  }
  {// partOutOut
    let pPart = pTrans1.properties.partOutOut;

    if (pPart.m_listDrawInrush && pPart.m_listDrawInrush.data.length > 0) {
      let m_listRefV2 = [convertRefV2(pPart.m_listDrawInrush.tabMapping, pObjGraph.graphNo)]
      retArr = retArr.concat(doDxfOutCurveTrans1Inrush(CDXFFile2, pPart, pObjGraph, m_listRefV2));

      let sRefV2 = doGetRefV2_0Trans1(pTrans1.properties, pObjGraph.nominalVolt, 2);
      retArr = retArr.concat(doDxfOutRefV2All(CDXFFile2, m_listRefV2, 0, DXF_LAYER_INRUSH, pObjGraph, sRefV2));
    }
    if (pPart.m_listDrawIntensity && pPart.m_listDrawIntensity.data.length > 0) {
      let m_listRefV2 = [convertRefV2(pPart.m_listDrawIntensity.tabMapping, pObjGraph.graphNo)]
      retArr = retArr.concat(doDxfOutCurveTrans1Intensity(CDXFFile2, pPart, pObjGraph, m_listRefV2));

      let sRefV2 = doGetRefV2_1Trans1(pTrans1.properties, pObjGraph.nominalVolt, 2);
      retArr = retArr.concat(doDxfOutRefV2All(CDXFFile2, m_listRefV2, 0, DXF_LAYER_INTENSITY, pObjGraph, sRefV2));
    }
    if (nOpeMode >= MS_MODE_CALCULATION &&
      pPart.fault == true) {
      let refV1Left = [pTrans1.properties.refNo];
      retArr = retArr.concat(doDxfOutCurvePoint(CDXFFile2, pPart.calcPoint0, refV1Left, pObjGraph));
      retArr = retArr.concat(doDxfOutCurvePoint(CDXFFile2, pPart.calcPoint1, refV1Left, pObjGraph));
    }
  }

  retArr = retArr.concat(doDxfOutCurveUserAll(CDXFFile2, pTrans1, pObjGraph));

  return retArr;
}

const doDxfOutCurveTrans1Inrush = (CDXFFile2: any, pPart: any, pObjGraph: any, m_listRefV2: any[]) => {
  let retArr: any[] = [];
  
  let InfoLine = convertInfoLine(pPart.m_listDrawInrush.tabMapping, pObjGraph.graphNo);
  doConvertNominal(pObjGraph, pPart.m_listDrawInrush.data);
  doConvertLinkLinePoint3WindingANDTrans(pPart);
  doSetLineProperty(pPart.m_listDrawInrush, InfoLine.m_colorNormal, InfoLine.m_nKind, InfoLine.m_nWidth);
  retArr = doDxfOutPointAll(CDXFFile2, pPart.m_listDrawInrush.data, DXF_LAYER_INRUSH, InfoLine.m_nKind);

  let pdPoint = pPart.m_listDrawInrush.data[0];
  retArr = retArr.concat(doDxfOutLinkLineRefV2All(CDXFFile2, m_listRefV2, 0, pdPoint, pObjGraph, DXF_LAYER_INRUSH, InfoLine.m_nKind));
  
  return retArr;
}

const doDxfOutCurveTrans1Intensity = (CDXFFile2: any, pPart: any, pObjGraph: any, m_listRefV2: any[]) => {
  let retArr: any[] = [];
  
  let InfoLine = convertInfoLine(pPart.m_listDrawIntensity.tabMapping, pObjGraph.graphNo);
  doConvertNominal(pObjGraph, pPart.m_listDrawIntensity.data);
  doConvertLinkLinePoint3WindingANDTrans(pPart);
  doSetLineProperty(pPart.m_listDrawIntensity, InfoLine.m_colorNormal, InfoLine.m_nKind, InfoLine.m_nWidth);
  retArr = doDxfOutPointAll(CDXFFile2, pPart.m_listDrawIntensity.data, DXF_LAYER_INTENSITY, InfoLine.m_nKind);

  let pdPoint = pPart.m_listDrawIntensity.data[0];
  retArr = retArr.concat(doDxfOutLinkLineRefV2All(CDXFFile2, m_listRefV2, 0, pdPoint, pObjGraph, DXF_LAYER_INTENSITY, InfoLine.m_nKind));
  
  return retArr;
}

const doDxfOutCurveTransScott = (CDXFFile2: any, pTransScott: any, pObjGraph: any, nOpeMode: number) => {
  let retArr: any[] = [];
  doConvertListPointTransScott(pTransScott);
  {// partSeatM
    let pPart = pTransScott.properties.partSeatM;

    if (pPart.m_listDrawInrush && pPart.m_listDrawInrush.data.length > 0) {
      let m_listRefV2 = [convertRefV2(pPart.m_listDrawInrush.tabMapping, pObjGraph.graphNo)]
      retArr = retArr.concat(doDxfOutCurveTransScottInrush(CDXFFile2, pPart, pObjGraph, m_listRefV2));

      let sRefV2 = doGetRefV2_0TransScott(pTransScott.properties, pObjGraph.nominalVolt, 1);
      retArr = retArr.concat(doDxfOutRefV2All(CDXFFile2, m_listRefV2, 0, DXF_LAYER_INRUSH, pObjGraph, sRefV2));
    }
    if (pPart.m_listDrawIntensity && pPart.m_listDrawIntensity.data.length > 0) {
      let m_listRefV2 = [convertRefV2(pPart.m_listDrawIntensity.tabMapping, pObjGraph.graphNo)]
      retArr = retArr.concat(doDxfOutCurveTransScottIntensity(CDXFFile2, pPart, pObjGraph, m_listRefV2));

      let sRefV2 = doGetRefV2_1TransScott(pTransScott.properties, pObjGraph.nominalVolt, 1);
      retArr = retArr.concat(doDxfOutRefV2All(CDXFFile2, m_listRefV2, 0, DXF_LAYER_INTENSITY, pObjGraph, sRefV2));
    }
    if (nOpeMode >= MS_MODE_CALCULATION &&
      pPart.fault == true) {
      let refV1Left = [pTransScott.properties.refNo];
      retArr = retArr.concat(doDxfOutCurvePoint(CDXFFile2, pPart.calcPoint0, refV1Left, pObjGraph));
      retArr = retArr.concat(doDxfOutCurvePoint(CDXFFile2, pPart.calcPoint1, refV1Left, pObjGraph));
    }
  }
  {// partSeatT
    let pPart = pTransScott.properties.partSeatT;

    if (pPart.m_listDrawInrush && pPart.m_listDrawInrush.data.length > 0) {
      let m_listRefV2 = [convertRefV2(pPart.m_listDrawInrush.tabMapping, pObjGraph.graphNo)]
      retArr = retArr.concat(doDxfOutCurveTransScottInrush(CDXFFile2, pPart, pObjGraph, m_listRefV2));

      let sRefV2 = doGetRefV2_0TransScott(pTransScott.properties, pObjGraph.nominalVolt, 2);
      retArr = retArr.concat(doDxfOutRefV2All(CDXFFile2, m_listRefV2, 0, DXF_LAYER_INRUSH, pObjGraph, sRefV2));
    }
    if (pPart.m_listDrawIntensity && pPart.m_listDrawIntensity.data.length > 0) {
      let m_listRefV2 = [convertRefV2(pPart.m_listDrawIntensity.tabMapping, pObjGraph.graphNo)]
      retArr = retArr.concat(doDxfOutCurveTransScottIntensity(CDXFFile2, pPart, pObjGraph, m_listRefV2));

      let sRefV2 = doGetRefV2_1TransScott(pTransScott.properties, pObjGraph.nominalVolt, 2);
      retArr = retArr.concat(doDxfOutRefV2All(CDXFFile2, m_listRefV2, 0, DXF_LAYER_INTENSITY, pObjGraph, sRefV2));
    }
    if (nOpeMode >= MS_MODE_CALCULATION &&
      pPart.fault == true) {
      let refV1Left = [pTransScott.properties.refNo];
      retArr = retArr.concat(doDxfOutCurvePoint(CDXFFile2, pPart.calcPoint0, refV1Left, pObjGraph));
      retArr = retArr.concat(doDxfOutCurvePoint(CDXFFile2, pPart.calcPoint1, refV1Left, pObjGraph));
    }
  }

  retArr = retArr.concat(doDxfOutCurveUserAll(CDXFFile2, pTransScott, pObjGraph));

  return retArr;
}

const doDxfOutCurveTransScottInrush = (CDXFFile2: any, pPart: any, pObjGraph: any, m_listRefV2: any[]) => {
  let retArr: any[] = [];
  
  let InfoLine = convertInfoLine(pPart.m_listDrawInrush.tabMapping, pObjGraph.graphNo);
  doConvertNominal(pObjGraph, pPart.m_listDrawInrush.data);
  doConvertLinkLinePoint3WindingANDTrans(pPart);
  doSetLineProperty(pPart.m_listDrawInrush, InfoLine.m_colorNormal, InfoLine.m_nKind, InfoLine.m_nWidth);
  retArr = doDxfOutPointAll(CDXFFile2, pPart.m_listDrawInrush.data, DXF_LAYER_INRUSH, InfoLine.m_nKind);

  let pdPoint = pPart.m_listDrawInrush.data[0];
  retArr = retArr.concat(doDxfOutLinkLineRefV2All(CDXFFile2, m_listRefV2, 0, pdPoint, pObjGraph, DXF_LAYER_INRUSH, InfoLine.m_nKind));
  
  return retArr;
}

const doDxfOutCurveTransScottIntensity = (CDXFFile2: any, pPart: any, pObjGraph: any, m_listRefV2: any[]) => {
  let retArr: any[] = [];
  
  let InfoLine = convertInfoLine(pPart.m_listDrawIntensity.tabMapping, pObjGraph.graphNo);
  doConvertNominal(pObjGraph, pPart.m_listDrawIntensity.data);
  doConvertLinkLinePoint3WindingANDTrans(pPart);
  doSetLineProperty(pPart.m_listDrawIntensity, InfoLine.m_colorNormal, InfoLine.m_nKind, InfoLine.m_nWidth);
  retArr = doDxfOutPointAll(CDXFFile2, pPart.m_listDrawIntensity.data, DXF_LAYER_INTENSITY, InfoLine.m_nKind);

  let pdPoint = pPart.m_listDrawIntensity.data[0];
  retArr = retArr.concat(doDxfOutLinkLineRefV2All(CDXFFile2, m_listRefV2, 0, pdPoint, pObjGraph, DXF_LAYER_INTENSITY, InfoLine.m_nKind));
  
  return retArr;
}

const doDxfOutCurveMotor = (CDXFFile2: any, pMotor: any, pObjGraph: any) => {
  let retArr: any[] = [];
  doConvertListPointMotor(pMotor);
  if (pMotor.m_listDrawInrush[0].data.length > 0) {
    let m_listRefV2 = [convertRefV2(pMotor.m_listDrawInrush[0].tabMapping, pObjGraph.graphNo)]
    let InfoLine = convertInfoLine(pMotor.m_listDrawInrush[0].tabMapping, pObjGraph.graphNo);
    // pMotor.DoMakeCurveInrushMotor(pObjGraph);
    doConvertNominal(pObjGraph, pMotor.m_listDrawInrush[0].data);
    doSetLineProperty(pMotor.m_listDrawInrush[0].data, InfoLine.m_colorNormal, InfoLine.m_nKind, InfoLine.m_nWidth);
    retArr = retArr.concat(doDxfOutPointAll(CDXFFile2, pMotor.m_listDrawInrush[0].data, DXF_LAYER_INRUSH, InfoLine.m_nKind));

    let sRefV2 = doGetRefV2_0Motor(pMotor.properties, pObjGraph.nominalVolt);
    retArr = retArr.concat(doDxfOutRefV2All(CDXFFile2, m_listRefV2, 0, DXF_LAYER_INRUSH, pObjGraph, sRefV2));
    let pdPoint = pMotor.m_listDrawInrush[0].data[0];
    retArr = retArr.concat(doDxfOutLinkLineRefV2All(CDXFFile2, m_listRefV2, 0, pdPoint, pObjGraph, DXF_LAYER_INRUSH, InfoLine.m_nKind));
  }

  if (pMotor.properties.useBaseLoad == true && pMotor.properties.dispBaseLoad == true) {
    if (pMotor.m_listDrawInrush[1].data.length > 0) {
      let m_listRefV2 = [convertRefV2(pMotor.m_listDrawInrush[1].tabMapping, pObjGraph.graphNo)]
      let InfoLine = convertInfoLine(pMotor.m_listDrawInrush[1].tabMapping, pObjGraph.graphNo);
      doConvertNominal(pObjGraph, pMotor.m_listDrawInrush[1].data);
      doSetLineProperty(pMotor.m_listDrawInrush[1].data, InfoLine.m_colorNormal, InfoLine.m_nKind, InfoLine.m_nWidth);
      retArr = retArr.concat(doDxfOutPointAll(CDXFFile2, pMotor.m_listDrawInrush[1].data, DXF_LAYER_INRUSH, InfoLine.m_nKind));
  
      let sRefV2 = doGetRefV2_1Motor(pMotor.properties, pObjGraph.nominalVolt);
      retArr = retArr.concat(doDxfOutRefV2All(CDXFFile2, m_listRefV2, 0, DXF_LAYER_INRUSH, pObjGraph, sRefV2));
      let pdPoint = pMotor.m_listDrawInrush[1].data[0];
      retArr = retArr.concat(doDxfOutLinkLineRefV2All(CDXFFile2, m_listRefV2, 0, pdPoint, pObjGraph, DXF_LAYER_INRUSH, InfoLine.m_nKind));
    }
  }

  retArr = retArr.concat(doDxfOutCurveUserAll(CDXFFile2, pMotor, pObjGraph));

  return retArr;
}

const doDxfOutCurveCapacitor = (CDXFFile2: any, pCapacitor: any, pObjGraph: any) => {
  let retArr: any[] = [];
  doConvertListPointCapacitor(pCapacitor);
  if (pCapacitor.m_listDrawInrush && pCapacitor.m_listDrawInrush.data.length > 0) {
    let m_listRefV2 = [convertRefV2(pCapacitor.m_listDrawInrush.tabMapping, pObjGraph.graphNo)]
    let InfoLine = convertInfoLine(pCapacitor.m_listDrawInrush.tabMapping, pObjGraph.graphNo);
    
    //DoMakeCurveInrushCapacitor
    doConvertNominal(pObjGraph, pCapacitor.m_listDrawInrush.data);
    doSetLineProperty(pCapacitor.m_listDrawInrush.data, InfoLine.m_colorNormal, InfoLine.m_nKind, InfoLine.m_nWidth);
    retArr = retArr.concat(doDxfOutPointAll(CDXFFile2, pCapacitor.m_listDrawInrush.data, DXF_LAYER_INRUSH, InfoLine.m_nKind));

    let sRefV2 = doGetRefV2Capacitor(pCapacitor.properties, pObjGraph.nominalVolt);
    retArr = retArr.concat(doDxfOutRefV2All(CDXFFile2, m_listRefV2, 0, DXF_LAYER_INRUSH, pObjGraph, sRefV2));
    let pdPoint = pCapacitor.m_listDrawInrush.data[0];
    retArr = retArr.concat(doDxfOutLinkLineRefV2All(CDXFFile2, m_listRefV2, 0, pdPoint, pObjGraph, DXF_LAYER_INRUSH, InfoLine.m_nKind));
  }
  retArr = retArr.concat(doDxfOutCurveUserAll(CDXFFile2, pCapacitor, pObjGraph));

  return retArr;
}

const doDxfOutCurveDS = (CDXFFile2: any, pDS: any, pObjGraph: any, nOpeMode: number) => {
  let retArr: any[] = [];

  if (nOpeMode >= MS_MODE_CALCULATION &&
    isPointFaultHVCB(pDS.properties) == true
  ) {
    let refV1Left = [pDS.properties.refNo];
    let m_listdPoint0 = doGetListPointCalcPoint(pDS.chart, 0);
    let m_listdPoint1 = doGetListPointCalcPoint(pDS.chart, 1);
    pDS.properties.calcPoint0.m_listdPoint = m_listdPoint0;
    pDS.properties.calcPoint1.m_listdPoint = m_listdPoint1;
    retArr = retArr.concat(doDxfOutCurvePoint(
      CDXFFile2, pDS.properties.calcPoint0, refV1Left, pObjGraph
    ));
    retArr = retArr.concat(doDxfOutCurvePoint(
      CDXFFile2, pDS.properties.calcPoint1, refV1Left, pObjGraph
    ));
  }
  
  retArr = retArr.concat(doDxfOutCurveUserAll(CDXFFile2, pDS, pObjGraph));

  return retArr;
}

const doDxfOutCurveLBS = (CDXFFile2: any, pLBS: any, pObjGraph: any, nOpeMode: number) => {
  let retArr: any[] = [];

  if (nOpeMode >= MS_MODE_CALCULATION &&
    isPointFaultHVCB(pLBS.properties) == true
  ) {
    let refV1Left = [pLBS.properties.refNo];
    let m_listdPoint0 = doGetListPointCalcPoint(pLBS.chart, 0);
    let m_listdPoint1 = doGetListPointCalcPoint(pLBS.chart, 1);
    pLBS.properties.calcPoint0.m_listdPoint = m_listdPoint0;
    pLBS.properties.calcPoint1.m_listdPoint = m_listdPoint1;
    retArr = retArr.concat(doDxfOutCurvePoint(
      CDXFFile2, pLBS.properties.calcPoint0, refV1Left, pObjGraph
    ));
    retArr = retArr.concat(doDxfOutCurvePoint(
      CDXFFile2, pLBS.properties.calcPoint1, refV1Left, pObjGraph
    ));
  }
  
  retArr = retArr.concat(doDxfOutCurveUserAll(CDXFFile2, pLBS, pObjGraph));

  return retArr;
}

const doDxfOutCurveMVCB = (CDXFFile2: any, pMVCB: any, pObjGraph: any, nOpeMode: number) => {
  let retArr: any[] = [];

  if (nOpeMode >= MS_MODE_CALCULATION &&
    isPointFaultHVCB(pMVCB.properties) == true
  ) {
    let refV1Left = [pMVCB.properties.refNo];
    let m_listdPoint0 = doGetListPointCalcPoint(pMVCB.chart, 0);
    let m_listdPoint1 = doGetListPointCalcPoint(pMVCB.chart, 1);
    pMVCB.properties.calcPoint0.m_listdPoint = m_listdPoint0;
    pMVCB.properties.calcPoint1.m_listdPoint = m_listdPoint1;
    retArr = retArr.concat(doDxfOutCurvePoint(
      CDXFFile2, pMVCB.properties.calcPoint0, refV1Left, pObjGraph
    ));
    retArr = retArr.concat(doDxfOutCurvePoint(
      CDXFFile2, pMVCB.properties.calcPoint1, refV1Left, pObjGraph
    ));
  }
  
  retArr = retArr.concat(doDxfOutCurveUserAll(CDXFFile2, pMVCB, pObjGraph));

  return retArr;
}

const doDxfOutCurveContactor = (CDXFFile2: any, pContactor: any, pObjGraph: any, nOpeMode: number) => {
  let retArr: any[] = [];

  if (nOpeMode >= MS_MODE_CALCULATION &&
    isPointFaultHVCB(pContactor.properties) == true
  ) {
    let refV1Left = [pContactor.properties.refNo];
    let m_listdPoint0 = doGetListPointCalcPoint(pContactor.chart, 0);
    let m_listdPoint1 = doGetListPointCalcPoint(pContactor.chart, 1);
    pContactor.properties.calcPoint0.m_listdPoint = m_listdPoint0;
    pContactor.properties.calcPoint1.m_listdPoint = m_listdPoint1;
    retArr = retArr.concat(doDxfOutCurvePoint(
      CDXFFile2, pContactor.properties.calcPoint0, refV1Left, pObjGraph
    ));
    retArr = retArr.concat(doDxfOutCurvePoint(
      CDXFFile2, pContactor.properties.calcPoint1, refV1Left, pObjGraph
    ));
  }
  
  retArr = retArr.concat(doDxfOutCurveUserAll(CDXFFile2, pContactor, pObjGraph));

  return retArr;
}

const doDxfOutCurveZeroCond = (CDXFFile2: any, pZeroCond: any, pObjGraph: any, nOpeMode: number) => {
  let retArr: any[] = [];

  if (nOpeMode >= MS_MODE_CALCULATION &&
    isPointFaultZeroCond(pZeroCond.properties) == true
  ) {
    let refV1Left = [pZeroCond.properties.refNo];
    let m_listdPoint0 = doGetListPointCalcPoint(pZeroCond.chart, 0);
    let m_listdPoint1 = doGetListPointCalcPoint(pZeroCond.chart, 1);
    pZeroCond.properties.calcPoint0.m_listdPoint = m_listdPoint0;
    pZeroCond.properties.calcPoint1.m_listdPoint = m_listdPoint1;
    retArr = retArr.concat(doDxfOutCurvePoint(
      CDXFFile2, pZeroCond.properties.calcPoint0, refV1Left, pObjGraph
    ));
    retArr = retArr.concat(doDxfOutCurvePoint(
      CDXFFile2, pZeroCond.properties.calcPoint1, refV1Left, pObjGraph
    ));
  }
  
  retArr = retArr.concat(doDxfOutCurveUserAll(CDXFFile2, pZeroCond, pObjGraph));

  return retArr;
}

const doDxfOutCurveWire = (CDXFFile2: any, pWire: any, pObjGraph: any, nOpeMode: number) => {
  let retArr: any[] = [];

  if (nOpeMode >= MS_MODE_CALCULATION &&
    isPointFaultHVCB(pWire.properties) == true
  ) {
    let refV1Left = [pWire.properties.refNo];
    let m_listdPoint0 = doGetListPointCalcPoint(pWire.chart, 0);
    let m_listdPoint1 = doGetListPointCalcPoint(pWire.chart, 1);
    pWire.properties.calcPoint0.m_listdPoint = m_listdPoint0;
    pWire.properties.calcPoint1.m_listdPoint = m_listdPoint1;
    retArr = retArr.concat(doDxfOutCurvePoint(
      CDXFFile2, pWire.properties.calcPoint0, refV1Left, pObjGraph
    ));
    retArr = retArr.concat(doDxfOutCurvePoint(
      CDXFFile2, pWire.properties.calcPoint1, refV1Left, pObjGraph
    ));
  }
  
  retArr = retArr.concat(doDxfOutCurveUserAll(CDXFFile2, pWire, pObjGraph));

  return retArr;
}

const doDxfOutCurveBusBar = (CDXFFile2: any, pBusBar: any, pObjGraph: any, nOpeMode: number) => {
  let retArr: any[] = [];

  if (nOpeMode >= MS_MODE_CALCULATION &&
    isPointFaultHVCB(pBusBar.properties) == true
  ) {
    let refV1Left = [pBusBar.properties.refNo];
    let m_listdPoint0 = doGetListPointCalcPoint(pBusBar.chart, 0);
    let m_listdPoint1 = doGetListPointCalcPoint(pBusBar.chart, 1);
    pBusBar.properties.calcPoint0.m_listdPoint = m_listdPoint0;
    pBusBar.properties.calcPoint1.m_listdPoint = m_listdPoint1;
    retArr = retArr.concat(doDxfOutCurvePoint(
      CDXFFile2, pBusBar.properties.calcPoint0, refV1Left, pObjGraph
    ));
    retArr = retArr.concat(doDxfOutCurvePoint(
      CDXFFile2, pBusBar.properties.calcPoint1, refV1Left, pObjGraph
    ));
  }
  
  retArr = retArr.concat(doDxfOutCurveUserAll(CDXFFile2, pBusBar, pObjGraph));

  return retArr;
}

const doDxfOutCurveBusDuct = (CDXFFile2: any, pBusDuct: any, pObjGraph: any, nOpeMode: number) => {
  let retArr: any[] = [];

  if (nOpeMode >= MS_MODE_CALCULATION &&
    isPointFaultHVCB(pBusDuct.properties) == true
  ) {
    let refV1Left = [pBusDuct.properties.refNo];
    let m_listdPoint0 = doGetListPointCalcPoint(pBusDuct.chart, 0);
    let m_listdPoint1 = doGetListPointCalcPoint(pBusDuct.chart, 1);
    pBusDuct.properties.calcPoint0.m_listdPoint = m_listdPoint0;
    pBusDuct.properties.calcPoint1.m_listdPoint = m_listdPoint1;
    retArr = retArr.concat(doDxfOutCurvePoint(
      CDXFFile2, pBusDuct.properties.calcPoint0, refV1Left, pObjGraph
    ));
    retArr = retArr.concat(doDxfOutCurvePoint(
      CDXFFile2, pBusDuct.properties.calcPoint1, refV1Left, pObjGraph
    ));
  }
  
  retArr = retArr.concat(doDxfOutCurveUserAll(CDXFFile2, pBusDuct, pObjGraph));

  return retArr;
}

const doDxfOutCurveReactor = (CDXFFile2: any, pReactor: any, pObjGraph: any, nOpeMode: number) => {
  let retArr: any[] = [];

  if (nOpeMode >= MS_MODE_CALCULATION &&
    isPointFaultHVCB(pReactor.properties) == true
  ) {
    let refV1Left = [pReactor.properties.refNo];
    let m_listdPoint0 = doGetListPointCalcPoint(pReactor.chart, 0);
    let m_listdPoint1 = doGetListPointCalcPoint(pReactor.chart, 1);
    pReactor.properties.calcPoint0.m_listdPoint = m_listdPoint0;
    pReactor.properties.calcPoint1.m_listdPoint = m_listdPoint1;
    retArr = retArr.concat(doDxfOutCurvePoint(
      CDXFFile2, pReactor.properties.calcPoint0, refV1Left, pObjGraph
    ));
    retArr = retArr.concat(doDxfOutCurvePoint(
      CDXFFile2, pReactor.properties.calcPoint1, refV1Left, pObjGraph
    ));
  }
  
  retArr = retArr.concat(doDxfOutCurveUserAll(CDXFFile2, pReactor, pObjGraph));

  return retArr;
}

const doDxfOutCurveMotorGrp = (CDXFFile2: any, pMotorGrp: any, pObjGraph: any, nOpeMode: number) => {
  let retArr: any[] = [];

  if (nOpeMode >= MS_MODE_CALCULATION &&
    isPointFaultHVCB(pMotorGrp.properties) == true
  ) {
    let refV1Left = [pMotorGrp.properties.refNo];
    let m_listdPoint0 = doGetListPointCalcPoint(pMotorGrp.chart, 0);
    let m_listdPoint1 = doGetListPointCalcPoint(pMotorGrp.chart, 1);
    pMotorGrp.properties.calcPoint0.m_listdPoint = m_listdPoint0;
    pMotorGrp.properties.calcPoint1.m_listdPoint = m_listdPoint1;
    retArr = retArr.concat(doDxfOutCurvePoint(
      CDXFFile2, pMotorGrp.properties.calcPoint0, refV1Left, pObjGraph
    ));
    retArr = retArr.concat(doDxfOutCurvePoint(
      CDXFFile2, pMotorGrp.properties.calcPoint1, refV1Left, pObjGraph
    ));
  }
  
  retArr = retArr.concat(doDxfOutCurveUserAll(CDXFFile2, pMotorGrp, pObjGraph));

  return retArr;
}

const doDxfOutCurveLoad = (CDXFFile2: any, pLoad: any, pObjGraph: any) => {
  let retArr: any[] = [];
  retArr = retArr.concat(doDxfOutCurveUserAll(CDXFFile2, pLoad, pObjGraph));
  return retArr;
}

const doDxfOutCurveLoadCenter = (CDXFFile2: any, pLoadCenter: any, pObjGraph: any) => {
  let retArr: any[] = [];
  retArr = retArr.concat(doDxfOutCurveUserAll(CDXFFile2, pLoadCenter, pObjGraph));
  return retArr;
}

const doDxfOutCurveVCT = (CDXFFile2: any, pVCT: any, pObjGraph: any) => {
  let retArr: any[] = [];
  retArr = retArr.concat(doDxfOutCurveUserAll(CDXFFile2, pVCT, pObjGraph));
  return retArr;
}

const doDxfOutCurveINV = (CDXFFile2: any, pINV: any, pObjGraph: any) => {
  let retArr: any[] = [];
  retArr = retArr.concat(doDxfOutCurveUserAll(CDXFFile2, pINV, pObjGraph));
  return retArr;
}

const doDxfOutCurveRELAY = (CDXFFile2: any, pRELAY: any, pObjGraph: any) => {
  let retArr: any[] = [];
  retArr = retArr.concat(doDxfOutCurveUserAll(CDXFFile2, pRELAY, pObjGraph));
  return retArr;
}

const doDxfOutCurveArrestor = (CDXFFile2: any, pArrestor: any, pObjGraph: any) => {
  let retArr: any[] = [];
  retArr = retArr.concat(doDxfOutCurveUserAll(CDXFFile2, pArrestor, pObjGraph));
  return retArr;
}

const doDxfOutCurveVT = (CDXFFile2: any, pVT: any, pObjGraph: any) => {
  let retArr: any[] = [];
  retArr = retArr.concat(doDxfOutCurveUserAll(CDXFFile2, pVT, pObjGraph));
  return retArr;
}

const doDxfOutCurveCT = (CDXFFile2: any, pCT: any, pObjGraph: any) => {
  let retArr: any[] = [];
  retArr = retArr.concat(doDxfOutCurveUserAll(CDXFFile2, pCT, pObjGraph));
  return retArr;
}

const doDxfOutCurveZCT = (CDXFFile2: any, pZCT: any, pObjGraph: any) => {
  let retArr: any[] = [];
  retArr = retArr.concat(doDxfOutCurveUserAll(CDXFFile2, pZCT, pObjGraph));
  return retArr;
}
// #endregion All Element

const doDxfOutCurveAdjustAll = (
  CDXFFile2: any,
  control: any,
  pObjGraph: any,
  sRefV2: any[],
) => {
  let retArr: any[] = [];

  const pAdjustObj = {
    m_listCalcCenter: [] as OneCurve[],
    m_listCalcMax: [] as OneCurve[],
    m_listCalcMin: [] as OneCurve[],
    m_listPointCenter: [] as CdPoint[],
    m_listPointMax: [] as CdPoint[],
    m_listPointMin: [] as CdPoint[],
  } as any
  let InfoLine = convertInfoLine(control.chart[0].tabMapping, pObjGraph.graphNo);
  let m_listRefV2 = [convertRefV2(control.chart[0].tabMapping, pObjGraph.graphNo)];

  convertListPoint(pAdjustObj, control.chart, pObjGraph);

  retArr = retArr.concat(doDxfOutCurveAdjust(CDXFFile2, control, pAdjustObj, DXF_LAYER_ADJUST, InfoLine));
  retArr = retArr.concat(doDxfOutRefV2All(CDXFFile2, m_listRefV2, 0, DXF_LAYER_ADJUST, pObjGraph, sRefV2));
  retArr = retArr.concat(doDxfOutLinkLineAdjust(CDXFFile2, control, pAdjustObj, pObjGraph, DXF_LAYER_ADJUST));
  
  return retArr;
}

const doDxfOutCurveAdjust = (CDXFFile2: any, control: any, pAdjust: any, nLayerNo: number, pInfoLine: any) => {
  let retArr: any[] = [];
  let adjust = control.properties.details;
  if(adjust.base.kindFlag == RS_KIND_FUSE && adjust.drawThreeCurve == true){
    retArr = retArr.concat(doDxfOutPointAll(CDXFFile2, pAdjust.m_listPointCenter, nLayerNo, pInfoLine.m_nKind));
    retArr = retArr.concat(doDxfOutPointAll(CDXFFile2, pAdjust.m_listPointMax, nLayerNo, pInfoLine.m_nKind));
    retArr = retArr.concat(doDxfOutPointAll(CDXFFile2, pAdjust.m_listPointMin, nLayerNo, pInfoLine.m_nKind));
  } else{
    if (pInfoLine.m_bDispBand == true) {
      if (pAdjust.m_listPointMax.length > 0 || pAdjust.m_listPointMin.length > 0) {
        retArr = retArr.concat(doDxfOutPointAll(CDXFFile2, pAdjust.m_listPointMax, nLayerNo, pInfoLine.m_nKind));
        retArr = retArr.concat(doDxfOutPointAll(CDXFFile2, pAdjust.m_listPointMin, nLayerNo, pInfoLine.m_nKind));
      } else {
        retArr = retArr.concat(doDxfOutPointAll(CDXFFile2, pAdjust.m_listPointCenter, nLayerNo, pInfoLine.m_nKind));
      }
    } else {
      if (pAdjust.m_listPointCenter.length > 0) {
        retArr = retArr.concat(doDxfOutPointAll(CDXFFile2, pAdjust.m_listPointCenter, nLayerNo, pInfoLine.m_nKind));
      } else {
        retArr = retArr.concat(doDxfOutPointAll(CDXFFile2, pAdjust.m_listPointMax, nLayerNo, pInfoLine.m_nKind));
        retArr = retArr.concat(doDxfOutPointAll(CDXFFile2, pAdjust.m_listPointMin, nLayerNo, pInfoLine.m_nKind));
      }
    }
  }
  return retArr;
}

const doDxfOutLinkLineAdjust = (CDXFFile2: any, control: any, pAdjust: any, pObjGraph: any, nLayerNo: number) => {
  let retArr: any[] = [];

  let adjust = control.properties.details;
  let InfoLine = convertInfoLine(control.chart[0].tabMapping, pObjGraph.graphNo);
  doConvertLinkLinePoint(pAdjust);
  if (adjust.base.kindFlag == RS_KIND_FUSE && adjust.drawThreeCurve == true) {
    for (let nIndex = 0; nIndex < control.chart.length; nIndex++) {
      let pObjRefV2 = convertRefV2(control.chart[nIndex].tabMapping, pObjGraph.graphNo);
      if (pAdjust.m_listPointMax.length > 0)
      {
        retArr = retArr.concat(doDxfOutLinkLineRefV2(
          CDXFFile2, pObjRefV2, pAdjust.m_dPointStartMax, pObjGraph, nLayerNo, InfoLine.m_nKind
        ));
      }
      if (pAdjust.m_listPointCenter.length > 0) 
      { 
        retArr = retArr.concat(doDxfOutLinkLineRefV2(
          CDXFFile2, pObjRefV2, pAdjust.m_dPointStartCenter, pObjGraph, nLayerNo, InfoLine.m_nKind
        ));
      }
      if (pAdjust.m_listPointMin.length > 0) 
      {
        retArr = retArr.concat(doDxfOutLinkLineRefV2(
          CDXFFile2, pObjRefV2, pAdjust.m_dPointStartMin, pObjGraph, nLayerNo, InfoLine.m_nKind
        ));
      }
    }
  } else { 
    if (InfoLine.m_bDispBand == true) {
      if (pAdjust.m_listPointMax.length > 0 || pAdjust.m_listPointMin.length > 0) {
        for (let nIndex = 0; nIndex < control.chart.length; nIndex++) {
          let pObjRefV2 = convertRefV2(control.chart[nIndex].tabMapping, pObjGraph.graphNo);
          retArr = retArr.concat(doDxfOutLinkLineRefV2(
            CDXFFile2, pObjRefV2, pAdjust.m_dPointStartMax, pObjGraph, nLayerNo, InfoLine.m_nKind
          ));
        }
        if (pAdjust.m_dPointStartMax.m_dx != pAdjust.m_dPointStartMin.m_dx ||
          pAdjust.m_dPointStartMax.m_dy != pAdjust.m_dPointStartMin.m_dy) {
          for (let nIndex = 0; nIndex < control.chart.length; nIndex++) {
            let pObjRefV2 = convertRefV2(control.chart[nIndex].tabMapping, pObjGraph.graphNo);
            retArr = retArr.concat(doDxfOutLinkLineRefV2(
              CDXFFile2, pObjRefV2, pAdjust.m_dPointStartMin, pObjGraph, nLayerNo, InfoLine.m_nKind
            ));
          }
        }
      } else {
        for (let nIndex = 0; nIndex < control.chart.length; nIndex++) {
          let pObjRefV2 = convertRefV2(control.chart[nIndex].tabMapping, pObjGraph.graphNo);
          retArr = retArr.concat(doDxfOutLinkLineRefV2(
            CDXFFile2, pObjRefV2, pAdjust.m_dPointStartCenter, pObjGraph, nLayerNo, InfoLine.m_nKind
          ));
        }
      }
    } else {
      if (pAdjust.m_listPointCenter.length > 0) {
        for (let nIndex = 0; nIndex < control.chart.length; nIndex++) {
          let pObjRefV2 = convertRefV2(control.chart[nIndex].tabMapping, pObjGraph.graphNo);
          retArr = retArr.concat(doDxfOutLinkLineRefV2(
            CDXFFile2, pObjRefV2, pAdjust.m_dPointStartCenter, pObjGraph, nLayerNo, InfoLine.m_nKind
          ));
        }
      } else {
        for (let nIndex = 0; nIndex < control.chart.length; nIndex++) {
          let pObjRefV2 = convertRefV2(control.chart[nIndex].tabMapping, pObjGraph.graphNo);
          retArr = retArr.concat(doDxfOutLinkLineRefV2(
            CDXFFile2, pObjRefV2, pAdjust.m_dPointStartMax, pObjGraph, nLayerNo, InfoLine.m_nKind
          ));
        }
        if (pAdjust.m_dPointStartMax.m_dx != pAdjust.m_dPointStartMin.m_dx ||
          pAdjust.m_dPointStartMax.m_dy != pAdjust.m_dPointStartMin.m_dy) {
          for (let nIndex = 0; nIndex < control.chart.length; nIndex++) {
            let pObjRefV2 = convertRefV2(control.chart[nIndex].tabMapping, pObjGraph.graphNo);
            retArr = retArr.concat(doDxfOutLinkLineRefV2(
              CDXFFile2, pObjRefV2, pAdjust.m_dPointStartMin, pObjGraph, nLayerNo, InfoLine.m_nKind
            ));
          }
        }
      }
    }
  }

  return retArr;
}

const doDxfOutPointAll = (CDXFFile2: any, plistPoint: any[], nLayerNo: number, nKind: number) => {
  let retArr: any[] = [];
  let sLayerNo = DLLGetTextByBeams(nLayerNo);
  let sLineKind = doGetTextLineKind(nKind);
  
  if (plistPoint.length > 1) {
    retArr.push("0");
    retArr.push("POLYLINE");
    
    retArr.push("8");
    retArr.push(sLayerNo);
    
    retArr.push("6");
    retArr.push(sLineKind);
    
    retArr.push("66");
    retArr.push("1");

    retArr.push("10");
    retArr.push("0");
    
    retArr.push("20");
    retArr.push("0");

    plistPoint.forEach((pdPoint: any) => {
      retArr.push("0");
      retArr.push("VERTEX");
      
      retArr.push("8");
      retArr.push(sLayerNo);

      retArr.push("6");
      retArr.push(sLineKind);
      
      retArr.push("10");
      let sCurrent = BeamsNumber(pdPoint.m_dx * CDXFFile2.m_dMulti, 5).toString();
      retArr.push(sCurrent);

      retArr.push("20");
      let sTime = BeamsNumber(pdPoint.m_dy * CDXFFile2.m_dMulti, 5).toString();
      retArr.push(sTime);
    })

    retArr.push("0");
    retArr.push("SEQEND");
    
    retArr.push("8");
    retArr.push(sLayerNo);
    
    retArr.push("6");
    retArr.push(sLineKind);
  } else if (plistPoint.length == 1) {
    retArr.push("0");
    retArr.push("CIRCLE");
    
    retArr.push("8");
    retArr.push(sLayerNo);
    
    retArr.push("6");
    retArr.push(sLineKind);
    
    retArr.push("62");
    retArr.push("256");

    let pdPoint = plistPoint[0];

    retArr.push("10");
    retArr.push(DLLGetTextByBeams(pdPoint.m_dx * CDXFFile2.m_dMulti));
    
    retArr.push("20");
    retArr.push(DLLGetTextByBeams(pdPoint.m_dy * CDXFFile2.m_dMulti));

    retArr.push("40");
    retArr.push(DLLGetTextByBeams(1));
  }
  return retArr;
}

const doDxfOutRefV2All = (
  CDXFFile2: any,
  plistRefV2: any[],
  nIndex: number,
  nLayerNo: number,
  pObjGraph: any,
  sRefV2: any[]
) => {
  let retArr: any[] = [];
  let pObjRefV2 = plistRefV2[nIndex];
  retArr = doDxfOutRefV2(CDXFFile2, pObjRefV2, nLayerNo, pObjGraph, sRefV2);
  return retArr;
}

const doDxfOutRefV2 = (CDXFFile2: any, pRefV2: any, nLayerNo: number, pObjGraph: any, sRefV2: any[]) => {
  let retArr: any[] = [];
  let dLeft: number;
  if (pObjGraph.nominalVolt > 0) {
    dLeft = doConvertNominalX(pObjGraph, pRefV2.m_pointTopLeft.m_dx * pObjGraph.nominalVolt);
  } else {
    dLeft = doConvertNominalX(pObjGraph, pRefV2.m_pointTopLeft.m_dx);
  }
  dLeft *= CDXFFile2.m_dMulti;

  let dTop = doConvertNominalY(pObjGraph, pRefV2.m_pointTopLeft.m_dy);
  dTop *= CDXFFile2.m_dMulti;

  let dHight = Math.abs(pRefV2.m_LogFont.lfHeight) / 40.0;
  dTop -= dHight;

  let sRemain = [...sRefV2];
  if (sRemain.length > 0) {
    sRemain.forEach((sOneLine) => {
      sOneLine = sOneLine.trim();
      if (sOneLine.length > 0) {
        retArr = retArr.concat(STRING(nLayerNo, dLeft, dTop, dHight, sOneLine));
      }
      dTop -= dHight + dHight / 10;
    })
  }

  return retArr;
}

const doDxfOutLinkLineRefV2All = (
  CDXFFile2: any,
  plistRefV2: any[],
  nIndex: number,
  pdPointStartLP: any,
  pObjGraph: any,
  nLayerNo: number,
  nKind: number
) => {
  let retArr: any[] = [];
  let pObjRefV2 = plistRefV2[nIndex];
  retArr = doDxfOutLinkLineRefV2(
    CDXFFile2, pObjRefV2, pdPointStartLP, pObjGraph, nLayerNo, nKind
  );
  return retArr;
}

const doDxfOutLinkLineRefV2 = (
  CDXFFile2: any,
  pRefV2: any,
  pdPointStartLP: CdPoint,
  pObjGraph: any,
  nLayerNo: number,
  nKind: number
) => {
  let retArr: any[] = [];

  if (pRefV2.m_bDispLinkLine != true || pRefV2.m_bDispRefV2 != true) {
    return retArr;
  }
  if (pdPointStartLP.m_dx <= 0 && pdPointStartLP.m_dy <= 0) {
    return retArr;
  }

  let dEnd: CdPoint = {
    m_dx: doConvertNominalX(pObjGraph, pRefV2.minPoint.m_dx * pObjGraph.nominalVolt),
    m_dy: doConvertNominalY(pObjGraph, pRefV2.minPoint.m_dy)
  };

  let sLayerNo = DLLGetTextByBeams(nLayerNo);
  let sLineKind = doGetTextLineKind(nKind);

  retArr.push("0");
  retArr.push("POLYLINE");
  
  retArr.push("8");
  retArr.push(sLayerNo);
  
  retArr.push("6");
  retArr.push(sLineKind);
  
  retArr.push("66");
  retArr.push("1");
  
  retArr.push("10");
  retArr.push("0");
  
  retArr.push("20");
  retArr.push("0");
  
  {
    retArr.push("0");
    retArr.push("VERTEX");
    
    retArr.push("8");
    retArr.push(sLayerNo);
    
    retArr.push("6");
    retArr.push(sLineKind);
    
    retArr.push("10");
    let sCurrent = BeamsNumber(pdPointStartLP.m_dx * CDXFFile2.m_dMulti, 5).toString();
    retArr.push(sCurrent);
    
    retArr.push("20");
    let sTime = BeamsNumber(pdPointStartLP.m_dy * CDXFFile2.m_dMulti, 5).toString();
    retArr.push(sTime);
  }

  {
    retArr.push("0");
    retArr.push("VERTEX");
    
    retArr.push("8");
    retArr.push(sLayerNo);
    
    retArr.push("6");
    retArr.push(sLineKind);
    
    retArr.push("10");
    let sCurrent = BeamsNumber(dEnd.m_dx * CDXFFile2.m_dMulti, 5).toString();
    retArr.push(sCurrent);
    
    retArr.push("20");
    let sTime = BeamsNumber(dEnd.m_dy * CDXFFile2.m_dMulti, 5).toString();
    retArr.push(sTime);
  }

  retArr.push("0");
  retArr.push("SEQEND");
  
  retArr.push("8");
  retArr.push(sLayerNo);
  
  retArr.push("6");
  retArr.push(sLineKind);

  return retArr;
}

// CURVE POINT
const doDxfOutCurvePoint = (
  CDXFFile2: any,
  pCalcPoint: any,
  sRefNo: any[],
  pObjGraph: any
) => {
  let retArr: any[] = [];
  let dIk3 = doGetIksymTotalMG(pCalcPoint);
  if (dIk3 <= 0) {
    return retArr;
  }

  // pCalcPoint.m_dpointTop.m_dx = dIk3 * lVoltage;
  // pCalcPoint.m_dpointTop.m_dy = 0.03;
  // doConvertNominal(pObjGraph, pCalcPoint.m_dpointTop)

  // pCalcPoint.m_dpointBottom.m_dx = dIk3 * lVoltage;
  // pCalcPoint.m_dpointBottom.m_dy = doGetScaleYmin(pObjGraph);
  // doConvertNominal(pObjGraph, pCalcPoint.m_dpointBottom)
  doConvertNominal(pObjGraph, pCalcPoint.m_listdPoint.data)

  let InfoLine = convertInfoLine(
    pCalcPoint.m_listdPoint.tabMapping, pObjGraph.graphNo
  );
  let m_listRefV2 = [convertRefV2(
    pCalcPoint.m_listdPoint.tabMapping, pObjGraph.graphNo
  )];

  // pCalcPoint.m_listdPoint.push(pCalcPoint.m_dpointTop);
  // pCalcPoint.m_listdPoint.push(pCalcPoint.m_dpointBottom);
  doSetLineProperty(pCalcPoint.m_listdPoint, InfoLine.m_colorNormal, InfoLine.m_nKind, InfoLine.m_nWidth);

  retArr = retArr.concat(doDxfOutPointAll(
    CDXFFile2, pCalcPoint.m_listdPoint.data, DXF_LAYER_POINT, InfoLine.m_nKind
  ));

  let sIsymDirect = doGetTextFaultDirect(
    pCalcPoint, sRefNo, dIk3, pObjGraph.nominalVolt
  );
  retArr = retArr.concat(doDxfOutRefV2All(
    CDXFFile2, m_listRefV2, 0, DXF_LAYER_POINT, pObjGraph, sIsymDirect
  ));

  retArr = retArr.concat(doDxfOutLinkLineRefV2All(
    CDXFFile2, m_listRefV2, 0, pCalcPoint.m_listdPoint.data[0], pObjGraph, DXF_LAYER_POINT, InfoLine.m_nKind
  ));
  
  return retArr;
}

// #region USER CURVE
const doDxfOutCurveUserAll = (CDXFFile2: any, control: any, pObjGraph: any) => {
  let retArr: any[] = [];
  if (!control.properties.listUser) return retArr;

  let pUserList = control.properties.listUser;
  mapUserCurve(pUserList, control.chart);
  pUserList.forEach((pObjUser: any) => {
    if (pObjUser.m_listDraw){
      retArr = doDxfOutCurveUser(CDXFFile2, pObjUser, pObjGraph);
    }
  })
  return retArr;
}

const doDxfOutCurveUser = (CDXFFile2: any, pUser: any, pObjGraph: any) => {
  let retArr: any[] = [];

  // doMakeCurveUser(pUser, pObjGraph.nominalVolt, lVoltage);
  doConvertNominal(pObjGraph, pUser.m_listDraw.data);

  let InfoLine = convertInfoLine(pUser.m_listDraw.tabMapping, pObjGraph.graphNo);
  let m_listRefV2 = [convertRefV2(pUser.m_listDraw.tabMapping, pObjGraph.graphNo)];
  doSetLineProperty(pUser.m_listDraw, InfoLine.m_colorNormal, InfoLine.m_nKind, InfoLine.m_nWidth);

  retArr = retArr.concat(doDxfOutPointAll(
    CDXFFile2, pUser.m_listDraw.data, DXF_LAYER_USER, InfoLine.m_nKind
  ));

  let sRefV2 = doGetTextRefV2UserCurve(pUser);
  retArr = retArr.concat(doDxfOutRefV2All(
    CDXFFile2, m_listRefV2, 0, DXF_LAYER_USER, pObjGraph, sRefV2
  ));

  let pdPoint = doGetStartPointLinkLineUserCurve(pUser);
  retArr = retArr.concat(doDxfOutLinkLineRefV2All(
    CDXFFile2, m_listRefV2, 0, pdPoint, pObjGraph, DXF_LAYER_USER, InfoLine.m_nKind
  ));

  return retArr;
}
// #endregion USER CURVE

const STRING = (
  nLayerNo: number,
  dLeft: number,
  dTop: number,
  dHight: number,
  sText: string
) => {
  let retArr: any[] = [];

  let sRemain = sText.split("\n");
  if (sRemain.length > 0) {
    sRemain.forEach((sOneLine: string) => {
      retArr.push("0");
      retArr.push("TEXT");

      retArr.push("8");
      retArr.push(DLLGetTextByBeams(nLayerNo));

      retArr.push("10");
      retArr.push(DLLGetTextByBeams(dLeft));
      
      retArr.push("20");
      retArr.push(DLLGetTextByBeams(dTop));
      
      retArr.push("40");
      retArr.push(DLLGetTextByBeams(dHight));

      sText.replace("�u", "m2"); // TODO m² . m2
      retArr.push("1");
      
      retArr.push(sOneLine);
    })
    dTop -= dHight + dHight / 10;
  }

  return retArr;
}

// ------------- sup func
const doConvertListPointCapacitor = (pMotor: any) => {
  let chartData: any[] = pMotor.chart;
  chartData.forEach((e: any) => {
    if (e.lineId == "INRUSH"){
      pMotor.m_listDrawInrush = {
        ...e,
        data: e.data.map((e2: any) => {return {m_dx: e2.x, m_dy: e2.y}})
      }
    }
  })
}

const doConvertListPointMotor = (pMotor: any) => {
  let chartData: any[] = pMotor.chart;
  pMotor.m_listDrawInrush = [{data: []}, {data: []}] as any[];
  chartData.forEach((e: any) => {
    if (e.lineId == "INRUSH"){
      pMotor.m_listDrawInrush[0] = {
        ...e,
        data: e.data.map((e2: any) => {return {m_dx: e2.x, m_dy: e2.y}})
      }
    } else if (e.lineId == "INRUSH_2"){
      pMotor.m_listDrawInrush[1] = {
        ...e,
        data: e.data.map((e2: any) => {return {m_dx: e2.x, m_dy: e2.y}})
      }
    }
  })
}

const doConvertListPointTransScott = (pTransScott: any) => {
  let chartData: any[] = pTransScott.chart;
  chartData.forEach((e: any) => {
    if (e.lineId == "INRUSH_1"){
      pTransScott.properties.partSeatM.m_listDrawInrush = {
        ...e,
        data: e.data.map((e2: any) => {return {m_dx: e2.x, m_dy: e2.y}})
      }
    } else if (e.lineId == "INTENSITY_1"){
      pTransScott.properties.partSeatM.m_listDrawIntensity = {
        ...e,
        data: e.data.map((e2: any) => {return {m_dx: e2.x, m_dy: e2.y}})
      }
    } else if (e.lineId == "INRUSH_2"){
      pTransScott.properties.partSeatT.m_listDrawInrush = {
        ...e,
        data: e.data.map((e2: any) => {return {m_dx: e2.x, m_dy: e2.y}})
      }
    } else if (e.lineId == "INTENSITY_2"){
      pTransScott.properties.partSeatT.m_listDrawIntensity = {
        ...e,
        data: e.data.map((e2: any) => {return {m_dx: e2.x, m_dy: e2.y}})
      }
    }
  })
}

const doConvertListPointTrans1 = (pTrans1: any) => {
  let chartData: any[] = pTrans1.chart;
  chartData.forEach((e: any) => {
    if (e.lineId == "INRUSH_OUT_IN"){
      pTrans1.properties.partOutIn.m_listDrawInrush = {
        ...e,
        data: e.data.map((e2: any) => {return {m_dx: e2.x, m_dy: e2.y}})
      }
    } else if (e.lineId == "INTENSITY_OUT_IN"){
      pTrans1.properties.partOutIn.m_listDrawIntensity = {
        ...e,
        data: e.data.map((e2: any) => {return {m_dx: e2.x, m_dy: e2.y}})
      }
    } else if (e.lineId == "INRUSH_OUT_OUT"){
      pTrans1.properties.partOutOut.m_listDrawInrush = {
        ...e,
        data: e.data.map((e2: any) => {return {m_dx: e2.x, m_dy: e2.y}})
      }
    } else if (e.lineId == "INTENSITY_OUT_OUT"){
      pTrans1.properties.partOutOut.m_listDrawIntensity = {
        ...e,
        data: e.data.map((e2: any) => {return {m_dx: e2.x, m_dy: e2.y}})
      }
    }
  })
}

const doConvertListPoint3Winding = (p3Winding: any) => {
  let chartData: any[] = p3Winding.chart;
  chartData.forEach((e: any) => {
    if (e.lineId == "INRUSH_PART_1"){
      p3Winding.properties.partPrimary.m_listDrawInrush = {
        ...e,
        data: e.data.map((e2: any) => {return {m_dx: e2.x, m_dy: e2.y}})
      }
    } else if (e.lineId == "INTENSITY_PART_1"){
      p3Winding.properties.partPrimary.m_listDrawIntensity = {
        ...e,
        data: e.data.map((e2: any) => {return {m_dx: e2.x, m_dy: e2.y}})
      }
    } else if (e.lineId == "INRUSH_PART_2"){
      p3Winding.properties.partSecondary.m_listDrawInrush = {
        ...e,
        data: e.data.map((e2: any) => {return {m_dx: e2.x, m_dy: e2.y}})
      }
    } else if (e.lineId == "INTENSITY_PART_2"){
      p3Winding.properties.partSecondary.m_listDrawIntensity = {
        ...e,
        data: e.data.map((e2: any) => {return {m_dx: e2.x, m_dy: e2.y}})
      }
    } else if (e.lineId == "INRUSH_PART_3"){
      p3Winding.properties.partThird.m_listDrawInrush = {
        ...e,
        data: e.data.map((e2: any) => {return {m_dx: e2.x, m_dy: e2.y}})
      }
    } else if (e.lineId == "INTENSITY_PART_3"){
      p3Winding.properties.partThird.m_listDrawIntensity = {
        ...e,
        data: e.data.map((e2: any) => {return {m_dx: e2.x, m_dy: e2.y}})
      }
    }
  })
}

const doConvertListPointTrans3 = (pTrans3: any) => {
  let chartData: any[] = pTrans3.chart;
  chartData.forEach((e: any) => {
    if (e.lineId == "INRUSH"){
      pTrans3.m_listDrawInrush = {
        ...e,
        data: e.data.map((e2: any) => {return {m_dx: e2.x, m_dy: e2.y}})
      }
    } else if (e.lineId == "INTENSITY"){
      pTrans3.m_listDrawIntensity = {
        ...e,
        data: e.data.map((e2: any) => {return {m_dx: e2.x, m_dy: e2.y}})
      }
    }
  })
}

const doConvertLinkLinePoint3WindingANDTrans = (pTrans3: any) => {
  if (pTrans3.m_listDrawInrush.data) {
    let pdPoint = pTrans3.m_listDrawInrush.data[0];
    pTrans3.m_dPointStartInrush = {m_dx: pdPoint.m_dx, m_dy: pdPoint.m_dy};
  }
  
  if (pTrans3.m_listDrawIntensity.data) {
    let pdPoint = pTrans3.m_listDrawIntensity.data[0];
    pTrans3.m_dPointStartInensity = {m_dx: pdPoint.m_dx, m_dy: pdPoint.m_dy};
  }
}

const doGetListPointCalcPoint = (chartData: any[], calcPointId: number) => {
  let lineId = "CALC_POINT_" + calcPointId.toString();
  let listdPoint = chartData.find((e: any) => e.lineId == lineId);
  listdPoint.data = listdPoint.data.map((e: any) => {return {m_dx: e.x, m_dy: e.y}});
  return listdPoint;
}

const doGetStartPointLinkLineUserCurve = (pUser: any) => {
  let arrLength = pUser.m_listDraw.data.length;
  if (arrLength == 0) {
    return null;
  }

  let pdPointHead = pUser.m_listDraw.data[0];
  let pdPointTail = pUser.m_listDraw.data[arrLength - 1];

  if (pdPointHead.m_dy < pdPointTail.m_dy) {
    return pdPointTail;
  }
  return pdPointHead;
}

const doGetTextRefV2UserCurve = (pUser: any) => {
  let sRefV2 = [pUser.refNo];
  sRefV2.push(pUser.name);

  if (pUser.notes && pUser.notes.length > 0) {
    let notes = pUser.notes.split("\n");
    notes.forEach((sOneLine: string) => {
      sRefV2.push(sOneLine);
    })
  }

  return sRefV2;
}

const getControlCurve = (control: any) => {
  let tmpControl = structuredClone(control)
  let arr = tmpControl.chart.filter(
    (e: any) => e.lineType == "CONTROL_CURVE" && !["CALC_POINT_0", "CALC_POINT_1","CALC_POINT_0_2","CALC_POINT_0_3","CALC_POINT_1_2","CALC_POINT_1_3"].includes(e.lineId) 
  )
  tmpControl = {
    ...tmpControl,
    chart: [...arr]
  }
  return tmpControl;
}

const doSetLineProperty = (listDraw: any, colorLine: any, nKind: number, nThick: number) => {
  listDraw.m_colorLine = colorLine;
  listDraw.m_nKind = nKind;
  listDraw.m_nThick = nThick;
}

const mapUserCurve = (listUser: any[], chartData: any[]) => {
  listUser.forEach((user) => {
    chartData.forEach((chart) => {
      let key1 = "user_curve_id";
      let key2 = "user_curve_id";
      if ("userCurveId" in user.listPoint[0]){
        key1 = "userCurveId";
      }
      if ("userCurveId" in chart.data[0]){
        key2 = "userCurveId";
      }
      if (key1 in user.listPoint[0] && user.listPoint[0][key1] == chart.data[0][key2]){
        chart.data = chart.data.map(
          (e: any) => {return {m_dx: e.x, m_dy: e.y}}
        )
        user.m_listDraw = chart;
      }
    })
  })
}

const convertListPoint = (pAdjustObj: any, listChartData: any[], pObjGraph: any) => {
  listChartData.forEach((chartData) => {
    let tmp = [{
      m_listdPoint: chartData.data.map(
        (e: any) => {return {m_dx: e.x, m_dy: e.y}}
      )
    }];
    doCalcSetNominal(tmp, pObjGraph);
    if (chartData.lineType == "CONTROL_CURVE") {
      if (chartData.lineId == "POINT_MAX") {
        pAdjustObj.m_listPointMax = tmp[0].m_listdPoint;
      } else if (chartData.lineId == "POINT_MIN") {
        pAdjustObj.m_listPointMin = tmp[0].m_listdPoint;
      } else {
        pAdjustObj.m_listPointCenter = tmp[0].m_listdPoint;
      }
    }
  })
  
}

const convertInfoLine = (tabMapping: Map<number, any>, idChartTab: number) => {
  let tmp = tabMapping.get(idChartTab);
  return {
    graphNo: idChartTab,
    m_bDispBand: tmp.dispBand,
    m_nKind: convertBorderDashToKind(tmp.borderDash),
    m_nWidth: tmp.borderWidth,
    m_colorNormal: tmp.borderColor
  }
}

const convertRefV2 = (tabMapping: Map<number, any>, idChartTab: number) => {
  let tmp = tabMapping.get(idChartTab);
  let minPoint = {m_dx: tmp.pointTopLeftX, m_dy: tmp.pointTopLeftY};
  if (tmp.minPointX != 0 || tmp.minPointY != 0){
    minPoint = {m_dx: tmp.minPointX, m_dy: tmp.minPointY};
  }
  return {
    graphNo: idChartTab,
    m_pointTopLeft: {m_dx: tmp.pointTopLeftX, m_dy: tmp.pointTopLeftY},
    // m_pointBottomRight: {m_dx: , m_dy: },
    m_LogFont: {lfHeight: Math.round(tmp.fontSize*40/3)},
    m_bDispLinkLine: tmp.dispLinkLine,
    m_bDispRefV2: tmp.dispRefV2,
    minPoint: minPoint,
  }
}

const convertRefV2TextGraph = (textGraph: any) => {
  return {
    m_pointTopLeft: {m_dx: textGraph.x, m_dy: textGraph.y},
    m_LogFont: {lfHeight: Math.round(textGraph.fontSize*40/3)},
  }
}

const doGetTextLineKind = (nKind: number) => {
  let sLineKind = "";
  switch (nKind) {
  case PS_SOLID:
    sLineKind = "CONTINUOUS";
    break;
  case PS_DASH:
    sLineKind = "DASHED";
    break;
  case PS_DOT:
    sLineKind = "DOT";
    break;
  case PS_DASHDOT:
    sLineKind = "DASHDOT";
    break;
  case PS_DASHDOTDOT:
    sLineKind = "DIVIDE";
    break;
  }
  return sLineKind;
}

const convertBorderDashToKind = (borderDash: []) => {
  let kind = 0;
  switch(borderDash.toString()) {
    case '':
      kind = 0;
      break;
    case '25,5':
      kind = 1;
      break;
    case '5,5':
      kind = 2;
      break;
    case '10,5,5,5':
      kind = 3;
      break;
    case '10,5,5,5,5,5':
      kind = 4;
      break;
    default:
      kind = 0;
      break;
  }
  return kind;
}

const readBaseFile = async (baseFileName: string) => {
  let baseFile;
  let baseFileContent = "";
  if (baseFileName === NAME_DXF_SKELETON)
    baseFile = DXFSkeleton
  else if (baseFileName === NAME_DXF_GRAPH)
    baseFile = DXFGraph
  else if (baseFileName === NAME_DXF_GRAPH_H)
    baseFile = DXFGraphH
  else if (baseFileName === NAME_DXF_GRAPH_L)
    baseFile = DXFGraphL

  await fetch(baseFile)
    .then((r) => r.text())
    .then((text) => {
      baseFileContent = text;
    })
    .catch((e) => {console.log(e)})
  return baseFileContent
}
