import { Box, Button, Checkbox, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Stack, Tab, Tabs, TextField, Typography } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { HarmonicRegisterModel, InfoCircuit, MsMotorModel } from '../../models/Index';
import { BeamsNumber } from "../../utils/FormatNumber";
import AlertDialog from "../common/AlertDialog";
import TextFieldOptions from "../common/TextFieldOptions";
import HarmonicRegisterDialog from "./HarmonicRegisterDialog";
import NoRowsOverlay, { NoRowsMessage } from "../common/NoRowsOverlay";
import { AgGridReact } from "ag-grid-react";
import { AG_GRID_LOCALE_JP } from "../../themes/localeJP";
import * as Constant from "../../models/Constants";
import { MS_MODE_DRAWING, MS_MODE_VOLTDROP } from "../../statics";

const numberStyle = {
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": 
    {
        display: "none",
    },
   "& input[type=number]": 
    {
        MozAppearance: "textfield",
    },
};

export type MsMotorEditDialogFormProps = {
    data: any;
    onOK: (data: any) => void;
    onCancel: () => void;
    // harmonic
    changeCombodata: any,
    controlId: string,
    defaultHarmonicInfo: any,
    onHarmonic: () => void;
    onChangeComboData: (data : any, controlId : string) => void;
    setChangeComboData: (a : any) => void;
    getHarmonicByID:(data:any) =>void;
    setIsDisableField: boolean;
    userRole: string;
    m_bModePM: boolean;
    infoCircuit: InfoCircuit;
    processMode: number;
    modeViewOnly:boolean;
};

function a11yProps(index: number) {
    return {
        id: `tab-${index}`,
        "aria-controls": `tabpanel-${index}`,
    };
}

const radioStyles = {
    padding: '0 0 0 9px', height: '20px'
}

const fieldsetStyle = {
    border: "1px solid #AAAAAA",
    padding:"auto",
}

const checkboxStyle = {
    padding: '0px 0px 0px 9px',
    height:"35px"
}
const checkboxStyles = {
    padding: '4px 4px 4px 8px',
}
//Tabs------------------
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

export const MsMotorEditDialog = (props: MsMotorEditDialogFormProps) => {
    const {infoCircuit, userRole, controlId, defaultHarmonicInfo, changeCombodata, data, onOK, onCancel, onHarmonic, onChangeComboData, setChangeComboData,getHarmonicByID,m_bModePM,setIsDisableField,
        processMode, modeViewOnly
     } = props;
    // 初期値設定
    const initialValue: MsMotorModel = {
        ...data,
    }

    const { control, handleSubmit, formState: { errors }, setValue ,getValues,setError,clearErrors} = useForm<MsMotorModel>({
        mode: 'all',
        criteriaMode: "all",
        defaultValues: initialValue,
    });
    const [selectTab, setSelectTab] = useState(0);
    const [isDisabledCapacityUnitKw, setIsDisabledCapacityUnitKw] = useState<boolean>(data.uIntHP);
    const [isDisabledUseEfficiency, setIsDisabledUseEfficiency] = useState<boolean>(!data.useEfficiency);
    const [isDisabledCalcDrop, setIsDisabledCalcDrop] = useState<boolean>(!data.calcDrop);
    const [isDisabledCalcImprovePf, setIsDisabledCalcImprovePf] = useState<boolean>(!data.calcImprovePF);
    const [isDisabledBaseLoad, setIsDisabledBaseLoad] = useState<boolean>(!data.useBaseLoad);
    const [isDisableInputAutomaticManual,setIsDisableInputAutomaticManual] = useState<boolean>((data.baseLoadInputManual=='false'||data.baseLoadInputManual == false)?false:true);
    const [isDisableManualLoadCurrent,setIsDisableManualLoadCurrent] = useState<boolean>(!getValues("manualLoadCurrent")); // 
    const [isDisableManualStartingCurrent,setIsDisableManualStartingCurrent] = useState<boolean>(!getValues("manualStartingCurrent")); //
    const [isDisableManualInrushCurrent,setIsDisableManualInrushCurrent] = useState<boolean>(!getValues("manualInrushCurrent")); // 
    const [capacityHpList] = useState<any>([]);
    const [capacityList] = useState<any>([]);
    const [MS_KW_TO_HORSEPOWER] = useState<number>(1.341);
    const [isDisableOkButton,setIsDisableOkButton] = useState<boolean>(false);
    const [listHarmonic, setListHarmonic] = useState<HarmonicRegisterModel[]>(data.harmonicList?data.harmonicList:[]);
    const [selectedRow, setSetlectedRow] = useState<Number>();
    const [selectedHarmonicDialogId, setSelectedHarmonicDialogId] = useState<Number>();
    const [isOpenedDialogEditHarmonic, setIsOpenedDialogEditHarmonic] = useState<boolean>(false);
    const [editDataHarmonicModel, setEditDataHarmonicModel] = useState<HarmonicRegisterModel>();
    const [isOpenedDialogAddHarmonic, setIsOpenedDialogAddHarmonic] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
    const [makeDataInput,setMakeDataInput] = useState<boolean>(getValues("makeDataInput"));
    const regexNumber = /^(0(\.\d+)?|1(\.0+)?)$/;
    const messageErrorRegex = "0 以上および 1 以下の数値を入力してください。";
    const [selectItem, setSelectItem] = useState<any>(null);

    const gridRef = useRef() as any;
     const noRowsOverlayComponentParams = useMemo(() => {
         return {
             noRowsMessageFunc: () => NoRowsMessage,
         };
     }, []);

     const noRowsOverlayComponent = useMemo(() => {
         return NoRowsOverlay;
     }, []);

     // 全列に共通の定義
     const defaultColDef = {
         sortable: true, // ソート可
         editable: false, // 編集不可
         resizable: true,
         suppressMovable: true,  // 列の移動を無効にする
     };

     // 列タイプの定義
     const columnTypes = {
         nonEditableColumn: { editable: false }, // 編集不可
     };
 // 列の個別定義
 const columnDefs = useMemo(() => [
    {
        headerName: "機器名称",
        field: "name",
        rowDrag: false,
        minWidth: 100,
        flex: 1.5,
    }
], [])
    // #region Method
    useEffect(() => { 
        initialValue.capacityHpList?.forEach((item:any)=>{
            if(item.dcapacity !== null){
            capacityHpList.push({label:item.dcapacity.toString(),value:item.dcapacity})
            }
        })
        initialValue.capacityList?.forEach((item:any)=>{
            if(item.dcapacity !== null){
            capacityList.push({label:item.dcapacity.toString(),value:item.dcapacity})
            }
        })
        doSetValuesMotor("");
        if(!getValues("baseLoadInputManual")){
            setValue("baseFactor",data.base_factor);
            setValue("baseCapacity",BeamsNumber(data.base_capacity,5));
        }else{
            setValue("baseCapacity",data.baseCapacity);
            setValue("baseFactor",data.baseFactor);
            setValue("baseCapacityM",data.baseCapacity);
            setValue("baseFactorM",data.baseFactor);
        }
        handleChangeBaseCapacity(1);
    },[])

    // #region Tab1
    // Submitイベント
    const handleEditSubmit = (value: MsMotorModel) => {
        const request = createRequestData(value);
        onOK(request);
    }

    // FormデータからRequestデータを作成する
    const createRequestData = (formValue: MsMotorModel) => {
        let request = {
            refNo: formValue.refNo,
            noMotor: data.noMotor,
            sPhase: formValue.sPhase,
            voltageID: formValue.voltageID,
            capacity: formValue.capacity,
            powerFactor: formValue.powerFactor,
            isLead: formValue.leadBaseLoad?false:formValue.isLead,
            startingTimes:formValue.startingTimes,
            starting600:formValue.starting600,
            inrushTime:formValue.inrushTimes,
            checkNG:formValue.checkNG,
            calcDrop: formValue.calcDrop,
            startingFactor: formValue.startingFactor,
            efficiency: formValue.efficiency,
            useBaseLoad : formValue.useBaseLoad,
            baseCapacity: formValue.baseLoadInputManual ? Number(formValue.baseCapacity) : Number(data.base_capacity),
            // leadBaseLoad: formValue.baseLoadInputManual ? formValue.leadBaseLoadM : false,
            leadBaseLoad: formValue.leadBaseLoad,
            baseFactor: formValue.baseLoadInputManual ? Number(formValue.baseFactor) : Number(data.base_factor),
            calcImprovePF: formValue.calcImprovePF,
            afterPF: formValue.afterPF,
            nodeNumber: formValue.nodeNumber,
            perUnitR: formValue.perUnitR,
            perUnitX: formValue.perUnitX,
            perUnitK: formValue.perUnitK,
            perUnitP: formValue.perUnitP,
            perUnitQ: formValue.perUnitQ,
            loadFactor: formValue.loadFactor,
            voltageMagnitude: formValue.voltageMagnitude,
            voltageAngle: formValue.voltageAngle,
            activePower: formValue.activePower,
            reactivePower: formValue.reactivePower,
            upTransKind:formValue.upTransKind,
            useEfficiency: formValue.useEfficiency,
            dispBaseLoad: formValue.dispBaseLoad,
            manualLoadCurrent:formValue.manualLoadCurrent,
            loadCurrent:formValue.fullCurrent,
            manualStartingCurrent:formValue.manualStartingCurrent,
            startingCurrent: formValue.startingCurrent,
            manualInrushCurrent:formValue.manualInrushCurrent,
            inrushCurrent:formValue.inrushCurrent,
            pageIndex :formValue.pageIndex,
            supply :formValue.supply,
            tcName :formValue.tcName,
            baseLoadInputManual :formValue.baseLoadInputManual,
            baseCapacityM :formValue.baseLoadInputManual ? Number(formValue.baseCapacityM) : Number(formValue.baseCapacityM),
            leadBaseLoadM :formValue.leadBaseLoadM,
            baseFactorM : formValue.baseLoadInputManual ? Number(formValue.baseFactorM) : Number(formValue.baseFactorM),
            baseLoadCalcRightLeft :formValue.baseLoadCalcRightLeft,
            makeDataInput: formValue.makeDataInput,
            makerName: formValue.makerName,
            makeYear: formValue.makeYear,
            makeMonth: formValue.makeMonth,
            makeNumber: formValue.makeNumber,
            type: formValue.type,
            uIntHP: formValue.uIntHP,
            capacityID: formValue.capacityID,
            capacityHpID: formValue.capacityHpID,
            viewFlowResult: formValue.viewFlowResult,
            primarySupply :formValue.primarySupply,
            viewResultText: formValue.viewResultText,
            inrush20Or40:formValue.inrush20Or40??false,
            startingTime:formValue.startingTime,
            harmonicList:formValue.harmonicList,
            inrushTimes:formValue.inrushTimes,
        } as any;
        return request;
    }

        // キャンセル
    const handleCancel = () => {
        onCancel();
    };  

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        if (newValue >= 0) {
            if (getValues('refNo') == "" ||getValues('refNo') == null || getValues('refNo') == undefined ) {
                setError("refNo",{ type: 'custom', message: '必須項目です。入力してください。' });
            }
            else if (isNaN(Number(getValues('voltageID'))) || Number(getValues('voltageID')) <= 0 || !/^[0-9\b]+$/.test(getValues('voltageID').toString())) {
                setError("voltageID",{ type: 'custom', message: '整数 > 0 を入力してください。' });
            }
            else if (isNaN(Number(getValues('capacityID'))) || Number(getValues('capacityID')) <= 0 || !/^[0-9]*[.]?[0-9]+$/.test(getValues('capacityID').toString())) {
                setError("capacityID",{ type: 'custom', message: '数字 > 0 を入力してください。' });
            }
            else if (isNaN(Number(getValues('capacityHpID'))) || Number(getValues('capacityHpID')) <= 0 || !/^[0-9]*[.]?[0-9]+$/.test(getValues('capacityHpID').toString())) {
                setError("capacityHpID",{ type: 'custom', message: '数字 > 0 を入力してください。' });
            }
            else if (isNaN(Number(getValues('powerFactor'))) || Number(getValues('powerFactor')) < 0 || Number(getValues('powerFactor')) > 1 || !/^[0-9]*[.]?[0-9]+$/.test(getValues('powerFactor').toString())) {
                setError("powerFactor",{ type: 'custom', message: '数字 0 ~ 1 を入力してください。' });
            }
            else if (isNaN(Number(getValues('efficiency'))) || Number(getValues('efficiency')) < 0 || Number(getValues('efficiency')) > 1 || !/^[0-9]*[.]?[0-9]+$/.test(getValues('efficiency').toString())) {
                setError("efficiency",{ type: 'custom', message: '数字 0 ~ 1 を入力してください。' });
            }
            else if (isNaN(Number(getValues('loadFactor'))) || Number(getValues('loadFactor')) < 0 || Number(getValues('loadFactor')) > 1 || !/^[0-9]*[.]?[0-9]+$/.test(getValues('loadFactor').toString())) {
                setError("loadFactor",{ type: 'custom', message: '数字 0 ~ 1 を入力してください。' });
            }
            else if (isNaN(Number(getValues('baseCapacity'))) || Number(getValues('baseCapacity')) < 0 || ! /^[0-9]*[.]?[0-9]+$/.test(getValues('baseCapacity').toString())) {
                setError("baseCapacity",{ type: 'custom', message: '数字 > 0 を入力してください。' });
            }
            else if (isNaN(Number(getValues('powerFactor'))) || Number(getValues('powerFactor')) < 0 || Number(getValues('powerFactor')) > 1 || !/^[0-9]*[.]?[0-9]+$/.test(getValues('powerFactor').toString())) {
                setError("powerFactor",{ type: 'custom', message: '数字 0 ~ 1 を入力してください。' });
            }
            else if (isNaN(Number(getValues('startingFactor'))) || Number(getValues('startingFactor')) < 0 || Number(getValues('startingFactor')) > 1 || !/^[0-9]*[.]?[0-9]+$/.test(getValues('startingFactor').toString())) {
                setError("startingFactor",{ type: 'custom', message: '数字 0 ~ 1 を入力してください。' });
            }
            else if (isNaN(Number(getValues('afterPF'))) || Number(getValues('afterPF')) < 0 || Number(getValues('afterPF')) > 1 || !/^[0-9]*[.]?[0-9]+$/.test(getValues('afterPF').toString())) {
                setError("afterPF",{ type: 'custom', message: '数字 0 ~ 1 を入力してください。' });
            }
            else if (!(isDisabledBaseLoad || !isDisableInputAutomaticManual||m_bModePM) && (isNaN(Number(getValues('baseFactor'))) || Number(getValues('baseFactor')) < 0 || Number(getValues('baseFactor')) > 1 || !/^[0-9]*[.]?[0-9]+$/.test(getValues('baseFactor').toString()))) {
                setError("baseFactor",{ type: 'custom', message: '数字 0 ~ 1 を入力してください。' });
            }
            else if (selectTab == 1 &&(isNaN(Number(getValues('loadCurrent'))) || Number(getValues('loadCurrent')) <= 0 || !/^[0-9]*[.]?[0-9]+$/.test(getValues('loadCurrent').toString()))) {
                setError("loadCurrent",{ type: 'custom', message: '数字 > 0 を入力してください。' });
            }
            else if (selectTab == 1 &&(isNaN(Number(getValues('startingTime'))) || Number(getValues('startingTime')) <= 0 || !/^[0-9]*[.]?[0-9]+$/.test(getValues('startingTime').toString()))) {
                setError("startingTime",{ type: 'custom', message: '数字 > 0 を入力してください。' });
            }
            else if (selectTab == 1 &&(isNaN(Number(getValues('startingTimes'))) || Number(getValues('startingTimes')) <= 0 || !/^[0-9]*[.]?[0-9]+$/.test(getValues('startingTimes').toString()))) {
                setError("startingTimes",{ type: 'custom', message: '数字 > 0 を入力してください。' });
            }
            else if (selectTab == 1 &&(isNaN(Number(getValues('startingCurrent'))) || Number(getValues('startingCurrent')) <= 0 || ! /^[0-9]*[.]?[0-9]+$/.test(getValues('startingCurrent').toString()))) {
                setError("startingCurrent",{ type: 'custom', message: '数字 > 0 を入力してください。' });
            }
            else if (selectTab == 1 &&(isNaN(Number(getValues('starting600'))) || Number(getValues('starting600')) <= 0 || !/^[0-9]*[.]?[0-9]+$/.test(getValues('starting600').toString()) || Number(getValues('starting600')) > getValues('startingTime'))) {
                setError("starting600",{ type: 'custom', message: '0 < 数字 <= 「始動時間(s)」 を入力してください。' });
            }
            else if (selectTab == 1 &&(isNaN(Number(getValues('inrushTimes'))) || Number(getValues('inrushTimes')) <= 0 || !/^[0-9]*[.]?[0-9]+$/.test(getValues('inrushTimes').toString()) || Number(getValues('inrushTimes')) < getValues('startingTimes'))){
                setError("inrushTimes",{ type: 'custom', message: '0 < 数字 <= 「始動電流(Imの倍数)」 を入力してください。' });
            }
            else if (selectTab == 1 &&(isNaN(Number(getValues('inrushCurrent'))) || Number(getValues('inrushCurrent')) <= 0 || !/^[0-9]*[.]?[0-9]+$/.test(getValues('inrushCurrent').toString()))) {
                setError("inrushCurrent",{ type: 'custom', message: '数字 > 0 を入力してください。' });
            }
            else{
                setSelectTab(newValue);
                if(newValue == 1){
                    doSetValuesMotor1("");
                }
            }
        }
        // setSelectTab(newValue);
        //  if(newValue == 1){
        //      doSetValuesMotor1("");
        //  }
    };

    const handleDisabled = (e: any) => {
        switch (e.target.name) {
            case "uIntHP":
                setIsDisabledCapacityUnitKw(!isDisabledCapacityUnitKw);
                break;
            case "baseLoadInputManual":
                if(getValues("baseLoadInputManual")== true){
                    setIsDisableInputAutomaticManual(true);
                }
                else{
                    setIsDisableInputAutomaticManual(false);
                }
                break;
            case "useEfficiency":
                setIsDisabledUseEfficiency(!isDisabledUseEfficiency);
                break;
            case "calcDrop":
                setIsDisabledCalcDrop(!isDisabledCalcDrop);
                break;
            case "calcImprovePF":
                setIsDisabledCalcImprovePf(!isDisabledCalcImprovePf);
                break;
            case "useBaseLoad":
                setIsDisabledBaseLoad(!isDisabledBaseLoad);
                break;
            case "manualLoadCurrent":
                setIsDisableManualLoadCurrent(!isDisableManualLoadCurrent);
                break;
            case "manualStartingCurrent":
                setIsDisableManualStartingCurrent(!isDisableManualStartingCurrent);
                break;
            case "manualInrushCurrent":
                setIsDisableManualInrushCurrent(!isDisableManualInrushCurrent);
                break;
            default:
                break;
        }
    }

    // #region

    const doSetValuesMotor = (name:any) => {
        if(name != "powerFactor"){
            setValue("powerFactor",doGetTextPowerFactor());
        }
        if(name != "efficiency"){
            setValue("efficiency",doGetTextEfficiency());
        }
        setValue("fullCurrent",doGetTextLoadCurrent(false))

        if(getValues("powerFactor") >=0 && getValues("powerFactor") <= 1){
            setValue("phaseCurrent",doGetTextPhaseCurrent());
        }else{
            setValue("phaseCurrent",0);
        }
        if(name != "loadFactor"){
            setValue("loadFactor",doGetTextLoadFactor());
        }
        // TODO: CHECK _SCAL _ACADEMIC
        setValue("startingFactor",doGetTextStartingFactor());
        setValue("afterPF",doGetTextAfterPF());
        setValue("baseCapacity",doGetTextBaseCapcity());
        setValue("baseCurrent",doGetTextBaseLoadCurrent());
        if(name != "baseFactor"){
            setValue("baseFactor",doGetTextBaseFactor());
        }
    }

    const doGetTextBaseFactor = () => {
        return BeamsNumber(Number(getValues("baseFactor")),5);
    }

    const doGetTextBaseLoadCurrent = () => {
        let baseCurrent = doGetValueBaseLoadCurrent();
        return BeamsNumber(Number(baseCurrent),4);
    }

    const doGetValueBaseLoadCurrent = () => {
        let baseLoad = 0;
        if(getValues("voltageID") > 0){
            baseLoad = getValues("baseCapacity") * 1000 / getValues("voltageID") / Math.sqrt(3.0);
        }
        return baseLoad;
    }

    const doGetTextBaseCapcity = () => {
        return BeamsNumber(Number(getValues("baseCapacity")),5);
    }

    const doGetTextAfterPF = () => {
        return BeamsNumber(Number(getValues("afterPF")),5);
    }

    const doGetTextStartingFactor = () => {
        return BeamsNumber(Number(getValues("startingFactor")),5);
    }
    
    const doGetTextLoadFactor = () => {
        return BeamsNumber(Number(getValues("loadFactor")),5);
    }

    const doGetTextPhaseCurrent = () => {
        let phaseCurrent = 0;
        phaseCurrent = doGetPhaseCurrent(getValues("powerFactor"));
        return BeamsNumber(Number(phaseCurrent),4);
    }

    const doGetPhaseCurrent = (powerFactor:any) => {
        if(powerFactor <-1 || powerFactor > 1){
            return 0;
        }
        return Math.acos(powerFactor) * 180 / Math.PI * -1;
    }

    const doGetTextLoadCurrent = (unit?:boolean) =>{
        let loadCurrent = 0;
        if(getValues("manualLoadCurrent") == true){
            loadCurrent = getValues("loadCurrent");
        }else{
            loadCurrent = doGetValueFullCurrent();
        }
        return BeamsNumber(Number(loadCurrent),4);
    }

    const doGetValueFullCurrent = () => {
        let fullCurrent;
        if(getValues("voltageID") <= 0){
            fullCurrent = 0;
        }else{
            let capacity =  getValues("capacity") * 1000 * getValues("loadFactor");
            if(getValues("useEfficiency") == true){
                if(getValues("efficiency") > 0){
                    capacity /= getValues("efficiency");
                }else{
                    capacity = 0;
                } 
            }
            if(getValues("powerFactor") == 0){
                fullCurrent = capacity / getValues("voltageID");
                if(getValues("sPhase") != true){
                    fullCurrent /= Math.sqrt(3.0);
                }
            }else{
                fullCurrent = capacity / getValues("voltageID") / getValues("powerFactor");
                if(getValues("sPhase") != true){
                    fullCurrent /= Math.sqrt(3.0);
                }
            }
        }
        return fullCurrent;
    }

    const doGetTextPowerFactor = () => {
        return BeamsNumber(Number(getValues("powerFactor")),5);
    }
    
    const doGetTextEfficiency = () => {
        return BeamsNumber(Number(getValues("efficiency")),5);
    }

    const handleEditChangeVoltage = (e:any) => {
        doSetValuesMotor(e.target.name);
        clearErrorsMotor()
    }

    const handleOnUnitKw = (e:any) => {
        setValue("uIntHP",false);
        const capacity = getValues("capacityID");
        setValue("capacity",capacity);
        doCalcSetValue(initialValue.capacityList,capacity);
        doSetValuesMotor(e.target.name);    
        clearErrorsMotor()
    }

    const handleOnUnitHorsePower = (e:any) => {
        setValue("uIntHP",true);
        const capacity = getValues("capacityHpID") / MS_KW_TO_HORSEPOWER;
        setValue("capacity",capacity);
        doCalcSetValue(initialValue.capacityHpList,capacity);
        doSetValuesMotor(e.target.name);    
        clearErrorsMotor()

    }

    const doCalcSetValue = (list:any,capacity:any) => {
        if(capacity <= 0){
            setValue("powerFactor",0);
            setValue("startingTimes",0);
            setValue("starting600",0);
            setValue("inrushTimes",0);
            return;
        }
        const object = {powerFactor:0,efficiency:0,startingTimes:0,starting600:0,inrushTimes:0,startTime:0};
        for(const item of list){
            if(capacity <= item.dcapacity || item.dcapacity == -1){
                if(object.powerFactor != null){
                    object.powerFactor = item.dpowerFactor;
                }
                if(object.efficiency != null){
                    object.efficiency = item.defficiency;
                }
                if(object.startingTimes != null){
                    object.startingTimes = item.dstartingCurrent;
                }
                if(object.starting600 != null){
                    object.starting600 = item.dstartingTime;
                }
                if(object.inrushTimes != null){
                    object.inrushTimes = item.dinrushCurrent;
                }
                if(object.startTime != null){
                    object.startTime = item.dstartTime;
                }
                break;
            }
        }
        setValue("powerFactor",object.powerFactor);
        setValue("efficiency",object.efficiency);
        if(getValues("manualLoadCurrent") != true){
            setValue("loadCurrent",doGetValueFullCurrent());
        }
        setValue("startingTimes",object.startingTimes);
        setValue("starting600",object.starting600);
        setValue("startingTime",object.startTime);
        setValue("inrushTimes",object.inrushTimes);
    }

    const handleEditChangeCapacity = (e:any) => {
        const capacity = e.target.value;
        doCalcSetValue(initialValue.capacityList,capacity);
        setValue("capacity",capacity);
        setValue("capacityID",capacity);
        doSetValuesMotor(e.target.name);
        clearErrorsMotor()

    }

    const handleSelectChangeCapacity = (e:any) => {
        const capacity = Number(e.target.value);
        doCalcSetValue(initialValue.capacityList,capacity);
        setValue("capacity",capacity);
        setValue("capacityID",capacity);
        doSetValuesMotor(e.target.name);
    }

    const handleEditChangeCapacityHp = (e:any) => {
        const capacity = Number(e.target.value) / MS_KW_TO_HORSEPOWER;
        doCalcSetValue(initialValue.capacityHpList,capacity);
        setValue("capacity",capacity);
        setValue("capacityHpID",e.target.value);
        doSetValuesMotor(e.target.name);
        clearErrorsMotor()

    }

    const handleSelectChangeCapacityHp = (e:any) => {
        const capacity = Number(e.target.value) / MS_KW_TO_HORSEPOWER;
        doCalcSetValue(initialValue.capacityHpList,capacity);
        setValue("capacity",capacity);
        setValue("capacityHpID",Number(e.target.value));
        doSetValuesMotor(e.target.name);
    }

    const handleChangePowerFactor = (e:any) => {
        doSetValuesMotor(e.target.name);
    }

    const handleOnUseEfficiency = (e:any) => {
        if(e.target.checked == false){
            if(getValues("manualLoadCurrent") != true){
                setValue("loadCurrent",doGetValueFullCurrent());
            }
        }
        doSetValuesMotor(e.target.name);
    }

    const handleChangeEfficiency = (e:any) => {
        // check Valididation
        doSetValuesMotor("efficiency");
        clearErrorsMotor()
    }

    const handleChangeLoadFactor = (e:any) => {
        doSetValuesMotor(e.target.name);
        clearErrorsMotor()
    }

    const handleOnBaseLoadAutomatic = (e:any) => {
        // TODO: Connect
    }

    const handleOnBaseLoadInputManual = (e:any) => {
        // TODO: Connect
    }

    const handleChangeBaseCapacity = (e:any) => {
        setValue("baseCurrent",doGetTextBaseLoadCurrent());
        clearErrorsMotor()
    }

    const doSetValuesMotor1 = (name:any) => {
        if(name != "loadCurrent"){
            setValue("loadCurrent",doGetTextLoadCurrent());
        }
        // check mode PM
        if(name != "startingTimes"){
            if(getValues("manualStartingCurrent") == true){
                setValue("startingTimes",doGetValueStartingTimes());
            }else{
                setValue("startingCurrent",doGetValueStartingCurrent());
            }
            setValue("startingTimes",doGetTextStartingTimes());
        }
        if(name != "startingCurrent"){
            setValue("startingCurrent",doGetTextStartingCurrent());
        }
        if(name !='starting600'){
            setValue("starting600",doGetTextStarting600());
        }
        if(name !='startingTime'){
             setValue("startingTime",doGetTextStartingTime());
        }

        if(name != "inrushTimes") {
            if(getValues("manualInrushCurrent") == true){
                setValue("inrushTimes",doGetValueInrushTimes());
            }else{
                setValue("inrushCurrent",doGetValueInrushCurrent());
            }
            setValue("inrushTimes",doGetTextInrushTimes());
        }
        if(name != "inrushCurrent"){
            setValue("inrushCurrent",doGetTextInrushCurrent());
        }
        // check ACEDEMIC
        setValue("loadCurrentBaseLoad",doGetTextTotalCurrent());
        setValue("baseLoadCurrent",doGetTextStartingCurrentBaseLoad());
        setValue("baseInrushCurrent",doGetTextInrushCurrentBaseLoad());
    }

    const doGetTextInrushTimes = () => {
        const inrushTimes = doGetValueInrushTimes();
        return BeamsNumber(Number(inrushTimes),5);
    }

    const doGetTextStartingCurrent = () => {
        let startingCurrent = doGetValueStartingCurrent();
        return BeamsNumber(Number(startingCurrent),5);
    }

    const doGetTextStartingTime = () => {
        return BeamsNumber(Number(getValues("startingTime")),5);
    }

    const doGetTextTotalCurrent = () => {
        let loadCurrent = doGetValueLoadCurrent();
        let baseCurrent = doGetValueBaseLoadCurrent();
        const sum = loadCurrent + baseCurrent;
        return BeamsNumber(Number(sum),4);
    }

    const doGetTextStartingCurrentBaseLoad = () => {
        let baseCurrent = doGetValueBaseLoadCurrent();
        let startingCurrent = doGetValueStartingCurrent();
        const sum = startingCurrent + baseCurrent;
        return BeamsNumber(Number(sum),4);
    }

    const doGetTextInrushCurrentBaseLoad = () => {
        let inrushCurrent = doGetValueInrushCurrent();
        let baseCurrent = doGetValueBaseLoadCurrent();
        const sum = inrushCurrent + baseCurrent;
        return BeamsNumber(Number(sum),4);
    }

    const doGetTextInrushCurrent = () => {
        let inrushCurrent = doGetValueInrushCurrent();
        return BeamsNumber(Number(inrushCurrent),5);
    }

    const doGetValueInrushCurrent = () => {
        let inrushCurrent = 0;
        if(getValues("manualInrushCurrent") == true){
            inrushCurrent = getValues("inrushCurrent");
        }else{
            inrushCurrent = getValues("inrushTimes") * doGetValueLoadCurrent();
        }
        return inrushCurrent;
    }
    
    const doGetValueInrushTimes = () => {
        let inrushTime = 0;
        let loadCurrent = doGetValueLoadCurrent();
        if(loadCurrent != 0){
            inrushTime = getValues("inrushCurrent") / loadCurrent;
        }
        return inrushTime;
    }

    const doGetTextStarting600 = () => {
        return BeamsNumber(Number(getValues("starting600")),5);
    }

    const doGetTextStartingTimes = () => {
        return BeamsNumber(Number(getValues("startingTimes")),5);
    }

    const doGetValueStartingCurrent = () => {
        let startingCurrent = 0;
        if(getValues("manualStartingCurrent") == true){
            startingCurrent = getValues("startingCurrent");
        }else{
            startingCurrent = getValues("startingTimes") * doGetValueLoadCurrent();
        }
        return startingCurrent;
    }

    const doGetValueStartingTimes = () => {
        let startingTimes = 0;
        let loadCurrent = doGetValueLoadCurrent();
        if(loadCurrent != 0){
            startingTimes = getValues("startingCurrent") / loadCurrent;
        }
        return startingTimes;
    } 

    const doGetValueLoadCurrent = () => {
        let loadCurrent;
        if(getValues("manualLoadCurrent") == true){
            loadCurrent = getValues("loadCurrent");
        }else{
            loadCurrent = doGetValueFullCurrent();
        }
        return loadCurrent;
    }

    const handleManualCalculateInput = (e:any) => {
        setValue("baseLoadInputManual",true);
        handleDisabled(e);
        handleOnBaseLoadInputManual(e);
        handleChangeFormat(e);
        setValue("baseCapacity",getValues("baseCapacityM"));
        setValue("baseFactor",getValues("baseFactorM"));
        setValue("leadBaseLoad",getValues("leadBaseLoadM"));
        setValue("isLead",getValues("leadBaseLoadM"));
        handleChangeBaseCapacity(1);
    }

    const handleAutoCalculateInput = (e:any) => {
        setValue("baseLoadInputManual",false);
        handleDisabled(e);
        handleOnBaseLoadAutomatic(e);
        handleChangeFormat(e);
        setValue("baseCapacity",BeamsNumber(data.base_capacity,5));
        setValue("baseFactor", data.base_factor);
        setValue("isLead",data.leadBaseLoad?true:false);
        setValue("leadBaseLoad",data.leadBaseLoad);
        handleChangeBaseCapacity(1);
    }
    // #endregion

    const handleFieldChange = (e:any) => {
        setValue(e.target.name,e.target.value);
    }
    
    // // #region Tab 2
    const handleOnManualLoadCurrent = (e:any) => {
        doSetValuesMotor1(e.target.name);
    }

    const handleChangeLoadCurrent = (e:any) => {
        doSetValuesMotor1(e.target.name);
    }

    const handleChangeStartTime = (e:any) => {
        doSetValuesMotor1(e.target.name);
    }

    const handleChangeStartingTimes =(e:any) => {
        // validate
        if(Number(e.target.value) <= 0){
            // DISABLE OK
        }else{
            // ENABLE OK
        }
        doSetValuesMotor1(e.target.name);
        clearErrorsMotor()
    }

    const handleOnManualStartingCurrent = (e:any) => {
        doSetValuesMotor1(e.target.name);
    }

    const handleChangeStartingCurrent = (e:any) => {
        doSetValuesMotor1(e.target.name);
        clearErrorsMotor()
    }

    const handleChangeStarting600 = (e:any) => {
        doSetValuesMotor1(e.target.name);
    }

    const handleChangeInRushTime = (e:any) => {
        doSetValuesMotor1(e.target.name);
        clearErrorsMotor()
    }

    const handleChangeInRushCurrent = (e:any) => {
        doSetValuesMotor1(e.target.name);
        clearErrorsMotor()
    }

    const handleOnManualInrushCurrent = (e:any) => {
        doSetValuesMotor1(e.target.name);
    }

    const handleOnChangeRadio = (event:any) => {
        handleFieldChange(event);
    }
    const handleCheckDisable = () => {
        let isDisabled = false;
        const starting600 = Number(getValues("starting600"));
        const startingTime = Number(getValues("startingTime"));
        const capacity = Number(getValues("capacity"));
        const powerFactor = Number(getValues("powerFactor"));
        const efficiency = Number(getValues("efficiency"));
        const startingFactor = Number(getValues("startingFactor"))
        const manualLoadCurrent = getValues("manualLoadCurrent");
        const loadCurrent = Number(getValues("loadCurrent"));
        const manualStartingCurrent = getValues("manualStartingCurrent");
        const startingTimes = Number(getValues("startingTimes"));
        const startingCurrent = Number(getValues("startingCurrent"));
        const manualInrushCurrent = getValues("manualInrushCurrent");
        const inrushTime = Number(getValues("inrushTimes"));
        const inrushCurrent = Number(getValues("inrushCurrent"));

        if(Number(startingTime) < Number(starting600))
            isDisabled = true;
        else if(capacity <= 0)
            isDisabled = true;
        else if(powerFactor < 0 || powerFactor > 1 )
            isDisabled = true;
        else if(efficiency < 0 || efficiency > 1)
            isDisabled = true;
        else if(startingFactor < 0 || startingFactor > 1)
            isDisabled = true;
        else if(manualLoadCurrent == true && loadCurrent <= 0)
            isDisabled = true;
        else if(manualStartingCurrent != true && startingTimes <=0)
            isDisabled = true;
        else if(manualStartingCurrent == true && startingCurrent <=0)
            isDisabled = true;
        else if(starting600 <=0)
            isDisabled = true;
        else if(manualInrushCurrent != true && inrushTime <= 0)
            isDisabled = true;
        else if(inrushTime < startingTimes)
            isDisabled = true;
        else if(manualInrushCurrent == true && inrushCurrent <= 0)
            isDisabled = true;
        if(isDisabled == true)
            setIsDisableOkButton(true);
        else{
            setIsDisableOkButton(false);
        }
    }
    //  #endregion Tab2

    // #region Tab3
    const getlastIdHarmonicRegisterModel = () => {
        return initialValue.harmonicList?.length > 0 ?
            Math.max(...initialValue.harmonicList.map((item: any) => item.hamorItemID)) : 0;
    }

    const [lastIdHamornicNewFormModel, setLastIdHamornicNewFormModel] = useState(getlastIdHarmonicRegisterModel());

    const handleOpenAddDialogHarmonicModel = () => {
        onHarmonic()
        setIsOpenedDialogAddHarmonic(true);
    }

    // Submitイベント
    const handleAddHarmonicModel = (data:any) => {
        setIsOpenedDialogAddHarmonic(false);
        const newList = [...listHarmonic];
        newList.push(data);
        setListHarmonic(newList);
        setValue("harmonicList",newList);
        setLastIdHamornicNewFormModel(data.hamorItemID);
    }

    // キャンセル
    const handleCancelAddHarmonicModel = () => {
        setIsOpenedDialogAddHarmonic(false);
    }

    const handleClickItemInListItem = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number, val: number) => {
        if (selectedRow === index) {
            setSetlectedRow(undefined);
            setSelectedHarmonicDialogId(undefined);
        }
        else {
            setSetlectedRow(index);
            setSelectedHarmonicDialogId(val);
        }
    }

    const handleOpenEditDialogHarmonicModelById = (data:any) => {
        setIsOpenedDialogEditHarmonic(true);
        setEditDataHarmonicModel(selectItem);
        getHarmonicByID(selectItem)
    }
    
    const handleOpenEditDialogHarmonicModel = () => {
        setIsOpenedDialogEditHarmonic(true);
        setEditDataHarmonicModel(selectItem);
        getHarmonicByID(selectItem)
    }


    // Submitイベント
    const handleEditHamornicNewModel = (data: HarmonicRegisterModel) => {
        setIsOpenedDialogEditHarmonic(false);
        const arrOptions = [...listHarmonic];
        const IndexOfHamornicNew = arrOptions.findIndex(item => item.hamorItemID === data.hamorItemID);
        IndexOfHamornicNew !== -1 && (arrOptions[IndexOfHamornicNew] = data);
        setListHarmonic(arrOptions);
        setValue("harmonicList", arrOptions);
    }

    // キャンセル
    const handleCancelEditHarmonicModel = () => {
        setIsOpenedDialogEditHarmonic(false);
    }

    // 消去
    const handleDeleteHamornicData = () => {
        const harmonicList = [...listHarmonic].filter(options => options.hamorItemID !== selectItem.hamorItemID);
        setListHarmonic(harmonicList);
        setValue("harmonicList", harmonicList);
        setSetlectedRow(undefined);
    }

    const initialValueHarmonicForm: HarmonicRegisterModel = {
        ...defaultHarmonicInfo,
        hamorItemID: lastIdHamornicNewFormModel + 1,
        name: "",
        manufacture: "",
        type: "",
        capacity: 0,
        number: 0,
        totalCapacity: 0,
        convertRated: 1,
        operatingRatio: 80,
        current7: 0,
        current5: 0,
        current13: 0,
        current17: 0,
        current19: 0,
        current11: 0,
        current23: 0,
        current25: 0,
        equipvalentCapacity: 1,
    }

    const handleChangeFormat = (e: any) => {
        setValue(e.target.name, e.target.value);
    }
    const clearErrorsMotor = () =>{
        if(getValues('powerFactor') >= 0 && getValues('powerFactor') <= 1){
            clearErrors('powerFactor')
        }
        if(getValues('capacityHpID') > 0){
            clearErrors('capacityHpID')
        }
        if(getValues('efficiency') >= 0 && getValues('efficiency') <= 1){
            clearErrors('efficiency')
        }
        if(getValues('starting600') <= getValues('startingTime')){
            clearErrors('starting600')
        }
        if(getValues('startingCurrent') > 0){
            clearErrors('startingCurrent')
        }
        if(getValues('startingTimes') > 0){
            clearErrors('startingTimes')
        }
        if(getValues('inrushTimes') > 0 && getValues('inrushTimes') <= getValues('startingTimes')){
            clearErrors('inrushTimes')
        }
        if(getValues('inrushCurrent') > 0){
            clearErrors('inrushCurrent')
        }
        if(getValues('loadCurrent') > 0){
            clearErrors('loadCurrent')
        }
    }
    // #endregion Tab3
    const handleFieldBaseFactor = () =>{
        if(getValues('baseLoadInputManual') == true){
            if(isNaN(Number(getValues('baseFactor'))) || Number(getValues('baseFactor')) < 0 || Number(getValues('baseFactor')) > 1 || !/^[0-9]*[.]?[0-9]+$/.test(getValues('baseFactor').toString())){
                setError("baseFactor",{ type: 'custom', message: '数字 0 ~ 1 を入力してください。' });
                setIsDisableOkButton(true)
            }
        }
    }
    const onChangeBaseFactor =(e:any) =>{
        setValue(e.target.name,e.target.value)
        const baseFactor = e.target.value
        if(isNaN(Number(baseFactor)) || Number(baseFactor) < 0 || Number(baseFactor) > 1 || !/^[0-9]*[.]?[0-9]+$/.test(baseFactor.toString())){
                setError("baseFactor",{ type: 'custom', message: '数字 0 ~ 1 を入力してください。' });
        }
        else{
                clearErrors("baseFactor")
                setIsDisableOkButton(false)
            }
    }
    // #endregion Method


    return (
        <>
            <div style={{ height: '100%', width: '100%' }}>
                <form onSubmit={handleSubmit(handleEditSubmit)} style={{ width: '100%',margin:"auto"}}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={selectTab} onChange={handleChange}
                            variant="scrollable" 
                            scrollButtons 
                            allowScrollButtonsMobile 
                            sx={{
                                '& .MuiButtonBase-root.MuiTabScrollButton-root.Mui-disabled': {
                                    display: "none"
                                }
                            }}
                        >
                            <Tab label="基本" {...a11yProps(0)} />
                            <Tab label="保護協調" {...a11yProps(1)} />
                            <Tab label="高調波発生機器" {...a11yProps(2)} />
                        </Tabs>
                    </Box>
                    {/* 基本タブ */}
                    <div style={{height:"40vh",width:"100%",overflowY:"auto"}}>
                        <TabPanel value={selectTab} index={0}>
                            {/* 上部 */}
                            <Grid container my={1}>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={5.5}>
                                            <Stack spacing={0.3}>
                                                <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                    <Typography variant="body2" width="100%">デバイス名:</Typography>
                                                    <Controller
                                                        name="refNo"
                                                        control={control}
                                                        rules={{ required: '必須項目です。入力してください。' ,
                        
                                                        }}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                fullWidth
                                                                type="refNo"
                                                                size="small"
                                                                onFocus={e => e.target.select()}
                                                                InputLabelProps={{
                                                                    style: { color: 'black' },
                                                                }}
                                                                InputProps={{
                                                                    sx:{height:32, backgroundColor:m_bModePM || modeViewOnly?'floralwhite':"white", color:modeViewOnly?"grey":"black"},
                                                                }}
                                                                inputProps={{readOnly:modeViewOnly}}
                                                                disabled={m_bModePM}
                                                                error={!!errors?.refNo}
                                                                helperText={errors?.refNo?.message}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                                <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                    <Typography variant="body2" width="100%">回路電圧(V):</Typography>
                                                    <FormControl fullWidth size="small">
                                                        <Controller
                                                            name="voltageID"
                                                            control={control}
                                                            rules={{ required: '必須項目です。入力してください。' ,
                                                                pattern:{
                                                                    value: /^[0-9\b]+$/,
                                                                    message: '数字を入力してください。'
                                                                },
                                                                validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                            }}
                                                            render={({ field }: any) => (
                                                                <>
                                                                    <TextFieldOptions
                                                                        {...field}
                                                                        options={initialValue.voltageList}
                                                                        size="small"
                                                                        setValue={(name:any,value:any) =>{
                                                                            field.onChange({target:{name:name,value:value}});
                                                                            handleFieldChange({target:{name:name,value:value}});
                                                                            handleEditChangeVoltage({target:{name:name,value:value}});
                                                                        }}
                                                                        // onChange={(e:any)=>{
                                                                        //     handleFieldChange(e);
                                                                        //     handleEditChangeVoltage(e);
                                                                        // }}
                                                                        // onSelectValue={(name:any,value:any) => {
                                                                        //     handleFieldChange({target:{name:name,value:value}});
                                                                        //     handleEditChangeVoltage({target:{name:name,value:value}});
                                                                        // }}
                                                                        height={32}
                                                                        disabled={getValues("isSourceVoltage")||m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING}
                                                                        error={!!errors?.voltageID}
                                                                        helperText={errors?.voltageID?.message}
                                                                        maxLength={6}
                                                                    >
                                                                    </TextFieldOptions>
                                                                </>
                                                            )}
                                                        />
                                                    </FormControl>
                                                </Stack>
                                                <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                    <Grid container>
                                                        <Grid item xs={3} mt={0.5}>
                                                            <Typography variant="body2">定格出力:</Typography>
                                                        </Grid>
                                                        <Grid item xs={6} spacing={1}>
                                                            <Controller
                                                                name="uIntHP"
                                                                control={control}
                                                                render={({ field }) => (
                                                                    <>
                                                                        <RadioGroup {...field}>
                                                                            {/* 定格出力(kW) */}
                                                                            <Stack direction="row">
                                                                                <FormControlLabel style={{marginRight:"9px"}} value={false}
                                                                                    control={<Radio name="uIntHP" style={radioStyles} 
                                                                                        onChange={
                                                                                        (e: any) => {
                                                                                            handleOnUnitKw(e);
                                                                                            handleDisabled(e);
                                                                                        }} 

                                                                                        />} 
                                                                                        disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING}
                                                                                        label={<Typography variant="body2" color={m_bModePM || processMode > MS_MODE_DRAWING?'grey':'black'}>(kW)</Typography>}/>
                                                                                    <FormControl sx={{width:"123px"}} size="small">
                                                                                        <Controller
                                                                                            name="capacityID"
                                                                                            control={control}
                                                                                            rules={{ required: '必須項目です。入力してください。',
                                                                                                pattern:{
                                                                                                    value: /^[0-9]*[.]?[0-9]+$/,
                                                                                                    message: '数字を入力してください。'
                                                                                                },
                                                                                                validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                                                            }}
                                                                                            render={({ field }: any) => (
                                                                                                <>
                                                                                                    <TextFieldOptions
                                                                                                        {...field}
                                                                                                        options={capacityList}
                                                                                                        size="small"
                                                                                                        setValue={(name:any,value:any) =>{
                                                                                                            field.onChange({target:{name:name,value:value}});
                                                                                                            handleFieldChange({target:{name:name,value:value}});
                                                                                                            handleEditChangeCapacity({target:{name:name,value:value}});
                                                                                                        }}
                                                                                                        // onChange={(e:any)=> {
                                                                                                        //     handleFieldChange(e);
                                                                                                        //     handleEditChangeCapacity(e);
                                                                                                        // }}
                                                                                                        // onSelectValue={(name:any,value:any) => {
                                                                                                        //     handleFieldChange({target:{name:name,value:value}});
                                                                                                        //     handleSelectChangeCapacity({target:{name:name,value:value}});
                                                                                                        // }}
                                                                                                        error={!!errors?.capacityID}
                                                                                                        helperText={errors?.capacityID?.message}
                                                                                                        height={32}
                                                                                                        disabled={isDisabledCapacityUnitKw||m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING}
                                                                                                        maxLength={6}
                                                                                                        
                                                                                                    >
                                                                                                    </TextFieldOptions>
                                                                                                </>
                                                                                            )}
                                                                                        />
                                                                                    </FormControl>
                                                                            </Stack>
                                                                            {/* 定格出力(HP) */}
                                                                            <Stack direction="row" mt={0.3} >
                                                                                <FormControlLabel style={{marginRight:"9px"}} value={true}
                                                                                    control={<Radio name="uIntHP" style={radioStyles} 
                                                                                        onChange={
                                                                                        (e: any) => 
                                                                                        {
                                                                                            handleOnUnitHorsePower(e);
                                                                                            handleDisabled(e);
                                                                                        }} 
                                                                                        />} 
                                                                                        label={<Typography variant="body2" color={m_bModePM || processMode > MS_MODE_DRAWING?'grey':'black'}>(HP)</Typography>}
                                                                                        disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING}
                                                                                        />
                                                                                <Grid>
                                                                                    <FormControl fullWidth size="small">
                                                                                        <Controller
                                                                                            name="capacityHpID"
                                                                                            control={control}
                                                                                            rules={{ required: '必須項目です。入力してください。',
                                                                                                pattern:{
                                                                                                    value: /^[0-9]*[.]?[0-9]+$/,
                                                                                                    message: '数字を入力してください。'
                                                                                                },
                                                                                                validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                                                            }}
                                                                                            render={({ field }: any) => (
                                                                                                <>
                                                                                                    <TextFieldOptions
                                                                                                        {...field}
                                                                                                        options={capacityHpList}
                                                                                                        size="small"
                                                                                                        setValue={(name:any,value:any) =>{
                                                                                                            field.onChange({target:{name:name,value:value}});
                                                                                                            handleFieldChange({target:{name:name,value:value}});
                                                                                                            handleEditChangeCapacityHp({target:{name:name,value:value}});
                                                                                                        }}
                                                                                                        error={!!errors?.capacityHpID}
                                                                                                        helperText={errors?.capacityHpID?.message}
                                                                                                        // onChange={(e:any) => {
                                                                                                        //     handleFieldChange(e);
                                                                                                        //     handleEditChangeCapacityHp(e);
                                                                                                        // }}
                                                                                                        // onSelectValue={(name:any,value:any) => {
                                                                                                        //     handleFieldChange({target:{name:name,value:value}});
                                                                                                        //     handleSelectChangeCapacityHp({target:{name:name,value:value}});
                                                                                                        // }}
                                                                                                        maxLength={5}
                                                                                                        height={32}
                                                                                                        disabled={!isDisabledCapacityUnitKw|| m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING}

                                                                                                    >
                                                                                                    </TextFieldOptions>
                                                                                                </>
                                                                                            )}
                                                                                        />
                                                                                    </FormControl>
                                                                                </Grid>
                                                                            </Stack>
                                                                        </RadioGroup>
                                                                    </>
                                                                )}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Stack>
                                                <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                    <Typography variant="body2" width="100%" color={setIsDisableField||m_bModePM?'grey':'black'}>力率(遅れ):</Typography>
                                                        <Controller
                                                        name="powerFactor"
                                                        control={control}
                                                        rules={{ required: '必須項目です。入力してください。',
                                                            pattern:{
                                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                                message: '数字を入力してください。'
                                                            },
                                                            validate: value => value >= 0 && value <= 1 || '数字 0 ~ 1 を入力してください。' ,
                                                        }}
                                                        render={({ field }) => {
                                                            const fieldWithoutRef = { ...field, ref: undefined };
                                                            return (
                                                                <TextField
                                                                    {...fieldWithoutRef}
                                                                    inputRef={field.ref}
                                                                    fullWidth
                                                                    // type="number"
                                                                    size="small"
                                                                   
                                                                    onFocus={e => e.target.select()}
                                                                    InputLabelProps={{
                                                                        style: { color: 'black' },
                                                                    }}
                                                                    onChange={
                                                                        (e:any) => 
                                                                        {
                                                                            field.onChange(e);
                                                                            handleCheckDisable();
                                                                            handleChangePowerFactor(e);
                                                                        }
                                                                    }
                                                                    error={!!errors?.powerFactor}
                                                                    helperText={errors?.powerFactor?.message}
                                                                    sx={numberStyle}
                                                                    disabled={setIsDisableField||m_bModePM || processMode > MS_MODE_DRAWING}
                                                                    InputProps={{
                                                                        sx:{height:32,backgroundColor:m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING?'floralwhite':'white', color:modeViewOnly?"grey":"black"},
                                                                    }}
                                                                    inputProps={{maxlength: 5, readOnly:modeViewOnly}}
                                                                />
                                                            )
                                                        }}
                                                    />
                                                </Stack>
                                                <Stack sx={{ flexDirection: "row", alignItems:"center"}}>
                                                    <Controller
                                                        name="useEfficiency"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormControlLabel
                                                                {...field}
                                                                control={<Checkbox style={{padding:'15px 4px 4px 8px'}} />}
                                                                checked={field.value}
                                                                label={<Typography variant="body2" style={{marginTop:'10px'}} color={m_bModePM || processMode > MS_MODE_DRAWING?'grey':'black'}>効率を考慮</Typography>}
                                                                onClick={(e: any) => {
                                                                    setValue(e.target.name,e.target.checked);
                                                                    handleDisabled(e);
                                                                    handleOnUseEfficiency(e);
                                                                }}
                                                                disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                                <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                    <Typography variant="body2" width={"85%"} color={isDisabledUseEfficiency||m_bModePM?'grey':'black'} style={{marginLeft:'20px'}}>効率:</Typography>
                                                    <Controller
                                                        name="efficiency"
                                                        control={control}
                                                        rules={{ required: '必須項目です。入力してください。',
                                                            pattern:{
                                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                                message: '数字を入力してください。'
                                                            },
                                                            validate: value => value >= 0 && value <= 1 || '数字 0 ~ 1 を入力してください。' ,
                                                        }}
                                                        render={({ field }) => {
                                                            const fieldWithoutRef = { ...field, ref: undefined };
                                                            return (
                                                                <TextField
                                                                    {...fieldWithoutRef}
                                                                    inputRef={field.ref}
                                                                    fullWidth
                                                                    // type="number"
                                                                    size="small"
                                                                    error={!!errors?.efficiency}
                                                                    helperText={errors?.efficiency?.message}
                                                                    onFocus={e => e.target.select()}
                                                                    InputLabelProps={{
                                                                        style: { color: 'black' },
                                                                    }}
                                                                    onChange={(e:any) => {
                                                                        field.onChange(e);
                                                                        handleCheckDisable();
                                                                        handleChangeEfficiency(e);
                                                                    }}
                                                                    sx={numberStyle}
                                                                    InputProps={{
                                                                        sx:{height:32,backgroundColor:isDisabledUseEfficiency||m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING?'floralwhite':'white', color:modeViewOnly?"grey":"black"},
                                                                
                                                                    }}
                                                                    disabled={isDisabledUseEfficiency||m_bModePM || processMode > MS_MODE_DRAWING}
                                                                    inputProps={{maxlength: 5, readOnly:modeViewOnly}}

                                                                />
                                                            )
                                                        }}
                                                    />
                                                </Stack>
                                                <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                    <Typography variant="body2" width={"100%"}>全負荷電流 Im(A):</Typography>
                                                    <Controller
                                                        name="fullCurrent"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                fullWidth
                                                                type="number"
                                                                size="small"
                                                                InputLabelProps={{
                                                                    style: { color: 'black' },
                                                                }}
                                                                inputProps={{
                                                                    readOnly: true,
                                                                }}
                                                                style={{
                                                                    backgroundColor: 'floralwhite',
                                                                }}
                                                                InputProps={{
                                                                    sx:{height:32,color:'grey'},
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                                <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                    <Typography variant="body2" width={"100%"}>電流位相(°):</Typography>
                                                    <Controller
                                                        name="phaseCurrent"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                fullWidth
                                                                type="text"
                                                                size="small"
                                                                InputLabelProps={{
                                                                    style: { color: 'black' },
                                                                }}
                                                                inputProps={{
                                                                    readOnly: true,
                                                                }}
                                                                style={{
                                                                    backgroundColor: 'floralwhite',
                                                                }}
                                                                InputProps={{
                                                                    sx:{height:32,color:'grey'},
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                                <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                    <Typography
                                                        variant="body2"
                                                        width={"100%"}
                                                        color={setIsDisableField ?'grey':'black'}
                                                    >
                                                        負荷率:
                                                    </Typography>
                                                    <Controller
                                                        name="loadFactor"
                                                        control={control}
                                                        rules={{ required: '必須項目です。入力してください。',
                                                            pattern:{
                                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                                message: '数字を入力してください。'
                                                            },
                                                            validate: value => value >= 0 && value <= 1 || '数字 0 ~ 1 を入力してください。' ,
                                                        }}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                fullWidth
                                                                type="number"
                                                                size="small"
                                                                error={!!errors?.loadFactor}
                                                                helperText={errors?.loadFactor?.message}
                                                                onFocus={e => e.target.select()}
                                                                InputLabelProps={{
                                                                    style: { color: 'black' },
                                                                }}
                                                                onChange={(event:any) => {
                                                                    field.onChange(event);
                                                                    handleChangeLoadFactor(event);
                                                                    handleCheckDisable();
                                                                }}
                                                                sx={numberStyle}
                                                                InputProps={{
                                                                    sx:{height:32,backgroundColor:m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING?'floralwhite':'white', color:modeViewOnly?"grey":"black"},
                                                                }}
                                                                disabled={setIsDisableField||m_bModePM || processMode > MS_MODE_DRAWING}
                                                                inputProps={{maxlength: 5, readOnly:modeViewOnly}}

                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={6.5} pl={2}>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Controller
                                                    name="calcDrop"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <FormControlLabel
                                                            {...field}
                                                            control={<Checkbox style={checkboxStyles} />}
                                                            checked={field.value}
                                                            label={<Typography variant="body2" color={(setIsDisableField||m_bModePM || (processMode > MS_MODE_DRAWING && processMode != MS_MODE_VOLTDROP))?'grey':'black'}>この電動機の始動時電圧降下を計算</Typography>}
                                                            name='calcDrop'
                                                            onChange={(e: any) => {
                                                                field.onChange(e);
                                                                handleDisabled(e);
                                                            }}
                                                            disabled={setIsDisableField||m_bModePM || modeViewOnly || (processMode > MS_MODE_DRAWING && processMode != MS_MODE_VOLTDROP)}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center", marginLeft:"30px" }}>
                                                <Typography variant="body2" width={"50%"} color={(isDisabledCalcDrop||m_bModePM || (processMode > MS_MODE_DRAWING && processMode != MS_MODE_VOLTDROP))?'grey':'black'}>始動力率(遅れ):</Typography>
                                                <Controller
                                                    name="startingFactor"
                                                    control={control}
                                                    rules={{ required: '必須項目です。入力してください。',
                                                        pattern:{
                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                            message: '数字を入力してください。'
                                                        },
                                                        validate: value => value >= 0 && value <= 1 || '数字 0 ~ 1 を入力してください。' ,
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            // type="number"
                                                            size="small"
                                                            error={!!errors?.startingFactor}
                                                            helperText={errors?.startingFactor?.message}
                                                            onFocus={e => e.target.select()}
                                                            InputLabelProps={{
                                                                style: { color: 'black',marginLeft:"20px" },
                                                            }}
                                                            onChange={(event:any) => {
                                                                field.onChange(event);
                                                                handleCheckDisable();
                                                            }}
                                                            sx={numberStyle}
                                                            style={{width:100}}
                                                            disabled={(isDisabledCalcDrop||m_bModePM || (processMode > MS_MODE_DRAWING && processMode != MS_MODE_VOLTDROP))}
                                                            InputProps={{
                                                                sx:{height:32,backgroundColor: (isDisabledCalcDrop||m_bModePM || modeViewOnly || (processMode > MS_MODE_DRAWING && processMode != MS_MODE_VOLTDROP)) ? 'floralwhite' : 'white', color:modeViewOnly?"grey":"black"}
                                                            }}
                                                            inputProps={{maxLength:5, readOnly:modeViewOnly}}
                                                            

                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Controller
                                                    name="calcImprovePF"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <FormControlLabel
                                                            {...field}
                                                            control={<Checkbox style={checkboxStyles} />}
                                                            checked={field.value}
                                                            label={<Typography variant="body2" color={setIsDisableField||m_bModePM?'grey':'black'}>力率改善を計算</Typography>}
                                                            name='calcImprovePF'
                                                            onChange={(e: any) => {
                                                                field.onChange(e);
                                                                handleDisabled(e);
                                                            }}
                                                            disabled={setIsDisableField||m_bModePM || modeViewOnly}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginLeft:'30px'}}>
                                                <Typography variant="body2" width={"50%"} color={(isDisabledCalcImprovePf || setIsDisableField||m_bModePM) ?'grey':'black'}>改善後の目標力率:</Typography>
                                                <Controller
                                                    name="afterPF"
                                                    control={control}
                                                    rules={{ required: '必須項目です。入力してください。',
                                                        pattern:{
                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                            message: '数字を入力してください。'
                                                        },
                                                        validate: value => value >= 0 && value <= 1 || '数字 0 ~ 1 を入力してください。' ,
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            // type="number"
                                                            size="small"
                                                            error={!!errors?.afterPF}
                                                            helperText={errors?.afterPF?.message}
                                                            onFocus={e => e.target.select()}
                                                            InputLabelProps={{
                                                                style: { color: 'black' },
                                                            }}
                                                            onChange={(e:any) => {
                                                                field.onChange(e);
                                                            }}
                                                            InputProps={{
                                                                sx:{height:32, backgroundColor: (isDisabledCalcImprovePf || setIsDisableField||m_bModePM || modeViewOnly) ? 'floralwhite' : 'white', color:modeViewOnly?"grey":"black"}
                                                            }}
                                                            sx={numberStyle}
                                                            style={{
                                                                color: 'black',
                                                                width:100
                                                            }}
                                                            disabled={(isDisabledCalcImprovePf || setIsDisableField ||m_bModePM)}
                                                            inputProps={{maxLength:5 , readOnly:modeViewOnly}}
                                                            
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            {/* Mode SP */}
                                            {(userRole && !(userRole == Constant.ROLE_LP)) &&
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Controller
                                                    name="viewFlowResult"
                                                    control={control}
                                                    render={({ field }: any) => (
                                                        <FormControlLabel
                                                            {...field}
                                                            control={<Checkbox style={checkboxStyles} />}
                                                            checked={field.value}
                                                            label={
                                                                <Typography
                                                                    variant="body2"
                                                                    color={setIsDisableField ||m_bModePM?'grey':'black'} >
                                                                        潮流解析用の値を表示
                                                                </Typography>
                                                            } 
                                                            name='viewFlowResult'
                                                            disabled={setIsDisableField||m_bModePM || modeViewOnly}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            }

                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Controller
                                                    name="viewResultText"
                                                    control={control}
                                                    render={({ field }: any) => (
                                                        <FormControlLabel {...field} control={<Checkbox style={checkboxStyles} />} checked={field.value} 
                                                        label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>注釈を表示</Typography>} name='viewResultText' disabled={m_bModePM || modeViewOnly}/>
                                                    )}
                                                />
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                        <Controller
                                            name="useBaseLoad"
                                            control={control}
                                            render={({ field }) => (
                                                <FormControlLabel
                                                    {...field}
                                                    control={<Checkbox style={{padding:'15px 4px 4px 8px'}} />}
                                                    checked={field.value}
                                                    label={<Typography variant="body2" style={{marginTop:'10px'}} color={setIsDisableField||m_bModePM?'grey':'black'}>基底負荷を考慮</Typography>}
                                                    name='useBaseLoad'
                                                    onChange={(e: any) => {
                                                        field.onChange(e);
                                                        handleDisabled(e)
                                                    }}
                                                    disabled={setIsDisableField||m_bModePM || modeViewOnly}
                                                />
                                            )}
                                        />
                                    </Stack>
                                    <fieldset style={fieldsetStyle}>
                                        <legend><Typography variant="body2" style={{color:isDisabledBaseLoad||m_bModePM?'grey':'black'}}>基底負荷</Typography></legend>
                                        <Grid container>
                                            <Grid item xs={6}>
                                                <Stack spacing={0.3}>
                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                        <Controller
                                                            name="baseLoadInputManual"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <>
                                                                    <RadioGroup {...field}>
                                                                        <FormControlLabel value={false} control={<Radio style={radioStyles} onChange={
                                                                                        (e: any) => {
                                                                                            handleAutoCalculateInput(e);
                                                                                        }}  />}
                                                                                        disabled={isDisabledBaseLoad || m_bModePM || modeViewOnly}
                                                                                         label={<Typography variant="body2" style={{color:isDisabledBaseLoad||m_bModePM?'grey':'black'}}>基底負荷を自動計算する</Typography>} />
                                                                        <FormControlLabel style={{marginTop:"10px"}} value={true} control={<Radio style={radioStyles} onChange={
                                                                                        (e: any) => {
                                                                                            handleManualCalculateInput(e);
                                                                                        }}  />}
                                                                                        disabled={isDisabledBaseLoad || m_bModePM || modeViewOnly}
                                                                                        label={<Typography variant="body2" style={{color:isDisabledBaseLoad||m_bModePM?'grey':'black'}}>基底負荷を手入力する</Typography>} />
                                                                    </RadioGroup>
                                                                </>
                                                            )}
                                                        />
                                                    </Stack>
                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                        <Typography variant="body2" width={"70%"} style={{color:isDisabledBaseLoad||m_bModePM?'grey':'black'}}>容量(kVA):</Typography>
                                                        <Controller
                                                            name="baseCapacity"
                                                            control={control}
                                                            rules={{ required: '必須項目です。入力してください。',
                                                                pattern:{
                                                                    value: /^[0-9]*[.]?[0-9]+$/,
                                                                    message: '数字を入力してください。'
                                                                },
                                                                validate: value => value >= 0 || '数字 >= 0 を入力してください。' ,
                                                            }}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    // type="number"
                                                                    size="small"
                                                                    disabled={isDisabledBaseLoad|| !isDisableInputAutomaticManual||m_bModePM}
                                                                    error={!!errors?.baseCapacity}
                                                                    helperText={errors?.baseCapacity?.message}
                                                                    onFocus={e => e.target.select()}
                                                                    InputLabelProps={{
                                                                        style: { color: 'black' },
                                                                    }}
                                                                    onChange={(e:any) => {
                                                                        field.onChange(e);
                                                                        handleChangeBaseCapacity(e);
                                                                        if(getValues("baseLoadInputManual")){
                                                                            setValue("baseCapacityM",Number(e.target.value));
                                                                        }
                                                                    }}
                                                                    InputProps={{
                                                                        sx:{height:32,backgroundColor:(isDisabledBaseLoad|| !isDisableInputAutomaticManual||m_bModePM || modeViewOnly)?'floralwhite':'white', color:modeViewOnly?"grey":"black"},
                                                                    }}
                                                                    sx={numberStyle}
                                                                    inputProps={{maxlength: 5, readOnly:modeViewOnly}}
                                                                />
                                                            )}
                                                        />
                                                    </Stack>
                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                        <Typography variant="body2" width={"70%"} style={{color:isDisabledBaseLoad||m_bModePM?'grey':'black'}}>負荷電流 Ib(A):</Typography>
                                                        <Controller
                                                            name="baseCurrent"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    type="text"
                                                                    size="small"
                                                                    disabled={isDisabledBaseLoad || !isDisableInputAutomaticManual||m_bModePM}
                                                                    inputProps={{readOnly:"true"}}
                                                                    error={!!errors?.baseCurrent}
                                                                    helperText={errors?.baseCurrent?.message}
                                                                    onFocus={e => e.target.select()}
                                                                    InputLabelProps={{
                                                                        style: { color: 'black' },
                                                                    }}
                                                                    style={{
                                                                        backgroundColor:"floralwhite"
                                                                    }}
                                                                    InputProps={{
                                                                        sx:{height:32, color:modeViewOnly?"grey":"black"},
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </Stack>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                    <Controller
                                                        name="baseLoadCalcRightLeft"
                                                        control={control}        
                                                        render={({ field }) => (
                                                            <FormControlLabel
                                                                {...field}
                                                                control={<Checkbox style={checkboxStyle} />}
                                                                checked={field.value}
                                                                label={<Typography variant="body2" style={{color:(isDisableInputAutomaticManual|| isDisabledBaseLoad||m_bModePM)?'grey':'black'}}>左右の母線の負荷も考慮</Typography>}
                                                                name='baseLoadCalcRightLeft'
                                                                onChange={(e: any) => {
                                                                    field.onChange(e);
                                                                }}
                                                                disabled={(isDisableInputAutomaticManual || isDisabledBaseLoad||m_bModePM || modeViewOnly)}

                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                                <Stack>
                                                <fieldset style={fieldsetStyle}>
                                                    <legend><Typography variant="body2" style={{color:isDisabledBaseLoad||m_bModePM?'grey':'black'}} >力率</Typography></legend>
                                                    <Stack direction='row'>
                                                        <FormControl>
                                                            <Controller
                                                                name="isLead"
                                                                control={control}
                                                                render={({ field }) => (
                                                                    <>
                                                                        <RadioGroup {...field} row={true}>
                                                                            <Grid item xs={12}>
                                                                                <Grid container>
                                                                                    <FormControlLabel disabled={!isDisableInputAutomaticManual || isDisabledBaseLoad||m_bModePM || modeViewOnly} value={true} control={<Radio style={radioStyles} onClick={(e) => {if(getValues("baseLoadInputManual")){setValue("leadBaseLoadM",true)}}} />} label={<Typography variant="body2" color={(!isDisableInputAutomaticManual || isDisabledBaseLoad||m_bModePM)?'grey':'black'}>進み</Typography>} />
                                                                                    <FormControlLabel disabled={!isDisableInputAutomaticManual || isDisabledBaseLoad||m_bModePM || modeViewOnly} value={false} control={<Radio style={radioStyles} onClick={(e) => {if(getValues("baseLoadInputManual")){setValue("leadBaseLoadM",false)}}} />} label={<Typography variant="body2"color={(!isDisableInputAutomaticManual || isDisabledBaseLoad||m_bModePM)?'grey':'black'}>遅れ</Typography>} />
                                                                                    <Controller
                                                                                        name="baseFactor"
                                                                                        control={control}
                                                                                        // rules={{ required: '必須項目です。入力してください。',
                                                                                        //     pattern:{
                                                                                        //         value: /^[0-9]*[.]?[0-9]+$/,
                                                                                        //         message: '数字を入力してください。'
                                                                                        //     },
                                                                                        //     validate: value => getValues("baseLoadInputManual") && value >= 0 && value <= 1 || '数字 0 ~ 1 を入力してください。' ,
                                                                                        // }}
                                                                                        render={({ field }) => (
                                                                                            <TextField
                                                                                                {...field}
                                                                                                // type="number"
                                                                                                size="small"
                                                                                                disabled={isDisabledBaseLoad || !isDisableInputAutomaticManual||m_bModePM}
                                                                                                error={!!errors?.baseFactor}
                                                                                                helperText={errors?.baseFactor?.message}
                                                                                                onFocus={e => e.target.select()}
                                                                                                InputLabelProps={{
                                                                                                    style: { color: 'black' },
                                                                                                }}
                                                                                                onChange={
                                                                                                    (e:any) => {
                                                                                                        // field.onChange(e);
                                                                                                        if(getValues("baseLoadInputManual")){
                                                                                                            setValue("baseFactorM",Number(e.target.value));
                                                                                                        }
                                                                                                        onChangeBaseFactor((e))
                                                                                                    }
                                                                                                }
                                                                                                InputProps={{
                                                                                                    sx:{height:32,backgroundColor:m_bModePM || modeViewOnly?'floralwhite':'white', color:modeViewOnly?"grey":"black"}
                                                                                                }}
                                                                                                sx={numberStyle}
                                                                                                style={{width:70}}
                                                                                                inputProps={{maxlength:5, readOnly:modeViewOnly}}
                                                                                            />
                                                                                        )}
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>
                                                                        </RadioGroup>
                                                                    </>
                                                                )}
                                                            />
                                                        </FormControl>
                                                    </Stack>
                                                </fieldset>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </fieldset>
                                </Grid>
                                {/* Mode PM */}
                                {[Constant.ROLE_PM, Constant.ROLE_ADMIN].includes(userRole) &&
                                <Grid container my={1}>
                                    <Grid item xs={8}>
                                        <Stack direction='column' mb={1}>
                                            <Grid container direction="row">
                                                <Grid item xs={12}>
                                                    <Stack>
                                                        <FormControlLabel
                                                            control={
                                                                <Controller
                                                                    name='makeDataInput'
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <Checkbox
                                                                            {...field}
                                                                            checked={field.value}
                                                                            onChange={(e)=>{
                                                                                setMakeDataInput(e.target.checked);
                                                                                setValue("makeDataInput",e.target.checked);
                                                                            }}
                                                                            style={{padding:'4px 4px 4px 9px'}}
                                                                            disabled={m_bModePM || modeViewOnly}
                                                                        />
                                                                    )}
                                                                />
                                                            }
                                                            label={<Typography variant="body2"  color={m_bModePM?'grey':'black'}>銘板入力</Typography>}
                                                        />
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Stack>
                                                    <fieldset
                                                        style={{ 
                                                            width: '100%',
                                                            color: !makeDataInput ? 'grey' : 'black', 
                                                            border: '1px solid #AAAA'
                                                            
                                                        }}
                                                    >
                                                        <legend ><Typography variant="body2"  >銘板</Typography></legend>
                                                        <Grid container>
                                                            <Grid item xs={12}>
                                                                <Stack spacing={0.3}>
                                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                                        <Typography variant="body2" width="35%"  >製造者:</Typography>
                                                                            <FormControl fullWidth size='small'>
                                                                                <Controller
                                                                                    name='makerName'
                                                                                    control={control}
                                                                                    render={({ field }: any) => (
                                                                                        <TextFieldOptions
                                                                                            {...field}
                                                                                            options={initialValue.makerNameList}
                                                                                            type='string'
                                                                                            disabled={!makeDataInput||m_bModePM || modeViewOnly}
                                                                                            size='small'
                                                                                            setValue={(name: any, value: any) => {
                                                                                                handleChangeFormat({target: {name: name, value: value}})
                                                                                            }}
                                                                                            height={32}
                                                                                            
                                                                                        />
                                                                                    )}
                                                                                />
                                                                            </FormControl>
                                                                    </Stack>
                                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                                        <Typography variant="body2" width="35%"  >形式:</Typography>
                                                                        <Controller
                                                                            name='type'
                                                                            control={control}
                                                                            render={({ field }) => (
                                                                                <TextField
                                                                                    {...field}
                                                                                    fullWidth
                                                                                    type='text'
                                                                                    size='small'
                                                                                    onChange={handleChangeFormat}
                                                                                    InputLabelProps={{
                                                                                        style: { color: makeDataInput ? 'black' : 'grey' },
                                                                                    }}
                                                                                    inputProps={{
                                                                                        readOnly: !makeDataInput || modeViewOnly,
                                                                                    }}
                                                                                    style={{
                                                                                       color: 'black',
                                                                                    }}
                                                                                    InputProps={{
                                                                                        sx:{height:32, backgroundColor: m_bModePM || modeViewOnly?'floralwhite':makeDataInput ? 'white' : 'floralwhite', color:modeViewOnly?"grey":makeDataInput ? 'black' : 'grey'}
                                                                                    }}
                                                                                    disabled={m_bModePM}
                                                                                />
                                                                            )}
                                                                        />
                                                                    </Stack>
                                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                                        <Typography variant="body2" width="35%"  >製造年月:</Typography>
                                                                        <Grid container>
                                                                            <Grid item xs={7}>
                                                                                <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                                                    <FormControl fullWidth size='small'>
                                                                                        <Controller
                                                                                            name='makeYear'
                                                                                            control={control}
                                                                                            
                                                                                            render={({ field }: any) => (
                                                                                                <TextFieldOptions
                                                                                                    {...field}
                                                                                                    options={initialValue.makeYearList}
                                                                                                    type='string'
                                                                                                    size='small'
                                                                                                    disabled={!makeDataInput||m_bModePM || modeViewOnly}
                                                                                                    setValue={(name: any, value: any) => {
                                                                                                        handleChangeFormat({target: {name: name, value: value}})
                                                                                                    }}
                                                                                                    height= {32}
                                                                                                    maxLength={8}
                                                                                                    
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                    </FormControl>
                                                                                    <Typography variant="body2" pr={1.5} pl={0.5}>年</Typography>
                                                                                </Stack>
                                                                                
                                                                            </Grid>
                                                                            <Grid item xs={5}>
                                                                                <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                                                    <FormControl fullWidth size='small'>
                                                                                        <Controller
                                                                                            name='makeMonth'
                                                                                            control={control}
                                                                                            
                                                                                            render={({ field }: any) => (
                                                                                                <TextFieldOptions
                                                                                                    {...field}
                                                                                                    disabled={!makeDataInput||m_bModePM || modeViewOnly}
                                                                                                    options={initialValue.makeMonthList}
                                                                                                    type='string'
                                                                                                    size='small'
                                                                                                    setValue={(name: any, value: any) => {
                                                                                                        handleChangeFormat({target: {name: name, value: value}})
                                                                                                    }}
                                                                                                    height= {32}
                                                                                                    maxLength={2}

                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                    </FormControl>
                                                                                    <Typography variant="body2" pl={0.5}>月</Typography>
                                                                                </Stack>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Stack>
                                                                    <Stack sx={{ flexDirection: "row", alignItems:"center"}}>
                                                                        <Typography variant="body2" width="35%"  >製造番号:</Typography>
                                                                        <Controller
                                                                            name='makeNumber'
                                                                            control={control}
                                                                            render={({ field }) => (
                                                                                <TextField
                                                                                    {...field}
                                                                                    fullWidth
                                                                                    type='text'
                                                                                    size='small'
                                                                                    onChange={handleChangeFormat}
                                                                                    InputLabelProps={{
                                                                                        style: { color: makeDataInput ? 'black' : 'grey' },
                                                                                    }}
                                                                                    inputProps={{
                                                                                        readOnly: !makeDataInput || modeViewOnly,
                                                                                    }}
                                                                                    style={{
                                                                                        color:'black',
                                                                                    }}
                                                                                    InputProps={{ sx: { height: 32 , backgroundColor: m_bModePM || modeViewOnly?'floralwhite':makeDataInput ? 'white' : 'floralwhite', color:modeViewOnly?"grey":makeDataInput ? 'black' : 'grey'  } }}
                                                                                    disabled={m_bModePM}
                                                                                    />
                                                                            )}
                                                                        />
                                                                    </Stack>
                                                                </Stack>
                                                            </Grid>
                                                        </Grid>
                                                    </fieldset>
                                                    </Stack>
                                                </Grid>
                                            </Grid>
                                        </Stack>
                                    </Grid>
                                </Grid>
                                }
                            </Grid>
                        </TabPanel>
                        
                        {/* 保護協調 */}
                        <TabPanel value={selectTab} index={1}>
                            <Grid container my={1}>
                                <Grid item xs={12}>
                                    <fieldset style={fieldsetStyle}>
                                        <legend><Typography variant="body2">始動電流曲線</Typography></legend>
                                        <Grid container>
                                            <Grid item xs={6.5}>
                                                <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                    <Typography variant="body2" width={"170%"}>全負荷電流 Im(A):</Typography>
                                                    <Controller
                                                        name="loadCurrent"
                                                        control={control}
                                                        rules={{ required: '必須項目です。入力してください。',
                                                            pattern:{
                                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                                message: '数字を入力してください。'
                                                            },
                                                            validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                        }}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                fullWidth
                                                                // type="number"
                                                                size="small"
                                                                disabled={isDisableManualLoadCurrent||m_bModePM}
                                                                style={{
                                                                    color:"black"
                                                                }}
                                                                onFocus={e => e.target.select()}
                                                                InputLabelProps={{
                                                                    style: { color: 'black' },
                                                                }}
                                                                onChange={(event:any) => {
                                                                    field.onChange(event);
                                                                    handleChangeLoadCurrent(event);
                                                                    handleCheckDisable();
                                                                }}
                                                                error={!!errors?.loadCurrent}
                                                                helperText={errors?.loadCurrent?.message}
                                                                sx={numberStyle}
                                                                InputProps={{
                                                                    sx:{height:32,backgroundColor: isDisableManualLoadCurrent||m_bModePM ||modeViewOnly ? "floralwhite" : "white",color:isDisableManualLoadCurrent||m_bModePM||modeViewOnly?'grey':'black'}
                                                                }}
                                                                inputProps={{
                                                                    maxLength:6,
                                                                    readOnly:modeViewOnly
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={5.4} pl={1}>
                                                <Controller
                                                    name="manualLoadCurrent"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <FormControlLabel
                                                            {...field}
                                                            control={<Checkbox style={checkboxStyles} />}
                                                            checked={field.value}
                                                            label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>任意の値を入力する</Typography>}
                                                            name='manualLoadCurrent'
                                                            onChange={(e: any) => {
                                                                field.onChange(e);
                                                                handleDisabled(e);
                                                                handleCheckDisable();
                                                                handleOnManualLoadCurrent(e);
                                                            }}
                                                            disabled={m_bModePM || modeViewOnly}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid item xs={6.5}>
                                                <Stack spacing={0.3}>
                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                        <Typography variant="body2" width={"170%"}>始動時間(s):</Typography>
                                                        <Controller
                                                            name="startingTime"
                                                            control={control}
                                                            rules={{ required: '必須項目です。入力してください。',
                                                                pattern:{
                                                                    value: /^[0-9]*[.]?[0-9]+$/,
                                                                    message: '数字を入力してください。'
                                                                },
                                                                validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                            }}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    // type="number"
                                                                    size="small"
                                                                    error={!!errors?.startingTime}
                                                                    helperText={errors?.startingTime?.message}
                                                                    onFocus={e => e.target.select()}
                                                                    InputLabelProps={{
                                                                        style: { color: 'black' },
                                                                    }}
                                                                    onChange={
                                                                        (e:any) => {
                                                                            field.onChange(e);
                                                                            handleChangeStartTime(e);
                                                                            handleCheckDisable();
                                                                        }
                                                                    }
                                                                    sx={numberStyle}
                                                                    InputProps={{
                                                                        sx:{height:32,backgroundColor:m_bModePM || modeViewOnly?'floralwhite':'white', color:modeViewOnly?"grey":"black"}
                                                                    }}
                                                                    inputProps={{
                                                                        maxLength:6,
                                                                        readOnly:modeViewOnly
                                                                    }}
                                                                    disabled={m_bModePM}
                                                                />
                                                            )}
                                                        />
                                                    </Stack>
                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                        <Typography variant="body2" width={"170%"}>始動電流(Imの倍数):</Typography>
                                                        <Controller
                                                            name="startingTimes"
                                                            control={control}
                                                            rules={{ required: '必須項目です。入力してください。',
                                                                pattern:{
                                                                    value: /^[0-9]*[.]?[0-9]+$/,
                                                                    message: '数字を入力してください。'
                                                                },
                                                                validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                            }}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    // type="number"
                                                                    size="small"
                                                                    disabled={!isDisableManualStartingCurrent||m_bModePM}
                                                                    style={{
                                                                        color: "black"
                                                                    }}
                                                                    error={!!errors?.startingTimes}
                                                                    helperText={errors?.startingTimes?.message}
                                                                    onFocus={e => e.target.select()}
                                                                    InputLabelProps={{
                                                                        style: { color: 'black' },
                                                                    }}
                                                                    onChange={(e:any) => {
                                                                        field.onChange(e);
                                                                        handleChangeStartingTimes(e);
                                                                        handleCheckDisable();
                                                                    }}
                                                                    sx={numberStyle}
                                                                    InputProps={{
                                                                        sx:{height:32 , backgroundColor: !isDisableManualStartingCurrent||m_bModePM || modeViewOnly ? "floralwhite" : "white", color:modeViewOnly?"grey":"black"}
                                                                    }}
                                                                    inputProps={{
                                                                        maxLength:6,
                                                                        readOnly:modeViewOnly
                                                                    }}
                                                                    
                                                                />
                                                            )}
                                                        />
                                                    </Stack>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid item xs={6.5} mt={0.3}>
                                                <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                    <Typography variant="body2" width={"170%"}>始動電流(A):</Typography>
                                                    <Controller
                                                        name="startingCurrent"
                                                        control={control}
                                                        rules={{ required: '必須項目です。入力してください。',
                                                            pattern:{
                                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                                message: '数字を入力してください。'
                                                            },
                                                            validate: value => value >0 || '数字 > 0 を入力してください。' ,
                                                        }}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                fullWidth
                                                                // type="number"
                                                                size="small"
                                                                disabled={isDisableManualStartingCurrent||m_bModePM}
                                                                style={{
                                                                    color: "black"
                                                                }}
                                                                error={!!errors?.startingCurrent}
                                                                helperText={errors?.startingCurrent?.message}
                                                                onFocus={e => e.target.select()}
                                                                InputLabelProps={{
                                                                    style: { color: 'black' },
                                                                }}
                                                                onChange={(e:any) => {
                                                                    field.onChange(e);
                                                                    handleCheckDisable();
                                                                    handleChangeStartingCurrent(e);
                                                                }}
                                                                sx={numberStyle}
                                                                InputProps={{
                                                                    sx:{height:32, backgroundColor: isDisableManualStartingCurrent||m_bModePM || modeViewOnly ? "floralwhite" : "white", color:modeViewOnly?"grey":"black"}
                                                                }}
                                                                inputProps={{
                                                                    maxLength:6,
                                                                    readOnly:modeViewOnly
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={5.4} pl={1}>
                                                <Controller
                                                    name="manualStartingCurrent"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <FormControlLabel
                                                            {...field}
                                                            control={<Checkbox style={checkboxStyles} />}
                                                            checked={field.value}
                                                            label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>任意の値を入力する</Typography>}
                                                            name='manualStartingCurrent'
                                                            onChange={(e: any) => {
                                                                field.onChange(e);
                                                                handleDisabled(e);
                                                                handleCheckDisable();
                                                                handleOnManualStartingCurrent(e);
                                                            }}
                                                            disabled={m_bModePM || modeViewOnly}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid item xs={6.5}>
                                                <Stack spacing={0.3} mt={0.3}>
                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                        <Typography variant="body2" width={"170%"}>600%電流での始勤時間(s):</Typography>
                                                        <Controller
                                                            name="starting600"
                                                            control={control}
                                                            rules={{ required: '必須項目です。入力してください。',
                                                                pattern:{
                                                                    value: /^[0-9]*[.]?[0-9]+$/,
                                                                    message: '数字を入力してください。'
                                                                },
                                                                validate: value => value > 0 || '0 < 数字 <= 始動時間(s)」 を入力してください。' ,
                                                            }}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    // type="number"
                                                                    size="small"
                                                                    error={!!errors?.starting600}
                                                                    helperText={errors?.starting600?.message}
                                                                    onFocus={e => e.target.select()}
                                                                    InputLabelProps={{
                                                                        style: { color: 'black' },
                                                                    }}
                                                                    onChange={(event:any) => {
                                                                        field.onChange(event);
                                                                        handleCheckDisable();
                                                                        handleChangeStarting600(event);
                                                                    }}
                                                                    sx={numberStyle}
                                                                    InputProps={{
                                                                        sx:{height:32,backgroundColor:m_bModePM || modeViewOnly?'floralwhite':'white', color:modeViewOnly?"grey":"black"}
                                                                    }}
                                                                    inputProps={{
                                                                        maxLength:5, readOnly:modeViewOnly
                                                                    }}
                                                                    disabled={m_bModePM}
                                                                />
                                                            )}
                                                        />
                                                    </Stack>
                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                        <Typography variant="body2" width={"170%"}>始動突入電流(Imの倍数):</Typography>
                                                        <Controller
                                                            name="inrushTimes"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    // type="number"
                                                                    size="small"
                                                                    disabled={!isDisableManualInrushCurrent||m_bModePM}
                                                                    style={{
                                                                        color: "black"
                                                                    }}
                                                                    error={!!errors?.inrushTimes}
                                                                    helperText={errors?.inrushTimes?.message}
                                                                    onFocus={e => e.target.select()}
                                                                    InputLabelProps={{
                                                                        style: { color: 'black' },
                                                                    }}
                                                                    onChange={(event:any) => {
                                                                        field.onChange(event);
                                                                        handleChangeInRushTime(event);
                                                                        handleCheckDisable();
                                                                    }}
                                                                    sx={numberStyle}
                                                                    InputProps={{
                                                                        sx:{height:32, backgroundColor: !isDisableManualInrushCurrent||m_bModePM || modeViewOnly? "floralwhite" : "white", color:modeViewOnly?"grey":"black"}
                                                                    }}
                                                                    inputProps={{
                                                                        maxLength:6, readOnly:modeViewOnly
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </Stack>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid item xs={6.5}>
                                                <Stack sx={{ flexDirection: "row", alignItems:"center"}} mt={0.3}>
                                                <Typography variant="body2" width={"170%"}>始動突入電流(A):</Typography>
                                                    <Controller
                                                        name="inrushCurrent"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                fullWidth
                                                                type="number"
                                                                size="small"
                                                                disabled={isDisableManualInrushCurrent||m_bModePM}
                                                                style={{
                                                                    color: "black"
                                                                }}
                                                                error={!!errors?.inrushCurrent}
                                                                helperText={errors?.inrushCurrent?.message}
                                                                onFocus={e => e.target.select()}
                                                                InputLabelProps={{
                                                                    style: { color: 'black' },
                                                                }}
                                                                onChange={(event:any) => {
                                                                    field.onChange(event);
                                                                    handleCheckDisable();
                                                                    handleChangeInRushCurrent(event);
                                                                }}
                                                                sx={numberStyle}
                                                                InputProps={{
                                                                    sx:{height:32, backgroundColor: isDisableManualInrushCurrent||m_bModePM || modeViewOnly? "floralwhite" : "white", color:modeViewOnly?"grey":"black"},
                                                                }}
                                                                inputProps={{
                                                                    maxLength:7, readOnly:modeViewOnly
                                                                }}
                                                            />
                                                        )}
                                                        />
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={5.4} pl={1}>
                                                <Controller
                                                    name="manualInrushCurrent"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <FormControlLabel
                                                            {...field}
                                                            control={<Checkbox style={checkboxStyles} />}
                                                            checked={field.value}
                                                            label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>任意の値を入力する</Typography>}
                                                            name='manualInrushCurrent'
                                                            onChange={(e: any) => {
                                                                field.onChange(e);
                                                                handleDisabled(e);
                                                                handleCheckDisable();
                                                                handleOnManualInrushCurrent(e);
                                                            }}
                                                            disabled={m_bModePM || modeViewOnly}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center"}} mt={0.3}>
                                                <Grid item xs={12}>
                                                    <Typography variant="body2" width={"170%"}>始動突入時間(s):</Typography>
                                                </Grid>
                                                <Controller
                                                    name="inrush20Or40"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <RadioGroup
                                                            {...field}
                                                            onChange={(event:any) => handleOnChangeRadio(event)}
                                                            row={true}
                                                            style={{marginLeft:"10px"}}
                                                        >
                                                            <Grid item xs={9}>
                                                                <FormControlLabel value={true} control={<Radio style={radioStyles} />} label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>40ms</Typography>} 
                                                                labelPlacement={"end"} disabled={m_bModePM || modeViewOnly}/>
                                                            </Grid>
                                                            <Grid item xs={3} >
                                                                <FormControlLabel value={false} control={<Radio style={radioStyles} />} label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>20ms</Typography>} 
                                                                labelPlacement={"end"} disabled={m_bModePM || modeViewOnly}/>
                                                            </Grid>
                                                        </RadioGroup>
                                                    )}
                                                />
                                            </Stack>
                                        </Grid>
                                    </fieldset>
                                </Grid>
                                <Grid item xs={12}>
                                    <Controller
                                        name="leadBaseLoad"
                                        control={control}
                                        render={({ field }) => (
                                            <FormControlLabel
                                                {...field}
                                                control={<Checkbox style={{padding:'15px 4px 4px 8px'}} />}
                                                checked={field.value}
                                                label={<Typography variant="body2"  style={{color:isDisabledBaseLoad||m_bModePM?'grey':'black',marginTop:'10px'}}>基底負荷を考慮した始動電流曲線も描画</Typography>}
                                                name="leadBaseLoad"
                                                onChange={(e: any) => {
                                                    field.onChange(e);
                                                }}
                                                disabled={isDisabledBaseLoad||m_bModePM || modeViewOnly}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <fieldset style={fieldsetStyle}>
                                        <legend style={{fontSize:"14px"}}><Typography variant="body2" style={{color:isDisabledBaseLoad||m_bModePM?'grey':'black'}}>基底負荷を考慮した始動電流曲線</Typography></legend>
                                        <Grid container>
                                            <Grid item xs={6}>
                                                <Stack sx={{ flexDirection: "row", alignItems:"center"}} mt={0.3}>
                                                    <Typography variant="body2" width={"80%"} style={{color:isDisabledBaseLoad||m_bModePM?'grey':'black'}}>合計負荷電流(A):</Typography>
                                                    <Controller
                                                        name="loadCurrentBaseLoad"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <TextField
                                                            {...field}
                                                            fullWidth
                                                            type="text"
                                                            size="small"
                                                            error={!!errors?.loadCurrentBaseLoad}
                                                            helperText={errors?.loadCurrentBaseLoad?.message}
                                                            onFocus={e => e.target.select()}
                                                            InputLabelProps={{
                                                                style: { color: 'black' },
                                                                shrink: true
                                                            }}
                                                            disabled={isDisabledBaseLoad||m_bModePM}
                                                            inputProps={{
                                                                readOnly:true
                                                            }}
                                                            style={{
                                                                backgroundColor: "floralwhite"
                                                            }}
                                                            InputProps={{
                                                                sx:{height:32, color: modeViewOnly?"grey":"black"}
                                                            }}
                                                        />
                                                        )}
                                                    />
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={5.4} pl={2}>
                                                <Stack sx={{ flexDirection: "row", alignItems:"center"}} mt={0.3}>
                                                    <Typography variant="body2" width={"120%"} style={{color:isDisabledBaseLoad||m_bModePM?'grey':'black'}}>始動電流(A):</Typography>
                                                    <Controller
                                                        name="baseLoadCurrent"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <TextField
                                                            {...field}
                                                            fullWidth
                                                            type="text"
                                                            size="small"
                                                            error={!!errors?.startingCurrent}
                                                            helperText={errors?.startingCurrent?.message}
                                                            onFocus={e => e.target.select()}
                                                            InputLabelProps={{
                                                                style: { color: 'black' },
                                                                shrink: true
                                                            }}
                                                            inputProps={{
                                                                readOnly:true
                                                            }}
                                                            disabled={isDisabledBaseLoad||m_bModePM}
                                                            style={{
                                                                backgroundColor: "floralwhite"
                                                            }}
                                                            InputProps={{
                                                                sx:{height:32, color: modeViewOnly?"grey":"black"}
                                                            }}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                                <Stack sx={{ flexDirection: "row", alignItems:"center"}} mt={0.3}>
                                                    <Typography variant="body2" width={"120%"} style={{color:isDisabledBaseLoad||m_bModePM?'grey':'black'}}>始動突入電流(A):</Typography>
                                                    <Controller
                                                        name="baseInrushCurrent"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <TextField
                                                            {...field}
                                                            fullWidth
                                                            type="text"
                                                            size="small"
                                                            error={!!errors?.inrushCurrent}
                                                            helperText={errors?.inrushCurrent?.message}
                                                            onFocus={e => e.target.select()}
                                                            InputLabelProps={{
                                                                style: { color: 'black' },
                                                                shrink: true
                                                            }}
                                                            inputProps={{
                                                                readOnly:true
                                                            }}
                                                            style={{
                                                                backgroundColor: "floralwhite"
                                                            }}
                                                            disabled={isDisabledBaseLoad||m_bModePM}
                                                            InputProps={{
                                                                sx:{height:32, color: modeViewOnly?"grey":"black"}
                                                            }}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </fieldset>
                                </Grid>
                            </Grid>
                        </TabPanel>

                        {/*  */}
                        <TabPanel value={selectTab} index={2}>
                            <Stack  mb={1} mt={2} style={{ width: '100%', margin: "auto" }} >
                                <Grid container spacing={2} direction="row" mt={0}>
                                    <Grid item xs={0.5}></Grid>
                                    <Grid item xs={9} spacing={0} >
                                        
                                        <div className="ag-theme-alpine" style={{ height: "300px", width: "100%" }}>
                                            <AgGridReact
                                                    ref={gridRef}
                                                    defaultColDef={defaultColDef}
                                                    columnTypes={columnTypes}
                                                    columnDefs={columnDefs}
                                                    // onGridReady={onGridReady}
                                                    domLayout={"normal"}
                                                    rowData={listHarmonic as any}
                                                    animateRows={true}
                                                    rowDragManaged={true}
                                                    // rowHeight={rowHeight}
                                                    rowSelection={'single'}
                                                    suppressMovableColumns={false}
                                                    suppressDragLeaveHidesColumns={false}
                                                    localeText={AG_GRID_LOCALE_JP}
                                                    // paginationPageSize={pageSize}
                                                    // onCellDoubleClicked={handleEdit}
                                                    onRowClicked={() => setSelectItem(gridRef.current.api.getSelectedRows()[0])}
                                                    onRowDoubleClicked={() => handleOpenEditDialogHarmonicModelById(gridRef.current.api.getSelectedRows()[0])}
                                                    // loadingOverlayComponent={loadingOverlayComponent}
                                                    // loadingOverlayComponentParams={loadingOverlayComponentParams}
                                                    noRowsOverlayComponent={noRowsOverlayComponent}
                                                    noRowsOverlayComponentParams={noRowsOverlayComponentParams}
                                                />
                                                </div>
                                            {/* <Typography variant="body2" component="h3" ml={2} mt={1} mb={1}>機器名称</Typography>
                                            <List  component="nav" sx={{ maxHeight: '400px',minHeight: '200px', overflow: 'auto',borderTop:"1px solid #babfc7",background:"white"}}>
                                                {listHarmonic.length > 0 &&
                                                    listHarmonic.map((val: HarmonicRegisterModel, index: number) => (
                                                        <ListItem style={{ height: '80%', width: '100%',padding:"0px" }} key={val.hamorItemID} >
                                                            <ListItemButton
                                                                selected={selectedRow === index}
                                                                onClick={(event) => handleClickItemInListItem(event, index, val.hamorItemID)}
                                                            >
                                                                <ListItemText
                                                                    primary={val.name}
                                                                    onDoubleClick={(event) => handleOpenEditDialogHarmonicModelById(event, val.hamorItemID)
                                                                    } />
                                                            </ListItemButton>
                                                        </ListItem>
                                                    ))}
                                            </List> */}
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Stack direction={"row"} justifyContent={"flex-end"} mb={1}>
                                            <Button fullWidth={true} color="inherit" variant="contained" onClick={handleOpenAddDialogHarmonicModel} disabled={m_bModePM}>新規 ..</Button>
                                        </Stack>
                                        <Stack direction={"row"} justifyContent={"flex-end"} mb={1}>
                                            <Button fullWidth={true} color="inherit" variant="contained" onClick={handleOpenEditDialogHarmonicModel} disabled={!selectItem}>編集 ..</Button>
                                        </Stack>
                                        <Stack direction={"row"} justifyContent={"flex-end"} mb={1} >
                                            <Button fullWidth={true} color="inherit" variant="contained" onClick={handleDeleteHamornicData} disabled={!selectItem||m_bModePM || modeViewOnly}>削除 ..</Button>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Stack>
                        </TabPanel>
                        
                    </div>
                    {/* OK/キャンセル */}
                    <Stack direction='row' justifyContent="flex-end" spacing={1} mt={2}>
                        <Button  variant="contained" type="submit" onClick={handleFieldBaseFactor} disabled={isDisableOkButton || modeViewOnly}>OK</Button>
                        <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                    </Stack>
                </form >

                {/* ==================== Harmonic ========================= */}
                {isOpenedDialogAddHarmonic && defaultHarmonicInfo &&
                    <HarmonicRegisterDialog setChangeComboData={setChangeComboData} changeCombodata={changeCombodata} controlId={controlId} data={initialValueHarmonicForm} onChangeComboData={onChangeComboData} onOK={handleAddHarmonicModel} onCancel={handleCancelAddHarmonicModel} m_bModePM={m_bModePM} modeViewOnly={modeViewOnly} infoCircuit={infoCircuit} />
                }
                {isOpenedDialogEditHarmonic &&
                    <HarmonicRegisterDialog setChangeComboData={setChangeComboData} changeCombodata={changeCombodata} controlId={controlId} data={editDataHarmonicModel ?? initialValueHarmonicForm} onChangeComboData={onChangeComboData} onOK={handleEditHamornicNewModel} onCancel={handleCancelEditHarmonicModel} m_bModePM={m_bModePM} modeViewOnly={modeViewOnly} infoCircuit={infoCircuit} />
                }
                {openDeleteDialog && <AlertDialog title={"MSSV3"} message={"このユーザー曲線を削除しますか?"} isOpen={true} onOK={handleDeleteHamornicData} onCancel={() => setOpenDeleteDialog(false)} />}
            </div>
        </>
    );
};
export default MsMotorEditDialog;


