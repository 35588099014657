import { Button, Dialog, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Stack, TextField, TextareaAutosize, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { HarmonicRegisterModel, InfoCircuit, MsLoadNewFormModel } from '../../models/Index';
import { FormEvent, useEffect, useState } from "react";
import theme from "../../themes/globalStyles";
import { BeamsNumber } from "../../utils/FormatNumber";
import DraggablePaper from "../common/DraggablePaper";
import TextFieldOptions from "../common/TextFieldOptions";
import { doCalcCoverRated, handleCurrent } from "../../utils/HarmonicFunction";

export type HarmonicRegisterDialogFormProps = {
    changeCombodata: any,
    controlId: string,
    data: HarmonicRegisterModel;
    onOK: (data: any) => void;
    onCancel: () => void;
    onChangeComboData: (data : any, controlId : string) => void;
    setChangeComboData: (data : any) => void;
    m_bModePM: boolean;
    infoCircuit: InfoCircuit;
    modeViewOnly:boolean;
};

const _3phasesOptions = [
    {
        label: "3相",
        value: 1,
    },
    {
        label: "2相",
        value: 0,
    }
]

export const HarmonicRegisterDialog = (props: HarmonicRegisterDialogFormProps) => {
    // #region properties
    const {infoCircuit, changeCombodata, controlId, data, onOK, onCancel, onChangeComboData, setChangeComboData ,m_bModePM,modeViewOnly} = props;

    const [isZeroTotalCapacity, setIsZeroTotalCapacity] = useState<boolean>(data.totalCapacity === 0);
    const [isEnableOkButton, setIsEnableOkButton] = useState<boolean>(true);
    const regexDecimal = /^\b(?=0*([1-9]\d*|\d+(\.\d+)?))\d+(\.\d+)?\b$/;
    const regexInteger = /^[1-9]\d*$/;
    const messageError = "> 0";

    // 初期値設定
    const initialValue = {
        hamorItemID: data.hamorItemID,
        voltage: infoCircuit.voltage,
        voltageList: infoCircuit.voltageList,
        electricPower: infoCircuit.electricPower,
        name: data.name,
        manufacture: data.manufacture,
        type: data.type,
        capacity: data.capacity,
        number: data.number,
        totalCapacity: data.totalCapacity,
        kindCode: data.kindCode,
        kindList: data.kindList,
        example: data.example,
        detailCode: data.detailCode,
        detailList: data.detailList,
        b3Phases: data.b3Phases ? 1 : 0,
        coEfficient: data.coEfficient,
        convertRated: doCalcCoverRated(data.b3Phases,data.capacity,data.number),
        operatingRatio: data.operatingRatio,
        percent5: data.percent5,
        percent7: data.percent7,
        percent11: data.percent11,
        percent13: data.percent13,
        percent17: data.percent17,
        percent19: data.percent19,
        percent23: data.percent23,
        percent25: data.percent25,
        current5: handleCurrent(data.percent5,data.b3Phases,data.capacity,data.number,data.operatingRatio),
        current7: handleCurrent(data.percent7,data.b3Phases,data.capacity,data.number,data.operatingRatio),
        current11: handleCurrent(data.percent11,data.b3Phases,data.capacity,data.number,data.operatingRatio),
        current13: handleCurrent(data.percent13,data.b3Phases,data.capacity,data.number,data.operatingRatio),
        current17: handleCurrent(data.percent17,data.b3Phases,data.capacity,data.number,data.operatingRatio),
        current19: handleCurrent(data.percent19,data.b3Phases,data.capacity,data.number,data.operatingRatio),
        current23: handleCurrent(data.percent23,data.b3Phases,data.capacity,data.number,data.operatingRatio),
        current25: handleCurrent(data.percent25,data.b3Phases,data.capacity,data.number,data.operatingRatio),
        equipvalentCapacity: data.equipvalentCapacity,
        kindName: data.kindName,
        detailName: data.detailName,
        harmoListId: data.harmoListId
    }

    const { control, handleSubmit, formState: { errors }, setValue, getValues, reset } = useForm<any>({
        mode: 'all',
        criteriaMode: "all",
        defaultValues: initialValue,
    });

    // #endregion properties

    // #region method

    // Submitイベント
    const handleEditSubmit = (value: HarmonicRegisterModel, event: any) => {
        const request = createRequestData(value);
        onOK(request);
    }

    // FormデータからRequestデータを作成する
    const createRequestData = (formValue: HarmonicRegisterModel) => {
        let request = {
            hamorItemID:formValue.hamorItemID,
            harmoListId: formValue.harmoListId,
            voltage: formValue.voltage,
            electricPower: formValue.electricPower,
            name: formValue.name,
            manufacture: formValue.manufacture,
            type: formValue.type,
            capacity: formValue.capacity,
            number: formValue.number,
            totalCapacity: formValue.totalCapacity,
            kindCode: formValue.kindCode,
            kindList: formValue.kindList,
            // kindName: formValue.kindName,
            example: formValue.example,
            detailCode: formValue.detailCode,
            detailList: formValue.detailList,
            // detailName: formValue.detailName,
            b3Phases: formValue.b3Phases,
            coEfficient: formValue.coEfficient,
            convertRated: formValue.convertRated,
            operatingRatio: formValue.operatingRatio,
            percent5: formValue.percent5,
            percent7: formValue.percent7,
            percent11: formValue.percent11,
            percent13: formValue.percent13,
            percent17: formValue.percent17,
            percent19: formValue.percent19,
            percent23: formValue.percent23,
            percent25: formValue.percent25,
            current5: formValue.current5,
            current7: formValue.current7,
            current11: formValue.current11,
            current13: formValue.current13,
            current17: formValue.current17,
            current19: formValue.current19,
            current23: formValue.current23,
            current25: formValue.current25,
            equipvalentCapacity: formValue.equipvalentCapacity,
        } as HarmonicRegisterModel;
        return request;
    }

    // キャンセル
    const handleCancel = () => {
        onCancel();
    };

    const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault(); // prevent default form submission
        event.stopPropagation(); // stop event propagation to parent form
        handleSubmit(handleEditSubmit)(event); // handle child form submission
    };

    const handleChangeVoltage = (e:any) => {
        setValue(e.target.name,e.target.value)
        if(e.target.value <= 0) {
            setIsZeroTotalCapacity(true);
        }else{
            setIsZeroTotalCapacity(false);
        }
        doSetHarmonicValue(e);
        // TODO: SELECT VOLTAGE
    }

    const handleChangeCapacity = (e:any) => {
        let dvalue = (Number(e.target.value) + 0.0000005) * 1000000;
        const capacity = Math.floor(dvalue)/1000000;
        // setValue("capacity",capacity);
        const totalCapacity = capacity * getValues("number");
        if(totalCapacity <= 0)
        {
            setValue("totalCapacity",0)
            setIsZeroTotalCapacity(true);
        }
        else
        {
            setValue("totalCapacity",BeamsNumber(totalCapacity,4));
            setIsZeroTotalCapacity(false);
        }
        doSetHarmonicValue(e);
    }

    const handleChangeCoEfficient = (e:any) => {
        if(e.target.value < 0){
            setIsEnableOkButton(false);
        }else{
            setIsEnableOkButton(true);
        }
        doSetHarmonicValue(e);
    }

    const handleChangeNumber =(e:any) => {
        const totalCapacity = getValues("capacity") * getValues("number");
        if(totalCapacity <= 0)
        {
            setValue("totalCapacity",0)
            setIsZeroTotalCapacity(true);
        }
        else
        {
            setValue("totalCapacity",BeamsNumber(totalCapacity,4));
            setIsZeroTotalCapacity(false);
        }
        doSetHarmonicValue(e);
    }

    const handleChangeOperatingRatio = (e:any) => {
        if(e.target.value <= 0){
            // setIsZeroTotalCapacity(true);
            setIsEnableOkButton(false);
        }else{
            // setIsZeroTotalCapacity(false);
            setIsEnableOkButton(true);
        }
        doSetHarmonicValue();
    }
    
    const reCalcValueEquipvalentCapacity = () => {
        const equipvalentCapacity = Number(getValues("capacity")) * Number(getValues("number")) * Number(getValues("coEfficient"));
        if(equipvalentCapacity <= 0)
            return 0
        else
            return BeamsNumber(equipvalentCapacity,4);
    }

    const doSetHarmonicValue = (e : any = null) => {
        const voltage = Number(getValues("voltage"));
        if(e && e.target.value < 0)
        {
            setIsZeroTotalCapacity(true);
        }
        else{
            setValue("equipvalentCapacity",reCalcValueEquipvalentCapacity());
            setValue("convertRated",doGetConvertRated(getValues("voltage")));
            // if(e.target.name !== "operatingRatio"){
            // }
            // setValue("operatingRatio",BeamsNumber(Number(getValues("operatingRatio")),0));
            setValue("percent5",BeamsNumber(Number(getValues("percent5")),4));
            setValue("percent7",BeamsNumber(Number(getValues("percent7")),4));
            setValue("percent11",BeamsNumber(Number(getValues("percent11")),4));
            setValue("percent13",BeamsNumber(Number(getValues("percent13")),4));
            setValue("percent17",BeamsNumber(Number(getValues("percent17")),4));
            setValue("percent19",BeamsNumber(Number(getValues("percent19")),4));
            setValue("percent23",BeamsNumber(Number(getValues("percent23")),4));
            setValue("percent25",BeamsNumber(Number(getValues("percent25")),4));
            setValue("current5",BeamsNumber(doGetValueCurrent(voltage,"percent5"),4));
            setValue("current7",BeamsNumber(doGetValueCurrent(voltage,"percent7"),4));
            setValue("current11",BeamsNumber(doGetValueCurrent(voltage,"percent11"),4));
            setValue("current13",BeamsNumber(doGetValueCurrent(voltage,"percent13"),4));
            setValue("current17",BeamsNumber(doGetValueCurrent(voltage,"percent17"),4));
            setValue("current19",BeamsNumber(doGetValueCurrent(voltage,"percent19"),4));
            setValue("current23",BeamsNumber(doGetValueCurrent(voltage,"percent23"),4));
            setValue("current25",BeamsNumber(doGetValueCurrent(voltage,"percent25"),4));
        }
    }

    const doGetValueCurrent = (voltage:number,percent:any) => {
        const convertRated = doGetConvertRated(voltage);
        const result = BeamsNumber(Number(getValues(percent)) * convertRated * Number(getValues("operatingRatio")) / 10000,0)
        return result;
    }

    const doGetConvertRated = (voltage:number) => {
        if(voltage <= 0)
            return 0;
        let convertRated = 0;

        if(getValues("b3Phases"))
            convertRated = getValues("capacity") * getValues("number") * 1000 * 1000 / voltage / Math.sqrt(3.0);
        else
            convertRated = getValues("capacity") * getValues("number") * 1000 * 1000 / voltage;
        return BeamsNumber(convertRated,4);
    }

    const handleChangePercent = (e:any,currentName:any) => {
        const convertRated = doGetConvertRated(getValues("voltage"));
        const result = BeamsNumber(e.target.value * convertRated * getValues("operatingRatio") / 10000,0)
        setValue(currentName,result)
    }
    
    useEffect(() => {
        if (changeCombodata) {
            setValue("harmoListId",changeCombodata.harmoListId)
            setChangeComboData(null)
            reset({
                ...getValues(),
                ...changeCombodata,
                b3Phases: changeCombodata.b3Phases ? 1 : 0
            })
            doSetHarmonicValue()
        }
    }, [changeCombodata, reset])

    const kindList =() =>{
        let kindList = getValues("kindList")
        let kindList1:  any[] =[]
        for(const item of kindList){
            if(!kindList1.map(o =>o.label).includes(item.label)){
                kindList1.push(item)
            }
        }

        return kindList1

    }

    const detailList =() =>{
        let detailList = getValues("detailList")
        let detailList1:  any[] =[]
        for(const item of detailList){
            if(!detailList1.map(o =>o.label).includes(item.label)){
                detailList1.push(item)
            }
        }

        return detailList1

    }
    // #endregion method

    return (
        <Dialog open={true} maxWidth={'md'} fullWidth PaperComponent={DraggablePaper}>
            <DialogTitle 
                onMouseOver={(e : any) => e.target.style.cursor = 'move'} 
                style={{ 
                    paddingTop: '2px', 
                    paddingBottom: '2px', 
                    background: theme.palette.primary.main, 
                    color: theme.palette.primary.contrastText 
                }}>
                    高調波発生機器の明細
            </DialogTitle>
            <DialogContent sx={{ pb: 1.5 }}>
                <form onSubmit={onSubmit}>
                    <div style={{ height: "50vh", overflow: "auto", width: '100%', margin: 'auto' }}>
                        <Stack mt={1}>
                            <Grid container>
                                
                                <Grid item xs={6} pr={1}>
                                    <Stack mt={0.5}>
                                        <Typography variant='body2'>高調波抑制対策技術指針(JEAG 9702-1995)に準拠しています。</Typography>
                                    </Stack>
                                    <Stack spacing={0.3} mt ={1}>
                                        {/* 受電電圧(V) */}
                                        <Stack flexDirection='row' alignItems='center'>
                                            <Grid item xs ={3.1}>
                                            <Typography variant='body2'>受電電圧(V):</Typography>
                                            </Grid>
                                            <Grid item xs ={8.9}>
                                            <Controller
                                                name="voltage"
                                                control={control}
                                                rules={{
                                                    required: '必須項目です。入力してください。',
                                                    pattern: {
                                                        value: /^[0-9\b]+$/,
                                                        message: '数字を入力してください。'
                                                    },
                                                    validate: value => value > 0 || '数字 > 0 を入力してください。',
                                                }}
                                                render={({ field }: any) => (
                                                    <TextFieldOptions
                                                        {...field}
                                                        options={initialValue.voltageList}
                                                        type='text'
                                                        size='small'
                                                        error={!!errors?.voltage}
                                                        helperText={errors?.voltage?.message}
                                                        height={32}
                                                        maxLength={6}
                                                        setValue={(name:any,value:any) => {
                                                            handleChangeVoltage({target:{name:name,value:value}})}
                                                        }
                                                        disabled={m_bModePM || modeViewOnly}
                                                    />
                                                )}
                                                
                                        
                                            />
                                            </Grid>
                                        </Stack>
                                        {/* 契約電力(kW) */}
                                        <Stack flexDirection='row' alignItems='center'>
                                            <Typography variant='body2' width='35%'>契約電力(kW):</Typography>
                                            <Controller
                                                name="electricPower"
                                                control={control}
                                                rules={{
                                                    required: '必須項目です。入力してください。',
                                                    pattern: {
                                                        value: /^[0-9\b]+$/,
                                                        message: '数字を入力してください。'
                                                    },
                                                    validate: value => value > 0 || '数字 > 0 を入力してください。',
                                                }}
                                                render={({ field, fieldState }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        error={!!errors?.electricPower}
                                                        helperText={fieldState.error?.message}
                                                        InputProps={{ sx: { height: 32 , color:modeViewOnly?"grey":"black", backgroundColor:modeViewOnly?'floralwhite':'white'} }}
                                                        disabled={m_bModePM}
                                                        inputProps={{maxlength:9, readOnly:modeViewOnly}}

                                                    />
                                                )}
                                            />
                                        </Stack>
                                        {/* 機器名称 */}
                                        <Stack flexDirection='row' alignItems='center'>
                                            <Typography variant='body2' width='35%'>機器名称:</Typography>
                                            <Controller
                                                name="name"
                                                control={control}
                                                rules={{
                                                    required: '必須項目です。入力してください。',
                                                }}
                                                render={({ field, fieldState }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        error={!!errors?.name}
                                                        helperText={fieldState.error?.message}
                                                        InputProps={{ sx: { height: 32, color:modeViewOnly?"grey":"black", backgroundColor:modeViewOnly?'floralwhite':'white'} }}
                                                        inputProps={{readOnly:modeViewOnly}}
                                                        disabled={m_bModePM}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                        {/* 製造業者 */}
                                        <Stack flexDirection='row' alignItems='center'>
                                            <Typography variant='body2' width='35%'>製造業者:</Typography>
                                            <Controller
                                                name="manufacture"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        error={!!errors?.manufacture}
                                                        InputProps={{ sx: { height: 32, color:modeViewOnly?"grey":"black", backgroundColor:modeViewOnly?'floralwhite':'white' } }}
                                                        inputProps={{readOnly:modeViewOnly}}
                                                        disabled={m_bModePM}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                        {/* 形式 */}
                                        <Stack flexDirection='row' alignItems='center'>
                                            <Typography variant='body2' width='35%'>形式:</Typography>
                                            <Controller
                                                name="type"
                                                control={control}
                                                render={({ field, fieldState }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        error={!!errors?.type}
                                                        helperText={fieldState.error?.message}
                                                        InputProps={{ sx: { height: 32, color:modeViewOnly?"grey":"black", backgroundColor:modeViewOnly?'floralwhite':'white' } }}
                                                        inputProps={{readOnly:modeViewOnly}}
                                                        disabled={m_bModePM}
                                                    />
                                                )}
                                            />
                                        </Stack>

                                        <Grid container>
                                            {/* 定格容量(kVA) */}
                                            <Grid item xs={7}>
                                                <Stack flexDirection='row' alignItems='center'>
                                                    <Typography variant='body2' width='80%'>定格容量(kVA):</Typography>
                                                    <Controller
                                                        name="capacity"
                                                        control={control}
                                                        rules={{
                                                            required: '必須項目です。入力してください。',
                                                            pattern: {
                                                                value: regexDecimal,
                                                                message: messageError,
                                                            },
                                                        }}
                                                        render={({ field, fieldState }) => (
                                                            <TextField
                                                                {...field}
                                                                fullWidth
                                                                // type="text"
                                                                size="small"
                                                                error={!!errors?.capacity}
                                                                helperText={fieldState.error?.message}
                                                                onChange={(e) => {
                                                                    field.onChange(e);
                                                                    handleChangeCapacity(e);
                                                                }}
                                                                InputProps={{ sx: { height: 32, color:modeViewOnly?"grey":"black", backgroundColor:modeViewOnly?'floralwhite':'white' } }}
                                                                disabled={m_bModePM}
                                                                inputProps={{maxlength:5, readOnly:modeViewOnly}}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                            </Grid>
                                            {/* 台数 */}
                                            <Grid item xs={5} pl={2}>
                                                <Stack flexDirection='row' alignItems='center'>
                                                    <Typography variant='body2' width='35%'>台数:</Typography>
                                                    <Controller
                                                        name="number"
                                                        control={control}
                                                        rules={{
                                                            required: '必須項目です。入力してください。',
                                                            validate: value => regexInteger.test(value.toString()) || '数字 > 0 を入力してください。',
                                                        }}
                                                        render={({ field, fieldState }) => (
                                                            <TextField
                                                                {...field}
                                                                fullWidth
                                                                type="text"
                                                                size="small"
                                                                error={!!errors?.number}
                                                                helperText={fieldState.error?.message}
                                                                onChange={(e) => {
                                                                    field.onChange(e);
                                                                    handleChangeNumber(e);
                                                                }}
                                                                InputProps={{ sx: { height: 32, color:modeViewOnly?"grey":"black", backgroundColor:modeViewOnly?'floralwhite':'white' } }}
                                                                disabled={m_bModePM}
                                                                inputProps={{maxlength:4, readOnly:modeViewOnly}}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                        {/* 合計容量(kVA) */}
                                        <Grid container direction="row">
                                            <Grid item xs={7}>
                                                <Stack flexDirection='row' alignItems='center'>
                                                    <Typography variant='body2' width='80%'>合計容量(kVA):</Typography>
                                                    <Controller
                                                        name="totalCapacity"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                fullWidth
                                                                type="text"
                                                                size="small"
                                                                inputProps={{ readOnly: true }}
                                                                style={{ backgroundColor: 'floralwhite' }}
                                                                InputProps={{ sx: { height: 32, color:modeViewOnly?"grey":"black", backgroundColor:'floralwhite' } }}
                                                                disabled={m_bModePM}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={5} pl={2} pt={0.5}>
                                                <Typography variant="body2">(定格容量 x 台数)</Typography>
                                            </Grid>
                                        </Grid>
                                        {/* 回路分類 */}
                                        <Stack flexDirection='row' alignItems='center'>
                                            <Typography variant='body2' width='35%'>回路分類:</Typography>
                                            <Controller
                                                name="kindCode"
                                                control={control}
                                                rules={{ required: '必須項目です。入力してください。' }}
                                                render={({ field }: any) => (
                                                    <Select
                                                        {...field}
                                                        fullWidth
                                                        error={!!errors?.kindCode}
                                                        disabled={isZeroTotalCapacity||m_bModePM|| modeViewOnly}
                                                        onChange={(e) => {
                                                            field.onChange(e)
                                                             onChangeComboData({comboName: "KIND_CODE", value: e.target.value}, controlId)
                                                        }}
                                                        sx={{ height: 32 }}
                                                    >
                                                        {kindList().map((item : any) => (
                                                            <MenuItem key={item.label} value={item.value}>
                                                                {item.label}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                )}
                                            />
                                        </Stack>
                                        {/* 利用例 */}
                                        <Stack flexDirection='row' alignItems='center'>
                                            <Typography variant='body2' width='35%'>利用例:</Typography>
                                            <FormControl fullWidth size="small">
                                                <Controller
                                                    name="example"
                                                    control={control}
                                                    render={({ field }: any) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            minRows={3}
                                                            multiline={true}
                                                            inputProps={{ readOnly: true}}
                                                            style={{ backgroundColor: 'floralwhite' }}
                                                            InputProps={{ sx: {color:modeViewOnly?"grey":"black", backgroundColor:'floralwhite' } }}
                                                        />
                                                    )}
                                                />
                                            </FormControl>
                                        </Stack>
                                        <Grid container>
                                            <Grid item xs={9}>
                                                {/* 回路細分 */}
                                                <Stack flexDirection='row' alignItems='center'>
                                                    <Typography variant='body2' width='52%'>回路細分:</Typography>
                                                    <Controller
                                                        name="detailCode"
                                                        control={control}
                                                        rules={{ required: '必須項目です。入力してください。' }}
                                                        render={({ field }: any) => (
                                                            <Select
                                                                {...field}
                                                                fullWidth
                                                                error={!!errors?.detailCode}
                                                                disabled={isZeroTotalCapacity||m_bModePM|| modeViewOnly}
                                                                onChange={(e) => {
                                                                    field.onChange(e)
                                                                    onChangeComboData({comboName: "DETAIL_CODE", value: e.target.value}, controlId)
                                                                }}
                                                                sx={{ height: 32 }}
                                                            >
                                                                {detailList().map((item : any) => (
                                                                    <MenuItem key={item.label} value={item.value}>
                                                                        {item.label}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        )}
                                                    />
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={3} pl={1}>
                                                <Stack>
                                                    <Controller
                                                        name="b3Phases"
                                                        control={control}
                                                        render={({ field }: any) => (
                                                            <Select
                                                                {...field}
                                                                fullWidth
                                                                style={{ backgroundColor: isZeroTotalCapacity||m_bModePM || getValues("kindCode") !== 7? 'floralwhite':'white' }}
                                                                sx={{ height: 32 }}
                                                                disabled={getValues("kindCode") !== 7||isZeroTotalCapacity||m_bModePM|| modeViewOnly}

                                                            >
                                                                {_3phasesOptions.map(item => (
                                                                    <MenuItem key={item.label} value={item.value}>
                                                                        {item.label}
                                                                    </MenuItem>
                                                                ))}
                                                                
                                                            </Select>
                                                        )}
                                                    />
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                        {/* 6パルス換算係数 Ki */}
                                        <Stack flexDirection='row' alignItems='center' width='58.3%'>
                                            <Typography variant='body2' width='175%'>6パルス換算係数 Ki:</Typography>
                                            <Controller
                                                name="coEfficient"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        // type="number"
                                                        size="small"
                                                        error={!!errors?.coEfficient}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            handleChangeCoEfficient(e);
                                                        }}
                                                        InputProps={{ sx: { height: 32, color:modeViewOnly?"grey":"black", backgroundColor:modeViewOnly?'floralwhite':'white' } }}
                                                        disabled={m_bModePM}
                                                        inputProps={{maxlength:5, readOnly:modeViewOnly}}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                        <Grid container>
                                            {/* 6パルス等価容量(kVA) */}
                                            <Grid item xs={7}>
                                                <Stack flexDirection='row' alignItems='center'>
                                                    <Typography variant='body2' width='175%'>6パルス等価容量(kVA):</Typography>
                                                    <Controller
                                                        name="equipvalentCapacity"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                fullWidth
                                                                type="text"
                                                                size="small"
                                                                inputProps={{ readOnly: true }}
                                                                style={{ backgroundColor: 'floralwhite' }}
                                                                InputProps={{ sx: { height: 32, color:modeViewOnly?"grey":"black", backgroundColor:'floralwhite' } }}
                                                                disabled={m_bModePM}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={5} pl={2} pt={1}>
                                                <Typography variant='body2'>合計容量 x 換算係数</Typography>
                                            </Grid>
                                        </Grid>
                                    </Stack>
                                </Grid>
                                <Grid item xs={6} pl={1} mt = {4}>
                                    <Stack spacing={0.3}>
                                        {/* 受電電圧換算定格電流(mA) */}
                                        <Stack flexDirection='row' alignItems='center'>
                                            <Typography variant='body2' width='80%'>受電電圧換算定格電流(mA):</Typography>
                                            <Controller
                                                name="convertRated"
                                                control={control}
                                                rules={{
                                                    pattern: {
                                                        value: /^[0-9\b]+$/,
                                                        message: '数字を入力してください。'
                                                    },
                                                    validate: value => value >= 0 || '数字 > 0 を入力してください。',
                                                }}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        disabled={isZeroTotalCapacity||m_bModePM}
                                                        inputProps={{ readOnly: true }}
                                                        style={{ backgroundColor: 'floralwhite' }}
                                                        InputProps={{ sx: { height: 32, color:modeViewOnly?"grey":"black", backgroundColor:'floralwhite' } }}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                        {/* 機器最大稼働率(%) */}
                                        <Stack flexDirection='row' alignItems='center'>
                                            <Typography variant='body2' width='80%'>機器最大稼働率(%):</Typography>
                                            <Controller
                                                name="operatingRatio"
                                                control={control}
                                                rules={{
                                                    required: '必須項目です。入力してください。',
                                                    pattern: {
                                                        value: /^[0-9\b]+$/,
                                                        message: '数字を入力してください。'
                                                    },
                                                    validate: value => value > 0 || '数字 > 0 を入力してください。',
                                                }}
                                                render={({ field,fieldState }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        onChange= {(e:any) => {
                                                            field.onChange(e);
                                                            handleChangeOperatingRatio(e);
                                                        }}
                                                        error={!!errors?.operatingRatio}
                                                        helperText={fieldState.error?.message}
                                                        inputProps={{maxlength:4, readOnly:modeViewOnly}}
                                                        InputProps={{ sx: { height: 32, color:modeViewOnly?"grey":"black", backgroundColor:modeViewOnly?'floralwhite':'white' } }}
                                                        disabled={isZeroTotalCapacity||m_bModePM}

                                                    />
                                                )}
                                            />
                                        </Stack>
                                        <Stack pt={1}>
                                            <fieldset style={{border:"1px solid #AAAA"}}>
                                                <legend><Typography variant="body2">高調波流出電流</Typography></legend>
                                                <Grid container justifyContent={"flex-end"}>
                                                    <Grid item xs={5}>
                                                        <Typography variant="body2">(%)</Typography>
                                                    </Grid>
                                                    <Grid item xs={3.5}>
                                                        <Typography variant="body2">(mA)</Typography>
                                                    </Grid>
                                                </Grid>
                                                <Stack spacing={0.5}>
                                                    <Grid container alignItems='center'>
                                                        <Grid item xs={1.5}>
                                                            <Typography variant="body2">5次</Typography>
                                                        </Grid>
                                                        {/* 5次 */}
                                                        <Grid item xs={5}>
                                                            <Controller
                                                                name="percent5"
                                                                control={control}
                                                                // rules={{
                                                                //     validate: value => value > 0 || '数字 > 0 を入力してください。',
                                                                // }}
                                                                render={({ field }) => (
                                                                    <TextField
                                                                        {...field}
                                                                        fullWidth
                                                                        type="text"
                                                                        size="small"
                                                                        error={!!errors?.percent5}
                                                                        disabled={isZeroTotalCapacity||m_bModePM}
                                                                        onChange={(e:any) => {
                                                                            field.onChange(e);
                                                                            handleChangePercent(e,"current5");
                                                                        }}
                                                                        InputProps={{ sx: { height: 32, color:modeViewOnly?"grey":"black", backgroundColor:modeViewOnly?'floralwhite':'white' } }}
                                                                        inputProps={{maxlength:5, readOnly:modeViewOnly}}
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={5} ml={1}>
                                                            <Controller
                                                                name="current5"
                                                                control={control}
                                                                render={({ field }) => (
                                                                    <TextField
                                                                        {...field}
                                                                        fullWidth
                                                                        type="text"
                                                                        size="small"
                                                                        disabled={isZeroTotalCapacity||m_bModePM}
                                                                        inputProps={{ readOnly: true }}
                                                                        style={{ backgroundColor: 'floralwhite' }}
                                                                        InputProps={{ sx: { height: 32, color:modeViewOnly?"grey":"black", backgroundColor:'floralwhite' } }}
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container alignItems='center'>
                                                        <Grid item xs={1.5}>
                                                            <Typography variant="body2">7次</Typography>
                                                        </Grid>
                                                        {/* 7次 */}
                                                        <Grid item xs={5}>
                                                            <Controller
                                                                name="percent7"
                                                                control={control}
                                                                rules={{
                                                                    required: '必須項目です。入力してください。',
                                                                    // validate: value => Number(value) > 0 || '数字 > 0 を入力してください。',
                                                                }}
                                                                render={({ field }) => (
                                                                    <TextField
                                                                        {...field}
                                                                        fullWidth
                                                                        type="text"
                                                                        size="small"
                                                                        error={!!errors?.percent7}
                                                                        disabled={isZeroTotalCapacity||m_bModePM}
                                                                        onChange={(e:any) => {
                                                                            field.onChange(e);
                                                                            handleChangePercent(e,"current7");
                                                                        }}
                                                                        InputProps={{ sx: { height: 32, color:modeViewOnly?"grey":"black", backgroundColor:modeViewOnly?'floralwhite':'white' } }}
                                                                        inputProps={{maxlength:5, readOnly:modeViewOnly}}
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={5} ml={1}>
                                                            <Controller
                                                                name="current7"
                                                                control={control}
                                                                render={({ field }) => (
                                                                    <TextField
                                                                        {...field}
                                                                        fullWidth
                                                                        type="text"
                                                                        size="small"
                                                                        inputProps={{ readOnly: true }}
                                                                        style={{ backgroundColor: 'floralwhite' }}
                                                                        InputProps={{ sx: { height: 32, color:modeViewOnly?"grey":"black", backgroundColor:'floralwhite' } }}
                                                                        disabled={isZeroTotalCapacity||m_bModePM}
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container alignItems='center'>
                                                        <Grid item xs={1.5}>
                                                            <Typography variant="body2">11次</Typography>
                                                        </Grid>
                                                        {/* 11次 */}
                                                        <Grid item xs={5}>
                                                            <Controller
                                                                name="percent11"
                                                                control={control}
                                                                rules={{
                                                                    // validate: value => value > 0 || '数字 > 0 を入力してください。',
                                                                }}
                                                                render={({ field }) => (
                                                                    <TextField
                                                                        {...field}
                                                                        fullWidth
                                                                        type="text"
                                                                        size="small"
                                                                        error={!!errors?.percent11}
                                                                        disabled={isZeroTotalCapacity||m_bModePM}
                                                                        onChange={(e:any) => {
                                                                            field.onChange(e);
                                                                            handleChangePercent(e,"current11");
                                                                        }}
                                                                        InputProps={{ sx: { height: 32, color:modeViewOnly?"grey":"black", backgroundColor:modeViewOnly?'floralwhite':'white' } }}
                                                                        inputProps={{maxlength:5, readOnly:modeViewOnly}}

                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={5} ml={1}>
                                                            <Controller
                                                                name="current11"
                                                                control={control}
                                                                render={({ field }) => (
                                                                    <TextField
                                                                        {...field}
                                                                        fullWidth
                                                                        type="text"
                                                                        size="small"
                                                                        inputProps={{ readOnly: true }}
                                                                        style={{ backgroundColor: 'floralwhite' }}
                                                                        InputProps={{ sx: { height: 32, color:modeViewOnly?"grey":"black", backgroundColor:'floralwhite' } }}
                                                                        disabled={isZeroTotalCapacity||m_bModePM}
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container alignItems='center'>
                                                        <Grid item xs={1.5}>
                                                            <Typography variant="body2">13次</Typography>
                                                        </Grid>
                                                        {/* 13次 */}
                                                        <Grid item xs={5}>
                                                            <Controller
                                                                name="percent13"
                                                                control={control}
                                                                rules={{
                                                                    // validate: value => value > 0 || '数字 > 0 を入力してください。',
                                                                }}
                                                                render={({ field }) => (
                                                                    <TextField
                                                                        {...field}
                                                                        fullWidth
                                                                        type="text"
                                                                        size="small"
                                                                        error={!!errors?.percent13}
                                                                        disabled={isZeroTotalCapacity||m_bModePM}
                                                                        onChange={(e:any) => {
                                                                            field.onChange(e);
                                                                            handleChangePercent(e,"current13");
                                                                        }}
                                                                        InputProps={{ sx: { height: 32, color:modeViewOnly?"grey":"black", backgroundColor:modeViewOnly?'floralwhite':'white' } }}
                                                                        inputProps={{maxlength:5, readOnly:modeViewOnly}}

                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={5} ml={1}>
                                                            <Controller
                                                                name="current13"
                                                                control={control}
                                                                render={({ field }) => (
                                                                    <TextField
                                                                        {...field}
                                                                        fullWidth
                                                                        type="text"
                                                                        size="small"
                                                                        inputProps={{ readOnly: true }}
                                                                        style={{ backgroundColor: 'floralwhite' }}
                                                                        InputProps={{ sx: { height: 32, color:modeViewOnly?"grey":"black", backgroundColor:'floralwhite' } }}
                                                                        disabled={isZeroTotalCapacity||m_bModePM}
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container alignItems='center'>
                                                        <Grid item xs={1.5}>
                                                            <Typography variant="body2">17次</Typography>
                                                        </Grid>
                                                        {/* 17次 */}
                                                        <Grid item xs={5}>
                                                            <Controller
                                                                name="percent17"
                                                                control={control}
                                                                rules={{
                                                                    // validate: value => value > 0 || '数字 > 0 を入力してください。',
                                                                }}
                                                                render={({ field }) => (
                                                                    <TextField
                                                                        {...field}
                                                                        fullWidth
                                                                        type="text"
                                                                        size="small"
                                                                        error={!!errors?.percent17}
                                                                        disabled={isZeroTotalCapacity||m_bModePM}
                                                                        onChange={(e:any) => {
                                                                            field.onChange(e);
                                                                            handleChangePercent(e,"current17");
                                                                        }}
                                                                        InputProps={{ sx: { height: 32, color:modeViewOnly?"grey":"black", backgroundColor:modeViewOnly?'floralwhite':'white' } }}
                                                                        inputProps={{maxlength:5, readOnly:modeViewOnly}}

                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={5} ml={1}>
                                                            <Controller
                                                                name="current17"
                                                                control={control}
                                                                render={({ field }) => (
                                                                    <TextField
                                                                        {...field}
                                                                        fullWidth
                                                                        type="text"
                                                                        size="small"
                                                                        inputProps={{ readOnly: true }}
                                                                        style={{ backgroundColor: 'floralwhite' }}
                                                                        InputProps={{ sx: { height: 32, color:modeViewOnly?"grey":"black", backgroundColor:'floralwhite' } }}
                                                                        disabled={isZeroTotalCapacity||m_bModePM}
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container alignItems='center'>
                                                        <Grid item xs={1.5}>
                                                            <Typography variant="body2">19次</Typography>
                                                        </Grid>
                                                        {/* 19次 */}
                                                        <Grid item xs={5}>
                                                            <Controller
                                                                name="percent19"
                                                                control={control}
                                                                rules={{
                                                                    // validate: value => value > 0 || '数字 > 0 を入力してください。',
                                                                }}
                                                                render={({ field }) => (
                                                                    <TextField
                                                                        {...field}
                                                                        fullWidth
                                                                        type="text"
                                                                        size="small"
                                                                        error={!!errors?.percent19}
                                                                        disabled={isZeroTotalCapacity||m_bModePM}
                                                                        onChange={(e:any) => {
                                                                            field.onChange(e);
                                                                            handleChangePercent(e,"current19");
                                                                        }}
                                                                        InputProps={{ sx: { height: 32, color:modeViewOnly?"grey":"black", backgroundColor:modeViewOnly?'floralwhite':'white' } }}
                                                                        inputProps={{maxlength:5, readOnly:modeViewOnly}}

                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={5} ml={1}>
                                                            <Controller
                                                                name="current19"
                                                                control={control}
                                                                render={({ field }) => (
                                                                    <TextField
                                                                        {...field}
                                                                        fullWidth
                                                                        type="text"
                                                                        size="small"
                                                                        inputProps={{ readOnly: true }}
                                                                        style={{ backgroundColor: 'floralwhite' }}
                                                                        InputProps={{ sx: { height: 32, color:modeViewOnly?"grey":"black", backgroundColor:'floralwhite' } }}
                                                                        disabled={isZeroTotalCapacity||m_bModePM}
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container alignItems='center'>
                                                        <Grid item xs={1.5}>
                                                            <Typography variant="body2">23次</Typography>
                                                        </Grid>
                                                        {/* 23次 */}
                                                        <Grid item xs={5}>
                                                            <Controller
                                                                name="percent23"
                                                                control={control}
                                                                rules={{
                                                                    // validate: value => value > 0 || '数字 > 0 を入力してください。',
                                                                }}
                                                                render={({ field }) => (
                                                                    <TextField
                                                                        {...field}
                                                                        fullWidth
                                                                        type="text"
                                                                        size="small"
                                                                        error={!!errors?.percent23}
                                                                        disabled={isZeroTotalCapacity||m_bModePM}
                                                                        onChange={(e:any) => {
                                                                            field.onChange(e);
                                                                            handleChangePercent(e,"current23");
                                                                        }}
                                                                        InputProps={{ sx: { height: 32, color:modeViewOnly?"grey":"black", backgroundColor:modeViewOnly?'floralwhite':'white' } }}
                                                                        inputProps={{maxlength:5, readOnly:modeViewOnly}}

                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={5} ml={1}>
                                                            <Controller
                                                                name="current23"
                                                                control={control}
                                                                render={({ field }) => (
                                                                    <TextField
                                                                        {...field}
                                                                        fullWidth
                                                                        type="text"
                                                                        size="small"
                                                                        inputProps={{ readOnly: true }}
                                                                        style={{ backgroundColor: 'floralwhite' }}
                                                                        InputProps={{ sx: { height: 32, color:modeViewOnly?"grey":"black", backgroundColor:'floralwhite' } }}
                                                                        disabled={isZeroTotalCapacity||m_bModePM}
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container alignItems='center'>
                                                        <Grid item xs={1.5}>
                                                            <Typography variant="body2">25次</Typography>
                                                        </Grid>
                                                        {/* 25次 */}
                                                        <Grid item xs={5}>
                                                            <Controller
                                                                name="percent25"
                                                                control={control}
                                                                rules={{
                                                                    // validate: value => value > 0 || '数字 > 0 を入力してください。',
                                                                }}
                                                                render={({ field }) => (
                                                                    <TextField
                                                                        {...field}
                                                                        fullWidth
                                                                        type="text"
                                                                        size="small"
                                                                        error={!!errors?.percent25}
                                                                        disabled={isZeroTotalCapacity||m_bModePM}
                                                                        onChange={(e:any) => {
                                                                            field.onChange(e);
                                                                            handleChangePercent(e,"current25");
                                                                        }}
                                                                        InputProps={{ sx: { height: 32, color:modeViewOnly?"grey":"black", backgroundColor:modeViewOnly?'floralwhite':'white' } }}
                                                                        inputProps={{maxlength:5, readOnly:modeViewOnly}}

                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={5} ml={1}>
                                                            <Controller
                                                                name="current25"
                                                                control={control}
                                                                render={({ field }) => (
                                                                    <TextField
                                                                        {...field}
                                                                        fullWidth
                                                                        type="text"
                                                                        size="small"
                                                                        inputProps={{ readOnly: true }}
                                                                        style={{ backgroundColor: 'floralwhite' }}
                                                                        InputProps={{ sx: { height: 32, color:modeViewOnly?"grey":"black", backgroundColor:'floralwhite' } }}
                                                                        disabled={isZeroTotalCapacity||m_bModePM}
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Stack>
                                            </fieldset>
                                        </Stack>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Stack>
                    </div>
                    <Stack direction='row' justifyContent="flex-end" spacing={2} mt={2}>
                        <Button variant="contained" type="submit" disabled={modeViewOnly}>OK</Button>
                        <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                    </Stack>
                </form >
            </DialogContent>
        </Dialog>
    );
};
export default HarmonicRegisterDialog;


