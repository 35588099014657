import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import { DialogTitle, Stack, Typography } from '@mui/material'
import theme from "../../themes/globalStyles";
import DraggablePaper from '../common/DraggablePaper'

export type InfomationDialogProps = {
  openDialog: boolean;
  setOpenDialog: (value: boolean) => void;
}

export const InfomationDialog = (props: InfomationDialogProps) => {
  const { openDialog, setOpenDialog } = props

  const handleCancel = () => {
    setOpenDialog(false)
  }

  return (
    <div>
      <Dialog
        open={openDialog}
        maxWidth={"xs"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperComponent={DraggablePaper}
        fullWidth
      >
        <DialogTitle
          onMouseOver={(e : any) => e.target.style.cursor = 'move'}
          style={{
            paddingBlock: "2px",
            background: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
          }}
        >
          バージョン情報
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Stack alignItems={'center'} mt={3}>
              <Typography sx={{color:'black', fontSize:'22px'}}>
                {process.env.REACT_APP_VERSION}
              </Typography>
            </Stack>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Stack alignItems={"flex-end"} mr={1} mb={1}>
            <Button
              onTouchEnd={handleCancel}
              onClick={handleCancel}
              color="inherit"
              variant="contained"
            >
              閉じる
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default InfomationDialog