import * as Constant from "../models/Constants";
import * as Element from "../models/ElementKind";
import { ElementKind } from "../models/ElementKind";
import * as Model from '../models/Index';
import { ControlModel, Coordinates, OptionModel, PartDetailsModel } from '../models/Index';

import image3Winding from "../images/control/三巻線変圧器＿無し.svg";
import image3Winding_2 from "../images/control/三巻線変圧器＿Ｙ／Ｙ／△.svg";
import image3Winding_3_5 from "../images/control/三巻線変圧器＿Ｙ／△／Ｙ＿Ｙ／△／Ｙｏ.svg";
import image3Winding_4 from "../images/control/三巻線変圧器＿Ｙ／△／△.svg";

import image3Winding_6_8 from "../images/control/三巻線変圧器＿△／△／Ｙ＿△／△／Ｙｏ.svg";
import image3Winding_7 from "../images/control/三巻線変圧器＿△／△／△.svg";



import imageTrans1_0 from "../images/control/単相変圧器＿無し.svg";
import imageTrans1_1 from "../images/control/単相変圧器＿ー／≡.svg";
import imageTransscott from "../images/control/スコット結線変圧器.svg";

import imageDS from "../images/control/断路器＿断路器.svg";
import imageDS2 from "../images/control/断路器＿高圧限流ﾋｭｰｽﾞ断路器.svg";
import imageDS3 from "../images/control/断路器＿差込形断路器.svg";

import imageLBS from "../images/control/開閉器＿開閉器.svg";
import imageLBS2 from "../images/control/開閉器＿高圧限流ﾋｭｰｽﾞ付開閉器.svg";
import imageLBS3 from "../images/control/開閉器＿負荷開閉器.svg";
import imageLBS4 from "../images/control/開閉器＿高圧限流ﾋｭｰｽﾞ付負荷開閉器.svg";

import imageMVCB from "../images/control/遮断器＿高圧遮断器／配線用遮断器.svg";
import imageMVCB2 from "../images/control/遮断器＿漏電遮断器.svg";
import imageMVCB3 from "../images/control/遮断器＿引出形遮断器.svg";

import imageContactor from "../images/control/電磁接触器＿ﾒｰｸ接点(a接点).svg";
import imageContactor2 from "../images/control/電磁接触器＿ﾌﾞﾚｰｸ接点(b接点).svg";
import imageFUSE_0 from "../images/control/開閉器＿高圧限流ﾋｭｰｽﾞ付開閉器.svg";
import imageFUSE_1 from "../images/control/断路器＿高圧限流ﾋｭｰｽﾞ断路器.svg";
import imageFUSE_2 from "../images/control/開閉器＿高圧限流ﾋｭｰｽﾞ付負荷開閉器.svg";
import imageFUSE_3 from "../images/control/高圧ヒューズ＿高圧限流ﾋｭｰｽﾞのみ.svg";
import image2E_0 from "../images/control/電動機保護リレー＿ﾒｰｸ接点(a接点).svg";
import image2E_1 from "../images/control/電動機保護リレー＿ﾌﾞﾚｰｸ接点(b接点).svg";
import imageZerocond from "../images/control/母線.svg";
import imageConnect from "../images/control/接続線.svg";
import imageWire from "../images/control/line.svg";
import imageBusbar from "../images/control/line.svg";
import imageBusduct from "../images/control/line.svg";
import imageReactor from "../images/control/限流リアクトル.svg";

import imageImpedance from "../images/control/line.svg";
import image_r from "../images/control/インピーダンス＿オーム＿R.svg";
import image_x from "../images/control/インピーダンス＿オーム＿X.svg";
import image_r_x from "../images/control/インピーダンス＿オーム＿R＿X.svg";
import image_x_r from "../images/control/インピーダンス＿オーム＿X＿R.svg";

import imageLVCB_0 from "../images/control/遮断器＿高圧遮断器／配線用遮断器.svg";
import imageLVCB_2 from "../images/control/低圧遮断器＿配線用遮断器(旧JIS).svg";
import imageLVCB_1 from "../images/control/低圧遮断器＿漏洩遮断器.svg";
import imageLVCB_3 from "../images/control/低圧遮断器＿引出形気中遮断器.svg";
import imageThermal_0 from "../images/control/サーマルリレー＿ﾒｰｸ接点(a接点).svg";
import imageThermal_1 from "../images/control/サーマルリレー＿ﾌﾞﾚｰｸ接点(b接点).svg";
import imageThermalCT from "../images/control/過負荷継電器.svg";
import imageMotorGroup from "../images/control/電動機群.svg";
import imageLoad from "../images/control/負荷.svg";
import imageLightBoard from "../images/control/分電盤.svg";
import imagePowerBoard from "../images/control/動力盤.svg";
import imageCapacitor from "../images/control/コンデンサ.svg";
import imageTransCenter_1 from "../images/control/変台.svg";
import imageINV_1 from "../images/control/計器用変成器＿VCT.svg";
import imageINV_2 from "../images/control/計器用変成器＿PCT.svg";
import imageINV_3 from "../images/control/計器用変成器＿MOF.svg";
import imageMsVT_1 from "../images/control/計器用変圧器.svg";
import imageArrestor_1_0 from "../images/control/避雷器＿設置なし.svg";
import imageArrestor_1_1 from "../images/control/避雷器＿設置あり.svg";
import imageArrestor_2_0 from "../images/control/避雷器(放電ｷﾞｬｯﾌﾟ)＿設置なし.svg";
import imageArrestor_2_1 from "../images/control/避雷器(放電ｷﾞｬｯﾌﾟ)＿設置あり.svg";
import imageArrestor_3_0 from "../images/control/避雷器ｻｰｼﾞｱﾌﾞｿｰﾊﾞ＿設置なし.svg";
import imageArrestor_3_1 from "../images/control/避雷器ｻｰｼﾞｱﾌﾞｿｰﾊﾞ＿設置あり.svg";
import imageEarth_1 from "../images/control/接地＿一般.svg";
import imageCableHead_1 from "../images/control/ケーブルヘッド＿下向き上向き.svg";
import imageCT_1 from "../images/control/変流器.svg";
import imageZCT_1 from "../images/control/零相変流器.svg";
import imageINV from "../images/control/インバーター.svg";
import imageRelay_1 from "../images/control/リレー＿Ry.svg";
import imageRelay_2 from "../images/control/リレー＿I＞.svg";
import imageRelay_3 from "../images/control/リレー＿任意の値.svg";
import imageTrans3_0_F from "../images/control/三相変圧器＿無し.svg";
import imageTrans3_0_T from "../images/control/三相変圧器＿無し＿負荷時電圧調整.svg";
import imageTrans3_1_F from "../images/control/三相変圧器＿Ｙ／△.svg";
import imageTrans3_1_T from "../images/control/三相変圧器＿Ｙ／△＿負荷時電圧調整.svg";
import imageTrans3_2_F from "../images/control/三相変圧器＿Ｙ／Y.svg";
import imageTrans3_2_T from "../images/control/三相変圧器＿Ｙ／Y＿負荷時電圧調整.svg";
import imageTrans3_3_F from "../images/control/三相変圧器＿△／Y.svg";
import imageTrans3_3_T from "../images/control/三相変圧器＿△／Y＿負荷時電圧調整.svg";
import imageTrans3_4_F from "../images/control/三相変圧器＿△／△.svg";
import imageTrans3_4_T from "../images/control/三相変圧器＿△／△＿負荷時電圧調整機能付き.svg";
import imageGenerator from "../images/control/発電機＿Equip_Generator.svg";
import imageHVCB_0 from "../images/control/過電流継電器＿高圧遮断器＋過電流継電器.svg";
import imageHVCB_0_1 from "../images/control/過電流継電器＿高圧遮断器＋過電流継電器_1.svg";
import imageHVCB_0_2 from "../images/control/過電流継電器＿高圧遮断器＋過電流継電器_2.svg";
import imageHVCB_1 from "../images/control/過電流継電器＿過電流継電器のみ.svg";
import imageHVCB_1_1 from "../images/control/過電流継電器＿過電流継電器のみ_1.svg";
import imageHVCB_1_2 from "../images/control/過電流継電器＿過電流継電器のみ_2.svg";
import imageMotor_1 from "../images/control/電動機.svg";
import imageSource_0 from "../images/control/電源＿電源.svg";
import imageSource_1 from "../images/control/電源＿無限大母線.svg";

import { doCalcSetValues, msSourceNormalCalculation, msTrans1GetInrushValue, msTrans1GetIntensityValue, msTrans1GetRatedCurrent, msTrans3GetDampingTime, msTrans3GetDropPerUnit, msTrans3GetDropVoltDiff, msTrans3GetInrushValue, msTrans3GetIntensityValue, msTrans3GetRatedCurrent, msTransNormalCalculation, msTransScottGetInrushValue, msTransScottGetIntensityValue, msTransScottGetRatedCurrent } from "../utils/PowerCalculation";
import { BeamsNumber } from "../utils/FormatNumber";
import { doGetTextPercentZe, doGetTextXr, doSetDefaultGenerator } from "../utils/generatorFunction";
import { DEFAULT_INTO_POINT_POSITION, FLOW_NODE_KIND_PQ, MAKE_YEAR_MAX, MAKE_YEAR_MIN, MS_COMPLEX_VOLTDROP, MS_XR_MAX, RS_3PHASES, RS_PHASE_N, RS_PHASE_PE, VOLT_SIDE_SECONDARY, VOLT_SIDE_SPECIAL_HIGH_MIN, VOLT_SIDE_THIRD, WIRE_SYMBOL_CABLE, WIRE_SYMBOL_NORMAL } from "../statics";
import { doGetValueVoltDrop, doSetDefault3Winding, doSetValuesWindingTab4 } from "../utils/3windingFunction";
import { convertTrans1ScottData } from "../utils/DataConverter";
import { BeamsValue, doSetDefaultPart } from "../utils/windingPartFunction";
import { doSetDefaultBusduct } from "../utils/busductFunction";
import { doSetDefaultBusbar } from "../utils/busbarFunction";
import { BeamsValueWirePhase, doGetTextCapcity, doGetTextOhmR, doGetTextOhmX, doSetDefaultWire } from "../utils/wireFunction";
import { doSetDefaultMotor } from "../utils/motorFunction";
import doGetSourceVolt, { doGetTransCenterSourceVolt, doGetValueCapacityTandG } from "../utils/mssv3Doc";
import { doSetStateCicuitImpedance } from "../utils/impedance";
import { percentZDefault } from "../utils/PercentZ";
import { doGetConnectElement } from "../utils/ElementList";
import { getTextWidth } from "../utils/TextUtil";

export const ElementImages = {
    image3Winding,image3Winding_2,image3Winding_3_5,image3Winding_4,image3Winding_6_8,image3Winding_7,
    imageTrans1_0, imageTrans1_1,
    imageTransscott,
    imageDS,imageDS2,imageDS3,
    imageLBS,imageLBS2,imageLBS3,imageLBS4,
    imageMVCB,imageMVCB2,imageMVCB3,
    imageContactor ,imageContactor2,
    imageFUSE_0, imageFUSE_1, imageFUSE_2, imageFUSE_3,
    image2E_0, image2E_1, 
    imageZerocond, 
    imageConnect, 
    imageWire, 
    imageBusbar, 
    imageBusduct, 
    imageReactor, 
    imageImpedance, image_r,image_x,image_r_x,image_x_r,
    imageLVCB_0, imageLVCB_1, imageLVCB_2, imageLVCB_3, 
    imageThermal_0, imageThermal_1, 
    imageThermalCT, 
    imageMotorGroup, 
    imageLoad, 
    imageLightBoard, 
    imagePowerBoard, 
    imageCapacitor, 
    imageTransCenter_1, 
    imageINV_1, imageINV_2, imageINV_3, 
    imageMsVT_1, 
    imageArrestor_1_0, imageArrestor_1_1, imageArrestor_2_0, imageArrestor_2_1, imageArrestor_3_0, imageArrestor_3_1, 
    imageEarth_1, 
    imageCableHead_1,
    imageCT_1, 
    imageZCT_1, 
    imageINV, 
    imageRelay_1, imageRelay_2, imageRelay_3,
    imageTrans3_0_F, imageTrans3_0_T,
    imageTrans3_1_F, imageTrans3_1_T,
    imageTrans3_2_F, imageTrans3_2_T,
    imageTrans3_3_F, imageTrans3_3_T,
    imageTrans3_4_F, imageTrans3_4_T,
    imageGenerator, 
    imageHVCB_0, imageHVCB_0_1, imageHVCB_0_2, imageHVCB_1, imageHVCB_1_1, imageHVCB_1_2,
    imageMotor_1, 
    imageSource_0, imageSource_1
}

export const createElementFromResponseData = (kind: ElementKind, id: number, currentPosition: Coordinates, responseData: any, tabId: number = 0,shapes:any,currentTabId:any,infoCircuit:Model.InfoCircuit,rotation:number,transCenterData:any|undefined=undefined) => {
    switch (kind) {
        case "MS_HVCB":
            return createHVCB(id, currentPosition, responseData,shapes,currentTabId,rotation);
        case "MS_SOURCE":
            return createSource(id, currentPosition, responseData);
        case "MS_MOTOR":
            return createMotor(id, currentPosition, responseData,shapes,currentTabId);
        case "MS_GENERATOR":
            return createMsGenerator(id, currentPosition, responseData,infoCircuit.frequency,infoCircuit.stdCapacity);
        case "MS_TRANS3":
            return createMsTrans3(id, currentPosition, responseData,shapes,currentTabId,infoCircuit.frequency);
        case "MS_3WINDING":
            return createMs3Winding(id, currentPosition, responseData,shapes,currentTabId,infoCircuit.frequency,infoCircuit.stdCapacity);
        case "MS_TRANS1":
            return createMsTrans1(id, currentPosition, responseData,shapes,currentTabId,infoCircuit.frequency);
        case "MS_TRANSSCOTT":
            return createMsTransscott(id, currentPosition, responseData,shapes,currentTabId,infoCircuit.frequency);
        case "MS_DS":
            return createMsDS(id, currentPosition, responseData,shapes,currentTabId,rotation);
        case "MS_LBS":
            return createMsLBS(id, currentPosition, responseData,shapes,currentTabId,rotation);
        case "MS_MVCB":
            return createMsMVCB(id, currentPosition, responseData,shapes,currentTabId,rotation);
        case "MS_CONTACTOR":
            return createMsContactor(id, currentPosition, responseData,shapes,currentTabId,rotation);
        case "MS_FUSE":
            return createMsFUSE(id, currentPosition, responseData,shapes,currentTabId,rotation);
        case "MS_2E":
            return createMs2E(id, currentPosition, responseData,shapes,currentTabId);
        case "MS_ZEROCOND":
            return createMsZerocond(id, currentPosition, responseData,shapes,currentTabId);
        case "MS_CONNECT":
            return createMsConnect(id, currentPosition, responseData);
        case "MS_WIRE":
            return createMsWire(id, currentPosition, responseData,shapes,currentTabId,infoCircuit.frequency,rotation);
        case "MS_BUSBAR":
            return createMsBusbar(id, currentPosition, responseData,shapes,currentTabId,rotation,infoCircuit.frequency);
        case "MS_BUSDUCT":
            return createMsBusduct(id, currentPosition, responseData,shapes,currentTabId,infoCircuit.frequency,rotation);
        case "MS_REACTOR":
            return createMsReactor(id, currentPosition, responseData,shapes,currentTabId,rotation);
        case "MS_IMPEDANCE":
            return createMsImpedance(id, currentPosition, responseData,shapes,currentTabId,rotation);
        case "MS_LVCB":
            return createMsLVCB(id, currentPosition, responseData,shapes,currentTabId,rotation);
        case "MS_THERMAL":
            return createMsThermal(id, currentPosition, responseData,shapes,currentTabId);
        case "MS_THERMAL_CT":
            return createMsThermalCT(id, currentPosition, responseData,shapes,currentTabId);
        case "MS_MOTOR_GROUP":
            return createMsMotorGroup(id, currentPosition, responseData,shapes);
        case "MS_LOAD":
            return createMsLoad(id, currentPosition, responseData,shapes,currentTabId);
        case "MS_LIGHTBOARD":
            return createMsLightBoard(id, currentPosition, responseData,shapes,currentTabId);
        case "MS_POWERBOARD":
            return createMsPowerBoard(id, currentPosition, responseData,shapes,currentTabId);
        case "MS_CAPACITOR":
            return createMsCapacitor(id, currentPosition, responseData,shapes,currentTabId);
        case "MS_TRANSCENTER":
            return createTransCenter(tabId, id, currentPosition, responseData,shapes);
        case Element.MS_INTO_POINT:
            return createIntoPoint(id,transCenterData);
        case "MS_VCT":
            return createMsVCT(id, currentPosition, responseData,shapes,currentTabId);
        case "MS_VT":
            return createImageMsVT(id, currentPosition, responseData,shapes,currentTabId);
        case "MS_ARRESTOR":
            return createMsArrestor(id, currentPosition, responseData,shapes,currentTabId);
        case "MS_EARTH":
            return createMsEarth(id, currentPosition, responseData,shapes,currentTabId);
        case "MS_CABLEHEAD":
            return createMsCableHead(id, currentPosition, responseData,shapes,currentTabId);
        case "MS_CT":
            return createMsCT(id, currentPosition, responseData,shapes,currentTabId);
        case "MS_ZCT":
            return createMsZCT(id, currentPosition, responseData,shapes,currentTabId);
        case "MS_INV":
            return createMsINV(id, currentPosition, responseData,shapes,currentTabId);
        case "MS_RELAY":
            return createMsRelay(id, currentPosition, responseData,shapes,currentTabId);
        default:
            throw new Error(`create element error : ${kind}`);
    }
}

// -------------------------------
// convert response to model data
// -------------------------------
const response2SourceModel = (responseData: any) => {
    let defaultPower  = msSourceNormalCalculation(responseData.voltage, 1000, 25)

    let lenParts = responseData.details.parts?.length
    if (lenParts > 0) {
      for (let i = 0; i < lenParts; i++){
        responseData.details.parts[i] = {
            ...responseData.details.parts[i],
            setLock: false,
            partID: i + 1
        }
      }
    }

    const  calcPoint0 ={
        loadCurrent: 0,
        isLead: false,
        powerFactor: 1,
        calcKind: Constant.CALC_IK3,
        stdCapacity:0,
        loadCapacity: 0,
        voltage:responseData.voltage,
        perZDirect: percentZDefault() as Model.PercentZ,
        perZTotal: percentZDefault() as Model.PercentZ,
        perZDirectMG: percentZDefault() as Model.PercentZ,
        perZTotalMG: percentZDefault() as Model.PercentZ,
        perZMotor: percentZDefault() as Model.PercentZ,
    } as Model.CalcPoint

    const  calcPoint1 ={
        loadCurrent: 0,
        isLead: false,
        powerFactor: 1,
        calcKind: Constant.CALC_IK2,
        stdCapacity:0,
        loadCapacity: 0,
        voltage:responseData.voltage,
        perZDirect: percentZDefault() as Model.PercentZ,
        perZTotal: percentZDefault() as Model.PercentZ,
        perZDirectMG: percentZDefault() as Model.PercentZ,
        perZTotalMG: percentZDefault() as Model.PercentZ,
        perZMotor: percentZDefault() as Model.PercentZ,
    } as Model.CalcPoint
     
    return {
        // 基本
        refNo: "電源-" + responseData.data.no,
        noSource: +responseData.data.no,
        pointText: "F" + responseData.data.point,
        voltageList: [],
        voltage: responseData.voltage,
        capacityList: [
            {label: '50', value: 50},
            {label: '100', value: 100},
            {label: '160', value: 160},
            {label: '500', value: 500},
            {label: '1000', value: 1000},
        ],
        capacity: 1000,
        fault: false,
        calcImprovePF: true,
        viewResultText: true,
        xr: 25,
        percentZ: BeamsNumber(defaultPower.percentZ, 6),
        percentR: 0, //BeamsNumber(defaultPower.percentR, 4),
        percentX: 0, //BeamsNumber(defaultPower.percentX, 4),
        ohmZ: BeamsNumber(defaultPower.ohmZ, 4),
        ohmR: BeamsNumber(defaultPower.ohmR, 4),
        ohmX: BeamsNumber(defaultPower.ohmX, 4),
        // 保護協調
        details: {
            base: {
                ...responseData.details.base, 
                ...{
                    kindFlag: Constant.RS_KIND_EPC_OCR,
                    setBreakingTime: false,
                    setBreakHz: false,
                    breakTimeHz: 3,

                    useCT: true,
                    useCC: false,
                    ctPrimaryList: [],
                    ctPrimary: 600,
                    ctSecondaryList: [
                        {label: '1', value: 1},
                        {label: '5', value: 5},
                    ],
                    ctSecondary: 5,
                    readBase: {
                        canUseCC: responseData.details.base.canUseCC,
                        canUseCT: responseData.details.base.canUseCT
                    }
                }
            },
            parts: responseData.details.parts,
            refNo2: "",
            drawThreeCurve: true
        },
        // 力率改善
        contract: 1000,
        present: 0.85,
        target: 0.98,
        demand: 0,
        // 潮流解析 -- ModePM
        nodeKind: 1,
        viewFlowResult: true,
        leadLag: 0,
        powerFactor: 0.85,
        appointVoltage: 1,
        flowQMin: 1000 * 0.2, // capacity * 0.2
        flowQMax: 1000 * 0.6, // capacity * 0.6
        // シンボル
        symKind: 0,
        listUser: [],
        
        // others
        checkNG: false,
        nodeNumber: 0,
        perUnitR: 0,
        perUnitX: 0,
        perUnitK: 0,
        perUnitP: 0,
        perUnitQ: 0,
        perUnitV: 1,
        voltageMagnitude: 1,
        voltageAngle: 0,
        activePower: 0,
        reactivePower: 0,
        examineComment: '',
        harmonicCurrent0: 0,
        harmonicCurrent1: 0,
        harmonicCurrent2: 0,
        harmonicCurrent3: 0,
        harmonicCurrent4: 0,
        harmonicCurrent5: 0,
        harmonicCurrent6: 0,
        harmonicCurrent7: 0,
        harmonicCurrent8: 0,
        harmonicCurrent9: 0,
        harmonicCurrent10: 0,
        harmonicCurrent11: 0,
        harmonicCurrent12: 0,
        harmonicCurrent13: 0,
        harmonicCurrent14: 0,
        harmonicCurrent15: 0,
        seriesed: false,
        loadCapacity: 0,

        calcPoint0:calcPoint0,
        calcPoint1:calcPoint1,
    } as Model.MsSourceModel;
}

const response2HVCBModel = (responseData: any,voltage:number,rotation:number) => {
    let lenParts = responseData.details.parts?.length
    if (lenParts > 0) {
      for (let i = 0; i < lenParts; i++){
        responseData.details.parts[i] = {
            ...responseData.details.parts[i],
            setLock: false,
            partID: i + 1
        }
      }
    }
    const  calcPoint0 ={
        loadCurrent: 0,
        isLead: false,
        powerFactor: 1,
        calcKind: Constant.CALC_IK3,
        stdCapacity:0,
        loadCapacity: 0,
        voltage:responseData.voltage,
        perZDirect: percentZDefault() as Model.PercentZ,
        perZTotal: percentZDefault() as Model.PercentZ,
        perZDirectMG: percentZDefault() as Model.PercentZ,
        perZTotalMG: percentZDefault() as Model.PercentZ,
        perZMotor: percentZDefault() as Model.PercentZ,
    } as Model.CalcPoint

    const  calcPoint1 ={
        loadCurrent: 0,
        isLead: false,
        powerFactor: 1,
        calcKind: Constant.CALC_IK2,
        stdCapacity:0,
        loadCapacity: 0,
        voltage:responseData.voltage,
        perZDirect: percentZDefault() as Model.PercentZ,
        perZTotal: percentZDefault() as Model.PercentZ,
        perZDirectMG: percentZDefault() as Model.PercentZ,
        perZTotalMG: percentZDefault() as Model.PercentZ,
        perZMotor: percentZDefault() as Model.PercentZ,
    } as Model.CalcPoint
    return {
        // 基本
        refNo: "52-" + responseData.data.no,
        noHVCB: responseData.data.no,
        pointText: "F" + responseData.data.point,
        voltageList: [],
        voltage: voltage,
        breakCapacityList: [
            {label: '8', value: 8},
            {label: '12.5', value: 12.5},
            {label: '16', value: 16},
            {label: '20', value: 20},
            {label: '25', value: 25},
            {label: '31.5', value: 31.5},
            {label: '40', value: 40},
        ],
        breakCapacity: 12.5,
        isOff: false,
        fault: true,
        dropMotor: false,
        dropRegular: false,
        viewResultText: true,
        
        // ModePM
        viewFlowResult: false,
        connectSwitch: false,

        makeDataInput: false,

        makerNameList: [],
        makerName: '',
        breakType: '',
        makeYearList: [],
        makeYear: null,
        makeMonthList: [],
        makeMonth: null,
        makeNumber: '',

        makeYearOCR: null,
        makeMonthOCR: null,
        makeNumberOCR: '',

        // 保護協調
        details: {
            base: {
                ...responseData.details.base, 
                ...{
                    kindFlag: Constant.RS_KIND_HVCB,
                    setBreakingTime: false,
                    setBreakHz: false,
                    breakTimeHz: 3,
                    useCT: true,
                    useCC: false,

                    ctPrimaryList: [],
                    ctPrimary: responseData.ctPrimary,
                    ctSecondaryList: [
                        {label: '1', value: 1},
                        {label: '5', value: 5},
                    ],
                    ctSecondary: 5,
                    readBase: {
                        canUseCC: responseData.details.base.canUseCC,
                        canUseCT: responseData.details.base.canUseCT
                    }
                }
            },
            
            parts: responseData.details.parts,
            refNo2: "51-" + responseData.data.no,
            drawThreeCurve: true
        },
        // シンボル
        symKind: 0,
        listUser: [],
        // others
        checkNG: false,
        supply: false,
        tcName: '',
        lineActiveFlow1: 0,
        lineReactiveFlow1: 0,
        lineActiveFlow2: 0,
        lineReactiveFlow2: 0,
        eleTopLeftNumber: 0,
        eleBottomRightNumber: 0,
        downTransKind: Element.MS_ELEMENT_ALL,
        primarySupply: false,
        examineComment: '',
        seriesed: false,
        voltDropSumRe: MS_COMPLEX_VOLTDROP,
        voltDropSumIm: MS_COMPLEX_VOLTDROP,
        voltDropRouteRefNo: '',
        rotate: rotation === -90?1:rotation === 90?2:rotation, // TODO

        calcPoint0:calcPoint0,
        calcPoint1:calcPoint1
    } as Model.MsHVCBModel;
}

const response2MotorModel = (responseData: any,voltage:number,upTransKind:number,sPhase:boolean) => {
    const motor = {} as Model.MsMotorModel
    motor.viewResultText = true;
    motor.viewFlowResult = false;
    motor.calcDrop = false;
    motor.viewFlowResult = false;
    motor.inputAutomaticManual = 0;
    motor.baseCurrent = 0;
    motor.baseFactor = 0;
    motor.manualLoadCurrent = false;
    motor.manualStartingCurrent = false;
    motor.manualInrushCurrent = false;
    doSetDefaultMotor(motor,responseData.voltLowDefaultItem,responseData.capacityMotorHPDefaultItem,responseData.capacityMotorList,sPhase,voltage);
    const capacityMotorDefaultItem = motor.uIntHP == false ? responseData.capacityMotorList?.find((item:any) => item.bisDefault == true) : responseData.capacityMotorHPDefaultItem;
    if(motor.powerFactor < -1 || motor.powerFactor > 1)
        motor.phaseCurrent = 0
    else
        motor.phaseCurrent = (Math.acos(motor.powerFactor) * 180 / Math.PI) * -1
    return {
        // // 基本
        refNo: "M-" + responseData.data.no,
        noMotor: responseData.data.no,
        sPhase: sPhase,
        voltageID: voltage,
        capacity: motor.capacity,
        powerFactor: motor.powerFactor,
        isLead: motor.isLead,
        startingTimes:motor.startingTimes,
        starting600:BeamsNumber(motor.starting600,5),
        inrushTime:BeamsNumber(motor.inrushTimes,5),
        checkNG:motor.checkNG,
        calcDrop: motor.calcDrop,
        startingFactor: motor.startingFactor,
        efficiency: motor.efficiency,
        useBaseLoad : motor.useBaseLoad,
        baseCapacity: motor.baseCapacity,
        leadBaseLoad: motor.leadBaseLoad,
        baseFactor: motor.baseFactor,
        afterPF: motor.afterPF,
        nodeNumber: motor.nodeNumber,
        perUnitR: motor.perUnitR,
        perUnitX: motor.perUnitX,
        perUnitK: motor.perUnitK,
        perUnitP: motor.perUnitP,
        perUnitQ: motor.perUnitQ,
        loadFactor: motor.loadFactor,
        voltageMagnitude: motor.voltageMagnitude,
        voltageAngle: motor.voltageAngle,
        activePower: motor.activePower,
        reactivePower: motor.reactivePower,
        upTransKind:upTransKind,
        useEfficiency: motor.useEfficiency,
        dispBaseLoad: motor.dispBaseLoad,
        manualLoadCurrent:motor.manualLoadCurrent,
        loadCurrent:BeamsNumber(motor.fullCurrent,4),
        manualStartingCurrent:motor.manualStartingCurrent,
        startingCurrent: capacityMotorDefaultItem !== undefined?BeamsNumber(capacityMotorDefaultItem.dstartingCurrent * motor.fullCurrent,5):0,
        manualInrushCurrent:motor.manualInrushCurrent,
        inrushCurrent:capacityMotorDefaultItem !== undefined?BeamsNumber(capacityMotorDefaultItem.dinrushCurrent * motor.fullCurrent,5):0,
        pageIndex :motor.pageIndex,
        supply :motor.supply,
        tcName :motor.tcName,
        baseLoadInputManual :motor.baseLoadInputManual,
        baseCapacityM :motor.baseCapacityM,
        leadBaseLoadM :motor.leadBaseLoadM,
        baseFactorM :motor.baseFactorM,
        baseLoadCalcRightLeft :motor.baseLoadCalcRightLeft,
        makeDataInput: false,
        makerName: "",
        makeYear: "",
        makeMonth: "",
        makeNumber: "",
        type: "",
        uIntHP: motor.uIntHP,
        capacityID: motor.capacityID,
        capacityHpID: motor.capacityHpID,
        viewFlowResult: motor.viewFlowResult,
        primarySupply :motor.primarySupply,
        viewResultText: motor.viewResultText,
        inrush20Or40:motor.inrush20Or40??false,
        startingTime:motor.startingTime,
        calcImprovePF: motor.calcImprovePF,
        inrushTimes:motor.inrushTimes,
 
        fullCurrent: BeamsNumber(motor.fullCurrent,4),
        phaseCurrent: BeamsNumber(motor.phaseCurrent,4),
        inputAutomaticManual: motor.inputAutomaticManual,
        baseCurrent: BeamsNumber(motor.baseCurrent,4),
        calcRightLeft: motor.calcRightLeft,
        modePM:true,
        // // Tab2
        // // Tab 3
        harmonicList: [],
        listUser: [],
        isSourceVoltage:false
    } as any;
}
const response2GeneratorModel = (responseData: any,frequency:number,stdCapacity:number) => {
    
    const generatorItemDefaultItem = responseData.generatorList.find((item:any) => item.bisDefault == true);
    const generatorItem = {} as Model.MsGeneratorModel
    doSetDefaultGenerator(0,generatorItem,responseData.voltLowDefaultItem,responseData.voltHighDefaultItem,responseData.generatorList)
    const voltage = generatorItem.voltage
    const capacity = generatorItem.capacity
    const ratedCurrent = voltage <= 0 ? 0 : capacity * 1000 / voltage / Math.sqrt(3.0);
    const percentZe  = doGetTextPercentZe(frequency,stdCapacity,voltage,capacity,generatorItemDefaultItem);
    const textXr = doGetTextXr(frequency,stdCapacity,generatorItemDefaultItem);


    const  calcPoint0 ={
        loadCurrent: 0,
        isLead: false,
        powerFactor: 1,
        calcKind: Constant.CALC_IK3,
        stdCapacity:0,
        loadCapacity: 0,
        voltage:voltage,
        perZDirect: percentZDefault() as Model.PercentZ,
        perZTotal: percentZDefault() as Model.PercentZ,
        perZDirectMG: percentZDefault() as Model.PercentZ,
        perZTotalMG: percentZDefault() as Model.PercentZ,
        perZMotor: percentZDefault() as Model.PercentZ,
    } as Model.CalcPoint

    const  calcPoint1 ={
        loadCurrent: 0,
        isLead: false,
        powerFactor: 1,
        calcKind: Constant.CALC_IK2,
        stdCapacity:0,
        loadCapacity: 0,
        voltage:voltage,
        perZDirect: percentZDefault() as Model.PercentZ,
        perZTotal: percentZDefault() as Model.PercentZ,
        perZDirectMG: percentZDefault() as Model.PercentZ,
        perZTotalMG: percentZDefault() as Model.PercentZ,
        perZMotor: percentZDefault() as Model.PercentZ,
    } as Model.CalcPoint
    return {
        refNo: "G-" + responseData.data.no,
        noGenerator: responseData.data.no,
        voltage: voltage,
        capacity:capacity,
        reactance: generatorItemDefaultItem === undefined?0:generatorItemDefaultItem.dreactance,
        subReactance: generatorItemDefaultItem === undefined?0:generatorItemDefaultItem.dsubReactance,
        constant: generatorItemDefaultItem === undefined?0:generatorItemDefaultItem.dconstant,
        subConstant: generatorItemDefaultItem === undefined?0:generatorItemDefaultItem.dsubConstant,
        fault: responseData.fault,
        checkNG: generatorItem.checkNG,
        powerFactor:generatorItem.powerFactor,
        nodeNumber:generatorItem.nodeNumber,
        perUnitR:generatorItem.perUnitR,
        perUnitX:generatorItem.perUnitX,
        perUnitK:generatorItem.perUnitK,
        perUnitP:generatorItem.perUnitP,
        perUnitQ:generatorItem.perUnitQ,
        perUnitV:generatorItem.perUnitV,
        nodeKind:generatorItem.nodeKind,
        isLead:generatorItem.isLead,
        voltageMagnitude:generatorItem.voltageMagnitude,
        voltageAngle:generatorItem.voltageAngle,
        activePower:generatorItem.activePower,
        reactivePower:generatorItem.reactivePower,
        Qmax:capacity * 0.6,
        Qmin:capacity * 0.2,
        makeDataInput: false,
        makerName: "",
        makeYear: "",
        makeMonth: "",
        makeNumber: "",
        type: "",
        pointText: "F" + responseData.data.point,
        viewFlowResult:generatorItem.viewFlowResult,
        examineComment:generatorItem.examineComment,
        seriesed:generatorItem.seriesed,
        viewResultText: generatorItem.viewResultText,
        appointVoltage:generatorItem.appointVoltage,

        makerNameList: [],
        makeYearList: [],
        makeMonthList: [],
        modePM:true,
        ratedCurrent: BeamsNumber(Number(ratedCurrent),4),
        percentZe:percentZe,
        Xr:textXr,

        listUser: [],
        voltageList: [],	    //	回路電流(V)
        capacityList:[],            //  定格出力(kW)＿リスト
        statorResistance: 0,
        subCurrentIkd: 0,
        currentIkd: 0,
        iacNoLoad: 0,
        iacLoad: 0,
        peakValue: 0,
        
        generatorList:[],
        calcPoint0:calcPoint0,
        calcPoint1:calcPoint1

    // ModePM
    } as Model.MsGeneratorModel;
}

const response2Trans3Model = (responseData: any,voltage:number,voltage2:number,frequency:number) => {
    // tab0
    let voltagePrimary = voltage
    let voltageSecondary = voltage2
        

    let ratedCurrentParmary = msTrans3GetRatedCurrent(
        responseData.capacity,
        voltagePrimary
    )
    let ratedCurrentSecondary = msTrans3GetRatedCurrent(
        responseData.capacity,
        voltageSecondary
    )
    let voltageAdjust = voltageSecondary

    let dampingTime = msTrans3GetDampingTime(responseData.dampingTime, frequency)

    let xr = responseData.percentR === 0?0:responseData.percentX === 0?MS_XR_MAX:responseData.percentX / responseData.percentR
    let calcData = {
        voltageSecondary: voltageSecondary,
        capacity: responseData.capacity,
        percentR: responseData.percentR,
        percentX: responseData.percentX,
    }
    let defaultPower = msTransNormalCalculation(calcData)
    
    // tab1
    let inrushValue = msTrans3GetInrushValue(
        responseData.inrushCurrent,
        responseData.capacity,
        voltagePrimary
    )
    
    let intensityValue = msTrans3GetIntensityValue(
        25,
        responseData.capacity,
        voltageSecondary
    )
    
    let calcData2 = {
        capacity: responseData.capacity,
        voltageSecondary: voltageSecondary,
        voltageAdjust: voltageAdjust,
        percentR: responseData.percentR,
        percentX: responseData.percentX,
        powerFactor: 1,
        loadCurrent: 0,
        leadLag: false,
    }
    let voltageDrop1 = msTrans3GetDropVoltDiff(calcData2)
    let voltageDrop = msTrans3GetDropPerUnit(calcData2)
    let calcPoint0 =
    {
        eleTrans1PartKind: 0,
        ele3WindingPartKind: 0,
        eleTranscottPartKind: 0,
        calcPointId: 1,
        calcKind:Constant.CALC_IK3,
        loadCapacity: 0,
        loadCurrent: 0,
        isLead: false, // 回路の力率
        powerFactor: 1,
        stdCapacity:0,
        voltage:voltageSecondary,
        perZDirect: percentZDefault() as Model.PercentZ,
        perZTotal: percentZDefault() as Model.PercentZ,
        perZDirectMG: percentZDefault() as Model.PercentZ,
        perZTotalMG: percentZDefault() as Model.PercentZ,
        perZMotor: percentZDefault() as Model.PercentZ,
    } as Model.CalcPoint
    let calcPoint1 ={
        eleTrans1PartKind: 0,
        ele3WindingPartKind: 0,
        eleTranscottPartKind: 0,
        calcPointId: 2,
        calcKind:Constant.CALC_IK2,
        loadCapacity: 0,
        loadCurrent: 0,
        isLead: false,
        powerFactor: 1,
        stdCapacity:0,
        voltage:voltageSecondary,
        perZDirect: percentZDefault() as Model.PercentZ,
        perZTotal: percentZDefault() as Model.PercentZ,
        perZDirectMG: percentZDefault() as Model.PercentZ,
        perZTotalMG: percentZDefault() as Model.PercentZ,
        perZMotor: percentZDefault() as Model.PercentZ,
    } as Model.CalcPoint
    
    return {
        // tab0 - 基本
        noTrans3: responseData.data.no,
        refNo: "Tr3Ф-" + responseData.data.no,
        pointText: "F" + responseData.data.point,
        voltagePrimaryList: [],
        voltagePrimary: voltagePrimary,
        voltageSecondaryList: [],
        voltageSecondary: voltageSecondary,
        capacityList: [],
        capacity: responseData.capacity,
        ratedCurrentParmary: BeamsNumber(ratedCurrentParmary, 4),
        ratedCurrentSecondary: BeamsNumber(ratedCurrentSecondary, 4),

        fault: false,
        viewResultText: true,
        voltageAdjust: voltageAdjust,
        // voltageTabPart: 0,

        xr: BeamsNumber(xr, 5),
        percentZ: BeamsNumber(defaultPower.percentZ, 5),
        percentR: BeamsNumber(responseData.percentR, 5),
        percentX: BeamsNumber(responseData.percentX, 5),
        ohmZ: BeamsNumber(defaultPower.ohmZ, 4),
        ohmR: BeamsNumber(defaultPower.ohmR, 4),
        ohmX: BeamsNumber(defaultPower.ohmX, 4),

        // ModePM
        viewFlowResult: false,
        makeDataInput: false,
        makerNameList: [],
        makerName: '',
        type: '',
        makeYearList: [],
        makeYear: null,
        makeMonthList: [],
        makeMonth: null,
        makeNumber: '',

        // tab1 - 保護協調
        dispInrush: true,
        inrushTimes: responseData.inrushCurrent,
        inrushValue: BeamsNumber(inrushValue, 4),
        dampingTime: BeamsNumber(dampingTime, 5),

        dispIntensity: false,
        intensityTimes: 25,
        intensityValue: BeamsNumber(intensityValue, 4),
        intensityTime: 2,

        // tab2 - 電圧降下
        dropMotor: false,
        dropRegular: true,

        calcPoint0:calcPoint0,
        calcPoint1: calcPoint1,
                 
        currentTopLeft: 1, // 負荷電流方向
        voltageDrop1: voltageDrop1,
        voltageDrop: voltageDrop,

        // tab3 - シンボル
        symKind: 1,
        haveTab: false,
        listUser: [],
        
        // others
        checkNG: false,
        selInrush: false,
        selIntensity: false,
        eleLineNumber: 0,
        eleTopLeftNumber: 0,
        eleBottomRightNumber: 0,
        perUnitR: 0,
        perUnitX: 0,
        perUnitK: 1,
        lineActiveFlow1: 0,
        lineReactiveFlow1: 0,
        lineActiveFlow2: 0,
        lineReactiveFlow2: 0,
        pageIndex: 0,
        supply: false,
        tcName: '',
        haveGround: false,
        neutralKind: 2, // check in VM
        inrushTimesKind: 0,
        inrushManualValue: 10,
        inrushTime: 0.1,
        voltageMagnitude1: 0,
        voltageAngle1: 0,
        voltageMagnitude2: 1,
        voltageAngle2: 0,
        inputedCapacity1: false, // check in VM
        inputedCurrent1: false, // check in VM
        inputedPowerFactor1: false, // check in VM
        dispAnsiKind: 0,
        ansiTimes: 16.6,
        ansiTime: 4,
        primarySupply: false,
        examineComment: '',
        seriesed: false,
        voltDropSumRe: MS_COMPLEX_VOLTDROP,
        voltDropSumIm: MS_COMPLEX_VOLTDROP,
        voltDropRouteRefNo: '',
        voltAdjustPart: 20, // check in VM
        trans3List:responseData.trans3List,
        isSourceVoltage: false,
    } as Model.MsTrans3Model;
}

const response23WindingModel = (responseData: any,voltage1:number,voltage2:number,voltage3:number,frequency:number,stdCapacity:number) => {
    const partPrimary = {
        voltage:voltage1,
        capacity:0,
        perRk3:0,
        perXk3:0,
        perZk3:0,
        dampingTime:0,
        intensityTimes:0,
        intensityTime:0,
        dispInrush:false,
        dispIntensity:false,
        fault:false,
        dropRegular:false,
        selInrush:false,
        selIntensity:false,
        pageIndex:0,
        pointText:"",
        eleLineNumber:0,
        eleNodeNumber:0,
        perUnitR:0,
        perUnitK:0,
        perUnitX:0,
        lineActiveFlow1:0,
        lineReactiveFlow1:0,
        lineActiveFlow2:0,
        lineReactiveFlow2:0,
        voltageMagnitude1:1,
        voltageAngle1:0,
        voltageMagnitude2:1,
        voltageAngle2:0,
        inputedCapacity1:false,
        inputedPowerFactor1:false,
        neutralKind:0,
        dispAnsiKind:0,
        ansiTime:4,
        ansiTimes:16.6,
        inrushTimes:0,
        inrushManualValue:10,
        inrushTime:0.1,
        inrushTimesKind:0,
        examineComment:"",
        inputedCurrent1:false,
        calcPoint0:{},
        calcPoint1:{},
        infoLineDispBand:false,
        infoLineColor:'#000000',
        infoLineKind:0,
        infoLineWidth:30,
        voltDropSumIm: 0,
        voltDropSumRe: 0,
        voltDropRouteRefNo: ''
    } as Model.Iele3WindingPart;
    const partSecondary = {
        voltage:voltage2,
        capacity:0,
        perRk3:0,
        perXk3:0,
        perZk3:0,
        dampingTime:0,
        intensityTimes:0,
        intensityTime:0,
        dispInrush:false,
        dispIntensity:false,
        fault:false,
        dropRegular:false,
        selInrush:false,
        selIntensity:false,
        pageIndex:0,
        pointText:"F"+responseData.data.point,
        eleLineNumber:0,
        eleNodeNumber:0,
        perUnitR:0,
        perUnitK:0,
        perUnitX:0,
        lineActiveFlow1:0,
        lineReactiveFlow1:0,
        lineActiveFlow2:0,
        lineReactiveFlow2:0,
        voltageMagnitude1:1,
        voltageAngle1:0,
        voltageMagnitude2:1,
        voltageAngle2:0,
        inputedCapacity1:false,
        inputedPowerFactor1:false,
        neutralKind:0,
        dispAnsiKind:0,
        ansiTime:4,
        ansiTimes:16.6,
        inrushTimes:0,
        inrushManualValue:10,
        inrushTimesKind:0,
        inrushTime:0.1,
        examineComment:"",
        inputedCurrent1:false,
        calcPoint0:{},
        calcPoint1:{},
        infoLineDispBand:false,
        infoLineColor:'#000000',
        infoLineKind:0,
        infoLineWidth:30,
        voltDropSumIm: 0,
        voltDropSumRe: 0,
        voltDropRouteRefNo: ''
    } as Model.Iele3WindingPart;
    const partThird = {
        voltage:voltage3,
        capacity:0,
        perRk3:0,perXk3:0,
        perZk3:0,
        dampingTime:0,
        intensityTimes:0,
        intensityTime:0,
        dispInrush:false,
        dispIntensity:false,
        fault:false,
        dropRegular:false,
        selInrush:false,
        selIntensity:false,
        pageIndex:0,
        pointText:"F"+responseData.data.point,
        eleLineNumber:0,
        eleNodeNumber:0,
        perUnitR:0,
        perUnitK:0,
        perUnitX:0,
        lineActiveFlow1:0,
        lineReactiveFlow1:0,
        lineActiveFlow2:0,
        lineReactiveFlow2:0,
        voltageMagnitude1:1,
        voltageAngle1:0,
        voltageMagnitude2:1,
        voltageAngle2:0,
        inputedCapacity1:false,
        inputedPowerFactor1:false,
        neutralKind:0,
        dispAnsiKind:0,
        ansiTime:4,
        ansiTimes:16.6,
        inrushTimes:0,
        inrushManualValue:10,
        inrushTimesKind:0,
        inrushTime:0.1,
        examineComment:"",
        inputedCurrent1:false,
        calcPoint0:{},
        calcPoint1:{},
        infoLineDispBand:false,
        infoLineColor:'#000000',
        infoLineKind:0,
        infoLineWidth:30,
        voltDropSumIm: 0,
        voltDropSumRe: 0,
        voltDropRouteRefNo: ''
    } as Model.Iele3WindingPart;
    const trans3List = responseData.trans3List;
    const trans3DefaultItem = trans3List.find((item:any) => item.bisDefault === true);
    const _3windingItem = {partPrimary:partPrimary,partSecondary:partSecondary,partThird:partThird} as Model.Ms3WindingModel;
    doSetDefault3Winding(responseData.data.no,partPrimary.voltage,partSecondary.voltage,partThird.voltage,frequency,false,0,responseData.voltLowDefault,responseData.voltHighDefault,partPrimary,partSecondary,partThird,_3windingItem);

    doSetDefaultPart(1,partPrimary,frequency,trans3DefaultItem,trans3List,"");
    doSetDefaultPart(2,partSecondary,frequency,trans3DefaultItem,trans3List,"");
    doSetDefaultPart(3,partThird,frequency,trans3DefaultItem,trans3List,"");
    partPrimary.dispIntensity = false;
    partPrimary.dispInrush = false; 
    partPrimary.fault = false;
    BeamsValue(partPrimary);
    BeamsValue(partSecondary);
    BeamsValue(partThird);

    const Ms3Winding = {partPrimary:structuredClone(partPrimary),partSecondary:structuredClone(partSecondary),partThird:structuredClone(partThird)} as Model.Ms3WindingModel
    const valueTab6 =  doGetValueVoltDrop(stdCapacity,"MODE_PM",VOLT_SIDE_SECONDARY,Ms3Winding);
    const valueTab7 =  doGetValueVoltDrop(stdCapacity,"MODE_PM",VOLT_SIDE_THIRD,Ms3Winding);
    // ModePM
    let makerNameList: OptionModel[] = [];
    if (responseData.makerNameList){
        responseData.makerNameList.forEach((element: any) => {
            let optionModel: OptionModel = {
                label: element.smanuName,
                value: element.smanuName
            };
            makerNameList.push(optionModel)
        });
    }

    let makeYearList: OptionModel[] = [];
    for (let i = MAKE_YEAR_MAX; i >= MAKE_YEAR_MIN; i--){
        let optionModel: OptionModel = {
            label: i.toString(),
            value: i
        };
        makeYearList.push(optionModel)
    }
    let makeMonthList: OptionModel[] = [];
    for (let i = 1; i <= 12; i++){
        let optionModel: OptionModel = {
            label: i.toString(),
            value: i
        };
        makeMonthList.push(optionModel)
    }
    
    return {
        
        // tab0
        partPrimary:partPrimary,
        partSecondary:partSecondary,
        partThird:partThird,
        refNo: "三巻Tr-" + responseData.data.no,
        no3Winding: responseData.data.no,
        // mode PM
        makeDataInput: false,
        makerNameList: makerNameList,
        makerName: '',
        type: '',
        makeYearList: makeYearList,
        makeYear: "",
        makeMonthList: makeMonthList,
        makeMonth: "",
        makeNumber: '',        
        // tab5
        dropRegular:partSecondary.dropRegular,
        loadCapacity:partSecondary.calcPoint0.loadCapacity,
        loadCurrent:partSecondary.calcPoint0.loadCurrent,
        isLead:partSecondary.calcPoint0.isLead,
        powerFactor: partSecondary.calcPoint0.powerFactor,
        voltDropDiff: valueTab6.voltDropDiff,
        voltDropPerUnit: valueTab6.voltDropPerUnit,

        // tab6
        dropRegularThird:partThird.dropRegular,
        loadCapacityThird:partThird.calcPoint0.loadCapacity,
        loadCurrentThird:partThird.calcPoint0.loadCurrent,
        isLeadThird:partThird.calcPoint0.isLead,
        powerFactorThird: partThird.calcPoint0.powerFactor,
        voltDropDiffThird: valueTab7.voltDropDiff,
        voltDropPerUnitThird: valueTab7.voltDropPerUnit,

        // tab7
        symKind: 0,
        listUser: [],

        checkNG : _3windingItem.checkNG,
        eleNeutralPointNumber : _3windingItem.eleNeutralPointNumber,
        supply : _3windingItem.supply,
        primarySupply : _3windingItem.primarySupply,
        tcName : _3windingItem.tcName,
        seriesed : _3windingItem.seriesed,
        viewResultText : _3windingItem.viewResultText,
        exchangeNumber : _3windingItem.exchangeNumber,
        viewFlowResult: _3windingItem.viewFlowResult,
        isSourceVoltage: false
    };
}

const response2Trans1Model = (responseData: any,voltage:number,voltage2:number,frequency:number) => {
    let voltageSecondary = voltage2

    let ratedCurrent = msTrans1GetRatedCurrent(
        responseData.capacity,
        voltage
    )
    // tab1-2
    let ratedCurrent1 = msTrans1GetRatedCurrent(
        responseData.capacity,
        voltageSecondary
    )

    let partOutOut: Model.MsTrans1PartModel
    let partOutIn: Model.MsTrans1PartModel
    partOutOut = getTrans1Part(responseData, 1,frequency) // [外線‐外線]
    partOutIn = getTrans1Part(responseData, 0,frequency) // [外線‐中性線]

    return {
        // tab0 - 基本
        noTrans1: responseData.data.no,
        refNo: "Tr1Ф-" + responseData.data.no,
        voltagePrimaryList: [],
        voltagePrimary: voltage,
        voltageSecondaryList: [],
        voltageSecondary: voltageSecondary,
        capacityList: [],
        capacity: responseData.capacity,
        ratedCurrent: BeamsNumber(ratedCurrent, 4),
        
        viewFlowResult: true,
        viewResultText: true,
        loadFactor: 1,
        powerFactor: 0.85,

        // ModePM
        makeDataInput: false,
        makerNameList: [],
        makerName: '',
        type: '',
        makeYearList: [],
        makeYear: null,
        makeMonthList: [],
        makeMonth: null,
        makeNumber: '',
        
        // MsTrans1Part: [外線‐中性線] && [外線‐外線]
        voltage1: Math.round(voltageSecondary / 2),
        capacity1: Math.round(responseData.capacity / 2),
        ratedCurrent1: BeamsNumber(ratedCurrent1, 4),
        partOutIn: partOutIn,
        partOutOut: partOutOut,

        // シンボル
        symKind: 1,
        listUser: [],

        // other
        checkNG: false,
        supply: false,
        tcName: '',
        nodeNumber: 0,
        perUnitP: 0,
        perUnitQ: 0,
        voltageMagnitude: 0,
        voltageAngle: 0,
        activePower: 0,
        reactivePower: 0,
        primarySupply: false,
        perUnitR: 0,
        perUnitX: 0,
        perUnitK: 0,
        seriesed: false,
        voltDropRouteRefNo: '',
        isSourceVoltage: false,
    } as Model.MsTrans1Model;
}

const getTrans1Part = (responseData: any, index: number,frequency:number) => {
    // partIndex == 0: [外線‐中性線] || partIndex == 1: [外線‐外線]
    let voltageSecondary = 210

    // tab1-2
    let calcData
    let xr, percentR, percentX
    if (index === 0) {
        if(responseData.perROutIn === 0){
            xr = 0
        }else{
            xr = BeamsNumber(responseData.perXOutIn / responseData.perROutIn, 5)
        }

        percentR = BeamsNumber(responseData.perROutIn, 5)
        percentX = BeamsNumber(responseData.perXOutIn, 5)
        calcData = {
            voltageSecondary: voltageSecondary / 2,
            capacity: responseData.capacity / 2,
            percentR: responseData.perROutIn,
            percentX: responseData.perXOutIn,
        }
    } else {
        if(responseData.perROutOut === 0){
            xr = 0
        }else{
        xr = BeamsNumber(responseData.perXOutOut / responseData.perROutOut, 5)
        }
        percentR = BeamsNumber(responseData.perROutOut, 5)
        percentX = BeamsNumber(responseData.perXOutOut, 5)
        calcData = {
            voltageSecondary: voltageSecondary,
            capacity: responseData.capacity,
            percentR: responseData.perROutOut,
            percentX: responseData.perXOutOut,
        }
    }
    let powerData = msTransNormalCalculation(calcData)
    
    // tab3-4
    let inrushTimes, inrushValue, dampingTime
    if (index === 0){
        inrushTimes = responseData.peakValueOutIn
        inrushValue = msTrans1GetInrushValue(
            responseData.peakValueOutIn,
            responseData.capacity,
            voltageSecondary
        )
        dampingTime = responseData.dampingTimeOutIn * 1000 / frequency
    } else {
        inrushTimes = responseData.peakValueOutOut
        inrushValue = msTrans1GetInrushValue(
            responseData.peakValueOutOut,
            responseData.capacity,
            voltageSecondary
        )
        dampingTime = responseData.dampingTimeOutOut * 1000 / frequency
    }

    let intensityValue = msTrans1GetIntensityValue(
        25,
        responseData.capacity,
        voltageSecondary
    )
    let calcPoint = {
        eleTrans1PartKind: index + 1,
        ele3WindingPartKind: 0,
        eleTranscottPartKind: 0,
        calcPointId: 1 ,
        calcKind: index == 1 ? Constant.CALC_IK2_OUTSIDE_OUTSIDE:Constant.CALC_IK2_OUTSIDE_NEUTRAL,
        loadCapacity: 0,
        stdCapacity:0,
        loadCurrent: 0,
        isLead: false, // 回路の力率
        powerFactor: 1,
        voltage: voltageSecondary,
        perZDirect: percentZDefault() as Model.PercentZ,
        perZTotal: percentZDefault() as Model.PercentZ,
        perZDirectMG: percentZDefault() as Model.PercentZ,
        perZTotalMG: percentZDefault() as Model.PercentZ,
        perZMotor: percentZDefault() as Model.PercentZ,
    } as Model.CalcPoint
     
    return {
        // 基本
        eleTrans1PartKind: index + 1,
        pointText: "F" + responseData.data.point,
        fault: false,
        
        xr: xr,
        percentZ: BeamsNumber(powerData.percentZ, 5),
        percentR: percentR,
        percentX: percentX,
        ohmZ: BeamsNumber(powerData.ohmZ, 4),
        ohmR: BeamsNumber(powerData.ohmR, 4),
        ohmX: BeamsNumber(powerData.ohmX, 4),

        // 保護協調
        dispInrush: true,
        inrushTimes: inrushTimes,
        inrushValue: BeamsNumber(inrushValue, 4),
        dampingTime: dampingTime,

        dispIntensity: false,
        intensityTimes: 25,
        intensityValue: BeamsNumber(intensityValue, 4),
        intensityTime: 2,

        // 電圧降下
        dropRegular: true,

        calcPoint: calcPoint,
        currentTopLeft: 0,  // always 0

        voltageDrop1: 0,
        voltageDrop: 0,
        
        // other
        selInrush: false,
        selIntensity: false,
        pageIndex: 0,
        inputedCapacity1: false,
        inputedCurrent1: false,
        inputedPowerFactor1: false,
        neutralKind: 0,
        dispAnsiKind: 0,
        ansiTimes: 16.6,
        ansiTime: 4,
        inrushTimesKind: 0, // m_nInrushTimes
        inrushManualValue: 10,
        inrushTime: 0.1,
        examineComment: '',
        voltDropSumIm: 0,
        voltDropSumRe: 0,
    } as Model.MsTrans1PartModel
}

const response2TransScottModel = (responseData: any,voltage1:number,voltage2:number,frequency:number) => {
    let voltagePrimary = voltage1
    let voltageSecondary = voltage2

    let ratedCurrent = msTransScottGetRatedCurrent(
        responseData.capacity,
        voltagePrimary,
        true
    )
    let ratedCurrent1 = msTransScottGetRatedCurrent(
        responseData.capacity,
        voltageSecondary
    )
    
    let partSeatM: Model.MsTransScottPartModel
    let partSeatT: Model.MsTransScottPartModel

    
    partSeatM =  getTransScottPart(responseData, 0,frequency) // [主座変圧器]
    
    partSeatT =  getTransScottPart(responseData, 1,frequency) // [T座変圧器]

    return {
        noTransScott: responseData.data.no,
        // tab0: 基本
        refNo: "スコットTr-" + responseData.data.no,
        voltagePrimaryList: [],
        voltagePrimary: voltagePrimary,
        voltageSecondaryList: [],
        voltageSecondary: voltageSecondary,
        capacityList: [],
        capacity: responseData.capacity,
        ratedCurrent: BeamsNumber(ratedCurrent, 4),
        ratedCurrent1: BeamsNumber(ratedCurrent1, 4),
        
        viewFlowResult: true,
        viewResultText: true,
        loadFactor: 1,
        powerFactor: 0.85,

        // ModePM
        makeDataInput: false,
        makerNameList: [],
        makerName: '',
        type: '',
        makeYearList: [],
        makeYear: null,
        makeMonthList: [],
        makeMonth: null,
        makeNumber: '',

        partSeatT: partSeatT,
        partSeatM: partSeatM,

        listUser: [],
        
        // other
        checkNG: false,
        supply: false,
        tcName: '',
        nodeNumber: 0,
        perUnitP: 0,
        perUnitQ: 0,
        voltageMagnitude: 0,
        voltageAngle: 0,
        activePower: 0,
        reactivePower: 0,
        primarySupply: false,
        perUnitR: 0,
        perUnitX: 0,
        perUnitK: 0,
        seriesed: false,
        voltDropRouteRefNo: '',
        isSourceVoltage: false,
    } as Model.MsTransScottModel;
}

const getTransScottPart = (responseData: any, index: number,frequency:number) => {
    // index == 0: [主座変圧器] || index == 1: [T座変圧器]
    let voltageSecondary = 210
    let capacity = responseData.capacity
    let value = doCalcSetValues(responseData,index,(capacity / 2),frequency)
    // tab1-2
    let percentR, percentX
    if (index === 0){
        percentX = BeamsNumber(value.dPerXSeatM, 5 )
        percentR = BeamsNumber(value.dPerRSeatM, 5 )
    } else {
        percentX = BeamsNumber(value.dPerXSeatT, 5 )
        percentR = BeamsNumber(value.dPerRSeatT, 5 )
    }
    let xr = BeamsNumber(value.xr, 5)
    let calcData = {
        voltageSecondary: voltageSecondary,
        capacity: capacity / 2,
        percentR: percentR,
        percentX: percentX,
    }
    let powerData = msTransNormalCalculation(calcData)
    
    // tab3-4
    let inrushTimes, inrushValue, dampingTime
    if (index === 0){
        inrushTimes = value.dInrushTimesSeatM
        inrushValue = msTransScottGetInrushValue(
            value.dInrushTimesSeatM,
            capacity,
            voltageSecondary
        )
        // dampingTime = responseData.dampingTimeSeatM * 1000 / frequency
        dampingTime = value.dDampingTimeSeatM
    } else {
        inrushTimes = value.dInrushTimesSeatT
        inrushValue = msTransScottGetInrushValue(
            value.dInrushTimesSeatT,
            capacity,
            voltageSecondary
        )
        dampingTime = value.dDampingTimeSeatT
    }
    let intensityValue = msTransScottGetIntensityValue(
        25,
        capacity,
        voltageSecondary
    )
    let calcPoint = {
        eleTrans1PartKind:0,
        ele3WindingPartKind: 0,
        eleTranscottPartKind: index + 1,
        calcPointId: 1 ,
        calcKind: index === 0 ?Constant.CALC_IK2_SEAT_MAIN : Constant.CALC_IK2_SEAT_T ,
        loadCapacity: 0,
        loadCurrent: 0,
        isLead: false, // 回路の力率
        powerFactor: 1,
        stdCapacity:0,
        voltage:voltageSecondary,
        perZDirect: percentZDefault() as Model.PercentZ,
        perZTotal: percentZDefault() as Model.PercentZ,
        perZDirectMG: percentZDefault() as Model.PercentZ,
        perZTotalMG: percentZDefault() as Model.PercentZ,
        perZMotor: percentZDefault() as Model.PercentZ,
    } as Model.CalcPoint

    return {
        eleTransScottPartKind: index + 1,
        // tab1: 基本
        pointText: "F" + responseData.data.point,
        fault: false,
        
        xr: xr,
        percentZ: BeamsNumber(value.perZk3, 5),
        percentR: percentR,
        percentX: percentX,
        ohmZ: BeamsNumber(powerData.ohmZ, 4),
        ohmR: BeamsNumber(powerData.ohmR, 4),
        ohmX: BeamsNumber(powerData.ohmX, 4),

        // tab3: 保護協調
        dispInrush: true,
        inrushTimes: inrushTimes,
        inrushValue: BeamsNumber(inrushValue, 4),
        dampingTime: dampingTime,

        dispIntensity: false,
        intensityTimes: 25,
        intensityValue: BeamsNumber(intensityValue, 4),
        intensityTime: 2,

        // tab5: 電圧降下
        dropRegular: true,

        calcPoint: calcPoint,
        currentTopLeft: 0, // always 0

        voltageDrop1: 0,
        voltageDrop: 0,
        
        // other
        selInrush: false,
        selIntensity: false,
        pageIndex: 0,
        inputedCapacity1: false,
        inputedCurrent1: false,
        inputedPowerFactor1: false,
        neutralKind: 0,
        dispAnsiKind: 0,
        ansiTimes: 16.6,
        ansiTime: 4,
        inrushTimesKind: 0, // m_nInrushTimes
        inrushManualValue: 10,
        inrushTime: 0.1,
        examineComment: '',
        voltDropSumIm: 0,
        voltDropSumRe: 0,
    } as Model.MsTransScottPartModel;
}

const response2DSModel = (id:number,refNo:string,responseData: any,voltage:number) => {
    // const makerNameList:any = [];
    // responseData.makerNameList?.forEach((item:any)=>{
    //     makerNameList.push({label:item.smanuName.toString(),
    //         value:item.smanuName} as OptionModel)
    // })
    // const makeYearList:any = [];
    // responseData.makeYearList?.forEach((item:any)=>{
    //     makeYearList.push({label:item.toString(),
    //         value:item} as OptionModel)
    // })
    // const makeMonthList:any = [];
    // responseData.makeMonthList?.forEach((item:any)=>{
    //     makeMonthList.push({label:item.toString(),
    //         value:item} as OptionModel)
    // })
    const  calcPoint0 ={
        loadCurrent: 0,
        isLead: false,
        powerFactor: 1,
        calcKind: Constant.CALC_IK3,
        stdCapacity:0,
        loadCapacity: 0,
        voltage:voltage,
        perZDirect: percentZDefault() as Model.PercentZ,
        perZTotal: percentZDefault() as Model.PercentZ,
        perZDirectMG: percentZDefault() as Model.PercentZ,
        perZTotalMG: percentZDefault() as Model.PercentZ,
        perZMotor: percentZDefault() as Model.PercentZ,
    } as Model.CalcPoint

    const  calcPoint1 ={
        loadCurrent: 0,
        isLead: false,
        powerFactor: 1,
        calcKind: Constant.CALC_IK2,
        stdCapacity:0,
        loadCapacity: 0,
        voltage:voltage,
        perZDirect: percentZDefault() as Model.PercentZ,
        perZTotal: percentZDefault() as Model.PercentZ,
        perZDirectMG: percentZDefault() as Model.PercentZ,
        perZTotalMG: percentZDefault() as Model.PercentZ,
        perZMotor: percentZDefault() as Model.PercentZ,
    } as Model.CalcPoint
    const cComplex = {
        voltDropSumRe: -99999,
        voltDropSumIm:-99999,

    } as Model.CComplex
    const listCompEleMG0 ={} as Model.CompEle
    const listCompEleMG1 ={} as Model.CompEle
    const listCompEle1 ={} as Model.CompEle
    const listCompEle2 ={} as Model.CompEle

    return {
         //基本
         refNo:  refNo,
         pointText: "F" + responseData.data.point,
         voltage: voltage,
        voltageList:[],
        makeDataInput:false,
        isOff: false,
        fault: false,
        dropMotor: false,
        dropRegular: false,
        viewFlowResult:false,
        connectSwitch:false,
        viewResultText: true,
        makerName:"",
        makerNameList:[],
        type:"",
        makeYear:"",
        makeYearList:[],
        makeMonth:"",
        makeMonthList:[],
        makeNumber:"",
         // シンボル
         exchangeNumber: 0,
         //他の
        isSourceVoltage:false,
        sourceVoltage:0,
        noLS: id,
        // pointTextLS: responseData.data.point,
        
        listUser: [],
        calcPoint0:calcPoint0,
        calcPoint1:calcPoint1,
        downTransKind:0,
        lineActiveFlow1: 0,
        lineActiveFlow2: 0,
        lineReactiveFlow1: 0,
        lineReactiveFlow2: 0,
        cComplex:cComplex,

        checkNG:false,
        supply:false,
        tc_name:"",
        eleTopLeftNumber: 0,
        eleBottomRightNumber:0,
        primarySupply: false,
        examineComment:"",
        seriesed:false,
        voltDropRouteRefNo:"",
        listCompEleMG0:listCompEleMG0,
        listCompEleMG1:listCompEleMG1,
        listCompEle1:listCompEle1,
        listCompEle2:listCompEle2
    } as Model.MsDSModel;
}

const response2LBSModel = (responseData: any,voltage:number) => {
    // const makerNameList:any = [];
    // responseData.makerNameList?.forEach((item:any)=>{
    //     makerNameList.push({label:item.smanuName.toString(),
    //         value:item.smanuName} as OptionModel)
    // })
    // const makeYearList:any = [];
    // responseData.makeYearList?.forEach((item:any)=>{
    //     makeYearList.push({label:item.toString(),
    //         value:item} as OptionModel)
    // })
    // const makeMonthList:any = [];
    // responseData.makeMonthList?.forEach((item:any)=>{
    //     makeMonthList.push({label:item.toString(),
    //         value:item} as OptionModel)
    // })
    const  calcPoint0 ={
        loadCurrent: 0,
        isLead: false,
        powerFactor: 1,
        calcKind: Constant.CALC_IK3,
        stdCapacity:0,
        loadCapacity: 0,
        voltage:voltage,
        perZDirect: percentZDefault() as Model.PercentZ,
        perZTotal: percentZDefault() as Model.PercentZ,
        perZDirectMG: percentZDefault() as Model.PercentZ,
        perZTotalMG: percentZDefault() as Model.PercentZ,
        perZMotor: percentZDefault() as Model.PercentZ,
    } as Model.CalcPoint

    const  calcPoint1 ={
        loadCurrent: 0,
        isLead: false,
        powerFactor: 1,
        calcKind: Constant.CALC_IK2,
        stdCapacity:0,
        loadCapacity: 0,
        voltage:voltage,
        perZDirect: percentZDefault() as Model.PercentZ,
        perZTotal: percentZDefault() as Model.PercentZ,
        perZDirectMG: percentZDefault() as Model.PercentZ,
        perZTotalMG: percentZDefault() as Model.PercentZ,
        perZMotor: percentZDefault() as Model.PercentZ,
    } as Model.CalcPoint
    const cComplex = {
        voltDropSumRe: -99999,
        voltDropSumIm:-99999
    } as Model.CComplex
    const listCompEleMG0 ={} as Model.CompEle
    const listCompEleMG1 ={} as Model.CompEle
    const listCompEle1 ={} as Model.CompEle
    const listCompEle2 ={} as Model.CompEle
    return {
        refNo: "LBS-" + responseData.data.no,
        noLBS:responseData.data.no,
        pointText: "F" + responseData.data.point,
        voltageList:[],
        voltage: voltage,
        isOff: false,
        makeDataInput:false,
        fault: false,
        dropMotor: false,
        dropRegular: false,
        viewFlowResult:false,
        connectSwitch:false,
        viewResultText: true,
        makerName:"",
        makerNameList:[],
        type:"",
        makeYear:"",
        makeYearList:[],
        makeMonth:"",
        makeMonthList:[],
        makeNumber:"",
        exchangeNumber: 0,
        isSourceVoltage:false,
        listUser: [],

        calcPoint0:calcPoint0,
        calcPoint1:calcPoint1,
        checkNG:false,
        supply: false,
        tcName: "",
        lineActiveFlow1: 0,
        lineActiveFlow2: 0,
        lineReactiveFlow1:0,
        lineReactiveFlow2:0,
        eleTopLeftNumber: 0,
        eleBottomRightNumber:0,
        primarySupply: false,
        cComplex:cComplex,
        downTransKind:0,
        examineComment:"",
        seriesed: false,
        voltDropRouteRefno:"",
        listCompEleMG0:listCompEleMG0,
        listCompEleMG1:listCompEleMG1,
        listCompEle1:listCompEle1,
        listCompEle2:listCompEle2

    } as Model.MsLBSModel;
}

const response2MVCBModel = (responseData: any,voltage:number,upTransKind:number,sPhase:boolean) => {
    // const makerNameList:any = [];
    // responseData.makerNameList?.forEach((item:any)=>{
    //     makerNameList.push({label:item.smanuName.toString(),
    //         value:item.smanuName} as OptionModel)
    // })
    // const makeYearList:any = [];
    // responseData.makeYearList?.forEach((item:any)=>{
    //     makeYearList.push({label:item.toString(),
    //         value:item} as OptionModel)
    // })
    // const makeMonthList:any = [];
    // responseData.makeMonthList?.forEach((item:any)=>{
    //     makeMonthList.push({label:item.toString(),
    //         value:item} as OptionModel)
    // })
    const listCompEleMG0 ={} as Model.CompEle
    const listCompEleMG1 ={} as Model.CompEle
    const listCompEle1 ={} as Model.CompEle
    const listCompEle2 ={} as Model.CompEle
    const  calcPoint0 ={
        loadCurrent: 0,
        isLead: false,
        powerFactor: 1,
        calcKind: Constant.CALC_IK3,
        stdCapacity:0,
        loadCapacity: 0,
        voltage: voltage,
        perZDirect: percentZDefault() as Model.PercentZ,
        perZTotal: percentZDefault() as Model.PercentZ,
        perZDirectMG: percentZDefault() as Model.PercentZ,
        perZTotalMG: percentZDefault() as Model.PercentZ,
        perZMotor: percentZDefault() as Model.PercentZ,
    } as Model.CalcPoint

    const  calcPoint1 ={
        loadCurrent: 0,
        isLead: false,
        powerFactor: 1,
        calcKind: Constant.CALC_IK2,
        stdCapacity:0,
        loadCapacity: 0,
        voltage:voltage,
        perZDirect: percentZDefault() as Model.PercentZ,
        perZTotal: percentZDefault() as Model.PercentZ,
        perZDirectMG: percentZDefault() as Model.PercentZ,
        perZTotalMG: percentZDefault() as Model.PercentZ,
        perZMotor: percentZDefault() as Model.PercentZ,
    } as Model.CalcPoint
    const cComplex = {
        voltDropSumRe: -99999,
        voltDropSumIm:-99999,
        voltDropSumRe2: -99999,
        voltDropSumIm2:-99999

    } as Model.CComplex
    return {
        refNo: "CB-" + responseData.data.no,
        noMVCB: responseData.data.no,
        pointText: "F" + responseData.data.point,
        voltageList:[],
        voltage: voltage,
        breakCapacityList:[],
        breakCapacity: 12.5,
        isOff: false,
        fault: false,
        dropMotor: false,
        dropRegular: false,
        viewFlowResult:false,
        connectSwitch:false,
        viewResultText: true,
        makerName:"",
        makerNameList:[],
        type:"",
        makeYear:"",
        makeYearList:[],
        makeMonth:"",
        makeMonthList:[],
        makeNumber:"",
        makeDataInput:false,    
        exchangeNumber: 0,
        isSourceVoltage:false,
        listUser: [],

        calcPoint0:calcPoint0,
        calcPoint1:calcPoint1,
        checkNG:false,
        supply: false,
        tcName: "",
        lineActiveFlow1: 0,
        lineActiveFlow2: 0,
        lineReactiveFlow1:0,
        lineReactiveFlow2:0,
        eleTopLeftNumber: 0,
        eleBottomRightNumber:0,
        primarySupply: false,
        cComplex:cComplex,
        upTransKind:upTransKind,
        examineComment:"",
        seriesed: false,
        voltDropRouteRefno:"",
        downTransKind: 0,
        sPhase: sPhase,
        examineCommentSPhase:"",
        listCompEleMG0:listCompEleMG0,
        listCompEleMG1:listCompEleMG1,
        listCompEle1:listCompEle1,
        listCompEle2:listCompEle2
    } as Model.MsMVCBModel;
}

const response2ContactorModel = (responseData: any,voltage:number,upTransKind:number,sPhase:boolean) => {
    // const makerNameList:any = [];
    // responseData.makerNameList?.forEach((item:any)=>{
    //     makerNameList.push({label:item.smanuName.toString(),
    //         value:item.smanuName} as OptionModel)
    // })
    // const makeYearList:any = [];
    // responseData.makeYearList?.forEach((item:any)=>{
    //     makeYearList.push({label:item.toString(),
    //         value:item} as OptionModel)
    // })
    // const makeMonthList:any = [];
    // responseData.makeMonthList?.forEach((item:any)=>{
    //     makeMonthList.push({label:item.toString(),
    //         value:item} as OptionModel)
    // })
    const listCompEleMG0 ={} as Model.CompEle
    const listCompEleMG1 ={} as Model.CompEle
    const listCompEle1 ={} as Model.CompEle
    const listCompEle2 ={} as Model.CompEle
    const  calcPoint0 ={
        loadCurrent: 0,
        isLead: false,
        powerFactor: 1,
        calcKind: Constant.CALC_IK3,
        stdCapacity:0,
        loadCapacity: 0,
        voltage: voltage,
        perZDirect: percentZDefault() as Model.PercentZ,
        perZTotal: percentZDefault() as Model.PercentZ,
        perZDirectMG: percentZDefault() as Model.PercentZ,
        perZTotalMG: percentZDefault() as Model.PercentZ,
        perZMotor: percentZDefault() as Model.PercentZ,
    } as Model.CalcPoint

    const  calcPoint1 ={
        loadCurrent: 0,
        isLead: false,
        powerFactor: 1,
        calcKind: Constant.CALC_IK2,
        stdCapacity:0,
        loadCapacity: 0,
        voltage: voltage,
        perZDirect: percentZDefault() as Model.PercentZ,
        perZTotal: percentZDefault() as Model.PercentZ,
        perZDirectMG: percentZDefault() as Model.PercentZ,
        perZTotalMG: percentZDefault() as Model.PercentZ,
        perZMotor: percentZDefault() as Model.PercentZ,
    } as Model.CalcPoint
    const cComplex = {
        voltDropSumRe: -99999,
        voltDropSumIm:-99999,
        voltDropSumRe2: -99999,
        voltDropSumIm2:-99999

    } as Model.CComplex
    return {
        refNo: "MC-" + responseData.data.no,
        noContactor: responseData.data.no,
        pointText: "F" + responseData.data.point,
        voltageList:[],
        voltage:voltage,
        isOff: false,
        fault: false,
        dropMotor: false,
        dropRegular: false,
        viewResultText: true,
        viewFlowResult:false,
        //  connectSwitch:false,
        makeDataInput: false,
        makerName:"",
        makerNameList:[],
        type: "",
        makeYear:"",
        makeYearList:[],
        makeMonth:"",
        makeMonthList:[],
        makeNumber:"",
        exchangeNumber: 0,
        isSourceVoltage:false,
        listUser: [],

        calcPoint0:calcPoint0,
        calcPoint1:calcPoint1,
        checkNG:false,
        supply: false,
        tcName: "",
        lineActiveFlow1: 0,
        lineActiveFlow2: 0,
        lineReactiveFlow1:0,
        lineReactiveFlow2:0,
        primarySupply: false,
        cComplex:cComplex,
        upTransKind:upTransKind,
        examineComment:"",
        seriesed: false,
        voltDropRouteRefno:"",
        downTransKind: 0,
        sPhase: sPhase,
        capacity: 0,
        examineCommentSPhase: "",
        listCompEleMG0:listCompEleMG0,
        listCompEleMG1:listCompEleMG1,
        listCompEle1:listCompEle1,
        listCompEle2:listCompEle2
    } as Model.MsContactorModel;
}

const response22EModel = (responseData: any,voltage:any,upTransKind:number,sPhase:boolean) => {
    let lenParts = responseData.details.parts?.length
    if (lenParts > 0) {
      for (let i = 0; i < lenParts; i++){
        responseData.details.parts[i] = {
            ...responseData.details.parts[i],
            setLock: false,
            partID: i + 1
        }
      }
    }
    // let convertedData = convert22EDataFromBE(responseData)
    let useCT = responseData.voltage > 600
    let turnNumber = 1
    const  calcPoint0 ={
        loadCurrent: 0,
        isLead: false,
        powerFactor: 1,
        calcKind: Constant.CALC_IK3,
        stdCapacity:0,
        loadCapacity: 0,
        voltage: voltage,
        perZDirect: percentZDefault() as Model.PercentZ,
        perZTotal: percentZDefault() as Model.PercentZ,
        perZDirectMG: percentZDefault() as Model.PercentZ,
        perZTotalMG: percentZDefault() as Model.PercentZ,
        perZMotor: percentZDefault() as Model.PercentZ,
    } as Model.CalcPoint

    const  calcPoint1 ={
        loadCurrent: 0,
        isLead: false,
        powerFactor: 1,
        calcKind: Constant.CALC_IK2,
        stdCapacity:0,
        loadCapacity: 0,
        voltage: voltage,
        perZDirect: percentZDefault() as Model.PercentZ,
        perZTotal: percentZDefault() as Model.PercentZ,
        perZDirectMG: percentZDefault() as Model.PercentZ,
        perZTotalMG: percentZDefault() as Model.PercentZ,
        perZMotor: percentZDefault() as Model.PercentZ,
    } as Model.CalcPoint
    return {
        // 基本
        no2E: responseData.data.no,
        refNo: "51M-" + responseData.data.no,
        pointText: "F" + responseData.data.point,
        voltageList: [],
        voltage: voltage,
        isOff: false,
        fault: false,
        dropMotor: false,
        dropRegular: false,
        viewResultText: true,
        
        // ModePM
        viewFlowResult: false,

        makeDataInput: false,

        makeYearList: [],
        makeYear: null,
        makeMonthList: [],
        makeMonth: null,
        makeNumber: '',

        // 保護協調
        details: {
            base: {
                ...responseData.details.base, 
                ...{
                    useCT: useCT,
                    useCC: true,
                    kindFlag: Constant.RS_KIND_2E,
                    turnNumber: 1,
                    ctPrimaryList: [],
                    ctPrimary: responseData.ctPrimary,
                    ctSecondaryList: [
                        {label: '1', value: 1},
                        {label: '5', value: 5},
                    ],
                    ctSecondary: 5,
                    readBase: {
                        canUseCC: responseData.details.base.canUseCC,
                        canUseCT: responseData.details.base.canUseCT
                    }
                }
            },
            parts: responseData.details.parts,
            refNo2: "51M-" + responseData.data.no,
            drawThreeCurve: true
        },
        turnNumber: turnNumber,
        // シンボル
        symKind: 0,
        listUser: [],
        
        // others
        makerName: '',
        sPhase: sPhase,
        upTransKind: upTransKind,
        checkNG: false,
        supply: false,
        tcName: '',
        lineActiveFlow1: 0,
        lineReactiveFlow1: 0,
        lineActiveFlow2: 0,
        lineReactiveFlow2: 0,
        downTransKind: Element.MS_ELEMENT_ALL,
        primarySupply: false,
        examineComment: '',
        examineCommentSPhase: '',
        seriesed: false,
        voltDropSumRe1: MS_COMPLEX_VOLTDROP,
        voltDropSumIm1: MS_COMPLEX_VOLTDROP,
        voltDropSumRe2: MS_COMPLEX_VOLTDROP,
        voltDropSumIm2: MS_COMPLEX_VOLTDROP,
        voltDropRouteRefNo: '',
        calcPoint0:calcPoint0,
        calcPoint1:calcPoint1,
    } as Model.Ms2EModel;
}

const response2FUSEModel = (responseData: any,voltage:number,rotation:number) => {
    let lenParts = responseData.details.parts?.length
    if (lenParts > 0) {
      for (let i = 0; i < lenParts; i++){
        responseData.details.parts[i] = {
            ...responseData.details.parts[i],
            setLock: false,
            partID: i + 1
        }
      }
    }
    // let convertedData = convertFUSEDataFromBE(responseData);
    responseData.details.parts.forEach((part : PartDetailsModel) => {
        part.readPart = {
            isLink: false,
            linkNumber: 0,
            currentFlag: 0,
            isCurrent: false
        }
    })
    const  calcPoint0 ={
        loadCurrent: 0,
        isLead: false,
        powerFactor: 1,
        calcKind: Constant.CALC_IK3,
        stdCapacity:0,
        loadCapacity: 0,
        voltage:responseData.voltage,
        perZDirect: percentZDefault() as Model.PercentZ,
        perZTotal: percentZDefault() as Model.PercentZ,
        perZDirectMG: percentZDefault() as Model.PercentZ,
        perZTotalMG: percentZDefault() as Model.PercentZ,
        perZMotor: percentZDefault() as Model.PercentZ,
    } as Model.CalcPoint

    const  calcPoint1 ={
        loadCurrent: 0,
        isLead: false,
        powerFactor: 1,
        calcKind: Constant.CALC_IK2,
        stdCapacity:0,
        loadCapacity: 0,
        voltage:responseData.voltage,
        perZDirect: percentZDefault() as Model.PercentZ,
        perZTotal: percentZDefault() as Model.PercentZ,
        perZDirectMG: percentZDefault() as Model.PercentZ,
        perZTotalMG: percentZDefault() as Model.PercentZ,
        perZMotor: percentZDefault() as Model.PercentZ,
    } as Model.CalcPoint
    return {
        // 基本
        refNo: "PF-" + responseData.data.no,
        noFUSE: responseData.data.no,
        pointText: "F" + responseData.data.point,
        voltageList: [],
        voltage: voltage,
        isOff: false,
        fault: true,
        dropMotor: false,
        dropRegular: false,
        viewResultText: true,
        
        // ModePM
        viewFlowResult: false,

        makeDataInput: false,
        makerName: '',
        makeYearList: [],
        makeYear: null,
        makeMonthList: [],
        makeMonth: null,
        makeNumber: '',

        // 保護協調
        details: {
            base: {
                ...responseData.details.base, 
                ...{
                    kindFlag: Constant.RS_KIND_FUSE,
                    setBreakingTime: null,
                    setBreakHz: null,
                    breakTimeHz: null,
                    useCT: false,
                    useCC: false,
                    readBase: {
                        canUseCC: responseData.details.base.canUseCC,
                        canUseCT: responseData.details.base.canUseCT
                    }
                }
            },
            parts: responseData.details.parts,
            refNo2: "PF-" + responseData.data.no,
            drawThreeCurve: true
        },
        // シンボル
        symKind: 0,
        listUser: [],

        // others
        checkNG: false,
        supply: false,
        tcName: '',
        lineActiveFlow1: 0,
        lineReactiveFlow1: 0,
        lineActiveFlow2: 0,
        lineReactiveFlow2: 0,
        downTransKind: Element.MS_ELEMENT_ALL,
        primarySupply: false,
        examineComment: '',
        seriesed: false,
        voltDropSumRe: MS_COMPLEX_VOLTDROP,
        voltDropSumIm: MS_COMPLEX_VOLTDROP,
        voltDropRouteRefNo: '',
        rotate: rotation === -90?1:rotation === 90?2:rotation, // TODO

        calcPoint0:calcPoint0,
        calcPoint1:calcPoint1,
    } as Model.MsFUSEModel;
}

const response2ZerocondModel = (responseData: any,voltage:number,upTransKind:number,sPhase:boolean) => {
    const  calcPoint0 ={
        loadCurrent: 0,
        isLead: false,
        powerFactor: 1,
        calcKind: Constant.CALC_IK3,
        stdCapacity:0,
        loadCapacity: 0,
        voltage:voltage,
        perZDirect: percentZDefault() as Model.PercentZ,
        perZTotal: percentZDefault() as Model.PercentZ,
        perZDirectMG: percentZDefault() as Model.PercentZ,
        perZTotalMG: percentZDefault() as Model.PercentZ,
        perZMotor: percentZDefault() as Model.PercentZ,
    } as Model.CalcPoint

    const  calcPoint1 ={
        loadCurrent: 0,
        isLead: false,
        powerFactor: 1,
        calcKind: Constant.CALC_IK2,
        stdCapacity:0,
        loadCapacity: 0,
        voltage:voltage,
        perZDirect: percentZDefault() as Model.PercentZ,
        perZTotal: percentZDefault() as Model.PercentZ,
        perZDirectMG: percentZDefault() as Model.PercentZ,
        perZTotalMG: percentZDefault() as Model.PercentZ,
        perZMotor: percentZDefault() as Model.PercentZ,
    } as Model.CalcPoint
    
    const listCompEleMG0 = [] as Model.CompEle[]
    const listCompEleMG1 = [] as Model.CompEle[]
    const listCompEle1 = [] as Model.CompEle[]
    const listCompEle2 = [] as Model.CompEle[]
    return {
        refNo: "母線-" + responseData.data.no,
        noZerocond:responseData.data.no,
        pointText: "F" + responseData.data.point,
        voltageList:[],
        voltage: voltage,
        fault: true,
        dropMotor: false,
        dropRegular: true,
        calcImprovePF: true,
        calcRightLeft: false,
        afterPF: 0.95,
        viewResultText: true,
        // tab 2
        flowConductance:0,
        flowSusceptance:0,
        viewFlowResult:true,
        isSourceVoltage:false,
        listUser: [],
        checkNG: false,
        sPhase: sPhase,
        beforePF: 1,
        totalCurrent: 0,
        nodeNumber: 0,
        voltageMagnitude: 1,
        voltageAngle: 0,
        activePower: 0,
        reactivePower: 0,
        upTransKind: upTransKind,
        supply: false,
        tcName: "",
        primarySupply: false,
        examineComment:"",
        examineCommentSPhase:"",
        seriesed: false,
        voltDropSumRe1: 0,
        voltDropSumIm1: 0,
        voltDropSumRe2: 0,
        voltDropSumIm2: 0,
        voltDropRouteRefNo: '',
        calcPoint0:calcPoint0,
        calcPoint1:calcPoint1,
        listCompEleMG0:listCompEleMG0,
        listCompEleMG1:listCompEleMG1,
        listCompEle1:listCompEle1,
        listCompEle2:listCompEle2,
        perUnitZrZerocond:percentZDefault() as Model.PercentZ,
    } as Model.MsZerocondModel;
}

const response2WireModel = (responseData: any,voltage:number,upTransKind:number,sPhase:boolean,frequency:number,rotation:number) => {
    const wireItem = {
        sPhase:sPhase,rotate:rotation === -90?1:rotation,voltage:voltage,fault:false,dropRegular:false,dropMotor:false,eleLineNumber:0,eleTopLeftNumber:0,eleBottomRightNumber:0,
        perUnitR:0,perUnitK:0,lineActiveFlow1:0,lineReactiveFlow1:0,lineActiveFlow2:0,lineReactiveFlow2:0,exchangeNumber:WIRE_SYMBOL_CABLE,upTransKind:upTransKind,
        supply:false,tcName:"",makeDataInput:false,makerName:"",makeYear:"",makeMonth:"",type:"",voltageMagnitude1:1,voltageAngle1:0,
        voltageMagnitude2:1,voltageAngle2:0,pointText:"",viewFlowResult:false,inputedCapacity1:false,inputedCurrent1:false,
        inputedPowerFactor1:false,inputedCapacity2:false,inputedCurrent2:false,inputedPowerFactor2:false,downTransKind:0,
        primarySupply:false,examineComment:"",examineCommentSPhase:"",seriesed:false,viewResultText:true,currentTopLeft:true,flowSusceptance:0,
        _3Phases:{phaseKind:1} as Model.WirePhase,PhaseN:{phaseKind:2} as Model.WirePhase,PhasePE:{phaseKind:3} as Model.WirePhase,
        calcPoint0:{},calcPoint1:{}
    } as any;
    
    doSetDefaultWire(true,voltage,frequency,wireItem,responseData.voltLowDefaultItem,responseData.equip_wireZList,responseData.equip_wireIList,responseData.equip_wireCList);
    // BeamsValueWirePhase(wireItem._3Phases);
    // BeamsValueWirePhase(wireItem.PhaseN);
    // BeamsValueWirePhase(wireItem.PhasePE);

    return {
        refNo: "低圧幹線-" + responseData.data.no,
        noWire: responseData.data.no,
        pointText: "F" + responseData.data.point,
        sPhase:wireItem.sPhase,
        rotate:wireItem.rotate,
        voltage:wireItem.voltage,
        fault:wireItem.fault,
        dropRegular:wireItem.dropRegular,
        wireSystem:wireItem.wireSystem,
        material:wireItem.material,
        insulation:wireItem.insulation,
        core:wireItem.core,
        method:wireItem.method,
        circuitNumber:wireItem.circuitNumber,
        ambientTemp:wireItem.ambientTemp,
        length:wireItem.length,
        dropMotor:wireItem.dropMotor,
        eleLineNumber:wireItem.eleLineNumber,
        eleTopLeftNumber:wireItem.eleTopLeftNumber,
        eleBottomRightNumber:wireItem.eleBottomRightNumber,
        perUnitR:wireItem.perUnitR,
        perUnitX:wireItem.perUnitX,
        perUnitK:wireItem.perUnitK,
        lineActiveFlow1:wireItem.lineActiveFlow1,
        lineReactiveFlow1:wireItem.lineReactiveFlow1,
        lineActiveFlow2:wireItem.lineActiveFlow2,
        lineReactiveFlow2:wireItem.lineReactiveFlow2,
        exchangeNumber:wireItem.exchangeNumber,
        upTransKind:wireItem.upTransKind,
        supply:wireItem.supply,
        tcName:wireItem.tcName,
        makeDataInput:wireItem.makeDataInput,
        makerName:wireItem.makerName,
        makeYear:wireItem.makeYear,
        makeMonth:wireItem.makeMonth,
        type:wireItem.type,
        voltageMagnitude1:wireItem.voltageMagnitude1,
        voltageAngle1:wireItem.voltageAngle1,
        voltageMagnitude2:wireItem.voltageMagnitude2,
        voltageAngle2:wireItem.voltageAngle2,
        viewFlowResult:wireItem.viewFlowResult,
        inputedCapacity1:wireItem.inputedCapacity1,
        inputedCurrent1:wireItem.inputedCurrent1,
        inputedPowerFactor1:wireItem.inputedPowerFactor1,
        inputedCapacity2:wireItem.inputedCapacity2,
        inputedCurrent2:wireItem.inputedCurrent2,
        inputedPowerFactor2:wireItem.inputedPowerFactor2,
        downTransKind:wireItem.downTransKind,
        primarySupply:wireItem.primarySupply,
        examineComment:wireItem.examineComment,
        examineCommentSPhase:wireItem.examineCommentSPhase,
        seriesed:wireItem.seriesed,
        viewResultText:wireItem.viewResultText,
        calcPoint0:wireItem.calcPoint0,
        calcPoint1:wireItem.calcPoint1,

        // voltDropSumRe1:wireItem.voltDropSumRe1,
        // voltDropSumIm1:wireItem.voltDropSumIm1,
        // voltDropSumRe2:wireItem.voltDropSumRe2,
        // voltDropSumIm2:wireItem.voltDropSumIm2,
        // voltDropRouteRefNo:wireItem.voltDropRouteRefNo,
        currentTopLeft:wireItem.currentTopLeft,
        flowSusceptance:wireItem.flowSusceptance,
        
        _3Phases:wireItem._3Phases,
        PhaseN:wireItem.PhaseN,
        PhasePE: wireItem.PhasePE,
        listUser: [] as any,
        isSourceVoltage: false,

        // basic:wireItem.basic,
        // voltage:wireItem.voltage,
        // viewResultText:true,
        // fault:false,
        // ohmR_3Phases: BeamsNumber(doGetTextOhmR(RS_3PHASES,wireItem),4),
        // ohmR_PhaseN:  BeamsNumber(doGetTextOhmR(RS_PHASE_N,wireItem),4),
        // ohmR_PhasePE: BeamsNumber(doGetTextOhmR(RS_PHASE_PE,wireItem),4),
        // ohmX_3Phases: BeamsNumber(doGetTextOhmX(RS_3PHASES,wireItem),4),
        // ohmX_PhaseN:  BeamsNumber(doGetTextOhmX(RS_PHASE_N,wireItem),4),
        // ohmX_PhasePE: BeamsNumber(doGetTextOhmX(RS_PHASE_PE,wireItem),4),
        // capacity_3Phases: doGetTextCapcity(wireItem._3Phases),
        // capacity_PhaseN: doGetTextCapcity(wireItem.PhaseN),
        // capacity_PhasePE: doGetTextCapcity(wireItem.PhasePE),
        // symKind:wireItem.exchangeNumber,
        // dropRegular:wireItem.dropRegular,
        // dropMotor:wireItem.dropMotor,
        // calcPoint:wireItem.calcPoint,
        // currentTopLeft:true,
        // // mode PM
        // makeDataInput: false,
        // makerName: '',
        
        // makeYear: null,
        // makeMonth: null,
        // makeNumber: '',
    } as Model.MsWireModel;
}

const response2BusbarModel = (id: number,responseData: any,voltage:number,upTransKind:number,sPhase:boolean,frequency:number) => {
    const m_3Phase ={} as Model.IeleBusbarSPhase
    const phaseN ={} as Model.IeleBusbarSPhase
    const phaseNPE ={} as Model.IeleBusbarSPhase

    const setDefaultBusbar = responseData.setDefaultBusbar
    const busBarList = responseData.busBarList.sort((id1:any,id2:any)=>id1.lbusbarID - id2.lbusbarID)
    doSetDefaultBusbar(m_3Phase,setDefaultBusbar,frequency,busBarList,RS_3PHASES)
    doSetDefaultBusbar(phaseN,setDefaultBusbar,frequency,busBarList,RS_PHASE_N)
    doSetDefaultBusbar(phaseNPE,setDefaultBusbar,frequency,busBarList,RS_PHASE_PE)

    const calcPoint0 ={
        loadCurrent: 0,
        isLead: false,
        powerFactor: 1,
        calcKind: Constant.CALC_IK3,
        stdCapacity:0,
        loadCapacity: 0,
        voltage:voltage,
        perZDirect: percentZDefault() as Model.PercentZ,
        perZTotal: percentZDefault() as Model.PercentZ,
        perZDirectMG: percentZDefault() as Model.PercentZ,
        perZTotalMG: percentZDefault() as Model.PercentZ,
        perZMotor: percentZDefault() as Model.PercentZ,
    } as Model.CalcPoint

    const calcPoint1 ={
        loadCurrent: 0,
        isLead: false,
        powerFactor: 1,
        calcKind: Constant.CALC_IK2,
        stdCapacity:0,
        loadCapacity: 0,
        voltage:voltage,
        perZDirect: percentZDefault() as Model.PercentZ,
        perZTotal: percentZDefault() as Model.PercentZ,
        perZDirectMG: percentZDefault() as Model.PercentZ,
        perZTotalMG: percentZDefault() as Model.PercentZ,
        perZMotor: percentZDefault() as Model.PercentZ,
    } as Model.CalcPoint
    
    const listCompEleMG0 ={} as Model.CompEle
    const listCompEleMG1 ={} as Model.CompEle
    const listCompEle1 ={} as Model.CompEle
    const listCompEle2 ={} as Model.CompEle
    const cComplex = {
        voltDropSumRe: -99999,
        voltDropSumIm:-99999,
        voltDropSumRe2: -99999,
        voltDropSumIm2:-99999

    } as Model.CComplex

    return {
        // tab0 - 基本
        refNo: "ブスバー‐" + responseData.data.no,
        noBusBar: responseData.data.no,
        pointText: 'F' + responseData.data.point,
        voltageList:[],
        voltage: voltage,
        lenght: 0,
        fault: false,
        viewResultText: true,
        wireSystem: 1,
        makeDataInput:false,
        makerName:"",
        makerNameList:[],
        type:"",
        makeYear:"",
        makeYearList:[],
        makeMonth:"",
        makeMonthList:[],
        // tab1 - 仕様
        materialCuAl: 1,
        bareOrPainted: 1,
        verticalOrHorizonal: 1,
        busbarTemp: 65,
        ambient: 35,
        // tab2 - 電圧線(3相/単相)
        // width: 50,
        // thickness: 5,
        // numberPhase: 1,
        // distance: 200,
        // skinEffect: 1.02,
        // ohmRPer: 0.07715,//17
        // ohmR: 0,
        // ohmXPer: 0.1898,
        // ohmX: 0,
        // basicCapacity: 583,
        // tempK2: 1,
        // horizontalK3: 1,
        // proximityK4: 1,
        // capacity: 583,
        m_3Phase:m_3Phase,
        // tab3 - 電圧降下
        dropMotor: false,
        dropRegular: true,
        
        topLeft: 1,
        leadOrLag1: 0,
        leadOrLag2: 0,

        
        voltageDrop_1: 0,
        voltageDrop: 0,

        voltageDrop_1_2: 0,
        voltageDrop2: 0,
        //tab 4 潮流解析
        flowSusceptance:0,
        viewFlowResult: true,
        // tab5 -  中性線(N相)
        phaseN:phaseN,
         // tab6 - 保護接地線(PE相)
        phaseNPE: phaseNPE,
         
        
        busBarList: [],
        // doReadZByValue:doReadZByValue,
        
        listUser: [],
        isSourceVoltage:false,
        calcPoint0:calcPoint0,
        calcPoint1:calcPoint1,
        listCompEleMG0:listCompEleMG0,
        listCompEleMG1:listCompEleMG1,
        listCompEle1:listCompEle1,
        listCompEle2:listCompEle2,
        sPhase: sPhase,
        checkNG: false,
        eleLineNumber: 0,
        eleTopLeftNumber: 0,
        eleBottomRightNumber: 0,
        perUnitR:0,
        perUnitX:0,
        perUnitK:1,

        lineActiveFlow1: 0,
        lineActiveFlow2: 0,
        lineReactiveFlow1: 0,
        lineReactiveFlow2: 0,
        upTransKind: upTransKind,
        supply: false,
        tcName: "",
        voltageMagnitude1: 1,
        voltageMagnitude2: 1,
        voltageAngle1: 0,
        voltageAngle2: 0,
        inputedCapacity1:false,
        inputedCurrent1:false,
        inputedPowerFactor1:false,
        inputedCapacity2:false,
        inputedCurrent2:false,
        inputedPowerFactor2:false,
        downTransKind:0,
        primarySupply:false,
        examineSomment:"",
        examineCommentSPhase:"",
        seriesed: false,
        cComplex: cComplex,
        volDropRouteRefNo: "",
        isModeSPhases: false
    } as Model.MsBusbarModel;
}

const response2BusductModel = (responseData: any,voltage:number,upTransKind:number,sPhase:boolean,frequency:number) => {
    // const makerNameList:any = [];
    // responseData.makerNameList?.forEach((item:any)=>{
    //     makerNameList.push({label:item.smanuName.toString(),
    //         value:item.smanuName} as OptionModel)
    // })
    // const makeYearList:any = [];
    // responseData.makeYearList?.forEach((item:any)=>{
    //     makeYearList.push({label:item.toString(),
    //         value:item} as OptionModel)
    // })
    // const makeMonthList:any = [];
    // responseData.makeMonthList?.forEach((item:any)=>{
    //     makeMonthList.push({label:item.toString(),
    //         value:item} as OptionModel)
    // })
    const m_3Sphase = {} as Model.IeleBusductSPhase;
    const SPhaseN = {} as Model.IeleBusductSPhase;
    const sPhasePE = {} as Model.IeleBusductSPhase;
    const ratedCurrentList = responseData.ratedCurrentList.sort((rated1:any,rated2:any)=> rated1.lbusductID - rated2.lbusductID)
    
    doSetDefaultBusduct(m_3Sphase,ratedCurrentList,frequency)
    doSetDefaultBusduct(SPhaseN,ratedCurrentList,frequency)
    doSetDefaultBusduct(sPhasePE,ratedCurrentList,frequency)
    
    // const busductList = responseData.busductList
    const cComplex = {
        voltDropSumRe: -99999,
        voltDropSumIm:-99999,
        voltDropSumRe2: -99999,
        voltDropSumIm2:-99999

    } as Model.CComplex

    const  calcPoint0 ={
        loadCurrent: 0,
        isLead: false,
        powerFactor: 1,
        calcKind: Constant.CALC_IK3,
        stdCapacity:0,
        loadCapacity: 0,
        voltage:voltage,
        perZDirect: percentZDefault() as Model.PercentZ,
        perZTotal: percentZDefault() as Model.PercentZ,
        perZDirectMG: percentZDefault() as Model.PercentZ,
        perZTotalMG: percentZDefault() as Model.PercentZ,
        perZMotor: percentZDefault() as Model.PercentZ,
    } as Model.CalcPoint

    const  calcPoint1 ={
        loadCurrent: 0,
        isLead: false,
        powerFactor: 1,
        calcKind: Constant.CALC_IK2,
        stdCapacity:0,
        loadCapacity: 0,
        voltage:voltage,
        perZDirect: percentZDefault() as Model.PercentZ,
        perZTotal: percentZDefault() as Model.PercentZ,
        perZDirectMG: percentZDefault() as Model.PercentZ,
        perZTotalMG: percentZDefault() as Model.PercentZ,
        perZMotor: percentZDefault() as Model.PercentZ,
    } as Model.CalcPoint
    
    const listCompEleMG0 ={} as Model.CompEle
    const listCompEleMG1 ={} as Model.CompEle
    const listCompEle1 ={} as Model.CompEle
    const listCompEle2 ={} as Model.CompEle
    return {
        // tab0 - 基本
        refNo: "バスダクト-" + responseData.data.no,
        noBusDuct: responseData.data.no,
        pointText: 'F' + responseData.data.point,
        voltageList:[],
        voltage: voltage,
        length: 0,
        fault: false,
        viewResultText: true,
        wireSystem: 1,//6
        makeDataInput:false,
        makerName:"",
        makerNameList:[],
        type:"",
        makeYear:"",
        makeYearList:[],
        makeMonth:"",
        makeMonthList:[],
        busductList :[],
        // tab1 - 仕様
        materialCU_AL: 1,
        busductTemp: 95,
        ambientTemp: 40,
        // tab2 電圧線(3相/単相)
        m_3Sphase:m_3Sphase,
        // tab3 電圧降下
        dropMotor: false,
        dropRegular: true,
        
        topLeft: 1,
        leadOrLag1: 0,
        leadOrLag2: 0,

        
        voltageDrop_1: 0,
        voltageDrop: 0,
        voltageDrop_1_2: 0,
        voltageDrop2: 0,
        listUser: [],
        isSourceVoltage:false,
        //tab4 - 潮流解析
        susceptance: 0,
        viewFlowResult:true,

        //tab5 中性線(N相)
        SPhaseN:SPhaseN,

        //tab6 保護接地線(PE相)
        sPhasePE:sPhasePE,

        ratedCurrentList:[],
        sPhase: sPhase,
        checkNG: false,
        earthCode: 0,
        surface: 2,
        
        eleLineNumber: 0,
        eleTopLeftNumber: 0,
        eleBottomRightNumber: 0,
        perUnitR: 0,
        perUnitX: 0,
        perUnitK:1,

        lineActiveFlow1: 0,
        lineActiveFlow2: 0,
        lineReactiveFlow1: 0,
        lineReactiveFlow2: 0,
        upTransKind: upTransKind,
        supply: false,
        tcName: "",
        voltageMagnitude1: 1,
        voltageMagnitude2: 1,
        voltageAngle1: 0,
        voltageAngle2: 0,
        inputedCapacity1:false,
        inputedCurrent1:false,
        inputedPowerFactor1:false,
        inputedCapacity2:false,
        inputedCurrent2:false,
        inputedPowerFactor2:false,
        downTransKind:0,
        primarySupply:false,
        examineSomment:"",
        examineCommentSPhase:"",
        seriesed: false,
        cComplex: cComplex,
        volDropRouteRefNo: "",
        calcPoint0:calcPoint0,
        calcPoint1:calcPoint1,
        listCompEleMG0:listCompEleMG0,
        listCompEleMG1:listCompEleMG1,
        listCompEle1:listCompEle1,
        listCompEle2:listCompEle2,     
        isModeSPhases: false,   
    } as Model.MsBusductModel;
}

const response2ReactorModel = (responseData: any,voltage:number) => {
    // const makerNameList:any = [];
    // responseData.makerNameList?.forEach((item:any)=>{
    //     makerNameList.push({label:item.smanuName.toString(),
    //         value:item.smanuName} as OptionModel)
    // })
    // const makeYearList:any = [];
    // responseData.makeYearList?.forEach((item:any)=>{
    //     makeYearList.push({label:item.toString(),
    //         value:item} as OptionModel)
    // })
    // const makeMonthList:any = [];
    // responseData.makeMonthList?.forEach((item:any)=>{
    //     makeMonthList.push({label:item.toString(),
    //         value:item} as OptionModel)
    // })
    const  calcPoint0 ={
        loadCurrent: 0,
        isLead: false,
        powerFactor: 1,
        calcKind: Constant.CALC_IK3,
        stdCapacity:0,
        loadCapacity: 0,
        voltage:voltage,
        perZDirect: percentZDefault() as Model.PercentZ,
        perZTotal: percentZDefault() as Model.PercentZ,
        perZDirectMG: percentZDefault() as Model.PercentZ,
        perZTotalMG: percentZDefault() as Model.PercentZ,
        perZMotor: percentZDefault() as Model.PercentZ,
    } as Model.CalcPoint

    const  calcPoint1 ={
        loadCurrent: 0,
        isLead: false,
        powerFactor: 1,
        calcKind: Constant.CALC_IK2,
        stdCapacity:0,
        loadCapacity: 0,
        voltage:voltage,
        perZDirect: percentZDefault() as Model.PercentZ,
        perZTotal: percentZDefault() as Model.PercentZ,
        perZDirectMG: percentZDefault() as Model.PercentZ,
        perZTotalMG: percentZDefault() as Model.PercentZ,
        perZMotor: percentZDefault() as Model.PercentZ,
    } as Model.CalcPoint
    const cComplex = {
        voltDropSumRe: -99999,
        voltDropSumIm:-99999,
        voltDropSumRe2: -99999,
        voltDropSumIm2:-99999

    } as Model.CComplex
    return {
        // tab0
        refNo: "限流R-" + responseData.data.no,
        noReactor: responseData.data.no,
        fault: true,
        pointText: 'F' + responseData.data.point,
        viewFlowResult: false,
        viewResultText: true,
        voltageList:[],
        voltage: voltage,
        capacity: 100,
        ratedCurrent: 144.3,
        percentX: 1,
        makeDataInput: false,
        makerName:"",
        makerNameList:[],
        type: "",
        makeYear:"",
        makeYearList:[],
        makeMonth:"",
        makeMonthList:[],
        makeNumber:"",

        // tab1
        dropMotor: false,
        dropRegular: true,
        loadCapacity: 0,
        loadCurrent: 0,
        topLeft: 1,
        leadOrLag: 0,
        powerFactor: 1,
        voltageDrop1: 0,
        voltageDrop: 0,
        listUser: [],
        isSourceVoltage:false,
        calcPoint0 :calcPoint0,
        calcPoint1 :calcPoint1,

        inputCurrent1:false,
        inputCapacity1:false,
        inputPowerFactor1:false,
        downTransKind:0,

        percentR: 0,
        checkNG: false,
        eleLineNumber : 0,
        eleTopLeftNumber: 0,
        eleBottomRight_number: 0,
        perUnitR: 0,
        perUnitX: 0,
        perUnitK: 1,
        lineActiveFlow1: 0,
        lineReactiveFlow1: 0,
        lineActiveFlow2: 0,
        lineReactiveFlow2: 0,
        supply: false,
        tcName: "",
        voltageMagnitude1: 1,
        voltageAngle1: 0,
        voltageMagnitude2: 1,
        voltageAngle2: 0,
        primarySupply: false,
        examineComment: "",
        seriesed: false,
        cComplex: cComplex,
        voltDropRouteRefNo: "",
    } as Model.MsReactorModel;
}

const response2ImpedanceModel = (responseData: any,voltage:number,upTransKind:number,sPhase:boolean,calcPoint0:Model.CalcPoint,calcPoint1:Model.CalcPoint) => {
    const cComplex = {
        voltDropSumRe: -99999,
        voltDropSumIm:-99999,
        voltDropSumRe2: -99999,
        voltDropSumIm2:-99999

    } as Model.CComplex

    
    
    const listCompEleMG0 ={} as Model.CompEle
    const listCompEleMG1 ={} as Model.CompEle
    const listCompEle1 ={} as Model.CompEle
    const listCompEle2 ={} as Model.CompEle
    return {
        // tab1
        refNo: "Z-" + responseData.data.no,
        noImpedance: responseData.data.no,
        pointText: 'F' + responseData.data.point,
        voltageList:[],
        voltage: voltage,
        fault: false,
        // field4: false,
        viewResultText: true,
        inputPercentZ: 1,
        stdCapacity: 10000,
        xr: 0,
        percentZ: 0,
        percentR: 0,
        percentX: 0,
        ohmZ: 0,
        ohmR: 0,
        ohmX: 0,
        // tab2
        dropMotor: false,
        dropRegular: true,
        // loadCapacity: 0,
        // loadCurrent: 0,
        topLeft: 1,
        leadOrLag1: 0,
        leadOrLag2: 0,

        // powerFactor: 1,
        voltageDrop_1: 0,
        voltageDrop: 0,
        voltageDrop_1_2: 0,
        voltageDrop2: 0,
        //tab 3
        flowSusceptance:0,
        viewFlowResult:true,

        listUser: [],
        isSourceVoltage:false,

        
        sPhase: sPhase,
        checkNG: false,
        
        
        eleLineNumber: 0,
        eleTopLeftNumber: 0,
        eleBottomRightNumber: 0,
        perUnitR: 0,
        perUnitX: 0,
        perUnitK:1,

        lineActiveFlow1: 0,
        lineActiveFlow2: 0,
        lineReactiveFlow1: 0,
        lineReactiveFlow2: 0,
        upTransKind: upTransKind,
        supply: false,
        tcName: "",
        voltageMagnitude1: 1,
        voltageMagnitude2: 1,
        voltageAngle1: 0,
        voltageAngle2: 0,
        inputedCapacity1:false,
        inputedCurrent1:false,
        inputedPowerFactor1:false,
        inputedCapacity2:false,
        inputedCurrent2:false,
        inputedPowerFactor2:false,
        downTransKind:0,
        primarySupply:false,
        examineComment:"",
        examineCommentSPhase:"",
        seriesed: false,
        cComplex: cComplex,
        voltDropRouteRefNo: "",
        calcPoint0:calcPoint0,
        calcPoint1:calcPoint1,
        listCompEleMG0:listCompEleMG0,
        listCompEleMG1:listCompEleMG1,
        listCompEle1:listCompEle1,
        listCompEle2:listCompEle2,
    } as Model.MsImpedanceModel;
}

const response2LVCBModel = (responseData: any,voltage:any,upTransKind:number,sPhase:boolean,rotation:number) => {
    let lenParts = responseData.details.parts?.length
    if (lenParts > 0) {
      for (let i = 0; i < lenParts; i++){
        responseData.details.parts[i] = {
            ...responseData.details.parts[i],
            setLock: false,
            partID: i + 1
        }
      }
    }
    const  calcPoint0 ={
        loadCurrent: 0,
        isLead: false,
        powerFactor: 1,
        calcKind: Constant.CALC_IK3,
        stdCapacity:0,
        loadCapacity: 0,
        voltage:voltage,
        perZDirect: percentZDefault() as Model.PercentZ,
        perZTotal: percentZDefault() as Model.PercentZ,
        perZDirectMG: percentZDefault() as Model.PercentZ,
        perZTotalMG: percentZDefault() as Model.PercentZ,
        perZMotor: percentZDefault() as Model.PercentZ,
    } as Model.CalcPoint

    const  calcPoint1 ={
        loadCurrent: 0,
        isLead: false,
        powerFactor: 1,
        calcKind: Constant.CALC_IK2,
        stdCapacity:0,
        loadCapacity: 0,
        voltage:voltage,
        perZDirect: percentZDefault() as Model.PercentZ,
        perZTotal: percentZDefault() as Model.PercentZ,
        perZDirectMG: percentZDefault() as Model.PercentZ,
        perZTotalMG: percentZDefault() as Model.PercentZ,
        perZMotor: percentZDefault() as Model.PercentZ,
    } as Model.CalcPoint
    return {
        // 基本
        noLVCB: responseData.data.no,
        refNo: "MCB-" + responseData.data.no,
        pointText: "F" + responseData.data.point,
        voltageList: [],
        voltage: voltage,
        isOff: false,
        fault: true,
        dropMotor: false,
        dropRegular: false,
        viewResultText: true,
        
        // ModePM
        viewFlowResult: false,
        connectSwitch: false,

        makeDataInput: false,
        makeYearList: [],
        makeYear: null,
        makeMonthList: [],
        makeMonth: null,
        makeNumber: '',

        // 保護協調
        details: {
            base: {
                ...responseData.details.base, 
                ...{
                    kindFlag: Constant.RS_KIND_LVCB,
                    useCT: false,
                    useCC: false,
                    readBase: {
                        canUseCC: responseData.details.base.canUseCC,
                        canUseCT: responseData.details.base.canUseCT
                    }
                }
            },
            parts: responseData.details.parts,
            refNo2: "",
            drawThreeCurve: true
        },
        // シンボル
        symKind: 1,

        listUser: [],

        // others
        sPhase: sPhase,
        rotate: rotation === -90?1:rotation === 90?2:rotation, // TODO
        upTransKind: upTransKind,
        makerName: '',
        checkNG: false,
        supply: false,
        tcName: '',
        lineActiveFlow1: 0,
        lineReactiveFlow1: 0,
        lineActiveFlow2: 0,
        lineReactiveFlow2: 0,
        eleTopLeftNumber: 0,
        eleBottomRightNumber: 0,
        downTransKind: Element.MS_ELEMENT_ALL,
        primarySupply: false,
        examineComment: '',
        examineCommentSPhase: '',
        seriesed: false,
        voltDropSumRe1: MS_COMPLEX_VOLTDROP,
        voltDropSumIm1: MS_COMPLEX_VOLTDROP,
        voltDropSumRe2: MS_COMPLEX_VOLTDROP,
        voltDropSumIm2: MS_COMPLEX_VOLTDROP,
        voltDropRouteRefNo: '',
        calcPoint0:calcPoint0,
        calcPoint1:calcPoint1,
    } as Model.MsLVCBModel;
}

const response2ThermalModel = (responseData: any,voltage:number,upTransKind:number,sPhase:boolean) => {
    let lenParts = responseData.details.parts?.length
    if (lenParts > 0) {
      for (let i = 0; i < lenParts; i++){
        responseData.details.parts[i] = {
            ...responseData.details.parts[i],
            setLock: false,
            partID: i + 1
        }
      }
    }
    const  calcPoint0 ={
        loadCurrent: 0,
        isLead: false,
        powerFactor: 1,
        calcKind: Constant.CALC_IK3,
        stdCapacity:0,
        loadCapacity: 0,
        voltage:voltage,
        perZDirect: percentZDefault() as Model.PercentZ,
        perZTotal: percentZDefault() as Model.PercentZ,
        perZDirectMG: percentZDefault() as Model.PercentZ,
        perZTotalMG: percentZDefault() as Model.PercentZ,
        perZMotor: percentZDefault() as Model.PercentZ,
    } as Model.CalcPoint

    const  calcPoint1 ={
        loadCurrent: 0,
        isLead: false,
        powerFactor: 1,
        calcKind: Constant.CALC_IK2,
        stdCapacity:0,
        loadCapacity: 0,
        voltage:voltage,
        perZDirect: percentZDefault() as Model.PercentZ,
        perZTotal: percentZDefault() as Model.PercentZ,
        perZDirectMG: percentZDefault() as Model.PercentZ,
        perZTotalMG: percentZDefault() as Model.PercentZ,
        perZMotor: percentZDefault() as Model.PercentZ,
    } as Model.CalcPoint
    return {
        // 基本
        noThermal: responseData.data.no,
        refNo: "THR-" + responseData.data.no,
        pointText: "F" + responseData.data.point,
        voltageList: [],
        voltage: voltage,
        isOff: false,
        fault: false,
        dropMotor: false,
        dropRegular: false,
        viewResultText: true,
        
        // ModePM
        viewFlowResult: false,

        makeDataInput: false,

        makeYearList: [],
        makeYear: null,
        makeMonthList: [],
        makeMonth: null,
        makeNumber: '',
        // 保護協調
        details: {
            base: {
                ...responseData.details.base, 
                ...{
                    kindFlag: Constant.RS_KIND_THERMAL,
                    useCT: false,
                    useCC: false,
                    readBase: {
                        canUseCC: responseData.details.base.canUseCC,
                        canUseCT: responseData.details.base.canUseCT
                    }
                }
            },
            parts: responseData.details.parts,
            refNo2: "THR-" + responseData.data.no,
            drawThreeCurve: true
        },
        // シンボル
        symKind: 0,
        listUser: [],

        // others
        makerName: '',
        sPhase: sPhase,
        upTransKind: upTransKind,
        checkNG: false,
        supply: false,
        tcName: '',
        lineActiveFlow1: 0,
        lineReactiveFlow1: 0,
        lineActiveFlow2: 0,
        lineReactiveFlow2: 0,
        downTransKind: Element.MS_ELEMENT_ALL,
        primarySupply: false,
        examineComment: '',
        examineCommentSPhase: '',
        seriesed: false,
        voltDropSumRe1: MS_COMPLEX_VOLTDROP,
        voltDropSumIm1: MS_COMPLEX_VOLTDROP,
        voltDropSumRe2: MS_COMPLEX_VOLTDROP,
        voltDropSumIm2: MS_COMPLEX_VOLTDROP,
        voltDropRouteRefNo: '',
        calcPoint0:calcPoint0,
        calcPoint1:calcPoint1,
    } as Model.MsThermalModel;
}

const response2ThermalCTModel = (responseData: any,voltage:number,upTransKind:number,sPhase:boolean) => {
    let lenParts = responseData.details.parts?.length
    if (lenParts > 0) {
      for (let i = 0; i < lenParts; i++){
        responseData.details.parts[i] = {
            ...responseData.details.parts[i],
            setLock: false,
            partID: i + 1
        }
      }
    }
    const  calcPoint0 ={
        loadCurrent: 0,
        isLead: false,
        powerFactor: 1,
        calcKind: Constant.CALC_IK3,
        stdCapacity:0,
        loadCapacity: 0,
        voltage:voltage,
        perZDirect: percentZDefault() as Model.PercentZ,
        perZTotal: percentZDefault() as Model.PercentZ,
        perZDirectMG: percentZDefault() as Model.PercentZ,
        perZTotalMG: percentZDefault() as Model.PercentZ,
        perZMotor: percentZDefault() as Model.PercentZ,
    } as Model.CalcPoint

    const  calcPoint1 ={
        loadCurrent: 0,
        isLead: false,
        powerFactor: 1,
        calcKind: Constant.CALC_IK2,
        stdCapacity:0,
        loadCapacity: 0,
        voltage:voltage,
        perZDirect: percentZDefault() as Model.PercentZ,
        perZTotal: percentZDefault() as Model.PercentZ,
        perZDirectMG: percentZDefault() as Model.PercentZ,
        perZTotalMG: percentZDefault() as Model.PercentZ,
        perZMotor: percentZDefault() as Model.PercentZ,
    } as Model.CalcPoint
    return {
        // 基本
        noThermalCT: responseData.data.no,
        refNo: "49-" + responseData.data.no,
        pointText: "F" + responseData.data.point,
        voltageList: [],
        voltage: voltage,
        isOff: false,
        fault: false,
        dropMotor: false,
        dropRegular: false,
        viewResultText: true,
        
        // ModePM
        viewFlowResult: false,

        makeDataInput: false,
        makeYearList: [],
        makeYear: null,
        makeMonthList: [],
        makeMonth: null,
        makeNumber: '',
        // 保護協調
        details: {
            base: {
                ...responseData.details.base, 
                ...{
                    kindFlag: Constant.RS_KIND_THERMAL,
                    useCT: true,
                    useCC: false,
                    ctPrimaryList: [],
                    ctPrimary: responseData.ctPrimary,
                    ctSecondaryList: [
                        {label: '1', value: 1},
                        {label: '5', value: 5},
                    ],
                    ctSecondary: 5,
                    readBase: {
                        canUseCC: responseData.details.base.canUseCC,
                        canUseCT: responseData.details.base.canUseCT
                    }
                }
            },
            parts: responseData.details.parts,
            refNo2: "49-" + responseData.data.no,
            drawThreeCurve: true
        },

        listUser: [],
        
        // others
        makerName: '',
        sPhase: sPhase,
        upTransKind: upTransKind,
        checkNG: false,
        supply: false,
        tcName: '',
        lineActiveFlow1: 0,
        lineReactiveFlow1: 0,
        lineActiveFlow2: 0,
        lineReactiveFlow2: 0,
        downTransKind: Element.MS_ELEMENT_ALL,
        primarySupply: false,
        examineComment: '',
        examineCommentSPhase: '',
        seriesed: false,
        voltDropSumRe1: MS_COMPLEX_VOLTDROP,
        voltDropSumIm1: MS_COMPLEX_VOLTDROP,
        voltDropSumRe2: MS_COMPLEX_VOLTDROP,
        voltDropSumIm2: MS_COMPLEX_VOLTDROP,
        voltDropRouteRefNo: '',
        calcPoint0:calcPoint0,
        calcPoint1:calcPoint1,
    } as Model.MsThermalCTModel;
}

const response2MotorGroupModel = (responseData: any,voltage:number,capacity1:number) => {
  
    const  calcPoint0 ={
        loadCurrent: 0,
        isLead: false,
        powerFactor: 1,
        calcKind: Constant.CALC_IK3,
        stdCapacity:0,
        loadCapacity: 0,
        voltage:voltage,
        perZDirect: percentZDefault() as Model.PercentZ,
        perZTotal: percentZDefault() as Model.PercentZ,
        perZDirectMG: percentZDefault() as Model.PercentZ,
        perZTotalMG: percentZDefault() as Model.PercentZ,
        perZMotor: percentZDefault() as Model.PercentZ,
    } as Model.CalcPoint

    const  calcPoint1 ={
        loadCurrent: 0,
        isLead: false,
        powerFactor: 1,
        calcKind: Constant.CALC_IK2,
        stdCapacity:0,
        loadCapacity: 0,
        voltage:voltage,
        perZDirect: percentZDefault() as Model.PercentZ,
        perZTotal: percentZDefault() as Model.PercentZ,
        perZDirectMG: percentZDefault() as Model.PercentZ,
        perZTotalMG: percentZDefault() as Model.PercentZ,
        perZMotor: percentZDefault() as Model.PercentZ,
    } as Model.CalcPoint
     
    let capacity = capacity1
    let subReactance = 0
    let statorResistance = 0
    let rotorResistance = 0
    if(capacity <= 0){

    }else{
    for(const item of responseData.capacityList){
        if(capacity <= item.dcapacity || item.dcapacity === -1){
            subReactance = item.dsubReactance
            statorResistance = item.dstatorResistance
            rotorResistance = item.drotorResistance
            break;
        }
    }
}
    return {
        refNo: 'MG-' + responseData.data.no,
        noMotorGrp: responseData.data.no,
        pointText: 'F' + responseData.data.point,
        voltageList:[],
        voltage: voltage,
        capacityList:responseData.capacityList,
        capacity: capacity,
        ratedCurrent: 0,
        powerFactor: 0.85,
        loadFactor: 1,
        fault: false,
        calcImprovePF: true,
        improveAfterPF: 0.95,
        viewFlowResult:false,
        viewResultText: true,
        percentZCalculate: 0,
        subReactance:subReactance,
        rotorResistance: rotorResistance,
        statorResistance: statorResistance,
        subImpedance: 20.06,
        constant: 0.01392,
        subConstant: 0.02216,
        initialCurrent: 0.007195,
        symmetricalCurrent: 0.004582,
        peakValue: 0.01144,
        // perCentZDirect:0,//radio
        capacity1: capacity,
        xr: 6,
        percentZ: 25,
        percentR: 4.11,
        percentX: 24.66,
        ohmZ: 4000,
        ohmR: 6576,
        ohmX: 39456,
        listUser: [],
        isSourceVoltage:false,

        checkNG:false,
        nodeNumber:0,
        perUnitR: 0,
        perUnitX: 0,
        perUnitK: 1,
        perUnitP: 0,
        perUnitQ: 0,
        isLead: false,
        voltageMagnitude:1,
        voltageAngle:0,
        activePower:0,
        reactivePower:0,
        supply:false,
        primarySupply:false,
        examineComment:"",
        calcPoint0:calcPoint0,
        calcPoint1:calcPoint1,
    } as Model.MsMotorGroupModel;
}

const response2LoadModel = (responseData: any,voltage:number,upTransKind:number,sPhase:boolean) => {
    const voltageHightList:any = [];
    responseData.voltageHightList?.forEach((item:any)=>{
        if(item.lvoltage !== null){
        voltageHightList.push({label:item.lvoltage.toString(),
            value:item.lvoltage} as OptionModel)
        }
    })
    
    return {
        refNo: "L-" + responseData.data.no,
        noLoad:  responseData.data.no,
        voltageHightList:voltageHightList,
        voltageList: [],
        loadCurrentList:[],
        voltage: voltage,
        loadCurrent: 0,
        leadOrLag: 0,//------
        powerFactor: 0.7,
        phaseCurrent: '-45.57',
        loadFactor: 1,
        startingCurrent: 0,
        startingTime: 0,
        calcImprovePF: true,
        improveAfterPF: 0.95,
        viewResultText: true,
        viewFlowResult :false,
        harmonicList : [],
        listUser: [],
        isSourceVoltage: false,

        sPhase: sPhase,
        checkNG: false,
        nodeNumber: 0,
        perUnitR: 0,
        perUnitX:0,
        perUnitK: 1,
        perUnitP: 0,
        perUnitQ: 0,
        voltageMagnitude:1,
        voltageAngle:0,
        activePower:0,
        reactivePower:0,
        upTransKind:upTransKind,
        supply:false,
        tcName:"",
        primarySupply:false,

        // field12: '',
    } as Model.MsLoadModel;
}

const response2LightBoardModel = (responseData: any,voltage:number,upTransKind:number,sPhase:boolean) => {
    // const makerNameList:any = [];
    // responseData.makerNameList?.forEach((item:any)=>{
    //     makerNameList.push({label:item.smanuName.toString(),
    //         value:item.smanuName} as OptionModel)
    // })
    // const makeYearList:any = [];
    // responseData.makeYearList?.forEach((item:any)=>{
    //     makeYearList.push({label:item.toString(),
    //         value:item} as OptionModel)
    // })
    // const makeMonthList:any = [];
    // responseData.makeMonthList?.forEach((item:any)=>{
    //     makeMonthList.push({label:item.toString(),
    //         value:item} as OptionModel)
    // })
    return {
        refNo: "分電盤-" + responseData.data.no,
        noLightBoard: responseData.data.no,
        voltageList: [],
        voltage: voltage,
        totalCapacityUnitList:[],
        totalCapacity: 0,
        totalCapacity1: 0,//temp variable
        powerFactor: 0.85,
        powerFactor1: 0.85,//temp variable
        fullCurrent: 0,
        fullCurrent1: 0,
        phaseCurrent1:"-31.79",
        phaseCurrent: "-31.79",
        loadFactor: 1,
        inputDetail: false,
        light: 0,
        soket: 0,
        FCU: 0,
        other: 0,
        viewResultText: true,
        totalCapacityUnit: "kW",
        lightUnit: "kW",
        soketUnit: "kW",
        FCUUnit: "kW",
        otherUnit: "kW",
        lightPF: 0.85,
        soketPF: 0.85,
        FCUPF: 0.85,
        otherPF: 0.85,
        //Mode PM
        makeDataInput:false,
        viewFlowResult:false,
        makerName:"",
        makerNameList:[],
        type:"",
        makeYear:"",
        makeYearList:[],
        makeMonth:"",
        makeMonthList:[],
        makeNumber:"",
        listUser: [],
        isSourceVoltage: false,

        sPhase: sPhase,
        loadCurrent: 0,
        checkNG: false,
        nodeNumber: 0,
        perUnitR: 0,
        perUnitX:0,
        perUnitK: 0,
        perUnitP: 0,
        perUnitQ: 0,
        voltageMagnitude:1,
        voltageAngle:0,
        activePower:0,
        reactivePower:0,
        upTransKind:upTransKind,
        supply:false,
        tcName:"",
        totalCapacityPf:0,
        primarySupply: false,
        isLead: false
    } as Model.MsLightBoardModel;
}

const response2PowerBoardModel = (responseData: any,voltage:number,upTransKind:number,sPhase:boolean) => {
    // const makerNameList:any = [];
    // responseData.makerNameList?.forEach((item:any)=>{
    //     makerNameList.push({label:item.smanuName.toString(),
    //         value:item.smanuName} as OptionModel)
    // })
    // const makeYearList:any = [];
    // responseData.makeYearList?.forEach((item:any)=>{
    //     makeYearList.push({label:item.toString(),
    //         value:item} as OptionModel)
    // })
    // const makeMonthList:any = [];
    // responseData.makeMonthList?.forEach((item:any)=>{
    //     makeMonthList.push({label:item.toString(),
    //         value:item} as OptionModel)
    // })
    return {
        refNo: "動力盤-" + responseData.data.no,
        noPowerBoard: responseData.data.no,
        voltageList:[],
        voltage: voltage,
        totalCapacity: 0,
        totalCapacity1: 0,//temp variable
        powerFactor: 0.85,
        powerFactor1: 0.85,//temp variable
        fullCurrent: 0,
        fullCurrent1: 0, //temp variable
        phaseCurrent: "-31.79",
        phaseCurrent1: "-31.79",//temp variable
        loadFactor: 1,
        inputDetail: false,
        freezer: 0,
        elevator: 0,
        aircon: 0,
        hygiene: 0,
        capacitor: 0,
        other: 0,
        viewResultText: true,
        totalCapacityUnitList:[],
        totalCapacityUnit: "kVA",
        freezerUnit: "kVA",
        elevatorUnit: "kVA",
        airconUnit: "kVA",
        hygieneUnit: "kVA",
        capacitorUnit: "kVA",
        otherUnit: "kVA",
        freezerPF: 0.85,
        elevatorPF: 0.85,
        airconPF: 0.85,
        hygienePF: 0.85,
        capacitorPF: 0,
        otherPF: 0.85,
        makeDataInput:false,
        viewFlowResult:false,
        makerName:"",
        makerNameList:[],
        type:"",
        makeYear:"",
        makeYearList:[],
        makeMonth:"",
        makeMonthList:[],
        makeNumber:"",
        listUser: [],
        isSourceVoltage: false,

        upTransKind:upTransKind,

        sPhase: sPhase,
        loadCurrent: 0,
        isLead: false,
        checkNG: false,
        nodeNumber: 0,
        perUnitR: 0,
        perUnitX: 0,
        perUnitK: 0,
        perUnitP: 0,
        perUnitQ: 0,
        voltageMagnitude: 1,
        voltageAngle:0,
        supply:false,
        tcName: "",
        totalCapacityPF: 0,
        primarySupply: false,
        activePower: 0,
        reactivePower: 0,

    } as Model.MsPowerBoardModel;
}

const response2CapacitorModel = (responseData: any,voltage:number) => {
    // const makerNameList:any = [];
    // responseData.makerNameList?.forEach((item:any)=>{
    //     makerNameList.push({label:item.smanuName.toString(),
    //         value:item.smanuName} as OptionModel)
    // })
    // const makeYearList:any = [];
    // responseData.makeYearList?.forEach((item:any)=>{
    //     makeYearList.push({label:item.toString(),
    //         value:item} as OptionModel)
    // })
    // const makeMonthList:any = [];
    // responseData.makeMonthList?.forEach((item:any)=>{
    //     makeMonthList.push({label:item.toString(),
    //         value:item} as OptionModel)
    // })
    return {
        refNo: "SC-" + responseData.data.no,
        noCapacitor: responseData.data.no,
        voltageList:[],
        voltage: voltage,
        capacity: 100,
        reactor:6,
        reactorList: [],
        staticCapacity: 0.001989,
        ratedCurrent: 144.3,
        powerFactor: 0,
        phaseCurrent: "+90",
        loadFactor: 1,
        viewFlowResult:false,
        viewResultText: true,
        makeDataInput:false,
        makerName:"",
        makerNameList:[],
        type:"",
        makeYear:"",
        makeYearList:[],
        makeMonth:"",
        makeMonthList:[],
        makeNumber:"",
        //tab2
        ratedCurrent2: 144.3,
        totalOhmR: 0,
        totalOhmX: 0,
        rushCurrent: 0,
        peakTime: 0,
        calcOhmR: true,
        calcOhmX: true,
        listUser: [],
        isSourceVoltage:false,

        isLead: true,
        checkNG: false,
        curveSel: false,
        nodeNumber: 0,
        perUnitR: 0,
        perUnitX: 0,
        perUnitK: 1,
        perUnitP: 0,
        perUnitQ: 0,
        voltageMagnitude: 1,
        voltageAngle: 0,
        activePower: 0,
        reactivePower: 0,
        pageIndex: 0,
        supply: false,
        tcName: "",
        primarySupply: false,
        eleNeutralPointNumber: 0,
        eleLineNumber: 0,
        reactorPerUnitR: 0,
        reactorPerUnitX: 0,
        reactorPerUnitK: 1,
        lineActiveFlow1: 0,
        lineReactiveFlow1: 0,
        lineActiveFlow2: 0,
        lineReactiveFlow2: 0,
    } as Model.MsCapacitorModel;
}

const response2TransCenterModel = (tabId: number, responseData: any,voltage:number,upTransKind:number,sPhase:boolean) => {
    const  calcPoint0 ={
        loadCurrent: 0,
        isLead: false,
        powerFactor: 1,
        calcKind: Constant.CALC_IK3,
        stdCapacity:0,
        loadCapacity: 0,
        voltage:voltage,
        perZDirect: percentZDefault() as Model.PercentZ,
        perZTotal: percentZDefault() as Model.PercentZ,
        perZDirectMG: percentZDefault() as Model.PercentZ,
        perZTotalMG: percentZDefault() as Model.PercentZ,
        perZMotor: percentZDefault() as Model.PercentZ,
    } as Model.CalcPoint

    const  calcPoint1 ={
        loadCurrent: 0,
        isLead: false,
        powerFactor: 1,
        calcKind: Constant.CALC_IK2,
        stdCapacity:0,
        loadCapacity: 0,
        voltage:voltage,
        perZDirect: percentZDefault() as Model.PercentZ,
        perZTotal: percentZDefault() as Model.PercentZ,
        perZDirectMG: percentZDefault() as Model.PercentZ,
        perZTotalMG: percentZDefault() as Model.PercentZ,
        perZMotor: percentZDefault() as Model.PercentZ,
    } as Model.CalcPoint
    return {
        noTransCenter: responseData.data.no,
        // tab1 - 基本
        refNo: "変台-" + responseData.data.no,
        pointText: "F" + responseData.data.point,
        voltageList: [],
        voltage: voltage,

        fault: false,
        viewResultText: true,
        // ModePM
        viewFlowResult: true,

        xr: NaN,
        percentZ: NaN,
        percentR: NaN,
        percentX: NaN,
        ohmZ: NaN,
        ohmR: NaN,
        ohmX: NaN,
        xFrameCount: 35,
        yFrameCount: 21,

        // tab2 - 集計・計算
        thinkOnOff: false,
        togetherLoad: 0,
        powerFactor: 0,
        fullCurrent: 0,
        phaseCurrent: -90,
        maxPower: 0,
        demandFactor: 0,
        periodAveragePower: 0,
        periodMaxPower: 0,
        loadFactor: 0,

        // diagram
        tabId: tabId,

        // others
        sPhase: sPhase,
        upTransKind: upTransKind,
        capacity: 0,
        leadLag: 1,
        checkNG: false,
        supply: false,
        nodeNumber: 0,
        perUnitR: 0,
        perUnitX: 0,
        perUnitK: 0,
        perUnitP: 0,
        perUnitQ: 0,
        diversityFactor: 1,
        voltageMagnitude: 0,
        voltageAngle: 0,
        activePower: 0,
        reactivePower: 0,

        hasElement: false,
        topElementId: NaN,

        left1: '定格容量の合計(kW)',
        left2: '最大需要電力(kW)',
        left3: '負荷率',
        right1: '上位機器名称',
        right2: '電源電圧(V)',
        right3: '電源％インピーダンス(%)',

        primarySupply: false,
        examineComment: '',
        examineCommentSPhase: '',
        seriesed: false,
        voltDropSumRe1: 0,
        voltDropSumIm1: 0,
        voltDropSumRe2: 0,
        voltDropSumIm2: 0,
        voltDropRouteRefNo: '',
        calcPoint0:calcPoint0,
        calcPoint1:calcPoint1,
    } as Model.MSTransCenterModel;
}

const response2VCTModel = (responseData: any,voltage:number) => {
    // const makerNameList:any = [];
    // responseData.makerNameList?.forEach((item:any)=>{
    //     makerNameList.push({label:item.smanuName.toString(),
    //         value:item.smanuName} as OptionModel)
    // })
    // const makeYearList:any = [];
    // responseData.makeYearList?.forEach((item:any)=>{
    //     makeYearList.push({label:item.toString(),
    //         value:item} as OptionModel)
    // })
    // const makeMonthList:any = [];
    // responseData.makeMonthList?.forEach((item:any)=>{
    //     makeMonthList.push({label:item.toString(),
    //         value:item} as OptionModel)
    // })
    return {
        refNo: 'VCT-' + responseData.data.no,
        noVCT: responseData.data.no,
        viewResultText: true,
        voltageList:[],
        voltage:voltage,
        viewFlowResult:false,
        makeDataInput: false,
        makerName:"",
        makerNameList:[],
        type: "",
        makeYear:"",
        makeYearList:[],
        makeMonth:"",
        makeMonthList:[],
        makeNumber:"",
        symKind: 0,
        listUser: [],
        isSourceVoltage:false,

        checkNG: false,
        supply: false,
        tcName: "",
        lineActiveFlow1: 0,
        lineReactiveFlow1: 0,
        lineActiveFlow2: 0,
        lineReactiveFlow2: 0,
        primarySupply: false,
        seriesed: false,

    } as Model.MsVCTModel;
}

const response2VTModel = (responseData: any,voltage:number) => {
    // const makerNameList:any = [];
    // responseData.makerNameList?.forEach((item:any)=>{
    //     makerNameList.push({label:item.smanuName.toString(),
    //         value:item.smanuName} as OptionModel)
    // })
    // const makeYearList:any = [];
    // responseData.makeYearList?.forEach((item:any)=>{
    //     makeYearList.push({label:item.toString(),
    //         value:item} as OptionModel)
    // })
    // const makeMonthList:any = [];
    // responseData.makeMonthList?.forEach((item:any)=>{
    //     makeMonthList.push({label:item.toString(),
    //         value:item} as OptionModel)
    // })
    return {
        refNo: 'VT-' + responseData.data.no,
        noVT: responseData.data.no,
        viewResultText: true,
        voltageList:[],
        voltage: voltage,
        viewFlowResult:false,
        makeDataInput: false,
        makerName:"",
        makerNameList:[],
        type: "",
        makeYear:"",
        makeYearList:[],
        makeMonth:"",
        makeMonthList:[],
        makeNumber:"",
        listUser: [],
        isSourceVoltage:false,

        checkNG: false,
        exchangeNumber: 0,
        supply: false,
        tcName: "",
        lineActiveFlow1: 0,
        lineReactiveFlow1: 0,
        lineActiveFlow2: 0,
        lineReactiveFlow2: 0,
        primarySupply: false,
        seriesed: false,
    } as Model.MSVTModel;
}

const response2ArrestorModel = (responseData: any,voltage:number) => {
    // const makerNameList:any = [];
    // responseData.makerNameList?.forEach((item:any)=>{
    //     makerNameList.push({label:item.smanuName.toString(),
    //         value:item.smanuName} as OptionModel)
    // })
    // const makeYearList:any = [];
    // responseData.makeYearList?.forEach((item:any)=>{
    //     makeYearList.push({label:item.toString(),
    //         value:item} as OptionModel)
    // })
    // const makeMonthList:any = [];
    // responseData.makeMonthList?.forEach((item:any)=>{
    //     makeMonthList.push({label:item.toString(),
    //         value:item} as OptionModel)
    // })
    return {
        refNo: 'LA-' + responseData.data.no,
        noArrestor: responseData.data.no,
        voltageList:[],
        voltage: voltage,
        makeDataInput: false,
        makerName:"",
        makerNameList:[],
        type: "",
        makeYear:"",
        makeYearList:[],
        makeMonth:"",
        makeMonthList:[],
        makeNumber:"",
        symKind: 0,
        haveEarth: true,//isGrounded

        listUser: [],
        isSourceVoltage: false,
        checkNG: false,
        supply: false,
        tcName: "",
        
        primarySupply: false,
    } as Model.MsArrestorModel;
}

const response2EarthModel = (responseData: any,voltage:number) => {
    // const makerNameList:any = [];
    // responseData.makerNameList?.forEach((item:any)=>{
    //     makerNameList.push({label:item.smanuName.toString(),
    //         value:item.smanuName} as OptionModel)
    // })
    // const makeYearList:any = [];
    // responseData.makeYearList?.forEach((item:any)=>{
    //     makeYearList.push({label:item.toString(),
    //         value:item} as OptionModel)
    // })
    // const makeMonthList:any = [];
    // responseData.makeMonthList?.forEach((item:any)=>{
    //     makeMonthList.push({label:item.toString(),
    //         value:item} as OptionModel)
    // })
    return {
        refNo: 'E-' + responseData.data.no,
        noEarth: responseData.data.no,
        voltageList:[],
        voltage: voltage,
        makeDataInput: false,
        makerName:"",
        makerNameList:[],
        type: "",
        makeYear:"",
        makeYearList:[],
        makeMonth:"",
        makeMonthList:[],
        makeNumber:"",
        symKind: 0,
        isGrounded: true,
        isSourceVoltage:false,
        checkNG: false,
        supply: false,
        tcName: "",
        primarySupply: false,

    } as Model.MsEarthModel;
}

const response2CableHeadModel = (responseData: any,voltage:number,upTransKind:number,sPhase:boolean) => {
    return {
        refNo: 'CH-' + responseData.data.no,
        noCableHead: responseData.data.no,
        voltageList:[],
        voltage: voltage,
        symKind: 0,
        isSourceVoltage: false,
        sPhase: sPhase,
        checkNG: false,
        upTransKind: upTransKind,
        supply: false,
        tcName: "",
        makerName: "",
        makeYear: "",
        makeNumber: "",
        primarySupply: false,
        seriesed: false,
    } as Model.MsCableHeadModel;
}

const response2CTModel = (responseData: any,voltage:number,upTransKind:number,sPhase:boolean) => {
    // const makerNameList:any = [];
    // responseData.makerNameList?.forEach((item:any)=>{
    //     makerNameList.push({label:item.smanuName.toString(),
    //         value:item.smanuName} as OptionModel)
    // })
    // const makeYearList:any = [];
    // responseData.makeYearList?.forEach((item:any)=>{
    //     makeYearList.push({label:item.toString(),
    //         value:item} as OptionModel)
    // })
    // const makeMonthList:any = [];
    // responseData.makeMonthList?.forEach((item:any)=>{
    //     makeMonthList.push({label:item.toString(),
    //         value:item} as OptionModel)
    // })
    return {
        refNo: 'CT-' + responseData.data.no,
        noCT: responseData.data.no,
        viewResultText: true,
        voltageList:[],
        voltage: voltage,
        viewFlowResult:false,
        makeDataInput: false,
        makerName:"",
        makerNameList:[],
        type: "",
        makeYear:"",
        makeYearList:[],
        makeMonth:"",
        makeMonthList:[],
        makeNumber:"",
        listUser: [],
        isSourceVoltage: false,
        sPhase: sPhase,
        checkNG: false,
        exchangeNumber: 0,
        upTransKind: upTransKind,
        supply: false,
        tcName: "",
        lineActiveFlow1: 0,
        lineReactiveFlow1: 0,
        lineActiveFlow2: 0,
        lineReactiveFlow2: 0,
        primarySupply: false,
        seriesed:false,
    } as Model.MsCTModel;
}

const response2ZCTModel = ( responseData: any,voltage:number) => {
    // const makerNameList:any = [];
    // responseData.makerNameList?.forEach((item:any)=>{
    //     makerNameList.push({label:item.smanuName.toString(),
    //         value:item.smanuName} as OptionModel)
    // })
    // const makeYearList:any = [];
    // responseData.makeYearList?.forEach((item:any)=>{
    //     makeYearList.push({label:item.toString(),
    //         value:item} as OptionModel)
    // })
    // const makeMonthList:any = [];
    // responseData.makeMonthList?.forEach((item:any)=>{
    //     makeMonthList.push({label:item.toString(),
    //         value:item} as OptionModel)
    // })
    return {
        refNo: 'ZCT-' + responseData.data.no,
        noZCT: responseData.data.no,
        viewResultText: true,
        voltageList:[],
        voltage: voltage,
        viewFlowResult:false,
        makeDataInput: false,
        makerName:"",
        makerNameList: [],
        type: "",
        makeYear:"",
        makeYearList:[],
        makeMonth:"",
        makeMonthList:[],
        makeNumber:"",
        listUser: [],
        isSourceVoltage: false,
        checkNG: false,
        exchangeNumber: 0,
        supply: false,
        tcName: "",
        lineActiveFlow1: 0,
        lineReactiveFlow1: 0,
        lineActiveFlow2: 0,
        lineReactiveFlow2: 0,
        primarySupply: false,
        seriesed: false,
    } as Model.MSZCTModel;
}

const response2INVModel = (responseData: any,voltage:number) => {
    // const makerNameList:any = [];
    // responseData.makerNameList?.forEach((item:any)=>{
    //     makerNameList.push({label:item.smanuName.toString(),
    //         value:item.smanuName} as OptionModel)
    // })
    // const makeYearList:any = [];
    // responseData.makeYearList?.forEach((item:any)=>{
    //     makeYearList.push({label:item.toString(),
    //         value:item} as OptionModel)
    // })
    // const makeMonthList:any = [];
    // responseData.makeMonthList?.forEach((item:any)=>{
    //     makeMonthList.push({label:item.toString(),
    //         value:item} as OptionModel)
    // })
    return {
        refNo : 'INV-' + responseData.data.no,
        noINV: responseData.data.no,
        voltage: voltage,
        voltageList : [],
        checkNG : false,
        supply : false,
        viewFlowResult : false,
        tcName : '',
        makeDataInput : false,
        makerName :   '',
        makerNameList : [],
        type : "",
        makeYear : "",
        makeYearList : [],
        makeMonth : "",
        makeMonthList : [],
        makeNumber : "",
        primarySupply : false,
        seriesed : false,
        viewResultText: true,
        harmonicList : [],
        listUser: [],
        isSourceVoltage:false,
        exchangeNumber: 0,
        lineActiveFlow1: 0,
        lineReactiveFlow1: 0,
        lineActiveFlow2: 0,
        lineReactiveFlow2: 0,
    } as Model.MsINVModel;
}

const response2RelayModel = (responseData: any,voltage:number,upTransKind:number,sPhase:boolean) => {
    // const makerNameList:any = [];
    // responseData.makerNameList?.forEach((item:any)=>{
    //     makerNameList.push({label:item.smanuName.toString(),
    //         value:item.smanuName} as OptionModel)
    // })
    // const makeYearList:any = [];
    // responseData.makeYearList?.forEach((item:any)=>{
    //     makeYearList.push({label:item.toString(),
    //         value:item} as OptionModel)
    // })
    // const makeMonthList:any = [];
    // responseData.makeMonthList?.forEach((item:any)=>{
    //     makeMonthList.push({label:item.toString(),
    //         value:item} as OptionModel)
    // })
    return {
        refNo: 'Ry-' + responseData.data.no,
        noRelay: responseData.data.no,
        viewResultText: true,
        voltageList:[],
        voltage: voltage,
        viewFlowResult:false,
        makeDataInput: false,
        makerName:"",
        makerNameList:[],
        type: "",
        makeYear:"",
        makeYearList:[],
        makeMonth:"",
        makeMonthList:[],
        makeNumber:"",
        symKind: 0,
        subText: '51',
        listUser: [],
        isSourceVoltage:false,
        checkNG: false,
        supply: false,
        tcName: "",
        lineActiveFlow1: 0,
        lineReactiveFlow1: 0,
        lineActiveFlow2: 0,
        lineReactiveFlow2: 0,
        primarySupply: false,
        seriesed: false,
    } as Model.MsRelayModel;
}



// -------------------------------
// create element from response data
// -------------------------------
// 電源生成
const createSource = (id: number, currentPosition: Coordinates, responseData: any) => {
    const properties = response2SourceModel(responseData);
    
    const element = {
        id: id.toString(),
        image: imageSource_0,
        ...currentPosition,
        width: 20,
        height: 60,
        offsetX: 0,
        offsetY: 0,
        type: Element.MS_SOURCE,
        isSelected: true,
        rotation: 0,
        chart: [],
        isRotationEnabled: false,
        parentGroupId: null,
        isOverlapping: false,
        raw: [],
        properties: properties,
    } as ControlModel;
    return element;
}
// 過電流継電器生成
const createHVCB = (id: number, currentPosition: Coordinates, responseData: any,shapes:any,currentTabId:number,rotation:number) => {
    let voltage = responseData.voltage
    let result = {} as any
    if(currentTabId !==1){
        //inside transcenter
        result  = doGetTransCenterSourceVolt(
            shapes,
            {id: id.toString(),
                image: imageHVCB_0,
                ...currentPosition,
                y: rotation === -90 ?currentPosition.y - 20:currentPosition.y,
                width: 40,
                height: 80,
                offsetX: rotation === -90 ? 1*40:0,
                offsetY: rotation === 90 ? 1*80:0,
                type: Element.MS_HVCB,
                isSelected: true,
                rotation: rotation,
                chart: [],
                isRotationEnabled: true,
                parentGroupId: null,
                raw: [],
                properties: responseData},
                Constant.GET_TOP_BOTTOM_LEFT_RIGHT,

        )
        if(result.voltage > 0){
            voltage = result.voltage
        }
    }
    else{
        //outside transcenter
        result = doGetSourceVolt(
        {
            id: id.toString(),
            image: imageHVCB_0,
            ...currentPosition,
            y: rotation === -90 ?currentPosition.y - 20:currentPosition.y,
            width: 40,
            height: 80,
            offsetX: rotation === -90 ? 1*40:0,
            offsetY: rotation === 90 ? 1*80:0,
            type: Element.MS_HVCB,
            isSelected: true,
            rotation: rotation,
            chart: [],
            isRotationEnabled: true,
            parentGroupId: null,
            raw: [],
            properties: responseData,
        },
        shapes,
        Constant.GET_TOP_BOTTOM_LEFT_RIGHT
      )
      if(result.voltage > 0){
        voltage = result.voltage
      }
    }

    const properties = response2HVCBModel(responseData,voltage,rotation);
    
    const element = {
        id: id.toString(),
        image: imageHVCB_0,
        ...currentPosition,
        y: rotation === -90 ?currentPosition.y - 20:currentPosition.y,
        width: 40,
        height: 80,
        offsetX: rotation === -90 ? 1*40:0,
        offsetY: rotation === 90 ? 1*80:0,
        type: Element.MS_HVCB,
        isSelected: true,
        rotation: rotation,
        chart: [],
        isRotationEnabled: true,
        parentGroupId: null,
        isOverlapping: false,
        raw: [],
        properties: properties,
    } as ControlModel;
    return element;
}
// 電動機生成
const createMotor = (id: number, currentPosition: Coordinates, responseData: any,shapes:any,currentTabId:number) => {
    let voltage = responseData.voltLowDefaultItem === null ?0: responseData.voltLowDefaultItem.lvoltage
    let upTransKind = 0
    let sPhase = false
    let result ={} as any
    if(currentTabId !==1){
        result = doGetTransCenterSourceVolt(
            shapes,
            {id: id.toString(),
                image: imageMotor_1,
                ...currentPosition,
                width: 20,
                height: 60,
                offsetX: 0,
                offsetY: 0,
                type: Element.MS_MOTOR,
                isSelected: true,
                rotation: 0,
                chart: [],
                isRotationEnabled: false,
                parentGroupId: null,
                properties: responseData},
                Constant.GET_TOP,
                false,
                0
        )
        if(result.voltage >0){
            voltage = result.voltage
            upTransKind = result.upTransKind??0
            sPhase = result.sPhase??false
        }
            }
    else{
        result = doGetSourceVolt(
            {
                id: id.toString(),
            image: imageMotor_1,
            ...currentPosition,
            width: 20,
            height: 60,
            offsetX: 0,
            offsetY: 0,
            type: Element.MS_MOTOR,
            isSelected: true,
            rotation: 0,
            chart: [],
            isRotationEnabled: false,
            parentGroupId: null,
            properties: responseData,
            },
            shapes,
            Constant.GET_TOP,
            false,
            0
        )
        if(result.voltage > 0){
            voltage = result.voltage
            upTransKind = result.upTransKind
            sPhase = result.sPhase
        }
    }
    const properties = response2MotorModel(responseData,voltage,upTransKind,sPhase);
    const element = {
        id: id.toString(),
        image: imageMotor_1,
        ...currentPosition,
        width: 20,
        height: 60,
        offsetX: 0,
        offsetY: 0,
        type: Element.MS_MOTOR,
        isSelected: true,
        rotation: 0,
        chart: [],
        isRotationEnabled: false,
        parentGroupId: null,
        isOverlapping: false,
        properties: properties,
    } as ControlModel;
    return element;
}

// ---------------------------------------
// create element from local data
// ---------------------------------------

const createMsGenerator = (id: number, currentPosition: Coordinates, responseData: any,frequency:number,stdCapacity:number) => {
    const properties = response2GeneratorModel(responseData,frequency,stdCapacity);
    return {
        id: id.toString(),
        image: imageGenerator,
        ...currentPosition,
        width: 20,
        height: 60,
        offsetX: 0,
        offsetY: 0,
        type: Element.MS_GENERATOR,
        isSelected: true,
        rotation: 0,
        chart: [],
        isRotationEnabled: false,
        parentGroupId: null,
        isOverlapping: false,
        properties: properties,
    } as ControlModel;
};

const createMsTrans3 = (id: number, currentPosition: Coordinates, responseData: any,shapes:any,currentTabId:number,frequency:number) => {
    let voltage = responseData.voltHighDefault
    let voltage2 = voltage>= VOLT_SIDE_SPECIAL_HIGH_MIN
            ? responseData.voltHighDefault
            : responseData.voltLowDefault
    let result = {} as any
    let result2 = {} as any

    if(currentTabId !==1){
        //inside transcenter
        //voltagePrimary
        result = doGetTransCenterSourceVolt(
            shapes,
            {id: id.toString(),
                image: imageTrans3_1_F,
                ...currentPosition,
                width: 20,
                height: 80,
                offsetX: 0,
                offsetY: 0,
                type: Element.MS_TRANS3,
                isSelected: true,
                rotation: 0,
                chart: [],
                isRotationEnabled: false,
                parentGroupId: null,
                properties: responseData},
                Constant.GET_TOP

        )
        if(result.voltage > 0){
            voltage = result.voltage
        }
        voltage2 = voltage>= VOLT_SIDE_SPECIAL_HIGH_MIN
            ? responseData.voltHighDefault
            : responseData.voltLowDefault
        //voltageSecondary
        result2  = doGetTransCenterSourceVolt(
                shapes,
                {id: id.toString(),
                    image: imageTrans3_1_F,
                    ...currentPosition,
                    width: 20,
                    height: 80,
                    offsetX: 0,
                    offsetY: 0,
                    type: Element.MS_TRANS3,
                    isSelected: true,
                    rotation: 0,
                    chart: [],
                    isRotationEnabled: false,
                    parentGroupId: null,
                    properties: responseData},
                    Constant.GET_BOTTOM
    
            )
            if(result2.voltage > 0){
                voltage2 = result2.voltage
            }
    }
    else{
        //outside transcenter
        //voltagePrimary
        result = doGetSourceVolt(
        {
            id: id.toString(),
            image: imageTrans3_1_F,
            ...currentPosition,
            width: 20,
            height: 80,
            offsetX: 0,
            offsetY: 0,
            type: Element.MS_TRANS3,
            isSelected: true,
            rotation: 0,
            chart: [],
            isRotationEnabled: false,
            parentGroupId: null,
            properties: responseData,
        },
        shapes,
        Constant.GET_TOP
      )
      if(result.voltage > 0){
        voltage = result.voltage
      }
      voltage2 = voltage>= VOLT_SIDE_SPECIAL_HIGH_MIN
            ? responseData.voltHighDefault
            : responseData.voltLowDefault
        //voltageSecondary
      result2 = doGetSourceVolt(
        {
            id: id.toString(),
            image: imageTrans3_1_F,
            ...currentPosition,
            width: 20,
            height: 80,
            offsetX: 0,
            offsetY: 0,
            type: Element.MS_TRANS3,
            isSelected: true,
            rotation: 0,
            chart: [],
            isRotationEnabled: false,
            parentGroupId: null,
            properties: responseData,
        },
        shapes,
        Constant.GET_BOTTOM
      )
      if(result2.voltage > 0){
        voltage2 = result2.voltage
      }
    }
    const properties = response2Trans3Model(responseData,voltage,voltage2,frequency);
    return {
        id: id.toString(),
        image: imageTrans3_1_F,
        ...currentPosition,
        width: 20,
        height: 80,
        offsetX: 0,
        offsetY: 0,
        type: Element.MS_TRANS3,
        isSelected: true,
        rotation: 0,
        chart: [],
        isRotationEnabled: false,
        parentGroupId: null,
        isOverlapping: false,
        properties: properties,
    } as ControlModel;
};

const createMs3Winding = (id: number, currentPosition: Coordinates, responseData: any,shapes:any,currentTabId:number,frequency:number,stdCapacity:number) => {

    let voltage1 = 0
    let voltage2 = 0
    let voltage3 = 0
    let result = {} as any
    let result2 = {} as any
    let result3 = {} as any
    if(currentTabId !==1){
        //inside transcenter
        //voltaPrimary
        result = doGetTransCenterSourceVolt(
            shapes,
            {id: id.toString(),
                image: image3Winding,
                ...currentPosition,
                width: 20,
                height: 160,
                offsetX: 0,
                offsetY: 0,
                type: Element.MS_3WINDING,
                isSelected: true,
                rotation: 0,
                chart: [],
                isRotationEnabled: false,
                parentGroupId: null,
                properties: responseData},
                Constant.GET_TOP

        )
        //voltaSecondary
        result2 = doGetTransCenterSourceVolt(
            shapes,
            {id: id.toString(),
                image: image3Winding,
                ...currentPosition,
                width: 20,
                height: 160,
                offsetX: 0,
                offsetY: 0,
                type: Element.MS_3WINDING,
                isSelected: true,
                rotation: 0,
                chart: [],
                isRotationEnabled: false,
                parentGroupId: null,
                properties: responseData},
                Constant.GET_RIGHT

        )
        //voltaThird
        result3 = doGetTransCenterSourceVolt(
            shapes,
            {id: id.toString(),
                image: image3Winding,
                ...currentPosition,
                width: 20,
                height: 160,
                offsetX: 0,
                offsetY: 0,
                type: Element.MS_3WINDING,
                isSelected: true,
                rotation: 0,
                chart: [],
                isRotationEnabled: false,
                parentGroupId: null,
                properties: responseData},
                Constant.GET_LEFT

        )
        if(result.voltage > 0){
            voltage1 = result.voltage
        }
        if(result2.voltage > 0){
            voltage2 = result2.voltage
        }
        if(result3.voltage > 0){
            voltage3 = result3.voltage
        }
        }
    else{
        //outside transcenter
        //voltaPrimary
        result = doGetSourceVolt(
            {
            id: id.toString(),
            image: image3Winding,
            ...currentPosition,
            width: 20,
            height: 160,
            offsetX: 0,
            offsetY: 0,
            type: Element.MS_3WINDING,
            isSelected: true,
            rotation: 0,
            chart: [],
            isRotationEnabled: false,
            parentGroupId: null,
            properties: responseData,
            },
            shapes,
            Constant.GET_TOP
        )
        if(result.voltage > 0){
            voltage1 = result.voltage
        }
        //voltaSecondary
        result2 = doGetSourceVolt(
            {
            id: id.toString(),
            image: image3Winding,
            ...currentPosition,
            width: 20,
            height: 160,
            offsetX: 0,
            offsetY: 0,
            type: Element.MS_3WINDING,
            isSelected: true,
            rotation: 0,
            chart: [],
            isRotationEnabled: false,
            parentGroupId: null,
            properties: responseData,
            },
            shapes,
            Constant.GET_RIGHT
        )
        if(result2.voltage > 0){
            voltage2 = result2.voltage
        }
        //voltaThird
        result3 = doGetSourceVolt(
            {
            id: id.toString(),
            image: image3Winding,
            ...currentPosition,
            width: 20,
            height: 160,
            offsetX: 0,
            offsetY: 0,
            type: Element.MS_3WINDING,
            isSelected: true,
            rotation: 0,
            chart: [],
            isRotationEnabled: false,
            parentGroupId: null,
            properties: responseData,
            },
            shapes,
            Constant.GET_LEFT
        )
        if(result3.voltage > 0){
            voltage3 = result3.voltage
        }
    }
    const properties = response23WindingModel(responseData,voltage1,voltage2,voltage3,frequency,stdCapacity);
    return {
        id: id.toString(),
        image: image3Winding,
        ...currentPosition,
        width: 20,
        height: 160,
        offsetX: 0,
        offsetY: 0,
        type: Element.MS_3WINDING,
        isSelected: true,
        rotation: 0,
        chart: [],
        isRotationEnabled: false,
        parentGroupId: null,
        isOverlapping: false,
        properties: properties,
    } as ControlModel;
};

const createMsTrans1 = (id: number, currentPosition: Coordinates, responseData: any,shapes:any,currentTabId:number,frequency:number) => {
    let voltage1 = responseData.voltHighDefault
    let voltage2 = 210
    let result = {} as any
    let result2 = {} as any

    if(currentTabId !==1){
        //inside transceter
        //voltagePrimary
        result = doGetTransCenterSourceVolt(
            shapes,
            {id: id.toString(),
                image: imageTrans1_1,
                ...currentPosition,
                width: 20,
                height: 120,
                offsetX: 0,
                offsetY: 0,
                type: Element.MS_TRANS1,
                isSelected: true,
                rotation: 0,
                chart: [],
                isRotationEnabled: false,
                parentGroupId: null,
                properties: responseData},
                Constant.GET_TOP

        )
        if(result.voltage > 0){
            voltage1 = result.voltage
        }
        //voltageSecondary
         result2 = doGetTransCenterSourceVolt(
                shapes,
                {id: id.toString(),
                    image: imageTrans1_1,
                    ...currentPosition,
                    width: 20,
                    height: 120,
                    offsetX: 0,
                    offsetY: 0,
                    type: Element.MS_TRANS1,
                    isSelected: true,
                    rotation: 0,
                    chart: [],
                    isRotationEnabled: false,
                    parentGroupId: null,
                    properties: responseData},
                    Constant.GET_BOTTOM
    
            )
            if(result2.voltage > 0){
                voltage2 = result2.voltage
            }
            }
    else{
        //outside transcenter
        //voltagePrimary
        result = doGetSourceVolt(
                {
                    id: id.toString(),
                    image: imageTrans1_1,
                    ...currentPosition,
                    width: 20,
                    height: 120,
                    offsetX: 0,
                    offsetY: 0,
                    type: Element.MS_TRANS1,
                    isSelected: true,
                    rotation: 0,
                    chart: [],
                    isRotationEnabled: false,
                    parentGroupId: null,
                    properties: responseData,
                },
                shapes,
                Constant.GET_TOP
            )
            if(result.voltage > 0){
                voltage1 = result.voltage
            }
        //voltageSecondary
            result2 = doGetSourceVolt(
                {
                    id: id.toString(),
                    image: imageTrans1_1,
                    ...currentPosition,
                    width: 20,
                    height: 120,
                    offsetX: 0,
                    offsetY: 0,
                    type: Element.MS_TRANS1,
                    isSelected: true,
                    rotation: 0,
                    chart: [],
                    isRotationEnabled: false,
                    parentGroupId: null,
                    properties: responseData,
                },
                shapes,
                Constant.GET_BOTTOM
                )
                if(result2.voltage > 0){
                voltage2 = result2.voltage
                }
    }
    const properties = response2Trans1Model(responseData,voltage1,voltage2,frequency);
    return {
        id: id.toString(),
        image: imageTrans1_1,
        ...currentPosition,
        width: 20,
        height: 120,
        offsetX: 0,
        offsetY: 0,
        type: Element.MS_TRANS1,
        isSelected: true,
        rotation: 0,
        chart: [],
        isRotationEnabled: false,
        parentGroupId: null,
        isOverlapping: false,
        properties: properties,
    } as ControlModel;
};

const createMsTransscott = (id: number, currentPosition: Coordinates, responseData: any,shapes:any,currentTabId:number,frequency:number) => {
    let voltage1 = responseData.voltHighDefault
    let voltage2 = 210
    let result = {} as any
    let result2 = {} as any
    if(currentTabId !==1){
        //inside transcenter
        //voltagePrimary
        result = doGetTransCenterSourceVolt(
            shapes,
            {id: id.toString(),
                image: imageTransscott,
                ...currentPosition,
                width: 20,
                height: 120,
                offsetX: 0,
                offsetY: 0,
                type: Element.MS_TRANSSCOTT,
                isSelected: true,
                rotation: 0,
                chart: [],
                isRotationEnabled: false,
                parentGroupId: null,
                properties: responseData},
                Constant.GET_TOP

        )
        //voltageSecondary
        result2 = doGetTransCenterSourceVolt(
            shapes,
            {id: id.toString(),
                image: imageTransscott,
                ...currentPosition,
                width: 20,
                height: 120,
                offsetX: 0,
                offsetY: 0,
                type: Element.MS_TRANSSCOTT,
                isSelected: true,
                rotation: 0,
                chart: [],
                isRotationEnabled: false,
                parentGroupId: null,
                properties: responseData},
                Constant.GET_BOTTOM

        )
        if(result.voltage > 0){
            voltage1 = result.voltage
        }
        if(result2.voltage > 0){
            voltage2 = result2.voltage
        }
            }
    else{
        //outside transcenter
        //voltagePrimary
        result = doGetSourceVolt(
        {
            id: id.toString(),
            image: imageTransscott,
            ...currentPosition,
            width: 20,
            height: 120,
            offsetX: 0,
            offsetY: 0,
            type: Element.MS_TRANSSCOTT,
            isSelected: true,
            rotation: 0,
            chart: [],
            isRotationEnabled: false,
            parentGroupId: null,
            properties: responseData,
        },
        shapes,
        Constant.GET_TOP
      )
      if(result.voltage > 0){
        voltage1 = result.voltage
      }

        //voltageSecondary
      result2 = doGetSourceVolt(
        {
            id: id.toString(),
            image: imageTransscott,
            ...currentPosition,
            width: 20,
            height: 120,
            offsetX: 0,
            offsetY: 0,
            type: Element.MS_TRANSSCOTT,
            isSelected: true,
            rotation: 0,
            chart: [],
            isRotationEnabled: false,
            parentGroupId: null,
            properties: responseData,
        },
        shapes,
        Constant.GET_BOTTOM
      )
      if(result2.voltage > 0){
        voltage2 = result2.voltage
      }
    }
    const properties = response2TransScottModel(responseData,voltage1,voltage2,frequency);
    return {
        id: id.toString(),
        image: imageTransscott,
        ...currentPosition,
        width: 20,
        height: 120,
        offsetX: 0,
        offsetY: 0,
        type: Element.MS_TRANSSCOTT,
        isSelected: true,
        rotation: 0,
        chart: [],
        isRotationEnabled: false,
        parentGroupId: null,
        isOverlapping: false,
        properties: properties,
    } as ControlModel;
};

const createMsDS = (id: number, currentPosition: Coordinates, responseData: any,shapes:any,currentTabId:any,rotation:number) => {
    let voltage = responseData.voltage
    let refNo = 'DS-' + responseData.data.no
    //Open Transcenter
    let reuslt ={} as any 
    if(currentTabId !==1){
        //inside transcenter
        reuslt = doGetTransCenterSourceVolt(
            shapes,
            {id: id.toString(),
                image: imageDS,
                ...currentPosition,
                width: 20,
                height: 80,
                type: Element.MS_DS,
                isSelected: true,
                parentGroupId: null,
                offsetX: rotation === -90 ? 1*20:0,
                offsetY: rotation === 90 ? 1*80:0,
                rotation: rotation,
                chart: [],
                isRotationEnabled: true,
                properties: responseData},
                 Constant.GET_TOP_BOTTOM_LEFT_RIGHT,

        )
        if(reuslt.voltage > VOLT_SIDE_SPECIAL_HIGH_MIN){
            voltage = reuslt.voltage
            refNo = 'LS-' + responseData.data.no       

        }
        else if(reuslt.voltage < VOLT_SIDE_SPECIAL_HIGH_MIN){
            if(reuslt.voltage > 0){
                voltage = reuslt.voltage
            }
            else{
                voltage = responseData.voltage
            }
            refNo = 'DS-' + responseData.data.no
        }
        
      }
      else{
        //outside transcenter

        reuslt = doGetSourceVolt(
            {
                id: id.toString(),
                image: imageDS,
                ...currentPosition,
                width: 20,
                height: 80,
                type: Element.MS_DS,
                isSelected: true,
                parentGroupId: null,
                offsetX: rotation === -90 ? 1*20:0,
                offsetY: rotation === 90 ? 1*80:0,
                rotation: rotation,
                chart: [],
                isRotationEnabled: true,
            properties: responseData,
            },
            shapes,
            Constant.GET_TOP_BOTTOM_LEFT_RIGHT
        )

        if(reuslt.voltage > VOLT_SIDE_SPECIAL_HIGH_MIN){
            voltage = reuslt.voltage
            refNo = 'LS-' + responseData.data.no       
        }
        else if(reuslt.voltage < VOLT_SIDE_SPECIAL_HIGH_MIN && reuslt.voltage > 0){
            if(reuslt.voltage > 0){
                voltage = reuslt.voltage
            }
            else{
                voltage = responseData.voltage
            }
            refNo = 'DS-' + responseData.data.no
        }
    }
      
    const properties = response2DSModel(id,refNo,responseData,voltage);
    return {
        id: id.toString(),
        image: imageDS,
        ...currentPosition,
        width: 20,
        height: 80,
        type: Element.MS_DS,
        isSelected: true,
        parentGroupId: null,
        offsetX: rotation === -90 ? 1*20:0,
        offsetY: rotation === 90 ? 1*80:0,
        rotation: rotation,
        chart: [],
        isRotationEnabled: true,
        isOverlapping: false,
        properties: properties,
    } as ControlModel;
};

const createMsLBS = (id: number, currentPosition: Coordinates, responseData: any,shapes: any,currentTabId:number,rotation:number) => {
    let voltage = responseData.voltage
    let result ={} as any
    if(currentTabId !==1){
        //inside transcenter
        result = doGetTransCenterSourceVolt(
            shapes,
            {id: id.toString(),
                image: imageLBS,
                ...currentPosition,
                width: 20,
                height: 80,
                offsetX: rotation === -90 ? 1*20:0,
                offsetY: rotation === 90 ? 1*80:0,
                type: Element.MS_LBS,
                isSelected: true,
                rotation: rotation,
                chart: [],
                isRotationEnabled: true,
                parentGroupId: null,
                properties: responseData},
            Constant.GET_TOP_BOTTOM_LEFT_RIGHT,

        )
        if(result.voltage > 0){
            voltage = result.voltage
        }
        }
    else{
        //outside transcenter
        result = doGetSourceVolt(
            {
            id: id.toString(),
            image: imageLBS,
            x: currentPosition.x,
            y: currentPosition.y,
            offsetX: rotation === -90 ? 1*20:0,
            offsetY: rotation === 90 ? 1*80:0,
            width: 0,
            height: 0,
            type: Element.MS_LBS,
            isSelected: true,
            rotation: rotation,
            chart: [],
            isRotationEnabled: false,
            parentGroupId: null,
            properties: responseData,
            },
            shapes,
            Constant.GET_TOP_BOTTOM_LEFT_RIGHT
        )
        if(result.voltage > 0){
            voltage = result.voltage
        }
    }
      
    const properties = response2LBSModel(responseData,voltage);
    return {
        id: id.toString(),
        image: imageLBS,
        ...currentPosition,
        width: 20,
        height: 80,
        offsetX: rotation === -90 ? 1*20:0,
        offsetY: rotation === 90 ? 1*80:0,
        type: Element.MS_LBS,
        isSelected: true,
        rotation: rotation,
        chart: [],
        isRotationEnabled: true,
        parentGroupId: null,
        isOverlapping: false,
        properties: properties,
    } as ControlModel;
};

const createMsMVCB = (id: number, currentPosition: Coordinates, responseData: any,shapes:any,currentTabId:number,rotation:number) => {
    let voltage = responseData.voltage
    let upTransKind = 0
    let sPhase = false
    let result ={} as any
    if(currentTabId !==1){
        //inside transcenter
        result = doGetTransCenterSourceVolt(
            shapes,
            {id: id.toString(),
                image: imageMVCB,
                ...currentPosition,
                width: 20,
                height: 80,
                offsetX: rotation === -90 ? 1*20:0,
                offsetY: rotation === 90 ? 1*80:0,
                type: Element.MS_MVCB,
                isSelected: true,
                rotation: rotation,
                chart: [],
                isRotationEnabled: true,
                parentGroupId: null,
                properties: responseData
            },
            Constant.GET_TOP_BOTTOM_LEFT_RIGHT,
            false,
            0
        )
        if(result.voltage > 0){
            voltage = result.voltage
            upTransKind = result.upTransKind??0
            sPhase = result.sPhase?? false
        }
        }
    else{
        //outside transcenter

        result = doGetSourceVolt(
            {
            id: id.toString(),
            image: imageMVCB,
            x: currentPosition.x,
            y: currentPosition.y,
            offsetX: rotation === -90 ? 1*20:0,
            offsetY: rotation === 90 ? 1*80:0,
            width: 0,
            height: 0,
            type: Element.MS_MVCB,
            isSelected: true,
            rotation: rotation,
            chart: [],
            isRotationEnabled: false,
            parentGroupId: null,
            properties: responseData,
            },
            shapes,
            Constant.GET_TOP_BOTTOM_LEFT_RIGHT,
            false,
            0
        )
        if(result.voltage > 0){
            voltage = result.voltage
            upTransKind = result.upTransKind
            sPhase = result.sPhase
        }
    }
    const properties = response2MVCBModel(responseData,voltage,upTransKind,sPhase);
    return {
        id: id.toString(),
        image: imageMVCB,
        ...currentPosition,
        width: 20,
        height: 80,
        offsetX: rotation === -90 ? 1*20:0,
        offsetY: rotation === 90 ? 1*80:0,
        type: Element.MS_MVCB,
        isSelected: true,
        rotation: rotation,
        chart: [],
        isRotationEnabled: true,
        parentGroupId: null,
        isOverlapping: false,
        properties: properties,
    } as ControlModel;
};

const createMsContactor = (id: number, currentPosition: Coordinates, responseData: any,shapes: any,currentTabId:number,rotation:number) => {
    let voltage = responseData.voltage
    let upTransKind = 0
    let sPhase = false
    let result ={} as any
    if(currentTabId !==1){
        //inside transcenter
        result = doGetTransCenterSourceVolt(
            shapes,
            {id: id.toString(),
                image: imageContactor,
                ...currentPosition,
                width: 20,
                height: 80,
                offsetX: rotation === -90 ? 1*20:0,
                offsetY: rotation === 90 ? 1*80:0,
                type: Element.MS_CONTACTOR,
                isSelected: true,
                parentGroupId: null,
                rotation: rotation,
                chart: [],
                isRotationEnabled: true,
                properties: responseData
            },
            Constant.GET_TOP_BOTTOM_LEFT_RIGHT,
            false,
            0
        )
        if(result.voltage > 0){
            voltage = result.voltage
            upTransKind = result.upTransKind??0
            sPhase = result.sPhase?? false
        }
        }
    else{
        //outside transcenter

        result = doGetSourceVolt(
            {
            id: id.toString(),
            image: imageContactor,
            x: currentPosition.x,
            y: currentPosition.y,
            offsetX: rotation === -90 ? 1*20:0,
            offsetY: rotation === 90 ? 1*80:0,
            width: 0,
            height: 0,
            type: Element.MS_CONTACTOR,
            isSelected: true,
            rotation: rotation,
            chart: [],
            isRotationEnabled: false,
            parentGroupId: null,
            properties: responseData,
            },
            shapes,
            Constant.GET_TOP_BOTTOM_LEFT_RIGHT,
            false,
            0
        )
        if(result.voltage > 0){
            voltage = result.voltage
            upTransKind = result.upTransKind
            sPhase = result.sPhase
        }
    }
      
    const properties = response2ContactorModel(responseData,voltage,upTransKind,sPhase);
    return {
        id: id.toString(),
        image: imageContactor,
        ...currentPosition,
        width: 20,
        height: 80,
        offsetX: rotation === -90 ? 1*20:0,
        offsetY: rotation === 90 ? 1*80:0,
        type: Element.MS_CONTACTOR,
        isSelected: true,
        parentGroupId: null,
        rotation: rotation,
        chart: [],
        isRotationEnabled: true,
        isOverlapping: false,
        properties: properties
    } as ControlModel;
};

const createMsFUSE = (id: number, currentPosition: Coordinates, responseData: any,shapes:any,currentTabId:number,rotation:number) => {
    let voltage = responseData.voltage
    let result ={} as any
    if(currentTabId !==1){
        //inside transcenter
        result = doGetTransCenterSourceVolt(
            shapes,
            {id: id.toString(),
                image: imageFUSE_0,
                ...currentPosition,
                width: 20,
                height: 80,
                offsetX: rotation === -90 ? 1*20:0,
                offsetY: rotation === 90 ? 1*80:0,
                type: Element.MS_FUSE,
                isSelected: true,
                rotation: rotation,
                chart: [],
                isRotationEnabled: true,
                parentGroupId: null,
                raw: [],
                properties: responseData},
                Constant.GET_TOP_BOTTOM_LEFT_RIGHT
                

        )
        if(result.voltage >0){
            voltage = result.voltage
        }
            }
    else{
        //outside transcenter
        result = doGetSourceVolt(
            {
            id: id.toString(),
            image: imageFUSE_0,
            ...currentPosition,
            width: 20,
            height: 80,
            offsetX: rotation === -90 ? 1*20:0,
            offsetY: rotation === 90 ? 1*80:0,
            type: Element.MS_FUSE,
            isSelected: true,
            rotation: rotation,
            chart: [],
            isRotationEnabled: true,
            parentGroupId: null,
            raw: [],
            properties: responseData,
            },
            shapes,
            Constant.GET_TOP_BOTTOM_LEFT_RIGHT
        )
        if(result.voltage > 0){
            voltage = result.voltage
        }
    }
    const properties = response2FUSEModel(responseData,voltage,rotation);
    return {
        id: id.toString(),
        image: imageFUSE_0,
        ...currentPosition,
        width: 20,
        height: 80,
        offsetX: rotation === -90 ? 1*20:0,
        offsetY: rotation === 90 ? 1*80:0,
        type: Element.MS_FUSE,
        isSelected: true,
        rotation: rotation,
        chart: [],
        isRotationEnabled: true,
        parentGroupId: null,
        isOverlapping: false,
        raw: [],
        properties: properties,
    } as ControlModel;
};

const createMs2E = (id: number, currentPosition: Coordinates, responseData: any,shapes:any,currentTabId:number) => {
    let voltage = responseData.voltage
    let upTransKind = 0
    let sPhase = false
    let result ={} as any
    if(currentTabId !==1){
        //inside transcenter

        result = doGetTransCenterSourceVolt(
            shapes,
            {id: id.toString(),
                image: image2E_0,
                ...currentPosition,
                width: 20,
                height: 80,
                offsetX: 0,
                offsetY: 0,
                type: Element.MS_2E,
                isSelected: true,
                rotation: 0,
                chart: [],
                isRotationEnabled: false,
                parentGroupId: null,
                raw: [],
                properties: responseData},
                Constant.GET_TOP_BOTTOM,
                false,
                0
        )
        if(result.voltage >0){
            voltage = result.voltage
            upTransKind = result.upTransKind??0
            sPhase = result.sPhase??false
        }
            }
    else{
        //outside transcenter
        result = doGetSourceVolt(
            {
                id: id.toString(),
                image: image2E_0,
                ...currentPosition,
                width: 20,
                height: 80,
                offsetX: 0,
                offsetY: 0,
                type: Element.MS_2E,
                isSelected: true,
                rotation: 0,
                chart: [],
                isRotationEnabled: false,
                parentGroupId: null,
                raw: [],
                properties: responseData,
            },
            shapes,
            Constant.GET_TOP_BOTTOM,
            false,
            0
        )
        if(result.voltage > 0){
            voltage = result.voltage
            upTransKind = result.upTransKind
            sPhase = result.sPhase
        }
    }
    const properties = response22EModel(responseData,voltage,upTransKind,sPhase);
    return {
        id: id.toString(),
        image: image2E_0,
        ...currentPosition,
        width: 20,
        height: 80,
        offsetX: 0,
        offsetY: 0,
        type: Element.MS_2E,
        isSelected: true,
        rotation: 0,
        chart: [],
        isRotationEnabled: false,
        parentGroupId: null,
        isOverlapping: false,
        raw: [],
        properties: properties
    } as ControlModel;
};

const createMsZerocond = (id: number, currentPosition: Coordinates, responseData: any,shapes:any,currentTabId:number) => {
    let voltage = responseData.voltage
    let upTransKind = 0
    let sPhase = false
    let result = {} as any
    if(currentTabId !==1){
        //inside transcenter
        result = doGetTransCenterSourceVolt(
            shapes,
            {id: id.toString(),
                image: imageZerocond,
                ...currentPosition,
                width: 20,
                height: 180,
                offsetX: 20,
                offsetY: 0,
                type: Element.MS_ZEROCOND,
                isSelected: true,
                rotation: -90,
                chart: [],
                isRotationEnabled: false,
                parentGroupId: null,
                properties: responseData
            },
            Constant.GET_TOP_BOTTOM_LEFT_RIGHT,
            false,
            0
        )
        if(result.voltage > 0){
            voltage = result.voltage
            upTransKind = result.upTransKind??0
            sPhase = result.sPhase?? false
        }
        }
    else{
        //outside transcenter
        result = doGetSourceVolt(
              {
                id: id.toString(),
                image: imageZerocond,
                ...currentPosition,
                width: 20,
                height: 180,
                offsetX: 20,
                offsetY: 0,
                type: Element.MS_ZEROCOND,
                isSelected: true,
                rotation: -90,
                chart: [],
                isRotationEnabled: false,
                parentGroupId: null,
                properties: responseData,
              },
              shapes,
              Constant.GET_TOP_BOTTOM_LEFT_RIGHT,
              false,
              0
            )
            if(result.voltage > 0){
                voltage = result.voltage
                upTransKind = result.upTransKind
                sPhase = result.sPhase
            }
    }
    const properties = response2ZerocondModel(responseData,voltage,upTransKind,sPhase);

    const element ={
        id: id.toString(),
        image: imageZerocond,
        ...currentPosition,
        width: 20,
        height: 180,
        offsetX: 20,
        offsetY: 0,
        type: Element.MS_ZEROCOND,
        isSelected: true,
        rotation: -90,
        chart: [],
        isRotationEnabled: false,
        parentGroupId: null,
        isOverlapping: false,
        properties: properties,
    } as ControlModel;

    return element;
};

const createMsConnect = (id: number, currentPosition: Coordinates, responseData: any) => {
    return {
        id: id.toString(),
        image: imageConnect,
        ...currentPosition,
        width: 20,
        height: 40,
        offsetX: 0,
        offsetY: 0,
        type: Element.MS_CONNECT,
        isSelected: true,
        rotation: 0,
        chart: [],
        parentGroupId: null,
        isOverlapping: false,
        isRotationEnabled: true,
        properties: {
            sPhase: false,
            upTransKind: Element.MS_ELEMENT_ALL,
            rotate: 0,
            supply: false,
            primarySupply: false
        } as Model.ConnectModel
    } as ControlModel;
};

const createMsWire = (id: number, currentPosition: Coordinates, responseData: any,shapes:any,currentTabId:number,frequency:number,rotation:number) => {
    
    let voltage = responseData.voltLowDefaultItem === null?0:responseData.voltLowDefaultItem.lvoltage
    let upTransKind = 0
    let sPhase = false
    let result ={} as any
    if(currentTabId !==1){
        //inside transcenter
        result = doGetTransCenterSourceVolt(
            shapes,
            {id: id.toString(),
                image: imageWire,
                ...currentPosition,
                width: 20,
                height: 80,
                offsetX: rotation === -90 ? 1*20:0,
                offsetY: rotation === 90 ? 1*80:0,
                type: Element.MS_WIRE,
                isSelected: true,
                rotation: rotation,
                chart: [],
                isRotationEnabled: true,
                parentGroupId: null,
                properties: responseData},
                Constant.GET_TOP_BOTTOM_LEFT_RIGHT,
                false,
                0

        )
        if(result.voltage > 0){
            voltage = result.voltage
            upTransKind = result.upTransKind??0
            sPhase = result.sPhase?? false
        }
    }
    else{
        //outside transcenter
        result = doGetSourceVolt(
        {
            id: id.toString(),
            image: imageWire,
            ...currentPosition,
            width: 20,
            height: 80,
            offsetX: rotation === -90 ? 1*20:0,
            offsetY: rotation === 90 ? 1*80:0,
            type: Element.MS_WIRE,
            isSelected: true,
            rotation: rotation,
            chart: [],
            isRotationEnabled: true,
            parentGroupId: null,
            properties: responseData
        },
        shapes,
        Constant.GET_TOP_BOTTOM_LEFT_RIGHT,
        false,
        0
      )
      if(result.voltage > 0){
        voltage = result.voltage
        upTransKind = result.upTransKind
        sPhase = result.sPhase
      }
    }
    const properties = response2WireModel(responseData,voltage,upTransKind,sPhase,frequency,rotation);
    return {
        id: id.toString(),
        image: imageWire,
        ...currentPosition,
        width: 20,
        height: 80,
        offsetX: rotation === -90 ? 1*20:0,
        offsetY: rotation === 90 ? 1*80:0,
        type: Element.MS_WIRE,
        isSelected: true,
        rotation: rotation,
        chart: [],
        isRotationEnabled: true,
        parentGroupId: null,
        isOverlapping: false,
        properties: properties
    } as ControlModel;
};

const createMsBusbar = (id: number, currentPosition: Coordinates, responseData: any,shapes:any,currentTabId:number,rotation:number,frequency:number) => {
     let voltage = responseData.voltage
     let upTransKind = 0
     let sPhase = false
     let result ={} as any
     if(currentTabId !==1){
        //inside transcenter
        result = doGetTransCenterSourceVolt(
            shapes,
            {id: id.toString(),
                image: imageBusbar,
                ...currentPosition,
                width: 20,
                height: 80,
                offsetX: rotation === -90 ? 1*20:0,
                offsetY: rotation === 90 ? 1*80:0,
                type: Element.MS_BUSBAR,
                isSelected: true,
                rotation: rotation,
                chart: [],
                isRotationEnabled: true,
                parentGroupId: null,
                properties: responseData
            },
            Constant.GET_TOP_BOTTOM_LEFT_RIGHT,
            false,
            0
        )
        if(result.voltage > 0){
            voltage = result.voltage
            upTransKind = result.upTransKind??0
            sPhase = result.sPhase?? false
        }
        }
    else{
        //outside transcenter
        result = doGetSourceVolt(
            {
                id: id.toString(),
                image: imageBusbar,
                ...currentPosition,
                width: 20,
                height: 80,
                offsetX: rotation === -90 ? 1*20:0,
                offsetY: rotation === 90 ? 1*80:0,
                type: Element.MS_BUSBAR,
                isSelected: true,
                rotation: rotation,
                chart: [],
                isRotationEnabled: true,
                parentGroupId: null,
                properties: responseData
            },
            shapes,
            Constant.GET_TOP_BOTTOM_LEFT_RIGHT,
            false,
            0
        )
        if(result.voltage > 0){
            voltage = result.voltage
            upTransKind = result.upTransKind
            sPhase = result.sPhase
        }
    }
    const properties = response2BusbarModel(id,responseData,voltage,upTransKind,sPhase,frequency);
    return {
        id: id.toString(),
        image: imageBusbar,
        ...currentPosition,
        width: 20,
        height: 80,
        offsetX: rotation === -90 ? 1*20:0,
        offsetY: rotation === 90 ? 1*80:0,
        type: Element.MS_BUSBAR,
        isSelected: true,
        rotation: rotation,
        chart: [],
        isRotationEnabled: true,
        parentGroupId: null,
        isOverlapping: false,
        properties: properties
    } as ControlModel;
};

const createMsBusduct = (id: number, currentPosition: Coordinates, responseData: any,shapes:any,currentTabId:number,frequency:number,rotation:number) => {
    let voltage = responseData.voltage
    let upTransKind = 0
    let sPhase  = false
    let result ={} as any
    if(currentTabId !==1){
        //inside transcenter
        result = doGetTransCenterSourceVolt(
            shapes,
            {id: id.toString(),
                image: imageBusduct,
                ...currentPosition,
                width: 20,
                height: 80,
                offsetX: rotation === -90 ? 1*20:0,
                offsetY: rotation === 90 ? 1*80:0,
                type: Element.MS_BUSDUCT,
                isSelected: true,
                rotation: rotation,
                chart: [],
                isRotationEnabled: true,
                parentGroupId: null,
                properties: responseData
            },
            Constant.GET_TOP_BOTTOM_LEFT_RIGHT,
            false,
            0
        )
        if(result.voltage > 0){
            voltage = result.voltage
            upTransKind = result.upTransKind??0
            sPhase = result.sPhase?? false
        }
        }
    else{
        //outside transcenter
        result = doGetSourceVolt(
        {
            id: id.toString(),
            image: imageBusduct,
            ...currentPosition,
            width: 20,
            height: 80,
            offsetX: rotation === -90 ? 1*20:0,
            offsetY: rotation === 90 ? 1*80:0,
            type: Element.MS_BUSDUCT,
            isSelected: true,
            rotation: rotation,
            chart: [],
            isRotationEnabled: true,
            parentGroupId: null,
            properties: responseData
        },
        shapes,
        Constant.GET_TOP_BOTTOM_LEFT_RIGHT,
        false,
        0
      )
      if(result.voltage > 0){
        voltage = result.voltage
        upTransKind = result.upTransKind
        sPhase = result.sPhase
      }
    }
      
    const properties = response2BusductModel(responseData,voltage,upTransKind,sPhase,frequency);
    return {
        id: id.toString(),
        image: imageBusduct,
        ...currentPosition,
        width: 20,
        height: 80,
        offsetX: rotation === -90 ? 1*20:0,
        offsetY: rotation === 90 ? 1*80:0,
        type: Element.MS_BUSDUCT,
        isSelected: true,
        rotation: rotation,
        chart: [],
        isRotationEnabled: true,
        parentGroupId: null,
        isOverlapping: false,
        properties: properties
    } as ControlModel;
};

const createMsReactor = (id: number, currentPosition: Coordinates, responseData: any,shapes:any,currentTabId:number,rotation:number) => {
    let voltage = responseData.voltage
    let result ={} as any
    if(currentTabId !==1){
        //inside transcenter
        result = doGetTransCenterSourceVolt(
            shapes,
            {id: id.toString(),
                image: imageReactor,
                ...currentPosition,
                width: 20,
                height: 80,
                offsetX: rotation === -90 ? 1*20:0,
                offsetY: rotation === 90 ? 1*80:0,
                type: Element.MS_REACTOR,
                isSelected: true,
                rotation: rotation,
                chart: [],
                isRotationEnabled: true,
                parentGroupId: null,
                properties: responseData
            },
            Constant.GET_TOP_BOTTOM_LEFT_RIGHT
        )
        if(result.voltage > 0){
            voltage = result.voltage

        }
        }
    else{
        //outside transcenter
        result = doGetSourceVolt(
            {
                id: id.toString(),
                image: imageReactor,
                ...currentPosition,
                width: 20,
                height: 80,
                offsetX: rotation === -90 ? 1*20:0,
                offsetY: rotation === 90 ? 1*80:0,
                type: Element.MS_REACTOR,
                isSelected: true,
                rotation: rotation,
                chart: [],
                isRotationEnabled: true,
                parentGroupId: null,
                properties: responseData
            },
            shapes,
            Constant.GET_TOP_BOTTOM_LEFT_RIGHT
        )
        if(result.voltage > 0){
            voltage = result.voltage
        }
    }
    const properties = response2ReactorModel(responseData,voltage);
    return {
        id: id.toString(),
        image: imageReactor,
        ...currentPosition,
        width: 20,
        height: 80,
        offsetX: rotation === -90 ? 1*20:0,
        offsetY: rotation === 90 ? 1*80:0,
        type: Element.MS_REACTOR,
        isSelected: true,
        rotation: rotation,
        chart: [],
        isRotationEnabled: true,
        parentGroupId: null,
        isOverlapping: false,
        properties: properties,
    } as ControlModel;
};

const createMsImpedance = (id: number, currentPosition: Coordinates, responseData: any,shapes:any,currentTabId:number,rotation:number) => {
    let voltage = responseData.voltage
    
    let upTransKind = 0
    let sPhase = false
    let result ={} as any
    if(currentTabId !==1){
        //inside transcenter
        result = doGetTransCenterSourceVolt(
            shapes,
            {id: id.toString(),
                image: imageImpedance,
                ...currentPosition,
                width: 20,
                height: 80,
                offsetX: rotation === -90 ? 1*20:0,
                offsetY: rotation === 90 ? 1*80:0,
                type: Element.MS_IMPEDANCE,
                isSelected: true,
                rotation: rotation,
                chart: [],
                isRotationEnabled: true,
                parentGroupId: null,
                properties: responseData
            },
            Constant.GET_TOP_BOTTOM_LEFT_RIGHT,
            false,
            0
        )
        if(result.voltage > 0){
            voltage = result.voltage
            upTransKind = result.upTransKind??0
            sPhase = result.sPhase?? false
        }
        }
    else{
        //outside transcenter
        result = doGetSourceVolt(
            {
                id: id.toString(),
                image: imageImpedance,
                ...currentPosition,
                width: 20,
                height: 80,
                offsetX: rotation === -90 ? 1*20:0,
                offsetY: rotation === 90 ? 1*80:0,
                type: Element.MS_IMPEDANCE,
                isSelected: true,
                rotation: rotation,
                chart: [],
                isRotationEnabled: true,
                parentGroupId: null,
                properties: responseData
            },
            shapes,
            Constant.GET_TOP_BOTTOM_LEFT_RIGHT,
            false,
            0
        )
        if(result.voltage > 0){
            voltage = result.voltage
            upTransKind = result.upTransKind
            sPhase = result.sPhase
        }
    }
      const  calcPoint0 ={
        loadCurrent: 0,
        isLead: false,
        powerFactor: 1,
        calcKind: Constant.CALC_IK3,
        stdCapacity:0,
        loadCapacity: 0,
        voltage:voltage,
        perZDirect: percentZDefault() as Model.PercentZ,
        perZTotal: percentZDefault() as Model.PercentZ,
        perZDirectMG: percentZDefault() as Model.PercentZ,
        perZTotalMG: percentZDefault() as Model.PercentZ,
        perZMotor: percentZDefault() as Model.PercentZ,
    } as Model.CalcPoint
    const  calcPoint1 ={
        loadCurrent: 0,
        isLead: false,
        powerFactor: 1,
        calcKind: Constant.CALC_IK2,
        stdCapacity:0,
        loadCapacity: 0,
        voltage:voltage,
        perZDirect: percentZDefault() as Model.PercentZ,
        perZTotal: percentZDefault() as Model.PercentZ,
        perZDirectMG: percentZDefault() as Model.PercentZ,
        perZTotalMG: percentZDefault() as Model.PercentZ,
        perZMotor: percentZDefault() as Model.PercentZ,
    } as Model.CalcPoint
    // doSetStateCicuitImpedance(sPhase,upTransKind,calcPoint0,calcPoint1)
    const properties = response2ImpedanceModel(responseData,voltage,upTransKind,sPhase,calcPoint0,calcPoint1);
    return {
        id: id.toString(),
        image: imageImpedance,
        ...currentPosition,
        width: 20,
        height: 80,
        offsetX: rotation === -90 ? 1*20:0,
        offsetY: rotation === 90 ? 1*80:0,
        type: Element.MS_IMPEDANCE,
        isSelected: true,
        rotation: rotation,
        chart: [],
        isRotationEnabled: true,
        parentGroupId: null,
        isOverlapping: false,
        properties: properties,
    } as ControlModel;
};

const createMsLVCB = (id: number, currentPosition: Coordinates, responseData: any,shapes:any,currentTabId:number,rotation:number) => {
    let voltage = responseData.voltage
    let upTransKind= 0
    let sPhase = false
    let result ={} as any
    if(currentTabId !==1){
        //inside transcenter
        result = doGetTransCenterSourceVolt(
            shapes,
            {id: id.toString(),
                image: imageLVCB_2,
                ...currentPosition,
                width: 20,
                height: 80,
                offsetX: rotation === -90 ? 1*20:0,
                offsetY: rotation === 90 ? 1*80:0,
                type: Element.MS_LVCB,
                isSelected: true,
                rotation: rotation,
                chart: [],
                isRotationEnabled: true,
                parentGroupId: null,
                raw: [],
                properties: responseData},
                Constant.GET_TOP_BOTTOM_LEFT_RIGHT,
                false,
                0

        )
        if(result.voltage >0){
            voltage = result.voltage
            upTransKind = result.upTransKind?? 0
            sPhase = result.sPhase??false
        }
            }
    else{
        //outside transcenter
        result = doGetSourceVolt(
            {
                id: id.toString(),
                image: imageLVCB_2,
                ...currentPosition,
                width: 20,
                height: 80,
                offsetX: rotation === -90 ? 1*20:0,
                offsetY: rotation === 90 ? 1*80:0,
                type: Element.MS_LVCB,
                isSelected: true,
                rotation: rotation,
                chart: [],
                isRotationEnabled: true,
                parentGroupId: null,
                raw: [],
                properties: responseData
            },
            shapes,
            Constant.GET_TOP_BOTTOM_LEFT_RIGHT,
            false,
            0
        )
        if(result.voltage > 0){
            voltage = result.voltage
            upTransKind = result.upTransKind
            sPhase = result.sPhase
        }
    }      

    const properties = response2LVCBModel(responseData,voltage,upTransKind,sPhase,rotation);
    
    return {
        id: id.toString(),
        image: imageLVCB_2,
        ...currentPosition,
        width: 20,
        height: 80,
        offsetX: rotation === -90 ? 1*20:0,
        offsetY: rotation === 90 ? 1*80:0,
        type: Element.MS_LVCB,
        isSelected: true,
        rotation: rotation,
        chart: [],
        isRotationEnabled: true,
        parentGroupId: null,
        isOverlapping: false,
        raw: [],
        properties: properties,
    } as ControlModel;
};

const createMsThermal = (id: number, currentPosition: Coordinates, responseData: any,shapes:any,currentTabId:number) => {
    let voltage = responseData.voltage
    let upTransKind = 0
    let sPhase = false
    let result ={} as any
    if(currentTabId !==1){
        //inside transcenter
        result = doGetTransCenterSourceVolt(
            shapes,
            {id: id.toString(),
                image: imageThermal_0,
                ...currentPosition,
                width: 20,
                height: 80,
                offsetX: 0,
                offsetY: 0,
                type: Element.MS_THERMAL,
                isSelected: true,
                rotation: 0,
                chart: [],
                isRotationEnabled: false,
                parentGroupId: null,
                raw: [],
                properties: responseData},
                Constant.GET_TOP_BOTTOM,
                false,
                0
        )
        if(result.voltage >0){
            voltage = result.voltage
            upTransKind = result.upTransKind||0
            sPhase = result.sPhase||false
        }
            }
    else{
        //outside transcenter
        result = doGetSourceVolt(
        {
            id: id.toString(),
            image: imageThermal_0,
            ...currentPosition,
            width: 20,
            height: 80,
            offsetX: 0,
            offsetY: 0,
            type: Element.MS_THERMAL,
            isSelected: true,
            rotation: 0,
            chart: [],
            isRotationEnabled: false,
            parentGroupId: null,
            raw: [],
            properties: responseData
        },
        shapes,
        Constant.GET_TOP_BOTTOM,
        false,
        0
      )
      if(result.voltage > 0){
        voltage = result.voltage
        upTransKind = result.upTransKind
        sPhase = result.sPhase
      }
    }
    const properties = response2ThermalModel(responseData,voltage,upTransKind,sPhase);
    return {
        id: id.toString(),
        image: imageThermal_0,
        ...currentPosition,
        width: 20,
        height: 80,
        offsetX: 0,
        offsetY: 0,
        type: Element.MS_THERMAL,
        isSelected: true,
        rotation: 0,
        chart: [],
        isRotationEnabled: false,
        parentGroupId: null,
        isOverlapping: false,
        raw: [],
        properties: properties,
    } as ControlModel;
}

const createMsThermalCT = (id: number, currentPosition: Coordinates, responseData: any,shapes:any,currentTabId:number) => {
    let voltage = responseData.voltage
    let upTransKind = 0
    let sPhase = false
    let result ={} as any
    if(currentTabId !==1){
        //inside transcenter
        result = doGetTransCenterSourceVolt(
            shapes,
            {id: id.toString(),
                image: imageThermalCT,
                ...currentPosition,
                width: 40,
                height: 80,
                offsetX: 0,
                offsetY: 0,
                type: Element.MS_THERMAL_CT,
                isSelected: true,
                rotation: 0,
                chart: [],
                isRotationEnabled: false,
                parentGroupId: null,
                raw: [],
                properties: responseData},
                Constant.GET_TOP_BOTTOM,
                false,
                0
        )
        if(result.voltage >0){
            voltage = result.voltage
            upTransKind = result.upTransKind??0
            sPhase = result.sPhase??false
        }
            }
    else{
        //outside transcenter
        result = doGetSourceVolt(
            {
                id: id.toString(),
                image: imageThermalCT,
                ...currentPosition,
                width: 40,
                height: 80,
                offsetX: 0,
                offsetY: 0,
                type: Element.MS_THERMAL_CT,
                isSelected: true,
                rotation: 0,
                chart: [],
                isRotationEnabled: false,
                parentGroupId: null,
                raw: [],
                properties: responseData
            },
            shapes,
            Constant.GET_TOP_BOTTOM,
            false,
            0
        )
        if(result.voltage > 0){
            voltage = result.voltage
            upTransKind = result.upTransKind
            sPhase = result.sPhase
        }
    }
    const properties = response2ThermalCTModel(responseData,voltage,upTransKind,sPhase);

    return {
        id: id.toString(),
        image: imageThermalCT,
        ...currentPosition,
        width: 40,
        height: 80,
        offsetX: 0,
        offsetY: 0,
        type: Element.MS_THERMAL_CT,
        isSelected: true,
        rotation: 0,
        chart: [],
        isRotationEnabled: false,
        parentGroupId: null,
        isOverlapping: false,
        raw: [],
        properties: properties,
    } as ControlModel
}

export const createText = (id: number, noText : number, currentPosition: Coordinates) => {
    return {
        id: id.toString(),
        ...currentPosition,
        height: Constant.POINTER_HEIGHT,
        rotation: 0,
        width: getTextWidth("テキスト" + noText, 'Noto Sans JP', 12),
        type: Element.MS_TEXT,
        isSelected: true,
        parentGroupId: null,
        properties: {
            noText,
            text: "テキスト" + noText,
            refNo: "テキスト" + noText,
            fontSize: 12,
            height: Constant.POINTER_HEIGHT,
            faceName: 'Noto Sans JP',
            colorFont: '#000000',
            underline: false,
            strikeOut: false,
            italic: false,
            weight: 400,
            charSet: 128,
        } as Model.MsTextModel,
    } as ControlModel;
};

const createMsMotorGroup = (id: number, currentPosition: Coordinates, responseData: any,shapes:any) => {
    let voltage = responseData.voltage
    let res = doGetSourceVolt(
        {
            id: id.toString(),
            image: imageMotorGroup,
            ...currentPosition,
            width: 20,
            height: 60,
            offsetX: 0,
            offsetY: 0,
            type: Element.MS_MOTOR_GROUP,
            isSelected: true,
            rotation: 0,
            chart: [],
            isRotationEnabled: false,
            parentGroupId: null,
            properties: responseData,
        },
        shapes,
        Constant.GET_TOP
    
      )
      if(res.voltage > 0){
        voltage = res.voltage
      }
      let capacity = 0
      let topCapacity = doGetValueCapacityTandG(
        {
            id: id.toString(),
            image: imageMotorGroup,
            ...currentPosition,
            width: 20,
            height: 60,
            offsetX: 0,
            offsetY: 0,
            type: Element.MS_MOTOR_GROUP,
            isSelected: true,
            rotation: 0,
            chart: [],
            isRotationEnabled: false,
            parentGroupId: null,
            properties: responseData,
        },
        shapes
      )
      if( topCapacity > 0){
        capacity = topCapacity * 0.8
      }
    const properties = response2MotorGroupModel(responseData,voltage,capacity);
    return {
        id: id.toString(),
        image: imageMotorGroup,
        ...currentPosition,
        width: 20,
        height: 60,
        offsetX: 0,
        offsetY: 0,
        type: Element.MS_MOTOR_GROUP,
        isSelected: true,
        rotation: 0,
        chart: [],
        isRotationEnabled: false,
        parentGroupId: null,
        isOverlapping: false,
        properties: properties,
    } as ControlModel;
};

const createMsLoad = (id: number, currentPosition: Coordinates, responseData: any,shapes:any,currentTabId:number) => {
    let voltage = responseData.voltage
    let upTransKind = 0
    let sPhase = false
    let reuslt ={} as any
    if(currentTabId !==1){
        //inside transcenter
        reuslt = doGetTransCenterSourceVolt(
            shapes,
            {   id: id.toString(),
                image: imageLoad,
                ...currentPosition,
                width: 20,
                height: 40,
                offsetX: 0,
                offsetY: 0,
                type: Element.MS_LOAD,
                isSelected: true,
                rotation: 0,
                chart: [],
                isRotationEnabled: false,
                parentGroupId: null,
                properties: responseData
            },
            Constant.GET_TOP,
            false,
            0
        )
        if(reuslt.voltage > 0){
            voltage = reuslt.voltage
            upTransKind = reuslt.upTransKind??0
            sPhase = reuslt.sPhase?? false
        }
        }
    else{
        //outside transcenter
        reuslt = doGetSourceVolt(
            {
                id: id.toString(),
                image: imageLoad,
                ...currentPosition,
                width: 20,
                height: 40,
                offsetX: 0,
                offsetY: 0,
                type: Element.MS_LOAD,
                isSelected: true,
                rotation: 0,
                chart: [],
                isRotationEnabled: false,
                parentGroupId: null,
                properties: responseData,
            },
            shapes,
            Constant.GET_TOP,
            false,
            0
        )
        if(reuslt.voltage > 0){
            voltage = reuslt.voltage
            upTransKind = reuslt.upTransKind
            sPhase = reuslt.sPhase
        }
    }
    const properties = response2LoadModel(responseData,voltage,upTransKind,sPhase);
    return {
        id: id.toString(),
        image: imageLoad,
        ...currentPosition,
        width: 20,
        height: 40,
        offsetX: 0,
        offsetY: 0,
        type: Element.MS_LOAD,
        isSelected: true,
        rotation: 0,
        chart: [],
        isRotationEnabled: false,
        parentGroupId: null,
        isOverlapping: false,
        properties: properties,
    } as ControlModel;
};

const createMsLightBoard = (id: number, currentPosition: Coordinates, responseData: any,shapes:any,currentTabId:number) => {
    let voltage = responseData.voltage
    let upTransKind = 0
    let sPhase = false
    let result ={} as any
    if(currentTabId !==1){
        //inside transcenter
        result = doGetTransCenterSourceVolt(
            shapes,
            {   id: id.toString(),
                image: imageLightBoard,
                ...currentPosition,
                width: 20,
                height: 40,
                offsetX: 0,
                offsetY: 0,
                type: Element.MS_LIGHTBOARD,
                isSelected: true,
                rotation: 0,
                chart: [],
                isRotationEnabled: false,
                parentGroupId: null,
                properties: responseData
            },
            Constant.GET_TOP,
            false,
            0
        )
        if(result.voltage > 0){
            voltage = result.voltage
            upTransKind = result.upTransKind??0
            sPhase = result.sPhase??false
        }
        }
    else{
        //outside transcenter
        result = doGetSourceVolt(
            {
                id: id.toString(),
                image: imageLightBoard,
                ...currentPosition,
                width: 20,
                height: 40,
                offsetX: 0,
                offsetY: 0,
                type: Element.MS_LIGHTBOARD,
                isSelected: true,
                rotation: 0,
                chart: [],
                isRotationEnabled: false,
                parentGroupId: null,
                properties: responseData,
            },
            shapes,
            Constant.GET_TOP,
            false,
            0
        )
        if(result.voltage > 0){
            voltage = result.voltage
            upTransKind = result.upTransKind
            sPhase = result.sPhase
        }
    }
      
    const properties = response2LightBoardModel(responseData,voltage,upTransKind,sPhase);
    return {
        id: id.toString(),
        image: imageLightBoard,
        ...currentPosition,
        width: 20,
        height: 40,
        offsetX: 0,
        offsetY: 0,
        type: Element.MS_LIGHTBOARD,
        isSelected: true,
        rotation: 0,
        chart: [],
        isRotationEnabled: false,
        parentGroupId: null,
        isOverlapping: false,
        properties: properties,
    } as ControlModel;
};

const createMsPowerBoard = (id: number, currentPosition: Coordinates, responseData: any,shapes:any,currentTabId:number) => {
    let voltage = responseData.voltage
    let upTransKind = 0
    let sPhase = false
    let result ={} as any
    if(currentTabId !==1){
        //inside transcenter
        result = doGetTransCenterSourceVolt(
            shapes,
            {   id: id.toString(),
                image: imageLightBoard,
                ...currentPosition,
                width: 20,
                height: 40,
                offsetX: 0,
                offsetY: 0,
                type: Element.MS_LIGHTBOARD,
                isSelected: true,
                rotation: 0,
                chart: [],
                isRotationEnabled: false,
                parentGroupId: null,
                properties: responseData
            },
            Constant.GET_TOP,

            false,
            0
        )
        if(result.voltage > 0){
            voltage = result.voltage
            upTransKind = result.upTransKind??0
            sPhase = result.sPhase??false
        }
        }
    else{
        //outside transcenter
        result = doGetSourceVolt(
            {
            id: id.toString(),
            image: imagePowerBoard,
            ...currentPosition,
            width: 20,
            height: 40,
            offsetX: 0,
            offsetY: 0,
            type: Element.MS_POWERBOARD,
            isSelected: true,
            rotation: 0,
            chart: [],
            isRotationEnabled: false,
            parentGroupId: null,
            properties: responseData,
            },
            shapes,
            Constant.GET_TOP,
            false,
            0
        )
        if(result.voltage > 0){
            voltage = result.voltage
            upTransKind = result.upTransKind
            sPhase = result.sPhase
        }
    }
      

    const properties = response2PowerBoardModel(responseData,voltage,upTransKind,sPhase);
    return {
        id: id.toString(),
        image: imagePowerBoard,
        ...currentPosition,
        width: 20,
        height: 40,
        offsetX: 0,
        offsetY: 0,
        type: Element.MS_POWERBOARD,
        isSelected: true,
        rotation: 0,
        chart: [],
        isRotationEnabled: false,
        parentGroupId: null,
        isOverlapping: false,
        properties: properties,
    } as ControlModel;
};

const createMsCapacitor = (id: number, currentPosition: Coordinates, responseData: any,shapes:any,currentTabId:number) => {
    let voltage = responseData.voltage
    let result = {} as any
    if(currentTabId !==1){
        //inside transcenter
        result = doGetTransCenterSourceVolt(
            shapes,
            {   id: id.toString(),
                image: imageCapacitor,
                ...currentPosition,
                width: 20,
                height: 60,
                offsetX: 0,
                offsetY: 0,
                type: Element.MS_CAPACITOR,
                isSelected: true,
                rotation: 0,
                chart: [],
                isRotationEnabled: false,
                parentGroupId: null,
                properties: responseData
            },
             Constant.GET_TOP

        )
        if(result.voltage > 0){
            voltage = result.voltage
        }
        }
    else{
        //outside transcenter
        result = doGetSourceVolt(
            {
                id: id.toString(),
                image: imageCapacitor,
                ...currentPosition,
                width: 20,
                height: 60,
                offsetX: 0,
                offsetY: 0,
                type: Element.MS_CAPACITOR,
                isSelected: true,
                rotation: 0,
                chart: [],
                isRotationEnabled: false,
                parentGroupId: null,
                properties: responseData,
            },
            shapes,
            Constant.GET_TOP

        )
        if(result.voltage > 0){
            voltage = result.voltage
        }
    }
    const properties = response2CapacitorModel(responseData,voltage);
    return {
        id: id.toString(),
        image: imageCapacitor,
        ...currentPosition,
        width: 20,
        height: 60,
        offsetX: 0,
        offsetY: 0,
        type: Element.MS_CAPACITOR,
        isSelected: true,
        rotation: 0,
        chart: [],
        isRotationEnabled: false,
        parentGroupId: null,
        isOverlapping: false,
        properties: properties,
    } as ControlModel;
};
const createTransCenter = (tabId: number, id: number, currentPosition: Coordinates, responseData: any,shapes:any) => {
    let voltage = responseData.voltage
    let upTransKind:number = 0
    let sPhase: boolean = false
    let res = doGetSourceVolt(
        {
            id: id.toString(),
            image: imageTransCenter_1,
            ...currentPosition,
            width: 20,
            height: 40,
            offsetX: 0,
            offsetY: 0,
            type: Element.MS_TRANSCENTER,
            isSelected: true,
            rotation: 0,
            chart: [],
            isRotationEnabled: true,
            parentGroupId: null,
            properties: responseData,
        },
        shapes,
        Constant.GET_TOP,
        false,
        0
      )
      if(res.voltage > 0){
        voltage = res.voltage
        upTransKind = res.upTransKind
        sPhase = res.sPhase
      }
    const properties = response2TransCenterModel(tabId, responseData,voltage,upTransKind,sPhase);
    return {
        id: id.toString(),
        image: imageTransCenter_1,
        ...currentPosition,
        width: 20,
        height: 40,
        offsetX: 0,
        offsetY: 0,
        type: Element.MS_TRANSCENTER,
        isSelected: true,
        rotation: 0,
        chart: [],
        isRotationEnabled: true,
        parentGroupId: null,
        isOverlapping: false,
        properties: properties,
    } as ControlModel;
}
const createIntoPoint = (id: number,transCenterData:any) => {
    
    return {
        id: id.toString(),
        image: '',
        x: DEFAULT_INTO_POINT_POSITION.x,
        y: DEFAULT_INTO_POINT_POSITION.y,
        width: Constant.POINTER_WIDTH,
        height: Constant.POINTER_HEIGHT,
        offsetX: 0,
        offsetY: 0,
        type: Element.MS_INTO_POINT,
        isSelected: false,
        rotation: 0,
        chart: [],
        isRotationEnabled: false,
        parentGroupId: null,
        isOverlapping: false,
        properties: {
            supply: transCenterData.properties.supply,
            sPhase: transCenterData.properties.sPhase,
            upTransKind: transCenterData.properties.upTransKind,
            voltage: transCenterData.properties.voltage,
            capacity: transCenterData.properties.capacity,
            percentZ: transCenterData.properties.percentZ,
            nodeNumber: transCenterData.properties.nodeNumber,
            perUnitR: transCenterData.properties.perUnitR,
            perUnitX: transCenterData.properties.perUnitX,
            perUnitK: transCenterData.properties.perUnitK,
            perUnitP: transCenterData.properties.perUnitP,
            perUnitQ: transCenterData.properties.perUnitQ,
            perUnitV: transCenterData.properties.perUnitV,
            voltageMagnitude: transCenterData.properties.voltageMagnitude,
            voltageAngle: transCenterData.properties.voltageAngle,
            activePower: transCenterData.properties.activePower,
            reactivePower: transCenterData.properties.reactivePower,
            refNo: '引込口',
            topEleRefNo: '',
            noIntoPoint: 1,
            primarySupply: transCenterData.properties.primarySupply,
            seriesed: transCenterData.properties.seriesed,
            voltDropSumRe1: transCenterData.properties.voltDropSumRe1,
            voltDropSumIm1: transCenterData.properties.voltDropSumIm1,
            voltDropSumRe2: transCenterData.properties.voltDropSumRe2,
            voltDropSumIm2: transCenterData.properties.voltDropSumIm2,
            voltDropRouteRefNo: transCenterData.properties.voltDropRouteRefNo,
            calcPoint0: transCenterData.properties.calcPoint0,
            calcPoint1: transCenterData.properties.calcPoint1,
        },
    } as ControlModel;
}
const createMsVCT = (id: number, currentPosition: Coordinates, responseData: any,shapes:any,currentTabId:number) => {
    let voltage = responseData.voltage
    let result ={} as any
    if(currentTabId !==1){
        //inside transcenter
        result = doGetTransCenterSourceVolt(
            shapes,
            {   id: id.toString(),
                image: imageINV_1,
                ...currentPosition,
                width: 20,
                height: 60,
                offsetX: 0,
                offsetY: 0,
                type: Element.MS_VCT,
                isSelected: true,
                rotation: 0,
                chart: [],
                isRotationEnabled: false,
                parentGroupId: null,
                properties: responseData
            },
            Constant.GET_TOP_BOTTOM

        )
        if(result.voltage > 0){
            voltage = result.voltage
        }
        }
    else{
        //outside transcenter
        result = doGetSourceVolt(
            {
                id: id.toString(),
                image: imageINV_1,
                ...currentPosition,
                width: 20,
                height: 60,
                offsetX: 0,
                offsetY: 0,
                type: Element.MS_VCT,
                isSelected: true,
                rotation: 0,
                chart: [],
                isRotationEnabled: false,
                parentGroupId: null,
                properties: responseData
            },
            shapes,
            Constant.GET_TOP_BOTTOM,
        )
        if(result.voltage > 0){
            voltage = result.voltage
        }
    }
    const properties = response2VCTModel(responseData,voltage);
    return {
        id: id.toString(),
        image: imageINV_1,
        ...currentPosition,
        width: 20,
        height: 60,
        offsetX: 0,
        offsetY: 0,
        type: Element.MS_VCT,
        isSelected: true,
        rotation: 0,
        chart: [],
        isRotationEnabled: false,
        parentGroupId: null,
        isOverlapping: false,
        properties: properties
    } as ControlModel;
};

const createImageMsVT = (id: number, currentPosition: Coordinates, responseData: any,shapes:any,currentTabId:number) => {
    let voltage = responseData.voltage
    let result = {} as any
    if(currentTabId !==1){
        //inside transcenter
        result = doGetTransCenterSourceVolt(
            shapes,
            {   id: id.toString(),
                image: imageMsVT_1,
                ...currentPosition,
                width: 20,
                height: 40,
                type: Element.MS_VT,
                isSelected: true,
                parentGroupId: null,
                offsetX: 0,
                offsetY: 0,
                rotation: 0,
                chart: [],
                isRotationEnabled: false,
                properties: responseData
            },
            Constant.GET_TOP_BOTTOM

        )
        if(result.voltage > 0){
            voltage = result.voltage
        }
        }
    else{
        //outside transcenter
        result = doGetSourceVolt(
            {
                id: id.toString(),
                image: imageINV_1,
                ...currentPosition,
                width: 20,
                height: 60,
                offsetX: 0,
                offsetY: 0,
                type: Element.MS_VCT,
                isSelected: true,
                rotation: 0,
                chart: [],
                isRotationEnabled: false,
                parentGroupId: null,
                properties: responseData
            },
            shapes,
            Constant.GET_TOP_BOTTOM
        )
        if(result.voltage > 0){
            voltage = result.voltage
        }
    }
    const properties = response2VTModel(responseData,voltage);
    return {
        id: id.toString(),
        image: imageMsVT_1,
        ...currentPosition,
        width: 20,
        height: 40,
        type: Element.MS_VT,
        isSelected: true,
        parentGroupId: null,
        offsetX: 0,
        offsetY: 0,
        rotation: 0,
        chart: [],
        isRotationEnabled: false,
        isOverlapping: false,
        properties: properties
    } as ControlModel;
};

const createMsArrestor = (id: number, currentPosition: Coordinates, responseData: any,shapes:any,currentTabId:number) => {
    let voltage = responseData.voltage
    let result ={} as any
    if(currentTabId !==1){
        //inside transcenter
        result = doGetTransCenterSourceVolt(
            shapes,
            {   id: id.toString(),
                image: imageArrestor_1_0,
                ...currentPosition,
                width: 20,
                height: 60,
                type: Element.MS_ARRESTOR,
                isSelected: true,
                parentGroupId: null,
                offsetX: 0,
                offsetY: 0,
                rotation: 0,
                chart: [],
                isRotationEnabled: false,
                properties: responseData
            },
            Constant.GET_TOP_BOTTOM

        )
        if(result.voltage > 0){
            voltage = result.voltage
        }
        }
    else{
        //outside transcenter
        result = doGetSourceVolt(
            {
                id: id.toString(),
            image: imageArrestor_1_0,
            ...currentPosition,
            width: 20,
            height: 60,
            type: Element.MS_ARRESTOR,
            isSelected: true,
            parentGroupId: null,
            offsetX: 0,
            offsetY: 0,
            rotation: 0,
            chart: [],
            isRotationEnabled: false,
            properties: responseData
            },
            shapes,
            Constant.GET_TOP_BOTTOM

        )
        if(result.voltage > 0){
            voltage = result.voltage
        }
    }
    const properties = response2ArrestorModel(responseData,voltage);
    return {
        id: id.toString(),
        image: imageArrestor_1_0,
        ...currentPosition,
        width: 20,
        height: 60,
        type: Element.MS_ARRESTOR,
        isSelected: true,
        parentGroupId: null,
        offsetX: 0,
        offsetY: 0,
        rotation: 0,
        chart: [],
        isRotationEnabled: false,
        isOverlapping: false,
        properties: properties
    } as ControlModel;
};

const createMsEarth = (id: number, currentPosition: Coordinates, responseData: any,shapes:any,currentTabId:number) => {
    let voltage = responseData.voltage
    let result ={} as any
    if(currentTabId !==1){
        //inside transcenter
        result = doGetTransCenterSourceVolt(
            shapes,
            {   id: id.toString(),
                image: imageEarth_1,
                ...currentPosition,
                width: 20,
                height: 20,
                type: Element.MS_EARTH,
                isSelected: true,
                parentGroupId: null,
                offsetX: 0,
                offsetY: 0,
                rotation: 0,
                chart: [],
                isRotationEnabled: false,
                properties: responseData
            },
             Constant.GET_TOP_BOTTOM

        )
        if(result.voltage > 0){
            voltage = result.voltage
        }
        }
    else{
        //outside transcenter
        result = doGetSourceVolt(
            {
                id: id.toString(),
                image: imageEarth_1,
                ...currentPosition,
                width: 20,
                height: 20,
                type: Element.MS_EARTH,
                isSelected: true,
                parentGroupId: null,
                offsetX: 0,
                offsetY: 0,
                rotation: 0,
                chart: [],
                isRotationEnabled: false,
                properties: responseData
            },
            shapes,
            Constant.GET_TOP_BOTTOM

        )
        if(result.voltage > 0){
            voltage = result.voltage
        }
    }
      
    const properties = response2EarthModel(responseData,voltage);
    return {
        id: id.toString(),
        image: imageEarth_1,
        ...currentPosition,
        width: 20,
        height: 20,
        type: Element.MS_EARTH,
        isSelected: true,
        parentGroupId: null,
        offsetX: 0,
        offsetY: 0,
        rotation: 0,
        chart: [],
        isRotationEnabled: false,
        isOverlapping: false,
        properties: properties
    } as ControlModel;
};

const createMsCableHead = (id: number, currentPosition: Coordinates, responseData: any,shapes:any,currentTabId:number) => {

    let voltage = responseData.voltage
    let upTransKind = 0
    let sPhase = false
    let result = {} as any
    if(currentTabId !==1){
        //inside transcenter
        result = doGetTransCenterSourceVolt(
            shapes,
            {   id: id.toString(),
                image: imageCableHead_1,
                ...currentPosition,
                width: 20,
                height: 20,
                type: Element.MS_CABLEHEAD,
                isSelected: true,
                parentGroupId: null,
                offsetX: 0,
                offsetY: 0,
                rotation: 0,
                chart: [],
                isRotationEnabled: true,
                properties: responseData
            },
            Constant.GET_TOP_BOTTOM_LEFT_RIGHT,
            false,
            0
        )
        if(result.voltage > 0){
            voltage = result.voltage
            upTransKind = result.upTransKind??0
            sPhase = result.sPhase??false
        }
        }
    else{
        //outside transcenter
        result = doGetSourceVolt(
            {
            id: id.toString(),
            image: imageCableHead_1,
            ...currentPosition,
            width: 20,
            height: 20,
            type: Element.MS_CABLEHEAD,
            isSelected: true,
            parentGroupId: null,
            offsetX: 0,
            offsetY: 0,
            rotation: 0,
            chart: [],
            isRotationEnabled: true,
            properties: responseData
            },
            shapes,
            Constant.GET_TOP_BOTTOM_LEFT_RIGHT,
            false,
            0
        )
        if(result.voltage > 0){
            voltage = result.voltage
            upTransKind = result.upTransKind
            sPhase = result.sPhase
        }
    }
      
    const properties = response2CableHeadModel(responseData,voltage,upTransKind,sPhase);
    return {
        id: id.toString(),
        image: imageCableHead_1,
        ...currentPosition,
        width: 20,
        height: 20,
        type: Element.MS_CABLEHEAD,
        isSelected: true,
        parentGroupId: null,
        offsetX: 0,
        offsetY: 0,
        rotation: 0,
        chart: [],
        isRotationEnabled: true,
        isOverlapping: false,
        properties: properties
    } as ControlModel;
};

const createMsCT = (id: number, currentPosition: Coordinates, responseData: any,shapes:any,currentTabId:number) => {
    let voltage = responseData.voltage
    let upTransKind = 0
    let sPhase = false
    let result = {} as any
    if(currentTabId !==1){
        //inside transcenter
        result = doGetTransCenterSourceVolt(
            shapes,
            {   id: id.toString(),
                image: imageCT_1,
                ...currentPosition,
                width: 20,
                height: 40,
                type: Element.MS_CT,
                isSelected: true,
                parentGroupId: null,
                offsetX: 0,
                offsetY: 0,
                rotation: 0,
                chart: [],
                isRotationEnabled: false,
                properties: responseData
            },
            Constant.GET_TOP_BOTTOM_LEFT_RIGHT,
            false,
            0
        )
        if(result.voltage > 0){
            voltage = result.voltage
            upTransKind = result.upTransKind ?? 0
            sPhase = result.sPhase ?? false
        }
        }
    else{
        //outside transcenter
        result = doGetSourceVolt(
            {
            id: id.toString(),
            image: imageCT_1,
            ...currentPosition,
            width: 20,
            height: 40,
            type: Element.MS_CT,
            isSelected: true,
            parentGroupId: null,
            offsetX: 0,
            offsetY: 0,
            rotation: 0,
            chart: [],
            isRotationEnabled: false,
            properties: responseData
            },
            shapes,
            Constant.GET_TOP_BOTTOM_LEFT_RIGHT,
            false,
            0
        )
        if(result.voltage > 0){
            voltage = result.voltage
            upTransKind = result.upTransKind
            sPhase = result.sPhase
        }
    }
      
    const properties = response2CTModel(responseData,voltage,upTransKind,sPhase);
    return {
        id: id.toString(),
        image: imageCT_1,
        ...currentPosition,
        width: 20,
        height: 40,
        type: Element.MS_CT,
        isSelected: true,
        parentGroupId: null,
        offsetX: 0,
        offsetY: 0,
        rotation: 0,
        chart: [],
        isRotationEnabled: false,
        isOverlapping: false,
        properties: properties
    } as ControlModel;
};

const createMsZCT = (id: number, currentPosition: Coordinates, responseData: any,shapes:any,currentTabId:number) => {
    let voltage = responseData.voltage
    let result ={} as any
    if(currentTabId !==1){
        //inside transcenter
        result = doGetTransCenterSourceVolt(
            shapes,
            {   id: id.toString(),
                image: imageZCT_1,
                ...currentPosition,
                width: 20,
                height: 40,
                type: Element.MS_ZCT,
                isSelected: true,
                parentGroupId: null,
                offsetX: 0,
                offsetY: 0,
                rotation: 0,
                chart: [],
                isRotationEnabled: false,
                properties: responseData
            },
        Constant.GET_TOP_BOTTOM_LEFT_RIGHT,
            
        )
        if(result.voltage > 0){
            voltage = result.voltage
        }
        }
    else{
        //outside transcenter
        result = doGetSourceVolt(
            {
                id: id.toString(),
                image: imageZCT_1,
                ...currentPosition,
                width: 20,
                height: 40,
                type: Element.MS_ZCT,
                isSelected: true,
                parentGroupId: null,
                offsetX: 0,
                offsetY: 0,
                rotation: 0,
                chart: [],
                isRotationEnabled: false,
                properties: responseData
            },
            shapes,
            Constant.GET_TOP_BOTTOM_LEFT_RIGHT
        )
        if(result.voltage > 0){
            voltage = result.voltage
        }
    }
    const properties = response2ZCTModel(responseData,voltage)
    return {
        id: id.toString(),
        image: imageZCT_1,
        ...currentPosition,
        width: 20,
        height: 40,
        type: Element.MS_ZCT,
        isSelected: true,
        parentGroupId: null,
        offsetX: 0,
        offsetY: 0,
        rotation: 0,
        chart: [],
        isRotationEnabled: false,
        isOverlapping: false,
        properties: properties
    } as ControlModel;
};

const createMsINV = (id: number, currentPosition: Coordinates, responseData: any,shapes:any,currentTabId:number) => {
    let voltage = responseData.voltage
    let result = {} as any
    if(currentTabId !==1){
        //inside transcenter
        result = doGetTransCenterSourceVolt(
            shapes,
            {   id: id.toString(),
                image: imageINV,
                ...currentPosition,
                width: 20,
                height: 40,
                offsetX: 0,
                offsetY: 0,
                type: Element.MS_INV,
                isSelected: true,
                rotation: 0,
                chart: [],
                isRotationEnabled: false,
                parentGroupId: null,
                properties: responseData
            },
            Constant.GET_TOP_BOTTOM
        )
        if(result.voltage > 0){
            voltage = result.voltage
        }
        }
    else{
        //outside transcenter
        result = doGetSourceVolt(
            {
            id: id.toString(),
            image: imageINV,
            ...currentPosition,
            width: 20,
            height: 40,
            offsetX: 0,
            offsetY: 0,
            type: Element.MS_INV,
            isSelected: true,
            rotation: 0,
            chart: [],
            isRotationEnabled: false,
            parentGroupId: null,
            properties: responseData
            },
            shapes,
            Constant.GET_TOP_BOTTOM
        )
        if(result.voltage > 0){
            voltage = result.voltage
        }
    }
    const properties = response2INVModel(responseData,voltage);
    return {
        id: id.toString(),
        image: imageINV,
        ...currentPosition,
        width: 20,
        height: 40,
        offsetX: 0,
        offsetY: 0,
        type: Element.MS_INV,
        isSelected: true,
        rotation: 0,
        chart: [],
        isRotationEnabled: false,
        isOverlapping: false,
        parentGroupId: null,
        properties: properties
    } as ControlModel;
};

const createMsRelay = (id: number, currentPosition: Coordinates, responseData: any,shapes:any,currentTabId:number) => {
    let voltage = responseData.voltage
    let upTransKind = 0
    let sPhase = false
    let result  = {} as any
    if(currentTabId !==1){
        //inside transcenter
        result = doGetTransCenterSourceVolt(
            shapes,
            {   id: id.toString(),
                image: imageRelay_1,
                ...currentPosition,
                width: 20,
                height: 40,
                type: Element.MS_RELAY,
                isSelected: true,
                parentGroupId: null,
                offsetX: 0,
                offsetY: 0,
                rotation: 0,
                chart: [],
                isRotationEnabled: true,
                properties: responseData
            },
            Constant.GET_TOP_BOTTOM_LEFT_RIGHT,
            false,
            0
        )
        if(result.voltage > 0){
            voltage = result.voltage
            upTransKind = result.upTransKind??0
            sPhase = result.sPhase??false
        }
        }
    else{
        //outside transcenter
        result = doGetSourceVolt(
                {
                    id: id.toString(),
                    image: imageRelay_1,
                    ...currentPosition,
                    width: 20,
                    height: 40,
                    type: Element.MS_RELAY,
                    isSelected: true,
                    parentGroupId: null,
                    offsetX: 0,
                    offsetY: 0,
                    rotation: 0,
                    chart: [],
                    isRotationEnabled: true,
                    properties: responseData
                },
                shapes,
                Constant.GET_TOP_BOTTOM_LEFT_RIGHT,
                false,
                0
            )
            if(result.voltage > 0){
                voltage = result.voltage
                upTransKind = result.upTransKind
                sPhase = result.sPhase
            }
    }
      
    const properties = response2RelayModel(responseData,voltage,upTransKind,sPhase);
    return {
        id: id.toString(),
        image: imageRelay_1,
        ...currentPosition,
        width: 20,
        height: 40,
        type: Element.MS_RELAY,
        isSelected: true,
        parentGroupId: null,
        offsetX: 0,
        offsetY: 0,
        rotation: 0,
        chart: [],
        isRotationEnabled: true,
        isOverlapping: false,
        properties: properties
    } as ControlModel;
};