import { store } from "..";
import { ControlModel, MsZerocondModel, ProcessMode } from "../models/Index";
import { BeamsNumber } from "./FormatNumber";
import { 
    MS_3PHASES, 
    MS_VOLTDROP_REGULAR, 
    MS_OUTSIDE_OUTSIDE, 
    MS_OUTSIDE_NEUTRAL, 
    MS_SEAT_MAIN, 
    MS_SEAT_T, 
    MS_TRANS1, 
    MS_VOLTDROP_MOTOR, 
    MS_TRANSSCOTT,
    VALUE_POWER_MIN
} from "../models/Constants"
import {
    doGetIksymTotalMG,
    doGetTextDropVoltMotor as doGetTextDropVoltMotorCalPoint,
    doGetTextDropVoltMotor2 as DoGetTextDropVoltMotor2CalcPoint,
    doGetTextDropPerMotor as doGetTextDropPerMotorCalcPoint,
	doGetTextDropVoltRegular as doGetTextDropVoltRegularCalcPoint,
	doGetTextDropPerRegular as doGetTextDropPerRegularCalcPoint,
	doGetTextDropPerRegular2 as doGetTextDropPerRegular2CalcPoint,
    isSetDropPerMotor,
    doGetIkpeakTotalMG,
    doGetIkasymTotalMG,
    doGetPerZTotalMG
} from "./calcPointFunction";
import { doGetTextVoltDropRegular } from "./mssv3Doc"
import { isCennectLoadDropElement } from "./element"
import { FLOW_VIEW_EVERY_ELEMENT } from "../statics";

//CEleZeroCond::DoGetTextTotalIksym
export const doGetTextTotalIksym = (props:MsZerocondModel, nPartKind:number, bUnit:boolean = true) =>
{
	let dIsymTotal = 0;
	if (props.sPhase) {		
		switch (nPartKind) {
		case MS_OUTSIDE_NEUTRAL:	
		case MS_SEAT_MAIN:			
			dIsymTotal = doGetIksymTotalMG(props.calcPoint1);
			break;
		case MS_OUTSIDE_OUTSIDE:	
		case MS_SEAT_T:				
			dIsymTotal = doGetIksymTotalMG(props.calcPoint0);
			break;
		}
	}
	else {							
		dIsymTotal = doGetIksymTotalMG(props.calcPoint0);
	}
	let sText;
	if (dIsymTotal == 0) {
		if(props.primarySupply) {
            sText = '∞';
        }
        else {
         sText = '0';
        }
    }
    else if(dIsymTotal > 0) {
        sText = BeamsNumber(dIsymTotal / 1000, 4).toString();
        if(bUnit) {
            sText += 'kAsym';
        }
    }
    else {
        sText = '????';
    }
	return sText;
}

//CEleZeroCond::DoGetTextDropVoltMotor
export const doGetTextDropVoltMotor = (props:MsZerocondModel, bUnit:boolean = true) =>
{
	return doGetTextDropVoltMotorCalPoint(props.calcPoint0, bUnit);
}

//CEleZeroCond::DoGetTextDropVoltMotor2
export const doGetTextDropVoltMotor2 = (props:MsZerocondModel, bUnit:boolean = true) =>
{
	return DoGetTextDropVoltMotor2CalcPoint(props.calcPoint0, bUnit);
}

//CEleZeroCond::DoGetTextDropPerMotor
export const doGetTextDropPerMotor = (props:MsZerocondModel, bUnit:boolean = true) =>
{
	return doGetTextDropPerMotorCalcPoint(props.calcPoint0, bUnit);
}

//CEleZeroCond::DoGetTextVoltage
export const doGetTextVoltage = (props:MsZerocondModel, nPartKind:number, bUnit:boolean = true) =>
{
	let sText = '';
	if (props.sPhase) {
		switch (nPartKind) {
		case MS_OUTSIDE_NEUTRAL:	
			sText = (props.voltage / 2).toString();
			break;
		case MS_OUTSIDE_OUTSIDE:	
			sText = props.voltage.toString();
			break;
		case MS_SEAT_MAIN:			
			sText = props.voltage.toString();
			break;
		case MS_SEAT_T:				
			sText = props.voltage.toString();
			break;
		}
	}
	else {
		sText = props.voltage.toString();
	}
	if (bUnit) {
		sText += 'V';
	}
	return sText;
}

//CEleZeroCond::DoGetTextDropVoltRegular
export const doGetTextDropVoltRegular = (props:MsZerocondModel, dStdVoltage:number, nPartKind:number, bUnit:boolean = true) =>
{
	let sText = '';
	if (props.sPhase) {		
		switch (nPartKind) {
		case MS_OUTSIDE_NEUTRAL:	
		case MS_SEAT_MAIN:			
			sText = doGetTextDropVoltRegularCalcPoint(props.calcPoint1, dStdVoltage, 0, 0, bUnit);
			break;
		case MS_OUTSIDE_OUTSIDE:	
		case MS_SEAT_T:				
			sText = doGetTextDropVoltRegularCalcPoint(props.calcPoint0, dStdVoltage, 0, 0, bUnit);
			break;
		}
	}
	else {							
		sText = doGetTextDropVoltRegularCalcPoint(props.calcPoint0, dStdVoltage, 0, 0, bUnit);
	}

	return sText;
}

//CEleZeroCond::IsPointFaultZeroCond
export const isPointFaultZeroCond = (props:MsZerocondModel) =>
{
	if (props.refNo === undefined || props.refNo === null) {
		return false;
	}
	if (props.fault) {
		return true;
	}
	return false;
}

//CEleZeroCond::DoGetTextPointState
export const doGetTextPointState = (props:MsZerocondModel) =>
{
	let sText = ''; 

	if (props.fault) {
		sText = 'Yes';
	}
	else {
		sText = 'No';
	}

	return sText;
}

//CEleZeroCond::DoGetTextDropPerRegular
export const doGetTextDropPerRegular = (props:MsZerocondModel, dStdVoltage:number, nPartKind:number, bUnit:boolean = true) =>
{
	let sText = '';
	if (props.sPhase) {		
		switch (nPartKind) {
		case MS_OUTSIDE_NEUTRAL:	
		case MS_SEAT_MAIN:			
			sText = doGetTextDropPerRegularCalcPoint(props.calcPoint1, dStdVoltage, 0, 0, bUnit);
			break;
		case MS_OUTSIDE_OUTSIDE:	
		case MS_SEAT_T:				
			sText = doGetTextDropPerRegularCalcPoint(props.calcPoint0, dStdVoltage, 0, 0, bUnit);
			break;
		}
	}
	else {							
		sText = doGetTextDropPerRegularCalcPoint(props.calcPoint0, dStdVoltage, 0, 0, bUnit);
	}

	return sText;
}

//CEleZeroCond::DoGetTextImproveBeforePF
export const doGetTextImproveBeforePF = (props:MsZerocondModel) =>
{
	return BeamsNumber(props.beforePF, 5).toString();
}

//CEleZeroCond::DoGetTextImproveAfterPF
export const doGetTextImproveAfterPF = (props:MsZerocondModel) =>
{
	return BeamsNumber(props.afterPF, 5).toString();
}

//CEleZeroCond::DoGetTextCapacitorCapacity
export const doGetTextCapacitorCapacity = (props:MsZerocondModel, bUnit:boolean = true) =>
{
	let dCapacity = Math.sqrt(3.0) * props.totalCurrent * props.voltage * Math.abs(props.beforePF);
	let dtanBefore = 0;
	if (props.beforePF != 0) {
		dtanBefore = Math.sqrt(1 - props.beforePF * props.beforePF) / props.beforePF;
	}
	let dtanAfter = 0;
	if (props.beforePF != 0) {
		dtanAfter = Math.sqrt(1 - props.afterPF * props.afterPF) / props.afterPF;
	}

	let dCapacitorCapacity = dCapacity * (dtanBefore - dtanAfter) / 1000;
	if (Math.abs(dCapacitorCapacity) < VALUE_POWER_MIN) {
		dCapacitorCapacity = 0;
	}
	let sText = BeamsNumber(dCapacitorCapacity, 5).toString();

	if (bUnit) {
		sText += "kvar";
	}

	return sText;
}

//CEleZeroCond::IsZeroResultFlow
export const isZeroResultFlow = (props:MsZerocondModel) =>
{
	if (props.voltageMagnitude == 0 &&
		props.voltageAngle == 0) {
		return true;
	}
	else {
		return false;
	}
}

//CEleZeroCond::DoGetTextVoltageMagnitude
export const doGetTextVoltageMagnitude = (props:MsZerocondModel) =>
{
	return BeamsNumber(props.voltageMagnitude, 6).toString();
}

// CEleZeroCond::DoGetTextVoltageAngle
export const doGetTextVoltageAngle = (props:MsZerocondModel) =>
{
	return BeamsNumber(props.voltageAngle, 5).toString();
}

//CEleZeroCond::DoGetRefV1
export const doGetRefV1 = (mode:any, control:ControlModel) =>
{
    let props = control.properties as MsZerocondModel;
    let project = store.getState().app.projectData;

	//CEleZeroCond::DoGetRefV1Left sRefV1Left
	let labelTexts = [{
		value: control.properties.refNo,
		color: "#f800f8",
	}];

	if (!props.viewResultText || (mode != ProcessMode.DRAWING && !props.supply))
    {
      return labelTexts;
    }

    switch(mode) {
        case ProcessMode.DRAWING:
        case ProcessMode.CHECKING:
        case ProcessMode.PERCENT_Z:
            break;
        case ProcessMode.CALCULATION:
            if(!props.fault) {
                break;
            }
            if(props.sPhase) {
                switch(props.upTransKind) {
                    case MS_TRANS1:
                        // '外線-外線 ' + 'I"k2' + '合計' + '=' + DoGetTextDirectIksym()
                        labelTexts.push({
                            value: '外線-外線 I"k2合計='
                            + doGetTextTotalIksym(props, MS_OUTSIDE_OUTSIDE),
                            color: "blue",
                        });

                        // '外線-中性線 ' + 'I"k2' + '合計' + '=' + DoGetTextDirectIksym()
                        labelTexts.push({
                            value: '外線-中性線 I"k2合計='
                            + doGetTextTotalIksym(props, MS_OUTSIDE_NEUTRAL),
                            color: "blue",
                        });

                        break;
                    case MS_TRANSSCOTT:
                        // '主座 ' + 'I"k2' + '合計' + '=' + DoGetTextDirectIksym()
                        labelTexts.push({
                            value: '主座 I"k2合計='
                            + doGetTextTotalIksym(props, MS_SEAT_MAIN),
                            color: "blue",
                        });
                        
                        // 'T座 ' + 'I"k2' + '合計' + '=' + DoGetTextDirectIksym()
                        labelTexts.push({
                            value: 'T座 I"k2合計='
                            + doGetTextTotalIksym(props, MS_SEAT_T),
                            color: "blue",
                        });

                        break;
                }
            }
            else
            {
                 // 'I"k3' + '合計' + '=' + DoGetTextDirectIksym()
                 labelTexts.push({
                    value: 'I"k3合計='
                    + doGetTextTotalIksym(props, MS_3PHASES),
                    color: "blue",
                });
            }
            break;
        case ProcessMode.VOLTDROP:
            if(project.dropMode == MS_VOLTDROP_MOTOR && props.dropMotor && !props.sPhase && isSetDropPerMotor(props.calcPoint0)) {
                // Vd = DropVoltMotor(VoltSecondary - DropVoltMotor2)
                let sTemp = 'Vd=' + doGetTextDropVoltMotor(props) + ' (';
                if (props.supply) {
                    sTemp += doGetTextVoltage(props, MS_3PHASES, false);
                    sTemp += '-';
                    sTemp += doGetTextDropVoltMotor2(props, false);
                } else {
                    sTemp += '0-0'
                }
                sTemp += ')';
                labelTexts.push({
                    value: sTemp,
                    color: "blue",
                });

                //Vp = DoGetTextDropPerMotor (VoltSecondary → DropVoltMotor2)
                sTemp = 'Vp=' + doGetTextDropPerMotor(props) + ' (';
                if (props.supply) {
                    sTemp += doGetTextVoltage(props, MS_3PHASES, false);
                    sTemp += '→';
                    sTemp += doGetTextDropVoltMotor2(props, false);
                } else {
                    sTemp += '0→0';
                }
                sTemp += ')';
                labelTexts.push({
                    value: sTemp,
                    color: "blue",
                });

                break;
            }
            if(project.dropMode == MS_VOLTDROP_REGULAR && props.dropRegular) {
                let stdVoltage = {
                    dStdVoltage : 0,
                    sStdVoltage : '0'
                };
                let sTemp = '';
                if(props.sPhase) {
                    switch(props.upTransKind) {
                        case MS_TRANS1:
                            if(props.supply) {
                                stdVoltage = doGetTextVoltDropRegular(control, MS_OUTSIDE_OUTSIDE, false);
                            }
                            
                            //外線-外線 Vp=DoGetTextDropPerRegular (DoGetTextVoltage→DoGetTextDropVoltRegular)
                            sTemp = '外線-外線 Vp=';
                            sTemp += doGetTextDropPerRegular(props, stdVoltage.dStdVoltage, MS_OUTSIDE_OUTSIDE);
                            sTemp += ' (';
                            if(props.supply) {
                                sTemp += doGetTextVoltage(props, MS_OUTSIDE_OUTSIDE, false);
                            }
                            else {
                                sTemp += stdVoltage.sStdVoltage;
                            }
                            sTemp += '→';
                            sTemp += doGetTextDropVoltRegular(props, stdVoltage.dStdVoltage, MS_OUTSIDE_OUTSIDE, false);
                            sTemp += ')';
                            labelTexts.push({
                                value: sTemp,
                                color: "blue",
                            });

                            if(props.supply) {
                                stdVoltage = doGetTextVoltDropRegular(control, MS_OUTSIDE_NEUTRAL, false);
                            }
                                
                            //外線-中性線 Vp=DoGetTextDropPerRegular (DoGetTextVoltage→DoGetTextDropVoltRegular)
                            sTemp = '外線-中性線 Vp=';
                            sTemp += doGetTextDropPerRegular(props, stdVoltage.dStdVoltage, MS_OUTSIDE_NEUTRAL);
                            sTemp += ' (';
                            if(props.supply) {
                                sTemp += doGetTextVoltage(props, MS_OUTSIDE_NEUTRAL, false);
                            }
                            else {
                                sTemp += stdVoltage.sStdVoltage;
                            }
                            sTemp += '→';
                            sTemp += doGetTextDropVoltRegular(props, stdVoltage.dStdVoltage, MS_OUTSIDE_NEUTRAL, false);
                            sTemp += ')';
                            labelTexts.push({
                                value: sTemp,
                                color: "blue",
                            });
                            
                            break;
                        case MS_TRANSSCOTT:
                            if(props.supply) {
                                stdVoltage = doGetTextVoltDropRegular(control, MS_SEAT_MAIN, false);
                            }
                            
                            //主座 Vp=DoGetTextDropPerRegular (DoGetTextVoltage→DoGetTextDropVoltRegular)
                            sTemp = '主座 Vp=';
                            sTemp += doGetTextDropPerRegular(props, stdVoltage.dStdVoltage, MS_SEAT_MAIN);
                            sTemp += ' (';
                            if(props.supply) {
                                sTemp += doGetTextVoltage(props, MS_SEAT_MAIN, false);
                            }
                            else {
                                sTemp += stdVoltage.sStdVoltage;
                            }
                            sTemp += '→';
                            sTemp += doGetTextDropVoltRegular(props, stdVoltage.dStdVoltage, MS_SEAT_MAIN, false);
                            sTemp += ')';
                            labelTexts.push({
                                value: sTemp,
                                color: "blue",
                            });

                            if(props.supply) {
                                stdVoltage = doGetTextVoltDropRegular(control, MS_SEAT_T, false);
                            }
                                
                            //T座 Vp=DoGetTextDropPerRegular (DoGetTextVoltage→DoGetTextDropVoltRegular)
                            sTemp = 'T座 Vp=';
                            sTemp += doGetTextDropPerRegular(props, stdVoltage.dStdVoltage, MS_SEAT_T);
                            sTemp += ' (';
                            if(props.supply) {
                                sTemp += doGetTextVoltage(props, MS_SEAT_T, false);
                            }
                            else {
                                sTemp += stdVoltage.sStdVoltage;
                            }
                            sTemp += '→';
                            sTemp += doGetTextDropVoltRegular(props, stdVoltage.dStdVoltage, MS_SEAT_T, false);
                            sTemp += ')';
                            labelTexts.push({
                                value: sTemp,
                                color: "blue",
                            });
                            
                            break; 
                    }
                }
                else {
                    if(props.supply) {
                        stdVoltage = doGetTextVoltDropRegular(control, MS_3PHASES, false);
                    }

                    //Vp=DoGetTextDropPerRegular (DoGetTextVoltage→DoGetTextDropVoltRegular)
                    let sTemp = 'Vp=';
                    sTemp += doGetTextDropPerRegular(props, stdVoltage.dStdVoltage, MS_3PHASES);
                    sTemp += ' (';
                    if(props.supply) {
                        sTemp += doGetTextVoltage(props, MS_3PHASES, false);
                    }
                    else {
                        sTemp += stdVoltage.sStdVoltage;
                    }
                    sTemp += '→';
                    sTemp += doGetTextDropVoltRegular(props, stdVoltage.dStdVoltage, MS_3PHASES, false);
                    sTemp += ')';
                    labelTexts.push({
                        value: sTemp,
                        color: "blue",
                    });
                }
                break;
            }
            break;
        case ProcessMode.IMPROVE_PF:
            if(!props.calcImprovePF) {
                break;
            }

            // 負荷力率=doGetTextImproveBeforePF
            labelTexts.push({
                value: '負荷力率='
                + doGetTextImproveBeforePF(props),
                color: "blue",
            });

            // 目標力率=doGetTextImproveAfterPF
            labelTexts.push({
                value: '目標力率='
                + doGetTextImproveAfterPF(props),
                color: "blue",
            });

            // Qc=doGetTextImproveAfterPF
            labelTexts.push({
                value: 'Qc='
                + doGetTextCapacitorCapacity(props),
                color: "blue",
            });
            break;
        case ProcessMode.HARMONIC:
            break;
        case ProcessMode.POWER_FLOW:
            if(!props.sPhase && props.viewFlowResult && project.flowViewMethod == FLOW_VIEW_EVERY_ELEMENT && !isZeroResultFlow(props)) {
                //Vmagn=doGetTextVoltageMagnitude
                labelTexts.push({
                    value: 'Vmagn='
                    + doGetTextVoltageMagnitude(props) + 'V',
                    color: "blue",
                });

                //Vangle=doGetTextVoltageAngle
                labelTexts.push({
                    value: 'Vangle='
                    + doGetTextVoltageAngle(props) + '度',
                    color: "blue",
                });
            }
            break;
        default:
            break;
    }

    return labelTexts;
}

//CEleZeroCond::DoGetTextTotalIkpeak
export const doGetTextTotalIkpeak = (props:MsZerocondModel, nPartKind:number, bUnit:boolean = true) =>
{
	let dIpeakTotal = 0;
	if (props.sPhase) {		
		switch (nPartKind) {
		case MS_OUTSIDE_NEUTRAL:	
		case MS_SEAT_MAIN:			
			dIpeakTotal = doGetIkpeakTotalMG(props.calcPoint1);
			break;
		case MS_OUTSIDE_OUTSIDE:	
		case MS_SEAT_T:				
			dIpeakTotal = doGetIkpeakTotalMG(props.calcPoint0);
			break;
		}
	}
	else {							
		dIpeakTotal = doGetIkpeakTotalMG(props.calcPoint0);
	}

	let sText = '';
	if (dIpeakTotal == 0) {
		if (props.primarySupply) {
			sText = '∞';
		}
		else {
			sText = "0";
		}
	}
	else if (dIpeakTotal > 0) {
		sText = BeamsNumber(dIpeakTotal / 1000, 4).toString();
		if (bUnit) {
			sText += 'kApeak';
		}
	}
	else {							
		sText = '????';
	}
	return sText;
}

//CEleZeroCond::DoGetTextTotalIkasym
export const doGetTextTotalIkasym = (props:MsZerocondModel, nPartKind:number, bUnit:boolean = true) =>
{
	let dIasymTotal = 0;
	if (props.sPhase) {		
		switch (nPartKind) {
		case MS_OUTSIDE_NEUTRAL:	
		case MS_SEAT_MAIN:			
			dIasymTotal = doGetIkasymTotalMG(props.calcPoint1);
			break;
		case MS_OUTSIDE_OUTSIDE:	
		case MS_SEAT_T:				
			dIasymTotal = doGetIkasymTotalMG(props.calcPoint0);
			break;
		}
	}
	else {							
		dIasymTotal = doGetIkasymTotalMG(props.calcPoint0);
	}

	let sText = '';
	if (dIasymTotal == 0) {
		if (props.primarySupply) {
			sText = '∞';
		}
		else {
			sText = "0";
		}
	}
	else if (dIasymTotal > 0) {
		sText = BeamsNumber(dIasymTotal / 1000, 4).toString();
		if (bUnit) {
			sText += 'kAasym';
		}
	}
	else {							
		sText = '????';
	}
	return sText;
}

//CEleZeroCond::DoGetTextTotalPercentZr
export const doGetTextTotalPercentZr = (props:MsZerocondModel, nPartKind:number, bUnit:boolean = true) =>
{
	let dPerZ = 0;
	if (props.sPhase) {		
		switch (nPartKind) {
		case MS_OUTSIDE_NEUTRAL:	
		case MS_SEAT_MAIN:			
			dPerZ = doGetPerZTotalMG(props.calcPoint1).dPercentZ;
			break;
		case MS_OUTSIDE_OUTSIDE:	
		case MS_SEAT_T:				
			dPerZ = doGetPerZTotalMG(props.calcPoint0).dPercentZ;
			break;
		}
	}
	else {							
		dPerZ = doGetPerZTotalMG(props.calcPoint0).dPercentZ;
	}

	let sText = '';
	if (dPerZ >= 0) {
		sText = BeamsNumber(dPerZ, 4).toString();
		if (bUnit) {
			sText += '%r';
		}
	}
	else {						
		sText = '????';
	}
	return sText;
}