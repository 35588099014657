import { Button, CircularProgress, Stack } from "@mui/material";
import { Html } from "react-konva-utils";
import { connect, useDispatch } from "react-redux";
import {
  copyAction,
  cutAction,
  openChart,
  pasteAction,
  removeAction,
  rotate,
  groupAction,
  unGroupAction,
  deleteDiagramByTabId,
  updatePropertiesOfTransCenter,
  addLineToChart,
  addCalcPointToChart,
  updateQuickProperties,
  isShowMaintenanceScreen,
} from "../../store/Actions";
import "./styles.css";
import { ControlModel, Coordinates, ProcessMode } from "../../models/Index";
import { useEffect, useState } from "react";
import AlertDialog from "../common/AlertDialog";
import * as EKind from "../../models/ElementKind";
import { doGetValuesTransCenter } from "../../utils/TransCenterFunction";
import { ApplicationState, actionCreators } from "../../store";
import GroupListDialog from "../dialogs/GroupListDialog";
import { doGetSupplyEle, isPointFaultAND } from "../../utils/element";
import { store } from "../..";
import { elementNotInTransCenter } from "../../statics";
import { onCanEditFlowResultOff, onCanEditFlowResultOn, onCanUpdateEditDropOff, onCanUpdateEditDropOn, onCanUpdateEditFaultOff, onCanUpdateEditFaultOn, onCanUpdateEditImproveOff, onCanUpdateEditImproveOn, onCanUpdateEditOff, onCanUpdateEditOn } from "../../utils/Mssv3View1";
import { AppLoadingAction } from "../../store/AppStore";
import { useNavigate } from "react-router-dom";
import { deleteFileByIdsS3 } from "../CallApi";
import { controlCanRotate } from "../dxfFile/dataConverter";

//#region Props
type OptionMenu = {
  controlId?: string;
  isOpenMenu: boolean;
  setIsOpenMenu: (isOpen: boolean) => void;
  onProperties: () => void;
  onUserCurves?: () => void;
  x: number;
  y: number;
  isDisabledDelete?: boolean;
  isDisabledCut?: boolean;
  isDisabledCopy?: boolean;
  isDisabledPaste?: boolean;
  isDisabledOpenGraph?: boolean;
  isDisabledRotate?: boolean;
  isDisabledGroup?: boolean;
  isDisabledUnGroup?: boolean;
  isDisabledAddCharacteristicCurve?: boolean;
  isDisabledAddShortCircuitCurrent?: boolean;
  isDisabledUserCurve?: boolean;
  isDisabledProps?: boolean;
};

export type OptionMenuProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OptionMenu;
//#endregion

const optionStyle = {
  justifyContent: 'flex-start',
  padding: '4px 5px',
  '&.Mui-disabled': { color: '#a6a6a6' },
  '&:hover': { backgroundColor: '#e6f7ff' }
}

const OptionMenu = (props: OptionMenuProps) => {
  //#region Fields
  const {
    userId,
    currUser,
    projectData,
    elementGroups,
    tcGroups,
    controlId,
    shapes,
    transformData,
    isOpenMenu,
    isDisabledDelete,
    isDisabledCut,
    isDisabledCopy,
    isDisabledPaste,
    isDisabledOpenGraph,
    isDisabledRotate,
    isDisabledUnGroup,
    isDisabledAddCharacteristicCurve,
    isDisabledAddShortCircuitCurrent,
    isDisabledUserCurve,
    isDisabledProps,
    x,
    y,
    currentTabId,
    chartDataList,
    diagramDataList,
    currentIDChartTab,
    isOpenChart,
    processMode,
    
    pointerPosition,
    copyShapes,
    copyEleGroups,
    copyTCEleGroups,
    copyDiagramData,
    m_bModePM,
    modeViewOnly,
  } = props;

  const {
    createGroupList,
    createGroup,
    deleteGroup,
    setIsOpenMenu,
    updateRotate,
    setOpenChart,
    setLineToChart,
    setCalcPointToChart,
    copyAction,
    cutAction,
    pasteElementAndGroup,
    removeAction,
    onProperties,
    onUserCurves,
    deleteDiagramByTabId,
    updateTransCenterProps,
    onUpdateQuickProperties,
    onCalcVoltDrop,
    isShowMaintenanceScreen,
  } = props;
  const dispatch = useDispatch()
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openGroupListDialog, setOpenGroupListDialog] = useState(false);
  const [checkAbleToShowLineToChart, setCheckAbleToShowLineToChart] = useState(true);
  const [checkAbleToShowCalcPointToChart, setCheckAbleToShowCalcPointToChart] = useState(true);


  // Processmode (CHECKING, PERCENTZ)
  const [editFaultOnFlag,setEditFaultOnFlag] = useState<boolean>(false);
  const [editFaultOffFlag,setEditFaultOffFlag] = useState<boolean>(false);
  const [editDropOnFlag,setEditDropOnFlag] = useState<boolean>(false);
  const [editDropOffFlag,setEditDropOffFlag] = useState<boolean>(false);
  const [editImproveOnFlag,setEditImproveOnFlag] = useState<boolean>(false);
  const [editImproveOffFlag,setEditImproveOffFlag] = useState<boolean>(false);
  const [editFlowResutOnFlag,setEditFlowResultOnFlag] = useState<boolean>(false);
  const [editFlowResutOffFlag,setEditFlowResultOffFlag] = useState<boolean>(false);
  const [enableEditSwitchOnFlag,setEnableEditSwitchOnFlag] = useState<boolean>(false);
  const [enableEditSwitchOffFlag,setEnableEditSwitchOffFlag] = useState<boolean>(false);
  const [disableMaintain,setDisableMaintain] = useState<boolean>(false);
  const [isDisabledRotate1,setIsDisabledRotate] = useState<boolean>(true);


  const mainDiagramData = diagramDataList.find((e: any) => e.tabId === 1);
  const transCenter = mainDiagramData?.shape.find(
    (e: any) => e.properties?.tabId === currentTabId
  );
  const groupsData = currentTabId === 1 ? elementGroups : tcGroups[transCenter?.id ?? ""]
  const controlClick = diagramDataList.find((item:any) => item.tabId === currentTabId)?.shape.filter((item:any) => item.id == controlId);
  //#endregion

  useEffect(()=>{
    handleCheckAbleToShowLine();
    handleCheckAbleToShowCalcPoint();
    // if(processMode === ProcessMode.CHECKING || processMode === ProcessMode.PERCENT_Z){
      const currentShape = diagramDataList.find(item => item.tabId === currentTabId)?.shape.filter((item) => item.isSelected === true);
      if(currentShape && currentShape.length > 0 && currentShape.filter((item:any) => item.parentGroupId == null).map((item:any) => item.type).some((type:any) => controlCanRotate.includes(type))){
        setIsDisabledRotate(false)
      }
      else{
          setIsDisabledRotate(true)
      }
      setEditFaultOnFlag(onCanUpdateEditFaultOn(currentTabId,currentShape))
      setEditFaultOffFlag(onCanUpdateEditFaultOff(currentTabId,currentShape))
      setEditDropOnFlag(onCanUpdateEditDropOn(currentTabId,currentShape))
      setEditDropOffFlag(onCanUpdateEditDropOff(currentTabId,currentShape))
      setEditImproveOnFlag(onCanUpdateEditImproveOn(currentTabId,currentShape))
      setEditImproveOffFlag(onCanUpdateEditImproveOff(currentTabId,currentShape))
      setEditFlowResultOnFlag(onCanEditFlowResultOn(currentTabId,currentShape))
      setEditFlowResultOffFlag(onCanEditFlowResultOff(currentTabId,currentShape))
      setEnableEditSwitchOnFlag(onCanUpdateEditOn(currentTabId,currentShape))
      setEnableEditSwitchOffFlag(onCanUpdateEditOff(currentTabId,currentShape))
      handleDisplayMaintain()
    // }
  },[])
  const handleDisplayMaintain =() =>{
    const control = controlClick?controlClick[0].type:''
    if([EKind.MS_TEXT,EKind.MS_ZEROCOND,EKind.MS_CONNECT].includes(control)){
      setDisableMaintain(true)
    }
    else{
      setDisableMaintain(false)
    }
  }
  const handleCheckAbleToShowLine = () =>{
    
    if(diagramDataList.find(data => data.tabId === currentTabId)!.shape.filter((x:any)=>x.isSelected).length > 1)
    {
      const arr = diagramDataList.find(data => data.tabId === currentTabId)!.shape.filter((x:any)=>x.isSelected)
      for(let i=0; i < arr.length; i++)
      {
        const checkIsShow = chartDataList.find(data => data.tabId === currentIDChartTab)?.shape.find(shapes => shapes.chart.find(charts => (charts.lineType === "USER_CURVE"? false : charts.controlId === arr[i].id) && !charts.lineId.toString().includes("CALC_POINT")))
        const checkEle = diagramDataList.find(data => data.tabId === currentTabId)?.shape.find(shapes => shapes.id === arr[i].id && (shapes.type === EKind.MS_TRANS3 || shapes.type === EKind.MS_3WINDING || shapes.type === EKind.MS_TRANS1 || shapes.type === EKind.MS_TRANSSCOTT || shapes.type === EKind.MS_HVCB || shapes.type === EKind.MS_FUSE || shapes.type === EKind.MS_2E || shapes.type === EKind.MS_LVCB || shapes.type === EKind.MS_THERMAL || shapes.type === EKind.MS_THERMAL_CT || shapes.type === EKind.MS_MOTOR || (shapes.type === EKind.MS_CAPACITOR && (Number(shapes.properties.totalOhmR) > 0 || Number(shapes.properties.totalOhmR) > 0)) || shapes.type === EKind.MS_SOURCE))
        if(checkEle && checkIsShow === undefined && (isOpenChart.payload?.mode || currentIDChartTab > 0)) {
          setCheckAbleToShowLineToChart(false)
          break
        }
      }
    }
    else
    {
      const isShow = chartDataList.find(data => data.tabId === currentIDChartTab)?.shape.find(shapes => shapes.chart.find(charts => (charts.lineType === "USER_CURVE"? false : charts.controlId === controlId) && !charts.lineId.toString().includes("CALC_POINT")))
      const isCheckEle = diagramDataList.find(data => data.tabId === currentTabId)?.shape.find(shapes => shapes.id === controlId && (shapes.type === EKind.MS_TRANS3 || shapes.type === EKind.MS_3WINDING || shapes.type === EKind.MS_TRANS1 || shapes.type === EKind.MS_TRANSSCOTT || shapes.type === EKind.MS_HVCB || shapes.type === EKind.MS_FUSE || shapes.type === EKind.MS_2E || shapes.type === EKind.MS_LVCB || shapes.type === EKind.MS_THERMAL || shapes.type === EKind.MS_THERMAL_CT || shapes.type === EKind.MS_MOTOR || (shapes.type === EKind.MS_CAPACITOR && (Number(shapes.properties.totalOhmR) > 0 || Number(shapes.properties.totalOhmR) > 0)) || shapes.type === EKind.MS_SOURCE))
      if(isCheckEle && isShow === undefined && (isOpenChart.payload?.mode || currentIDChartTab > 0)) {
        setCheckAbleToShowLineToChart(false)
      }
    }
  }

  const handleCheckAbleToShowCalcPoint = () =>{
    const chart = chartDataList.find(data => data.tabId === currentIDChartTab)?.shape.find(shapes => shapes.chart.find(charts => charts.controlId === controlId))
    const chooseShape = shapes.filter((item: ControlModel) => item.isSelected === true)[0];
    const isCheckEle = diagramDataList.find(data => data.tabId === currentTabId)?.shape.find(shapes => shapes.id === controlId)
    if(chart === undefined && (isOpenChart.payload?.mode || currentIDChartTab > 0) && processMode > 3 && isPointFaultAND(chooseShape)) {
      if(isCheckEle && doGetSupplyEle(isCheckEle))
      {
        setCheckAbleToShowCalcPointToChart(false)
        //setCheckAbleToShowLineToChart(false)
      }
    }
  }

  const handleOpenChart = () => {
    setOpenChart(true);
    setIsOpenMenu(false);
  };

  const handleAddToChart = () => {
    setLineToChart(true, controlId??'');
    setIsOpenMenu(false);
  };

  const handleAddCalcPointToChart = () => {
    setCalcPointToChart(true, controlId??'');
    setIsOpenMenu(false);
  };

  const handleRotation = () => {
    updateRotate(currentTabId);
    setIsOpenMenu(false);
  };

  // コピ
  const handleCopy = () => {
    copyAction(currentTabId);
    setIsOpenMenu(false);
  };

  // 切り取り
  const handleCut = () => {
    cutAction(currentTabId);
    removeAction(currentTabId);
    
    const selected = shapes.filter((shape: any) => shape.isSelected === true)
    if (currentTabId !== 1){
      let diagramData = shapes.filter((e: any) => !selected.includes(e))
      doSetValuesTransCenter(diagramData)
    } else {
      // delete trans center
      selected.forEach((control: any) => {
        if (control.type === EKind.MS_TRANSCENTER){
          deleteDiagramByTabId(control.properties?.tabId)
        }
      })
    }
    // Amazon S3: delete maintenance file
    deleteFileByIdsS3(
      currUser?.userSub ?? '',
      projectData.projectId,
      selected.map((e: any) => e.id)
  )
    setIsOpenMenu(false);
  };

  // 貼り付け
  const handlePaste = () => {
    if (copyShapes.length > 0){
      let transCenterId = null as any
      if (currentTabId !== 1){
        // handle paste invalid control into trans center
        let invalid = false
        copyShapes.forEach((element: any) => {
          if (elementNotInTransCenter.includes(element.type)){
            invalid = true
          }
        });
        if (invalid) return
        
        transCenterId = transCenter?.id
      }
      let params = {
        userId: userId,
        projectId: projectData.projectId,
        elements: copyShapes,
        groups: copyEleGroups,
        tcGroups: copyTCEleGroups,
        tcDiagramList: copyDiagramData,
        x: pointerPosition.x,
        y: pointerPosition.y,
        transCenterId: transCenterId,
        ownerProject: projectData.createUserId
      } as any
      // setLoadingFlag(true)
      pasteElementAndGroup(params)
    }
    // pasteAction(currentTabId, { x, y });
    setIsOpenMenu(false);
  };

  // 削除
  const handleRemove = () => {
    setOpenDeleteDialog(true);
  };

  // プロパティ
  const handleProperties = () => {
    onProperties();
    setIsOpenMenu(false);
  };

  const handleUserCurves = () => {
    onUserCurves && onUserCurves()
    setIsOpenMenu(false);
  }

  const isAbleToDrawUserCurve = () => {
    const selectedControls = shapes.filter((control: ControlModel) => control.isSelected === true)
    return selectedControls.length === 1 && selectedControls[0].id === controlId
  }

  const handleDeleteOK = () => {
    removeAction(currentTabId);
    setIsOpenMenu(false);
  };

  const handleDeleteCancel = () => {
    setOpenDeleteDialog(false);
  };

  const isAbleToGroup = () => {
    const peekGroupId = groupsData ? groupsData.byEleId[controlId as any]?.at(-1) ?? -1 : -1
    const elementIds = groupsData ? groupsData.byId[peekGroupId]?.elementIds ?? [] : [];

    if (
      shapes.filter((item:any)=> item.isSelected === true).length > 1 &&
      !shapes.filter((item:any)=> item.isSelected === true).every((el: any) => {
        return elementIds.includes(el.id);
      })
    )
      return true;
    return false;
  };

  const isAbleToUnGroup = () => {
    return groupsData ? groupsData.byEleId[controlId as any] && groupsData.byEleId[controlId as any].length > 0 : false;
  };

  const handleGroup = () => {
    let controls = shapes.filter((item:any)=> item.isSelected === true);
    const toGroupEleIds = controls.map((item: any) => item.id)
    const checkedIds: string[] = [];
    
    const groups : any = []
    const elements : any = []
    toGroupEleIds.forEach((id: string) => {            
        if (checkedIds.includes(id)) return;
        const peekGroupId = groupsData.byEleId[id as any]?.at(-1) ?? -1
        if (peekGroupId !== -1) {
          const elementIds = groupsData.byId[peekGroupId]?.elementIds ?? []
          checkedIds.push(...elementIds);
          groups.push(peekGroupId)
        } else {
          checkedIds.push(id);
          elements.push(id);
        }
    })
    if (toGroupEleIds.length > 0) {
      if (currentTabId === 1) {
        const param = {
          userId: userId,
          projectId: projectData.projectId,
          elements,
          groups,
          ownerProject: projectData.createUserId
        }
        createGroup(param, `create-group`)
      } else {
        const mainDiagramData = diagramDataList.find((e: any) => e.tabId === 1);
        const transCenter = mainDiagramData?.shape.find(
          (e: any) => e.properties?.tabId === currentTabId
        );
        const param = {
          userId: userId,
          projectId: projectData.projectId,
          elements,
          groups,
          tcId: transCenter?.id,
          ownerProject: projectData.createUserId
        }
        createGroup(param, `create-tcgroup`)
      }
    }
    setIsOpenMenu(false);
  };

  const handleUnGroup = () => {
    const selected = shapes.filter((shape: any) => shape.isSelected === true)

    const toDeleteGroupIds : any = []
    const checkedIds : any = []
    selected.forEach((control: any) => {
      if (checkedIds.includes(control.id)) return;
      const peekGroupId = groupsData.byEleId[control.id as any]?.at(-1) ?? -1
      if (peekGroupId !== -1) {
        const elementIds = groupsData.byId[peekGroupId]?.elementIds ?? []
        checkedIds.push(...elementIds)
        toDeleteGroupIds.push(peekGroupId)
      } else {
        checkedIds.push(control.id)
      }
    })

    if (currentTabId === 1) {
      const param = {
        userId: userId,
        projectId: projectData.projectId,
        groupIds: toDeleteGroupIds,
        ownerProject: projectData.createUserId
      }
      deleteGroup(param, `delete-group`)    
    } else {
      const mainDiagramData = diagramDataList.find((e: any) => e.tabId === 1);
      const transCenter = mainDiagramData?.shape.find(
        (e: any) => e.properties?.tabId === currentTabId
      );
      const param = {
        userId: userId,
        projectId: projectData.projectId,
        groupIds: toDeleteGroupIds,
        tcId: transCenter?.id,
        ownerProject: projectData.createUserId
      }
      deleteGroup(param, `delete-tcgroup`)    
    }
    setIsOpenMenu(false);
  };
  
  const doSetValuesTransCenter = (diagramData: any) => {
    // TransCenter
    // let mainDiagramData = diagramDataList.find((e: any) => e.tabId === 1);
    // let transCenter = mainDiagramData?.shape.find(
    //   (e: any) => e.properties?.tabId === currentTabId
    // );

    if (transCenter) {
      let tmpCalc = doGetValuesTransCenter(transCenter.properties, diagramData);
      transCenter.properties = tmpCalc.newProps;

      updateTransCenterProps(transCenter.id, transCenter.properties);
    }
  };

  const openAddGroupListDialog = () => {
    setOpenGroupListDialog(true)
  }

  const addGroupListOK = (data: any) => {
    const groupId = groupsData.byEleId[controlId as any]?.at(-1) ?? -1

    const selected = shapes.filter((shape: any) => shape.isSelected === true);
    let diff_x = selected[0].x
    let diff_y = selected[0].y
    for (let i = 1; i < selected.length; i++){
      if (selected[i].x < diff_x) diff_x = selected[i].x
      if (selected[i].y < diff_y) diff_y = selected[i].y
    }

    const params = {
      userId: userId,
      projectId: projectData.projectId,
      groupId: groupId,
      groupName: data.groupName,
      explan: data.explan,
      diff_x: diff_x,
      diff_y: diff_y,
      ownerProject: projectData.createUserId,
    }
    createGroupList(params)
    
    setOpenGroupListDialog(false)
    setIsOpenMenu(false)
  }

  const addGroupListCancel = () => {
    setOpenGroupListDialog(false)
    setIsOpenMenu(false)
  }

  // MODE CHECKING || MODE PERCENTZ
  const handleChangeFault = (newData: boolean) => {
    setIsOpenMenu(false)
    onUpdateQuickProperties("fault",newData)
  }

  const handleChangeMotorANDRegular = (newData: boolean) => {
    setIsOpenMenu(false)
    onUpdateQuickProperties("drop",newData)
  }

  const handleChangeCalcImprovePF = (newData:boolean) => {
    setIsOpenMenu(false)
    onUpdateQuickProperties("improve",newData)
  }

  const handleChangeViewFlowResult = (newData:boolean) => {
    setIsOpenMenu(false)
    onUpdateQuickProperties("view_flow_result",newData)
  }

  const handleChangeEditSwitch = (newData:boolean) => {
    setIsOpenMenu(false)
    onUpdateQuickProperties("switch",newData)
  }
  const handleChangeDropMode = (newData: boolean) => {
    setIsOpenMenu(false)
    onUpdateQuickProperties("drop_mode",newData)
  }
  const handleChangeFlowViewMethod = (newData: boolean) => {
    setIsOpenMenu(false)
    onUpdateQuickProperties("flow_view_method",newData)
  }

  // MODE VOLT_DROP
  const handleCalcVoltDrop = () => {
    if(!diagramDataList[0].shape.filter((item) => item.isSelected == true)[0].properties.calcDrop){
      const params = {
        userId: userId,
        projectId: projectData.projectId,
        element_id: diagramDataList[0].shape.filter((item) => item.isSelected == true)[0].id,
        ownerProject: projectData.createUserId
      }
      onCalcVoltDrop(params);
      dispatch({type:"APP_LOADING"} as AppLoadingAction)
    }
  }
  //Maintenace
  const handleShowMaintenanceSreen =()=>{
    isShowMaintenanceScreen(true,controlId)
  }
  return (
    <>
      <Html>
        {openDeleteDialog ? (
          <AlertDialog
            title={"MSSV3"}
            message={"選択されている機器を削除しますか?"}
            isOpen={true}
            onOK={handleDeleteOK}
            onCancel={handleDeleteCancel}
          />
        ) : (
          <div />
        )}
        {isOpenMenu && (
          <div
            style={{
              position: "absolute",
              top: y,
              left: x,
              backgroundColor: "white",
              zIndex: 9999,
              width: "230px",
              boxShadow: "5px 5px 10px grey",
              border: "0.7px solid grey",
              textAlign: "left",
            }}
          >
          { 
            !(m_bModePM||modeViewOnly) && processMode === ProcessMode.DRAWING &&
            <>
            <Button
              fullWidth
              onClick={handleCut}
              disabled={isDisabledCut ?? true}
              color="inherit"
              sx={{
                justifyContent: "flex-start",
                padding: "4px 5px",
                "&.Mui-disabled": { color: "#a6a6a6" },
                "&:hover": { backgroundColor: "#e6f7ff" },
              }}
            >
              切り取り
            </Button>
            <Button
              fullWidth
              onClick={handleCopy}
              disabled={isDisabledCopy ?? true}
              color="inherit"
              sx={{
                justifyContent: "flex-start",
                padding: "4px 5px",
                "&.Mui-disabled": { color: "#a6a6a6" },
                "&:hover": { backgroundColor: "#e6f7ff" },
              }}
            >
              コピー
            </Button>
            <Button
              fullWidth
              onClick={handlePaste}
              disabled={isDisabledPaste ?? true}
              color="inherit"
              sx={{
                justifyContent: "flex-start",
                padding: "4px 5px",
                "&.Mui-disabled": { color: "#a6a6a6" },
                "&:hover": { backgroundColor: "#e6f7ff" },
              }}
            >
              貼り付け
            </Button>
            <hr className="menu-option-hr-style" />
            <Button
              onClick={handleRemove}
              fullWidth
              disabled={isDisabledDelete ?? true}
              color="inherit"
              sx={{
                justifyContent: "flex-start",
                padding: "4px 5px",
                "&.Mui-disabled": { color: "#a6a6a6" },
                "&:hover": { backgroundColor: "#e6f7ff" },
              }}
            >
             削除
            </Button>
            <hr className="menu-option-hr-style" />
            <Button
              fullWidth
              onClick={handleRotation}
              disabled={(isDisabledRotate ?? true)||isDisabledRotate1}
              color="inherit"
              sx={{
                justifyContent: "flex-start",
                padding: "4px 5px",
                "&.Mui-disabled": { color: "#a6a6a6" },
                "&:hover": { backgroundColor: "#e6f7ff" },
              }}
            >
              回転
            </Button>
            <hr className="menu-option-hr-style" />
            <Button
              onClick={handleGroup}
              fullWidth
              disabled={!isAbleToGroup()}
              color="inherit"
              sx={{
                justifyContent: "flex-start",
                padding: "4px 5px",
                "&.Mui-disabled": { color: "#a6a6a6" },
                "&:hover": { backgroundColor: "#e6f7ff" },
              }}
            >
              グループ化
            </Button>
            <Button
              onClick={handleUnGroup}
              fullWidth
              disabled={!isAbleToUnGroup()}
              color="inherit"
              sx={{
                justifyContent: "flex-start",
                padding: "4px 5px",
                "&.Mui-disabled": { color: "#a6a6a6" },
                "&:hover": { backgroundColor: "#e6f7ff" },
              }}
            >
              グループ解除
            </Button>
            {currentTabId === 1 &&
              <Button
                onClick={openAddGroupListDialog}
                fullWidth
                disabled={!isAbleToUnGroup()}
                color="inherit"
                sx={{
                  justifyContent: "flex-start",
                  padding: "4px 5px",
                  "&.Mui-disabled": { color: "#a6a6a6" },
                  "&:hover": { backgroundColor: "#e6f7ff" },
                }}
              >
                グループリスト登録
              </Button>
            }
            <hr className="menu-option-hr-style" />
            </>
          }
          {
            ((m_bModePM||modeViewOnly) && processMode === ProcessMode.DRAWING) &&
            <>
              <Button
                fullWidth
                color="inherit"
                sx={optionStyle}
                onClick={handleShowMaintenanceSreen}
                disabled={disableMaintain}
              > 
                メンテナンスデータを開く
              </Button>
              <hr className="menu-option-hr-style" />
            </>
           }
          {
            (processMode === ProcessMode.CHECKING) &&
            <>
                <Button
                  fullWidth
                  disabled={!enableEditSwitchOnFlag}
                  onClick={() => handleChangeEditSwitch(true)}
                  color='inherit'
                  sx={optionStyle}
                >
                  主回路を投入
                </Button>
                <Button
                  fullWidth
                  disabled={!enableEditSwitchOffFlag}
                  onClick={() => handleChangeEditSwitch(false)}
                  color='inherit'
                  sx={optionStyle}
                >
                  主回路を開放
                </Button>

                <hr className='menu-option-hr-style' />
                <Button
                  fullWidth
                  disabled={!editFaultOnFlag}
                  onClick={() => handleChangeFault(true)}
                  color='inherit'
                  sx={optionStyle}
                >
                  短絡電流計算点に設定
                </Button>
                <Button
                  fullWidth
                  disabled={
                    !editFaultOffFlag
                  }
                  onClick={() => handleChangeFault(false)}
                  color='inherit'
                  sx={optionStyle}
                >
                  短絡電流計算点を解除
                </Button>

                <hr className='menu-option-hr-style' />
                <Button
                  fullWidth
                  disabled={
                    !editDropOnFlag
                  }
                  onClick={() => handleChangeMotorANDRegular(true)}
                  color='inherit'
                  sx={optionStyle}
                >
                  電圧降下計算点に設定
                </Button>
                <Button
                  fullWidth
                  disabled={
                    !editDropOffFlag
                  }
                  onClick={() => handleChangeMotorANDRegular(false)}
                  color='inherit'
                  sx={optionStyle}
                >
                  電圧降下計算点を解除
                </Button>

                <hr className='menu-option-hr-style' />
                <Button
                  fullWidth
                  disabled={!editImproveOnFlag}
                  onClick={() => handleChangeCalcImprovePF(true)}
                  color='inherit'
                  sx={optionStyle}
                >
                  力率改善計算点に設定
                </Button>
                <Button
                  fullWidth
                  disabled={!editImproveOffFlag}
                  onClick={() => handleChangeCalcImprovePF(false)}
                  color='inherit'
                  sx={optionStyle}
                >
                  力率改善計算点を解除
                </Button>
                <hr className="menu-option-hr-style" />
            </>
          }
          {
            processMode === ProcessMode.PERCENT_Z &&
            <>
                <Button
                  fullWidth
                  disabled={!enableEditSwitchOnFlag}
                  onClick={() => handleChangeEditSwitch(true)}
                  color='inherit'
                  sx={optionStyle}
                >
                  主回路を投入
                </Button>
                <Button
                  fullWidth
                  disabled={!enableEditSwitchOffFlag}
                  onClick={() => handleChangeEditSwitch(false)}
                  color='inherit'
                  sx={optionStyle}
                >
                  主回路を開放
                </Button>

                <hr className='menu-option-hr-style' />
                <Button
                  fullWidth
                  disabled={!editFaultOnFlag}
                  onClick={() => handleChangeFault(true)}
                  color='inherit'
                  sx={optionStyle}
                >
                  短絡電流計算点に設定
                </Button>
                <Button
                  fullWidth
                  disabled={
                    !editFaultOffFlag
                  }
                  onClick={() => handleChangeFault(false)}
                  color='inherit'
                  sx={optionStyle}
                >
                  短絡電流計算点を解除
                </Button>

                <hr className='menu-option-hr-style' />
                <Button
                  fullWidth
                  disabled={
                    !editDropOnFlag
                  }
                  onClick={() => handleChangeMotorANDRegular(true)}
                  color='inherit'
                  sx={optionStyle}
                >
                  電圧降下計算点に設定
                </Button>
                <Button
                  fullWidth
                  disabled={
                    !editDropOffFlag
                  }
                  onClick={() => handleChangeMotorANDRegular(false)}
                  color='inherit'
                  sx={optionStyle}
                >
                  電圧降下計算点を解除
                </Button>
                <hr className='menu-option-hr-style' />
                <Button
                  fullWidth
                  disabled={!editImproveOnFlag}
                  onClick={() => handleChangeCalcImprovePF(true)}
                  color='inherit'
                  sx={optionStyle}
                >
                  力率改善計算点に設定
                </Button>
                <Button
                  fullWidth
                  disabled={!editImproveOffFlag}
                  onClick={() => handleChangeCalcImprovePF(false)}
                  color='inherit'
                  sx={optionStyle}
                >
                  力率改善計算点を解除
                </Button>
                <hr className="menu-option-hr-style" />
            </>
          }

          {
            !(m_bModePM||modeViewOnly) && (processMode == ProcessMode.CHECKING || processMode == ProcessMode.PERCENT_Z) &&
            <>
                <Button
                  fullWidth
                  disabled={!editFlowResutOnFlag}
                  onClick={() => handleChangeViewFlowResult(true)}
                  color='inherit'
                  sx={optionStyle}
                >
                  潮流解析結果を表示
                </Button>
                <Button
                  fullWidth
                  disabled={!editFlowResutOffFlag}
                  onClick={() => handleChangeViewFlowResult(false)}
                  color='inherit'
                  sx={optionStyle}
                >
                  潮流解析結果を非表示
                </Button>
              <hr className="menu-option-hr-style" />
            </>
          }
          {
            (processMode == ProcessMode.CALCULATION) &&
            <>
                <Button
                  fullWidth
                  disabled={!editFaultOnFlag}
                  onClick={() => handleChangeFault(true)}
                  color='inherit'
                  sx={optionStyle}
                >
                  短絡電流計算点に設定
                </Button>
                <Button
                  fullWidth
                  disabled={
                    !editFaultOffFlag
                  }
                  onClick={() => handleChangeFault(false)}
                  color='inherit'
                  sx={optionStyle}
                >
                  短絡電流計算点を解除
                </Button>
            <hr className='menu-option-hr-style' />
                <Button
                  fullWidth
                  disabled={!enableEditSwitchOnFlag}
                  onClick={() => handleChangeEditSwitch(true)}
                  color='inherit'
                  sx={optionStyle}
                >
                  主回路を投入
                </Button>
                <Button
                  fullWidth
                  disabled={!enableEditSwitchOffFlag}
                  onClick={() => handleChangeEditSwitch(false)}
                  color='inherit'
                  sx={optionStyle}
                >
                  主回路を開放
                </Button>
              <hr className="menu-option-hr-style" />
            </>
          }
          {
            (processMode == ProcessMode.VOLTDROP) &&
            <>            
                <Button
                  fullWidth
                  disabled={diagramDataList[0].shape.filter((item) => item.isSelected == true).length != 1 || diagramDataList[0].shape.filter((item) => item.isSelected == true)[0].type != EKind.MS_MOTOR}
                  onClick={() => handleCalcVoltDrop()}
                  color='inherit'
                  sx={{
                    justifyContent: "flex-start",
                    padding: "4px 5px",
                    "&.Mui-disabled": { color: "#a6a6a6" },
                    "&:hover": { backgroundColor: "#e6f7ff" },
                    whiteSpace: 'nowrap',
                    backgroundColor: diagramDataList[0].shape.filter((item) => item.isSelected == true).length > 0 &&  diagramDataList[0].shape.filter((item) => item.isSelected == true)[0]?.properties.calcDrop == true ? "#e6f7ff" :"white"
                    }}
                >
                  電動機始動時電圧降下計算
                </Button>
                <hr className='menu-option-hr-style' />
                <Button
                  fullWidth
                  disabled={
                    !editDropOnFlag
                  }
                  onClick={() => handleChangeMotorANDRegular(true)}
                  color='inherit'
                  sx={optionStyle}
                >
                  電圧降下計算点に設定
                </Button>
                <Button
                  fullWidth
                  disabled={
                    !editDropOffFlag
                  }
                  onClick={() => handleChangeMotorANDRegular(false)}
                  color='inherit'
                  sx={optionStyle}
                >
                  電圧降下計算点を解除
                </Button>
                <hr className='menu-option-hr-style' />
                <Button
                  fullWidth
                  disabled={!enableEditSwitchOnFlag}
                  onClick={() => handleChangeEditSwitch(true)}
                  color='inherit'
                  sx={optionStyle}
                >
                  主回路を投入
                </Button>
                <Button
                  fullWidth
                  disabled={!enableEditSwitchOffFlag}
                  onClick={() => handleChangeEditSwitch(false)}
                  color='inherit'
                  sx={optionStyle}
                >
                  主回路を開放
                </Button>
                <hr className='menu-option-hr-style' />
                <Button
                  fullWidth
                  color='inherit'
                  sx={{
                    justifyContent: "flex-start",
                    padding: "4px 5px",
                    "&.Mui-disabled": { color: "#a6a6a6" },
                    "&:hover": { backgroundColor: "#e6f7ff" },
                    whiteSpace: 'nowrap',
                    backgroundColor:projectData.dropMode == 0?"#e6f7ff" :undefined
                    }}
                  
                  onClick={() => handleChangeDropMode(false)}
                >
                電動機始動時電圧降下表示モード
                </Button>
                <Button
                  fullWidth
                  color='inherit'
                  sx={{
                    justifyContent: "flex-start",
                    padding: "4px 5px",
                    "&.Mui-disabled": { color: "#a6a6a6" },
                    "&:hover": { backgroundColor: "#e6f7ff" },
                      backgroundColor:projectData.dropMode == 1?"#e6f7ff" :undefined
                  }}
                  onClick={() => handleChangeDropMode(true)}
                  
                >
                  定常時電圧降下表示モード
                </Button>
                <hr className="menu-option-hr-style" />
            </>
          }
          {
            processMode === ProcessMode.IMPROVE_PF &&
            <>
                <Button
                  fullWidth
                  disabled={!editImproveOnFlag}
                  onClick={() => handleChangeCalcImprovePF(true)}
                  color='inherit'
                  sx={optionStyle}
                >
                  力率改善計算点に設定
                </Button>
                <Button
                  fullWidth
                  disabled={!editImproveOffFlag}
                  onClick={() => handleChangeCalcImprovePF(false)}
                  color='inherit'
                  sx={optionStyle}
                >
                  力率改善計算点を解除
                </Button>
                <hr className='menu-option-hr-style' />
                <Button
                  fullWidth
                  disabled={!enableEditSwitchOnFlag}
                  onClick={() => handleChangeEditSwitch(true)}
                  color='inherit'
                  sx={optionStyle}
                >
                  主回路を投入
                </Button>
                
                <Button
                  fullWidth
                  disabled={!enableEditSwitchOffFlag}
                  onClick={() => handleChangeEditSwitch(false)}
                  color='inherit'
                  sx={optionStyle}
                >
                  主回路を開放
                </Button>
              <hr className="menu-option-hr-style" />
            </>
          }
          {
           (!(m_bModePM||modeViewOnly) && processMode === ProcessMode.HARMONIC) &&
            <>
                <Button
                  fullWidth
                  disabled={!enableEditSwitchOnFlag}
                  onClick={() => handleChangeEditSwitch(true)}
                  color='inherit'
                  sx={optionStyle}
                >
                  主回路を投入
                </Button>
                
                <Button
                  fullWidth
                  disabled={!enableEditSwitchOffFlag}
                  onClick={() => handleChangeEditSwitch(false)}
                  color='inherit'
                  sx={optionStyle}
                >
                  主回路を開放
                </Button>
              <hr className="menu-option-hr-style" />
            </>
          }
           {
            (!(m_bModePM||modeViewOnly) &&processMode == ProcessMode.POWER_FLOW) &&
            <>
                <Button
                  fullWidth
                  disabled={!editFlowResutOnFlag}
                  onClick={() => handleChangeViewFlowResult(true)}
                  color='inherit'
                  sx={optionStyle}
                >
                  潮流解析結果を表示
                </Button>
                <Button
                  fullWidth
                  disabled={!editFlowResutOffFlag}
                  onClick={() => handleChangeViewFlowResult(false)}
                  color='inherit'
                  sx={optionStyle}
                >
                  潮流解析結果を非表示
                </Button>
                <hr className='menu-option-hr-style' />
                <Button
                  fullWidth
                  disabled={!enableEditSwitchOnFlag}
                  onClick={() => handleChangeEditSwitch(true)}
                  color='inherit'
                  sx={optionStyle}
                >
                  主回路を投入
                </Button>
                <Button
                  fullWidth
                  disabled={!enableEditSwitchOffFlag}
                  onClick={() => handleChangeEditSwitch(false)}
                  color='inherit'
                  sx={optionStyle}
                >
                  主回路を開放
                </Button>
                <hr className='menu-option-hr-style' />
                <Button
                  fullWidth
                  color='inherit'
                  sx={{
                    justifyContent: "flex-start",
                    padding: "4px 5px",
                    "&.Mui-disabled": { color: "#a6a6a6" },
                    "&:hover": { backgroundColor: "#e6f7ff" },
                    backgroundColor:projectData.flowViewMethod == 1?"#e6f7ff" :undefined}}
                  onClick={() => handleChangeFlowViewMethod(false)}

                >
                  機器ごとに表示
                </Button>
                
                <Button
                  fullWidth
                  color='inherit'
                  sx={{
                    justifyContent: "flex-start",
                    padding: "4px 5px",
                    "&.Mui-disabled": { color: "#a6a6a6" },
                    "&:hover": { backgroundColor: "#e6f7ff" },
                    backgroundColor:projectData.flowViewMethod == 2?"#e6f7ff" :undefined}}
                  onClick={() => handleChangeFlowViewMethod(true)}
                >
                母線ごとに表示
                </Button>
              <hr className="menu-option-hr-style" />
            </>
          }
          {
            !(m_bModePM||modeViewOnly) &&
            <>
            <Button
              fullWidth
              onClick={handleOpenChart}
              disabled={isDisabledOpenGraph ?? true}
              color="inherit"
              sx={{
                justifyContent: "flex-start",
                padding: "4px 5px",
                "&.Mui-disabled": { color: "#a6a6a6" },
                "&:hover": { backgroundColor: "#e6f7ff" },
              }}
            > 
              保護協調図を新規作成
            </Button>
            <Button
              fullWidth
              disabled={checkAbleToShowLineToChart}
              color="inherit"
              onClick={handleAddToChart}
              sx={{
                justifyContent: "flex-start",
                padding: "4px 5px",
                "&.Mui-disabled": { color: "#a6a6a6" },
                "&:hover": { backgroundColor: "#e6f7ff" },
              }}
            >
              特性曲線を追加
            </Button>
            <Button
              fullWidth
              disabled={checkAbleToShowCalcPointToChart}
              color="inherit"
              onClick={handleAddCalcPointToChart}
              sx={{
                justifyContent: "flex-start",
                padding: "4px 5px",
                "&.Mui-disabled": { color: "#a6a6a6" },
                "&:hover": { backgroundColor: "#e6f7ff" },
              }}
            >
              短絡電流を追加
            </Button>
            <Button
              fullWidth
              disabled={isDisabledUserCurve ?? !isAbleToDrawUserCurve()}
              color="inherit"
              onClick={handleUserCurves}
              sx={{
                justifyContent: "flex-start",
                padding: "4px 5px",
                "&.Mui-disabled": { color: "#a6a6a6" },
                "&:hover": { backgroundColor: "#e6f7ff" },
              }}
            >
              ユーザー曲線
            </Button>
          <hr className="menu-option-hr-style" />
            </>
          }
         
          
          {/* Properties プロパティ */}
          <Button
              fullWidth
              onClick={handleProperties}
              disabled={isDisabledProps ?? true}
              color="inherit"
              sx={{
                justifyContent: "flex-start",
                padding: "4px 5px",
                "&.Mui-disabled": { color: "#a6a6a6" },
                "&:hover": { backgroundColor: "#e6f7ff" },
              }}
            >
              プロパティ
          </Button>
            
            
          </div>
        )}
        {openGroupListDialog && 
          <GroupListDialog
            onOK={addGroupListOK}
            onCancel={addGroupListCancel}
          />
        }
      </Html>
    </>
  );
};
const mapStateToProps = (state: ApplicationState) => {
  return {
    userId: state.app.user?.userId,
    currUser: state.app.user,

    projectData: state.app.projectData,
    elementGroups: state.app.diagram.elementGroups,
    tcGroups: state.app.diagram.tcGroups,
    currentTabId: state.app.diagram.currentIDDiagramTab,
    currentIDChartTab: state.app.diagram.currentIDChartTab,
    transformData: state.app.diagram.transformData,
    shapes: state.app.diagram.diagramData.filter((r: any) => r.tabId === state.app.diagram.currentIDDiagramTab)[0].shape,
    chartDataList: state.app.diagram.chartData,
    diagramDataList: state.app.diagram.diagramData,
    isOpenChart: state.app.diagram.isOpenChart,
    processMode: state.app.diagram.processMode,
    
    pointerPosition: state.app.diagram.pointerPosition,
    copyShapes: state.app.diagram.copyShapes,
    copyEleGroups: state.app.diagram.copyEleGroups,
    copyTCEleGroups: state.app.diagram.copyTCEleGroups,
    copyDiagramData: state.app.diagram.copyDiagramData,
    m_bModePM: state.app.diagram.m_bModePM,
    modeViewOnly: state.app.diagram.modeViewOnly,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    createGroup: (params : any, actionUrl : string) => dispatch(actionCreators.fetch("CREATE_GROUP", `/diagram/${actionUrl}`, "POST", params, false, true)),
    deleteGroup: (params : any, actionUrl : string) => dispatch(actionCreators.fetch("DELETE_GROUP", `/diagram/${actionUrl}`, "POST", params, false, true)),
    createGroupList: (params: any) =>
      dispatch(
        actionCreators.fetch(
          "CREATE_GROUP_LIST",
          "/grouplist/add",
          "POST",
          params,
          false,
          true
        )
      ),
    updateRotate: (tabId: number) => dispatch(rotate(tabId)),
    setOpenChart: (isOpenChart: boolean) => dispatch(openChart(isOpenChart)),
    setLineToChart: (isAddToChart: boolean, controlId: string) => dispatch(addLineToChart(isAddToChart, controlId)),
    setCalcPointToChart: (isAddCalcPointToChart: boolean, controlId: string) => dispatch(addCalcPointToChart(isAddCalcPointToChart, controlId)),
    copyAction: (tabId: number) => dispatch(copyAction(tabId)),
    cutAction: (tabId: number) => dispatch(cutAction(tabId)),
    pasteElementAndGroup: (params: any) => dispatch(actionCreators.fetch("PASTE_ELES_AND_GROUPS", "/diagram/paste-elements", "POST", params, false, true)),
    removeAction: (tabId: number) => dispatch(removeAction(tabId)),
    deleteDiagramByTabId: (tabId: number) => dispatch(deleteDiagramByTabId(tabId)),
    updateTransCenterProps: (id: string, data: object) => dispatch(updatePropertiesOfTransCenter(id, data)),
    onUpdateQuickProperties: (field:string,data:boolean) => dispatch(updateQuickProperties(field,data)),
    onCalcVoltDrop: (params:any) => dispatch(actionCreators.fetch("CALC_VOLT_DROP","/check/calc-volt-drop","POST",params,false,true)),
    isShowMaintenanceScreen:(show:boolean,controlId?:string) => dispatch(isShowMaintenanceScreen(show,controlId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OptionMenu);
