import { store } from "..";
import { ControlModel, MsTransScottModel, MsTransScottPartModel, PercentZ, ProcessMode } from "../models/Index";
import { BeamsNumber } from "./FormatNumber";
import { doGetValuePerZk3, doGetValuePerZk2SeatM, doGetValuePerZk2SeatT } from "./PercentZ";
import { MS_OUTSIDE_NEUTRAL, MS_OUTSIDE_OUTSIDE, MS_SEAT_MAIN, MS_SEAT_T, MS_3PHASES, MS_VOLTDROP_REGULAR, DIRECT_NONE, TOP_CENTER, LEFT_CENTER} from "../models/Constants"
import { doGetValueDropVoltRegular as doGetValueDropVoltRegularCalcPoint,
		 doGetPerZSourceDirectMG,
		 doGetIkpeakDirectMG,
		 doGetIkasymDirectMG,
		 doGetIksymDirectMG,
		 doGetTextDropVoltRegular as doGetTextDropVoltRegularCalcPoint,
		 doGetTextDropPerRegular as doGetTextDropPerRegularCalcPoint,
		 doGetTextDropPerRegular2 as doGetTextDropPerRegular2CalcPoint
} from "./calcPointFunction";
import { MS_MODE_CALCULATION, MS_MODE_CHECKING, MS_MODE_DRAWING, MS_MODE_HARMONIC, MS_MODE_PERCENT_Z, MS_MODE_POWER_FLOW, MS_MODE_VOLTDROP } from "../statics";

//CEleTransScott::DoGetPerZrTransScott
export const doGetPerZrTransScott = (props:MsTransScottModel, dStdCapacity:number) =>
{
	let percentZ = {
        perRk3 : props.partSeatT.percentR,
	    perXk3 : props.partSeatT.percentX,
	    perRk3Difference : props.partSeatM.percentR,
	    perXk3Difference : props.partSeatM.percentX,
        //CHECK ****
        perRk3Source : 0,
        perXk3Source : 0 
    };

	if (props.capacity == 0) {
		percentZ.perRk3 = 0;
		percentZ.perXk3 = 0;
	}
	else {
	    percentZ.perRk3 *= dStdCapacity / (props.capacity / 2);
	    percentZ.perXk3 *= dStdCapacity / (props.capacity / 2);
		percentZ.perRk3Difference *= dStdCapacity / (props.capacity / 2);
		percentZ.perXk3Difference *= dStdCapacity / (props.capacity / 2);
							
		percentZ.perRk3Difference = percentZ.perRk3 - percentZ.perRk3Difference;
		percentZ.perXk3Difference = percentZ.perXk3 - percentZ.perXk3Difference;
							
        percentZ.perRk3Source = doGetPerZSourceDirectMG(props.partSeatT.calcPoint).perRk3Source;
        percentZ.perXk3Source = doGetPerZSourceDirectMG(props.partSeatT.calcPoint).perXk3Source;
	}

	return percentZ;
}

//CEleTransScott::DoGetValueDropVoltRegular
export const doGetValueDropVoltRegular = (props:MsTransScottModel, dStdCapacity:number, nPartKind:number) =>
{
	let percentZ = doGetPerZrTransScott(props, dStdCapacity);
	percentZ.perRk3Source = 0;
	percentZ.perXk3Source = 0;
	let dVolt = 0;
    let resultData = {
        dPercentZ: 0,
        pdPerRk3 : 0,
        pdPerXk3 : 0
    };
	switch (nPartKind) {
	case MS_SEAT_MAIN:
        resultData = doGetValuePerZk2SeatM(percentZ);
		dVolt = doGetValueDropVoltRegularCalcPoint(props.voltageSecondary, resultData.pdPerRk3, resultData.pdPerXk3, props.partSeatM.calcPoint);
		break;
	case MS_SEAT_T:	
        resultData = doGetValuePerZk2SeatT(percentZ);
		dVolt = doGetValueDropVoltRegularCalcPoint(props.voltageSecondary, resultData.pdPerRk3, resultData.pdPerXk3, props.partSeatT.calcPoint);
		break;
	}

	return dVolt;
}

//CEleTransScottPart::DoGetTextPointState
export const doGetTextPointState = (props:MsTransScottPartModel) =>
{
	let sText = ''; 

	if (props.fault) {
		sText = 'Yes';
	}
	else {
		sText = 'No';
	}

	return sText;
}

//CEleTransScott::IsPointFaultANDTransScott
export const isPointFaultANDTransScott = (props:MsTransScottModel) =>
{
	if (props.partSeatM.fault && props.partSeatT.fault) {
		return true;
	}
	return false;
}

//CEleTransScott::DoGetTextVoltPrimary
export const doGetTextVoltPrimary = (props:MsTransScottModel, bUnit:boolean = true) =>
{
	let sText = props.voltagePrimary.toString();
	if (bUnit) {
		sText += 'V';
	}
	return sText;
}

//CEleTransScott::DoGetTextVoltSecondary
export const doGetTextVoltSecondary = (props:MsTransScottModel, bUnit:boolean = true) =>
{
	let sText = props.voltageSecondary.toString();
	if (bUnit) {
		sText += 'V';
	}
	return sText;
}

//CEleTransScott::DoGetTextCapacity
export const doGetTextCapacity = (props:MsTransScottModel, bUnit:boolean = true) =>
{
	let sText = BeamsNumber(props.capacity, 6).toString();
	if (bUnit) {
		sText += 'kVA';
	}
	return sText;
}

//CEleTransScott::DoGetValueRatedCurrent
export const doGetValueRatedCurrent = (dVoltSecondary:number, dCapacity:number) =>
{
	let dRatedCurrent = 0;

	if (dVoltSecondary <= 0) {
		return 0;
	}
	else {	
		dRatedCurrent = (dCapacity / 2) * 1000 / dVoltSecondary;
	}
	return dRatedCurrent;
}

//CEleTransScott::DoGetValueParmaryRatedCurrent
export const doGetValueParmaryRatedCurrent = (props:MsTransScottModel) =>
{
	let dRatedCurrent = props.voltagePrimary;
	
	dRatedCurrent = props.capacity * 1000 / dRatedCurrent / Math.sqrt(3.0);

	return dRatedCurrent;
}

//CEleTransScott::DoGetTextRatedCurrent
export const doGetTextRatedCurrent = (props:MsTransScottModel, nPartKind:number, bUnit:boolean = true) =>
{
	let dRatedCurrent = 0;
	switch (nPartKind) {
	case MS_SEAT_MAIN:		
		dRatedCurrent = doGetValueRatedCurrent(props.voltageSecondary, props.capacity);
		break;
	case MS_SEAT_T:			
		dRatedCurrent = doGetValueRatedCurrent(props.voltageSecondary, props.capacity);
		break;
	case MS_3PHASES:		
		dRatedCurrent = doGetValueParmaryRatedCurrent(props);
		break;
	}
	let sText = BeamsNumber(dRatedCurrent, 4).toString();
	if (bUnit) {
		sText += 'A';
	}
	return sText;
}

//CEleTransScott::DoGetTextPerZe
export const doGetTextPerZe = (props:MsTransScottModel, nPartKind:number, bUnit:boolean = true) =>
{
	let sText = '';
	switch (nPartKind) {
	case MS_SEAT_MAIN:		
		sText = BeamsNumber(props.partSeatM.percentZ, 5).toString(); //perZk3 ***
		break;
	case MS_SEAT_T:		
		sText = BeamsNumber(props.partSeatT.percentZ, 5).toString();; //perZk3 ***
		break;
	}
	if (bUnit) {
		sText += '%e';
	}
	return sText;
}

//CEleTransScott::DoGetTextXR
export const doGetTextXR = (props:MsTransScottModel, nPartKind:number) =>
{
	let sText = '';
	switch (nPartKind) {
	case MS_SEAT_MAIN:		
		sText = BeamsNumber(props.partSeatM.xr, 5).toString();
		break;
	case MS_SEAT_T:		
		sText = BeamsNumber(props.partSeatT.xr, 5).toString();
		break;
	}
	return sText;
}

//CEleTransScott::DoGetPercentZr
export const doGetPercentZr = (props:MsTransScottModel, nPartKind:number, dStdCapacity:number) =>
{
	let percentZ = {} as PercentZ;

	switch (nPartKind) {
	case MS_SEAT_MAIN:		
		percentZ.perRk3 = props.partSeatM.percentR; //PerRk3
		percentZ.perXk3 = props.partSeatM.percentX; //PerXk3
		break;
	case MS_SEAT_T:			
		percentZ.perRk3 = props.partSeatT.percentR; //PerRk3
		percentZ.perXk3 = props.partSeatT.percentX; //PerXk3 
		break;
	}

	if (props.capacity == 0) {
		percentZ.perRk3 = 0;
		percentZ.perXk3 = 0;
	}
	else {
		percentZ.perRk3 *= dStdCapacity / (props.capacity / 2);
		percentZ.perXk3 *= dStdCapacity / (props.capacity / 2);
	}

	return percentZ;
}

//CEleTransScott::DoGetTextPerZr
export const doGetTextPerZr = (props:MsTransScottModel, nPartKind:number, dStdCapacity:number, bUnit:boolean = true) =>
{
	let percentZ = doGetPercentZr(props, nPartKind, dStdCapacity);
	let dPercentZ = doGetValuePerZk3(percentZ).dPercentZ;
	let sText = BeamsNumber(dPercentZ, 4).toString();
	if (bUnit) {
		sText += '%r';
	}
	return sText;
}

//CEleTransScott::DoGetTextPerRr
export const doGetTextPerRr = (props:MsTransScottModel, nPartKind:number, dStdCapacity:number, bUnit:boolean = true) =>
{
	let percentZ = doGetPercentZr(props, nPartKind, dStdCapacity);
	let sText = BeamsNumber(percentZ.perRk3, 4).toString();
	if (bUnit) {
		sText += '%r';
	}
	return sText;
}

//CEleTransScott::DoGetTextPerXr
export const doGetTextPerXr = (props:MsTransScottModel, nPartKind:number, dStdCapacity:number, bUnit:boolean = true) =>
{
	let percentZ = doGetPercentZr(props, nPartKind, dStdCapacity);
	let sText = BeamsNumber(percentZ.perXk3, 4).toString();
	if (bUnit ) {
		sText += '%r';
	}
	return sText;
}

//CEleTransScott::IsPointFaultORTransScott
export const isPointFaultORTransScott = (props:MsTransScottModel) =>
{
	if (props.partSeatM.fault || props.partSeatT.fault) {
		return true;
	}
	return false;
}

//CEleTransScott::DoGetTextDirectIkSpeak
export const doGetTextDirectIkSpeak = (props:MsTransScottModel, nPartKind:number, bUnit:boolean = true) =>
{
	let dIpeakDirect = 0;
	switch (nPartKind) {
	case MS_SEAT_MAIN:		
		dIpeakDirect = doGetIkpeakDirectMG(props.partSeatM.calcPoint);
		break;
	case MS_SEAT_T:			
		dIpeakDirect = doGetIkpeakDirectMG(props.partSeatT.calcPoint);
		break;
	}
	let sText;
	if (dIpeakDirect >= 0) {
		sText = BeamsNumber(dIpeakDirect / 1000, 4).toString();
		if (bUnit) {
			sText += 'kApeak';
		}
	}
	else {							
		sText = '????';
	}
	return sText;
}

export const doGetTextDirectIkSasym = (props:MsTransScottModel, nPartKind:number, bUnit:boolean = true) =>
{
	let dIasymDirect = 0;
	switch (nPartKind) {
	case MS_SEAT_MAIN:		
		dIasymDirect = doGetIkasymDirectMG(props.partSeatM.calcPoint);
		break;
	case MS_SEAT_T:			
		dIasymDirect = doGetIkasymDirectMG(props.partSeatT.calcPoint);
		break;
	}

	let sText;
	if (dIasymDirect >= 0) {
		sText = BeamsNumber(dIasymDirect / 1000, 4).toString();
		if (bUnit) {
			sText += 'kAasym';
		}
	}
	else {							
		sText = '????';
	}
	return sText;
}

//CEleTransScott::DoGetTextDirectIkSsym
export const doGetTextDirectIkSsym = (props:MsTransScottModel, nPartKind:number, bUnit:boolean = true) =>
{
	let dIsymDirect = 0;
	switch (nPartKind) {
	case MS_SEAT_MAIN:		
		dIsymDirect = doGetIksymDirectMG(props.partSeatM.calcPoint);
		break;
	case MS_SEAT_T:			
		dIsymDirect = doGetIksymDirectMG(props.partSeatT.calcPoint);
		break;
	}
	let sText;
	if (dIsymDirect >= 0) {
		sText = BeamsNumber(dIsymDirect / 1000, 4).toString();
		if (bUnit) {
			sText += 'kAsym';
		}
	}
	else {							
		sText = '????';
	}
	return sText;
}

//CEleTransScott::DoGetTextLoadCurrent
export const doGetTextLoadCurrent = (props:MsTransScottModel, nPartKind:number, bUnit:boolean = true) =>
{
	let sText = '';
	switch (nPartKind) {
	case MS_SEAT_MAIN:		
		sText = BeamsNumber(props.partSeatM.calcPoint.loadCurrent, 5).toString();
		break;
	case MS_SEAT_T:		
		sText = BeamsNumber(props.partSeatT.calcPoint.loadCurrent, 5).toString();
		break;
	}
	if (bUnit) {
		sText += 'A';
	}
	return sText;
}

//CEleTransScott::DoGetTextDropVoltPF
export const doGetTextDropVoltPF = (props:MsTransScottModel, nPartKind:number, bUnit:boolean = true) =>
{
	let sText = '';
	switch (nPartKind) {
	case MS_SEAT_MAIN:		
		sText = BeamsNumber(props.partSeatM.calcPoint.powerFactor, 5).toString();
		if (bUnit) {
			if (props.partSeatM.calcPoint.isLead) {
				sText += '(進み)';
			}
			else {
				sText += '(遅れ)';
			}
		}
		break;
	case MS_SEAT_T:		
		sText = BeamsNumber(props.partSeatT.calcPoint.powerFactor, 5).toString();
		if (bUnit) {
			if (props.partSeatT.calcPoint.isLead) {
				sText += '(進み)';
			}
			else {
				sText += '(遅れ)';
			}
		}
		break;
	}
	return sText;
}

//CEleTransScott::DoGetTextDropVoltDiff
export const doGetTextDropVoltDiff = (props:MsTransScottModel, dStdCapacity:number, nOpeMode:number, nPartKind:number, bUnit:boolean = true) =>
{
	let percentZSource = doGetPerZrTransScott(props, dStdCapacity);
	percentZSource.perRk3Source = 0;
	percentZSource.perXk3Source = 0;
	let dVolt = 0;
	let percentZ = {
		dPercentZ: 0,
        pdPerRk3: 0,
        pdPerXk3: 0
	}

	if (nOpeMode == MS_MODE_DRAWING) {
		switch (nPartKind) {
		case MS_SEAT_MAIN:		
			percentZ = doGetValuePerZk2SeatM(percentZSource);
			dVolt = doGetValueDropVoltRegularCalcPoint(props.voltageSecondary, percentZ.pdPerRk3, percentZ.pdPerXk3, props.partSeatM.calcPoint);
			dVolt = props.voltageSecondary - dVolt;
			break;
		case MS_SEAT_T:		
			percentZ = doGetValuePerZk2SeatT(percentZSource);
			dVolt = doGetValueDropVoltRegularCalcPoint(props.voltageSecondary, percentZ.pdPerRk3, percentZ.pdPerXk3, props.partSeatT.calcPoint);
			dVolt = props.voltageSecondary - dVolt;
			break;
		}
	}
	else {
		if (props.supply) {
			switch (nPartKind) {
			case MS_SEAT_MAIN:		
				percentZ = doGetValuePerZk2SeatM(percentZSource);
				dVolt = doGetValueDropVoltRegularCalcPoint(props.voltageSecondary, percentZ.pdPerRk3, percentZ.pdPerXk3, props.partSeatM.calcPoint);
				dVolt = props.voltageSecondary - dVolt;
				break;
			case MS_SEAT_T:		
				percentZ = doGetValuePerZk2SeatT(percentZSource);
				dVolt = doGetValueDropVoltRegularCalcPoint(props.voltageSecondary, percentZ.pdPerRk3, percentZ.pdPerXk3, props.partSeatT.calcPoint);
				dVolt = props.voltageSecondary - dVolt;
				break;
			}
		}
	}

	let sText = BeamsNumber(dVolt, 4).toString();
	if (bUnit) {
		sText += 'V';
	}
	return sText;
}

//CEleTransScott::DoGetTextDropVoltRegular
export const doGetTextDropVoltRegular = (props:MsTransScottModel, dStdCapacity:number, nPartKind:number, bUnit:boolean = true) =>
{
	let sText = '';
	let percentZSource = doGetPerZrTransScott(props, dStdCapacity);
	percentZSource.perRk3Source = 0;
	percentZSource.perXk3Source = 0;
	let percentZ = {
		dPercentZ: 0,
        pdPerRk3: 0,
        pdPerXk3: 0
	}
	switch (nPartKind) {
	case MS_SEAT_MAIN:		
		percentZ = doGetValuePerZk2SeatM(percentZSource);
		if (props.supply) {
			sText = doGetTextDropVoltRegularCalcPoint(props.partSeatM.calcPoint, props.voltageSecondary, percentZ.pdPerRk3, percentZ.pdPerXk3, bUnit);
		}
		else {
			sText = doGetTextDropVoltRegularCalcPoint(props.partSeatM.calcPoint, 0, percentZ.pdPerRk3, percentZ.pdPerXk3, bUnit);
		}
		break;
	case MS_SEAT_T:		
		percentZ = doGetValuePerZk2SeatT(percentZSource);
		if (props.supply) {
			sText = doGetTextDropVoltRegularCalcPoint(props.partSeatT.calcPoint, props.voltageSecondary, percentZ.pdPerRk3, percentZ.pdPerXk3, bUnit);
		}
		else {
			sText = doGetTextDropVoltRegularCalcPoint(props.partSeatT.calcPoint, 0, percentZ.pdPerRk3, percentZ.pdPerXk3, bUnit);
		}
		break;
	}
	return sText;
}

//CEleTransScott::DoGetTextDropPerRegular
export const doGetTextDropPerRegular = (props:MsTransScottModel, dStdCapacity:number, nPartKind:number, bUnit:boolean = true) =>
{
	let sText = '';
	let percentZSource = doGetPerZrTransScott(props, dStdCapacity);
	percentZSource.perRk3Source = 0;
	percentZSource.perXk3Source = 0;
	let percentZ = {
		dPercentZ: 0,
        pdPerRk3: 0,
        pdPerXk3: 0
	}
	switch (nPartKind) {
	case MS_SEAT_MAIN:		
		percentZ = doGetValuePerZk2SeatM(percentZSource);
		if (props.supply) {
			sText = doGetTextDropPerRegularCalcPoint(props.partSeatM.calcPoint, props.voltageSecondary, percentZ.pdPerRk3, percentZ.pdPerXk3, bUnit);
		}
		else {
			sText = doGetTextDropPerRegularCalcPoint(props.partSeatM.calcPoint, 0, percentZ.pdPerRk3, percentZ.pdPerXk3, bUnit);
		}
		break;
	case MS_SEAT_T:		
		percentZ = doGetValuePerZk2SeatT(percentZSource);
		if (props.supply) {
			sText = doGetTextDropPerRegularCalcPoint(props.partSeatT.calcPoint, props.voltageSecondary, percentZ.pdPerRk3, percentZ.pdPerXk3, bUnit);
		}
		else {
			sText = doGetTextDropPerRegularCalcPoint(props.partSeatT.calcPoint, 0, percentZ.pdPerRk3, percentZ.pdPerXk3, bUnit);
		}
		break;
	}
	return sText;
}

//CEleTransScott::DoGetTextDropPerRegular2
export const doGetTextDropPerRegular2 = (props:MsTransScottModel, dStdCapacity:number, nPartKind:number, bUnit:boolean = true) =>
{
	let sText = '';
	let percentZSource = doGetPerZrTransScott(props, dStdCapacity);
	percentZSource.perRk3Source = 0;
	percentZSource.perXk3Source = 0;
	let percentZ = {
		dPercentZ: 0,
        pdPerRk3: 0,
        pdPerXk3: 0
	}
	switch (nPartKind) {
	case MS_SEAT_MAIN:		
		percentZ = doGetValuePerZk2SeatM(percentZSource);
		if (props.supply) {
			sText = doGetTextDropPerRegular2CalcPoint(props.partSeatM.calcPoint, props.voltageSecondary, percentZ.pdPerRk3, percentZ.pdPerXk3, bUnit);
		}
		else {
			sText = doGetTextDropPerRegular2CalcPoint(props.partSeatM.calcPoint, 0, percentZ.pdPerRk3, percentZ.pdPerXk3, bUnit);
		}
		break;
	case MS_SEAT_T:		
		percentZ = doGetValuePerZk2SeatT(percentZSource);
		if (props.supply) {
			sText = doGetTextDropPerRegular2CalcPoint(props.partSeatT.calcPoint, props.voltageSecondary, percentZ.pdPerRk3, percentZ.pdPerXk3, bUnit);
		}
		else {
			sText = doGetTextDropPerRegular2CalcPoint(props.partSeatT.calcPoint, 0, percentZ.pdPerRk3, percentZ.pdPerXk3, bUnit);
		}
		break;
	}
	return sText;
}

//CEleTransScott::DoGetRefV1
export const doGetRefV1 = (mode:any, control:ControlModel, bSociety:boolean) =>
{
	let props = control.properties as MsTransScottModel;
    let infoCircuit = store.getState().app.diagram.infoCircuit;
    let project = store.getState().app.projectData;

	//CEleTransScott::DoGetRefV1Left  sRefV1Left
	let labelTexts = [{
		value: control.properties.refNo,
		color: "#f800f8",
	}];

	if (!props.viewResultText || (mode != ProcessMode.DRAWING && !props.supply))
    {
      return labelTexts;
    }
    
    if (bSociety){
		// VoltPrimary / VoltSecondary
		labelTexts.push({
			value: doGetTextVoltPrimary(props)
			+ '/'
			+ doGetTextVoltSecondary(props),
			color: "blue",
		});

		// Capacity
		labelTexts.push({
			value: doGetTextCapacity(props),
			color: "blue",
		});
        return labelTexts;
    }

	switch(mode) {
        case ProcessMode.DRAWING:
        case ProcessMode.CHECKING:
			// VoltPrimary / VoltSecondary
			labelTexts.push({
				value: doGetTextVoltPrimary(props)
				+ '/'
				+ doGetTextVoltSecondary(props),
				color: "blue",
			});

			// Capacity
			labelTexts.push({
				value: doGetTextCapacity(props),
				color: "blue",
			});

			// SEAT_MAIN
			labelTexts.push({
				value: '主座',
				color: "blue",
			});

			// doGetTextRatedCurrent,doGetTextPerZe
            labelTexts.push({
                value: doGetTextRatedCurrent(props, MS_SEAT_MAIN)
                + ','
                + doGetTextPerZe(props, MS_SEAT_MAIN),
                color: "blue",
            });
            
            // X/R=doGetTextXR
            labelTexts.push({
                value: 'X/R='
                + doGetTextXR(props, MS_SEAT_MAIN),
                color: "blue",
            });

			// SEAT_T
			labelTexts.push({
				value: 'T座',
				color: "blue",
			});

			// doGetTextRatedCurrent,doGetTextPerZe
            labelTexts.push({
                value: doGetTextRatedCurrent(props, MS_SEAT_T)
                + ','
                + doGetTextPerZe(props, MS_SEAT_T),
                color: "blue",
            });
            
            // X/R=doGetTextXR
            labelTexts.push({
                value: 'X/R='
                + doGetTextXR(props, MS_SEAT_T),
                color: "blue",
            });

			break;
		case ProcessMode.PERCENT_Z:
			// SEAT_MAIN
			labelTexts.push({
				value: '主座',
				color: "blue",
			});

			 // X/R=doGetTextXR
			 labelTexts.push({
                value: 'X/R='
                + doGetTextXR(props, MS_SEAT_MAIN),
                color: "blue",
            });

			// Zr=Rr+jXr
			labelTexts.push({
				value: doGetTextPerZr(props, MS_SEAT_MAIN, infoCircuit.stdCapacity)
				+ '='
				+ doGetTextPerRr(props, MS_SEAT_MAIN, infoCircuit.stdCapacity, false)
				+ '+j'
				+ doGetTextPerXr(props, MS_SEAT_MAIN, infoCircuit.stdCapacity, false),
				color: "blue",
            });

			// SEAT_T
			labelTexts.push({
				value: 'T座',
				color: "blue",
			});

			 // X/R=doGetTextXR
			 labelTexts.push({
                value: 'X/R='
                + doGetTextXR(props, MS_SEAT_T),
                color: "blue",
            });

			// Zr=Rr+jXr
			labelTexts.push({
				value: doGetTextPerZr(props, MS_SEAT_T, infoCircuit.stdCapacity)
				+ '='
				+ doGetTextPerRr(props, MS_SEAT_T, infoCircuit.stdCapacity, false)
				+ '+j'
				+ doGetTextPerXr(props, MS_SEAT_T, infoCircuit.stdCapacity, false),
				color: "blue",
            });

			break;
		case ProcessMode.CALCULATION:
			// VoltPrimary / VoltSecondary
			labelTexts.push({
				value: doGetTextVoltPrimary(props)
				+ '/'
				+ doGetTextVoltSecondary(props),
				color: "blue",
			});

			if(props.partSeatM.fault) {
				// SEAT_MAIN
				labelTexts.push({
					value: '主座',
					color: "blue",
				});

				// += 'I"k2' + '通過' + '=' + doGetTextDirectIkSpeak
				labelTexts.push({
					value: 'I"k2通過='
					+ doGetTextDirectIkSpeak(props, MS_SEAT_MAIN),
					color: "blue",
				});

				// += 'I"k2' + '通過' + '=' + doGetTextDirectIkSasym
				labelTexts.push({
					value: 'I"k2通過='
					+ doGetTextDirectIkSasym(props, MS_SEAT_MAIN),
					color: "blue",
				});

				// += 'I"k2' + '合計' + '=' + doGetTextDirectIkSsym()
				labelTexts.push({
					value: 'I"k2合計='
					+ doGetTextDirectIkSsym(props, MS_SEAT_MAIN),
					color: "blue",
				});
			}

			if(props.partSeatT.fault) {
				// SEAT_T
				labelTexts.push({
					value: 'T座',
					color: "blue",
				});

				// += 'I"k2' + '通過' + '=' + doGetTextDirectIkSpeak
				labelTexts.push({
					value: 'I"k2通過='
					+ doGetTextDirectIkSpeak(props, MS_SEAT_T),
					color: "blue",
				});

				// += 'I"k2' + '通過' + '=' + doGetTextDirectIkSasym
				labelTexts.push({
					value: 'I"k2通過='
					+ doGetTextDirectIkSasym(props, MS_SEAT_T),
					color: "blue",
				});

				// += 'I"k2' + '合計' + '=' + doGetTextDirectIkSsym()
				labelTexts.push({
					value: 'I"k2合計='
					+ doGetTextDirectIkSsym(props, MS_SEAT_T),
					color: "blue",
				});
			}
			break;
		case ProcessMode.VOLTDROP:
			// VoltPrimary / VoltSecondary
			labelTexts.push({
				value: doGetTextVoltPrimary(props)
				+ '/'
				+ doGetTextVoltSecondary(props),
				color: "blue",
			});

			if(project.dropMode != MS_VOLTDROP_REGULAR) {
				break;
			}

			if(props.partSeatM.dropRegular) {
				// SEAT_MAIN
				labelTexts.push({
					value: '主座',
					color: "blue",
				});
				
				 // IL=doGetTextLoadCurrent,力率=doGetTextDropVoltPF
				 labelTexts.push({
                    value: 'IL='
                    + doGetTextLoadCurrent(props, MS_SEAT_MAIN)
                    + ',力率='
                    + doGetTextDropVoltPF(props, MS_SEAT_MAIN),
                    color: "blue",
                });

                //Vd=doGetTextDropVoltDiff (doGetTextVoltage-doGetTextDropVoltRegular)
                let sTemp = 'Vd=';
                sTemp += doGetTextDropVoltDiff(props, infoCircuit.stdCapacity, project.opeMode, MS_SEAT_MAIN);
                sTemp += ' (';
                if(props.supply) {
                    sTemp += doGetTextVoltSecondary(props, false);
                }
                else {
                    sTemp += '0';
                }
                sTemp += '-';
                sTemp += doGetTextDropVoltRegular(props, infoCircuit.stdCapacity, MS_SEAT_MAIN, false);
                sTemp += ')';
                labelTexts.push({
                    value: sTemp,
                    color: "blue",
                });

                //Vp=doGetTextDropPerRegular (doGetTextDropPerRegular2)
                labelTexts.push({
                    value: 'Vp='
                    + doGetTextDropPerRegular(props, infoCircuit.stdCapacity, MS_SEAT_MAIN)
                    + ' ('
                    + doGetTextDropPerRegular2(props, infoCircuit.stdCapacity, MS_SEAT_MAIN)
                    + ')',
                    color: "blue",
                });
			}

			if(props.partSeatT.dropRegular) {
				// SEAT_T
				labelTexts.push({
					value: 'T座',
					color: "blue",
				});

				 // IL=doGetTextLoadCurrent,力率=doGetTextDropVoltPF
				 labelTexts.push({
                    value: 'IL='
                    + doGetTextLoadCurrent(props, MS_SEAT_T)
                    + ',力率='
                    + doGetTextDropVoltPF(props, MS_SEAT_T),
                    color: "blue",
                });

                //Vd=doGetTextDropVoltDiff (doGetTextVoltage-doGetTextDropVoltRegular)
                let sTemp = 'Vd=';
                sTemp += doGetTextDropVoltDiff(props, infoCircuit.stdCapacity, project.opeMode, MS_SEAT_T);
                sTemp += ' (';
                if(props.supply) {
                    sTemp += doGetTextVoltSecondary(props, false);
                }
                else {
                    sTemp += '0';
                }
                sTemp += '-';
                sTemp += doGetTextDropVoltRegular(props, infoCircuit.stdCapacity, MS_SEAT_T, false);
                sTemp += ')';
                labelTexts.push({
                    value: sTemp,
                    color: "blue",
                });

                //Vp=doGetTextDropPerRegular (doGetTextDropPerRegular2)
                labelTexts.push({
                    value: 'Vp='
                    + doGetTextDropPerRegular(props, infoCircuit.stdCapacity, MS_SEAT_T)
                    + ' ('
                    + doGetTextDropPerRegular2(props, infoCircuit.stdCapacity, MS_SEAT_T)
                    + ')',
                    color: "blue",
                });
			}
			break;
		case ProcessMode.HARMONIC:
			// VoltPrimary / VoltSecondary
			labelTexts.push({
				value: doGetTextVoltPrimary(props)
				+ '/'
				+ doGetTextVoltSecondary(props),
				color: "blue",
			});
			break;
		case ProcessMode.POWER_FLOW:
			break;
	}

	return labelTexts;
}

export const doGetRefV2_0 = (properties: MsTransScottModel, dNominalVolt:number, nPartKind:number) => { 
	let infos = []
    const text = properties.refNo;
    const name = properties.partSeatM.pointText
    const lVolSecondary = properties.voltageSecondary;
    const dCapacity = Math.round(properties.capacity);
    let dInrushTimes = 0;
    let dDampingTime = 0;
	
    if(nPartKind === 1){
        dInrushTimes = properties.partSeatM.inrushTimes;
        dDampingTime = properties.partSeatM.dampingTime;
    }
	
    if(nPartKind === 2){
        dInrushTimes = properties.partSeatT.inrushTimes;
        dDampingTime = properties.partSeatT.dampingTime;
    }
    const textRateCurrent = doGetTextRatedCurrent(properties, nPartKind);
    infos.push(text +`,`);
    infos.push(nPartKind === 1 ? `主座` : `T座`)
    infos.push(name);
    infos.push(dCapacity.toString()+`kVA`);
    if(lVolSecondary === dNominalVolt){
        infos.push(textRateCurrent.toString() +`A`)
    } else {
        const dRateCurrent = doGetValueRatedCurrent(lVolSecondary,dCapacity);
        const ampere = Math.round(dRateCurrent * lVolSecondary /dNominalVolt)
        infos.push(ampere.toString()+`A` +`(` +textRateCurrent+`)`);

    }
    
    infos.push(Math.round(dInrushTimes).toString()+`倍`+`,`+ dDampingTime+`ms`)
	return infos
}

export const doGetRefV2_1 = (properties: MsTransScottModel, dNominalVolt:number, nPartKind:number) => {
	let infos = []
	const text = properties.refNo;
    const name = properties.partSeatM.pointText
    const lVolSecondary = properties.voltageSecondary;
    const dCapacity = Math.round(properties.capacity);
    infos.push(text);
    infos.push(nPartKind === 1 ? `主座` : `T座`)
    infos.push(name);
    infos.push(dCapacity.toString()+`kVA`)
    let dInrushTimes = 0;
    let m_dIntensityTime = 0;
	
    if(nPartKind === 1){
        dInrushTimes = properties.partSeatM.inrushTimes;
        m_dIntensityTime = properties.partSeatM.intensityTime;
    }
	
	if(nPartKind === 2){
		dInrushTimes = properties.partSeatT.inrushTimes;
		m_dIntensityTime = properties.partSeatT.intensityTime;
	}

	infos.push(dInrushTimes.toString()+`倍`);
	const arms = Math.round(doGetTextIntensityValue(nPartKind, lVolSecondary, dCapacity, dInrushTimes));
	if(lVolSecondary === dNominalVolt){
		infos.push(arms.toString() +`Arms`);
		
	}
	else{
		let dIntensityValue = 0;
		const dRateCurrent = doGetValueRatedCurrent(lVolSecondary,dCapacity);
		dIntensityValue = dInrushTimes * dRateCurrent;
		const arms1 = Math.round(dIntensityValue * lVolSecondary/dNominalVolt);
		infos.push(arms1 +`Arms` +`(`+arms+`)`)
		
	}
	infos.push(m_dIntensityTime.toString() +`s`)
	return infos
}

const doGetTextIntensityValue =(
	nPartKind: number,
	m_lVoltSecondary: number,
	m_dCapacity: number,
	m_dIntensityTimes: number
) =>{
    let dRateCurrent = 0;
    let dIntensityValue = 0;
    switch(nPartKind){
        case 1://MS_SEAT_MAIN //主座
            dRateCurrent = doGetValueRatedCurrent(m_lVoltSecondary, m_dCapacity)
            if(dRateCurrent === 0){
                dIntensityValue = 0
            }else{
                dIntensityValue = m_dIntensityTimes * dRateCurrent;
            }
            break;
        case 2://MS_SEAT_T //T座
            dRateCurrent = doGetValueRatedCurrent(m_lVoltSecondary, m_dCapacity);
            if(dRateCurrent === 0){
                dIntensityValue = 0;
            }else{
                dIntensityValue = m_dIntensityTimes * dRateCurrent;
            }
            break;
    }
    return dIntensityValue;//Arms
}
//CEleTransScott::DoGetTextFlowsResult
export const doGetTextFlowsResult = (props:MsTransScottModel, processMode:any, nDirect:number, bSociety:boolean = false) =>
{
	let sRefV1:string[] = [];

	if (bSociety) {
		return sRefV1;
	}

	switch (processMode) {
	case MS_MODE_DRAWING:
	case MS_MODE_CHECKING:
	case MS_MODE_PERCENT_Z:
	case MS_MODE_CALCULATION:
	case MS_MODE_VOLTDROP:
	case MS_MODE_HARMONIC:
		break;
	case MS_MODE_POWER_FLOW:
		if (props.activePower !==0 && props.reactivePower !==0) {
			if (nDirect == DIRECT_NONE) {
				sRefV1.push(BeamsNumber(Math.abs(props.activePower),5).toString() + 'kW');
				sRefV1.push(BeamsNumber(Math.abs(props.reactivePower),5).toString() + 'kvar');
			}
			else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
				sRefV1.push(BeamsNumber(Math.abs(props.activePower),5).toString() + 'kW');
				let sTemp = ''
				if (props.reactivePower > 0) {
					sTemp += "+j";
				}
				else {
					sTemp += "-j";
				}
				sRefV1.push(sTemp + BeamsNumber(Math.abs(props.reactivePower),5).toString() + 'kvar');
			}
		}
		break;
	}

	return sRefV1;
}