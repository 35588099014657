import { AgGridReact } from "ag-grid-react";
import { useRef } from "react";
import { AG_GRID_LOCALE_JP } from "../../themes/localeJP";
import { Grid, IconButton, MenuItem, Select, Typography } from "@mui/material";
import ModeEdit from "@mui/icons-material/ModeEdit";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PreviewIcon from '@mui/icons-material/Preview';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';

import { OptionModel } from "../../models/Index";
import { reportIndexToName } from "../../statics";

export type ReportGridFormProps = {
  rowData: any;
  listElementKind: OptionModel[];
  listTransCenter: OptionModel[];
  editReport:(data:any) => void
  previewPDF: (data: any) => void;
  downloadPDF: (data: any) => void;
  downloadCSV: (data: any) => void;
};

export const ReportGrid = (props: ReportGridFormProps) => {
  const { 
    rowData,
    listElementKind,
    listTransCenter,
    editReport,
    previewPDF,
    downloadPDF,
    downloadCSV
  } = props;

  const gridRef = useRef() as any;
  const dictReport: any = reportIndexToName

  const reportNameRenderer = (props: any) => {
    return (
      <Typography variant="body2" color="initial">
        {dictReport[props.value] || props.value}
      </Typography>
    );
  };

  const cellOptionRenderer = (props: any) => {
    // 詳細
    if (props.value == null) return (<>ー</>)

    let listData: OptionModel[];
    if (props.colDef.field === "eleKind") listData = listElementKind
    else listData = listTransCenter

    const handleChange = (e: any) => {
      props.setValue(e.target.value)
    };
    return (
      <Grid width={"100%"}>
        <Select
          fullWidth
          sx={{ height: 32 }}
          value={props.value}
          onChange={handleChange}
        >
          {listData.map((item) => (
            <MenuItem key={item.label} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </Grid>
    );
  };
  
  const cellActionRenderer = (props: any) => {
    // 詳細
    const handleEdit = () => {
      // onShowDetails(props.data.projectId);
      let data = {
        reportIndex: props.data.reportIndex,
      }
      editReport(data)
    };

    const handlePreviewPDF = () => {
      let data = {
        reportIndex: props.data.reportIndex,
        eleKind: props.data.eleKind,
        reportNoTransCenter: props.data.reportNoTransCenter,
        target: props.data.target
      }
      previewPDF(data)
    };
    const handleDownloadPDF = () => {
      let data = {
        reportIndex: props.data.reportIndex,
        eleKind: props.data.eleKind,
        reportNoTransCenter: props.data.reportNoTransCenter,
        target: props.data.target
      }
      downloadPDF(data)
    };
    
    const handleDownloadCSV = () => {
      let data = {
        reportIndex: props.data.reportIndex,
        eleKind: props.data.eleKind,
        reportNoTransCenter: props.data.reportNoTransCenter
      }
      downloadCSV(data)
    };
    return (
      <Grid
        container
        direction="row"
      >
        <IconButton aria-label="edit" onClick={handleEdit}>
          <ModeEdit fontSize="small" />
        </IconButton>
        <IconButton aria-label="pdf" onClick={handlePreviewPDF}>
          <PreviewIcon fontSize="small" />
        </IconButton>
        <IconButton aria-label="pdf" onClick={handleDownloadPDF}>
          <PictureAsPdfIcon fontSize="small" />
        </IconButton>
        {props.data.canExportCSV &&
          <IconButton aria-label="csv" onClick={handleDownloadCSV}>
            <DescriptionOutlinedIcon fontSize="small" />
          </IconButton>
        }
      </Grid>
    );
  };

  // 全列に共通の定義
  const defaultColDef = {
    resizable: true,
    suppressMovable: true, // 列の移動を無効にする
  };

  const columnDefs = [
    {
      headerName: "対象",
      field: "target",
      rowDrag: false,
      flex: 1,
      editable: true,
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      })
    },
    {
      headerName: "レポート",
      field: "reportIndex",
      rowDrag: false,
      flex: 3,
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
      }),
      cellRenderer: reportNameRenderer,
    },
    {
      headerName: "",
      field: "eleKind",
      rowDrag: false,
      flex: 3,
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }),
      cellRenderer: cellOptionRenderer,
    },
    {
      headerName: "",
      field: "reportNoTransCenter",
      rowDrag: false,
      flex: 3,
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }),
      cellRenderer: cellOptionRenderer,
      
    },
    {
      headerName: "",
      field: "action",
      reseizable: false,
      suppressMovable: true,
      minWidth: 200,
      maxWidth: 200,
      cellRenderer: cellActionRenderer,
    },
  ];

  const rowHeight = 42;

  return (
    <div
      className="ag-theme-alpine"
      style={{ height: "100%", width: "100%" }}
    >
      <AgGridReact
        ref={gridRef}
        defaultColDef={defaultColDef}
        columnDefs={columnDefs}
        domLayout={"normal"}
        // rowSelection={'multiple'}
        rowData={rowData}
        animateRows={true}
        rowDragManaged={true}
        suppressMovableColumns={false}
        suppressDragLeaveHidesColumns={false}
        rowHeight={rowHeight}
        localeText={AG_GRID_LOCALE_JP}
      />
    </div>
  );
};

export default ReportGrid;
