import { Box, Button, Checkbox, CircularProgress, FormControlLabel, Grid, Stack, Tab, Tabs, TextField, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { ApplicationState, actionCreators, selectors } from "../../../store";
import { BreakOpeKindModel, BreakTypeModel } from "../../../models/breakModel";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import FetchHelper from "../../FetchHelper";
import { connect } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { Path as RoutePath } from "../../../path";
import { BreakGroupModel, ManufactureModel } from "../../../models/breakModel";
import AlertDialog from "../../common/AlertDialog";
import BreakOpeKindEdit from "../BreakOpeKind/BreakOpeKindEdit";
import BreakOpeKindGrid from "../BreakOpeKind/BreakOpeKindGrid";
// ------------------
// FETCH ID
const GET_BREAKTYPE = "GET_BREAKTYPE";
const UPDATE_BREAKTYPE = "UPDATE_BREAKTYPE";
const CREATE_BREAKTYPE = "CREATE_BREAKTYPE";
const DELETE_BREAK_OPEKIND = "DELETE_BREAK_OPEKIND";
const SEARCH_BREAKTYPE_BY_ID = "SEARCH_BREAKTYPE_BY_ID";
// ---------
const initialValue: BreakTypeModel = {
    lTypeID: 0,
    mv3breakManufacture: {
        lManufactureID: 0,
        bIsDefSource: false,
        bIsDefHVCB: false,
        bIsDefLVCB: false,
        bIsDefThermal: false,
        bIsDefFuse: false,
        bIsDef2E: false,
        sManuName: '',
        sManuKana: ''
    },
    mv3breakBreakGroup: {
        lGroupID: 0,
        nKindFlag: 1,
        sGroupName: '',
        bIsDefault: false,
        mv3breakManufacture: {

            lManufactureID: 0,
    
            bIsDefSource: false,
    
            bIsDefHVCB: false,
    
            bIsDefLVCB: false,
    
            bIsDefThermal: false,
    
            bIsDefFuse: false,
    
            bIsDef2E: false,
    
            sManuName: '',
    
            sManuKana: ''
    
        }
    },
    bIsDefault: false,
    sTypeName: '',
    bCanUseCT: false,
    bCanUseCC: false,
    hidden: false,
    sOpeKindTitle: '',
    sSeriesName: '',
    sOldNew: '',
    sNewDate: '',
    sUpdateDate: '',
    sMaker: '',
    sNotes: '',
}
// -----------------
// Tabs
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, index, value, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `oilManagement-tab-${index}`,
        'aria-controls': `oilManagement-tabpanel-${index}`,
    };
}
// ----
// Props
export type BreakTypeEditFormProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
export const BreakTypeEdit = (props: BreakTypeEditFormProps) => {
    const {
        userId,
        fetchUpdate,
        onUpdate, onUpdateError,
        onSuccess, onError,
        onCreate, onCreateError,
        onDeleteOpeKind, onSuccessDeleteOpeKind, onErrorDeleteOpeKind,
        onSearchById,
    } = props;

    const { control, handleSubmit, setValue, getValues } = useForm<BreakTypeModel>({
        criteriaMode: "all",
        defaultValues: initialValue,
    })
    const gridRef = useRef() as any;
    type MyParams = {
        id: string;
        mode: string;
    };
    let { id, mode } = useParams<keyof MyParams>() as MyParams;
    const location = useLocation();
    let lBreakGroupId = location.state ? location.state.lGroupId : undefined;
    let lManufactureID = location.state ? location.state.lManufactureID : undefined;
    let sManuName = location.state ? location.state.sManuName : "";
    let sGroupName = location.state ? location.state.sGroupName : "";

    useEffect(() => {
        if (sManuName !== undefined && sGroupName !== undefined) {
            setValue("mv3breakBreakGroup.sGroupName", sGroupName);
            setValue("mv3breakManufacture.sManuName", sManuName);
        }
    }, [])

    let navigate = useNavigate();
    let tab = location.state && location.state.tab;
    const object = {
        requestPrms:{
            id:id
            
        },
        userId: userId,
    }
    const [selectTab, setSelectTab] = useState(0);
    const [loadingFlag, setLoadingFlag] = useState<boolean>(mode === "edit" ? true : false);
    const [openedDeleteDialog, setOpenedDeleteDialog] = useState<boolean>(false);
    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");
    const ref = useRef() as any;
    const [idDelete, setIdDelete] = useState<string>("");
    const [colState, setColState] = useState<any>();
    const [mv3breakBreakOpeKindList, setMv3breakBreakOpeKindList] = useState<BreakOpeKindModel[]>([])



    const handleSuccess = (data: any) => {
        console.log(">>> handleSuccess")
        setValue("lTypeID", data.data.ltypeID);
        setValue("bIsDefault", data.data.bisDefault);
        setValue("sTypeName", data.data.stypeName);
        setValue("bCanUseCT", data.data.bcanUseCT);
        setValue("bCanUseCC", data.data.bcanUseCC);
        setValue("hidden", data.data.hidden);
        setValue("sOpeKindTitle", data.data.sopeKindTitle);
        setValue("sSeriesName", data.data.sseriesName);
        setValue("sOldNew", data.data.soldNew);
        setValue("sNewDate", data.data.snewDate);
        setValue("sUpdateDate", data.data.supdateDate);
        setValue("sMaker", data.data.smaker);
        setValue("mv3breakBreakGroup.lGroupID", data.data.lgroupID);
        setValue("mv3breakBreakGroup.sGroupName", data.data.sgroupName);
        setValue("mv3breakManufacture.lManufactureID", data.data.lmanufactureID);
        setValue("mv3breakManufacture.sManuName", data.data.smanuName);
        setValue("sNotes", data.data.snotes);
        if (data.breakOpeKindList?.length > 0) {
            setMv3breakBreakOpeKindList(data.breakOpeKindList);
        }
        if (tab !== undefined && tab !== null) {
            setSelectTab(tab)
        }
        setLoadingFlag(false);
    }

    // 検索エラー時
    const handleError = (success: boolean, data: any, error: any) => {
        console.log(">>> handleError");
        gridRef.current.hideOverLay();
    }
    // ---
    const handleEditSubmit = (data: BreakTypeModel) => {
        setLoadingFlag(true);
        if (mode === "edit") {
            
            onUpdate({
            "userId": userId,
            "requestPrms":{
                lTypeID: getValues("lTypeID"),
                bIsDefault: getValues("bIsDefault"),
                sTypeName: getValues("sTypeName"),
                bCanUseCT: getValues("bCanUseCT"),
                bCanUseCC: getValues("bCanUseCC"),
                hidden: getValues("hidden"),
                sOpeKindTitle: getValues("sOpeKindTitle"),
                sSeriesName: getValues("sSeriesName"),
                sOldNew: getValues("sOldNew"),
                sNewDate: getValues("sNewDate"),
                sUpdateDate: getValues("sUpdateDate"),
                sMaker: getValues("sMaker"),
                sNotes: getValues("sNotes"),
                lGroupID: getValues("mv3breakBreakGroup.lGroupID"),
                lManufactureID: getValues("mv3breakManufacture.lManufactureID")
            }
            });
        }
        else if (mode === "add") {
            
            onCreate({
            "userId": userId,
            "requestPrms":{
                bIsDefault: getValues("bIsDefault"),
                sTypeName: getValues("sTypeName"),
                bCanUseCT: getValues("bCanUseCT"),
                bCanUseCC: getValues("bCanUseCC"),
                hidden: getValues("hidden"),
                sOpeKindTitle: getValues("sOpeKindTitle"),
                sSeriesName: getValues("sSeriesName"),
                sOldNew: getValues("sOldNew"),
                sNewDate: getValues("sNewDate"),
                sUpdateDate: getValues("sUpdateDate"),
                sMaker: getValues("sMaker"),
                sNotes: getValues("sNotes"),
                lGroupID: lBreakGroupId,
                lManufactureID: lManufactureID
            }
            });
        }
    }

    // キャンセル
    const handleCancel = () => {
        if (location.key !== "default")
            navigate(-1);
        else
            navigate(RoutePath.BreakTypeList);
    }

    //入力欄背景
    const divStyles = {
        backgroundColor: 'white',
        border: '1px solid #bebebe',
        boxShadow: '0 0 5px #fff, 0 0 5px #bebebe, 0 0 1px #aaa',
        padding: '20px 40px',
        marginTop: '40px',
    }
    // --
    const tabHandleChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectTab(newValue);
        if (selectTab === 1) {
            gridRef.current.setRowData(mv3breakBreakOpeKindList);
        }
        navigate(".", {
            replace: true,
            state: {
                tab: newValue,
                lGroupId: lBreakGroupId,
                lManufactureID: lManufactureID,
                sManuName: sManuName,
                sGroupName: sGroupName
            }
        });
    }
    // Submit後のAPI呼び出し成功時
    const handleSubmitSuccess = (success: boolean, data: any, error: any) => {
        onSuccess();
        // if (lBreakGroupId !== undefined && lBreakGroupId !== null) {
        //     navigate(-1);
        // }
        if(mode==="add"){
            navigate(RoutePath.BreakTypeEdit+`/edit/${data.data.ltypeID}`,{replace:true})
        }
        setLoadingFlag(false)
    }
    // Submit後のAPI呼び出しエラー時
    const handleSubmitError = (success: boolean, data: any, error: any) => {
        onCreateError();
    }

    const handleShowDetailPage = (data: any) => {
        navigate(RoutePath.BreakOpeKindEdit + `/edit/${data}`, {
            state: {
                lTypeID: getValues("lTypeID"),
                sTypeName: getValues("sTypeName")
            }
        })
    }

    const handleAdd = () => {
        navigate(RoutePath.BreakOpeKindEdit + `/add`, {
            state: {
                lTypeID: getValues("lTypeID"),
                sTypeName: getValues("sTypeName")
            }
        })
    }

    //削除の処理
    const handleDelete = (data: any) => {
        setIdDelete(data);
        const msg = "ID: " + data + "を削除します。"
        setTitle("定格ダイアル情報削除");
        setMessage(msg);
        setOpenedDeleteDialog(true);
    }

    // 削除確認ダイアログでOKボタンのイベントを受け取って、APIに送信する
    const handleDeleteOK = () => {
        setOpenedDeleteDialog(false);
        onDeleteOpeKind({
            userId: userId,
            requestType: "breakOpeKindDelete",
            requestPrms:{
                    id :idDelete
        }
        });
        setIdDelete("");
    }

    // 削除確認ダイアログでCancelボタンのイベントを受け取って、ダイアログを無効にする
    const handleDeleteCancel = () => {
        setIdDelete("");
        setOpenedDeleteDialog(false);
    }

    const handleSuccessDelete = (success: boolean, data: any, error: any) => {
        console.log(">>>> handledeleteSuccesss");
        onSuccessDeleteOpeKind();
        onSearchById({
            userId: userId,
            requestPrms:{
                    id :id
                }
        });
    }

    const handleErrorDelete = (success: boolean, data: any, error: any) => {
        onErrorDeleteOpeKind();
    }
    const handleSuccessRefresh = (success: boolean, data: any, error: any) => {
        setMv3breakBreakOpeKindList(data.breakOpeKindList);
        gridRef.current.setRowData(data.breakOpeKindList);
    }

    //分析一覧を表示
    const resetGrid = () => {
        if (selectTab === 1) {
            gridRef.current.setRowData(mv3breakBreakOpeKindList);
        }
    }
    useEffect(resetGrid, [selectTab]);

    return (
        <>
            {
                mode === "edit" &&
                <FetchHelper
                    fetchId={GET_BREAKTYPE}
                    url={`/master/break_type/get`}
                    method="POST"
                    json ={true}
                    params={object}
                    request={true}
                    onComplete={(success, data, error) => {
                        success ? handleSuccess(data) : handleError(success, data, error);
                    }}
                />
            }
            {loadingFlag && (
                <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1500, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                    <CircularProgress />
                </div>
            )}
            <div style={{ height: '100%', width: '100%', marginTop: '10px' }}>
                <Stack direction="row" justifyContent="flex-start" alignItems="center" sx={{}} style={{ margin: '18px', color: 'MenuText' }}>
                    <Typography variant="h4">
                        型式詳細
                    </Typography>
                </Stack>
                <Stack>
                    <form onSubmit={handleSubmit(handleEditSubmit)}>
                        <Stack direction='row'>
                            <div style={{ top: "100px", position: "absolute", zIndex: 1200 }}>

                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    {
                                        mode === "edit"
                                            ?
                                            <Tabs value={selectTab} onChange={tabHandleChange} aria-label="oilManagementDetailTabs">
                                                <Tab label='型式' {...a11yProps(0)} />
                                                <Tab label='動作種類' {...a11yProps(1)} />
                                            </Tabs>
                                            :
                                            <Tabs value={selectTab} onChange={tabHandleChange} aria-label="oilManagementDetailTabs">
                                                <Tab label='型式' {...a11yProps(0)} />
                                            </Tabs>
                                    }
                                </Box>
                            </div>
                        </Stack>
                        {/* 型式 */}
                        <TabPanel value={selectTab} index={0}>
                            <div style={divStyles}>
                                <Typography variant="h6">
                                    型式
                                </Typography>
                            <Stack sx={{ maxHeight:"calc(100vh)", overflow: 'auto' }} >
                                <Stack direction={{ xs: 'column', sm: 'row' }} mt={3}>
                                    <Grid container spacing={3} direction={'row'}>
                                        <Grid item xs={12}>
                                            <Controller
                                                control={control}
                                                name="mv3breakManufacture.sManuName"
                                                render={({ field }) => (
                                                    <TextField
                                                        fullWidth
                                                        {...field}
                                                        type="text"
                                                        label="メーカー名"
                                                        size="small"
                                                        disabled={true}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Controller
                                                control={control}
                                                name="mv3breakBreakGroup.sGroupName"
                                                render={({ field }) => (
                                                    <TextField
                                                        fullWidth
                                                        {...field}
                                                        type="text"
                                                        label="グループ名"
                                                        size="small"
                                                        disabled={true}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Controller
                                                control={control}
                                                name="sTypeName"
                                                render={({ field }) => (
                                                    <TextField
                                                        fullWidth
                                                        {...field}
                                                        type="text"
                                                        label="型式名"
                                                        size="small"
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Controller
                                                control={control}
                                                name="sOpeKindTitle"
                                                render={({ field }) => (
                                                    <TextField
                                                        fullWidth
                                                        {...field}
                                                        type="text"
                                                        label="動作種類タイトル"
                                                        size="small"
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Controller
                                                control={control}
                                                name="sSeriesName"
                                                render={({ field }) => (
                                                    <TextField
                                                        fullWidth
                                                        {...field}
                                                        type="text"
                                                        label="シリーズ名称"
                                                        size="small"
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Controller
                                                control={control}
                                                name="sOldNew"
                                                render={({ field }) => (
                                                    <TextField
                                                        fullWidth
                                                        {...field}
                                                        type="text"
                                                        label="改廃表示"
                                                        size="small"
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Controller
                                                control={control}
                                                name="sMaker"
                                                render={({ field }) => (
                                                    <TextField
                                                        fullWidth
                                                        {...field}
                                                        type="text"
                                                        label="作成者"
                                                        size="small"
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Controller
                                                control={control}
                                                name="sNotes"
                                                render={({ field }) => (
                                                    <TextField
                                                        fullWidth
                                                        {...field}
                                                        type="text"
                                                        label="備考"
                                                        size="small"
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Controller
                                                control={control}
                                                name="sNewDate"
                                                render={({ field }) => (
                                                    <TextField
                                                        fullWidth
                                                        {...field}
                                                        type="text"
                                                        label="新規登録日"
                                                        size="small"
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Controller
                                                control={control}
                                                name="sUpdateDate"
                                                render={({ field }) => (
                                                    <TextField
                                                        fullWidth
                                                        {...field}
                                                        type="text"
                                                        label="更新日"
                                                        size="small"
                                                    />
                                                )}
                                            />
                                        </Grid>

                                        {/* <Grid item xs={2} ml={3}>
                                        <Controller
                                            name="bIsDefault"
                                            control={control}
                                            render={({ field }: any) => (
                                                <FormControlLabel {...field} control={<Checkbox />} checked={field.value} label="ﾃﾞﾌｫﾙﾄ" labelPlacement="start" />
                                            )}
                                        />
                                    </Grid> */}
                                    </Grid>

                                </Stack>
                                <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3}>
                                    <Grid container spacing={5} direction={'row'}>
                                        <Grid item xs={2}>
                                            <Controller
                                                name="bIsDefault"
                                                control={control}
                                                render={({ field }: any) => (
                                                    <FormControlLabel {...field} control={<Checkbox />} checked={field.value} label="デフォルト" labelPlacement="start" />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Controller
                                                name="bCanUseCT"
                                                control={control}
                                                render={({ field }: any) => (
                                                    <FormControlLabel {...field} control={<Checkbox />} checked={field.value} label="CT使用" labelPlacement="start" />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Controller
                                                name="bCanUseCC"
                                                control={control}
                                                render={({ field }: any) => (
                                                    <FormControlLabel {...field} control={<Checkbox />} checked={field.value} label="CC使用" labelPlacement="start" />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Controller
                                                name="hidden"
                                                control={control}
                                                render={({ field }: any) => (
                                                    <FormControlLabel {...field} control={<Checkbox />} checked={field.value} label="非表示" labelPlacement="start" />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                </Stack>
                            </Stack>
                            </div>

                        </TabPanel>
                        {/* TODO: DETAIL */}
                        {/* 型式 */}
                        <TabPanel value={selectTab} index={1}>
                            <div style={divStyles}>
                                <Stack direction="row" display='flex' justifyContent='space-between'>
                                    <div><Typography variant="h6" >動作種類</Typography></div>
                                    <div >
                                        <Stack direction='row' display='flex' spacing={2}>
                                            <Stack>
                                                <Button fullWidth color="inherit" variant="contained" onClick={() => handleAdd()}>動作種類の追加</Button>
                                            </Stack>
                                        </Stack>
                                    </div>
                                </Stack>
                                <Stack spacing={2} sx={{ mt: 1 }} >
                                    <BreakOpeKindGrid
                                        ref={gridRef}
                                        colState={colState}
                                        onChangeSearchCondition={() => { }}
                                        keyword={""}
                                        onShowDetails={handleShowDetailPage}
                                        onDelete={handleDelete}
                                        heightForm="365"
                                        checkEdit={true}
                                        mode="edit"
                                    />
                                    {openedDeleteDialog ?
                                        <AlertDialog title={title} message={message} isOpen={true} onOK={handleDeleteOK} onCancel={handleDeleteCancel} /> :
                                        <div />
                                    }
                                </Stack>
                            </div>
                        </TabPanel>
                        <Stack direction='row' justifyContent="flex-end" spacing={2} sx={{ mt: 0, mr: 3 }}>
                            <LoadingButton variant="contained" type="submit" loading={loadingFlag}>保存</LoadingButton>
                            <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                        </Stack>
                    </form>
                </Stack>
            </div>
            <FetchHelper fetchId={CREATE_BREAKTYPE} onComplete={(success, data, error) =>
                success ? handleSubmitSuccess(success, data, error) : handleSubmitError(success, data, error)} />
            <FetchHelper fetchId={UPDATE_BREAKTYPE} onComplete={(success, data, error) =>
                success ? handleSubmitSuccess(success, data, error) : onUpdateError()} />
            <FetchHelper fetchId={DELETE_BREAK_OPEKIND} onComplete={(success, data, error) =>
                success ? handleSuccessDelete(success, data, error) : handleErrorDelete(success, data, error)} />
            <FetchHelper fetchId={SEARCH_BREAKTYPE_BY_ID} onComplete={(success, data, error) =>
                success ? handleSuccessRefresh(success, data, error) : handleErrorDelete(success, data, error)} />

        </>
    );

};
const mapStateToProps = (state: ApplicationState) => ({
    userId: state.app.user?.userId,
    fetchUpdate: selectors.getFormState(
        state,
        GET_BREAKTYPE
    ),
})
const mapDispatchToProps = (dispatch: any) => {
    return {
        onUpdate: (data: any) => dispatch(actionCreators.fetch(UPDATE_BREAKTYPE, `/master/break_type/modify`, "POST", data,false, true)),
        onCreate: (data: any) => dispatch(actionCreators.fetch(CREATE_BREAKTYPE, `/master/break_type/add`, "POST", data,false, true)),
        onSearch: (params: any ) => dispatch(actionCreators.fetch(GET_BREAKTYPE, `/master/break_type/gets`, "POST", params,false, true)),
        onDeleteOpeKind: (id: any) => dispatch(actionCreators.fetch(DELETE_BREAK_OPEKIND, `/master/break_ope_kind/delete`, "POST", id,false,true)),
        onSearchById: (id: any) => dispatch(actionCreators.fetch(SEARCH_BREAKTYPE_BY_ID, `/master/break_type/get`, "POST", id,false,true)),
        onSuccess: () =>
            dispatch(actionCreators.showMessage({
                type: "info",
                title: "型式更新",
                body: "型式を更新しました。",
            })),
        onError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "検索",
                body: "検索できません。",
            })),
        onCreateError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "型式登録",
                body: "型式を登録出来ませんでした。",
            })),
        onUpdateError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "型式編集",
                body: "型式を更新出来ませんでした。",
            })),
        onSuccessDeleteOpeKind: () =>
            dispatch(actionCreators.showMessage({
                type: "info",
                title: "動作種類削除",
                body: "動作種類を削除しました。",
            })),
        onErrorDeleteOpeKind: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "動作種類削除",
                body: "動作種類を削除できませんでした。",
            })),
    }
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BreakTypeEdit as any);



