import { InfoSkeleton, ProcessMode } from "../models/Index";

export const getSymbolAndBackColor = (infoSkeleton:any,processMode:any,properties:any) => {
    let symbolColor = "";
    let backColor = "";
    if(properties.checkNG == true){
      backColor = '#ff00ff';
      symbolColor = "#000000";
    }
    else{
      if(infoSkeleton.dispStress == true && processMode > ProcessMode.DRAWING){
        if(infoSkeleton.dispStainOrBack != true){
          if(properties.supply != true)
            backColor = "#00FF00"
          else
            backColor = "#FFFFFF"
          symbolColor = "#000000";
        }else{
          if(properties.supply != true)
            symbolColor = "#008040"
          else
            symbolColor = "#FF0000"
          backColor = "#FFFFFF";
        }
      }
      else{
        symbolColor = "#000000";
        backColor = "#FFFFFF";
      }
    }
    return {symbolColor:symbolColor,backColor:backColor}
  }