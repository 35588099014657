import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import { Button, ButtonGroup, CircularProgress, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { Controller } from "react-hook-form";
import { GetDetailsData } from "../../utils/DetailsDataController";
import { convertDetailsDataFromBE } from "../../utils/DataConverter";
import { MS_HVCB } from "../../models/ElementKind";

export type SetupRatedProps = {
    base: any;
    elementType: string;
    formSetting: any;
    m_bModePM: boolean;
    modeViewOnly: boolean;
};


export const SetupRated = (props: SetupRatedProps) => {
    const { base, formSetting: { control, setValue, getValues }, elementType, m_bModePM, modeViewOnly } = props;

    const [loadingFlag, setLoadingFlag] = useState(false)

    const handleChangeRated = (e: any) => {
        setValue(e.target.name, e.target.value);
        const selectedRated = base.ratedList.find((rated : any) => rated.value === e.target.value);
        setValue('base.ratedID', selectedRated?.itemId);
        setValue('base.ratedValue', selectedRated?.itemValue);
        setValue('base.fixedName', selectedRated?.label || '');
        setValue("callWatchFuction",true);
        
        if ([1, 2].includes(base.readBase.ratedFlag || -1)){
            DoGetDetailsData('base.ratedID');
        }
    }

    const handleSelectOptionsByButton = (name : string, action : 'next' | 'prev') => {
        let currIndex, incomingIndex = 0;
        switch(name) {
            case 'ctPrimary':
                currIndex = base.ctPrimaryList.findIndex((v : any) => v.value === getValues(`base.ctPrimary`)) ?? 0;
                incomingIndex = action === 'next' ? Math.min(currIndex + 1, base.ctPrimaryList.length - 1) : Math.max(currIndex - 1, 0)
                setValue(`base.ctPrimary`, base.ctPrimaryList[incomingIndex].value)
                break;
            case 'ratedSelectionValue':
                currIndex = base.ratedList.findIndex((v : any) => v.value === getValues(`base.ratedSelectionValue`)) ?? 0;
                incomingIndex = action === 'next' ? Math.min(currIndex + 1, base.ratedList.length - 1) : Math.max(currIndex - 1, 0)
                const toSetValue = base.ratedList[incomingIndex].value
                setValue(`base.ratedSelectionValue`, toSetValue)
                const selectedRated = base.ratedList.find((rated : any) => rated.value === toSetValue)
                setValue('base.ratedID', selectedRated?.itemId)
                setValue('base.ratedValue', selectedRated?.itemValue)
                setValue('base.fixedName', selectedRated?.label || '');
            
                if ([1, 2].includes(base.readBase.ratedFlag || -1)){
                    DoGetDetailsData('base.ratedID');
                }
            
                break;
        }
        setValue("callWatchFuction",true);
    }

    const handleChangeCTPrimary = (e: any) =>{
         setValue(`base.ctPrimary`, e.target.value)
         setValue("callWatchFuction",true);
    }

    const handleChangeRatedRange = (e: any) => {
        setValue(e.target.name, e.target.value);
        setValue("callWatchFuction",true);
        DoGetDetailsData(e.target.name);
    }

    const DoGetDetailsData = async (from: any) => {

        setLoadingFlag(true)
        let newDetailsData = await GetDetailsData(elementType, getValues(`base`), from)
        if (newDetailsData){
            let convertedData = convertDetailsDataFromBE(newDetailsData)
            
            const newBase = {...base, ...convertedData.base}
            const newParts = convertedData.parts
            const newDelaySettings = convertedData.delaySettings

            setValue('base', newBase)
            setValue('parts', newParts)
            setValue('delaySettings', newDelaySettings)
            setValue("callWatchFuction",true);
        }
        
        setLoadingFlag(false)
    }

    return (
        <>
            {loadingFlag &&
                <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1500, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                    <CircularProgress/>
                </div>
            }
            <Grid container mt={1}>
                <Grid item xs={6}>
                    <Stack>
                        <Typography variant="body2" width="65%">範囲:</Typography>
                        <FormControl fullWidth size="small">
                            <Controller
                                name="base.ratedRangeID"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        onChange={handleChangeRatedRange}
                                        style={{
                                            backgroundColor: base.ratedRangeList?.length === 0 
                                                ? "floralwhite" : "white"
                                        }}
                                        disabled = {base.ratedRangeList?.length === 0 || m_bModePM || modeViewOnly}
                                        sx={{height: 32}}
                                    >
                                        {base.ratedRangeList?.map((item : any) => (
                                            <MenuItem key={item.label} value={item.value}>
                                                {item.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )}
                            />
                        </FormControl>
                    </Stack>
                </Grid>
            </Grid>

            <fieldset style={{marginTop:'16px'}}>
                <Grid container>
                    <Grid item xs={4} display={'flex'} alignItems={'center'}>
                        <Typography variant="body2" width="65%">
                            {base.ratedTitle && (base.ratedTitle + " (A):")}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} display={'flex'} alignItems={'center'}>
                        <FormControl fullWidth size="small">
                            <Controller
                                name="base.ratedSelectionValue"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        onChange={handleChangeRated}
                                        style={{
                                            backgroundColor: base.ratedList?.length === 0 
                                                ? "floralwhite" : "white"
                                        }}
                                        disabled = {base.ratedList?.length === 0 || m_bModePM || modeViewOnly}
                                        sx={{height: 32}}
                                    >
                                        {base.ratedList?.map((item : any) => (
                                            <MenuItem key={item.label} value={item.value}>
                                                {item.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={2} display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
                        <ButtonGroup>
                            <Button disabled={m_bModePM || modeViewOnly} onClick={() => {handleSelectOptionsByButton('ratedSelectionValue', 'prev')}} size="small" variant={'outlined'} sx={{'& .MuiButton-startIcon': {marginRight: '0px'}}} startIcon={<ArrowLeft />}></Button>
                            <Button disabled={m_bModePM || modeViewOnly} onClick={() => {handleSelectOptionsByButton('ratedSelectionValue', 'next')}} size="small" variant={'outlined'} sx={{'& .MuiButton-startIcon': {marginRight: '0px'}}} startIcon={<ArrowRight />}></Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
            </fieldset>

            <fieldset style={{marginTop:'16px'}}>
                <Grid container>
                    <Grid item xs={4} display={'flex'} alignItems={'center'}>
                        <Typography variant="body2" width="50%">{base.ctPrimary ? `CT一次側:` : ''}</Typography>
                    </Grid>
                    <Grid item xs={6} display={'flex'} alignItems={'center'}>
                        <FormControl fullWidth size="small">
                            <Controller
                                name={base.ctPrimary ? "base.ctPrimary" : ""}
                                control={control}
                                render={({ field, fieldState }) => (
                                    <Select
                                        {...field}
                                        error={!!fieldState.error}
                                        sx={{ height: 32 }}
                                        disabled={!base.ctPrimary || m_bModePM || modeViewOnly}
                                        onChange={handleChangeCTPrimary}
                                        style={{
                                            backgroundColor: !base.ctPrimary
                                                ? "floralwhite" : "white"
                                        }}
                                    >
                                        {base.ctPrimaryList?.map((item : any) => (
                                            <MenuItem key={item.label} value={item.value}>
                                                {item.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={2} display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
                        <ButtonGroup disabled={!base.ctPrimary}>
                            <Button disabled={m_bModePM || modeViewOnly} onClick={() => {handleSelectOptionsByButton('ctPrimary', 'prev')}} size="small" variant={'outlined'} sx={{'& .MuiButton-startIcon': {marginRight: '0px'}}} startIcon={<ArrowLeft />}></Button>
                            <Button disabled={m_bModePM || modeViewOnly} onClick={() => {handleSelectOptionsByButton('ctPrimary', 'next')}} size="small" variant={'outlined'} sx={{'& .MuiButton-startIcon': {marginRight: '0px'}}} startIcon={<ArrowRight />}></Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
            </fieldset>
        </>
    );
};
export default SetupRated;


