import { useEffect, useState } from 'react';
import { NavLink as RouterLink, matchPath, matchRoutes, useLocation } from 'react-router-dom';
// material
import { Box, Collapse, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { alpha, styled, useTheme } from '@mui/material/styles';
//
//import Iconify from './Iconify';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { NavMenuItem } from '../../models/Index';
import { stages } from 'konva/lib/Stage';
// ----------------------------------------------------------------------

export type NavItemProps = {
  item: NavMenuItem;
  active: Function;
  stage?:number;
};

function NavItem(props: NavItemProps) {
  const { item, active, stage } = props;
  const theme = useTheme();
  const isActiveRoot = active(item.path);
  const { title, path, icon, info, children, key } = item;
  const [open, setOpen] = useState(true);

  const location = useLocation()
  const handleOpen = () => {
    setOpen((prev: boolean) => !prev);
  };

  const activeRootStyle = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
    '&:before': { display: 'block' }
  };

  const activeSubStyle = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium'
  };

  const pageLoad = () => {
    localStorage.setItem('currentUrl', (window.location.pathname + window.location.search).slice(1));
  };
  useEffect(pageLoad, []);

  if (children !== undefined) {
    return (
      <>
        <ListItemButton
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot)
            
          }}
          style={{marginLeft: stage == 1 ? "0px" : "20px"}}
        >
          <ListItemText disableTypography primary={title} />
          {info && info}
          {
            open ? <ArrowDropDownIcon /> : <ArrowRightIcon />
          }
        </ListItemButton>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((item) => {
              const { key, title, path, children} = item;
              let isActiveSub = path === location.pathname && key !== "";

              if(children && children?.length! > 0 ){
                return (
                <>
                {children.map((item: NavMenuItem) =>{
                  const match = (lastActivePath: string, path: string) => (path ? !!matchPath(lastActivePath, path) : false);
                  return <NavItem stage={2} key={item.key} item={item} active={match} />
                } 
                )}
                </>
                )
                  
            
              }

              return (
                <ListItemButton
                  key={key}
                  component={RouterLink}
                  to={path}
                  sx={{
                    ...(isActiveSub && activeSubStyle)
                  }}
                >
                  <ListItemText disableTypography primary={title} style={{ marginLeft: '40px' }} />
                </ListItemButton>
              );
            })}
          </List>
        </Collapse>
      </>
    );
  }
  let isActiveMainRoot = path === location.pathname && key !== "";
  return (
    <ListItemButton
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveMainRoot && activeRootStyle)
      }}
    >
      <ListItemText disableTypography primary={title} />
      {info && info}
    </ListItemButton>
  );
}

type NavSectionProps = {
  navConfig: NavMenuItem[];
};

export default function NavSection(props: NavSectionProps, { ...other }) {
  const { navConfig } = props;
  const match = (lastActivePath: string, path: string) => (path ? !!matchPath(lastActivePath, path) : false);

  return (
    <Box {...other}>
      <List disablePadding>
        {navConfig.map((item: NavMenuItem) => (
          <NavItem stage={1} key={item.key} item={item} active={match} />
        ))}
      </List>
    </Box>
  );
}
