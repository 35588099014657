import { MsMotorModel, ControlModel, ProcessMode } from "../models/Index";
import { MS_MODE_CALCULATION, MS_MODE_CHECKING, MS_MODE_DRAWING, MS_MODE_HARMONIC, MS_MODE_PERCENT_Z, MS_MODE_POWER_FLOW, MS_MODE_VOLTDROP, VOLT_SIDE_LOW_MAX } from "../statics";
import { store } from "..";
import { BeamsNumber } from "./FormatNumber";
import { DIRECT_NONE, LEFT_CENTER, MS_OUTSIDE_NEUTRAL, MS_OUTSIDE_OUTSIDE, MS_SEAT_MAIN, MS_SEAT_T, TOP_CENTER, VALUE_POWER_MIN } from "../models/Constants";

export const doSetDefaultMotor = (motor:MsMotorModel,voltLowDefaultItem:any,capacityMotorHpDefaultItem:any,capacityMotorList:any,bPhase:boolean,voltage:number) => {
    motor.sPhase = bPhase;
    motor.loadFactor = 1;
    if(voltage <= 0){
        motor.voltageID = voltLowDefaultItem === null?0:voltLowDefaultItem.lvoltage;
    }else{
        motor.voltageID = voltage;
    }
    motor.uIntHP = false;
    const capacityMotorDefaultItem = capacityMotorList.filter((item:any) => item.bisDefault == true)[0];
    if(capacityMotorDefaultItem !== undefined){
        motor.capacityID = capacityMotorDefaultItem.dcapacity;
        motor.efficiency = capacityMotorDefaultItem.defficiency;
    }
    else{
        motor.capacityID = 0
        motor.efficiency = 0
    }
    if(capacityMotorHpDefaultItem !== undefined && capacityMotorHpDefaultItem !== null){
        motor.capacityHpID = capacityMotorHpDefaultItem.dcapacity;
    }
    else{
        motor.capacityHpID = 0
    }
    motor.capacity = motor.capacityID;
    motor.useEfficiency = true;
    doCalcSetValues(motor,capacityMotorList);
    motor.isLead = false;
    if(voltage > VOLT_SIDE_LOW_MAX){
        motor.startingFactor = 0.2;
    }
    else{
        motor.startingFactor = 0.4;
    }

    motor.useBaseLoad = false;
    motor.baseCapacity = 0;
    motor.leadBaseLoad = false;
    motor.baseFactor = 0.85;
    motor.dispBaseLoad = false;

    motor.calcImprovePF = true;
    motor.afterPF = 0.95
    motor.nodeNumber = 0;
    motor.perUnitR = 0
    motor.perUnitX = 0
    motor.perUnitK = 0
    motor.perUnitP = 0
    motor.perUnitQ = 0
    motor.voltageMagnitude = 1;
    motor.voltageAngle = 0
    motor.activePower = 0
    motor.reactivePower = 0
    motor.checkNG = false
    // motor.upTransKind = 0
    motor.pageIndex = 0
    motor.supply = false
    motor.tcName = ""
    motor.baseLoadInputManual = false
    motor.baseCapacityM = 0
    motor.leadBaseLoadM = false
    motor.baseFactorM = 0.85
    motor.baseLoadCalcRightLeft = false
    motor.primarySupply = false
    motor.inrush20Or40 = true
}

const doCalcSetValues = (motor:MsMotorModel,capacityMotorList:any) => {
    if(motor.capacity <= 0){
        motor.powerFactor = 0;
        motor.startingTimes = 0;
        motor.starting600 = 0;
        motor.inrushCurrent = 0;
        motor.efficiency = 0;
        motor.inrushTimes = 0;
        motor.startingTime = 0;
        return;
    }
    let powerFactor =0;
    let efficiency = 0;
    let startingTimes = 0;
    let starting600 = 0;
    let inrushTimes =0;
    let startTime = 50;
    for(const item of capacityMotorList){
        if(motor.capacity <= item.dcapacity || item.dcapacity == -1){
            if(item.dpowerFactor != null && item.dpowerFactor != undefined){
                powerFactor = item.dpowerFactor;
            }
            if(item.defficiency != null && item.defficiency != undefined){
                efficiency = item.defficiency;
            }
            if(item.dstartingCurrent != null && item.dstartingCurrent != undefined){
                startingTimes = item.dstartingCurrent;
            }
            if(item.dstartingTime != null && item.dstartingTime != undefined){
                starting600 = item.dstartingTime;
            }
            if(item.dinrushCurrent != null && item.dinrushCurrent != undefined){
                inrushTimes = item.dinrushCurrent;
            }
            // if(item.startTime != null && item.startTime != undefined){
            //     motor.startingTime = item.startTime;
            // }
            break;
        }
    }

    motor.powerFactor = powerFactor;
    motor.efficiency = efficiency;
    if(motor.manualLoadCurrent != true){
        motor.fullCurrent = doGetValueFullCurrent(motor);
    }
    motor.startingTimes = startingTimes;
    motor.starting600 = starting600;
    motor.startingTime = startTime;
    motor.inrushTimes = inrushTimes;
    return;
}

//CEleMotor::DoGetValueFullCurrent
const doGetValueFullCurrent = (motor:MsMotorModel) => {
    let fullCurrent;
    if(motor.voltageID <= 0){
        motor.fullCurrent =0;
    }
    else{
        let capacity = motor.capacity * 1000 * motor.loadFactor;
        if(motor.useEfficiency == true){
            if(motor.efficiency > 0){
                capacity /= motor.efficiency;
            }else{
                capacity = 0;
            }
        }
        if(motor.powerFactor == 0){
            fullCurrent = capacity / motor.voltageID;
            if(motor.sPhase != true){
                fullCurrent /= Math.sqrt(3.0);
            }
        }else{
            fullCurrent = capacity / motor.voltageID / motor.powerFactor;
            if(motor.sPhase != true){
                fullCurrent /= Math.sqrt(3.0);
            }
        }
    }
    return Number(fullCurrent);
}

//CEleMotor::DoGetTextKiloWattCapacity
export const doGetTextKiloWattCapacity = (props:MsMotorModel, bUnit:boolean = true) =>
{
	let sText = BeamsNumber(props.capacityID, 6).toString();
	if (bUnit ) {
		sText += 'kW';
	}
	return sText;
}

//CEleMotor::DoGetTextHorsePowerCapacity
export const doGetTextHorsePowerCapacity = (props:MsMotorModel, bUnit:boolean = true) =>
{
	let sText = BeamsNumber(props.capacityHpID, 6).toString();
	if (bUnit) {
		sText += "HP";
	}
	return sText;
}

//CEleMotor::DoGetTextLoadCurrent
export const doGetTextLoadCurrent = (props:MsMotorModel, bUnit:boolean = true) =>
{
	let dLoadCurrent = 0;
	if (props.manualLoadCurrent) {
		dLoadCurrent = props.loadCurrent;
	}
	else {
		dLoadCurrent = doGetValueFullCurrent(props);
	}
	let sText = BeamsNumber(dLoadCurrent, 4).toString();
	if (bUnit) {
		sText += 'A';
	}
	return sText;
}

//CEleMotor::DoGetTextEfficiency
export const doGetTextEfficiency = (props:MsMotorModel) =>
{
	return BeamsNumber(props.efficiency, 5).toString();
}

//CEleMotor::DoGetTextBaseCapacity
export const doGetTextBaseCapacity = (props:MsMotorModel, bUnit:boolean = true) =>
{
	let sText = BeamsNumber(props.baseCapacity, 5).toString();
	if (bUnit) {
		sText += 'kVA';
	}
	return sText;
}

//CEleMotor::DoGetTextPowerFactor
export const doGetTextPowerFactor = (props:MsMotorModel) =>
{
	return BeamsNumber(props.powerFactor, 5).toString();
}

//CEleMotor::DoGetTextStartingFactor
export const doGetTextStartingFactor = (props:MsMotorModel) =>
{
	return BeamsNumber(props.startingFactor, 5).toString();
}

//CEleMotor::DoGetTextImproveAfterPF
export const doGetTextImproveAfterPF = (props:MsMotorModel) =>
{
	return BeamsNumber(props.afterPF, 5).toString();
}

//CEleMotor::DoGetTextCapacitorCapacity
export const doGetTextCapacitorCapacity = (props:MsMotorModel, bUnit:boolean = true) =>
{
	let dCapacity = props.capacity * 1000 * props.loadFactor;
	if (props.useEfficiency) {		
		dCapacity /= props.efficiency;
	}

	let dtanBefore = Math.sqrt(1 - props.powerFactor * props.powerFactor) / props.powerFactor;
	let dtanAfter = 0;
	if (props.afterPF != 0) {
		dtanAfter = Math.sqrt(1 - props.afterPF * props.afterPF) / props.afterPF;
	}

	let dCapacitorCapacity = dCapacity * (dtanBefore - dtanAfter) / 1000;
	if (Math.abs(dCapacitorCapacity) < VALUE_POWER_MIN) {
		dCapacitorCapacity = 0;
	}
	let sText = BeamsNumber(dCapacitorCapacity, 5).toString();

	if (bUnit) {
		sText += 'kvar';
	}

	return sText;
}

//CEleMotor::DoGetTextVoltage
export const doGetTextVoltage = (props:MsMotorModel, nPartKind:number, bUnit:boolean = true) =>
{
	let sText = '';
	if (props.sPhase) {
		switch (nPartKind) {
		case MS_OUTSIDE_NEUTRAL:	
			sText = (props.voltageID / 2).toString();
			break;
		case MS_OUTSIDE_OUTSIDE:	
			sText = props.voltageID.toString();
			break;
		case MS_SEAT_MAIN:			
			sText = props.voltageAngle.toString();
			break;
		case MS_SEAT_T:				
			sText = props.voltageID.toString();
			break;
		}
	}
	else {
		sText = props.voltageID.toString();
	}
	if (bUnit) {
		sText += 'V';
	}
	return sText;
}


//TODO baseCurrent
//CEleMotor::DoGetTextBaseCurrentTotal
export const doGetTextBaseCurrentTotal = (props:MsMotorModel, bUnit:boolean = true) =>
{
	let dBaseCurrent = props.baseCurrent;

    //CHECK
    // for(let temp of props.harmonicList) {
    //     dBaseCurrent += temp.baseCurrent;
    // }

	let sText = BeamsNumber(dBaseCurrent, 5).toString();
	if (bUnit) {
		sText += 'pu';
	}
	return sText;
}

//CEleMotor::DoGetText6PulseCapacity
export const doGetText6PulseCapacity = (props:MsMotorModel, bUnit:boolean = true) =>
{
	let dTotalCapacity = 0;
	let dTotalEquivalent = 0;

    for(let temp of props.harmonicList) {
        dTotalCapacity += temp.capacity * temp.number;
        dTotalEquivalent += temp.capacity * temp.number * temp.coEfficient;
    }
	let sText = BeamsNumber(dTotalEquivalent, 4).toString();
	if (bUnit) {
		sText += 'kVA';
	}
	return sText;
}

//CEleMotor::DoGetRefV1
export const doGetRefV1 = (mode:any, control:ControlModel, bSociety:boolean) =>
{
    let props = control.properties as MsMotorModel;

	//CEleMotor::DoGetRefV1Left  sRefV1Left
	let labelTexts = [{
		value: control.properties.refNo,
		color: "#f800f8",
	}];

	if (!props.viewResultText || (mode != ProcessMode.DRAWING && !props.supply))
    {
      return labelTexts;
    }

    if(bSociety) {
        if(!props.uIntHP) {
            //KiloWattCapacity
            labelTexts.push({
                value: doGetTextKiloWattCapacity(props),
                color: "blue",
            });
        }
        else {
             //HorsePowerCapacity
            labelTexts.push({
                value: doGetTextHorsePowerCapacity(props),
                color: "blue",
            });
        }
        return labelTexts;
    }

    switch(mode) {
        case ProcessMode.DRAWING:
            if(!props.uIntHP) {
                //KiloWattCapacity
                labelTexts.push({
                    value: doGetTextKiloWattCapacity(props),
                    color: "blue",
                });
            }
            else {
                  //HorsePowerCapacity
                labelTexts.push({
                    value: doGetTextHorsePowerCapacity(props),
                    color: "blue",
                });
            }

            //LoadCurrent
            labelTexts.push({
                value: doGetTextLoadCurrent(props),
                color: "blue",
            });

            if(props.useEfficiency) {
                //効率=doGetTextEfficiency()
                labelTexts.push({
                    value: '効率='
                    + doGetTextEfficiency(props),
                    color: "blue",
                });
            }
            break;
        case ProcessMode.CHECKING:
            if(!props.uIntHP) {
                //KiloWattCapacity
                labelTexts.push({
                    value: doGetTextKiloWattCapacity(props),
                    color: "blue",
                });
            }
            else {
                  //HorsePowerCapacity
                labelTexts.push({
                    value: doGetTextHorsePowerCapacity(props),
                    color: "blue",
                });
            }

            //LoadCurrent
            labelTexts.push({
                value: doGetTextLoadCurrent(props),
                color: "blue",
            });

            if(props.useBaseLoad) {
                //基底負荷=doGetTextBaseCapacity()
                labelTexts.push({
                    value: '基底負荷='
                    + doGetTextBaseCapacity(props),
                    color: "blue",
                });
            }
            break;
        case ProcessMode.PERCENT_Z:
            break;
        case ProcessMode.CALCULATION:
            break;
        case ProcessMode.VOLTDROP:
            if(!props.uIntHP) {
                //KiloWattCapacity
                labelTexts.push({
                    value: doGetTextKiloWattCapacity(props),
                    color: "blue",
                });
            }
            else {
                  //HorsePowerCapacity
                labelTexts.push({
                    value: doGetTextHorsePowerCapacity(props),
                    color: "blue",
                });
            }

            //LoadCurrent
            labelTexts.push({
                value: doGetTextLoadCurrent(props),
                color: "blue",
            });

            //力率=doGetTextPowerFactor()
            labelTexts.push({
                value: '力率='
                + doGetTextPowerFactor(props),
                color: "blue",
            });

            if(props.useBaseLoad) {
                //基底負荷=doGetTextBaseCapacity()
                labelTexts.push({
                    value: '基底負荷='
                    + doGetTextBaseCapacity(props),
                    color: "blue",
                });
            }

            if(props.calcDrop) {
                //始動力率=doGetTextStartingFactor()
                labelTexts.push({
                    value: '始動力率='
                    + doGetTextStartingFactor(props),
                    color: "blue",
                });
            }
            break;
        case ProcessMode.IMPROVE_PF:
            if(!props.uIntHP) {
                //KiloWattCapacity
                labelTexts.push({
                    value: doGetTextKiloWattCapacity(props),
                    color: "blue",
                });
            }
            else {
                  //HorsePowerCapacity
                labelTexts.push({
                    value: doGetTextHorsePowerCapacity(props),
                    color: "blue",
                });
            }

            if(props.calcImprovePF) {
                //負荷力率=doGetTextPowerFactor()
                labelTexts.push({
                    value: '負荷力率='
                    + doGetTextPowerFactor(props),
                    color: "blue",
                });

                //目標力率=doGetTextImproveAfterPF()
                labelTexts.push({
                    value: '目標力率='
                    + doGetTextImproveAfterPF(props),
                    color: "blue",
                });

                 //Qc=doGetTextCapacitorCapacity()
                 labelTexts.push({
                    value: 'Qc='
                    + doGetTextCapacitorCapacity(props),
                    color: "blue",
                });
            }
            else {
                 //力率=doGetTextPowerFactor()
                 labelTexts.push({
                    value: '力率='
                    + doGetTextPowerFactor(props),
                    color: "blue",
                });
            }
            break;
        case ProcessMode.HARMONIC:
            //CHINESE 
            //  //6ﾊﾟﾙｽ等価容量
            //  labelTexts.push({
            //     value: '6ﾊﾟﾙｽ等価容量',
            //     color: "blue",
            // });

            // //doGetTextBaseCurrentTotal
            // labelTexts.push({
            //     value: doGetTextBaseCurrentTotal(props),
            //     color: "blue",
            // });

            //6ﾊﾟﾙｽ等価容量
            labelTexts.push({
                value: '6ﾊﾟﾙｽ等価容量',
                color: "blue",
            });

             //doGetText6PulseCapacity
             labelTexts.push({
                value: doGetText6PulseCapacity(props),
                color: "blue",
            });
            break;
        case ProcessMode.POWER_FLOW:
            break;
        default:
            break;
    }

    return labelTexts;
}

export const doGetRefV2_0 = (props: MsMotorModel, dNominalVolt: number) => {
    let infos = []
    const refNo = props.refNo;
    const uIntHP = props.uIntHP;
    const m_dKiloWattCapacity = props.capacityID;
    const m_dHorsePowerCapacity = props.capacityHpID;
    const m_lVoltage = props.voltageID;
    const m_dStartingTimes = props.startingTimes;
    const m_dStarting600 = props.starting600;
    const m_dInrushTimes = props.inrushTimes
    infos.push(refNo);
    if(uIntHP == false){//not check HP
        infos.push(m_dKiloWattCapacity.toString()+`kW`);
    } else {
        infos.push(m_dHorsePowerCapacity.toString()+`HP`);
    }
    const dloadCurrent = doGetTextLoadCurrent(props);

    if(m_lVoltage === dNominalVolt){
        infos.push(dloadCurrent+`A`)
    } else {
        const dLoadCurrent = doGetValueLoadCurrent(props);
        infos.push(Math.round(dLoadCurrent *m_lVoltage /dNominalVolt) +`A` +`(` +dloadCurrent+`)`);
    }

    infos.push(m_dStartingTimes +`倍,`+m_dStarting600 +`s`);
    infos.push(m_dInrushTimes+`倍`);

    return infos
}

export const doGetRefV2_1 = (props: MsMotorModel, dNominalVolt: number) => {
    let infos = []
    const refNo = props.refNo;
    const uIntHP = props.uIntHP;
    const m_dKiloWattCapacity = props.capacityID;
    const m_dHorsePowerCapacity = props.capacityHpID;
    const m_dBaseCapacity = props.baseCapacity;
    const m_lVoltage = props.voltageID;
    const m_dStartingTimes = props.startingTimes;
    const m_dStarting600 = props.starting600;
    const m_dInrushTimes = props.inrushTimes;
    infos.push(refNo)
    if(uIntHP == false){
        infos.push(m_dKiloWattCapacity +`kW` +`+` +m_dBaseCapacity +`kVA`)
    }
    else{
        infos.push(m_dHorsePowerCapacity.toString()+`HP`+`+` +m_dBaseCapacity +`kVA`);
    }
    const ampere = doGetTextTotalCurrent(props, m_lVoltage, m_dBaseCapacity);
    if(m_lVoltage === dNominalVolt){
        
        infos.push(ampere + `A`)
    }
    else{
        const dLoadCurrent = doGetValueLoadCurrent(props);
        const dBaseCurrent = doGetValueBaseLoadCurrent(m_lVoltage,m_dBaseCapacity);
        const result = Math.round((dLoadCurrent + dBaseCurrent) * m_lVoltage/ dNominalVolt);
        infos.push(result +`A` +`(`+ampere +`)`)
    }
    infos.push(m_dStartingTimes +`倍,`+m_dStarting600 +`s`)
    infos.push(m_dInrushTimes+`倍`)

    return infos
}

const doGetValueLoadCurrent=(props: MsMotorModel) =>{
    let m_bManualLoadCurrent: boolean = props.manualLoadCurrent;
    let dLoadCurrent = 0;
    if(m_bManualLoadCurrent) {
        dLoadCurrent = props.loadCurrent;
    } else {
        dLoadCurrent = doGetValueFullCurrent(props);
    }
    return dLoadCurrent;
}

const doGetTextTotalCurrent =(props: MsMotorModel, m_lVoltage: number, m_dBaseCapacity: number) =>{
   const dLoadCurrent = doGetValueLoadCurrent(props);
   const dBaseCurrent = doGetValueBaseLoadCurrent(m_lVoltage, m_dBaseCapacity);
   return dLoadCurrent + dBaseCurrent;//Apere
}

const doGetValueBaseLoadCurrent = (m_lVoltage: number, m_dBaseCapacity: number) =>{
   let dBaseLoad = 0;
   if(m_lVoltage > 0){
       dBaseLoad = m_dBaseCapacity * 1000.0 / m_lVoltage / Math.sqrt(3.0);
   }
   return dBaseLoad;
}

//CEleMotor::DoGetTextFlowsResult
export const doGetTextFlowsResult = (props:MsMotorModel, processMode: any, nDirect:number, bSociety:boolean = false) =>
{
	let sRefV1:string[] = [];

	if (bSociety) {
		return sRefV1;
	}

	switch (processMode) {
	case MS_MODE_DRAWING:
	case MS_MODE_CHECKING:
	case MS_MODE_PERCENT_Z:
	case MS_MODE_CALCULATION:
	case MS_MODE_VOLTDROP:
	case MS_MODE_HARMONIC:
		break;
	case MS_MODE_POWER_FLOW:
		if (props.activePower !== 0 && props.reactivePower !== 0) {
			if (nDirect == DIRECT_NONE) {
				sRefV1.push(BeamsNumber(Math.abs(props.activePower),5).toString() + 'kW');
				sRefV1.push(BeamsNumber(Math.abs(props.reactivePower),5).toString() + 'kvar');
			}
			else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
				sRefV1.push(BeamsNumber(Math.abs(props.activePower),5).toString() + 'kW');
                let sTemp = ''
				if (props.reactivePower > 0) {
					sTemp += "+j";
				}
				else {
					sTemp += "-j";
				}
				sRefV1.push(sTemp + BeamsNumber(Math.abs(props.reactivePower),5).toString() + 'kvar');
			}
		}
		break;
	}

	return sRefV1;
}