import { RS_CURVE_CENTER, RS_CURVE_MAX, RS_CURVE_MIN, RS_KIND_FUSE } from "../../models/Constants"
import { AdjustElements, CdPoint, ControlModel, GraphLineData, OneCurve, PartDetailsModel } from "../../models/Index"
import { doCalcSetAllOneCurve, doCalcSetErrorCurves, doMakeOneCurve, doReadAllCurveInfos } from "./infoRead"
import {store} from "../../"
import axios from "axios";
import { addListToData, convertDataFromBE } from "../../utils/DataConverter";
import { updatePropertiesOfControlAction } from "../../store/Actions";
import { Auth } from "aws-amplify";
import { SessionExpiredAction } from "../../store/AppStore";
import { redirectMaintenancePage } from "../CallApi";
/**
 * Source
 * HVCB
 * LVCB
 * FUSE
 * 2E
 * THERMAL
 * THERMAL CT
 */
const doReadDataFromDB = async (props : AdjustElements, element : ControlModel, isDrawCalcPoint: boolean = false) => {

    // TODO: handle when error occurs
    let token = ""
    try {
        const session = await Auth.currentSession()
        token = session.getIdToken().getJwtToken()
    } catch (error) {
        store.dispatch({
            type: "SESSION_EXPIRED",
        } as SessionExpiredAction);
    }

    try {
        // check if control had list detail        
        if (!props.isReadLists) {
            const userId = store.getState().app.user?.userId;
            const params = {
                userId: userId,
                projectId: store.getState().app.projectData.projectId,
                elementId: element.id,
                elementType: element.type,
                data: props,
                ownerProject: store.getState().app.projectData.createUserId
            };

            const data = await axios.post(`${process.env.REACT_APP_APIGW_URL}/diagram/get-param`, params, {headers: {'Authorization' : token}})
            if (data) {
                const newData = data.data.data
                
                props = addListToData(props, newData)
            
                let convertData = convertDataFromBE(props)
                props.voltageList = convertData.voltageList
                props.details.base = {...props.details.base, ...convertData.details.base}
                props.details.base.ctPrimaryList = convertData.details.base.ctPrimaryList
                props.details.parts = convertData.details.parts
                props.details.delaySettings = convertData.details.delaySettings

                //props.isReadLists = true
                store.dispatch(updatePropertiesOfControlAction(element.id, {...props, isDrawCalcPoint : isDrawCalcPoint}))
            }
        }
    } catch (err: any) {
        if (err.response && err.response.status === 503){
            redirectMaintenancePage(err.response);
        }
        console.error(err)
    }
    
    const {
        details : {
            parts : m_listInfoPart,
            base : {
                readBase : {
                    kindFlag
                }
            },
            drawThreeCurve
        }
    } = props 

    return doReadAllCurveInfos(m_listInfoPart, kindFlag === RS_KIND_FUSE, drawThreeCurve)
}

interface AdjustObj {
    m_listCalcCenter: OneCurve[],
    m_listCalcMax: OneCurve[],
    m_listCalcMin: OneCurve[],
    m_listPointCenter: CdPoint[],
    m_listPointMax: CdPoint[],
    m_listPointMin: CdPoint[],
}

const doDrawCurveAdjustAll = (props : AdjustElements, listInfosFromDB : any, nFrequency : number, bDispBand : boolean = false) => {
    const pAdjustObj = {
        m_listCalcCenter: [] as OneCurve[],
        m_listCalcMax: [] as OneCurve[],
        m_listCalcMin: [] as OneCurve[],
        m_listPointCenter: [] as CdPoint[],
        m_listPointMax: [] as CdPoint[],
        m_listPointMin: [] as CdPoint[],
    } as AdjustObj

    doCalcSetEachCurve(pAdjustObj, props, listInfosFromDB, nFrequency)

    doConvertCurveTotal(pAdjustObj)

    return doDrawCurveAdjust(pAdjustObj, props, listInfosFromDB, bDispBand)
}

const doDrawCurveAdjust = (pAdjustObj : any, props : AdjustElements, listInfosFromDB : any, bDispBand = false) => {
    const graphLineData : GraphLineData = { lines: [], raw: listInfosFromDB }
    if (props.details.base.readBase.kindFlag === RS_KIND_FUSE && props.details.drawThreeCurve === true) {
        graphLineData.lines[0] = {
            points: pAdjustObj.m_listPointCenter.map((point : CdPoint) => ({x: point.m_dx, y: point.m_dy})),
            id: 'POINT_CENTER'
        } 
        graphLineData.lines[1] = {
            points: pAdjustObj.m_listPointMax.map((point : CdPoint) => ({x: point.m_dx, y: point.m_dy})),
            id: 'POINT_MAX'
        } 
        graphLineData.lines[2] = {
            points: pAdjustObj.m_listPointMin.map((point : CdPoint) => ({x: point.m_dx, y: point.m_dy})),
            id: 'POINT_MIN'
        } 
    } else {
        if (bDispBand) {
            if (pAdjustObj.m_listCalcMax.length > 0 || pAdjustObj.m_listCalcMin.length > 0) {
                graphLineData.lines[0] = {
                    points: pAdjustObj.m_listPointMax.map((point : CdPoint) => ({x: point.m_dx, y: point.m_dy})),
                    id: 'POINT_MAX'
                } 
                graphLineData.lines[1] = {
                    points: pAdjustObj.m_listPointMin.map((point : CdPoint) => ({x: point.m_dx, y: point.m_dy})),
                    id: 'POINT_MIN'
                } 
            } else {
                graphLineData.lines[0] = {
                    points: pAdjustObj.m_listPointCenter.map((point : CdPoint) => ({x: point.m_dx, y: point.m_dy})),
                    id: 'POINT_CENTER'
                } 
            }
        } else {
            if (pAdjustObj.m_listCalcCenter.length > 0) {
                graphLineData.lines[0] = {
                    points: pAdjustObj.m_listPointCenter.map((point : CdPoint) => ({x: point.m_dx, y: point.m_dy})),
                    id: 'POINT_CENTER'
                } 
            } else {
                graphLineData.lines[0] = {
                    points: pAdjustObj.m_listPointMax.map((point : CdPoint) => ({x: point.m_dx, y: point.m_dy})),
                    id: 'POINT_MAX'
                } 
                graphLineData.lines[1] = {
                    points: pAdjustObj.m_listPointMin.map((point : CdPoint) => ({x: point.m_dx, y: point.m_dy})),
                    id: 'POINT_MIN'
                } 
            }
        }
    }

    return graphLineData
}

const doCalcSetEachCurve = (pAdjustObj : any, props : AdjustElements, listInfosFromDB : any, nFrequency : number) => {

    let dDelayTime = 0;
    if (props.details.delaySettings && props.details.delaySettings.breakingTime && nFrequency > 0) {
        dDelayTime = props.details.delaySettings.breakingTimeHzID / nFrequency
    }

    // loop over array of detail parts, calc listCalcCenter + listCalcMin + listCalcMax
    // START LOOP
    props.details.parts.forEach((part : PartDetailsModel, index) => {
        //if (index == 0) part.currentValue = props.details.base.ratedValue //TODO  delete this line when fixed HVCB change ratedValue finished
        if (props.details.base.readBase.kindFlag === RS_KIND_FUSE && props.details.drawThreeCurve === true) {
            const partCurve = listInfosFromDB[index]
            doCalcSetAllOneCurve(RS_CURVE_CENTER, props, partCurve[0], part, dDelayTime, pAdjustObj.m_listCalcCenter)
            doCalcSetAllOneCurve(RS_CURVE_CENTER, props, partCurve[1], part, dDelayTime, pAdjustObj.m_listCalcMax)
            doCalcSetAllOneCurve(RS_CURVE_CENTER, props, partCurve[2], part, dDelayTime, pAdjustObj.m_listCalcMin)
        } else {
            const readCurves = listInfosFromDB[index]
            doCalcSetAllOneCurve(RS_CURVE_CENTER, props, readCurves, part, dDelayTime, pAdjustObj.m_listCalcCenter)
            if (!doCalcSetAllOneCurve(RS_CURVE_MAX, props, readCurves, part, dDelayTime, pAdjustObj.m_listCalcMax)) {
                doCalcSetErrorCurves(RS_CURVE_MAX, props, readCurves, part, dDelayTime, pAdjustObj.m_listCalcMax)
            }
            if (!doCalcSetAllOneCurve(RS_CURVE_MIN, props, readCurves, part, dDelayTime, pAdjustObj.m_listCalcMin)) {
                doCalcSetErrorCurves(RS_CURVE_MIN, props, readCurves, part, dDelayTime, pAdjustObj.m_listCalcMin)
            }
            
        }
    })
    // END LOOP
}

const handleConvertNominal = (plistdPoint: any[]) => {
    if (plistdPoint.length > 0){
      plistdPoint.forEach((pdPoint: any) => {
        handleConvertNominalSub(pdPoint);
      })
    }
    return plistdPoint;
  }
  
const handleConvertNominalSub = (dPoint: any) => {
    dPoint.m_dx = handleConvertNominalX(dPoint.m_dx);
    dPoint.m_dy = handleConvertNominalY(dPoint.m_dy);
    return dPoint;
  }
  
const handleConvertNominalX = (dPointX: number) => {
    dPointX = Math.log10(dPointX / 10)
    return dPointX;
  }
  
const handleConvertNominalY = (dPointY: number) => {
    dPointY = Math.log10(dPointY / 0.01)
    return dPointY;
  }

const handleRevertNominal = (plistdPoint: any[]) => {
    if (plistdPoint.length > 0){
      plistdPoint.forEach((pdPoint: any) => {
        handleRevertNominalSub(pdPoint);
      })
    }
    return plistdPoint;
  }
  
const handleRevertNominalSub = (dPoint: any) => {
    dPoint.m_dx = handleRevertNominalX(dPoint.m_dx);
    dPoint.m_dy = handleRevertNominalY(dPoint.m_dy);
    return dPoint;
  }
  
const handleRevertNominalX = (dPointX: number) => {
    dPointX = (10 ** dPointX) * 10
    return dPointX;
  }
  
const handleRevertNominalY = (dPointY: number) => {
    dPointY = (10 ** dPointY) * 0.01
    return dPointY;
  }

const doConvertCurveTotal = (pAdjustObj : AdjustObj) => {    
    // convert list center
    doCalcSetNominal(pAdjustObj.m_listCalcCenter)
    doMakeOneCurve(pAdjustObj.m_listPointCenter, pAdjustObj.m_listCalcCenter)
    // convert list max
    doCalcSetNominal(pAdjustObj.m_listCalcMax)
    doMakeOneCurve(pAdjustObj.m_listPointMax, pAdjustObj.m_listCalcMax)
    // convert list min
    doCalcSetNominal(pAdjustObj.m_listCalcMin)
    doMakeOneCurve(pAdjustObj.m_listPointMin, pAdjustObj.m_listCalcMin)
}

// 要素ごとの曲線を公称値換算して、飽和時間以下の座標を削除する
const doCalcSetNominal = (plistOneCurve : OneCurve[]) => {
    plistOneCurve.forEach((pCurveNow : OneCurve) => {
        if (pCurveNow.m_dSaturationTime > 0)
        {
            // pCurveNow.m_dSaturationTime = doConvertNominalY(pCurveNow.m_dSaturationTime);
            // 飽和時間以下の曲線をカットする
            doCutSaturation(pCurveNow.m_listdPoint, pCurveNow.m_dSaturationTime);
        }
    })
}

// 飽和時間以下の曲線をカットする（論理換算された曲線で使用すること）
const doCutSaturation = (plistdPoint : CdPoint[], dSaturationTime : number) =>
{
    let ppointEnd : CdPoint | undefined
    let ppointPrev : CdPoint | undefined
    let dEndX = 0;
    
    for( let i = plistdPoint.length - 1; i >= 0; i--)
    {
        const pdPoint : CdPoint = plistdPoint[i];

        if( ppointPrev === undefined)
        {
            ppointPrev = pdPoint;
            dEndX = pdPoint.m_dx;
            continue;
        }
        ppointEnd = ppointPrev;
        ppointPrev = pdPoint;
        if (ppointEnd.m_dy < dSaturationTime && ppointPrev.m_dy < dSaturationTime)
        {
            const index = plistdPoint.findIndex(x => (x.m_dx === ppointEnd?.m_dx ) && (x.m_dy == ppointEnd.m_dy));     // 飽和時間より小さい座標を削除する
            plistdPoint.splice(index, 1);
            continue;
        }                   // 計算誤差を補正するための足し算
        if (ppointEnd.m_dy >= dSaturationTime || ppointPrev.m_dy < dSaturationTime)
        {
            continue;
        }
        const dRatioX = (Math.log10(ppointEnd.m_dx / 10) - Math.log10(ppointPrev.m_dx / 10)) / (Math.log10(ppointPrev.m_dy / 0.01) - Math.log10(ppointEnd.m_dy / 0.01));
        let dStdXUp = Math.log10(ppointPrev.m_dx / 10) + dRatioX * (Math.log10(ppointPrev.m_dy / 0.01) - Math.log10(dSaturationTime) / 0.01)
        dStdXUp = (10 ** dStdXUp) * 10
        ppointEnd.m_dx = dStdXUp;
        ppointEnd.m_dy = dSaturationTime;

        plistdPoint.push({
            m_dx: dEndX,
            m_dy: dSaturationTime
        });
        // TODO:
        // double dx = pObjGraph.DoConvertNormalX(dEndX);
        // pdPoint.m_dx = pObjGraph.DoConvertNominalX(dx);
        // pdPoint.m_dy = dSaturationTime;
        break;
    }
}

export {
    doReadDataFromDB,
    doDrawCurveAdjustAll,
}