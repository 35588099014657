export function getTextWidth(text: string, fontName: string, fontSize: number): number {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    if (context) {
        context.font = `${fontSize}px ${fontName}`;
        const textList = text.split("\n");
        let maxWidth = 0;
        textList.forEach(e => {
            const metrics = context.measureText(e);
            if (metrics.width > maxWidth) maxWidth = metrics.width;
        })
        return maxWidth;
    }

    return 0;
}