import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Stack } from '@mui/material';

export type ConfirmDialogProps = {
  messageLine1?: string;
  messageLine2: string;
  messageLine3: string;
  isOpen: boolean;
  onNo?: ()=>void;
  onOK: () => void;
  onCancel?: () => void;
};

export const ConfirmDialog = (props: ConfirmDialogProps) => {
  const { messageLine1, messageLine2, messageLine3, isOpen, onOK, onNo, onCancel } = props;
  const [open, setOpen] = React.useState(isOpen);

  const handleOK = () => {
    console.log(">>> modal handleOK")
    setOpen(false);
    onOK();
  };

  const handleNo = () => {
    console.log(">>> modal handleNo")
    setOpen(false);
    onNo && onNo();
  };

  const handleCancel = () => {
    console.log(">>> modal handleCancel")
    setOpen(false);
    {onCancel && onCancel()};
  };

  return (
    <div>
      <Dialog
        open={open}
        aria-describedby="alert-dialog-description"
        onKeyDown={(e) => { e.preventDefault(); e.key === "Enter" && handleOK()}}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {messageLine1 && 
                <Stack mb={2} textAlign={"center"}>
                {messageLine1}
                </Stack >
            }
            <Stack textAlign={"center"}>
            {messageLine2}
            </Stack>
            <Stack textAlign={"center"}>
            {messageLine3}
            </Stack>
          </DialogContentText>
        </DialogContent>
       
        <DialogActions style={{alignSelf:"center"}}>
            <Button onClick={handleOK} variant="contained" style={{width:"80px", marginInline:"10px"}}>はい</Button>
            {onNo && <Button onClick={handleNo} variant="contained" style={{width:"80px", marginInline:"10px"}}>いいえ</Button>}
            {onCancel && <Button onClick={handleCancel} color="inherit" variant="contained" >キャンセル</Button>}
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ConfirmDialog;