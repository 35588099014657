import { CircularProgress, Dialog, DialogContent, DialogTitle } from "@mui/material";
import Konva from "konva";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { Group, Image, Label, Rect, Text, Transformer } from "react-konva";
import { Html, useImage } from "react-konva-utils";
import { connect } from "react-redux";
import leftArrow from "../../images/control/blue_arrow.svg";
import rightArrow from "../../images/control/red_arrow.svg";
import lineSvg from "../../images/control/two_legs_line.svg";
import lineGreenSvg from "../../images/control/two_legs_line_green.svg";
import lineRedSvg from "../../images/control/two_legs_line_red.svg";
import percentZSvg from "../../images/control/三巻線変圧器＿PZ.svg";
import percentZGreenSvg from "../../images/control/三巻線変圧器＿PZ_green.svg";
import percentZRedSvg from "../../images/control/三巻線変圧器＿PZ_red.svg";
import image3WindingTransformer6Svg from "../../images/control/三巻線変圧器＿△／△／△.svg";
import image3WindingTransformer6GreenSvg from "../../images/control/三巻線変圧器＿△／△／△_green.svg";
import image3WindingTransformer6RedSvg from "../../images/control/三巻線変圧器＿△／△／△_red.svg";
import { default as image3WindingTransformer5Svg, default as image3WindingTransformer7Svg } from "../../images/control/三巻線変圧器＿△／△／Ｙ＿△／△／Ｙｏ.svg";
import { default as image3WindingTransformer5GreenSvg, default as image3WindingTransformer7GreenSvg } from "../../images/control/三巻線変圧器＿△／△／Ｙ＿△／△／Ｙｏ_green.svg";
import { default as image3WindingTransformer5RedSvg, default as image3WindingTransformer7RedSvg } from "../../images/control/三巻線変圧器＿△／△／Ｙ＿△／△／Ｙｏ_red.svg";
import image3WindingTransformer0Svg from "../../images/control/三巻線変圧器＿無し.svg";
import image3WindingTransformer0GreenSvg from "../../images/control/三巻線変圧器＿無し_green.svg";
import image3WindingTransformer0RedSvg from "../../images/control/三巻線変圧器＿無し_red.svg";
import image3WindingTransformer3Svg from "../../images/control/三巻線変圧器＿Ｙ／△／△.svg";
import image3WindingTransformer3GreenSvg from "../../images/control/三巻線変圧器＿Ｙ／△／△_green.svg";
import image3WindingTransformer3RedSvg from "../../images/control/三巻線変圧器＿Ｙ／△／△_red.svg";
import { default as image3WindingTransformer2Svg, default as image3WindingTransformer4Svg } from "../../images/control/三巻線変圧器＿Ｙ／△／Ｙ＿Ｙ／△／Ｙｏ.svg";
import { default as image3WindingTransformer2GreenSvg, default as image3WindingTransformer4GreenSvg } from "../../images/control/三巻線変圧器＿Ｙ／△／Ｙ＿Ｙ／△／Ｙｏ_green.svg";
import { default as image3WindingTransformer2RedSvg, default as image3WindingTransformer4RedSvg } from "../../images/control/三巻線変圧器＿Ｙ／△／Ｙ＿Ｙ／△／Ｙｏ_red.svg";
import image3WindingTransformer1Svg from "../../images/control/三巻線変圧器＿Ｙ／Ｙ／△.svg";
import image3WindingTransformer1GreenSvg from "../../images/control/三巻線変圧器＿Ｙ／Ｙ／△_green.svg";
import image3WindingTransformer1RedSvg from "../../images/control/三巻線変圧器＿Ｙ／Ｙ／△_red.svg";
import * as Constant from "../../models/Constants";
import * as Model from "../../models/Index";
import { Coordinates, DiagramModel, LineType, Ms3WindingModel, OptionModel } from "../../models/Index";
import { MAKE_YEAR_MAX, MAKE_YEAR_MIN, MS_MODE_CALCULATION, MS_MODE_HARMONIC, MS_MODE_IMPROVE_PF, MS_MODE_POWER_FLOW, MS_MODE_VOLTDROP } from "../../statics";
import { actionCreators } from "../../store";
import {
  addUserCurve,
  clearOptionMenu,
  deleteRelatedGraphByUserCurveDialog,
  deleteUserCurve,
  openChart,
  openDialogEvents,
  openUserCurveEvents,
  openUserCurvePropertiesEvents,
  saveAllControlsToDiagram,
  setRepositionElement,
  setTransformFlag,
  undo,
  updateControlHeight,
  updateElementPosition,
  updatePropertiesOfControlAction,
  updatePropertiesOfTransCenter,
  updateSelectControl,
  updateUserCurve,
} from "../../store/Actions";
import theme from "../../themes/globalStyles";
import { doGetRefV11, doGetRefV13 } from "../../utils/3windingFunction";
import { getElementKindValue } from "../../utils/ElementFunction";
import { doGetValuesTransCenter } from "../../utils/TransCenterFunction";
import { doGetTransCenterSourceVolt } from "../../utils/mssv3Doc";
import DraggablePaper from "../common/DraggablePaper";
import OptionMenu from "../contextMenu/OptionMenu";
import Ms3WindingEditDialog from "../dialogs/Ms3WindingEditDialog";
import UserCurveDetailDialog from "../dialogs/UserCurveDetailDialog";
import UserCurvesDialog from "../dialogs/UserCurvesDialog";
import { getSymbolAndBackColor } from "../../utils/drawElement";
import { getElements, post } from "../CallApi";
import { calcContextMenuPosition, calcDragBoundPosition, calcElementSize, getMenuOptionHeight } from "../../utils/PositionCalculation";
import { doDrawSymbolGroup } from "../graph/DCView";
import { MS_3WINDING } from "../../models/ElementKind";
import { BeamsNumber } from "../../utils/FormatNumber";

//#region Props
type CreateMs3WindingProps = {
  id: string;
  image: string;
  x: number;
  y: number;
  offsetX: number;
  offsetY: number;
  width: number;
  height: number;
  type: string;
  isSelected: boolean;
  isHidden: boolean;
  rotation: number;
  lineDiagramRef: any;
  parentGroupId: number | null;
  properties: Ms3WindingModel;
  chart: Model.LineInfoModel[];
  gridSizeController: {
    width: number;
    height: number;
    scale: number;
    stageOffsetX: number;
    stageOffsetY: number;
  };
  isOnShiftKey: boolean;
};

export type PureCreateMs3WindingProps = CreateMs3WindingProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;
//#endregion

const CONTROL_POS_FACTOR = 1;

const CreateMs3Winding = memo((props: PureCreateMs3WindingProps) => {
  //#region Fields
  const {
    id,
    image,
    x,
    y,
    offsetX,
    offsetY,
    width,
    height,
    type,
    isSelected,
    isHidden,
    rotation,
    lineDiagramRef,
    parentGroupId,
    processMode,
    properties,
    chart
  } = props;

  const {
    // getElementParam,
    // updateElementPost,
    // addUserCurveDB,
    // updateUserCurveDB,
    // deleteUserCurveDB,
    diagramDataUpdate,
    openDialogData,
    openUserCurveData,
    userCurveProperties,
    projectId,
    diagramDataList,
    diagramData,
    mainDiagramData,
    gridSizeController,
    currentTabId,
    infoCircuit,
    userRole,
    m_bModePM,
    modeViewOnly,
    infoSkeleton,
    userId,
    drawPowerArrow,
    projectData,
    clearMenuState,
    isSelectTouchGroup,
    isOnShiftKey,
    elementGroups,
    tcGroups,
    elementReposition,
    updateElementPosition,
    setRepositionElement,
    updateSelectControl,
    updateControlData,
    updateControlHeight,
    setTransformFlag,
    openDialogEvents,
    addUserCurve,
    updateUserCurve,
    deleteUserCurve,
    setOpenChart,
    updateTransCenterProps,
    saveAllControlsToDiagram,
    mappedDataFromBE,
    openUserCurvePropertiesEvent,
    deleteRelatedGraphByUserCurveDialog,
    openUserCurveEvent,
    setUndoData,
    clearOptionMenu,
  } = props;

  const [imageStatus, setImageStatus] = useState(image);
  const [lineStatus,setLineStatus] = useState(lineSvg);
  const [percentZStatus,setPercentZStatus] = useState(percentZSvg);
  const [showPowerInfo, setShowPowerInfo] = useState(false)
  const [showPowerDirectInfo, setShowPowerDirectInfo] = useState(false)
  const [activePower, setActivePower] = useState("?????kW")
  const [reactivePower, setReactivePower] = useState("?????kvar");
  const [svgHeight, setSvgHeight] = useState('')
  const [svgWidth, setSvgWidth] = useState('')
  const [svgLine, setSvgLine] = useState('')
  const [svgArrowActive, setSvgArrowActive] = useState('')
  const [svgArrowReactive, setSvgArrowReactive] = useState('')
  const [svgTextX, setSvgTextX] = useState('')
  const [svgTextY, setSvgTextY] = useState('')
  const [svgTextRotation, setSvgTextRotation] = useState('')
  const [svgTextX1, setSvgTextX1] = useState('')
  const [svgTextY1, setSvgTextY1] = useState('')
  const [svgPosX1, setSvgPosX1] = useState(0)
  const [svgPosX2, setSvgPosX2] = useState(0)
  const [svgPosX3, setSvgPosX3] = useState(0)
  const [svgPosY1, setSvgPosY1] = useState(0)
  const [svgPosY2, setSvgPosY2] = useState(0)
  const [svgPosY3, setSvgPosY3] = useState(0)
  const [activePowerSecondaryPart,setActivePowerSecondaryPart] = useState("?????kW");
  const [reactivePowerSecondaryPart,setReactivePowerSecondaryPart] = useState("?????kvar");
  const [activePowerThirdPart,setActivePowerThirdPart] = useState("?????kW");
  const [reactivePowerThirdPart,setReactivePowerThirdPart] = useState("?????kvar");
  const [isHiddenPrimaryPart,setIsHiddenPrimaryPart] = useState(false);
  const [isHiddenSecondaryPart,setIsHiddenSecondaryPart] = useState(false);
  const [isHiddenThirdPart,setIsHiddenThirdPart] = useState(false);

  var imageObj = new window.Image();
  imageObj.src = imageStatus;
  
  var lineObj = new window.Image();
  lineObj.src = lineStatus;

  var leftArrowObj = new window.Image();
  leftArrowObj.src = leftArrow;

  var rightArrowObj = new window.Image();
  rightArrowObj.src = rightArrow;

  var percentZObj = new window.Image();
  percentZObj.src = percentZStatus;

  const svgToURL = (s:any) =>{
    const uri = window.btoa(unescape(encodeURIComponent(s)));
    return "data:image/svg+xml;base64," + uri;
  }

    const svgActivePower =
    `<svg height="${svgHeight}" width="${svgWidth}"  xmlns="http://www.w3.org/2000/svg">` +
    `<path  d="${svgLine}" stroke="${isSelected? 'red' : 'blue'}"  stroke-width="1" fill="none"/>` +
    `<path  d="${svgArrowActive}" stroke="${isSelected? 'red' : 'blue'}" stroke-width="1"  fill="none"/>` +
    `<text x="${svgTextX}" y="${svgTextY}" text-anchor="middle" font-size="10" fill="${isSelected? 'red' : 'blue'}" transform="translate(15,15) rotate(${svgTextRotation})">${activePower}</text>` +
    '</svg>' ;

  const urlActivePower = svgToURL(svgActivePower);
  const [imageSVGActivePower] = useImage(urlActivePower);

  const svgActivePowerSecondaryPart = 
  `<svg height="${svgHeight}" width="${svgWidth}"  xmlns="http://www.w3.org/2000/svg">` +
  `<path  d="${svgLine}" stroke="${isSelected? 'red' : 'blue'}"  stroke-width="1" fill="none"/>` +
  `<path  d="${svgArrowActive}" stroke="${isSelected? 'red' : 'blue'}" stroke-width="1"  fill="none"/>` +
  `<text x="${svgTextX}" y="${svgTextY}" text-anchor="middle" font-size="10" fill="${isSelected? 'red' : 'blue'}" transform="translate(15,15) rotate(${svgTextRotation})">${activePowerSecondaryPart}</text>` +
  '</svg>' ;

  const urlActivePowerSecondaryPart = svgToURL(svgActivePowerSecondaryPart);
  const [imageSVGActivePowerSecondaryPart] = useImage(urlActivePowerSecondaryPart);

  const svgActivePowerThirdPart = 
  `<svg height="${svgHeight}" width="${svgWidth}"  xmlns="http://www.w3.org/2000/svg">` +
  `<path  d="${svgLine}" stroke="${isSelected? 'red' : 'blue'}"  stroke-width="1" fill="none"/>` +
  `<path  d="${svgArrowActive}" stroke="${isSelected? 'red' : 'blue'}" stroke-width="1"  fill="none"/>` +
  `<text x="${svgTextX}" y="${svgTextY}" text-anchor="middle" font-size="10" fill="${isSelected? 'red' : 'blue'}" transform="translate(15,15) rotate(${svgTextRotation})">${activePowerThirdPart}</text>` +
  '</svg>';
  const urlActivePowerThirdPart = svgToURL(svgActivePowerThirdPart);
  const [imageSVGActivePowerThirdPart] = useImage(urlActivePowerThirdPart);

  const svgReactivePower =
  `<svg height="${svgHeight}" width="${svgWidth}"  xmlns="http://www.w3.org/2000/svg">` +
  `<path  d="${svgLine}" stroke="${isSelected? 'red' : 'blue'}"  stroke-width="1" fill="none"/>` +
  `<path  d="${svgArrowReactive}" stroke="${isSelected? 'red' : 'blue'}" stroke-width="1"  fill="none"/>` +
  `<text x="${svgTextX}" y="${svgTextY}" text-anchor="middle" font-size="10" fill="${isSelected? 'red' : 'blue'}" transform="translate(15,15) rotate(${svgTextRotation})">${reactivePower}</text>` +
  '</svg>' ;
  const urlReactivePower = svgToURL(svgReactivePower);
  const [imageSVGReactivePower] = useImage(urlReactivePower);

  const svgReactivePowerSecondaryPart = 
  `<svg height="${svgHeight}" width="${svgWidth}"  xmlns="http://www.w3.org/2000/svg">` +
  `<path  d="${svgLine}" stroke="${isSelected? 'red' : 'blue'}"  stroke-width="1" fill="none"/>` +
  `<path  d="${svgArrowActive}" stroke="${isSelected? 'red' : 'blue'}" stroke-width="1"  fill="none"/>` +
  `<text x="${svgTextX}" y="${svgTextY}" text-anchor="middle" font-size="10" fill="${isSelected? 'red' : 'blue'}" transform="translate(15,15) rotate(${svgTextRotation})">${reactivePowerSecondaryPart}</text>` +
  '</svg>' ;
  const urlReactivePowerSecondaryPart = svgToURL(svgReactivePowerSecondaryPart);
  const [imageSVGReactivePowerSecondaryPart] = useImage(urlReactivePowerSecondaryPart);

  const svgReactivePowerThirdPart = 
  `<svg height="${svgHeight}" width="${svgWidth}"  xmlns="http://www.w3.org/2000/svg">` +
  `<path  d="${svgLine}" stroke="${isSelected? 'red' : 'blue'}"  stroke-width="1" fill="none"/>` +
  `<path  d="${svgArrowActive}" stroke="${isSelected? 'red' : 'blue'}" stroke-width="1"  fill="none"/>` +
  `<text x="${svgTextX}" y="${svgTextY}" text-anchor="middle" font-size="10" fill="${isSelected? 'red' : 'blue'}" transform="translate(15,15) rotate(${svgTextRotation})">${reactivePowerThirdPart}</text>` +
  '</svg>' ;
  const urlReactivePowerThirdPart = svgToURL(svgReactivePowerThirdPart);
  const [imageSVGReactivePowerThirdPart] = useImage(urlReactivePowerThirdPart);
 
  const svgActiveReactivePower =
  `<svg height="${svgHeight}" width="${svgWidth}"  xmlns="http://www.w3.org/2000/svg">` +
  `<path  d="${svgLine}" stroke="${isSelected? 'red' : 'blue'}"  stroke-width="1" fill="none"/>` +
  `<path  d="${svgArrowActive}" stroke="${isSelected? 'red' : 'blue'}" stroke-width="1"  fill="none"/>` +
  `<text x="${svgTextX}" y="${svgTextY}" text-anchor="middle" font-size="10" fill="${isSelected? 'red' : 'blue'}" >${activePower}</text>` +
  `<text x="${svgTextX1}" y="${svgTextY1}" text-anchor="middle" font-size="10" fill="${isSelected? 'red' : 'blue'}" >${reactivePower}</text>` +
  '</svg>';
  const urlActiveReactivePower = svgToURL(svgActiveReactivePower);
  const [imageSVGActiveReactivePower] = useImage(urlActiveReactivePower);

  const svgActiveReactivePowerSecondaryPart = 
  `<svg height="${svgHeight}" width="${svgWidth}"  xmlns="http://www.w3.org/2000/svg" >` +
  `<path  d="${svgLine}" stroke="${isSelected? 'red' : 'blue'}"  stroke-width="1" fill="none" style="transform: translateX(55px);"/>` +
  `<path  d="${svgArrowActive}" stroke="${isSelected? 'red' : 'blue'}" stroke-width="1"  fill="none" style="transform: translateX(55px);"/>` +
  `<text x="${Number(svgTextX)}" y="${svgTextY}" text-anchor="middle" font-size="10" fill="${isSelected? 'red' : 'blue'}" style="transform: translateX(-5px);" >${activePowerSecondaryPart}</text>` +
  `<text x="${svgTextX1}" y="${svgTextY1}" text-anchor="middle" font-size="10" fill="${isSelected? 'red' : 'blue'}" style="transform: translateX(-5px);" >${reactivePowerSecondaryPart}</text>` +
  '</svg>';
  const urlActiveReactivePowerSecondaryPart = svgToURL(svgActiveReactivePowerSecondaryPart);
  const [imageSVGActiveReactivePowerSecondaryPart] = useImage(urlActiveReactivePowerSecondaryPart);

  const [isOpenUserCurvesDialog, setIsOpenUserCurvesDialog] = useState(false);
  const [loadingFlag,setLoadingFlag] = useState<boolean>(false);
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [initialValue,setInitialValue] = useState<any>(JSON.parse(JSON.stringify(properties)));
  const [menuPosition, setMenuPosition] = useState<Coordinates>({ x: 0, y: 0 });
  const [isOpenEditorDialog, setIsOpenEditorDialog] = useState(false);
  const [groupPosition, setGroupPosition] = useState({ x: x, y: y });
  const [dragStartPosition, setDragStartPosition] = useState({ x: x, y: y, labelX: groupPosition.x + Constant.ELEMENT_SIZE, labelY: groupPosition.y + CONTROL_POS_FACTOR * (height / 2) });;
  const [isResizing, setIsResizing] = useState(false);
  const [heightTransform, setHeightTransform] = useState(0);
  const [colorLine,setColorLine] = useState<any>("normal");
  const [labelPosition, setLabelPosition] = useState({
    x: groupPosition.x + Constant.ELEMENT_SIZE,
    y: groupPosition.y + CONTROL_POS_FACTOR * (height / 2),
  });
  const [arrowPosition, setArrowPosition] = useState({
    x: groupPosition.x,
    y: groupPosition.y + height - 3 * Constant.ELEMENT_SIZE / 2,
  });
  const [listUserCurves, setListUserCurves] = useState(properties.listUser?[...properties.listUser]:[])
  const [isOpenUserCurveProperties, setIsOpenUserCurveProperties] = useState(false)
  const [selectItem, setSelectItem] = useState<any>(null);
  const [isOnOpenMenu, setIsOnOpenMenu] = useState<boolean>(false);

  const [dispInputDialog, setDispInputDialog] = useState<any>(null);

  const transformRef = useRef<Konva.Transformer>(null);
  const transformMoveRef = useRef<Konva.Transformer>(null);
  const groupRef = useRef<any>();
  const topRef = useRef<any>();
  const bottomRef = useRef<any>();
  const labelRef = useRef<any>();
  const p2LeftArrowRef = useRef<any>();
  const p3LeftArrowRef = useRef<any>();
  const p2RightArrowRef = useRef<any>();
  const p3RightArrowRef = useRef<any>();
  // const labelTexts1 = doGetRefV11(processMode, props as any, false);
  // const labelTexts2 = doGetRefV13(processMode, props as any, false);
  // const [labelTexts1, setLabelTexts1] = useState<any>([])
  // const [labelTexts2, setLabelTexts2] = useState<any>([])
  const [labelTexts1, setLabelTexts1] = useState<any>({title:'',body:'',textHeight:0,textWidth:0})
  const [labelTexts2, setLabelTexts2] = useState<any>({title:'',body:'',textHeight:0,textWidth:0})



  //#endregion

  //#region useEffect
  const getLabel =(part:number)=>{
    let labelFirst:any;
    let title:any
    if(part === 1){
      labelFirst = doGetRefV11(processMode, props as any, false)
      title = labelFirst?.shift()
    }
    else if(part === 2){
      labelFirst = doGetRefV13(processMode, props as any, false)
    }
    let txt = ""
    if(labelFirst?.length > 0){
      labelFirst?.map((item:any, index:any) =>{
        txt += item.value +'\n'
      })
      txt = txt.slice(0, -1)
    }
    let labelTexts1 = new Konva.Text({
      text: txt,
    });
    if(part === 1){
      setLabelTexts1({title:title.value,body:labelTexts1.attrs.text,textHeight:labelTexts1.height(),textWidth:labelTexts1.textWidth})
    }
    else if(part === 2){
      setLabelTexts2({title:'',body:labelTexts1.attrs.text,textHeight:labelTexts1.textHeight,textWidth:labelTexts1.textWidth})
    }
  }
  useEffect(()=>{
    // setLabelTexts1(doGetRefV11(processMode, props as any, false))
    // setLabelTexts2(doGetRefV13(processMode, props as any, false))
    getLabel(1);
    getLabel(2);
  },[processMode,diagramDataUpdate])
  useEffect(()=>{
    if(isSelected){
      if(isOnOpenMenu) {
        setIsOnOpenMenu(false)
      }
      else
      {
        setIsOpenMenu(false)
      }
    }
  },[clearMenuState])

  useEffect(()=>{
    if(elementReposition?.id)
    {
      const groupsData = currentTabId === 1 ? elementGroups : tcGroups[diagramData.find((x:any)=>x.id == id)?.parentTcId ?? 0]
      const peekGroupId = groupsData?.byEleId[elementReposition.id as any]?.at(-1) ?? -1
      const elementIds = groupsData?.byId[peekGroupId]?.elementIds ?? []
      if(elementIds.includes(id) || diagramData[0].shape.find((item:any)=>(item.isSelected && item.id == id)))
      {
        setGroupPosition({
          x: dragStartPosition.x,
          y: dragStartPosition.y,
        });

        labelRef.current.to({
          x: dragStartPosition.labelX,
          y: dragStartPosition.labelY,
        });
        
        groupRef.current.to({
          x: dragStartPosition.x,
          y: dragStartPosition.y,
        });

        updateElementPosition(id, dragStartPosition.x, dragStartPosition.y)
      }
    }
  },[elementReposition])

  useEffect(()=>{
    if(userCurveProperties?.controlId === id && userCurveProperties !== undefined) {
      let userCurve = properties.listUser.find(x => x.userCurveId === userCurveProperties.userCurveId)
      userCurve && setSelectItem(userCurve)
      setIsOpenUserCurveProperties(true)
    }
  },[userCurveProperties])

  useEffect(() => {
    
    setIsOpenMenu(false)
    let result = [];
    if(processMode == MS_MODE_POWER_FLOW && drawPowerArrow.payload.length > 0 && id)
    {
        result = drawPowerArrow.payload.filter((arrows:any) => arrows.arrow?.element?.id && Number(arrows.arrow?.element?.id) == Number(id))
        if(result && result.arrow && result.text.length > 0){
            setPowerFlowArrow(result)
        }
    }
  }, [drawPowerArrow])

  useEffect(() => {
    if(processMode == MS_MODE_POWER_FLOW)
    {
      const pElement = diagramData[0].shape.find((x:any)=>x.id === id)
      let result = doDrawSymbolGroup(pElement,processMode)
      if(result && result.arrow && result.text.length > 0){
        setPowerFlowArrow(result)
      }else{
        setIsHiddenSecondaryPart(true);
        setIsHiddenPrimaryPart(true);
        setIsHiddenThirdPart(true);
      }
    }
    else if(showPowerInfo == true || showPowerDirectInfo == true)
    {
      setShowPowerInfo(false);
      setShowPowerDirectInfo(false);
    }
  },[processMode,projectData.flowViewMethod,properties.viewFlowResult])


  useEffect(() => {
    setInitialValue(properties);
  },[properties])

  useEffect(() => {
    if (x >= 0 && y >= 0 && (groupPosition.x !== x || groupPosition.y !== y)) {
      setGroupPosition({x, y})
    }
  }, [x, y])

  useEffect(() => {
    if(openDialogData?.id === id && openDialogData !== undefined) {
      openDialogEvents(({type: "OPEN_DIALOG", data: { }}))
      handleOpenEditorDialog()
    }
  }, [openDialogData]);

  useEffect(() => {
    if(openUserCurveData?.id === id && openUserCurveData !== undefined) {
      setIsOpenUserCurvesDialog(true)
    }
  }, [openUserCurveData]);
  
  useEffect(() => {
    if (isSelected && !parentGroupId) {
      transformRef.current?.nodes([groupRef.current]);
    } else {
      transformRef.current?.nodes([]);
    }
    transformRef.current?.getLayer()?.batchDraw();
  }, [isSelected, parentGroupId, heightTransform]);

  useEffect(() => {
    if (!isSelected) setIsOpenMenu(false);
  }, [isSelected]);

  useEffect(() => {
    switch(getSymbolAndBackColor(infoSkeleton,processMode,properties).symbolColor){
      case "#008040":
        setColorLine("green");
        setLineStatus(lineGreenSvg);
        setPercentZStatus(percentZGreenSvg);
        break;
      case "#FF0000":
        setColorLine("red");
        setLineStatus(lineRedSvg);
        setPercentZStatus(percentZRedSvg);
        break;
      default:
        setColorLine("normal");
        setLineStatus(lineSvg);
        setPercentZStatus(percentZSvg)
        break;
    }
    switch(getSymbolAndBackColor(infoSkeleton,processMode,properties).symbolColor){
      case "#FF0000":
        switch (properties.symKind.toString()) {
          case "0":
            setImageStatus(image3WindingTransformer0RedSvg);
            break;
          case "1":
            setImageStatus(image3WindingTransformer1RedSvg);
            break;
          case "2":
            setImageStatus(image3WindingTransformer2RedSvg);
            break;
          case "3":
            setImageStatus(image3WindingTransformer3RedSvg);
            break;
          case "4":
            setImageStatus(image3WindingTransformer4RedSvg);
            break;
          case "5":
            setImageStatus(image3WindingTransformer5RedSvg);
            break;
          case "6":
            setImageStatus(image3WindingTransformer6RedSvg);
            break;
          case "7":
            setImageStatus(image3WindingTransformer7RedSvg);
            break;
        }
        break
      case "#008040":
        switch (properties.symKind.toString()) {
          case "0":
            setImageStatus(image3WindingTransformer0GreenSvg);
            break;
          case "1":
            setImageStatus(image3WindingTransformer1GreenSvg);
            break;
          case "2":
            setImageStatus(image3WindingTransformer2GreenSvg);
            break;
          case "3":
            setImageStatus(image3WindingTransformer3GreenSvg);
            break;
          case "4":
            setImageStatus(image3WindingTransformer4GreenSvg);
            break;
          case "5":
            setImageStatus(image3WindingTransformer5GreenSvg);
            break;
          case "6":
            setImageStatus(image3WindingTransformer6GreenSvg);
            break;
          case "7":
            setImageStatus(image3WindingTransformer7GreenSvg);
            break;
        }
        break
      default:
        switch (properties.symKind.toString()) {
          case "0":
            setImageStatus(image3WindingTransformer0Svg);
            break;
          case "1":
            setImageStatus(image3WindingTransformer1Svg);
            break;
          case "2":
            setImageStatus(image3WindingTransformer2Svg);
            break;
          case "3":
            setImageStatus(image3WindingTransformer3Svg);
            break;
          case "4":
            setImageStatus(image3WindingTransformer4Svg);
            break;
          case "5":
            setImageStatus(image3WindingTransformer5Svg);
            break;
          case "6":
            setImageStatus(image3WindingTransformer6Svg);
            break;
          case "7":
            setImageStatus(image3WindingTransformer7Svg);
            break;
        }
        break
    }
  },[infoSkeleton,processMode,properties])

  useEffect(() => {
    // check grid size; label, icon position when rotate, move and change height
    // handleGridSize(groupPosition.x, groupPosition.y, height, rotation);
    calcLabelPos();
    calcArrowPos();
    if (diagramData[0].tabId != 1){
      // TransCenter
      doSetValuesTransCenter();
    }
  }, [groupPosition, height, rotation]);
  //#endregion

  //#region Method
  const setPowerFlowArrow = (data:any) => {
    if(data.arrow.drawActiveNoneDirect){
        if(data.text){
            if(data.text[2]?.length > 0){
                setIsHiddenSecondaryPart(false);
                setActivePowerSecondaryPart(data.text[2][0]);
                setReactivePowerSecondaryPart(data.text[2][1]);
            }else{
                setIsHiddenSecondaryPart(true);
            }
            if(data.text[3]?.length > 0){
                setIsHiddenThirdPart(false);
                setActivePowerThirdPart(data.text[3][0]);
                setReactivePowerThirdPart(data.text[3][1]);
            }else{
            setIsHiddenThirdPart(true);
            }
        }
        setShowPowerInfo(true)
        setShowPowerDirectInfo(false)
        setIsHiddenPrimaryPart(false);
        if(data.arrow.arrowActiveAtTop){
            const svgLine = "M 20 50 L 20 0";
            const svgArrowLine = "M 16 5 L 20 0 L 24 5";
            setSvgHeight('70')
            setSvgWidth('30')
            setSvgLine(svgLine)
            setSvgArrowActive(svgArrowLine)
            setSvgTextX('-10')
            setSvgTextY('-5')
            setSvgTextRotation('270')
            setActivePower(data.text[0])
            setSvgPosX1(-55)
            setSvgPosY1(-30)
        }
        else if(data.arrow.arrowActiveAtRight) {
            const svgLine = "M 50 20 L 0 20";
            const svgArrowLine = "M 5 16  L 0 20 L 5 24";
            setSvgHeight('30')
            setSvgWidth('70')
            setSvgLine(svgLine)
            setSvgArrowActive(svgArrowLine)
            setSvgTextX('7')
            setSvgTextY('3')
            setSvgTextRotation('0')
            setActivePower(data.text[0])
            setSvgPosX1(-40)
            setSvgPosY1(20)
        }
        else if(data.arrow.arrowActiveAtLeft){
            const svgLine = "M 50 20 L 0 20";
            const svgArrowLine = "M 45 16 L 50 20 L 45 24";
            setSvgHeight('30')
            setSvgWidth('70')
            setSvgLine(svgLine)
            setSvgArrowActive(svgArrowLine)
            setSvgTextX('7')
            setSvgTextY('3')
            setSvgTextRotation('0')
            setActivePower(data.text[0])
            setSvgPosX1(-55)
            setSvgPosY1(-30)
            setSvgPosX1(-40)
            setSvgPosY1(30)
        }
        else {
            const svgLine = "M 20 50 L 20 0";
            const svgArrowLine = "M 16 45 L 20 50 L 24 45";
            setSvgHeight('70')
            setSvgWidth('30')
            setSvgLine(svgLine)
            setSvgArrowActive(svgArrowLine)
            setSvgTextX('-10')
            setSvgTextY('-5')
            setSvgTextRotation('270')
            setActivePower(data.text[0])
            setSvgPosX1(-55)
            setSvgPosY1(-30)
        }
    }
    if(data.arrow.drawReactiveNoneDirect){
        setShowPowerInfo(true)
        setShowPowerDirectInfo(false)
        if(data.arrow.arrowReactiveAtTop){
            setSvgHeight('70')
            setSvgWidth('30')
            setSvgLine('M 20 50 L 20 0')
            setSvgArrowReactive('M 16 5  L 20 0 L 24 5')
            setSvgTextX('-10')
            setSvgTextY('-5')
            setSvgTextRotation('270')
            setReactivePower(data.text[1])
            setSvgPosX2(-30)
            setSvgPosY2(-30)
        }
        else if(data.arrow.arrowReactiveAtRight) {
            setSvgHeight('30')
            setSvgWidth('70')
            setSvgLine('M 50 20 L 0 20')
            setSvgArrowReactive('M 5 16  L 0 20 L 5 24')
            setSvgTextX('7')
            setSvgTextY('3')
            setSvgTextRotation('0')
            setReactivePower(data.text[1])
            setSvgPosX2(-40)
            setSvgPosY2(40)
        }
        else if(data.arrow.arrowReactiveAtLeft){
            setSvgHeight('30')
            setSvgWidth('70')
            setSvgLine('M 50 20 L 0 20')
            setSvgArrowReactive('M 45 16 L 50 20 L 45 24')
            setSvgTextX('7')
            setSvgTextY('3')
            setSvgTextRotation('0')
            setReactivePower(data.text[1])
            setSvgPosX2(-40)
            setSvgPosY2(40)
        }
        else {
            setSvgHeight('70')
            setSvgWidth('30')
            setSvgLine('M 20 50 L 20 0')
            setSvgArrowReactive('M 16 45 L 20 50 L 24 45')
            setSvgTextX('-10')
            setSvgTextY('-5')
            setSvgTextRotation('270')
            setReactivePower(data.text[1])
            setSvgPosX2(-30)
            setSvgPosY2(-30)
        }
    }
    if(data.arrow.drawOtherDirect){
        setShowPowerInfo(false)
        setShowPowerDirectInfo(true)
        if(data.arrow.arrowActiveReactiveAtTop){
            setSvgHeight('50')
            setSvgWidth('70')
            setSvgLine('M 10 50 L 10 20')
            setSvgArrowActive('M 6 25 L 10 20 L 14 25')
            setSvgTextX('40')
            setSvgTextY('30')
            setSvgTextX1('40')
            setSvgTextY1('40')
            setSvgTextRotation('0')
            setActivePower(data.text[0])
            setReactivePower(data.text[1])
            setSvgPosX3(20)
            setSvgPosY3(-50)
        }
        else if(data.arrow.arrowActiveReactiveAtRight) {
            setSvgHeight('70')
            setSvgWidth('50')
            setSvgLine('M 50 10 L 20 10')
            setSvgArrowActive('M 25 6 L 20 10 L 25 14')
            setSvgTextX('25')
            setSvgTextY('20')
            setSvgTextX1('25')
            setSvgTextY1('30')
            setSvgTextRotation('0')
            setActivePower(data.text[0])
            setReactivePower(data.text[1])
            setSvgPosX3(-30)
            setSvgPosY3(30)
        }
        else if(data.arrow.arrowActiveReactiveAtLeft){
            setSvgHeight('70')
            setSvgWidth('50')
            setSvgLine('M 50 10 L 20 10')
            setSvgArrowActive('M 45 6 L 50 10 L 45 14')
            setSvgTextX('25')
            setSvgTextY('20')
            setSvgTextX1('25')
            setSvgTextY1('30')
            setSvgTextRotation('0')
            setActivePower(data.text[0])
            setReactivePower(data.text[1])
            setSvgPosX3(-30)
            setSvgPosY3(30)
        }
        else {
            setSvgHeight('50')
            setSvgWidth('70')
            setSvgLine('M 10 50 L 10 20')
            setSvgArrowActive('M 6 45 L 10 50 L 14 45')
            setSvgTextX('40')
            setSvgTextY('30')
            setSvgTextX1('40')
            setSvgTextY1('40')
            setSvgTextRotation('0')
            setActivePower(data.text[0])
            setReactivePower(data.text[1])
            setSvgPosX3(20)
            setSvgPosY3(-50)
        }
    }
  }

  const handleOKUserCurveDetailForm = async (data: any) => {
    const foundUserCurve = listUserCurves.find((curve:any) => curve.userCurveId === data.userCurveId)        
    if (foundUserCurve) { // update  
      setLoadingFlag(true);
      const request = { 
        userId: userId,
        projectId: projectData.projectId,
        elementId: parseInt(id),
        params:{...data},
        ownerProject: projectData.ownerId

      }
      const result = await post("/diagram/edit-curve-user", request);
      if (result.success) {
        setLoadingFlag(false);
        handleUpdateCurveUserSuccess(id, result.data);
        updateUserCurve(id,data);
      } else {
        handleSubmitError(result.error);
        setLoadingFlag(false);
      }
      //setOpenChart(true, LineType.USER_CURVE, data.userCurveId??data.user_curve_id)
    } else { // add new
        addUserCurve(id, data)
    }
    setSelectItem(null)
    setIsOpenUserCurveProperties(false)
  }

  const handleCancelUserCurveDetailForm = () => {
    setSelectItem(null)
    setIsOpenUserCurveProperties(false)
    openUserCurvePropertiesEvent('', '')
  }
  
  const doSetValuesTransCenter = () => {
    // TransCenter
    let transCenter = mainDiagramData.shape.find(
      (e: any) => e.properties?.tabId === diagramData[0].tabId
    );

    if (transCenter) {
      let tmpCalc = doGetValuesTransCenter(transCenter.properties, diagramData[0].shape);
      transCenter.properties = tmpCalc.newProps;
      updateTransCenterProps(transCenter.id, transCenter.properties);
    }
  };

  const handleContextMenu = (e: any) => {
    e.evt.preventDefault();
    if(e.evt.shiftKey) return
    setIsOnOpenMenu(true)
    updateSelectControl(id);

    const menuHeight = getMenuOptionHeight()
    const clientWidth = lineDiagramRef.current.clientWidth
    const isTransCenter = currentTabId == 1 ? false : true;
    let {posX, posY} = calcContextMenuPosition(
      e, menuHeight, clientWidth, x, y, gridSizeController, isTransCenter
    )
    clearOptionMenu(!clearMenuState)
    
    setMenuPosition({
      x: posX,
      y: posY,
    });
    setIsOpenMenu(true);
  };

  const handleClick = (e: any) => {
    if (e.type === "click") setIsOpenMenu(false);
    if(parentGroupId && parentGroupId > 0) return;
    if (e.evt.shiftKey || isSelectTouchGroup) {
      updateSelectControl(id, true);
    } else {
      updateSelectControl(id);
    }
  };

  const handleOpenMenu = (value: boolean) => {
    setIsOpenMenu(value);
    updateSelectControl(id);
  };

  const handleOpenEditorDialog = async () => {
    setLoadingFlag(true);
    let params = {
      userId: userId,
      projectId: projectId,
      elementId: id,
      elementType: type,
      data: properties,
      ownerProject: projectData.ownerId
    };
    // getElementParam(params,id);
    const result = await post("/diagram/get-param", params);
    if (result.success) {
      handleGetSuccess(result.data);
    } else {
      handleSubmitError(result.error);
    }
  };

  const handleCancelEditorDialog = () => {
    setIsOpenEditorDialog(false);
  };
  const checkOldValue = (data:any,initialValue:any) =>{
      if(data.partPrimary.perRk3 == BeamsNumber(initialValue.partPrimary.perRk3,4)){
        data.partPrimary.perRk3 = initialValue.partPrimary.perRk3
      }
      if(data.partPrimary.xR == BeamsNumber(initialValue.partPrimary.xR,4)){
        data.partPrimary.xR = initialValue.partPrimary.xR
      }
      if(data.partPrimary.perZk3 == BeamsNumber(initialValue.partPrimary.perZk3,4)){
        data.partPrimary.perZk3 = initialValue.partPrimary.perZk3
      }
      if(data.partPrimary.perXk3 == BeamsNumber(initialValue.partPrimary.perXk3,4)){
        data.partPrimary.perXk3 = initialValue.partPrimary.perXk3
      }
      //second
      if(data.partSecondary.perRk3 == BeamsNumber(initialValue.partSecondary.perRk3,4)){
        data.partSecondary.perRk3 = initialValue.partSecondary.perRk3
      }
      if(data.partSecondary.xR == BeamsNumber(initialValue.partSecondary.xR,4)){
        data.partSecondary.xR = initialValue.partSecondary.xR
      }
      if(data.partSecondary.perZk3 == BeamsNumber(initialValue.partSecondary.perZk3,4)){
        data.partSecondary.perZk3 = initialValue.partSecondary.perZk3
      }
      if(data.partSecondary.perXk3 == BeamsNumber(initialValue.partSecondary.perXk3,4)){
        data.partSecondary.perXk3 = initialValue.partSecondary.perXk3
      }
      //third
      if(data.partThird.perRk3 == BeamsNumber(initialValue.partThird.perRk3,4)){
        data.partThird.perRk3 = initialValue.partThird.perRk3
      }
      if(data.partThird.xR == BeamsNumber(initialValue.partThird.xR,4)){
        data.partThird.xR = initialValue.partThird.xR
      }
      if(data.partThird.perZk3 == BeamsNumber(initialValue.partThird.perZk3,4)){
        data.partThird.perZk3 = initialValue.partThird.perZk3
      }
      if(data.partThird.perXk3 == BeamsNumber(initialValue.partThird.perXk3,4)){
        data.partThird.perXk3 = initialValue.partThird.perXk3
      }
      return data
  }
  const handleUpdate = async (data: any) => {
    let updateData = JSON.parse(JSON.stringify(data));
    setIsOpenEditorDialog(false);
    updateData = checkOldValue(data,initialValue)
    const dataSend = {
      no3Winding:updateData.no3Winding,
      refNo:updateData.refNo,
      checkNG:updateData.checkNG,
      eleNeutralPointNumber:updateData.eleNeutralPointNumber,
      supply:updateData.supply,
      tcName:updateData.tcName,
      exchangeNumber:updateData.symKind,
      makeDataInput:updateData.makeDataInput,
      makerName:updateData.makerName,
      makeYear:updateData.makeYear,
      makeMonth:updateData.makeMonth,
      makeNumber:updateData.makeNumber,
      type:updateData.type,
      viewFlowResult:updateData.viewFlowResult,
      primarySupply:updateData.primarySupply,
      seriesed:updateData.seriesed,
      viewResultText:updateData.viewResultText,
      partPrimary:{...updateData.partPrimary},
      partSecondary: {...updateData.partSecondary},
      partThird:{...updateData.partThird}, 
    } 
    const request = { 
      userId: userId,
      projectId: projectId,
      elementType: getElementKindValue(props.type),
      elementId: id,
      param: dataSend,
      ownerProject: projectData.ownerId
    }
    setLoadingFlag(true);
    // updateElementPost(request, id)
    const result = await post("/diagram/set-param", request);
      if (result.success) {
        handleUpdateSuccess(result.data);
        if(result.data && result.data.resultCode == 0){
          setUndoData({type:"UPDATE_PROPERTIES_ELEMENT", dataUndo:{}} as Model.UndoModel)
          updateControlData(id, updateData);
          setInitialValue(updateData);
        }
      } else {
        handleSubmitError(result.error);
      }
    // // set the same voltage to connected control
    // let tmpAllDiagram: DiagramModel[] = structuredClone(diagramDataList)
    // let index = tmpAllDiagram.findIndex((e: DiagramModel) => e.tabId === diagramData[0].tabId)
    // let newTransCenter: DiagramModel[] = []

    // let resultData = doSetSameVoltToTop(
    //   tmpAllDiagram,
    //   diagramData[0].tabId,
    //   id, 
    //   Number(updateData.partPrimary.voltage),
    //   false
    // )
    // if (resultData.code === 0){
    //   let newData = resultData.data!
    //   tmpAllDiagram[index].shape = newData.currentDiagram
    //   if (newData.transCenterDiagramList){
    //     newTransCenter = [...newTransCenter, ...newData.transCenterDiagramList]
    //   }
    // }
    
    // resultData = doSetSameVoltToRight(
    //   tmpAllDiagram,
    //   diagramData[0].tabId,
    //   id, 
    //   Number(updateData.partSecondary.voltage),
    //   false
    // )
    // if (resultData.code === 0){
    //   let newData = resultData.data!
    //   tmpAllDiagram[index].shape = newData.currentDiagram
    //   if (newData.transCenterDiagramList){
    //     newTransCenter = [...newTransCenter, ...newData.transCenterDiagramList]
    //   }
    // }

    // resultData = doSetSameVoltToLeft(
    //   tmpAllDiagram,
    //   diagramData[0].tabId,
    //   id,
    //   Number(updateData.partThird.voltage),
    //   false
    // )
    // if (resultData.code === 0){
    //   let newData = resultData.data!
    //   tmpAllDiagram[index].shape = newData.currentDiagram
    //   if (newData.transCenterDiagramList){
    //     newTransCenter = [...newTransCenter, ...newData.transCenterDiagramList]
    //   }
    // }

    // newTransCenter.forEach((e: DiagramModel) => {
    //   saveAllControlsToDiagram({...e}, false)
    // })
    // saveAllControlsToDiagram({...diagramData[0], ...{shape: tmpAllDiagram[index].shape}}, true)

    openDialogEvents({type: "RELOAD", data: { }});
    // TODO updateElementPost for all ??
  };


  const dragBoundFunc = (e: any, mode: string) => {
    // let rect = groupRef.current.getClientRect();
    const newPosition = calcDragBoundPosition(
      e, groupPosition, rotation, height, mode, gridSizeController
    );

    return {
      x: newPosition.newX,
      y: newPosition.newY,
    };
  };

  const calcLabelPos = (e?: any) => {
    let newX = groupPosition.x,
      newY = groupPosition.y;
    if (e?.type === "dragmove") {
      newX = groupRef.current.attrs.x;
      newY = groupRef.current.attrs.y;
    }

    const newPosition = {
      x: newX + Constant.ELEMENT_SIZE,
      y: newY + CONTROL_POS_FACTOR * (height / 2),
    };

    if (rotation !== 0) {
      newPosition.x = newX + CONTROL_POS_FACTOR * (height / 2);
      newPosition.y = newY - Constant.ELEMENT_SIZE;
    }

    setLabelPosition(newPosition);
  };

  const calcArrowPos = (e?: any) => {
    let newX = groupPosition.x,
      newY = groupPosition.y;
    if (e?.type === "dragmove") {
      newX = groupRef.current.attrs.x;
      newY = groupRef.current.attrs.y;
    }

    const newPosition = {
      x: newX,
      y: newY + height - 3 * Constant.ELEMENT_SIZE / 2,
    };

    if (rotation !== 0) {
      newPosition.y = newY;
      rotation === -90 &&
        (newPosition.x = newX + height - 3 * Constant.ELEMENT_SIZE / 2);
      rotation === 90 && (newPosition.x = newX);
    }

    setArrowPosition(newPosition);
  };

  const handleDragStart = (e: any) => {
    groupRef.current.moveToTop()
    transformRef.current?.moveToTop()
    labelRef.current?.moveToTop()
    p2LeftArrowRef.current?.moveToTop()
    p3LeftArrowRef.current?.moveToTop()
    p2RightArrowRef.current?.moveToTop()
    p3RightArrowRef.current?.moveToTop()
    setDragStartPosition({x: groupPosition.x, y: groupPosition.y, labelX: labelPosition.x, labelY: labelPosition.y});
    setIsOpenMenu(false);
  }

  const handleDragmove = (e: any) => {
    calcLabelPos(e);
    calcArrowPos(e);
  };

  const onDragEnd = (e: any) => {
    if (
      e.currentTarget.attrs.x === groupPosition.x &&
      e.currentTarget.attrs.y === groupPosition.y
    ) {
      return;
    }

    let newX =
      Math.round(e.target.x() / Constant.POINTER_WIDTH) *
      Constant.POINTER_WIDTH;
    let newY =
      Math.round(e.target.y() / Constant.POINTER_HEIGHT) *
      Constant.POINTER_HEIGHT;
         
    // check new position is outside grid
    if (newX < 0 || newY < 0){
      setRepositionElement(id, !(elementReposition?.state??true))
      newX = dragStartPosition.x;
      newY = dragStartPosition.y;
    }
    
    setGroupPosition({
      x: newX,
      y: newY,
    });

    e.target.to({
      x: newX,
      y: newY,
    });
  };

  // Mobile Touch Events
  var isMoving = false
  var isLongPress = false
  
  const handleTouchStart = useCallback((e:any) => {
    setIsOpenMenu(false)
    isLongPress = true
    isMoving = false
    !isSelectTouchGroup && setTimeout(() => {
      if (isLongPress && !isMoving) {
        handleContextMenu(e)
      }
    }, 1000)
  },[x,y,isSelectTouchGroup])

  const handleTouchMove = useCallback(() => {
    isMoving = true
  },[x,y,isSelectTouchGroup])

  const handleTouchEnd = useCallback((e:any) => {
    isLongPress = false
  },[x,y,isSelectTouchGroup])

  const handleMouseDown = (e: any, mode: string) => {
    const container = e.target.getStage().container();
    container.style.cursor = rotation === 0 ? "ns-resize" : "ew-resize";
    setIsResizing(true);
  };

  const handleMouseMove = (e: any, mode: string) => {
    if (!isResizing) return;
    setHeightTransform(e.evt.movementY);
  };

  const handleMouseUp = (e: any, mode: string) => {
    setTransformFlag(true);
    const container = e.target.getStage().container();
    container.style.cursor = "default";

    let newPosX = e.target._lastPos.x // e.evt.offsetX
    let newPosY = e.target._lastPos.y // e.evt.offsetY

    let newEle = calcElementSize(
      newPosX,
      newPosY,
      groupPosition,
      rotation,
      height,
      offsetY,
      mode,
      gridSizeController
    )

    topRef.current.y(0);
    // bottomRef.current.y(newHeight - 7)
    setGroupPosition({ x: newEle.newX, y: newEle.newY });

    updateControlHeight(id, newEle.newHeight, newEle.newX, newEle.newY, newEle.newOffsetY);
    setIsResizing(false);
  };
  
  const handleGetSuccess = (data:any) =>{
    setDispInputDialog(data.dispInputDialog)
    const newData = data.data;
    let results = {...initialValue}

    //Transcenter source voltage
    let shapes = diagramData[0].shape
    let result =[] as any 
    let result2 =[] as any 
    let result3 =[] as any 

    if(currentTabId !== 1){
      //voltagePrimary
      result = doGetTransCenterSourceVolt(
      shapes,
      {
        id: id,
        image: image,
        x: x,
        y: y,
        offsetX: offsetX,
        offsetY: offsetY,
        width: width,
        height: height,
        type: type,
        isSelected: isSelected,
        rotation: rotation,
        chart: chart,
        isRotationEnabled: false,
        parentGroupId,
        properties: properties
      },
      Constant.GET_TOP,
      
    )
    //voltageSecondary
    result2 = doGetTransCenterSourceVolt(
      shapes,
      {
        id: id,
        image: image,
        x: x,
        y: y,
        offsetX: offsetX,
        offsetY: offsetY,
        width: width,
        height: height,
        type: type,
        isSelected: isSelected,
        rotation: rotation,
        chart: chart,
        isRotationEnabled: false,
        parentGroupId,
        properties: properties
      },
      Constant.GET_RIGHT,
      
    )
    //voltageThird
    result3 = doGetTransCenterSourceVolt(
      shapes,
      {
        id: id,
        image: image,
        x: x,
        y: y,
        offsetX: offsetX,
        offsetY: offsetY,
        width: width,
        height: height,
        type: type,
        isSelected: isSelected,
        rotation: rotation,
        chart: chart,
        isRotationEnabled: false,
        parentGroupId,
        properties: properties
      },
      Constant.GET_LEFT,
      
    )
    
    }
    if(result.voltage > 0 || result2.voltage > 0 || result3.voltage > 0){
      results.isSourceVoltage = true
    }
    else{
      results.isSourceVoltage = false
    }
    

    const voltHighList = [];
    if(newData.allVoltList){
      for(const item of newData.voltHighList){
        voltHighList.push({label:item.lvoltage,value:item.lvoltage}as OptionModel)
      }
    }
    results.voltHighList = voltHighList;

    const allVoltList = [];
    if(newData.allVoltList){
      for(const item of newData.allVoltList){
        allVoltList.push({label:item.lvoltage,value:item.lvoltage}as OptionModel)
      }
    }
    results.allVoltList = allVoltList;

    const trans3List = [];
    if(newData.trans3List){
      for(const item of newData.trans3List){
        trans3List.push(item)
      }
    }
    results.trans3List = trans3List;

    const capacityList:any = [];
    if(newData.trans3List){
      for(const item of newData.trans3List){
        capacityList.push({label:item.dcapacity,value:item.dcapacity} as OptionModel)
      }
    }
    results.capacityList = capacityList;

    let makerNameList: OptionModel[] = [];
    if (newData.makerNameList){
        newData.makerNameList.forEach((element: any) => {
            let optionModel: OptionModel = {
                label: element.smanuName,
                value: element.smanuName
            };
            makerNameList.push(optionModel)
        });
    }
 
    let makeYearList: OptionModel[] = [];
    for (let i = MAKE_YEAR_MAX; i >= MAKE_YEAR_MIN; i--){
        let optionModel: OptionModel = {
            label: i.toString(),
            value: i
        };
        makeYearList.push(optionModel)
    }
    let makeMonthList: OptionModel[] = [];
    for (let i = 1; i <= 12; i++){
        let optionModel: OptionModel = {
            label: i.toString(),
            value: i
        };
        makeMonthList.push(optionModel)
    }

    results.makeYearList = makeYearList;
    results.makeMonthList = makeMonthList;
    results.makerNameList = makerNameList;

    setInitialValue({...results});
    setLoadingFlag(false);
    setIsOpenEditorDialog(true);

  }
  // updateElementPostのAPI呼び出し成功時
  const handleUpdateSuccess = async (data:any) => {
    setLoadingFlag(false);
    if(data && data.resultCode == 0){
      // mappedDataFromBE(data.data.elements);
      const result = await getElements(userId, projectId, data.data.element_ids,projectData.ownerId)
      if (result.success) {
        mappedDataFromBE(result.data)
      } else {
        handleSubmitError(result.error)
      }
    }
  }
  // updateElementPostのAPI呼び出しエラー時
  const handleSubmitError = (error:any) => {
    setLoadingFlag(false);
  }

  const handleAddUserCurve = async (controlId:string,data:any)=>{
    const request = { 
      userId: userId,
      projectId: projectId,
      elementId: parseInt(controlId),
      params:{...data},
      ownerProject: projectData.ownerId

    }
    // addUserCurveDB(controlId,request)
    const result = await post("/diagram/add-curve-user", request);
    if (result.success) {
      handleAddCurveUserSuccess(id, result.data);
    } else {
      handleSubmitError(result.error);
    }
  }
  const handleUpdateUserCurve = async (controlId:string,data:any) =>{
    const request = { 
      userId: userId,
      projectId: projectId,
      elementId: parseInt(controlId),
      params:{...data},
      ownerProject: projectData.ownerId

    }
    // updateUserCurveDB(controlId,request)
    const result = await post("/diagram/edit-curve-user", request);
    if (result.success) {
      handleUpdateCurveUserSuccess(id, result.data);
    } else {
      handleSubmitError(result.error);
    }
  }
  const handleDeleteUserCurve = async (controlId:string,data:any) =>{
    const request = { 
      userId: userId,
      projectId: projectId,
      elementId: parseInt(controlId),
      userCurveId: data["userCurveId"],
      ownerProject: projectData.ownerId

    }
    // deleteUserCurveDB(controlId,request)
    deleteRelatedGraphByUserCurveDialog({controlId, data: data["userCurveId"], mode : true})
    const result = await post("/diagram/delete-curve-user", request);
    if (result.success) {
      handleDeleteCurveUserSuccess(id, result.data);
    } else {
      handleSubmitError(result.error);
    }
  }
  const handleAddCurveUserSuccess = (controlId:string,data:any) =>{
    addUserCurve(controlId,data)
    setListUserCurves(prev => [...prev, data])
  }
  const handleUpdateCurveUserSuccess =(controlId:string,data:any) =>{
    const foundUserCurve = listUserCurves.findIndex((curve:any) => curve.userCurveId === data["userCurveId"])
    const newList = [...listUserCurves]
    newList[foundUserCurve] = data
    updateUserCurve(controlId,data)
    setListUserCurves(newList)

  }
  const handleDeleteCurveUserSuccess = (controlId:string,data:any) =>{
      const newList = [...listUserCurves]
      newList.splice(newList.findIndex((curve : any) => curve.userCurveId === data), 1)
      let params = {
        userCurveId: data,
        projectId: projectId,
      }
      deleteUserCurve(controlId,params)
      setListUserCurves([...newList])

  }
  //#endregion

  return (
    <>
      {isOpenMenu &&
        <OptionMenu
          controlId={id}
          isOpenMenu={isOpenMenu}
          setIsOpenMenu={handleOpenMenu}
          onProperties={handleOpenEditorDialog}
          x={menuPosition.x}
          y={menuPosition.y}
          isDisabledDelete={false}
          isDisabledCut={false}
          isDisabledCopy={false}
          isDisabledPaste={false}
          isDisabledOpenGraph={false}
          isDisabledProps={false}
          isDisabledRotate={true}
          onUserCurves={() => setIsOpenUserCurvesDialog(true)}
        />
      }

      <Label
        ref={labelRef}
        listening={false}
        width={Constant.LABEL_WIDTH}
        x={labelPosition.x}
        y={labelPosition.y}
        offsetX={[90, -90].includes(rotation) ? 8 : -5}
        offsetY={
          rotation === 0 ? ((labelTexts1.textHeight/12 +1) * Constant.LINE_HEIGHT) / 2 : 0
        }
      > 
        {/* {labelTexts1.length > 0 &&
          labelTexts1.map((item:any, index:any) => {
            let content = item.value;

            let offsetY = index * Constant.LINE_HEIGHT;
            rotation !== 0 &&
              index !== 0 &&
              (offsetY =
                (index - 1) * Constant.LINE_HEIGHT +
                width +
                Constant.ELEMENT_SIZE);

            return (
              <Text
                y={offsetY - 10}
                key={index}
                text={content}
                fill={isSelected ? "red" : item.color}
              />
            );
          })} */}
          <Text
                y={-10}
                text={labelTexts1.title}
                // key={index}
                fill={isSelected ? "red" : '#f800f8'}
          />
          {labelTexts1.body.length > 0 &&
            <Text
                  y={Constant.LINE_HEIGHT-10}
                  // key={index}
                  text={labelTexts1.body}
                  fill={isSelected ? "red" : 'blue'}
            />
          }
          {labelTexts2.body.length > 0 &&
            <Text
                  y={(Constant.LINE_HEIGHT - 20)}
                  offsetX={30 + labelTexts2.textWidth}
                  offsetY={([Model.ProcessMode.DRAWING,Model.ProcessMode.CHECKING].includes(processMode)?-56:
                            processMode == Model.ProcessMode.PERCENT_Z?-34:
                            processMode == Model.ProcessMode.VOLTDROP?-10:-11
                  )}
                  // key={index}
                  text={labelTexts2.body}
                  fill={isSelected ? "red" : 'blue'}
              />
            }

        {/* {labelTexts2.length > 0 &&
          labelTexts2.map((item:any, index:any) => {
            let content = item.value;

            let offsetY = index * Constant.LINE_HEIGHT;
            let pointX = 90;
            let pointY = -56;
            switch(processMode) {
              case Model.ProcessMode.DRAWING:
              case Model.ProcessMode.CHECKING:
                pointX = 90;
                pointY = -56;
                break;
              case Model.ProcessMode.PERCENT_Z:
                pointX = 140;
                pointY = -34;
                break;
              case Model.ProcessMode.CALCULATION:
                pointX = 90;
                pointY = -11;
                break;
              case Model.ProcessMode.VOLTDROP:
                pointX = 140;
                pointY = -10;
                break;
              case Model.ProcessMode.HARMONIC:
              case Model.ProcessMode.IMPROVE_PF:
              case Model.ProcessMode.POWER_FLOW:
                pointX = 90;
                pointY = -11;
                break;  
            }
            rotation !== 0 &&
              index !== 0 &&
              (offsetY =
                (index - 1) * Constant.LINE_HEIGHT +
                width +
                Constant.ELEMENT_SIZE);

            return (
              <Text
                y={offsetY - 10}
                offsetX={pointX}
                offsetY={pointY}
                key={index}
                text={content}
                fill={isSelected ? "red" : item.color}
              />
            );
          })} */}
      </Label>

      <Group
        ref={groupRef}
        id={id}
        x={groupPosition.x}
        y={groupPosition.y}
        offset={{ x: offsetX, y: offsetY }}
        width={width}
        height={height}
        draggable={(processMode === Model.ProcessMode.DRAWING && !m_bModePM && !modeViewOnly && !isOnShiftKey)}
        type={type}
        onClick={handleClick}
        onTap={handleClick}
        onContextMenu={handleContextMenu}
        rotation={rotation}
        onDblClick={handleOpenEditorDialog}
        onDblTap={handleOpenEditorDialog}
        onMouseDown={() => transformMoveRef.current?.nodes([groupRef.current])}
        onMouseUp={() => transformMoveRef.current?.nodes([])}
        onDragStart={handleDragStart}
        onDragMove={handleDragmove}
        onDragEnd={onDragEnd}
        onMouseLeave={(e: any) => {
          const container = e.target.getStage().container();
          container.style.cursor = "default";
        }}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <Image
          fill={getSymbolAndBackColor(infoSkeleton,processMode,properties).backColor}
          image={lineObj}
          height={height - 10}
          onMouseEnter={(e: any) => {
            const container = e.target.getStage().container();
            container.style.cursor = (processMode === Model.ProcessMode.DRAWING && !m_bModePM && !modeViewOnly) ? "move" : "crosshair";
          }}
        />
        {processMode === Model.ProcessMode.PERCENT_Z &&
          <Image
            fill={getSymbolAndBackColor(infoSkeleton,processMode,properties).backColor}
            image={percentZObj}
            width={Constant.ELEMENT_SIZE}
            height={Constant.ELEMENT_SIZE * 2}
            y={height / 2 - 24}
            onMouseEnter={(e: any) => {
              const container = e.target.getStage().container();
              container.style.cursor = "crosshair";
            }}
          />
        }

        {
          processMode !== Model.ProcessMode.PERCENT_Z && 
          <Image
            fill={getSymbolAndBackColor(infoSkeleton,processMode,properties).backColor}
            image={imageObj}
            width={Constant.ELEMENT_SIZE}
            height={Constant.ELEMENT_SIZE}
            y={height / 2 - 10}
            onMouseEnter={(e: any) => {
              const container = e.target.getStage().container();
              container.style.cursor = (processMode === Model.ProcessMode.DRAWING && !m_bModePM && !modeViewOnly) ? "move" : "crosshair";
            }}
          />
        }
        
        {processMode === Model.ProcessMode.DRAWING && !m_bModePM && !modeViewOnly && isSelected && !parentGroupId && (
          <>
            <Rect
              ref={topRef}
              fill="black"
              x={6.75}
              y={0}
              width={7}
              height={7}
              draggable
              onMouseDown={() => setTransformFlag(false)}
              dragBoundFunc={(e: any) => dragBoundFunc(e, "top")}
              onDragStart={(e: any) => handleMouseDown(e, "top")}
              onDragMove={(e: any) => handleMouseMove(e, "top")}
              onDragEnd={(e: any) => handleMouseUp(e, "top")}
              onMouseEnter={(e: any) => {
                const container = e.target.getStage().container();
                container.style.cursor =
                  rotation === 0 ? "ns-resize" : "ew-resize";
              }}
            />
            <Rect
              ref={bottomRef}
              fill="black"
              x={6.75}
              y={height - 7}
              width={7}
              height={7}
              draggable
              onMouseDown={() => setTransformFlag(false)}
              dragBoundFunc={(e: any) => dragBoundFunc(e, "bottom")}
              onDragStart={(e: any) => handleMouseDown(e, "bottom")}
              onDragMove={(e: any) => handleMouseMove(e, "bottom")}
              onDragEnd={(e: any) => handleMouseUp(e, "bottom")}
              onMouseEnter={(e: any) => {
                const container = e.target.getStage().container();
                container.style.cursor =
                  rotation === 0 ? "ns-resize" : "ew-resize";
              }}
            />
          </>
        )}
      </Group>
      {(properties.partThird.dropRegular &&!([Model.ProcessMode.CALCULATION,Model.ProcessMode.IMPROVE_PF,Model.ProcessMode.HARMONIC,Model.ProcessMode.POWER_FLOW].includes(processMode)) && !m_bModePM) && (
        <Image
          ref={p3LeftArrowRef}
          listening={false}
          offsetY={[90, -90].includes(rotation) ? Constant.ELEMENT_SIZE / 4 - 2.5 : -2.5}
          offsetX={2}
          x={arrowPosition.x}
          y={arrowPosition.y}
          image={leftArrowObj}
          width={Constant.ELEMENT_SIZE / 2}
          height={Constant.ELEMENT_SIZE}
        />
      )}

      { showPowerInfo &&
        <>
        {!isHiddenPrimaryPart && <>

          <Image  
            image={imageSVGActivePower} 
            listening={false}
            x={arrowPosition.x + svgPosX1 + 80}
            y={arrowPosition.y + svgPosY1 - 90}
          />
          <Image  
            image={imageSVGReactivePower} 
            listening={false}
            x={arrowPosition.x + svgPosX2 + 80}
            y={arrowPosition.y + svgPosY2 - 90}
          />
        </>}
        
        {
          !isHiddenSecondaryPart &&
          <>
            <Image
              image={imageSVGActivePowerSecondaryPart}
              listening={false}
              x={arrowPosition.x + svgPosX1 + 80}
              y={arrowPosition.y + svgPosY1 - 20}
            />
            <Image image={imageSVGReactivePowerSecondaryPart}
              listening={false}
              x={arrowPosition.x + svgPosX2 + 80}
              y={arrowPosition.y + svgPosY2 - 20}
            />
          </>
        }

        {
          !isHiddenThirdPart &&
          <>
            <Image
              image={imageSVGActivePowerThirdPart}
              listening={false}
              x={arrowPosition.x + svgPosX1}
              y={arrowPosition.y + svgPosY1 - 20}
            />
            <Image image={imageSVGReactivePowerThirdPart}
              listening={false}
              x={arrowPosition.x + svgPosX2}
              y={arrowPosition.y + svgPosY2 - 20}
            />
          </>
        }
        </>
      }
        { showPowerDirectInfo &&
            <>
                <Image  
                    image={imageSVGActiveReactivePower} 
                    listening={false}
                    x={arrowPosition.x + svgPosX3}
                    y={arrowPosition.y + svgPosY3}
                />
                <Image
                    image={imageSVGActiveReactivePowerSecondaryPart}
                    listening={false}
                    x={arrowPosition.x + svgPosX3 - 96}
                    y={arrowPosition.y + svgPosY3}
                />
            </>
        }

      {(properties.partThird.fault && !([Model.ProcessMode.VOLTDROP,Model.ProcessMode.IMPROVE_PF,Model.ProcessMode.HARMONIC,Model.ProcessMode.POWER_FLOW].includes(processMode)) && !m_bModePM) && (
        <>
        <Image
          ref={p3RightArrowRef}
          listening={false}
          offsetY={[90, -90].includes(rotation) ? Constant.ELEMENT_SIZE / 4 : 0}
          offsetX={-Constant.ELEMENT_SIZE / 2 + 2}
          x={arrowPosition.x}
          y={arrowPosition.y}
          image={rightArrowObj}
          width={Constant.ELEMENT_SIZE * 2}
          height={Constant.ELEMENT_SIZE * 2}
        />
         <Text
          text={properties.partThird.pointText}
          fontSize={10}
          fill="red"
          offsetY={[90, -90].includes(rotation) ? Constant.ELEMENT_SIZE / 4 - 2.5 : -2.5}
          offsetX={0}
          x={arrowPosition.x - 20}
          y={arrowPosition.y - 2.5}
        />
        </>
        
      )}
      {(properties.partSecondary.dropRegular &&!([Model.ProcessMode.CALCULATION,Model.ProcessMode.IMPROVE_PF,Model.ProcessMode.HARMONIC,Model.ProcessMode.POWER_FLOW].includes(processMode)) && !m_bModePM) && (
        <Image
          ref={p2LeftArrowRef}
          listening={false}
          offsetY={[90, -90].includes(rotation) ? Constant.ELEMENT_SIZE / 4 - 2.5 : -2.5}
          offsetX={-4}
          x={arrowPosition.x}
          y={arrowPosition.y}
          image={leftArrowObj}
          width={Constant.ELEMENT_SIZE / 2}
          height={Constant.ELEMENT_SIZE}
        />
      )}
      {(properties.partSecondary.fault  && !([Model.ProcessMode.VOLTDROP,Model.ProcessMode.IMPROVE_PF,Model.ProcessMode.HARMONIC,Model.ProcessMode.POWER_FLOW].includes(processMode)) && !m_bModePM) && (
        <>
        <Image
          ref={p2RightArrowRef}
          listening={false}
          offsetY={[90, -90].includes(rotation) ? Constant.ELEMENT_SIZE / 4 : 0}
          offsetX={-Constant.ELEMENT_SIZE / 2 - 4}
          x={arrowPosition.x}
          y={arrowPosition.y}
          image={rightArrowObj}
          width={Constant.ELEMENT_SIZE * 2}
          height={Constant.ELEMENT_SIZE * 2}
        />
        <Text
          text={properties.partSecondary.pointText}
          fontSize={10}
          fill="red"
          offsetY={[90, -90].includes(rotation) ? Constant.ELEMENT_SIZE / 4 : 0}
          offsetX={-Constant.ELEMENT_SIZE / 2 - 4}
          x={arrowPosition.x + 10}
          y={arrowPosition.y}
        />
        </> 
      )}

      <Transformer
        ref={transformRef}
        rotateEnabled={false}
        resizeEnabled={false}
        keepRatio={false}
        borderDash={[4]}
        anchorStroke="black"
        borderStroke="black"
        anchorFill="black"
      />

      <Transformer
        ref={transformMoveRef}
        rotateEnabled={false}
        resizeEnabled={false}
        keepRatio={false}
        borderEnabled={false}
        anchorStroke="black"
        borderStroke="black"
        anchorFill="black"
      />
      {loadingFlag && isSelected && (
                
                <Html>
                  <Dialog open={true} maxWidth={"md"} fullWidth>
                  <div id="loadingFlag" style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1500, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                      <CircularProgress />
                  </div>
                  </Dialog>
                </Html>
      )}
      {isOpenEditorDialog && !isHidden && (
        <Html>
          <Dialog open={true} fullWidth PaperComponent={DraggablePaper}>
            <DialogTitle
              onMouseOver={(e : any) => e.target.style.cursor = 'move'}
              style={{
                paddingTop: "2px",
                paddingBottom: "2px",
                background: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
              }}
            >
              三巻線変圧器
            </DialogTitle>
            <DialogContent>
              <Ms3WindingEditDialog
                data={initialValue}
                onCancel={handleCancelEditorDialog}
                onOK={handleUpdate}
                infoCircuit={infoCircuit}
                userRole={userRole}
                m_bModePM={m_bModePM}
                modeViewOnly={modeViewOnly}
                dispInputDialog={dispInputDialog}
                processMode={processMode}
              ></Ms3WindingEditDialog>
            </DialogContent>
          </Dialog>
        </Html>
      )}

      {/* ============== USER CURVE ============== */}
      {isOpenUserCurvesDialog && !isHidden && (
      <Html>
        <UserCurvesDialog
        type={type}
        listUserCurves={listUserCurves}
        addUserCurve={handleAddUserCurve}
        updateUserCurve={handleUpdateUserCurve}
        deleteUserCurve={handleDeleteUserCurve}
        controlId={id}
        controlProperties={properties}
        modeViewOnly={modeViewOnly}
        onCancel={() => {setIsOpenUserCurvesDialog(false); openUserCurveEvent('')}}
        onDraw={(data : any) => {   
          setOpenChart(true, LineType.USER_CURVE, data.userCurveId??data.user_curve_id)
          setIsOpenUserCurvesDialog(false)
        }}
        />
      </Html>
      )}

      {isOpenUserCurveProperties && !isHidden &&
        <Html>
            <UserCurveDetailDialog modeViewOnly={modeViewOnly} type={type} selectedRow={selectItem} properties={properties} onOK={handleOKUserCurveDetailForm} onCancel={handleCancelUserCurveDetailForm}></UserCurveDetailDialog>
        </Html>
      }
    </>
  );
});

const mapStateToProps = (state: any) => {
  return {
    userId: state.app.user?.userId,
    processMode: state.app.diagram.processMode,
    diagramDataList: state.app.diagram.diagramData,
    diagramData: state.app.diagram.diagramData.filter((r: any) => r.tabId === state.app.diagram.currentIDDiagramTab),
    mainDiagramData: state.app.diagram.diagramData.find((r: any) => r.tabId === 1),
    projectId: state.app.projectData.projectId,
    currentTabId: state.app.diagram.currentIDDiagramTab,
    openDialogData : state.app.diagram.openDialog["OPEN_DIALOG"],
    infoCircuit: state.app.diagram.infoCircuit,
    openUserCurveData: state.app.diagram.openUserCurve["OPEN_USER_CURVE"],
    userCurveProperties: state.app.diagram.openUserCurveProperties["OPEN_USER_CURVE_PROPERTIES"],
    clearMenuState: state.app.diagram.clearOptionMenu,
    userRole: state.app.user?.userRole,
    m_bModePM: state.app.diagram.m_bModePM,
    modeViewOnly: state.app.diagram.modeViewOnly,
    infoSkeleton:state.app.diagram.infoSkeleton,
    drawPowerArrow: state.app.diagram.isDrawPowerArrow,
    isSelectTouchGroup: state.app.diagram.isSelectTouchGroup,
    diagramDataUpdate: state.app.diagram.diagramDataUpdate,
    projectData:state.app.projectData,
    tcGroups: state.app.diagram.tcGroups,
    elementGroups: state.app.diagram.elementGroups,
    elementReposition: state.app.diagram.elementReposition,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    updateSelectControl: (controlId: string, isMultiple : boolean = false) =>
      dispatch(updateSelectControl(controlId, isMultiple)),
    updateControlData: (id: string, data: object) =>
      dispatch(updatePropertiesOfControlAction(id, data)),
    updateTransCenterProps: (id: string, data: object) =>
      dispatch(updatePropertiesOfTransCenter(id, data)),
    updateControlHeight: (
      id: string,
      height: number,
      x: number,
      y: number,
      offsetY: number
    ) => dispatch(updateControlHeight(id, height, x, y, offsetY)),
    setTransformFlag: (visible: boolean) => dispatch(setTransformFlag(visible)),
    // getElementParam: (data: any,id:any) => dispatch(actionCreators.fetch(`GET_3WINDING_${id}`, "/diagram/get-param", "POST", data, false, true)),
    // updateElementPost: (data: any, controlId: string) => dispatch(actionCreators.fetch(`UPDATE_3WINDING_${controlId}`, "/diagram/set-param", "POST", data, false, true)),
    // addUserCurveDB: (controlId:string,data : any) => dispatch(actionCreators.fetch(`ADD_CURVE_USER_3WINDING${controlId}`, "/diagram/add-curve-user", "POST", data, false, true)),
    // updateUserCurveDB: (controlId:string,data : any) => dispatch(actionCreators.fetch(`UPDATE_CURVE_USER_3WINDING${controlId}`, "/diagram/edit-curve-user", "POST", data, false, true)),
    // deleteUserCurveDB: (controlId:string,data : any) => dispatch(actionCreators.fetch(`DELETE_CURVE_USER_3WINDING${controlId}`, "/diagram/delete-curve-user", "POST", data, false, true)),
    addUserCurve: (controlId : string, data : any) => dispatch(addUserCurve(controlId, data)),
    updateUserCurve: (controlId : string, data : any) => dispatch(updateUserCurve(controlId, data)),
    deleteUserCurve: (controlId : string, data : any) => dispatch(deleteUserCurve(controlId, data)),
    setOpenChart: (isOpenChart: boolean, lineType : string, lineId : number) => dispatch(openChart(isOpenChart, lineType, lineId)),
    clearOptionMenu: (state:boolean) => dispatch(clearOptionMenu(state)),
    saveAllControlsToDiagram: (data: DiagramModel, flag: boolean) => dispatch(saveAllControlsToDiagram(data, flag)),
    openDialogEvents: (param : any) => dispatch(openDialogEvents(param)),
    mappedDataFromBE: (elements:any) => dispatch(actionCreators.mappedData(elements)),
    openUserCurveEvent: (params: any) => dispatch(openUserCurveEvents({type: "OPEN_USER_CURVE", data: { id: params }})),
    openUserCurvePropertiesEvent: (controlId: any, userCurveId: any) => dispatch(openUserCurvePropertiesEvents({type: "OPEN_USER_CURVE_PROPERTIES", data: { controlId: controlId, userCurveId: userCurveId }})),
    deleteRelatedGraphByUserCurveDialog: (params:any) => dispatch(deleteRelatedGraphByUserCurveDialog(params)),
    setUndoData: (data:Model.UndoModel) => dispatch(undo(data)),
    setRepositionElement: (id:any, state:boolean) => dispatch(setRepositionElement(id, state)),
    updateElementPosition:(id:any, x:any, y:any) => dispatch(updateElementPosition(id, x, y)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateMs3Winding);
