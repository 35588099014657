import { ElementImages } from '../components/CreateElement'
import { CALC_PER_Z_TYPE_DIRECT, CALC_PER_Z_TYPE_DIRECT_MG, CALC_PER_Z_TYPE_MOTOR, CALC_PER_Z_TYPE_TOTAL, CALC_PER_Z_TYPE_TOTAL_MG, CALC_PER_Z_TYPE_ZEROCOND, ELEMENT_SIZE, MS_PI, MS_TRANS1, MS_TRANSSCOTT, POINTER_HEIGHT, POINTER_WIDTH } from '../models/Constants'
import * as EKind from '../models/ElementKind'
import {
  CalcPoint,
  ControlModel,
  DiagramModel,
  Iele3WindingPart,
  InfoCover,
  MSIntoPointModel,
  Ms2EModel,
  Ms3WindingModel,
  MsFUSEModel,
  MsGeneratorModel,
  MsHVCBModel,
  MsINVModel,
  MsLVCBModel,
  MsSourceModel,
  MsTextModel,
  MsThermalCTModel,
  MsThermalModel,
  MsTrans1Model,
  MsTrans1PartModel,
  MsTrans3Model,
  MsTransScottModel,
  MsTransScottPartModel,
  MsWireModel,
  PartDetailsModel,
  PercentZ,
  WirePhase,
} from '../models/Index'
import { 
  DEFAULT_INTO_POINT_POSITION,
  MS_OUTSIDE_NEUTRAL,
  MS_OUTSIDE_OUTSIDE,
  MS_SEAT_MAIN,
  MS_SEAT_T,
  VOLT_SIDE_PRIMARY,
  VOLT_SIDE_SECONDARY,
  VOLT_SIDE_THIRD,
} from '../statics'
import { 
  getBottomControl, 
  getTopControl,
  getLeftControl,
  getRightControl,
} from './CollisionDetection'
import { convertBaseFromBEToFE, convertPartsFromBEToFE, doConvertDataUserCurve } from './DataConverter'
import { BeamsNumber } from './FormatNumber'
import { 
  doGetValuePercentRe,
  doGetValuePercentXe,
  msSourceNormalCalculation, 
  msTrans1GetDropPerUnit, 
  msTrans1GetDropVoltDiff, 
  msTrans1GetInrushValue, 
  msTrans1GetIntensityValue, 
  msTrans1GetRatedCurrent, 
  msTrans3GetDropPerUnit, 
  msTrans3GetDropVoltDiff, 
  msTrans3GetInrushValue, 
  msTrans3GetIntensityValue,
  msTrans3GetRatedCurrent,
  msTransNormalCalculation,
  msTransScottGetDropPerUnit,
  msTransScottGetDropVoltDiff,
  msTransScottGetInrushValue,
  msTransScottGetIntensityValue,
  msTransScottGetRatedCurrent,
} from './PowerCalculation'
import { getHeadControl } from './TransCenterFunction'
import { doConvertDataCalcPoint } from './calcPointFunction'
import { doGetTextPercentZe, doGetTextXr } from './generatorFunction'
import { doGetLoadCurrent } from './lightBoard'
import { doGetValueLoadCurrent } from './powerBoard'
import { groupBy } from './groupBy'
import { convertDataWindingPart } from './windingPartFunction'
import { convertWirePhaseDataFromBE } from './wireFunction'
import { AnyAction } from 'redux'
import { doGetSubExisting, doGetSubIncrease, doGetSubNew, doGetValuPowerTotal, doGetValueHeatTotal, doGetValueLightTotal, doGetValueSubTotal } from './infoCover1'
import { doCalcCoverRated, handleCurrent, harmonicListMap } from './HarmonicFunction'
import { getTextWidth } from './TextUtil'

const type0VoltageArray = [
  EKind.MS_CONNECT,
  EKind.MS_TEXT
]

const type2VoltageArray = [
  EKind.MS_TRANS1,
  EKind.MS_TRANS3,
  EKind.MS_TRANSSCOTT
]

// type has at least 2 props: sPhase, upTransKind
const typeHave2StateCircuit = [
  EKind.MS_TRANSCENTER,
  EKind.MS_IMPEDANCE,
  EKind.MS_BUSDUCT,
  EKind.MS_BUSBAR,
  EKind.MS_WIRE,
  EKind.MS_MVCB,
  EKind.MS_CONTACTOR,
  EKind.MS_LVCB,
  EKind.MS_2E,
  EKind.MS_THERMAL,
  EKind.MS_MOTOR,
  EKind.MS_LOAD,
  EKind.MS_LOADCENTER,
  EKind.MS_ZEROCOND,
  EKind.MS_CONNECT,
  EKind.MS_THERMAL_CT,
  EKind.MS_CABLEHEAD,
  EKind.MS_CT,
  EKind.MS_LIGHTBOARD,
  EKind.MS_POWERBOARD,

  EKind.MS_INTO_POINT,
]

// type have all sPhase, upTransKind, downTransKind
const typeHave3StateCircuit = [
  EKind.MS_IMPEDANCE,
  EKind.MS_BUSDUCT,
  EKind.MS_BUSBAR,
  EKind.MS_WIRE,
  EKind.MS_MVCB,
  EKind.MS_CONTACTOR,
  EKind.MS_LVCB,
  EKind.MS_2E,
  EKind.MS_THERMAL,
  EKind.MS_THERMAL_CT,
]

// type have only downTransKind
const typeOnlyDownTransKind = [
  EKind.MS_REACTOR,
  EKind.MS_HVCB,
  EKind.MS_RELAY51,
  EKind.MS_FUSE,
  EKind.MS_LBS,
  EKind.MS_DS,
]

const setVoltBottomBreakType = [
  EKind.MS_TRANS3,
  EKind.MS_3WINDING,
  EKind.MS_TRANS1,
  EKind.MS_TRANSSCOTT,
  EKind.MS_MOTOR_GROUP,
  EKind.MS_MOTOR,
  EKind.MS_LOAD,
  EKind.MS_LIGHTBOARD,
  EKind.MS_POWERBOARD,
  EKind.MS_LOADCENTER,
  EKind.MS_CAPACITOR,
]

const setVoltTopBreakType = [
  EKind.MS_SOURCE,
  EKind.MS_GENERATOR,
  EKind.MS_TRANS3,
  EKind.MS_TRANS1,
  EKind.MS_TRANSSCOTT,
]

export const doGetCircuitVolt = (control: ControlModel, voltSide: number) => {
  if (type0VoltageArray.includes(control.type))
    return 0

  if (type2VoltageArray.includes(control.type)){
    if (voltSide === VOLT_SIDE_PRIMARY)
      return control.properties.voltagePrimary
    else
      return control.properties.voltageSecondary
  }

  if (control.type === EKind.MS_3WINDING){
    if (voltSide === VOLT_SIDE_PRIMARY)
      return control.properties.partPrimary.voltage
    else if (voltSide === VOLT_SIDE_SECONDARY)
      return control.properties.partSecondary.voltage
    else
      return control.properties.partThird.voltage
  }
  // others
  return control.properties.voltage
}

const doSetCircuitVolt = (control: ControlModel, voltSide: number, voltage: number) => {
  if (type0VoltageArray.includes(control.type))
    return control

  if (type2VoltageArray.includes(control.type)){
    if (voltSide === VOLT_SIDE_PRIMARY)
      control.properties.voltagePrimary = voltage
    else
      control.properties.voltageSecondary = voltage
  } else if (control.type === EKind.MS_3WINDING){
    if (voltSide === VOLT_SIDE_PRIMARY)
      control.properties.partPrimary.voltage = voltage
    else if (voltSide === VOLT_SIDE_SECONDARY)
      control.properties.hvcbPartsecondary.voltage = voltage
    else
      control.properties.partThird.voltage = voltage
  } else {
    // others
    control.properties.voltage = voltage
  }

  return control
}

export const isSPhaseCircuitEle = (control: ControlModel, voltSide: number) => {
  let sPhase = false
  let upTransKind = EKind.MS_ELEMENT_ALL

  if ([EKind.MS_TRANS1, EKind.MS_TRANSSCOTT].includes(control.type)){
    if (voltSide === VOLT_SIDE_SECONDARY){
      sPhase = true
      upTransKind = control.type
    }
  } else if (typeHave2StateCircuit.includes(control.type)){
    sPhase = control.properties.sPhase
    upTransKind = control.properties.upTransKind
  }

  return { sPhase, upTransKind }
}

export const getTextVoltageEle = (
  voltage: number,
  sPhase: boolean,
  partKind: number,
  unit: boolean
) => {
  let text = ''

  if (sPhase){
    if (partKind === MS_OUTSIDE_NEUTRAL)
      text = Math.trunc(voltage / 2).toString()
    else if ([MS_OUTSIDE_OUTSIDE, MS_SEAT_MAIN, MS_SEAT_T].includes(partKind))
      text = voltage.toString()
  } else {
    text = voltage.toString()
  }

  if (unit) text += 'V'

  return text
}

const doSetStateCircuitEle = (
  target: ControlModel,
  sPhase: boolean,
  upTransKind: string,
  downTransKind: string,
) => {
  let retTarget: ControlModel = {...target}
  if (typeHave2StateCircuit.includes(target.type)){
    retTarget.properties.sPhase = sPhase
    retTarget.properties.upTransKind = upTransKind

    if (typeHave3StateCircuit.includes(target.type))
      retTarget.properties.downTransKind = downTransKind
  }
  else if (typeOnlyDownTransKind.includes(target.type))
    retTarget.properties.downTransKind = downTransKind
  
  return retTarget
}

// *** Set voltage to other control *** ///
export const doSetSameVoltToBottom = (
  diagramList: DiagramModel[],
  currentTabId: number,
  targetID: string,
  voltage: number,
  sPhase: boolean,
  upTransKind: string,
) => {
  if (diagramList.find((e: DiagramModel) => e.tabId === currentTabId) === undefined)
    return {code: 1, message: `Could not find diagram in array with tabId: ${currentTabId}`}
  let tmpDiagram = diagramList.find((e: DiagramModel) => e.tabId === currentTabId)!
  let diagramData = structuredClone(tmpDiagram.shape)

  if (diagramData.find((e: ControlModel) => e.id === targetID) === undefined)
    return {code: 2, message: `Could not find control in array with id: ${targetID}`}
  
  let retTransCenter: DiagramModel[] = []
  let retDiagram = [...diagramData]
  let target: ControlModel = diagramData.find((e: ControlModel) => e.id === targetID)!
  diagramData = diagramData.filter((e: ControlModel) => e.id !== targetID)

  let bottomControl = getBottomControl(target, diagramData)
  while(bottomControl.code === 0){
    let control: ControlModel = bottomControl.data
    diagramData = diagramData.filter((e: ControlModel) => e.id !== control.id)

    let controlIndex: number = retDiagram.findIndex((e) => e.id === control.id)
    let updateControl: ControlModel = retDiagram[controlIndex]

    // doSetStateCircuitEle
    updateControl = doSetStateCircuitEle(
      updateControl,
      sPhase,
      upTransKind,
      EKind.MS_ELEMENT_ALL
    )
    // doSetCircuitVolt
    updateControl = doSetCircuitVolt(updateControl, VOLT_SIDE_PRIMARY, voltage)
    retDiagram[controlIndex] = updateControl

    if (control.type === EKind.MS_ZEROCOND){
      let tmp = doSetSameVoltZerocond(
        diagramList,
        control, 
        diagramData, 
        voltage, 
        sPhase, 
        EKind.MS_ELEMENT_ALL
      )
      retDiagram.map(obj => tmp.currentDiagram.find(o => o.id === obj.id) || obj)
      if (tmp.transCenterDiagramList)
        retTransCenter = [...retTransCenter, ...tmp.transCenterDiagramList]
      break
    }
    if (setVoltBottomBreakType.includes(control.type)) break
    if (control.type === EKind.MS_TRANSCENTER){
      let transCenterTabId = control.properties.tabId
      if (diagramList.find(
        (e: DiagramModel) => e.tabId === transCenterTabId) === undefined
      )
        return {code: 1, message: `Could not find diagram in array with tabId: ${transCenterTabId}`}
      let tmpDiagram2 = diagramList.find(
        (e: DiagramModel) => e.tabId === transCenterTabId
      )!
      let diagramData2 = structuredClone(tmpDiagram2.shape)
      let transCenterDiagram = doSetSameVoltTransCenter(
        diagramData2,
        voltage,
        sPhase,
        upTransKind
      )
      retTransCenter.push({
        tabId: transCenterTabId, 
        shape: transCenterDiagram
      })

      break
    }

    bottomControl = getBottomControl(control, diagramData)
  }

  if (retTransCenter.length > 0)
    return {
      code: 0,
      data: {
        currentDiagram: retDiagram,
        transCenterDiagramList: retTransCenter
      }
    }
  return {code: 0, data: {currentDiagram: retDiagram}}
}

export const doSetSameVoltToTop = (
  diagramList: DiagramModel[],
  currentTabId: number,
  targetID: string,
  voltage: number,
  sPhase: boolean,
) => {
  if (diagramList.find((e: DiagramModel) => e.tabId === currentTabId) === undefined)
    return {code: 1, message: `Could not find diagram in array with tabId: ${currentTabId}`}
  let tmpDiagram = diagramList.find((e: DiagramModel) => e.tabId === currentTabId)!
  let diagramData = structuredClone(tmpDiagram.shape)
  
  if (diagramData.find((e: any) => e.id === targetID) === undefined)
    return {code: 2, message: `Could not find control in array with id: ${targetID}`}
  
  let retTransCenter: DiagramModel[] = []
  let retDiagram = [...diagramData]
  let target: ControlModel = diagramData.find((e: any) => e.id === targetID)!
  diagramData = diagramData.filter((e: any) => e.id !== targetID)

  let topControl = getTopControl(target, diagramData)
  while(topControl.code === 0){
    let control: ControlModel = topControl.data
    diagramData = diagramData.filter((e: any) => e.id !== control.id)

    let controlIndex: number = retDiagram.findIndex((e) => e.id === control.id)
    let updateControl: ControlModel = retDiagram[controlIndex]

    // doSetStateCircuitEle
    updateControl = doSetStateCircuitEle(
      updateControl,
      sPhase,
      EKind.MS_ELEMENT_ALL,
      target.type
    )
    // doSetCircuitVolt
    updateControl = doSetCircuitVolt(updateControl, VOLT_SIDE_SECONDARY, voltage)
    retDiagram[controlIndex] = updateControl

    if (control.type === EKind.MS_ZEROCOND){
      let tmp = doSetSameVoltZerocond(
        diagramList,
        control, 
        diagramData, 
        voltage, 
        sPhase, 
        EKind.MS_ELEMENT_ALL
      )
      retDiagram.map(obj => tmp.currentDiagram.find(o => o.id === obj.id) || obj)
      if (tmp.transCenterDiagramList)
        retTransCenter = [...retTransCenter, ...tmp.transCenterDiagramList]
      break
    }
    if (setVoltTopBreakType.includes(control.type)) break

    topControl = getTopControl(control, diagramData)
  }

  if (retTransCenter.length > 0)
    return {
      code: 0,
      data: {
        currentDiagram: retDiagram,
        transCenterDiagramList: retTransCenter
      }
    }
  return {code: 0, data: {currentDiagram: retDiagram}}
}

export const doSetSameVoltToLeft = (
  diagramList: DiagramModel[],
  currentTabId: number,
  targetID: string,
  voltage: number,
  sPhase: boolean,
) => {
  if (diagramList.find((e: DiagramModel) => e.tabId === currentTabId) === undefined)
    return {code: 1, message: `Could not find diagram in array with tabId: ${currentTabId}`}
  let tmpDiagram = diagramList.find((e: DiagramModel) => e.tabId === currentTabId)!
  let diagramData = structuredClone(tmpDiagram.shape)

  if (diagramData.find((e: any) => e.id === targetID) === undefined)
    return {code: 2, message: `Could not find control in array with id: ${targetID}`}
  
  let retTransCenter: DiagramModel[] = []
  let retDiagram = [...diagramData]
  let target: ControlModel = diagramData.find((e: any) => e.id === targetID)!

  diagramData = diagramData.filter((e: any) => e.id !== targetID)

  let leftControl = getLeftControl(target, diagramData)
  while(leftControl.code === 0){
    let control: ControlModel = leftControl.data
    diagramData = diagramData.filter((e: any) => e.id !== control.id)

    let controlIndex: number = retDiagram.findIndex((e) => e.id === control.id)
    let updateControl: ControlModel = retDiagram[controlIndex]

    // doSetStateCircuitEle
    updateControl = doSetStateCircuitEle(
      updateControl,
      sPhase,
      EKind.MS_ELEMENT_ALL,
      EKind.MS_ELEMENT_ALL
    )
    // doSetCircuitVolt
    if (control.type === EKind.MS_3WINDING)
      updateControl = doSetCircuitVolt(updateControl, VOLT_SIDE_SECONDARY, voltage)
    else
      updateControl = doSetCircuitVolt(updateControl, VOLT_SIDE_PRIMARY, voltage)
    retDiagram[controlIndex] = updateControl

    if (control.type === EKind.MS_ZEROCOND){
      let tmp = doSetSameVoltZerocond(
        diagramList,
        control, 
        diagramData, 
        voltage, 
        sPhase, 
        EKind.MS_ELEMENT_ALL
      )
      retDiagram.map(obj => tmp.currentDiagram.find(o => o.id === obj.id) || obj)
      if (tmp.transCenterDiagramList)
        retTransCenter = [...retTransCenter, ...tmp.transCenterDiagramList]
      break
    }

    leftControl = getLeftControl(control, diagramData)
  }

  if (retTransCenter.length > 0)
    return {
      code: 0,
      data: {
        currentDiagram: retDiagram,
        transCenterDiagramList: retTransCenter
      }
    }
  return {code: 0, data: {currentDiagram: retDiagram}}
}

export const doSetSameVoltToRight = (

  diagramList: DiagramModel[],
  currentTabId: number,
  targetID: string,
  voltage: number,
  sPhase: boolean,
) => {
  if (diagramList.find((e: DiagramModel) => e.tabId === currentTabId) === undefined)
    return {code: 1, message: `Could not find diagram in array with tabId: ${currentTabId}`}
  let tmpDiagram = diagramList.find((e: DiagramModel) => e.tabId === currentTabId)!
  let diagramData = structuredClone(tmpDiagram.shape)

  if (diagramData.find((e: any) => e.id === targetID) === undefined)
    return {code: 2, message: `Could not find control in array with id: ${targetID}`}
  
  let retTransCenter: DiagramModel[] = []
  let retDiagram = [...diagramData]
  let target: ControlModel = diagramData.find((e: any) => e.id === targetID)!

  diagramData = diagramData.filter((e: any) => e.id !== targetID)

  let rightControl = getRightControl(target, diagramData)
  while(rightControl.code === 0){
    let control: ControlModel = rightControl.data
    diagramData = diagramData.filter((e: any) => e.id !== control.id)

    let controlIndex: number = retDiagram.findIndex((e) => e.id === control.id)
    let updateControl: ControlModel = retDiagram[controlIndex]

    // doSetStateCircuitEle
    updateControl = doSetStateCircuitEle(
      updateControl,
      sPhase,
      EKind.MS_ELEMENT_ALL,
      EKind.MS_ELEMENT_ALL
    )
    // doSetCircuitVolt
    if (control.type === EKind.MS_3WINDING)
      updateControl = doSetCircuitVolt(updateControl, VOLT_SIDE_THIRD, voltage)
    else
      updateControl = doSetCircuitVolt(updateControl, VOLT_SIDE_PRIMARY, voltage)
    retDiagram[controlIndex] = updateControl

    if (control.type === EKind.MS_ZEROCOND){
      let tmp = doSetSameVoltZerocond(
        diagramList,
        control, 
        diagramData, 
        voltage, 
        sPhase, 
        EKind.MS_ELEMENT_ALL
      )
      retDiagram.map(obj => tmp.currentDiagram.find(o => o.id === obj.id) || obj)
      if (tmp.transCenterDiagramList)
        retTransCenter = [...retTransCenter, ...tmp.transCenterDiagramList]
      break
    }

    rightControl = getRightControl(control, diagramData)
  }

  if (retTransCenter.length > 0)
    return {
      code: 0,
      data: {
        currentDiagram: retDiagram,
        transCenterDiagramList: retTransCenter
      }
    }
  return {code: 0, data: {currentDiagram: retDiagram}}
}

const doSetSameVoltZerocond = (
  diagramList: DiagramModel[],
  target: ControlModel,
  diagramData: ControlModel[],
  voltage: number,
  sPhase: boolean,
  upTransKind: string,
) => {
  let retTransCenter: DiagramModel[] = []
  let retDiagram = [...diagramData]
  diagramData = diagramData.filter((e: any) => e.id !== target.id)

  //-- top
  let topControl = getTopControl(target, diagramData)
  while(topControl.code === 0){
    let control: ControlModel = topControl.data
    diagramData = diagramData.filter((e: any) => e.id !== control.id)

    let controlIndex: number = retDiagram.findIndex((e) => e.id === control.id)
    let updateControl: ControlModel = retDiagram[controlIndex]

    // doSetStateCircuitEle
    updateControl = doSetStateCircuitEle(
      updateControl,
      sPhase,
      upTransKind,
      EKind.MS_ELEMENT_ALL
    )
    // doSetCircuitVolt
    updateControl = doSetCircuitVolt(updateControl, VOLT_SIDE_SECONDARY, voltage)
    retDiagram[controlIndex] = updateControl
    
    //- toptop
    let continueFlag = true
    if (setVoltTopBreakType.includes(control.type))
      continueFlag = false
    
    let topTopControl = getTopControl(control, diagramData)
    while(continueFlag && topTopControl.code === 0){
      let control2: ControlModel = topTopControl.data
      diagramData = diagramData.filter((e: any) => e.id !== control2.id)

      let controlIndex: number = retDiagram.findIndex((e) => e.id === control2.id)
      let updateControl: ControlModel = retDiagram[controlIndex]

      // doSetStateCircuitEle
      updateControl = doSetStateCircuitEle(
        updateControl,
        sPhase,
        upTransKind,
        EKind.MS_ELEMENT_ALL
      )
      // doSetCircuitVolt
      updateControl = doSetCircuitVolt(updateControl, VOLT_SIDE_SECONDARY, voltage)
      retDiagram[controlIndex] = updateControl

      if (control2.type === EKind.MS_ZEROCOND){
        let tmp = doSetSameVoltZerocond(
          diagramList,
          control2, 
          diagramData,
          voltage, 
          sPhase, 
          upTransKind
        )
        retDiagram.map(obj => tmp.currentDiagram.find(o => o.id === obj.id) || obj)
        if (tmp.transCenterDiagramList)
          retTransCenter = [...retTransCenter, ...tmp.transCenterDiagramList]
        break
      }
      if (setVoltTopBreakType.includes(control2.type)) break
      
      topTopControl = getTopControl(control2, diagramData)
    }

    topControl = getTopControl(target, diagramData)
  }

  //-- bottom
  let bottomControl = getBottomControl(target, diagramData)
  while(bottomControl.code === 0){
    let control: ControlModel = bottomControl.data
    diagramData = diagramData.filter((e: any) => e.id !== control.id)

    let controlIndex: number = retDiagram.findIndex((e) => e.id === control.id)
    let updateControl: ControlModel = retDiagram[controlIndex]

    // doSetStateCircuitEle
    updateControl = doSetStateCircuitEle(
      updateControl,
      sPhase,
      upTransKind,
      EKind.MS_ELEMENT_ALL
    )
    // doSetCircuitVolt
    updateControl = doSetCircuitVolt(updateControl, VOLT_SIDE_PRIMARY, voltage)
    retDiagram[controlIndex] = updateControl

    if (control.type === EKind.MS_TRANSCENTER) {
      let transCenterTabId = control.properties.tabId
      if (diagramList.find(
        (e: DiagramModel) => e.tabId === transCenterTabId) === undefined
      )
        throw new Error('Could not find diagram in array with tabId: ' + transCenterTabId)
      
      let tmpDiagram2 = diagramList.find(
        (e: DiagramModel) => e.tabId === transCenterTabId
      )!
      let diagramData2 = structuredClone(tmpDiagram2.shape)
      let transCenterDiagram = doSetSameVoltTransCenter(
        diagramData2,
        voltage,
        sPhase,
        upTransKind
      )
      retTransCenter.push({
        tabId: transCenterTabId, 
        shape: transCenterDiagram
      })
    }
    //- bottombottom
    let continueFlag = true
    if (setVoltBottomBreakType.includes(control.type))
      continueFlag = false
    let bottomBottomControl = getBottomControl(control, diagramData)
    while(continueFlag && bottomBottomControl.code === 0){
      let control2: ControlModel = bottomBottomControl.data
      diagramData = diagramData.filter((e: any) => e.id !== control2.id)

      let controlIndex: number = retDiagram.findIndex((e) => e.id === control2.id)
      let updateControl: ControlModel = retDiagram[controlIndex]

      // doSetStateCircuitEle
      updateControl = doSetStateCircuitEle(
        updateControl,
        sPhase,
        upTransKind,
        EKind.MS_ELEMENT_ALL
      )
      // doSetCircuitVolt
      updateControl = doSetCircuitVolt(updateControl, VOLT_SIDE_PRIMARY, voltage)
      retDiagram[controlIndex] = updateControl

      if (control2.type === EKind.MS_ZEROCOND){
        let tmp = doSetSameVoltZerocond(
          diagramList,
          control2,
          diagramData,
          voltage,
          sPhase,
          upTransKind
        )
        retDiagram.map(obj => tmp.currentDiagram.find(o => o.id === obj.id) || obj)
        if (tmp.transCenterDiagramList)
          retTransCenter = [...retTransCenter, ...tmp.transCenterDiagramList]
        break
      }
      if (setVoltBottomBreakType.includes(control2.type)) break
      if (control2.type === EKind.MS_TRANSCENTER) {
        let transCenterTabId = control2.properties.tabId
        if (diagramList.find(
          (e: DiagramModel) => e.tabId === transCenterTabId) === undefined
        )
          throw new Error('Could not find diagram in array with tabId: ' + transCenterTabId)
        
        let tmpDiagram2 = diagramList.find(
          (e: DiagramModel) => e.tabId === transCenterTabId
        )!
        let diagramData2 = structuredClone(tmpDiagram2.shape)
        let transCenterDiagram = doSetSameVoltTransCenter(
          diagramData2,
          voltage,
          sPhase,
          upTransKind
        )
        retTransCenter.push({
          tabId: transCenterTabId, 
          shape: transCenterDiagram
        })

        break
      }
      
      bottomBottomControl = getBottomControl(control2, diagramData)
    }

    bottomControl = getBottomControl(target, diagramData)
  }

  // left
  let leftControl = getLeftControl(target, diagramData)
  while(leftControl.code === 0){
    let control: ControlModel = leftControl.data
    diagramData = diagramData.filter((e: any) => e.id !== control.id)

    let controlIndex: number = retDiagram.findIndex((e) => e.id === control.id)
    let updateControl: ControlModel = retDiagram[controlIndex]

    // doSetStateCircuitEle
    updateControl = doSetStateCircuitEle(
      updateControl,
      sPhase,
      upTransKind,
      EKind.MS_ELEMENT_ALL
    )
    // doSetCircuitVolt
    if (control.type === EKind.MS_3WINDING)
      updateControl = doSetCircuitVolt(updateControl, VOLT_SIDE_SECONDARY, voltage)
    else
      updateControl = doSetCircuitVolt(updateControl, VOLT_SIDE_PRIMARY, voltage)
    retDiagram[controlIndex] = updateControl

    if (control.type === EKind.MS_3WINDING) break
    if (control.type === EKind.MS_ZEROCOND){
      let tmp = doSetSameVoltZerocond(
        diagramList,
        control, 
        diagramData, 
        voltage, 
        sPhase, 
        upTransKind
      )
      retDiagram.map(obj => tmp.currentDiagram.find(o => o.id === obj.id) || obj)
      if (tmp.transCenterDiagramList)
        retTransCenter = [...retTransCenter, ...tmp.transCenterDiagramList]
      break
    }

    leftControl = getLeftControl(control, diagramData)
  }

  // right
  let rightControl = getRightControl(target, diagramData)
  while(rightControl.code === 0){
    let control: ControlModel = rightControl.data
    diagramData = diagramData.filter((e: any) => e.id !== control.id)

    let controlIndex: number = retDiagram.findIndex((e) => e.id === control.id)
    let updateControl: ControlModel = retDiagram[controlIndex]

    // doSetStateCircuitEle
    updateControl = doSetStateCircuitEle(
      updateControl,
      sPhase,
      upTransKind,
      EKind.MS_ELEMENT_ALL
    )
    // doSetCircuitVolt
    if (control.type === EKind.MS_3WINDING)
      updateControl = doSetCircuitVolt(updateControl, VOLT_SIDE_THIRD, voltage)
    else
      updateControl = doSetCircuitVolt(updateControl, VOLT_SIDE_PRIMARY, voltage)
    retDiagram[controlIndex] = updateControl

    if (control.type === EKind.MS_3WINDING) break
    if (control.type === EKind.MS_ZEROCOND){
      let tmp = doSetSameVoltZerocond(
        diagramList,
        control, 
        diagramData, 
        voltage, 
        sPhase, 
        upTransKind
      )
      retDiagram.map(obj => tmp.currentDiagram.find(o => o.id === obj.id) || obj)
      if (tmp.transCenterDiagramList)
        retTransCenter = [...retTransCenter, ...tmp.transCenterDiagramList]
      break
    }

    rightControl = getRightControl(control, diagramData)
  }

  if (retTransCenter.length > 0)
    return {
      currentDiagram: retDiagram,
      transCenterDiagramList: retTransCenter
    }
  return {currentDiagram: retDiagram}
}

const doSetSameVoltTransCenter = (
  diagramData: ControlModel[],
  voltage: number,
  sPhase: boolean,
  upTransKind: string,
) => {
  let retDiagram = [...diagramData]
  let bottomControl = getHeadControl(diagramData)
  while(bottomControl.code === 0){
    let control: ControlModel = bottomControl.data
    diagramData = diagramData.filter((e: any) => e.id !== control.id)

    let controlIndex: number = retDiagram.findIndex((e) => e.id === control.id)
    let updateControl: ControlModel = retDiagram[controlIndex]

    // doSetStateCircuitEle
    updateControl = doSetStateCircuitEle(
      updateControl,
      sPhase,
      upTransKind,
      EKind.MS_ELEMENT_ALL
    )
    // doSetCircuitVolt
    updateControl = doSetCircuitVolt(updateControl, VOLT_SIDE_PRIMARY, voltage)
    retDiagram[controlIndex] = updateControl

    if (control.type === EKind.MS_ZEROCOND){
      let tmp = doSetSameVoltZerocond(
        [],
        control, 
        diagramData, 
        voltage, 
        sPhase, 
        EKind.MS_ELEMENT_ALL
      )
      retDiagram.map(obj => tmp.currentDiagram.find(o => o.id === obj.id) || obj)
      break
    }
    if (setVoltBottomBreakType.includes(control.type)) break

    bottomControl = getBottomControl(control, diagramData)
  }

  // set the same voltage for all controls in TransCenter
  let transCenterDiagram: ControlModel[] = [...retDiagram]

  let msTrans3List = transCenterDiagram.filter((e) => e.type === EKind.MS_TRANS3)
  if (msTrans3List.length > 0){
    msTrans3List.forEach((control: ControlModel) => {
      let tmpVoltage = doGetCircuitVolt(control, VOLT_SIDE_PRIMARY)
      let resultData = doSetSameVoltToTop(
        [{tabId: 0, shape: transCenterDiagram}],
        0,
        control.id,
        tmpVoltage,
        false
      )
      if (resultData.code === 0){
        let newData = resultData.data!
        transCenterDiagram = newData.currentDiagram
        retDiagram = [...newData.currentDiagram]
      }

      tmpVoltage = doGetCircuitVolt(control, VOLT_SIDE_SECONDARY)
      resultData = doSetSameVoltToBottom(
        [{tabId: 0, shape: transCenterDiagram}],
        0,
        control.id,
        tmpVoltage,
        false,
        EKind.MS_ELEMENT_ALL
      )
      if (resultData.code === 0){
        let newData = resultData.data!
        transCenterDiagram = newData.currentDiagram
        retDiagram = [...newData.currentDiagram]
      }
    })
  }

  let ms3WindingList = transCenterDiagram.filter((e) => e.type === EKind.MS_3WINDING)
  if (ms3WindingList.length > 0){
    ms3WindingList.forEach((control: ControlModel) => {
      let tmpVoltage = doGetCircuitVolt(control, VOLT_SIDE_PRIMARY)
      let resultData = doSetSameVoltToTop(
        [{tabId: 0, shape: transCenterDiagram}],
        0,
        control.id,
        tmpVoltage,
        false
      )
      if (resultData.code === 0){
        let newData = resultData.data!
        transCenterDiagram = newData.currentDiagram
        retDiagram = [...newData.currentDiagram]
      }

      tmpVoltage = doGetCircuitVolt(control, VOLT_SIDE_SECONDARY)
      resultData = doSetSameVoltToRight(
        [{tabId: 0, shape: transCenterDiagram}],
        0,
        control.id,
        tmpVoltage,
        false
      )
      if (resultData.code === 0){
        let newData = resultData.data!
        transCenterDiagram = newData.currentDiagram
        retDiagram = [...newData.currentDiagram]
      }
      
      tmpVoltage = doGetCircuitVolt(control, VOLT_SIDE_THIRD)
      resultData = doSetSameVoltToLeft(
        [{tabId: 0, shape: transCenterDiagram}],
        0,
        control.id,
        tmpVoltage,
        false
      )
      if (resultData.code === 0){
        let newData = resultData.data!
        transCenterDiagram = newData.currentDiagram
        retDiagram = [...newData.currentDiagram]
      }
    })
  }

  let msTrans1List = transCenterDiagram.filter((e) => e.type === EKind.MS_TRANS1)
  if (msTrans1List.length > 0){
    msTrans1List.forEach((control: ControlModel) => {
      let tmpVoltage = doGetCircuitVolt(control, VOLT_SIDE_PRIMARY)
      let resultData = doSetSameVoltToTop(
        [{tabId: 0, shape: transCenterDiagram}],
        0,
        control.id,
        tmpVoltage,
        false
      )
      if (resultData.code === 0){
        let newData = resultData.data!
        transCenterDiagram = newData.currentDiagram
        retDiagram = [...newData.currentDiagram]
      }

      tmpVoltage = doGetCircuitVolt(control, VOLT_SIDE_SECONDARY)
      resultData = doSetSameVoltToBottom(
        [{tabId: 0, shape: transCenterDiagram}],
        0,
        control.id,
        tmpVoltage,
        true,
        EKind.MS_TRANS1
      )
      if (resultData.code === 0){
        let newData = resultData.data!
        transCenterDiagram = newData.currentDiagram
        retDiagram = [...newData.currentDiagram]
      }
    })
  }

  let msTransScottList = transCenterDiagram.filter((e) => e.type === EKind.MS_TRANSSCOTT)
  if (msTransScottList.length > 0){
    msTransScottList.forEach((control: ControlModel) => {
      let tmpVoltage = doGetCircuitVolt(control, VOLT_SIDE_PRIMARY)
      let resultData = doSetSameVoltToTop(
        [{tabId: 0, shape: transCenterDiagram}],
        0,
        control.id,
        tmpVoltage,
        false
      )
      if (resultData.code === 0){
        let newData = resultData.data!
        transCenterDiagram = newData.currentDiagram
        retDiagram = [...newData.currentDiagram]
      }

      tmpVoltage = doGetCircuitVolt(control, VOLT_SIDE_SECONDARY)
      resultData = doSetSameVoltToBottom(
        [{tabId: 0, shape: transCenterDiagram}],
        0,
        control.id,
        tmpVoltage,
        true,
        EKind.MS_TRANSSCOTT
      )
      if (resultData.code === 0){
        let newData = resultData.data!
        transCenterDiagram = newData.currentDiagram
        retDiagram = [...newData.currentDiagram]
      }
    })
  }
  // TODO: set connected control for ZeroCond? - MSSV3Doc.cpp :line 12483

  return retDiagram
}

export const getElementKindValue = (elementKind : string) => {
  switch (elementKind) {
    case EKind.MS_ELEMENT_ALL:
      return 0
    case EKind.MS_HVCB:
      return 1
    case EKind.MS_LVCB:
      return 2
    case EKind.MS_THERMAL:
      return 3
    case EKind.MS_SOURCE:
      return 4
    case EKind.MS_TRANS3:
      return 5
    case EKind.MS_GENERATOR:
      return 6
    case EKind.MS_MOTOR_GROUP:
      return 7
    case EKind.MS_ZEROCOND:
      return 8
    case EKind.MS_BUSBAR:
      return 9
    case EKind.MS_WIRE:
      return 10
    case EKind.MS_CONTACTOR:
      return 11
    case EKind.MS_MOTOR:
      return 12
    case EKind.MS_LOAD:
      return 13
    case EKind.MS_CAPACITOR:
      return 14
    case EKind.MS_2E:
      return 15
    case EKind.MS_FUSE:
      return 17
    case EKind.MS_MVCB:
      return 18
    case EKind.MS_REACTOR:
      return 19
    case EKind.MS_3WINDING:
      return 20
    case EKind.MS_BUSDUCT:
      return 21
    case EKind.MS_TRANS1:
      return 22
    case EKind.MS_TEXT:
      return 23
    case EKind.MS_TRANSSCOTT:
      return 24
    case EKind.MS_DS:
      return 25
    case EKind.MS_LBS:
      return 26
    case EKind.MS_THERMAL_CT:
      return 27
    case EKind.MS_ARRESTOR:
      return 28
    case EKind.MS_EARTH:
      return 29
    case EKind.MS_CABLEHEAD:
      return 30
    case EKind.MS_CT:
      return 31
    case EKind.MS_ZCT:
      return 32
    case EKind.MS_VT:
      return 33
    case EKind.MS_VCT:
      return 34
    case EKind.MS_LOADCENTER:
      return 35
    case EKind.MS_TRANSCENTER:
      return 36
    case EKind.MS_LIGHTBOARD:
      return 37
    case EKind.MS_POWERBOARD:
      return 38
    case EKind.MS_INTO_POINT:
      return 39
    case EKind.MS_RELAY51:
      return 40
    case EKind.MS_IMPEDANCE:
      return 41
    case EKind.MS_CONNECT:
      return 42
    case EKind.MS_INV:
      return 43
    case EKind.MS_RELAY:
      return 44
  }
  return elementKind;
}

export const getElementKindLabel = (elementKind : number) => {
  switch (elementKind) {
    case 0:
      return EKind.MS_ELEMENT_ALL
    case 1:
      return EKind.MS_HVCB
    case 2:
      return EKind.MS_LVCB
    case 3:
      return EKind.MS_THERMAL
    case 4:
      return EKind.MS_SOURCE
    case 5:
      return EKind.MS_TRANS3
    case 6:
      return EKind.MS_GENERATOR
    case 7:
      return EKind.MS_MOTOR_GROUP
    case 8:
      return EKind.MS_ZEROCOND
    case 9:
      return EKind.MS_BUSBAR
    case 10:
      return EKind.MS_WIRE
    case 11:
      return EKind.MS_CONTACTOR
    case 12:
      return EKind.MS_MOTOR
    case 13:
      return EKind.MS_LOAD
    case 14:
      return EKind.MS_CAPACITOR
    case 15:
      return EKind.MS_2E
    case 17:
      return EKind.MS_FUSE
    case 18:
      return EKind.MS_MVCB
    case 19:
      return EKind.MS_REACTOR
    case 20:
      return EKind.MS_3WINDING
    case 21:
      return EKind.MS_BUSDUCT
    case 22:
      return EKind.MS_TRANS1
    case 23:
      return EKind.MS_TEXT
    case 24:
      return EKind.MS_TRANSSCOTT
    case 25:
      return EKind.MS_DS
    case 26:
      return EKind.MS_LBS
    case 27:
      return EKind.MS_THERMAL_CT
    case 28:
      return EKind.MS_ARRESTOR
    case 29:
      return EKind.MS_EARTH
    case 30:
      return EKind.MS_CABLEHEAD
    case 31:
      return EKind.MS_CT
    case 32:
      return EKind.MS_ZCT
    case 33:
      return EKind.MS_VT
    case 34:
      return EKind.MS_VCT
    case 35:
      return EKind.MS_LOADCENTER
    case 36:
      return EKind.MS_TRANSCENTER
    case 37:
      return EKind.MS_LIGHTBOARD
    case 38:
      return EKind.MS_POWERBOARD
    case 39:
      return EKind.MS_INTO_POINT
    case 40:
      return EKind.MS_RELAY51
    case 41:
      return EKind.MS_IMPEDANCE
    case 42:
      return EKind.MS_CONNECT
    case 43:
      return EKind.MS_INV
    case 44:
      return EKind.MS_RELAY
  }
}

export const convertRotationValue = (value: number) => {
  const map : { [index: number]: any } = {
    0 : 0,
    1 : -90,
    2 : 90,
    [-90] : 1,
    90 : 2
  }

  return map[value] ?? 0
}

export const getElementCoordinatesData = (element : ControlModel) => {
  const { x, y, rotation, width, height } = element

  if (element.type === EKind.MS_TEXT) 
    return {
      x,
      y,
      rotation: null,
      width: null,
      height: null,
      xMax: x,
      yMax: y,
      canConnectRight: null,
      canConnectBottom: null,
      canConnectLeft: null,
      canConnectTop: null,
      rightConnectPointX: null,
      rightConnectPointY: null,
      leftConnectPointX: null,
      leftConnectPointY: null,
      topConnectPointX: null,
      topConnectPointY: null,
      bottomConnectPointX: null,
      bottomConnectPointY: null,
    };
  else if( element.type === EKind.MS_INTO_POINT){
    return {
      x:x,
      y:DEFAULT_INTO_POINT_POSITION.y,
      rotation: 0,
      width:POINTER_WIDTH,
      height:POINTER_HEIGHT,
      xMax:x + POINTER_WIDTH,
      yMax:0,
      canConnectRight: isCanConnectRight(element),
      canConnectBottom: isCanConnectBottom(element),
      canConnectLeft: isCanConnectLeft(element),
      canConnectTop: isCanConnectTop(element),
      rightConnectPointX:null,
      rightConnectPointY:null,
      leftConnectPointX:null,
      leftConnectPointY:null,
      topConnectPointX:x + POINTER_WIDTH / 2,
      topConnectPointY:-POINTER_HEIGHT,
      bottomConnectPointX:x + POINTER_WIDTH / 2,
      bottomConnectPointY:0,
    }
  }

  let xMax = 0;
  let yMax = 0;
  const canConnectRight = isCanConnectRight(element);
  const canConnectBottom = isCanConnectBottom(element);
  const canConnectLeft = isCanConnectLeft(element);
  const canConnectTop = isCanConnectTop(element);
  let topConnectPointX = x + (width * 0.5);
  let topConnectPointY = y;
  let bottomConnectPointX = x + (width * 0.5);
  let bottomConnectPointY = y + height;
  let leftConnectPointX = x;
  let rightConnectPointX = x + width;
  // width height don't change
  let leftConnectPointY = y + (height * 0.5);
  let rightConnectPointY = y + (height * 0.5);
  
  if(rotation === 90 || rotation === -90){
    xMax = x + height;
    yMax = y + width;
    topConnectPointX = x + (height * 0.5);
    bottomConnectPointX = x + (height * 0.5);
    bottomConnectPointY = x + width;
    rightConnectPointX = x + height;
    leftConnectPointY = y + width / 2;
    rightConnectPointY = y + width / 2;
  }else if(rotation === 0){
    xMax = x + width;
    yMax = y + height;
  }

  if (element.type === EKind.MS_3WINDING) {
    leftConnectPointY = yMax - POINTER_WIDTH / 2;
    rightConnectPointY = yMax - POINTER_WIDTH / 2;
  }

  if(element.type === EKind.MS_HVCB || element.type === EKind.MS_THERMAL_CT){
    if(rotation === 0){
      topConnectPointX = x + (width * 0.5 * 0.5);
      topConnectPointY = y;
      bottomConnectPointX = x + (width * 0.5 * 0.5);
      bottomConnectPointY = y + height;
    }else if(rotation === -90){
      leftConnectPointX = x;
      leftConnectPointY = y + (width * 3/4);
      rightConnectPointX = x + height;
      rightConnectPointY = y + (width * 3/4);
    }else if(rotation === 90){
      leftConnectPointX = x;
      leftConnectPointY = y + (width * 1/4);
      rightConnectPointX = x + height;
      rightConnectPointY = y + (width * 1/4);
    }
  }

  return {
    x,
    y,
    rotation : convertRotationValue(rotation),
    width,
    height,
    xMax,
    yMax,
    canConnectRight,
    canConnectBottom,
    canConnectLeft,
    canConnectTop,
    rightConnectPointX,
    rightConnectPointY,
    leftConnectPointX,
    leftConnectPointY,
    topConnectPointX,
    topConnectPointY,
    bottomConnectPointX,
    bottomConnectPointY,
  }
}

export const isCanConnectTop = (element : ControlModel) => {
  let ret = false
  switch(element.type) {
    case EKind.MS_SOURCE:
      break;
    case EKind.MS_GENERATOR:
      break;
    case EKind.MS_TRANS1:
      ret = true
      break;
    case EKind.MS_TRANS3:
      ret = true
      break;
    case EKind.MS_TRANSCENTER:
      ret = true
      break;
    case EKind.MS_3WINDING:
      ret = true
      break;
    case EKind.MS_TRANSSCOTT:
      ret = true
      break;
    case EKind.MS_IMPEDANCE:
      element.rotation === 0 && (ret = true)
      break;
    case EKind.MS_BUSDUCT:
      element.rotation === 0 && (ret = true)
      break;
    case EKind.MS_BUSBAR:
      element.rotation === 0 && (ret = true)
      break;
    case EKind.MS_WIRE:
      element.rotation === 0 && (ret = true)
      break;
    case EKind.MS_REACTOR:
      element.rotation === 0 && (ret = true)
      break;
    case EKind.MS_HVCB:
      element.rotation === 0 && (ret = true)
      break;
    case EKind.MS_RELAY51:
      ret = true
      break;
    case EKind.MS_MVCB:
      element.rotation === 0 && (ret = true)
      break;
    case EKind.MS_CONTACTOR:
      element.rotation === 0 && (ret = true)
      break;
    case EKind.MS_LVCB:
      element.rotation === 0 && (ret = true)
      break;
    case EKind.MS_FUSE:
      element.rotation === 0 && (ret = true)
      break;
    case EKind.MS_2E:
      ret = true
      break;
    case EKind.MS_THERMAL:
      ret = true
      break;
    case EKind.MS_MOTOR:
      ret = true
      break;
    case EKind.MS_MOTOR_GROUP:
      ret = true
      break;
    case EKind.MS_LOAD:
      ret = true
      break;
    case EKind.MS_CAPACITOR:
      ret = true
      break;
    case EKind.MS_LOADCENTER:
      ret = true
      break;
    case EKind.MS_ZEROCOND:
      ret = true
      break;
    case EKind.MS_CONNECT:
      element.rotation === 0 && (ret = true)
      break;
    case EKind.MS_TEXT:
      break;
    case EKind.MS_DS:
      element.rotation === 0 && (ret = true)
      break;
    case EKind.MS_LBS:
      element.rotation === 0 && (ret = true)
      break;
    case EKind.MS_THERMAL_CT:
      ret = true
      break;
    case EKind.MS_ARRESTOR:
      ret = true
      break;
    case EKind.MS_EARTH:
      ret = true
      break;
    case EKind.MS_CABLEHEAD:
      element.rotation === 0 && (ret = true)
      break;
    case EKind.MS_CT:
      ret = true
      break;
    case EKind.MS_ZCT:
      ret = true
      break;
    case EKind.MS_VT:
      ret = true
      break;
    case EKind.MS_VCT:
      ret = true
      break;
    case EKind.MS_INV:
      ret = true
      break;
    case EKind.MS_RELAY:
      element.rotation === 0 && (ret = true)
      break;
    case EKind.MS_LIGHTBOARD:
      ret = true
      break;
    case EKind.MS_POWERBOARD:
      ret = true
      break;
    case EKind.MS_INTO_POINT:
      break;
  }
  return ret
}

export const isCanConnectBottom = (element : ControlModel) => {
  let ret = false
  switch(element.type) {
    case EKind.MS_SOURCE:
      ret = true
      break;
    case EKind.MS_GENERATOR:
      ret = true
      break;
    case EKind.MS_TRANS1:
      ret = true
      break;
    case EKind.MS_TRANS3:
      ret = true
      break;
    case EKind.MS_TRANSCENTER:
      break;
    case EKind.MS_3WINDING:
      break;
    case EKind.MS_TRANSSCOTT:
      ret = true
      break;
    case EKind.MS_IMPEDANCE:
      element.rotation === 0 && (ret = true)
      break;
    case EKind.MS_BUSDUCT:
      element.rotation === 0 && (ret = true)
      break;
    case EKind.MS_BUSBAR:
      element.rotation === 0 && (ret = true)
      break;
    case EKind.MS_WIRE:
      element.rotation === 0 && (ret = true)
      break;
    case EKind.MS_REACTOR:
      element.rotation === 0 && (ret = true)
      break;
    case EKind.MS_HVCB:
      element.rotation === 0 && (ret = true)
      break;
    case EKind.MS_RELAY51:
      ret = true
      break;
    case EKind.MS_MVCB:
      element.rotation === 0 && (ret = true)
      break;
    case EKind.MS_CONTACTOR:
      element.rotation === 0 && (ret = true)
      break;
    case EKind.MS_LVCB:
      element.rotation === 0 && (ret = true)
      break;
    case EKind.MS_FUSE:
      element.rotation === 0 && (ret = true)
      break;
    case EKind.MS_2E:
      ret = true
      break;
    case EKind.MS_THERMAL:
      ret = true
      break;
    case EKind.MS_MOTOR:
      break;
    case EKind.MS_MOTOR_GROUP:
      break;
    case EKind.MS_LOAD:
      break;
    case EKind.MS_CAPACITOR:
      break;
    case EKind.MS_LOADCENTER:
      break;
    case EKind.MS_ZEROCOND:
      ret = true
      break;
    case EKind.MS_CONNECT:
      element.rotation === 0 && (ret = true)
      break;
    case EKind.MS_TEXT:
      break;
    case EKind.MS_DS:
      element.rotation === 0 && (ret = true)
      break;
    case EKind.MS_LBS:
      element.rotation === 0 && (ret = true)
      break;
    case EKind.MS_THERMAL_CT:
      ret = true
      break;
    case EKind.MS_ARRESTOR:
      // if (!element.properties.haveEarth) ret = true
      break;
    case EKind.MS_EARTH:
      break;
    case EKind.MS_CABLEHEAD:
      element.rotation === 0 && (ret = true)
      break;
    case EKind.MS_CT:
      ret = true
      break;
    case EKind.MS_ZCT:
      ret = true
      break;
    case EKind.MS_VT:
      ret = true
      break;
    case EKind.MS_VCT:
      ret = true
      break;
    case EKind.MS_INV:
      ret = true
      break;
    case EKind.MS_RELAY:
      element.rotation === 0 && (ret = true)
      break;
    case EKind.MS_LIGHTBOARD:
      break;
    case EKind.MS_POWERBOARD:
      break;
    case EKind.MS_INTO_POINT:
      ret = true
      break;
  }
  return ret
}

export const isCanConnectLeft = (element : ControlModel) => {
  let ret = false
  switch(element.type) {
    case EKind.MS_SOURCE:
      break;
    case EKind.MS_GENERATOR:
      break;
    case EKind.MS_TRANS1:
      break;
    case EKind.MS_TRANS3:
      break;
    case EKind.MS_TRANSCENTER:
      break;
    case EKind.MS_3WINDING:
      ret = true
      break;
    case EKind.MS_TRANSSCOTT:
      break;
    case EKind.MS_IMPEDANCE:
      element.rotation !== 0 && (ret = true)
      break;
    case EKind.MS_BUSDUCT:
      element.rotation !== 0 && (ret = true)
      break;
    case EKind.MS_BUSBAR:
      element.rotation !== 0 && (ret = true)
      break;
    case EKind.MS_WIRE:
      element.rotation !== 0 && (ret = true)
      break;
    case EKind.MS_REACTOR:
      element.rotation !== 0 && (ret = true)
      break;
    case EKind.MS_HVCB:
      element.rotation !== 0 && (ret = true)
      break;
    case EKind.MS_RELAY51:
      break;
    case EKind.MS_MVCB:
      element.rotation !== 0 && (ret = true)
      break;
    case EKind.MS_CONTACTOR:
      element.rotation !== 0 && (ret = true)
      break;
    case EKind.MS_LVCB:
      element.rotation !== 0 && (ret = true)
      break;
    case EKind.MS_FUSE:
      element.rotation !== 0 && (ret = true)
      break;
    case EKind.MS_2E:
      break;
    case EKind.MS_THERMAL:
      break;
    case EKind.MS_MOTOR:
      break;
    case EKind.MS_MOTOR_GROUP:
      break;
    case EKind.MS_LOAD:
      break;
    case EKind.MS_CAPACITOR:
      break;
    case EKind.MS_LOADCENTER:
      break;
    case EKind.MS_ZEROCOND:
      ret = true
      break;
    case EKind.MS_CONNECT:
      element.rotation !== 0 && (ret = true)
      break;
    case EKind.MS_TEXT:
      break;
    case EKind.MS_DS:
      element.rotation !== 0 && (ret = true)
      break;
    case EKind.MS_LBS:
      element.rotation !== 0 && (ret = true)
      break;
    case EKind.MS_THERMAL_CT:
      break;
    case EKind.MS_ARRESTOR:
      break;
    case EKind.MS_EARTH:
      break;
    case EKind.MS_CABLEHEAD:
      element.rotation !== 0 && (ret = true)
      break;
    case EKind.MS_CT:
      break;
    case EKind.MS_ZCT:
      break;
    case EKind.MS_VT:
      break;
    case EKind.MS_VCT:
      break;
    case EKind.MS_INV:
      break;
    case EKind.MS_RELAY:
      element.rotation !== 0 && (ret = true)
      break;
    case EKind.MS_LIGHTBOARD:
      break;
    case EKind.MS_POWERBOARD:
      break;
    case EKind.MS_INTO_POINT:
      break;
  }
  return ret
}

export const isCanConnectRight = (element : ControlModel) => {
  let ret = false
  switch(element.type) {
    case EKind.MS_SOURCE:
      break;
    case EKind.MS_GENERATOR:
      break;
    case EKind.MS_TRANS1:
      break;
    case EKind.MS_TRANS3:
      break;
    case EKind.MS_TRANSCENTER:
      break;
    case EKind.MS_3WINDING:
      ret = true
      break;
    case EKind.MS_TRANSSCOTT:
      break;
    case EKind.MS_IMPEDANCE:
      element.rotation !== 0 && (ret = true)
      break;
    case EKind.MS_BUSDUCT:
      element.rotation !== 0 && (ret = true)
      break;
    case EKind.MS_BUSBAR:
      element.rotation !== 0 && (ret = true)
      break;
    case EKind.MS_WIRE:
      element.rotation !== 0 && (ret = true)
      break;
    case EKind.MS_REACTOR:
      element.rotation !== 0 && (ret = true)
      break;
    case EKind.MS_HVCB:
      element.rotation !== 0 && (ret = true)
      break;
    case EKind.MS_RELAY51:
      break;
    case EKind.MS_MVCB:
      element.rotation !== 0 && (ret = true)
      break;
    case EKind.MS_CONTACTOR:
      element.rotation !== 0 && (ret = true)
      break;
    case EKind.MS_LVCB:
      element.rotation !== 0 && (ret = true)
      break;
    case EKind.MS_FUSE:
      element.rotation !== 0 && (ret = true)
      break;
    case EKind.MS_2E:
      break;
    case EKind.MS_THERMAL:
      break;
    case EKind.MS_MOTOR:
      break;
    case EKind.MS_MOTOR_GROUP:
      break;
    case EKind.MS_LOAD:
      break;
    case EKind.MS_CAPACITOR:
      break;
    case EKind.MS_LOADCENTER:
      break;
    case EKind.MS_ZEROCOND:
      ret = true
      break;
    case EKind.MS_CONNECT:
      element.rotation !== 0 && (ret = true)
      break;
    case EKind.MS_TEXT:
      break;
    case EKind.MS_DS:
      element.rotation !== 0 && (ret = true)
      break;
    case EKind.MS_LBS:
      element.rotation !== 0 && (ret = true)
      break;
    case EKind.MS_THERMAL_CT:
      break;
    case EKind.MS_ARRESTOR:
      break;
    case EKind.MS_EARTH:
      break;
    case EKind.MS_CABLEHEAD:
      element.rotation !== 0 && (ret = true)
      break;
    case EKind.MS_CT:
      break;
    case EKind.MS_ZCT:
      break;
    case EKind.MS_VT:
      break;
    case EKind.MS_VCT:
      break;
    case EKind.MS_INV:
      break;
    case EKind.MS_RELAY:
      element.rotation !== 0 && (ret = true)
      break;
    case EKind.MS_LIGHTBOARD:
      break;
    case EKind.MS_POWERBOARD:
      break;
    case EKind.MS_INTO_POINT:
      break;
  }
  return ret
}

export const getElementImage = (elementKind : string, properties : any, rotation: any) => {
  // TODO: check symkind to return the exact image of the element
  switch (elementKind) {
    case EKind.MS_HVCB:
      {
        const symKind = properties.symKind
        if (symKind === 0){
          if (rotation === 0) return ElementImages.imageHVCB_0
          if (rotation === -90) return ElementImages.imageHVCB_0_1
          return ElementImages.imageHVCB_0_2
        }
        else
        if (rotation === 0) return ElementImages.imageHVCB_1
        if (rotation === -90) return ElementImages.imageHVCB_1_1
        return ElementImages.imageHVCB_1_2
      }
    case EKind.MS_LVCB:
      {
        const symKind = properties.symKind
        if (symKind === 0)
          return ElementImages.imageLVCB_0
        if (symKind === 1)
          return ElementImages.imageLVCB_2
        if (symKind === 2)
          return ElementImages.imageLVCB_1
        else
          return ElementImages.imageLVCB_3
      }
    case EKind.MS_THERMAL:
      {
        const symKind = properties.symKind
        if (symKind === 0)
          return ElementImages.imageThermal_0
        else
          return ElementImages.imageThermal_1
      }
    case EKind.MS_SOURCE:
      {
        const symKind = properties.symKind
        if (symKind === 0)
          return ElementImages.imageSource_0
        else
          return ElementImages.imageSource_1
      }
    case EKind.MS_TRANS3:
      {
        const symKind = properties.symKind
        const haveTab = properties.haveTab
        if (symKind === 0)
          return haveTab
            ? ElementImages.imageTrans3_0_T
            : ElementImages.imageTrans3_0_F
        if (symKind === 1)
          return haveTab
            ? ElementImages.imageTrans3_1_T
            : ElementImages.imageTrans3_1_F
        if (symKind === 2)
          return haveTab
            ? ElementImages.imageTrans3_2_T
            : ElementImages.imageTrans3_2_F
        if (symKind === 3)
          return haveTab
            ? ElementImages.imageTrans3_3_T
            : ElementImages.imageTrans3_3_F
        else
          return haveTab
            ? ElementImages.imageTrans3_4_T
            : ElementImages.imageTrans3_4_F
      }
    case EKind.MS_GENERATOR:
      return ElementImages.imageGenerator
    case EKind.MS_MOTOR_GROUP:
      return ElementImages.imageMotorGroup
    case EKind.MS_ZEROCOND:
      return ElementImages.imageZerocond
    case EKind.MS_BUSBAR:
      return ElementImages.imageBusbar
    case EKind.MS_WIRE:
      {
        const exchangeNumber = properties.exchangeNumber
        if (exchangeNumber === 0){
          // TODO line and triangleUpObj and triangleDownObj => 3 images
          return ElementImages.imageWire
        }
        return ElementImages.imageWire
      }
    case EKind.MS_CONTACTOR:
      { const exchangeNumber = properties.exchangeNumber
        if(exchangeNumber === 0){
          return ElementImages.imageContactor

        }
        else {
          return ElementImages.imageContactor2
        }
      }
    case EKind.MS_MOTOR:
      return ElementImages.imageMotor_1
    case EKind.MS_LOAD:
      return ElementImages.imageLoad
    case EKind.MS_CAPACITOR:
      return ElementImages.imageCapacitor
    case EKind.MS_2E:
      {
        const symKind = properties.symKind
        if (symKind === 0)
          return ElementImages.image2E_0
        else
          return ElementImages.image2E_1
      }
    case EKind.MS_FUSE:
      {
        const symKind = properties.symKind
        if (symKind === 0)
          return ElementImages.imageFUSE_0
        if (symKind === 1)
          return ElementImages.imageFUSE_1
        if (symKind === 2)
          return ElementImages.imageFUSE_2
        else
          return ElementImages.imageFUSE_3
      }
    case EKind.MS_MVCB:
      { const exchangeNumber = properties.exchangeNumber
        if(exchangeNumber === 0){
          return ElementImages.imageMVCB

        }
        else if(exchangeNumber === 1){
          return ElementImages.imageMVCB2
          
        }
        else{
          return ElementImages.imageMVCB3
        }
        
      }
    case EKind.MS_REACTOR:
      return ElementImages.imageReactor
    case EKind.MS_3WINDING:
      {
      const symKind = properties.symKind
      if(symKind === 0){
        return ElementImages.image3Winding
      }
      else if(symKind === 1){
        return ElementImages.image3Winding_2
      }
      else if(symKind === 2){
        return ElementImages.image3Winding_3_5
      }
      else if(symKind === 3){
        return ElementImages.image3Winding_4
      }
      else if(symKind === 4){
        return ElementImages.image3Winding_3_5
      }
      else if(symKind === 5){
        return ElementImages.image3Winding_6_8
      }
      else if(symKind === 6){
        return ElementImages.image3Winding_7
      }
      else {
        return ElementImages.image3Winding_6_8
      }
      }
    case EKind.MS_BUSDUCT:
      return ElementImages.imageBusduct
    case EKind.MS_TRANS1:
      {
        const symKind = properties.symKind
        if (symKind === 0)
          return ElementImages.imageTrans1_0
        else
          return ElementImages.imageTrans1_1
      }
    case EKind.MS_TEXT:
      return ElementImages.imageINV
    case EKind.MS_TRANSSCOTT:
      return ElementImages.imageTransscott
    case EKind.MS_DS:
      { const exchangeNumber = properties.exchangeNumber
        if(exchangeNumber === 0){
          return ElementImages.imageDS

        }
        else if(exchangeNumber === 1){
          return ElementImages.imageDS2
          
        }
        else{
          return ElementImages.imageDS3
        }
      }
    case EKind.MS_LBS:
      { const exchangeNumber = properties.exchangeNumber
        if(exchangeNumber === 0){
          return ElementImages.imageLBS

        }
        else if(exchangeNumber === 1){
          return ElementImages.imageLBS2
          
        }
        else if(exchangeNumber === 2){
          return ElementImages.imageLBS3
        }
        else{
          return ElementImages.imageLBS4
        }
      }
    case EKind.MS_THERMAL_CT:
      return ElementImages.imageThermalCT
    case EKind.MS_ARRESTOR:
      {
        const symKind = properties.symKind
        if (symKind === 0) {
          if (properties.haveEarth) return ElementImages.imageArrestor_1_1
          return ElementImages.imageArrestor_1_0
        } else if (symKind === 1) {
          if (properties.haveEarth) return ElementImages.imageArrestor_2_1
          return ElementImages.imageArrestor_2_0
        } else {
          if (properties.haveEarth) return ElementImages.imageArrestor_3_1
          return ElementImages.imageArrestor_3_0
        }
      }
    case EKind.MS_EARTH:
      return ElementImages.imageEarth_1
    case EKind.MS_CABLEHEAD:
      return ElementImages.imageCableHead_1
    case EKind.MS_CT:
      return ElementImages.imageCT_1
    case EKind.MS_ZCT:
      return ElementImages.imageZCT_1
    case EKind.MS_VT:
      return ElementImages.imageMsVT_1
    case EKind.MS_VCT:
      {
        const symKind = properties.symKind
        if (symKind === 0) return ElementImages.imageINV_1
        else if (symKind === 1) return ElementImages.imageINV_2
        return ElementImages.imageINV_3
      }
    case EKind.MS_LOADCENTER:
      return ElementImages.imageINV
    case EKind.MS_TRANSCENTER:
      return ElementImages.imageTransCenter_1
    case EKind.MS_LIGHTBOARD:
      return ElementImages.imageLightBoard
    case EKind.MS_POWERBOARD:
      return ElementImages.imagePowerBoard
    case EKind.MS_INTO_POINT:
      return ''
    case EKind.MS_RELAY51:
      return ElementImages.imageINV
    case EKind.MS_IMPEDANCE:
      {
        const inputPercentZ = properties.inputPercentZ
        let image = ""
        switch (inputPercentZ) {
          case 1:
            if (properties.percentR > 0 && properties.percentX > 0) {
              image = ElementImages.image_r_x
            } 
            else if (properties.percentR > 0) {
              image = ElementImages.image_r
            } 
            else if (properties.percentX > 0) {
              image = ElementImages.image_x
            } 
            else {
              image = ElementImages.imageImpedance
            }
            break;
          case 2:
            if (properties.ohmR > 0 && properties.ohmX > 0) {
              image = ElementImages.image_r_x
            } else if (properties.ohmR > 0) {
              image = ElementImages.image_r
            } else if (properties.ohmX > 0) {
              image = ElementImages.image_x
            } else {
              image = ElementImages.imageImpedance
            }
            break;

        }
       return image

    }
    case EKind.MS_CONNECT:
      return ElementImages.imageConnect
    case EKind.MS_INV:
      return ElementImages.imageINV
    case EKind.MS_RELAY:
      {
        const symKind = properties.symKind
        if (symKind === 0) return ElementImages.imageRelay_1
        if (symKind === 1) return ElementImages.imageRelay_2
        return ElementImages.imageRelay_3
      }
    default:
      return ""
  }
}
export const printPercentZ =(percentZ:any) =>{
  return {
    kind: percentZ.kind,
    x: percentZ.x,
    y: percentZ.y,
    xMax: percentZ.x_max,
    yMax: percentZ.y_max,
    fault: percentZ.fault,
    rotate: percentZ.rotate,
    perRk3: percentZ.per_rk3,
    perXk3: percentZ.per_xk3,
    statorPerRk3: percentZ.stator_per_rk3,
    fault2: percentZ.fault2,
    perR2k3: percentZ.per_r2k3,
    perX2k3: percentZ.per_x2k3,
    fault3: percentZ.fault3,
    perR3k3: percentZ.per_r3k3,
    perX3k3: percentZ.per_x3k3,
    fault1: percentZ.fault1,
    perR1k3: percentZ.per_r1k3,
    perX1k3: percentZ.per_x1k3,
    perRk3Difference: percentZ.per_rk3_difference,
    perXk3Difference: percentZ.per_xk3_difference,
    perRk3Source: percentZ.per_rk3_source,
    perXk3Source: percentZ.per_xk3_source,
    calcFailed: percentZ.calc_failed,
    circuitName: percentZ.circuit_name,
    specialImpedance: percentZ.special_impedance,
    parallelFactor: percentZ.parallel_factor
  }
}
export const getElementProperties = (elementKind : string, properties : any[]) => {
  switch (elementKind) {
    case EKind.MS_HVCB:
    {
      let listUser :any [] = []
      let listUserPoint :any [] = []
      let hvcbProps: any, hvcbBase: any, hvcbAdjust: any
      let hvcbParts: PartDetailsModel[] = []
        let calcPoint1 = {} as any
        let calcPoint0 = {} as any
        let calcPoint0_perZDirect = {} as any
        let calcPoint0_perZTotal = {} as any
        let calcPoint0_perZDirectMG = {} as any
        let calcPoint0_perZTotalMG = {} as any
        let calcPoint0_perZMotor= {} as any

        let calcPoint1_perZDirect = {} as any
        let calcPoint1_perZTotal = {} as any
        let calcPoint1_perZDirectMG = {} as any
        let calcPoint1_perZTotalMG = {} as any
        let calcPoint1_perZMotor = {} as any


        properties.forEach(rowData =>{
          if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT){
            calcPoint0_perZDirect = rowData
          }
          else if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL){
            calcPoint0_perZTotal = rowData
          }
          else if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT_MG){
            calcPoint0_perZDirectMG = rowData
          }
          else if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL_MG){
            calcPoint0_perZTotalMG = rowData
          }
          else if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_MOTOR){
            calcPoint0_perZMotor = rowData
          }
          else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT){
            calcPoint1_perZDirect = rowData
          }
          else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL){
            calcPoint1_perZTotal = rowData
          }
          else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT_MG){
            calcPoint1_perZDirectMG = rowData
          }
          else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL_MG){
            calcPoint1_perZTotalMG = rowData
          }
          else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_MOTOR){
            calcPoint1_perZMotor = rowData
          }
          else if(rowData.calc_point_id === 1){
            calcPoint0 = rowData
          }
          else if(rowData.calc_point_id === 2){
            calcPoint1 = rowData
          }
          else if(rowData.user_curve_point_id){
            listUserPoint.push(rowData)
          }
          else if(rowData.user_curve_id){
            listUser.push(rowData)
          }
          else if(rowData.no_hvcb){
            hvcbProps = rowData
          }
          else if("type_id" in rowData){
            hvcbBase = rowData
          }
          else if("part_id" in rowData){
            hvcbParts.push(rowData as PartDetailsModel) 
          }
          else if("draw_three_curve" in rowData){
            hvcbAdjust = rowData
          }
        })
      // properties.forEach((e: any) => {
      //   if ('no_hvcb' in e)
      //     hvcbProps = e
      //   else if ('type_id' in e)
      //     hvcbBase = e
      //   else if ('part_id' in e)
      //     hvcbParts.push(e as PartDetailsModel) 
      //   else if ('draw_three_curve' in e)
      //     hvcbAdjust = e
      // })
      // TODO if (!hvcbProps || !hvcbBase)

      return {
        // 基本
        refNo: hvcbProps.ref_no,
        noHVCB: hvcbProps.no_hvcb,
        pointText: hvcbProps.point_text,
        // voltageList: [],
        voltage: hvcbProps.voltage,
        breakCapacityList: [
            {label: '8', value: 8},
            {label: '12.5', value: 12.5},
            {label: '16', value: 16},
            {label: '20', value: 20},
            {label: '25', value: 25},
            {label: '31.5', value: 31.5},
            {label: '40', value: 40},
        ],
        breakCapacity: hvcbProps.break_capacity,
        isOff: hvcbProps.is_off,
        fault: hvcbProps.fault,
        dropMotor: hvcbProps.drop_motor,
        dropRegular: hvcbProps.drop_regular,
        viewResultText: hvcbProps.view_result_text,
        
        // ModePM
        viewFlowResult: hvcbProps.view_flow_result,
        connectSwitch: hvcbProps.connect_switch,
        makeDataInput: hvcbProps.make_data_input,
        // makerNameList: [],
        makerName: hvcbProps.maker_name,
        breakType: hvcbProps.break_type,
        // makeYearList: [],
        makeYear: hvcbProps.make_year,
        // makeMonthList: [],
        makeMonth: hvcbProps.make_month,
        makeNumber: hvcbProps.make_number,
        makeYearOCR: hvcbProps.make_year_ocr,
        makeMonthOCR: hvcbProps.make_month_ocr,
        makeNumberOCR: hvcbProps.make_number_ocr,

        // 保護協調
        details: {
          base: convertBaseFromBEToFE(hvcbBase),
          parts: convertPartsFromBEToFE(hvcbParts),
          //TODO: delaySettings
          
          refNo2: hvcbAdjust.ref_no,
          drawThreeCurve: hvcbAdjust.draw_three_curve,
        },
        // シンボル
        symKind: hvcbProps.exchange_number,
        // others
        checkNG: hvcbProps.check_ng,
        supply: hvcbProps.supply,
        tcName: hvcbProps.tc_name,
        lineActiveFlow1: hvcbProps.line_active_flow1,
        lineReactiveFlow1: hvcbProps.line_reactive_flow1,
        lineActiveFlow2: hvcbProps.line_active_flow2,
        lineReactiveFlow2: hvcbProps.line_reactive_flow2,
        eleTopLeftNumber: hvcbProps.ele_top_left_number,
        eleBottomRightNumber: hvcbProps.ele_bottom_right_number,
        downTransKind: getElementKindLabel(hvcbProps.down_trans_kind),
        primarySupply: hvcbProps.primary_supply,
        examineComment: hvcbProps.examine_comment,
        seriesed: hvcbProps.seriesed,
        voltDropSumRe: hvcbProps.volt_drop_sum_re,
        voltDropSumIm: hvcbProps.volt_drop_sum_im,
        voltDropRouteRefNo: hvcbProps.volt_drop_route_ref_no,
        rotate: hvcbProps.rotate,
        calcPoint0: doConvertDataCalcPoint(calcPoint0,
                                          calcPoint0_perZDirect,
                                          calcPoint0_perZTotal,
                                          calcPoint0_perZDirectMG,
                                          calcPoint0_perZTotalMG,
                                          calcPoint0_perZMotor),
        calcPoint1: doConvertDataCalcPoint(calcPoint1,
                                          calcPoint1_perZDirect,
                                          calcPoint1_perZTotal,
                                          calcPoint1_perZDirectMG,
                                          calcPoint1_perZTotalMG,
                                          calcPoint1_perZMotor),
        listUser: doConvertDataUserCurve(listUser,listUserPoint)
      } as MsHVCBModel;
    }
    case EKind.MS_LVCB:
    {
      let listUser :any [] = []
      let listUserPoint :any [] = []
      let mainProps: any, base: any, adjust: any
      let parts: PartDetailsModel[] = []
      let calcPoint1 = {} as any
        let calcPoint0 = {} as any
        let calcPoint0_perZDirect = {} as any
        let calcPoint0_perZTotal = {} as any
        let calcPoint0_perZDirectMG = {} as any
        let calcPoint0_perZTotalMG  = {} as any
        let calcPoint0_perZMotor  = {} as any

        let calcPoint1_perZDirect = {} as any
        let calcPoint1_perZTotal = {} as any
        let calcPoint1_perZDirectMG = {} as any
        let calcPoint1_perZTotalMG = {} as any
        let calcPoint1_perZMotor = {} as any


        properties.forEach(row =>{
          if(row.calc_point_id === 1 && row.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT){
            calcPoint0_perZDirect = row
          }
          else if(row.calc_point_id === 1 && row.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL){
            calcPoint0_perZTotal = row
          }
          else if(row.calc_point_id === 1 && row.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT_MG){
            calcPoint0_perZDirectMG = row
          }
          else if(row.calc_point_id === 1 && row.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL_MG){
            calcPoint0_perZTotalMG  = row
          }
          else if(row.calc_point_id === 1 && row.calc_per_z_type === CALC_PER_Z_TYPE_MOTOR){
            calcPoint0_perZMotor  = row
          }
          else if(row.calc_point_id === 2 && row.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT){
            calcPoint1_perZDirect = row
          }
          else if(row.calc_point_id === 2 && row.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL){
            calcPoint1_perZTotal = row
          }
          else if(row.calc_point_id === 2 && row.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT_MG){
            calcPoint1_perZDirectMG = row
          }
          else if(row.calc_point_id === 2 && row.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL_MG){
            calcPoint1_perZTotalMG = row
          }
          else if(row.calc_point_id === 2 && row.calc_per_z_type === CALC_PER_Z_TYPE_MOTOR){
            calcPoint1_perZMotor  = row
          }
          else if(row.calc_point_id === 1){
            calcPoint0 = row
          }
          else if(row.calc_point_id === 2){
            calcPoint1 = row
          }
          else if(row.user_curve_point_id){
            listUserPoint.push(row)
          }
          else if(row.user_curve_id){
            listUser.push(row)
          }
          else if(row.no_lvcb){
            mainProps = row
          }
          else if("type_id" in row){
            base = row
          }
          else if("part_id" in row){
            parts.push(row as PartDetailsModel) 
          }
          else if("draw_three_curve" in row){
            adjust = row
          }
        })
      // properties.forEach((e: any) => {
      //   if ('no_lvcb' in e)
      //     mainProps = e
      //   else if ('type_id' in e)
      //     base = e
      //   else if ('part_id' in e)
      //     parts.push(e as PartDetailsModel) 
      //   else if ('draw_three_curve' in e)
      //     adjust = e
      // })      
      return {
        // 基本
        noLVCB: mainProps.no_lvcb,
        refNo: mainProps.ref_no,
        pointText: mainProps.point_text,
      //   // voltageList: [],
        voltage: mainProps.voltage,
        isOff: mainProps.is_off,
        fault: mainProps.fault,
        dropMotor: mainProps.drop_motor,
        dropRegular: mainProps.drop_regular,
        viewResultText: mainProps.view_result_text,
        
      //   // ModePM
        viewFlowResult: mainProps.view_flow_result,
        connectSwitch: mainProps.connect_switch,

        makeDataInput: mainProps.make_data_input,
      //   // makeYearList: [],
        makeYear: mainProps.make_year,
      //   // makeMonthList: [],
        makeMonth: mainProps.make_month,
        makeNumber: mainProps.make_number,

      //   // 保護協調
        details: {
          base: convertBaseFromBEToFE(base),
          parts: convertPartsFromBEToFE(parts),
          //TODO: delaySettings
          
          refNo2: adjust.ref_no,
          drawThreeCurve: adjust.draw_three_curve,
        },
      //   // シンボル
        symKind: mainProps.exchange_number,
        listUser: doConvertDataUserCurve(listUser,listUserPoint),
        
      //   // others
        makerName: mainProps.maker_name,
        sPhase: mainProps.s_phase,
        rotate: mainProps.rotate,
        upTransKind: mainProps.up_trans_kind,
        checkNG: mainProps.check_ng,
        supply: mainProps.supply,
        tcName: mainProps.tc_name,
        lineActiveFlow1: mainProps.line_active_flow1,
        lineReactiveFlow1: mainProps.line_reactive_flow1,
        lineActiveFlow2: mainProps.line_active_flow2,
        lineReactiveFlow2: mainProps.line_reactive_flow2,
        eleTopLeftNumber: mainProps.ele_top_left_number,
        eleBottomRightNumber: mainProps.ele_bottom_right_number,
        downTransKind: getElementKindLabel(mainProps.down_trans_kind),
        primarySupply: mainProps.primary_supply,
        examineComment: mainProps.examine_comment,
        examineCommentSPhase: mainProps.examine_comment_s_phase ,
        seriesed: mainProps.seriesed,
        voltDropSumRe1: mainProps.volt_drop_sum_re1,
        voltDropSumIm1: mainProps.volt_drop_sum_im1,
        voltDropSumRe2: mainProps.volt_drop_sum_re2,
        voltDropSumIm2: mainProps.volt_drop_sum_im2,
        voltDropRouteRefNo: mainProps.volt_drop_route_ref_no,
        calcPoint0: doConvertDataCalcPoint(calcPoint0,
                                          calcPoint0_perZDirect,
                                          calcPoint0_perZTotal,
                                          calcPoint0_perZDirectMG,
                                          calcPoint0_perZTotalMG,
                                          calcPoint0_perZMotor),
        calcPoint1: doConvertDataCalcPoint(calcPoint1,
                                          calcPoint1_perZDirect,
                                          calcPoint1_perZTotal,
                                          calcPoint1_perZDirectMG,
                                          calcPoint1_perZTotalMG,
                                          calcPoint1_perZMotor),
      } as MsLVCBModel;
    }
    case EKind.MS_THERMAL:
    {
      let listUser :any [] = []
      let listUserPoint :any [] = []
      let mainProps: any, base: any, adjust: any
      let parts: PartDetailsModel[] = []
      let calcPoint1 = {} as any
        let calcPoint0 = {} as any
        let calcPoint0_perZDirect = {} as any
        let calcPoint0_perZTotal = {} as any
        let calcPoint0_perZDirectMG = {} as any
        let calcPoint0_perZTotalMG  = {} as any
        let calcPoint0_perZMotor  = {} as any

        let calcPoint1_perZDirect = {} as any
        let calcPoint1_perZTotal = {} as any
        let calcPoint1_perZDirectMG = {} as any
        let calcPoint1_perZTotalMG = {} as any
        let calcPoint1_perZMotor = {} as any


        properties.forEach(rowData =>{
          if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT){
            calcPoint0_perZDirect = rowData
          }
          else if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL){
            calcPoint0_perZTotal = rowData
          }
          else if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT_MG){
            calcPoint0_perZDirectMG = rowData
          }
          else if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL_MG){
            calcPoint0_perZTotalMG  = rowData
          }
          else if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_MOTOR){
            calcPoint0_perZMotor  = rowData
          }
          else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT){
            calcPoint1_perZDirect = rowData
          }
          else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL){
            calcPoint1_perZTotal = rowData
          }
          else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT_MG){
            calcPoint1_perZDirectMG = rowData
          }
          else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL_MG){
            calcPoint1_perZTotalMG = rowData
          }
          else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_MOTOR){
            calcPoint1_perZMotor = rowData
          }
          else if(rowData.calc_point_id === 1){
            calcPoint0 = rowData
          }
          else if(rowData.calc_point_id === 2){
            calcPoint1 = rowData
          }
          else if(rowData.user_curve_point_id){
                      listUserPoint.push(rowData)
                    }
          else if(rowData.user_curve_id){
            listUser.push(rowData)
          }
          else if(rowData.no_thermal){
            mainProps = rowData
          }
          else if("type_id" in rowData){
            base = rowData
          }
          else if("part_id" in rowData){
            parts.push(rowData as PartDetailsModel) 
          }
          else if("draw_three_curve" in rowData){
            adjust = rowData
          }
        })
      // properties.forEach((e: any) => {
      //   if ('no_thermal' in e)
      //     mainProps = e
      //   else if ('type_id' in e)
      //     base = e
      //   else if ('part_id' in e)
      //     parts.push(e as PartDetailsModel) 
      //   else if ('draw_three_curve' in e)
      //     adjust = e
      // })      
      return {
        // 基本
        noThermal: mainProps.no_thermal,
        refNo: mainProps.ref_no,
        pointText: mainProps.point_text,
        // voltageList: [],
        voltage: mainProps.voltage,
        isOff: mainProps.is_off,
        fault: mainProps.fault,
        dropMotor: mainProps.drop_motor,
        dropRegular: mainProps.drop_regular,
        viewResultText: mainProps.view_result_text,
        
        // ModePM
        viewFlowResult: mainProps.view_flow_result,

        makeDataInput: mainProps.make_data_input,
        // makeYearList: [],
        makeYear: mainProps.make_year,
        // makeMonthList: [],
        makeMonth: mainProps.make_month,
        makeNumber: mainProps.make_number,

        // 保護協調
        details: {
          base: convertBaseFromBEToFE(base),
          parts: convertPartsFromBEToFE(parts),
          //TODO: delaySettings
          
          refNo2: adjust.ref_no,
          drawThreeCurve: adjust.draw_three_curve,
        },
        // シンボル
        symKind: mainProps.exchange_number,
        listUser: doConvertDataUserCurve(listUser,listUserPoint),
        
        // others
        makerName: mainProps.maker_name,
        sPhase: mainProps.s_phase,
        upTransKind: mainProps.up_trans_kind,
        checkNG: mainProps.check_ng,
        supply: mainProps.supply,
        tcName: mainProps.tc_name,
        lineActiveFlow1: mainProps.line_active_flow1,
        lineReactiveFlow1: mainProps.line_reactive_flow1,
        lineActiveFlow2: mainProps.line_active_flow2,
        lineReactiveFlow2: mainProps.line_reactive_flow2,
        downTransKind: getElementKindLabel(mainProps.down_trans_kind),
        primarySupply: mainProps.primary_supply,
        examineComment: mainProps.examine_comment,
        examineCommentSPhase: mainProps.examine_comment_s_phase	,
        seriesed: mainProps.seriesed,
        voltDropSumRe1: mainProps.volt_drop_sum_re1,
        voltDropSumIm1: mainProps.volt_drop_sum_im1,
        voltDropSumRe2: mainProps.volt_drop_sum_re2,
        voltDropSumIm2: mainProps.volt_drop_sum_im2,
        voltDropRouteRefNo: mainProps.volt_drop_route_ref_no,
        calcPoint0: doConvertDataCalcPoint(calcPoint0,
          calcPoint0_perZDirect,
          calcPoint0_perZTotal,
          calcPoint0_perZDirectMG,
          calcPoint0_perZTotalMG,
          calcPoint0_perZMotor),
        calcPoint1: doConvertDataCalcPoint(calcPoint1,
          calcPoint1_perZDirect,
          calcPoint1_perZTotal,
          calcPoint1_perZDirectMG,
          calcPoint1_perZTotalMG,
          calcPoint1_perZMotor),
      } as MsThermalModel;
    }
    case EKind.MS_SOURCE: 
    {
      let listUser :any [] = []
      let listUserPoint :any [] = []
      let mainProps: any, base: any, adjust: any
      let parts: PartDetailsModel[] = []
      let calcPoint1 = {} as any
      let calcPoint0 = {} as any
      let calcPoint0_perZDirect = {} as any
      let calcPoint0_perZTotal = {} as any
      let calcPoint0_perZDirectMG = {} as any
      let calcPoint0_perZTotalMG = {} as any
      let calcPoint0_perZMotor = {} as any

      let calcPoint1_perZDirect = {} as any
      let calcPoint1_perZTotal = {} as any
      let calcPoint1_perZDirectMG = {} as any
      let calcPoint1_perZTotalMG = {} as any
      let calcPoint1_perZMotor = {} as any

      properties.forEach(row => {
        if(row.calc_point_id === 1 && row.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT){
          calcPoint0_perZDirect = row
        }
        else if(row.calc_point_id === 1 && row.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL){
          calcPoint0_perZTotal = row
        }
        else if(row.calc_point_id === 1 && row.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT_MG){
          calcPoint0_perZDirectMG = row
        }
        else if(row.calc_point_id === 1 && row.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL_MG){
          calcPoint0_perZTotalMG = row
        }
        else if(row.calc_point_id === 1 && row.calc_per_z_type === CALC_PER_Z_TYPE_MOTOR){
          calcPoint0_perZMotor = row
        }
        else if(row.calc_point_id === 2 && row.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT){
          calcPoint1_perZDirect = row
        }
        else if(row.calc_point_id === 2 && row.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL){
          calcPoint1_perZTotal = row
        }
        else if(row.calc_point_id === 2 && row.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT_MG){
          calcPoint1_perZDirectMG = row
        }
        else if(row.calc_point_id === 2 && row.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL_MG){
          calcPoint1_perZTotalMG = row
        }
        else if(row.calc_point_id === 2 && row.calc_per_z_type === CALC_PER_Z_TYPE_MOTOR){
          calcPoint1_perZMotor = row
        }
        else if(row.calc_point_id === 1){
          calcPoint0 = row
        }
        else if(row.calc_point_id === 2){
          calcPoint1 = row
        }
        else if(row.user_curve_point_id){
          listUserPoint.push(row)
        }
        else if(row.user_curve_id){
          listUser.push(row)
        }
        else if(row.no_source){
          mainProps = row
        }
        else if("type_id" in row){
          base = row
        }
        else if("part_id" in row){
          parts.push(row as PartDetailsModel) 
        }
        else if("draw_three_curve" in row){
          adjust = row
        }
      // properties.forEach((e: any) => {
      //   if(e.)
        // if ('no_source' in e)
        //   mainProps = e
        // else if ('type_id' in e)
        //   base = e
        // else if ('part_id' in e)
        //   parts.push(e as PartDetailsModel) 
        // else if ('draw_three_curve' in e)
        //   adjust = e
      })
      let defaultPower = msSourceNormalCalculation(mainProps.voltage, mainProps.capacity, mainProps.xr)
      
      return {
        // 基本
        refNo: mainProps.ref_no,
        noSource: mainProps.no_source,
        pointText: mainProps.point_text,
      //   voltageList: [],
        voltage: mainProps.voltage,
        capacityList: [
            {label: '50', value: 50},
            {label: '100', value: 100},
            {label: '160', value: 160},
            {label: '500', value: 500},
            {label: '1000', value: 1000},
        ],
        capacity: mainProps.capacity,
        fault: mainProps.fault,
        calcImprovePF: mainProps.calc_improve_pf,
        viewResultText: mainProps.view_result_text,
        xr: mainProps.xr,
        percentZ: BeamsNumber(defaultPower.percentZ, 6),
        percentR: BeamsNumber(defaultPower.percentR, 4),
        percentX: BeamsNumber(defaultPower.percentX, 4),
        ohmZ: BeamsNumber(defaultPower.ohmZ, 4),
        ohmR: BeamsNumber(defaultPower.ohmR, 4),
        ohmX: BeamsNumber(defaultPower.ohmX, 4),
      //   // 保護協調
        details: {
           base: convertBaseFromBEToFE(base),
           parts: convertPartsFromBEToFE(parts),
      //     //TODO: delaySettings

          refNo2: adjust.ref_no,
          drawThreeCurve: adjust.draw_three_curve,
       },
      //   // 力率改善
        contract: mainProps.contract,
        present: mainProps.present,
        target: mainProps.target,
        demand: mainProps.demand,
        // 潮流解析 -- ModePM
        nodeKind: mainProps.node_kind,
        viewFlowResult: mainProps.view_flow_result,
        leadLag: mainProps.is_lead ? 1 : 0,
        powerFactor: mainProps.power_factor,
        appointVoltage: mainProps.appoint_voltage,
        flowQMin: mainProps.q_min,
        flowQMax: mainProps.q_max,
      //   // シンボル
        symKind: mainProps.exchange_number,
        listUser: doConvertDataUserCurve(listUser,listUserPoint),
      //   // others
        checkNG: mainProps.check_ng,
        nodeNumber: mainProps.node_number,
        perUnitR: mainProps.per_unit_r,
        perUnitX: mainProps.per_unit_x,
        perUnitK: mainProps.per_unit_k,
        perUnitP: mainProps.per_unit_p,
        perUnitQ: mainProps.per_unit_q,
        perUnitV: mainProps.per_unit_v,
        voltageMagnitude: mainProps.voltage_magnitude,
        voltageAngle: mainProps.voltage_angle,
        activePower: mainProps.active_power,
        reactivePower: mainProps.reactive_power,
        examineComment: mainProps.examine_comment,
        harmonicCurrent0: mainProps.harmonic_current0,
        harmonicCurrent1: mainProps.harmonic_current1,
        harmonicCurrent2: mainProps.harmonic_current2,
        harmonicCurrent3: mainProps.harmonic_current3,
        harmonicCurrent4: mainProps.harmonic_current4,
        harmonicCurrent5: mainProps.harmonic_current5,
        harmonicCurrent6: mainProps.harmonic_current6,
        harmonicCurrent7: mainProps.harmonic_current7,
        harmonicCurrent8: mainProps.harmonic_current8,
        harmonicCurrent9: mainProps.harmonic_current9,
        harmonicCurrent10: mainProps.harmonic_current10,
        harmonicCurrent11: mainProps.harmonic_current11,
        harmonicCurrent12: mainProps.harmonic_current12,
        harmonicCurrent13: mainProps.harmonic_current13,
        harmonicCurrent14: mainProps.harmonic_current14,
        harmonicCurrent15: mainProps.harmonic_current15,
        seriesed: mainProps.seriesed,
        loadCapacity: mainProps.load_capacity,
        calcPoint0: doConvertDataCalcPoint(calcPoint0,
          calcPoint0_perZDirect,
          calcPoint0_perZTotal,
          calcPoint0_perZDirectMG,
          calcPoint0_perZTotalMG,
          calcPoint0_perZMotor),
        calcPoint1: doConvertDataCalcPoint(calcPoint1,
          calcPoint1_perZDirect,
          calcPoint1_perZTotal,
          calcPoint1_perZDirectMG,
          calcPoint1_perZTotalMG,
          calcPoint1_perZMotor),
      } as MsSourceModel;
    }
    case EKind.MS_TRANS3:
    {
      let listUser :any [] = []
      let listUserPoint :any [] = []
      let mainProps ={}as  any
      let calcPoint0={} as  any
      let calcPoint1={} as any
      let calcPoint0_perZDirect = {} as PercentZ
      let calcPoint0_perZTotal = {} as PercentZ
      let calcPoint0_perZDirectMG = {} as PercentZ
      let calcPoint0_perZTotalMG = {} as PercentZ
      let calcPoint0_perZMotor = {} as PercentZ

      let calcPoint1_perZDirect = {} as PercentZ
      let calcPoint1_perZTotal = {} as PercentZ
      let calcPoint1_perZDirectMG = {} as PercentZ
      let calcPoint1_perZTotalMG = {} as PercentZ
      let calcPoint1_perZMotor = {} as PercentZ
      properties.forEach((rowData: any) => {
        if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT){
          calcPoint0_perZDirect = rowData
        }
        else if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL){
          calcPoint0_perZTotal = rowData
        }
        else if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT_MG){
          calcPoint0_perZDirectMG = rowData
        }
        else if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL_MG){
          calcPoint0_perZTotalMG  = rowData
        }
        else if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_MOTOR){
          calcPoint0_perZMotor = rowData
        }
        else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT){
          calcPoint1_perZDirect = rowData
        }
        else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL){
          calcPoint1_perZTotal = rowData
        }
        else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT_MG){
          calcPoint1_perZDirectMG = rowData
        }
        else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL_MG){
          calcPoint1_perZTotalMG = rowData
        }
        else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_MOTOR){
          calcPoint1_perZMotor = rowData
        }
        else if(rowData.calc_point_id === 1){
          calcPoint0 = rowData
        }
        else if(rowData.calc_point_id === 2){
          calcPoint1 = rowData
        }
        else if(rowData.user_curve_point_id){
          listUserPoint.push(rowData)
        }
        else if(rowData.user_curve_id){
          listUser.push(rowData)
        }
        else if(rowData.no_trans3){
          mainProps = rowData
        }
        // if ('no_trans3' in e)
        //   mainProps = e
        // else {
        //   if (e.calc_type == 0) calcPoint0 = e
        //   else calcPoint1 = e
        // }
      })
      let ratedCurrentParmary = msTrans3GetRatedCurrent(
        mainProps.capacity,
        mainProps.volt_primary
      )
      let ratedCurrentSecondary = msTrans3GetRatedCurrent(
        mainProps.capacity,
        mainProps.volt_secondary
      )
      let calcData = {
        voltageSecondary: mainProps.volt_secondary,
        capacity: mainProps.capacity,
        percentR: doGetValuePercentRe(mainProps.xr,mainProps.per_z_k3),
        percentX: doGetValuePercentXe(mainProps.xr,doGetValuePercentRe(mainProps.xr,mainProps.per_z_k3)),
      }
      let defaultPower = msTransNormalCalculation(calcData)

      let inrushValue = msTrans3GetInrushValue(
        mainProps.inrush_times,
        mainProps.capacity,
        mainProps.volt_primary
      )
      
      let intensityValue = msTrans3GetIntensityValue(
        mainProps.intensity_times,
        mainProps.capacity,
        mainProps.volt_secondary
      )
      
      let calcData2 = {
        capacity: mainProps.capacity,
        voltageSecondary: mainProps.volt_secondary,
        voltageAdjust: mainProps.voltage_adjust,
        percentR: doGetValuePercentRe(mainProps.xr,mainProps.per_z_k3),
        percentX: doGetValuePercentXe(mainProps.xr,doGetValuePercentRe(mainProps.xr,mainProps.per_z_k3)),
        powerFactor: calcPoint0.power_factor,
        loadCurrent: calcPoint0.load_current,
        leadLag: calcPoint0.is_lead,
      }
      let voltageDrop1 = msTrans3GetDropVoltDiff(calcData2)
      let voltageDrop = msTrans3GetDropPerUnit(calcData2)
      return {
        // tab0 - 基本
        noTrans3: mainProps.no_trans3,
        refNo: mainProps.ref_no,
        pointText: mainProps.point_text,
        // voltagePrimaryList: [],
        voltagePrimary: mainProps.volt_primary,
        // voltageSecondaryList: [],
        voltageSecondary: mainProps.volt_secondary,
        // capacityList: [],
        capacity: mainProps.capacity,
        ratedCurrentParmary: BeamsNumber(ratedCurrentParmary, 4),
        ratedCurrentSecondary: BeamsNumber(ratedCurrentSecondary, 4),

        fault: mainProps.fault,
        viewResultText: mainProps.view_result_text,
        voltageAdjust: mainProps.voltage_adjust,
        // voltageTabPart: 0,

        xr: mainProps.xr,
        percentZ: mainProps.per_z_k3,
        percentR: BeamsNumber(doGetValuePercentRe(mainProps.xr,mainProps.per_z_k3),5),
        percentX: BeamsNumber(doGetValuePercentXe(mainProps.xr,doGetValuePercentRe(mainProps.xr,mainProps.per_z_k3)),5),
        ohmZ: BeamsNumber(defaultPower.ohmZ, 4),
        ohmR: BeamsNumber(defaultPower.ohmR, 4),
        ohmX: BeamsNumber(defaultPower.ohmX, 4),

        // // ModePM
        viewFlowResult: mainProps.view_flow_result,
        makeDataInput: mainProps.make_data_input,
        // makerNameList: [],
        makerName: mainProps.maker_name,
        type: mainProps.type,
        // makeYearList: [],
        makeYear: mainProps.make_year,
        // makeMonthList: [],
        makeMonth: mainProps.make_month,
        makeNumber: mainProps.make_number,

        // // tab1 - 保護協調
        dispInrush: mainProps.disp_inrush,
        inrushTimes: mainProps.inrush_times,
        inrushValue: BeamsNumber(inrushValue, 4),
        dampingTime: mainProps.damping_time,

        dispIntensity: mainProps.disp_intensity,
        intensityTimes: mainProps.intensity_times,
        intensityValue: BeamsNumber(intensityValue, 4),
        intensityTime: mainProps.intensity_time,

        // // tab2 - 電圧降下
        dropMotor: mainProps.drop_motor,
        dropRegular: mainProps.drop_regular,
        calcPoint0: doConvertDataCalcPoint(calcPoint0,
          calcPoint0_perZDirect,
          calcPoint0_perZTotal,
          calcPoint0_perZDirectMG,
          calcPoint0_perZTotalMG,
          calcPoint0_perZMotor),
        calcPoint1: doConvertDataCalcPoint(calcPoint1,
          calcPoint1_perZDirect,
          calcPoint1_perZTotal,
          calcPoint1_perZDirectMG,
          calcPoint1_perZTotalMG,
          calcPoint1_perZMotor),
      
        currentTopLeft: mainProps.current_top_left ? 1 : 0,
        voltageDrop1: voltageDrop1,
        voltageDrop: voltageDrop,

        // // tab3 - シンボル
        symKind: mainProps.exchange_number,
        haveTab: mainProps.have_tab,
        listUser: doConvertDataUserCurve(listUser,listUserPoint),
        
        // // others
        checkNG: mainProps.check_ng,
        selInrush: mainProps.sel_inrush,
        selIntensity: mainProps.sel_intensity,
        eleLineNumber: mainProps.ele_line_number,
        eleTopLeftNumber: mainProps.ele_top_left_number,
        eleBottomRightNumber: mainProps.ele_bottom_right_number,
        perUnitR: mainProps.per_unit_r,
        perUnitX: mainProps.per_unit_x,
        perUnitK: mainProps.per_unit_k,
        lineActiveFlow1: mainProps.line_active_flow1,
        lineReactiveFlow1: mainProps.line_reactive_flow1,
        lineActiveFlow2: mainProps.line_active_flow2,
        lineReactiveFlow2: mainProps.line_reactive_flow2,
        pageIndex: mainProps.page_index,
        supply: mainProps.supply,
        tcName: mainProps.tc_name,
        haveGround: mainProps.have_ground,
        neutralKind: mainProps.neutral_kind,
        inrushTimesKind: mainProps.inrush_times_kind,
        inrushManualValue: mainProps.inrush_manual_value,
        inrushTime: mainProps.inrush_time,
        voltageMagnitude1: mainProps.voltage_magnitude1,
        voltageAngle1: mainProps.voltage_angle1,
        voltageMagnitude2: mainProps.voltage_magnitude2,
        voltageAngle2: mainProps.voltage_angle2,
        inputedCapacity1: mainProps.inputed_capacity1,
        inputedCurrent1: mainProps.inputed_current1,
        inputedPowerFactor1: mainProps.inputed_power_factor1,
        dispAnsiKind: mainProps.disp_ansi_kind,
        ansiTimes: mainProps.ansi_times,
        ansiTime: mainProps.ansi_time,
        primarySupply: mainProps.primary_supply,
        examineComment: mainProps.examine_comment,
        seriesed: mainProps.seriesed,
        voltDropSumRe: mainProps.volt_drop_sum_re,
        voltDropSumIm: mainProps.volt_drop_sum_im,
        voltDropRouteRefNo: mainProps.volt_drop_route_ref_no,
        voltAdjustPart: mainProps.volt_adjust_part,
      } as MsTrans3Model;
    }
    case EKind.MS_GENERATOR:
      {
       let listUser :any [] = []
        let listUserPoint :any [] = []
        let calcPoint1 = {} as any
        let calcPoint0 = {} as any
        let mainProperties = {} as any
        let calcPoint0_perZDirect = {} as any
        let calcPoint0_perZTotal = {} as any
        let calcPoint0_perZDirectMG = {} as any
        let calcPoint0_perZTotalMG  = {} as any
        let calcPoint0_perZMotor  = {} as any

        let calcPoint1_perZDirect = {} as any
        let calcPoint1_perZTotal = {} as any
        let calcPoint1_perZDirectMG = {} as any
        let calcPoint1_perZTotalMG = {} as any
        let calcPoint1_perZMotor = {} as any

        properties.forEach(rowData =>{
          if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT){
            calcPoint0_perZDirect = rowData
          }
          else if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL){
            calcPoint0_perZTotal = rowData
          }
          else if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT_MG){
            calcPoint0_perZDirectMG = rowData
          }
          else if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL_MG){
            calcPoint0_perZTotalMG  = rowData
          }
          else if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_MOTOR){
            calcPoint0_perZMotor  = rowData
          }
          else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT){
            calcPoint1_perZDirect = rowData
          }
          else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL){
            calcPoint1_perZTotal = rowData
          }
          else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT_MG){
            calcPoint1_perZDirectMG = rowData
          }
          else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL_MG){
            calcPoint1_perZTotalMG = rowData
          }
          else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_MOTOR){
            calcPoint1_perZMotor = rowData
          }
          else if(rowData.calc_point_id === 1){
            calcPoint0 = rowData
          }
          else if(rowData.calc_point_id === 2){
            calcPoint1 = rowData
          }
          else if(rowData.user_curve_point_id){
            listUserPoint.push(rowData)
          }
          else if(rowData.user_curve_id){
            listUser.push(rowData)
          }
          else if(rowData.calc_point_id === undefined){
            mainProperties = rowData
          }
        })
        return {
          refNo: mainProperties.ref_no,
          noGenerator: mainProperties.no_generator,
          voltage: mainProperties.voltage,
          capacity: mainProperties.capacity,
          reactance: mainProperties.reactance,
          subReactance: mainProperties.sub_reactance,
          constant: mainProperties.constant,
          subConstant: mainProperties.sub_constant,
          fault: mainProperties.fault,
          checkNG: mainProperties.check_n_g,
          powerFactor:mainProperties.power_factor,
          nodeNumber:mainProperties.node_number,
          perUnitR:mainProperties.per_unit_r,
          perUnitX:mainProperties.per_unit_x,
          perUnitK:mainProperties.per_unit_k,
          perUnitP:mainProperties.per_unit_p,
          perUnitQ:mainProperties.per_unit_q,
          perUnitV:mainProperties.per_unit_v,
          nodeKind:mainProperties.node_kind,
          isLead:mainProperties.is_lead,
          voltageMagnitude:mainProperties.voltage_magnitude,
          voltageAngle:mainProperties.voltage_angle,
          activePower:mainProperties.active_power,
          reactivePower:mainProperties.reactive_power,
          Qmax:mainProperties.q_max,
          Qmin:mainProperties.q_min,
          makeDataInput:mainProperties.make_data_input,
          makerName:mainProperties.maker_name,
          makeYear:mainProperties.make_year,
          makeMonth:mainProperties.make_month,
          makeNumber:mainProperties.make_number,
          type:mainProperties.type,
          pointText: mainProperties.point_text,
          viewFlowResult:mainProperties.view_flow_result,
          examineComment: mainProperties.examine_comment,
          seriesed: mainProperties.seriesed,
          viewResultText: mainProperties.view_result_text,
          appointVoltage:mainProperties.appoint_voltage,
          calcPoint0: doConvertDataCalcPoint(calcPoint0,
            calcPoint0_perZDirect,
            calcPoint0_perZTotal,
            calcPoint0_perZDirectMG,
            calcPoint0_perZTotalMG,
            calcPoint0_perZMotor),
          calcPoint1: doConvertDataCalcPoint(calcPoint1,
            calcPoint1_perZDirect,
            calcPoint1_perZTotal,
            calcPoint1_perZDirectMG,
            calcPoint1_perZTotalMG,
            calcPoint1_perZMotor),
          listUser: doConvertDataUserCurve(listUser,listUserPoint)
        } as MsGeneratorModel
      }
    case EKind.MS_MOTOR_GROUP:
      {
        let listUser: any[] = []
        let listUserPoint :any [] = []
        let calcPoint1 = {} as any
        let calcPoint0 = {} as any
        let mainProperties = {} as any
        let calcPoint0_perZDirect = {} as any
        let calcPoint0_perZTotal = {} as any
        let calcPoint0_perZDirectMG = {} as any
        let calcPoint0_perZTotalMG  = {} as any
        let calcPoint0_perZMotor  = {} as any

        let calcPoint1_perZDirect = {} as any
        let calcPoint1_perZTotal = {} as any
        let calcPoint1_perZDirectMG = {} as any
        let calcPoint1_perZTotalMG = {} as any
        let calcPoint1_perZMotor = {} as any

        properties.forEach(rowData =>{
          if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT){
            calcPoint0_perZDirect = rowData
          }
          else if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL){
            calcPoint0_perZTotal = rowData
          }
          else if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT_MG){
            calcPoint0_perZDirectMG = rowData
          }
          else if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL_MG){
            calcPoint0_perZTotalMG  = rowData
          }
          else if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_MOTOR){
            calcPoint0_perZMotor  = rowData
          }
          else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT){
            calcPoint1_perZDirect = rowData
          }
          else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL){
            calcPoint1_perZTotal = rowData
          }
          else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT_MG){
            calcPoint1_perZDirectMG = rowData
          }
          else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL_MG){
            calcPoint1_perZTotalMG = rowData
          }
          else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_MOTOR){
            calcPoint1_perZMotor = rowData
          }
          else if(rowData.calc_point_id === 1){
            calcPoint0 = rowData
          }
          else if(rowData.calc_point_id === 2){
            calcPoint1 = rowData
          }
          else if(rowData.user_curve_point_id){
            listUserPoint.push(rowData)
          }
          else if(rowData.user_curve_id){
            listUser.push(rowData)
          }
          else if(rowData.calc_point_id === undefined){
            mainProperties = rowData
          }
        })
      let back_per_z_direct = 0
       let ratedCurrentMotor_Grp = BeamsNumber(mainProperties.load_factor * mainProperties.capacity * 1000 /mainProperties.voltage / Math.sqrt(3.0),4)
      // let subImpedance = Math.pow(properties[0].stator_per_r_k3 + properties[0].rotor_per_r_k3 + 0,2) + Math.pow(properties[0].sub_reactance + 0, 2)
      //     subImpedance = BeamsNumber(Math.sqrt(subImpedance),4)
      // let initialCurrent  = BeamsNumber((subImpedance === 0 ? 0 : ratedCurrent / subImpedance * 100)/1000,4)
      if(mainProperties.back_per_z_direct){ 
        back_per_z_direct = 1
      }
      else{
        back_per_z_direct = 0
      }

      return {
        refNo: mainProperties.ref_no,
        noMotorGrp: mainProperties.no_motor_grp,
        pointText: mainProperties.point_text,
        voltage: mainProperties.voltage,
        capacity: mainProperties.capacity,
        powerFactor: mainProperties.power_factor,
        loadFactor: mainProperties.load_factor,
        fault: mainProperties.fault,
        calcImprovePF: mainProperties.calc_improve_pf,
        improveAfterPF: mainProperties.after_p_f,
        viewFlowResult: mainProperties.view_flow_result,
        viewResultText: mainProperties.view_result_text,
        percentZCalculate: back_per_z_direct,
        subReactance: mainProperties.sub_reactance,
        rotorResistance: mainProperties.rotor_per_r_k3,
        statorResistance: mainProperties.stator_per_r_k3,
        capacity1: mainProperties.back_capacity,
        xr: mainProperties.back_xr,
        percentZ: mainProperties.back_percent_z,
        ratedCurrent:  ratedCurrentMotor_Grp,
        checkNG: mainProperties.check_ng,
        nodeNumber: mainProperties.node_number,
        perUnitR: mainProperties.per_unit_r,
        perUnitX: mainProperties.per_unit_x,
        perUnitK: mainProperties.per_unit_k,
        perUnitP: mainProperties.per_unit_p,
        perUnitQ: mainProperties.per_unit_q,
        isLead: mainProperties.is_lead,
        voltageMagnitude: mainProperties.voltage_magnitude,
        voltageAngle: mainProperties.voltage_angle,
        activePower: mainProperties.active_power,
        reactivePower: mainProperties.reactive_power,
        supply: mainProperties.supply,
        primarySupply: mainProperties.primary_supply,
        examineComment: mainProperties.examine_comment,
        calcPoint0: doConvertDataCalcPoint(calcPoint0,
          calcPoint0_perZDirect,
          calcPoint0_perZTotal,
          calcPoint0_perZDirectMG,
          calcPoint0_perZTotalMG,
          calcPoint0_perZMotor),
        calcPoint1: doConvertDataCalcPoint(calcPoint1,
          calcPoint1_perZDirect,
          calcPoint1_perZTotal,
          calcPoint1_perZDirectMG,
          calcPoint1_perZTotalMG,
          calcPoint1_perZMotor),  
        
        listUser: doConvertDataUserCurve(listUser,listUserPoint)
        }
      }
    case EKind.MS_ZEROCOND:
      {
        let perUnitZrZerocond ={} as any
        let listUser :any [] = []
        let listUserPoint :any [] = []
        let calcPoint1 = {} as any
        let calcPoint0 = {} as any
        let mainProperties = {} as any
        let calcPoint0_perZDirect = {} as any
        let calcPoint0_perZTotal = {} as any
        let calcPoint0_perZDirectMG = {} as any
        let calcPoint0_perZTotalMG  = {} as any
        let calcPoint0_perZMotor  = {} as any

        let calcPoint1_perZDirect = {} as any
        let calcPoint1_perZTotal = {} as any
        let calcPoint1_perZDirectMG = {} as any
        let calcPoint1_perZTotalMG = {} as any
        let calcPoint1_perZMotor = {} as any


        properties.forEach(rowData =>{
          if(rowData.calc_per_z_type === CALC_PER_Z_TYPE_ZEROCOND){
            perUnitZrZerocond = rowData
          }
          else if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT){
            calcPoint0_perZDirect = rowData
          }
          else if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL){
            calcPoint0_perZTotal = rowData
          }
          else if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT_MG){
            calcPoint0_perZDirectMG = rowData
          }
          else if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL_MG){
            calcPoint0_perZTotalMG  = rowData
          }
          else if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_MOTOR){
            calcPoint0_perZMotor  = rowData
          }
          else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT){
            calcPoint1_perZDirect = rowData
          }
          else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL){
            calcPoint1_perZTotal = rowData
          }
          else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT_MG){
            calcPoint1_perZDirectMG = rowData
          }
          else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL_MG){
            calcPoint1_perZTotalMG = rowData
          }
          else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_MOTOR){
            calcPoint1_perZMotor = rowData
          }
          else if(rowData.calc_point_id === 1){
          calcPoint0 = rowData
        }
        else if(rowData.calc_point_id === 2){
          calcPoint1 = rowData
        }
        else if(rowData.user_curve_point_id){
          listUserPoint.push(rowData)
        }
        else if(rowData.user_curve_id){
          listUser.push(rowData)
        }
        else if(rowData.calc_point_id === undefined){
          mainProperties = rowData
        }
      })
      return {
        refNo: mainProperties.ref_no,
        noZerocond: mainProperties.no_zero_cond,
        pointText: mainProperties.point_text,
        voltage: mainProperties.voltage,
        fault: mainProperties.fault,
        dropMotor: mainProperties.drop_motor,
        dropRegular: mainProperties.drop_regular,
        calcImprovePF: mainProperties.calc_improve_pf,
        calcRightLeft: mainProperties.calc_right_left,
        afterPF: mainProperties.after_pf,
        viewResultText: mainProperties.view_result_text,
        flowConductance: mainProperties.flow_conductance,
        flowSusceptance: mainProperties.flow_susceptance,
        viewFlowResult: mainProperties.view_flow_result,
        checkNG: mainProperties.check_ng,
        sPhase: mainProperties.s_phase,
        beforePF: mainProperties.before_pf,
        totalCurrent: mainProperties.total_current,
        nodeNumber: mainProperties.node_number,
        voltageMagnitude: mainProperties.voltage_magnitude,
        voltageAngle: mainProperties.voltage_angle,
        activePower: mainProperties.active_power,
        reactivePower: mainProperties.reactive_power,
        upTransKind: mainProperties.up_trans_kind,
        supply: mainProperties.supply,
        tcName: mainProperties.tc_name,
        primarySupply: mainProperties.primary_supply,
        examineComment: mainProperties.examine_comment,
        examineCommentSPhase: mainProperties.examine_comment_s_phase,
        seriesed: mainProperties.seriesed,
        voltDropSumRe1: mainProperties.volt_drop_sum_re1,
        voltDropSumIm1: mainProperties.volt_drop_sum_im1,
        voltDropSumRe2: mainProperties.volt_drop_sum_re2,
        voltDropSumIm2: mainProperties.volt_drop_sum_im2,
        voltDropRouteRefNo: mainProperties.volt_drop_route_ref_no,
        calcPoint0: doConvertDataCalcPoint(calcPoint0,
          calcPoint0_perZDirect,
          calcPoint0_perZTotal,
          calcPoint0_perZDirectMG,
          calcPoint0_perZTotalMG,
          calcPoint0_perZMotor),
        calcPoint1: doConvertDataCalcPoint(calcPoint1,
          calcPoint1_perZDirect,
          calcPoint1_perZTotal,
          calcPoint1_perZDirectMG,
          calcPoint1_perZTotalMG,
          calcPoint1_perZMotor),
        perUnitZrZerocond: printPercentZ(perUnitZrZerocond),
        listUser: doConvertDataUserCurve(listUser,listUserPoint)
      }
    }
    case EKind.MS_BUSBAR:
      {
        let listUser :any [] = []
        let listUserPoint :any [] = []
        let mainProperties = {} as any
        let calcPoint0 ={} as any
        let calcPoint1 ={} as any
        let m_3Phase  ={} as any
        let phaseN = {} as any
        let phaseNPE = {} as any
        let topLeft = 0
        let leadOrLag1 = 0
        let leadOrLag2 = 0

        let calcPoint0_perZDirect = {} as any
        let calcPoint0_perZTotal = {} as any
        let calcPoint0_perZDirectMG = {} as any
        let calcPoint0_perZTotalMG  = {} as any
        let calcPoint0_perZMotor  = {} as any

        let calcPoint1_perZDirect = {} as any
        let calcPoint1_perZTotal = {} as any
        let calcPoint1_perZDirectMG = {} as any
        let calcPoint1_perZTotalMG = {} as any
        let calcPoint1_perZMotor = {} as any

        properties.forEach(e =>{
          if(e.calc_point_id === 1 && e.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT){
            calcPoint0_perZDirect = e
          }
          else if(e.calc_point_id === 1 && e.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL){
            calcPoint0_perZTotal = e
          }
          else if(e.calc_point_id === 1 && e.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT_MG){
            calcPoint0_perZDirectMG = e
          }
          else if(e.calc_point_id === 1 && e.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL_MG){
            calcPoint0_perZTotalMG  = e
          }
          else if(e.calc_point_id === 1 && e.calc_per_z_type === CALC_PER_Z_TYPE_MOTOR){
            calcPoint0_perZMotor  = e
          }
          else if(e.calc_point_id === 2 && e.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT){
            calcPoint1_perZDirect = e
          }
          else if(e.calc_point_id === 2 && e.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL){
            calcPoint1_perZTotal = e
          }
          else if(e.calc_point_id === 2 && e.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT_MG){
            calcPoint1_perZDirectMG = e
          }
          else if(e.calc_point_id === 2 && e.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL_MG){
            calcPoint1_perZTotalMG = e
          }
          else if(e.calc_point_id === 2 && e.calc_per_z_type === CALC_PER_Z_TYPE_MOTOR){
            calcPoint1_perZMotor = e
          }
          else if(e.phase_kind === 1){
              m_3Phase  = e
            }
          else if(e.phase_kind === 2){
            phaseN = e

          }
          else if(e.phase_kind === 3 ){
            phaseNPE = e

          }
          else if(e.calc_point_id === 1){
            calcPoint0 = e

          }
          else if(e.calc_point_id === 2){
            calcPoint1 = e

          }
          else if(e.user_curve_point_id){
            listUserPoint.push(e)
          }
          else if(e.user_curve_id){
            listUser.push(e)
          }
          else if( e.phase_kind === undefined && e.calc_point_id === undefined){
            mainProperties = e

            }
          })
          // m_3Phase
          let capacityM_3Phase  = m_3Phase.basic_capacity_a
          if(m_3Phase.tempk2_a > 0){
            capacityM_3Phase *= m_3Phase.tempk2_a
          }
          if(m_3Phase.horizontalk3_a > 0){
            capacityM_3Phase *= m_3Phase.horizontalk3_a
          }
          if(m_3Phase.proximityk4_a > 0){
            capacityM_3Phase *= m_3Phase.proximityk4_a
          }
          let capacityM3Phase = BeamsNumber(capacityM_3Phase,4)

            if(calcPoint1.is_lead){
              leadOrLag2 = 1
            }
            else{
              leadOrLag2 = 0
            }
            if(calcPoint0.is_lead){
              leadOrLag1 = 1
            }
            else{
              leadOrLag1 = 0
            }
          if(mainProperties.current_top_left){
               topLeft = 1
          }
          else{
            topLeft = 0
          }
        return {
          refNo: mainProperties.ref_no,
          noBusBar: mainProperties.no_busbar,
          pointText: mainProperties.point_text,
          voltage: mainProperties.voltage,
          lenght: mainProperties.length,
          fault: mainProperties.fault,
          viewResultText: mainProperties.view_result_text,
          wireSystem: mainProperties.wire_system,
          makeDataInput: mainProperties.make_data_input,
          makerName: mainProperties.maker_name,
          type: mainProperties.type,
          makeYear: mainProperties.make_year,
          makeMonth: mainProperties.make_month,
          materialCuAl: mainProperties.material,
          bareOrPainted: mainProperties.surface,
          verticalOrHorizonal: mainProperties.install,
          busbarTemp: mainProperties.busbar_temp,
          ambient: mainProperties.ambient_temp,
          m_3Phase:{
            width: m_3Phase.width,
            thickness: m_3Phase.thickness,
            numberPhase: m_3Phase.number_phase,
            distance: m_3Phase.distance,
            skinEffectM: m_3Phase.skin_effect_m,
            ohmRPerM: m_3Phase.ohm_r_per_m,
            ohmXPerM: m_3Phase.ohm_x_per_m,
            basicCapacityM: m_3Phase.basic_capacity_m,
            tempK2M: m_3Phase.tempk2_m,
            horizontalK3M: m_3Phase.horizontalk3_m,
            proximityK4M:m_3Phase.proximityk4_m,

            skinEffectA: m_3Phase.skin_effect_a,
            ohmRPerA: m_3Phase.ohm_r_per_a,
            ohmXPerA: m_3Phase.ohm_x_per_a,
            basicCapacityA: m_3Phase.basic_capacity_a,
            tempK2A: m_3Phase.tempk2_a,
            horizontalK3A: m_3Phase.horizontalk3_a,
            proximityK4A:m_3Phase.proximityk4_a,
            capacity: capacityM3Phase
          },
          dropMotor: mainProperties.drop_motor,
          dropRegular: mainProperties.drop_regular,
          flowSusceptance: mainProperties.flow_susceptance,
          viewFlowResult: mainProperties.view_flow_result,
          topLeft : topLeft,
          leadOrLag1: leadOrLag1,
          leadOrLag2: leadOrLag2,
          phaseN:{
            width: phaseN.width,
            thickness: phaseN.thickness,
            numberPhase: phaseN.number_phase,
            distance: phaseN.distance,
            skinEffectA: phaseN.skin_effect_a,
            ohmRPerA: phaseN.ohm_r_per_a,
            ohmXPerA: phaseN.ohm_x_per_a,
            basicCapacityA: phaseN.basic_capacity_a,
            tempK2A: phaseN.tempk2_a,
            horizontalK3A: phaseN.horizontalk3_a,
            proximityK4A:phaseN.proximityk4_a,


            skinEffectM: phaseN.skin_effect_m,
            ohmRPerM: phaseN.ohm_r_per_m,
            ohmXPerM: phaseN.ohm_x_per_m,
            basicCapacityM: phaseN.basic_capacity_m,
            tempK2M: phaseN.tempk2_m,
            horizontalK3M: phaseN.horizontalk3_m,
            proximityK4M:phaseN.proximityk4_m
          },
          phaseNPE:{
            width: phaseNPE.width,
            thickness: phaseNPE.thickness,
            numberPhase: phaseNPE.number_phase,
            distance: phaseNPE.distance,
            skinEffectA: phaseNPE.skin_effect_a,
            ohmRPerA: phaseNPE.ohm_r_per_a,
            ohmXPerA: phaseNPE.ohm_x_per_a,
            basicCapacityA: phaseNPE.basic_capacity_a,
            tempK2A: phaseNPE.tempk2_a,
            horizontalK3A: phaseNPE.horizontalk3_a,
            proximityK4A:phaseNPE.proximityk4_a,

            skinEffectM: phaseNPE.skin_effect_m,
            ohmRPerM: phaseNPE.ohm_r_per_m,
            ohmXPerM: phaseNPE.ohm_x_per_m,
            basicCapacityM: phaseNPE.basic_capacity_m,
            tempK2M: phaseNPE.tempk2_m,
            horizontalK3M: phaseNPE.horizontalk3_m,
            proximityK4M:phaseNPE.proximityk4_m
          },
          sPhase: mainProperties.s_phase,
          checkNG: mainProperties.check_ng,
          eleLineNumber: mainProperties.ele_line_number,
          eleTopLeftNumber: mainProperties.ele_top_left_number,
          eleBottomRightNumber: mainProperties.ele_bottom_right_number,
          perUnitR: mainProperties.per_unit_r,
          perUnitX: mainProperties.per_unit_x,
          perUnitK: mainProperties.per_unit_k,
          lineActiveFlow1: mainProperties.line_active_flow1,
          lineActiveFlow2: mainProperties.line_active_flow2,
          lineReactiveFlow1: mainProperties.line_reactive_flow1,
          lineReactiveFlow2: mainProperties.line_reactive_flow2,
          upTransKind: mainProperties.up_trans_kind,
          supply: mainProperties.supply,
          tcName: mainProperties.tc_name,
          voltageMagnitude1: mainProperties.voltage_magnitude1,
          voltageMagnitude2: mainProperties.voltage_magnitude2,
          voltageAngle1: mainProperties.voltage_angle1,
          voltageAngle2: mainProperties.voltage_angle2,
          inputedCapacity1: mainProperties.inputed_capacity1,
          inputedCurrent1: mainProperties.inputed_current1,
          inputedPowerFactor1: mainProperties.inputed_power_factor1,
          inputedCapacity2: mainProperties.inputed_capacity2,
          inputedCurrent2: mainProperties.inputed_current2,
          inputedPowerFactor2: mainProperties.inputed_power_factor2,
          downTransKind: mainProperties.down_trans_kind,
          primarySupply: mainProperties.primary_supply,
          examineSomment: mainProperties.examine_comment,
          examineCommentSPhase: mainProperties.examine_comment_s_phase,
          seriesed: mainProperties.seriesed,
          volDropRouteRefNo: mainProperties.volt_drop_route_ref_no,
          cComplex:{
            voltDropSumRe:mainProperties.volt_drop_sum_re1,
            voltDropSumIm: mainProperties.volt_drop_sum_im1,
            voltDropSumRe2:mainProperties.volt_drop_sum_re2,
            voltDropSumIm2: mainProperties.volt_drop_sum_im2,
          },
          calcPoint0: doConvertDataCalcPoint(calcPoint0,
            calcPoint0_perZDirect,
            calcPoint0_perZTotal,
            calcPoint0_perZDirectMG,
            calcPoint0_perZTotalMG,
            calcPoint0_perZMotor),
          calcPoint1: doConvertDataCalcPoint(calcPoint1,
            calcPoint1_perZDirect,
            calcPoint1_perZTotal,
            calcPoint1_perZDirectMG,
            calcPoint1_perZTotalMG,
            calcPoint1_perZMotor),       
          listUser: doConvertDataUserCurve(listUser,listUserPoint)
        }
      }
    case EKind.MS_WIRE:
      {
        let listUser :any [] = []
        let listUserPoint :any [] = []
        let _3Phase = {} as any
        let phaseN = {} as any
        let phasePE = {} as any
        let wireItem = {} as any
        let calcPoint0 = {} as any
        let calcPoint1 = {} as any
        let calcPoint0_perZDirect = {} as any
        let calcPoint0_perZTotal = {} as any
        let calcPoint0_perZDirectMG = {} as any
        let calcPoint0_perZTotalMG  = {} as any
        let calcPoint0_perZMotor  = {} as any

        let calcPoint1_perZDirect = {} as any
        let calcPoint1_perZTotal = {} as any
        let calcPoint1_perZDirectMG = {} as any
        let calcPoint1_perZTotalMG = {} as any
        let calcPoint1_perZMotor  = {} as any
        properties.forEach((item:any) => {
          if(item.calc_point_id === 1 && item.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT){
            calcPoint0_perZDirect = item
          }
          else if(item.calc_point_id === 1 && item.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL){
            calcPoint0_perZTotal = item
          }
          else if(item.calc_point_id === 1 && item.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT_MG){
            calcPoint0_perZDirectMG = item
          }
          else if(item.calc_point_id === 1 && item.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL_MG){
            calcPoint0_perZTotalMG  = item
          }
          else if(item.calc_point_id === 1 && item.calc_per_z_type === CALC_PER_Z_TYPE_MOTOR){
            calcPoint0_perZMotor  = item
          }
          else if(item.calc_point_id === 2 && item.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT){
            calcPoint1_perZDirect = item
          }
          else if(item.calc_point_id === 2 && item.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL){
            calcPoint1_perZTotal = item
          }
          else if(item.calc_point_id === 2 && item.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT_MG){
            calcPoint1_perZDirectMG = item
          }
          else if(item.calc_point_id === 2 && item.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL_MG){
            calcPoint1_perZTotalMG = item
          }
          else if(item.calc_point_id === 2 && item.calc_per_z_type === CALC_PER_Z_TYPE_MOTOR){
            calcPoint1_perZMotor  = item
          }
          else if(item.calc_point_id === 1){
            calcPoint0 = item;
          }
          else if(item.calc_point_id === 2){
            calcPoint1 = item

          }
          else if(item.phase_kind == 1){
            _3Phase = item
          }
          else if(item.phase_kind == 2){
            phaseN = item
            }
          else if(item.phase_kind == 3){
            phasePE = item
          }
          else if(item.user_curve_point_id){
            listUserPoint.push(item)
          }
          else if(item.user_curve_id){
            listUser.push(item)
          }
          else{
            wireItem = item;
          }
        })
        return {
          noWire:wireItem.no_wire,
          refNo:wireItem.ref_no,
          sPhase:wireItem.s_phase,
          rotate:wireItem.rotate,
          voltage:wireItem.voltage,
          fault:wireItem.fault,
          dropRegular:wireItem.drop_regular,
          checkNG:wireItem.check_ng,
          wireSystem:wireItem.wire_system,
          material:wireItem.material,
          insulation:wireItem.insulation,
          core:wireItem.core,
          method:wireItem.method,
          circuitNumber:wireItem.circuit_number,
          ambientTemp:wireItem.ambient_temp,
          length:wireItem.length,
          dropMotor:wireItem.drop_motor,
          eleLineNumber:wireItem.ele_line_number,
          eleTopLeftNumber:wireItem.ele_top_left_number,
          eleBottomRightNumber:wireItem.ele_bottom_right_number,
          perUnitR:wireItem.per_unit_r,
          perUnitX:wireItem.per_unit_x,
          perUnitK:wireItem.per_unit_k,
          lineActiveFlow1:wireItem.line_active_flow1,
          lineReactiveFlow1:wireItem.line_reactive_flow1,
          lineActiveFlow2:wireItem.line_active_flow2,
          lineReactiveFlow2:wireItem.line_reactive_flow2,
          exchangeNumber:wireItem.exchange_number,
          upTransKind:wireItem.up_trans_kind,
          supply:wireItem.supply,
          tcName:wireItem.tc_name,
          makeDataInput: wireItem.make_data_input,
          makerName:wireItem.maker_name,
          makeYear: wireItem.make_year,
          makeMonth: wireItem.make_month,
          type: wireItem.type,
          voltageMagnitude1:wireItem.voltage_magnitude1,
          voltageAngle1:wireItem.voltage_angle1,
          voltageMagnitude2:wireItem.voltage_magnitude2,
          voltageAngle2:wireItem.voltage_angle2,
          pointText:wireItem.point_text,
          viewFlowResult:wireItem.view_flow_result,
          inputedCapacity1:wireItem.inputed_capacity1,
          inputedCurrent1:wireItem.inputed_current1,
          inputedPowerFactor1:wireItem.inputed_power_factor1,
          inputedCapacity2:wireItem.inputed_capacity2,
          inputedCurrent2:wireItem.inputed_current2,
          inputedPowerFactor2:wireItem.inputed_power_factor2,
          downTransKind:wireItem.down_trans_kind,
          primarySupply:wireItem.primary_supply,
          examineComment:wireItem.examine_comment,
          examineCommentSPhase:wireItem.examine_comment_s_phase,
          seriesed:wireItem.seriesed,
          viewResultText:wireItem.view_result_text,
          voltDropSumRe1:wireItem.volt_drop_sum_re1,
          voltDropSumIm1:wireItem.volt_drop_sum_im1,
          voltDropSumRe2:wireItem.volt_drop_sum_re2,
          voltDropSumIm2:wireItem.volt_drop_sum_im2,
          voltDropRouteRefNo:wireItem.volt_drop_route_ref_no,
          currentTopLeft:wireItem.current_top_left,
          flowSusceptance:wireItem.flow_susceptance,
          _3Phases:convertWirePhaseDataFromBE(_3Phase),
          PhaseN:convertWirePhaseDataFromBE(phaseN),
          PhasePE:convertWirePhaseDataFromBE(phasePE),
          calcPoint0: doConvertDataCalcPoint(calcPoint0, 
                                             calcPoint0_perZDirect, 
                                             calcPoint0_perZTotal, 
                                             calcPoint0_perZDirectMG, 
                                             calcPoint0_perZTotalMG,
                                             calcPoint0_perZMotor),
          calcPoint1:doConvertDataCalcPoint(calcPoint1,
                                            calcPoint1_perZDirect, 
                                            calcPoint1_perZTotal, 
                                            calcPoint1_perZDirectMG, 
                                            calcPoint1_perZTotalMG,
                                            calcPoint1_perZMotor),
          listUser: doConvertDataUserCurve(listUser,listUserPoint)
        } as MsWireModel
      }
    case EKind.MS_CONTACTOR:
      {
        let listUser :any [] = []
        let listUserPoint :any [] = []
        let calcPoint1 = {} as any
        let calcPoint0 = {} as any
        let mainProperties = {} as any
        let calcPoint0_perZDirect = {} as any
        let calcPoint0_perZTotal = {} as any
        let calcPoint0_perZDirectMG = {} as any
        let calcPoint0_perZTotalMG  = {} as any
        let calcPoint0_perZMotor  = {} as any

        let calcPoint1_perZDirect = {} as any
        let calcPoint1_perZTotal = {} as any
        let calcPoint1_perZDirectMG = {} as any
        let calcPoint1_perZTotalMG = {} as any
        let calcPoint1_perZMotor = {} as any

        properties.forEach(rowData =>{
          if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT){
            calcPoint0_perZDirect = rowData
          }
          else if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL){
            calcPoint0_perZTotal = rowData
          }
          else if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT_MG){
            calcPoint0_perZDirectMG = rowData
          }
          else if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL_MG){
            calcPoint0_perZTotalMG  = rowData
          }
          else if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_MOTOR){
            calcPoint0_perZMotor  = rowData
          }
          else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT){
            calcPoint1_perZDirect = rowData
          }
          else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL){
            calcPoint1_perZTotal = rowData
          }
          else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT_MG){
            calcPoint1_perZDirectMG = rowData
          }
          else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL_MG){
            calcPoint1_perZTotalMG = rowData
          }
          else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_MOTOR){
            calcPoint1_perZMotor = rowData
          }
          else if(rowData.calc_point_id === 1){
          calcPoint0 = rowData
        }
        else if(rowData.calc_point_id === 2){
          calcPoint1 = rowData
        }
        else if(rowData.user_curve_point_id){
          listUserPoint.push(rowData)
        }
        else if(rowData.user_curve_id){
          listUser.push(rowData)
        }
        else if(rowData.calc_point_id === undefined){
          mainProperties = rowData
        }
      })
      
      return {
        refNo: mainProperties.ref_no,
        noContactor: mainProperties.no_contactor,
        pointText: mainProperties.point_text,
        voltage: mainProperties.voltage,
        isOff: mainProperties.is_off,
        fault: mainProperties.fault,
        dropMotor: mainProperties.drop_motor,
        dropRegular: mainProperties.drop_regular,
        viewResultText: mainProperties.view_result_text,
        viewFlowResult: mainProperties.view_flow_result,
        makeDataInput: mainProperties.make_data_input,
        makerName: mainProperties.maker_name,
        type: mainProperties.type,
        makeYear: mainProperties.make_year,
        makeMonth: mainProperties.make_month,
        makeNumber: mainProperties.make_number,
        exchangeNumber: mainProperties.exchange_number,
        checkNG: mainProperties.check_ng,
        supply: mainProperties.supply,
        tcName: mainProperties.tc_name,
        lineActiveFlow1: mainProperties.line_active_flow1,
        lineActiveFlow2: mainProperties.line_active_flow2,
        lineReactiveFlow1: mainProperties.line_reactive_flow1,
        lineReactiveFlow2: mainProperties.line_reactive_flow2,
        primarySupply: mainProperties.primary_supply,
        upTransKind: mainProperties.up_trans_kind,
        examineComment: mainProperties.examine_comment,
        seriesed: mainProperties.seriesed,
        voltDropRouteRefno: mainProperties.volt_drop_route_ref_no,
        downTransKind: mainProperties.down_trans_kind,
        sPhase: mainProperties.s_phase,
        capacity: mainProperties.capacity,
        examineCommentSPhase: mainProperties.examine_comment_s_phase,
        cComplex:{
          voltDropSumRe:mainProperties.volt_drop_sum_re1,
          voltDropSumIm: mainProperties.volt_drop_sum_im1,
          voltDropSumRe2:mainProperties.volt_drop_sum_re2,
          voltDropSumIm2: mainProperties.volt_drop_sum_im2
        },
        calcPoint0: doConvertDataCalcPoint(calcPoint0,
                                          calcPoint0_perZDirect,
                                          calcPoint0_perZTotal,
                                          calcPoint0_perZDirectMG,
                                          calcPoint0_perZTotalMG,
                                          calcPoint0_perZMotor),
        calcPoint1: doConvertDataCalcPoint(calcPoint1,
                                            calcPoint1_perZDirect,
                                            calcPoint1_perZTotal,
                                            calcPoint1_perZDirectMG,
                                            calcPoint1_perZTotalMG,
                                            calcPoint1_perZMotor),
        listUser: doConvertDataUserCurve(listUser,listUserPoint)
      }
    }
    case EKind.MS_MOTOR:
      {
      let listUser :any [] = []
      let listUserPoint :any [] = []
      let harmonicList = [] as any;
      let mainProperties = {} as any;
      properties.forEach(rowData =>{
        if(rowData.harmo_list_id !== undefined){
          harmonicList.push(rowData);
        }
        else if(rowData.user_curve_point_id){
          listUserPoint.push(rowData)
        }
        else if(rowData.user_curve_id){
          listUser.push(rowData)
        }
        else if(rowData.harmo_list_id === undefined){
          mainProperties = rowData;
        }
      })
        return {
          refNo:mainProperties.ref_no,
          noMotor:mainProperties.no_motor,
          sPhase:mainProperties.s_phase,
          voltageID:mainProperties.voltage,
          capacity:mainProperties.capacity,
          powerFactor:mainProperties.power_factor,
          isLead:mainProperties.is_lead,
          startingTimes:mainProperties.starting_times,
          starting600:mainProperties.starting600,
          inrushTimes:mainProperties.inrush_times,
          checkNG: mainProperties.check_ng,
          calcDrop:mainProperties.calc_drop,
          startingFactor:mainProperties.starting_factor,
          efficiency:mainProperties.efficiency,
          useBaseLoad :mainProperties.use_base_load,
          baseCapacity:mainProperties.base_capacity,
          leadBaseLoad:mainProperties.lead_base_load,
          baseFactor:mainProperties.base_factor,
          calcImprovePF:mainProperties.calc_improve_pf,
          afterPF:mainProperties.after_pf,
          nodeNumber:mainProperties.node_number,
          perUnitR:mainProperties.per_unit_r,
          perUnitX:mainProperties.per_unit_x,
          perUnitK:mainProperties.per_unit_k,
          perUnitP:mainProperties.per_unit_p,
          perUnitQ:mainProperties.per_unit_q,
          loadFactor:mainProperties.load_factor,
          voltageMagnitude:mainProperties.voltage_magnitude,
          voltageAngle:mainProperties.voltage_angle,
          activePower:mainProperties.active_power,
          reactivePower:mainProperties.reactive_power,
          upTransKind:mainProperties.up_trans_kind,
          useEfficiency:mainProperties.use_efficiency,
          dispBaseLoad:mainProperties.disp_base_load,
          manualLoadCurrent:mainProperties.manual_load_current,
          loadCurrent:mainProperties.load_current,
          manualStartingCurrent:mainProperties.manual_starting_current,
          startingCurrent:mainProperties.starting_current,
          manualInrushCurrent:mainProperties.manual_inrush_current,
          inrushCurrent:mainProperties.inrush_current,
          pageIndex: mainProperties.page_index,
          supply: mainProperties.supply,
          tcName: mainProperties.tc_name,
          baseLoadInputManual: mainProperties.base_load_input_manual,
          baseCapacityM:mainProperties.base_capacity_m,
          leadBaseLoadM:mainProperties.lead_base_load_m,
          baseFactorM:mainProperties.base_factor_m,
          baseLoadCalcRightLeft: mainProperties.base_load_calc_right_left,
          makeDataInput:mainProperties.make_data_input,
          makerName:mainProperties.maker_name,
          makeYear:mainProperties.make_year,
          makeMonth:mainProperties.make_month,
          makeNumber:mainProperties.make_number,
          type:mainProperties.type,
          uIntHP:mainProperties.uint_hp,
          capacityID:mainProperties.kilo_watt_capacity,
          capacityHpID:mainProperties.horse_power_capacity,
          viewFlowResult: mainProperties.view_flow_result,
          primarySupply: mainProperties.primary_supply,
          viewResultText:mainProperties.view_result_text,
          inrush20Or40:mainProperties.inrush20or40,
          startingTime:mainProperties.startingtime,
          fullCurrent: mainProperties.load_current,
          listUser: doConvertDataUserCurve(listUser,listUserPoint),
          harmonicList:harmonicListMap(harmonicList)
        }
      }
    case EKind.MS_LOAD:
      {
        let listUser :any [] = []
        let listUserPoint :any [] = []
        let harmonicList = [] as any
        let mainProperties = {} as any
        properties.forEach(rowData =>{
          if(rowData.harmo_list_id !== undefined){
            harmonicList.push(rowData)
          }
          else if(rowData.user_curve_point_id){
            listUserPoint.push(rowData)
          }
          else if(rowData.user_curve_id){
            listUser.push(rowData)
          }
          else if(rowData.harmo_list_id === undefined){
            mainProperties = rowData
          }
      })
        let isLoadOrLag = 0// hard Code
          if(mainProperties.is_lead){
            isLoadOrLag = 1
          }
          else{
            isLoadOrLag = 0
          }
        let phaseCurrent = ""
        let powerFactor = mainProperties.power_factor
        
        if(powerFactor >=0 && powerFactor <=1){
          if(powerFactor < -1 || powerFactor > 1){
            phaseCurrent = ""
        }
        else{
          let phaseCurrent0 = BeamsNumber(Math.acos(powerFactor) * 180 / MS_PI,4)
          let phaseCurrent1 =""
          if(isLoadOrLag == 1){
              phaseCurrent1 = "+" + Math.abs(phaseCurrent0)
          }
          else if(isLoadOrLag == 0){
              phaseCurrent1 = "-" + Math.abs(phaseCurrent0)
          }
            phaseCurrent = phaseCurrent1
        }
        }
        else{
            phaseCurrent = ""
        }
      return {
        refNo: mainProperties.ref_no,
        noLoad: mainProperties.no_load,
        voltage: mainProperties.voltage,
        loadCurrent: mainProperties.load_current,
        leadOrLag: isLoadOrLag,
        powerFactor: mainProperties.power_factor,
        phaseCurrent: phaseCurrent,
        loadFactor: mainProperties.load_factor,
        startingCurrent: mainProperties.starting_current,
        startingTime: mainProperties.starting_time,
        calcImprovePF: mainProperties.calc_improve_pf,
        improveAfterPF: mainProperties.after_pf,
        viewResultText: mainProperties.view_result_text,
        viewFlowResult: mainProperties.view_flow_result,
        sPhase: mainProperties.s_phase,
        checkNG: mainProperties.check_ng,
        nodeNumber: mainProperties.node_number,
        perUnitR: mainProperties.per_unit_r,
        perUnitX: mainProperties.per_unit_x,
        perUnitK: mainProperties.per_unit_k,
        perUnitP: mainProperties.per_unit_p,
        perUnitQ: mainProperties.per_unit_q,
        voltageMagnitude: mainProperties.voltage_magnitude,
        voltageAngle: mainProperties.voltage_angle,
        activePower: mainProperties.active_power,
        reactivePower: mainProperties.reactive_power,
        upTransKind: mainProperties.up_trans_kind,
        supply: mainProperties.supply,
        tcName: mainProperties.tc_name,
        primarySupply: mainProperties.primary_supply,
        listUser: doConvertDataUserCurve(listUser,listUserPoint),
        harmonicList:harmonicListMap(harmonicList)
      }
    }
    case EKind.MS_CAPACITOR:
      {
      let listUser :any [] = []
      let listUserPoint :any [] = []
      let mainProps ={} as any
      properties.forEach((rowData:any)=>{

      if(rowData.user_curve_point_id){
        listUserPoint.push(rowData)
      }
      else if(rowData.user_curve_id){
        listUser.push(rowData)
      }
      else{
        mainProps = rowData
      }
      })

      let dRatedCurrent = 0
      if(mainProps.voltage <=0){
        dRatedCurrent = 0;
      }
      else{
        dRatedCurrent = BeamsNumber(mainProps.capacity * 1000 / mainProps.voltage / Math.sqrt(3.0),4)
      }
      return {
        refNo: mainProps.ref_no,
        noCapacitor: mainProps.no_capacitor,
        voltage: mainProps.voltage,
        capacity: mainProps.capacity,
        reactor: mainProps.reactor,
        ratedCurrent: dRatedCurrent,
        ratedCurrent2: dRatedCurrent,

        powerFactor: mainProps.power_factor,
        loadFactor: mainProps.load_factor,
        viewFlowResult: mainProps.view_flow_result,
        viewResultText: mainProps.view_result_text,
        makeDataInput: mainProps.make_data_input,
        makerName: mainProps.maker_name,
        type: mainProps.type,
        makeYear: mainProps.make_year,
        makeMonth: mainProps.make_number,
        makeNumber: mainProps.maker_name,
        totalOhmR: mainProps.total_ohm_r,
        totalOhmX: mainProps.total_ohm_x,
        calcOhmR: mainProps.calc_ohm_r,
        calcOhmX: mainProps.calc_ohm_x,

        isLead: mainProps.is_lead,
        checkNG: mainProps.check_ng,
        curveSel: mainProps.curve_sel,
        nodeNumber: mainProps.node_number,
        perUnitR: mainProps.per_unit_r,
        perUnitX: mainProps.per_unit_x,
        perUnitK: mainProps.per_unit_k,
        perUnitP: mainProps.per_unit_p,
        perUnitQ: mainProps.per_unit_q,
        voltageMagnitude: mainProps.voltage_magnitude,
        voltageAngle: mainProps.voltage_angle,
        activePower: mainProps.active_power,
        reactivePower: mainProps.reactive_power,
        pageIndex: mainProps.page_index,
        supply: mainProps.supply,
        tcName: mainProps.tc_name,
        primarySupply: mainProps.primary_supply,
        eleNeutralPointNumber: mainProps.ele_neutral_point_number,
        eleLineNumber: mainProps.ele_line_number,
        reactorPerUnitR: mainProps.reactor_per_unit_r,
        reactorPerUnitX: mainProps.reactor_per_unit_x,
        reactorPerUnitK: mainProps.reactor_per_unit_k,
        lineActiveFlow1: mainProps.line_active_flow1,
        lineReactiveFlow1: mainProps.line_reactive_flow1,
        lineActiveFlow2: mainProps.line_active_flow2,
        lineReactiveFlow2: mainProps.line_reactive_flow2,
        listUser: doConvertDataUserCurve(listUser,listUserPoint)
      }
      }
    case EKind.MS_2E:
    {
      let listUser :any [] = []
      let listUserPoint :any [] = []
      let mainProps: any, base: any, adjust: any
      let parts: PartDetailsModel[] = []
      let calcPoint1 = {} as any
      let calcPoint0 = {} as any
      let calcPoint0_perZDirect = {} as any
      let calcPoint0_perZTotal = {} as any
      let calcPoint0_perZDirectMG = {} as any
      let calcPoint0_perZTotalMG  = {} as any
      let calcPoint0_perZMotor  = {} as any

      let calcPoint1_perZDirect = {} as any
      let calcPoint1_perZTotal = {} as any
      let calcPoint1_perZDirectMG = {} as any
      let calcPoint1_perZTotalMG = {} as any
      let calcPoint1_perZMotor = {} as any

      properties.forEach(rowData =>{
        if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT){
          calcPoint0_perZDirect = rowData
        }
        else if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL){
          calcPoint0_perZTotal = rowData
        }
        else if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT_MG){
          calcPoint0_perZDirectMG = rowData
        }
        else if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL_MG){
          calcPoint0_perZTotalMG  = rowData
        }
        else if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_MOTOR){
          calcPoint0_perZMotor  = rowData
        }
        else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT){
          calcPoint1_perZDirect = rowData
        }
        else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL){
          calcPoint1_perZTotal = rowData
        }
        else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT_MG){
          calcPoint1_perZDirectMG = rowData
        }
        else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL_MG){
          calcPoint1_perZTotalMG = rowData
        }
        else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_MOTOR){
          calcPoint1_perZMotor = rowData
        }
        else if(rowData.calc_point_id === 1){
          calcPoint0 = rowData
        }
        else if(rowData.calc_point_id === 2){
          calcPoint1 = rowData
        }
        else if(rowData.user_curve_point_id){
          listUserPoint.push(rowData)
        }
        else if(rowData.user_curve_id){
          listUser.push(rowData)
        }
        else if(rowData.no_2e){
          mainProps = rowData
        }
        else if("type_id" in rowData){
          base = rowData
        }
        else if("part_id" in rowData){
          parts.push(rowData as PartDetailsModel) 
        }
        else if("draw_three_curve" in rowData){
          adjust = rowData
        }
    
    })
      // properties.forEach((e: any) => {
      //   if ('no_2e' in e)
      //     mainProps = e
      //   else if ('type_id' in e)
      //     base = e
      //   else if ('part_id' in e)
      //     parts.push(e as PartDetailsModel) 
      //   else if ('draw_three_curve' in e)
      //     adjust = e
      // })
      return {
        // 基本
        no2E: mainProps.no_2e,
        refNo: mainProps.ref_no,
        pointText: mainProps.point_text,
        // voltageList: [],
        voltage: mainProps.voltage,
        isOff: mainProps.is_off,
        fault: mainProps.fault,
        dropMotor: mainProps.drop_motor,
        dropRegular: mainProps.drop_regular,
        viewResultText: mainProps.view_result_text,
        
        // ModePM
        viewFlowResult: mainProps.view_flow_result,
        makeDataInput: mainProps.make_data_input,
        // makeYearList: [],
        makeYear: mainProps.make_year,
        // makeMonthList: [],
        makeMonth: mainProps.make_month,
        makeNumber: mainProps.make_number,

        // 保護協調
        details: {
          base: convertBaseFromBEToFE(base),
          parts: convertPartsFromBEToFE(parts),
          //TODO: delaySettings
          
          refNo2: adjust.ref_no,
          drawThreeCurve: adjust.draw_three_curve,
        },
        // シンボル
        symKind: mainProps.exchange_number,
        listUser: doConvertDataUserCurve(listUser,listUserPoint),
        
        // others
        makerName: mainProps.maker_name,
        sPhase: mainProps.s_phase,
        upTransKind: mainProps.up_trans_kind,
        checkNG: mainProps.check_ng,
        supply: mainProps.supply,
        tcName: mainProps.tc_name,
        lineActiveFlow1: mainProps.line_active_flow1,
        lineReactiveFlow1: mainProps.line_reactive_flow1,
        lineActiveFlow2: mainProps.line_active_flow2,
        lineReactiveFlow2: mainProps.line_reactive_flow2,
        downTransKind: getElementKindLabel(mainProps.down_trans_kind),
        primarySupply: mainProps.primary_supply,
        examineComment: mainProps.examine_comment,
        examineCommentSPhase: mainProps.examine_comment_s_phase	,
        seriesed: mainProps.seriesed,
        voltDropSumRe1: mainProps.volt_drop_sum_re1,
        voltDropSumIm1: mainProps.volt_drop_sum_im1,
        voltDropSumRe2: mainProps.volt_drop_sum_re2,
        voltDropSumIm2: mainProps.volt_drop_sum_im2,
        voltDropRouteRefNo: mainProps.volt_drop_route_ref_no,
        calcPoint0: doConvertDataCalcPoint(calcPoint0,
          calcPoint0_perZDirect,
          calcPoint0_perZTotal,
          calcPoint0_perZDirectMG,
          calcPoint0_perZTotalMG,
          calcPoint0_perZMotor),
        calcPoint1: doConvertDataCalcPoint(calcPoint1,
          calcPoint1_perZDirect,
          calcPoint1_perZTotal,
          calcPoint1_perZDirectMG,
          calcPoint1_perZTotalMG,
          calcPoint1_perZMotor),
      } as Ms2EModel;
    }
    case EKind.MS_FUSE:
    {
      let listUser :any [] = []
      let listUserPoint :any [] = []
      let fuseProps: any, fuseBase: any, fuseAdjust: any
      let fuseParts: PartDetailsModel[] = []
      // properties.forEach((e: any) => {
      //   if ('no_fuse' in e)
      //     fuseProps = e
      //   else if ('type_id' in e)
      //     fuseBase = e
      //   else if ('part_id' in e)
      //     fuseParts.push(e as PartDetailsModel) 
      //   else if ('draw_three_curve' in e)
      //     fuseAdjust = e
      // })
      let calcPoint1 = {} as any
      let calcPoint0 = {} as any
      let calcPoint0_perZDirect = {} as any
      let calcPoint0_perZTotal = {} as any
      let calcPoint0_perZDirectMG = {} as any
      let calcPoint0_perZTotalMG  = {} as any
      let calcPoint0_perZMotor  = {} as any

      let calcPoint1_perZDirect = {} as any
      let calcPoint1_perZTotal = {} as any
      let calcPoint1_perZDirectMG = {} as any
      let calcPoint1_perZTotalMG = {} as any
      let calcPoint1_perZMotor = {} as any


      properties.forEach(rowData =>{
        if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT){
          calcPoint0_perZDirect = rowData
        }
        else if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL){
          calcPoint0_perZTotal = rowData
        }
        else if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT_MG){
          calcPoint0_perZDirectMG = rowData
        }
        else if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL_MG){
          calcPoint0_perZTotalMG  = rowData
        }
        else if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_MOTOR){
          calcPoint0_perZMotor  = rowData
        }
        else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT){
          calcPoint1_perZDirect = rowData
        }
        else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL){
          calcPoint1_perZTotal = rowData
        }
        else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT_MG){
          calcPoint1_perZDirectMG = rowData
        }
        else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL_MG){
          calcPoint1_perZTotalMG = rowData
        }
        else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_MOTOR){
          calcPoint1_perZMotor = rowData
        }
        else if(rowData.calc_point_id === 1){
          calcPoint0 = rowData
        }
        else if(rowData.calc_point_id === 2){
          calcPoint1 = rowData
        }
        else if(rowData.user_curve_point_id){
          listUserPoint.push(rowData)
        }
        else if(rowData.user_curve_id){
          listUser.push(rowData)
        }
        else if(rowData.no_fuse){
          fuseProps = rowData
        }
        else if("type_id" in rowData){
          fuseBase = rowData
        }
        else if("part_id" in rowData){
          fuseParts.push(rowData as PartDetailsModel) 
        }
        else if("draw_three_curve" in rowData){
          fuseAdjust = rowData
        }
    
    })
      return {
        // 基本
        refNo: fuseProps.ref_no,
        noFUSE: fuseProps.no_fuse,
        pointText: fuseProps.point_text,
        // voltageList: [],
        voltage: fuseProps.voltage,
        isOff: fuseProps.is_off,
        fault: fuseProps.fault,
        dropMotor: fuseProps.drop_motor,
        dropRegular: fuseProps.drop_regular,
        viewResultText: fuseProps.view_result_text,
        // ModePM
        viewFlowResult: fuseProps.view_flow_result,
        makeDataInput: fuseProps.make_data_input,
        makerName: fuseProps.maker_name,
        // makeYearList: [],
        makeYear: fuseProps.make_year,
        // makeMonthList: [],
        makeMonth: fuseProps.make_month,
        makeNumber: fuseProps.make_number,
        // 保護協調
        details: {
          base: convertBaseFromBEToFE(fuseBase),
          parts: convertPartsFromBEToFE(fuseParts),
          //TODO: delaySettings
          
          refNo2: fuseAdjust.ref_no,
          drawThreeCurve: fuseAdjust.draw_three_curve,
        },
        // シンボル
        symKind: fuseProps.exchange_number,
        listUser: doConvertDataUserCurve(listUser,listUserPoint),
        
        // others
        checkNG: fuseProps.check_ng,
        supply: fuseProps.supply,
        tcName: fuseProps.tc_name	,
        lineActiveFlow1: fuseProps.line_active_flow1,
        lineReactiveFlow1: fuseProps.line_reactive_flow1,
        lineActiveFlow2: fuseProps.line_active_flow2,
        lineReactiveFlow2: fuseProps.line_reactive_flow2,
        downTransKind: getElementKindLabel(fuseProps.down_trans_kind),
        primarySupply: fuseProps.primary_supply,
        examineComment: fuseProps.examine_comment,
        seriesed: fuseProps.seriesed,
        voltDropSumRe: fuseProps.volt_drop_sum_re,
        voltDropSumIm: fuseProps.volt_drop_sum_im,
        voltDropRouteRefNo: fuseProps.volt_drop_route_ref_no,
        rotate: fuseProps.rotate,
        calcPoint0: doConvertDataCalcPoint(calcPoint0,
          calcPoint0_perZDirect,
          calcPoint0_perZTotal,
          calcPoint0_perZDirectMG,
          calcPoint0_perZTotalMG,
          calcPoint0_perZMotor),
        calcPoint1: doConvertDataCalcPoint(calcPoint1,
          calcPoint1_perZDirect,
          calcPoint1_perZTotal,
          calcPoint1_perZDirectMG,
          calcPoint1_perZTotalMG,
          calcPoint1_perZMotor),
      } as MsFUSEModel;
    }
    case EKind.MS_MVCB:
      {
        let listUser :any [] = []
        let listUserPoint :any [] = []
        let calcPoint1 = {} as any
        let calcPoint0 = {} as any
        let mainProperties = {} as any
        let calcPoint0_perZDirect = {} as any
        let calcPoint0_perZTotal = {} as any
        let calcPoint0_perZDirectMG = {} as any
        let calcPoint0_perZTotalMG  = {} as any
        let calcPoint0_perZMotor  = {} as any

        let calcPoint1_perZDirect = {} as any
        let calcPoint1_perZTotal = {} as any
        let calcPoint1_perZDirectMG = {} as any
        let calcPoint1_perZTotalMG = {} as any
        let calcPoint1_perZMotor = {} as any

        properties.forEach(rowData =>{
          if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT){
            calcPoint0_perZDirect = rowData
          }
          else if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL){
            calcPoint0_perZTotal = rowData
          }
          else if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT_MG){
            calcPoint0_perZDirectMG = rowData
          }
          else if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL_MG){
            calcPoint0_perZTotalMG  = rowData
          }
          else if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_MOTOR){
            calcPoint0_perZMotor  = rowData
          }
          else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT){
            calcPoint1_perZDirect = rowData
          }
          else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL){
            calcPoint1_perZTotal = rowData
          }
          else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT_MG){
            calcPoint1_perZDirectMG = rowData
          }
          else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL_MG){
            calcPoint1_perZTotalMG = rowData
          }
          else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_MOTOR){
            calcPoint1_perZMotor = rowData
          }
          else if(rowData.calc_point_id === 1){
            calcPoint0 = rowData
          }
          else if(rowData.calc_point_id === 2){
            calcPoint1 = rowData
          }
          else if(rowData.user_curve_point_id){
            listUserPoint.push(rowData)
          }
          else if(rowData.user_curve_id){
            listUser.push(rowData)
          }
          else if(rowData.calc_point_id === undefined){
            mainProperties = rowData
          }
        })
      
      return {
        refNo: mainProperties.ref_no,
        noMVCB: mainProperties.no_mvcb,
        pointText: mainProperties.point_text,
        voltage: mainProperties.voltage,
        breakCapacity: mainProperties.break_capacity,
        isOff: mainProperties.is_off,
        fault: mainProperties.fault,
        dropMotor: mainProperties.drop_motor,
        dropRegular: mainProperties.drop_regular,
        viewFlowResult: mainProperties.view_flow_result,
        connectSwitch: mainProperties.connect_switch,
        viewResultText: mainProperties.view_result_text,
        makerName: mainProperties.maker_name,
        type: mainProperties.type,
        makeYear: mainProperties.make_year,
        makeMonth: mainProperties.make_month,
        makeNumber: mainProperties.make_number,
        makeDataInput: mainProperties.make_data_input,
        exchangeNumber: mainProperties.exchange_number,

        checkNG: mainProperties.check_ng,
        supply: mainProperties.supply,
        tcName: mainProperties.tc_name,
        lineActiveFlow1: mainProperties.line_active_flow1,
        lineActiveFlow2: mainProperties.line_active_flow2,
        lineReactiveFlow1: mainProperties.line_reactive_flow1,
        lineReactiveFlow2: mainProperties.line_reactive_flow2,
        eleTopLeftNumber: mainProperties.ele_top_left_number,
        eleBottomRightNumber: mainProperties.ele_bottom_right_number,
        primarySupply: mainProperties.primary_supply,
        upTransKind: mainProperties.up_trans_kind,
        examineComment: mainProperties.examine_comment,
        examineCommentSPhase: mainProperties.examine_comment_s_phase,
        seriesed: mainProperties.seriesed,
        voltDropRouteRefno: mainProperties.volt_drop_route_ref_no,
        downTransKind: mainProperties.down_trans_kind,
        sPhase: mainProperties.s_phase,
        cComplex:{
          voltDropSumRe:mainProperties.volt_drop_sum_re1,
          voltDropSumIm: mainProperties.volt_drop_sum_im1,
          voltDropSumRe2:mainProperties.volt_drop_sum_re2,
          voltDropSumIm2: mainProperties.volt_drop_sum_im2
        },
        calcPoint0: doConvertDataCalcPoint(calcPoint0,
          calcPoint0_perZDirect,
          calcPoint0_perZTotal,
          calcPoint0_perZDirectMG,
          calcPoint0_perZTotalMG,
          calcPoint0_perZMotor),
        calcPoint1: doConvertDataCalcPoint(calcPoint1,
          calcPoint1_perZDirect,
          calcPoint1_perZTotal,
          calcPoint1_perZDirectMG,
          calcPoint1_perZTotalMG,
          calcPoint1_perZMotor),
        listUser: doConvertDataUserCurve(listUser,listUserPoint)
      }
    }
      case EKind.MS_REACTOR:
        {
          let listUser :any [] = []
          let listUserPoint :any [] = []
          let ratedCurrent = 0
          let loadCapacity = 0
          let loadCurrent = 0
          let powerFactor = 0
          let leadOrLag = 0 //true = 0, false = 1
          let calcPoint1 = {} as any
          let calcPoint0 = {} as any
          let mainProperties = {} as any
          let current_top_left = 0
          let calcPoint0_perZDirect = {} as any
          let calcPoint0_perZTotal = {} as any
          let calcPoint0_perZDirectMG = {} as any
          let calcPoint0_perZTotalMG  = {} as any
          let calcPoint0_perZMotor  = {} as any

          let calcPoint1_perZDirect = {} as any
          let calcPoint1_perZTotal = {} as any
          let calcPoint1_perZDirectMG = {} as any
          let calcPoint1_perZTotalMG = {} as any
          let calcPoint1_perZMotor = {} as any

        properties.forEach(rowData =>{
          if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT){
            calcPoint0_perZDirect = rowData
          }
          else if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL){
            calcPoint0_perZTotal = rowData
          }
          else if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT_MG){
            calcPoint0_perZDirectMG = rowData
          }
          else if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL_MG){
            calcPoint0_perZTotalMG  = rowData
          }
          else if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_MOTOR){
            calcPoint0_perZMotor  = rowData
          }
          else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT){
            calcPoint1_perZDirect = rowData
          }
          else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL){
            calcPoint1_perZTotal = rowData
          }
          else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT_MG){
            calcPoint1_perZDirectMG = rowData
          }
          else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL_MG){
            calcPoint1_perZTotalMG = rowData
          }
          else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_MOTOR){
            calcPoint1_perZMotor = rowData
          }
          else if(rowData.calc_point_id === 1){
              calcPoint0 = rowData
            }
          else if(rowData.calc_point_id === 2){
              calcPoint1 = rowData
            }
          else if(rowData.user_curve_point_id){
            listUserPoint.push(rowData)
          }
          else if(rowData.user_curve_id){
            listUser.push(rowData)
          }
          else if(rowData.calc_point_id === undefined){
              mainProperties = rowData
            }
          })

          if(mainProperties.current_top_left){
            current_top_left = 1
          }else{
            current_top_left = 0
          }

          if(mainProperties.voltage === 0){
            ratedCurrent = 0;
          }else{
              ratedCurrent = BeamsNumber(mainProperties.capacity * 1000 /mainProperties.voltage/Math.sqrt(3.0),4)
          }
        if(mainProperties.down_trans_kind === MS_TRANS1){
          loadCapacity = calcPoint1.load_capacity
          loadCurrent = calcPoint1.load_current
          powerFactor = calcPoint1.power_factor

          if(calcPoint1.is_lead){
            leadOrLag = 1
          }
          else{
            leadOrLag = 0
          }
        }
        else{
          loadCapacity = calcPoint0.load_capacity
          loadCurrent = calcPoint0.load_current
          powerFactor = calcPoint0.power_factor

          if(calcPoint0.is_lead){
            leadOrLag = 1
          }
          else{
            leadOrLag = 0
          }
        }
        return {
          refNo: mainProperties.ref_no,
          noReactor: mainProperties.no_reactor,
          fault: mainProperties.fault,
          pointText: mainProperties.point_text,
          viewFlowResult: mainProperties.view_flow_result,
          viewResultText: mainProperties.view_result_text,
          voltage: mainProperties.voltage,
          capacity: mainProperties.capacity,
          percentX: mainProperties.percent_x,
          makeDataInput: mainProperties.make_data_input,
          makerName: mainProperties.maker_name,
          type: mainProperties.type,
          makeYear: mainProperties.make_year,
          makeMonth: mainProperties.make_month,
          makeNumber: mainProperties.make_number,
          dropMotor: mainProperties.drop_motor,
          dropRegular: mainProperties.drop_regular,
          loadCapacity : loadCapacity,
          loadCurrent : loadCurrent,
          powerFactor :powerFactor,
          leadOrLag : leadOrLag,
          ratedCurrent: ratedCurrent,
          topLeft: current_top_left,

          inputCurrent1: mainProperties.inputed_current1,
          inputCapacity1: mainProperties.inputed_capacity1,
          inputPowerFactor1: mainProperties.inputed_power_factor1,
          downTransKind: mainProperties.down_trans_kind,
          percentR: mainProperties.percent_r,
          checkNG: mainProperties.check_ng,
          eleLineNumber: mainProperties.ele_line_number,
          eleTopLeftNumber: mainProperties.ele_top_left_number,
          eleBottomRight_number: mainProperties.ele_bottom_right_number,
          perUnitR: mainProperties.per_unit_r,
          perUnitX: mainProperties.per_unit_x,
          perUnitK: mainProperties.per_unit_k,
          lineActiveFlow1: mainProperties.line_active_flow1,
          lineReactiveFlow1: mainProperties.line_reactive_flow1,
          lineActiveFlow2: mainProperties.line_active_flow2,
          lineReactiveFlow2: mainProperties.line_reactive_flow2,
          supply: mainProperties.supply,
          tcName: mainProperties.tc_name,
          voltageMagnitude1: mainProperties.voltage_magnitude1,
          voltageAngle1: mainProperties.voltage_angle1,
          voltageMagnitude2: mainProperties.voltage_magnitude2,
          voltageAngle2: mainProperties.voltage_angle2,
          primarySupply: mainProperties.primary_supply,
          examineComment: mainProperties.examine_comment,
          seriesed: mainProperties.seriesed,
          voltDropRouteRefNo: mainProperties.volt_drop_route_ref_no,
          cComplex:{
            voltDropSumRe:mainProperties.volt_drop_sum_re,
            voltDropSumIm: mainProperties.volt_drop_sum_im,
          },
          calcPoint0: doConvertDataCalcPoint(calcPoint0,
            calcPoint0_perZDirect,
            calcPoint0_perZTotal,
            calcPoint0_perZDirectMG,
            calcPoint0_perZTotalMG,
            calcPoint0_perZMotor),
          calcPoint1: doConvertDataCalcPoint(calcPoint1,
            calcPoint1_perZDirect,
            calcPoint1_perZTotal,
            calcPoint1_perZDirectMG,
            calcPoint1_perZTotalMG,
            calcPoint1_perZMotor),
          listUser: doConvertDataUserCurve(listUser,listUserPoint)
        }
      }
    case EKind.MS_3WINDING:
      {
        let listUser :any [] = []
        let listUserPoint :any [] = []
        let _3Winding = {} as any
        let partPrimary = [] as any
        let partSecondary = [] as any
        let partThird = [] as any
        properties.forEach((item:any)=>{

          if(item.ele_3winding_part_kind){
            if(item.ele_3winding_part_kind === 1)
            {
              partPrimary.push(item)
            }
            else if(item.ele_3winding_part_kind === 2)
            {
              partSecondary.push(item)
            }
            else if(item.ele_3winding_part_kind === 3)
            {
              partThird.push(item)
            }
          }
          else if(item.user_curve_point_id){
            listUserPoint.push(item)
          }
          else if(item.user_curve_id){
            listUser.push(item)
          }
          else if(item.ref_no){
            _3Winding = item;
          }

        })

        return {
          refNo: _3Winding.ref_no,
          no3Winding: _3Winding.no_3winding,
          checkNG:_3Winding.check_ng,
          eleNeutralPointNumber:_3Winding.ele_neutral_point_number,
          supply:_3Winding.supply,
          tcName:_3Winding.tc_name,
          symKind:_3Winding.exchange_number,
          makeDataInput:_3Winding.make_data_input,
          makerName:_3Winding.maker_name,
          makeYear:_3Winding.make_year,
          makeMonth:_3Winding.make_month,
          makeNumber:_3Winding.make_number,
          type:_3Winding.type,
          viewFlowResult:_3Winding.view_flow_result,
          primarySupply:_3Winding.primary_supply,
          seriesed:_3Winding.seriesed,
          viewResultText:_3Winding.view_result_text,
          partPrimary:convertDataWindingPart(partPrimary),
          partSecondary:convertDataWindingPart(partSecondary),
          partThird:convertDataWindingPart(partThird),
          listUser: doConvertDataUserCurve(listUser,listUserPoint)
        } as Ms3WindingModel
      }
    case EKind.MS_BUSDUCT:
      {
        let listUser :any [] = []
        let listUserPoint :any [] = []
        let mainProperties = {} as any
        let calcPoint0 = {} as any
        let calcPoint1 = {} as any
        let m_3Sphase = {} as any
        let SPhaseN = {} as any
        let sPhasePE = {} as any
        let calcPoint0_perZDirect = {} as any
        let calcPoint0_perZTotal = {} as any
        let calcPoint0_perZDirectMG = {} as any
        let calcPoint0_perZTotalMG  = {} as any
        let calcPoint0_perZMotor  = {} as any

        let calcPoint1_perZDirect = {} as any
        let calcPoint1_perZTotal = {} as any
        let calcPoint1_perZDirectMG = {} as any
        let calcPoint1_perZTotalMG = {} as any
        let calcPoint1_perZMotor = {} as any

        properties.forEach(e =>{
          if(e.calc_point_id === 1 && e.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT){
            calcPoint0_perZDirect = e
          }
          else if(e.calc_point_id === 1 && e.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL){
            calcPoint0_perZTotal = e
          }
          else if(e.calc_point_id === 1 && e.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT_MG){
            calcPoint0_perZDirectMG = e
          }
          else if(e.calc_point_id === 1 && e.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL_MG){
            calcPoint0_perZTotalMG  = e
          }
          else if(e.calc_point_id === 1 && e.calc_per_z_type === CALC_PER_Z_TYPE_MOTOR){
            calcPoint0_perZMotor  = e
          }
          else if(e.calc_point_id === 2 && e.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT){
            calcPoint1_perZDirect = e
          }
          else if(e.calc_point_id === 2 && e.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL){
            calcPoint1_perZTotal = e
          }
          else if(e.calc_point_id === 2 && e.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT_MG){
            calcPoint1_perZDirectMG = e
          }
          else if(e.calc_point_id === 2 && e.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL_MG){
            calcPoint1_perZTotalMG = e
          }
          else if(e.calc_point_id === 2 && e.calc_per_z_type === CALC_PER_Z_TYPE_MOTOR){
            calcPoint1_perZMotor = e
          }
          else if(e.phase_kind === 1){
          m_3Sphase = e
          }
          else if(e.phase_kind === 2){
            SPhaseN = e
          }
          else if(e.phase_kind === 3){
            sPhasePE = e
          }
          else if(e.calc_point_id === 1){
            calcPoint0 = e
          }
          else if(e.calc_point_id === 2){
            calcPoint1 = e
          }
          else if(e.user_curve_point_id){
            listUserPoint.push(e)
          }
          else if(e.user_curve_id){
            listUser.push(e)
          }
          else if( e.phase_kind === undefined && e.calc_point_id === undefined){
            mainProperties = e
          }
        })
          
        let topLeft = 0
        let leadOrLag1 = 0
        let leadOrLag2 = 0

        if(calcPoint1.is_lead){
          leadOrLag2 = 1
        }
        else{
          leadOrLag2 = 0
        }
        if(calcPoint0.is_lead){
          leadOrLag1 = 1
        }
        else{
          leadOrLag1 = 0
        }
        if(mainProperties.current_top_left){
              topLeft = 1
        }
        else{
          topLeft = 0
        }
      return {
        refNo: mainProperties.ref_no,
        noBusDuct: mainProperties.no_busduct,
        pointText: mainProperties.point_text,
        voltage: mainProperties.voltage,
        length: mainProperties.length,
        wireSystem: mainProperties.wire_system,
        fault: mainProperties.fault,
        viewResultText: mainProperties.view_result_text,
        sphase: mainProperties.wire_system,
        makeDataInput: mainProperties.make_data_input,
        makerName: mainProperties.maker_name,
        type: mainProperties.type,
        makeYear: mainProperties.make_year,
        makeMonth: mainProperties.make_month,
        materialCU_AL: mainProperties.material,
        busductTemp: mainProperties.busduct_temp,
        ambientTemp: mainProperties.ambient_temp,
        m_3Sphase: {
          ratedCurrent: m_3Sphase.rated_current,
          width: m_3Sphase.width,
          thickness: m_3Sphase.thickness,
          numberPhase: m_3Sphase.number_phase,
          ohmRPerA: m_3Sphase.ohm_r_per_a,
          ohmXPerA: m_3Sphase.ohm_x_per_a,
          ohmRPerM: m_3Sphase.ohm_r_per_m,
          ohmXPerM: m_3Sphase.ohm_x_per_m
        },
        dropMotor: mainProperties.drop_motor,
        dropRegular: mainProperties.drop_regular,
        susceptance: mainProperties.flow_susceptance,
        viewFlowResult: mainProperties.view_flow_result,
        
        topLeft: topLeft,
        leadOrLag1 : leadOrLag1,
        leadOrLag2 : leadOrLag2,

        SPhaseN: {
          ratedCurrent: SPhaseN.rated_current,
          width: SPhaseN.width,
          thickness: SPhaseN.thickness,
          numberPhase: SPhaseN.number_phase,
          ohmRPerA: SPhaseN.ohm_r_per_a,
          ohmXPerA: SPhaseN.ohm_x_per_a,
          ohmRPerM: SPhaseN.ohm_r_per_m,
          ohmXPerM: SPhaseN.ohm_x_per_m
        },
        sPhasePE: {
          ratedCurrent: sPhasePE.rated_current,
          width: sPhasePE.width,
          thickness: sPhasePE.thickness,
          numberPhase: sPhasePE.number_phase,
          ohmRPerA: sPhasePE.ohm_r_per_a,
          ohmXPerA: sPhasePE.ohm_x_per_a,
          ohmRPerM: sPhasePE.ohm_r_per_m,
          ohmXPerM: sPhasePE.ohm_x_per_m,
        },
        sPhase: mainProperties.s_phase,
        checkNG: mainProperties.check_ng,
        earthCode: mainProperties.earth_code,
        surface: mainProperties.surface,
        eleLineNumber: mainProperties.ele_line_number,
        eleTopLeftNumber: mainProperties.ele_top_left_number,
        eleBottomRightNumber: mainProperties.ele_bottom_right_number,
        perUnitR: mainProperties.per_unit_r,
        perUnitX: mainProperties.per_unit_x,
        perUnitK: mainProperties.per_unit_k,
        lineActiveFlow1: mainProperties.line_active_flow1,
        lineActiveFlow2: mainProperties.line_active_flow2,
        lineReactiveFlow1: mainProperties.line_reactive_flow1,
        lineReactiveFlow2: mainProperties.line_reactive_flow2,
        upTransKind: mainProperties.up_trans_kind,
        supply: mainProperties.supply,
        tcName: mainProperties.tc_name,
        voltageMagnitude1: mainProperties.voltage_magnitude1,
        voltageMagnitude2: mainProperties.voltage_magnitude2,
        voltageAngle1: mainProperties.voltage_angle1,
        voltageAngle2: mainProperties.voltage_angle2,
        inputedCapacity1: mainProperties.inputed_capacity1,
        inputedCurrent1: mainProperties.inputed_current1,
        inputedPowerFactor1: mainProperties.inputed_power_factor1,
        inputedCapacity2: mainProperties.inputed_capacity2,
        inputedCurrent2: mainProperties.inputed_current2,
        inputedPowerFactor2: mainProperties.inputed_power_factor2,
        downTransKind: mainProperties.down_trans_kind,
        primarySupply: mainProperties.primary_supply,
        examineSomment: mainProperties.examine_comment,
        examineCommentSPhase: mainProperties.examine_comment_s_phase,
        seriesed: mainProperties.seriesed,
        volDropRouteRefNo: mainProperties.volt_drop_route_ref_no,
        cComplex:{
          voltDropSumRe:mainProperties.volt_drop_sum_re1,
          voltDropSumIm: mainProperties.volt_drop_sum_im1,
          voltDropSumRe2:mainProperties.volt_drop_sum_re2,
          voltDropSumIm2: mainProperties.volt_drop_sum_im2,
        },
        calcPoint0: doConvertDataCalcPoint(calcPoint0,
          calcPoint0_perZDirect,
          calcPoint0_perZTotal,
          calcPoint0_perZDirectMG,
          calcPoint0_perZTotalMG,
          calcPoint0_perZMotor),
        calcPoint1: doConvertDataCalcPoint(calcPoint1,
          calcPoint1_perZDirect,
          calcPoint1_perZTotal,
          calcPoint1_perZDirectMG,
          calcPoint1_perZTotalMG,
          calcPoint1_perZMotor),
                listUser: doConvertDataUserCurve(listUser,listUserPoint)
      }
    }
    case EKind.MS_TRANS1:
      {
        let props = getPropsTrans1(properties)
        return props
      }
    case EKind.MS_TEXT:
      let props = {} as any
      properties.forEach(rowData =>{
        if("no_text" in rowData){
          props = rowData
        }
      })
      return {
        noText: props.no_text,
        text: props.text,
        refNo: props.ref_no,
        fontSize: props.font_size,
        faceName: props.face_name,
        colorFont: props.color_font,
        underline: props.underline,
        italic: props.italic,
        strikeOut: props.strike_out,
        charSet: props.char_set,
        height: ELEMENT_SIZE,
        weight: props.weight
      } as MsTextModel
    case EKind.MS_TRANSSCOTT:
      {
        let props = getPropsTransScott(properties)
        return props
      }
    case EKind.MS_DS:
      {
        let listUser :any [] = []
        let listUserPoint :any [] = []

        let calcPoint1 = {} as any
        let calcPoint0 = {} as any
        let mainProperties = {} as any
        let calcPoint0_perZDirect = {} as any
        let calcPoint0_perZTotal = {} as any
        let calcPoint0_perZDirectMG = {} as any
        let calcPoint0_perZTotalMG  = {} as any
        let calcPoint0_perZMotor  = {} as any

        let calcPoint1_perZDirect = {} as any
        let calcPoint1_perZTotal = {} as any
        let calcPoint1_perZDirectMG = {} as any
        let calcPoint1_perZTotalMG = {} as any
        let calcPoint1_perZMotor = {} as any
        properties.forEach(rowData =>{
          if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT){
            calcPoint0_perZDirect = rowData
          }
          else if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL){
            calcPoint0_perZTotal = rowData
          }
          else if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT_MG){
            calcPoint0_perZDirectMG = rowData
          }
          else if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL_MG){
            calcPoint0_perZTotalMG = rowData
          }
          else if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_MOTOR){
            calcPoint0_perZMotor = rowData
          }
          else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT){
            calcPoint1_perZDirect = rowData
          }
          else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL){
            calcPoint1_perZTotal = rowData
          }
          else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT_MG){
            calcPoint1_perZDirectMG = rowData
          }
          else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL_MG){
            calcPoint1_perZTotalMG = rowData
          }
          else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_MOTOR){
            calcPoint1_perZMotor = rowData
          }
          else if(rowData.calc_point_id === 1){
            calcPoint0 = rowData
          }
          else if(rowData.calc_point_id === 2){
            calcPoint1 = rowData
          }
          else if(rowData.user_curve_point_id){
            listUserPoint.push(rowData)
          }
          else if(rowData.user_curve_id){
            listUser.push(rowData)
          }
          else if(rowData.calc_point_id === undefined){
            mainProperties = rowData
          }
        })
        
      return {
        refNo: mainProperties.ref_no,
        pointText: mainProperties.point_text,
        voltage: mainProperties.voltage,
        makeDataInput: mainProperties.make_data_input,
        isOff: mainProperties.is_off,
        fault: mainProperties.fault,
        dropMotor: mainProperties.drop_motor,
        dropRegular: mainProperties.drop_regular,
        viewFlowResult: mainProperties.view_flow_result,
        connectSwitch: mainProperties.connect_switch,
        viewResultText: mainProperties.view_result_text,
        makerName: mainProperties.maker_name,
        type: mainProperties.type,
        makeYear: mainProperties.make_year,
        makeMonth: mainProperties.make_month,
        makeNumber: mainProperties.make_number,
        noLS  : mainProperties.no_ds,
        exchangeNumber:mainProperties.exchange_number,
        downTransKind: mainProperties.down_trans_kind,
        lineActiveFlow1: mainProperties.line_active_flow1,
        lineActiveFlow2: mainProperties.line_active_flow2,
        lineReactiveFlow1: mainProperties.line_reactive_flow1,
        lineReactiveFlow2: mainProperties.line_reactive_flow2,
        checkNG: mainProperties.check_ng,
        supply: mainProperties.supply,
        tc_name: mainProperties.tc_name,
        eleTopLeftNumber: mainProperties.ele_top_left_number,
        eleBottomRightNumber: mainProperties.ele_bottom_right_number,
        primarySupply: mainProperties.primary_supply,
        examineComment: mainProperties.examine_comment,
        seriesed: mainProperties.seriesed,
        voltDropRouteRefNo: mainProperties.volt_drop_route_ref_no,
        cComplex:{
          voltDropSumRe:mainProperties.volt_drop_sum_re,
          voltDropSumIm: mainProperties.volt_drop_sum_im,
        },
        calcPoint0: doConvertDataCalcPoint(calcPoint0,
          calcPoint0_perZDirect,
          calcPoint0_perZTotal,
          calcPoint0_perZDirectMG,
          calcPoint0_perZTotalMG,
          calcPoint0_perZMotor),
        calcPoint1: doConvertDataCalcPoint(calcPoint1,
          calcPoint1_perZDirect,
          calcPoint1_perZTotal,
          calcPoint1_perZDirectMG,
          calcPoint1_perZTotalMG,
          calcPoint1_perZMotor),
        listUser: doConvertDataUserCurve(listUser,listUserPoint)
      }
    }
    case EKind.MS_LBS:
      {
        let listUser :any [] = []
        let listUserPoint :any [] = []
        let calcPoint1 = {} as any
        let calcPoint0 = {} as any
        let mainProperties = {} as any
        let calcPoint0_perZDirect = {} as any
        let calcPoint0_perZTotal = {} as any
        let calcPoint0_perZDirectMG = {} as any
        let calcPoint0_perZTotalMG  = {} as any
        let calcPoint0_perZMotor  = {} as any

        let calcPoint1_perZDirect = {} as any
        let calcPoint1_perZTotal = {} as any
        let calcPoint1_perZDirectMG = {} as any
        let calcPoint1_perZTotalMG  = {} as any
        let calcPoint1_perZMotor = {} as any

        properties.forEach(rowData =>{
          if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT){
            calcPoint0_perZDirect = rowData
          }
          else if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL){
            calcPoint0_perZTotal = rowData
          }
          else if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT_MG){
            calcPoint0_perZDirectMG = rowData
          }
          else if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL_MG){
            calcPoint0_perZTotalMG  = rowData
          }
          else if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_MOTOR){
            calcPoint0_perZMotor  = rowData
          }
          else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT){
            calcPoint1_perZDirect = rowData
          }
          else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL){
            calcPoint1_perZTotal = rowData
          }
          else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT_MG){
            calcPoint1_perZDirectMG = rowData
          }
          else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL_MG){
            calcPoint1_perZTotalMG = rowData
          }
          else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_MOTOR){
            calcPoint1_perZMotor = rowData
          }
          else if(rowData.calc_point_id === 1){
            calcPoint0 = rowData
          }
          else if(rowData.calc_point_id === 2){
            calcPoint1 = rowData
          }
          else if(rowData.user_curve_point_id){
            listUserPoint.push(rowData)
          }
          else if(rowData.user_curve_id){
            listUser.push(rowData)
          }
          else if(rowData.calc_point_id === undefined){
            mainProperties = rowData
          }
        })
      return {
        refNo: mainProperties.ref_no,
        noLBS: mainProperties.no_lbs,
        pointText: mainProperties.point_text,
        voltage: mainProperties.voltage,
        isOff: mainProperties.is_off,
        makeDataInput: mainProperties.make_data_input,
        fault: mainProperties.fault,
        dropMotor: mainProperties.drop_motor,
        dropRegular: mainProperties.drop_regular,
        viewFlowResult: mainProperties.view_flow_result,
        connectSwitch: mainProperties.connect_switch,
        viewResultText: mainProperties.view_result_text,
        makerName: mainProperties.maker_name,
        type: mainProperties.type,
        makeYear: mainProperties.make_year,
        makeMonth: mainProperties.make_month,
        makeNumber:mainProperties.make_number,
        exchangeNumber: mainProperties.exchange_number,
        checkNG: mainProperties.check_ng,
        supply: mainProperties.supply,
        tcName: mainProperties.tc_name,
        lineActiveFlow1: mainProperties.line_active_flow1,
        lineActiveFlow2: mainProperties.line_active_flow2,
        lineReactiveFlow1: mainProperties.line_reactive_flow1,
        lineReactiveFlow2: mainProperties.line_reactive_flow2,
        eleTopLeftNumber: mainProperties.ele_top_left_number,
        eleBottomRightNumber: mainProperties.ele_bottom_right_number,
        primarySupply: mainProperties.primary_supply,
        downTransKind: mainProperties.down_trans_kind,
        examineComment: mainProperties.examine_comment,
        seriesed: mainProperties.seriesed,
        voltDropRouteRefno: mainProperties.volt_drop_route_ref_no,
        cComplex:{
          voltDropSumRe:mainProperties.volt_drop_sum_re,
          voltDropSumIm: mainProperties.volt_drop_sum_im,
        },
        calcPoint0: doConvertDataCalcPoint(calcPoint0,
          calcPoint0_perZDirect,
          calcPoint0_perZTotal,
          calcPoint0_perZDirectMG,
          calcPoint0_perZTotalMG,
          calcPoint0_perZMotor),
        calcPoint1: doConvertDataCalcPoint(calcPoint1,
          calcPoint1_perZDirect,
          calcPoint1_perZTotal,
          calcPoint1_perZDirectMG,
          calcPoint1_perZTotalMG,
          calcPoint1_perZMotor),
        listUser: doConvertDataUserCurve(listUser,listUserPoint)
      }
    }
    case EKind.MS_THERMAL_CT:
    {
      let listUser :any [] = []
      let listUserPoint :any [] = []
      let mainProps: any, base: any, adjust: any
      let parts: PartDetailsModel[] = []
      let calcPoint1 = {} as any
      let calcPoint0 = {} as any
      let calcPoint0_perZDirect = {} as any
      let calcPoint0_perZTotal = {} as any
      let calcPoint0_perZDirectMG = {} as any
      let calcPoint0_perZTotalMG  = {} as any
      let calcPoint0_perZMotor  = {} as any

      let calcPoint1_perZDirect = {} as any
      let calcPoint1_perZTotal = {} as any
      let calcPoint1_perZDirectMG = {} as any
      let calcPoint1_perZTotalMG = {} as any
      let calcPoint1_perZMotor = {} as any

      properties.forEach(rowData =>{
        if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT){
          calcPoint0_perZDirect = rowData
        }
        else if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL){
          calcPoint0_perZTotal = rowData
        }
        else if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT_MG){
          calcPoint0_perZDirectMG = rowData
        }
        else if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL_MG){
          calcPoint0_perZTotalMG  = rowData
        }
        else if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_MOTOR){
          calcPoint0_perZMotor  = rowData
        }
        else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT){
          calcPoint1_perZDirect = rowData
        }
        else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL){
          calcPoint1_perZTotal = rowData
        }
        else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT_MG){
          calcPoint1_perZDirectMG = rowData
        }
        else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL_MG){
          calcPoint1_perZTotalMG = rowData
        }
        else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_MOTOR){
          calcPoint1_perZMotor = rowData
        }
        else if(rowData.calc_point_id === 1){
          calcPoint0 = rowData
        }
        else if(rowData.calc_point_id === 2){
          calcPoint1 = rowData
        }
        else if(rowData.user_curve_point_id){
          listUserPoint.push(rowData)
        }
        else if(rowData.user_curve_id){
          listUser.push(rowData)
        }
        else if(rowData.no_thermal_ct){
          mainProps = rowData
        }
        else if("type_id" in rowData){
          base = rowData
        }
        else if("part_id" in rowData){
          parts.push(rowData as PartDetailsModel) 
        }
        else if("draw_three_curve" in rowData){
          adjust = rowData
        }
    
    })
      // properties.forEach((e: any) => {
      //   if ('no_thermal_ct' in e)
      //     mainProps = e
      //   else if ('type_id' in e)
      //     base = e
      //   else if ('part_id' in e)
      //     parts.push(e as PartDetailsModel) 
      //   else if ('draw_three_curve' in e)
      //     adjust = e
      // })      
      return {
        // 基本
        noThermalCT: mainProps.no_thermal_ct,
        refNo: mainProps.ref_no,
        pointText: mainProps.point_text,
        // voltageList: [],
        voltage: mainProps.voltage,
        isOff: mainProps.is_off,
        fault: mainProps.fault,
        dropMotor: mainProps.drop_motor,
        dropRegular: mainProps.drop_regular,
        viewResultText: mainProps.view_result_text,
        
        // ModePM
        viewFlowResult: mainProps.view_flow_result,
        
        makeDataInput: mainProps.make_data_input,
        // makeYearList: [],
        makeYear: mainProps.make_year,
        // makeMonthList: [],
        makeMonth: mainProps.make_month,
        makeNumber: mainProps.make_number,

        // 保護協調
        details: {
          base: convertBaseFromBEToFE(base),
          parts: convertPartsFromBEToFE(parts),
          //TODO: delaySettings
          
          refNo2: adjust.ref_no,
          drawThreeCurve: adjust.draw_three_curve,
        },
        listUser: doConvertDataUserCurve(listUser,listUserPoint),
        
        // others
        makerName: mainProps.maker_name,
        sPhase: mainProps.s_phase,
        upTransKind: mainProps.up_trans_kind,
        checkNG: mainProps.check_ng,
        supply: mainProps.supply,
        tcName: mainProps.tc_name,
        lineActiveFlow1: mainProps.line_active_flow1,
        lineReactiveFlow1: mainProps.line_reactive_flow1,
        lineActiveFlow2: mainProps.line_active_flow2,
        lineReactiveFlow2: mainProps.line_reactive_flow2,
        downTransKind: getElementKindLabel(mainProps.down_trans_kind),
        primarySupply: mainProps.primary_supply,
        examineComment: mainProps.examine_comment,
        examineCommentSPhase: mainProps.examine_comment_s_phase	,
        seriesed: mainProps.seriesed,
        voltDropSumRe1: mainProps.volt_drop_sum_re1,
        voltDropSumIm1: mainProps.volt_drop_sum_im1,
        voltDropSumRe2: mainProps.volt_drop_sum_re2,
        voltDropSumIm2: mainProps.volt_drop_sum_im2,
        voltDropRouteRefNo: mainProps.volt_drop_route_ref_no,
        calcPoint0: doConvertDataCalcPoint(calcPoint0,
          calcPoint0_perZDirect,
          calcPoint0_perZTotal,
          calcPoint0_perZDirectMG,
          calcPoint0_perZTotalMG,
          calcPoint0_perZMotor),
        calcPoint1: doConvertDataCalcPoint(calcPoint1,
          calcPoint1_perZDirect,
          calcPoint1_perZTotal,
          calcPoint1_perZDirectMG,
          calcPoint1_perZTotalMG,
          calcPoint1_perZMotor),
      } as MsThermalCTModel;
    }
    case EKind.MS_ARRESTOR:
      {
      let listUser: any[] = []
      let mainProps ={} as any
      let listUserPoint :any [] = []
      properties.forEach((rowData:any)=>{
        if(rowData.user_curve_point_id){
          listUserPoint.push(rowData)
        }
        else if(rowData.user_curve_id){
          listUser.push(rowData)
        }
        else{
          mainProps = rowData
        }

      })
          return {
            refNo: mainProps.ref_no,
            noArrestor: mainProps.no_arrestor,
            voltage: mainProps.voltage,
            makeDataInput: mainProps.make_data_input,
            makerName: mainProps.maker_name,
            type: mainProps.type,
            makeYear: mainProps.make_year,
            makeMonth: mainProps.make_month,
            makeNumber: mainProps.make_number,
            symKind: mainProps.exchange_number,
            checkNG: mainProps.check_ng,
            supply: mainProps.supply,
            tcName: mainProps.tc_name,
            haveEarth: mainProps.have_earth,
            primarySupply: mainProps.primary_supply,
            listUser: doConvertDataUserCurve(listUser,listUserPoint)
      }
    }
    case EKind.MS_EARTH:
    {
      let props = {} as any
      properties.forEach(rowData =>{
        if("no_earth" in rowData){
          props = rowData
        }
      });
      return {
        refNo: props.ref_no,
        noEarth: props.no_earth,
        voltage: props.voltage,
        makeDataInput: props.make_data_input,
        makerName: props.maker_name,
        type: props.type,
        makeYear: props.make_year,
        makeMonth: props.make_month,
        makeNumber: props.make_number,
        symKind: props.exchange_number,
        checkNG: props.check_ng,
        supply: props.supply,
        tcName: props.tc_name,
        primarySupply: props.primary_supply,
      }
    }
    case EKind.MS_CABLEHEAD:
    {
      let props = {} as any
      properties.forEach(rowData =>{
        if("no_cable_head" in rowData){
          props = rowData
        }
      });
      return {
        refNo: props.ref_no,
        noCableHead: props.no_cable_head,
        voltage: props.voltage,
        symKind: props.exchange_number,
        sPhase: props.s_phase,
        checkNG: props.check_ng,
        upTransKind: props.up_trans_kind,
        supply: props.supply,
        tcName: props.tc_name,
        primarySupply: props.primary_supply,
        seriesed: props.seriesed,
        makerName: props.maker_name,
        makeYear: props.make_year,
        makeNumber: props.make_number,
      } 
    }
    case EKind.MS_CT:
      {
      let mainProps ={} as any
      let listUser: any[] = []
      let listUserPoint :any [] = []
      properties.forEach((rowData:any)=>{
        if(rowData.user_curve_point_id){
         listUserPoint.push(rowData)
       }
       else if(rowData.user_curve_id){
         listUser.push(rowData)
       }
       else{
        mainProps = rowData
       }

      })
      return {
        refNo: mainProps.ref_no,
        noCT: mainProps.no_ct,
        viewResultText: mainProps.view_result_text,
        voltage: mainProps.voltage,
        viewFlowResult: mainProps.view_flow_result,
        makeDataInput: mainProps.make_data_input,
        makerName: mainProps.maker_name,
        type: mainProps.type,
        makeYear: mainProps.make_year,
        makeMonth: mainProps.make_month,
        makeNumber: mainProps.make_number,
        
        sPhase: mainProps.s_phase,
        checkNG: mainProps.check_ng,
        exchangeNumber: mainProps.exchange_number,
        upTransKind: mainProps.up_trans_kind,
        supply: mainProps.supply,
        tcName: mainProps.tc_name,
        lineActiveFlow1: mainProps.line_active_flow1,
        lineReactiveFlow1: mainProps.line_reactive_flow1,
        lineActiveFlow2: mainProps.line_active_flow2,
        lineReactiveFlow2: mainProps.line_reactive_flow2,
        primarySupply: mainProps.primary_supply,
        seriesed: mainProps.seriesed,
        listUser: doConvertDataUserCurve(listUser,listUserPoint)
      }
    }
    case EKind.MS_ZCT:
      {
      let listUser: any[] = []
      let listUserPoint :any [] = []
      let mainProps ={} as any
      properties.forEach((rowData:any)=>{
        if(rowData.user_curve_point_id){
          listUserPoint.push(rowData)
        }
        else if(rowData.user_curve_id){
          listUser.push(rowData)
        }
        else{
          mainProps = rowData
        }

      })
      return {
        refNo: mainProps.ref_no,
        noZCT: mainProps.no_zct,
        viewResultText: mainProps.view_result_text,
        voltage: mainProps.voltage,
        viewFlowResult: mainProps.view_flow_result,
        makeDataInput: mainProps.make_data_input,
        makerName: mainProps.maker_name,
        type: mainProps.type,
        makeYear: mainProps.make_year,
        makeMonth: mainProps.make_month,
        makeNumber: mainProps.make_number,
        checkNG: mainProps.check_ng,
        exchangeNumber: mainProps.exchange_number,
        supply: mainProps.supply,
        tcName: mainProps.tc_name,
        lineActiveFlow1: mainProps.line_active_flow1,
        lineReactiveFlow1: mainProps.line_reactive_flow1,
        lineActiveFlow2: mainProps.line_active_flow2,
        lineReactiveFlow2: mainProps.line_reactive_flow2,
        primarySupply: mainProps.primary_supply,
        seriesed: mainProps.seriesed,
        listUser: doConvertDataUserCurve(listUser,listUserPoint)
      }
    }
    case EKind.MS_VT:
      {
      let listUser: any[] = []
      let listUserPoint :any [] = []
      let mainProps = {} as any
      properties.forEach((rowData:any)=>{
          if(rowData.user_curve_point_id){
            listUserPoint.push(rowData)
          }
          else if(rowData.user_curve_id){
            listUser.push(rowData)
          }
          else{
            mainProps = rowData
          }
      })
      return {
        refNo: mainProps.ref_no,
        noVT: mainProps.no_vt,
        viewResultText: mainProps.view_result_text,
        voltage: mainProps.voltage,
        viewFlowResult: mainProps.view_flow_result,
        makeDataInput: mainProps.make_data_input,
        makerName: mainProps.maker_name,
        type: mainProps.type,
        makeYear: mainProps.make_year,
        makeMonth: mainProps.make_month,
        makeNumber: mainProps.make_number,

        checkNG: mainProps.check_ng,
        exchangeNumber: mainProps.exchange_number,
        supply: mainProps.supply,
        tcName: mainProps.tc_name,
        lineActiveFlow1: mainProps.line_active_flow1,
        lineReactiveFlow1: mainProps.line_reactive_flow1,
        lineActiveFlow2: mainProps.line_active_flow2,
        lineReactiveFlow2: mainProps.line_reactive_flow2,
        primarySupply: mainProps.primary_supply,
        seriesed: mainProps.seriesed,
        listUser: doConvertDataUserCurve(listUser,listUserPoint)
      }
    }
    
    
    case EKind.MS_VCT:
      {
      let listUser: any[] = []
      let listUserPoint :any [] = []
      let mainProps = {} as any
      properties.forEach((rowData:any)=>{
          if(rowData.user_curve_point_id){
            listUserPoint.push(rowData)
          }
          else if(rowData.user_curve_id){
            listUser.push(rowData)
          }
          else{
            mainProps = rowData
          }
      })
      return {
        refNo: mainProps.ref_no,
        noVCT: mainProps.no_vct,
        viewResultText: mainProps.view_result_text,
        voltage: mainProps.voltage,
        viewFlowResult: mainProps.view_flow_result,
        makeDataInput: mainProps.make_data_input,
        makerName: mainProps.maker_name,
        type: mainProps.type,
        makeYear: mainProps.make_year,
        makeMonth: mainProps.make_month,
        makeNumber: mainProps.make_number,
        symKind: mainProps.exchange_number,

        checkNG: mainProps.check_ng,
        supply: mainProps.supply,
        tcName: mainProps.tc_name,
        lineActiveFlow1: mainProps.line_active_flow1,
        lineReactiveFlow1: mainProps.line_reactive_flow1,
        lineActiveFlow2: mainProps.line_active_flow2,
        lineReactiveFlow2: mainProps.line_reactive_flow2,
        primarySupply: mainProps.primary_supply,
        seriesed: mainProps.seriesed,
        listUser: doConvertDataUserCurve(listUser,listUserPoint)
      }
    }
    case EKind.MS_LOADCENTER:
      return {}
    case EKind.MS_TRANSCENTER:
      {
        let calcPoint1 = {} as any
        let calcPoint0 = {} as any
        let mainProperties = {} as any
        let calcPoint0_perZDirect = {} as any
        let calcPoint0_perZTotal = {} as any
        let calcPoint0_perZDirectMG = {} as any
        let calcPoint0_perZTotalMG  = {} as any
        let calcPoint0_perZMotor  = {} as any

        let calcPoint1_perZDirect = {} as any
        let calcPoint1_perZTotal = {} as any
        let calcPoint1_perZDirectMG = {} as any
        let calcPoint1_perZTotalMG = {} as any
        let calcPoint1_perZMotor = {} as any

        properties.forEach(rowData =>{
          if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT){
            calcPoint0_perZDirect = rowData
          }
          else if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL){
            calcPoint0_perZTotal = rowData
          }
          else if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT_MG){
            calcPoint0_perZDirectMG = rowData
          }
          else if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL_MG){
            calcPoint0_perZTotalMG  = rowData
          }
          else if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_MOTOR){
            calcPoint0_perZMotor  = rowData
          }
          else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT){
            calcPoint1_perZDirect = rowData
          }
          else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL){
            calcPoint1_perZTotal = rowData
          }
          else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT_MG){
            calcPoint1_perZDirectMG = rowData
          }
          else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL_MG){
            calcPoint1_perZTotalMG = rowData
          }
          else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_MOTOR){
            calcPoint1_perZMotor = rowData
          }
          else if(rowData.calc_point_id === 1){
            calcPoint0 = rowData
          }
          else if(rowData.calc_point_id === 2){
            calcPoint1 = rowData
          }
          else if(rowData.calc_point_id === undefined){
            mainProperties = rowData
          }
        })
      return {
        noTransCenter: mainProperties.no_trans_center,
        // tab1 - 基本
        refNo: mainProperties.ref_no,
        pointText: mainProperties.point_text,
        voltageList: [],
        voltage: mainProperties.voltage,

        fault: mainProperties.fault,
        viewResultText: mainProperties.view_result_text,
        // ModePM
        viewFlowResult: mainProperties.view_flow_result,

        xr: NaN,
        percentZ: NaN,
        percentR: NaN,
        percentX: NaN,
        ohmZ: NaN,
        ohmR: NaN,
        ohmX: NaN,
        xFrameCount: mainProperties.square_count_hlizontal,
        yFrameCount: mainProperties.square_count_vertical,

        // tab2 - 集計・計算
        thinkOnOff: mainProperties.think_on_off,
        togetherLoad: 0, // ?
        powerFactor: mainProperties.power_factor,
        fullCurrent: 0, // ?
        phaseCurrent: -90, // ?
        maxPower: mainProperties.max_power,
        demandFactor: 0, // ?
        periodAveragePower: mainProperties.average_power,
        periodMaxPower: mainProperties.period_max_power,
        loadFactor: 0, // ?

        // diagram
        tabId: 1, // TODO

        // others
        sPhase: mainProperties.s_phase,
        upTransKind: getElementKindLabel(mainProperties.up_trans_kind),
        capacity: mainProperties.capacity,
        leadLag: mainProperties.is_lead ? 0 : 1,
        checkNG: mainProperties.check_ng,
        supply: mainProperties.supply,
        nodeNumber: mainProperties.node_number	,
        perUnitR: mainProperties.per_unit_r,
        perUnitX: mainProperties.per_unit_x,
        perUnitK: mainProperties.per_unit_k,
        perUnitP: mainProperties.per_unit_p,
        perUnitQ: mainProperties.per_unit_q,
        diversityFactor: mainProperties.diversity_factor,
        voltageMagnitude: mainProperties.voltage_magnitude,
        voltageAngle: mainProperties.voltage_angle,
        activePower: mainProperties.active_power,
        reactivePower: mainProperties.reactive_power,

        hasElement: mainProperties.has_element,
        topElementId: mainProperties.top_element_id,

        left1: mainProperties.left1,
        left2: mainProperties.left2,
        left3: mainProperties.left3,
        right1: mainProperties.right1,
        right2: mainProperties.right2,
        right3: mainProperties.right3,

        primarySupply: mainProperties.primary_supply,
        examineComment: mainProperties.examine_comment,
        examineCommentSPhase: mainProperties.examine_comment_s_phase	,
        seriesed: mainProperties.seriesed,
        voltDropSumRe1: mainProperties.volt_drop_sum_re1,
        voltDropSumIm1: mainProperties.volt_drop_sum_im1,
        voltDropSumRe2: mainProperties.volt_drop_sum_re2,
        voltDropSumIm2: mainProperties.volt_drop_sum_im2,
        voltDropRouteRefNo: mainProperties.volt_drop_route_ref_no,
        calcPoint0: doConvertDataCalcPoint(calcPoint0,
          calcPoint0_perZDirect,
          calcPoint0_perZTotal,
          calcPoint0_perZDirectMG,
          calcPoint0_perZTotalMG,
          calcPoint0_perZMotor),
        calcPoint1: doConvertDataCalcPoint(calcPoint1,
          calcPoint1_perZDirect,
          calcPoint1_perZTotal,
          calcPoint1_perZDirectMG,
          calcPoint1_perZTotalMG,
          calcPoint1_perZMotor),
      }
    }
    case EKind.MS_LIGHTBOARD:
      {
        let listUser: any[] = []
        let listUserPoint :any [] = []
        let mainProps = {} as any
        properties.forEach((rowData:any)=>{
          if(rowData.user_curve_point_id){
            listUserPoint.push(rowData)
          }
          else if(rowData.user_curve_id){
            listUser.push(rowData)
          }
          else{
            mainProps = rowData
          }
        })
        let fullCurrent = doGetLoadCurrent(mainProps,mainProps.s_phase)
      return {
        refNo:mainProps.ref_no,
        noLightBoard: mainProps.no_light_board,
        voltage:mainProps.voltage,
        totalCapacity:mainProps.total_capacity,
        totalCapacity1: mainProps.total_capacity,
        powerFactor:mainProps.power_factor,
        powerFactor1: mainProps.power_factor,
        loadFactor:mainProps.load_factor,
        inputDetail:mainProps.input_detail,
        fullCurrent: fullCurrent,
        fullCurrent1: fullCurrent,
        light:mainProps.light,
        soket:mainProps.soket,
        FCU:mainProps.fcu,
        other:mainProps.other,
        totalCapacityUnit:mainProps.total_capacity_unit,
        lightUnit:mainProps.light_unit,
        soketUnit:mainProps.soket_unit,
        FCUUnit:mainProps.fcu_unit,
        otherUnit:mainProps.other_unit,
        lightPF:mainProps.light_pf,
        soketPF:mainProps.soket_pf,
        FCUPF:mainProps.fcu_pf,
        otherPF:mainProps.other_pf,
        viewResultText:mainProps.view_result_text,
        viewFlowResult:mainProps.view_flow_result,
        makeDataInput:mainProps.make_data_input,
        makerName:mainProps.maker_name,
        type:mainProps.type,
        makeYear:mainProps.make_year,
        makeMonth:mainProps.make_month,
        makeNumber:mainProps.make_number,

        sPhase: mainProps.s_phase,
        loadCurrent: mainProps.load_current,
        checkNG: mainProps.check_ng,
        nodeNumber: mainProps.node_number,
        perUnitR: mainProps.per_unit_r,
        perUnitX: mainProps.per_unit_x,
        perUnitK: mainProps.per_unit_k,
        perUnitP: mainProps.per_unit_p,
        perUnitQ: mainProps.per_unit_q,
        voltageMagnitude: mainProps.voltage_magnitude,
        voltageAngle: mainProps.voltage_angle,
        activePower: mainProps.active_power,
        reactivePower: mainProps.reactive_power,
        upTransKind: mainProps.up_trans_kind,
        supply: mainProps.supply,
        tcName: mainProps.tc_name,
        totalCapacityPf: mainProps.total_capacity_pf,
        primarySupply: mainProps.primary_supply,
        isLead: mainProps.is_lead,
        listUser: doConvertDataUserCurve(listUser,listUserPoint)
      }
    }
    case EKind.MS_POWERBOARD:
      {
        let listUser: any[] = []
        let listUserPoint :any [] = []
        let mainProps ={} as any
        properties.forEach((rowData:any)=>{
          if(rowData.user_curve_point_id){
            listUserPoint.push(rowData)
          }
          else if(rowData.user_curve_id){
            listUser.push(rowData)
          }
          else{
            mainProps = rowData
          }
        })
        let fullCurrent = 0

        if(mainProps.input_detail){
          fullCurrent = BeamsNumber(doGetValueLoadCurrent(mainProps,mainProps.s_phase,mainProps.is_lead,true),4)
        }
        else{
          fullCurrent = BeamsNumber(doGetValueLoadCurrent(mainProps,mainProps.s_phase,mainProps.is_lead,false),4)
        }
       return {
        refNo: mainProps.ref_no,
        noPowerBoard: mainProps.no_power_board,
        voltage: mainProps.voltage,
        totalCapacity: mainProps.total_capacity,
        totalCapacity1:mainProps.total_capacity,
        fullCurrent: fullCurrent,
        fullCurrent1: fullCurrent,
        powerFactor: mainProps.power_factor,
        powerFactor1: mainProps.power_factor,
        loadFactor: mainProps.load_factor,
        inputDetail: mainProps.input_detail,
        freezer: mainProps.freezer,
        elevator: mainProps.elevator,
        aircon: mainProps.air_con,
        hygiene: mainProps.hygiene,
        capacitor: mainProps.capacitor,
        other: mainProps.other,
        viewResultText: mainProps.view_result_text,
        totalCapacityUnit: mainProps.total_capacity_unit,
        freezerUnit: mainProps.freezer_unit,
        elevatorUnit: mainProps.elevator_unit,
        airconUnit: mainProps.air_con_unit,
        hygieneUnit: mainProps.hygiene_unit,
        capacitorUnit: mainProps.capacitor_unit,
        otherUnit: mainProps.other_unit,
        freezerPF: mainProps.freezer_pf,
        elevatorPF: mainProps.elevator_pf,
        airconPF: mainProps.air_con_pf,
        hygienePF: mainProps.hygiene_pf,
        capacitorPF: mainProps.capacitor_pf,
        otherPF: mainProps.other_pf,
        makeDataInput: mainProps.make_data_input,
        viewFlowResult: mainProps.view_flow_result,
        makerName: mainProps.maker_name,
        type: mainProps.type,
        makeYear: mainProps.make_year,
        makeMonth: mainProps.make_month,
        makeNumber: mainProps.make_number,
        upTransKind: mainProps.up_trans_kind,
        sPhase: mainProps.s_phase,
        loadCurrent: mainProps.load_current,
        isLead: mainProps.is_lead,
        checkNG: mainProps.check_ng,
        nodeNumber: mainProps.node_number,
        perUnitR: mainProps.per_unit_r,
        perUnitX: mainProps.per_unit_x,
        perUnitK: mainProps.per_unit_k,
        perUnitP: mainProps.per_unit_p,
        perUnitQ: mainProps.per_unit_q,
        voltageMagnitude: mainProps.voltage_magnitude,
        voltageAngle: mainProps.voltage_angle,
        supply: mainProps.supply,
        tcName: mainProps.tc_name,
        totalCapacityPF: mainProps.total_capacity_pf,
        primarySupply: mainProps.primary_supply,
        activePower: mainProps.active_power,
        reactivePower: mainProps.reactive_power,
        listUser: doConvertDataUserCurve(listUser,listUserPoint)
      }
    }
    
    case EKind.MS_INTO_POINT:
      {
        let calcPoint1 = {} as any
        let calcPoint0 = {} as any
        let mainProperties = {} as any
        let calcPoint0_perZDirect = {} as any
        let calcPoint0_perZTotal = {} as any
        let calcPoint0_perZDirectMG = {} as any
        let calcPoint0_perZTotalMG  = {} as any
        let calcPoint0_perZMotor  = {} as any

        let calcPoint1_perZDirect = {} as any
        let calcPoint1_perZTotal = {} as any
        let calcPoint1_perZDirectMG = {} as any
        let calcPoint1_perZTotalMG = {} as any
        let calcPoint1_perZMotor = {} as any


        properties.forEach(rowData =>{
          if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT){
            calcPoint0_perZDirect = rowData
          }
          else if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL){
            calcPoint0_perZTotal = rowData
          }
          else if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT_MG){
            calcPoint0_perZDirectMG = rowData
          }
          else if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL_MG){
            calcPoint0_perZTotalMG  = rowData
          }
          else if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_MOTOR){
            calcPoint0_perZMotor  = rowData
          }
          else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT){
            calcPoint1_perZDirect = rowData
          }
          else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL){
            calcPoint1_perZTotal = rowData
          }
          else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT_MG){
            calcPoint1_perZDirectMG = rowData
          }
          else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL_MG){
            calcPoint1_perZTotalMG = rowData
          }
          else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_MOTOR){
            calcPoint1_perZMotor = rowData
          }
          else if(rowData.calc_point_id === 1){
            calcPoint0 = rowData
          }
          else if(rowData.calc_point_id === 2){
            calcPoint1 = rowData
          }
          else if(rowData.calc_point_id === undefined){
            mainProperties = rowData
          }
        })
      return {
        supply: mainProperties.supply,
        sPhase: mainProperties.s_phase,
        upTransKind: mainProperties.up_trans_kind,
        voltage: mainProperties.voltage,
        capacity: mainProperties.capacity,
        percentZ:mainProperties.percent_z,
        nodeNumber: mainProperties.node_number,
        perUnitR: mainProperties.per_unit_r,
        perUnitX: mainProperties.per_unit_x,
        perUnitK: mainProperties.per_unit_k,
        perUnitP: mainProperties.per_unit_p,
        perUnitQ: mainProperties.per_unit_q,
        perUnitV: mainProperties.per_unit_v,      
        voltageMagnitude: mainProperties.voltage_magnitude,
        voltageAngle: mainProperties.voltage_angle,
        activePower: mainProperties.active_power,
        reactivePower: mainProperties.reactive_power,
        refNo: mainProperties.ref_no,
        noIntoPoint: mainProperties.no_into_point,
        topEleRefNo: mainProperties.top_ele_ref_no,
        primarySupply: mainProperties.primary_supply,
        seriesed: mainProperties.seriesed,
        voltDropSumRe1: mainProperties.volt_drop_sum_re1,
        voltDropSumIm1: mainProperties.volt_drop_sum_im1,
        voltDropSumRe2: mainProperties.volt_drop_sum_re2,
        voltDropSumIm2: mainProperties.volt_drop_sum_im2,
        voltDropRouteRefNo: mainProperties.volt_drop_route_ref_no,
        calcPoint0: doConvertDataCalcPoint(calcPoint0,
          calcPoint0_perZDirect,
          calcPoint0_perZTotal,
          calcPoint0_perZDirectMG,
          calcPoint0_perZTotalMG,
          calcPoint0_perZMotor),
        calcPoint1: doConvertDataCalcPoint(calcPoint1,
          calcPoint1_perZDirect,
          calcPoint1_perZTotal,
          calcPoint1_perZDirectMG,
          calcPoint1_perZTotalMG,
          calcPoint1_perZMotor),
      } as MSIntoPointModel 
    }
    case EKind.MS_RELAY51:
      return {}
    case EKind.MS_IMPEDANCE:
      {
        let listUser :any [] = []
        let listUserPoint :any [] = []
        let calcPoint1 = {} as any
        let calcPoint0 = {} as any
        let mainProperties = {} as any
        let calcPoint0_perZDirect = {} as any
        let calcPoint0_perZTotal = {} as any
        let calcPoint0_perZDirectMG = {} as any
        let calcPoint0_perZTotalMG  = {} as any
        let calcPoint0_perZMotor  = {} as any

        let calcPoint1_perZDirect = {} as any
        let calcPoint1_perZTotal = {} as any
        let calcPoint1_perZDirectMG = {} as any
        let calcPoint1_perZTotalMG = {} as any
        let calcPoint1_perZMotor = {} as any


        properties.forEach(rowData =>{
          if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT){
            calcPoint0_perZDirect = rowData
          }
          else if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL){
            calcPoint0_perZTotal = rowData
          }
          else if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT_MG){
            calcPoint0_perZDirectMG = rowData
          }
          else if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL_MG){
            calcPoint0_perZTotalMG  = rowData
          }
          else if(rowData.calc_point_id === 1 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_MOTOR){
            calcPoint0_perZMotor  = rowData
          }
          else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT){
            calcPoint1_perZDirect = rowData
          }
          else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL){
            calcPoint1_perZTotal = rowData
          }
          else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT_MG){
            calcPoint1_perZDirectMG = rowData
          }
          else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL_MG){
            calcPoint1_perZTotalMG = rowData
          }
          else if(rowData.calc_point_id === 2 && rowData.calc_per_z_type === CALC_PER_Z_TYPE_MOTOR){
            calcPoint1_perZMotor = rowData
          }
          else if(rowData.calc_point_id === 1){
            calcPoint0 = rowData
          }
          else if(rowData.calc_point_id === 2){
            calcPoint1 = rowData
          }
          else if(rowData.user_curve_point_id){
            listUserPoint.push(rowData)
          }
          else if(rowData.user_curve_id){
            listUser.push(rowData)
          }
          else if(rowData.calc_point_id === undefined){
            mainProperties = rowData
          }
        })
        let topLeft = 0
        let leadOrLag2 = 0
        let leadOrLag1 = 0

        let powerFactor = 0
    
        if(calcPoint1.is_lead){
          leadOrLag2 = 1
        }
        else{
          leadOrLag2 = 0
        }
        if(calcPoint0.is_lead){
          leadOrLag1 = 1
        }
        else{
          leadOrLag1 = 0
        }
        if(mainProperties.current_top_left){
          topLeft = 1
        }
        else{
          topLeft = 0
        }
      return {
        refNo: mainProperties.ref_no,
        noImpedance: mainProperties.no_impedance,
        pointText: mainProperties.point_text,
        voltage: mainProperties.voltage,
        fault: mainProperties.fault,
        viewResultText: mainProperties.view_result_text,
        inputPercentZ: mainProperties.input_kind,
        stdCapacity: mainProperties.ele_std_capacity,
        xr: mainProperties.xr,
        percentZ: mainProperties.per_z_k3,
        percentR: mainProperties.per_r_k3,
        percentX: mainProperties.per_x_k3,
        ohmR: mainProperties.ohm_r_input,
        ohmX: mainProperties.ohm_x_input,
        dropMotor: mainProperties.drop_motor,
        dropRegular: mainProperties.drop_regular,
        flowSusceptance: mainProperties.flow_susceptance,
        viewFlowResult: mainProperties.view_flow_result,
        topLeft: topLeft,
        leadOrLag1: leadOrLag1,
        leadOrLag2: leadOrLag2,

        sPhase: mainProperties.s_phase,
        checkNG: mainProperties.check_ng,
        eleLineNumber: mainProperties.ele_line_number,
        eleTopLeftNumber: mainProperties.ele_top_left_number,
        eleBottomRightNumber: mainProperties.ele_bottom_right_number,
        perUnitR: mainProperties.per_unit_r,
        perUnitX: mainProperties.per_unit_x,
        perUnitK: mainProperties.per_unit_k,
        lineActiveFlow1: mainProperties.line_active_flow1,
        lineActiveFlow2: mainProperties.line_active_flow2,
        lineReactiveFlow1: mainProperties.line_reactive_flow1,
        lineReactiveFlow2: mainProperties.line_reactive_flow2,
        upTransKind: mainProperties.up_trans_kind,
        supply: mainProperties.supply,
        tcName: mainProperties.tc_name,
        voltageMagnitude1: mainProperties.voltage_magnitude1,
        voltageMagnitude2: mainProperties.voltage_magnitude2,
        voltageAngle1: mainProperties.voltage_angle1,
        voltageAngle2: mainProperties.voltage_angle2,
        inputedCapacity1: mainProperties.inputed_capacity1,
        inputedCurrent1: mainProperties.inputed_current1,
        inputedPowerFactor1: mainProperties.inputed_power_factor1,
        inputedCapacity2: mainProperties.inputed_capacity2,
        inputedCurrent2: mainProperties.inputed_current2,
        inputedPowerFactor2: mainProperties.inputed_power_factor2,
        downTransKind: mainProperties.down_trans_kind,
        primarySupply: mainProperties.primary_supply,
        examineComment: mainProperties.examine_comment,
        examineCommentSPhase: mainProperties.examine_comment_s_phase,
        seriesed: mainProperties.seriesed,
        voltDropRouteRefNo: mainProperties.volt_drop_route_ref_no,
        cComplex:{
          voltDropSumRe:mainProperties.volt_drop_sum_re1,
          voltDropSumIm: mainProperties.volt_drop_sum_im1,
          voltDropSumRe2:mainProperties.volt_drop_sum_re2,
          voltDropSumIm2: mainProperties.volt_drop_sum_im2,
        },
        calcPoint0: doConvertDataCalcPoint(calcPoint0,
          calcPoint0_perZDirect,
          calcPoint0_perZTotal,
          calcPoint0_perZDirectMG,
          calcPoint0_perZTotalMG,
          calcPoint0_perZMotor),
        calcPoint1: doConvertDataCalcPoint(calcPoint1,
          calcPoint1_perZDirect,
          calcPoint1_perZTotal,
          calcPoint1_perZDirectMG,
          calcPoint1_perZTotalMG,
          calcPoint1_perZMotor),
        listUser: doConvertDataUserCurve(listUser,listUserPoint)
      }

    }
    case EKind.MS_CONNECT:
      {
        let props = {} as any
        properties.forEach(rowData =>{
          if("supply" in rowData){
            props = rowData
          }
        });
        return {
          sPhase: props.s_phase,
          rotate: props.rotate,
          upTransKind: props.up_trans_kind,
          supply: props.supply,
          primarySupply: props.primary_supply,
        }
      }
    case EKind.MS_INV:
      {
      let listUser: any[] = []
      let listUserPoint :any [] = []
      let harmonicList = [] as any;
      let mainProperties = {} as any;
      properties.forEach(rowData =>{
        if(rowData.harmo_list_id !== undefined){
          harmonicList.push(rowData);
        }
        else if(rowData.user_curve_point_id){
            listUserPoint.push(rowData)
        }
        else if(rowData.user_curve_id){
          listUser.push(rowData)
        }
        else if(rowData.harmo_list_id === undefined){
          mainProperties = rowData;
        }
      })
      return {
        refNo:  mainProperties.ref_no,
        noINV: mainProperties.no_inv,
        voltage: mainProperties.voltage,
        checkNG: mainProperties.check_ng,
        supply: mainProperties.supply,
        tcName: mainProperties.tc_name,
        makeDataInput: mainProperties.make_data_input,
        makerName: mainProperties.maker_name,
        makeYear: mainProperties.make_year,
        makeMonth: mainProperties.make_month,
        makeNumber: mainProperties.make_number,
        type: mainProperties.type,
        viewFlowResult: mainProperties.view_flow_result,
        viewResultText: mainProperties.view_result_text,

       
        primarySupply: mainProperties.primary_supply,
        seriesed: mainProperties.seriesed,
        exchangeNumber: mainProperties.exchange_number,
        lineActiveFlow1: mainProperties.line_active_flow1,
        lineReactiveFlow1: mainProperties.line_reactive_flow1,
        lineActiveFlow2: mainProperties.line_active_flow2,
        lineReactiveFlow2: mainProperties.line_reactive_flow2,
        harmonicList:harmonicListMap(harmonicList),
        listUser: doConvertDataUserCurve(listUser,listUserPoint)
      } 
    }
      
    case EKind.MS_RELAY:
      {
      let listUser: any[] = []
      let listUserPoint :any [] = []
      let mainProps = {} as any
      properties.forEach((rowData:any)=>{
        if(rowData.user_curve_point_id){
          listUserPoint.push(rowData)
        }
        else if(rowData.user_curve_id){
          listUser.push(rowData)
        }
        else{
          mainProps = rowData
        }
      })
      return {
        refNo: mainProps.ref_no,
        noRelay: mainProps.no_relay,
        viewResultText: mainProps.view_result_text,
        voltage: mainProps.voltage,
        viewFlowResult: mainProps.view_flow_result,
        makeDataInput: mainProps.make_data_input,
        makerName: mainProps.maker_name,
        type: mainProps.type,
        makeYear: mainProps.make_year,
        makeMonth: mainProps.make_month,
        makeNumber: mainProps.make_number,
        symKind: mainProps.exchange_number,
        subText: mainProps.symbol_text,
        checkNG: mainProps.check_ng,
        supply: mainProps.supply,
        tcName: mainProps.tc_name,
        lineActiveFlow1: mainProps.line_active_flow1,
        lineReactiveFlow1: mainProps.line_reactive_flow1,
        lineActiveFlow2: mainProps.line_active_flow2,
        lineReactiveFlow2: mainProps.line_reactive_flow2,
        primarySupply: mainProps.primary_supply,
        seriesed: mainProps.seriesed,
        listUser: doConvertDataUserCurve(listUser,listUserPoint)
    }
  }
}
}

export const mapElementsResponseToLocal = (elements : any[], diagramDataList:any) => {
  const elementsById = groupBy(elements, 'element_id');
  return Object.values(elementsById).map((elementById:any) =>{
    const { root_group_id, parent_group_id, parent_tc_id, parent_tc_group_id, element_id, element_kind, x, y, width, height, rotation } = elementById[0]
    const type = getElementKindLabel(element_kind)
    const properties = getElementProperties(type as string, elementById.map((e:any) => e.properties))
    const parentGroupId = parent_tc_group_id === null
                          // element in root group
                          ? root_group_id === parent_group_id ? null : parent_group_id
                          // element in transcenter 
                          : parent_tc_group_id === 0 ? null : parent_tc_group_id;
    let isSelectedEle = false
    diagramDataList?.map((shapes:any)=>{
        let check = shapes?.shape?.find((item:any)=> Number(item.id) == Number(element_id) && type == item.type)
        if(check)
        {
          isSelectedEle = check.isSelected
          return
        }
    })

    // if response element is text element
    if (type === EKind.MS_TEXT) {
      let props = properties as MsTextModel
      return {
        id: ""+element_id, //cast to string
        x,
        y,
        type,
        isSelected: isSelectedEle,
        parentGroupId,
        properties,
        parentTcID: parent_tc_id,
        rotation: 0,
        height: props.fontSize*(props.text.split("\n").length),
        width: getTextWidth(props.text, props.faceName, props.fontSize)
      }
    }    

    const convertedRotation = convertRotationValue(rotation)

    let offsetX = 0, offsetY = 0
    if (convertedRotation === 90) {
      offsetY += height
    } else if (convertedRotation === -90) {
      offsetX += width
    }
    
    const image = getElementImage(type as string, properties, convertedRotation)
    let isRotationEnabled = false;

    switch(type){
      case "MS_DS":
      case "MS_LBS":
      case "MS_MVCB":
      case "MS_CONTACTOR":
      case "MS_HVCB":
      case "MS_FUSE":
      case "MS_CONNECT":
      case "MS_WIRE":
      case "MS_BUSBAR":
      case "MS_BUSDUCT":
      case "MS_REACTOR":
      case "MS_IMPEDANCE":
      case "MS_LVCB":
      case "MS_CABLEHEAD":
      case "MS_RELAY":
        isRotationEnabled = true;
        break;
      default:
        break;
    }

    return {
      id: ""+element_id, //cast to string
      image,
      x,
      y,
      offsetX,
      offsetY,
      width,
      height,
      type,
      isSelected: isSelectedEle,
      rotation: convertedRotation,      
      // raw: ,
      chart: [],
      isRotationEnabled: isRotationEnabled, 
      parentGroupId,
      properties,
      parentTcID: parent_tc_id,
    }
  })
  
}

export const mapProjectDataFromResponse = (project : any) => {

  const {
    owner_id : ownerId,
    project_id : projectId,
    project_name : projectName,
    create_user_id : createUserId,
    create_datetime : createDatetime,
    last_update_user_id : lastUpdateUserId,
    last_update_datetime : lastUpdateDatetime,
    graph_no : graphNo,
    def_x_min : defXMin,
    def_x_max : defXMax,
    def_y_min : defYMin,
    def_y_max : defYMax,
    ope_mode : opeMode,
    drop_mode : dropMode,
    power_flow_method : powerFlowMethod,
    max_iteration : maxIteration,
    precision : precision,
    acceleration_factor : accelerationFactor,
    iteration : iteration,
    view_point_text_only : viewPointTextOnly,
    point_text_changed : pointTextChanged,
    flow_view_method : flowViewMethod,
    calculated_flow : calculatedFlow,
    calculated_harmonic_flow : calculatedHarmonicFlow,
    no_illuminance : noIlluminance,
    no_earth_resistance : noEarthResistance,
    edit_text : editText,
    weight : weight,
    italic : italic,
    underline : underline,
    strike_out : strikeOut,
    char_set : charSet,
    face_name : faceName,
    no_hvcb : noHVCB,
    no_lvcb : noLVCB,
    no_thermal : noThermal,
    no_source : noSource,
    no_trans3 : noTrans3,
    no_generator : noGenerator,
    no_motor_grp : noMotorGrp,
    no_zero_cond : noZeroCond,
    no_bus_bar : noBusBar,
    no_wire : noWire,
    no_contactor : noContactor,
    no_motor : noMotor,
    no_load : noLoad,
    no_capacitor : noCapacitor,
    no_2E : no2E,
    no_fuse : noFuse,
    no_mvcb : noMVCB,
    no_reactor : noReactor,
    no_3winding : no3winding,
    no_bus_duct : noBusDuct,
    no_trans1 : noTrans1,
    no_text : noText,
    no_trans_scott : noTransScott,
    no_ds : noDS,
    no_lbs : noLBS,
    no_thermal_ct : noThermalCT,
    no_arrestor : noArrestor,
    no_earth : noEarth,
    no_cable_head : noCableHead,
    no_ct : noCT,
    no_zct : noZCT,
    no_vt : noVT,
    no_vct : noVCT,
    no_load_center : noLoadCenter,
    no_trans_center : noTransCenter,
    no_light_board : noLightBoard,
    no_power_board : noPowerBoard,
    no_into_point : noIntoPoint,
    no_relay51 : noRelay51,
    no_impedance : noImpedance,
    no_fault : noFault,
    no_inv : noINV,
    no_relay : noRelay,
    mode_pm : modePM,
    is_shared: isShared,
    locked_user_id:lockedUserId

  } = project

  return {
      ownerId,
      projectId,
      projectName,
      createUserId,
      createDatetime,
      lastUpdateUserId,
      lastUpdateDatetime,
      graphNo,
      defXMin,
      defXMax,
      defYMin,
      defYMax,
      opeMode,
      dropMode,
      powerFlowMethod,
      maxIteration,
      precision,
      accelerationFactor,
      iteration,
      viewPointTextOnly,
      pointTextChanged,
      flowViewMethod,
      calculatedFlow,
      calculatedHarmonicFlow,
      noIlluminance,
      noEarthResistance,
      editText,
      weight,
      italic,
      underline,
      strikeOut,
      charSet,
      faceName,
      noBusBar,
      noCapacitor,
      noGenerator,
      noHVCB,
      noLoad,
      noLVCB,
      noFuse,
      no2E,
      noMotor,
      noMotorGrp,
      noSource,
      noThermal,
      noContactor,
      noTrans3,
      noWire,
      noZeroCond,
      noMVCB,
      noReactor,
      no3winding,
      noBusDuct,
      noTrans1,
      noText,
      noTransScott,
      noDS,
      noLBS,
      noThermalCT,
      noArrestor,
      noEarth,
      noCableHead,
      noCT,
      noZCT,
      noVT,
      noVCT,
      noLoadCenter,
      noTransCenter,
      noLightBoard,
      noPowerBoard,
      noIntoPoint,
      noRelay51,
      noImpedance,
      noFault,
      noINV,
      noRelay,
      modePM,
      isShared,
      lockedUserId
  }
}

export const mapElementGroupsResponse = (elements : ControlModel[], groupsData : any) => {
  const byId = groupsData.reduce((acc : any, curr : any) => {
    if (curr.tc_id) { // tc group
      acc[curr.tc_group_id] = {
        groupId : curr.tc_group_id,
        groupName : curr.group_name,
        explan : curr.explan,
        parentGroupId : curr.tc_group_id === 0 ? null : curr.parent_tc_group_id,
        elementIds: []
      }
    } else if (curr.group_id){ // element group
      acc[curr.group_id] = {
        groupId : curr.group_id,
        groupName : curr.group_name,
        explan : curr.explan,
        parentGroupId : curr.parent_group_id,
        elementIds: []
      }
    }
    return acc
  }, {})

  const byEleId : {[key : string] : number[]} = {}
  elements.forEach((element : ControlModel) => {
    if (element.parentGroupId) {
      const elementIds = []
      let parentGroup = byId[element.parentGroupId]
      while (parentGroup && parentGroup.parentGroupId !== null) {
        elementIds.push(parentGroup.groupId)
        parentGroup.elementIds.push(element.id)
        parentGroup = byId[parentGroup.parentGroupId]
      }

      byEleId[element.id] = elementIds
    }
  })

  return {
    byId,
    byEleId
  }
}

export const getElementNoKey = (key : string) => {
  switch(key) {
    case EKind.MS_HVCB:
      return 'noHVCB' 
    case EKind.MS_LVCB:
      return 'noLVCB' 
    case EKind.MS_THERMAL:
      return 'noThermal' 
    case EKind.MS_SOURCE:
      return 'noSource' 
    case EKind.MS_TRANS3:
      return 'noTrans3' 
    case EKind.MS_GENERATOR:
      return 'noGenerator' 
    case EKind.MS_MOTOR_GROUP:
      return 'noMotorGrp' 
    case EKind.MS_ZEROCOND:
      return 'noZeroCond' 
    case EKind.MS_BUSBAR:
      return 'noBusBar' 
    case EKind.MS_WIRE:
      return 'noWire' 
    case EKind.MS_CONTACTOR:
      return 'noContactor' 
    case EKind.MS_MOTOR:
      return 'noMotor' 
    case EKind.MS_LOAD:
      return 'noLoad' 
    case EKind.MS_CAPACITOR:
      return 'noCapacitor' 
    case EKind.MS_2E:
      return 'no2E' 
    case EKind.MS_FUSE:
      return 'noFuse' 
    case EKind.MS_MVCB:
      return 'noMVCB' 
    case EKind.MS_REACTOR:
      return 'noReactor' 
    case EKind.MS_3WINDING:
      return 'no3winding' 
    case EKind.MS_BUSDUCT:
      return 'noBusDuct' 
    case EKind.MS_TRANS1:
      return 'noTrans1' 
    case EKind.MS_TEXT:
      return 'noText' 
    case EKind.MS_TRANSSCOTT:
      return 'noTransScott' 
    case EKind.MS_DS:
      return 'noDS' 
    case EKind.MS_LBS:
      return 'noLBS'
    case EKind.MS_THERMAL_CT:
      return 'noThermalCT' 
    case EKind.MS_ARRESTOR:
      return 'noArrestor' 
    case EKind.MS_EARTH:
      return 'noEarth' 
    case EKind.MS_CABLEHEAD:
      return 'noCableHead' 
    case EKind.MS_CT:
      return 'noCT' 
    case EKind.MS_ZCT:
      return 'noZCT' 
    case EKind.MS_VT:
      return 'noVT' 
    case EKind.MS_VCT:
      return 'noVCT' 
    case EKind.MS_LOADCENTER:
      return 'noLoadCenter' 
    case EKind.MS_TRANSCENTER:
      return 'noTransCenter' 
    case EKind.MS_LIGHTBOARD:
      return 'noLightBoard' 
    case EKind.MS_POWERBOARD:
      return 'noPowerBoard' 
    case EKind.MS_INTO_POINT:
      return 'noIntoPoint' 
    case EKind.MS_RELAY51:
      return 'noRelay51' 
    case EKind.MS_IMPEDANCE:
      return 'noImpedance' 
    case EKind.MS_INV:
      return 'noINV' 
    case EKind.MS_RELAY:
      return 'noRelay'
    default:
      return '' 
  }
}
export const mapInfoCoverFromResponse = (infoCover:any) =>{
    {
        const powerTotal = doGetValuPowerTotal(infoCover.power_existing,infoCover.power_increase,infoCover.power_new)
        const heatTotal = doGetValueHeatTotal(infoCover.heat_existing,infoCover.heat_increase,infoCover.heat_new)
        const lightTotal = doGetValueLightTotal(infoCover.light_existing,infoCover.light_increase,infoCover.light_new)
        const subExisting = doGetSubExisting(infoCover.unit_change,infoCover.power_existing,infoCover.heat_existing,infoCover.light_existing)
        const subIncrease = doGetSubIncrease(infoCover.unit_change,infoCover.power_increase,infoCover.heat_increase,infoCover.light_increase)
        const subNew = doGetSubNew(infoCover.unit_change,infoCover.power_new,infoCover.heat_new,infoCover.light_new)
        const subTotal = doGetValueSubTotal(infoCover.unit_change,infoCover.power_existing,infoCover.heat_existing,infoCover.light_existing,
          infoCover.power_increase,infoCover.heat_increase,infoCover.light_increase,infoCover.power_new,infoCover.heat_new,infoCover.light_new)
      return{
        // corporation: infoCover.,
        titleCorporationAddress: infoCover.title_corporation_address,
        corporationAddress: infoCover.corporation_address,
        titleCorporationTelephone: infoCover.title_corporation_telephone,
        corporationTelephone: infoCover.corporation_telephone,
        titleDateDesigned: infoCover.title_date_designed,
        dateDesigned: infoCover.date_designed,
        application: infoCover.application,
        // titleProject: infoCover.,
        // project: infoCover.,
        // titleProjectAddress: infoCover.,
        // projectAddress: infoCover.,
        titleProjectNumber: infoCover.title_project_number,
        projectNumber: infoCover.project_number,
        
        item1 : infoCover.item1,
        item2 : infoCover.item2,
        item3 : infoCover.item3,
        item4 : infoCover.item4,
        item5 : infoCover.item5,
        item6 : infoCover.item6,
        item7 : infoCover.item7,
        item8 : infoCover.item8,
        item9 : infoCover.item9,
        item10 : infoCover.item10,
        item11 : infoCover.item11,
        item12 : infoCover.item12,
        item13 : infoCover.item13,
        item14 : infoCover.item14,
        item15 : infoCover.item15,
        item16 : infoCover.item16,
        item17 : infoCover.item17,
        item18 : infoCover.item18,
        item19 : infoCover.item19,
        item20 : infoCover.item20,
        item21 : infoCover.item21,
        item22 : infoCover.item22,
        item23 : infoCover.item23,
        item24 : infoCover.item24,
        item25 : infoCover.item25,
        item26 : infoCover.item26,
        item27 : infoCover.item27,
        item28 : infoCover.item28,
        item29 : infoCover.item29,
        item30 : infoCover.item30,
        item31 : infoCover.item31,

        content1 : infoCover.content1,
        content2 : infoCover.content2,
        content3 : infoCover.content3,
        content4 : infoCover.content4,
        content5 : infoCover.content5,
        content6 : infoCover.content6,
        content7 : infoCover.content7,
        content8 : infoCover.content8,
        content9 : infoCover.content9,
        content10 : infoCover.content10,
        content11 : infoCover.content11,
        content12 : infoCover.content12,
        content13 : infoCover.content13,
        content14 : infoCover.content14,
        content15 : infoCover.content15,
        content16 : infoCover.content16,
        content17 : infoCover.content17,
        content18 : infoCover.content18,
        content19 : infoCover.content19,
        content20 : infoCover.content20,
        content21 : infoCover.content21,
        content22 : infoCover.content22,
        content23 : infoCover.content23,
        content24 : infoCover.content24,
        content25 : infoCover.content25,
        content26 : infoCover.content26,
        content27 : infoCover.content27,
        content28 : infoCover.content28,
        content29 : infoCover.content29,
        content30 : infoCover.content30,
        content31 : infoCover.content31,
       
        page1 : infoCover.page1,
        page2 : infoCover.page2,
        page3 : infoCover.page3,
        page4 : infoCover.page4,
        page5 : infoCover.page5,
        page6 : infoCover.page6,
        page7 : infoCover.page7,
        page8 : infoCover.page8,
        page9 : infoCover.page9,
        page10 : infoCover.page10,
        page11 : infoCover.page11,

        page12 : infoCover.page12,
        page13 : infoCover.page13,
        page14 : infoCover.page14,
        page15 : infoCover.page15,
        page16 : infoCover.page16,
        page17 : infoCover.page17,
        page18 : infoCover.page18,
        page19 : infoCover.page19,
        page20 : infoCover.page20,
        page21 : infoCover.page21,
        page22 : infoCover.page22,

        page23 : infoCover.page23,
        page24 : infoCover.page24,
        page25 : infoCover.page25,
        page26 : infoCover.page26,
        page27 : infoCover.page27,
        page28 : infoCover.page28,
        page29 : infoCover.page29,
        page30 : infoCover.page30,
        page31 : infoCover.page31,

        itemDispIndex: infoCover.item_disp_index,
        itemBottomIndex: infoCover.item_bottom_index,
        outline: infoCover.outline,
        titleProjectManager: infoCover.title_project_manager,
        projectManager: infoCover.project_manager,
        titleProjectKind: infoCover.title_project_kind,
        projectKind: infoCover.project_kind,
        titleVoltage: infoCover.title_voltage,
        voltage: infoCover.voltage,
        titleEquipCapacity: infoCover.title_equip_capacity,
        powerExisting: infoCover.power_existing,
        powerIncrease: infoCover.power_increase,
        powerNew: infoCover.power_new,
        heatExisting: infoCover.heat_existing,
        heatIncrease: infoCover.heat_increase,
        heatNew: infoCover.heat_new,
        lightExisting: infoCover.light_existing,
        lightIncrease: infoCover.light_increase,
        lightNew: infoCover.light_new,
        titleContractPower: infoCover.title_contract_power,
        contractPower: infoCover.contract_power,
        titlePowerUse: infoCover.title_power_use,
        powerUse: infoCover.power_use,
        titlePowerForecast: infoCover.title_power_forecast,
        powerForecast: infoCover.power_forecast,
        titleUser1: infoCover.title_user1,
        user1: infoCover.user1,
        titleUser2: infoCover.title_user2,
        user2: infoCover.user2,
        titleUser3: infoCover.title_user3,
        user3: infoCover.user3,
        titleUser4: infoCover.title_user4,
        user4: infoCover.user4,
        titleUser5: infoCover.title_user5,
        user5: infoCover.user5,
        execution: infoCover.execution,
        titleStandard: infoCover.title_standard,
        standard: infoCover.standard,
        titlePlace: infoCover.title_place,
        placeNormal: infoCover.place_normal,
        placeNormalEdit: infoCover.place_normal_edit,
        placeDanger: infoCover.place_danger,
        placeDangerEdit: infoCover.place_danger_edit,
        placeDust: infoCover.place_dust,
        placeDustEdit: infoCover.place_dust_edit,
        placeCorrosion: infoCover.place_corrosion,
        placeCorrosionEdit: infoCover.place_corrosion_edit,
        placeUser: infoCover.place_user,
        placeUserText: infoCover.place_user_text,
        titleWire: infoCover.title_wire,
        wireHigh: infoCover.wire_high,
        wireHighEdit: infoCover.wire_high_edit,
        wireLow: infoCover.wire_low,
        wireLowEdit: infoCover.wire_low_edit,
        wireUser: infoCover.wire_user,
        wireUserText: infoCover.wire_user_text,
        titleDuct: infoCover.title_duct,
        ductPvc: infoCover.duct_pvc,
        ductPvcEdit: infoCover.duct_pvc_edit,
        ductGip: infoCover.duct_gip,
        ductGipEdit: infoCover.duct_gip_edit,
        ductUser: infoCover.duct_user,
        ductUserText: infoCover.duct_user_text,
        titleMeter: infoCover.title_meter,
        meterExisting: infoCover.meter_existing,
        meterExistingEdit: infoCover.meter_existing_edit,
        meterNew: infoCover.meter_new,
        meterNewEdit: infoCover.meter_new_edit,
        meterUser: infoCover.meter_user,
        meterUserText: infoCover.meter_user_text,
        titleSwitch: infoCover.title_switch,
        switchNormal: infoCover.switch_normal,
        switchNormalEdit: infoCover.switch_normal_edit,
        switchBurstProof: infoCover.switch_burst_proof,
        switchBurstProofEdit: infoCover.switch_burst_proof_edit,
        switchUser: infoCover.switch_user,
        switchUserText: infoCover.switch_user_text,
        titleAppliance: infoCover.title_appliance,
        appliance: infoCover.appliance,
        titleOther: infoCover.title_other,
        other: infoCover.other,
        titleInstrument: infoCover.title_instrument,
        instrument1: infoCover.instrument1,
        instrument2: infoCover.instrument2,
        instrument3: infoCover.instrument3,
        instrument4: infoCover.instrument4,
        instrument5: infoCover.instrument5,
        instrument6: infoCover.instrument6,
        instrument7: infoCover.instrument7,
        instrument8: infoCover.instrument8,
        instrument9: infoCover.instrument9,
        instrument10: infoCover.instrument10,
        instrument11: infoCover.instrument11,
        instrument12: infoCover.instrument12,
        instrument13: infoCover.instrument13,
        instrument14: infoCover.instrument14,
        instrument15: infoCover.instrument15,
        instrument16: infoCover.instrument16,
        instrument17: infoCover.instrument17,
        instrument18: infoCover.instrument18,
        instrument19: infoCover.instrument19,
        instrument20: infoCover.instrument20,
        instrument21: infoCover.instrument21,
        corporation1: infoCover.corporation1,
        corporation2: infoCover.corporation2,
        corporation3: infoCover.corporation3,
        corporation4: infoCover.corporation4,
        corporation5: infoCover.corporation5,
        corporation6: infoCover.corporation6,
        corporation7: infoCover.corporation7,
        corporation8: infoCover.corporation8,
        corporation9: infoCover.corporation9,
        corporation10: infoCover.corporation10,
        corporation11: infoCover.corporation11,
        corporation12: infoCover.corporation12,
        corporation13: infoCover.corporation13,
        corporation14: infoCover.corporation14,
        corporation15: infoCover.corporation15,
        corporation16: infoCover.corporation16,
        corporation17: infoCover.corporation17,
        corporation18: infoCover.corporation18,
        corporation19: infoCover.corporation19,
        corporation20: infoCover.corporation20,
        corporation21: infoCover.corporation21,
        instrumentDispIndex: infoCover.instrument_disp_index,
        instrumentBottomIndex: infoCover.instrument_bottom_index,
        unitChange: infoCover.unit_change,
        powerTotal: BeamsNumber(powerTotal,6),
        heatTotal: BeamsNumber(heatTotal,6),
        lightTotal: BeamsNumber(lightTotal,6),
        subExisting: BeamsNumber(subExisting,6),
        subIncrease: BeamsNumber(subIncrease,6),
        subNew: BeamsNumber(subNew,6),
        subTotal: BeamsNumber(subTotal,6),
      } as InfoCover
}
}
export const mapInfoSkeletonFromResponse = (infoSkeleton:any) =>{  
  //  const {
  //   print_detail_head_view1 : printDetailHeadView1,
  // } = infoSkeleton
   let dispSupply = 0
   if(infoSkeleton.disp_supply){
    dispSupply = 1
   }
   else{
    dispSupply = 0
   }
   let changeDeltaToStar = 0
   if(infoSkeleton.change_delt_to_star){
    changeDeltaToStar = 1
   }
   else{
    changeDeltaToStar = 0
   }
   
  return {
    squareCountHlizontal: infoSkeleton["square_count_hlizontal"],
    squareCountVertical: infoSkeleton["square_count_vertical"],
    zoom: infoSkeleton["zoom"],
    marginTop: infoSkeleton["margin_top"],
    marginBottom: infoSkeleton["margin_bottom"],
    marginLeft: infoSkeleton["margin_left"],
    marginRight: infoSkeleton["margin_right"],
    orientation: infoSkeleton["orientation"],
    paperSize: infoSkeleton["paper_size"],
    figTitle: infoSkeleton["fig_title"],
    figSubTitle: infoSkeleton["fig_sub_title"],
    figNumber: infoSkeleton["fig_number"],
    drawner: infoSkeleton["drawner"],
    dateDrawn: infoSkeleton["date_drawn"],
    checker: infoSkeleton["checker"],
    dateChecked: infoSkeleton["date_checked"],
    designer: infoSkeleton["designer"],
    dateDesigned: infoSkeleton["date_designed"],
    approver: infoSkeleton["approver"],
    dateApproved: infoSkeleton["date_approved"],
    revision0: infoSkeleton["revision0"],
    revision1: infoSkeleton["revision1"],
    revision2: infoSkeleton["revision2"],
    revision3: infoSkeleton["revision3"],
    revision4: infoSkeleton["revision4"],
    comments: infoSkeleton["comments"],
    titleNumber: infoSkeleton["title_number"],
    titleMode: infoSkeleton["title_mode"],
    titleComment: infoSkeleton["title_comment"],
    titleDrawn: infoSkeleton["title_drawn"],
    titleChecked: infoSkeleton["title_checked"],
    titleDesigned: infoSkeleton["title_designed"],
    titleApproved: infoSkeleton["title_approved"],
    titleDateDrawn: infoSkeleton["title_date_drawn"],
    titleDateChecked: infoSkeleton["title_date_checked"],
    titleDateDesigned: infoSkeleton["title_date_designed"],
    titleDateApproved: infoSkeleton["title_date_approved"],
    dispStress: infoSkeleton["disp_stress"],
    dispSupply,
    dispStainOrBack:infoSkeleton["disp_stain_or_back"],
    roopOpen: infoSkeleton["roop_open"],
    changeDeltaToStar: changeDeltaToStar,
    
    figTitleDefault: "単線結線図",
    figSubTitleDefault:"Single-line Diagram (Skeleton)",
    titleDrawnDefault:"作成",
    titleDateDefault:"日付",
    titleCheckedDefault:"照査",
    titleNumberDefault:"図番",
    titleDesignedDefault:"設計",
    titleModeDefault:"解析モード",
    titleApprovedDefault: "検認",
    titleCommentDefault: "コメント",

    titleCorporation:infoSkeleton["title_corporation"],//"会社",
    titleProject:infoSkeleton["title_project"], //"プロジェクト",
    printDetailHead: infoSkeleton["print_detail_head_view1"],
    titleReference:infoSkeleton["title_reference"],//"回路名称",

    frequency: infoSkeleton["frequency"],
    stdCapacity: infoSkeleton["std_capacity"],
    voltage: infoSkeleton["voltage"],
    electricPower: infoSkeleton["electric_power"],
    project: infoSkeleton["project"],
    reference: infoSkeleton["reference"],
    corporation: infoSkeleton["corporation"],
    printDetailHeadView1:infoSkeleton["print_detail_head_view1"],
    printDetailHeadView2:infoSkeleton["print_detail_head_view2"],
    printDetailHeadView3:infoSkeleton["print_detail_head_view3"],
  }
}
export const mapReportFromResponse = (report:any) =>{
    return{
      figTitle: report["fig_title"],
      figSubTitle: report["fig_sub_title"],
      figNumber: report["fig_number"],
      comments: report["comments"],
      marginTop: report["margin_top"],
      marginBottom: report["margin_bottom"],
      marginLeft: report["margin_left"],
      marginRight: report["margin_right"],
      orientation: report["orientation"],
      paperSize: report["paper_size"],
      reportIndex: report["report_index"],
      titleCorporation : '会社',
      corporation :'' ,
      revision1 : '',
      titleProject : 'プロジェクト' ,
      project : '',
      drawn : '作成',
      drawner : '',
      titleDateDrawn : '日付',
      dateDrawn : '',
      revision2 : '',
      titleReference : '回路名称',
      reference : '',
      checked : '図番	',
      checker : '',
      titleDateChecked : '日付',
      dateChecked : '',
      revision3 : '',
      titleNumber : '図番',
      designed : '',
      designer : '設計',
      titleDateDesigned :'日付' ,
      dateDesigned : '',
      revision4 : '',
      titleMode : '解析モード',
      approved : '検認',
      approver : '',
      titleDateApproved : '日付',
      dateApproved : '',
      revision5 : '',
      titleComment : 'コメント',


      printDetailHead: report["print_detail_head_view3"],
      frequency: report["frequency"],
      stdCapacity: report["std_capacity"],
      voltage: report["voltage"],
      electricPower: report["electric_power"],
      printDetailHeadView1:report["print_detail_head_view1"],
      printDetailHeadView2:report["print_detail_head_view2"],
      printDetailHeadView3:report["print_detail_head_view3"],
      editText : report["edit_text"][0],
      weight : report["weight"][0],
      italic : report["italic"][0],
      underline : report["underline"][0],
      strikeOut : report["strike_out"][0],
      charSet : report["char_set"][0],
      faceName : report["face_name"][0],
      colorFont: report["color_font"][0],
      fontSize: report["font_size"] [0]
    }
}

export const mapInfoCircuitFromResponse = (infoCircuit : any) => {
  const {
    frequency,
    std_capacity : stdCapacity,
    voltage,
    electric_power : electricPower,
    project,
    reference,
    corporation,
    title_project : titleProject,
    title_reference : titleReference,
    title_corporation : titleCorporation,
    print_detail_head_view1 : printDetailHeadView1,
    print_detail_head_view2 : printDetailHeadView2,
    print_detail_head_view3 : printDetailHeadView3,
  } = infoCircuit

  return {
    frequency,
    stdCapacity,
    voltage,
    voltageList : [], // TODO
    electricPower,
    project,
    reference,
    corporation,
    defaultTitleProject: 'プロジェクト',
    defaultTitleReference: '回路名称',
    defaultTitleCorporation: '会社',
    titleProject,
    titleReference,
    titleCorporation,
    printDetailHeadView1,
    printDetailHeadView2,
    printDetailHeadView3,
  }
}

const getPropsTrans1 = (propsList: any) => {
  let listUser:any[] = [];
  let listUserPoint :any [] = []
  let mainProps: any;
  let part0: any, part1: any;
  let part0_calcPoint: any, part1_calcPoint: any;
  let part0_perZDirect: any,part0_perZTotal:any,part0_perZDirectMG:any,part0_perZTotalMG :any, part0_perZMotor :any;
  let part1_perZDirect: any,part1_perZTotal:any,part1_perZDirectMG:any,part1_perZTotalMG:any, part1_perZMotor:any;

  propsList.forEach((e: any) => {
    if(e.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT && e.ele_trans1_part_kind == 1){
      part0_perZDirect = e
    }
    else if(e.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL && e.ele_trans1_part_kind == 1){
      part0_perZTotal = e
    }
    else if(e.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT_MG && e.ele_trans1_part_kind == 1){
      part0_perZDirectMG = e
    }
    else if(e.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL_MG && e.ele_trans1_part_kind == 1){
      part0_perZTotalMG  = e
    }
    else if(e.calc_per_z_type === CALC_PER_Z_TYPE_MOTOR && e.ele_trans1_part_kind == 1){
      part0_perZMotor  = e
    }
    else if(e.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT && e.ele_trans1_part_kind == 2){
      part1_perZDirect = e
    }
    else if(e.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL && e.ele_trans1_part_kind == 2){
      part1_perZTotal = e
    }
    else if(e.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT_MG && e.ele_trans1_part_kind == 2){
      part1_perZDirectMG = e
    }
    else if(e.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL_MG && e.ele_trans1_part_kind == 2){
      part1_perZTotalMG = e
    }
    else if(e.calc_per_z_type === CALC_PER_Z_TYPE_MOTOR && e.ele_trans1_part_kind == 2){
      part1_perZMotor = e
    }
    else if(e.user_curve_point_id){
      listUserPoint.push(e)
    }
    else if(e.user_curve_id){
      listUser.push(e)
    }
    else if (e.no_trans1 !== undefined){
      mainProps = e
    }
    else if (e.calc_point_id === 1 && e.ele_trans1_part_kind == 1) {
      part0_calcPoint = e // neutral - [外線‐中性線]
    }
    else if(e.calc_point_id === 1 && e.ele_trans1_part_kind == 2){
      part1_calcPoint = e // out side - [外線‐外線]
    }
    else {
      if (e.ele_trans1_part_kind == 1) 
        part0 = e // neutral - [外線‐中性線]
      else
        part1 = e // out side - [外線‐外線]
    }
  })

  let voltageSecondary = mainProps.volt_secondary

  let ratedCurrent = msTrans1GetRatedCurrent(
    mainProps.capacity,
    mainProps.volt_primary
  )
  // tab1-2
  let ratedCurrent1 = msTrans1GetRatedCurrent(
    mainProps.capacity,
    voltageSecondary
  )

  let partOutOut: MsTrans1PartModel
  let partOutIn: MsTrans1PartModel
  
  partOutOut = getTrans1Part(mainProps, 1, [part0, part1],part1_calcPoint , part0_perZDirect, part0_perZTotal,part0_perZDirectMG,part0_perZTotalMG, part0_perZMotor)
  // [外線‐外線]
  
  partOutIn = getTrans1Part(mainProps, 0, [part0, part1], part0_calcPoint, part1_perZDirect, part1_perZTotal, part1_perZDirectMG, part1_perZTotalMG, part1_perZMotor)
   // [外線‐中性線]

  return {
    // tab0 - 基本
    noTrans1: mainProps.no_trans1,
    refNo: mainProps.ref_no,
    voltagePrimaryList: [],
    voltagePrimary: mainProps.volt_primary,
    voltageSecondaryList: [],
    voltageSecondary: voltageSecondary,
    capacityList: [],
    capacity: mainProps.capacity,
    ratedCurrent: BeamsNumber(ratedCurrent, 4),
    
    viewFlowResult: mainProps.view_flow_result,
    viewResultText: mainProps.view_result_text,
    loadFactor: mainProps.load_factor,
    powerFactor: mainProps.power_factor,

    // ModePM
    makeDataInput: mainProps.make_data_input,
    makerNameList: [],
    makerName: mainProps.maker_name,
    type: mainProps.type,
    makeYearList: [],
    makeYear: mainProps.make_year,
    makeMonthList: [],
    makeMonth: mainProps.make_month,
    makeNumber: mainProps.make_number,
    
    // MsTrans1Part: [外線‐中性線] && [外線‐外線]
    voltage1: Math.round(voltageSecondary / 2),
    capacity1: Math.round(mainProps.capacity / 2),
    ratedCurrent1: BeamsNumber(ratedCurrent1, 4),
    partOutOut: partOutOut,
    partOutIn:partOutIn,

    // シンボル
    symKind: mainProps.exchange_number,
    listUser: doConvertDataUserCurve(listUser,listUserPoint),

    // other
    checkNG: mainProps.check_ng,
    supply: mainProps.supply,
    tcName: mainProps.tc_name,
    nodeNumber: mainProps.node_number,
    perUnitP: mainProps.per_unit_p,
    perUnitQ: mainProps.per_unit_q,
    voltageMagnitude: mainProps.voltage_magnitude,
    voltageAngle: mainProps.voltage_angle,
    activePower: mainProps.active_power,
    reactivePower: mainProps.reactive_power,
    primarySupply: mainProps.primary_supply,
    perUnitR: mainProps.per_unit_r,
    perUnitX: mainProps.per_unit_x,
    perUnitK: mainProps.per_unit_k,
    seriesed: mainProps.seriesed,
    isSourceVoltage:false,
    voltDropRouteRefNo: mainProps.volt_drop_route_ref_no,
  } as MsTrans1Model;
}

const getTrans1Part = (trans1Data: any, partIndex: number, partsData: any[], calcPoint: any,perZDirect:any,perZTotal:any,perZDirectMG:any,perZTotalMG :any,perZMotor :any) => {
  // partIndex == 0: [外線‐中性線] || partIndex == 1: [外線‐外線]
  let partData: any = partsData[partIndex]
  let voltageSecondary = trans1Data.volt_secondary

  // tab1-2
  let percentR = partData.per_r_k3
  let percentX = partData.per_x_k3
  let xr = BeamsNumber(percentX / percentR, 5)
 
  let calcData = {
    voltageSecondary: partIndex === 0? voltageSecondary / 2: voltageSecondary,
    capacity: partIndex === 0?trans1Data.capacity / 2:trans1Data.capacity,
    percentR: percentR,
    percentX: percentX,
  }
 
  
  let powerData = msTransNormalCalculation(calcData)
  
  // tab3-4
  let inrushValue = msTrans1GetInrushValue(
    partData.inrush_times,
    trans1Data.capacity,
    voltageSecondary
  )
  
  let intensityValue = msTrans1GetIntensityValue(
    partData.intensity_times,
    trans1Data.capacity,
    voltageSecondary
  )
  let calcData2 :any
  if(partIndex === 0){
    calcData2 = {
    isNeutral: true,
    xr1: partsData[0].xr,
    percentZ1: partsData[0].per_z_k3,
    xr2: partsData[1].xr,
    percentZ2: partsData[1].per_z_k3,
    capacity: trans1Data.capacity,
    voltageSecondary: voltageSecondary,
    loadCurrent: calcPoint.load_current,
    powerFactor: calcPoint.power_factor,
    leadLag: calcPoint.is_lead,
  }
}else if(partIndex === 1){
    calcData2 = {
      isNeutral: false,
      xr1: partsData[0].xr,
      percentZ1: partsData[0].per_z_k3,
      xr2: partsData[1].xr,
      percentZ2: partsData[1].per_z_k3,
      capacity: trans1Data.capacity,
      voltageSecondary: voltageSecondary,
      loadCurrent: calcPoint.load_current,
      powerFactor: calcPoint.power_factor,
      leadLag: calcPoint.is_lead,
}
}
  let voltageDrop1 = msTrans1GetDropVoltDiff(calcData2)
  let voltageDrop = msTrans1GetDropPerUnit(calcData2)
   
  return {
    // 基本
    eleTrans1PartKind: partData.ele_trans1_part_kind,
    pointText: partData.point_text,
    fault: partData.fault,
    
    xr: xr,
    percentZ: BeamsNumber(powerData.percentZ, 5),
    percentR: percentR,
    percentX: percentX,
    ohmZ: BeamsNumber(powerData.ohmZ, 4),
    ohmR: BeamsNumber(powerData.ohmR, 4),
    ohmX: BeamsNumber(powerData.ohmX, 4),

    // 保護協調
    dispInrush: partData.disp_inrush,
    inrushTimes: partData.inrush_times,
    inrushValue: BeamsNumber(inrushValue, 4),
    dampingTime: partData.damping_time,

    dispIntensity: partData.disp_intensity,
    intensityTimes: partData.intensity_times,
    intensityValue: BeamsNumber(intensityValue, 4),
    intensityTime: partData.intensity_time,

    // 電圧降下
    dropRegular: partData.drop_regular,
    calcPoint: doConvertDataCalcPoint(calcPoint,
      perZDirect,
      perZTotal,
      perZDirectMG,
      perZTotalMG,
      perZMotor) as CalcPoint,
    
    currentTopLeft: 0, // always 0

    voltageDrop1: BeamsNumber(voltageDrop1, 4),
    voltageDrop: BeamsNumber(voltageDrop, 4),
    
    // other
    selInrush: partData.sel_inrush,
    selIntensity: partData.sel_intensity,
    pageIndex: partData.page_index,
    inputedCapacity1: partData.inputed_capacity1,
    inputedCurrent1: partData.inputed_current1,
    inputedPowerFactor1: partData.inputed_power_factor1,
    neutralKind: partData.neutral_kind,
    dispAnsiKind: partData.disp_ansi_kind,
    ansiTimes: partData.ansi_times,
    ansiTime: partData.ansi_time,
    inrushTimesKind: partData.inrush_times_kind, // m_nInrushTimes
    inrushManualValue: partData.inrush_manual_value,
    inrushTime: partData.inrush_time,
    examineComment: partData.examine_comment,
    voltDropSumIm: partData.volt_drop_sum_im,
    voltDropSumRe: partData.volt_drop_sum_re
  } as MsTrans1PartModel
}

const getPropsTransScott = (propsList: any) => {
  let mainProps: any
  let listUser: any[] =[]
  let listUserPoint :any [] = []
  let part0: any, part1: any
  let part0_calcPoint: any, part1_calcPoint: any
  let part0_perZDirect: any, part0_perZTotal:any,part0_perZDirectMG:any,part0_perZTotalMG :any, part0_perZMotor :any;
  let part1_perZDirect: any,part1_perZTotal:any,part1_perZDirectMG:any,part1_perZTotalMG:any,part1_perZMotor:any;

  propsList.forEach((e: any) => {
    if(e.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT && e.ele_trans_scott_part_kind == 1){
      part0_perZDirect = e
    }
    else if(e.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL && e.ele_trans_scott_part_kind == 1){
      part0_perZTotal = e
    }
    else if(e.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT_MG && e.ele_trans_scott_part_kind == 1){
      part0_perZDirectMG = e
    }
    else if(e.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL_MG && e.ele_trans_scott_part_kind == 1){
      part0_perZTotalMG  = e
    }
    else if(e.calc_per_z_type === CALC_PER_Z_TYPE_MOTOR && e.ele_trans_scott_part_kind == 1){
      part0_perZMotor  = e
    }
    else if(e.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT && e.ele_trans_scott_part_kind == 2){
      part1_perZDirect = e
    }
    else if(e.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL && e.ele_trans_scott_part_kind == 2){
      part1_perZTotal = e
    }
    else if(e.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT_MG && e.ele_trans_scott_part_kind == 2){
      part1_perZDirectMG = e
    }
    else if(e.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL_MG && e.ele_trans_scott_part_kind == 2){
      part1_perZTotalMG = e
    }
    else if(e.calc_per_z_type === CALC_PER_Z_TYPE_MOTOR && e.ele_trans_scott_part_kind == 2){
      part1_perZMotor = e
    }
    else if (e.calc_point_id === 1 && e.ele_trans_scott_part_kind == 1) {
      part0_calcPoint = e // [主座変圧器]
    }
    else if(e.calc_point_id === 1 && e.ele_trans_scott_part_kind == 2){
      part1_calcPoint = e // [T座変圧器]
    }
    else if(e.user_curve_point_id){
      listUserPoint.push(e)
    }
    else if(e.user_curve_id){
      listUser.push(e)
    }
    else if (e.no_trans_scott){
      mainProps = e
    }
    else {
      if (e.ele_trans_scott_part_kind == 1) 
        part0 = e // [主座変圧器]
      else
        part1 = e //  [T座変圧器]
    }
  })
  // let mainProps: any
  // let part0: any, part1: any
  // let calcPoint0: any, calcPoint1: any
  // propsList.forEach((e: any) => {
  //   if ('no_trans_scott' in e)
  //     mainProps = e
  //   else if ('calc_type' in e) {
  //     if (e.ele_trans_scott_part_kind == 1)
  //       calcPoint0 = e // [主座変圧器]
  //     else
  //       calcPoint1 = e // [T座変圧器]
  //   }
  //   else {
  //     if (e.ele_trans_scott_part_kind == 1) 
  //       part0 = e // [主座変圧器]
  //     else
  //       part1 = e // [T座変圧器]
  //   }
  // })

  let voltagePrimary = mainProps.volt_primary
  let voltageSecondary = mainProps.volt_secondary

  let ratedCurrent = msTransScottGetRatedCurrent(
    mainProps.capacity,
    voltagePrimary,
    true
  )
  let ratedCurrent1 = msTransScottGetRatedCurrent(
    mainProps.capacity,
    voltageSecondary
  )

  let partSeatM: MsTransScottPartModel
  let partSeatT: MsTransScottPartModel

 
  partSeatM = getTransScottPart(mainProps, 0, [part0, part1], part0_calcPoint,part0_perZDirect,part0_perZTotal,part0_perZDirectMG,part0_perZTotalMG,part0_perZMotor) // [主座変圧器]
  partSeatT =  getTransScottPart(mainProps, 1, [part0, part1],part1_calcPoint,part1_perZDirect,part1_perZTotal,part1_perZDirectMG,part1_perZTotalMG,part1_perZMotor) // [T座変圧器]

  return {
    // tab0 - 基本
    noTransScott: mainProps.no_trans_scott,
    refNo: mainProps.ref_no,
    voltagePrimaryList: [],
    voltagePrimary: voltagePrimary,
    voltageSecondaryList: [],
    voltageSecondary: voltageSecondary,
    capacityList: [],
    capacity: mainProps.capacity,
    ratedCurrent: BeamsNumber(ratedCurrent, 4),
    ratedCurrent1: BeamsNumber(ratedCurrent1, 4),
    
    viewFlowResult: mainProps.view_flow_result,
    viewResultText: mainProps.view_result_text,
    loadFactor: mainProps.load_factor,
    powerFactor: mainProps.power_factor,

    // ModePM
    makeDataInput: mainProps.make_data_input,
    makerNameList: [],
    makerName: mainProps.maker_name,
    type: mainProps.type,
    makeYearList: [],
    makeYear: mainProps.make_year,
    makeMonthList: [],
    makeMonth: mainProps.make_month,
    makeNumber: mainProps.make_number,
    
    partSeatT: partSeatT,
    partSeatM: partSeatM,

    listUser: doConvertDataUserCurve(listUser,listUserPoint),

    // other
    checkNG: mainProps.check_ng,
    supply: mainProps.supply,
    tcName: mainProps.tc_name,
    nodeNumber: mainProps.node_number,
    perUnitP: mainProps.per_unit_p,
    perUnitQ: mainProps.per_unit_q,
    voltageMagnitude: mainProps.voltage_magnitude,
    voltageAngle: mainProps.voltage_angle,
    activePower: mainProps.active_power,
    reactivePower: mainProps.reactive_power,
    primarySupply: mainProps.primary_supply,
    perUnitR: mainProps.per_unit_r,
    perUnitX: mainProps.per_unit_x,
    perUnitK: mainProps.per_unit_k,
    seriesed: mainProps.seriesed,
    voltDropRouteRefNo: mainProps.volt_drop_route_ref_no,
    isSourceVoltage: false,
  } as MsTransScottModel;
}

const getTransScottPart = (
  transScottData: any, 
  partIndex: number, 
  partsData: any[], 
  calcPoint: any,
  perZDirect:any,
  perZTotal:any,
  perZDirectMG:any,
  perZTotalMG :any,
  perZMotor :any
) => {
  // partIndex == 0: [主座変圧器] || partIndex == 1: [T座変圧器] 
  let partData = partsData[partIndex]
  let voltageSecondary = transScottData.volt_secondary

  // tab1-2
  let percentR = partData.per_r_k3
  let percentX = partData.per_x_k3
  let xr = BeamsNumber(percentX / percentR, 5)
  
  let calcData = {
    voltageSecondary: voltageSecondary,
    capacity: transScottData.capacity / 2,
    percentR: percentR,
    percentX: percentX,
  }
  let powerData = msTransNormalCalculation(calcData)
  
  // tab3-4
  let inrushValue = msTransScottGetInrushValue(
    partData.inrush_times,
    transScottData.capacity,
    voltageSecondary
  )
  
  let intensityValue = msTransScottGetIntensityValue(
    partData.intensity_times,
    transScottData.capacity,
    voltageSecondary
  )
  let calcData2:any
    if(partIndex === 0){
     calcData2 = {
      isSeatM: true,
      percentR1: partsData[0].per_r_k3,
      percentX1: partsData[0].per_x_k3,
      percentR2: partsData[1].per_r_k3,
      percentX2: partsData[1].per_x_k3,
      capacity: transScottData.capacity,
      voltageSecondary: voltageSecondary,
      loadCurrent: calcPoint.load_current,
      powerFactor: calcPoint.power_factor,
      leadLag: calcPoint.is_lead,
    }
}else if(partIndex === 1){
   calcData2 = {
    isSeatM: false,
    percentR1: partsData[0].per_r_k3,
    percentX1: partsData[0].per_x_k3,
    percentR2: partsData[1].per_r_k3,
    percentX2: partsData[1].per_x_k3,
    capacity: transScottData.capacity,
    voltageSecondary: voltageSecondary,
    loadCurrent: calcPoint.load_current,
    powerFactor: calcPoint.power_factor,
    leadLag: calcPoint.is_lead,
  }
}
  let voltageDrop1 = msTransScottGetDropVoltDiff(calcData2)
  let voltageDrop = msTransScottGetDropPerUnit(calcData2)
   
  return {
    // 基本
    eleTransScottPartKind: partData.ele_trans_scott_part_kind,
    pointText: partData.point_text,
    fault: partData.fault,
    
    xr: xr,
    percentZ: BeamsNumber(powerData.percentZ, 5),
    percentR: percentR,
    percentX: percentX,
    ohmZ: BeamsNumber(powerData.ohmZ, 4),
    ohmR: BeamsNumber(powerData.ohmR, 4),
    ohmX: BeamsNumber(powerData.ohmX, 4),

    // 保護協調
    dispInrush: partData.disp_inrush,
    inrushTimes: partData.inrush_times,
    inrushValue: BeamsNumber(inrushValue, 4),
    dampingTime: partData.damping_time,

    dispIntensity: partData.disp_intensity,
    intensityTimes: partData.intensity_times,
    intensityValue: BeamsNumber(intensityValue, 4),
    intensityTime: partData.intensity_time,

    // 電圧降下
    dropRegular: partData.drop_regular,

    calcPoint: doConvertDataCalcPoint(calcPoint,
      perZDirect,
      perZTotal,
      perZDirectMG,
      perZTotalMG,
      perZMotor) as CalcPoint,
    
    currentTopLeft: 0, // always 0

    voltageDrop1: BeamsNumber(voltageDrop1, 4),
    voltageDrop: BeamsNumber(voltageDrop, 4),
    
    // other
    selInrush: partData.sel_inrush,
    selIntensity: partData.sel_intensity,
    pageIndex: partData.page_index,
    inputedCapacity1: partData.inputed_capacity1,
    inputedCurrent1: partData.inputed_current1,
    inputedPowerFactor1: partData.inputed_power_factor1,
    neutralKind: partData.neutral_kind,
    dispAnsiKind: partData.disp_ansi_kind,
    ansiTimes: partData.ansi_times,
    ansiTime: partData.ansi_time,
    inrushTimesKind: partData.inrush_times_kind, // m_nInrushTimes
    inrushManualValue: partData.inrush_manual_value,
    inrushTime: partData.inrush_time,
    voltDropSumIm: partData.volt_drop_sum_im,
    voltDropSumRe: partData.volt_drop_sum_re,
    examineComment: partData.examine_comment
  } as MsTransScottPartModel
}