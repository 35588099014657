import { Button, ButtonGroup, Checkbox, Container, Dialog, DialogContent, DialogTitle, FormControlLabel, Grid, Radio, RadioGroup, Stack, TextField, TextareaAutosize, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import theme from "../../themes/globalStyles";
import DraggablePaper from "../common/DraggablePaper";
import {InfoCircuit, InfoSkeleton } from "../../models/Index";
import { useState } from "react";

const radioStyles = {
    padding: '0 0 0 9px',
}

export type SettingSkeletonForm = {
    squareCountHlizontal: number,
    squareCountVertical: number,
    zoom: number,
    marginTop:number,
    marginBottom: number,
    marginLeft:number,
    marginRight:number,
    orientation:number,
    paperSize: number,
    dispStress: boolean,
    dispSupply: number,//radio
    changeDeltaToStar: number,//radio
    roopOpen: boolean,
    printDetailHead: boolean,
    figTitle: string,
    figSubTitle: string,
    titleCorporation: string,
    corporation: string,
    revision0: string,
    titleProject: string,
    project: string,
    titleDrawn: string,
    drawner: string,
    titleDateDrawn: string,
    dateDrawn: string,
    revision1: string,
    titleReference: string,
    reference: string,
    titleChecked: string,
    checker: string,
    titleDateChecked: string,
    dateChecked: string,
    revision2: string,
    titleNumber: string,
    figNumber: string,
    titleDesigned: string,
    designer: string,
    titleDateDesigned: string,
    dateDesigned: string,
    revision3:string,
    titleMode: string,
    titleApproved: string,
    approver:string,
    titleDateApproved: string,
    dateApproved: string,
    revision4: string,
    titleComment: string,
    comments: string,
    dispStainOrBack: boolean,
}

export type SettingSkeletonFormProps = {
    infoCircuit: InfoCircuit;
    data: InfoSkeleton;
    onOK: (data: any) => void;
    onCancel: () => void;
};

export const SettingSkeletonDialog = (props: SettingSkeletonFormProps) => {
    //#region fields
    const {infoCircuit, data, onOK, onCancel } = props;
    // 初期値設定
    const initialValue : SettingSkeletonForm = {
        ...data,
        titleProject: infoCircuit.titleProject,
        project: infoCircuit.project,
        titleReference: infoCircuit.titleReference,
        reference: infoCircuit.reference,
        titleCorporation: infoCircuit.titleCorporation,
        corporation: infoCircuit.corporation
    }
    const checkboxStyle = {
        padding: '0px 0px 0px 9px'
    }

    const { control, handleSubmit, formState: { errors }, setValue, getValues } = useForm<SettingSkeletonForm>({
        mode: "all",
        criteriaMode: "all",
        defaultValues: initialValue,
    })
        const [isDisableField,SetIsDisableField] = useState(!initialValue.printDetailHead)
        const [isDisableDispSupply,SetIsDisableDispSupply] = useState(!initialValue.dispStress)

    //#endregion fields

    //#region methods
    // Submitイベント
    const handleEditSubmit = (value: any, event: any) => {
        const request = createRequestData(value);
        onOK(request);
    }
    
    // FormデータからRequestデータを作成する
    const createRequestData = (formValue : SettingSkeletonForm) => {       
        let request = {
            ...formValue,
        } as SettingSkeletonForm;
        return request;
    }

    const handleReset = () => {
        setValue("figTitle", data.figTitleDefault)
        setValue("figSubTitle", data.figSubTitleDefault)
        setValue("titleDrawn", data.titleDrawnDefault)
        setValue("titleChecked", data.titleCheckedDefault)
        setValue("titleNumber", data.titleNumberDefault)
        setValue("titleDesigned", data.titleDesignedDefault)
        setValue("titleMode", data.titleModeDefault)
        setValue("titleApproved", data.titleApprovedDefault)
        setValue("titleComment", data.titleCommentDefault)

        setValue("titleDateDrawn", data.titleDateDefault)
        setValue("titleDateChecked", data.titleDateDefault)
        setValue("titleDateDesigned", data.titleDateDefault)
        setValue("titleDateApproved", data.titleDateDefault)
    }
    const checkedPrintDetailHead =(e:any) =>{
        setValue(e.target.name,e.target.checked)
        if(getValues("printDetailHead")){
            SetIsDisableField(false)
        }
        else{
            SetIsDisableField(true)
        }
    }
    const checkedDispStress =(e:any) =>{
        setValue(e.target.name,e.target.checked)
        if(getValues("dispStress")){
            SetIsDisableDispSupply(false)
        }
        else{
            SetIsDisableDispSupply(true)
        }
    }
    const onClickDispSupply =(e:any) =>{
        setValue(e.target.name,e.target.value)
        if(getValues("dispSupply") == 1){
            setValue("dispStainOrBack",true)
        }
        else{
            setValue("dispStainOrBack",false)
        }
    }
    

    //#endregion methods

    return (
        <>
            <Dialog open={true} maxWidth={"md"} fullWidth
                PaperComponent={
                    DraggablePaper
                }
            >
                <DialogTitle 
                    onMouseOver={(e : any) => e.target.style.cursor = 'move'}
                    style={{ paddingTop: '2px', paddingBottom: '2px', background: theme.palette.primary.main, color: theme.palette.primary.contrastText }}
                >
                    単線結線図の設定
                </DialogTitle>
                <DialogContent style={{paddingTop: '10px'}}>
                <div style={{height:"40vh", width: '100%',marginLeft:'-10px'}}>
                    <form onSubmit={handleSubmit(handleEditSubmit)}>
                        <div style={{height:"calc(40vh - 45px)",overflowY:"auto", width: '100%'}}>
                            <Grid container >
                                <Grid container>
                                    <fieldset style={{width:"55%",border:'1px solid #AAAA'}}>
                                            <legend ><Typography variant="body2">格子数{'(最大: 2048 X 1024 )'}</Typography></legend>
                                            <Grid container item xs={12}>
                                                <Grid item xs={5} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                    <Typography variant="body2" width="20%">横:</Typography>
                                                    <Controller
                                                        name="squareCountHlizontal"
                                                        control={control}
                                                        rules={{
                                                            required: '必須項目です。入力してください。' ,
                                                            pattern:{
                                                                value: /^[0-9\b]+$/,
                                                                message: '数字を入力してください。'
                                                            },
                                                            validate: value => value > 0 && value <= 2048 || '数字 > 0 & <= 2048  を入力してください。' ,
                                                        }}
                                                        render={({ field }) => (
                                                            <TextField 
                                                                {...field}
                                                                fullWidth
                                                                type="text"
                                                                size="small"
                                                                error={!!errors?.squareCountHlizontal}
                                                                helperText={errors?.squareCountHlizontal?.message}
                                                                inputProps={{maxLength:4}}
                                                                InputProps={{sx: { height: 32 }}}
                                                            />
                                                        )}
                                                    />
                                                 </Grid>
                                                 <Grid item xs={6} display={'flex'} alignItems={'center'} justifyContent={'center'} pl={2}>
                                                    <Typography variant="body2" width="20%">縦:</Typography>
                                                        <Controller
                                                            name="squareCountVertical"
                                                            control={control}
                                                            rules={{
                                                                required: '必須項目です。入力してください。' ,
                                                                pattern:{
                                                                    value: /^[0-9\b]+$/,
                                                                    message: '数字を入力してください。'
                                                                },
                                                                validate: value => value > 0 && value <= 1024  || '数字 > 0 && <= 1024 を入力してください。' ,
                                                            }}
                                                            render={({ field }) => (
                                                                <TextField 
                                                                    {...field}
                                                                    fullWidth
                                                                    type="text"
                                                                    size="small"
                                                                    error={!!errors?.squareCountVertical}
                                                                    helperText={errors?.squareCountVertical?.message}
                                                                    inputProps={{maxLength:4}}
                                                                    InputProps={{sx: { height: 32 }}}
                                                                />
                                                            )}
                                                        />
                                                </Grid>
                                            </Grid>
                                        </fieldset>
                                </Grid>
                                <Grid container mt ={1}>
                                            <fieldset style={{border:'1px solid #AAAA',width:"55%"}}>
                                                <legend ><Typography variant="body2">系統表示</Typography></legend>
                                                    <Grid>
                                                        <Controller
                                                            name="dispStress"
                                                            control={control}
                                                            render={({ field }: any) => (
                                                                <FormControlLabel 
                                                                    {...field} 
                                                                    checked={field.value}
                                                                    label={<Typography variant="body2">回路チェックモード以降で強調表示する</Typography>}
                                                                    name='dispStress'
                                                                    control={<Checkbox style={checkboxStyle} />} 
                                                                    onChange={(e) =>{checkedDispStress(e)}}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid ml={3} mt={1}>
                                                    <Controller
                                                        name="dispSupply"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <RadioGroup
                                                                {...field}
                                                                sx={{justifyContent: 'space-evenly'}}
                                                                onClick={(e) =>onClickDispSupply(e)}
                                                            >
                                                                <FormControlLabel
                                                                    control={<Radio style={radioStyles} size="small" />}
                                                                    label={<Typography variant="body2" color={isDisableDispSupply?'grey':'black'}>電源から切り離されている回路の背景を強調表示する</Typography>}
                                                                    value={"0"}
                                                                    disabled={isDisableDispSupply}
                                                                />
                                                                <FormControlLabel
                                                                    control={<Radio style={radioStyles} size="small" />}
                                                                    label={<Typography variant="body2" color={isDisableDispSupply?'grey':'black'}>通電回路（赤色）と非通電回路（緑色）を色分けして表示する</Typography>}
                                                                    value={"1"}
                                                                    disabled={isDisableDispSupply}

                                                                />
                                                            </RadioGroup>
                                                        
                                                        )}
                                                    />
                                                </Grid>

                                            </fieldset>
                                    </Grid>
                                <Grid container mt={1}>
                                    <Grid item xs={6}>
                                        <fieldset style={{border:'1px solid #AAAA'}}>
                                            <legend ><Typography variant="body2">◿ ⇔ Y変換順番</Typography></legend>
                                                <Grid>
                                                    <Controller
                                                        name="changeDeltaToStar"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <RadioGroup
                                                                {...field}
                                                                row
                                                                sx={{paddingLeft:'5%'}}
                                                                
                                                            >
                                                                <FormControlLabel
                                                                    control={<Radio style={radioStyles} size="small" />}
                                                                    label={<Typography variant="body2">◿ → Y変換を先に実施する</Typography>}
                                                                    value={"0"}
                                                                />
                                                                <FormControlLabel
                                                                    control={<Radio style={radioStyles} size="small" />}
                                                                    label={<Typography variant="body2">Y → ◿変換を先に実施する</Typography>}
                                                                    value={"1"}

                                                                />
                                                            </RadioGroup>
                                                        
                                                        )}
                                                    />
                                                </Grid>
                                        </fieldset>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <fieldset style={{height:'calc(100% - 18px)',border:'1px solid #AAAA'}}>
                                            <legend ><Typography variant="body2">ループ回路の通過短絡電流計算</Typography></legend>
                                                <Grid paddingLeft={2}>
                                                    <Controller
                                                        name="roopOpen"
                                                        control={control}
                                                        render={({ field }: any) => (
                                                            <FormControlLabel 
                                                                {...field}
                                                                checked={field.value}
                                                                label={<Typography variant="body2">二次側が開放されたと仮定する</Typography>}
                                                                name='roopOpen'
                                                                control={<Checkbox style={checkboxStyle} />} 
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                        </fieldset>
                                    </Grid>
                                </Grid>
                                <Grid container mt={1}>
                                    <fieldset style={{width:'100%',border:'1px solid #AAAA'}}>
                                        <legend ><Typography variant="body2">単線結線図の印刷で使用</Typography></legend>
                                            <Stack direction="row" justifyContent={"space-between"}>
                                                <Stack>
                                                    <Controller
                                                            name="printDetailHead"
                                                            control={control}
                                                            render={({ field }: any) => (
                                                                <FormControlLabel 
                                                                    {...field}
                                                                    checked={field.value}
                                                                    label={<Typography variant="body2">ヘッドラインを印刷</Typography>}
                                                                    name='printDetailHead'
                                                                    control={<Checkbox style={checkboxStyle} />} 
                                                                    onChange={e =>checkedPrintDetailHead(e)}
                                                                />
                                                            )}
                                                        />
                                                </Stack>
                                                <Stack  mr={1}>
                                                    <Button  color="inherit" variant="contained" onClick={handleReset}>リセット</Button>
                                                </Stack>
                                            </Stack>
                                            <Grid container direction={"row"} mt={1}>
                                                        <Grid item xs={4} pl={0.3}>
                                                            <Stack>
                                                                <Typography variant="body2">タイトル:</Typography>
                                                            </Stack>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Stack>
                                                                <Typography variant="body2">サブタイトル:</Typography>
                                                            </Stack>
                                                        </Grid>
                                                </Grid>
                                                        <Grid container direction={"row"} spacing={0.3}>
                                                                <Grid item xs={3.95}>
                                                                    <Controller
                                                                        name="figTitle"
                                                                        control={control}
                                                                        render={({ field }) => (
                                                                            <>
                                                                                <TextField
                                                                                    {...field}
                                                                                    type="text"
                                                                                    size="small"
                                                                                    fullWidth
                                                                                    error={!!errors?.figTitle}
                                                                                    onFocus={e => e.target.select()}
                                                                                    InputProps={{
                                                                                        sx:{height:32},
                                                                                    }}
                                                                                />
                                                                            </>
                                                                        )}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={5.95}>
                                                                    <Controller
                                                                        name="figSubTitle"
                                                                        control={control}
                                                                        render={({ field }) => (
                                                                            <>
                                                                                <TextField
                                                                                    {...field}
                                                                                    type="text"
                                                                                    size="small"
                                                                                    fullWidth
                                                                                    error={!!errors?.figSubTitle}
                                                                                    onFocus={e => e.target.select()}
                                                                                    InputLabelProps={{
                                                                                        style: { color: 'black' },
                                                                                    }}
                                                                                    InputProps={{
                                                                                        sx:{height:32},
                                                                                    }}
                                                                                />
                                                                            </>
                                                                        )}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={1} mt={0.6} ml={0.3}>
                                                                    <Typography variant="body2">Revisions:</Typography>
                                                                </Grid>
                                                        </Grid>

                                            <Grid container direction={"row"} mt={1}>
                                                <Grid item xs={2}>
                                                        <Controller
                                                                name="titleCorporation"
                                                                control={control}
                                                                rules={{
                                                                }}
                                                                render={({ field }) => (
                                                                    <TextField 
                                                                        {...field}
                                                                        fullWidth
                                                                        type="text"
                                                                        size="small"
                                                                        error={!!errors?.titleCorporation}
                                                                        helperText={errors?.titleCorporation?.message}
                                                                        
                                                                        style={{width:'100%',backgroundColor:'floralwhite'}}
                                                                        disabled={true}
                                                                        // value={"会社"}
                                                                        InputProps={{sx: { height: 32 }}}

                                                                    />
                                                                )}
                                                            />
                                                </Grid>
                                                <Grid item xs ={0.2} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                    :
                                                </Grid>
                                                <Grid item xs={7.698}>
                                                            <Controller
                                                                    name="corporation"
                                                                    control={control}
                                                                    rules={{
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <TextField 
                                                                            {...field}
                                                                            fullWidth
                                                                            type="text"
                                                                            size="small"
                                                                            error={!!errors?.corporation}
                                                                            helperText={errors?.corporation?.message}
                                                                            style={{backgroundColor:'floralwhite'}}
                                                                            InputProps={{sx: { height: 32 }}}

                                                                            disabled={true}
                                                                        />
                                                                    )}
                                                                />
                                                </Grid>
                                                <Grid item xs={2} ml={0.4}>
                                                    <Typography variant="body2" width="25%"></Typography>
                                                        <Controller
                                                                name="revision0"
                                                                control={control}
                                                                rules={{
                                                                }}
                                                                render={({ field }) => (
                                                                    <TextField 
                                                                        {...field}
                                                                        fullWidth
                                                                        type="text"
                                                                        size="small"
                                                                        error={!!errors?.revision0}
                                                                        helperText={errors?.revision0?.message}
                                                                        disabled={isDisableField}
                                                                        InputProps={{sx: { height: 32 }}}

                                                                        style={{
                                                                            backgroundColor: isDisableField ? 'floralwhite' : 'white',
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                </Grid>
                                            </Grid>

                                            <Grid container direction={"row"} mt={1}>
                                                <Grid item xs={2}>
                                                    <Stack>
                                                            <Controller
                                                                    name="titleProject"
                                                                    control={control}
                                                                    rules={{
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <TextField 
                                                                            {...field}
                                                                            fullWidth
                                                                            type="text"
                                                                            size="small"
                                                                            error={!!errors?.titleProject}
                                                                            helperText={errors?.titleProject?.message}
                                                                            style={{width:'100%',backgroundColor:'floralwhite'}}
                                                                            InputProps={{sx: { height: 32 }}}

                                                                            disabled={true}
                                                                            // value={"プロジェクト"}

                                                                        />
                                                                    )}
                                                                />
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs ={0.2} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                    :
                                                </Grid>
                                                <Grid item xs={1.75}>
                                                    <Stack>
                                                            <Controller
                                                                    name="project"
                                                                    control={control}
                                                                    rules={{
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <TextField 
                                                                            {...field}
                                                                            fullWidth
                                                                            type="text"
                                                                            size="small"
                                                                            error={!!errors?.project}
                                                                            helperText={errors?.project?.message}
                                                                            style={{backgroundColor:'floralwhite'}}
                                                                            InputProps={{sx: { height: 32 }}}

                                                                            disabled={true}
                                                                            
                                                                        />
                                                                    )}
                                                                />
                                                    </Stack>
                                                </Grid>
                                                {/* <Grid item xs ={0.15} mt={2.5} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                    
                                                </Grid> */}
                                                <Grid item xs={1.15} ml={0.3}>
                                                    <Stack>
                                                            <Controller
                                                                    name="titleDrawn"
                                                                    control={control}
                                                                    rules={{
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <TextField 
                                                                            {...field}
                                                                            fullWidth
                                                                            type="text"
                                                                            size="small"
                                                                            error={!!errors?.titleDrawn}
                                                                            helperText={errors?.titleDrawn?.message}
                                                                            disabled={isDisableField}
                                                                            InputProps={{sx: { height: 32 }}}

                                                                            style={{
                                                                                backgroundColor: isDisableField ? 'floralwhite' : 'white',
                                                                            }}
                                                                        />
                                                                    )}
                                                                />
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs ={0.2} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                    :
                                                </Grid>
                                                <Grid item xs={1.75}>
                                                    <Stack>
                                                            <Controller
                                                                    name="drawner"
                                                                    control={control}
                                                                    rules={{
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <TextField 
                                                                            {...field}
                                                                            fullWidth
                                                                            type="text"
                                                                            size="small"
                                                                            error={!!errors?.drawner}
                                                                            helperText={errors?.drawner?.message}
                                                                            disabled={isDisableField}
                                                                            style={{
                                                                                backgroundColor: isDisableField ? 'floralwhite' : 'white',
                                                                            }}
                                                                            InputProps={{sx: { height: 32 }}}

                                                                        />
                                                                    )}
                                                                />
                                                    </Stack>
                                                </Grid>
                                                {/* <Grid item xs ={0.15} mt={2.5} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                    
                                                </Grid> */}
                                                <Grid item xs={1.15} ml={0.3}>
                                                    <Stack >
                                                            <Controller
                                                                    name="titleDateDrawn"
                                                                    control={control}
                                                                    rules={{
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <TextField 
                                                                            {...field}
                                                                            fullWidth
                                                                            type="text"
                                                                            size="small"
                                                                            error={!!errors?.titleDateDrawn}
                                                                            helperText={errors?.titleDateDrawn?.message}
                                                                            disabled={isDisableField}
                                                                            style={{
                                                                                backgroundColor: isDisableField ? 'floralwhite' : 'white',
                                                                            }}
                                                                            InputProps={{sx: { height: 32 }}}

                                                                        />
                                                                    )}
                                                                />
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs ={0.2} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                    :
                                                </Grid>
                                                <Grid item xs={1.42}>
                                                    <Stack >
                                                            <Controller
                                                                    name="dateDrawn"
                                                                    control={control}
                                                                    rules={{
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <TextField 
                                                                            {...field}
                                                                            fullWidth
                                                                            type="text"
                                                                            size="small"
                                                                            error={!!errors?.dateDrawn}
                                                                            helperText={errors?.dateDrawn?.message}
                                                                            disabled={isDisableField}
                                                                            style={{
                                                                                backgroundColor: isDisableField ? 'floralwhite' : 'white',
                                                                            }}
                                                                            InputProps={{sx: { height: 32 }}}

                                                                        />
                                                                    )}
                                                                />
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={2} ml={0.4}>
                                                    <Stack>
                                                            <Controller
                                                                    name="revision1"
                                                                    control={control}
                                                                    rules={{
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <TextField 
                                                                            {...field}
                                                                            fullWidth
                                                                            type="text"
                                                                            size="small"
                                                                            error={!!errors?.revision1}
                                                                            helperText={errors?.revision1?.message}
                                                                            disabled={isDisableField}
                                                                            style={{
                                                                                backgroundColor: isDisableField ? 'floralwhite' : 'white',
                                                                            }}
                                                                            InputProps={{sx: { height: 32 }}}

                                                                        />
                                                                    )}
                                                                />
                                                    </Stack>
                                                </Grid>
                                            </Grid>

                                            <Grid container direction={"row"} mt={1}>
                                                <Grid item xs={2}>
                                                    <Stack>
                                                            <Controller
                                                                    name="titleReference"
                                                                    control={control}
                                                                    rules={{
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <TextField 
                                                                            {...field}
                                                                            fullWidth
                                                                            type="text"
                                                                            size="small"
                                                                            error={!!errors?.titleReference}
                                                                            helperText={errors?.titleReference?.message}
                                                                            style={{width:'100%',backgroundColor:'floralwhite'}}

                                                                            disabled={true}
                                                                            // value={"回路名称"}
                                                                            InputProps={{sx: { height: 32 }}}

                                                                        />
                                                                    )}
                                                                />
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs ={0.2} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                    :
                                                </Grid>
                                                <Grid item xs={1.75}>
                                                    <Stack>
                                                            <Controller
                                                                    name="reference"
                                                                    control={control}
                                                                    rules={{
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <TextField 
                                                                            {...field}
                                                                            fullWidth
                                                                            type="text"
                                                                            size="small"
                                                                            error={!!errors?.reference}
                                                                            helperText={errors?.reference?.message}
                                                                            style={{backgroundColor:'floralwhite'}}
                                                                            InputProps={{sx: { height: 32 }}}

                                                                            disabled={true}
                                                                            
                                                                        />
                                                                    )}
                                                                />
                                                    </Stack>
                                                </Grid>
                                                {/* <Grid item xs ={0.15} mt={2.5} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                    
                                                </Grid> */}
                                                <Grid item xs={1.15} ml={0.3}>
                                                    <Stack>
                                                            <Controller
                                                                    name="titleChecked"
                                                                    control={control}
                                                                    rules={{
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <TextField 
                                                                            {...field}
                                                                            fullWidth
                                                                            type="text"
                                                                            size="small"
                                                                            error={!!errors?.titleChecked}
                                                                            helperText={errors?.titleChecked?.message}
                                                                            disabled={isDisableField}
                                                                            style={{
                                                                                backgroundColor: isDisableField ? 'floralwhite' : 'white',
                                                                            }}
                                                                            InputProps={{sx: { height: 32 }}}

                                                                        />
                                                                    )}
                                                                />
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs ={0.2} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                    :
                                                </Grid>
                                                <Grid item xs={1.75}>
                                                    <Stack>
                                                            <Controller
                                                                    name="checker"
                                                                    control={control}
                                                                    rules={{
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <TextField 
                                                                            {...field}
                                                                            fullWidth
                                                                            type="text"
                                                                            size="small"
                                                                            error={!!errors?.checker}
                                                                            helperText={errors?.checker?.message}
                                                                            disabled={isDisableField}
                                                                            style={{
                                                                                backgroundColor: isDisableField ? 'floralwhite' : 'white',
                                                                            }}
                                                                            InputProps={{sx: { height: 32 }}}

                                                                        />
                                                                    )}
                                                                />
                                                    </Stack>
                                                </Grid>
                                                {/* <Grid item xs ={0.15} mt={2.5} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                    
                                                </Grid> */}
                                                <Grid item xs={1.15} ml={0.3}>
                                                    <Stack >
                                                            <Controller
                                                                    name="titleDateChecked"
                                                                    control={control}
                                                                    rules={{
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <TextField 
                                                                            {...field}
                                                                            fullWidth
                                                                            type="text"
                                                                            size="small"
                                                                            error={!!errors?.titleDateChecked}
                                                                            helperText={errors?.titleDateChecked?.message}
                                                                            disabled={isDisableField}
                                                                            style={{
                                                                                backgroundColor: isDisableField ? 'floralwhite' : 'white',
                                                                            }}
                                                                            InputProps={{sx: { height: 32 }}}

                                                                        />
                                                                    )}
                                                                />
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs ={0.2} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                    :
                                                </Grid>
                                                <Grid item xs={1.42}>
                                                    <Stack >
                                                            <Controller
                                                                    name="dateChecked"
                                                                    control={control}
                                                                    rules={{
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <TextField 
                                                                            {...field}
                                                                            fullWidth
                                                                            type="text"
                                                                            size="small"
                                                                            error={!!errors?.dateChecked}
                                                                            helperText={errors?.dateChecked?.message}
                                                                            disabled={isDisableField}
                                                                            style={{
                                                                                backgroundColor: isDisableField ? 'floralwhite' : 'white',
                                                                            }}
                                                                            InputProps={{sx: { height: 32 }}}

                                                                        />
                                                                    )}
                                                                />
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={2} ml={0.4}>
                                                    <Stack>
                                                            <Controller
                                                                    name="revision2"
                                                                    control={control}
                                                                    rules={{
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <TextField 
                                                                            {...field}
                                                                            fullWidth
                                                                            type="text"
                                                                            size="small"
                                                                            error={!!errors?.revision2}
                                                                            helperText={errors?.revision2?.message}
                                                                            disabled={isDisableField}
                                                                            style={{
                                                                                backgroundColor: isDisableField ? 'floralwhite' : 'white',
                                                                            }}
                                                                            InputProps={{sx: { height: 32 }}}

                                                                        />
                                                                    )}
                                                                />
                                                    </Stack>
                                                </Grid>
                                            </Grid>

                                            <Grid container direction={"row"} mt={1}>
                                                <Grid item xs={2}>
                                                    <Stack>
                                                            <Controller
                                                                  name="titleNumber"
                                                                    control={control}
                                                                    rules={{
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <TextField 
                                                                            {...field}
                                                                            fullWidth
                                                                            type="text"
                                                                            size="small"
                                                                            error={!!errors?.titleNumber}
                                                                            helperText={errors?.titleNumber?.message}
                                                                            disabled={isDisableField}
                                                                            style={{
                                                                                backgroundColor: isDisableField ? 'floralwhite' : 'white',
                                                                            }}
                                                                        InputProps={{sx: { height: 32 }}}
                                                                            
                                                                        />
                                                                    )}
                                                                />
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs ={0.2} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                    :
                                                </Grid>
                                                <Grid item xs={1.75}>
                                                    <Stack>
                                                            <Controller
                                                                    name="figNumber"
                                                                    control={control}
                                                                    rules={{
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <TextField 
                                                                            {...field}
                                                                            fullWidth
                                                                            type="text"
                                                                            size="small"
                                                                            error={!!errors?.figNumber}
                                                                            helperText={errors?.figNumber?.message}
                                                                            disabled={isDisableField}
                                                                            style={{
                                                                                backgroundColor: isDisableField ? 'floralwhite' : 'white',
                                                                            }}
                                                                            InputProps={{sx: { height: 32 }}}

                                                                        />
                                                                    )}
                                                                />
                                                    </Stack>
                                                </Grid>
                                                {/* <Grid item xs ={0.15} mt={2.5} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                    
                                                </Grid> */}
                                                <Grid item xs={1.15} ml={0.3}>                
                                                    <Stack>
                                                            <Controller
                                                                    name="titleDesigned"
                                                                    control={control}
                                                                    rules={{
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <TextField 
                                                                            {...field}
                                                                            fullWidth
                                                                            type="text"
                                                                            size="small"
                                                                            error={!!errors?.titleDesigned}
                                                                            helperText={errors?.titleDesigned?.message}
                                                                            disabled={isDisableField}
                                                                            style={{
                                                                                backgroundColor: isDisableField ? 'floralwhite' : 'white',
                                                                            }}
                                                                            InputProps={{sx: { height: 32 }}}

                                                                        />
                                                                    )}
                                                                />
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs ={0.2} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                    :
                                                </Grid>
                                                <Grid item xs={1.75}>
                                                    <Stack>
                                                            <Controller
                                                                    name="designer"
                                                                    control={control}
                                                                    rules={{
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <TextField 
                                                                            {...field}
                                                                            fullWidth
                                                                            type="text"
                                                                            size="small"
                                                                            error={!!errors?.designer}
                                                                            helperText={errors?.designer?.message}
                                                                            disabled={isDisableField}
                                                                            style={{
                                                                                backgroundColor: isDisableField ? 'floralwhite' : 'white',
                                                                            }}
                                                                            InputProps={{sx: { height: 32 }}}

                                                                        />
                                                                    )}
                                                                />
                                                    </Stack>
                                                </Grid>
                                                {/* <Grid item xs ={0.15} mt={2.5} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                    
                                                </Grid> */}
                                                <Grid item xs={1.15} ml={0.3}>
                                                    <Stack>
                                                            <Controller
                                                                    name="titleDateDesigned"
                                                                    control={control}
                                                                    rules={{
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <TextField 
                                                                            {...field}
                                                                            fullWidth
                                                                            type="text"
                                                                            size="small"
                                                                            error={!!errors?.titleDateDesigned}
                                                                            helperText={errors?.titleDateDesigned?.message}
                                                                            disabled={isDisableField}
                                                                            style={{
                                                                                backgroundColor: isDisableField ? 'floralwhite' : 'white',
                                                                            }}
                                                                            InputProps={{sx: { height: 32 }}}

                                                                        />
                                                                    )}
                                                                />
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs ={0.2} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                    :
                                                </Grid>
                                                <Grid xs={1.42}>
                                                    <Stack >
                                                            <Controller
                                                                    name="dateDesigned"
                                                                    control={control}
                                                                    rules={{
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <TextField 
                                                                            {...field}
                                                                            fullWidth
                                                                            type="text"
                                                                            size="small"
                                                                            error={!!errors?.dateDesigned}
                                                                            helperText={errors?.dateDesigned?.message}
                                                                            disabled={isDisableField}
                                                                            style={{
                                                                                backgroundColor: isDisableField ? 'floralwhite' : 'white',
                                                                            }}
                                                                            InputProps={{sx: { height: 32 }}}

                                                                        />
                                                                    )}
                                                                />
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={2} ml={0.4}>
                                                    <Stack>
                                                            <Controller
                                                                    name="revision3"
                                                                    control={control}
                                                                    rules={{
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <TextField 
                                                                            {...field}
                                                                            fullWidth
                                                                            type="text"
                                                                            size="small"
                                                                            error={!!errors?.revision3}
                                                                            helperText={errors?.revision3?.message}
                                                                            disabled={isDisableField}
                                                                            style={{
                                                                                backgroundColor: isDisableField ? 'floralwhite' : 'white',
                                                                            }}
                                                                            InputProps={{sx: { height: 32 }}}

                                                                        />
                                                                    )}
                                                                />
                                                    </Stack>
                                                </Grid>
                                            </Grid>

                                            <Grid container direction={"row"} mt={1}>
                                                <Grid item xs={2}>
                                                    <Stack>
                                                            <Controller
                                                                    name="titleMode"
                                                                    control={control}
                                                                    rules={{
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <TextField 
                                                                            {...field}
                                                                            fullWidth
                                                                            type="text"
                                                                            size="small"
                                                                            error={!!errors?.titleMode}
                                                                            helperText={errors?.titleMode?.message}
                                                                            style={{width:'100%'}}
                                                                            InputProps={{sx: { height: 32 }}}

                                                                        />
                                                                    )}
                                                                />
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs ={0.2} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                    
                                                </Grid>
                                                <Grid item xs={1.75}>
                                                    <Stack>
                                                            
                                                    </Stack>
                                                </Grid>
                                                {/* <Grid item xs ={0.15} mt={2.5} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                    
                                                </Grid> */}
                                                <Grid item xs={1.15} ml={0.3}>                
                                                    <Stack>
                                                            <Controller
                                                                    name="titleApproved"
                                                                    control={control}
                                                                    rules={{
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <TextField 
                                                                            {...field}
                                                                            fullWidth
                                                                            type="text"
                                                                            size="small"
                                                                            error={!!errors?.titleApproved}
                                                                            helperText={errors?.titleApproved?.message}
                                                                            disabled={isDisableField}
                                                                            style={{
                                                                                backgroundColor: isDisableField ? 'floralwhite' : 'white',
                                                                            }}
                                                                            InputProps={{sx: { height: 32 }}}

                                                                        />
                                                                    )}
                                                                />
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs ={0.2} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                    :
                                                </Grid>
                                                <Grid item xs={1.75}>
                                                    <Stack>
                                                            <Controller
                                                                    name="approver"
                                                                    control={control}
                                                                    rules={{
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <TextField 
                                                                            {...field}
                                                                            fullWidth
                                                                            type="text"
                                                                            size="small"
                                                                            error={!!errors?.approver}
                                                                            helperText={errors?.approver?.message}
                                                                            disabled={isDisableField}
                                                                            style={{
                                                                                backgroundColor: isDisableField ? 'floralwhite' : 'white',
                                                                            }}
                                                                            InputProps={{sx: { height: 32 }}}

                                                                        />
                                                                    )}
                                                                />
                                                    </Stack>
                                                </Grid>
                                                {/* <Grid item xs ={0.15} mt={2.5} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                    
                                                </Grid> */}
                                                <Grid item xs={1.15} ml={0.3}>
                                                    <Stack>
                                                            <Controller
                                                                    name="titleDateApproved"
                                                                    control={control}
                                                                    rules={{
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <TextField 
                                                                            {...field}
                                                                            fullWidth
                                                                            type="text"
                                                                            size="small"
                                                                            error={!!errors?.titleDateApproved}
                                                                            helperText={errors?.titleDateApproved?.message}
                                                                            disabled={isDisableField}
                                                                            style={{
                                                                                backgroundColor: isDisableField ? 'floralwhite' : 'white',
                                                                            }}
                                                                            InputProps={{sx: { height: 32 }}}

                                                                            
                                                                        />
                                                                    )}
                                                                />
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs ={0.2} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                    :
                                                </Grid>
                                                <Grid xs={1.42}>
                                                    <Stack >
                                                            <Controller
                                                                    name="dateApproved"
                                                                    control={control}
                                                                    rules={{
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <TextField 
                                                                            {...field}
                                                                            fullWidth
                                                                            type="text"
                                                                            size="small"
                                                                            error={!!errors?.dateApproved}
                                                                            helperText={errors?.dateApproved?.message}
                                                                            disabled={isDisableField}
                                                                            style={{
                                                                                backgroundColor: isDisableField ? 'floralwhite' : 'white',
                                                                            }}
                                                                            InputProps={{sx: { height: 32 }}}

                                                                        />
                                                                    )}
                                                                />
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={2} ml={0.4}>
                                                    <Stack>
                                                            <Controller
                                                                    name="revision4"
                                                                    control={control}
                                                                    rules={{
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <TextField 
                                                                            {...field}
                                                                            fullWidth
                                                                            type="text"
                                                                            size="small"
                                                                            error={!!errors?.revision4}
                                                                            helperText={errors?.revision4?.message}
                                                                            disabled={isDisableField}
                                                                            style={{
                                                                                backgroundColor: isDisableField ? 'floralwhite' : 'white',
                                                                            }}
                                                                            InputProps={{sx: { height: 32 }}}

                                                                        />
                                                                    )}
                                                                />
                                                    </Stack>
                                                </Grid>
                                            </Grid>

                                            <Grid container mt={1}>
                                                <Grid item xs={2}>
                                                    <Stack direction={'row'} >
                                                        <Controller
                                                            name="titleComment"
                                                            control={control}
                                                            render={({ field }: any) => (
                                                                <TextField
                                                                    {...field}
                                                                    type="text"
                                                                    size="small"
                                                                    // sx={{flex: 1}}
                                                                    InputProps={{sx: { height: 32 }}}

                                                                />
                                                            )}
                                                        />
                                                        </Stack>
                                                </Grid>
                                                <Grid item xs ={0.2} pl={0.5} alignItems={'center'} justifyContent={'center'}>
                                                    :
                                                </Grid>
                                                <Grid item xs ={9.75}>
                                                    <Stack>
                                                    <Controller
                                                    name="comments"
                                                    control={control}
                                                    
                                                    render={({ field }) => (
                                                        <TextareaAutosize
                                                                    {...field}
                                                                    
                                                                    maxRows={6}
                                                                    minRows={6}
                                                                    style={{overflowY:'scroll',resize:'none',fontFamily:'Roboto,Helvetica,Arial,sans-serif',fontSize:'16px'}}
                                                                    />
                                                                    )}
                                                                />
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                        
                                    </fieldset>

                                </Grid>

                            </Grid>  
                            
                        </div>  
                        <Stack direction='row' justifyContent="flex-end" gap={2} mt={1} paddingBottom={1}>
                                <Button variant="contained" type="submit">OK</Button>
                                <Button color="inherit" variant="contained" onClick={onCancel}>キャンセル</Button>
                        </Stack>                  
                    </form>
                </div>
                </DialogContent>
            </Dialog>
        </>
    )
}
export default SettingSkeletonDialog;