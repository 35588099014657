import { Box, Button, Checkbox, FormControl, FormControlLabel, Grid, Stack, Tab, Tabs, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { MsLightBoardModel } from '../../models/Index';
import TextFieldOptions from "../common/TextFieldOptions";
import { BeamsNumber } from "../../utils/FormatNumber";
import { MS_KW_TO_HORSEPOWER, MS_PI } from "../../models/Constants";
import * as Constant from "../../models/Constants";
import { MS_MODE_CHECKING } from "../../statics";

export type Image3_4EditDialogFormProps = {
    data: MsLightBoardModel;
    onOK: (data: MsLightBoardModel) => void;
    onCancel: () => void;
    setIsConnectTrans1OrTranscott: boolean;
    userRole:string;
    m_bModePM: boolean;
    processMode:number;
    modeViewOnly:boolean;
};

function a11yProps(index: number) {
    return {
        id: `tab-${index}`,
        "aria-controls": `tabpanel-${index}`,
    };
}
//Tabs------------------
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}
const checkboxStyle = {
    padding: '4px 4px 4px 8px',
}

export const MsLightBoardEditDialog = (props: Image3_4EditDialogFormProps) => {
    const { userRole, data, onOK, onCancel,setIsConnectTrans1OrTranscott,m_bModePM,processMode, modeViewOnly} = props;
    const [selectTab, setSelectTab] = useState(0);
    
    // 初期値設定
    const initialValue: MsLightBoardModel = {
        ...data
    }
    const { control, handleSubmit, formState: { errors }, getValues, setFocus, setValue } = useForm<MsLightBoardModel>({
        mode: 'all',
        criteriaMode: "all",
        defaultValues: initialValue,
    });
    const [makeDataInput, setMakeDataInput] = useState(initialValue.makeDataInput); // ModePM
    const [isDisabledEditValue, setDisabledEditValue] = useState<boolean>(!initialValue.inputDetail);

    // Submitイベント
    const handleEditSubmit = (value: MsLightBoardModel) => {
        const request = createRequestData(value);
        onOK(request);
    }
    const handleChangeFormat1 = (e: any) => {
        setValue(e.target.name, e.target.value);
    }
    // FormデータからRequestデータを作成する
    const createRequestData = (formValue: MsLightBoardModel) => {
        
        let request = {
            ...formValue
        } as MsLightBoardModel;
        return request;
    }

    // キャンセル
    const handleCancel = () => {
        onCancel();
    };
    // ModePM
    const handleChangeMakeDataInput = (e: any) => {
        setValue(e.target.name, e.target.checked);
        setMakeDataInput(e.target.checked);
    }
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        if (newValue === 1) {
            if (isNaN(Number(getValues('fullCurrent'))) || Number(getValues('fullCurrent')) === 0) {
                setFocus("fullCurrent");
            } else {
                setSelectTab(newValue);
            }
        } else
            setSelectTab(newValue);
    };
    const handleChangeColor = () => m_bModePM|| processMode > MS_MODE_CHECKING?'floralwhite':getValues("inputDetail") ? "white" : "floralwhite";
    const handleChangeColorRevert = () => (getValues("inputDetail")||m_bModePM|| processMode > MS_MODE_CHECKING) ?'floralwhite': "white"
    const handleChangeFormat = (e: any) => {
        if (getValues(e.target.name) === false) {
            setValue(e.target.name, !e.target.value);
            setDisabledEditValue(false)
            doSetValuesLightBoard(e,"",true)
        } else {
            setValue(e.target.name, !e.target.value);
            setValue("totalCapacity", getValues("totalCapacity1"));
            setValue("powerFactor",getValues("powerFactor1"));
            setValue("fullCurrent", getValues("fullCurrent1"));
            setValue("phaseCurrent", getValues("phaseCurrent1"));
            setDisabledEditValue(true)
            doSetValuesLightBoard(e,"",false)

        };
       
    }
    const doGetValueTotalCapacity = (e:any,totalCapacityPF:number,inputDetailTemp:boolean|undefined = undefined) =>{
        setValue(e.target.name,e.target.value)
        let totalCapacity = 0
        let dP = 0
        let dQ = 0
        let inputDetail = inputDetailTemp
        let totalCapacityUnit = getValues("totalCapacityUnit")
        
        //light
        let light = +getValues("light")
        let lightPF = +getValues("lightPF")
        let lightUnit = getValues("lightUnit")
        //soket
        let soket = +getValues("soket")
        let soketPF = +getValues("soketPF")
        let soketUnit = getValues("soketUnit")
        //FCU
        let FCU = +getValues("FCU")
        let FCUPF = +getValues("FCUPF")
        let FCUUnit = getValues("FCUUnit")
        //other
        let other = +getValues("other")
        let otherPF = +getValues("otherPF")
        let otherUnit = getValues("otherUnit")

        //check input data
        if(light < 0 || soket < 0 || FCU < 0 || other < 0 || lightPF < 0 || lightPF > 1 || soketPF < 0 || soketPF > 1
            || FCUPF < 0 || FCUPF > 1 || otherPF < 0 || otherPF > 1){
            // setIsDisableOKBtn(true)
        }
        else{
            if(light >= 0 && soket >= 0 && FCU >= 0 && other >= 0 && lightPF >= 0 && lightPF <= 1 && soketPF >= 0 && soketPF <= 1
            && FCUPF >= 0 && FCUPF <= 1 && otherPF >= 0 && otherPF <= 1){
            // setIsDisableOKBtn(false)
            }
            else{
                // setIsDisableOKBtn(true)
            }
        }
        //calc
        //light
        if(lightPF !== 0){
            if(lightUnit === "kW"){
                dP = light
                dQ = light * Math.sqrt(1 - lightPF * lightPF) / lightPF

            }
            else if(lightUnit === "kVA"){
                dP = light * lightPF
                dQ = light * Math.sqrt(1 - lightPF * lightPF)
            }
            else if(lightUnit === "HP"){
                dP = light / MS_KW_TO_HORSEPOWER
                dQ = (light / MS_KW_TO_HORSEPOWER) * Math.sqrt(1 - lightPF * lightPF) / lightPF
            }
        }
        else{
            if(lightUnit === "kW"){
                dP = 0
                dQ = light
            }else if( lightUnit === "kVA"){
                dP = 0
                dQ = light
            }
            else if(lightUnit === "HP"){
                dP = 0
                dQ = light / MS_KW_TO_HORSEPOWER
            }
        }
        //soket
        if(soketPF !==0 ){
            if(soketUnit === "kW"){
                dP += soket
                dQ += soket * Math.sqrt(1 - soketPF * soketPF) / soketPF
            }else if(soketUnit === "kVA"){
                dP += soket * soketPF
                dQ += soket * Math.sqrt(1 - soketPF * soketPF)
            }else if(soketUnit === "HP"){
                dP += soket / MS_KW_TO_HORSEPOWER
                dQ +=(soket / MS_KW_TO_HORSEPOWER) * Math.sqrt(1 - soketPF * soketPF) / soketPF
            }
        }
        else{
            if(soketUnit === "kW"){
                dQ += soket
            }else if(soketUnit === "kVA"){
                dQ += soket
            }
            else if(soketUnit === "HP"){
                dQ += soket / MS_KW_TO_HORSEPOWER
            }
        }
        //FCU
        if(FCUPF !==0 ){
            if(FCUUnit === "kW"){
                dP += FCU
                dQ += FCU * Math.sqrt(1 - FCUPF * FCUPF) / FCUPF
            }else if(FCUUnit === "kVA"){
                dP += FCU * FCUPF
                dQ += FCU * Math.sqrt(1 - FCUPF * FCUPF)
            }else if(FCUUnit === "HP"){
                dP += FCU / MS_KW_TO_HORSEPOWER
                dQ +=(FCU / MS_KW_TO_HORSEPOWER) * Math.sqrt(1 - FCUPF * FCUPF) / FCUPF
            }
        }
        else{
            if(FCUUnit === "kW"){
                dQ += FCU
            }else if(FCUUnit === "kVA"){
                dQ += FCU
            }
            else if(FCUUnit === "HP"){
                dQ += FCU / MS_KW_TO_HORSEPOWER
            }
        }
        //other
        if(otherPF !==0 ){
            if(otherUnit === "kW"){
                dP += other
                dQ += other * Math.sqrt(1 - otherPF * otherPF) / otherPF
            }else if(otherUnit === "kVA"){
                dP += other * otherPF
                dQ += other * Math.sqrt(1 - otherPF * otherPF)
            }else if(otherUnit === "HP"){
                dP += other / MS_KW_TO_HORSEPOWER
                dQ +=(other / MS_KW_TO_HORSEPOWER) * Math.sqrt(1 - otherPF * otherPF) / otherPF
            }
        }
        else{
            if(otherUnit === "kW"){
                dQ += other
            }else if(otherUnit === "kVA"){
                dQ += other
            }
            else if(otherUnit === "HP"){
                dQ += other / MS_KW_TO_HORSEPOWER
            }
        }

        totalCapacity = Math.sqrt(dP*dP + dQ*dQ)
        if(totalCapacity !== 0){
            totalCapacityPF = dP / totalCapacity
        }
        else{
            totalCapacityPF = 0
        }
        if(totalCapacityUnit === "kW"){
            totalCapacity = dP
        }else if(totalCapacityUnit === "kVA"){

        }
        else if(totalCapacityUnit === "HP"){
            totalCapacity = dP * MS_KW_TO_HORSEPOWER
        }
        if(!inputDetail){
            totalCapacity = +getValues("totalCapacity")
            totalCapacityPF = +getValues("powerFactor")
        }
        setValue("powerFactor",BeamsNumber(totalCapacityPF,5))//note
        return BeamsNumber(totalCapacity,6)  
    }
    const doGetTotalCapacity = (e:any,inputDetailTemp:boolean | undefined = undefined) =>{
        setValue(e.target.name,e.target.value)
        let totalCapacity = 0
        let totalCapacityPF = 0;
        let inputDetail = inputDetailTemp
        
        if(inputDetail === undefined){
            inputDetail = getValues("inputDetail")
        }
        if(inputDetail === true){
            totalCapacity = doGetValueTotalCapacity(e,totalCapacityPF)
        }else{
            totalCapacity = getValues("totalCapacity")
        }
        setValue("totalCapacity", BeamsNumber(+totalCapacity,6))
        return BeamsNumber(+totalCapacity,6)

    }
    const doGetTotalCapacityPF = (e:any,inputDetailTemp:boolean|undefined = undefined) =>{
        setValue(e.target.name,e.target.value)
        let totalCapacity = 0
        let totalCapacityPF = 0
        let inputDetail = inputDetailTemp
        if(inputDetail === undefined){
            inputDetail = getValues("inputDetail")
        }
        if(inputDetail === true){
            totalCapacity = doGetValueTotalCapacity(e,totalCapacityPF,inputDetail)
            setValue("totalCapacity",BeamsNumber(totalCapacity,6))
        }
        else {
            totalCapacityPF = getValues("powerFactor")
        }

        return BeamsNumber(+totalCapacityPF,5)

    }
    const doGetLoadCurrent = (e:any,m_bSPhase:boolean,inputDetailTemp:boolean | undefined = undefined) =>{
        setValue(e.target.name,e.target.value)
        let fullCurrent = 0
        let inputDetail = inputDetailTemp
        let voltage = getValues("voltage")
        let loadFactor = +getValues("loadFactor")
        let totalCapacityUnit = getValues("totalCapacityUnit")
        
        if(inputDetail === undefined){
            inputDetail = getValues("inputDetail")
        }
        
        if(voltage <= 0) {
            fullCurrent = 0
        }
        else{
            let totalCapacity = 0
            let totalCapacityPF = 0
            if(inputDetail === true){
                totalCapacity = doGetValueTotalCapacity(e,totalCapacityPF,inputDetail)
                totalCapacityPF = getValues("powerFactor")
                setValue("totalCapacity",BeamsNumber(totalCapacity,6))
            }
            else{
                totalCapacity = getValues("totalCapacity")
                totalCapacityPF = getValues("powerFactor")
            }
            let capacity = totalCapacity * 1000 * loadFactor
            if(totalCapacityPF === 0){
                if(totalCapacityUnit === "kW"){
                    fullCurrent = capacity / voltage
                }
                else if (totalCapacityUnit === "kVA"){
                    fullCurrent = capacity / voltage
                }
                else if(totalCapacityUnit === "HP"){
                    fullCurrent = capacity / voltage / MS_KW_TO_HORSEPOWER
                }
                if(!m_bSPhase){
                    fullCurrent /= Math.sqrt(3.0)
                }
            }
            else {
                if(totalCapacityUnit === "kW"){
                    fullCurrent = capacity / voltage / totalCapacityPF
                }
                else if( totalCapacityUnit === "kVA"){
                    fullCurrent = capacity / voltage
                }
                else if (totalCapacityUnit === "HP"){
                    fullCurrent = capacity / voltage / totalCapacityPF /MS_KW_TO_HORSEPOWER
                }
                if(!m_bSPhase){
                    fullCurrent /= Math.sqrt(3.0)
                }
            }
        }
        return BeamsNumber(+fullCurrent,4)
    }
    const doGetPhaseCurrent = (bIsLead:boolean,inputDetailTemp:boolean|undefined= undefined) =>{
        
        let powerFactor = 0
        let voltage  = getValues("voltage")
        let totalCapacity  = getValues("totalCapacity")
        let inputDetail  = inputDetailTemp
        if(voltage <=0 || voltage > 600 || totalCapacity < 0){
            // setIsDisableOKBtn(true)
        }
        else{
            if(voltage > 0 && voltage <= 600 && totalCapacity >=0){
            // setIsDisableOKBtn(false)
                
            }else{
            // setIsDisableOKBtn(true)

            }
        }
        if(inputDetail === undefined){
            inputDetail = getValues("inputDetail")
        }
        if(voltage <=0){
            powerFactor = 0
        }
        else{
             if(inputDetail === true){
                // doGetValueTotalCapacity(e,powerFactor,inputDetail)
                powerFactor = getValues("powerFactor")
             }
             else{
                powerFactor = getValues("powerFactor")
             }
        }
        if(powerFactor < -1 || powerFactor > 1){
            return 0
        }
        let phaseCurrent = BeamsNumber(Math.acos(powerFactor) * 180/ MS_PI,4)
        if(!bIsLead){
            phaseCurrent = (-1) * phaseCurrent
        }
        else{
            phaseCurrent = 1 * phaseCurrent
        }
        return phaseCurrent

    }
    const doSetValuesLightBoard =(e:any,nControl:string,inputDetailTemp:boolean | undefined = undefined) =>{
        setValue(e.target.name,e.target.value,{shouldValidate: true})
        let m_bSPhase = getValues("sPhase")
        let bIsLead = getValues("isLead")
        let phaseCurrent =""
        if(nControl === "IDC_TOTAL_CAPACITY"){
            setValue("totalCapacity1",e.target.value)
        }
        if(nControl === "IDC_POWER_FACTOR"){
            setValue("powerFactor1",e.target.value)
        }
        if(nControl !== "IDC_TOTAL_CAPACITY"){
            let totalCapacity = doGetTotalCapacity(e,inputDetailTemp)
            setValue("totalCapacity",totalCapacity)
        }
        if(nControl !== "IDC_POWER_FACTOR"){
            let totalCapacityPF = doGetTotalCapacityPF(e,inputDetailTemp)
            setValue("powerFactor",totalCapacityPF)

        }
        let fullCurrent = doGetLoadCurrent(e,m_bSPhase,inputDetailTemp)
        let powerFactor = getValues("powerFactor")
        if(powerFactor >= 0 && powerFactor <=1){
            phaseCurrent = doGetPhaseCurrent(bIsLead,inputDetailTemp).toString()
        }
        else {
            phaseCurrent = ""
        }
        setValue("fullCurrent",fullCurrent)
        setValue("phaseCurrent",phaseCurrent);
         // temp variable
        setValue("fullCurrent1",BeamsNumber(fullCurrent,4))
        setValue("phaseCurrent1",phaseCurrent)
    }
    const handleLoadFactor = (e:any) =>{
        setValue(e.target.name,e.target.value,{shouldValidate:true})
        let loadFactor = +e.target.value
        if(loadFactor > 1 || loadFactor < 0){
            // setIsDisableOKBtn(true)
        }else{
            if(loadFactor <=1 && loadFactor >=0){
                // setIsDisableOKBtn(false)
            }else{
                // setIsDisableOKBtn(true)
            }
        }
    }

    const doSetTotalCapacityUnit =(value:any)=>{
        let totalCapacityUnitList =[] as any
        initialValue.totalCapacityUnitList.forEach(a =>{
           totalCapacityUnitList.push(a.value)
        })
        if(totalCapacityUnitList.includes(value)){
            return true
        }
        else{

        return false
        }
    }
    

    return (
        <>
            <div style={{ height: '100%', width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={selectTab} onChange={handleChange}
                        variant="scrollable" 
                        scrollButtons 
                        allowScrollButtonsMobile 
                        sx={{
                            '& .MuiButtonBase-root.MuiTabScrollButton-root.Mui-disabled': {
                                display: "none"
                            }
                        }}
                    >
                        <Tab label="基本" {...a11yProps(0)} />
                    </Tabs>
                </Box>
                <form onSubmit={handleSubmit(handleEditSubmit)}>
                    <div style={{ height: "40vh", overflow: "auto", width: '100%', margin: 'auto' }}>
                        <TabPanel value={selectTab} index={0}>
                            <Stack mt={1}>
                                <Grid container>
                                    <Grid item xs={7}>
                                        <Stack spacing={0.3}>
                                            <Stack flexDirection='row' alignItems='center'>
                                                <Typography variant='body2' width='55%'>盤名称:</Typography>
                                                <Controller
                                                    name="refNo"
                                                    control={control}
                                                    rules={{required: '必須項目です。入力してください。'}}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type="text"
                                                            error={!!errors?.refNo}
                                                            helperText={errors?.refNo?.message}
                                                            inputProps={{readOnly:modeViewOnly}}
                                                            InputProps={{ sx: { height: 32 ,backgroundColor:m_bModePM || modeViewOnly?'floralwhite':'white', color:modeViewOnly?"grey":"black"} }}
                                                            disabled={m_bModePM}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack flexDirection='row' alignItems='center'>
                                                <Typography variant='body2' width='55%'>回路電圧(V):</Typography>
                                                <FormControl fullWidth>
                                                    <Controller
                                                        name="voltage"
                                                        control={control}
                                                        rules={{ required: '必須項目です。入力してください。',
                                                        pattern:{
                                                            value: /^[0-9\b]+$/,
                                                            message: '数字を入力してください。'
                                                        },
                                                        validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                        // maxLength: {
                                                        //     value: 3,
                                                        //     message: "最大長さ <= 3 を入力してください。"
                                                        // }
                                                    }}
                                                        render={({ field }) => (
                                                            <TextFieldOptions
                                                                {...field}
                                                                options={initialValue.voltageList}
                                                                setValue={(name: any, value: any) => {                                                               
                                                                    doSetValuesLightBoard({target: {name: name, value: value}},"")
                                                                }}
                                                                height= {32}
                                                                maxLength={3}
                                                                error={!!errors?.voltage}
                                                                helperText={errors?.voltage?.message}
                                                                disabled={getValues("isSourceVoltage")||m_bModePM|| processMode > MS_MODE_CHECKING || modeViewOnly}
                                                            />
                                                        )}
                                                    />
                                                </FormControl>
                                            </Stack>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={5} pl={3}>
                                        {/* Mode SP */}
                                        {(userRole && !(userRole == Constant.ROLE_LP)) &&
                                        <Stack>
                                            <Controller
                                                name="viewFlowResult"
                                                control={control}
                                                render={({ field }: any) => (
                                                    <FormControlLabel
                                                        {...field}
                                                        control={<Checkbox size="small" style={checkboxStyle}/>}
                                                        name='viewFlowResult'
                                                        checked={field.value} 
                                                        label={
                                                            <Typography variant='body2' color={(setIsConnectTrans1OrTranscott||m_bModePM)?'grey':'black'} >
                                                                潮流解析結果を表示
                                                            </Typography>
                                                        }
                                                        disabled={setIsConnectTrans1OrTranscott||m_bModePM || modeViewOnly}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                        }
                                        
                                        <Stack>
                                            <Controller
                                                name="viewResultText"
                                                control={control}
                                                render={({ field }: any) => (
                                                    <FormControlLabel 
                                                        {...field} 
                                                        control={<Checkbox size="small" style={checkboxStyle}/>} 
                                                        name='viewResultText'
                                                        checked={field.value} 
                                                        label={
                                                            <Typography variant='body2' color={m_bModePM?'grey':'black'}>
                                                                注釈を表示
                                                            </Typography>
                                                        }
                                                        disabled={m_bModePM || modeViewOnly}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                    </Grid>
                                </Grid>
                                
                                <Grid container my={0.3} width={'70%'}>
                                    <Grid item xs={7}>
                                        <Stack flexDirection='row' alignItems='center'>
                                            <Typography variant='body2' width='104%'>負荷容量(kW):</Typography>
                                            <Controller
                                                name="totalCapacity"
                                                control={control}
                                                rules={{ 
                                                    pattern:{
                                                        value: /^[0-9]*[.]?[0-9]+$/,
                                                        message: '数字を入力してください。'
                                                    },
                                                    validate: value => value >= 0 || '数字 >= 0 を入力してください。' ,
                                                    // maxLength: {
                                                    //     value: 6,
                                                    //     message: "最大長さ <= 6 を入力してください。"
                                                    // }
                                                }}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        // type="text"
                                                        style={{
                                                            background: handleChangeColorRevert(),
                                                            
                                                        }}
                                                        InputProps={{ sx: { height: 32,color:(getValues("inputDetail")||m_bModePM|| processMode > MS_MODE_CHECKING) || modeViewOnly?'grey':'black'  } }}
                                                        onChange={(e) =>{
                                                            field.onChange(e);
                                                            doSetValuesLightBoard(e,"IDC_TOTAL_CAPACITY")}}
                                                        error={!!errors?.totalCapacity}
                                                        helperText={errors?.totalCapacity?.message}
                                                        inputProps={{maxLength:6,readOnly:modeViewOnly}}
                                                        disabled={m_bModePM|| processMode > MS_MODE_CHECKING}
                                                        
                                                    />
                                                )}
                                            />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={5} pl={1}>
                                        <Stack flexDirection='row' alignItems='center'>
                                            <Typography variant='body2' width='40%'>{'(単位'}:</Typography>
                                            <FormControl size="small" fullWidth>
                                                <Controller
                                                    name="totalCapacityUnit"
                                                    control={control}
                                                    rules={
                                                        {
                                                            validate: value => doSetTotalCapacityUnit(value) === true || '単位が存在しません。'
                                                        }
                                                    }
                                                    render={({ field }) => (
                                                        <TextFieldOptions
                                                            {...field}
                                                            options={initialValue.totalCapacityUnitList}
                                                            onChange= {handleChangeFormat1}
                                                            size="small"
                                                            setValue={(name: any, value: any) => {
                                                                field.onChange({target: {name: name, value: value}});
                                                                // doGetTotalCapacity({target: {name: name, value: value}},false)//bIsLead
                                                                doSetValuesLightBoard({target: {name: name, value: value}},"");
                                                            }}
                                                            height= {32}
                                                            maxLength={5}
                                                            error={!!errors?.totalCapacityUnit}
                                                            helperText={errors?.totalCapacityUnit?.message}
                                                            disabled={m_bModePM|| processMode > MS_MODE_CHECKING || modeViewOnly}
                                                           
                                                        />
                                                    )}
                                                />
                                            </FormControl>
                                            <Typography>{')'}</Typography>

                                        </Stack>
                                    </Grid>
                                </Grid>

                                <Stack spacing={0.3} width={'58.3%'}>
                                    <Stack flexDirection='row' alignItems='center'>
                                        <Typography variant='body2' width='55%'>負荷力率(遅れ):</Typography>
                                        <Controller
                                            name="powerFactor"
                                            control={control}
                                            rules={{ 
                                                pattern:{
                                                    value: /^[0-9]*[.]?[0-9]+$/,
                                                    message: '数字を入力してください。'
                                                },
                                                validate: value => value > 0 && value <= 1 || '数字 0 ~ 1 を入力してください。' ,
                                                // maxLength: {
                                                //     value: 5,
                                                //     message: "最大長さ <= 5 を入力してください。"
                                                // }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    disabled={!isDisabledEditValue||m_bModePM|| processMode > MS_MODE_CHECKING}
                                                    fullWidth
                                                    // type="text"
                                                    error={!!errors?.powerFactor}
                                                    style={{
                                                        background: handleChangeColorRevert()
                                                    }}
                                                    InputProps={{ sx: { height: 32, color:modeViewOnly?"grey":"black" } }}
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                        doSetValuesLightBoard(e,"IDC_POWER_FACTOR")}}
                                                    inputProps={{maxLength:5,readOnly:modeViewOnly}}
                                                    helperText={errors?.powerFactor?.message}
                                                    
                                                />
                                                
                                            )}
                                        />
                                    </Stack>
                                    <Stack flexDirection='row' alignItems='center'>
                                        <Typography variant='body2' width='55%'>負荷電流(A):</Typography>
                                        <Controller
                                            name="fullCurrent"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    type="number"
                                                    inputProps={{ readOnly: true }}
                                                    style={{ backgroundColor: 'floralwhite' }}
                                                    InputProps={{ sx: { height: 32,color:'grey',backgroundColor: 'floralwhite'} }}
                                                />
                                            )}
                                        />
                                    </Stack>
                                    <Stack flexDirection='row' alignItems='center'>
                                        <Typography variant='body2' width='55%'>負荷電流位相(ﾟ ):</Typography>
                                        <Controller
                                            name="phaseCurrent"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    inputProps={{ readOnly: true }}
                                                    style={{ backgroundColor: 'floralwhite' }}
                                                    InputProps={{ sx: { height: 32,color:'grey',backgroundColor: 'floralwhite' } }}
                                                />
                                            )}
                                        />
                                    </Stack>
                                    <Stack flexDirection='row' alignItems='center'>
                                        <Typography variant='body2' width='55%'>負荷率:</Typography>
                                        <Controller
                                            name="loadFactor"
                                            control={control}
                                            rules={{ 
                                                pattern:{
                                                    value: /^[0-9]*[.]?[0-9]+$/,
                                                    message: '数字を入力してください。'
                                                },
                                                validate: value => value >= 0 && value <= 1 || '数字 0 ~ 1 を入力してください。' ,
                                                // maxLength: {
                                                //     value: 5,
                                                //     message: "最大長さ <= 5 を入力してください。"
                                                // }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    // type="text"
                                                    InputProps={{ sx: { height: 32 ,backgroundColor:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white', color:modeViewOnly?"grey":"black"} }}
                                                    onChange={(e)=>{field.onChange(e);handleLoadFactor(e)}}
                                                    inputProps={{maxLength:5,readOnly:modeViewOnly}}
                                                    error={!!errors?.loadFactor}
                                                    helperText={errors?.loadFactor?.message}
                                                    disabled={m_bModePM|| processMode > MS_MODE_CHECKING}
                                                />
                                            )}
                                        />
                                    </Stack>
                                </Stack>
                            </Stack>
                            
                            <Stack mt={1}>
                                <Stack>
                                    <Controller
                                        name="inputDetail"
                                        control={control}
                                        render={({ field }: any) => (
                                            <FormControlLabel
                                                {...field}
                                                control={
                                                    <Checkbox
                                                        name="inputDetail"
                                                        onClick={handleChangeFormat}
                                                        checked={field.value}
                                                        style={{padding:'4px 4px 4px 9px'}}
                                                        size="small"
                                                        disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}
                                                    />}
                                                label={<Typography variant='body2' color={m_bModePM|| processMode > MS_MODE_CHECKING?'grey':'black'}>負荷の詳細を入力</Typography>}
                                            />
                                        )}
                                    />
                                </Stack>
                                <fieldset style={{border:'1px solid #AAAA'}}>
                                    <legend><Typography variant='body2'>負荷の種類と容量・力率</Typography></legend>
                                    <Grid container>
                                        <Grid item xs={5}>
                                            <Stack spacing={0.3}>
                                                <Stack flexDirection='row' alignItems='center'>
                                                    <Typography variant='body2' width='75%'>照明:</Typography>
                                                    <Controller
                                                        name="light"
                                                        control={control}
                                                        rules={{ 
                                                            pattern:{
                                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                                message: '数字を入力してください。'
                                                            },
                                                            validate: value => value >= 0 || '数字 >= 0 を入力してください。' ,
                                                            // maxLength: {
                                                            //     value: 6,
                                                            //     message: "最大長さ <= 6 を入力してください。"
                                                            // }
                                                        }}
                                                        render={
                                                            ({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    // type="text"
                                                                    style={
                                                                        { backgroundColor: handleChangeColor() }
                                                                    }
                                                                    inputProps={{ readOnly: isDisabledEditValue || modeViewOnly,maxLength:6,} }
                                                                    InputProps={{ sx: { height: 32,color:isDisabledEditValue || modeViewOnly?'grey':'black' } }}
                                                                    onChange={(e)=>{field.onChange();doSetValuesLightBoard(e,"")}}
                                                                    error={!!errors?.light}
                                                                    helperText={errors?.light?.message}
                                                                    disabled={m_bModePM|| processMode > MS_MODE_CHECKING}
                                                                />
                                                            )
                                                        }
                                                    />
                                                </Stack>
                                                <Stack flexDirection='row' alignItems='center'>
                                                    <Typography variant='body2' width='75%'>コンセント:</Typography>
                                                    <Controller
                                                        name="soket"
                                                        control={control}
                                                        rules={{ 
                                                            pattern:{
                                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                                message: '数字を入力してください。'
                                                            },
                                                            validate: value => value >= 0 || '数字 >= 0 を入力してください。' ,
                                                            // maxLength: {
                                                            //     value: 6,
                                                            //     message: "最大長さ <= 6 を入力してください。"
                                                            // }
                                                        }}
                                                        render={
                                                            ({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    // type="text"
                                                                    style={
                                                                        { backgroundColor: handleChangeColor() }
                                                                    }
                                                                    inputProps={{ readOnly: isDisabledEditValue || modeViewOnly,maxLength:6 }}
                                                                    InputProps={{ sx: { height: 32,color:isDisabledEditValue|| processMode > MS_MODE_CHECKING || modeViewOnly?'grey':'black' } }}
                                                                    onChange={(e)=>{field.onChange();doSetValuesLightBoard(e,"")}}
                                                                    error={!!errors?.soket}
                                                                    helperText={errors?.soket?.message}
                                                                    disabled={m_bModePM|| processMode > MS_MODE_CHECKING}
                                                                />
                                                            )
                                                        }
                                                    />
                                                </Stack>
                                                <Stack flexDirection='row' alignItems='center'>
                                                    <Typography variant='body2' width='75%'>FCU:</Typography>
                                                    <Controller
                                                        name="FCU"
                                                        control={control}
                                                        rules={{ 
                                                            pattern:{
                                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                                message: '数字を入力してください。'
                                                            },
                                                            validate: value => value >= 0 || '数字 >= 0 を入力してください。' ,
                                                            // maxLength: {
                                                            //     value: 6,
                                                            //     message: "最大長さ <= 6 を入力してください。"
                                                            // }
                                                        }}
                                                        render={
                                                            ({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    // type="text"
                                                                    style={
                                                                        { backgroundColor: handleChangeColor() }
                                                                    }
                                                                    inputProps={{ readOnly: isDisabledEditValue || modeViewOnly,maxLength:6 }}
                                                                    InputProps={{ sx: { height: 32,color:isDisabledEditValue|| processMode > MS_MODE_CHECKING || modeViewOnly?'grey':'black' } }}
                                                                    onChange={(e)=>{field.onChange();doSetValuesLightBoard(e,"")}}
                                                                    error={!!errors?.FCU}
                                                                    helperText={errors?.FCU?.message}
                                                                    disabled={m_bModePM|| processMode > MS_MODE_CHECKING}
                                                                />
                                                            )
                                                        }
                                                    />
                                                </Stack>
                                                <Stack flexDirection='row' alignItems='center'>
                                                    <Typography variant='body2' width='75%'>その他:</Typography>
                                                    <Controller
                                                        name="other"
                                                        control={control}
                                                        rules={{ 
                                                            pattern:{
                                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                                message: '数字を入力してください。'
                                                            },
                                                            validate: value => value >= 0 || '数字 >= 0 を入力してください。' ,
                                                            // maxLength: {
                                                            //     value: 6,
                                                            //     message: "最大長さ <= 6 を入力してください。"
                                                            // }
                                                        }}
                                                        render={
                                                            ({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    // type="text"
                                                                    style={
                                                                        { backgroundColor: handleChangeColor() }
                                                                    }
                                                                    inputProps={{ readOnly: isDisabledEditValue || modeViewOnly,maxLength:6} }
                                                                    InputProps={{ sx: { height: 32,color:isDisabledEditValue|| processMode > MS_MODE_CHECKING || modeViewOnly?'grey':'black' } }}
                                                                    onChange={(e)=>{field.onChange();doSetValuesLightBoard(e,"")}}
                                                                    error={!!errors?.other}
                                                                    helperText={errors?.other?.message}
                                                                    disabled={m_bModePM|| processMode > MS_MODE_CHECKING}
                                                                />
                                                            )
                                                        }
                                                    />
                                                </Stack>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={3.5} pl={0.5}>
                                            <Stack spacing={0.3}>
                                                <Stack flexDirection='row' alignItems='center'>
                                                    <Typography variant='body2' width='75%'>{'(単位:'}</Typography>
                                                    <Controller
                                                        name="lightUnit"
                                                        control={control}
                                                        rules={
                                                            {
                                                                validate: value => doSetTotalCapacityUnit(value) === true || '単位が存在しません。'
                                                            }
                                                        }
                                                        render={({ field }: any) => (
                                                            <TextFieldOptions
                                                                {...field}
                                                                options={initialValue.totalCapacityUnitList}
                                                                onChange= {handleChangeFormat1}
                                                                disabled={isDisabledEditValue||m_bModePM|| processMode > MS_MODE_CHECKING || modeViewOnly}
                                                                setValue={(name: any, value: any) => {
                                                                    doSetValuesLightBoard({target:{name:name,value:value}},"")
                                                                }}
                                                                height= {32}
                                                                maxLength={5}
                                                                error={!!errors?.lightUnit}
                                                                helperText={errors?.lightUnit?.message}
                                                            />
                                                        )}
                                                    />
                                                    <Typography>{')'}</Typography>
                                                </Stack>
                                                <Stack flexDirection='row' alignItems='center'>
                                                    <Typography variant='body2' width='75%'>{'(単位:'}</Typography>
                                                    <Controller
                                                        name="soketUnit"
                                                        control={control}
                                                        rules={
                                                            {
                                                                validate: value => doSetTotalCapacityUnit(value) === true || '単位が存在しません。'
                                                            }
                                                        }
                                                        render={({ field }: any) => (
                                                            <TextFieldOptions
                                                                {...field}
                                                                options={initialValue.totalCapacityUnitList}
                                                                onChange= {handleChangeFormat1}
                                                                disabled={isDisabledEditValue||m_bModePM|| processMode > MS_MODE_CHECKING || modeViewOnly}
                                                                setValue={(name: any, value: any) => {
                                                                    doSetValuesLightBoard({target:{name:name,value:value}},"")
                                                                }}
                                                                height= {32}
                                                            maxLength={5}
                                                            error={!!errors?.soketUnit}
                                                                helperText={errors?.soketUnit?.message}

                                                            />
                                                        )}
                                                    />
                                                    <Typography>{')'}</Typography>
                                                </Stack>
                                                <Stack flexDirection='row' alignItems='center'>
                                                    <Typography variant='body2' width='75%'>{'(単位:'}</Typography>
                                                    <Controller
                                                        name="FCUUnit"
                                                        control={control}
                                                        rules={
                                                            {
                                                                validate: value => doSetTotalCapacityUnit(value) === true || '単位が存在しません。'
                                                            }
                                                        }
                                                        render={({ field }: any) => (
                                                            <TextFieldOptions
                                                                {...field}
                                                                options={initialValue.totalCapacityUnitList}
                                                                onChange= {handleChangeFormat1}
                                                                disabled={isDisabledEditValue||m_bModePM|| processMode > MS_MODE_CHECKING || modeViewOnly}
                                                                setValue={(name: any, value: any) => {
                                                                    doSetValuesLightBoard({target:{name:name,value:value}},"")
                                                                }}
                                                                height= {32}
                                                            maxLength={5}
                                                            error={!!errors?.FCUUnit}
                                                            helperText={errors?.FCUUnit?.message}
                                                            />
                                                        )}
                                                    />
                                                    <Typography>{')'}</Typography>
                                                </Stack>
                                                <Stack flexDirection='row' alignItems='center'>
                                                    <Typography variant='body2' width='75%'>{'(単位:'}</Typography>
                                                    <Controller
                                                        name="otherUnit"
                                                        control={control}
                                                        rules={
                                                            {
                                                                validate: value => doSetTotalCapacityUnit(value) === true || '単位が存在しません。'
                                                            }
                                                        }
                                                        render={({ field }) => (
                                                            <TextFieldOptions
                                                                {...field}
                                                                disabled={isDisabledEditValue||m_bModePM|| processMode > MS_MODE_CHECKING || modeViewOnly}
                                                                options={initialValue.totalCapacityUnitList}
                                                                setValue={(name: any, value: any) => {
                                                                    doSetValuesLightBoard({target: {name: name, value: value}},"")
                                                                }}
                                                                height= {32}
                                                            maxLength={5}
                                                            error={!!errors?.otherUnit}
                                                            helperText={errors?.otherUnit?.message}
                                                            />
                                                        )}
                                                    />
                                                    <Typography>{')'}</Typography>
                                                </Stack>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={3.5} pl={1.5}>
                                            <Stack spacing={0.3}>
                                                <Stack flexDirection='row' alignItems='center'>
                                                    <Typography variant='body2' width='75%'>力率:</Typography>
                                                    <Controller
                                                        name="lightPF"
                                                        control={control}
                                                        rules={{ 
                                                            pattern:{
                                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                                message: '数字を入力してください。'
                                                            },
                                                            validate: value => value > 0 && value <= 1 || '0 < 数字 <= 1 を入力してください。' ,
                                                            // maxLength: {
                                                            //     value: 5,
                                                            //     message: "最大長さ <= 5 を入力してください。"
                                                            // }
                                                        }}
                                                        render={
                                                            ({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    type="text"
                                                                    style={
                                                                        { backgroundColor: handleChangeColor() }
                                                                    }
                                                                    inputProps={{ readOnly: isDisabledEditValue || modeViewOnly,maxLength:5  }}
                                                                    InputProps={{ sx: { height: 32,color:isDisabledEditValue|| processMode > MS_MODE_CHECKING || modeViewOnly?'grey':'black'  } }}
                                                                    onChange={(e) =>{doSetValuesLightBoard(e,"")}}
                                                                    error={!!errors?.lightPF}
                                                                    helperText={errors?.lightPF?.message}
                                                                    disabled={m_bModePM|| processMode > MS_MODE_CHECKING}
                                                                />
                                                            )
                                                        }
                                                    />
                                                </Stack>
                                                <Stack flexDirection='row' alignItems='center'>
                                                    <Typography variant='body2' width='75%'>力率:</Typography>
                                                    <Controller
                                                        name="soketPF"
                                                        control={control}
                                                        rules={{ 
                                                            pattern:{
                                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                                message: '数字を入力してください。'
                                                            },
                                                            validate: value => value > 0 && value <= 1 || '0 < 数字 <= 1 を入力してください。' ,
                                                            // maxLength: {
                                                            //     value: 5,
                                                            //     message: "最大長さ <= 5 を入力してください。"
                                                            // }
                                                        }}
                                                        render={
                                                            ({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    type="text"
                                                                    style={
                                                                        { backgroundColor: handleChangeColor() }
                                                                    }
                                                                    inputProps={{ readOnly: isDisabledEditValue || modeViewOnly,maxLength:5} }
                                                                    InputProps={{ sx: { height: 32 ,color:isDisabledEditValue|| processMode > MS_MODE_CHECKING || modeViewOnly?'grey':'black' } }}
                                                                    onChange={(e) =>{doSetValuesLightBoard(e,"")}}
                                                                    error={!!errors?.soketPF}
                                                                    helperText={errors?.soketPF?.message}
                                                                    disabled={m_bModePM|| processMode > MS_MODE_CHECKING}
                                                                />
                                                            )
                                                        }
                                                    />
                                                </Stack>
                                                <Stack flexDirection='row' alignItems='center'>
                                                    <Typography variant='body2' width='75%'>力率:</Typography>
                                                    <Controller
                                                        name="FCUPF"
                                                        control={control}
                                                        rules={{ 
                                                            pattern:{
                                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                                message: '数字を入力してください。'
                                                            },
                                                            validate: value => value > 0 && value <= 1 || '0 < 数字 <= 1 を入力してください。' ,
                                                            // maxLength: {
                                                            //     value: 5,
                                                            //     message: "最大長さ <= 5 を入力してください。"
                                                            // }
                                                        }}
                                                        render={
                                                            ({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    type="text"
                                                                    style={
                                                                        { backgroundColor: handleChangeColor() }
                                                                    }
                                                                    inputProps={{ readOnly: isDisabledEditValue || modeViewOnly,maxLength:5 }}
                                                                    InputProps={{ sx: { height: 32 ,color:isDisabledEditValue|| processMode > MS_MODE_CHECKING || modeViewOnly?'grey':'black' } }}
                                                                    onChange={(e)=>{doSetValuesLightBoard(e,"")}}
                                                                    error={!!errors?.FCUPF}
                                                                    helperText={errors?.FCUPF?.message}
                                                                    disabled={m_bModePM|| processMode > MS_MODE_CHECKING}

                                                                />
                                                            )
                                                        }
                                                    />
                                                </Stack>
                                                <Stack flexDirection='row' alignItems='center'>
                                                    <Typography variant='body2' width='75%'>力率:</Typography>
                                                    <Controller
                                                        name="otherPF"
                                                        control={control}
                                                        rules={{ 
                                                            pattern:{
                                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                                message: '数字を入力してください。'
                                                            },
                                                            validate: value => value > 0 && value <= 1 || '0 < 数字 <= 1 を入力してください。' ,
                                                            // maxLength: {
                                                            //     value: 5,
                                                            //     message: "最大長さ <= 5 を入力してください。"
                                                            // }
                                                        }}
                                                        render={
                                                            ({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    type="text"
                                                                    style={
                                                                        { backgroundColor: handleChangeColor() }
                                                                    }
                                                                    inputProps={{ readOnly: isDisabledEditValue || modeViewOnly,maxLength:5}}
                                                                    InputProps={{ sx: { height: 32 ,color:isDisabledEditValue|| processMode > MS_MODE_CHECKING || modeViewOnly?'grey':'black' } }}
                                                                    onChange={(e)=>{doSetValuesLightBoard(e,"")}}
                                                                    error={!!errors?.otherPF}
                                                                    helperText={errors?.otherPF?.message}
                                                                    disabled={m_bModePM|| processMode > MS_MODE_CHECKING}

                                                                />
                                                            )
                                                        }
                                                    />
                                                </Stack>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </fieldset>
                            </Stack>

                            {/* ModePM */}
                            {[Constant.ROLE_PM, Constant.ROLE_ADMIN].includes(userRole) &&
                            <>
                                <Stack mt={1}>
                                    <FormControlLabel
                                        control={
                                            <Controller
                                                name='makeDataInput'
                                                control={control}
                                                render={({ field }) => (
                                                    <Checkbox
                                                        size='small'
                                                        {...field}
                                                        checked={field.value}
                                                        onChange={handleChangeMakeDataInput}
                                                        style={{padding:'4px 4px 4px 9px'}}
                                                        disabled={m_bModePM || modeViewOnly}
                                                    />
                                                )}
                                            />
                                        }
                                        label={<Typography variant="body2" color={m_bModePM?'grey':'black'} >銘板入力</Typography>}
                                    />
                                </Stack>
                                <Stack>
                                    <fieldset
                                        style={{ 
                                            width: '70%',
                                            color: !makeDataInput ? 'grey' : 'black', 
                                            border:'1px solid #AAAA'
                                        }}
                                    >
                                        <legend> <Typography variant="body2"  >銘板</Typography></legend>
                                        <Stack spacing={0.3}>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="35%"  >製造者:</Typography>
                                                <FormControl fullWidth size='small'>
                                                    <Controller
                                                        name='makerName'
                                                        control={control}
                                                        render={({ field }: any) => (
                                                            <TextFieldOptions
                                                                {...field}
                                                                options={initialValue.makerNameList}
                                                                type='string'
                                                                size='small'
                                                                setValue={(name: any, value: any) => {
                                                                    handleChangeFormat({target: {name: name, value: value}})
                                                                }}
                                                                height= {32}
                                                                disabled={!makeDataInput||m_bModePM || modeViewOnly}
                                                            />
                                                        )}
                                                    />
                                                </FormControl>
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="35%"  >形式:</Typography>
                                                <Controller
                                                    name='type'
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type='text'
                                                            size='small'
                                                            onChange={handleChangeFormat}
                                                            inputProps={{
                                                                readOnly: !makeDataInput || modeViewOnly,
                                                                style: { color: makeDataInput? 'black' : 'grey' },
                                                            }}
                                                            style={{
                                                                color: 'black',
                                                            }}
                                                            InputProps={{ sx: { height: 32, backgroundColor: m_bModePM || modeViewOnly?'floralwhite':makeDataInput ? 'white' : 'floralwhite', color:modeViewOnly?"grey":makeDataInput? 'black' : 'grey'} }}
                                                            disabled={m_bModePM}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="35%"  >製造年月:</Typography>
                                                <Grid container>
                                                    <Grid item xs={7}>
                                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                            <FormControl fullWidth size='small'>
                                                                <Controller
                                                                    name='makeYear'
                                                                    control={control}
                                                                    render={({ field }: any) => (
                                                                        <TextFieldOptions
                                                                            {...field}
                                                                            options={initialValue.makeYearList}
                                                                            type='string'
                                                                            size='small'
                                                                            setValue={(name: any, value: any) => {
                                                                                handleChangeFormat({target: {name: name, value: value}})
                                                                            }}
                                                                            height= {32}
                                                                            maxLength={8}
                                                                    disabled={!makeDataInput||m_bModePM || modeViewOnly}

                                                                        />
                                                                    )}
                                                                />
                                                            </FormControl>
                                                            <Typography variant="body2" pr={1.5} pl={0.5}>年</Typography>
                                                        </Stack>
                                                        
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                            <FormControl fullWidth size='small'>
                                                                <Controller
                                                                    name='makeMonth'
                                                                    control={control}
                                                                    render={({ field }: any) => (
                                                                        <TextFieldOptions
                                                                            {...field}
                                                                            options={initialValue.makeMonthList}
                                                                            type='string'
                                                                            size='small'
                                                                            setValue={(name: any, value: any) => {
                                                                                handleChangeFormat({target: {name: name, value: value}})
                                                                            }}
                                                                            height= {32}
                                                                            maxLength={2}
                                                                            disabled={!makeDataInput||m_bModePM|| modeViewOnly}
                                                                        />
                                                                    )}
                                                                />
                                                            </FormControl>
                                                            <Typography variant="body2" pl={0.5}>月</Typography>
                                                        </Stack>
                                                    </Grid>
                                                </Grid>
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="35%"  >製造番号:</Typography>
                                                <Controller
                                                    name='makeNumber'
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type='text'
                                                            size='small'
                                                            onChange={handleChangeFormat}
                                                            inputProps={{
                                                                readOnly: !makeDataInput || modeViewOnly,
                                                                style: { color: makeDataInput ? 'black' : 'grey' },
                                                            }}
                                                            style={{
                                                                color: 'black',
                                                            }}
                                                            InputProps={{ sx: { height: 32, backgroundColor: m_bModePM || modeViewOnly?'floralwhite':makeDataInput ? 'white' : 'floralwhite', color:modeViewOnly? "grey": makeDataInput ? 'black' : 'grey'  } }}
                                                            disabled={m_bModePM}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                        </Stack>
                                    </fieldset>
                                </Stack>
                            </>
                            }
                        </TabPanel>
                    </div>
                    <Stack direction='row' justifyContent="flex-end" spacing={2} mt={2}>
                        <Button variant="contained" type="submit" disabled={modeViewOnly}>OK</Button>
                        <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                    </Stack>
                </form >
            </div>
        </>
    );
};
export default MsLightBoardEditDialog;


