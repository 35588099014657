import { CALC_IK2, CALC_IK2_OUTSIDE_NEUTRAL, CALC_IK2_OUTSIDE_OUTSIDE, CALC_IK2_SEAT_MAIN, CALC_IK2_SEAT_T, CALC_IK3, CALC_PER_Z_TYPE_DIRECT, CALC_PER_Z_TYPE_DIRECT_MG, CALC_PER_Z_TYPE_MOTOR, CALC_PER_Z_TYPE_TOTAL, CALC_PER_Z_TYPE_TOTAL_MG, DIRECT_NONE, LEFT_CENTER, TOP_CENTER } from "../models/Constants";
import { CalcPoint, Iele3WindingPart } from "../models/Index";
import { MS_MODE_CALCULATION, MS_MODE_CHECKING, MS_MODE_DRAWING, MS_MODE_HARMONIC, MS_MODE_PERCENT_Z, MS_MODE_POWER_FLOW, MS_MODE_VOLTDROP } from "../statics";
import { printPercentZ } from "./ElementFunction";
import { BeamsNumber } from "./FormatNumber";
import { percentZDefault } from "./PercentZ";
import { doConvertDataCalcPoint, doSetVoltage,
    doGetIkpeakDirectMG,
    doGetIkasymDirectMG,
    doGetIksymDirectMG,
    doGetIksymTotalMG,
    doGetTextDropVoltRegular as doGetTextDropVoltRegularCalcPoint,
    doGetTextDropPerRegular2 as doGetTextDropPerRegular2CalcPoint
} from "./calcPointFunction";

export const  doSetDefaultPart = (ele_3winding_part_kind:number,part:Iele3WindingPart,frequency:number,capacityTrans3Item:any,listCapcityT3:any,name?:string) => {
    part.capacity = capacityTrans3Item === undefined?0:capacityTrans3Item.dcapacity;
    doCalcSetValue(part,part.capacity,frequency,listCapcityT3,name);
    part.dispInrush = true;
    part.dispIntensity = false;
    part.intensityTimes = 25;
    part.intensityTime = 2;
    part.fault = false;
    part.dropRegular = true;
    part.infoLineDispBand = false;
    part.infoLineKind = 0;
    part.infoLineColor = "#000000";
    part.infoLineWidth = 30;
    const calcPoint1 = {} as CalcPoint ;
    calcPoint1.loadCapacity = 0;
    calcPoint1.loadCurrent = 0;
    calcPoint1.powerFactor = 1;
    calcPoint1.isLead = false;
    calcPoint1.calcKind = CALC_IK3;
    calcPoint1.stdCapacity = 0;
    calcPoint1.voltage = part.voltage;
    calcPoint1.ele3WindingPartKind = ele_3winding_part_kind;
    calcPoint1.perZDirect = percentZDefault();
    calcPoint1.perZTotal = percentZDefault();
    calcPoint1.perZDirectMG = percentZDefault();
    calcPoint1.perZTotalMG = percentZDefault();
    calcPoint1.perZMotor = percentZDefault();
    
    doSetVoltage(part.voltage,calcPoint1);
    const calcPoint2 = {} as CalcPoint ;
    calcPoint2.loadCapacity = 0;
    calcPoint2.loadCurrent = 0;
    calcPoint2.powerFactor = 1;
    calcPoint2.isLead = false;
    calcPoint2.calcKind = CALC_IK2;
    calcPoint2.stdCapacity = 0;
    calcPoint2.voltage = part.voltage;
    calcPoint2.ele3WindingPartKind = ele_3winding_part_kind
    calcPoint2.perZDirect = percentZDefault();
    calcPoint2.perZTotal = percentZDefault();
    calcPoint2.perZDirectMG = percentZDefault();
    calcPoint2.perZTotalMG = percentZDefault();
    calcPoint2.perZMotor = percentZDefault();

    doSetVoltage(part.voltage,calcPoint2);
    part.calcPoint0 = calcPoint1
    part.calcPoint1 = calcPoint2
}

export const doCalcSetValue = (windingPart:Iele3WindingPart,capacity:number,frequency:any,listCapcityT3:any,nConTrol:any) => {
    const obj = {ohmR : 0, ohmX : 0,inrushTimes : 0, dampingTime : 0};
    doReadByCapacityT3(capacity,listCapcityT3,obj);
    if(capacity <= 0){
        windingPart.perZk3 = 0;
        windingPart.xR = 0;
    }else{  
        if(nConTrol != "partPrimary.perZk3" && nConTrol != "partSecondary.perZk3" && nConTrol != "partThird.perZk3" && nConTrol != "partPrimary.xR" && nConTrol != "partSecondary.xR" && nConTrol != "partThird.xR"){
            windingPart.perZk3 = Math.sqrt(obj.ohmR*obj.ohmR + obj.ohmX*obj.ohmX);
            if(obj.ohmR == 0 ){
                windingPart.xR = 0
            }else{
                if(obj.ohmR ==0)
                    windingPart.xR = 99999;
                else if(obj.ohmX ==0)
                    windingPart.xR = 0;
                else
                    windingPart.xR = obj.ohmX  /obj.ohmR;
            }
        }
        if(nConTrol != "voltage_secondary" && nConTrol != "PERCENT_R"){
            if(windingPart.xR === 99999){
                windingPart.perRk3  = 0;
            }
            else{
                windingPart.perRk3  = doGetPercentRe(windingPart.xR,windingPart.perZk3);
            }
        }
        if(nConTrol != "voltage_secondary" && nConTrol != "PERCENT_X"){
            if(windingPart.xR === 0){
                windingPart.perXk3  = 0;
            }
            else{
                if(windingPart.perRk3 == 0){
                    windingPart.perXk3 = windingPart.perZk3;
                }
                else{
                    windingPart.perXk3  = doGetPercentXe(windingPart.xR,windingPart.perRk3);
                }
            }
        }
        windingPart.inrushTimes = obj.inrushTimes;
        if(frequency == 0){
            windingPart.dampingTime = 0;
        }
        else{
            windingPart.dampingTime = obj.dampingTime * 1000 / frequency;
        }
    }
}

export const doSetValueDropLoadCurrent = (voltage:number,partWinding:Iele3WindingPart) => {
    partWinding.calcPoint0.loadCurrent = partWinding.calcPoint0.loadCapacity /voltage;
    switch(partWinding.calcPoint0.calcKind){
        case CALC_IK3:
            partWinding.calcPoint0.loadCurrent /= Math.sqrt(3.0);
            break;
        case CALC_IK2:
        case CALC_IK2_OUTSIDE_NEUTRAL:
        case CALC_IK2_OUTSIDE_OUTSIDE:
        case CALC_IK2_SEAT_MAIN:
        case CALC_IK2_SEAT_T:
            break;
        default:
            break;
    }
    return partWinding.calcPoint0.loadCurrent;
}

export const doGetValueDropLoadCapacity = (voltage:any,partWinding:Iele3WindingPart) => {
    let loadCapacity = voltage * partWinding.calcPoint0.loadCurrent;
    switch(partWinding.calcPoint0.calcKind){
        case CALC_IK3:
            loadCapacity *= Math.sqrt(3.0);
            break;
        case CALC_IK2:
        case CALC_IK2_OUTSIDE_NEUTRAL:
        case CALC_IK2_OUTSIDE_OUTSIDE:
        case CALC_IK2_SEAT_MAIN:
        case CALC_IK2_SEAT_T:
            break;
        default:
            break;
    }
    return loadCapacity;
}

export const doReadByCapacityT3 = (capacity:any,listCapcityT3:any,object:any) =>{
    if(listCapcityT3 && listCapcityT3.length > 0){
        listCapcityT3.sort((a:any,b:any) => a.ltrans3ID - b.ltrans3ID)
        for(const item of listCapcityT3){
            if(Number(capacity) <= Number(item.dcapacity) || item.dcapacity == -1)
            {
                object.ohmR = item.dpercentR;
                object.ohmX = item.dpercentX;
                object.inrushTimes = item.dpeakValue;
                object.dampingTime = item.ddampingTime;
                break;
            }
        }
    }
} 
export const doGetPercentRe = (dXr:number,percentZ:number) =>
{
    return  percentZ / Math.sqrt(dXr * dXr +1);
}
export const doGetPercentXe = (dXr:number,percentR:number) =>
{
    return dXr * percentR;
}
export const doGetValueRatedCurrent = (capacity:number,voltage:number) => {
    let ratedCurrent;
    if(voltage <= 0){
        ratedCurrent = 0;
    }else{
        ratedCurrent = capacity * 1000 / voltage / Math.sqrt(3.0);
    }
    return ratedCurrent;
}
export const doGetValueOhmR = (voltage:number,capacity:number,percentR:number) =>
{
    if(voltage == 0 || percentR == 0 ){
        return 0;
    }
    return voltage * voltage / capacity / 1000 * percentR * 10;
}
export const doGetValueOhmX = (voltage:number,capacity:number,percentX:number) =>
{
    if(voltage == 0 || percentX == 0 ){
        return 0;
    }
    return voltage * voltage / capacity / 1000 * percentX * 10;
}

export const doGetValueOhmZ = (ohmR:number,ohmX:number)=>{
    return Math.sqrt(ohmR * ohmR + ohmX * ohmX);
} 

export const doGetTextOhmR = (windingPart:Iele3WindingPart) => {
    const ohmR = doGetValueOhmR(windingPart.voltage,windingPart.capacity,windingPart.perRk3)
    return BeamsNumber(Number(ohmR),4);
} 

export const doGetTextOhmX =(partWinding:Iele3WindingPart)=>{
    const ohmX = doGetValueOhmX(partWinding.voltage,partWinding.capacity,partWinding.perXk3);
    return BeamsNumber(Number(ohmX),4);
}

export const doGetTextOhmZ = (windingPart:Iele3WindingPart) => {
    const ohmR = doGetValueOhmR(windingPart.voltage,windingPart.capacity,windingPart.perRk3);
    const ohmX = doGetValueOhmX(windingPart.voltage,windingPart.capacity,windingPart.perXk3);
    const ohmZ = doGetValueOhmZ(ohmR,ohmX);
    return BeamsNumber(Number(ohmZ),4);
} 
export const BeamsValue = (windingPart:Iele3WindingPart) =>{
    // windingPart.xR = BeamsNumber(Number(windingPart.xR),4);
    // windingPart.perZk3 = BeamsNumber(Number(windingPart.perZk3),4);
    // windingPart.perRk3 = BeamsNumber(Number(windingPart.perRk3),4);
    windingPart.ratedCurrent = BeamsNumber(Number(doGetValueRatedCurrent(windingPart.capacity,windingPart.voltage)),4);
    // windingPart.perXk3 = BeamsNumber(Number(windingPart.perXk3),4);
    windingPart.ohmR = doGetTextOhmR(windingPart);
    windingPart.ohmX = doGetTextOhmX(windingPart);
    windingPart.ohmZ = doGetTextOhmZ(windingPart);
}
export const BeamsValue2 = (windingPart:Iele3WindingPart) =>{
    windingPart.ohmZ = doGetTextOhmZ(windingPart);
    windingPart.ohmR = doGetTextOhmR(windingPart);
    windingPart.ohmX = doGetTextOhmX(windingPart);
    windingPart.xR = BeamsNumber(Number(windingPart.xR),4);
    windingPart.perZk3 = BeamsNumber(Number(windingPart.perZk3),4);
    windingPart.perRk3 = BeamsNumber(Number(windingPart.perRk3),4);
    windingPart.perXk3 = BeamsNumber(Number(windingPart.perXk3),4);
    windingPart.ratedCurrent = BeamsNumber(Number(doGetValueRatedCurrent(windingPart.capacity,windingPart.voltage)),4);
    
}
export const doGetTextOhmR_Map = (windingPart:any) =>{
    const ohmR = doGetValueOhmR(windingPart.voltage,windingPart.capacity,windingPart.per_r_k3)
    return BeamsNumber(Number(ohmR),4);
}
export const doGetTextOhmX_Map = (windingPart:any) =>{
    const ohmX = doGetValueOhmX(windingPart.voltage,windingPart.capacity,windingPart.per_x_k3);
    return BeamsNumber(Number(ohmX),4);
}
export const doGetTextOhmZ_Map = (windingPart:any) =>{
    const ohmR = doGetValueOhmR(windingPart.voltage,windingPart.capacity,windingPart.per_r_k3);
    const ohmX = doGetValueOhmX(windingPart.voltage,windingPart.capacity,windingPart.per_x_k3);
    const ohmZ = doGetValueOhmZ(ohmR,ohmX);
    return BeamsNumber(Number(ohmZ),4);
}
export const doGetValueRatedCurrent_Map = (windingPart:any) =>{
    return BeamsNumber(Number(doGetValueRatedCurrent(windingPart.capacity,windingPart.voltage)),4);
}

export const doGetTextInRushTimes = (windingPart:Iele3WindingPart,partKind?:any) =>{
    return BeamsNumber(Number(doGetValueInRushPointTimes(windingPart)),4);
}

export const doGetValueInRushPointTimes = (windingPart:Iele3WindingPart) => {
    return windingPart.inrushTimes;
}

export const doGetTextInRushValue = (windingPart:Iele3WindingPart) => {
    let inRushValue;
    const ratedCurrent = doGetValueRatedCurrent(windingPart.capacity,windingPart.voltage);
    if(ratedCurrent === 0){
        inRushValue = 0;
    }else{
        inRushValue = doGetValueInRushPointTimes(windingPart) * ratedCurrent;
    }
    return BeamsNumber(Number(inRushValue),4);
}

export const doGetTextDampingTime = (windingPart:Iele3WindingPart,partKind?:any) => {
    return BeamsNumber(Number(windingPart.dampingTime),4);
}

export const doGetTextIntensityTimes = (windingPart:Iele3WindingPart,partKind?:any) => {
    return BeamsNumber(Number(windingPart.intensityTimes),4);
}

export const doGetTextIntensityValue = (windingPart:Iele3WindingPart,partKind?:any) => {
    let intensityValue;
    const ratedCurrent = doGetValueRatedCurrent(windingPart.capacity,windingPart.voltage);
    if(ratedCurrent === 0){
        intensityValue = 0;
    }
    else{
        intensityValue = windingPart.intensityTimes * ratedCurrent;
    }
    return BeamsNumber(Number(intensityValue),4);
}

export const doGetTextIntensityTime = (windingPart:Iele3WindingPart,partKind?:any) => {
    return BeamsNumber(Number(windingPart.intensityTime),0);
}

export const doGetTextVoltDropPF = (CalcPoint:CalcPoint) => {
    return BeamsNumber(Number(CalcPoint.powerFactor),5);
}

//CEle3WindingPart::DoGetTextVoltage
export const doGetTextVoltage = (windingPart:Iele3WindingPart) =>
{
	return windingPart.voltage.toString();

}

//CEle3WindingPart::DoGetTextCapacity
export const doGetTextCapacity = (windingPart:Iele3WindingPart) =>
{
	return BeamsNumber(windingPart.capacity, 6).toString();
}

//CEle3WindingPart::DoGetTextRatedCurrent
export const doGetTextRatedCurrent = (windingPart:Iele3WindingPart, bUnit:boolean) =>
{
	let dRatedCurrent = doGetValueRatedCurrent(windingPart.capacity, windingPart.voltage);
	let sText = BeamsNumber(dRatedCurrent, 4).toString();
	if (bUnit) {
		sText += 'A';
	}
	return sText;
}

//CEle3WindingPart::DoGetTextPercentZe
export const doGetTextPercentZe  = (windingPart:Iele3WindingPart) =>
{
	return BeamsNumber(windingPart.perZk3, 4).toString();
}

//CEle3WindingPart::DoGetTextXR
export const doGetTextXR = (windingPart:Iele3WindingPart) =>
{
	return BeamsNumber(windingPart.xR, 4).toString();
}

//CEle3WindingPart::DoGetTextDirectIk3peak
export const doGetTextDirectIk3peak = (windingPart:Iele3WindingPart) =>
{
	let dIpeakDirect = doGetIkpeakDirectMG(windingPart.calcPoint0);
	let sText;
	if (dIpeakDirect >= 0) {
		sText = BeamsNumber(dIpeakDirect / 1000, 4);
	}
	else {							
        sText = '????';	
	}

	return sText;
}

//CEle3WindingPart::DoGetTextDirectIk3asym
export const doGetTextDirectIk3asym = (windingPart:Iele3WindingPart) =>
{
	let dIasymDirect = doGetIkasymDirectMG(windingPart.calcPoint0);
	let sText;
	if (dIasymDirect >= 0) {
		sText = BeamsNumber(dIasymDirect / 1000, 4).toString();
	}
	else {							
        sText = '????';	
	}

	return sText;
}

//CEle3WindingPart::DoGetTextDirectIk3sym
export const doGetTextDirectIk3sym = (windingPart:Iele3WindingPart) =>
{
	let dIsymDirect = doGetIksymDirectMG(windingPart.calcPoint0);
	let sText;
	if (dIsymDirect >= 0) {
		sText = BeamsNumber(dIsymDirect / 1000, 4).toString();
	}
	else {							
        sText = '????';	
	}

	return sText;
}

//CEle3WindingPart::DoGetTextTotalIk3sym
export const doGetTextTotalIk3sym = (windingPart:Iele3WindingPart) =>
{
	let dIsymTotal = doGetIksymTotalMG(windingPart.calcPoint0);
	let sText;
	if (dIsymTotal >= 0) {
		sText = BeamsNumber(dIsymTotal / 1000, 4).toString();
	}
	else {						
        sText = '????';	
	}

	return sText;
}

//CEle3WindingPart::DoGetTextLoadCurrent
export const doGetTextLoadCurrent = (windingPart:Iele3WindingPart) =>
{
	return BeamsNumber(windingPart.calcPoint0.loadCurrent, 5).toString();
}

//CEle3WindingPart::DoGetTextDropVoltPF
export const doGetTextDropVoltPF = (windingPart:Iele3WindingPart, bUnit:boolean) =>
{
	let sText = BeamsNumber(windingPart.calcPoint0.powerFactor, 5).toString();

	if (bUnit) {
		if (windingPart.calcPoint0.isLead) {
			sText += '(進み)';
		}
		else {
			sText += '(遅れ)';
		}
	}
	return sText;
}

//CEle3WindingPart::DoGetTextDropVoltRegular
export const doGetTextDropVoltRegular = (windingPart:Iele3WindingPart, dVoltage:number, dPerRk3:number, dPerXk3:number) =>
{
	return doGetTextDropVoltRegularCalcPoint(windingPart.calcPoint0, dVoltage, dPerRk3, dPerXk3, false);

}

//CEle3WindingPart::DoGetTextDropPerRegular
export const doGetTextDropPerRegular = (windingPart:Iele3WindingPart, dVoltage:number, dPerRk3:number, dPerXk3:number) =>
{									
	return doGetTextDropVoltRegularCalcPoint(windingPart.calcPoint0, dVoltage, dPerRk3, dPerXk3, false);
}

//CEle3WindingPart::DoGetTextDropPerRegular2
export const doGetTextDropPerRegular2 = (windingPart:Iele3WindingPart, dVoltage:number, dPerRk3:number, dPerXk3:number) =>
{
    return doGetTextDropPerRegular2CalcPoint(windingPart.calcPoint0, dVoltage, dPerRk3, dPerXk3, false);
}

export const convertDataWindingPart = (part:any[]) => {
        let calcPoint0 ={} as any
        let calcPoint1 ={} as any
        let calcPoint0_perZDirect = {} as any
        let calcPoint0_perZTotal = {} as any
        let calcPoint0_perZDirectMG = {} as any
        let calcPoint0_perZTotalMG  = {} as any
        let calcPoint0_perZMotor  = {} as any

        let calcPoint1_perZDirect = {} as any
        let calcPoint1_perZTotal = {} as any
        let calcPoint1_perZDirectMG = {} as any
        let calcPoint1_perZTotalMG = {} as any
        let calcPoint1_perZMotor = {} as any
        let mainProps = {} as any
    part.forEach((item:any) =>{
        if(item.calc_point_id === 1 && item.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT){
            calcPoint0_perZDirect = item
        }
        else if(item.calc_point_id === 1 && item.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL){
            calcPoint0_perZTotal = item
        }
        else if(item.calc_point_id === 1 && item.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT_MG){
            calcPoint0_perZDirectMG = item
        }
        else if(item.calc_point_id === 1 && item.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL_MG){
            calcPoint0_perZTotalMG = item
        }
        else if(item.calc_point_id === 1 && item.calc_per_z_type === CALC_PER_Z_TYPE_MOTOR){
            calcPoint0_perZMotor = item
        }
        else if(item.calc_point_id === 2 && item.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT){
            calcPoint1_perZDirect = item
        }
        else if(item.calc_point_id === 2 && item.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL){
            calcPoint1_perZTotal = item
        }
        else if(item.calc_point_id === 2 && item.calc_per_z_type === CALC_PER_Z_TYPE_DIRECT_MG){
            calcPoint1_perZDirectMG = item
        }
        else if(item.calc_point_id === 2 && item.calc_per_z_type === CALC_PER_Z_TYPE_TOTAL_MG){
            calcPoint1_perZTotalMG = item
        }
        else if(item.calc_point_id === 2 && item.calc_per_z_type === CALC_PER_Z_TYPE_MOTOR){
            calcPoint1_perZMotor = item
        }
        else if(item.calc_point_id === 1){
            calcPoint0 = item
        }
        else if(item.calc_point_id === 2){
            calcPoint1 = item
        }
        else{
            mainProps = item
        }
    })
    return {voltage : mainProps.voltage,
    capacity : mainProps.capacity,
    xR : mainProps.xr,
    perRk3 : mainProps.per_r_k3,
    perXk3 : mainProps.per_x_k3,
    perZk3 : mainProps.per_z_k3,
    ohmR: doGetTextOhmR_Map(mainProps),
    ohmX: doGetTextOhmX_Map(mainProps),
    ohmZ: doGetTextOhmZ_Map(mainProps),
    ratedCurrent: doGetValueRatedCurrent_Map(mainProps),
    inrushTimes : mainProps.inrush_times,
    inrushTime : mainProps.inrush_time,
    dampingTime : mainProps.damping_time,
    intensityTimes : mainProps.intensity_times,
    intensityTime : mainProps.intensity_time,
    // bFalse : boolean,//
    dispInrush : mainProps.disp_inrush,
    dispIntensity : mainProps.disp_intensity,
    fault : mainProps.fault,
    selInrush : mainProps.sel_inrush,
    selIntensity : mainProps.sel_intensity,
    infoLineDispBand : mainProps.info_line_disp_band,
    infoLineColor : mainProps.info_line_color,
    infoLineKind : mainProps.info_line_kind,
    infoLineWidth : mainProps.info_line_width,
    pageIndex : mainProps.page_index,
    pointText : mainProps.point_text,
    eleLineNumber : mainProps.ele_line_number,
    eleNodeNumber : mainProps.ele_node_number,
    perUnitR : mainProps.per_unit_r,
    perUnitX : mainProps.per_unit_x,
    perUnitK : mainProps.per_unit_k,
    lineActiveFlow1 : mainProps.line_active_flow1,
    lineReactiveFlow1 : mainProps.line_reactive_flow1,
    lineActiveFlow2 : mainProps.line_active_flow2,
    lineReactiveFlow2 : mainProps.line_reactive_flow2,
    voltageMagnitude1 : mainProps.voltage_magnitude1,
    voltageAngle1 : mainProps.voltage_angle1,
    voltageMagnitude2 : mainProps.voltage_magnitude2,
    voltageAngle2 : mainProps.voltage_angle2,
    inputedCapacity1 : mainProps.inputed_capacity1,
    inputedCurrent1 : mainProps.inputed_current1,
    inputedPowerFactor1 : mainProps.inputed_power_factor1,
    neutralKind : mainProps.neutral_kind,
    dispAnsiKind : mainProps.disp_ansi_kind,
    ansiTimes : mainProps.ansi_times,
    ansiTime : mainProps.ansi_time,
    inrushTimesKind : mainProps.inrush_times_kind,
    inrushManualValue : mainProps.inrush_manual_value,
    // ratedCurrent : 0,
    dropRegular : mainProps.drop_regular,
    examineComment : mainProps.examine_comment,
    voltDropSumRe: mainProps.volt_drop_sum_re,
    voltDropSumIm: mainProps.volt_drop_sum_im,
    voltDropRouteRefNo: mainProps.volt_drop_route_ref_no,
    calcPoint0 : doConvertDataCalcPoint(calcPoint0, 
                                        calcPoint0_perZDirect, 
                                        calcPoint0_perZTotal, 
                                        calcPoint0_perZDirectMG, 
                                        calcPoint0_perZTotalMG, 
                                        calcPoint0_perZMotor),
    calcPoint1 : doConvertDataCalcPoint(calcPoint1, 
                                        calcPoint1_perZDirect, 
                                        calcPoint1_perZTotal, 
                                        calcPoint1_perZDirectMG, 
                                        calcPoint1_perZTotalMG, 
                                        calcPoint1_perZMotor),
    } as Iele3WindingPart
}

//CEle3WindingPart::DoGetTextActiveLeft
export const doGetTextActiveLeft = (props:Iele3WindingPart, nDirect:number, bUnit:boolean = true) =>
{
	let sText:string ='';
	if (nDirect == DIRECT_NONE) {
		if (props.lineActiveFlow1 >= 0) {
			sText = BeamsNumber(props.lineActiveFlow1, 5).toString();
		}
		else {
			sText = BeamsNumber(props.lineActiveFlow2, 5).toString();
		}
	}
	else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
		sText = BeamsNumber(Math.abs(props.lineActiveFlow1), 5).toString();
	}
	else {
		sText = BeamsNumber(Math.abs(props.lineActiveFlow2), 5).toString();
	}

	if (bUnit) {
		sText += 'kW';
	}

	return sText;
}

//CEle3WindingPart::DoGetTextReactiveLeft
export const doGetTextReactiveLeft = (props:Iele3WindingPart, nDirect:number, bUnit:boolean = true) =>
{
	let sText = '';
	if (nDirect == DIRECT_NONE) {
		if (props.lineReactiveFlow1 >= 0) {
			sText = BeamsNumber(props.lineReactiveFlow1, 5).toString();
		}
		else {
			sText = BeamsNumber(props.lineReactiveFlow2, 5).toString();
		}
	}
	else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
		sText = BeamsNumber(Math.abs(props.lineReactiveFlow1), 5).toString();
	}
	else {
		sText = BeamsNumber(Math.abs(props.lineReactiveFlow2), 5).toString();
	}

	if (bUnit) {
		sText += 'kvar';
	}

	return sText;
}

//CEle3WindingPart::DoGetTextFlowsResult
export const doGetTextFlowsResult = (props:Iele3WindingPart, processMode:any, nDirect:number, bSociety:boolean = false) =>
{
	let sRefV1: string[] = [];

	if (bSociety) {
		return sRefV1;
	}

	switch (processMode) {
	case MS_MODE_DRAWING:
	case MS_MODE_CHECKING:
	case MS_MODE_PERCENT_Z:
	case MS_MODE_CALCULATION:
	case MS_MODE_VOLTDROP:
	case MS_MODE_HARMONIC:
		break;
	case MS_MODE_POWER_FLOW:
		if (props.lineActiveFlow1 !==0 && props.lineReactiveFlow1 !==0 && props.lineActiveFlow2 !==0 && props.lineReactiveFlow2 !==0) {
			if (nDirect == DIRECT_NONE) {
				sRefV1.push(doGetTextActiveLeft(props,nDirect));
				sRefV1.push(doGetTextReactiveLeft(props,nDirect));
			}
			else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
				sRefV1.push(doGetTextActiveLeft(props,nDirect));
                let sTemp = ''
				if (props.lineActiveFlow1 > 0) {
					if (props.lineReactiveFlow1 > 0) {
						sTemp += "+j";
					}
					else {
						sTemp += "-j";
					}
				}
				else {
					if (props.lineReactiveFlow1 > 0) {
						sTemp += "-j";
					}
					else {
						sTemp += "+j";
					}
				}
				sRefV1.push(sTemp + doGetTextReactiveLeft(props, nDirect));
			}
			else {
				sRefV1.push(doGetTextActiveLeft(props,nDirect));
                let sTemp = ''
				if (props.lineActiveFlow2 > 0) {
					if (props.lineReactiveFlow2 > 0) {
						sTemp += "+j";
					}
					else {
						sTemp += "-j";
					}
				}
				else {
					if (props.lineReactiveFlow2 > 0) {
						sTemp += "-j";
					}
					else {
						sTemp += "+j";
					}
				}
				sRefV1.push(sTemp + doGetTextReactiveLeft(props,nDirect));
			}
		}
		break;
	}

	return sRefV1;
}


