import { store } from "../../..";
import { CdPoint, GraphLineData, Ms3WindingModel, Point } from "../../../models/Index";
import { MS_MODE_CALCULATION } from "../../../statics";
import { doSetPosCurvePointDirect } from "../DCView";

const doDrawCurve3Winding = (props:Ms3WindingModel, drawMode: number) =>{
    // let m_bDispInsush2 = props.partSecondary.dispInrush;
    // let m_bDispInsush3 = props.partThird.dispInrush;
    
    // let m_bDispIntensity2 = props.partSecondary.dispIntensity;
    // let m_bDispIntensity3 = props.partThird.dispIntensity;
    
    //Inrush Time
    let dInrushTimes2 = props.partSecondary.inrushTimes;
    let dInrushDampingTime2 = props.partSecondary.dampingTime;
    let dInrushTimes3 = props.partThird.inrushTimes;
    let dInrushDampingTime3 = props.partThird.dampingTime;


    //Intensity time
    let intensityDampingTimes2 = props.partSecondary.intensityTimes;
    let intensityDampingTime2 = props.partSecondary.intensityTime;
    let intensityDampingTimes3 = props.partThird.intensityTimes;
    let intensityDampingTime3 = props.partThird.intensityTime;
    
    
    let graphLineData : GraphLineData = { lines: [] }
   
//////////////////////////////// 一次側 ////////////////////////////////
    
    const inrushPointsOutIn1 : Point[] = []
    const inrushPointsOutIn1_1 : Point[] = []
    
    const intensityPointsOutIn1 : Point[] = []
    const intensityPointsOutIn1_2: Point[] = []

//////////////////////////////// ニ次側 ////////////////////////////////
    const inrushPointsPart2 : Point[] = []
    const intensityPointPart2 : Point[] = []
    

    const part2 ={
        m_listDrawInrush: [] as CdPoint[],
        m_listDrawIntensity: [] as CdPoint[],
   }
   
   if(drawMode === 0 || drawMode === 1) {
        //Inrush
        //if(m_bDispInsush2) {
            if(doDrawInrushPart(props, part2.m_listDrawInrush, 2, dInrushTimes2, dInrushDampingTime2)){
                part2.m_listDrawInrush.forEach(point => inrushPointsPart2.push({x: point.m_dx, y: point.m_dy} as Point))
                graphLineData.lines.push({points: inrushPointsPart2, id: 'INRUSH_PART_2'})
            }
        //}   
        // Intensity
        //if(m_bDispIntensity2) {     
            if(doDrawIntensityPart(props, part2.m_listDrawIntensity, 1, intensityDampingTimes2, intensityDampingTime2)){
                part2.m_listDrawIntensity.forEach(point => intensityPointPart2.push({x: point.m_dx, y: point.m_dy} as Point))
                graphLineData.lines.push({points: intensityPointPart2, id: 'INTENSITY_PART_2'})
            }
        //}
   }
    
    let mode = store.getState().app.diagram.processMode;

    if(drawMode === 0 || drawMode === 2) {
        if (mode >= MS_MODE_CALCULATION && props.partSecondary.fault) {				
            let temp = doSetPosCurvePointDirect(props.partSecondary.calcPoint0, 0);
            if(temp !== undefined) {
                graphLineData.lines.push(temp);
            }
    
            temp = doSetPosCurvePointDirect(props.partSecondary.calcPoint1, 1);
            if(temp !== undefined) {
                graphLineData.lines.push(temp)
            }
        }
    }
       

//////////////////////////////// 三次側 ////////////////////////////////
    const inrushPointsPart3 : Point[] = []
    const intensityPointsPart3 : Point[] = []

    const part3 ={
        m_listDrawInrush: [] as CdPoint[],
        m_listDrawIntensity: [] as CdPoint[]
   }
 
    if(drawMode === 0 || drawMode === 1) {
        //if(m_bDispInsush3){
            if(doDrawInrushPart(props, part3.m_listDrawInrush, 3, dInrushTimes3, dInrushDampingTime3)){
                part3.m_listDrawInrush.forEach(point => inrushPointsPart3.push({x: point.m_dx, y: point.m_dy} as Point))
                graphLineData.lines.push({points: inrushPointsPart3, id: 'INRUSH_PART_3'})
            }
        //}

        //if(m_bDispIntensity3){
            if(doDrawIntensityPart(props, part3.m_listDrawIntensity, 1, intensityDampingTimes3, intensityDampingTime3)){
                part3.m_listDrawIntensity.forEach(point => intensityPointsPart3.push({x: point.m_dx, y: point.m_dy} as Point))
                graphLineData.lines.push({points: intensityPointsPart3, id: 'INTENSITY_PART_3'})
            }
        //}
    }
   
    if(drawMode === 0 || drawMode === 2) {
        if (mode >= MS_MODE_CALCULATION && props.partThird.fault) {				
            let temp = doSetPosCurvePointDirect(props.partThird.calcPoint0, 0);
            if(temp !== undefined) {
                graphLineData.lines.push(temp);
            }
    
            temp = doSetPosCurvePointDirect(props.partThird.calcPoint1, 1);
            if(temp !== undefined) {
                graphLineData.lines.push(temp)
            }
        }
    }

    return graphLineData;
}

const doDrawInrushPart = (props: Ms3WindingModel,m_listDrawInrush:Array<CdPoint>,tabid:number,dInrushTimes:number,dDampingTime:number) : boolean =>{
    
    
    if(doMakeCurveInrush(props,m_listDrawInrush,tabid,dInrushTimes,dDampingTime) !==true){
        return false;
    }
    return true;
}

const doGetValueRatedCurrent = (m_lVoltage:number,dCapacity :number) =>{
    let dRateCurrent = 0;
    if(m_lVoltage <= 0){
        dRateCurrent = 0;
    }else{
        dRateCurrent = dCapacity *1000 / m_lVoltage /Math.sqrt(3.0);
    }
    return dRateCurrent;
}

const doMakeCurveInrush =(props:Ms3WindingModel,m_listDrawInrush: Array<CdPoint>, tabid:number,dInrushTimes:number,dDampingTime:number) :boolean =>{
            let m_lVoltage : number = 0;
            let dCapacity : number = 0;
            let dRatedCurrent : number =0;


    const pdPoint : CdPoint = {
        m_dx: 0,
        m_dy: 0
    }  
    if(tabid ===1){                
        m_lVoltage = props.partPrimary.voltage;
        dCapacity = props.partPrimary.capacity;   
        dRatedCurrent = doGetValueRatedCurrent(m_lVoltage,dCapacity) * m_lVoltage;

    }
    if(tabid ===2){                
        m_lVoltage = props.partPrimary.voltage;
        dCapacity = props.partSecondary.capacity;
        dRatedCurrent = doGetValueRatedCurrent(m_lVoltage,dCapacity) * m_lVoltage;

    }
    if(tabid ===3){                
        m_lVoltage = props.partPrimary.voltage;
        dCapacity = props.partThird.capacity;
        dRatedCurrent = doGetValueRatedCurrent(m_lVoltage,dCapacity) * m_lVoltage;        

    }
    if(dRatedCurrent <=0){
        return false;
    }
    let nCount = 0;
    for(let dTime = dDampingTime *10;dTime >=0.01;dTime -=(dTime /10)){
        const dCurrent = dRatedCurrent * dInrushTimes * Math.exp(-1 *(dTime -0.01)/ (dDampingTime /1000));
        if(dCurrent >= dRatedCurrent){
            if(nCount ===0){
                pdPoint.m_dx = dRatedCurrent;
                pdPoint.m_dy = dTime * 1000;
                m_listDrawInrush.push({...pdPoint});

                pdPoint.m_dx = dRatedCurrent;
                pdPoint.m_dy = dTime + dTime /5;
                m_listDrawInrush.push({...pdPoint});

                pdPoint.m_dx = dRatedCurrent +dRatedCurrent / 30;
                pdPoint.m_dy = dTime + dTime /10;
                m_listDrawInrush.push({...pdPoint});

                nCount++;
                if(dCurrent <= pdPoint.m_dx){
                    pdPoint.m_dx =  dRatedCurrent +dRatedCurrent / 10;
                    pdPoint.m_dy = dTime;
                    continue;
                }
            }
            pdPoint.m_dx = dCurrent;
            pdPoint.m_dy = dTime;
            m_listDrawInrush.push({...pdPoint});

        }
    }
            pdPoint.m_dx = dRatedCurrent * dInrushTimes;
            pdPoint.m_dy = 0.01;
            m_listDrawInrush.push({...pdPoint});

            pdPoint.m_dx = dRatedCurrent * dInrushTimes - dRatedCurrent * dInrushTimes /100;
            pdPoint.m_dy = 0.0095;
            m_listDrawInrush.push({...pdPoint});

            pdPoint.m_dx = dRatedCurrent * dInrushTimes - dRatedCurrent * dInrushTimes /30;
            pdPoint.m_dy = 0.009;
            m_listDrawInrush.push({...pdPoint});

            pdPoint.m_dx = dRatedCurrent * dInrushTimes - dRatedCurrent * dInrushTimes /15;
            pdPoint.m_dy = 0.0087;
            m_listDrawInrush.push({...pdPoint});

            pdPoint.m_dx = dRatedCurrent * dInrushTimes - dRatedCurrent * dInrushTimes /5;
            pdPoint.m_dy = 0.0082;
            m_listDrawInrush.push({...pdPoint});

            pdPoint.m_dx = dRatedCurrent /10;
            pdPoint.m_dy = 0.005;
            m_listDrawInrush.push({...pdPoint});

    return true;
}

const doDrawIntensityPart = (props:Ms3WindingModel,m_listDrawInrush: Array<CdPoint>,tabid:number,m_dDampingTimes:number,m_dDampingTime:number) :boolean =>{
    if(doMakeCurveIntensity(props,m_listDrawInrush,tabid,m_dDampingTimes,m_dDampingTime)!==true){
        return false;
    }

    return true;
}

const doMakeCurveIntensity = (props:Ms3WindingModel,m_listDrawInrush: Array<CdPoint>,tabid:number,m_dDampingTimes:number,m_dDampingTime:number) :boolean =>{
    let m_lVoltage : number = 0;
    let dCapacity : number = 0;
    let dRatedCurrent : number =0;
    const pdPoint : CdPoint = {
        m_dx: 0,
        m_dy: 0
    }
   
    if(tabid === 1){                
        m_lVoltage = props.partPrimary.voltage;
        dCapacity = props.partPrimary.capacity;
        dRatedCurrent = doGetValueRatedCurrent(m_lVoltage,dCapacity) * m_lVoltage;
      
    }
     if(tabid === 2){                
        m_lVoltage = props.partPrimary.voltage;
        dCapacity = props.partSecondary.capacity;
        dRatedCurrent = doGetValueRatedCurrent(m_lVoltage,dCapacity) * m_lVoltage;
      
    }
    if(tabid === 3){                
        m_lVoltage = props.partPrimary.voltage;
        dCapacity = props.partThird.capacity;        
        dRatedCurrent = doGetValueRatedCurrent(m_lVoltage,dCapacity) * m_lVoltage;

    }
    if(dRatedCurrent<=0){
        return false;
    }   
   
    pdPoint.m_dx = dRatedCurrent * m_dDampingTimes;
    pdPoint.m_dy = m_dDampingTime;

    m_listDrawInrush.push({...pdPoint});
     
    return true;
}

//////////////////////////////// Label line ///////////////////////////////////////
const getMs3WindingInrushLabel = (properties : Ms3WindingModel, infos : string[], dNominalVolt : number, nPartKind : number, inrushTimeAndDampingTimeKind : number) => { 
        const text = properties.refNo;
        const name = properties.partSecondary.pointText;
        let partName = ''
        let dRatedCurrent : number =0;
        let m_lVoltage : number = 0;
        let dCapacity : number = 0;
        let dInrushTimes = 0;
        let dInrushDampingTime = 0;
        if(nPartKind === 1){
            partName = '一次側'                
            m_lVoltage = properties.partPrimary.voltage;
            dCapacity = properties.partPrimary.capacity;  
            dRatedCurrent = doGetValueRatedCurrent(m_lVoltage,dCapacity);   
        }
         if(nPartKind === 2){
            partName = '二次側'                
            m_lVoltage = properties.partSecondary.voltage;
            dCapacity = properties.partSecondary.capacity;     
            dRatedCurrent = doGetValueRatedCurrent(m_lVoltage,dCapacity);     
        }
        if(nPartKind === 3){
            partName = '三次側'                
            m_lVoltage = properties.partThird.voltage;
            dCapacity = properties.partThird.capacity;   
            dRatedCurrent = doGetValueRatedCurrent(m_lVoltage,dCapacity);             
        }
        infos.push(text);
        infos.push(partName + `,` + name);
        infos.push(Math.round(dCapacity).toString() +`kVA`);
        
        if(m_lVoltage === dNominalVolt){
            infos.push(Math.round(dRatedCurrent) +`A`)
        }
        else{
            const dRateCurrent1 = dRatedCurrent;
            infos.push(Math.round(dRateCurrent1 * m_lVoltage/dNominalVolt) +`A` +`(`+Math.round(dRatedCurrent)+`A)`)
        }

        if(inrushTimeAndDampingTimeKind === 2){
            dInrushTimes = properties.partSecondary.inrushTimes;
            dInrushDampingTime = properties.partSecondary.dampingTime;

        }
        if(inrushTimeAndDampingTimeKind === 3){
            dInrushTimes = properties.partThird.inrushTimes;
            dInrushDampingTime = properties.partThird.dampingTime;
        }

        infos.push(dInrushTimes.toString() +`倍` +`,` +dInrushDampingTime +`ms` )
}

const doGetTextIntensityValue =(m_lVoltage:number,dCapacity:number,m_dIntensityTimes:number)=>{
    let dIntensityValue = 0;
    let dRatedCurrent = doGetValueRatedCurrent(m_lVoltage,dCapacity);
    if(dRatedCurrent ===0){
        dIntensityValue =0
    }
    else{
        dIntensityValue = m_dIntensityTimes * dRatedCurrent;
    }
    return dIntensityValue;
}

const getMs3WindingIntensityLabel = (properties : Ms3WindingModel, infos : string[], dNominalVolt : number, nPartKind : number, intensityTimesAndDampingTimeKind : number) => { 
    const text = properties.refNo;
    const name = properties.partThird.pointText;
    let partName = ''
    let m_lVoltage : number = 0;
    let dCapacity : number = 0;
    let dRatedCurrent : number =0;
    let intensityTimes = 0;
    let intensityTime = 0;

    if(nPartKind === 1){    
        partName = '一次側'            
        m_lVoltage = properties.partPrimary.voltage;
        dCapacity = properties.partPrimary.capacity;
        dRatedCurrent = doGetValueRatedCurrent(m_lVoltage,dCapacity);
      
    }
     if(nPartKind === 2){      
        partName = '二次側'          
        m_lVoltage = properties.partSecondary.voltage;
        dCapacity = properties.partSecondary.capacity;
        dRatedCurrent = doGetValueRatedCurrent(m_lVoltage,dCapacity);
      
    }
    if(nPartKind === 3){          
        partName = '三次側'      
        m_lVoltage = properties.partThird.voltage;
        dCapacity = properties.partThird.capacity;        
        dRatedCurrent = doGetValueRatedCurrent(m_lVoltage,dCapacity);

    }
    infos.push(text);
    infos.push(partName + `,` + name);
    infos.push(dCapacity+`kVA`);

    if(intensityTimesAndDampingTimeKind === 2){
        intensityTimes = properties.partSecondary.intensityTimes;
        intensityTime = properties.partSecondary.intensityTime;
    }
    if(intensityTimesAndDampingTimeKind === 3){
        intensityTimes = properties.partThird.intensityTimes;
        intensityTime = properties.partThird.intensityTime;
    }
    infos.push(intensityTimes +`倍,`)
    const intensity =Math.round(doGetTextIntensityValue(m_lVoltage,dCapacity,intensityTimes))
    if(m_lVoltage === dNominalVolt){
        infos.push(intensity.toString() +`Arms`)
    }
    else{
        let dIntensityValue = 0;
        let dRatedCurrent1 = dRatedCurrent;
        dIntensityValue = intensityTimes * dRatedCurrent1;
        infos.push(Math.round(dIntensityValue * m_lVoltage /dNominalVolt).toString()+`Arms` +`(`+intensity+`)`)
    }
    infos.push(intensityTime.toString() +`s`)


}

export {
    doMakeCurveInrush,
    doDrawCurve3Winding,
    getMs3WindingInrushLabel,
    getMs3WindingIntensityLabel
}