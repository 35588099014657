import { store } from "../../..";
import { CdPoint, GraphLineData, MsTrans3Model, Point } from "../../../models/Index"
import { MS_MODE_CALCULATION } from "../../../statics";
import { isPointFaultTrans3 } from "../../../utils/Trans3Function";
import { doSetPosCurvePointDirect } from "../DCView";

const doDrawCurveTrans3 = (props:MsTrans3Model, drawMode: number)=>{
  let graphLineData : GraphLineData = { lines: [] }

  if(drawMode === 0 || drawMode === 1) {
      // Inrush
      //let m_bDispInrush = props.dispInrush;
      const m_listDrawInrush : CdPoint[] = []
      //if(m_bDispInrush){
        if(doMakeCurveInrushTrans3(m_listDrawInrush, props)){
        graphLineData.lines.push({
          points: m_listDrawInrush.map((point: CdPoint) => ({x: point.m_dx, y: point.m_dy} as Point)),
          id: 'INRUSH'
        })
       // }
    }
      // Intensity
      const m_listDrawIntensity : CdPoint[] = []
      //let m_bDispIntensity = props.dispIntensity;
      //if(m_bDispIntensity){
      if(doMakeCurveIntensityTrans3(m_listDrawIntensity,props)){
        graphLineData.lines.push({
          points: m_listDrawIntensity.map((point: CdPoint) => ({x: point.m_dx, y: point.m_dy} as Point)),
          id: 'INTENSITY'
        })
      }
    //}
  }

  if(drawMode === 0 || drawMode === 2) {
     // get data draw calcpoint
    let mode = store.getState().app.diagram.processMode;
    if (mode >= MS_MODE_CALCULATION && isPointFaultTrans3(props)) {
        let temp = doSetPosCurvePointDirect(props.calcPoint0, 0);
        if(temp !== undefined) {
            graphLineData.lines.push(temp);
        }

        temp = doSetPosCurvePointDirect(props.calcPoint1, 1);
        if(temp !== undefined) {
            graphLineData.lines.push(temp)
        }
    }
  }

    return graphLineData
}

const doGetValueRatedCurrent = (dVoltage:number, dCapacity:number) => {  
  let dRatedCurrent = dVoltage <= 0 ? 0 : (dCapacity * 1000 ) / dVoltage / Math.sqrt(3);
    return dRatedCurrent;
  };

const doGetVauleInrushPointTimes = (m_dInrushTimes: number) => {
    let dTimes = 0;
    // FIXME: fix dTimes
    dTimes = m_dInrushTimes;
    return dTimes;

  }

const doMakeCurveInrushTrans3 = (m_listDrawInrush : Array<CdPoint>, properties : MsTrans3Model):boolean =>{
    const m_lVoltPrimary = properties.voltagePrimary;
    const m_dCapacity = properties.capacity;
    const m_dDampingTime = properties.dampingTime;
    const m_dInrushTimes = properties.inrushTimes;

    let dRatedCurrent = doGetValueRatedCurrent(m_lVoltPrimary, m_dCapacity) * m_lVoltPrimary;
    if(dRatedCurrent <= 0){
      return false;
    }

    const pdPoint : CdPoint = {
      m_dx: 0,
      m_dy: 0
  }
    let nCount = 0;
    for(let dTime = m_dDampingTime * 10;dTime >= 0.01;dTime -= (dTime/10)){
      // FIXME: proper DoGetVauleInrushPointTimes
      let dCurrent = dRatedCurrent * doGetVauleInrushPointTimes(m_dInrushTimes) * Math.exp(-1 * (dTime - 0.01) / (m_dDampingTime / 1000));
      if (dCurrent >= dRatedCurrent)
      {
        if (nCount == 0)
        {
          pdPoint.m_dx = dRatedCurrent;
          pdPoint.m_dy = dTime * 1000;  
          m_listDrawInrush.push({...pdPoint});
         
          pdPoint.m_dx = dRatedCurrent;
          pdPoint.m_dy = dTime + dTime / 5;
          m_listDrawInrush.push({...pdPoint});

          pdPoint.m_dx = dRatedCurrent + dRatedCurrent / 30;
          pdPoint.m_dy = dTime + dTime / 10;
          m_listDrawInrush.push({...pdPoint});

          nCount++;
          if (dCurrent <= pdPoint.m_dx)
          {
             
              pdPoint.m_dx = dRatedCurrent + dRatedCurrent / 10;
              pdPoint.m_dy = dTime;
              m_listDrawInrush.push({...pdPoint});
              continue;
          }
        }
        
        pdPoint.m_dx = dCurrent;
        pdPoint.m_dy = dTime;
        m_listDrawInrush.push({...pdPoint});

      }
    }
   
    pdPoint.m_dx = dRatedCurrent * doGetVauleInrushPointTimes(m_dInrushTimes);
    pdPoint.m_dy = 0.01;
    m_listDrawInrush.push({...pdPoint});

    
    pdPoint.m_dx = dRatedCurrent * doGetVauleInrushPointTimes(m_dInrushTimes) - dRatedCurrent * doGetVauleInrushPointTimes(m_dInrushTimes) / 100;
    pdPoint.m_dy = 0.0095;
    m_listDrawInrush.push({...pdPoint});

    pdPoint.m_dx = dRatedCurrent * doGetVauleInrushPointTimes(m_dInrushTimes) - dRatedCurrent * doGetVauleInrushPointTimes(m_dInrushTimes) / 30;
    pdPoint.m_dy = 0.009;
    m_listDrawInrush.push({...pdPoint});

    pdPoint.m_dx = dRatedCurrent * doGetVauleInrushPointTimes(m_dInrushTimes) - dRatedCurrent * doGetVauleInrushPointTimes(m_dInrushTimes) / 15;
    pdPoint.m_dy = 0.0087;
    m_listDrawInrush.push({...pdPoint});

    pdPoint.m_dx = dRatedCurrent * doGetVauleInrushPointTimes(m_dInrushTimes) - dRatedCurrent * doGetVauleInrushPointTimes(m_dInrushTimes) / 5;
    pdPoint.m_dy = 0.0082;
    m_listDrawInrush.push({...pdPoint});

    pdPoint.m_dx = dRatedCurrent / 10;
    pdPoint.m_dy = 0.005;
    m_listDrawInrush.push({...pdPoint});

    return true;
}

export const doGetTextIntensityValue = (m_lVoltSecondary:number, m_dCapacity:number,m_dIntensityTimes:number) =>{
    let dIntensityValue =0;
    let dRatedCurrent = doGetValueRatedCurrent(m_lVoltSecondary,m_dCapacity);
    if(dRatedCurrent ===0){
      dIntensityValue =0;
    }
    else{
      dIntensityValue =  Math.round(m_dIntensityTimes * dRatedCurrent);
    }
    return dIntensityValue;
}

const doMakeCurveIntensityTrans3 = (m_listDrawIntensity : Array<CdPoint>, properties : MsTrans3Model):boolean =>{
    const m_lVoltSecondary = properties.voltageSecondary;
    const m_dCapacity = properties.capacity;
    const m_dIntensityTimes = properties.intensityTimes;
    const m_dIntensityTime = properties.intensityTime;
    let dRatedCurrent = doGetValueRatedCurrent(m_lVoltSecondary, m_dCapacity) * m_lVoltSecondary;
    if (dRatedCurrent <= 0)
    {
        return false;
    }
    const pdPoint : CdPoint = {
      m_dx: 0,
      m_dy: 0
  }
   
    pdPoint.m_dx = dRatedCurrent * m_dIntensityTimes;
    pdPoint.m_dy = m_dIntensityTime;
    m_listDrawIntensity.push({...pdPoint})
    return true
}

const getMsTrans3Label =(properties : MsTrans3Model, infos : string[],dNominalVolt:number) =>{
  const text = properties.refNo;
  const name = properties.pointText;
  const m_dCapacity = properties.capacity;
  const m_lVoltPrimary = properties.voltagePrimary;
  const m_dDampingTime = properties.dampingTime;
  const m_dInrushTimes = properties.inrushTimes;

  infos.push(text);
  infos.push(name);
  infos.push(m_dCapacity+`kVA`);
  if(m_lVoltPrimary === dNominalVolt){
      let dRatedCurrent = Math.round(doGetValueRatedCurrent(m_lVoltPrimary,m_dCapacity));
      infos.push(dRatedCurrent.toString()+`A`);
  }
  else{
      let dRatedCurrent = doGetValueRatedCurrent(m_lVoltPrimary,m_dCapacity);
      dRatedCurrent = Math.round((dRatedCurrent * m_lVoltPrimary)/ dNominalVolt);
      infos.push(dRatedCurrent.toString()+`A`+` (`+ Math.round(doGetValueRatedCurrent(m_lVoltPrimary,m_dCapacity)) +`A)`);

  }
  
  infos.push(m_dInrushTimes +`倍,`+m_dDampingTime+`ms` )
}

const getMsTrans3Label2 =(properties : MsTrans3Model, infos : string[],dNominalVolt:number) =>{ 
  const text = properties.refNo;
  const name = properties.pointText
  const m_dCapacity = properties.capacity;
  const m_lVoltSecondary = properties.voltageSecondary;
  const m_dIntensityTimes = properties.intensityTimes;
  const m_dIntensityTime = properties.intensityTime;
  const intensityValue = doGetTextIntensityValue(m_lVoltSecondary,m_dCapacity,m_dIntensityTimes);
  infos.push(text);
  infos.push(name);
  infos.push(m_dCapacity.toString()+`kVA`);
  infos.push(m_dIntensityTimes.toString() + `倍,`);
  if(properties.voltageSecondary === dNominalVolt) {
      
      infos.push(intensityValue.toString()+`Arms`)
  }
  else{
    let dIntensityValue = 0;
    let dRatedCurrent = doGetValueRatedCurrent(properties.voltageSecondary,m_dCapacity);
    dIntensityValue = m_dIntensityTimes * dRatedCurrent;
    const values = Math.round(dIntensityValue *properties.voltageSecondary / dNominalVolt);
    infos.push(values.toString() +`Arms`);
    infos.push(`(` + intensityValue +`)`)
  }
  infos.push(m_dIntensityTime.toString()+`s`)
}

export {
    doDrawCurveTrans3,
    doGetValueRatedCurrent,
    getMsTrans3Label,
    getMsTrans3Label2,
}