import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { Controller, useForm } from "react-hook-form"
import theme from "../../themes/globalStyles"
import DraggablePaper from "../common/DraggablePaper"
import { GroupListModel } from "../../models/Index"
import { convertUndefinedToNull } from "../../utils/DataConverter"

export type GroupListProps = {
  data?: GroupListModel,
  onOK: (data: any) => void
  onCancel: () => void
}

export const GroupListDialog = (props: GroupListProps) => {
  const { onOK, onCancel, data } = props

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    mode: "all",
    criteriaMode: "all",
    defaultValues: data
  })

  // Submitイベント
  const handleEditSubmit = (value: any, event: any) => {
    const request = convertUndefinedToNull({ ...value })
    onOK(request)
  }

  return (
    <>
      <Dialog
        open={true}
        maxWidth={"sm"}
        fullWidth
        PaperComponent={DraggablePaper}
      >
        <DialogTitle
          onMouseOver={(e: any) => (e.target.style.cursor = "move")}
          style={{
            paddingTop: "2px",
            paddingBottom: "2px",
            background: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
          }}
        >
          機器グループの登録
        </DialogTitle>
        <DialogContent
          style={{
            paddingTop: "10px",
            height: "150px",
            display: "flex",
            alignItems: "flex-end",
          }}
        >
          <div style={{ width: "100%" }}>
            <form onSubmit={handleSubmit(handleEditSubmit)}>
              <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
                <Typography variant="body2" width="35%">
                  グループ名：
                </Typography>
                <Controller
                  name="groupName"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      type="text"
                      size="small"
                      required
                      InputProps={{ sx: { height: 32 } }}
                    />
                  )}
                />
              </Stack>
              <Stack sx={{ flexDirection: "row", alignItems: "center" }} mt={1}>
                <Typography variant="body2" width="35%">
                  説明：
                </Typography>
                <Controller
                  name="explan"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      type="text"
                      size="small"
                      InputProps={{ sx: { height: 32 } }}
                    />
                  )}
                />
              </Stack>
              <Stack
                direction="row"
                justifyContent="flex-end"
                gap={2}
                sx={{ mt: 4 }}
              >
                <Button variant="contained" type="submit">
                  追加
                </Button>
                <Button color="inherit" variant="contained" onClick={onCancel}>
                  キャンセル
                </Button>
              </Stack>
            </form>
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}
export default GroupListDialog
