import { Box, Button, Checkbox, CircularProgress, FormControlLabel, Grid, Stack, Tab, Tabs, TextField, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { EquipWireiModel, ListVoltlowModel } from '../../../models/breakModel'
import { connect } from 'react-redux'
import { ApplicationState, actionCreators, selectors } from '../../../store'
import { Controller, useForm } from 'react-hook-form'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Path as RoutePath } from "../../../path";
import FetchHelper from '../../FetchHelper'
import { LoadingButton } from '@mui/lab'
//-----------
//FETCH ID
const GET_LIST_VOLTLOW = "GET_LIST_VOLTLOW"
const UPDATE_LIST_VOLTLOW = "UPDATE_LIST_VOLTLOW"
const CREATE_LIST_VOLTLOW = "CREATE_LIST_VOLTLOW"

const initialValue: ListVoltlowModel = {
    lVoltageId: 0,
    bIsDefault: false,
    lVoltage: 0,
}
// -------------
// Props
export type ListVoltlowEditProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
const ListVoltlowEdit = (props: ListVoltlowEditProps) => {
    const {
        userId,
        onCreate,
        onCreateError,
        onUpdate, 
        onUpdateError,
        onSuccess, 
    } = props;

    const { control, formState: { errors }, handleSubmit, setValue, getValues, } = useForm<ListVoltlowModel>({
        criteriaMode: "all",
        defaultValues: initialValue,
        mode: "all",
    })
    type MyParams = {
        id: string;
        mode: string;
    };
    let { id, mode } = useParams<keyof MyParams>() as MyParams;
    const location = useLocation();
    let navigate = useNavigate();
    const [loadingFlag, setLoadingFlag] = useState(mode === "edit" ? true : false);
    const gridRef = useRef() as any;
    const Exp = /^[-+]?\d+(\.\d+)?$/;
    const messageIsNotNumber = "数字を入力してください。";

    const handleSuccess = (data: any) => {
        console.log('>>>> handle Successs');
        const obj = data.data;
        setValue("lVoltageId", obj.lVoltageId);
        setValue("bIsDefault", obj.bIsDefault);
        setValue("lVoltage", obj.lVoltage??"");
        setLoadingFlag(false);
    }

    // 検索エラー時
    const handleError = (success: boolean, data: any, error: any) => {
        console.log('>>>> handle Error');
        gridRef.current.hideOverlay();
    }

    // 検索実行
    const handleEditSubmit = (data: ListVoltlowModel) => {
        setLoadingFlag(true);
        if (mode === "edit") {
            let request = {
                lVoltageId: getValues("lVoltageId"),
                bIsDefault: getValues("bIsDefault") ?? null,
                lVoltage: getValues("lVoltage") ?? null
            } as any
            const object = {
                requestPrms: request,
                userId: userId,
            }
            onUpdate(object);
        } else if (mode === "add") {
            let request = {
                bIsDefault: getValues("bIsDefault") ?? null,
                lVoltage: getValues("lVoltage") ?? null,
            } as any
            const object = {
                requestPrms: request,
                userId: userId,
            }
            onCreate(object);
        }
    }

    // キャンセル
    const handleCancel = () => {
        if (location.key !== "default")
            navigate(-1);
        else
            navigate(RoutePath.ListVoltlowList);
    }

    // Submit後のAPI呼び出しエラー時
    const handleErrorUpdate = (success: boolean, data: any, error: any) => {
        onUpdateError();
        setLoadingFlag(false);
    }

    // Submit後のAPI呼び出し成功時
    const handleSuccessSubmit = (success: boolean, data: any, error: any) => {
        onSuccess();
        if (mode === "add") {
            navigate(RoutePath.ListVoltlowEdit + `/edit/${data.data.lVoltageId}`, { replace: true })
        }
        setLoadingFlag(false);
    }

    // Submit後のAPI呼び出しエラー時
    const handleErrorSubmit = (success: boolean, data: any, error: any) => {
        onCreateError();
        setLoadingFlag(false)
    }

    //入力欄背景
    const divStyles = {
        backgroundColor: 'white',
        border: '1px solid #bebebe',
        boxShadow: '0 0 5px #fff, 0 0 5px #bebebe, 0 0 1px #aaa',
        padding: '20px 40px',
        marginTop: '40px',
    }

    return (
        <>
            {
                mode === "edit" &&
                <FetchHelper
                    fetchId={GET_LIST_VOLTLOW}
                    url={`/master/list_voltlow/get`}
                    method="POST"
                    request={true}
                    params={{
                        requestPrms: {
                            id: id
                        },
                        userId: userId,
                    }}
                    json={true}
                    onComplete={(success, data, error) => {
                        success ? handleSuccess(data) : handleError(success, data, error);
                    }}
                />
            }
            {loadingFlag && (
                <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1500, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                    <CircularProgress />
                </div>
            )}
            <div style={{ height: "100%", width: "100%", marginTop: "10px" }}>
                <Stack direction="row" justifyContent="flex-start" alignItems="center" sx={{}} style={{ margin: '18px', color: 'MenuText' }}>
                    <Typography variant="h4">
                        低圧電圧詳細
                    </Typography>
                </Stack>
                <form onSubmit={handleSubmit(handleEditSubmit)}>
                        <div style={divStyles}>
                            <Typography variant="h6">
                            低圧電圧
                            </Typography>
                            <Stack mt={3} direction={{ xs: 'column', sm: 'row' }} sx={{ maxHeight: "calc(100vh)", overflow: 'auto' }}>
                                <Grid container spacing={3} direction={'row'}>
                                    <Grid item xs={6} mt={1} >
                                        <Controller
                                            control={control}
                                            name="lVoltage"
                                            rules={{
                                                pattern: {
                                                    value: Exp,
                                                    message: messageIsNotNumber,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.lVoltage}
                                                    helperText={errors?.lVoltage?.message}
                                                    type="text"
                                                    size="small"
                                                    label="低圧電圧値"
                                                // InputLabelProps={{ shrink: true }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6} mt={1} >
                                        <Controller
                                            control={control}
                                            name="bIsDefault"
                                            rules={{
                                                pattern: {
                                                    value: Exp,
                                                    message: messageIsNotNumber,
                                                }
                                            }}
                                            render={({ field }: any) => (
                                                <FormControlLabel {...field} control={<Checkbox />} checked={field.value} label="デフォルトの低圧電圧値" labelPlacement="start" />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Stack>
                        </div>
                        <Stack direction='row' justifyContent="flex-end" spacing={2} sx={{ mt: 1, mr: 2 }}>
                            <LoadingButton variant="contained" type="submit" loading={loadingFlag}>保存</LoadingButton>
                            <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                        </Stack>
                    {/* </TabPanel> */}
                </form>
            </div>
            <FetchHelper fetchId={UPDATE_LIST_VOLTLOW}
                onComplete={(success: boolean, data: any, error: any) =>
                    success ? handleSuccessSubmit(success, data, error) : handleErrorUpdate(success, data, error)} />

            <FetchHelper fetchId={CREATE_LIST_VOLTLOW}
                onComplete={(success, data, error) => success ?
                    handleSuccessSubmit(success, data, error) :
                    handleErrorSubmit(success, data, error)} />

        </>
    );
}
const mapStateToProps = (state: ApplicationState) => (
    {
        userId: state.app.user?.userId,
    }
)
const mapDispatchToProps = (dispatch: any) => {
    return {
        onCreate: (data: any) => dispatch(actionCreators.fetch(CREATE_LIST_VOLTLOW, `/master/list_voltlow/add`, "POST", data, false, true)),
        onUpdate: (data: any) => dispatch(actionCreators.fetch(UPDATE_LIST_VOLTLOW, `/master/list_voltlow/modify`, "POST", data, false, true)),
        onSuccess: () =>
            dispatch(actionCreators.showMessage({
                type: "info",
                title: "低圧電圧更新",
                body: "低圧電圧を更新しました。",
            })),
        onError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "検索",
                body: "検索できません。",
            })),
        onCreateError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "低圧電圧登録",
                body: "低圧電圧を登録出来ませんでした。",
            })),
        onUpdateError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "低圧電圧編集",
                body: "低圧電圧を更新出来ませんでした。",
            })),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ListVoltlowEdit as any) 