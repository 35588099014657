import { Button, ButtonGroup, Container, Dialog, DialogContent, DialogTitle, FormControlLabel, Grid, Radio, RadioGroup, Stack, TextField, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import theme from "../../themes/globalStyles";
import DraggablePaper from "../common/DraggablePaper";
import TextFieldOptions from "../common/TextFieldOptions";
import { InfoCircuit, OptionModel } from "../../models/Index";

const radioStyles = {
    padding: '0 0 0 9px',
}

export type SettingCircuitForm = {
    frequency: number;
    stdCapacity: number;
    voltage: number;
    voltageList: OptionModel[];
    electricPower: number;
    titleCorporation : string;
    corporation : string;
    titleProject : string;
    project : string;
    titleReference : string;
    reference : string;
}

export type SettingCircuitFormProps = {
  
    data: InfoCircuit;
    onOK: (data: any) => void;
    onCancel: () => void;
    modeViewOnly:boolean;
};

export const SettingCircuitDialog = (props: SettingCircuitFormProps) => {

    //#region fields
    const {data, onOK, onCancel,modeViewOnly } = props;

    // 初期値設定
    const initialValue : SettingCircuitForm = {
        ...data,
        voltageList: data.voltageList,
        // voltage: data.voltageList.length  > 0 && typeof(data.voltage) !=='string'? data.voltageList[0].value : data.voltage
    }
    const { control, handleSubmit, formState: { errors }, setValue, getValues } = useForm<SettingCircuitForm>({
        mode: "all",
        criteriaMode: "all",
        defaultValues: initialValue,
    })

    //#endregion fields
    //#region methods
    // Submitイベント
    const handleEditSubmit = (value: any, event: any) => {
        const request = createRequestData(value);
        onOK(request);
    }
    
    // FormデータからRequestデータを作成する
    const createRequestData = (formValue : SettingCircuitForm) => {       
        let request = {
            ...formValue,
        } as SettingCircuitForm;
        return request;
    }

    const handleReset = () => {
        setValue("titleCorporation", data.defaultTitleCorporation)
        setValue("titleProject", data.defaultTitleProject)
        setValue("titleReference", data.defaultTitleReference)
    }
    const handleChangeVoltage = (e:any) =>{
        setValue(e.target.name,e.target.value)
    }
    //#endregion methods

    return (
        <>
            <Dialog open={true} maxWidth={"sm"} fullWidth
                PaperComponent={
                    DraggablePaper
                }
            >
                <DialogTitle 
                    onMouseOver={(e : any) => e.target.style.cursor = 'move'}
                    style={{ paddingTop: '2px', paddingBottom: '2px', background: theme.palette.primary.main, color: theme.palette.primary.contrastText }}
                >
                    回路情報
                </DialogTitle>
                <DialogContent style={{paddingTop: '20px'}}>
                    <div style={{width: '100%'}}>
                        <form onSubmit={handleSubmit(handleEditSubmit)}>
                            <Grid container spacing={2} >
                                <Grid item md={6}>
                                    <fieldset style={{border:'1px solid #AAAA'}} >
                                        <legend><Typography variant="body2">周波数</Typography></legend>
                                        <Controller
                                                name="frequency"
                                                control={control}
                                                render={({ field }) => (
                                                    <RadioGroup
                                                        {...field}
                                                        sx={{justifyContent: 'space-evenly'}}
                                                        row
                                                    >
                                                        <FormControlLabel
                                                            value={50}
                                                            control={<Radio style={radioStyles} size="small" />}
                                                            label={<Typography variant="body2">50Hz</Typography>}
                                                            disabled={modeViewOnly}
                                                        />
                                                        <FormControlLabel
                                                            value={60}
                                                            control={<Radio style={radioStyles} size="small" />}
                                                            label={<Typography variant="body2">60Hz</Typography>}
                                                            disabled={modeViewOnly}
                                                        />
                                                    </RadioGroup>
                                                   
                                                )}
                                        />
                                    </fieldset>
                                </Grid>
                                <Grid item md={6} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                    <Typography variant="body2" width="60%">基準容量(kVA):</Typography>
                                    <Controller
                                        name="stdCapacity"
                                        control={control}
                                        rules={{
                                            required: '必須項目です。入力してください。' ,
                                            pattern:{
                                                value: /^[0-9\b]+$/,
                                                message: '数字を入力してください。'
                                            },
                                            validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                        }}
                                        render={({ field }) => (
                                            <TextField 
                                                {...field}
                                                fullWidth
                                                type="text"
                                                size="small"
                                                error={!!errors?.stdCapacity}
                                                helperText={errors?.stdCapacity?.message}
                                                InputProps={{ sx: { height: 32 } }}
                                                inputProps={{maxlength:9}}
                                                disabled={modeViewOnly}
                                            
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item md={12}>
                                    <fieldset style={{border:'1px solid #AAAA'}}>
                                        <legend><Typography variant="body2">高調波解析で使用</Typography></legend>
                                        <Grid container spacing={2}>
                                            <Grid item md={6} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                <Typography variant="body2" width="60%">受電電圧(V):</Typography>
                                                <Controller
                                                    name='voltage'
                                                    control={control}
                                                    rules={{
                                                        required: '必須項目です。入力してください。' ,
                                                        pattern:{
                                                            value: /^[0-9\b]+$/,
                                                            message: '数字を入力してください。'
                                                        },
                                                        validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                    }}
                                                    render={({ field }: any) => (
                                                        <TextFieldOptions
                                                            {...field}
                                                            options={initialValue.voltageList}
                                                            type='text'
                                                            size='small'
                                                            error={!!errors?.voltage}
                                                            helperText={errors?.voltage?.message}
                                                            height={32}
                                                            maxLength={6}
                                                            setValue={(name:any,value:any) => {
                                                                handleChangeVoltage({target:{name:name,value:value}})}
                                                            }
                                                            disabled={modeViewOnly}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item md={6} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                <Typography variant="body2" width="65%">契約電力 (kW):</Typography>
                                                <Controller
                                                    name="electricPower"
                                                    control={control}
                                                    rules={{
                                                        required: '必須項目です。入力してください。' ,
                                                        pattern:{
                                                            value: /^[0-9\b]+$/,
                                                            message: '数字を入力してください。'
                                                        },
                                                        validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type="text"
                                                            size="small"
                                                            error={!!errors?.electricPower}
                                                            helperText={errors?.electricPower?.message}
                                                            InputProps={{ sx: { height: 32 } }}
                                                            inputProps={{maxlength:6}}
                                                            disabled={modeViewOnly}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                    </fieldset>
                                </Grid>
                                <Grid item md={12}>
                                    <fieldset style={{border:'1px solid #AAAA'}}>
                                        <legend><Typography variant="body2">単線結線図/保護協調図/レポートの印刷で使用</Typography></legend>
                                        <Stack direction={'row'} gap={1} alignItems={'center'}>
                                                <Controller
                                                    name="titleCorporation"
                                                    control={control}
                                                    render={({ field }: any) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type="text"
                                                            size="small"
                                                            sx={{flex: 1}}
                                                            InputProps={{ sx: { height: 32 } }}
                                                            disabled={modeViewOnly}

                                                        />
                                                    )}
                                                />
                                              :
                                                <Controller
                                                    name="corporation"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type="text"
                                                            size="small"                                                            
                                                            sx={{flex: 3}}
                                                            InputProps={{ sx: { height: 32 } }}
                                                            disabled={modeViewOnly}
                                                        />
                                                    )}
                                                />
                                        </Stack>
                                        <Stack mt={1} direction={'row'} gap={1} alignItems={'center'}>
                                                <Controller
                                                    name="titleProject"
                                                    control={control}
                                                    render={({ field }: any) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type="text"
                                                            size="small"
                                                            sx={{flex: 1}}
                                                            InputProps={{ sx: { height: 32 } }}
                                                            disabled={modeViewOnly}

                                                        />
                                                    )}
                                                />
                                              :
                                                <Controller
                                                    name="project"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type="text"
                                                            size="small"
                                                            sx={{flex: 3}}
                                                            InputProps={{ sx: { height: 32 } }}
                                                            disabled={modeViewOnly}
                                                        />
                                                    )}
                                                />
                                        </Stack>
                                        <Stack mt={1} direction={'row'} gap={1} alignItems={'center'}>
                                                <Controller
                                                    name="titleReference"
                                                    control={control}
                                                    render={({ field }: any) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type="text"
                                                            size="small"
                                                            sx={{flex: 1}}
                                                            InputProps={{ sx: { height: 32 } }}
                                                            disabled={modeViewOnly}
                                                        />
                                                    )}
                                                />
                                              :
                                                <Controller
                                                    name="reference"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type="text"
                                                            size="small"
                                                            sx={{flex: 3}}
                                                            InputProps={{ sx: { height: 32 } }}
                                                            disabled={modeViewOnly}
                                                        />
                                                    )}
                                                />
                                        </Stack>
                                        <Stack alignItems={'flex-end'} mt={1}><Button size="small" color="inherit" variant="contained" onClick={handleReset} disabled={modeViewOnly}>リセット</Button></Stack>
                                    </fieldset>
                                </Grid>
                            </Grid>
                            
                            <Stack direction='row' justifyContent="flex-end" gap={2} sx={{ mt: 4 }}>
                                <Button variant="contained" type="submit" disabled={modeViewOnly}>OK</Button>
                                <Button color="inherit" variant="contained" onClick={onCancel}>キャンセル</Button>
                            </Stack>
                        </form>
                    </div>                    
                </DialogContent>
            </Dialog>
        </>
    )
}
export default SettingCircuitDialog;