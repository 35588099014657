import { CALC_IK2, CALC_IK3, DIRECT_NONE, LEFT_CENTER, TOP_CENTER } from "../models/Constants";
import { CalcPoint, MsWireModel, PercentZ, WirePhase, ControlModel, ProcessMode } from "../models/Index";
import { MS_MODE_CALCULATION, MS_MODE_CHECKING, MS_MODE_DRAWING, MS_MODE_HARMONIC, MS_MODE_PERCENT_Z, MS_MODE_POWER_FLOW, MS_MODE_VOLTDROP, MS_OUTSIDE_NEUTRAL, 
    MS_OUTSIDE_OUTSIDE, 
    MS_SEAT_MAIN, 
    MS_SEAT_T, 
    RS_3PHASES, 
    RS_PHASE_N, 
    RS_PHASE_PE, 
    VOLT_SIDE_LOW_MAX, 
    WIRE_AL, 
    WIRE_CU, 
    WIRE_METHOD_A, 
    WIRE_METHOD_B, 
    WIRE_METHOD_C, 
    WIRE_METHOD_D, 
    WIRE_METHOD_E, 
    WIRE_METHOD_F, 
    WIRE_METHOD_G, 
    WIRE_MULTI, 
    WIRE_PVC, 
    WIRE_RESISTIVITY_AL, 
    WIRE_RESISTIVITY_CU, 
    WIRE_SINGLE, 
    WIRE_SYMBOL_CABLE, 
    WIRE_SYMBOL_NORMAL, 
    WIRE_TEMP_COEFFICIENT_AL, 
    WIRE_TEMP_COEFFICIENT_CU, 
    WIRE_XLPE 
} from "../statics";
import {  
    RS_3PHASE,
    MS_TRANS1,  
    MS_TRANSSCOTT, 
    MS_VOLTDROP_MOTOR,
    MS_VOLTDROP_REGULAR,
    MS_3PHASES,
} from "../models/Constants";
import { BeamsNumber } from "./FormatNumber";
import { percentZDefault, doGetValuePerZk3 } from "./PercentZ";
import { store } from "..";
import { doGetValueDropVoltRegular as doGetValueDropVoltRegularCalcPoint,
        doSetCapacity,
        doSetVoltage,
        doGetIkasymDirectMG, 
        doGetIkpeakDirectMG,
        doGetIksymDirectMG,
        doGetIksymTotalMG,
        isSetDropPerMotor,
        doGetTextDropVoltMotor as doGetTextDropVoltMotorCalcPoint,
        doGetTextDropVoltMotor2 as doGetTextDropVoltMotor2CalcPoint,
        doGetTextDropPerMotor as doGetTextDropPerMotorCalcPoint,
        doGetTextDropVoltRegular as doGetTextDropVoltRegularCalcPoint,
        doGetTextDropPerRegular as doGetTextDropPerRegularCalcPoint,
        doGetPerZDirectMG,
        doGetIkpeakTotalMG,
        doGetIkasymTotalMG,
        doGetPerZTotalMG
} from "./calcPointFunction";
import { doGetTextVoltDropRegular } from "./mssv3Doc";
import { doGetLoadCurrentEndSide, doGetLoadCurrentStartSide, isCennectLoadDropElement, isCennectSourceDropElement } from "./element";


export const doSetDefaultWire = (sPhase:boolean,voltage:any,frequency:any,wireItem:MsWireModel,voltLowDefaultItem:any,equip_wireZList:any,equip_wireIList:any,equip_wireCList:any,newRefNo?:boolean,noFault?:any,) => {
    //TODO CalcPoint0, CalcPoint1
    // wireItem.calcPoint = new Array<CalcPoint>(2);
    wireItem.calcPoint0 = {} as CalcPoint;
    wireItem.calcPoint1 = {} as CalcPoint;

    // wireItem.lineActiveFlow = new Array<number>(2);
    // wireItem.lineReactiveFlow = new Array<number>(2);

    if(wireItem.setDefault==true){
        return voltage;
    } 
    wireItem.setDefault = true;
    // wireItem.sPhase = sPhase;
    
    if(voltage <= 0){
        wireItem.voltage = voltLowDefaultItem === null?0:voltLowDefaultItem.lvoltage;
    }else{
        doSetVoltageWire(voltage,wireItem);
    }
    wireItem.wireSystem = RS_3PHASES;
    wireItem.material = WIRE_CU;
    wireItem.insulation = WIRE_PVC;
    wireItem.core = WIRE_MULTI;
    wireItem.method = WIRE_METHOD_E;
    wireItem.circuitNumber = 1;
    // Chineseset
    wireItem.ambientTemp = 30;
    // end
    wireItem.length = 0;
    doSetDefault3Phases(frequency,wireItem,wireItem._3Phases,equip_wireZList,equip_wireIList,equip_wireCList);
    doSetDefault3Phases(frequency,wireItem,wireItem.PhaseN,equip_wireZList,equip_wireIList,equip_wireCList);
    doSetDefault3Phases(frequency,wireItem,wireItem.PhasePE,equip_wireZList,equip_wireIList,equip_wireCList);
    if(voltage > VOLT_SIDE_LOW_MAX){
        wireItem.exchangeNumber = WIRE_SYMBOL_NORMAL;
    }else{
        wireItem.exchangeNumber = WIRE_SYMBOL_CABLE;
    }
    // ChineseSET
    wireItem.dropRegular = true;
    wireItem.dropMotor = false;
    wireItem.viewFlowResult = true;

    const calcPoint0 = {
                        loadCapacity:0,loadCurrent:0,isLead:false,powerFactor:1,
                        perZDirect: percentZDefault(),
                        perZTotal: percentZDefault(),
                        perZDirectMG: percentZDefault(),
                        perZTotalMG: percentZDefault(),
                        perZMotor: percentZDefault(),
                    } as CalcPoint
    const calcPoint1 = {
                        loadCapacity:0,loadCurrent:0,isLead:false,powerFactor:1,
                        perZDirect: percentZDefault(),
                        perZTotal: percentZDefault(),
                        perZDirectMG: percentZDefault(),
                        perZTotalMG: percentZDefault(),
                        perZMotor: percentZDefault(),
                    } as CalcPoint
    doSetVoltage(wireItem.voltage,calcPoint0);
    calcPoint0.calcKind = CALC_IK3;
    calcPoint0.stdCapacity = 0;
    // calcPoint0.voltage = 0;

    doSetVoltage(wireItem.voltage,calcPoint1);
    calcPoint1.calcKind = CALC_IK2;
    calcPoint1.stdCapacity = 0;
    // calcPoint1.voltage = 0;


    wireItem.calcPoint0 = calcPoint0;
    wireItem.calcPoint1 = calcPoint1;

    wireItem.eleLineNumber = 0;
    wireItem.eleTopLeftNumber = 0;
    wireItem.eleBottomRightNumber = 0;
    wireItem.perUnitR =0;
    wireItem.perUnitX = 0;
    wireItem.perUnitK = 1;
    wireItem.lineActiveFlow1 = 0;
    wireItem.lineReactiveFlow1 = 0;
    wireItem.lineActiveFlow2 = 0;
    wireItem.lineReactiveFlow2 = 0;
    // wireItem.upTransKind = 0;
    return wireItem.voltage;
    
}
export const doSetVoltageWire = (voltage:any,wireItem:any) => {
    wireItem.voltage = voltage;
    wireItem.calcPoint0.voltage = voltage;
    wireItem.calcPoint1.voltage = voltage;
}

export const doSetDefault3Phases = (frequency:any,wireItem:MsWireModel,phaseItem:WirePhase,equip_wireZList:any,equip_wireIList:any,equip_wireCList:any) => {
    const wireZItemDefault = equip_wireZList.filter((item:any) => item.bisDefault == true)[0];
    // if(phaseItem.setDefault == true){
    //     return;
    // }
    phaseItem.number = 1;
    const limit = {limitMax:0,limitMin:0};
    doGetLimitSize3Phases(wireItem.material,wireItem.core,limit);
    phaseItem.coreSize = doSetDefaultWZ(wireZItemDefault,null);
    doCalcSetZValues(frequency,wireItem,phaseItem,0,0,0,equip_wireZList);
    doCalcSetIValues(wireItem,phaseItem.coreSize,phaseItem,phaseItem.number,equip_wireIList,equip_wireCList);
}

export const doGetLimitSize3Phases = (material:any,core:any,limit:any) => {
    switch(material){
        case WIRE_CU:
            switch(core){
                case WIRE_SINGLE:
                    limit.limitMin=1.5
                    limit.limitMax=2000
                    break;
                case WIRE_MULTI:
                    limit.limitMin=1.5
                    limit.limitMax=1000
                    break;
        }
        break;
        case WIRE_AL:
            switch(core){
                case WIRE_SINGLE:
                    limit.limitMin=2.5
                    limit.limitMax=2000
                    break;
                case WIRE_MULTI:
                    limit.limitMin=2.5
                    limit.limitMax=1000
                    break;
        }
        break;
    }
    return limit;
}

export const doGetLimitSizePhaseN = (material:any,coreSize_3Phases:any,object:any) => {
    switch(material){
        case WIRE_CU:
            if(coreSize_3Phases > 16){
                object.limitMin = coreSize_3Phases;
                object.limitMax = 2000;
            }
            else{
                object.limitMin = coreSize_3Phases;
                object.limitMax = coreSize_3Phases;
            }
            break;
        case WIRE_AL:
            if(coreSize_3Phases > 16){
                object.limitMin = coreSize_3Phases;
            }else{
                object.limitMin = coreSize_3Phases;
            }
            if(coreSize_3Phases > 25){
                object.limitMax = 2000;
            }else{
                object.limitMax = coreSize_3Phases;
            }
            break;
    }
} 

export const doGetLimitSizePhasePE = (sizePhase3:any,object:any) => {
    if(sizePhase3 <= 16){
        object.limitMin = sizePhase3;
        object.limitMax = sizePhase3;
    }else{
        object.limitMin = sizePhase3;
        object.limitMax = 2000;
    }
}

const doSetDefaultWZ = (WZItemDefault:any,object:any) => {
    if(WZItemDefault){
        if(WZItemDefault.dcoefficient&&object)
            object.dcoefficient = WZItemDefault.dcoefficient;
        if(WZItemDefault.dthickNess && object)
            object.dthickNess = WZItemDefault.dthickNess;
        if(WZItemDefault.doutsideDe && object)
            object.doutsideDe = WZItemDefault.doutsideDe;
        // object.dcoreSize = WZItemDefault.dcoreSize;
    }
    return WZItemDefault === undefined ? 0:WZItemDefault.dcoreSize;
}

export const doCalcSetZValues = (frequency:any,wireItem:MsWireModel,phaseItem:WirePhase,inThickNess:any,inDiaMeter:any,inDistance:any,equip_wireZList:any) => {
    const object = {} as any;
    const objectLimit = {limitMin:0,limitMax:0};
    doGetLimitSize3Phases(wireItem.material,wireItem.core,objectLimit);
    let wireZID = doReadByCoreSize(phaseItem.coreSize,objectLimit,object,equip_wireZList);
    if(wireZID <= 0){
        phaseItem.ohmRperperA = -1;
        phaseItem.ohmRperperM = -1;
        phaseItem.thickNessA = -1;
        phaseItem.thickNessM = -1;
        phaseItem.diameterA = -1;
        phaseItem.diameterM = -1;
        phaseItem.distanceA = -1;
        phaseItem.distanceM = -1;
        phaseItem.ohmXperperA = -1;
        phaseItem.ohmXperperM = -1;
        return;
    }
    let oldOhmRperperA = phaseItem.ohmRperperA;
    switch(wireItem.material){
        case WIRE_CU:
            phaseItem.ohmRperperA = doGetValueOhmRperCu(phaseItem.coreSize,wireItem.ambientTemp);
            break;
        case WIRE_AL:
            phaseItem.ohmRperperA = doGetValueOhmRperAl(phaseItem.coreSize,wireItem.ambientTemp);
            break;
    }
    if(oldOhmRperperA == phaseItem.ohmRperperM){
        phaseItem.ohmRperperM = phaseItem.ohmRperperA;
    }
    let oldThickNessA = phaseItem.thickNessA;
    let oldDiameterA = phaseItem.diameterA;
    let oldInDistanceA = phaseItem.distanceA;
    let dr = Math.sqrt(phaseItem.coreSize * object.dcoefficient / Math.PI);
    let thickT = object.doutsideDe /2 -dr;
    switch(wireItem.method){
        case WIRE_METHOD_A:
        case WIRE_METHOD_B:    
        case WIRE_METHOD_C:    
        case WIRE_METHOD_D:    
        case WIRE_METHOD_E:    
            if(inThickNess <= 0){
                phaseItem.thickNessA = object.dthickNess;
                if(oldThickNessA == phaseItem.thickNessM){
                    phaseItem.thickNessM = phaseItem.thickNessA;
                }
            }else{
                if(oldThickNessA == phaseItem.thickNessM){
                    phaseItem.thickNessM = inThickNess;
                }
            }
            if(inDiaMeter <= 0){
                phaseItem.diameterA = 2 * dr;
                if(oldDiameterA == phaseItem.diameterM){
                    phaseItem.diameterM = phaseItem.diameterA;
                }
            }else{
                if(oldDiameterA == phaseItem.diameterM){
                    phaseItem.diameterM = inDiaMeter;
                }
            }
            if(inDistance <= 0){
                phaseItem.distanceA = 2 * (dr + phaseItem.thickNessM);
                if(oldInDistanceA == phaseItem.distanceM){
                    phaseItem.distanceM = phaseItem.distanceA;
                }
            }else{
                if(oldInDistanceA == phaseItem.distanceM){
                    phaseItem.distanceM = inDistance;
                }
            }
            phaseItem.ohmXperperM = doGetValueOhmXper(frequency,phaseItem.diameterM,phaseItem.distanceM);
            phaseItem.ohmXperperA = phaseItem.ohmXperperM;
            break;
        case WIRE_METHOD_F:
            if(inThickNess <= 0){
                phaseItem.thickNessA = thickT;
                if(oldThickNessA == phaseItem.thickNessM){
                    phaseItem.thickNessM = phaseItem.thickNessA;
                }
            }else{
                if(oldThickNessA == phaseItem.thickNessM){
                    phaseItem.thickNessM = inThickNess;
                }
            }
            if(inDiaMeter <= 0){
                phaseItem.diameterA = 2 * dr;
                if(oldDiameterA == phaseItem.diameterM){
                    phaseItem.diameterM = phaseItem.diameterA;
                }
            }else{
                if(oldDiameterA == phaseItem.diameterM){
                    phaseItem.diameterM = inDiaMeter;
                }
            }
            if(inDistance <= 0){
                phaseItem.distanceA = 2 * (dr + phaseItem.thickNessM);
                if(oldInDistanceA == phaseItem.distanceM){
                    phaseItem.distanceM = phaseItem.distanceA;
                }
            }else{
                if(oldInDistanceA == phaseItem.distanceM){
                    phaseItem.distanceM = inDistance;
                }
            }
            phaseItem.ohmXperperM = doGetValueOhmXper(frequency,phaseItem.diameterM,phaseItem.distanceM);
            phaseItem.ohmXperperA = phaseItem.ohmXperperM;
            break;
        case WIRE_METHOD_G:
            if(inThickNess <= 0){
                phaseItem.thickNessA = thickT;
                if(oldThickNessA == phaseItem.thickNessM){
                    phaseItem.thickNessM = phaseItem.thickNessA;
                }
            }else{
                if(oldThickNessA == phaseItem.thickNessM){
                    phaseItem.thickNessM = inThickNess;
                }
            }
            if(inDiaMeter <= 0){
                phaseItem.diameterA = 2 * dr;
                if(oldDiameterA == phaseItem.diameterM){
                    phaseItem.diameterM = phaseItem.diameterA;
                }
            }else{
                if(oldDiameterA == phaseItem.diameterM){
                    phaseItem.diameterM = inDiaMeter;
                }
            }
            if(inDistance <= 0){
                phaseItem.distanceA = 6 * (dr + phaseItem.thickNessM);
                if(oldInDistanceA == phaseItem.distanceM){
                    phaseItem.distanceM = phaseItem.distanceA;
                }
            }else{
                if(oldInDistanceA == phaseItem.distanceM){
                    phaseItem.distanceM = inDistance;
                }
            }
            phaseItem.ohmXperperM = doGetValueOhmXper(frequency,phaseItem.diameterM,phaseItem.distanceM);
            phaseItem.ohmXperperA = phaseItem.ohmXperperM;
            break;
    }
}

// equipZList condition objectLimit
export const doReadByCoreSize = (coreSize:any,objectLimit:any,objectRead:any,equip_wireZList:any) => {
    if(coreSize < objectLimit.limitMin){
        return 0;
    }
    let wireZID = 0;
    if(equip_wireZList.length > 0){
        const newList = equip_wireZList.filter((item:any) => item.dcoreSize >= objectLimit.limitMin && item.dcoreSize <= objectLimit.limitMax);
        for(const item of newList){
            if(item.dcoefficient){
                objectRead.dcoefficient = item.dcoefficient;
            }
            if(item.dthickNess){
                objectRead.dthickNess = item.dthickNess;
            }
            if(item.doutsideDe){
                objectRead.doutsideDe = item.doutsideDe;
            }
            wireZID = item.lwireZID;
            if(coreSize <= item.dcoreSize){
                break;
            }
        }
    }
    return wireZID;
}

export const doGetTextOhmRperper = (wireSystem:number,_3Phases:WirePhase,PhaseN:WirePhase,PhasePE:WirePhase) => {
    let ohmRperper = 0;
    switch(wireSystem){
        case RS_3PHASES:
            ohmRperper = _3Phases.ohmRperperM;
            break;
        case RS_PHASE_N:
            ohmRperper = PhaseN.ohmRperperM;
            break;
        case RS_PHASE_PE:
            ohmRperper = PhasePE.ohmRperperM;
            break;
    }
    return ohmRperper;
}

const doGetValueOhmRperCu = (coreSize:any,ambientTemp:any) => {
    if(coreSize <= 0){
        return -1;
    }
    let ohmRper = (WIRE_RESISTIVITY_CU / coreSize) * (1 + WIRE_TEMP_COEFFICIENT_CU * (ambientTemp -20));
    return ohmRper;
}
const doGetValueOhmRperAl = (coreSize:any,ambientTemp:any) => {
    if(coreSize <= 0){
        return -1;
    }
    let ohmRper = (WIRE_RESISTIVITY_AL / coreSize) * (1 + WIRE_TEMP_COEFFICIENT_AL * (ambientTemp -20));
    return ohmRper;
}

export const doGetTextCapcity = (wirePhase:WirePhase,bUnit?:boolean) => {
    let capacity = 0;
    capacity = doGetValueCapacity(wirePhase.basicCapacityM,wirePhase.number,wirePhase.tempk2M,wirePhase.reductionFactorM);
    return BeamsNumber(capacity,4);
}

const doGetValueOhmXper = (frequency:any,diameter:any,distance:any) => {
    if(diameter == 0 || distance == 0){
        return -1;
    }
    let ohmXper = 2 * Math.PI * frequency * (0.05 + 0.4605 * Math.log10(distance/(diameter/2)))/1000;
    return ohmXper;
}

export const doCalcSetIValues = (wireItem:MsWireModel,coreSize:any,phaseItem:WirePhase,number:any,equip_wireIList:any,equip_wireCList:any) => {
    let A=0,m=0,B=0,n=0;
    const objectRead = {A:A,m:m,B:B,n:n};
    let objectLimit = {limitMin:0,limitMax:0};
    doGetLimitSize3Phases(wireItem.material,wireItem.core,objectLimit);
    const wireIID = doReadByValuesWI(wireItem.insulation,wireItem.method,wireItem.material,coreSize,objectLimit,objectRead,equip_wireIList)
    if(wireIID <= 0){
        phaseItem.basicCapacityA = -1;
        phaseItem.basicCapacityM = -1;
        phaseItem.tempk2A = -1;
        phaseItem.tempk2M = -1;
    }else{
        phaseItem.basicCapacityA = doGetValueBasicCapacity(coreSize,objectRead.A,objectRead.m,objectRead.B,objectRead.n);
        phaseItem.basicCapacityM = phaseItem.basicCapacityA;
        if(wireItem.method == WIRE_METHOD_D){
            phaseItem.tempk2A = doGetValueGroundTempk2(wireItem.insulation,wireItem.ambientTemp);
        }else{
            phaseItem.tempk2A = doGetValueAirTempk2(wireItem.insulation,wireItem.ambientTemp);
        }
        phaseItem.tempk2M = phaseItem.tempk2A;
    }
    const objectWireC = {factorA:0,factorB:0,factorC:0,factorD:0,factorE:0,factorF:0,factorG:0}
    switch(wireItem.method){
        case WIRE_METHOD_A:
            doReadByNumber(wireItem.circuitNumber,objectWireC,equip_wireCList);
            phaseItem.reductionFactorA = objectWireC.factorA;
            phaseItem.reductionFactorM = phaseItem.reductionFactorA;
            break;
        case WIRE_METHOD_B:
            doReadByNumber(wireItem.circuitNumber,objectWireC,equip_wireCList);
            phaseItem.reductionFactorA = objectWireC.factorB;
            phaseItem.reductionFactorM = phaseItem.reductionFactorA;
            break;
        case WIRE_METHOD_C:
            doReadByNumber(wireItem.circuitNumber,objectWireC,equip_wireCList);
            phaseItem.reductionFactorA = objectWireC.factorC;
            phaseItem.reductionFactorM = phaseItem.reductionFactorA;
            break;
        case WIRE_METHOD_D:
            doReadByNumber(wireItem.circuitNumber,objectWireC,equip_wireCList);
            phaseItem.reductionFactorA = objectWireC.factorD;
            phaseItem.reductionFactorM = phaseItem.reductionFactorA;
            break;
        case WIRE_METHOD_E:
            doReadByNumber(wireItem.circuitNumber,objectWireC,equip_wireCList);
            phaseItem.reductionFactorA = objectWireC.factorE;
            phaseItem.reductionFactorM = phaseItem.reductionFactorA;
            break;
        case WIRE_METHOD_F:
            doReadByNumber(wireItem.circuitNumber * number,objectWireC,equip_wireCList);
            phaseItem.reductionFactorA = objectWireC.factorF;
            phaseItem.reductionFactorM = phaseItem.reductionFactorA;
            break;
        case WIRE_METHOD_G:
            doReadByNumber(wireItem.circuitNumber * number,objectWireC,equip_wireCList);
            phaseItem.reductionFactorA = objectWireC.factorG;
            phaseItem.reductionFactorM = phaseItem.reductionFactorA;
            break;
    }
}

const doReadByValuesWI = (insulation:any,method:any,material:any,coreSize:any,objectLimit:any,objectRead:any,equip_wireIList:any) => {
    if(coreSize < objectLimit.limitMin){
        return 0;
    }
    let wireIID = 0;
    if(equip_wireIList.length > 0){
        const newList = equip_wireIList.filter((item:any)=> item.ninsulation == insulation && item.nmethod == method && item.nmaterial == material && item.dcoreSize >= objectLimit.limitMin && item.dcoreSize <= objectLimit.limitMax);
        for(const item of newList){
            objectRead.A = item.da;
            objectRead.m = item.dm;
            objectRead.B = item.db;
            objectRead.n = item.dn;
            wireIID = item.lwireIID;
            if(coreSize <= item.dcoreSize){
                break;
            }
        }
    }
    return wireIID;
}

const doGetValueBasicCapacity = (coreSize:any,A:any,m:any,B:any,n:any) => {
    return A * Math.pow(coreSize,m) - B * Math.pow(coreSize,n);
}

const doGetValueGroundTempk2 = (insulation:any,ambientTemp:any) =>{
    let tempk2 = 0;
    switch(insulation){
        case WIRE_PVC:
            tempk2 = Math.sqrt((70 - ambientTemp) / (70 - 20));
            break;
        case WIRE_XLPE:
            tempk2 = Math.sqrt((90 - ambientTemp)/(90 - 20));
            break;
    }
    return tempk2;
}

const doGetValueAirTempk2 = (insulation:any,ambientTemp:any) => {
    let tempk2 = 0;
    switch(insulation){
        case WIRE_PVC:
            tempk2 = Math.sqrt((70 - ambientTemp) / (70 - 30));
            break;
        case WIRE_XLPE:
            tempk2 = Math.sqrt((90 - ambientTemp)/(90 - 30));
            break;
    }
    return tempk2;
}

const doGetTextVoltDropPF = (partKind:number,wireItem:MsWireModel,bUnit?:any) => {
    let result;
    if(wireItem.sPhase === true){
        switch(partKind){
            case MS_OUTSIDE_NEUTRAL:
            case MS_SEAT_MAIN:
                result = BeamsNumber(wireItem.calcPoint1.powerFactor,5);
                // MISSING UNIT
                break;
            case MS_OUTSIDE_OUTSIDE:
            case MS_SEAT_T:
                result = BeamsNumber(wireItem.calcPoint0.powerFactor,5);
                // MISSING UNIT
                break;
        }
    }else{
        if(wireItem.downTransKind == MS_TRANS1){
            result = BeamsNumber(wireItem.calcPoint1.powerFactor,5);
            // MISSING UNIT
        }else{
            result = BeamsNumber(wireItem.calcPoint0.powerFactor,5);
        }
    }
}

export const doGetTextDropPerUnit = (capacity:any,partKind:any,wireItem:MsWireModel) => {
    const percentZ = doGetPerZrWire(wireItem, capacity, wireItem.wireSystem, partKind,false);
    let volt = 0;
    let percent = 0;
    if(wireItem.sPhase){
        switch(partKind){
            case MS_OUTSIDE_NEUTRAL:
                doSetCapacity(capacity,wireItem.calcPoint1);
                volt = doGetValueDropVoltRegularCalcPoint(wireItem.voltage/2,percentZ.perRk3,percentZ.perXk3,wireItem.calcPoint1);
                percent = 1 - volt / (wireItem.voltage/2);
                break;
            case MS_SEAT_MAIN:
                doSetCapacity(capacity,wireItem.calcPoint1);
                volt = doGetValueDropVoltRegularCalcPoint(wireItem.voltage,percentZ.perRk3,percentZ.perXk3,wireItem.calcPoint1);
                percent = 1 - volt / wireItem.voltage;
                break;
            case MS_OUTSIDE_OUTSIDE:
                doSetCapacity(capacity,wireItem.calcPoint0);
                volt = doGetValueDropVoltRegularCalcPoint(wireItem.voltage,percentZ.perRk3,percentZ.perXk3,wireItem.calcPoint0);
                percent = 1 - volt / (wireItem.voltage);
                break;
            case MS_SEAT_T:
                doSetCapacity(capacity,wireItem.calcPoint0);
                volt = doGetValueDropVoltRegularCalcPoint(wireItem.voltage,percentZ.perRk3,percentZ.perXk3,wireItem.calcPoint0);
                percent = 1 - volt / (wireItem.voltage);
                break;
        }
    }else{
        if(wireItem.downTransKind == MS_TRANS1){
            doSetCapacity(capacity,wireItem.calcPoint1);
            volt = doGetValueDropVoltRegularCalcPoint(wireItem.voltage,percentZ.perRk3,percentZ.perXk3,wireItem.calcPoint1);
        }else{
            doSetCapacity(capacity,wireItem.calcPoint0);
            volt = doGetValueDropVoltRegularCalcPoint(wireItem.voltage,percentZ.perRk3,percentZ.perXk3,wireItem.calcPoint0);
        }
        percent = 1 - volt / wireItem.voltage;
    }
    percent *= 100;
    return BeamsNumber(percent,4);
}

const doSetZValuesWire = (wireItem:MsWireModel) => {
    // if(basic.core == WIRE_MULTI){
    //     // disable Number
    // }else{
    //     // enable Number
    // }

}

//CEleWire::DoGetPerZrWire
const doGetPerZrWire = (props:MsWireModel, dStdCapacity:number, nWireSystem:number, nPartKind:number, bTwoWays:boolean = true) =>{
    const percentZ = {} as PercentZ;
    if(props.voltage <= 0){
        percentZ.perRk3 = 0;
        percentZ.perXk3 = 0;
    }else{
        switch(Number(nWireSystem)){
            case RS_3PHASES:
                if(props.sPhase){
                    switch(nPartKind){
                        case MS_OUTSIDE_NEUTRAL:
                            percentZ.perRk3 = dStdCapacity * props._3Phases.ohmRperperM * props.length / props._3Phases.number / (props.voltage / 2) / (props.voltage / 2) * 100;
                            percentZ.perXk3 = dStdCapacity * props._3Phases.ohmXperperM * props.length / props._3Phases.number / (props.voltage / 2) / (props.voltage / 2) * 100;
                            if(bTwoWays){
                                percentZ.perRk3 *= 2;
                                percentZ.perXk3 *= 2;
                            }
                            break;
                        case MS_OUTSIDE_OUTSIDE:
                            percentZ.perRk3 = dStdCapacity * props._3Phases.ohmRperperM * props.length / props._3Phases.number / (props.voltage ) / (props.voltage) * 100;
                            percentZ.perXk3 = dStdCapacity * props._3Phases.ohmXperperM * props.length / props._3Phases.number / (props.voltage ) / (props.voltage) * 100;
                            if(bTwoWays){
                                percentZ.perRk3 *= 2;
                                percentZ.perXk3 *= 2;
                            }
                            percentZ.perRk3Difference = dStdCapacity * props._3Phases.ohmRperperM * props.length / props._3Phases.number / (props.voltage / 2) / (props.voltage / 2) * 100;
                            percentZ.perXk3Difference = dStdCapacity * props._3Phases.ohmXperperM * props.length / props._3Phases.number / (props.voltage / 2) / (props.voltage / 2) * 100;
                            if(bTwoWays){
                                percentZ.perRk3Difference *= 2;
                                percentZ.perXk3Difference *= 2;
                            }
                            percentZ.perRk3Difference = percentZ.perRk3 - percentZ.perRk3Difference
                            percentZ.perXk3Difference *= percentZ.perXk3 - percentZ.perXk3Difference
                            break;
                        case MS_SEAT_MAIN:
                        case MS_SEAT_T:
                            percentZ.perRk3 = dStdCapacity * props._3Phases.ohmRperperM * props.length / props._3Phases.number / (props.voltage ) / (props.voltage) * 100;
                            percentZ.perXk3 = dStdCapacity * props._3Phases.ohmXperperM * props.length / props._3Phases.number / (props.voltage ) / (props.voltage) * 100;
                            if(bTwoWays){
                                percentZ.perRk3 *= 2;
                                percentZ.perXk3 *= 2;
                            }
                            break;
                    }
                }else{
                    percentZ.perRk3 = dStdCapacity * props._3Phases.ohmRperperM * props.length / props._3Phases.number / (props.voltage ) / (props.voltage) * 100;
                    percentZ.perXk3 = dStdCapacity * props._3Phases.ohmXperperM * props.length / props._3Phases.number / (props.voltage ) / (props.voltage) * 100;
                }
                break;
            case RS_PHASE_N:
                percentZ.perRk3 = dStdCapacity * props.PhaseN.ohmRperperM * props.length / props.PhaseN.number / (props.voltage ) / (props.voltage) * 100;
                percentZ.perXk3 = dStdCapacity * props.PhaseN.ohmXperperM * props.length / props.PhaseN.number / (props.voltage ) / (props.voltage) * 100;
                break;
            case RS_PHASE_PE:
                percentZ.perRk3 = dStdCapacity * props.PhasePE.ohmRperperM * props.length / props.PhasePE.number / (props.voltage ) / (props.voltage) * 100;
                percentZ.perXk3 = dStdCapacity * props.PhasePE.ohmXperperM * props.length / props.PhasePE.number / (props.voltage ) / (props.voltage) * 100;
                break;
        }
    }
    return percentZ;
}

export const BeamsValueWirePhase = (wirePhase:WirePhase) => {
    wirePhase.basicCapacityA = BeamsNumber(Number(wirePhase.basicCapacityA),4);
    wirePhase.basicCapacityM = BeamsNumber(Number(wirePhase.basicCapacityM),4);
    wirePhase.diameterA = BeamsNumber(Number(wirePhase.diameterA),4);
    wirePhase.diameterM = BeamsNumber(Number(wirePhase.diameterM),4);
    wirePhase.distanceA = BeamsNumber(Number(wirePhase.distanceA),4);
    wirePhase.distanceM = BeamsNumber(Number(wirePhase.distanceM),4);
    wirePhase.thickNessA = BeamsNumber(Number(wirePhase.thickNessA),4);
    wirePhase.thickNessM = BeamsNumber(Number(wirePhase.thickNessM),4);
    wirePhase.ohmRperperA = BeamsNumber(Number(wirePhase.ohmRperperA),4);
    wirePhase.ohmRperperM = BeamsNumber(Number(wirePhase.ohmRperperM),4);
    wirePhase.ohmXperperA = BeamsNumber(Number(wirePhase.ohmXperperA),4);
    wirePhase.ohmXperperM = BeamsNumber(Number(wirePhase.ohmXperperM),4);
}

// EQUIP_WIREC--------------METHOD
const doReadByNumber = (number:any,objectReturn:any,wireCList:any) => {
    let wireCID = 0;
    try{
        const newList = wireCList.filter((item:any) => item.ncircuitNumber == number);
        for(const item of newList){
            objectReturn.factorA = item.dfactora;
            objectReturn.factorB = item.dfactorb;
            objectReturn.factorC = item.dfactorc;
            objectReturn.factorD = item.dfactord;
            objectReturn.factorE = item.dfactore;
            objectReturn.factorF = item.dfactorf;
            objectReturn.factorG = item.dfactorg;
            wireCID = item.lwireCID;
        }
    }catch{

    } 
    return wireCID;
}

export const convertWirePhaseDataFromBE = (item:any) => {
    return {
            phaseKind : item.phase_kind,
            coreSize : item.core_size,
            number : item.number,
            ohmRperperA : item.ohm_r_per_per_a,             
            ohmRperperM : item.ohm_r_per_per_m,             
            diameterA : item.diameter_a,               
            diameterM : item.diameter_m,              
            thickNessA : item.thickness_a,             
            thickNessM : item.thickness_m,              
            distanceA : item.distance_a,              
            distanceM : item.distance_m,               
            ohmXperperA : item.ohm_x_per_per_a,              
            ohmXperperM : item.ohm_x_per_per_m,  
            basicCapacityA : item.basic_capacity_a,           
            basicCapacityM : item.basic_capacity_m,           
            tempk2A : item.tempk2_a,                
            tempk2M : item.tempk2_m,                
            reductionFactorA : item.reduction_factor_a,        
            reductionFactorM : item.reduction_factor_m       
    }
}

//CEleWire::DoGetValueDropVoltRegular
export const doGetValueDropVoltRegular = (props:MsWireModel, dStdCapacity:number, dStdVoltage:number, nPartKind:number) =>
{
	let percentZ = doGetPerZrWire(props, dStdCapacity, RS_3PHASES, nPartKind, false);
	let dVolt = 0;
	if (props.sPhase) {		
		switch (nPartKind) {
		case MS_OUTSIDE_NEUTRAL:	
		case MS_SEAT_MAIN:			
			dVolt = doGetValueDropVoltRegularCalcPoint(dStdVoltage, percentZ.perRk3, percentZ.perXk3, props.calcPoint1);
			break;
		case MS_OUTSIDE_OUTSIDE:	
		case MS_SEAT_T:				
			dVolt = doGetValueDropVoltRegularCalcPoint(dStdVoltage, percentZ.perRk3, percentZ.perXk3, props.calcPoint0);
			break;
		}
	}
	else {
		if (props.downTransKind == MS_TRANS1) {
			dVolt = doGetValueDropVoltRegularCalcPoint(dStdVoltage, percentZ.perRk3, percentZ.perXk3, props.calcPoint1);
		}
		else {
			dVolt = doGetValueDropVoltRegularCalcPoint(dStdVoltage, percentZ.perRk3, percentZ.perXk3, props.calcPoint0);
		}
	}
	return dVolt;
}

//CEleWire::DoGetTextLength
export const doGetTextLength = (props: MsWireModel, bUnit: boolean = true) => {
    let sText = BeamsNumber(props.length, 5).toString();
    if (bUnit) {
        sText += 'm';
    }
    return sText;
}

//CEleWire::DoGetTextMaterial
export const doGetTextMaterial = (props: MsWireModel) => {
    let sText = '';
    switch(props.material) {
        case WIRE_CU:
            sText = '銅';
            break;
        case WIRE_AL:
            sText = 'ｱﾙﾐﾆｳﾑ';
            break;
        }
    return sText;
}

//CEleWirePhase::DoGetValueCapacity
export const doGetValueCapacity = (dBasicCapacity:number, nNumber:number, dTempk2:number, dReductionFactor:number) => {
    let dCapacity = dBasicCapacity;
    if(dTempk2 > 0) {
        dCapacity *= dTempk2;
    }
    if(dReductionFactor > 0) {
        dCapacity *= nNumber;
        dCapacity *= dReductionFactor;
    }
    return dCapacity;
}

//CEleWire::DoGetTextCapacity
export const doGetTextCapacity = (props: MsWireModel, nWireSystem: number, bUnit: boolean = true) => {
    let dCapacity = 0;
    switch(nWireSystem) {
        case RS_3PHASE:
            dCapacity = doGetValueCapacity(props._3Phases.basicCapacityM, props._3Phases.number, props._3Phases.tempk2M, props._3Phases.reductionFactorM);
            break;
        case RS_PHASE_N:
            dCapacity = doGetValueCapacity(props.PhaseN.basicCapacityM, props.PhaseN.number, props.PhaseN.tempk2M, props.PhaseN.reductionFactorM);
            break;
        case RS_PHASE_PE:
            dCapacity = doGetValueCapacity(props.PhasePE.basicCapacityM, props.PhasePE.number, props.PhasePE.tempk2M, props.PhasePE.reductionFactorM);
            break;
    }
    let sText = BeamsNumber(dCapacity, 4, true).toString();
    if(bUnit && dCapacity >= 0) {
        sText += 'A';
    }
    return sText;
}

//CEleWire::DoGetTextSize
export const doGetTextSize = (props: MsWireModel, nWireSystem: number, nUnit: boolean = true) => {
   let sText;
   switch(nWireSystem) {
    case RS_3PHASE:
        sText = props._3Phases.coreSize.toString();
        if(nUnit)
        {
            sText += 'm㎡';
        }
        sText += '×';
        switch(props.core) {
            case WIRE_SINGLE:
                sText += props._3Phases.number;
                break;
            case WIRE_MULTI:
                sText += '3C';
        }
       break;
    case RS_PHASE_N:
        sText = props._3Phases.coreSize.toString();
        if(nUnit) 
        {
            sText += 'm㎡';
        }
        if(props.core == WIRE_SINGLE)
        {
            sText += '×';
            sText += props._3Phases.number;
        }
        break;
    case RS_PHASE_PE:
        sText = props.PhasePE.coreSize.toString() 
        if(nUnit)
        {
            sText += 'm㎡';
        }
        break;
    }
    return sText;
}

//CEleWire::DoGetValueOhmZ
export const doGetValueOhmZ = (dOhmR:number, dOhmX:number) => {
    let dOhmZ = Math.sqrt(dOhmR * dOhmR + dOhmX * dOhmX);
    return dOhmZ;
}

//CEleWire::DoGetTextOhmZ
export const doGetTextOhmZ = (props: MsWireModel, nWireSystem: number, bUnit: boolean = true) => {
    let dOhmZ = 0;
    switch(nWireSystem) {
        case RS_3PHASE:
            dOhmZ = doGetValueOhmZ(props._3Phases.ohmRperperM * props.length / props._3Phases.number, props._3Phases.ohmXperperM * props.length / props._3Phases.number);
           break;
        case RS_PHASE_N:
            dOhmZ = doGetValueOhmZ(props.PhaseN.ohmRperperM * props.length / props.PhaseN.number, props.PhaseN.ohmXperperM * props.length / props.PhaseN.number);
            break;
        case RS_PHASE_PE:
            dOhmZ = doGetValueOhmZ(props.PhasePE.ohmRperperM * props.length / props.PhasePE.number, props.PhasePE.ohmXperperM * props.length / props.PhasePE.number);
            break;
    }
    let sText = BeamsNumber(dOhmZ, 4, true).toString();
    if(bUnit && dOhmZ >= 0)
    {
        sText += 'mΩ';
    }
    return sText;
 }

 //CEleWire::DoGetTextOhmR
export const doGetTextOhmR = (props: MsWireModel, nWireSystem: number,  bUnit: boolean = true) => {
    let dOhmR = 0;
    switch(nWireSystem) {
        case RS_3PHASE:
            dOhmR = props._3Phases.ohmRperperM * props.length / props._3Phases.number;
            break;
        case RS_PHASE_N:
            dOhmR = props.PhaseN.ohmRperperM * props.length / props.PhaseN.number;
            break;
        case RS_PHASE_PE:
            dOhmR = props.PhasePE.ohmRperperM * props.length / props.PhasePE.number;
            break;
    }
    let sText = BeamsNumber(dOhmR, 4, true).toString();
    if(bUnit && dOhmR >= 0)
    {
        sText += 'mΩ';
    }
    return sText;
}

//CEleWire::DoGetTextOhmX
export const doGetTextOhmX = (props: MsWireModel, nWireSystem: number,  bUnit: boolean = true) => {
    let dOhmX = 0;
    switch(nWireSystem) {
        case RS_3PHASE:
            dOhmX = props._3Phases.ohmXperperM * props.length / props._3Phases.number;
           break;
        case RS_PHASE_N:
            dOhmX = props.PhaseN.ohmXperperM * props.length / props.PhaseN.number;
            break;
        case RS_PHASE_PE:
            dOhmX = props.PhasePE.ohmXperperM * props.length / props.PhasePE.number;
            break; 
    }
    let sText = BeamsNumber(dOhmX, 4, true).toString();
    if(bUnit && dOhmX >= 0)
    {
        sText += 'mΩ';
    }
    return sText;
}

//CEleWire::DoGetTextXR
export const doGetTextXR = (props: MsWireModel, nWireSystem: number) => {
    let dXR = 0;
    switch(nWireSystem) {
        case RS_3PHASE:
            dXR = props._3Phases.ohmXperperM / props._3Phases.ohmRperperM;
            break;
        case RS_PHASE_N:
            dXR = props.PhaseN.ohmXperperM / props.PhaseN.ohmRperperM;
            break;
        case RS_PHASE_PE:
            dXR = props.PhasePE.ohmXperperM / props.PhasePE.ohmRperperM;
            break;
    }
    let sText = BeamsNumber(dXR, 4).toString();
    return sText;
}

//CEleWire::DoGetTextPercentZr
export const doGetTextPercentZr = (props: MsWireModel, nWireSystem: number, nPartKind:number, dStdCapacity:number, bUnit:boolean = true) => {
    let percentZ = doGetPerZrWire(props, dStdCapacity, nWireSystem, nPartKind);
    let dPercentZ = doGetValuePerZk3(percentZ).dPercentZ;
    let sText = BeamsNumber(dPercentZ, 4, true).toString();
    if(bUnit && dPercentZ >= 0)
    {
        sText += '%r';
    }
    return sText;
}

//CEleWire::DoGetTextPercentRr
export const doGetTextPercentRr = (props: MsWireModel, nWireSystem: number, nPartKind:number, dStdCapacity:number, bUnit:boolean = true) => {
    let percentZ = doGetPerZrWire(props, dStdCapacity, nWireSystem, nPartKind);
    let sText = BeamsNumber(percentZ.perRk3, 4, true).toString();
    if (bUnit && percentZ.perRk3 >= 0) {
        sText += '%r';
    }
    return sText;
}

//CEleWire::DoGetTextPercentXr
export const doGetTextPercentXr = (props: MsWireModel, nWireSystem: number, nPartKind:number, dStdCapacity:number, bUnit:boolean = true) => {
    let percentZ = doGetPerZrWire(props, dStdCapacity, nWireSystem, nPartKind);
    let sText = BeamsNumber(percentZ.perXk3, 4, true).toString();
    if (bUnit && percentZ.perXk3 >= 0) {
        sText += '%r';
    }
    return sText;
}

//CEleWire::IsPointFaultWire
export const isPointFaultWire = (props: MsWireModel) => {
    if (props.fault) {
        return true;
    }
    return false;
}

//CEleWire::DoGetTextDirectIkpeak
export const doGetTextDirectIkpeak = (props:MsWireModel, nPartKind:number, bUnit: boolean = true ) => {
    let dIpeakDirect = 0;
    if(props.sPhase)
    {
        switch(nPartKind) {
            case MS_OUTSIDE_NEUTRAL:
            case MS_SEAT_MAIN:
                dIpeakDirect = doGetIkpeakDirectMG(props.calcPoint1);
                break;
            case MS_OUTSIDE_OUTSIDE:
            case MS_SEAT_T:
                dIpeakDirect = doGetIkpeakDirectMG(props.calcPoint0);
                break;
        }
    }
    else
    {
        if(props.downTransKind == MS_TRANS1) {
            dIpeakDirect = doGetIkpeakDirectMG(props.calcPoint1);
        }
        else {
            dIpeakDirect = doGetIkpeakDirectMG(props.calcPoint0);
        }

    }
    let sText;
    if (dIpeakDirect == 0) {
       if(props.primarySupply) {
        sText = '∞';
       } 
       else {
        sText = '0';
       }
    }
    else if(dIpeakDirect > 0) {
        sText = BeamsNumber(dIpeakDirect / 1000, 4).toString();
        if(bUnit)
        {
            sText += 'kApeak';
        }
    }
    else {
        sText = '????';
    }
    return sText;
}

//CEleWire::DoGetTextDirectIkasym
export const doGetTextDirectIkasym = (props:MsWireModel, nPartKind:number, bUnit: boolean = true ) => {
    let dIasymDirect = 0;
    if(props.sPhase) {
        switch(nPartKind) {
            case MS_OUTSIDE_NEUTRAL:
            case MS_SEAT_MAIN:
                dIasymDirect = doGetIkasymDirectMG(props.calcPoint1);
                break;
            case MS_OUTSIDE_OUTSIDE:
            case MS_SEAT_T:
                dIasymDirect = doGetIkasymDirectMG(props.calcPoint0);
                break;
        }
    }
    else {
        if(props.downTransKind == MS_TRANS1)
        {
            dIasymDirect = doGetIkasymDirectMG(props.calcPoint1);
        }
        else
        {
            dIasymDirect = doGetIkasymDirectMG(props.calcPoint0);
        }
    }
    let sText;
    if (dIasymDirect == 0) {
       if(props.primarySupply) {
        sText = '∞';
       }
       else {
        sText = '0';
       }
    }
    else if(dIasymDirect > 0) {
        sText = BeamsNumber(dIasymDirect / 1000, 4).toString();
        if(bUnit) {
            sText += 'kAasym';
        }
    }
    else {
        sText = '????';
    }
    return sText;
}

//CEleWire::DoGetTextDirectIksym
export const doGetTextDirectIksym = (props:MsWireModel, nPartKind:number, bUnit: boolean = true ) => {
    let dIsymDirect = 0;
    if(props.sPhase) {
        switch(nPartKind) {
            case MS_OUTSIDE_NEUTRAL:
            case MS_SEAT_MAIN:
                dIsymDirect = doGetIksymDirectMG(props.calcPoint1);
                break;
            case MS_OUTSIDE_OUTSIDE:
            case MS_SEAT_T:
                dIsymDirect = doGetIksymDirectMG(props.calcPoint0);
                break;
        }
    }
    else {
        if(props.downTransKind === MS_TRANS1) {
            dIsymDirect = doGetIksymDirectMG(props.calcPoint1);
        }
        else {
            dIsymDirect = doGetIksymDirectMG(props.calcPoint0);
        }
    }
    let sText;
    if (dIsymDirect == 0) {
        if(props.primarySupply) {
            sText = '∞';
        }
        else {
         sText = '0';
        }
    }
    else if(dIsymDirect > 0) {
        sText = BeamsNumber(dIsymDirect / 1000, 4).toString();
        if(bUnit) {
            sText += 'kAsym';
        }
    }
    else {
        sText = '????';
    }
    return sText;
}

//CEleWire::DoGetTextTotalIk3sym
export const doGetTextTotalIk3sym = (props:MsWireModel, bUnit: boolean = true ) => {
    let dIsymTotal = 0;
    if(props.downTransKind == MS_TRANS1) {
        dIsymTotal = doGetIksymTotalMG(props.calcPoint1);
    }
    else {
        dIsymTotal = doGetIksymTotalMG(props.calcPoint0);
    }
    let sText;
    if (dIsymTotal == 0) {
        if(props.primarySupply) {
            sText = '∞';
        }
        else {
         sText = '0';
        }
    }
    else if(dIsymTotal > 0) {
        sText = BeamsNumber(dIsymTotal / 1000, 4).toString();
        if(bUnit) {
            sText += 'kAsym';
        }
    }
    else {
        sText = '????';
    }
    return sText;
}

//CEleWire::DoGetTextDropVoltMotor
export const doGetTextDropVoltMotor = (props:MsWireModel, bUnit: boolean = true) => {
    return doGetTextDropVoltMotorCalcPoint(props.calcPoint0, bUnit)
}

//CEleWire::DoGetTextDropVoltMotor2
export const doGetTextDropVoltMotor2 = (props:MsWireModel, bUnit: boolean = true) => {
    return doGetTextDropVoltMotor2CalcPoint(props.calcPoint0, bUnit);
}

//CEleWire::DoGetTextDropPerMotor
export const doGetTextDropPerMotor = (props:MsWireModel, bUnit: boolean = true) => {
    return doGetTextDropPerMotorCalcPoint(props.calcPoint0, bUnit)
}

//CEleWire::DoGetTextVoltage
export const doGetTextVoltage = (props:MsWireModel, nPartKind:number, bUnit:boolean = true) => {
	let sText = '';
	if (props.sPhase) {
		switch (nPartKind) {
		case MS_OUTSIDE_NEUTRAL:
			sText = (props.voltage / 2).toString();
			break;
		case MS_OUTSIDE_OUTSIDE:
			sText = props.voltage.toString();
			break;
		case MS_SEAT_MAIN:
			sText = props.voltage.toString();
			break;
		case MS_SEAT_T:
			sText = props.voltage.toString();
			break;
		}
	}
	else {
		sText = props.voltage.toString();
	}
	if (bUnit) {
		sText += 'V';
	}
	return sText;
}

//CEleWire::DoGetTextLoadCurrent
export const doGetTextLoadCurrent = (props:MsWireModel, nPartKind:number, bUnit: boolean = true) => {
    let sText = '';
    if(props.sPhase) {
        switch(nPartKind) {
            case MS_OUTSIDE_NEUTRAL:
            case MS_SEAT_MAIN:
                sText = BeamsNumber(props.calcPoint1.loadCurrent, 5).toString();
                break;
            case MS_OUTSIDE_OUTSIDE:
            case MS_SEAT_T:
                sText = BeamsNumber(props.calcPoint0.loadCurrent, 5).toString();
                break;
        }
    }
    else {
        if(props.downTransKind == MS_TRANS1) {
            sText = BeamsNumber(props.calcPoint1.loadCurrent, 5).toString();
        }
        else {
            sText = BeamsNumber(props.calcPoint0.loadCurrent, 5).toString();
        }
    }
    if(bUnit) {
        sText += 'A';
    }
    return sText;
}

//CEleWire::DoGetTextPointState
export const doGetTextPointState = (props:MsWireModel) =>
{
	let sText = ''; 

	if (props.fault) {
		sText = 'Yes';
	}
	else {
		sText = 'No';
	}

	return sText;
}

//CEleWire::DoGetTextDropVoltPF
export const doGetTextDropVoltPF = (props:MsWireModel, nPartKind:number, bUnit: boolean = true) => {
    let sText = '';
    if(props.sPhase) {
        switch(nPartKind) {
            case MS_OUTSIDE_NEUTRAL:
            case MS_SEAT_MAIN:
                sText = BeamsNumber(props.calcPoint1.powerFactor, 5).toString();
                if(bUnit) {
                    if(props.calcPoint1.isLead) {
                        sText+= '(進み)';
                    }
                    else {
                        sText += '(遅れ)';
                    }
                }
                break;
            case MS_OUTSIDE_OUTSIDE:
            case MS_SEAT_T:
                sText = BeamsNumber(props.calcPoint0.powerFactor, 5).toString();
                if(bUnit) {
                    if(props.calcPoint0.isLead) {
                        sText+= '(進み)';
                    }
                    else {
                        sText += '(遅れ)';
                    }
                }
                break;
        }
    }
    else {
        if(props.downTransKind == MS_TRANS1) {
            sText = BeamsNumber(props.calcPoint1.powerFactor, 5).toString();
            if(bUnit) {
                if(props.calcPoint1.isLead) {
                    sText+= '(進み)';
                }
                else {
                    sText += '(遅れ)';
                }
            }
        }
        else {
            sText = BeamsNumber(props.calcPoint0.powerFactor, 5).toString();
            if(bUnit) {
                if(props.calcPoint0.isLead) {
                    sText+= '(進み)';
                }
                else {
                    sText += '(遅れ)';
                }
            }
        }
    }
    return sText;
}

//CEleWire::DoGetTextDropVoltDiff
export const doGetTextDropVoltDiff = (props:MsWireModel, dStdCapacity: number, dStdVoltage: number, nPartKind:number, bUnit: boolean = true) => {
    let dVolt = 0;
    let percentZ = doGetPerZrWire(props, dStdCapacity, RS_3PHASE, nPartKind, false);

    if(props.sPhase) {
        switch(nPartKind) {
            case MS_OUTSIDE_NEUTRAL:
            case MS_SEAT_MAIN:
                dVolt = doGetValueDropVoltRegularCalcPoint(dStdVoltage, percentZ.perRk3, percentZ.perXk3, props.calcPoint1);
                break;
            case MS_OUTSIDE_OUTSIDE:
            case MS_SEAT_T:
                dVolt = doGetValueDropVoltRegularCalcPoint(dStdVoltage, percentZ.perRk3, percentZ.perXk3, props.calcPoint0);
                break;
        }
        dVolt = dStdVoltage - dVolt;
    }
    else {
        if(props.downTransKind == MS_TRANS1) {
            dVolt = doGetValueDropVoltRegularCalcPoint(dStdVoltage, percentZ.perRk3, percentZ.perXk3, props.calcPoint1);
        }
        else {
            dVolt = doGetValueDropVoltRegularCalcPoint(dStdVoltage, percentZ.perRk3, percentZ.perXk3, props.calcPoint0);
        }
        dVolt = dStdVoltage - dVolt;
    }

    let sText = BeamsNumber(dVolt, 4).toString();
    if(bUnit) {
        sText += 'V';
    }
    return sText;
}

//CEleWire::DoGetTextDropVoltRegular
export const doGetTextDropVoltRegular = (props:MsWireModel, dStdCapacity: number, dStdVoltage:number, nPartKind:number, bUnit: boolean = true) => {
    let percentZ = doGetPerZrWire(props, dStdCapacity, RS_3PHASE, nPartKind, false);
    let sText = '';
    if(props.sPhase) {
        switch(nPartKind) {
            case MS_OUTSIDE_NEUTRAL:
            case MS_SEAT_MAIN:
                sText = doGetTextDropVoltRegularCalcPoint(props.calcPoint1, dStdVoltage, percentZ.perRk3, percentZ.perXk3, bUnit);
                break;
            case MS_OUTSIDE_OUTSIDE:
            case MS_SEAT_T:
                sText = doGetTextDropVoltRegularCalcPoint(props.calcPoint0, dStdVoltage, percentZ.perRk3, percentZ.perXk3, bUnit);
                break;
        }
    } 
    else {
        if(props.downTransKind == MS_TRANS1) {
            sText = doGetTextDropVoltRegularCalcPoint(props.calcPoint1, dStdVoltage, percentZ.perRk3, percentZ.perXk3, bUnit);
        }
        else {
            sText = doGetTextDropVoltRegularCalcPoint(props.calcPoint0, dStdVoltage, percentZ.perRk3, percentZ.perXk3, bUnit);
        }
    }
    return sText;
}

//CEleWire::DoGetTextDropPerRegular
export const doGetTextDropPerRegular = (props:MsWireModel, dStdCapacity: number, dStdVoltage:number, nPartKind:number, bUnit: boolean = true) => {
    let sText = '';
    let percentZ = doGetPerZrWire(props, dStdCapacity, RS_3PHASE, nPartKind, false);
    if(props.sPhase) {
        switch(nPartKind) {
            case MS_OUTSIDE_NEUTRAL:
            case MS_SEAT_MAIN:
                sText = doGetTextDropPerRegularCalcPoint(props.calcPoint1, dStdVoltage, percentZ.perRk3, percentZ.perXk3, bUnit);
                break;
            case MS_OUTSIDE_OUTSIDE:
            case MS_SEAT_T:
                sText = doGetTextDropPerRegularCalcPoint(props.calcPoint0, dStdVoltage, percentZ.perRk3, percentZ.perXk3, bUnit);
                break;
        }
    }
    else {
        if(props.downTransKind == MS_TRANS1) {
            sText = doGetTextDropPerRegularCalcPoint(props.calcPoint1, dStdVoltage, percentZ.perRk3, percentZ.perXk3, bUnit);
        }
        else {
            sText = doGetTextDropPerRegularCalcPoint(props.calcPoint0, dStdVoltage, percentZ.perRk3, percentZ.perXk3, bUnit);
        }
    }
    return sText;
}

//CEleWire::DoGetRefV1
export const doGetRefV1 = (mode: any, control:ControlModel, bSociety:boolean) =>
{
    let props = control.properties as MsWireModel;
    let infoCircuit = store.getState().app.diagram.infoCircuit;
    let project = store.getState().app.projectData;

    //CEleWire::DoGetRefV1Left  sRefV1Left
    let labelTexts = [{
        value: control.properties.refNo,
        color: "#f800f8",
    }];
  
    if (!props.viewResultText || (mode != ProcessMode.DRAWING && !props.supply))
    {
      return labelTexts;
    }
    
    if (bSociety){
        return labelTexts;
    }

    switch(mode) {
        case ProcessMode.DRAWING:
        case ProcessMode.CHECKING:
            
            //Length / Material
            labelTexts.push({
                value: doGetTextLength(props)
                    + ','
                    + doGetTextMaterial(props)
                    ,
                color: "blue",
            });

            //Capacity
            labelTexts.push({
                value: doGetTextCapacity(props, RS_3PHASES),
                color: "blue",
            });

            //Size 
            labelTexts.push({
                value: doGetTextSize(props, RS_3PHASE),
                color: "blue",
            });
            if(props.voltage > 0)
            {
                //OhmZ / OhmR / OhmX
                labelTexts.push({
                    value: doGetTextOhmZ(props, RS_3PHASE)
                    + '='
                    + doGetTextOhmR(props, RS_3PHASE, false)
                    + '+j'
                    + doGetTextOhmX(props, RS_3PHASE, false),
                    color: "blue",
                });
            }       
            break;
        case ProcessMode.PERCENT_Z:

            if(props.sPhase)
            {
                switch(props.upTransKind) {
                    case MS_TRANS1:
                        //XR
                        labelTexts.push({
                            value: 'X/R=' 
                            + doGetTextXR(props,RS_3PHASE),
                            color: "blue",
                        });

                        //OUTSIDE_OUTSIDE
                        labelTexts.push({
                            value: '外線-外線',
                            color: "blue",
                        });
                        
                        //Zr = Rr + jXr
                        labelTexts.push({
                            value: doGetTextPercentZr(props, RS_3PHASE, MS_OUTSIDE_OUTSIDE, infoCircuit.stdCapacity)
                            + '='
                            + doGetTextPercentRr(props, RS_3PHASE, MS_OUTSIDE_OUTSIDE, infoCircuit.stdCapacity, false)
                            + '+j'
                            + doGetTextPercentXr(props, RS_3PHASE, MS_OUTSIDE_OUTSIDE, infoCircuit.stdCapacity, false)
                            ,
                            color: "blue",
                        });

                        //OUTSIDE_NEUTRAL
                        labelTexts.push({
                            value: '外線-中性線',
                            color: "blue",
                        });

                         //Zr = Rr + jXr
                         labelTexts.push({
                            value: doGetTextPercentZr(props, RS_3PHASE, MS_OUTSIDE_NEUTRAL, infoCircuit.stdCapacity)
                            + '='
                            + doGetTextPercentRr(props, RS_3PHASE, MS_OUTSIDE_NEUTRAL, infoCircuit.stdCapacity, false)
                            + '+j'
                            + doGetTextPercentXr(props, RS_3PHASE, MS_OUTSIDE_NEUTRAL, infoCircuit.stdCapacity, false)
                            ,
                            color: "blue",
                        });
                        break;
                    case MS_TRANSSCOTT:
                         //XR
                         labelTexts.push({
                            value: 'X/R='
                            + doGetTextXR(props, RS_3PHASE),
                            color: "blue",
                        });

                        //SEAT_MAIN
                        labelTexts.push({
                            value: '主座',
                            color: "blue",
                        });

                        //Zr = Rr + jXr
                        labelTexts.push({
                            value: doGetTextPercentZr(props, RS_3PHASE, MS_SEAT_MAIN, infoCircuit.stdCapacity)
                            + '='
                            + doGetTextPercentRr(props, RS_3PHASE, MS_SEAT_MAIN, infoCircuit.stdCapacity, false)
                            + '+j'
                            + doGetTextPercentXr(props, RS_3PHASE, MS_SEAT_MAIN, infoCircuit.stdCapacity, false)
                            ,
                            color: "blue",
                        });

                        //SEAT_T
                        labelTexts.push({
                            value: 'T座',
                            color: "blue",
                        });

                         //Zr = Rr + jXr
                         labelTexts.push({
                            value: doGetTextPercentZr(props, RS_3PHASE, MS_SEAT_T, infoCircuit.stdCapacity)
                            + '='
                            + doGetTextPercentRr(props, RS_3PHASE, MS_SEAT_T, infoCircuit.stdCapacity, false)
                            + '+j'
                            + doGetTextPercentXr(props, RS_3PHASE, MS_SEAT_T, infoCircuit.stdCapacity, false)
                            ,
                            color: "blue",
                        });
                        break;
                }
            }
            else {
                 //XR
                 labelTexts.push({
                    value: 'X/R='
                    + doGetTextXR(props, RS_3PHASE),
                    color: "blue",
                });

                //Zr = Rr + jXr
                labelTexts.push({
                    value: doGetTextPercentZr(props, RS_3PHASE, MS_3PHASES, infoCircuit.stdCapacity)
                    + '='
                    + doGetTextPercentRr(props, RS_3PHASE, MS_3PHASES, infoCircuit.stdCapacity, false)
                    + '+j'
                    + doGetTextPercentXr(props, RS_3PHASE, MS_3PHASES, infoCircuit.stdCapacity, false)
                    ,
                    color: "blue",
                });
            }
            break;
        case ProcessMode.CALCULATION:

            if(!isPointFaultWire(props)) {
                break;
            }

            if(props.sPhase) {
                switch(props.upTransKind) {
                    case MS_TRANS1:
                        //OUTSIDE_OUTSIDE
                        labelTexts.push({
                            value: '外線-外線',
                            color: "blue",
                        });

                        // += 'I"k2' + '通過' + '=' + DoGetTextDirectIkpeak()
                        labelTexts.push({
                            value: 'I"k2通過='
                            + doGetTextDirectIkpeak(props, MS_OUTSIDE_OUTSIDE),
                            color: "blue",
                        });

                        // += 'I"k2' + '通過' + '=' + DoGetTextDirectIkasym()
                        labelTexts.push({
                            value: 'I"k2通過='
                            + doGetTextDirectIkasym(props, MS_OUTSIDE_OUTSIDE),
                            color: "blue",
                        });

                         // += 'I"k2' + '通過' + '=' + DoGetTextDirectIksym()
                        labelTexts.push({
                            value: 'I"k2通過='
                            + doGetTextDirectIksym(props, MS_OUTSIDE_OUTSIDE),
                            color: "blue",
                        });
                        
                         //OUTSIDE_NEUTRAL
                         labelTexts.push({
                            value: '外線-中性線',
                            color: "blue",
                        });

                        // += 'I"k2' + '通過' + '=' + DoGetTextDirectIkpeak()
                        labelTexts.push({
                            value: 'I"k2通過='
                            + doGetTextDirectIkpeak(props, MS_OUTSIDE_NEUTRAL),
                            color: "blue",
                        });

                        // += 'I"k2' + '通過' + '=' + DoGetTextDirectIkasym()
                        labelTexts.push({
                            value: 'I"k2通過='
                            + doGetTextDirectIkasym(props, MS_OUTSIDE_NEUTRAL),
                            color: "blue",
                        });

                         // += 'I"k2' + '通過' + '=' + DoGetTextDirectIksym()
                        labelTexts.push({
                            value: 'I"k2通過='
                            + doGetTextDirectIksym(props, MS_OUTSIDE_NEUTRAL),
                            color: "blue",
                        });
                        break;
                    case MS_TRANSSCOTT:
                         //SEAT_MAIN
                         labelTexts.push({
                            value: '主座',
                            color: "blue",
                        });

                        // += 'I"k2' + '通過' + '=' + DoGetTextDirectIkpeak()
                        labelTexts.push({
                            value: 'I"k2通過='
                            + doGetTextDirectIkpeak(props, MS_SEAT_MAIN),
                            color: "blue",
                        });

                        // += 'I"k2' + '通過' + '=' + DoGetTextDirectIkasym()
                        labelTexts.push({
                            value: 'I"k2通過='
                            + doGetTextDirectIkasym(props, MS_SEAT_MAIN),
                            color: "blue",
                        });

                         // += 'I"k2' + '通過' + '=' + DoGetTextDirectIksym()
                        labelTexts.push({
                            value: 'I"k2通過='
                            + doGetTextDirectIksym(props, MS_SEAT_MAIN),
                            color: "blue",
                        });

                        //SEAT_T
                        labelTexts.push({
                            value: 'T座',
                            color: "blue",
                        });

                        // += 'I"k2' + '通過' + '=' + DoGetTextDirectIkpeak()
                        labelTexts.push({
                            value: 'I"k2通過='
                            + doGetTextDirectIkpeak(props, MS_SEAT_T),
                            color: "blue",
                        });

                        // += 'I"k2' + '通過' + '=' + DoGetTextDirectIkasym()
                        labelTexts.push({
                            value: 'I"k2通過='
                            + doGetTextDirectIkasym(props, MS_SEAT_T),
                            color: "blue",
                        });

                        // += 'I"k2' + '通過' + '=' + DoGetTextDirectIksym()
                        labelTexts.push({
                            value: 'I"k2通過='
                            + doGetTextDirectIksym(props, MS_SEAT_T),
                            color: "blue",
                        });
                        break;
                }
            }
            else {
                if(props.downTransKind === MS_TRANS1) {
                     // += 'I"k2' + '通過' + '=' + DoGetTextDirectIkpeak()
                     labelTexts.push({
                        value: 'I"k2通過='
                        + doGetTextDirectIkpeak(props, MS_3PHASES),
                        color: "blue",
                    });

                    // += 'I"k2' + '通過' + '=' + DoGetTextDirectIkasym()
                    labelTexts.push({
                        value: 'I"k2通過='
                        + doGetTextDirectIkasym(props, MS_3PHASES),
                        color: "blue",
                    });

                    // += 'I"k2' + '通過' + '=' + DoGetTextDirectIksym()
                    labelTexts.push({
                        value: 'I"k2通過='
                        + doGetTextDirectIksym(props, MS_3PHASES),
                        color: "blue",
                    });

                    // += 'I"k2' + '合計' + '=' + DoGetTextDirectIksym()
                    labelTexts.push({
                        value: 'I"k2合計='
                        + doGetTextTotalIk3sym(props),
                        color: "blue",
                    });
                }
                else {
                    // += 'I"k3' + '通過' + '=' + DoGetTextDirectIkpeak()
                    labelTexts.push({
                        value: 'I"k3通過='
                        + doGetTextDirectIkpeak(props, MS_3PHASES),
                        color: "blue",
                    });

                    // += 'I"k3' + '通過' + '=' + DoGetTextDirectIkasym()
                    labelTexts.push({
                        value: 'I"k3通過='
                        + doGetTextDirectIkasym(props, MS_3PHASES),
                        color: "blue",
                    });

                    // += 'I"k3' + '通過' + '=' + DoGetTextDirectIksym()
                    labelTexts.push({
                        value: 'I"k3通過='
                        + doGetTextDirectIksym(props, MS_3PHASES),
                        color: "blue",
                    });

                    // += 'I"k3' + '合計' + '=' + DoGetTextDirectIksym()
                    labelTexts.push({
                        value: 'I"k3合計='
                        + doGetTextTotalIk3sym(props),
                        color: "blue",
                    });
                }
            }
            break;
        case ProcessMode.VOLTDROP:

            if(project.dropMode == MS_VOLTDROP_MOTOR && props.dropMotor && !props.sPhase && isSetDropPerMotor(props.calcPoint0)) {
                // Vd = DropVoltMotor(VoltSecondary - DropVoltMotor2)
                let sTemp = 'Vd=' + doGetTextDropVoltMotor(props) + ' (';
                if (props.supply ) {
                    sTemp += doGetTextVoltage(props, MS_3PHASES, false);
                    sTemp += '-';
                    sTemp += doGetTextDropVoltMotor2(props, false);
                } else {
                    sTemp += '0-0'
                }
                sTemp += ')';
                labelTexts.push({
                    value: sTemp,
                    color: "blue",
                });

                //Vp = DoGetTextDropPerMotor (VoltSecondary → DropVoltMotor2)
                sTemp = 'Vp=' + doGetTextDropPerMotor(props) + ' (';
                if (props.supply) {
                    sTemp += doGetTextVoltage(props, MS_3PHASES, false);
                    sTemp += '→';
                    sTemp += doGetTextDropVoltMotor2(props, false);
                } else {
                    sTemp += '0→0';
                }
                sTemp += ')';
                labelTexts.push({
                    value: sTemp,
                    color: "blue",
                });
                break;
            }
            if(isCennectSourceDropElement(control, doGetLoadCurrentStartSide(control)) 
            && isCennectLoadDropElement(control, doGetLoadCurrentEndSide(control))
            && project.dropMode == MS_VOLTDROP_REGULAR && props.dropRegular) {
                let stdVoltage = {
                    dStdVoltage : 0,
                    sStdVoltage : '0'
                };
                if(props.sPhase) {
                    switch(props.upTransKind) {
                        case MS_TRANS1:
                            if(props.supply) {
                               stdVoltage = doGetTextVoltDropRegular(control, MS_OUTSIDE_OUTSIDE, false);
                            }

                             //OUTSIDE_OUTSIDE
                            labelTexts.push({
                                value: '外線-外線',
                                color: "blue",
                            });

                             //IL=DoGetTextLoadCurrent,力率=DoGetTextDropVoltPF
                            labelTexts.push({
                                value: 'IL='
                                + doGetTextLoadCurrent(props, MS_OUTSIDE_OUTSIDE)
                                + ',力率='
                                + doGetTextDropVoltPF(props, MS_OUTSIDE_OUTSIDE),
                                color: "blue",
                            });

                            //Vd=DoGetTextDropVoltDiff (sStdVoltage-DoGetTextDropVoltRegular)
                            labelTexts.push({
                                value: 'Vd='
                                + doGetTextDropVoltDiff(props, infoCircuit.stdCapacity, props.voltage, MS_OUTSIDE_OUTSIDE)
                                + ' ('
                                + stdVoltage.sStdVoltage
                                + '-'
                                + doGetTextDropVoltRegular(props, infoCircuit.stdCapacity, stdVoltage.dStdVoltage, MS_OUTSIDE_OUTSIDE, false)
                                + ')',
                                color: "blue",
                            });

                            //Vp=DoGetTextDropPerRegular (DoGetTextVoltage→doGetTextDropVoltRegular)
                            let sTemp = 'Vp=';
                            sTemp += doGetTextDropPerRegular(props, infoCircuit.stdCapacity, stdVoltage.dStdVoltage, MS_OUTSIDE_OUTSIDE);
                            sTemp += ' (';
                            if(props.supply) {
                                sTemp += doGetTextVoltage(props, MS_OUTSIDE_OUTSIDE, false);
                            } 
                            else {
                                sTemp += stdVoltage.sStdVoltage;
                            }
                            sTemp += '→';
                            sTemp += doGetTextDropVoltRegular(props, infoCircuit.stdCapacity, stdVoltage.dStdVoltage, MS_OUTSIDE_OUTSIDE, false);
                            sTemp += ')';
                            labelTexts.push({
                                value: sTemp,
                                color: "blue",
                            });
                            
                            if(props.supply) {
                                stdVoltage = doGetTextVoltDropRegular(control, MS_OUTSIDE_NEUTRAL, false);
                            }

                            //OUTSIDE_NEUTRAL
                            labelTexts.push({
                                value: '外線-中性線',
                                color: "blue",
                            });

                            //IL=DoGetTextLoadCurrent,力率=DoGetTextDropVoltPF
                            labelTexts.push({
                                value: 'IL='
                                + doGetTextLoadCurrent(props, MS_OUTSIDE_NEUTRAL)
                                + ',力率='
                                + doGetTextDropVoltPF(props, MS_OUTSIDE_NEUTRAL),
                                color: "blue",
                            });

                             //Vd=DoGetTextDropVoltDiff (sStdVoltage-DoGetTextDropVoltRegular)
                             labelTexts.push({
                                value: 'Vd='
                                + doGetTextDropVoltDiff(props, infoCircuit.stdCapacity, props.voltage / 2, MS_OUTSIDE_NEUTRAL)
                                + ' ('
                                + stdVoltage.sStdVoltage
                                + '-'
                                + doGetTextDropVoltRegular(props, infoCircuit.stdCapacity, stdVoltage.dStdVoltage, MS_OUTSIDE_NEUTRAL, false)
                                + ')',
                                color: "blue",
                            });

                            //Vp=DoGetTextDropPerRegular (DoGetTextVoltage→DoGetTextDropVoltRegular)
                            sTemp = 'Vp=';
                            sTemp += doGetTextDropPerRegular(props, infoCircuit.stdCapacity, stdVoltage.dStdVoltage, MS_OUTSIDE_NEUTRAL);
                            sTemp += ' (';
                            if(props.supply) {
                                sTemp += doGetTextVoltage(props, MS_OUTSIDE_NEUTRAL, false);
                            }
                            else {
                                sTemp += stdVoltage.sStdVoltage;
                            }
                            sTemp += '→';
                            sTemp += doGetTextDropVoltRegular(props, infoCircuit.stdCapacity, stdVoltage.dStdVoltage, MS_OUTSIDE_NEUTRAL, false);
                            sTemp += ')';
                            labelTexts.push({
                                value: sTemp,
                                color: "blue",
                            });
                            break;
                        case MS_TRANSSCOTT:
                            if(props.supply) {
                                stdVoltage = doGetTextVoltDropRegular(control, MS_SEAT_MAIN, false);
                            }
                            
                            //SEAT_MAIN
                            labelTexts.push({
                                value: '主座',
                                color: "blue",
                            });

                            //IL=DoGetTextLoadCurrent,力率=DoGetTextDropVoltPF
                            labelTexts.push({
                                value: 'IL='
                                + doGetTextLoadCurrent(props, MS_SEAT_MAIN)
                                + ',力率='
                                + doGetTextDropVoltPF(props, MS_SEAT_MAIN),
                                color: "blue",
                            });

                            //Vd=DoGetTextDropVoltDiff (sStdVoltage-DoGetTextDropVoltRegular)
                            labelTexts.push({
                                value: 'Vd='
                                + doGetTextDropVoltDiff(props, infoCircuit.stdCapacity, props.voltage, MS_SEAT_MAIN)
                                + ' ('
                                + stdVoltage.sStdVoltage
                                + '-'
                                + doGetTextDropVoltRegular(props, infoCircuit.stdCapacity, stdVoltage.dStdVoltage, MS_SEAT_MAIN, false)
                                + ')',
                                color: "blue",
                            });

                            //Vp=DoGetTextDropPerRegular (DoGetTextVoltage→DoGetTextDropVoltRegular)
                            sTemp = 'Vp=';
                            sTemp += doGetTextDropPerRegular(props, infoCircuit.stdCapacity, stdVoltage.dStdVoltage, MS_SEAT_MAIN);
                            sTemp += ' (';
                            if(props.supply) {
                                sTemp += doGetTextVoltage(props, MS_SEAT_MAIN, false);
                            }
                            else {
                                sTemp += stdVoltage.sStdVoltage;
                            }
                            sTemp += '→';
                            sTemp += doGetTextDropVoltRegular(props, infoCircuit.stdCapacity, stdVoltage.dStdVoltage, MS_SEAT_MAIN, false);
                            sTemp += ')';
                            labelTexts.push({
                                value: sTemp,
                                color: "blue",
                            });

                            if(props.supply) {
                                stdVoltage = doGetTextVoltDropRegular(control, MS_SEAT_T, false);
                            }

                            //SEAT_T
                            labelTexts.push({
                                value: 'T座',
                                color: "blue",
                            });

                            //IL=DoGetTextLoadCurrent,力率=DoGetTextDropVoltPF
                            labelTexts.push({
                                value: 'IL='
                                + doGetTextLoadCurrent(props, MS_SEAT_T)
                                + ',力率='
                                + doGetTextDropVoltPF(props, MS_SEAT_T),
                                color: "blue",
                            });

                            //Vd=DoGetTextDropVoltDiff (sStdVoltage-DoGetTextDropVoltRegular)
                            labelTexts.push({
                                value: 'Vd='
                                + doGetTextDropVoltDiff(props, infoCircuit.stdCapacity, props.voltage, MS_SEAT_T)
                                + ' ('
                                + stdVoltage.sStdVoltage
                                + '-'
                                + doGetTextDropVoltRegular(props, infoCircuit.stdCapacity, stdVoltage.dStdVoltage, MS_SEAT_T, false)
                                + ')',
                                color: "blue",
                            });

                            //Vp=DoGetTextDropPerRegular (DoGetTextVoltage→DoGetTextDropVoltRegular)
                            sTemp = 'Vp=';
                            sTemp += doGetTextDropPerRegular(props, infoCircuit.stdCapacity, stdVoltage.dStdVoltage, MS_SEAT_T);
                            sTemp += ' (';
                            if(props.supply) {
                                sTemp += doGetTextVoltage(props, MS_SEAT_T, false);
                            }
                            else {
                                sTemp += stdVoltage.sStdVoltage;
                            }
                            sTemp += '→';
                            sTemp += doGetTextDropVoltRegular(props, infoCircuit.stdCapacity, stdVoltage.dStdVoltage, MS_SEAT_T, false);
                            sTemp += ')';
                            labelTexts.push({
                                value: sTemp,
                                color: "blue",
                            });
                            break;
                    }
                }
                else {
                    if(props.supply) {
                        stdVoltage = doGetTextVoltDropRegular(control, MS_3PHASES, false);
                    }

                    //IL=DoGetTextLoadCurrent,力率=DoGetTextDropVoltPF
                    labelTexts.push({
                        value: 'IL='
                        + doGetTextLoadCurrent(props, MS_3PHASES)
                        + ',力率='
                        + doGetTextDropVoltPF(props, MS_3PHASES),
                        color: "blue",
                    });

                    //Vd=DoGetTextDropVoltDiff (sStdVoltage-DoGetTextDropVoltRegular)
                    labelTexts.push({
                        value: 'Vd='
                        + doGetTextDropVoltDiff(props, infoCircuit.stdCapacity, props.voltage, MS_3PHASES)
                        + ' ('
                        + stdVoltage.sStdVoltage
                        + '-'
                        + doGetTextDropVoltRegular(props, infoCircuit.stdCapacity, stdVoltage.dStdVoltage, MS_3PHASES, false)
                        + ')',
                        color: "blue",
                    });

                    //Vp=DoGetTextDropPerRegular (DoGetTextVoltage→DoGetTextDropVoltRegular)
                    let sTemp = 'Vp=';
                    sTemp += doGetTextDropPerRegular(props, infoCircuit.stdCapacity, stdVoltage.dStdVoltage, MS_3PHASES);
                    sTemp += ' (';
                    if(props.supply) {
                        sTemp += doGetTextVoltage(props, MS_3PHASES, false);
                    }
                    else {
                        sTemp += stdVoltage.sStdVoltage;
                    }
                    sTemp += '→';
                    sTemp += doGetTextDropVoltRegular(props, infoCircuit.stdCapacity, stdVoltage.dStdVoltage, MS_3PHASES, false);
                    sTemp += ')';
                    labelTexts.push({
                        value: sTemp,
                        color: "blue",
                    });
                    break;
                }
            }
            break;
        case ProcessMode.HARMONIC:
          break;
        case ProcessMode.POWER_FLOW:
          break;
      }
    
      return labelTexts;
}

//CEleWire::DoGetTextDirectPercentZr
export const doGetTextDirectPercentZr = (props:ControlModel, nPartKind:number, bUnit:boolean = true) =>
{
	let dPerZ = 0;
	if (props.properties.sPhase) {		// 単相回路のとき
		switch (nPartKind) {
		case MS_OUTSIDE_NEUTRAL:	// 外線－中性線間
		case MS_SEAT_MAIN:			// 主座変圧器
			dPerZ = doGetPerZDirectMG(props.properties.calcPoint1).dPercentZ;
			break;
		case MS_OUTSIDE_OUTSIDE:	// 外線間
		case MS_SEAT_T:				// Ｔ座変圧器
			dPerZ = doGetPerZDirectMG(props.properties.calcPoint0).dPercentZ;
			break;
		}
	}
	else {							// 三相回路のとき
		if (props.properties.downTransKind == MS_TRANS1) {
			dPerZ = doGetPerZDirectMG(props.properties.calcPoint1).dPercentZ;
		}
		else {
			dPerZ = doGetPerZDirectMG(props.properties.calcPoint0).dPercentZ;
		}
	}

	let sText = '';
	if (dPerZ >= 0) {
		sText = BeamsNumber(dPerZ, 4).toString();
		if (bUnit) {
			sText += '%r';
		}
	}
	else {							// 短絡電流が正しく計算されなかったとき
		sText = '????';
	}
	return sText;
}

//CEleWire::DoGetTextTotalIk3peak
export const doGetTextTotalIk3peak = (props:MsWireModel, bUnit:boolean = true) =>
{
	let dIpeakTotal = 0;
	if (props.downTransKind == MS_TRANS1) {
		dIpeakTotal = doGetIkpeakTotalMG(props.calcPoint1);
	}
	else {
		dIpeakTotal = doGetIkpeakTotalMG(props.calcPoint0);
	}
	let sText = '';
	if (dIpeakTotal == 0) {
		if (props.primarySupply) {
			sText = '∞';
		}
		else {
			sText = "0";
		}
	}
	else if (dIpeakTotal > 0) {
		sText = BeamsNumber(dIpeakTotal / 1000, 4).toString();
		if (bUnit) {
			sText += 'kApeak';
		}
	}
	else {							// 短絡電流が正しく計算されなかったとき
		sText = '????';
	}
	return sText;
}

//CEleWire::DoGetTextTotalIk3asym
export const doGetTextTotalIk3asym = (props:MsWireModel, bUnit:boolean = true) =>
{
	let dIasymTotal = 0;
	if (props.downTransKind == MS_TRANS1) {
		dIasymTotal = doGetIkasymTotalMG(props.calcPoint1);
	}
	else {
		dIasymTotal = doGetIkasymTotalMG(props.calcPoint0);
	}
	let sText = '';
	if (dIasymTotal == 0) {
		if (props.primarySupply) {
			sText = '∞';
		}
		else {
			sText = "0";
		}
	}
	else if (dIasymTotal > 0) {
		sText = BeamsNumber(dIasymTotal / 1000, 4).toString();
		if (bUnit) {
			sText += 'kAasym';
		}
	}
	else {							// 短絡電流が正しく計算されなかったとき
		sText = '????';
	}
	return sText;
}

//CEleWire::DoGetTextTotalPercentZr
export const doGetTextTotalPercentZr = (props:MsWireModel, bUnit:boolean = true) =>
{
	let dPerZ = 0;
	if (props.downTransKind == MS_TRANS1) {
		dPerZ = doGetPerZTotalMG(props.calcPoint1).dPercentZ;
	}
	else {
		dPerZ = doGetPerZTotalMG(props.calcPoint0).dPercentZ;
	}
	let sText = '';
	if (dPerZ >= 0) {
		sText = BeamsNumber(dPerZ, 4).toString();
		if (bUnit) {
			sText += '%r';
		}
	}
	else {							// 短絡電流が正しく計算されなかったとき
		sText = '????';
	}
	return sText;
}

//CEleWire::DoGetTextActiveLeft
export const doGetTextActiveLeft = (props:MsWireModel, nDirect:number, bUnit:boolean = true) =>
{
	let sText = '';
	if (nDirect == DIRECT_NONE) {
		if (props.lineActiveFlow1 >= 0) {
			sText = BeamsNumber(props.lineActiveFlow1, 5).toString();
		}
		else {
			sText = BeamsNumber(props.lineActiveFlow2, 5).toString();
		}
	}
	else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
		sText = BeamsNumber(Math.abs(props.lineActiveFlow1), 5).toString();
	}
	else {
		sText = BeamsNumber(Math.abs(props.lineActiveFlow2), 5).toString();
	}

	if (bUnit) {
		sText += 'kW';
	}

	return sText;
}

// CEleWire::DoGetTextReactiveLeft
export const doGetTextReactiveLeft = (props:MsWireModel, nDirect:number, bUnit:boolean = true) =>
{
	let sText = '';
	if (nDirect == DIRECT_NONE) {
		if (props.lineReactiveFlow1 >= 0) {
			sText = BeamsNumber(props.lineReactiveFlow1, 5).toString();
		}
		else {
			sText = BeamsNumber(props.lineReactiveFlow2, 5).toString();
		}
	}
	else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
		sText = BeamsNumber(Math.abs(props.lineReactiveFlow1), 5).toString();
	}
	else {
		sText = BeamsNumber(Math.abs(props.lineReactiveFlow2), 5).toString();
	}

	if (bUnit) {
		sText += 'kvar';
	}

	return sText;
}

//CEleWire::DoGetTextFlowsResult
export const doGetTextFlowsResult = (props:MsWireModel, processMode:any, nDirect:number, bSociety:boolean = false) =>
{
	let sRefV1:string[] = [];

	if (bSociety) {
		return sRefV1;
	}

	switch (processMode) {
	case MS_MODE_DRAWING:
	case MS_MODE_CHECKING:
	case MS_MODE_PERCENT_Z:
	case MS_MODE_CALCULATION:
	case MS_MODE_VOLTDROP:
	case MS_MODE_HARMONIC:
		break;
	case MS_MODE_POWER_FLOW:
		if (!props.sPhase && props.lineActiveFlow1 !==0 && props.lineActiveFlow2 !==0 && props.lineReactiveFlow1 !==0 && props.lineReactiveFlow2 !==0) {
			if (nDirect == DIRECT_NONE) {
				sRefV1.push(doGetTextActiveLeft(props,nDirect));
				sRefV1.push(doGetTextReactiveLeft(props,nDirect));
			}
			else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
				sRefV1.push(doGetTextActiveLeft(props,nDirect));
                let sTemp = ''
				if (props.lineActiveFlow1 > 0) {
					if (props.lineReactiveFlow1 > 0) {
						sTemp += "+j";
					}
					else {
						sTemp += "-j";
					}
				}
				else {
					if (props.lineActiveFlow1 > 0) {
						sTemp += "-j";
					}
					else {
						sTemp += "+j";
					}
				}
				sRefV1.push(sTemp + doGetTextReactiveLeft(props,nDirect));
			}
			else {
				sRefV1.push(doGetTextActiveLeft(props,nDirect));
                let sTemp = ''
				if (props.lineActiveFlow2 > 0) {
					if (props.lineActiveFlow2 > 0) {
						sTemp += "+j";
					}
					else {
						sTemp += "-j";
					}
				}
				else {
					if (props.lineActiveFlow2 > 0) {
						sTemp += "-j";
					}
					else {
						sTemp += "+j";
					}
				}
				sRefV1.push(sTemp + doGetTextReactiveLeft(props,nDirect));
			}
		}
		break;
	}

	return sRefV1;
}
