import * as CONSTANTS from "../models/Constants"
import * as dxfFile2 from "../components/dxfFile/dxfFile2";
import { doSetRangeGraphDXF } from "./Graph";

// const params = {
//     fileName: string,
//     pObjGraph: any,
//     diagramData: ControlModel[],
//     infoCircuit: InfoCircuit,
//     projectData: ProjectModel,
//     listTextGraph: any[],
// }
export const onFileDxfOutPut = (params: any) => {
    let fileName = params.fileName
    let pObjGraph = params.pObjGraph
    let saveFile = fileName + ".dxf"
    let CDXFFile2 = {
        m_dMulti: 0,
        m_dOffsetX: 0
    }
    let baseFileName = ""
    if (pObjGraph.normalXMin <= 0.1){
        baseFileName = CONSTANTS.NAME_DXF_GRAPH_L
        CDXFFile2.m_dOffsetX = -43.4
    } else if (pObjGraph.normalXMin >= 10){
        baseFileName = CONSTANTS.NAME_DXF_GRAPH_H
        CDXFFile2.m_dOffsetX = 43.4
    } else {
        baseFileName = CONSTANTS.NAME_DXF_GRAPH
        CDXFFile2.m_dOffsetX = 0
    }

    let objGraph = {...pObjGraph}
    objGraph.normalXMin = 2
    objGraph.normalXMax = 30000
    objGraph.normalYMin = 0.01
    objGraph.normalYMax = 10000
    objGraph.zoom = 100

    let dc = {nRetVal: 1}
    let rectGraph = {
        left: 4000,
        top: 12000,
        right: 22140,
        bottom: 38060,
    }
    rectGraph = doSetRangeGraphDXF(objGraph, rectGraph)

    CDXFFile2.m_dMulti = 0.01

    params.pObjGraph = objGraph
    params.dc = dc
    dxfFile2.doDxfOutToFile(CDXFFile2, baseFileName, saveFile, params)
}
