// 基準容量

import { store } from ".."
import { ControlModel, MsTrans1PartModel, MsTrans3Model, MsTransScottPartModel } from "../models/Index"
import { MS_XR_MAX, SOURCE_SYMBOL_INFINITE, STANDARD_CAPACITY, VALUE_NODE_VOLT_MIN } from "../statics"
import { doCalcCapacitorCapacity } from "./CollisionDetection"
import { BeamsNumber } from "./FormatNumber"

// -----MS_SOURCE-----
export function msSourceNormalCalculation(voltage: number, capacity: number, xr: number) {
    let percentZ, percentR, percentX
    let ohmZ, ohmR, ohmX
    // percentZ
    if (capacity == 0){
        percentZ = 0
    } else {
        percentZ = 10 * 100 / capacity
    }
    // percentR
    if (capacity == 0) {
        percentR = 0
    } else if (xr == 0){
        percentR = percentZ
    } else if (xr == 999999) {
        percentR = 0
    } else {
        percentR = percentZ / Math.sqrt(xr * xr + 1)
    }
    // percentX
    if (capacity == 0 || xr == 0){
        percentX = 0
    } else if (xr == 999999){
        percentX = percentZ
    } else {
        percentX = xr * percentR
    }

    // ohmZ
    if (capacity == 0 || STANDARD_CAPACITY == 0){
        ohmZ = 0
    } else {
        ohmZ = voltage * voltage / (capacity * 1000000) * 100 * 0.01 * 1000
    }
    // ohmR
    ohmR = ohmZ / Math.sqrt(xr * xr + 1)
    // ohmX
    ohmX = xr * ohmR
    
    return {
        percentZ: percentZ,
        percentR: percentR,
        percentX: percentX,
        ohmZ: ohmZ,
        ohmR: ohmR,
        ohmX: ohmX
    }
}

export function msSourceFromRXCalculation(
    voltage: number,
    percentR: number,
    percentX: number,
    m_nExchangeNumber:number,
    nControl:string
) {
    let infoCircuit = store.getState().app.diagram.infoCircuit;
    let percentZ = 0
    let capacity = 0, xr = 0
    // let ohmZ, ohmR, ohmX
    if(nControl ==="percentR"){
    if(percentR <= 0){
        xr = MS_XR_MAX
        percentZ = percentX
    }
    else{
        xr = percentX / percentR
        percentZ = Math.sqrt(percentX * percentX + percentR * percentR)
    }
    
    }
    else if(nControl ==="percentX"){
        if(percentX <= 0){
            percentZ = percentR
            xr = 0
        }
        else if( percentR == 0){
            xr = MS_XR_MAX
            percentZ = percentX
        }
        else{
            xr = percentX / percentR
            percentZ = Math.sqrt(percentX * percentX + percentR * percentR)
        }
    }
    if (percentZ == 0){
        capacity = 0
    } else {
        capacity = infoCircuit.stdCapacity * 1000 * 100 / percentZ / 1000000
    }
    let source  = doSetValuesSource (infoCircuit.stdCapacity,capacity,voltage,xr,percentR,percentX,percentZ,m_nExchangeNumber,nControl)
    // percentZ = Math.sqrt(percentX * percentX + percentR * percentR)

    // // capacity
    // if (percentZ == 0){
    //     capacity = 0
    // } else {
    //     capacity = STANDARD_CAPACITY * 1000 * 100 / percentZ / 1000000
    // }
    // // xr
    // if (percentX == 0)
    //     xr = 0
    // else if (percentR > 0)
    //     xr = percentX / percentR
    // else
    //     xr = 99999
    
    // // ohmZ
    // if (capacity == 0 || STANDARD_CAPACITY == 0){
    //     ohmZ = 0
    // } else {
    //     ohmZ = voltage * voltage / (capacity * 1000000) * 100 * 0.01 * 1000
    // }
    // // ohmR
    // ohmR = ohmZ / Math.sqrt(xr * xr + 1)
    // // ohmX
    // ohmX = xr * ohmR

    return {
        capacity: capacity,
        xr: xr,
        percentZ: percentZ,
        ohmZ: source.ohmZ,
        ohmR: source.ohmR,
        ohmX: source.ohmX
    }
}
const doGetValuePercentZSource = (percentZ:number,m_nExchangeNumber:number) =>{
    let dPercentZ = percentZ;
	if (m_nExchangeNumber == SOURCE_SYMBOL_INFINITE) {
		dPercentZ = 0;
	}

	return dPercentZ;
}
const doGetValueCapacitySource = (capacity:number,m_nExchangeNumber:number) =>{
    let capacity1 = capacity;
	if (m_nExchangeNumber == SOURCE_SYMBOL_INFINITE) {
		capacity1 = 0;
	}

	return capacity1;
}
const doSetValuesSource = (dStdCapacity:number,capacity:number,voltage:number,xr:number,percentR:number,percentX:number,percentZ:number,m_nExchangeNumber:number,nControl:string) =>
{
    let m_cOhmZ = 0
    let m_cOhmR = 0
    let m_cOhmX = 0
	if (nControl != "percentR"){
		if(nControl == "capacity"){
			if(xr == MS_XR_MAX){
				percentR = 0;
			}
			else if(xr == 0){
				percentR = percentZ;
			}
			else{
				 percentR = doGetValuePercentZSource(percentZ,m_nExchangeNumber) /Math.sqrt(xr * xr + 1)
			}
		}	
	}

	if (nControl != "percentX"){
		if(nControl == "capacity"){
			if(xr == 0){
                percentX = 0;
			}
			else if(xr == MS_XR_MAX){
				percentX = percentZ;
			}
			else{
				percentX = xr * percentR;
			}
		}	
	}	

	m_cOhmZ = DoGetTextOhmZ(dStdCapacity, doGetValueCapacitySource(capacity,m_nExchangeNumber),voltage);

	m_cOhmR = DoGetTextOhmR(xr, m_cOhmZ);

	m_cOhmX = DoGetTextOhmX(xr, m_cOhmR);
    return {
        xr: xr,
        percentX: percentX,
        percentR: percentR,
        ohmZ: m_cOhmZ,
        ohmR: m_cOhmR,
        ohmX: m_cOhmX
    }
}
const DoGetTextOhmX =(xr:number,m_cOhmR:number) =>{
    return xr * m_cOhmR
}
const DoGetTextOhmR =(xr:number,m_cOhmZ:number) =>{
    return m_cOhmZ/Math.sqrt(xr*xr +1)

}
const DoGetTextOhmZ =(dStdCapacity:number,capacity:number,voltage:number) =>{
    if (capacity == 0 || dStdCapacity == 0) {
		return 0;
	}
	const  dOhmZ = voltage * voltage / (capacity * 1000000) * 100 * 0.01 * 1000;
	return dOhmZ;

}
export function msSourceFromZCalculation(
    voltage: number,
    xr: number,
    percentZ: number,
    m_nExchangeNumber: number,
    nControl:string
) {
    let capacity
    let percentR, percentX
    // let ohmZ, ohmR, ohmX
    let infoCircuit = store.getState().app.diagram.infoCircuit;

    if(xr == 0){
        percentR = percentZ
        percentX = 0
    }
    else if(xr == MS_XR_MAX){
        percentR = 0
        percentX = percentZ
    }
    else{
        let root = Math.sqrt(1 + xr * xr)
        percentR = percentZ / root
        percentX = Math.sqrt(percentZ * percentZ - percentR * percentR)
    }
    if (percentZ == 0){
        capacity = 0
    } else {
        capacity = infoCircuit.stdCapacity * 1000 * 100 / percentZ / 1000000
    }

    let source  = doSetValuesSource (infoCircuit.stdCapacity,capacity,voltage,xr,percentR,percentX,percentZ,m_nExchangeNumber,nControl)

    // // capacity
    // if (percentZ == 0){
    //     capacity = 0
    // } else {
    //     capacity = STANDARD_CAPACITY * 1000 * 100 / percentZ / 1000000
    // }
    
    // // percentR
    // if (capacity == 0) {
    //     percentR = 0
    // } else if (xr == 0){
    //     percentR = percentZ
    // } else if (xr == 999999) {
    //     percentR = 0
    // } else {
    //     percentR = percentZ / Math.sqrt(xr * xr + 1)
    // }
    // // percentX
    // if (capacity == 0 || xr == 0){
    //     percentX = 0
    // } else if (xr == 999999){
    //     percentX = percentZ
    // } else {
    //     percentX = xr * percentR
    // }
    
    // // ohmZ
    // if (capacity == 0 || STANDARD_CAPACITY == 0){
    //     ohmZ = 0
    // } else {
    //     ohmZ = voltage * voltage / (capacity * 1000000) * 100 * 0.01 * 1000
    // }
    // // ohmR
    // ohmR = ohmZ / Math.sqrt(xr * xr + 1)
    // // ohmX
    // ohmX = xr * ohmR

    return {
        capacity: capacity,
        percentR: source.percentR,
        percentX: source.percentX,
        ohmZ: source.ohmZ,
        ohmR: source.ohmR,
        ohmX: source.ohmX
    }
}

export function DoCalcDemandCapacity(
    contract: number,
    present: number,
    target: number,
    diagramData: ControlModel[],
    sourceID: string
) {
    if (present == 0 || target == 0) return 0

    let capacity: number = 0

    let dd: number = 1 / present / present
    capacity = 1 / target / target
    capacity = contract * (Math.sqrt(dd - 1) - Math.sqrt(capacity - 1))

    let targetControl = diagramData.find((e) => e.id == sourceID)
    let capacitorCapacity = doCalcCapacitorCapacity(targetControl!, diagramData)

    capacity -= capacitorCapacity
    return capacity
}

export const doGetValuePercentRe =(xr:number,percentZ:number ) =>{
    return percentZ / Math.sqrt(xr * xr +1)
}
export const doGetValuePercentXe =(xr:number,percentR:number) =>{
    return xr * percentR
}
// -----MS_TRANS3, MS_TRANS1, MS_TRANSSCOTT-----

// calcData {
//     voltageSecondary: number,
//     capacity: number,
//     percentR: number,
//     percentX: number,
// }
export function msTransNormalCalculation(calcData: any) {
    let percentZ
    let ohmZ = 0, ohmR = 0, ohmX = 0

    // percentZ
    percentZ = Math.sqrt(
        calcData.percentR * calcData.percentR +
        calcData.percentX * calcData.percentX
    )

    if (calcData.capacity != 0){
        // ohmR
        if (calcData.capacity == 0 || calcData.percentR == 0){
            ohmR = 0
        } else {
            ohmR =
                ((calcData.voltageSecondary * calcData.voltageSecondary) /
                    calcData.capacity /
                    1000) *
                calcData.percentR *
                10
        }
        // ohmX
        if (calcData.capacity == 0 || calcData.percentX == 0){
            ohmX = 0
        } else {
            ohmX =
                ((calcData.voltageSecondary * calcData.voltageSecondary) /
                    calcData.capacity /
                    1000) *
                calcData.percentX *
                10
        }
        // ohmZ
        ohmZ = Math.sqrt(ohmR * ohmR + ohmX * ohmX)
    }
    
    return {
        percentZ: percentZ,
        ohmZ: ohmZ,
        ohmR: ohmR,
        ohmX: ohmX
    }
}
export function msTransFromXR_ZCalculation(
    voltageSecondary: number,
    percentZ: number,
    capacity: number,
    xr: number
) {
    let percentR, percentX
    let ohmZ = 0, ohmR = 0, ohmX = 0

    if (capacity != 0) {
        // percentR, percentX
        if (xr == 0){
            percentR = 0
            percentX = 0
        }
        else if (xr == MS_XR_MAX){
            percentR = 0
            percentX = percentZ
        } else {
            percentR = percentZ / Math.sqrt(xr * xr + 1)
            percentX = xr * percentR
        }

        // ohmR
        if (percentR == 0)
            ohmR = 0
        else
            ohmR =
                ((voltageSecondary * voltageSecondary) / capacity / 1000) *
                percentR * 10

        // ohmX
        if (percentX == 0)
            ohmX = 0
        else 
            ohmX =
                ((voltageSecondary * voltageSecondary) / capacity / 1000) *
                percentX * 10

        // ohmZ
        ohmZ = Math.sqrt(ohmR * ohmR + ohmX * ohmX)
    }

    return {
        percentR: percentR,
        percentX: percentX,
        ohmZ: ohmZ,
        ohmR: ohmR,
        ohmX: ohmX
    }
}

// -----MS_TRANS3-----

export function msTrans3GetRatedCurrent(capacity: number, voltage: number) {
    if (voltage == 0) return 0
    return (capacity * 1000) / (voltage * Math.sqrt(3.0))
}

export function msTrans3GetDampingTime(dampingTime: number, frequency: number) {
    if (frequency == 0) return 0
    return (dampingTime * 1000) / frequency
}

export function msTrans3GetInrushValue(inrushTimes: number, capacity: number, voltagePrimary: number) {
    let ratedCurrent = msTrans3GetRatedCurrent(capacity, voltagePrimary)

    if (ratedCurrent == 0)
        return 0
    return inrushTimes * ratedCurrent
}

export function msTrans3GetIntensityValue(intensityTimes: number, capacity: number, voltageSecondary: number) {
    let ratedCurrent = msTrans3GetRatedCurrent(capacity, voltageSecondary)

    if (ratedCurrent == 0)
        return 0
    return intensityTimes * ratedCurrent
}

export function msTrans3GetLoadCapacity(voltageSecondary: number, loadCurrent: number) {
    let loadCapacity = loadCurrent * voltageSecondary
    
    loadCapacity *= Math.sqrt(3.0)
    return loadCapacity
}

export function msTrans3GetLoadCurrent(voltageSecondary: number, loadCapacity: number) {
    let loadCurrent = loadCapacity / voltageSecondary

    loadCurrent /= Math.sqrt(3.0)
    return loadCurrent
}

// calcData {
//     capacity: number,
//     voltageSecondary: number,
//     voltageAdjust: number,
//     percentR: number,
//     percentX: number,
//     powerFactor: number,
//     loadCurrent: number,
//     leadLag: boolean,
// }
export function msTrans3GetDropVoltDiff(calcData: any) {
    let volt = 0
    let percentZ = msTrans3GetPerZ(calcData)
    calcData["percentRk3"] = percentZ.percentRk3
    calcData["percentXk3"] = percentZ.percentXk3

    // if (opeMode == MS_MODE_DRAWING || supply == true){
    //     volt = msTrans3GetDropVoltRegular(calcData)
    //     volt = calcData.voltageSecondary - volt
    // }
    volt = msTrans3GetDropVoltRegular(calcData)
    volt = calcData.voltageSecondary - volt

    return volt
}

export function msTrans3GetDropPerUnit(calcData: any) {
    let percentZ = msTrans3GetPerZ(calcData)
    calcData["percentRk3"] = percentZ.percentRk3
    calcData["percentXk3"] = percentZ.percentXk3

    let volt = msTrans3GetDropVoltRegular(calcData)
    let percent = 1 - volt / calcData.voltageSecondary 
    percent *= 100

    return percent
}

const msTrans3GetPerZ = (calcData: any) => {
    let percentZ = {
        percentRk3: 0,
        percentXk3: 0
    }
    if (calcData.capacity == 0) return percentZ

    let voltage = calcData.voltageSecondary / calcData.voltageAdjust
    percentZ.percentRk3 = calcData.percentR *
        (STANDARD_CAPACITY / calcData.capacity) *
        voltage * voltage

    percentZ.percentXk3 = calcData.percentX *
        (STANDARD_CAPACITY / calcData.capacity) *
        voltage * voltage

    return percentZ
}

// calcData {
//     powerFactor: number,
//     voltageSecondary: number,
//     loadCurrent: number,
//     leadLag: number,
//     percentRk3: number,
//     percentXk3: number,
// }
const msTrans3GetDropVoltRegular = (calcData: any) => {
    let percent = msTrans3GetDropPerRegular(calcData)
    let dropVolt = calcData.voltageSecondary - calcData.voltageSecondary * percent

    if (Math.abs(dropVolt) < VALUE_NODE_VOLT_MIN)
        dropVolt = 0

    return dropVolt
}

const msTrans3GetDropPerRegular = (calcData: any) => {
    if (calcData.powerFactor < 0 || STANDARD_CAPACITY <= 0) return 0

    let capacity = calcData.voltageSecondary * calcData.loadCurrent
    // switch (calcKind) {
    //     case CALC_IK3:
    //         capacity *= Math.sqrt(3.0)
    //         break
    //      ...
    // }
    capacity *= Math.sqrt(3.0)

    let percent = 0
    if (calcData.powerFactor * calcData.powerFactor <= 1){
        if (calcData.leadLag)
            percent =
                calcData.percentRk3 * calcData.powerFactor -
                calcData.percentXk3 *
                Math.sqrt(1 - calcData.powerFactor * calcData.powerFactor)
        else
            percent =
                calcData.percentRk3 * calcData.powerFactor +
                calcData.percentXk3 *
                Math.sqrt(1 - calcData.powerFactor * calcData.powerFactor)
    }

    percent *= capacity
    percent /= STANDARD_CAPACITY * 1000
    percent /= 100

    if (Math.abs(percent) < VALUE_NODE_VOLT_MIN)
        percent = 0

    return percent
}

// -----MS_TRANS1-----
export function msTrans1GetRatedCurrent(capacity: number, voltage: number) {
    if (voltage == 0) return 0
    return (capacity * 1000) / voltage
}

export function msTrans1GetInrushValue(inrushTimes: number, capacity: number, voltageSecondary: number) {
    let ratedCurrent = msTrans1GetRatedCurrent(capacity, voltageSecondary)

    if (ratedCurrent == 0)
        return 0
    return inrushTimes * ratedCurrent
}

export function msTrans1GetIntensityValue(intensityTimes: number, capacity: number, voltageSecondary: number) {
    let ratedCurrent = msTrans1GetRatedCurrent(capacity, voltageSecondary)

    if (ratedCurrent == 0)
        return 0
    return intensityTimes * ratedCurrent
}

export function msTrans1ScottGetLoadCapacity(voltage: number, loadCurrent: number) {
    return loadCurrent * voltage
}

export function msTrans1ScottGetLoadCurrent(voltage: number, loadCapacity: number) {
    return loadCapacity / voltage
}
const DoReadByCapacityT1 =(dCapacity:number,dPerROutIn:number,dPerXOutIn:number,dInrushTimesOutIn:number,dDampingTimeOutIn:number,dPerROutOut:number,
    dPerXOutOut:number,dInrushTimesOutOut:number,dDampingTimeOutOut:number,trans1List:any) =>{
        for(const element of trans1List){
            if(dCapacity <= element.dcapacity || element.dcapacity == -1){
            if (dPerROutIn != null) {
                dPerROutIn = element.dperROutIn;
            }
            if (dPerXOutIn != null) {
                dPerXOutIn = element.dperXOutIn;
            }
            if (dInrushTimesOutIn != null) {
                dInrushTimesOutIn = element.dpeakValueOutIn;
            }
            if (dDampingTimeOutIn != null) {
                dDampingTimeOutIn = element.ddampingTimeOutIn;
            }
            if (dPerROutOut != null) {
                dPerROutOut = element.dperROutOut;
            }
            if (dPerXOutOut != null) {
                dPerXOutOut = element.dperXOutOut;
            }
            if (dInrushTimesOutOut != null) {
                dInrushTimesOutOut = element.dpeakValueOutOut;
            }
            if (dDampingTimeOutOut != null) {
                dDampingTimeOutOut = element.ddampingTimeOutOut;
            }
            break;
        }
    }
    return {
        dPerROutIn: dPerROutIn,
        dPerXOutIn: dPerXOutIn,
        dInrushTimesOutIn: dInrushTimesOutIn,
        dDampingTimeOutIn: dDampingTimeOutIn,
        dPerROutOut: dPerROutOut,
        dPerXOutOut: dPerXOutOut,
        dInrushTimesOutOut: dInrushTimesOutOut,
        dDampingTimeOutOut: dDampingTimeOutOut

    }
    }

const DoGetValuePercentRe = (xr:number, percentZ:number) =>{
    return  percentZ / Math.sqrt(xr * xr + 1)
}
const DoGetValuePercentXe = (xr:number, percentR:number) =>{
    return  xr * percentR
}
export const beamsNumberTrans1 =(part:MsTrans1PartModel) =>{
    part.xr  =  BeamsNumber(part.xr,5)
    part.percentZ = BeamsNumber(part.percentZ,5)
    part.percentX = BeamsNumber(part.percentX,5)
    part.percentR = BeamsNumber(part.percentR,5)
}
    
export function doCalcSetValuesTrans1(dCapacity:number,trans1List:any,m_PartOutIn:MsTrans1PartModel,m_PartOutOut:MsTrans1PartModel, nControl:string)
{
	let dPerROutIn = 0;
	let dPerXOutIn = 0;
	let dInrushTimesOutIn = 0;
	let dDampingTimeOutIn = 0;
	let dPerROutOut = 0;
	let dPerXOutOut = 0;
	let dInrushTimesOutOut = 0;
	let dDampingTimeOutOut = 0;
    let infor = store.getState().app.diagram.infoCircuit

	const trans1 = DoReadByCapacityT1(dCapacity,dPerROutIn,dPerXOutIn,dInrushTimesOutIn,dDampingTimeOutIn,dPerROutOut,dPerXOutOut,dInrushTimesOutOut,dDampingTimeOutOut,trans1List)

	if (dCapacity <= 0) {
		m_PartOutIn.percentZ = 0;
		m_PartOutIn.xr = 0;
		m_PartOutOut.percentZ = 0;
		m_PartOutOut.xr = 0;
	}
	else {
		if (nControl != "percentZ" && nControl != "xr") {
			m_PartOutIn.percentZ = Math.sqrt(trans1.dPerROutIn * trans1.dPerROutIn + trans1.dPerXOutIn * trans1.dPerXOutIn);
			if (trans1.dPerROutIn == 0) {
				m_PartOutIn.xr = 0;
			}
			else {
				if(trans1.dPerROutIn <= 0)	{
					m_PartOutIn.xr = MS_XR_MAX;
				}
				else if(trans1.dPerXOutIn <= 0){
					m_PartOutIn.xr = 0;
				}
				else{
					m_PartOutIn.xr = trans1.dPerXOutIn / trans1.dPerROutIn;
				}
			}
		}
		if (nControl != "voltSecondary" && nControl != "percentR") {
			if(m_PartOutIn.xr == MS_XR_MAX){
				m_PartOutIn.percentR = 0;
			}
			else{
				m_PartOutIn.percentR = DoGetValuePercentRe(m_PartOutIn.xr, m_PartOutIn.percentZ);
			}
		}
		if (nControl != "voltSecondary" && nControl != "percentX") {
			if(m_PartOutIn.xr == 0){
				m_PartOutIn.percentX = 0;
			}
			else{
				if(m_PartOutIn.percentR == 0)
					m_PartOutIn.percentX = m_PartOutIn.percentZ;
				else
					m_PartOutIn.percentX = DoGetValuePercentXe(m_PartOutIn.xr, m_PartOutIn.percentR);
			}
		}
		if (nControl != "percentZ" && nControl != "xr") {
			m_PartOutOut.percentZ = Math.sqrt(trans1.dPerROutOut * trans1.dPerROutOut + trans1.dPerXOutOut * trans1.dPerXOutOut);
			if (trans1.dPerROutOut == 0) {
				m_PartOutOut.xr = 0;
			}
			else {
				if(trans1.dPerROutOut <= 0)	{
					m_PartOutOut.xr = MS_XR_MAX;
				}
				else if(trans1.dPerXOutOut <= 0){
					m_PartOutOut.xr = 0;
				}
				else{
					m_PartOutOut.xr = trans1.dPerXOutOut / trans1.dPerROutOut;
				}
			}
		}
		if (nControl != "voltSecondary" && nControl != "percentR") {
			if(m_PartOutOut.xr == MS_XR_MAX){
				m_PartOutOut.percentR = 0;
			}
			else{
				m_PartOutOut.percentR = DoGetValuePercentRe(m_PartOutOut.xr, m_PartOutOut.percentZ);
			}
		}
		if (nControl != "voltSecondary" && nControl != "percentX") {
			if(m_PartOutOut.xr == 0){
				m_PartOutOut.percentX = 0;
			}
			else{
				if(m_PartOutOut.percentR == 0)
					m_PartOutOut.percentX = m_PartOutOut.percentZ;
				else
					m_PartOutOut.percentX = DoGetValuePercentXe(m_PartOutOut.xr, m_PartOutOut.percentR);
			}
		}
	}

	m_PartOutIn.inrushTimes = trans1.dInrushTimesOutIn;
	m_PartOutOut.inrushTimes = trans1.dInrushTimesOutOut;

	if (infor.frequency == 0) {
		m_PartOutIn.dampingTime = 0;
		m_PartOutOut.dampingTime = 0;
	}
	else {
		m_PartOutIn.dampingTime = trans1.dDampingTimeOutIn * 1000 / infor.frequency;
		m_PartOutOut.dampingTime = trans1.dDampingTimeOutOut * 1000 / infor.frequency;
	}
}
// calcData {
//     isNeutral: boolean, // Neutral or 1 or Inside
//     xr1: number,
//     percentZ1: number,
//     xr2: number,
//     percentZ2: number,
//     capacity: number,
//     voltageSecondary: number,
//     loadCurrent: number,
//     powerFactor: number
//     leadLag: boolean,
// }
export function msTrans1GetDropVoltDiff(calcData: any) {
    let volt = 0
    let percentZ = msTrans1GetPerZ(calcData)
    calcData["percentRk3"] = percentZ.percentRk3
    calcData["percentXk3"] = percentZ.percentXk3

    if (calcData.isNeutral)
        calcData.voltageSecondary /= 2

    volt = msTrans1GetDropVoltRegular(calcData)
    volt = calcData.voltageSecondary - volt

    return volt
}

// calcData: as above
export function msTrans1GetDropPerUnit(calcData: any,dialog:number = 0) {
    let percentZ = msTrans1GetPerZ(calcData)
    calcData["percentRk3"] = percentZ.percentRk3
    calcData["percentXk3"] = percentZ.percentXk3
    if(dialog == 1){
        if (calcData.isNeutral)
            calcData.voltageSecondary /= 2
    }
    

    let volt = msTrans1GetDropVoltRegular(calcData)
    let percent = 1 - volt / calcData.voltageSecondary 
    percent *= 100

    return percent
}

const msTrans1GetPerZ = (calcData: any) => {
    let percentZ = {
        percentRk3: 0,
        percentXk3: 0,
        // percentRk3Diff: 0,
        // percentXk3Diff: 0
    }
    if (calcData.capacity == 0) return percentZ

    if (calcData.isNeutral){
        percentZ.percentRk3 = calcData.percentZ1 / Math.sqrt(calcData.xr1 * calcData.xr1 + 1)
        percentZ.percentXk3 = calcData.xr1 * percentZ.percentRk3

        percentZ.percentRk3 *= STANDARD_CAPACITY / (calcData.capacity / 2)
        percentZ.percentXk3 *= STANDARD_CAPACITY / (calcData.capacity / 2)
    } else {
        percentZ.percentRk3 = calcData.percentZ2 / Math.sqrt(calcData.xr2 * calcData.xr2 + 1)
        percentZ.percentXk3 = calcData.xr2 * percentZ.percentRk3
        // percentZ.percentRk3Diff = calcData.percentZ1 / Math.sqrt(calcData.xr1 * calcData.xr1 + 1)
        // percentZ.percentXk3Diff = calcData.xr1 * percentZ.percentRk3Diff

        percentZ.percentRk3 *= STANDARD_CAPACITY / calcData.capacity
        percentZ.percentXk3 *= STANDARD_CAPACITY / calcData.capacity

        // percentZ.percentRk3Diff *= STANDARD_CAPACITY / (calcData.capacity / 2)
        // percentZ.percentXk3Diff *= STANDARD_CAPACITY / (calcData.capacity / 2)
        // percentZ.percentRk3Diff = percentZ.percentRk3 - percentZ.percentRk3Diff
        // percentZ.percentXk3Diff = percentZ.percentXk3 - percentZ.percentXk3Diff
    }

    return percentZ
}

const msTrans1GetDropVoltRegular = (calcData: any) => {
    let percent = msTrans1GetDropPerRegular(calcData)
    let dropVolt = calcData.voltageSecondary - calcData.voltageSecondary * percent

    if (Math.abs(dropVolt) < VALUE_NODE_VOLT_MIN)
        dropVolt = 0

    return dropVolt
}

const msTrans1GetDropPerRegular = (calcData: any) => {
    if (calcData.powerFactor < 0 || STANDARD_CAPACITY <= 0) return 0

    let capacity = calcData.voltageSecondary * calcData.loadCurrent
    if (calcData.isNeutral == false)
        capacity *= 2
    
    let percent = 0
    if (calcData.powerFactor * calcData.powerFactor <= 1){
        if (calcData.leadLag)
            percent =
                calcData.percentRk3 * calcData.powerFactor -
                calcData.percentXk3 *
                Math.sqrt(1 - calcData.powerFactor * calcData.powerFactor)
        else
            percent =
                calcData.percentRk3 * calcData.powerFactor +
                calcData.percentXk3 *
                Math.sqrt(1 - calcData.powerFactor * calcData.powerFactor)
    }

    percent *= capacity
    percent /= STANDARD_CAPACITY * 1000
    percent /= 100

    if (Math.abs(percent) < VALUE_NODE_VOLT_MIN)
        percent = 0

    return percent
}

// -----MS_TRANSSCOTT-----
export function msTransScottGetRatedCurrent(capacity: number, voltage: number, is3Phases: boolean = false) {
    if (is3Phases) {
        return (capacity * 1000) / voltage / Math.sqrt(3)
    }

    if (voltage == 0) return 0
    return ((capacity / 2) * 1000) / voltage
}

export function msTransScottGetInrushValue(inrushTimes: number, capacity: number, voltageSecondary: number) {
    let ratedCurrent = msTransScottGetRatedCurrent(capacity, voltageSecondary)

    if (ratedCurrent == 0)
        return 0
    return inrushTimes * ratedCurrent
}

export function msTransScottGetIntensityValue(intensityTimes: number, capacity: number, voltageSecondary: number) {
    let ratedCurrent = msTransScottGetRatedCurrent(capacity, voltageSecondary)

    if (ratedCurrent == 0)
        return 0
    return intensityTimes * ratedCurrent
}

// calcData {
//     isSeatM: boolean, // Main or 1
//     percentR1: number,
//     percentX1: number,
//     percentR2: number,
//     percentX2: number,
//     capacity: number,
//     voltageSecondary: number,
//     loadCurrent: number,
//     powerFactor: number
//     leadLag: boolean,
// }
export function msTransScottGetDropVoltDiff(calcData: any) {
    let volt = 0
    let percentZ = msTransScottGetPerZ(calcData)

    let newPercentk3
    if (calcData.isSeatM)
        newPercentk3 = msTransScottGetPerZSeatM(percentZ)
    else
        newPercentk3 = msTransScottGetPerZSeatT(percentZ)
    calcData["percentRk3"] = newPercentk3.percentR
    calcData["percentXk3"] = newPercentk3.percentX

    volt = msTransScottGetDropVoltRegular(calcData)
    volt = calcData.voltageSecondary - volt

    return volt
}

// calcData: as above
export function msTransScottGetDropPerUnit(calcData: any) {
    let percentZ = msTransScottGetPerZ(calcData)
    
    let newPercentk3
    if (calcData.isSeatM)
        newPercentk3 = msTransScottGetPerZSeatM(percentZ)
    else
        newPercentk3 = msTransScottGetPerZSeatT(percentZ)
    calcData["percentRk3"] = newPercentk3.percentR
    calcData["percentXk3"] = newPercentk3.percentX
    
    let volt = msTransScottGetDropVoltRegular(calcData)
    let percent = 1 - volt / calcData.voltageSecondary 
    percent *= 100

    return percent
}

const msTransScottGetPerZ = (calcData: any) => {
    let percentZ = {
        percentRk3: 0,
        percentXk3: 0,
        percentRk3Diff: calcData.percentR1,
        percentXk3Diff: calcData.percentX1,
        percentRk3Source: 0,
        percentXk3Source: 0,
    }

    if (calcData.capacity == 0) return percentZ
    else {
        percentZ.percentRk3 = calcData.percentR2 * STANDARD_CAPACITY / (calcData.capacity / 2)
        percentZ.percentXk3 = calcData.percentX2 * STANDARD_CAPACITY / (calcData.capacity / 2)

        percentZ.percentRk3Diff *= STANDARD_CAPACITY / (calcData.capacity / 2)
        percentZ.percentXk3Diff *= STANDARD_CAPACITY / (calcData.capacity / 2)
        percentZ.percentRk3Diff = percentZ.percentRk3 - percentZ.percentRk3Diff
        percentZ.percentXk3Diff = percentZ.percentXk3 - percentZ.percentXk3Diff
    }

    return percentZ
}

//percentZk3 {
//     percentRk3: number,
//     percentXk3: number,
//     percentRk3Diff: number,
//     percentXk3Diff: number,
//     percentRk3Source: number,
//     percentXk3Source: number,
// }
const msTransScottGetPerZSeatM = (percentZk3: any) => {
    let percentR =
        percentZk3.percentRk3Source +
        (percentZk3.percentRk3 -
        percentZk3.percentRk3Source -
        percentZk3.percentRk3Diff) /  2

    let percentX =
        percentZk3.percentXk3Source +
        (percentZk3.percentXk3 -
        percentZk3.percentXk3Source -
        percentZk3.percentXk3Diff) /  2
    
    let percentZ = Math.sqrt(percentR * percentR + percentX * percentX)

    return {
        percentR: percentR,
        percentX: percentX,
        percentZ: percentZ
    }
}

//percentZk3: as above
const msTransScottGetPerZSeatT = (percentZk3: any) => {
    let percentR =
        percentZk3.percentRk3Source +
        (percentZk3.percentRk3 - percentZk3.percentRk3Source) +
        (percentZk3.percentRk3Source +
            (percentZk3.percentRk3 -
                percentZk3.percentRk3Source -
                percentZk3.percentRk3Diff) / 2) / 2

    let percentX =
        percentZk3.percentXk3Source +
        (percentZk3.percentXk3 -
        percentZk3.percentXk3Source) +
        percentZk3.percentXk3Source /  2
    
    let percentZ = Math.sqrt(percentR * percentR + percentX * percentX)
    
    return {
        percentR: percentR,
        percentX: percentX,
        percentZ: percentZ
    }
}

const msTransScottGetDropVoltRegular = (calcData: any) => {
    let percent = msTransScottGetDropPerRegular(calcData)
    let dropVolt = calcData.voltageSecondary - calcData.voltageSecondary * percent

    if (Math.abs(dropVolt) < VALUE_NODE_VOLT_MIN)
        dropVolt = 0

    return dropVolt
}

const msTransScottGetDropPerRegular = (calcData: any) => {
    if (calcData.powerFactor < 0 || STANDARD_CAPACITY <= 0) return 0

    let capacity = calcData.voltageSecondary * calcData.loadCurrent * 2

    let percent = 0
    if (calcData.powerFactor * calcData.powerFactor <= 1){
        if (calcData.leadLag)
            percent =
                calcData.percentRk3 * calcData.powerFactor -
                calcData.percentXk3 *
                Math.sqrt(1 - calcData.powerFactor * calcData.powerFactor)
        else
            percent =
                calcData.percentRk3 * calcData.powerFactor +
                calcData.percentXk3 *
                Math.sqrt(1 - calcData.powerFactor * calcData.powerFactor)
    }

    percent *= capacity
    percent /= STANDARD_CAPACITY * 1000
    percent /= 100

    if (Math.abs(percent) < VALUE_NODE_VOLT_MIN)
        percent = 0

    return percent
}
export const doCalcSetValues=(responseData: any,index:number,capacity:number,frequency:number) =>{
    let dPerRSeatM = 0
    let dPerXSeatM = 0
    let dInrushTimesSeatM = 0
    let dDampingTimeSeatM = 0
    let dPerRSeatT = 0
    let dPerXSeatT = 0
    let dInrushTimesSeatT = 0
    let dDampingTimeSeatT = 0
    for(var item of responseData.transcottList){
        if(capacity <= item.dcapacity || item.dcapacity == -1){
        dPerRSeatM = item.dperRSeatM
        dPerXSeatM = item.dperXSeatM
        dInrushTimesSeatM = item.dpeakValueSeatM
        dDampingTimeSeatM = item.ddampingTimeSeatM
        dPerRSeatT = item.dperRSeatT
        dPerXSeatT = item.dperXSeatT
        dInrushTimesSeatT = item.dpeakValueSeatT
        dDampingTimeSeatT = item.ddampingTimeSeatT
        break
        }
    }
    let perZk3 = 0
    let perRk3 = 0
    let perXk3 = 0
    let xr = 0
    if(responseData.capacity <=0 ){
        perZk3 = 0
        xr = 0
    }
    else{
        if(index === 0){
            perZk3 = Math.sqrt(dPerRSeatM * dPerRSeatM + dPerXSeatM * dPerXSeatM)
            if(dPerRSeatM == 0){
                xr = 0
            }
            else{
                if(dPerRSeatM <= 0){
                    xr = MS_XR_MAX
                }
                else if(dPerXSeatM <= 0){
                    xr =0
                }
                else{
                    xr = dPerXSeatM / dPerRSeatM
                }
            }
            if(xr === 0){
                perRk3 = perZk3
                perXk3 = 0
            }
            else{
                perRk3 = perZk3 / Math.sqrt(xr * xr + 1)
            }
            if(xr === MS_XR_MAX){
                perXk3 = perZk3
                perRk3 = 0
            }
            else{
                perXk3 = xr * perRk3
            }
        }
        if(index === 1){
            perZk3 = Math.sqrt(dPerRSeatT * dPerRSeatT + dPerXSeatT * dPerXSeatT)
            if(dPerRSeatT === 0){
                xr = 0
            }
            else{
                if(dPerRSeatT == 0){
                    xr = MS_XR_MAX
                }
                else if(dPerXSeatT === 0){
                    xr = 0
                }
                else{
                    xr = dPerXSeatT / dPerRSeatT
                }
            }
            if(xr === 0){
                perRk3 = perZk3
                perXk3 = 0
            }
            else{
                perRk3 = perZk3 / Math.sqrt(xr * xr + 1)
            }
            if(xr === MS_XR_MAX){
                perXk3 = perZk3
                perRk3 = 0
            }
            else{
                perXk3 = xr * perRk3
            }
        }
    }
    if(frequency == 0){
        dDampingTimeSeatM = 0
        dDampingTimeSeatT = 0
    }
    else{
        dDampingTimeSeatM = dDampingTimeSeatM * 1000 / frequency
        dDampingTimeSeatT = dDampingTimeSeatT * 1000 / frequency
    }
    return {
        dPerRSeatM,
        dPerXSeatM,
        dInrushTimesSeatM,
        dDampingTimeSeatM,
        dPerRSeatT,
        dPerXSeatT,
        dInrushTimesSeatT,
        dDampingTimeSeatT,
        perZk3,
        perRk3,
        perXk3,
        xr
    }

}

export const doCalcSetValuesTransscott=(transcottList: any,capacity:number,partM: MsTransScottPartModel,partT:MsTransScottPartModel,nControl:string) =>{
    const infor = store.getState().app.diagram.infoCircuit
    let dPerRSeatM = 0
    let dPerXSeatM = 0
    let dInrushTimesSeatM = 0
    let dDampingTimeSeatM = 0
    let dPerRSeatT = 0
    let dPerXSeatT = 0
    let dInrushTimesSeatT = 0
    let dDampingTimeSeatT = 0
    for(var item of transcottList){
        if(capacity <= item.dcapacity || item.dcapacity == -1){
        dPerRSeatM = item.dperRSeatM
        dPerXSeatM = item.dperXSeatM
        dInrushTimesSeatM = item.dpeakValueSeatM
        dDampingTimeSeatM = item.ddampingTimeSeatM
        dPerRSeatT = item.dperRSeatT
        dPerXSeatT = item.dperXSeatT
        dInrushTimesSeatT = item.dpeakValueSeatT
        dDampingTimeSeatT = item.ddampingTimeSeatT
        break
        }
    }

    if(capacity <=0 ){
        partM.percentZ = 0
        partM.xr = 0
        partT.percentZ = 0
        partT.xr = 0
    }
    else{
        if(nControl !== 'percentZ' && nControl !== 'xr'){
            partM.percentZ = Math.sqrt(dPerRSeatM * dPerRSeatM + dPerXSeatM * dPerXSeatM)
            if(dPerRSeatM == 0){
                partM.xr = 0
            }
            else{
                if(dPerRSeatM <= 0){
                    partM.xr = MS_XR_MAX
                }
                else if(dPerXSeatM <= 0){
                    partM.xr = 0
                }
                else{
                   partM.xr = dPerXSeatM / dPerRSeatM
                }
            }
        }
        if(nControl !== 'voltSecondary' && nControl !== 'percentR'){
            if(partM.xr === 0){
                partM.percentR = partM.percentZ
                partM.percentX = 0
            }
            else{
                partM.percentR = partM.percentZ / Math.sqrt(partM.xr * partM.xr + 1)
            }
        }
        if(nControl !== 'voltSecondary' && nControl !== 'percentX'){
            if(partM.xr === MS_XR_MAX){
                partM.percentX = partM.percentZ
                partM.percentR = 0
            }
            else{
                partM.percentX = partM.xr * partM.percentR
            }
        }
        if(nControl !== 'percentZ' && nControl !== 'xr'){
            partT.percentZ = Math.sqrt(dPerRSeatT * dPerRSeatT + dPerXSeatT * dPerXSeatT)
            if(dPerRSeatT === 0){
                partT.xr = 0
            }
            else{
                if(dPerRSeatT == 0){
                    partT.xr = MS_XR_MAX
                }
                else if(dPerXSeatT === 0){
                    partT.xr = 0
                }
                else{
                    partT.xr = dPerXSeatT / dPerRSeatT
                }
            }
        }
        if(nControl !== 'voltSecondary' && nControl !== 'percentR'){
            if(partT.xr === 0){
                partT.percentR = partT.percentZ
                partT.percentX = 0
            }
            else{
                partT.percentR = partT.percentZ / Math.sqrt(partT.xr * partT.xr + 1)
            }
        }
        if(nControl !== 'voltSecondary' && nControl !== 'percentX'){
            if(partT.xr === MS_XR_MAX){
                partT.percentX = partT.percentZ
                partT.percentR = 0
            }
            else{
                partT.percentX = partT.xr * partT.percentR
            }
        }
    }
    partM.inrushTimes = dInrushTimesSeatM;
	partT.inrushTimes = dInrushTimesSeatT;
    if(infor.frequency == 0){
        dDampingTimeSeatM = 0
        dDampingTimeSeatT = 0
    }
    else{
        dDampingTimeSeatM = dDampingTimeSeatM * 1000 / infor.frequency
        dDampingTimeSeatT = dDampingTimeSeatT * 1000 / infor.frequency
    }
}
export const beamsNumberTransscott =(part:MsTransScottPartModel) =>{
    part.xr  =  BeamsNumber(part.xr,5)
    part.percentZ = BeamsNumber(part.percentZ,5)
    part.percentX = BeamsNumber(part.percentX,5)
    part.percentR = BeamsNumber(part.percentR,5)
}