import Konva from "konva";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { Group, Image, Label, Rect, Text, Transformer } from "react-konva";
import { connect } from "react-redux";
import * as Constant from "../../models/Constants";
import { Coordinates, MSTransCenterModel, ProcessMode, UndoModel } from '../../models/Index';
import {
    clearOptionMenu,
    openDialogEvents,
    saveCurrentIdDiagramTab,
    setRepositionElement,
    setTransformFlag,
    undo,
    updateControlHeight,
    updateElementPosition,
    updatePropertiesOfTransCenter,
    updateSelectControl,
} from "../../store/Actions";
import OptionMenu from '../contextMenu/OptionMenu';
import lineSvg from "../../images/control/line.svg";
import lineGreenSvg from "../../images/control/line_green.svg";
import lineRedSvg from "../../images/control/line_red.svg";
import imageSvg from "../../images/control/変台.svg";
import imageRedSvg from "../../images/control/変台_red.svg";
import imageGreenSvg from "../../images/control/変台_green.svg";
import { doGetValuesTransCenter } from "../../utils/TransCenterFunction";
import DraggablePaper from "../common/DraggablePaper";
import { CircularProgress, Dialog, DialogContent, DialogTitle } from "@mui/material";
import theme from "../../themes/globalStyles";
import MSTransCenterEditDialog from "../dialogs/MsTransCenterEditDialog";
import { Html, useImage } from "react-konva-utils";
import doGetSourceVolt from "../../utils/mssv3Doc";
import { MS_OUTSIDE_NEUTRAL, MS_OUTSIDE_OUTSIDE, MS_SEAT_MAIN, MS_SEAT_T } from "../../statics";
import { getElementKindValue, getTextVoltageEle } from "../../utils/ElementFunction";
import * as EKind from '../../models/ElementKind'
import { convertUndefinedToNull, convertVoltToOptionList } from "../../utils/DataConverter";
import { doGetRefV1 } from "../../utils/TransCenterFunction";
import rightArrow from "../../images/control/red_arrow.svg";
import { post } from "../CallApi";
import { getSymbolAndBackColor } from "../../utils/drawElement";
import { calcContextMenuPosition, calcDragBoundPosition, calcElementSize, getMenuOptionHeight } from "../../utils/PositionCalculation";
import { doDrawSymbolGroup } from "../graph/DCView";
import * as Model from "../../models/Index";

//#region Props
type CreateMSTransCenterProps = {
    id: string,
    image: string,
    x: number,
    y: number,
    offsetX: number;
    offsetY: number;
    width: number,
    height: number,
    type: string,
    isSelected: boolean,
    isHidden: boolean;
    rotation: number,
    lineDiagramRef: any,
    parentGroupId: number | null;
    properties: MSTransCenterModel,
    gridSizeController: {
        width: number;
        height: number;
        scale: number;
        stageOffsetX: number;
        stageOffsetY: number;
    };
    handleUpdateTCSuccess: (data: any) => void;
    isOnShiftKey: boolean;
};

export type PureCreateMSTransCenterProps = CreateMSTransCenterProps & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
//#endregion

const CONTROL_POS_FACTOR = 1;

const CreateMSTransCenter = memo((props: PureCreateMSTransCenterProps) => {
    //#region Fields
    const {
        id,
        image,
        x,
        y,
        offsetX,
        offsetY,
        width,
        height,
        type,
        isSelected,
        isHidden,
        rotation,
        lineDiagramRef,
        parentGroupId,
        processMode,
        properties,
    } = props;
    
    const {
        // getElementParam,
        // updateElementPost,
        diagramDataUpdate,
        gridSizeController,
        mainDiagram,
        diagramList,
        transCenterUpdateTimes,
        projectData,
        openDialogData,
        userRole,
        m_bModePM,
        modeViewOnly,
        infoSkeleton,
        userId,
        diagramData,
        drawPowerArrow,
        currentTabId,
        clearMenuState,
        isSelectTouchGroup,
        isOnShiftKey,
        elementGroups,
        tcGroups,
        elementReposition,
        updateElementPosition,
        setRepositionElement,
        handleUpdateTCSuccess,
        updateSelectControl,
        updateControlHeight,
        setTransformFlag,
        saveCurrentTabId,
        updateTransCenterProps,
        setUndoData,
        openDialogEvents,
        clearOptionMenu,
    } = props;

    const [imageStatus, setImageStatus] = useState(image);
    const [lineStatus, setLineStatus] = useState(lineSvg);
    const [showPowerInfo, setShowPowerInfo] = useState(false)
    const [showPowerDirectInfo, setShowPowerDirectInfo] = useState(false)
    const [activePower, setActivePower] = useState("?????kW")
    const [reactivePower, setReactivePower] = useState("?????kvar")
    const [svgHeight, setSvgHeight] = useState('')
    const [svgWidth, setSvgWidth] = useState('')
    const [svgLine, setSvgLine] = useState('')
    const [svgArrowActive, setSvgArrowActive] = useState('')
    const [svgArrowReactive, setSvgArrowReactive] = useState('')
    const [svgTextX, setSvgTextX] = useState('')
    const [svgTextY, setSvgTextY] = useState('')
    const [svgTextRotation, setSvgTextRotation] = useState('')
    const [svgTextX1, setSvgTextX1] = useState('')
    const [svgTextY1, setSvgTextY1] = useState('')
    const [svgPosX1, setSvgPosX1] = useState(0)
    const [svgPosX2, setSvgPosX2] = useState(0)
    const [svgPosX3, setSvgPosX3] = useState(0)
    const [svgPosY1, setSvgPosY1] = useState(0)
    const [svgPosY2, setSvgPosY2] = useState(0)
    const [svgPosY3, setSvgPosY3] = useState(0)

    var imageObj = new window.Image();
    imageObj.src = imageStatus;

    var lineObj = new window.Image();
    lineObj.src = lineStatus;

    var rightArrowObj = new window.Image();
    rightArrowObj.src = rightArrow;

    const svgToURL = (s:any) =>{
        const uri = window.btoa(unescape(encodeURIComponent(s)));
        return "data:image/svg+xml;base64," + uri;
      }
    
        const svgActivePower =
        `<svg height="${svgHeight}" width="${svgWidth}"  xmlns="http://www.w3.org/2000/svg">` +
        `<path  d="${svgLine}" stroke="${isSelected? 'red' : 'blue'}"  stroke-width="1" fill="none"/>` +
        `<path  d="${svgArrowActive}" stroke="${isSelected? 'red' : 'blue'}" stroke-width="1"  fill="none"/>` +
        `<text x="${svgTextX}" y="${svgTextY}" text-anchor="middle" font-size="10" fill="${isSelected? 'red' : 'blue'}" transform="translate(15,15) rotate(${svgTextRotation})">${activePower}</text>` +
        '</svg>' ;
    
      const urlActivePower = svgToURL(svgActivePower);
      const [imageSVGActivePower] = useImage(urlActivePower);
    
      const svgReactivePower =
      `<svg height="${svgHeight}" width="${svgWidth}"  xmlns="http://www.w3.org/2000/svg">` +
      `<path  d="${svgLine}" stroke="${isSelected? 'red' : 'blue'}"  stroke-width="1" fill="none"/>` +
      `<path  d="${svgArrowReactive}" stroke="${isSelected? 'red' : 'blue'}" stroke-width="1"  fill="none"/>` +
      `<text x="${svgTextX}" y="${svgTextY}" text-anchor="middle" font-size="10" fill="${isSelected? 'red' : 'blue'}" transform="translate(15,15) rotate(${svgTextRotation})">${reactivePower}</text>` +
      '</svg>' ;
    
      const urlReactivePower = svgToURL(svgReactivePower);
      const [imageSVGReactivePower] = useImage(urlReactivePower);
    
      
      const svgActiveReactivePower =
      `<svg height="${svgHeight}" width="${svgWidth}"  xmlns="http://www.w3.org/2000/svg">` +
      `<path  d="${svgLine}" stroke="${isSelected? 'red' : 'blue'}"  stroke-width="1" fill="none"/>` +
      `<path  d="${svgArrowActive}" stroke="${isSelected? 'red' : 'blue'}" stroke-width="1"  fill="none"/>` +
      `<text x="${svgTextX}" y="${svgTextY}" text-anchor="middle" font-size="10" fill="${isSelected? 'red' : 'blue'}" >${activePower}</text>` +
      `<text x="${svgTextX1}" y="${svgTextY1}" text-anchor="middle" font-size="10" fill="${isSelected? 'red' : 'blue'}" >${reactivePower}</text>` +
      '</svg>' ;
      
      const urlActiveReactivePower = svgToURL(svgActiveReactivePower);
      const [imageSVGActiveReactivePower] = useImage(urlActiveReactivePower);

    const [loadingFlag, setLoadingFlag] = useState(false);
    const [isOpenMenu, setIsOpenMenu] = useState(false);
    const [menuPosition, setMenuPosition] = useState<Coordinates>({ x: 0, y: 0 });
    const [groupPosition, setGroupPosition] = useState({ x: x, y: y });
    const [dragStartPosition, setDragStartPosition] = useState({ x: x, y: y, labelX: groupPosition.x + Constant.ELEMENT_SIZE, labelY: groupPosition.y + CONTROL_POS_FACTOR * (height / 2) });
    const [isResizing, setIsResizing] = useState(false);
    const [heightTransform, setHeightTransform] = useState(0);
    const [labelPosition, setLabelPosition] = useState({
        x: groupPosition.x + Constant.ELEMENT_SIZE,
        y: groupPosition.y + CONTROL_POS_FACTOR * (height / 2),
    });
    const [arrowPosition, setArrowPosition] = useState({
        x: groupPosition.x,
        y: groupPosition.y + height - Constant.ELEMENT_SIZE - 10,
    });
	const [initialValue, setInitialValue] = useState<MSTransCenterModel>({...properties})
    const [isOpenEditorDialog, setIsOpenEditorDialog] = useState(false)
    const [haveSourceVoltage, setHaveSourceVoltage] = useState(false)
    const [sourceVoltText, setSourceVoltText] = useState('');
    const [capacityXPowerFactor, setCapacityXPowerFactor] = useState(0);

    const transCenterDiagram = diagramList.filter((r: any) => r.tabId === properties.tabId)[0]?.shape;
    const transformRef = useRef<Konva.Transformer>(null);
    const transformMoveRef = useRef<Konva.Transformer>(null);
    const groupRef = useRef<any>();
    const topRef = useRef<any>();
    const bottomRef = useRef<any>();
    const labelRef = useRef<any>();
    const rightArrowRef = useRef<any>();
    // const labelTexts = doGetRefV1(processMode, props as any, false);
  const [labelTexts, setLabelTexts] = useState<any>([])
    const [isOnOpenMenu, setIsOnOpenMenu] = useState<boolean>(false);

    //#endregion

    //#region useEffect
    useEffect(() => {
        setLabelTexts(doGetRefV1(processMode, props as any, false))
      }, [processMode,diagramDataUpdate])

    useEffect(()=>{
        if(isSelected){
          if(isOnOpenMenu) {
            setIsOnOpenMenu(false)
          }
          else
          {
            setIsOpenMenu(false)
          }
        }
      },[clearMenuState])

      useEffect(()=>{
        if(elementReposition?.id)
        {
          const groupsData = currentTabId === 1 ? elementGroups : tcGroups[diagramData.find((x:any)=>x.id == id)?.parentTcId ?? 0]
          const peekGroupId = groupsData?.byEleId[elementReposition.id as any]?.at(-1) ?? -1
          const elementIds = groupsData?.byId[peekGroupId]?.elementIds ?? []
          if(elementIds.includes(id) || diagramData[0].shape.find((item:any)=>(item.isSelected && item.id == id)))
          {
            setGroupPosition({
              x: dragStartPosition.x,
              y: dragStartPosition.y,
            });

            labelRef.current.to({
                x: dragStartPosition.labelX,
                y: dragStartPosition.labelY,
            });

            groupRef.current.to({
              x: dragStartPosition.x,
              y: dragStartPosition.y,
            });

            updateElementPosition(id, dragStartPosition.x, dragStartPosition.y)
          }
        }
      },[elementReposition])

    useEffect(() => {
        setIsOpenMenu(false)
    
        if(processMode == ProcessMode.POWER_FLOW && drawPowerArrow.payload.length > 0 && id)
        {
        let result = drawPowerArrow.payload.find((arrows:any) => arrows.arrow?.element?.id && Number(arrows.arrow?.element?.id) == Number(id))
        if(result && result.arrow && result.text.length > 0){
            setPowerFlowArrow(result)
        }
        }
    }, [drawPowerArrow])

    useEffect(() => {
        if(processMode == ProcessMode.POWER_FLOW)
        {
          const pElement = diagramData[0].shape.find((x:any)=>x.id === id)
          let result = doDrawSymbolGroup(pElement,processMode)
          if(result && result.arrow && result.text.length > 0){
            setPowerFlowArrow(result)
          }else{
            setActivePower("")
            setReactivePower("")
            setSvgArrowReactive("")
            setSvgArrowActive("")
            setSvgLine("");
          }
        }
        else if(showPowerInfo == true || showPowerDirectInfo == true)
        {
          setShowPowerInfo(false);
          setShowPowerDirectInfo(false);
        }
      },[processMode, projectData.flowViewMethod, properties.viewFlowResult])

    useEffect(() => {
        if (x >= 0 && y >= 0 && (groupPosition.x !== x || groupPosition.y !== y)) {
            setGroupPosition({x, y})
        }
    }, [x, y])
  
    useEffect(() => {
        let props: MSTransCenterModel = {...properties}

        // check source voltage
        let transCenterData = mainDiagram.find((e: any) => e.id === id)
        let res = doGetSourceVolt(transCenterData, mainDiagram,Constant.GET_TOP, false, 0)
        setHaveSourceVoltage(res.voltage > 0)
        // sPhase
        if (res.sPhase){
            let text = ''
            if (res.upTransKind === EKind.MS_ELEMENT_ALL1.MS_TRANS1){
                text = getTextVoltageEle(props.voltage, res.sPhase, MS_OUTSIDE_OUTSIDE, false)
                text += '-'
                text += getTextVoltageEle(props.voltage, res.sPhase, MS_OUTSIDE_NEUTRAL, false)
            } else if (res.upTransKind === EKind.MS_ELEMENT_ALL1.MS_TRANSSCOTT){
                text = getTextVoltageEle(props.voltage, res.sPhase, MS_SEAT_MAIN, false)
                text += '-'
                text += getTextVoltageEle(props.voltage, res.sPhase, MS_SEAT_T, false)
            }
            setSourceVoltText(text) 
        }
        props.sPhase = res.sPhase || false
        props.upTransKind = res.upTransKind || EKind.MS_ELEMENT_ALL1.MS_ELEMENT_ALL
        setInitialValue({...props})
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transCenterUpdateTimes])

    useEffect(() => {
        if (isSelected && !parentGroupId) {
            transformRef.current?.nodes([groupRef.current]);
        } else {
            transformRef.current?.nodes([]);
        }
        transformRef.current?.getLayer()?.batchDraw();
    }, [isSelected, parentGroupId, heightTransform]);

    useEffect(() => {
        if (!isSelected) setIsOpenMenu(false);
    }, [isSelected]);

    
    useEffect(() => {
        if(openDialogData?.id === id && openDialogData !== undefined) {
            openDialogEvents(({type: "OPEN_DIALOG", data: { }}))
            handleOpenTransCenterProps()
        }
    }, [openDialogData]);

    useEffect(() => {
        // check grid size; label, icon position when rotate, move and change height
        // handleGridSize(groupPosition.x, groupPosition.y, height, rotation);
        calcLabelPos();
        calcArrowPos();
    }, [groupPosition, height, rotation]);

    useEffect(() => {
        switch(getSymbolAndBackColor(infoSkeleton,processMode,properties).symbolColor){
          case "#008040":
            setLineStatus(lineGreenSvg);
            setImageStatus(imageGreenSvg);
            break;
          case "#FF0000":
            setLineStatus(lineRedSvg);
            setImageStatus(imageRedSvg);
            break;
          default:
            setLineStatus(lineSvg);
            setImageStatus(imageSvg);
            break;
        }
      },[getSymbolAndBackColor(infoSkeleton,processMode,properties).symbolColor])

    useEffect(() => {
      setInitialValue(properties);
    },[properties])
    //#endregion

    //#region Method
    const setPowerFlowArrow = (data:any) => {
        if(data.arrow.drawActiveNoneDirect){
          setShowPowerInfo(true)
          setShowPowerDirectInfo(false)
          if(data.arrow.arrowActiveAtTop){
              setSvgHeight('50')
              setSvgWidth('30')
              setSvgLine('M 20 50 L 20 0')
              setSvgArrowActive('M 16 5 L 20 0 L 24 5')
              setSvgTextX('-10')
              setSvgTextY('-5')
              setSvgTextRotation('270')
              setActivePower(data.text[0])
              setSvgPosX1(-55)
              setSvgPosY1(-30)
          }
          else if(data.arrow.arrowActiveAtRight) {
              setSvgHeight('30')
              setSvgWidth('50')
              setSvgLine('M 50 20 L 0 20')
              setSvgArrowActive('M 5 16  L 0 20 L 5 24')
              setSvgTextX('7')
              setSvgTextY('3')
              setSvgTextRotation('0')
              setActivePower(data.text[0])
              setSvgPosX1(-40)
              setSvgPosY1(20)
              
          }
          else if(data.arrow.arrowActiveAtLeft){
              setSvgHeight('30')
              setSvgWidth('50')
              setSvgLine('M 50 20 L 0 20')
              setSvgArrowActive('M 45 16 L 50 20 L 45 24')
              setSvgTextX('7')
              setSvgTextY('3')
              setSvgTextRotation('0')
              setActivePower(data.text[0])
              setSvgPosX1(-55)
              setSvgPosY1(-30)
              setSvgPosX1(-40)
              setSvgPosY1(30)
          }
          else {
              setSvgHeight('50')
              setSvgWidth('30')
              setSvgLine('M 20 50 L 20 0')
              setSvgArrowActive('M 16 45 L 20 50 L 24 45')
              setSvgTextX('-10')
              setSvgTextY('-5')
              setSvgTextRotation('270')
              setActivePower(data.text[0])
              setSvgPosX1(-55)
              setSvgPosY1(-30)
          }
        }
        if(data.arrow.drawReactiveNoneDirect){
          setShowPowerInfo(true)
          setShowPowerDirectInfo(false)
          if(data.arrow.arrowReactiveAtTop){
              setSvgHeight('50')
              setSvgWidth('30')
              setSvgLine('M 20 50 L 20 0')
              setSvgArrowReactive('M 16 5  L 20 0 L 24 5')
              setSvgTextX('-10')
              setSvgTextY('-5')
              setSvgTextRotation('270')
              setReactivePower(data.text[1])
              setSvgPosX2(-30)
              setSvgPosY2(-30)
          }
          else if(data.arrow.arrowReactiveAtRight) {
              setSvgHeight('30')
              setSvgWidth('50')
              setSvgLine('M 50 20 L 0 20')
              setSvgArrowReactive('M 5 16  L 0 20 L 5 24')
              setSvgTextX('7')
              setSvgTextY('3')
              setSvgTextRotation('0')
              setReactivePower(data.text[1])
              setSvgPosX2(-40)
              setSvgPosY2(40)
          }
          else if(data.arrow.arrowReactiveAtLeft){
              setSvgHeight('30')
              setSvgWidth('50')
              setSvgLine('M 50 20 L 0 20')
              setSvgArrowReactive('M 45 16 L 50 20 L 45 24')
              setSvgTextX('7')
              setSvgTextY('3')
              setSvgTextRotation('0')
              setReactivePower(data.text[1])
              setSvgPosX2(-40)
              setSvgPosY2(40)
          }
          else {
              setSvgHeight('50')
              setSvgWidth('30')
              setSvgLine('M 20 50 L 20 0')
              setSvgArrowReactive('M 16 45 L 20 50 L 24 45')
              setSvgTextX('-10')
              setSvgTextY('-5')
              setSvgTextRotation('270')
              setReactivePower(data.text[1])
              setSvgPosX2(-30)
              setSvgPosY2(-30)
          }
        }
        if(data.arrow.drawOtherDirect){
          setShowPowerInfo(false)
          setShowPowerDirectInfo(true)
          if(data.arrow.arrowActiveReactiveAtTop){
              setSvgHeight('50')
              setSvgWidth('70')
              setSvgLine('M 10 50 L 10 20')
              setSvgArrowActive('M 6 25 L 10 20 L 14 25')
              setSvgTextX('40')
              setSvgTextY('30')
              setSvgTextX1('40')
              setSvgTextY1('40')
              setSvgTextRotation('0')
              setActivePower(data.text[0])
              setReactivePower(data.text[1])
              setSvgPosX3(20)
              setSvgPosY3(-50)
          }
          else if(data.arrow.arrowActiveReactiveAtRight) {
              setSvgHeight('70')
              setSvgWidth('50')
              setSvgLine('M 50 10 L 20 10')
              setSvgArrowActive('M 25 6 L 20 10 L 25 14')
              setSvgTextX('25')
              setSvgTextY('20')
              setSvgTextX1('25')
              setSvgTextY1('30')
              setSvgTextRotation('0')
              setActivePower(data.text[0])
              setReactivePower(data.text[1])
              setSvgPosX3(-30)
              setSvgPosY3(30)
          }
          else if(data.arrow.arrowActiveReactiveAtLeft){
              setSvgHeight('70')
              setSvgWidth('50')
              setSvgLine('M 50 10 L 20 10')
              setSvgArrowActive('M 45 6 L 50 10 L 45 14')
              setSvgTextX('25')
              setSvgTextY('20')
              setSvgTextX1('25')
              setSvgTextY1('30')
              setSvgTextRotation('0')
              setActivePower(data.text[0])
              setReactivePower(data.text[1])
              setSvgPosX3(-30)
              setSvgPosY3(30)
          }
          else {
              setSvgHeight('50')
              setSvgWidth('70')
              setSvgLine('M 10 50 L 10 20')
              setSvgArrowActive('M 6 45 L 10 50 L 14 45')
              setSvgTextX('40')
              setSvgTextY('30')
              setSvgTextX1('40')
              setSvgTextY1('40')
              setSvgTextRotation('0')
              setActivePower(data.text[0])
              setReactivePower(data.text[1])
              setSvgPosX3(20)
              setSvgPosY3(-50)
          }
        }
      }

    const handleContextMenu = (e: any) => {
        e.evt.preventDefault();
        if(e.evt.shiftKey) return
        setIsOnOpenMenu(true)
        updateSelectControl(id);
    
        const menuHeight = getMenuOptionHeight()
        const clientWidth = lineDiagramRef.current.clientWidth
        const isTransCenter = currentTabId == 1 ? false : true;
        let {posX, posY} = calcContextMenuPosition(
          e, menuHeight, clientWidth, x, y, gridSizeController, isTransCenter
        )
        clearOptionMenu(!clearMenuState)
        
        setMenuPosition({
          x: posX,
          y: posY,
        });
        setIsOpenMenu(true);
      };

    const handleClick = (e: any) => {
        if (e.type === "click") setIsOpenMenu(false);
        if(parentGroupId && parentGroupId > 0) return;
        if (e.evt.shiftKey || isSelectTouchGroup) {
            updateSelectControl(id, true);
        } else {
            updateSelectControl(id);
        }
    };

    const handleOpenMenu = (value: boolean) => {
        setIsOpenMenu(value);
        updateSelectControl(id);
    };

    const handleOpenTransCenterTab = () => {
        // open TransCenter tab

        openDialogEvents(({type: "OPEN_DIALOG", data: { }}))
        saveCurrentTabId(properties.tabId);
        setUndoData({type:null,dataUndo:null} as UndoModel)
    };

    const handleOpenTransCenterProps = async () => {
        // open props popup
        // doSetValuesTransCenter()
        
        let params = {
            userId: userId,
            projectId: projectData.projectId,
            elementId: id,
            elementType: type,
            data: properties,
            ownerProject: projectData.ownerId

        };
        setLoadingFlag(true);
        // getElementParam(params, id);
        const result = await post("/diagram/get-param", params);
        if (result.success) {
            handleGetSuccess(result.data);
        } else {
            handleSubmitError(result.error);
        }
    };
    
    const handleUpdate = async (data: any) => {

        setIsOpenEditorDialog(false)
        // call api
		let params = {
			...data,
			upTransKind: Number(getElementKindValue(data.upTransKind))
		}
        params = convertUndefinedToNull({...params})
        const request = {
            userId: userId,
            projectId: projectData.projectId,
            elementType: getElementKindValue(type),
            elementId: id,
            param: params,
            ownerProject: projectData.ownerId
        }
        // updateElementPost(request, id);
        setLoadingFlag(true);
        const result = await post("/diagram/set-param", request);
        if (result.success) {
            setLoadingFlag(false);
            if(result.data && result.data.resultCode == 0){
                setUndoData({type:"UPDATE_PROPERTIES_ELEMENT", dataUndo:{}} as UndoModel);
		        setInitialValue({ ...data })
                handleUpdateTCSuccess(result.data)
                updateTransCenterProps(id, { ...data })
            }
        } else {
            handleSubmitError(result.error);
        }

        openDialogEvents({type: "RELOAD", data: { }});
    }

	const handleCancelEditorDialog = () => {
        setIsOpenEditorDialog(false)
    }

    const dragBoundFunc = (e: any, mode: string) => {
        // let rect = groupRef.current.getClientRect();
        const newPosition = calcDragBoundPosition(
          e, groupPosition, rotation, height, mode, gridSizeController
        );
    
        return {
          x: newPosition.newX,
          y: newPosition.newY,
        };
      };

    const calcLabelPos = (e?: any) => {
        let newX = groupPosition.x,
            newY = groupPosition.y;
        if (e?.type === "dragmove") {
            newX = groupRef.current.attrs.x;
            newY = groupRef.current.attrs.y;
        }

        const newPosition = {
            x: newX + Constant.ELEMENT_SIZE,
            y: newY + CONTROL_POS_FACTOR * (height / 2),
        };

        if (rotation !== 0) {
            newPosition.x = newX + CONTROL_POS_FACTOR * (height / 2) - 5;
            newPosition.y = newY - Constant.ELEMENT_SIZE;
        }

        setLabelPosition(newPosition);
    };

    const calcArrowPos = (e?: any) => {
        let newX = groupPosition.x,
          newY = groupPosition.y;
        if (e?.type === "dragmove") {
          newX = groupRef.current.attrs.x;
          newY = groupRef.current.attrs.y;
        }
    
        const newPosition = {
          x: newX,
          y: newY + height - Constant.ELEMENT_SIZE - 5,
        };
    
        if (rotation !== 0) {
          newPosition.y = newY;
          rotation === -90 &&
            (newPosition.x = newX + height - Constant.ELEMENT_SIZE - 10);
          rotation === 90 && (newPosition.x = newX + 10);
        }
    
        setArrowPosition(newPosition);
      };

    const handleDragStart = (e: any) => {
        groupRef.current.moveToTop()
        transformRef.current?.moveToTop()
        labelRef.current?.moveToTop()
        rightArrowRef.current?.moveToTop()
        setDragStartPosition({ x: groupPosition.x, y: groupPosition.y, labelX: labelPosition.x, labelY: labelPosition.y });
        setIsOpenMenu(false);
    };

    const handleDragmove = (e: any) => {
        calcLabelPos(e);
        calcArrowPos(e);
    };

    const onDragEnd = (e: any) => {
        if (
            e.currentTarget.attrs.x === groupPosition.x &&
            e.currentTarget.attrs.y === groupPosition.y
        ) {
            return;
        }

        let newX =
            Math.round(e.target.x() / Constant.POINTER_WIDTH) *
            Constant.POINTER_WIDTH;
        let newY =
            Math.round(e.target.y() / Constant.POINTER_HEIGHT) *
            Constant.POINTER_HEIGHT;

        // check new position is outside grid
        if (newX < 0 || newY < 0) {
            setRepositionElement(id, !(elementReposition?.state??true))
            newX = dragStartPosition.x;
            newY = dragStartPosition.y;
        }

        setGroupPosition({
            x: newX,
            y: newY,
        });

        e.target.to({
            x: newX,
            y: newY,
        });
    };

    // Mobile Touch Events
    var isMoving = false
  var isLongPress = false
  
  const handleTouchStart = useCallback((e:any) => {
    setIsOpenMenu(false)
    isLongPress = true
    isMoving = false
    !isSelectTouchGroup && setTimeout(() => {
      if (isLongPress && !isMoving) {
        handleContextMenu(e)
      }
    }, 1000)
  },[x,y,isSelectTouchGroup])

  const handleTouchMove = useCallback(() => {
    isMoving = true
  },[x,y,isSelectTouchGroup])

  const handleTouchEnd = useCallback((e:any) => {
    isLongPress = false
  },[x,y,isSelectTouchGroup])

    const handleMouseDown = (e: any, mode: string) => {
        const container = e.target.getStage().container();
        container.style.cursor = rotation === 0 ? "ns-resize" : "ew-resize";
        setIsResizing(true);
    };

    const handleMouseMove = (e: any, mode: string) => {
        if (!isResizing) return;
        setHeightTransform(e.evt.movementY);
    };

    const handleMouseUp = (e: any, mode: string) => {
        setTransformFlag(true);
        const container = e.target.getStage().container();
        container.style.cursor = "default";
    
        let newPosX = e.target._lastPos.x // e.evt.offsetX
        let newPosY = e.target._lastPos.y // e.evt.offsetY
    
        let newEle = calcElementSize(
          newPosX,
          newPosY,
          groupPosition,
          rotation,
          height,
          offsetY,
          mode,
          gridSizeController
        )
    
        topRef.current.y(0);
        // bottomRef.current.y(newHeight - 7)
        setGroupPosition({ x: newEle.newX, y: newEle.newY });
    
        updateControlHeight(id, newEle.newHeight, newEle.newX, newEle.newY, newEle.newOffsetY);
        setIsResizing(false);
      };

    const handleSubmitError = (error: any) => {
        console.log(">>>> handle Error");
        console.log(error);
        setLoadingFlag(false);
    }
      
    const handleGetSuccess = (data: any) => {
        let newData = data.data
        let initData = {...initialValue}
        
        let convertData = convertVoltToOptionList(newData.voltageList)
        
        doSetValuesTransCenter({...initData, voltageList: convertData})
    }
    
    const doSetValuesTransCenter = (transCenter: any) => {
		let tmpCalc = doGetValuesTransCenter(
			{...transCenter},
			transCenterDiagram,
		)
		setInitialValue(tmpCalc.newProps)
		setCapacityXPowerFactor(tmpCalc.capacityXPowerFactor)
		updateTransCenterProps(id, tmpCalc.newProps)

        setLoadingFlag(false)
        setIsOpenEditorDialog(true)
	}
    //#endregion

    return (
        <>
            {isOpenMenu &&
                <OptionMenu
                    controlId={id}
                    isOpenMenu={isOpenMenu}
                    setIsOpenMenu={handleOpenMenu}
                    onProperties={handleOpenTransCenterProps}
                    x={menuPosition.x}
                    y={menuPosition.y}
                    isDisabledDelete={false}
                    isDisabledCut={false}
                    isDisabledCopy={false}
                    isDisabledPaste={false}
                    isDisabledOpenGraph={false}
                    isDisabledProps={false}
                    isDisabledUserCurve={true}
                />
            }

    <Label
        ref={labelRef}
        listening={false}
        width={Constant.LABEL_WIDTH}
        x={labelPosition.x}
        y={labelPosition.y}
        offsetX={[90, -90].includes(rotation) ? 8 : -5}
        offsetY={
          rotation === 0 ? (labelTexts.length * Constant.LINE_HEIGHT) / 2 : 0
        }
    >
        {labelTexts.length > 0 &&
         labelTexts.map((item:any, index:any) => {
            
          let content = item.value; 
          let offsetY = index * Constant.LINE_HEIGHT;
          rotation !== 0 &&
            index !== 0 &&
            (offsetY = (index - 1) * Constant.LINE_HEIGHT + width + Constant.ELEMENT_SIZE);

          return (
            <Text
              y={offsetY + 10}
              key={index}
              text={content}
              fill={isSelected ? "red" : item.color}
            />
          );
        })}
    </Label>

            <Group
                ref={groupRef}
                id={id}
                x={groupPosition.x}
                y={groupPosition.y}
                offset={{ x: offsetX, y: offsetY }}
                width={width}
                height={height}
                draggable={processMode === ProcessMode.DRAWING && !m_bModePM && !modeViewOnly && !isOnShiftKey}
                type={type}
                onClick={handleClick}
                onTap={handleClick}
                onContextMenu={handleContextMenu}
                rotation={rotation}
                onDblClick={handleOpenTransCenterTab}
                onDblTap={handleOpenTransCenterTab}
                onMouseDown={() =>
                    transformMoveRef.current?.nodes([groupRef.current])
                }
                onMouseUp={() => transformMoveRef.current?.nodes([])}
                onDragStart={handleDragStart}
                onDragMove={handleDragmove}
                onDragEnd={onDragEnd}
                onMouseLeave={(e: any) => {
                    const container = e.target.getStage().container();
                    container.style.cursor = "default";
                }}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
            >
                <Image
                    fill={getSymbolAndBackColor(infoSkeleton,processMode,properties).backColor}
                    image={lineObj}
                    height={height}
                    onMouseEnter={(e: any) => {
                        const container = e.target.getStage().container();
                        container.style.cursor = (processMode === ProcessMode.DRAWING && !m_bModePM && !modeViewOnly) ? "move" : "crosshair";
                    }}
                />
                <Image
                    fill={getSymbolAndBackColor(infoSkeleton,processMode,properties).backColor}
                    image={imageObj}
                    width={Constant.ELEMENT_SIZE}
                    height={Constant.ELEMENT_SIZE}
                    y={height - Constant.ELEMENT_SIZE}
                    onMouseEnter={(e: any) => {
                        const container = e.target.getStage().container();
                        container.style.cursor = (processMode === ProcessMode.DRAWING && !m_bModePM && !modeViewOnly) ? "move" : "crosshair";
                    }}
                />

                {processMode === ProcessMode.DRAWING && !m_bModePM && !modeViewOnly && isSelected && !parentGroupId && (
                    <>
                        <Rect
                            ref={topRef}
                            fill="black"
                            x={6.75}
                            y={0}
                            width={7}
                            height={7}
                            draggable
                            onMouseDown={() => setTransformFlag(false)}
                            dragBoundFunc={(e: any) => dragBoundFunc(e, "top")}
                            onDragStart={(e: any) => handleMouseDown(e, "top")}
                            onDragMove={(e: any) => handleMouseMove(e, "top")}
                            onDragEnd={(e: any) => handleMouseUp(e, "top")}
                            onMouseEnter={(e: any) => {
                                const container = e.target.getStage().container();
                                container.style.cursor = "ns-resize";
                            }}
                        />
                        <Rect
                            ref={bottomRef}
                            fill="black"
                            x={6.75}
                            y={height - 7}
                            width={7}
                            height={7}
                            draggable
                            onMouseDown={() => setTransformFlag(false)}
                            dragBoundFunc={(e: any) => dragBoundFunc(e, "bottom")}
                            onDragStart={(e: any) => handleMouseDown(e, "bottom")}
                            onDragMove={(e: any) => handleMouseMove(e, "bottom")}
                            onDragEnd={(e: any) => handleMouseUp(e, "bottom")}
                            onMouseEnter={(e: any) => {
                                const container = e.target.getStage().container();
                                container.style.cursor = "ns-resize";
                            }}
                        />
                    </>
                )}
            </Group>
            
            { showPowerInfo &&
                <>
                <Image  
                    image={imageSVGActivePower} 
                    listening={false}
                    x={arrowPosition.x + svgPosX1}
                    y={arrowPosition.y + svgPosY1}
                />
        
                <Image  
                    image={imageSVGReactivePower} 
                    listening={false}
                    x={arrowPosition.x + svgPosX2}
                    y={arrowPosition.y + svgPosY2}
                />
                </>
                
            }
            { showPowerDirectInfo &&
                <>
                <Image  
                    image={imageSVGActiveReactivePower} 
                    listening={false}
                    x={arrowPosition.x + svgPosX3}
                    y={arrowPosition.y + svgPosY3}
                />
                </>
                
            }
            
            {(properties.fault && !([Model.ProcessMode.VOLTDROP,Model.ProcessMode.IMPROVE_PF,Model.ProcessMode.HARMONIC,Model.ProcessMode.POWER_FLOW].includes(processMode)) && !m_bModePM) && (
                <>
                <Image
                ref={rightArrowRef}
                listening={false}
                offsetY={[90, -90].includes(rotation) ? Constant.ELEMENT_SIZE / 4 : 0}
                offsetX={-Constant.ELEMENT_SIZE / 2}
                x={arrowPosition.x}
                y={arrowPosition.y - 15}
                image={rightArrowObj}
                width={Constant.ELEMENT_SIZE * 2}
                height={Constant.ELEMENT_SIZE * 2}
                />
                <Text
                text={properties.pointText}
                fontSize={10}
                fill="red"
                offsetY={[90, -90].includes(rotation) ? Constant.ELEMENT_SIZE / 2 : 0}
                offsetX={-Constant.ELEMENT_SIZE / 2}
                x={arrowPosition.x + 10}
                y={arrowPosition.y - 15}
                />
                </>
            )}

            <Transformer
                ref={transformRef}
                rotateEnabled={false}
                resizeEnabled={false}
                keepRatio={false}
                borderDash={[4]}
                anchorStroke="black"
                borderStroke="black"
                anchorFill="black"
            />

            <Transformer
                ref={transformMoveRef}
                rotateEnabled={false}
                resizeEnabled={false}
                keepRatio={false}
                borderEnabled={false}
                anchorStroke="black"
                borderStroke="black"
                anchorFill="black"
            />
            {loadingFlag && (
                <Html>
                    <Dialog open={true} maxWidth={"md"} fullWidth>
                        <div style={{ top: "0px", left: "0px", position: "fixed", zIndex: 1500, width: "100%", height: "100%", padding: "50vh 50% 50% 50%", background: "#00000030" }}>
                            <CircularProgress/>
                        </div>
                    </Dialog>
                </Html>
            )}
            {isOpenEditorDialog && !isHidden && (
                <Html>
                    <Dialog open={true} fullWidth PaperComponent={DraggablePaper}>
                        <DialogTitle
                            onMouseOver={(e : any) => e.target.style.cursor = 'move'}
                            style={{
                                paddingTop: '2px',
                                paddingBottom: '2px',
                                background: theme.palette.primary.main,
                                color: theme.palette.primary.contrastText,
                            }}
                        >
                            変台
                        </DialogTitle>
                        <DialogContent sx={{ pb: 1.5 }}>
                            <MSTransCenterEditDialog
                                transCenterDiagram={transCenterDiagram}
                                data={initialValue}
                                haveSourceVoltage={haveSourceVoltage}
                                sourceVoltText={sourceVoltText}
                                capacityXPowerFactor={capacityXPowerFactor}
                                setCapacityXPowerFactor={setCapacityXPowerFactor}
                                onCancel={handleCancelEditorDialog}
                                onOK={handleUpdate}
                                userRole={userRole}
                                m_bModePM={m_bModePM}
                                processMode={processMode}
                                modeViewOnly={modeViewOnly}
                            />
                        </DialogContent>
                    </Dialog>
                </Html>
            )}
            
        </>
    );
});

const mapStateToProps = (state: any) => {
    return {
        userId: state.app.user?.userId,
        projectData: state.app.projectData,
        processMode: state.app.diagram.processMode,
        mainDiagram: state.app.diagram.diagramData.filter(
            (r: any) => r.tabId === 1
        )[0].shape,
        diagramList: state.app.diagram.diagramData,
		transCenterUpdateTimes: state.app.diagram.transCenterUpdate,
        openDialogData : state.app.diagram.openDialog["OPEN_DIALOG"],
        userRole: state.app.user?.userRole,
        m_bModePM: state.app.diagram.m_bModePM,
        modeViewOnly: state.app.diagram.modeViewOnly,
        infoSkeleton: state.app.diagram.infoSkeleton,
        diagramData: state.app.diagram.diagramData.filter((r: any) => r.tabId === state.app.diagram.currentIDDiagramTab),
        drawPowerArrow: state.app.diagram.isDrawPowerArrow,
        isSelectTouchGroup: state.app.diagram.isSelectTouchGroup,
        currentTabId: state.app.diagram.currentIDDiagramTab,
        diagramDataUpdate: state.app.diagram.diagramDataUpdate,
        clearMenuState: state.app.diagram.clearOptionMenu,
        tcGroups: state.app.diagram.tcGroups,
        elementGroups: state.app.diagram.elementGroups,
        elementReposition: state.app.diagram.elementReposition,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        updateSelectControl: (controlId: string, isMultiple : boolean = false) =>
            dispatch(updateSelectControl(controlId, isMultiple)),
        updateControlHeight: (
            id: string,
            height: number,
            x: number,
            y: number,
            offsetY: number
        ) => dispatch(updateControlHeight(id, height, x, y, offsetY)),
        setTransformFlag: (visible: boolean) =>
            dispatch(setTransformFlag(visible)),
        saveCurrentTabId: (tabId: number) => dispatch(saveCurrentIdDiagramTab(tabId)),
        updateTransCenterProps: (id: string, data: object) =>
            dispatch(updatePropertiesOfTransCenter(id, data)),
        // getElementParam: (data: any, controlId: string) => 
        //     dispatch(actionCreators.fetch(`GET_TRANS_CENTER${controlId}`, "/diagram/get-param", "POST", data, false, true)),
        // updateElementPost: (data: any, controlId: string) => 
        //     dispatch(actionCreators.fetch(
        //         // `UPDATE_TRANS_CENTER${controlId}`,
        //         `UPDATE_TRANS_CENTER`,
        //         "/diagram/set-param",
        //         "POST",
        //         data,
        //         false,
        //         true
        //     )
        // ),
        openDialogEvents: (param : any) => dispatch(openDialogEvents(param)),
        setUndoData: (data:UndoModel) => dispatch(undo(data)),
        clearOptionMenu: (state:boolean) => dispatch(clearOptionMenu(state)),
        setRepositionElement: (id:any, state:boolean) => dispatch(setRepositionElement(id, state)),
        updateElementPosition:(id:any, x:any, y:any) => dispatch(updateElementPosition(id, x, y)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateMSTransCenter);