import { Button, ButtonGroup, Container, Dialog, DialogContent, DialogTitle, Stack, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import theme from "../../themes/globalStyles";
import DraggablePaper from "../common/DraggablePaper";

export type AddProjectForm = {
    projectName : string
}

export type AddNewProjectProps = {
    title?: string;
    fieldName?: string;
    data: AddProjectForm;
    onOK: (data: any) => void;
    onCancel: () => void;
};

export const AddNewProjectDialog = (props: AddNewProjectProps) => {

    //#region fields
    const { title, fieldName, data, onOK, onCancel } = props;

    // 初期値設定
    const initialValue : AddProjectForm = {
        ...data,
    }

    const { control, handleSubmit, formState: { errors }, setValue, getValues } = useForm<AddProjectForm>({
        mode: "all",
        criteriaMode: "all",
        defaultValues: initialValue,
    })
    //#endregion fields

    //#region methods
    // Submitイベント
    const handleEditSubmit = (value: any, event: any) => {
        const request = createRequestData(value);
        onOK(request);
    }
    
    // FormデータからRequestデータを作成する
    const createRequestData = (formValue : AddProjectForm) => {       
        let request = {
            ...formValue,
        } as AddProjectForm;
        return request;
    }
    //#endregion methods

    return (
        <>
            <Dialog open={true} maxWidth={"sm"} fullWidth
                PaperComponent={
                    DraggablePaper
                }
            >
                <DialogTitle 
                    onMouseOver={(e : any) => e.target.style.cursor = 'move'}
                    style={{ paddingTop: '2px', paddingBottom: '2px', background: theme.palette.primary.main, color: theme.palette.primary.contrastText }}
                >
                    {title || `プロジェクト追加`}
                </DialogTitle>
                <DialogContent style={{paddingTop: '10px', height: '150px', display: 'flex', alignItems: 'flex-end'}}>
                    <div style={{width: '100%'}}>
                        <form onSubmit={handleSubmit(handleEditSubmit)}>
                            <Controller
                                name="projectName"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        fullWidth
                                        type="text"
                                        size="small"
                                        label={fieldName||"プロジェクト名"}
                                        InputLabelProps={{
                                            style: { color: 'black' },
                                        }}
                                    />
                                )}
                            />
                            <Stack direction='row' justifyContent="flex-end" gap={2} sx={{ mt: 4 }}>
                                <Button variant="contained" type="submit">{title ? '保存' : '追加'}</Button>
                                <Button color="inherit" variant="contained" onClick={onCancel}>キャンセル</Button>
                            </Stack>
                        </form>
                    </div>                    
                </DialogContent>
            </Dialog>
        </>
    )
}
export default AddNewProjectDialog;