import { MenuItem } from "@mui/material";
import * as React from "react";
import { CustomTextField } from "../styles";
import { OptionItem } from "../../models/Index";

export type TextSelectorProps = {
  name?: string;
  value: string | null;
  label?: string;
  options?: OptionItem[];
  onSelectValue?: (value: string) => void;
  onBlur?: React.FocusEventHandler<any>;
  onChange?: React.ChangeEventHandler<any>;
  error?: boolean;
  helperText?: string | undefined;
  required?: boolean
};

export const TextSelector = (props: TextSelectorProps, { ...prop }) => {
  const { name, value, label, options, onSelectValue,
    onBlur, onChange, error, helperText, required } = props;

  const [selectValue, setSelectValue] = React.useState(value);
  const handleChangeSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectValue(event.target.value);
    if (onSelectValue) onSelectValue(event.target.value);
  };

  return (
    <>
      <CustomTextField
        name={name}
        id="outlined-select-currency"
        fullWidth
        select
        label={label}
        value={onChange ? value : selectValue}
        onChange={onChange ? onChange : handleChangeSelect}
        size="small"
        onBlur={onBlur}
        error={error}
        helperText={helperText}
        required={required}
        sx={{ borderRadius: 1 }}
      >
        {options?.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </CustomTextField>
    </>
  );
};

export default TextSelector;
