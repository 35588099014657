import React, { useEffect, useRef, useState } from 'react'
import { EquipWirecModel } from '../../../models/breakModel'
import { Box, Button, CircularProgress, Grid, Stack, Tab, Tabs, TextField, Typography } from '@mui/material'
import { ApplicationState, actionCreators, selectors } from '../../../store'
import { connect } from 'react-redux'
import { Controller, useForm } from 'react-hook-form'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Path as RoutePath } from "../../../path";
import FetchHelper from '../../FetchHelper'
import { LoadingButton } from '@mui/lab'
//-----------
//FETCH ID
const GET_EQUIP_WIREC = "GET_EQUIP_WIREC"
const UPDATE_EQUIP_WIREC = "UPDATE_EQUIP_WIREC"
const CREATE_EQUIP_WIREC = "CREATE_EQUIP_WIREC"
const initialValue: EquipWirecModel = {
    lWirecId: 0,
    nCircuitNumber: 0,
    dFactora: 0,
    dFactorb: 0,
    dFactorc: 0,
    dFactord: 0,
    dFactore: 0,
    dFactorf: 0,
    dFactorg: 0,
}
// -------------
// Props
export type EquipWirecEditProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
const EquipWirecEdit = (props: EquipWirecEditProps) => {
    const {
        userId,
        onCreate, 
        onCreateError,
        onUpdate, 
        onUpdateError,
        onSuccess, 
        onError,
    } = props;

    const { control, formState: { errors }, handleSubmit, setValue, getValues, } = useForm<EquipWirecModel>({
        criteriaMode: "all",
        defaultValues: initialValue,
        mode: "all",
    })
    type MyParams = {
        id: string;
        mode: string;
    };
    let { id, mode } = useParams<keyof MyParams>() as MyParams;
    const location = useLocation();
    let navigate = useNavigate();
    const [loadingFlag, setLoadingFlag] = useState(mode === "edit" ? true : false);
    const gridRef = useRef() as any;
    const Exp = /^[-+]?\d+(\.\d+)?$/;
    const messageIsNotNumber = "数字を入力してください。";

    const handleSuccess = (data: any) => {
        console.log('>>>> handle Successs');
        // if(data && data.data){
        const obj = data.data;
        setValue("lWirecId", obj.lWirecId);
        setValue("nCircuitNumber", obj.nCircuitNumber??"");
        setValue("dFactora", obj.dFactora??"");
        setValue("dFactorb", obj.dFactorb??"");
        setValue("dFactorc", obj.dFactorc??"");
        setValue("dFactord", obj.dFactord??"");
        setValue("dFactore", obj.dFactore??"");
        setValue("dFactorf", obj.dFactorf??"");
        setValue("dFactorg", obj.dFactorg??"");
        // }
        setLoadingFlag(false);
    }

    // 検索エラー時
    const handleError = (success: boolean, data: any, error: any) => {
        console.log('>>>> handle Error');
        gridRef.current.hideOverlay();
    }

    // 検索実行
    const handleEditSubmit = (data: EquipWirecModel) => {
        setLoadingFlag(true);
        if (mode === "edit") {
            let request = {
                lWirecId: getValues("lWirecId"),
                nCircuitNumber: getValues("nCircuitNumber") ?? null,
                dFactora: getValues("dFactora") ?? null,
                dFactorb: getValues("dFactorb") ?? null,
                dFactorc: getValues("dFactorc") ?? null,
                dFactord: getValues("dFactord") ?? null,
                dFactore: getValues("dFactore") ?? null,
                dFactorf: getValues("dFactorf") ?? null,
                dFactorg: getValues("dFactorg") ?? null
            } as any
            const object = {
                requestPrms: request,
                userId: userId,
            }
            onUpdate(object);
        } else if (mode === "add") {
            let request = {
                nCircuitNumber: getValues("nCircuitNumber") ?? null,
                dFactora: getValues("dFactora") ?? null,
                dFactorb: getValues("dFactorb") ?? null,
                dFactorc: getValues("dFactorc") ?? null,
                dFactord: getValues("dFactord") ?? null,
                dFactore: getValues("dFactore") ?? null,
                dFactorf: getValues("dFactorf") ?? null,
                dFactorg: getValues("dFactorg") ?? null
            } as any
            const object = {
                requestPrms: request,
                userId: userId,
            }
            onCreate(object);
        }
    }

    // キャンセル
    const handleCancel = () => {
        if (location.key !== "default")
            navigate(-1);
        else
            navigate(RoutePath.EquipWirecList);
    }
    // Submit後のAPI呼び出しエラー時
    const handleErrorUpdate = (success: boolean, data: any, error: any) => {
        onUpdateError();
        setLoadingFlag(false);
    }

    // Submit後のAPI呼び出し成功時
    const handleSuccessSubmit = (success: boolean, data: any, error: any) => {
        onSuccess();
        if (mode === "add") {
            navigate(RoutePath.EquipWirecEdit + `/edit/${data.data.lWirecId}`, { replace: true })
        }
        setLoadingFlag(false);
    }

    // Submit後のAPI呼び出しエラー時
    const handleErrorSubmit = (success: boolean, data: any, error: any) => {
        onCreateError();
        setLoadingFlag(false)
    }

    //入力欄背景
    const divStyles = {
        backgroundColor: 'white',
        border: '1px solid #bebebe',
        boxShadow: '0 0 5px #fff, 0 0 5px #bebebe, 0 0 1px #aaa',
        padding: '20px 40px',
        marginTop: '40px',
    }

    return (
        <>
            {
                mode === "edit" &&
                <FetchHelper
                    fetchId={GET_EQUIP_WIREC}
                    url={`/master/equip_wirec/get`}
                    method="POST"
                    request={true}
                    params={{
                        requestPrms: {
                            id: id
                        },
                        userId: userId,
                    }}
                    json={true}
                    onComplete={(success, data, error) => {
                        success ? handleSuccess(data) : handleError(success, data, error);
                    }}
                />
            }
            {loadingFlag && (
                <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1500, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                    <CircularProgress />
                </div>
            )}
            <div style={{ height: "100%", width: "100%", marginTop: "10px" }}>
                <Stack direction="row" justifyContent="flex-start" alignItems="center" sx={{}} style={{ margin: '18px', color: 'MenuText' }}>
                    <Typography variant="h4">
                        電線(C)詳細
                    </Typography>
                </Stack>
                <form onSubmit={handleSubmit(handleEditSubmit)}>
                    <div style={divStyles}>
                        <Typography variant="h6">
                            電線(C)
                        </Typography>
                        <Stack mt={2} direction={{ xs: 'column', sm: 'row' }} sx={{ maxHeight: "calc(100vh)", overflow: 'auto' }}>
                            <Grid container spacing={3} direction={'row'}>
                                <Grid item xs={6} mt={1}>
                                    <Controller
                                        control={control}
                                        name="nCircuitNumber"
                                        rules={{
                                            pattern: {
                                                value: Exp,
                                                message: messageIsNotNumber,
                                            }
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                fullWidth
                                                {...field}
                                                error={!!errors?.nCircuitNumber}
                                                helperText={errors?.nCircuitNumber?.message}
                                                type="text"
                                                size="small"
                                                label="回路数"
                                            // InputLabelProps={{ shrink: true }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6} mt={1}>
                                    <Controller
                                        control={control}
                                        name="dFactora"
                                        rules={{
                                            pattern: {
                                                value: Exp,
                                                message: messageIsNotNumber,
                                            }
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                fullWidth
                                                {...field}
                                                error={!!errors?.dFactora}
                                                helperText={errors?.dFactora?.message}
                                                type="text"
                                                size="small"
                                                label="A方式補正係数"
                                            // InputLabelProps={{ shrink: true }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Controller
                                        control={control}
                                        name="dFactorb"
                                        rules={{
                                            pattern: {
                                                value: Exp,
                                                message: messageIsNotNumber,
                                            }
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                fullWidth
                                                {...field}
                                                error={!!errors?.dFactorb}
                                                helperText={errors?.dFactorb?.message}
                                                type="text"
                                                size="small"
                                                label="B方式補正係数"


                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Controller
                                        control={control}
                                        name="dFactorc"
                                        rules={{
                                            pattern: {
                                                value: Exp,
                                                message: messageIsNotNumber,
                                            }
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                fullWidth
                                                {...field}
                                                error={!!errors?.dFactorc}
                                                helperText={errors?.dFactorc?.message}
                                                type="text"
                                                label="C方式補正係数"
                                                size="small"

                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Controller
                                        control={control}
                                        name="dFactord"
                                        rules={{
                                            pattern: {
                                                value: Exp,
                                                message: messageIsNotNumber,
                                            }
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                fullWidth
                                                {...field}
                                                error={!!errors?.dFactord}
                                                helperText={errors?.dFactord?.message}
                                                type="text"
                                                label="D方式補正係数"
                                                size="small"

                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Controller
                                        control={control}
                                        name="dFactore"
                                        rules={{
                                            pattern: {
                                                value: Exp,
                                                message: messageIsNotNumber,
                                            }
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                fullWidth
                                                {...field}
                                                error={!!errors?.dFactore}
                                                helperText={errors?.dFactore?.message}
                                                type="text"
                                                label="E方式補正係数"
                                                size="small"

                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Controller
                                        control={control}
                                        name="dFactorf"
                                        rules={{
                                            pattern: {
                                                value: Exp,
                                                message: messageIsNotNumber,
                                            }
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                fullWidth
                                                {...field}
                                                error={!!errors?.dFactorf}
                                                helperText={errors?.dFactorf?.message}
                                                type="text"
                                                label="F方式補正係数"
                                                size="small"

                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Controller
                                        control={control}
                                        name="dFactorg"
                                        rules={{
                                            pattern: {
                                                value: Exp,
                                                message: messageIsNotNumber,
                                            }
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                fullWidth
                                                {...field}
                                                error={!!errors?.dFactorg}
                                                helperText={errors?.dFactorg?.message}
                                                type="text"
                                                label="G方式補正係数"
                                                size="small"

                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Stack>
                    </div>
                    <Stack direction='row' justifyContent="flex-end" spacing={2} sx={{ mt: 1, mr: 2 }}>
                        <LoadingButton variant="contained" type="submit" loading={loadingFlag}>保存</LoadingButton>
                        <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                    </Stack>
                </form>
            </div>
            <FetchHelper fetchId={UPDATE_EQUIP_WIREC}
                onComplete={(success: boolean, data: any, error: any) =>
                    success ? handleSuccessSubmit(success, data, error) : handleErrorUpdate(success, data, error)} />

            <FetchHelper fetchId={CREATE_EQUIP_WIREC}
                onComplete={(success, data, error) => success ?
                    handleSuccessSubmit(success, data, error) :
                    handleErrorSubmit(success, data, error)} />

        </>
    );
}
const mapStateToProps = (state: ApplicationState) => (
    {
        userId: state.app.user?.userId,
    }
)
const mapDispatchToProps = (dispatch: any) => {
    return {
        onCreate: (data: any) => dispatch(actionCreators.fetch(CREATE_EQUIP_WIREC, `/master/equip_wirec/add`, "POST", data, false, true)),
        onUpdate: (data: any) => dispatch(actionCreators.fetch(UPDATE_EQUIP_WIREC, `/master/equip_wirec/modify`, "POST", data, false, true)),
        onSuccess: () =>
            dispatch(actionCreators.showMessage({
                type: "info",
                title: "電線(C)更新",
                body: "電線(C)を更新しました。",
            })),
        onError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "検索",
                body: "検索できません。",
            })),
        onCreateError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "電線(C)登録",
                body: "電線(C)を登録出来ませんでした。",
            })),
        onUpdateError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "電線(C)編集",
                body: "電線(C)を更新出来ませんでした。",
            })),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(EquipWirecEdit)