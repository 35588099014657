import { Box, Button, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, Stack, Tab, Tabs, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { MsCableHeadModel } from '../../models/Index';
import TextFieldOptions from "../common/TextFieldOptions";

export type MsCableHeadEditDialogFormProps = {
    data: any;
    onOK: (data: any) => void;
    onCancel: () => void;
    setIsVoltText: string;
    setIsConectTrans1OrTranscott:boolean;
    m_bModePM: boolean;
    modeViewOnly:boolean;
};

function a11yProps(index: number) {
    return {
        id: `tab-${index}`,
        "aria-controls": `tabpanel-${index}`,
    };
}

//Tabs------------------
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const radioStyles = {
    padding: '4px 4px 4px 4px'
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}


export const MsCableHeadEditDialog = (props: MsCableHeadEditDialogFormProps) => {
    const { data, onOK, onCancel,setIsVoltText,setIsConectTrans1OrTranscott,m_bModePM, modeViewOnly} = props;
    const [selectTab, setSelectTab] = useState(0);
    const [isDisableOKBtn, setIsDisableOKBtn] = useState(false);
    // 初期値設定
    const initialValue: MsCableHeadModel = {
        ...data
    }
    const { control, handleSubmit, formState: { errors }, setValue,getValues,setError } = useForm<MsCableHeadModel>({
        mode: 'all',
        criteriaMode: "all",
        defaultValues: initialValue,
    });

    // Submitイベント
    const handleEditSubmit = (value: MsCableHeadModel) => {
        const request = createRequestData(value);
        onOK(request);
    }

    // FormデータからRequestデータを作成する
    const createRequestData = (formValue: MsCableHeadModel) => {
        let request = {
            ...formValue
        } as MsCableHeadModel;
        return request;
    }

    // キャンセル
    const handleCancel = () => {
        onCancel();
    };

    const handleChangeFormat = (e: any) => {
        setValue(e.target.name, e.target.value,{shouldValidate:true});
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        if (newValue >= 0) {
            if (getValues('refNo') == "" ||getValues('refNo') == null || getValues('refNo') == undefined ) {
                setError("refNo",{ type: 'custom', message: '必須項目です。入力してください。' });
            }
            else if (isNaN(Number(getValues('voltage'))) || Number(getValues('voltage')) <= 0 || !/^[0-9\b]+$/.test(getValues('voltage').toString())) {
                setError("voltage",{ type: 'custom', message: '整数 > 0 を入力してください。' });
            }
            else{
                setSelectTab(newValue);
            }
        }
        else{
        setSelectTab(newValue);
        }
    };
    const handleInput = (e: any) => {
        setValue(e.target.name, e.target.value,{shouldValidate:true})
        const isInteger = /^\d*$/.test(getValues("voltage").toString())
        console.log(isInteger); 
        if(getValues("voltage") > 0 && isInteger){
            // setIsDisableCalcBtn(false);
            setIsDisableOKBtn(false);
        }
        else{
            // setIsDisableCalcBtn(true);
            setIsDisableOKBtn(true);
        }
    }
    return (
        <>
            <div style={{ height: '100%', width: '100%' }}>
                <form onSubmit={handleSubmit(handleEditSubmit)}>
                    <div style={{ height: '175px', width: '100%', margin: 'auto', overflow: "auto" }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={selectTab} onChange={handleChange}
                                variant="scrollable" 
                                scrollButtons 
                                allowScrollButtonsMobile 
                                sx={{
                                    '& .MuiButtonBase-root.MuiTabScrollButton-root.Mui-disabled': {
                                        display: "none"
                                    }
                                }}
                            >
                                <Tab label="基本" {...a11yProps(0)} />
                                <Tab label="シンボル" {...a11yProps(1)} />
                            </Tabs>
                        </Box>

                        <TabPanel value={selectTab} index={0}>
                        <Stack spacing={0.3}>
                                <Grid container mt={2}>
                                    <Grid item xs={5.5}>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center",marginTop:"5px" }}>
                                            <Typography variant="body2" width="50%">デバイス名:</Typography>
                                            <Controller
                                                name="refNo"
                                                control={control}
                                                rules={{
                                                    required: '必須項目です。入力してください。',
                                                }}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        // label="デバイス名"
                                                        error={!!errors?.refNo}
                                                        helperText={errors?.refNo?.message}
                                                        onBlur={handleChangeFormat}
                                                        onFocus={e => e.target.select()}
                                                        InputLabelProps={{
                                                            style: { color: 'black' },
                                                        }}
                                                        InputProps={{sx: { height: 32 ,fontSize:'14px',background:m_bModePM || modeViewOnly?'floralwhite':'white', color:modeViewOnly?"grey":"black"}}}
                                                        disabled={m_bModePM}
                                                        inputProps={{readOnly:modeViewOnly}}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                        
                                        <Stack sx={{ flexDirection: "row", alignItems:"center", marginTop:"2px"}}>
                                            <Typography variant="body2" width="50%">回路電圧(V):</Typography>
                                                <FormControl fullWidth size="small">
                                                    <Controller
                                                        name="voltage"
                                                        control={control}
                                                        rules={{
                                                            required: '必須項目です。入力してください。' ,
                                                            pattern:{
                                                                value: /^[0-9\b]+$/,
                                                                message: '数字を入力してください。'
                                                            },
                                                            validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                            // maxLength: {
                                                            //     value: 6,
                                                            //     message: "最大長さ <= 6 を入力してください。"
                                                            // }
                                                                }}
                                                        render={({ field }) => (
                                                            <TextFieldOptions
                                                                    {...field}
                                                                    options={initialValue.voltageList}
                                                                    // label="回路電圧(V)"
                                                                    // onChange= {handleChangeFormat}
                                                                    size="small"

                                                                    setValue={(name: any, value: any) => {
                                                                        // handleChangeFormat({target: {name: name, value: value}});
                                                                        handleInput({target: {name: name, value: value}})
                                                                    }}
                                                                    maxLength={6}
                                                                    error={!!errors?.voltage}
                                                                    helperText={errors?.voltage?.message}
                                                                    disabled={getValues("isSourceVoltage")||m_bModePM || modeViewOnly}
                                                                    height={32}
                                                                />
                                                        )}
                                                    />
                                            </FormControl>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={6.5} pl={2} >
                                        
                                        
                                    </Grid>
                                    {/* ModePM */}
                                    
                                </Grid>
                            </Stack>
                        </TabPanel>

                        <TabPanel value={selectTab} index={1}>
                        <Stack mt={1} width={'75%'}>
                            <fieldset style={{border:'1px solid #AAAA'}}>
                                <legend><Typography variant="body2">単線結線図で表示するシンボルの選択</Typography></legend>
                                <Grid container>
                                    <Grid item xs={5}>
                                        <Controller
                                            name='symKind'
                                            control={control}
                                            render={({ field }) => (
                                                <RadioGroup {...field}>
                                                    <FormControlLabel 
                                                        value='0' 
                                                        control={<Radio size='small'/>} 
                                                        label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>下向き▽</Typography>}
                                                        disabled={m_bModePM || modeViewOnly}
                                                    />
                                                    <FormControlLabel 
                                                        value='1' 
                                                        control={<Radio size='small'/>} 
                                                        label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>上向き△</Typography>}
                                                        disabled={m_bModePM || modeViewOnly}
                                                    />
                                                </RadioGroup>
                                            )}
                                        />
                                    </Grid>
                                    
                                </Grid>
                            </fieldset>
                        </Stack>
                        </TabPanel>

                    </div>
                    <Stack direction='row' justifyContent="flex-end" spacing={2} mt={2}>
                        <Button variant="contained" type="submit" disabled={isDisableOKBtn || modeViewOnly}>OK</Button>
                        <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                    </Stack>
                </form >
            </div>
        </>
    );
};
export default MsCableHeadEditDialog;


