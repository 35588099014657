import { Node, NodeConfig } from "konva/lib/Node";
import { CognitoUser } from 'amazon-cognito-identity-js';

// ユーザ情報格納用の型定義
export interface UserDetail {
    name: any;
    authCodes: string[];
    company_name:string;
    // TODO: 
    id_token: string;
    access_token: string;
    refresh_token: string;
    cognit_user: any | CognitoUser;
    cognito_username: string;
    email: string;
    // family_name: string;
    // given_name: string;
    // c_employee_number: string;
    // c_company: number;
    // c_department: number;
    // c_system_manage_flag: number;
    // id_token: string;
    // access_token: string;
    // refresh_token: string;
    userRole: string,
    userId: number,
    userSub: string,
    expired: boolean,
}

export interface ShareProjectModel {
    organizationId: string,
    organizationName: string,
    startDate: string,
    authority: string,
}

export interface ProjectModel {
    projectId : number	
    projectName	: string 
    createUserId : number 
    isShared:boolean;
    lockedUserId:number;
    // createDatetime : string 	
    lastUpdateUserId : number 	
    // lastUpdateDatetime : string	
    graphNo : number 	
    defXMin	: number 	
    defXMax	: number 	
    defYMin	: number 	
    defYMax	: number 	
    opeMode : number 	
    dropMode : number 	 	
    powerFlowMethod : number 	
    maxIteration : number 	
    precision	: number 	
    accelerationFactor : number 	 	
    iteration : number 	
    viewPointTextOnly : boolean 	
    pointTextChanged : boolean 	
    flowViewMethod : number 	
    calculatedFlow : boolean 	
    calculatedHarmonicFlow : boolean 	
    noIlluminance : number 	
    noEarthResistance : number 	
    editText : string 	
    weight : number 	
    italic : boolean 	
    underline : boolean 	
    strikeOut : boolean 	
    charSet : number 	
    faceName : string
    // Element no
    noBusBar : number
    noCapacitor : number
    noGenerator : number
    noHVCB : number
    noLoad : number
    noLVCB : number
    noFuse : number
    no2E : number
    noMotor : number
    noMotorGrp : number
    noSource : number
    noThermal : number
    noContactor : number
    noTrans3 : number
    noWire : number
    noZeroCond : number
    noMVCB : number
    noReactor : number
    no3winding : number
    noBusDuct : number
    noTrans1 : number
    noText : number
    noTransScott : number
    noDS : number
    noLBS : number
    noThermalCT : number
    noArrestor : number
    noEarth : number
    noCableHead : number
    noCT : number
    noZCT : number
    noVT : number
    noVCT : number
    noLoadCenter : number
    noTransCenter : number
    noLightBoard : number
    noPowerBoard : number
    noIntoPoint : number
    noRelay51 : number
    noImpedance : number
    noFault : number
    noINV : number
    noRelay : number
    modePM : boolean
}

export interface InfoCircuit {
    frequency : number;
    stdCapacity : number;
    voltage : number;
    voltageList : OptionModel[];
    electricPower : number;
    project : string;
    reference : string;
    corporation : string;
    defaultTitleProject : string;
    defaultTitleReference : string;
    defaultTitleCorporation : string;
    titleProject : string;
    titleReference : string;
    titleCorporation : string;
    printDetailHeadView1 : boolean;
    printDetailHeadView2 : boolean;
    printDetailHeadView3 : boolean;
}

export interface ElementGroupModel {
    // owner_id : string;
    groupId : number;
    groupName : string | null;
    explan : string | null;
    parentGroupId : number;
    elementIds: string[]
    // root_group_id : string;
}

export interface MappedEleGroup {
    byId: {
        [groupId : number] : ElementGroupModel
    },
    byEleId: {
        [eleId : string] : number[]
    }
}

export interface Credential {
    username: string;
    password: string;
    rememberMe: string;
}

export interface Message {
    id?: string;
    type: 'error' | 'info' | 'warning';
    title: string;
    body: string;
    detail?: any
};

export interface NavMenuItem {
    key: string
    title: string;
    path: string;
    icon: string;
    children?: NavMenuItem[];
    info?: string;
}

//ページの情報
export interface PageData {
    rowFrom: string,
    rowTo: string,
    totalRow: string,
    currPage: string,
    totalPage: string,
}

export interface OptionItem {
    value: string;
    label: string;
    attribute: string;
}

export interface Coordinates {
    x: number,
    y: number,
}

export interface DiagramModel {
    tabId: number,
    shape: ControlModel[],
}

export interface ChartTabModel {
    diagramId: number,
    tabId: number,
    shape: ControlModel[],
    maxId?: number,
    typesMaxId?: Map<String, Number>,
    nominalVolt: number,
    nominalVoltList: number[],
    zoom: number,
    isJapaneseElectricMode: boolean
}

export interface TabModel {
    id: number;
    label: string;
}

export enum ProcessMode {
    DRAWING = 1,
    CHECKING = 2,
    PERCENT_Z = 3,
    CALCULATION = 4,
    VOLTDROP = 5,
    IMPROVE_PF = 6,
    HARMONIC = 7,
    POWER_FLOW = 8,
}

export interface FontFormatType {
    fontSize: number; // No
    faceName : string;
    colorFont : string;
    underline : boolean;
    strikeOut : boolean;
    italic : boolean;
    weight : number;
    charSet : number; //TODO: 
}

// ============ Graph models =============

export interface GapLine {
    dispGapLine: boolean;
    dispCurrent: boolean;
    stdCurrent: number;
    dispTime: boolean;
    stdTime: number;
}

export interface InfoCircuit {
    frequency : number;
    stdCapacity : number;
    voltage : number;
    voltageList : OptionModel[];
    electricPower : number;
    project : string;
    reference : string;
    corporation : string;
    defaultTitleProject : string;
    defaultTitleReference : string;
    defaultTitleCorporation : string;
    titleProject : string;
    titleReference : string;
    titleCorporation : string;
    printDetailHeadView1 : boolean;
    printDetailHeadView2 : boolean;
    printDetailHeadView3 : boolean;
}
export interface InfoSkeleton{
    squareCountHlizontal: number,
    squareCountVertical: number,
    zoom: number,
    marginTop:number,
    marginBottom: number,
    marginLeft:number,
    marginRight:number,
    orientation:number,
    paperSize: number,
    dispStress: boolean,
    dispSupply: number,//radio
    changeDeltaToStar: number,//radio
    roopOpen: boolean,
    printDetailHead: boolean,
    figTitle: string,
    figSubTitle: string,
    titleCorporation: string,
    corporation: string,
    revision0: string,
    titleProject: string,
    project: string,
    titleDrawn: string,
    drawner: string,
    titleDateDrawn: string,
    dateDrawn: string,
    revision1: string,
    titleReference: string,
    reference: string,
    titleChecked: string,
    checker: string,
    titleDateChecked: string,
    dateChecked: string,
    revision2: string,
    titleNumber: string,
    figNumber: string,
    titleDesigned: string,
    designer: string,
    titleDateDesigned: string,
    dateDesigned: string,
    revision3:string,
    titleMode: string,
    titleApproved: string,
    approver:string,
    titleDateApproved: string,
    dateApproved: string,
    revision4: string,
    titleComment: string,
    comments: string,
    dispStainOrBack: boolean,

    figTitleDefault: string,//単線結線図
    figSubTitleDefault:string,//Single-line Diagram (Skeleton)
    titleDrawnDefault:string,//作成
    titleDateDefault:string,//日付
    titleCheckedDefault:string,//照査
    titleNumberDefault:string,//図番
    titleDesignedDefault:string,//設計
    titleModeDefault:string,//解析モード
    titleApprovedDefault: string,//検認
    titleCommentDefault: string,//コメント
}

export interface Report {
    printDetailHead: boolean,
    figTitle: string,
    figSubTitle: string,
    titleCorporation: string,
    corporation: string,
    revision1 : string,
    titleProject: string,
    project: string,
    drawn: string,
    drawner: string,
    titleDateDrawn: string,
    dateDrawn: string,
    revision2: string,
    titleReference: string,
    reference: string,
    checked: string,
    checker: string,
    titleDateChecked: string,
    dateChecked: string,
    revision3: string,
    titleNumber: string,
    figNumber: string,
    designed: string,
    designer: string,
    titleDateDesigned: string,
    dateDesigned: string,
    revision4: string,
    titleMode: string,
    approved: string,
    approver:string,
    titleDateApproved: string,
    dateApproved: string,
    revision5: string,
    titleComment: string,
    comments: string,
    marginTop: number,
    marginBottom: number,
    marginLeft: number,
    marginRight: number,
    orientation: number,
    paperSize: number,
    reportIndex:number,
    
    //memo
    editText : string,
    weight : number,
    italic : boolean,
    underline : boolean,
    strikeOut : boolean,
    charSet : number,
    faceName : string,
    colorFont: string,
    fontSize: number


}
export interface InfoCover{
    corporation:string,
    titleCorporationAddress:string,
    corporationAddress:string
    titleCorporationTelephone:string,
    corporationTelephone:string,
    titleDateDesigned: string,
    dateDesigned: string,
    application: string,
    titleProject:string,
    project:string,
    titleProjectAddress:string,
    projectAddress: string,
    titleProjectNumber:string,
    projectNumber:string,
    item1 : string,
    item2 : string,
    item3 : string,
    item4 : string,
    item5 : string,
    item6 : string,
    item7 : string,
    item8 : string,
    item9 : string,
    item10 : string,
    item11 : string,
    item12 : string,
    item13 : string,
    item14 : string,
    item15 : string,
    item16 : string,
    item17 : string,
    item18 : string,
    item19 : string,
    item20 : string,
    item21 : string,
    item22 : string,
    item23 : string,
    item24 : string,
    item25 : string,
    item26 : string,
    item27 : string,
    item28 : string,
    item29 : string,
    item30 : string,
    item31 : string,

    content1 : string,
    content2 : string,
    content3 : string,
    content4 : string,
    content5 : string,
    content6 : string,
    content7 : string,
    content8 : string,
    content9 : string,
    content10 : string,
    content11 : string,
    content12 : string,
    content13 : string,
    content14 : string,
    content15 : string,
    content16 : string,
    content17 : string,
    content18 : string,
    content19 : string,
    content20 : string,
    content21 : string,
    content22 : string,
    content23 : string,
    content24 : string,
    content25 : string,
    content26 : string,
    content27 : string,
    content28 : string,
    content29 : string,
    content30 : string,
    content31 : string,

    page1 : string,
    page2 : string,
    page3 : string,
    page4 : string,
    page5 : string,
    page6 : string,
    page7 : string,
    page8 : string,
    page9 : string,
    page10 : string,
    page11 : string,

    page12 : string,
    page13 : string,
    page14 : string,
    page15 : string,
    page16 : string,
    page17 : string,
    page18 : string,
    page19 : string,
    page20 : string,
    page21 : string,
    page22 : string,

    page23 : string,
    page24 : string,
    page25 : string,
    page26 : string,
    page27 : string,
    page28 : string,
    page29 : string,
    page30 : string,
    page31 : string,
    
    //
    itemDispIndex: number,
itemBottomIndex: number,
outline: string,
titleProjectManager: string,
projectManager: string,
titleProjectKind: string,
projectKind: string,
titleVoltage: string,
voltage: string,
titleEquipCapacity: string,
powerExisting: number,
powerIncrease: number,
powerNew: number,
heatExisting: number,
heatIncrease: number,
heatNew: number,
lightExisting: number,
lightIncrease: number,
lightNew: number,
titleContractPower: string,
contractPower: string,
titlePowerUse: string,
powerUse: string,
titlePowerForecast: string,
powerForecast: string,
titleUser1: string,
user1: string,
titleUser2: string,
user2: string,
titleUser3: string,
user3: string,
titleUser4: string,
user4: string,
titleUser5: string,
user5: string,
execution: string,
titleStandard: string,
standard: string,
titlePlace: string,
placeNormal: boolean,
placeNormalEdit: string,
placeDanger: boolean,
placeDangerEdit: string,
placeDust: boolean,
placeDustEdit: string,
placeCorrosion: boolean,
placeCorrosionEdit: string,
placeUser: boolean,
placeUserText: string,
titleWire: string,
wireHigh: boolean,
wireHighEdit: string,
wireLow: boolean,
wireLowEdit: string,
wireUser: boolean,
wireUserText: string,
titleDuct: string,
ductPvc: boolean,
ductPvcEdit: string,
ductGip: boolean,
ductGipEdit: string,
ductUser: boolean,
ductUserText: string,
titleMeter: string,
meterExisting: boolean,
meterExistingEdit: string,
meterNew: boolean,
meterNewEdit: string,
meterUser: boolean,
meterUserText: string,
titleSwitch: string,
switchNormal: boolean,
switchNormalEdit: string,
switchBurstProof: boolean,
switchBurstProofEdit: string,
switchUser: boolean,
switchUserText: string,
titleAppliance: string,
appliance: string,
titleOther: string,
other: string,
titleInstrument: string,
instrument1: string,
instrument2: string,
instrument3: string,
instrument4: string,
instrument5: string,
instrument6: string,
instrument7: string,
instrument8: string,
instrument9: string,
instrument10: string,
instrument11: string,
instrument12: string,
instrument13: string,
instrument14: string,
instrument15: string,
instrument16: string,
instrument17: string,
instrument18: string,
instrument19: string,
instrument20: string,
instrument21: string,
corporation1: string,
corporation2: string,
corporation3: string,
corporation4: string,
corporation5: string,
corporation6: string,
corporation7: string,
corporation8: string,
corporation9: string,
corporation10: string,
corporation11: string,
corporation12: string,
corporation13: string,
corporation14: string,
corporation15: string,
corporation16: string,
corporation17: string,
corporation18: string,
corporation19: string,
corporation20: string,
corporation21: string,
instrumentDispIndex: number,
instrumentBottomIndex: number,
unitChange: boolean,
subExisting: number,
subIncrease: number,
subNew: number,
powerTotal:number,
heatTotal:number,
lightTotal:number,
subTotal: number,

}

export interface DatasetEditForm {
    relatedGraphId: string,
    relatedGraphTextId: string,
    controlId: string,
    refNo: string,
    datasetId: string | number,
    relatedGraph: AdjustLine;
    relatedGraphText : AdjustNotes,
    updateId: number,
    isCallDB: boolean,
}

export interface AdjustLine {
    colorNormal: string,
    kind: number[],
    width: number,
    dispBand: boolean,
}

export enum LineDashKind {
    SOLID = 0,	     //直線
    DASH = 1,	     ///* -------  */
    DOT = 2,	     ///* .......  */
    DASHDOT = 3,	 ///* _._._._  */
    DASHDOTDOT = 4,  ///* _.._.._  */
}

export interface AdjustNotes extends FontFormatType{
    kindLinkLine : number;
    dispLinkLine : boolean;
    dispRefV2 : boolean;
    dispTextFree : boolean;
    textFixed : string;
    textFree : string;
    pointTopLeftX:number;
    pointTopLeftY:number;
    pointOldX:number;
    pointOldY:number;
    minPointX: number;
    minPointY: number;
}

export interface DatasetModel {
    // custom
    relatedGraphId: string,
    relatedGraphTextId: string,
    controlId: string,
    lineId: string | number,
    lineType: LineType,
    savedColor: string,

    // original
    label: string,
    data: Point[],
    pointBorderColor: string,
    pointBackgroundColor: string,
    pointRadius: number,
    spanGaps: boolean,
    backgroundColor: string,
    borderColor: string,
    borderDash: number[],
    borderWidth: number,
    dispBand: boolean,
    dispRefV2: boolean,
    dispLinkLine: boolean,
    dispTextFree: boolean,
    animation?: boolean,
    fontSize: number,
    faceName : string,
    colorFont : string,
    underline : boolean,
    strikeOut : boolean,
    italic : boolean,
    weight : number,
    textFree: string
    text: string
    pointHoverRadius?: number,
    pointBorderWidth?:number,
}

export interface LineStyleModel {
    backgroundColor: string,
    borderColor: string,
    borderDash: number[],
    borderWidth: number,
    dispBand: boolean,
    dispRefV2: boolean,
    dispLinkLine: boolean,
    dispTextFree: boolean,
    fontSize: number,
    faceName : string,
    colorFont : string,
    underline : boolean,
    strikeOut : boolean,
    italic : boolean,
    weight : number,
    textFree: string,
    text: string,
    pointTopLeftX:number,
    pointTopLeftY:number,
    pointOldX:number,
    pointOldY:number,
    minPointX: number,
    minPointY: number,
}

export interface LineInfoModel {
    relatedGraphId: string,
    relatedGraphTextId: string,
    controlId: string,
    lineId: string | number,
    lineType: LineType,
    label: string,
    data: Point[],
    pointBorderColor: string,
    pointBackgroundColor: string,
    pointRadius: number,
    spanGaps: boolean,
    tabMapping: Map<number, LineStyleModel>
}

export enum LineType {
    CONTROL_CURVE = "CONTROL_CURVE",
    USER_CURVE = "USER_CURVE",
    MEASUREMENT_LINE = "MEASUREMENT_LINE",
    CONNECT_LINE = "CONNECT_LINE"
}

export interface ChartModel {
    labels: string[],
    datasets: DatasetModel[],
}

export interface GraphLineData {
    raw? : any[]  
    lines : {
        id: string | number,
        points: Point[],
    }[]
}

export interface Point {
    x: number,
    y: number,
    user_curve_id?: number,
    userCurveId?: number
}

export interface CdPoint {
    m_dx: number,
    m_dy: number
}

export interface keyable {
    [key: string]: any  
}

export interface OneCurve {
    m_listdPoint : CdPoint[],
    m_bSetLock : boolean,
    m_dSaturationTime : number,
    m_dInstValue : number,
    m_dMultiValue : number,
}

export type AdjustElements = MsSourceModel | MsHVCBModel | MsFUSEModel | MsLVCBModel | MsThermalCTModel| MsThermalModel | Ms2EModel 

// ============ END graph models ==========
export interface ControlModel {
    id: string,
    image: string,
    type: string,
    x: number,
    y: number,
    offsetX: number,
    offsetY: number,
    width: number,
    height: number,
    rotation: number,
    chart: LineInfoModel[],
    parentGroupId: number | null,
    isSelected: boolean,
    isRotationEnabled: boolean,
    raw?: any[],
    properties?: any,
}

export interface StateModel {
    idState: number,
    tabId: number,
    shape: ControlModel[],
}

export interface AppStore {
    elementTemplates: object,
    currentIDDiagramTab: number,
    transCenterUpdate: number,
    diagramData: DiagramModel[],
    chartData: ChartTabModel[],
    currentIDChartTab: number,
    maxIDChartTab: number,
    elementMaxId: number,
    chartEvents: any,
    openDialog : any,
    openUserCurve: any,
    openUserCurveProperties: any,
    graphData: any,
    checkZerocondConnect: object,
    typesMaxId: Map<string, number>,
    preDiagramData: StateModel[],
    copyShapes: ControlModel[],
    copyEleGroups: any,
    copyTCEleGroups: { [tcId : string] : any },
    copyDiagramData: DiagramModel[], // TransCenter diagram
    pointerPosition: Coordinates,
    deleteGraphNo: any,
    isOpenChart: any,
    isAddToChart: any,
    isAddCalcPointToChart:any,
    transformData: Node<NodeConfig>[],
    transformFlag: boolean,
    elementGroups: MappedEleGroup,
    tcGroups: { [tcId : string] : MappedEleGroup },
    processMode: ProcessMode,
    gapLine: GapLine;
    infoCircuit: InfoCircuit;    
    infoSkeleton: InfoSkeleton;
    infoCover:InfoCover;
    report: Report;
    groupList: GroupListModel[];
    m_bModePM: boolean;
    undoData: UndoModel;
    deleteGraphText: any;
    deleteUserCurveRelatedGraph:any;
    reDrawGraphText: any;
    isDrawPowerArrow: any;
    listGraphName:any;
    isShowScreen: any;
    isDownloadDiagramPDF: any;
	contextMenuFunction: number | null;
    listGraph:any;
	clearOptionMenu: boolean;
    isSelectTouchGroup: boolean;
    diagramDataUpdate:number;
    isShowMaintenanceScreen:any;
    isShowMyProject:boolean;
    elementReposition:any;
    modeViewOnly: boolean;
}

export interface OptionModel {
    label: string,
    value: any,
    itemValue?: any,
    itemId?: any,
}

export interface GroupListModel {
    groupId: number,
    groupName: string,
    explan: string,
}

//--- 機器詳細ダイアログ
export interface AdjustModel {
    base: BaseDetailsModel, // 形式タブ
    parts: PartDetailsModel[], // 特性タブ
    delaySettings?: DelaySettingDetailsModel | null // 遅延設定タブ
    
    refNo2: string,
    drawThreeCurve: boolean,
}

// 機器詳細ダイアログ -- 形式タブ
export interface BaseDetailsModel {
    manufacturerList: OptionModel[], // 製造者＿リスト
    manufacturerID: number, // 製造者＿選択値
    kindList: OptionModel[], // 機器種類＿リスト
    kindID: number, // 機器種類＿選択値
    typeList: OptionModel[], // 形式＿リスト
    typeID: number, // 形式＿選択値
    opeKindTitle: string, // 保護種類＿タイトル
    opeKindList: OptionModel[], // 保護種類＿リスト
    opeKindID: number, // 保護種類＿選択値
    ratedRangeList: OptionModel[], // 範囲＿リスト
    ratedRangeID: number, // 範囲＿選択値
    ratedTitle: string, // 定格＿タイトル
    ratedList: OptionModel[], // 定格＿リスト
    ratedID: number, // 定格＿選択値
    ratedValue: number,
    ratedSelectionValue: number,
    magniTitle: string, // 倍率＿タイトル
    magniList: OptionModel[], // 倍率＿リスト
    magniValue: number,
    series: string, // シリーズ名称
    oldNew: string, // 生産状況
    notes: string, // 備考

    useCT: boolean, // CT使用可否
    useCC: boolean, // カレントコンバータ使用可否
    typeName?: string, // 形式名
    fixedName?: string, // 定格名
    readBase: ReadBaseModel,
    turnNumber?: number,
    kindFlag: number,
    setBreakingTime: boolean | null,
    setBreakHz: boolean | null,
    breakTimeHz: number | null,
    useOpenTime: number,
    
    ctPrimaryList: OptionModel[],	//	CT一次側＿リスト
    ctPrimary: number,	            //	CT一次側
    ctSecondaryList: OptionModel[],	//	CT二次側＿リスト
    ctSecondary: number,	        //	CT二次側
}

export interface ReadBaseModel {
    ratedFlag?: number,
    magniFlag?: number,
    kindFlag?: number
    magniUnit?: string,
    ratedUnit?: string,
    canUseCT?: boolean,
    canUseCC?: boolean,
}

// 機器詳細ダイアログ -- 特性タブ
export interface PartDetailsModel {
    partID: number,
    tabName: string, // タブ名
    charDialList: OptionModel[], // 特性＿リスト
    charDialID: number, // 特性＿選択値
    characterName: string,
    setLock: boolean, // ロック
    useLock: boolean, // active setLock?
    currentTitle: string, // 電流ダイアル＿タイトル
    currentFlag: number,
    currentList: OptionModel[], // 電流ダイアル＿リスト
    currentValue: number,
    timeFlag: number,
    timeTitle: string, // 時間ダイアル＿タイトル
    timeList: OptionModel[], // 時間ダイアル＿リスト
    timeValue: number,
    magniCurrentFlag: number,
    magniCurrentTitle: string, // 電流倍率ダイアル＿タイトル
    magniCurrentList: OptionModel[], // 電流倍率ダイアル＿リスト
    magniCurrentValue: number,
    magniTimeFlag: number,
    magniTimeTitle: string, // 時間倍率ダイアル＿タイトル
    magniTimeList: OptionModel[], // 時間倍率ダイアル＿リスト
    magniTimeValue: number,
    readPart?: ReadPartModel
}

export interface ReadPartModel {
    isLink?: boolean,
    linkNumber?: number,
    isCurrent?: boolean,
    currentFlag?: number,
    dispCurrentMulti?: boolean,
    isNoneSadamu?: boolean,
    isUseLock?: boolean,
    isUseTimer?: boolean,
    isMCR?: boolean,
    currentNotes? : string,
    currentUnit? : string,
    magniCurrentUnit? : string,
    magniTimeUnit? : string,
    timeUnit? : string,
}

// 機器詳細ダイアログ -- 遅延設定タブ
export interface DelaySettingDetailsModel {
    breakingTime: boolean, // 遮断時間
    breakingTimeHzList: OptionModel[], // 遮断時間＿リスト
    breakingTimeHzID: number // 遮断時間＿選択値
}

// 電源モデル
export interface MsSourceModel {
    // 基本
    refNo: string,	                //	デバイス名
    noSource: number,
    pointText: string,	            //	事故点名称
    voltageList: OptionModel[],	    //	受電電圧＿リスト
    voltage: number,	            //	受電電圧(V)
    capacityList: OptionModel[],	//	容量＿リスト
    capacity: number,	            //	容量(MVA)
    fault: boolean,	        //	短絡電流を計算
    calcImprovePF: boolean,	        //	力率改善を計算
    viewResultText: boolean,	    //	注釈を表示
    xr: number,	                    //	X/R
    percentZ: number,	            //	インピーダンス(%)_左辺
    percentR: number,	            //	インピーダンス(%)_右辺１
    percentX: number,	            //	インピーダンス(%)_右辺２
    ohmZ: number,	                //	インピーダンス(mΩ)_左辺
    ohmR: number,	                //	インピーダンス(mΩ)_右辺１
    ohmX: number,	                //	インピーダンス(mΩ)_右辺２
    // 保護協調
    details: AdjustModel,    //  動作特性曲線 - 機器詳細
    // 力率改善
    contract: number,	            //	契約電力(kW)
    present: number,	            //	電源回路の力率(実測値)
    target: number,	                //	改善後の目標力率
    demand: number | string,	    //	力率改善に必要なコンデンサ容量(kvar)
    // 潮流解析 -- ModePM
    nodeKind: number,
    viewFlowResult: boolean,
    leadLag: number,
    powerFactor: number,
    appointVoltage: number,
    flowQMin: number,
    flowQMax: number,
    // シンボル
    symKind: number,	            //	シンボル種別 電源(0),無限大母船(1)
    oldCapacity?: number,           // update when symKind == 1
    oldXr?: number,                 // update when symKind == 1
    // sym_normal: boolean,
    // sym_infinite: boolean,
    listUser: any[]
    isReadLists?: boolean,

    // others
    checkNG: boolean,
    nodeNumber: number,
    perUnitR: number,
    perUnitX: number,
    perUnitK: number,
    perUnitP: number,
    perUnitQ: number,
    perUnitV: number,
    voltageMagnitude: number,
    voltageAngle: number,
    activePower: number,
    reactivePower: number,
    examineComment: string,
    harmonicCurrent0: number,
    harmonicCurrent1: number,
    harmonicCurrent2: number,
    harmonicCurrent3: number,
    harmonicCurrent4: number,
    harmonicCurrent5: number,
    harmonicCurrent6: number,
    harmonicCurrent7: number,
    harmonicCurrent8: number,
    harmonicCurrent9: number,
    harmonicCurrent10: number,
    harmonicCurrent11: number,
    harmonicCurrent12: number,
    harmonicCurrent13: number,
    harmonicCurrent14: number,
    harmonicCurrent15: number,
    seriesed: boolean,
    loadCapacity: number,

    calcPoint0: CalcPoint,
    calcPoint1: CalcPoint,
}
export interface PercentZ{
    kind :number,
    x: number,
    y: number,
    xMax: number,
    yMax: number,
    fault: boolean,
    rotate: boolean,
    perRk3: number,
    perXk3: number,
    statorPerRk3: number,
    fault2: boolean,
    perR2k3: number,
    perX2k3: number,
    fault3: boolean,
    perR3k3: number
    perX3k3: number,
    fault1: boolean,
    perR1k3: number
    perX1k3: number,
    perRk3Difference: number,
    perXk3Difference: number,
    perRk3Source: number,
    perXk3Source: number,
    calcFailed: boolean,
    circuitName: string,
    specialImpedance: number,
    parallelFactor:number
}

// 過電流継電器モデル
export interface MsHVCBModel {
    // 基本
    refNo: string,                 //	デバイス名
    noHVCB: number,
    pointText: string,             //	事故点名称
    voltageList: OptionModel[],    //	回路電圧＿リスト
    voltage: number,               //	回路電圧(V)
    breakCapacityList: OptionModel[],     //	遮断電流＿リスト
    breakCapacity: number,         //	遮断電流(kA)
    isOff: boolean,                  //	主回路を開放
    fault: boolean,           //	短絡電流を計算
    dropMotor: boolean,           //	電動機始動時の電圧降下を計算
    dropRegular: boolean,         //	定常時の電圧降下を計算
    viewResultText: boolean,       //	注釈を表示
    
    // ModePM
    viewFlowResult: boolean,
    connectSwitch: boolean,

    makeDataInput: boolean,

    makerNameList: OptionModel[],   // s_manu_name where bhvcb == true
    makerName: string,
    breakType: string,
    makeYearList: OptionModel[],
    makeYear: number | null,
    makeMonthList: OptionModel[],
    makeMonth: number | null,
    makeNumber: string,

    makeYearOCR: number | null,
    makeMonthOCR: number | null,
    makeNumberOCR: string

    // 保護協調
    details: AdjustModel,    //  動作特性曲線 - 機器詳細
    // シンボル
    symKind: number,                     //	シンボル種別 電源(0),無限大母船(1)
    isReadLists?: boolean,
    listUser: any[],
    // others
    checkNG: boolean,
    supply: boolean,
    tcName: string,
    lineActiveFlow1: number,
    lineReactiveFlow1: number,
    lineActiveFlow2: number,
    lineReactiveFlow2: number,
    eleTopLeftNumber: number,
    eleBottomRightNumber: number,
    downTransKind: string,
    primarySupply: boolean,
    examineComment: string,
    seriesed: boolean,
    voltDropSumRe: number,
    voltDropSumIm: number,
    voltDropRouteRefNo: string,
    rotate: number,

    calcPoint0: CalcPoint,
    calcPoint1: CalcPoint
}

// 電動機モデル
export interface MsMotorModel {
    // 基本
    refNo: string,                  //  デバイス名
    sPhase:boolean,
    voltageID:number,
    capacityID:number,              //  定格出力(kW)＿選択値
    powerFactor: number,            //  力率(遅れ)
    isLead: boolean,                //  ★ 力率 0:進み/1:遅れ
    startingTimes:number,
    starting600:number,
    inrushTimes:number,
    checkNG:boolean,
    calcDrop: boolean,              //  この電動機の始動時電圧降下を計算
    startingFactor: number,         //  始動力率(遅れ)
    efficiency: number,             //  効率
    useBaseLoad:boolean,
    baseCapacity: number,           //  容量(kVA)
    leadBaseLoad:boolean,
    baseFactor: number,             //  力率_値
    calcImprovePF: boolean,         //  力率改善を計算
    afterPF:number,
    nodeNumber:number,
    perUnitR: number,
    perUnitX: number,
    perUnitK: number,
    perUnitP: number,
    perUnitQ: number,
    loadFactor: number,             //  負荷率
    voltageMagnitude:number,
    voltageAngle:number,
    activePower: number,
    reactivePower:number,
    upTransKind:number,
    useEfficiency: boolean,         //  効率を考慮
    dispBaseLoad:boolean,
    manualLoadCurrent:boolean,
    loadCurrent:number,
    manualStartingCurrent:boolean,
    startingCurrent:number,
    manualInrushCurrent:boolean,
    inrushCurrent:number,
    pageIndex:number,
    supply:boolean,
    tcName:string,
    baseLoadInputManual:boolean,
    baseCapacityM:number,
    leadBaseLoadM:boolean,
    baseFactorM:number,
    baseLoadCalcRightLeft:boolean,
    makeDataInput: boolean,
    makerName: string,
    makeYear: number | null,
    makeMonth: number | null,
    makeNumber: string,
    type: string,
    uIntHP: boolean,            //  ★ 定格出力 0:kW/1:HP
    kiloWatCapacity:number,
    viewFlowResult:boolean,
    primarySupply:boolean,
    viewResultText: boolean,        //  注釈を表示
    inrush20Or40:boolean,
    startingTime:number,
 
    voltageList: OptionModel[],             //  回路電流(V)
    capacityList:any[],            //  定格出力(kW)＿リスト
    capacityHpList:any[]         //  定格出力(HP)＿リスト
    capacityHpID:number
    capacity: number,               //  定格出力(kW)_値
    fullCurrent: number,            //  全負荷電流 Im(A)
    phaseCurrent: number,           //  電流位相(°)
    inputAutomaticManual: number,   //  ★ 基底負荷を 0:自動計算する/1:手入力する
    baseCurrent: number,            //  負荷電流 lB(A)
    calcRightLeft: boolean,         //  左右の母線の負荷も考慮
    bSPhase:boolean,
   
 
    // ModePM
    makerNameList: OptionModel[],
    makeYearList: OptionModel[],
    makeMonthList: OptionModel[],
 
    // Tab2
    baseLoadCurrent:number,
    baseInrushCurrent:number,
    loadCurrentBaseLoad:number,
    // Tab3
    harmonicList:any[],
    listUser: any[],
    isSourceVoltage: boolean
}

export interface MsTextModel extends FontFormatType {
    text: string,
    refNo: string,
    noText: number,
    height: number,
    // fontSize: number,
    // fontFamily: string,
    // fill: string,
    // fontStyle: string,
    // textDecoration: string,
}

export interface MsGeneratorModel {
    noGenerator:string,
    refNo: string,	                //	デバイス名
    pointText:string,               
    voltage: number,              
    voltageList: any,	    //	回路電流(V)
    capacity:number,              //  定格出力(kW)＿選択値
    capacityList:any,            //  定格出力(kW)＿リスト
    ratedCurrent: number,
    reactance: number,
    subReactance: number,
    constant: number,
    subConstant: number,
    statorResistance: number,
    fault: boolean,
    viewResultText: boolean,
    subCurrentIkd: number,
    currentIkd: number,
    iacNoLoad: number,
    iacLoad: number,
    peakValue: number,
    generatorList:any,
    percentZe:number,
    Xr:number,
    powerFactor:number,
    activePower:number,
    reactivePower:number,
    Qmax:number,
    Qmin:number,
    nodeKind:number,
    appointVoltage:number,
    isLead:boolean,
    viewFlowResult:boolean,
    // ModePM
    modePM:boolean,
    makeDataInput: boolean,
    makerNameList: OptionModel[],
    makerName: string,
    type: string,
    makeYearList: OptionModel[],
    makeYear: string | null,
    makeMonthList: OptionModel[],
    makeMonth: string | null,
    makeNumber: string,
    listUser: any[],
    nodeNumber:number,
    perUnitR:number,
    perUnitX:number,
    perUnitK:number,
    perUnitP:number,
    perUnitQ:number,
    perUnitV:number,
    voltageMagnitude:number,
    voltageAngle:number,
    examineComment:string,
    seriesed:boolean,
    checkNG:boolean,

    calcPoint0: CalcPoint,
    calcPoint1: CalcPoint
}

export interface MsTrans3Model { 
    // tab0 - 基本
    noTrans3: number,
    refNo: string,
    pointText: string,
    voltagePrimaryList: OptionModel[],
    voltagePrimary: number,
    voltageSecondaryList: OptionModel[],
    voltageSecondary: number,
    capacityList: OptionModel[],
    capacity: number,
    ratedCurrentParmary: number,
    ratedCurrentSecondary: number,

    fault: boolean,
    viewResultText: boolean,
    voltageAdjust: number,

    xr: number,
    percentZ: number,
    percentR: number,
    percentX: number,
    ohmZ: number,
    ohmR: number,
    ohmX: number,

    // ModePM
    viewFlowResult: boolean,
    makeDataInput: boolean,
    makerNameList: OptionModel[],   // s_manu_name where b_trans == true
    makerName: string,
    type: string,
    makeYearList: OptionModel[],
    makeYear: number | null,
    makeMonthList: OptionModel[],
    makeMonth: number | null,
    makeNumber: string

    // tab1 - 保護協調
    dispInrush: boolean,
    inrushTimes: number,
    inrushValue: number,
    dampingTime: number,

    dispIntensity: boolean,
    intensityTimes: number,
    intensityValue: number,
    intensityTime: number,

    // tab2 - 電圧降下
    dropMotor: boolean,
    dropRegular: boolean,

    calcPoint0: CalcPoint,
    calcPoint1: CalcPoint,

    currentTopLeft: number, // 負荷電流方向

    voltageDrop1: number,
    voltageDrop: number,

    // tab3 - シンボル
    symKind: number,
    haveTab: boolean,
    listUser: any[],

    // others
    checkNG: boolean,
    selInrush: boolean,
    selIntensity: boolean,
    eleLineNumber: number,
    eleTopLeftNumber: number,
    eleBottomRightNumber: number,
    perUnitR: number,
    perUnitX: number,
    perUnitK: number,
    lineActiveFlow1: number,
    lineReactiveFlow1: number,
    lineActiveFlow2: number,
    lineReactiveFlow2: number,
    pageIndex: number,
    supply: boolean,
    tcName: string,
    haveGround: boolean,
    neutralKind: number, // check in VM
    inrushTimesKind: number,
    inrushManualValue: number,
    inrushTime: number,
    voltageMagnitude1: number,
    voltageAngle1: number,
    voltageMagnitude2: number,
    voltageAngle2: number,
    inputedCapacity1: boolean, // check in VM
    inputedCurrent1: boolean, // check in VM
    inputedPowerFactor1: boolean, // check in VM
    dispAnsiKind: number,
    ansiTimes: number,
    ansiTime: number,
    primarySupply: boolean,
    examineComment: string,
    seriesed: boolean,
    voltDropSumRe: number,
    voltDropSumIm: number,
    voltDropRouteRefNo: string,
    voltAdjustPart: number, // check in VM
    isSourceVoltage: boolean,
    trans3List:any[],

}

export interface Ms3WindingModel {
    no3Winding:string,
    // ModePM
    makeDataInput: boolean,
    makerNameList: OptionModel[],
    makerName: string,
    type: string,
    makeYearList: OptionModel[],
    makeYear: number | null,
    makeMonthList: OptionModel[],
    makeMonth: number | null,
    makeNumber: string
    // -----------------
    // tab0 - 基本 - [一次側]
    partPrimary:Iele3WindingPart,
    refNo: string,
    field2: boolean,
    viewFlowResult:boolean,

    // tab1 - 基本 - [二次側]
    partSecondary: Iele3WindingPart,
    pointText: string,
    field14: boolean,

    // tab2 - 基本 - [三次側]
    partThird:Iele3WindingPart,
    pointText1: string,
    field26: boolean,

    // tab3 - 保護協調 - [二次側]
    dispInrushSecond: boolean,
    inrushTimesSecond: number,
    inrushValueSecond: number,
    dampingTimeSecond: number,
    dispIntensitySecond: boolean,
    intensityTimesSecond: number,
    intensityValueSecond: number,
    intensityTimeSecond: number,

    // tab4 - 保護協調 - [三次側]
    dispInrushThird: boolean,
    inrushTimesThird: number,
    inrushValueThird: number,
    dampingTimeThird: number,
    dispIntensityThird: boolean,
    intensityTimesThird: number,
    intensityValueThird: number,
    intensityTimeThird: number,

    // tab5 - 電圧降下 - [二次側]
    dropRegular: boolean,
    loadCapacity: number,
    loadCurrent: number,
    isLead:boolean,
    powerFactor:number,
    voltDropDiff: number,
    voltDropPerUnit: number,

    // tab6 - 電圧降下 - [三次側]
    dropRegularThird: boolean,
    loadCapacityThird: number,
    loadCurrentThird: number,
    isLeadThird:boolean,
    powerFactorThird:number,
    voltDropDiffThird: number,
    voltDropPerUnitThird: number,

    // tab7 - シンボル
    symKind: number,

    listUser: any[],
    checkNG:boolean,
    eleNeutralPointNumber:number,
    supply:boolean,
    tcName:string,
    exchangeNumber:number,
    primarySupply:boolean,
    seriesed:boolean,
    viewResultText:boolean,
    isSourceVoltage: boolean
}

export interface MsTrans1Model {
    // tab0 - 基本
    noTrans1: number,
    refNo: string,
    voltagePrimaryList: OptionModel[],
    voltagePrimary: number,
    voltageSecondaryList: OptionModel[],
    voltageSecondary: number,
    capacityList: OptionModel[],
    capacity: number,
    ratedCurrent: number,
    
    viewFlowResult: boolean,
    viewResultText: boolean,
    loadFactor: number,
    powerFactor: number,

    // ModePM
    makeDataInput: boolean,
    makerNameList: OptionModel[],
    makerName: string,
    type: string,
    makeYearList: OptionModel[],
    makeYear: number | null,
    makeMonthList: OptionModel[],
    makeMonth: number | null,
    makeNumber: string

    voltage1: number, // equal voltageSecondary / 2
    capacity1: number, // equal capacity / 2
    ratedCurrent1: number,
    // trans1Parts: MsTrans1PartModel[], // index:: [0]:[外線‐外線], 
    partOutOut : MsTrans1PartModel,//[0]:[外線‐外線]
    partOutIn : MsTrans1PartModel,//[1]:[外線‐中性線]

    // シンボル
    symKind: number,
    listUser: any[],

    // other
    checkNG: boolean,
    supply: boolean,
    tcName: string,
    nodeNumber: number,
    perUnitP: number,
    perUnitQ: number,
    voltageMagnitude: number,
    voltageAngle: number,
    activePower: number,
    reactivePower: number,
    primarySupply: boolean,
    perUnitR: number,
    perUnitX: number,
    perUnitK: number,
    seriesed: boolean,
    isSourceVoltage:boolean,
    voltDropRouteRefNo: string,
}

export interface MsTrans1PartModel {
    // [外線‐中性線] && [外線‐外線]
    eleTrans1PartKind: number,  // 1:外線－中性線間の情報, 2:外線間の情報
    
    // 基本
    pointText: string,
    fault: boolean,

    xr: number,
    percentZ: number,
    percentR: number,
    percentX: number,
    ohmZ: number,
    ohmR: number,
    ohmX: number,

    // 保護協調
    dispInrush: boolean,
    inrushTimes: number,
    inrushValue: number,
    dampingTime: number,

    dispIntensity: boolean,
    intensityTimes: number,
    intensityValue: number,
    intensityTime: number,

    // 電圧降下 
    dropRegular: boolean,

    calcPoint: CalcPoint,

    currentTopLeft: number,  // always 0

    voltageDrop1: number,
    voltageDrop: number,

    // other
    selInrush: boolean,
    selIntensity: boolean,
    pageIndex: number,
    inputedCapacity1: boolean,
    inputedCurrent1: boolean,
    inputedPowerFactor1: boolean,
    neutralKind: number,
    dispAnsiKind: number,
    ansiTimes: number,
    ansiTime: number,
    inrushTimesKind: number, // m_nInrushTimes
    inrushManualValue: number,
    inrushTime: number,
    examineComment: string
    voltDropSumRe: number,
    voltDropSumIm: number
}

export interface MsTransScottModel {
    noTransScott: number,
    // tab0: 基本
    refNo: string,
    voltagePrimaryList: OptionModel[],
    voltagePrimary: number,
    voltageSecondaryList: OptionModel[],
    voltageSecondary: number,
    capacityList: OptionModel[],
    capacity: number,
    ratedCurrent: number,
    ratedCurrent1: number,
    
    viewFlowResult: boolean,
    viewResultText: boolean,
    loadFactor: number,
    powerFactor: number,

    // ModePM
    makeDataInput: boolean,
    makerNameList: OptionModel[],
    makerName: string,
    type: string,
    makeYearList: OptionModel[],
    makeYear: number | null,
    makeMonthList: OptionModel[],
    makeMonth: number | null,
    makeNumber: string

    // part index::  , transScottParts[0]
    partSeatM: MsTransScottPartModel,//[0]:[主座変圧器]
    partSeatT: MsTransScottPartModel,//[1]:[T座変圧器] 

    
    listUser: any[],
    // other
    checkNG: boolean,
    supply: boolean,
    tcName: string,
    nodeNumber: number,
    perUnitP: number,
    perUnitQ: number,
    voltageMagnitude: number,
    voltageAngle: number,
    activePower: number,
    reactivePower: number,
    primarySupply: boolean,
    perUnitR: number,
    perUnitX: number,
    perUnitK: number,
    seriesed: boolean,
    voltDropRouteRefNo: string,
    isSourceVoltage: boolean
}

export interface MsTransScottPartModel {
    // [主座変圧器] && [T座変圧器]
    eleTransScottPartKind: number, // 1:主座変圧器の情報, 2:T座変圧器の情報
    // tab1-2: 基本
    pointText: string,
    fault: boolean,
    
    xr: number,
    percentZ: number,
    percentR: number,
    percentX: number,
    ohmZ: number,
    ohmR: number,
    ohmX: number,

    // tab3-4: 保護協調
    dispInrush: boolean,
    inrushTimes: number,
    inrushValue: number,
    dampingTime: number,

    dispIntensity: boolean,
    intensityTimes: number,
    intensityValue: number,
    intensityTime: number,

    // tab5-6: 電圧降下
    dropRegular: boolean,

    calcPoint: CalcPoint,
    currentTopLeft: number,

    voltageDrop1: number,
    voltageDrop: number,
    
    // other
    selInrush: boolean,
    selIntensity: boolean,
    pageIndex: number,
    inputedCapacity1: boolean,
    inputedCurrent1: boolean,
    inputedPowerFactor1: boolean,
    neutralKind: number,
    dispAnsiKind: number,
    ansiTimes: number,
    ansiTime: number,
    inrushTimesKind: number, // m_nInrushTimes
    inrushManualValue: number,
    inrushTime: number,
    examineComment: string,
    voltDropSumRe: number,
    voltDropSumIm: number
}

export interface MsDSModel {
    //基本
    refNo: string,
    pointText: string,
    voltageList: OptionModel[],
    voltage: number,
    makeDataInput:boolean,
    isOff: boolean,
    fault: boolean,
    dropMotor: boolean,
    dropRegular: boolean,
    viewFlowResult: boolean,
    connectSwitch: boolean,
    viewResultText: boolean,
    makerName: string,
    makerNameList:OptionModel[],
    type:string,
    makeYear:string,
    makeYearList:OptionModel[],
    makeMonth:string,
    makeMonthList:OptionModel[],
    makeNumber:string,

    // シンボル
    exchangeNumber: number,
    isSourceVoltage:boolean,
    sourceVoltage:number,
    noLS:number,
    // pointTextLS:number,
    listUser: any[],

    calcPoint0: CalcPoint,
    calcPoint1: CalcPoint,
    downTransKind:number,
    lineActiveFlow1:number,
    lineActiveFlow2:number,

    lineReactiveFlow1:number,
    lineReactiveFlow2:number,
    cComplex: CComplex,
    checkNG: boolean,
    supply:boolean,
    tc_name:string,
    eleTopLeftNumber:number
    eleBottomRightNumber:number
    primarySupply:boolean,
    examineComment:string,
    seriesed:boolean,
    voltDropRouteRefNo:string,

    listCompEleMG0:CompEle,
    listCompEleMG1:CompEle,
    listCompEle1:CompEle,
    listCompEle2:CompEle
}
export interface CompEle{
    compEleId: number,
    calcKind: number,
    circuitName: string,
    kind: number,
    fault: boolean,
    gOrMgPerRK3: number,
    gOrMgPerXK3: number


}
export interface CComplex{
    voltDropSumRe:number,
    voltDropSumIm:number,
    voltDropSumRe2:number,
    voltDropSumIm2: number
}

export interface MsLBSModel {
    refNo: string,
    noLBS: number,
    pointText: string,
    voltageList:OptionModel[],
    voltage: number,
    isOff: boolean,
    fault: boolean,
    dropMotor: boolean,
    dropRegular: boolean,
    viewFlowResult:boolean,
    connectSwitch:boolean,
    viewResultText: boolean,
    makerName: string,
    makerNameList:OptionModel[],
    type:string,
    makeYear:string,
    makeYearList:OptionModel[],
    makeMonth:string,
    makeMonthList:OptionModel[],
    makeNumber:string,
    makeDataInput:boolean,
    // シンボル
    exchangeNumber: number,
    //他の
    isSourceVoltage:boolean,
    listUser: any[],
    calcPoint0: CalcPoint,
    calcPoint1: CalcPoint,
    checkNG: boolean,
    supply: boolean,
    tcName: string,
    lineActiveFlow1:number,
    lineActiveFlow2:number,
    lineReactiveFlow1:number,
    lineReactiveFlow2:number,
    eleTopLeftNumber:number,
    eleBottomRightNumber:number,
    primarySupply:boolean,
    downTransKind:number,
    cComplex: CComplex,
    examineComment:string,
    seriesed:boolean,
    voltDropRouteRefno: string,

    listCompEleMG0:CompEle,
    listCompEleMG1:CompEle,
    listCompEle1:CompEle,
    listCompEle2:CompEle
}

export interface MsMVCBModel {
    refNo: string,
    noMVCB:number,
    pointText: string,
    voltageList: OptionModel[],
    voltage: number,
    breakCapacityList: OptionModel[],
    breakCapacity: number,
    isOff: boolean,
    fault: boolean,
    dropMotor: boolean,
    dropRegular: boolean,
    viewFlowResult:boolean,
    connectSwitch:boolean,
    viewResultText: boolean,
    makerName: string,
    makerNameList:OptionModel[],
    type:string,
    makeYear:string,
    makeYearList:OptionModel[],
    makeMonth:string,
    makeMonthList:OptionModel[],
    makeNumber:string,
    makeDataInput:boolean,
    exchangeNumber: number,
    //他の
    isSourceVoltage:boolean,
    
    listUser: any[],

    calcPoint0: CalcPoint,
    calcPoint1: CalcPoint,
    checkNG: boolean,
    supply: boolean,
    tcName: string,
    lineActiveFlow1:number,
    lineActiveFlow2:number,
    lineReactiveFlow1:number,
    lineReactiveFlow2:number,
    eleTopLeftNumber:number,
    eleBottomRightNumber:number,
    primarySupply:boolean,
    upTransKind:number,
    cComplex: CComplex,
    examineComment:string,
    seriesed:boolean,
    voltDropRouteRefno: string,
    downTransKind:number,
    sPhase: boolean,
    listCompEleMG0:CompEle,
    listCompEleMG1:CompEle,
    listCompEle1:CompEle,
    listCompEle2:CompEle,
    examineCommentSPhase:string
}

export interface MsContactorModel {
    refNo: string,
    noContactor: number,
    pointText: string,
    voltageList: OptionModel[]
    voltage: number,
    isOff: boolean,
    fault: boolean,
    dropMotor: boolean,
    dropRegular: boolean,
    viewResultText: boolean,
    viewFlowResult:boolean,
    // connectSwitch:boolean,
    makeDataInput: boolean,
    makerName:string,
    makerNameList:OptionModel[],
    type: string,
    makeYear:string,
    makeYearList:OptionModel[],
    makeMonth:string,
    makeMonthList:OptionModel[],
    makeNumber:string,
    exchangeNumber: number,
    //他の
    isSourceVoltage:boolean,
    listUser: any[],


    calcPoint0: CalcPoint,
    calcPoint1: CalcPoint,
    checkNG: boolean,
    supply: boolean,
    tcName: string,
    lineActiveFlow1:number,
    lineActiveFlow2:number,
    lineReactiveFlow1:number,
    lineReactiveFlow2:number,
    primarySupply:boolean,
    upTransKind:number,
    cComplex: CComplex,
    examineComment:string,
    seriesed:boolean,
    voltDropRouteRefno: string,
    downTransKind:number,
    sPhase: boolean,
    capacity: number,
    examineCommentSPhase:string

    listCompEleMG0:CompEle,
    listCompEleMG1:CompEle,
    listCompEle1:CompEle,
    listCompEle2:CompEle
}

export interface MsFUSEModel {
    // 基本
    refNo: string,                 //	デバイス名
    noFUSE: number,
    pointText: string,             //	事故点名称
    voltageList: OptionModel[],    //	回路電圧＿リスト
    voltage: number,               //	回路電圧(V)
    isOff: boolean,                  //	主回路を開放
    fault: boolean,           //	短絡電流を計算
    dropMotor: boolean,           //	電動機始動時の電圧降下を計算
    dropRegular: boolean,         //	定常時の電圧降下を計算
    viewResultText: boolean,       //	注釈を表示

    // ModePM
    viewFlowResult: boolean,

    makeDataInput: boolean,
    makerName: string,
    makeYearList: OptionModel[],
    makeYear: number | null,
    makeMonthList: OptionModel[],
    makeMonth: number | null,
    makeNumber: string,

    // 保護協調
    details: AdjustModel,    //  動作特性曲線 - 機器詳細
    // シンボル
    symKind: number,                     //	シンボル種別
    isReadLists?: boolean,
    listUser: any[],
    
    // others
    checkNG: boolean,
    supply: boolean,
    tcName: string,
    lineActiveFlow1: number,
    lineReactiveFlow1: number,
    lineActiveFlow2: number,
    lineReactiveFlow2: number,
    downTransKind: string,
    primarySupply: boolean,
    examineComment: string,
    seriesed: boolean,
    voltDropSumRe: number,
    voltDropSumIm: number,
    voltDropRouteRefNo: string,
    rotate: number,

    calcPoint0: CalcPoint,
    calcPoint1: CalcPoint,
}

export interface Ms2EModel {
    // 基本
    no2E: number,
    refNo: string,                 //	デバイス名
    pointText: string,             //	事故点名称
    voltageList: OptionModel[],    //	受電電圧＿リスト
    voltage: number,               //	受電電圧＿値
    isOff: boolean,                  //	主回路を解放
    fault: boolean,           //	短絡電流を計算
    dropMotor: boolean,           //	電動機始動時の電圧降下を計算
    dropRegular: boolean,         //	定常時の電圧降下を計算
    viewResultText: boolean,       //	注釈を表示
    
    // ModePM
    viewFlowResult: boolean,

    makeDataInput: boolean,

    makeYearList: OptionModel[],
    makeYear: number | null,
    makeMonthList: OptionModel[],
    makeMonth: number | null,
    makeNumber: string,

    // 保護協調
    details: AdjustModel,    //  動作特性曲線 - 機器詳細
    // シンボル
    symKind: number,                //	単線結線図で表示するシンボルの選択
    listUser: any[],
    isReadLists?: boolean,

    // others
    makerName: string,
    sPhase: boolean, 
    upTransKind: number,
    checkNG: boolean,
    supply: boolean,
    tcName: string,
    lineActiveFlow1: number,
    lineReactiveFlow1: number,
    lineActiveFlow2: number,
    lineReactiveFlow2: number,
    downTransKind: string,
    primarySupply: boolean,
    examineComment: string,
    examineCommentSPhase: string,
    seriesed: boolean,
    voltDropSumRe1: number,
    voltDropSumIm1: number,
    voltDropSumRe2: number,
    voltDropSumIm2: number,
    voltDropRouteRefNo: string,
    calcPoint0: CalcPoint,
    calcPoint1: CalcPoint
}


export interface MsZerocondModel {
    refNo: string,
    pointText: string,
    voltageList: OptionModel[]
    voltage: number,
    fault: boolean,
    dropMotor: boolean,
    dropRegular: boolean,
    calcImprovePF: boolean,
    calcRightLeft: boolean,
    afterPF: number,
    viewResultText: boolean,

    //tab 2
    flowConductance:number,
    flowSusceptance:number,
    viewFlowResult:boolean,
    isSourceVoltage:boolean,
    listUser: any[],
    checkNG: boolean,
    sPhase: boolean,
    beforePF: number,
    totalCurrent: number,
    nodeNumber: number,
    voltageMagnitude: number,
    voltageAngle: number,
    activePower: number,
    reactivePower: number,
    upTransKind: number,
    supply: boolean,
    tcName: string,
    primarySupply: boolean,
    examineComment: string,
    examineCommentSPhase: string
    seriesed:boolean,

    calcPoint0: CalcPoint,
    calcPoint1: CalcPoint,
    listCompEleMG0:CompEle[],
    listCompEleMG1:CompEle[],
    listCompEle1:CompEle[],
    listCompEle2:CompEle[],
    voltDropSumRe1: number,
    voltDropSumIm1: number,
    voltDropSumRe2: number,
    voltDropSumIm2: number,
    voltDropRouteRefNo: string,
    perUnitZrZerocond: PercentZ
}

export interface MsWireModel {
    refNo:string,
    noWire:number,
    sPhase:boolean,
    rotate:number,
    voltage:number,
    fault:number, // boolean?
    dropRegular:boolean,
    checkNG:boolean,
    wireSystem:number,
    material:number,
    insulation:number,
    core:number,

    setDefault:boolean,
    bphase:boolean,
    method:number,
    circuitNumber:number,
    ambientTemp:number,
    length:number,
    dropMotor:boolean,
    eleLineNumber:number,
    eleTopLeftNumber:number,
    eleBottomRightNumber:number,
    perUnitR:number,
    perUnitX:number,
    perUnitK:number,
    lineActiveFlow1:any,
    lineReactiveFlow1:any,
    lineActiveFlow2:any,
    lineReactiveFlow2:any,
    exchangeNumber:number,
    upTransKind:number,
    supply:boolean,
    tcName:string,
    makeDataInput: boolean,
    makerName:string,
    makeYear: number | null,
    makeMonth: number | null,
    type: string,
    voltageMagnitude1:number,
    voltageAngle1:number,
    voltageMagnitude2:number,
    voltageAngle2:number,
    pointText:string,
    viewFlowResult:boolean,
    inputedCapacity1:boolean,
    inputedCurrent1:boolean,
    inputedPowerFactor1:boolean,
    inputedCapacity2:boolean,
    inputedCurrent2:boolean,
    inputedPowerFactor2:boolean,
    downTransKind:any,
    primarySupply:boolean,
    examineComment:string,
    examineCommentSPhase:string,
    seriesed:boolean,
    viewResultText:boolean,
    voltDropSumRe1:number,
    voltDropSumIm1:number,
    voltDropSumRe2:number,
    voltDropSumIm2:number,
    voltDropRouteRefNo:string,
    currentTopLeft:boolean,
    flowSusceptance:number,

    powerFactorTab6:any,
    _3Phases:WirePhase,
    PhaseN:WirePhase,
    PhasePE:WirePhase,
    calcPoint0:CalcPoint   
    calcPoint1:CalcPoint    

    ohmR_PhaseN:number,
    ohmR_PhasePE:number,
    voltageDrop:number,
    voltageDrop1:number,
    voltageDropPoint1:number,
    voltageDropPoint:number,
    
    ohmX_PhaseN:number,
    ohmX_PhasePE:number,
    
    capacity_PhaseN:number,
    capacity_PhasePE:number,
    symKind:number,
    // ---------- tab1 - 基本--------------
    field14: number,
    ohmR_3Phases:number,
    ohmX_3Phases:number,
    capacity_3Phases:number,
    // Tab 6 
    
    // ModePM
    
    makerNameList: OptionModel[],
    makeYearList: OptionModel[],
    makeMonthList: OptionModel[],

    listUser: any[],
    isSourceVoltage: boolean
}

export interface MsBusbarModel {
    // tab0 - 基本
    refNo: string,
    noBusBar:number,
    pointText: string,//1
    voltageList:OptionModel[],
    voltage: number,
    lenght: number,
    fault: boolean,
    viewResultText: boolean,
    wireSystem: number,//6
    makeDataInput: boolean,
    makerName:string,
    makerNameList:OptionModel[],
    type: string,
    makeYear:string,
    makeYearList:OptionModel[],
    makeMonth:string,
    makeMonthList:OptionModel[],
    // tab1 - 仕様
    materialCuAl: number,//material
    bareOrPainted: number,//surface
    verticalOrHorizonal: number,//install
    busbarTemp: number,
    ambient: number,//11
    // tab2 - 電圧線(3相/単相)
    m_3Phase :IeleBusbarSPhase,
    // tab3 - 電圧降下
    dropMotor: boolean,//54
    dropRegular: boolean,
    
    topLeft: number,
    leadOrLag1: number,
    leadOrLag2: number,
    voltageDrop_1: number,
    voltageDrop: number,

    voltageDrop_1_2: number,
    voltageDrop2: number,
    //tab 4 潮流解析
    flowSusceptance:number,
    viewFlowResult: boolean
    // tab5 -  中性線(N相)
    phaseN: IeleBusbarSPhase,//26 - 39
    // tab6 - 保護接地線(PE相)
    phaseNPE: IeleBusbarSPhase,//40-53
    
    // widthList: OptionModel[],
    busBarList:any[],
    // doReadZByValue:any
    listUser: any[],
    isSourceVoltage:boolean,

    calcPoint0: CalcPoint,
    calcPoint1: CalcPoint,
    listCompEleMG0:CompEle,
    listCompEleMG1:CompEle,
    listCompEle1:CompEle,
    listCompEle2:CompEle,
    sPhase: boolean,
    checkNG: boolean,
    eleLineNumber: number,
    eleTopLeftNumber: number,
    eleBottomRightNumber: number,
    perUnitR: number,
    perUnitX: number,
    perUnitK: number,
    lineActiveFlow1:number,
    lineActiveFlow2:number,
    lineReactiveFlow1:number,
    lineReactiveFlow2:number,
    upTransKind: number,
    supply: boolean,
    tcName: string,
    voltageMagnitude1: number,
    voltageMagnitude2 : number,
    voltageAngle1: number,
    voltageAngle2: number,
    inputedCapacity1: boolean,
    inputedCurrent1: boolean,
    inputedPowerFactor1: boolean,
    inputedCapacity2: boolean,
    inputedCurrent2: boolean,
    inputedPowerFactor2: boolean,
    downTransKind: number,
    primarySupply: boolean,
    examineSomment : string,
    examineCommentSPhase: string,
    seriesed : boolean,
    cComplex: CComplex,
    volDropRouteRefNo: string,
    isModeSPhases:boolean
}
export interface IeleBusbarSPhase {
    widthList:OptionModel[],
    width: number,//12
    thicknessList:OptionModel[],
    thickness: number,
    numberPhaseList:OptionModel[],
    numberPhase: number,
    distance: number,
    skinEffectA: number,
    skinEffectM: number,
    ohmRPerA: number,
    ohmRPerM: number,
    ohmR: number,
    ohmXPerA: number,
    ohmXPerM: number,

    ohmX: number,
    basicCapacityA: number,
    basicCapacityM: number,

    tempK2A: number,
    tempK2M: number,

    horizontalK3A: number,
    horizontalK3M: number,

    proximityK4A: number,
    proximityK4M: number,

    capacity: number,
}

export interface MsBusductModel {
    // tab0 - 基本
    refNo: string,
    noBusDuct:number,
    pointText: string,
    voltageList: OptionModel[],
    voltage: number,
    length: number,
    fault: boolean,
    viewResultText: boolean,
    wireSystem: number,
    makeDataInput: boolean,
    makerName:string,
    makerNameList:OptionModel[],
    type: string,
    makeYear:string,
    makeYearList:OptionModel[],
    makeMonth:string,
    makeMonthList:OptionModel[],
    busductList:any[],
    // tab1 - 仕様
    materialCU_AL: number,
    busductTemp: number,
    ambientTemp: number,
    // tab2 - 電圧線(3相/単相)
    m_3Sphase: IeleBusductSPhase,
    // tab3 - 電圧降下
    dropMotor: boolean,
    dropRegular: boolean,
    
    topLeft: number,
    leadOrLag1: number,
    leadOrLag2: number,
    voltageDrop_1: number,
    voltageDrop: number,
    voltageDrop_1_2: number,
    voltageDrop2: number,
    listUser: any[],
    isSourceVoltage:boolean,

    //tab4 - 潮流解析
    susceptance: number,
    viewFlowResult:boolean

    // tab 5 中性線(N相) 
    SPhaseN :IeleBusductSPhase,

    // tab 6 保護接地線(PE相) 
    sPhasePE:IeleBusductSPhase,
    ratedCurrentList:any[],

    sPhase: boolean,
    checkNG: boolean,
    earthCode: number,
    surface: number,

    eleLineNumber:number,
    eleTopLeftNumber:number,
    eleBottomRightNumber: number,
    perUnitR:number,
    perUnitX:number,
    perUnitK:number,
    lineActiveFlow1:number,
    lineActiveFlow2:number,
    lineReactiveFlow1:number,
    lineReactiveFlow2:number,
    upTransKind:number,
    supply: boolean,
    tcName:string,

    voltageMagnitude1: number,
    voltageMagnitude2 : number,
    voltageAngle1: number,
    voltageAngle2: number,
    inputedCapacity1: boolean,
    inputedCurrent1: boolean,
    inputedPowerFactor1: boolean,
    inputedCapacity2: boolean,
    inputedCurrent2: boolean,
    inputedPowerFactor2: boolean,
    downTransKind: number,
    primarySupply: boolean,
    examineSomment : string,
    examineCommentSPhase: string,
    seriesed : boolean,
    cComplex: CComplex,
    volDropRouteRefNo: string,

    calcPoint0: CalcPoint,
    calcPoint1: CalcPoint,
    listCompEleMG0:CompEle,
    listCompEleMG1:CompEle,
    listCompEle1:CompEle,
    listCompEle2:CompEle,
    isModeSPhases: boolean,
}
export interface IeleBusductSPhase{
    ratedCurrentList:OptionModel[],
    ratedCurrent: number,
    width: number,
    thickness: number,
    numberPhase: number,
    ohmRPerA: number,
    ohmRPerM: number,

    ohmR: number,
    ohmXPerA: number,
    ohmXPerM: number,

    ohmX: number,
    capacity: number,
}


export interface MsReactorModel {
    // tab0 - 基本
    refNo: string,//1
    noReactor: number,
    fault: boolean,
    pointText: string,
    viewFlowResult: boolean,
    viewResultText: boolean,
    voltageList: OptionModel[],
    voltage: number,
    capacity: number,
    ratedCurrent: number,
    percentX: number,
    makeDataInput: boolean,
    makerName:string,
    makerNameList:OptionModel[],
    type: string,
    makeYear:string,
    makeYearList:OptionModel[],
    makeMonth:string,
    makeMonthList:OptionModel[],
    makeNumber:string,

    // tab1 - 電圧降下
    dropMotor: boolean,
    dropRegular: boolean,
    loadCapacity: number,
    loadCurrent: number,
    topLeft: number,
    leadOrLag: number,
    powerFactor: number,
    voltageDrop1: number,
    voltageDrop: number,
    listUser: any[],
    isSourceVoltage:boolean,
    calcPoint0: CalcPoint,
    calcPoint1: CalcPoint,

    inputCurrent1:boolean,
    inputCapacity1:boolean,
    inputPowerFactor1:boolean
    downTransKind: number,
    percentR: number,
    checkNG: boolean,

    eleLineNumber: number,
    eleTopLeftNumber: number,
    eleBottomRight_number: number,
    perUnitR: number,
    perUnitX: number,
    perUnitK: number,
    lineActiveFlow1: number,
    lineReactiveFlow1: number,
    lineActiveFlow2: number,
    lineReactiveFlow2: number,
    supply: boolean,
    tcName: string,

    voltageMagnitude1: number
    voltageAngle1: number
    voltageMagnitude2: number
    voltageAngle2: number
    primarySupply: boolean
    examineComment: string
    seriesed: boolean
    cComplex: CComplex,
    voltDropRouteRefNo: string,
}

export interface MsImpedanceModel {
    // tab1 - 基本
    refNo: string,
    noImpedance:number,
    pointText: string,
    voltageList: OptionModel[],
    voltage: number,
    fault: boolean,
    // field4: boolean,
    viewResultText: boolean,
    inputPercentZ: number, //inputKind
    stdCapacity: number,//7
    xr: number,
    percentZ: number,
    percentR: number,

    percentX: number,
    ohmZ: number,//12
    ohmR: number,
    ohmX: number,
    // tab2 - 電圧降下
    dropMotor: boolean,//15
    dropRegular: boolean,
    // loadCapacity: number,//17
    // loadCurrent: number,
    topLeft: number,
    leadOrLag1: number,
    leadOrLag2: number,

    // powerFactor: number,
    voltageDrop_1: number,//22
    voltageDrop: number,

    voltageDrop_1_2: number,//22
    voltageDrop2: number,

    //tab 3
    flowSusceptance:number,
    viewFlowResult:boolean

    listUser: any[],
    isSourceVoltage:boolean,

    calcPoint0: CalcPoint,
    calcPoint1: CalcPoint,
    listCompEleMG0:CompEle,
    listCompEleMG1:CompEle,
    listCompEle1:CompEle,
    listCompEle2:CompEle,
    sPhase: boolean,
    checkNG: boolean,
    eleLineNumber: number,
    eleTopLeftNumber: number,
    eleBottomRightNumber: number,
    perUnitR: number,
    perUnitX: number,
    perUnitK: number,
    lineActiveFlow1:number,
    lineActiveFlow2:number,
    lineReactiveFlow1:number,
    lineReactiveFlow2:number,
    upTransKind: number,
    supply: boolean,
    tcName: string,
    voltageMagnitude1: number,
    voltageMagnitude2 : number,
    voltageAngle1: number,
    voltageAngle2: number,
    inputedCapacity1: boolean,
    inputedCurrent1: boolean,
    inputedPowerFactor1: boolean,
    inputedCapacity2: boolean,
    inputedCurrent2: boolean,
    inputedPowerFactor2: boolean,
    downTransKind: number,
    primarySupply: boolean,
    examineComment : string,
    examineCommentSPhase: string,
    seriesed : boolean,
    cComplex: CComplex,
    voltDropRouteRefNo: string,
}

export interface MsLVCBModel {
    // 基本
    noLVCB: number,
    refNo: string,                 //	デバイス名
    pointText: string,             //	事故点名称
    voltageList: OptionModel[],    //	回路電圧＿リスト
    voltage: number,               //	回路電圧(V)
    isOff: boolean,                  //	主回路を開放
    fault: boolean,           //	短絡電流を計算
    dropMotor: boolean,           //	電動機始動時の電圧降下を計算
    dropRegular: boolean,         //	定常時の電圧降下を計算
    viewResultText: boolean,       //	注釈を表示
    
    // ModePM
    viewFlowResult: boolean,
    connectSwitch: boolean,

    makeDataInput: boolean,
    makeYearList: OptionModel[],
    makeYear: number | null,
    makeMonthList: OptionModel[],
    makeMonth: number | null,
    makeNumber: string,

    // 保護協調
    details: AdjustModel,   //  動作特性曲線 - 機器詳細  
    // シンボル
    symKind: number,               //	シンボル種別

    isReadLists?: boolean,
    listUser: any[],

    // others
    makerName: string,
    sPhase: boolean,
    rotate: number,
    upTransKind: number,
    checkNG: boolean,
    supply: boolean,
    tcName: string,
    lineActiveFlow1: number,
    lineReactiveFlow1: number,
    lineActiveFlow2: number,
    lineReactiveFlow2: number,
    eleTopLeftNumber: number,
    eleBottomRightNumber: number,
    downTransKind: string,
    primarySupply: boolean,
    examineComment: string,
    examineCommentSPhase: string,
    seriesed: boolean,
    voltDropSumRe1: number,
    voltDropSumIm1: number,
    voltDropSumRe2: number,
    voltDropSumIm2: number,
    voltDropRouteRefNo: string,
    calcPoint0: CalcPoint,
    calcPoint1: CalcPoint,
}

export interface MsThermalModel {
    // 基本
    noThermal: number,
    refNo: string,                 //	デバイス名
    pointText: string,             //	事故点名称
    voltageList: OptionModel[],    //	受電電圧＿リスト
    voltage: number,               //	受電電圧＿値
    isOff: boolean,                  //	主回路を解放
    fault: boolean,           //	短絡電流を計算
    dropMotor: boolean,           //	電動機始動時の電圧降下を計算
    dropRegular: boolean,         //	定常時の電圧降下を計算
    viewResultText: boolean,       //	注釈を表示
    
    // ModePM
    viewFlowResult: boolean,

    makeDataInput: boolean,
    makeYearList: OptionModel[],
    makeYear: number | null,
    makeMonthList: OptionModel[],
    makeMonth: number | null,
    makeNumber: string,
    // 保護協調
    details: AdjustModel, //  動作特性曲線 - 機器詳細
    // シンボル
    symKind: number, //	単線結線図で表示するシンボルの選択

    isReadLists?: boolean,
    listUser: any[],
    
    // others
    makerName: string,
    sPhase: boolean, 
    upTransKind: number,
    checkNG: boolean,
    supply: boolean,
    tcName: string,
    lineActiveFlow1: number,
    lineReactiveFlow1: number,
    lineActiveFlow2: number,
    lineReactiveFlow2: number,
    downTransKind: string,
    primarySupply: boolean,
    examineComment: string,
    examineCommentSPhase: string,
    seriesed: boolean,
    voltDropSumRe1: number,
    voltDropSumIm1: number,
    voltDropSumRe2: number,
    voltDropSumIm2: number,
    voltDropRouteRefNo: string,
    calcPoint0: CalcPoint,
    calcPoint1: CalcPoint,
}

export interface MsThermalCTModel {
    // 基本
    noThermalCT: number,
    refNo: string,                 //	デバイス名
    pointText: string,             //	事故点名称
    voltageList: OptionModel[],    //	受電電圧＿リスト
    voltage: number,               //	受電電圧＿値
    isOff: boolean,                  //	主回路を解放
    fault: boolean,           //	短絡電流を計算
    dropMotor: boolean,           //	電動機始動時の電圧降下を計算
    dropRegular: boolean,         //	定常時の電圧降下を計算
    viewResultText: boolean,       //	注釈を表示
    
    // ModePM
    viewFlowResult: boolean,

    makeDataInput: boolean,

    makeYearList: OptionModel[],
    makeYear: number | null,
    makeMonthList: OptionModel[],
    makeMonth: number | null,
    makeNumber: string,
    // 保護協調
    details: AdjustModel,    //  動作特性曲線 - 機器詳細
    
    isReadLists?: boolean,
    listUser: any[],
    
    // others
    makerName: string,
    sPhase: boolean,
    upTransKind: number,
    checkNG: boolean,
    supply: boolean,
    tcName: string,
    lineActiveFlow1: number,
    lineReactiveFlow1: number,
    lineActiveFlow2: number,
    lineReactiveFlow2: number,
    downTransKind: string,
    primarySupply: boolean,
    examineComment: string,
    examineCommentSPhase: string,
    seriesed: boolean,
    voltDropSumRe1: number,
    voltDropSumIm1: number,
    voltDropSumRe2: number,
    voltDropSumIm2: number,
    voltDropRouteRefNo: string,
    calcPoint0: CalcPoint,
    calcPoint1: CalcPoint,
}

export interface MsMotorGroupModel {
    refNo: string,
    pointText: string, // 2
    voltageList:OptionModel[],
    voltage: number,
    capacityList:any,
    capacity: number,//4
    ratedCurrent: number,
    powerFactor: number,
    loadFactor: number,
    fault: boolean,//8
    calcImprovePF: boolean,
    improveAfterPF: number,
    viewFlowResult:boolean,
    viewResultText: boolean,//isShowAnnotations
    percentZCalculate: number,//12
    subReactance: number,
    rotorResistance: number,
    statorResistance: number,//15
    subImpedance: number,
    constant: number,
    subConstant: number,
    initialCurrent: number,//19
    symmetricalCurrent: number,
    peakValue: number,
    // perCentZDirect: number,//radio
    capacity1: number,//22
    xr: number,
    percentZ: number,
    percentR: number,//25
    percentX: number,
    ohmZ: number,//cuoi
    ohmR: number,
    ohmX: number,
    listUser: any[],
    isSourceVoltage:boolean,

    checkNG : boolean,
    nodeNumber: number,

    perUnitR:number,
    perUnitX:number,
    perUnitK:number,
    perUnitP:number,
    perUnitQ:number,
    isLead: boolean,
    voltageMagnitude:number,
    voltageAngle:number,
    activePower:number,
    reactivePower:number,
    supply: boolean,
    primarySupply:boolean,
    examineComment:string,

    calcPoint0: CalcPoint,
    calcPoint1: CalcPoint,
}

export interface MsLoadModel {
    refNo: string,
    noLoad:number,
    voltageHightList:OptionModel[],
    voltageList: OptionModel[],
    voltage: number,
    loadCurrent: number,
    leadOrLag: number, //isLead
    powerFactor: number,
    phaseCurrent: string,//5
    loadFactor: number,
    startingCurrent: number,
    startingTime: number,
    calcImprovePF: boolean,//9
    improveAfterPF: number,
    viewResultText: boolean,//11
    viewFlowResult: boolean,
    harmonicList : any[],
    listUser: any[],
    loadCurrentList:any,
    isSourceVoltage: boolean,
    // field12: any,
    // optionData: OptionDataModel[],
    sPhase:boolean,
    checkNG:boolean,
    nodeNumber:number,
    perUnitR:number,
    perUnitX:number,
    perUnitK:number,
    perUnitP:number,
    perUnitQ:number,
    voltageMagnitude:number,
    voltageAngle:number,
    activePower:number,
    reactivePower:number,
    upTransKind:number,
    supply:boolean,
    tcName:string,
    primarySupply:boolean,
}

export interface MsLoadNewFormModel {
    id: number
    field1: number,
    field2: number,
    field3: string,
    field4: string,
    field5: string,
    field6: number,
    field7: number,
    field8: number,
    field9: number,
    field10: number,
    field11: number,
    field12: number,
    field13: number,
    field14: number,
    field15: number,
    field16: number,
    field17: number,
    field18: number,
    field19: number,
    field20: number,
    field21: number,
    field22: number,
    field23: number,
    field24: number,
    field25: number,
    field26: number,
    field27: number,
    field28: number,
    field29: number,
    field30: number,
    field31: number,
    field32: number,
}

export interface MsLightBoardModel {
    refNo: string,
    noLightBoard:number,
    voltageList: OptionModel[],
    voltage: number,
    totalCapacity: number,
    totalCapacity1: number,//temp variable
    powerFactor: number,
    powerFactor1: number,//temp variable
    fullCurrent: number,
    fullCurrent1: number,
    phaseCurrent1: string,
    phaseCurrent: string,
   
    loadFactor: number,
    inputDetail: boolean,
    light: number,
    soket: number,
    FCU: number,
    other: number,
    totalCapacityUnitList: OptionModel[],
    totalCapacityUnit: string,
    lightUnit: string,
    soketUnit: string,
    FCUUnit: string,
    otherUnit: string,
    lightPF: number,
    soketPF: number,
    FCUPF: number,
    otherPF: number,
    viewResultText:boolean,
    //Mode PM
    viewFlowResult:boolean,
    makeDataInput:boolean,
    makerName: string,
    makerNameList:OptionModel[],
    type:string,
    makeYear:string,
    makeYearList:OptionModel[],
    makeMonth:string,
    makeMonthList:OptionModel[],
    makeNumber:string,
    listUser: any[],
    isSourceVoltage: boolean,
    
    sPhase: boolean,
    loadCurrent: number,
    checkNG: boolean,
    nodeNumber: number,
    perUnitR: number,
    perUnitX: number,
    perUnitK: number,
    perUnitP: number,
    perUnitQ: number,
    voltageMagnitude: number,
    voltageAngle: number,
    activePower: number,
    reactivePower: number,
    upTransKind: number,
    supply: boolean,
    tcName: string,
    totalCapacityPf: number,
    primarySupply: boolean,
    isLead: boolean
}

export interface MsPowerBoardModel {
    refNo: string,
    noPowerBoard:number,
    voltageList:OptionModel[],
    voltage: number,//1
    totalCapacity: number,
    totalCapacity1: number,//temp variable
    // totalCapacity2:number,//temp variable
    powerFactor: number,
    powerFactor1: number,//temp variable
    // powerFactor2: number,//temp variable
    fullCurrent: number,
    fullCurrent1:number,//temp variable
    // fullCurrent2:number,//temp variable
    phaseCurrent: string,
    phaseCurrent1: string,//temp variable
    // phaseCurrent2: string,//temp variable
    loadFactor: number,
    inputDetail: boolean,
    freezer: number,
    elevator: number,
    aircon: number,
    hygiene: number,
    capacitor: number,
    other: number,
    viewResultText: boolean,
    totalCapacityUnitList:OptionModel[],
    totalCapacityUnit: string,
    freezerUnit: string,
    elevatorUnit: string,
    airconUnit: string,
    hygieneUnit: string,
    capacitorUnit: string,
    otherUnit: string,
    freezerPF: number,
    elevatorPF: number,
    airconPF: number,
    hygienePF: number,
    capacitorPF: number,
    otherPF: number,
    makeDataInput:boolean,
    viewFlowResult: boolean,
    makerName: string,
    makerNameList:OptionModel[],
    type:string,
    makeYear:string,
    makeYearList:OptionModel[],
    makeMonth:string,
    makeMonthList:OptionModel[],
    makeNumber:string,
    listUser: any[],
    isSourceVoltage: boolean,
    upTransKind:number,

    sPhase: boolean,
    loadCurrent: number,
    isLead: boolean,
    checkNG: boolean,
    nodeNumber: number,
    perUnitR: number,
    perUnitX: number,
    perUnitK: number,
    perUnitP:number,
    perUnitQ:number,
    voltageMagnitude:number
    supply: boolean,
    tcName: string,
    totalCapacityPF: number
    primarySupply: boolean,
    voltageAngle: number,
    activePower: number,
    reactivePower: number,

}

export interface MsCapacitorModel {
    refNo: string,
    noCapacitor: number,
    voltageList:OptionModel[],
    voltage: number,//1
    capacity: number,
    reactorList:OptionModel[],
    reactor: number,
    staticCapacity: number,
    ratedCurrent: number,
    powerFactor: number,
    phaseCurrent: string,
    loadFactor: number,
    viewFlowResult:boolean,
    viewResultText: boolean,
    makeDataInput:boolean,
    makerName: string,
    makerNameList:OptionModel[],
    type:string,
    makeYear:string,
    makeYearList:OptionModel[],
    makeMonth:string,
    makeMonthList:OptionModel[],
    makeNumber:string,
    //tab 2
    ratedCurrent2: number,
    totalOhmR: number,
    totalOhmX: number,
    rushCurrent: number,
    peakTime: number,
    calcOhmR: boolean,
    calcOhmX: boolean,
    listUser: any[],
    isSourceVoltage: boolean,

    isLead: boolean,
    checkNG: boolean,
    curveSel: boolean,
    nodeNumber: number,
    perUnitR: number,
    perUnitX: number,
    perUnitK: number,
    perUnitP: number,
    perUnitQ: number,
    voltageMagnitude: number,
    voltageAngle: number,
    activePower: number,
    reactivePower: number,
    pageIndex: number,
    supply: boolean,
    tcName: string,
    primarySupply: boolean,
    eleNeutralPointNumber: number,
    eleLineNumber: number,
    reactorPerUnitR: number,
    reactorPerUnitX: number,
    reactorPerUnitK: number,
    lineActiveFlow1: number,
    lineReactiveFlow1: number,
    lineActiveFlow2: number,
    lineReactiveFlow2: number,
}

export interface MSTransCenterModel {
    noTransCenter: number,
    // tab1 - 基本
    refNo: string,
    pointText: string,
    voltageList: OptionModel[],
    voltage: number,

    fault: boolean,
    // ModePM
    viewFlowResult: boolean,
    viewResultText: boolean,

    xr: number,
    percentZ: number,
    percentR: number,
    percentX: number,
    ohmZ: number,
    ohmR: number,
    ohmX: number,
    xFrameCount: number,
    yFrameCount: number,

    // tab2 - 集計・計算
    thinkOnOff: boolean,
    togetherLoad: number,
    powerFactor: number,
    fullCurrent: number,
    phaseCurrent: number,
    maxPower: number,
    demandFactor: number
    periodAveragePower: number,
    periodMaxPower: number,
    loadFactor: number,

    // diagram
    tabId: number,

    // others
    sPhase: boolean, 
    upTransKind: number,
    capacity: number,
    leadLag: number,
    checkNG: boolean,
    supply: boolean,
    nodeNumber: number,
    perUnitR: number,
    perUnitX: number,
    perUnitK: number,
    perUnitP: number,
    perUnitQ: number,
    diversityFactor: number,
    voltageMagnitude: number,
    voltageAngle: number,
    activePower: number,
    reactivePower: number,
    hasElement: boolean,
    topElementId: number,
    left1: string,
    left2: string,
    left3: string,
    right1: string,
    right2: string,
    right3: string,
    primarySupply: boolean,
    examineComment: string,
    examineCommentSPhase: string,
    seriesed: boolean,

    calcPoint0: CalcPoint,
    calcPoint1: CalcPoint,

    voltDropSumRe1: number,
    voltDropSumIm1: number,
    voltDropSumRe2: number,
    voltDropSumIm2: number,
    voltDropRouteRefNo: string
}

export interface MSIntoPointModel {
    noIntoPoint: number,
    refNo: string,
    supply: boolean,
    sPhase: boolean,
    upTransKind: number,
    voltage: number,
    capacity: number,
    percentZ: number,
    nodeNumber: number,
    perUnitR: number,
    perUnitX: number,
    perUnitK: number,
    perUnitP: number,
    perUnitQ: number,
    perUnitV: number,
    voltageMagnitude: number,
    voltageAngle: number,
    activePower: number,
    reactivePower: number,
    topEleRefNo: string,
    primarySupply: boolean,
    seriesed: boolean,

    calcPoint0: CalcPoint,
    calcPoint1: CalcPoint,

    voltDropSumRe1: number,
    voltDropSumIm1: number,
    voltDropSumRe2: number,
    voltDropSumIm2: number,
    voltDropRouteRefNo: string
}

export interface Image4Model {
    text: string,
    faultPointName: string,
    receivingVoltage: number,
    capacity: number,
    isCaculateShortCircuiltCurrent: boolean,
    isCaculatePowerFactorCorrection: boolean,
    isShowAnnotations: boolean,
    xR: number,
    impedancePecent: number,
    impedancePecent1: number,
    impedancePecent2: number,
    impedance: number,
    impedance1: number,
    impedance2: number,

    tab2Filed1: number,
    tab2Filed2: number,
    tab2Filed3: string,
    tab2Filed4: string,
    tab2Filed5: string,
    tab2Filed6: string,
    tab2Filed7: string,
    tab2Child1Field1: string,
    tab2Child1Field2: string,
    tab2Child1Field3: string,
    tab2Child1Field4: string,
    tab2Child1Field5: string,
    tab2Child1Field6: string,
    tab2Child1Field7: string,
    tab2Child1Field8: string,
    tab2Child2Field1: string,
    tab2Child2Field2: number,
    tab2Child2Field3: number,

    tab3Field1: number,
    tab3Field2: number,
    tab3Field3: number,
    tab3Field4: number,

    tab4Field1: number,
}

export interface MsVCTModel {
    refNo: string,
    noVCT: number
    viewResultText: boolean,
    voltageList: OptionModel[],
    voltage: number,
    viewFlowResult:boolean,
    makeDataInput:boolean
    makerName: string,
    makerNameList:OptionModel[],
    type:string,
    makeYear:string,
    makeYearList:OptionModel[],
    makeMonth:string,
    makeMonthList:OptionModel[],
    makeNumber:string,
    symKind: number,
    listUser: any[],
    isSourceVoltage:boolean,

    checkNG: boolean,
    supply: boolean,
    tcName: string,
    lineActiveFlow1: number,
    lineReactiveFlow1: number,
    lineActiveFlow2: number,
    lineReactiveFlow2: number,
    primarySupply: boolean,
    seriesed: boolean,
}

export interface MSVTModel {
    refNo: string,
    noVT: number,
    viewResultText: boolean,
    viewFlowResult:boolean,
    voltageList: OptionModel[]
    voltage: number,
    makeDataInput:boolean
    makerName: string,
    makerNameList:OptionModel[],
    type:string,
    makeYear:string,
    makeYearList:OptionModel[],
    makeMonth:string,
    makeMonthList:OptionModel[],
    makeNumber:string,
    listUser: any[],
    isSourceVoltage: boolean,

    checkNG: boolean,
    exchangeNumber: number,
    supply: boolean,
    tcName: string,
    lineActiveFlow1: number,
    lineReactiveFlow1: number,
    lineActiveFlow2: number,
    lineReactiveFlow2: number,
    primarySupply: boolean,
    seriesed: boolean,
}

export interface MsArrestorModel {
    refNo: string,
    noArrestor: number,
    voltageList: OptionModel[],
    voltage: number,
    makeDataInput:boolean
    makerName: string,
    makerNameList:OptionModel[],
    type:string,
    makeYear:string,
    makeYearList:OptionModel[],
    makeMonth:string,
    makeMonthList:OptionModel[],
    makeNumber:string,
    symKind: number,
    haveEarth: boolean,

    listUser: any[],
    isSourceVoltage: boolean,

    checkNG: boolean,
    supply: boolean,
    tcName: string,
    primarySupply: boolean,
    }

export interface MsEarthModel {
    refNo: string,
    noEarth: number,
    voltageList:OptionModel[]
    voltage: number,
    makeDataInput:boolean
    makerName: string,
    makerNameList:OptionModel[],
    type:string,
    makeYear:string,
    makeYearList:OptionModel[],
    makeMonth:string,
    makeMonthList:OptionModel[],
    makeNumber:string,
    symKind: number,
    isSourceVoltage:boolean,

    checkNG: boolean,
    supply: boolean,
    tcName: string,
    primarySupply: boolean,
}

export interface MsCableHeadModel {
    refNo: string,
    noCableHead: number,
    voltageList: OptionModel[],
    voltage: number,
    symKind: number,
    isSourceVoltage: boolean,
    sPhase: boolean,
    checkNG: boolean,
    upTransKind: number,
    supply: boolean,
    tcName: string,
    makerName: string,
    makeYear: string,
    makeNumber: string,
    primarySupply: boolean,
    seriesed: boolean,
}

export interface MsCTModel {
    refNo: string,
    noCT:number,
    viewResultText: boolean,
    voltageList:OptionModel[],
    voltage: number,
    viewFlowResult:boolean,
    makeDataInput:boolean
    makerName: string,
    makerNameList:OptionModel[],
    type:string,
    makeYear:string,
    makeYearList:OptionModel[],
    makeMonth:string,
    makeMonthList:OptionModel[],
    makeNumber:string,
    listUser: any[],
    isSourceVoltage:boolean,
    sPhase: boolean,
    checkNG: boolean,
    exchangeNumber: number,
    upTransKind: number,
    supply: boolean,
    tcName: string,
    lineActiveFlow1: number,
    lineReactiveFlow1: number,
    lineActiveFlow2: number,
    lineReactiveFlow2: number,
    primarySupply: boolean,
    seriesed: boolean,
}

export interface MSZCTModel {
    refNo: string,
    noZCT:number,
    viewResultText: boolean,
    voltage: number,
    voltageList: OptionModel[]
    viewFlowResult:boolean,
    makeDataInput:boolean
    makerName: string,
    makerNameList:OptionModel[],
    type:string,
    makeYear:string,
    makeYearList:OptionModel[],
    makeMonth:string,
    makeMonthList:OptionModel[],
    makeNumber:string,
    listUser: any[],
    isSourceVoltage:boolean,

    checkNG: boolean,
    exchangeNumber: number,
    supply: boolean,
    tcName: string,
    lineActiveFlow1: number,
    lineReactiveFlow1: number,
    lineActiveFlow2: number,
    lineReactiveFlow2: number,
    primarySupply: boolean,
    seriesed: boolean,
}

export interface MsINVModel {
    refNo : string,
    noINV: number,
    voltage: number,
    voltageList : OptionModel[],
    checkNG : boolean,
    supply : boolean,
    viewFlowResult : boolean,
    tcName : string,
    makeDataInput : boolean
    makerName:   string,
    makerNameList : OptionModel[],
    type : string,
    makeYear : string,
    makeYearList : OptionModel[],
    makeMonth : string,
    makeMonthList : OptionModel[],
    makeNumber : string,
    primarySupply : boolean,
    seriesed : boolean,
    viewResultText: boolean,
    harmonicList : any[],
    listUser: any[],
    isSourceVoltage:boolean,

    exchangeNumber: number,
    lineActiveFlow1: number,
    lineReactiveFlow1: number,
    lineActiveFlow2: number,
    lineReactiveFlow2: number,
}

export interface MsRelayModel {
    refNo: string,
    viewResultText: boolean,
    voltageList:OptionModel[],
    voltage: number,
    viewFlowResult:boolean,
    makeDataInput:boolean
    makerName: string,
    makerNameList:OptionModel[],
    type:string,
    makeYear:string,
    makeYearList:OptionModel[],
    makeMonth:string,
    makeMonthList:OptionModel[],
    makeNumber:string,
    symKind: number,
    subText: string,
    listUser: any[],
    isSourceVoltage:boolean,

    checkNG: boolean,
    supply: boolean,
    tcName: string,
    lineActiveFlow1: number,
    lineReactiveFlow1: number,
    lineActiveFlow2: number,
    lineReactiveFlow2: number,
    primarySupply: boolean,
    seriesed: boolean,
}

export interface HarmonicGeneratorsModel {
    id: number,
    receivingVoltage: number,
    field2: number,
    field3: string,
    field4: string,
    field5: string,
    field6: number,
    field7: number,
    field8: number,
    field9: number,
    field10: number,
    field11: number,
    field12: number,
    field13: number,
    field14: number,
    field15: number,
    field16: number,
    field17: number,
    field18: number,
    field19: number,
    field20: number,
    field21: number,
    field22: number,
    field23: number,
    field24: number,
    field25: number,
    field26: number,
    field27: number,
    field28: number,
    field29: number,
    field30: number,
    field31: number,
}

export interface OptionDataModel {
    refNo: string,
    noUser:number,
    name: string,
    notes: string,
    freeOrCircuitVolt: number,
    voltage: number,
    stdVoltage: number,
    calcCircuitVolt: boolean,
    listPoint: any,
    formulaProperties: Formula,
    remProperties:RemModel,
    pageIndex: number,
    userCurveId:number
}
export interface Formula{
    formula: string,
    currentMax: number,
    currentMin: number,
    currentInterval: number,
    currentPartition:number,
    timeMax: number,
    timeMin:number,
    checkTime: boolean,
    checkCurrent: boolean,
    // userCurvePointId:number,
    // userCurveId:number
}

export interface RemModel{
    ratedPower : number,
    ratedVoltage : number,
    ratedCurrent : number,
    startingCurrent : number,
    startingTime : number,
    puScale : number,
    stallTime : number,
    weightingFactor : number,
    priorLoadCurrent : number,
    ctPrimary : number,
    ctSecondary : number,
    input5Aor1A : number,
    shortCircuitUse : boolean,
    shortCircuitCurrent : number, // startCurrent
    shortOperateTime : number,
    ambientTemperature : number,
    timeConstant : number
    listDrawREM610 : Point[],
    notes: string
    
}
export interface HarmonicRegisterModel {
    hamorItemID : number,
    voltage : number,
    electricPower : number,
    name : string,
    manufacture : number|null,
    type : number|null,
    capacity : number,
    number : number,
    totalCapacity : number,
    kindCode : number|null,
    kindList :  OptionModel[],
    harmoListId: number,
    kindName : string,
    example : string,
    detailCode : number,
    detailName : string,
    detailList :  OptionModel[],
    b3Phases : boolean,
    coEfficient : number,
    equipvalentCapacity : number,
    convertRated : number,
    operatingRatio : number,
    percent5 : number,
    percent7 : number,
    percent11 : number,
    percent13 : number,
    percent17 : number,
    percent19 : number,
    percent23 : number,
    percent25 : number,
    current5 : number,
    current7 : number,
    current11 : number,
    current13 : number,
    current17 : number,
    current19 : number,
    current23 : number,
    current25 : number,
}

export interface Iele3WindingPart{
    voltage:number,
    capacity:number,
    xR:number,
    perRk3:number,
    perXk3:number,
    perZk3:number,
    inrushTimes:number,
    dampingTime:number,
    intensityTimes:number,
    intensityTime:number,
    dispInrush:boolean;
    dispIntensity:boolean;
    fault:boolean,
    selInrush:boolean,
    selIntensity:boolean,
    infoLineDispBand:boolean
    infoLineColor:string,
    infoLineKind:number,
    infoLineWidth:number,
    pageIndex:number,
    pointText:string,
    eleLineNumber:number,
    eleNodeNumber:number,
    perUnitR:number,
    perUnitX:number,
    perUnitK:number,
    lineActiveFlow1:number,
    lineReactiveFlow1:number,
    lineActiveFlow2:number,
    lineReactiveFlow2:number,
    voltageMagnitude1:number,
    voltageAngle1:number,
    voltageMagnitude2:number,
    voltageAngle2:number,
    inputedCapacity1:boolean,
    inputedCurrent1:boolean,
    inputedPowerFactor1:boolean,
    neutralKind:number,
    dispAnsiKind:number,
    ansiTimes:number,
    ansiTime:number,
    inrushTimesKind:number,
    inrushManualValue:number,
    inrushTime:number,
    ratedCurrent:number,
    ohmR:number,
    ohmX:number,
    ohmZ:number,
    dropRegular:boolean,
    calcPoint0:CalcPoint,
    calcPoint1:CalcPoint,
    examineComment:string,
    voltDropSumRe: number,
    voltDropSumIm: number,
    voltDropRouteRefNo: string
}

export interface CalcPoint{
    eleTrans1PartKind:number,
    ele3WindingPartKind:number,
    eleTranscottPartKind:number,
    calcPointId:number,
    stdCapacity:number,
    loadCurrent: number,
    isLead:boolean,
    powerFactor:number,
    pointTopX:number,
    pointTopY:number,
    pointBottomX:number,
    pointBottomY:number,
    perZDirect:PercentZ,
    perZTotal:PercentZ,
    perZDirectMG:PercentZ,
    perZTotalMG:PercentZ,
    perZMotor:PercentZ,
    calcKind:number,
    voltage:number,
    loadCapacity:number,
}

export interface WireBasic{
    wireSystem:number,          // 配線方式
    material:number,            // 材質
    insulation:number,          // 紀緑方式
    core:number,                // 心のタイブ
    method:number,              // 設置方法
    circuitNumber:number,       // ユトレイ上の回路数
    ambientTemp:number,         // 周同温度
    length:number,              // 長さ (m)
}

export interface WirePhase{
    phaseKind:number,
    coreSize:number,
    number:number,
    ohmRperperA:number,             // 1心1mあたりの抵抗値 (mohmsブmグ10) (自動)
    ohmRperperM:number,             // 1心1mあたりの抵抗値 (mohmsブmグ 10) (手入力)
    diameterA:number,               // 直径 (mm) (自動)
    diameterM:number,               // 直径 (mm) (手入力)
    thickNessA:number,              // さT (mm) (自動)
    thickNessM:number,              // さT (mm) (手入力)
    distanceA:number,               // 相間距離(mm) (自動)
    distanceM:number,               // 絕得皮膜厚这T(mm) (自動)
    ohmXperperA:number,             // (自動)
    ohmXperperM:number,             // (手入力)
    basicCapacityA:number,           // 基本許容電流 (自動)
    basicCapacityM:number,           // 基本許容電流 (手入力)
    tempk2A:number,                 // 温度補正係数k 2 (自動)
    tempk2M:number,                 // 温度補正係数k 2 (手入力)
    reductionFactorA:number,        // グループ切欠係数 (自動)
    reductionFactorM:number,        // グループ切欠係数 (手入力)
}

export interface WireModel{
    setDefault:boolean,
    bphase:boolean,
    voltage:number,
    exchangeNumber:number,
    dropRegular:boolean,
    dropMotor:boolean,
    viewFlowResult:boolean,
    eleLineNumber:number,
    eleTopLeftNumber:number,
    eleBottomRightNumber:number,
    perUnitR:number,
    perUnitX:number,
    perUnitK:number,
    lineActiveFlow:any,
    lineReactiveFlow:any,
    basic:WireBasic,
    _3Phases:WirePhase,
    PhaseN:WirePhase,
    PhasePE:WirePhase,
    calcPoint:CalcPoint[]
}

export interface ConnectModel{
    sPhase: boolean, 
    rotate: number,
    upTransKind: string
    supply:boolean,
    primarySupply:boolean
}

export interface PowerFlowModel{
    powerFlowMethod:number,
    maxIteration:number,
    precision:number,
    accelerationFactor:number,
    flowViewMethod:number,
}

export interface UndoModel{
    type:string|null,
    dataUndo:any|null,
}

export interface NotificationModel{
    userId: number,
    notificationId: number,
    unread: boolean|null,
    title: string|null,
    startDate: string|null,
    endDate: string|null,
    target: number|null,
    sendMail: string|null,
    dateCreated: string|null,
    emailGreeting: string|null,
    emailSignature: string|null
}