export const MS_ELEMENT_ALL = "MS_ELEMENT_ALL";    // すべての機器
export const MS_HVCB = "MS_HVCB";    // 過電流継電器
export const MS_LVCB = "MS_LVCB";    // 低圧遮断器
export const MS_THERMAL = "MS_THERMAL";    // サーマルリレー
export const MS_SOURCE = "MS_SOURCE";    // 電源
export const MS_TRANS3 = "MS_TRANS3";    // 三相変圧器
export const MS_GENERATOR = "MS_GENERATOR";    // 発電機
export const MS_MOTOR_GROUP = "MS_MOTOR_GROUP";    // 電動機群
export const MS_ZEROCOND = "MS_ZEROCOND";    // 母線
export const MS_BUSBAR = "MS_BUSBAR";    // ブスバー
export const MS_WIRE = "MS_WIRE";    // 電線
export const MS_CONTACTOR = "MS_CONTACTOR";    // 電磁接触器
export const MS_MOTOR = "MS_MOTOR";    // 電動機
export const MS_LOAD = "MS_LOAD";    // 一般負荷
export const MS_CAPACITOR = "MS_CAPACITOR";    // コンデンサ
export const MS_2E = "MS_2E";    // 電動機保護リレー
export const MS_FUSE = "MS_FUSE";    // 高圧ヒューズ
export const MS_MVCB = "MS_MVCB";    // 遮断器（マルチボルテージ）
export const MS_REACTOR = "MS_REACTOR";    // 限流リアクトル
export const MS_3WINDING = "MS_3WINDING";    // 三巻線変圧器
export const MS_BUSDUCT = "MS_BUSDUCT";    // バスダクト
export const MS_TRANS1 = "MS_TRANS1";    // 単相変圧器
export const MS_TEXT = "MS_TEXT";    // 任意文字列
export const MS_TRANSSCOTT = "MS_TRANSSCOTT";    // スコット結線変圧器
export const MS_DS = "MS_DS";    // 断路器
export const MS_LBS = "MS_LBS";    // 開閉器
export const MS_THERMAL_CT = "MS_THERMAL_CT";    // 過負荷継電器
export const MS_ARRESTOR = "MS_ARRESTOR";    // 避雷器
export const MS_EARTH = "MS_EARTH";    // 接地
export const MS_CABLEHEAD = "MS_CABLEHEAD";    // ケーブルヘッド
export const MS_CT = "MS_CT";    // 変流器
export const MS_ZCT = "MS_ZCT";    // 零相変流器
export const MS_VT = "MS_VT";    // 計器用変圧器
export const MS_VCT = "MS_VCT";    // 計器用変成器
export const MS_LOADCENTER = "MS_LOADCENTER";    // ロードセンタ
export const MS_TRANSCENTER = "MS_TRANSCENTER";    // 変台
export const MS_LIGHTBOARD = "MS_LIGHTBOARD";    // 分電盤
export const MS_POWERBOARD = "MS_POWERBOARD";    // 制御盤
export const MS_INTO_POINT = "MS_INTO_POINT";    // 引込口
export const MS_RELAY51 = "MS_RELAY51";    // 継電器_51
export const MS_IMPEDANCE = "MS_IMPEDANCE";    // インピーダンス
export const MS_CONNECT = "MS_CONNECT";    // 接続線 
export const MS_RELAY = "MS_RELAY";    // リレー
export const MS_PERCENT_Z = "MS_PERCENT_Z";    // ％Ｚ
export const MS_SEGMENT = "MS_SEGMENT";    // 線分
export const MS_STRAIGHT = "MS_STRAIGHT";    // 直線
export const MS_POINT_TEXT = "MS_POINT_TEXT";    // 事故点名称
export const MS_IMPEDANCE_ELEMENT = "MS_IMPEDANCE_ELEMENT";    // インピーダンス機器
export const MS_CALC_AND_EXAMINE = "MS_CALC_AND_EXAMINE";    // 未使用
export const MS_FLOW_HEADER_1 = "MS_FLOW_HEADER_1";    // 潮流計算ヘッダ一行目
export const MS_COVER_HEADER_TITLE = "MS_COVER_HEADER_TITLE";    // 表紙ヘッダのタイトル
export const MS_COVER_HEADER_ITEM = "MS_COVER_HEADER_ITEM";    // 表紙ヘッダの項目
export const MS_INV = "MS_INV";    // インバーター

export enum MS_ELEMENT_ALL1{
    MS_ELEMENT_ALL = 0,
    MS_HVCB = 1,
    MS_LVCB = 2,
    MS_THERMAL = 3,
    MS_SOURCE = 4,
    MS_TRANS3 = 5,
    MS_GENERATOR = 6,
    MS_MOTOR_GROUP = 7,
    MS_ZEROCOND = 8,
    MS_BUSBAR = 9,
    MS_WIRE = 10,
    MS_CONTACTOR = 11,
    MS_MOTOR = 12,
    MS_LOAD = 13,
    MS_CAPACITOR = 14,
    MS_2E = 15,
    MS_FUSE = 17,
    MS_MVCB = 18,
    MS_REACTOR = 19,
    MS_3WINDING = 20,
    MS_BUSDUCT = 21,
    MS_TRANS1 = 22,
    MS_TEXT = 23,
    MS_TRANSSCOTT = 24,
    MS_DS = 25,
    MS_LBS = 26,
    MS_THERMAL_CT = 27,
    MS_ARRESTOR = 28,
    MS_EARTH = 29,
    MS_CABLEHEAD = 30,
    MS_CT = 31,
    MS_ZCT = 32,
    MS_VT = 33,
    MS_VCT = 34,
    MS_LOADCENTER = 35,
    MS_TRANSCENTER = 36,
    MS_LIGHTBOARD = 37,
    MS_POWERBOARD = 38,
    MS_INTO_POINT = 39,
    MS_RELAY51 = 40,
    MS_IMPEDANCE = 41,
    MS_CONNECT = 42,
    MS_INV = 43,
    MS_RELAY = 44
}

export type ElementKind =
    "MS_ELEMENT_ALL" |
    "MS_HVCB" |
    "MS_LVCB" |
    "MS_THERMAL" |
    "MS_SOURCE" |
    "MS_TRANS3" |
    "MS_GENERATOR" |
    "MS_MOTOR_GROUP" |
    "MS_ZEROCOND" |
    "MS_BUSBAR" |
    "MS_WIRE" |
    "MS_CONTACTOR" |
    "MS_MOTOR" |
    "MS_LOAD" |
    "MS_CAPACITOR" |
    "MS_2E" |
    "MS_FUSE" |
    "MS_MVCB" |
    "MS_REACTOR" |
    "MS_3WINDING" |
    "MS_BUSDUCT" |
    "MS_TRANS1" |
    "MS_TEXT" |
    "MS_TRANSSCOTT" |
    "MS_DS" |
    "MS_LBS" |
    "MS_THERMAL_CT" |
    "MS_ARRESTOR" |
    "MS_EARTH" |
    "MS_CABLEHEAD" |
    "MS_CT" |
    "MS_ZCT" |
    "MS_VT" |
    "MS_VCT" |
    "MS_LOADCENTER" |
    "MS_TRANSCENTER" |
    "MS_LIGHTBOARD" |
    "MS_POWERBOARD" |
    "MS_INTO_POINT" |
    "MS_RELAY51" |
    "MS_IMPEDANCE" |
    "MS_CONNECT" |
    "MS_RELAY" |
    "MS_PERCENT_Z" |
    "MS_SEGMENT" |
    "MS_STRAIGHT" |
    "MS_POINT_TEXT" |
    "MS_IMPEDANCE_ELEMENT" |
    "MS_CALC_AND_EXAMINE" |
    "MS_FLOW_HEADER_1" |
    "MS_COVER_HEADER_TITLE" |
    "MS_COVER_HEADER_ITEM" |
    "MS_INV";