import React, { useEffect, useRef, useState } from 'react'
import { EquipTrans3Model } from '../../../models/breakModel'
import { Box, Button, Checkbox, CircularProgress, FormControlLabel, Grid, Stack, Tab, Tabs, TextField, Typography } from '@mui/material'
import { ApplicationState, actionCreators, selectors } from '../../../store'
import { connect } from 'react-redux'
import { Controller, useForm } from 'react-hook-form'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Path as RoutePath } from "../../../path";
import FetchHelper from '../../FetchHelper'
import { LoadingButton } from '@mui/lab'
//-----------
//FETCH ID
const GET_EQUIP_TRANS3 = "GET_EQUIP_TRANS3"
const UPDATE_EQUIP_TRANS3 = "UPDATE_EQUIP_TRANS3"
const CREATE_EQUIP_TRANS3 = "CREATE_EQUIP_TRANS3"

const initialValue: EquipTrans3Model = {
    lTrans3Id: 0,
    bIsDefault: false,
    dCapacity: 0,
    dDampingTime: 0,
    dPeakValue: 0,
    dPercentr: 0,
    dPercentx: 0,

}
// -------------
// Props
export type EquipTrans3EditProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
const EquipTrans3Edit = (props: EquipTrans3EditProps) => {
    const {
        userId,
        onCreate, onCreateError,
        onUpdate, onUpdateError,
        onSuccess, onError,
    } = props;

    const { control, formState: { errors }, handleSubmit, setValue, getValues, } = useForm<EquipTrans3Model>({
        criteriaMode: "all",
        defaultValues: initialValue,
        mode: "all",
    })
    type MyParams = {
        id: string;
        mode: string;
    };
    let { id, mode } = useParams<keyof MyParams>() as MyParams;
    const location = useLocation();
    let navigate = useNavigate();
    const [loadingFlag, setLoadingFlag] = useState(mode === "edit" ? true : false);
    const gridRef = useRef() as any;
    const Exp = /^[-+]?\d+(\.\d+)?$/;
    const messageIsNotNumber = "数字を入力してください。";

    const handleSuccess = (data: any) => {
        console.log('>>>> handle Successs');
        // if(data && data.data){
        const obj = data.data;
        setValue("lTrans3Id", obj.lTrans3Id);
        setValue("bIsDefault", obj.bIsDefault);
        setValue("dCapacity", obj.dCapacity?? "");
        setValue("dDampingTime", obj.dDampingTime?? "");
        setValue("dPeakValue", obj.dPeakValue?? "");
        setValue("dPercentr", obj.dPercentr?? "");
        setValue("dPercentx", obj.dPercentx?? "");
        // }
        setLoadingFlag(false);
    }

    // 検索エラー時
    const handleError = (success: boolean, data: any, error: any) => {
        console.log('>>>> handle Error');
        gridRef.current.hideOverlay();
    }

    // 検索実行
    const handleEditSubmit = (data: EquipTrans3Model) => {
        setLoadingFlag(true);
        if (mode === "edit") {
            let request = {
                lTrans3Id: getValues("lTrans3Id"),
                bIsDefault: getValues("bIsDefault") ?? null,
                dCapacity: getValues("dCapacity") ?? null,
                dDampingTime: getValues("dDampingTime") ?? null,
                dPeakValue: getValues("dPeakValue") ?? null,
                dPercentr: getValues("dPercentr") ?? null,
                dPercentx: getValues("dPercentx") ?? null
            } as any
            const object = {
                requestPrms: request,
                userId: userId,
            }
            onUpdate(object);
        } else if (mode === "add") {
            let request = {
                bIsDefault: getValues("bIsDefault") ?? null,
                dCapacity: getValues("dCapacity") ?? null,
                dDampingTime: getValues("dDampingTime") ?? null,
                dPeakValue: getValues("dPeakValue") ?? null,
                dPercentr: getValues("dPercentr") ?? null,
                dPercentx: getValues("dPercentx") ?? null,
            } as any
            const object = {
                requestPrms: request,
                userId: userId,
            }
            onCreate(object);
        }
    }

    // キャンセル
    const handleCancel = () => {
        if (location.key !== "default")
            navigate(-1);
        else
            navigate(RoutePath.EquipTrans3List);
    }
    // Submit後のAPI呼び出しエラー時
    const handleErrorUpdate = (success: boolean, data: any, error: any) => {
        onUpdateError();
        setLoadingFlag(false);
    }

    // Submit後のAPI呼び出し成功時
    const handleSuccessSubmit = (success: boolean, data: any, error: any) => {
        onSuccess();
        if (mode === "add") {
            navigate(RoutePath.EquipTrans3Edit + `/edit/${data.data.lTrans3Id}`, { replace: true })
        }
        setLoadingFlag(false);
    }

    // Submit後のAPI呼び出しエラー時
    const handleErrorSubmit = (success: boolean, data: any, error: any) => {
        onCreateError();
        setLoadingFlag(false)
    }

    //入力欄背景
    const divStyles = {
        backgroundColor: 'white',
        border: '1px solid #bebebe',
        boxShadow: '0 0 5px #fff, 0 0 5px #bebebe, 0 0 1px #aaa',
        padding: '20px 40px',
        marginTop: '40px',
    }

    return (
        <>
            {
                mode === "edit" &&
                <FetchHelper
                    fetchId={GET_EQUIP_TRANS3}
                    url={`/master/equip_trans3/get`}
                    method="POST"
                    request={true}
                    params={{
                        requestPrms: {
                            id: id
                        },
                        userId: userId,
                    }}
                    json={true}
                    onComplete={(success, data, error) => {
                        success ? handleSuccess(data) : handleError(success, data, error);
                    }}
                />
            }
            {loadingFlag && (
                <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1500, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                    <CircularProgress />
                </div>
            )}
            <div style={{ height: "100%", width: "100%", marginTop: "10px" }}>
                <Stack direction="row" justifyContent="flex-start" alignItems="center" sx={{}} style={{ margin: '18px', color: 'MenuText' }}>
                    <Typography variant="h4">
                    三相変圧器詳細
                    </Typography>
                </Stack>
                <form onSubmit={handleSubmit(handleEditSubmit)}>
                        <div style={divStyles}>
                            <Typography variant="h6">
                            三相変圧器
                            </Typography>
                            <Stack mt={3} direction={{ xs: 'column', sm: 'row' }} sx={{ maxHeight: "calc(100vh)", overflow: 'auto' }}>
                                <Grid container spacing={3} direction={'row'}>
                                    <Grid item xs={6} mt={1}>
                                        <Controller
                                            control={control}
                                            name="dCapacity"
                                            rules={{
                                                pattern: {
                                                    value: Exp,
                                                    message: messageIsNotNumber,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.dCapacity}
                                                    helperText={errors?.dCapacity?.message}
                                                    type="text"
                                                    size="small"
                                                    label="容量（kVA）"
                                                // InputLabelProps={{ shrink: true }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6} mt={1}>
                                        <Controller
                                            control={control}
                                            name="dDampingTime"
                                            rules={{
                                                pattern: {
                                                    value: Exp,
                                                    message: messageIsNotNumber,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.dDampingTime}
                                                    helperText={errors?.dDampingTime?.message}
                                                    type="text"
                                                    size="small"
                                                    label="Damping time constant（cycles）"


                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            control={control}
                                            name="dPeakValue"
                                            rules={{
                                                pattern: {
                                                    value: Exp,
                                                    message: messageIsNotNumber,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.dPeakValue}
                                                    helperText={errors?.dPeakValue?.message}
                                                    type="text"
                                                    label="Peak value of rush current（times）"
                                                    size="small"

                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            control={control}
                                            name="dPercentr"
                                            rules={{
                                                pattern: {
                                                    value: Exp,
                                                    message: messageIsNotNumber,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.dPercentr}
                                                    helperText={errors?.dPercentr?.message}
                                                    type="text"
                                                    label="%R（%）"
                                                    size="small"

                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            control={control}
                                            name="dPercentx"
                                            rules={{
                                                pattern: {
                                                    value: Exp,
                                                    message: messageIsNotNumber,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.dPercentx}
                                                    helperText={errors?.dPercentx?.message}
                                                    type="text"
                                                    label="%X（%）"
                                                    size="small"

                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            control={control}
                                            name="bIsDefault"
                                            render={({ field }: any) => (
                                                <FormControlLabel {...field} control={<Checkbox />} checked={field.value} label="デフォルトの容量値" labelPlacement="start" />
                                              )}
                                        />
                                    </Grid>
                                </Grid>
                            </Stack>
                        </div>
                        <Stack direction='row' justifyContent="flex-end" spacing={2} sx={{ mt: 1, mr: 2 }}>
                            <LoadingButton variant="contained" type="submit" loading={loadingFlag}>保存</LoadingButton>
                            <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                        </Stack>
                    {/* </TabPanel> */}
                </form>
            </div>
            <FetchHelper fetchId={UPDATE_EQUIP_TRANS3}
                onComplete={(success: boolean, data: any, error: any) =>
                    success ? handleSuccessSubmit(success, data, error) : handleErrorUpdate(success, data, error)} />

            <FetchHelper fetchId={CREATE_EQUIP_TRANS3}
                onComplete={(success, data, error) => success ?
                    handleSuccessSubmit(success, data, error) :
                    handleErrorSubmit(success, data, error)} />

        </>
    );
}
const mapStateToProps = (state: ApplicationState) => (
    {
        userId: state.app.user?.userId,
    }
)
const mapDispatchToProps = (dispatch: any) => {
    return {
        onCreate: (data: any) => dispatch(actionCreators.fetch(CREATE_EQUIP_TRANS3, `/master/equip_trans3/add`, "POST", data, false, true)),
        onUpdate: (data: any) => dispatch(actionCreators.fetch(UPDATE_EQUIP_TRANS3, `/master/equip_trans3/modify`, "POST", data, false, true)),
        onSuccess: () =>
            dispatch(actionCreators.showMessage({
                type: "info",
                title: "三相変圧器更新",
                body: "三相変圧器を更新しました。",
            })),
        onError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "検索",
                body: "検索できません。",
            })),
        onCreateError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "三相変圧器登録",
                body: "三相変圧器を登録出来ませんでした。",
            })),
        onUpdateError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "三相変圧器編集",
                body: "三相変圧器を更新出来ませんでした。",
            })),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(EquipTrans3Edit)