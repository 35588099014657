import React, { forwardRef, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { PageData } from '../../../models/Index';
import { EquipTransscottModel } from '../../../models/breakModel';
import LoadingOverlay, { DefaultLoadingMessage } from '../../common/LoadingOverlay';
import NoRowsOverlay, { NoRowsMessage } from '../../common/NoRowsOverlay';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Grid, IconButton } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import { AG_GRID_LOCALE_JP } from '../../../themes/localeJP';
import PaginationCpn from '../../common/PaginationCpn';
export type EquipTransscottGridProps = {
  colState: any[];
  pageSize?: number;
  pageData?: PageData;
  onChangeSearchCondition: (name: string, value: any) => void;
  keyword: string;
  onDelete: (data: EquipTransscottModel) => void;
  onShowDetails: (lTrans1Id: string) => void;
  heightForm?: string;
  checkEdit?: boolean;
  mode?: string;
}
const EquipTransscottGrid = forwardRef((props: EquipTransscottGridProps, ref) => {
  const {
    pageSize,
    pageData,
    heightForm,
    keyword,
    checkEdit,
    onChangeSearchCondition,
    onDelete,
    onShowDetails,
    mode
  } = props;
  const gridRef = useRef() as any;
  const [rowData, setRowData] = useState();
  useImperativeHandle(ref, () => ({
    setRowData(value: any) {
      setRowData(value)
    },
    hideOverlay() {
      gridRef.current.api.hideOverlay();
    }
  }));
  // ag-Grid の準備完了後に呼ばれるコールバック関数
  const onGridReady = (grid: any) => {
  };

  // Loading時のメッセージ
  const loadingOverlayComponent = useMemo(() => {
    return LoadingOverlay;
  }, []);
  const loadingOverlayComponentParams = useMemo(() => {
    return {
      loadingMessage: checkEdit ? "" : DefaultLoadingMessage,
    };
  }, []);

  const noRowsOverlayComponent = useMemo(() => {
    return NoRowsOverlay;
  }, []);

  const noRowsOverlayComponentParams = useMemo(() => {
    return {
      noRowsMessageFunc: () => NoRowsMessage,
    };
  }, []);

  // 編集ボタンのセル内表示とボタンイベント
  const cellMessageRenderer = (props: any) => {
    // 詳細
    const handleEdit = () => {
      onShowDetails(props.data.lTrans1Id);
    };

    const handleDelete = () => {
      onDelete(props.data.lTrans1Id);
    };

    return (
      <Grid container direction="row" justifyContent="flex-start" paddingLeft={"-60px"}>
        <IconButton aria-label="edit" onClick={handleEdit} >
          <EditIcon fontSize="small" />
        </IconButton>
        <IconButton aria-label="delete" onClick={handleDelete} >
          <DeleteIcon fontSize="small" />
        </IconButton>
      </Grid>
    );
  };
  // 全列に共通の定義
  const defaultColDef = {
    sortable: true, // ソート可
    editable: false, // 編集不可
    resizable: true,
    suppressMovable: true,  // 列の移動を無効にする
    headerClass: "ag-custom-header"
  };

  // 列タイプの定義
  const columnTypes = {
    nonEditableColumn: { editable: false }, // 編集不可
  };
  // 列の個別定義
  const columnDefs = [
    {
      headerName: "単相変圧器情報ID",
      field: "lTrans1Id",
      rowDrag: false,
      flex: 1.5,
      minWidth: 180
    },
    {
      headerName: "デフォルトの容量値",
      field: "bIsDefault",
      rowDrag: false,
      flex: 1.5,
      minWidth: 150
    },
    {
      headerName: "容量（kVA）",
      field: "dCapacity",
      rowDrag: false,
      flex: 1.5,
      minWidth: 150
    },
    {
      headerName: "Damping time constant（cycles）(主座変圧器)",
      field: "dDampingTimeSeatm",
      rowDrag: false,
      flex: 1.5,
      minWidth: 320
    },
    {
      headerName: "Damping time constant（cycles）(T座変圧器)",
      field: "dDampingTimeSeatt",
      rowDrag: false,
      flex: 1.5,
      minWidth: 320
    },
    {
      headerName: "Peak value of rush current（times）(主座変圧器)",
      field: "dPeakValueSeatm",
      rowDrag: false,
      flex: 1.5,
      minWidth: 330
    },
    {
      headerName: "Peak value of rush current（times）(T座変圧器)",
      field: "dPeakValueSeatt",
      rowDrag: false,
      flex: 1.5,
      minWidth: 330
    },
    {
      headerName: "主座の%R（%）",
      field: "dPerrseatm",
      rowDrag: false,
      flex: 1.5,
      minWidth: 150
    },
    {
      headerName: "T座の%R（%）",
      field: "dPerrseatt",
      rowDrag: false,
      flex: 1.5,
      minWidth: 150
    },
    {
      headerName: "主座の%X（%）",
      field: "dPerxseatm",
      rowDrag: false,
      flex: 1.5,
      minWidth: 150
    },
    {
      headerName: "T座の%X（%）",
      field: "dPerxseatt",
      rowDrag: false,
      flex: 1.5,
      minWidth: 150
    },
    {
      headerName: "",
      field: "option",
      reseizable: false,
      suppressMovable: true,
      minWidth: 110,
      maxWidth: 110,
      cellRenderer: cellMessageRenderer,
    }
  ];

  const rowHeight = 48;
  gridRef.current?.api?.setQuickFilter(keyword);
  const handleEdit = (props: any) => {
    onShowDetails(props.data.lTrans1Id);
}

  return (
    <>
      <div>
        <div className="ag-theme-alpine" style={{ height: heightForm ? 'calc(100vh - ' + heightForm + 'px)' : 'calc(100vh - 285px)', width: "100%" }}>
          <AgGridReact
            ref={gridRef}
            defaultColDef={defaultColDef}
            columnTypes={columnTypes}
            columnDefs={columnDefs}
            onGridReady={onGridReady}
            domLayout={"normal"}
            rowData={rowData}
            animateRows={true}
            rowDragManaged={true}
            rowHeight={rowHeight}
            rowSelection={'single'}
            suppressMovableColumns={false}
            suppressDragLeaveHidesColumns={false}
            localeText={AG_GRID_LOCALE_JP}
            paginationPageSize={pageSize}
            onCellDoubleClicked={handleEdit}
            loadingOverlayComponent={loadingOverlayComponent}
            loadingOverlayComponentParams={loadingOverlayComponentParams}
            noRowsOverlayComponent={noRowsOverlayComponent}
            noRowsOverlayComponentParams={noRowsOverlayComponentParams}
          />
        </div>
        {pageSize && pageData && <PaginationCpn pageData={pageData} onChangeSearchCondition={onChangeSearchCondition} limit={pageSize} />}
      </div>
    </>
  );
})

export default EquipTransscottGrid