import { IeleBusbarSPhase, OptionModel } from "../models/Index";
import { ControlModel, MsBusbarModel, ProcessMode } from "../models/Index";
import { BUSBAR_CU, 
        BUSBAR_AL, 
        RS_3PHASE,
        RS_PHASE_N, 
        RS_PHASE_PE, 
        MS_TRANS1, 
        MS_TRANSSCOTT, 
        MS_OUTSIDE_OUTSIDE, 
        MS_OUTSIDE_NEUTRAL,
        MS_SEAT_MAIN,
        MS_SEAT_T,
        MS_VOLTDROP_MOTOR,
        MS_VOLTDROP_REGULAR,
        DIRECT_NONE,
        TOP_CENTER,
        LEFT_CENTER,
        BUSBAR_BARE,
        BUSBAR_VERTICAL,
        BUSBAR_TEMP_COEFFICIENT_CU,
        BUSBAR_TEMP_COEFFICIENT_AL,
        MS_PI,
        BUSBAR_PAINTED,
        BUSBAR_HORIZONTAL
} from "../models/Constants";
import { store } from "..";
import { BeamsNumber } from "./FormatNumber";
import { doGetValuePerZk3 } from "./PercentZ";
import { MS_3PHASES, MS_MODE_CALCULATION, MS_MODE_CHECKING, MS_MODE_DRAWING, MS_MODE_HARMONIC, MS_MODE_PERCENT_Z, MS_MODE_POWER_FLOW, MS_MODE_VOLTDROP, RS_3PHASES } from "../statics";
import { doGetIkasymDirectMG, 
        doGetIkpeakDirectMG,
        doGetIksymDirectMG,
        doGetIksymTotalMG,
        isSetDropPerMotor,
        doGetTextDropVoltMotor as doGetTextDropVoltMotorCalcPoint,
        doGetTextDropVoltMotor2 as doGetTextDropVoltMotor2CalcPoint,
        doGetTextDropPerMotor as doGetTextDropPerMotorCalcPoint,
        doGetValueDropVoltRegular as doGetValueDropVoltRegularCalcPoint,
        doGetTextDropVoltRegular as doGetTextDropVoltRegularCalcPoint,
        doGetTextDropPerRegular as doGetTextDropPerRegularCalcPoint,
        doGetPerZDirectMG,
        doGetIkpeakTotalMG,
        doGetIkasymTotalMG,
        doGetPerZTotalMG
} from "./calcPointFunction";
import { doGetTextVoltDropRegular } from "./mssv3Doc";
import { doGetLoadCurrentEndSide, doGetLoadCurrentStartSide, isCennectLoadDropElement, isCennectSourceDropElement } from "./element";

export const  doSetDefaultBusbar = (sphase:IeleBusbarSPhase,setDefaultBusbar:any,frequency:number,busBarList:any,wireSystem:number) =>{
    sphase.distance = 200
    const basic = {
        wireSystem: wireSystem,
        materialCuAl: BUSBAR_CU,
        bareOrPainted: BUSBAR_BARE,
        verticalOrHorizonal: BUSBAR_VERTICAL,
        busbarTemp: 65,
        ambient: 35,
    }
    if(setDefaultBusbar === null || setDefaultBusbar.length < 1){
        sphase.width = 0
        sphase.thickness = 0
        sphase.numberPhase = 0
        // sphase.basicCapacityA = 0;
        // sphase.tempK2A = 0;
        // sphase.horizontalK3A = 0;
        // sphase.proximityK4A = 0;
    }
    else{
        for(const item of setDefaultBusbar){
            if(item.nwidth !== null &&item.nthickness !== null &&item.nnumber !== null ){
                sphase.width = item.nwidth
                sphase.thickness = item.nthickness
                sphase.numberPhase = item.nnumber
            }
            else{
                sphase.width = 0
                sphase.thickness = 0
                sphase.numberPhase = 0
            }
        }
    }
    const fillterValue = doSetFillterValues(sphase,busBarList)
    DoCalcSetZValues(frequency, basic,sphase,fillterValue);
    DoCalcSetIValues(basic, sphase,fillterValue);
    sphase.capacity = doGetValuesCapacity(sphase)
}
const doGetValuesCapacity = (sphase:IeleBusbarSPhase) =>{
    let capacity = sphase.basicCapacityM
    if(sphase.tempK2M > 0){
        capacity *= sphase.tempK2M
    }
    if(sphase.horizontalK3M > 0){
        capacity *= sphase.horizontalK3M
    }
    if(sphase.proximityK4M > 0){
        capacity *= sphase.proximityK4M
    }
    return capacity
}
export const doSetFillterValues = (sphase:IeleBusbarSPhase,busBarList:any) =>{
    let filterValue:any = [];
    if(sphase.width > 0 && sphase.thickness > 0 && sphase.numberPhase > 0){
        for(const item of busBarList){
            if(item.nwidth == sphase.width && item.nthickness == sphase.thickness && item.nnumber == sphase.numberPhase){
                filterValue.push(item)
            }
        }
    }
    else if(sphase.width > 0 && sphase.thickness > 0){
        for(const item of busBarList){
            if(item.nwidth == sphase.width && item.nthickness == sphase.thickness){
                filterValue.push(item)
            }
        }
    }
    else if(sphase.width > 0 && sphase.numberPhase > 0){
        for(const item of busBarList){
            if(item.nwidth == sphase.width && item.nnumber == sphase.numberPhase){
                filterValue.push(item)
            }
        }
    }
    else if(sphase.thickness > 0 && sphase.numberPhase > 0){
        for(const item of busBarList){
            if(item.nthickness == sphase.thickness && item.nnumber == sphase.numberPhase){
                filterValue.push(item)
            }
        }
    }
    else if(sphase.width > 0){
        for(const item of busBarList){
            if(item.nwidth == sphase.width){
                filterValue.push(item)
            }
        }
    }
    else if(sphase.thickness > 0){
        for(const item of busBarList){
            if(item.nthickness == sphase.thickness){
                filterValue.push(item)
            }
        }
    }
    else if(sphase.numberPhase > 0){
        for(const item of busBarList){
            if(item.nnumber == sphase.numberPhase){
                filterValue.push(item)
            }
        }
    }
    else{
        filterValue = busBarList
    }
    return filterValue
}
export const DoCalcSetIValues = (basic:any, sphase:IeleBusbarSPhase,setDefaultBusbar:any) =>
{
	let ddm = -1;
	let dCuIzPainted = -1;
	let dCuIzBare = -1;
	let dAlIzPainted = -1;
	let dAlIzBare = -1;
	let dk3Painted = -1;
	let dk3Bare = -1;
	let dProximityk4 = -1;

	for(const item of setDefaultBusbar){
        // if(item.nwidth === sphase.width && item.nthickness === sphase.thickness && item.nnumber === sphase.numberPhase){
            ddm = item.ddm || 0
            dCuIzPainted = item.dskinEffect || 0
            dCuIzBare = item.dcuIzBare || 0
            dAlIzPainted = item.dalIzPainted || 0
            dAlIzBare = item.dalIzBare || 0
            dk3Painted = item.dk3painted || 0
            dk3Bare = item.dk3bare || 0
            dProximityk4 = item.dproximityk4 || 0

        // }
    }

	switch (+basic.materialCuAl) {
	case BUSBAR_CU:
		switch (+basic.bareOrPainted) {
		case BUSBAR_PAINTED:
			sphase.basicCapacityA = dCuIzPainted;
			sphase.tempK2A = DoGetValueTempk2(basic.busbarTemp, basic.ambient);
			if (+basic.verticalOrHorizonal == BUSBAR_HORIZONTAL) {
				sphase.horizontalK3A = dk3Painted;
			}
			else {
				sphase.horizontalK3A = 1;
			}
			sphase.proximityK4A = dProximityk4;
			break;
		case BUSBAR_BARE:
			sphase.basicCapacityA = dCuIzBare;
			sphase.tempK2A = DoGetValueTempk2(basic.busbarTemp, basic.ambient);
			if (+basic.verticalOrHorizonal == BUSBAR_HORIZONTAL) {
				sphase.horizontalK3A = dk3Painted;
			}
			else {
				sphase.horizontalK3A = 1;
			}
			sphase.proximityK4A = dProximityk4;
			break;
		}
		break;
	case BUSBAR_AL:
		switch (+basic.bareOrPainted) {
		case BUSBAR_PAINTED:
			sphase.basicCapacityA = dAlIzPainted;
			sphase.tempK2A = DoGetValueTempk2(basic.busbarTemp, basic.ambient);
			if (basic.verticalOrHorizonal == BUSBAR_HORIZONTAL) {
				sphase.horizontalK3A = dk3Painted;
			}
			else {
				sphase.horizontalK3A = 1;
			}
			sphase.proximityK4A = dProximityk4;
			break;
		case BUSBAR_BARE:
			sphase.basicCapacityA = dAlIzBare;
			sphase.tempK2A = DoGetValueTempk2(basic.busbarTemp, basic.ambient);
			if (+basic.verticalOrHorizonal == BUSBAR_HORIZONTAL) {
				sphase.horizontalK3A = dk3Painted;
			}
			else {
				sphase.horizontalK3A = 1;
			}
			sphase.proximityK4A = dProximityk4;
			break;
		}
		break;
	}
	sphase.basicCapacityM = sphase.basicCapacityA;
	sphase.tempK2M = sphase.tempK2A;
	sphase.horizontalK3M = sphase.horizontalK3A;
	sphase.proximityK4M = sphase.proximityK4A;
}
const DoGetValueTempk2 = (dBusBarTemp:number, dAmbientTemp:number) =>
{
	const dTempk2 = Math.pow((dBusBarTemp - dAmbientTemp) / 30 * (1 + 0.004 * (65 - 20)) / (1 + 0.004 * (dBusBarTemp - 20)), 1 / 1.7);

	return dTempk2;
}
export const DoCalcSetZValues = (frequency:number, basic:any,sphase:IeleBusbarSPhase,setDefaultBusbar:any,dSkinValue:number = 0) =>
{
	let ddm = -1;
	let dSkinEffect = -1;
    for(const item of setDefaultBusbar){
        // if(item.nwidth === sphase.width && item.nthickness === sphase.thickness && item.nnumber === sphase.numberPhase){
            ddm = item.ddm || 0
            dSkinEffect = item.dskinEffect || 0
        // }
    }

	if (dSkinValue == 0) {
		sphase.skinEffectA = dSkinEffect;
		switch (+basic.materialCuAl) {		// １ｍあたりの抵抗値（ｍｏｈｍｓ／ｍ）
		case BUSBAR_CU:
			sphase.ohmRPerA = DoGetValueOhmRperCu(basic.ambient, sphase, sphase.skinEffectA);
			break;
		case BUSBAR_AL:
			sphase.ohmRPerA  = DoGetValueOhmRperAl(basic.ambient, sphase, sphase.skinEffectA);
			break;
		}									// １ｍあたりのリアクタンス値（ｍｏｈｍｓ／ｍ）
		sphase.ohmXPerA  = DoGetValueOhmXper(frequency, sphase,ddm);

		sphase.skinEffectM = sphase.skinEffectA;
		sphase.ohmRPerM = sphase.ohmRPerA;
		sphase.ohmXPerM = sphase.ohmXPerA ;
	}
	else {
		switch (+basic.materialCuAl) {		// １ｍあたりの抵抗値（ｍｏｈｍｓ／ｍ）
		case BUSBAR_CU:
			sphase.ohmRPerA = DoGetValueOhmRperCu(basic.ambient, sphase,dSkinValue);
			break;
		case BUSBAR_AL:
			sphase.ohmRPerA = DoGetValueOhmRperAl(basic.ambient, sphase,dSkinValue);
			break;
		}									// １ｍあたりのリアクタンス値（ｍｏｈｍｓ／ｍ）
		sphase.ohmXPerA = DoGetValueOhmXper(frequency, sphase,ddm);

		sphase.skinEffectM = dSkinValue;
		sphase.ohmRPerM = sphase.ohmRPerA;
		sphase.ohmXPerM = sphase.ohmXPerA;
	}
}
const DoGetValueOhmXper = (frequency:number, sphase:IeleBusbarSPhase,ddm:number) =>
{
	if (ddm <= 0 || sphase.width + ddm <= 0 || sphase.distance <= 0 ) {
		return -1;
	}
	const dOhmXper = 2 * MS_PI * frequency * (0.04615 + 0.4605 * Math.log10(sphase.distance / (sphase.width + ddm) / 0.2235)) / 1000;

	return dOhmXper;
}
const DoGetValueOhmRperAl = (dAmbientTemp:number,sphase:IeleBusbarSPhase, dSkinEffect:number) =>
{
	if (sphase.width <= 0 || sphase.thickness <= 0 || sphase.numberPhase <= 0) {
		return -1;
	}
	const dOhmRper = (dSkinEffect * 1000 / 35.1 / (sphase.width * sphase.thickness * sphase.numberPhase)) *
														(1 + BUSBAR_TEMP_COEFFICIENT_AL * (dAmbientTemp - 20));

	return dOhmRper;
}
const DoGetValueOhmRperCu = ( dAmbientTemp:number,sphase:IeleBusbarSPhase, dSkinEffect:number) =>
{
	if (sphase.width <= 0 || sphase.thickness <= 0 || sphase.numberPhase <= 0) {
		return -1;
	}
	const dOhmRper = (dSkinEffect * 1000 / 56 / (sphase.width * sphase.thickness * sphase.numberPhase)) *
														(1 + BUSBAR_TEMP_COEFFICIENT_CU * (dAmbientTemp - 20));

	return dOhmRper;
}

//CEleBusBar::DoGetTextLength
export const doGetTextLength = (props: MsBusbarModel, bUnit: boolean = true) => {
    let sText = props.lenght.toString();
    if (bUnit) {
        sText += 'm';
    }
    return sText;
}

//CEleBusBar::DoGetTextMaterial
export const doGetTextMaterial = (props: MsBusbarModel) => {
    let sText = '';
    switch(props.materialCuAl) {
        case BUSBAR_CU:
            sText = '銅';
            break;
        case BUSBAR_AL:
            sText = 'ｱﾙﾐﾆｳﾑ';
            break;
        }
    return sText;
}

//CEleBusBarPhase::DoGetValueCapacity
export const doGetValueCapacity = (dBasicCapacity:number, dTempk2:number, dHorizontalk3:number, dProximityk4:number) => {
    let dCapacity = dBasicCapacity;
    if(dTempk2 > 0) {
        dCapacity *= dTempk2;
    }
    if(dHorizontalk3 > 0) {
        dCapacity *= dHorizontalk3;
    }
    if(dProximityk4 > 0) {
        dCapacity *= dProximityk4;
    }
    return dCapacity;
}

//CEleBusBar::DoGetTextCapacity
export const doGetTextCapacity = (props: MsBusbarModel, nWireSystem: number, bUnit: boolean = true) => {
    let dCapacity = 0;
    switch(nWireSystem) {
        case RS_3PHASE:
            dCapacity = doGetValueCapacity(props.m_3Phase.basicCapacityM, props.m_3Phase.tempK2M, props.m_3Phase.horizontalK3M, props.m_3Phase.proximityK4M);
            break;
        case RS_PHASE_N:
            dCapacity = doGetValueCapacity(props.phaseN.basicCapacityM, props.phaseN.tempK2M, props.phaseN.horizontalK3M, props.phaseN.proximityK4M);
            break;
        case RS_PHASE_PE:
            dCapacity = doGetValueCapacity(props.phaseNPE.basicCapacityM, props.phaseNPE.tempK2M, props.phaseNPE.horizontalK3M, props.phaseNPE.proximityK4M);
            break;
    }
    let sText = BeamsNumber(dCapacity, 4, true).toString();
    if(bUnit && dCapacity >= 0) {
        sText += 'A';
    }
    return sText;
}

//CEleBusBar::DoGetTextSize
export const doGetTextSize = (props: MsBusbarModel, nWireSystem: number) => {
   let sText = '';
   switch(nWireSystem) {
    case RS_3PHASE:
        sText = props.m_3Phase.width.toString() + '×' + props.m_3Phase.thickness + '×' + props.m_3Phase.numberPhase;
       break;
    case RS_PHASE_N:
        sText = props.phaseN.width.toString() + '×' + props.phaseN.thickness + '×' + props.phaseN.numberPhase;
        break;
    case RS_PHASE_PE:
        sText = props.phaseNPE.width.toString() + '×' + props.phaseNPE.thickness + '×' + props.phaseNPE.numberPhase;
        break;
    }
    return sText;
}

//CEleBusBar::DoGetValueOhmZ
export const doGetValueOhmZ = (dOhmR:number, dOhmX:number) => {
    let dOhmZ = Math.sqrt(dOhmR * dOhmR + dOhmX * dOhmX);
    return dOhmZ;
}

//CEleBusBar::DoGetTextOhmZ
export const doGetTextOhmZ = (props: MsBusbarModel, nWireSystem: number, bUnit: boolean = true) => {
    let dOhmZ = 0;
    switch(nWireSystem) {
        case RS_3PHASE:
            dOhmZ = doGetValueOhmZ(props.m_3Phase.ohmRPerM * props.lenght, props.m_3Phase.ohmXPerM * props.lenght);
           break;
        case RS_PHASE_N:
            dOhmZ = doGetValueOhmZ(props.phaseN.ohmRPerM * props.lenght, props.phaseN.ohmXPerM * props.lenght);
            break;
        case RS_PHASE_PE:
            dOhmZ = doGetValueOhmZ(props.phaseNPE.ohmRPerM * props.lenght, props.phaseNPE.ohmXPerM * props.lenght);
            break;
    }
    let sText = BeamsNumber(dOhmZ, 4, true).toString();
    if(bUnit && dOhmZ >= 0)
    {
        sText += 'mΩ';
    }
    return sText;
 }

 //CEleBusBar::DoGetTextOhmR
export const doGetTextOhmR = (props: MsBusbarModel, nWireSystem: number,  bUnit: boolean = true) => {
    let dOhmR = 0;
    switch(nWireSystem) {
        case RS_3PHASE:
            dOhmR = props.m_3Phase.ohmRPerM;
            break;
        case RS_PHASE_N:
            dOhmR = props.phaseN.ohmRPerM;
            break;
        case RS_PHASE_PE:
            dOhmR = props.phaseNPE.ohmRPerM;
            break;
    }
    dOhmR *= props.lenght;
    let sText = BeamsNumber(dOhmR, 4, true).toString();
    if(bUnit && dOhmR >= 0)
    {
        sText += 'mΩ';
    }
    return sText;
}

//CEleBusBar::DoGetTextOhmX
export const doGetTextOhmX = (props: MsBusbarModel, nWireSystem: number,  bUnit: boolean = true) => {
    let dOhmX = 0;
    switch(nWireSystem) {
        case RS_3PHASE:
            dOhmX = props.m_3Phase.ohmXPerM;
           break;
        case RS_PHASE_N:
            dOhmX = props.phaseN.ohmXPerM;
            break;
        case RS_PHASE_PE:
            dOhmX = props.phaseNPE.ohmXPerM;
            break; 
    }
    dOhmX *= props.lenght;
    let sText = BeamsNumber(dOhmX, 4, true).toString();
    if(bUnit && dOhmX >= 0)
    {
        sText += 'mΩ';
    }
    return sText;
}

//CEleBusBar::DoGetTextXR
export const doGetTextXR = (props: MsBusbarModel, nWireSystem: number) => {
    let dXR = 0;
    switch(nWireSystem) {
        case RS_3PHASE:
            dXR = props.m_3Phase.ohmXPerM / props.m_3Phase.ohmRPerM;
            break;
        case RS_PHASE_N:
            dXR = props.phaseN.ohmXPerM / props.phaseN.ohmRPerM;
            break;
        case RS_PHASE_PE:
            dXR = props.phaseNPE.ohmXPerM / props.phaseNPE.ohmRPerM;
            break;
    }
    let sText = BeamsNumber(dXR, 4).toString();
    return sText;
}

//CEleBusBar::DoGetPerZrBusBar
export const doGetPerZrBusBar = (props: MsBusbarModel, dStdCapacity: number, nWireSystem: number, nPartKind:number, bTwoWays:boolean = true) => {
    let percentZ = {
        perRk3: 0,
        perXk3: 0,
        perRk3Difference: 0,
        perXk3Difference: 0
    };

    if(props.voltage <= 0) {
        percentZ.perRk3 = 0;
        percentZ.perXk3 = 0;
    }
    else {
        switch(nWireSystem) {
            case RS_3PHASE:
                if(props.sPhase) {
                    switch(nPartKind) {
                        case MS_OUTSIDE_NEUTRAL:
                            percentZ.perRk3 = dStdCapacity * props.m_3Phase.ohmRPerM * props.lenght / (props.voltage / 2) / (props.voltage / 2) * 100;
                            percentZ.perXk3 = dStdCapacity * props.m_3Phase.ohmXPerM * props.lenght / (props.voltage / 2) / (props.voltage / 2) * 100;
                            if(bTwoWays)
                            {
                                percentZ.perRk3 *= 2;
                                percentZ.perXk3 *= 2;
                            }
                            break;
                        case MS_OUTSIDE_OUTSIDE:
                            percentZ.perRk3 = dStdCapacity * props.m_3Phase.ohmRPerM * props.lenght / props.voltage / props.voltage * 100;
                            percentZ.perXk3 = dStdCapacity * props.m_3Phase.ohmXPerM * props.lenght / props.voltage / props.voltage * 100;
                            if(bTwoWays)
                            {
                                percentZ.perRk3 *= 2;
                                percentZ.perXk3 *= 2;
                            }
                            percentZ.perRk3Difference = dStdCapacity * props.m_3Phase.ohmRPerM * props.lenght / (props.voltage / 2) / (props.voltage / 2) * 100;
                            percentZ.perXk3Difference = dStdCapacity * props.m_3Phase.ohmXPerM * props.lenght / (props.voltage / 2) / (props.voltage / 2) * 100;
                            if(bTwoWays)
                            {
                                percentZ.perRk3Difference *= 2;
                                percentZ.perXk3Difference *= 2;
                            }
                            percentZ.perRk3Difference = percentZ.perRk3 - percentZ.perRk3Difference;
                            percentZ.perXk3Difference = percentZ.perXk3 - percentZ.perXk3Difference;
                            break;
                        case MS_SEAT_MAIN:
                        case MS_SEAT_T:
                            percentZ.perRk3 = dStdCapacity * props.m_3Phase.ohmRPerM * props.lenght / props.voltage / props.voltage * 100; 
                            percentZ.perXk3 = dStdCapacity * props.m_3Phase.ohmXPerM * props.lenght / props.voltage / props.voltage * 100; 
                            if(bTwoWays)
                            {
                                percentZ.perRk3 *= 2;
                                percentZ.perXk3 *= 2;
                            }
                            break;
                        }
                }
                else {
                    percentZ.perRk3 = dStdCapacity * props.m_3Phase.ohmRPerM * props.lenght / props.voltage / props.voltage * 100;
                    percentZ.perXk3 = dStdCapacity * props.m_3Phase.ohmXPerM * props.lenght / props.voltage / props.voltage * 100;
                }
               break;
            case RS_PHASE_N:
                percentZ.perRk3 = dStdCapacity * props.phaseN.ohmRPerM * props.lenght / props.voltage / props.voltage * 100;
                percentZ.perXk3 = dStdCapacity * props.phaseN.ohmXPerM * props.lenght / props.voltage / props.voltage * 100;
                break;
            case RS_PHASE_PE:
                percentZ.perRk3 = dStdCapacity * props.phaseNPE.ohmRPerM * props.lenght / props.voltage / props.voltage * 100;
                percentZ.perXk3 = dStdCapacity * props.phaseNPE.ohmXPerM * props.lenght / props.voltage / props.voltage * 100;
                break;
        }
    }
    return percentZ;
}

//CEleBusBar::DoGetTextPercentZr
export const doGetTextPercentZr = (props: MsBusbarModel, nWireSystem: number, nPartKind:number, dStdCapacity:number, bUnit:boolean = true) => {
    let percentZ = doGetPerZrBusBar(props, dStdCapacity, nWireSystem, nPartKind);
    let dPercentZ = doGetValuePerZk3(percentZ).dPercentZ;
    let sText = BeamsNumber(dPercentZ, 4, true).toString();
    if(bUnit && dPercentZ >= 0)
    {
        sText += '%r';
    }
    return sText;
}

//CEleBusBar::DoGetTextPercentRr
export const doGetTextPercentRr = (props: MsBusbarModel, nWireSystem: number, nPartKind:number, dStdCapacity:number, bUnit:boolean = true) => {
    let percentZ = doGetPerZrBusBar(props, dStdCapacity, nWireSystem, nPartKind);
    let sText = BeamsNumber(percentZ.perRk3, 4, true).toString();
    if (bUnit && percentZ.perRk3 >= 0) {
        sText += '%r';
    }
    return sText;
}

//CEleBusBar::DoGetTextPercentXr
export const doGetTextPercentXr = (props: MsBusbarModel, nWireSystem: number, nPartKind:number, dStdCapacity:number, bUnit:boolean = true) => {
    let percentZ = doGetPerZrBusBar(props, dStdCapacity, nWireSystem, nPartKind);
    let sText = BeamsNumber(percentZ.perXk3, 4, true).toString();
    if (bUnit && percentZ.perXk3 >= 0) {
        sText += '%r';
    }
    return sText;
}

//CEleBusBar::IsPointFaultBusBar
export const isPointFaultBusBar = (props: MsBusbarModel) => {
    if (props.fault) {
        return true;
    }
    return false;
}

//CEleBusBar::DoGetTextDirectIkpeak
export const doGetTextDirectIkpeak = (props:MsBusbarModel, nPartKind:number, bUnit: boolean = true ) => {
    let dIpeakDirect = 0;
    if(props.sPhase)
    {
        switch(nPartKind) {
            case MS_OUTSIDE_NEUTRAL:
            case MS_SEAT_MAIN:
                dIpeakDirect = doGetIkpeakDirectMG(props.calcPoint1);
                break;
            case MS_OUTSIDE_OUTSIDE:
            case MS_SEAT_T:
                dIpeakDirect = doGetIkpeakDirectMG(props.calcPoint0);
                break;
        }
    }
    else
    {
        if(props.downTransKind == MS_TRANS1) {
            dIpeakDirect = doGetIkpeakDirectMG(props.calcPoint1);
        }
        else {
            dIpeakDirect = doGetIkpeakDirectMG(props.calcPoint0);
        }

    }
    let sText;
    if (dIpeakDirect == 0) {
       if(props.primarySupply) {
        sText = '∞';
       } 
       else {
        sText = '0';
       }
    }
    else if(dIpeakDirect > 0) {
        sText = BeamsNumber(dIpeakDirect / 1000, 4).toString();
        if(bUnit)
        {
            sText += 'kApeak';
        }
    }
    else {
        sText = '????';
    }
    return sText;
}

//CEleBusBar::DoGetTextDirectIkasym
export const doGetTextDirectIkasym = (props:MsBusbarModel, nPartKind:number, bUnit: boolean = true ) => {
    let dIasymDirect = 0;
    if(props.sPhase) {
        switch(nPartKind) {
            case MS_OUTSIDE_NEUTRAL:
            case MS_SEAT_MAIN:
                dIasymDirect = doGetIkasymDirectMG(props.calcPoint1);
                break;
            case MS_OUTSIDE_OUTSIDE:
            case MS_SEAT_T:
                dIasymDirect = doGetIkasymDirectMG(props.calcPoint0);
                break;
        }
    }
    else {
        if(props.downTransKind == MS_TRANS1)
        {
            dIasymDirect = doGetIkasymDirectMG(props.calcPoint1);
        }
        else
        {
            dIasymDirect = doGetIkasymDirectMG(props.calcPoint0);
        }
    }
    let sText;
    if (dIasymDirect == 0) {
       if(props.primarySupply) {
        sText = '∞';
       }
       else {
        sText = '0';
       }
    }
    else if(dIasymDirect > 0) {
        sText = BeamsNumber(dIasymDirect / 1000, 4).toString();
        if(bUnit) {
            sText += 'kAasym';
        }
    }
    else {
        sText = '????';
    }
    return sText;
}

//CEleBusBar::DoGetTextDirectIksym
export const doGetTextDirectIksym = (props:MsBusbarModel, nPartKind:number, bUnit: boolean = true ) => {
    let dIsymDirect = 0;
    if(props.sPhase) {
        switch(nPartKind) {
            case MS_OUTSIDE_NEUTRAL:
            case MS_SEAT_MAIN:
                dIsymDirect = doGetIksymDirectMG(props.calcPoint1);
                break;
            case MS_OUTSIDE_OUTSIDE:
            case MS_SEAT_T:
                dIsymDirect = doGetIksymDirectMG(props.calcPoint0);
                break;
        }
    }
    else {
        if(props.downTransKind === MS_TRANS1) {
            dIsymDirect = doGetIksymDirectMG(props.calcPoint1);
        }
        else {
            dIsymDirect = doGetIksymDirectMG(props.calcPoint0);
        }
    }
    let sText;
    if (dIsymDirect == 0) {
        if(props.primarySupply) {
            sText = '∞';
        }
        else {
         sText = '0';
        }
    }
    else if(dIsymDirect > 0) {
        sText = BeamsNumber(dIsymDirect / 1000, 4).toString();
        if(bUnit) {
            sText += 'kAsym';
        }
    }
    else {
        sText = '????';
    }
    return sText;
}

//CEleBusBar::DoGetTextTotalIk3sym
export const doGetTextTotalIk3sym = (props:MsBusbarModel, bUnit: boolean = true ) => {
    let dIsymTotal = 0;
    if(props.downTransKind == MS_TRANS1) {
        dIsymTotal = doGetIksymTotalMG(props.calcPoint1);
    }
    else {
        dIsymTotal = doGetIksymTotalMG(props.calcPoint0);
    }
    let sText;
    if (dIsymTotal == 0) {
        if(props.primarySupply) {
            sText = '∞';
        }
        else {
         sText = '0';
        }
    }
    else if(dIsymTotal > 0) {
        sText = BeamsNumber(dIsymTotal / 1000, 4).toString();
        if(bUnit) {
            sText += 'kAsym';
        }
    }
    else {
        sText = '????';
    }
    return sText;
}

//CEleBusBar::DoGetTextDropVoltMotor
export const doGetTextDropVoltMotor = (props:MsBusbarModel, bUnit: boolean = true) => {
    return doGetTextDropVoltMotorCalcPoint(props.calcPoint0, bUnit)
}

//CEleBusBar::DoGetTextDropVoltMotor2
export const doGetTextDropVoltMotor2 = (props:MsBusbarModel, bUnit: boolean = true) => {
    return doGetTextDropVoltMotor2CalcPoint(props.calcPoint0, bUnit)
}

//CEleBusBar::DoGetTextDropPerMotor
export const doGetTextDropPerMotor = (props:MsBusbarModel, bUnit: boolean = true) => {
    return doGetTextDropPerMotorCalcPoint(props.calcPoint0, bUnit)
}

//CEleBusBar::DoGetTextVoltage
export const doGetTextVoltage = (props:MsBusbarModel, nPartKind:number, bUnit:boolean = true) => {
	let sText = '';
	if (props.sPhase) {
		switch (nPartKind) {
		case MS_OUTSIDE_NEUTRAL:
			sText = (props.voltage / 2).toString();
			break;
		case MS_OUTSIDE_OUTSIDE:
			sText = props.voltage.toString();
			break;
		case MS_SEAT_MAIN:
			sText = props.voltage.toString();
			break;
		case MS_SEAT_T:
			sText = props.voltage.toString();
			break;
		}
	}
	else {
		sText = props.voltage.toString();
	}
	if (bUnit) {
		sText += 'V';
	}
	return sText;
}

//CEleBusBar::DoGetTextLoadCurrent
export const doGetTextLoadCurrent = (props:MsBusbarModel, nPartKind:number, bUnit: boolean = true) => {
    let sText = '';
    if(props.sPhase) {
        switch(nPartKind) {
            case MS_OUTSIDE_NEUTRAL:
            case MS_SEAT_MAIN:
                sText = BeamsNumber(props.calcPoint1.loadCurrent, 5).toString();
                break;
            case MS_OUTSIDE_OUTSIDE:
            case MS_SEAT_T:
                sText = BeamsNumber(props.calcPoint0.loadCurrent, 5).toString();
                break;
        }
    }
    else {
        if(props.downTransKind == MS_TRANS1) {
            sText = BeamsNumber(props.calcPoint1.loadCurrent, 5).toString();
        }
        else {
            sText = BeamsNumber(props.calcPoint0.loadCapacity, 5).toString();
        }
    }
    if(bUnit) {
        sText += 'A';
    }
    return sText;
}

//CEleBusBar::doGetTextDropVoltPF
export const doGetTextDropVoltPF = (props:MsBusbarModel, nPartKind:number, bUnit: boolean = true) => {
    let sText = '';
    if(props.sPhase) {
        switch(nPartKind) {
            case MS_OUTSIDE_NEUTRAL:
            case MS_SEAT_MAIN:
                sText = BeamsNumber(props.calcPoint1.powerFactor, 5).toString();
                if(bUnit) {
                    if(props.calcPoint1.isLead) {
                        sText+= '(進み)';
                    }
                    else {
                        sText += '(遅れ)';
                    }
                }
                break;
            case MS_OUTSIDE_OUTSIDE:
            case MS_SEAT_T:
                sText = BeamsNumber(props.calcPoint0.powerFactor, 5).toString();
                if(bUnit) {
                    if(props.calcPoint0.isLead) {
                        sText+= '(進み)';
                    }
                    else {
                        sText += '(遅れ)';
                    }
                }
                break;
        }
    }
    else {
        if(props.downTransKind == MS_TRANS1) {
            sText = BeamsNumber(props.calcPoint1.powerFactor, 5).toString();
            if(bUnit) {
                if(props.calcPoint1.isLead) {
                    sText+= '(進み)';
                }
                else {
                    sText += '(遅れ)';
                }
            }
        }
        else {
            sText = BeamsNumber(props.calcPoint0.powerFactor, 5).toString();
            if(bUnit) {
                if(props.calcPoint0.isLead) {
                    sText+= '(進み)';
                }
                else {
                    sText += '(遅れ)';
                }
            }
        }
    }
    return sText;
}

//CEleBusBar::DoGetTextDropVoltDiff
export const doGetTextDropVoltDiff = (props:MsBusbarModel, dStdCapacity: number, dStdVoltage: number, nPartKind:number, bUnit: boolean = true) => {
    let dVolt = 0;
    let percentZ = doGetPerZrBusBar(props, dStdCapacity, RS_3PHASE, nPartKind, false);

    if(props.sPhase) {
        switch(nPartKind) {
            case MS_OUTSIDE_NEUTRAL:
            case MS_SEAT_MAIN:
                dVolt = doGetValueDropVoltRegularCalcPoint(dStdCapacity, percentZ.perRk3, percentZ.perXk3, props.calcPoint1);
                break;
            case MS_OUTSIDE_OUTSIDE:
            case MS_SEAT_T:
                dVolt = doGetValueDropVoltRegularCalcPoint(dStdCapacity, percentZ.perRk3, percentZ.perXk3, props.calcPoint0);
                break;
        }
        dVolt = dStdVoltage - dVolt;
    }
    else {
        if(props.downTransKind == MS_TRANS1) {
            dVolt = doGetValueDropVoltRegularCalcPoint(dStdVoltage, percentZ.perRk3, percentZ.perXk3, props.calcPoint1);
        }
        else {
            dVolt = doGetValueDropVoltRegularCalcPoint(dStdVoltage, percentZ.perRk3, percentZ.perXk3, props.calcPoint0);
        }
        dVolt = dStdVoltage - dVolt;
    }

    let sText = BeamsNumber(dVolt, 4).toString();
    if(bUnit) {
        sText += 'V';
    }
    return sText;
}

//CEleBusBar::DoGetValueDropVoltRegular
export const doGetValueDropVoltRegular = (props:MsBusbarModel, dStdCapacity:number, dStdVoltage:number, nPartKind:number) =>
{
	let percentZ = doGetPerZrBusBar(props, dStdCapacity, RS_3PHASE, nPartKind, false);
	let dVolt = 0;
	if (props.sPhase) {		
		switch (nPartKind) {
		case MS_OUTSIDE_NEUTRAL:	
		case MS_SEAT_MAIN:			
			dVolt = doGetValueDropVoltRegularCalcPoint(dStdVoltage, percentZ.perRk3, percentZ.perXk3, props.calcPoint1);
			break;
		case MS_OUTSIDE_OUTSIDE:	
		case MS_SEAT_T:				
			dVolt = doGetValueDropVoltRegularCalcPoint(dStdVoltage, percentZ.perRk3, percentZ.perXk3, props.calcPoint0);
			break;
		}
	}
	else {
		if (props.downTransKind == MS_TRANS1) {
			dVolt = doGetValueDropVoltRegularCalcPoint(dStdVoltage, percentZ.perRk3, percentZ.perXk3, props.calcPoint1);
		}
		else {
			dVolt = doGetValueDropVoltRegularCalcPoint(dStdVoltage, percentZ.perRk3, percentZ.perXk3, props.calcPoint0);
		}
	}
	return dVolt;
}

//CEleBusBar::DoGetTextDropVoltRegular
export const doGetTextDropVoltRegular = (props:MsBusbarModel, dStdCapacity: number, dStdVoltage:number, nPartKind:number, bUnit: boolean = true) => {
    let percentZ = doGetPerZrBusBar(props, dStdCapacity, RS_3PHASE, nPartKind, false);
    let sText = '';
    if(props.sPhase) {
        switch(nPartKind) {
            case MS_OUTSIDE_NEUTRAL:
            case MS_SEAT_MAIN:
                sText = doGetTextDropVoltRegularCalcPoint(props.calcPoint1, dStdVoltage, percentZ.perRk3, percentZ.perXk3, bUnit);
                break;
            case MS_OUTSIDE_OUTSIDE:
            case MS_SEAT_T:
                sText = doGetTextDropVoltRegularCalcPoint(props.calcPoint0, dStdVoltage, percentZ.perRk3, percentZ.perXk3, bUnit);
                break;
        }
    } 
    else {
        if(props.downTransKind == MS_TRANS1) {
            sText = doGetTextDropVoltRegularCalcPoint(props.calcPoint1, dStdVoltage, percentZ.perRk3, percentZ.perXk3, bUnit);
        }
        else {
            sText = doGetTextDropVoltRegularCalcPoint(props.calcPoint0, dStdVoltage, percentZ.perRk3, percentZ.perXk3, bUnit);
        }
    }
    return sText;
}

//CEleBusBar::DoGetTextDropPerRegular
export const doGetTextDropPerRegular = (props:MsBusbarModel, dStdCapacity: number, dStdVoltage:number, nPartKind:number, bUnit: boolean = true) => {
    let sText = '';
    let percentZ = doGetPerZrBusBar(props, dStdCapacity, RS_3PHASE, nPartKind, false);
    if(props.sPhase) {
        switch(nPartKind) {
            case MS_OUTSIDE_NEUTRAL:
            case MS_SEAT_MAIN:
                sText = doGetTextDropPerRegularCalcPoint(props.calcPoint1, dStdVoltage, percentZ.perRk3, percentZ.perXk3, bUnit);
                break;
            case MS_OUTSIDE_OUTSIDE:
            case MS_SEAT_T:
                sText = doGetTextDropPerRegularCalcPoint(props.calcPoint0, dStdVoltage, percentZ.perRk3, percentZ.perXk3, bUnit);
                break;
        }
    }
    else {
        if(props.downTransKind == MS_TRANS1) {
            sText = doGetTextDropPerRegularCalcPoint(props.calcPoint1, dStdVoltage, percentZ.perRk3, percentZ.perXk3, bUnit);
        }
        else {
            sText = doGetTextDropPerRegularCalcPoint(props.calcPoint0, dStdVoltage, percentZ.perRk3, percentZ.perXk3, bUnit);
        }
    }
    return sText;
}

//CEleBusBar::DoGetTextPointState
export const doGetTextPointState = (props:MsBusbarModel) =>
{
	let sText = ''; 

	if (props.fault) {
		sText = 'Yes';
	}
	else {
		sText = 'No';
	}

	return sText;
}

//CEleBusBar::DoGetRefV1
export const doGetRefV1 = (mode: any, control:ControlModel, bSociety:boolean) => {
    let props = control.properties as MsBusbarModel;
    let infoCircuit = store.getState().app.diagram.infoCircuit;
    let project = store.getState().app.projectData;

    //CEleBusBar::DoGetRefV1Left  sRefV1Left
    let labelTexts = [{
        value: control.properties.refNo,
        color: "#f800f8",
    }];
  
    if (!control.properties.viewResultText || (mode != ProcessMode.DRAWING && !props.supply))
    {
      return labelTexts;
    }
    
    if (bSociety){
        return labelTexts;
    }

    switch(mode) {
        case ProcessMode.DRAWING:
        case ProcessMode.CHECKING:
            
            //Length / Material
            labelTexts.push({
                value: doGetTextLength(props)
                    + ','
                    + doGetTextMaterial(props)
                    ,
                color: "blue",
            });

            //Capacity
            labelTexts.push({
                value: doGetTextCapacity(props, RS_3PHASE),
                color: "blue",
            });

            //Size 
            labelTexts.push({
                value: doGetTextSize(props, RS_3PHASE),
                color: "blue",
            });
            if(props.voltage > 0)
            {
                //OhmZ / OhmR / OhmX
                labelTexts.push({
                    value: doGetTextOhmZ(props, RS_3PHASE)
                    + '='
                    + doGetTextOhmR(props, RS_3PHASE, false)
                    + '+j'
                    + doGetTextOhmX(props, RS_3PHASE, false),
                    color: "blue",
                });
            }       
            break;
        case ProcessMode.PERCENT_Z:

            if(props.sPhase)
            {
                switch(props.upTransKind) {
                    case MS_TRANS1:
                        //XR
                        labelTexts.push({
                            value: 'X/R=' 
                            + doGetTextXR(props,RS_3PHASE),
                            color: "blue",
                        });

                        //OUTSIDE_OUTSIDE
                        labelTexts.push({
                            value: '外線-外線',
                            color: "blue",
                        });
                        
                        //Zr = Rr + jXr
                        labelTexts.push({
                            value: doGetTextPercentZr(props, RS_3PHASE, MS_OUTSIDE_OUTSIDE, infoCircuit.stdCapacity)
                            + '='
                            + doGetTextPercentRr(props, RS_3PHASE, MS_OUTSIDE_OUTSIDE, infoCircuit.stdCapacity, false)
                            + '+j'
                            + doGetTextPercentXr(props, RS_3PHASE, MS_OUTSIDE_OUTSIDE, infoCircuit.stdCapacity, false)
                            ,
                            color: "blue",
                        });

                        //OUTSIDE_NEUTRAL
                        labelTexts.push({
                            value: '外線-中性線',
                            color: "blue",
                        });

                         //Zr = Rr + jXr
                         labelTexts.push({
                            value: doGetTextPercentZr(props, RS_3PHASE, MS_OUTSIDE_NEUTRAL, infoCircuit.stdCapacity)
                            + '='
                            + doGetTextPercentRr(props, RS_3PHASE, MS_OUTSIDE_NEUTRAL, infoCircuit.stdCapacity, false)
                            + '+j'
                            + doGetTextPercentXr(props, RS_3PHASE, MS_OUTSIDE_NEUTRAL, infoCircuit.stdCapacity, false)
                            ,
                            color: "blue",
                        });
                        break;
                    case MS_TRANSSCOTT:
                         //XR
                         labelTexts.push({
                            value: 'X/R='
                            + doGetTextXR(props, RS_3PHASE),
                            color: "blue",
                        });

                        //SEAT_MAIN
                        labelTexts.push({
                            value: '主座',
                            color: "blue",
                        });

                        //Zr = Rr + jXr
                        labelTexts.push({
                            value: doGetTextPercentZr(props, RS_3PHASE, MS_SEAT_MAIN, infoCircuit.stdCapacity)
                            + '='
                            + doGetTextPercentRr(props, RS_3PHASE, MS_SEAT_MAIN, infoCircuit.stdCapacity, false)
                            + '+j'
                            + doGetTextPercentXr(props, RS_3PHASE, MS_SEAT_MAIN, infoCircuit.stdCapacity, false)
                            ,
                            color: "blue",
                        });

                        //SEAT_T
                        labelTexts.push({
                            value: 'T座',
                            color: "blue",
                        });

                         //Zr = Rr + jXr
                         labelTexts.push({
                            value: doGetTextPercentZr(props, RS_3PHASE, MS_SEAT_T, infoCircuit.stdCapacity)
                            + '='
                            + doGetTextPercentRr(props, RS_3PHASE, MS_SEAT_T, infoCircuit.stdCapacity, false)
                            + '+j'
                            + doGetTextPercentXr(props, RS_3PHASE, MS_SEAT_T, infoCircuit.stdCapacity, false)
                            ,
                            color: "blue",
                        });
                        break;
                }
            }
            else {
                 //XR
                 labelTexts.push({
                    value: 'X/R='
                    + doGetTextXR(props, RS_3PHASE),
                    color: "blue",
                });

                //Zr = Rr + jXr
                labelTexts.push({
                    value: doGetTextPercentZr(props, RS_3PHASE, MS_3PHASES, infoCircuit.stdCapacity)
                    + '='
                    + doGetTextPercentRr(props, RS_3PHASE, MS_3PHASES, infoCircuit.stdCapacity, false)
                    + '+j'
                    + doGetTextPercentXr(props, RS_3PHASE, MS_3PHASES, infoCircuit.stdCapacity, false)
                    ,
                    color: "blue",
                });
            }
            break;
        case ProcessMode.CALCULATION:

            if(!isPointFaultBusBar(props)) {
                break;
            }

            if(props.sPhase) {
                switch(props.upTransKind) {
                    case MS_TRANS1:
                        //OUTSIDE_OUTSIDE
                        labelTexts.push({
                            value: '外線-外線',
                            color: "blue",
                        });

                        // += 'I"k2' + '通過' + '=' + DoGetTextDirectIkpeak()
                        labelTexts.push({
                            value: 'I"k2通過='
                            + doGetTextDirectIkpeak(props, MS_OUTSIDE_OUTSIDE),
                            color: "blue",
                        });

                        // += 'I"k2' + '通過' + '=' + DoGetTextDirectIkasym()
                        labelTexts.push({
                            value: 'I"k2通過='
                            + doGetTextDirectIkasym(props, MS_OUTSIDE_OUTSIDE),
                            color: "blue",
                        });

                         // += 'I"k2' + '通過' + '=' + DoGetTextDirectIksym()
                        labelTexts.push({
                            value: 'I"k2通過='
                            + doGetTextDirectIksym(props, MS_OUTSIDE_OUTSIDE),
                            color: "blue",
                        });
                        
                         //OUTSIDE_NEUTRAL
                         labelTexts.push({
                            value: '外線-中性線',
                            color: "blue",
                        });

                        // += 'I"k2' + '通過' + '=' + DoGetTextDirectIkpeak()
                        labelTexts.push({
                            value: 'I"k2通過='
                            + doGetTextDirectIkpeak(props, MS_OUTSIDE_NEUTRAL),
                            color: "blue",
                        });

                        // += 'I"k2' + '通過' + '=' + DoGetTextDirectIkasym()
                        labelTexts.push({
                            value: 'I"k2通過='
                            + doGetTextDirectIkasym(props, MS_OUTSIDE_NEUTRAL),
                            color: "blue",
                        });

                         // += 'I"k2' + '通過' + '=' + DoGetTextDirectIksym()
                        labelTexts.push({
                            value: 'I"k2通過='
                            + doGetTextDirectIksym(props, MS_OUTSIDE_NEUTRAL),
                            color: "blue",
                        });
                        break;
                    case MS_TRANSSCOTT:
                         //SEAT_MAIN
                         labelTexts.push({
                            value: '主座',
                            color: "blue",
                        });

                        // += 'I"k2' + '通過' + '=' + DoGetTextDirectIkpeak()
                        labelTexts.push({
                            value: 'I"k2通過='
                            + doGetTextDirectIkpeak(props, MS_SEAT_MAIN),
                            color: "blue",
                        });

                        // += 'I"k2' + '通過' + '=' + DoGetTextDirectIkasym()
                        labelTexts.push({
                            value: 'I"k2通過='
                            + doGetTextDirectIkasym(props, MS_SEAT_MAIN),
                            color: "blue",
                        });

                         // += 'I"k2' + '通過' + '=' + DoGetTextDirectIksym()
                        labelTexts.push({
                            value: 'I"k2通過='
                            + doGetTextDirectIksym(props, MS_SEAT_MAIN),
                            color: "blue",
                        });

                        //SEAT_T
                        labelTexts.push({
                            value: 'T座',
                            color: "blue",
                        });

                        // += 'I"k2' + '通過' + '=' + DoGetTextDirectIkpeak()
                        labelTexts.push({
                            value: 'I"k2通過='
                            + doGetTextDirectIkpeak(props, MS_SEAT_T),
                            color: "blue",
                        });

                        // += 'I"k2' + '通過' + '=' + DoGetTextDirectIkasym()
                        labelTexts.push({
                            value: 'I"k2通過='
                            + doGetTextDirectIkasym(props, MS_SEAT_T),
                            color: "blue",
                        });

                        // += 'I"k2' + '通過' + '=' + DoGetTextDirectIksym()
                        labelTexts.push({
                            value: 'I"k2通過='
                            + doGetTextDirectIksym(props, MS_SEAT_T),
                            color: "blue",
                        });
                        break;
                }
            }
            else {
                if(props.downTransKind === MS_TRANS1) {
                     // += 'I"k2' + '通過' + '=' + DoGetTextDirectIkpeak()
                     labelTexts.push({
                        value: 'I"k2通過='
                        + doGetTextDirectIkpeak(props, MS_3PHASES),
                        color: "blue",
                    });

                    // += 'I"k2' + '通過' + '=' + DoGetTextDirectIkasym()
                    labelTexts.push({
                        value: 'I"k2通過='
                        + doGetTextDirectIkasym(props, MS_3PHASES),
                        color: "blue",
                    });

                    // += 'I"k2' + '通過' + '=' + DoGetTextDirectIksym()
                    labelTexts.push({
                        value: 'I"k2通過='
                        + doGetTextDirectIksym(props, MS_3PHASES),
                        color: "blue",
                    });

                    // += 'I"k2' + '合計' + '=' + DoGetTextDirectIksym()
                    labelTexts.push({
                        value: 'I"k2合計='
                        + doGetTextTotalIk3sym(props),
                        color: "blue",
                    });
                }
                else {
                    // += 'I"k3' + '通過' + '=' + DoGetTextDirectIkpeak()
                    labelTexts.push({
                        value: 'I"k3通過='
                        + doGetTextDirectIkpeak(props, MS_3PHASES),
                        color: "blue",
                    });

                    // += 'I"k3' + '通過' + '=' + DoGetTextDirectIkasym()
                    labelTexts.push({
                        value: 'I"k3通過='
                        + doGetTextDirectIkasym(props, MS_3PHASES),
                        color: "blue",
                    });

                    // += 'I"k3' + '通過' + '=' + DoGetTextDirectIksym()
                    labelTexts.push({
                        value: 'I"k3通過='
                        + doGetTextDirectIksym(props, MS_3PHASES),
                        color: "blue",
                    });

                    // += 'I"k3' + '合計' + '=' + DoGetTextDirectIksym()
                    labelTexts.push({
                        value: 'I"k3合計='
                        + doGetTextTotalIk3sym(props),
                        color: "blue",
                    });
                }
            }
            break;
        case ProcessMode.VOLTDROP:

            if(project.dropMode == MS_VOLTDROP_MOTOR && props.dropMotor && !props.sPhase && isSetDropPerMotor(props.calcPoint0)) {
                // Vd = DropVoltMotor(VoltSecondary - DropVoltMotor2)
                let sTemp = 'Vd=' + doGetTextDropVoltMotor(props) + ' (';
                if (props.supply ) {
                    sTemp += doGetTextVoltage(props, MS_3PHASES, false);
                    sTemp += '-';
                    sTemp += doGetTextDropVoltMotor2(props, false);
                } else {
                    sTemp += '0-0'
                }
                sTemp += ')';
                labelTexts.push({
                    value: sTemp,
                    color: "blue",
                });

                //Vp = DoGetTextDropPerMotor (VoltSecondary → DropVoltMotor2)
                sTemp = 'Vp=' + doGetTextDropPerMotor(props) + ' (';
                if (props.supply) {
                    sTemp += doGetTextVoltage(props, MS_3PHASES, false);
                    sTemp += '→';
                    sTemp += doGetTextDropVoltMotor2(props, false);
                } else {
                    sTemp += '0→0';
                }
                sTemp += ')';
                labelTexts.push({
                    value: sTemp,
                    color: "blue",
                });
                break;
            }
            if(isCennectSourceDropElement(control, doGetLoadCurrentStartSide(control)) 
            && isCennectLoadDropElement(control, doGetLoadCurrentEndSide(control))
            && project.dropMode == MS_VOLTDROP_REGULAR && props.dropRegular) {
                let stdVoltage = {
                    dStdVoltage : 0,
                    sStdVoltage : '0'
                };
                if(props.sPhase) {
                    switch(props.upTransKind) {
                        case MS_TRANS1:
                            if(props.supply) {
                               stdVoltage = doGetTextVoltDropRegular(control, MS_OUTSIDE_OUTSIDE, false);
                            }

                             //OUTSIDE_OUTSIDE
                            labelTexts.push({
                                value: '外線-外線',
                                color: "blue",
                            });

                             //IL=DoGetTextLoadCurrent,力率=DoGetTextDropVoltPF
                            labelTexts.push({
                                value: 'IL='
                                + doGetTextLoadCurrent(props, MS_OUTSIDE_OUTSIDE)
                                + ',力率='
                                + doGetTextDropVoltPF(props, MS_OUTSIDE_OUTSIDE),
                                color: "blue",
                            });

                            //Vd=DoGetTextDropVoltDiff (sStdVoltage-DoGetTextDropVoltRegular)
                            labelTexts.push({
                                value: 'Vd='
                                + doGetTextDropVoltDiff(props, infoCircuit.stdCapacity, props.voltage, MS_OUTSIDE_OUTSIDE)
                                + ' ('
                                + stdVoltage.sStdVoltage
                                + '-'
                                + doGetTextDropVoltRegular(props, infoCircuit.stdCapacity, stdVoltage.dStdVoltage, MS_OUTSIDE_OUTSIDE, false)
                                + ')',
                                color: "blue",
                            });

                            //Vp=DoGetTextDropPerRegular (DoGetTextVoltage→doGetTextDropVoltRegular)
                            let sTemp = 'Vp=';
                            sTemp += doGetTextDropPerRegular(props, infoCircuit.stdCapacity, stdVoltage.dStdVoltage, MS_OUTSIDE_OUTSIDE);
                            sTemp += ' (';
                            if(props.supply) {
                                sTemp += doGetTextVoltage(props, MS_OUTSIDE_OUTSIDE, false);
                            } 
                            else {
                                sTemp += stdVoltage.sStdVoltage;
                            }
                            sTemp += '→';
                            sTemp += doGetTextDropVoltRegular(props, infoCircuit.stdCapacity, stdVoltage.dStdVoltage, MS_OUTSIDE_OUTSIDE, false);
                            sTemp += ')';
                            labelTexts.push({
                                value: sTemp,
                                color: "blue",
                            });
                            
                            if(props.supply) {
                                stdVoltage = doGetTextVoltDropRegular(control, MS_OUTSIDE_NEUTRAL, false);
                            }

                            //OUTSIDE_NEUTRAL
                            labelTexts.push({
                                value: '外線-中性線',
                                color: "blue",
                            });

                            //IL=DoGetTextLoadCurrent,力率=DoGetTextDropVoltPF
                            labelTexts.push({
                                value: 'IL='
                                + doGetTextLoadCurrent(props, MS_OUTSIDE_NEUTRAL)
                                + ',力率='
                                + doGetTextDropVoltPF(props, MS_OUTSIDE_NEUTRAL),
                                color: "blue",
                            });

                             //Vd=DoGetTextDropVoltDiff (sStdVoltage-DoGetTextDropVoltRegular)
                             labelTexts.push({
                                value: 'Vd='
                                + doGetTextDropVoltDiff(props, infoCircuit.stdCapacity, props.voltage / 2, MS_OUTSIDE_NEUTRAL)
                                + ' ('
                                + stdVoltage.sStdVoltage
                                + '-'
                                + doGetTextDropVoltRegular(props, infoCircuit.stdCapacity, stdVoltage.dStdVoltage, MS_OUTSIDE_NEUTRAL, false)
                                + ')',
                                color: "blue",
                            });

                            //Vp=DoGetTextDropPerRegular (DoGetTextVoltage→DoGetTextDropVoltRegular)
                            sTemp = 'Vp=';
                            sTemp += doGetTextDropPerRegular(props, infoCircuit.stdCapacity, stdVoltage.dStdVoltage, MS_OUTSIDE_NEUTRAL);
                            sTemp += ' (';
                            if(props.supply) {
                                sTemp += doGetTextVoltage(props, MS_OUTSIDE_NEUTRAL, false);
                            }
                            else {
                                sTemp += stdVoltage.sStdVoltage;
                            }
                            sTemp += '→';
                            sTemp += doGetTextDropVoltRegular(props, infoCircuit.stdCapacity, stdVoltage.dStdVoltage, MS_OUTSIDE_NEUTRAL, false);
                            sTemp += ')';
                            labelTexts.push({
                                value: sTemp,
                                color: "blue",
                            });
                            break;
                        case MS_TRANSSCOTT:
                            if(props.supply) {
                                stdVoltage = doGetTextVoltDropRegular(control, MS_SEAT_MAIN, false);
                            }
                            
                            //SEAT_MAIN
                            labelTexts.push({
                                value: '主座',
                                color: "blue",
                            });

                            //IL=DoGetTextLoadCurrent,力率=DoGetTextDropVoltPF
                            labelTexts.push({
                                value: 'IL='
                                + doGetTextLoadCurrent(props, MS_SEAT_MAIN)
                                + ',力率='
                                + doGetTextDropVoltPF(props, MS_SEAT_MAIN),
                                color: "blue",
                            });

                            //Vd=DoGetTextDropVoltDiff (sStdVoltage-DoGetTextDropVoltRegular)
                            labelTexts.push({
                                value: 'Vd='
                                + doGetTextDropVoltDiff(props, infoCircuit.stdCapacity, props.voltage, MS_SEAT_MAIN)
                                + ' ('
                                + stdVoltage.sStdVoltage
                                + '-'
                                + doGetTextDropVoltRegular(props, infoCircuit.stdCapacity, stdVoltage.dStdVoltage, MS_SEAT_MAIN, false)
                                + ')',
                                color: "blue",
                            });

                            //Vp=DoGetTextDropPerRegular (DoGetTextVoltage→DoGetTextDropVoltRegular)
                            sTemp = 'Vp=';
                            sTemp += doGetTextDropPerRegular(props, infoCircuit.stdCapacity, stdVoltage.dStdVoltage, MS_SEAT_MAIN);
                            sTemp += ' (';
                            if(props.supply) {
                                sTemp += doGetTextVoltage(props, MS_SEAT_MAIN, false);
                            }
                            else {
                                sTemp += stdVoltage.sStdVoltage;
                            }
                            sTemp += '→';
                            sTemp += doGetTextDropVoltRegular(props, infoCircuit.stdCapacity, stdVoltage.dStdVoltage, MS_SEAT_MAIN, false);
                            sTemp += ')';
                            labelTexts.push({
                                value: sTemp,
                                color: "blue",
                            });

                            if(props.supply) {
                                stdVoltage = doGetTextVoltDropRegular(control, MS_SEAT_T, false);
                            }

                            //SEAT_T
                            labelTexts.push({
                                value: 'T座',
                                color: "blue",
                            });

                            //IL=DoGetTextLoadCurrent,力率=DoGetTextDropVoltPF
                            labelTexts.push({
                                value: 'IL='
                                + doGetTextLoadCurrent(props, MS_SEAT_T)
                                + ',力率='
                                + doGetTextDropVoltPF(props, MS_SEAT_T),
                                color: "blue",
                            });

                            //Vd=DoGetTextDropVoltDiff (sStdVoltage-DoGetTextDropVoltRegular)
                            labelTexts.push({
                                value: 'Vd='
                                + doGetTextDropVoltDiff(props, infoCircuit.stdCapacity, props.voltage, MS_SEAT_T)
                                + ' ('
                                + stdVoltage.sStdVoltage
                                + '-'
                                + doGetTextDropVoltRegular(props, infoCircuit.stdCapacity, stdVoltage.dStdVoltage, MS_SEAT_T, false)
                                + ')',
                                color: "blue",
                            });

                            //Vp=DoGetTextDropPerRegular (DoGetTextVoltage→DoGetTextDropVoltRegular)
                            sTemp = 'Vp=';
                            sTemp += doGetTextDropPerRegular(props, infoCircuit.stdCapacity, stdVoltage.dStdVoltage, MS_SEAT_T);
                            sTemp += ' (';
                            if(props.supply) {
                                sTemp += doGetTextVoltage(props, MS_SEAT_T, false);
                            }
                            else {
                                sTemp += stdVoltage.sStdVoltage;
                            }
                            sTemp += '→';
                            sTemp += doGetTextDropVoltRegular(props, infoCircuit.stdCapacity, stdVoltage.dStdVoltage, MS_SEAT_T, false);
                            sTemp += ')';
                            labelTexts.push({
                                value: sTemp,
                                color: "blue",
                            });
                            break;
                    }
                }
                else {
                    if(props.supply) {
                        stdVoltage = doGetTextVoltDropRegular(control, MS_3PHASES, false);
                    }

                    //IL=DoGetTextLoadCurrent,力率=DoGetTextDropVoltPF
                    labelTexts.push({
                        value: 'IL='
                        + doGetTextLoadCurrent(props, MS_3PHASES)
                        + ',力率='
                        + doGetTextDropVoltPF(props, MS_3PHASES),
                        color: "blue",
                    });

                    //Vd=DoGetTextDropVoltDiff (sStdVoltage-DoGetTextDropVoltRegular)
                    labelTexts.push({
                        value: 'Vd='
                        + doGetTextDropVoltDiff(props, infoCircuit.stdCapacity, props.voltage, MS_3PHASES)
                        + ' ('
                        + stdVoltage.sStdVoltage
                        + '-'
                        + doGetTextDropVoltRegular(props, infoCircuit.stdCapacity, stdVoltage.dStdVoltage, MS_3PHASES, false)
                        + ')',
                        color: "blue",
                    });

                    //Vp=DoGetTextDropPerRegular (DoGetTextVoltage→DoGetTextDropVoltRegular)
                    let sTemp = 'Vp=';
                    sTemp += doGetTextDropPerRegular(props, infoCircuit.stdCapacity, stdVoltage.dStdVoltage, MS_3PHASES);
                    sTemp += ' (';
                    if(props.supply) {
                        sTemp += doGetTextVoltage(props, MS_3PHASES, false);
                    }
                    else {
                        sTemp += stdVoltage.sStdVoltage;
                    }
                    sTemp += '→';
                    sTemp += doGetTextDropVoltRegular(props, infoCircuit.stdCapacity, stdVoltage.dStdVoltage, MS_3PHASES, false);
                    sTemp += ')';
                    labelTexts.push({
                        value: sTemp,
                        color: "blue",
                    });
                    break;
                }
            }
            break;
        case ProcessMode.HARMONIC:
          break;
        case ProcessMode.POWER_FLOW:
          break;
      }
    
      return labelTexts;
}

//CEleBusBar::DoGetTextDirectPercentZr
export const doGetTextDirectPercentZr = (props:ControlModel, nPartKind:number, bUnit:boolean = true) =>
{
	let dPerZ = 0;
	if (props.properties.sPhase) {		// 単相回路のとき
		switch (nPartKind) {
		case MS_OUTSIDE_NEUTRAL:	// 外線－中性線間
		case MS_SEAT_MAIN:			// 主座変圧器
			dPerZ = doGetPerZDirectMG(props.properties.calcPoint1).dPercentZ;
			break;
		case MS_OUTSIDE_OUTSIDE:	// 外線間
		case MS_SEAT_T:				// Ｔ座変圧器
			dPerZ = doGetPerZDirectMG(props.properties.calcPoint0).dPercentZ;
			break;
		}
	}
	else {							// 三相回路のとき
		if (props.properties.downTransKind == MS_TRANS1) {
			dPerZ = doGetPerZDirectMG(props.properties.calcPoint1).dPercentZ;
		}
		else {
			dPerZ = doGetPerZDirectMG(props.properties.calcPoint0).dPercentZ;
		}
	}

	let sText = '';
	if (dPerZ >= 0) {
		sText = BeamsNumber(dPerZ, 4).toString();
		if (bUnit) {
			sText += '%r';
		}
	}
	else {							// 短絡電流が正しく計算されなかったとき
		sText = '????';
	}
	return sText;
}

//CEleBusBar::DoGetTextTotalIk3peak
export const doGetTextTotalIk3peak = (props:MsBusbarModel, bUnit:boolean = true) =>
{
	let dIpeakTotal = 0;
	if (props.downTransKind == MS_TRANS1) {
		dIpeakTotal = doGetIkpeakTotalMG(props.calcPoint1);
	}
	else {
		dIpeakTotal = doGetIkpeakTotalMG(props.calcPoint0);
	}
	let sText = '';
	if (dIpeakTotal == 0) {
		if (props.primarySupply) {
			sText = '∞';
		}
		else {
			sText = "0";
		}
	}
	else if (dIpeakTotal > 0) {
		sText = BeamsNumber(dIpeakTotal / 1000, 4).toString();
		if (bUnit) {
			sText += 'kApeak';
		}
	}
	else {							// 短絡電流が正しく計算されなかったとき
		sText = '????';
	}
	return sText;
}

//CEleBusBar::DoGetTextTotalIk3asym
export const doGetTextTotalIk3asym = (props:MsBusbarModel, bUnit:boolean = true) =>
{
    let dIasymTotal = 0;
	if (props.downTransKind == MS_TRANS1) {
		dIasymTotal = doGetIkasymTotalMG(props.calcPoint1);
	}
	else {
		dIasymTotal = doGetIkasymTotalMG(props.calcPoint0);
	}
	let sText = '';
	if (dIasymTotal == 0) {
		if (props.primarySupply) {
			sText = '∞';
		}
		else {
			sText = "0";
		}
	}
	else if (dIasymTotal > 0) {
		sText = BeamsNumber(dIasymTotal / 1000, 4).toString();
		if (bUnit) {
			sText += 'kAasym';
		}
	}
	else {							// 短絡電流が正しく計算されなかったとき
		sText = '????';
	}
	return sText;
}

//CEleBusBar::DoGetTextTotalPercentZr
export const doGetTextTotalPercentZr = (props:MsBusbarModel, bUnit:boolean = true) =>
{
	let dPerZ = 0;
	if (props.downTransKind == MS_TRANS1) {
		dPerZ = doGetPerZTotalMG(props.calcPoint1).dPercentZ;
	}
	else {
		dPerZ = doGetPerZTotalMG(props.calcPoint0).dPercentZ;
	}
	let sText = '';
	if (dPerZ >= 0) {
		sText = BeamsNumber(dPerZ, 4).toString();
		if (bUnit) {
			sText += '%r';
		}
	}
	else {							// 短絡電流が正しく計算されなかったとき
		sText = '????';
	}
	return sText;
}

//CEleBusBar::DoGetTextActiveLeft
export const doGetTextActiveLeft = (props:MsBusbarModel, nDirect:number, bUnit:boolean = true) =>
{
	let sText = '';
	if (nDirect == DIRECT_NONE) {
		if (props.lineActiveFlow1 >= 0) {
			sText = BeamsNumber(props.lineActiveFlow1, 5).toString();
		}
		else {
			sText = BeamsNumber(props.lineActiveFlow2, 5).toString();
		}
	}
	else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
		sText = BeamsNumber(Math.abs(props.lineActiveFlow1), 5).toString();
	}
	else {
		sText = BeamsNumber(Math.abs(props.lineActiveFlow2), 5).toString();
	}

	if (bUnit) {
		sText += 'kW';
	}

	return sText;
}

//CEleBusBar::DoGetTextReactiveLeft
export const doGetTextReactiveLeft = (props:MsBusbarModel, nDirect:number, bUnit:boolean = true) =>
{
	let sText = '';
	if (nDirect == DIRECT_NONE) {
		if (props.lineReactiveFlow1 >= 0) {
			sText = BeamsNumber(props.lineReactiveFlow1, 5).toString();
		}
		else {
			sText = BeamsNumber(props.lineReactiveFlow2, 5).toString();
		}
	}
	else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
		sText = BeamsNumber(Math.abs(props.lineReactiveFlow1), 5).toString();
	}
	else {
		sText = BeamsNumber(Math.abs(props.lineReactiveFlow2), 5).toString();
	}

	if (bUnit) {
		sText += 'kvar';
	}

	return sText;
}

//CEleBusBar::DoGetTextFlowsResult
export const doGetTextFlowsResult = (props:MsBusbarModel, processMode:any, nDirect:number, bSociety:boolean = false) =>
{
	let sRefV1:string[] = [];

	if (bSociety) {
		return sRefV1;
	}

	switch (processMode) {
	case MS_MODE_DRAWING:
	case MS_MODE_CHECKING:
	case MS_MODE_PERCENT_Z:
	case MS_MODE_CALCULATION:
	case MS_MODE_VOLTDROP:
	case MS_MODE_HARMONIC:
		break;
	case MS_MODE_POWER_FLOW:
		if (!props.sPhase && props.lineActiveFlow1 !==0 && props.lineActiveFlow2 !==0 && props.lineReactiveFlow1 !==0 && props.lineReactiveFlow2 !==0) {
			if (nDirect == DIRECT_NONE) {
				sRefV1.push(doGetTextActiveLeft(props,nDirect));
				sRefV1.push(doGetTextReactiveLeft(props,nDirect));
			}
			else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
                sRefV1.push(doGetTextActiveLeft(props,nDirect));
                let sTemp = ''
				if (props.lineActiveFlow1 > 0) {
					if (props.lineReactiveFlow1 > 0) {
						sTemp += "+j";
					}
					else {
						sTemp += "-j";
					}
				}
				else {
					if (props.lineReactiveFlow1 > 0) {
						sTemp += "-j";
					}
					else {
						sTemp += "+j";
					}
				}
				sRefV1.push(sTemp + doGetTextReactiveLeft(props,nDirect));
			}
			else {
                sRefV1.push(doGetTextActiveLeft(props,nDirect));
                let sTemp = ''
				if (props.lineActiveFlow2 > 0) {
					if (props.lineReactiveFlow2 > 0) {
						sTemp += "+j";
					}
					else {
						sTemp += "-j";
					}
				}
				else {
					if (props.lineReactiveFlow2 > 0) {
						sTemp += "-j";
					}
					else {
						sTemp += "+j";
					}
				}
				sRefV1.push(sTemp + doGetTextReactiveLeft(props,nDirect));
			}
		}
		break;
	}

	return sRefV1;
}
