import DeleteIcon from '@mui/icons-material/Delete';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import ErrorIcon from '@mui/icons-material/Error';
import SettingsIcon from '@mui/icons-material/Settings';
import { CircularProgress, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from 'ag-grid-react';
import { forwardRef, useImperativeHandle, useMemo, useRef, useState } from "react";
import { PageData } from '../../models/Index';
import { AG_GRID_LOCALE_JP } from '../../themes/localeJP';
import LoadingOverlay, { DefaultLoadingMessage } from '../common/LoadingOverlay';
import NoRowsOverlay, { NoRowsMessage } from '../common/NoRowsOverlay';
import PaginationCpn from '../common/PaginationCpn';
import './projectGrid.css';
import HandymanIcon from '@mui/icons-material/Handyman';
import LockIcon from '@mui/icons-material/Lock';

// -------------
// Props
export type ProjectGridProps = {
    pageSize: number;
    pageData: PageData;
    keyword: string;
    isShowMyProject: boolean;
    userExpired?: boolean;
    onChangeSearchCondition: (name: string, value: any) => void;
    onDelete: (data: object) => void;
    onSettingProjectDetails: (data: any) => void;
    onEditProjectDiagram: (projectId: number,ownerProject:number) => void;
    onShowMaintenanceScreen: (projectId: number,ownerProject:number) => void;
    onProjectSelected: (data: any) => void;
};

// -------------
// Component
export const ProjectGrid = forwardRef((props: ProjectGridProps, ref) => {
    const {
        pageSize,
        pageData,
        keyword,
        isShowMyProject,
        userExpired,
        onChangeSearchCondition,
        onDelete,
        onSettingProjectDetails,
        onEditProjectDiagram,
        onProjectSelected,
        onShowMaintenanceScreen
    } = props;
    const gridRef = useRef() as any;
    const [rowData, setRowData] = useState<any[]>([]);
    useImperativeHandle(ref, () => ({
        setRowData(value: any) {
            setRowData(value);
        },

        hideOverlay() {
            gridRef.current.api.hideOverlay();
        }
    }));


    // Loading時のメッセージ
    const loadingOverlayComponent = useMemo(() => {
        return LoadingOverlay;
    }, []);
    const loadingOverlayComponentParams = useMemo(() => {
        return {
            loadingMessage: DefaultLoadingMessage,
        };
    }, []);

    const noRowsOverlayComponent = useMemo(() => {
        return NoRowsOverlay;
    }, []);

    const noRowsOverlayComponentParams = useMemo(() => {
        return {
            noRowsMessageFunc: () => NoRowsMessage,
        };
    }, []);
    const handleEditProjectDiagram = (data: any) => {
        if (data.data.importing && data.data.importErrorCode !== 0) {
            return;
        }
        onEditProjectDiagram(data.data.projectId,data.data.ownerId);
    };

    const handleSelectProject = (e: any) => {
        if (!(e.data.importing && e.data.importErrorCode !== 0)) {
            onProjectSelected(e.data);
        }
    }
    // 編集ボタンのセル内表示とボタンイベント
    const cellMessageRenderer = (props: any) => {
        const importing = props.data.importing && props.data.importErrorCode === -1;
        const importError = props.data.importing && props.data.importErrorCode > 0;
        // 詳細
        const handleSettingProjectDetail = () => {
            onSettingProjectDetails(props.data);
        };

        const handleEditProjectDiagram = () => {
            onEditProjectDiagram(props.data.projectId,props.data.ownerId);
        };

        const handleDelete = () => {
            onDelete({ id: props.data.projectId, name: props.data.projectName });
        };

        const handleShowMaintenanceScreen = () =>{
            onShowMaintenanceScreen(props.data.projectId,props.data.ownerId);
        }
        return (
            <Stack direction="row" justifyContent="flex-start" alignItems={'center'} height={"100%"}>
                <Tooltip arrow title='単線結線図の編集'>
                    <IconButton aria-label="drawing" onClick={handleEditProjectDiagram} disabled={importing || importError}>
                        <DescriptionOutlinedIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip arrow title='プロジェクト設定'>
                    <IconButton aria-label="edit" onClick={handleSettingProjectDetail} disabled={importing || importError}>
                        <SettingsIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip arrow title='メンテナンスデータ'>
                    <IconButton aria-label="maintain" onClick={handleShowMaintenanceScreen} disabled={importing || importError}>
                        <HandymanIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                {
                    isShowMyProject &&
                    <Tooltip arrow title='プロジェクト削除'>
                        <IconButton aria-label="delete" onClick={handleDelete} disabled={importing || userExpired}>
                            <DeleteIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                }
                
            </Stack>
        );
    };

    // プロジェクト名セル内表示
    const cellProjectNameRenderer = (props: any) => {
        const locker_user_id = props.data.lockedUserId
        const importing = props.data.importing && props.data.importErrorCode === -1;
        const importError = props.data.importing && props.data.importErrorCode > 0;
        const errorMessage = () => {
            if (props.data.importErrorCode === 1) {
                return "インポート失敗（バージョン不正）";
            } else if (props.data.importErrorCode === 2) {
                return "インポート失敗（コンバートエラー）";
            } else if (props.data.importErrorCode === 99) {
                return "インポート失敗（不明なエラー）";
            }
            return "";
        };

        return (
            <Stack direction="row" justifyContent="flex-start" alignItems={'center'} height={"100%"} spacing={1}>
                {!isShowMyProject && locker_user_id !== null &&
                    <Stack>
                        <LockIcon/>
                    </Stack>
                }
                <Stack>
                    <Typography variant="body1">
                        {props.data.projectName}
                    </Typography>
                    {importing &&
                        <Tooltip arrow title='インポート中'>
                            <CircularProgress disableShrink size={'1rem'}/>
                        </Tooltip>
                    }
                    {importError &&
                        <Tooltip arrow title={errorMessage()}>
                            <ErrorIcon />
                        </Tooltip>
                    }
                </Stack>
            </Stack>
        );
    };

    // 全列に共通の定義
    const defaultColDef = {
        sortable: true, // ソート可
        editable: false, // 編集不可
        resizable: true,
        suppressMovable: true,  // 列の移動を無効にする
    };

    // 列タイプの定義
    const columnTypes = {
        nonEditableColumn: { editable: false }, // 編集不可
    };

    // 列の個別定義
    const columnDefs = [
        {
            headerName: "プロジェクト名",
            field: "projectName",
            rowDrag: false,
            flex: 4,
            cellRenderer: cellProjectNameRenderer,
        },
        {
            headerName: "更新日時",
            field: "lastUpdateDatetime",
            rowDrag: false,
            flex: 3,
        },
        {
            headerName: "",
            field: "option",
            suppressMovable: true,
            resizable: false,
            flex: 2,
            cellRenderer: cellMessageRenderer,
        }
    ];

    const columnDefsSharingProject = [
        {
            headerName: "プロジェクト名",
            field: "projectName",
            rowDrag: false,
            flex: 2,
            cellRenderer: cellProjectNameRenderer,
        },
        {
            headerName: "權限",
            field: "permission",
            rowDrag: false,
            flex: 1,
            valueFormatter: (params:any) => params.value == 'owner'?'所有':params.value =='view'?'参照':'編集'
        },
        {
            headerName: "所有者",
            field: "owner",
            rowDrag: false,
            flex: 1,
        },
        {
            headerName: "最終更新者",
            field: "nameLastUpdateUser",
            rowDrag: false,
            flex: 1,
        },
        {
            headerName: "更新日時",
            field: "lastUpdateDatetime",
            rowDrag: false,
            flex: 1.5,
        },
        {
            headerName: "編集ロック",
            field: "beEditingBy",
            rowDrag: false,
            flex: 2,
        },
        {
            headerName: "",
            field: "option",
            suppressMovable: true,
            resizable: false,
            flex: 2,
            cellRenderer: cellMessageRenderer,
        }
    ];

    const rowHeight = 48;
    gridRef.current?.api?.setQuickFilter(keyword)
    return (
        <>
            <div>
                <div className="ag-theme-alpine" style={{ height: 'calc(100vh - 208px)', width: "100%" }}>
                    <AgGridReact
                        ref={gridRef}
                        defaultColDef={defaultColDef}
                        columnTypes={columnTypes}
                        columnDefs={isShowMyProject? columnDefs : columnDefsSharingProject}
                        domLayout={"normal"}
                        rowData={rowData}
                        animateRows={true}
                        rowDragManaged={true}
                        suppressMovableColumns={false}
                        suppressDragLeaveHidesColumns={false}
                        rowHeight={rowHeight}
                        localeText={AG_GRID_LOCALE_JP}
                        paginationPageSize={pageSize}
                        onCellClicked={handleSelectProject}
                        onCellDoubleClicked={handleEditProjectDiagram}
                        loadingOverlayComponent={loadingOverlayComponent}
                        loadingOverlayComponentParams={loadingOverlayComponentParams}
                        noRowsOverlayComponent={noRowsOverlayComponent}
                        noRowsOverlayComponentParams={noRowsOverlayComponentParams}
                        suppressRowHoverHighlight={true}
                        rowSelection={"single"}
                    />
                </div>
                <PaginationCpn pageData={pageData} onChangeSearchCondition={onChangeSearchCondition} limit={pageSize} />
            </div>
        </>
    );
});

export default ProjectGrid;