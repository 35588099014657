import { LoadingButton } from "@mui/lab";
import { Button, CircularProgress, Grid, Stack, TextField, Typography } from "@mui/material";
import { useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { EquipMotoreffModel } from "../../../models/breakModel";
import { Path as RoutePath } from "../../../path";
import { ApplicationState, actionCreators } from "../../../store";
import FetchHelper from "../../FetchHelper";
//-----------
//FETCH ID
const GET_EQUIP_MOTOREFF = "GET_EQUIP_MOTOREFF"
const UPDATE_EQUIP_MOTOREFF = "UPDATE_EQUIP_MOTOREFF"
const CREATE_EQUIP_MOTOREFF = "CREATE_EQUIP_MOTOREFF"

const initialValue:EquipMotoreffModel = {
    lMotorEffId:0,
    dCapacity:0,
    dRotorResistance:0,
    dStatorResistance:0,
    dSubReactance:0,
}
// -------------
// Props
export type EquipMotorEffEditProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
export const EquipMotoreffEdit = (props: EquipMotorEffEditProps) => {
    const {
        userId,
        onCreateError,
        onUpdate, onUpdateError,
        onSuccess, onError,
        onCreate
    } = props;

    const { control, formState: { errors }, handleSubmit, setValue, getValues, } = useForm<EquipMotoreffModel>({
        criteriaMode: "all",
        mode:"all",
        defaultValues: initialValue,
    })
    type MyParams = {
        id: string;
        mode: string;
    };
    let { id, mode } = useParams<keyof MyParams>() as MyParams;
    let navigate = useNavigate();
    const [loadingFlag, setLoadingFlag] = useState(mode === "edit" ? true : false);
    const gridRef = useRef() as any;
    const Exp = /^[-+]?\d+(\.\d+)?$/;
    const messageIsNotNumber = "数字を入力してください。";

    const handleSuccess = (data: any) => {
        setLoadingFlag(false);
        if(data && data.resultCode == 0){
            setValue("dCapacity",data.data.dCapacity?? "")
            setValue("dRotorResistance",data.data.dRotorResistance?? "")
            setValue("dStatorResistance",data.data.dStatorResistance?? "")
            setValue("dSubReactance",data.data.dSubReactance?? "")
        }
    }

    // 検索エラー時
    const handleError = (success: boolean, data: any, error: any) => {
        setLoadingFlag(false);
        console.log('>>>> handle Error');
        gridRef.current.hideOverlay();
    };

    // 検索実行
    const handleEditSubmit = (data: EquipMotoreffModel) => {
        setLoadingFlag(true);
        if(mode === "edit") {
            let request = {
                lMotorEffId:id,
                dCapacity:data.dCapacity,
                dRotorResistance:data.dRotorResistance,
                dStatorResistance:data.dStatorResistance,
                dSubReactance:data.dSubReactance,
            } as any;
            const object = {
                requestPrms:request,
                userId: userId,
            };
            onUpdate(object);
        } else if (mode === "add") {
            let request = {
                dCapacity:data.dCapacity,
                dRotorResistance:data.dRotorResistance,
                dStatorResistance:data.dStatorResistance,
                dSubReactance:data.dSubReactance,
            } as any;
            const object = {
                requestPrms:request,
                userId: userId,
            };
            onCreate(object);
        }
    }

    // キャンセル
    const handleCancel = () => {
        navigate(RoutePath.EquipMotoreffList);
    }

    // Submit後のAPI呼び出し成功時
    const handleSuccessSubmit = (success: boolean, data: any, error: any) => {
        onSuccess();
        if (mode === "add") {
            navigate(RoutePath.EquipMotoreffEdit + `/edit/${data.data.lMotorEffId}`, { replace: true })
        }
        setLoadingFlag(false);
    }
    // Submit後のAPI呼び出しエラー時
    const handleErrorUpdate = (success: boolean, data: any, error: any) => {
        onUpdateError();
        setLoadingFlag(false);
    }

    // Submit後のAPI呼び出しエラー時
    const handleSubmitError = (success: boolean, data: any, error: any) => {
        onCreateError();
        setLoadingFlag(false);
    }

    //入力欄背景
    const divStyles = {
        backgroundColor: 'white',
        border: '1px solid #bebebe',
        boxShadow: '0 0 5px #fff, 0 0 5px #bebebe, 0 0 1px #aaa',
        padding: '20px 40px',
        marginTop: '40px',
    }

    return (
        <>
            {
                mode === "edit" &&
                <FetchHelper
                fetchId={GET_EQUIP_MOTOREFF}
                url={`/master/equip_motoreff/get`}
                method="POST"
                json={true}
                request={true}
                params={{
                    requestPrms:{
                        id:id
                    },
                    userId: userId,
                }}
                onComplete={(success, data, error) => {
                    success ? handleSuccess(data) : handleError(success, data, error);
                }}
            />
            }
            {loadingFlag && (
                <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1500, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                    <CircularProgress />
                </div>
            )}
            <div style={{ height: "100%", width: "100%", marginTop: "10px" }}>
                <Stack direction="row" justifyContent="flex-start" alignItems="center" sx={{}} style={{ margin: '18px', color: 'MenuText' }}>
                    <Typography variant="h4">
                        {/* FIXME: */}
                        電動機(FF)詳細
                    </Typography>
                </Stack>
                <form onSubmit={handleSubmit(handleEditSubmit)}>
                    <div style={divStyles}>
                        <Typography variant="h6">
                            {/* FIXME: */}
                            電動機(FF)
                        </Typography>
                        <Stack mt={2} direction={{ xs: 'column', sm: 'row' }} sx={{maxHeight: "calc(100vh)"}}>
                            <Grid width={"100%"} spacing={1}>
                                <Grid pt={1} spacing={2} container direction={"row"}>
                                    <Grid item xs={6}>
                                        <Controller
                                            control={control}
                                            name="dCapacity"
                                            rules={{
                                                pattern: {
                                                    value: Exp,
                                                    message: messageIsNotNumber,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.dCapacity}
                                                    helperText={errors?.dCapacity?.message}
                                                    label="容量（kVA）"
                                                    type="text"
                                                    size="small"

                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            control={control}
                                            name="dRotorResistance"
                                            rules={{
                                                pattern: {
                                                    value: Exp,
                                                    message: messageIsNotNumber,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.dRotorResistance}
                                                    helperText={errors?.dRotorResistance?.message}
                                                    label="Rotor Resistance Rr（%）"
                                                    type="text"
                                                    size="small"

                                                />
                                            )}
                                        />
                                    </Grid>                           
                                </Grid>
                                <Grid mt={0} spacing={2} container direction={"row"}>
                                    <Grid item xs={6}>
                                        <Controller
                                            control={control}
                                            name="dStatorResistance"
                                            rules={{
                                                pattern: {
                                                    value: Exp,
                                                    message: messageIsNotNumber,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.dStatorResistance}
                                                    helperText={errors?.dStatorResistance?.message}
                                                    label="Stator Resistance Rs（%）"
                                                    type="text"
                                                    size="small"

                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            control={control}
                                            name="dSubReactance"
                                            rules={{
                                                pattern: {
                                                    value: Exp,
                                                    message: messageIsNotNumber,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.dSubReactance}
                                                    helperText={errors?.dSubReactance?.message}
                                                    label="Subtransient Reactance X''m（%）"
                                                    type="text"
                                                    size="small"

                                                />
                                            )}
                                        />
                                    </Grid>   
                                </Grid>
                            </Grid>
                        </Stack>
                    </div>
                    <Stack direction='row' justifyContent="flex-end" spacing={2} sx={{ mt: 1, mr: 2 }}>
                        <LoadingButton variant="contained" type="submit" loading={loadingFlag}>保存</LoadingButton>
                        <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                    </Stack>
                </form>
            </div>
            <FetchHelper fetchId={UPDATE_EQUIP_MOTOREFF}
                onComplete={(success: boolean, data: any, error: any) =>
                    success ? handleSuccessSubmit(success, data, error) : handleErrorUpdate(success, data, error)} />
            <FetchHelper fetchId={CREATE_EQUIP_MOTOREFF}
                onComplete={(success, data, error) => success ?
                    handleSuccessSubmit(success, data, error) :
                    handleSubmitError(success, data, error)} />
        </>
    );
}

const mapStateToProps = (state: ApplicationState) => (
    {
        userId: state.app.user?.userId,
    }
)

const mapDispatchToProps = (dispatch: any) => {
    return {
        onCreate: (data: any) => dispatch(actionCreators.fetch(CREATE_EQUIP_MOTOREFF, `/master/equip_motoreff/add`, "POST", data, false, true)),
        onUpdate: (data: any) => dispatch(actionCreators.fetch(UPDATE_EQUIP_MOTOREFF, `/master/equip_motoreff/modify`, "POST", data,false,true)),
        onSuccess: () =>
            dispatch(actionCreators.showMessage({
                type: "info",
                title: "電動機(FF)更新",
                body: "電動機(FF)を更新しました。",
            })),
        onError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "検索",
                body: "検索できません。",
            })),
        onCreateError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "電動機(FF)登録",
                body: "電動機(FF)を登録出来ませんでした。",
            })),
        onUpdateError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "電動機(FF)編集",
                body: "電動機(FF)を更新出来ませんでした。",
            })),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(EquipMotoreffEdit as any);