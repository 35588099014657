import { Box, Button, CircularProgress, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from 'react-hook-form';
import { connect } from "react-redux";
import { actionCreators, ApplicationState,} from "../../../store";
import { post } from "../../CallApi";
import { useNavigate } from "react-router-dom";
import { Message } from "../../../models/Index";
import ConfirmDialog from "../../common/ConfirmDialog";
import FetchHelper from "../../FetchHelper";

const SEND_MAIL_CONFIRM = "SEND_MAIL_CONFIRM"

interface ContracExpirationDate {
    "id": number;
    "title": string;
    "content": string;
    "numberOfDays": number;
    "emailGreeting": string;
    "emailSignature":string;
}
// 初期値設定
const initialValue: ContracExpirationDate = {
    id: 0,
    title: "",
    content: "",
    numberOfDays: 0,
    emailGreeting:"",
    emailSignature:""
}

type ContracExpirationDateProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

export const ContracExpirationDate = (props: ContracExpirationDateProps) => {
    const {userSub, userEmail, onShowMessage, sendMailToUser, onSendMailError, onSendMailSuccess} = props;

    const { control, handleSubmit, setValue, getValues, formState: { errors } } = useForm<ContracExpirationDate>({
        criteriaMode: "all",
        defaultValues: initialValue,
    });

    const navigate = useNavigate()
    
    const [loadingFlag, setLoadingFlag] = React.useState(false)
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
    useEffect(() => {
        initData()
    },[])

    const initData = async () => {
        setLoadingFlag(true)
        const res = await post("/notification/get-contract-notification-setting", {})
        if (res.success){
            if (res.data.data) {
                const data = res.data.data
                setValue('id', data.contract_exp_id)
                setValue('title', data.title)
                setValue('content', data.content)
                setValue('numberOfDays', data.number_of_days)
                setValue('emailGreeting', data.email_greeting)
                setValue('emailSignature', data.email_signature)
            }
        } else {
            onShowMessage({
                type: 'error',
                title: '契約終了日の事前通知設定',
                body: res.error.message,
            });
        }
        setLoadingFlag(false)
    }

    const handleEditSubmit = async (value: ContracExpirationDate) => {
        setLoadingFlag(true)
        const res = await post("/notification/set-contract-notification-setting", {...value})
        if (res.success && res.data.data){
            onShowMessage({
                type: 'info',
                title: '契約終了日の事前通知設定',
                body: '契約終了日の事前通知設定しました。',
            })
        } else {
            onShowMessage({
                type: 'error',
                title: '契約終了日の事前通知設定',
                body: res.error.message,
            });
        }
        setLoadingFlag(false)
    }

    const handleCancel = () =>{
        setOpenConfirmDialog(false)
    }

    const handleSendMailCheck = () =>{
        let params = {
            title: getValues("title"),
            content: getValues("content"),
            emailGreeting: getValues("emailGreeting"),
            emailSignature: getValues("emailSignature"),
            sub_user: [userSub],
            isConfirm: true,
            isExpireEmail: true
        }
        sendMailToUser(params)
        setLoadingFlag(true)
        setOpenConfirmDialog(false)
    }

    const handleSuccess = (data:any) =>{
        setLoadingFlag(false)
        if(data && data.resultCode == 0)
        {
            onSendMailSuccess()
        }
        else
        {
            onSendMailError(data ? data.message : "")
        }
    }

    const handleError = (data:any, error:any) => {
        console.log(error)
        setLoadingFlag(false)
        onSendMailError(error)
    }

    return (
        <>
            {loadingFlag && (
                <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1500, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                    <CircularProgress />
                </div>
            )}
            
            <fieldset 
                style={{
                    border:'2px solid black',
                    height:'calc(100vh - 96px)',
                    width:'calc(100% - 36px)',
                    marginTop:'10px'
                }}
            >
                <Stack mt={1} ml={1}>
                    <Typography variant="h6" fontWeight={"bold"}>
                        契約終了日の事前通知設定
                    </Typography>
                </Stack>
                <form onSubmit={handleSubmit(handleEditSubmit)}>
                    <Stack style={{height:'calc(100vh - 196px)', overflow:'auto'}}>
                        <Stack mt={2} mx={3}>
                            <Stack>
                                <Controller
                                    name="title"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label={<Typography variant="body1">件名</Typography>}
                                        />
                                    )}
                                />
                            </Stack>
                            <Stack mt={3}>
                                <Controller
                                    name="content"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label={<Typography variant="body1">本文</Typography>}
                                            multiline
                                            maxRows={7}
                                            minRows={7}
                                        />
                                    )}
                                />
                            </Stack>
                            <Stack>
                                <Typography variant="body1">※本文中の契約終了日を表示する位置に、%dateと入力してください</Typography>
                            </Stack>
                            <Stack mt={3} flexDirection={"row"} alignItems={"center"}>
                                <Typography variant="body1">契約終了日の</Typography>
                                <Stack>
                                    <Controller
                                        name="numberOfDays"
                                        rules={{
                                            validate: value => value >= 0 || '数字 >= 0 を入力してください。' ,
                                        }}
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                size="small"
                                                type="number"
                                                {...field}
                                                style={{width:'80px', marginLeft:'5px', marginRight:'5px'}}
                                                error={!!errors?.numberOfDays}
                                                helperText={errors?.numberOfDays?.message}
                                            />
                                        )}
                                    />
                                    </Stack>
                                <Typography variant="body1">日前からお知らせ表示する</Typography>
                            </Stack>
                            <Stack mt={2}>
                                <Box>
                                    <Button variant="contained" style={{textTransform:"none"}} onClick={()=>setOpenConfirmDialog(true)}>{userEmail}にメール送信（確認用）</Button>
                                </Box>
                            </Stack>
                            <Stack mt={3}>
                                <Controller
                                    name="emailGreeting"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label={<Typography variant="body1">メールの挨拶文</Typography>}
                                            multiline
                                            maxRows={5}
                                            minRows={5}
                                        />
                                    )}
                                />
                            </Stack>
                            <Stack ml={1}>
                                <Typography variant="body1">※お客様名を表示する位置に、%usernameと入力してください</Typography>
                            </Stack>
                            <Stack mt={3}>
                                <Controller
                                    name="emailSignature"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label={<Typography variant="body1">メールの署名</Typography>}
                                            multiline
                                            maxRows={5}
                                            minRows={5}
                                        />
                                    )}
                                />
                            </Stack>
                        </Stack>
                    </Stack>
                    <Stack direction='row' justifyContent="flex-end" gap={2} my={2} mr={2}>
                        <Button variant="contained" type="submit">保存</Button>
                        <Button color="inherit" variant="contained" onClick={() => {navigate(-1)}}>キャンセル</Button>
                    </Stack>
                </form>
            </fieldset>    
            <FetchHelper fetchId={SEND_MAIL_CONFIRM} onComplete={(success, data, error) =>
                success ? handleSuccess(data) : handleError(data, error)} />  
            {
                openConfirmDialog &&
                <ConfirmDialog messageLine2={userEmail+'にメールを送信します。'} messageLine3={'よろしいですか？'} isOpen={true} onOK={handleSendMailCheck} onCancel={handleCancel}/>
            } 
        </>
    );
};

const mapStateToProps = (state: ApplicationState) => ({
    userSub: state.app.user?.userSub,
    userEmail : state.app.user?.cognit_user.attributes.email
});

const mapDispatchToProps = (dispatch: any) => {
    return {
        onShowMessage: (message: Message) => dispatch(actionCreators.showMessage(message)),
        sendMailToUser: (params:any) => dispatch(actionCreators.fetch(SEND_MAIL_CONFIRM, `/notification/send-mail-to-user`, "POST", params,false,true)),
        onSendMailSuccess: () =>
            dispatch(actionCreators.showMessage({
                type: "info",
                title: "お知らせメールを送信する",
                body: "お知らせ情報メールの送信に成功しました。",
            })),
        onSendMailError: (error:any) =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "お知らせメールを送信する",
                body: "お知らせ情報メールを送信できませんでした。" + error,
            })),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContracExpirationDate);
