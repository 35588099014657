import { BOTTOM_CENTER, DIRECT_NONE, RIGHT_CENTER } from "../models/Constants";
import { ControlModel, MsGeneratorModel, ProcessMode } from "../models/Index";
import { FLOW_NODE_KIND_PQ, MS_MODE_CALCULATION, MS_MODE_CHECKING, MS_MODE_DRAWING, MS_MODE_HARMONIC, MS_MODE_PERCENT_Z, MS_MODE_POWER_FLOW, MS_MODE_VOLTDROP } from "../statics";
import { BeamsNumber } from "./FormatNumber";
import { percentZDefault } from "./PercentZ";
import { doGetIkasymDirectMG, doGetIkasymTotalMG, doGetIkpeakDirectMG, doGetIkpeakTotalMG, doGetIksymDirectMG, doGetIksymTotalMG, doGetPerZTotalMG } from "./calcPointFunction";

export const doGetValueIsym = (circuitFrequency:number,circuitCapacity:number,percentExX:number,percentExR:number,item:MsGeneratorModel,voltageValue:number) => {
    const capacity = item=== undefined? 0:item.capacity;
    const voltage = voltageValue;
    const subReactance = item=== undefined? 0:item.subReactance;
    const reactance = item=== undefined? 0:item.reactance;
    const subConstantForm = item=== undefined? 0:item.subConstant;
    const constant = item=== undefined? 0:item.constant;
    if(voltage <= 0 && circuitFrequency <= 0)
        return 0;
    percentExX *= capacity / circuitCapacity;
    percentExR *= capacity / circuitCapacity;
    let statorResistance = doGetValueStatorOhmR(circuitFrequency,subReactance,constant);
    let Z2eg = Math.sqrt(Math.pow(statorResistance+percentExR,2) + Math.pow(subReactance+percentExX,2));
    let Zeg = Math.sqrt(Math.pow(statorResistance+percentExR,2) + Math.pow(reactance+percentExX,2));
    let I2eg;
    if(Z2eg != 0){
        I2eg = capacity * 1000 * 100 / (voltage * Math.sqrt(3.0) *Z2eg);
    }else{
        I2eg = 0;
    }
    let Ieg;
    if(Zeg != 0)
        Ieg = capacity * 1000 * 100 / (voltage * Math.sqrt(3.0) *Zeg);
    else
        Ieg = 0;
    let subConstant;
    if((subReactance + percentExX) >= 0 && (reactance + percentExX) >= 0 ){
        subConstant = subConstantForm * (1 + percentExX / (subReactance + percentExX)) / (1 + percentExX / (reactance + percentExX));
    }else{
        subConstant = subConstantForm;
    }
    if(subConstant <= 0){
        return 0;
    }
    return 1.1 * ((I2eg - Ieg) * Math.exp(-1/(2 * circuitFrequency * subConstant)) + Ieg)
}
export const doGetValueStatorOhmR = (frequency:number,dsubReactance:number,dConstant:number) => {
    if(frequency === 0 || dConstant === 0)
        return 0;
    return dsubReactance/( 2 * Math.PI * frequency * dConstant);
}
export const doGetValuePercentZe = (frequency:number,capacityCurcuit:number,voltage:number,item:MsGeneratorModel) => {
    const Isym = doGetValueIsym(frequency,capacityCurcuit,0,0,item,voltage);
    return capacityCurcuit * 1000 * 100 / (Math.sqrt(3.0) * voltage * Isym);
}
export const doGetTextPercentZe = (frequency:number,capacityCurcuit:number,voltage:number,capacity:number,item:MsGeneratorModel) => {
    let percentZ = doGetValuePercentZe(frequency,capacityCurcuit,voltage,item);
    percentZ *= capacity / capacityCurcuit;
    return BeamsNumber(percentZ,4);
}

const doGetPercentZr = (frequency:number,curcuitCapacity:number,item:MsGeneratorModel) => {
    const subReactance = item === undefined?0:item.subReactance;
    const constant = item ===undefined?0:item.constant;
    const capacity = item ===undefined?0:item.capacity;
    const percentZR:any = {};
    percentZR.PerRk3 = doGetValueStatorOhmR(frequency,subReactance,constant);
    percentZR.PerXk3 = subReactance;
    if(capacity == 0){
        percentZR.PerRk3 = 0;
        percentZR.PerXk3 = 0;
    }
    else{
        percentZR.PerRk3 *= curcuitCapacity / capacity;
        percentZR.PerXk3 *= curcuitCapacity / capacity;
    }
    return percentZR;
}

export const doGetTextXr = (frequency:number,curcuitCapacity:number,item:MsGeneratorModel) => {
    let result;
    const percentZ = doGetPercentZr(frequency,curcuitCapacity,item);
    if(percentZ.PerRk3 <= 0){
        result = 0;
    }
    else{
        result = Number(percentZ.PerXk3/percentZ.PerRk3)
    }
    return BeamsNumber(result,4);
}

export const doGetTextPowerFactor = (generatorItem:MsGeneratorModel,opeMode:any,unit?:boolean) =>{
    let result
    if(opeMode != MS_MODE_POWER_FLOW){
        result = BeamsNumber(generatorItem.powerFactor,5);
    }else{
        const powerFactor = generatorItem.activePower / Math.sqrt(generatorItem.activePower * generatorItem.activePower + generatorItem.reactivePower * generatorItem.reactivePower);
        result = BeamsNumber(powerFactor,5);
    }
    return result;
}

export const doSetDefaultGenerator = (voltage:any,item:MsGeneratorModel,voltLowDefaultItem:any,voltHightDefaultItem:any,generatorList:any) => {
    const generatorItem = generatorList.find((item:any) => item.bisDefault == true);
    if(voltage <= 0){
        // CHINESET
        // if(item){
        //     item.voltage = voltLowDefaultItem.lvoltage;
        // }
        // CHINESES
        // if(item){
        //     item.voltage = voltLowDefaultItem.lvoltage;
        // }
        if(voltHightDefaultItem){
            item.voltage = voltHightDefaultItem.lvoltage;
        }
        else{
            item.voltage = 0
        }
    }else{
        doSetVoltageGenerator(item,voltage);
    }

    if(generatorItem){
        item.capacity = generatorItem.lcapacity;
    }
    else{
        item.capacity = 0
    }
    doCalcSetValues(item,generatorList);
    item.checkNG = false;
    item.powerFactor = 0.85;
    item.viewResultText = true;
    item.nodeKind = FLOW_NODE_KIND_PQ;
    item.nodeNumber = 0;
    item.perUnitR = 0;
    item.perUnitX = 0;
    item.perUnitK = 0;
    item.perUnitP = 0;
    item.perUnitQ = 0;
    item.perUnitV = 1;
    item.isLead = false;
    item.voltageMagnitude = 1;
    item.voltageAngle = 0;
    item.activePower = 0;
    item.reactivePower =0;
    item.Qmax = item.capacity * 0.6;
    item.Qmin = item.capacity * 0.2;
    item.viewFlowResult = true;
    item.examineComment = "";
    item.seriesed = false;
    item.appointVoltage =  1.0;
}

const doSetVoltageGenerator = (item:MsGeneratorModel,voltage:any) => {
    let oldVolt  = item.voltage;
    item.voltage = voltage;

    // CALCPOINT
    item.calcPoint0.voltage =voltage
    item.calcPoint1.voltage =voltage
    return oldVolt;
}

const doCalcSetValues = (generatorItem:MsGeneratorModel,generatorList:any) => {
    for(const item of generatorList){
        if(generatorItem.capacity <= item.lcapacity || item.lcapacity == -1 ){
            generatorItem.reactance = item.dreactance;
            generatorItem.subReactance = item.dsubReactance;
            generatorItem.constant = item.dconstant;
            generatorItem.subConstant = item.dsubConstant;
        }
    }
}

//CEleGenerator::DoGetValueRatedCurrent
export const doGetValueRatedCurrent = (dVoltage: any, dCapacity: any) => {
    if (dVoltage <= 0) {
        return 0;
    }
    else {
        return dCapacity * 1000 / dVoltage / Math.sqrt(3);
    }
}

export const doGetPerZrGenerator = (control:ControlModel, nFrequency:any, dStdCapacity:any) => {
    let props = control.properties as MsGeneratorModel;
    let percentZ = {
        perRk3: 0,
        perXk3: 0
    };

    percentZ.perRk3 = doGetValueStatorOhmR(nFrequency, props.subReactance, props.constant);
    percentZ.perXk3 = props.subReactance;

    if (props.capacity == 0) {
        percentZ.perRk3 = 0;
        percentZ.perXk3 = 0;
    } else {
        percentZ.perRk3 *= dStdCapacity / props.capacity; 
        percentZ.perXk3 *= dStdCapacity / props.capacity; 
    }
    return percentZ;
}

//CEleGenerator::DoGetTextVoltage
export const doGetTextVoltage = (control:ControlModel,bUnit: boolean = true) => {
    let ret = control.properties.voltage; //DLLGetTextByBeams(lVoltage);
    if (bUnit) {
      ret += "V";
    }
    return ret;
}

//CEleGenerator::IsPointFaultGenerator
export const isPointFaultGenerator = (props:MsGeneratorModel) =>
{
	if (props.fault) {
		return true;
	}
	return false;
}


//CEleGenerator::DoGetTextCapacity
export const doGetTextCapacity = (control:ControlModel,bUnit: boolean = true) => {
    let sText = BeamsNumber(control.properties.capacity, 6).toString();
    if (bUnit) {
        sText += "kVA";
    }
    return sText;
}

//CEleGenerator::DoGetTextRatedCurrent
export const doGetTextRatedCurrent = (control:ControlModel, bUnit: boolean = true) => {
    let dRatedCurrent = doGetValueRatedCurrent(control.properties.voltage, control.properties.capacity);
    let sText = BeamsNumber(dRatedCurrent, 4).toString();
    if (bUnit) {
        sText += 'A';
    }
    return sText;
}

//CEleGenerator::DoGetTextDirectPercentZe
export const doGetTextDirectPercentZe = (control: ControlModel, infoCircuit: any, bUnit:boolean = true) => {
    let props = control.properties as MsGeneratorModel;
    let dPercentZ = doGetValuePercentZe(infoCircuit.frequency, infoCircuit.stdCapacity, props.voltage, props);
    dPercentZ *= props.capacity / infoCircuit.stdCapacity;
    let sText = BeamsNumber(dPercentZ, 4).toString();
    if (bUnit) {
        sText += '%e';
    }
    return sText;
}

//CEleGenerator::DoGetTextDirectPercentZr
export const doGetTextDirectPercentZr = (control: ControlModel, infoCircuit: any, bUnit:boolean = true) => {
    let props = control.properties as MsGeneratorModel;
    let dPercentZ = doGetValuePercentZe(infoCircuit.frequency, infoCircuit.stdCapacity, props.voltage, props);
    let sText = "";
    if (dPercentZ >= 0) {
        sText = BeamsNumber(dPercentZ, 4).toString();
        if (bUnit) {
            sText += '%r';
        }
    } else {
        sText = "????";
    }
    return sText;
}

//CEleGenerator::DoGetTextPointState
export const doGetTextPointState = (props:MsGeneratorModel) =>
{
	let sText = '';

	if (props.fault) {
		sText = 'Yes';
	}
	else {
		sText = 'No';
	}

	return sText;
}

//CEleGenerator::DoGetTextDirectPercentRr
export const doGetTextDirectPercentRr = (control: ControlModel, infoCircuit: any, bUnit:boolean = true) => {
    let props = control.properties as MsGeneratorModel;
    let percentZ = doGetPerZrGenerator(control, infoCircuit.frequency, infoCircuit.stdCapacity);
    let dXR = 0;
    if (percentZ.perRk3 > 0) {
        dXR = percentZ.perXk3 / percentZ.perRk3;
    }

    let dPercentZ = doGetValuePercentZe(infoCircuit.frequency, infoCircuit.stdCapacity, props.voltage, props);
    let dPercentR = dPercentZ / Math.sqrt(1 + dXR * dXR);
    let sText = '';
    if (dPercentR >= 0) {
        sText = BeamsNumber(dPercentR, 4).toString();
        if (bUnit) {
            sText += '%r';
        }
    } else {
        sText = "????";
    }

    return sText;
}

//CEleGenerator::DoGetTextDirectPercentXr
export const doGetTextDirectPercentXr = (control: ControlModel, infoCircuit: any, bUnit:boolean = true) => {
    let props = control.properties as MsGeneratorModel;
    let percentZ = doGetPerZrGenerator(control, infoCircuit.frequency, infoCircuit.stdCapacity);
    let dXR = 0;
    if (percentZ.perRk3 > 0) {
        dXR = percentZ.perXk3 / percentZ.perRk3;
    }

    let dPercentZ = doGetValuePercentZe(infoCircuit.frequency, infoCircuit.stdCapacity, props.voltage, props);
    let dPercentR = dPercentZ / Math.sqrt(1 + dXR * dXR);
    let dPercentX = dPercentR * dXR;
    let sText = '';
    if (dPercentX >= 0) {
        sText = BeamsNumber(dPercentX, 4).toString();
        if (bUnit) {
            sText += '%r';
        }
    } else {
        sText = "????";
    }

    return sText;
}

//CEleGenerator::DoGetTextXR
export const doGetTextXR = (control: ControlModel, nFrequency: any, dStdCapacity: any) => {
    let percentZ = doGetPerZrGenerator(control, nFrequency, dStdCapacity);
    let sText = '';
    if (percentZ.perRk3 <= 0) {
        sText = BeamsNumber(0, 4).toString();
    } else {
        sText = BeamsNumber(percentZ.perXk3/ percentZ.perRk3, 4).toString();
    }
    return sText;
} 

//CEleSource::DoGetTextDirectIk3sym
export const doGetTextDirectIk3sym = (control:ControlModel, bUnit: boolean = true ) => {
    let dIsymDirect = doGetIksymDirectMG(control.properties.calcPoint0);
    let sText = '';

    if (dIsymDirect >= 0) {
        sText = BeamsNumber(dIsymDirect / 1000,4).toString();
        if (bUnit) {
            sText += 'kAsym';
        }
    } else {
      sText = "????"
    }
    return sText;
  }

//CEleGenerator::DoGetRefV1
export const doGetRefV1 = (mode: any, control:ControlModel, infoCircuit:any,bSociety:boolean) => {

    //CEleGenerator::DoGetRefV1Left  sRefV1Left
    let labelTexts = [{
        value: control.properties.refNo,
        color: "#f800f8",
    }];
  
    if (!control.properties.viewResultText)
    {
      return labelTexts;
    }
    
    if (bSociety){
        labelTexts.push({
            value: doGetTextVoltage(control),
            color: "blue",
        });
        return labelTexts;
    }
  
    switch(mode) {
      case ProcessMode.DRAWING:
      case ProcessMode.CHECKING:
        //+= DoGetTextVoltage()
        labelTexts.push({
          value: doGetTextVoltage(control),
          color: "blue",
        });
        
        // += doGetTextCapacity() + ',' + doGetTextRatedCurrent
        labelTexts.push({
          value: doGetTextCapacity(control) + ',' + doGetTextRatedCurrent(control),
          color: "blue",
        });

        // += doGetTextDirectPercentZe
        labelTexts.push({
          value: doGetTextDirectPercentZe(control, infoCircuit),
          color: "blue",
        });

        labelTexts.push({
            value: 'X/R' + '=' + doGetTextXR(control, infoCircuit.frequency, infoCircuit.stdCapacity),
            color: "blue"
          });
        break;
      case ProcessMode.PERCENT_Z:
        //+= 'X/R=' + DoGetTextXR()
        labelTexts.push({
            value: 'X/R' + '=' + doGetTextXR(control, infoCircuit.frequency, infoCircuit.stdCapacity),
            color: "blue"
        });

        labelTexts.push({
            value: doGetTextDirectPercentZr(control, infoCircuit)
                   + '='
                   + doGetTextDirectPercentRr(control, infoCircuit, false)
                   + '+j'
                   + doGetTextDirectPercentXr(control, infoCircuit, false)
                   ,
            color: "blue"
        });
        break;
      case ProcessMode.CALCULATION:
        //+= DoGetTextVoltage()
        labelTexts.push({
          value: doGetTextVoltage(control),
          color: "blue",
        });
        
        if (!control.properties.fault){
          break;
        }
        
        // += _T(" ")
        labelTexts.push({
          value: ' ',
          color: "blue",
        });

        // += 'I"k3' + '通過' + '=' + DoGetTextDirectIk3sym()
        labelTexts.push({
          value: 'I"k3通過=' + doGetTextDirectIk3sym(control),
          color: "blue",
        });
  
        break;
      case ProcessMode.VOLTDROP:
        labelTexts.push({
            value: doGetTextVoltage(control),
            color: "blue"
        });
        break;
      case ProcessMode.HARMONIC:
        labelTexts.push({
            value: doGetTextVoltage(control),
            color: "blue"
        });
        break;
      case ProcessMode.POWER_FLOW:
        break;
      default:
        break;
    }
  
    return labelTexts;
  }

  //CEleGenerator::DoGetTextDirectIk3peak
export const doGetTextDirectIk3peak = (props: ControlModel, bUnit: boolean = true) =>
{
	let dIpeakDirect = doGetIkpeakDirectMG(props.properties.calcPoint0);
	let sText;
	if (dIpeakDirect >= 0) {
		sText = BeamsNumber(dIpeakDirect / 1000, 4).toString();
		if (bUnit) {
			sText += 'kApeak';
		}
	}
	else {							// 短絡電流が正しく計算されなかったとき
		sText = '????';
	}
	return sText;
}

//CEleGenerator::DoGetTextDirectIk3asym
export const doGetTextDirectIk3asym = (props:ControlModel, bUnit:boolean = true) =>
{
	let dIasymDirect = doGetIkasymDirectMG(props.properties.calcPoint0);
	let sText;
	if (dIasymDirect >= 0) {
		sText = BeamsNumber(dIasymDirect / 1000, 4).toString();
		if (bUnit) {
			sText += 'kAasym';
		}
	}
	else {							// 短絡電流が正しく計算されなかったとき
		sText = '????';
	}
	return sText;
}

//CEleGenerator::DoGetTextTotalIk3sym
export const doGetTextTotalIk3sym = (props:ControlModel, bUnit:boolean = true) =>
{
	let dIsymTotal = doGetIksymTotalMG(props.properties.calcPoint0);
	let sText = '';
	if (dIsymTotal >= 0) {
		sText = BeamsNumber(dIsymTotal / 1000, 4).toString();
		if (bUnit) {
			sText += 'kAsym';
		}
	}
	else {							// 短絡電流が正しく計算されなかったとき
		sText = '????';
	}
	return sText;
}

//CEleGenerator::DoGetTextTotalIk3peak
export const doGetTextTotalIk3peak = (props:MsGeneratorModel, bUnit:boolean = true) =>
{
	let dIpeakTotal = doGetIkpeakTotalMG(props.calcPoint0);
	let sText = '';
	if (dIpeakTotal >= 0) {
		sText = BeamsNumber(dIpeakTotal / 1000, 4).toString();
		if (bUnit) {
			sText += 'kApeak';
		}
	}
	else {							// 短絡電流が正しく計算されなかったとき
		sText = '????';
	}
	return sText;
}

//CEleGenerator::DoGetTextTotalIk3asym
export const doGetTextTotalIk3asym = (props:MsGeneratorModel, bUnit:boolean = true) =>
{
    let dIasymTotal = doGetIkasymTotalMG(props.calcPoint0);
	let sText = '';
	if (dIasymTotal == 0) {
		sText = '∞';
	}
	else if (dIasymTotal > 0) {
		sText = BeamsNumber(dIasymTotal / 1000, 4).toString();
		if (bUnit) {
			sText += 'kAasym';
		}
	}
	else {							// 短絡電流が正しく計算されなかったとき
		sText = '????';
	}
	return sText;
}

//CEleGenerator::DoGetTextTotalPercentZr
export const doGetTextTotalPercentZr = (props:MsGeneratorModel, bUnit:boolean = true) =>
{
	let dPerZ = doGetPerZTotalMG(props.calcPoint0).dPercentZ;
	let sText = '';
	if (dPerZ >= 0) {
		sText = BeamsNumber(dPerZ, 4).toString();
		if (bUnit) {
			sText += '%r';
		}
	}
	else {							// 短絡電流が正しく計算されなかったとき
		sText = '????';
	}
	return sText;
}

//CEleGenerator::DoGetTextFlowsResult
export const doGetTextFlowsResult = (props:MsGeneratorModel, processMode:number, nDirect:number, bSociety:boolean = false) =>
{
	let sRefV1: string[] = [];

	if (bSociety) {
		return sRefV1;
	}

	switch (processMode) {
	case MS_MODE_DRAWING:
	case MS_MODE_CHECKING:
	case MS_MODE_PERCENT_Z:
	case MS_MODE_CALCULATION:
	case MS_MODE_VOLTDROP:
	case MS_MODE_HARMONIC:
		break;
	case MS_MODE_POWER_FLOW:
		if (props.activePower !== 0 && props.reactivePower !== 0) {
			if (nDirect == DIRECT_NONE) {
				sRefV1.push(BeamsNumber(Math.abs(props.activePower),5).toString() + 'kW')
				sRefV1.push(BeamsNumber(Math.abs(props.reactivePower),5).toString() + 'kvar')
			}
			else if (nDirect == BOTTOM_CENTER || nDirect == RIGHT_CENTER) {
				sRefV1.push(BeamsNumber(Math.abs(props.activePower),5).toString() + 'kW')
				let sTemp = ''
				if (props.activePower > 0) {
					if (props.reactivePower < 0) {
						sTemp += "-j";
					}
					else {
						sTemp += "+j";
					}
				}
				else {
					if (props.reactivePower < 0) {
						sTemp += "+j";
					}
					else {
						sTemp += "-j";
					}
				}
				sRefV1.push(sTemp + BeamsNumber(Math.abs(props.reactivePower),5).toString() + 'kvar')
			}
		}
		break;
	}

	return sRefV1;
}