import { ControlModel, MsLoadModel, ProcessMode } from "../models/Index";
import { BeamsNumber } from "./FormatNumber";
import { DIRECT_NONE, LEFT_CENTER, MS_OUTSIDE_NEUTRAL, MS_OUTSIDE_OUTSIDE, MS_SEAT_MAIN, MS_SEAT_T, TOP_CENTER, VALUE_POWER_MIN } from "../models/Constants"
import { MS_MODE_CALCULATION, MS_MODE_CHECKING, MS_MODE_DRAWING, MS_MODE_HARMONIC, MS_MODE_PERCENT_Z, MS_MODE_POWER_FLOW, MS_MODE_VOLTDROP } from "../statics";

//CEleLoad::DoGetTextLoadCurrent
export const doGetTextLoadCurrent = (props:MsLoadModel, bUnit:boolean = true) =>
{
	let sText = BeamsNumber(props.loadCurrent, 5).toString();
	if (bUnit) {
		sText += 'A';
	}
	return sText;
}

//CEleLoad::DoGetTextPowerFactor
export const doGetTextPowerFactor = (props:MsLoadModel, bUnit:boolean = true) =>
{
	let sText = BeamsNumber(props.powerFactor, 5).toString();

	if (bUnit) {
		if (props.leadOrLag) {
			sText += '(進み)';
		}
		else {
			sText += '(遅れ)';
		}
	}

	return sText;
}

//CEleLoad::DoGetTextImproveAfterPF
export const doGetTextImproveAfterPF = (props:MsLoadModel) =>
{
	return BeamsNumber(props.improveAfterPF, 5).toString();
}

//CEleLoad::DoGetValueLoadCapacity
export const doGetValueLoadCapacity = (props:MsLoadModel) =>
{
	return (props.loadFactor * props.loadCurrent * props.voltage);
}

//CEleLoad::DoGetTextCapacitorCapacity
export const doGetTextCapacitorCapacity = (props:MsLoadModel, bUnit:boolean = true) =>
{
	let dCapacity = doGetValueLoadCapacity(props) * props.powerFactor;
	if (!props.sPhase) {
		dCapacity *= Math.sqrt(3.0);
	}

	let dtanBefore = Math.sqrt(1 - props.powerFactor * props.powerFactor) / props.powerFactor;
	let dtanAfter = 0;
	if (props.improveAfterPF != 0) {
		dtanAfter = Math.sqrt(1 - props.improveAfterPF * props.improveAfterPF) / props.improveAfterPF;
	}

	let dCapacitorCapacity = dCapacity * (dtanBefore - dtanAfter) / 1000;

	if (Math.abs(dCapacitorCapacity) < VALUE_POWER_MIN) {
		dCapacitorCapacity = 0;
	}
	let sText = BeamsNumber(dCapacitorCapacity, 5).toString();

	if (bUnit) {
		sText += 'kvar';
	}

	return sText;
}

//TODO check m_listHarmonic base current
//CEleLoad::DoGetTextBaseCurrentTotal
export const doGetTextBaseCurrentTotal = (props:MsLoadModel, bUnit:boolean = true) =>
{
	let dBaseCurrent = 0;

    for(let item of props.harmonicList)
    {
        dBaseCurrent += item.baseCurrent;
    }

	let sText = BeamsNumber(dBaseCurrent, 5).toString();
	if (bUnit) {
		sText += 'pu';
	}
	return sText;
}

//CEleLoad::DoGetText6PulseCapacity
export const doGetText6PulseCapacity = (props:MsLoadModel, bUnit:boolean = true) =>
{
	let dTotalCapacity = 0;
	let dTotalEquivalent = 0;

    for(let item of props.harmonicList)
    {
        dTotalCapacity += item.capacity * item.number;
        dTotalEquivalent += item.capacity * item.number * item.coEfficient;
    }

	let sText = BeamsNumber(dTotalEquivalent, 4).toString();
	if (bUnit) {
		sText += 'kVA';
	}
	return sText;
}

//CEleLoad::DoGetTextVoltage
export const doGetTextVoltage = (props:MsLoadModel, nPartKind:number, bUnit:boolean = true) =>
{
	let sText = '';
	if (props.sPhase) {
		switch (nPartKind) {
		case MS_OUTSIDE_NEUTRAL:	
			sText = (props.voltage / 2).toString();
			break;
		case MS_OUTSIDE_OUTSIDE:	
			sText = props.voltage.toString();
			break;
		case MS_SEAT_MAIN:			
			sText = props.voltage.toString();
			break;
		case MS_SEAT_T:			
			sText = props.voltage.toString();
			break;
		}
	}
	else {
		sText = props.voltage.toString();
	}
	if (bUnit) {
		sText += 'V';
	}
	return sText;
}

//CEleLoad::DoGetRefV1
export const doGetRefV1 = (mode:any, props:MsLoadModel, bSociety:boolean) =>
{
	//CEleLoad::DoGetRefV1Left  sRefV1Left
	let labelTexts = [{
		value: props.refNo,
		color: "#f800f8",
	}];

	if (!props.viewResultText || (mode != ProcessMode.DRAWING && !props.supply))
    {
      return labelTexts;
    }

    if(bSociety) {
        return labelTexts;
    }

    switch(mode) {
        case ProcessMode.DRAWING:
        case ProcessMode.CHECKING:
            //LoadCurrent
            labelTexts.push({
                value: doGetTextLoadCurrent(props),
                color: "blue",
            });
            break;
        case ProcessMode.PERCENT_Z:
        case ProcessMode.CALCULATION:
            break;
        case ProcessMode.VOLTDROP:
            //LoadCurrent
            labelTexts.push({
                value: doGetTextLoadCurrent(props),
                color: "blue",
            });

            
            //力率=doGetTextPowerFactor()
            labelTexts.push({
                value: '力率='
                + doGetTextPowerFactor(props),
                color: "blue",
            });

            break;
        case ProcessMode.IMPROVE_PF:
            //LoadCurrent
            labelTexts.push({
                value: doGetTextLoadCurrent(props),
                color: "blue",
            });

            if(props.calcImprovePF) {
                //負荷力率=doGetTextPowerFactor()
                labelTexts.push({
                    value: '負荷力率='
                    + doGetTextPowerFactor(props),
                    color: "blue",
                });

                //目標力率=doGetTextImproveAfterPF()
                labelTexts.push({
                    value: '目標力率='
                    + doGetTextImproveAfterPF(props),
                    color: "blue",
                });

                 //Qc=doGetTextCapacitorCapacity()
                 labelTexts.push({
                    value: 'Qc='
                    + doGetTextCapacitorCapacity(props),
                    color: "blue",
                });
            }
            else {
                  //力率=doGetTextPowerFactor()
                  labelTexts.push({
                    value: '力率='
                    + doGetTextPowerFactor(props),
                    color: "blue",
                });
            }
            break;
        case ProcessMode.HARMONIC:
              //CHINESE 
            //  //6ﾊﾟﾙｽ等価容量
            //  labelTexts.push({
            //     value: '6ﾊﾟﾙｽ等価容量',
            //     color: "blue",
            // });

            // //doGetTextBaseCurrentTotal
            // labelTexts.push({
            //     value: doGetTextBaseCurrentTotal(props),
            //     color: "blue",
            // });

            //6ﾊﾟﾙｽ等価容量
            labelTexts.push({
                value: '6ﾊﾟﾙｽ等価容量',
                color: "blue",
            });

             //doGetText6PulseCapacity
             labelTexts.push({
                value: doGetText6PulseCapacity(props),
                color: "blue",
            });
            break;
        case ProcessMode.POWER_FLOW:
            break;
        default:
            break;
    }

    return labelTexts;
}

//CEleLoad::DoGetTextFlowsResult
export const doGetTextFlowsResult = (props:MsLoadModel, processMode:any, nDirect:number, bSociety:boolean = false) =>
{
	let sRefV1:string[] = [];

	if (bSociety) {
		return sRefV1;
	}

	switch (processMode) {
	case MS_MODE_DRAWING:
	case MS_MODE_CHECKING:
	case MS_MODE_PERCENT_Z:
	case MS_MODE_CALCULATION:
	case MS_MODE_VOLTDROP:
	case MS_MODE_HARMONIC:
		break;
	case MS_MODE_POWER_FLOW:
		if (props.activePower !==0 && props.reactivePower !==0) {
			if (nDirect == DIRECT_NONE) {
				sRefV1.push(BeamsNumber(Math.abs(props.activePower),5).toString() + 'kW');
				sRefV1.push(BeamsNumber(Math.abs(props.reactivePower),5).toString() + 'kvar');
			}
			else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
				sRefV1.push(BeamsNumber(Math.abs(props.activePower),5).toString() + 'kW');
				let sTemp = ''
				if (props.reactivePower > 0) {
					sTemp += "+j";
				}
				else {
					sTemp += "-j";
				}
				sRefV1.push(sTemp + BeamsNumber(Math.abs(props.reactivePower),5).toString() + 'kvar');
			}
		}
		break;
	}

	return sRefV1;
}