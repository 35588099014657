
import { LoadingButton } from "@mui/lab";
import { Autocomplete, Box, Button, Checkbox, CircularProgress, FormControlLabel, Grid, Stack, Tab, Tabs, TextField, Typography } from "@mui/material";
import { useRef, useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Path as RoutePath } from "../../../path";
import { ApplicationState, actionCreators, selectors } from "../../../store";
import FetchHelper from "../../FetchHelper";
import AlertDialog from "../../common/AlertDialog";
import { SearchItemForm } from "../BreakType/BreakTypeList";
import { BreakGroupModel, ManufactureModel, BreakTypeModel } from "../../../models/breakModel";
import BreakGroupGrid, { BreakTypeGrid } from "../BreakType/BreakTypeGrid";
// -------------------------
// FETCH ID
const GET_BREAKGROUP_BY_ID = "GET_BREAKGROUP_BY_ID";
const GET_BREAKGROUP = "GET_BREAKGROUP";
const CREATE_BREAKGROUP = "CREATE_BREAKGROUP";
const UPDATE_BREAKGROUP = "UPDATE_BREAKGROUP";
const DELETE_BREAKTYPE = "DELETE_BREAKTYPE"

// 初期値設定
const initialValue: BreakGroupModel = {
    lGroupID: 0,
    nKindFlag: null,
    sGroupName: "",
    bIsDefault: false,
    mv3breakManufacture: {
        lManufactureID: 0,
        bIsDefSource: false,
        bIsDefHVCB: false,
        bIsDefLVCB: false,
        bIsDefThermal: false,
        bIsDefFuse: false,
        bIsDef2E: false,
        sManuName: "",
        sManuKana: ""
    }
}

// -------------
//Tabs------------------
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `oilManagement-tab-${index}`,
        'aria-controls': `oilManagement-tabpanel-${index}`,
    };
}

const nKindFlagOption = [
    {
        label: "高圧遮断器",
        value: 1
    },
    {
        label: "低圧遮断器",
        value: 2
    },
    {
        label: "サーマルリレー",
        value: 3
    },
    {
        label: "ヒューズ",
        value: 4
    },
    {
        label: "2E",
        value: 5
    },
    {
        label: "配変OCR",
        value: 6
    },
]

// ----------------
// Props
export type BreakGroupEditProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export const BreakGroupEdit = (props: BreakGroupEditProps) => {
    const {
        userId,
        fetchUpdate,
        onCreate, onCreateError,
        onUpdate, onUpdateError,
        onSuccess, onError,
        onDeleteType, onSuccessDeleteType, onErrorDeleteType,
        onSearchById,
    } = props;
    const { register, control, handleSubmit, setValue, getValues } = useForm<BreakGroupModel>({
        criteriaMode: "all",
        defaultValues: initialValue,
    })
    type MyParams = {
        id: string;
        mode: string;
    };

    let { id, mode } = useParams<keyof MyParams>() as MyParams;
    const location = useLocation();
    let lManufactureID = (location.state) ? location.state.lManufactureID : undefined;
    const object = {
        requestPrms:{
            id:id    
        },
        userId: userId,
        requestType :"breakGroupGetById",
    }
    let sManuName = (location.state) ? location.state.sManuName : undefined;
    let navigate = useNavigate();
    const [selectTab, setSelectTab] = useState(0);
    const [loadingFlag, setLoadingFlag] = useState(mode === "edit" ? true : false);
    const gridRef = useRef() as any;
    const [colState, setColState] = useState<any>();
    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");
    const [openedDeleteDialog, setOpenedDeleteDialog] = useState<boolean>(false);
    const [mv3breakBreakTypeList, setMv3breakBreakTypeList] = useState<BreakTypeModel>();
    const [idDelete, setIdDelete] = useState<string>("");
    let tab = location.state && location.state.tab;
   
    const handleSuccess = (data: any) => {
        console.log('>>>> handle Successs');
        setValue("mv3breakManufacture.sManuName", data.data.smanuName);
        setValue("lGroupID", data.data.lgroupID);
        setValue("bIsDefault", Boolean(data.data.bisDefault));
        setValue("sGroupName", data.data.sgroupName);
        setValue("nKindFlag", data.data.nkindFlag);
        setValue("mv3breakManufacture.lManufactureID", data.data.lmanufactureID);
        setLoadingFlag(false);
        if (data.breakTypeLists.length > 0) {
            setMv3breakBreakTypeList(data.breakTypeLists);
        }
        setLoadingFlag(false);
        if (tab !== undefined && tab !== null) {
            setSelectTab(tab)
        }
    }

    // 検索エラー時
    const handleError = (success: boolean, data: any, error: any) => {
        console.log('>>>> handle Error');
        gridRef.current.hideOverlay();
    }

    useEffect(() => {
        if (mode === "add")
            setValue("mv3breakManufacture.sManuName", sManuName);
    }, [])

    const tabHandleChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectTab(newValue);
        if (selectTab === 1) {
            gridRef.current.setRowData(mv3breakBreakTypeList);
        }
        navigate(".", {
            replace: true,
            state: {
                tab: newValue,
                sManuName: sManuName,
                lManufactureID: lManufactureID,
            },
        });
    }

    // 検索実行
    const handleEditSubmit = (data: BreakGroupModel) => {
        setLoadingFlag(true);
        if (mode === "edit") {
            onUpdate({
                "userId": userId,
                "requestPrms":{
                    lGroupID: getValues("lGroupID"),
                    bIsDefault: getValues("bIsDefault"),
                    nKindFlag: getValues("nKindFlag"),
                    sGroupName: getValues("sGroupName"),
                    lManufactureID: getValues("mv3breakManufacture.lManufactureID")
                }

            });
        } else if (mode === "add") {    
            onCreate({
                "userId": userId,
                "requestPrms":{
                    bIsDefault: getValues("bIsDefault"),
                    nKindFlag: getValues("nKindFlag"),
                    sGroupName: getValues("sGroupName"),
                    lManufactureID: lManufactureID
                }
            })
        }
        // setLoadingFlag(false);
    }

    // Submit後のAPI呼び出し成功時
    const handleSubmitSuccess = (success: boolean, data: any, error: any) => {
        onSuccess();
        // if (lManufactureID !== undefined && lManufactureID !== null) {
        //     navigate(-1);
        // }
        if (mode === "add") {
            navigate(RoutePath.BreakGroupEdit + `/edit/${data.data.lgroupID}`, {replace: true})
        }
        setLoadingFlag(false)
    }

    //削除の処理
    const handleShowDetailPage = (data: any) => {
        navigate(RoutePath.BreakTypeEdit + `/edit/${data}`,
            {
                state: {
                    lGroupId: id,
                    lManufactureID: getValues("mv3breakManufacture.lManufactureID"),
                }
            })
    }

    // Submit後のAPI呼び出しエラー時
    const handleSubmitError = (success: boolean, data: any, error: any) => {
        onError();
        setLoadingFlag(false);
    }

    const handleSuccessUpdate = (success: boolean, data: any, error: any) => {
        onSuccess();
        if (mode === "edit") {
            navigate(RoutePath.BreakGroupEdit + `/edit/${data.data.lgroupID}`, {replace: true})
        }
        setLoadingFlag(false);
        // if (lManufactureID !== undefined && lManufactureID !== null) {
        //     navigate(-1);
        // }
    }

    const handleErrorUpdate = (success: boolean, data: any, error: any) => {
        setLoadingFlag(false);
        onUpdateError();
    }

    //削除の処理
    const handleDelete = (data: any) => {
        setIdDelete(data);
        const msg = "ID: " + data + "を削除します。"
        setTitle("機器グループ情報削除");
        setMessage(msg);
        setOpenedDeleteDialog(true);
    }

    // 削除確認ダイアログでOKボタンのイベントを受け取って、APIに送信する
    const handleDeleteOK = () => {
        setOpenedDeleteDialog(false);
        setLoadingFlag(true);
        onDeleteType({
            userId: userId,
            requestPrms:{
              id :idDelete
        }
        });
        setIdDelete("");
    }
    // 削除確認ダイアログでCancelボタンのイベントを受け取って、ダイアログを無効にする
    const handleDeleteCancel = () => {
        setIdDelete("");
        setOpenedDeleteDialog(false);
    }

    // キャンセル
    const handleCancel = () => {
        if (location.key !== "default")
            navigate(-1);
        else
            navigate(RoutePath.BreakGroupList);
    }

    const handleSuccessDelete = (data: any) => {
        console.log('>>> handleSuccessDelete');
        setLoadingFlag(false);
        onSuccessDeleteType();
        onSearchById(
            {
            userId: userId,
            requestPrms:{
              id :id
            }
            }
        );
    };

    const handleSuccessRefresh = (data: any) => {
        setMv3breakBreakTypeList(data.breakTypeLists);
        gridRef.current.setRowData(data.breakTypeLists);
    }

    // //分析一覧を表示
    // const resetGrid = () => {
    //     if (selectTab === 1) {
    //         gridRef.current.setRowData(mv3breakBreakTypeList);
    //     }
    // }
    // useEffect(resetGrid, [selectTab]);

    //入力欄背景
    const divStyles = {
        backgroundColor: 'white',
        border: '1px solid #bebebe',
        boxShadow: '0 0 5px #fff, 0 0 5px #bebebe, 0 0 1px #aaa',
        padding: '20px 40px',
        marginTop: '40px',
    }
    //分析一覧を表示
    const handleAdd = () => {
        navigate(RoutePath.BreakTypeEdit + `/add`,
            {
                state: {
                    lGroupId: getValues("lGroupID"),
                    lManufactureID: getValues("mv3breakManufacture.lManufactureID"),
                    sManuName: getValues("mv3breakManufacture.sManuName"),
                    sGroupName: getValues("sGroupName")
                }
            })
    }
    //分析一覧を表示
    const resetGrid = () => {
        if (selectTab === 1) {
            gridRef.current.setRowData(mv3breakBreakTypeList);
        }
    }
    useEffect(resetGrid, [selectTab]);
    return (
        <>
            {mode === "edit" &&
                <FetchHelper fetchId={GET_BREAKGROUP}
                    url={`/master/break_group/get`}
                    method="POST"
                    params={object}
                    json={true}
                    multipart={false}
                    request={true}
                    onComplete={(success, data, error) => {
                        success ? handleSuccess(data) : handleError(success, data, error);
                    }}
                />
            }
            {loadingFlag && (
                <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1500, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                    <CircularProgress />
                </div>
            )}
            <div style={{ height: "100%", width: "100%", marginTop: "10px" }}>
                <Stack direction="row" justifyContent="flex-start" alignItems="center" sx={{}} style={{ margin: '18px', color: 'MenuText' }}>
                    <Typography variant="h4">
                        機器グループ詳細
                    </Typography>
                </Stack>
                <form onSubmit={handleSubmit(handleEditSubmit)}>
                    <Stack direction='row'>
                        <div style={{ top: "100px", position: "absolute", zIndex: 1200 }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                {
                                    mode === "edit"
                                        ?
                                        <Tabs value={selectTab} onChange={tabHandleChange} aria-label="oilManagementDetailTabs">
                                            <Tab label='機器グループ' {...a11yProps(0)} />
                                            <Tab label='型式' {...a11yProps(1)} />
                                        </Tabs>
                                        :
                                        <Tabs value={selectTab} onChange={tabHandleChange} aria-label="oilManagementDetailTabs">
                                            <Tab label='機器グループ' {...a11yProps(0)} />
                                        </Tabs>
                                }
                            </Box>
                        </div>
                    </Stack>
                    {/* 機器グループ */}
                    <TabPanel value={selectTab} index={0}>
                        <div style={divStyles}>
                            <Typography variant="h6">
                                機器グループ
                            </Typography>

                        <Stack sx={{maxHeight:"calc(100vh)" ,overflow: 'auto' }} >
                            
                                <Grid container xs={12} spacing={3} direction={'row'}>
                                    <Grid item xs={12} mt={3}>
                                        <Controller
                                            control={control}               
                                             name="mv3breakManufacture.sManuName"
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    type="text"
                                                    label="メーカー名"
                                                    size="small"
                                                    disabled={true}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            control={control}
                                            name="nKindFlag"
                                            render={({ field: { value } }) => (
                                                <Autocomplete
                                                    autoHighlight
                                                    value={nKindFlagOption.find(options => options.value == value)??null}
                                                    options={nKindFlagOption}
                                                    getOptionLabel={(option) => option.label}
                                                    fullWidth
                                                    renderInput={(params) => <TextField {...params} label="機器種類" size="small" />}
                                                    onChange={(_, data) => {
                                                        setValue("nKindFlag", Number(data?.value))
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={2} ml={3}>
                                        <Controller
                                            name="bIsDefault"
                                            control={control}
                                            render={({ field }: any) => (
                                                <FormControlLabel {...field} control={<Checkbox />} checked={field.value} label="デフォルト" labelPlacement="start" />
                                            )}
                                        />
                                    </Grid>
                                <Grid item xs={12} mt={3} spacing={4} >
                                <Controller
                                    control={control}
                                    name="sGroupName"
                                    render={({ field }) => (
                                        <TextField
                                            fullWidth
                                            {...field}
                                            type="text"
                                            label="グループ名"
                                            size="small"
                                        />
                                    )}
                                />
                                </Grid>
                              
                                </Grid>

                           
                            {/* <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3}>
                                <Controller
                                    control={control}
                                    name="sGroupName"
                                    render={({ field }) => (
                                        <TextField
                                            fullWidth
                                            {...field}
                                            type="text"
                                            label="グループ名"
                                            size="small"
                                        />
                                    )}
                                />
                            </Stack> */}
                        </Stack>
                        </div>
                    </TabPanel>
                    {/* 機器グループ */}
                    <TabPanel value={selectTab} index={1}>
                        <div style={divStyles}>
                            <Stack direction="row" display='flex' justifyContent='space-between'>
                                <div><Typography variant="h6" >型式</Typography></div>
                                <div >
                                    <Stack direction='row' display='flex' spacing={2}>
                                        <Stack>
                                            <Button fullWidth color="inherit" variant="contained" onClick={() => handleAdd()}>型式の追加</Button>
                                        </Stack>
                                    </Stack>
                                </div>
                            </Stack>
                            <Stack spacing={2} sx={{ mt: 1 }} >
                                <BreakTypeGrid
                                    ref={gridRef}
                                    colState={colState}
                                    onChangeSearchCondition={() => { }}
                                    keyword={""}
                                    onShowDetails={handleShowDetailPage}
                                    onDelete={handleDelete}
                                    heightForm="365"
                                    checkEdit={true}
                                    mode="edit"
                                />
                                {openedDeleteDialog ?
                                    <AlertDialog title={title} message={message} isOpen={true} onOK={handleDeleteOK} onCancel={handleDeleteCancel} /> :
                                    <div />
                                }
                            </Stack>
                        </div>
                    </TabPanel>
                    <Stack direction='row' justifyContent="flex-end" spacing={2} sx={{ mt: 0, mr: 3 }}>
                        <LoadingButton variant="contained" type="submit" loading={loadingFlag}>保存</LoadingButton>
                        <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                    </Stack>
                </form>
            </div>
            <FetchHelper fetchId={UPDATE_BREAKGROUP} onComplete={(success, data, error) => success ? handleSuccessUpdate(success, data, error) : handleErrorUpdate(success, data, error)} />
            <FetchHelper fetchId={CREATE_BREAKGROUP} onComplete={(success, data, error) => success ? handleSubmitSuccess(success, data, error) : handleSubmitError(success, data, error)} />
            <FetchHelper fetchId={DELETE_BREAKTYPE} onComplete={(success, data, error) => success ? handleSuccessDelete(data) : () => {
                setLoadingFlag(false);
                onError();
            }} />
            <FetchHelper fetchId={GET_BREAKGROUP_BY_ID} onComplete={(success, data, error) => success ? handleSuccessRefresh(data) : onError()} />
        </>
    );

};
const mapStateToProps = (state: ApplicationState) => ({
    userId: state.app.user?.userId,
    fetchUpdate: selectors.getFormState(
        state,
        GET_BREAKGROUP
    ),
});

const mapDispatchToProps = (dispatch: any) => {
    return {
        onCreate: (data: any) => dispatch(actionCreators.fetch(CREATE_BREAKGROUP, `/master/break_group/add`, "POST", data,false, true)),
        onUpdate: (params: { [key: string]: any }) => dispatch(actionCreators.fetch(UPDATE_BREAKGROUP, `/master/break_group/modify`, "POST", params,false, true)),
        onDeleteType: (id: any) => dispatch(actionCreators.fetch(DELETE_BREAKTYPE, `/master/break_type/delete`, "POST", id,false,true)),
        onSearchById: (params: any) => dispatch(actionCreators.fetch(GET_BREAKGROUP_BY_ID, `/master/break_group/get`, 'POST', params,false,true)),
        onSearch: (params: { [key: string]: any }) => dispatch(actionCreators.fetch(GET_BREAKGROUP, `/master/break_group/gets`, "POST", params, false, true)),
        onSuccess: () =>
            dispatch(actionCreators.showMessage({
                type: "info",
                title: "機器グループ更新",
                body: "機器グループを更新しました。",
            })),
        onError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "検索",
                body: "検索できません。",
            })),
        onCreateError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "機器グループ登録",
                body: "機器グループを登録出来ませんでした。",
            })),
        onUpdateError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "機器グループ編集",
                body: "機器グループを更新出来ませんでした。",
            })),
        onSuccessDeleteType: () =>
            dispatch(actionCreators.showMessage({
                type: "info",
                title: "型式削除",
                body: "型式を削除しました。",
            })),
        onErrorDeleteType: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "型式削除",
                body: "型式を削除できませんでした。",
            })),
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BreakGroupEdit as any);
