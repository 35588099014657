import { Auth } from "aws-amplify";
import axios from "axios"
import { store } from "../";
import { SessionExpiredAction } from "../store/AppStore";
import { redirectMaintenancePage } from "../components/CallApi";

export const GetDetailsData = async (
  elementType: string,
  baseData: any,
  from: any
) => {
  let token = "";
  try {
    const session = await Auth.currentSession()
    token = session.getIdToken().getJwtToken()
  } catch (error) {
    store.dispatch({
      type: "SESSION_EXPIRED",
    } as SessionExpiredAction);
  }
  const userId = store.getState().app.user?.userId;
  let params = {
    userId: userId,
    elementType: elementType,
    manufacturerID: baseData.manufacturerID,
  }
  // get index
  let index = 1
  if (from.endsWith("base.kindID")) index = 2
  if (from.endsWith("base.typeID")) index = 3
  if (from.endsWith("base.opeKindID")) index = 4
  if (from.endsWith("base.ratedRangeID")) index = 5
  if (from.endsWith("base.ratedID")) index = 6

  // add params
  if (index >= 2) params = { ...params, ...{ kindID: baseData.kindID } }
  if (index >= 3) params = { ...params, ...{ typeID: baseData.typeID } }
  if (index >= 4) params = { ...params, ...{ opeKindID: baseData.opeKindID } }
  if (index >= 5) params = { ...params, ...{ ratedRangeID: baseData.ratedRangeID } }
  if (index >= 6) params = { ...params, ...{ ratedID: baseData.ratedID } }

  let url = process.env.REACT_APP_APIGW_URL + "/diagram/get-details"
  try {
    let responseData = await axios.post(url, params, { headers: { 'Authorization': token } })
    if (responseData.status === 200 && responseData.data.resultCode === 0) 
      return responseData.data.details
    return null
  } catch (err: any) {
    if (err.response && err.response.status === 503){
      redirectMaintenancePage(err.response);
    }
    return null
  }
}