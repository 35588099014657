import { Button, CircularProgress, Grid, Stack, TextField, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import { useForm } from 'react-hook-form';
import { connect } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { PageData } from "../../../models/Index";
import { Path as RoutePath } from "../../../path";
import { ApplicationState, FetchState, actionCreators, selectors } from "../../../store";
import FetchHelper from '../../FetchHelper';
import { BreakElementGrid } from './BreakElementGrid';
import AlertDialog from "../../common/AlertDialog";

// -----------------
// FETCH ID
const SEARCH_BREAK_ELEMENT = "SEARCH_BREAK_ELEMENT"
const DELETE_BREAK_ELEMENT = "DELETE_BREAK_ELEMENT"

// 検索フォーム
export interface SearchItemForm {
    "target": string;
    "limit": number;
    "page": number;
}

// 初期値設定
const initialValue: SearchItemForm = {
    target: '',
    limit: 50,
    page: 1,
}

const initialPageData: PageData = {
    rowFrom: '0',
    rowTo: '0',
    totalRow: '0',
    currPage: '0',
    totalPage: '0',
};
// ------------
// Component 
export type BreakElementListProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

export const BreakElementList = (props: BreakElementListProps) => {
    const {
        userId,
        onSearch,
        onError,
        onDelete,
        onDeleteSuccess,
        onDeleteError
    } = props;
    const { handleSubmit, setValue, getValues } = useForm<SearchItemForm>({
        criteriaMode: "all",
        defaultValues: initialValue,
    });
    const [title, setTitle] = React.useState("");
    const [message, setMessage] = React.useState("");
    const [pageData, setPageData] = React.useState(initialPageData);
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
    const [colState, setColState] = useState<any>();
    const gridRef = useRef() as any;
    const [loadingFlag, setLoadingFlag] = React.useState(true);
    const [keyword, setKeyword] = useState("");
    const [idDelete, setIdDelete] = React.useState("");
    let navigate = useNavigate();

    // 検索実行
    const handleSearchSubmit = (value: SearchItemForm) => {
        const params = new Map<string, any>();
        if (value.limit) params.set("limit", value.limit);
        params.set("page", value.page);
        setLoadingFlag(true);
        onSearch({
            "userId": userId,
            "requestPrms":{
                "limit": value.limit,
                "page": value.page
            }
        });
    }

    // 初回検索実行
    React.useEffect(() => {
        handleSearchSubmit(getValues());
        // eslint-disable-next-line
    }, []);

    const handleSuccess = async (data: any) => {
        console.log('>>> handleSuccess');
        console.log(data);
        if (data && data.results) {
            if (gridRef && gridRef.current) {
                gridRef.current.setRowData(data.results)
            }
            const totalCount = data.totalCount;
            const pageSize = getValues('limit');
            const page = Number(data.page);
            const totalPage = Math.ceil(totalCount / pageSize);
            const rowFrom = (pageSize * page - pageSize) + 1;
            const rowTo = (pageSize * page) > totalCount ? totalCount : (pageSize * page);
            const updateData: PageData = {
                rowFrom: rowFrom.toLocaleString(),
                rowTo: rowTo.toLocaleString(),
                totalRow: totalCount.toLocaleString(),
                currPage: page.toLocaleString(),
                totalPage: totalPage.toLocaleString(),
            };
            setPageData(updateData);
        }
        else {
            if (gridRef && gridRef.current) {
                gridRef.current.setRowData([]);
            }
        }
        setLoadingFlag(false);
    };

    // 検索エラー時
    const handleError = (success: boolean, data: any, error: any) => {
        console.log('>>> handleError');
        onError(success, data, error);
        setLoadingFlag(false);
        gridRef.current.hideOverlay();
    };

    // ページ数変更時にAPI検索する
    const handleChangePageData = (name: any, value: any) => {
        // 値変更
        setValue(name, value);
        if (name === 'limit') {
            setValue("page", 1);
        }
        handleSearchSubmit(getValues());
    };

    const handleSuccessDelete = (data: any) => {
        console.log('>>> handleSuccessDelete');
        onDeleteSuccess();
        handleSearchSubmit(getValues());
    };

    //削除の処理
    const handleDelete = (data: any) => {
        setIdDelete(data);
        const msg = "ID: " + data + "を削除します。"
        setTitle("要素情報削除");
        setMessage(msg);
        setOpenDeleteDialog(true);
    }

    // 削除確認ダイアログでOKボタンのイベントを受け取って、APIに送信する
    const handleDeleteOK = () => {
        setOpenDeleteDialog(false);
        onDelete({
            "userId": userId,
            "requestPrms":{
                "id": idDelete
  }
        });
        setIdDelete("");
    }

    // 削除確認ダイアログでCancelボタンのイベントを受け取って、ダイアログを無効にする
    const handleDeleteCancel = () => {
        setIdDelete("");
        setOpenDeleteDialog(false);
    }

    // 検索エラー時
    const handleErrorDelete = (success: boolean, data: any, error: any) => {
        console.log('>>> handleErrorDelete');
        onDeleteError()
    };

    //削除の処理
    const handleShowDetailPage = (id: any) => {
        navigate(RoutePath.BreakElementEdit + `/edit/${id}`, { state: 1 })
    }

    return (
        <>
            {loadingFlag && (
                <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1500, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                    <CircularProgress />
                </div>
            )}
            {openDeleteDialog ?
                <AlertDialog title={title} message={message} isOpen={true} onOK={handleDeleteOK} onCancel={handleDeleteCancel} /> :
                <div />
            }
            <div style={{ height: '100%', width: '100%', marginTop: '10px' }}>
                <Stack direction="row" justifyContent="flex-start" alignItems="center" sx={{}} style={{ margin: '18px', color: 'MenuText' }}>
                    <Typography variant="h4">
                        要素
                    </Typography>
                </Stack>
                <form onSubmit={handleSubmit(handleSearchSubmit)}>
                </form>
                <div style={{ marginBottom: "10px" }}>
                    <Stack direction="row">
                        <div style={{ marginInlineStart: 'auto' }}>
                            <Grid container justifyContent="flex-start" alignItems={"center"} direction="row" spacing={1} >
                                <Grid item>
                                    <TextField
                                        fullWidth
                                        autoComplete="searchStr"
                                        name="keyword"
                                        type="text"
                                        label="フィルター"
                                        size="small"
                                        value={keyword}
                                        onChange={e => setKeyword(e.target.value)}
                                    />
                                </Grid>
                                <Grid item>
                                    <Button variant="outlined" onClick={e => setKeyword("")}>クリア</Button>
                                </Grid>
                            </Grid>
                        </div>
                    </Stack>
                </div>
                <div>
                    <BreakElementGrid
                        ref={gridRef}
                        colState={colState}
                        pageSize={getValues('limit')}
                        pageData={pageData}
                        onChangeSearchCondition={handleChangePageData}
                        keyword={keyword}
                        onShowDetails={handleShowDetailPage}
                        onDelete={handleDelete}
                        heightForm='240'
                    />
                </div>
            </div>
            <FetchHelper fetchId={SEARCH_BREAK_ELEMENT} onComplete={(success, data, error) =>
                success ? handleSuccess(data) : handleError(success, data, error)} />
            <FetchHelper fetchId={DELETE_BREAK_ELEMENT} onComplete={(success, data, error) => 
                success ? handleSuccessDelete(data) : handleErrorDelete(success, data, error)} />
        </>
    )
}
const mapStateToProps = (state: ApplicationState) => ({
    userId: state.app.user?.userId,
    fetchState: selectors.getFetchState(
        state,
        SEARCH_BREAK_ELEMENT
    ) as FetchState,
});

const mapDispatchToProps = (dispatch: any) => {
    return {
        onSearch: (params: { [key: string]: any }) => dispatch(actionCreators.fetch(SEARCH_BREAK_ELEMENT, `/master/break_element/gets`, "POST", params, false, true)),
        onDelete: (params: { [key: string]: any }) => dispatch(actionCreators.fetch(DELETE_BREAK_ELEMENT, `/master/break_element/delete`, "POST", params, false, true)),
        onDeleteSuccess: () =>
            dispatch(actionCreators.showMessage({
                type: "info",
                title: "要素削除",
                body: "要素を削除しました。",
            })),
        onDeleteError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "要素削除",
                body: "要素を削除できませんでした。",
            })),
        onError: (success: boolean, data: any, error: any) =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "検索",
                body: "検索できません。" + error,
            })),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BreakElementList);