import { GRAPH_SIZE_SOCIETY } from "../models/Constants"

export const doSetRangeGraphDXF = (graph: any, rectGraph: any) =>{
  graph.rectGraph = {...rectGraph}

  let dCountX: number = Math.log10(graph.normalXMax / graph.normalXMin)
  let dCountY: number = Math.log10(graph.normalYMax / graph.normalYMin)

  graph.sizeFrame = {
    cx: Math.floor((rectGraph.right - rectGraph.left) / dCountX),
    cy: Math.floor((rectGraph.bottom - rectGraph.top) / dCountY)
  }

  graph.pointStd = {x: graph.rectGraph.left, y: graph.rectGraph.top}

  return graph.rectGraph
}

export const doConvertNominal = (graph: any, plistdPoint: any[]) => {
  if (plistdPoint.length > 0){
    plistdPoint.forEach((pdPoint: any) => {
      doConvertNominalSub(graph, pdPoint);
    })
  }
  return plistdPoint;
}

const doConvertNominalSub = (graph: any, dPoint: any) => {
  dPoint.m_dx = doConvertNominalX(graph, dPoint.m_dx);
  dPoint.m_dy = doConvertNominalY(graph, dPoint.m_dy);
  return dPoint;
}

export const doConvertNominalX = (graph: any, dPointX: number) => {
  if (dPointX > 0 && graph.nominalVolt > 0) {
    dPointX = graph.pointStd.x + Math.log10(dPointX / (graph.nominalVolt * doGetScaleXmin(graph))) * doGetFrameSizeX(graph);
  } else {
    if (dPointX > 0 && graph.nominalVolt <= 0) {
      dPointX = graph.pointStd.x + Math.log10(dPointX / doGetScaleXmin(graph)) * doGetFrameSizeX(graph);
    }
    else {
      dPointX = graph.pointStd.x;
    }
  }
  return dPointX;
}

export const doConvertNominalY = (graph: any, dPointY: number) => {
  if (dPointY > 0) {
    dPointY = graph.pointStd.y + Math.log10(dPointY / doGetScaleYmin(graph)) * doGetFrameSizeY(graph);
  } else {
    dPointY = graph.pointStd.y;
  }
  return dPointY;
}

const doGetScaleXmin = (graph: any) => {
  let dScalX;
  if (graph.society == true) {
    dScalX = graph.societyXMin;
  } else {
    dScalX = graph.normalXMin;
  }
  return dScalX;
}

export const doGetScaleYmin = (graph: any) => {
  let dScalY;
  if (graph.society == true) {
    dScalY = graph.societyYMin;
  } else {
    dScalY = graph.normalYMin;
  }
  return dScalY;
}
const doGetFrameSizeY = (graph: any) => {
  let dSizeY;
  if (graph.society == true && graph.printDetailHead == true) {
    dSizeY = GRAPH_SIZE_SOCIETY;
  } else {
    dSizeY = graph.sizeFrame.cy;
  }
  return dSizeY;
}

const doGetFrameSizeX = (graph: any) => {
  let dSizeX;
  if (graph.society == true && graph.printDetailHead == true) {
    dSizeX = GRAPH_SIZE_SOCIETY;
  }
  else {
    dSizeX = graph.sizeFrame.cx;
  }
  return dSizeX;
}
