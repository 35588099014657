import { Box, Button, Checkbox, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Stack, Tab, Tabs, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { InfoCircuit, MsGeneratorModel } from '../../models/Index';
import { FLOW_NODE_KIND_PQ, FLOW_NODE_KIND_PV, FLOW_NODE_KIND_SLACK, MS_MODE_CHECKING } from "../../statics";
import { BeamsNumber } from "../../utils/FormatNumber";
import TextFieldOptions from "../common/TextFieldOptions";
import * as Constant from "../../models/Constants";
import { isNumber } from "mathjs";

export type MsGeneratorEditDialogFormProps = {
    data: any;
    onOK: (data: any) => void;
    onCancel: () => void;
    infoCircuit: InfoCircuit;
    userRole: string;
    m_bModePM: boolean;
    modeViewOnly: boolean;
    processMode:number;
};

function a11yProps(index: number) {
    return {
        id: `tab-${index}`,
        "aria-controls": `tabpanel-${index}`,
    };
}

const radioStyles1 = {
    padding: '4px 4px 4px 8px',
}
const checkboxStyle = {
    padding: '0px 0px 0px 9px'
}

const fieldsetStyle = {
    border: "1px solid #AAAAAA",
    padding:"auto",
}

//Tabs------------------
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

export const MsGeneratorEditDialog = (props: MsGeneratorEditDialogFormProps) => {
    // #region properties
    const { infoCircuit, userRole, m_bModePM, modeViewOnly, data, onOK, onCancel,processMode } = props;
    // 初期値設定
    const initialValue = {...data}
    const { control, handleSubmit, formState: { errors }, setValue ,getValues,clearErrors,setError} = useForm<MsGeneratorModel>({
        mode: 'all',
        criteriaMode: "all",
        defaultValues: initialValue,
    });
    const [makeDataInput,setMakeDataInput] = useState(getValues("makeDataInput"));
    const [selectTab, setSelectTab] = useState(0);
    const [isSlackFlag,setIsSlackFlag] = useState(getValues("nodeKind") == FLOW_NODE_KIND_SLACK);
    const [isPQFlag,setIsPQFlag] = useState(getValues("nodeKind") == FLOW_NODE_KIND_PQ);
    const [isPVFlag,setIsPVFlag] = useState(getValues("nodeKind") == FLOW_NODE_KIND_PV);

    // #endregion properties


    // #region UseEffect
    // useEffect(()=>{
    //     doSetValueGenerator(frequency,{target:{name:"",value:"0"}})
    //     doCalcSetValue();
    // },[])
    // #endregion useEffect

    // #region Method

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        if(newValue >= 0){
            if (getValues('refNo') == "" ||getValues('refNo') == null || getValues('refNo') == undefined ) {
                setError("refNo",{ type: 'custom', message: '必須項目です。入力してください。' });
            }
            else if(isNaN(Number(getValues('voltage'))) || Number(getValues('voltage')) <= 0 || !/^[0-9\b]+$/.test(getValues('voltage').toString())){
                setError("voltage",{ type: 'custom', message: '整数 > 0 を入力してください。' });
            }
            else if(isNaN(Number(getValues('capacity'))) || Number(getValues('capacity')) <= 0){
                setError("capacity",{ type: 'custom', message: '数字 > 0 を入力してください。' });
            }
            else if(isNaN(Number(getValues('reactance'))) || Number(getValues('reactance')) <= 0){
                setError("reactance",{ type: 'custom', message: '数字 > 0 を入力してください。' });
            }
            else if(isNaN(Number(getValues('subReactance'))) || Number(getValues('subReactance')) <= 0){
                setError("subReactance",{ type: 'custom', message: '数字 > 0 を入力してください。' });
            }
            else if(isNaN(Number(getValues('constant'))) || Number(getValues('constant')) <= 0){
                setError("constant",{ type: 'custom', message: '数字 > 0 を入力してください。' });
            }
            else if(isNaN(Number(getValues('subConstant'))) || Number(getValues('subConstant')) <= 0){
                setError("subConstant",{ type: 'custom', message: '数字 > 0 を入力してください。' });
            }
            else if(isNaN(Number(getValues('powerFactor'))) || Number(getValues('powerFactor')) < 0 || Number(getValues('powerFactor')) > 1 || !/^-?[0-9]*[.]?[0-9]+$/.test(getValues('powerFactor').toString())){
                setError("powerFactor",{ type: 'custom', message: '整数 0 ~ 1 を入力してください。' });
            }
            else if (isNaN(Number(getValues('appointVoltage'))) || Number(getValues('appointVoltage')) < 0 ||!/^[0-9]*[.]?[0-9]+$/.test(getValues('appointVoltage').toString())){
                setError("appointVoltage",{ type: 'custom', message: '数字 >= 0 を入力してください。' });
            }
            else if (Number(getValues('Qmin')) > (Number(getValues('Qmax')))|| !/^-?[0-9]*[.]?[0-9]+$/.test(getValues('Qmin').toString())){
                setError("Qmin",{ type: 'custom', message: '数字  <= 「最大」  を入力してください。' });
            }
            else if(!/^[0-9]*[.]?[0-9]+$/.test(getValues('Qmax').toString())){
                setError("Qmax",{ type: 'custom', message: '数字を入力してください。' });
            
            }
            else{
                setSelectTab(newValue);

            }
        }
        else{
            setSelectTab(newValue);
        }
    };

    // Submitイベント
    const handleEditSubmit = (value: MsGeneratorModel) => {
        const request = createRequestData(value);
        onOK(request);
    }

    // FormデータからRequestデータを作成する
    const createRequestData = (formValue: MsGeneratorModel) => {
        if(!Number(formValue.powerFactor)){
            formValue.powerFactor = 0
        }
        if(!Number(formValue.appointVoltage)){
            formValue.appointVoltage = 0
        }
        if(!Number(formValue.Qmin)){
            formValue.Qmin = 0
        }
        if(!Number(formValue.Qmax)){
            formValue.Qmax = 0
        }
        let request = {
            ...formValue,
            noGenerator:initialValue.noGenerator,
        } as MsGeneratorModel;
        return request;
    }

    // キャンセル
    const handleCancel = () => {
        onCancel();
    };

    const handleChangeFormat = (e: any) => {
        setValue(e.target.name, e.target.value);
        if(e.target.name === "Qmax"){
            if(+getValues("Qmin") < +e.target.value){
                clearErrors("Qmin")
            }
            else if(+getValues("Qmin") > +e.target.value){
                setError("Qmin",{type:"custom",message :"数字  <= 「最大」  を入力してください。"})
            }
        }
        else if(e.target.name === "Qmin"){
            if(+getValues("Qmin") <= +getValues('Qmax') && !/^-?[0-9]*[.]?[0-9]+$/.test(getValues('Qmax').toString())){
                clearErrors("Qmax")
            }
        }
    }

    const doCalcSetValue = () => {
        const capacity = getValues("capacity");
        if(capacity <= 0){
            setValue("reactance",0);
            setValue("subReactance",0);
            setValue("constant",0);
            setValue("subConstant",0);
            return;
        }
        // TODO: READVALUECAPACITYG
        doReadByCapacityG(getValues("generatorList"));
    }
    
    const doReadByCapacityG = (generatorList:any) => {
        const capacity = getValues("capacity");
        for(const item of generatorList){
            if(capacity <= item.lcapacity||capacity === -1){
                setValue("reactance",item.dreactance);
                setValue("subReactance",item.dsubReactance);
                setValue("constant",item.dconstant);
                setValue("subConstant",item.dsubConstant);
                break;
            }
        }
    }

    const doSetValueGenerator = (frequency:any,e:any) => {
        const dvoltage = getValues("voltage");
        const dcapacity = getValues("capacity");
        const dsubReactance = getValues("subReactance");
        const dreactance = getValues("reactance");
        const dconstant = getValues("constant");
        const dsubConstant = getValues("subConstant");
        const dratedCurrent = doGetValueRatedCurremt(dvoltage,dcapacity);
        const dstatorResistance =  doGetValueStatorOhmR(frequency,dsubReactance,dconstant);
        const dsubCurrentIkd = doGetValueSubCurrentIkd(dratedCurrent,dstatorResistance,dsubReactance);
        const dcurrentIkd = doGetValueCurrentIkd(dratedCurrent,dstatorResistance,dreactance);
        const ddiacNoLoad = doGetValueIacNoLoad(frequency,dsubConstant,dcurrentIkd,dsubCurrentIkd);
        const ddiacLoad = doGetValueIacLoad(ddiacNoLoad)
        const dpeakNumber = doGetValuePeakValue(frequency,dconstant,dsubCurrentIkd,ddiacNoLoad);
        setValue("ratedCurrent",BeamsNumber(Number(dratedCurrent),4));
        setValueField(e,"reactance",4);
        setValueField(e,"subReactance",4);
        setValueField(e,"constant",7);
        setValueField(e,"subConstant",7);
        setValue("statorResistance",BeamsNumber(Number(dstatorResistance),4));
        setValue("subCurrentIkd",BeamsNumber(Number(dsubCurrentIkd)/1000,4));
        setValue("currentIkd",BeamsNumber(Number(dcurrentIkd)/1000,4));
        setValue("iacNoLoad",BeamsNumber(Number(ddiacNoLoad)/1000,4));
        setValue("iacLoad",BeamsNumber(Number(ddiacLoad)/1000,4));
        setValue("peakValue",BeamsNumber(Number(dpeakNumber)/1000,4));
    }

    const doGetValuePeakValue = (frequency:number,dconstant:number,dsubCurrentIkd:number,ddiacNoLoad:number) => {
        let peakValue;
        if(frequency == 0 || dconstant == 0)
            peakValue = Math.sqrt(2.0) * ddiacNoLoad;
        else
            peakValue = Math.sqrt(2.0) * ddiacNoLoad + Math.sqrt(2.0) * dsubCurrentIkd * Math.exp(-1 / (2 * frequency * dconstant));
        return peakValue;
    }


    const doGetValueIacLoad = (diacNoLoad:number) => {
        return 1.1 * diacNoLoad;
    }

    const doGetValueIacNoLoad = (frequency:number,dsubConstant:number,dcurrentIkd:number,dsubCurrentIkd:number) =>{
        let diacNoLoad;
        if(frequency === 0 || dsubConstant === 0)
            diacNoLoad =dcurrentIkd;
        else 
            diacNoLoad = (dsubCurrentIkd - dcurrentIkd) * Math.exp(-1 / (2 * frequency * dsubConstant)) + dcurrentIkd;
        return diacNoLoad;
    }

    const doGetValueCurrentIkd = (dratedCurrent:number,dstatorResistance:number,dreactance:number) => {
        if(dstatorResistance === 0 && dreactance === 0)
            return 0;
        return (dratedCurrent * 100 /  Math.sqrt(Math.pow(dstatorResistance,2) + Math.pow(dreactance,2)));
    }

    const doGetValueSubCurrentIkd = (dratedCurrent:number,dstatorResistance:number,dsubReactance:number) => {
        if(dstatorResistance === 0 && dsubReactance === 0)
            return 0;
        return (dratedCurrent * 100 / Math.sqrt(Math.pow(dstatorResistance,2) + Math.pow(dsubReactance,2)));
    }

    const doGetValueStatorOhmR = (frequency:number,dsubReactance:number,dConstant:number) => {
        if(frequency === 0 || dConstant === 0)
            return 0;
        return dsubReactance/( 2 * Math.PI * frequency * dConstant);
    }

    const setValueField = (e:any,name:any,digit:number) => {
        if(e.target.name !== name){
            setValue(name,BeamsNumber(Number(getValues(name)),digit));
        }
    }

    const doGetValueRatedCurremt = (dvoltage:number,dcapacity:number) => {
        let dratedCurrent;
        if(dvoltage <= 0)
            dratedCurrent = 0;
        else
            dratedCurrent = dcapacity * 1000 / dvoltage / Math.sqrt(3.0);
        return dratedCurrent;
    }

    const doGetValueIsym = (circuitFrequency:number,circuitCapacity:number,percentExX:number,percentExR:number) => {
        const capacity = getValues("capacity");
        const voltage = getValues("voltage");
        const subReactance = getValues("subReactance");
        const reactance = getValues("reactance");
        const subConstantForm = getValues("subConstant");
        if(voltage <= 0 && infoCircuit.frequency <= 0)
            return 0;
        percentExX *= capacity / circuitCapacity;
        percentExR *= capacity / circuitCapacity;
        let statorResistance = doGetValueStatorOhmR(infoCircuit.frequency, getValues("subReactance"),getValues("constant"));
        let Z2eg = Math.sqrt(Math.pow(statorResistance+percentExR,2) + Math.pow(subReactance+percentExX,2));
        let Zeg = Math.sqrt(Math.pow(statorResistance+percentExR,2) + Math.pow(reactance+percentExX,2));
        let I2eg;
        if(Z2eg != 0){
            I2eg = capacity * 1000 * 100 / (voltage * Math.sqrt(3.0) *Z2eg);
        }else{
            I2eg = 0;
        }
        let Ieg;
        if(Zeg != 0)
            Ieg = capacity * 1000 * 100 / (voltage * Math.sqrt(3.0) *Zeg);
        else
            Ieg = 0;
        let subConstant;
        if((subReactance + percentExX) >= 0 && (reactance + percentExX) >= 0 ){
            subConstant = subConstantForm * (1 + percentExX / (subReactance + percentExX)) / (1 + percentExX / (reactance + percentExX));
        }else{
            subConstant = subConstantForm;
        }
        if(subConstant <= 0){
            return 0;
        }
        return 1.1 * ((I2eg - Ieg) * Math.exp(-1/(2 * infoCircuit.frequency * subConstant)) + Ieg)
    }

    const doGetPercentZr = (frequency:number,curcuitCapacity:number) => {
        const subReactance = getValues("subReactance");
        const constant = getValues("constant");
        const capacity = getValues("capacity");
        const percentZR:any = {};
        percentZR.PerRk3 = doGetValueStatorOhmR(frequency,subReactance,constant);
        percentZR.PerXk3 = subReactance;
        if(capacity == 0){
            percentZR.PerRk3 = 0;
            percentZR.PerXk3 = 0;
        }
        else{
            percentZR.PerRk3 *= curcuitCapacity / capacity;
            percentZR.PerXk3 *= curcuitCapacity / capacity;
        }
        return percentZR;
    }

    const doGetPercentXe = (frequency:number,curcuitCapacity:number) =>{
        const percentZR:any = doGetPercentZr(frequency,curcuitCapacity);
        let dXR = 0;
        if(percentZR.PerRk3 > 0)
            dXR = percentZR.PerXk3/percentZR.PerRk3;
    }

    const doGetValuePercentZe = (frequency:any,capacityCurcuit:any,voltage:number) => {
        const Isym = doGetValueIsym(frequency,capacityCurcuit,0,0);
        return capacityCurcuit * 1000 * 100 / (Math.sqrt(3.0) * voltage * Isym);
    }
    
    const doGetTextPercentZe = (frequency:any,capacityCurcuit:any) => {
        let percentZ = doGetValuePercentZe(frequency,capacityCurcuit,getValues("voltage"));
        percentZ *= getValues("capacity") / capacityCurcuit;
        return BeamsNumber(percentZ,4);
    }

    // #region handleEvent


    // Reactance,subReact,constant,subConstant
    const handleChangeCommon = (event:any) => {
        doSetValueGenerator(infoCircuit.frequency,event);
    }

    const handleEditVoltageOrCapacity = (event:any) => {
        setValue(event.target.name,event.target.value);
        doCalcSetValue();
        doSetValueGenerator(infoCircuit.frequency,event);
    }

    const handleSelectChangeVoltage = (event:any) => {
        setValue(event.target.name,event.target.value);
        if(+event.target.value > 0){
            clearErrors(event.target.name)
        }
        doSetValueGenerator(infoCircuit.frequency,event);
    }

    const handleSelectCapacity = (event:any) => {
        if(event.target.value > 0)
            clearErrors(event.target.name)
        doCalcSetValue();
        doSetValueGenerator(infoCircuit.frequency,event);
    }

    const handleChangeNodeKind = (e:any)=> {
        switch(Number(e.target.value)){
            case FLOW_NODE_KIND_SLACK:
                setIsSlackFlag(true);
                setIsPQFlag(false);
                setIsPVFlag(false);
                break;
            case FLOW_NODE_KIND_PQ:
                setIsSlackFlag(false);
                setIsPQFlag(true);
                setIsPVFlag(false);
                break;
            case FLOW_NODE_KIND_PV:
                setIsSlackFlag(false);
                setIsPQFlag(false);
                setIsPVFlag(true);
                break;
        }
    }

    // #endregion handleEvent

    // #endregion Method

    return (
        <>
            <div style={{ height: '100%', width: '100%' }}>
                <form onSubmit={handleSubmit(handleEditSubmit)} style={{ width: '100%', margin: 'auto' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={selectTab} onChange={handleChange} 
                            variant="scrollable" 
                            scrollButtons 
                            allowScrollButtonsMobile 
                            sx={{
                                '& .MuiButtonBase-root.MuiTabScrollButton-root.Mui-disabled': {
                                    display: "none"
                                }
                            }}
                        >
                            <Tab label="基本" {...a11yProps(0)} />
                            {userRole != Constant.ROLE_LP && <Tab label="潮流解析" {...a11yProps(1)} />}
                        </Tabs>
                    </Box>
                    <div style={{height:"42vh",width:"100%",overflowY:"auto"}}>
                    <TabPanel value={selectTab} index={0}>
                        <Grid container my={1}>
                            <Grid item xs={7}>
                                <Stack spacing={0.3}>
                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                        <Typography variant="body2" width="40%">デバイス名:</Typography>
                                        <Controller
                                            name="refNo"
                                            control={control}
                                            rules={{
                                                required: '必須項目です。入力してください。',
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    size="small"
                                                    error={!!errors?.refNo}
                                                    helperText={errors?.refNo?.message}
                                                    onBlur={handleChangeFormat}
                                                    onFocus={e => e.target.select()}
                                                    InputLabelProps={{
                                                        style: { color: 'black' },
                                                    }}
                                                    inputProps={{readOnly: m_bModePM || modeViewOnly}}
                                                    InputProps={{ 
                                                        sx: { 
                                                            height: 32,
                                                            background:m_bModePM || modeViewOnly?'floralwhite':'white',
                                                            color: m_bModePM || modeViewOnly?'grey':'black',
                                                        }
                                                    }}
                                                />
                                            )}
                                        />
                                    </Stack>
                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                        <Typography variant="body2" width="40%">事故点名称:</Typography>
                                        <Controller
                                            name="pointText"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    size="small"
                                                    onBlur={handleChangeFormat}
                                                    onFocus={e => e.target.select()}
                                                    InputLabelProps={{
                                                        style: { color: 'black' },
                                                    }}
                                                    inputProps={{readOnly: m_bModePM || modeViewOnly}}
                                                    InputProps={{ 
                                                        sx: { 
                                                            height: 32,
                                                            background:m_bModePM || modeViewOnly?'floralwhite':'white',
                                                            color: m_bModePM || modeViewOnly?'grey':'black',
                                                        }
                                                    }}
                                                />
                                            )}
                                        />          
                                    </Stack>
                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                        <Typography variant="body2" width="40%">定格電圧(V):</Typography>
                                        <FormControl fullWidth size="small">
                                            <Controller
                                                name="voltage"
                                                control={control}
                                                rules={{ required: '必須項目です。入力してください。' ,
                                                    pattern:{
                                                        value: /^[0-9\b]+$/,
                                                        message: '数字を入力してください。'
                                                    },
                                                    validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                }}
                                                render={({ field }: any) => (
                                                
                                                        <TextFieldOptions
                                                            {...field}
                                                            disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}
                                                            options={data.voltageList}
                                                            size="small"
                                                            setValue={(name: any, value: any) => {
                                                                field.onChange({target:{name:name,value:value}});
                                                                handleSelectChangeVoltage({target:{name:name,value:value}})

                                                                // handleEditVoltageOrCapacity({target: {name: name, value: value}});
                                                            }}
                                                            // onSelectValue={(name:any,value) => {
                                                            //     setValue(name,Number(value));
                                                            //     handleSelectChangeVoltage({target:{name:name,value:value}})
                                                            // }}
                                                            height={32}
                                                            maxLength={6}
                                                            error={!!errors?.voltage}
                                                            helperText={errors?.voltage?.message}
                                                        />
                                                    
                                                )}
                                            />
                                        </FormControl>
                                    </Stack>
                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                        <Typography variant="body2" width="40%">容量(kVA):</Typography>
                                        <FormControl fullWidth size="small">
                                            <Controller
                                                name="capacity"
                                                control={control}
                                                rules={{ required: '必須項目です。入力してください。',
                                                    pattern:{
                                                        value: /^[0-9]*[.]?[0-9]+$/,
                                                        message: '数字を入力してください。'
                                                    },
                                                    validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                }}
                                                render={({ field }: any) => (
                                                    <>
                                                        <TextFieldOptions 
                                                            {...field}
                                                            disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}
                                                            options={data.capacityList}
                                                            size="small"
                                                            setValue = {(name: any, value: any) =>{
                                                                handleEditVoltageOrCapacity({target:{name:name,value:value}});
                                                            }}
                                                            onSelectValue={(name:any,value) => {
                                                                setValue(name,value);
                                                                handleSelectCapacity({target:{name:name,value:value}});
                                                            }}
                                                            maxLength={6}
                                                            height={32}
                                                            error={!!errors?.capacity}
                                                            helperText={errors?.capacity?.message}
                                                        />
                                                    </>
                                                )}
                                            />
                                        </FormControl>
                                    </Stack>
                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                        <Typography variant="body2" width="40%">定格電流(A):</Typography>
                                        <Controller
                                            name="ratedCurrent"
                                            control={control}
                                            rules={{
                                                required: '必須項目です。入力してください。',
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    size="small"
                                                    InputLabelProps={{
                                                        style: { color: 'black' },
                                                        shrink: true,
                                                    }}
                                                    style={{
                                                        backgroundColor: 'floralwhite',
                                                    }}
                                                    inputProps={{
                                                        readOnly: true,
                                                    }}
                                                    InputProps={{
                                                        sx:{height:32,color:'grey'},
                                                    }}
                                                />
                                            )}
                                        />
                                    </Stack>
                                </Stack>
                            </Grid>
                            <Grid item xs={5}>
                                <Stack pl={3}>
                                    <Stack>
                                        <Controller
                                            name="fault"
                                            control={control}
                                            render={({ field }: any) => (
                                                <FormControlLabel
                                                    {...field}
                                                    control={<Checkbox  style={radioStyles1} />}
                                                    checked={field.value}
                                                    disabled={m_bModePM || modeViewOnly}
                                                    label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>短絡電流を計算</Typography>}
                                                    name='fault' onClick={handleChangeFormat}
                                                />
                                            )}
                                        />
                                    </Stack>
                                    <Stack>
                                        <Controller
                                            name="viewResultText"
                                            control={control}
                                            render={({ field }: any) => (
                                                <FormControlLabel
                                                    {...field}
                                                    control={<Checkbox style={radioStyles1} />}
                                                    checked={field.value}
                                                    disabled={m_bModePM || modeViewOnly}
                                                    label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>注釈を表示</Typography>}
                                                    name='viewResultText'
                                                    onClick={handleChangeFormat}
                                                />
                                            )}
                                        />
                                    </Stack>
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid container my={1}>
                            <Grid item xs={9}>
                                <fieldset style={fieldsetStyle}>
                                    <legend><Typography variant="body2">インピーダンス</Typography></legend>
                                    <Stack spacing={0.3}>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="120%">過渡リアクタンス X'd(%):</Typography>
                                                <Controller
                                                    name="reactance"
                                                    control={control}
                                                    rules={{ required: '必須項目です。入力してください。',
                                                        pattern:{
                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                            message: '数字を入力してください。'
                                                        },
                                                        validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type="text"
                                                            size="small"
                                                            error={!!errors?.reactance}
                                                            helperText={errors?.reactance?.message}
                                                            onBlur={handleChangeFormat}
                                                            onFocus={e => e.target.select()}
                                                            InputLabelProps={{
                                                                style: { color: 'black' },
                                                                shrink:true
                                                            }}
                                                            onChange={(event:any) => {
                                                                field.onChange(event);
                                                                handleChangeCommon(event);
                                                            }}
                                                            inputProps={{maxlength:5,readOnly: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}}
                                                            InputProps={{ 
                                                                sx: { 
                                                                    height: 32,
                                                                    background:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white',
                                                                    color: m_bModePM|| modeViewOnly || processMode > MS_MODE_CHECKING?'grey':'black',
                                                                }
                                                            }}
                                                        />
                                                    )}
                                                />
                                        </Stack>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="120%">初期過渡リアクタンス X"d(%):</Typography>
                                            <Controller
                                                name="subReactance"
                                                control={control}
                                                rules={{ required: '必須項目です。入力してください。',
                                                    pattern:{
                                                        value: /^[0-9]*[.]?[0-9]+$/,
                                                        message: '数字を入力してください。'
                                                    },
                                                    validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                }}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        error={!!errors?.subReactance}
                                                        helperText={errors?.subReactance?.message}
                                                        onBlur={handleChangeFormat}
                                                        onFocus={e => e.target.select()}
                                                        InputLabelProps={{
                                                            style: { color: 'black' },
                                                            shrink:true
                                                        }}
                                                        onChange={(event:any) => {
                                                            field.onChange(event);
                                                            handleChangeCommon(event);
                                                        }}
                                                        inputProps={{maxlength:5,readOnly: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}}
                                                        InputProps={{ 
                                                            sx: { 
                                                                height: 32,
                                                                background:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white',
                                                                color: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'grey':'black',
                                                            }
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="120%">直流時定数 Tdc(s):</Typography>
                                            <Controller
                                                name="constant"
                                                control={control}
                                                rules={{ required: '必須項目です。入力してください。',
                                                    pattern:{
                                                        value: /^[0-9]*[.]?[0-9]+$/,
                                                        message: '数字を入力してください。'
                                                    },
                                                    validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                }}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        error={!!errors?.constant}
                                                        helperText={errors?.constant?.message}
                                                        onBlur={handleChangeFormat}
                                                        onFocus={e => e.target.select()}
                                                        InputLabelProps={{
                                                            style: { color: 'black' },
                                                            shrink:true
                                                        }}
                                                        onChange={(event:any) => {
                                                            field.onChange(event);
                                                            handleChangeCommon(event);
                                                        }}
                                                        inputProps={{maxlength:7,readOnly: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}}
                                                        InputProps={{ 
                                                            sx: { 
                                                                height: 32,
                                                                background:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white',
                                                                color: m_bModePM || modeViewOnly|| processMode > MS_MODE_CHECKING?'grey':'black',
                                                            }
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="120%">初期過渡時定数 T"d(s):</Typography>
                                            <Controller
                                                name="subConstant"
                                                control={control}
                                                rules={{ required: '必須項目です。入力してください。',
                                                    pattern:{
                                                        value: /^[0-9]*[.]?[0-9]+$/,
                                                        message: '数字を入力してください。'
                                                    },
                                                    validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                }}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        error={!!errors?.subConstant}
                                                        helperText={errors?.subConstant?.message}
                                                        onBlur={handleChangeFormat}
                                                        onFocus={e => e.target.select()}
                                                        InputLabelProps={{
                                                            style: { color: 'black' },
                                                            shrink:true
                                                        }}
                                                        onChange={(event:any) => {
                                                            field.onChange(event);
                                                            handleChangeCommon(event);
                                                        }}
                                                        inputProps={{maxlength:7,readOnly: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}}
                                                        InputProps={{ 
                                                            sx: { 
                                                                height: 32,
                                                                background:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white',
                                                                color: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'grey':'black',
                                                            }
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="120%">ステータ抵抗 Ra(%):</Typography>
                                            <Controller
                                                name="statorResistance"
                                                control={control}
                                                rules={{
                                                    required: '必須項目です。入力してください。',
                                                }}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        InputLabelProps={{
                                                            style: { color: 'black' },
                                                            shrink:true
                                                        }}
                                                        style={{
                                                            backgroundColor: 'floralwhite',
                                                        }}
                                                        inputProps={{
                                                            readOnly: true,
                                                        }}
                                                        InputProps={{
                                                            sx:{height:32,color:'grey'},
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                    </Stack>
                                </fieldset>
                            </Grid>
                        </Grid>
                        <Grid container my={1}>
                            <Grid item xs={12}>
                                <fieldset style={fieldsetStyle}>
                                    <legend><Typography variant="body2">短絡電流</Typography></legend>
                                    <Stack spacing={0.3}>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="160%">初期過渡短絡電流 I"kd(kA):</Typography>
                                            <Controller
                                                name="subCurrentIkd"
                                                control={control}
                                                rules={{
                                                    required: '必須項目です。入力してください。',
                                                }}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        style={{
                                                            backgroundColor: 'floralwhite',
                                                        }}
                                                        InputLabelProps={{
                                                            style: { color: 'black' },
                                                            shrink:true
                                                        }}
                                                        inputProps={{
                                                            readOnly: true,
                                                        }}
                                                        InputProps={{
                                                            sx:{height:32,color:'grey'}
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="160%">過渡短絡電流 I'kd(kA):</Typography>
                                            <Controller
                                                name="currentIkd"
                                                control={control}
                                                rules={{
                                                    required: '必須項目です。入力してください。',
                                                }}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        style={{
                                                            backgroundColor: 'floralwhite',
                                                        }}
                                                        InputLabelProps={{
                                                            style: { color: 'black' },
                                                            shrink:true
                                                        }}
                                                        inputProps={{
                                                            readOnly: true,
                                                        }}
                                                        InputProps={{
                                                            sx:{height:32,color:'grey'}
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="160%">無負荷時短絡電流の交流成分 Iac no-load(kA):</Typography>
                                            <Controller
                                                name="iacNoLoad"
                                                control={control}
                                                rules={{
                                                    required: '必須項目です。入力してください。',
                                                }}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        style={{
                                                            backgroundColor: 'floralwhite',
                                                        }}
                                                        InputLabelProps={{
                                                            style: { color: 'black' },
                                                            shrink:true
                                                        }}
                                                        inputProps={{
                                                            readOnly: true,
                                                        }}
                                                        InputProps={{
                                                            sx:{height:32,color:'grey'}
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="160%">有負荷時短絡電流の交流成分 Iac load(kA):</Typography>
                                            <Controller
                                                name="iacLoad"
                                                control={control}
                                                rules={{
                                                    required: '必須項目です。入力してください。',
                                                }}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        style={{
                                                            backgroundColor: 'floralwhite',
                                                        }}
                                                        InputLabelProps={{
                                                            style: { color: 'black' },
                                                            shrink:true
                                                        }}
                                                        inputProps={{
                                                            readOnly: true,
                                                        }}
                                                        InputProps={{
                                                            sx:{height:32,color:'grey'}
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="160%">短絡電流Iacのピ一ク値(kA):</Typography>
                                            <Controller
                                                name="peakValue"
                                                control={control}
                                                rules={{
                                                    required: '必須項目です。入力してください。',
                                                }}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        style={{
                                                            backgroundColor: 'floralwhite',
                                                        }}
                                                        InputLabelProps={{
                                                            style: { color: 'black' },
                                                            shrink:true
                                                        }}
                                                        inputProps={{
                                                            readOnly: true,
                                                        }}
                                                        InputProps={{
                                                            sx:{height:32,color:'grey'}
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                    </Stack>
                                </fieldset>
                            </Grid>
                        </Grid>

                        {/* MODE PM */}
                        {[Constant.ROLE_PM, Constant.ROLE_ADMIN].includes(userRole) &&
                        <Grid container my={1}>
                            <Grid item xs={8}>
                                <Stack direction='column' mb={1}>
                                    <Grid container direction="row">
                                        <Grid item xs={12}>
                                            <Stack>
                                                <FormControlLabel
                                                    control={
                                                        <Controller
                                                            name='makeDataInput'
                                                            control={control}
                                                            render={({ field }) => (
                                                                <Checkbox
                                                                    {...field}
                                                                    disabled={m_bModePM || modeViewOnly}
                                                                    checked={field.value}
                                                                    onChange={(e)=>{
                                                                        setMakeDataInput(e.target.checked);
                                                                        setValue("makeDataInput",e.target.checked);
                                                                    }}
                                                                    style={radioStyles1}
                                                                />
                                                            )}
                                                        />
                                                    }
                                                    label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>銘板入力</Typography>}
                                                />
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Stack>
                                            <fieldset
                                                style={{ 
                                                    width: '100%',
                                                    color: m_bModePM || !makeDataInput ? 'grey' : 'black', 
                                                    border:'1px solid #AAAA'
                                                    
                                                }}
                                            >
                                                <legend><Typography variant="body2" >銘板</Typography></legend>
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <Stack spacing={0.3}>
                                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                                <Typography variant="body2" width="35%"  >製造者:</Typography>
                                                                    <FormControl fullWidth size='small'>
                                                                        <Controller
                                                                            name='makerName'
                                                                            control={control}
                                                                            render={({ field }: any) => (
                                                                                <TextFieldOptions
                                                                                    {...field}
                                                                                    options={initialValue.makerNameList}
                                                                                    type='string'
                                                                                    disabled={m_bModePM || modeViewOnly || !makeDataInput}
                                                                                    size='small'
                                                                                    setValue={(name: any, value: any) => {
                                                                                        handleChangeFormat({target: {name: name, value: value}})
                                                                                    }}
                                                                                    height={32}
                                                                                />
                                                                            )}
                                                                        />
                                                                    </FormControl>
                                                            </Stack>
                                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                                <Typography variant="body2" width="35%"  >形式:</Typography>
                                                                <Controller
                                                                    name='type'
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <TextField
                                                                            {...field}
                                                                            fullWidth
                                                                            type='text'
                                                                            size='small'
                                                                            onChange={handleChangeFormat}
                                                                            inputProps={{readOnly: m_bModePM || modeViewOnly || !makeDataInput}}
                                                                            InputProps={{ 
                                                                                sx: { 
                                                                                    height: 32,
                                                                                    background:m_bModePM || modeViewOnly || !makeDataInput?'floralwhite':'white',
                                                                                    color: m_bModePM || modeViewOnly || !makeDataInput?'grey':'black',
                                                                                }
                                                                            }}
                                                                        />
                                                                    )}
                                                                />
                                                            </Stack>
                                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                                <Typography variant="body2" width="35%"  >製造年月:</Typography>
                                                                <Grid container>
                                                                    <Grid item xs={7}>
                                                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                                            <FormControl fullWidth size='small'>
                                                                                <Controller
                                                                                    name='makeYear'
                                                                                    control={control}
                                                                                    
                                                                                    render={({ field }: any) => (
                                                                                        <TextFieldOptions
                                                                                            {...field}
                                                                                            options={initialValue.makeYearList}
                                                                                            type='string'
                                                                                            size='small'
                                                                                            disabled={m_bModePM || modeViewOnly || !makeDataInput}
                                                                                            setValue={(name: any, value: any) => {
                                                                                                handleChangeFormat({target: {name: name, value: value}})
                                                                                            }}
                                                                                            maxLength={8}
                                                                                            height= {32}
                                                                                        />
                                                                                    )}
                                                                                />
                                                                            </FormControl>
                                                                            <Typography variant="body2" pr={1.5} pl={0.5}>年</Typography>
                                                                        </Stack>
                                                                        
                                                                    </Grid>
                                                                    <Grid item xs={5}>
                                                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                                            <FormControl fullWidth size='small'>
                                                                                <Controller
                                                                                    name='makeMonth'
                                                                                    control={control}
                                                                                    
                                                                                    render={({ field }: any) => (
                                                                                        <TextFieldOptions
                                                                                            {...field}
                                                                                            disabled={m_bModePM || modeViewOnly || !makeDataInput}
                                                                                            options={initialValue.makeMonthList}
                                                                                            type='string'
                                                                                            size='small'
                                                                                            setValue={(name: any, value: any) => {
                                                                                                handleChangeFormat({target: {name: name, value: value}})
                                                                                            }}
                                                                                            maxLength={2}
                                                                                            height= {32}
                                                                                        />
                                                                                    )}
                                                                                />
                                                                            </FormControl>
                                                                            <Typography variant="body2" pl={0.5}>月</Typography>
                                                                        </Stack>
                                                                    </Grid>
                                                                </Grid>
                                                            </Stack>
                                                            <Stack sx={{ flexDirection: "row", alignItems:"center"}}>
                                                                <Typography variant="body2" width="35%"  >製造番号:</Typography>
                                                                <Controller
                                                                    name='makeNumber'
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <TextField
                                                                            {...field}
                                                                            fullWidth
                                                                            type='text'
                                                                            size='small'
                                                                            onChange={handleChangeFormat}
                                                                            inputProps={{readOnly: m_bModePM || modeViewOnly || !makeDataInput}}
                                                                            InputProps={{ 
                                                                                sx: { 
                                                                                    height: 32,
                                                                                    background:m_bModePM || modeViewOnly || !makeDataInput?'floralwhite':'white',
                                                                                    color: m_bModePM || modeViewOnly || !makeDataInput?'grey':'black',
                                                                                }
                                                                            }}
                                                                        />
                                                                    )}
                                                                />
                                                            </Stack>
                                                        </Stack>
                                                    </Grid>
                                                </Grid>
                                            </fieldset>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Stack>
                            </Grid>
                        </Grid>
                        }
                    </TabPanel>
                    <TabPanel value={selectTab} index={1}>
                    <Grid container mt={1}>
                                <Grid item xs={7.5}>
                                    <fieldset style={fieldsetStyle}>
                                        <legend ><Typography variant="body2">運転モード</Typography></legend>
                                        <Controller
                                            name="nodeKind"
                                            control={control}
                                            render={({ field }) => (
                                                <RadioGroup
                                                    {...field}
                                                    onChange={(e:any)=>{
                                                        field.onChange(e);
                                                        handleChangeNodeKind(e);
                                                    }} 
                                                    row
                                                >
                                                    <FormControlLabel 
                                                        value={FLOW_NODE_KIND_SLACK}
                                                        disabled={m_bModePM || modeViewOnly}
                                                        control={<Radio style={radioStyles1} size="small"/>} 
                                                        label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>スラック発電機</Typography>}
                                                    />
                                                    <FormControlLabel 
                                                        value={FLOW_NODE_KIND_PQ}
                                                        disabled={m_bModePM || modeViewOnly}
                                                        control={<Radio style={radioStyles1} size="small"/>} 
                                                        label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>PQ指定</Typography>}
                                                    />
                                                    <FormControlLabel 
                                                        value={FLOW_NODE_KIND_PV} 
                                                        disabled={m_bModePM || modeViewOnly}
                                                        control={<Radio style={radioStyles1} size="small"/>} 
                                                        label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>PV指定</Typography>}
                                                    />
                                                </RadioGroup>
                                            )}
                                        />
                                    </fieldset>
                                </Grid>
                                <Grid item xs={4.5} pl={1} mt={2}>
                                    <FormControlLabel
                                        control={
                                            <Controller
                                                name='viewFlowResult'
                                                control={control}
                                                render={({ field }) => (
                                                    <Checkbox
                                                        {...field}
                                                        disabled={m_bModePM || modeViewOnly}
                                                        checked={field.value}
                                                        onClick={handleChangeFormat} 
                                                        size="small"
                                                    />
                                                )}
                                            />
                                        }
                                        label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>潮流解析結果を表示</Typography>}
                                    />
                                </Grid>

                                <Grid item xs={5} mt={2}>
                                    <fieldset style={fieldsetStyle}>
                                        <legend><Typography variant="body2" color={(isSlackFlag || !isPQFlag) && !isPVFlag ? "grey":"black"}>発電機の力率(実測値)</Typography></legend>
                                        <Grid container>
                                            <Grid item xs={6}>
                                                <Controller
                                                    name='isLead'
                                                    control={control}
                                                    render={({ field }) => (
                                                        <RadioGroup {...field} row onChange={handleChangeFormat}>
                                                            <FormControlLabel 
                                                                // style={{backgroundColor:(isSlackFlag || !isPQFlag) && !isPVFlag ? "floralwhite":"white"}} 
                                                                value={true}
                                                                control={<Radio style={radioStyles1} size="small"/>} 
                                                                label={<Typography variant="body2" color={m_bModePM || ((isSlackFlag || !isPQFlag) && !isPVFlag) ? "grey":"black"}>進み</Typography>}
                                                                disabled={m_bModePM || modeViewOnly || ((isSlackFlag || !isPQFlag) && !isPVFlag)}
                                                            />
                                                            <FormControlLabel 
                                                                control={<Radio style={radioStyles1} size="small"/>} 
                                                                label={<Typography variant="body2" color={m_bModePM || ((isSlackFlag || !isPQFlag) && !isPVFlag) ? "grey":"black"}>遅れ</Typography>}
                                                                disabled={m_bModePM || modeViewOnly || ((isSlackFlag || !isPQFlag) && !isPVFlag)} 
                                                                // style={{backgroundColor:(isSlackFlag || !isPQFlag) && !isPVFlag ? "floralwhite":"white"}} 
                                                                value={false}
                                                            />
                                                        </RadioGroup>
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={6} mt={1}>
                                                <Controller
                                                    name='powerFactor'
                                                    control={control}
                                                    rules={{
                                                        pattern:{
                                                        value: /^[0-9]*[.]?[0-9]+$/,
                                                        message: '数字を入力してください。'
                                                        },
                                                        validate: value => value >= 0 && value <= 1|| ' 0 <= 数字 <= 1  を入力してください。' ,
                                                        }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type='text'
                                                            size='small'
                                                            onChange={(e:any)=>{
                                                                field.onChange(e);
                                                            }}
                                                            disabled={m_bModePM || modeViewOnly || ((isSlackFlag || !isPQFlag) && !isPVFlag)}
                                                            style={{backgroundColor:m_bModePM || ((isSlackFlag || !isPQFlag) && !isPVFlag) ? "floralwhite":"white"}}
                                                            InputLabelProps={{
                                                                style: { color: 'black' },
                                                            }}
                                                            inputProps={{
                                                                maxLength:5,
                                                            }}
                                                            InputProps={{
                                                                sx:{height:32},
                                                            }}
                                                            error={!!errors?.powerFactor}
                                                            helperText={errors?.powerFactor?.message}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                    </fieldset>
                                </Grid>
                                <Grid item xs={7} mt={2} pl={5}>
                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                        <Typography variant="body2" width="40%" style={{color:m_bModePM || isPQFlag ? "grey":"black"}}>電圧指定値(pu):</Typography>
                                        <Controller
                                            name='appointVoltage'
                                            control={control}
                                            rules={{
                                                validate: value => value >= 0 || '数字 >= 0 を入力してください。' ,
                                                }}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    // fullWidth
                                                    type="text"
                                                    size='small'
                                                    disabled={m_bModePM || modeViewOnly || isPQFlag}
                                                    onChange={(e:any)=>{
                                                        field.onChange(e)
                                                    }}
                                                    inputProps={{
                                                        maxLength:5
                                                    }}
                                                    style={{backgroundColor:m_bModePM || isPQFlag ? "floralwhite":"white",width:"167px"}}
                                                    InputLabelProps={{
                                                        style: { color: 'black' },
                                                    }}
                                                    InputProps={{
                                                        sx:{height:32,width:167}
                                                    }}
                                                    error={!!errors?.appointVoltage}
                                                    helperText={errors?.appointVoltage?.message}
                                                />
                                            )}
                                        />
                                    </Stack>
                                    <Stack mt={2}>
                                        <fieldset style={fieldsetStyle}>
                                            <legend><Typography variant="body2" color={m_bModePM || (isSlackFlag || isPQFlag) ? "grey" : "black"}>無効電力の変動範囲</Typography></legend>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="50%" color={m_bModePM || (isSlackFlag || isPQFlag) ? "grey" : "black"}>最小 (kvar):</Typography>
                                                <Controller
                                                    name='Qmin'
                                                    control={control}
                                                    rules={{
                                                        pattern:{
                                                        value: /^-?[0-9]*[.]?[0-9]+$/,
                                                        message: '数字を入力してください。'
                                                        },
                                                        validate:  value => value <= +getValues("Qmax") || '数字  <= 「最大」  を入力してください。' ,
                                                        }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type="text"
                                                            size="small"
                                                            disabled={m_bModePM || modeViewOnly || isSlackFlag || isPQFlag}
                                                            onChange={(e:any)=>{
                                                                field.onChange(e);
                                                                handleChangeFormat(e)

                                                            }}
                                                            inputProps={{
                                                                maxLength:6
                                                            }}
                                                            style={{backgroundColor:m_bModePM || (isSlackFlag || isPQFlag) ? "floralwhite" : "white"}}
                                                            InputLabelProps={{
                                                                style: { color: 'black' },
                                                            }}
                                                            InputProps={{
                                                                sx:{height:32},
                                                            }}
                                                            error={!!errors?.Qmin}
                                                            helperText={errors?.Qmin?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack mt={1} sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="50%" color={m_bModePM || (isSlackFlag || isPQFlag) ? "grey" : "black"}>最大 (kvar):</Typography>
                                                <Controller
                                                    name='Qmax'
                                                    control={control}
                                                    rules={{
                                                        pattern:{
                                                        value: /^[0-9]*[.]?[0-9]+$/,
                                                        message: '数字を入力してください。'
                                                        },
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type="text"
                                                            size="small"
                                                            disabled={m_bModePM || modeViewOnly || isSlackFlag || isPQFlag}
                                                            style={{marginTop:"10px",backgroundColor:m_bModePM || (isSlackFlag || isPQFlag) ? "floralwhite" : "white"}}
                                                            onChange={(e:any)=>{
                                                                field.onChange(e);
                                                                handleChangeFormat(e)
                                                            }}
                                                            inputProps={{
                                                                maxLength:6
                                                            }}
                                                            InputLabelProps={{
                                                                style: { color: 'black' },
                                                            }}
                                                            InputProps={{
                                                                sx:{height:32},
                                                            }}
                                                            error={!!errors?.Qmax}
                                                            helperText={errors?.Qmax?.message}
                                                         />
                                                    )}
                                                />
                                            </Stack>
                                        </fieldset>
                                    </Stack>
                                </Grid>
                            </Grid>
                    </TabPanel>             
                    </div>
                    <Stack direction='row' justifyContent="flex-end" spacing={1} mt={2}>
                        <Button variant="contained" type="submit" disabled={modeViewOnly}>OK</Button>
                        <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                    </Stack>
                </form >
            </div>
        </>
    );
};
export default MsGeneratorEditDialog;


