import './scrollbar.css';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { TC_INFO_TABLE_HEIGHT } from '../../models/Constants';

type VerticalScrollbarProps = {
  contentRef: React.RefObject<HTMLDivElement>;
  currentTabId: number;
  heightGrid: number;
  scaleGrid: number;
};

const VerticalScrollbar = ((props: VerticalScrollbarProps) => {
  const { contentRef, currentTabId, heightGrid, scaleGrid } = props;

  const scrollTrackRef = useRef<HTMLDivElement>(null);
  const scrollThumbRef = useRef<HTMLDivElement>(null);

  const observer = useRef<ResizeObserver | null>(null);

  const [thumbHeight, setThumbHeight] = useState(20);
  const [scrollStartPosition, setScrollStartPosition] = useState<number>(0);
  const [initialScrollTop, setInitialScrollTop] = useState<number>(0);

  const [isDragging, setIsDragging] = useState(false);
  
  const [isHideScrollbar, setIsHideScrollbar] = useState(true);

  const handleResize = useCallback(() => {
    if (contentRef.current && scrollTrackRef.current) {
      const ref = contentRef.current;
      let { clientHeight } = ref;
      const { clientHeight: trackSizeV } = scrollTrackRef.current;

      let scrollHeight = heightGrid;
      if (currentTabId != 1) scrollHeight += TC_INFO_TABLE_HEIGHT;

      const ratioHeight = Math.min((clientHeight / scrollHeight), 1);

      setThumbHeight(Math.max(ratioHeight * trackSizeV, 20));
      setIsHideScrollbar(ratioHeight === 1);
    }
  }, [heightGrid, scaleGrid, currentTabId])

  const handleScrollButton = (direction: 'up' | 'down') => {
    const { current } = contentRef;
    if (current) {
      const scrollAmount = direction === 'down' ? 200 : -200;
      current.scrollBy({ top: scrollAmount, behavior: 'smooth' });
    }
  }

  const handleTrackClick = useCallback(
    (e: any) => {
      if (e.cancelable) {
        e.preventDefault();
        e.stopPropagation();
        const { current: trackCurrent } = scrollTrackRef;
        const { current: contentCurrent } = contentRef;
        if (trackCurrent && contentCurrent) {
          const { clientY } = e;
          const target = e.target as HTMLDivElement;
          const rect = target.getBoundingClientRect();
          const trackTop = rect.top;
          const thumbOffset = -(thumbHeight / 2);
          const clickRatio =
            (clientY - trackTop + thumbOffset) / trackCurrent.clientHeight;
          const scrollAmount = Math.floor(
            clickRatio * contentCurrent.scrollHeight
          );
          contentCurrent.scrollTo({
            top: scrollAmount,
            behavior: 'smooth',
          });
        }
      }
    },
    [thumbHeight]
  );

  const handleThumbPosition = useCallback(() => {
    if (
      !contentRef.current ||
      !scrollTrackRef.current ||
      !scrollThumbRef.current
    ) {
      return;
    }
    const { scrollTop: contentTop, scrollHeight: contentHeight } =
      contentRef.current;
    const { clientHeight: trackHeight } = scrollTrackRef.current;
    let newTop = (+contentTop / +contentHeight) * trackHeight;
    newTop = Math.min(newTop, trackHeight - thumbHeight);
    const thumb = scrollThumbRef.current;
    thumb.style.top = `${newTop}px`;
  }, []);

  const handleThumbMousedown = useCallback((e: any) => {
    if (e.cancelable) {
      e.preventDefault();
      setScrollStartPosition(e.clientY);
      if (contentRef.current) setInitialScrollTop(contentRef.current.scrollTop);
      setIsDragging(true);
    }
  }, []);

  const handleThumbMouseup = useCallback(
    (e: any) => {
      if (e.cancelable) {
        e.preventDefault();
        if (isDragging) setIsDragging(false);
      }
    },
    [isDragging]
  );

  const handleThumbMousemove = useCallback(
    (e: any) => {
      if (e.cancelable) {
        e.preventDefault();
        if (isDragging && contentRef.current) {
          const {
            scrollHeight: contentScrollHeight,
            offsetHeight: contentOffsetHeight,
          } = contentRef.current;
          const deltaY =
            (e.clientY - scrollStartPosition) *
            (contentOffsetHeight / thumbHeight);
          const newScrollTop = Math.min(
            initialScrollTop + deltaY,
            contentScrollHeight - contentOffsetHeight
          );

          contentRef.current.scrollTop = newScrollTop;
        }
      }
    },
    [isDragging, scrollStartPosition, thumbHeight]
  );

  // region touch event
  const handleThumbTouchstart = useCallback((e: any) => {
    setScrollStartPosition(e.changedTouches[0].clientY);
    if (contentRef.current) setInitialScrollTop(contentRef.current.scrollTop);
    setIsDragging(true);
  }, []);

  const handleThumbTouchend = useCallback(
    (e: any) => {
      if(e.cancelable){
        if (isDragging) setIsDragging(false);
      }
    },
    [isDragging]
  );

  const handleThumbTouchmove = useCallback(
    (e: any) => {
      if (isDragging && contentRef.current) {
        const {
          scrollHeight: contentScrollHeight,
          offsetHeight: contentOffsetHeight,
        } = contentRef.current;
        const deltaY =
          (e.changedTouches[0].clientY - scrollStartPosition) *
          (contentOffsetHeight / thumbHeight);
        const newScrollTop = Math.min(
          initialScrollTop + deltaY,
          contentScrollHeight - contentOffsetHeight
        );

        contentRef.current.scrollTop = newScrollTop;
      }
    },
    [isDragging, scrollStartPosition, thumbHeight]
  );

  // If the content and the scrollbar track exist, use a ResizeObserver to adjust height of thumb and listen for scroll event to move the thumb
  useEffect(() => {
    if (contentRef.current) {
      const ref = contentRef.current;
      observer.current = new ResizeObserver(() => {handleResize();});
      observer.current.observe(ref);
      ref.addEventListener('scroll', handleThumbPosition);
      return () => {
        observer.current?.unobserve(ref);
        ref.removeEventListener('scroll', handleThumbPosition);
      };
    }
  },[heightGrid, scaleGrid, currentTabId])

  // Listen for mouse events to handle scrolling by dragging the thumb
  useEffect(() => {
    document.addEventListener('mousemove', handleThumbMousemove);
    document.addEventListener('mouseup', handleThumbMouseup);
    document.addEventListener('mouseleave', handleThumbMouseup);

    return () => {
      document.removeEventListener('mousemove', handleThumbMousemove);
      document.removeEventListener('mouseup', handleThumbMouseup);
      document.removeEventListener('mouseleave', handleThumbMouseup);
    };
    
  }, [handleThumbMousemove, handleThumbMouseup]);

  useEffect(() => {
    document.addEventListener('touchmove', handleThumbTouchmove, {passive: false});
    document.addEventListener('touchend', handleThumbTouchend, {passive: false});
    document.addEventListener('touchcancel', handleThumbTouchend, {passive: false});

    return () => {
      document.removeEventListener('touchmove', handleThumbTouchmove);
      document.removeEventListener('touchend', handleThumbTouchend);
      document.removeEventListener('touchcancel', handleThumbTouchend);
    };
  }, [handleThumbTouchmove, handleThumbTouchend]);

  return (
    <div
      className="custom-scrollbars__scrollbar-vertical"
      style={{visibility: isHideScrollbar ? 'hidden' : 'visible'}}
    >
      <FontAwesomeIcon 
        icon={faCaretUp}
        className="custom-scrollbars__button"
        onClick={() => handleScrollButton('up')}
      />
      <div className="custom-scrollbars__track-and-thumb-vertical">
        <div
          className="custom-scrollbars__track-vertical"
          ref={scrollTrackRef}
          onClick={handleTrackClick}
        ></div>
        <div
          className="custom-scrollbars__thumb-vertical"
          ref={scrollThumbRef}
          onMouseDown={handleThumbMousedown}
          onTouchStart={handleThumbTouchstart}
          style={{ height: `${thumbHeight}px` }}
        ></div>
      </div>
      <FontAwesomeIcon 
        icon={faCaretDown}
        className="custom-scrollbars__button"
        onClick={() => handleScrollButton('down')}
      />
    </div>
  );
});

export default VerticalScrollbar;