import { LoadingButton } from "@mui/lab";
import { Box, Button, Checkbox, CircularProgress, FormControlLabel, Grid, Stack, TextField, Typography } from "@mui/material";
import { useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { EquipHarmoListModel } from "../../../models/breakModel";
import { Path as RoutePath } from "../../../path";
import { ApplicationState, actionCreators, selectors } from "../../../store";
import FetchHelper from "../../FetchHelper";
//-----------
//FETCH ID
const GET_EQUIP_HARMO = "GET_EQUIP_HARMO"
const UPDATE_EQUIP_HARMO = "UPDATE_EQUIP_HARMO"
const CREATE_EQUIP_HARMO = "CREATE_EQUIP_HARMO"

const initialValue:EquipHarmoListModel = {
    lHarmoListId:0,
    b3phases:false,
    bIsDefDetail:false,
    bIsDefKind:false,
    dCoefficient:0,
    dPercent5:0,
    dPercent7:0,
    dPercent11:0,
    dPercent13:0,
    dPercent17:0,
    dPercent19:0,
    dPercent23:0,
    dPercent25:0,
    lDetailCode:0,
    lKindDode:0,
    sDetailName:"",
    sExample:"",
    sKindName:"",
}
// -------------
// Props
export type EquipBusductEditProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
export const EquipHarmoEdit = (props: EquipBusductEditProps) => {
    const {
        userId,
        onCreateError,
        onUpdate, onUpdateError,
        onSuccess, onError,
        onCreate
    } = props;

    const { control, formState: { errors }, handleSubmit, setValue, getValues, } = useForm<EquipHarmoListModel>({
        criteriaMode: "all",
        mode:"all",
        defaultValues: initialValue,
    })
    type MyParams = {
        id: string;
        mode: string;
    };
    let { id, mode } = useParams<keyof MyParams>() as MyParams;
    let navigate = useNavigate();
    const [loadingFlag, setLoadingFlag] = useState(mode === "edit" ? true : false);
    const gridRef = useRef() as any;
    const Exp = /^[-+]?\d+(\.\d+)?$/;
    const messageIsNotNumber = "数字を入力してください。";

    const handleSuccess = (data: any) => {
        setLoadingFlag(false);
        if(data && data.resultCode == 0){
            setValue("b3phases",data.data.b3phases);
            setValue("bIsDefDetail",data.data.bIsDefDetail);
            setValue("bIsDefKind",data.data.bIsDefKind);
            setValue("dCoefficient",data.data.dCoefficient?? "");
            setValue("dPercent5",data.data.dPercent5?? "");
            setValue("dPercent7",data.data.dPercent7?? "");
            setValue("dPercent11",data.data.dPercent11?? "");
            setValue("dPercent13",data.data.dPercent13?? "");
            setValue("dPercent17",data.data.dPercent17?? "");
            setValue("dPercent19",data.data.dPercent19?? "");
            setValue("dPercent23",data.data.dPercent23?? "");
            setValue("dPercent25",data.data.dPercent25?? "");
            setValue("lDetailCode",data.data.lDetailCode?? "");
            setValue("lKindDode",data.data.lKindDode?? "");
            setValue("sDetailName",data.data.sDetailName?? "");
            setValue("sExample",data.data.sExample?? "");
            setValue("sKindName",data.data.sKindName?? "");
        }
    }

    // 検索エラー時
    const handleError = (success: boolean, data: any, error: any) => {
        setLoadingFlag(false);
        console.log('>>>> handle Error');
        gridRef.current.hideOverlay();
    };

    // 検索実行
    const handleEditSubmit = (data: EquipHarmoListModel) => {
        setLoadingFlag(true);
        if(mode === "edit") {
            let request = {
                lHarmoListId:id,
                b3phases:data.b3phases,
                bIsDefDetail:data.bIsDefDetail,
                bIsDefKind:data.bIsDefKind,
                dCoefficient:data.dCoefficient,
                dPercent5:data.dPercent5,
                dPercent7:data.dPercent7,
                dPercent11:data.dPercent11,
                dPercent13:data.dPercent13,
                dPercent17:data.dPercent17,
                dPercent19:data.dPercent19,
                dPercent23:data.dPercent23,
                dPercent25:data.dPercent25,
                lDetailCode:data.lDetailCode,
                lKindDode:data.lKindDode,
                sDetailName:data.sDetailName,
                sExample:data.sExample,
                sKindName:data.sKindName,
            } as any
            const object = {
                requestPrms:request,
                userId: userId,
            }
            onUpdate(object);
        } else if (mode === "add") {
            let request = {
                b3phases:data.b3phases,
                bIsDefDetail:data.bIsDefDetail,
                bIsDefKind:data.bIsDefKind,
                dCoefficient:data.dCoefficient,
                dPercent5:data.dPercent5,
                dPercent7:data.dPercent7,
                dPercent11:data.dPercent11,
                dPercent13:data.dPercent13,
                dPercent17:data.dPercent17,
                dPercent19:data.dPercent19,
                dPercent23:data.dPercent23,
                dPercent25:data.dPercent25,
                lDetailCode:data.lDetailCode,
                lKindDode:data.lKindDode,
                sDetailName:data.sDetailName,
                sExample:data.sExample,
                sKindName:data.sKindName,
            } as any
            const object = {
                requestPrms:request,
                userId: userId,
            }
            onCreate(object);
        }
    }

    // キャンセル
    const handleCancel = () => {
        navigate(RoutePath.EquipHarmoList);
    }

    // Submit後のAPI呼び出しエラー時
    const handleErrorUpdate = (success: boolean, data: any, error: any) => {
        onUpdateError();
        setLoadingFlag(false);
    }

    // Submit後のAPI呼び出し成功時
    const handleSuccessSubmit = (success: boolean, data: any, error: any) => {
        onSuccess();
        if (mode === "add") {
            navigate(RoutePath.EquipHarmoEdit + `/edit/${data.data.lHarmoListId}`, { replace: true })
        }
        setLoadingFlag(false)
    }

    // Submit後のAPI呼び出しエラー時
    const handleSubmitError = (success: boolean, data: any, error: any) => {
        onCreateError();
        setLoadingFlag(false)
    }

    //入力欄背景
    const divStyles = {
        backgroundColor: 'white',
        border: '1px solid #bebebe',
        boxShadow: '0 0 5px #fff, 0 0 5px #bebebe, 0 0 1px #aaa',
        padding: '20px 40px',
        marginTop: '40px',
    }

    return (
        <>
            {
                mode === "edit" && 
                <FetchHelper
                fetchId={GET_EQUIP_HARMO}
                url={`/master/equip_harmolist/get`}
                method="POST"
                json={true}
                request={true}
                params={{
                    requestPrms:{
                        id:id
                    },
                    userId: userId,
                }}
                onComplete={(success, data, error) => {
                    success ? handleSuccess(data) : handleError(success, data, error);
                }}
            />
            }
            {loadingFlag && (
                <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1500, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                    <CircularProgress />
                </div>
            )}
            <div style={{ height: "100%", width: "100%", marginTop: "10px" }}>
                <Stack direction="row" justifyContent="flex-start" alignItems="center" sx={{}} style={{ margin: '18px', color: 'MenuText' }}>
                    <Typography variant="h4">
                        {/* FIXME: */}
                        高調波発生機器詳細
                    </Typography>
                </Stack>
                <form onSubmit={handleSubmit(handleEditSubmit)}>
                    <div style={divStyles}>
                        <Typography variant="h6">
                            {/* FIXME: */}
                            高調波発生機器
                        </Typography>
                        <Stack mt={2} direction={{ xs: 'column', sm: 'row' }} sx={{maxHeight: "calc(100vh)", overflow: 'auto'}}>
                            <Grid width={"100%"} spacing={2}>
                                <Grid pt={1} spacing={2} container direction={"row"}>
                                    <Grid item xs={4}>
                                        <Controller
                                            control={control}
                                            name="dCoefficient"
                                            rules={{
                                                pattern: {
                                                    value: Exp,
                                                    message: messageIsNotNumber,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.dCoefficient}
                                                    helperText={errors?.dCoefficient?.message}
                                                    label="換算係数"
                                                    type="text"
                                                    size="small"

                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Controller
                                            control={control}
                                            name="dPercent5"
                                            rules={{
                                                pattern: {
                                                    value: Exp,
                                                    message: messageIsNotNumber,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.dPercent5}
                                                    helperText={errors?.dPercent5?.message}
                                                    label="5次（%）"
                                                    type="text"
                                                    size="small"

                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Controller
                                            control={control}
                                            name="dPercent7"
                                            rules={{
                                                pattern: {
                                                    value: Exp,
                                                    message: messageIsNotNumber,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.dPercent7}
                                                    helperText={errors?.dPercent7?.message}
                                                    label="7次（%）"
                                                    type="text"
                                                    size="small"

                                                />
                                            )}
                                        />
                                    </Grid>                                
                                </Grid>
                                <Grid mt={0} spacing={2} container direction={"row"}>
                                    <Grid item xs={4}>
                                        <Controller
                                            control={control}
                                            name="dPercent11"
                                            rules={{
                                                pattern: {
                                                    value: Exp,
                                                    message: messageIsNotNumber,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.dPercent11}
                                                    helperText={errors?.dPercent11?.message}
                                                    label="11次（%）"
                                                    type="text"
                                                    size="small"

                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Controller
                                            control={control}
                                            name="dPercent13"
                                            rules={{
                                                pattern: {
                                                    value: Exp,
                                                    message: messageIsNotNumber,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.dPercent13}
                                                    helperText={errors?.dPercent13?.message}
                                                    label="13次（%）"
                                                    type="text"
                                                    size="small"

                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Controller
                                            control={control}
                                            name="dPercent17"
                                            rules={{
                                                pattern: {
                                                    value: Exp,
                                                    message: messageIsNotNumber,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.dPercent17}
                                                    helperText={errors?.dPercent17?.message}
                                                    label="17次（%）"
                                                    type="text"
                                                    size="small"

                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid mt={0} spacing={2} container direction={"row"}>
                                    <Grid item xs={4}>
                                        <Controller
                                            control={control}
                                            name="dPercent19"
                                            rules={{
                                                pattern: {
                                                    value: Exp,
                                                    message: messageIsNotNumber,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.dPercent19}
                                                    helperText={errors?.dPercent19?.message}
                                                    label="19次（%）"
                                                    type="text"
                                                    size="small"

                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Controller
                                            control={control}
                                            name="dPercent23"
                                            rules={{
                                                pattern: {
                                                    value: Exp,
                                                    message: messageIsNotNumber,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.dPercent23}
                                                    helperText={errors?.dPercent23?.message}
                                                    label="23次（%）"
                                                    type="text"
                                                    size="small"

                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Controller
                                            control={control}
                                            name="dPercent25"
                                            rules={{
                                                pattern: {
                                                    value: Exp,
                                                    message: messageIsNotNumber,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.dPercent25}
                                                    helperText={errors?.dPercent25?.message}
                                                    label="25次（%）"
                                                    type="text"
                                                    size="small"

                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid mt={0} spacing={2} container direction={"row"}>
                                    <Grid item xs={4}>
                                        <Controller
                                            control={control}
                                            name="lDetailCode"
                                            rules={{
                                                pattern: {
                                                    value: Exp,
                                                    message: messageIsNotNumber,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.lDetailCode}
                                                    helperText={errors?.lDetailCode?.message}
                                                    label="回路細分コード"
                                                    type="text"
                                                    size="small"

                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Controller
                                            control={control}
                                            name="lKindDode"
                                            rules={{
                                                pattern: {
                                                    value: Exp,
                                                    message: messageIsNotNumber,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.lKindDode}
                                                    helperText={errors?.lKindDode?.message}
                                                    label="回路分類コード"
                                                    type="text"
                                                    size="small"

                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid mt={0} spacing={2} container direction={"row"}>
                                    <Grid item xs={4}>
                                        <Controller
                                            control={control}
                                            name="sDetailName"
                                            render={({ field }:any) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    label="回路種別"
                                                    type="text"
                                                    size="small"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Controller
                                            control={control}
                                            name="sExample"
                                            render={({ field }:any) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    label="主な利用例"
                                                    type="text"
                                                    size="small"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Controller
                                            control={control}
                                            name="sKindName"
                                            render={({ field }:any) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    label="回路分類名"
                                                    type="text"
                                                    size="small"
                                                />
                                            )}
                                        />
                                    </Grid>                            
                                </Grid>
                                <Grid mt={0} spacing={2} container direction={"row"}>
                                    <Grid item xs={12} md={6} >
                                        <Grid mt={0} container direction={"row"}>
                                            <Grid item xs={4}>
                                                <Controller
                                                    name="b3phases"
                                                    control={control}
                                                    render={({ field }: any) => (
                                                        <FormControlLabel {...field} control={<Checkbox />} checked={field.value} label="相" labelPlacement="start" />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Controller
                                                    name="bIsDefDetail"
                                                    control={control}
                                                    render={({ field }: any) => (
                                                        <FormControlLabel {...field} control={<Checkbox />} checked={field.value} label="デフォルトの回路細分" labelPlacement="start" />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Controller
                                                    name="bIsDefKind"
                                                    control={control}
                                                    render={({ field }: any) => (
                                                        <FormControlLabel {...field} control={<Checkbox />} checked={field.value} label="デフォルトの回路分類" labelPlacement="start" />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Stack>
                    </div>
                    <Stack direction='row' justifyContent="flex-end" spacing={2} sx={{ mt: 1, mr: 2 }}>
                        <LoadingButton variant="contained" type="submit" loading={loadingFlag}>保存</LoadingButton>
                        <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                    </Stack>
                </form>
            </div>
            <FetchHelper fetchId={UPDATE_EQUIP_HARMO}
                onComplete={(success: boolean, data: any, error: any) =>
                    success ? handleSuccessSubmit(success, data, error) : handleErrorUpdate(success, data, error)} />
            <FetchHelper fetchId={CREATE_EQUIP_HARMO}
                onComplete={(success, data, error) => success ?
                    handleSuccessSubmit(success, data, error) :
                    handleSubmitError(success, data, error)} />
        </>
    );
}
const mapStateToProps = (state: ApplicationState) => (
    {
        userId: state.app.user?.userId,
        fetchUpdate: selectors.getFormState(
            state,
            // GET_BREAK_CAPACITY
            UPDATE_EQUIP_HARMO
        ),
    }
)
const mapDispatchToProps = (dispatch: any) => {
    return {
        onCreate: (data: any) => dispatch(actionCreators.fetch(CREATE_EQUIP_HARMO, `/master/equip_harmolist/add`, "POST", data, false, true)),
        onUpdate: (data: any) => dispatch(actionCreators.fetch(UPDATE_EQUIP_HARMO, `/master/equip_harmolist/modify`, "POST", data,false,true)),
        onSuccess: () =>
            dispatch(actionCreators.showMessage({
                type: "info",
                title: "高調波発生機器更新",
                body: "高調波発生機器を更新しました。",
            })),
        onError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "検索",
                body: "検索できません。",
            })),
        onCreateError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "高調波発生機器登録",
                body: "高調波発生機器を登録出来ませんでした。",
            })),
        onUpdateError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "高調波発生機器編集",
                body: "高調波発生機器を更新出来ませんでした。",
            })),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(EquipHarmoEdit as any);