export function BeamsNumber(
    inValue: number,
    beamsNumber: number,
    useNoData: boolean = false
) {
    // if (value < 0 && useNoData)
    //     return 'データなし'
    
    let value = Number(inValue);
    let absInteger = Math.trunc(Math.abs(value))
    let lenInt = absInteger.toString().length

    if (lenInt >= beamsNumber){
        return Math.round(value)
    }
    return Number.parseFloat(value.toPrecision(beamsNumber))
}

//DLLGetTextByBeams
export const formatForUndefined = (dValue:number, bUseNodata:boolean = false) =>
{
	let sValue;

    if(dValue === undefined || dValue === null) {
        sValue = 'ﾃﾞｰﾀなし';
    }
	else if (dValue < 0 && bUseNodata == true) {
		sValue = 'ﾃﾞｰﾀなし';
	}
	else {
		sValue = dValue.toString();
	}
	return sValue;
}