import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Grid, IconButton } from "@mui/material";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from 'ag-grid-react';
import { forwardRef, useImperativeHandle, useMemo, useRef, useState } from "react";
import { PageData } from '../../../models/Index';
import { BreakOpeKindModel } from '../../../models/breakModel';
import { AG_GRID_LOCALE_JP } from '../../../themes/localeJP';
import LoadingOverlay, { DefaultLoadingMessage } from '../../common/LoadingOverlay';
import NoRowsOverlay, { NoRowsMessage } from '../../common/NoRowsOverlay';
import PaginationCpn from '../../common/PaginationCpn';

// -------------
// Props
export type BreakOpeKindGridProps = {
    colState: any[];
    pageSize?: number;
    pageData?: PageData;
    onChangeSearchCondition: (name: string, value: any) => void;
    keyword: string;
    onDelete: (data: BreakOpeKindModel) => void;
    onShowDetails: (lBreakOpeKindID: string) => void;
    heightForm?: string;
    checkEdit?: boolean;
    mode?:string
};

// -------------
// Component
export const BreakOpeKindGrid = forwardRef((props: BreakOpeKindGridProps, ref) => {
    const {
        pageSize,
        pageData,
        keyword,
        heightForm,
        checkEdit,
        onChangeSearchCondition,
        onDelete,
        onShowDetails,
        mode
    } = props;

    const gridRef = useRef() as any;
    const [rowData, setRowData] = useState();
    useImperativeHandle(ref, () => ({
        setRowData(value: any) {
            setRowData(value)
        },

        hideOverlay() {
            gridRef.current.api.hideOverlay();
        }
    }));

    // ag-Grid の準備完了後に呼ばれるコールバック関数
    const onGridReady = (grid: any) => {
    };

    // Loading時のメッセージ
    const loadingOverlayComponent = useMemo(() => {
        return LoadingOverlay;
    }, []);

    const loadingOverlayComponentParams = useMemo(() => {
        return {
            loadingMessage: checkEdit ? "" : DefaultLoadingMessage,
        };
    }, []);

    const noRowsOverlayComponent = useMemo(() => {
        return NoRowsOverlay;
    }, []);

    const noRowsOverlayComponentParams = useMemo(() => {
        return {
            noRowsMessageFunc: () => NoRowsMessage,
        };
    }, []);
    const cellRenderTypeName =(props: any) => {
        return props.data.ltypeID +" "+ props.data.stypeName
    }
    // 編集ボタンのセル内表示とボタンイベント
    const cellMessageRenderer = (props: any) => {
        // 詳細
        const handleEdit = () => {
            onShowDetails(props.data.lopeKindID);
        };

        const handleDelete = () => {
            onDelete(props.data.lopeKindID);
        };

        return (
            <Grid container direction="row" justifyContent="flex-start" paddingLeft={"-60px"}>
                <IconButton aria-label="edit" onClick={handleEdit} >
                    <EditIcon fontSize="small" />
                </IconButton>
                <IconButton aria-label="delete" onClick={handleDelete} >
                    <DeleteIcon fontSize="small" />
                </IconButton>
            </Grid>
        );
    };

    // 全列に共通の定義
    const defaultColDef = {
        sortable: true, // ソート可
        editable: false, // 編集不可
        resizable: true,
        suppressMovable: true,  // 列の移動を無効にする
    };

    // 列タイプの定義
    const columnTypes = {
        nonEditableColumn: { editable: false }, // 編集不可
    };

    // 列の個別定義
    const columnDefs = [
        {
            headerName: "動作種類ID",
            field: "lopeKindID",
            rowDrag: false,
            width: 120
        },
        {
            headerName: "型式",
            field: "ltypeID",
            rowDrag: false,
            width: 120,
            cellRenderer: cellRenderTypeName
        },
        {
            headerName: "デフォルト",
            field: "bisDefault",
            rowDrag: false,
            width: 120
        },
        {
            headerName: "動作種類名",
            field: "sopeKindName",
            rowDrag: false,
            width: 250
        },
        {
            headerName: "定格可変方法",
            field: "nratedFlag",
            rowDrag: false,
            width: 120
        },
        {
            headerName: "定格ダイアルタイトル",
            field: "sratedTitle",
            rowDrag: false,
            width: 250
        },
        {
            headerName: "定格单位",
            field: "sratedUnit",
            rowDrag: false,
            width: 120
        },
        {
            headerName: "可变方法",
            field: "nmagniFlag",
            rowDrag: false,
            width: 120
        },
        {
            headerName: "定格倍率タイトル",
            field: "smagniTitle",
            rowDrag: false,
            width: 250
        },
        {
            headerName: "定格倍率単位",
            field: "smagniUnit",
            rowDrag: false,
            width: 120
        },
        {
            headerName: "開極時間設定",
            field: "buseOpenTime",
            rowDrag: false,
            width: 120
        },
        {
            headerName: "遮断時間設定",
            field: "buseBreakingTime",
            rowDrag: false,
            width: 120
        },
        {
            headerName: "",
            field: "option",
            reseizable: false,
            suppressMovable: true,
            width: 110,
            cellRenderer: cellMessageRenderer,
        }
    ];
    if(mode==="edit"){
        columnDefs.splice(columnDefs.findIndex(item =>item.field==="ltypeID"),1)
    }
    const rowHeight = 48;
    gridRef.current?.api?.setQuickFilter(keyword);
    const handleEdit = (props: any) => {
        onShowDetails(props.data.lopeKindID);
    }
    return (
        <>
            <div>
                <div className="ag-theme-alpine" style={{ height: heightForm ? 'calc(100vh - ' + heightForm + 'px)' : 'calc(100vh - 275px)', width: "100%" }}>
                    <AgGridReact
                        ref={gridRef}
                        defaultColDef={defaultColDef}
                        columnTypes={columnTypes}
                        columnDefs={columnDefs}
                        onGridReady={onGridReady}
                        domLayout={"normal"}
                        rowData={rowData}
                        animateRows={true}
                        rowDragManaged={true}
                        suppressMovableColumns={false}
                        suppressDragLeaveHidesColumns={false}
                        rowHeight={rowHeight}
                        localeText={AG_GRID_LOCALE_JP}
                        paginationPageSize={pageSize}
                        onCellDoubleClicked={handleEdit}
                        loadingOverlayComponent={loadingOverlayComponent}
                        loadingOverlayComponentParams={loadingOverlayComponentParams}
                        noRowsOverlayComponent={noRowsOverlayComponent}
                        noRowsOverlayComponentParams={noRowsOverlayComponentParams}
                    />
                </div>
                {pageData && pageSize && <PaginationCpn pageData={pageData} onChangeSearchCondition={onChangeSearchCondition} limit={pageSize} />}
            </div>
        </>
    );
});

export default BreakOpeKindGrid;