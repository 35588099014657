import { Box, Button, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, Stack, Tab, Tabs, TextField, Typography } from "@mui/material";
import { IeleBusductSPhase, InfoCircuit, MsBusductModel, OptionModel } from "../../models/Index";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import TextFieldOptions from "../common/TextFieldOptions";
import { BeamsNumber } from "../../utils/FormatNumber";
import { CALC_IK2, CALC_IK2_OUTSIDE_NEUTRAL, CALC_IK2_OUTSIDE_OUTSIDE, CALC_IK2_SEAT_MAIN, CALC_IK2_SEAT_T, CALC_IK3, MS_3PHASES, MS_OUTSIDE_NEUTRAL, MS_OUTSIDE_OUTSIDE, MS_SEAT_MAIN, MS_SEAT_T, MS_TRANS1, MS_TRANSSCOTT, RS_3PHASE, RS_PHASE_N, RS_PHASE_PE } from "../../models/Constants";
import { MS_MODE_CHECKING, MS_MODE_DRAWING, RS_3PHASES, VALUE_NODE_VOLT_MIN } from "../../statics";
import { isNumber } from "chart.js/helpers";
import * as Constant from "../../models/Constants";
import { doInitComboRatedCurrent } from "../../utils/busductFunction";

let list: OptionModel[] = [];
export type MsBusductEditDialogFormProps = {
    data: any;
    onOK: (data: any) => void;
    onCancel: () => void;
    setIsDisplayTabConnectTran1: boolean;
    setIsDisplayTabConnectTranscott:boolean;
    setIsVoltText: string;
    currentTabId: boolean;
    infoCircuit: InfoCircuit
    userRole: string;
    m_bModePM: boolean;
    dispInputDialog: any;
    processMode:number;
    modeViewOnly:boolean;
};

const checkboxStyle = {
    padding: '0px 0px 0px 9px'
}
const checkboxStyles = {
    padding: '4px 4px 4px 8px'
}
const radioStyles = {
    padding: '0 0 0 9px'
}

function a11yProps(index: number) {
    return {
        id: `tab-${index}`,
        "aria-controls": `tabpanel-${index}`,
    };
}

//Tabs------------------
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

export const MsBusductEditDialog = (props: MsBusductEditDialogFormProps) => {
    const { userRole, data, modeViewOnly, onOK, onCancel,setIsDisplayTabConnectTran1,setIsVoltText, setIsDisplayTabConnectTranscott,currentTabId ,infoCircuit,m_bModePM,dispInputDialog,processMode} = props;
    const [selectTab, setSelectTab] = useState(0);

    // 初期値設定
    const initialValue: MsBusductModel = {
        ...data,
        calcPoint0: {...data.calcPoint0, loadCapacity: BeamsNumber(data.calcPoint0.loadCapacity,7),
            loadCurrent: BeamsNumber(data.calcPoint0.loadCurrent,5)
        },
        calcPoint1: {...data.calcPoint1, loadCapacity: BeamsNumber(data.calcPoint1.loadCapacity,7),
            loadCurrent: BeamsNumber(data.calcPoint1.loadCurrent,5)
        }
    }
    const { control, handleSubmit, formState: { errors }, setValue , getValues,clearErrors,setError } = useForm<MsBusductModel>({
        mode: 'all',
        criteriaMode: "all",
        defaultValues: initialValue,
    });
    const [makeDataInput, setMakeDataInput] = useState(initialValue.makeDataInput); // ModePM
    const [isDisplayTabTabSPhase, setIsDisplayTabSPhase] = useState(initialValue.wireSystem);
    const [isDisableByPointRegular, setIsDisableByPointRegular] = useState(initialValue.dropRegular);
    const [isModeSPhases] = useState(data.isModeSPhases);



    // FormデータからRequestデータを作成する
    const createRequestData = (formValue: MsBusductModel) => {
        if(!Number(formValue.susceptance)){
            formValue.susceptance = 0
        }
        if(!/^[0-9]*[.]?[0-9]+$/.test(formValue.calcPoint0.loadCurrent.toString())){
            formValue.calcPoint0.loadCurrent = 0
        }
        if(!/^[0-9]*[.]?[0-9]+$/.test(formValue.calcPoint0.loadCapacity.toString())){
            formValue.calcPoint0.loadCapacity = 0
        }
        if(!/^[0-9]*[.]?[0-9]+$/.test(formValue.calcPoint0.powerFactor.toString())){
            formValue.calcPoint0.powerFactor = 0
        }
        if(!/^[0-9]*[.]?[0-9]+$/.test(formValue.calcPoint1.loadCurrent.toString())){
            formValue.calcPoint1.loadCurrent = 0
        }
        if(!/^[0-9]*[.]?[0-9]+$/.test(formValue.calcPoint1.loadCapacity.toString())){
            formValue.calcPoint1.loadCapacity = 0
        }
        if(!/^[0-9]*[.]?[0-9]+$/.test(formValue.calcPoint1.powerFactor.toString())){
            formValue.calcPoint1.powerFactor = 0
        }
        let request = {
            ...formValue
        };
        return request;
    }
    useEffect(()=> {
        setValue("sPhase",data.isModeSPhases)
    },[])
    // Submitイベント
    const handleEditSubmit = (value: MsBusductModel) => {
        const request = createRequestData(value);
        onOK(request);
    }

    const handleCancel = () => {
        onCancel();
    };

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        if (newValue >= 0) {
            if (getValues('refNo') == "" ||getValues('refNo') == null || getValues('refNo') == undefined ) {
                setError("refNo",{ type: 'custom', message: '必須項目です。入力してください。' });
            }
            else if (isNaN(Number(getValues('voltage'))) || Number(getValues('voltage')) <= 0 || !/^[0-9\b]+$/.test(getValues('voltage').toString())) {
                setError("voltage",{ type: 'custom', message: '整数 > 0 を入力してください。' });
            }
            else if (isNaN(Number(getValues('length'))) || Number(getValues('length')) < 0) {
                setError("length",{ type: 'custom', message: '数字 >= 0 を入力してください。' });
            }
            else if (selectTab == 2 &&(isNaN(Number(getValues('m_3Sphase.ratedCurrent'))) || Number(getValues('m_3Sphase.ratedCurrent')) <= 0)){
                setError("m_3Sphase.ratedCurrent",{ type: 'custom', message: '数字 > 0 を入力してください。' });
            }
            else if (selectTab == 2 &&(isNaN(Number(getValues('m_3Sphase.width'))) || Number(getValues('m_3Sphase.width')) <= 0 || !/^[0-9\b]+$/.test(getValues('m_3Sphase.width').toString()))){
                setError("m_3Sphase.width",{ type: 'custom', message: '整数 > 0 を入力してください。' });
            }
            else if (selectTab == 2 &&(isNaN(Number(getValues('m_3Sphase.thickness'))) || Number(getValues('m_3Sphase.thickness')) <= 0)){
                setError("m_3Sphase.thickness",{ type: 'custom', message: '数字 > 0 を入力してください。' });
            }
            else if (selectTab == 2 &&(isNaN(Number(getValues('m_3Sphase.numberPhase'))) || Number(getValues('m_3Sphase.numberPhase')) <= 0)){
                setError("m_3Sphase.numberPhase",{ type: 'custom', message: '数字 > 0 を入力してください。' });
            }
            else if (selectTab == 2 &&(isNaN(Number(getValues('m_3Sphase.ohmRPerM'))) || Number(getValues('m_3Sphase.ohmRPerM')) <= 0)){
                setError("m_3Sphase.ohmRPerM",{ type: 'custom', message: '数字 > 0 を入力してください。' });
            }
            else if (selectTab == 2 &&(isNaN(Number(getValues('m_3Sphase.ohmXPerM'))) || Number(getValues('m_3Sphase.ohmXPerM')) <= 0)){
                setError("m_3Sphase.ohmXPerM",{ type: 'custom', message: '数字 > 0 を入力してください。' });
            }
            //===
            else if ((isDisplayTabTabSPhase === 2 || isDisplayTabTabSPhase === 3)&& !isModeSPhases  &&selectTab == 3&&(isNaN(Number(getValues('SPhaseN.ratedCurrent'))) || Number(getValues('SPhaseN.ratedCurrent')) <= 0 || Number(getValues('SPhaseN.ratedCurrent')) < Number(getValues('m_3Sphase.ratedCurrent')))){
                setError("SPhaseN.ratedCurrent",{ type: 'custom', message: '数字 >= '+ Number(getValues('m_3Sphase.ratedCurrent'))+ ' を入力してください。' });
            }
            else if ((isDisplayTabTabSPhase === 2 || isDisplayTabTabSPhase === 3)&& !isModeSPhases  &&selectTab == 3 &&(isNaN(Number(getValues('SPhaseN.width'))) || Number(getValues('SPhaseN.width')) <= 0 || !/^[0-9\b]+$/.test(getValues('SPhaseN.width').toString()))){
                setError("SPhaseN.width",{ type: 'custom', message: '整数 > 0 を入力してください。' });
            }
            else if ((isDisplayTabTabSPhase === 2 || isDisplayTabTabSPhase === 3)&& !isModeSPhases  &&selectTab == 3 &&(isNaN(Number(getValues('SPhaseN.thickness'))) || Number(getValues('SPhaseN.thickness')) <= 0)){
                setError("SPhaseN.thickness",{ type: 'custom', message: '数字 > 0 を入力してください。' });
            }
            else if ((isDisplayTabTabSPhase === 2 || isDisplayTabTabSPhase === 3)&& !isModeSPhases  &&selectTab == 3 &&(isNaN(Number(getValues('SPhaseN.numberPhase'))) || Number(getValues('SPhaseN.numberPhase')) <= 0)){
                setError("SPhaseN.numberPhase",{ type: 'custom', message: '数字 > 0 を入力してください。' });
            }
            else if ((isDisplayTabTabSPhase === 2 || isDisplayTabTabSPhase === 3)&& !isModeSPhases  &&selectTab == 3 &&(isNaN(Number(getValues('SPhaseN.ohmRPerM'))) || Number(getValues('SPhaseN.ohmRPerM')) <= 0)){
                setError("SPhaseN.ohmRPerM",{ type: 'custom', message: '数字 > 0 を入力してください。' });
            }
            else if ((isDisplayTabTabSPhase === 2 || isDisplayTabTabSPhase === 3)&& !isModeSPhases  &&selectTab == 3 &&(isNaN(Number(getValues('SPhaseN.ohmXPerM'))) || Number(getValues('SPhaseN.ohmXPerM')) <= 0)){
                setError("SPhaseN.ohmXPerM",{ type: 'custom', message: '数字 > 0 を入力してください。' });
            }
            //===
            else if (isDisplayTabTabSPhase === 3 && !isModeSPhases  &&selectTab == 4 &&(isNaN(Number(getValues('sPhasePE.ratedCurrent'))) || Number(getValues('sPhasePE.ratedCurrent')) <= 0 || Number(getValues('sPhasePE.ratedCurrent')) < Number(getValues('m_3Sphase.ratedCurrent')))){
                setError("sPhasePE.ratedCurrent",{ type: 'custom', message: '数字 >= '+ Number(getValues('m_3Sphase.ratedCurrent'))+ ' を入力してください。' });
            }
            else if ( isDisplayTabTabSPhase === 3 && !isModeSPhases  &&selectTab == 4 &&(isNaN(Number(getValues('sPhasePE.width'))) || Number(getValues('sPhasePE.width')) <= 0  ||!/^[0-9\b]+$/.test(getValues('sPhasePE.width').toString()))){
                setError("sPhasePE.width",{ type: 'custom', message: '整数 > 0 を入力してください。' });
            }
            else if (isDisplayTabTabSPhase === 3 && !isModeSPhases  &&selectTab == 4 &&(isNaN(Number(getValues('sPhasePE.thickness'))) || Number(getValues('sPhasePE.thickness')) <= 0)){
                setError("sPhasePE.thickness",{ type: 'custom', message: '数字 > 0 を入力してください。' });
            }
            else if ( isDisplayTabTabSPhase === 3 && !isModeSPhases  &&selectTab == 4 &&(isNaN(Number(getValues('sPhasePE.numberPhase'))) || Number(getValues('sPhasePE.numberPhase')) <= 0)){
                setError("sPhasePE.numberPhase",{ type: 'custom', message: '数字 > 0 を入力してください。' });
            }
            else if ( isDisplayTabTabSPhase === 3 && !isModeSPhases  &&selectTab == 4 &&(isNaN(Number(getValues('sPhasePE.ohmRPerM'))) || Number(getValues('sPhasePE.ohmRPerM')) <= 0)){
                setError("sPhasePE.ohmRPerM",{ type: 'custom', message: '数字 > 0 を入力してください。' });
            }
            else if ( isDisplayTabTabSPhase === 3 && !isModeSPhases  &&selectTab == 4 &&(isNaN(Number(getValues('sPhasePE.ohmXPerM'))) || Number(getValues('sPhasePE.ohmXPerM')) <= 0)){
                setError("sPhasePE.ohmXPerM",{ type: 'custom', message: '数字 > 0 を入力してください。' });
            }
            //===
            else if((setIsDisplayTabConnectTranscott ||setIsDisplayTabConnectTran1) && selectTab == 3 && (!/^[0-9]*[.]?[0-9]+$/.test(getValues('calcPoint0.loadCapacity').toString()) || Number(getValues('calcPoint0.loadCapacity')) < 0)){
                setError("calcPoint0.loadCapacity",{ type: 'custom', message: '数字 >= 0 を入力してください。' });
            }
            else if((setIsDisplayTabConnectTranscott ||setIsDisplayTabConnectTran1) && selectTab == 3 && (!/^[0-9]*[.]?[0-9]+$/.test(getValues('calcPoint0.loadCurrent').toString()) || Number(getValues('calcPoint0.loadCurrent')) < 0)){
                setError("calcPoint0.loadCurrent",{ type: 'custom', message: '数字 >= 0 を入力してください。' });
            }
            else if((setIsDisplayTabConnectTranscott ||setIsDisplayTabConnectTran1) && selectTab == 3 && (!/^[0-9]*[.]?[0-9]+$/.test(getValues('calcPoint0.powerFactor').toString()) || Number(getValues('calcPoint0.powerFactor')) < 0)){
                setError("calcPoint0.powerFactor",{ type: 'custom', message: '数字 >= 0 を入力してください。' });
            }
            else if((setIsDisplayTabConnectTranscott ||setIsDisplayTabConnectTran1) && selectTab == 4 && (!/^[0-9]*[.]?[0-9]+$/.test(getValues('calcPoint1.loadCapacity').toString()) || Number(getValues('calcPoint1.loadCapacity')) < 0)){
                setError("calcPoint1.loadCapacity",{ type: 'custom', message: '数字 >= 0 を入力してください。' });
            }
            else if((setIsDisplayTabConnectTranscott ||setIsDisplayTabConnectTran1) && selectTab == 4 && (!/^[0-9]*[.]?[0-9]+$/.test(getValues('calcPoint1.loadCurrent').toString()) || Number(getValues('calcPoint1.loadCurrent')) < 0)){
                setError("calcPoint1.loadCurrent",{ type: 'custom', message: '数字 >= 0 を入力してください。' });
            }
            else if((setIsDisplayTabConnectTranscott ||setIsDisplayTabConnectTran1) && selectTab == 4 && (!/^[0-9]*[.]?[0-9]+$/.test(getValues('calcPoint1.powerFactor').toString()) || Number(getValues('calcPoint1.powerFactor')) < 0)){
                setError("calcPoint1.powerFactor",{ type: 'custom', message: '数字 >= 0 を入力してください。' });
            }
            else{
                setSelectTab(newValue);

            }
        }
    };
    const handleDisplayTab =(e:any) =>{
        setValue(e.target.name,e.target.value)
        let sphase = +getValues("wireSystem")
        
        if(sphase === 1  && !isModeSPhases){
            setValue("wireSystem",1)
            setIsDisplayTabSPhase(1)
        }
        if (sphase === 2 && !isModeSPhases) {
            setValue("wireSystem",2)
            setIsDisplayTabSPhase(2)

        }
        else if (sphase === 3 && !isModeSPhases){
            setValue("wireSystem",3)
            setIsDisplayTabSPhase(3)

        }
    }
    const handleDisableByPointRegular =(e:any) =>{
        setValue(e.target.name,e.target.checked)
        setIsDisableByPointRegular(e.target.checked);


    }
    const handleChangeFormat = (e: any) => {
        setValue(e.target.name, e.target.value,{shouldValidate:true});
    }
    const handleChangeMakeDataInput = (e: any) => {
        setValue(e.target.name, e.target.checked);
        setMakeDataInput(e.target.checked);
    }
    const onChangLength =(e:any) =>{
        setValue(e.target.name, e.target.value)
        doSetZValuesBusDuct('',1)
        doSetZValuesBusDuct ('',2)
        doSetZValuesBusDuct('',3)
    }
    const doGetPerZBusDuct = (m_bSPhase:boolean,m_dStdCapacity:number, wireSystem:number,partKind:number,bTwoWays:boolean) =>{
        let percentZ={
             m_dPerRk3 : 0,
            m_dPerXk3 : 0,
            m_dPerRk3Difference :0,
            m_dPerXk3Difference :0,


        }
        let ohmRperM3 = +getValues("m_3Sphase.ohmRPerM")
        let ohmXperM3 = +getValues("m_3Sphase.ohmXPerM")

        let lenght = +getValues("length")
        let voltage =+getValues("voltage")
        if(voltage<=0){
            percentZ.m_dPerRk3 = 0
            percentZ.m_dPerXk3 = 0
        }else{
            switch(wireSystem){
                case RS_3PHASE:
                    if(m_bSPhase){
                        switch(partKind){
                            case MS_OUTSIDE_NEUTRAL:
                                percentZ.m_dPerRk3 = m_dStdCapacity * ohmRperM3 * lenght / (voltage/2)/(voltage/2)*100
                                percentZ.m_dPerXk3 = m_dStdCapacity * ohmXperM3 * lenght / (voltage/2)/(voltage/2)*100
                                if(bTwoWays){
                                    percentZ.m_dPerRk3 *=2
                                    percentZ.m_dPerXk3 *=2
                                }
                                break;
                            case MS_OUTSIDE_OUTSIDE:
                                percentZ.m_dPerRk3 = m_dStdCapacity * ohmRperM3 * lenght /voltage/voltage*100
                                percentZ.m_dPerXk3 = m_dStdCapacity * ohmXperM3 * lenght /voltage/voltage*100
                                if(bTwoWays){
                                    percentZ.m_dPerRk3 *=2
                                    percentZ.m_dPerXk3 *=2
                                }
                                percentZ.m_dPerRk3Difference = m_dStdCapacity * ohmRperM3 * lenght / (voltage/2)/(voltage/2)*100
                                percentZ.m_dPerXk3Difference = m_dStdCapacity * ohmXperM3 * lenght / (voltage/2)/(voltage/2)*100
                                if(bTwoWays){
                                    percentZ.m_dPerRk3Difference *=2
                                    percentZ.m_dPerXk3Difference *=2
                                }
                                percentZ.m_dPerRk3Difference = percentZ.m_dPerRk3 - percentZ.m_dPerRk3Difference
                                percentZ.m_dPerXk3Difference = percentZ.m_dPerXk3 - percentZ.m_dPerXk3Difference
                                break;
                            case MS_SEAT_MAIN:
                            case MS_SEAT_T:
                                percentZ.m_dPerRk3 = m_dStdCapacity * ohmRperM3 * lenght /voltage/voltage*100
                                percentZ.m_dPerXk3 = m_dStdCapacity * ohmXperM3 * lenght /voltage/voltage*100
                                if(bTwoWays){
                                    percentZ.m_dPerRk3 *=2
                                    percentZ.m_dPerXk3 *=2
                                }
                                break;
                        }
                    }
                    else{
                        percentZ.m_dPerRk3 = m_dStdCapacity * ohmRperM3 * lenght /voltage/voltage*100
                        percentZ.m_dPerXk3 = m_dStdCapacity * ohmXperM3 * lenght /voltage/voltage*100
                    }
                    break;
                case RS_PHASE_N:
                    percentZ.m_dPerRk3 = m_dStdCapacity * ohmRperM3 * lenght /voltage/voltage*100
                    percentZ.m_dPerXk3 = m_dStdCapacity * ohmXperM3 * lenght /voltage/voltage*100
                    break;
                case RS_PHASE_PE:
                    percentZ.m_dPerRk3 = m_dStdCapacity * ohmRperM3 * lenght /voltage/voltage*100
                    percentZ.m_dPerXk3 = m_dStdCapacity * ohmXperM3 * lenght /voltage/voltage*100
                    break;
            }
        }
        return percentZ
    }
    const doGetValueDropPerRegular = (voltage:number,m_dStdCapacity:number,m_dPerRk3:number,m_dPerXk3:number,m_nCalcKind:number,powerFactor:number,leadOrLag:number,loadCurrent:number) =>{
        if(powerFactor < 0 || m_dStdCapacity< 0){
            return 0
        }
        let dcapacity = voltage * loadCurrent
        switch(m_nCalcKind){
            case CALC_IK3:
                dcapacity *=Math.sqrt(3.0);
                break;
            case CALC_IK2:
                dcapacity *= 2
                break;
            case CALC_IK2_OUTSIDE_NEUTRAL:
                break;
            case CALC_IK2_OUTSIDE_OUTSIDE:
                dcapacity *= 2
                break;
            case CALC_IK2_SEAT_MAIN:
                dcapacity *= 2
                break;
            case CALC_IK2_SEAT_T:
                dcapacity *= 2
                break;
        }
        let dPercent = 0
        if(powerFactor * powerFactor <=1){
            if(leadOrLag === 1){
                dPercent = m_dPerRk3 * powerFactor - m_dPerXk3 * Math.sqrt(1- powerFactor * powerFactor)
            }
            else{
                dPercent = m_dPerRk3 * powerFactor + m_dPerXk3 * Math.sqrt(1- powerFactor * powerFactor)

            }
        }
        dPercent *= dcapacity
        dPercent /= m_dStdCapacity * 1000
        dPercent /= 100
        if(Math.abs(dPercent) < VALUE_NODE_VOLT_MIN){
            dPercent = 0
        }
        return dPercent
    }
    const doGetValueDropVoltRegular =(voltage:number,m_dStdCapacity:number,m_dPerRk3:number,m_dPerXk3:number,m_nCalcKind:number,powerFactor:number,leadOrLag:number,loadCurrent:number) =>{
        let dPercent = doGetValueDropPerRegular(voltage,m_dStdCapacity,m_dPerRk3,m_dPerXk3,m_nCalcKind,powerFactor,leadOrLag,loadCurrent)
        let dDropVolt = voltage - voltage * dPercent
        if(Math.abs(dDropVolt) < VALUE_NODE_VOLT_MIN){
            dDropVolt = 0
        }
        return dDropVolt

    }
    const doGetDropVoltDiff = (m_bSPhase:boolean,m_dStdCapacity:number,voltage:number,partKind:number,powerFactor:number,leadOrLag:number,loadCurrent:number) =>{
        let perentZ = doGetPerZBusDuct(m_bSPhase,m_dStdCapacity,RS_3PHASE,partKind,false)
        let m_nDownTransKind = getValues("downTransKind")
        let dVolt = 0
        if(m_bSPhase){
            switch(partKind){
                case MS_OUTSIDE_NEUTRAL:
                case MS_SEAT_MAIN:
                    dVolt = doGetValueDropVoltRegular(voltage,m_dStdCapacity,perentZ.m_dPerRk3,perentZ.m_dPerXk3,getValues("calcPoint1.calcKind"),powerFactor,leadOrLag,loadCurrent)
                    break;
                case MS_OUTSIDE_OUTSIDE:
                case MS_SEAT_T:
                    dVolt = doGetValueDropVoltRegular(voltage,m_dStdCapacity,perentZ.m_dPerRk3,perentZ.m_dPerXk3,getValues("calcPoint0.calcKind"),powerFactor,leadOrLag,loadCurrent)
                    break;
                }
                dVolt = voltage - dVolt
        }
        else{
            if(m_nDownTransKind === MS_TRANS1){
                dVolt = doGetValueDropVoltRegular(voltage,m_dStdCapacity, perentZ.m_dPerRk3,perentZ.m_dPerXk3,getValues("calcPoint1.calcKind"),powerFactor,leadOrLag,loadCurrent)
            }
            else{
                    dVolt = doGetValueDropVoltRegular(voltage,m_dStdCapacity, perentZ.m_dPerRk3,perentZ.m_dPerXk3,getValues("calcPoint0.calcKind"),powerFactor,leadOrLag,loadCurrent)
            }
            dVolt = voltage - dVolt
        }
        return BeamsNumber(dVolt,4)
    }
    const doGetDropPerUnit = (m_bSPhase:boolean,m_dStdCapacity:number,voltage:number,partKind:number,powerFactor:number,leadOrLag:number,loadCurrent:number) =>{
        let percentZ = doGetPerZBusDuct(m_bSPhase,m_dStdCapacity,RS_3PHASE,partKind,false)
        let m_nDownTransKind = getValues("downTransKind")
        let dVolt = 0
        let dPercent = 0
        if(m_bSPhase){
            switch(partKind){
                case MS_OUTSIDE_NEUTRAL:
                    setValue("calcPoint1.stdCapacity", m_dStdCapacity)
                    dVolt = doGetValueDropVoltRegular(voltage/2,m_dStdCapacity,percentZ.m_dPerRk3,percentZ.m_dPerXk3,getValues("calcPoint1.calcKind"),powerFactor,leadOrLag,loadCurrent)
                    dPercent = 1 - dVolt / (voltage/2)
                    break;
                case MS_SEAT_MAIN:
                    setValue("calcPoint1.stdCapacity", m_dStdCapacity)

                    dVolt = doGetValueDropVoltRegular(voltage,m_dStdCapacity,percentZ.m_dPerRk3,percentZ.m_dPerXk3,getValues("calcPoint1.calcKind"),powerFactor,leadOrLag,loadCurrent)
                    dPercent = 1 - dVolt / voltage
                    break;

                case MS_OUTSIDE_OUTSIDE:
                    setValue("calcPoint0.stdCapacity", m_dStdCapacity)

                    dVolt = doGetValueDropVoltRegular(voltage,m_dStdCapacity,percentZ.m_dPerRk3,percentZ.m_dPerXk3,getValues("calcPoint0.calcKind"),powerFactor,leadOrLag,loadCurrent)
                    dPercent = 1 - dVolt / voltage
                    break;
                case MS_SEAT_T:
                    setValue("calcPoint0.stdCapacity", m_dStdCapacity)
                    dVolt = doGetValueDropVoltRegular(voltage,m_dStdCapacity,percentZ.m_dPerRk3,percentZ.m_dPerXk3,getValues("calcPoint0.calcKind"),powerFactor,leadOrLag,loadCurrent)
                    dPercent = 1 - dVolt / voltage
                    break;
                }
        }
        else{
            if(m_nDownTransKind === MS_TRANS1){
                setValue("calcPoint1.stdCapacity", m_dStdCapacity)
                dVolt = doGetValueDropVoltRegular(voltage,m_dStdCapacity,percentZ.m_dPerRk3,percentZ.m_dPerXk3,getValues("calcPoint1.calcKind"),powerFactor,leadOrLag,loadCurrent)
            }
            else{
                setValue("calcPoint0.stdCapacity", m_dStdCapacity)
                dVolt = doGetValueDropVoltRegular(voltage,m_dStdCapacity,percentZ.m_dPerRk3,percentZ.m_dPerXk3,getValues("calcPoint0.calcKind"),powerFactor,leadOrLag,loadCurrent)
            }
            dPercent = 1- dVolt / voltage
        }
        dPercent *= 100
        return BeamsNumber(dPercent,4)
    }
    const doSetValueVolDrop = (e:any,isConnectTrans1OrTranscott:boolean) =>{
        const m_bSPhase = getValues("sPhase") 
        const m_nUpTransKind = getValues("upTransKind") 
        const m_dStdCapacity = infoCircuit.stdCapacity//har code
        let voltage = +getValues("voltage")
        
        if(isConnectTrans1OrTranscott){
            let voltageDrop_1_2 = 0
            let voltageDrop2 = 0
            if(m_bSPhase){
                switch(m_nUpTransKind){
                    case MS_TRANS1:
                        voltageDrop_1_2 = doGetDropVoltDiff(m_bSPhase,m_dStdCapacity,voltage/2,MS_OUTSIDE_NEUTRAL,+getValues("calcPoint1.powerFactor"),+getValues("leadOrLag2"),+getValues("calcPoint1.loadCurrent"))
                        voltageDrop2 = doGetDropPerUnit(m_bSPhase,m_dStdCapacity,voltage,MS_OUTSIDE_NEUTRAL,+getValues("calcPoint1.powerFactor"),+getValues("leadOrLag2"),+getValues("calcPoint1.loadCurrent"))
                        break;
                    case MS_TRANSSCOTT:
                        voltageDrop_1_2 = doGetDropVoltDiff(m_bSPhase,m_dStdCapacity,voltage,MS_SEAT_MAIN,+getValues("calcPoint1.powerFactor"),+getValues("leadOrLag2"),+getValues("calcPoint1.loadCurrent"))
                        voltageDrop2 = doGetDropPerUnit(m_bSPhase,m_dStdCapacity,voltage,MS_SEAT_MAIN,+getValues("calcPoint1.powerFactor"),+getValues("leadOrLag2"),+getValues("calcPoint1.loadCurrent"))
                        break;
    
                }
            }
            else{
                voltageDrop_1_2 = doGetDropVoltDiff(m_bSPhase,m_dStdCapacity,voltage,MS_3PHASES,+getValues("calcPoint1.powerFactor"),+getValues("leadOrLag2"),+getValues("calcPoint1.loadCurrent"))
                voltageDrop2 = doGetDropPerUnit(m_bSPhase,m_dStdCapacity,voltage,MS_3PHASES,+getValues("calcPoint1.powerFactor"),+getValues("leadOrLag2"),+getValues("calcPoint1.loadCurrent"))
            }
            setValue("voltageDrop_1_2",voltageDrop_1_2)
            setValue("voltageDrop2",voltageDrop2)
        }
        else{
            let voltageDrop_1 = 0
            let voltageDrop = 0
            if(m_bSPhase){
                switch(m_nUpTransKind){
                    case MS_TRANS1:
                        voltageDrop_1 = doGetDropVoltDiff(m_bSPhase,m_dStdCapacity,voltage,MS_OUTSIDE_OUTSIDE,+getValues("calcPoint0.powerFactor"),+getValues("leadOrLag1"),+getValues("calcPoint0.loadCurrent"))
                        voltageDrop = doGetDropPerUnit(m_bSPhase,m_dStdCapacity,voltage,MS_OUTSIDE_OUTSIDE,+getValues("calcPoint0.powerFactor"),+getValues("leadOrLag1"),+getValues("calcPoint0.loadCurrent"))
                        break;
                    case MS_TRANSSCOTT:
                        voltageDrop_1 = doGetDropVoltDiff(m_bSPhase,m_dStdCapacity,voltage,MS_SEAT_T,+getValues("calcPoint0.powerFactor"),+getValues("leadOrLag1"),+getValues("calcPoint0.loadCurrent"))
                        voltageDrop = doGetDropPerUnit(m_bSPhase,m_dStdCapacity,voltage,MS_SEAT_T,+getValues("calcPoint0.powerFactor"),+getValues("leadOrLag1"),+getValues("calcPoint0.loadCurrent"))
                        break;

                }
            }
            else{
                voltageDrop_1 = doGetDropVoltDiff(m_bSPhase,m_dStdCapacity,voltage,MS_3PHASES,+getValues("calcPoint0.powerFactor"),+getValues("leadOrLag1"),+getValues("calcPoint0.loadCurrent"))
                voltageDrop = doGetDropPerUnit(m_bSPhase,m_dStdCapacity,voltage,MS_3PHASES,+getValues("calcPoint0.powerFactor"),+getValues("leadOrLag1"),+getValues("calcPoint0.loadCurrent"))
            }
            setValue("voltageDrop_1",voltageDrop_1)
            setValue("voltageDrop",voltageDrop)
    }
        
    }
    const doSetValueDropLoadCapacity =(e:any,m_nCalcKind:number,voltage: number,loadCurrent:number) =>{
        let loadCapacity = voltage * loadCurrent
        switch(m_nCalcKind){
            case CALC_IK3:
                loadCapacity *= Math.sqrt(3.0)
                break;
            case CALC_IK2:
                    break;
            case CALC_IK2_OUTSIDE_NEUTRAL:
                    break;
            case CALC_IK2_OUTSIDE_OUTSIDE:
                    break;
            case CALC_IK2_SEAT_MAIN:
                    break;
            case CALC_IK2_SEAT_T:
                    break;
        }
        return BeamsNumber(loadCapacity,7)
    }
    const doSetValueDropLoadCurrent =(e:any,m_nCalcKind:number,voltage:number,loadCapacity:number) =>{
        let loadCurrent = loadCapacity / voltage
        switch(m_nCalcKind){
            case CALC_IK3:
                loadCurrent /= Math.sqrt(3.0)
                break;
            case CALC_IK2:
                break;
            case CALC_IK2_OUTSIDE_NEUTRAL:
                break;
            case CALC_IK2_OUTSIDE_OUTSIDE:
                break;
            case CALC_IK2_SEAT_MAIN:
                break;
            case CALC_IK2_SEAT_T:
                break;
        }
        return BeamsNumber(loadCurrent,7)
    }
    const onEnChangeLoadCurrent = (e:any,isConnectTrans1OrTranscott:boolean) =>{
        setValue(e.target.name, e.target.value)
        let upTransKind = getValues("upTransKind")
        if(isConnectTrans1OrTranscott){
            setValue("inputedCurrent2", true)
            setValue("inputedCapacity2", false)
        }
        else{
        setValue("inputedCurrent1", true)
        setValue("inputedCapacity1", false)
        }

            if(isConnectTrans1OrTranscott){
                let loadCapacity = 0
                if(getValues("calcPoint1.loadCurrent") >= 0){
                    setValue("calcPoint1.loadCurrent",+getValues("calcPoint1.loadCurrent"))
                if(doSetSPhaseConnect()  && getValues("upTransKind") === MS_TRANS1){
                    loadCapacity = doSetValueDropLoadCapacity(e,getValues("calcPoint1.calcKind"),getValues("voltage")/2,+getValues("calcPoint1.loadCurrent"))
                    }
                    else{
                    loadCapacity = doSetValueDropLoadCapacity(e,getValues("calcPoint1.calcKind"),getValues("voltage"),+getValues("calcPoint1.loadCurrent"))
                    }
                    setValue("calcPoint1.loadCapacity",loadCapacity)
                    doSetValueVolDrop(e,isConnectTrans1OrTranscott)

                }
            }
            else{
                let loadCapacity = 0
                if(+getValues("calcPoint0.loadCurrent") >= 0){
                    setValue("calcPoint0.loadCurrent",+getValues("calcPoint0.loadCurrent"))
                    loadCapacity = doSetValueDropLoadCapacity(e,getValues("calcPoint0.calcKind"),getValues("voltage"),getValues("calcPoint0.loadCurrent"))
                
                }
                setValue("calcPoint0.loadCapacity",loadCapacity)
                doSetValueVolDrop(e,isConnectTrans1OrTranscott)

            }
            }
    const onEnChangeLoadCapacity = (e:any,isConnectTrans1OrTranscott:boolean) =>{
        setValue(e.target.name, e.target.value)
        let upTransKind = getValues("upTransKind")
        if(isConnectTrans1OrTranscott){
            setValue("inputedCurrent2", false)
            setValue("inputedCapacity2", true)
        }
        else{
        setValue("inputedCurrent1", false)
        setValue("inputedCapacity1", true)
        }
        if(isConnectTrans1OrTranscott){
            let loadCurrent = 0
            if(+getValues("calcPoint1.loadCapacity") >=0){
            setValue("calcPoint1.loadCapacity", +getValues("calcPoint1.loadCapacity"))
            if(doSetSPhaseConnect() && upTransKind === MS_TRANS1){
                loadCurrent = doSetValueDropLoadCurrent(e,getValues("calcPoint1.calcKind"),getValues("voltage")/2,+getValues("calcPoint1.loadCapacity"))
            }
            else{
                loadCurrent = doSetValueDropLoadCurrent(e,getValues("calcPoint1.calcKind"),getValues("voltage"),+getValues("calcPoint1.loadCapacity"))
            }
            setValue("calcPoint1.loadCurrent", loadCurrent)
            doSetValueVolDrop(e,isConnectTrans1OrTranscott)
        }
        else{
            // setIsDisableOKBt(true)
        }
        }
        else{
            let loadCurrent = 0
            if(+getValues("calcPoint0.loadCapacity") >= 0){
                loadCurrent = doSetValueDropLoadCurrent(e, getValues("calcPoint0.calcKind"),getValues("voltage"),+getValues("calcPoint0.loadCapacity"))
                setValue("calcPoint0.loadCurrent", loadCurrent)
                doSetValueVolDrop(e,isConnectTrans1OrTranscott)
            
            }
            else{
                // setIsDisableOKBt(true)
            }
        }
        
    }
    const onBnClickedLeadOrLag =(e:any,isConnectTrans1OrTranscott:boolean) =>{
        setValue(e.target.name, e.target.value)
        let upTransKind = getValues("upTransKind")
        if(isConnectTrans1OrTranscott){
            if(getValues("leadOrLag2") == 1){
                setValue("inputedPowerFactor2", true)
                setValue("calcPoint1.isLead",true)
            } 
            else if( getValues("leadOrLag2") == 0){
                setValue("inputedPowerFactor2", true)
                setValue("calcPoint1.isLead",false)
            }
            doSetValueVolDrop(e,isConnectTrans1OrTranscott)
        }
        else{
            if(getValues("leadOrLag1") == 1){
                setValue("inputedPowerFactor1", true)
                setValue("calcPoint0.isLead",true)
            } 
            else if( getValues("leadOrLag1") == 0){
                setValue("inputedPowerFactor1", true)
                setValue("calcPoint0.isLead",false)
            }
            doSetValueVolDrop(e,isConnectTrans1OrTranscott)
    }
    }
    const onBnClickedTopLeft = (e:any) =>{
        setValue(e.target.name, e.target.value)
    
    }
    const onChangePowerFactor =(e:any,isConnectTrans1OrTranscott:boolean)=>{
        setValue(e.target.name, e.target.value)
        let upTransKind = getValues("upTransKind")

        if(isConnectTrans1OrTranscott){
            setValue("inputedPowerFactor2", true)
            setValue("calcPoint1.powerFactor",getValues("calcPoint1.powerFactor"))
            doSetValueVolDrop(e,isConnectTrans1OrTranscott)


        }
        else{
            setValue("inputedPowerFactor1", true)
            setValue("calcPoint0.powerFactor",getValues("calcPoint0.powerFactor"))
            doSetValueVolDrop(e,isConnectTrans1OrTranscott)

        }

    }
    const doGetTextLoadCurrent = (nPartKind: number) =>{
        let loadCurrent = 0
         if(getValues("sPhase")){
            switch(nPartKind){
                case MS_OUTSIDE_NEUTRAL:
                case MS_SEAT_MAIN:
                    loadCurrent = getValues("calcPoint1.loadCurrent")
                    break
                case MS_OUTSIDE_OUTSIDE:
                case MS_SEAT_T:
                    loadCurrent = getValues("calcPoint0.loadCurrent")
                    break
            }
         }
         else{
            if(getValues("upTransKind") == MS_TRANS1){
                loadCurrent = getValues("calcPoint1.loadCurrent")
            }
            else{
                loadCurrent = getValues("calcPoint0.loadCurrent")
            }
         }
         return loadCurrent
    }
    const doSetSPhaseConnect =() =>{
        let sPhase = false
        if(setIsDisplayTabConnectTran1 || setIsDisplayTabConnectTranscott){
            sPhase = true
        }
        else{
            sPhase = false
        }
        return sPhase
    }
    const onBuClickedGetLoadCurrent =(e:any,isConnectTrans1OrTranscott:boolean) =>{//bt1
        setValue(e.target.name, e.target.value)
        let upTransKind = getValues("upTransKind")
        
        if(isConnectTrans1OrTranscott){
            clearErrors("calcPoint1.loadCapacity")
            clearErrors("calcPoint1.loadCurrent")

            let loadCurrent = 0
            let loadCapacity = 0
            setValue("inputedCapacity2",false)
            setValue("inputedCurrent2", false)
            setValue("calcPoint1.loadCurrent",dispInputDialog.load_current_1)

         if(doSetSPhaseConnect()){
            switch(getValues("upTransKind")){
                case MS_TRANS1:
                    loadCurrent = doGetTextLoadCurrent(MS_OUTSIDE_NEUTRAL)
                    break
                case MS_TRANSSCOTT:
                    loadCurrent = doGetTextLoadCurrent(MS_SEAT_MAIN)
                    break
            }
        }
        else {
            loadCurrent = doGetTextLoadCurrent(MS_3PHASES)
        }
        if(doSetSPhaseConnect() && getValues("upTransKind")=== MS_TRANS1){
            loadCapacity = doSetValueDropLoadCapacity(e,getValues("calcPoint1.calcKind"),+getValues("voltage")/2,+getValues("calcPoint1.loadCurrent"))
        }
        else{
            loadCapacity = doSetValueDropLoadCapacity(e,getValues("calcPoint1.calcKind"),getValues("voltage"),+getValues("calcPoint1.loadCurrent"))
        }
        setValue("calcPoint1.loadCurrent",BeamsNumber(loadCurrent,5))
        setValue("calcPoint1.loadCapacity",loadCapacity)
        doSetValueVolDrop(e,isConnectTrans1OrTranscott)
       
        }
        else{
            clearErrors("calcPoint0.loadCapacity")
            clearErrors("calcPoint0.loadCurrent")
            let loadCurrent = 0
            let loadCapacity = 0
            setValue("inputedCapacity1",false)
            setValue("inputedCurrent1", false)
            setValue("calcPoint0.loadCurrent",dispInputDialog.load_current_0)
            if(getValues("sPhase")){
                switch(getValues("upTransKind")){
                    case MS_TRANS1:
                        loadCurrent = doGetTextLoadCurrent(MS_OUTSIDE_OUTSIDE)
                        break
                    case MS_TRANSSCOTT:
                        loadCurrent = doGetTextLoadCurrent(MS_SEAT_T)
                        break
                }
            }
            else {
                loadCurrent = doGetTextLoadCurrent(MS_3PHASES)
            }
            loadCapacity = doSetValueDropLoadCapacity(e,getValues("calcPoint0.calcKind"),getValues("voltage"),+getValues("calcPoint0.loadCurrent"))
            setValue("calcPoint0.loadCurrent",BeamsNumber(loadCurrent,5))
            setValue("calcPoint0.loadCapacity",loadCapacity)
            doSetValueVolDrop(e,isConnectTrans1OrTranscott)
        } 
        
    }
    const doGetTextDropVoltPF = (nPartKind:number) =>{
        let powerFactor = 0
        if(doSetSPhaseConnect()){
            switch(nPartKind){
                case MS_OUTSIDE_NEUTRAL:
                case MS_SEAT_MAIN:
                    powerFactor = getValues("calcPoint1.powerFactor")
                    break;
                case MS_OUTSIDE_OUTSIDE:
                case MS_SEAT_T:
                    powerFactor = getValues("calcPoint0.powerFactor")
                    break;
            }
        }
        else{
            if(getValues("downTransKind") === MS_TRANS1){
                powerFactor = getValues("calcPoint1.powerFactor")

            }
            else{
                powerFactor = getValues("calcPoint0.powerFactor")

            }

        }
        return powerFactor
    }
    const onBuClickedGetPowerFactor =(e:any,isConnectTrans1OrTranscott:boolean) =>{//bt2
        setValue(e.target.name, e.target.value)
        let upTransKind = getValues("upTransKind")
        if(isConnectTrans1OrTranscott){
            clearErrors("calcPoint1.powerFactor")
            let powerFactor =0
            setValue("inputedPowerFactor2", false)
            setValue("calcPoint1.powerFactor",dispInputDialog.power_factor_1)
            setValue("calcPoint1.isLead",dispInputDialog.is_lead_1)
            switch(getValues("calcPoint1.isLead")){
                case true: 
                   setValue("leadOrLag2", 1)
                   break
                default:
                    setValue("leadOrLag2",0)
            }
            if(doSetSPhaseConnect()){                                                                                                                                                                                                                
                switch(getValues("upTransKind")){
                    case MS_TRANS1:
                        powerFactor = doGetTextDropVoltPF(MS_OUTSIDE_NEUTRAL)
                        break
                    case MS_TRANSSCOTT:
                        powerFactor = doGetTextDropVoltPF(MS_SEAT_MAIN)
                        break
                }
            }
            else{
                powerFactor = doGetTextDropVoltPF(MS_3PHASES)
            }
            setValue("calcPoint1.powerFactor",powerFactor)
            doSetValueVolDrop(e,isConnectTrans1OrTranscott)
        }
        else{
            clearErrors("calcPoint0.powerFactor")
            let powerFactor =0
            setValue("inputedPowerFactor1", false)
            setValue("calcPoint0.powerFactor",dispInputDialog.power_factor_0)
            setValue("calcPoint0.isLead",dispInputDialog.is_lead_0)
            switch(getValues("calcPoint0.isLead")){
                case true: 
                setValue("leadOrLag1", 1)
                break
                default:
                    setValue("leadOrLag1",0)
            }
            if(doSetSPhaseConnect()){                                                                                                                                                                                                                
                switch(getValues("upTransKind")){
                    case MS_TRANS1:
                        powerFactor = doGetTextDropVoltPF(MS_OUTSIDE_OUTSIDE)
                        break
                    case MS_TRANSSCOTT:
                        powerFactor = doGetTextDropVoltPF(MS_SEAT_T)
                        break
                }
            }
            else{
                powerFactor = doGetTextDropVoltPF(MS_3PHASES)

            }
            setValue("calcPoint0.powerFactor",powerFactor)
            doSetValueVolDrop(e,isConnectTrans1OrTranscott)
    }
    }
    const doReadByValuesBD = (phase:IeleBusductSPhase,frequency:number,material:number) =>{
        const busductList = getValues('busductList')
        for(const item of busductList){
            if(item.nmaterial == material){
                if(phase.ratedCurrent <= item.dratedCurrent || item.dratedCurrent == -1){
                    phase.width = item.nwidth
                    phase.thickness = item.nthickness
                    phase.numberPhase = item.nnumber
                    phase.ohmRPerA = item.dohmr
                    if(frequency == 50){
                        phase.ohmXPerA = item.dohmx50
                    }
                    else if (frequency == 60){
                        phase.ohmXPerA = item.dohmx60
                    }
                    break
                }
            }
        }
    }
    const doCalcSetZValues = (phase:IeleBusductSPhase, frequency:number,material:number) =>{
        if(phase.ratedCurrent > 0){
            doReadByValuesBD(phase,frequency,material)
        }
        else{
            phase.width = 0
            phase.thickness = 0
            phase.numberPhase = 0
            phase.ohmRPerA = 0
            phase.ohmXPerA = 0
        }
        phase.ohmRPerM = phase.ohmRPerA
        phase.ohmXPerM = phase.ohmXPerA
    }
    const onChangeMaterial = (e:any) =>{
        setValue(e.target.name,e.target.value)
        doCalcSetZValues(getValues('m_3Sphase'),infoCircuit.frequency,+e.target.value)
        doCalcSetZValues(getValues('SPhaseN'),infoCircuit.frequency,+e.target.value)
        doCalcSetZValues(getValues('sPhasePE'),infoCircuit.frequency,+e.target.value)

    }
    const doGetValueOhmR = (ohmRPerM:number) =>{
        return BeamsNumber(ohmRPerM * (+getValues('length')),4)
    }
    const doGetValueOhmX = (ohmXPerM:number) =>{
        return BeamsNumber(ohmXPerM * (+getValues('length')),4)
    }
    const doSetZValuesBusDuct = (nControl:string,kindPhase:number) =>{
        if(kindPhase == 1){
            setValue('m_3Sphase.width',getValues('m_3Sphase.width'))
            setValue('m_3Sphase.thickness',getValues('m_3Sphase.thickness'))
            setValue('m_3Sphase.numberPhase',getValues('m_3Sphase.numberPhase'))
            if(nControl !== 'ohmr'){
                setValue('m_3Sphase.ohmRPerM',BeamsNumber(+getValues('m_3Sphase.ohmRPerM'),4))
            }
            if(nControl !== 'ohmx'){
                setValue('m_3Sphase.ohmXPerM',BeamsNumber(+getValues('m_3Sphase.ohmXPerM'),4))
            }
            setValue('m_3Sphase.ohmR',doGetValueOhmR(+getValues('m_3Sphase.ohmRPerM')))
            setValue('m_3Sphase.ohmX',doGetValueOhmX(+getValues('m_3Sphase.ohmXPerM')))

        }
        else if(kindPhase == 2){
            setValue('SPhaseN.width',getValues('SPhaseN.width'))
            setValue('SPhaseN.thickness',getValues('SPhaseN.thickness'))
            setValue('SPhaseN.numberPhase',getValues('SPhaseN.numberPhase'))
            if(nControl !== 'ohmr'){
                setValue('SPhaseN.ohmRPerM',BeamsNumber(+getValues('SPhaseN.ohmRPerM'),4))
            }
            if(nControl !== 'ohmx'){
                setValue('SPhaseN.ohmXPerM',BeamsNumber(+getValues('SPhaseN.ohmXPerM'),4))
            }
            setValue('SPhaseN.ohmR',doGetValueOhmR(+getValues('SPhaseN.ohmRPerM')))
            setValue('SPhaseN.ohmX',doGetValueOhmX(+getValues('SPhaseN.ohmXPerM')))
        }
        else if(kindPhase == 3){
            setValue('sPhasePE.width',getValues('sPhasePE.width'))
            setValue('sPhasePE.thickness',getValues('sPhasePE.thickness'))
            setValue('sPhasePE.numberPhase',getValues('sPhasePE.numberPhase'))
            if(nControl !== 'ohmr'){
                setValue('sPhasePE.ohmRPerM',BeamsNumber(+getValues('sPhasePE.ohmRPerM'),4))
            }
            if(nControl !== 'ohmx'){
                setValue('sPhasePE.ohmXPerM',BeamsNumber(+getValues('sPhasePE.ohmXPerM'),4))
            }
            setValue('sPhasePE.ohmR',doGetValueOhmR(+getValues('sPhasePE.ohmRPerM')))
            setValue('sPhasePE.ohmX',doGetValueOhmX(+getValues('sPhasePE.ohmXPerM')))
        }
    }
    const doSetIValuesBusDuct = (kindPhase:number) =>{
        if(kindPhase == 1){
            setValue('m_3Sphase.capacity',+getValues('m_3Sphase.ratedCurrent'))
        }
        else if(kindPhase == 2){
            setValue('SPhaseN.capacity',+getValues('SPhaseN.ratedCurrent'))
        }
        else if(kindPhase == 3){
            setValue('sPhasePE.capacity',+getValues('sPhasePE.ratedCurrent'))
        }
    }
    const clearErrorsBusduct = ()=>{
        if(getValues('m_3Sphase.width') > 0){
            clearErrors('m_3Sphase.width')
        }
        if(getValues('m_3Sphase.thickness') > 0){
            clearErrors('m_3Sphase.thickness')
        }
        if(getValues('m_3Sphase.numberPhase') > 0){
            clearErrors('m_3Sphase.numberPhase')
        }
        if(getValues('m_3Sphase.ohmRPerM') > 0){
            clearErrors('m_3Sphase.ohmRPerM')
        }
        if(getValues('m_3Sphase.ohmXPerM') > 0){
            clearErrors('m_3Sphase.ohmXPerM')
        }
        //
        if(getValues('SPhaseN.width') > 0){
            clearErrors('SPhaseN.width')
        }
        if(getValues('SPhaseN.thickness') > 0){
            clearErrors('SPhaseN.thickness')
        }
        if(getValues('SPhaseN.numberPhase') > 0){
            clearErrors('SPhaseN.numberPhase')
        }
        if(getValues('SPhaseN.ohmRPerM') > 0){
            clearErrors('SPhaseN.ohmRPerM')
        }
        if(getValues('SPhaseN.ohmXPerM') > 0){
            clearErrors('SPhaseN.ohmXPerM')
        }
        //
        if(getValues('sPhasePE.width') > 0){
            clearErrors('sPhasePE.width')
        }
        if(getValues('sPhasePE.thickness') > 0){
            clearErrors('sPhasePE.thickness')
        }
        if(getValues('sPhasePE.numberPhase') > 0){
            clearErrors('sPhasePE.numberPhase')
        }
        if(getValues('sPhasePE.ohmRPerM') > 0){
            clearErrors('sPhasePE.ohmRPerM')
        }
        if(getValues('sPhasePE.ohmXPerM') > 0){
            clearErrors('sPhasePE.ohmXPerM')
        }
        
    }
    const onChangeRatedCurrent = (e:any, kindPhase:number) =>{
        setValue(e.target.name,e.target.value)
        if(kindPhase == 1){
            doCalcSetZValues(getValues('m_3Sphase'),infoCircuit.frequency,+getValues('materialCU_AL'))
            doSetZValuesBusDuct('',1)
            doSetIValuesBusDuct(1)

            setValue('SPhaseN.ratedCurrent',getValues('m_3Sphase.ratedCurrent'))
            doCalcSetZValues(getValues('SPhaseN'),infoCircuit.frequency,+getValues('materialCU_AL'))
            doSetZValuesBusDuct('',2)
            doSetIValuesBusDuct(2)
            doInitComboRatedCurrent(getValues('busductList'),getValues('SPhaseN'),getValues('materialCU_AL'),+e.target.value)

            setValue('sPhasePE.ratedCurrent',getValues('m_3Sphase.ratedCurrent'))
            doCalcSetZValues(getValues('sPhasePE'),infoCircuit.frequency,+getValues('materialCU_AL'))
            doSetZValuesBusDuct('',3)
            doSetIValuesBusDuct(3)
            doInitComboRatedCurrent(getValues('busductList'),getValues('sPhasePE'),getValues('materialCU_AL'),+e.target.value)
            clearErrorsBusduct()
        }
        else if (kindPhase == 2){
            doCalcSetZValues(getValues('SPhaseN'),infoCircuit.frequency,+getValues('materialCU_AL'))
            doSetZValuesBusDuct('',2)
            doSetIValuesBusDuct(2)
            clearErrorsBusduct()

        }
        else if (kindPhase == 3){
            doCalcSetZValues(getValues('sPhasePE'),infoCircuit.frequency,+getValues('materialCU_AL'))
            doSetZValuesBusDuct('',3)
            doSetIValuesBusDuct(3)
            clearErrorsBusduct()
        }
    }
    const onChangeNumberPhase_width_thickness = (e:any) =>{
        setValue(e.target.name,e.target.value)
    }
    const onChangeOhmRper = (e:any, kindPhase:number) =>{
        setValue(e.target.name,e.target.value)
        if(kindPhase == 1) {
            doSetZValuesBusDuct('ohmr',1)

            setValue('SPhaseN.ohmRPerM',+getValues('m_3Sphase.ohmRPerM'))
            doSetZValuesBusDuct('ohmr',2)

            setValue('sPhasePE.ohmRPerM',+getValues('m_3Sphase.ohmRPerM'))
            doSetZValuesBusDuct('ohmr',3)
        }
        else if(kindPhase == 2) {
            doSetZValuesBusDuct('ohmr',2)
        }
        else if(kindPhase == 3) {
            doSetZValuesBusDuct('ohmr',3)
        }
    }
    const onChangeOhmXper = (e:any, kindPhase:number) =>{
        setValue(e.target.name,e.target.value)
        if(kindPhase == 1) {
            doSetZValuesBusDuct('ohmx',1)

            setValue('SPhaseN.ohmXPerM',+getValues('m_3Sphase.ohmXPerM'))
            doSetZValuesBusDuct('ohmr',2)

            setValue('sPhasePE.ohmXPerM',+getValues('m_3Sphase.ohmXPerM'))
            doSetZValuesBusDuct('ohmx',3)
        }
        else if(kindPhase == 2) {
            doSetZValuesBusDuct('ohmx',2)
        }
        else if(kindPhase == 3) {
            doSetZValuesBusDuct('ohmx',3)
        }
    }

    return (
        <>
                    
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={selectTab} onChange={handleChange}
                                variant="scrollable" 
                                scrollButtons 
                                allowScrollButtonsMobile 
                                sx={{
                                    '& .MuiButtonBase-root.MuiTabScrollButton-root.Mui-disabled': {
                                        display: "none"
                                    }
                                }}
                            >
                                <Tab label="基本" {...a11yProps(0)} />
                                <Tab label="仕様" {...a11yProps(1)} />
                                <Tab label="電圧線(3相/単相)" {...a11yProps(2)}/>
                                {((isDisplayTabTabSPhase === 2 || isDisplayTabTabSPhase === 3))&& !isModeSPhases && <Tab label="中性線(N相)" {...a11yProps(3)} />}
                                {(isDisplayTabTabSPhase === 3 && !isModeSPhases) && <Tab label="保護接地線(PE相)" {...a11yProps(4)} />}

                                {setIsDisplayTabConnectTran1 && <Tab label="電圧降下-[外線-外線]" {...a11yProps(4)}/> ||
                                setIsDisplayTabConnectTranscott && <Tab label="電圧降下-[主座]" {...a11yProps(4)}/> ||
                                <Tab label="電圧降下" {...a11yProps(5)}/>}
                            
                                {setIsDisplayTabConnectTran1 && <Tab label="電圧降下-[外線-中性線]" {...a11yProps(5)}/> ||
                                setIsDisplayTabConnectTranscott && <Tab label="電圧降下-T座" {...a11yProps(5)}/>}
                                
                                {userRole != Constant.ROLE_LP && <Tab label="潮流解析" {...a11yProps(6)} />}
                            </Tabs>
                        </Box>
                        <form onSubmit={handleSubmit(handleEditSubmit)}>
                            <div style={{ height: '40vh', width: '100%', margin: 'auto', overflow: 'auto' }}>

                                {/* tab0 - 基本 */}
                                <TabPanel value={selectTab} index={0}>
                                    <Stack spacing={0.3}>
                                        <Grid container mt={2}>
                                            <Grid item xs={6}>
                                                <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"5px"}}>
                                                    <Typography variant="body2" width="50%">デバイス名:</Typography>  
                                                        <Controller
                                                            name="refNo"
                                                            control={control}
                                                            rules={{ 
                                                                required: '必須項目です。入力してください。' ,
                                                                }}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    type="text"
                                                                    size="small"
                                                                    // label="デバイス名"
                                                                    error={!!errors?.refNo}
                                                                    helperText={errors?.refNo?.message}
                                                                    // onBlur={handleChangeFormat}
                                                                    onFocus={e => e.target.select()}
                                                                    InputLabelProps={{ style: { color: 'black' }}}
                                                                    // onChange={e =>handleDisableOkBt(e)}
                                                                    InputProps={{sx:{height:32,backgroundColor:m_bModePM || modeViewOnly?'floralwhite':'white',color:modeViewOnly?"grey":"black"}}}
                                                                    inputProps={{readOnly:modeViewOnly}}
                                                                    disabled={m_bModePM}
                                                                    
                                                                />
                                                            )}
                                                        />
                                                    </Stack>
                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                        <Typography variant="body2" width="50%">事故点名称:</Typography>  
                                                        <Controller
                                                            name="pointText"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    type="text"
                                                                    size="small"
                                                                    // label="事故点名称"
                                                                    onBlur={handleChangeFormat}
                                                                    onFocus={e => e.target.select()}
                                                                    InputLabelProps={{ style: { color: 'black' }}}
                                                                    InputProps={{sx:{height:32,backgroundColor:m_bModePM || modeViewOnly?'floralwhite':'white',color:modeViewOnly?"grey":"black"}}}
                                                                    inputProps={{readOnly:modeViewOnly}}
                                                                    disabled={m_bModePM}

                                                                />
                                                            )}
                                                        />
                                                    </Stack>
                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                        <Typography variant="body2" width="50%">回路電圧(V):</Typography>  
                                                            <FormControl fullWidth size="small">
                                                                    <Controller
                                                                        name="voltage"
                                                                        control={control}
                                                                        rules={{
                                                                            pattern:{
                                                                            value: /^[0-9\b]+$/,
                                                                            message: '数字を入力してください。'
                                                                            },
                                                                            validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                                        //     maxLength: {
                                                                        //     value: 6,
                                                                        //     message: "最大長さ <= 6 を入力してください。"
                                                                        // }
                                                                            }}
                                                                        render={({ field }) => (
                                                                            <TextFieldOptions
                                                                                    {...field}
                                                                                    options={initialValue.voltageList}
                                                                                    // label="回路電圧(V)"
                                                                                    // onChange= {(e) =>handleChangeFormat(e)}
                                                                                    size="small"
                                                                                    setValue={(name: any, value: any) => {
                                                                                        handleChangeFormat({target:{name:name,value:value}})
                                                                                    }}
                                                                                    height={32}
                                                                                    disabled={getValues("isSourceVoltage") || m_bModePM || modeViewOnly}
                                                                                    maxLength={6}
                                                                                    error={!!errors?.voltage}
                                                                                    helperText={errors?.voltage?.message}
                                                                                    value={(setIsDisplayTabConnectTran1||setIsDisplayTabConnectTranscott)?setIsVoltText:getValues("voltage")}

                                                                                />
                                                                        
                                                                        )}
                                                                    />
                                                                {/* <InputLabel style={{ color: 'black' }}>回路電圧</InputLabel>
                                                                <Controller
                                                                    name="voltage"
                                                                    control={control}
                                                                    rules={{ required: '必須項目です。入力してください。' }}
                                                                    render={({ field }) => (
                                                                        <Select
                                                                            {...field}
                                                                            label="回路電圧(V)"
                                                                            error={!!errors?.voltage}
                                                                            onChange={handleChangeFormat}
                                                                        >
                                                                            {field2Options.map(item => (
                                                                                <MenuItem key={item.label} value={item.value}>
                                                                                    {item.label}
                                                                                </MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                    )}
                                                                /> */}
                                                            </FormControl>
                                                    </Stack>
                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                        <Typography variant="body2" width="50%">長さ(m):</Typography> 
                                                        <Controller
                                                            name="length"
                                                            control={control}
                                                            rules={{
                                                                pattern:{
                                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                                message: '数字を入力してください。'
                                                                },
                                                                validate: value => value >= 0 || '数字 >= 0 を入力してください。' ,
                                                            //     maxLength: {
                                                            //     value: 3,
                                                            //     message: "最大長さ <= 3 を入力してください。"
                                                            // }
                                                                }}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    type="text"
                                                                    size="small"
                                                                    // label="長さ(m)"
                                                                    onChange ={(e) =>{onChangLength(e);}}
                                                                    onBlur={handleChangeFormat}
                                                                    onFocus={e => e.target.select()}
                                                                    InputLabelProps={{ style: { color: 'black' }}}
                                                                    InputProps={{sx:{height:32,backgroundColor:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white', color:modeViewOnly?"grey":"black"}}}
                                                                    inputProps={{maxLength:3,readOnly:modeViewOnly}}
                                                                    error={!!errors?.length}
                                                                    helperText={errors?.length?.message}
                                                                    disabled={m_bModePM|| processMode > MS_MODE_CHECKING}

                                                                />
                                                            )}
                                                        />
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={5} pl={1}>
                                                    <Stack>
                                                        <Controller
                                                            name="fault"
                                                            control={control}
                                                            render={({ field }: any) => (
                                                                <FormControlLabel 
                                                                    {...field} 
                                                                    checked={field.value}
                                                                    label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>短絡電流を計算</Typography>}
                                                                    name='fault'
                                                                    onClick={handleChangeFormat}
                                                                    control={<Checkbox style={checkboxStyles} />} 
                                                                    disabled={m_bModePM || modeViewOnly}
                                                                />
                                                            )}
                                                        />
                                                    </Stack>
                                                    <Stack>
                                                        <Controller
                                                            name="viewResultText"
                                                            control={control}
                                                            render={({ field }: any) => (
                                                                <FormControlLabel 
                                                                    {...field} 
                                                                    checked={field.value}
                                                                    label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>注釈を表示</Typography>}
                                                                    name='viewResultText'
                                                                    onClick={handleChangeFormat}
                                                                    control={<Checkbox style={checkboxStyles} />} 
                                                                    disabled={m_bModePM || modeViewOnly}

                                                                />
                                                            )}
                                                        />
                                                    </Stack>
                                                    
                                                </Grid>
                                            </Grid>
                                        <Grid>
                                            <fieldset style={{width:"75%",marginTop:'15px',border:'1px solid #AAAA'}}>
                                                <legend>{<Typography variant="body2">配線システム</Typography>}</legend>
                                                    <Controller
                                                            name="sPhase"
                                                            control={control}
                                                            render={({ field }) => (
                                                            <>
                                                                <FormControlLabel  
                                                                    disabled={!isModeSPhases || m_bModePM || modeViewOnly}
                                                                    control={<Radio checked={field.value} style={radioStyles} />} 
                                                                    label= {<Typography variant="body2" style={{color:!isModeSPhases||m_bModePM?'grey':'black'}}>電圧線(単相)</Typography>}
                                                                    
                                                                />
                                                            </>
                                                            )}
                                                        />
                                                    <Controller
                                                        name="wireSystem"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <>
                                                                <RadioGroup {...field} onChange={(e) =>handleDisplayTab(e)}>
                                                                    {/* <FormControlLabel 
                                                                        value="0" 
                                                                        disabled={!(setIsDisplayTabConnectTran1||setIsDisplayTabConnectTranscott)}
                                                                        control={<Radio style={radioStyles} />} 
                                                                        label="電圧線(単相)" 
                                                                    /> */}
                                                                    <FormControlLabel 
                                                                        value="1" 
                                                                        control={<Radio style={radioStyles} />} 
                                                                        label={<Typography variant="body2" style={{color:(setIsDisplayTabConnectTran1||setIsDisplayTabConnectTranscott||m_bModePM|| processMode > MS_MODE_DRAWING)?'grey':'black'}}>電圧線(3相)</Typography>} 
                                                                        disabled={(setIsDisplayTabConnectTran1||setIsDisplayTabConnectTranscott||m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING)}
                                                                        checked={field.value == RS_3PHASES && !isModeSPhases}

                                                                    />
                                                                    <FormControlLabel 
                                                                        value="2" 
                                                                        control={<Radio style={radioStyles} />} 
                                                                        label={<Typography variant="body2" style={{color:(setIsDisplayTabConnectTran1||setIsDisplayTabConnectTranscott||m_bModePM|| processMode > MS_MODE_DRAWING)?'grey':'black'}}>電圧線(3相) + 中性線(N相)</Typography>} 
                                                                        disabled={(setIsDisplayTabConnectTran1||setIsDisplayTabConnectTranscott||m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING)}
                                                                        checked={field.value == RS_PHASE_N && !isModeSPhases}

                                                                    />
                                                                    <FormControlLabel 
                                                                        value="3" 
                                                                        control={<Radio style={radioStyles} />} 
                                                                        label={<Typography variant="body2" style={{color:(setIsDisplayTabConnectTran1||setIsDisplayTabConnectTranscott||m_bModePM|| processMode > MS_MODE_DRAWING)?'grey':'black'}}>電圧線(3相) + 中性線(N相) + 保護接地線(PE相)</Typography>} 
                                                                        disabled={(setIsDisplayTabConnectTran1||setIsDisplayTabConnectTranscott||m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING)}
                                                                        checked={field.value == RS_PHASE_PE && !isModeSPhases}
                                                                    
                                                                    />
                                                                </RadioGroup>
                                                            </>
                                                        )}
                                                    />
                                            </fieldset>
                                        </Grid>
                                        {/* Mode PM */}
                                        {[Constant.ROLE_PM, Constant.ROLE_ADMIN].includes(userRole) &&
                                        <>
                                            <Stack mt={10}>
                                                <FormControlLabel
                                                    control={
                                                        <Controller
                                                            name='makeDataInput'
                                                            control={control}
                                                            render={({ field }) => (
                                                                <Checkbox
                                                                    size='small'
                                                                    {...field}
                                                                    checked={field.value}
                                                                    onChange={handleChangeMakeDataInput}
                                                                    style={{padding:'15px 4px 4px 9px'}}
                                                                    disabled={m_bModePM || modeViewOnly}
                                                                />
                                                            )}
                                                        />
                                                    }
                                                    label={<Typography variant="body2" style={{marginTop:'10px'}} color={m_bModePM?'grey':'black'}>銘板入力</Typography>}
                                                />
                                            </Stack>
                                            <Stack>
                                                <fieldset
                                                    style={{ 
                                                        width: '70%',
                                                        color: !makeDataInput ? 'grey' : 'black',
                                                        border:'1px solid #AAAA'
                                                    }}
                                                >
                                                    <legend ><Typography variant="body2"  >銘板</Typography></legend>
                                                        <Stack spacing={0.3}>
                                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                                <Typography variant="body2" width="35%"  >製造者:</Typography>
                                                                <FormControl fullWidth size='small'>
                                                                    <Controller
                                                                        name='makerName'
                                                                        control={control}
                                                                        render={({ field }: any) => (
                                                                            <TextFieldOptions
                                                                                {...field}
                                                                                options={initialValue.makerNameList}
                                                                                type='string'
                                                                                size='small'
                                                                                setValue={(name: any, value: any) => {
                                                                                    handleChangeFormat({target: {name: name, value: value}})
                                                                                }}
                                                                                height= {32}
                                                                                disabled={!makeDataInput||m_bModePM|| modeViewOnly}

                                                                            />
                                                                        )}
                                                                    />
                                                                </FormControl>
                                                            </Stack>
                                                            
                                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                                <Typography variant="body2" width="35%"  >敷設年月:</Typography>
                                                                <Grid container>
                                                                    <Grid item xs={7}>
                                                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                                            <FormControl fullWidth size='small'>
                                                                                <Controller
                                                                                    name='makeYear'
                                                                                    control={control}
                                                                                    rules={{
                                                                                        // maxLength: {
                                                                                        //     value: 8,
                                                                                        //     message: "最大長さ <= 8 を入力してください。"
                                                                                        // }
                                                                                            }}
                                    
                                                                                    render={({ field }: any) => (
                                                                                        <TextFieldOptions
                                                                                            {...field}
                                                                                            options={initialValue.makeYearList}
                                    
                                                                                            type='string'
                                                                                            size='small'
                                                                                            setValue={(name: any, value: any) => {
                                                                                                handleChangeFormat({target: {name: name, value: value}})
                                                                                            }}
                                                                                            error={!!errors?.makeYear}
                                                                                            helperText={errors?.makeYear?.message}
                                                                                            disabled={!makeDataInput||m_bModePM|| modeViewOnly}
                                                                                            height={32}
                                                                                            maxLength={8}
                                                                                        />
                                                                                    )}
                                                                                />
                                                                            </FormControl>
                                                                            <Typography variant="body2" pr={0.5} pl={0.5}>年</Typography>
                                                                        </Stack>
                                                                    </Grid>
                                                                    <Grid item xs={5}>
                                                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                                            <FormControl fullWidth size='small'>
                                                                                <Controller
                                                                                name='makeMonth'
                                                                                control={control}
                                                                                rules={{
                                                                                    // maxLength:{
                                                                                    //     value:2,
                                                                                    //     message:'最大長さ <= 2 を入力してください'
                                                                                    // }
                                                                                }}
                                                                                render={({ field }: any) => (
                                                                                    <TextFieldOptions
                                                                                        {...field}
                                                                                        options={initialValue.makeMonthList}
                                                                                        // label='製造者'
                                                                                        type='string'
                                                                                        size='small'
                                                                                        // onChange={handleChangeFormat}
                                                                                        setValue={(name: any, value: any) => {
                                                                                            handleChangeFormat({target: {name: name, value: value}})
                                
                                                                                        }} 
                                                                                        error={!!errors?.makeMonth}
                                                                                        helperText={errors?.makeMonth?.message}
                                                                                        disabled={!makeDataInput||m_bModePM|| modeViewOnly}
                                                                                        height={32}
                                                                                        maxLength={2}
                                
                                                                                    />
                                                                                )}
                                                                                    
                                                                                />
                                                                            </FormControl>
                                                                            <Typography variant="body2" pl={0.5}>月</Typography>
                                                                        </Stack>
                                                                    </Grid>
                                                                </Grid>
                                                            </Stack>
                                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                                <Typography variant="body2" width="35%"  >その他:</Typography>
                                                                <Controller
                                                                    name='type'
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <TextField
                                                                            {...field}
                                                                            fullWidth
                                                                            type='text'
                                                                            size='small'
                                                                            onChange={handleChangeFormat}
                                                                            InputLabelProps={{
                                                                                style: { color: makeDataInput ? 'black' : 'grey' },
                                                                            }}
                                                                            InputProps={{
                                                                                readOnly: !makeDataInput || modeViewOnly,
                                                                                style:{color:!makeDataInput ?'grey' : 'black'},
                                                                                sx: { height: 32 ,backgroundColor:modeViewOnly?'floralwhite':makeDataInput ? 'white' : 'floralwhite',color:modeViewOnly?"grey":makeDataInput ? 'black' : 'grey'}
                                                                            }}
                                                                            style={{
                                                                                backgroundColor:m_bModePM ?'floralwhite':makeDataInput ? 'white' : 'floralwhite',
                                                                            }}
                                                                        />
                                                                    )}
                                                                />
                                                            </Stack>
                                                        </Stack>
                                                </fieldset>
                                            </Stack>
                                        </>
                                        }
                                    </Stack>
                                </TabPanel>

                                {/* tab1 - 仕様 */}
                                <TabPanel value={selectTab} index={1}>
                                    <Stack margin={2} spacing={3} style={{ width: '100%', margin: 'auto' }}>
                                        <Stack>
                                            <Typography mt={2}  variant="body2">JIS C 8364に準拠しています。</Typography>
                                            <fieldset style={{width:'50%',border: "1px solid #AAAAAA"}}>
                                                <legend><Typography variant="body2">材質</Typography></legend>
                                                    <Stack>
                                                        <Controller
                                                            name="materialCU_AL"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <>
                                                                    <RadioGroup {...field} 
                                                                    onChange={(e) =>{
                                                                        onChangeMaterial(e)
                                                                    }}
                                                                    >
                                                                        <FormControlLabel value="1" control={<Radio style={radioStyles} />} label={<Typography variant="body2" color={m_bModePM|| processMode > MS_MODE_DRAWING?'grey':'black'}>銅</Typography>} disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING}/>
                                                                        <FormControlLabel value="2" control={<Radio style={radioStyles} />} label={<Typography variant="body2" color={m_bModePM|| processMode > MS_MODE_DRAWING?'grey':'black'}>アルミニウム</Typography>} disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING}/>
                                                                    </RadioGroup>
                                                                </>
                                                            )}
                                                        />
                                                    </Stack>
                                            </fieldset>
                                            <Grid container>
                                                <Grid item xs={8} mt={2}>
                                                        <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                            <Typography variant="body2" width="80%">バスダクトの最高温度(°C):</Typography>
                                                                <Controller
                                                                    name="busductTemp"
                                                                    control={control}
                                                                    
                                                                    render={({ field }) => (
                                                                        <TextField
                                                                            {...field}
                                                                            type="text"
                                                                            size="small"
                                                                            // label="バスダクトの最高温度(°C)"
                                                                            onBlur={handleChangeFormat}
                                                                            disabled={true}
                                                                            onFocus={e => e.target.select()}
                                                                            InputLabelProps={{ style: { color: 'black' }}}
                                                                            InputProps={{sx:{height:32,backgroundColor:'floralwhite'}}}
                                                                            
                                                                        />
                                                                    )}
                                                                />
                                                        </Stack>
                                                        <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                            <Typography variant="body2" width="80%">バスダクトの周囲温度(°C):</Typography>
                                                            <Controller
                                                                name="ambientTemp"
                                                                control={control}
                                                                
                                                                render={({ field }) => (
                                                                    <TextField
                                                                        {...field}
                                                                        type="text"
                                                                        size="small"
                                                                        // label="バスダクトの周囲温度(°C)"
                                                                        onBlur={handleChangeFormat}
                                                                        disabled={true}
                                                                        onFocus={e => e.target.select()}
                                                                        InputLabelProps={{ style: { color: 'black'}}}
                                                                        InputProps={{sx:{height:32,backgroundColor:'floralwhite'}}}
                                                                    
                                                                    />
                                                                )}
                                                            />
                                                        </Stack>
                                                    </Grid>
                                            </Grid>
                                        </Stack>
                                    </Stack>
                                </TabPanel>

                                {/* tab2 - 電圧線(3相/単相) */}
                                <TabPanel value={selectTab} index={2}>
                                    <Stack spacing={3} style={{ width: '100%', margin: 'auto' }}>
                                        <Stack>
                                            <Grid container>
                                                <Grid item xs={12} mt={1}>
                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                        <Typography variant="body2" width="100%">定格電流(A):</Typography>
                                                            <FormControl fullWidth size="small">
                                                                <Controller
                                                                    name="m_3Sphase.ratedCurrent"
                                                                    control={control}
                                                                    rules={{
                                                                        pattern:{
                                                                        value: /^[0-9\b]+$/,
                                                                        message: '数字を入力してください。'
                                                                        },
                                                                        validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                                    //     maxLength: {
                                                                    //     value: 4,
                                                                    //     message: "最大長さ <= 4 を入力してください。"
                                                                    // }
                                                                        }}
                                                                    render={({ field }) => (
                                                                        <TextFieldOptions
                                                                                {...field}
                                                                                options={initialValue.m_3Sphase.ratedCurrentList}
                                                                                // label="定格電流(A)"
                                                                                // onChange= {handleInput}
                                                                                size="small"

                                                                                setValue={(name: any, value: any) => {
                                                                                    field.onChange({target:{name:name,value:value}});
                                                                                    onChangeRatedCurrent({target:{name:name,value:value}},1)

                                                                                }}
                                                                                height={32}
                                                                                error={!!errors?.m_3Sphase?.ratedCurrent}
                                                                                helperText={errors?.m_3Sphase?.ratedCurrent?.message}
                                                                                maxLength={4}
                                                                                disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING}
                                                                            />
                                                                    
                                                                    )}
                                                                />
                                                            {/* <InputLabel style={{ color: 'black' }}>定格電流(A)</InputLabel>
                                                            <Controller
                                                                name="m_3Sphase.ratedCurrent"
                                                                control={control}
                                                                rules={{ required: '必須項目です。入力してください。' }}
                                                                render={({ field }) => (
                                                                    <Select
                                                                        {...field}
                                                                        label="定格電流(A)"
                                                                        // error={!!errors?.(m_3Sphase.ratedCurrent)}
                                                                        onChange={handleChangeFormat}
                                                                    >
                                                                        {field10Options.map(item => (
                                                                            <MenuItem key={item.label} value={item.value}>
                                                                                {item.label}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                )}
                                                            /> */}
                                                            </FormControl>
                                                    </Stack>
                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                        <Typography variant="body2" width="100%">幅(mm):</Typography>
                                                        <Controller
                                                            name="m_3Sphase.width"
                                                            control={control}
                                                            rules={{
                                                                pattern:{
                                                                value: /^[0-9\b]+$/,
                                                                message: '数字を入力してください。'
                                                                },
                                                                validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                            //     maxLength: {
                                                            //     value: 3,
                                                            //     message: "最大長さ <= 3 を入力してください。"
                                                            // }
                                                                }}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    // type="text"
                                                                    size="small"
                                                                    // label="幅(mm)"
                                                                    onBlur={handleChangeFormat}
                                                                    onFocus={e => e.target.select()}
                                                                    InputLabelProps={{ style: { color: 'black' }}}
                                                                    onChange ={(e) =>{
                                                                        field.onChange(e);
                                                                        onChangeNumberPhase_width_thickness(e)
                                                                    }}
                                                                    InputProps={{sx:{height:32,backgroundColor:m_bModePM || modeViewOnly?'floralwhite':'white', color:modeViewOnly?"grey":"black"}}}
                                                                    error={!!errors?.m_3Sphase?.width}
                                                                    helperText={errors?.m_3Sphase?.width?.message}
                                                                    inputProps={{maxLength:3,readOnly:modeViewOnly}}
                                                                    disabled={m_bModePM}
                                                                />
                                                            )}
                                                        />
                                                    </Stack>
                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                        <Typography variant="body2" width="100%">厚さ(mm):</Typography>
                                                        <Controller
                                                            name="m_3Sphase.thickness"
                                                            control={control}
                                                            rules={{
                                                                pattern:{
                                                                value: /^[0-9\b]+$/,
                                                                message: '数字を入力してください。'
                                                                },
                                                                validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                            //     maxLength: {
                                                            //     value: 2,
                                                            //     message: "最大長さ <= 2 を入力してください。"
                                                            // }
                                                                }}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    // type="text"
                                                                    size="small"
                                                                    // label="厚さ(mm)"
                                                                    onBlur={handleChangeFormat}
                                                                    onFocus={e => e.target.select()}
                                                                    InputLabelProps={{ style: { color: 'black' }}}
                                                                    onChange ={(e) =>{
                                                                        field.onChange(e);
                                                                        onChangeNumberPhase_width_thickness(e)
                                                                    }}
                                                                    InputProps={{sx:{height:32,backgroundColor:m_bModePM || modeViewOnly?'floralwhite':'white', color:modeViewOnly?"grey":"black"}}}
                                                                    error={!!errors?.m_3Sphase?.thickness}
                                                                    helperText={errors?.m_3Sphase?.thickness?.message}
                                                                    inputProps={{maxLength:2,readOnly:modeViewOnly}}
                                                                    disabled={m_bModePM}
                                                                />
                                                            )}
                                                        />
                                                    </Stack>
                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                        <Typography variant="body2" width="100%">1相当り本数:</Typography>
                                                        <Controller
                                                            name="m_3Sphase.numberPhase"
                                                            control={control}
                                                            rules={{
                                                                pattern:{
                                                                value: /^[0-9\b]+$/,
                                                                message: '数字を入力してください。'
                                                                },
                                                                validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                            //     maxLength: {
                                                            //     value: 1,
                                                            //     message: "最大長さ <= 1 を入力してください。"
                                                            // }
                                                                }}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    // type="text"
                                                                    size="small"
                                                                    // label="1相当り本数"
                                                                    onBlur={handleChangeFormat}
                                                                    onFocus={e => e.target.select()}
                                                                    InputLabelProps={{ style: { color: 'black' }}}
                                                                    onChange ={(e) =>{
                                                                        field.onChange(e);
                                                                        onChangeNumberPhase_width_thickness(e)
                                                                    }}
                                                                    InputProps={{sx:{height:32,backgroundColor:m_bModePM || modeViewOnly ?'floralwhite':'white', color: modeViewOnly?"grey":"black"}}}
                                                                    error={!!errors?.m_3Sphase?.numberPhase}
                                                                    helperText={errors?.m_3Sphase?.numberPhase?.message}
                                                                    inputProps={{maxLength:1, readOnly:modeViewOnly}}
                                                                    disabled={m_bModePM}
                                                                />
                                                            )}
                                                        />
                                                    </Stack>
                                                </Grid>
                                            </Grid>
                                            <Grid container>
                                                <Grid  xs={12} mt={1}>
                                                    <fieldset style={{border:"1px solid #AAAA"}}>
                                                        <legend><Typography variant="body2">インピーダンス</Typography></legend>
                                                        <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                            <Typography variant="body2" width="100%">1m当りの抵抗 (mΩ/m):</Typography>
                                                                <Controller
                                                                    name="m_3Sphase.ohmRPerM"
                                                                    control={control}
                                                                    rules={{
                                                                        pattern:{
                                                                        value: /^[0-9]*[.]?[0-9]+$/,
                                                                        message: '数字を入力してください。'
                                                                        },
                                                                        validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                                    //     maxLength: {
                                                                    //     value: 7,
                                                                    //     message: "最大長さ <= 7 を入力してください。"
                                                                    // }
                                                                        }}
                                                                    render={({ field }) => (
                                                                        <TextField
                                                                            {...field}
                                                                            defaultValue={0}
                                                                            fullWidth
                                                                            // type="text"
                                                                            size="small"
                                                                            // label="1m当りの抵抗 (mΩ/m)"
                                                                            // onBlur={handleChangeFormat}
                                                                            onFocus={e => e.target.select()}
                                                                            InputLabelProps={{ style: { color: 'black' }}}
                                                                            onChange ={(e) =>{
                                                                                field.onChange(e);
                                                                                onChangeOhmRper(e,1)
                                                                            }}
                                                                            InputProps={{sx:{height:32 ,backgroundColor:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white', color:modeViewOnly?"grey":"black"}, 
                                                                            endAdornment:(
                                                                                <text style={{color:getValues("m_3Sphase.ohmRPerM") != getValues("m_3Sphase.ohmRPerA") ? "black" : "grey",fontSize:"30px",marginTop:"15px"}}>*</text>
                                                                            )}}
                                                                            error={!!errors?.m_3Sphase?.ohmRPerM}
                                                                            helperText={errors?.m_3Sphase?.ohmRPerM?.message}
                                                                            inputProps={{maxLength:7, readOnly:modeViewOnly}}
                                                                            disabled={m_bModePM|| processMode > MS_MODE_CHECKING}

                                                                        />
                                                                    )}
                                                                />
                                                        </Stack>
                                                        <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                            <Typography variant="body2" width="100%">抵抗(mΩ):</Typography>
                                                            <Controller
                                                                name="m_3Sphase.ohmR"
                                                                control={control}
                                                                render={({ field }) => (
                                                                    <TextField
                                                                        {...field}
                                                                        disabled
                                                                        fullWidth
                                                                        type="text"
                                                                        size="small"
                                                                        // label="抵抗(mΩ2)"
                                                                        onBlur={handleChangeFormat}
                                                                        onFocus={e => e.target.select()}
                                                                        InputProps={{sx:{height:32,backgroundColor:'floralwhite'}}}
                                                                        

                                                                    />
                                                                )}
                                                            />
                                                        </Stack>
                                                        <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                            <Typography variant="body2" width="100%">1m当りのリアクタンス(mΩ/m):</Typography>
                                                            <Controller
                                                                name="m_3Sphase.ohmXPerM"
                                                                control={control}
                                                                rules={{
                                                                    pattern:{
                                                                    value: /^[0-9]*[.]?[0-9]+$/,
                                                                    message: '数字を入力してください。'
                                                                    },
                                                                    validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                                //     maxLength: {
                                                                //     value: 7,
                                                                //     message: "最大長さ <= 7 を入力してください。"
                                                                // }
                                                                    }}
                                                                render={({ field }) => (
                                                                    <TextField
                                                                        {...field}
                                                                        fullWidth
                                                                        // type="text"
                                                                        size="small"
                                                                        // label="1m当りのリアクタンス(mΩ2/m)"
                                                                        onBlur={handleChangeFormat}
                                                                        onFocus={e => e.target.select()}
                                                                        onChange ={(e) =>{
                                                                            field.onChange(e);
                                                                            onChangeOhmXper(e,1)
                                                                        }}
                                                                        InputProps={{sx:{height:32,backgroundColor:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white', color:modeViewOnly?"grey":"black"}, 
                                                                        endAdornment:(
                                                                            <text style={{color:getValues("m_3Sphase.ohmXPerM") != getValues("m_3Sphase.ohmXPerA") ? "black" : "grey",fontSize:"30px",marginTop:"15px"}}>*</text>
                                                                        )}}
                                                                        error={!!errors?.m_3Sphase?.ohmXPerM}
                                                                        helperText={errors?.m_3Sphase?.ohmXPerM?.message}
                                                                        inputProps={{maxLength:7, readOnly:modeViewOnly}}
                                                                        disabled={m_bModePM|| processMode > MS_MODE_CHECKING}

                                                                    />
                                                                )}
                                                            />
                                                        </Stack>
                                                        <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                            <Typography variant="body2" width="100%">リアクタンス(mΩ):</Typography>
                                                            <Controller
                                                                name="m_3Sphase.ohmX"
                                                                control={control}
                                                                render={({ field }) => (
                                                                    <TextField
                                                                        {...field}
                                                                        disabled
                                                                        fullWidth
                                                                        type="text"
                                                                        size="small"
                                                                        // label="リアクタンス(mΩ)"
                                                                        onBlur={handleChangeFormat}
                                                                        onFocus={e => e.target.select()}
                                                                        InputProps={{sx:{height:32,backgroundColor:'floralwhite'}}}

                                                                    />
                                                                )}
                                                            />
                                                        </Stack>
                                                    </fieldset>
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid  item xs={12} mt={1}>
                                                <fieldset style={{border:"1px solid #AAAA"}}>
                                                <legend><Typography variant="body2">許容電流</Typography></legend>
                                                <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                    <Typography variant="body2" width="100%">連続許容電流(A):</Typography>
                                                    <Controller
                                                        name="m_3Sphase.capacity"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                fullWidth
                                                                type="text"
                                                                size="small"
                                                                disabled
                                                                // label="連続許容電流(A)"
                                                                onFocus={e => e.target.select()}
                                                                InputLabelProps={{ style: { color: 'black' }}}
                                                                InputProps={{sx:{height:32,backgroundColor:'floralwhite'}}}

                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                                </fieldset>
                                            </Grid>
                                        </Grid>
                                    </Stack>
                                </Stack>
                            </TabPanel>
                    
                            {/* tab3 - 電圧降下 */}
                        <TabPanel value={selectTab} index={(setIsDisplayTabConnectTran1||setIsDisplayTabConnectTranscott)?3:isDisplayTabTabSPhase === 2 ? 4: isDisplayTabTabSPhase === 3 ? 5 : 3}>
                            <Grid container>
                                <Grid item xs={10}>
                                    <Controller
                                            name="dropMotor"
                                            control={control}
                                            render={({ field }: any) => (
                                                <FormControlLabel
                                                    {...field}
                                                    checked={field.value}
                                                    label={<Typography variant="body2" color={m_bModePM|| processMode > MS_MODE_CHECKING?'grey':'black'}>電動機始動時の電圧降下を計算</Typography> }
                                                    name='dropMotor'
                                                    onClick={handleChangeFormat}
                                                    control={<Checkbox style={checkboxStyles} />}
                                                    disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}
                                                    // disabled={currentTabId === true? false:(setIsDisplayTabConnectTran1 || setIsDisplayTabConnectTranscott)}
                                                
                                                />
                                            )}
                                        />
                                </Grid>
                                <Grid item xs={10}>
                                    <Controller
                                            name="dropRegular"
                                            control={control}
                                            render={({ field }: any) => (
                                                <FormControlLabel
                                                    {...field}
                                                    checked={field.value}
                                                    label={<Typography variant="body2" color={m_bModePM|| processMode > MS_MODE_CHECKING?'grey':'black'}>定常時の電圧降下を計算</Typography> }
                                                    name='dropRegular'
                                                    onChange={(e) =>handleDisableByPointRegular(e)} 
                                                    control={<Checkbox style={checkboxStyles} />}
                                                    disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}

                                                />
                                            )}
                                        />
                                </Grid>
                                <Grid container>
                                    <Grid item xs={7.5}>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                            <Typography variant="body2" width="90%" ml={5}>負荷容量 (VA):</Typography>  
                                                <Controller
                                                    name="calcPoint0.loadCapacity"
                                                    control={control}
                                                    rules={{
                                                        pattern:{
                                                        value: /^[0-9]*[.]?[0-9]+$/,
                                                        message: '数字を入力してください。'
                                                        },
                                                        validate: value => value >= 0 || '数字 >= 0 を入力してください。' ,
                                                        }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            // type="text"
                                                            size="small"
                                                            // label="負荷容量 (VA)"
                                                            onBlur={handleChangeFormat}
                                                            onFocus={e => e.target.select()}
                                                            InputLabelProps={{ style: { color: 'black' }}}
                                                            onChange ={(e) => {
                                                                // handleDisableOkBt(e);
                                                                field.onChange(e);
                                                                                    onEnChangeLoadCapacity(e,false)}}
                                                            disabled={!isDisableByPointRegular||m_bModePM|| processMode > MS_MODE_CHECKING}
                                                            InputProps={{sx:{height:32,backgroundColor:m_bModePM || modeViewOnly ||!isDisableByPointRegular|| processMode > MS_MODE_CHECKING?'floralwhite':'white', color:modeViewOnly?"grey":"black"}}}
                                                            inputProps={{readOnly:modeViewOnly}}
                                                            error={!!errors?.calcPoint0?.loadCapacity}
                                                            helperText={errors?.calcPoint0?.loadCapacity?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                    </Grid>
                                    <Grid item xs ={7.5}>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                            <Typography variant="body2" width="90%" ml={5}>負荷電流 IL(A):</Typography>  
                                            <Controller
                                                name="calcPoint0.loadCurrent"
                                                control={control}
                                                rules={{
                                                    pattern:{
                                                    value: /^[0-9]*[.]?[0-9]+$/,
                                                    message: '数字を入力してください。'
                                                    },
                                                    validate: value => value >= 0 || '数字 >= 0 を入力してください。' ,
                                                //     maxLength: {
                                                //     value: 7,
                                                //     message: "最大長さ <= 7 を入力してください。"
                                                // }
                                                    }}
                                                render={({ field }) => (
                                                    <TextField
                                                    {...field}
                                                    fullWidth
                                                    // type="text"
                                                    size="small"
                                                    // label="負荷電流 IL(A)"
                                                    onBlur={handleChangeFormat}
                                                    onFocus={e => e.target.select()}
                                                    InputLabelProps={{ style: { color: 'black' }}}
                                                    onChange={(e) =>{
                                                        field.onChange(e);
                                                        onEnChangeLoadCurrent(e,false);
                                                        // handleDisableOkBt(e)
                                                    }}
                                                    disabled={!isDisableByPointRegular||m_bModePM|| processMode > MS_MODE_CHECKING}
                                                    InputProps={{sx:{height:32,backgroundColor:m_bModePM || modeViewOnly ||!isDisableByPointRegular|| processMode > MS_MODE_CHECKING?'floralwhite':'white', color:modeViewOnly?"grey":"black"}}}
                                                    error={!!errors?.calcPoint0?.loadCurrent}
                                                    helperText={errors?.calcPoint0?.loadCurrent?.message}
                                                    inputProps={{maxLength:7, readOnly:modeViewOnly}}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                    </Grid>
                                </Grid>
                                <Grid container ml={6.5}>
                                    <fieldset style={{border:"1px solid #AAAA",width:"53%"}}>
                                        <legend>{<Typography variant="body2">負荷電流方向</Typography> }</legend>
                                        <Controller
                                                name="topLeft"
                                                control={control}
                                                rules={{ required: '必須項目です。入力してください。' }}
                                                render={({ field }) => (
                                                        <RadioGroup {...field} onChange={(e) =>onBnClickedTopLeft(e)}>
                                                            <FormControlLabel
                                                                value="1"
                                                                control={<Radio style={radioStyles} />}
                                                                label={<Typography variant="body2" color={m_bModePM|| processMode > MS_MODE_CHECKING?'grey':'black'}>上→下 or 左→右</Typography> }
                                                                disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}
                                                            />
                                                            <FormControlLabel
                                                                value="0"
                                                                control={<Radio style={radioStyles} />}
                                                                label={<Typography variant="body2" color={m_bModePM|| processMode > MS_MODE_CHECKING?'grey':'black'}>下→上 or 右→左</Typography> }
                                                                disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}
                                                            />
                                                        </RadioGroup>
                                                )}
                                            />
                                        </fieldset>
                                        <Stack ml={1.2}>
                                            <Typography color="common.white">nothing</Typography>
                                            <Typography color="common.white">nothing</Typography>
                                            <Button variant="outlined" onClick={(e)=>{onBuClickedGetLoadCurrent(e,false);}} 
                                                                        disabled={!isDisableByPointRegular||m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}
                                                            >概算値</Button>
                                        </Stack>
                                </Grid>
                                <Grid container ml={3}>
                                    <fieldset style={{width:"76%",border:"1px solid #AAAA"}}>
                                        <legend>{<Typography variant="body2">回路の力率</Typography> }</legend>
                                        <Grid container>
                                            <Grid item xs={5}>
                                                <Controller
                                                    name="leadOrLag1"
                                                    control={control}
                                                    rules={{ required: '必須項目です。入力してください。' }}
                                                    render={({ field }) => (
                                                        <RadioGroup {...field} row onChange={(e)=>onBnClickedLeadOrLag(e,false)}>
                                                            <FormControlLabel
                                                                value="1"
                                                                control={<Radio style={radioStyles} />}
                                                                label={<Typography style={{color:!isDisableByPointRegular||m_bModePM|| processMode > MS_MODE_CHECKING?"grey":"black"}} variant="body2">進み</Typography> }
                                                            disabled={!isDisableByPointRegular||m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}

                                                            />
                                                            <FormControlLabel
                                                                value="0"
                                                                control={<Radio style={radioStyles} />}
                                                                label={<Typography style={{color:!isDisableByPointRegular||m_bModePM|| processMode > MS_MODE_CHECKING?"grey":"black"}} variant="body2">遅れ</Typography> }
                                                            disabled={!isDisableByPointRegular||m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}

                                                            />
                                                        </RadioGroup>
                                                    )}
                                                />
                                            </Grid>
                                            <Grid xs={1}/>
                                            <Grid xs={2.75} ml={1.4}>
                                                <Controller
                                                    name="calcPoint0.powerFactor"
                                                    control={control}
                                                    rules={{
                                                        pattern:{
                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                            message: '数字を入力してください。'
                                                        },
                                                        validate: value => value >= 0 && value <= 1 || '数字 0 ~ 1 を入力してください。' ,
        
                                                        // maxLength: {
                                                        //     value: 7,
                                                        //     message: "最大長さ <= 7 を入力してください。"
                                                        // }
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            // type="text"
                                                            size="small"
                                                            onBlur={handleChangeFormat}
                                                            onFocus={e => e.target.select()}
                                                            InputLabelProps={{ style: { color: 'black' }}}
                                                            onChange={(e) =>{
                                                                field.onChange(e);
                                                                onChangePowerFactor(e,false);
                                                                // handleDisableOkBt(e)
                                                            }}
                                                            disabled={!isDisableByPointRegular||m_bModePM|| processMode > MS_MODE_CHECKING}
                                                            InputProps={{sx:{height:32, backgroundColor:m_bModePM || modeViewOnly ||!isDisableByPointRegular|| processMode > MS_MODE_CHECKING?'floralwhite':'white',color:modeViewOnly?"grey":"black"}}}
                                                            error={!!errors?.calcPoint0?.powerFactor}
                                                            helperText={errors?.calcPoint0?.powerFactor?.message}
                                                            inputProps={{maxLength:7,readOnly:modeViewOnly}}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={2.5} ml={1.64}>
                                                <Button style={{margin:"0"}} variant="outlined" onClick={(e)=>{onBuClickedGetPowerFactor(e,false);}} disabled={!isDisableByPointRegular||m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}
                                                    >概算値</Button>
                                            </Grid>
                                        </Grid>
                                    </fieldset>
                                </Grid>
                                <Grid container  mt={0.25}>
                                    <Grid container flexDirection={"row"} ml={6} alignItems={"center"}>
                                        <Grid item xs={4} >
                                            <Typography variant="body2" width="100%" >単体での電圧降下(V):</Typography>  
                                        </Grid>
                                        <Grid item xs={4} ml={-2.6}>
                                            <Controller
                                                name="voltageDrop_1"
                                                control={control}
                                                
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        onBlur={handleChangeFormat}
                                                        onFocus={e => e.target.select()}
                                                        InputLabelProps={{ style: { color: 'black'}}}
                                                        InputProps={{sx:{height:32}}}
                                                        style={{backgroundColor: 'floralwhite',width:"70%",marginLeft:'20%'}}
                                                        disabled={true}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container flexDirection={"row"} ml={6} mt={0.25} alignItems={"center"}>
                                        <Grid item xs={4} >
                                            <Typography variant="body2" width="100%" >単体での電圧降下率(%):</Typography>  
                                        </Grid>
                                        <Grid item xs={4} ml={-2.6}>
                                            <Controller
                                                name="voltageDrop"
                                                control={control}
                                                
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        onBlur={handleChangeFormat}
                                                        onFocus={e => e.target.select()}
                                                        InputLabelProps={{ style: { color: 'black'}}}
                                                        InputProps={{sx:{height:32}}}
                                                        style={{backgroundColor: 'floralwhite',width:"70%",marginLeft:'20%'}}
                                                        disabled={true}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </TabPanel>

                    {/* tab3 - 電圧降下 when connect trans1 or transcott*/}
                    {(setIsDisplayTabConnectTran1 || setIsDisplayTabConnectTranscott) &&
                    <TabPanel value={selectTab} index={4}>
                        <Grid container>
                        <Grid item xs={10}>
                            <Controller
                                    name="dropMotor"
                                    control={control}
                                    render={({ field }: any) => (
                                        <FormControlLabel
                                            {...field}
                                            checked={field.value}
                                            label={<Typography variant="body2" color={m_bModePM|| processMode > MS_MODE_CHECKING?'grey':'black'}>電動機始動時の電圧降下を計算</Typography> }
                                            name='dropMotor'
                                            onClick={handleChangeFormat}
                                            control={<Checkbox style={checkboxStyles} />}
                                            disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}
                                            // disabled={currentTabId === true? false:(setIsDisplayTabConnectTran1 || setIsDisplayTabConnectTranscott)}
                                        />
                                    )}
                                />
                        </Grid>
                        <Grid item xs={10}>
                            <Controller
                                    name="dropRegular"
                                    control={control}
                                    render={({ field }: any) => (
                                        <FormControlLabel
                                            {...field}
                                            checked={field.value}
                                            label={<Typography variant="body2" color={m_bModePM|| processMode > MS_MODE_CHECKING?'grey':'black'}>定常時の電圧降下を計算</Typography> }
                                            name='dropRegular'
                                            onChange={(e) =>handleDisableByPointRegular(e)} 
                                            control={<Checkbox style={checkboxStyles} />}
                                            disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}
                                        />
                                    )}
                                />
                        </Grid>
                        <Grid container>
                            <Grid item xs={7.5}>
                                <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                    <Typography variant="body2" width="90%" ml={5}>負荷容量 (VA):</Typography>  
                                        <Controller
                                            name="calcPoint1.loadCapacity"
                                            control={control}
                                            rules={{
                                                pattern:{
                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                message: '数字を入力してください。'
                                                },
                                                validate: value => value >= 0 || '数字 >= 0 を入力してください。' ,
                                                }}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    // type="text"
                                                    size="small"
                                                    // label="負荷容量 (VA)"
                                                    onBlur={handleChangeFormat}
                                                    onFocus={e => e.target.select()}
                                                    InputLabelProps={{ style: { color: 'black' }}}
                                                    onChange ={(e) => {
                                                        // handleDisableOkBt(e);
                                                        field.onChange(e);
                                                        onEnChangeLoadCapacity(e,true)}}
                                                    disabled={!isDisableByPointRegular||m_bModePM|| processMode > MS_MODE_CHECKING}
                                                    InputProps={{sx:{height:32,backgroundColor:m_bModePM || modeViewOnly ||!isDisableByPointRegular|| processMode > MS_MODE_CHECKING?'floralwhite':'white', color:modeViewOnly?"grey":"black"}}}
                                                    inputProps={{readOnly:modeViewOnly}}
                                                    error={!!errors?.calcPoint1?.loadCapacity}
                                                    helperText={errors?.calcPoint1?.loadCapacity?.message}
                                                />
                                            )}
                                        />
                                    </Stack>
                            </Grid>
                            <Grid item xs ={7.5}>
                                <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                    <Typography variant="body2" width="90%" ml={5}>負荷電流 IL(A):</Typography>  
                                    <Controller
                                        name="calcPoint1.loadCurrent"
                                        control={control}
                                        rules={{
                                            pattern:{
                                            value: /^[0-9]*[.]?[0-9]+$/,
                                            message: '数字を入力してください。'
                                            },
                                            validate: value => value >= 0 || '数字 >= 0 を入力してください。' ,
                                        //     maxLength: {
                                        //     value: 7,
                                        //     message: "最大長さ <= 7 を入力してください。"
                                        // }
                                            }}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                // type="text"
                                                size="small"
                                                // label="負荷電流 IL(A)"
                                                onBlur={handleChangeFormat}
                                                onFocus={e => e.target.select()}
                                                InputLabelProps={{ style: { color: 'black' }}}
                                                onChange={(e) =>{
                                                    field.onChange(e);
                                                    onEnChangeLoadCurrent(e,true);
                                                    // handleDisableOkBt(e)
                                                }}
                                                disabled={!isDisableByPointRegular||m_bModePM|| processMode > MS_MODE_CHECKING}
                                                InputProps={{sx:{height:32,backgroundColor:m_bModePM || modeViewOnly ||!isDisableByPointRegular|| processMode > MS_MODE_CHECKING?'floralwhite':'white', color: modeViewOnly?"grey":"black"}}}
                                                error={!!errors?.calcPoint0?.loadCurrent}
                                                helperText={errors?.calcPoint0?.loadCurrent?.message}
                                                inputProps={{maxLength:7, readOnly:modeViewOnly}}
                                            />
                                        )}
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid container ml={6.5}>
                            <fieldset style={{border:"1px solid #AAAA",width:"53%"}}>
                                <legend>{<Typography variant="body2">負荷電流方向</Typography> }</legend>
                                <Controller
                                        name="topLeft"
                                        control={control}
                                        rules={{ required: '必須項目です。入力してください。' }}
                                        render={({ field }) => (
                                                <RadioGroup {...field} onChange={(e) =>onBnClickedTopLeft(e)}>
                                                    <FormControlLabel
                                                        value="1"
                                                        control={<Radio style={radioStyles} />}
                                                        label={<Typography variant="body2"  color={m_bModePM|| processMode > MS_MODE_CHECKING?'grey':'black'}>上→下 or 左→右</Typography> }
                                                        disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}
                                                    />
                                                    <FormControlLabel
                                                        value="0"
                                                        control={<Radio style={radioStyles} />}
                                                        label={<Typography variant="body2" color={m_bModePM|| processMode > MS_MODE_CHECKING?'grey':'black'}>下→上 or 右→左</Typography> }
                                                        disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}
                                                    />
                                                </RadioGroup>
                                        )}
                                    />
                                </fieldset>
                                <Stack ml={1.2}>
                                    <Typography color="common.white">nothing</Typography>
                                    <Typography color="common.white">nothing</Typography>
                                    <Button variant="outlined" onClick={(e)=>{onBuClickedGetLoadCurrent(e,true);}} 
                                                                disabled={!isDisableByPointRegular||m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}
                                                    >概算値</Button>
                                </Stack>
                        </Grid>
                        <Grid container ml={3}>
                            <fieldset style={{width:"76%",border:"1px solid #AAAA"}}>
                                <legend>{<Typography variant="body2">回路の力率</Typography> }</legend>
                                <Grid container>
                                    <Grid item xs={5}>
                                        <Controller
                                            name="leadOrLag1"
                                            control={control}
                                            rules={{ required: '必須項目です。入力してください。' }}
                                            render={({ field }) => (
                                                <RadioGroup {...field} row onChange={(e)=>onBnClickedLeadOrLag(e,true)}>
                                                    <FormControlLabel
                                                        value="1"
                                                        control={<Radio style={radioStyles} />}
                                                        label={<Typography style={{color:!isDisableByPointRegular||m_bModePM|| processMode > MS_MODE_CHECKING?"grey":"black"}}variant="body2">進み</Typography> }
                                                        disabled={!isDisableByPointRegular || modeViewOnly ||m_bModePM|| processMode > MS_MODE_CHECKING}

                                                    />
                                                    <FormControlLabel
                                                        value="0"
                                                        control={<Radio style={radioStyles} />}
                                                        label={<Typography style={{color:!isDisableByPointRegular||m_bModePM|| processMode > MS_MODE_CHECKING?"grey":"black"}}variant="body2">遅れ</Typography> }
                                                        disabled={!isDisableByPointRegular || modeViewOnly ||m_bModePM|| processMode > MS_MODE_CHECKING}

                                                    />
                                                </RadioGroup>
                                            )}
                                        />
                                    </Grid>
                                    <Grid xs={1}/>
                                    <Grid xs={2.75} ml={1.4}>
                                        <Controller
                                            name="calcPoint1.powerFactor"
                                            control={control}
                                            rules={{
                                                pattern:{
                                                    value: /^[0-9]*[.]?[0-9]+$/,
                                                    message: '数字を入力してください。'
                                                },
                                                validate: value => value >= 0 && value <= 1 || '数字 0 ~ 1 を入力してください。' ,

                                                // maxLength: {
                                                //     value: 7,
                                                //     message: "最大長さ <= 7 を入力してください。"
                                                // }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    // type="text"
                                                    size="small"
                                                    onBlur={handleChangeFormat}
                                                    onFocus={e => e.target.select()}
                                                    InputLabelProps={{ style: { color: 'black' }}}
                                                    onChange={(e) =>{
                                                        field.onChange(e);
                                                        onChangePowerFactor(e,true);
                                                        // handleDisableOkBt(e)
                                                    }}
                                                    disabled={!isDisableByPointRegular||m_bModePM|| processMode > MS_MODE_CHECKING}
                                                    InputProps={{sx:{height:32,backgroundColor:m_bModePM || modeViewOnly ||!isDisableByPointRegular|| processMode > MS_MODE_CHECKING?'floralwhite':'white', color:modeViewOnly?"grey":"black"}}}
                                                    error={!!errors?.calcPoint1?.powerFactor}
                                                    helperText={errors?.calcPoint1?.powerFactor?.message}
                                                    inputProps={{maxLength:7, readOnly:modeViewOnly}}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={2.5} ml={1.64}>
                                        <Button style={{margin:"0"}} variant="outlined"onClick={(e)=>{onBuClickedGetPowerFactor(e,true);}} disabled={!isDisableByPointRegular||m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}
                                            >概算値</Button>
                                    </Grid>
                                </Grid>
                            </fieldset>
                        </Grid>
                        <Grid container  mt={0.25}>
                            <Grid container flexDirection={"row"} ml={6} alignItems={"center"}>
                                <Grid item xs={4} >
                                    <Typography variant="body2" width="100%" >単体での電圧降下(V):</Typography>  
                                </Grid>
                                <Grid item xs={4} ml={-2.6}>
                                    <Controller
                                        name="voltageDrop_1_2"
                                        control={control}
                                        
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                type="text"
                                                size="small"
                                                onBlur={handleChangeFormat}
                                                onFocus={e => e.target.select()}
                                                InputLabelProps={{ style: { color: 'black'}}}
                                                InputProps={{sx:{height:32}}}
                                                style={{backgroundColor: 'floralwhite',width:"70%",marginLeft:'20%'}}
                                                disabled={true}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container flexDirection={"row"} ml={6} mt={0.25} alignItems={"center"}>
                                <Grid item xs={4} >
                                    <Typography variant="body2" width="100%" >単体での電圧降下率(%):</Typography>  
                                </Grid>
                                <Grid item xs={4} ml={-2.6}>
                                    <Controller
                                        name="voltageDrop2"
                                        control={control}
                                        
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                type="text"
                                                size="small"
                                                onBlur={handleChangeFormat}
                                                onFocus={e => e.target.select()}
                                                InputLabelProps={{ style: { color: 'black'}}}
                                                InputProps={{sx:{height:32}}}
                                                style={{backgroundColor: 'floralwhite',width:"70%",marginLeft:'20%'}}
                                                disabled={true}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    </TabPanel>
                        }

                        {/* tab4 - 潮流解析 */}
                        <TabPanel value={selectTab} index={(setIsDisplayTabConnectTran1||setIsDisplayTabConnectTranscott)?5:isDisplayTabTabSPhase === 2 ? 5: isDisplayTabTabSPhase === 3 ? 6 : 4}>
                            <Stack spacing={0.5} style={{ width: '100%',marginTop:'10px' }}>
                                <fieldset style={{ width: '70%',border:"1px solid #AAAA"}}>
                                    <legend><Typography variant="body2" width="100%">対中性点アドミタンス (基準容量に対する pu値)</Typography> </legend>
                                    <Grid container item xs={10}>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                <Typography variant="body2" width="100%">サセプタンス B(pu値):</Typography>
                                                        <Controller
                                                            name="susceptance"
                                                            control={control}
                                                            rules={{
                                                        
                                                            //     maxLength: {
                                                            //     value: 6,
                                                            //     message: "最大長さ <= 6 を入力してください。"
                                                            // }
                                                                }}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    type="text"
                                                                    size="small"
                                                                    // label="サセプタンス B(pu値)"
                                                                    onBlur={handleChangeFormat}
                                                                    onFocus={e => e.target.select()}
                                                                    InputLabelProps={{ style: { color: 'black' }}}
                                                                    InputProps={{sx:{height:32,backgroundColor:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white', color:modeViewOnly?"grey":"black"}}}
                                                                    error={!!errors?.susceptance}
                                                                    helperText={errors?.susceptance?.message}
                                                                    inputProps={{maxLength:6, readOnly:modeViewOnly}}
                                                                    disabled={m_bModePM|| processMode > MS_MODE_CHECKING}
                                                                />
                                                            )}
                                                        />
                                            </Stack>
                                    </Grid>
                                </fieldset>
                                <Stack>
                                    <Grid container>
                                        <Grid item xs={3.8}/>
                                        <Grid xs={6}>
                                            <Controller
                                                name="viewFlowResult"        
                                                control={control}
                                                render={({ field }: any) => (
                                                    <FormControlLabel 
                                                        {...field} 
                                                        checked={field.value}
                                                        label={<Typography variant="body2" width="100%" color={m_bModePM|| processMode > MS_MODE_CHECKING?'grey':'black'}>潮流解析結果を表示</Typography>}
                                                        name='viewFlowResult'
                                                        onClick={handleChangeFormat}
                                                        control={<Checkbox style={checkboxStyle} />}
                                                        disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING} 
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                </Stack>
                            </Stack>
                        </TabPanel>

                        {/* tab5 中性線(N相) */}
                       {((isDisplayTabTabSPhase === 2 || isDisplayTabTabSPhase === 3)&& !isModeSPhases) && 
                       <TabPanel value={selectTab} index={3}>
                            <Stack spacing={3} style={{ width: '100%', margin: 'auto' }}>
                                <Stack>
                                    <Grid container>
                                        <Grid xs={12} mt={1}>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                <Typography variant="body2" width="100%">定格電流(A):</Typography>
                                                    <FormControl fullWidth size="small">
                                                    <Controller
                                                            name="SPhaseN.ratedCurrent"
                                                            control={control}
                                                            rules={{
                                                                pattern:{
                                                                value: /^[0-9\b]+$/,
                                                                message: '数字を入力してください。'
                                                                },
                                                                validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                            //     maxLength: {
                                                            //     value: 4,
                                                            //     message: "最大長さ <= 4 を入力してください。"
                                                            // }
                                                                }}
                                                            render={({ field }) => (
                                                                <TextFieldOptions
                                                                        {...field}
                                                                        // options={setRadtedCurrentListTab5_6()}
                                                                        options={getValues('SPhaseN.ratedCurrentList')}
                                                                        // label="定格電流(A)"
                                                                        // onChange= {handleInput}
                                                                        size="small"

                                                                        setValue={(name: any, value: any) => {
                                                                            field.onChange({target:{name:name,value:value}});
                                                                            onChangeRatedCurrent({target:{name:name,value:value}},2)
                                                                        }}
                                                                        height={32}
                                                                        error={!!errors?.SPhaseN?.ratedCurrent}
                                                                        helperText={errors?.SPhaseN?.ratedCurrent?.message}
                                                                        maxLength={4}
                                                                        disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING}
                                                                    />
                                                            )}
                                                            />
                                                        {/* <InputLabel style={{ color: 'black' }}>定格電流(A)</InputLabel>
                                                        <Controller
                                                            name="SPhaseN.ratedCurrent"
                                                            control={control}
                                                            rules={{ required: '必須項目です。入力してください。' }}
                                                            render={({ field }) => (
                                                                <Select
                                                                    {...field}
                                                                    label="定格電流(A)"
                                                                    // error={!!errors?.ratedCurrent}
                                                                    onChange={handleChangeFormat}
                                                                >
                                                                    {field10Options.map(item => (
                                                                        <MenuItem key={item.label} value={item.value}>
                                                                            {item.label}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            )}
                                                        /> */}
                                                    </FormControl>
                                                </Stack>
                                                <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                    <Typography variant="body2" width="100%">幅(mm):</Typography>
                                                    <Controller
                                                        name="SPhaseN.width"
                                                        control={control}
                                                        rules={{
                                                            pattern:{
                                                            value: /^[0-9\b]+$/,
                                                            message: '数字を入力してください。'
                                                            },
                                                            validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                        //     maxLength: {
                                                        //     value: 3,
                                                        //     message: "最大長さ <= 3 を入力してください。"
                                                        // }
                                                            }}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                fullWidth
                                                                // type="text"
                                                                size="small"
                                                                // label="幅(mm)"
                                                                onBlur={handleChangeFormat}
                                                                onFocus={e => e.target.select()}
                                                                InputLabelProps={{ style: { color: 'black' }}}
                                                                onChange={(e) =>{
                                                                    field.onChange(e);
                                                                    onChangeNumberPhase_width_thickness(e)
                                                                }}
                                                                InputProps={{sx:{height:32,backgroundColor:m_bModePM || modeViewOnly ?'floralwhite':'white', color:modeViewOnly?"grey":"black"}}}
                                                                error={!!errors?.SPhaseN?.width}
                                                                helperText={errors?.SPhaseN?.width?.message}
                                                                inputProps={{maxLength:3,readOnly:modeViewOnly}}
                                                                disabled={m_bModePM}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                                <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                    <Typography variant="body2" width="100%">厚さ(mm):</Typography>
                                                    <Controller
                                                        name="SPhaseN.thickness"
                                                        control={control}
                                                        rules={{
                                                            pattern:{
                                                            value: /^[0-9\b]+$/,
                                                            message: '数字を入力してください。'
                                                            },
                                                            validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                        //     maxLength: {
                                                        //     value: 3,
                                                        //     message: "最大長さ <= 3 を入力してください。"
                                                        // }
                                                            }}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                fullWidth
                                                                // type="text"
                                                                size="small"
                                                                // label="厚さ(mm)"
                                                                onBlur={handleChangeFormat}
                                                                onFocus={e => e.target.select()}
                                                                InputLabelProps={{ style: { color: 'black' }}}
                                                                onChange={(e) =>{
                                                                    field.onChange(e);
                                                                    onChangeNumberPhase_width_thickness(e)
                                                                }}                                                               
                                                                InputProps={{sx:{height:32,backgroundColor:m_bModePM || modeViewOnly ?'floralwhite':'white', color:modeViewOnly?"grey":"black"}}}
                                                                error={!!errors?.SPhaseN?.thickness}
                                                                helperText={errors?.SPhaseN?.thickness?.message}
                                                                inputProps={{maxLength:2,readOnly:modeViewOnly}}
                                                                disabled={m_bModePM}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                                <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                    <Typography variant="body2" width="100%">1相当り本数:</Typography>
                                                    <Controller
                                                        name="SPhaseN.numberPhase"
                                                        control={control}
                                                        rules={{
                                                            pattern:{
                                                            value: /^[0-9\b]+$/,
                                                            message: '数字を入力してください。'
                                                            },
                                                            validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                        //     maxLength: {
                                                        //     value: 1,
                                                        //     message: "最大長さ <= 1 を入力してください。"
                                                        // }
                                                            }}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                fullWidth
                                                                // type="text"
                                                                size="small"
                                                                // label="1相当り本数"
                                                                onBlur={handleChangeFormat}
                                                                onFocus={e => e.target.select()}
                                                                InputLabelProps={{ style: { color: 'black' }}}
                                                                onChange={(e) =>{
                                                                    field.onChange(e);
                                                                    onChangeNumberPhase_width_thickness(e)
                                                                }}
                                                                InputProps={{sx:{height:32,backgroundColor:m_bModePM || modeViewOnly ?'floralwhite':'white', color:modeViewOnly?"grey":"black"}}}
                                                                error={!!errors?.SPhaseN?.numberPhase}
                                                                helperText={errors?.SPhaseN?.numberPhase?.message}
                                                                inputProps={{maxLength:1,readOnly:modeViewOnly}}
                                                                disabled={m_bModePM}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid  xs={12} mt={1}>
                                                <fieldset style={{border:"1px solid #AAAA"}}>
                                                    <legend><Typography variant="body2">インピーダンス</Typography></legend>
                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                        <Typography variant="body2" width="100%">1m当りの抵抗 (mΩ/m):</Typography>
                                                        <Controller
                                                            name="SPhaseN.ohmRPerM"
                                                            control={control}
                                                            rules={{
                                                                pattern:{
                                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                                message: '数字を入力してください。'
                                                                },
                                                                validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                            //     maxLength: {
                                                            //     value: 7,
                                                            //     message: "最大長さ <= 7 を入力してください。"
                                                            // }
                                                                }}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    defaultValue={0}
                                                                    fullWidth
                                                                    // type="text"
                                                                    size="small"
                                                                    // label="1m当りの抵抗 (mΩ/m)"
                                                                    onBlur={handleChangeFormat}
                                                                    onFocus={e => e.target.select()}
                                                                    InputLabelProps={{ style: { color: 'black' }}}
                                                                    onChange={(e) =>{
                                                                        field.onChange(e);
                                                                        onChangeOhmRper(e,2)
                                                                    }}
                                                                    InputProps={{sx:{height:32,backgroundColor:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white', color:modeViewOnly?"grey":"black"}, 
                                                                    endAdornment:(
                                                                        <text style={{color:getValues("SPhaseN.ohmRPerM") != getValues("SPhaseN.ohmRPerA") ? "black" : "grey",fontSize:"30px",marginTop:"15px"}}>*</text>
                                                                    )}}
                                                                    error={!!errors?.SPhaseN?.ohmRPerM}
                                                                    helperText={errors?.SPhaseN?.ohmRPerM?.message}
                                                                    inputProps={{maxLength:7, readOnly:modeViewOnly}}
                                                                    disabled={m_bModePM|| processMode > MS_MODE_CHECKING}
                                                                />
                                                            )}
                                                        />
                                                    </Stack>
                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                        <Typography variant="body2" width="100%">抵抗(mΩ):</Typography>
                                                        <Controller
                                                            name="SPhaseN.ohmR"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    disabled
                                                                    fullWidth
                                                                    type="text"
                                                                    size="small"
                                                                    // label="抵抗(mΩ2)"
                                                                    onBlur={handleChangeFormat}
                                                                    onFocus={e => e.target.select()}
                                                                    InputProps={{sx:{height:32,backgroundColor:'floralwhite'}}}

                                                                />
                                                            )}
                                                        />
                                                    </Stack>
                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                        <Typography variant="body2" width="100%">1m当りのリアクタンス(mΩ/m):</Typography>
                                                        <Controller
                                                            name="SPhaseN.ohmXPerM"
                                                            control={control}
                                                            rules={{
                                                                pattern:{
                                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                                message: '数字を入力してください。'
                                                                },
                                                                validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                            //     maxLength: {
                                                            //     value: 7,
                                                            //     message: "最大長さ <= 7 を入力してください。"
                                                            // }
                                                                }}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    // type="text"
                                                                    size="small"
                                                                    // label="1m当りのリアクタンス(mΩ2/m)"
                                                                    onBlur={handleChangeFormat}
                                                                    onFocus={e => e.target.select()}
                                                                    onChange={(e) =>{
                                                                        field.onChange(e);
                                                                        onChangeOhmXper(e,2)
                                                                    }}
                                                                    InputProps={{sx:{height:32,backgroundColor:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white', color:modeViewOnly?"grey":"black"}, 
                                                                    endAdornment:(
                                                                        <text style={{color:getValues("SPhaseN.ohmXPerM") != getValues("SPhaseN.ohmXPerA") ? "black" : "grey",fontSize:"30px",marginTop:"15px"}}>*</text>
                                                                    )}}
                                                                    error={!!errors?.SPhaseN?.ohmXPerM}
                                                                    helperText={errors?.SPhaseN?.ohmXPerM?.message}
                                                                    inputProps={{maxLength:7, readOnly:modeViewOnly}}
                                                                    disabled={m_bModePM|| processMode > MS_MODE_CHECKING}
                                                                />
                                                            )}
                                                        />
                                                    </Stack>
                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                        <Typography variant="body2" width="100%">リアクタンス(mΩ):</Typography>
                                                        <Controller
                                                            name="SPhaseN.ohmX"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    disabled
                                                                    fullWidth
                                                                    type="text"
                                                                    size="small"
                                                                    // label="リアクタンス(mΩ)"
                                                                    onBlur={handleChangeFormat}
                                                                    onFocus={e => e.target.select()}
                                                                    InputProps={{sx:{height:32,backgroundColor:'floralwhite'}}}
                                                                />
                                                            )}
                                                        />
                                                    </Stack>
                                                </fieldset>
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid  xs={12} mt={1}>
                                                <fieldset style={{border:"1px solid #AAAA"}}>
                                                    <legend><Typography variant="body2">許容電流</Typography></legend>
                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                        <Typography variant="body2" width="100%">連続許容電流(A):</Typography>
                                                        <Controller
                                                            name="SPhaseN.capacity"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    type="text"
                                                                    size="small"
                                                                    // label="連続許容電流(A)"
                                                                    disabled
                                                                    onFocus={e => e.target.select()}
                                                                    InputLabelProps={{ style: { color: 'black' }}}
                                                                    InputProps={{sx:{height:32,backgroundColor:'floralwhite'}}}

                                                                />
                                                            )}
                                                        />
                                                    </Stack>
                                                </fieldset>
                                            </Grid>
                                        </Grid>
                                </Stack>
                            </Stack>
                        </TabPanel>
                    }
                    {/* tab6 保護接地線(PE相) */}
                    {(isDisplayTabTabSPhase === 3 && !isModeSPhases) && <TabPanel value={selectTab} index={4}>
                            <Stack spacing={3} style={{ width: '100%', margin: 'auto' }}>
                                <Stack>
                                    <Grid container>
                                        <Grid xs={12} mt={1}>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                    <Typography variant="body2" width="100%">定格電流(A):</Typography>
                                                    <FormControl fullWidth size="small">
                                                    <Controller
                                                            name="sPhasePE.ratedCurrent"
                                                            control={control}
                                                            rules={{
                                                                pattern:{
                                                                value: /^[0-9\b]+$/,
                                                                message: '数字を入力してください。'
                                                                },
                                                                validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                            //     maxLength: {
                                                            //     value: 4,
                                                            //     message: "最大長さ <= 4 を入力してください。"
                                                            // }
                                                                }}
                                                            render={({ field }) => (
                                                                <TextFieldOptions
                                                                        {...field}
                                                                        // options={setRadtedCurrentListTab5_6()}
                                                                        options={getValues('sPhasePE.ratedCurrentList')}
                                                                        // label="定格電流(A)"
                                                                        // onChange= {handleInput}
                                                                        size="small"

                                                                        setValue={(name: any, value: any) => {
                                                                            field.onChange({target:{name:name,value:value}});
                                                                            onChangeRatedCurrent({target:{name:name,value:value}},3)
                                                                        
                                                                        }}
                                                                        height={32}
                                                                        error={!!errors?.sPhasePE?.ratedCurrent}
                                                                        helperText={errors?.sPhasePE?.ratedCurrent?.message}
                                                                        maxLength={4}
                                                                        disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING}
                                                                    />
                                                            )}
                                                            />
                                                        {/* <InputLabel style={{ color: 'black' }}>定格電流(A)</InputLabel>
                                                        <Controller
                                                            name="sPhasePE.ratedCurrent"
                                                            control={control}
                                                            rules={{ required: '必須項目です。入力してください。' }}
                                                            render={({ field }) => (
                                                                <Select
                                                                    {...field}
                                                                    label="定格電流(A)"
                                                                    // error={!!errors?.ratedCurrent}
                                                                    onChange={handleChangeFormat}
                                                                >
                                                                    {field10Options.map(item => (
                                                                        <MenuItem key={item.label} value={item.value}>
                                                                            {item.label}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            )}
                                                        /> */}
                                                    </FormControl>
                                                </Stack>
                                                <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                    <Typography variant="body2" width="100%">幅(mm):</Typography>
                                                    <Controller
                                                        name="sPhasePE.width"
                                                        control={control}
                                                        rules={{
                                                            pattern:{
                                                            value: /^[0-9\b]+$/,
                                                            message: '数字を入力してください。'
                                                            },
                                                            validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                        //     maxLength: {
                                                        //     value: 3,
                                                        //     message: "最大長さ <= 3 を入力してください。"
                                                        // }
                                                            }}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                fullWidth
                                                                // type="text"
                                                                size="small"
                                                                // label="幅(mm)"
                                                                // onBlur={handleChangeFormat}
                                                                onFocus={e => e.target.select()}
                                                                InputLabelProps={{ style: { color: 'black' }}}
                                                                onChange={(e) =>{
                                                                    field.onChange(e);
                                                                    onChangeNumberPhase_width_thickness(e)
                                                                }}
                                                                InputProps={{sx:{height:32,backgroundColor:m_bModePM || modeViewOnly ?'floralwhite':'white', color:modeViewOnly?"grey":"black"}}}
                                                                error={!!errors?.sPhasePE?.width}
                                                                helperText={errors?.sPhasePE?.width?.message}
                                                                inputProps={{maxLength:3, readOnly:modeViewOnly}}
                                                                disabled={m_bModePM}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                                <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                    <Typography variant="body2" width="100%">厚さ(mm):</Typography>
                                                    <Controller
                                                        name="sPhasePE.thickness"
                                                        control={control}
                                                        rules={{
                                                            pattern:{
                                                            value: /^[0-9\b]+$/,
                                                            message: '数字を入力してください。'
                                                            },
                                                            validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                        //     maxLength: {
                                                        //     value: 2,
                                                        //     message: "最大長さ <= 2 を入力してください。"
                                                        // }
                                                            }}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                fullWidth
                                                                // type="text"
                                                                size="small"
                                                                // label="厚さ(mm)"
                                                                onBlur={handleChangeFormat}
                                                                onFocus={e => e.target.select()}
                                                                InputLabelProps={{ style: { color: 'black' }}}
                                                                onChange={(e) =>{
                                                                    field.onChange(e);
                                                                    onChangeNumberPhase_width_thickness(e)
                                                                }}
                                                                InputProps={{sx:{height:32,backgroundColor:m_bModePM || modeViewOnly?'floralwhite':'white',color:modeViewOnly?"grey":"black"}}}
                                                                error={!!errors?.sPhasePE?.thickness}
                                                                helperText={errors?.sPhasePE?.thickness?.message}
                                                                inputProps={{maxLength:2,readOnly:modeViewOnly}}
                                                                disabled={m_bModePM}

                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                                <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                    <Typography variant="body2" width="100%">1相当り本数:</Typography>
                                                    <Controller
                                                        name="sPhasePE.numberPhase"
                                                        control={control}
                                                        rules={{
                                                            pattern:{
                                                            value: /^[0-9\b]+$/,
                                                            message: '数字を入力してください。'
                                                            },
                                                            validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                        //     maxLength: {
                                                        //     value: 1,
                                                        //     message: "最大長さ <= 1 を入力してください。"
                                                        // }
                                                            }}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                fullWidth
                                                                // type="text"
                                                                size="small"
                                                                // label="1相当り本数"
                                                                onBlur={handleChangeFormat}
                                                                onFocus={e => e.target.select()}
                                                                InputLabelProps={{ style: { color: 'black' }}}
                                                                onChange={(e) =>{
                                                                    field.onChange(e);
                                                                    onChangeNumberPhase_width_thickness(e)
                                                                }}
                                                                InputProps={{sx:{height:32,backgroundColor:m_bModePM || modeViewOnly?'floralwhite':'white',color:modeViewOnly?"grey":"black"}}}
                                                                error={!!errors?.sPhasePE?.numberPhase}
                                                                helperText={errors?.sPhasePE?.numberPhase?.message}
                                                                inputProps={{maxLength:1,readOnly:modeViewOnly}}
                                                                disabled={m_bModePM}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid  xs={12} mt={1}>
                                                <fieldset style={{border:"1px solid #AAAA"}}>
                                                    <legend><Typography variant="body2">インピーダンス</Typography></legend>
                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                        <Typography variant="body2" width="100%">1m当りの抵抗 (mΩ/m):</Typography>
                                                        <Controller
                                                            name="sPhasePE.ohmRPerM"
                                                            control={control}
                                                            rules={{
                                                                pattern:{
                                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                                message: '数字を入力してください。'
                                                                },
                                                                validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                            //     maxLength: {
                                                            //     value: 7,
                                                            //     message: "最大長さ <= 7 を入力してください。"
                                                            // }
                                                                }}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    defaultValue={0}
                                                                    fullWidth
                                                                    // type="text"
                                                                    size="small"
                                                                    // label="1m当りの抵抗 (mΩ/m)"
                                                                    onBlur={handleChangeFormat}
                                                                    onFocus={e => e.target.select()}
                                                                    InputLabelProps={{ style: { color: 'black' }}}
                                                                    onChange ={(e) =>{
                                                                        field.onChange(e)
                                                                        onChangeOhmRper(e,3);
                                                                    }}
                                                                    InputProps={{sx:{height:32,backgroundColor:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white', color:modeViewOnly?"grey":"black"}, 
                                                                    endAdornment:(
                                                                        <text style={{color:getValues("sPhasePE.ohmRPerM") != getValues("sPhasePE.ohmRPerA") ? "black" : "grey",fontSize:"30px",marginTop:"15px"}}>*</text>
                                                                    )}}
                                                                    error={!!errors?.sPhasePE?.ohmRPerM}
                                                                    helperText={errors?.sPhasePE?.ohmRPerM?.message}
                                                                    inputProps={{maxLength:7, readOnly:modeViewOnly}}
                                                                    disabled={m_bModePM|| processMode > MS_MODE_CHECKING}
                                                                />
                                                            )}
                                                        />
                                                    </Stack>
                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                        <Typography variant="body2" width="100%">抵抗(mΩ):</Typography>
                                                        <Controller
                                                            name="sPhasePE.ohmR"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    disabled
                                                                    fullWidth
                                                                    type="text"
                                                                    size="small"
                                                                    // label="抵抗(mΩ2)"
                                                                    onBlur={handleChangeFormat}
                                                                    onFocus={e => e.target.select()}
                                                                    InputProps={{sx:{height:32,backgroundColor:'floralwhite'}}}

                                                                />
                                                            )}
                                                        />
                                                    </Stack>
                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                        <Typography variant="body2" width="100%">1m当りのリアクタンス(mΩ/m):</Typography>
                                                        <Controller
                                                            name="sPhasePE.ohmXPerM"
                                                            control={control}
                                                            rules={{
                                                                pattern:{
                                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                                message: '数字を入力してください。'
                                                                },
                                                                validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                            //     maxLength: {
                                                            //     value: 7,
                                                            //     message: "最大長さ <= 7 を入力してください。"
                                                            // }
                                                                }}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    // type="text"
                                                                    size="small"
                                                                    // label="1m当りのリアクタンス(mΩ2/m)"
                                                                    onBlur={handleChangeFormat}
                                                                    onFocus={e => e.target.select()}
                                                                    onChange ={(e) =>{
                                                                        field.onChange(e)
                                                                        onChangeOhmXper(e,3);
                                                                    }}
                                                                    InputProps={{sx:{height:32,backgroundColor:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white', color:modeViewOnly?"grey":"black"}, 
                                                                    endAdornment:(
                                                                        <text style={{color:getValues("sPhasePE.ohmXPerM") != getValues("sPhasePE.ohmXPerA") ? "black" : "grey",fontSize:"30px",marginTop:"15px"}}>*</text>
                                                                    )}}
                                                                    error={!!errors?.sPhasePE?.ohmXPerM}
                                                                    helperText={errors?.sPhasePE?.ohmXPerM?.message}
                                                                    inputProps={{maxLength:7,readOnly:modeViewOnly}}
                                                                    disabled={m_bModePM|| processMode > MS_MODE_CHECKING}
                                                                />
                                                            )}
                                                        />
                                                    </Stack>
                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                        <Typography variant="body2" width="100%">リアクタンス(mΩ):</Typography>
                                                        <Controller
                                                            name="sPhasePE.ohmX"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    disabled
                                                                    fullWidth
                                                                    type="text"
                                                                    size="small"
                                                                    // label="リアクタンス(mΩ)"
                                                                    onBlur={handleChangeFormat}
                                                                    onFocus={e => e.target.select()}
                                                                    InputProps={{sx:{height:32,backgroundColor:'floralwhite'}}}

                                                                />
                                                            )}
                                                        />
                                                    </Stack>
                                                </fieldset>
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid  xs={12} mt={1}>
                                                <fieldset style={{border:"1px solid #AAAA"}}>
                                                    <legend><Typography variant="body2">許容電流</Typography></legend>
                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                        <Typography variant="body2" width="100%">連続許容電流(A):</Typography>
                                                        <Controller
                                                            name="sPhasePE.capacity"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    type="text"
                                                                    size="small"
                                                                    // label="連続許容電流(A)"
                                                                    onBlur={handleChangeFormat}
                                                                    onFocus={e => e.target.select()}
                                                                    InputLabelProps={{ style: { color: 'black' }}}
                                                                    disabled
                                                                    InputProps={{sx:{height:32,backgroundColor:'floralwhite'}}}
                                                                />
                                                            )}
                                                        />
                                                    </Stack>
                                                </fieldset>
                                            </Grid>
                                        </Grid>
                                </Stack>
                            </Stack>
                        </TabPanel>
                        }
                        
                    </div>
                    <Stack direction='row' justifyContent="flex-end" spacing={2} sx={{ mt: 2 }}>
                        <Button variant="contained" type="submit" disabled={modeViewOnly}>OK</Button>
                        <Button color="inherit" variant="contained" onClick={handleCancel}>
                            キャンセル
                        </Button>
                    </Stack>
                </form>
        </>
    )
}

export default MsBusductEditDialog