import { Button, ButtonGroup, Checkbox, Container, Dialog, DialogContent, DialogTitle, FormControlLabel, Grid, Paper, Radio, RadioGroup, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, TextareaAutosize, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import theme from "../../themes/globalStyles";
import DraggablePaper from "../common/DraggablePaper";
import { InfoCircuit, InfoCover } from "../../models/Index";
import { useCallback, useState } from "react";
import { boolean, isNull } from "mathjs";

export type SettingCoverForm = {
    corporation:string,
    titleCorporationAddress:string,
    corporationAddress:string
    titleCorporationTelephone:string,
    corporationTelephone:string,
    titleDateDesigned: string,
    dateDesigned: string,
    application: string,
    titleProject:string,
    project:string,
    titleProjectAddress:string,
    projectAddress: string,
    titleProjectNumber:string,
    projectNumber:string,
    item1 : string,
    item2 : string,
    item3 : string,
    item4 : string,
    item5 : string,
    item6 : string,
    item7 : string,
    item8 : string,
    item9 : string,
    item10 : string,
    item11 : string,
    item12 : string,
    item13 : string,
    item14 : string,
    item15 : string,
    item16 : string,
    item17 : string,
    item18 : string,
    item19 : string,
    item20 : string,
    item21 : string,
    item22 : string,
    item23 : string,
    item24 : string,
    item25 : string,
    item26 : string,
    item27 : string,
    item28 : string,
    item29 : string,
    item30 : string,
    item31 : string,

    content1 : string,
    content2 : string,
    content3 : string,
    content4 : string,
    content5 : string,
    content6 : string,
    content7 : string,
    content8 : string,
    content9 : string,
    content10 : string,
    content11 : string,
    content12 : string,
    content13 : string,
    content14 : string,
    content15 : string,
    content16 : string,
    content17 : string,
    content18 : string,
    content19 : string,
    content20 : string,
    content21 : string,
    content22 : string,
    content23 : string,
    content24 : string,
    content25 : string,
    content26 : string,
    content27 : string,
    content28 : string,
    content29 : string,
    content30 : string,
    content31 : string,

    page1 : string,
    page2 : string,
    page3 : string,
    page4 : string,
    page5 : string,
    page6 : string,
    page7 : string,
    page8 : string,
    page9 : string,
    page10 : string,
    page11 : string,

    page12 : string,
    page13 : string,
    page14 : string,
    page15 : string,
    page16 : string,
    page17 : string,
    page18 : string,
    page19 : string,
    page20 : string,
    page21 : string,
    page22 : string,

    page23 : string,
    page24 : string,
    page25 : string,
    page26 : string,
    page27 : string,
    page28 : string,
    page29 : string,
    page30 : string,
    page31 : string,
}

export type SettingCoverFormProps = {
    infoCircuit: InfoCircuit;
    data: InfoCover;
    title: string;
    onOK: (data: any) => void;
    onCancel: () => void;
    modeViewOnly:boolean;
};
const MIN_OF_POINTS_ARRAY = 10

export const SettingCoverDialog = (props: SettingCoverFormProps) => {

    //#region fields
    const {infoCircuit, data, title, onOK, onCancel,modeViewOnly } = props;

    // 初期値設定
    const initialValue : SettingCoverForm = {
        ...data,
        corporation: infoCircuit.corporation,
        project: infoCircuit.project,
        titleProject: infoCircuit.titleProject,
        titleProjectAddress: infoCircuit.titleReference,
        projectAddress: infoCircuit.reference,
    }
    const checkboxStyle = {
        padding: '0px 0px 0px 9px'
    }
    let dataList = [{item:initialValue.item2,content:initialValue.content2,page:initialValue.page2},
                    {item:initialValue.item3,content:initialValue.content3,page:initialValue.page3},
                    {item:initialValue.item4,content:initialValue.content4,page:initialValue.page4},
                    {item:initialValue.item5,content:initialValue.content5,page:initialValue.page5},
                    {item:initialValue.item6,content:initialValue.content6,page:initialValue.page6},
                    {item:initialValue.item7,content:initialValue.content7,page:initialValue.page7},
                    {item:initialValue.item8,content:initialValue.content8,page:initialValue.page8},
                    {item:initialValue.item9,content:initialValue.content9,page:initialValue.page9},
                    {item:initialValue.item10,content:initialValue.content10,page:initialValue.page10},
                    {item:initialValue.item11,content:initialValue.content11,page:initialValue.page11},
                    {item:initialValue.item12,content:initialValue.content12,page:initialValue.page12},
                    {item:initialValue.item13,content:initialValue.content13,page:initialValue.page13},
                    {item:initialValue.item14,content:initialValue.content14,page:initialValue.page14},
                    {item:initialValue.item15,content:initialValue.content15,page:initialValue.page15},
                    {item:initialValue.item16,content:initialValue.content16,page:initialValue.page16},
                    {item:initialValue.item17,content:initialValue.content17,page:initialValue.page17},
                    {item:initialValue.item18,content:initialValue.content18,page:initialValue.page18},
                    {item:initialValue.item19,content:initialValue.content19,page:initialValue.page19},
                    {item:initialValue.item20,content:initialValue.content20,page:initialValue.page20},
                    {item:initialValue.item21,content:initialValue.content21,page:initialValue.page21},
                    {item:initialValue.item22,content:initialValue.content22,page:initialValue.page22},
                    {item:initialValue.item23,content:initialValue.content23,page:initialValue.page23},
                    {item:initialValue.item24,content:initialValue.content24,page:initialValue.page24},
                    {item:initialValue.item25,content:initialValue.content25,page:initialValue.page25},
                    {item:initialValue.item26,content:initialValue.content26,page:initialValue.page26},
                    {item:initialValue.item27,content:initialValue.content27,page:initialValue.page27},
                    {item:initialValue.item28,content:initialValue.content28,page:initialValue.page28},
                    {item:initialValue.item29,content:initialValue.content29,page:initialValue.page29},
                    {item:initialValue.item30,content:initialValue.content30,page:initialValue.page30},
                    {item:initialValue.item31,content:initialValue.content31,page:initialValue.page31},
    ]
    dataList = dataList.filter((item) => !isNull(item.item));
    

    const { control, handleSubmit, formState: { errors }, setValue, getValues } = useForm<SettingCoverForm>({
        mode: "all",
        criteriaMode: "all",
        defaultValues: initialValue,
    })
    
    let initPointsArr = dataList
        if(initPointsArr.length <=0){
            initPointsArr =[{item:"",content:"",page:""},{item:"",content:"",page:""},{item:"",content:"",page:""},{item:"",content:"",page:""},{item:"",content:"",page:""}]
        }
    const [array, setArray] = useState<any>(initPointsArr);

    //#endregion fields
    const [deleteField, setDeleteField] = useState(false)

    //#region methods
    // Submitイベント
    const handleEditSubmit = (value: any, event: any) => {
        const request = createRequestData(value);
        onOK(request);
    }
    const convertArrayToData =(request:any)=>{
        for(var i =0; i< 30;i++){
            request['item'+(i+2)] = null
            request['content'+(i+2)] = null
            request['page'+(i+2)] = null
        }

        for(var i =0; i< array.length;i++){
            if((array[i].item) === undefined &&!deleteField){
                array[i].item =""
            }
            if((array[i].content) === undefined&&!deleteField){
                array[i].content =""
            }
            if((array[i].page) === undefined&&!deleteField){
                array[i].page =""
            }
            request['item'+(i+2)] = array[i].item
            request['content'+(i+2)] = array[i].content
            request['page'+(i+2)] = array[i].page
        }
    }
   
    // FormデータからRequestデータを作成する
    const createRequestData = (formValue : any) => {
        convertArrayToData(formValue)
        let request = {
            ...formValue,
        } as any;
        return request;
    }
    const [selectedPointItem, setSelectedPointItem] = useState<any>({
        index: -1,
        axis: 'item'
    });
    const [isDisableButtonAdd,setDisableButtonAdd] = useState(array.length >30)
    const [isClickButtonAdd, setIsClickButtonAdd] = useState(false)

    //#endregion methods
    
    const checkDisable = (index: any) => {
        return index !== 0 &&(array[index - 1].item === undefined && array[index - 1].content === undefined && array[index - 1].page === undefined)&& (array[index].item === undefined&&array[index].content === undefined&&array[index].page === undefined&&!isClickButtonAdd);
    }
    const handleDeleteItem = () => {
        const index = selectedPointItem.index;
        if(index >= 0){
            array.splice(index,1);
            if(array.length < MIN_OF_POINTS_ARRAY){
                array.push({item:"",content:"",page:""});
                setDeleteField(true)
                setIsClickButtonAdd(false)
            }
            setArray([...array])

            
        }
        if(array.length >29){
            setDisableButtonAdd(true)
        }
        else{
            setDisableButtonAdd(false)
        }
    }
    
    const handleAddItem = () => {
        const index = selectedPointItem.index;
        if(index >=0){
            array.splice(index,0,{item:"",content:"",page:""})
            setArray([...array])
            setIsClickButtonAdd(true)
        }
        if(array.length >29){
            setDisableButtonAdd(true)
        }
        else{
            setDisableButtonAdd(false)
        }
    }

    const renderItems = () => {
        return array.map((item: any, index: any) => (
            <div key={index} style={{ marginBottom: "10px", marginTop:'5px' }}>
                <Grid container direction={"row"}>
                    <Grid item xs={2.5}>
                        <TextField
                                id={`item${index}`}
                                size="small"
                                value={item.item ? item.item: ""}
                                disabled={checkDisable(index)||modeViewOnly}
                                fullWidth
                                style={{
                                    backgroundColor: (checkDisable(index)||modeViewOnly) ? "floralwhite" : "white"
                                }}
                                inputProps={{style:{textAlign:'center'}}}
                                name ={`item${index+2}`}
                                onChange={(e: any) => {
                                    item.item = e.target.value;
                                    setArray([...array])
                                }}
                                onFocus={() => {
                                    setSelectedPointItem({index, axis: 'item'});
                                }}
                                onBlur={(e:any) => {
                                    if(e.relatedTarget){
                                        if(e.relatedTarget.id === "deleteButton"){
                                            setSelectedPointItem({index: index, axis: 'item'});
                                        }
                                        else if(e.relatedTarget.id === "addButton"){
                                            setSelectedPointItem({index: index, axis: 'item'});
                                        }
                                    }
                                }}
                                InputProps={{
                                    sx:{height:32},
                                }}
                            />
                    </Grid>
                    <Grid item xs ={6.7} ml={0.5} mr={0.5} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                        <TextField
                            id={`content${index}`}
                            fullWidth
                            value={item.content ? item.content : ""}
                            disabled={checkDisable(index)||modeViewOnly}
                            style={{
                                backgroundColor: (checkDisable(index)||modeViewOnly) ? "floralwhite" : "white"
                            }}
                            name ={`content${index+2}`}
                            onChange={(e: any) => {
                                item.content = e.target.value;
                                setArray([...array])
                            }}
                            onFocus={() => {
                                setSelectedPointItem({index, axis: 'content'});
                            }}
                            onBlur={(e:any) => {
                                if(e.relatedTarget){
                                    if(e.relatedTarget.id === "deleteButton"){
                                        setSelectedPointItem({index: index, axis: 'content'});
                                    }
                                    else if(e.relatedTarget.id === "addButton"){
                                        setSelectedPointItem({index: index, axis: 'content'});

                                    }
                                }
                            }}
                            InputProps={{
                                sx:{height:32},
                            }}
                        />
                    </Grid>
                    <Grid item xs={2.5} >
                        <TextField
                            id={`page${index}`}
                            size="small"
                            value={item.page ? item.page : ""}
                            disabled={checkDisable(index)||modeViewOnly}
                            style={{
                                backgroundColor: (checkDisable(index)||modeViewOnly) ? "floralwhite" : "white"
                            }}
                            name ={`page${index+2}`}

                            onChange={(e: any) => {
                                item.page = e.target.value;
                                setArray([...array])
                            }}
                            onFocus={() => {
                                setSelectedPointItem({index, axis: 'page'});
                            }}  
                            onBlur={(e:any) => {
                                if(e.relatedTarget){
                                    if(e.relatedTarget.id === "deleteButton"){
                                        setSelectedPointItem({index: index, axis: 'page'});
                                    }
                                    else if(e.relatedTarget.id === "addButton"){
                                        setSelectedPointItem({index: index, axis: 'content'});

                                    }
                                }
                            }}
                            InputProps={{
                                sx:{height:32},
                            }}
                        />
                    </Grid> 
                </Grid>
            </div>
        ));
    }
    const handleReset = () =>{
        if(title === '表紙')
        {
            setValue("titleCorporationAddress",'連絡宛先')  
            setValue("titleCorporationTelephone",'連絡電話')  
            setValue("titleDateDesigned",'日付')  
            setValue("application", '設計計算書') 
            setValue("titleProjectNumber", '工事番号') 
        }
        else
        {
            setValue("titleCorporationAddress",initialValue.titleCorporationAddress)
            setValue("titleCorporationTelephone",initialValue.titleCorporationTelephone)
            setValue("titleDateDesigned",initialValue.titleDateDesigned)
            setValue("application",initialValue.application)
            setValue("titleProjectNumber",initialValue.titleProjectNumber)
        }
        setValue("titleProject",initialValue.titleProject)
        setValue("titleProjectAddress",initialValue.titleProjectAddress)
        setValue("item1",initialValue.item1)
        setValue("content1",initialValue.content1)
        setValue("page1",initialValue.page1)
    }


    return (
        <>
            <Dialog open={true} maxWidth={"md"} fullWidth
                PaperComponent={
                    DraggablePaper
                }
            >
                <DialogTitle 
                    onMouseOver={(e : any) => e.target.style.cursor = 'move'}
                    style={{ paddingTop: '2px', paddingBottom: '2px', background: theme.palette.primary.main, color: theme.palette.primary.contrastText }}
                >
                    レポートの設定 -［{title}］
                </DialogTitle>
                <DialogContent style={{paddingTop: '10px'}}>
                    <div style={{height:"40vh", width: '100%',marginLeft:'-10px'}}>
                        <form onSubmit={handleSubmit(handleEditSubmit)}>
                            <div style={{height:"calc(40vh - 45px)",overflowY:"auto", width: '100%'}}>
                            <Grid container>
                                <Grid xs={9.25}>
                                    <Controller
                                         name="corporation"
                                         control={control}
                                        rules={{
                                        }}
                                        render={({ field }) => (
                                             <TextField 
                                                {...field}
                                                fullWidth
                                                type="text"
                                                size="small"
                                                error={!!errors?.corporation}
                                                helperText={errors?.corporation?.message}
                                                disabled={true}
                                                style={{
                                                    backgroundColor: 'floralwhite' ,

                                                }}
                                                InputProps={{sx: { height: 32}}}
                                                inputProps={{style:{textAlign:"center"}}}
                                            ></TextField>
                                         )}
                                        /> 
                                </Grid>

                                <Grid container direction={"row"} mt={1}>
                                    <Grid item xs={3}>
                                        <Controller
                                            name="titleCorporationAddress"
                                            control={control}
                                            rules={{
                                            }}
                                            render={({ field }) => (
                                                <TextField 
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    size="small"
                                                    error={!!errors?.titleCorporationAddress}
                                                    helperText={errors?.titleCorporationAddress?.message}
                                                    disabled={modeViewOnly}
                                                    InputProps={{sx: { height: 32 }}}
                                                ></TextField>
                                            )}
                                            /> 
                                    </Grid>
                                    <Grid item xs ={0.25} display={'flex'} alignItems={'center'} justifyContent={'center'}>:</Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            name="corporationAddress"
                                            control={control}
                                            rules={{
                                            }}
                                            render={({ field }) => (
                                                <TextField 
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    size="small"
                                                    error={!!errors?.corporationAddress}
                                                    helperText={errors?.corporationAddress?.message}
                                                    InputProps={{sx: { height: 32 }}}
                                                    disabled={modeViewOnly}
                                                ></TextField>
                                            )}
                                            /> 
                                    </Grid>
                                </Grid>

                                <Grid container direction={"row"} mt={1}>
                                    <Grid item xs={3}>
                                        <Controller
                                            name="titleCorporationTelephone"
                                            control={control}
                                            rules={{
                                            }}
                                            render={({ field }) => (
                                                <TextField 
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    size="small"
                                                    error={!!errors?.titleCorporationTelephone}
                                                    helperText={errors?.titleCorporationTelephone?.message}
                                                    disabled={modeViewOnly}
                                                    InputProps={{sx: { height: 32 }}}
                                                ></TextField>
                                            )}
                                            /> 
                                    </Grid>
                                    <Grid item xs ={0.25} display={'flex'} alignItems={'center'} justifyContent={'center'}>:</Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            name="corporationTelephone"
                                            control={control}
                                            rules={{
                                            }}
                                            render={({ field }) => (
                                                <TextField 
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    size="small"
                                                    error={!!errors?.corporationTelephone}
                                                    helperText={errors?.corporationTelephone?.message}
                                                    InputProps={{sx: { height: 32 }}}
                                                    disabled={modeViewOnly}
                                                ></TextField>
                                            )}
                                            /> 
                                    </Grid>
                                </Grid>

                                <Grid container direction={"row"} mt={1}>
                                    <Grid item xs={3}>
                                        <Controller
                                            name="titleDateDesigned"
                                            control={control}
                                            rules={{
                                            }}
                                            render={({ field }) => (
                                                <TextField 
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    size="small"
                                                    error={!!errors?.titleDateDesigned}
                                                    helperText={errors?.titleDateDesigned?.message}
                                                    disabled={modeViewOnly}
                                                    InputProps={{sx: { height: 32 }}}
                                                ></TextField>
                                            )}
                                            /> 
                                    </Grid>
                                    <Grid item xs ={0.25} display={'flex'} alignItems={'center'} justifyContent={'center'}>:</Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            name="dateDesigned"
                                            control={control}
                                            rules={{
                                            }}
                                            render={({ field }) => (
                                                <TextField 
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    size="small"
                                                    error={!!errors?.dateDesigned}
                                                    helperText={errors?.dateDesigned?.message}
                                                    InputProps={{sx: { height: 32 }}}
                                                    disabled={modeViewOnly}
                                                ></TextField>
                                            )}
                                            /> 
                                    </Grid>
                                </Grid>
                                <Grid style={{borderTop:"1px solid black",width:"100%",marginTop:"5px"}}></Grid>
                                <Grid container>
                                    <Grid xs={3.5}  mt={1}  />
                                    <Grid xs={6}  mt={1}  display={'flex'}>
                                        <Controller
                                            name="application"
                                            control={control}
                                            rules={{
                                            }}
                                            render={({ field }) => (
                                                <TextField 
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    size="small"
                                                    error={!!errors?.application}
                                                    helperText={errors?.application?.message}
                                                    InputProps={{sx: { height: 32}}}
                                                    inputProps={{style:{textAlign:'center'}}}
                                                    disabled={modeViewOnly}
                                                />
                                            )}
                                            /> 
                                    </Grid>
                                    <Grid xs={2.3}>
                                        <Stack alignItems={'flex-end'} mt={1}>
                                            <Button color="inherit"  variant="contained" onClick={handleReset} disabled={modeViewOnly}>リセット</Button>
                                        </Stack>
                                    </Grid>
                                </Grid>
                                <Grid container direction={"row"} mt={1}>
                                    <Grid item xs={3}>
                                        <Controller
                                            name="titleProject"
                                            control={control}
                                            rules={{
                                            }}
                                            render={({ field }) => (
                                                <TextField 
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    size="small"
                                                    error={!!errors?.titleProject}
                                                    helperText={errors?.titleProject?.message}
                                                    // value={"プロジェクト"}
                                                    disabled={true}
                                                    style={{
                                                        backgroundColor: 'floralwhite' ,
                                                    }}
                                                    InputProps={{sx: { height: 32 }}}
                                                ></TextField>
                                            )}
                                            /> 
                                    </Grid>
                                    <Grid item xs ={0.25} display={'flex'} alignItems={'center'} justifyContent={'center'}>:</Grid>
                                    <Grid item xs={8.75}>
                                        <Controller
                                            name="project"
                                            control={control}
                                            rules={{
                                            }}
                                            render={({ field }) => (
                                                <TextField 
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    size="small"
                                                    error={!!errors?.project}
                                                    helperText={errors?.project?.message}
                                                    InputProps={{sx: { height: 32 }}}
                                                    disabled={true}
                                                    style={{
                                                        backgroundColor: 'floralwhite' ,
                                                    }}
                                                ></TextField>
                                            )}
                                            /> 
                                    </Grid>
                                </Grid>

                                <Grid container direction={"row"} mt={1}>
                                    <Grid item xs={3}>
                                        <Controller
                                            name="titleProjectAddress"
                                            control={control}
                                            rules={{
                                            }}
                                            render={({ field }) => (
                                                <TextField 
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    size="small"
                                                    error={!!errors?.titleProjectAddress}
                                                    helperText={errors?.titleProjectAddress?.message}
                                                    // value={"回路名称"}
                                                    disabled={true}
                                                    InputProps={{sx: { height: 32 }}}
                                                    style={{
                                                        backgroundColor: 'floralwhite' ,
                                                    }}
                                                ></TextField>
                                            )}
                                            /> 
                                    </Grid>
                                    <Grid item xs ={0.25} display={'flex'} alignItems={'center'} justifyContent={'center'}>:</Grid>
                                    <Grid item xs={8.75}>
                                        <Controller
                                            name="projectAddress"
                                            control={control}
                                            rules={{
                                            }}
                                            render={({ field }) => (
                                                <TextField 
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    size="small"
                                                    error={!!errors?.projectAddress}
                                                    helperText={errors?.projectAddress?.message}
                                                    InputProps={{sx: { height: 32 }}}
                                                    disabled={true}
                                                    style={{
                                                        backgroundColor: 'floralwhite' ,
                                                    }}
                                                ></TextField>
                                            )}
                                            /> 
                                    </Grid>
                                </Grid>

                                <Grid container direction={"row"} mt={1}>
                                    <Grid item xs={3}>
                                        <Controller
                                            name="titleProjectNumber"
                                            control={control}
                                            rules={{
                                            }}
                                            render={({ field }) => (
                                                <TextField 
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    size="small"
                                                    error={!!errors?.titleProjectNumber}
                                                    helperText={errors?.titleProjectNumber?.message}
                                                    disabled={modeViewOnly}
                                                    InputProps={{sx: { height: 32 }}}
                                                ></TextField>
                                            )}
                                            /> 
                                    </Grid>
                                    <Grid item xs ={0.25} display={'flex'} alignItems={'center'} justifyContent={'center'}>:</Grid>
                                    <Grid item xs={8.75}>
                                        <Controller
                                            name="projectNumber"
                                            control={control}
                                            rules={{
                                            }}
                                            render={({ field }) => (
                                                <TextField 
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    size="small"
                                                    error={!!errors?.projectNumber}
                                                    helperText={errors?.projectNumber?.message}
                                                    InputProps={{sx: { height: 32 }}}
                                                    disabled={modeViewOnly}
                                                ></TextField>
                                            )}
                                            /> 
                                    </Grid>
                                </Grid>

                                <Grid container mt={1}>
                                    <fieldset style={{width:"100%",border:'1px solid #AAAA'}}>
                                            <Grid container direction={"row"} mt={1}>
                                                <Grid item xs={2.45}>
                                                    <Controller
                                                        name="item1"
                                                        control={control}
                                                        rules={{
                                                        }}
                                                        render={({ field }) => (
                                                            <TextField 
                                                                {...field}
                                                                fullWidth
                                                                type="text"
                                                                size="small"
                                                                error={!!errors?.item1}
                                                                helperText={errors?.item1?.message}
                                                                InputProps={{sx: { height: 32 }}}
                                                                inputProps={{style:{textAlign:'center'}}}
                                                                disabled={modeViewOnly}
                                                            ></TextField>
                                                        )}
                                                        /> 
                                                    </Grid>
                                                <Grid item xs ={6.55} ml={0.5} mr={0.5} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                    <Controller
                                                        name="content1"
                                                        control={control}
                                                        rules={{
                                                        }}
                                                        render={({ field }) => (
                                                            <TextField 
                                                                {...field}
                                                                fullWidth
                                                                type="text"
                                                                size="small"
                                                                error={!!errors?.content1}
                                                                helperText={errors?.content1?.message}
                                                                InputProps={{sx: { height: 32}}}
                                                                style={{padding:'0px',textAlign:'center'}}
                                                                inputProps={{style:{textAlign:'center'}}}
                                                                disabled={modeViewOnly}
                                                                />
                                                        )}
                                                        /> 
                                                </Grid>
                                                <Grid item xs={2.45} >
                                                    <Controller
                                                        name="page1"
                                                        control={control}
                                                        rules={{
                                                        }}
                                                        render={({ field }) => (
                                                            <TextField 
                                                                {...field}
                                                                fullWidth
                                                                type="text"
                                                                size="small"
                                                                error={!!errors?.page1}
                                                                helperText={errors?.page1?.message}
                                                                InputProps={{sx: { height: 32 }}}
                                                                inputProps={{style:{textAlign:'center'}}}
                                                                disabled={modeViewOnly}
                                                            ></TextField>
                                                        )}
                                                        /> 
                                            </Grid>
                                        </Grid>
                                        <Grid mt={0.5} mr={3.6} style={{borderTop:"1px solid black"}}></Grid>
                                        {/* Table------------------------- */}
                                        <Grid>
                                            <Stack style={{ maxHeight: "500px", overflowY: "auto" }}>
                                                {renderItems()}
                                            </Stack>
                                            {/* <TableContainer component={Paper}>
                                                <Table sx={{ width:"100%" }} size="small" aria-label="a dense table">
                                                        <TableBody>
                                                           
                                                            <TableRow>
                                                                    <TableCell component="th" scope="row" width={"21.09%"} style={{paddingLeft:'0px',paddingRight:'0px'}}>
                                                                        <Controller
                                                                            name={'item2'}
                                                                            control={control}
                                                                            rules={{
                                                                            }}
                                                                            render={({ field }) => (
                                                                                <TextField 
                                                                                    {...field}
                                                                                    fullWidth
                                                                                    type="text"
                                                                                    size="small"
                                                                                    error={!!errors?.item2}
                                                                                    helperText={errors?.item2?.message}
                                                                                    InputProps={{sx: { height: 32 }}}
                                                                                ></TextField>
                                                                            )}
                                                                            /> 
                                                                    </TableCell>
                                                                    <TableCell component="th" scope="row" width={"57%"} style={{paddingLeft:'5px',paddingRight:'0px'}}>
                                                                        <Controller
                                                                            name="content2"
                                                                            control={control}
                                                                            rules={{
                                                                            }}
                                                                            render={({ field }) => (
                                                                                <TextField 
                                                                                    {...field}
                                                                                    fullWidth
                                                                                    type="text"
                                                                                    size="small"
                                                                                    error={!!errors?.content2}
                                                                                    helperText={errors?.content2?.message}
                                                                                    InputProps={{sx: { height: 32 }}}
                                                                                ></TextField>
                                                                            )}
                                                                            /> 
                                                                    </TableCell>
                                                                    <TableCell component="th" scope="row" style={{paddingRight:'0px',paddingLeft:'5px'}} width={'100%'}>
                                                                        <Controller
                                                                            name="page2"
                                                                            control={control}
                                                                            rules={{
                                                                            }}
                                                                            render={({ field }) => (
                                                                                <TextField 
                                                                                    {...field}
                                                                                    fullWidth
                                                                                    type="text"
                                                                                    size="small"
                                                                                    error={!!errors?.page2}
                                                                                    helperText={errors?.page2?.message}
                                                                                    InputProps={{sx: { height: 32 }}}
                                                                                ></TextField>
                                                                            )}
                                                                            /> 
                                                                    </TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                </Table>
                                            </TableContainer> */}
                                            <Stack direction='row' gap={2} paddingBottom={1} justifyContent="center" mt={1}>
                                                <Button variant="contained" id="addButton" onClick={handleAddItem} disabled={isDisableButtonAdd ||modeViewOnly}>カーソル位置に行を挿入</Button>
                                                <Button variant="contained"
                                                id="deleteButton"
                                                onClick={handleDeleteItem} 
                                                disabled={(selectedPointItem.index === 0 && array.length === 1) ||modeViewOnly } >カーソル位置の行を削除</Button>
                                            </Stack>
                                        </Grid>
                                    </fieldset>
                                </Grid>
                                
                            </Grid>  
                            </div>
                            <Stack direction='row' justifyContent="flex-end" gap={2} mt={1} paddingBottom={1}>
                                <Button variant="contained" type="submit" disabled={modeViewOnly}>OK</Button>
                                <Button color="inherit" variant="contained" onClick={onCancel}>キャンセル</Button>
                            </Stack>
                        </form>
                        </div>
                </DialogContent>
            </Dialog>
        </>
    )
}
export default SettingCoverDialog;