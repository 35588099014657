import { Button, Dialog, DialogContent, DialogTitle, FormControl, Grid, MenuItem, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import { Control, Controller, useForm } from "react-hook-form";
import theme from "../../themes/globalStyles";
import DraggablePaper from "../common/DraggablePaper";
import { 
    ControlModel, 
    DiagramModel, 
    MSTransCenterModel, 
    MSVTModel, 
    MSZCTModel, 
    Ms2EModel, 
    Ms3WindingModel, 
    MsArrestorModel, 
    MsBusbarModel, 
    MsBusductModel, 
    MsCTModel, 
    MsCableHeadModel, 
    MsCapacitorModel, 
    MsContactorModel, 
    MsDSModel, 
    MsEarthModel, 
    MsFUSEModel, 
    MsHVCBModel, 
    MsINVModel, 
    MsImpedanceModel, 
    MsLBSModel, 
    MsLVCBModel, 
    MsLightBoardModel, 
    MsLoadModel, 
    MsMVCBModel, 
    MsMotorGroupModel, 
    MsMotorModel, 
    MsPowerBoardModel, 
    MsReactorModel, 
    MsRelayModel, 
    MsThermalCTModel, 
    MsThermalModel, 
    MsTrans1Model, 
    MsTrans3Model, 
    MsTransScottModel, 
    MsVCTModel, 
    MsWireModel, 
    MsZerocondModel, 
    OptionModel 
} from "../../models/Index";
import { 
    MS_2E, 
    MS_3WINDING, 
    MS_ARRESTOR, 
    MS_BUSBAR, 
    MS_BUSDUCT, 
    MS_CABLEHEAD, 
    MS_CAPACITOR, 
    MS_CONNECT, 
    MS_CONTACTOR, 
    MS_CT, 
    MS_DS, 
    MS_EARTH, 
    MS_FUSE, 
    MS_GENERATOR, 
    MS_HVCB, 
    MS_IMPEDANCE, 
    MS_INTO_POINT, 
    MS_INV, 
    MS_LBS, 
    MS_LIGHTBOARD, 
    MS_LOAD, 
    MS_LOADCENTER, 
    MS_LVCB, 
    MS_MOTOR, 
    MS_MOTOR_GROUP, 
    MS_MVCB, 
    MS_POWERBOARD, 
    MS_REACTOR, 
    MS_RELAY, 
    MS_RELAY51, 
    MS_SOURCE, 
    MS_THERMAL, 
    MS_THERMAL_CT, 
    MS_TRANS1, 
    MS_TRANS3, 
    MS_TRANSCENTER, 
    MS_TRANSSCOTT, 
    MS_VCT, 
    MS_VT, 
    MS_WIRE, 
    MS_ZCT, 
    MS_ZEROCOND 
} from "../../models/ElementKind";
import { getElementKindLabel, getElementKindValue } from "../../utils/ElementFunction";
import { MS_3PHASES, MS_OUTSIDE_NEUTRAL, MS_OUTSIDE_OUTSIDE, MS_SEAT_MAIN, RS_3PHASES, VOLT_SIDE_PRIMARY, VOLT_SIDE_SECONDARY, VOLT_SIDE_THIRD, elementTypeENtoJP } from "../../statics";
import { IMPEDANCE_INPUT_OHM, IMPEDANCE_INPUT_PERCENT, MS_TRANS1 as MS_TRANS1_CONST, MS_TRANSSCOTT as MS_TRANSSCOTT_CONST } from "../../models/Constants";
import { store } from "../..";
import { DoGetTextVoltage as doGetTextVoltageSource, DoGetTextCapacity as doGetTextCapacitySource } from "../../utils/source"
import { doGetTextVoltage as doGetTextVoltageLBS } from "../../utils/LBSFunction"
import { doGetTextVoltage as doGetTextVoltageDS } from "../../utils/DSFunction"
import { doGetTextVoltage as doGetTextVoltageFUSE } from "../../utils/FUSEFunction"
import { doGetTextVoltage as doGetTextVoltage2E } from "../../utils/2EFunction"
import { doGetTextVoltage as doGetTextVoltageTHERMAL } from "../../utils/THERMALFunction"
import { doGetTextVoltage as doGetTextVoltageThermalCT } from "../../utils/ThermalCTFunction"
import { doGetTextVoltage as doGetTextVoltageLVCB } from "../../utils/LVCBFunction"
import { doGetTextVoltage as doGetTextVoltageZeroCond } from "../../utils/ZeroCondFunction"
import { doGetTextVoltage as doGetTextVoltageVCT } from "../../utils/VCTFunction"
import { doGetTextVoltage as doGetTextVoltageVT } from "../../utils/VTFunction"
import { doGetTextVoltage as doGetTextVoltageZCT } from "../../utils/ZCTFunction"
import { doGetTextVoltage as doGetTextVoltageCT } from "../../utils/CTFunction"
import { doGetTextVoltage as doGetTextVoltageINV } from "../../utils/INVFunction"
import { doGetTextVoltage as doGetTextVoltageRELAY } from "../../utils/RELAYFunction"
import { doGetTextVoltage as doGetTextVoltageArrestor } from "../../utils/ArrestorFunction"
import { doGetTextVoltage as doGetTextVoltageEarth } from "../../utils/EARTHFunction"
import { doGetTextVoltage as doGetTextVoltageCableHead } from "../../utils/CABLEHEADFunction"
import { doGetTextVoltage as doGetTextVoltageLightBoard, doGetTextTotalCapacity as doGetTextTotalCapacityLightBoard } from "../../utils/lightBoard"
import { doGetTextVoltage as doGetTextVoltagePowerBoard, doGetTextTotalCapacity as doGetTextTotalCapacityPowerBoard } from "../../utils/powerBoard"
import { doGetTextVoltage as doGetTextVoltageLoad, doGetTextLoadCurrent as doGetTextLoadCurrentLoad } from "../../utils/LoadFunction"
import { doGetTextVoltage as doGetTextVoltageMotor, doGetTextKiloWattCapacity as doGetTextKiloWattCapacityMotor, doGetTextHorsePowerCapacity as doGetTextHorsePowerCapacityMotor } from "../../utils/motorFunction"
import { doGetTextVoltage as doGetTextVoltageImpedance, doGetTextPercentZe as doGetTextPercentZeImpedance, doGetTextPercentRe as doGetTextPercentReImpedance, doGetTextPercentXe as doGetTextPercentXeImpedance, doGetTextOhmZ as doGetTextOhmZImpedance, doGetTextOhmR as doGetTextOhmRImpedance, doGetTextOhmX as doGetTextOhmXImpedance } from "../../utils/impedance"
import { doGetTextVoltage as doGetTextVoltageWire, doGetTextLength as doGetTextLengthWire, doGetTextSize as doGetTextSizeWire } from "../../utils/wireFunction"
import { doGetTextVoltage as doGetTextVoltageBusbar, doGetTextLength as doGetTextLengthBusbar, doGetTextSize as doGetTextSizeBusbar } from "../../utils/busbarFunction"
import { doGetTextVoltage as doGetTextVoltageBusduct, doGetTextLength as doGetTextLengthBusduct, doGetTextSize as doGetTextSizeBusduct } from "../../utils/busductFunction"
import { doGetTextVoltage as doGetTextVoltageContactor } from "../../utils/ContactorFunction"
import { doGetTextVoltage as doGetTextVoltage3Winding, doGetTextCapacity as doGetTextCapacity3Winding } from "../../utils/3windingFunction"
import { doGetTextVoltage as doGetTextVoltageGenerator, doGetTextCapacity as doGetTextCapacityGenerator } from "../../utils/generatorFunction"
import { doGetTextVoltage as doGetTextVoltageReactor, doGetTextCapacity as doGetTextCapacityReactor } from "../../utils/reactorFunction"
import { doGetTextVoltage as doGetTextVoltageMotorGrp, doGetTextCapacity as doGetTextCapacityMotorGrp } from "../../utils/MotorGrpFunction"
import { doGetTextVoltage as doGetTextVoltageCapacitor, doGetTextCapacity as doGetTextCapacityCapacitor } from "../../utils/capacitorFunction"
import { doGetTextVoltage as doGetTextVoltageMVCB, doGetTextBreakCapacity as doGetTextBreakCapacityMVCB } from "../../utils/MVCBFunction"
import { doGetTextVoltage as doGetTextVoltageHVCB, doGetTextBreakCapacity as doGetTextBreakCapacityHVCB } from "../../utils/HVCBFunction"
import { doGetTextVoltPrimary as doGetTextVoltPrimaryTrans3, doGetTextVoltSecondary as doGetTextVoltSecondaryTrans3, doGetTextCapacity as doGetTextCapacityTrans3 } from "../../utils/Trans3Function"
import { doGetTextVoltPrimary as doGetTextVoltPrimaryTrans1, doGetTextVoltSecondary as doGetTextVoltSecondaryTrans1, doGetTextCapacity as doGetTextCapacityTrans1 } from "../../utils/Trans1Function"
import { doGetTextVoltPrimary as doGetTextVoltPrimaryTransscott, doGetTextVoltSecondary as doGetTextVoltSecondaryTransscott, doGetTextCapacity as doGetTextCapacityTransscott } from "../../utils/transscottFunction"
import { doGetTextVoltage as doGetTextVoltageTransCenter, doGetTextTotalRatedCapacity as doGetTextTotalRatedCapacityTransCenter, doGetTextFullCurrent as doGetTextFullCurrentTransCenter} from "../../utils/TransCenterFunction";
import { useEffect, useMemo, useRef, useState } from "react";

import { AG_GRID_LOCALE_JP } from '../../themes/localeJP';
import NoRowsOverlay, { NoRowsMessage } from "../common/NoRowsOverlay";
import { AgGridReact } from "ag-grid-react";
import { useDispatch, useSelector } from "react-redux";
import { openDialogEvents, saveCurrentIdDiagramTab, updateSelectControl } from "../../store/Actions";

//CDlgDeviceList::DoGetTextVoltAndRated
export const doGetTextVoltAndRated = (control:ControlModel, diagramData: ControlModel[]) =>
{
    let result = {
        sVoltage: '',
        sRated: ''
    }
    let props;

	switch (control.type) {
	case MS_SOURCE:			
		{
			result.sVoltage = doGetTextVoltageSource(control, false);

			result.sRated = doGetTextCapacitySource(control);
		}
		break;
	case MS_GENERATOR:		
		{
			result.sVoltage = doGetTextVoltageGenerator(control, false);

			result.sRated = doGetTextCapacityGenerator(control);
		}
		break;
	case MS_TRANS3:			
		{
            props = control.properties as MsTrans3Model;
			result.sVoltage = doGetTextVoltPrimaryTrans3(props, false);
			result.sVoltage += '/';
			result.sVoltage += doGetTextVoltSecondaryTrans3(props, false);

			result.sRated = doGetTextCapacityTrans3(props);
		}
		break;
        
	case MS_TRANSCENTER:	
		{
            props = control.properties as MSTransCenterModel;
			if (props.sPhase) {
				switch (props.upTransKind) {
				case MS_TRANS1_CONST:
					result.sVoltage = doGetTextVoltageTransCenter(props, MS_OUTSIDE_OUTSIDE, false);
					break;
				case MS_TRANSSCOTT_CONST:
					result.sVoltage = doGetTextVoltageTransCenter(props, MS_SEAT_MAIN, false);
					break;
				}
			}
			else {
				result.sVoltage = doGetTextVoltageTransCenter(props, MS_3PHASES, false);
			}

			result.sRated = doGetTextTotalRatedCapacityTransCenter(props, diagramData);
			result.sRated += ', ';
			result.sRated += doGetTextFullCurrentTransCenter(props, diagramData);
		}
		break;
	case MS_3WINDING:	
		{
            props = control.properties as Ms3WindingModel;
			result.sVoltage = doGetTextVoltage3Winding(props, VOLT_SIDE_PRIMARY, false);
			result.sVoltage += '/';
			result.sVoltage += doGetTextVoltage3Winding(props, VOLT_SIDE_SECONDARY, false);
			result.sVoltage += '/';
			result.sVoltage += doGetTextVoltage3Winding(props, VOLT_SIDE_THIRD, false);

			result.sRated = doGetTextCapacity3Winding(props, VOLT_SIDE_PRIMARY);
			result.sRated += '/';
			result.sRated += doGetTextCapacity3Winding(props, VOLT_SIDE_SECONDARY);
			result.sRated += '/';
			result.sRated += doGetTextCapacity3Winding(props, VOLT_SIDE_THIRD);
		}
		break;
	case MS_TRANS1:			
		{
            props = control.properties as MsTrans1Model;
			result.sVoltage = doGetTextVoltPrimaryTrans1(props, false);
			result.sVoltage += '/';
			result.sVoltage += doGetTextVoltSecondaryTrans1(props, MS_OUTSIDE_OUTSIDE, false);
			result.sVoltage += '-';
			result.sVoltage += doGetTextVoltSecondaryTrans1(props, MS_OUTSIDE_NEUTRAL, false);

			result.sRated = doGetTextCapacityTrans1(props, MS_OUTSIDE_OUTSIDE);
		}
		break;
	case MS_TRANSSCOTT:		
		{
            props = control.properties as MsTransScottModel;
			result.sVoltage = doGetTextVoltPrimaryTransscott(props, false);
			result.sVoltage += '/';
			result.sVoltage += doGetTextVoltSecondaryTransscott(props, false);

			result.sRated = doGetTextCapacityTransscott(props);
		}
		break;
	case MS_DS:			
		{
            props = control.properties as MsDSModel;
			result.sVoltage = doGetTextVoltageDS(props, false);
		}
		break;
	case MS_LBS:			
		{
            props = control.properties as MsLBSModel;
            result.sVoltage = doGetTextVoltageLBS(props, false);
		}
		break;
	case MS_MVCB:			
		{
            props = control.properties as MsMVCBModel;
            if (props.sPhase) {
				switch (props.upTransKind) {
				case MS_TRANS1_CONST:
					result.sVoltage = doGetTextVoltageMVCB(props, MS_OUTSIDE_OUTSIDE, false);
					break;
				case MS_TRANSSCOTT_CONST:
					result.sVoltage = doGetTextVoltageMVCB(props, MS_SEAT_MAIN, false);
					break;
				}
			}
			else {
				result.sVoltage = doGetTextVoltageMVCB(props, MS_3PHASES, false);
			}

			result.sRated = doGetTextBreakCapacityMVCB(props);
		}
		break;
	case MS_CONTACTOR:	
		{
            props = control.properties as MsContactorModel;
			if (props.sPhase) {
				switch (props.upTransKind) {
				case MS_TRANS1_CONST:
					result.sVoltage = doGetTextVoltageContactor(props, MS_OUTSIDE_OUTSIDE, false);
					break;
				case MS_TRANSSCOTT_CONST:
					result.sVoltage = doGetTextVoltageContactor(props, MS_SEAT_MAIN, false);
					break;
				}
			}
			else {
				result.sVoltage = doGetTextVoltageContactor(props, MS_3PHASES, false);
			}
		}
		break;
	case MS_HVCB:			
		{
            props = control.properties as MsHVCBModel;
			result.sVoltage = doGetTextVoltageHVCB(props, false);

			result.sRated = doGetTextBreakCapacityHVCB(props);
		}
		break;
    //TODO
	// case MS_RELAY51:		
	// 	{
	// 		CEleRelay51* pRelay51 = (CEleRelay51*)pElement->DoGetPointerEle();
	// 		result.sVoltage = pRelay51->DoGetTextVoltage(false);
	// 	}
	// 	break;
	case MS_FUSE:			
		{
            props = control.properties as MsFUSEModel;
			result.sVoltage = doGetTextVoltageFUSE(props, false);
		}
		break;
	case MS_2E:				
		{
            props = control.properties as Ms2EModel;
			if (props.sPhase) {
				switch (props.upTransKind) {
				case MS_TRANS1_CONST:
					result.sVoltage = doGetTextVoltage2E(props, MS_OUTSIDE_OUTSIDE, false);
					break;
				case MS_TRANSSCOTT_CONST:
					result.sVoltage = doGetTextVoltage2E(props, MS_SEAT_MAIN, false);
					break;
				}
			}
			else {
				result.sVoltage = doGetTextVoltage2E(props, MS_3PHASES, false);
			}
		}
		break;
	case MS_ZEROCOND:		
		{
            props = control.properties as MsZerocondModel;
			if (props.sPhase) {
				switch (props.upTransKind) {
				case MS_TRANS1_CONST:
					result.sVoltage = doGetTextVoltageZeroCond(props, MS_OUTSIDE_OUTSIDE, false);
					break;
				case MS_TRANSSCOTT_CONST:
					result.sVoltage = doGetTextVoltageZeroCond(props, MS_SEAT_MAIN, false);
					break;
				}
			}
			else {
				result.sVoltage = doGetTextVoltageZeroCond(props, MS_3PHASES, false);
			}
		}
		break;
	case MS_CONNECT:		
		break;
	case MS_WIRE:			
		{
            props = control.properties as MsWireModel;
			if (props.sPhase) {
				switch (props.upTransKind) {
				case MS_TRANS1_CONST:
					result.sVoltage = doGetTextVoltageWire(props, MS_OUTSIDE_OUTSIDE, false);
					break;
				case MS_TRANSSCOTT_CONST:
					result.sVoltage = doGetTextVoltageWire(props, MS_SEAT_MAIN, false);
					break;
				}
			}
			else {
				result.sVoltage = doGetTextVoltageWire(props, MS_3PHASES, false);
			}

			result.sRated = doGetTextLengthWire(props);
			result.sRated += ', ';
			result.sRated += doGetTextSizeWire(props, RS_3PHASES);
		}
		break;
	case MS_BUSBAR:			
		{
            props = control.properties as MsBusbarModel;
			if (props.sPhase) {
				switch (props.upTransKind) {
				case MS_TRANS1_CONST:
					result.sVoltage = doGetTextVoltageBusbar(props,  MS_OUTSIDE_OUTSIDE, false);
					break;
				case MS_TRANSSCOTT_CONST:
					result.sVoltage = doGetTextVoltageBusbar(props, MS_SEAT_MAIN, false);
					break;
				}
			}
			else {
				result.sVoltage = doGetTextVoltageBusbar(props, MS_3PHASES, false);
			}

			result.sRated = doGetTextLengthBusbar(props);
			result.sRated += ', ';
			result.sRated += doGetTextSizeBusbar(props, RS_3PHASES);
		}
		break;
	case MS_IMPEDANCE:		
		{
            props = control.properties as MsImpedanceModel;
			if (props.sPhase) {
				switch (props.upTransKind) {
				case MS_TRANS1_CONST:
					result.sVoltage = doGetTextVoltageImpedance(props, MS_OUTSIDE_OUTSIDE, false);
					break;
				case MS_TRANSSCOTT_CONST:
					result.sVoltage = doGetTextVoltageImpedance(props, MS_SEAT_MAIN, false);
					break;
				}
			}
			else {
				result.sVoltage = doGetTextVoltageImpedance(props, MS_3PHASES, false);
			}

			if (props.inputPercentZ == IMPEDANCE_INPUT_PERCENT) {
				result.sRated = doGetTextPercentZeImpedance(props, props.stdCapacity);
				result.sRated += ' = ';
				result.sRated += doGetTextPercentReImpedance(props, props.stdCapacity, false);
				result.sRated += ' + ';
				result.sRated += doGetTextPercentXeImpedance(props, props.stdCapacity, false);
			}
			else if (props.inputPercentZ == IMPEDANCE_INPUT_OHM) {
				result.sRated = doGetTextOhmZImpedance(props);
				result.sRated += ' = ';
				result.sRated += doGetTextOhmRImpedance(props, false);
				result.sRated += ' + ';
				result.sRated += doGetTextOhmXImpedance(props, false);
			}
		}
		break;
	case MS_BUSDUCT:		
		{
            props = control.properties as MsBusductModel;
			if (props.sPhase) {
				switch (props.upTransKind) {
				case MS_TRANS1_CONST:
					result.sVoltage = doGetTextVoltageBusduct(props, MS_OUTSIDE_OUTSIDE, false);
					break;
				case MS_TRANSSCOTT_CONST:
					result.sVoltage = doGetTextVoltageBusduct(props, MS_SEAT_MAIN, false);
					break;
				}
			}
			else {
				result.sVoltage = doGetTextVoltageBusduct(props, MS_3PHASES, false);
			}

			result.sRated = doGetTextLengthBusduct(props);
			result.sRated += ', ';
			result.sRated += doGetTextSizeBusduct(props, RS_3PHASES);
		}
		break;
	case MS_REACTOR:		
		{
            props = control.properties as MsReactorModel;
			result.sVoltage = doGetTextVoltageReactor(props, false);
	
			result.sRated += doGetTextCapacityReactor(props);
		}
		break;
	case MS_LVCB:			
		{
            props = control.properties as MsLVCBModel;
			if (props.sPhase) {
				switch (props.upTransKind) {
				case MS_TRANS1_CONST:
					result.sVoltage = doGetTextVoltageLVCB(props ,MS_OUTSIDE_OUTSIDE, false);
					break;
				case MS_TRANSSCOTT_CONST:
					result.sVoltage = doGetTextVoltageLVCB(props, MS_SEAT_MAIN, false);
					break;
				}
			}
			else {
				result.sVoltage = doGetTextVoltageLVCB(props, MS_3PHASES, false);
			}
		}
		break;
	case MS_THERMAL:		
		{
            props = control.properties as MsThermalModel;
			if (props.sPhase) {
				switch (props.upTransKind) {
				case MS_TRANS1_CONST:
					result.sVoltage = doGetTextVoltageTHERMAL(props, MS_OUTSIDE_OUTSIDE, false);
					break;
				case MS_TRANSSCOTT_CONST:
					result.sVoltage = doGetTextVoltageTHERMAL(props, MS_SEAT_MAIN, false);
					break;
				}
			}
			else {
				result.sVoltage = doGetTextVoltageTHERMAL(props, MS_3PHASES, false);
			}
		}
		break;
	case MS_THERMAL_CT:		
		{
            props = control.properties as MsThermalCTModel;
			if (props.sPhase) {
				switch (props.upTransKind) {
				case MS_TRANS1_CONST:
					result.sVoltage = doGetTextVoltageThermalCT(props, MS_OUTSIDE_OUTSIDE, false);
					break;
				case MS_TRANSSCOTT_CONST:
					result.sVoltage = doGetTextVoltageThermalCT(props, MS_SEAT_MAIN, false);
					break;
				}
			}
			else {
				result.sVoltage = doGetTextVoltageThermalCT(props, MS_3PHASES, false);
			}
		}
		break;
	case MS_MOTOR_GROUP:	
		{
            props = control.properties as MsMotorGroupModel;
			result.sVoltage = doGetTextVoltageMotorGrp(props, false);

			result.sRated = doGetTextCapacityMotorGrp(props);
		}
		break;
	case MS_MOTOR:			
		{
            props = control.properties as MsMotorModel;
			if (props.sPhase) {
				switch (props.upTransKind) {
				case MS_TRANS1_CONST:
					result.sVoltage = doGetTextVoltageMotor(props, MS_OUTSIDE_OUTSIDE, false);
					break;
				case MS_TRANSSCOTT_CONST:
					result.sVoltage = doGetTextVoltageMotor(props, MS_SEAT_MAIN, false);
					break;
				}
			}
			else {
				result.sVoltage = doGetTextVoltageMotor(props, MS_3PHASES, false);
			}

			if (!props.uIntHP) {
				result.sRated = doGetTextKiloWattCapacityMotor(props);
			}
			else {
				result.sRated = doGetTextHorsePowerCapacityMotor(props);
			}
		}
		break;
	case MS_LOAD:			
		{
            props = control.properties as MsLoadModel;
            if (props.sPhase) {
				switch (props.upTransKind) {
				case MS_TRANS1_CONST:
					result.sVoltage = doGetTextVoltageLoad(props, MS_OUTSIDE_OUTSIDE, false);
					break;
				case MS_TRANSSCOTT_CONST:
					result.sVoltage = doGetTextVoltageLoad(props, MS_SEAT_MAIN, false);
					break;
				}
			}
			else {
				result.sVoltage = doGetTextVoltageLoad(props, MS_3PHASES, false);
			}

			result.sRated = doGetTextLoadCurrentLoad(props);
		}
		break;
	case MS_CAPACITOR:		
		{
            props = control.properties as MsCapacitorModel;
			result.sVoltage = doGetTextVoltageCapacitor(props, false);

			result.sRated = doGetTextCapacityCapacitor(props);
		}
		break;
    //TODO
	// case MS_LOADCENTER:		
	// 	{
	// 		CEleLoadCenter* pLoadCenter = (CEleLoadCenter*)pElement->DoGetPointerEle();
	// 		if (pLoadCenter->m_bSPhase == TRUE) {
	// 			switch (pLoadCenter->m_nUpTransKind) {
	// 			case MS_TRANS1:
	// 				result.sVoltage = pLoadCenter->DoGetTextVoltage(MS_OUTSIDE_OUTSIDE, false);
	// 				break;
	// 			case MS_TRANSSCOTT:
	// 				result.sVoltage = pLoadCenter->DoGetTextVoltage(MS_SEAT_MAIN, false);
	// 				break;
	// 			}
	// 		}
	// 		else {
	// 			result.sVoltage = pLoadCenter->DoGetTextVoltage(MS_3PHASES, false);
	// 		}

	// 		result.sRated = pLoadCenter->DoGetTextLoadCurrent();
	// 	}
	// 	break;
	case MS_VCT:			
		{
            props = control.properties as MsVCTModel;
			result.sVoltage = doGetTextVoltageVCT(props, false);
		}
		break;
	case MS_INV:			
		{
            props = control.properties as MsINVModel;
			result.sVoltage = doGetTextVoltageINV(props, false);
		}
		break;
	case MS_RELAY:			
		{
            props = control.properties as MsRelayModel;
			result.sVoltage = doGetTextVoltageRELAY(props, false);
		}
		break;
	case MS_ARRESTOR:		
		{
            props = control.properties as MsArrestorModel;
			result.sVoltage = doGetTextVoltageArrestor(props, false);
		}
		break;
	case MS_EARTH:			
		{
            props = control.properties as MsEarthModel;
			result.sVoltage = doGetTextVoltageEarth(props, false);
		}
		break;
	case MS_VT:				
		{
            props = control.properties as MSVTModel;
			result.sVoltage = doGetTextVoltageVT(props, false);
		}
		break;
	case MS_CABLEHEAD:		
		{
            props = control.properties as MsCableHeadModel;
			if (props.sPhase) {
				switch (props.upTransKind) {
				case MS_TRANS1_CONST:
					result.sVoltage = doGetTextVoltageCableHead(props, MS_OUTSIDE_OUTSIDE, false);
					break;
				case MS_TRANSSCOTT_CONST:
					result.sVoltage = doGetTextVoltageCableHead(props, MS_SEAT_MAIN, false);
					break;
				}
			}
			else {
				result.sVoltage = doGetTextVoltageCableHead(props, MS_3PHASES, false);
			}
		}
		break;
	case MS_CT:				
		{
            props = control.properties as MsCTModel;
			if (props.sPhase) {
				switch (props.upTransKind) {
				case MS_TRANS1_CONST:
					result.sVoltage = doGetTextVoltageCT(props, MS_OUTSIDE_OUTSIDE, false);
					break;
				case MS_TRANSSCOTT_CONST:
					result.sVoltage = doGetTextVoltageCT(props, MS_SEAT_MAIN, false);
					break;
				}
			}
			else {
				result.sVoltage = doGetTextVoltageCT(props, MS_3PHASES, false);
			}
		}
		break;
	case MS_ZCT:			
		{
            props = control.properties as MSZCTModel;
			result.sVoltage = doGetTextVoltageZCT(props, false);
		}
		break;
	case MS_LIGHTBOARD:		
		{
            props = control.properties as MsLightBoardModel;
			if (props.sPhase) {
				switch (props.upTransKind) {
				case MS_TRANS1_CONST:
					result.sVoltage = doGetTextVoltageLightBoard(props, MS_OUTSIDE_OUTSIDE, false);
					break;
				case MS_TRANSSCOTT_CONST:
					result.sVoltage = doGetTextVoltageLightBoard(props, MS_SEAT_MAIN, false);
					break;
				}
			}
			else {
				result.sVoltage = doGetTextVoltageLightBoard(props, MS_3PHASES, false);
			}

			result.sRated = doGetTextTotalCapacityLightBoard(props);
		}
		break;
	case MS_POWERBOARD:		
		{
            props = control.properties as MsPowerBoardModel;
			if (props.sPhase) {
				switch (props.upTransKind) {
				case MS_TRANS1_CONST:
					result.sVoltage = doGetTextVoltagePowerBoard(props, MS_OUTSIDE_OUTSIDE, false);
					break;
				case MS_TRANSSCOTT_CONST:
					result.sVoltage = doGetTextVoltagePowerBoard(props, MS_SEAT_MAIN, false);
					break;
				}
			}
			else {
				result.sVoltage = doGetTextVoltagePowerBoard(props, MS_3PHASES, false);
			}

			result.sRated = doGetTextTotalCapacityPowerBoard(props);
		}
		break;
	}
    
    return result;
}

export const handleInitElementListDialog = (elementType:number, transCenter:number) => {
    let allControl: ControlModel[] = []
    store.getState().app.diagram.diagramData.forEach((e: DiagramModel) => allControl = allControl.concat(e.shape))
    if (allControl.length > 0){
        let sTemp = {
            sVoltage : '',
            sRated : ''
        };
        // element kind
        const dictEleKind: any = elementTypeENtoJP
        let listElementType = allControl.map(e => e.type)
        let uniqElementType = Array.from(new Set(listElementType));
        let newListElementType: OptionModel[] = []
        uniqElementType.map((e) => e !== MS_INTO_POINT && e !== MS_CONNECT && newListElementType.push({
            label: dictEleKind[e] || e,
            value: getElementKindValue(e).toString() || ''
        }))
        //element trans center
        let tmpListTransCenter = allControl.filter(e => e.type === MS_TRANSCENTER)
        let newListTransCenter: OptionModel[] = []
        tmpListTransCenter.map(e => newListTransCenter.push({
            label: e.properties?.refNo,
            value: e.properties?.noTransCenter.toString()
        }))

        //element in trans center
        let  newListEleTransCenter: any = [];
        if(transCenter !== 0) {
            let tmpListEleTransCenter = [...allControl.filter((e: any) => e.type === MS_TRANSCENTER)];  
			let targetTranscenter = [...tmpListEleTransCenter.filter(x => x.properties.noTransCenter === transCenter)];
            let listTCAllElement = [...(store.getState().app.diagram.diagramData.filter((e: any) => e.tabId === targetTranscenter[0].properties.tabId)[0]).shape];
            if(elementType !== 0 ) {
                listTCAllElement = [...listTCAllElement.filter(e => e.type === getElementKindLabel(elementType))];
            }

            listTCAllElement.map(e => {
                sTemp = doGetTextVoltAndRated(e, allControl);
                e.type !== MS_INTO_POINT && e.type !== MS_CONNECT && newListEleTransCenter.push({
					id: e.id,
					refNo: e.properties?.refNo,
					voltage: sTemp?.sVoltage.toString(),
					rated: sTemp?.sRated    
                })
            })
        }

        // element by type
        let listElement= allControl;
        if(elementType !== 0 ) {
            listElement = [...allControl.filter(e => e.type === getElementKindLabel(elementType))];
        }
        let newListElement: any = []
        listElement.map((e) => {
        sTemp = doGetTextVoltAndRated(e, allControl);
        e.type !== MS_INTO_POINT && e.type !== MS_CONNECT && newListElement.push({
			id: e.id,
			refNo: e.properties?.refNo,
			voltage: sTemp?.sVoltage.toString(),
			rated: sTemp?.sRated
        })
        })

        return {
            listElementType : newListElementType,
            listTransCenter : newListTransCenter,
            listEleTransCenter : newListEleTransCenter,
            listElement : newListElement
        }
    }
}

export type ShowElementpmentListProps = {
    title?: string;
	elementGroups:any;
	tcGroups:any;
	transCenterSelected:any;
    onCancel: () => void;
	onSelectGroup: (data: any) => void;
    setLoadingFlag: (flag: boolean) => void;
	scrollToControl: (id: any) => void;
};

export const ShowElementListDialog = (props: ShowElementpmentListProps) => {
    const { title, elementGroups, tcGroups, transCenterSelected, onCancel, onSelectGroup, setLoadingFlag, scrollToControl} = props;

    const { formState: { errors }, setValue, getValues } = useForm({
        mode: "all",
        criteriaMode: "all",
    })

    const [showList, setShowList] = useState(handleInitElementListDialog(0, 0));
	const [disableTranscenterCB, setDisableTranscenterCB] = useState(false)
	const [transcenterCB, setTranscenterCB] = useState(0)
	const dispatch = useDispatch();
	const reloadTable = useSelector((state:any) => state.app.diagram.openDialog["RELOAD"]);
	const gridRef = useRef() as any;
	const noRowsOverlayComponentParams = useMemo(() => {
		return {
			noRowsMessageFunc: () => NoRowsMessage,
		};
	}, []);

	useEffect(() => {
		if(reloadTable) {
		  DoGetDetailsData();
		}
	  }, [reloadTable]);

	const noRowsOverlayComponent = useMemo(() => {
		return NoRowsOverlay;
	}, []);

	// 全列に共通の定義
	const defaultColDef = {
		sortable: true, // ソート可
		editable: false, // 編集不可
		resizable: true,
		suppressMovable: true,  // 列の移動を無効にする
	};

	 // 列タイプの定義
	 const columnTypes = {
		nonEditableColumn: { editable: false }, // 編集不可
	};

	 // 列の個別定義
	 const columnDefs = [
        {
            headerName: "デバイス名",
            field: "refNo",
            rowDrag: false,
            flex: 2
        },
        {
            headerName: "回路電圧(V)",
            field: "voltage",
            rowDrag: false,
           flex: 2.5
        },
        {
            headerName: "定格",
            field: "rated",
            rowDrag: false,
            flex: 3,
        }
    ];

    const handleElementTypeChange = (e:any) => {
		let type = Number(e.target.value)
		if(getElementKindLabel(type) === MS_GENERATOR || getElementKindLabel(type) === MS_ARRESTOR || getElementKindLabel(type) === MS_SOURCE || getElementKindLabel(type) === MS_EARTH || getElementKindLabel(type) === MS_VCT || getElementKindLabel(type) === MS_TRANSCENTER || getElementKindLabel(type) === MS_MOTOR_GROUP)
		{
			setDisableTranscenterCB(true)
			setValue('transcenter', 0);
			setTranscenterCB(0)
		}
		else
		{
			setDisableTranscenterCB(false)
		}
        setValue('type', type);
        DoGetDetailsData();
    };

    const handleTransCenterChange = (e:any) => {
        setValue('transcenter', e.target.value);
		setTranscenterCB(e.target.value)
        DoGetDetailsData();
    };

    const DoGetDetailsData = () => {
        let type = getValues('type') === undefined? 0 : getValues('type');
        let transcenter = getValues('transcenter') === undefined? 0 : getValues('transcenter');
      
        setLoadingFlag(true)

        setShowList(handleInitElementListDialog(Number(type), Number(transcenter)));

        setLoadingFlag(false)
    }

	const handleSelectRow = (e:any) => {
		let groupsData = store.getState().app.diagram.currentIDDiagramTab === 1 ? elementGroups : tcGroups[transCenterSelected?.id ?? 1]
		if(store.getState().app.diagram.currentIDDiagramTab === 1) {
			let checkTabTranscenter = store.getState().app.diagram.diagramData.find(x => x.shape.find((shapes:any) => shapes.id === e.id))
			if(checkTabTranscenter && checkTabTranscenter?.tabId !== 1) {
				let transcenter = store.getState().app.diagram.diagramData.find(x => x.tabId === 1)?.shape.find((shapes:ControlModel) => shapes.type === MS_TRANSCENTER && shapes.properties.tabId === checkTabTranscenter?.tabId)
				if (transcenter) {
					const peekGroupId = groupsData?.byEleId[e.id as any]?.at(-1) ?? -1
					if(peekGroupId != -1)
					{
						onSelectGroup(transcenter.id)
					}
					else
					{
						dispatch(updateSelectControl(transcenter.id))
						onSelectGroup(-1)
					}
					scrollToControl(transcenter.id)
				}
			}
			else
			{
				const peekGroupId = groupsData?.byEleId[e.id as any]?.at(-1) ?? -1
				if(peekGroupId != -1)
				{
					onSelectGroup(e.id)
				}
				else
				{
					dispatch(updateSelectControl(e.id))
					onSelectGroup(-1)
				}
				scrollToControl(e.id)
			}
		}
		else
		{
			const peekGroupId = groupsData?.byEleId[e.id as any]?.at(-1) ?? -1
			if(peekGroupId != -1)
			{
				onSelectGroup(e.id)
			}
			else
			{
				dispatch(updateSelectControl(e.id))
				onSelectGroup(-1)
			}
			scrollToControl(e.id)
		}
	}

	const handleSelectRowForEdit = (e:any) => {
		let checkTabTranscenter = store.getState().app.diagram.diagramData.find(x => x.shape.find((shapes:any) => shapes.id === e.id))
		if(checkTabTranscenter && checkTabTranscenter?.tabId !== store.getState().app.diagram.currentIDDiagramTab) {
			dispatch(saveCurrentIdDiagramTab(checkTabTranscenter?.tabId))
		}
		dispatch(updateSelectControl(e.id))
		dispatch(openDialogEvents({type: "OPEN_DIALOG", data: { id: (e.id) }}))
	}

    return (
        <>
            <Dialog open={true} maxWidth={"sm"} fullWidth
			  	hideBackdrop 
                PaperComponent={
                    DraggablePaper
                }
            >
                <DialogTitle 
                    onMouseOver={(e : any) => e.target.style.cursor = 'move'}
                    style={{ paddingTop: '2px', paddingBottom: '2px', background: theme.palette.primary.main, color: theme.palette.primary.contrastText }}
                >
                    {title || `機器リスト`}
                </DialogTitle>
                <DialogContent >
                    <div style={{width: '100%', height: '500px'}}>
                        <Grid container  mt={1} columnSpacing={2}>
                            <Grid item xs={6} mt={1} >
                                <FormControl fullWidth size="small">
                                    <Select
                                        defaultValue={0}
                                        onChange={handleElementTypeChange}
                                    >
                                        <MenuItem
                                        key={'すべて'}
                                        value={0}
                                        >
                                        {'すべて'}
                                        </MenuItem>
                                        {
                                        showList?.listElementType.map((e) => (
                                        <MenuItem
                                            key={e.label}
                                            value={e.value}
                                        
                                        >
                                            {e.label}
                                        </MenuItem>
                                        ))
                                    }
                                    </Select>
                                </FormControl>
                                    
                            </Grid>
                            <Grid item xs={6} mt={1} >
                                <FormControl fullWidth size="small">
                                    <Select
                                        defaultValue={0}
                                        onChange={handleTransCenterChange}
										disabled={disableTranscenterCB}
										value={transcenterCB}
                                    >
                                        <MenuItem
                                            key={'すべて'}
                                            value={0}
                                            >
                                            {'すべて'}
                                        </MenuItem>
                                        {
                                            showList?.listTransCenter.map((e) => (
                                                <MenuItem
                                                  key={e.label}
                                                  value={e.value}
                                                
                                                >
                                                  {e.label}
                                                </MenuItem>
                                              ))
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

						<div className="ag-theme-alpine" style={{ height: "370px", width: "100%", marginTop: "15px" }}>
							<AgGridReact
							  ref={gridRef}
							  defaultColDef={defaultColDef}
							  columnTypes={columnTypes}
							  columnDefs={columnDefs}
							  domLayout={"normal"}
							  rowData={getValues('transcenter') === undefined || getValues('transcenter') === 0? showList?.listElement : showList?.listEleTransCenter}
							  animateRows={true}
							  rowDragManaged={true}
							  rowSelection={'single'}
							  suppressMovableColumns={false}
							  suppressDragLeaveHidesColumns={false}
							  localeText={AG_GRID_LOCALE_JP}
							  noRowsOverlayComponent={noRowsOverlayComponent}
							  noRowsOverlayComponentParams={noRowsOverlayComponentParams}
							  onRowClicked={() => handleSelectRow(gridRef.current.api.getSelectedRows()[0])}
							  onRowDoubleClicked={() => handleSelectRowForEdit(gridRef.current.api.getSelectedRows()[0])}
							/>
                        </div>
                            
                        <Stack direction='row' justifyContent="flex-end" gap={2} sx={{ mt: 3 }}>
							<Button onClick={onCancel} color="inherit" variant="contained" >閉じる</Button>
                        </Stack>
                    </div>                    
                </DialogContent>
            </Dialog>
        </>
    )
}
export default ShowElementListDialog;