import { AdjustModel, ControlModel } from "../models/Index";
import { BeamsNumber } from "./FormatNumber";
import { doGetTextCurrent, doGetTextMagniCurrent, doGetTextMagniTime, doGetTextTime, isOkSetCurveInfo } from "./infoRead";
import { IDS_SIGN_MULTIPLE, IDS_STS_LOCK, RS_DIAL_CONTINUE, RS_DIAL_FIXED, RS_DIAL_NONE, RS_KIND_FUSE, RS_KIND_HVCB } from "../models/Constants";
import { doConvertNominal } from "./Graph";


//CObjAdjust::DoGetTextCT1Current
export const doGetTextCT1Current = (props:AdjustModel, bUnit:boolean = true) =>
{
	let sText = BeamsNumber(props.base?.ctPrimary, 0).toString();
	if (bUnit) {
		sText += 'A';
	}
	return sText;
}

//CObjAdjust::DoGetTextCT2Current
export const doGetTextCT2Current = (props:AdjustModel, bUnit:boolean = true) =>
{
	let sText = props.base?.ctSecondary.toString();
	if (bUnit) {
		sText += 'A';
	}
	return sText;
}

//TODO
//CObjAdjust::DoGetTextCurrentName
export const doGetTextCurrentName = (props:AdjustModel, nIndex:number, bUnit:boolean = true) =>
{
	// POSITION posInfo = m_listInfoPart.FindIndex(nIndex);
	// POSITION posRead = m_listReadPart.FindIndex(nIndex);
	// if (posRead == NULL) {
	// 	DoReadDataFromDB(FALSE);		// ����l���̋Ȑ������f�[�^�x�[�X������W����
	// 	posRead = m_listReadPart.FindIndex(nIndex);
	// }
	// if (posInfo == NULL) {
	// 	return _T("-");
	// }

	// CInfoPart* pInfoPart = m_listInfoPart.GetAt(posInfo);
	// ASSERT_VALID(pInfoPart); 
	// CReadPart* pReadPart = m_listReadPart.GetAt(posRead);
	// ASSERT_VALID(pReadPart);
	// if (pInfoPart == NULL || pReadPart == NULL) {
	// 	return _T("-");
	// }

	// CString sText = pReadPart->DoGetTextCurrent(pInfoPart->m_dCurrentValue, bUnit, FALSE);
	// if (sText.IsEmpty() != 0) {
	// 	sText = pReadPart->m_DialCurrent.m_sNotes + pReadPart->m_DialCurrent.m_sUnit;
	// }
	// if (sText.IsEmpty() != 0) {
	// 	sText = _T("-");
	// }
	// else if (pInfoPart->m_bSetLock == TRUE) {
	// 	sText.LoadString(IDS_STS_LOCK);
	// }

	let sText = doGetTextCurrent(props, nIndex, bUnit, false);
	if(sText !== undefined && sText!== null) {
		sText = props.base.notes + props.base.readBase.ratedUnit;
	}
	if(sText !== undefined && sText !== null) {
		sText = '-';
	}
	else if(props.parts[nIndex].setLock) {
		sText = 'ﾛｯｸ(不動作)';
	}
	return sText;
}

export const doGetTextAdjust = (props: AdjustModel) => {
	let res: any[] = [];
	let sAdjust = "";
	let nCount = 0;
	
	if (props.parts.length > 0){
		props.parts.forEach((part: any, index) => {
			if (props.base.kindFlag != RS_KIND_FUSE) {
				sAdjust += part.tabName;
			}
			if (part.setLock == true) {
				sAdjust += "  ";
				sAdjust += IDS_STS_LOCK;
				return;
			}
			switch (part.currentFlag) {
			case RS_DIAL_FIXED:
			case RS_DIAL_CONTINUE:
				if (props.base.kindFlag == RS_KIND_HVCB && nCount == 0) {
					if (part.timeFlag >= RS_DIAL_FIXED) {
						sAdjust += " T/L = ";
					} else {
						sAdjust += " Tap = ";
					}
					nCount++;
				} else {
					sAdjust += "  ";
				}
				sAdjust += doGetTextCurrent(props, index, true, true);
				switch (part.magniCurrentFlag) {
				case RS_DIAL_FIXED:
				case RS_DIAL_CONTINUE:
				{
					sAdjust += IDS_SIGN_MULTIPLE;
					sAdjust += doGetTextMagniCurrent(part, part.magniCurrentValue);
				}
				break;
				}
				break;
			case RS_DIAL_NONE:
				if (part.readPart.currentNotes) {
					sAdjust += "  ";
					sAdjust += part.readPart.currentNotes;
					sAdjust += "(" + part.readPart.currentUnit + ")";
				}
				break;
			}
			switch (part.timeFlag) {
			case RS_DIAL_FIXED:
			case RS_DIAL_CONTINUE:
				if (part.currentFlag != RS_DIAL_FIXED &&
					part.currentFlag != RS_DIAL_CONTINUE) {
					sAdjust += "  ";
				} else {
					sAdjust += "/";
				}
				sAdjust += doGetTextTime(part, part.timeValue);
				switch (part.magniCurrentFlag) {
				case RS_DIAL_FIXED:
				case RS_DIAL_CONTINUE:
				{
					sAdjust += IDS_SIGN_MULTIPLE;
					sAdjust += doGetTextMagniTime(part, part.magniTimeValue);
				}
				break;
				}
				break;
			case RS_DIAL_NONE:
				break;
			}
			
			res.push(sAdjust);
		})
	}
	return res;
}

export const doCalcSetNominal = (plistOneCurve: any[], pObjGraph: any) => {
	if (plistOneCurve.length > 0){
		plistOneCurve.forEach((pCurveNow: any) => {
			doConvertNominal(pObjGraph, pCurveNow.m_listdPoint);
			// if (pCurveNow.m_dSaturationTime > 0) { // TODO
			// 	pCurveNow.m_dSaturationTime = doConvertNominalY(pObjGraph, pCurveNow.m_dSaturationTime);
			// 	doCutSaturation(pObjGraph, pCurveNow.m_listdPoint, pCurveNow.m_dSaturationTime);
			// }
		})
	}
	return plistOneCurve;
}
export const doConvertLinkLinePoint = (pAdjust: any) => {
	if (pAdjust.m_listPointCenter.length > 0) {
		let pdPoint = pAdjust.m_listPointCenter[0];
		if (pAdjust.m_bClickPointCenter != true) {
			pAdjust.m_dPointStartCenter = {...pdPoint};
		}
	}

	if (pAdjust.m_listPointMax.length > 0) {
		let pdPoint = pAdjust.m_listPointMax[0];
		if (pAdjust.m_bClickPointMax != true) {
			pAdjust.m_dPointStartMax = {...pdPoint};
		}
	}

	if (pAdjust.m_listPointMin.length > 0) {
		let pdPoint = pAdjust.m_listPointMin[0];
		if (pAdjust.m_bClickPointMin != true) {
			pAdjust.m_dPointStartMin = {...pdPoint};
		}
	}
}

export const doGetTextCharacterName = (pObjAdjust: AdjustModel, index: number) => {
	if (pObjAdjust == null) {
	  return "-";
	}
	if (isOkSetCurveInfo(pObjAdjust) != true){
	  return "-";
	}
	if (pObjAdjust.parts.length < index - 1){
	  return "-";
	}
	return pObjAdjust.parts[index].characterName;
  }

export const doGetRefV1Left = (props: any, bSociety: boolean) => {
	let sRefV1Left: any[] = [props.refNo];

	if (bSociety == true) {
		let pObjAdjust = props.details;
		if (pObjAdjust != null) {
			sRefV1Left.push("");
			sRefV1Left.push("");
			sRefV1Left.push(props.details.refNo2);
		}
	}

	return sRefV1Left;

}