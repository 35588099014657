import { Box, Button, Checkbox, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Stack, Tab, Tabs, TextField, Typography } from "@mui/material";
import { IeleBusbarSPhase, InfoCircuit, MsBusbarModel, OptionModel } from "../../models/Index";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import TextFieldOptions from "../common/TextFieldOptions";
import { BUSBAR_TEMP_COEFFICIENT_AL, BUSBAR_TEMP_COEFFICIENT_CU, CALC_IK2, CALC_IK2_OUTSIDE_NEUTRAL, CALC_IK2_OUTSIDE_OUTSIDE, CALC_IK2_SEAT_MAIN, CALC_IK2_SEAT_T, CALC_IK3, MS_3PHASES, MS_OUTSIDE_NEUTRAL, MS_OUTSIDE_OUTSIDE, MS_PI, MS_SEAT_MAIN, MS_SEAT_T, MS_TRANS1, MS_TRANSSCOTT, RS_3PHASE, RS_PHASE_N, RS_PHASE_PE, VALUE_NODE_VOLT_MIN } from "../../models/Constants";
import { BeamsNumber } from "../../utils/FormatNumber";
import { isNumber } from "chart.js/helpers";
import { MS_MODE_CHECKING, MS_MODE_DRAWING, RS_3PHASES } from "../../statics";
import * as Constant from "../../models/Constants";
import { DoCalcSetIValues, DoCalcSetZValues, doSetFillterValues } from "../../utils/busbarFunction";

export type MsBusbarEditDialogFormProps = {
    data: any;
    onOK: (data: any) => void;
    onCancel: () => void;
    setIsDisplayTabConnectTran1: boolean;
    setIsDisplayTabConnectTranscott:boolean;
    setIsVoltText: string;
    currentTabId: boolean;
    infoCircuit: InfoCircuit;
    userRole: string;
    m_bModePM:boolean;
    modeViewOnly:boolean;
    dispInputDialog: any;
    processMode: number;
};

const checkboxStyle = {
    padding: '0px 0px 0px 9px'
}
const checkboxStyles = {
    padding: '4px 4px 4px 8px'
}
const radioStyles = {
    padding: '0 0 0 9px'
}

function a11yProps(index: number) {
    return {
        id: `tab-${index}`,
        "aria-controls": `tabpanel-${index}`,
    };
}

//Tabs------------------
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}



export const MsBusbarEditDialog = (props: MsBusbarEditDialogFormProps) => {
    const { userRole, data, onOK, onCancel,setIsDisplayTabConnectTranscott,setIsDisplayTabConnectTran1,setIsVoltText,currentTabId,infoCircuit,m_bModePM, modeViewOnly,dispInputDialog,processMode } = props;
    const [selectTab, setSelectTab] = useState(0);

    // 初期値設定
    const beamsData = (data: any, phase: string) => {
        return {
            ohmRPerM: BeamsNumber(data[phase].ohmRPerM, 4),
            ohmXPerM: BeamsNumber(data[phase].ohmXPerM, 4)
        }
    }
    const initialValue: MsBusbarModel = {
        ...data,
        m_3Phase: {...data.m_3Phase, ...beamsData(data, "m_3Phase")},
        phaseN: {...data.phaseN, ...beamsData(data, "phaseN")},
        phaseNPE: {...data.phaseNPE, ...beamsData(data, "phaseNPE")},
        calcPoint0: {...data.calcPoint0, loadCapacity: BeamsNumber(data.calcPoint0.loadCapacity,7),
            loadCurrent: BeamsNumber(data.calcPoint0.loadCurrent,5)
        },
        calcPoint1: {...data.calcPoint1, loadCapacity: BeamsNumber(data.calcPoint1.loadCapacity,7),
            loadCurrent: BeamsNumber(data.calcPoint1.loadCurrent,5)
        }
    }
    const [makeDataInput, setMakeDataInput] = useState(initialValue.makeDataInput); // ModePM
    const [isDisplayTabTabSPhase, setIsDisplayTabSPhase] = useState(initialValue.wireSystem);
    const [isDisableByPointRegular, setIsDisableByPointRegular] = useState(initialValue.dropRegular);
    const [isModeSPhases] = useState(data.isModeSPhases);
    const { control, handleSubmit, formState: { errors }, setValue,getValues, watch,clearErrors,setError } = useForm<MsBusbarModel>({
        mode: 'all',
        criteriaMode: "all",
        defaultValues: initialValue,
    });
    // const thicknessListWatchm_3Phase = watch("m_3Phase.thicknessList")
    // const thicknessListWatchphaseN = watch("phaseN.thicknessList")
    // const thicknessListWatchphaseNPE = watch("phaseNPE.thicknessList")

    // const numberPhaseListWatchm_3Phase = watch("m_3Phase.numberPhaseList")
    // const numberPhaseListWatchphaseN = watch("phaseN.numberPhaseList")
    // const numberPhaseListWatchphaseNPE = watch("phaseNPE.numberPhaseList")

    // FormデータからRequestデータを作成する
    const createRequestData = (formValue: MsBusbarModel) => {
        if(!Number(formValue.flowSusceptance)){
            formValue.flowSusceptance = 0
        }
        if(!Number(formValue.calcPoint0.loadCurrent)){
            formValue.calcPoint0.loadCurrent = 0
        }
        if(!Number(formValue.calcPoint0.loadCapacity)){
            formValue.calcPoint0.loadCapacity = 0
        }
        if(!Number(formValue.calcPoint0.powerFactor)){
            formValue.calcPoint0.powerFactor = 0
        }
        if(!Number(formValue.calcPoint1.loadCurrent)){
            formValue.calcPoint1.loadCurrent = 0
        }
        if(!Number(formValue.calcPoint1.loadCapacity)){
            formValue.calcPoint1.loadCapacity = 0
        }
        if(!Number(formValue.calcPoint1.powerFactor)){
            formValue.calcPoint1.powerFactor = 0
        }
        if(!Number(formValue.m_3Phase.width)){
            formValue.m_3Phase.width = 0
        }
        if(!Number(formValue.m_3Phase.thickness)){
            formValue.m_3Phase.thickness = 0
        }
        if(!Number(formValue.m_3Phase.numberPhase)){
            formValue.m_3Phase.numberPhase = 0
        }
        if(!Number(formValue.phaseN.width)){
            formValue.phaseN.width = 0
        }
        if(!Number(formValue.phaseN.thickness)){
            formValue.phaseN.thickness = 0
        }
        if(!Number(formValue.phaseN.numberPhase)){
            formValue.phaseN.numberPhase = 0
        }
        if(!Number(formValue.phaseNPE.width)){
            formValue.phaseNPE.width = 0
        }
        if(!Number(formValue.phaseNPE.thickness)){
            formValue.phaseNPE.thickness = 0
        }
        if(!Number(formValue.phaseNPE.numberPhase)){
            formValue.phaseNPE.numberPhase = 0
        }
        let request = {
            ...formValue
        };
        return request;
    }
    useEffect(()=> {
        setValue("sPhase",data.isModeSPhases)
    },[])
    // Submitイベント
    const handleEditSubmit = (value: MsBusbarModel) => {
        const request = createRequestData(value);
        onOK(request);
    }
    const handleDisableByPointRegular =(e:any) =>{
        setValue(e.target.name,e.target.checked)
        setIsDisableByPointRegular(e.target.checked);


    }
    const handleCancel = () => {
        onCancel();
    };

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        if (newValue >= 0) {
            if (getValues('refNo') == "" ||getValues('refNo') == null || getValues('refNo') == undefined ) {
                setError("refNo",{ type: 'custom', message: '必須項目です。入力してください。' });
            }
            else if (isNaN(Number(getValues('voltage'))) || Number(getValues('voltage')) <= 0 || !/^[0-9\b]+$/.test(getValues('voltage').toString())) {
                setError("voltage",{ type: 'custom', message: '整数 > 0 を入力してください。' });
            }
            else if (isNaN(Number(getValues('lenght'))) || Number(getValues('lenght')) < 0) {
                setError("lenght",{ type: 'custom', message: '数字 >= 0 を入力してください。' });
            }
            else if (isNaN(Number(getValues('busbarTemp'))) || Number(getValues('busbarTemp')) < 50 ||Number(getValues('busbarTemp')) > 125 ) {
                setError("busbarTemp",{ type: 'custom', message: '数字 50 ~ 125 を入力してください。' });
            }
            else if (isNaN(Number(getValues('ambient'))) || Number(getValues('ambient')) < 0 ||Number(getValues('ambient')) > 65 || Number(getValues('ambient')) >+getValues('busbarTemp') ) {
                setError("ambient",{ type: 'custom', message: '数字 0 ~ 65 と数字 <=「ブスバーの最高温」を入力してください。' });
            }
            else if(selectTab == 2  && !/^[0-9\b]+$/.test(getValues('m_3Phase.width').toString())){
                setError("m_3Phase.width",{ type: 'custom', message: '整数 >= 0を入力してください。' });
            }
            else if(selectTab == 2  && !/^[0-9\b]+$/.test(getValues('m_3Phase.thickness').toString())){
                setError("m_3Phase.thickness",{ type: 'custom', message: '整数 >= 0を入力してください。' });
            }
            else if(selectTab == 2  &&!/^[0-9\b]+$/.test(getValues('m_3Phase.numberPhase').toString())){
                setError("m_3Phase.numberPhase",{ type: 'custom', message: '整数 >= 0を入力してください。' });
            }
            else if(selectTab == 2  &&!/^[0-9\b]+$/.test(getValues('m_3Phase.distance').toString())){
                setError("m_3Phase.distance",{ type: 'custom', message: '整数 >= 0を入力してください。' });
            }
            else if(selectTab == 2  && (!/^[0-9]*[.]?[0-9]+$/.test(getValues('m_3Phase.skinEffectM').toString()) || Number(getValues('m_3Phase.skinEffectM')) <= 0)){
                setError("m_3Phase.skinEffectM",{ type: 'custom', message: '数字 > 0を入力してください。' });
            }
            else if(selectTab == 2  && (!/^[0-9]*[.]?[0-9]+$/.test(getValues('m_3Phase.ohmRPerM').toString()) || Number(getValues('m_3Phase.ohmRPerM')) <= 0)){
                setError("m_3Phase.ohmRPerM",{ type: 'custom', message: '数字 > 0を入力してください。' });
            }
            else if(selectTab == 2  && (!/^[0-9]*[.]?[0-9]+$/.test(getValues('m_3Phase.ohmXPerM').toString()) || Number(getValues('m_3Phase.ohmXPerM')) <= 0)){
                setError("m_3Phase.ohmXPerM",{ type: 'custom', message: '数字 > 0を入力してください。' });
            }
            else if(selectTab == 2  && (!/^[0-9]*[.]?[0-9]+$/.test(getValues('m_3Phase.basicCapacityM').toString()) || Number(getValues('m_3Phase.basicCapacityM')) <= 0)){
                setError("m_3Phase.basicCapacityM",{ type: 'custom', message: '数字 > 0を入力してください。' });
            }
            else if(selectTab == 2  &&(!/^[0-9]*[.]?[0-9]+$/.test(getValues('m_3Phase.tempK2M').toString()) || Number(getValues('m_3Phase.tempK2M')) <= 0)){
                setError("m_3Phase.tempK2M",{ type: 'custom', message: '数字 > 0を入力してください。' });
            }
            else if(selectTab == 2  &&(!/^[0-9]*[.]?[0-9]+$/.test(getValues('m_3Phase.horizontalK3M').toString()) || Number(getValues('m_3Phase.horizontalK3M')) <= 0)){
                setError("m_3Phase.horizontalK3M",{ type: 'custom', message: '数字 > 0を入力してください。' });
            }
            else if(selectTab == 2  &&(!/^[0-9]*[.]?[0-9]+$/.test(getValues('m_3Phase.proximityK4M').toString()) || Number(getValues('m_3Phase.proximityK4M')) <= 0)){
                setError("m_3Phase.proximityK4M",{ type: 'custom', message: '数字 > 0を入力してください。' });
            }


            else if((isDisplayTabTabSPhase == 2 || isDisplayTabTabSPhase == 3)&& !isModeSPhases && selectTab ==3 &&!/^[0-9\b]+$/.test(getValues('phaseN.width').toString())){
                setError("phaseN.width",{ type: 'custom', message: '整数 >= 0を入力してください。' });
            }
            else if((isDisplayTabTabSPhase == 2 || isDisplayTabTabSPhase == 3)&& !isModeSPhases && selectTab ==3 &&!/^[0-9\b]+$/.test(getValues('phaseN.thickness').toString())){
                setError("phaseN.thickness",{ type: 'custom', message: '整数 >= 0を入力してください。' });
            }
            else if((isDisplayTabTabSPhase == 2 || isDisplayTabTabSPhase == 3)&& !isModeSPhases && selectTab ==3 &&!/^[0-9\b]+$/.test(getValues('phaseN.numberPhase').toString())){
                setError("phaseN.numberPhase",{ type: 'custom', message: '整数 >= 0を入力してください。' });
            }
            else if((isDisplayTabTabSPhase == 2 || isDisplayTabTabSPhase == 3)&& !isModeSPhases && selectTab ==3 &&!/^[0-9\b]+$/.test(getValues('phaseN.distance').toString())){
                setError("phaseN.distance",{ type: 'custom', message: '整数 >= 0を入力してください。' });
            }
            else if((isDisplayTabTabSPhase == 2 || isDisplayTabTabSPhase == 3)&& !isModeSPhases && selectTab ==3 &&(!/^[0-9]*[.]?[0-9]+$/.test(getValues('phaseN.skinEffectM').toString()) || Number(getValues('phaseN.skinEffectM')) <= 0)){
                setError("phaseN.skinEffectM",{ type: 'custom', message: '数字 > 0を入力してください。' });
            }
            else if((isDisplayTabTabSPhase == 2 || isDisplayTabTabSPhase == 3)&& !isModeSPhases && selectTab ==3 &&(!/^[0-9]*[.]?[0-9]+$/.test(getValues('phaseN.ohmRPerM').toString()) || Number(getValues('phaseN.ohmRPerM')) <= 0)){
                setError("phaseN.ohmRPerM",{ type: 'custom', message: '数字 > 0を入力してください。' });
            }
            else if((isDisplayTabTabSPhase == 2 || isDisplayTabTabSPhase == 3)&& !isModeSPhases && selectTab ==3 &&(!/^[0-9]*[.]?[0-9]+$/.test(getValues('phaseN.ohmXPerM').toString()) || Number(getValues('phaseN.ohmXPerM')) <= 0)){
                setError("phaseN.ohmXPerM",{ type: 'custom', message: '数字 > 0を入力してください。' });
            }
            else if((isDisplayTabTabSPhase == 2 || isDisplayTabTabSPhase == 3)&& !isModeSPhases && selectTab ==3 &&(!/^[0-9]*[.]?[0-9]+$/.test(getValues('phaseN.basicCapacityM').toString()) || Number(getValues('phaseN.basicCapacityM')) <= 0)){
                setError("phaseN.basicCapacityM",{ type: 'custom', message: '数字 > 0を入力してください。' });
            }
            else if((isDisplayTabTabSPhase == 2 || isDisplayTabTabSPhase == 3)&& !isModeSPhases && selectTab ==3 &&(!/^[0-9]*[.]?[0-9]+$/.test(getValues('phaseN.tempK2M').toString()) || Number(getValues('phaseN.tempK2M')) <= 0)){
                setError("phaseN.tempK2M",{ type: 'custom', message: '数字 > 0を入力してください。' });
            }
            else if((isDisplayTabTabSPhase == 2 || isDisplayTabTabSPhase == 3)&& !isModeSPhases && selectTab ==3 &&(!/^[0-9]*[.]?[0-9]+$/.test(getValues('phaseN.horizontalK3M').toString()) || Number(getValues('phaseN.horizontalK3M')) <= 0)){
                setError("phaseN.horizontalK3M",{ type: 'custom', message: '数字 > 0を入力してください。' });
            }
            else if((isDisplayTabTabSPhase == 2 || isDisplayTabTabSPhase == 3)&& !isModeSPhases && selectTab ==3 &&(!/^[0-9]*[.]?[0-9]+$/.test(getValues('phaseN.proximityK4M').toString()) || Number(getValues('phaseN.proximityK4M')) <= 0)){
                setError("phaseN.proximityK4M",{ type: 'custom', message: '数字 > 0を入力してください。' });
            }

            else if((isDisplayTabTabSPhase === 3 && !isModeSPhases) && selectTab == 4 && !/^[0-9\b]+$/.test(getValues('phaseNPE.width').toString())){
                setError("phaseNPE.width",{ type: 'custom', message: '整数 >= 0を入力してください。' });
            }
            else if((isDisplayTabTabSPhase == 3 && !isModeSPhases) && selectTab == 4 &&!/^[0-9\b]+$/.test(getValues('phaseNPE.thickness').toString())){
                setError("phaseNPE.thickness",{ type: 'custom', message: '整数 >= 0を入力してください。' });
            }
            else if((isDisplayTabTabSPhase == 3 && !isModeSPhases) && selectTab == 4 &&!/^[0-9\b]+$/.test(getValues('phaseNPE.numberPhase').toString())){
                setError("phaseNPE.numberPhase",{ type: 'custom', message: '整数 >= 0を入力してください。' });
            }
            else if((isDisplayTabTabSPhase == 3 && !isModeSPhases) && selectTab == 4 &&!/^[0-9\b]+$/.test(getValues('phaseNPE.distance').toString())){
                setError("phaseNPE.distance",{ type: 'custom', message: '整数 >= 0を入力してください。' });
            }
            else if((isDisplayTabTabSPhase == 3 && !isModeSPhases) && selectTab == 4 &&(!/^[0-9]*[.]?[0-9]+$/.test(getValues('phaseNPE.skinEffectM').toString()) || Number(getValues('phaseNPE.skinEffectM')) <= 0)){
                setError("phaseNPE.skinEffectM",{ type: 'custom', message: '数字 > 0を入力してください。' });
            }
            else if((isDisplayTabTabSPhase == 3 && !isModeSPhases) && selectTab == 4 &&(!/^[0-9]*[.]?[0-9]+$/.test(getValues('phaseNPE.ohmRPerM').toString()) || Number(getValues('phaseNPE.ohmRPerM')) <= 0)){
                setError("phaseNPE.ohmRPerM",{ type: 'custom', message: '数字 > 0を入力してください。' });
            }
            else if((isDisplayTabTabSPhase == 3 && !isModeSPhases) && selectTab == 4 &&(!/^[0-9]*[.]?[0-9]+$/.test(getValues('phaseNPE.ohmXPerM').toString()) || Number(getValues('phaseNPE.ohmXPerM')) <= 0)){
                setError("phaseNPE.ohmXPerM",{ type: 'custom', message: '数字 > 0 を入力してください。' });
            }
            else if((isDisplayTabTabSPhase == 3 && !isModeSPhases) && selectTab == 4 &&(!/^[0-9]*[.]?[0-9]+$/.test(getValues('phaseNPE.basicCapacityM').toString()) || Number(getValues('phaseNPE.basicCapacityM')) <= 0)){
                setError("phaseNPE.basicCapacityM",{ type: 'custom', message: '数字 > 0 を入力してください。' });
            }
            else if((isDisplayTabTabSPhase == 3 && !isModeSPhases) && selectTab == 4 &&(!/^[0-9]*[.]?[0-9]+$/.test(getValues('phaseNPE.tempK2M').toString()) || Number(getValues('phaseNPE.tempK2M')) <= 0)){
                setError("phaseNPE.tempK2M",{ type: 'custom', message: '数字 > 0 を入力してください。' });
            }
            else if((isDisplayTabTabSPhase == 3 && !isModeSPhases) && selectTab == 4 &&(!/^[0-9]*[.]?[0-9]+$/.test(getValues('phaseNPE.horizontalK3M').toString()) || Number(getValues('phaseNPE.horizontalK3M')) <= 0)){
                setError("phaseNPE.horizontalK3M",{ type: 'custom', message: '数字 > 0 を入力してください。' });
            }
            else if((isDisplayTabTabSPhase == 3 && !isModeSPhases) && selectTab == 4 &&(!/^[0-9]*[.]?[0-9]+$/.test(getValues('phaseNPE.proximityK4M').toString()) || Number(getValues('phaseNPE.proximityK4M')) <= 0)){
                setError("phaseNPE.proximityK4M",{ type: 'custom', message: '数字 > 0 を入力してください。' });
            }
            else if(isDisplayTabTabSPhase == 1 && selectTab == 3 && !isModeSPhases && (!/^[0-9]*[.]?[0-9]+$/.test(getValues('calcPoint0.loadCurrent').toString()) || Number(getValues('calcPoint0.loadCurrent')) < 0)){
                setError("calcPoint0.loadCurrent",{ type: 'custom', message: '数字 >= 0 を入力してください。' });
            }
            else if(isDisplayTabTabSPhase == 1 && selectTab == 3 && !isModeSPhases && (!/^[0-9]*[.]?[0-9]+$/.test(getValues('calcPoint0.loadCapacity').toString()) || Number(getValues('calcPoint0.loadCapacity')) < 0)){
                setError("calcPoint0.loadCapacity",{ type: 'custom', message: '数字 >= 0 を入力してください。' });
            }
            else if(isDisplayTabTabSPhase == 1 && selectTab == 3 && !isModeSPhases && (!/^[0-9]*[.]?[0-9]+$/.test(getValues('calcPoint0.powerFactor').toString()) || Number(getValues('calcPoint0.powerFactor')) < 0 || Number(getValues('calcPoint0.powerFactor')) > 1)){
                setError("calcPoint0.powerFactor",{ type: 'custom', message: '数字 0 ~ 1 を入力してください。' });
            }
            else if(isDisplayTabTabSPhase == 2 && selectTab == 4 && !isModeSPhases && (!/^[0-9]*[.]?[0-9]+$/.test(getValues('calcPoint0.loadCurrent').toString()) || Number(getValues('calcPoint0.loadCurrent')) < 0)){
                setError("calcPoint0.loadCurrent",{ type: 'custom', message: '数字 >= 0 を入力してください。' });
            }
            else if(isDisplayTabTabSPhase == 2 && selectTab == 4 && !isModeSPhases && (!/^[0-9]*[.]?[0-9]+$/.test(getValues('calcPoint0.loadCapacity').toString()) || Number(getValues('calcPoint0.loadCapacity')) < 0)){
                setError("calcPoint0.loadCapacity",{ type: 'custom', message: '数字 >= 0 を入力してください。' });
            }
            else if(isDisplayTabTabSPhase == 2 && selectTab == 4 && !isModeSPhases && (!/^[0-9]*[.]?[0-9]+$/.test(getValues('calcPoint0.powerFactor').toString()) || Number(getValues('calcPoint0.powerFactor')) < 0 || Number(getValues('calcPoint0.powerFactor')) > 1)){
                setError("calcPoint0.powerFactor",{ type: 'custom', message: '数字 0 ~ 1 を入力してください。' });
            }
            else if(isDisplayTabTabSPhase == 3 && selectTab == 5 && !isModeSPhases && (!/^[0-9]*[.]?[0-9]+$/.test(getValues('calcPoint0.loadCurrent').toString()) || Number(getValues('calcPoint0.loadCurrent')) < 0)){
                setError("calcPoint0.loadCurrent",{ type: 'custom', message: '数字 >= 0 を入力してください。' });
            }
            else if(isDisplayTabTabSPhase == 3 && selectTab == 5 && !isModeSPhases && (!/^[0-9]*[.]?[0-9]+$/.test(getValues('calcPoint0.loadCapacity').toString()) || Number(getValues('calcPoint0.loadCapacity')) < 0)){
                setError("calcPoint0.loadCapacity",{ type: 'custom', message: '数字 >= 0 を入力してください。' });
            }
            else if(isDisplayTabTabSPhase == 3 && selectTab == 5 && !isModeSPhases && (!/^[0-9]*[.]?[0-9]+$/.test(getValues('calcPoint0.powerFactor').toString()) || Number(getValues('calcPoint0.powerFactor')) < 0 || Number(getValues('calcPoint0.powerFactor')) > 1)){
                setError("calcPoint0.powerFactor",{ type: 'custom', message: '数字 0 ~ 1 を入力してください。' });
            }
            else if((setIsDisplayTabConnectTranscott ||setIsDisplayTabConnectTran1) && selectTab == 3 && (!/^[0-9]*[.]?[0-9]+$/.test(getValues('calcPoint0.loadCapacity').toString()) || Number(getValues('calcPoint0.loadCapacity')) < 0)){
                setError("calcPoint0.loadCapacity",{ type: 'custom', message: '数字 >= 0 を入力してください。' });
            }
            else if((setIsDisplayTabConnectTranscott ||setIsDisplayTabConnectTran1) && selectTab == 3 && (!/^[0-9]*[.]?[0-9]+$/.test(getValues('calcPoint0.loadCurrent').toString()) || Number(getValues('calcPoint0.loadCurrent')) < 0)){
                setError("calcPoint0.loadCurrent",{ type: 'custom', message: '数字 >= 0 を入力してください。' });
            }
            else if((setIsDisplayTabConnectTranscott ||setIsDisplayTabConnectTran1) && selectTab == 3 && (!/^[0-9]*[.]?[0-9]+$/.test(getValues('calcPoint0.powerFactor').toString()) || Number(getValues('calcPoint0.powerFactor')) < 0)){
                setError("calcPoint0.powerFactor",{ type: 'custom', message: '数字 >= 0 を入力してください。' });
            }
            else if((setIsDisplayTabConnectTranscott ||setIsDisplayTabConnectTran1) && selectTab == 4 && (!/^[0-9]*[.]?[0-9]+$/.test(getValues('calcPoint1.loadCapacity').toString()) || Number(getValues('calcPoint1.loadCapacity')) < 0)){
                setError("calcPoint1.loadCapacity",{ type: 'custom', message: '数字 >= 0 を入力してください。' });
            }
            else if((setIsDisplayTabConnectTranscott ||setIsDisplayTabConnectTran1) && selectTab == 4 && (!/^[0-9]*[.]?[0-9]+$/.test(getValues('calcPoint1.loadCurrent').toString()) || Number(getValues('calcPoint1.loadCurrent')) < 0)){
                setError("calcPoint1.loadCurrent",{ type: 'custom', message: '数字 >= 0 を入力してください。' });
            }
            else if((setIsDisplayTabConnectTranscott ||setIsDisplayTabConnectTran1) && selectTab == 4 && (!/^[0-9]*[.]?[0-9]+$/.test(getValues('calcPoint1.powerFactor').toString()) || Number(getValues('calcPoint1.powerFactor')) < 0)){
                setError("calcPoint1.powerFactor",{ type: 'custom', message: '数字 >= 0 を入力してください。' });
            }
            else{
                setSelectTab(newValue);
            }
        }
        else{
        setSelectTab(newValue);
        }
    };

    const handleChangeFormat = (e: any) => {
        setValue(e.target.name, e.target.value);
    }
    const handleChangeFormatChecked =(e:any) =>{
        setValue(e.target.name,e.target.checked)

    }
    const handleDisplayTab =(e:any) =>{
        setValue(e.target.name,e.target.value)
        let wireSystem = +getValues("wireSystem")
        if(wireSystem === 1 && !isModeSPhases){
            // setValue("wireSystem",1)
            setIsDisplayTabSPhase(1)
        }
        if (wireSystem === 2 && !isModeSPhases) {
            // setValue("wireSystem",2)
            setIsDisplayTabSPhase(2)

        }
        else if (wireSystem === 3 && !isModeSPhases){
            // setValue("wireSystem",3)
            setIsDisplayTabSPhase(3)

        }
    }
    const handleChangeMakeDataInput = (e: any) => {
        setValue(e.target.name, e.target.checked);
        setMakeDataInput(e.target.checked);
    }
    
    const doGetValueTempk2 = (e:any) =>{
        setValue(e.target.name,e.target.value)
        let busbarTemp = +getValues("busbarTemp")
        let ambientTemp = +getValues("ambient")
        let dTempk2 = Math.pow((busbarTemp - ambientTemp)/ 30 *(1 + 0.004 * (65 -20))/(1 + 0.004 * (busbarTemp - 20)),1 / 1.7)

        return dTempk2
    }
    const onChangeBusBarTempAndAmbient = (e:any) =>{
        setValue(e.target.name, e.target.value,{shouldValidate:true})
        if(e.target.name === 'busbarTemp'){
            if(e.target.value >= Number(getValues('ambient'))){
                clearErrors('ambient')
            }
        }
        let tempk2  = doGetValueTempk2(e)

        setValue("m_3Phase.tempK2M",BeamsNumber(tempk2,3))
        setValue("phaseN.tempK2M",BeamsNumber(tempk2,3))
        setValue("phaseNPE.tempK2M",BeamsNumber(tempk2,3))
        doSetIValuesBusBar(e,1)
        doSetIValuesBusBar(e,2)
        doSetIValuesBusBar(e,3)
    }
    
    const onChangeLength =(e:any) =>{
        setValue(e.target.name, e.target.value,{shouldValidate: true})
        doSetZValuesBusBar(e,1)
        doSetZValuesBusBar(e,2)
        doSetZValuesBusBar(e,3)
        if(setIsDisplayTabConnectTran1|| setIsDisplayTabConnectTranscott){
            doSetValueVoltDrop(e,+getValues("voltage"),true)
            doSetValueVoltDrop(e,+getValues("voltage"),false)
        }
        else{
            doSetValueVoltDrop(e,+getValues("voltage"),false)
        }
        // handleDisableOKBt(e)

    }
    const doGetValueCapacity = (sphase:IeleBusbarSPhase) =>{
        let capacity  = sphase.basicCapacityM
        if(sphase.tempK2M > 0){
            capacity *= sphase.tempK2M
        }
        if(sphase.horizontalK3M > 0){
            capacity *= sphase.horizontalK3M
        }
        if(sphase.proximityK4M > 0){
            capacity *= sphase.proximityK4M
        }
        return BeamsNumber(capacity,4)
    }
    //set capacity

    const doSetValueDropLoadCapacity =(voltage:number,nCalcKind:number,loadCurrent:number) =>{
        let loadCapacity = voltage * loadCurrent
        switch(nCalcKind){
            case CALC_IK3:
                loadCapacity *= Math.sqrt(3.0)
                break
            case CALC_IK2:
                break
            case CALC_IK2_OUTSIDE_NEUTRAL:
                break
            case CALC_IK2_OUTSIDE_OUTSIDE:
                break
            case CALC_IK2_SEAT_MAIN:
                break
            case CALC_IK2_SEAT_T:
                break
        }
        return BeamsNumber(loadCapacity,7)
    }
    const doGetValuePerRegular = (voltage:number,m_dStdCapacity:number,m_dPerRk3:number,m_dPerXk3:number,nCalcKind:number,powerFactor:number,leadOrLag:number,loadCurrent:number) =>{
        if(powerFactor < 0 || m_dStdCapacity <=0){
            return 0
        }
        let dcapacity = voltage * loadCurrent
        switch(nCalcKind){
            case CALC_IK3:
                dcapacity *= Math.sqrt(3.0)
                break
            case CALC_IK2:
                dcapacity *= 2
                break
            case CALC_IK2_OUTSIDE_NEUTRAL:
                break
            case CALC_IK2_OUTSIDE_OUTSIDE:
                dcapacity *= 2
                break
            case CALC_IK2_SEAT_MAIN:
                dcapacity *= 2
                break
            case CALC_IK2_SEAT_T:
                dcapacity *= 2
                break
        }
        let dPercent = 0
        if(powerFactor * powerFactor <= 1){
            if(leadOrLag === 1){
                dPercent = m_dPerRk3 * powerFactor - m_dPerXk3 * Math.sqrt(1- powerFactor * powerFactor)
            }
            else{
                dPercent = m_dPerRk3 * powerFactor + m_dPerXk3 * Math.sqrt(1- powerFactor * powerFactor)

            }
        }
        dPercent *= dcapacity
        dPercent /= m_dStdCapacity * 1000
        dPercent /= 100
        if(Math.abs(dPercent) < VALUE_NODE_VOLT_MIN){
            dPercent = 0

        }
        return dPercent


    }
    const doGetValueVoltRegular = (voltage:number,m_dStdCapacity:number,m_dPerRk3:number, m_dPerXk3:number,nCalcKind:number,powerFactor:number,leadOrLag:number,loadCurrent:number) =>{
        let percent = doGetValuePerRegular(voltage,m_dStdCapacity,m_dPerRk3,m_dPerXk3,nCalcKind,powerFactor,leadOrLag,loadCurrent)
        let dDropVolt = voltage - voltage * percent

        if(Math.abs(dDropVolt) < VALUE_NODE_VOLT_MIN){
            dDropVolt = 0
        }
        return dDropVolt
    }
    const doGetPerZrBusBar =(m_dStdCapacity:number,wireSystem:number,partKind:number,bTwoWay:boolean) =>{
        let percentZ ={
            m_dPerRk3 : 0,
            m_dPerXk3 : 0,
            m_dPerRk3Difference: 0,
            m_dPerXk3Difference: 0
        }
        let lenght = +getValues("lenght")
        let m_3PhaseohmRPer = +getValues("m_3Phase.ohmRPerM")
        let m_3PhaseohmXPer = +getValues("m_3Phase.ohmXPerM")
        let voltage = +getValues("voltage")
        if(voltage <=0){
            percentZ.m_dPerRk3 =0
            percentZ.m_dPerXk3 = 0

        }
        else{
            switch(wireSystem){
                case RS_3PHASE:
                    if(doSetSPhaseConnect()){
                        switch(partKind){
                            case MS_OUTSIDE_NEUTRAL:
                                percentZ.m_dPerRk3 = m_dStdCapacity *  m_3PhaseohmRPer * lenght /(voltage / 2)/ (voltage / 2) * 100
                                percentZ.m_dPerXk3 = m_dStdCapacity *  m_3PhaseohmXPer * lenght /(voltage / 2)/ (voltage / 2) * 100
                                if(bTwoWay){
                                    percentZ.m_dPerRk3 *= 2
                                    percentZ.m_dPerXk3 *= 2

                                }
                                break
                            case MS_OUTSIDE_OUTSIDE:
                                percentZ.m_dPerRk3 = m_dStdCapacity *  m_3PhaseohmRPer * lenght /voltage/ voltage  * 100
                                percentZ.m_dPerXk3 = m_dStdCapacity *  m_3PhaseohmXPer * lenght /voltage / voltage  * 100
                                if(bTwoWay){
                                    percentZ.m_dPerRk3 *= 2
                                    percentZ.m_dPerXk3 *= 2

                                }
                                percentZ.m_dPerRk3Difference = m_dStdCapacity *  m_3PhaseohmRPer * lenght /(voltage / 2)/ (voltage / 2) * 100
                                percentZ.m_dPerXk3Difference = m_dStdCapacity *  m_3PhaseohmXPer * lenght /(voltage / 2)/ (voltage / 2) * 100
                                if(bTwoWay){
                                    percentZ.m_dPerRk3Difference *= 2
                                    percentZ.m_dPerXk3Difference *= 2
                                }
                                percentZ.m_dPerRk3Difference = percentZ.m_dPerRk3 - percentZ.m_dPerRk3Difference
                                percentZ.m_dPerXk3Difference = percentZ.m_dPerXk3 - percentZ.m_dPerXk3Difference
                                break
                            case MS_SEAT_MAIN:
                            case MS_SEAT_T:
                                percentZ.m_dPerRk3 = m_dStdCapacity *  m_3PhaseohmRPer * lenght /voltage/ voltage  * 100
                                percentZ.m_dPerXk3 = m_dStdCapacity *  m_3PhaseohmXPer * lenght /voltage / voltage  * 100
                                if(bTwoWay){
                                    percentZ.m_dPerRk3 *= 2
                                    percentZ.m_dPerXk3 *= 2

                                }
                                break;

                        }
                    }
                    else{
                        percentZ.m_dPerRk3 = m_dStdCapacity *  m_3PhaseohmRPer * lenght /voltage/ voltage  * 100
                        percentZ.m_dPerXk3 = m_dStdCapacity *  m_3PhaseohmXPer * lenght /voltage / voltage  * 100
                    }
                    break
                case RS_PHASE_N:
                    percentZ.m_dPerRk3 = m_dStdCapacity *  m_3PhaseohmRPer * lenght /voltage/ voltage  * 100
                    percentZ.m_dPerXk3 = m_dStdCapacity *  m_3PhaseohmXPer * lenght /voltage / voltage  * 100
                    break
                case RS_PHASE_PE:
                    percentZ.m_dPerRk3 = m_dStdCapacity *  m_3PhaseohmRPer * lenght /voltage/ voltage  * 100
                    percentZ.m_dPerXk3 = m_dStdCapacity *  m_3PhaseohmXPer * lenght /voltage / voltage  * 100
                    break
            }
        }
        return percentZ
    }
    const doGetDropVoltDiff = (m_dStdCapacity:number,voltage:number, partKind:number,powerFactor:number,leadOrLag:number,loadCurrent:number)=>{
        let m_nDownTransKind = getValues("downTransKind")
        let percentZ = doGetPerZrBusBar(m_dStdCapacity,RS_3PHASE,partKind,false)
        let volt = 0
        if(doSetSPhaseConnect()){
            switch(partKind){
                case MS_OUTSIDE_NEUTRAL:
                case MS_SEAT_MAIN:
                    let nCalcKind1 = getValues("calcPoint1.calcKind")
                    volt = doGetValueVoltRegular(voltage,m_dStdCapacity,percentZ.m_dPerRk3, percentZ.m_dPerXk3,nCalcKind1,powerFactor,leadOrLag,loadCurrent)
                    break;
                case MS_OUTSIDE_OUTSIDE:
                case MS_SEAT_T:
                    let nCalcKind0 = getValues("calcPoint0.calcKind")
                    volt = doGetValueVoltRegular(voltage,m_dStdCapacity,percentZ.m_dPerRk3, percentZ.m_dPerXk3,nCalcKind0,powerFactor,leadOrLag,loadCurrent)
                     break
            }
            volt = voltage - volt
        }
        else{
            if(m_nDownTransKind === MS_TRANS1){
                    let nCalcKind1 = getValues("calcPoint1.calcKind")

                    volt = doGetValueVoltRegular(voltage,m_dStdCapacity,percentZ.m_dPerRk3, percentZ.m_dPerXk3,nCalcKind1,powerFactor,leadOrLag,loadCurrent)

            }
            else{
                let nCalcKind0 = getValues("calcPoint0.calcKind")
                volt = doGetValueVoltRegular(voltage,m_dStdCapacity,percentZ.m_dPerRk3, percentZ.m_dPerXk3,nCalcKind0,powerFactor,leadOrLag,loadCurrent)

            }
            volt = voltage - volt
        }

        return BeamsNumber(volt,4)
    }
    const doGetDropPerUnit = (voltage:number,m_dStdCapacity:number,partKind:number,powerFactor:number,leadOrLag:number,loadCurrent:number) =>{
        let m_nDownTransKind = getValues("downTransKind")

        let percentZ = doGetPerZrBusBar(m_dStdCapacity,RS_3PHASE,partKind,false)
        let volt = 0
        let percent = 0
        if(doSetSPhaseConnect()) {
            switch(partKind){
                case MS_OUTSIDE_NEUTRAL:
                    setValue("calcPoint1.stdCapacity",m_dStdCapacity)
                    volt = doGetValueVoltRegular(voltage/2,m_dStdCapacity, percentZ.m_dPerRk3,percentZ.m_dPerXk3,getValues("calcPoint1.calcKind"),powerFactor,leadOrLag,loadCurrent)
                    percent = 1- volt /(voltage/2)
                    break
                case MS_SEAT_MAIN:
                    setValue("calcPoint1.stdCapacity",m_dStdCapacity)
                    volt = doGetValueVoltRegular(voltage,m_dStdCapacity, percentZ.m_dPerRk3,percentZ.m_dPerXk3,getValues("calcPoint1.calcKind"),powerFactor,leadOrLag,loadCurrent)
                    percent = 1- volt /voltage
                    break
                case MS_OUTSIDE_OUTSIDE:
                    setValue("calcPoint0.stdCapacity",m_dStdCapacity)
                    volt = doGetValueVoltRegular(voltage,m_dStdCapacity, percentZ.m_dPerRk3,percentZ.m_dPerXk3, getValues("calcPoint0.calcKind"),powerFactor,leadOrLag,loadCurrent)
                    percent = 1- volt /voltage
                    break
                case MS_SEAT_T:
                    setValue("calcPoint0.stdCapacity",m_dStdCapacity)
                    volt = doGetValueVoltRegular(voltage,m_dStdCapacity, percentZ.m_dPerRk3,percentZ.m_dPerXk3, getValues("calcPoint0.calcKind"),powerFactor,leadOrLag,loadCurrent)
                    percent = 1- volt /voltage
                    break
            }
        }
        else{
            if(m_nDownTransKind === MS_TRANS1){
                    setValue("calcPoint1.stdCapacity",m_dStdCapacity)
                    let nCalcKind1  = getValues("calcPoint1.calcKind")
                volt = doGetValueVoltRegular(voltage,m_dStdCapacity, percentZ.m_dPerRk3,percentZ.m_dPerXk3,nCalcKind1,powerFactor,leadOrLag,loadCurrent)
            }
            else{
                    setValue("calcPoint0.stdCapacity",m_dStdCapacity)
                    let nCalcKind0  = getValues("calcPoint0.calcKind")
                volt = doGetValueVoltRegular(voltage,m_dStdCapacity, percentZ.m_dPerRk3,percentZ.m_dPerXk3,nCalcKind0,powerFactor,leadOrLag,loadCurrent)

            }
            percent = 1- volt / voltage
        }
        percent *= 100
    return BeamsNumber(percent,4)

    }
    const doSetValueVoltDrop = (e:any,voltage:number,isConnectTrans1OrTranscott:boolean) =>{
        const m_dStdCapacity = infoCircuit.stdCapacity// hard code
        let upTransKind = getValues("upTransKind")
        if(isConnectTrans1OrTranscott){
            let voltageDrop1_2 = 0
            let voltageDrop2 = 0
        if(doSetSPhaseConnect()){
            
            switch(upTransKind){
                case MS_TRANS1:
                    voltageDrop1_2 = doGetDropVoltDiff(m_dStdCapacity,voltage/2, MS_OUTSIDE_NEUTRAL,+getValues("calcPoint1.powerFactor"),+getValues("leadOrLag2"),+getValues("calcPoint1.loadCurrent"))
                    voltageDrop2 = doGetDropPerUnit(voltage,m_dStdCapacity,MS_OUTSIDE_NEUTRAL,+getValues("calcPoint1.powerFactor"),+getValues("leadOrLag2"),+getValues("calcPoint1.loadCurrent"))
                    break
                case MS_TRANSSCOTT:
                    voltageDrop1_2 = doGetDropVoltDiff(m_dStdCapacity,voltage, MS_SEAT_MAIN,+getValues("calcPoint1.powerFactor"),+getValues("leadOrLag2"),+getValues("calcPoint1.loadCurrent"))
                    voltageDrop2 = doGetDropPerUnit(voltage,m_dStdCapacity,MS_SEAT_MAIN,+getValues("calcPoint1.powerFactor"),+getValues("leadOrLag2"),+getValues("calcPoint1.loadCurrent"))
                    break
            }
        }
        else{
            voltageDrop1_2 = doGetDropVoltDiff(m_dStdCapacity,voltage, MS_3PHASES,+getValues("calcPoint1.powerFactor"),+getValues("leadOrLag2"),+getValues("calcPoint1.loadCurrent"))
            voltageDrop2 = doGetDropPerUnit(voltage,m_dStdCapacity,MS_3PHASES,+getValues("calcPoint1.powerFactor"),+getValues("leadOrLag2"),+getValues("calcPoint1.loadCurrent"))
        }
            setValue("voltageDrop_1_2",voltageDrop1_2)
            setValue("voltageDrop2",voltageDrop2)
        
    }
    else{
        let voltageDrop1 =0
        let voltageDrop = 0
        if(doSetSPhaseConnect()){
            switch(upTransKind){
                case MS_TRANS1:
                    voltageDrop1 = doGetDropVoltDiff(m_dStdCapacity,voltage, MS_OUTSIDE_OUTSIDE,+getValues("calcPoint0.powerFactor"),+getValues("leadOrLag1"),+getValues("calcPoint0.loadCurrent"))
                    voltageDrop = doGetDropPerUnit(voltage,m_dStdCapacity,MS_OUTSIDE_OUTSIDE,+getValues("calcPoint0.powerFactor"),+getValues("leadOrLag1"),+getValues("calcPoint0.loadCurrent"))
                    break
                case MS_TRANSSCOTT:
                    voltageDrop1 = doGetDropVoltDiff(m_dStdCapacity,voltage, MS_SEAT_T,+getValues("calcPoint0.powerFactor"),+getValues("leadOrLag1"),+getValues("calcPoint0.loadCurrent"))
                    voltageDrop = doGetDropPerUnit(voltage,m_dStdCapacity,MS_SEAT_T,+getValues("calcPoint0.powerFactor"),+getValues("leadOrLag1"),+getValues("calcPoint0.loadCurrent"))
                    break
            }
        }
        else{
            voltageDrop1 = doGetDropVoltDiff(m_dStdCapacity,voltage, MS_3PHASES,+getValues("calcPoint0.powerFactor"),+getValues("leadOrLag1"),+getValues("calcPoint0.loadCurrent"))
            voltageDrop = doGetDropPerUnit(voltage,m_dStdCapacity,MS_3PHASES,+getValues("calcPoint0.powerFactor"),+getValues("leadOrLag1"),+getValues("calcPoint0.loadCurrent"))
        }
        setValue("voltageDrop_1",voltageDrop1)
        setValue("voltageDrop",voltageDrop)
    }
    }
    const doSetSPhaseConnect =() =>{
        let sPhase = false
        if(setIsDisplayTabConnectTran1 || setIsDisplayTabConnectTranscott){
            sPhase = true
        }
        else{
            sPhase = false
        }
        return sPhase
    }
    const onChangeLoadCurrent =(e:any,isConnectTrans1OrTranscott:boolean) =>{
        setValue(e.target.name, e.target.value,{shouldValidate:true})
        let upTransKind = getValues("upTransKind")
        if(isConnectTrans1OrTranscott){
            setValue("inputedCurrent2",true)
            setValue("inputedCapacity2",false)
        }else{
            setValue("inputedCurrent1",true)
            setValue("inputedCapacity1",false)
        }
        let voltage = +getValues("voltage")
        if(isConnectTrans1OrTranscott){
            let loadCapacity = 0
            if(getValues("calcPoint1.loadCurrent") >= 0){
                setValue("calcPoint1.loadCurrent", getValues("calcPoint1.loadCurrent")) 
                if(doSetSPhaseConnect() && upTransKind == MS_TRANS1){
                    loadCapacity = doSetValueDropLoadCapacity(getValues("voltage")/2,getValues("calcPoint1.calcKind"),+getValues("calcPoint1.loadCurrent"))
                }
                else{
                    loadCapacity = doSetValueDropLoadCapacity(getValues("voltage"),getValues("calcPoint1.calcKind"),+getValues("calcPoint1.loadCurrent"))

                }
                setValue("calcPoint1.loadCapacity", loadCapacity)
                doSetValueVoltDrop(e,voltage,isConnectTrans1OrTranscott)
            }
            if(+getValues('calcPoint1.loadCapacity') >= 0){
                clearErrors('calcPoint1.loadCapacity')
            }
        }
        else{
            let loadCapacity = 0
            if(getValues("calcPoint0.loadCurrent") >= 0){
                setValue("calcPoint0.loadCurrent", getValues("calcPoint0.loadCurrent")) 
                loadCapacity = doSetValueDropLoadCapacity(getValues("voltage"),getValues("calcPoint0.calcKind"),+getValues("calcPoint0.loadCurrent"))
            }
            setValue("calcPoint0.loadCapacity", loadCapacity)
            doSetValueVoltDrop(e,voltage,isConnectTrans1OrTranscott)
            if(+getValues('calcPoint0.loadCapacity') >= 0){
                clearErrors('calcPoint0.loadCapacity')
            }
        }
    }
    const doSetValueDropLoadCurrent = (voltage:number,nCalcKind:number,loadCapacity:number) =>{
        let loadCurrent = +loadCapacity / voltage
        switch(nCalcKind){
            case CALC_IK3:
                    loadCurrent /= Math.sqrt(3.0)
                    break
            case CALC_IK2:
                break
            case CALC_IK2_OUTSIDE_NEUTRAL:
                break
            case CALC_IK2_OUTSIDE_OUTSIDE:
                break
            case CALC_IK2_SEAT_MAIN:
                break
            case CALC_IK2_SEAT_T:
                break
        }
        return BeamsNumber(loadCurrent,7)
    }
    const onChangeLoadCapacity =(e:any,isConnectTrans1OrTranscott:boolean) =>{
        setValue(e.target.name, e.target.value,{shouldValidate:true})
        let voltage = +getValues("voltage")
        let upTransKind = getValues("upTransKind")
        if(isConnectTrans1OrTranscott){
            setValue("inputedCurrent2",false)
            setValue("inputedCapacity2",true)
        }else{
             setValue("inputedCurrent1",false)
            setValue("inputedCapacity1",true)
        }
        if(isConnectTrans1OrTranscott){ 
            let loadCurrent = 0
            if(+getValues("calcPoint1.loadCapacity") >=0){
                setValue("calcPoint1.loadCapacity", +getValues("calcPoint1.loadCapacity"))
                if(doSetSPhaseConnect() && upTransKind == MS_TRANS1){
                    loadCurrent = doSetValueDropLoadCurrent(getValues("voltage")/2,getValues("calcPoint1.calcKind"),+getValues("calcPoint1.loadCapacity"))
                }
                else{
                    loadCurrent = doSetValueDropLoadCurrent(getValues("voltage"),getValues("calcPoint1.calcKind"),+getValues("calcPoint1.loadCapacity"))
                }
                setValue("calcPoint1.loadCurrent", loadCurrent)
                doSetValueVoltDrop(e,voltage,isConnectTrans1OrTranscott)
            }
            if(+getValues('calcPoint1.loadCurrent') >= 0){
                clearErrors('calcPoint1.loadCurrent')
            }
           
        }
        else{
            if(+getValues("calcPoint0.loadCapacity") >=0){
                let loadCurrent = 0
                loadCurrent = doSetValueDropLoadCurrent(voltage,getValues("calcPoint0.calcKind"),+getValues("calcPoint0.loadCapacity"))
                setValue("calcPoint0.loadCurrent", loadCurrent)
                doSetValueVoltDrop(e,voltage,isConnectTrans1OrTranscott)
            }
            if(+getValues('calcPoint0.loadCurrent') >= 0){
                clearErrors('calcPoint0.loadCurrent')
            }
            
            }
        
    }
    const onBnClickedLead =(e:any,isConnectTrans1OrTranscott:boolean) =>{
        setValue(e.target.name, e.target.value)
        if(isConnectTrans1OrTranscott){ 
           if(getValues("leadOrLag2") == 1){
               setValue("inputedPowerFactor2", true)
               setValue("calcPoint1.isLead",true)
             }
            else if( getValues("leadOrLag2") == 0){
               setValue("inputedPowerFactor2", true)
               setValue("calcPoint1.isLead",false)
            }
        doSetValueVoltDrop(e,+getValues("voltage"),isConnectTrans1OrTranscott)
        clearErrors('calcPoint1.powerFactor')
        

        }
        else{
            if(getValues("leadOrLag1") == 1){
                setValue("inputedPowerFactor1", true)
                setValue("calcPoint0.isLead",true)
              }
             else if( getValues("leadOrLag1") == 0){
                setValue("inputedPowerFactor1", true)
                setValue("calcPoint0.isLead",false)
             }
        doSetValueVoltDrop(e,+getValues("voltage"),isConnectTrans1OrTranscott)
        clearErrors('calcPoint0.powerFactor')

        }
    }
    const onBnClickedTopLeft = (e:any) =>{
        setValue(e.target.name, e.target.value)
    }
    const onChangePowerFactor =(e:any,isConnectTrans1OrTranscott:boolean) =>{
        setValue(e.target.name, e.target.value)
        let upTransKind = getValues("upTransKind")
        if(isConnectTrans1OrTranscott){ 
            setValue("inputedPowerFactor2",true)
            // setValue("calcPoint1.powerFactor",getValues("calcPoint1.powerFactor"))
            doSetValueVoltDrop(e,+getValues("voltage"),isConnectTrans1OrTranscott)
            if(getValues('calcPoint1.powerFactor') >= 0 && getValues('calcPoint1.powerFactor') <= 1 ){
                clearErrors('calcPoint1.powerFactor')
            }


        }
        else{
            setValue("inputedPowerFactor1",true)
            // setValue("calcPoint0.powerFactor",+getValues("calcPoint0.powerFactor"))
            doSetValueVoltDrop(e,+getValues("voltage"),isConnectTrans1OrTranscott)
            if(getValues('calcPoint0.powerFactor') >= 0 && getValues('calcPoint0.powerFactor') <= 1 ){
                clearErrors('calcPoint0.powerFactor')
            }

        }

    }
    const doGetTextLoadCurrent =(nPartKind:number) =>{
        let loadCurrent = 0
          if(doSetSPhaseConnect()){
            switch(nPartKind){
                case MS_OUTSIDE_NEUTRAL:
                case MS_SEAT_MAIN:
                    loadCurrent = +getValues("calcPoint1.loadCurrent")
                    break;
                case MS_OUTSIDE_OUTSIDE:
                case MS_SEAT_T:
                    loadCurrent = +getValues("calcPoint0.loadCurrent")
                    break
            }
          }
          else{
            if(getValues("downTransKind") === MS_TRANS1){
                loadCurrent = +getValues("calcPoint1.loadCurrent")
            }
            else{
                loadCurrent = +getValues("calcPoint0.loadCurrent")
            }
          }
          return loadCurrent
    }
    const doGetTextDropLoadCapacity =(loadCapacity:number,bUnit:boolean) =>{
        let text = BeamsNumber(loadCapacity,7).toString()
        if(bUnit){
            text += "A"
        }
        return text
    }
    const onBnClickGetLoadCurrent =(e:any,isConnectTrans1OrTranscott:boolean) =>{
        setValue(e.target.name, e.target.value)
        
        let upTransKind = getValues("upTransKind")
        if(isConnectTrans1OrTranscott){ 
            let loadCurrent = 0
            let loadCapacity = 0
            clearErrors("calcPoint1.loadCapacity")
            clearErrors("calcPoint1.loadCurrent")
            setValue("inputedCapacity2", false)
            setValue("inputedCurrent2",false)
            setValue("calcPoint1.loadCurrent",dispInputDialog.load_current_1)
            if(doSetSPhaseConnect()){
                switch(getValues("upTransKind")){
                    case MS_TRANS1: 
                       loadCurrent = doGetTextLoadCurrent(MS_OUTSIDE_NEUTRAL)
                       break
                    case MS_TRANSSCOTT:
                       loadCurrent = doGetTextLoadCurrent(MS_SEAT_MAIN)
                        break
                }
            }
            else{
                loadCurrent = doGetTextLoadCurrent(MS_3PHASES)
            }
            if(doSetSPhaseConnect() && upTransKind == MS_TRANS1){
                loadCapacity = doSetValueDropLoadCapacity(getValues("voltage")/2,getValues("calcPoint1.calcKind"),+getValues("calcPoint1.loadCurrent"))
            }
            else{
                loadCapacity = doSetValueDropLoadCapacity(getValues("voltage"),getValues("calcPoint1.calcKind"),+getValues("calcPoint1.loadCurrent"))

            }
            setValue("calcPoint1.loadCapacity",+doGetTextDropLoadCapacity(loadCapacity,false))
            setValue("calcPoint1.loadCurrent",BeamsNumber(loadCurrent,5))
            doSetValueVoltDrop(e,getValues("voltage"),isConnectTrans1OrTranscott);

        }
        else{
            clearErrors("calcPoint0.loadCapacity")
            clearErrors("calcPoint0.loadCurrent")
            let loadCurrent = 0
            let loadCapacity = 0
            setValue("inputedCapacity1", false)
            setValue("inputedCurrent1",false)
            setValue("calcPoint0.loadCurrent",dispInputDialog.load_current_0)
            if(doSetSPhaseConnect()){
                switch(getValues("upTransKind")){
                    case MS_TRANS1: 
                       loadCurrent = doGetTextLoadCurrent(MS_OUTSIDE_OUTSIDE)
                       break
                    case MS_TRANSSCOTT:
                       loadCurrent = doGetTextLoadCurrent(MS_SEAT_T)
                        break
                }
            }
            else{
                loadCurrent = doGetTextLoadCurrent(MS_3PHASES)
            }
            loadCapacity = doSetValueDropLoadCapacity(getValues("voltage"),getValues("calcPoint0.calcKind"),+getValues("calcPoint0.loadCurrent"))
            setValue("calcPoint0.loadCapacity",+doGetTextDropLoadCapacity(loadCapacity,false))
            setValue("calcPoint0.loadCurrent",BeamsNumber(loadCurrent,5))
            doSetValueVoltDrop(e,getValues("voltage"),isConnectTrans1OrTranscott)
        }
    }
    const doGetTextDropVoltPF = (nPartKind:number) =>{
        let powerFactor = 0
        if(doSetSPhaseConnect()){
            switch(nPartKind){
               case MS_OUTSIDE_NEUTRAL:
               case MS_SEAT_MAIN:
                   powerFactor = getValues("calcPoint1.powerFactor")
                  break;
                case MS_OUTSIDE_OUTSIDE:
                case MS_SEAT_T:
                   powerFactor = getValues("calcPoint0.powerFactor")
                  break;
            }
        }
        else{
            if(getValues("downTransKind") === MS_TRANS1){
                powerFactor = getValues("calcPoint1.powerFactor")
            }
            else{
                powerFactor = getValues("calcPoint0.powerFactor")
            }
        }
        return powerFactor
    }
    const onBnClickGetPowerFactor =(e:any,isConnectTrans1OrTranscott:boolean) =>{
        setValue(e.target.name, e.target.value)
        if(isConnectTrans1OrTranscott){ 
            clearErrors("calcPoint1.powerFactor")
            let powerFactor = 0
            setValue("inputedCapacity2",false)
            setValue("calcPoint1.powerFactor",dispInputDialog.power_factor_1)
            setValue("calcPoint1.isLead",dispInputDialog.is_lead_1)
        switch(getValues("calcPoint1.isLead")){
            case true:
                setValue("leadOrLag2",1)
                break
            default:
                setValue("leadOrLag2",0)
                break

        }
        if(doSetSPhaseConnect()){
            switch(getValues("upTransKind")){
                case MS_TRANS1:
                    powerFactor = doGetTextDropVoltPF(MS_OUTSIDE_NEUTRAL)
                    break
                case MS_TRANSSCOTT:
                    powerFactor = doGetTextDropVoltPF(MS_SEAT_MAIN)
                       break
            }
        }
        else{
            powerFactor = doGetTextDropVoltPF(MS_3PHASES)
        }
        setValue("calcPoint1.powerFactor",powerFactor)
        doSetValueVoltDrop(e,+getValues("voltage"),isConnectTrans1OrTranscott)
        }
        else{
            clearErrors("calcPoint0.powerFactor")
            let powerFactor = 0
            setValue("inputedCapacity1",false)
            setValue("calcPoint0.powerFactor",dispInputDialog.power_factor_0)
            setValue("calcPoint0.isLead",dispInputDialog.is_lead_0)
            switch(getValues("calcPoint0.isLead")){
                case true:
                    setValue("leadOrLag1",1)
                    break
                default:
                    setValue("leadOrLag1",0)
                    break

            }
            if(doSetSPhaseConnect()){
                switch(getValues("upTransKind")){
                    case MS_TRANS1:
                        powerFactor = doGetTextDropVoltPF(MS_OUTSIDE_OUTSIDE)
                        break
                    case MS_TRANSSCOTT:
                        powerFactor = doGetTextDropVoltPF(MS_SEAT_T)
                        break
                }
            }
            else{
                powerFactor = doGetTextDropVoltPF(MS_3PHASES)
            }
            setValue("calcPoint0.powerFactor",powerFactor)
            doSetValueVoltDrop(e,+getValues("voltage"),isConnectTrans1OrTranscott)
    }
    }
    const onChangeVoltage =(e:any) =>{
        setValue(e.target.name, e.target.value,{shouldValidate:true})
        if(setIsDisplayTabConnectTran1 || setIsDisplayTabConnectTranscott){
            doSetValueVoltDrop(e,+getValues("voltage"),true)
            doSetValueVoltDrop(e,+getValues("voltage"),false)
        }
        else{
            doSetValueVoltDrop(e,+getValues("voltage"),false)
        }
    }

    //On change radio button: materialCuAl,bareOrPainted,verticalOrHorizonal
    const onChangeRadioButtonTab1 = (e:any) =>{
        setValue(e.target.name,e.target.value)
        const filterValue3Phase = doSetFillterValues(getValues('m_3Phase'),getValues('busBarList'))
        DoCalcSetZValues(infoCircuit.frequency,getValues(),getValues('m_3Phase'),filterValue3Phase)
        DoCalcSetIValues(getValues(),getValues('m_3Phase'),filterValue3Phase)
        doSetZValuesBusBar("",1)
        doSetIValuesBusBar("",1)

        const filterValuePhaseN = doSetFillterValues(getValues('phaseN'),getValues('busBarList'))
        DoCalcSetZValues(infoCircuit.frequency,getValues(),getValues('phaseN'),filterValuePhaseN)
        DoCalcSetIValues(getValues(),getValues('phaseN'),filterValuePhaseN)
        doSetZValuesBusBar("",2)
        doSetIValuesBusBar("",2)

        const filterValuePhasePE = doSetFillterValues(getValues('phaseNPE'),getValues('busBarList'))
        DoCalcSetZValues(infoCircuit.frequency,getValues(),getValues('phaseNPE'),filterValuePhasePE)
        DoCalcSetIValues(getValues(),getValues('phaseNPE'),filterValuePhasePE)
        doSetZValuesBusBar("",3)
        doSetIValuesBusBar("",3)

    } 
    const doSetFilterByLimit = (width:number,dLimitWidth:number,dLimitThickness:number = 0) =>{
        const busBarList = getValues('busBarList')
        let filter = []
        if(width > 0 && dLimitWidth > 0 && dLimitThickness > 0){
            for(const item of busBarList){
                if(item.nwidth >= dLimitWidth && item.nthickness >= dLimitThickness ){
                    filter.push(item)
                }
            }
        }
        else if(width > 0 && dLimitWidth > 0){
            for(const item of busBarList){
                if(item.nwidth >= dLimitWidth){
                    filter.push(item)
                }
            }
        }
        else if(width > 0 && dLimitThickness > 0){
            for(const item of busBarList){
                if(item.nwidth == width && item.nthickness >= dLimitThickness){
                    filter.push(item)
                }
            }
        }
        else if(dLimitThickness > 0 && dLimitWidth > 0){
            for(const item of busBarList){
                if(item.nthickness >= dLimitThickness && item.nwidth >= dLimitWidth){
                    filter.push(item)
                }
            }
        }
        else if(width > 0){
            for(const item of busBarList){
                if(item.nwidth == width){
                    filter.push(item)
                }
            }
        }
        else if(dLimitWidth > 0){
            for(const item of busBarList){
                if(item.nwidth >= dLimitWidth){
                    filter.push(item)
                }
            }
        }
        else if(dLimitThickness > 0){
            for(const item of busBarList){
                if(item.nthickness >= dLimitThickness){
                    filter.push(item)
                }
            }
        }
        return filter
    }
    const doInitComboThickness = (width:number,kindPhase:number,dLimitThickness:number = 0) =>{
        if(kindPhase === 1){
            let filterValue = doSetFilterByLimit(width,0,dLimitThickness)
            let thicknessList:any = [];
                    filterValue?.forEach((item:any)=>{
                if(!thicknessList?.map((o:any) => o.value).includes(item.nthickness)){
                    thicknessList.push({label:item.nthickness.toString(),
                        value:item.nthickness} as OptionModel)
                    }
                })
                setValue('m_3Phase.thicknessList',thicknessList)
                let thickness1 = getValues('m_3Phase.thickness')
                let thickness = -1
                if(thicknessList.length > 0){
                    for(const item of thicknessList){
                        if(item.value == thickness1){
                            thickness = item.value
                            break;
                        }
                        else{
                            thickness = thicknessList[0].value
                        }
                    }
                }
            setValue('m_3Phase.thickness',thickness)
        }
        if(kindPhase === 2 ){
            let limitThickness = (+getValues('m_3Phase.width') * +getValues('m_3Phase.thickness') * +getValues('m_3Phase.numberPhase') / 2) /
                        (+getValues('phaseN.width') * +getValues('phaseN.numberPhase'));
            let filterValue = doSetFilterByLimit(+getValues('phaseN.width'),0,Math.floor(limitThickness))
            let thicknessList:any = [];
                    filterValue?.forEach((item:any)=>{
                if(!thicknessList?.map((o:any) => o.value).includes(item.nthickness)){
                    thicknessList.push({label:item.nthickness.toString(),
                        value:item.nthickness} as OptionModel)
                    }
                })
            setValue('phaseN.thicknessList',thicknessList)
                let thickness1 = getValues('phaseN.thickness')
                let thickness = -1
                if(thicknessList.length > 0){
                    for(const item of thicknessList){
                        if(item.value == thickness1){
                            thickness = item.value
                            break;
                        }
                        else{
                            thickness = thicknessList[0].value
                        }
                    }
                }

            // let thickness = thicknessList.length > 0?thicknessList[0].value:   -1
            //     for(const item of filterValue){
            //         if(item.bisDefaultt){
            //             thickness = item.nthickness
            //             break
            //         }
            //         // else{
            //         //     thickness = item.nthickness
            //         // }
            //     }
            setValue('phaseN.thickness',thickness)
        }
        if(kindPhase === 3 ){
            let limitThickness = (getValues('m_3Phase.width') * getValues('m_3Phase.thickness') * getValues('m_3Phase.numberPhase') / 2) /
                        (getValues('phaseNPE.width') * getValues('phaseNPE.numberPhase'));
            let filterValue = doSetFilterByLimit(width,0,Math.floor(limitThickness))
            let thicknessList:any = [];
                    filterValue?.forEach((item:any)=>{
                if(!thicknessList?.map((o:any) => o.value).includes(item.nthickness)){
                    thicknessList.push({label:item.nthickness.toString(),
                        value:item.nthickness} as OptionModel)
                    }
                })
            setValue('phaseNPE.thicknessList',thicknessList)
            let thickness1 = getValues('phaseNPE.thickness')
                let thickness = -1
                if(thicknessList.length > 0){
                    for(const item of thicknessList){
                        if(item.value == thickness1){
                            thickness = item.value
                            break;
                        }
                        else{
                            thickness = thicknessList[0].value
                        }
                    }
                }
                // let thickness = thicknessList.length > 0?thicknessList[0].value:   -1
                // for(const item of filterValue){
                //     if(item.bisDefaultt){
                //         thickness = item.nthickness
                //         break
                //     }
                //     // else{
                //     //     thickness = item.nthickness
                //     // }
                // }
            setValue('phaseNPE.thickness',thickness)
        }
    }
    const doSetFilterByValue = (width:number,thickness:number,dLimitNumber:number = 0) =>{
        const busBarList = getValues('busBarList')
        let filter = []
        if(width > 0 && thickness > 0 && dLimitNumber > 0){
            for(const item of busBarList){
                if(item.nwidth == width && item.nthickness == thickness && item.nnumber == dLimitNumber ){
                    filter.push(item)
                }
            }
        }
        else if(width > 0 && thickness > 0){
            for(const item of busBarList){
                if(item.nwidth == width && item.nthickness == thickness){
                    filter.push(item)
                }
            }
        }
        else if(width > 0 && dLimitNumber > 0){
            for(const item of busBarList){
                if(item.nwidth == width && item.nnumber == dLimitNumber){
                    filter.push(item)
                }
            }
        }
        else if(thickness > 0 && dLimitNumber > 0){
            for(const item of busBarList){
                if(item.nthickness == thickness && item.nnumber == dLimitNumber){
                    filter.push(item)
                }
            }
        }
        else if(width > 0){
            for(const item of busBarList){
                if(item.nwidth == width){
                    filter.push(item)
                }
            }
        }
        else if(thickness > 0){
            for(const item of busBarList){
                if(item.nthickness == thickness){
                    filter.push(item)
                }
            }
        }
        else if(dLimitNumber > 0){
            for(const item of busBarList){
                if(item.nnumber == dLimitNumber){
                    filter.push(item)
                }
            }
        }
        return filter
    }
    const doInitComboNumber = (width:number,kindPhase:number) =>{
        if(kindPhase === 1){
            let filterValue = doSetFilterByValue(width,+getValues('m_3Phase.thickness'),0)
            let numberPhaseList:any = [];
            filterValue?.forEach((item:any)=>{
                if(!numberPhaseList?.map((o:any) => o.value).includes(item.nnumber)){
                        numberPhaseList.push({label:item.nnumber.toString(),
                                        value:item.nnumber} as OptionModel)
                }
            })
            setValue('m_3Phase.numberPhaseList',numberPhaseList.sort((number1:any,number2:any)=>number1.value - number2.value))

            let nnumber1 = getValues('m_3Phase.numberPhase')
            let nnumber = -1
            if(numberPhaseList.length > 0){
                for(const item of numberPhaseList){
                    if(item.value == nnumber1){
                        nnumber = item.value
                        break;
                    }
                    else{
                        nnumber = numberPhaseList[0].value
                    }
                }
            }
            // let nnumber = numberPhaseList.length > 0? numberPhaseList[0].value: -1
            // for(const item of filterValue){
            //     if(item.bisDefaultn){
            //         nnumber = item.nnumber
            //         break
            //     }
                // else{
                //     nnumber = item.nnumber
                // }
            // }
            setValue('m_3Phase.numberPhase',nnumber)

        }
        else if(kindPhase === 2){
            const dLimitNumber = (+getValues('m_3Phase.width') * +getValues('m_3Phase.thickness') * +getValues('m_3Phase.numberPhase') / 2) /
            (+getValues('phaseN.width') * +getValues('phaseN.thickness'));
            let filterValue = doSetFilterByValue(+getValues('phaseN.width'),+getValues('phaseN.thickness'),0)
            let numberPhaseList:any = [];
            filterValue?.forEach((item:any)=>{
                if(!numberPhaseList?.map((o:any) => o.value).includes(item.nnumber)){
                    if(item.nnumber >= dLimitNumber){
                        numberPhaseList.push({label:item.nnumber.toString(),
                                        value:item.nnumber} as OptionModel)
                        // if(item.bisDefaultn){
                        //     nnumber = item.nnumber
                        // }
                        // else{
                        //     nnumber = numberPhaseList[0].value
                        // }
                    }
                }
            })
            setValue('phaseN.numberPhaseList',numberPhaseList.sort((number1:any,number2:any)=>number1.value - number2.value))
            let nnumber1 = getValues('m_3Phase.numberPhase')
            let nnumber = -1
            if(numberPhaseList.length > 0){
                for(const item of numberPhaseList){
                    if(item.value == nnumber1){
                        nnumber = item.value
                        break;
                    }
                    else{
                        nnumber = numberPhaseList[0].value
                    }
                }
            }
            // for(const item of filterValue){
            //     if(item.bisDefaultn){
            //         nnumber = item.nnumber
            //         break
            //     }
            //     else{
            //         nnumber = item.nnumber
            //     }
            // }
                setValue('phaseN.numberPhase',nnumber)
        }
        else if(kindPhase === 3){
            const dLimitNumber = (+getValues('m_3Phase.width') * +getValues('m_3Phase.thickness') * +getValues('m_3Phase.numberPhase') / 2) /
            (+getValues('phaseNPE.width') * +getValues('phaseNPE.thickness'));
            let filterValue = doSetFilterByValue(+getValues('phaseNPE.width') ,+getValues('phaseNPE.thickness'),0)
            let numberPhaseList:any = [];
            filterValue?.forEach((item:any)=>{
                if(!numberPhaseList?.map((o:any) => o.value).includes(item.nnumber)){
                    if(item.nnumber >= dLimitNumber){
                        numberPhaseList.push({label:item.nnumber.toString(),
                                        value:item.nnumber} as OptionModel)
                        // if(item.bisDefaultn){
                        //     nnumber = item.nnumber
                        // }
                        // else{
                        //     nnumber = numberPhaseList[0].value
                        // }
                    }
                }
            })
            setValue('phaseNPE.numberPhaseList',numberPhaseList.sort((number1:any,number2:any)=>number1.value - number2.value))
            let nnumber1 = getValues('m_3Phase.numberPhase')
            let nnumber = -1
            if(numberPhaseList.length > 0){
                for(const item of numberPhaseList){
                    if(item.value == nnumber1){
                        nnumber = item.value
                        break;
                    }
                    else{
                        nnumber = numberPhaseList[0].value
                    }
                }
            }
            // for(const item of filterValue){
            //     if(item.bisDefaultn){
            //         nnumber = item.nnumber
            //         break
            //     }
            //     else{
            //         nnumber = item.nnumber
            //     }
            // }
                setValue('phaseNPE.numberPhase',nnumber)
        }
    }
    const doSetZValuesBusBar = (nControl:string,kindPhase:number) =>{
        if(kindPhase === 1){
            if(nControl !== 'distance'){
               setValue("m_3Phase.distance",getValues('m_3Phase.distance'))
            }
            if(nControl !== 'skinEffectM'){
                setValue("m_3Phase.skinEffectM",BeamsNumber(getValues('m_3Phase.skinEffectM'),3))
            }
            if(nControl !== 'ohmRPerM'){
                setValue("m_3Phase.ohmRPerM",BeamsNumber(getValues('m_3Phase.ohmRPerM'),4))
            }
            if(nControl !== 'ohmXPerM'){
                setValue("m_3Phase.ohmXPerM",BeamsNumber(getValues('m_3Phase.ohmXPerM'),4))
            }
            setValue("m_3Phase.ohmR",BeamsNumber(getValues('m_3Phase.ohmRPerM') * getValues('lenght'),4))
            setValue("m_3Phase.ohmX",BeamsNumber(getValues('m_3Phase.ohmXPerM') * getValues('lenght'),4))
        }
        else if(kindPhase === 2){
            if(nControl !== 'distance'){
                setValue("phaseN.distance",getValues('phaseN.distance'))
             }
             if(nControl !== 'skinEffectM'){
                 setValue("phaseN.skinEffectM",BeamsNumber(getValues('phaseN.skinEffectM'),3))
             }
             if(nControl !== 'ohmRPerM'){
                 setValue("phaseN.ohmRPerM",BeamsNumber(getValues('phaseN.ohmRPerM'),4))
             }
             if(nControl !== 'ohmXPerM'){
                 setValue("phaseN.ohmXPerM",BeamsNumber(getValues('phaseN.ohmXPerM'),4))
             }
            setValue("phaseN.ohmR",BeamsNumber(getValues('phaseN.ohmRPerM') * getValues('lenght'),4))
            setValue("phaseN.ohmX",BeamsNumber(getValues('phaseN.ohmXPerM') * getValues('lenght'),4))
        }
        else if(kindPhase === 3){
            if(nControl !== 'distance'){
                setValue("phaseNPE.distance",getValues('phaseNPE.distance'))
             }
             if(nControl !== 'skinEffectM'){
                 setValue("phaseNPE.skinEffectM",BeamsNumber(getValues('phaseNPE.skinEffectM'),3))
             }
             if(nControl !== 'ohmRPerM'){
                 setValue("phaseNPE.ohmRPerM",BeamsNumber(getValues('phaseNPE.ohmRPerM'),4))
             }
             if(nControl !== 'ohmXPerM'){
                 setValue("phaseNPE.ohmXPerM",BeamsNumber(getValues('phaseNPE.ohmXPerM'),4))
             }
            setValue("phaseNPE.ohmR",BeamsNumber(getValues('phaseNPE.ohmRPerM') * getValues('lenght'),4))
            setValue("phaseNPE.ohmX",BeamsNumber(getValues('phaseNPE.ohmXPerM') * getValues('lenght'),4))
        }


    }
    const doSetIValuesBusBar = (nControl:string,kindPhase:number) =>{
        if(kindPhase === 1){
            if(nControl !== 'basicCapacityM'){
                setValue("m_3Phase.basicCapacityM",BeamsNumber(getValues('m_3Phase.basicCapacityM'),4))
            }
            if(nControl !== 'tempK2M'){
                setValue("m_3Phase.tempK2M",BeamsNumber(getValues('m_3Phase.tempK2M'),3))
            }
            if(nControl !== 'horizontalK3M'){
                setValue("m_3Phase.horizontalK3M",BeamsNumber(getValues('m_3Phase.horizontalK3M'),3))
            }
            if(nControl !== 'proximityK4M'){
                setValue("m_3Phase.proximityK4M",BeamsNumber(getValues('m_3Phase.proximityK4M'),3))
            }
            setValue("m_3Phase.capacity",doGetValueCapacity(getValues('m_3Phase')))
        }
        else if(kindPhase === 2){
            if(nControl !== 'basicCapacityM'){
                setValue("phaseN.basicCapacityM",BeamsNumber(getValues('phaseN.basicCapacityM'),4))
            }
            if(nControl !== 'tempK2M'){
                setValue("phaseN.tempK2M",BeamsNumber(getValues('phaseN.tempK2M'),3))
            }
            if(nControl !== 'horizontalK3M'){
                setValue("phaseN.horizontalK3M",BeamsNumber(getValues('phaseN.horizontalK3M'),3))
            }
            if(nControl !== 'proximityK4M'){
                setValue("phaseN.proximityK4M",BeamsNumber(getValues('phaseN.proximityK4M'),3))
            }
            setValue("phaseN.capacity",doGetValueCapacity(getValues('phaseN')))
        }
        else if(kindPhase === 3){
            if(nControl !== 'basicCapacityM'){
                setValue("phaseNPE.basicCapacityM",BeamsNumber(getValues('phaseNPE.basicCapacityM'),4))
            }
            if(nControl !== 'tempK2M'){
                setValue("phaseNPE.tempK2M",BeamsNumber(getValues('phaseNPE.tempK2M'),3))
            }
            if(nControl !== 'horizontalK3M'){
                setValue("phaseNPE.horizontalK3M",BeamsNumber(getValues('phaseNPE.horizontalK3M'),3))
            }
            if(nControl !== 'proximityK4M'){
                setValue("phaseNPE.proximityK4M",BeamsNumber(getValues('phaseNPE.proximityK4M'),3))
            }
            setValue("phaseNPE.capacity",doGetValueCapacity(getValues('phaseNPE')))
        }
    }
    const doInitComboWidth = (kindPhase:number) =>{
        if(kindPhase == 2){
        const limitWidth = (getValues('m_3Phase.width') * getValues('m_3Phase.thickness') * getValues('m_3Phase.numberPhase') / 2) /
                  (getValues('phaseN.thickness') * getValues('phaseN.numberPhase'))
          const filterValue = doSetFilterByLimit (0,limitWidth,0)
          let widthList:OptionModel[] = [];
          filterValue?.forEach((item:any)=>{
                if(!widthList?.map((o:any) => o.value).includes(item.nwidth)){
                    widthList.push({label:item.nwidth.toString(),
                        value:item.nwidth} as OptionModel)
              }
          })
          setValue('phaseN.widthList',widthList)
        }
        else if(kindPhase == 3){
          const limitWidth = (getValues('m_3Phase.width') * getValues('m_3Phase.thickness') * getValues('m_3Phase.numberPhase') / 2) /
                  (getValues('phaseNPE.thickness') * getValues('phaseNPE.numberPhase'))
          const filterValue = doSetFilterByLimit (0,limitWidth,0)
          let widthList:OptionModel[] = [];
          filterValue?.forEach((item:any)=>{
                if(!widthList?.map((o:any) => o.value).includes(item.nwidth)){
                    widthList.push({label:item.nwidth.toString(),
                        value:item.nwidth} as OptionModel)
              }
          })
          setValue('phaseNPE.widthList',widthList)
        }
        
      }
    const doReadSetDefaultNumber = (kindPhase:number,phase:IeleBusbarSPhase,dLimitNumber:number = 0) =>{
        if(kindPhase == 2){
            let filterValue = doSetFilterByValue(+phase.width,+phase.thickness,0)
            let numberPhaseList:any = [];
            let nnumber = -1
                filterValue?.forEach((item:any)=>{
                    if(!numberPhaseList?.map((o:any) => o.value).includes(item.nnumber)){
                        if(item.nnumber >= dLimitNumber){
                            numberPhaseList.push({label:item.nnumber.toString(),
                                            value:item.nnumber} as OptionModel)
                            if(item.bisDefaultn){
                                nnumber = item.nnumber
                            }
                            else{
                                nnumber = numberPhaseList[0].value
                            }
                        }
                    }
                })
                setValue('phaseN.numberPhaseList',numberPhaseList.sort((number1:any,number2:any)=>number1.value - number2.value))
                setValue('phaseN.numberPhase',nnumber)
        }
        else if( kindPhase == 3){
            let filterValue = doSetFilterByValue(+phase.width,+phase.thickness,0)
            let numberPhaseList:any = [];
            let nnumber = -1
                filterValue?.forEach((item:any)=>{
                    if(!numberPhaseList?.map((o:any) => o.value).includes(item.nnumber)){
                        if(item.nnumber >= dLimitNumber){
                            numberPhaseList.push({label:item.nnumber.toString(),
                                            value:item.nnumber} as OptionModel)
                            if(item.bisDefaultn){
                                nnumber = item.nnumber
                            }
                            else{
                                nnumber = numberPhaseList[0].value
                            }
                        }
                    }
                })
                setValue('phaseNPE.numberPhaseList',numberPhaseList.sort((number1:any,number2:any)=>number1.value - number2.value))
                setValue('phaseNPE.numberPhase',nnumber)
        }
    }
    //On change: width, thickness, number
    const onChangeEditChangeWidth = (e:any,kindPhase:number) =>{
        setValue(e.target.name,e.target.value)
        if(kindPhase === 1){
            if(!/^[0-9]*[.]?[0-9]+$/.test(e.target.value.toString())){
                setValue('m_3Phase.skinEffectM',-1)
                setValue('m_3Phase.ohmRPerM',-1)
                setValue('m_3Phase.ohmXPerM',-1)
                setValue('m_3Phase.basicCapacityM',-1)
                setValue('m_3Phase.proximityK4M',-1)
            }
            else{
                if(+e.target.value > 0){
                    setValue(e.target.name,+e.target.value)
                    if(e.target.name !== 'm_3Phase.thickness' && e.target.name!== 'm_3Phase.numberPhase'){
                        doInitComboThickness(+getValues('m_3Phase.width'),kindPhase);

                    }
                    if(e.target.name !==  'm_3Phase.numberPhase'){
                         doInitComboNumber(+getValues('m_3Phase.width'),kindPhase);
                    }
                    const filterValue3Phase = doSetFillterValues(getValues('m_3Phase'),getValues('busBarList'))
                    DoCalcSetZValues(infoCircuit.frequency,getValues(),getValues('m_3Phase'),filterValue3Phase)
                    DoCalcSetIValues(getValues(),getValues('m_3Phase'),filterValue3Phase)
                }
                else{
                    setValue('m_3Phase.skinEffectM',-1)
                    setValue('m_3Phase.ohmRPerM',-1)
                    setValue('m_3Phase.ohmXPerM',-1)
                    setValue('m_3Phase.basicCapacityM',-1)
                    setValue('m_3Phase.proximityK4M',-1)
                }
            }
            doSetZValuesBusBar("",1)
            doSetIValuesBusBar("",1)

            setValue('phaseN.width',+getValues('m_3Phase.width'))
            setValue('phaseN.thickness',+getValues('m_3Phase.thickness'))
            setValue('phaseN.numberPhase',+getValues('m_3Phase.numberPhase'))

            doInitComboWidth(2)
            doInitComboThickness(+getValues('m_3Phase.width'),2);
            doInitComboNumber(+getValues('m_3Phase.width'),2);
            

            const filterValuePhaseN = doSetFillterValues(getValues('phaseN'),getValues('busBarList'))
            DoCalcSetZValues(infoCircuit.frequency,getValues(),getValues('phaseN'),filterValuePhaseN)
            DoCalcSetIValues(getValues(),getValues('phaseN'),filterValuePhaseN)
            doSetZValuesBusBar("",2)
            doSetIValuesBusBar("",2)


            setValue('phaseNPE.width',+getValues('m_3Phase.width'))
            setValue('phaseNPE.thickness',+getValues('m_3Phase.thickness'))
            setValue('phaseNPE.numberPhase',+getValues('m_3Phase.numberPhase'))
            doInitComboWidth(3)
            doInitComboThickness(+getValues('m_3Phase.width'),3);
            doInitComboNumber(+getValues('m_3Phase.width'),3);
            

            const filterValuePhasePE = doSetFillterValues(getValues('phaseNPE'),getValues('busBarList'))
            DoCalcSetZValues(infoCircuit.frequency,getValues(),getValues('phaseNPE'),filterValuePhasePE)
            DoCalcSetIValues(getValues(),getValues('phaseNPE'),filterValuePhasePE)
            doSetZValuesBusBar("",3)
            doSetIValuesBusBar("",3)
            clearErrorsBusbar()

        }
        if(kindPhase === 2){
            if(e.target.name == 'phaseN.thickness' && typeof e.target.value == 'number'){

                doReadSetDefaultNumber(kindPhase,getValues('phaseN'),0)
                const filterValue3Phase = doSetFillterValues(getValues('phaseN'),getValues('busBarList'))
                DoCalcSetZValues(infoCircuit.frequency,getValues(),getValues('phaseN'),filterValue3Phase)
                DoCalcSetIValues(getValues(),getValues('phaseN'),filterValue3Phase)

            }
            else{
                if(!/^[0-9]*[.]?[0-9]+$/.test(e.target.value.toString())){
                    setValue('phaseN.skinEffectM',-1)
                    setValue('phaseN.ohmRPerM',-1)
                    setValue('phaseN.ohmXPerM',-1)
                    setValue('phaseN.basicCapacityM',-1)
                    setValue('phaseN.proximityK4M',-1)
                }
                else{
                    if(+e.target.value > 0){
                        setValue(e.target.name,+e.target.value)
                        if(e.target.name !== 'phaseN.thickness' && e.target.name!== 'phaseN.numberPhase'){
                            doInitComboThickness(getValues('phaseN.width'),kindPhase);
                        }
                        if( e.target.name!== 'phaseN.numberPhase'){
                            doInitComboNumber(getValues('phaseN.width'),kindPhase);
                        }
                        const filterValue3Phase = doSetFillterValues(getValues('phaseN'),getValues('busBarList'))
                        DoCalcSetZValues(infoCircuit.frequency,getValues(),getValues('phaseN'),filterValue3Phase)
                        DoCalcSetIValues(getValues(),getValues('phaseN'),filterValue3Phase)
                    }
                    else{
                        setValue('phaseN.skinEffectM',-1)
                        setValue('phaseN.ohmRPerM',-1)
                        setValue('phaseN.ohmXPerM',-1)
                        setValue('phaseN.basicCapacityM',-1)
                        setValue('phaseN.proximityK4M',-1)
                    }
                }
            }
            doSetZValuesBusBar("",2)
            doSetIValuesBusBar("",2)
            clearErrorsBusbar()
        }
        if(kindPhase === 3){
            if(e.target.name == 'phaseNPE.thickness' && typeof e.target.value == 'number'){

                doReadSetDefaultNumber(kindPhase,getValues('phaseNPE'),0)
                const filterValue3Phase = doSetFillterValues(getValues('phaseNPE'),getValues('busBarList'))
                DoCalcSetZValues(infoCircuit.frequency,getValues(),getValues('phaseNPE'),filterValue3Phase)
                DoCalcSetIValues(getValues(),getValues('phaseNPE'),filterValue3Phase)
            }
            else{
                if(!/^[0-9]*[.]?[0-9]+$/.test(e.target.value.toString())){
                    setValue('phaseNPE.skinEffectM',-1)
                    setValue('phaseNPE.ohmRPerM',-1)
                    setValue('phaseNPE.ohmXPerM',-1)
                    setValue('phaseNPE.basicCapacityM',-1)
                    setValue('phaseNPE.proximityK4M',-1)
                }
                else{
                    if(+e.target.value > 0){
                        setValue(e.target.name,+e.target.value)
                        if(e.target.name !== 'phaseNPE.thickness' && e.target.name!== 'phaseNPE.numberPhase'){
                            doInitComboThickness(getValues('phaseNPE.width'),kindPhase);
                        }
                        if(e.target.name !== 'phaseNPE.numberPhase'){
                            doInitComboNumber(getValues('phaseNPE.width'),kindPhase);
                        }
                        const filterValue3Phase = doSetFillterValues(getValues('phaseNPE'),getValues('busBarList'))
                        DoCalcSetZValues(infoCircuit.frequency,getValues(),getValues('phaseNPE'),filterValue3Phase)
                        DoCalcSetIValues(getValues(),getValues('phaseNPE'),filterValue3Phase)
                    }
                    else{
                        setValue('phaseNPE.skinEffectM',-1)
                        setValue('phaseNPE.ohmRPerM',-1)
                        setValue('phaseNPE.ohmXPerM',-1)
                        setValue('phaseNPE.basicCapacityM',-1)
                        setValue('phaseNPE.proximityK4M',-1)
                    }
                }
            }
            doSetZValuesBusBar("",3)
            doSetIValuesBusBar("",3)
            clearErrorsBusbar()
        }
    }
    
    const onChangeDistance =(e:any,kindPhase :number)=>{
        setValue(e.target.name,e.target.value)
        if(kindPhase == 1){
            const filterValue3Phase = doSetFillterValues(getValues('m_3Phase'),getValues('busBarList'))
            DoCalcSetZValues(infoCircuit.frequency,getValues(),getValues('m_3Phase'),filterValue3Phase)
            DoCalcSetIValues(getValues(),getValues('m_3Phase'),filterValue3Phase)
            doSetZValuesBusBar("distance",1)
            doSetIValuesBusBar("distance",1)
            
            setValue('phaseN.distance',getValues('m_3Phase.distance'))
            const filterValuePhaseN = doSetFillterValues(getValues('phaseN'),getValues('busBarList'))
            DoCalcSetZValues(infoCircuit.frequency,getValues(),getValues('phaseN'),filterValuePhaseN)
            DoCalcSetIValues(getValues(),getValues('phaseN'),filterValuePhaseN)
            doSetZValuesBusBar("",2)
            doSetIValuesBusBar("",2)

            setValue('phaseNPE.distance',getValues('m_3Phase.distance'))
            const filterValuePhasePE = doSetFillterValues(getValues('phaseNPE'),getValues('busBarList'))
            DoCalcSetZValues(infoCircuit.frequency,getValues(),getValues('phaseNPE'),filterValuePhasePE)
            DoCalcSetIValues(getValues(),getValues('phaseNPE'),filterValuePhasePE)
            doSetZValuesBusBar("",3)
            doSetIValuesBusBar("",3)
            clearErrorsBusbar()
        }
        else if(kindPhase == 2){
            const filterValuePhaseN = doSetFillterValues(getValues('phaseN'),getValues('busBarList'))
            DoCalcSetZValues(infoCircuit.frequency,getValues(),getValues('phaseN'),filterValuePhaseN)
            DoCalcSetIValues(getValues(),getValues('phaseN'),filterValuePhaseN)
            doSetZValuesBusBar("distance",2)
            doSetIValuesBusBar("distance",2)
            clearErrorsBusbar()
        }
        else if(kindPhase == 3){
            const filterValuePhasePE = doSetFillterValues(getValues('phaseNPE'),getValues('busBarList'))
            DoCalcSetZValues(infoCircuit.frequency,getValues(),getValues('phaseNPE'),filterValuePhasePE)
            DoCalcSetIValues(getValues(),getValues('phaseNPE'),filterValuePhasePE)
            doSetZValuesBusBar("distance",3)
            doSetIValuesBusBar("distance",3)
            clearErrorsBusbar()
        }

    }
    const onChangeSkinEffect =(e:any,kindPhase :number)=>{
        setValue(e.target.name,e.target.value)
        if(kindPhase == 1){
            const filterValue3Phase = doSetFillterValues(getValues('m_3Phase'),getValues('busBarList'))
            DoCalcSetZValues(infoCircuit.frequency,getValues(),getValues('m_3Phase'),filterValue3Phase,+e.target.value)
            DoCalcSetIValues(getValues(),getValues('m_3Phase'),filterValue3Phase)
            doSetZValuesBusBar("skinEffectM",1)
            doSetIValuesBusBar("skinEffectM",1)

            setValue('phaseN.skinEffectM',+e.target.value)
            const filterValuePhaseN = doSetFillterValues(getValues('phaseN'),getValues('busBarList'))
            DoCalcSetZValues(infoCircuit.frequency,getValues(),getValues('phaseN'),filterValuePhaseN,+e.target.value)
            DoCalcSetIValues(getValues(),getValues('phaseN'),filterValuePhaseN)
            doSetZValuesBusBar("",2)
            doSetIValuesBusBar("",2)

            setValue('phaseNPE.skinEffectM',+e.target.value)
            const filterValuePhasePE = doSetFillterValues(getValues('phaseNPE'),getValues('busBarList'))
            DoCalcSetZValues(infoCircuit.frequency,getValues(),getValues('phaseNPE'),filterValuePhasePE,+e.target.value)
            DoCalcSetIValues(getValues(),getValues('phaseNPE'),filterValuePhasePE)
            doSetZValuesBusBar("",3)
            doSetIValuesBusBar("",3)
            clearErrorsBusbar()
        }
        else if(kindPhase == 2){
            const filterValuePhaseN = doSetFillterValues(getValues('phaseN'),getValues('busBarList'))
            DoCalcSetZValues(infoCircuit.frequency,getValues(),getValues('phaseN'),filterValuePhaseN,+e.target.value)
            DoCalcSetIValues(getValues(),getValues('phaseN'),filterValuePhaseN)
            doSetZValuesBusBar("skinEffectM",2)
            doSetIValuesBusBar("skinEffectM",2)
            clearErrorsBusbar()
        }
        else if(kindPhase == 3){
            const filterValuePhasePE = doSetFillterValues(getValues('phaseNPE'),getValues('busBarList'))
            DoCalcSetZValues(infoCircuit.frequency,getValues(),getValues('phaseNPE'),filterValuePhasePE,+e.target.value)
            DoCalcSetIValues(getValues(),getValues('phaseNPE'),filterValuePhasePE)
            doSetZValuesBusBar("skinEffectM",3)
            doSetIValuesBusBar("skinEffectM",3)
            clearErrorsBusbar()
        }
    }

    const onChangeOhmRperper = (e:any,kindPhase:number) =>{
        setValue(e.target.name,e.target.value)
        if(kindPhase == 1){
            doSetZValuesBusBar("ohmRPerM",1)
            setValue('phaseN.ohmRPerM',+e.target.value)
            setValue('phaseNPE.ohmRPerM',+e.target.value)
            doSetZValuesBusBar("",2)
            doSetZValuesBusBar("",3)
            clearErrorsBusbar()

        }
        else if(kindPhase == 2){
            doSetZValuesBusBar("ohmRPerM",2)
            clearErrorsBusbar()

        }
        else if(kindPhase == 3){
            doSetZValuesBusBar("ohmRPerM",3)
            clearErrorsBusbar()

        }
    }
    const onChangeOhmXperper = (e:any,kindPhase:number) =>{
        setValue(e.target.name,e.target.value)
        if(kindPhase == 1){
            doSetZValuesBusBar("ohmXPerM",1)
            setValue('phaseN.ohmXPerM',+e.target.value)
            setValue('phaseNPE.ohmXPerM',+e.target.value)
            doSetZValuesBusBar("",2)
            doSetZValuesBusBar("",3)
            clearErrorsBusbar()

        }
        else if(kindPhase == 2){
            doSetZValuesBusBar("ohmXPerM",2)
            clearErrorsBusbar()

        }
        else if(kindPhase == 3){
            doSetZValuesBusBar("ohmXPerM",3)
            clearErrorsBusbar()

        }
    }

    const onChangeBasicCapacity = (e:any,kindPhase:number) =>{
        setValue(e.target.name,e.target.value)
        if(kindPhase == 1){
            doSetIValuesBusBar("basicCapacityM",1)
            setValue('phaseN.basicCapacityM',+e.target.value)
            setValue('phaseNPE.basicCapacityM',+e.target.value)
            doSetIValuesBusBar("",2)
            doSetIValuesBusBar("",3)
            clearErrorsBusbar()

        }
        else if(kindPhase == 2){
            doSetIValuesBusBar("basicCapacityM",2)
            clearErrorsBusbar()

        }
        else if(kindPhase == 3){
            doSetIValuesBusBar("basicCapacityM",3)
            clearErrorsBusbar()

        }
    }
    const onChangeTempk2 = (e:any,kindPhase:number) =>{
        setValue(e.target.name,e.target.value)
        if(kindPhase == 1){
            doSetIValuesBusBar("tempK2M",1)
            setValue('phaseN.tempK2M',+e.target.value)
            setValue('phaseNPE.tempK2M',+e.target.value)
            doSetIValuesBusBar("",2)
            doSetIValuesBusBar("",3)
            clearErrorsBusbar()

        }
        else if(kindPhase == 2){
            doSetIValuesBusBar("tempK2M",2)
            clearErrorsBusbar()

        }
        else if(kindPhase == 3){
            doSetIValuesBusBar("tempK2M",3)
            clearErrorsBusbar()

        }
    }

    const onChangeHorizontalk3 = (e:any,kindPhase:number) =>{
        setValue(e.target.name,e.target.value)
        if(kindPhase == 1){
            doSetIValuesBusBar("horizontalK3M",1)
            setValue('phaseN.horizontalK3M',+e.target.value)
            setValue('phaseNPE.horizontalK3M',+e.target.value)
            doSetIValuesBusBar("",2)
            doSetIValuesBusBar("",3)
            clearErrorsBusbar()

        }
        else if(kindPhase == 2){
            doSetIValuesBusBar("horizontalK3M",2)
            clearErrorsBusbar()

        }
        else if(kindPhase == 3){
            doSetIValuesBusBar("horizontalK3M",3)
            clearErrorsBusbar()

        }
    }

    const onChangeProximityk4 = (e:any,kindPhase:number) =>{
        setValue(e.target.name,e.target.value)
        if(kindPhase == 1){
            doSetIValuesBusBar("proximityK4M",1)
            setValue('phaseN.proximityK4M',+e.target.value)
            setValue('phaseNPE.proximityK4M',+e.target.value)
            doSetIValuesBusBar("",2)
            doSetIValuesBusBar("",3)
            clearErrorsBusbar()

        }
        else if(kindPhase == 2){
            doSetIValuesBusBar("proximityK4M",2)
            clearErrorsBusbar()

        }
        else if(kindPhase == 3){
            doSetIValuesBusBar("proximityK4M",3)
            clearErrorsBusbar()

        }
    }
    const clearErrorsBusbar = () =>{
        if(getValues('m_3Phase.width') >= 0){
            clearErrors('m_3Phase.width')
        }
        if(getValues('m_3Phase.thickness') >= 0){
            clearErrors('m_3Phase.thickness')
        }
        if(getValues('m_3Phase.numberPhase') >= 0){
            clearErrors('m_3Phase.numberPhase')
        }
        if(getValues('m_3Phase.distance') > 0){
            clearErrors('m_3Phase.distance')
        }
        if(getValues('m_3Phase.skinEffectM') > 0){
            clearErrors('m_3Phase.skinEffectM')
        }
        if(getValues('m_3Phase.ohmRPerM') > 0){
            clearErrors('m_3Phase.ohmRPerM')
        }
        if(getValues('m_3Phase.ohmXPerM') > 0){
            clearErrors('m_3Phase.ohmXPerM')
        }
        if(getValues('m_3Phase.basicCapacityM') > 0){
            clearErrors('m_3Phase.basicCapacityM')
        }
        if(getValues('m_3Phase.tempK2M') > 0){
            clearErrors('m_3Phase.tempK2M')
        }
        if(getValues('m_3Phase.horizontalK3M') > 0){
            clearErrors('m_3Phase.horizontalK3M')
        }
        if(getValues('m_3Phase.proximityK4M') > 0){
            clearErrors('m_3Phase.proximityK4M')
        }
        //-----------
        if(getValues('phaseN.width') >= 0){
            clearErrors('phaseN.width')
        }
        if(getValues('phaseN.numberPhase') >= 0){
            clearErrors('phaseN.numberPhase')
        }
        if(getValues('phaseN.thickness') >= 0){
            clearErrors('phaseN.thickness')
        }
        if(getValues('phaseN.distance') > 0){
            clearErrors('phaseN.distance')
        }
        if(getValues('phaseN.skinEffectM') > 0){
            clearErrors('phaseN.skinEffectM')
        }
        if(getValues('phaseN.ohmRPerM') > 0){
            clearErrors('phaseN.ohmRPerM')
        }
        if(getValues('phaseN.ohmXPerM') > 0){
            clearErrors('phaseN.ohmXPerM')
        }
        if(getValues('phaseN.basicCapacityM') > 0){
            clearErrors('phaseN.basicCapacityM')
        }
        if(getValues('phaseN.tempK2M') > 0){
            clearErrors('phaseN.tempK2M')
        }
        if(getValues('phaseN.horizontalK3M') > 0){
            clearErrors('phaseN.horizontalK3M')
        }
        if(getValues('phaseN.proximityK4M') > 0){
            clearErrors('phaseN.proximityK4M')
        }
        //-----------

        if(getValues('phaseNPE.width') >= 0){
            clearErrors('phaseNPE.width')
        }
        if(getValues('phaseNPE.thickness') >= 0){
            clearErrors('phaseNPE.thickness')
        }
        if(getValues('phaseNPE.numberPhase') >= 0){
            clearErrors('phaseNPE.numberPhase')
        }
        if(getValues('phaseNPE.distance') > 0){
            clearErrors('phaseNPE.distance')
        }
        if(getValues('phaseNPE.skinEffectM') > 0){
            clearErrors('phaseNPE.skinEffectM')
        }
        if(getValues('phaseNPE.ohmRPerM') > 0){
            clearErrors('phaseNPE.ohmRPerM')
        }
        if(getValues('phaseNPE.ohmXPerM') > 0){
            clearErrors('phaseNPE.ohmXPerM')
        }
        if(getValues('phaseNPE.basicCapacityM') > 0){
            clearErrors('phaseNPE.basicCapacityM')
        }
        if(getValues('phaseNPE.tempK2M') > 0){
            clearErrors('phaseNPE.tempK2M')
        }
        if(getValues('phaseNPE.horizontalK3M') > 0){
            clearErrors('phaseNPE.horizontalK3M')
        }
        if(getValues('phaseNPE.proximityK4M') > 0){
            clearErrors('phaseNPE.proximityK4M')
        }
        
    }

    return (
        <>
           
                
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={selectTab} onChange={handleChange}
                            variant="scrollable"
                            scrollButtons
                            allowScrollButtonsMobile
                            sx={{
                                '& .MuiButtonBase-root.MuiTabScrollButton-root.Mui-disabled': {
                                    display: "none"
                                }
                            }}
                        >
                            <Tab label="基本" {...a11yProps(0)} />
                            <Tab label="仕様" {...a11yProps(1)} />
                            <Tab label="電圧線(3相/単相)" {...a11yProps(2)}/>

                            {((isDisplayTabTabSPhase === 2 || isDisplayTabTabSPhase === 3)&& !isModeSPhases) &&
                            <Tab label="中性線(N相)" {...a11yProps(3)} />
                            }
                            {(isDisplayTabTabSPhase === 3 && !isModeSPhases) &&
                            <Tab label="保護接地線(PE相)" {...a11yProps(4)} />
                            }
                            {setIsDisplayTabConnectTran1 && <Tab label="電圧降下-[外線-外線]" {...a11yProps(4)}/> ||
                            setIsDisplayTabConnectTranscott && <Tab label="電圧降下-[主座]" {...a11yProps(4)}/> ||
                            <Tab label="電圧降下" {...a11yProps(5)}/>}
                            
                            {setIsDisplayTabConnectTran1 && <Tab label="電圧降下-[外線-中性線]" {...a11yProps(5)}/> ||
                            setIsDisplayTabConnectTranscott && <Tab label="電圧降下-T座" {...a11yProps(5)}/>}
                            
                            {userRole != Constant.ROLE_LP && <Tab label="潮流解析" {...a11yProps(6)} />}
                        </Tabs>
                    </Box>
                    <form onSubmit={handleSubmit(handleEditSubmit)}>
                        <div style={{ height: '40vh', width: '100%', margin: 'auto', overflow: 'auto' }}>
                    {/*tab0 基本 */}
                            <TabPanel value={selectTab} index={0}>
                                <Stack spacing={0.3} >
                                    <Grid container mt={2}>
                                        <Grid item xs={6}>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" ,mt:"5px"}}>
                                                <Typography variant="body2" width="60%">デバイス名:</Typography>
                                                    <Controller
                                                        name="refNo"
                                                        control={control}
                                                        rules={{
                                                            required: '必須項目です。入力してください。',
                                                        }}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                fullWidth
                                                                type="text"
                                                                size="small"
                                                                // label="デバイス名"
                                                                error={!!errors?.refNo}
                                                                helperText={errors?.refNo?.message}
                                                                onBlur={handleChangeFormat}
                                                                onFocus={e => e.target.select()}
                                                                InputLabelProps={{ style: { color: 'black' }}}
                                                                InputProps={{sx:{height:32,backgroundColor:m_bModePM || modeViewOnly?'floralwhite':'white', color: modeViewOnly?'grey':'black'}}}
                                                                disabled={m_bModePM}
                                                                inputProps={{readOnly: modeViewOnly}}
                                                            />
                                                        )}
                                                    />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" ,mt:"2px"}}>
                                                <Typography variant="body2" width="60%">事故点名称:</Typography>
                                                <Controller
                                                    name="pointText"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type="text"
                                                            size="small"
                                                            // label="事故点名称"
                                                            onBlur={handleChangeFormat}
                                                            onFocus={e => e.target.select()}
                                                            InputLabelProps={{ style: { color: 'black' }}}
                                                            InputProps={{sx:{height:32,backgroundColor:m_bModePM?'floralwhite':'white',color: modeViewOnly?'grey':'black'}}}
                                                            disabled={m_bModePM}
                                                            inputProps={{readOnly: modeViewOnly}}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" ,mt:"2px"}}>
                                                <Typography variant="body2" width="60%">回路電圧(V):</Typography>
                                                <FormControl fullWidth size="small">
                                                    {/* <InputLabel style={{ color: 'black' }}>回路電圧</InputLabel> */}
                                                    <Controller
                                                        name="voltage"
                                                        control={control}
                                                        rules={{ required: '必須項目です。入力してください。',
                                                        pattern:{
                                                            value: /^[0-9\b]+$/,
                                                            message: '数字を入力してください。'
                                                        },
                                                        validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                        // maxLength: {
                                                        //     value: 6,
                                                        //     message: "最大長さ <= 6 を入力してください。"
                                                        // }
                                                    }}
                                                        render={({ field }) => (
                                                            <TextFieldOptions
                                                                {...field}
                                                                options={initialValue.voltageList}
                                                                // label="回路電圧(V)"
                                                                // onChange= {(e) =>handleChangeFormat(e)}
                                                                size="small"
                                                                setValue={(name: any, value: any) => {
                                                                    // handleChangeFormat({target:{name:name,value:value}});
                                                                    onChangeVoltage({target:{name:name,value:value}});
                                                                }}
                                                                height={32}
                                                                disabled={getValues("isSourceVoltage")||m_bModePM || modeViewOnly}
                                                                error={!!errors?.voltage}
                                                                helperText={errors?.voltage?.message}
                                                                maxLength={6}
                                                                value={(setIsDisplayTabConnectTran1||setIsDisplayTabConnectTranscott)?setIsVoltText:getValues("voltage")}
                                                            />
                                                        )}
                                                    />
                                                </FormControl>
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" ,mt:"2px"}}>
                                                <Typography variant="body2" width="60%">長さ(m):</Typography>
                                                <Controller
                                                    name="lenght"
                                                    control={control}
                                                    rules={{
                                                        pattern:{
                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                            message: '数字を入力してください。'
                                                        },
                                                        validate: value => value >= 0 || '数字 >= 0 を入力してください。' ,
                                                        // maxLength: {
                                                        //     value: 3,
                                                        //     message: "最大長さ <= 3 を入力してください。"
                                                        // }
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type="text"
                                                            size="small"
                                                            // label="長さ(m)"
                                                            // onBlur={}
                                                            onFocus={e => e.target.select()}
                                                            InputLabelProps={{ style: { color: 'black' }}}
                                                            onChange={(e) =>{onChangeLength(e);}}
                                                            InputProps={{sx:{height:32,backgroundColor:m_bModePM?'floralwhite':'white',color: modeViewOnly?'grey':'black'}}}
                                                            error={!!errors?.lenght}
                                                            helperText={errors?.lenght?.message}
                                                            inputProps={{maxlength:3,readOnly: modeViewOnly}}
                                                            disabled={m_bModePM|| processMode > MS_MODE_CHECKING}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={4} pl={1}>
                                            <Stack>
                                                <Controller
                                                    name="fault"
                                                    control={control}
                                                    render={({ field }: any) => (
                                                        <FormControlLabel
                                                            {...field}
                                                            label={<Typography variant="body2"  color={m_bModePM?'grey':'black'}>短絡電流を計算</Typography>}
                                                            name='fault'
                                                            checked={field.value}
                                                            control={<Checkbox style={checkboxStyles} />}
                                                            onClick={handleChangeFormatChecked}
                                                            disabled={m_bModePM || modeViewOnly}

                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack>
                                                <Controller
                                                    name="viewResultText"
                                                    control={control}
                                                    render={({ field }: any) => (
                                                        <FormControlLabel
                                                            {...field}
                                                            label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>注釈を表示</Typography>}
                                                            name='viewResultText'
                                                            checked={field.value}
                                                            control={<Checkbox style={checkboxStyles} />}
                                                            onClick={handleChangeFormatChecked}
                                                            disabled={m_bModePM || modeViewOnly}

                                                        />
                                                    )}
                                                />
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                        <fieldset style={{width:"80%",marginTop:'15px', padding:'auto',border:"1px solid #AAAA"}}>
                                            <legend><Typography variant="body2">配線システム</Typography></legend>
                                                <Controller
                                                            name="sPhase"
                                                            control={control}
                                                            render={({ field }) => (
                                                            <>
                                                                <FormControlLabel  
                                                                    disabled={!isModeSPhases||m_bModePM}
                                                                    control={<Radio checked={field.value} style={radioStyles} />} 
                                                                    label={<Typography variant="body2" style={{color:!isModeSPhases||m_bModePM?'grey':'black'}}>電圧線(単相)</Typography>}
                                                                />
                                                            </>
                                                            )}
                                                        />
                                            <Controller
                                                name="wireSystem"
                                                control={control}
                                                rules={{ required: '必須項目です。入力してください。' }}
                                                render={({ field }) => (
                                                    <>
                                                        <RadioGroup
                                                            {...field}
                                                            onChange={(e)=>handleDisplayTab(e)}
                                                        >
                                                            {/* <FormControlLabel
                                                                value="0"
                                                                disabled={!(setIsDisplayTabConnectTran1||setIsDisplayTabConnectTranscott)}
                                                                control={<Radio style={radioStyles} />}
                                                                label="電圧線(単相)"
                                                            /> */}
                                                            <FormControlLabel
                                                                value="1"
                                                                control={<Radio style={radioStyles} />}
                                                                label={<Typography variant="body2" style={{color:(setIsDisplayTabConnectTran1||setIsDisplayTabConnectTranscott||m_bModePM || processMode > MS_MODE_DRAWING)?'grey':'black'}}>電圧線(3相)</Typography>}
                                                                disabled={(setIsDisplayTabConnectTran1||setIsDisplayTabConnectTranscott||m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING)}
                                                                checked={field.value == RS_3PHASES && !isModeSPhases}
                                                            />
                                                            <FormControlLabel
                                                                value="2"
                                                                control={<Radio style={radioStyles} />}
                                                                label={<Typography variant="body2" style={{color:(setIsDisplayTabConnectTran1||setIsDisplayTabConnectTranscott||m_bModePM || processMode > MS_MODE_DRAWING)?'grey':'black'}}>電圧線(3相) + 中性線(N相)</Typography>}
                                                                disabled={(setIsDisplayTabConnectTran1||setIsDisplayTabConnectTranscott||m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING)}
                                                                checked={field.value == RS_PHASE_N && !isModeSPhases}
                                                            />
                                                            <FormControlLabel
                                                                value="3"
                                                                control={<Radio style={radioStyles} />}
                                                                label={<Typography variant="body2" style={{color:(setIsDisplayTabConnectTran1||setIsDisplayTabConnectTranscott||m_bModePM || processMode > MS_MODE_DRAWING)?'grey':'black'}}>電圧線(3相) + 中性線(N相) + 保護接地線(PE相)</Typography>}
                                                                disabled={(setIsDisplayTabConnectTran1||setIsDisplayTabConnectTranscott||m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING)}
                                                                checked={field.value == RS_PHASE_PE && !isModeSPhases}
                                                            />
                                                        </RadioGroup>
                                                    </>
                                                )}
                                            />
                                    </fieldset>
                                    {/* Mode PM */}
                                    {[Constant.ROLE_PM, Constant.ROLE_ADMIN].includes(userRole) &&
                                    <>
                                        <Stack>
                                            <FormControlLabel
                                                control={
                                                    <Controller
                                                        name='makeDataInput'
                                                        control={control}
                                                        render={({ field }) => (
                                                            <Checkbox
                                                                size='small'
                                                                {...field}
                                                                checked={field.value}
                                                                onChange={handleChangeMakeDataInput}
                                                                style={{padding:'15px 4px 4px 9px'}}
                                                                disabled={m_bModePM || modeViewOnly}
                                                            />
                                                        )}
                                                    />
                                                }
                                                label={<Typography variant="body2" style={{marginTop:'10px'}} color={m_bModePM?'grey':'black'}>銘板入力</Typography>}
                                            />
                                        </Stack>
                                        <Stack>
                                            <fieldset
                                                style={{ 
                                                    width: '70%',
                                                    color: !makeDataInput ? 'grey' : 'black', 
                                                    border:"1px solid #AAAA"
                                                }}
                                            >
                                                <legend >{<Typography variant="body2"  >銘板</Typography>}</legend>
                                                <Stack spacing={0.3}>
                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                        <Typography variant="body2" width="35%"  >製造者:</Typography>
                                                        <FormControl fullWidth size='small'>
                                                            <Controller
                                                                name='makerName'
                                                                control={control}
                                                                render={({ field }: any) => (
                                                                    <TextFieldOptions
                                                                        {...field}
                                                                        options={initialValue.makerNameList}
                                                                        type='string'
                                                                        size='small'
                                                                        setValue={(name: any, value: any) => {
                                                                            handleChangeFormat({target: {name: name, value: value}})
                                                                        }}
                                                                        height= {32}
                                                                        disabled={!makeDataInput||m_bModePM || modeViewOnly}

                                                                    />
                                                                )}
                                                            />
                                                        </FormControl>
                                                    </Stack>
                                                    
                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                        <Typography variant="body2" width="35%"  >敷設年月:</Typography>
                                                        <Grid container>
                                                            <Grid item xs={7}>
                                                                <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                                    <FormControl fullWidth size='small'>
                                                                        <Controller
                                                                            name='makeYear'
                                                                            control={control}
                                                                            rules={{
                                                                                // maxLength: {
                                                                                //     value: 8,
                                                                                //     message: "最大長さ <= 8 を入力してください。"
                                                                                // }
                                                                                    }}
                            
                                                                            render={({ field }: any) => (
                                                                                <TextFieldOptions
                                                                                    {...field}
                                                                                    options={initialValue.makeYearList}
                            
                                                                                    type='string'
                                                                                    size='small'
                                                                                    setValue={(name: any, value: any) => {
                                                                                        handleChangeFormat({target: {name: name, value: value}})
                                                                                    }}
                                                                                    error={!!errors?.makeYear}
                                                                                    helperText={errors?.makeYear?.message}
                                                                                    disabled={!makeDataInput||m_bModePM || modeViewOnly}
                                                                                    height={32}
                                                                                    maxLength={8}

                                                                                />
                                                                            )}
                                                                        />
                                                                    </FormControl>
                                                                    <Typography variant="body2" pr={0.5} pl={0.5}>年</Typography>
                                                                </Stack>
                                                            </Grid>
                                                            <Grid item xs={5}>
                                                                <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                                    <FormControl fullWidth size='small'>
                                                                        <Controller
                                                                        name='makeMonth'
                                                                        control={control}
                                                                        rules={{
                                                                            // maxLength:{
                                                                            //     value:2,
                                                                            //     message:'最大長さ <= 2 を入力してください'
                                                                            // }
                                                                        }}
                                                                        render={({ field }: any) => (
                                                                            <TextFieldOptions
                                                                                {...field}
                                                                                options={initialValue.makeMonthList}
                                                                                // label='製造者'
                                                                                type='string'
                                                                                size='small'
                                                                                // onChange={handleChangeFormat}
                                                                                setValue={(name: any, value: any) => {
                                                                                    handleChangeFormat({target: {name: name, value: value}})
                        
                                                                                }} 
                                                                                error={!!errors?.makeMonth}
                                                                                helperText={errors?.makeMonth?.message}
                                                                                disabled={!makeDataInput||m_bModePM || modeViewOnly}
                                                                                height={32}
                                                                                maxLength={2}

                        
                                                                            />
                                                                        )}
                                                                            
                                                                        />
                                                                    </FormControl>
                                                                    <Typography variant="body2" pl={0.5}>月</Typography>
                                                                </Stack>
                                                            </Grid>
                                                        </Grid>
                                                    </Stack>
                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                        <Typography variant="body2" width="35%"  >その他:</Typography>
                                                        <Controller
                                                            name='type'
                                                            control={control}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    type='text'
                                                                    size='small'
                                                                    onChange={handleChangeFormat}
                                                                    InputLabelProps={{
                                                                        style: { color: makeDataInput ? 'black' : 'grey' },
                                                                    }}
                                                                    InputProps={{
                                                                        readOnly: !makeDataInput || modeViewOnly,
                                                                        style:{color:!makeDataInput || modeViewOnly ?'grey' : 'black'},
                                                                        sx: { height: 32 }
                                                                    }}
                                                                    style={{
                                                                        backgroundColor:m_bModePM || modeViewOnly?'floralwhite': makeDataInput ? 'white' : 'floralwhite',
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </Stack>
                                                </Stack>
                                            </fieldset>
                                        </Stack>
                                    </>
                                    }
                                </Stack>
                            </TabPanel>

                            {/* tab1 仕様 */}
                            <TabPanel value={selectTab} index={1}>
                                <Stack spacing={3} style={{ width: '100%', margin: 'auto' }}>
                                    <Stack>
                                        <Grid mt={2} mb={2}><Typography variant="body2">DIN 43670, DIN 43671に準拠しています。</Typography> </Grid>
                                        <fieldset style={{width: '50%',border: "1px solid #AAAAAA"}}>
                                            <legend ><Typography variant="body2">材質</Typography></legend>
                                            <Controller
                                                name="materialCuAl"
                                                control={control}
                                                rules={{ required: '必須項目です。入力してください。' }}
                                                render={({ field }) => (
                                                    <>
                                                        <RadioGroup {...field} 
                                                                onChange={(e) => onChangeRadioButtonTab1(e)}
                                                        >
                                                            <FormControlLabel value="1" control={<Radio style={radioStyles} />} label={<Typography variant="body2" color={m_bModePM|| processMode > MS_MODE_DRAWING?'grey':'black'}>銅</Typography>} disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING}/>
                                                            <FormControlLabel value="2" control={<Radio style={radioStyles} />} label={<Typography variant="body2" color={m_bModePM|| processMode > MS_MODE_DRAWING?'grey':'black'}>アルミニウム</Typography>} disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING}/>
                                                        </RadioGroup>
                                                    </>
                                                )}
                                            />
                                        </fieldset>
                                        <fieldset style={{marginTop:"5px",border: "1px solid #AAAAAA"}}>
                                            <legend ><Typography variant="body2">許容電流の温度補正係数</Typography></legend>
                                            <Grid container direction="row">
                                                <fieldset style={{width: '25%',border: "1px solid #AAAAAA"}}>
                                                        <legend><Typography variant="body2">表面仕様</Typography></legend>
                                                        <Controller
                                                            name="bareOrPainted"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <>
                                                                    <RadioGroup {...field} 
                                                                        onChange={(e) => onChangeRadioButtonTab1(e)}
                                                                    >
                                                                        <FormControlLabel value="1" control={<Radio style={radioStyles} />} label={<Typography variant="body2"color={m_bModePM|| processMode > MS_MODE_DRAWING?'grey':'black'}>裸</Typography>} disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING}/>
                                                                        <FormControlLabel value="2" control={<Radio style={radioStyles} />} label={<Typography variant="body2"color={m_bModePM|| processMode > MS_MODE_DRAWING?'grey':'black'}>塗装付</Typography>} disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING}/>
                                                                    </RadioGroup>
                                                                </>
                                                            )}
                                                        />
                                                    
                                                    </fieldset>
                                                    <fieldset style={{width: '25%',border: "1px solid #AAAAAA"}}>
                                                            <legend><Typography variant="body2">設置方向</Typography></legend>
                                                            <Controller
                                                                name="verticalOrHorizonal"
                                                                control={control}
                                                                render={({ field }) => (
                                                                    <>
                                                                        <RadioGroup {...field} 
                                                                        onChange={(e) => onChangeRadioButtonTab1(e)}
                                                                        >
                                                                            <FormControlLabel value="1" control={<Radio style={radioStyles} />} label={<Typography variant="body2" color={m_bModePM|| processMode > MS_MODE_DRAWING?'grey':'black'}>垂直</Typography>}  disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING}/>
                                                                            <FormControlLabel value="2" control={<Radio style={radioStyles} />} label={<Typography variant="body2" color={m_bModePM|| processMode > MS_MODE_DRAWING?'grey':'black'}>水平</Typography>} disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING}/>
                                                                        </RadioGroup>
                                                                    </>
                                                                )}
                                                            />
                                                    </fieldset>
                                            </Grid>
                                            <Grid container>
                                                    <Grid item xs={12}>   
                                                        <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"10px"}}>
                                                            <Typography variant="body2" width="40%">ブスバーの最高温度(°C):</Typography>  
                                                                <Controller
                                                                    name="busbarTemp"
                                                                    control={control}
                                                                    rules={{ required: '必須項目です。入力してください。',
                                                                            pattern:{
                                                                                value: /^[0-9\b]+$/,
                                                                                message: '数字を入力してください。'
                                                                            },
                                                                            validate: value => value >= 50 && value <= 125 || '数字 50 ~ 125 を入力してください。' ,
                                                                            // maxLength: {
                                                                            //     value: 3,
                                                                            //     message: "最大長さ <= 3 を入力してください。"
                                                                            // }
                                                                        }}
                                                                    render={({ field }) => (
                                                                        <TextField
                                                                            {...field}
                                                                            // type="text"
                                                                            size="small"
                                                                            // label="ブスバーの最高温度(°C)"
                                                                            // onBlur={handleChangeFormat}
                                                                            InputLabelProps={{ style: { color: 'black' }}}
                                                                            onChange={(e)=>{
                                                                                field.onChange(e);
                                                                                onChangeBusBarTempAndAmbient(e);
                                                                                // handleDisableOKBt(e);
                                                                                                }}
                                                                            inputProps={{
                                                                                maxLength:3,
                                                                                readOnly: modeViewOnly
                                                                            }}
                                                                            InputProps={{sx:{height:32,backgroundColor:m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING?'floralwhite':'white', color: modeViewOnly? "grey":"black"}}}
                                                                            error={!!errors?.busbarTemp}
                                                                            helperText={errors?.busbarTemp?.message}
                                                                            disabled={m_bModePM|| processMode > MS_MODE_DRAWING}
                                                                        />
                                                                )}
                                                            />
                                                            <Grid item xs={5} pl={1} fontSize={"14px"}><Typography variant="body2">{' '}(50~125の範囲で入力)</Typography></Grid>
                                                        </Stack>
                                                    </Grid>
                                                    <Grid item xs ={12}>
                                                        <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                            <Typography variant="body2" width="40%">ブスバーの基準温度(°C):</Typography>  
                                                                <Controller
                                                                    name="ambient"
                                                                    control={control}
                                                                    rules={{
                                                                            pattern:{
                                                                                value: /^[0-9\b]+$/,
                                                                                message: '数字を入力してください。'
                                                                            },
                                                                            validate: value => value >= 0 && value <= 65 && value <= getValues('busbarTemp') || '数字 0 ~ 65 と数字 <=「ブスバーの最高温」を入力してください。' ,
                                                                            // maxLength: {
                                                                            //     value: 2,
                                                                            //     message: "最大長さ <= 2 を入力してください。"
                                                                            // }
                                                                        }}
                                                                    render={({ field }) => (
                                                                        <TextField
                                                                            {...field}
                                                                            // type="text"
                                                                            size="small"
                                                                            // label="ブスバーの基準温度(°C)"
                                                                            onFocus={e => e.target.select()}
                                                                            InputLabelProps={{ style: { color: 'black' }}}
                                                                            onChange={(e)=>{
                                                                                field.onChange(e);
                                                                                onChangeBusBarTempAndAmbient(e);
                                                                                // handleDisableOKBt(e);
                                                                                                    // onChangeMostOfFields(e);
                                                                                                    }}
                                                                            inputProps={{
                                                                                maxLength:2,
                                                                                readOnly: modeViewOnly
                                                                            }}
                                                                            InputProps={{sx:{height:32,backgroundColor:m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING?'floralwhite':'white', color: modeViewOnly? "grey":"black"}}}
                                                                            error={!!errors?.ambient}
                                                                            helperText={errors?.ambient?.message}
                                                                            disabled={m_bModePM|| processMode > MS_MODE_DRAWING}

                                                                        />
                                                                    )}
                                                                />
                                                            <Grid item xs={5} pl={1} fontSize={"14px"}><Typography variant="body2">{' '}(0~65の範囲で入力)</Typography> </Grid>
                                                        </Stack>
                                                    </Grid>
                                                </Grid>
                                        </fieldset>
                                    </Stack>
                                </Stack>
                            </TabPanel>

                            {/*tab2 電圧線(3相/単相) */}
                            <TabPanel value={selectTab} index={2}>
                                <Stack spacing={3} style={{ width: '100%'}} marginTop={1}>
                                    <Stack>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                    <Typography variant="body2" width="100%">電圧線のブスバーの幅 b(mm):</Typography>  
                                                        <FormControl fullWidth size="small">

                                                            <Controller
                                                                name="m_3Phase.width"
                                                                control={control}
                                                                rules={{        
                                                                    pattern:{
                                                                        value: /^[0-9\b]+$/,
                                                                        message: '整数 >= 0を入力してください。'
                                                                    },                                  
                                                                    validate: value => value >= 0 || '整数 >= 0を入力してください。' ,
                                                            }}
                                                                render={({ field }) => (
                                                                    <TextFieldOptions
                                                                        {...field}
                                                                        options={getValues("m_3Phase.widthList")}
                                                                    
                                                                        size="small"
                                                                        setValue={(name: any, value: any) => {
                                                                            onChangeEditChangeWidth({target:{name:name,value:value}},1)
                                                                            // onChangeWidth({target:{name:name,value:value}},1);
                                                                            // onChangeInput({target:{name:name,value:value}});
                                                                        }}
                                                                        height={32}
                                                                        error={!!errors?.m_3Phase?.width}
                                                                        helperText={errors?.m_3Phase?.width?.message}
                                                                        maxLength={8}
                                                                        disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING}
                                                                                />
                                                    
                                                                )}
                                                            />
                                                        </FormControl>
                                                    </Stack>
                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                        <Typography variant="body2" width="100%">電圧線のブスバーの厚さ(mm):</Typography>  
                                                            <FormControl fullWidth size="small">
                                                                {/* <InputLabel style={{ color: 'black' }}>電圧線のブスパーの厚さ(mm)</InputLabel> */}
                                                                <Controller
                                                                    name="m_3Phase.thickness"
                                                                    control={control}
                                                                    rules={{        
                                                                        pattern:{
                                                                            value: /^[0-9\b]+$/,
                                                                            message: '整数 >= 0を入力してください。'
                                                                        },                                  
                                                                        validate: value => value >= 0 || '整数 >= 0を入力してください。' ,
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <TextFieldOptions
                                                                            {...field}
                                                                            options={getValues("m_3Phase.thicknessList")}
                                                                    
                                                                            size="small"
                                                                            setValue={(name: any, value: any) => {
                                                                                onChangeEditChangeWidth({target:{name:name,value:value}},1)
                                                                                // doSelComboThickness({target:{name:name,value:value}},1);
                                                                                // onChangeInput({target:{name:name,value:value}});
                                                                                // handleDisableOKBt({target:{name:name,value:value}});

                                                                            }}
                                                                            height={32}
                                                                            error={!!errors?.m_3Phase?.thickness}
                                                                            helperText={errors?.m_3Phase?.thickness?.message}
                                                                            maxLength={8}
                                                                            disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING}

                                                                                    />
                                                                        
                                                                    )}
                                                                />
                                                            </FormControl>
                                                    </Stack>
                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                        <Typography variant="body2" width="100%">電圧線の1相当りの枚数:</Typography>  
                                                            <FormControl fullWidth size="small">
                                                                <Controller
                                                                    name="m_3Phase.numberPhase"
                                                                    control={control}
                                                                    rules={{        
                                                                        pattern:{
                                                                            value: /^[0-9\b]+$/,
                                                                            message: '整数 >= 0を入力してください。'
                                                                        },                                  
                                                                        validate: value => value >= 0 || '整数 >= 0を入力してください。' ,
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <TextFieldOptions
                                                                            {...field}
                                                                            options={getValues("m_3Phase.numberPhaseList")}
                                                                            // label="電圧線の1相当りの枚数"
                                                                            size="small"
                                                                            setValue={(name: any, value: any) => {
                                                                                onChangeEditChangeWidth({target:{name:name,value:value}},1)
                                                                                // doSelComboNumberPhase({target:{name:name,value:value}},1);
                                                                                // handleDisableOKBt({target:{name:name,value}});
                                                                                // onChangeInput({target:{name:name,value:value}});


                                                                            }}
                                                                            height={32}
                                                                            error={!!errors?.m_3Phase?.numberPhase}
                                                                            helperText={errors?.m_3Phase?.numberPhase?.message}
                                                                            maxLength={8}
                                                                            disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING}

                                                                                    />
                                                                        
                                                                    )}
                                                                />
                                                            </FormControl>
                                                    </Stack>
                                                </Grid>
                                            </Grid>
                                            <Grid mt={1}>
                                                <fieldset style={{border:"1px solid #AAAA"}}>
                                                    <legend><Typography variant="body2">インピーダンス</Typography></legend>
                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                        <Typography variant="body2" width="100%">相間の中心間距離 a(mm):</Typography>  
                                                            <Controller
                                                                name="m_3Phase.distance"
                                                                control={control}
                                                                rules={{        
                                                                    required: '必須項目です。入力してください。' ,
                                                                    pattern:{
                                                                        value: /^[0-9\b]+$/,
                                                                        message: '整数 >= 0を入力してください。'
                                                                    },                                  
                                                                    validate: value => value >= 0 || '整数 >= 0を入力してください。' ,
                                                                }}
                                                                render={({ field }) => (
                                                                    <TextField
                                                                        {...field}
                                                                        fullWidth
                                                                        // type="text"
                                                                        size="small"
                                                                        // label="相間の中心間距離 a(mm)"
                                                                        // onBlur={handleChangeFormat}
                                                                        onFocus={e => e.target.select()}
                                                                        InputLabelProps={{ style: { color: 'black' }}}
                                                                        onChange={(e) =>{
                                                                            field.onChange(e);
                                                                            onChangeDistance(e,1)
                                                                            }}
                                                                        InputProps={{sx:{height:32,backgroundColor:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white',color:modeViewOnly?"grey":"black"}}}
                                                                        error={!!errors?.m_3Phase?.distance}
                                                                        helperText={errors?.m_3Phase?.distance?.message}
                                                                        inputProps={{maxLength:3,readOnly:modeViewOnly}}
                                                                        disabled={m_bModePM|| processMode > MS_MODE_CHECKING}

                                                                        />
                                                                )}
                                                            />
                                                    </Stack>
                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                        <Typography variant="body2" width="100%">表皮効果 ks:</Typography>  
                                                            <Controller
                                                                name="m_3Phase.skinEffectM"
                                                                control={control}
                                                                rules={{
                                                                    required: '必須項目です。入力してください。' ,
                                                                    pattern:{
                                                                        value: /^[0-9]*[.]?[0-9]+$/,
                                                                        message: '数字を入力してください。'
                                                                    },
                                                                    validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                                }}
                                                                render={({ field }) => (
                                                                    <TextField
                                                                        {...field}
                                                                        fullWidth
                                                                        // type="text"
                                                                        size="small"
                                                                        // label="表皮効果 ks"
                                                                        onFocus={e => e.target.select()}
                                                                        InputLabelProps={{ style: { color: 'black' }}}
                                                                        onChange={(e) =>{
                                                                            field.onChange(e);
                                                                            onChangeSkinEffect(e,1)
                                                                        }}
                                                                        InputProps={{sx:{height:32,backgroundColor:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white',color: modeViewOnly?"grey":"black"},
                                                                        endAdornment:(
                                                                            <text style={{color:getValues("m_3Phase.skinEffectM") != getValues("m_3Phase.skinEffectA") ? "black" : "grey",fontSize:"30px",marginTop:"15px"}}>*</text>
                                                                        )}}
                                                                        error={!!errors?.m_3Phase?.skinEffectM}
                                                                        helperText={errors?.m_3Phase?.skinEffectM?.message}
                                                                        inputProps={{maxLength:4, readOnly: modeViewOnly}}
                                                                        disabled={m_bModePM|| processMode > MS_MODE_CHECKING}

                                                                    />
                                                                )}
                                                            />
                                                    </Stack>
                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                        <Typography variant="body2" width="100%">1m当りの抵抗 (mΩ/m):</Typography>  
                                                        <Controller
                                                            name="m_3Phase.ohmRPerM"
                                                            control={control}
                                                            rules={{
                                                                required: '必須項目です。入力してください。' ,
                                                                pattern:{
                                                                    value: /^[0-9]*[.]?[0-9]+$/,
                                                                    message: '数字を入力してください。'
                                                                },
                                                                validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                                
                                                            }}
                                                            render={({field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    size="small"
                                                                    // label="1m当りの抵抗 (mΩ/m)"
                                                                    onFocus={e => e.target.select()}
                                                                    InputLabelProps={{ style: { color: 'black' }}}
                                                                    onChange={(e:any) =>{
                                                                        field.onChange(e);
                                                                        onChangeOhmRperper(e,1)
                                                                    }}
                                                                    InputProps={{sx:{height:32,backgroundColor:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white', color: modeViewOnly?"grey":"black"},
                                                                    endAdornment:(
                                                                        <text style={{color:getValues("m_3Phase.ohmRPerM") != getValues("m_3Phase.ohmRPerA") ? "black" : "grey",fontSize:"30px",marginTop:"15px"}}>*</text>
                                                                    )}}
                                                                    error={!!errors?.m_3Phase?.ohmRPerM}
                                                                    helperText={errors?.m_3Phase?.ohmRPerM?.message}
                                                                    inputProps={{maxLength:7,readOnly: modeViewOnly}}
                                                                    disabled={m_bModePM|| processMode > MS_MODE_CHECKING}

                                                                />
                                                            )}
                                                        />
                                                    </Stack>
                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                        <Typography variant="body2" width="100%">抵抗(mΩ):</Typography>  
                                                        <Controller
                                                            name="m_3Phase.ohmR"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    disabled={true}
                                                                    defaultValue={0}
                                                                    fullWidth
                                                                    type="text"
                                                                    size="small"
                                                                    // label="抵抗(mΩ)"
                                                                    onFocus={e => e.target.select()}
                                                                    InputLabelProps={{ style: { color: 'black' }}}
                                                                    InputProps={{sx:{height:32,backgroundColor:'floralwhite'}}}

                                                                />
                                                            )}
                                                        />
                                                    </Stack>
                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                        <Typography variant="body2" width="100%">1m当りのリアクタンス(mΩ/m):</Typography>  
                                                        <Controller
                                                            name="m_3Phase.ohmXPerM"
                                                            control={control}
                                                            rules={{
                                                                required: '必須項目です。入力してください。' ,
                                                                pattern:{
                                                                    value: /^[0-9]*[.]?[0-9]+$/,
                                                                    message: '数字を入力してください。'
                                                                },
                                                                validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                                
                                                                // maxLength: {
                                                                //     value: 7,
                                                                //     message: "最大長さ <= 7 を入力してください。"
                                                                // }
                                                            }}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    // type="text"
                                                                    size="small"
                                                                    // label="1m当りのリアクタンス(mΩ/m)"
                                                                    onFocus={e => e.target.select()}
                                                                    InputLabelProps={{ style: { color: 'black' }}}
                                                                    onChange={(e) =>{
                                                                        field.onChange(e);
                                                                        onChangeOhmXperper(e,1)
                                                                                }}
                                                                    InputProps={{sx:{height:32,backgroundColor:m_bModePM|| modeViewOnly|| processMode > MS_MODE_CHECKING?'floralwhite':'white', color:modeViewOnly?"grey":"black"},
                                                                    endAdornment:(
                                                                        <text style={{color:getValues("m_3Phase.ohmXPerM") != getValues("m_3Phase.ohmXPerA") ? "black" : "grey",fontSize:"30px",marginTop:"15px"}}>*</text>
                                                                    )}}
                                                                    error={!!errors?.m_3Phase?.ohmXPerM}
                                                                    helperText={errors?.m_3Phase?.ohmXPerM?.message}
                                                                    inputProps={{maxLength:7,readOnly:modeViewOnly}}
                                                                    disabled={m_bModePM|| processMode > MS_MODE_CHECKING}


                                                                />
                                                            )}
                                                        />
                                                    </Stack>
                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                        <Typography variant="body2" width="100%">リアクタンス(mΩ):</Typography>  
                                                        <Controller
                                                            name="m_3Phase.ohmX"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    disabled={true}
                                                                    defaultValue={0}
                                                                    fullWidth
                                                                    type="text"
                                                                    size="small"
                                                                    // label="リアクタンス(mΩ)"
                                                                    onFocus={e => e.target.select()}
                                                                    InputLabelProps={{ style: { color: 'black' }}}
                                                                    InputProps={{sx:{height:32,backgroundColor:'floralwhite'}}}

                                                                />
                                                            )}
                                                        />
                                                    </Stack>
                                                </fieldset>
                                            </Grid>
                                            <Grid >
                                                <fieldset style={{marginTop:"10px",border:"1px solid #AAAA"}}>
                                                    <legend><Typography variant="body2">許容電流</Typography></legend>
                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                        <Typography variant="body2" width="100%">基本通電許容電流(A):</Typography>  
                                                        <Controller
                                                            name="m_3Phase.basicCapacityM"
                                                            control={control}
                                                            rules={{
                                                                required: '必須項目です。入力してください。' ,
                                                                pattern:{
                                                                    value: /^[0-9]*[.]?[0-9]+$/,
                                                                    message: '数字を入力してください。'
                                                                },
                                                                validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                                
                                                                // maxLength: {
                                                                //     value: 4,
                                                                //     message: "最大長さ <= 4 を入力してください。"
                                                                // }
                                                            }}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    // type="text"
                                                                    size="small"
                                                                    sx={{width:"50%"}}
                                                                    // label="基本通電許容電流(A)"
                                                                    // onBlur={handleChangeFormat}
                                                                    onFocus={e => e.target.select()}
                                                                    InputLabelProps={{ style: { color: 'black' }}}
                                                                    onChange={(e) =>{
                                                                        field.onChange(e);
                                                                        onChangeBasicCapacity(e,1)
                                                                    }}
                                                                    InputProps={{sx:{height:32,backgroundColor:m_bModePM || modeViewOnly|| processMode > MS_MODE_DRAWING?'floralwhite':'white', color: modeViewOnly? "grey":"black"},
                                                                    endAdornment:(
                                                                        <text style={{color:getValues("m_3Phase.basicCapacityM") != getValues("m_3Phase.basicCapacityA") ? "black" : "grey",fontSize:"30px",marginTop:"15px"}}>*</text>
                                                                    )}}
                                                                    error={!!errors?.m_3Phase?.basicCapacityM}
                                                                    helperText={errors?.m_3Phase?.basicCapacityM?.message}
                                                                    inputProps={{maxLength:4, readOnly:modeViewOnly}}
                                                                    disabled={m_bModePM|| processMode > MS_MODE_DRAWING}

                                                                />
                                                            )}
                                                        />
                                                    </Stack>
                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                        <Typography variant="body2" width="100%">温度補正係数:</Typography>  
                                                        <Controller
                                                            name="m_3Phase.tempK2M"
                                                            control={control}
                                                            rules={{
                                                                required: '必須項目です。入力してください。' ,
                                                                pattern:{
                                                                    value: /^[0-9]*[.]?[0-9]+$/,
                                                                    message: '数字を入力してください。'
                                                                },
                                                                validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                                
                                                                // maxLength: {
                                                                //     value: 4,
                                                                //     message: "最大長さ <= 4 を入力してください。"
                                                                // }
                                                            }}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    // type="text"
                                                                    size="small"
                                                                    sx={{width:"50%"}}

                                                                    // label="温度補正係数"
                                                                    onFocus={e => e.target.select()}
                                                                    InputLabelProps={{ style: { color: 'black' }}}
                                                                    onChange={(e) =>{
                                                                        field.onChange(e);
                                                                        onChangeTempk2(e,1)
                                                                    }}
                                                                    InputProps={{sx:{height:32,backgroundColor:m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING?'floralwhite':'white', color: modeViewOnly? "grey":"black"},
                                                                    endAdornment:(
                                                                        <text style={{color:getValues("m_3Phase.tempK2M") != getValues("m_3Phase.tempK2A") ? "black" : "grey",fontSize:"30px",marginTop:"15px"}}>*</text>
                                                                    )}}
                                                                    error={!!errors?.m_3Phase?.tempK2M}
                                                                    helperText={errors?.m_3Phase?.tempK2M?.message}
                                                                    inputProps={{maxLength:4, readOnly: modeViewOnly}}
                                                                    disabled={m_bModePM|| processMode > MS_MODE_DRAWING}

                                                                />
                                                            )}
                                                        />
                                                    </Stack>
                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                        <Typography variant="body2" width="100%">水平配置補正係数:</Typography>  
                                                        <Controller
                                                            name="m_3Phase.horizontalK3M"
                                                            control={control}
                                                            rules={{
                                                                required: '必須項目です。入力してください。' ,
                                                                pattern:{
                                                                    value: /^[0-9]*[.]?[0-9]+$/,
                                                                    message: '数字を入力してください。'
                                                                },
                                                                validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                                
                                                                // maxLength: {
                                                                //     value: 4,
                                                                //     message: "最大長さ <= 4 を入力してください。"
                                                                // }
                                                            }}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    // type="text"
                                                                    size="small"
                                                                    sx={{width:"50%"}}

                                                                    // label="水平配置補正係数"
                                                                    onFocus={e => e.target.select()}
                                                                    InputLabelProps={{ style: { color: 'black' }}}
                                                                    onChange={(e) =>{
                                                                        field.onChange(e);
                                                                        onChangeHorizontalk3(e,1)
                                                                    }}
                                                                    InputProps={{sx:{height:32,backgroundColor:m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING?'floralwhite':'white', color: modeViewOnly? "grey":"black"},
                                                                    endAdornment:(
                                                                        <text style={{color:getValues("m_3Phase.horizontalK3M") != getValues("m_3Phase.horizontalK3A") ? "black" : "grey",fontSize:"30px",marginTop:"15px"}}>*</text>
                                                                    )}}
                                                                    error={!!errors?.m_3Phase?.horizontalK3M}
                                                                    helperText={errors?.m_3Phase?.horizontalK3M?.message}
                                                                    inputProps={{maxLength:4, readOnly: modeViewOnly}}
                                                                    disabled={m_bModePM|| processMode > MS_MODE_DRAWING}

                                                                />
                                                            )}
                                                        />
                                                    </Stack>
                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                        <Typography variant="body2" width="100%">dm x b / (a x a) = 0.2での近接効果補正係数:</Typography>  
                                                        <Controller
                                                            name="m_3Phase.proximityK4M"
                                                            control={control}
                                                            rules={{
                                                                required: '必須項目です。入力してください。' ,
                                                                pattern:{
                                                                    value: /^[0-9]*[.]?[0-9]+$/,
                                                                    message: '数字を入力してください。'
                                                                },
                                                                validate: value => value > 0 || '数字 > 0 を入力してください。' ,

                                                                // maxLength: {
                                                                //     value: 4,
                                                                //     message: "最大長さ <= 4 を入力してください。"
                                                                // }
                                                            }}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    // type="text"
                                                                    size="small"
                                                                    sx={{width:"50%"}}

                                                                    // label="dm x b / (a x a) = 0.2での近接効果補正係数"
                                                                    onFocus={e => e.target.select()}
                                                                    InputLabelProps={{ style: { color: 'black' }}}
                                                                    onChange={(e) =>{
                                                                        field.onChange(e);
                                                                        onChangeProximityk4(e,1)
                                                                    }}
                                                                    InputProps={{sx:{height:32,backgroundColor:m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING?'floralwhite':'white', color: modeViewOnly? "grey":"black"},
                                                                    endAdornment:(
                                                                        <text style={{color:getValues("m_3Phase.proximityK4M") != getValues("m_3Phase.proximityK4A") ? "black" : "grey",fontSize:"30px",marginTop:"15px"}}>*</text>
                                                                    )}}
                                                                    error={!!errors?.m_3Phase?.proximityK4M}
                                                                    helperText={errors?.m_3Phase?.proximityK4M?.message}
                                                                    inputProps={{maxLength:4, readOnly: modeViewOnly}}
                                                                    disabled={m_bModePM|| processMode > MS_MODE_DRAWING}

                                                                />
                                                            )}
                                                        />
                                                    </Stack>
                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                        <Typography variant="body2" width="100%">通電許容電流(A):</Typography>  
                                                        <Controller
                                                            name="m_3Phase.capacity"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    disabled={true}
                                                                    defaultValue={0}
                                                                    fullWidth
                                                                    sx={{width:"50%"}}

                                                                    type="text"
                                                                    size="small"
                                                                    // label="通電許容電流(A)"
                                                                    onFocus={e => e.target.select()}
                                                                    InputLabelProps={{ style: { color: 'black' }}}
                                                                    InputProps={{sx:{height:32,backgroundColor:'floralwhite'}}}

                                                                />
                                                            )}
                                                        />
                                                    </Stack>
                                                </fieldset>
                                            </Grid>
                                        </Stack>
                                    </Stack>
                                </TabPanel>
                                {/*tab3 電圧降下 */}
                                <TabPanel value={selectTab} index={(setIsDisplayTabConnectTran1||setIsDisplayTabConnectTranscott)?3:isDisplayTabTabSPhase === 2 ? 4 : isDisplayTabTabSPhase === 3 ? 5 : 3}>
                                    <Grid container>
                                            <Grid item xs={10}>
                                                <Controller
                                                        name="dropMotor"
                                                        control={control}
                                                        render={({ field }: any) => (
                                                            <FormControlLabel
                                                                {...field}
                                                                checked={field.value}
                                                                label={<Typography variant="body2" width="100%"  color={m_bModePM|| processMode > MS_MODE_CHECKING?'grey':'black'}>電動機始動時の電圧降下を計算</Typography>}
                                                                name='dropMotor'
                                                                onChange={handleChangeFormatChecked}
                                                                control={<Checkbox style={checkboxStyles} />}
                                                                disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}
                                                                // disabled={currentTabId === true? false:(setIsDisplayTabConnectTran1 || setIsDisplayTabConnectTranscott)}
                                                            
                                                            />
                                                        )}
                                                    />
                                            </Grid>
                                            <Grid item xs={10}>
                                                <Controller
                                                        name="dropRegular"
                                                        control={control}
                                                        render={({ field }: any) => (
                                                            <FormControlLabel
                                                                {...field}
                                                                checked={field.value}
                                                                label={<Typography variant="body2" width="100%" color={m_bModePM|| processMode > MS_MODE_CHECKING?'grey':'black'}>定常時の電圧降下を計算</Typography>}
                                                                name='dropRegular'
                                                                onChange={(e) =>handleDisableByPointRegular(e)} 
                                                                control={<Checkbox style={checkboxStyles} />}
                                                                disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}
                                                            />
                                                        )}
                                                    />
                                            </Grid>
                                            <Grid container>
                                                <Grid item xs={7.5}>
                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                        <Typography variant="body2" width="90%" ml={5}>負荷容量 (VA):</Typography>  
                                                            <Controller
                                                                name="calcPoint0.loadCapacity"
                                                                control={control}
                                                                rules={{
                                                                    pattern:{
                                                                        value: /^[0-9]*[.]?[0-9]+$/,
                                                                        message: '数字を入力してください。'
                                                                    },
                                                                    validate: value => value >= 0 || '数字 >= 0 を入力してください。' ,
                                                                }}
                                                                render={({ field }) => (
                                                                    <TextField
                                                                        {...field}
                                                                        fullWidth
                                                                        // type="text"
                                                                        size="small"
                                                                        // label="負荷容量 (VA)"
                                                                        onFocus={e => e.target.select()}
                                                                        InputLabelProps={{ style: { color: 'black' }}}
                                                                        onChange={(e) =>{
                                                                            field.onChange(e);
                                                                            onChangeLoadCapacity(e,false);
                                                                            // handleDisableOKBt(e);
                                                                                        }}
                                                                        error={!!errors?.calcPoint0?.loadCapacity}
                                                                        helperText={errors?.calcPoint0?.loadCapacity?.message}
                                                                        disabled={!isDisableByPointRegular||m_bModePM|| processMode > MS_MODE_CHECKING}
                                                                        InputProps={{sx:{height:32 ,backgroundColor:m_bModePM||modeViewOnly||!isDisableByPointRegular|| processMode > MS_MODE_CHECKING?'floralwhite':'white', color: modeViewOnly? "grey":"black"}}}
                                                                        inputProps={{readOnly:modeViewOnly}}

                                                                    />
                                                                )}
                                                            />
                                                        </Stack>
                                                </Grid>
                                                <Grid item xs ={7.5}>
                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                        <Typography variant="body2" width="90%" ml={5}>負荷電流 IL(A):</Typography>  
                                                        <Controller
                                                            name="calcPoint0.loadCurrent"
                                                            control={control}
                                                            rules={{
                                                                pattern:{
                                                                    value: /^[0-9]*[.]?[0-9]+$/,
                                                                    message: '数字を入力してください。'
                                                                },
                                                                validate: value => value >= 0 || '数字 >= 0 を入力してください。' ,

                                                                // maxLength: {
                                                                //     value: 7,
                                                                //     message: "最大長さ <= 7 を入力してください。"
                                                                // }
                                                            }}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    // type="text"
                                                                    size="small"
                                                                    onFocus={e => e.target.select()}
                                                                    InputLabelProps={{ style: { color: 'black' }}}
                                                                    onChange={(e) =>{
                                                                        field.onChange(e);
                                                                        onChangeLoadCurrent(e,false);
                                                                        // handleDisableOKBt(e);
                                                                                }}
                                                                    disabled={!isDisableByPointRegular||m_bModePM|| processMode > MS_MODE_CHECKING}
                                                                    InputProps={{sx:{height:32,backgroundColor:m_bModePM||modeViewOnly||!isDisableByPointRegular|| processMode > MS_MODE_CHECKING?'floralwhite':'white', color: modeViewOnly? "grey":"black"}}}
                                                                    error={!!errors?.calcPoint0?.loadCurrent}
                                                                    helperText={errors?.calcPoint0?.loadCurrent?.message}
                                                                    inputProps={{maxLength:7, readOnly: modeViewOnly}}

                                                                />
                                                            )}
                                                        />
                                                    </Stack>
                                                </Grid>
                                            </Grid>
                                            <Grid container ml={6.5}>
                                                <fieldset style={{border:"1px solid #AAAA",width:"53%"}}>
                                                    <legend><Typography variant="body2">負荷電流方向</Typography>  </legend>
                                                    <Controller
                                                            name="topLeft"
                                                            control={control}
                                                            rules={{ required: '必須項目です。入力してください。' }}
                                                            render={({ field }) => (
                                                                    <RadioGroup {...field} onChange={(e) =>onBnClickedTopLeft(e)}>
                                                                        <FormControlLabel
                                                                            value="1"
                                                                            control={<Radio style={radioStyles} />}
                                                                            label={<Typography variant="body2"  color={m_bModePM|| processMode > MS_MODE_CHECKING?'grey':'black'}>上→下 or 左→右</Typography> }
                                                                            disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}
                                                                        />
                                                                        <FormControlLabel
                                                                            value="0"
                                                                            control={<Radio style={radioStyles} />}
                                                                            label={<Typography variant="body2" color={m_bModePM|| processMode > MS_MODE_CHECKING?'grey':'black'}>下→上 or 右→左</Typography> }
                                                                            disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}
                                                                        />
                                                                    </RadioGroup>
                                                            )}
                                                        />
                                                    </fieldset>
                                                    <Stack ml={1.2}>
                                                        <Typography color="common.white">nothing</Typography>
                                                        <Typography color="common.white">nothing</Typography>
                                                        <Button variant="outlined" onClick={(e)=>onBnClickGetLoadCurrent(e,false)} 
                                                                                disabled={!isDisableByPointRegular||m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}
                                                                        >概算値</Button>
                                                    </Stack>
                                            </Grid>
                                            <Grid container ml={3}>
                                                <fieldset style={{width:"76%",border:"1px solid #AAAA"}}>
                                                    <legend>{<Typography variant="body2">回路の力率</Typography> }</legend>
                                                    <Grid container>
                                                        <Grid item xs={5}>
                                                            <Controller
                                                                name="leadOrLag1"
                                                                control={control}
                                                                rules={{ required: '必須項目です。入力してください。' }}
                                                                render={({ field }) => (
                                                                    <RadioGroup {...field} row onChange={(e)=>onBnClickedLead(e,false)}>
                                                                        <FormControlLabel
                                                                            value="1"
                                                                            control={<Radio style={radioStyles} />}
                                                                            label={<Typography style={{color:!isDisableByPointRegular||m_bModePM|| processMode > MS_MODE_CHECKING?"grey":"black"}} variant="body2">進み</Typography> }
                                                                        disabled={!isDisableByPointRegular||m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}

                                                                        />
                                                                        <FormControlLabel
                                                                            value="0"
                                                                            control={<Radio style={radioStyles} />}
                                                                            label={<Typography style={{color:!isDisableByPointRegular||m_bModePM|| processMode > MS_MODE_CHECKING?"grey":"black"}} variant="body2">遅れ</Typography> }
                                                                        disabled={!isDisableByPointRegular||m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}

                                                                        />
                                                                    </RadioGroup>
                                                                )}
                                                            />
                                                        </Grid>
                                                        <Grid xs={1}/>
                                                        <Grid xs={2.75} ml={1.4}>
                                                            <Controller
                                                                name="calcPoint0.powerFactor"
                                                                control={control}
                                                                rules={{
                                                                    pattern:{
                                                                        value: /^[0-9]*[.]?[0-9]+$/,
                                                                        message: '数字を入力してください。'
                                                                    },
                                                                    validate: value => value >= 0 && value <= 1 || '数字 0 ~ 1 を入力してください。' ,
                    
                                                                    // maxLength: {
                                                                    //     value: 7,
                                                                    //     message: "最大長さ <= 7 を入力してください。"
                                                                    // }
                                                                }}
                                                                render={({ field }) => (
                                                                    <TextField
                                                                        {...field}
                                                                        fullWidth
                                                                        // type="text"
                                                                        size="small"
                                                                        onBlur={handleChangeFormat}
                                                                        onFocus={e => e.target.select()}
                                                                        InputLabelProps={{ style: { color: 'black' }}}
                                                                        onChange={(e) =>{ 
                                                                            field.onChange(e);
                                                                            onChangePowerFactor(e,false)
                                                                            // handleDisableOKBt(e);
                                                                        }}
                                                                        disabled={!isDisableByPointRegular||m_bModePM}
                                                                        InputProps={{sx:{height:32,backgroundColor:m_bModePM || modeViewOnly ||!isDisableByPointRegular|| processMode > MS_MODE_CHECKING?'floralwhite':'white',color: modeViewOnly? "grey":"black"}}}
                                                                        error={!!errors?.calcPoint0?.powerFactor}
                                                                        helperText={errors?.calcPoint0?.powerFactor?.message}
                                                                        inputProps={{maxLength:7,readOnly:modeViewOnly}}

                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={2.5} ml={1.64}>
                                                            <Button style={{margin:"0"}} variant="outlined" onClick={(e) =>{onBnClickGetPowerFactor(e,false);}}
                                                                disabled={!isDisableByPointRegular||m_bModePM|| modeViewOnly|| processMode > MS_MODE_CHECKING}
                                                                >概算値</Button>
                                                        </Grid>
                                                    </Grid>
                                                </fieldset>
                                            </Grid>
                                            <Grid container  mt={0.25}>
                                                <Grid container flexDirection={"row"} ml={6} alignItems={"center"}>
                                                    <Grid item xs={4} >
                                                        <Typography variant="body2" width="100%" >単体での電圧降下(V):</Typography>  
                                                    </Grid>
                                                    <Grid item xs={4} ml={-2.6}>
                                                        <Controller
                                                            name="voltageDrop_1"
                                                            control={control}
                                                            
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    type="text"
                                                                    size="small"
                                                                    onBlur={handleChangeFormat}
                                                                    onFocus={e => e.target.select()}
                                                                    InputLabelProps={{ style: { color: 'black'}}}
                                                                    InputProps={{sx:{height:32}}}
                                                                    style={{backgroundColor: 'floralwhite',width:"70%",marginLeft:'20%'}}
                                                                    disabled={true}
                                                                    

                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid container flexDirection={"row"} ml={6} mt={0.25} alignItems={"center"}>
                                                    <Grid item xs={4} >
                                                        <Typography variant="body2" width="100%" >単体での電圧降下率(%):</Typography>  
                                                    </Grid>
                                                    <Grid item xs={4} ml={-2.6}>
                                                        <Controller
                                                            name="voltageDrop"
                                                            control={control}
                                                            
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    type="text"
                                                                    size="small"
                                                                    onBlur={handleChangeFormat}
                                                                    onFocus={e => e.target.select()}
                                                                    InputLabelProps={{ style: { color: 'black'}}}
                                                                    InputProps={{sx:{height:32}}}
                                                                    style={{backgroundColor: 'floralwhite',width:"70%",marginLeft:'20%'}}
                                                                    disabled={true}
                                                                    

                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                </TabPanel>
                        {/*tab3 電圧降下 when connect trans1 or transcott*/}
                        {(setIsDisplayTabConnectTran1 || setIsDisplayTabConnectTranscott) &&
                                <TabPanel value={selectTab} index={4}>
                                    <Grid container>
                                            <Grid item xs={10}>
                                                <Controller
                                                        name="dropMotor"
                                                        control={control}
                                                        render={({ field }: any) => (
                                                            <FormControlLabel
                                                                {...field}
                                                                checked={field.value}
                                                                label={<Typography variant="body2" width="100%"color={m_bModePM|| processMode > MS_MODE_CHECKING?'grey':'black'}>電動機始動時の電圧降下を計算</Typography>}
                                                                name='dropMotor'
                                                                onChange={handleChangeFormatChecked}
                                                                control={<Checkbox style={checkboxStyles} />}
                                                                disabled={m_bModePM|| modeViewOnly || processMode > MS_MODE_CHECKING}
                                                                // disabled={currentTabId === true? false:(setIsDisplayTabConnectTran1 || setIsDisplayTabConnectTranscott)}
                                                            
                                                            />
                                                        )}
                                                    />
                                            </Grid>
                                            <Grid item xs={10}>
                                                <Controller
                                                        name="dropRegular"
                                                        control={control}
                                                        render={({ field }: any) => (
                                                            <FormControlLabel
                                                                {...field}
                                                                checked={field.value}
                                                                label={<Typography variant="body2" width="100%" color={m_bModePM|| processMode > MS_MODE_CHECKING?'grey':'black'}>定常時の電圧降下を計算</Typography>}
                                                                name='dropRegular'
                                                                onChange={(e) =>handleDisableByPointRegular(e)} 
                                                                control={<Checkbox style={checkboxStyles} />}
                                                                disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}
                                                            />
                                                        )}
                                                    />
                                            </Grid>
                                            <Grid container>
                                                <Grid item xs={7.5}>
                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                        <Typography variant="body2" width="90%" ml={5}>負荷容量 (VA):</Typography>  
                                                            <Controller
                                                                name="calcPoint1.loadCapacity"
                                                                control={control}
                                                                rules={{
                                                                    pattern:{
                                                                        value: /^[0-9]*[.]?[0-9]+$/,
                                                                        message: '数字を入力してください。'
                                                                    },
                                                                    validate: value => value >= 0 || '数字 >= 0 を入力してください。' ,
                                                                }}
                                                                render={({ field }) => (
                                                                    <TextField
                                                                        {...field}
                                                                        fullWidth
                                                                        // type="text"
                                                                        size="small"
                                                                        // label="負荷容量 (VA)"
                                                                        onFocus={e => e.target.select()}
                                                                        InputLabelProps={{ style: { color: 'black' }}}
                                                                        onChange={(e) =>{
                                                                            field.onChange(e);
                                                                            onChangeLoadCapacity(e,true);
                                                                            // handleDisableOKBt(e);
                                                                                        }}
                                                                        error={!!errors?.calcPoint1?.loadCapacity}
                                                                        helperText={errors?.calcPoint1?.loadCapacity?.message}
                                                                        disabled={!isDisableByPointRegular||m_bModePM|| processMode > MS_MODE_CHECKING}
                                                                        InputProps={{sx:{height:32,backgroundColor:m_bModePM || modeViewOnly ||!isDisableByPointRegular|| processMode > MS_MODE_CHECKING?'floralwhite':'white',color:modeViewOnly?"grey":"black"}}}
                                                                        inputProps={{readOnly:modeViewOnly}}
                                                                    />
                                                                )}
                                                            />
                                                        </Stack>
                                                </Grid>
                                                <Grid item xs ={7.5}>
                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                        <Typography variant="body2" width="90%" ml={5}>負荷電流 IL(A):</Typography>  
                                                            <Controller
                                                                name="calcPoint1.loadCurrent"
                                                                control={control}
                                                                rules={{
                                                                    pattern:{
                                                                        value: /^[0-9]*[.]?[0-9]+$/,
                                                                        message: '数字を入力してください。'
                                                                    },
                                                                    validate: value => value >= 0 || '数字 >= 0 を入力してください。' ,

                                                                    // maxLength: {
                                                                    //     value: 7,
                                                                    //     message: "最大長さ <= 7 を入力してください。"
                                                                    // }
                                                                }}
                                                                render={({ field }) => (
                                                                    <TextField
                                                                        {...field}
                                                                        fullWidth
                                                                        // type="text"
                                                                        size="small"
                                                                        // label="負荷電流 IL(A)"
                                                                        // onBlur={handleChangeFormat}
                                                                        onFocus={e => e.target.select()}
                                                                        InputLabelProps={{ style: { color: 'black' }}}
                                                                        onChange={(e) =>{
                                                                            field.onChange(e);
                                                                            onChangeLoadCurrent(e,true);
                                                                            // handleDisableOKBt(e);
                                                                                    }}
                                                                        disabled={!isDisableByPointRegular||m_bModePM|| processMode > MS_MODE_CHECKING}
                                                                        InputProps={{sx:{height:32,backgroundColor:m_bModePM|| modeViewOnly||!isDisableByPointRegular|| processMode > MS_MODE_CHECKING?'floralwhite':'white',color:modeViewOnly?"grey":"black"}}}
                                                                        error={!!errors?.calcPoint1?.loadCurrent}
                                                                        helperText={errors?.calcPoint1?.loadCurrent?.message}
                                                                        inputProps={{maxLength:7,readOnly:modeViewOnly}}

                                                                    />
                                                                )}
                                                            />
                                                    </Stack>
                                                </Grid>
                                            </Grid>
                                            <Grid container ml={6.5}>
                                                <fieldset style={{border:"1px solid #AAAA",width:"53%"}}>
                                                    <legend><Typography variant="body2">負荷電流方向</Typography></legend>
                                                        <Controller
                                                                name="topLeft"
                                                                control={control}
                                                                rules={{ required: '必須項目です。入力してください。' }}
                                                                render={({ field }) => (
                                                                        <RadioGroup {...field} onChange={(e) =>onBnClickedTopLeft(e)}>
                                                                            <FormControlLabel
                                                                                value="1"
                                                                                control={<Radio style={radioStyles} />}
                                                                                label={<Typography variant="body2" 
                                                                                color={m_bModePM|| processMode > MS_MODE_CHECKING?'grey':'black'}>上→下 or 左→右</Typography>}
                                                                                disabled={m_bModePM|| modeViewOnly || processMode > MS_MODE_CHECKING}
                                                                            />
                                                                            <FormControlLabel
                                                                                value="0"
                                                                                control={<Radio style={radioStyles} />}
                                                                                label={<Typography variant="body2" color={m_bModePM|| processMode > MS_MODE_CHECKING?'grey':'black'}>下→上 or 右→左</Typography>}
                                                                                disabled={m_bModePM|| modeViewOnly || processMode > MS_MODE_CHECKING}
                                                                            />
                                                                        </RadioGroup>
                                                                )}
                                                            />
                                                        </fieldset>
                                                        <Stack ml={1.2}>
                                                            <Typography color="common.white">nothing</Typography>
                                                            <Typography color="common.white">nothing</Typography>
                                                            <Button variant="outlined" onClick={(e)=>onBnClickGetLoadCurrent(e,true)} 
                                                                                    disabled={!isDisableByPointRegular||m_bModePM|| modeViewOnly || processMode > MS_MODE_CHECKING}
                                                                            >概算値</Button>
                                                        </Stack>
                                            </Grid>
                                            <Grid container ml={3}>
                                                <fieldset style={{width:"76%",border:"1px solid #AAAA"}}>
                                                    <legend>{<Typography variant="body2">回路の力率</Typography>}</legend>
                                                    <Grid container>
                                                        <Grid item xs={5}>
                                                            <Controller
                                                                name="leadOrLag2"
                                                                control={control}
                                                                rules={{ required: '必須項目です。入力してください。' }}
                                                                render={({ field }) => (
                                                                    <RadioGroup {...field} row onChange={(e)=>onBnClickedLead(e,true)}>
                                                                        <FormControlLabel
                                                                            value="1"
                                                                            control={<Radio style={radioStyles} />}
                                                                            label={<Typography style={{color:!isDisableByPointRegular||m_bModePM|| processMode > MS_MODE_CHECKING?"grey":"black"}} variant="body2">進み</Typography>}
                                                                        disabled={!isDisableByPointRegular||m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}
                                                                        />
                                                                        <FormControlLabel
                                                                            value="0"
                                                                            control={<Radio style={radioStyles} />}
                                                                            label={<Typography style={{color:!isDisableByPointRegular||m_bModePM|| processMode > MS_MODE_CHECKING?"grey":"black"}} variant="body2">遅れ</Typography>}
                                                                        disabled={!isDisableByPointRegular||m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}

                                                                        />
                                                                    </RadioGroup>
                                                                )}
                                                            />
                                                        </Grid>
                                                        <Grid xs={1}/>
                                                        <Grid xs={2.75} ml={1.4}>
                                                            <Controller
                                                                name="calcPoint1.powerFactor"
                                                                control={control}
                                                                rules={{
                                                                    pattern:{
                                                                        value: /^[0-9]*[.]?[0-9]+$/,
                                                                        message: '数字を入力してください。'
                                                                    },
                                                                    validate: value => value >= 0 && value <= 1 || '数字 0 ~ 1 を入力してください。' ,
                    
                                                                    // maxLength: {
                                                                    //     value: 7,
                                                                    //     message: "最大長さ <= 7 を入力してください。"
                                                                    // }
                                                                }}
                                                                render={({ field }) => (
                                                                    <TextField
                                                                        {...field}
                                                                        fullWidth
                                                                        // type="text"
                                                                        size="small"
                                                                        onBlur={handleChangeFormat}
                                                                        onFocus={e => e.target.select()}
                                                                        InputLabelProps={{ style: { color: 'black' }}}
                                                                        onChange={(e) =>{ 
                                                                            field.onChange(e);
                                                                            onChangePowerFactor(e,true)
                                                                            // handleDisableOKBt(e);
                                                                        }}
                                                                        disabled={!isDisableByPointRegular||m_bModePM|| processMode > MS_MODE_CHECKING}
                                                                        InputProps={{sx:{height:32,backgroundColor:m_bModePM|| modeViewOnly ||!isDisableByPointRegular|| processMode > MS_MODE_CHECKING?'floralwhite':'white',color:modeViewOnly?"grey":"black"}}}
                                                                        error={!!errors?.calcPoint1?.powerFactor}
                                                                        helperText={errors?.calcPoint1?.powerFactor?.message}
                                                                        inputProps={{maxLength:7,readOnly:modeViewOnly}}

                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={2.5} ml={1.64}>
                                                            <Button style={{margin:"0"}} variant="outlined" onClick={(e) =>{onBnClickGetPowerFactor(e,true);}}
                                                                        disabled={!isDisableByPointRegular||m_bModePM|| modeViewOnly|| processMode > MS_MODE_CHECKING}
                                                                        >概算値</Button>
                                                        </Grid>
                                                    </Grid>
                                                </fieldset>
                                            </Grid>
                                            <Grid container  mt={0.25}>
                                                <Grid container flexDirection={"row"} ml={6} alignItems={"center"}>
                                                    <Grid item xs={4} >
                                                        <Typography variant="body2" width="100%" >単体での電圧降下(V):</Typography>  
                                                    </Grid>
                                                    <Grid item xs={4} ml={-2.6}>
                                                        <Controller
                                                            name="voltageDrop_1_2"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    type="text"
                                                                    size="small"
                                                                    // label="単体での電圧降下(V)"
                                                                    onBlur={handleChangeFormat}
                                                                    onFocus={e => e.target.select()}
                                                                    InputLabelProps={{ style: { color: 'black'}}}
                                                                    InputProps={{sx:{height:32}}}
                                                                    style={{backgroundColor: 'floralwhite' ,width:"70%",marginLeft:'20%'}}
                                                                    disabled={true}


                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid container flexDirection={"row"} ml={6} mt={0.25} alignItems={"center"}>
                                                    <Grid item xs={4} >
                                                        <Typography variant="body2" width="100%" >単体での電圧降下率(%):</Typography>  
                                                    </Grid>
                                                    <Grid item xs={4} ml={-2.6}>
                                                        <Controller
                                                            name="voltageDrop2"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    type="text"
                                                                    size="small"
                                                                    // label="単体での電圧降下率(%)"
                                                                    onBlur={handleChangeFormat}
                                                                    onFocus={e => e.target.select()}
                                                                    InputLabelProps={{ style: { color: 'black' 
                                                                                                }}}
                                                                    InputProps={{sx:{height:32}}}
                                                                    style={{backgroundColor: 'floralwhite',width:"70%",marginLeft:'20%'}}

                                                                    disabled={true}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                </TabPanel>
                            }

                                {/* tab4 潮流解析*/}
                                <TabPanel value={selectTab} index={(setIsDisplayTabConnectTran1||setIsDisplayTabConnectTranscott)?5:isDisplayTabTabSPhase === 2 ? 5 : isDisplayTabTabSPhase === 3 ? 6 : 4}>
                                    <Stack spacing={0.5} style={{ width: '100%',marginTop:'10px' }}>
                                                <fieldset style={{width:"70%",border:"1px solid #AAAA"}}>
                                                    <legend><Typography variant="body2" width="100%"> 対中性点アドミタンス (基準容量に対する pu値)</Typography> </legend>
                                                    <Grid container item xs={10}>
                                                        <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                                <Typography variant="body2" width="100%">サセプタンス B(pu値):</Typography>  
                                                            <Controller
                                                                name="flowSusceptance"
                                                                control={control}
                                                                render={({ field }) => (
                                                                    <TextField
                                                                        {...field}
                                                                        fullWidth
                                                                        type="text"
                                                                        size="small"
                                                                        // label="サセプタンス B(pu値)"
                                                                        onBlur={handleChangeFormat}
                                                                        onFocus={e => e.target.select()}
                                                                        InputLabelProps={{ style: { color: 'black' }}}
                                                                        InputProps={{sx:{height:32,backgroundColor:m_bModePM|| modeViewOnly|| processMode > MS_MODE_CHECKING?'floralwhite':'white',color: modeViewOnly? "grey":"black"}}}
                                                                        inputProps={{maxLength:6,readOnly:modeViewOnly}}
                                                                        disabled={m_bModePM|| processMode > MS_MODE_CHECKING}


                                                                    />
                                                                )}
                                                            />
                                                        </Stack>
                                                    </Grid>
                                                </fieldset>
                                            
                                            <Stack>
                                                <Grid container>
                                                    <Grid item xs={3.8}/>
                                                    <Grid xs={6}>
                                                        <Controller
                                                            name="viewFlowResult"        
                                                            control={control}
                                                            render={({ field }: any) => (
                                                                <FormControlLabel 
                                                                    {...field} 
                                                                    checked={field.value}
                                                                    label={<Typography variant="body2" width="100%" color={m_bModePM|| processMode > MS_MODE_CHECKING?'grey':'black'}>潮流解析結果を表示</Typography>}
                                                                    name='viewFlowResult'
                                                                    onClick={handleChangeFormat}
                                                                    control={<Checkbox style={checkboxStyle} />} 
                                                                    disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Stack>
                                        </Stack>
                                </TabPanel>

                                {/*tab5 中性線(N相) */}
                                {((isDisplayTabTabSPhase === 2 || isDisplayTabTabSPhase ===3) && !isModeSPhases) &&
                                <TabPanel value={selectTab} index={3}>
                                    <Stack spacing={3} style={{ width: '100%'}} marginTop={1}>
                                        <Stack>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                        <Typography variant="body2" width="100%">中性線のブスバーの幅 b(mm):</Typography>  
                                                            <FormControl fullWidth size="small">
                                                                <Controller
                                                                    name="phaseN.width"
                                                                    control={control}
                                                                    rules={{        
                                                                        pattern:{
                                                                            value: /^[0-9\b]+$/,
                                                                            message: '整数 >= 0を入力してください。'
                                                                        },                                  
                                                                        validate: value => value >= 0 || '整数 >= 0を入力してください。' ,
                                                                }}
                                                                    render={({ field }) => (
                                                                        <TextFieldOptions
                                                                            {...field}
                                                                            options={getValues("phaseN.widthList")}
                                                                            // label="中性線のブスバーの幅 b(mm)"
                                                                            // onChange= {(e) =>handleChangeFormat(e)}
                                                                            size="small"
                                                                            setValue={(name: any, value: any) => {
                                                                                onChangeEditChangeWidth({target:{name:name,value:value}},2)
                                                                                // onChangeFields({target:{name:name,value:value}},2);
                                                                                // onChangeWidth({target:{name:name,value:value}},2);
                                                                                // handleDisableOKBt({target:{name:name,value:value}});

                                                                            }}
                                                                            height={32}
                                                                            error={!!errors?.phaseN?.width}
                                                                            helperText={errors?.phaseN?.width?.message}
                                                                            maxLength={8}
                                                                            disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}
                                                                                    />
                                                                        // <Select
                                                                        //     {...field}
                                                                        //     label="中性線のブスバーの幅 b(mm)"
                                                                        //     // error={!!errors?.field26}
                                                                        //     onChange={handleChangeFormat}
                                                                        // >
                                                                        //     {field12Options.map(item => (
                                                                        //         <MenuItem key={item.label} value={item.value}>
                                                                        //             {item.label}
                                                                        //         </MenuItem>
                                                                        //     ))}
                                                                        // </Select>
                                                                    )}
                                                                />
                                                            </FormControl>
                                                    </Stack>
                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                        <Typography variant="body2" width="100%">中性線のブスバーの厚さ(mm):</Typography>  
                                                            <FormControl fullWidth size="small">
                                                                {/* <InputLabel style={{ color: 'black' }}>中性線のブスバーの厚さ(mm)</InputLabel> */}
                                                                <Controller
                                                                    name="phaseN.thickness"
                                                                    control={control}
                                                                    rules={{        
                                                                        pattern:{
                                                                            value: /^[0-9\b]+$/,
                                                                            message: '整数 >= 0を入力してください。'
                                                                        },                                  
                                                                        validate: value => value >= 0 || '整数 >= 0を入力してください。' ,
                                                                }}
                                                                    render={({ field }) => (
                                                                        <TextFieldOptions
                                                                            {...field}
                                                                            options={getValues("phaseN.thicknessList")}
                                                                            // label="中性線のブスバーの厚さ(mm)"
                                                                            // onChange= {(e) =>handleChangeFormat(e)}
                                                                            size="small"
                                                                            setValue={(name: any, value: any) => {
                                                                                onChangeEditChangeWidth({target:{name:name,value:value}},2)
                                                                                // doSelComboThickness({target:{name:name,value:value}},2);
                                                                                // // onChangeFields({target:{name:name,value:value}},2);
                                                                                // handleDisableOKBt({target:{name:name,value:value}})

                                                                            }}
                                                                            height={32}
                                                                            error={!!errors?.phaseN?.thickness}
                                                                            helperText={errors?.phaseN?.thickness?.message}
                                                                            maxLength={8}
                                                                            disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}
                                                                                    />

                                                                        // <Select
                                                                        //     {...field}
                                                                        //     label="中性線のブスバーの厚さ(mm)"
                                                                        //     // error={!!errors?.field27}
                                                                        //     onChange={handleChangeFormat}
                                                                        // >
                                                                        //     {field13Options.map(item => (
                                                                        //         <MenuItem key={item.label} value={item.value}>
                                                                        //             {item.label}
                                                                        //         </MenuItem>
                                                                        //     ))}
                                                                        // </Select>
                                                                    )}
                                                                />
                                                            </FormControl>
                                                    </Stack>
                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                        <Typography variant="body2" width="100%">中性線の枚数:</Typography>  
                                                        <FormControl fullWidth size="small">
                                                            {/* <InputLabel style={{ color: 'black' }}>中性線の枚数</InputLabel> */}
                                                            <Controller
                                                                name="phaseN.numberPhase"
                                                                control={control}
                                                                rules={{        
                                                                    pattern:{
                                                                        value: /^[0-9\b]+$/,
                                                                        message: '整数 >= 0を入力してください。'
                                                                    },                                  
                                                                    validate: value => value >= 0 || '整数 >= 0を入力してください。' ,
                                                            }}
                                                                render={({ field }) => (
                                                                    <TextFieldOptions
                                                                        {...field}
                                                                        options={getValues("phaseN.numberPhaseList")}
                                                                        // label="中性線の枚数"
                                                                        // onChange= {(e) =>handleChangeFormat(e)}
                                                                        size="small"
                                                                        setValue={(name: any, value: any) => {
                                                                            onChangeEditChangeWidth({target:{name:name,value:value}},2)
                                                                            // doSelComboNumberPhase({target:{name:name,value:value}},2);
                                                                            // // onChangeFields({target:{name:name,value:value}},2);
                                                                            // handleDisableOKBt({target:{name:name,value:value}})
                                                                        }}
                                                                        height={32}
                                                                        error={!!errors?.phaseN?.numberPhase}
                                                                        helperText={errors?.phaseN?.numberPhase?.message}
                                                                        maxLength={8}
                                                                        disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}

                                                                                />
                                                                    // <Select
                                                                    //     {...field}
                                                                    //     label="中性線の枚数"
                                                                    //     // error={!!errors?.field28}
                                                                    //     onChange={handleChangeFormat}
                                                                    // >
                                                                    //     {field14Options.map(item => (
                                                                    //         <MenuItem key={item.label} value={item.value}>
                                                                    //             {item.label}
                                                                    //         </MenuItem>
                                                                    //     ))}
                                                                    // </Select>
                                                                )}
                                                            />
                                                        </FormControl>
                                                    </Stack>
                                                </Grid>
                                            </Grid>
                                            <Grid container mt={1}>
                                                <Grid item xs={12}>
                                                    <fieldset style={{border:"1px solid #AAAA"}}>
                                                        <legend><Typography variant="body2">インピーダンス</Typography></legend>
                                                        
                                                                <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                                    <Typography variant="body2" width="100%">相間の中心間距離 a(mm):</Typography>  
                                                                    <Controller
                                                                        name="phaseN.distance"
                                                                        control={control}
                                                                        rules={{        
                                                                            required: '必須項目です。入力してください。' ,
                                                                            pattern:{
                                                                                value: /^[0-9\b]+$/,
                                                                                message: '整数 >= 0を入力してください。'
                                                                            },                                  
                                                                            validate: value => value >= 0 || '整数 >= 0を入力してください。' ,
                                                                        }}
                                                                        render={({ field }) => (
                                                                            <TextField
                                                                                {...field}
                                                                                fullWidth
                                                                                // type="text"
                                                                                size="small"
                                                                                // label="相間の中心間距離 a(mm)"
                                                                                // onBlur={handleChangeFormat}
                                                                                onFocus={e => e.target.select()}
                                                                                InputLabelProps={{ style: { color: 'black' }}}
                                                                                onChange={(e) =>{
                                                                                    field.onChange(e);
                                                                                    onChangeDistance(e,2)}}

                                                                                InputProps={{sx:{height:32,backgroundColor:m_bModePM|| processMode > MS_MODE_CHECKING?'floralwhite':'white', color:modeViewOnly?"grey":"black"}}}
                                                                                error={!!errors?.phaseN?.distance}
                                                                                helperText={errors?.phaseN?.distance?.message}
                                                                                inputProps={{maxLength:3,readOnly:modeViewOnly}}
                                                                                disabled={m_bModePM|| processMode > MS_MODE_CHECKING}

                                                                            />
                                                                        )}
                                                                    />
                                                                </Stack>
                                                                <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                                    <Typography variant="body2" width="100%">表皮効果 ks:</Typography>  
                                                                    <Controller
                                                                        name="phaseN.skinEffectM"
                                                                        control={control}
                                                                        rules={{
                                                                            required: '必須項目です。入力してください。' ,
                                                                            pattern:{
                                                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                                                message: '数字を入力してください。'
                                                                            },
                                                                        validate: value => value > 0 || '数字 > 0 を入力してください。' ,

                                                                            // maxLength: {
                                                                            //     value: 4,
                                                                            //     message: "最大長さ <= 4 を入力してください。"
                                                                            // }
                                                                        }}
                                                                        render={({ field }) => (
                                                                            <TextField
                                                                                {...field}
                                                                                fullWidth
                                                                                // type="text"
                                                                                size="small"
                                                                                // label="表皮効果 ks"
                                                                                onFocus={e => e.target.select()}
                                                                                InputLabelProps={{ style: { color: 'black' }}}
                                                                                onChange={(e) =>{
                                                                                    field.onChange(e);
                                                                                    onChangeSkinEffect(e,2)}}
                                                                                InputProps={{sx:{height:32,backgroundColor:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white', color:modeViewOnly?"grey":"black"},
                                                                                endAdornment:(
                                                                                    <text style={{color:getValues("phaseN.skinEffectM") != getValues("phaseN.skinEffectA") ? "black" : "grey",fontSize:"30px",marginTop:"15px"}}>*</text>
                                                                                )}}
                                                                                error={!!errors?.phaseN?.skinEffectM}
                                                                                helperText={errors?.phaseN?.skinEffectM?.message}
                                                                                inputProps={{maxLength:4, readOnly:modeViewOnly}}
                                                                                disabled={m_bModePM|| processMode > MS_MODE_CHECKING}

                                                                            />
                                                                        )}
                                                                    />
                                                                </Stack>
                                                                <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                                    <Typography variant="body2" width="100%">1m当りの抵抗 (mΩ/m):</Typography> 
                                                                    <Controller
                                                                        name="phaseN.ohmRPerM"
                                                                        control={control}
                                                                        rules={{
                                                                            required: '必須項目です。入力してください。' ,
                                                                            pattern:{
                                                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                                                message: '数字を入力してください。'
                                                                            },
                                                                        validate: value => value > 0 || '数字 > 0 を入力してください。' ,

                                                                            // maxLength: {
                                                                            //     value: 7,
                                                                            //     message: "最大長さ <= 7 を入力してください。"
                                                                            // }
                                                                        }}
                                                                        render={({ field }) => (
                                                                            <TextField
                                                                                {...field}
                                                                                fullWidth
                                                                                // type="text"
                                                                                size="small"
                                                                                // label="1m当りの抵抗 (mΩ/m)"
                                                                                onFocus={e => e.target.select()}
                                                                                InputLabelProps={{ style: { color: 'black' }}}
                                                                                onChange={(e) =>{
                                                                                    field.onChange(e);
                                                                                    onChangeOhmRperper(e,2)
                                                                                }}
                                                                                InputProps={{sx:{height:32,backgroundColor:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white', color:modeViewOnly? "grey":"black"},
                                                                                endAdornment:(
                                                                                    <text style={{color:getValues("phaseN.ohmRPerM") != getValues("phaseN.ohmRPerA") ? "black" : "grey",fontSize:"30px",marginTop:"15px"}}>*</text>
                                                                                )}}
                                                                                error={!!errors?.phaseN?.ohmRPerM}
                                                                                helperText={errors?.phaseN?.ohmRPerM?.message}
                                                                                inputProps={{maxLength:7,readOnly:modeViewOnly}}
                                                                                disabled={m_bModePM|| processMode > MS_MODE_CHECKING}

                                                                            />
                                                                        )}
                                                                    />
                                                                </Stack>
                                                                <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                                    <Typography variant="body2" width="100%">抵抗(mΩ):</Typography> 
                                                                    <Controller
                                                                        name="phaseN.ohmR"
                                                                        control={control}
                                                                        render={({ field }) => (
                                                                            <TextField
                                                                                {...field}
                                                                                disabled={true}
                                                                                defaultValue={0}
                                                                                fullWidth
                                                                                type="text"
                                                                                size="small"
                                                                                // label="抵抗(mΩ)"
                                                                                onFocus={e => e.target.select()}
                                                                                InputLabelProps={{ style: { color: 'black' }}}
                                                                                InputProps={{sx:{height:32,backgroundColor:'floralwhite'}}}

                                                                            />
                                                                        )}
                                                                    />
                                                                </Stack>
                                                                <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                                    <Typography variant="body2" width="100%">1m当りのリアクタンス(mΩ/m):</Typography> 
                                                                    <Controller
                                                                        name="phaseN.ohmXPerM"
                                                                        control={control}
                                                                        rules={{
                                                                            required: '必須項目です。入力してください。' ,
                                                                            pattern:{
                                                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                                                message: '数字を入力してください。'
                                                                            },
                                                                        validate: value => value > 0 || '数字 > 0 を入力してください。' ,

                                                                            // maxLength: {
                                                                            //     value: 7,
                                                                            //     message: "最大長さ <= 7 を入力してください。"
                                                                            // }
                                                                        }}
                                                                        render={({ field }) => (
                                                                            <TextField
                                                                                {...field}
                                                                                fullWidth
                                                                                // type="text"
                                                                                size="small"
                                                                                // label="1m当りのリアクタンス(mΩ/m)"
                                                                                onFocus={e => e.target.select()}
                                                                                InputLabelProps={{ style: { color: 'black' }}}
                                                                                onChange={(e) =>{
                                                                                    field.onChange(e);
                                                                                    onChangeOhmXperper(e,2)
                                                                                            }}
                                                                                InputProps={{sx:{height:32,backgroundColor:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white',color:modeViewOnly?"grey":"black"},
                                                                                endAdornment:(
                                                                                    <text style={{color:getValues("phaseN.ohmXPerM") != getValues("phaseN.ohmXPerA") ? "black" : "grey",fontSize:"30px",marginTop:"15px"}}>*</text>
                                                                                )}}
                                                                                error={!!errors?.phaseN?.ohmXPerM}
                                                                                helperText={errors?.phaseN?.ohmXPerM?.message}
                                                                                inputProps={{maxLength:7,readOnly:modeViewOnly}}
                                                                                disabled={m_bModePM|| processMode > MS_MODE_CHECKING}

                                                                            />
                                                                        )}
                                                                    />
                                                                </Stack>
                                                                <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                                    <Typography variant="body2" width="100%">リアクタンス(mΩ):</Typography> 
                                                                    <Controller
                                                                        name="phaseN.ohmX"
                                                                        control={control}
                                                                        render={({ field }) => (
                                                                            <TextField
                                                                                {...field}
                                                                                disabled={true}
                                                                                defaultValue={0}
                                                                                fullWidth
                                                                                type="text"
                                                                                size="small"
                                                                                // label="リアクタンス(mΩ)"
                                                                                onFocus={e => e.target.select()}
                                                                                InputLabelProps={{ style: { color: 'black' }}}
                                                                                InputProps={{sx:{height:32,backgroundColor:'floralwhite'}}}

                                                                            />
                                                                        )}
                                                                    />
                                                                </Stack>
                                                    </fieldset>
                                                </Grid>
                                            </Grid>
                                            <Grid container mt={1}>
                                                <fieldset style={{border:"1px solid #AAAA"}}>
                                                    <legend><Typography variant="body2">許容電流</Typography></legend>
                                                    <Grid item xs={12}>                        
                                                        <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                            <Typography variant="body2" width="100%">基本通電許容電流(A):</Typography> 
                                                            <Controller
                                                                name="phaseN.basicCapacityM"
                                                                control={control}
                                                                rules={{
                                                                    required: '必須項目です。入力してください。' ,
                                                                    pattern:{
                                                                        value: /^[0-9]*[.]?[0-9]+$/,
                                                                        message: '数字を入力してください。'
                                                                    },
                                                                    validate: value => value > 0 || '数字 > 0 を入力してください。' ,

                                                                    // maxLength: {
                                                                    //     value: 4,
                                                                    //     message: "最大長さ <= 4 を入力してください。"
                                                                    // }
                                                                }}
                                                                render={({ field }) => (
                                                                    <TextField
                                                                        {...field}
                                                                        fullWidth
                                                                        // type="text"
                                                                        size="small"
                                                                        sx={{width:"50%"}}

                                                                        // label="基本通電許容電流(A)"
                                                                        onFocus={e => e.target.select()}
                                                                        InputLabelProps={{ style: { color: 'black' }}}
                                                                        onChange={(e) =>{
                                                                            field.onChange(e);
                                                                            onChangeBasicCapacity(e,2)
                                                                        }}
                                                                        InputProps={{sx:{height:32,backgroundColor:m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING?'floralwhite':'white', color:modeViewOnly?"grey":"black"},
                                                                        endAdornment:(
                                                                            <text style={{color:getValues("phaseN.basicCapacityM") != getValues("phaseN.basicCapacityA") ? "black" : "grey",fontSize:"30px",marginTop:"15px"}}>*</text>
                                                                        )}}
                                                                        error={!!errors?.phaseN?.basicCapacityM}
                                                                        helperText={errors?.phaseN?.basicCapacityM?.message}
                                                                        inputProps={{maxLength:4,readOnly:modeViewOnly}}
                                                                        disabled={m_bModePM|| processMode > MS_MODE_DRAWING}
                                                                    

                                                                    />
                                                                )}
                                                            />
                                                        </Stack>
                                                        <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                            <Typography variant="body2" width="100%">温度補正係数:</Typography> 
                                                            <Controller
                                                                name="phaseN.tempK2M"
                                                                control={control}
                                                                rules={{
                                                                    required: '必須項目です。入力してください。' ,
                                                                    pattern:{
                                                                        value: /^[0-9]*[.]?[0-9]+$/,
                                                                        message: '数字を入力してください。'
                                                                    },
                                                                    validate: value => value > 0 || '数字 > 0 を入力してください。' ,

                                                                    // maxLength: {
                                                                    //     value: 4,
                                                                    //     message: "最大長さ <= 4 を入力してください。"
                                                                    // }
                                                                }}
                                                                render={({ field }) => (
                                                                    <TextField
                                                                        {...field}
                                                                        fullWidth
                                                                        // type="text"
                                                                        size="small"
                                                                        sx={{width:"50%"}}
                                                                        // label="温度補正係数"
                                                                        onFocus={e => e.target.select()}
                                                                        InputLabelProps={{ style: { color: 'black' }}}
                                                                        onChange={(e) =>{
                                                                            field.onChange(e);
                                                                            onChangeTempk2(e,2)
                                                                        }}
                                                                        InputProps={{sx:{height:32,backgroundColor:m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING?'floralwhite':'white', color:modeViewOnly?"grey":"black"},
                                                                        endAdornment:(
                                                                            <text style={{color:getValues("phaseN.tempK2M") != getValues("phaseN.tempK2A") ? "black" : "grey",fontSize:"30px",marginTop:"15px"}}>*</text>
                                                                        )}}
                                                                        error={!!errors?.phaseN?.tempK2M}
                                                                        helperText={errors?.phaseN?.tempK2M?.message}
                                                                        inputProps={{maxLength:4,readOnly:modeViewOnly}}
                                                                        disabled={m_bModePM|| processMode > MS_MODE_DRAWING}

                                                                    />
                                                                )}
                                                            />
                                                        </Stack>
                                                        <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                            <Typography variant="body2" width="100%">水平配置補正係数:</Typography> 
                                                            <Controller
                                                                name="phaseN.horizontalK3M"
                                                                control={control}
                                                                rules={{
                                                                    required: '必須項目です。入力してください。' ,
                                                                    pattern:{
                                                                        value: /^[0-9]*[.]?[0-9]+$/,
                                                                        message: '数字を入力してください。'
                                                                    },
                                                                    validate: value => value > 0 || '数字 > 0 を入力してください。' ,

                                                                    // maxLength: {
                                                                    //     value: 4,
                                                                    //     message: "最大長さ <= 4 を入力してください。"
                                                                    // }
                                                                }}
                                                                render={({ field }) => (
                                                                    <TextField
                                                                        {...field}
                                                                        fullWidth
                                                                        // type="text"
                                                                        size="small"
                                                                        sx={{width:"50%"}}
                                                                        // label="水平配置補正係数"
                                                                        onFocus={e => e.target.select()}
                                                                        InputLabelProps={{ style: { color: 'black' }}}
                                                                        onChange={(e) =>{
                                                                            field.onChange(e);
                                                                            onChangeHorizontalk3(e,2)
                                                                        }}
                                                                        InputProps={{sx:{height:32,backgroundColor:m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING?'floralwhite':'white',color:modeViewOnly?"grey":"black"},
                                                                        endAdornment:(
                                                                            <text style={{color:getValues("phaseN.horizontalK3M") != getValues("phaseN.horizontalK3A") ? "black" : "grey",fontSize:"30px",marginTop:"15px"}}>*</text>
                                                                        )}}
                                                                        error={!!errors?.phaseN?.horizontalK3M}
                                                                        helperText={errors?.phaseN?.horizontalK3M?.message}
                                                                        inputProps={{maxLength:4,readOnly:modeViewOnly}}
                                                                        disabled={m_bModePM|| processMode > MS_MODE_DRAWING}

                                                                    />
                                                                )}
                                                            />
                                                        </Stack>
                                                        <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                            <Typography variant="body2" width="100%">dm x b / (a x a) = 0.2での近接効果補正係数:</Typography> 
                                                            <Controller
                                                                name="phaseN.proximityK4M"
                                                                control={control}
                                                                rules={{
                                                                    required: '必須項目です。入力してください。' ,
                                                                    pattern:{
                                                                        value: /^[0-9]*[.]?[0-9]+$/,
                                                                        message: '数字を入力してください。'
                                                                    },
                                                                    validate: value => value > 0 || '数字 > 0 を入力してください。' ,

                                                                    // maxLength: {
                                                                    //     value: 4,
                                                                    //     message: "最大長さ <= 4 を入力してください。"
                                                                    // }
                                                                }}
                                                                render={({ field }) => (
                                                                    <TextField
                                                                        {...field}
                                                                        fullWidth
                                                                        // type="text"
                                                                        size="small"
                                                                        sx={{width:"50%"}}
                                                                        // label="dm x b / (a x a) = 0.2での近接効果補正係数"
                                                                        onFocus={e => e.target.select()}
                                                                        InputLabelProps={{ style: { color: 'black' }}}
                                                                        onChange={(e) =>{
                                                                            field.onChange(e);
                                                                            onChangeProximityk4(e,2)
                                                                        }}
                                                                        InputProps={{sx:{height:32,backgroundColor:m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING?'floralwhite':'white', color:modeViewOnly?"grey":"black"},
                                                                        endAdornment:(
                                                                            <text style={{color:getValues("phaseN.proximityK4M") != getValues("phaseN.proximityK4A") ? "black" : "grey",fontSize:"30px",marginTop:"15px"}}>*</text>
                                                                        )}}
                                                                        error={!!errors?.phaseN?.proximityK4M}
                                                                        helperText={errors?.phaseN?.proximityK4M?.message}
                                                                        inputProps={{maxLength:4,readOnly:modeViewOnly}}
                                                                        disabled={m_bModePM|| processMode > MS_MODE_DRAWING}

                                                                    />
                                                                )}
                                                            />
                                                        </Stack>
                                                        <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                            <Typography variant="body2" width="100%">通電許容電流(A):</Typography> 
                                                            <Controller
                                                                name="phaseN.capacity"
                                                                control={control}
                                                                render={({ field }) => (
                                                                    <TextField
                                                                        {...field}
                                                                        disabled={true}
                                                                        defaultValue={0}
                                                                        fullWidth
                                                                        type="text"
                                                                        size="small"
                                                                        sx={{width:"50%"}}
                                                                        // label="通電許容電流(A)"
                                                                        onFocus={e => e.target.select()}
                                                                        InputLabelProps={{ style: { color: 'black' }}}
                                                                        InputProps={{sx:{height:32,backgroundColor:'floralwhite'}}}

                                                                    />
                                                                )}
                                                            />
                                                        </Stack>
                                                    </Grid>
                                                </fieldset>
                                            </Grid>
                                        </Stack>
                                    </Stack>
                                </TabPanel>
                                }

                                {/*tab6 保護接地線(PE相) */}
                                {(isDisplayTabTabSPhase === 3 && !isModeSPhases) &&
                                <TabPanel value={selectTab} index={4}>
                                    <Stack spacing={3} style={{ width: '100%'}} marginTop={1}>
                                        <Stack>
                                            <Grid container>
                                                <Grid item xs ={12}>       
                                                        <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                            <Typography variant="body2" width="100%">保護接地線のブスバーの幅 b(mm):</Typography> 
                                                            <FormControl fullWidth size="small">
                                                                {/* <InputLabel style={{ color: 'black' }}>保護接地線のブスバーの幅 b(mm)</InputLabel> */}
                                                                <Controller
                                                                    name="phaseNPE.width"
                                                                    control={control}
                                                                    rules={{ 
                                                                        validate: value => value >= 0 || '数字 >= 0 を入力してください。' ,

                                                                    }}
                                                                    render={({ field }) => (
                                                                        <TextFieldOptions
                                                                            {...field}
                                                                            options={getValues("phaseNPE.widthList")}
                                                                            // label="保護接地線のブスバーの幅 b(mm)"
                                                                            // onChange= {(e) =>handleChangeFormat(e)}
                                                                            size="small"
                                                                            setValue={(name: any, value: any) => {
                                                                                onChangeEditChangeWidth({target:{name:name,value:value}},3)
                                                                                // onChangeFields({target:{name:name,value:value}},3);
                                                                                // handleDisableOKBt({target:{name:name,value:value}})
                                                                                // onChangeWidth({target:{name:name,value:value}},3)
                                                                            }}
                                                                            height={32}
                                                                            error={!!errors?.phaseNPE?.width}
                                                                            helperText={errors?.phaseNPE?.width?.message}
                                                                            maxLength={8}
                                                                            disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING}
                                                                                    />
                                                                       
                                                                    )}
                                                                />
                                                            </FormControl>
                                                        </Stack>
                                                        <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                            <Typography variant="body2" width="100%">保護接地線のブスバーの厚さ(mm):</Typography> 
                                                                <FormControl fullWidth size="small">
                                                                    <Controller
                                                                        name="phaseNPE.thickness"
                                                                        control={control}
                                                                        rules={{                                                            
                                                                            validate: value => value >= 0 || '数字 >= 0 を入力してください。' ,
                                                                    }}
                                                                        render={({ field }) => (
                                                                            <TextFieldOptions
                                                                                {...field}
                                                                                options={getValues("phaseNPE.thicknessList")}
                                                                                // label="保護接地線のブスバーの厚さ(mm)"
                                                                                // onChange= {(e) =>handleChangeFormat(e)}
                                                                                size="small"
                                                                                setValue={(name: any, value: any) => {
                                                                                     onChangeEditChangeWidth({target:{name:name,value:value}},3)

                                                                                    // doSelComboThickness({target:{name:name,value:value}},3);
                                                                                    // // onChangeFields({target:{name:name,value:value}},3);
                                                                                    // handleDisableOKBt({target:{name:name,value:value}})

                                                                                }}
                                                                                height={32}
                                                                                error={!!errors?.phaseNPE?.thickness}
                                                                                helperText={errors?.phaseNPE?.thickness?.message}
                                                                                maxLength={8}
                                                                                disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING}

                                                                                />
                                                                        )}
                                                                    />
                                                                </FormControl>
                                                        </Stack>
                                                        <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                            <Typography variant="body2" width="100%">保護接地線の枚数:</Typography> 
                                                            <FormControl fullWidth size="small">
                                                                {/* <InputLabel style={{ color: 'black' }}>保護接地線の枚数</InputLabel> */}
                                                                <Controller
                                                                    name="phaseNPE.numberPhase"
                                                                    control={control}
                                                                    rules={{ 
                                                                        validate: value => value >= 0 || '数字 >= 0 を入力してください。' ,
                                                                        
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <TextFieldOptions
                                                                            {...field}
                                                                            options={getValues("phaseNPE.numberPhaseList")}
                                                                            // label="保護接地線の枚数"
                                                                            // onChange= {(e) =>handleChangeFormat(e)}
                                                                            size="small"
                                                                            setValue={(name: any, value: any) => {
                                                                                onChangeEditChangeWidth({target:{name:name,value:value}},3)
                                                                                // doSelComboNumberPhase({target:{name:name,value:value}},3);
                                                                                // // onChangeFields({target:{name:name,value:value}},3);
                                                                                // handleDisableOKBt({target:{name:name,value:value}})

                                                                            }}
                                                                            height={32}
                                                                            error={!!errors?.phaseNPE?.numberPhase}
                                                                            helperText={errors?.phaseNPE?.numberPhase?.message}
                                                                            maxLength={8}
                                                                            disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING}

                                                                            />
                                                                    
                                                                    )}
                                                                />
                                                            </FormControl>
                                                        </Stack>
                                                    </Grid>
                                                </Grid>
                                                <Grid container mt={1}>
                                                    <Grid item xs={12}>
                                                        <fieldset style={{border:"1px solid #AAAA"}}>
                                                            <legend><Typography variant="body2">インピーダンス</Typography></legend>
                                                            {/* <Grid md={7} xs={9} mt={1.5}> */}
                                                            <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                                <Typography variant="body2" width="100%">相間の中心間距離 a(mm):</Typography> 
                                                                <Controller
                                                                    name="phaseNPE.distance"
                                                                    control={control}
                                                                    rules={{
                                                                        required: '必須項目です。入力してください。' ,
                                                                        pattern:{
                                                                            value: /^[0-9\b]+$/,
                                                                            message: '整数 >= 0を入力してください。'
                                                                        },                                  
                                                                        validate: value => value >= 0 || '整数 >= 0を入力してください。' ,
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <TextField
                                                                            {...field}
                                                                            fullWidth
                                                                            // type="text"
                                                                            size="small"
                                                                            // label="相間の中心間距離 a(mm)"
                                                                            onFocus={e => e.target.select()}
                                                                            InputLabelProps={{ style: { color: 'black' }}}
                                                                            onChange={(e) =>{
                                                                                field.onChange(e);
                                                                                onChangeDistance(e,3)}}
                                                                            InputProps={{sx:{height:32,backgroundColor:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white',color:modeViewOnly?"grey":"black"}}}
                                                                            error={!!errors?.phaseNPE?.distance}
                                                                            helperText={errors?.phaseNPE?.distance?.message}
                                                                            inputProps={{maxLength:3,readOnly:modeViewOnly}}
                                                                            disabled={m_bModePM|| processMode > MS_MODE_CHECKING}
                                                                        />
                                                                    )}
                                                                />
                                                            </Stack>
                                                            <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                                <Typography variant="body2" width="100%">表皮効果 ks:</Typography> 
                                                                <Controller
                                                                    name="phaseNPE.skinEffectM"
                                                                    control={control}
                                                                    rules={{
                                                                        required: '必須項目です。入力してください。' ,
                                                                        pattern:{
                                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                                            message: '数字を入力してください。'
                                                                        },
                                                                        validate: value => value > 0 || '数字 > 0 を入力してください。' ,

                                                                        // maxLength: {
                                                                        //     value: 4,
                                                                        //     message: "最大長さ <= 4 を入力してください。"
                                                                        // }
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <TextField
                                                                            {...field}
                                                                            fullWidth
                                                                            // type="text"
                                                                            size="small"
                                                                            // label="表皮効果 ks"
                                                                            onFocus={e => e.target.select()}
                                                                            InputLabelProps={{ style: { color: 'black' }}}
                                                                            onChange={(e) =>{
                                                                                field.onChange(e);
                                                                                onChangeSkinEffect(e,3)}}

                                                                            InputProps={{sx:{height:32,backgroundColor:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white',color:modeViewOnly?"grey":"black"},
                                                                            endAdornment:(
                                                                                <text style={{color:getValues("phaseNPE.skinEffectM") != getValues("phaseNPE.skinEffectA") ? "black" : "grey",fontSize:"30px",marginTop:"15px"}}>*</text>
                                                                            )}}
                                                                            error={!!errors?.phaseNPE?.skinEffectM}
                                                                            helperText={errors?.phaseNPE?.skinEffectM?.message}
                                                                            inputProps={{maxLength:4,readOnly:modeViewOnly}}
                                                                            disabled={m_bModePM|| processMode > MS_MODE_CHECKING}

                                                                        />
                                                                    )}
                                                                />
                                                            </Stack>
                                                            <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                                <Typography variant="body2" width="100%">1m当りの抵抗 (mΩ/m):</Typography> 
                                                                <Controller
                                                                    name="phaseNPE.ohmRPerM"
                                                                    control={control}
                                                                    rules={{
                                                                        required: '必須項目です。入力してください。' ,
                                                                        pattern:{
                                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                                            message: '数字を入力してください。'
                                                                        },
                                                                        validate: value => value > 0 || '数字 > 0 を入力してください。' ,

                                                                        // maxLength: {
                                                                        //     value: 7,
                                                                        //     message: "最大長さ <= 7 を入力してください。"
                                                                        // }
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <TextField
                                                                            {...field}
                                                                            fullWidth
                                                                            // type="text"
                                                                            size="small"
                                                                            // label="1m当りの抵抗 (mΩ/m)"
                                                                            onFocus={e => e.target.select()}
                                                                            InputLabelProps={{ style: { color: 'black' }}}
                                                                            onChange={(e) =>{
                                                                                field.onChange(e);
                                                                                onChangeOhmRperper(e,3)
                                                                            }}
                                                                            InputProps={{sx:{height:32,backgroundColor:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white',color:modeViewOnly?"grey":"black"},
                                                                            endAdornment:(
                                                                                <text style={{color:getValues("phaseNPE.ohmRPerM") != getValues("phaseNPE.ohmRPerA") ? "black" : "grey",fontSize:"30px",marginTop:"15px"}}>*</text>
                                                                            )}}
                                                                            error={!!errors?.phaseNPE?.ohmRPerM}
                                                                            helperText={errors?.phaseNPE?.ohmRPerM?.message}
                                                                            inputProps={{maxLength:7,readOnly:modeViewOnly}}
                                                                            disabled={m_bModePM|| processMode > MS_MODE_CHECKING}

                                                                        />
                                                                    )}
                                                                />
                                                            </Stack>
                                                            <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                                <Typography variant="body2" width="100%">抵抗(mΩ):</Typography> 
                                                                <Controller
                                                                    name="phaseNPE.ohmR"
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <TextField
                                                                            {...field}
                                                                            disabled={true}
                                                                            defaultValue={0}
                                                                            fullWidth
                                                                            type="text"
                                                                            size="small"
                                                                            // label="抵抗(mΩ)"
                                                                            onFocus={e => e.target.select()}
                                                                            InputLabelProps={{ style: { color: 'black' }}}
                                                                            InputProps={{sx:{height:32,backgroundColor:'floralwhite'}}}

                                                                        />
                                                                    )}
                                                                />
                                                            </Stack>
                                                            <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                                <Typography variant="body2" width="100%">1m当りのリアクタンス(mΩ/m):</Typography> 
                                                                <Controller
                                                                    name="phaseNPE.ohmXPerM"
                                                                    control={control}
                                                                    rules={{
                                                                        required: '必須項目です。入力してください。' ,
                                                                        pattern:{
                                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                                            message: '数字を入力してください。'
                                                                        },
                                                                        validate: value => value > 0 || '数字 > 0 を入力してください。' ,

                                                                        // maxLength: {
                                                                        //     value: 7,
                                                                        //     message: "最大長さ <= 7 を入力してください。"
                                                                        // }
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <TextField
                                                                            {...field}
                                                                            fullWidth
                                                                            // type="text"
                                                                            size="small"
                                                                            // label="1m当りのリアクタンス(mΩ/m)"
                                                                            onFocus={e => e.target.select()}
                                                                            InputLabelProps={{ style: { color: 'black' }}}
                                                                            onChange={(e) =>{
                                                                                field.onChange(e);
                                                                                onChangeOhmXperper(e,3)
                                                                                        }}
                                                                            InputProps={{sx:{height:32,backgroundColor:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white',color:modeViewOnly?"grey":"black"},
                                                                            endAdornment:(
                                                                                <text style={{color:getValues("phaseNPE.ohmXPerM") != getValues("phaseNPE.ohmXPerA") ? "black" : "grey",fontSize:"30px",marginTop:"15px"}}>*</text>
                                                                            )}}
                                                                            error={!!errors?.phaseNPE?.ohmXPerM}
                                                                            helperText={errors?.phaseNPE?.ohmXPerM?.message}
                                                                            inputProps={{maxLength:7,readOnly:modeViewOnly}}
                                                                            disabled={m_bModePM|| processMode > MS_MODE_CHECKING}

                                                                        />
                                                                    )}
                                                                />
                                                            </Stack>
                                                            <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                                <Typography variant="body2" width="100%">リアクタンス(mΩ):</Typography> 
                                                                <Controller
                                                                    name="phaseNPE.ohmX"
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <TextField
                                                                            {...field}
                                                                            disabled={true}
                                                                            defaultValue={0}
                                                                            fullWidth
                                                                            type="text"
                                                                            size="small"
                                                                            // label="リアクタンス(mΩ)"
                                                                            onFocus={e => e.target.select()}
                                                                            InputLabelProps={{ style: { color: 'black' }}}
                                                                            InputProps={{sx:{height:32,backgroundColor:'floralwhite'}}}

                                                                        />
                                                                    )}
                                                                />
                                                            </Stack>
                                                        </fieldset>
                                                    </Grid>
                                                </Grid>
                                                <Grid container mt={1}>
                                                    <Grid item xs={12}>
                                                        <fieldset style={{border:"1px solid #AAAA"}}>
                                                            <legend><Typography variant="body2" >許容電流</Typography> </legend>
                                                            {/* <Grid md={7} xs={9} mt={1.5}> */}
                                                            <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                                <Typography variant="body2" width="100%">基本通電許容電流(A):</Typography> 
                                                                <Controller
                                                                    name="phaseNPE.basicCapacityM"
                                                                    control={control}
                                                                    rules={{
                                                                        required: '必須項目です。入力してください。' ,
                                                                        pattern:{
                                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                                            message: '数字を入力してください。'
                                                                        },
                                                                        validate: value => value > 0 || '数字 > 0 を入力してください。' ,

                                                                        // maxLength: {
                                                                        //     value: 4,
                                                                        //     message: "最大長さ <= 4 を入力してください。"
                                                                        // }
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <TextField
                                                                            {...field}
                                                                            fullWidth
                                                                            // type="text"
                                                                            size="small"
                                                                            sx={{width:"50%"}}

                                                                            // label="基本通電許容電流(A)"
                                                                            // onBlur={handleChangeFormat}
                                                                            onFocus={e => e.target.select()}
                                                                            InputLabelProps={{ style: { color: 'black' }}}
                                                                            onChange={(e) =>{
                                                                                field.onChange(e);
                                                                                onChangeBasicCapacity(e,3)
                                                                            }}
                                                                            InputProps={{sx:{height:32,backgroundColor:m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING?'floralwhite':'white',color:modeViewOnly?"grey":"black"},
                                                                            endAdornment:(
                                                                                <text style={{color:getValues("phaseNPE.basicCapacityM") != getValues("phaseNPE.basicCapacityA") ? "black" : "grey",fontSize:"30px",marginTop:"15px"}}>*</text>
                                                                            )}}
                                                                            error={!!errors?.phaseNPE?.basicCapacityM}
                                                                            helperText={errors?.phaseNPE?.basicCapacityM?.message}
                                                                            inputProps={{maxLength:4,readOnly:modeViewOnly}}
                                                                            disabled={m_bModePM|| processMode > MS_MODE_DRAWING}

                                                                        />
                                                                    )}
                                                                />
                                                            </Stack>
                                                            <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                                <Typography variant="body2" width="100%">温度補正係数:</Typography> 
                                                                <Controller
                                                                    name="phaseNPE.tempK2M"
                                                                    control={control}
                                                                    rules={{
                                                                        required: '必須項目です。入力してください。' ,
                                                                        pattern:{
                                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                                            message: '数字を入力してください。'
                                                                        },
                                                                        validate: value => value > 0 || '数字 > 0 を入力してください。' ,

                                                                        // maxLength: {
                                                                        //     value: 4,
                                                                        //     message: "最大長さ <= 4 を入力してください。"
                                                                        // }
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <TextField
                                                                            {...field}
                                                                            fullWidth
                                                                            // type="text"
                                                                            size="small"
                                                                            sx={{width:"50%"}}
                                                                            // label="温度補正係数"
                                                                            onFocus={e => e.target.select()}
                                                                            InputLabelProps={{ style: { color: 'black' }}}
                                                                            onChange={(e) =>{
                                                                                field.onChange(e);
                                                                                onChangeTempk2(e,3)
                                                                            }}
                                                                            InputProps={{sx:{height:32,backgroundColor:m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING?'floralwhite':'white',color:modeViewOnly?"grey":"black"},
                                                                            endAdornment:(
                                                                                <text style={{color:getValues("phaseNPE.tempK2M") != getValues("phaseNPE.tempK2A") ? "black" : "grey",fontSize:"30px",marginTop:"15px"}}>*</text>
                                                                            )}}
                                                                            error={!!errors?.phaseNPE?.tempK2M}
                                                                            helperText={errors?.phaseNPE?.tempK2M?.message}
                                                                            inputProps={{maxLength:4,readOnly:modeViewOnly}}
                                                                            disabled={m_bModePM|| processMode > MS_MODE_DRAWING}

                                                                        />
                                                                    )}
                                                                />
                                                            </Stack>
                                                            <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                                <Typography variant="body2" width="100%">水平配置補正係数:</Typography> 
                                                                <Controller
                                                                    name="phaseNPE.horizontalK3M"
                                                                    control={control}
                                                                    rules={{
                                                                        required: '必須項目です。入力してください。' ,
                                                                        pattern:{
                                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                                            message: '数字を入力してください。'
                                                                        },
                                                                        validate: value => value > 0 || '数字 > 0 を入力してください。' ,

                                                                        // maxLength: {
                                                                        //     value: 4,
                                                                        //     message: "最大長さ <= 4 を入力してください。"
                                                                        // }
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <TextField
                                                                            {...field}
                                                                            fullWidth
                                                                            // type="text"
                                                                            size="small"
                                                                            sx={{width:"50%"}}
                                                                            // label="水平配置補正係数"
                                                                            // onBlur={handleChangeFormat}
                                                                            onFocus={e => e.target.select()}
                                                                            InputLabelProps={{ style: { color: 'black' }}}
                                                                            onChange={(e) =>{
                                                                                field.onChange(e);
                                                                                onChangeHorizontalk3(e,3)
                                                                            }}
                                                                            InputProps={{sx:{height:32,backgroundColor:m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING?'floralwhite':'white', color:modeViewOnly?"grey":"black"},
                                                                            endAdornment:(
                                                                                <text style={{color:getValues("phaseNPE.horizontalK3M") != getValues("phaseNPE.horizontalK3A") ? "black" : "grey",fontSize:"30px",marginTop:"15px"}}>*</text>
                                                                            )}}
                                                                            error={!!errors?.phaseNPE?.horizontalK3M}
                                                                            helperText={errors?.phaseNPE?.horizontalK3M?.message}
                                                                            inputProps={{maxLength:4,readOnly:modeViewOnly}}
                                                                            disabled={m_bModePM|| processMode > MS_MODE_DRAWING}

                                                                        />
                                                                    )}
                                                                />
                                                            </Stack>
                                                            <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                                <Typography variant="body2" width="100%">dm x b / (a x a) = 0.2での近接効果補正係数:</Typography> 
                                                                <Controller
                                                                    name="phaseNPE.proximityK4M"
                                                                    control={control}
                                                                    rules={{
                                                                        required: '必須項目です。入力してください。' ,
                                                                        pattern:{
                                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                                            message: '数字を入力してください。'
                                                                        },
                                                                        validate: value => value > 0 || '数字 > 0 を入力してください。' ,

                                                                        // maxLength: {
                                                                        //     value: 4,
                                                                        //     message: "最大長さ <= 4 を入力してください。"
                                                                        // }
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <TextField
                                                                            {...field}
                                                                            fullWidth
                                                                            // type="text"
                                                                            size="small"
                                                                            sx={{width:"50%"}}
                                                                            // label="dm x b / (a x a) = 0.2での近接効果補正係数"
                                                                            // onBlur={handleChangeFormat}
                                                                            onFocus={e => e.target.select()}
                                                                            InputLabelProps={{ style: { color: 'black' }}}
                                                                            onChange={(e) =>{
                                                                                field.onChange(e);
                                                                                onChangeProximityk4(e,3)
                                                                            }}
                                                                            InputProps={{sx:{height:32,backgroundColor:m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING?'floralwhite':'white', color:modeViewOnly?"grey":"black"},
                                                                            endAdornment:(
                                                                                <text style={{color:getValues("phaseNPE.proximityK4M") != getValues("phaseNPE.proximityK4A") ? "black" : "grey",fontSize:"30px",marginTop:"15px"}}>*</text>
                                                                            )}}
                                                                            error={!!errors?.phaseNPE?.proximityK4M}
                                                                            helperText={errors?.phaseNPE?.proximityK4M?.message}
                                                                            inputProps={{maxLength:4,readOnly:modeViewOnly}}
                                                                            disabled={m_bModePM|| processMode > MS_MODE_DRAWING}

                                                                        />
                                                                    )}
                                                                />
                                                            </Stack>
                                                            <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                                <Typography variant="body2" width="100%">通電許容電流(A):</Typography> 
                                                                <Controller
                                                                    name="phaseNPE.capacity"
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <TextField
                                                                            {...field}
                                                                            disabled={true}
                                                                            defaultValue={0}
                                                                            fullWidth
                                                                            type="text"
                                                                            sx={{width:"50%"}}
                                                                            size="small"
                                                                            // label="通電許容電流(A)"
                                                                            onFocus={e => e.target.select()}
                                                                            InputLabelProps={{ style: { color: 'black' }}}
                                                                            InputProps={{sx:{height:32,backgroundColor:'floralwhite'}}}

                                                                        />
                                                                    )}
                                                                />
                                                            </Stack>
                                                        </fieldset>
                                                    </Grid>
                                                </Grid>
                                            </Stack>
                                        </Stack>
                                    </TabPanel>
                                }
                     </div>
                    <Stack direction='row' justifyContent="flex-end" spacing={2} sx={{ mt: 2 }}>
                        <Button variant="contained" type="submit" disabled={modeViewOnly}>OK</Button>
                        <Button color="inherit" variant="contained" onClick={handleCancel}>
                            キャンセル
                        </Button>
                    </Stack>
                </form>
        </>
    )
}

export default MsBusbarEditDialog