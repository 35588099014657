import { Box, Button, Checkbox, FormControl, FormControlLabel, Grid, Stack, Tab, Tabs, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { MSTransCenterModel } from '../../models/Index';
import TextFieldOptions from '../common/TextFieldOptions';
import { BeamsNumber } from '../../utils/FormatNumber';
import { doGetTextDirectOhmR, doGetTextDirectOhmX, doGetTextDirectOhmZ, doGetTextDirectPercentRr, doGetTextDirectPercentXr, doGetTextDirectPercentZr, doGetTextDirectXR, doGetValuesTransCenter } from '../../utils/TransCenterFunction';
import * as Constant from "../../models/Constants";
import * as EKind from "../../models/ElementKind";
import { MS_3PHASES, MS_MODE_DRAWING, MS_OUTSIDE_OUTSIDE, MS_SEAT_MAIN } from '../../statics';
import { getElementKindLabel, getElementKindValue } from '../../utils/ElementFunction';

export type MSTransCenterEditDialogFormProps = {
    transCenterDiagram: any;
    data: MSTransCenterModel;
    haveSourceVoltage: boolean;
    sourceVoltText: string;
    capacityXPowerFactor: number;
    setCapacityXPowerFactor: any;
    onOK: (data: MSTransCenterModel) => void;
    onCancel: () => void;
    userRole: string;
    m_bModePM: boolean;
    processMode: number;
    modeViewOnly:boolean;
};

function a11yProps(index: number) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}

const checkboxStyle = { padding: '4px 4px 4px 8px' }

//Tabs------------------
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

export const MSTransCenterEditDialog = (props: MSTransCenterEditDialogFormProps) => {
    const { 
        transCenterDiagram,
        data,
        haveSourceVoltage,
        sourceVoltText,
        capacityXPowerFactor,
        setCapacityXPowerFactor,
        userRole,
        onOK,
        onCancel,
        m_bModePM,
        processMode,
        modeViewOnly
    } = props;
    const [selectTab, setSelectTab] = useState(0);

    const initialValue: MSTransCenterModel = { ...data }

    const { control, handleSubmit, formState: { errors }, setValue, getValues ,setError} = useForm<MSTransCenterModel>({
        mode: 'all',
        criteriaMode: 'all',
        defaultValues: initialValue,
    });

	const [minXFrameCount, setMinXFrameCount] = useState(29)
	const [minYFrameCount, setMinYFrameCount] = useState(15)

    useEffect(() => {
        let minSize = calcMinSize(transCenterDiagram)
        setMinXFrameCount(minSize.minXFrameCount)
        setMinYFrameCount(minSize.minYFrameCount)
        doSetValuesTransCenterData();
    }, [])

    // Submitイベント
    const handleEditSubmit = (value: MSTransCenterModel) => {
        const request = createRequestData(value);
        onOK(request);
    }

    // FormデータからRequestデータを作成する
    const createRequestData = (formValue: MSTransCenterModel) => {
        let request = { ...formValue } as MSTransCenterModel;
        return request;
    }

    // キャンセル
    const handleCancel = () => {
        onCancel();
    };
    
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        if (newValue >= 0) {
			if(selectTab == 0){
                if (isNaN(Number(getValues('voltage'))) || Number(getValues('voltage')) <= 0) {
                    setError("voltage",{ type: 'custom', message: '数字 > 0 を入力してください。' });
                }else if(isNaN(Number(getValues('xFrameCount'))) || Number(getValues('xFrameCount')) < minXFrameCount || Number(getValues('xFrameCount')) > 99){
                    setError("xFrameCount",{ type: 'custom', message: '数字 >= 29 & <= 99 を入力してください。'});
                }
                else if(isNaN(Number(getValues('yFrameCount'))) || Number(getValues('yFrameCount')) < minYFrameCount || Number(getValues('yFrameCount')) > 99){
                    setError("yFrameCount",{ type: 'custom', message: '数字 >= 15 & <= 99 を入力してください。'});
                }
                else{
                    setSelectTab(newValue);
                }
            }else if(selectTab == 1){
                if (isNaN(Number(getValues('maxPower'))) || Number(getValues('maxPower')) < 0) {
                    setError("maxPower",{ type: 'custom', message: '数字 >= 0 を入力してください。' });
                }else if (isNaN(Number(getValues('periodMaxPower'))) || Number(getValues('periodMaxPower')) < 0){
                    setError("periodMaxPower",{ type: 'custom', message: '数字 >= 0 を入力してください。' });
                }
                else if (isNaN(Number(getValues('periodAveragePower'))) || Number(getValues('periodAveragePower')) < 0){
                    setError("periodAveragePower",{ type: 'custom', message: '数字 >= 0 を入力してください。' });
                }else{
                    setSelectTab(newValue);
                }            }
        }
        else{
        setSelectTab(newValue);
        }
    };

    const handleChangeFormat = (e: any) => {
        setValue(e.target.name, e.target.value)
    }

    const handleChangeCheckbox = (e: any) => {
        setValue(e.target.name, e.target.checked)
    }

    const handleChangeThinkOnOff = (e: any) => {
        setValue(e.target.name, e.target.checked)
        doSetValuesTransCenter()
    }

    const handleChangePower = (e: any) => {
        // change 'maxPower', 'periodAveragePower', 'periodMaxPower'
        setValue(e.target.name, e.target.value)

        if (e.target.name === 'maxPower')
            doCalcDemandFactor()
        else
            doCalcLoadFactor()
    }

    const handleElectricityCalculation = (e: any) => {
        setValue(e.target.name, e.target.value)
    }
    
    const doCalcDemandFactor = () => {
        let maxPower = Number(getValues('maxPower'))
        let demandFactor = 0

        if (capacityXPowerFactor !== 0)
            demandFactor = maxPower / capacityXPowerFactor

        setValue('demandFactor', BeamsNumber(demandFactor, 5))
    }

    const doCalcLoadFactor = () => {
        let periodAveragePower = Number(getValues('periodAveragePower'))
        let periodMaxPower = Number(getValues('periodMaxPower'))
        let loadFactor = 0
        if (periodMaxPower !== 0)
            loadFactor = periodAveragePower / periodMaxPower

        setValue('loadFactor', BeamsNumber(loadFactor, 5))
    }

    const doSetValuesTransCenter = () => {

        let tmpCalc = doGetValuesTransCenter(
            getValues(),
            transCenterDiagram,
        )
        setValue('togetherLoad', BeamsNumber(tmpCalc.newProps.togetherLoad, 5))
        setValue('powerFactor', BeamsNumber(tmpCalc.newProps.powerFactor, 5))
        setValue('fullCurrent', BeamsNumber(tmpCalc.newProps.fullCurrent, 4))
        setValue('phaseCurrent', BeamsNumber(tmpCalc.newProps.phaseCurrent, 4))
        setValue('demandFactor', BeamsNumber(tmpCalc.newProps.demandFactor, 5))
        setValue('loadFactor', BeamsNumber(tmpCalc.newProps.loadFactor, 5))

        setCapacityXPowerFactor(tmpCalc.capacityXPowerFactor)
    }

    // #region sub function
    const calcMinSize = (diagram: []) => {
        let intoPoint: any = diagram.find((e: any) => e.type === EKind.MS_INTO_POINT)
        let minMaxPosition = getMinMaxPosition(diagram, intoPoint)

        let maxSquareCountHorizontal = Math.max( // find max distance to IntoPoint
            ((intoPoint.x - minMaxPosition.xMin) / 20)*2 + 1,
            ((minMaxPosition.xMax - (intoPoint.x + intoPoint.width)) / 20)*2 + 3,
        )
        let maxSquareCountVertical = minMaxPosition.yMax/Constant.POINTER_HEIGHT + 1

        // 29 <= Horizontal <= 99
        // 15 <= Vertical <= 99
        let minSize = {
            minXFrameCount: Math.min(Math.max(maxSquareCountHorizontal, Constant.MIN_X_FRAME_COUNT_TC), 99),
            minYFrameCount: Math.min(Math.max(maxSquareCountVertical, Constant.MIN_Y_FRAME_COUNT_TC), 99)
        }
        return minSize
    }

    const getMinMaxPosition = (diagram: [], intoPoint: any) => {
        let minMaxPosition = {
            xMin: intoPoint.x,
            xMax: intoPoint.x + intoPoint.width,
            yMax: 0,
        }
        diagram.forEach((e: any) => {
            if (e.id != intoPoint.id){
                let eMaxPosition = getMaxPosition(e)
                if (e.x < minMaxPosition.xMin){
                    minMaxPosition.xMin = e.x
                }
                if (eMaxPosition.xMax > minMaxPosition.xMax){
                    minMaxPosition.xMax = eMaxPosition.xMax
                }
                if (eMaxPosition.yMax > minMaxPosition.yMax){
                    minMaxPosition.yMax = eMaxPosition.yMax
                }
            }
        })
        return minMaxPosition
    }

    const getMaxPosition = (element: any) => {
        let xMax, yMax
        if(element.rotation !== 0){
            xMax = element.x + element.height
            yMax = element.y + element.width
        }else{
            xMax = element.x + element.width
            yMax = element.y + element.height
        }
        return {xMax, yMax}
    }

    const doSetValuesTransCenterData = () => {
        let data:any = {properties:{}};
        data.properties = JSON.parse(JSON.stringify(props.data));
        if(props.data.sPhase == true){
            switch(props.data.upTransKind){
                case getElementKindValue(EKind.MS_TRANS1):
                    setValue("percentZ", Number(doGetTextDirectPercentZr(data,processMode,MS_OUTSIDE_OUTSIDE,false)));
                    setValue("percentR", Number(doGetTextDirectPercentRr(data,processMode,MS_OUTSIDE_OUTSIDE,false)));
                    setValue("percentX", Number(doGetTextDirectPercentXr(data,processMode,MS_OUTSIDE_OUTSIDE,false)));
                    setValue("xr",Number(doGetTextDirectXR(data,processMode,MS_OUTSIDE_OUTSIDE)));
                    setValue("ohmZ",Number(doGetTextDirectOhmZ(data,processMode,MS_OUTSIDE_OUTSIDE,false)));
                    setValue("ohmR",Number(doGetTextDirectOhmR(data,processMode,MS_OUTSIDE_OUTSIDE,false)));
                    setValue("ohmX",Number(doGetTextDirectOhmX(data,processMode,MS_OUTSIDE_OUTSIDE,false)));
                    break
                case getElementKindValue(EKind.MS_TRANSSCOTT):
                    setValue("percentZ", Number(doGetTextDirectPercentZr(data,processMode,MS_SEAT_MAIN,false)));
                    setValue("percentR", Number(doGetTextDirectPercentRr(data,processMode,MS_SEAT_MAIN,false)));
                    setValue("percentX", Number(doGetTextDirectPercentXr(data,processMode,MS_SEAT_MAIN,false)));
                    setValue("xr",Number(doGetTextDirectXR(data,processMode,MS_SEAT_MAIN)));
                    setValue("ohmZ",Number(doGetTextDirectOhmZ(data,processMode,MS_SEAT_MAIN,false)));
                    setValue("ohmR",Number(doGetTextDirectOhmR(data,processMode,MS_SEAT_MAIN,false)));
                    setValue("ohmX",Number(doGetTextDirectOhmX(data,processMode,MS_SEAT_MAIN,false)));
                    break;

            }
        }else{
            setValue("percentZ", Number(doGetTextDirectPercentZr(data,processMode,MS_3PHASES,false)));
            setValue("percentR", Number(doGetTextDirectPercentRr(data,processMode,MS_3PHASES,false)));
            setValue("percentX", Number(doGetTextDirectPercentXr(data,processMode,MS_3PHASES,false)));
            setValue("xr",Number(doGetTextDirectXR(data,processMode,MS_3PHASES)));
            setValue("ohmZ",Number(doGetTextDirectOhmZ(data,processMode,MS_3PHASES,false)));
            setValue("ohmR",Number(doGetTextDirectOhmR(data,processMode,MS_3PHASES,false)));
            setValue("ohmX",Number(doGetTextDirectOhmX(data,processMode,MS_3PHASES,false)));
        }
    }


    // #endregion

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={selectTab} onChange={handleChange} aria-label='basic tabs example'
                    variant="scrollable" 
                    scrollButtons 
                    allowScrollButtonsMobile 
                    sx={{
                        '& .MuiButtonBase-root.MuiTabScrollButton-root.Mui-disabled': {
                            display: "none"
                        }
                    }}
                >
                    <Tab label='基本' {...a11yProps(0)} />
                    <Tab label='集計·計算' {...a11yProps(1)} />
                </Tabs>
            </Box>
            <form onSubmit={handleSubmit(handleEditSubmit)}>
                <div style={{ height: '40vh', width: '100%', margin: 'auto', overflow: 'auto' }}>
                    {/* tab1 */}
                    <TabPanel value={selectTab} index={0}>
                        <Grid container my={1}>
                            <Grid item xs={6}>
                                <Stack spacing={0.3}>
                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                        <Typography variant="body2" width="50%">変台名:</Typography>
                                        <Controller
                                            name="refNo"
                                            control={control}
                                            rules={{
                                                required: '必須項目です。入力してください。',
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    size="small"
                                                    error={!!errors?.refNo}
                                                    helperText={errors?.refNo?.message}
                                                    onChange={handleChangeFormat}
                                                    InputProps={{ sx: { height: 32 ,background:m_bModePM || modeViewOnly?'floralwhite':'white', color:modeViewOnly?"grey":"black"} }}
                                                    inputProps={{readOnly:modeViewOnly}}
                                                    disabled={m_bModePM}
                                                />
                                            )}
                                        />
                                    </Stack>
                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                        <Typography variant="body2" width="50%">事故点名称:</Typography>
                                        <Controller
                                            name="pointText"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    size="small"
                                                    onChange={handleChangeFormat}
                                                    InputProps={{ sx: { height: 32 ,background:m_bModePM || modeViewOnly ?'floralwhite':'white', color:modeViewOnly?"grey":"black"} }}
                                                    disabled={m_bModePM}
                                                    inputProps={{readOnly:modeViewOnly}}
                                                />
                                            )}
                                        />
                                    </Stack>
                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                        <Typography variant="body2" width="50%">電源電圧(V):</Typography>
                                        <FormControl fullWidth size='small'>
                                            <Controller
                                                name='voltage'
                                                control={control}
                                                rules={{ 
                                                    required: '必須項目です。入力してください。' ,
                                                    pattern:{
                                                        value: /^[0-9\b]+$/,
                                                        message: '数字を入力してください。'
                                                    },
                                                    validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                    
                                                }}
                                                render={({ field }: any) => (
                                                    <TextFieldOptions
                                                        {...field}
                                                        options={initialValue.voltageList}
                                                        // type='text'
                                                        size='small'
                                                        setValue={(name: any, value: any) => {
                                                            handleElectricityCalculation({target: {name: name, value: value}})
                                                        }}
                                                        height= {32}
                                                        maxLength={6}
                                                        value={initialValue.sPhase ? sourceVoltText : field.value}
                                                        error={!!errors?.voltage}
                                                        helperText={errors?.voltage?.message}
                                                        disabled={haveSourceVoltage||m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING}
                                                    />
                                                )}
                                            />
                                        </FormControl>
                                    </Stack>
                                </Stack>
                            </Grid>
                            <Grid item xs={6} pl={4}>
                                <Stack>
                                    <FormControlLabel
                                        control={
                                            <Controller
                                                name='fault'
                                                control={control}
                                                render={({ field }) => (
                                                    <Checkbox
                                                        {...field}
                                                        size="small"
                                                        style={checkboxStyle}
                                                        checked={field.value}
                                                        onChange={handleChangeCheckbox}
                                                        disabled={m_bModePM || modeViewOnly}
                                                    />
                                                )}
                                            />
                                        }
                                        label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>短絡電流を計算</Typography>}
                                    />
                                </Stack>
                                {/* Mode SP */}
                                {(userRole && !(userRole == Constant.ROLE_LP)) &&
                                <Stack>
                                    <FormControlLabel
                                        control={
                                            <Controller
                                                name='viewFlowResult'
                                                control={control}
                                                render={({ field }) => (
                                                    <Checkbox
                                                        {...field}
                                                        size="small"
                                                        style={checkboxStyle}
                                                        checked={field.value}
                                                        onChange={handleChangeCheckbox}
                                                        disabled={m_bModePM || modeViewOnly || initialValue.sPhase}
                                                    />
                                                )}
                                            />
                                        }
                                        label={
                                            <Typography 
                                                variant="body2" color={(initialValue.sPhase ||m_bModePM) ? 'grey' : undefined}
                                                
                                            >
                                                潮流解析結果を表示
                                            </Typography>
                                        }
                                    />
                                </Stack>
                                }

                                <Stack>
                                    <FormControlLabel
                                        control={
                                            <Controller
                                                name='viewResultText'
                                                control={control}
                                                render={({ field }) => (
                                                    <Checkbox
                                                        {...field}
                                                        size="small"
                                                        style={checkboxStyle}
                                                        checked={field.value}
                                                        onChange={handleChangeCheckbox}
                                                        disabled={m_bModePM || modeViewOnly}

                                                    />
                                                )}
                                            />
                                        }
                                        label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>注釈を表示</Typography>}
                                    />
                                </Stack>
                            </Grid>
                        </Grid>

                        <fieldset style={{border:'1px solid #AAAA'}}>
                            <legend><Typography variant="body2" >変台電源インピーダンス(基準容量ベース)</Typography></legend>
                            <Stack spacing={0.3}>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="100%">X/R:</Typography>
                                            <Controller
                                                name="xr"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        type="text"
                                                        size="small"
                                                        value={field.value || '未計算'}
                                                        inputProps={{ readOnly: true }}
                                                        style={{ backgroundColor: 'floralwhite' }}
                                                        sx={{ width: 200 }}
                                                        InputProps={{sx: { height: 32,color:'grey', backgroundColor: 'floralwhite' }}}

                                                    />
                                                )}
                                            />
                                        </Stack>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width='100%'>%インピーダンス(%):</Typography>
                                            <Controller
                                                name="percentZ"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        type="text"
                                                        size="small"
                                                        value={field.value || '未計算'}
                                                        inputProps={{ readOnly: true }}
                                                        style={{ backgroundColor: 'floralwhite' }}
                                                        sx={{ width: 200 }}
                                                        InputProps={{sx: { height: 32 ,color:'grey', backgroundColor: 'floralwhite'}}}

                                                    />
                                                )}
                                            />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={0.5} style={{ marginTop: 'auto', marginBottom: 'auto', textAlign: 'center' }}>
                                        <span>=</span>
                                    </Grid>
                                    <Grid item xs={2.5}>
                                        <Stack>
                                            <Controller
                                                name="percentR"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        value={field.value || '未計算'}
                                                        inputProps={{ readOnly: true }}
                                                        style={{ backgroundColor: 'floralwhite' }}
                                                        InputProps={{sx: { height: 32,color:'grey', backgroundColor: 'floralwhite' }}}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={0.5} style={{ marginTop: 'auto', marginBottom: 'auto', textAlign: 'center' }}>
                                        <span>+ j</span>
                                    </Grid>
                                    <Grid item xs={2.5}>
                                        <Stack>
                                            <Controller
                                                name="percentX"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        value={field.value || '未計算'}
                                                        inputProps={{ readOnly: true }}
                                                        style={{ backgroundColor: 'floralwhite' }}
                                                        InputProps={{sx: { height: 32 ,color:'grey', backgroundColor: 'floralwhite'}}}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width='100%'>インピーダンス(mΩ):</Typography>
                                            <Controller
                                                name="ohmZ"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        type="text"
                                                        size="small"
                                                        value={field.value || '未計算'}
                                                        inputProps={{ readOnly: true }}
                                                        style={{ backgroundColor: 'floralwhite' }}
                                                        sx={{ width: 200 }}
                                                        InputProps={{sx: { height: 32 ,color:'grey', backgroundColor: 'floralwhite'}}}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={0.5} style={{ marginTop: 'auto', marginBottom: 'auto', textAlign: 'center' }}>
                                        <span>=</span>
                                    </Grid>
                                    <Grid item xs={2.5}>
                                        <Stack>
                                            <Controller
                                                name="ohmR"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        value={field.value || '未計算'}
                                                        inputProps={{ readOnly: true }}
                                                        style={{ backgroundColor: 'floralwhite' }}
                                                        InputProps={{sx: { height: 32 ,color:'grey', backgroundColor: 'floralwhite'}}}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={0.5} style={{ marginTop: 'auto', marginBottom: 'auto', textAlign: 'center' }}>
                                        <span>+ j</span>
                                    </Grid>
                                    <Grid item xs={2.5}>
                                        <Stack>
                                            <Controller
                                                name="ohmX"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        value={field.value || '未計算'}
                                                        inputProps={{ readOnly: true }}
                                                        style={{ backgroundColor: 'floralwhite' }}
                                                        InputProps={{sx: { height: 32,color:'grey', backgroundColor: 'floralwhite' }}}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Stack>
                        </fieldset>

                        <Grid mt={2}>
                            <Stack>
                                <Typography variant="body2">変台格子数 (最大:99 × 99)</Typography>
                            </Stack>
                            <Grid container alignItems='center' mt={1}>
                                <Typography variant="body2" pr={1}>横:</Typography>
                                <Box sx={{ width: '20%' }}>
                                    <Controller
                                        name='xFrameCount'
                                        control={control}
                                        rules={{
                                            required: '必須項目です。入力してください。' ,
                                            pattern:{
                                                value: /^[0-9\b]+$/,
                                                message: '数字を入力してください。'
                                            },
                                            validate: value => value >= minXFrameCount && value <= 99 || `数字 >= ${minXFrameCount} & <= 99  を入力してください。` ,
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                type='number'
                                                size='small'
                                                onChange={handleChangeFormat}
                                                error={!!errors?.xFrameCount}
                                                helperText={errors?.xFrameCount?.message}
                                                inputProps={{maxLength:2,readOnly:modeViewOnly}}
                                                InputProps={{sx: { height: 32 ,background:m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING?'floralwhite':'white', color:modeViewOnly?"grey":"black"}}}
                                                disabled={m_bModePM|| processMode > MS_MODE_DRAWING}
                                            />
                                        )}
                                    />
                                </Box>
                                <Typography variant="body2" pl={1} pr={3}>{'(>='}{minXFrameCount}{')'}</Typography>
                                <Typography variant="body2" pr={1}>縦:</Typography>
                                <Box sx={{ width: '20%' }}>
                                    <Controller
                                        name='yFrameCount'
                                        control={control}
                                        rules={{
                                            required: '必須項目です。入力してください。' ,
                                            pattern:{
                                                value: /^[0-9\b]+$/,
                                                message: '数字を入力してください。'
                                            },
                                            validate: value => value >= minYFrameCount && value <= 99 || `数字 >= ${minYFrameCount} & <= 99  を入力してください。` ,
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                type='number'
                                                size='small'
                                                onChange={handleChangeFormat}
                                                error={!!errors?.yFrameCount}
                                                helperText={errors?.yFrameCount?.message}
                                                inputProps={{maxLength:2,readOnly:modeViewOnly}}
                                                InputProps={{sx: { height: 32 ,background:m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING?'floralwhite':'white', color:modeViewOnly?"grey":"black"}}}
                                                disabled={m_bModePM|| processMode > MS_MODE_DRAWING}
                                            />
                                        )}
                                    />
                                </Box>
                                <Typography variant="body2" pl={1} pr={1}>{'(>='}{minYFrameCount}{')'}</Typography>
                            </Grid>
                        </Grid>
                    </TabPanel>
                    
                    {/* tab2 */}
                    <TabPanel value={selectTab} index={1}>
                        <Stack sx={{alignItems: 'center'}}>
                            <Stack width={'100%'} mt={1}>
                                <fieldset  style={{border:"1px solid #AAAA"}}>
                                    <legend><Typography variant="body2">負荷の集計</Typography></legend>
                                    <Stack spacing={0.3}>
                                        <Stack>
                                            <FormControlLabel
                                                control={
                                                    <Controller
                                                        name='thinkOnOff'
                                                        control={control}
                                                        render={({ field }) => (
                                                            <Checkbox
                                                                {...field}
                                                                size='small'
                                                                checked={field.value}
                                                                onChange={handleChangeThinkOnOff}
                                                            />
                                                        )}
                                                    />
                                                }
                                                disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING}
                                                label={<Typography variant="body2" color={m_bModePM|| processMode > MS_MODE_DRAWING?'grey':'black'}>
                                                    電源から切り離されている負荷を合算しない
                                                </Typography>}
                                            />
                                        </Stack>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="100%">負荷設備容量の合計(kW):</Typography>
                                            <Controller
                                                name='togetherLoad'
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type='number'
                                                        size='small'
                                                        onChange={handleChangeFormat}
                                                        inputProps={{ readOnly: true }}
                                                        style={{ backgroundColor: 'floralwhite' }}
                                                        InputProps={{ sx: { height: 32,color:'grey',backgroundColor: 'floralwhite' } }}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="100%">負荷力率(遅れ):</Typography>
                                            <Controller
                                                name='powerFactor'
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type='number'
                                                        size='small'
                                                        onChange={handleChangeFormat}
                                                        inputProps={{ readOnly: true }}
                                                        style={{ backgroundColor: 'floralwhite' }}
                                                        InputProps={{ sx: { height: 32 ,color:'grey', backgroundColor: 'floralwhite'} }}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="100%">負荷電流(A):</Typography>
                                            <Controller
                                                name='fullCurrent'
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type='number'
                                                        size='small'
                                                        onChange={handleChangeFormat}
                                                        inputProps={{ readOnly: true }}
                                                        style={{ backgroundColor: 'floralwhite' }}
                                                        InputProps={{ sx: { height: 32,color:'grey', backgroundColor: 'floralwhite' } }}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="100%">負荷電流位相(° ):</Typography>
                                            <Controller
                                                name='phaseCurrent'
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type='number'
                                                        size='small'
                                                        onChange={handleChangeFormat}
                                                        inputProps={{ readOnly: true }}
                                                        style={{ backgroundColor: 'floralwhite' }}
                                                        InputProps={{ sx: { height: 32 ,color:'grey', backgroundColor: 'floralwhite'} }}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                    </Stack>
                                </fieldset>
                            </Stack>
                            <Stack width={'100%'} mt={1}>
                                <fieldset style={{border:"1px solid #AAAA"}}>
                                    <legend ><Typography variant="body2">需要率計算</Typography></legend>
                                    <Stack spacing={0.3}>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="100%">最大需要電力(kW):</Typography>
                                            <Controller
                                                name='maxPower'
                                                control={control}
                                                rules={{
                                                    validate: value => value >= 0
                                                }}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type='number'
                                                        size='small'
                                                        label=''
                                                        InputProps={{ sx: { height: 32, backgroundColor: m_bModePM || modeViewOnly ?'floralwhite':'white', color: modeViewOnly?"grey":"black"  } }}
                                                        inputProps={{readOnly:modeViewOnly}}
                                                        disabled={m_bModePM}
                                                        style={{ color: 'black'}}
                                                        error={!!errors?.maxPower}
                                                        onChange={handleChangePower}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="100%">需要率:</Typography>
                                            <Controller
                                                name='demandFactor'
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type='number'
                                                        size='small'
                                                        onChange={handleChangeFormat}
                                                        inputProps={{ readOnly: true }}
                                                        style={{ backgroundColor: 'floralwhite' }}
                                                        InputProps={{ sx: { height: 32 ,color:'grey',backgroundColor: 'floralwhite' } }}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                        <Stack>
                                            <Typography variant="body2">(需要率 = 最大需要電力 ÷ 負荷設備容量の合計)</Typography>
                                        </Stack>
                                    </Stack>
                                </fieldset>
                            </Stack>
                            <Stack width={'100%'} mt={1}>
                                <fieldset style={{border:"1px solid #AAAA"}}>
                                    <legend><Typography variant="body2" width="100%">負荷率計算</Typography></legend>
                                    <Stack spacing={0.3}>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="100%">平均需要電力(kW):</Typography>
                                            <Controller
                                                name='periodAveragePower'
                                                control={control}
                                                rules={{
                                                    validate: value => value >= 0
                                                }}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type='number'
                                                        size='small'
                                                        label=''
                                                        inputProps={{readOnly:modeViewOnly}}
                                                        InputProps={{ sx: { height: 32, backgroundColor: m_bModePM || modeViewOnly ?'floralwhite':'white', color: modeViewOnly?"grey":"black"  } }}
                                                        style={{ color: 'black' }}
                                                        disabled={m_bModePM}
                                                        error={!!errors?.periodAveragePower}
                                                        onChange={handleChangePower}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="100%">最大需要電力(kW):</Typography>
                                            <Controller
                                                name='periodMaxPower'
                                                control={control}
                                                rules={{
                                                    validate: value => value >= 0
                                                }}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type='number'
                                                        size='small'
                                                        label=''
                                                        inputProps={{readOnly:modeViewOnly}}
                                                        InputProps={{ sx: { height: 32, backgroundColor: m_bModePM || modeViewOnly ?'floralwhite':'white', color: modeViewOnly?"grey":"black"  } }}
                                                        disabled={m_bModePM}
                                                        style={{ color:'black' }}
                                                        error={!!errors?.periodMaxPower}
                                                        onChange={handleChangePower}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="100%">負荷率:</Typography>
                                            <Controller
                                                name='loadFactor'
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type='number'
                                                        size='small'
                                                        inputProps={{ readOnly: true }}
                                                        style={{ backgroundColor: 'floralwhite' }}
                                                        InputProps={{ sx: { height: 32,color:'grey',backgroundColor: 'floralwhite' } }}
                                                        onChange={handleChangeFormat}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                        <Stack>
                                            <Typography variant="body2">(負荷率 = 平均需要電力 ÷ 最大需要電力)</Typography>
                                        </Stack>
                                    </Stack>
                                </fieldset>
                            </Stack>
                        </Stack>
                    </TabPanel>
                </div>
                <Stack direction='row' justifyContent='flex-end' spacing={2} sx={{ mt: 2 }}>
                    <Button variant='contained' type='submit' disabled={modeViewOnly || modeViewOnly}>OK</Button>
                    <Button color='inherit' variant='contained' onClick={handleCancel}>キャンセル</Button>
                </Stack>
            </form >
        </>
    );
};
export default MSTransCenterEditDialog;


