import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Grid, IconButton } from "@mui/material";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from 'ag-grid-react';
import { forwardRef, useImperativeHandle, useMemo, useRef, useState } from "react";
import { PageData } from '../../../models/Index';
import { CurveStandardModel } from '../../../models/breakModel';
import { AG_GRID_LOCALE_JP } from '../../../themes/localeJP';
import LoadingOverlay, { DefaultLoadingMessage } from '../../common/LoadingOverlay';
import NoRowsOverlay, { NoRowsMessage } from '../../common/NoRowsOverlay';
import PaginationCpn from '../../common/PaginationCpn';

// -------------
// Props
export type CurveStandardGridProps = {
    colState: any[];
    pageSize?: number;
    pageData?: PageData;
    onChangeSearchCondition: (name: string, value: any) => void;
    keyword: string;
    onDelete: (data: CurveStandardModel) => void;
    onShowDetails: (lCurveStandardID: string) => void;
    heightForm?: string;
    checkEdit?:boolean;
    mode?:string
};

// -------------
// Component
export const CurveStandardGrid = forwardRef((props: CurveStandardGridProps, ref) => {
    const {
        pageSize,
        pageData,
        keyword,
        heightForm,
        checkEdit,
        onChangeSearchCondition,
        onDelete,
        onShowDetails,
        mode
    } = props;

    const gridRef = useRef() as any;
    const [rowData, setRowData] = useState();
    useImperativeHandle(ref, () => ({
        setRowData(value: any) {
            setRowData(value)
        },

        hideOverlay() {
            gridRef.current.api.hideOverlay();
        }
    }));

    // ag-Grid の準備完了後に呼ばれるコールバック関数
    const onGridReady = (grid: any) => {
    };

    // Loading時のメッセージ
    const loadingOverlayComponent = useMemo(() => {
        return LoadingOverlay;
    }, []);

    const loadingOverlayComponentParams = useMemo(() => {
        return {
            loadingMessage: checkEdit? "" : DefaultLoadingMessage,
        };
    }, []);

    const noRowsOverlayComponent = useMemo(() => {
        return NoRowsOverlay;
    }, []);

    const noRowsOverlayComponentParams = useMemo(() => {
        return {
            noRowsMessageFunc: () => NoRowsMessage,
        };
    }, []);
    const cellDialCharacterNameRenderer = (props: any) => {
        return props.data.ldialCharID +" " + props.data.scharacterName

    }
    const cellCurveHeaderNameRenderer = (props: any) => {
        return props.data.lcurveHeaderID +" " + props.data.scurveName

    }
    // 編集ボタンのセル内表示とボタンイベント
    const cellMessageRenderer = (props: any) => {
        // 詳細
        const handleEdit = () => {
            onShowDetails(props.data.lstandardID);
        };

        const handleDelete = () => {
            onDelete(props.data.lstandardID);
        };

        return (
            <Grid container direction="row" justifyContent="flex-start" paddingLeft={"-60px"}>
                <IconButton aria-label="edit" onClick={handleEdit} >
                    <EditIcon fontSize="small" />
                </IconButton>
                <IconButton aria-label="delete" onClick={handleDelete} >
                    <DeleteIcon fontSize="small" />
                </IconButton>
            </Grid>
        );
    };

    // 全列に共通の定義
    const defaultColDef = {
        sortable: true, // ソート可
        editable: false, // 編集不可
        resizable: true,
        suppressMovable: true,  // 列の移動を無効にする
    };

    // 列タイプの定義
    const columnTypes = {
        nonEditableColumn: { editable: false }, // 編集不可
    };

    // 列の個別定義
    const columnDefs = [
        {
            headerName: "曲線基準ID",
            field: "lstandardID",
            rowDrag: false,
            flex: 1.5
        },
        {
            headerName: "特性",
            field: "ldialCharID",
            rowDrag: false,
            flex: 1.5,
            cellRenderer: cellDialCharacterNameRenderer
        },
        {
            headerName: "動作特性本体",
            field: "lcurveHeaderID",
            rowDrag: false,
            flex: 2,
            cellRenderer: cellCurveHeaderNameRenderer
        },
        {
            headerName: "不定則時間(s)",
            field: "dpairTime",
            rowDrag: false,
            flex: 2
        },
        {
            headerName: "2次側電圧值(V)",
            field: "lstdVoltage2",
            rowDrag: false,
            flex: 2
        },
        {
            headerName: "描画線種",
            field: "nlineKind",
            rowDrag: false,
            flex: 2
        },
        {
            headerName: "",
            field: "option",
            reseizable: false,
            suppressMovable: true,
            width: 110,
            cellRenderer: cellMessageRenderer,
        }
    ];

    if(mode ==="edit"){
        columnDefs.splice(columnDefs.findIndex(item => item.field==="ldialCharID"),1)
        columnDefs.splice(columnDefs.findIndex(item => item.field==="lcurveHeaderID"),1)

    }
    const rowHeight = 48;
    gridRef.current?.api?.setQuickFilter(keyword);
    const handleEdit = (props: any) => {
        onShowDetails(props.data.lstandardID);
    }
    return (
        <>
            <div>
                <div className="ag-theme-alpine" style={{ height: heightForm ? 'calc(100vh - ' + heightForm + 'px)' : 'calc(100vh - 275px)', width: "100%" }}>
                    <AgGridReact
                        ref={gridRef}
                        defaultColDef={defaultColDef}
                        columnTypes={columnTypes}
                        columnDefs={columnDefs}
                        onGridReady={onGridReady}
                        domLayout={"normal"}
                        rowData={rowData}
                        animateRows={true}
                        rowDragManaged={true}
                        suppressMovableColumns={false}
                        suppressDragLeaveHidesColumns={false}
                        rowHeight={rowHeight}
                        localeText={AG_GRID_LOCALE_JP}
                        paginationPageSize={pageSize}
                        onCellDoubleClicked={handleEdit}
                        loadingOverlayComponent={loadingOverlayComponent}
                        loadingOverlayComponentParams={loadingOverlayComponentParams}
                        noRowsOverlayComponent={noRowsOverlayComponent}
                        noRowsOverlayComponentParams={noRowsOverlayComponentParams}
                    />
                </div>
                {pageData && pageSize && <PaginationCpn pageData={pageData} onChangeSearchCondition={onChangeSearchCondition} limit={pageSize} />}
            </div>
        </>
    );
});

export default CurveStandardGrid;