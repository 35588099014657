import { LoadingButton } from "@mui/lab";
import { Box, Button, CircularProgress, Grid, Stack, Tab, Tabs, TextField, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BreakCapacityModel, BreakOpeKindModel } from "../../../models/breakModel";
import { Path as RoutePath } from "../../../path";
import { ApplicationState, actionCreators, selectors } from "../../../store";
import FetchHelper from "../../FetchHelper";
//-----------
//FETCH ID
const GET_BREAK_CAPACITY = "GET_BREAK_CAPACITY"
const UPDATE_BREAK_CAPACITY = "UPDATE_BREAK_CAPACITY"
const CREATE_BREAK_CAPACITY = "CREATE_BREAK_CAPACITY"
const initialValue: BreakCapacityModel = {
    lCapacityID: 0,
    mv3breakBreakOpeKind: {
        lOpeKindID:0
    } as BreakOpeKindModel,
    lVoltage:"",
    lJIS: "",
    lIECIcu: "",
    lIECIcs: "",
    lIECIq: "",
    lIECIcuMCR: "",
    lIECIcsMCR: "",
    lIECIqMCR: "",
}
// -------------
//Tabs------------------
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `oilManagement-tab-${index}`,
        'aria-controls': `oilManagement-tabpanel-${index}`,
    };
}
// -------------
// Props
export type BreakCapacityEditProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
export const BreakCapacityEdit = (props: BreakCapacityEditProps) => {
    const {
        userId,
        onCreate, onCreateError,
        onUpdate, onUpdateError,
        onSuccess, onError,
    } = props;

    const { control, formState: { errors }, handleSubmit, setValue, getValues, } = useForm<BreakCapacityModel>({
        criteriaMode: "all",
        defaultValues: initialValue,
        mode:"all",
    })
    type MyParams = {
        id: string;
        mode: string;
    };
    let { id, mode } = useParams<keyof MyParams>() as MyParams;
    const location = useLocation();
    let lOpeKindID = (location.state) ? location.state.lBreakOpeKindID : undefined;
    let sOpeKindName = (location.state) ? location.state.sOpeKindName : undefined;
    let navigate = useNavigate();
    const [selectTab, setSelectTab] = useState(0);
    const [loadingFlag, setLoadingFlag] = useState(mode === "edit" ? true : false);
    const gridRef = useRef() as any;
    let tab = location.state && location.state.tab;
    const Exp = /^[-+]?\d+(\.\d+)?$/;
    const messageIsNotNumber = "数字を入力してください。";

    const handleSuccess = (data: any) => {
        console.log('>>>> handle Successs');
        // const object = data.data;
        setValue("lCapacityID", data.data.lcapacityID);
        setValue("lIECIcs", data.data.liecics);
        setValue("lIECIcu", data.data.liecicu);
        setValue("lIECIq", data.data.lieciq);
        setValue("lIECIcsMCR", data.data.liecicsMCR);
        setValue("lIECIcuMCR", data.data.liecicuMCR);
        setValue("lIECIqMCR", data.data.lieciqMCR);
        setValue("lJIS", data.data.ljis);
        setValue("lVoltage", data.data.lvoltage);
        setValue("mv3breakBreakOpeKind.sOpeKindName", data.data.mv3breakBreakOpeKindEntity.sopeKindName);
        setValue("mv3breakBreakOpeKind.lOpeKindID", data.data.mv3breakBreakOpeKindEntity.lopeKindID)
        if (tab !== undefined && tab !== null) {
            setSelectTab(tab)
        }
        setLoadingFlag(false);
    }
    useEffect(() => {
        if (mode === "add"){
            setValue("mv3breakBreakOpeKind.sOpeKindName", sOpeKindName);
            setValue("mv3breakBreakOpeKind.lOpeKindID", lOpeKindID)
        }

    }, []);

    // 検索エラー時
    const handleError = (success: boolean, data: any, error: any) => {
        console.log('>>>> handle Error');
        gridRef.current.hideOverlay();
    };

    const tabHandleChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectTab(newValue);
        navigate(".", {
            replace: true,
            state: {
                tab: newValue,
                lBreakOpeKindID: lOpeKindID,
                sOpeKindName: sOpeKindName
            }
        });
    };

    // 検索実行
    const handleEditSubmit = (data: BreakCapacityModel) => {
        setLoadingFlag(true);
        if (mode === "edit") {
            let request = {
                lCapacityID: getValues("lCapacityID"),
                lIECIcs: getValues("lIECIcs"),
                lIECIcsMCR: getValues("lIECIcsMCR"),
                lIECIcu: getValues("lIECIcu"),
                lIECIcuMCR: getValues("lIECIcuMCR"),
                lIECIq: getValues("lIECIq"),
                lIECIqMCR: getValues("lIECIqMCR"),
                lJIS: getValues("lJIS"),
                lVoltage: getValues("lVoltage"),
                lOpeKindID: getValues("mv3breakBreakOpeKind.lOpeKindID")
            } as any
            const object = {
                requestPrms:request,
                userId: userId,
            }
            onUpdate(object);
        } else if (mode === "add") {
            let request = {            
                lIECIcs: getValues("lIECIcs"),
                lIECIcsMCR: getValues("lIECIcsMCR"),
                lIECIcu: getValues("lIECIcu"),
                lIECIcuMCR: getValues("lIECIcuMCR"),
                lIECIq: getValues("lIECIq"),
                lIECIqMCR: getValues("lIECIqMCR"),
                lJIS: getValues("lJIS"),
                lVoltage: getValues("lVoltage"),
                lOpeKindID: getValues("mv3breakBreakOpeKind.lOpeKindID")??248,
            } as any
            const object = {
                requestPrms:request,
                userId: userId,
                
            }
            onCreate(object);
        }
    }

    // キャンセル
    const handleCancel = () => {
        if (location.key !== "default")
            navigate(-1);
        else
            navigate(RoutePath.BreakCapacityList);
    }

    // Submit後のAPI呼び出し成功時
    const handleSuccessUpdate = (success: boolean, data: any, error: any) => {
        onSuccess();
        // if (lOpeKindID !== undefined && lOpeKindID !== null) {
        //     navigate(-1);
        // }
        // if(mode === "edit"){
        //     navigate(RoutePath.BreakCapacityEdit+`/edit/${data.data.lcapacityID}`,{replace:true})
        // }
        setLoadingFlag(false)
    }
    // Submit後のAPI呼び出しエラー時
    const handleErrorUpdate = (success: boolean, data: any, error: any) => {
        onUpdateError();
        setLoadingFlag(false);
    }

    // Submit後のAPI呼び出し成功時
    const handleSubmitSuccess = (success: boolean, data: any, error: any) => {
        onSuccess();
        // if (lOpeKindID !== undefined && lOpeKindID !== null) {
        //     navigate(-1);
        // }
        if(mode === "add"){
            navigate(RoutePath.BreakCapacityEdit + `/edit/${data.lcapacityID}`, {replace:true})
      
        }
        setLoadingFlag(false)
    }

    // Submit後のAPI呼び出しエラー時
    const handleSubmitError = (success: boolean, data: any, error: any) => {
        onCreateError();
    }

    //入力欄背景
    const divStyles = {
        backgroundColor: 'white',
        border: '1px solid #bebebe',
        boxShadow: '0 0 5px #fff, 0 0 5px #bebebe, 0 0 1px #aaa',
        padding: '20px 40px',
        marginTop: '40px',
    }

    return (
        <>
            {
                mode === "edit" &&
                <FetchHelper
                    fetchId={GET_BREAK_CAPACITY}
                    url={`/master/break_capacity/get`}
                    method="POST"
                    json={true}
                    request={true}
                    params={{
                        requestPrms:{
                            id:id
                        },
                        userId: userId,
                    }}
                    onComplete={(success, data, error) => {
                        success ? handleSuccess(data) : handleError(success, data, error);
                    }}
                />
            }
            {loadingFlag && (
                <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1500, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                    <CircularProgress />
                </div>
            )}
            <div style={{ height: "100%", width: "100%", marginTop: "10px" }}>
                <Stack direction="row" justifyContent="flex-start" alignItems="center" sx={{}} style={{ margin: '18px', color: 'MenuText' }}>
                    <Typography variant="h4">
                        遮断容量詳細
                    </Typography>
                </Stack>
                <form onSubmit={handleSubmit(handleEditSubmit)}>
                    <Stack direction='row'>
                        <div style={{ top: "100px", position: "absolute", zIndex: 1200 }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={selectTab} onChange={tabHandleChange} aria-label="oilManagementDetailTabs">
                                    <Tab label='遮断容量' {...a11yProps(0)} />
                                </Tabs>
                            </Box>
                        </div>
                    </Stack>
                    {/* 遮断容量 */}
                    <TabPanel value={selectTab} index={0}>
                        <div style={divStyles}>
                            <Typography variant="h6">
                                遮断容量
                            </Typography>
                            <Stack mt={2} direction={{ xs: 'column', sm: 'row' }} sx={{height: "calc(40vh)", overflow: 'auto'}}>
                                <Grid container spacing={3} direction={'row'}>
                                    <Grid item xs={12} mt={1}>
                                        <Controller
                                            control={control}
                                            name="mv3breakBreakOpeKind.sOpeKindName"
                                            render={({ field }:any) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    label="動作種類名"
                                                    type="text"
                                                    size="small"
                                                    disabled={true}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Controller
                                            control={control}
                                            name="lVoltage"
                                            rules={{
                                                pattern: {
                                                    value: Exp,
                                                    message: messageIsNotNumber,
                                                }
                                            }}
                                            render={({ field }:any) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.lVoltage}
                                                    helperText={errors?.lVoltage?.message}
                                                    type="text"
                                                    label="電圧(V)"
                                                    size="small"
                                                    
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Controller
                                            control={control}
                                            name="lJIS"
                                            rules={{
                                                pattern: {
                                                    value: Exp,
                                                    message: messageIsNotNumber,
                                                }
                                            }}
                                            render={({ field }:any) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.lJIS}
                                                    helperText={errors?.lJIS?.message}
                                                    type="text"
                                                    size="small"
                                                    label="JIS(A)"
                                                    
                                                />
                                            )}
                                        />
                                    </Grid>
                                    {/*  */}
                                    <Grid item xs={12}>
                                        <Typography>IECでの通常の場合</Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Controller
                                            control={control}
                                            name="lIECIcu"
                                            rules={{
                                                pattern: {
                                                    value: Exp,
                                                    message: messageIsNotNumber,
                                                }
                                            }}
                                            render={({ field }:any) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.lIECIcu}
                                                    helperText={errors?.lIECIcu?.message}
                                                    type="text"
                                                    size="small"
                                                    label="IECIcu(A)"
                                                   
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Controller
                                            control={control}
                                            name="lIECIcs"
                                            rules={{
                                                pattern: {
                                                    value: Exp,
                                                    message: messageIsNotNumber,
                                                }
                                            }}
                                            render={({ field }:any) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.lIECIcs}
                                                    helperText={errors?.lIECIcs?.message}
                                                    type="text"
                                                    label="IECIcs(A)"
                                                    size="small"
                                                    
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Controller
                                            control={control}
                                            name="lIECIq"
                                            rules={{
                                                pattern: {
                                                    value: Exp,
                                                    message: messageIsNotNumber,
                                                }
                                            }}
                                            render={({ field }:any) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.lIECIq}
                                                    helperText={errors?.lIECIq?.message}
                                                    type="text"
                                                    label="IECIq(A)"
                                                    size="small"
                                                    
                                                />
                                            )}
                                        />
                                    </Grid>
                                    {/*  */}
                                    <Grid item xs={12}>
                                        <Typography>IECでのMCR時の瞬時ロック場合</Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Controller
                                            control={control}
                                            name="lIECIcuMCR"
                                            rules={{
                                                pattern: {
                                                    value: Exp,
                                                    message: messageIsNotNumber,
                                                }
                                            }}
                                            render={({ field }:any) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.lIECIcuMCR}
                                                    helperText={errors?.lIECIcuMCR?.message}
                                                    type="text"
                                                    label="IECIcu(A)"
                                                    size="small"
                                                    
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Controller
                                            control={control}
                                            name="lIECIcsMCR"
                                            rules={{
                                                pattern: {
                                                    value: Exp,
                                                    message: messageIsNotNumber,
                                                }
                                            }}
                                            render={({ field }:any) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.lIECIcsMCR}
                                                    helperText={errors?.lIECIcsMCR?.message}
                                                    type="text"
                                                    label="IECIcs(A)"
                                                    size="small"
                                                   
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Controller
                                            control={control}
                                            name="lIECIqMCR"
                                            rules={{
                                                pattern: {
                                                    value: Exp,
                                                    message: messageIsNotNumber,
                                                }
                                            }}
                                            render={({ field }:any) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.lIECIqMCR}
                                                    helperText={errors?.lIECIqMCR?.message}
                                                    type="text"
                                                    label="lIECIqMCR"
                                                    size="small"
                                                    
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Stack>
                        </div>
                        <Stack direction='row' justifyContent="flex-end" spacing={2} sx={{ mt: 1, mr: 2 }}>
                            <LoadingButton variant="contained" type="submit" loading={loadingFlag}>保存</LoadingButton>
                            <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                        </Stack>
                    </TabPanel>
                </form>
            </div>
            <FetchHelper fetchId={UPDATE_BREAK_CAPACITY}
                onComplete={(success: boolean, data: any, error: any) =>
                    success ? handleSuccessUpdate(success, data, error) : handleErrorUpdate(success, data, error)} />
            <FetchHelper fetchId={CREATE_BREAK_CAPACITY}
                onComplete={(success, data, error) =>
                    success ? handleSubmitSuccess(success, data, error) : handleSubmitError(success, data, error)} />
        </>
    );
}
const mapStateToProps = (state: ApplicationState) => (
    {
        userId: state.app.user?.userId,
        fetchUpdate: selectors.getFormState(
            state,
            // GET_BREAK_CAPACITY
            UPDATE_BREAK_CAPACITY
        ),
    }
)
const mapDispatchToProps = (dispatch: any) => {
    return {
        onCreate: (data: any) => dispatch(actionCreators.fetch(CREATE_BREAK_CAPACITY, `/master/break_capacity/add`, "POST", data,false,true)),
        onUpdate: (data: any) => dispatch(actionCreators.fetch(UPDATE_BREAK_CAPACITY, `/master/break_capacity/modify`, "POST", data,false,true)),
        onSuccess: () =>
            dispatch(actionCreators.showMessage({
                type: "info",
                title: "遮断容量更新",
                body: "時間倍率を更新しました。",
            })),
        onError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "検索",
                body: "検索できません。",
            })),
        onCreateError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "遮断容量登録",
                body: "遮断容量を登録出来ませんでした。",
            })),
        onUpdateError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "遮断容量編集",
                body: "遮断容量を更新出来ませんでした。",
            })),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(BreakCapacityEdit as any);