import * as React from 'react'
import { connect } from 'react-redux'
import { ApplicationState, FetchState, selectors } from '../store'
import { actionCreators } from '../store/AppStore'

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE'

export type FetchHelperProps<T, P> = {
    fetchId: string;
    url?: string;
    method?: HttpMethod;
    params?: P;
    request?: boolean;
    onComplete?: (success: boolean, data: T, error: any) => void;
    multipart?: boolean,
    json?:boolean;
    retry?: number;
}

export interface FetchHelper<T = any, P = any> {
    (props: FetchHelperProps<T, P>): JSX.Element
}

export type PureFetchHelperProps<T = any, P = any> =
    FetchHelperProps<T, P> &
    ReturnType<typeof mapStateToProps> &
    typeof mapDispatchToProps;

export const PureFetchHelper = (props: PureFetchHelperProps) => {
    const { fetchId, url, method, params, request, multipart, fetchState,json, retry = 0, onRequest, onInitFetch, onDiscardFetch, onComplete } = props;
    const onInit = () => {
        onInitFetch && onInitFetch(fetchId);
        return () => { onDiscardFetch && onDiscardFetch(fetchId) }
    }
    const onRequestChange = () => {
        if (url && request) {
            onRequest && onRequest(fetchId, url, method || 'GET', params, multipart,json||false, retry);
        }
    }
    const onLoadingChange = () => {
        if (fetchState && fetchState.requested && !fetchState.loading) {
            const { data, success, error } = fetchState
            onComplete && onComplete(success || false, data, error);
        }
    }
    React.useEffect(onInit, [fetchId])
    React.useEffect(onRequestChange, [url, request])
    React.useEffect(onLoadingChange, [fetchState])
    return null;
}

interface mapStateToProps<T = any, P = any> {
    (state: ApplicationState, ownProps: FetchHelperProps<T, P>): {
        fetchState: FetchState<T>
    }
}

const mapStateToProps: mapStateToProps = (state, ownProps) => ({
    fetchState: selectors.getFetchState(state, ownProps.fetchId)
})

const mapDispatchToProps = {
    onRequest: actionCreators.fetch,
    onInitFetch: actionCreators.initFetch,
    onDiscardFetch: actionCreators.discardFetch,
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PureFetchHelper as any) as FetchHelper