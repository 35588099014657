import { store } from "../../..";
import { ControlModel, GraphLineData, MsDSModel } from "../../../models/Index";
import { MS_MODE_CALCULATION } from "../../../statics";
import { isPointFaultDS } from "../../../utils/DSFunction";
import { doSetPosCurvePointDirect } from "../DCView";

const doDrawCurveDS = async (props : MsDSModel, element : ControlModel, willCallDb : boolean = false, drawMode: number) => {
    let graphLineData : GraphLineData = { lines: [], }
    let listInfo : any[] = []
    
    if (element.raw) { 
        listInfo = element.raw
    }
    
    if(drawMode === 0 || drawMode === 2) {
         // get data draw calcpoint
        let mode = store.getState().app.diagram.processMode;
        if (mode >= MS_MODE_CALCULATION && isPointFaultDS(props)) {
            let temp = doSetPosCurvePointDirect(props.calcPoint0, 0);
            if(temp !== undefined) {
                graphLineData.lines.push(temp);
            }

            temp = doSetPosCurvePointDirect(props.calcPoint1, 1);
            if(temp !== undefined) {
                graphLineData.lines.push(temp)
            }
        }
    }

    return graphLineData
}



export {
    doDrawCurveDS
}