import { Box, Button, Checkbox, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Stack, Tab, Tabs, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { InfoCircuit, MsMotorGroupModel } from '../../models/Index';
import TextFieldOptions from "../common/TextFieldOptions";
import { BeamsNumber } from "../../utils/FormatNumber";
import { MS_PI } from "../../models/Constants";
import * as Constant from "../../models/Constants";
import { MS_MODE_CHECKING } from "../../statics";

export type Image3_1EditDialogFormProps = {
    data: any;
    onOK: (data: any) => void;
    onCancel: () => void;
    infoCircuit: InfoCircuit;
    userRole: string;
    m_bModePM: boolean;
    processMode: number;
    modeViewOnly: boolean;
};

function a11yProps(index: number) {
    return {
        id: `tab-${index}`,
        "aria-controls": `tabpanel-${index}`,
    };
}

const radioStyles = {
    padding: '0 0 0 9px'
}
const checkboxStyles = {
    padding: '4px 4px 4px 9px'
}

//Tabs------------------
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}


export const MsMotorGroupEditDialog = (props: Image3_1EditDialogFormProps) => {
    const { userRole, data, onOK, onCancel,infoCircuit,m_bModePM,processMode, modeViewOnly } = props;
    const [selectTab, setSelectTab] = useState(0);

    // 初期値設定
    const initialValue: MsMotorGroupModel = {
        ...data,
        ratedCurrent: data.ratedCurrent? data.ratedCurrent : 0
    }
    const { control, handleSubmit, formState: { errors }, getValues, setFocus,setValue,setError } = useForm<MsMotorGroupModel>({
        mode: 'all',
        criteriaMode: "all",
        defaultValues: initialValue,
    });
    const [isCapacityEqualZero, setIsCapacityEqualZero] = useState(initialValue.capacity === 0);
    const [isDisabledField10, setIsDisabledField10] = useState<boolean>(!initialValue.calcImprovePF);
    const [isDisableOKBtn, setIsDisableOKBtn] = useState(false);
    const [isDisablePercentZCalculate, setIsDisablePercentZCalculate] = useState(!(+initialValue.percentZCalculate));
    const [isChangeCapacityOneTimes, setIsChangeCapacityOneTimes] = useState((+initialValue.capacity) === 0);
    const [isChangeCapacity1BigerThanCapacity, setIsChangeCapacity1BigerThanCapacity] = useState(false);


    // Submitイベント
    const handleEditSubmit = (value: MsMotorGroupModel) => {
        const request = createRequestData(value);
        onOK(request);
    }
    const handleDisablePercentZCalculate =(e:any) =>{
        setValue(e.target.name,e.target.value)
        if(e.target.value == 0){
            setIsDisablePercentZCalculate(true)
        }else{
        setIsDisablePercentZCalculate(false)
        }
    }

    // FormデータからRequestデータを作成する
    const createRequestData = (formValue: MsMotorGroupModel) => {
        if(!formValue.loadFactor){
            formValue.loadFactor = 0
        }
        if(!formValue.improveAfterPF){
            formValue.improveAfterPF = 0
        }
        let request = {
            ...formValue
        } as MsMotorGroupModel;
        return request;
    }

    // キャンセル
    const handleCancel = () => {
        onCancel();
    };

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
       
        if (newValue >= 0) {
            if (getValues('refNo') == "" ||getValues('refNo') == null || getValues('refNo') == undefined ) {
                setError("refNo",{ type: 'custom', message: '必須項目です。入力してください。' });
            }
            else if (isNaN(Number(getValues('voltage'))) || Number(getValues('voltage')) <= 0 || !/^[0-9\b]+$/.test(getValues('voltage').toString())) {
                setError("voltage",{ type: 'custom', message: '整数 > 0 を入力してください。' });
            }
            else if (isNaN(Number(getValues('capacity'))) || Number(getValues('capacity')) <= 0 || !/^[0-9]*[.]?[0-9]+$/.test(getValues('capacity').toString())) {
                setError("capacity",{ type: 'custom', message: '数字 > 0 を入力してください。' });
                setFocus("capacity");
            } 
            else if (isNaN(Number(getValues('powerFactor'))) || Number(getValues('powerFactor')) <= 0 || Number(getValues('powerFactor')) > 1 ||!/^[0-9]*[.]?[0-9]+$/.test(getValues('powerFactor').toString())) {
                setError("powerFactor",{ type: 'custom', message: '0 < 数字 <= 1 を入力してください。' });
            }
            else if (isNaN(Number(getValues('loadFactor'))) || Number(getValues('loadFactor')) < 0 || Number(getValues('loadFactor')) > 1 || !/^[0-9]*[.]?[0-9]+$/.test(getValues('loadFactor').toString())) {
                setError("loadFactor",{ type: 'custom', message: '数字 0 ~ 1 を入力してください。' });
            }
            else if (isNaN(Number(getValues('improveAfterPF'))) || Number(getValues('improveAfterPF')) < 0 || Number(getValues('improveAfterPF')) > 1 || !/^[0-9]*[.]?[0-9]+$/.test(getValues('improveAfterPF').toString())) {
                setError("improveAfterPF",{ type: 'custom', message: '数字 0 ~ 1 を入力してください。' });
            }
            else if (isNaN(Number(getValues('subReactance'))) || Number(getValues('subReactance')) <= 0 || !/^[0-9]*[.]?[0-9]+$/.test(getValues('subReactance').toString())) {
                setError("subReactance",{ type: 'custom', message: '数字 > 0 を入力してください。' });
            } 
            else if (isNaN(Number(getValues('rotorResistance'))) || Number(getValues('rotorResistance')) <= 0 || !/^[0-9]*[.]?[0-9]+$/.test(getValues('rotorResistance').toString())) {
                setError("rotorResistance",{ type: 'custom', message: '数字 > 0 を入力してください。' });
            }
            else if (isNaN(Number(getValues('statorResistance'))) || Number(getValues('statorResistance')) <= 0 || !/^[0-9]*[.]?[0-9]+$/.test(getValues('statorResistance').toString())) {
                setError("statorResistance",{ type: 'custom', message: '数字 > 0 を入力してください。' });
            }
            else if (isNaN(Number(getValues('capacity1'))) || Number(getValues('capacity1')) <= 0 || !/^[0-9]*[.]?[0-9]+$/.test(getValues('capacity1').toString()) || Number(getValues('capacity1')) > getValues('capacity')) {
                setError("capacity1",{ type: 'custom', message: '0 < 数字 <= 総容量(kVA) を入力してください。' });
            }
            else if (isNaN(Number(getValues('xr'))) || Number(getValues('xr')) <= 0 || !/^[0-9]*[.]?[0-9]+$/.test(getValues('xr').toString())) {
                setError("xr",{ type: 'custom', message: '数字 > 0 を入力してください。' });
            }
            else if (isNaN(Number(getValues('percentZ'))) || Number(getValues('percentZ')) <= 0 || !/^[0-9]*[.]?[0-9]+$/.test(getValues('percentZ').toString())) {
                setError("percentZ",{ type: 'custom', message: '数字 > 0 を入力してください。' });
            }
            else {
                setSelectTab(newValue);
                if(+getValues("capacity") > 0){
                    setIsChangeCapacityOneTimes(false)
                }
            }
        }

        
    };
    const handleChangeFormat=(e:any) =>{
        setValue(e.target.name, e.target.value,{shouldValidate:true})
    }
    const handleChangeFormat1=(e:any) =>{
        setValue(e.target.name, e.target.value)
        if(+e.target.value > (+getValues("capacity"))){
                setIsChangeCapacity1BigerThanCapacity(true)
            }
        else{
            setIsChangeCapacity1BigerThanCapacity(false)
        }
    }
    const handleDisabled = (e: any) => {
        setIsDisabledField10(!isDisabledField10);
    }
    //tab0
    const doGetValueRatedCurrent =() =>{
        let voltage = +getValues("voltage")
        let loadFactor = +getValues("loadFactor")
        let capacity = +getValues("capacity")
        if(voltage <=0 || loadFactor < 0 || loadFactor > 1 || capacity < 0) {
            setIsDisableOKBtn(true)
           
        }
        else{
            if(voltage > 0 && loadFactor >= 0 && loadFactor <= 1 && capacity >=0) {          
                setIsDisableOKBtn(false)
            }else{
                setIsDisableOKBtn(true)
            }
        }
        if(voltage <=0){
            return 0
        }
        let ratedCurrent = loadFactor * capacity * 1000 /voltage / Math.sqrt(3.0)
        return ratedCurrent

    }
    //tab0
    const doSetValuesMotorGrp =(e:any) =>{
        setValue(e.target.name, e.target.value) 
        let ratedCurrent = doGetValueRatedCurrent()

        setValue("ratedCurrent",BeamsNumber(ratedCurrent,4))
    }
    const doReadByCapacity =(capacityList:any) =>{
        const capacity = getValues("capacity")
        for(const item of capacityList){
            if(capacity <= item.dcapacity || item.dcapacity === -1){
                setValue("subReactance",item.dsubReactance)
                setValue("statorResistance",item.dstatorResistance)
                setValue("rotorResistance",item.drotorResistance)
                break;
            }
        }
    }
    const doCalcSetValue = (capacity:number) =>{
        if(capacity <= 0 ) {
            setValue("subReactance",0)
            setValue("statorResistance",0)
            setValue("rotorResistance",0)
            return;
        }
        doReadByCapacity(getValues("capacityList"))

    }
    const doChangeCapacity = (e:any) =>{
        setValue(e.target.name, e.target.value)
        let capacity = +getValues("capacity")
        if(capacity <=0){
            setIsDisableOKBtn(true)
        }else{
            setIsDisableOKBtn(false)

        }
        doCalcSetValue(capacity)
        doSetValuesMotorGrp(e)
    }
    // const handleDisabledOkBt = (e:any) =>{
    //      // //Check disable ok button
    //     // if(subReactance <= 0 || rotorResistance <= 0 || statorResistance <=0 || capacity1 <= 0 || capacity1 > capacity
    //     //     || xr <= 0 || percentZ <=0){
    //     //     setIsDisableOKBtn(true)
    //     // }else{
    //     //     if(subReactance > 0 && rotorResistance > 0 && statorResistance > 0 && capacity1 > 0 && capacity1 < capacity && xr > 0
    //     //         && percentZ > 0){
    //     //     setIsDisableOKBtn(false)
    //     //     }
    //     //     else{
    //     //     setIsDisableOKBtn(true)
    //     //     }
    //     // }
    //     // setValue(e.target.name, e.target.value)
    //     // let loadFactor = getValues("loadFactor")
    //     // let improveAfterPF = getValues("improveAfterPF")
    //     // let powerFactor = getValues("powerFactor")
    //     // let subReactance = getValues("subReactance")
    //     // let rotorResistance = getValues("rotorResistance")
    //     // let statorResistance = getValues ("statorResistance")
    //     // let capacity1 = getValues("capacity1")
    //     // let capacity = getValues("capacity")
    //     // let xr = getValues("xr")
    //     // let percentZ = getValues("percentZ")

    //     // if(loadFactor < 0 || loadFactor > 1  || powerFactor <=0 || powerFactor > 1 || subReactance <= 0
    //     //     || rotorResistance <=0 || statorResistance <=0 || capacity1 <=0 || capacity1 > capacity || xr <=0 || percentZ <=0){
    //     //     setIsDisableOKBtn(true)
    //     // }else{
    //     //     if(loadFactor >=0 && loadFactor <=1 && improveAfterPF >=0 && improveAfterPF<=1 && powerFactor > 0 && powerFactor <=1 && subReactance >0 && rotorResistance >0
    //     //         && statorResistance > 0 && capacity1 > 0 && capacity1 <= capacity && xr >0 && percentZ > 0){
    //     //         setIsDisableOKBtn(false)
    //     //     }else{
    //     //         setIsDisableOKBtn(true)
    //     //     }
    //     // }
    // }
    const doGetSubPercentZ =(rotorResistance:number,statorResistance:number,exResistance:number,subReactance:number,exReactance:number) =>{//4
        let subPercent = Math.pow(statorResistance +rotorResistance +exReactance,2) + Math.pow(subReactance + exReactance, 2)
        subPercent = Math.sqrt(subPercent)

        return subPercent
    }
    const doGetConstant = (n_frequency:number,statorResistance:number,subReactance:number) =>{//4
        if(n_frequency === 0 || statorResistance === 0) {
            return 0
        }
        let constant = subReactance / (2 * MS_PI * n_frequency * statorResistance)

        return constant
    }
    const doGetSubConstant =(n_frequency:number,rotorResistance:number,subReactance:number,exReactance:number) =>{
        if(n_frequency === 0 || rotorResistance === 0) {
            return 0;
        }
        const subConstant = (subReactance + exReactance) / (2 * MS_PI * n_frequency * rotorResistance)

        return subConstant

    }
    const doGetValueInitCurrent = (ratedCurrent:number,subPerCent:number) =>{
        const initialCurrent  = subPerCent === 0 ? 0 : ratedCurrent / subPerCent * 100
        return initialCurrent
    }

    const doGetInitialCurrent = (rotorResistance:number,statorResistance:number,exResistance:number,subReactance:number,exReactance:number) =>{
        let ratedCurrent = doGetValueRatedCurrent()
        let subPerCent = doGetSubPercentZ(rotorResistance,statorResistance,exResistance,subReactance,exReactance)
        let initialCurrent  = doGetValueInitCurrent(ratedCurrent,subPerCent)

        return initialCurrent/1000
    
    }
    const doGetValueSymCurrent = (n_frequency:number,initialCurrent:number,subConstant:number) => {
        if(n_frequency === 0 || subConstant === 0){
            return 0
        }
        const symmetricalCurrent = initialCurrent * Math.exp(-1 / (n_frequency * 2 * subConstant) )

        return symmetricalCurrent
    }
    const doGetSymCurrent = (n_frequency:number,rotorResistance:number,statorResistance:number,exResistance:number,subReactance:number,exReactance:number) =>{
        let ratedCurrent = doGetValueRatedCurrent()
        let subConstant = doGetSubConstant(n_frequency,rotorResistance,subReactance,exReactance)
        let subPerCent = doGetSubPercentZ(rotorResistance,statorResistance,exResistance,subReactance,exReactance)
        let initialCurrent  = doGetValueInitCurrent(ratedCurrent,subPerCent)
        let symmetricalCurrent = doGetValueSymCurrent(n_frequency,initialCurrent,subConstant)
        
        return symmetricalCurrent/1000
    }
    const doGetValuePeakValue = (n_frequency:number, initialCurrent:number, symmetricalCurrent:number,constant:number) =>{
        let peakValue = 0
        if(n_frequency === 0 || constant === 0){
            peakValue = Math.sqrt(2.0) * symmetricalCurrent
        }
        else{
            peakValue = Math.sqrt(2.0) * symmetricalCurrent + Math.sqrt(2.0) * initialCurrent * Math.exp(-1 / (n_frequency * 2 * constant))
        }
        return peakValue
    } 
    const doGetPeakValue =(n_frequency:number,rotorResistance:number,statorResistance:number,exResistance:number,subReactance:number,exReactance:number) =>{
        let ratedCurrent = doGetValueRatedCurrent()
        let subConstant = doGetSubConstant(n_frequency,rotorResistance,subReactance,exReactance)
        let constant = doGetConstant(n_frequency,statorResistance,subReactance)
        let subPerCent = doGetSubPercentZ(rotorResistance,statorResistance,exResistance,subReactance,exReactance)
        let initialCurrent  = doGetValueInitCurrent(ratedCurrent,subPerCent)
        let symmetricalCurrent = doGetValueSymCurrent(n_frequency,initialCurrent,subConstant)
        let peakValue = doGetValuePeakValue(n_frequency, initialCurrent, symmetricalCurrent,constant)

        return peakValue / 1000
    }
    const doGetBackPercentRe = (capacity1:number,xr:number,percentZ:number) =>{
        let percentR = 0
        if(capacity1 !== 0) {
            percentR = percentZ / Math.sqrt(1 + xr * xr)
        }
        return percentR
    }
    const doGetBackPercentXe = (capacity1:number,xr:number,percentZ:number) =>{
        let percentX = 0
        if(xr !== 0 && capacity1 !== 0){
            const xr1 = 1/ xr
            percentX = percentZ / Math.sqrt(1 + xr1 * xr1)
        }
        return percentX
    }
    const doGetValueOhmR =(voltage:number,capacity1:number,percentR:number) =>{
        if(capacity1 === 0 || percentR === 0){
            return 0
        }
        const ohmR = voltage * voltage / capacity1 / 1000 * percentR * 10
        return ohmR
    }
    // const doGetValueOhmX =(voltage:number,capacity1:number,percentX:number) =>{
    //     if(capacity1 === 0 || percentX === 0){
    //         return 0
    //     }
    //     const ohmX = voltage * voltage / capacity1 / 1000 * percentX * 10
    //     return ohmX
    // }

    const doGetBackOhmZ = (percentZ:number,xr:number,voltage:number,capacity1:number) =>{
        let percentR = 0
        if(capacity1 !== 0){
            percentR = percentZ / Math.sqrt(1 + xr * xr)
        }
        let ohmR = doGetValueOhmR(voltage,capacity1,percentR)
        let percentX = 0
        if(xr !== 0 && capacity1 !== 0) {
            const xr1 = 1 / xr
            percentX = percentZ / Math.sqrt(1 + xr1 * xr1)
        }
        let ohmX = doGetValueOhmR(voltage,capacity1,percentX)//OhmR 同じ
        let ohmZ  = Math.sqrt(ohmR * ohmR + ohmX * ohmX)

        return ohmZ
    }
    const doGetBackOhmR = (percentZ:number,xr:number,voltage:number,capacity1:number) =>{
        let percentR = 0
        if(capacity1 !== 0) {
            percentR = percentZ / Math.sqrt(1 + xr * xr)
        }
        const ohmR = doGetValueOhmR(voltage,capacity1,percentR)

        return ohmR
    }
    const doGetBackOhmX = (percentZ:number,xr:number,voltage:number,capacity1:number) =>{
        let percentX = 0
        if(xr !== 0 && capacity1 !== 0){
            const xr1 = 1 / xr
            percentX = percentZ / Math.sqrt(1 + xr1 * xr1)
        }
        const ohmX = doGetValueOhmR(voltage,capacity1,percentX)//doGetValueOhmR = doGetValueOhmX

        return ohmX
    }
    //change tab2
    const doSetValuesMotorGrp2 =(e:any,nControl:string) =>{
        setValue(e.target.name, e.target.value)
        const n_frequency = infoCircuit.frequency // hard code
        let voltage = +getValues("voltage")
        let capacity = +getValues("capacity")
        let capacity1 = +getValues("capacity1")
        let subReactance = +getValues("subReactance")
        let rotorResistance = +getValues("rotorResistance")
        let statorResistance = +getValues("statorResistance")
        let xr = +getValues("xr")
        let percentZ = +getValues("percentZ")

       

        // Calc
        let subImpedance  = doGetSubPercentZ(rotorResistance,statorResistance,0,subReactance,0)
        let constant = doGetConstant(n_frequency,statorResistance,subReactance)
        let subConstant = doGetSubConstant(n_frequency,rotorResistance,subReactance,0)
        let initialCurrent = doGetInitialCurrent(rotorResistance,statorResistance,0,subReactance,0)
        let symmetricalCurrent = doGetSymCurrent(n_frequency,rotorResistance,statorResistance,0,subReactance,0)
        let peakValue = doGetPeakValue (n_frequency,rotorResistance,statorResistance,0,subReactance,0)

        let percentR = 0
        if(nControl !== "IDC_PERCENT_R") {
            percentR = doGetBackPercentRe(capacity1,xr,percentZ)
        }
        let percentX = 0
        if(nControl !== "IDC_PERCENT_X") {
            percentX = doGetBackPercentXe(capacity1,xr,percentZ)
        }
        let ohmZ = doGetBackOhmZ(percentZ,xr,voltage,capacity1)
        let ohmR = doGetBackOhmR(percentZ,xr,voltage,capacity1)
        let ohmX = doGetBackOhmX(percentZ,xr,voltage,capacity1)

        setValue("subImpedance",BeamsNumber(subImpedance,4))
        setValue("constant",BeamsNumber(constant,4))
        setValue("subConstant",BeamsNumber(subConstant,4))
        setValue("initialCurrent",BeamsNumber(initialCurrent,4))
        setValue("symmetricalCurrent",BeamsNumber(symmetricalCurrent,4))
        setValue("peakValue",BeamsNumber(peakValue,4))
        setValue("percentR",BeamsNumber(percentR,4))
        setValue("percentX",BeamsNumber(percentX,4))
        setValue("ohmZ",BeamsNumber(ohmZ,4))
        setValue("ohmR",BeamsNumber(ohmR,4))
        setValue("ohmX",BeamsNumber(ohmX,4))

    }
    //Calc  initialCurrent, symmetricalCurrent, peakValue when open project
    const calc3field = (e:any) =>{
        setValue(e.target.name,e.target.value)
        if(isCapacityEqualZero){
            doSetValuesMotorGrp2(e,'')
            setIsCapacityEqualZero(false)
        }

    }

    const setValueCapacity1FirstTimes =(e:any) =>{
        setValue(e.target.name,e.target.value)
        if(isChangeCapacityOneTimes){
            setValue("capacity1",getValues("capacity"))
            doSetValuesMotorGrp2(e,'')
        }
    }
    
    return (
        <>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={selectTab} onChange={handleChange}
                            variant="scrollable" 
                            scrollButtons 
                            allowScrollButtonsMobile 
                            sx={{
                                '& .MuiButtonBase-root.MuiTabScrollButton-root.Mui-disabled': {
                                    display: "none"
                                }
                            }}
                        >
                            <Tab label="基本" {...a11yProps(0)} />
                            <Tab label="寄与電流" {...a11yProps(1)} />
                        </Tabs>
                    </Box>
                <form onSubmit={handleSubmit(handleEditSubmit)}>
                    <div style={{ height: '40vh', width: '100%', margin: 'auto', overflow: 'auto' }}>
                        <TabPanel value={selectTab} index={0}>
                            <Stack spacing={0.3}>
                                <Grid container>
                                    <Grid item xs={6.5}>
                                        <Stack spacing={0.3} mt={1}>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="50%">デバイス名:</Typography>
                                                    <Controller
                                                        name="refNo"
                                                        control={control}
                                                        rules={{
                                                            required: '必須項目です。入力してください。',
                                                        }}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                fullWidth
                                                                type="text"
                                                                size="small"
                                                                // label="デバイス名"
                                                                onChange={handleChangeFormat}
                                                                onFocus={e => e.target.select()}
                                                                InputLabelProps={{
                                                                    style: { color: 'black' },
                                                                }}
                                                                InputProps={{sx:{height:32,backgroundColor:m_bModePM || modeViewOnly?'floralwhite':'white', color:modeViewOnly?"grey":"black"}}}
                                                                inputProps={{readOnly:modeViewOnly}}
                                                                error={!!errors?.refNo}
                                                                helperText={errors?.refNo?.message}
                                                                disabled={m_bModePM}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                                <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="50%">事故点名称:</Typography>
                                                    <Controller
                                                        name="pointText"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                fullWidth
                                                                type="text"
                                                                size="small"
                                                                // label="事故点名称"
                                                                onFocus={e => e.target.select()}
                                                                InputLabelProps={{
                                                                    style: { color: 'black' },
                                                                }}
                                                                InputProps={{sx:{height:32,backgroundColor:m_bModePM || modeViewOnly?'floralwhite':'white', color:modeViewOnly?"grey":"black"}}}
                                                                disabled={m_bModePM}
                                                                inputProps={{readOnly:modeViewOnly}}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                                <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="50%">回路電圧(V):</Typography>
                                                    <FormControl fullWidth size="small">
                                                        <Controller
                                                        name="voltage"
                                                        control={control}
                                                        rules={{
                                                            required: '必須項目です。入力してください。' ,
                                                            pattern:{
                                                            value: /^[0-9\b]+$/,
                                                            message: '数字を入力してください。'
                                                            },
                                                            validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                        //     maxLength: {
                                                        //        value: 6,
                                                        //        message: "最大長さ <= 6 を入力してください。"
                                                        //    }
                                                            }}
                                                        render={({ field }) => (
                                                            <TextFieldOptions
                                                                    {...field}
                                                                    options={initialValue.voltageList}
                                                                    // label="回路電圧(V)"
                                                                    // onChange= {handleInput}
                                                                    size="small"

                                                                    setValue={(name: any, value: any) => {
                                                                        handleChangeFormat({target: {name: name, value: value}});
                                                                        // handleInput({target: {name: name, value: value}});
                                                                        doSetValuesMotorGrp({target: {name: name, value: value}})
                                                                    }}
                                                                    disabled={getValues("isSourceVoltage")||m_bModePM || modeViewOnly}
                                                                    height={32}
                                                                    error={!!errors?.voltage}
                                                                    helperText={errors?.voltage?.message}
                                                                    maxLength={6}
                                                                    
                                                                />
                                                    
                                                        )}
                                                    />
                                                    </FormControl>
                                                </Stack>
                                                <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="50%">総容量(kVA):</Typography>
                                                    <Controller
                                                        name="capacity"
                                                        control={control}
                                                        rules={{
                                                            required: '必須項目です。入力してください。' ,
                                                            pattern:{
                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                            message: '数字を入力してください。'
                                                            },
                                                            validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                        //     maxLength: {
                                                        //        value: 6,
                                                        //        message: "最大長さ <= 6 を入力してください。"
                                                        //    }
                                                            }}

                                                        render={({ field }) => {
                                                            const fieldWithoutRef = { ...field, ref: undefined };
                                                            return (
                                                                <TextField
                                                                    {...fieldWithoutRef}
                                                                    inputRef={field.ref}
                                                                    fullWidth
                                                                    type="text"
                                                                    size="small"
                                                                    // label="総容量(kVA)"
                                                                    error={!!errors?.capacity}
                                                                    helperText={errors?.capacity?.message}
                                                                    onFocus={e => e.target.select()}
                                                                    InputLabelProps={{
                                                                        style: { color: 'black' },
                                                                    }}
                                                                    onChange={(e) =>{handleChangeFormat(e); doChangeCapacity(e);calc3field(e);
                                                                        setValueCapacity1FirstTimes(e)}}
                                                                    disabled={m_bModePM|| processMode > MS_MODE_CHECKING}
                                                                    InputProps={{sx:{height:32,backgroundColor:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white', color:modeViewOnly?"grey":"black"}}}
                                                                    inputProps={{maxLength:6,readOnly:modeViewOnly}}
                                                                />
                                                            )
                                                        }}
                                                    />
                                                </Stack>
                                                <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="50%">全負荷電流(A):</Typography>
                                                    <Controller
                                                        name="ratedCurrent"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                fullWidth
                                                                type="text"
                                                                size="small"
                                                                // label="全負荷電流(A)"
                                                                InputLabelProps={{
                                                                    style: { color: 'black' },
                                                                }}
                                                                inputProps={{
                                                                    readOnly: true,
                                                                }}
                                                                style={{
                                                                    backgroundColor: 'floralwhite',
                                                                }}
                                                                InputProps={{sx:{height:32, backgroundColor: 'floralwhite', color:modeViewOnly?"grey":"black"}}}
                                                                disabled={m_bModePM}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                                <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="50%">力率(遅れ):</Typography>
                                                    <Controller
                                                        name="powerFactor"
                                                        control={control}

                                                    rules={{
                                                            required: '必須項目です。入力してください。' ,
                                                            pattern:{
                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                            message: '数字を入力してください。'
                                                            },
                                                            validate: value => value > 0 && value <= 1|| '数字 > 0 && <= 1 を入力してください。' ,
                                                        //     maxLength: {
                                                        //        value: 5,
                                                        //        message: "最大長さ <= 5 を入力してください。"
                                                        //    }
                                                            }}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                fullWidth
                                                                type="text"
                                                                size="small"
                                                                // label="力率(遅れ)"
                                                                error={!!errors?.powerFactor}
                                                                helperText={errors?.powerFactor?.message}
                                                                onFocus={e => e.target.select()}
                                                                InputLabelProps={{
                                                                    style: { color: 'black' },
                                                                }}
                                                                onChange={(e)=>{handleChangeFormat(e)}}
                                                                InputProps={{sx:{height:32,backgroundColor:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white', color:modeViewOnly?"grey":"black"}}}
                                                                inputProps={{maxLength:5, readOnly:modeViewOnly}}
                                                                disabled={m_bModePM|| processMode > MS_MODE_CHECKING}

                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                                <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="50%">負荷率:</Typography>
                                                    <Controller
                                                        name="loadFactor"
                                                        control={control}
                                                        rules={{
                                                            
                                                            pattern:{
                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                            message: '数字を入力してください。'
                                                            },
                                                            validate: value => value >= 0 && value <= 1|| '数字 0 ~ 1 を入力してください。' ,
                                                        //     maxLength: {
                                                        //        value: 5,
                                                        //        message: "最大長さ <= 5 を入力してください。"
                                                        //    }
                                                            }}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                fullWidth
                                                                type="text"
                                                                size="small"
                                                                // label="負荷率"
                                                                error={!!errors?.loadFactor}
                                                                helperText={errors?.loadFactor?.message}
                                                                onFocus={e => e.target.select()}
                                                                InputLabelProps={{
                                                                    style: { color: 'black' },
                                                                }}
                                                                onChange={(e) =>{handleChangeFormat(e);doSetValuesMotorGrp(e)}}
                                                                InputProps={{sx:{height:32,backgroundColor:m_bModePM || modeViewOnly|| processMode > MS_MODE_CHECKING?'floralwhite':'white', color:modeViewOnly?"grey":"black"}}}
                                                                inputProps={{maxLength:5, readOnly:modeViewOnly}}
                                                                disabled={m_bModePM|| processMode > MS_MODE_CHECKING}

                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={5.5} pl={1}>
                                        <Stack spacing={0.3}>
                                            <Grid>
                                                <Controller
                                                    name="fault"
                                                    control={control}
                                                    render={({ field }: any) => (
                                                        <FormControlLabel {...field} control={<Checkbox style={checkboxStyles} />} checked={field.value} label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>短絡電流を計算</Typography>}
                                                         name='fault' disabled={m_bModePM || modeViewOnly}/>
                                                    )}
                                                />
                                            </Grid>
                                            <Grid>
                                                <Controller
                                                    name="calcImprovePF"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <FormControlLabel
                                                            {...field}
                                                            control={<Checkbox style={checkboxStyles} />}
                                                            checked={field.value}
                                                            label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>力率改善を計算</Typography>}
                                                            name='calcImprovePF'
                                                            onChange={(e: any) => {
                                                                field.onChange(e);
                                                                handleDisabled(e)
                                                            }}
                                                            disabled={m_bModePM || modeViewOnly}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center"}}>
                                                <Grid container marginLeft={3} sx={{ flexDirection: "row", alignItems:"center"}}>
                                                    <Grid item xs={7}>
                                                        <Typography variant="body2" width="100%" color={isDisabledField10||m_bModePM?'grey':'black'}>改善後の目標力率:</Typography>
                                                    </Grid>
                                                    <Grid xs ={5} ml={-1}>
                                                        <Controller
                                                            name="improveAfterPF"
                                                            control={control}
                                                            rules={{
                                                                pattern:{
                                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                                message: '数字を入力してください。'
                                                                },
                                                                validate: value => value >= 0 && value <= 1 || '数字 0 ~ 1 を入力してください。' ,
                                                            //     maxLength: {
                                                            //        value: 5,
                                                            //        message: "最大長さ <= 5 を入力してください。"
                                                            //    }
                                                                }}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    disabled={isDisabledField10||m_bModePM}
                                                                    fullWidth
                                                                    type="text"
                                                                    size="small"
                                                                    // label="改善後の目標力率"
                                                                    onFocus={e => e.target.select()}
                                                                    InputLabelProps={{
                                                                        style: { color: 'black' },
                                                                    }}
                                                                    onChange={(e)=>{handleChangeFormat(e)}}
                                                                    InputProps={{sx:{height:32,backgroundColor:isDisabledField10||m_bModePM || modeViewOnly?'floralwhite':'white', color:modeViewOnly?"grey":"black"}}}
                                                                    error={!!errors?.improveAfterPF}
                                                                    helperText={errors?.improveAfterPF?.message}
                                                                    inputProps={{maxLength:5, readOnly:modeViewOnly}}
                                                                />
                                                            )}
                                                        />
                                                        </Grid>
                                                    </Grid>
                                            </Stack>
                                            <Grid>
                                                <Typography style={{ display: 'flex', alignItems: 'center',marginTop:'15px' }} variant="body2">(初期値=上位変圧器総容量×80%)</Typography>
                                            </Grid>
                                            <Grid>
                                                {/* Mode SP */}
                                                {(userRole && !(userRole == Constant.ROLE_LP)) &&
                                                <Grid>
                                                    <Controller
                                                        name="viewFlowResult"
                                                        control={control}
                                                        render={({ field }: any) => (
                                                            <FormControlLabel {...field} control={<Checkbox style={checkboxStyles} />} checked={field.value} label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>潮流解析用の値を表示</Typography>} name='viewFlowResult'
                                                            disabled={m_bModePM || modeViewOnly} />
                                                        )}
                                                    />
                                                </Grid>
                                                }

                                                <Grid>
                                                    <Controller
                                                        name="viewResultText"
                                                        control={control}
                                                        render={({ field }: any) => (
                                                            <FormControlLabel {...field} control={<Checkbox style={checkboxStyles}/>} checked={field.value} label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>注釈を表示</Typography>} name='viewResultText'
                                                            disabled={m_bModePM || modeViewOnly} />
                                                        )}
                                                    />
                                                </Grid>
                                            </Grid>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Stack>
                    </TabPanel>
                    <TabPanel value={selectTab} index={1}>
                        <Stack mt={1} mb={1}>
                            <Grid>
                                <Controller
                                    name="percentZCalculate"
                                    control={control}
                                    render={({ field }) => (
                                        <>
                                            <RadioGroup {...field} onChange={(e) =>handleDisablePercentZCalculate(e)}>
                                                <FormControlLabel value="0" control={<Radio style={radioStyles} />} label={<Typography variant="body2" color={m_bModePM|| processMode > MS_MODE_CHECKING?'grey':'black'}>IEC 61363-1で計算する</Typography>} 
                                                disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}/>
                                            </RadioGroup>
                                        </>
                                    )}
                                />
                            </Grid>
                        </Stack>
                        <fieldset  style={{border:"1px solid #AAAA"}}>
                            <legend><Typography variant="body2">インピーダンス</Typography></legend>
                            <Stack spacing={0.3}>
                                <Grid container spacing={0.3} direction="row">
                                    <Grid item xs={12}>
                                        <Stack spacing={0.3}>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="100%">初期過渡リアクタンス X"m(%):</Typography>
                                                <Controller
                                                    name="subReactance"
                                                    control={control}
                                                    rules={{
                                                        required: '必須項目です。入力してください。',
                                                        pattern: {
                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                            message: '数字を入力してください。'
                                                        },
                                                        validate: value => value > 0 || '数字 > 0 を入力してください。',
                                                        // maxLength:{
                                                        //     value: 5,
                                                        //     message:'最大長さ <= 5 を入力してください'
                                                        // }
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            // type="text"
                                                            size="small"
                                                            // label='初期過渡リアクタンス X"m(%)'
                                                            onFocus={e => e.target.select()}
                                                            error={!!errors?.subReactance}
                                                            helperText={errors?.subReactance?.message}
                                                            InputLabelProps={{
                                                                // style: { color: 'black' },
                                                                style: {
                                                                    color:isDisablePercentZCalculate?'black': 'grey'
                                                                }
                                                            }}
                                                            InputProps={{
                                                                // maxLength: 5,
                                                                sx:{height:32, backgroundColor:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':isDisablePercentZCalculate ?'white':'floralwhite', color:modeViewOnly?"grey":isDisablePercentZCalculate?'black': 'grey'}
                                                                
                                                            }}
                                                            style={{
                                                                color:"black",
                                                            }}
                                                            onChange={(e) =>{
                                                                field.onChange(e);
                                                                doSetValuesMotorGrp2(e,"");}}
                                                            disabled={!isDisablePercentZCalculate||m_bModePM|| processMode > MS_MODE_CHECKING}
                                                            inputProps={{maxLength:5,readOnly:modeViewOnly}}

                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="100%">ロータ抵抗 Rr(%):</Typography>
                                                <Controller
                                                    name="rotorResistance"
                                                    control={control}
                                                    rules={{
                                                        required: '必須項目です。入力してください。',
                                                        pattern: {
                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                            message: '数字を入力してください。'
                                                        },
                                                        validate: value => value > 0 || '数字 > 0 を入力してください。',
                                                        // maxLength:{
                                                        //     value: 5,
                                                        //     message:'最大長さ <= 5 を入力してください'
                                                        // }
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            // type="text"
                                                            size="small"
                                                            // label='ロータ抵抗 Rr(%)'
                                                            onFocus={e => e.target.select()}
                                                            error={!!errors?.rotorResistance}
                                                            helperText={errors?.rotorResistance?.message}
                                                            InputLabelProps={{
                                                                style: {
                                                                    color:isDisablePercentZCalculate?'black': 'grey'
                                                                }
                                                            }}
                                                            InputProps={{
                                                                // maxLength: 5,
                                                                sx:{height:32, backgroundColor:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':isDisablePercentZCalculate ?'white':'floralwhite', color:modeViewOnly?"grey":isDisablePercentZCalculate?'black': 'grey'}

                                                            }}
                                                            style={{
                                                                color:'black',
                                                            }}
                                                            onChange={e=>{
                                                                field.onChange(e);
                                                                doSetValuesMotorGrp2(e,"");}}
                                                            disabled={!isDisablePercentZCalculate||m_bModePM|| processMode > MS_MODE_CHECKING}
                                                            inputProps={{maxLength:5,readOnly:modeViewOnly}}

                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="100%">ステータ抵抗 Rs(%):</Typography>
                                                <Controller
                                                    name="statorResistance"
                                                    control={control}
                                                    rules={{
                                                        required: '必須項目です。入力してください。',
                                                        pattern: {
                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                            message: '数字を入力してください。'
                                                        },
                                                        validate: value => value > 0 || '数字 > 0 を入力してください。',
                                                        // maxLength:{
                                                        //     value: 5,
                                                        //     message:'最大長さ <= 5 を入力してください'
                                                        // }
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            // type="text"
                                                            size="small"
                                                            // label='ステータ抵抗 Rs(%)'
                                                            onFocus={e => e.target.select()}
                                                            error={!!errors?.statorResistance}
                                                            helperText={errors?.statorResistance?.message}
                                                            InputLabelProps={{
                                                                style: {
                                                                    color:isDisablePercentZCalculate|| processMode > MS_MODE_CHECKING?'black': 'grey'
                                                                }
                                                            }}
                                                            InputProps={{
                                                                // maxLength: 5,
                                                                sx:{height:32, backgroundColor:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':isDisablePercentZCalculate ?'white':'floralwhite', color:modeViewOnly?"grey":isDisablePercentZCalculate|| processMode > MS_MODE_CHECKING?'black': 'grey'}
                                                            }}
                                                            style={{
                                                                color:"black",
                                                            }}
                                                            onChange={e=>{
                                                                field.onChange(e);
                                                                doSetValuesMotorGrp2(e,"");}}
                                                            disabled={!isDisablePercentZCalculate||m_bModePM|| processMode > MS_MODE_CHECKING}
                                                            inputProps={{maxLength:5,readOnly:modeViewOnly}}

                                                        />
                                                    )}
                                                />
                                            </Stack>                                
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="100%">初期過渡インピーダンス Z" m(%):</Typography>
                                                <Controller
                                                    name="subImpedance"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type="text"
                                                            size="small"
                                                            // label='初期過渡インピーダンス Z" m(%)'
                                                            onFocus={e => e.target.select()}
                                                            InputLabelProps={{
                                                                style: { color: 'grey' },
                                                            }}
                                                            InputProps={{
                                                                readOnly: true,
                                                                style: {
                                                                    color: 'grey'
                                                                },
                                                                sx:{height:32, backgroundColor: 'floralwhite', color:modeViewOnly?"grey":"black"}
                                                            }}
                                                            style={{
                                                                backgroundColor: 'floralwhite',
                                                            }}
                                                            disabled={!isDisablePercentZCalculate||m_bModePM}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="100%">直流時定数 Tdcm(s):</Typography>
                                                <Controller
                                                    name="constant"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type="text"
                                                            size="small"
                                                            // label='直流時定数 Tdcm(s)'
                                                            onFocus={e => e.target.select()}
                                                            InputLabelProps={{
                                                                style: { color: 'grey' },
                                                            }}
                                                            InputProps={{
                                                                readOnly: true,
                                                                style: {
                                                                    color: 'grey'
                                                                },
                                                                sx:{height:32,backgroundColor: 'floralwhite', color:modeViewOnly?"grey":"black"}
                                                            }}
                                                            style={{
                                                                backgroundColor: 'floralwhite',
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="100%">初期過渡時定数 T"m(s):</Typography>
                                                <Controller
                                                    name="subConstant"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type="text"
                                                            size="small"
                                                            // label='初期過渡時定数 T"m(s)'
                                                            onFocus={e => e.target.select()}
                                                            InputLabelProps={{
                                                                style: { color: 'grey' },
                                                            }}
                                                            InputProps={{
                                                                readOnly: true,
                                                                style: {
                                                                    color: 'grey'
                                                                },
                                                                sx:{height:32, backgroundColor: 'floralwhite', color:modeViewOnly?"grey":"black"}
                                                            }}
                                                            style={{
                                                                backgroundColor: 'floralwhite',
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Stack>
                    </fieldset>
                    <fieldset  style={{border:"1px solid #AAAA",marginTop:"5px"}}>
                        <legend><Typography variant="body2">短絡電流</Typography></legend>
                        <Stack spacing={0.3}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Stack spacing={0.3}>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="100%">初期過渡短絡電流 I”m(kA):</Typography>
                                                <Controller
                                                    name="initialCurrent"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type="text"
                                                            size="small"
                                                            // label='初期過渡短絡電流 I”m(kA)'
                                                            onFocus={e => e.target.select()}
                                                            InputLabelProps={{
                                                                style: { color: 'grey' },
                                                            }}
                                                            InputProps={{
                                                                readOnly: true,
                                                                style: {
                                                                    color: 'grey'
                                                                },
                                                                sx:{height:32, backgroundColor: 'floralwhite', color:modeViewOnly?"grey":"black"}
                                                            }}
                                                            style={{
                                                                backgroundColor: 'floralwhite',
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="100%">対称短絡電流 Iacm(kA):</Typography>
                                                <Controller
                                                    name="symmetricalCurrent"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type="text"
                                                            size="small"
                                                            // label='対称短絡電流 Iacm(kA)'
                                                            onFocus={e => e.target.select()}
                                                            InputLabelProps={{
                                                                style: { color: 'grey' },
                                                            }}
                                                            InputProps={{
                                                                readOnly: true,
                                                                style: {
                                                                    color: 'grey'
                                                                },
                                                                sx:{height:32, backgroundColor: 'floralwhite', color:modeViewOnly?"grey":"black"}
                                                            }}
                                                            style={{
                                                                backgroundColor: 'floralwhite',
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Stack>                  
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                    <Typography variant="body2" width="100%">短絡電流ピーク値 Ipm(kA):</Typography>
                                                    <Controller
                                                        name="peakValue"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                fullWidth
                                                                type="text"
                                                                size="small"
                                                                // label='短絡電流ピーク値 Ipm(kA)'
                                                                onFocus={e => e.target.select()}
                                                                InputLabelProps={{
                                                                    style: { color: 'grey' },
                                                                }}
                                                                InputProps={{
                                                                    readOnly: true,
                                                                    style: {
                                                                        color: 'grey'
                                                                    },
                                                                    sx:{height:32, backgroundColor: 'floralwhite', color:modeViewOnly?"grey":"black"}
                                                                }}
                                                                style={{
                                                                    backgroundColor: 'floralwhite',
                                                                    
                                                                }}
                                                            />
                                                        )}
                                                    />
                                            </Stack>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Stack>
                    </fieldset>
                        <Stack mt={2} mb={1}>
                            <Grid>
                                <Controller
                                    name="percentZCalculate"//12
                                    control={control}
                                    render={({ field }) => (
                                        <>
                                            <RadioGroup {...field} onChange={(e) =>handleDisablePercentZCalculate(e)}>
                                                <FormControlLabel value="1" control={<Radio style={radioStyles} />} label={<Typography variant="body2" color={m_bModePM|| processMode > MS_MODE_CHECKING?'grey':'black'}>寄与インピーダンスを直接入力する</Typography>} 
                                                disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}/>
                                            </RadioGroup>
                                        </>
                                    )}
                                />
                            </Grid>
                        </Stack>
                        <fieldset  style={{border:"1px solid #AAAA"}}>
                            <legend>{<Typography variant="body2">寄与インピーダンス</Typography>}</legend>
                            <Stack spacing={0.3} direction='column'>
                                <Grid container direction="row">
                                    <Grid item xs={7}>
                                        <Stack spacing={0.3}>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="100%">基準容量(kVA):</Typography>
                                                <Controller
                                                    name="capacity1"
                                                    control={control}
                                                    rules={{
                                                        pattern: {
                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                            message: '数字を入力してください。'
                                                        },
                                                        validate: value => value > 0 && !isChangeCapacity1BigerThanCapacity || '0 < 数字 <= 総容量(kVA) を入力してください。',
                                                       
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            // type="text"
                                                            size="small"
                                                            // label="基準容量(kVA)"
                                                            onFocus={e => e.target.select()}
                                                            InputLabelProps={{
                                                                style: {
                                                                    color:!isDisablePercentZCalculate?'black': 'grey'
                                                                }
                                                            }}
                                                            InputProps={{
                                                                // readOnly: true,
                                                                sx:{height:32, backgroundColor:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':!isDisablePercentZCalculate ?'white':'floralwhite', color:modeViewOnly?"grey":!isDisablePercentZCalculate?'black': 'grey'}

                                                            }}
                                                            style={{
                                                                color:"black"
                                                            }}
                                                            onChange={(e) =>{
                                                                field.onChange(e);
                                                                
                                                                handleChangeFormat1(e);doSetValuesMotorGrp2(e,"");}}
                                                            disabled={isDisablePercentZCalculate||m_bModePM|| processMode > MS_MODE_CHECKING}
                                                            error={!!errors?.capacity1}
                                                            helperText={errors?.capacity1?.message}
                                                            inputProps={{maxLength:6, readOnly:modeViewOnly}}

                                                        />
                                                    )}
                                                />
                                            </Stack>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Stack>
                            <Stack spacing={0.3} direction='column' mt={0.1}>
                                <Grid container spacing={0.3} direction="row">
                                    <Grid item xs={7}>
                                        <Stack spacing={0.3}>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="100%">X/R:</Typography>
                                                <Controller
                                                    name="xr"
                                                    control={control}
                                                    rules={{
                                                        required: '必須項目です。入力してください。' ,
                                                        pattern:{
                                                        value: /^[0-9]*[.]?[0-9]+$/,
                                                        message: '数字を入力してください。'
                                                        },
                                                        validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                    //     maxLength: {
                                                    //        value: 8,
                                                    //        message: "最大長さ <= 8 を入力してください。"
                                                    //    }
                                                        }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            // type="text"
                                                            size="small"
                                                            // label="X/R"
                                                            onFocus={e => e.target.select()}
                                                            InputLabelProps={{
                                                                style: {
                                                                    color:!isDisablePercentZCalculate?'black': 'grey'
                                                                }
                                                            }}
                                                            InputProps={{
                                                                // readOnly: true,
                                                                sx:{height:32, backgroundColor:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':!isDisablePercentZCalculate ?'white':'floralwhite', color:modeViewOnly?"grey":!isDisablePercentZCalculate?'black': 'grey'}
                                                            }}
                                                            style={{
                                                                color:'black',
                                                            }}
                                                            onChange={(e) =>{
                                                                field.onChange(e);
                                                                
                                                                handleChangeFormat(e);doSetValuesMotorGrp2(e,"");}}
                                                            disabled={isDisablePercentZCalculate||m_bModePM|| processMode > MS_MODE_CHECKING}
                                                            error={!!errors?.xr}
                                                            helperText={errors?.xr?.message}
                                                            inputProps={{maxLength:8, readOnly:modeViewOnly}}

                                                        />
                                                    )}
                                                />
                                            </Stack>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Stack>
                        <Stack spacing={0.3} direction='column' mt={0.1}>
                            <Grid container spacing={0.3} direction="row">
                                <Grid item xs={7}>
                                    <Stack spacing={0.3}>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="100%">%インピーダンス(%):</Typography>
                                                <Controller
                                                    name="percentZ"
                                                    control={control}
                                                    rules={{
                                                        required: '必須項目です。入力してください。' ,
                                                        pattern:{
                                                        value: /^[0-9]*[.]?[0-9]+$/,
                                                        message: '数字を入力してください。'
                                                        },
                                                        validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                    //     maxLength: {
                                                    //        value: 8,
                                                    //        message: "最大長さ <= 8 を入力してください。"
                                                    //    }
                                                        }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type="text"
                                                            size="small"
                                                            // label="%インピーダンス(%)"
                                                            onFocus={e => e.target.select()}
                                                            InputLabelProps={{
                                                                style: {
                                                                    color:!isDisablePercentZCalculate?'black': 'grey'
                                                                }
                                                            }}
                                                            InputProps={{
                                                                // readOnly: true,
                                                                sx:{height:32, backgroundColor:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':!isDisablePercentZCalculate ?'white':'floralwhite', color:modeViewOnly?"grey":!isDisablePercentZCalculate?'black': 'grey'}
                                                            }}
                                                            style={{
                                                                color:'black',
                                                            }}
                                                            onChange={(e) =>{
                                                                field.onChange(e);
                                                                
                                                                handleChangeFormat(e);doSetValuesMotorGrp2(e,"");}}
                                                            disabled={isDisablePercentZCalculate||m_bModePM|| processMode > MS_MODE_CHECKING}
                                                            error={!!errors?.percentZ}
                                                            helperText={errors?.percentZ?.message}
                                                            inputProps={{maxLength:8, readOnly:modeViewOnly}}

                                                        />
                                                    )}
                                                />
                                            </Stack>
                                    </Stack>
                                </Grid>
                                <Grid item xs={0.5} style={{ margin: 'auto', padding: '0', textAlign: 'center' }}>
                                    <span>=</span>
                                </Grid>
                                <Grid item xs={2}>
                                    <Stack spacing={0.3}>
                                        <Grid>
                                            <Controller
                                                name="percentR"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        onFocus={e => e.target.select()}
                                                        InputLabelProps={{
                                                            style: { color: 'black' },
                                                        }}
                                                        InputProps={{
                                                            readOnly: true,
                                                            style: {
                                                                color: 'grey'
                                                            },
                                                            sx:{height:32, backgroundColor: 'floralwhite', color:modeViewOnly?"grey":"black"}
                                                        }}
                                                        style={{
                                                            backgroundColor: 'floralwhite',
                                                        }}
                                                        
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </Stack>
                                </Grid>
                                <Grid item xs={0.5} style={{ margin: 'auto', padding: '0', textAlign: 'center' }}>
                                    <span>+ j</span>
                                </Grid>
                                <Grid item xs={2}>
                                    <Stack spacing={0.3}>
                                        <Grid>
                                            <Controller
                                                name="percentX"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        onFocus={e => e.target.select()}
                                                        InputLabelProps={{
                                                            style: { color: 'black' },
                                                        }}
                                                        InputProps={{
                                                            readOnly: true,
                                                            style: {
                                                                color: 'grey'
                                                            },
                                                            sx:{height:32, backgroundColor: 'floralwhite', color:modeViewOnly?"grey":"black"}

                                                        }}
                                                        style={{
                                                            backgroundColor: 'floralwhite',
                                                        }}
                                                       
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Stack>
                        <Stack spacing={0.3} direction='column' mt={0.1}>
                            <Grid container spacing={0.3} direction="row">
                                <Grid item xs={7}>
                                    <Stack spacing={0.3}>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="100%">インピーダンス(mΩ):</Typography>
                                                <Controller
                                                    name="ohmZ"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type="text"
                                                            size="small"
                                                            // label="インピーダンス(mΩ)"
                                                            onFocus={e => e.target.select()}
                                                            InputLabelProps={{
                                                                style: { color: 'black' },
                                                            }}
                                                            InputProps={{
                                                                readOnly: true,
                                                                style: {
                                                                    color: 'grey'
                                                                },
                                                                sx:{height:32, backgroundColor: 'floralwhite', color:modeViewOnly?"grey":"black"}
                                                            }}
                                                            style={{
                                                                backgroundColor: 'floralwhite',
                                                            }}
                                                            
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                    </Stack>
                                </Grid>
                                <Grid item xs={0.5} style={{ margin: 'auto', padding: '0', textAlign: 'center' }}>
                                    <span>=</span>
                                </Grid>
                                <Grid item xs={2}>
                                    <Stack spacing={0.3}>
                                        <Grid>
                                            <Controller
                                                name="ohmR"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        onFocus={e => e.target.select()}
                                                        InputLabelProps={{
                                                            style: { color: 'black' },
                                                        }}
                                                        InputProps={{
                                                            readOnly: true,
                                                            style: {
                                                                color: 'grey'
                                                            },
                                                            sx:{height:32, backgroundColor: 'floralwhite', color:modeViewOnly?"grey":"black"}

                                                        }}
                                                        style={{
                                                            backgroundColor: 'floralwhite',
                                                        }}
                                                       
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </Stack>
                                </Grid>
                                <Grid item xs={0.5} style={{ margin: 'auto', padding: '0', textAlign: 'center' }}>
                                    <span>+ j</span>
                                </Grid>
                                <Grid item xs={2}>
                                    <Stack spacing={0.3}>
                                        <Grid>
                                            <Controller
                                                name="ohmX"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        onFocus={e => e.target.select()}
                                                        InputLabelProps={{
                                                            style: { color: 'black' },
                                                        }}
                                                        InputProps={{
                                                            readOnly: true,
                                                            style: {
                                                                color: 'grey'
                                                            },
                                                            sx:{height:32, backgroundColor: 'floralwhite', color:modeViewOnly?"grey":"black"}
                                                        }}
                                                        style={{
                                                            backgroundColor: 'floralwhite',
                                                        }}
                                                       
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Stack>
                    </fieldset>
                    </TabPanel>
            </div>

                    <Stack direction='row' justifyContent="flex-end" spacing={2} mt={2}>
                        <Button variant="contained" type="submit" disabled={isDisableOKBtn || modeViewOnly}>OK</Button>
                        <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                    </Stack>
                </form >
        </>
    );
};
export default MsMotorGroupEditDialog;


