import { Box, Button, Checkbox, FormControl, FormControlLabel, Grid, MenuItem, Radio, RadioGroup, Select, Stack, Tab, Tabs, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { AdjustModel, MsThermalModel } from '../../models/Index';
import { DeviceDetailsDialog } from "../details/DeviceDetailsDialog";
import TextFieldOptions from "../common/TextFieldOptions";
import * as EKind from "../../models/ElementKind";
import { GetDetailsData } from "../../utils/DetailsDataController";
import { convertDetailsDataFromBE } from "../../utils/DataConverter";
import * as Constant from "../../models/Constants";

export type MsThermalEditDialogFormProps = {
    data: any;
    haveSourceVoltage: boolean;
    sourceVoltText: string;
    onOK: (data: any) => void;
    onCancel: () => void;
    setLoadingFlag: (flag: boolean) => void;
    userRole: string;
    m_bModePM:boolean;
    modeViewOnly: boolean;
};

function a11yProps(index: number) {
    return {
        id: `tab-${index}`,
        "aria-controls": `tabpanel-${index}`,
    };
}

//Tabs------------------
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

const checkboxStyle = { padding: '4px 0px 4px 8px' }
const checkboxStyles = { padding: '4px 4px 4px 8px' }


export const MsThermalEditDialog = (props: MsThermalEditDialogFormProps) => {
    const { userRole, data, haveSourceVoltage, sourceVoltText, onOK, onCancel, setLoadingFlag,m_bModePM, modeViewOnly } = props;
    const [selectTab, setSelectTab] = useState(0);
    const [isOpenedDetailMsThermal, setIsOpenedDetailMsThermal] = useState<boolean>(false);

    // 初期値設定
    const [initialValue, setInitialValue] = useState<MsThermalModel>({ ...data });
    const { register, control, handleSubmit, formState: { errors }, setValue, getValues,setError } = useForm<MsThermalModel>({
        mode: 'all',
        criteriaMode: "all",
        defaultValues: initialValue,
    });
    
    const [makeDataInput, setMakeDataInput] = useState(initialValue.makeDataInput); // ModePM
    const [isDisableDetailsBtn, setIsDisableDetailsBtn] = useState(!initialValue.details.base.typeID);

    useEffect(() => {
        setValue("details", data.details)
    }, [data])

    // Submitイベント
    const handleEditSubmit = (value: MsThermalModel) => {
        const request = createRequestData(value);
        onOK(request);
    }

    // FormデータからRequestデータを作成する
    const createRequestData = (formValue: MsThermalModel) => {
        let request = {
            ...formValue,
        } as MsThermalModel;
        return request;
    }

    // キャンセル
    const handleCancel = () => {
        onCancel();
    };

    const handleChangeFormat = (e: any) => {
        setValue(e.target.name, e.target.value);
        if (e.target.name === "details.base.typeID"){
            let typeName = initialValue.details.base.typeList?.find(
                (item) => item.value === e.target.value
            )?.label || ''
            setValue("details.base.typeName", typeName)
        } else if (e.target.name === "details.base.ratedID"){
            let fixedName = initialValue.details.base.ratedList?.find(
                (item) => item.value === e.target.value
            )?.label || ''
            setValue("details.base.fixedName", fixedName)
        }
    }
    
    const handleChangeCheckbox = (e: any) => {
        setValue(e.target.name, e.target.checked);
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        if (newValue >= 0) {
            if (getValues('refNo') == "" ||getValues('refNo') == null || getValues('refNo') == undefined ) {
                setError("refNo",{ type: 'custom', message: '必須項目です。入力してください。' });
            }
            else if (isNaN(Number(getValues('voltage'))) || Number(getValues('voltage')) <= 0 || !/^[0-9\b]+$/.test(getValues('voltage').toString())) {
                setError("voltage",{ type: 'custom', message: '整数 > 0 を入力してください。' });
            }
            else if (getValues('details.refNo2') == "" ||getValues('details.refNo2') == null || getValues('details.refNo2') == undefined ) {
                setError("details.refNo2",{ type: 'custom', message: '必須項目です。入力してください。' });
            }
            else{
                setSelectTab(newValue);
            }
        }
    };

    const handleUpdateDetailData = (data: AdjustModel) => {
        setIsOpenedDetailMsThermal(false);
        setValue("details", data);

        let newDetails = {details: {...data}};
        setInitialValue(initialValue => ({...initialValue, ...newDetails}));
    }

    const handleOpenDetail = () => {
        setIsOpenedDetailMsThermal(true);
    }

    // ModePM
    const handleChangeMakeDataInput = (e: any) => {
        setValue(e.target.name, e.target.checked);
        setMakeDataInput(e.target.checked);
    }

    const handleChangeDetails = (e: any) => {
        setValue(e.target.name, e.target.value);
        DoGetDetailsData(e.target.name);
        
        if (e.target.name === "details.base.typeID"){
            let typeName = initialValue.details.base.typeList?.find(
                (item) => item.value === e.target.value
            )?.label || ''
            setValue("details.base.typeName", typeName)
        }
    }

    const handleChangeRated = (e: any) => {
        setValue(e.target.name, e.target.value);
        const selectedRated = initialValue.details.base.ratedList?.find(rated => rated.value === e.target.value);
        setValue('details.base.ratedID', selectedRated?.itemId);
        setValue('details.base.ratedValue', selectedRated?.itemValue);
        setValue('details.base.fixedName', selectedRated?.label || '');
        
        if ([1, 2].includes(initialValue.details.base.readBase.ratedFlag || -1)){
            DoGetDetailsData('details.base.ratedID');
        }
    }

    const DoGetDetailsData = async (name: string) => {
        let elementType = EKind.MS_THERMAL
        let details = getValues('details')

        setLoadingFlag(true)
        let newDetailsData = await GetDetailsData(elementType, details.base, name)
        if (newDetailsData){
            let convertedData = convertDetailsDataFromBE(newDetailsData)
            
            details.base = {...details.base, ...convertedData.base}
            details.parts = convertedData.parts
            details.delaySettings = convertedData.delaySettings
            setValue('details', details)

            setInitialValue(initialValue => ({...initialValue, details: details}))
            setIsDisableDetailsBtn(!details.base.typeID)
        }
    
        setLoadingFlag(false)
    }

    return (
        <>
            <div style={{ height: '100%', width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={selectTab} onChange={handleChange} aria-label="basic tabs example"
                        variant="scrollable" 
                        scrollButtons 
                        allowScrollButtonsMobile 
                        sx={{
                            '& .MuiButtonBase-root.MuiTabScrollButton-root.Mui-disabled': {
                                display: "none"
                            }
                        }}
                    >
                        <Tab label="基本" {...a11yProps(0)} />
                        <Tab label="保護協調" {...a11yProps(1)} />
                        <Tab label="シンボル" {...a11yProps(2)} />
                    </Tabs>
                </Box>
                <form onSubmit={handleSubmit(handleEditSubmit)}>
                    <div style={{ height: '40vh', width: '100%', margin: 'auto', overflow: "auto" }}>
                        
                        {/* tab0 */}
                        <TabPanel value={selectTab} index={0}>
                            <Grid container mt={1}>
                                <Grid item xs={5.5}>
                                    <Stack spacing={0.3}>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="50%">デバイス名:</Typography>
                                            <Controller
                                                name="refNo"
                                                control={control}
                                                rules={{
                                                    required: '必須項目です。入力してください。',
                                                }}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        // type="text"
                                                        size="small"
                                                        error={!!errors?.refNo}
                                                        helperText={errors?.refNo?.message}
                                                        onChange={(e) =>{field.onChange(e);handleChangeFormat(e)}}
                                                        InputProps={{ sx: { height: 32 ,backgroundColor:m_bModePM || modeViewOnly?'floralwhite':'white', color:modeViewOnly?"grey":"black"} }}
                                                        disabled={m_bModePM}
                                                        inputProps={{readOnly:modeViewOnly}}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="50%">事故点名称:</Typography>
                                            <Controller
                                                name="pointText"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        onChange={handleChangeFormat}
                                                        InputProps={{ sx: { height: 32 ,backgroundColor:m_bModePM || modeViewOnly?'floralwhite':'white', color:modeViewOnly?"grey":"black"} }}
                                                        disabled={m_bModePM}
                                                        inputProps={{readOnly:modeViewOnly}}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="50%">回路電圧(V):</Typography>
                                            <FormControl fullWidth size='small'>
                                                <Controller
                                                    name='voltage'
                                                    control={control}
                                                    rules={{ 
                                                        required: '必須項目です。入力してください。' ,
                                                        pattern:{
                                                           value: /^[0-9\b]+$/,
                                                           message: '数字を入力してください。'
                                                        },
                                                        validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                    }}
                                                    render={({ field }: any) => (
                                                        <TextFieldOptions
                                                            {...field}
                                                            options={initialValue.voltageList}
                                                            // type='text'
                                                            size='small'
                                                            disabled={haveSourceVoltage||m_bModePM || modeViewOnly}
                                                            setValue={(name: any, value: any) => {
                                                                field.onChange({target: {name: name, value: value}});
                                                                handleChangeFormat({target: {name: name, value: value}})
                                                            }}
                                                            height= {32}
                                                            value={initialValue.sPhase ? sourceVoltText : field.value}
                                                            error={!!errors?.voltage}
                                                            helperText={errors?.voltage?.message}
                                                            maxLength={6}
                                                        />
                                                    )}
                                                />
                                            </FormControl>
                                        </Stack>
                                    </Stack>
                                </Grid>
                                <Grid item xs={6.5} pl={2} mt={1}>
                                    <Stack mb={1}>
                                        <Typography variant="body2">(単線結線図で使用)</Typography>
                                    </Stack>
                                    <Stack>
                                        <FormControlLabel
                                            control={
                                                <Controller
                                                    name='isOff'
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Checkbox
                                                            {...field}
                                                            size="small"
                                                            style={checkboxStyles}
                                                            checked={field.value}
                                                            onChange={handleChangeCheckbox}
                                                            disabled={m_bModePM || modeViewOnly}
                                                        />
                                                    )}
                                                />
                                            }
                                            label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>主回路を開放</Typography>}
                                        />
                                    </Stack>
                                    <Stack>
                                        <FormControlLabel
                                            control={
                                                <Controller
                                                    name='fault'
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Checkbox
                                                            {...field}
                                                            size="small"
                                                            style={checkboxStyles}
                                                            checked={field.value}
                                                            onChange={handleChangeCheckbox}
                                                            disabled={m_bModePM || modeViewOnly}

                                                        />
                                                    )}
                                                />
                                            }
                                            label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>短絡電流を計算</Typography>}
                                        />
                                    </Stack>
                                    <Stack>
                                        <FormControlLabel
                                            control={
                                                <Controller
                                                    name='dropMotor'
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Checkbox
                                                            {...field}
                                                            size="small"
                                                            style={checkboxStyles}
                                                            checked={field.value}
                                                            onChange={handleChangeCheckbox}
                                                            disabled={m_bModePM || modeViewOnly}

                                                        />
                                                    )}
                                                />
                                            }
                                            label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>電動機始動時の電圧降下を計算</Typography>}
                                        />
                                    </Stack>
                                    <Stack>
                                        <FormControlLabel
                                            control={
                                                <Controller
                                                    name='dropRegular'
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Checkbox
                                                            {...field}
                                                            size="small"
                                                            style={checkboxStyles}
                                                            checked={field.value}
                                                            onChange={handleChangeCheckbox}
                                                            disabled={m_bModePM || modeViewOnly}

                                                        />
                                                    )}
                                                />
                                            }
                                            label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>定常時の電圧降下を計算</Typography>}
                                        />
                                    </Stack>
                                    {/* Mode SP */}
                                    {(userRole && !(userRole == Constant.ROLE_LP)) &&
                                    <Stack>
                                        <FormControlLabel
                                            control={
                                                <Controller
                                                    name='viewFlowResult'
                                                    control={control}
                                                    disabled={initialValue.sPhase}
                                                    render={({ field }) => (
                                                        <Checkbox
                                                            {...field}
                                                            size="small"
                                                            style={checkboxStyles}
                                                            checked={field.value}
                                                            onChange={handleChangeCheckbox}
                                                            disabled={m_bModePM || modeViewOnly}

                                                        />
                                                    )}
                                                />
                                            }
                                            label={
                                                <Typography 
                                                    variant="body2" color={initialValue.sPhase||m_bModePM ? 'grey' : undefined}
                                                >
                                                    潮流解析結果を表示
                                                </Typography>
                                            }
                                        />
                                    </Stack>
                                    }

                                    <Stack>
                                        <FormControlLabel
                                            control={
                                                <Controller
                                                    name='viewResultText'
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Checkbox
                                                            {...field}
                                                            size="small"
                                                            style={checkboxStyles}
                                                            checked={field.value}
                                                            onChange={handleChangeCheckbox}
                                                            disabled={m_bModePM|| modeViewOnly}
                                                        />
                                                    )}
                                                />
                                            }
                                            label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>注釈を表示</Typography>}
                                        />
                                    </Stack>
                                </Grid>
                            </Grid>

                            {/* ModePM */}
                            {[Constant.ROLE_PM, Constant.ROLE_ADMIN].includes(userRole) &&
                            <>
                                <Stack>
                                    <FormControlLabel
                                        control={
                                            <Controller
                                                name='makeDataInput'
                                                control={control}
                                                render={({ field }) => (
                                                    <Checkbox
                                                        size='small'
                                                        {...field}
                                                        checked={field.value}
                                                        onChange={handleChangeMakeDataInput}
                                                        style={{padding:'4px 4px 4px 9px'}}
                                                        disabled={m_bModePM || modeViewOnly}
                                                    />
                                                )}
                                            />
                                        }
                                        label={<Typography variant="body2"  color={m_bModePM?'grey':'black'}>銘板入力</Typography>}
                                    />
                                </Stack>
                                
                                <Stack>
                                    <fieldset
                                        style={{ 
                                            width: '60%',
                                            color: !makeDataInput ? 'grey' : 'black', 
                                            border:'1px solid #AAAA'
                                        }}
                                    >
                                        <legend><Typography variant="body2"  >銘板</Typography></legend>
                                        <Stack spacing={0.3}>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="35%"  >製造者:</Typography>
                                                <FormControl fullWidth size="small">
                                                    <Controller
                                                        name="details.base.manufacturerID"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                disabled
                                                                IconComponent={() => null}
                                                                style={{backgroundColor: "floralwhite"}}
                                                                sx={{height:32,backgroundColor:'floralwhite'}}
                                                            >
                                                                {initialValue.details.base.manufacturerList?.map(item => (
                                                                    <MenuItem key={item.value} value={item.value}>
                                                                        {item.label}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        )}
                                                    />
                                                </FormControl>
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="35%"  >形式:</Typography>
                                                <FormControl fullWidth size="small">
                                                    <Controller
                                                        name="details.base.typeID"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <TextFieldOptions
                                                                {...field}
                                                                options={initialValue.details.base.typeList}
                                                                // type="number"
                                                                size="small"
                                                                setValue={(name: any, value: any) => {
                                                                    field.onChange({target:{name:name,value:value}});
                                                                    handleChangeDetails({target:{name:name,value:value}})
                                                                }}
                                                                height={32}
                                                                disabled={true}
                                                                style={{backgroundColor: "floralwhite"}}
                                                                value={getValues("details.base.typeName")}
                                                            />
                                                        )}
                                                    />
                                                </FormControl>
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="35%"  >製造年月:</Typography>
                                                <Grid container>
                                                    <Grid item xs={7}>
                                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                            <FormControl fullWidth size='small'>
                                                                <Controller
                                                                    name='makeYear'
                                                                    control={control}
                                                                    render={({ field }: any) => (
                                                                        <TextFieldOptions
                                                                            {...field}
                                                                            options={initialValue.makeYearList}
                                                                            type='string'
                                                                            size='small'
                                                                            setValue={(name: any, value: any) => {
                                                                                handleChangeFormat({target: {name: name, value: value}})
                                                                            }}
                                                                            height= {32}
                                                                            disabled={(m_bModePM || modeViewOnly ||!makeDataInput)}
                                                                            style={{
                                                                                backgroundColor: m_bModePM?'floralwhite':makeDataInput ? 'white' : 'floralwhite',
                                                                            }}
                                                                            maxLength={8}
                                                                        />
                                                                    )}
                                                                />
                                                            </FormControl>
                                                            <Typography variant="body2" pr={1.5} pl={0.5}>年</Typography>
                                                        </Stack>
                                                        
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                            <FormControl fullWidth size='small'>
                                                                <Controller
                                                                    name='makeMonth'
                                                                    control={control}
                                                                    render={({ field }: any) => (
                                                                        <TextFieldOptions
                                                                            {...field}
                                                                            options={initialValue.makeMonthList}
                                                                            type='string'
                                                                            size='small'
                                                                            setValue={(name: any, value: any) => {
                                                                                handleChangeFormat({target: {name: name, value: value}})
                                                                            }}
                                                                            height= {32}
                                                                            disabled={m_bModePM || modeViewOnly ||!makeDataInput}
                                                                            style={{
                                                                                backgroundColor: m_bModePM?'floralwhite':makeDataInput ? 'white' : 'floralwhite',
                                                                            }}
                                                                            maxLength={2}
                                                                        />
                                                                    )}
                                                                />
                                                            </FormControl>
                                                            <Typography variant="body2" pl={0.5}>月</Typography>
                                                        </Stack>
                                                    </Grid>
                                                </Grid>
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="35%"  >製造番号:</Typography>
                                                <Controller
                                                    name='makeNumber'
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type='text'
                                                            size='small'
                                                            onChange={handleChangeFormat}
                                                            inputProps={{
                                                                readOnly: !makeDataInput || modeViewOnly,
                                                                style: { color: makeDataInput ? 'black' : 'grey' },
                                                            }}
                                                            style={{
                                                                backgroundColor: m_bModePM || modeViewOnly?'floralwhite':makeDataInput ? 'white' : 'floralwhite', color:modeViewOnly?"grey":makeDataInput ? 'black' : 'grey'
                                                            }}
                                                            InputProps={{ sx: { height: 32 } }}
                                                            disabled={m_bModePM||!makeDataInput}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                        </Stack>
                                    </fieldset>
                                </Stack>
                            </>
                            }
                        </TabPanel>

                        {/* tab1 */}
                        <TabPanel value={selectTab} index={1}>
                            <Stack my={1}>
                                <Grid container>
                                    <Grid item xs={5}>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="66%">デバイス名:</Typography>
                                            <Controller
                                                name="details.refNo2"
                                                control={control}
                                                rules={{
                                                    required: '必須項目です。入力してください。',
                                                }}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        // type="text"
                                                        size="small"
                                                        onChange={(e) =>{field.onChange(e);handleChangeFormat(e)}}
                                                        error={!!errors?.details?.refNo2}
                                                        helperText={errors?.details?.refNo2?.message}
                                                        InputProps={{ sx: { height: 32 ,backgroundColor:m_bModePM || modeViewOnly?'floralwhite':'white', color:modeViewOnly?"grey":"black"} }}
                                                        disabled={m_bModePM}
                                                        inputProps={{readOnly:modeViewOnly}}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={6} mt={0.5} pl={1}>
                                        <Typography variant="body2">(保護協調図で使用)</Typography>
                                    </Grid>
                                </Grid>
                            </Stack>
                            
                            <fieldset style={{border:"1px solid #AAAA"}}>
                                <legend><Typography variant="body2" >動作特性曲線</Typography></legend>
                                <Stack spacing={0.3}>
                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                        <Typography variant="body2" width="25%">製造者:</Typography>
                                        <FormControl fullWidth size="small">
                                            <Controller
                                                name="details.base.manufacturerID"
                                                control={control}
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        onChange={handleChangeDetails}
                                                        disabled={initialValue.details.base.manufacturerList?.length === 0 || m_bModePM || modeViewOnly}
                                                        style={{
                                                            backgroundColor:
                                                                initialValue.details.base.manufacturerList?.length === 0 || m_bModePM
                                                                    ? "floralwhite"
                                                                    : "white",
                                                        }}
                                                        sx={{height:32}}
                                                    >
                                                        {initialValue.details.base.manufacturerList?.map(item => (
                                                            <MenuItem key={item.value} value={item.value}>
                                                                {item.label}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                )}
                                            />
                                        </FormControl>
                                    </Stack>
                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                        <Typography variant="body2" width="25%">機器種類:</Typography>
                                        <FormControl fullWidth size="small">
                                            <Controller
                                                name="details.base.kindID"
                                                control={control}
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        onChange={handleChangeDetails}
                                                        disabled={initialValue.details.base.kindList?.length === 0 || m_bModePM || modeViewOnly}
                                                        style={{
                                                            backgroundColor:
                                                                initialValue.details.base.kindList?.length === 0 || m_bModePM
                                                                    ? "floralwhite"
                                                                    : "white",
                                                        }}
                                                        sx={{height:32}}
                                                    >
                                                        {initialValue.details.base.kindList?.map(item => (
                                                            <MenuItem key={item.label} value={item.value}>
                                                                {item.label}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                )}
                                            />
                                        </FormControl>
                                    </Stack>
                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                        <Typography variant="body2" width="25%">形式:</Typography>
                                        <FormControl fullWidth size="small">
                                            <Controller
                                                name="details.base.typeID"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextFieldOptions
                                                        {...field}
                                                        options={initialValue.details.base.typeList}
                                                        // type="number"
                                                        size="small"
                                                        setValue={(name: any, value: any) => {
                                                            field.onChange({target:{name:name,value:value}});
                                                            handleChangeDetails({target:{name:name,value:value}})
                                                        }}
                                                        notAllowInput={true}
                                                        height={32}
                                                        disabled={initialValue.details.base.typeList?.length === 0 || m_bModePM|| modeViewOnly}
                                                        style={{backgroundColor:
                                                                initialValue.details.base.typeList?.length === 0 || m_bModePM
                                                                    ? "floralwhite"
                                                                    : "white",}}
                                                        value={getValues("details.base.typeName")}
                                                    />
                                                )}
                                            />
                                        </FormControl>
                                    </Stack>
                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                        <Typography variant="body2" width="25%">
                                            {initialValue.details.base.opeKindTitle &&
                                            (initialValue.details.base.opeKindTitle + ':')}
                                        </Typography>
                                        <FormControl fullWidth size="small">
                                            <Controller
                                                name="details.base.opeKindID"
                                                control={control}
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        error={!!errors?.details?.base?.opeKindID}
                                                        onChange={handleChangeDetails}
                                                        disabled={initialValue.details.base.opeKindList?.length === 0 || m_bModePM || modeViewOnly}
                                                        style={{
                                                            backgroundColor:
                                                                initialValue.details.base.opeKindList?.length === 0 || m_bModePM
                                                                    ? "floralwhite"
                                                                    : "white",
                                                        }}
                                                        sx={{height:32}}
                                                    >
                                                        {initialValue.details.base.opeKindList?.map(item => (
                                                            <MenuItem key={item.label} value={item.value}>
                                                                {item.label}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                )}
                                            />
                                        </FormControl>
                                    </Stack>
                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                        <Typography variant="body2" width="25%">範囲:</Typography>
                                        <FormControl fullWidth size="small">
                                            <Controller
                                                name="details.base.ratedRangeID"
                                                control={control}
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        onChange={handleChangeDetails}
                                                        disabled={initialValue.details.base.ratedRangeList?.length === 0 || m_bModePM || modeViewOnly}
                                                        style={{
                                                            backgroundColor:
                                                                initialValue.details.base.ratedRangeList?.length === 0 || m_bModePM
                                                                    ? "floralwhite"
                                                                    : "white",
                                                        }}
                                                        sx={{height:32}}
                                                    >
                                                        {initialValue.details.base.ratedRangeList?.map(item => (
                                                            <MenuItem key={item.label} value={item.value}>
                                                                {item.label}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                )}
                                            />
                                        </FormControl>
                                    </Stack>
                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                        <Typography variant="body2" width="25%">
                                            {initialValue.details.base.ratedTitle &&
                                            (initialValue.details.base.ratedTitle + ' (A):')}
                                        </Typography>
                                        <FormControl fullWidth size="small">
                                            <Controller
                                                name="details.base.ratedSelectionValue"
                                                control={control}
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        onChange={handleChangeRated}
                                                        disabled={initialValue.details.base.ratedList?.length === 0 || m_bModePM || modeViewOnly}
                                                        style={{
                                                            backgroundColor:
                                                                initialValue.details.base.ratedList?.length === 0 || m_bModePM
                                                                    ? "floralwhite"
                                                                    : "white",
                                                        }}
                                                        sx={{height:32}}
                                                    >
                                                        {initialValue.details.base.ratedList?.map(item => (
                                                            <MenuItem key={item.label} value={item.value}>
                                                                {item.label}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                )}
                                            />
                                        </FormControl>
                                    </Stack>
                                </Stack>
                                
                                <Grid pt={1} justifyContent="flex-end" display='flex'>
                                    <Button variant="outlined" size="medium" onClick={handleOpenDetail} disabled={isDisableDetailsBtn}>詳細...</Button>
                                </Grid>
                            </fieldset>
                        </TabPanel>

                        {/* tab2 */}
                        <TabPanel value={selectTab} index={2}>
                        <Stack mt={1} width={'75%'}>
                            <fieldset style={{border:'1px solid #AAAA'}}>
                                <legend><Typography variant="body2">単線結線図で表示するシンボルの選択</Typography></legend>
                                <Grid container>
                                    <Grid item xs={7}>
                                        <Controller
                                            name='symKind'
                                            control={control}
                                            render={({ field }) => (
                                                <RadioGroup {...field}>
                                                    <FormControlLabel 
                                                        value='0' 
                                                        control={<Radio size='small'/>} 
                                                        label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>メーク接点(a接点)</Typography>}
                                                        disabled={m_bModePM || modeViewOnly}
                                                    />
                                                    <FormControlLabel 
                                                        value='1' 
                                                        control={<Radio size='small'/>} 
                                                        label={<Typography variant="body2"  color={m_bModePM?'grey':'black'}>ブレーク接点(b接点)</Typography>}
                                                        disabled={m_bModePM || modeViewOnly}
                                                    
                                                    />
                                                    
                                                </RadioGroup>
                                            )}
                                        />
                                    </Grid>
                                    
                                </Grid>
                            </fieldset>
                        </Stack>
                        </TabPanel>
                    </div>
                    <Stack direction='row' mt={1} justifyContent="flex-end" spacing={2}>
                        <Button variant="contained" type="submit" disabled={modeViewOnly}>OK</Button>
                        <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                    </Stack>
                </form >
                {isOpenedDetailMsThermal &&
                    <DeviceDetailsDialog 
                        data={getValues("details")} 
                        elementType={EKind.MS_THERMAL}
                        onOK={handleUpdateDetailData} 
                        onCancel={() => setIsOpenedDetailMsThermal(false)}
                        setLoadingFlag={setLoadingFlag}
                        m_bModePM={m_bModePM}
                        modeViewOnly={modeViewOnly}
                    />
                }
            </div>
        </>
    );
};
export default MsThermalEditDialog;


