import { connect } from 'react-redux'
import { actionCreators, ApplicationState } from '../../store'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import { CircularProgress, DialogTitle, Stack } from '@mui/material'
import theme from '../../themes/globalStyles'
import DraggablePaper from '../common/DraggablePaper'
import NoticeList from './NoticeList'
import NoticeCard from './NoticeCard'
import { useEffect, useState } from 'react'
import { post } from '../CallApi'
import { Message, NotificationModel } from '../../models/Index'
import { convertNotificationData } from '../../utils/DataConverter'
import AlertDialog from '../common/AlertDialog'

type NoticeDialogProps = {
  openDialog: boolean;
  setOpenDialog: (value: boolean) => void;
}

type PureNoticeDialogProps = NoticeDialogProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

export const NoticeDialog = (props: PureNoticeDialogProps) => {
  const {
    user,
    notificationList,
    openDialog,
    setOpenDialog,
    onShowMessage,
    setNotificationList,
  } = props

  const [loadingFlag, setLoadingFlag] = useState(false)
  const [cardList, setCardList] = useState<NotificationModel[]>([])
  const [cardIndex, setCardIndex] = useState(-1)

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [deleteMsg, setDeleteMsg] = useState("")
  const [idDelete, setIdDelete] = useState<number|null>(null);

  useEffect(() => {
    initData()
  },[])

  const initData = async () => {
    if (notificationList !== null) {
      setCardList(notificationList)
    } else {
      setLoadingFlag(true)
      const res = await post('/notification/get-notification', {userId: user?.userId})
      if (res.success && res.data.data){
        const lst = convertNotificationData(res.data.data)
        setCardList(lst)
        setNotificationList(lst)
      } else {
        onShowMessage({
          type: 'error',
          title: '通知を受け取る',
          body: res.error.message,
        });
      }
      setLoadingFlag(false)
    }
  }

  const handleSelectCard = async (id: number) => {
    let tmpList = [...cardList]
    let index = tmpList.findIndex((card) => card.notificationId === id)
    setCardIndex(index)

    await handleCheckUnread(cardList, index, id);
  }

  const handleChangeCardIndex = async (isNext: boolean) => {
    const index = isNext ? cardIndex + 1 : cardIndex - 1
    if (index < 0 || index >= cardList.length) return
    setCardIndex(index)
    
    await handleCheckUnread(cardList, index);
  }

  const handleCheckUnread = async (
    cardList: NotificationModel[],
    index: number,
    notificationId?: number
  ) => {
    let tmpList = [...cardList]
    if (tmpList[index].unread === true) {
      setLoadingFlag(true)
      
      if (notificationId === undefined) {
        notificationId = tmpList[index].notificationId
      }
      const param = {userId: user?.userId, notificationId}

      const res = await post('/notification/read-notification', param)
      if (res.success) {
        tmpList[index].unread = false
        setCardList(tmpList)
        setNotificationList(tmpList)
      } else {
        onShowMessage({
          type: 'error',
          title: '通知を読む',
          body: res.error.message,
        });
      }

      setLoadingFlag(false)
    }
  }

  const handleCloseDialog = () => {
    setCardIndex(-1)
    setOpenDialog(false)
  }

  const handleReturn = () => {
    setCardIndex(-1)
  }

  const handleDeleteNotification = (notification: NotificationModel) => {
    setIdDelete(notification.notificationId)
    setDeleteMsg("通知「" + notification.title + "」を削除します。")
    setOpenDeleteDialog(true)
  }

  const handleDeleteOK = async () => {
    if (idDelete !== null) {
      setLoadingFlag(true)
      const param = {
        userId: user?.userId,
        notificationId: idDelete,
      }
      const res = await post('/notification/delete-notification', param)
      if (res.success) {
        setCardIndex(-1)
        const newList = cardList.filter(e => e.notificationId !== idDelete)
        setCardList(newList)
        setNotificationList(newList)
        
        onShowMessage({
          type: 'info',
          title: '通知を削除する',
          body: "通知が削除されました",
        });
      } else {
        onShowMessage({
          type: 'error',
          title: '通知を削除する',
          body: res.error.message,
        });
      }
    } else {
      onShowMessage({
        type: 'error',
        title: '通知を削除する',
        body: 'エラーが発生しました',
      });
    }

    setOpenDeleteDialog(false)
    setIdDelete(null)
    setDeleteMsg("")
    setLoadingFlag(false)
  }

  const handleDeleteCancel = () => {
    setOpenDeleteDialog(false)
    setIdDelete(null)
    setDeleteMsg("")
  }

  return (
    <div>
      {loadingFlag && (
        <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1500, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
          <CircularProgress/>
        </div>
      )}

      {openDeleteDialog && 
        <AlertDialog
          title={"通知を削除する"}
          message={deleteMsg}
          isOpen={true}
          onOK={handleDeleteOK}
          onCancel={handleDeleteCancel}
        />
      }
      
      <Dialog
        open={openDialog}
        maxWidth={'sm'}
        fullWidth
        PaperComponent={DraggablePaper}
      >
        <DialogTitle 
          onMouseOver={(e : any) => e.target.style.cursor = 'move'}
          style={{
            paddingTop: '2px',
            paddingBottom: '2px',
            background: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
          }}
        >
          <Stack width={'100%'} alignItems={'center'}>お知らせ</Stack>
        </DialogTitle>
        <DialogContent>
          {cardIndex < 0
            ? <NoticeList
                cardList={cardList}
                handleSelectCard={handleSelectCard}
                handleDeleteNotification={handleDeleteNotification}
              />
            : <NoticeCard
                cardData={cardList[cardIndex]}
                cardIndex={cardIndex}
                quantity={cardList.length}
                handleChangeCardIndex={handleChangeCardIndex}
                handleDeleteNotification={handleDeleteNotification}
              />
          }
        </DialogContent>
        <DialogActions>
          {cardIndex < 0 ?
            <Button
              onClick={handleCloseDialog}
              onTouchEnd={handleCloseDialog}
              color='inherit'
              variant='contained'
            >
              閉じる
            </Button>
            :
            <Button
              onClick={handleReturn}
              onTouchEnd={handleReturn}
              color='inherit'
              variant='contained'
            >
              戻る
            </Button>
          }
        </DialogActions>
      </Dialog>
    </div>
  )
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.app.user,
  notificationList: state.app.notificationList,
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    onShowMessage: (message: Message) => dispatch(actionCreators.showMessage(message)),
    setNotificationList: (data: NotificationModel[]) => dispatch(actionCreators.setNotificationList(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NoticeDialog)