import { LoadingButton } from "@mui/lab";
import { Box, Button, Checkbox, CircularProgress, FormControlLabel, Grid, Stack, TextField, Typography } from "@mui/material";
import { useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { EquipGeneratorModel } from "../../../models/breakModel";
import { Path as RoutePath } from "../../../path";
import { ApplicationState, actionCreators, selectors } from "../../../store";
import FetchHelper from "../../FetchHelper";
//-----------
//FETCH ID
const GET_EQUIP_GENERATOR = "GET_EQUIP_GENERATOR"
const UPDATE_EQUIP_GENERATOR = "UPDATE_EQUIP_GENERATOR"
const CREATE_EQUIP_GENERATOR = "CREATE_EQUIP_GENERATOR"

const initialValue:EquipGeneratorModel = {
    lGeneratorId:0,
    bIsDefault:false,
    dConstant:0,
    dReactance:0,
    dSubConstant:0,
    dSubReactance:0,
    lCapacity:0,
}
// -------------
// Props
export type EquipGeneratorEditProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
export const EquipGeneratorEdit = (props: EquipGeneratorEditProps) => {
    const {
        userId,
        onCreateError,
        onUpdate, onUpdateError,
        onSuccess, onError,
        onCreate
    } = props;

    const { control, formState: { errors }, handleSubmit, setValue, getValues, } = useForm<EquipGeneratorModel>({
        criteriaMode: "all",
        mode:"all",
        defaultValues: initialValue,
    })
    type MyParams = {
        id: string;
        mode: string;
    };
    let { id, mode } = useParams<keyof MyParams>() as MyParams;
    let navigate = useNavigate();
    const [loadingFlag, setLoadingFlag] = useState(mode === "edit" ? true : false);
    const gridRef = useRef() as any;
    const Exp = /^[-+]?\d+(\.\d+)?$/;
    const messageIsNotNumber = "数字を入力してください。";

    const handleSuccess = (data: any) => {
        setLoadingFlag(false);
        if(data && data.resultCode == 0){
            setValue("bIsDefault",data.data.bIsDefault);
            setValue("dConstant",data.data.dConstant?? "");
            setValue("dReactance",data.data.dReactance?? "");
            setValue("dSubConstant",data.data.dSubConstant?? "");
            setValue("dSubReactance",data.data.dSubReactance?? "");
            setValue("lCapacity",data.data.lCapacity?? "");  
        }
    }

    // 検索エラー時
    const handleError = (success: boolean, data: any, error: any) => {
        setLoadingFlag(false);
        console.log('>>>> handle Error');
        gridRef.current.hideOverlay();
    };

    // 検索実行
    const handleEditSubmit = (data: EquipGeneratorModel) => {
        setLoadingFlag(true);
        if(mode === "edit") {
            let request = {
                lGeneratorId:id,
                bIsDefault:data.bIsDefault,
                dConstant:data.dConstant,
                dReactance:data.dReactance,
                dSubConstant:data.dSubConstant,
                dSubReactance:data.dSubReactance,
                lCapacity:data.lCapacity,
            } as any
            const object = {
                requestPrms:request,
                userId: userId,
            }
            onUpdate(object);
        } else if (mode === "add") {
            let request = {
                bIsDefault:data.bIsDefault,
                dConstant:data.dConstant,
                dReactance:data.dReactance,
                dSubConstant:data.dSubConstant,
                dSubReactance:data.dSubReactance,
                lCapacity:data.lCapacity,
            } as any
            const object = {
                requestPrms:request,
                userId: userId,
            }
            onCreate(object);
        }
    }

    // キャンセル
    const handleCancel = () => {
        navigate(RoutePath.EquipGeneratorList);
    }

    // Submit後のAPI呼び出しエラー時
    const handleErrorUpdate = (success: boolean, data: any, error: any) => {
        onUpdateError();
        setLoadingFlag(false);
    }

    // Submit後のAPI呼び出し成功時
    const handleSuccessSubmit = (success: boolean, data: any, error: any) => {
        onSuccess();
        if (mode === "add") {
            navigate(RoutePath.EquipGeneratorEdit + `/edit/${data.data.lGeneratorId}`, { replace: true })
        }
        setLoadingFlag(false)
    }

    // Submit後のAPI呼び出しエラー時
    const handleSubmitError = (success: boolean, data: any, error: any) => {
        onCreateError();
        setLoadingFlag(false)
    }

    //入力欄背景
    const divStyles = {
        backgroundColor: 'white',
        border: '1px solid #bebebe',
        boxShadow: '0 0 5px #fff, 0 0 5px #bebebe, 0 0 1px #aaa',
        padding: '20px 40px',
        marginTop: '40px',
    }

    return (
        <>
            {
                mode === "edit" &&
                <FetchHelper
                fetchId={GET_EQUIP_GENERATOR}
                url={`/master/equip_generator/get`}
                method="POST"
                json={true}
                request={true}
                params={{
                    requestPrms:{
                        id:id
                    },
                    userId: userId,
                }}
                onComplete={(success, data, error) => {
                    success ? handleSuccess(data) : handleError(success, data, error);
                }}
            />
            }
            {loadingFlag && (
                <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1500, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                    <CircularProgress />
                </div>
            )}
            <div style={{ height: "100%", width: "100%", marginTop: "10px" }}>
                <Stack direction="row" justifyContent="flex-start" alignItems="center" sx={{}} style={{ margin: '18px', color: 'MenuText' }}>
                    <Typography variant="h4">
                        {/* FIXME: */}
                        発電機詳細
                    </Typography>
                </Stack>
                <form onSubmit={handleSubmit(handleEditSubmit)}>
                    <div style={divStyles}>
                        <Typography variant="h6">
                            {/* FIXME: */}
                            発電機
                        </Typography>
                        <Stack mt={2} direction={{ xs: 'column', sm: 'row' }} sx={{maxHeight: "calc(100vh)", overflow: 'auto'}}>
                            <Grid width={"100%"} spacing={2}>
                                <Grid pt={1} spacing={2} container direction={"row"}>
                                    <Grid item xs={4}>
                                        <Controller
                                            control={control}
                                            name="dConstant"
                                            rules={{
                                                pattern: {
                                                    value: Exp,
                                                    message: messageIsNotNumber,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.dConstant}
                                                    helperText={errors?.dConstant?.message}
                                                    label="Time constant Tgdc（s）"
                                                    type="text"
                                                    size="small"

                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Controller
                                            control={control}
                                            name="dReactance"
                                            rules={{
                                                pattern: {
                                                    value: Exp,
                                                    message: messageIsNotNumber,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.dReactance}
                                                    helperText={errors?.dReactance?.message}
                                                    label="Transient reactance X'g（%）"
                                                    type="text"
                                                    size="small"

                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Controller
                                            control={control}
                                            name="dSubConstant"
                                            rules={{
                                                pattern: {
                                                    value: Exp,
                                                    message: messageIsNotNumber,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.dSubConstant}
                                                    helperText={errors?.dSubConstant?.message}
                                                    label="Sub-transient time constant T''g（s）"
                                                    type="text"
                                                    size="small"

                                                />
                                            )}
                                        />
                                    </Grid>                                
                                </Grid>
                                <Grid mt={0} spacing={2} container direction={"row"}>
                                    <Grid item xs={4}>
                                        <Controller
                                            control={control}
                                            name="dSubReactance"
                                            rules={{
                                                pattern: {
                                                    value: Exp,
                                                    message: messageIsNotNumber,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.dSubReactance}
                                                    helperText={errors?.dSubReactance?.message}
                                                    label="Sub-Transient reactance X''g（%）"
                                                    type="text"
                                                    size="small"

                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Controller
                                            control={control}
                                            name="lCapacity"
                                            rules={{
                                                pattern: {
                                                    value: Exp,
                                                    message: messageIsNotNumber,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.lCapacity}
                                                    helperText={errors?.lCapacity?.message}
                                                    label="容量（kVA）"
                                                    type="text"
                                                    size="small"


                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Controller
                                            name="bIsDefault"
                                            control={control}
                                            render={({ field }: any) => (
                                                <FormControlLabel {...field} control={<Checkbox />} checked={field.value} label="デフォルトの容量値" labelPlacement="start" />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Stack>
                    </div>
                    <Stack direction='row' justifyContent="flex-end" spacing={2} sx={{ mt: 1, mr: 2 }}>
                        <LoadingButton variant="contained" type="submit" loading={loadingFlag}>保存</LoadingButton>
                        <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                    </Stack>
                </form>
            </div>
            <FetchHelper fetchId={UPDATE_EQUIP_GENERATOR}
                onComplete={(success: boolean, data: any, error: any) =>
                    success ? handleSuccessSubmit(success, data, error) : handleErrorUpdate(success, data, error)} />
            <FetchHelper fetchId={CREATE_EQUIP_GENERATOR}
                onComplete={(success, data, error) => success ?
                    handleSuccessSubmit(success, data, error) :
                    handleSubmitError(success, data, error)} />
        </>
    );
}
const mapStateToProps = (state: ApplicationState) => (
    {
        userId: state.app.user?.userId,
        fetchUpdate: selectors.getFormState(
            state,
            // GET_BREAK_CAPACITY
            UPDATE_EQUIP_GENERATOR
        ),
    }
)
const mapDispatchToProps = (dispatch: any) => {
    return {
        onCreate: (data: any) => dispatch(actionCreators.fetch(CREATE_EQUIP_GENERATOR, `/master/equip_generator/add`, "POST", data, false, true)),
        onUpdate: (data: any) => dispatch(actionCreators.fetch(UPDATE_EQUIP_GENERATOR, `/master/equip_generator/modify`, "POST", data,false,true)),
        onSuccess: () =>
            dispatch(actionCreators.showMessage({
                type: "info",
                title: "発電機更新",
                body: "発電機を更新しました。",
            })),
        onError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "検索",
                body: "検索できません。",
            })),
        onCreateError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "発電機登録",
                body: "発電機を登録出来ませんでした。",
            })),
        onUpdateError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "発電機編集",
                body: "発電機を更新出来ませんでした。",
            })),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(EquipGeneratorEdit as any);