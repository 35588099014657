import AddIcon from '@mui/icons-material/Add';
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import { Autocomplete, Breakpoint, Button, CircularProgress, Collapse, Dialog, DialogContent, DialogTitle, FormControl, Grid, Stack, TextField, Typography } from "@mui/material";
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from 'react-hook-form';
import { connect } from "react-redux";
import useResponsive from '../../../hooks/useResponsive';
import { accountStatusOptions, packageOptions, roleOptions } from '../../../statics';
import { ApplicationState } from "../../../store";
import { actionCreators } from "../../../store/AppStore";
import FetchHelper from "../../FetchHelper";
import AlertDialog from "../../common/AlertDialog";
import UsersAdd from "./UserAdd";
import UserEdit from "./UserEdit";
import UsersGrid from "./UserGrid";

// FETCH ID
const GET_USER_LIST = "GET_USER_LIST";
const INSERT_USER = "INSERT_USER";
const UPDATE_USER = "UPDATE_USER";
const DELETE_USER = "DELETE_USER";
const RESET_PASS_USER = "RESET_PASS_USER";
const strInsert = "insert";
const strUpdate = "update";
const strDelete = "delete";

interface SearchItemForm{
    custom_management_no:string;
    custom_company_name:string;
    custom_role:string;
    custom_plan:string;
    account_status: string;
    custom_expiration_start_date: string;
    custom_expiration_end_date: string;
}

// 初期値設定
const initialValue: SearchItemForm = {
    custom_management_no:"",
    custom_company_name:"",
    custom_role:"",
    custom_plan:"",
    account_status:"",
    custom_expiration_start_date:"",
    custom_expiration_end_date:"",
};

// Props
export type UsersListProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
    
export const UsersList = (props: UsersListProps) => {
    // #region props
    const { userId } = props;
    const { 
        onGet, onError,
        onInsertUsers, onCreateSuccess, onCreateError,
        onUpdateUsers, onUpdateSuccess, onUpdateError,
        onDeleteUsers, onDeleteSuccess, onDeleteError,
        onResetPassWordUser, onResetSuccess, onResetError
    } = props;

    const [openSearch, setOpenSearch] = useState(false);
    const [listData, setListData] = useState<any>([]);
    const [loadingFlag, setLoadingFlag] = useState(false);
    const [disableEdit, setDisableEdit] = useState(true);
    const [disableDelete, setDisableDelete] = useState(true);
    const [keyword, setKeyword] = useState("");
    const [colState, setColState] = useState<any>();
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [messageDelete, setMessageDelete] = useState("");
    const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
    const [selectData, setSelectData] = useState<any>();
    const [startDateSelect,setStartDateSelect] = useState<any>();
    const [endDateSelect,setEndDateSelect] = useState<any>();
    const [totalUser,setTotalUser] = useState<any>(0);
    const [totalActiveUser,setTotalActiveUser] = useState<any>(0);
    const [totalInactiveUser,setTotalInactiveUser] = useState<any>(0);
    const [totalActiveCustomers,setTotalActiveCustomers] = useState<any>(0);
    const [totalTrialUser,setTotalTrialUser] = useState<any>(0);
    const [exportCsvFlag,setExportCsvFlag] = useState<number>(0);

    const show2line = useResponsive({query: "between",key:"lg" as Breakpoint,start:300,end:1600});
    const { handleSubmit, control, setValue, getValues } = useForm<SearchItemForm>({
        mode: 'all',
        criteriaMode: "all",
        defaultValues: initialValue,
    });
    const gridRef = useRef() as any;
    // #endregion props

    // #region useEffect
    useEffect(() => {
        handleSearchSubmit(getValues());
    },[])
    // #endregion useEffect
    // #region handleMethod
    // 追加の処理
    const handleAdd = () => {
        setOpenAddDialog(true);
    }

    const handleInsert = (data:any) => {
        setLoadingFlag(true);
        onInsertUsers(data);
    }
    
    const handleUpdateOK = (data:any) => {
        setLoadingFlag(true);
        onUpdateUsers(data);
    }

    // 編集の処理
    const handleOpenUpdateDialog = () => {
        // TODO: INITIAL DATA FOR UPDATE
        const customerSelect = listData.filter((item:any) => item.isSelect === true)[0]
        setSelectData(customerSelect);
        setOpenUpdateDialog(true);
    }

    //削除の処理
    const handleDelete = () => {
        let selectedData = listData.filter((item:any) => item.isSelect === true);
        if (selectedData.length === 0) {
            return;
        }
        let userName = selectedData.map((obj: any) => obj.username).join(', ');
        const msg = "ユーザー：" + userName + " を削除しますか？";
        setMessageDelete(msg);
        setOpenDeleteDialog(true);
    }

    const handleDeleteOK = () => {
        setLoadingFlag(true);
        const params:any = []
        listData.filter((item:any) => item.isSelect === true).forEach((item:any) => {
            params.push({ cognito_username: item.username })
        })
        onDeleteUsers(params);
    }

    const handleChangeKeyword = (value: string) => {
        setKeyword(value);
    }

    // 検索正常終了時
    const handleSelect = (data: any) => {
        if (gridRef && gridRef.current) {
            setColState(gridRef.current.saveState());
        }
        let list = listData.filter((item:any) => item.username === data.username)
        list[0].isSelect = !list[0].isSelect;
        if (gridRef && gridRef.current) {
            gridRef.current.setSelectRowData(data.username);
        }

        let selectCount = listData.filter((item:any) => item.isSelect === true).length;

        if (selectCount === 1) {
            setDisableEdit(false);
            setDisableDelete(false);
        } else if (selectCount > 0) {
            setDisableEdit(true);
            setDisableDelete(false);
        }
        else {
            setDisableEdit(true);
            setDisableDelete(true);
        }
    };    

    // 検索正常終了時
    const handleSuccess = (data: any, type: string) => {
        console.log('>>> handleSuccess');

        if (gridRef && gridRef.current) {
            setColState(gridRef.current.saveState());
        }
        if (data && data.result_code == 0) {
            switch (type) {
                case strInsert:
                    setOpenAddDialog(false);
                    onCreateSuccess();
                    break;
                case strUpdate:
                    setOpenUpdateDialog(false);
                    onUpdateSuccess();
                    break;
                case strDelete:
                    setOpenDeleteDialog(false);
                    onDeleteSuccess();
                    break;
                default:
                    break;
            }
            handleSearchSubmit(getValues());
        }
        else {
            switch (type) {
                case strInsert:
                    onCreateError();
                    break;
                case strUpdate:
                    onUpdateError();
                    break;
                case strDelete:
                    setOpenDeleteDialog(false);
                    onDeleteError();
                    break;
                default:
                    break;
            }
            setLoadingFlag(false);
        }
    };

    const handleError = (type:string) => {
        console.log('>>> handleError');

        if (gridRef && gridRef.current) {
            setColState(gridRef.current.saveState());
        }
        switch(type){
            case strInsert:
                onCreateError();
                break;
            case strUpdate:
                onUpdateError();
                break;
            case strDelete:
                setOpenDeleteDialog(false);
                onDeleteError();
                break;
            default:
                break;
        }
        setLoadingFlag(false);
    }
    
    // 検索正常終了時
    const handleSuccessGetList = (data:any) => {
        setLoadingFlag(false);
        if (data && data.result_code == 0) {
            setDisableEdit(true);
            setDisableDelete(true);
            if (gridRef && gridRef.current) {
                gridRef.current.setRowData(handleGetData(data.data))
            }
            setListData(() => [...handleGetData(data.data)]);
        } else {
            if (gridRef && gridRef.current) {
                gridRef.current.setRowData([]);
            }
            setListData(() => []);
        }
    }

    const handleGetData = (data: any) => {
        const result = [] as any[];
        data.user_records?.forEach((element: any) => {
            let userItem = {} as any
            userItem.username = element.Username;
            userItem.custom_management_no = "";
            userItem.custom_company_name = "";
            userItem.custom_zip_code = "";
            userItem.address = "";
            userItem.family_name = "";
            userItem.given_name = "";
            userItem.custom_role = "";
            userItem.email = "";
            userItem.phone_number = "";
            userItem.custom_expiration_date = "";
            userItem.custom_memo = "";
            userItem.custom_mfa = "";
            userItem.custom_mfa_system = "";
            userItem.custom_plan = "";
            userItem.custom_serial_no = "";
            userItem.isSelect = false;
            userItem.account_status = element.Enabled;
            setTotalUser(data["total_user_count"])
            setTotalActiveUser(data["enable_user_count"]);
            setTotalInactiveUser(data["disable_user_count"]);
            setTotalActiveCustomers(data["customer_active_count"]);
            setTotalTrialUser(data["trial_user_count"]);
            
            element.Attributes.forEach((item: any) => {
                if (item.Name === "custom:management_no") {
                    userItem.custom_management_no = item.Value;
                }
                if (item.Name === "custom:company_name") {
                    userItem.custom_company_name = item.Value;
                }
                if (item.Name === "custom:zip_code") {
                    userItem.custom_zip_code = item.Value;
                }
                if (item.Name === "address") {
                    userItem.address = item.Value;
                }
                if (item.Name === "family_name") {
                    userItem.family_name = item.Value;
                }
                if (item.Name === "given_name") {
                    userItem.given_name = item.Value;
                }
                if (item.Name === "custom:role") {
                    userItem.custom_role = item.Value;
                }
                if (item.Name === "email") {
                    userItem.email = item.Value;
                }
                if (item.Name === "phone_number") {
                    userItem.phone_number = item.Value;
                }
                if (item.Name === "custom:expiration_date") {
                    userItem.custom_expiration_date = item.Value;
                }
                if (item.Name === "custom:memo") {
                    userItem.custom_memo = item.Value;
                }
                if (item.Name === "custom:mfa") {
                    if(item.Value.toLowerCase() == "true"){
                        userItem.custom_mfa = "true";
                    }else{
                        userItem.custom_mfa = "false";
                    }
                }
                if (item.Name === "custom:mfa_system") {
                    userItem.custom_mfa_system = item.Value;
                }
                if (item.Name === "custom:plan") {
                    userItem.custom_plan = item.Value;
                }
                if (item.Name === "custom:serial_no") {
                    userItem.custom_serial_no = item.Value;
                }
            })
            result.push(userItem);
        });
        return result;
    }

    // 検索エラー時
    const handleErrorGetList = (success: boolean, data: any, error: any) => {
        setLoadingFlag(false);
        gridRef.current.hideOverlay();
        onError(success, data, error);
    }

    const resetPassword = (data:any) => {
        setLoadingFlag(true);
        onResetPassWordUser(data);
    }

    const handleSucessReset = (data:any) => {
        setLoadingFlag(false);
        if(data && data.result_code == 0){
            onResetSuccess();
        }else{
            onResetError();
        }
    }

    const handleErrorReset = (success:any, data:any, error:any) => {
        setLoadingFlag(false);
        onResetError();
    }

    // 検索実行
    const handleSearchSubmit = (value: any) => {

        if (gridRef && gridRef.current) {
            setColState(gridRef.current.saveState());
        }

        const params:any = {
            default:true,
            custom_management_no:"",
            custom_company_name:"",
            custom_role:"",
            custom_plan:"",
            account_status:"",
            custom_expiration_start_date:"",
            custom_expiration_end_date:"",
        };

        if(value.account_status) {
            params["default"] = false;
            params["account_status"] = value.account_status;
        }

        if(value.custom_company_name) {
            params["default"] = false;
            params["custom_company_name"] = value.custom_company_name;
        }

        if(value.custom_expiration_end_date) {
            params["default"] = false;
            params["custom_expiration_end_date"] = value.custom_expiration_end_date.replaceAll("/","");
        }

        if(value.custom_expiration_start_date) {
            params["default"] = false;
            params["custom_expiration_start_date"] = value.custom_expiration_start_date.replaceAll("/","");
        }

        if(value.custom_plan) {
            params["default"] = false;
            params["custom_plan"] = value.custom_plan;
        }

        if(value.custom_role) {
            params["default"] = false;
            params["custom_role"] = value.custom_role;
        }

        if(value.custom_management_no) {
            params["default"] = false;
            params["custom_management_no"] = value.custom_management_no;
        }

        setLoadingFlag(true);
        onGet(params);
    }

    const handleClearSeachCondition = () => {
        setValue("custom_management_no","");
        setValue("custom_company_name","");
        setValue("custom_role", "");
        setValue("custom_plan", "");
        setValue("account_status", "");
        setStartDateSelect(null);
        setEndDateSelect(null);
        setValue("custom_expiration_start_date","");
        setValue("custom_expiration_end_date", "");
    }

    const handleSearch = () => {
        handleSearchSubmit(getValues());
    }

    const handleExportCSV = () => {
        setExportCsvFlag(exportCsvFlag + 1);
    }
    //  #endregion handleMethod

    return (<>
        {loadingFlag && (
            <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1500, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                <CircularProgress />
            </div>
        )}

        {openAddDialog &&
            <Dialog open={openAddDialog} fullWidth maxWidth="sm" style={{ zIndex: 1200 }}>
                <DialogTitle style={{fontWeight:"bold"}}>{"ユーザー追加"}</DialogTitle>
                <DialogContent sx={{ pb: 1.5,pr:1.5 }}>
                    <UsersAdd
                        onOK={handleInsert}
                        onCancel={() => setOpenAddDialog(false)}
                    />
                </DialogContent>
            </Dialog>
        }

        {openDeleteDialog &&
            <AlertDialog 
                title={"ユーザー削除"} 
                message={messageDelete} 
                isOpen={true} 
                onOK={handleDeleteOK} 
                onCancel={() => setOpenDeleteDialog(false)} 
            />
        }
        {
            openUpdateDialog &&
            <Dialog open={openUpdateDialog} fullWidth style={{ zIndex: 1200}}>
                <DialogContent sx={{ pb: 1.5, pl:1.7, mr:0, ml:1 }}>
                    <UserEdit
                        onOK={handleUpdateOK}
                        onCancel={() => setOpenUpdateDialog(false)}
                        onResetPassword={resetPassword}
                        user={selectData}
                    />
                </DialogContent>
            </Dialog>
        }
        <div style={{ height: '100%', width: '100%', marginTop: '10px' }}>
            <Stack direction="row" justifyContent="flex-start" alignItems="center" sx={{}} style={{ margin: '18px', color: 'MenuText' }}>
                <Typography variant="h4">
                    ユーザー
                </Typography>
            </Stack>
            <form onSubmit={handleSubmit(handleSearchSubmit)}>
                <Stack spacing={1} style={{ border: openSearch ? "2px solid gray" : '', borderRadius: "15px" }}>
                    <div style={{ cursor: 'pointer', display: 'inline-block', marginLeft: '10px'}} onClick={() => {setOpenSearch((prev:boolean) => !prev)}}>
                        <div style={{ display: 'inline-block' }}>
                            <Typography variant="h6" >検索条件</Typography>
                        </div>
                        <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                            {
                                openSearch ? <ArrowDropDownIcon /> : <ArrowRightIcon />
                            }
                        </div>
                    </div>
                    <div style={{ marginLeft: "10px", marginRight: "10px", marginBottom: "10px" }}>
                        <Collapse in={openSearch} timeout="auto" unmountOnExit>
                            <Grid
                                container
                                justifyContent="flex-start"
                                alignItems={"end"}
                                spacing={1}
                                direction="row"
                            >
                                <Grid item xs={2}>
                                    <FormControl fullWidth>
                                    <Controller
                                        control={control}
                                        name="custom_management_no"
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                type="text"
                                                label="管理No"
                                                size="small"
                                                InputProps={{ sx: { borderRadius: "8px" } }}
                                            />
                                        )}
                                    />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={2}>
                                    <Controller
                                        control={control}
                                        name="custom_company_name"
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                type="text"
                                                label="会社名"
                                                size="small"
                                                InputProps={{ sx: { borderRadius: "8px" } }}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                justifyContent="flex-start"
                                alignItems={"end"}
                                spacing={1}
                                direction="row"
                                mt={0}
                            >
                                <Grid item xs={2}>
                                    <Controller
                                        control={control}
                                        name="custom_role"
                                        render={({ field: { value } }:any) => (
                                            <Autocomplete
                                                autoHighlight
                                                value={roleOptions.find(options => options.value == value)??null}
                                                options={roleOptions}
                                                getOptionLabel={(option) => option.label}
                                                fullWidth
                                                renderInput={(params) => <TextField {...params} label="権限" size="small" />}
                                                onChange={(_, data) => {
                                                    if(data){
                                                        setValue("custom_role", data.value)
                                                    }
                                                    else{
                                                        setValue("custom_role", "")
                                                    }
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <Controller
                                        control={control}
                                        name="custom_plan"
                                        render={({ field: { value } }:any) => (
                                            <Autocomplete
                                                autoHighlight
                                                value={packageOptions.find(options => options.value == value)??null}
                                                options={packageOptions}
                                                getOptionLabel={(option) => option.label}
                                                fullWidth
                                                renderInput={(params) => <TextField {...params} label="パック" size="small" />}
                                                onChange={(_, data) => {
                                                    if(data){
                                                        setValue("custom_plan", data.value)
                                                    }
                                                    else{
                                                        setValue("custom_plan", "")
                                                    }
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <Controller
                                        control={control}
                                        name="account_status"
                                        render={({ field: { value } }:any) => (
                                            <Autocomplete
                                                autoHighlight
                                                value={accountStatusOptions.find(options => options.value == value)??null}
                                                options={accountStatusOptions}
                                                getOptionLabel={(option) => option.label}
                                                fullWidth
                                                renderInput={(params) => <TextField {...params} label="ステータス" size="small" />}
                                                onChange={(_, data) => {
                                                    if(data){
                                                        setValue("account_status", data.value)
                                                    }
                                                    else{
                                                        setValue("account_status", "")
                                                    }
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>                   
                            </Grid>
                            <Grid
                                container
                                justifyContent="flex-start"
                                alignItems={"end"}
                                spacing={1}
                                direction="row"
                                mt="3px"
                            >
                                <Grid item xs={4} minWidth="29.5rem">
                                    <Stack spacing={1} direction="row">
                                        <Controller
                                            control={control}
                                            name="custom_expiration_start_date"
                                            render={({ field: { value } }) => (
                                                <>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DateTimePicker
                                                            format="YYYY/MM/DD"
                                                            views={['year', 'month', 'day']}
                                                            openTo="day"
                                                            label="有効期間From"
                                                            value={startDateSelect}
                                                            onChange={(newValue: any) => {
                                                                if (newValue != null) {
                                                                    if (newValue.$d.toString() === "Invalid Date") {
                                                                        setValue("custom_expiration_start_date", "")
                                                                    } else {
                                                                        const month = Number(newValue.$M) + 1;
                                                                        const date = newValue.$y + "/" + (month < 10 ? "0" + month.toString() : month.toString()) + "/" + (Number(newValue.$D) < 10 ? "0" + newValue.$D.toString() : newValue.$D.toString())
                                                                        setStartDateSelect(dayjs(newValue));
                                                                        setValue("custom_expiration_start_date", date)
                                                                    }
                                                                }
                                                            }}
                                                            slotProps={{ actionBar: { actions: [] }, textField: { size: 'small', sx: { backgroundColor: "white" } } }}
                                                        />
                                                    </LocalizationProvider>
                                                </>
                                            )}
                                        />
                                        <Stack justifyContent="center" alignItems="center">
                                            <div>～</div>
                                        </Stack>
                                        <Controller
                                            control={control}
                                            name="custom_expiration_end_date"
                                            rules={{ required: '必須項目です。選択してください。' }}
                                            render={({ field: { value } }) => (
                                                <>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DateTimePicker
                                                            format="YYYY/MM/DD"
                                                            views={['year', 'month', 'day']}
                                                            openTo="day"
                                                            label="有効期間To"
                                                            value={endDateSelect}
                                                            onChange={(newValue: any) => {
                                                                if (newValue != null) {
                                                                    if (newValue.$d.toString() === "Invalid Date") {                                                                        
                                                                        setValue("custom_expiration_end_date", "")
                                                                    } else {
                                                                        const month = Number(newValue.$M) + 1;
                                                                        const date = newValue.$y + "/" + (month < 10 ? "0" + month.toString() : month.toString()) + "/" + (Number(newValue.$D) < 10 ? "0" + newValue.$D.toString() : newValue.$D.toString())
                                                                        setEndDateSelect(dayjs(newValue));
                                                                        setValue("custom_expiration_end_date", date);
                                                                    }
                                                                }
                                                            }}
                                                            slotProps={{ actionBar: { actions: [] }, textField: { size: 'small', sx: { backgroundColor: "white" }} }}
                                                        />
                                                    </LocalizationProvider>
                                                </>
                                            )}
                                        />
                                    </Stack>
                                </Grid>
                                <Grid item>
                                    <Button variant="outlined" onClick={handleClearSeachCondition}>クリア</Button>
                                </Grid>
                                <Grid item>
                                    <Button variant="contained" onClick={handleSearch}>検索</Button>
                                </Grid>
                            </Grid>
                        </Collapse>
                    </div>
                </Stack>
            </form>
            {
                show2line &&
                <div style={{ marginInlineEnd: 'auto' }}>
                    <Typography marginTop={"10px"}>{`総ユーザー数：${totalUser}名、有効：${totalActiveUser}名、無効：${totalInactiveUser}名、有効なお客様：${totalActiveCustomers}名、体験版利用者：${totalTrialUser}名`}</Typography>
                </div>
            }
            <div style={{ marginBottom: "10px",paddingTop:"5px",marginTop:`${openSearch?10:0}px`}}>
                <Stack direction="row">
                    {
                        !show2line && 
                        <div style={{ marginInlineEnd: 'auto' }}>
                            <Typography marginTop={"10px"}>{`総ユーザー数：${totalUser}名、有効：${totalActiveUser}名、無効：${totalInactiveUser}名、有効なお客様：${totalActiveCustomers}名、体験版利用者：${totalTrialUser}名`}</Typography>
                        </div>
                    }
                    <div style={{ marginInlineStart: 'auto' }}>
                        <Grid container justifyContent="flex-start" alignItems={"center"} direction="row" spacing={1} >
                            <Grid item>
                                <TextField
                                    fullWidth
                                    autoComplete="searchStr"
                                    name="keyword"
                                    type="text"
                                    label="フィルター"
                                    size="small"
                                    value={keyword}
                                    InputProps={{ sx: { borderRadius: "8px" } }}
                                    onChange={e => handleChangeKeyword(e.target.value)}
                                />
                            </Grid>
                            <Grid item>
                                <Button variant="outlined" onClick={e => handleChangeKeyword("")}>クリア</Button>
                            </Grid>
                        </Grid>
                    </div>
                    <Stack direction={{ xs: 'column', sm: 'row' }} ml={2} spacing={1}>
                        <Button variant="contained" size="small"  onClick={handleAdd}>{<AddIcon />}</Button>
                        <Button variant="contained" size="small"  onClick={handleOpenUpdateDialog} disabled={disableEdit}>{<EditIcon />}</Button>
                        <Button variant="contained" size="small"  onClick={handleDelete} disabled={disableDelete}>{<ClearIcon />} </Button>
                        <Button variant="contained" size="small"  onClick={handleExportCSV}>CSV</Button>
                    </Stack>
                </Stack>
                <div>
                    <UsersGrid
                        ref={gridRef}
                        colState={colState}
                        onSelect={handleSelect}
                        keyword={keyword}
                        openSearch={openSearch}
                        exportCsvFlag={exportCsvFlag}
                        onChangePassword={(data) => {
                            // setDataUserChangePass(data);
                            // setOpenChangePasswordDialog(true)
                        }}
                        show2line={show2line!}
                    />
                </div>
            </div>
        </div>
        <FetchHelper
            fetchId={INSERT_USER}
            onComplete={(success, data, error) =>
                success ? handleSuccess(data,strInsert) : handleError(strInsert)} 
        />
        <FetchHelper
            fetchId={UPDATE_USER}
            onComplete={(success, data, error) =>
                success ? handleSuccess(data,strUpdate) : handleError(strUpdate)} 
        />
        <FetchHelper
            fetchId={DELETE_USER}
            onComplete={(success, data, error) =>
                success ? handleSuccess(data,strDelete) : handleError(strDelete)} 
        />
        <FetchHelper
            fetchId={GET_USER_LIST}
            onComplete={(success, data, error) =>
                success ? handleSuccessGetList(data) : handleErrorGetList(success, data, error)} 
        />
        <FetchHelper
            fetchId={RESET_PASS_USER}
            onComplete={(success, data, error) =>
                success ? handleSucessReset(data) : handleErrorReset(success, data, error)} 
        />
    </>
    )
}

const mapStateToProps = (state: ApplicationState) => ({
    userId: state.app.user?.userId,
})

const mapDispatchToProps = (dispatch: any) => ({
    onGet: (params: any ) => dispatch(actionCreators.fetch(GET_USER_LIST,"/user/get-list-user","POST",params,false,true)),
    onInsertUsers: (data: any) => dispatch(actionCreators.fetch(INSERT_USER,"/user/add-user","POST",data,false,true)),
    onUpdateUsers: (data:any) => dispatch(actionCreators.fetch(UPDATE_USER,"/user/edit-user","POST",data,false,true)),
    onDeleteUsers: (data:any) => dispatch(actionCreators.fetch(DELETE_USER,"/user/delete-user","POST",data,false,true)),
    onResetPassWordUser: (data:any) => dispatch(actionCreators.fetch(RESET_PASS_USER,"/user/reset-user-pass","POST",data,false,true)),
    onError: (success: boolean, data: any, error: any) =>
        dispatch(actionCreators.showMessage({
            type: "error",
            title: "ユーザー情報",
            body: "ユーザー情報を取得できません。" + error,
        }),),
    onCreateSuccess: () =>
        dispatch(actionCreators.showMessage({
            type: "info",
            title: "ユーザー情報追加",
            body: "ユーザー情報を登録しました。",
        })),
    onUpdateSuccess: () =>
        dispatch(actionCreators.showMessage({
            type: "info",
            title: "ユーザー情報編集",
            body: "ユーザー情報を更新しました。",
        })),
    onDeleteSuccess: () =>
        dispatch(actionCreators.showMessage({
            type: "info",
            title: "ユーザー情報削除",
            body: "ユーザー情報を削除しました。",
        })),
    onResetSuccess: () =>
        dispatch(actionCreators.showMessage({
            type: "info",
            title: "パスワードリセット",
            body: "パスワードをリセットしました。",
        })),
    onCreateError: () =>
        dispatch(actionCreators.showMessage({
            type: "error",
            title: "ユーザー情報追加",
            body: "ユーザー情報が登録出来ませんでした。",
        })),
    onUpdateError: () =>
        dispatch(actionCreators.showMessage({
            type: "error",
            title: "ユーザー情報編集",
            body: "ユーザー情報が更新出来ませんでした。",
        })),
    onDeleteError: () =>
        dispatch(actionCreators.showMessage({
            type: "error",
            title: "ユーザー情報削除",
            body: "ユーザー情報が削除出来ませんでした。",
        })),
    onResetError: () =>
        dispatch(actionCreators.showMessage({
            type: "error",
            title: "パスワードリセット",
            body: "パスワードのリセットは成功しませんでした。",
        })),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UsersList as any);

