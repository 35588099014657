import { DIRECT_NONE, LEFT_CENTER, MS_KW_TO_HORSEPOWER, MS_OUTSIDE_NEUTRAL, MS_OUTSIDE_OUTSIDE, MS_SEAT_MAIN, MS_SEAT_T, TOP_CENTER } from "../models/Constants"
import { BeamsNumber } from "./FormatNumber"
import { ControlModel, MsPowerBoardModel, ProcessMode } from "../models/Index";
import { MS_MODE_CALCULATION, MS_MODE_CHECKING, MS_MODE_DRAWING, MS_MODE_HARMONIC, MS_MODE_PERCENT_Z, MS_MODE_POWER_FLOW, MS_MODE_VOLTDROP } from "../statics";

const doGetValueTotalCapacity = (initData:any,totalCapacityPF:number,bIsLead:boolean,inputFDetail:boolean |undefined = undefined) =>{
    let totalCapacity = 0
    let dP = 0
    let dQ = 0
    let powerFactor = +initData.power_factor
    let totalCapacityUnit = initData.total_capacity_unit
    let inputDetail= inputFDetail
 
    let freezer = +initData.freezer
    let freezerPF = +initData.freezer_pf
    let freezerUnit = initData.freezer_unit

    //elevator
    let elevator = +initData.elevator
    let elevatorPF = +initData.elevator_pf
    let elevatorUnit = initData.elevator_unit
    //aircon
    let aircon  = +initData.aircon
    let airconPF  = +initData.air_con_pf
    let airconUnit  = initData.air_con_unit
    //hygiene
    let hygiene = +initData.hygiene
    let hygienePF = +initData.hygiene_pf
    let hygieneUnit = initData.hygiene_unit
    //capcitor
    let capacitor = +initData.capacitor
    let capacitorPF = +initData.capacitor_pf
    let capacitorUnit = initData.capacitor_unit
    //other
    let other = +initData.other
    let otherPF = +initData.other_pf
    let otherUnit = initData.other_unit

    
    //freezer
    if(inputDetail === undefined){
        inputDetail = initData.inputDetail
    }
    if(freezerPF !== 0){
        if(freezerUnit === "kW"){
            dP = freezer
            dQ = freezer * Math.sqrt(1 - freezerPF * freezerPF) / freezerPF
        }else if(freezerUnit === "kVA"){
            dP = freezer * freezerPF
            dQ = freezer * Math.sqrt(1 - freezerPF * freezerPF)
        }
        else if(freezerUnit === "HP") {
            dP = freezer / MS_KW_TO_HORSEPOWER
            dQ = (freezer/ MS_KW_TO_HORSEPOWER) * Math.sqrt(1 - freezerPF * freezerPF) / freezerPF
        }
    }
    else{
        if(freezerUnit === "kW"){
            dP = 0
            dQ = freezer
        }
        else if(freezerUnit === "kVA"){
            dP = 0;
            dQ = freezer
        }
        else if(freezerUnit === "HP"){
            dP = 0
            dQ = freezer / MS_KW_TO_HORSEPOWER
        }
    }
    //elevator
    if(elevatorPF !== 0) {
        if(elevatorUnit === "kW"){
            dP += elevator
            dQ += elevator * Math.sqrt(1 - elevatorPF * elevatorPF) / elevatorPF
        }
        else if(elevatorUnit === "kVA") {
            dP += elevator * elevatorPF
            dQ += elevator * Math.sqrt(1 - elevatorPF * elevatorPF)
        }
        else if(elevatorUnit === "HP"){
            dP += elevator / MS_KW_TO_HORSEPOWER
            dQ += (elevator / MS_KW_TO_HORSEPOWER) * Math.sqrt(1 - elevatorPF * elevatorPF) / elevatorPF
        }
    }
    else{
        if(elevatorUnit === "kW"){
            dQ += elevator
        }
        else if( elevatorUnit === "kVA"){
            dQ += elevator
        }
        else if( elevatorUnit === "HP"){
            dQ +=  elevator / MS_KW_TO_HORSEPOWER
        }
    }
    //aircon
    if( airconPF !== 0) {
        if( airconUnit === "kW"){
            dP +=  aircon
            dQ +=  aircon * Math.sqrt(1 -  airconPF *  airconPF) /  airconPF
        }
        else if( airconUnit === "kVA"){
            dP +=  aircon *  airconPF
            dQ +=  aircon * Math.sqrt(1 -  airconPF *  airconPF)
        }
        else if(airconUnit === "HP"){
            dP += aircon / MS_KW_TO_HORSEPOWER
            dQ += (aircon / MS_KW_TO_HORSEPOWER) * Math.sqrt(1 - airconPF * airconPF) / airconPF
        }
    }
    else{
        if (airconUnit === "kW"){
            dQ += aircon
        }
        else if(airconUnit === "kVA"){
            dQ += aircon
        }
        else if(airconUnit === "HP"){
            dQ += aircon / MS_KW_TO_HORSEPOWER
        }
    }
     //hygiene
     if(hygienePF !== 0) {
        if(hygieneUnit === "kW"){
            dP += hygiene
            dQ += hygiene * Math.sqrt(1 - hygienePF * hygienePF) / hygienePF
        }
        else if(hygieneUnit === "kVA"){
            dP += hygiene * hygienePF
            dQ += hygiene * Math.sqrt(1 - hygienePF * hygienePF)
        }
        else if(hygieneUnit === "HP"){
            dP += hygiene / MS_KW_TO_HORSEPOWER
            dQ += (hygiene / MS_KW_TO_HORSEPOWER) * Math.sqrt(1 - hygienePF * hygienePF) / hygienePF
        }
    }
    else{
        if(hygieneUnit === "kW"){
            dQ += hygiene
        }
        else if(hygieneUnit === "kVA"){
            dQ += hygiene
        }
        else if(hygieneUnit === "HP"){
            dQ += hygiene / MS_KW_TO_HORSEPOWER
        }
    }
    //capacitor
    if(capacitorPF !== 0) {
        if(capacitorUnit === "kW"){
            dP += capacitor
            dQ -= capacitor * Math.sqrt(1 - capacitorPF * capacitorPF) / capacitorPF
        }
        else if(capacitorUnit === "kVA"){
            dP += capacitor * capacitorPF
            dQ -= capacitor * Math.sqrt(1 - capacitorPF * capacitorPF)
        }
        else if(capacitorUnit === "HP"){
            dP += capacitor / MS_KW_TO_HORSEPOWER
            dQ -= (capacitor / MS_KW_TO_HORSEPOWER) * Math.sqrt(1 - capacitorPF * capacitorPF) / capacitorPF
        }
    }
    else{
        if(capacitorUnit === "kW"){
            dQ -= capacitor
        }
        else if(capacitorUnit === "kVA"){
            dQ -= capacitor
        }
        else if(capacitorUnit === "HP"){
            dQ -= capacitor /  MS_KW_TO_HORSEPOWER
        }
    }
    //other
    if(otherPF !== 0) {
        if(otherUnit === "kW"){
            dP += other
            dQ += other * Math.sqrt(1 - otherPF * otherPF) / otherPF
        }
        else if(otherUnit === "kVA"){
            dP += other * otherPF
            dQ += other * Math.sqrt(1 - otherPF * otherPF)
        }
        else if(otherUnit === "HP"){
            dP += other / MS_KW_TO_HORSEPOWER
            dQ += (other / MS_KW_TO_HORSEPOWER) * Math.sqrt(1 - otherPF * otherPF) / otherPF
        }
    }
    else{
        if(otherUnit === "kW"){
            dQ += other
        }
        else if(otherUnit === "kVA"){
            dQ += other
        }
        else if(otherUnit === "HP"){
            dQ += other / MS_KW_TO_HORSEPOWER
        }
    }
    totalCapacity = Math.sqrt(dP * dP + dQ * dQ)
    if(totalCapacity !== 0) {
        totalCapacityPF = dP / totalCapacity //powerFactor 
    }
    else{
        totalCapacityPF = 0
       
    }
    
    if(totalCapacityUnit === "kW"){
        totalCapacity = dP
    }
    else if(totalCapacityUnit === "kVA"){

    }else if (totalCapacityUnit === "HP"){
        totalCapacity = dP * MS_KW_TO_HORSEPOWER
    }
    if(!inputDetail){
        totalCapacity = initData.total_capacity
        totalCapacityPF = powerFactor
    }
    initData.power_factor = BeamsNumber(totalCapacityPF,5)


    return totalCapacity
  }


export const doGetValueLoadCurrent = (initdata:any,m_bSPhase:boolean,bIsLead:boolean,inputFDetail:boolean|undefined = undefined) =>{
    let fullCurrent = 0;
    let voltage = initdata.voltage
    let totalCapacity = initdata.total_capacity
    let inputDetail = inputFDetail
    let loadFactor = initdata.load_factor
    let totalCapacityUnit = initdata.total_capacity_unit
    if(inputDetail === undefined){
        inputDetail = initdata.input_detail
    }
    
    if(voltage <= 0){  
        fullCurrent = 0
    }
    else{
        let totalCapacity = 0
        let capacityPF = 0
        if(inputDetail === true){
            totalCapacity = doGetValueTotalCapacity(initdata,capacityPF,bIsLead)
            capacityPF = initdata.power_factor
            initdata.total_capacity = BeamsNumber(totalCapacity,6)
        }
        else{
            totalCapacity = initdata.total_capacity
            capacityPF = initdata.power_factor
         
        }
        let capacity = totalCapacity * 1000 * loadFactor
        if(capacityPF === 0) {

            if(totalCapacityUnit === "kW"){
                fullCurrent = capacity / voltage

            }
            else if(totalCapacityUnit === "kVA"){
                fullCurrent = capacity / voltage
            }
            else if(totalCapacityUnit === "HP"){
                fullCurrent = capacity / voltage / MS_KW_TO_HORSEPOWER
            }
            if(m_bSPhase !== true){
                fullCurrent /=Math.sqrt(3.0)
            }
        }
        else{

            if(totalCapacityUnit === "kW"){
                fullCurrent = capacity / voltage / capacityPF
            }
            else if(totalCapacityUnit === "kVA"){
                fullCurrent = capacity / voltage
            }
            else if(totalCapacityUnit === "HP"){
                fullCurrent = capacity /voltage / capacityPF / MS_KW_TO_HORSEPOWER
            }
           if(m_bSPhase !== true){
                fullCurrent /=Math.sqrt(3.0)
            }
        }
        // setValue("capacitorPF",capacityPF)//-------------
        
        // setValue("totalCapacity",BeamsNumber(+totalCapacity,6))
    }
    return fullCurrent
}

//CElePowerBoard::DoGetTextTotalCapacity
export const doGetTextTotalCapacity = (props:MsPowerBoardModel, bUnit:boolean = true) =>
{
	let dTotalCapacity = 0;
	let dCapacityPF = 0;
	let bIsLead = false;

	if (props.inputDetail) {
		dTotalCapacity = doGetValueTotalCapacity(props, dCapacityPF, bIsLead);
	}
	else {
		dTotalCapacity = props.totalCapacity;
	}
	let sText = BeamsNumber(dTotalCapacity, 6).toString();
	if (bUnit) {
		sText += props.totalCapacityUnit;
	}
	return sText;
}

//CElePowerBoard::DoGetTextVoltage
export const doGetTextVoltage = (props:MsPowerBoardModel, nPartKind:number, bUnit:boolean = true) =>
{
	let sText = '';
	if (props.sPhase) {
		switch (nPartKind) {
		case MS_OUTSIDE_NEUTRAL:	
			sText = (props.voltage / 2).toString();
			break;
		case MS_OUTSIDE_OUTSIDE:	
			sText = props.voltage.toString();
			break;
		case MS_SEAT_MAIN:		
			sText = props.voltage.toString();
			break;
		case MS_SEAT_T:				
			sText = props.voltage.toString();
			break;
		}
	}
	else {
		sText = props.voltage.toString();
	}
	if (bUnit) {
		sText += 'V';
	}
	return sText;
}

//CElePowerBoard::DoGetValueFullCurrent
export const doGetValueFullCurrent = (props:MsPowerBoardModel) =>
{
	let dFullCurrent = 0;

	let sTextHP = 'HP';
	let sTextkVA = 'kVA';
	let sTextkW = 'kW';

	if (props.voltage <= 0) {
		dFullCurrent = 0;
	}
	else {
		let dTotalCapacity = 0;
		let dCapacityPF = 0;
		let bIsLead = false;

		if (props.inputDetail) {
			dTotalCapacity = doGetValueTotalCapacity(props, dCapacityPF, bIsLead);
		}
		else {
			dTotalCapacity = props.totalCapacity;
			dCapacityPF = props.powerFactor;
		}
		let dCapacity = dTotalCapacity * 1000 * props.loadFactor;
		if (dCapacityPF == 0) {
			if (props.totalCapacityUnit == sTextkW) {
				dFullCurrent = dCapacity / props.voltage;
			}
			else if (props.totalCapacityUnit == sTextkVA) {
				dFullCurrent = dCapacity / props.voltage;
			}
			else if (props.totalCapacityUnit == sTextHP) {
				dFullCurrent = dCapacity / props.voltage / MS_KW_TO_HORSEPOWER;
			}

			if (!props.sPhase) {
				dFullCurrent /= Math.sqrt(3.0);
			}
		}
		else {
			if (props.totalCapacityUnit == sTextkW) {
				dFullCurrent = dCapacity / props.voltage / dCapacityPF;
			}
			else if (props.totalCapacityUnit == sTextkVA) {
				dFullCurrent = dCapacity / props.voltage;
			}
			else if (props.totalCapacityUnit == sTextHP) {
				dFullCurrent = dCapacity / props.voltage / dCapacityPF / MS_KW_TO_HORSEPOWER;
			}

			if (!props.sPhase ) {
				dFullCurrent /= Math.sqrt(3.0);
			}
		}
	}

	return dFullCurrent;
}

//CElePowerBoard::DoGetTextLoadCurrent
export const doGetTextLoadCurrent = (props:MsPowerBoardModel, bUnit:boolean = true) =>
{
	let dLoadCurrent = doGetValueFullCurrent(props);
	let sText = BeamsNumber(dLoadCurrent, 4).toString();
	if (bUnit) {
		sText += 'A';
	}
	return sText;
}

//CElePowerBoard::DoGetTextFullCurrent
export const doGetTextFullCurrent = (props:MsPowerBoardModel, bUnit:boolean = true) =>
{
	let dFullCurrent = doGetValueFullCurrent(props);
	let sText = BeamsNumber(dFullCurrent, 4).toString();
	if (bUnit) {
		sText += 'A';
	}
	return sText;
}

//CElePowerBoard::DoGetTextTotalCapacityPF
export const doGetTextTotalCapacityPF = (props:MsPowerBoardModel, pbIsLead:boolean) =>
{
	let dTotalCapacity = 0;
	let dTotalCapacityPF = 0;

	if (props.inputDetail) {
		dTotalCapacity = doGetValueTotalCapacity(props, dTotalCapacityPF, pbIsLead);
	}
	else {
		dTotalCapacityPF = props.powerFactor;
		if (pbIsLead != null) {
			pbIsLead = props.isLead;
		}
	}
	let sText = BeamsNumber(dTotalCapacityPF, 5).toString();
	return {
        sText : sText,
        bIsLead : pbIsLead
    };
}

//CElePowerBoard::DoGetRefV1
export const doGetRefV1 = (mode:any, props:MsPowerBoardModel, bSociety:boolean) =>
{
    let result = {sText: '', bIsLead: false}
    
	//CElePowerBoard::DoGetRefV1Left  sRefV1Left
	let labelTexts = [{
		value: props.refNo,
		color: "#f800f8",
	}];

	if (!props.viewResultText || (mode != ProcessMode.DRAWING && !props.supply))
    {
      return labelTexts;
    }

    if(bSociety) {
        return labelTexts;
    }

    switch(mode) {
        case ProcessMode.DRAWING:
        case ProcessMode.CHECKING:
            //TotalCapacity
            labelTexts.push({
                value: doGetTextTotalCapacity(props),
                color: "blue",
            });

            //LoadCurrent
            labelTexts.push({
                value: doGetTextLoadCurrent(props),
                color: "blue",
            });
            break;
        case ProcessMode.PERCENT_Z:
        case ProcessMode.CALCULATION:
            break;
        case ProcessMode.VOLTDROP:
            //FullCurrent
            labelTexts.push({
                value: doGetTextFullCurrent(props),
                color: "blue",
            });

            //力率=doGetTextTotalCapacityPF()
            result = doGetTextTotalCapacityPF(props, result.bIsLead);
            labelTexts.push({
                value: '力率='
                + result.sText,
                color: "blue",
            });
            break;
        case ProcessMode.IMPROVE_PF:
            //力率=doGetTextTotalCapacityPF()
            result = doGetTextTotalCapacityPF(props, result.bIsLead);
            labelTexts.push({
                value: '力率='
                + result.sText,
                color: "blue",
            });
            break;
        case ProcessMode.HARMONIC:
            break;
        case ProcessMode.POWER_FLOW:
            break;
        default:
            break;
    }

    return labelTexts;
}

//CElePowerBoard::DoGetTextFlowsResult
export const doGetTextFlowsResult = (props:MsPowerBoardModel, processMode:any, nDirect:number, bSociety:boolean = false) =>
{
	let sRefV1:string[] = [];

	if (bSociety) {
		return sRefV1;
	}

	switch (processMode) {
	case MS_MODE_DRAWING:
	case MS_MODE_CHECKING:
	case MS_MODE_PERCENT_Z:
	case MS_MODE_CALCULATION:
	case MS_MODE_VOLTDROP:
	case MS_MODE_HARMONIC:
		break;
	case MS_MODE_POWER_FLOW:
		if (props.activePower !==0 && props.reactivePower !==0) {
			if (nDirect == DIRECT_NONE) {
				sRefV1.push(BeamsNumber(Math.abs(props.activePower),5).toString() + 'kW')
				sRefV1.push(BeamsNumber(Math.abs(props.reactivePower),5).toString() + 'kvar')
			}
			else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
				sRefV1.push(BeamsNumber(Math.abs(props.activePower),5).toString() + 'kW')
				let sTemp = ''
				if (props.reactivePower > 0) {
					sTemp += "+j";
				}
				else {
					sTemp += "-j";
				}
				sRefV1.push(sTemp + BeamsNumber(Math.abs(props.reactivePower),5).toString() + 'kvar')
			}
		}
		break;
	}

	return sRefV1;
}
