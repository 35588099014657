import { memo, useEffect, useMemo } from "react";

//#region Props
interface Props {
    scaleGrid: number;
    shapes: any;
    widthGrid:number;
    heightGrid:number;
}
//#endregion

export const SvgDotsGrid = memo((props: Props) => {
    const { scaleGrid, shapes, widthGrid, heightGrid } = props;
    let wid:number = useMemo(()=>{
        let calc:number = widthGrid * scaleGrid
        shapes.map((item:any)=>{
            (item.x + 20) * scaleGrid > calc && (calc = (item.x + 200) * scaleGrid)
        })
        return calc
    },[shapes, widthGrid, scaleGrid])
    let hei:number = useMemo(()=>{
        let calc:number = heightGrid * scaleGrid
        shapes.map((item:any)=>{
            (item.y + 20) * scaleGrid > calc && (calc = (item.y + 200) * scaleGrid)
        })
        return calc
    },[shapes, heightGrid, scaleGrid])
  
    return (
        <div style={{zIndex:0, overflow:"hidden", position:"absolute", width:wid, height:hei, top:0, left:0}}>
            <svg width="100%" height="100%"  opacity="0.5">
                <pattern id="dot-pattern" x={-5} y={-5} width={`${20*scaleGrid}`} height={`${20*scaleGrid}`} patternUnits="userSpaceOnUse">
                    <circle cx="5" cy="5" r={ 1 * scaleGrid } fill="#202020" />
                </pattern>

                <rect width="100%" height="100%" fill="url(#dot-pattern)"></rect>

            </svg>
        </div>
    );
});

export default SvgDotsGrid;
