import { CircularProgress, Dialog, DialogContent, DialogTitle } from "@mui/material";
import Konva from "konva";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { Group, Image, Label, Rect, Text, Transformer } from "react-konva";
import { Html, useImage } from "react-konva-utils";
import { connect } from "react-redux";
import * as Constant from "../../models/Constants";
import { Coordinates, LineInfoModel, LineType, MSZCTModel, OptionModel, ProcessMode, UndoModel } from '../../models/Index';
import {
    addUserCurve,
    clearOptionMenu,
    deleteRelatedGraphByUserCurveDialog,
    deleteUserCurve,
    openChart,
    openDialogEvents,
    openUserCurveEvents,
    openUserCurvePropertiesEvents,
    setRepositionElement,
    setTransformFlag,
    undo,
    updateControlHeight,
    updateElementPosition,
    updatePropertiesOfControlAction,
    updatePropertiesOfTransCenter,
    updateSelectControl,
    updateUserCurve,
} from "../../store/Actions";
import theme from "../../themes/globalStyles";
import OptionMenu from '../contextMenu/OptionMenu';
import MsZCTEditDialog from '../dialogs/MsZCTEditDialog';
import lineSvg from "../../images/control/line.svg";
import lineGreenSvg from "../../images/control/line_green.svg";
import lineRedSvg from "../../images/control/line_red.svg";
import imageSvg from "../../images/control/零相変流器.svg";
import imageRedSvg from "../../images/control/零相変流器_red.svg";
import imageGreenSvg from "../../images/control/零相変流器_green.svg";
import { addListToData } from "../../utils/DataConverter";
import UserCurvesDialog from "../dialogs/UserCurvesDialog";
import DraggablePaper from "../common/DraggablePaper";
import { doGetValuesTransCenter } from "../../utils/TransCenterFunction";
import { getElementKindValue } from "../../utils/ElementFunction";
import doGetSourceVolt, { doGetTransCenterSourceVolt } from "../../utils/mssv3Doc";
import { doGetRefV1 } from "../../utils/ZCTFunction";
import UserCurveDetailDialog from "../dialogs/UserCurveDetailDialog";
import { post } from "../CallApi";
import { getSymbolAndBackColor } from "../../utils/drawElement";
import { calcContextMenuPosition, calcDragBoundPosition, calcElementSize, getMenuOptionHeight } from "../../utils/PositionCalculation";
import { doDrawSymbolGroup } from "../graph/DCView";

//#region Props
type CreateMsZCTProps = {
    id: string,
    image: string,
    x: number,
    y: number,
    offsetX: number;
    offsetY: number;
    width: number,
    height: number,
    type: string,
    isSelected: boolean,
    isHidden: boolean;
    rotation: number,
    lineDiagramRef: any,
    parentGroupId: number | null;
    properties: MSZCTModel,
    chart: LineInfoModel[];
    gridSizeController: {
        width: number;
        height: number;
        scale: number;
        stageOffsetX: number;
        stageOffsetY: number;
    };
    isOnShiftKey: boolean;
};

export type PureCreateMsZCTProps = CreateMsZCTProps & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
//#endregion

const CONTROL_POS_FACTOR = 1;

const CreateMsZCT = memo((props: PureCreateMsZCTProps) => {
    //#region Fields
    const {
        id,
        image,
        x,
        y,
        offsetX,
        offsetY,
        width,
        height,
        type,
        isSelected,
        isHidden,
        rotation,
        lineDiagramRef,
        parentGroupId,
        processMode,
        properties,
        chart,
    } = props;
    
    const {
        // getElementParam,
        // updateElementPost,
        // addUserCurveDB,
        // updateUserCurveDB,
        // deleteUserCurveDB,
        diagramDataUpdate,
        diagramData,
        mainDiagramData,
        projectData,
        gridSizeController,
        currentTabId,
        openDialogData,
        openUserCurveData,
        userCurveProperties,
        userRole,
        m_bModePM,
        modeViewOnly,
        infoSkeleton,
        userId,
        drawPowerArrow,
        clearMenuState,
        isSelectTouchGroup,
        isOnShiftKey,
        elementGroups,
        tcGroups,
        elementReposition,
        updateElementPosition,
        setRepositionElement,
        updateSelectControl,
        updateControlData,
        updateControlHeight,
        setTransformFlag,
        addUserCurve,
        updateUserCurve,
        deleteUserCurve,
        setOpenChart,
        updateTransCenterProps,
        openDialogEvents,
        openUserCurvePropertiesEvent,
    	deleteRelatedGraphByUserCurveDialog,
        openUserCurveEvent,
        setUndoData,
        clearOptionMenu,
    } = props;

    const [imageStatus, setImageStatus] = useState(image);
    const [lineStatus, setLineStatus] = useState(lineSvg);
    const [showPowerInfo, setShowPowerInfo] = useState(false)
    const [showPowerDirectInfo, setShowPowerDirectInfo] = useState(false)
    const [activePower, setActivePower] = useState("?????kW")
    const [reactivePower, setReactivePower] = useState("?????kvar")
    const [svgHeight, setSvgHeight] = useState('')
    const [svgWidth, setSvgWidth] = useState('')
    const [svgLine, setSvgLine] = useState('')
    const [svgArrowActive, setSvgArrowActive] = useState('')
    const [svgArrowReactive, setSvgArrowReactive] = useState('')
    const [svgTextX, setSvgTextX] = useState('')
    const [svgTextY, setSvgTextY] = useState('')
    const [svgTextRotation, setSvgTextRotation] = useState('')
    const [svgTextX1, setSvgTextX1] = useState('')
    const [svgTextY1, setSvgTextY1] = useState('')
    const [svgPosX1, setSvgPosX1] = useState(0)
    const [svgPosX2, setSvgPosX2] = useState(0)
    const [svgPosX3, setSvgPosX3] = useState(0)
    const [svgPosY1, setSvgPosY1] = useState(0)
    const [svgPosY2, setSvgPosY2] = useState(0)
    const [svgPosY3, setSvgPosY3] = useState(0)

    var imageObj = new window.Image();
    imageObj.src = imageStatus;

    var lineObj = new window.Image();
    lineObj.src = lineStatus;

    const svgToURL = (s:any) =>{
        const uri = window.btoa(unescape(encodeURIComponent(s)));
        return "data:image/svg+xml;base64," + uri;
      }
    
        const svgActivePower =
        `<svg height="${svgHeight}" width="${svgWidth}"  xmlns="http://www.w3.org/2000/svg">` +
        `<path  d="${svgLine}" stroke="${isSelected? 'red' : 'blue'}"  stroke-width="1" fill="none"/>` +
        `<path  d="${svgArrowActive}" stroke="${isSelected? 'red' : 'blue'}" stroke-width="1"  fill="none"/>` +
        `<text x="${svgTextX}" y="${svgTextY}" text-anchor="middle" font-size="10" fill="${isSelected? 'red' : 'blue'}" transform="translate(15,15) rotate(${svgTextRotation})">${activePower}</text>` +
        '</svg>' ;
    
      const urlActivePower = svgToURL(svgActivePower);
      const [imageSVGActivePower] = useImage(urlActivePower);
    
      const svgReactivePower =
      `<svg height="${svgHeight}" width="${svgWidth}"  xmlns="http://www.w3.org/2000/svg">` +
      `<path  d="${svgLine}" stroke="${isSelected? 'red' : 'blue'}"  stroke-width="1" fill="none"/>` +
      `<path  d="${svgArrowReactive}" stroke="${isSelected? 'red' : 'blue'}" stroke-width="1"  fill="none"/>` +
      `<text x="${svgTextX}" y="${svgTextY}" text-anchor="middle" font-size="10" fill="${isSelected? 'red' : 'blue'}" transform="translate(15,15) rotate(${svgTextRotation})">${reactivePower}</text>` +
      '</svg>' ;
    
      const urlReactivePower = svgToURL(svgReactivePower);
      const [imageSVGReactivePower] = useImage(urlReactivePower);
    
      
      const svgActiveReactivePower =
      `<svg height="${svgHeight}" width="${svgWidth}"  xmlns="http://www.w3.org/2000/svg">` +
      `<path  d="${svgLine}" stroke="${isSelected? 'red' : 'blue'}"  stroke-width="1" fill="none"/>` +
      `<path  d="${svgArrowActive}" stroke="${isSelected? 'red' : 'blue'}" stroke-width="1"  fill="none"/>` +
      `<text x="${svgTextX}" y="${svgTextY}" text-anchor="middle" font-size="10" fill="${isSelected? 'red' : 'blue'}" >${activePower}</text>` +
      `<text x="${svgTextX1}" y="${svgTextY1}" text-anchor="middle" font-size="10" fill="${isSelected? 'red' : 'blue'}" >${reactivePower}</text>` +
      '</svg>' ;
      
      const urlActiveReactivePower = svgToURL(svgActiveReactivePower);
      const [imageSVGActiveReactivePower] = useImage(urlActiveReactivePower);

    const [isOpenUserCurvesDialog, setIsOpenUserCurvesDialog] = useState(false);
    const [isOpenMenu, setIsOpenMenu] = useState(false);
    const [menuPosition, setMenuPosition] = useState<Coordinates>({ x: 0, y: 0 });
    const [isOpenEditorDialog, setIsOpenEditorDialog] = useState(false);
    const [groupPosition, setGroupPosition] = useState({ x: x, y: y });
    const [dragStartPosition, setDragStartPosition] = useState({ x: x, y: y, labelX: groupPosition.x + Constant.ELEMENT_SIZE, labelY: groupPosition.y + CONTROL_POS_FACTOR * (height / 2) });
    const [isResizing, setIsResizing] = useState(false);
    const [heightTransform, setHeightTransform] = useState(0);
    const [labelPosition, setLabelPosition] = useState({
        x: groupPosition.x + Constant.ELEMENT_SIZE,
        y: groupPosition.y + CONTROL_POS_FACTOR * (height / 2),
    });
    const [arrowPosition, setArrowPosition] = useState({
        x: groupPosition.x,
        y: groupPosition.y + height - Constant.ELEMENT_SIZE - 10,
      });

    const transformRef = useRef<Konva.Transformer>(null);
    const transformMoveRef = useRef<Konva.Transformer>(null);
    const groupRef = useRef<any>();
    const topRef = useRef<any>();
    const bottomRef = useRef<any>();
    const labelRef = useRef<any>();

    const [initialValue, setInitialValue] = useState(properties);
    const [loadingFlag, setLoadingFlag] = useState(false);
    const [listUserCurves, setListUserCurves] = useState(properties.listUser?[...properties.listUser]:[])

    const [isOpenUserCurveProperties, setIsOpenUserCurveProperties] = useState(false)
    const [selectItem, setSelectItem] = useState<any>(null);
    const [isOnOpenMenu, setIsOnOpenMenu] = useState<boolean>(false);

    // const labelTexts = doGetRefV1(processMode, initialValue);
    const [labelTexts, setLabelTexts] = useState<any>([])

    //#endregion

    //#region useEffect
    useEffect(() => {
        setLabelTexts(doGetRefV1(processMode,initialValue))
    }, [processMode,diagramDataUpdate])
    useEffect(()=>{
        if(isSelected){
          if(isOnOpenMenu) {
            setIsOnOpenMenu(false)
          }
          else
          {
            setIsOpenMenu(false)
          }
        }
      },[clearMenuState])

    useEffect(()=>{
    if(elementReposition?.id)
    {
        const groupsData = currentTabId === 1 ? elementGroups : tcGroups[diagramData.find((x:any)=>x.id == id)?.parentTcId ?? 0]
        const peekGroupId = groupsData?.byEleId[elementReposition.id as any]?.at(-1) ?? -1
        const elementIds = groupsData?.byId[peekGroupId]?.elementIds ?? []
        if(elementIds.includes(id) || diagramData[0].shape.find((item:any)=>(item.isSelected && item.id == id)))
        {
            setGroupPosition({
                x: dragStartPosition.x,
                y: dragStartPosition.y,
            });

            labelRef.current.to({
                x: dragStartPosition.labelX,
                y: dragStartPosition.labelY,
            });

            groupRef.current.to({
                x: dragStartPosition.x,
                y: dragStartPosition.y,
            });

            updateElementPosition(id, dragStartPosition.x, dragStartPosition.y)
        }
    }
    },[elementReposition])

    useEffect(() => {
        if (x >= 0 && y >= 0 && (groupPosition.x !== x || groupPosition.y !== y)) {
            setGroupPosition({x, y})
        }
    }, [x, y])

    useEffect(()=>{
        if(userCurveProperties?.controlId === id && userCurveProperties !== undefined) {
          let userCurve = properties.listUser.find(x => x.userCurveId === userCurveProperties.userCurveId)
          userCurve && setSelectItem(userCurve)
          setIsOpenUserCurveProperties(true)
        }
      },[userCurveProperties])

    useEffect(() => {
        setIsOpenMenu(false)
        if(processMode == ProcessMode.POWER_FLOW && drawPowerArrow.payload.length > 0 && id)
        {
        let result = drawPowerArrow.payload.find((arrows:any) => arrows.arrow?.element?.id && Number(arrows.arrow?.element?.id) == Number(id))
        if(result && result.arrow && result.text.length > 0){
            setPowerFlowArrow(result)
        }
        }
    }, [drawPowerArrow])

    useEffect(() => {
        if(processMode == ProcessMode.POWER_FLOW)
        {
        const pElement = diagramData[0].shape.find((x:any)=>x.id === id)
        let result = doDrawSymbolGroup(pElement,processMode)
        if(result && result.arrow && result.text.length > 0){
            setPowerFlowArrow(result)
        }else{
            setActivePower("")
            setReactivePower("")
            setSvgArrowReactive("")
            setSvgArrowActive("")
            setSvgLine("");
        }
        }
        else if(showPowerInfo == true || showPowerDirectInfo == true)
        {
        setShowPowerInfo(false);
        setShowPowerDirectInfo(false);
        }
    },[processMode, projectData.flowViewMethod, properties.viewFlowResult])

    useEffect(() => {
      setInitialValue(properties);
    },[properties])

    useEffect(() => {
        if (isSelected && !parentGroupId) {
            transformRef.current?.nodes([groupRef.current]);
        } else {
            transformRef.current?.nodes([]);
        }
        transformRef.current?.getLayer()?.batchDraw();
    }, [isSelected, parentGroupId, heightTransform]);

    useEffect(() => {
        if (!isSelected) setIsOpenMenu(false);
    }, [isSelected]);

    useEffect(() => {
        if(openDialogData?.id === id && openDialogData !== undefined) {
            openDialogEvents(({type: "OPEN_DIALOG", data: { }}))
            handleOpenEditorDialog()
        }
      }, [openDialogData]);

    useEffect(() => {
    if(openUserCurveData?.id === id && openUserCurveData !== undefined) {
        setIsOpenUserCurvesDialog(true)
    }
    }, [openUserCurveData]);

    useEffect(() => {
        // check grid size; label, icon position when rotate, move and change height
        // handleGridSize(groupPosition.x, groupPosition.y, height, rotation);
        calcLabelPos();
        if (diagramData[0].tabId != 1){
            // TransCenter
            doSetValuesTransCenter();
        }
    }, [groupPosition, height, rotation]);

    useEffect(() => {
        switch(getSymbolAndBackColor(infoSkeleton,processMode,properties).symbolColor){
          case "#008040":
            setLineStatus(lineGreenSvg);
            setImageStatus(imageGreenSvg);
            break;
          case "#FF0000":
            setLineStatus(lineRedSvg);
            setImageStatus(imageRedSvg);
            break;
          default:
            setLineStatus(lineSvg);
            setImageStatus(imageSvg);
            break;
        }
      },[getSymbolAndBackColor(infoSkeleton,processMode,properties).symbolColor])
    //#endregion

    //#region Method
    const setPowerFlowArrow = (data:any) => {
        if(data.arrow.drawActiveNoneDirect){
          setShowPowerInfo(true)
          setShowPowerDirectInfo(false)
          if(data.arrow.arrowActiveAtTop){
              setSvgHeight('50')
              setSvgWidth('30')
              setSvgLine('M 20 50 L 20 0')
              setSvgArrowActive('M 16 5 L 20 0 L 24 5')
              setSvgTextX('-10')
              setSvgTextY('-5')
              setSvgTextRotation('270')
              setActivePower(data.text[0])
              setSvgPosX1(-80)
              setSvgPosY1(-30)
          }
          else if(data.arrow.arrowActiveAtRight) {
              setSvgHeight('30')
              setSvgWidth('50')
              setSvgLine('M 50 20 L 0 20')
              setSvgArrowActive('M 5 16  L 0 20 L 5 24')
              setSvgTextX('7')
              setSvgTextY('3')
              setSvgTextRotation('0')
              setActivePower(data.text[0])
              setSvgPosX1(-40)
              setSvgPosY1(20)
              
          }
          else if(data.arrow.arrowActiveAtLeft){
              setSvgHeight('30')
              setSvgWidth('50')
              setSvgLine('M 50 20 L 0 20')
              setSvgArrowActive('M 45 16 L 50 20 L 45 24')
              setSvgTextX('7')
              setSvgTextY('3')
              setSvgTextRotation('0')
              setActivePower(data.text[0])
              setSvgPosX1(-55)
              setSvgPosY1(-30)
              setSvgPosX1(-40)
              setSvgPosY1(30)
          }
          else {
              setSvgHeight('50')
              setSvgWidth('30')
              setSvgLine('M 20 50 L 20 0')
              setSvgArrowActive('M 16 45 L 20 50 L 24 45')
              setSvgTextX('-10')
              setSvgTextY('-5')
              setSvgTextRotation('270')
              setActivePower(data.text[0])
              setSvgPosX1(-80)
              setSvgPosY1(-30)
          }
        }
        if(data.arrow.drawReactiveNoneDirect){
          setShowPowerInfo(true)
          setShowPowerDirectInfo(false)
          if(data.arrow.arrowReactiveAtTop){
              setSvgHeight('50')
              setSvgWidth('30')
              setSvgLine('M 20 50 L 20 0')
              setSvgArrowReactive('M 16 5  L 20 0 L 24 5')
              setSvgTextX('-10')
              setSvgTextY('-5')
              setSvgTextRotation('270')
              setReactivePower(data.text[1])
              setSvgPosX2(-55)
              setSvgPosY2(-30)
          }
          else if(data.arrow.arrowReactiveAtRight) {
              setSvgHeight('30')
              setSvgWidth('50')
              setSvgLine('M 50 20 L 0 20')
              setSvgArrowReactive('M 5 16  L 0 20 L 5 24')
              setSvgTextX('7')
              setSvgTextY('3')
              setSvgTextRotation('0')
              setReactivePower(data.text[1])
              setSvgPosX2(-40)
              setSvgPosY2(40)
          }
          else if(data.arrow.arrowReactiveAtLeft){
              setSvgHeight('30')
              setSvgWidth('50')
              setSvgLine('M 50 20 L 0 20')
              setSvgArrowReactive('M 45 16 L 50 20 L 45 24')
              setSvgTextX('7')
              setSvgTextY('3')
              setSvgTextRotation('0')
              setReactivePower(data.text[1])
              setSvgPosX2(-40)
              setSvgPosY2(40)
          }
          else {
              setSvgHeight('50')
              setSvgWidth('30')
              setSvgLine('M 20 50 L 20 0')
              setSvgArrowReactive('M 16 45 L 20 50 L 24 45')
              setSvgTextX('-10')
              setSvgTextY('-5')
              setSvgTextRotation('270')
              setReactivePower(data.text[1])
              setSvgPosX2(-55)
              setSvgPosY2(-30)
          }
        }
        if(data.arrow.drawOtherDirect){
          setShowPowerInfo(false)
          setShowPowerDirectInfo(true)
          if(data.arrow.arrowActiveReactiveAtTop){
              setSvgHeight('50')
              setSvgWidth('70')
              setSvgLine('M 10 50 L 10 20')
              setSvgArrowActive('M 6 25 L 10 20 L 14 25')
              setSvgTextX('40')
              setSvgTextY('30')
              setSvgTextX1('40')
              setSvgTextY1('40')
              setSvgTextRotation('0')
              setActivePower(data.text[0])
              setReactivePower(data.text[1])
              setSvgPosX3(20)
              setSvgPosY3(-50)
          }
          else if(data.arrow.arrowActiveReactiveAtRight) {
              setSvgHeight('70')
              setSvgWidth('50')
              setSvgLine('M 50 10 L 20 10')
              setSvgArrowActive('M 25 6 L 20 10 L 25 14')
              setSvgTextX('25')
              setSvgTextY('20')
              setSvgTextX1('25')
              setSvgTextY1('30')
              setSvgTextRotation('0')
              setActivePower(data.text[0])
              setReactivePower(data.text[1])
              setSvgPosX3(-30)
              setSvgPosY3(30)
          }
          else if(data.arrow.arrowActiveReactiveAtLeft){
              setSvgHeight('70')
              setSvgWidth('50')
              setSvgLine('M 50 10 L 20 10')
              setSvgArrowActive('M 45 6 L 50 10 L 45 14')
              setSvgTextX('25')
              setSvgTextY('20')
              setSvgTextX1('25')
              setSvgTextY1('30')
              setSvgTextRotation('0')
              setActivePower(data.text[0])
              setReactivePower(data.text[1])
              setSvgPosX3(-30)
              setSvgPosY3(30)
          }
          else {
              setSvgHeight('50')
              setSvgWidth('70')
              setSvgLine('M 10 50 L 10 20')
              setSvgArrowActive('M 6 45 L 10 50 L 14 45')
              setSvgTextX('40')
              setSvgTextY('30')
              setSvgTextX1('40')
              setSvgTextY1('40')
              setSvgTextRotation('0')
              setActivePower(data.text[0])
              setReactivePower(data.text[1])
              setSvgPosX3(20)
              setSvgPosY3(-50)
          }
        }
      }

    const handleOKUserCurveDetailForm = async (data: any) => {
        const foundUserCurve = listUserCurves.find((curve:any) => curve.userCurveId === data.userCurveId)        
        if (foundUserCurve) { // update  
            setLoadingFlag(true);
            const request = { 
                userId: userId,
                projectId: projectData.projectId,
                elementId: parseInt(id),
                params:{...data},
                ownerProject: projectData.ownerId

            }
            const result = await post("/diagram/edit-curve-user", request);
            if (result.success) {
                setLoadingFlag(false);
                handleUpdateCurveUserSuccess(id, result.data);
                updateUserCurve(id,data);
            } else {
                handleSubmitError(result.error);
                setLoadingFlag(false);
            }
            //setOpenChart(true, LineType.USER_CURVE, data.userCurveId??data.user_curve_id)
        } else { // add new
            addUserCurve(id, data)
        }
        setSelectItem(null)
        setIsOpenUserCurveProperties(false)
      }
    
      const handleCancelUserCurveDetailForm = () => {
        setSelectItem(null)
        setIsOpenUserCurveProperties(false)
        openUserCurvePropertiesEvent('', '')
      }
      
    const doSetValuesTransCenter = () => {
        // TransCenter
        let transCenter = mainDiagramData.shape.find(
            (e: any) => e.properties?.tabId === diagramData[0].tabId
        );

        if (transCenter) {
            let tmpCalc = doGetValuesTransCenter(transCenter.properties, diagramData[0].shape);
            transCenter.properties = tmpCalc.newProps;
            updateTransCenterProps(transCenter.id, transCenter.properties);
        }
    };

    const handleContextMenu = (e: any) => {
        e.evt.preventDefault();
        if(e.evt.shiftKey) return
        setIsOnOpenMenu(true)
        updateSelectControl(id);
    
        const menuHeight = getMenuOptionHeight()
        const clientWidth = lineDiagramRef.current.clientWidth
        const isTransCenter = currentTabId == 1 ? false : true;
        let {posX, posY} = calcContextMenuPosition(
          e, menuHeight, clientWidth, x, y, gridSizeController, isTransCenter
        )
        clearOptionMenu(!clearMenuState)
        
        setMenuPosition({
            x: posX,
            y: posY,
        });
        setIsOpenMenu(true);
    };

    const handleClick = (e: any) => {
        if (e.type === "click") setIsOpenMenu(false);
        if(parentGroupId && parentGroupId > 0) return;
        if (e.evt.shiftKey || isSelectTouchGroup) {
          updateSelectControl(id, true);
        } else {
          updateSelectControl(id);
        }
    };

    const handleOpenMenu = (value: boolean) => {
        setIsOpenMenu(value);
        updateSelectControl(id);
    };

    const handleOpenEditorDialog = async () => {
        let params ={
            userId: userId,
            projectId: projectData.projectId,
            elementId: id,
            elementType: type,
            data: properties,
            ownerProject: projectData.ownerId

          };
          setLoadingFlag(true);
        //   getElementParam(params, id);
        const result = await post("/diagram/get-param", params);
        if (result.success) {
        handleGetSuccess(result.data);
        } else {
        handleSubmitError(result.error);
        }
    };
    const handleUpdateSuccess = (data: any) => {
        console.log('>>>> handle Successs');
        setLoadingFlag(false);
      }
      // updateElementPostのAPI呼び出しエラー時
      const handleSubmitError = (error: any) => {
        console.log('>>>> handle Error');
        console.log(error);
        setLoadingFlag(false);
      }

    const handleCancelEditorDialog = () => {
        setIsOpenEditorDialog(false);
    };

    const handleUpdate = async (data: any) => {
        const updateData = {
            ...data,
        };
        setIsOpenEditorDialog(false);
        // call api
    const request = { 
        userId: userId,
        projectId: projectData.projectId,
        elementType: getElementKindValue(props.type),
        elementId: id,
        param: {...updateData},
        ownerProject: projectData.ownerId
      }
    //   updateElementPost(request, id);
    setLoadingFlag(true);
    const result = await post("/diagram/set-param", request);
    if (result.success) {
        if(result.data && result.data.resultCode == 0){
            setUndoData({type:"UPDATE_PROPERTIES_ELEMENT", dataUndo:{}} as UndoModel);
            setInitialValue(data);
            updateControlData(id, updateData);
        }
        handleUpdateSuccess(result.data);
    } else {
      handleSubmitError(result.error);
    }

      openDialogEvents({type: "RELOAD", data: { }});
    };

    const dragBoundFunc = (e: any, mode: string) => {
        // let rect = groupRef.current.getClientRect();
        const newPosition = calcDragBoundPosition(
            e, groupPosition, rotation, height, mode, gridSizeController
        );
    
        return {
            x: newPosition.newX,
            y: newPosition.newY,
        };
    };

    const calcLabelPos = (e?: any) => {
        let newX = groupPosition.x,
            newY = groupPosition.y;
        if (e?.type === "dragmove") {
            newX = groupRef.current.attrs.x;
            newY = groupRef.current.attrs.y;
        }

        const newPosition = {
            x: newX + Constant.ELEMENT_SIZE,
            y: newY + CONTROL_POS_FACTOR * (height / 2),
        };

        if (rotation !== 0) {
            newPosition.x = newX + CONTROL_POS_FACTOR * (height / 2) - 5;
            newPosition.y = newY - Constant.ELEMENT_SIZE;
        }

        setLabelPosition(newPosition);
        setArrowPosition(newPosition)
    };

    const handleDragStart = (e: any) => {
        groupRef.current.moveToTop()
        transformRef.current?.moveToTop()
        labelRef.current?.moveToTop()
        setDragStartPosition({ x: groupPosition.x, y: groupPosition.y, labelX: labelPosition.x, labelY: labelPosition.y });
        setIsOpenMenu(false);
    };

    const handleDragmove = (e: any) => {
        calcLabelPos(e);
    };

    const onDragEnd = (e: any) => {
        if (
            e.currentTarget.attrs.x === groupPosition.x &&
            e.currentTarget.attrs.y === groupPosition.y
        ) {
            return;
        }

        let newX =
            Math.round(e.target.x() / Constant.POINTER_WIDTH) *
            Constant.POINTER_WIDTH;
        let newY =
            Math.round(e.target.y() / Constant.POINTER_HEIGHT) *
            Constant.POINTER_HEIGHT;

        // check new position is outside grid
        if (newX < 0 || newY < 0) {
            setRepositionElement(id, !(elementReposition?.state??true))
            newX = dragStartPosition.x;
            newY = dragStartPosition.y;
        }

        setGroupPosition({
            x: newX,
            y: newY,
        });

        e.target.to({
            x: newX,
            y: newY,
        });
    };

    // Mobile Touch Events
    var isMoving = false
  var isLongPress = false
  
  const handleTouchStart = useCallback((e:any) => {
    setIsOpenMenu(false)
    isLongPress = true
    isMoving = false
    !isSelectTouchGroup && setTimeout(() => {
      if (isLongPress && !isMoving) {
        handleContextMenu(e)
      }
    }, 1000)
  },[x,y,isSelectTouchGroup])

  const handleTouchMove = useCallback(() => {
    isMoving = true
  },[x,y,isSelectTouchGroup])

  const handleTouchEnd = useCallback((e:any) => {
    isLongPress = false
  },[x,y,isSelectTouchGroup])

    const handleMouseDown = (e: any, mode: string) => {
        const container = e.target.getStage().container();
        container.style.cursor = rotation === 0 ? "ns-resize" : "ew-resize";
        setIsResizing(true);
    };

    const handleMouseMove = (e: any, mode: string) => {
        if (!isResizing) return;
        setHeightTransform(e.evt.movementY);
    };

    const handleMouseUp = (e: any, mode: string) => {
        setTransformFlag(true);
        const container = e.target.getStage().container();
        container.style.cursor = "default";
    
        let newPosX = e.target._lastPos.x // e.evt.offsetX
        let newPosY = e.target._lastPos.y // e.evt.offsetY
    
        let newEle = calcElementSize(
            newPosX,
            newPosY,
            groupPosition,
            rotation,
            height,
            offsetY,
            mode,
            gridSizeController
        )
    
        topRef.current.y(0);
        // bottomRef.current.y(newHeight - 7)
        setGroupPosition({ x: newEle.newX, y: newEle.newY });
    
        updateControlHeight(id, newEle.newHeight, newEle.newX, newEle.newY, newEle.newOffsetY);
        setIsResizing(false);
    };

    const convertDataFromBE = (BEData : any) =>{
        let voltageList: OptionModel[] = [];
        if (BEData.voltageList && BEData.voltageList.length > 0){
          BEData.voltageList.forEach((element: any) => {
            if(element.lvoltage !== null){
              let optionModel: OptionModel = {
                  label: element.lvoltage.toString(),
                  value: element.lvoltage
              };
              voltageList.push(optionModel)
            }
          });
    
      }
        return {
          voltageList: voltageList
      }
      }
      
      const handleGetSuccess = (data: any) => {
        let newData = data.data
        let initData = {...initialValue}
        
        initData = addListToData(initData, newData)
        let convertData = convertDataFromBE(initData)
        initData.voltageList = convertData.voltageList
        const makerNameList:any = [];
        newData.makerNameList?.forEach((item:any)=>{
            makerNameList.push({label:item.smanuName.toString(),
                value:item.smanuName} as OptionModel)
        })
        const makeYearList:any = [];
        newData.makeYearList?.forEach((item:any)=>{
            makeYearList.push({label:item.toString(),
                value:item} as OptionModel)
        })
        const makeMonthList:any = [];
        newData.makeMonthList?.forEach((item:any)=>{
            makeMonthList.push({label:item.toString(),
                value:item} as OptionModel)
        })
        initData.makerNameList = makerNameList
        initData.makeMonthList = makeMonthList
        initData.makeYearList = makeYearList

      let shapes = diagramData[0].shape
      //Transcenter source voltage
      let result ={} as any 
      if(currentTabId !== 1){
      //inside transcenter
        result = doGetTransCenterSourceVolt(
        shapes,
        {
          id: id,
          image: image,
          x: x,
          y: y,
          offsetX: offsetX,
          offsetY: offsetY,
          width: width,
          height: height,
          type: type,
          isSelected: isSelected,
          rotation: rotation,
          chart: chart,
          isRotationEnabled: false,
          parentGroupId,
          properties: properties
        },
        Constant.GET_TOP_BOTTOM_LEFT_RIGHT,

      )
      }
      else{
      //outside transcenter
       result = doGetSourceVolt(
        {
          id: id,
          image: image,
          x: x,
          y: y,
          offsetX: offsetX,
          offsetY: offsetY,
          width: width,
          height: height,
          type: type,
          isSelected: isSelected,
          rotation: rotation,
          chart: chart,
          isRotationEnabled: false,
          parentGroupId,
          properties: properties
        },
        shapes,
        Constant.GET_TOP_BOTTOM_LEFT_RIGHT

      )
    }

      
      if(result.voltage > 0){
        initData.isSourceVoltage = true
      }
      else{
        initData.isSourceVoltage = false
      }
    
        setInitialValue(initData)
        setLoadingFlag(false)
        setIsOpenEditorDialog(true)
      }
      const handleAddUserCurve = async (controlId:string,data:any)=>{
        const request = { 
          userId: userId,
          projectId: projectData.projectId,
          elementId: parseInt(controlId),
          params:{...data},
          ownerProject: projectData.ownerId

        }
        // addUserCurveDB(controlId,request)
        const result = await post("/diagram/add-curve-user", request);
        if (result.success) {
        handleAddCurveUserSuccess(id, result.data);
        } else {
        handleSubmitError(result.error);
        }
      }
      const handleUpdateUserCurve = async (controlId:string,data:any) =>{
        const request = { 
          userId: userId,
          projectId: projectData.projectId,
          elementId: parseInt(controlId),
          params:{...data},
          ownerProject: projectData.ownerId

        }
        // updateUserCurveDB(controlId,request)
        const result = await post("/diagram/edit-curve-user", request);
        if (result.success) {
        handleUpdateCurveUserSuccess(id, result.data);
        } else {
        handleSubmitError(result.error);
        }
      }
      const handleDeleteUserCurve = async (controlId:string,data:any) =>{
        const request = { 
          userId: userId,
          projectId: projectData.projectId,
          elementId: parseInt(controlId),
          userCurveId: data["userCurveId"],
          ownerProject: projectData.ownerId

        }
        // deleteUserCurveDB(controlId,request)
    	deleteRelatedGraphByUserCurveDialog({controlId, data: data["userCurveId"], mode : true})
        const result = await post("/diagram/delete-curve-user", request);
        if (result.success) {
        handleDeleteCurveUserSuccess(id, result.data);
        } else {
        handleSubmitError(result.error);
        }
      }
      const handleAddCurveUserSuccess = (controlId:string,data:any) =>{
        addUserCurve(controlId,data)
        setListUserCurves(prev => [...prev, data])
      }
      const handleUpdateCurveUserSuccess =(controlId:string,data:any) =>{
        const foundUserCurve = listUserCurves.findIndex((curve:any) => curve.userCurveId === data["userCurveId"])
        const newList = [...listUserCurves]
        newList[foundUserCurve] = data
        updateUserCurve(controlId,data)
        setListUserCurves(newList)
    
      }
      const handleDeleteCurveUserSuccess = (controlId:string,data:any) =>{
          const newList = [...listUserCurves]
          newList.splice(newList.findIndex((curve : any) => curve.userCurveId === data), 1)
          let params = {
            userCurveId: data,
            projectId: projectData.projectId,
          }
          deleteUserCurve(controlId,params)
          setListUserCurves([...newList])
    
      }
    //#endregion

    return (
    <>
        {isOpenMenu &&
            <OptionMenu
                controlId={id}
                isOpenMenu={isOpenMenu}
                setIsOpenMenu={handleOpenMenu}
                onProperties={handleOpenEditorDialog}
                x={menuPosition.x}
                y={menuPosition.y}
                isDisabledDelete={false}
                isDisabledCut={false}
                isDisabledCopy={false}
                isDisabledPaste={false}
                isDisabledOpenGraph={false}
                isDisabledProps={false}
                onUserCurves={() => setIsOpenUserCurvesDialog(true)}
            />
        }

        <Label
            ref={labelRef}
            listening={false}
            width={Constant.LABEL_WIDTH}
            x={labelPosition.x}
            y={labelPosition.y}
            offsetX={[90, -90].includes(rotation) ? 8 : -5}
            offsetY={
            rotation === 0 ? (labelTexts.length * Constant.LINE_HEIGHT) / 2 : 0
            }
        >
            {labelTexts.length > 0 &&
            labelTexts.map((item:any, index:any) => {
                
            let content = item.value; 
            let offsetY = index * Constant.LINE_HEIGHT;
            rotation !== 0 &&
                index !== 0 &&
                (offsetY = (index - 1) * Constant.LINE_HEIGHT + width + Constant.ELEMENT_SIZE);

            return (
                <Text
                y={offsetY}
                key={index}
                text={content}
                fill={isSelected ? "red" : item.color}
                />
            );
            })}
        </Label>
        <Group
            ref={groupRef}
            id={id}
            x={groupPosition.x}
            y={groupPosition.y}
            offset={{ x: offsetX, y: offsetY }}
            width={width}
            height={height}
            draggable={processMode === ProcessMode.DRAWING && !m_bModePM && !modeViewOnly && !isOnShiftKey}
            type={type}
            onClick={handleClick}
            onTap={handleClick}
            onContextMenu={handleContextMenu}
            rotation={rotation}
            onDblClick={handleOpenEditorDialog}
            onDblTap={handleOpenEditorDialog}
            onMouseDown={() =>
                transformMoveRef.current?.nodes([groupRef.current])
            }
            onMouseUp={() => transformMoveRef.current?.nodes([])}
            onDragStart={handleDragStart}
            onDragMove={handleDragmove}
            onDragEnd={onDragEnd}
            onMouseLeave={(e: any) => {
                const container = e.target.getStage().container();
                container.style.cursor = "default";
            }}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
        >
            <Image
                fill={getSymbolAndBackColor(infoSkeleton,processMode,properties).backColor}
                image={lineObj}
                height={height}
                onMouseEnter={(e: any) => {
                    const container = e.target.getStage().container();
                    container.style.cursor = (processMode === ProcessMode.DRAWING && !m_bModePM && !modeViewOnly) ? "move" : "crosshair";
                }}
            />
            <Image
                fill={getSymbolAndBackColor(infoSkeleton,processMode,properties).backColor}
                image={imageObj}
                width={Constant.ELEMENT_SIZE}
                height={Constant.ELEMENT_SIZE}
                x={1}
                y={height / 2 - 10}
                onMouseEnter={(e: any) => {
                    const container = e.target.getStage().container();
                    container.style.cursor = (processMode === ProcessMode.DRAWING && !m_bModePM && !modeViewOnly) ? "move" : "crosshair";
                }}
            />

            {processMode === ProcessMode.DRAWING && !m_bModePM && !modeViewOnly && isSelected && !parentGroupId && (
                <>
                    <Rect
                        ref={topRef}
                        fill="black"
                        x={6.75}
                        y={0}
                        width={7}
                        height={7}
                        draggable
                        onMouseDown={() => setTransformFlag(false)}
                        dragBoundFunc={(e: any) => dragBoundFunc(e, "top")}
                        onDragStart={(e: any) => handleMouseDown(e, "top")}
                        onDragMove={(e: any) => handleMouseMove(e, "top")}
                        onDragEnd={(e: any) => handleMouseUp(e, "top")}
                        onMouseEnter={(e: any) => {
                            const container = e.target.getStage().container();
                            container.style.cursor = "ns-resize";
                        }}
                    />
                    <Rect
                        ref={bottomRef}
                        fill="black"
                        x={6.75}
                        y={height - 7}
                        width={7}
                        height={7}
                        draggable
                        onMouseDown={() => setTransformFlag(false)}
                        dragBoundFunc={(e: any) => dragBoundFunc(e, "bottom")}
                        onDragStart={(e: any) => handleMouseDown(e, "bottom")}
                        onDragMove={(e: any) => handleMouseMove(e, "bottom")}
                        onDragEnd={(e: any) => handleMouseUp(e, "bottom")}
                        onMouseEnter={(e: any) => {
                            const container = e.target.getStage().container();
                            container.style.cursor = "ns-resize";
                        }}
                    />
                </>
            )}
        </Group>

        { showPowerInfo &&
            <>
            <Image  
                image={imageSVGActivePower} 
                listening={false}
                x={arrowPosition.x + svgPosX1}
                y={arrowPosition.y + svgPosY1}
            />
    
            <Image  
                image={imageSVGReactivePower} 
                listening={false}
                x={arrowPosition.x + svgPosX2}
                y={arrowPosition.y + svgPosY2}
            />
            </>
            
        }
        { showPowerDirectInfo &&
            <>
            <Image  
                image={imageSVGActiveReactivePower} 
                listening={false}
                x={arrowPosition.x + svgPosX3}
                y={arrowPosition.y + svgPosY3}
            />
            </>
            
        }

        <Transformer
            ref={transformRef}
            rotateEnabled={false}
            resizeEnabled={false}
            keepRatio={false}
            borderDash={[4]}
            anchorStroke="black"
            borderStroke="black"
            anchorFill="black"
        />

        <Transformer
            ref={transformMoveRef}
            rotateEnabled={false}
            resizeEnabled={false}
            keepRatio={false}
            borderEnabled={false}
            anchorStroke="black"
            borderStroke="black"
            anchorFill="black"
        />

        {isOpenEditorDialog && !isHidden && (
            <Html>
                <Dialog open={true} fullWidth PaperComponent={DraggablePaper}>
                <DialogTitle
                    onMouseOver={(e : any) => e.target.style.cursor = 'move'}
                    style={{
                        paddingTop: "2px",
                        paddingBottom: "2px",
                        background: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                    }}
                >
                    零相変流器
                </DialogTitle>
                <DialogContent sx={{ pb: 1.5 }}>
                    <MsZCTEditDialog
                        data={initialValue}
                        onCancel={handleCancelEditorDialog}
                        onOK={handleUpdate}
                        userRole={userRole}
                        m_bModePM={m_bModePM}
                        modeViewOnly={modeViewOnly}
                    />
                </DialogContent>
                </Dialog>
            </Html>
        )}

        {/* ============== USER CURVE ============== */}
        {isOpenUserCurvesDialog && !isHidden && (
        <Html>
        <UserCurvesDialog
            type={type}
            addUserCurve={handleAddUserCurve}
            updateUserCurve={handleUpdateUserCurve}
            deleteUserCurve={handleDeleteUserCurve}
            listUserCurves ={listUserCurves}
            controlId={id}
            controlProperties={properties}
            modeViewOnly={modeViewOnly}
            onCancel={() => {setIsOpenUserCurvesDialog(false); openUserCurveEvent('')}}
            onDraw={(data : any) => {  
                setOpenChart(true, LineType.USER_CURVE, data.userCurveId??data.user_curve_id)
                setIsOpenUserCurvesDialog(false)
            }}
        />
        </Html>
        )}

        {isOpenUserCurveProperties && !isHidden &&
            <Html>
                <UserCurveDetailDialog modeViewOnly={modeViewOnly} type={type} selectedRow={selectItem} properties={properties} onOK={handleOKUserCurveDetailForm} onCancel={handleCancelUserCurveDetailForm}></UserCurveDetailDialog>
            </Html>
        }

        {loadingFlag && (
            <Html>
                <Dialog open={true} maxWidth={"md"} fullWidth>
                    <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1500, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                        <CircularProgress />
                    </div>
                </Dialog>
            </Html>
        )}

      </>
    );
});

const mapStateToProps = (state: any) => {
    return {
        userId: state.app.user?.userId,
        projectData:  state.app.projectData,
        processMode: state.app.diagram.processMode,
        diagramData: state.app.diagram.diagramData.filter((r: any) => r.tabId === state.app.diagram.currentIDDiagramTab),
        mainDiagramData: state.app.diagram.diagramData.find((r: any) => r.tabId === 1),
        currentTabId: state.app.diagram.currentIDDiagramTab,
        openDialogData : state.app.diagram.openDialog["OPEN_DIALOG"],
        openUserCurveData: state.app.diagram.openUserCurve["OPEN_USER_CURVE"],
        userCurveProperties: state.app.diagram.openUserCurveProperties["OPEN_USER_CURVE_PROPERTIES"],
        clearMenuState: state.app.diagram.clearOptionMenu,
        userRole: state.app.user?.userRole,
        m_bModePM: state.app.diagram.m_bModePM,
        modeViewOnly: state.app.diagram.modeViewOnly,
        infoSkeleton: state.app.diagram.infoSkeleton,
        diagramDataUpdate: state.app.diagram.diagramDataUpdate,
        drawPowerArrow: state.app.diagram.isDrawPowerArrow,
        isSelectTouchGroup: state.app.diagram.isSelectTouchGroup,
        tcGroups: state.app.diagram.tcGroups,
        elementGroups: state.app.diagram.elementGroups,
        elementReposition: state.app.diagram.elementReposition,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        updateSelectControl: (controlId: string, isMultiple : boolean = false) =>
            dispatch(updateSelectControl(controlId, isMultiple)),
        updateControlData: (id: string, data: object) =>
            dispatch(updatePropertiesOfControlAction(id, data)),
        updateTransCenterProps: (id: string, data: object) =>
            dispatch(updatePropertiesOfTransCenter(id, data)),
        updateControlHeight: (
            id: string,
            height: number,
            x: number,
            y: number,
            offsetY: number
        ) => dispatch(updateControlHeight(id, height, x, y, offsetY)),
        setTransformFlag: (visible: boolean) =>
            dispatch(setTransformFlag(visible)),
        // updateElementPost: (data: any, controlId: string) => dispatch(actionCreators.fetch(`UPDATE_ZCT${controlId}`, "/diagram/set-param", "POST", data, false, true)),
        // getElementParam: (data: any, controlId: string) => dispatch(actionCreators.fetch(`GET_ZCT${controlId}`, "/diagram/get-param", "POST", data, false, true)),
        addUserCurve: (controlId : string, data : any) => dispatch(addUserCurve(controlId, data)),
        updateUserCurve: (controlId : string, data : any) => dispatch(updateUserCurve(controlId, data)),
        deleteUserCurve: (controlId : string, data : any) => dispatch(deleteUserCurve(controlId, data)),
        setOpenChart: (isOpenChart: boolean, lineType : string, lineId : number) => dispatch(openChart(isOpenChart, lineType, lineId)),
        clearOptionMenu: (state:boolean) => dispatch(clearOptionMenu(state)),
        // addUserCurveDB: (controlId:string,data : any) => dispatch(actionCreators.fetch(`ADD_CURVE_USER_ZCT${controlId}`, "/diagram/add-curve-user", "POST", data, false, true)),
        // updateUserCurveDB: (controlId:string,data : any) => dispatch(actionCreators.fetch(`UPDATE_CURVE_USER_ZCT${controlId}`, "/diagram/edit-curve-user", "POST", data, false, true)),
        // deleteUserCurveDB: (controlId:string,data : any) => dispatch(actionCreators.fetch(`DELETE_CURVE_USER_ZCT${controlId}`, "/diagram/delete-curve-user", "POST", data, false, true)),
        openDialogEvents: (param : any) => dispatch(openDialogEvents(param)),
        openUserCurveEvent: (params: any) => dispatch(openUserCurveEvents({type: "OPEN_USER_CURVE", data: { id: params }})),
        openUserCurvePropertiesEvent: (controlId: any, userCurveId: any) => dispatch(openUserCurvePropertiesEvents({type: "OPEN_USER_CURVE_PROPERTIES", data: { controlId: controlId, userCurveId: userCurveId }})),
    	deleteRelatedGraphByUserCurveDialog: (params:any) => dispatch(deleteRelatedGraphByUserCurveDialog(params)),
        setUndoData: (data:UndoModel) => dispatch(undo(data)),
        setRepositionElement: (id:any, state:boolean) => dispatch(setRepositionElement(id, state)),
        updateElementPosition:(id:any, x:any, y:any) => dispatch(updateElementPosition(id, x, y)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateMsZCT);