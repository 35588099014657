import { CdPoint, GraphLineData, MsCapacitorModel, Point } from "../../../models/Index";

const doDrawCurrveCapacitor =(props:MsCapacitorModel,nFrequency:number) =>{
    let graphLineData : GraphLineData = { lines: [] }
    const m_listDrawInrush = [] as CdPoint[]
    const inrushPoints = [] as Point[]
    if(doMakeCurveInrushCapacitor(m_listDrawInrush,props,nFrequency)){
        m_listDrawInrush.forEach(point => inrushPoints.push({x: point.m_dx, y:point.m_dy} as Point))
        graphLineData.lines.push({
            points: inrushPoints,
            id: 'INRUSH'
        });
    }

    return graphLineData;
}
const doMakeCurveInrushCapacitor =(m_listDrawInrush:Array<CdPoint>,props:MsCapacitorModel,nFrequency:number) =>{
    let m_lVoltage:number = props.voltage;
    let m_dLoadFactor:number = props.loadFactor;
    let m_dCapacity:number = props.capacity;
    // let nFrequency:number = 50;//not correct
    let dOhmR:number  = props.totalOhmR;
    let dOhmX:number = props.totalOhmX;
    let m_dReactor:number = props.reactor;
    
    let dRatedCurrent = doGetValueRatedCurrent(m_lVoltage,m_dLoadFactor*m_dCapacity);
    let dStaticCapacity = doGetStaticCapacity(nFrequency,m_lVoltage,m_dLoadFactor*m_dCapacity);
    let dOhmZ = doGetValueOhmZ(dOhmR,dOhmX);
    let dPeakTime = doGetPeakTime(nFrequency,m_lVoltage,m_dReactor,dRatedCurrent,dStaticCapacity,dOhmZ,5);
    let dRushCurrent = doGetPeakCurrent(nFrequency,m_lVoltage,m_dReactor,dRatedCurrent,dStaticCapacity,dOhmZ,5);
    dRatedCurrent *= m_lVoltage;
    dRushCurrent *= m_lVoltage;
    dPeakTime /=1000;
    if(dRushCurrent <= 0 || dPeakTime <=0){
        return false;
    }

    let pdPoint: CdPoint ={
        m_dx : 0,
        m_dy : 0
    }
    pdPoint.m_dx = dRatedCurrent;
    pdPoint.m_dy = dPeakTime * 2 * 1000;
    m_listDrawInrush.push({...pdPoint});

    pdPoint.m_dx = dRatedCurrent;
    pdPoint.m_dy = dPeakTime * 2
    m_listDrawInrush.push({...pdPoint});

    pdPoint.m_dx = dRatedCurrent + dRatedCurrent /30;
    pdPoint.m_dy = dPeakTime * 2 - dPeakTime * 2 /20;
    m_listDrawInrush.push({...pdPoint});

    pdPoint.m_dx = dRatedCurrent + dRatedCurrent /10;
    pdPoint.m_dy = dPeakTime * 2 - dPeakTime * 2 /9;
    m_listDrawInrush.push({...pdPoint});

    pdPoint.m_dx = dRushCurrent - dRushCurrent /10;
    pdPoint.m_dy = dPeakTime + dPeakTime /5;
    m_listDrawInrush.push({...pdPoint});

    pdPoint.m_dx = dRushCurrent - dRushCurrent /40;
    pdPoint.m_dy = dPeakTime + dPeakTime /10;
    m_listDrawInrush.push({...pdPoint});

    pdPoint.m_dx = dRushCurrent;
    pdPoint.m_dy = dPeakTime ;
    m_listDrawInrush.push({...pdPoint});

    pdPoint.m_dx = dRushCurrent;
    pdPoint.m_dy = dPeakTime * 0.5;
    m_listDrawInrush.push({...pdPoint});

    pdPoint.m_dx = dRushCurrent - dRushCurrent /60;
    pdPoint.m_dy = dPeakTime *0.5  - dPeakTime*0.5 /6;
    m_listDrawInrush.push({...pdPoint});

    pdPoint.m_dx = dRushCurrent - dRushCurrent /10;
    pdPoint.m_dy = dPeakTime *0.5  - dPeakTime*0.5 /4;
    m_listDrawInrush.push({...pdPoint});
 
    pdPoint.m_dx = dRatedCurrent + dRatedCurrent /6;
    pdPoint.m_dy = dPeakTime /10 + dPeakTime / 10 / 30;
    m_listDrawInrush.push({...pdPoint});

    pdPoint.m_dx = dRatedCurrent;
    pdPoint.m_dy = dPeakTime /10;
    m_listDrawInrush.push({...pdPoint});

    pdPoint.m_dx = dRatedCurrent/10;
    pdPoint.m_dy = dPeakTime /10;
    m_listDrawInrush.push({...pdPoint});

return true;

}
const doGetValueRatedCurrent = (m_lVoltage:number,dCapacity:number) =>{
    let dRatedCurrent = 0;
    if(m_lVoltage <=0){
        dRatedCurrent = 0;
    }
    else{
        dRatedCurrent = dCapacity * 1000 / m_lVoltage / Math.sqrt(3.0);
    }

    return dRatedCurrent;
}
const doGetStaticCapacity =(nFrequency:number,m_lVoltage:number,m_dCapacity:number)=>{
    let dStaticCapacity = 0;
    const MS_PI = 3.14159265358979;
    if(m_lVoltage <= 0 || nFrequency <=0){
         dStaticCapacity = 0;
    }
    else{
        dStaticCapacity = m_dCapacity * 1000 / m_lVoltage / m_lVoltage/(2 * MS_PI * nFrequency)
    }
    return dStaticCapacity;
}
const doGetValueOhmZ = (dOhmR:number,dOhmX:number)=>{
    const dOhmZ = Math.sqrt(dOhmR * dOhmR + dOhmX *dOhmX);
    return dOhmZ;
}
const doGetValueOhmR =(dOhmZ:number,dXLR:number)=>{
    const result = Math.sqrt(1 + Math.pow(dXLR,2));
    if(result === 0){
        return 0;
    }
    const dOhmR = dOhmZ / result;
    return dOhmR;
}
const doGetPeakTime =(nFrequency:number,m_lVoltage:number,m_Reactor:number,dRateCurrent:number
    ,dStaticCapacity:number,dOhmZ:number,dXLR:number) =>{
        if(nFrequency <=0||dStaticCapacity <=0){
            return 0;
        }
        const dOhmR = doGetValueOhmR(dOhmZ,dXLR);
        if(dOhmR === 0){
            return 0;
        }
        const dTotalHerryL = doGetTotalHenryL(nFrequency,m_lVoltage,dRateCurrent,m_Reactor,dOhmR,dXLR)
        const dConstant = doGetValueConstant(dStaticCapacity,dOhmR,dTotalHerryL);
        if(dConstant === 0){
            return 0;
        }
        const dPeakTime = 2 *dTotalHerryL /dOhmR / dConstant * Math.atan(dConstant) * 1000;
        return dPeakTime;
    
    
    }
const doGetTotalHenryL =(nFrequency: number,m_lVoltage: number,dRateCurrent: number,m_Reactor: number,dOhmR: number,dXLR: number)=>{
    const MS_PI = 3.14159265358979;
    const RADIUS = 2 * MS_PI * nFrequency;
    if(nFrequency <=0 || dRateCurrent <=0){
        return 0;
    }
    const dHenrryL1 = (m_lVoltage/Math.sqrt(3.0)/dRateCurrent*1000)*(m_Reactor/100)/(RADIUS);
    const dHenrryL2 = dOhmR *dXLR / RADIUS;
    const dTotalHerryL = dHenrryL1 +dHenrryL2;
    return dTotalHerryL;
}
const doGetValueConstant =(dStaticCapacity:number,dOhmR:number,dHerryL:number)=>{
    if(dOhmR ===0){
        return 0;
    }
    const dConstant = Math.sqrt(4 * dHerryL * 1000 / dStaticCapacity - dOhmR * dOhmR )/ dOhmR;
    return dConstant;

}
const doGetPeakCurrent =(nFrequency:number,m_lVoltage:number,m_dReactor:number,dRatedCurrent:number,dStaticCapacity:number,dOhmZ:number,dXLR:number) =>{
    if(nFrequency <=0 ||dStaticCapacity <=0 || m_lVoltage <= 0 || dOhmZ <=0){
        return 0;
    }
    const dEm = Math.sqrt(2.0) * m_lVoltage / Math.sqrt(3.0);
    const dOhmR = doGetValueOhmR(dOhmZ,dXLR);
    const dTotalHerryL = doGetTotalHenryL(nFrequency,m_lVoltage,dRatedCurrent,m_dReactor,dOhmR,dXLR);
    const dConstant = doGetValueConstant(dStaticCapacity,dOhmR,dTotalHerryL);
    if(dConstant === 0|| dTotalHerryL ===0){
        return 0;
    }
    let dPeakCurrent = dEm/Math.sqrt(dTotalHerryL * 1000 /dStaticCapacity) * Math.exp(-1 /dConstant * Math.atan(dConstant)) *1000;
        dPeakCurrent /=Math.sqrt(2.0);
    return dPeakCurrent;
}
const getMsCapacitorInrushLabel = (props : MsCapacitorModel, infos : string[],dNominalVolt:number) => { 
    const name = props.refNo;
    const m_dCapacity = props.capacity;
    const m_lVoltage = props.voltage;
    const m_dLoadFactor = props.loadFactor;
    const m_dReactor = props.reactor;
    infos.push(name);
    infos.push(m_dCapacity.toString() +`kvar`);
    let dRateCurrent = doGetValueRatedCurrent(m_lVoltage,m_dCapacity * m_dLoadFactor);
    if(m_lVoltage === dNominalVolt){
        infos.push(Math.round(dRateCurrent * 10) /10 +`A`);
    }
    else{
        infos.push(Math.round(dRateCurrent * m_lVoltage /dNominalVolt).toString()+`A` +`(`+Math.round(dRateCurrent) +`)`);
        
    }
    if(m_dReactor > 0){
        infos.push(Math.round(m_dReactor) +`%`)
    }
}

export{
    doDrawCurrveCapacitor,
    getMsCapacitorInrushLabel
}