import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, Typography } from "@mui/material";
import { connect } from "react-redux";
import { Message } from "../../../models/Index";
import { actionCreators, ApplicationState, } from "../../../store";
import React from "react";
import FetchHelper from "../../FetchHelper";

type ReflectMasterProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

export const ReflectMaster = (props: ReflectMasterProps) => {
    const{onCopyData,onSuccess,onError} = props
    const [loadingFlag,setLoadingFlag] = React.useState(false);
    const [openDialog,setOpenDialog] = React.useState(false);

    const handleOK = () => {
        onCopyData({
            "userId": 1,
            "requestPrms":{}
        });
        setLoadingFlag(true);
        setOpenDialog(false);
    }

    const handleSuccess = (data:any)=>{
        data.result_code == 0 ? onSuccess() : onError()
        setLoadingFlag(false)
    }

    const handleError =(success:any, data:any, error:any) =>{
        onError();
        setLoadingFlag(false);
        setOpenDialog(false)
    }

    const handleOpenDialog =(openDialog:boolean) =>{
        setOpenDialog(openDialog)
    }

    return (
        <>
            {loadingFlag && (
                <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1500, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                    <CircularProgress />
                </div>
            )}
            {
                openDialog &&
                <>
                    <Dialog
                        open={openDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        onKeyDown={(e:any) => { e.preventDefault(); e.key === "Enter" && handleOK()}}
                    >
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                本番環境へマスタデータを反映します。
                                <br/>
                                よろしいですか？
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleOK} variant="contained">はい</Button>
                            <Button onClick={() => setOpenDialog(false)} color="inherit" variant="contained">キャンセル</Button>
                        </DialogActions>
                    </Dialog>
                </>
            }
            <div style={{ height: '100%', width: '100%', marginTop: '10px' }}>
                <Stack direction="row" justifyContent="flex-start" alignItems="center" style={{ margin: '18px', color: 'MenuText' }}>
                    <Typography variant="h4">
                        データ反映
                    </Typography>
                </Stack>
                <Box ml={5}>
                    <Typography sx={{fontWeight:"10px",color:"black"}}>※マスタデータを本番環境へ反映します</Typography>
                </Box>
                <Box ml={5} mt={1}>
                    <Button variant="contained" onClick={() => handleOpenDialog(true)}>本番環境へマスタデータを反映する</Button>
                </Box>
            </div>

            <FetchHelper fetchId={"COPY_DATABASE"} onComplete={(success, data, error) =>
                success ? handleSuccess(data) : handleError(success, data, error)} />
        </>
    );
};

const mapStateToProps = (state: ApplicationState) => ({
    
});

const mapDispatchToProps = (dispatch: any) => {
    return {
        onShowMessage: (message: Message) => dispatch(actionCreators.showMessage(message)),
        onCopyData: (data: any) => dispatch(actionCreators.fetch("COPY_DATABASE", `/master/copy_database/copy`, "POST", data,false,true)),
        onSuccess: () =>
            dispatch(actionCreators.showMessage({
                type: "info",
                title: "本番環境へのマスタデータ",
                body: "本番環境へのマスタデータ反映に成功しました",
            })),
        onError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "本番環境へのマスタデータ",
                body: "本番環境へのマスタデータ反映に失敗しました。",
            })),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReflectMaster);
