import { Button } from '@mui/material';
import { connect } from "react-redux";
import './styles.css';
import { useState } from 'react';
import { Coordinates, GapLine } from '../../models/Index';
import { ApplicationState } from '../../store';
import CheckIcon from '@mui/icons-material/Check';

//#region Props
type ChartMenuCoordinates = {
    gapLine: GapLine,
    handleContextMenuClick: any,
    isHittedCurve: boolean,
    isUserCurve: boolean,
    x: number,
    y: number,
    lineDiagramRef: any,
    isGraphText?:boolean,
    m_bModePM: boolean,
    modeViewOnly: boolean,
};

export type ChartMenuProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & ChartMenuCoordinates;
//#endregion


const ChartMenu = (props: ChartMenuProps) => {
    //#region Fields
    const { x, y, gapLine, isHittedCurve, isUserCurve, isJapaneseElectricMode, lineDiagramRef,isGraphText, handleContextMenuClick, m_bModePM, modeViewOnly } = props;
    const beyondWidth = x > 300
    const beyondHeight = y + 285 > lineDiagramRef.current.clientHeight
    const position = {
        x: beyondWidth ? (isHittedCurve? x - 250 : x - 300 ) : x,
        y: beyondHeight ?  (isHittedCurve? y - 135 : y - 235 ): y,
    };
    const style = {
        justifyContent: "space-between",    
        padding: '4px 8px',
        "&.Mui-disabled": { color: "#a6a6a6" },
        "&:hover": { backgroundColor: "#e6f7ff" }
    }
    //#endregion

    const onContextMenu = (e: any) => {
        e.preventDefault()
    }
    
    return (
        <>
                <div
                    onContextMenu={onContextMenu}
                    style={{
                        position: "absolute",
                        top: position.y,
                        left: position.x,
                        backgroundColor: "white",
                        zIndex: 9999,
                        width: isHittedCurve? '250px' :'300px',
                        boxShadow: '5px 5px 10px grey',
                        border: "0.7px solid grey",
                        textAlign: 'left'
                    }}
                >
                    {
                        isHittedCurve || isGraphText == true? 
                        <>
                            <Button disabled={isGraphText || m_bModePM} fullWidth color='inherit' sx={style} onClick={handleContextMenuClick.onOpenUserCurve}>
                                ユーザー曲線リスト  
                            </Button>
                            <Button disabled={(!isUserCurve || isGraphText) || m_bModePM} fullWidth color='inherit' sx={style} onClick={handleContextMenuClick.onOpenUserCurveProperties}>
                                ユーザー曲線の編集
                            </Button>
                            <hr className='menu-option-hr-style' />
                            <Button disabled={modeViewOnly} fullWidth color='inherit' sx={style} onClick={handleContextMenuClick.onDeleteLine}>
                                削除
                            </Button>
                            <hr className='menu-option-hr-style' />
                            <Button fullWidth color='inherit' sx={style} onClick={isGraphText? handleContextMenuClick.onOpenPropertiesGraphText : handleContextMenuClick.onOpenProperties}>
                                プロパティ
                            </Button>
                        </>
                        :
                        <>
                            <Button fullWidth color='inherit' sx={style} onClick={handleContextMenuClick.onCreateMsText} disabled={modeViewOnly}>
                                テキストボックスの配置
                            </Button>
                            <hr className='menu-option-hr-style' />
                            <Button disabled={isJapaneseElectricMode} fullWidth color='inherit' sx={style}　onClick={handleContextMenuClick.onSetUpGraphScale}>
                                グラフスケールの調整
                            </Button>
                            <hr className='menu-option-hr-style' />
                            <Button endIcon={gapLine.dispGapLine ? <CheckIcon /> : null} disabled={isJapaneseElectricMode} fullWidth color='inherit' sx={style} onClick={handleContextMenuClick.onDiffenceMeasurement}>
                                電流差/時間差の計測
                            </Button>
                            <hr className='menu-option-hr-style' />
                            <Button disabled={gapLine.dispGapLine} endIcon={isJapaneseElectricMode ? <CheckIcon /> : null} fullWidth color='inherit' sx={style} onClick={handleContextMenuClick.onJapanElectric}>
                                日本電気協会仕様
                            </Button>
                            {/* <Button disabled={!isJapaneseElectricMode} fullWidth color='inherit' sx={style} onClick={handleContextMenuClick.onDownloadPDF}>
                                保護協調図のみの印刷
                            </Button>
                            <Button disabled={!isJapaneseElectricMode} fullWidth color='inherit' sx={style} onClick={handleContextMenuClick.onReviewPDF}>
                                保護協調図のみの印刷プレビュー
                            </Button> */}
                            <hr className='menu-option-hr-style' />
                            <Button fullWidth color='inherit' sx={style} onClick={handleContextMenuClick.onChangeGraphDispBand} disabled={modeViewOnly}>
                                バンド/中心値表示の切替
                            </Button>
                        </>
                    }
                    
                </div>
        </>
    );
};
const mapStateToProps = (state: ApplicationState) => {
    return {
        isJapaneseElectricMode: state.app.diagram.chartData.find(chartTab => chartTab.tabId === state.app.diagram.currentIDChartTab)?.isJapaneseElectricMode,
    };
};
const mapDispatchToProps = (dispatch: any) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(ChartMenu);