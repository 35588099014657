import RectangleIcon from '@mui/icons-material/Rectangle';
import { Button, Checkbox, Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FontFormatType } from '../../models/Index';
import DraggablePaper from '../common/DraggablePaper';
import theme from "../../themes/globalStyles";
import { getFontStyleValue, getTextDecoration } from '../../utils/formatText';

export type FormatTextFormProps = {
    data: FontFormatType;
    modeViewOnly: boolean;
    onOK: (data: any) => void;
    onCancel: () => void;
};

type TextEditorForm = {
    fontSize: number;
    fontStyle: string;
    faceName : string;
    colorFont : string;
    underline : boolean;
    strikeOut : boolean;
    italic : boolean;
    weight : number;
    charSet : number;
}

const fontFamilyOptions = [
    {
        label: "Noto Sans Japanese",
        value: "Noto Sans JP",
        hasFontStyle: true
    },
    {
        label: "Noto Serif Japanese",
        value: "Noto Serif JP",
        hasFontStyle: true
    },
    // {
    //     label: "Meiryo UI",
    //     value: "Meiryo UI",
    //     hasFontStyle: false
    // },
    // {
    //     label: "ＭＳ Ｐゴシック",
    //     value: "ＭＳ Ｐゴシック",
    //     hasFontStyle: false
    // },
    // {
    //     label: "ＭＳ Ｐ明朝",
    //     value: "ＭＳ Ｐ明朝",

    // },
    // {
    //     label: "MS UI Gothic",
    //     value: "MS UI Gothic",
    //     hasFontStyle: false
    // },
    // {
    //     label: "MS ゴシック",
    //     value: "MS ゴシック",
    //     hasFontStyle: false
    // },
    // {
    //     label: "ＭＳ 明朝",
    //     value: "ＭＳ 明朝",
    //     hasFontStyle: false
    // },
    // {
    //     label: "メイリオ",
    //     value: "メイリオ",
    // },
    // {
    //     label: "Calibri",
    //     value: "Calibri",
    //     hasFontStyle: true
    // },
    // {
    //     label: "Cambria",
    //     value: "Cambria",
    //     hasFontStyle: true
    // },
    // {
    //     label: "Cambria Math",
    //     value: "Cambria Math",
    // },
    // {
    //     label: "Consolas",
    //     value: "Consolas",
    //     hasFontStyle: true
    // },
    // {
    //     label: "Georgia",
    //     value: "Georgia",
    // },
    // {
    //     label: "Segoe UI Symbol",
    //     value: "Segoe UI Symbol",
    //     hasFontStyle: false
    // },
    // {
    //     label: "Symbol",
    //     value: "Symbol",
    // },
    // {
    //     label: "Verdana",
    //     value: "Verdana",
    // },
    // {
    //     label: "Webdings",
    //     value: "Webdings",
    // },
    // {
    //     label: "Wingdings",
    //     value: "Wingdings",
    // },
]

const fillOptions = [
    {
        label: "黒",
        value: "#000000",
    },
    {
        label: "栗色",
        value: "#800000",
    },
    {
        label: "緑",
        value: "#008000",
    },
    {
        label: "オリーブ",
        value: "#808000",
    },
    {
        label: "濃紺",
        value: "#00008b",
    },
    {
        label: "紫",
        value: "#a020f0",
    },
    {
        label: "緑青",
        value: "#67968a",
    },
    {
        label: "灰色",
        value: "#808080",
    },
    {
        label: "銀色",
        value: "#c0c0c0",
    },
    {
        label: "赤",
        value: "#ff0000",
    },
    {
        label: "黄緑",
        value: "#9dff00",
    },
    {
        label: "黄",
        value: "#ffff00",
    },
    {
        label: "青",
        value: "#0000ff",
    },
    {
        label: "明るい紫",
        value: "#ff80c5",
    },
    {
        label: "水色",
        value: "#9bedff",
    },
    {
        label: "白",
        value: "#ffffff",
    },
]


const fontStyleOptions = [
    {
        label: "標準",
        value: "normal",
        disabled: false,
    },
    // {
    //     label: "斜体",
    //     value: "italic",
    //     disabled: true,
    // },
    {
        label: "太字",
        value: "bold",
        disabled: false,
    },
    // {
    //     label: "太字 斜体",
    //     value: "bold italic",
    //     disabled: true,
    // }
]

const fontSizeOptions = [
    {
        label: "8",
        value: 8
    },
    {
        label: "9",
        value: 9
    },
    {
        label: "10",
        value: 10
    },
    {
        label: "11",
        value: 11
    },
    {
        label: "12",
        value: 12
    },
    {
        label: "14",
        value: 14
    },
    {
        label: "16",
        value: 16
    },
    {
        label: "18",
        value: 18
    },
    {
        label: "20",
        value: 20
    },
    {
        label: "22",
        value: 22
    },
    {
        label: "24",
        value: 24
    },
    {
        label: "26",
        value: 26
    },
    {
        label: "28",
        value: 28
    },
    {
        label: "36",
        value: 36
    },
    {
        label: "48",
        value: 48
    },
    {
        label: "72",
        value: 72
    }
]

const getFontStyle = (weight : number, italic : boolean) => {
    let fontStyle = 'normal';
    let fontWeight = 'normal';
    weight === 700 && (fontWeight = 'bold');
    italic && (fontStyle = 'italic');
    return {
        fontStyle: fontStyle,
        fontWeight: fontWeight,
    }
}

export const FormatTextDialog = (props: FormatTextFormProps) => {
    const { data, modeViewOnly, onOK, onCancel  } = props;
    const [fontSize, setFontSize] = useState(data.fontSize);
    const [faceName, setFaceName] = useState(data.faceName);
    const [colorFont, setColorFont] = useState(data.colorFont);
    const [isDisableFontStyle, setIsDisableFontStyle] = useState(!fontFamilyOptions.find((item)=> item.value === data.faceName )?.hasFontStyle);
    const [fontStyle, setFontStyle] = useState(isDisableFontStyle? getFontStyle(400, false):getFontStyle(data.weight, data.italic));
    const [textDecoration, setTextDecoration] = useState(getTextDecoration(data.underline, data.strikeOut));

    // 初期値設定
    const initialValue : TextEditorForm = {
        ...data,
        fontStyle: isDisableFontStyle? getFontStyleValue(400, false) : getFontStyleValue(data.weight, data.italic)
    }

    const { control, handleSubmit, formState: { errors }, setValue, getValues } = useForm<TextEditorForm>({
        mode: 'all',
        criteriaMode: "all",
        defaultValues: initialValue,
    });

    // Submitイベント
    const handleEditSubmit = (value: TextEditorForm) => {
        const request = createRequestData(value);
        onOK(request);
    }

    // FormデータからRequestデータを作成する
    const createRequestData = (formValue: TextEditorForm) => {
        const { fontStyle, ...request  } = formValue
        return request as FontFormatType;
    }

    // キャンセル
    const handleCancel = () => {
        onCancel();
    };

    const handleChangeFormat = (e: any) => { 
        if (e.target.name === 'underline' || e.target.name === 'strikeOut') {
            setValue(e.target.name, e.target.checked);
            const isUnderline = getValues('underline');
            const isLineThrough = getValues('strikeOut');
            setTextDecoration(getTextDecoration(isUnderline, isLineThrough));
        }
        else if (e.target.name === 'fontStyle') {
            const currentValue = e.target.value
            currentValue.includes("bold") ? setValue('weight', 700) : setValue('weight', 400)
            setValue('italic', currentValue.includes("italic"))
            setValue('fontStyle', currentValue)
            setFontStyle(getFontStyle(getValues('weight'), getValues('italic')));
        }
        else if(e.target.name === 'faceName') {
            let hasFontStyte = fontFamilyOptions.find((item)=> item.value === e.target.value)?.hasFontStyle
            hasFontStyte !== undefined && setIsDisableFontStyle(!hasFontStyte)
            if (!hasFontStyte) {
                setValue('italic', false)
                setValue('weight', 400)
                setValue('fontStyle', 'normal')
                setFontStyle(getFontStyle(400, false))
            }
            setFaceName(e.target.value)
            setValue(e.target.name, e.target.value);
        } 
        else {
            e.target.name === 'fontSize' && setFontSize(e.target.value);
            e.target.name === 'colorFont' && setColorFont(e.target.value);
            setValue(e.target.name, e.target.value);
        }
    }

    return (
        <Dialog open={true} maxWidth={"sm"} fullWidth
            PaperComponent={
                DraggablePaper
            }
        >
            <DialogTitle 
                onMouseOver={(e : any) => e.target.style.cursor = 'move'}
                style={{ paddingTop: '2px', paddingBottom: '2px', background: theme.palette.primary.main, color: theme.palette.primary.contrastText }}
            >
                フォント
            </DialogTitle>
            <DialogContent style={{paddingTop: '12px'}}>
                <div style={{ height: '100%', width: '100%'}}>
                    <form onSubmit={handleSubmit(handleEditSubmit)}>
                        <Stack>
                            <Stack direction={{ xs: 'column', sm: 'row' }}>
                                <Grid container direction="row">
                                    <Grid item xs={6} pr={1}>
                                        <Stack direction='row' style={{ marginBottom: '10px' }}>
                                            <Typography variant="body2" width="60%">フォント名(F):</Typography>
                                            <FormControl fullWidth size="small">
                                                <Controller
                                                    name="faceName"
                                                    control={control}
                                                    rules={{ required: '必須項目です。入力してください。' }}
                                                    render={({ field }) => (

                                                        <>
                                                            <Select
                                                                {...field}                                                                
                                                                error={!!errors?.faceName}
                                                                onChange={handleChangeFormat}
                                                                sx={{ height: 32 }}
                                                                disabled={modeViewOnly}
                                                            >
                                                                {fontFamilyOptions.map(item => (
                                                                    <MenuItem  key={item.label} value={item.value}>
                                                                        {item.label}
                                                                    </MenuItem>
                                                                    
                                                                ))}
                                                            </Select>
                                                        </>
                                                    )}
                                                />
                                            </FormControl>
                                        </Stack>
                                        <Stack direction='row' style={{ marginBottom: '10px' }}>
                                            <Typography variant="body2" width="60%">スタイル(Y):</Typography>
                                            <FormControl fullWidth size="small">
                                                <Controller
                                                    name="fontStyle"
                                                    control={control}                                                    
                                                    rules={{ required: '必須項目です。入力してください。' }}
                                                    render={({ field }) => (
                                                        <>
                                                            <Select
                                                                {...field}
                                                                disabled = {isDisableFontStyle || modeViewOnly} 
                                                                error={!!errors?.fontStyle}
                                                                onChange={handleChangeFormat}
                                                                sx={{ height: 32 }}
                                                                
                                                            >
                                                                {fontStyleOptions.map(item => (
                                                                    <MenuItem key={item.label} value={item.value} disabled={item.disabled}>
                                                                        <Stack spacing={2} direction='row'>
                                                                            {item.label}
                                                                        </Stack>
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </>
                                                    )}
                                                />
                                            </FormControl>
                                        </Stack>
                                        <Stack direction={'row'} style={{ marginBottom: '10px' }}>
                                            <Typography variant="body2" width="60%">サイズ(S):</Typography>
                                            <FormControl fullWidth size="small">
                                                <Controller
                                                    name="fontSize"
                                                    control={control}
                                                    rules={{ required: '必須項目です。入力してください。' }}
                                                    render={({ field }) => (
                                                        <>
                                                            <Select
                                                                {...field}
                                                                error={!!errors?.fontSize}
                                                                onChange={handleChangeFormat}
                                                                sx={{ height: 32 }}
                                                                disabled={modeViewOnly}
                                                            >
                                                                {fontSizeOptions.map(item => (
                                                                    <MenuItem key={item.label} value={item.value}>
                                                                        {item.label}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </>
                                                    )}
                                                />
                                            </FormControl>
                                        </Stack>
                                        <fieldset style={{border:"1px solid #AAAA"}}>
                                            <legend><Typography variant='body1'>文字飾り</Typography></legend>
                                            <Stack>
                                                <FormControl>
                                                    <Controller
                                                        
                                                        name="underline"
                                                        control={control}
                                                        render={({ field }: any) => (
                                                            <FormControlLabel disabled={modeViewOnly}  {...field} control={<Checkbox />} checked={field.value} label="下線" name='underline' onClick={handleChangeFormat} />
                                                        )}
                                                    />
                                                </FormControl>
                                            </Stack>
                                            <Stack>
                                                <FormControl>
                                                    <Controller
                                                        name="strikeOut"
                                                        control={control}
                                                        render={({ field }: any) => (
                                                            <FormControlLabel disabled={modeViewOnly} {...field} control={<Checkbox />} checked={field.value} label="取り消し線" name='strikeOut' onClick={handleChangeFormat} />
                                                        )}
                                                    />
                                                </FormControl>
                                            </Stack>
                                            <Stack direction='row' style={{ marginTop: '10px' }}>
                                                <Typography variant="body2" width="60%">色(C):</Typography>
                                                <FormControl fullWidth size="small">
                                                    <Controller
                                                        name="colorFont"
                                                        control={control}
                                                        rules={{ required: '必須項目です。入力してください。' }}
                                                        render={({ field }) => (
                                                            <>
                                                                <Select
                                                                    {...field}
                                                                    error={!!errors?.colorFont}
                                                                    // defaultValue={field.value}
                                                                    onChange={handleChangeFormat}
                                                                    sx={{ height: 32 }}
                                                                    disabled={modeViewOnly}
                                                                >
                                                                    {fillOptions.map(item => (
                                                                        <MenuItem key={item.label} value={item.value}>
                                                                            <Stack spacing={2} direction='row' style={{ margin: 'auto 0' }}>
                                                                                <RectangleIcon style={{border: '0.1px groove #ccc', color: `${item.value}` }} />
                                                                                <span style={{ marginLeft: '6px', paddingTop: '2px' }}>{item.label}</span>
                                                                            </Stack>
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </>
                                                        )}
                                                    />
                                                </FormControl>
                                            </Stack>
                                        </fieldset>
                                    </Grid>
                                    <Grid item xs={6} display={'flex'}>
                                        <fieldset style={{display: 'flex', width: "100%",height:"75%", borderRadius: '8px', marginTop: '-4px',border:"1px solid #AAAA"}}>
                                            <legend>サンプル</legend>  
                                            <div style={{ 
                                                margin: 'auto',
                                                fontSize: `${fontSize}px`,
                                                fontFamily: `${faceName}`,
                                                color: `${colorFont}`,
                                                fontStyle: `${fontStyle.fontStyle}`,
                                                fontWeight: `${fontStyle.fontWeight}`,
                                                textDecoration: `${textDecoration === 'strikeOut'? 'line-through' : textDecoration}` 
                                            }}>
                                                Aa あぁアァ亜宇
                                            </div>
                                        </fieldset>
                                    </Grid>
                                </Grid>
                            </Stack>
                            <Stack direction='row' justifyContent="flex-end" sx={{mt: 2}}>
                                <Button disabled={modeViewOnly} variant="contained" type="submit" sx={{mr: 2}}>OK</Button>
                                <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                            </Stack>
                        </Stack>
                    </form>
                </div>
            </DialogContent>
        </Dialog>
    );
};
export default FormatTextDialog;


