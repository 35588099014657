import { BOTTOM_CENTER, LEFT_CENTER, RIGHT_CENTER, TOP_CENTER } from "../models/Constants";
import { ControlModel, MSTransCenterModel } from "../models/Index";
import { getBottomControl, getLeftControl, getRightControl, getTopControl, isCanSwitchOnOff, isConnnectLoadOnZeroCond3Winding, isHaveConnectSource, isSwitchOff } from "./element";
import * as EKind from "../models/ElementKind"
import { store } from "..";

//CObjElementList::IslistCennectSourceDropElement
export const isListCennectSourceDropElement = (pListElement: ControlModel[], pStdElement: ControlModel, nDirect: number) => {
    let listAllElement = [...pListElement];
    if (nDirect == TOP_CENTER) {
        for (;;) {
            let findResult = getTopControl(pStdElement, listAllElement);
            if (findResult.code != 0 ) {
                break;
            }
            let pTop = findResult.data as ControlModel;
            if (pTop.type == EKind.MS_SOURCE ||
                pTop.type == EKind.MS_GENERATOR ||
                pTop.type == EKind.MS_INTO_POINT) {
                return true;
            }
            if (isCanSwitchOnOff(pTop) && isSwitchOff(pTop)) {
                continue;
            }
            if (pTop.type == EKind.MS_ZEROCOND) {
                if (isHaveConnectSource(pTop, listAllElement)) {
                    return true;
                } else {
                    continue;
                } 
            }
            for(;;) {
                findResult = getTopControl(pTop, listAllElement);
                if (findResult.code != 0 ) {
                    break;
                }
                pTop = findResult.data as ControlModel;
                if (pTop.type == EKind.MS_SOURCE ||
                    pTop.type == EKind.MS_GENERATOR ||
                    pTop.type == EKind.MS_INTO_POINT) {
                    return true;
                }
                if (isCanSwitchOnOff(pTop) && isSwitchOff(pTop)) {
                    break;
                }
                if (pTop.type == EKind.MS_ZEROCOND) {
                    if (isHaveConnectSource(pTop, listAllElement)) {
                        return true;
                    } else {
                        break;
                    } 
                }
            }
        }
    } else if (nDirect == BOTTOM_CENTER) {
        for (;;) {
            let findResult = getBottomControl(pStdElement, listAllElement);
            if (findResult.code != 0 ) {
                break;
            }
            let pBottom = findResult.data as ControlModel;
            if (isCanSwitchOnOff(pBottom) && isSwitchOff(pBottom)) {
                continue;
            }
            if (pBottom.type == EKind.MS_ZEROCOND ||
                pBottom.type == EKind.MS_3WINDING) {
                if (isHaveConnectSource(pBottom, listAllElement)) {
                    return true;
                } else {
                    continue;
                } 
            }
            for(;;) {
                findResult = getBottomControl(pBottom, listAllElement);
                if (findResult.code != 0 ) {
                    break;
                }
                pBottom = findResult.data as ControlModel;
                if (isCanSwitchOnOff(pBottom) && isSwitchOff(pBottom)) {
                    break;
                }
                if (pBottom.type == EKind.MS_ZEROCOND ||
                    pBottom.type == EKind.MS_3WINDING) {
                    if (isHaveConnectSource(pBottom, listAllElement)) {
                        return true;
                    } else {
                        break;
                    } 
                }
            }
        }
    } else if (nDirect == LEFT_CENTER) {
        let pLeft = pStdElement;
        for (;;) {
            let findResult = getLeftControl(pLeft, listAllElement);
            if (findResult.code != 0 ) {
                break;
            }
            pLeft = findResult.data as ControlModel;
            if (isCanSwitchOnOff(pLeft) && isSwitchOff(pLeft)) {
                break;
            }
            if (pLeft.type == EKind.MS_ZEROCOND ||
                pLeft.type == EKind.MS_3WINDING) {
                return isHaveConnectSource(pLeft, listAllElement);
            }
        }
    } else if (nDirect == LEFT_CENTER) {
        let pRight = pStdElement;
        for (;;) {
            let findResult = getRightControl(pRight, listAllElement);
            if (findResult.code != 0 ) {
                break;
            }
            pRight = findResult.data as ControlModel;
            if (isCanSwitchOnOff(pRight) && isSwitchOff(pRight)) {
                break;
            }
            if (pRight.type == EKind.MS_ZEROCOND ||
                pRight.type == EKind.MS_3WINDING) {
                return isHaveConnectSource(pRight, listAllElement);
            }
        }
    }
    return false;
}

//CObjElementList::IslistCennectLoadDropElement
export const isListCennectLoadDropElement = (pListElement: ControlModel[], pStdElement: ControlModel, nDirect: number) => {
    let listAllElement = [...pListElement];

    if (pStdElement.type == EKind.MS_ZEROCOND ||
        pStdElement.type == EKind.MS_3WINDING) {
        return isConnnectLoadOnZeroCond3Winding(pStdElement, listAllElement);
    }

    if ((nDirect & TOP_CENTER) == TOP_CENTER) {
        for (;;) {
            let findResult = getTopControl(pStdElement, listAllElement);
            if (findResult.code != 0 ) {
                break;
            }
            let pTop = findResult.data as ControlModel;
            if (pTop.type == EKind.MS_SOURCE ||
                pTop.type == EKind.MS_GENERATOR ||
                pTop.type == EKind.MS_INTO_POINT) {
                continue;
            }
            if (isCanSwitchOnOff(pTop) && isSwitchOff(pTop)) {
                continue;
            }
            if (pTop.type == EKind.MS_ZEROCOND) {
                if (isConnnectLoadOnZeroCond3Winding(pTop, listAllElement)) {
                    return true;
                } else {
                    continue;
                } 
            }
            for(;;) {
                findResult = getTopControl(pTop, listAllElement);
                if (findResult.code != 0 ) {
                    break;
                }
                pTop = findResult.data as ControlModel;
                if (pTop.type == EKind.MS_SOURCE ||
                    pTop.type == EKind.MS_GENERATOR ||
                    pTop.type == EKind.MS_INTO_POINT) {
                    break;
                }
                if (isCanSwitchOnOff(pTop) && isSwitchOff(pTop)) {
                    break;
                }
                if (pTop.type == EKind.MS_ZEROCOND) {
                    if (isConnnectLoadOnZeroCond3Winding(pTop, listAllElement)) {
                        return true;
                    } else {
                        break;
                    } 
                }
            }
        }
    } else if ((nDirect & BOTTOM_CENTER) == BOTTOM_CENTER) {
        for (;;) {
            let findResult = getBottomControl(pStdElement, listAllElement);
            if (findResult.code != 0 ) {
                break;
            }
            let pBottom = findResult.data as ControlModel;
            if (isCanSwitchOnOff(pBottom) && isSwitchOff(pBottom)) {
                continue;
            }

            if (pBottom.type == EKind.MS_TRANSCENTER) {
                let pTransCenter = pBottom.properties as MSTransCenterModel;
                let listTCElement = (store.getState().app.diagram.diagramData.filter((e: any) => e.tabId === pTransCenter.tabId)[0]).shape;
                let pTCStdElement = doGetTransCenterEnterElement(listTCElement);
                if (pTCStdElement && isListCennectLoadDropElement(listTCElement, pTCStdElement, BOTTOM_CENTER)) {
                    return true;
                }
                continue;
            }
            if (pBottom.type == EKind.MS_ZEROCOND ||
                pBottom.type == EKind.MS_3WINDING) {
                if (isConnnectLoadOnZeroCond3Winding(pBottom, listAllElement)) {
                    return true;
                } else {
                    continue;
                } 
            }
            if (pBottom.type == EKind.MS_MOTOR ||
                pBottom.type == EKind.MS_MOTOR_GROUP ||
                pBottom.type == EKind.MS_CAPACITOR ||
                pBottom.type == EKind.MS_LOAD ||
                pBottom.type == EKind.MS_LIGHTBOARD ||
                pBottom.type == EKind.MS_POWERBOARD ||
                pBottom.type == EKind.MS_LOADCENTER) {
                return true;
            }
            for(;;) {
                findResult = getBottomControl(pBottom, listAllElement);
                if (findResult.code != 0 ) {
                    break;
                }
                pBottom = findResult.data as ControlModel;
                if (isCanSwitchOnOff(pBottom) && isSwitchOff(pBottom)) {
                    break;
                }
                if (pBottom.type == EKind.MS_TRANSCENTER) {
                    let pTransCenter = pBottom.properties as MSTransCenterModel;
                    let listTCElement = (store.getState().app.diagram.diagramData.filter((e: any) => e.tabId === pTransCenter.tabId)[0]).shape;
                    let pTCStdElement = doGetTransCenterEnterElement(listTCElement);
                    if (pTCStdElement && isListCennectLoadDropElement(listTCElement, pTCStdElement, BOTTOM_CENTER)) {
                        return true;
                    }
                    break;
                }
                if (pBottom.type == EKind.MS_ZEROCOND ||
                    pBottom.type == EKind.MS_3WINDING) {
                    if (isConnnectLoadOnZeroCond3Winding(pBottom, listAllElement)) {
                        return true;
                    } else {
                        break;
                    } 
                }
                if (pBottom.type == EKind.MS_MOTOR ||
                    pBottom.type == EKind.MS_MOTOR_GROUP ||
                    pBottom.type == EKind.MS_CAPACITOR ||
                    pBottom.type == EKind.MS_LOAD ||
                    pBottom.type == EKind.MS_LIGHTBOARD ||
                    pBottom.type == EKind.MS_POWERBOARD ||
                    pBottom.type == EKind.MS_LOADCENTER) {
                    return true;
                }
            }
        }
    } else if ((nDirect & LEFT_CENTER) == LEFT_CENTER) {
        let pLeft = pStdElement;
        for (;;) {
            let findResult = getLeftControl(pLeft, listAllElement);
            if (findResult.code != 0 ) {
                break;
            }
            pLeft = findResult.data as ControlModel;
            if (isCanSwitchOnOff(pLeft) && isSwitchOff(pLeft)) {
                break;
            }
            if (pLeft.type == EKind.MS_ZEROCOND ||
                pLeft.type == EKind.MS_3WINDING) {
                return isConnnectLoadOnZeroCond3Winding(pLeft, listAllElement);
            }
        }
    } else if (nDirect == LEFT_CENTER) {
        let pRight = pStdElement;
        for (;;) {
            let findResult = getRightControl(pRight, listAllElement);
            if (findResult.code != 0 ) {
                break;
            }
            pRight = findResult.data as ControlModel;
            if (isCanSwitchOnOff(pRight) && isSwitchOff(pRight)) {
                break;
            }
            if (pRight.type == EKind.MS_ZEROCOND ||
                pRight.type == EKind.MS_3WINDING) {
                return isConnnectLoadOnZeroCond3Winding(pRight, listAllElement);
            }
        }
    }

    return false;
}

//CObjElementList::DoGetTransCenterEnterElement
export const doGetTransCenterEnterElement = (pListElement: ControlModel[]) => {
    let pObjTCElement = pListElement.find(x=> x.type == EKind.MS_INTO_POINT);
    return pObjTCElement;
}

//CObjElementList::DoFindSourceFromZero
export const doFindSourceFromZero = (pListElement: ControlModel[], pStdZeroCond: ControlModel) => {

    for (;;) {
        let findResult = getTopControl(pStdZeroCond, pListElement);
        if (findResult.code != 0 ) {
            break;
        }
        let pTop = findResult.data as ControlModel;
        for (;;) {
          if (pTop.type == EKind.MS_SOURCE ||
            pTop.type == EKind.MS_GENERATOR ||
            pTop.type == EKind.MS_INTO_POINT) {
              return pTop;
          }
  
          if (pTop.type == EKind.MS_ZEROCOND) {
            let pRetElement: any = doFindSourceFromZero(pListElement, pTop);
            if ( pRetElement != null) {
              return pRetElement;
            }
            break; 
          }
          
          if (isCanSwitchOnOff(pTop) && isSwitchOff(pTop)) {
            break;
          }
          let findResult = getTopControl(pTop, pListElement);
          if (findResult.code != 0 ) {
            break;
          }
          pTop = findResult.data as ControlModel;
        }
      }
  
      for (;;) {
        let findResult = getBottomControl(pStdZeroCond, pListElement);
        if (findResult.code != 0 ) {
            break;
        }
        let pBottom = findResult.data as ControlModel;
        for (;;) {
          if (pBottom.type == EKind.MS_MOTOR ||
            pBottom.type == EKind.MS_MOTOR_GROUP ||
            pBottom.type == EKind.MS_CAPACITOR ||
            pBottom.type == EKind.MS_LOAD ||
            pBottom.type == EKind.MS_LIGHTBOARD ||
            pBottom.type == EKind.MS_POWERBOARD ||
            pBottom.type == EKind.MS_LOADCENTER) {
            break;
          }
          
          if (pBottom.type == EKind.MS_ZEROCOND ||
            pBottom.type == EKind.MS_3WINDING) {
            let pRetElement: any = doFindSourceFromZero(pListElement, pBottom)
            if (pRetElement != null) {
                return pRetElement;
            }
            break; 
          }
  
          if (isCanSwitchOnOff(pBottom) && isSwitchOff(pBottom)) {
            break;
          }
  
          findResult = getBottomControl(pBottom, pListElement);
          if (findResult.code != 0 ) {
              break;
          }
          pBottom = findResult.data as ControlModel;
        }
      }
  
      let pLeft = pStdZeroCond;
      for (;;) {
          let findResult = getLeftControl(pLeft, pListElement);
          if (findResult.code != 0 ) {
              break;
          }
          pLeft = findResult.data as ControlModel;
  
          if (pLeft.type == EKind.MS_ZEROCOND ||
            pLeft.type == EKind.MS_3WINDING) {
            let pRetElement: any = doFindSourceFromZero(pListElement, pLeft)
            if (pRetElement != null) {
                return pRetElement;
            }
            break; 
          }
  
          if (isCanSwitchOnOff(pLeft) && isSwitchOff(pLeft)) {
              break;
          }
      }
  
      let pRight = pStdZeroCond;
      for (;;) {
        let findResult = getRightControl(pRight, pListElement);
        if (findResult.code != 0 ) {
            break;
        }
        pRight = findResult.data as ControlModel;
  
        if (pRight.type == EKind.MS_ZEROCOND ||
          pRight.type == EKind.MS_3WINDING) {
            let pRetElement: any = doFindSourceFromZero(pListElement, pRight)
            if (pRetElement != null) {
                return pRetElement;
            }
            break; 
        }
  
        if (isCanSwitchOnOff(pRight) && isSwitchOff(pRight)) {
            break;
        }
      }

    return null;

}

//CObjElementList::DoGetConnectElement
export const doGetConnectElement = (pListElement: ControlModel[],pStdElement:ControlModel, nDirect: number, bRemove:boolean = true) => {
    switch(nDirect) {
    case TOP_CENTER:				
        return getTopControl(pStdElement,pListElement, bRemove);
    case BOTTOM_CENTER:					
        return  getBottomControl(pStdElement,pListElement, bRemove);
    case LEFT_CENTER:					
        return  getLeftControl(pStdElement,pListElement, bRemove);
    case RIGHT_CENTER:				
        return  getRightControl(pStdElement,pListElement, bRemove);
    default : 
        return getTopControl(pStdElement,pListElement, bRemove);
    }
}

//CObjElementList::DoGetTransCenterIntoPointPointer
export const doGetTransCenterIntoPointPointer = (props:ControlModel[]) =>
{
  let pInoPoint = props.find((e:ControlModel) => e.type == EKind.MS_INTO_POINT);
  return pInoPoint?.properties;
}

