import { Button, ButtonGroup, Container, Dialog, DialogContent, DialogTitle, Grid, IconButton, Stack, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import theme from "../../themes/globalStyles";
import DraggablePaper from "../common/DraggablePaper";
import DecreaseIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import IncreaseIcon from '@mui/icons-material/AddBoxOutlined';
import { useState } from "react";

export type SettingUpGraphScaleDialogProps = {
    onOK: (data: any) => void;
    onCancel: () => void;
    initialData:SettingUpGraphScale;
};

export interface SettingUpGraphScale{
    scaleYMin:number,
    scaleYMax:number,
    scaleXMin:number,
    scaleXMax:number,
}

export const SettingUpGraphScaleDialog = (props: SettingUpGraphScaleDialogProps) => {
    //#region fields
    const { initialData,onOK, onCancel } = props;
    const scaleYArray = [0.00001,0.0001,0.001,0.01,0.1,1,10,100,1000,10000,100000,1000000,10000000,100000000];
    const scaleXArray = [0.001,0.01,0.1,1,10,100,1000,10000,100000,1000000,10000000,100000000];
    const [indexOfScaleYMin,setIndexOfScaleYMin]= useState(scaleYArray.findIndex((item) => item == initialData.scaleYMin));
    const [indexOfScaleYMax,setIndexOfScaleYMax]= useState(scaleYArray.findIndex((item) => item == initialData.scaleYMax));
    const [indexOfScaleXMin,setIndexOfScaleXMin]= useState(scaleXArray.findIndex((item) => item == initialData.scaleXMin));
    const [indexOfScaleXMax,setIndexOfScaleXMax]= useState(scaleXArray.findIndex((item) => item == initialData.scaleXMax));

    // 初期値設定
    const initialValue = {
        ...initialData
    }

    const { control, handleSubmit, formState: { errors }, setValue, getValues } = useForm<SettingUpGraphScale>({
        mode: "all",
        criteriaMode: "all",
        defaultValues: initialValue,
    })
    //#endregion fields

    //#region methods
    // Submitイベント
    const handleEditSubmit = (value: any, event: any) => {
        const request = createRequestData(value);
        onOK(request);
    }
    
    // FormデータからRequestデータを作成する
    const createRequestData = (formValue : any) => {       
        let request = {
            ...formValue,
        } as any;
        return request;
    }
    //#endregion methods

    return (
        <>
            <Dialog open={true} maxWidth={"sm"} fullWidth
                PaperComponent={
                    DraggablePaper
                }
            >
                <DialogTitle 
                    onMouseOver={(e : any) => e.target.style.cursor = 'move'}
                    style={{ paddingTop: '2px', paddingBottom: '2px', background: theme.palette.primary.main, color: theme.palette.primary.contrastText }}
                >
                    {`グラフスケールの調整`}
                </DialogTitle>
                <DialogContent style={{display: 'flex', alignItems: 'flex-end'}}>
                    <div style={{width: '100%'}}>
                        <form onSubmit={handleSubmit(handleEditSubmit)}>
                            <div style={{ maxHeight:"260px",marginTop:"10px"}}>
                                <Grid container spacing={1} paddingTop={"10px"} direction={"row"}>
                                    <Grid item xs={3}>
                                        <Stack spacing={13.5}>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }} mt={1}>
                                                <Controller
                                                    name="scaleYMax"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type="text"
                                                            size="small"
                                                            disabled={true}
                                                            style={{backgroundColor:"floralwhite"}}
                                                            InputProps={{ sx: { height: 32 } }}
                                                        />
                                                    )}
                                                />
                                                <Stack>
                                                    <IconButton disabled={indexOfScaleYMax === scaleYArray.length -1} style={{width:24,height:24}} children={<IncreaseIcon />}
                                                    onClick={() => {
                                                        if(indexOfScaleYMax < scaleYArray.length - 1 ){
                                                            setValue("scaleYMax",scaleYArray[indexOfScaleYMax + 1]);
                                                            setIndexOfScaleYMax(indexOfScaleYMax + 1);
                                                        }
                                                    }}></IconButton>
                                                    <IconButton disabled={indexOfScaleYMax === indexOfScaleYMin + 1} style={{width:24,height:24}} children={<DecreaseIcon />}
                                                    onClick={() => {
                                                        if(indexOfScaleYMin + 1 < indexOfScaleYMax){
                                                            setValue("scaleYMax",scaleYArray[indexOfScaleYMax - 1]);
                                                            setIndexOfScaleYMax(indexOfScaleYMax - 1);
                                                        }
                                                    }}></IconButton>
                                                </Stack>
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Controller
                                                    name="scaleYMin"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type="text"
                                                            size="small"
                                                            disabled={true}
                                                            style={{backgroundColor:"floralwhite"}}
                                                            InputProps={{ sx: { height: 32 } }}
                                                        />
                                                    )}
                                                />
                                                <Stack>
                                                    <IconButton disabled={indexOfScaleYMax === indexOfScaleYMin + 1 } style={{width:24,height:24}} children={<IncreaseIcon />}
                                                        onClick={() => {
                                                            if(indexOfScaleYMin + 1 < indexOfScaleYMax){
                                                                setValue("scaleYMin",scaleYArray[indexOfScaleYMin + 1]);
                                                                setIndexOfScaleYMin(indexOfScaleYMin + 1);
                                                            }
                                                        }}
                                                    ></IconButton>
                                                    <IconButton disabled={indexOfScaleYMin === 0} style={{width:24,height:24}} children={<DecreaseIcon />} 
                                                    onClick={() => {
                                                        if(indexOfScaleYMin >=1){
                                                            setValue("scaleYMin",scaleYArray[indexOfScaleYMin - 1]);
                                                            setIndexOfScaleYMin(indexOfScaleYMin - 1);
                                                        }
                                                    }}></IconButton>
                                                </Stack>
                                            </Stack>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={0.3}></Grid>
                                    <Grid item xs={5.5}>
                                        <div style={{position:"relative"}}>
                                            <div style={{position: "absolute",
                                            top: "-10px",
                                            left: `-15px`,
                                            width: "50px",
                                            color:"#000000",
                                            margin:"auto"}}>
                                                時問(s)
                                            </div>
                                            {/* TOP */}
                                            <div style={{
                                                position: "absolute",
                                                top: "15px",
                                                left: `0px`,
                                                width: "3px",
                                                height: "180px",
                                                backgroundColor: "#808080",
                                                zIndex:"999",
                                            }}>
                                                {/* RIGHT */}
                                                    <div style={{
                                                    position: "absolute",
                                                    bottom:"15px",
                                                    left:"-15px",
                                                    width: "180px",
                                                    height: "3px",
                                                    backgroundColor: "#808080",
                                                    zIndex:"999",
                                                    }}>
                                                    </div>
                                                    <div style={{position: "absolute",
                                                        bottom:"3px",
                                                        right:"-250px",
                                                        width: "80px",
                                                        color:"#000000",
                                                        margin:"auto"}}>
                                                        電流(A)
                                                    </div>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid container direction={"row"} mt={0.1}>
                                    <Grid item xs={1.8}>
                                    </Grid>
                                    <Grid item xs={3.2}>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <IconButton onClick={() => {
                                                if(indexOfScaleXMin >=1){
                                                    setValue("scaleXMin",scaleXArray[indexOfScaleXMin - 1]);
                                                    setIndexOfScaleXMin(indexOfScaleXMin - 1);
                                                }
                                            }} style={{width:24,height:24}} disabled={indexOfScaleXMin === 0} children={<DecreaseIcon />}></IconButton>
                                            <Controller
                                                name="scaleXMin"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        disabled={true}
                                                        style={{backgroundColor:"floralwhite"}}
                                                        InputProps={{ sx: { height: 32 } }}
                                                    />
                                                )}
                                            />
                                            <IconButton onClick={() => {
                                                if(indexOfScaleXMin + 1 < indexOfScaleXMax){
                                                    setValue("scaleXMin",scaleXArray[indexOfScaleXMin + 1]);
                                                    setIndexOfScaleXMin(indexOfScaleXMin + 1);
                                                }
                                            }} style={{width:24,height:24}} disabled={indexOfScaleXMin + 1 === indexOfScaleXMax} children={<IncreaseIcon />} ></IconButton>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={1.6}>
                                        
                                    </Grid>
                                    <Grid item xs={3.5}>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <IconButton onClick={() => {
                                                if(indexOfScaleXMin + 1 !== indexOfScaleXMax){
                                                    setValue("scaleXMax",scaleXArray[indexOfScaleXMax - 1]);
                                                    setIndexOfScaleXMax(indexOfScaleXMax - 1);
                                                }
                                            }} disabled={indexOfScaleXMin + 1 === indexOfScaleXMax} style={{width:24,height:24}} children={<DecreaseIcon />}></IconButton>
                                            <Controller
                                                name="scaleXMax"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        disabled={true}
                                                        style={{backgroundColor:"floralwhite"}}
                                                        InputProps={{ sx: { height: 32 } }}
                                                    />
                                                )}
                                            />
                                            <IconButton onClick={() => {
                                                if(indexOfScaleXMax < scaleXArray.length - 1){
                                                    setValue("scaleXMax",scaleXArray[indexOfScaleXMax + 1]);
                                                    setIndexOfScaleXMax(indexOfScaleXMax + 1);
                                                }
                                            }} style={{width:24,height:24}} disabled={indexOfScaleXMax === scaleXArray.length -1} children={<IncreaseIcon />} ></IconButton>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </div>
                            <Stack direction='row' justifyContent="flex-end" gap={2} sx={{ mt: 3 }}>
                                <Button variant="contained" type="submit">OK</Button>
                                <Button variant="contained" color="inherit" onClick={onCancel}>キャンセル</Button>
                            </Stack>
                        </form>
                    </div>                    
                </DialogContent>
            </Dialog>
        </>
    )
}
export default SettingUpGraphScaleDialog;