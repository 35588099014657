import {  Box, Button, ButtonGroup, Checkbox, Dialog, DialogContent, DialogTitle, FormControlLabel, Grid, Stack,TextField, Typography } from "@mui/material";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import DraggablePaper from "../../common/DraggablePaper";
import {DemoItem } from '@mui/x-date-pickers/internals/demo';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from "dayjs";
import UserGrid from "./UserGrid";
import AlertDialog from "../../common/AlertDialog";
import ConfirmDialog from "../../common/ConfirmDialog";
import { getValue } from "@testing-library/user-event/dist/utils";

type NotificationEditFormProps = {
    subList:any;
    listUser: any;
    userSub:any;
    userEmail:any;
    isAdd: boolean;
    data: any;
    onCancel:() => void;
    addNotification:(param:any) => void;
    updateNotification:(param:any) =>void;
    sendMail:(param:any) => void;
    sendMailAndAddNotify:(param:any, state:boolean) => void;
};

export const NotificationEdit = (props: NotificationEditFormProps) => {
    const {
        subList,
        listUser,
        isAdd,
        data,
        userSub,
        userEmail,
        onCancel,
        addNotification,
        updateNotification,
        sendMail,
        sendMailAndAddNotify,
    } = props;

    // 初期値設定
    const initialValue: any = { ...data }
    const {control, handleSubmit, formState: { errors }, setValue, getValues} = useForm<any>({
        mode: 'all',
        criteriaMode: "all",
        defaultValues: initialValue,
    });
    const [valueStartTime, setValueStartTime] = useState<Dayjs | null>(dayjs(initialValue.startDate));
    const [valueEndTime, setValueEndTime] = useState<Dayjs | null>(dayjs(initialValue.endDate));
    const [target, setTarget] = useState(initialValue.target);
    const [checkSendMail, setCheckSendMail] = useState(initialValue.sendMail);
    const [msgError, setMsgError] = useState("");

    const [keyword, setKeyword] = useState("");
    const [subUser, setSubUser] = useState<any>(subList);

    const [openSubmitDialog, setOpenSubmitDialog] = useState<boolean>(false);
    const [openConfirmMailDialog, setOpenConfirmDialog] = useState<boolean>(false);
    const [openSendMailDialog, setOpenSendMailDialog] = useState<boolean>(false);

    // Submitイベント
    const handleEditSubmit = (value:any) => {
        if(isAdd){
            let params ={
                "sub_user": subUser||[],
                params:{
                    "title": value.title,
                    "content": value.content,
                    "startDate": value.startDate||null,
                    "endDate": value.endDate||null,
                    "emailGreeting":value.emailGreeting,
                    "emailSignature": value.emailSignature,
                    "target": target,
                    "sendMail": value.sendMail 
                }
            }
            addNotification(params)
        }
        else{
            let params ={
                "sub_user": subUser||[],
                params:{
                    "notificationId" : value.notificationId,
                    "title": value.title,
                    "content": value.content,
                    "startDate": value.startDate||null,
                    "endDate": value.endDate||null,
                    "emailGreeting":value.emailGreeting,
                    "emailSignature": value.emailSignature,
                    "target": target,
                    "sendMail": value.sendMail 
                }
            }
            updateNotification(params)
        }
    }

    // キャンセル
    const handleCancel = () => {
        onCancel();
    };

    const handleSetSubmit = () => {
        let params = {
            title: getValues("title"),
            content: getValues("content"),
            startDate: getValues("startDate"),
            endDate: getValues("endDate"),
            sendMail: getValues("sendMail"),
            emailGreeting: getValues("emailGreeting"),
            emailSignature: getValues("emailSignature"),
            target: getValues("target"),
            notificationId: getValues("notificationId"),
            sub_user: getValues("sub")
        }
        handleEditSubmit(params)
    }

    const handleCancelDialog = () => {
        setOpenConfirmDialog(false)
        setOpenSendMailDialog(false)
        setOpenSubmitDialog(false)
    }

    const handleSendMailAndNotify = () => {
        if(isAdd){
            let params ={
                "sub_user": subUser||[],
                params:{
                    "title": getValues("title"),
                    "content": getValues("content"),
                    "startDate": getValues("startDate")||null,
                    "endDate": getValues("endDate")||null,
                    "emailGreeting":getValues("emailGreeting"),
                    "emailSignature": getValues("emailSignature"),
                    "target": getValues("target"),
                    "sendMail": getValues("sendMail"),
                    "notificationId": getValues("notificationId"),
                    "isSendMail":true
                }
            }
            sendMailAndAddNotify(params, false)
        }
        else{
            let params ={
                "sub_user": subUser||[],
                params:{
                    "title": getValues("title"),
                    "content": getValues("content"),
                    "startDate": getValues("startDate")||null,
                    "endDate": getValues("endDate")||null,
                    "emailGreeting":getValues("emailGreeting"),
                    "emailSignature": getValues("emailSignature"),
                    "target": getValues("target"),
                    "sendMail": getValues("sendMail"),
                    "notificationId": getValues("notificationId"),
                    "isSendMail":true
                }
            }
            sendMailAndAddNotify(params, true)
        }
        setOpenSubmitDialog(false)
    }

    const handleSendMail = (isConfirm:boolean) => {
        let params:any
        if(isConfirm)
        {
            params = {
                title: getValues("title"),
                content: getValues("content"),
                startDate: getValues("startDate"),
                endDate: getValues("endDate"),
                sendMail: getValues("sendMail"),
                emailGreeting: getValues("emailGreeting"),
                emailSignature: getValues("emailSignature"),
                target: getValues("target"),
                notificationId: getValues("notificationId"),
                sub_user: [userSub],
                isConfirm: true
            }
        }
        else
        {
            params = {
                title: getValues("title"),
                content: getValues("content"),
                startDate: getValues("startDate"),
                endDate: getValues("endDate"),
                sendMail: getValues("sendMail"),
                emailGreeting: getValues("emailGreeting"),
                emailSignature: getValues("emailSignature"),
                target: getValues("target"),
                notificationId: getValues("notificationId"),
                sub_user: getValues("sub") || []
            }
        }
        sendMail(params)
        setOpenConfirmDialog(false)
        setOpenSendMailDialog(false)
    }

    const handleCheckSendMail = (e:any) =>{
        setCheckSendMail(e.target.checked)
    }

    const handleClickSubmit = () => {
        if(getValues("sendMail") && isAdd)
        {
            setOpenSubmitDialog(true)
        }
        else
        {
            handleSetSubmit()
        }
    }

    const formatDateTime =(newValue:any) =>{
        if (newValue != null) {
            if (newValue.$d.toString() === "Invalid Date") {                                                                        
                return {time:"",date:null}
            } else {
                const month = Number(newValue.$M) + 1;
                const hour = Number(newValue.$H)
                const minute = Number(newValue.$m)
                const second = Number(newValue.$s)
                const time = (hour < 10 ? "0" + hour.toString() : hour.toString())
                    + ":" + (minute < 10 ? "0" + minute.toString() : minute.toString())
                    + ":" + (second < 10 ? "0" + second.toString() : second.toString())
                const date = newValue.$y
                    + "-" + (month < 10 ? "0" + month.toString() : month.toString())
                    + "-" + (Number(newValue.$D) < 10 ? "0" + newValue.$D.toString() : newValue.$D.toString())
                    + " " + time
                return {time:newValue,date:date}
            }
        }
    }

    const handleChangeStartDate = (newValue:any) =>{
        const date = formatDateTime(newValue)

        if (valueEndTime && newValue) {
            const diff = valueEndTime?.diff(dayjs(date?.time))
            if (diff && diff < 0) {
                setMsgError("(終了日) は (開始日)より大きくなければならない")
            } else setMsgError("")
        } else setMsgError("")
        
        setValueStartTime(dayjs(date?.time));
        setValue("startDate", date?.date);
    }

    const handleChangeEndDate = (newValue:any) =>{
        const date = formatDateTime(newValue)
        
        if (valueStartTime && newValue) {
            const diff = valueStartTime?.diff(dayjs(date?.time))
            if (diff && diff > 0) {
                setMsgError("(終了日) は (開始日)より大きくなければならない")
            } else setMsgError("")
        } else setMsgError("")
        setValueEndTime(dayjs(date?.time));
        setValue("endDate", date?.date);
    }

    const handleSelect = (data:any)=>{
        let listSubUser:any[] = data.map((e: any) => e.sub)
        setSubUser(listSubUser)
        
        if (target == 1) setTarget(2)
    }

    const handleTarget = (e:any) =>{
        setTarget(e.target.value)

        if(e.target.value == 1){
            handleSelect(listUser)
            listUser.map((e:any) =>{ e.isSelect = true })
        }
        else if(e.target.value == 2){
            handleSelect([])
            listUser.map((e:any) =>{ e.isSelect = false })
        }
    }

    return (
        <>
            <Dialog open={true} maxWidth={"md"} fullWidth
                PaperComponent={
                    DraggablePaper
                }
            >
                <DialogTitle
                    onMouseOver={(e : any) => e.target.style.cursor = 'move'}
                    style={{ paddingTop: '16px' }}
                >
                    <Typography variant="h6" fontWeight={"bold"}> {isAdd ? 'お知らせの登録' : 'お知らせの編集'}</Typography>
                </DialogTitle>
                <DialogContent>
                    <form onSubmit={handleSubmit(handleEditSubmit)}>
                        <div style={{height:"50vh", overflowY:"auto", width: '100%'}}>
                            <Grid mt={1} ml={3}>
                                <Stack>
                                    <Controller
                                        name="title"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                id="outlined-multiline-static"
                                                label={<Typography variant="body1">件名</Typography>}
                                            />
                                        )}
                                    />
                                </Stack>
                                <Stack mt={3}>
                                    <Controller
                                        name="content"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                id="outlined-multiline-static"
                                                label={<Typography variant="body1" >本文</Typography>}
                                                multiline
                                                rows={7}
                                            />
                                        )}
                                    />
                                </Stack>
                                <Stack mt={3}>
                                    <Controller
                                        name="startDate"
                                        control={control}
                                        render={({ field }) => (
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoItem>
                                                    <DateTimePicker 
                                                    ampm={false}
                                                    format="YYYY/MM/DD HH:mm"
                                                    label={<Typography variant="body1">表示期間 (開始日)</Typography>}
                                                    value={valueStartTime}
                                                    onChange={(newValue: any) => { handleChangeStartDate(newValue)}}
                                                    />
                                                </DemoItem>
                                            </LocalizationProvider>
                                        )}
                                    />
                                </Stack>
                                <Stack mt={3}>
                                    <Controller
                                        name="endDate"
                                        control={control}
                                        render={({ field }) => (
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoItem>
                                                    <DateTimePicker 
                                                    ampm={false}
                                                    format="YYYY/MM/DD HH:mm"
                                                    label={<Typography variant="body1">表示期間 (終了日)</Typography>}
                                                    value={valueEndTime}
                                                    onChange={(newValue) => handleChangeEndDate(newValue)}
                                                    name="endDate"
                                                    />
                                                </DemoItem>
                                            </LocalizationProvider>
                                        )}
                                    />
                                    <Typography variant="body1" sx={{color:"red"}}>{msgError}</Typography>
                                </Stack>
                                <Stack ml={2} width={"50%"} mt={3}>
                                    <Typography variant="body1">表示対象</Typography>
                                    <Stack flexDirection={"row"}>
                                        <ButtonGroup >
                                            <Button variant={target ==1?"contained":"outlined"} value={1} onClick={handleTarget}>全てのユーザー</Button>
                                            <Button variant={target ==2?"contained":"outlined"} value={2} onClick={handleTarget}>指定のユーザー</Button>
                                        </ButtonGroup>
                                    </Stack>
                                </Stack>
                                <Grid ml={3} container  alignItems={"center"} direction="row" spacing={1}>
                                    <Grid item xs={6.5}>
                                        <Typography variant="body1" fontWeight={"bold"}>対象 : {listUser.length}名</Typography>
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            fullWidth
                                            autoComplete="searchStr"
                                            name="keyword"
                                            type="text"
                                            label="フィルター"
                                            size="small"
                                            value={keyword}
                                            onChange={e => setKeyword(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Button variant="outlined" onClick={() =>setKeyword("")}>クリア</Button>
                                    </Grid>
                                </Grid>
                                <Stack mt={1}>
                                    <UserGrid listUser={listUser} keyword={keyword} onSelect={handleSelect}/>
                                </Stack>
                                <Stack mt={3} flexDirection={"row"} >
                                    <Typography variant="body1" alignSelf={"center"}>メール送信</Typography>
                                    <Controller
                                        name="sendMail"
                                        control={control}
                                        render={({ field }: any) => (
                                            <FormControlLabel
                                                {...field}
                                                control={<Checkbox style={{marginLeft:'10px'}}/>}
                                                checked={checkSendMail}
                                                name='sendMail'
                                                onClick={handleCheckSendMail}
                                            />
                                        )}
                                    />
                                </Stack>
                                {checkSendMail &&
                                    <>
                                        <Stack mt={0.5} flexDirection={"column"} spacing={1}>
                                            <Box>
                                                <Button variant="contained" style={{textTransform:"none"}} onClick={()=>setOpenConfirmDialog(true)}>{userEmail}にメール送信（確認用）</Button>
                                            </Box>
                                            {!isAdd &&
                                                <Box>
                                                    <Button variant="contained"  onClick={()=>setOpenSendMailDialog(true)}>表示対象ユーザーにメール送信</Button>
                                                </Box>
                                            }
                                        </Stack>

                                        <Stack mt={2}>
                                            <Controller
                                                name="emailGreeting"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        id="outlined-multiline-static"
                                                        label={<Typography variant="body1">メールの挨拶文</Typography>}
                                                        multiline
                                                        rows={5}
                                                        defaultValue={
                                                            "%uesrname 様" +'\n'+
                                                            "いつもお世話になっております。"+"\n" +
                                                            "エレックス極東です。"
                                                        }
                                                    />
                                                )}
                                            />
                                        </Stack>
                                        <Stack ml={1}>
                                            <Typography variant="body1">※お客様名を表示する位置に、%usernameと入力してください</Typography>
                                        </Stack>
                                        <Stack mt={3} mb={1}>
                                            <Controller
                                                name="emailSignature"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        id="outlined-multiline-static"
                                                        label={<Typography variant="body1">メールの署名</Typography>}
                                                        multiline
                                                        rows={5}
                                                        defaultValue={
                                                            "株式会社 エレックス極東" +"\n" +
                                                            "電話：○○○-○○○-○○○" +"\n" +
                                                            "メール：aaaaaa@a.com"
                                                        }
                                                    />
                                                )}
                                            />
                                        </Stack>
                                    </>
                                }
                            </Grid>     
                        </div>
                        <Stack direction='row' justifyContent="flex-end" gap={2} mt={1} paddingBottom={1}>
                            <Button variant="contained" onClick={handleClickSubmit} disabled={msgError !== ""}>保存</Button>
                            <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                        </Stack>
                    </form>
                </DialogContent>
            </Dialog>

            {
                openSubmitDialog &&
                <ConfirmDialog messageLine1={'表示対象のユーザーにメールを送信しますか？'} messageLine2={'はい（お知らせ登録 + メール送信）'} messageLine3={'いいえ（お知らせ登録）'} isOpen={true} onOK={handleSendMailAndNotify} onNo={handleSetSubmit} onCancel={handleCancelDialog}/>
            }
            {
                openConfirmMailDialog &&
                <ConfirmDialog messageLine2={userEmail+'にメールを送信します。'} messageLine3={'よろしいですか？'} isOpen={true} onOK={()=>handleSendMail(true)} onCancel={handleCancelDialog}/>
            }
            {
                openSendMailDialog &&
                <ConfirmDialog messageLine2={'表示対象のユーザーにメールを送信します。'} messageLine3={'よろしいですか？'} isOpen={true} onOK={()=>handleSendMail(false)} onCancel={handleCancelDialog}/>
            }
        </>
    );
};
export default NotificationEdit;


