import { Button, FormControlLabel, Grid, Radio, RadioGroup, Stack, TextField, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { PowerFlowModel } from "../../models/Index";
import { FLOW_METHOD_GAUSS, FLOW_METHOD_NEWTON, FLOW_VIEW_EVERY_ELEMENT, FLOW_VIEW_ZEROCOND_ONLY } from "../../statics";

export type PowerFlowFormProps = {
    data: any;
    onOK: (data: any) => void;
    onCancel: () => void;
};

export const PowerFlowDialog = (props: PowerFlowFormProps) => {
    const {data,onOK,onCancel} = props;

    // キャンセル
    const handleCancel = () => {
        onCancel();
    };

    // FormデータからRequestデータを作成する
    const createRequestData = (formValue: PowerFlowModel) => {
        let request = {
            ...formValue
        } as PowerFlowModel;
        return request;
    }

    // Submitイベント
    const handleEditSubmit = (value: PowerFlowModel) => {
        const request = createRequestData(value);
        onOK(request);
    }
    
    const { control, handleSubmit, formState: { errors }, setValue } = useForm<PowerFlowModel>({
        mode: 'all',
        criteriaMode: "all",
        defaultValues: JSON.parse(JSON.stringify(data)),
    });  

    const handleChangeFormat = (e:any) => {
        setValue(e.target.name,e.target.value)
    }

    return (
        <>
        <form onSubmit={handleSubmit(handleEditSubmit)} style={{ width: '100%', margin: 'auto' }}>
                <div style={{height:"30vh",width:"100%",overflowY:"auto"}}>
                    <Grid container my={1}>
                        <Grid item xs={5.5}>
                            <fieldset style={{border:"1px solid grey"}}>
                                <legend>潮流解析方法</legend>
                                    <Controller
                                        name="powerFlowMethod"
                                        control={control}
                                        render={({ field }) => (
                                            <RadioGroup
                                                {...field}
                                                onChange={(e)=>{field.onChange(e)}}
                                                value={field.value}
                                            >
                                                <FormControlLabel
                                                    value={FLOW_METHOD_NEWTON}
                                                    control={<Radio size="small"/>}
                                                    label={<Typography variant='body2'>ニュートン・ラプソン法</Typography>}
                                                />
                                                <FormControlLabel
                                                    value={FLOW_METHOD_GAUSS}
                                                    control={<Radio size="small"/>}
                                                    label={<Typography variant='body2'>ガウス・ザイデル法</Typography>}
                                                />
                                            </RadioGroup>
                                        )}
                                    />
                            </fieldset>
                        </Grid>
                        <Grid item xs={6} ml={1} mt={1.3}>
                            <Stack spacing={0.3}>
                                <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                    <Typography variant="body2" width="50%">収束条件:</Typography>
                                        <Controller
                                            name="precision"
                                            control={control}
                                            rules={{
                                                required: '必須項目です。入力してください。',
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    size="small"
                                                    onChange={handleChangeFormat}
                                                    onFocus={e => e.target.select()}
                                                    InputLabelProps={{
                                                        style: { color: 'black' },
                                                    }}
                                                    InputProps={{sx:{height:32}}}
                                                    error={!!errors?.precision}
                                                    helperText={errors?.precision?.message}
                                                />
                                            )}
                                        />
                                </Stack>
                                <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                    <Typography variant="body2" width="50%">最大反復回数:</Typography>
                                        <Controller
                                            name="maxIteration"
                                            control={control}
                                            rules={{
                                                required: '必須項目です。入力してください。',
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    size="small"
                                                    onChange={handleChangeFormat}
                                                    onFocus={e => e.target.select()}
                                                    InputLabelProps={{
                                                        style: { color: 'black' },
                                                    }}
                                                    InputProps={{sx:{height:32}}}
                                                    error={!!errors?.maxIteration}
                                                    helperText={errors?.maxIteration?.message}
                                                />
                                            )}
                                        />
                                </Stack>
                                <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                    <Typography variant="body2" width="50%">加速係数:</Typography>
                                        <Controller
                                            name="accelerationFactor"
                                            control={control}
                                            rules={{
                                                required: '必須項目です。入力してください。',
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    size="small"
                                                    onChange={handleChangeFormat}
                                                    onFocus={e => e.target.select()}
                                                    InputLabelProps={{
                                                        style: { color: 'black' },
                                                    }}
                                                    InputProps={{sx:{height:32}}}
                                                    error={!!errors?.accelerationFactor}
                                                    helperText={errors?.accelerationFactor?.message}
                                                />
                                            )}
                                        />
                                </Stack>
                            </Stack>
                            
                        </Grid>
                    </Grid>
                    <Grid container my={1}>
                        <Grid item xs={8.5}>
                            <fieldset style={{border:"1px solid grey"}}>
                                <legend>潮流解析結果の表示方法</legend>
                                <Controller
                                    name="flowViewMethod"
                                    control={control}
                                    render={({ field }) => (
                                        <RadioGroup
                                            {...field}
                                            row
                                            onChange={(e)=>{field.onChange(e)}}
                                            value={field.value}
                                        >
                                            <FormControlLabel
                                                value={FLOW_VIEW_EVERY_ELEMENT}
                                                control={<Radio size="small"/>}
                                                label={<Typography variant='body2'>機器ごとに表示</Typography>}
                                            />
                                            <FormControlLabel
                                                value={FLOW_VIEW_ZEROCOND_ONLY}
                                                control={<Radio size="small"/>}
                                                label={<Typography variant='body2'>母線ごとに表示</Typography>}
                                            />
                                        </RadioGroup>
                                    )}
                                />            
                            </fieldset>
                        </Grid>
                    </Grid>
                </div>
                <Grid container my={1}>
                    <Grid item xs={7} mt={1}>
                        <Stack direction='row' justifyContent="flex-start">
                            <Typography>注意 : 単相回路では単相変圧器の容量と負荷率で計算します。</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={5} mt={1}>
                        <Stack direction='row' justifyContent="flex-end" spacing={2}>
                            <Button variant="contained" type="submit">OK</Button>
                            <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                        </Stack>
                    </Grid>
                </Grid>       
        </form>
        </>
    )
}
