import { Iele3WindingPart, Ms3WindingModel, PercentZ, ProcessMode, ControlModel, CalcPoint } from "../models/Index";
import { MS_MODE_CALCULATION, MS_MODE_CHECKING, MS_MODE_DRAWING, MS_MODE_HARMONIC, MS_MODE_PERCENT_Z, MS_MODE_POWER_FLOW, MS_MODE_VOLTDROP, VOLT_SIDE_PRIMARY, VOLT_SIDE_SECONDARY, VOLT_SIDE_THIRD } from "../statics";
import { BeamsNumber } from "./FormatNumber";
import { store } from "..";
import { doGetIkasymTotalMG, doGetIkpeakTotalMG, doGetPerZDirectMG, doGetPerZTotalMG, doGetValueDropVoltRegular as doGetValueDropVoltRegularCalcPoint, 
    doSetCapacity, 
    doSetVoltage,
} from "./calcPointFunction";
import { doGetTextDampingTime, 
    doGetTextInRushTimes, 
    doGetTextInRushValue, 
    doGetTextIntensityTime, 
    doGetTextIntensityTimes, 
    doGetTextIntensityValue, 
    doGetTextOhmR, 
    doGetTextOhmX, 
    doGetTextOhmZ,  
    doGetValueRatedCurrent,
    doGetTextVoltage as doGetTextVoltagePart,
    doGetTextCapacity as doGetTextCapacityPart,
    doGetTextRatedCurrent as doGetTextRatedCurrentPart,
    doGetTextPercentZe as doGetTextPercentZePart,
    doGetTextXR as doGetTextXRPart,
    doGetTextDirectIk3peak as doGetTextDirectIk3peakPart,
    doGetTextDirectIk3asym as doGetTextDirectIk3asymPart,
    doGetTextDirectIk3sym as doGetTextDirectIk3symPart,
    doGetTextTotalIk3sym as doGetTextTotalIk3symPart,
    doGetTextLoadCurrent as doGetTextLoadCurrentPart,
    doGetTextDropVoltPF as doGetTextDropVoltPFPart,
    doGetTextDropVoltRegular as doGetTextDropVoltRegularPart,
    doGetTextDropPerRegular as doGetTextDropPerRegularPart,
    doGetTextDropPerRegular2 as doGetTextDropPerRegular2Part,
    doGetValueOhmZ,
    doGetValueOhmX,
    doGetValueOhmR
} from "./windingPartFunction";
import { doGetValuePerZk33Winding } from "./PercentZ"
import {DIRECT_NONE, MS_VOLTDROP_REGULAR} from "../models/Constants"
const voltSideSpecialHighMin = 7000;

export const doGetValueVoltDrop = (capacityCircuit:number,opeMode:any,partKind:number,Ms3Winding:Ms3WindingModel) =>{
    const voltDropDiff = doGetTextVoltDropDiff(capacityCircuit,opeMode,partKind,Ms3Winding);
    const voltDropPerUnit = doGetTextDropPerUnit(capacityCircuit,partKind,Ms3Winding);
    return {voltDropDiff:voltDropDiff,voltDropPerUnit:voltDropPerUnit}
}

export const doSetDefault3Winding = (refNo:number,voltPrimary:number,voltSecondary:number,voltThird:number,frequency:any,newRefNo:boolean,noFautlt:number
    ,voltageLowDefault:any,voltageHighDefault:any,partPrimary:any,partSecondary:any,partThird:any,_3windingItem:Ms3WindingModel) => {
    if(voltPrimary <= 0){       
        doSetVoltage3Winding(VOLT_SIDE_PRIMARY,66000,partPrimary,partSecondary,partThird);
    }else{
        doSetVoltage3Winding(VOLT_SIDE_PRIMARY,voltPrimary,partPrimary,partSecondary,partThird);
    }
    if(voltSecondary <= 0){
        if(partPrimary.voltage >= voltSideSpecialHighMin)
            doSetVoltage3Winding(VOLT_SIDE_SECONDARY,11000,partPrimary,partSecondary,partThird);
        else{
            doReadSetDefaultVoltage(partSecondary,voltageLowDefault);
        }
    }else{
        doSetVoltage3Winding(VOLT_SIDE_PRIMARY,voltSecondary,partPrimary,partSecondary,partThird);
    }
    if(voltThird <= 0){
        if(partSecondary.voltage >= voltSideSpecialHighMin){
            doReadSetDefaultVoltage(partThird,voltageHighDefault);
        }else{
            doReadSetDefaultVoltage(partThird,voltageLowDefault);
        }
    }else{
        doSetVoltage3Winding(VOLT_SIDE_THIRD,voltThird,partPrimary,partSecondary,partThird);
    }
    _3windingItem.checkNG = false;
    _3windingItem.eleNeutralPointNumber = 0;
    _3windingItem.supply = false;
    _3windingItem.primarySupply = false;
    _3windingItem.tcName = "";
    _3windingItem.seriesed = false;
    _3windingItem.viewResultText = true;
    _3windingItem.viewFlowResult = false;
    _3windingItem.exchangeNumber = 0;
}
export const doSetVoltage3Winding = (voltSide:number,voltage:number,partPrimary:Iele3WindingPart,partSecondary:Iele3WindingPart,partThird:Iele3WindingPart) => {
    let oldVolt = 0;
    if(voltSide === VOLT_SIDE_PRIMARY){
        oldVolt = partPrimary.voltage;
        partPrimary.voltage = voltage;
        doSetVoltage(voltage,partPrimary.calcPoint0)
        doSetVoltage(voltage,partPrimary.calcPoint1)
    }else if(voltSide === VOLT_SIDE_SECONDARY){
        oldVolt = partSecondary.voltage;
        partSecondary.voltage = voltage;
        doSetVoltage(voltage,partSecondary.calcPoint0)
        doSetVoltage(voltage,partSecondary.calcPoint1)
    }else if(voltSide === VOLT_SIDE_THIRD){
        oldVolt = partThird.voltage;
        partThird.voltage = voltage;
        doSetVoltage(voltage,partThird.calcPoint0)
        doSetVoltage(voltage,partThird.calcPoint1)
    }
    return oldVolt;
}

const doReadSetDefaultVoltage = (partWinding:Iele3WindingPart,voltageItemDefault:any) => {
        if (voltageItemDefault === null){
            partWinding.voltage = 0
        }
        else{
        partWinding.voltage = voltageItemDefault.lvoltage;
        }
}

export const doSetValuesWindingTab1 = (idControl:any,partWinding:Iele3WindingPart) => {
    partWinding.ratedCurrent = BeamsNumber(doGetValueRatedCurrent(partWinding.capacity,partWinding.voltage),4);
    
    partWinding.ohmX = doGetValueOhmX(partWinding.voltage,partWinding.capacity,partWinding.perXk3)
    partWinding.ohmR = doGetValueOhmR(partWinding.voltage,partWinding.capacity,partWinding.perRk3);
    partWinding.ohmZ = doGetValueOhmZ(partWinding.ohmR,partWinding.ohmX);
    if(idControl != "IDC_PERCENT_Z"){
        partWinding.perZk3 = BeamsNumber(partWinding.perZk3,4);
    }
    if(idControl != "IDC_PERCENT_R"){
        partWinding.perRk3 = BeamsNumber(partWinding.perRk3,4);
    }
    if(idControl != "IDC_PERCENT_X"){
        partWinding.perXk3  = BeamsNumber(partWinding.perXk3,4);
    }
    if(idControl != "IDC_XR"){
        partWinding.xR = BeamsNumber(partWinding.xR,4);
    }
    partWinding.ohmZ = BeamsNumber(partWinding.ohmZ,4)
    partWinding.ohmR = BeamsNumber(partWinding.ohmR,4)
    partWinding.ohmX = BeamsNumber(partWinding.ohmX,4)

}

export const doSetValuesWindingTab4 = (nameControl:any,partWinding:Iele3WindingPart) => {
    const newObject = {
        dispInrush: false,
        inrushTimes: 0,
        inrushValue: 0,
        dispIntensity: false,
        intensityTimes: 0,
        intensityValue: 0,
        intensityTime: 0,
        dampingTime:0,
    }
    newObject.inrushTimes = doGetTextInRushTimes(partWinding);
    newObject.inrushValue = doGetTextInRushValue(partWinding);
    newObject.dampingTime = doGetTextDampingTime(partWinding);

    newObject.intensityTimes = doGetTextIntensityTimes(partWinding);
    newObject.intensityValue = doGetTextIntensityValue(partWinding);
    newObject.intensityTime = doGetTextIntensityTime(partWinding);

    if(partWinding.dispInrush === true){
        newObject.dispInrush = true
    }else{
        newObject.dispInrush = false;
    }
    if(partWinding.dispIntensity === true){
        newObject.dispIntensity = true
    }else{
        newObject.dispIntensity = false;
    }
    return newObject;
}



export const doGetTextVoltDropDiff  = (capacity:number,opeMode:number,partKind:number,Ms3Winding:Ms3WindingModel) => {
    let volt = 0
    const partPrimary = Ms3Winding.partPrimary;
    const partSecondary = Ms3Winding.partSecondary;
    const partThird = Ms3Winding.partThird;
    const percentZ = doGetPerZr3Winding(Ms3Winding, capacity)
    if(opeMode === MS_MODE_DRAWING){
        switch(partKind){
            case VOLT_SIDE_PRIMARY:
                volt = doGetValueDropVoltRegularCalcPoint(partPrimary.voltage,percentZ.perR1k3,percentZ.perX1k3,partPrimary.calcPoint0);
                volt = partPrimary.voltage - volt;
                break;
            case VOLT_SIDE_SECONDARY:
                volt = doGetValueDropVoltRegularCalcPoint(partSecondary.voltage,percentZ.perR2k3,percentZ.perX2k3,partSecondary.calcPoint0);
                volt = partSecondary.voltage - volt;
                break;
            case VOLT_SIDE_THIRD:
                volt = doGetValueDropVoltRegularCalcPoint(partThird.voltage,percentZ.perR3k3,percentZ.perX3k3,partThird.calcPoint0);
                volt = partThird.voltage - volt;
                break;
        }
    }
    else{
        if(Ms3Winding.supply){
            switch(partKind){
                case VOLT_SIDE_PRIMARY:
                    volt = doGetValueDropVoltRegularCalcPoint(partPrimary.voltage,percentZ.perR1k3,percentZ.perX1k3,partPrimary.calcPoint0);
                    volt = partPrimary.voltage - volt;
                    break;
                case VOLT_SIDE_SECONDARY:
                    volt = doGetValueDropVoltRegularCalcPoint(partSecondary.voltage,percentZ.perR2k3,percentZ.perX2k3,partSecondary.calcPoint0);
                    volt = partSecondary.voltage - volt;
                    break;
                case VOLT_SIDE_THIRD:
                    volt = doGetValueDropVoltRegularCalcPoint(partThird.voltage,percentZ.perR3k3,percentZ.perX3k3,partThird.calcPoint0);
                    volt = partThird.voltage - volt;
                    break;
            }
        }
    }
    return BeamsNumber(Number(volt),4);
} 

export const doGetTextDropPerUnit = (capacity:any,partKind:any,Ms3Winding:Ms3WindingModel,bUnit?:any) => {
    const percentZ = doGetPerZr3Winding(Ms3Winding, capacity);
    let volt = 0;
    let percent = 0;
    switch(partKind){
        case VOLT_SIDE_PRIMARY:
            const partPrimary = Ms3Winding.partPrimary;
            doSetCapacity(capacity,partPrimary.calcPoint0)
            volt = doGetValueDropVoltRegularCalcPoint(partPrimary.voltage,percentZ.perR1k3,percentZ.perX1k3,Ms3Winding.partPrimary.calcPoint0);
            percent = 1 - volt / partPrimary.voltage;
            percent *= 100;
            break;
        case VOLT_SIDE_SECONDARY:
            const partSecondary = Ms3Winding.partSecondary;
            doSetCapacity(capacity,partSecondary.calcPoint0)
            volt = doGetValueDropVoltRegularCalcPoint(partSecondary.voltage,percentZ.perR2k3,percentZ.perX2k3,Ms3Winding.partSecondary.calcPoint0);
            percent = 1 - volt / partSecondary.voltage;
            percent *= 100;
            break;
        case VOLT_SIDE_THIRD:
            const partThird = Ms3Winding.partThird;
            doSetCapacity(capacity,partThird.calcPoint0);
            volt = doGetValueDropVoltRegularCalcPoint(partThird.voltage,percentZ.perR3k3,percentZ.perX3k3,Ms3Winding.partThird.calcPoint0);
            percent = 1 - volt / partThird.voltage;
            percent *= 100;
            break;
    }
    return percent;
}

//CEle3Winding::DoGetValueDropVoltRegular
export const doGetValueDropVoltRegular = (props:Ms3WindingModel, dStdCapacity:number, nPartKind:number) => {
    let dVolt = 0;
    const partPrimary = props.partPrimary;
    const partSecondary = props.partSecondary;
    const partThird = props.partThird;
	let percentZ = doGetPerZr3Winding(props, dStdCapacity);
	switch (nPartKind) {
	case VOLT_SIDE_PRIMARY:			
		dVolt = doGetValueDropVoltRegularCalcPoint(partPrimary.voltage, percentZ.perR1k3, percentZ.perX1k3, partPrimary.calcPoint0);
		break;
	case VOLT_SIDE_SECONDARY:		
		dVolt = doGetValueDropVoltRegularCalcPoint(partSecondary.voltage, percentZ.perR2k3, percentZ.perX2k3, partSecondary.calcPoint0);
		break;
	case VOLT_SIDE_THIRD:			
		dVolt = doGetValueDropVoltRegularCalcPoint(partThird.voltage, percentZ.perR3k3, percentZ.perX3k3, partThird.calcPoint0);
		break;
	}

	return dVolt;
}

//CEle3Winding::DoGetTextVoltage
export const doGetTextVoltage = (props: Ms3WindingModel, nPartKind: number, bUnit: boolean = true) =>
{
	let sText = '';
	switch (nPartKind) {
	case VOLT_SIDE_PRIMARY:			
		sText = doGetTextVoltagePart(props.partPrimary);
		break;
	case VOLT_SIDE_SECONDARY:		
		sText = doGetTextVoltagePart(props.partSecondary);
		break;
	case VOLT_SIDE_THIRD:			
		sText = doGetTextVoltagePart(props.partThird);
		break;
	}
	if (bUnit) {
		sText += 'V';
	}
	return sText;
}

//CEle3Winding::DoGetTextCapacity
export const doGetTextCapacity = (props: Ms3WindingModel, nPartKind: number, bUnit: boolean = true) =>
{
	let sText = '';

	switch (nPartKind) {
	case VOLT_SIDE_PRIMARY:			
		sText = doGetTextCapacityPart(props.partPrimary);
		break;
	case VOLT_SIDE_SECONDARY:		
		sText = doGetTextCapacityPart(props.partSecondary);
		break;
	case VOLT_SIDE_THIRD:			
		sText = doGetTextCapacityPart(props.partThird);
		break;
	}
	if (bUnit) {
        sText += 'kVA';
	}
	return sText;
}

//CEle3Winding::DoGetTextRatedCurrent
export const doGetTextRatedCurrent = (props:Ms3WindingModel, nPartKind:number, bUnit:boolean = true) =>
{
	let sText = '';
	switch (nPartKind) {
	case VOLT_SIDE_PRIMARY:		
        sText = doGetTextRatedCurrentPart(props.partPrimary, bUnit);
		break;
	case VOLT_SIDE_SECONDARY:		
        sText = doGetTextRatedCurrentPart(props.partSecondary, bUnit);
		break;
	case VOLT_SIDE_THIRD:			
        sText = doGetTextRatedCurrentPart(props.partThird, bUnit);
		break;
	}
	return sText;
}

// CEle3Winding::DoGetTextPercentZe
export const doGetTextPercentZe = (props: Ms3WindingModel, nPartKind: number, bUnit: boolean = true) =>
{
	let sText = '';

	switch (nPartKind) {
	case VOLT_SIDE_PRIMARY:		
		sText = doGetTextPercentZePart(props.partPrimary);
		break;
	case VOLT_SIDE_SECONDARY:		
        sText = doGetTextPercentZePart(props.partSecondary);
		break;
	case VOLT_SIDE_THIRD:			
        sText = doGetTextPercentZePart(props.partThird);
		break;
	}
	if (bUnit) {
		sText += '%e';
	}
	return sText;
}

//CEle3Winding::DoGetTextXR
export const doGetTextXR = (props:Ms3WindingModel, nPartKind:number) =>
{
	let sText = '';
	switch (nPartKind) {
	case VOLT_SIDE_PRIMARY:			
		sText = doGetTextXRPart(props.partPrimary);
		break;
	case VOLT_SIDE_SECONDARY:		
        sText = doGetTextXRPart(props.partSecondary);
		break;
	case VOLT_SIDE_THIRD:		
        sText = doGetTextXRPart(props.partThird);
		break;
	}
	return sText;
}

//CEle3Winding::DoGetPerZr3Winding
export const doGetPerZr3Winding = (props: Ms3WindingModel, dStdCapacity: number) =>
{
	let percentZ = {} as PercentZ;
	if (props.partPrimary.capacity == 0 ||
		props.partSecondary.capacity == 0 ||
		props.partThird.capacity == 0) {
        percentZ.perR1k3 = 0;
		percentZ.perX1k3 = 0;
		percentZ.perR2k3 = 0;
		percentZ.perX2k3 = 0;
		percentZ.perR3k3 = 0;
		percentZ.perX3k3 = 0;
	}
	else {
		percentZ.perR1k3 = 0.5 * dStdCapacity * (props.partPrimary.perRk3 / props.partPrimary.capacity + props.partThird.perRk3 / props.partThird.capacity - props.partSecondary.perRk3 / props.partSecondary.capacity);
		percentZ.perX1k3 = 0.5 * dStdCapacity * (props.partPrimary.perXk3 / props.partPrimary.capacity + props.partThird.perXk3 / props.partThird.capacity - props.partSecondary.perXk3 / props.partSecondary.capacity);
		percentZ.perR2k3 = 0.5 * dStdCapacity * (props.partPrimary.perRk3 / props.partPrimary.capacity + props.partSecondary.perRk3 / props.partSecondary.capacity - props.partThird.perRk3 / props.partThird.capacity);
		percentZ.perX2k3 = 0.5 * dStdCapacity * (props.partPrimary.perXk3 / props.partPrimary.capacity + props.partSecondary.perXk3 / props.partSecondary.capacity - props.partThird.perXk3 / props.partThird.capacity);
		percentZ.perR3k3 = 0.5 * dStdCapacity * (props.partSecondary.perRk3 / props.partSecondary.capacity + props.partThird.perRk3 / props.partThird.capacity - props.partPrimary.perRk3 / props.partPrimary.capacity);
		percentZ.perX3k3 = 0.5 * dStdCapacity * (props.partSecondary.perXk3 / props.partSecondary.capacity + props.partThird.perXk3 / props.partThird.capacity - props.partPrimary.perXk3 / props.partPrimary.capacity);
	}

	return percentZ;
}

//CEle3Winding::DoGetTextPercentZr
export const doGetTextPercentZr = (props:Ms3WindingModel, nPartKind:number, dStdCapacity:number, bUnit:boolean = true) =>
{
	let dPercentZ = 0;

	let percentZ = doGetPerZr3Winding(props, dStdCapacity);
	switch (nPartKind) {
	case VOLT_SIDE_PRIMARY:			
		dPercentZ = doGetValuePerZk33Winding(percentZ, VOLT_SIDE_PRIMARY).dPercentZ;
		break;
	case VOLT_SIDE_SECONDARY:		
		dPercentZ = doGetValuePerZk33Winding(percentZ, VOLT_SIDE_SECONDARY).dPercentZ;
		break;
	case VOLT_SIDE_THIRD:			
		dPercentZ = doGetValuePerZk33Winding(percentZ, VOLT_SIDE_THIRD).dPercentZ;
		break;
	}
	let sText = BeamsNumber(dPercentZ, 4).toString();
	if (bUnit) {
		sText += '%r';
	}
	return sText;
}

//CEle3Winding::DoGetTextPercentRr
export const doGetTextPercentRr = (props:Ms3WindingModel, nPartKind:number, dStdCapacity:number, bUnit:boolean = true) =>
{
	let sText = '';

	let percentZ = doGetPerZr3Winding(props, dStdCapacity);
	switch (nPartKind) {
	case VOLT_SIDE_PRIMARY:			
		sText = BeamsNumber(percentZ.perR1k3, 4).toString();
		break;
	case VOLT_SIDE_SECONDARY:		
		sText = BeamsNumber(percentZ.perR2k3, 4).toString();
		break;
	case VOLT_SIDE_THIRD:			
		sText = BeamsNumber(percentZ.perR3k3, 4).toString();
		break;
	}
	if (bUnit) {
		sText += '%r';
	}
	return sText;
}

//CEle3Winding::DoGetTextPercentXr
export const doGetTextPercentXr = (props:Ms3WindingModel, nPartKind:number, dStdCapacity:number, bUnit:boolean = true) =>
{
	let sText = '';

	let percentZ = doGetPerZr3Winding(props, dStdCapacity);
	switch (nPartKind) {
	case VOLT_SIDE_PRIMARY:			
		sText = BeamsNumber(percentZ.perX1k3, 4).toString();
		break;
	case VOLT_SIDE_SECONDARY:		
		sText = BeamsNumber(percentZ.perX2k3, 4).toString();
		break;
	case VOLT_SIDE_THIRD:			
		sText = BeamsNumber(percentZ.perX3k3, 4).toString();
		break;
	}
	if (bUnit) {
		sText += '%r';
	}
	return sText;
}

//CEle3Winding::DoGetTextDirectIk3peak
export const doGetTextDirectIk3peak = (props:Ms3WindingModel, nPartKind:number, bUnit: boolean = true ) => {
    let sText = "";

	switch (nPartKind) {
	case VOLT_SIDE_PRIMARY:			
        sText = doGetTextDirectIk3peakPart(props.partPrimary).toString();
		break;
	case VOLT_SIDE_SECONDARY:		
        sText = doGetTextDirectIk3peakPart(props.partSecondary).toString();
		break;
	case VOLT_SIDE_THIRD:			
        sText = doGetTextDirectIk3peakPart(props.partThird).toString();
		break;
	}
    if (bUnit) {
        sText += 'kApeak';
    }
	return sText;
}

//CEle3Winding::DoGetTextDirectIk3asym
export const doGetTextDirectIk3asym = (props:Ms3WindingModel, nPartKind:number, bUnit:boolean = true) =>
{
	let sText = '';

	switch (nPartKind) {
	case VOLT_SIDE_PRIMARY:			
        sText = doGetTextDirectIk3asymPart(props.partPrimary);
		break;
	case VOLT_SIDE_SECONDARY:		
        sText = doGetTextDirectIk3asymPart(props.partSecondary);
		break;
	case VOLT_SIDE_THIRD:		
        sText = doGetTextDirectIk3asymPart(props.partThird);
		break;
	}
    if(bUnit) {
        sText += 'kAasym';
    }

	return sText;
}

//CEle3Winding::DoGetTextDirectIk3sym
export const doGetTextDirectIk3sym = (props:Ms3WindingModel, nPartKind:number, bUnit:boolean = true) =>
{
	let sText = '';

	switch (nPartKind) {
	case VOLT_SIDE_PRIMARY:			
        sText = doGetTextDirectIk3symPart(props.partPrimary);
		break;
	case VOLT_SIDE_SECONDARY:		
        sText = doGetTextDirectIk3symPart(props.partSecondary);
		break;
	case VOLT_SIDE_THIRD:		
        sText = doGetTextDirectIk3symPart(props.partThird);
		break;
	}
    if(bUnit) {
        sText += 'kAsym';
    }

	return sText;
}

//CEle3Winding::DoGetTextTotalIk3sym
export const doGetTextTotalIk3sym = (props:Ms3WindingModel, nPartKind:number, bUnit:boolean = true) =>
{
	let sText = '';

	switch (nPartKind) {
	case VOLT_SIDE_PRIMARY:			
        sText = doGetTextTotalIk3symPart(props.partPrimary);
		break;
	case VOLT_SIDE_SECONDARY:		
        sText = doGetTextTotalIk3symPart(props.partSecondary);
		break;
	case VOLT_SIDE_THIRD:		
        sText = doGetTextTotalIk3symPart(props.partThird);
		break;
	}
    if(bUnit) {
        sText += 'kAsym';
    }
	return sText;
}

//CEle3Winding::DoGetTextLoadCurrent
export const doGetTextLoadCurrent = (props:Ms3WindingModel, nPartKind:number, bUnit: boolean = true) => {
    let sText = '';
    switch(nPartKind) {
        case VOLT_SIDE_PRIMARY:
            sText = doGetTextLoadCurrentPart(props.partPrimary);
            break;
        case VOLT_SIDE_SECONDARY:
            sText = doGetTextLoadCurrentPart(props.partSecondary);;
            break;
        case VOLT_SIDE_THIRD:
            sText = doGetTextLoadCurrentPart(props.partThird);;
            break;
    }
    if(bUnit) {
        sText += 'A';
    }
    return sText;
}

//CEle3Winding::DoGetTextDropVoltPF
export const doGetTextDropVoltPF = (props:Ms3WindingModel, nPartKind:number, bUnit: boolean = true) => {
    let sText = '';
    switch (nPartKind) {
        case VOLT_SIDE_PRIMARY:			
            sText = doGetTextDropVoltPFPart(props.partPrimary, bUnit);
            break;
        case VOLT_SIDE_SECONDARY:		
            sText = doGetTextDropVoltPFPart(props.partSecondary, bUnit);
            break;
        case VOLT_SIDE_THIRD:		
            sText = doGetTextDropVoltPFPart(props.partThird, bUnit);
            break;
        }
    return sText;
}

//CEle3Winding::DoGetTextDropVoltDiff
export const doGetTextDropVoltDiff = (props:Ms3WindingModel, dStdCapacity: number, nOpeMode: number, nPartKind:number, bUnit: boolean = true) => 
{
    let dVolt = 0;
	let percentZ = doGetPerZr3Winding(props, dStdCapacity);

	if (nOpeMode == MS_MODE_DRAWING) {
		switch (nPartKind) {
		case VOLT_SIDE_PRIMARY:			
			dVolt = doGetValueDropVoltRegularCalcPoint(props.partPrimary.voltage, percentZ.perR1k3, percentZ.perX1k3, props.partPrimary.calcPoint0);
			dVolt = props.partPrimary.voltage - dVolt;
			break;
		case VOLT_SIDE_SECONDARY:		
			dVolt = doGetValueDropVoltRegularCalcPoint(props.partSecondary.voltage, percentZ.perR2k3, percentZ.perX2k3, props.partSecondary.calcPoint0);
			dVolt = props.partSecondary.voltage - dVolt;
			break;
		case VOLT_SIDE_THIRD:			
			dVolt = doGetValueDropVoltRegularCalcPoint(props.partThird.voltage, percentZ.perR3k3, percentZ.perX3k3, props.partThird.calcPoint0);
			dVolt = props.partThird.voltage - dVolt;
			break;
		}
	}
	else {
		if (props.supply) {
			switch (nPartKind) {
                case VOLT_SIDE_PRIMARY:			
                dVolt = doGetValueDropVoltRegularCalcPoint(props.partPrimary.voltage, percentZ.perR1k3, percentZ.perX1k3, props.partPrimary.calcPoint0);
                dVolt = props.partPrimary.voltage - dVolt;
                break;
            case VOLT_SIDE_SECONDARY:		
                dVolt = doGetValueDropVoltRegularCalcPoint(props.partSecondary.voltage, percentZ.perR2k3, percentZ.perX2k3, props.partSecondary.calcPoint0);
                dVolt = props.partSecondary.voltage - dVolt;
                break;
            case VOLT_SIDE_THIRD:			
                dVolt = doGetValueDropVoltRegularCalcPoint(props.partThird.voltage, percentZ.perR3k3, percentZ.perX3k3, props.partThird.calcPoint0);
                dVolt = props.partThird.voltage - dVolt;
                break;
			}
		}
	}

	let sText = BeamsNumber(dVolt, 4).toString();
	if (bUnit) {
		sText += 'V';
	}
	return sText;
}

//CEle3Winding::DoGetTextDropVoltRegular
export const doGetTextDropVoltRegular = (props:Ms3WindingModel, dStdCapacity: number, nPartKind:number, bUnit: boolean = true) => {
    let sText = '';
	let percentZ = doGetPerZr3Winding(props, dStdCapacity);
	switch (nPartKind) {
	case VOLT_SIDE_PRIMARY:			
		if (props.supply) {
			sText = doGetTextDropVoltRegularPart(props.partPrimary, props.partPrimary.voltage, percentZ.perR1k3, percentZ.perX1k3);
		}
		else {
			sText = doGetTextDropVoltRegularPart(props.partPrimary, 0, percentZ.perR1k3, percentZ.perX1k3);
		}
		break;
	case VOLT_SIDE_SECONDARY:	
		if (props.supply) {
			sText = doGetTextDropVoltRegularPart(props.partSecondary, props.partSecondary.voltage, percentZ.perR2k3, percentZ.perX2k3);
		}
		else {
			sText = doGetTextDropVoltRegularPart(props.partSecondary, 0, percentZ.perR2k3, percentZ.perX2k3);
		}
		break;
	case VOLT_SIDE_THIRD:			
		if (props.supply) {
			sText = doGetTextDropVoltRegularPart(props.partThird, props.partThird.voltage, percentZ.perR3k3, percentZ.perX3k3);
		}
		else {
			sText = doGetTextDropVoltRegularPart(props.partThird, 0, percentZ.perR3k3, percentZ.perX3k3);
		}
		break;
	}
	if (bUnit) {
		sText += 'V';
	}
	return sText;
}

//CEle3Winding::DoGetTextDropPerRegular
export const doGetTextDropPerRegular = (props:Ms3WindingModel, dStdCapacity: number, nPartKind:number, bUnit: boolean = true) => {
    let sText = '';
	let percentZ = doGetPerZr3Winding(props, dStdCapacity);
	switch (nPartKind) {
	case VOLT_SIDE_PRIMARY:			
		if (props.supply) {
			sText = doGetTextDropPerRegularPart(props.partPrimary, props.partPrimary.voltage, percentZ.perR1k3, percentZ.perX1k3);
		}
		else {
			sText = doGetTextDropPerRegularPart(props.partPrimary, 0, percentZ.perR1k3, percentZ.perX1k3);
		}
		break;
	case VOLT_SIDE_SECONDARY:		
		if (props.supply) {
			sText = doGetTextDropPerRegularPart(props.partSecondary, props.partSecondary.voltage, percentZ.perR2k3, percentZ.perX2k3);
		}
		else {
			sText = doGetTextDropPerRegularPart(props.partSecondary, 0, percentZ.perR2k3, percentZ.perX2k3);
		}
		break;
	case VOLT_SIDE_THIRD:			
		if (props.supply) {
			sText = doGetTextDropPerRegularPart(props.partThird, props.partThird.voltage, percentZ.perR3k3, percentZ.perX3k3);
		}
		else {
			sText = doGetTextDropPerRegularPart(props.partThird, 0, percentZ.perR3k3, percentZ.perX3k3);
		}
		break;
	}
	if (bUnit) {
		sText += '%';
	}
	return sText;
}

//CEle3Winding::DoGetTextDropPerRegular2
export const doGetTextDropPerRegular2 = (props:Ms3WindingModel, dStdCapacity:number, nPartKind:number, bUnit:boolean = true) =>
{
	let sText = '';
	let percentZ = doGetPerZr3Winding(props, dStdCapacity);
	switch (nPartKind) {
	case VOLT_SIDE_PRIMARY:			
		if (props.supply) {
			sText = doGetTextDropPerRegular2Part(props.partPrimary, props.partPrimary.voltage, percentZ.perR1k3, percentZ.perX1k3);
		}
		else {
			sText = doGetTextDropPerRegular2Part(props.partPrimary, 0, percentZ.perR1k3, percentZ.perX1k3);
		}
		break;
	case VOLT_SIDE_SECONDARY:		
		if (props.supply ) {
			sText = doGetTextDropPerRegular2Part(props.partSecondary, props.partSecondary.voltage, percentZ.perR2k3, percentZ.perX2k3);
		}
		else {
			sText = doGetTextDropPerRegular2Part(props.partSecondary, 0, percentZ.perR2k3, percentZ.perX2k3);
		}
		break;
	case VOLT_SIDE_THIRD:			
		if (props.supply) {
			sText = doGetTextDropPerRegular2Part(props.partThird, props.partThird.voltage, percentZ.perR3k3, percentZ.perX3k3);
		}
		else {
			sText = doGetTextDropPerRegular2Part(props.partThird, 0, percentZ.perR3k3, percentZ.perX3k3);
		}
		break;
	}
	if (bUnit) {
		sText += '%';
	}
	return sText;
}

//CEle3Winding::IsPointFaultAND3Winding
export const isPointFaultAND3Winding = (props:Ms3WindingModel) =>
{
	if (props.partSecondary.fault && props.partThird.fault) {
		return true;
	}
	return false;
}

//CEle3WindingPart::DoGetTextPointState
export const doGetTextPointState = (props:Iele3WindingPart) =>
{
	let sText = ''; 

	if (props.fault) {
		sText = 'Yes';
	}
	else {
		sText = 'No';
	}

	return sText;
}

//CEle3Winding::DoGetRefV11
export const doGetRefV11 = (mode:any, control:ControlModel, bSociety:boolean) =>
{
    let props = control.properties as Ms3WindingModel;
    let infoCircuit = store.getState().app.diagram.infoCircuit;
    let project = store.getState().app.projectData;

	//CEle3Winding:DoGetRefV1Left  sRefV1Left
	let labelTexts = [{
		value: control.properties.refNo,
		color: "#f800f8",
	}];

	if (!props.viewResultText || (mode != ProcessMode.DRAWING && !props.supply))
    {
      return labelTexts;
    }
    
    if (bSociety){

        // VOLT_SIDE_PRIMARY / VOLT_SIDE_SECONDARY / VOLT_SIDE_THIRD
        labelTexts.push({
            value: doGetTextVoltage(props, VOLT_SIDE_PRIMARY)
            + '/'
            + doGetTextVoltage(props, VOLT_SIDE_SECONDARY)
            + '/'
            + doGetTextVoltage(props, VOLT_SIDE_THIRD),
            color: "blue",
        });
        
        // 一次側
        labelTexts.push({
            value: '一次側',
            color: "blue",
        });

        //Capacity
        labelTexts.push({
            value: doGetTextCapacity(props, VOLT_SIDE_PRIMARY),
            color: "blue",
        });

         // 二次側
         labelTexts.push({
            value: ' 二次側',
            color: "blue",
        });

        //Capacity
        labelTexts.push({
        value: doGetTextCapacity(props, VOLT_SIDE_SECONDARY),
        color: "blue",
        });
        return labelTexts;
    }

    switch(mode) {
        case ProcessMode.DRAWING:
        case ProcessMode.CHECKING:
            // VOLT_SIDE_PRIMARY / VOLT_SIDE_SECONDARY / VOLT_SIDE_THIRD
            labelTexts.push({
                value: doGetTextVoltage(props, VOLT_SIDE_PRIMARY)
                + '/'
                + doGetTextVoltage(props, VOLT_SIDE_SECONDARY)
                + '/'
                + doGetTextVoltage(props, VOLT_SIDE_THIRD),
                color: "blue",
            });

            // 一次側
            labelTexts.push({
                value: '一次側',
                color: "blue",
            });

            //Capacity
            labelTexts.push({
                value: doGetTextCapacity(props, VOLT_SIDE_PRIMARY),
                color: "blue",
            });
            
            // RatedCurrent,PercentZe
            labelTexts.push({
                value: doGetTextRatedCurrent(props, VOLT_SIDE_PRIMARY)
                + ','
                + doGetTextPercentZe(props, VOLT_SIDE_PRIMARY),
                color: "blue",
            });

            //X/R=
            labelTexts.push({
                value: 'X/R='
                + doGetTextXR(props, VOLT_SIDE_PRIMARY),
                color: "blue",
            });

             // 二次側
            labelTexts.push({
                value: ' 二次側',
                color: "blue",
            });

            //Capacity
            labelTexts.push({
                value: doGetTextCapacity(props, VOLT_SIDE_SECONDARY),
                color: "blue",
            });
            
            // RatedCurrent,PercentZe
            labelTexts.push({
                value: doGetTextRatedCurrent(props, VOLT_SIDE_SECONDARY)
                + ','
                + doGetTextPercentZe(props, VOLT_SIDE_SECONDARY),
                color: "blue",
            });

            //X/R=
            labelTexts.push({
                value: 'X/R='
                + doGetTextXR(props, VOLT_SIDE_SECONDARY),
                color: "blue",
            });
            break;
        case ProcessMode.PERCENT_Z:
             // 一次側
            labelTexts.push({
                value: '一次側',
                color: "blue",
            });

            // X/R=
            labelTexts.push({
            value: 'X/R='
            + doGetTextXR(props, VOLT_SIDE_PRIMARY),
            color: "blue",
            });

            // Zr=Rr+jXr
            labelTexts.push({
                value: doGetTextPercentZr(props, VOLT_SIDE_PRIMARY, infoCircuit.stdCapacity)
                + '='
                + doGetTextPercentRr(props, VOLT_SIDE_PRIMARY, infoCircuit.stdCapacity, false)
                + '+j'
                + doGetTextPercentXr(props, VOLT_SIDE_PRIMARY, infoCircuit.stdCapacity, false),
                color: "blue",
            });

            // 二次側
            labelTexts.push({
                value: ' 二次側',
                color: "blue",
            });

             // X/R=
             labelTexts.push({
                value: 'X/R='
                + doGetTextXR(props, VOLT_SIDE_SECONDARY),
                color: "blue",
            });
    
             // Zr=Rr+jXr
             labelTexts.push({
                value: doGetTextPercentZr(props, VOLT_SIDE_SECONDARY, infoCircuit.stdCapacity)
                + '='
                + doGetTextPercentRr(props, VOLT_SIDE_SECONDARY, infoCircuit.stdCapacity, false)
                + '+j'
                + doGetTextPercentXr(props, VOLT_SIDE_SECONDARY, infoCircuit.stdCapacity, false),
                color: "blue",
            });

            break;
        case ProcessMode.CALCULATION:
             // VOLT_SIDE_PRIMARY / VOLT_SIDE_SECONDARY / VOLT_SIDE_THIRD
             labelTexts.push({
                value: doGetTextVoltage(props, VOLT_SIDE_PRIMARY)
                + '/'
                + doGetTextVoltage(props, VOLT_SIDE_SECONDARY)
                + '/'
                + doGetTextVoltage(props, VOLT_SIDE_THIRD),
                color: "blue",
            });

            if(props.partSecondary.fault) {
                // 二次側
                labelTexts.push({
                    value: '二次側',
                    color: "blue",
                });

                 // += 'I"k3' + '通過' + '=' + doGetTextDirectIk3peak()
                 labelTexts.push({
                    value: 'I"k3通過='
                    + doGetTextDirectIk3peak(props, VOLT_SIDE_SECONDARY),
                    color: "blue",
                });

                // += 'I"k3' + '通過' + '=' + doGetTextDirectIk3asym()
                labelTexts.push({
                    value: 'I"k3通過='
                    + doGetTextDirectIk3asym(props, VOLT_SIDE_SECONDARY),
                    color: "blue",
                });

                 // += 'I"k3' + '通過' + '=' + doGetTextDirectIk3sym()
                labelTexts.push({
                    value: 'I"k3通過='
                    + doGetTextDirectIk3sym(props, VOLT_SIDE_SECONDARY),
                    color: "blue",
                });

                 // += 'I"k3' + '合計' + '=' + doGetTextTotalIk3sym()
                 labelTexts.push({
                    value: 'I"k3合計='
                    + doGetTextTotalIk3sym(props, VOLT_SIDE_SECONDARY),
                    color: "blue",
                });
            }
            break;
        case ProcessMode.VOLTDROP:
             // VOLT_SIDE_PRIMARY / VOLT_SIDE_SECONDARY / VOLT_SIDE_THIRD
             labelTexts.push({
                value: doGetTextVoltage(props, VOLT_SIDE_PRIMARY)
                + '/'
                + doGetTextVoltage(props, VOLT_SIDE_SECONDARY)
                + '/'
                + doGetTextVoltage(props, VOLT_SIDE_THIRD),
                color: "blue",
            });

            if (project.dropMode == MS_VOLTDROP_REGULAR && props.partSecondary.dropRegular) {
                  // 二次側
                labelTexts.push({
                    value: '二次側',
                    color: "blue",
                });
                
                //IL=doGetTextLoadCurrent,力率=doGetTextDropVoltPF
                labelTexts.push({
                    value: 'IL='
                    + doGetTextLoadCurrent(props, VOLT_SIDE_SECONDARY)
                    + ',力率='
                    + doGetTextDropVoltPF(props, VOLT_SIDE_SECONDARY),
                    color: "blue",
                });
                
                //Vd=doGetTextDropVoltDiff (doGetTextVoltage-doGetTextDropVoltRegular)
                let sTemp = 'Vd=';
                sTemp += doGetTextDropVoltDiff(props, infoCircuit.stdCapacity, project.opeMode, VOLT_SIDE_SECONDARY);
                sTemp += ' (';
                if(props.supply) {
                    sTemp += doGetTextVoltage(props, VOLT_SIDE_SECONDARY, false);
                }
                else {
                    sTemp += '0';
                }
                sTemp += '-';
                sTemp += doGetTextDropVoltRegular(props, infoCircuit.stdCapacity, VOLT_SIDE_SECONDARY, false);
                sTemp += ')';
                labelTexts.push({
                    value: sTemp,
                    color: "blue",
                });

                //Vp=doGetTextDropPerRegular (doGetTextDropPerRegular2)
                labelTexts.push({
                    value: 'Vp='
                    + doGetTextDropPerRegular(props, infoCircuit.stdCapacity, VOLT_SIDE_SECONDARY)
                    + ' ('
                    + doGetTextDropPerRegular2(props, infoCircuit.stdCapacity, VOLT_SIDE_SECONDARY)
                    + ')',
                    color: "blue",
                });
            }
            break;
        case ProcessMode.HARMONIC:
             // VOLT_SIDE_PRIMARY / VOLT_SIDE_SECONDARY / VOLT_SIDE_THIRD
             labelTexts.push({
                value: doGetTextVoltage(props, VOLT_SIDE_PRIMARY)
                + '/'
                + doGetTextVoltage(props, VOLT_SIDE_SECONDARY)
                + '/'
                + doGetTextVoltage(props, VOLT_SIDE_THIRD),
                color: "blue",
            });
            break;
        case ProcessMode.POWER_FLOW:
            break;
    }
    return labelTexts;
}

//CEle3Winding::DoGetRefV13
export const doGetRefV13 = (mode:any, control:ControlModel, bSociety:boolean) =>
{
    let props = control.properties as Ms3WindingModel;
    let infoCircuit = store.getState().app.diagram.infoCircuit;
    let project = store.getState().app.projectData;

	//CEle3Winding::DoGetRefV1Left sRefV1Left
	let labelTexts = [{
		value: '',
		color: '',
	}];

	if (!props.viewResultText || (mode != ProcessMode.DRAWING && !props.supply))
    {
      return labelTexts;
    }
    
    if (bSociety){
        // 三次側
        labelTexts.push({
            value: '三次側',
            color: "blue",
        });
        
        // Capacity
        labelTexts.push({
            value: doGetTextCapacity(props, VOLT_SIDE_THIRD),
            color: "blue",
        });
        
        return labelTexts;
    }

    switch (mode) {
        case ProcessMode.DRAWING:
        case ProcessMode.CHECKING:
              // 三次側
            labelTexts.push({
                value: '三次側',
                color: "blue",
            });

             // Capacity
            labelTexts.push({
                value: doGetTextCapacity(props, VOLT_SIDE_THIRD),
                color: "blue",
            });

            // doGetTextRatedCurrent,doGetTextPercentZe
            labelTexts.push({
                value: doGetTextRatedCurrent(props, VOLT_SIDE_THIRD)
                + ','
                + doGetTextPercentZe(props, VOLT_SIDE_THIRD),
                color: "blue",
            });
            
            // X/R=doGetTextXR
            labelTexts.push({
                value: 'X/R='
                + doGetTextXR(props, VOLT_SIDE_THIRD),
                color: "blue",
            });
            break;
        case ProcessMode.PERCENT_Z:
              // 三次側
              labelTexts.push({
                value: '三次側',
                color: "blue",
            });

             // X/R=doGetTextXR
             labelTexts.push({
                value: 'X/R='
                + doGetTextXR(props, VOLT_SIDE_THIRD),
                color: "blue",
            });

             // Zr=Rr+jXr
             labelTexts.push({
                value: doGetTextPercentZr(props, VOLT_SIDE_THIRD, infoCircuit.stdCapacity)
                + '='
                + doGetTextPercentRr(props, VOLT_SIDE_THIRD, infoCircuit.stdCapacity, false)
                + '+j'
                + doGetTextPercentXr(props, VOLT_SIDE_THIRD, infoCircuit.stdCapacity, false),
                color: "blue",
            });
            break;
        case ProcessMode.CALCULATION:
            if(props.partThird.fault) {
                 // 三次側
                labelTexts.push({
                    value: '三次側',
                    color: "blue",
                });

                // += 'I"k3' + '通過' + '=' + doGetTextDirectIk3peak()
                labelTexts.push({
                    value: 'I"k3通過='
                    + doGetTextDirectIk3peak(props, VOLT_SIDE_THIRD),
                    color: "blue",
                });

                // += 'I"k3' + '通過' + '=' + doGetTextDirectIk3asym()
                labelTexts.push({
                    value: 'I"k3通過='
                    + doGetTextDirectIk3asym(props, VOLT_SIDE_THIRD),
                    color: "blue",
                });

                // += 'I"k3' + '通過' + '=' + doGetTextDirectIk3sym()
                labelTexts.push({
                    value: 'I"k3通過='
                    + doGetTextDirectIk3sym(props, VOLT_SIDE_THIRD),
                    color: "blue",
                });

                // += 'I"k3' + '合計' + '=' + doGetTextTotalIk3sym()
                labelTexts.push({
                    value: 'I"k3合計='
                    + doGetTextTotalIk3sym(props, VOLT_SIDE_THIRD),
                    color: "blue",
                });
            }
            break;
        case ProcessMode.VOLTDROP:
            if(project.dropMode == MS_VOLTDROP_REGULAR && props.partThird.dropRegular) {
                 // 三次側
                 labelTexts.push({
                    value: '三次側',
                    color: "blue",
                });
                
                // IL=doGetTextLoadCurrent,力率=doGetTextDropVoltPF
                labelTexts.push({
                    value: 'IL='
                    + doGetTextLoadCurrent(props, VOLT_SIDE_THIRD)
                    + ',力率='
                    + doGetTextDropVoltPF(props, VOLT_SIDE_THIRD),
                    color: "blue",
                });

                //Vd=doGetTextDropVoltDiff (doGetTextVoltage-doGetTextDropVoltRegular)
                let sTemp = 'Vd=';
                sTemp += doGetTextDropVoltDiff(props, infoCircuit.stdCapacity, project.opeMode, VOLT_SIDE_THIRD);
                sTemp += ' (';
                if(props.supply) {
                    sTemp += doGetTextVoltage(props, VOLT_SIDE_THIRD, false);
                }
                else {
                    sTemp += '0';
                }
                sTemp += '-';
                sTemp += doGetTextDropVoltRegular(props, infoCircuit.stdCapacity, VOLT_SIDE_THIRD, false);
                sTemp += ')';
                labelTexts.push({
                    value: sTemp,
                    color: "blue",
                });

                //Vp=doGetTextDropPerRegular (doGetTextDropPerRegular2)
                labelTexts.push({
                    value: 'Vp='
                    + doGetTextDropPerRegular(props, infoCircuit.stdCapacity, VOLT_SIDE_THIRD)
                    + ' ('
                    + doGetTextDropPerRegular2(props, infoCircuit.stdCapacity, VOLT_SIDE_THIRD)
                    + ')',
                    color: "blue",
                });
            }
            break;
        case ProcessMode.HARMONIC:
		    break;
	    case ProcessMode.POWER_FLOW:
		    break;
    }
    return labelTexts;
}

export const doGetRefV2_0 = (
    properties: Ms3WindingModel,
    dNominalVolt: number,
    nPartKind: number,
    inrushTimeAndDampingTimeKind: number
) => {
    let infos: string[] = [];

    const text = properties.refNo;
    const name = properties.partSecondary.pointText;
    let partName = ''
    let dRatedCurrent: number = 0;
    let m_lVoltage: number = 0;
    let dCapacity: number = 0;
    let dInrushTimes = 0;
    let dInrushDampingTime = 0;
    if(nPartKind === 1){
        partName = '一次側'        
        m_lVoltage = properties.partPrimary.voltage;
        dCapacity = properties.partPrimary.capacity;  
        dRatedCurrent = doGetValueRatedCurrent(m_lVoltage,dCapacity);   
    }
    if(nPartKind === 2){
        partName = '二次側'        
        m_lVoltage = properties.partSecondary.voltage;
        dCapacity = properties.partSecondary.capacity;   
        dRatedCurrent = doGetValueRatedCurrent(m_lVoltage,dCapacity);   
    }
    if(nPartKind === 3){
        partName = '三次側'        
        m_lVoltage = properties.partThird.voltage;
        dCapacity = properties.partThird.capacity;   
        dRatedCurrent = doGetValueRatedCurrent(m_lVoltage,dCapacity);       
    }
    infos.push(text);
    infos.push(partName + `,` + name);
    infos.push(Math.round(dCapacity).toString() +`kVA`);

    if(m_lVoltage === dNominalVolt){
        infos.push(Math.round(dRatedCurrent) +`A`)
    } else {
        const dRateCurrent1 = dRatedCurrent;
        infos.push(Math.round(dRateCurrent1 * m_lVoltage/dNominalVolt) +`A` +`(`+Math.round(dRatedCurrent)+`A)`)
    }

    if(inrushTimeAndDampingTimeKind === 2){
        dInrushTimes = properties.partSecondary.inrushTimes;
        dInrushDampingTime = properties.partSecondary.dampingTime;
    }
    if(inrushTimeAndDampingTimeKind === 3){
        dInrushTimes = properties.partThird.inrushTimes;
        dInrushDampingTime = properties.partThird.dampingTime;
    }

    infos.push(dInrushTimes.toString() +`倍` +`,` +dInrushDampingTime +`ms` )
    return infos;
}

export const doGetRefV2_1 = (
    properties: Ms3WindingModel,
    dNominalVolt: number,
    nPartKind: number,
    intensityTimesAndDampingTimeKind: number
) => {
    let infos = [];
    const text = properties.refNo;
    const name = properties.partThird.pointText;
    let partName = ''
    let m_lVoltage: number = 0;
    let dCapacity: number = 0;
    let dRatedCurrent: number =0;
    let intensityTimes = 0;
    let intensityTime = 0;
    let intensity = 0;

    if(nPartKind === 1){  
        partName = '一次側'      
        m_lVoltage = properties.partPrimary.voltage;
        dCapacity = properties.partPrimary.capacity;
        dRatedCurrent = doGetValueRatedCurrent(m_lVoltage,dCapacity);
        intensity = Math.round(doGetTextIntensityValue(properties.partPrimary));
    }
    if(nPartKind === 2){    
        partName = '二次側'      
        m_lVoltage = properties.partSecondary.voltage;
        dCapacity = properties.partSecondary.capacity;
        dRatedCurrent = doGetValueRatedCurrent(m_lVoltage,dCapacity);
        intensity = Math.round(doGetTextIntensityValue(properties.partSecondary));
    }
    if(nPartKind === 3){      
        partName = '三次側'    
        m_lVoltage = properties.partThird.voltage;
        dCapacity = properties.partThird.capacity;    
        dRatedCurrent = doGetValueRatedCurrent(m_lVoltage,dCapacity);
        intensity = Math.round(doGetTextIntensityValue(properties.partThird));
    }
    infos.push(text);
    infos.push(partName + `,` + name);
    infos.push(dCapacity+`kVA`);

    if(intensityTimesAndDampingTimeKind === 2){
        intensityTimes = properties.partSecondary.intensityTimes;
        intensityTime = properties.partSecondary.intensityTime;
    }
    if(intensityTimesAndDampingTimeKind === 3){
        intensityTimes = properties.partThird.intensityTimes;
        intensityTime = properties.partThird.intensityTime;
    }
    infos.push(intensityTimes +`倍,`)
    // const intensity =Math.round(doGetTextIntensityValue(m_lVoltage,dCapacity,intensityTimes))
    if(m_lVoltage === dNominalVolt){
        infos.push(intensity.toString() +`Arms`)
    }
    else{
        let dIntensityValue = 0;
        let dRatedCurrent1 = dRatedCurrent;
        dIntensityValue = intensityTimes * dRatedCurrent1;
        infos.push(Math.round(dIntensityValue * m_lVoltage /dNominalVolt).toString()+`Arms` +`(`+intensity+`)`)
    }
    infos.push(intensityTime.toString() +`s`)
    return infos
}

//CEle3WindingPart::DoGetTextDirectPercentZr
export const doGetTextDirectPercentZrPart = (props:Iele3WindingPart) =>
{
	let dPerZ = doGetPerZDirectMG(props.calcPoint0).dPercentZ;
	let sText = '';
	if (dPerZ >= 0) {
		sText = BeamsNumber(dPerZ, 4).toString();
	}
	else {							// 短絡電流が正しく計算されなかったとき
		sText = '????';
	}

	return sText;
}

//CEle3Winding::DoGetTextDirectPercentZr
export const doGetTextDirectPercentZr = (props:ControlModel, nPartKind:number, bUnit:boolean = true) =>
{
	let sText = "";

	switch (nPartKind) {
	case VOLT_SIDE_PRIMARY:			// 一次側
		sText = doGetTextDirectPercentZrPart(props.properties.partPrimary);
		break;
	case VOLT_SIDE_SECONDARY:		// 二次側
		sText = doGetTextDirectPercentZrPart(props.properties.partSecondary);
		break;
	case VOLT_SIDE_THIRD:			// 三次側
		sText = doGetTextDirectPercentZrPart(props.properties.partThird);
		break;
	}
	if (bUnit) {
		sText += '%r';
	}
	return sText;
}

//CEle3WindingPart::DoGetTextTotalIk3peak
export const doGetTextTotalIk3peakPart = (props:Iele3WindingPart) =>
{
	let dIpeakTotal = doGetIkpeakTotalMG(props.calcPoint0);
	let sText = '';
	if (dIpeakTotal >= 0) {
		sText = BeamsNumber(dIpeakTotal / 1000, 4).toString();
	}
	else {							// 短絡電流が正しく計算されなかったとき
		sText = '????';
	}

	return sText;
}

//CEle3Winding::DoGetTextTotalIk3peak
export const doGetTextTotalIk3peak = (props:Ms3WindingModel, nPartKind:number, bUnit:boolean = true) =>
{
	let sText = "";

	switch (nPartKind) {
	case VOLT_SIDE_PRIMARY:			// 一次側
		sText = doGetTextTotalIk3peakPart(props.partPrimary);
		break;
	case VOLT_SIDE_SECONDARY:		// 二次側
		sText = doGetTextTotalIk3peakPart(props.partSecondary);
		break;
	case VOLT_SIDE_THIRD:			// 三次側
		sText = doGetTextTotalIk3peakPart(props.partThird);
		break;
	}
	if (bUnit) {
		sText += 'kApeak';
	}
	return sText;
}

//CEle3WindingPart::DoGetTextTotalIk3asym
export const doGetTextTotalIk3asymPart = (props:Iele3WindingPart) =>
{
	let dIasymTotal = doGetIkasymTotalMG(props.calcPoint0);
	let sText = '';
	if (dIasymTotal >= 0) {
		sText = BeamsNumber(dIasymTotal / 1000, 4).toString();
	}
	else {							// 短絡電流が正しく計算されなかったとき
		sText = '????';
	}

	return sText;
}

//CEle3Winding::DoGetTextTotalIk3asym
export const doGetTextTotalIk3asym = (props:Ms3WindingModel, nPartKind:number, bUnit:boolean = true) =>
{
	let sText = "";

	switch (nPartKind) {
	case VOLT_SIDE_PRIMARY:			// 一次側
		sText = doGetTextTotalIk3asymPart(props.partPrimary);
		break;
	case VOLT_SIDE_SECONDARY:		// 二次側
		sText = doGetTextTotalIk3asymPart(props.partSecondary);
		break;
	case VOLT_SIDE_THIRD:			// 三次側
		sText = doGetTextTotalIk3asymPart(props.partThird);
		break;
	}
	if (bUnit) {
		sText += 'kAasym';
	}
	return sText;
}

//CEle3WindingPart::DoGetTextTotalPercentZr
export const doGetTextTotalPercentZrPart = (props:Iele3WindingPart) =>
{
	let dPerZ = doGetPerZTotalMG(props.calcPoint0).dPercentZ;
	let sText = '';
	if (dPerZ >= 0) {
		sText = BeamsNumber(dPerZ, 4).toString();
	}
	else {							// 短絡電流が正しく計算されなかったとき
		sText = '????';
	}

	return sText;
}

export const doGetTextTotalPercentZr = (props:Ms3WindingModel, nPartKind:number, bUnit:boolean = true) =>
{
	let sText = "";

	switch (nPartKind) {
	case VOLT_SIDE_PRIMARY:			// 一次側
		sText = doGetTextTotalPercentZrPart(props.partPrimary);
		break;
	case VOLT_SIDE_SECONDARY:		// 二次側
		sText = doGetTextTotalPercentZrPart(props.partSecondary);
		break;
	case VOLT_SIDE_THIRD:			// 三次側
		sText = doGetTextTotalPercentZrPart(props.partThird);
		break;
	}
	if (bUnit) {
		sText += '%r';
	}
	return sText;
}
