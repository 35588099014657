import { Box, Button, Checkbox, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Stack, Tab, Tabs, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { MsContactorModel } from '../../models/Index';
import TextFieldOptions from "../common/TextFieldOptions";
import * as Constant from "../../models/Constants";

export type MsContactorEditDialogFormProps = {
    data: MsContactorModel;
    onOK: (data: MsContactorModel) => void;
    onCancel: () => void;
    setIsVoltText:string;
    setIsConectTrans1OrTranscott:boolean;
    userRole: string;
    m_bModePM: boolean;
    modeViewOnly: boolean;
};

function a11yProps(index: number) {
    return {
        id: `tab-${index}`,
        "aria-controls": `tabpanel-${index}`,
    };
}

//Tabs------------------
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

const checkboxStyles = {
    padding: '4px 4px 4px 8px'
}
export const MsContactorEditDialog = (props: MsContactorEditDialogFormProps) => {
    const { m_bModePM, modeViewOnly, userRole, data, onOK, onCancel,setIsConectTrans1OrTranscott,setIsVoltText } = props;
    const [selectTab, setSelectTab] = useState(0);
    const [isDisableOKBtn, setIsDisableOKBtn] = useState(false);
    // 初期値設定
    const initialValue: MsContactorModel = {
        ...data,
    }
    
    const [makeDataInput, setMakeDataInput] = useState(initialValue.makeDataInput); // ModePM
    const { register, control, handleSubmit, formState: { errors }, setValue, getValues ,setError} = useForm<MsContactorModel>({
        mode: 'all',
        criteriaMode: "all",
        defaultValues: initialValue,
    });

    // Submitイベント
    const handleEditSubmit = (value: MsContactorModel) => {
        const request = createRequestData(value);
        onOK(request);
    }

    // FormデータからRequestデータを作成する
    const createRequestData = (formValue: MsContactorModel) => {
        let request = {
            ...formValue
        } as MsContactorModel;
        return request;
    }

    // キャンセル
    const handleCancel = () => {
        onCancel();
    };

    const handleChangeFormat = (e: any) => {
        setValue(e.target.name, e.target.value);
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        if(newValue >= 0){
            if (getValues('refNo') == "" ||getValues('refNo') == null || getValues('refNo') == undefined ) {
                setError("refNo",{ type: 'custom', message: '必須項目です。入力してください。' });
            }
            else if(isNaN(Number(getValues('voltage'))) || Number(getValues('voltage')) <= 0 || !/^[0-9\b]+$/.test(getValues('voltage').toString())){
                setError("voltage",{ type: 'custom', message: '整数 > 0 を入力してください。' });
            }else{
                setSelectTab(newValue);
            }
        }
        else{
        setSelectTab(newValue);
        }
        // setSelectTab(newValue);
    };
    const handleInput = (e: any) => {
        setValue(e.target.name, e.target.value,{
            shouldValidate:true
        })
        const isInteger = /^[0-9\b]+$/.test(getValues("voltage").toString())
        if(getValues("voltage") > 0 && isInteger){
            // setIsDisableCalcBtn(false);
            setIsDisableOKBtn(false);
        }
        else{
            // setIsDisableCalcBtn(true);
            setIsDisableOKBtn(true);
        }
    }
    const handleChangeMakeDataInput = (e: any) => {
        setValue(e.target.name, e.target.checked);
        setMakeDataInput(e.target.checked);
    }
    return (
        <>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={selectTab} onChange={handleChange} aria-label="basic tabs example"
                        variant="scrollable" 
                        scrollButtons 
                        allowScrollButtonsMobile 
                        sx={{
                            '& .MuiButtonBase-root.MuiTabScrollButton-root.Mui-disabled': {
                                display: "none"
                            }
                        }}
                    >
                        <Tab label="基本" {...a11yProps(0)} />
                        <Tab label="シンボル" {...a11yProps(1)} />
                    </Tabs>
                </Box>
                <form onSubmit={handleSubmit(handleEditSubmit)}>
                     <div style={{ height: '40vh', width: '100%', margin: 'auto', overflow: 'auto' }}>
                            <TabPanel value={selectTab} index={0}>
                                <Stack spacing={0.3} >
                                    <Grid container mt={2} >
                                        <Grid item xs={5.5}>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"5px"}}>
                                                <Typography variant="body2" width="55%">デバイス名:</Typography>
                                                    <Controller
                                                        name="refNo"
                                                        control={control}
                                                        rules={{
                                                            required: '必須項目です。入力してください。',
                                                        }}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                fullWidth
                                                                type="text"
                                                                size="small"
                                                                error={!!errors?.refNo}
                                                                helperText={errors?.refNo?.message}
                                                                onBlur={handleChangeFormat}
                                                                onFocus={e => e.target.select()}
                                                                inputProps={{readOnly: m_bModePM || modeViewOnly}}
                                                                InputProps={{ 
                                                                    sx: { 
                                                                        height: 32,
                                                                        background:m_bModePM || modeViewOnly?'floralwhite':'white',
                                                                        color: m_bModePM || modeViewOnly?'grey':'black',
                                                                    }
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                                <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                    <Typography variant="body2" width="55%">事故点名称:</Typography>
                                                    <Controller
                                                        name="pointText"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                fullWidth
                                                                type="text"
                                                                size="small"
                                                                onBlur={handleChangeFormat}
                                                                onFocus={e => e.target.select()}
                                                                inputProps={{readOnly: m_bModePM || modeViewOnly}}
                                                                InputProps={{ 
                                                                    sx: { 
                                                                        height: 32,
                                                                        background:m_bModePM || modeViewOnly?'floralwhite':'white',
                                                                        color: m_bModePM || modeViewOnly?'grey':'black',
                                                                    }
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                                <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                    <Typography variant="body2" width="55%">回路電圧(V):</Typography>
                                                    <FormControl fullWidth size="small">
                                                    <Controller
                                                            name="voltage"
                                                            control={control}
                                                            rules={{ 
                                                                required: '必須項目です。入力してください。' ,
                                                                pattern:{
                                                                value: /^[0-9\b]+$/,
                                                                message: '数字を入力してください。'
                                                                },
                                                                validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                            }}
                                                            render={({ field }) => (
                                                                <TextFieldOptions
                                                                    {...field}
                                                                    options={initialValue.voltageList}
                                                                    size="small"
                                                                    setValue={(name: any, value: any) => {
                                                                        handleInput({target: {name: name, value: value}})
                                                                    }}
                                                                    error={!!errors?.voltage}
                                                                    helperText={errors?.voltage?.message}
                                                                    disabled={m_bModePM || modeViewOnly || getValues("isSourceVoltage")}
                                                                    height={32}
                                                                    maxLength={6}
                                                                    value={setIsConectTrans1OrTranscott?setIsVoltText:getValues("voltage")}
                                                                />
                                                            )}
                                                        />
                                                    </FormControl>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={6.5} pl={1}>
                                                <Stack>
                                                    <Controller
                                                        name="isOff"
                                                        control={control}
                                                        render={({ field }: any) => (
                                                            <FormControlLabel {...field} disabled={m_bModePM || modeViewOnly} control={<Checkbox style={checkboxStyles} />} checked={field.value} label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>主回路を開放</Typography>} name='isOff' onClick={handleChangeFormat} />
                                                        )}
                                                    />
                                                </Stack>
                                                <Stack>
                                                    <Controller
                                                        name="fault"
                                                        control={control}
                                                        render={({ field }: any) => (
                                                            <FormControlLabel {...field} disabled={m_bModePM || modeViewOnly} control={<Checkbox style={checkboxStyles} />} checked={field.value} label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>短絡電流を計算</Typography>} name='fault' onClick={handleChangeFormat} />
                                                        )}
                                                    />
                                                </Stack>
                                                <Stack>
                                                    <Controller
                                                        name="dropMotor"
                                                        control={control}
                                                        render={({ field }: any) => (
                                                            <FormControlLabel {...field} disabled={m_bModePM || modeViewOnly} control={<Checkbox style={checkboxStyles} />} checked={field.value} label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>電動機始動時の電圧降下を計算</Typography>} name='dropMotor' onClick={handleChangeFormat} />
                                                        )}
                                                    />
                                                </Stack>
                                                <Stack>
                                                    <Controller
                                                        name="dropRegular"
                                                        control={control}
                                                        render={({ field }: any) => (
                                                            <FormControlLabel {...field} disabled={m_bModePM || modeViewOnly} control={<Checkbox style={checkboxStyles} />} checked={field.value} label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>定常時の電圧降下を計算</Typography>} name='dropRegular' onClick={handleChangeFormat} />
                                                        )}
                                                    />
                                                </Stack>
                                                {/* Mode SP */}
                                                {(userRole && !(userRole == Constant.ROLE_LP)) &&
                                                <Stack>
                                                    <Controller
                                                        name="viewFlowResult"
                                                        control={control}
                                                        render={({ field }: any) => (
                                                            <FormControlLabel 
                                                                {...field} 
                                                                control={<Checkbox style={checkboxStyles} />} 
                                                                checked={field.value} 
                                                                label={
                                                                    <Typography variant="body2" color={m_bModePM || initialValue.sPhase ? 'grey' : undefined}>
                                                                        潮流解析結果を表示
                                                                    </Typography>
                                                                } 
                                                                name='viewFlowResult' 
                                                                onClick={handleChangeFormat} 
                                                                disabled={m_bModePM || modeViewOnly || initialValue.sPhase}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                                }

                                                <Stack>
                                                    <Controller
                                                        name="viewResultText"
                                                        control={control}
                                                        render={({ field }: any) => (
                                                            <FormControlLabel 
                                                                {...field}
                                                                disabled={m_bModePM || modeViewOnly}
                                                                control={<Checkbox style={checkboxStyles} />}
                                                                checked={field.value}
                                                                label={
                                                                    <Typography variant="body2" color={m_bModePM?'grey':'black'}>
                                                                        注釈を表示
                                                                    </Typography>
                                                                }
                                                                name='viewResultText'
                                                                onClick={handleChangeFormat}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                            </Grid>
                                        {/* ModePM */}
                                        {[Constant.ROLE_PM, Constant.ROLE_ADMIN].includes(userRole) && 
                                        <>
                                            <Stack mt={-2}>
                                                <FormControlLabel
                                                    control={
                                                        <Controller
                                                            name='makeDataInput'
                                                            control={control}
                                                            render={({ field }) => (
                                                                <Checkbox
                                                                    size='small'
                                                                    {...field}
                                                                    disabled={m_bModePM || modeViewOnly}
                                                                    checked={field.value}
                                                                    onChange={handleChangeMakeDataInput}
                                                                    style={{padding:'4px 4px 4px 9px'}}
                                                                />
                                                            )}
                                                        />
                                                    }
                                                    label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>銘板入力</Typography>}
                                                />
                                            </Stack>
                                            <Stack>
                                                <fieldset
                                                    style={{ 
                                                        width: '70%',
                                                        color: !makeDataInput ? 'grey' : 'black', 
                                                        border:'1px solid #AAAA'
                                                    }}
                                                >
                                                    <legend ><Typography variant="body2" >銘板</Typography></legend>
                                                    <Stack spacing={0.3}>
                                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                            <Typography variant="body2" width="35%"  >製造者:</Typography>
                                                            <FormControl fullWidth size='small'>
                                                                <Controller
                                                                    name='makerName'
                                                                    control={control}
                                                                    render={({ field }: any) => (
                                                                        <TextFieldOptions
                                                                            {...field}
                                                                            options={initialValue.makerNameList}
                                                                            type='string'
                                                                            size='small'
                                                                            setValue={(name: any, value: any) => {
                                                                                handleChangeFormat({target: {name: name, value: value}})
                                                                            }}
                                                                            height= {32}
                                                                            disabled={m_bModePM || modeViewOnly || !makeDataInput}
                                                                        />
                                                                    )}
                                                                />
                                                            </FormControl>
                                                        </Stack>
                                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                            <Typography variant="body2" width="35%"  >形式:</Typography>
                                                            <Controller
                                                                name='type'
                                                                control={control}
                                                                render={({ field }) => (
                                                                    <TextField
                                                                        {...field}
                                                                        fullWidth
                                                                        type='text'
                                                                        size='small'
                                                                        onChange={handleChangeFormat}
                                                                        inputProps={{readOnly: m_bModePM || modeViewOnly || !makeDataInput}}
                                                                        InputProps={{ 
                                                                            sx: { 
                                                                                height: 32,
                                                                                background:m_bModePM || modeViewOnly || !makeDataInput?'floralwhite':'white',
                                                                                color: m_bModePM || modeViewOnly || !makeDataInput?'grey':'black',
                                                                            }
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </Stack>
                                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                            <Typography variant="body2" width="35%"  >製造年月:</Typography>
                                                            <Grid container>
                                                                <Grid item xs={7}>
                                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                                        <FormControl fullWidth size='small'>
                                                                            <Controller
                                                                                name='makeYear'
                                                                                control={control}
                                                                                render={({ field }: any) => (
                                                                                    <TextFieldOptions
                                                                                        {...field}
                                                                                        options={initialValue.makeYearList}
                                                                                        type='string'
                                                                                        size='small'
                                                                                        setValue={(name: any, value: any) => {
                                                                                            handleChangeFormat({target: {name: name, value: value}})
                                                                                        }}
                                                                                        error={!!errors?.makeYear}
                                                                                        helperText={errors?.makeYear?.message}
                                                                                        disabled={m_bModePM || modeViewOnly || !makeDataInput}
                                                                                        height={32}
                                                                                        maxLength={8}
                                                                                    />
                                                                                )}
                                                                            />
                                                                        </FormControl>
                                                                        <Typography variant="body2" pr={0.5} pl={0.5}>年</Typography>
                                                                    </Stack>
                                                        
                                                                </Grid>
                                                                <Grid item xs={5}>
                                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                                        <FormControl fullWidth size='small'>
                                                                            <Controller
                                                                            name='makeMonth'
                                                                            control={control}
                                                                            render={({ field }: any) => (
                                                                                <TextFieldOptions
                                                                                    {...field}
                                                                                    options={initialValue.makeMonthList}
                                                                                    type='string'
                                                                                    size='small'
                                                                                    setValue={(name: any, value: any) => {
                                                                                        handleChangeFormat({target: {name: name, value: value}})
                                                                                    }} 
                                                                                    error={!!errors?.makeMonth}
                                                                                    helperText={errors?.makeMonth?.message}
                                                                                    disabled={m_bModePM || modeViewOnly || !makeDataInput}
                                                                                    height={32}
                                                                                    maxLength={2}
                                                                                />
                                                                            )}
                                                                            />
                                                                        </FormControl>
                                                                        <Typography variant="body2" pl={0.5}>月</Typography>
                                                                    </Stack>
                                                                </Grid>
                                                            </Grid>
                                                        </Stack>
                                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                            <Typography variant="body2" width="35%"  >製造番号:</Typography>
                                                            <Controller
                                                                name='makeNumber'
                                                                control={control}
                                                                render={({ field }) => (
                                                                    <TextField
                                                                        {...field}
                                                                        fullWidth
                                                                        type='text'
                                                                        size='small'
                                                                        onChange={handleChangeFormat}
                                                                        inputProps={{readOnly: m_bModePM || modeViewOnly || !makeDataInput}}
                                                                        InputProps={{ 
                                                                            sx: { 
                                                                                height: 32,
                                                                                background:m_bModePM || modeViewOnly || !makeDataInput?'floralwhite':'white',
                                                                                color: m_bModePM || modeViewOnly || !makeDataInput?'grey':'black',
                                                                            }
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </Stack>
                                                    </Stack>
                                                </fieldset>
                                            </Stack>
                                        </>
                                        }
                                    </Grid>
                                </Stack>
                            </TabPanel>
                            <TabPanel value={selectTab} index={1}>
                            <Stack mt={1} width={'75%'}>
                            <fieldset style={{border:'1px solid #AAAA'}}>
                                <legend ><Typography variant="body2">単線結線図で表示するシンボルの選択</Typography></legend>
                                <Grid container>
                                    <Grid item xs={7}>
                                        <Controller
                                            name='exchangeNumber'
                                            control={control}
                                            render={({ field }) => (
                                                <RadioGroup {...field}>
                                                    <FormControlLabel 
                                                        value='0'
                                                        disabled={m_bModePM || modeViewOnly}
                                                        control={<Radio size='small'/>} 
                                                        label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>メーク接点(a接点)</Typography>}
                                                    />
                                                    <FormControlLabel 
                                                        value='1'
                                                        disabled={m_bModePM || modeViewOnly}
                                                        control={<Radio size='small'/>} 
                                                        label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>ブレーク接点(b接点)</Typography>}
                                                    />
                                                </RadioGroup>
                                            )}
                                        />
                                    </Grid>
                                    
                                </Grid>
                            </fieldset>
                        </Stack>
                            </TabPanel>
                    </div>
                    <Stack direction='row' justifyContent="flex-end" spacing={2} sx={{ mt: 2 }}>
                        <Button variant="contained" type="submit" disabled={isDisableOKBtn || modeViewOnly}>OK</Button>
                        <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                    </Stack>
                </form >
        </>
    );
};
export default MsContactorEditDialog;


