import { IeleBusductSPhase, MsBusductModel, OptionModel, ControlModel, ProcessMode } from "../models/Index";
import { MS_TRANS1,
    MS_OUTSIDE_NEUTRAL,
    MS_OUTSIDE_OUTSIDE, 
    MS_SEAT_MAIN, 
    MS_SEAT_T, 
    RS_3PHASE,
    RS_PHASE_N,
    RS_PHASE_PE,
	BUSDUCT_AL,
	BUSDUCT_CU,
	MS_TRANSSCOTT,
    MS_3PHASES,
    MS_VOLTDROP_MOTOR,
    MS_VOLTDROP_REGULAR,
    DIRECT_NONE,
    TOP_CENTER,
    LEFT_CENTER
} from "../models/Constants";
import { BeamsNumber } from "./FormatNumber";
import { doGetValuePerZk3 } from "./PercentZ";
import { doGetValueDropVoltRegular as doGetValueDropVoltRegularCalcPoint,
        doGetIkasymDirectMG, 
        doGetIkpeakDirectMG,
        doGetIksymDirectMG,
        doGetIksymTotalMG,
        isSetDropPerMotor,
        doGetTextDropVoltMotor as doGetTextDropVoltMotorCalcPoint,
        doGetTextDropVoltMotor2 as doGetTextDropVoltMotor2CalcPoint,
        doGetTextDropPerMotor as doGetTextDropPerMotorCalcPoint,
        doGetTextDropVoltRegular as doGetTextDropVoltRegularCalcPoint,
        doGetTextDropPerRegular as doGetTextDropPerRegularCalcPoint,
        doGetPerZDirectMG,
        doGetIkpeakTotalMG,
        doGetIkasymTotalMG,
        doGetPerZTotalMG
} from "./calcPointFunction";
import { store } from "..";
import { doGetTextVoltDropRegular } from "./mssv3Doc";
import { doGetLoadCurrentEndSide, doGetLoadCurrentStartSide, isCennectLoadDropElement, isCennectSourceDropElement } from "./element";
import { MS_MODE_CALCULATION, MS_MODE_CHECKING, MS_MODE_DRAWING, MS_MODE_HARMONIC, MS_MODE_PERCENT_Z, MS_MODE_POWER_FLOW, MS_MODE_VOLTDROP } from "../statics";

export const doSetDefaultBusduct = (sphase:IeleBusductSPhase,ratedCurrentList:any,n_frequency:number) =>{
    if(ratedCurrentList == null || ratedCurrentList.length < 0){
        sphase.ratedCurrent =  0
            sphase.width =  0
            sphase.thickness =  0
            sphase.numberPhase =  0
            sphase.ohmRPerM =  0
            sphase.ohmRPerA =  0

            if(n_frequency === 50){
            sphase.ohmXPerM =  0
            sphase.ohmXPerA =  0

            }
            else if(n_frequency === 60){
            sphase.ohmXPerM =  0
            sphase.ohmXPerA =  0

            }
            sphase.ohmR = 0
            sphase.ohmX = 0
            sphase.capacity = 0
    }
    else{
        for(const item of ratedCurrentList){
            if(item.bisDefault){
                sphase.ratedCurrent = item.dratedCurrent || 0
                sphase.width = item.nwidth || 0
                sphase.thickness = item.nthickness || 0
                sphase.numberPhase = item.nnumber || 0
                sphase.ohmRPerM = item.dohmr || 0
                sphase.ohmRPerA = item.dohmr || 0

                if(n_frequency === 50){
                sphase.ohmXPerM = item.dohmx50 || 0
                sphase.ohmXPerA = item.dohmx50 || 0

                }
                else if(n_frequency === 60){
                sphase.ohmXPerM = item.dohmx60 || 0
                sphase.ohmXPerA = item.dohmx60 || 0

                }
                sphase.ohmR = 0
                sphase.ohmX = 0
                sphase.capacity = 0
                break;
            }
            else{
                sphase.ratedCurrent = 0
                sphase.width =  0
                sphase.thickness =  0
                sphase.numberPhase =  0
                sphase.ohmRPerM = 0
                sphase.ohmRPerA =  0

                if(n_frequency === 50){
                sphase.ohmXPerM =  0
                sphase.ohmXPerA =  0

                }
                else if(n_frequency === 60){
                sphase.ohmXPerM =  0
                sphase.ohmXPerA =  0

                }
                sphase.ohmR = 0
                sphase.ohmX = 0
                sphase.capacity = 0
            }
        }
        let ratedCurrentList1: OptionModel[] = [];
            ratedCurrentList.forEach((element: any) => {
                if (element.dratedCurrent >= 0 && element.dratedCurrent !== null){
                    let optionModel: OptionModel = {
                        label: element.dratedCurrent.toString(),
                        value: +element.dratedCurrent
                    };
                    ratedCurrentList1.push(optionModel)
                }
            });
        sphase.ratedCurrentList = ratedCurrentList1
    }
}
export const doInitComboRatedCurrent =  (busductList1:any,phase:IeleBusductSPhase,material:number,limitMin:number) =>{
    const busductList = busductList1.sort((bus1:any,bus2:any)=>bus1.lbusductID - bus2.lbusductID)
    let ratedList: any = []
    for(const item of busductList){
        if(item.nmaterial == material){
            if(item.dratedCurrent <= 0 || limitMin > 0 && item.dratedCurrent < limitMin){
                continue
            }
            ratedList.push({label:item.dratedCurrent.toString(),
                            value:item.dratedCurrent} as OptionModel)
        }
    }
    phase.ratedCurrentList = ratedList
}
//CEleBusDuct::DoGetPerZrBusDuct
export const doGetPerZrBusDuct = (props:MsBusductModel, dStdCapacity:number, nWireSystem:number, nPartKind:number, bTwoWays:boolean = true) =>
{
	let percentZ = {
        perRk3 : 0,
        perXk3 : 0,
        perRk3Difference : 0,
        perXk3Difference : 0
    };

	if (props.voltage <= 0) {
	 	percentZ.perRk3 = 0;
		percentZ.perXk3 = 0;
	}
	else {
		switch (nWireSystem) {		
		case RS_3PHASE:
			if (props.sPhase ) {
				switch (nPartKind) {
				case MS_OUTSIDE_NEUTRAL:			
				 	percentZ.perRk3 = dStdCapacity * props.m_3Sphase.ohmRPerM * props.length / (props.voltage / 2) / (props.voltage / 2) * 100;
					percentZ.perXk3 = dStdCapacity * props.m_3Sphase.ohmXPerM * props.length / (props.voltage / 2) / (props.voltage / 2) * 100;
					if (bTwoWays) {
						percentZ.perRk3 *= 2;
						percentZ.perXk3 *= 2;
					}
					break;
				case MS_OUTSIDE_OUTSIDE:	
				 	percentZ.perRk3 = dStdCapacity * props.m_3Sphase.ohmRPerM * props.length / props.voltage / props.voltage * 100;
					percentZ.perXk3 = dStdCapacity * props.m_3Sphase.ohmXPerM * props.length / props.voltage / props.voltage * 100;
					if (bTwoWays) {
						percentZ.perRk3 *= 2;
						percentZ.perXk3 *= 2;
					}
										
				 	percentZ.perRk3Difference = dStdCapacity * props.m_3Sphase.ohmRPerM * props.length / (props.voltage / 2) / (props.voltage / 2) * 100;
					percentZ.perXk3Difference = dStdCapacity * props.m_3Sphase.ohmXPerM * props.length / (props.voltage / 2) / (props.voltage / 2) * 100;
					if (bTwoWays) {
						percentZ.perRk3Difference *= 2;
						percentZ.perXk3Difference *= 2;
					}
					percentZ.perRk3Difference = percentZ.perRk3 - percentZ.perRk3Difference;
					percentZ.perXk3Difference = percentZ.perXk3 - percentZ.perXk3Difference;
					break;
				case MS_SEAT_MAIN:		
				case MS_SEAT_T:		
				 	percentZ.perRk3 = dStdCapacity * props.m_3Sphase.ohmRPerM * props.length / props.voltage / props.voltage * 100;
					percentZ.perXk3 = dStdCapacity * props.m_3Sphase.ohmXPerM * props.length / props.voltage / props.voltage * 100;
					if (bTwoWays) {
						percentZ.perRk3 *= 2;
						percentZ.perXk3 *= 2;
					}
					break;
				}
			}
			else {
			 	percentZ.perRk3 = dStdCapacity * props.m_3Sphase.ohmRPerM * props.length / props.voltage / props.voltage * 100;
				percentZ.perXk3 = dStdCapacity * props.m_3Sphase.ohmXPerM * props.length / props.voltage / props.voltage * 100;
			}
			break;
		case RS_PHASE_N:
		 	percentZ.perRk3 = dStdCapacity * props.SPhaseN.ohmRPerM * props.length / props.voltage / props.voltage * 100;
			percentZ.perXk3 = dStdCapacity * props.SPhaseN.ohmXPerM * props.length / props.voltage / props.voltage * 100;
			break;
		case RS_PHASE_PE:
		 	percentZ.perRk3 = dStdCapacity * props.sPhasePE.ohmRPerM * props.length / props.voltage / props.voltage * 100;
			percentZ.perXk3 = dStdCapacity * props.sPhasePE.ohmXPerM * props.length / props.voltage / props.voltage * 100;
			break;
		}
	}

	return percentZ;
}

//CEleBusDuct::DoGetValueDropVoltRegular
export const doGetValueDropVoltRegular = (props:MsBusductModel, dStdCapacity:number, dStdVoltage:number, nPartKind:number) =>
{
	let percentZ = doGetPerZrBusDuct(props, dStdCapacity, RS_3PHASE, nPartKind, false);
	let dVolt = 0;
	if (props.sPhase) {		
		switch (nPartKind) {
		case MS_OUTSIDE_NEUTRAL:	
		case MS_SEAT_MAIN:			
			dVolt = doGetValueDropVoltRegularCalcPoint(dStdVoltage, percentZ.perRk3, percentZ.perXk3, props.calcPoint1);
			break;
		case MS_OUTSIDE_OUTSIDE:	
		case MS_SEAT_T:				
			dVolt = doGetValueDropVoltRegularCalcPoint(dStdVoltage, percentZ.perRk3, percentZ.perXk3, props.calcPoint0);
			break;
		}
	}
	else {
		if (props.downTransKind == MS_TRANS1) {
			dVolt = doGetValueDropVoltRegularCalcPoint(dStdVoltage, percentZ.perRk3, percentZ.perXk3, props.calcPoint1);
		}
		else {
			dVolt = doGetValueDropVoltRegularCalcPoint(dStdVoltage, percentZ.perRk3, percentZ.perXk3, props.calcPoint0);
		}
	}
	return dVolt;
}

//CEleBusDuct::DoGetTextPointState
export const doGetTextPointState = (props:MsBusductModel) =>
{
    let sText = ''; 

	if (props.fault) {
		sText = 'Yes';
	}
	else {
		sText = 'No';
	}

	return sText;
}

//CEleBusDuct::DoGetTextLength
export const doGetTextLength = (props: MsBusductModel, bUnit: boolean = true) =>
{
	let sText = props.length.toString();
    if (bUnit) {
        sText += 'm';
    }
    return sText;
}

//CEleBusDuct::DoGetTextMaterial
export const doGetTextMaterial = (props: MsBusductModel) => {
    let sText = '';
    switch(props.materialCU_AL) {
        case BUSDUCT_CU:
            sText = '銅';
            break;
        case BUSDUCT_AL:
            sText = 'ｱﾙﾐﾆｳﾑ';
            break;
    }
    return sText;
}

//CEleBusDuct::DoGetTextCapacity
export const doGetTextCapacity = (props: MsBusductModel, nWireSystem: number, bUnit: boolean = true) => {
    let dCurrent = 0;
    switch(nWireSystem) {
        case RS_3PHASE:
            dCurrent = props.m_3Sphase.ratedCurrent;
            break;
        case RS_PHASE_N:
            dCurrent = props.SPhaseN.ratedCurrent;
            break;
        case RS_PHASE_PE:
            dCurrent = props.sPhasePE.ratedCurrent;
            break;
    }
    let sText = dCurrent.toString();
    if(bUnit) {
        sText += 'A';
    }
    return sText;
}

//CEleBusDuct::DoGetTextSize
export const doGetTextSize = (props: MsBusductModel, nWireSystem: number) => {
   let sText;
   switch(nWireSystem) {
    case RS_3PHASE:
		sText = props.m_3Sphase.width.toString();
        sText += '×';
		sText += props.m_3Sphase.thickness.toString();
		sText += '×';
		sText += props.m_3Sphase.numberPhase.toString();
       break;
    case RS_PHASE_N:
        sText = props.SPhaseN.width.toString();
        sText += '×';
		sText += props.SPhaseN.thickness.toString();
		sText += '×';
		sText += props.SPhaseN.numberPhase.toString();
        break;
    case RS_PHASE_PE:
		sText = props.sPhasePE.width.toString();
        sText += '×';
		sText += props.sPhasePE.thickness.toString();
		sText += '×';
		sText += props.sPhasePE.numberPhase.toString();
        break;
    }
    return sText;
}

//CEleBusDuct::DoGetValueOhmZ
export const doGetValueOhmZ = (dOhmR:number, dOhmX:number) => {
    let dOhmZ = Math.sqrt(dOhmR * dOhmR + dOhmX * dOhmX);
    return dOhmZ;
}

//CEleBusDuct::DoGetTextOhmZ
export const doGetTextOhmZ = (props: MsBusductModel, nWireSystem: number, bUnit: boolean = true) => {
    let dOhmZ = 0;
    switch(nWireSystem) {
        case RS_3PHASE:
            dOhmZ = doGetValueOhmZ(props.m_3Sphase.ohmRPerM * props.length, props.m_3Sphase.ohmXPerM * props.length);
           break;
        case RS_PHASE_N:
            dOhmZ = doGetValueOhmZ(props.SPhaseN.ohmRPerM * props.length, props.SPhaseN.ohmXPerM * props.length);
            break;
        case RS_PHASE_PE:
            dOhmZ = doGetValueOhmZ(props.sPhasePE.ohmRPerM * props.length, props.sPhasePE.ohmXPerM * props.length);
            break;
    }
    let sText = BeamsNumber(dOhmZ, 4, true).toString();
    if(bUnit && dOhmZ >= 0)
    {
        sText += 'mΩ';
    }
    return sText;
 }

 //CEleBusDuct::DoGetTextOhmR
export const doGetTextOhmR = (props: MsBusductModel, nWireSystem: number,  bUnit: boolean = true) => {
    let dOhmR = 0;
    switch(nWireSystem) {
        case RS_3PHASE:
            dOhmR = props.m_3Sphase.ohmRPerM;
            break;
        case RS_PHASE_N:
            dOhmR = props.SPhaseN.ohmRPerM;
            break;
        case RS_PHASE_PE:
            dOhmR = props.sPhasePE.ohmRPerM;
            break;
    }
    dOhmR *= props.length;
    let sText = BeamsNumber(dOhmR, 4, true).toString();
    if(bUnit && dOhmR >= 0)
    {
        sText += 'mΩ';
    }
    return sText;
}

//CEleBusDuct::DoGetTextOhmX
export const doGetTextOhmX = (props: MsBusductModel, nWireSystem: number,  bUnit: boolean = true) => {
    let dOhmX = 0;
    switch(nWireSystem) {
        case RS_3PHASE:
            dOhmX = props.m_3Sphase.ohmXPerM;
           break;
        case RS_PHASE_N:
            dOhmX = props.SPhaseN.ohmXPerM;
            break;
        case RS_PHASE_PE:
            dOhmX = props.sPhasePE.ohmXPerM;
            break;
    }
    dOhmX *= props.length;
    let sText = BeamsNumber(dOhmX, 4, true).toString();
    if(bUnit && dOhmX >= 0)
    {
        sText += 'mΩ';
    }
    return sText;
}

//CEleBusDuct::DoGetTextXR
export const doGetTextXR = (props: MsBusductModel, nWireSystem: number) => {
    let dXR = 0;
    switch(nWireSystem) {
        case RS_3PHASE:
            dXR = props.m_3Sphase.ohmXPerM / props.m_3Sphase.ohmRPerM;
            break;
        case RS_PHASE_N:
            dXR = props.SPhaseN.ohmXPerM / props.SPhaseN.ohmRPerM;
            break;
        case RS_PHASE_PE:
            dXR = props.sPhasePE.ohmXPerM / props.sPhasePE.ohmRPerM;
            break; 
    }
    let sText = BeamsNumber(dXR, 4).toString();
    return sText;
}

//CEleBusDuct::DoGetTextPercentZr
export const doGetTextPercentZr = (props: MsBusductModel, nWireSystem: number, nPartKind:number, dStdCapacity:number, bUnit:boolean = true) => {
    let percentZ = doGetPerZrBusDuct(props, dStdCapacity, nWireSystem, nPartKind);
    let dPercentZ = doGetValuePerZk3(percentZ).dPercentZ;
    let sText = BeamsNumber(dPercentZ, 4, true).toString();
    if(bUnit && dPercentZ >= 0)
    {
        sText += '%r';
    }
    return sText;
}

//CEleBusDuct::DoGetTextPercentRr
export const doGetTextPercentRr = (props: MsBusductModel, nWireSystem: number, nPartKind:number, dStdCapacity:number, bUnit:boolean = true) => {
    let percentZ = doGetPerZrBusDuct(props, dStdCapacity, nWireSystem, nPartKind);
    let sText = BeamsNumber(percentZ.perRk3, 4, true).toString();
    if (bUnit && percentZ.perRk3 >= 0) {
        sText += '%r';
    }
    return sText;
}

//CEleBusDuct::DoGetTextPercentXr
export const doGetTextPercentXr = (props: MsBusductModel, nWireSystem: number, nPartKind:number, dStdCapacity:number, bUnit:boolean = true) => {
    let percentZ = doGetPerZrBusDuct(props, dStdCapacity, nWireSystem, nPartKind);
    let sText = BeamsNumber(percentZ.perXk3, 4, true).toString();
    if (bUnit && percentZ.perXk3 >= 0) {
        sText += '%r';
    }
    return sText;
}

//CEleBusDuct::IsPointFaultBusDuct
export const isPointFaultBusDuct = (props: MsBusductModel) => {
    if (props.fault) {
        return true;
    }
    return false;
}

//CEleBusDuct::DoGetTextDirectIkpeak
export const doGetTextDirectIkpeak = (props:MsBusductModel, nPartKind:number, bUnit: boolean = true ) => {
    let dIpeakDirect = 0;
    if(props.sPhase)
    {
        switch(nPartKind) {
            case MS_OUTSIDE_NEUTRAL:
            case MS_SEAT_MAIN:
                dIpeakDirect = doGetIkpeakDirectMG(props.calcPoint1);
                break;
            case MS_OUTSIDE_OUTSIDE:
            case MS_SEAT_T:
                dIpeakDirect = doGetIkpeakDirectMG(props.calcPoint0);
                break;
        }
    }
    else
    {
        if(props.downTransKind == MS_TRANS1) {
            dIpeakDirect = doGetIkpeakDirectMG(props.calcPoint1);
        }
        else {
            dIpeakDirect = doGetIkpeakDirectMG(props.calcPoint0);
        }

    }
    let sText;
    if (dIpeakDirect == 0) {
       if(props.primarySupply) {
        sText = '∞';
       } 
       else {
        sText = '0';
       }
    }
    else if(dIpeakDirect > 0) {
        sText = BeamsNumber(dIpeakDirect / 1000, 4).toString();
        if(bUnit)
        {
            sText += 'kApeak';
        }
    }
    else {
        sText = '????';
    }
    return sText;
}

//CEleBusDuct::DoGetTextDirectIkasym
export const doGetTextDirectIkasym = (props:MsBusductModel, nPartKind:number, bUnit: boolean = true ) => {
    let dIasymDirect = 0;
    if(props.sPhase) {
        switch(nPartKind) {
            case MS_OUTSIDE_NEUTRAL:
            case MS_SEAT_MAIN:
                dIasymDirect = doGetIkasymDirectMG(props.calcPoint1);
                break;
            case MS_OUTSIDE_OUTSIDE:
            case MS_SEAT_T:
                dIasymDirect = doGetIkasymDirectMG(props.calcPoint0);
                break;
        }
    }
    else {
        if(props.downTransKind == MS_TRANS1)
        {
            dIasymDirect = doGetIkasymDirectMG(props.calcPoint1);
        }
        else
        {
            dIasymDirect = doGetIkasymDirectMG(props.calcPoint0);
        }
    }
    let sText;
    if (dIasymDirect == 0) {
       if(props.primarySupply) {
        sText = '∞';
       }
       else {
        sText = '0';
       }
    }
    else if(dIasymDirect > 0) {
        sText = BeamsNumber(dIasymDirect / 1000, 4).toString();
        if(bUnit) {
            sText += 'kAasym';
        }
    }
    else {
        sText = '????';
    }
    return sText;
}

//CEleBusDuct::DoGetTextDirectIksym
export const doGetTextDirectIksym = (props:MsBusductModel, nPartKind:number, bUnit: boolean = true ) => {
    let dIsymDirect = 0;
    if(props.sPhase) {
        switch(nPartKind) {
            case MS_OUTSIDE_NEUTRAL:
            case MS_SEAT_MAIN:
                dIsymDirect = doGetIksymDirectMG(props.calcPoint1);
                break;
            case MS_OUTSIDE_OUTSIDE:
            case MS_SEAT_T:
                dIsymDirect = doGetIksymDirectMG(props.calcPoint0);
                break;
        }
    }
    else {
        if(props.downTransKind === MS_TRANS1) {
            dIsymDirect = doGetIksymDirectMG(props.calcPoint1);
        }
        else {
            dIsymDirect = doGetIksymDirectMG(props.calcPoint0);
        }
    }
    let sText;
    if (dIsymDirect == 0) {
        if(props.primarySupply) {
            sText = '∞';
        }
        else {
         sText = '0';
        }
    }
    else if(dIsymDirect > 0) {
        sText = BeamsNumber(dIsymDirect / 1000, 4).toString();
        if(bUnit) {
            sText += 'kAsym';
        }
    }
    else {
        sText = '????';
    }
    return sText;
}

//CEleBusDuct::DoGetTextTotalIk3sym
export const doGetTextTotalIk3sym = (props:MsBusductModel, bUnit: boolean = true ) => {
    let dIsymTotal = 0;
    if(props.downTransKind == MS_TRANS1) {
        dIsymTotal = doGetIksymTotalMG(props.calcPoint1);
    }
    else {
        dIsymTotal = doGetIksymTotalMG(props.calcPoint0);
    }
    let sText;
    if (dIsymTotal == 0) {
        if(props.primarySupply) {
            sText = '∞';
        }
        else {
         sText = '0';
        }
    }
    else if(dIsymTotal > 0) {
        sText = BeamsNumber(dIsymTotal / 1000, 5).toString();
        if(bUnit) {
            sText += 'kAsym';
        }
    }
    else {
        sText = '????';
    }
    return sText;
}

//CEleBusDuct::DoGetTextDropVoltMotor
export const doGetTextDropVoltMotor = (props:MsBusductModel, bUnit: boolean = true) => {
    return doGetTextDropVoltMotorCalcPoint(props.calcPoint0, bUnit)
}

//CEleBusDuct::DoGetTextDropVoltMotor2
export const doGetTextDropVoltMotor2 = (props:MsBusductModel, bUnit: boolean = true) => {
    return  doGetTextDropVoltMotor2CalcPoint(props.calcPoint0, bUnit)
}

//CEleBusDuct::DoGetTextDropPerMotor
export const doGetTextDropPerMotor = (props:MsBusductModel, bUnit: boolean = true) => {
    return doGetTextDropPerMotorCalcPoint(props.calcPoint0, bUnit)
}

//CEleBusDuct::DoGetTextVoltage
export const doGetTextVoltage = (props:MsBusductModel, nPartKind:number, bUnit:boolean = true) => {
	let sText = '';
	if (props.sPhase) {
		switch (nPartKind) {
		case MS_OUTSIDE_NEUTRAL:
			sText = (props.voltage / 2).toString();
			break;
		case MS_OUTSIDE_OUTSIDE:
			sText = props.voltage.toString();
			break;
		case MS_SEAT_MAIN:
			sText = props.voltage.toString();
			break;
		case MS_SEAT_T:
			sText = props.voltage.toString();
			break;
		}
	}
	else {
		sText = props.voltage.toString();
	}
	if (bUnit) {
		sText += 'V';
	}
	return sText;
}

//CEleBusDuct::DoGetTextLoadCurrent
export const doGetTextLoadCurrent = (props:MsBusductModel, nPartKind:number, bUnit: boolean = true) => {
    let sText = '';
    if(props.sPhase) {
        switch(nPartKind) {
            case MS_OUTSIDE_NEUTRAL:
            case MS_SEAT_MAIN:
                sText = BeamsNumber(props.calcPoint1.loadCurrent, 5).toString();
                break;
            case MS_OUTSIDE_OUTSIDE:
            case MS_SEAT_T:
                sText = BeamsNumber(props.calcPoint0.loadCurrent, 5).toString();
                break;
        }
    }
    else {
        if(props.downTransKind == MS_TRANS1) {
            sText = BeamsNumber(props.calcPoint1.loadCurrent, 5).toString();
        }
        else {
            sText = BeamsNumber(props.calcPoint0.loadCapacity, 5).toString();
        }
    }
    if(bUnit) {
        sText += 'A';
    }
    return sText;
}

//CEleBusDuct::DoGetTextDropVoltPF
export const doGetTextDropVoltPF = (props:MsBusductModel, nPartKind:number, bUnit: boolean = true) => {
    let sText = '';
    if(props.sPhase) {
        switch(nPartKind) {
            case MS_OUTSIDE_NEUTRAL:
            case MS_SEAT_MAIN:
                sText = BeamsNumber(props.calcPoint1.powerFactor, 5).toString();
                if(bUnit) {
                    if(props.calcPoint1.isLead) {
                        sText+= '(進み)';
                    }
                    else {
                        sText += '(遅れ)';
                    }
                }
                break;
            case MS_OUTSIDE_OUTSIDE:
            case MS_SEAT_T:
                sText = BeamsNumber(props.calcPoint0.powerFactor, 5).toString();
                if(bUnit) {
                    if(props.calcPoint0.isLead) {
                        sText+= '(進み)';
                    }
                    else {
                        sText += '(遅れ)';
                    }
                }
                break;
        }
    }
    else {
        if(props.downTransKind == MS_TRANS1) {
            sText = BeamsNumber(props.calcPoint1.powerFactor, 5).toString();
            if(bUnit) {
                if(props.calcPoint1.isLead) {
                    sText+= '(進み)';
                }
                else {
                    sText += '(遅れ)';
                }
            }
        }
        else {
            sText = BeamsNumber(props.calcPoint0.powerFactor, 5).toString();
            if(bUnit) {
                if(props.calcPoint0.isLead) {
                    sText+= '(進み)';
                }
                else {
                    sText += '(遅れ)';
                }
            }
        }
    }
    return sText;
}

//CEleBusDuct::DoGetTextDropVoltDiff
export const doGetTextDropVoltDiff = (props:MsBusductModel, dStdCapacity: number, dStdVoltage: number, nPartKind:number, bUnit: boolean = true) => {
    let dVolt = 0;
    let percentZ = doGetPerZrBusDuct(props, dStdCapacity, RS_3PHASE, nPartKind, false);

    if(props.sPhase) {
        switch(nPartKind) {
            case MS_OUTSIDE_NEUTRAL:
            case MS_SEAT_MAIN:
                dVolt = doGetValueDropVoltRegularCalcPoint(dStdCapacity, percentZ.perRk3, percentZ.perXk3, props.calcPoint1);
                break;
            case MS_OUTSIDE_OUTSIDE:
            case MS_SEAT_T:
                dVolt = doGetValueDropVoltRegularCalcPoint(dStdCapacity, percentZ.perRk3, percentZ.perXk3, props.calcPoint0);
                break;
        }
        dVolt = dStdVoltage - dVolt;
    }
    else {
        if(props.downTransKind == MS_TRANS1) {
            dVolt = doGetValueDropVoltRegularCalcPoint(dStdVoltage, percentZ.perRk3, percentZ.perXk3, props.calcPoint1);
        }
        else {
            dVolt = doGetValueDropVoltRegularCalcPoint(dStdVoltage, percentZ.perRk3, percentZ.perXk3, props.calcPoint0);
        }
        dVolt = dStdVoltage - dVolt;
        // V2.41
        //if(props.nWireSystem == RS_PHASE_N || props.nWireSystem == RS_PHASE_PE) {
        //  dVolt /= Math.sqrt(3);
        //}
    }

    let sText = BeamsNumber(dVolt, 4).toString();
    if(bUnit) {
        sText += 'V';
    }
    return sText;
}

//CEleBusDuct::DoGetTextDropVoltRegular
export const doGetTextDropVoltRegular = (props:MsBusductModel, dStdCapacity: number, dStdVoltage:number, nPartKind:number, bUnit: boolean = true) => {
    let percentZ = doGetPerZrBusDuct(props, dStdCapacity, RS_3PHASE, nPartKind, false);
    let sText = '';
    if(props.sPhase) {
        switch(nPartKind) {
            case MS_OUTSIDE_NEUTRAL:
            case MS_SEAT_MAIN:
                sText = doGetTextDropVoltRegularCalcPoint(props.calcPoint1, dStdVoltage, percentZ.perRk3, percentZ.perXk3, bUnit);
                break;
            case MS_OUTSIDE_OUTSIDE:
            case MS_SEAT_T:
                sText = doGetTextDropVoltRegularCalcPoint(props.calcPoint0, dStdVoltage, percentZ.perRk3, percentZ.perXk3, bUnit);
                break;
        }
    } 
    else {
        if(props.downTransKind == MS_TRANS1) {
            sText = doGetTextDropVoltRegularCalcPoint(props.calcPoint1, dStdVoltage, percentZ.perRk3, percentZ.perXk3, bUnit);
        }
        else {
            sText = doGetTextDropVoltRegularCalcPoint(props.calcPoint0, dStdVoltage, percentZ.perRk3, percentZ.perXk3, bUnit);
        }
    }
    return sText;
}

//CEleBusDuct::DoGetTextDropPerRegular
export const doGetTextDropPerRegular = (props:MsBusductModel, dStdCapacity: number, dStdVoltage:number, nPartKind:number, bUnit: boolean = true) => {
    let sText = '';
    let percentZ = doGetPerZrBusDuct(props, dStdCapacity, RS_3PHASE, nPartKind, false);
    if(props.sPhase) {
        switch(nPartKind) {
            case MS_OUTSIDE_NEUTRAL:
            case MS_SEAT_MAIN:
                sText = doGetTextDropPerRegularCalcPoint(props.calcPoint1, dStdVoltage, percentZ.perRk3, percentZ.perXk3, bUnit);
                break;
            case MS_OUTSIDE_OUTSIDE:
            case MS_SEAT_T:
                sText = doGetTextDropPerRegularCalcPoint(props.calcPoint0, dStdVoltage, percentZ.perRk3, percentZ.perXk3, bUnit);
                break;
        }
    }
    else {
        if(props.downTransKind == MS_TRANS1) {
            sText = doGetTextDropPerRegularCalcPoint(props.calcPoint1, dStdVoltage, percentZ.perRk3, percentZ.perXk3, bUnit);
        }
        else {
            sText = doGetTextDropPerRegularCalcPoint(props.calcPoint0, dStdVoltage, percentZ.perRk3, percentZ.perXk3, bUnit);
        }
    }
    return sText;
}
 
//CEleBusDuct::DoGetRefV1
export const doGetRefV1 = (mode:any, control:ControlModel, bSociety:boolean) =>
{
    let props = control.properties as MsBusductModel;
    let infoCircuit = store.getState().app.diagram.infoCircuit;
    let project = store.getState().app.projectData;

	//CEleBusDuct::DoGetRefV1Left  sRefV1Left
	let labelTexts = [{
		value: control.properties.refNo,
		color: "#f800f8",
	}];

	if (!props.viewResultText || (mode != ProcessMode.DRAWING && !props.supply))
    {
      return labelTexts;
    }
    
    if (bSociety){
        return labelTexts;
    }

	switch(mode) {
        case ProcessMode.DRAWING:
        case ProcessMode.CHECKING:

			 //Length / Material
			 labelTexts.push({
                value: doGetTextLength(props)
                    + ','
                    + doGetTextMaterial(props)
                    ,
                color: "blue",
            });

			 //Capacity
			 labelTexts.push({
                value: doGetTextCapacity(props, RS_3PHASE),
                color: "blue",
            });

            //Size 
            labelTexts.push({
                value: doGetTextSize(props, RS_3PHASE),
                color: "blue",
            });
            if(props.voltage > 0) 
            {
                //OhmZ = OhmR +j OhmX
                labelTexts.push({
                    value: doGetTextOhmZ(props, RS_3PHASE)
                    + '='
                    + doGetTextOhmR(props, RS_3PHASE, false)
                    + '+j'
                    + doGetTextOhmX(props, RS_3PHASE, false),
                    color: "blue",
                });
            }       
			break;
		case ProcessMode.PERCENT_Z:
			if(props.sPhase) {
				switch(props.upTransKind) {
					case MS_TRANS1:
						//XR
                        labelTexts.push({
                            value: 'X/R=' 
                            + doGetTextXR(props,RS_3PHASE),
                            color: "blue",
                        });

                        //OUTSIDE_OUTSIDE
                        labelTexts.push({
                            value: '外線-外線',
                            color: "blue",
                        });
                        
                        //Zr = Rr + jXr
                        labelTexts.push({
                            value: doGetTextPercentZr(props, RS_3PHASE, MS_OUTSIDE_OUTSIDE, infoCircuit.stdCapacity)
                            + '='
                            + doGetTextPercentRr(props, RS_3PHASE, MS_OUTSIDE_OUTSIDE, infoCircuit.stdCapacity, false)
                            + '+j'
                            + doGetTextPercentXr(props, RS_3PHASE, MS_OUTSIDE_OUTSIDE, infoCircuit.stdCapacity, false)
                            ,
                            color: "blue",
                        });

                        //OUTSIDE_NEUTRAL
                        labelTexts.push({
                            value: '外線-中性線',
                            color: "blue",
                        });

                         //Zr = Rr + jXr
                         labelTexts.push({
                            value: doGetTextPercentZr(props, RS_3PHASE, MS_OUTSIDE_NEUTRAL, infoCircuit.stdCapacity)
                            + '='
                            + doGetTextPercentRr(props, RS_3PHASE, MS_OUTSIDE_NEUTRAL, infoCircuit.stdCapacity, false)
                            + '+j'
                            + doGetTextPercentXr(props, RS_3PHASE, MS_OUTSIDE_NEUTRAL, infoCircuit.stdCapacity, false)
                            ,
                            color: "blue",
                        });
						break;
					case MS_TRANSSCOTT:
                        //XR
                        labelTexts.push({
                            value: 'X/R='
                            + doGetTextXR(props, RS_3PHASE),
                            color: "blue",
                        });

                        //SEAT_MAIN
                        labelTexts.push({
                            value: '主座',
                            color: "blue",
                        });

                        //Zr = Rr + jXr
                        labelTexts.push({
                            value: doGetTextPercentZr(props, RS_3PHASE, MS_SEAT_MAIN, infoCircuit.stdCapacity)
                            + '='
                            + doGetTextPercentRr(props, RS_3PHASE, MS_SEAT_MAIN, infoCircuit.stdCapacity, false)
                            + '+j'
                            + doGetTextPercentXr(props, RS_3PHASE, MS_SEAT_MAIN, infoCircuit.stdCapacity, false)
                            ,
                            color: "blue",
                        });

                        //SEAT_T
                        labelTexts.push({
                            value: 'T座',
                            color: "blue",
                        });

                         //Zr = Rr + jXr
                         labelTexts.push({
                            value: doGetTextPercentZr(props, RS_3PHASE, MS_SEAT_T, infoCircuit.stdCapacity)
                            + '='
                            + doGetTextPercentRr(props, RS_3PHASE, MS_SEAT_T, infoCircuit.stdCapacity, false)
                            + '+j'
                            + doGetTextPercentXr(props, RS_3PHASE, MS_SEAT_T, infoCircuit.stdCapacity, false)
                            ,
                            color: "blue",
                        });
						break;
				}
			}
			else 
			{
                 //XR
                 labelTexts.push({
                    value: 'X/R='
                    + doGetTextXR(props, RS_3PHASE),
                    color: "blue",
                });

                //Zr = Rr + jXr
                labelTexts.push({
                    value: doGetTextPercentZr(props, RS_3PHASE, MS_3PHASES, infoCircuit.stdCapacity)
                    + '='
                    + doGetTextPercentRr(props, RS_3PHASE, MS_3PHASES, infoCircuit.stdCapacity, false)
                    + '+j'
                    + doGetTextPercentXr(props, RS_3PHASE, MS_3PHASES, infoCircuit.stdCapacity, false)
                    ,
                    color: "blue",
                });
			}
			break;
		case ProcessMode.CALCULATION:
            if(!isPointFaultBusDuct(props)) {
                break;
            }
            if(props.sPhase) {
                switch(props.upTransKind) {
                    case MS_TRANS1:
                        //OUTSIDE_OUTSIDE
                        labelTexts.push({
                            value: '外線-外線',
                            color: "blue",
                        });

                        // += 'I"k2' + '通過' + '=' + DoGetTextDirectIkpeak()
                        labelTexts.push({
                            value: 'I"k2通過='
                            + doGetTextDirectIkpeak(props, MS_OUTSIDE_OUTSIDE),
                            color: "blue",
                        });

                        // += 'I"k2' + '通過' + '=' + DoGetTextDirectIkasym()
                        labelTexts.push({
                            value: 'I"k2通過='
                            + doGetTextDirectIkasym(props, MS_OUTSIDE_OUTSIDE),
                            color: "blue",
                        });

                         // += 'I"k2' + '通過' + '=' + DoGetTextDirectIksym()
                        labelTexts.push({
                            value: 'I"k2通過='
                            + doGetTextDirectIksym(props, MS_OUTSIDE_OUTSIDE),
                            color: "blue",
                        });
                        
                         //OUTSIDE_NEUTRAL
                         labelTexts.push({
                            value: '外線-中性線',
                            color: "blue",
                        });

                        // += 'I"k2' + '通過' + '=' + DoGetTextDirectIkpeak()
                        labelTexts.push({
                            value: 'I"k2通過='
                            + doGetTextDirectIkpeak(props, MS_OUTSIDE_NEUTRAL),
                            color: "blue",
                        });

                        // += 'I"k2' + '通過' + '=' + DoGetTextDirectIkasym()
                        labelTexts.push({
                            value: 'I"k2通過='
                            + doGetTextDirectIkasym(props, MS_OUTSIDE_NEUTRAL),
                            color: "blue",
                        });

                         // += 'I"k2' + '通過' + '=' + DoGetTextDirectIksym()
                        labelTexts.push({
                            value: 'I"k2通過='
                            + doGetTextDirectIksym(props, MS_OUTSIDE_NEUTRAL),
                            color: "blue",
                        });
                        break;
                    case MS_TRANSSCOTT:
                         //SEAT_MAIN
                         labelTexts.push({
                            value: '主座',
                            color: "blue",
                        });

                        // += 'I"k2' + '通過' + '=' + DoGetTextDirectIkpeak()
                        labelTexts.push({
                            value: 'I"k2通過='
                            + doGetTextDirectIkpeak(props, MS_SEAT_MAIN),
                            color: "blue",
                        });

                        // += 'I"k2' + '通過' + '=' + DoGetTextDirectIkasym()
                        labelTexts.push({
                            value: 'I"k2通過='
                            + doGetTextDirectIkasym(props, MS_SEAT_MAIN),
                            color: "blue",
                        });

                         // += 'I"k2' + '通過' + '=' + DoGetTextDirectIksym()
                        labelTexts.push({
                            value: 'I"k2通過='
                            + doGetTextDirectIksym(props, MS_SEAT_MAIN),
                            color: "blue",
                        });

                        //SEAT_T
                        labelTexts.push({
                            value: 'T座',
                            color: "blue",
                        });

                        // += 'I"k2' + '通過' + '=' + DoGetTextDirectIkpeak()
                        labelTexts.push({
                            value: 'I"k2通過='
                            + doGetTextDirectIkpeak(props, MS_SEAT_T),
                            color: "blue",
                        });

                        // += 'I"k2' + '通過' + '=' + DoGetTextDirectIkasym()
                        labelTexts.push({
                            value: 'I"k2通過='
                            + doGetTextDirectIkasym(props, MS_SEAT_T),
                            color: "blue",
                        });

                        // += 'I"k2' + '通過' + '=' + DoGetTextDirectIksym()
                        labelTexts.push({
                            value: 'I"k2通過='
                            + doGetTextDirectIksym(props, MS_SEAT_T),
                            color: "blue",
                        });
                        break;
                }
            }
            else {
                if(props.downTransKind === MS_TRANS1) {
                     // += 'I"k2' + '通過' + '=' + DoGetTextDirectIkpeak()
                     labelTexts.push({
                        value: 'I"k2通過='
                        + doGetTextDirectIkpeak(props, MS_3PHASES),
                        color: "blue",
                    });

                    // += 'I"k2' + '通過' + '=' + DoGetTextDirectIkasym()
                    labelTexts.push({
                        value: 'I"k2通過='
                        + doGetTextDirectIkasym(props, MS_3PHASES),
                        color: "blue",
                    });

                    // += 'I"k2' + '通過' + '=' + DoGetTextDirectIksym()
                    labelTexts.push({
                        value: 'I"k2通過='
                        + doGetTextDirectIksym(props, MS_3PHASES),
                        color: "blue",
                    });

                    // += 'I"k2' + '合計' + '=' + DoGetTextDirectIksym()
                    labelTexts.push({
                        value: 'I"k2合計='
                        + doGetTextTotalIk3sym(props),
                        color: "blue",
                    });
                }
                else {
                    // += 'I"k3' + '通過' + '=' + DoGetTextDirectIkpeak()
                    labelTexts.push({
                        value: 'I"k3通過='
                        + doGetTextDirectIkpeak(props, MS_3PHASES),
                        color: "blue",
                    });

                    // += 'I"k3' + '通過' + '=' + DoGetTextDirectIkasym()
                    labelTexts.push({
                        value: 'I"k3通過='
                        + doGetTextDirectIkasym(props, MS_3PHASES),
                        color: "blue",
                    });

                    // += 'I"k3' + '通過' + '=' + DoGetTextDirectIksym()
                    labelTexts.push({
                        value: 'I"k3通過='
                        + doGetTextDirectIksym(props, MS_3PHASES),
                        color: "blue",
                    });

                    // += 'I"k3' + '合計' + '=' + DoGetTextDirectIksym()
                    labelTexts.push({
                        value: 'I"k3合計='
                        + doGetTextTotalIk3sym(props),
                        color: "blue",
                    });
                }
            }
			break;
		case ProcessMode.VOLTDROP:
            if(project.dropMode == MS_VOLTDROP_MOTOR && props.dropMotor && !props.sPhase && isSetDropPerMotor(props.calcPoint0)) {
                // Vd = DropVoltMotor(VoltSecondary - DropVoltMotor2)
                let sTemp = 'Vd=' + doGetTextDropVoltMotor(props) + ' (';
                if (props.supply) {
                    sTemp += doGetTextVoltage(props, MS_3PHASES, false);
                    sTemp += '-';
                    sTemp += doGetTextDropVoltMotor2(props, false);
                } else {
                    sTemp += '0-0'
                }
                sTemp += ')';
                labelTexts.push({
                    value: sTemp,
                    color: "blue",
                });

                //Vp = DoGetTextDropPerMotor (VoltSecondary → DropVoltMotor2)
                sTemp = 'Vp=' + doGetTextDropPerMotor(props) + ' (';
                if (props.supply) {
                    sTemp += doGetTextVoltage(props, MS_3PHASES, false);
                    sTemp += '→';
                    sTemp += doGetTextDropVoltMotor2(props, false);
                } else {
                    sTemp += '0→0';
                }
                sTemp += ')';
                labelTexts.push({
                    value: sTemp,
                    color: "blue",
                });
                break;
            }
            if(isCennectSourceDropElement(control, doGetLoadCurrentStartSide(control)) 
            && isCennectLoadDropElement(control, doGetLoadCurrentEndSide(control))
            && project.dropMode == MS_VOLTDROP_REGULAR && props.dropRegular) {
                let stdVoltage = {
                    dStdVoltage : 0,
                    sStdVoltage : '0'
                };
                if(props.sPhase) {
                    switch(props.upTransKind) {
                        case MS_TRANS1:
                            if(props.supply) {
                               stdVoltage = doGetTextVoltDropRegular(control, MS_OUTSIDE_OUTSIDE, false);
                            }

                             //OUTSIDE_OUTSIDE
                            labelTexts.push({
                                value: '外線-外線',
                                color: "blue",
                            });

                             //IL=DoGetTextLoadCurrent,力率=DoGetTextDropVoltPF
                            labelTexts.push({
                                value: 'IL='
                                + doGetTextLoadCurrent(props, MS_OUTSIDE_OUTSIDE)
                                + ',力率='
                                + doGetTextDropVoltPF(props, MS_OUTSIDE_OUTSIDE),
                                color: "blue",
                            });

                            //Vd=DoGetTextDropVoltDiff (sStdVoltage-DoGetTextDropVoltRegular)
                            labelTexts.push({
                                value: 'Vd='
                                + doGetTextDropVoltDiff(props, infoCircuit.stdCapacity, props.voltage, MS_OUTSIDE_OUTSIDE)
                                + ' ('
                                + stdVoltage.sStdVoltage
                                + '-'
                                + doGetTextDropVoltRegular(props, infoCircuit.stdCapacity, stdVoltage.dStdVoltage, MS_OUTSIDE_OUTSIDE, false)
                                + ')',
                                color: "blue",
                            });

                            //Vp=DoGetTextDropPerRegular (DoGetTextVoltage→doGetTextDropVoltRegular)
                            let sTemp = 'Vp=';
                            sTemp += doGetTextDropPerRegular(props, infoCircuit.stdCapacity, stdVoltage.dStdVoltage, MS_OUTSIDE_OUTSIDE);
                            sTemp += ' (';
                            if(props.supply) {
                                sTemp += doGetTextVoltage(props, MS_OUTSIDE_OUTSIDE, false);
                            } 
                            else {
                                sTemp += stdVoltage.sStdVoltage;
                            }
                            sTemp += '→';
                            sTemp += doGetTextDropVoltRegular(props, infoCircuit.stdCapacity, stdVoltage.dStdVoltage, MS_OUTSIDE_OUTSIDE, false);
                            sTemp += ')';
                            labelTexts.push({
                                value: sTemp,
                                color: "blue",
                            });
                            
                            if(props.supply) {
                                stdVoltage = doGetTextVoltDropRegular(control, MS_OUTSIDE_NEUTRAL, false);
                            }

                            //OUTSIDE_NEUTRAL
                            labelTexts.push({
                                value: '外線-中性線',
                                color: "blue",
                            });

                            //IL=DoGetTextLoadCurrent,力率=DoGetTextDropVoltPF
                            labelTexts.push({
                                value: 'IL='
                                + doGetTextLoadCurrent(props, MS_OUTSIDE_NEUTRAL)
                                + ',力率='
                                + doGetTextDropVoltPF(props, MS_OUTSIDE_NEUTRAL),
                                color: "blue",
                            });

                             //Vd=DoGetTextDropVoltDiff (sStdVoltage-DoGetTextDropVoltRegular)
                             labelTexts.push({
                                value: 'Vd='
                                + doGetTextDropVoltDiff(props, infoCircuit.stdCapacity, props.voltage / 2, MS_OUTSIDE_NEUTRAL)
                                + ' ('
                                + stdVoltage.sStdVoltage
                                + '-'
                                + doGetTextDropVoltRegular(props, infoCircuit.stdCapacity, stdVoltage.dStdVoltage, MS_OUTSIDE_NEUTRAL, false)
                                + ')',
                                color: "blue",
                            });

                            //Vp=DoGetTextDropPerRegular (DoGetTextVoltage→DoGetTextDropVoltRegular)
                            sTemp = 'Vp=';
                            sTemp += doGetTextDropPerRegular(props, infoCircuit.stdCapacity, stdVoltage.dStdVoltage, MS_OUTSIDE_NEUTRAL);
                            sTemp += ' (';
                            if(props.supply) {
                                sTemp += doGetTextVoltage(props, MS_OUTSIDE_NEUTRAL, false);
                            }
                            else {
                                sTemp += stdVoltage.sStdVoltage;
                            }
                            sTemp += '→';
                            sTemp += doGetTextDropVoltRegular(props, infoCircuit.stdCapacity, stdVoltage.dStdVoltage, MS_OUTSIDE_NEUTRAL, false);
                            sTemp += ')';
                            labelTexts.push({
                                value: sTemp,
                                color: "blue",
                            });
                            break;
                        case MS_TRANSSCOTT:
                            if(props.supply) {
                                stdVoltage = doGetTextVoltDropRegular(control, MS_SEAT_MAIN, false);
                            }
                            
                            //SEAT_MAIN
                            labelTexts.push({
                                value: '主座',
                                color: "blue",
                            });

                            //IL=DoGetTextLoadCurrent,力率=DoGetTextDropVoltPF
                            labelTexts.push({
                                value: 'IL='
                                + doGetTextLoadCurrent(props, MS_SEAT_MAIN)
                                + ',力率='
                                + doGetTextDropVoltPF(props, MS_SEAT_MAIN),
                                color: "blue",
                            });

                            //Vd=DoGetTextDropVoltDiff (sStdVoltage-DoGetTextDropVoltRegular)
                            labelTexts.push({
                                value: 'Vd='
                                + doGetTextDropVoltDiff(props, infoCircuit.stdCapacity, props.voltage, MS_SEAT_MAIN)
                                + ' ('
                                + stdVoltage.sStdVoltage
                                + '-'
                                + doGetTextDropVoltRegular(props, infoCircuit.stdCapacity, stdVoltage.dStdVoltage, MS_SEAT_MAIN, false)
                                + ')',
                                color: "blue",
                            });

                            //Vp=DoGetTextDropPerRegular (DoGetTextVoltage→DoGetTextDropVoltRegular)
                            sTemp = 'Vp=';
                            sTemp += doGetTextDropPerRegular(props, infoCircuit.stdCapacity, stdVoltage.dStdVoltage, MS_SEAT_MAIN);
                            sTemp += ' (';
                            if(props.supply) {
                                sTemp += doGetTextVoltage(props, MS_SEAT_MAIN, false);
                            }
                            else {
                                sTemp += stdVoltage.sStdVoltage;
                            }
                            sTemp += '→';
                            sTemp += doGetTextDropVoltRegular(props, infoCircuit.stdCapacity, stdVoltage.dStdVoltage, MS_SEAT_MAIN, false);
                            sTemp += ')';
                            labelTexts.push({
                                value: sTemp,
                                color: "blue",
                            });

                            if(props.supply) {
                                stdVoltage = doGetTextVoltDropRegular(control, MS_SEAT_T, false);
                            }

                            //SEAT_T
                            labelTexts.push({
                                value: 'T座',
                                color: "blue",
                            });

                            //IL=DoGetTextLoadCurrent,力率=DoGetTextDropVoltPF
                            labelTexts.push({
                                value: 'IL='
                                + doGetTextLoadCurrent(props, MS_SEAT_T)
                                + ',力率='
                                + doGetTextDropVoltPF(props, MS_SEAT_T),
                                color: "blue",
                            });

                            //Vd=DoGetTextDropVoltDiff (sStdVoltage-DoGetTextDropVoltRegular)
                            labelTexts.push({
                                value: 'Vd='
                                + doGetTextDropVoltDiff(props, infoCircuit.stdCapacity, props.voltage, MS_SEAT_T)
                                + ' ('
                                + stdVoltage.sStdVoltage
                                + '-'
                                + doGetTextDropVoltRegular(props, infoCircuit.stdCapacity, stdVoltage.dStdVoltage, MS_SEAT_T, false)
                                + ')',
                                color: "blue",
                            });

                            //Vp=DoGetTextDropPerRegular (DoGetTextVoltage→DoGetTextDropVoltRegular)
                            sTemp = 'Vp=';
                            sTemp += doGetTextDropPerRegular(props, infoCircuit.stdCapacity, stdVoltage.dStdVoltage, MS_SEAT_T);
                            sTemp += ' (';
                            if(props.supply) {
                                sTemp += doGetTextVoltage(props, MS_SEAT_T, false);
                            }
                            else {
                                sTemp += stdVoltage.sStdVoltage;
                            }
                            sTemp += '→';
                            sTemp += doGetTextDropVoltRegular(props, infoCircuit.stdCapacity, stdVoltage.dStdVoltage, MS_SEAT_T, false);
                            sTemp += ')';
                            labelTexts.push({
                                value: sTemp,
                                color: "blue",
                            });
                            break; 
                    }
                }
                else {
                    if(props.supply) {
                        stdVoltage = doGetTextVoltDropRegular(control, MS_3PHASES, false);
                    }

                    //IL=DoGetTextLoadCurrent,力率=DoGetTextDropVoltPF
                    labelTexts.push({
                        value: 'IL='
                        + doGetTextLoadCurrent(props, MS_3PHASES)
                        + ',力率='
                        + doGetTextDropVoltPF(props, MS_3PHASES),
                        color: "blue",
                    });

                    //Vd=DoGetTextDropVoltDiff (sStdVoltage-DoGetTextDropVoltRegular)
                    labelTexts.push({
                        value: 'Vd='
                        + doGetTextDropVoltDiff(props, infoCircuit.stdCapacity, props.voltage, MS_3PHASES)
                        + ' ('
                        + stdVoltage.sStdVoltage
                        + '-'
                        + doGetTextDropVoltRegular(props, infoCircuit.stdCapacity, stdVoltage.dStdVoltage, MS_3PHASES, false)
                        + ')',
                        color: "blue",
                    });

                    //Vp=DoGetTextDropPerRegular (DoGetTextVoltage→DoGetTextDropVoltRegular)
                    let sTemp = 'Vp=';
                    sTemp += doGetTextDropPerRegular(props, infoCircuit.stdCapacity, stdVoltage.dStdVoltage, MS_3PHASES);
                    sTemp += ' (';
                    if(props.supply) {
                        sTemp += doGetTextVoltage(props, MS_3PHASES, false);
                    }
                    else {
                        sTemp += stdVoltage.sStdVoltage;
                    }
                    sTemp += '→';
                    sTemp += doGetTextDropVoltRegular(props, infoCircuit.stdCapacity, stdVoltage.dStdVoltage, MS_3PHASES, false);
                    sTemp += ')';
                    labelTexts.push({
                        value: sTemp,
                        color: "blue",
                    });
                    break;
                }
            }
			break;
		case ProcessMode.HARMONIC:
			break;
		case ProcessMode.POWER_FLOW:
			break;
	}

	return labelTexts;
}

//CEleBusDuct::DoGetTextDirectPercentZr
export const doGetTextDirectPercentZr = (props:ControlModel, nPartKind:number, bUnit:boolean = true) =>
{
	let dPerZ = 0;
	if (props.properties.sPhase) {		// 単相回路のとき
		switch (nPartKind) {
		case MS_OUTSIDE_NEUTRAL:	// 外線－中性線間
		case MS_SEAT_MAIN:			// 主座変圧器
			dPerZ = doGetPerZDirectMG(props.properties.calcPoint1).dPercentZ;
			break;
		case MS_OUTSIDE_OUTSIDE:	// 外線間
		case MS_SEAT_T:				// Ｔ座変圧器
			dPerZ = doGetPerZDirectMG(props.properties.calcPoint0).dPercentZ;
			break;
		}
	}
	else {							// 三相回路のとき
		if (props.properties.downTransKind == MS_TRANS1) {
			dPerZ = doGetPerZDirectMG(props.properties.calcPoint1).dPercentZ;
		}
		else {
			dPerZ = doGetPerZDirectMG(props.properties.calcPoint0).dPercentZ;
		}
	}

	let sText = '';
	if (dPerZ >= 0) {
		sText = BeamsNumber(dPerZ, 4).toString();
		if (bUnit) {
			sText += '%r';
		}
	}
	else {							// 短絡電流が正しく計算されなかったとき
		sText = '????';
	}
	return sText;
}

//CEleBusDuct::DoGetTextTotalIk3peak
export const doGetTextTotalIk3peak = (props:MsBusductModel, bUnit:boolean = true) =>
{
	let dIpeakTotal = 0;
	if (props.downTransKind == MS_TRANS1) {
		dIpeakTotal = doGetIkpeakTotalMG(props.calcPoint1);
	}
	else {
		dIpeakTotal = doGetIkpeakTotalMG(props.calcPoint0);
	}
	let sText = '';
	if (dIpeakTotal == 0) {
		if (props.primarySupply) {
			sText = '∞';
		}
		else {
			sText = "0";
		}
	}
	else if (dIpeakTotal > 0) {
		sText = BeamsNumber(dIpeakTotal / 1000, 4).toString();
		if (bUnit) {
			sText += 'kApeak';
		}
	}
	else {							// 短絡電流が正しく計算されなかったとき
		sText = '????';
	}
	return sText;
}

//CEleBusDuct::DoGetTextTotalIk3asym
export const doGetTextTotalIk3asym = (props:MsBusductModel, bUnit:boolean = true) =>
{
	let dIasymTotal = 0;
	if (props.downTransKind == MS_TRANS1) {
		dIasymTotal = doGetIkasymTotalMG(props.calcPoint1);
	}
	else {
		dIasymTotal = doGetIkasymTotalMG(props.calcPoint0);
	}
	let sText = '';
	if (dIasymTotal == 0) {
		if (props.primarySupply) {
			sText = '∞';
		}
		else {
			sText = "0";
		}
	}
	else if (dIasymTotal > 0) {
		sText = BeamsNumber(dIasymTotal / 1000, 4).toString();
		if (bUnit) {
			sText += 'kAasym';
		}
	}
	else {							// 短絡電流が正しく計算されなかったとき
		sText = '????';
	}
	return sText;
}

//CEleBusDuct::DoGetTextTotalPercentZr
export const doGetTextTotalPercentZr = (props:MsBusductModel, bUnit:boolean = true) =>
{
	let dPerZ = 0;
	if (props.downTransKind == MS_TRANS1) {
		dPerZ = doGetPerZTotalMG(props.calcPoint1).dPercentZ;
	}
	else {
		dPerZ = doGetPerZTotalMG(props.calcPoint0).dPercentZ;
	}
	let sText = '';
	if (dPerZ >= 0) {
		sText = BeamsNumber(dPerZ, 4).toString();
		if (bUnit) {
			sText += '%r';
		}
	}
	else {							// 短絡電流が正しく計算されなかったとき
		sText = '????';
	}
	return sText;
}

//CEleBusDuct::DoGetTextActiveLeft
export const doGetTextActiveLeft = (props:MsBusductModel, nDirect:number, bUnit:boolean = true) =>
{
	let sText = '';
	if (nDirect == DIRECT_NONE) {
		if (props.lineActiveFlow1 >= 0) {
			sText = BeamsNumber(props.lineActiveFlow1, 5).toString();
		}
		else {
			sText = BeamsNumber(props.lineActiveFlow2, 5).toString();
		}
	}
	else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
		sText = BeamsNumber(Math.abs(props.lineActiveFlow1), 5).toString();
	}
	else {
		sText = BeamsNumber(Math.abs(props.lineActiveFlow2), 5).toString();
	}

	if (bUnit) {
		sText += 'kW';
	}

	return sText;
}

//CEleBusDuct::DoGetTextReactiveLeft
export const doGetTextReactiveLeft = (props:MsBusductModel, nDirect:number, bUnit:boolean = true) =>
{
	let sText = '';
	if (nDirect == DIRECT_NONE) {
		if (props.lineReactiveFlow1 >= 0) {
			sText = BeamsNumber(props.lineReactiveFlow1, 5).toString();
		}
		else {
			sText = BeamsNumber(props.lineReactiveFlow2, 5).toString();
		}
	}
	else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
		sText = BeamsNumber(Math.abs(props.lineReactiveFlow1), 5).toString();
	}
	else {
		sText = BeamsNumber(Math.abs(props.lineReactiveFlow2), 5).toString();
	}

	if (bUnit) {
		sText += 'kvar';
	}

	return sText;
}

//CEleBusDuct::DoGetTextFlowsResult
export const doGetTextFlowsResult = (props:MsBusductModel, processMode:any, nDirect:number, bSociety:boolean = false) =>
{
	let sRefV1:string[] = [];

	if (bSociety) {
		return sRefV1;
	}

	switch (processMode) {
	case MS_MODE_DRAWING:
	case MS_MODE_CHECKING:
	case MS_MODE_PERCENT_Z:
	case MS_MODE_CALCULATION:
	case MS_MODE_VOLTDROP:
	case MS_MODE_HARMONIC:
		break;
	case MS_MODE_POWER_FLOW:
		if (!props.sPhase && props.lineActiveFlow1 !==0 && props.lineActiveFlow2 !==0 && props.lineReactiveFlow1 !==0 && props.lineReactiveFlow2 !==0) {
			if (nDirect == DIRECT_NONE) {
				sRefV1.push(doGetTextActiveLeft(props,nDirect));
				sRefV1.push(doGetTextReactiveLeft(props,nDirect));
			}
			else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
                sRefV1.push(doGetTextActiveLeft(props,nDirect));
                let sTemp = ''
				if (props.lineActiveFlow1 > 0) {
					if (props.lineReactiveFlow1 > 0) {
						sTemp += "+j";
					}
					else {
						sTemp += "-j";
					}
				}
				else {
					if (props.lineReactiveFlow1 > 0) {
						sTemp += "-j";
					}
					else {
						sTemp += "+j";
					}
				}
				sRefV1.push(sTemp + doGetTextReactiveLeft(props,nDirect));
			}
			else {
                sRefV1.push(doGetTextActiveLeft(props,nDirect));
                let sTemp = ''
				if (props.lineActiveFlow2 > 0) {
					if (props.lineReactiveFlow2 > 0) {
						sTemp += "+j";
					}
					else {
						sTemp += "-j";
					}
				}
				else {
					if (props.lineReactiveFlow2 > 0) {
						sTemp += "-j";
					}
					else {
						sTemp += "+j";
					}
				}
				sRefV1.push(sTemp + doGetTextReactiveLeft(props,nDirect));
			}
		}
		break;
	}

	return sRefV1;
}