import { store } from "../../..";
import { AdjustElements, CdPoint, ControlModel, GraphLineData, MsHVCBModel, MsSourceModel, Point, keyable } from "../../../models/Index";
import { MS_MODE_CALCULATION } from "../../../statics";
import { isPointFaultHVCB } from "../../../utils/HVCBFunction";
import { doSetPosCurvePointDirect } from "../DCView";
import { doDrawCurveAdjustAll, doReadDataFromDB } from "../adjust";

const doDrawCurveHVCB = async (props : MsHVCBModel, element : ControlModel, willCallDb : boolean, drawMode:number) => {
    let graphLineData : GraphLineData = { lines: [], }
    let listInfo : any[] = []
    
    if (willCallDb || element.raw === undefined) { // read data from db
        listInfo = await doReadDataFromDB(props as AdjustElements, element, drawMode === 2 ? true : false)
        graphLineData.raw = listInfo
    } else if (element.raw) { // read data from raw (saved in control when first read)
        listInfo = element.raw
    }

    if(drawMode === 0 || drawMode === 1) {
        // draw curve adjust
        let temp = props as any;
        graphLineData = doDrawCurveAdjustAll(props as AdjustElements, listInfo, 50, temp.dispBand??false)
    }
    
    if(drawMode === 0 || drawMode === 2) {
         // get data draw calcpoint
        let mode = store.getState().app.diagram.processMode;
        if (mode >= MS_MODE_CALCULATION && isPointFaultHVCB(props)) {
            let temp = doSetPosCurvePointDirect(props.calcPoint0, 0);
            if(temp !== undefined) {
                graphLineData.lines.push(temp);
            }

            temp = doSetPosCurvePointDirect(props.calcPoint1, 1);
            if(temp !== undefined) {
                graphLineData.lines.push(temp)
            }
        }
    }
    return graphLineData
}

export {
    doDrawCurveHVCB
}