import { Box, Button, Checkbox, FormControl, FormControlLabel, Grid, MenuItem, Radio, RadioGroup, Select, Stack, Tab, Tabs, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as EKind from "../../models/ElementKind";
import { InfoCircuit, MsWireModel } from "../../models/Index";
import { MS_3PHASES, MS_MODE_CHECKING, MS_MODE_DRAWING, MS_OUTSIDE_NEUTRAL, MS_OUTSIDE_OUTSIDE, MS_SEAT_MAIN, MS_SEAT_T, RS_3PHASES, RS_PHASE_N, RS_PHASE_PE, WIRE_AL, WIRE_CU, WIRE_METHOD_A, WIRE_METHOD_B, WIRE_METHOD_C, WIRE_METHOD_D, WIRE_METHOD_E, WIRE_METHOD_F, WIRE_METHOD_G, WIRE_MULTI, WIRE_PVC, WIRE_SINGLE, WIRE_SYMBOL_CABLE, WIRE_SYMBOL_NORMAL, WIRE_XLPE } from "../../statics";
import { BeamsNumber } from "../../utils/FormatNumber";
import { doGetValueDropLoadCurrent } from "../../utils/calcPointFunction";
import { BeamsValueWirePhase, doCalcSetIValues, doCalcSetZValues, doGetLimitSize3Phases, doGetLimitSizePhaseN, doGetLimitSizePhasePE, doGetTextCapcity, doGetTextDropPerUnit, doGetTextDropVoltDiff, doGetTextDropVoltPF, doGetTextLoadCurrent, doGetTextOhmR, doGetTextOhmX, doGetTextVoltage } from "../../utils/wireFunction";
import TextFieldOptions, { OptionModel } from "../common/TextFieldOptions";
import * as Constant from "../../models/Constants";
import { boolean, isNumber } from "mathjs";

export type MsWireEditDialogFormProps = {
    infoCircuit:InfoCircuit;
    data: any;
    onOK: (data: any) => void;
    onCancel: () => void;
    userRole: string;
    m_bModePM: boolean;
    modeViewOnly:boolean;
    dispInputDialog: any;
    processMode: number;
};

const checkboxStyle = {
    padding: '0px 0px 0px 9px',
}
const checkboxStyles = {
    padding: '4px 4px 4px 8px',
}
const radioStyles = {
    padding: '0 0 0 9px',
}

const fieldsetStyle = {
    border: "1px solid #AAAAAA",
    padding:"auto",
}

function a11yProps(index: number) {
    return {
        id: `tab-${index}`,
        "aria-controls": `tabpanel-${index}`,
    };
}

//Tabs------------------
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

const cirCuitOptions = [
    {
        label: "1",
        value: 1,
    },
    {
        label: "2",
        value: 2,
    },
    {
        label: "3",
        value: 3,
    },
    {
        label: "4",
        value: 4,
    },
    {
        label: "5",
        value: 5,
    },
    {
        label: "6",
        value: 6,
    },
    {
        label: "7",
        value: 7,
    },
    {
        label: "8",
        value: 8,
    },
    {
        label: "9",
        value: 9,
    },
]

const numberPhaseList = [
    {
        label: "1",
        value: 1,
    },
    {
        label: "2",
        value: 2,
    },
    {
        label: "3",
        value: 3,
    },
    {
        label: "4",
        value: 4,
    },
    {
        label: "5",
        value: 5,
    },
    {
        label: "6",
        value: 6,
    },
    {
        label: "7",
        value: 7,
    },
    {
        label: "8",
        value: 8,
    },
]

export const MsWireEditDialog = (props: MsWireEditDialogFormProps) => {
    const { m_bModePM, modeViewOnly, userRole, infoCircuit, data, onOK, onCancel,dispInputDialog,processMode } = props;
    // 初期値設定
    const beamsData = (data: any, phase: string) => {
        return {
            ohmRperperM: BeamsNumber(data[phase].ohmRperperM, 4),
            diameterM: BeamsNumber(data[phase].diameterM, 4),
            distanceM: BeamsNumber(data[phase].distanceM, 4),
            ohmXperperM: BeamsNumber(data[phase].ohmXperperM, 4),
            basicCapacityM: BeamsNumber(data[phase].basicCapacityM, 4),
            thickNessM:BeamsNumber(data[phase].thickNessM, 4)
        }
    }
    const initialValue: MsWireModel = {
        ...data,
        _3Phases: {...data._3Phases, ...beamsData(data, "_3Phases")},
        PhaseN: {...data.PhaseN, ...beamsData(data, "PhaseN")},
        PhasePE: {...data.PhasePE, ...beamsData(data, "PhasePE")},
        calcPoint0: {...data.calcPoint0, loadCapacity: BeamsNumber(data.calcPoint0.loadCapacity,7),
            loadCurrent: BeamsNumber(data.calcPoint0.loadCurrent,5),
            powerFactor: BeamsNumber(data.calcPoint0.powerFactor,5)

        },
        calcPoint1: {...data.calcPoint1, loadCapacity: BeamsNumber(data.calcPoint1.loadCapacity,7),
            loadCurrent: BeamsNumber(data.calcPoint1.loadCurrent,5),
            powerFactor: BeamsNumber(data.calcPoint1.powerFactor,5)

        }
    }
    
    const { control, handleSubmit, formState: { errors }, setValue, getValues,clearErrors,setError, } = useForm<MsWireModel>({
        mode: 'all',
        criteriaMode: "all",
        defaultValues: initialValue,
    });
    
    const [selectTab, setSelectTab] = useState(0);
    const [optionCoreSizePhaseN,setOptionCoreSizePhaseN] = useState<any>();
    const [optionCoreSizePhasePE,setOptionCoreSizePhasePE] = useState<any>()
    const [optionNumber,setOptionNumber] = useState<any>(numberPhaseList);
    const [equipWireZList] = useState(data.equip_wireZList);
    const [equip_wireCList] = useState(data.equip_wireCList);
    const [equip_wireIList] = useState(data.equip_wireIList);
    const [isModeSPhases] = useState(data.isModeSPhases);
    const [isOpenTabPhaseN,setOpenTabPhaseN] = useState(data.wireSystem == RS_PHASE_N || data.wireSystem == RS_PHASE_PE);
    const [isOpenTabPhasePE,setOpenTabPhasePE] = useState(data.wireSystem == RS_PHASE_PE);
    const [isSingleCore,setIsSingleCore] = useState<boolean>(data.core == WIRE_SINGLE);
    const [isCheckedDropRegular,setCheckedDropRegular] = useState<boolean>(data.dropRegular);
    const [makeDataInput,setMakeDataInput] = useState<boolean>(getValues("makeDataInput"));
    const [voltText,setIsVoltText] = useState<any>();
    const [limitSize3Phase,setLimitSize3Phase] = useState({limitMin:0,limitMax:0});
    const [disableOK,setIsDisableOKBtn] = useState(false);

    const regexDecimal = /^\d+(\.\d+)?$/;

    // FormデータからRequestデータを作成する
    const createRequestData = (formValue: MsWireModel) => {
        if(!Number(formValue.length)){
            formValue.length = 0
        }
        if(!Number(formValue.ambientTemp)){
            formValue.ambientTemp = 0
        }
        if(!Number(formValue.calcPoint0.loadCurrent)){
            formValue.calcPoint0.loadCurrent = 0
        }
        if(!Number(formValue.calcPoint0.loadCapacity)){
            formValue.calcPoint0.loadCapacity = 0
        }
        if(!Number(formValue.calcPoint0.powerFactor)){
            formValue.calcPoint0.powerFactor = 0
        } 
        if(!Number(formValue.flowSusceptance)){
            formValue.flowSusceptance = 0
        }
        let request = {
            ...formValue
         } as MsWireModel;
         return request;
    }

    // Submitイベント
    const handleEditSubmit = (value: MsWireModel) => {
        const request = createRequestData(value);
        onOK(request);
    }

    const handleCancel = () => {
        onCancel();
    };

    useEffect(()=> {
        initialValueTab1()
        initComboNumber(RS_3PHASES);
        initComboCoreSize(equipWireZList,RS_PHASE_PE);
        initComboCoreSize(equipWireZList,RS_PHASE_N);
        setValue("sPhase",data.isModeSPhases)
    },[])

    useEffect(() => {
        if(isModeSPhases){
            setOpenTabPhaseN(false);
            setOpenTabPhasePE(false);
        }
        else{
            setOpenTabPhaseN(data.wireSystem == RS_PHASE_N || data.wireSystem == RS_PHASE_PE);
            setOpenTabPhasePE(data.wireSystem == RS_PHASE_PE);
        }
    },[isModeSPhases])

    useEffect(()=>{
        let limit = {limitMin:0,limitMax:0}
        doGetLimitSize3Phases(getValues("material"),getValues("core"),limit);
        setLimitSize3Phase({limitMin: limit.limitMin,limitMax: limit.limitMax})
    },[getValues("material")||getValues("core")])

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        const limit = {limitMin:0,limitMax:0}
        doGetLimitSize3Phases(getValues("material"),getValues("core"),limit);
        const object_PhaseN = {limitMin:0, limitMax:0}
        doGetLimitSizePhaseN(getValues("material"),+getValues('_3Phases.coreSize'),object_PhaseN);
        const object_PhasePE = {limitMin:0,limitMax:0}
        doGetLimitSizePhasePE(+getValues('_3Phases.coreSize') * +getValues('_3Phases.number'),object_PhasePE);
        if (newValue >= 0) {
            if (isNaN(Number(getValues('voltage'))) || Number(getValues('voltage')) <= 0 || !/^[0-9\b]+$/.test(getValues('voltage').toString())) {
                setError("voltage",{ type: 'custom', message: '整数 > 0 を入力してください。' });
            }
            else if (isNaN(Number(getValues('length'))) || Number(getValues('length')) < 0) {
                setError("length",{ type: 'custom', message: '数字 >= 0 を入力してください。' });
            }
            else if (isNaN(Number(getValues('ambientTemp'))) || Number(getValues('ambientTemp')) < 0) {
                setError("ambientTemp",{ type: 'custom', message: '数字 >= 0 を入力してください。' });
            }
            else if (isNaN(Number(getValues('calcPoint0.loadCapacity'))) || Number(getValues('calcPoint0.loadCapacity')) < 0) {
                setError("calcPoint0.loadCapacity",{ type: 'custom', message: '数字 >= 0 を入力してください。' });
            }
            else if (isNaN(Number(getValues('calcPoint0.loadCurrent'))) || Number(getValues('calcPoint0.loadCurrent')) < 0) {
                setError("calcPoint0.loadCurrent",{ type: 'custom', message: '数字 >= 0 を入力してください。' });
            }
            else if (isNaN(Number(getValues('calcPoint0.powerFactor'))) || Number(getValues('calcPoint0.powerFactor')) < 0 || Number(getValues('calcPoint0.powerFactor')) > 1) {
                setError("calcPoint0.powerFactor",{ type: 'custom', message: '数字 0 ~ 1 を入力してください。' });
            }
            else if ((isModeSPhases || getValues('downTransKind')) &&(isNaN(Number(getValues('calcPoint1.loadCapacity'))) || Number(getValues('calcPoint1.loadCapacity')) < 0)) {
                setError("calcPoint1.loadCapacity",{ type: 'custom', message: '数字 >= 0 を入力してください。' });
            }
            else if ((isModeSPhases || getValues('downTransKind')) &&(isNaN(Number(getValues('calcPoint1.loadCurrent'))) || Number(getValues('calcPoint1.loadCurrent')) < 0)) {
                setError("calcPoint1.loadCurrent",{ type: 'custom', message: '数字 >= 0 を入力してください。' });
            }
            else if ((isModeSPhases || getValues('downTransKind')) &&(isNaN(Number(getValues('calcPoint1.powerFactor'))) || Number(getValues('calcPoint1.powerFactor')) < 0 || Number(getValues('calcPoint1.powerFactor')) > 1)) {
                setError("calcPoint1.powerFactor",{ type: 'custom', message: '数字 0 ~ 1 を入力してください。' });
            }
            
            else if (selectTab == 2 &&(isNaN(Number(getValues('_3Phases.coreSize'))) || Number(getValues('_3Phases.coreSize')) < limit.limitMin|| Number(getValues('_3Phases.coreSize')) > limit.limitMax)) {
                setError("_3Phases.coreSize",{ type: 'custom', message: limit.limitMin +' <= 数字 <= '+limit.limitMax +' を入力してください。' });
            }
            else if (selectTab == 2 &&(isNaN(Number(getValues('_3Phases.ohmRperperM'))) || Number(getValues('_3Phases.ohmRperperM')) <= 0)) {
                setError("_3Phases.ohmRperperM",{ type: 'custom', message:'数字 > 0 を入力してください。]' });
            }
            else if (selectTab == 2 &&(isNaN(Number(getValues('_3Phases.diameterM'))) || Number(getValues('_3Phases.diameterM')) <= 0)) {
                setError("_3Phases.diameterM",{ type: 'custom', message:'数字 > 0 を入力してください。]' });
            }
            else if (selectTab == 2 &&(isNaN(Number(getValues('_3Phases.thickNessM'))) || Number(getValues('_3Phases.thickNessM')) <= 0)) {
                setError("_3Phases.thickNessM",{ type: 'custom', message:'数字 > 0 を入力してください。]' });
            }
            else if (selectTab == 2 &&(isNaN(Number(getValues('_3Phases.distanceM'))) || Number(getValues('_3Phases.distanceM')) <= 0)) {
                setError("_3Phases.distanceM",{ type: 'custom', message:'数字 > 0 を入力してください。]' });
            }
            else if (selectTab == 2 &&(isNaN(Number(getValues('_3Phases.ohmXperperM'))) || Number(getValues('_3Phases.ohmXperperM')) <= 0)) {
                setError("_3Phases.ohmXperperM",{ type: 'custom', message:'数字 > 0 を入力してください。]' });
            }
            else if (selectTab == 2 &&(isNaN(Number(getValues('_3Phases.basicCapacityM'))) || Number(getValues('_3Phases.basicCapacityM')) <= 0)) {
                setError("_3Phases.basicCapacityM",{ type: 'custom', message:'数字 > 0 を入力してください。]' });
            }
            else if (selectTab == 2 &&(isNaN(Number(getValues('_3Phases.tempk2M'))) || Number(getValues('_3Phases.tempk2M')) <= 0)) {
                setError("_3Phases.tempk2M",{ type: 'custom', message:'数字 > 0 を入力してください。]' });
            }
            else if (selectTab == 2 &&(isNaN(Number(getValues('_3Phases.reductionFactorM'))) || Number(getValues('_3Phases.reductionFactorM')) <= 0)) {
                setError("_3Phases.reductionFactorM",{ type: 'custom', message:'数字 > 0 を入力してください。]' });
            }
            //PhaseN
            else if (isOpenTabPhaseN && selectTab == 3 &&(isNaN(Number(getValues('PhaseN.coreSize'))) || Number(getValues('PhaseN.coreSize')) < object_PhaseN.limitMin )) {
                setError("PhaseN.coreSize",{ type: 'custom', message: object_PhaseN.limitMin +' <= 数字 を入力してください。' });
            }
            else if (isOpenTabPhaseN && selectTab == 3 &&(isNaN(Number(getValues('PhaseN.ohmRperperM'))) || Number(getValues('PhaseN.ohmRperperM')) <= 0)) {
                setError("PhaseN.ohmRperperM",{ type: 'custom', message:'数字 > 0 を入力してください。]' });
            }
            else if (isOpenTabPhaseN && selectTab == 3 &&(isNaN(Number(getValues('PhaseN.diameterM'))) || Number(getValues('PhaseN.diameterM')) <= 0)) {
                setError("PhaseN.diameterM",{ type: 'custom', message:'数字 > 0 を入力してください。]' });
            }
            else if (isOpenTabPhaseN && selectTab == 3 &&(isNaN(Number(getValues('PhaseN.thickNessM'))) || Number(getValues('PhaseN.thickNessM')) <= 0)) {
                setError("PhaseN.thickNessM",{ type: 'custom', message:'数字 > 0 を入力してください。]' });
            }
            else if (isOpenTabPhaseN && selectTab == 3 &&(isNaN(Number(getValues('PhaseN.distanceM'))) || Number(getValues('PhaseN.distanceM')) <= 0)) {
                setError("PhaseN.distanceM",{ type: 'custom', message:'数字 > 0 を入力してください。]' });
            }
            else if (isOpenTabPhaseN && selectTab == 3 &&(isNaN(Number(getValues('PhaseN.ohmXperperM'))) || Number(getValues('PhaseN.ohmXperperM')) <= 0)) {
                setError("PhaseN.ohmXperperM",{ type: 'custom', message:'数字 > 0 を入力してください。]' });
            }
            else if (isOpenTabPhaseN && selectTab == 3 &&(isNaN(Number(getValues('PhaseN.basicCapacityM'))) || Number(getValues('PhaseN.basicCapacityM')) <= 0)) {
                setError("PhaseN.basicCapacityM",{ type: 'custom', message:'数字 > 0 を入力してください。]' });
            }
            else if (isOpenTabPhaseN && selectTab == 3 &&(isNaN(Number(getValues('PhaseN.tempk2M'))) || Number(getValues('PhaseN.tempk2M')) <= 0)) {
                setError("PhaseN.tempk2M",{ type: 'custom', message:'数字 > 0 を入力してください。]' });
            }
            else if (isOpenTabPhaseN && selectTab == 3 &&(isNaN(Number(getValues('PhaseN.reductionFactorM'))) || Number(getValues('PhaseN.reductionFactorM')) <= 0)) {
                setError("PhaseN.reductionFactorM",{ type: 'custom', message:'数字 > 0 を入力してください。]' });
            }

            //PhasePE
            else if (isOpenTabPhasePE && selectTab == 4 &&(isNaN(Number(getValues('PhasePE.coreSize'))) || Number(getValues('PhasePE.coreSize')) < object_PhasePE.limitMin)) {
                setError("PhasePE.coreSize",{ type: 'custom', message: object_PhasePE.limitMin +' <= 数字を入力してください。' });
            }
            else if (isOpenTabPhasePE && selectTab == 4 &&(isNaN(Number(getValues('PhasePE.ohmRperperM'))) || Number(getValues('PhasePE.ohmRperperM')) <= 0)) {
                setError("PhasePE.ohmRperperM",{ type: 'custom', message:'数字 > 0 を入力してください。]' });
            }
            else if (isOpenTabPhasePE && selectTab == 4 &&(isNaN(Number(getValues('PhasePE.diameterM'))) || Number(getValues('PhasePE.diameterM')) <= 0)) {
                setError("PhasePE.diameterM",{ type: 'custom', message:'数字 > 0 を入力してください。]' });
            }
            else if (isOpenTabPhasePE && selectTab == 4 &&(isNaN(Number(getValues('PhasePE.thickNessM'))) || Number(getValues('PhasePE.thickNessM')) <= 0)) {
                setError("PhasePE.thickNessM",{ type: 'custom', message:'数字 > 0 を入力してください。]' });
            }
            else if (isOpenTabPhasePE && selectTab == 4 &&(isNaN(Number(getValues('PhasePE.distanceM'))) || Number(getValues('PhasePE.distanceM')) <= 0)) {
                setError("PhasePE.distanceM",{ type: 'custom', message:'数字 > 0 を入力してください。]' });
            }
            else if (isOpenTabPhasePE && selectTab == 4 &&(isNaN(Number(getValues('PhasePE.ohmXperperM'))) || Number(getValues('PhasePE.ohmXperperM')) <= 0)) {
                setError("PhasePE.ohmXperperM",{ type: 'custom', message:'数字 > 0 を入力してください。]' });
            }
            else if (isOpenTabPhasePE && selectTab == 4 &&(isNaN(Number(getValues('PhasePE.basicCapacityM'))) || Number(getValues('PhasePE.basicCapacityM')) <= 0)) {
                setError("PhasePE.basicCapacityM",{ type: 'custom', message:'数字 > 0 を入力してください。]' });
            }
            else if (isOpenTabPhasePE && selectTab == 4 &&(isNaN(Number(getValues('PhasePE.tempk2M'))) || Number(getValues('PhasePE.tempk2M')) <= 0)) {
                setError("PhasePE.tempk2M",{ type: 'custom', message:'数字 > 0 を入力してください。]' });
            }
            else if (isOpenTabPhasePE && selectTab == 4 &&(isNaN(Number(getValues('PhasePE.reductionFactorM'))) || Number(getValues('PhasePE.reductionFactorM')) <= 0)) {
                setError("PhasePE.reductionFactorM",{ type: 'custom', message:'数字 > 0 を入力してください。]' });
            }
            else{            
                if(newValue == 1){
                    initialValueTab1()
                }
                if(newValue == 2){
                    doSetZValuesWire(RS_3PHASES,"");
                    doSetIValuesWire(RS_3PHASES,"");
                }
                if(isOpenTabPhaseN || isOpenTabPhasePE){
                    if(newValue == 3){
                        doSetZValuesWire(RS_PHASE_N,"");
                        doSetIValuesWire(RS_PHASE_N,"");
                    }
                    if(!isOpenTabPhasePE){
                        if(newValue == 4){
                            doSetValueVoltDrop();
                        }
                    }
                }   

                if(isOpenTabPhasePE){
                    if(newValue == 4){
                        doSetZValuesWire(RS_PHASE_PE,"");
                        doSetIValuesWire(RS_PHASE_PE,"");
                    }
                    if(newValue == 5){
                        doSetValueVoltDrop();
                        
                    }
                }
                if(isModeSPhases){
                    if(newValue == 3){
                        doSetValueVoltDrop();
                    }
                    else if(newValue == 4){
                        doSetValueVoltDrop1();
                    }
                }
            setSelectTab(newValue);

            }
        }
        doSetValueVoltDrop();
        doSetValueVoltDrop1();
    };

    const handleChangeFormat = (e: any) => {
        setValue(e.target.name, e.target.value);
    }

    const initComboCoreSize = (equip_wireZList:any,type:any) => {
        const _3Phases = getValues("_3Phases");
        const object_3Phases = {limitMin:0,limitMax:0}
        doGetLimitSize3Phases(getValues("material"),getValues("core"),object_3Phases);
        if(type == RS_PHASE_N){
            const object_PhaseN = {limitMin:0, limitMax:0}
            doGetLimitSizePhaseN(getValues("material"),_3Phases.coreSize,object_PhaseN);
            const newList = equip_wireZList.filter((item:any)=> item.dcoreSize <= object_3Phases.limitMax && item.dcoreSize >= object_PhaseN.limitMin);
            const sizeCoreList = [];
            if(newList){
                for(const item of newList){
                  sizeCoreList.push({label:item.dcoreSize,value:item.dcoreSize} as OptionModel)
                }
              }
            setOptionCoreSizePhaseN(sizeCoreList);
        }else if(type == RS_PHASE_PE){
            const object_PhasePE = {limitMin:0,limitMax:0}
            doGetLimitSizePhasePE(_3Phases.coreSize * _3Phases.number,object_PhasePE);
            const newList = equipWireZList.filter((item:any)=> item.dcoreSize <= object_3Phases.limitMax && item.dcoreSize >= object_PhasePE.limitMin);
            const sizeCoreList = [];
            if(newList){
                for(const item of newList){
                    sizeCoreList.push({label:item.dcoreSize,value:item.dcoreSize} as OptionModel)
                }
            }
            setOptionCoreSizePhasePE(sizeCoreList);
        }
    }

    const handleChangeWireSystem = (e:any) => {
        if(e.target.value.toString() === RS_PHASE_N.toString()){
            initComboCoreSize(equipWireZList,RS_PHASE_N);
            initComboNumber(RS_3PHASES);
            setOpenTabPhaseN(true);
            setOpenTabPhasePE(false);
        }else if (e.target.value.toString() === RS_PHASE_PE.toString()){
            initComboNumber(RS_3PHASES);
            initComboCoreSize(equipWireZList,RS_PHASE_PE);
            initComboCoreSize(equipWireZList,RS_PHASE_N);
            setOpenTabPhaseN(true);
            setOpenTabPhasePE(true);
        }else if(e.target.value.toString() === "0"){
            setValue("wireSystem",0)
            setOpenTabPhasePE(false);
            setOpenTabPhaseN(false);
        }
        else{
            setOpenTabPhasePE(false);
            setOpenTabPhaseN(false);
        }
    }

    const onChangeMaterial = (e:any) => {
        reSetValue();
    }

    const reSetValue = () => {
        const _3Phases = getValues("_3Phases");
        const PhaseN = getValues("PhaseN");
        const PhasePE = getValues("PhasePE");
        BeamsValueWirePhase(_3Phases);
        BeamsValueWirePhase(PhaseN);
        BeamsValueWirePhase(PhasePE);
        doCalcSetZValues(infoCircuit.frequency,getValues(),_3Phases,0,0,0,equipWireZList);
        doCalcSetIValues(getValues(),_3Phases.coreSize,_3Phases,_3Phases.number,equip_wireIList,equip_wireCList);
        doCalcSetZValues(infoCircuit.frequency,getValues(),PhaseN,0,0,0,equipWireZList);
        doCalcSetIValues(getValues(),PhaseN.coreSize,PhaseN,PhaseN.number,equip_wireIList,equip_wireCList);
        doCalcSetZValues(infoCircuit.frequency,getValues(),PhasePE,0,0,0,equipWireZList);
        doCalcSetIValues(getValues(),PhasePE.coreSize,PhasePE,PhasePE.number,equip_wireIList,equip_wireCList);
        doSetIValuesWire(RS_3PHASES,"RS_3PHASES")
        BeamsValueWirePhase(_3Phases);
        BeamsValueWirePhase(PhaseN);
        BeamsValueWirePhase(PhasePE);
        setValue("_3Phases",_3Phases);
        setValue("PhaseN",PhaseN);
        setValue("PhasePE",PhasePE);
    }

    const onChangeInsulationPVC = (e:any) => {
        const _3Phases = getValues("_3Phases");
        const PhaseN = getValues("PhaseN");
        const PhasePE = getValues("PhasePE");
        doCalcSetIValues(getValues(),_3Phases.coreSize,_3Phases,_3Phases.number,equip_wireIList,equip_wireCList);
        doCalcSetIValues(getValues(),PhaseN.coreSize,PhaseN,PhaseN.number,equip_wireIList,equip_wireCList);
        doCalcSetIValues(getValues(),PhasePE.coreSize,PhasePE,PhasePE.number,equip_wireIList,equip_wireCList);
        doSetIValuesWire(RS_3PHASES,e.target.name)
        BeamsValueWirePhase(_3Phases);
        BeamsValueWirePhase(PhaseN);
        BeamsValueWirePhase(PhasePE);
        setValue("_3Phases",_3Phases);
        setValue("PhaseN",PhaseN);
        setValue("PhasePE",PhasePE);
    }

    const handleChangeAmbientTemp = (e:any) => {
        reSetValue();
    }

    const handleChangeCore = (e:any) => {
        if(e.target.value == WIRE_SINGLE.toString()){
            setValue("method",WIRE_METHOD_F);
            setIsSingleCore(true);
        }else if(e.target.value == WIRE_MULTI.toString()){
            setValue("method",WIRE_METHOD_E);
            setIsSingleCore(false);
        }
        reSetValue();
    }

    const handleChangeMethod = (e:any)=> {
        reSetValue();
    }

    const handleSelectCircuitNumber = (e:any) => {
        reSetValue();
    }

    const handleRefreshValue = (e:any) => {
        if(!isNaN(e.target.value) && e.target.value > 0){
            const limit = {limitMin:0,limitMax:0}
            doGetLimitSize3Phases(getValues("material"),getValues("core"),limit);
            if(e.target.value >= limit.limitMin && e.target.value <= limit.limitMin){
                // setIsDisableOKBtn(false)
            }else{
                // setIsDisableOKBtn(true)
            }
            const _3Phases = getValues("_3Phases");
            const PhaseN = getValues("PhaseN");
            const PhasePE = getValues("PhasePE");
            _3Phases.ohmRperperM = _3Phases.ohmRperperA;
            _3Phases.diameterM = _3Phases.diameterA;
            _3Phases.thickNessM = _3Phases.thickNessA;
            _3Phases.distanceM = _3Phases.distanceA;
            PhaseN.ohmRperperM = PhaseN.ohmRperperA;
            PhaseN.diameterM = PhaseN.diameterA;
            PhaseN.thickNessM = PhaseN.thickNessA;
            PhaseN.distanceM = PhaseN.distanceA;
            PhasePE.ohmRperperM = PhasePE.ohmRperperA;
            PhasePE.diameterM = PhasePE.diameterA;
            PhasePE.thickNessM = PhasePE.thickNessA;
            PhasePE.distanceM = PhasePE.distanceA;
            BeamsValueWirePhase(_3Phases);
            doCalcSetZValues(infoCircuit.frequency,getValues(),_3Phases,0,0,0,equipWireZList);
            doCalcSetIValues(getValues(),_3Phases.coreSize,_3Phases,_3Phases.number,equip_wireIList,equip_wireCList);
            PhaseN.coreSize = _3Phases.coreSize;
            PhasePE.coreSize = _3Phases.coreSize;
            doCalcSetZValues(infoCircuit.frequency,getValues(),PhaseN,0,0,0,equipWireZList);
            doCalcSetIValues(getValues(),PhaseN.coreSize,PhaseN,PhaseN.number,equip_wireIList,equip_wireCList);
            doCalcSetZValues(infoCircuit.frequency,getValues(),PhasePE,0,0,0,equipWireZList);
            doCalcSetIValues(getValues(),PhasePE.coreSize,PhasePE,PhasePE.number,equip_wireIList,equip_wireCList);
            doSetIValuesWire(RS_3PHASES,e.target.name);
            doSetZValuesWire(RS_3PHASES,e.target.name);
            doSetIValuesWire(RS_PHASE_N,e.target.name);
            doSetZValuesWire(RS_PHASE_N,e.target.name);
            doSetIValuesWire(RS_PHASE_PE,e.target.name);
            doSetZValuesWire(RS_PHASE_PE,e.target.name);
            BeamsValueWirePhase(_3Phases);
            BeamsValueWirePhase(PhaseN);
            BeamsValueWirePhase(PhasePE);
            setValue("_3Phases",_3Phases);
            setValue("PhaseN",PhaseN);
            setValue("PhasePE",PhasePE);
            initComboCoreSize(equipWireZList,RS_PHASE_N);
            initComboCoreSize(equipWireZList,RS_PHASE_PE);
            initComboNumber(RS_3PHASES);
        }
        handleClearErrors()
    }

    const initComboNumber = (type:any) => {
        const _3Phases = getValues("_3Phases");
        if(type == RS_3PHASES|| type== RS_PHASE_N){
            const PhaseN = getValues("PhaseN");
            let number = 4;
            let remain = 0;
            if(PhaseN.coreSize > 0) {
                number = parseInt((_3Phases.coreSize * _3Phases.number / 2 / PhaseN.coreSize).toString());
                const x = (_3Phases.coreSize * _3Phases.number / 2 % parseInt(PhaseN.coreSize.toString()));
                remain = parseInt(x.toString());
                if(number > 4){
                    number = 4;
                }
                if(remain > 0){
                    number++;
                }
            }
            let retNumber = number;
            const options:any = [];
            for(let i=number;i<=4;i++)
            {
                options.push({label:i.toString(),value:i} as OptionModel)
                if(PhaseN.number == i){
                    retNumber = PhaseN.number;
                }
            }
            setOptionNumber(options);
            return retNumber;
        }else {
            const PhasePE = getValues("PhasePE");
            let number = 4;
            let remain = 0;
            if(PhasePE.coreSize >0) {
                number = parseInt((_3Phases.coreSize * _3Phases.number / 2 / PhasePE.coreSize).toString());
                const x = (_3Phases.coreSize * _3Phases.number / 2 % parseInt(PhasePE.coreSize.toString()));
                remain = parseInt(x.toString());
                if(number > 4){
                    number = 4;
                }
                if(remain > 0){
                    number++;
                }
            }
            let retNumber = number;
            const options:any = [];
            for(let i=number;i<=4;i++)
            {
                options.push({label:i.toString(),value:i} as OptionModel)
                if(PhasePE.number == i){
                    retNumber = PhasePE.number;
                }
            }
            setOptionNumber(options);
            return retNumber;
        }
        
    }

    const doSetZValuesWire = (wireSystem:any,name:any) => {
        switch(wireSystem){
            case RS_3PHASES:
                setValue("ohmX_3Phases",Number(doGetTextOhmX(getValues(), RS_3PHASES, false)));
                setValue("ohmR_3Phases",Number(doGetTextOhmR(getValues(), RS_3PHASES, false)));
                break;
            case RS_PHASE_N:
                setValue("ohmX_PhaseN",Number(doGetTextOhmX(getValues(), RS_PHASE_N, false)));
                setValue("ohmR_PhaseN",Number(doGetTextOhmR(getValues(), RS_PHASE_N, false)));
                break;
            case RS_PHASE_PE:
                setValue("ohmX_PhasePE",Number(doGetTextOhmX(getValues(), RS_PHASE_PE, false)));
                setValue("ohmR_PhasePE",Number(doGetTextOhmR(getValues(), RS_PHASE_PE, false)));
                break;
        }
    }

    const doSetIValuesWire = (wireSystem:any,name:any) => {
        switch(wireSystem){
            case RS_3PHASES:
                const _3Phases = getValues("_3Phases");
                setValue("capacity_3Phases",doGetTextCapcity(_3Phases));
                break;
            case RS_PHASE_N:
                const PhaseN = getValues("PhaseN");
                setValue("capacity_PhaseN",doGetTextCapcity(PhaseN));
                break;
            case RS_PHASE_PE:
                const PhasePE = getValues("PhasePE");
                setValue("capacity_PhasePE",doGetTextCapcity(PhasePE));
                break;
        }
    }

    const handleChangeDiameter = (e:any,type?:any) => {
        setValue(e.target.name,e.target.value)
        if(e.target.value > 0){
            clearErrors(e.target.name)
        }
        if(regexDecimal.test(e.target.value) && e.target.value > 0){
            const _3Phases = getValues("_3Phases");
            const PhaseN = getValues("PhaseN");
            const PhasePE = getValues("PhasePE");
            if(type){
                switch(type){
                    case RS_3PHASES:
                        _3Phases.diameterM = Number(e.target.value);
                        setValue("_3Phases.diameterM",Number(e.target.value));
                        BeamsValueWirePhase(_3Phases);
                        doCalcSetZValues(infoCircuit.frequency,getValues(),_3Phases,0,Number(e.target.value),0,equipWireZList)
                        PhaseN.diameterM = Number(e.target.value);
                        PhasePE.diameterM = Number(e.target.value);
                        doCalcSetZValues(infoCircuit.frequency,getValues(),PhaseN,0,Number(e.target.value),0,equipWireZList);
                        doCalcSetZValues(infoCircuit.frequency,getValues(),PhasePE,0,Number(e.target.value),0,equipWireZList); 
                        BeamsValueWirePhase(_3Phases);
                        BeamsValueWirePhase(PhaseN);
                        BeamsValueWirePhase(PhasePE);
                        setValue("_3Phases",_3Phases);
                        setValue("PhaseN",PhaseN);
                        setValue("PhasePE",PhasePE);
                        doSetZValuesWire(RS_3PHASES,e.target.name);
                        doSetZValuesWire(RS_PHASE_N,e.target.name);
                        doSetZValuesWire(RS_PHASE_PE,e.target.name);
                        break;
                    case RS_PHASE_N:
                        PhaseN.diameterM = Number(e.target.value);
                        setValue("PhaseN.diameterM",Number(e.target.value));
                        BeamsValueWirePhase(PhaseN);
                        doCalcSetZValues(infoCircuit.frequency,getValues(),PhaseN,0,Number(e.target.value),0,equipWireZList);
                        BeamsValueWirePhase(PhaseN);
                        setValue("PhaseN",PhaseN);
                        doSetZValuesWire(RS_PHASE_N,e.target.name);
                        break;
                    case RS_PHASE_PE:
                        PhasePE.diameterM = Number(e.target.value);
                        setValue("PhasePE.diameterM",Number(e.target.value));
                        BeamsValueWirePhase(PhasePE);
                        doCalcSetZValues(infoCircuit.frequency,getValues(),PhasePE,0,Number(e.target.value),0,equipWireZList);
                        BeamsValueWirePhase(PhasePE);
                        setValue("PhasePE",PhasePE);
                        doSetZValuesWire(RS_PHASE_PE,e.target.name);
                        break;
                }
            }else{
                
            }
        }
    };

    const handleChangeThickNess = (e:any,type:any) => {
        setValue(e.target.name,e.target.value)
        if(e.target.value > 0){
            clearErrors(e.target.name)
        }
        if(regexDecimal.test(e.target.value) && e.target.value > 0){
            const _3Phases = getValues("_3Phases");
            const PhaseN = getValues("PhaseN");
            const PhasePE = getValues("PhasePE");
            switch(type){
                case RS_3PHASES:
                    _3Phases.thickNessM = Number(e.target.value);
                    setValue("_3Phases.thickNessM",Number(e.target.value));
                    BeamsValueWirePhase(_3Phases);
                    doCalcSetZValues(infoCircuit.frequency,getValues(),_3Phases,Number(e.target.value),0,0,equipWireZList);
                    PhaseN.thickNessM = Number(e.target.value);
                    PhasePE.thickNessM = Number(e.target.value);
                    BeamsValueWirePhase(PhaseN);
                    BeamsValueWirePhase(PhasePE);
                    doCalcSetZValues(infoCircuit.frequency,getValues(),PhaseN,Number(e.target.value),0,0,equipWireZList);
                    doCalcSetZValues(infoCircuit.frequency,getValues(),PhasePE,Number(e.target.value),0,0,equipWireZList); 
                    BeamsValueWirePhase(_3Phases);
                    BeamsValueWirePhase(PhaseN);
                    BeamsValueWirePhase(PhasePE);
                    setValue("_3Phases",_3Phases);
                    setValue("PhaseN",PhaseN);
                    setValue("PhasePE",PhasePE);
                    doSetZValuesWire(RS_PHASE_N,e.target.name);
                    doSetZValuesWire(RS_3PHASES,e.target.name);
                    doSetZValuesWire(RS_PHASE_PE,e.target.name);
                    break;
                case RS_PHASE_N:
                    PhaseN.thickNessM = Number(e.target.value);
                    BeamsValueWirePhase(PhaseN);
                    doCalcSetZValues(infoCircuit.frequency,getValues(),PhaseN,Number(e.target.value),0,0,equipWireZList);
                    doSetZValuesWire(RS_PHASE_N,e.target.name);
                    BeamsValueWirePhase(PhaseN);
                    setValue("PhaseN",PhaseN);
                    break;
                case RS_PHASE_PE:
                    PhasePE.thickNessM = Number(e.target.value);
                    BeamsValueWirePhase(PhasePE);
                    doCalcSetZValues(infoCircuit.frequency,getValues(),PhasePE,Number(e.target.value),0,0,equipWireZList);
                    setValue("ohmX_PhasePE",Number(doGetTextOhmX(getValues(), RS_PHASE_PE, false)))
                    doSetZValuesWire(RS_PHASE_PE,e.target.name);
                    BeamsValueWirePhase(PhasePE);
                    setValue("PhasePE",PhasePE);
                    break;
            }
            
        }
    }
    const handleClearErrors = () =>{
        if(+getValues('_3Phases.ohmRperperM') > 0){
            clearErrors('_3Phases.ohmRperperM')
        }
        if(+getValues('_3Phases.diameterM')> 0){
            clearErrors('_3Phases.diameterM')
        }
        if(+getValues('_3Phases.thickNessM')> 0){
            clearErrors('_3Phases.thickNessM')
        }
        if(+getValues('_3Phases.distanceM')> 0){
            clearErrors('_3Phases.distanceM')
        }
        if(+getValues('_3Phases.ohmXperperM')> 0){
            clearErrors('_3Phases.ohmXperperM')
        }
        if(+getValues('_3Phases.basicCapacityM')> 0){
            clearErrors('_3Phases.basicCapacityM')
        }
        if(+getValues('_3Phases.tempk2M') > 0){
            clearErrors('_3Phases.tempk2M')
        }
        if(+getValues('_3Phases.reductionFactorM') > 0){
            clearErrors('_3Phases.reductionFactorM')
        }
        if(isOpenTabPhaseN){
            if(+getValues('PhaseN.ohmRperperM') > 0){
                clearErrors('PhaseN.ohmRperperM')
            }
            if(+getValues('PhaseN.diameterM')> 0){
                clearErrors('PhaseN.diameterM')
            }
            if(+getValues('PhaseN.thickNessM')> 0){
                clearErrors('PhaseN.thickNessM')
            }
            if(+getValues('PhaseN.distanceM')> 0){
                clearErrors('PhaseN.distanceM')
            }
            if(+getValues('PhaseN.ohmXperperM')> 0){
                clearErrors('PhaseN.ohmXperperM')
            }
            if(+getValues('PhaseN.basicCapacityM')> 0){
                clearErrors('PhaseN.basicCapacityM')
            }
            if(+getValues('PhaseN.tempk2M') > 0){
                clearErrors('PhaseN.tempk2M')
            }
            if(+getValues('PhaseN.reductionFactorM') > 0){
                clearErrors('PhaseN.reductionFactorM')
            }
        }
        if(isOpenTabPhasePE){
            if(+getValues('PhasePE.ohmRperperM') > 0){
                clearErrors('PhasePE.ohmRperperM')
            }
            if(+getValues('PhasePE.diameterM')> 0){
                clearErrors('PhasePE.diameterM')
            }
            if(+getValues('PhasePE.thickNessM')> 0){
                clearErrors('PhasePE.thickNessM')
            }
            if(+getValues('PhasePE.distanceM')> 0){
                clearErrors('PhasePE.distanceM')
            }
            if(+getValues('PhasePE.ohmXperperM')> 0){
                clearErrors('PhasePE.ohmXperperM')
            }
            if(+getValues('PhasePE.basicCapacityM')> 0){
                clearErrors('PhasePE.basicCapacityM')
            }
            if(+getValues('PhasePE.tempk2M') > 0){
                clearErrors('PhasePE.tempk2M')
            }
            if(+getValues('PhasePE.reductionFactorM') > 0){
                clearErrors('PhasePE.reductionFactorM')
            }
        }

    }
    const handleChangeDistanceM = (e:any,type:any) => {
        setValue(e.target.name,e.target.value)
        if(e.target.value > 0){
            clearErrors(e.target.name)
        }
        if(regexDecimal.test(e.target.value) && e.target.value > 0){
            const _3Phases = getValues("_3Phases");
            const PhaseN = getValues("PhaseN");
            const PhasePE = getValues("PhasePE");
            switch(type){
                case RS_3PHASES:
                    _3Phases.distanceM = Number(e.target.value);
                    setValue("_3Phases.distanceM",Number(e.target.value));
                    // BeamsValueWirePhase(_3Phases);
                    doCalcSetZValues(infoCircuit.frequency,getValues(),_3Phases,0,0,Number(e.target.value),equipWireZList);
                    PhaseN.distanceM = Number(e.target.value);
                    PhasePE.distanceM = Number(e.target.value);
                    BeamsValueWirePhase(PhaseN);
                    BeamsValueWirePhase(PhasePE);
                    doCalcSetZValues(infoCircuit.frequency,getValues(),PhaseN,0,0,Number(e.target.value),equipWireZList);
                    doCalcSetZValues(infoCircuit.frequency,getValues(),PhasePE,0,0,Number(e.target.value),equipWireZList); 
                    BeamsValueWirePhase(_3Phases);
                    BeamsValueWirePhase(PhaseN);
                    BeamsValueWirePhase(PhasePE);
                    setValue("_3Phases",_3Phases);
                    setValue("PhaseN",PhaseN);
                    setValue("PhasePE",PhasePE);
                    doSetZValuesWire(RS_3PHASES,e.target.value);
                    break;
                case RS_PHASE_N:
                    PhaseN.distanceM = Number(e.target.value);
                    setValue("PhaseN.distanceM",Number(e.target.value));
                    BeamsValueWirePhase(PhaseN);
                    doCalcSetZValues(infoCircuit.frequency,getValues(),PhaseN,0,0,Number(e.target.value),equipWireZList);
                    doSetZValuesWire(RS_PHASE_N,e.target.name);
                    BeamsValueWirePhase(PhaseN);
                    setValue("PhaseN",PhaseN);
                    break;
                case RS_PHASE_PE:
                    PhasePE.distanceM = Number(e.target.value);
                    setValue("PhasePE.distanceM",Number(e.target.value));
                    BeamsValueWirePhase(PhasePE);
                    doCalcSetZValues(infoCircuit.frequency,getValues(),PhasePE,0,0,Number(e.target.value),equipWireZList);
                    doSetZValuesWire(RS_PHASE_PE,e.target.name);
                    BeamsValueWirePhase(PhasePE);
                    setValue("PhasePE",PhasePE);
                    break;
            }
            
        }
        handleClearErrors()
    }

    const handleChangeOhmX = (e:any,type:any)=> {
        setValue(e.target.name,e.target.value)
        if(e.target.value > 0){
            clearErrors(e.target.name)
        }
        if(regexDecimal.test(e.target.value) && e.target.value > 0){
            switch(type){
                case RS_3PHASES:
                    setValue("_3Phases.ohmXperperM",Number(e.target.value));
                    setValue("PhaseN.ohmXperperM",Number(e.target.value));
                    setValue("PhasePE.ohmXperperM",Number(e.target.value));
                    doSetZValuesWire(RS_3PHASES,e.target.name);
                    break;
                case RS_PHASE_N:
                    setValue("PhaseN.ohmXperperM",Number(e.target.value));
                    doSetZValuesWire(RS_PHASE_N,e.target.name);
                    break;
                case RS_PHASE_PE:
                    setValue("PhasePE.ohmXperperM",Number(e.target.value));
                    doSetZValuesWire(RS_PHASE_PE,e.target.name);
                    break;
            }
        }
    }

    const handleChangeBasicCapacity = (e:any,type:any) => {
        setValue(e.target.name,e.target.value)
        if(e.target.value > 0){
            clearErrors(e.target.name)
        }
        if(regexDecimal.test(e.target.value) && e.target.value > 0){
            switch(type){
                case RS_3PHASES:  
                    setValue("_3Phases.basicCapacityM",Number(e.target.value));
                    setValue("PhaseN.basicCapacityM",Number(e.target.value));
                    setValue("PhasePE.basicCapacityM",Number(e.target.value));
                    doSetIValuesWire(RS_3PHASES,e.target.name)
                    break;
                case RS_PHASE_N:
                    setValue("PhaseN.basicCapacityM",Number(e.target.value));   
                    doSetIValuesWire(RS_PHASE_N,e.target.name)
                    break;
                case RS_PHASE_PE:
                    setValue("PhasePE.basicCapacityM",Number(e.target.value));   
                    doSetIValuesWire(RS_PHASE_PE,e.target.name)
                    break;
            }
        }
    }

    const handleChangeTempk2M = (e:any,type:any) => {
         setValue(e.target.name,e.target.value)
        if(e.target.value > 0){
            clearErrors(e.target.name)
        }
        if(regexDecimal.test(e.target.value) && e.target.value > 0)
        {
            switch(type){
                case RS_3PHASES:
                    setValue("_3Phases.tempk2M",Number(e.target.value));
                    setValue("PhaseN.tempk2M",Number(e.target.value));
                    setValue("PhasePE.tempk2M",Number(e.target.value));
                    doSetIValuesWire(RS_3PHASES,e.target.name)
                    break;
                case RS_PHASE_N:
                    setValue("PhaseN.tempk2M",Number(e.target.value));
                    doSetIValuesWire(RS_PHASE_N,e.target.name)
                    break;
                case RS_PHASE_PE:
                    setValue("PhasePE.tempk2M",Number(e.target.value));
                    doSetIValuesWire(RS_PHASE_PE,e.target.name)
                    break;
            }  
        }   
    }

    const handleChangeReductionFactorM = (e:any,type:any) => {
        setValue(e.target.name,e.target.value)
        if(e.target.value > 0){
            clearErrors(e.target.name)
        }
        if(regexDecimal.test(e.target.value) && e.target.value > 0){
            switch(type){
                case RS_3PHASES:
                    setValue("_3Phases.reductionFactorM",Number(e.target.value));
                    setValue("PhasePE.reductionFactorM",Number(e.target.value));
                    setValue("PhaseN.reductionFactorM",Number(e.target.value));
                    doSetIValuesWire(RS_3PHASES,e.target.name)
                    break;
                case RS_PHASE_N:
                    setValue("PhaseN.reductionFactorM",Number(e.target.value));
                    doSetIValuesWire(RS_PHASE_N,e.target.name)
                    break;
                case RS_PHASE_PE:
                    setValue("PhasePE.reductionFactorM",Number(e.target.value));
                    doSetIValuesWire(RS_PHASE_PE,e.target.name)
                    break;
            }
        }
    }

    const handleEditCoreSize = (e:any,type:any) => {
        if(regexDecimal.test(e.target.value) && e.target.value > 0){
            if(type === RS_PHASE_N){
                const limit = {limitMin:0,limitMax:0};
                doGetLimitSizePhaseN(RS_PHASE_N,getValues("PhaseN.coreSize"),limit);
                setValue("PhaseN.coreSize",Number(e.target.value));
                const phaseN = getValues("PhaseN");
                phaseN.ohmRperperM = phaseN.ohmRperperA;
                phaseN.diameterM = phaseN.diameterA;
                phaseN.thickNessM = phaseN.thickNessA;
                phaseN.distanceM = phaseN.distanceA;
                doCalcSetZValues(infoCircuit.frequency,getValues(),phaseN,0,0,0,equipWireZList);
                doCalcSetIValues(getValues(),phaseN.coreSize,phaseN,phaseN.number,equip_wireIList,equip_wireCList);
                BeamsValueWirePhase(phaseN);
                setValue("PhaseN",phaseN);
                setValue("PhaseN.number",initComboNumber(RS_PHASE_N));
                doSetZValuesWire(RS_PHASE_N,e.target.name);
                doSetIValuesWire(RS_PHASE_N,e.target.name);
            }else if(type == RS_PHASE_PE){
                const limit = {limitMin:0,limitMax:0};
                doGetLimitSizePhaseN(getValues("material"),getValues("PhasePE.coreSize"),limit);
                setValue("PhasePE.coreSize",Number(e.target.value));
                const PhasePE = getValues("PhasePE");
                PhasePE.ohmRperperM = PhasePE.ohmRperperA;
                PhasePE.diameterM = PhasePE.diameterA;
                PhasePE.thickNessM = PhasePE.thickNessA;
                PhasePE.distanceM = PhasePE.distanceA;
                doCalcSetZValues(infoCircuit.frequency,getValues(),PhasePE,0,0,0,equipWireZList);
                doCalcSetIValues(getValues(),PhasePE.coreSize,PhasePE,PhasePE.number,equip_wireIList,equip_wireCList);
                BeamsValueWirePhase(PhasePE);
                setValue("PhasePE",PhasePE);
                doSetZValuesWire(RS_PHASE_PE,e.target.name);
                doSetIValuesWire(RS_PHASE_PE,e.target.name);
            }
        }
        handleClearErrors()
    }

    const handleChangeNumber = (e:any,type:any)=> {
        if(type === RS_PHASE_N){
            const PhaseN = getValues("PhaseN");
            doCalcSetZValues(infoCircuit.frequency,getValues(),PhaseN,0,0,0,equipWireZList);
            doCalcSetIValues(getValues(),PhaseN.coreSize,PhaseN,PhaseN.number,equip_wireIList,equip_wireCList);
            BeamsValueWirePhase(PhaseN);
            setValue("PhaseN",PhaseN);
            doSetZValuesWire(RS_PHASE_N,e.target.name);            
            doSetIValuesWire(RS_PHASE_N,e.target.name);
        }
    }

    const handleChangeOhmRperper = (e:any,type:any) => {
        if(regexDecimal.test(e.target.value) && e.target.value > 0){
            switch(type){
                case RS_3PHASES:
                    const PhaseN = getValues("PhaseN");
                    const PhasePE = getValues("PhasePE");
                    PhasePE.ohmRperperM = Number(e.target.value);
                    PhaseN.ohmRperperM = Number(e.target.value);
                    BeamsValueWirePhase(PhaseN);
                    BeamsValueWirePhase(PhasePE);
                    setValue("PhaseN",PhaseN);
                    setValue("PhasePE",PhasePE);
                    doSetZValuesWire(RS_3PHASES,e.target.name);
                    break;
                case RS_PHASE_N:
                    setValue("PhaseN.ohmRperperM",Number(e.target.value));
                    doSetZValuesWire(RS_PHASE_N,e.target.name);
                    break;
                case RS_PHASE_PE:
                    setValue("PhasePE.ohmRperperM",Number(e.target.value));
                    doSetZValuesWire(RS_PHASE_PE,e.target.name);
                    break;
            }
        }
    }

    const handleChangeLoadCapacity = (e:any,key:string) => {
		
        if(key === "calcPoint0"){
            setValue("inputedCapacity1",true);
            setValue("inputedCurrent1",false);
            
            setValue(`calcPoint0.loadCapacity`,e.target.value);
            const loadCurrent = BeamsNumber(doGetValueDropLoadCurrent(getValues("voltage"),getValues(`calcPoint0.loadCapacity`),getValues(`calcPoint0.calcKind`)),7)
            setValue(`calcPoint0.loadCurrent`,loadCurrent);
            doSetValueVoltDrop();
            if(+getValues('calcPoint0.loadCurrent') >= 0){
                clearErrors('calcPoint0.loadCurrent')
            }
        }
        else if(key === "calcPoint1"){
            setValue("inputedCapacity2",true);
            setValue("inputedCurrent2",false);
            setValue(`calcPoint1.loadCapacity`,e.target.value);
            const loadCurrent = BeamsNumber(doGetValueDropLoadCurrent(getValues("voltage"),getValues(`calcPoint1.loadCapacity`),getValues(`calcPoint1.calcKind`)),7)
            setValue(`calcPoint1.loadCurrent`,loadCurrent);
            doSetValueVoltDrop1();
            if(+getValues('calcPoint1.loadCurrent') >= 0){
                clearErrors('calcPoint1.loadCurrent')
            }
        }
        
    }
    const handleChangeLoadCurrent=(e:any,key:string) =>{
        if(key === "calcPoint0"){
            setValue("inputedCurrent1",true)
            setValue("inputedCapacity1",false)
            setValue(`calcPoint0.loadCurrent`,e.target.value);
            const loadCapacity = BeamsNumber(doSetValueDropLoadCapcity(getValues(`calcPoint0.calcKind`),Number(e.target.value),getValues("voltage")),7)
            setValue(`calcPoint0.loadCapacity`,loadCapacity);
            doSetValueVoltDrop();
            if(+getValues('calcPoint0.loadCapacity') >= 0){
                clearErrors('calcPoint0.loadCapacity')
            }
        }
        else if(key === "calcPoint1"){
            setValue("inputedCurrent2",true)
            setValue("inputedCapacity2",false)
            let  loadCapacity= 0
            setValue(`calcPoint1.loadCurrent`,e.target.value);
            if(getValues("sPhase") && getValues("upTransKind") == Constant.MS_TRANS1){
                loadCapacity = BeamsNumber(doSetValueDropLoadCapcity(getValues(`calcPoint1.calcKind`),Number(e.target.value),getValues("voltage")/2),7)
            }
            else{
                loadCapacity = BeamsNumber(doSetValueDropLoadCapcity(getValues(`calcPoint1.calcKind`),Number(e.target.value),getValues("voltage")),7)
            }
            setValue(`calcPoint1.loadCapacity`,loadCapacity);
            doSetValueVoltDrop1();
            if(+getValues('calcPoint1.loadCapacity') >= 0){
                clearErrors('calcPoint1.loadCapacity')
            }
        }
    }
	

    const doSetValueVoltDrop = () => {
        const wire = getValues();
        if(wire.sPhase == true){
            switch(wire.upTransKind){
                case EKind.MS_ELEMENT_ALL1.MS_TRANS1:
                    setValue("voltageDrop1",Number(doGetTextDropVoltDiff(getValues(), infoCircuit.stdCapacity, +getValues("voltage"),MS_OUTSIDE_OUTSIDE, false)));
                    setValue("voltageDrop",doGetTextDropPerUnit(infoCircuit.stdCapacity,MS_OUTSIDE_OUTSIDE,wire));
                    break;
                case EKind.MS_ELEMENT_ALL1.MS_TRANSSCOTT:
                    setValue("voltageDrop1",Number(doGetTextDropVoltDiff(getValues(), infoCircuit.stdCapacity,+getValues("voltage"),MS_SEAT_T, false)));
                    setValue("voltageDrop",doGetTextDropPerUnit(infoCircuit.stdCapacity,MS_SEAT_T,wire));
                    break;
            }
        }
        else{
            setValue("voltageDrop1",Number(doGetTextDropVoltDiff(getValues(), infoCircuit.stdCapacity,+getValues("voltage"),MS_3PHASES, false)));
            setValue("voltageDrop",doGetTextDropPerUnit(infoCircuit.stdCapacity,MS_3PHASES,wire));
        }
    }
    const doSetValueDropLoadCapcity = (calc_kind:number,load_current:number,voltage:number) =>{
        let load_capacity = voltage * load_current
        if(calc_kind == Constant.CALC_IK3){
            load_capacity *= Math.sqrt(3.0);
        }
        return load_capacity
    }
    const doGetTextDropLoadCapacity = (load_capacity:number,bUnit:boolean)=>{
        let text = BeamsNumber(load_capacity,7).toString();
        if(bUnit){
            text = text + "A";
        }
        return text
    }
    const doGetTextLoadCurrent = (props:MsWireModel, nPartKind:number) => {
        let sText = 0;
        if(props.sPhase) {
            switch(nPartKind) {
                case MS_OUTSIDE_NEUTRAL:
                case MS_SEAT_MAIN:
                    sText = props.calcPoint1.loadCurrent;
                    break;
                case MS_OUTSIDE_OUTSIDE:
                case MS_SEAT_T:
                    sText = props.calcPoint0.loadCurrent;
                    break;
            }
        }
        else {
            if(props.downTransKind == Constant.MS_TRANS1) {
                sText = props.calcPoint1.loadCurrent;
            }
            else {
                sText = props.calcPoint0.loadCurrent;
            }
        }
        return sText;
    }
    const handleClearLoadValue = (key:string) => {
        const wire = getValues();
        if(key === "calcPoint0"){
            clearErrors("calcPoint0.loadCapacity")
            clearErrors("calcPoint0.loadCurrent")
            let m_cLoadCurrent = 0;
            setValue("inputedCapacity1",false);
            setValue("inputedCurrent1",false);
            setValue("calcPoint0.loadCurrent",dispInputDialog.load_current_0);
            if(getValues("sPhase")){
                if(getValues("upTransKind") == EKind.MS_ELEMENT_ALL1.MS_TRANS1){
                    m_cLoadCurrent = doGetTextLoadCurrent(wire,MS_OUTSIDE_OUTSIDE);
                }
                else if(getValues("upTransKind") == EKind.MS_ELEMENT_ALL1.MS_TRANSSCOTT){
                    m_cLoadCurrent = doGetTextLoadCurrent(wire,MS_SEAT_T);
                }
            }
            else{
                m_cLoadCurrent = doGetTextLoadCurrent(wire,MS_3PHASES);
            }
            setValue("calcPoint0.loadCurrent",BeamsNumber(m_cLoadCurrent,5));
            let load_capacity = doSetValueDropLoadCapcity(getValues("calcPoint0.calcKind"),m_cLoadCurrent,getValues("voltage"));
            setValue("calcPoint0.loadCapacity",+doGetTextDropLoadCapacity(load_capacity,false))
            doSetValueVoltDrop()
            clearErrors('calcPoint0.loadCapacity')
            clearErrors('calcPoint0.loadCurrent')


        }
        else if(key === "calcPoint1"){
            setValue("inputedCapacity2",false);
            clearErrors("calcPoint1.loadCapacity")
            clearErrors("calcPoint1.loadCurrent")
            let m_cLoadCurrent = 0;
            let load_capacity = 0
            setValue("inputedCurrent2",false);
            setValue("calcPoint1.loadCurrent",dispInputDialog.load_current_1);
            if(getValues("sPhase")){
                if(getValues("upTransKind") == EKind.MS_ELEMENT_ALL1.MS_TRANS1){
                    m_cLoadCurrent = doGetTextLoadCurrent(wire,MS_OUTSIDE_NEUTRAL);
                }
                else if(getValues("upTransKind") == EKind.MS_ELEMENT_ALL1.MS_TRANSSCOTT){
                    m_cLoadCurrent = doGetTextLoadCurrent(wire,MS_SEAT_MAIN);
                }
            }
            else{
                m_cLoadCurrent = doGetTextLoadCurrent(wire,MS_3PHASES);
            }
            if(getValues("sPhase")== true && getValues("upTransKind") == EKind.MS_ELEMENT_ALL1.MS_TRANS1){
                 load_capacity = doSetValueDropLoadCapcity(getValues("calcPoint1.calcKind"),+m_cLoadCurrent,getValues("voltage")/2)
            }
            else{
                load_capacity = doSetValueDropLoadCapcity(getValues("calcPoint1.calcKind"),+m_cLoadCurrent,getValues("voltage"))
            }
            setValue("calcPoint1.loadCurrent",BeamsNumber(m_cLoadCurrent,5));
            setValue("calcPoint1.loadCapacity",+doGetTextDropLoadCapacity(load_capacity,false))
            doSetValueVoltDrop1()
            clearErrors('calcPoint1.loadCapacity')
            clearErrors('calcPoint1.loadCurrent')
        }
    }

    const doSetValueVoltDrop1 = () => {
        const wire = getValues();
        if(wire.sPhase == true){
            switch(wire.upTransKind){
                case EKind.MS_ELEMENT_ALL1.MS_TRANS1:
                    setValue("voltageDropPoint1", Number(doGetTextDropVoltDiff(getValues(), infoCircuit.stdCapacity,+getValues("voltage") / 2,MS_OUTSIDE_NEUTRAL, false)));
                    setValue("voltageDropPoint",doGetTextDropPerUnit(infoCircuit.stdCapacity,MS_OUTSIDE_NEUTRAL,wire));
                    break;
                case EKind.MS_ELEMENT_ALL1.MS_TRANSSCOTT:
                    setValue("voltageDropPoint1", Number(doGetTextDropVoltDiff(getValues(), infoCircuit.stdCapacity,+getValues("voltage"),MS_SEAT_MAIN, false)));
                    setValue("voltageDropPoint", Number(doGetTextDropPerUnit(infoCircuit.stdCapacity,MS_SEAT_MAIN,wire)));
                    break;
            }
        }
        else{
            setValue("voltageDropPoint1",Number(doGetTextDropVoltDiff(getValues(),infoCircuit.stdCapacity,+getValues("voltage"),MS_3PHASES, false)));
            setValue("voltageDropPoint",doGetTextDropPerUnit(infoCircuit.stdCapacity,MS_3PHASES,wire));
        }
    }


    const handleClearFactorValue = (key:string) => {
        const wire = getValues();
        if(key === "calcPoint0"){
            clearErrors("calcPoint0.powerFactor")
            let power_factor = 0
            setValue("inputedPowerFactor1",false);
            setValue("calcPoint0.powerFactor",BeamsNumber(dispInputDialog.power_factor_0,5))
            setValue("calcPoint0.isLead",dispInputDialog.is_lead_0)
           if(getValues("sPhase")){
            if(getValues("upTransKind") == EKind.MS_ELEMENT_ALL1.MS_TRANS1){
                power_factor = +doGetTextDropVoltPF(wire,MS_OUTSIDE_OUTSIDE,false)
            }
            else if(getValues("upTransKind") == EKind.MS_ELEMENT_ALL1.MS_TRANSSCOTT){
                power_factor = +doGetTextDropVoltPF(wire,MS_SEAT_T,false)
            }
           }
           else{
                 power_factor = +doGetTextDropVoltPF(wire,MS_3PHASES,false)
           }
           setValue("calcPoint0.powerFactor",BeamsNumber(power_factor,5));
           doSetValueVoltDrop();
           clearErrors('calcPoint0.powerFactor')
        }
        else if(key === "calcPoint1"){
            clearErrors("calcPoint1.powerFactor")
            let power_factor = 0
            setValue("inputedPowerFactor2",false);
            setValue("calcPoint1.powerFactor",BeamsNumber(dispInputDialog.power_factor_1,5))
            setValue("calcPoint1.isLead",dispInputDialog.is_lead_1)
           if(getValues("sPhase")){
            if(getValues("upTransKind") == EKind.MS_ELEMENT_ALL1.MS_TRANS1){
                power_factor = +doGetTextDropVoltPF(wire,MS_OUTSIDE_NEUTRAL,false)
            }
            else if(getValues("upTransKind") == EKind.MS_ELEMENT_ALL1.MS_TRANSSCOTT){
                power_factor = +doGetTextDropVoltPF(wire,MS_SEAT_MAIN,false)
            }
           }
           else{
                 power_factor = +doGetTextDropVoltPF(wire,MS_3PHASES,false)
           }
           setValue("calcPoint1.powerFactor",BeamsNumber(power_factor,5));
           doSetValueVoltDrop1();
           clearErrors('calcPoint1.powerFactor')

        }
    }

    const handleChangeBasicLength = (e:any) => {
        doSetZValuesWire(RS_3PHASES,e.target.name);
        doSetZValuesWire(RS_PHASE_N,e.target.name);
        doSetZValuesWire(RS_PHASE_PE,e.target.name);
        doSetIValuesWire(RS_3PHASES,e.target.name);
        doSetIValuesWire(RS_PHASE_N,e.target.name);
        doSetIValuesWire(RS_PHASE_PE,e.target.name);
    }

    const initalValueTab6 = () => {
        
        doSetValueVoltDrop();
    }

    const initialValueTab1 = () => {
        let voltage_text = ""
        const wire = getValues()
        if(isModeSPhases){
            if(data.isMsTrans1){
                voltage_text += doGetTextVoltage(wire,MS_OUTSIDE_OUTSIDE)
                voltage_text += " - "
                voltage_text += doGetTextVoltage(wire,MS_OUTSIDE_NEUTRAL)
            }else{
                voltage_text += doGetTextVoltage(wire,MS_SEAT_MAIN)
                voltage_text += " - "
                voltage_text += doGetTextVoltage(wire,MS_SEAT_T)
            }
        }
        setIsVoltText(voltage_text)
    }
    const handleRadioPower = (e:any,key:string)=>{
        setValue(e.target.name,e.target.value)
        if(key =="calcPoint0"){
            setValue("inputedPowerFactor1",true)
            setValue("calcPoint0.isLead",boolean(e.target.value))
            doSetValueVoltDrop()
        }
        else if(key =="calcPoint1"){
            setValue("inputedPowerFactor2",true)
            setValue("calcPoint1.isLead",boolean(e.target.value))
            doSetValueVoltDrop1()
        }

    }
    const handleChangePowerFactor = (e:any,key:string) =>{
        if(key =="calcPoint0"){
            setValue("inputedPowerFactor1",true)
            setValue("calcPoint0.powerFactor",getValues("calcPoint0.powerFactor"))
            doSetValueVoltDrop()
        }
        else if(key =="calcPoint1"){
            setValue("inputedPowerFactor2",true)
            setValue("calcPoint1.powerFactor",getValues("calcPoint1.powerFactor"))
            doSetValueVoltDrop1()
        }
    }
    return (
        <>
        <div>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={selectTab} onChange={handleChange}
                        variant="scrollable" 
                        scrollButtons 
                        allowScrollButtonsMobile 
                        sx={{
                            '& .MuiButtonBase-root.MuiTabScrollButton-root.Mui-disabled': {
                                display: "none"
                            }
                        }}
                    >
                        <Tab label="基本" {...a11yProps(0)} />
                        <Tab label="仕様" {...a11yProps(1)} />
                        <Tab label="電圧線(3相/単相)" {...a11yProps(2)}/>
                        {
                            isOpenTabPhaseN && 
                            <Tab label="中性線(N相)" {...a11yProps(3)} />
                        }
                        {
                            isOpenTabPhasePE && 
                            <Tab label="保護接地線(PE相)" {...a11yProps(4)} />
                        }
                        <Tab label={isModeSPhases ? "電圧降下 - " + `[${data.isMsTrans1 ? "外線一外線":"住座"}]`: "電圧降下"} {...a11yProps(5)}/>
                        {
                            isModeSPhases && <Tab label={isModeSPhases ? "電圧降下 - " + `[${data.isMsTrans1 ? "外線一中性線":"Ｔ座"}]`: "電圧降下"} {...a11yProps(6)} />
                        }
                        {userRole != Constant.ROLE_LP && <Tab label="潮流解析" {...a11yProps(7)}/>}
                        <Tab label="シンボル" {...a11yProps(8)}/>
                    </Tabs>
                </Box>
                <form onSubmit={handleSubmit(handleEditSubmit)}>
                    <div style={{ height: '40vh', width: '100%', margin: 'auto', overflow: "auto" }}>
                        {/* tab1 - 基本 */}
                        <TabPanel value={selectTab} index={0}>
                            <Grid container my={1}>
                                <Grid item xs={7}>
                                    <Stack spacing={0.3}>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="35%">デバイス名:</Typography>
                                            <Controller
                                                name="refNo"
                                                control={control}
                                                rules={{
                                                    required: '必須項目です。入力してください。',
                                                }}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        error={!!errors?.refNo}
                                                        helperText={errors?.refNo?.message}
                                                        onChange={handleChangeFormat}
                                                        inputProps={{readOnly: m_bModePM || modeViewOnly}}
                                                        InputProps={{ 
                                                            sx: { 
                                                                height: 32,
                                                                background:m_bModePM || modeViewOnly?'floralwhite':'white',
                                                                color: m_bModePM || modeViewOnly?'grey':'black',
                                                            }
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="35%">事故点名称:</Typography>
                                            <Controller
                                                name="pointText"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        error={!!errors?.pointText}
                                                        helperText={errors?.pointText?.message}
                                                        onChange={handleChangeFormat}
                                                        inputProps={{readOnly: m_bModePM || modeViewOnly}}
                                                        InputProps={{ 
                                                            sx: { 
                                                                height: 32,
                                                                background:m_bModePM || modeViewOnly?'floralwhite':'white',
                                                                color: m_bModePM || modeViewOnly?'grey':'black',
                                                            }
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="35%">回路電圧(V):</Typography>
                                                <FormControl fullWidth size="small">
                                                    <Controller
                                                        name="voltage"
                                                        control={control}
                                                        rules={{ required: '必須項目です。入力してください。' ,
                                                            pattern:{
                                                                value: /^[0-9\b]+$/,
                                                                message: '数字を入力してください。'
                                                            },
                                                            validate: value => value >= 0 || '数字 >= 0 を入力してください。' ,
                                                        }}
                                                        render={({ field }) => (
                                                            <>
                                                                <TextFieldOptions
                                                                    {...field}
                                                                    options={data.voltageList}
                                                                    size="small"
                                                                    setValue={(name: any, value: any) => {
                                                                        // setValue(event.target.name,event.target.value);
                                                                        field.onChange({target:{name:name,value:value}})
                                                                        // handleDisableOkBtn({target:{name:name,value:value}})
                                                                    }}
                                                                    // onSelectValue={(name:any,value) => {
                                                                    //     setValue(name,value);
                                                                    // }}
                                                                    maxLength={6}
                                                                    height={32}
                                                                    disabled={m_bModePM || modeViewOnly || getValues("isSourceVoltage")}
                                                                    value={isModeSPhases? voltText :getValues("voltage")}
                                                                    error={!!errors?.voltage}
                                                                    helperText={errors?.voltage?.message}
                                                                    
                                                                />
                                                            </>
                                                            
                                                        )}
                                                    />
                                                </FormControl>
                                        </Stack>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="35%">長さ(m)</Typography>
                                                <Controller
                                                name="length"
                                                control={control}
                                                rules={{
                                                    pattern:{
                                                        value: /^[0-9]*[.]?[0-9]+$/,
                                                        message: '数字を入力してください。'
                                                    },
                                                    validate: value => value >= 0 || '数字 >= 0 を入力してください。' ,
                                                }}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        onBlur={handleChangeFormat}
                                                        onFocus={e => e.target.select()}
                                                        InputLabelProps={{ style: { color: 'black' }}}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            handleChangeBasicLength(e);
                                                        }}
                                                        inputProps={{maxLength:5, readOnly: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}}
                                                        InputProps={{ 
                                                            sx: { 
                                                                height: 32,
                                                                background:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white',
                                                                color: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'grey':'black',
                                                            }
                                                        }}
                                                        error={!!errors?.length}
                                                        helperText={errors?.length?.message}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                    </Stack>
                                </Grid>
                                <Grid item xs={5} pl={1} mt={-0.8}>
                                    <Stack >
                                        <Stack>
                                            <Controller
                                                name="fault"
                                                control={control}
                                                render={({ field }: any) => (
                                                    <FormControlLabel {...field} disabled={m_bModePM || modeViewOnly} control={<Checkbox style={checkboxStyles} />} checked={field.value} label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>短絡電流を計算</Typography>} name='fault' onClick={handleChangeFormat} />
                                                )}
                                            />
                                        </Stack>
                                        <Stack>
                                            <Controller
                                                name="viewResultText"
                                                control={control}
                                                render={({ field }: any) => (
                                                    <FormControlLabel 
                                                        {...field} 
                                                        disabled={m_bModePM || modeViewOnly}
                                                        checked={field.value}
                                                        label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>注釈を表示</Typography>}
                                                        name='viewResultText'
                                                        onClick={handleChangeFormat}
                                                        control={<Checkbox style={checkboxStyles} />} 
                                                    />
                                                )}
                                            />
                                        </Stack>
                                    </Stack>
                                </Grid>
                            </Grid>
                            <Grid container my={1}>
                                <Grid item xs={9}>
                                    <Stack spacing={2} direction='column' mt={1}>
                                        <Grid container direction="row">
                                            <Grid item xs={12}>
                                                <fieldset style={fieldsetStyle}>
                                                    <legend ><Typography variant="body2">配線システム</Typography></legend>
                                                    <Controller
                                                        name="sPhase"
                                                        control={control}
                                                        render={({ field }) => (
                                                        <>
                                                            <FormControlLabel  
                                                                disabled={m_bModePM || modeViewOnly || !isModeSPhases}
                                                                control={<Radio checked={field.value} style={radioStyles} />} 
                                                                label={<Typography variant="body2" color={!isModeSPhases?"grey":"black" }>電圧線(単相)</Typography>}
                                                                
                                                            />
                                                        </>
                                                        )}
                                                    />
                                                    <Controller
                                                        name="wireSystem"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <>
                                                                <RadioGroup {...field} onChange={(e)=> {
                                                                    field.onChange(e)
                                                                    handleChangeWireSystem(e);
                                                                }}>
                                                                    
                                                                    <FormControlLabel 
                                                                        value={RS_3PHASES}
                                                                        disabled={m_bModePM || modeViewOnly || isModeSPhases || processMode > MS_MODE_DRAWING}
                                                                        checked={field.value == RS_3PHASES && !isModeSPhases}
                                                                        control={<Radio style={radioStyles} />} 
                                                                        label={<Typography variant="body2" color={m_bModePM || isModeSPhases || processMode > MS_MODE_DRAWING?"grey":"black" }>電圧線(3相)</Typography>}
                                                                    />
                                                                    <FormControlLabel 
                                                                        value={RS_PHASE_N}
                                                                        disabled={m_bModePM || modeViewOnly || isModeSPhases || processMode > MS_MODE_DRAWING}
                                                                        checked={field.value == RS_PHASE_N && !isModeSPhases}
                                                                        control={<Radio style={radioStyles} />} 
                                                                        label={<Typography variant="body2" color={m_bModePM || isModeSPhases || processMode > MS_MODE_DRAWING?"grey":"black" }>電圧線(3相) + 中性線(N相)</Typography>}
                                                                    />
                                                                    <FormControlLabel 
                                                                        value={RS_PHASE_PE}
                                                                        disabled={m_bModePM || modeViewOnly || isModeSPhases || processMode > MS_MODE_DRAWING}
                                                                        checked={field.value == RS_PHASE_PE && !isModeSPhases}
                                                                        control={<Radio style={radioStyles} />} 
                                                                        label={<Typography variant="body2" color={m_bModePM || isModeSPhases || processMode > MS_MODE_DRAWING?"grey":"black" }>電圧線(3相) + 中性線(N相) + 保護接地線(PE相)</Typography>}
                                                                    />
                                                                </RadioGroup>
                                                            </>
                                                        )}
                                                    />
                                                </fieldset>
                                            </Grid>
                                        </Grid>
                                    </Stack>
                                </Grid>
                            </Grid>
                            {/* PM */}
                            {[Constant.ROLE_PM, Constant.ROLE_ADMIN].includes(userRole) && 
                            <Grid container my={1}>
                                <Grid item xs={8}>
                                    <Stack direction='column' mb={1}>
                                        <Grid container direction="row">
                                            <Grid item xs={12}>
                                                <Stack>
                                                    <FormControlLabel
                                                        control={
                                                            <Controller
                                                                name='makeDataInput'
                                                                control={control}
                                                                render={({ field }) => (
                                                                    <Checkbox
                                                                        {...field}
                                                                        disabled={m_bModePM || modeViewOnly}
                                                                        checked={field.value}
                                                                        onChange={(e)=>{
                                                                            setMakeDataInput(e.target.checked);
                                                                            setValue("makeDataInput",e.target.checked);
                                                                        }}
                                                                        style={{padding:"4px 4px 4px 9px"}}
                                                                    />
                                                                )}
                                                            />
                                                        }
                                                        label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>銘板入力</Typography>}
                                                    />
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Stack>
                                                <fieldset
                                                    style={{ 
                                                        width: '100%',
                                                        color: !makeDataInput ? 'grey' : 'black', 
                                                        border:'1px solid #AAAA'
                                                    }}
                                                >
                                                    <legend><Typography variant="body2">銘板</Typography></legend>
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <Stack spacing={0.3}>
                                                                <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                                    <Typography variant="body2" width="35%">製造者:</Typography>
                                                                        <FormControl fullWidth size='small'>
                                                                            <Controller
                                                                                name='makerName'
                                                                                control={control}
                                                                                render={({ field }: any) => (
                                                                                    <TextFieldOptions
                                                                                        {...field}
                                                                                        options={initialValue.makerNameList}
                                                                                        type='string'
                                                                                        disabled={m_bModePM || modeViewOnly || !makeDataInput}
                                                                                        size='small'
                                                                                        setValue={(name: any, value: any) => {
                                                                                            handleChangeFormat({target: {name: name, value: value}})
                                                                                        }}
                                                                                        height={32}
                                                                                    />
                                                                                )}
                                                                            />
                                                                        </FormControl>
                                                                </Stack>
                                                                <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                                    <Typography variant="body2" width="35%"  >敷設年月:</Typography>
                                                                    <Grid container>
                                                                        <Grid item xs={7}>
                                                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                                                <FormControl fullWidth size='small'>
                                                                                    <Controller
                                                                                        name='makeYear'
                                                                                        control={control}
                                                                                        render={({ field }: any) => (
                                                                                            <TextFieldOptions
                                                                                                {...field}
                                                                                                options={initialValue.makeYearList}
                                                                                                type='string'
                                                                                                size='small'
                                                                                                disabled={m_bModePM || modeViewOnly || !makeDataInput}
                                                                                                setValue={(name: any, value: any) => {
                                                                                                    handleChangeFormat({target: {name: name, value: value}})
                                                                                                }}
                                                                                                height= {32}
                                                                                                maxLength={8}
                                                                                            />
                                                                                        )}
                                                                                    />
                                                                                </FormControl>
                                                                                <Typography variant="body2" pr={1.5} pl={0.5}>年</Typography>
                                                                            </Stack>
                                                                            
                                                                        </Grid>
                                                                        <Grid item xs={5}>
                                                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                                                <FormControl fullWidth size='small'>
                                                                                    <Controller
                                                                                        name='makeMonth'
                                                                                        control={control}
                                                                                        
                                                                                        render={({ field }: any) => (
                                                                                            <TextFieldOptions
                                                                                                {...field}
                                                                                                disabled={m_bModePM || modeViewOnly || !makeDataInput}
                                                                                                options={initialValue.makeMonthList}
                                                                                                type='string'
                                                                                                size='small'
                                                                                                setValue={(name: any, value: any) => {
                                                                                                    handleChangeFormat({target: {name: name, value: value}})
                                                                                                }}
                                                                                                height= {32}
                                                                                                maxLength={2}
                                                                                            />
                                                                                        )}
                                                                                    />
                                                                                </FormControl>
                                                                                <Typography variant="body2" pl={0.5}>月</Typography>
                                                                            </Stack>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Stack>
                                                                <Stack sx={{ flexDirection: "row", alignItems:"center"}}>
                                                                    <Typography variant="body2" width="35%"  >その他:</Typography>
                                                                    <Controller
                                                                        name='type'
                                                                        control={control}
                                                                        render={({ field }) => (
                                                                            <TextField
                                                                                {...field}
                                                                                fullWidth
                                                                                type='text'
                                                                                size='small'
                                                                                onChange={handleChangeFormat}
                                                                                inputProps={{readOnly: m_bModePM || modeViewOnly || !makeDataInput}}
                                                                                InputProps={{ 
                                                                                    sx: { 
                                                                                        height: 32,
                                                                                        background:m_bModePM || modeViewOnly || !makeDataInput?'floralwhite':'white',
                                                                                        color: m_bModePM || modeViewOnly || !makeDataInput?'grey':'black',
                                                                                    }
                                                                                }}
                                                                            />
                                                                        )}
                                                                    />
                                                                </Stack>
                                                            </Stack>
                                                        </Grid>
                                                    </Grid>
                                                </fieldset>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </Stack>
                                </Grid>
                            </Grid>
                            }
                        </TabPanel>

                        {/* tab2 - 仕様 */}
                        <TabPanel value={selectTab} index={1}>
                            <Stack>
                                <Typography variant="body2" mt={2} mb={2}>JIS C 3307, JIS C 3342, JIS C0364-5-523に準拠しています。</Typography>
                            </Stack>
                            <Grid container my={1}>
                                <Grid item xs={2.5}>
                                    <Stack spacing={0.3}>
                                        <fieldset style={fieldsetStyle}>
                                            <legend><Typography variant="body2">材質</Typography></legend>
                                            <Controller
                                                name="material"
                                                control={control}
                                                render={({ field }) => (
                                                    <>
                                                        <RadioGroup {...field}
                                                            onChange={(e) => {
                                                            setValue("material",Number(e.target.value));
                                                            onChangeMaterial(e);
                                                        }}>
                                                            <FormControlLabel disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING} value={WIRE_CU} control={<Radio style={radioStyles} />} label={<Typography variant="body2" color={m_bModePM || processMode > MS_MODE_DRAWING?'grey':'black'}>銅</Typography>}/>
                                                            <FormControlLabel disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING} value={WIRE_AL} control={<Radio style={radioStyles} />} label={<Typography variant="body2" color={m_bModePM || processMode > MS_MODE_DRAWING?'grey':'black'}>アルミニウム</Typography>}/>
                                                        </RadioGroup>
                                                    </>
                                                )}
                                            />
                                        </fieldset>
                                    </Stack>
                                </Grid>
                                <Grid item xs={6.8} mr={1} ml={1}>
                                    <fieldset style={fieldsetStyle}>
                                        <legend> <Typography variant="body2">絶縁物の種類と導体の許容温度</Typography></legend>
                                        <Controller
                                            name="insulation"
                                            control={control}
                                            rules={{ required: '必須項目です。入力してください。' }}
                                            render={({ field }) => (
                                                <>
                                                    <RadioGroup {...field} onChange={(e) => {
                                                        setValue("insulation",Number(e.target.value));
                                                        onChangeInsulationPVC(e);
                                                    }}>
                                                        <FormControlLabel 
                                                            value={WIRE_PVC}
                                                            disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING}
                                                            control={<Radio style={radioStyles} />} 
                                                            label={<Typography variant="body2" color={m_bModePM || processMode > MS_MODE_DRAWING?'grey':'black'}>ポリ塩化ビニル 70°C</Typography>}
                                                        />
                                                        <FormControlLabel 
                                                            value={WIRE_XLPE}
                                                            disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING}
                                                            control={<Radio style={radioStyles} />} 
                                                            label={<Typography variant="body2" color={m_bModePM || processMode > MS_MODE_DRAWING?'grey':'black'}>架橋ポリエチレン及びエチレンプロピレンゴム混合物 90°C</Typography>}
                                                        />
                                                    </RadioGroup>
                                                </>
                                            )}
                                        />
                                    </fieldset>
                                </Grid>
                                <Grid item xs={2.2}>
                                    <fieldset style={{margin:"auto",height:"83%",border:"1px solid #AAAAAA"}}>
                                        <legend ><Typography variant="body2">心線構成</Typography></legend>
                                        <Controller
                                            name="core"
                                            control={control}
                                            rules={{ required: '必須項目です。入力してください。' }}
                                            render={({ field }) => (
                                                <>
                                                    <RadioGroup {...field} onChange={(e) => {
                                                        setValue("core",Number(e.target.value));
                                                        handleChangeCore(e);
                                                    }}>
                                                        <FormControlLabel value={WIRE_SINGLE} disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING} control={<Radio style={radioStyles} />} label={<Typography variant="body2" color={m_bModePM || processMode > MS_MODE_DRAWING?'grey':'black'}>単心</Typography>}/>
                                                        <FormControlLabel value={WIRE_MULTI} disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING} control={<Radio style={radioStyles} />} label={<Typography variant="body2" color={m_bModePM || processMode > MS_MODE_DRAWING?'grey':'black'}>多心</Typography>}/>
                                                    </RadioGroup>
                                                </>
                                            )}
                                        />
                                    </fieldset>
                                </Grid>
                            </Grid>
                            <Grid container my={1}>
                                <Grid item xs={12}>
                                    <Stack spacing={0.3}>
                                    <fieldset style={fieldsetStyle}>
                                        <legend ><Typography variant="body2">JIS C 0364-5-523による施設方法</Typography></legend>
                                        <Controller
                                            name="method"
                                            control={control}
                                            rules={{ required: '必須項目です。入力してください。' }}
                                            render={({ field }) => (
                                                <>
                                                    <RadioGroup {...field} onChange={(e) => {
                                                        field.onChange(e);
                                                        handleChangeMethod(e);
                                                    }}>
                                                        <FormControlLabel 
                                                            value={WIRE_METHOD_A}
                                                            control={<Radio style={radioStyles} disabled={m_bModePM || modeViewOnly || isSingleCore || processMode > MS_MODE_DRAWING} />} 
                                                            label={<Typography fontSize={"12px"} color={m_bModePM || isSingleCore || processMode > MS_MODE_DRAWING?"grey":"black"}>A: 多心ケーブルを絶縁壁内の電線管内に通し、内壁材だけを通して熱放射する施設方法</Typography>}
                                                        />
                                                        <FormControlLabel 
                                                            value= {WIRE_METHOD_B}
                                                            control={<Radio style={radioStyles} disabled={m_bModePM || modeViewOnly || isSingleCore || processMode > MS_MODE_DRAWING}/>} 
                                                            label={<Typography fontSize={"12px"} color={m_bModePM || isSingleCore || processMode > MS_MODE_DRAWING?"grey":"black"}>B: 多心ケーブルを壁面に施設した電線管内に通し、電線管を通して熱放射する施設方法</Typography>}
                                                        />
                                                        <FormControlLabel 
                                                            value= {WIRE_METHOD_C}
                                                            control={<Radio style={radioStyles} disabled={m_bModePM || modeViewOnly || isSingleCore || processMode > MS_MODE_DRAWING}/>} 
                                                            label={<Typography fontSize={"12px"} color={m_bModePM || isSingleCore || processMode > MS_MODE_DRAWING?"grey":"black"}>C: 多心ケーブルを壁面に施設し、熱放射を妨げることなく支持する施設方法</Typography>}
                                                        />
                                                        <FormControlLabel 
                                                            value={WIRE_METHOD_D}
                                                            control={<Radio style={radioStyles} disabled={m_bModePM || modeViewOnly || isSingleCore || processMode > MS_MODE_DRAWING}/>} 
                                                            label={<Typography fontSize={"12px" } color={m_bModePM || isSingleCore || processMode > MS_MODE_DRAWING?"grey":"black"}>D: 多心ケーブルを地中ダクト内に通し、地中に熱放射する施設方法</Typography>}
                                                        />
                                                        <FormControlLabel 
                                                            value={WIRE_METHOD_E}
                                                            control={<Radio style={radioStyles} disabled={m_bModePM || modeViewOnly || isSingleCore || processMode > MS_MODE_DRAWING} />} 
                                                            label={<Typography fontSize={"12px"} color={m_bModePM || isSingleCore || processMode > MS_MODE_DRAWING?"grey":"black"}>E: 多心ケーブルを気中開放し、熱放射を妨げることなく支持する施設方法</Typography>}
                                                        />
                                                        <FormControlLabel 
                                                            value={WIRE_METHOD_F}
                                                            control={<Radio style={radioStyles} disabled={m_bModePM || modeViewOnly || !isSingleCore || processMode > MS_MODE_DRAWING} />} 
                                                            label={<Typography fontSize={"12px"} color={m_bModePM || !isSingleCore || processMode > MS_MODE_DRAWING?"grey":"black"}>F: 束ねた単心ケーブルを気中開放し、熱放射を妨げることなく支持する施設方法</Typography>}
                                                        />
                                                        <FormControlLabel 
                                                            value={WIRE_METHOD_G} 
                                                            control={<Radio style={radioStyles} disabled={m_bModePM || modeViewOnly || !isSingleCore || processMode > MS_MODE_DRAWING} />} 
                                                            label={<Typography fontSize={"12px"} color={m_bModePM || !isSingleCore || processMode > MS_MODE_DRAWING?"grey":"black"}>G: 離隔した単心ケーブルを気中開放し、熱放射を妨げることなく支持する施設方法</Typography>}
                                                        />
                                                    </RadioGroup>
                                                </>
                                            )}
                                        />
                                        </fieldset>
                                    </Stack>
                                </Grid>
                            </Grid>
                            <Grid container my={1}>
                                <Grid item xs={12}>
                                    <Stack spacing={0.3}>
                                        <fieldset style={fieldsetStyle}>
                                            <legend ><Typography variant="body2">許容電流の補正係数</Typography></legend>
                                                <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                    <Typography variant="body2" width="100%">電線の周囲温度(°C):</Typography>
                                                    <Controller
                                                        name="ambientTemp"
                                                        control={control}
                                                        rules={{
                                                            pattern:{
                                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                                message: '数字を入力してください。'
                                                            },
                                                            validate: value => value >= 0 || '数字 >= 0 を入力してください。' ,
                                                        }}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                type="text"
                                                                size="small"
                                                                fullWidth
                                                                onBlur={handleChangeFormat}
                                                                onFocus={e => e.target.select()}
                                                                onChange={(e:any) => {
                                                                    field.onChange(e);
                                                                    handleChangeAmbientTemp(e);
                                                                }}
                                                                inputProps={{maxlength:3,readOnly: m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING}}
                                                                InputProps={{ 
                                                                    sx: { 
                                                                        height: 32,
                                                                        background:m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING?'floralwhite':'white',
                                                                        color: m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING?'grey':'black',
                                                                    }
                                                                }}
                                                                error={!!errors?.ambientTemp}
                                                                helperText={errors?.ambientTemp?.message}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                                <Stack sx={{ flexDirection: "row", alignItems:"center",marginTop:"3px"}}>
                                                    <Typography variant="body2" width="100%">1つのトレイ上に集合する回路の数:</Typography>
                                                    <FormControl fullWidth size="small">
                                                        <Controller
                                                            name="circuitNumber"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <Select
                                                                    {...field}
                                                                    error={!!errors?.circuitNumber}
                                                                    onChange={(e:any)=>{
                                                                        field.onChange(e);
                                                                        handleSelectCircuitNumber(e);
                                                                    }}
                                                                    inputProps={{readOnly: m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING}}
                                                                    sx={{
                                                                        height:32,
                                                                        background:m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING?'floralwhite':'white',
                                                                        color: m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING?'grey':'black',
                                                                    }}
                                                                >
                                                                    {cirCuitOptions.map(item => (
                                                                        <MenuItem key={item.label} value={item.value}>
                                                                            {item.label}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            )}
                                                        />
                                                    </FormControl>
                                                </Stack>
                                            </fieldset>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </TabPanel>

                        {/* tab3 - 電圧線(3相/単相) */}
                        <TabPanel value={selectTab} index={2}>
                        <Grid container my={1}>
                                <Grid item xs={12} style={{margin:"auto"}}>
                                    <Stack spacing={0.3}>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="80%">電圧線の1相当りの公称断面積(mm<sup>2</sup>):</Typography>
                                            <FormControl fullWidth size="small">
                                                    <Controller
                                                        name="_3Phases.coreSize"
                                                        control={control}
                                                        rules={{ required: '必須項目です。入力してください。',
                                                            pattern:{
                                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                                message: '数字を入力してください。'
                                                            },
                                                            validate: value => value >= limitSize3Phase.limitMin && value <= limitSize3Phase.limitMax || (limitSize3Phase.limitMin +'<= 数字 <=' +limitSize3Phase.limitMax +'を入力してください。') ,
                                                        }}
                                                        render={({ field }) => (
                                                            <>
                                                            <TextFieldOptions
                                                                {...field}
                                                                options={data.sizeCoreList}
                                                                size="small"
                                                                setValue={(name: any, value: any) => {
                                                                    // setValue(event.target.name,event.target.value);
                                                                    field.onChange({target:{name:name,value:value}})
                                                                    handleRefreshValue({target:{name:name,value:value}});
                                                                }}
                                                                // onSelectValue={(name:any,value) => {
                                                                //     setValue(name,value);
                                                                //     handleRefreshValue({target:{value:value,name:name}});
                                                                // }}
                                                                height={32}
                                                                maxLength={4}
                                                                disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING}
                                                                error={!!errors?._3Phases?.coreSize}
                                                                helperText={errors?._3Phases?.coreSize?.message}
                                                            />
                                                        </>
                                                        )}
                                                    />
                                                </FormControl>
                                        </Stack>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="80%">電圧線の1相当りの心線本数:</Typography>
                                            <FormControl fullWidth size="small">
                                                <Controller
                                                    name="_3Phases.number"
                                                    control={control}
                                                    rules={{ required: '必須項目です。入力してください。' }}
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            error={!!errors?._3Phases?.number}
                                                            onChange={
                                                                (e:any)=>{
                                                                    setValue("_3Phases.number",Number(e.target.value));
                                                                    setValue("PhaseN.number",Number(e.target.value));
                                                                    setValue("PhasePE.number",Number(e.target.value));
                                                                    handleRefreshValue(e);
                                                                }
                                                            }
                                                            disabled={m_bModePM || modeViewOnly || !isSingleCore || processMode > MS_MODE_DRAWING}
                                                            style={{backgroundColor:m_bModePM || modeViewOnly || !isSingleCore || processMode > MS_MODE_DRAWING? "floralwhite":"white",height:"32px"}}
                                                        >
                                                            {numberPhaseList.map(item => (
                                                                <MenuItem key={item.label} value={item.value}>
                                                                    {item.label}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    )}
                                                />
                                            </FormControl>
                                        </Stack>
                                    </Stack>
                                </Grid>
                            </Grid>
                            <Grid container my={1}>
                                <Grid item xs={12}>
                                    <fieldset style={fieldsetStyle}>
                                        <legend><Typography variant="body2">インピーダンス</Typography></legend>
                                        <Stack spacing={0.3}>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="160%">1心線の1m当りの抵抗(mΩ/m/1心線)：</Typography>
                                                <Controller
                                                    name={"_3Phases.ohmRperperM"}
                                                    control={control}
                                                    rules={{ required: '必須項目です。入力してください。',
                                                        pattern:{
                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                            message: '数字を入力してください。'
                                                        },
                                                        validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            // type="text"
                                                            size="small"
                                                            onBlur={handleChangeFormat}
                                                            onFocus={e => e.target.select()}
                                                            InputLabelProps={{ style: { color: 'black' }}}
                                                            onChange={(e:any) => {
                                                                field.onChange(e);
                                                                handleChangeOhmRperper(e,RS_3PHASES);
                                                            }}
                                                            inputProps={{
                                                                maxLength:7,
                                                                readOnly: m_bModePM || modeViewOnly  || processMode > MS_MODE_CHECKING
                                                            }}
                                                            InputProps={{
                                                                sx: { 
                                                                    height: 32,
                                                                    background:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white',
                                                                    color: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'grey':'black',
                                                                },
                                                                endAdornment:(
                                                                    <text style={{color:getValues("_3Phases.ohmRperperM") != getValues("_3Phases.ohmRperperA") ? "black" : "grey",fontSize:"30px",marginTop:"15px"}}>*</text>
                                                                )
                                                            }}
                                                            error={!!errors?._3Phases?.ohmRperperM}
                                                            helperText={errors?._3Phases?.ohmRperperM?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="160%">抵抗(mΩ):</Typography>
                                                <Controller
                                                    name="ohmR_3Phases"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            disabled
                                                            fullWidth
                                                            type="text"
                                                            size="small"
                                                            onBlur={(handleChangeFormat)}
                                                            onFocus={e => e.target.select()}
                                                            InputProps={{
                                                                sx: { height: 32 }
                                                            }}
                                                            style={{backgroundColor:"floralwhite"}}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="160%">心線の直径(mm):</Typography>
                                                <Controller
                                                    name="_3Phases.diameterM"
                                                    control={control}
                                                    rules={{ required: '必須項目です。入力してください。',
                                                        pattern:{
                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                            message: '数字を入力してください。'
                                                        },
                                                        validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            // type="text"
                                                            size="small"
                                                            onBlur={handleChangeFormat}
                                                            onFocus={e => e.target.select()}
                                                            InputLabelProps={{ style: { color: 'black' }}}
                                                            onChange={(e)=>{
                                                                field.onChange(e);
                                                                handleChangeDiameter(e,RS_3PHASES);
                                                            }}
                                                            inputProps={{
                                                                maxLength:5,
                                                                readOnly: m_bModePM || modeViewOnly
                                                            }}
                                                            InputProps={{
                                                                sx: { 
                                                                    height: 32,
                                                                    background:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white',
                                                                    color: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'grey':'black',
                                                                },
                                                                endAdornment:(
                                                                    <text style={{color:getValues("_3Phases.diameterM") != getValues("_3Phases.diameterA") ? "black" : "grey",fontSize:"30px",marginTop:"15px"}}>*</text>
                                                                )
                                                            }}
                                                            error={!!errors?._3Phases?.diameterM}
                                                            helperText={errors?._3Phases?.diameterM?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="160%">絶縁皮膜厚さ(mm):</Typography>
                                                <Controller
                                                    name="_3Phases.thickNessM"
                                                    control={control}
                                                    rules={{ required: '必須項目です。入力してください。',
                                                        pattern:{
                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                            message: '数字を入力してください。'
                                                        },
                                                        validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            defaultValue={0}
                                                            fullWidth
                                                            // type="text"
                                                            size="small"
                                                            onBlur={handleChangeFormat}
                                                            onFocus={e => e.target.select()}
                                                            onChange={(e:any) => {
                                                                field.onChange(e);
                                                                handleChangeThickNess(e,RS_3PHASES);
                                                            }}
                                                            inputProps={{
                                                                maxLength:4,
                                                                readOnly: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING
                                                            }}
                                                            InputProps={{
                                                                sx: { 
                                                                    height: 32,
                                                                    background:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white',
                                                                    color: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'grey':'black',
                                                                },
                                                                endAdornment:(
                                                                    <text style={{color:getValues("_3Phases.thickNessM") != getValues("_3Phases.thickNessA") ? "black" : "grey",fontSize:"30px",marginTop:"15px"}}>*</text>
                                                                )
                                                            }}
                                                            error={!!errors?._3Phases?.thickNessM}
                                                            helperText={errors?._3Phases?.thickNessM?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="160%">相間の中心間距離(mm):</Typography>
                                                <Controller
                                                    name="_3Phases.distanceM"
                                                    control={control}
                                                    rules={{ required: '必須項目です。入力してください。',
                                                        pattern:{
                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                            message: '数字を入力してください。'
                                                        },
                                                        validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            // type="text"
                                                            size="small"
                                                            onBlur={handleChangeFormat}
                                                            onFocus={e => e.target.select()}
                                                            onChange={(e:any)=> {
                                                                field.onChange(e);
                                                                handleChangeDistanceM(e,RS_3PHASES);
                                                            }}
                                                            inputProps={{
                                                                maxLength:5,
                                                                readOnly: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING
                                                            }}
                                                            InputProps={{
                                                                sx: { 
                                                                    height: 32,
                                                                    background:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white',
                                                                    color: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'grey':'black',
                                                                },
                                                                endAdornment:(
                                                                    <text style={{color:getValues("_3Phases.distanceM") != getValues("_3Phases.distanceA") ? "black" : "grey",fontSize:"30px",marginTop:"15px"}}>*</text>
                                                                )
                                                            }}
                                                            error={!!errors?._3Phases?.distanceM}
                                                            helperText={errors?._3Phases?.distanceM?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="160%">1心線の1m当りのリアクタンス(mΩ/m/1心線)：</Typography>
                                                <Controller
                                                    name="_3Phases.ohmXperperM"
                                                    control={control}
                                                    rules={{ required: '必須項目です。入力してください。',
                                                        pattern:{
                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                            message: '数字を入力してください。'
                                                        },
                                                        validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            defaultValue={0}
                                                            fullWidth
                                                            // type="text"
                                                            size="small"
                                                            onBlur={handleChangeFormat}
                                                            onFocus={e => e.target.select()}
                                                            onChange={(e:any)=> {
                                                                field.onChange(e);
                                                                handleChangeOhmX(e,RS_3PHASES);
                                                            }}
                                                            inputProps={{
                                                                maxLength:7,
                                                                readOnly: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING
                                                            }}
                                                            InputProps={{
                                                                sx: { 
                                                                    height: 32,
                                                                    background:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white',
                                                                    color: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'grey':'black',
                                                                },
                                                                endAdornment:(
                                                                    <text style={{color:getValues("_3Phases.ohmXperperM") != getValues("_3Phases.ohmXperperA")? "black" : "grey",fontSize:"30px",marginTop:"15px"}}>*</text>
                                                                )
                                                            }}
                                                            error={!!errors?._3Phases?.ohmXperperM}
                                                            helperText={errors?._3Phases?.ohmXperperM?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="160%">リアクタンス(mΩ):</Typography>
                                                <Controller
                                                    name="ohmX_3Phases"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            disabled
                                                            fullWidth
                                                            type="text"
                                                            size="small"
                                                            onBlur={handleChangeFormat}
                                                            onFocus={e => e.target.select()}
                                                            style={{backgroundColor:"floralwhite"}}
                                                            InputProps={{
                                                                sx:{height:32}
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                        </Stack>
                                    </fieldset>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={7}>
                                    <fieldset style={fieldsetStyle}>
                                        <legend><Typography variant="body2">許容電流</Typography></legend>
                                        <Stack spacing={0.3}>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="160%">基本通電許容電流(A):</Typography>
                                                <Controller
                                                    name="_3Phases.basicCapacityM"
                                                    control={control}
                                                    rules={{ required: '必須項目です。入力してください。',
                                                        pattern:{
                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                            message: '数字を入力してください。'
                                                        },
                                                        validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            // type="text"
                                                            size="small"
                                                            onBlur={handleChangeFormat}
                                                            onFocus={e => e.target.select()}
                                                            onChange={(e:any)=>{
                                                                field.onChange(e);
                                                                handleChangeBasicCapacity(e,RS_3PHASES);
                                                            }}
                                                            inputProps={{
                                                                maxLength:7,
                                                                readOnly: m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING
                                                            }}
                                                            InputProps={{
                                                                sx: { 
                                                                    height: 32,
                                                                    background:m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING?'floralwhite':'white',
                                                                    color: m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING?'grey':'black',
                                                                },
                                                                endAdornment:(
                                                                    <text style={{color:getValues("_3Phases.basicCapacityM") != getValues("_3Phases.basicCapacityA") ? "black" : "grey",fontSize:"30px",marginTop:"15px"}}>*</text>
                                                                )
                                                            }}
                                                            error={!!errors?._3Phases?.basicCapacityM}
                                                            helperText={errors?._3Phases?.basicCapacityM?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="160%">周囲温度補正係数:</Typography>
                                                <Controller
                                                    name="_3Phases.tempk2M"
                                                    control={control}
                                                    rules={{ required: '必須項目です。入力してください。',
                                                        pattern:{
                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                            message: '数字を入力してください。'
                                                        },
                                                        validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            // type="text"
                                                            size="small"
                                                            onBlur={handleChangeFormat}
                                                            onFocus={e => e.target.select()}
                                                            onChange={(e:any)=>{
                                                                field.onChange(e);
                                                                handleChangeTempk2M(e,RS_3PHASES);
                                                            }}
                                                            inputProps={{
                                                                maxLength:6,
                                                                readOnly: m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING
                                                            }}
                                                            InputProps={{
                                                                sx: { 
                                                                    height: 32,
                                                                    background:m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING?'floralwhite':'white',
                                                                    color: m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING?'grey':'black',
                                                                },
                                                                endAdornment:(
                                                                    <text style={{color:getValues("_3Phases.tempk2M") != getValues("_3Phases.tempk2A") ? "black" : "grey",fontSize:"30px",marginTop:"15px"}}>*</text>
                                                                )
                                                            }}
                                                            error={!!errors?._3Phases?.tempk2M}
                                                            helperText={errors?._3Phases?.tempk2M?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="160%">複数集合補正係数:</Typography>
                                                <Controller
                                                    name="_3Phases.reductionFactorM"
                                                    control={control}
                                                    rules={{ required: '必須項目です。入力してください。',
                                                        pattern:{
                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                            message: '数字を入力してください。'
                                                        },
                                                        validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            // type="text"
                                                            size="small"
                                                            onBlur={handleChangeFormat}
                                                            onFocus={e => e.target.select()}
                                                            onChange={(e:any)=>{
                                                                field.onChange(e);
                                                                handleChangeReductionFactorM(e,RS_3PHASES);
                                                            }}
                                                            inputProps={{
                                                                maxLength:4,
                                                                readOnly: m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING
                                                            }}
                                                            InputProps={{
                                                                sx: { 
                                                                    height: 32,
                                                                    background:m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING?'floralwhite':'white',
                                                                    color: m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING?'grey':'black',
                                                                },
                                                                endAdornment:(
                                                                    <text style={{color:getValues("_3Phases.reductionFactorM") != getValues("_3Phases.reductionFactorA") ? "black" : "grey",fontSize:"30px",marginTop:"15px"}}>*</text>
                                                                )
                                                            }}
                                                            error={!!errors?._3Phases?.reductionFactorM}
                                                            helperText={errors?._3Phases?.reductionFactorM?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="160%">通電許容電流(A):</Typography>
                                                <Controller
                                                    name="capacity_3Phases"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            disabled={true}
                                                            defaultValue={0}
                                                            fullWidth
                                                            type="number"
                                                            size="small"
                                                            onBlur={handleChangeFormat}
                                                            onFocus={e => e.target.select()}
                                                            style={{backgroundColor:"floralwhite"}}
                                                            InputProps={{
                                                                sx:{height:32}
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                        </Stack>
                                    </fieldset>
                                </Grid>
                            </Grid>
                        </TabPanel>

                        {/* tab 4 - 中性線(N相) */}
                        {isOpenTabPhaseN &&
                        <TabPanel value={selectTab} index={3}>
                            <Grid container my={1}>
                                <Grid item xs={12} style={{margin:"auto"}}>
                                    <Stack spacing={0.3}>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="80%">中性線の公称断面積(mm<sup>2</sup>):</Typography>
                                            <FormControl fullWidth size="small">
                                                    <Controller
                                                        name="PhaseN.coreSize"
                                                        control={control}
                                                        rules={{ required: '必須項目です。入力してください。',
                                                            pattern:{
                                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                                message: '数字を入力してください。'
                                                            },
                                                            validate: value => value >= +getValues("_3Phases.coreSize") || '数字 >= '+ getValues("_3Phases.coreSize")+' を入力してください。' ,
                                                        }}
                                                        render={({ field }) => (
                                                            <>
                                                            <TextFieldOptions
                                                                {...field}
                                                                disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING}
                                                                options={optionCoreSizePhaseN}
                                                                size="small"
                                                               setValue={(name:any,value:any)=>{
                                                                    field.onChange({target:{name:name,value:value}})
                                                                    handleEditCoreSize({target:{name:name,value:value}},RS_PHASE_N);
                                                                }}
                                                                // onSelectValue={(name:any,value) => {
                                                                //     setValue(name,value);
                                                                //     handleEditCoreSize({target:{name:name,value:value}},RS_PHASE_N)
                                                                // }}
                                                                maxLength={4}
                                                                height={32}
                                                                error={!!errors?.PhaseN?.coreSize}
                                                                helperText={errors?.PhaseN?.coreSize?.message}
                                                            />
                                                        </>
                                                        )}
                                                    />
                                                </FormControl>
                                        </Stack>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="80%">中性線の心線本数：</Typography>
                                            <FormControl fullWidth size="small">
                                                <Controller
                                                    name="PhaseN.number"
                                                    control={control}
                                                    rules={{ required: '必須項目です。入力してください。' }}
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            error={!!errors?.PhaseN?.number}
                                                            onChange={(e:any) => {
                                                                setValue("PhaseN.number",Number(e.target.value));
                                                                handleChangeNumber(e,RS_PHASE_N);
                                                            } }
                                                            disabled={m_bModePM || modeViewOnly || !isSingleCore || processMode > MS_MODE_DRAWING}
                                                            style={{backgroundColor:m_bModePM || !isSingleCore || processMode > MS_MODE_DRAWING? "floralwhite":"white",height:"32px"}}
                                                        >
                                                            {optionNumber.map((item:any) => (
                                                                <MenuItem key={item.label} value={item.value}>
                                                                    {item.label}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    )}
                                                />
                                            </FormControl>
                                        </Stack>
                                    </Stack>
                                </Grid>
                            </Grid>
                            <Grid container my={1}>
                                <Grid item xs={12}>
                                    <fieldset style={fieldsetStyle}>
                                        <legend ><Typography variant="body2">インピーダンス</Typography></legend>
                                        <Stack spacing={0.3}>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="160%">1心線の1m当りの抵抗(mΩ/m/1心線)：</Typography>
                                                <Controller
                                                    name={"PhaseN.ohmRperperM"}
                                                    control={control}
                                                    rules={{ required: '必須項目です。入力してください。',
                                                        pattern:{
                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                            message: '数字を入力してください。'
                                                        },
                                                        validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            // type="text"
                                                            size="small"
                                                            onBlur={handleChangeFormat}
                                                            onFocus={e => e.target.select()}
                                                            onChange={(e:any)=>{
                                                                field.onChange(e);
                                                                handleChangeOhmRperper(e,RS_PHASE_N);
                                                            }}
                                                            inputProps={{
                                                                maxLength:7,
                                                                readOnly: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING
                                                            }}
                                                            InputProps={{
                                                                sx: { 
                                                                    height: 32,
                                                                    background:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white',
                                                                    color: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'grey':'black',
                                                                },
                                                                endAdornment:(
                                                                    <text style={{color:getValues("PhaseN.ohmRperperM") != getValues("PhaseN.ohmRperperA") ? "black" : "grey",fontSize:"30px",marginTop:"15px"}}>*</text>
                                                                )
                                                            }}
                                                            error={!!errors?.PhaseN?.ohmRperperM}
                                                            helperText={errors?.PhaseN?.ohmRperperM?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="160%">抵抗(mΩ):</Typography>
                                                <Controller
                                                    name="ohmR_PhaseN"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            disabled
                                                            fullWidth
                                                            type="text"
                                                            size="small"
                                                            onBlur={handleChangeFormat}
                                                            onFocus={e => e.target.select()}
                                                            style={{backgroundColor:"floralwhite"}}
                                                            inputProps={{readOnly: m_bModePM || modeViewOnly}}
                                                            InputProps={{ 
                                                                sx: { 
                                                                    height: 32,
                                                                    // background:m_bModePM?'floralwhite':'white',
                                                                    // color: m_bModePM?'grey':'black',
                                                                }
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="160%">心線の直径(mm):</Typography>
                                                <Controller
                                                    name="PhaseN.diameterM"
                                                    control={control}
                                                    rules={{ required: '必須項目です。入力してください。',
                                                        pattern:{
                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                            message: '数字を入力してください。'
                                                        },
                                                        validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            // type="text"
                                                            size="small"
                                                            onBlur={handleChangeFormat}
                                                            onFocus={e => e.target.select()}
                                                            onChange={(e:any)=>{
                                                                field.onChange(e);
                                                                handleChangeDiameter(e,RS_PHASE_N)
                                                            }}
                                                            inputProps={{
                                                                maxLength:5,
                                                                readOnly: m_bModePM || modeViewOnly
                                                            }}
                                                            InputProps={{
                                                                sx: { 
                                                                    height: 32,
                                                                    background:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white',
                                                                    color: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'grey':'black',
                                                                },
                                                                endAdornment:(
                                                                    <text style={{color:getValues("PhaseN.diameterM") != getValues("PhaseN.diameterA") ? "black" : "grey",fontSize:"30px",marginTop:"15px"}}>*</text>
                                                                )
                                                            }}
                                                            error={!!errors?.PhaseN?.diameterM}
                                                            helperText={errors?.PhaseN?.diameterM?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="160%">絶縁皮膜厚さ(mm):</Typography>
                                                <Controller
                                                    name="PhaseN.thickNessM"
                                                    control={control}
                                                    rules={{ required: '必須項目です。入力してください。',
                                                        pattern:{
                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                            message: '数字を入力してください。'
                                                        },
                                                        validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            defaultValue={0}
                                                            fullWidth
                                                            // type="text"
                                                            size="small"
                                                            onBlur={handleChangeFormat}
                                                            onFocus={e => e.target.select()}
                                                            onChange={(e:any)=> {
                                                                field.onChange(e);
                                                                handleChangeThickNess(e,RS_PHASE_N)
                                                            }}
                                                            inputProps={{
                                                                maxLength:4,
                                                                readOnly: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING
                                                            }}
                                                            InputProps={{
                                                                sx: { 
                                                                    height: 32,
                                                                    background:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white',
                                                                    color: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'grey':'black',
                                                                },
                                                                endAdornment:(
                                                                    <text style={{color:getValues("PhaseN.thickNessM") != getValues("PhaseN.thickNessA") ? "black" : "grey",fontSize:"30px",marginTop:"15px"}}>*</text>
                                                                )
                                                            }}
                                                            error={!!errors?.PhaseN?.thickNessM}
                                                            helperText={errors?.PhaseN?.thickNessM?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="160%">相間の中心間距離(mm):</Typography>
                                                <Controller
                                                    name="PhaseN.distanceM"
                                                    control={control}
                                                    rules={{ required: '必須項目です。入力してください。',
                                                        pattern:{
                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                            message: '数字を入力してください。'
                                                        },
                                                        validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            // type="text"
                                                            size="small"
                                                            onBlur={handleChangeFormat}
                                                            onFocus={e => e.target.select()}
                                                            onChange={(e)=>{
                                                                field.onChange(e);
                                                                handleChangeDistanceM(e,RS_PHASE_N);
                                                            }}
                                                            inputProps={{
                                                                maxLength:5,
                                                                readOnly: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING
                                                            }}
                                                            InputProps={{
                                                                sx: { 
                                                                    height: 32,
                                                                    background:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white',
                                                                    color: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'grey':'black',
                                                                },
                                                                endAdornment:(
                                                                    <text style={{color:getValues("PhaseN.distanceM") != getValues("PhaseN.distanceA") ? "black" : "grey",fontSize:"30px",marginTop:"15px"}}>*</text>
                                                                )
                                                            }}
                                                            error={!!errors?.PhaseN?.distanceM}
                                                            helperText={errors?.PhaseN?.distanceM?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="160%">1心線の1m当りのリアクタンス(mΩ/m/1心線)：</Typography>
                                                <Controller
                                                    name="PhaseN.ohmXperperM"
                                                    control={control}
                                                    rules={{ required: '必須項目です。入力してください。',
                                                        pattern:{
                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                            message: '数字を入力してください。'
                                                        },
                                                        validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            defaultValue={0}
                                                            fullWidth
                                                            // type="text"
                                                            size="small"
                                                            onBlur={handleChangeFormat}
                                                            onFocus={e => e.target.select()}
                                                            onChange={(e:any)=> {
                                                                field.onChange(e);
                                                                handleChangeOhmX(e,RS_PHASE_N)
                                                            }}
                                                            inputProps={{
                                                                maxLength:7,
                                                                readOnly: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING
                                                            }}
                                                            InputProps={{
                                                                sx: { 
                                                                    height: 32,
                                                                    background:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white',
                                                                    color: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'grey':'black',
                                                                },
                                                                endAdornment:(
                                                                    <text style={{color:getValues("PhaseN.ohmXperperM") != getValues("PhaseN.ohmXperperA") ? "black" : "grey",fontSize:"30px",marginTop:"15px"}}>*</text>
                                                                )
                                                            }}
                                                            error={!!errors?.PhaseN?.ohmXperperM}
                                                            helperText={errors?.PhaseN?.ohmXperperM?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="160%">リアクタンス(mΩ):</Typography>
                                                <Controller
                                                    name="ohmX_PhaseN"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            disabled
                                                            fullWidth
                                                            type="text"
                                                            size="small"
                                                            onBlur={handleChangeFormat}
                                                            onFocus={e => e.target.select()}
                                                            style={{backgroundColor:"floralwhite"}}
                                                            inputProps={{readOnly: m_bModePM || modeViewOnly}}
                                                            InputProps={{ 
                                                                sx: { 
                                                                    height: 32,
                                                                    // background:m_bModePM?'floralwhite':'white',
                                                                    // color: m_bModePM?'grey':'black',
                                                                }
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                        </Stack>
                                    </fieldset>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={7}>
                                    <fieldset style={fieldsetStyle}>
                                        <legend ><Typography variant="body2">許容電流</Typography></legend>
                                        <Stack spacing={0.3}>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="160%">基本通電許容電流(A):</Typography>
                                                <Controller
                                                    name="PhaseN.basicCapacityM"
                                                    control={control}
                                                    rules={{ required: '必須項目です。入力してください。',
                                                        pattern:{
                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                            message: '数字を入力してください。'
                                                        },
                                                        validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            // type="text"
                                                            size="small"
                                                            onBlur={handleChangeFormat}
                                                            onFocus={e => e.target.select()}
                                                            onChange={(e:any)=>{
                                                                field.onChange(e);
                                                                handleChangeBasicCapacity(e,RS_PHASE_N);
                                                            }}
                                                            inputProps={{
                                                                maxLength:7,
                                                                readOnly: m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING
                                                            }}
                                                            InputProps={{
                                                                sx: { 
                                                                    height: 32,
                                                                    background:m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING?'floralwhite':'white',
                                                                    color: m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING?'grey':'black',
                                                                },
                                                                endAdornment:(
                                                                    <text style={{color:getValues("PhaseN.basicCapacityM") != getValues("PhaseN.basicCapacityA") ? "black" : "grey",fontSize:"30px",marginTop:"15px"}}>*</text>
                                                                )
                                                            }}
                                                            error={!!errors?.PhaseN?.basicCapacityM}
                                                            helperText={errors?.PhaseN?.basicCapacityM?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="160%">周囲温度補正係数:</Typography>
                                                <Controller
                                                    name="PhaseN.tempk2M"
                                                    control={control}
                                                    rules={{ required: '必須項目です。入力してください。',
                                                        pattern:{
                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                            message: '数字を入力してください。'
                                                        },
                                                        validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            // type="text"
                                                            size="small"
                                                            onBlur={handleChangeFormat}
                                                            onFocus={e => e.target.select()}
                                                            onChange={(e:any) => {
                                                                field.onChange(e);
                                                                handleChangeTempk2M(e,RS_PHASE_N);
                                                            }}
                                                            inputProps={{
                                                                maxLength:6,
                                                                readOnly: m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING
                                                            }}
                                                            InputProps={{
                                                                sx: { 
                                                                    height: 32,
                                                                    background:m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING?'floralwhite':'white',
                                                                    color: m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING?'grey':'black',
                                                                },
                                                                endAdornment:(
                                                                    <text style={{color:getValues("PhaseN.tempk2M") != getValues("PhaseN.tempk2A") ? "black" : "grey",fontSize:"30px",marginTop:"15px"}}>*</text>
                                                                )
                                                            }}
                                                            error={!!errors?.PhaseN?.tempk2M}
                                                            helperText={errors?.PhaseN?.tempk2M?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="160%">複数集合補正係数:</Typography>
                                                <Controller
                                                    name="PhaseN.reductionFactorM"
                                                    control={control}
                                                    rules={{ required: '必須項目です。入力してください。',
                                                        pattern:{
                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                            message: '数字を入力してください。'
                                                        },
                                                        validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            // type="text"
                                                            size="small"
                                                            onBlur={handleChangeFormat}
                                                            onFocus={e => e.target.select()}
                                                            onChange={(e:any)=>{
                                                                field.onChange(e);
                                                                handleChangeReductionFactorM(e,RS_PHASE_N);
                                                            }}
                                                            inputProps={{
                                                                maxLength:4,
                                                                readOnly: m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING
                                                            }}
                                                            InputProps={{
                                                                sx: { 
                                                                    height: 32,
                                                                    background:m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING?'floralwhite':'white',
                                                                    color: m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING?'grey':'black',
                                                                },
                                                                endAdornment:(
                                                                    <text style={{color:getValues("PhaseN.reductionFactorM") != getValues("PhaseN.reductionFactorA") ? "black" : "grey",fontSize:"30px",marginTop:"15px"}}>*</text>
                                                                )
                                                            }}
                                                            error={!!errors?.PhaseN?.reductionFactorM}
                                                            helperText={errors?.PhaseN?.reductionFactorM?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="160%">通電許容電流(A):</Typography>
                                                <Controller
                                                    name="capacity_PhaseN"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            disabled={true}
                                                            defaultValue={0}
                                                            fullWidth
                                                            type="number"
                                                            size="small"
                                                            onBlur={handleChangeFormat}
                                                            onFocus={e => e.target.select()}
                                                            InputProps={{
                                                                sx:{height:32}
                                                            }}
                                                            style={{backgroundColor:"floralwhite"}}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                        </Stack>
                                    </fieldset>
                                </Grid>
                            </Grid>
                        </TabPanel>
                        }

                        {/* tab 5 - 保護接地線(PE相) */}
                        {isOpenTabPhasePE && 
                            <TabPanel value={selectTab} index={4}>
                                <Grid container my={1}>
                                <Grid item xs={12} style={{margin:"auto"}}>
                                    <Stack spacing={0.3}>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="80%">保護接地線の公称断面槙(mm<sup>2</sup>):</Typography>
                                            <FormControl fullWidth size="small">
                                                    <Controller
                                                        name="PhasePE.coreSize"
                                                        control={control}
                                                        rules={{ required: '必須項目です。入力してください。',
                                                            pattern:{
                                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                                message: '数字を入力してください。'
                                                            },
                                                            validate: value => value >= +getValues("_3Phases.coreSize") || '数字 >= '+getValues("_3Phases.coreSize") + ' を入力してください。' ,
                                                        }}
                                                        render={({ field }) => (
                                                            <>
                                                            <TextFieldOptions
                                                                {...field}
                                                                disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING}
                                                                options={optionCoreSizePhasePE}
                                                                size="small"
                                                                setValue={(name:any,value:any)=>{
                                                                    field.onChange({target:{name:name,value:value}});
                                                                    handleEditCoreSize({target:{name:name,value:value}},RS_PHASE_PE);
                                                                }}
                                                                // onSelectValue={(name:any,value) => {
                                                                //     setValue(name,value);
                                                                //     handleEditCoreSize({target:{name:name,value:value}},RS_PHASE_PE);
                                                                // }}
                                                                maxLength={4}
                                                                height={32}
                                                                error={!!errors?.PhasePE?.coreSize}
                                                                helperText={errors?.PhasePE?.coreSize?.message}
                                                            />
                                                        </>
                                                        )}
                                                    />
                                                </FormControl>
                                        </Stack>
                                    </Stack>
                                </Grid>
                            </Grid>
                            <Grid container my={1}>
                                <Grid item xs={12}>
                                    <fieldset style={fieldsetStyle}>
                                        <legend ><Typography variant="body2">インピーダンス</Typography></legend>
                                        <Stack spacing={0.3}>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="160%">1心線の1m当りの抵抗(mΩ/m/1心線)：</Typography>
                                                <Controller
                                                    name={"PhasePE.ohmRperperM"}
                                                    control={control}
                                                    rules={{ required: '必須項目です。入力してください。',
                                                        pattern:{
                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                            message: '数字を入力してください。'
                                                        },
                                                        validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            // type="text"etValue
                                                            size="small"
                                                            onBlur={handleChangeFormat}
                                                            onFocus={e => e.target.select()}
                                                            onChange={(e:any) => {
                                                                field.onChange(e);
                                                                handleChangeOhmRperper(e,RS_PHASE_PE);
                                                            }} 
                                                            inputProps={{
                                                                maxLength:7,
                                                                readOnly: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING
                                                            }}
                                                            InputProps={{
                                                                sx: { 
                                                                    height: 32,
                                                                    background:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white',
                                                                    color: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'grey':'black',
                                                                },
                                                                endAdornment:(
                                                                    <text style={{color:getValues("PhasePE.ohmRperperM") != getValues("PhasePE.ohmRperperA") ? "black" : "grey",fontSize:"30px",marginTop:"15px"}}>*</text>
                                                                )
                                                            }}
                                                            error={!!errors?.PhasePE?.ohmRperperM}
                                                            helperText={errors?.PhasePE?.ohmRperperM?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="160%">抵抗(mΩ):</Typography>
                                                <Controller
                                                    name="ohmR_PhasePE"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            disabled
                                                            fullWidth
                                                            type="text"
                                                            size="small"
                                                            onBlur={handleChangeFormat}
                                                            onFocus={e => e.target.select()}
                                                            style={{backgroundColor:"floralwhite"}}
                                                            InputProps={{
                                                                sx:{height:32}
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="160%">心線の直径(mm):</Typography>
                                                <Controller
                                                    name="PhasePE.diameterM"
                                                    control={control}
                                                    rules={{ required: '必須項目です。入力してください。',
                                                        pattern:{
                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                            message: '数字を入力してください。'
                                                        },
                                                        validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            // type="text"
                                                            size="small"
                                                            onBlur={handleChangeFormat}
                                                            onFocus={e => e.target.select()}
                                                            onChange={(e:any)=>{
                                                                field.onChange(e);
                                                                handleChangeDiameter(e,RS_PHASE_PE);
                                                            }}
                                                            inputProps={{
                                                                maxLength:5,
                                                                readOnly: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING
                                                            }}
                                                            InputProps={{
                                                                sx: { 
                                                                    height: 32,
                                                                    background:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white',
                                                                    color: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'grey':'black',
                                                                },
                                                                endAdornment:(
                                                                    <text style={{color:getValues("PhasePE.diameterM") != getValues("PhasePE.diameterA") ? "black" : "grey",fontSize:"30px",marginTop:"15px"}}>*</text>
                                                                )
                                                            }}
                                                            error={!!errors?.PhasePE?.diameterM}
                                                            helperText={errors?.PhasePE?.diameterM?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="160%">絶縁皮膜厚さ(mm):</Typography>
                                                <Controller
                                                    name="PhasePE.thickNessM"
                                                    control={control}
                                                    rules={{ required: '必須項目です。入力してください。',
                                                        pattern:{
                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                            message: '数字を入力してください。'
                                                        },
                                                        validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            defaultValue={0}
                                                            fullWidth
                                                            // type="text"
                                                            size="small"
                                                            onBlur={handleChangeFormat}
                                                            onFocus={e => e.target.select()}
                                                            onChange={(e:any)=> {
                                                                field.onChange(e);
                                                                handleChangeThickNess(e,RS_PHASE_PE);
                                                            }}
                                                            inputProps={{
                                                                maxLength:4,
                                                                readOnly: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING
                                                            }}
                                                            InputProps={{
                                                                sx: { 
                                                                    height: 32,
                                                                    background:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white',
                                                                    color: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'grey':'black',
                                                                },
                                                                endAdornment:(
                                                                    <text style={{color:getValues("PhasePE.thickNessM") != getValues("PhasePE.thickNessA") ? "black" : "grey",fontSize:"30px",marginTop:"15px"}}>*</text>
                                                                )
                                                            }}
                                                            error={!!errors?.PhasePE?.thickNessM}
                                                            helperText={errors?.PhasePE?.thickNessM?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="160%">相間の中心間距離(mm):</Typography>
                                                <Controller
                                                    name="PhasePE.distanceM"
                                                    control={control}
                                                    rules={{ required: '必須項目です。入力してください。',
                                                        pattern:{
                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                            message: '数字を入力してください。'
                                                        },
                                                        validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            // type="text"
                                                            size="small"
                                                            onBlur={handleChangeFormat}
                                                            onFocus={e => e.target.select()}
                                                            onChange={(e:any) => {
                                                                field.onChange(e);
                                                                handleChangeDistanceM(e,RS_PHASE_PE);
                                                            }}
                                                            inputProps={{
                                                                maxLength:5,
                                                                readOnly: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING
                                                            }}
                                                            InputProps={{
                                                                sx: { 
                                                                    height: 32,
                                                                    background:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white',
                                                                    color: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'grey':'black',
                                                                },
                                                                endAdornment:(
                                                                    <text style={{color:getValues("PhasePE.distanceM") != getValues("PhasePE.distanceA") ? "black" : "grey",fontSize:"30px",marginTop:"15px"}}>*</text>
                                                                )
                                                            }}
                                                            error={!!errors?.PhasePE?.distanceM}
                                                            helperText={errors?.PhasePE?.distanceM?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="160%">1心線の1m当りのリアクタンス(mΩ/m/1心線)：</Typography>
                                                <Controller
                                                    name="PhaseN.ohmXperperM"
                                                    control={control}
                                                    rules={{ required: '必須項目です。入力してください。',
                                                        pattern:{
                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                            message: '数字を入力してください。'
                                                        },
                                                        validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            defaultValue={0}
                                                            fullWidth
                                                            // type="text"
                                                            size="small"
                                                            onBlur={handleChangeFormat}
                                                            onFocus={e => e.target.select()}
                                                            onChange={(e:any)=> {
                                                                field.onChange(e);
                                                                handleChangeOhmX(e,RS_PHASE_PE);
                                                            }}
                                                            inputProps={{
                                                                maxLength:7,
                                                                readOnly: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING
                                                            }}
                                                            InputProps={{
                                                                sx: { 
                                                                    height: 32,
                                                                    background:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white',
                                                                    color: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'grey':'black',
                                                                },
                                                                endAdornment:(
                                                                    <text style={{color:getValues("PhasePE.ohmXperperM") != getValues("PhasePE.ohmXperperA") ? "black" : "grey",fontSize:"30px",marginTop:"15px"}}>*</text>
                                                                )
                                                            }}
                                                            error={!!errors?.PhasePE?.ohmXperperM}
                                                            helperText={errors?.PhasePE?.ohmXperperM?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="160%">リアクタンス(mΩ):</Typography>
                                                <Controller
                                                    name="ohmX_PhasePE"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            disabled
                                                            fullWidth
                                                            type="text"
                                                            size="small"
                                                            onBlur={handleChangeFormat}
                                                            onFocus={e => e.target.select()}
                                                            style={{backgroundColor:"floralwhite"}}
                                                            InputProps={{
                                                                sx:{height:32},
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                        </Stack>
                                    </fieldset>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={7}>
                                    <fieldset style={fieldsetStyle}>
                                        <legend><Typography variant="body2">許容電流</Typography></legend>
                                        <Stack spacing={0.3}>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="160%">基本通電許容電流(A):</Typography>
                                                <Controller
                                                    name="PhasePE.basicCapacityM"
                                                    control={control}
                                                    rules={{ required: '必須項目です。入力してください。',
                                                        pattern:{
                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                            message: '数字を入力してください。'
                                                        },
                                                        validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            // type="text"
                                                            size="small"
                                                            onBlur={handleChangeFormat}
                                                            onFocus={e => e.target.select()}
                                                            onChange={(e:any)=> {
                                                                field.onChange(e);
                                                                handleChangeBasicCapacity(e,RS_PHASE_PE);
                                                            }}
                                                            inputProps={{
                                                                maxLength:7,
                                                                readOnly: m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING
                                                            }}
                                                            InputProps={{
                                                                sx: { 
                                                                    height: 32,
                                                                    background:m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING?'floralwhite':'white',
                                                                    color: m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING?'grey':'black',
                                                                },
                                                                endAdornment:(
                                                                    <text style={{color:getValues("PhasePE.basicCapacityM") != getValues("PhasePE.basicCapacityA") ? "black" : "grey",fontSize:"30px",marginTop:"15px"}}>*</text>
                                                                )
                                                            }}
                                                            error={!!errors?.PhasePE?.basicCapacityM}
                                                            helperText={errors?.PhasePE?.basicCapacityM?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="160%">周囲温度補正係数:</Typography>
                                                <Controller
                                                    name="PhasePE.tempk2M"
                                                    control={control}
                                                    rules={{ required: '必須項目です。入力してください。',
                                                        pattern:{
                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                            message: '数字を入力してください。'
                                                        },
                                                        validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            // type="text"
                                                            size="small"
                                                            onBlur={handleChangeFormat}
                                                            onFocus={e => e.target.select()}
                                                            onChange={(e:any)=> {
                                                                field.onChange(e);
                                                                handleChangeTempk2M(e,RS_PHASE_PE);
                                                            }}
                                                            inputProps={{
                                                                maxLength:6,
                                                                readOnly: m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING
                                                            }}
                                                            InputProps={{
                                                                sx: { 
                                                                    height: 32,
                                                                    background:m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING?'floralwhite':'white',
                                                                    color: m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING?'grey':'black',
                                                                },
                                                                endAdornment:(
                                                                    <text style={{color:getValues("PhasePE.tempk2M") != getValues("PhasePE.tempk2A") ? "black" : "grey",fontSize:"30px",marginTop:"15px"}}>*</text>
                                                                )
                                                            }}
                                                            error={!!errors?.PhasePE?.tempk2M}
                                                            helperText={errors?.PhasePE?.tempk2M?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="160%">複数集合補正係数:</Typography>
                                                <Controller
                                                    name="PhasePE.reductionFactorM"
                                                    control={control}
                                                    rules={{ required: '必須項目です。入力してください。',
                                                        pattern:{
                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                            message: '数字を入力してください。'
                                                        },
                                                        validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            // type="text"
                                                            size="small"
                                                            onBlur={handleChangeFormat}
                                                            onFocus={e => e.target.select()}
                                                            onChange={(e:any)=> {
                                                                field.onChange(e);
                                                                handleChangeReductionFactorM(e,RS_PHASE_PE);
                                                            }}
                                                            inputProps={{
                                                                maxLength:4,
                                                                readOnly: m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING
                                                            }}
                                                            InputProps={{
                                                                sx: { 
                                                                    height: 32,
                                                                    background:m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING?'floralwhite':'white',
                                                                    color: m_bModePM || modeViewOnly || processMode > MS_MODE_DRAWING?'grey':'black',
                                                                },
                                                                endAdornment:(
                                                                    <text style={{color:getValues("PhasePE.reductionFactorM") != getValues("PhasePE.reductionFactorA") ? "black" : "grey",fontSize:"30px",marginTop:"15px"}}>*</text>
                                                                )
                                                            }}
                                                            error={!!errors?.PhasePE?.reductionFactorM}
                                                            helperText={errors?.PhasePE?.reductionFactorM?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="160%">通電許容電流(A):</Typography>
                                                <Controller
                                                    name="capacity_PhasePE"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                        {...field}
                                                        disabled={true}
                                                        defaultValue={0}
                                                        fullWidth
                                                        type="number"
                                                        size="small"
                                                        onBlur={handleChangeFormat}
                                                        onFocus={e => e.target.select()}
                                                        style={{backgroundColor:"floralwhite"}}
                                                        InputProps={{
                                                            sx:{height:32}
                                                        }}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                        </Stack>
                                    </fieldset>
                                </Grid>
                            </Grid>
                            </TabPanel>
                        }

                        {/* tab 6 - 電圧降下 */}
                    {getValues("downTransKind") !== Constant.MS_TRANS1 &&
                        <TabPanel value={selectTab} index={ isOpenTabPhasePE ? 5 : isOpenTabPhaseN ? 4 : 3}>
                        <Grid container my={1}>
                                <Grid item xs={10}>
                                    <Controller
                                            name="dropMotor"
                                            control={control}
                                            render={({ field }: any) => (
                                                <FormControlLabel
                                                    {...field}
                                                    disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}
                                                    checked={field.value}
                                                    label={<Typography variant="body2" color={m_bModePM || processMode > MS_MODE_CHECKING?'grey':'black'}>電動機始動時の電圧降下を計算</Typography>}
                                                    name='dropMotor'
                                                    onChange={(e:any) => {
                                                        field.onChange(e);
                                                    }}
                                                    control={<Checkbox style={checkboxStyles} />}
                                                    // disabled={currentTabId === true? false:(setIsDisplayTabConnectTran1 || setIsDisplayTabConnectTranscott)}
                                                
                                                />
                                            )}
                                        />
                                </Grid>
                                <Grid item xs={10}>
                                    <Controller
                                            name="dropRegular"
                                            control={control}
                                            render={({ field }: any) => (
                                                <FormControlLabel
                                                    {...field}
                                                    disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}
                                                    checked={field.value}
                                                    label={<Typography variant="body2" color={m_bModePM || processMode > MS_MODE_CHECKING?'grey':'black'}>定常時の電圧降下を計算</Typography>}
                                                    name='dropRegular'
                                                    onChange={(e:any)=>{
                                                        field.onChange(e);
                                                        setCheckedDropRegular(e.target.checked);
                                                    }}
                                                    control={<Checkbox style={checkboxStyles} />}
                                                />
                                            )}
                                        />
                                </Grid>
                                <Grid container>
                                    <Grid item xs={7.5}>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                            <Typography variant="body2" width="90%" ml={5}>負荷容量 (VA):</Typography>  
                                                <Controller
                                                    name="calcPoint0.loadCapacity"
                                                    control={control}
                                                    rules={{
                                                            pattern:{
                                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                                message: '数字を入力してください。'
                                                            },
                                                            validate: value => value >= 0 || '数字 > 0 を入力してください。' ,
                                                        }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type="text"
                                                            size="small"
                                                            onBlur={handleChangeFormat}
                                                            onFocus={e => e.target.select()}
                                                            disabled={m_bModePM || !isCheckedDropRegular || processMode > MS_MODE_CHECKING}
                                                            style={{color:'black'}}
                                                            onChange={(e:any)=>
                                                            {
                                                                field.onChange(e);
                                                                handleChangeLoadCapacity(e,"calcPoint0");
                                                            }}
                                                            InputLabelProps={{ style: { color: 'black' }}}
                                                            InputProps={{ sx: { height: 32,backgroundColor:m_bModePM || modeViewOnly || !isCheckedDropRegular || processMode > MS_MODE_CHECKING?"floralwhite":"white",color: modeViewOnly?'grey':'black' } }}
                                                            inputProps={{maxlength:7, readOnly: modeViewOnly}}
                                                            error={!!errors?.calcPoint0?.loadCapacity}
                                                            helperText={errors?.calcPoint0?.loadCapacity?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                    </Grid>
                                    <Grid item xs ={7.5}>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                            <Typography variant="body2" width="90%" ml={5}>負荷電流 IL(A):</Typography>  
                                            <Controller
                                                name="calcPoint0.loadCurrent"
                                                control={control}
                                                rules={{
                                                        pattern:{
                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                            message: '数字を入力してください。'
                                                        },
                                                        validate: value => value >= 0 || '数字 > 0 を入力してください。' ,
                                                    }}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        onBlur={handleChangeFormat}
                                                        onFocus={e => e.target.select()}
                                                        disabled={m_bModePM || !isCheckedDropRegular || processMode > MS_MODE_CHECKING}
                                                        style={{color:'black'}}
                                                        onChange={(e:any)=>{field.onChange(e);handleChangeLoadCurrent(e,"calcPoint0")}}
                                                        InputLabelProps={{ style: { color: 'black' }}}
                                                        InputProps={{ sx: { height: 32,backgroundColor:m_bModePM || modeViewOnly || !isCheckedDropRegular || processMode > MS_MODE_CHECKING?"floralwhite":"white", color: modeViewOnly?'grey':'black' } }}
                                                        inputProps={{maxlength:7, readOnly: modeViewOnly}}
                                                        error={!!errors?.calcPoint0?.loadCurrent}
                                                        helperText={errors?.calcPoint0?.loadCurrent?.message}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                    </Grid>
                                </Grid>
                                <Grid container ml={6.5}>
                                    <fieldset style={{border:"1px solid #AAAA",width:"53%"}}>
                                        <legend><Typography variant="body2">負荷電流方向</Typography>  </legend>
                                        <Controller
                                                name="currentTopLeft"
                                                control={control}
                                                render={({ field }) => (
                                                        <RadioGroup {...field}>
                                                            <FormControlLabel
                                                                value={true} 
                                                                disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}
                                                                control={<Radio style={radioStyles} />}
                                                                label={<Typography variant="body2" color={m_bModePM || processMode > MS_MODE_CHECKING?'grey':'black'}>上→下 or 左→右</Typography>}
                                                            />
                                                            <FormControlLabel
                                                                value={false}
                                                                disabled={m_bModePM || modeViewOnly}
                                                                control={<Radio style={radioStyles} />}
                                                                label={<Typography variant="body2" color={m_bModePM || processMode > MS_MODE_CHECKING?'grey':'black'}>下→上 or 右→左</Typography>}
                                                            />
                                                        </RadioGroup>
                                                )}
                                            />
                                        </fieldset>
                                        <Stack ml={1.2}>
                                            <Typography color="common.white">nothing</Typography>
                                            <Typography color="common.white">nothing</Typography>
                                            <Button variant="outlined" disabled={m_bModePM || modeViewOnly || !isCheckedDropRegular || processMode > MS_MODE_CHECKING}
                                                                        onClick={() => handleClearLoadValue("calcPoint0")}
                                                            >概算値</Button>
                                        </Stack>
                                </Grid>
                                <Grid container ml={3}>
                                    <fieldset style={{width:"76%",border:'1px solid #AAAA'}}>
                                        <legend>{<Typography variant="body2">回路の力率</Typography>}</legend>
                                        <Grid container>
                                            <Grid item xs={5}>
                                                <Controller
                                                    name="calcPoint0.isLead"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <RadioGroup {...field} row onChange ={(e) =>handleRadioPower(e,"calcPoint0")}> 
                                                            <FormControlLabel
                                                                value={true} 
                                                                control={<Radio style={radioStyles} />}
                                                                label={<Typography style={{color:m_bModePM || !isCheckedDropRegular || processMode > MS_MODE_CHECKING?"grey":"black"}} variant="body2">進み</Typography>}
                                                                disabled={m_bModePM || modeViewOnly || !isCheckedDropRegular || processMode > MS_MODE_CHECKING}
                                                            />
                                                            <FormControlLabel
                                                                value={false}
                                                                control={<Radio style={radioStyles} />}
                                                                label={<Typography style={{color:m_bModePM || !isCheckedDropRegular || processMode > MS_MODE_CHECKING?"grey":"black"}} variant="body2">遅れ</Typography>}
                                                                disabled={m_bModePM || modeViewOnly || !isCheckedDropRegular || processMode > MS_MODE_CHECKING}
                                                            />
                                                        </RadioGroup>
                                                    )}
                                                />
                                            </Grid>
                                            <Grid xs={1}/>
                                            <Grid xs={2.75} ml={1.4}>
                                                <Controller
                                                    name="calcPoint0.powerFactor"
                                                    control={control}
                                                    rules={{
                                                        pattern:{
                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                            message: '数字を入力してください。'
                                                        },
                                                        validate: value => value >= 0 && value <= 1 || '数字 0 ~ 1 を入力してください。' ,
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type="text"
                                                            size="small"
                                                            onBlur={handleChangeFormat}
                                                            onFocus={e => e.target.select()}
                                                            InputLabelProps={{ style: { color: 'black' }}}
                                                            onChange={(e:any)=>{
                                                                field.onChange(e);
                                                                handleChangePowerFactor(e,"calcPoint0")
                                                            }}
                                                            error={!!errors?.calcPoint0?.powerFactor}
                                                            helperText={errors?.calcPoint0?.powerFactor?.message}
                                                            disabled={m_bModePM || !isCheckedDropRegular || processMode > MS_MODE_CHECKING}
                                                            style={{backgroundColor:m_bModePM || modeViewOnly || !isCheckedDropRegular || processMode > MS_MODE_CHECKING?"floralwhite":"white", color: modeViewOnly?'grey':'black'}}
                                                            InputProps={{
                                                                sx:{height:32,backgroundColor: modeViewOnly?"floralwhite":"white", color: modeViewOnly?'grey':'black'},
                                                            }}
                                                            
                                                            inputProps={{
                                                                maxLength:6,
                                                                readOnly: modeViewOnly
                                                            }}
                                                            

                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={2.5} ml={1.64}>
                                                <Button style={{margin:"0"}} variant="outlined" disabled={m_bModePM || modeViewOnly || !isCheckedDropRegular || processMode > MS_MODE_CHECKING} onClick={() => handleClearFactorValue("calcPoint0")}
                                                    >概算値</Button>
                                            </Grid>
                                        </Grid>
                                    </fieldset>
                                </Grid>
                                <Grid container  mt={0.25}>
                                    <Grid container flexDirection={"row"} ml={6} alignItems={"center"}>
                                        <Grid item xs={4} >
                                            <Typography variant="body2" width="100%" >単体での電圧降下(V):</Typography>  
                                        </Grid>
                                        <Grid item xs={4} ml={-2.6}>
                                            <Controller
                                                name="voltageDrop1"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        onBlur={handleChangeFormat}
                                                        onFocus={e => e.target.select()}
                                                        onChange={(e:any)=>{field.onChange(e)}}
                                                        InputLabelProps={{ style: { color: 'black' }}}
                                                        inputProps={{
                                                            maxLength:6,
                                                        }}
                                                        //disabled={m_bModePM || !isCheckedDropRegular}
                                                        disabled ={true}
                                                        style={{backgroundColor:"floralwhite",width:"70%",marginLeft:'20%'}}
                                                        InputProps={{ sx: { height: 32 } }}

                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container flexDirection={"row"} ml={6} mt={0.25} alignItems={"center"}>
                                        <Grid item xs={4} >
                                            <Typography variant="body2" width="100%" >単体での電圧降下率(%):</Typography>  
                                        </Grid>
                                        <Grid item xs={4} ml={-2.6}>
                                            <Controller
                                                name="voltageDrop"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        onBlur={handleChangeFormat}
                                                        onFocus={e => e.target.select()}
                                                        onChange={(e:any)=>{field.onChange(e)}}
                                                        InputLabelProps={{ style: { color: 'black' }}}
                                                        inputProps={{
                                                            maxLength:6
                                                        }}
                                                        // disabled={m_bModePM || !isCheckedDropRegular}
                                                        disabled ={true}
                                                        style={{backgroundColor:"floralwhite",width:"70%",marginLeft:'20%'}}
                                                        InputProps={{ sx: { height: 32 } }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </TabPanel>
                    }

                        {/* tab 7 - 電圧降下 */}
                        {
                            (isModeSPhases|| getValues("downTransKind") === Constant.MS_TRANS1) &&
                            <>
                                <TabPanel value={selectTab} index={(!isModeSPhases && getValues("downTransKind") === Constant.MS_TRANS1)? 3 : 4}>
                                    <Grid container>
                                            <Grid item xs={10}>
                                                <Controller
                                                        name="dropMotor"
                                                        control={control}
                                                        render={({ field }: any) => (
                                                            <FormControlLabel
                                                                {...field}
                                                                disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}
                                                                checked={field.value}
                                                                label={<Typography variant="body2" color={m_bModePM || processMode > MS_MODE_CHECKING?'grey':'black'}>電動機始動時の電圧降下を計算</Typography>}
                                                                name='dropMotor'
                                                                onChange={(e:any) => {
                                                                    field.onChange(e);
                                                                }}
                                                                control={<Checkbox style={checkboxStyles} />}
                                                            />
                                                        )}
                                                    />
                                            </Grid>
                                            <Grid item xs={10}>
                                                <Controller
                                                        name="dropRegular"
                                                        control={control}
                                                        render={({ field }: any) => (
                                                            <FormControlLabel
                                                                {...field}
                                                                disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}
                                                                checked={field.value}
                                                                label={<Typography variant="body2" color={m_bModePM || processMode > MS_MODE_CHECKING?'grey':'black'}>定常時の電圧降下を計算</Typography>}
                                                                name='dropRegular'
                                                                onChange={(e:any)=>{
                                                                    field.onChange(e);
                                                                    setCheckedDropRegular(e.target.checked);
                                                                }}
                                                                control={<Checkbox style={checkboxStyles} />}
                                                            />
                                                        )}
                                                    />
                                            </Grid>
                                            <Grid container>
                                                <Grid item xs={7.5}>
                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                        <Typography variant="body2" width="90%" ml={5}>負荷容量 (VA):</Typography>  
                                                            <Controller
                                                                name="calcPoint1.loadCapacity"
                                                                control={control}
                                                                rules={{
                                                                pattern:{
                                                                    value: /^[0-9]*[.]?[0-9]+$/,
                                                                    message: '数字を入力してください。'
                                                                },
                                                                validate: value => value >= 0 || '数字 > 0 を入力してください。' ,
                                                            }}
                                                                render={({ field }) => (
                                                                    <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    type="text"
                                                                    size="small"
                                                                    disabled={m_bModePM || !isCheckedDropRegular}
                                                                    style={{backgroundColor:m_bModePM || modeViewOnly || !isCheckedDropRegular?"floralwhite":"white", color: modeViewOnly?'grey':'black'}}
                                                                    onChange={(e:any)=>
                                                                    {
                                                                        field.onChange(e);
                                                                        handleChangeLoadCapacity(e,"calcPoint1");
                                                                    }}
                                                                    InputLabelProps={{ style: { color: 'black' }}}
                                                                    InputProps={{ sx: { height: 32 } }}
                                                                    inputProps={{maxlength:7, readOnly: modeViewOnly}}
                                                                    error={!!errors?.calcPoint1?.loadCapacity}
                                                                    helperText={errors?.calcPoint1?.loadCapacity?.message}
                                                                    />
                                                                )}
                                                            />
                                                        </Stack>
                                                </Grid>
                                                <Grid item xs ={7.5}>
                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                        <Typography variant="body2" width="90%" ml={5}>負荷電流 IL(A):</Typography>  
                                                        <Controller
                                                            name="calcPoint1.loadCurrent"
                                                            control={control}
                                                            rules={{
                                                                pattern:{
                                                                    value: /^[0-9]*[.]?[0-9]+$/,
                                                                    message: '数字を入力してください。'
                                                                },
                                                                validate: value => value >= 0 || '数字 > 0 を入力してください。' ,
                                                            }}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    type="text"
                                                                    size="small"
                                                                    onBlur={handleChangeFormat}
                                                                    onFocus={e => e.target.select()}
                                                                    onChange={(e:any)=>{field.onChange(e);handleChangeLoadCurrent(e,"calcPoint1")}}
                                                                    disabled={m_bModePM || !isCheckedDropRegular}
                                                                    style={{color:"black"}}
                                                                    InputLabelProps={{ style: { color: 'black' }}}
                                                                    InputProps={{ sx: { height: 32,backgroundColor: m_bModePM|| !isCheckedDropRegular || modeViewOnly?"floralwhite":"white", color: m_bModePM|| !isCheckedDropRegular || modeViewOnly?'grey':'black'} }}
                                                                    inputProps={{maxlength:7, readOnly: modeViewOnly}}
                                                                    error={!!errors?.calcPoint1?.loadCurrent}
                                                                    helperText={errors?.calcPoint1?.loadCurrent?.message}
                                                                    />
                                                            )}
                                                        />
                                                    </Stack>
                                                </Grid>
                                            </Grid>
                                            <Grid container ml={6.5}>
                                                <fieldset style={{border:"1px solid #AAAA",width:"53%"}}>
                                                    <legend><Typography variant="body2">負荷電流方向</Typography></legend>
                                                    <Controller
                                                            name="currentTopLeft"
                                                            control={control}
                                                            rules={{ required: '必須項目です。入力してください。' }}
                                                            render={({ field }) => (
                                                                    <RadioGroup {...field}>
                                                                        <FormControlLabel
                                                                            value={true} 
                                                                            disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}
                                                                            control={<Radio style={radioStyles} />}
                                                                            label={<Typography variant="body2" color={m_bModePM || processMode > MS_MODE_CHECKING?'grey':'black'}>上→下 or 左→右</Typography>}
                                                                        />
                                                                        <FormControlLabel
                                                                            value={false}
                                                                            disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}
                                                                            control={<Radio style={radioStyles} />}
                                                                            label={<Typography variant="body2" color={m_bModePM || processMode > MS_MODE_CHECKING?'grey':'black'}>下→上 or 右→左</Typography>}
                                                                        />
                                                                    </RadioGroup>
                                                            )}
                                                        />
                                                    </fieldset>
                                                    <Stack ml={1.2}>
                                                        <Typography color="common.white">nothing</Typography>
                                                        <Typography color="common.white">nothing</Typography>
                                                        <Button variant="outlined" disabled={m_bModePM || modeViewOnly || !isCheckedDropRegular}
                                                                                    onClick={() => handleClearLoadValue("calcPoint1")}
                                                                        >概算値</Button>
                                                    </Stack>
                                            </Grid>
                                            <Grid container ml={3}>
                                                <fieldset style={{width:"76%",border:'1px solid #AAAA'}}>
                                                    <legend>{<Typography variant="body2">回路の力率</Typography>}</legend>
                                                    <Grid container>
                                                        <Grid item xs={5}>
                                                            <Controller
                                                                name="calcPoint1.isLead"
                                                                control={control}
                                                                rules={{ required: '必須項目です。入力してください。' }}
                                                                render={({ field }) => (
                                                                    <RadioGroup {...field} row onChange ={(e) =>handleRadioPower(e,"calcPoint1")}>
                                                                        <FormControlLabel
                                                                            value={true} 
                                                                            control={<Radio style={radioStyles} />}
                                                                            label={<Typography style={{color:m_bModePM || !isCheckedDropRegular?"grey":"black"}} variant="body2">進み</Typography>}
                                                                            disabled={m_bModePM || modeViewOnly || !isCheckedDropRegular}
                                                                        />
                                                                        <FormControlLabel
                                                                            value={false}
                                                                            control={<Radio style={radioStyles} />}
                                                                            label={<Typography style={{color:m_bModePM || !isCheckedDropRegular?"grey":"black"}} variant="body2">遅れ</Typography>}
                                                                            disabled={m_bModePM || modeViewOnly || !isCheckedDropRegular}
                                                                        />
                                                                    </RadioGroup>
                                                                )}
                                                            />
                                                        </Grid>
                                                        <Grid xs={1}/>
                                                        <Grid xs={2.75} ml={1.4}>
                                                            <Controller
                                                                name="calcPoint1.powerFactor"
                                                                control={control}
                                                                rules={{
                                                                    pattern:{
                                                                        value: /^[0-9]*[.]?[0-9]+$/,
                                                                        message: '数字を入力してください。'
                                                                    },
                                                                    validate: value => value >= 0 && value <= 1 || '数字 0 ~ 1 を入力してください。' ,
                                                                }}
                                                                render={({ field }) => (
                                                                    <TextField
                                                                        {...field}
                                                                        fullWidth
                                                                        type="text"
                                                                        size="small"
                                                                        onBlur={handleChangeFormat}
                                                                        onFocus={e => e.target.select()}
                                                                        onChange={(e:any)=>{field.onChange(e);handleChangePowerFactor(e,"calcPoint1")}}
                                                                        InputLabelProps={{ style: { color: 'black' }}}
                                                                        disabled={m_bModePM || !isCheckedDropRegular}
                                                                        style={{backgroundColor:m_bModePM || modeViewOnly || !isCheckedDropRegular?"floralwhite":"white",color: modeViewOnly?'grey':'black'}}
                                                                        inputProps={{
                                                                            maxLength:6,
                                                                            readOnly: modeViewOnly
                                                                        }}
                                                                        InputProps={{
                                                                            sx:{height:32},
                                                                        }}
                                                                        error={!!errors?.calcPoint1?.powerFactor}
                                                                        helperText={errors?.calcPoint1?.powerFactor?.message}
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={2.5} ml={1.64}>
                                                            <Button style={{margin:"0"}} variant="outlined" disabled={m_bModePM || modeViewOnly || !isCheckedDropRegular} onClick={() => handleClearFactorValue("calcPoint1")}
                                                                >概算値</Button>
                                                        </Grid>
                                                    </Grid>
                                                </fieldset>
                                            </Grid>
                                            <Grid container  mt={0.25}>
                                                <Grid container flexDirection={"row"} ml={6} alignItems={"center"}>
                                                    <Grid item xs={4} >
                                                        <Typography variant="body2" width="100%" >単体での電圧降下(V):</Typography>  
                                                    </Grid>
                                                    <Grid item xs={4} ml={-2.6}>
                                                        <Controller
                                                            name="voltageDropPoint1"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    type="text"
                                                                    size="small"
                                                                    onBlur={handleChangeFormat}
                                                                    onFocus={e => e.target.select()}
                                                                    onChange={(e:any)=>{field.onChange(e)}}
                                                                    InputLabelProps={{ style: { color: 'black' }}}
                                                                    inputProps={{
                                                                        maxLength:6,
                                                                    }}
                                                                    // disabled={m_bModePM || !isCheckedDropRegular}
                                                                    disabled ={true}
                                                                    style={{backgroundColor:"floralwhite",width:"70%",marginLeft:'20%'}}
                                                                    InputProps={{ sx: { height: 32 } }}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid container flexDirection={"row"} ml={6} mt={0.25} alignItems={"center"}>
                                                    <Grid item xs={4} >
                                                        <Typography variant="body2" width="100%" >単体での電圧降下率(%):</Typography>  
                                                    </Grid>
                                                    <Grid item xs={4} ml={-2.6}>
                                                        <Controller
                                                            name="voltageDropPoint"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    type="text"
                                                                    size="small"
                                                                    onBlur={handleChangeFormat}
                                                                    onFocus={e => e.target.select()}
                                                                    onChange={(e:any)=>{field.onChange(e)}}
                                                                    InputLabelProps={{ style: { color: 'black' }}}
                                                                    inputProps={{
                                                                        maxLength:6
                                                                    }}
                                                                    // disabled={m_bModePM || !isCheckedDropRegular}
                                                                    disabled ={true}
                                                                    style={{backgroundColor:"floralwhite",width:"70%",marginLeft:'20%'}}
                                                                    InputProps={{ sx: { height: 32 } }}
                                                                        

                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                </TabPanel>
                                {
                                    isModeSPhases && 
                                    <>
                                        {/* tab7 潮流解析 */}
                                        <TabPanel value={selectTab} index={5}>
                                             <Stack spacing={0.5} style={{ width: '100%',marginTop:'10px' }}>
                                                <fieldset style={{ width: '70%',border:"1px solid #AAAA"}}>
                                                    <legend><Typography variant="body2"> 対中性点アド ミ夕ンス(基準容量に対する pu値)</Typography></legend>
                                                    <Grid container item xs={10}>
                                                            <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                                <Typography variant="body2" width="100%">サセプ夕ンス B(pu値):</Typography>
                                                                        <Controller
                                                                            name="flowSusceptance"
                                                                            control={control}
                                                                            render={({ field }) => (
                                                                                <TextField
                                                                                    {...field}
                                                                                    fullWidth
                                                                                    type="text"
                                                                                    size="small"
                                                                                    onBlur={handleChangeFormat}
                                                                                    onFocus={e => e.target.select()}
                                                                                    InputLabelProps={{ style: { color: 'black' }}}
                                                                                    error={!!errors?.flowSusceptance}
                                                                                    helperText={errors?.flowSusceptance?.message}
                                                                                    inputProps={{maxLength:6, readOnly: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}}
                                                                                    InputProps={{ 
                                                                                        sx: { 
                                                                                            height: 32,
                                                                                            background:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white',
                                                                                            color: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'grey':'black',
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            )}
                                                                        />
                                                            </Stack>
                                                    </Grid>
                                                </fieldset>
                                                <Stack>
                                                    <Grid container>
                                                        <Grid item xs={3.8}/>
                                                        <Grid xs={6}>
                                                            <Controller
                                                                name="viewFlowResult"        
                                                                control={control}
                                                                render={({ field }: any) => (
                                                                    <FormControlLabel 
                                                                        {...field}
                                                                        disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING} 
                                                                        checked={field.value}
                                                                        label={<Typography variant="body2" width="100%" color={m_bModePM || processMode > MS_MODE_CHECKING?'grey':'black'}>潮流解析結果を表示</Typography>}
                                                                        name='viewFlowResult'
                                                                        onClick={handleChangeFormat}
                                                                        control={<Checkbox style={checkboxStyle} />} 
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Stack>
                                            </Stack>
                                        </TabPanel>

                                        {/* tab 8  シンボル */}
                                        <TabPanel value={selectTab} index={6}>
                                            <Stack mt={1} width={'75%'}>
                                                <fieldset style={{border:'1px solid #AAAA'}}>
                                                    <legend ><Typography variant="body2">単線結線図で表示するシンボルの選択</Typography></legend>
                                                    <Grid container>
                                                        <Grid item xs={5}>
                                                            <Controller
                                                                name='exchangeNumber'
                                                                control={control}
                                                                render={({ field }) => (
                                                                    <RadioGroup {...field}>
                                                                        <FormControlLabel 
                                                                            value={WIRE_SYMBOL_NORMAL} 
                                                                            disabled={m_bModePM || modeViewOnly}
                                                                            control={<Radio size='small'/>} 
                                                                            label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>ケーブル</Typography>}
                                                                        />
                                                                        <FormControlLabel 
                                                                            value={WIRE_SYMBOL_CABLE}
                                                                            disabled={m_bModePM || modeViewOnly}
                                                                            control={<Radio size='small'/>} 
                                                                            label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>ワイヤ</Typography>}
                                                                        />
                                                                        
                                                                    </RadioGroup>
                                                                )}
                                                            />
                                                        </Grid>
                                                        
                                                    </Grid>
                                                </fieldset>
                                            </Stack>
                                        </TabPanel>
                                    </>
                                }
                            </>
                        }
                        {
                            !isModeSPhases &&
                            <>
                                {/* tab7 潮流解析 */}
                                {userRole != Constant.ROLE_LP &&
                                <TabPanel value={selectTab} index={isOpenTabPhasePE ? 6 : isOpenTabPhaseN ? 5 : 4}>
                                    <Stack spacing={0.5} style={{ width: '100%',marginTop:'10px' }}>
                                                <fieldset style={{ width: '70%',border:"1px solid #AAAA"}}>
                                                    <legend><Typography variant="body2"> 対中性点アド ミ夕ンス(基準容量に対する pu値)</Typography></legend>
                                                    <Grid container item xs={10}>
                                                            <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                                <Typography variant="body2" width="100%">サセプ夕ンス B(pu値):</Typography>
                                                                        <Controller
                                                                            name="flowSusceptance"
                                                                            control={control}
                                                                            render={({ field }) => (
                                                                                <TextField
                                                                                    {...field}
                                                                                    fullWidth
                                                                                    type="text"
                                                                                    size="small"
                                                                                    onBlur={handleChangeFormat}
                                                                                    onFocus={e => e.target.select()}
                                                                                    InputLabelProps={{ style: { color: 'black' }}}
                                                                                    error={!!errors?.flowSusceptance}
                                                                                    helperText={errors?.flowSusceptance?.message}
                                                                                    inputProps={{maxLength:6, readOnly: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}}
                                                                                    InputProps={{ 
                                                                                        sx: { 
                                                                                            height: 32,
                                                                                            background:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white',
                                                                                            color: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'grey':'black',
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            )}
                                                                        />
                                                            </Stack>
                                                    </Grid>
                                                </fieldset>
                                                
                                                <Stack>
                                                    <Grid container>
                                                        <Grid item xs={3.8}/>
                                                        <Grid xs={6}>
                                                            <Controller
                                                                name="viewFlowResult"        
                                                                control={control}
                                                                render={({ field }: any) => (
                                                                    <FormControlLabel 
                                                                        {...field} 
                                                                        disabled={m_bModePM || modeViewOnly}
                                                                        checked={field.value}
                                                                        label={<Typography variant="body2" width="100%" color={m_bModePM?'grey':'black'}>潮流解析結果を表示</Typography>}
                                                                        name='viewFlowResult'
                                                                        onClick={handleChangeFormat}
                                                                        control={<Checkbox style={checkboxStyle} />} 
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Stack>
                                            </Stack>
                                </TabPanel>
                                }
                                {/* tab 8  シンボル */}
                                <TabPanel 
                                    value={selectTab}
                                    index={(isOpenTabPhasePE ? 7 : isOpenTabPhaseN ? 6 : 5) - (userRole != Constant.ROLE_LP ? 0 : 1)}>
                                    <Stack mt={1} width={'75%'}>
                                        <fieldset style={{border:'1px solid #AAAA'}}>
                                            <legend ><Typography variant="body2">単線結線図で表示するシンボルの選択</Typography></legend>
                                            <Grid container>
                                                <Grid item xs={5}>
                                                    <Controller
                                                        name='exchangeNumber'
                                                        control={control}
                                                        render={({ field }) => (
                                                            <RadioGroup {...field}>
                                                                <FormControlLabel 
                                                                    value={WIRE_SYMBOL_NORMAL}
                                                                    disabled={m_bModePM || modeViewOnly}
                                                                    control={<Radio size='small'/>} 
                                                                    label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>ケーブル</Typography>}
                                                                />
                                                                <FormControlLabel 
                                                                    value={WIRE_SYMBOL_CABLE}
                                                                    disabled={m_bModePM || modeViewOnly}
                                                                    control={<Radio size='small'/>} 
                                                                    label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>ワイヤ</Typography>}
                                                                />
                                                                
                                                            </RadioGroup>
                                                        )}
                                                    />
                                                </Grid>
                                                
                                            </Grid>
                                        </fieldset>
                                    </Stack>
                                </TabPanel>
                            </>
                        }
                        
                    </div>
                    <Stack direction='row' justifyContent="flex-end" spacing={2} sx={{ mt: 2 }}>
                        <Button variant="contained" type="submit" disabled={modeViewOnly}>OK</Button>
                        <Button color="inherit" variant="contained" onClick={handleCancel}>
                            キャンセル
                        </Button>
                    </Stack>
                </form>
        </div>
        </>
    )
}

export default MsWireEditDialog;