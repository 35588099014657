import { Box, Button, Checkbox, FormControl, FormControlLabel, Grid, Stack, Tab, Tabs, TextField, Typography } from "@mui/material";
import { useMemo, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { HarmonicRegisterModel, InfoCircuit, MsINVModel } from '../../models/Index';
import TextFieldOptions from "../common/TextFieldOptions";
import HarmonicRegisterDialog from "./HarmonicRegisterDialog";
import AlertDialog from "../common/AlertDialog";
import { AgGridReact } from "ag-grid-react";
import NoRowsOverlay, { NoRowsMessage } from "../common/NoRowsOverlay";
import { AG_GRID_LOCALE_JP } from "../../themes/localeJP";
import * as Constant from "../../models/Constants";

export type MsINVEditDialogFormProps = {
    data: MsINVModel;
    onOK: (data: any) => void;
    onCancel: () => void;
    // harmonic
    changeCombodata: any,
    controlId: string,
    defaultHarmonicInfo: any,
    onHarmonic: () => void;
    onChangeComboData: (data : any, controlId : string) => void;
    setChangeComboData: (a : any) => void;
    getHarmonicByID:(data:any) =>void;
    // deleteHarmonicByID:(data:any) =>void;
    // updateHarmonicByID: (data:any) => void;
    // addHarmonic: (data:any) => void;
    userRole:string;
    m_bModePM: boolean;
    infoCircuit: InfoCircuit;
    modeViewOnly:boolean;
};

function a11yProps(index: number) {
    return {
        id: `tab-${index}`,
        "aria-controls": `tabpanel-${index}`,
    };
}

//Tabs------------------
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

const radioStyles = { padding: '4px 4px 4px 8px' }

export const MsINVEditDialog = (props: MsINVEditDialogFormProps) => {
    const { userRole, controlId, defaultHarmonicInfo, changeCombodata, data, onOK, onCancel, onHarmonic, onChangeComboData, setChangeComboData,getHarmonicByID,m_bModePM, modeViewOnly,infoCircuit
        // deleteHarmonicByID,
        // updateHarmonicByID,
        // addHarmonic
    } = props;

    // 初期値設定
    const initialValue: MsINVModel = {
        ...data
    }

    const { control, handleSubmit, formState: { errors }, setValue, getValues,setError } = useForm<MsINVModel>({
        mode: 'all',
        criteriaMode: "all",
        defaultValues: initialValue,
    });

    const [selectTab, setSelectTab] = useState(0);
    const [isDisableOKBtn, setIsDisableOKBtn] = useState(false);
    const [makeDataInput, setMakeDataInput] = useState(initialValue.makeDataInput); // ModePM

    const [listHarmonic, setListHarmonic] = useState<HarmonicRegisterModel[]>(data.harmonicList?data.harmonicList:[]);
    const [selectedRow, setSetlectedRow] = useState<Number>();
    const [selectedHarmonicDialogId, setSelectedHarmonicDialogId] = useState<Number>();
    const [isOpenedDialogEditHarmonic, setIsOpenedDialogEditHarmonic] = useState<boolean>(false);
    const [editDataHarmonicModel, setEditDataHarmonicModel] = useState<HarmonicRegisterModel>();
    const [isOpenedDialogAddHarmonic, setIsOpenedDialogAddHarmonic] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
    const [selectItem, setSelectItem] = useState<any>(null);

    // const [isDeleteHarmonic, setIsDeleteHarmonic] = useState(false)
    // const [isDeleteHarmonicData, setIsDeleteHarmonicData] = useState<HarmonicRegisterModel[]>()

    // const [isUpdateHarmonic, setIsUpdateHarmonic] = useState(false)
    // const [isUpdateHarmonicData, setIsUpdateHarmonicData] = useState<HarmonicRegisterModel[]>()   

    // const [isAddHarmonic, setIsAddHarmonic] = useState(false)
    // const [isAddHarmonicData, setIsAddHarmonicData] = useState<HarmonicRegisterModel[]>()

     //#region GRID DEFINITIONS
     const gridRef = useRef() as any;
     const noRowsOverlayComponentParams = useMemo(() => {
         return {
             noRowsMessageFunc: () => NoRowsMessage,
         };
     }, []);

     const noRowsOverlayComponent = useMemo(() => {
         return NoRowsOverlay;
     }, []);

     // 全列に共通の定義
     const defaultColDef = {
         sortable: true, // ソート可
         editable: false, // 編集不可
         resizable: true,
         suppressMovable: true,  // 列の移動を無効にする
     };

     // 列タイプの定義
     const columnTypes = {
         nonEditableColumn: { editable: false }, // 編集不可
     };
 // 列の個別定義
 const columnDefs = useMemo(() => [
    {
        headerName: "機器名称",
        field: "name",
        rowDrag: false,
        minWidth: 100,
        flex: 1.5,
    }
], [])
    //#region tab 1
    const handleChangeMakeDataInput = (e: any) => {
        setValue(e.target.name, e.target.checked);
        setMakeDataInput(e.target.checked);
    }

    // Submitイベント
    const handleEditSubmit = (value: MsINVModel) => {
        const request = createRequestData(value);
        onOK(request);
        // if(isDeleteHarmonic){
        //     deleteHarmonicByID(isDeleteHarmonicData)
        // }
        // if(isUpdateHarmonic){
        //     updateHarmonicByID(isUpdateHarmonicData)
        // }
        // if(isAddHarmonic){
        //     addHarmonic(isAddHarmonicData)
        // }
    }

    // FormデータからRequestデータを作成する
    const createRequestData = (formValue: MsINVModel) => {
        let request = {
           ...formValue
        } as MsINVModel;
        return request;
    }

    // キャンセル
    const handleCancel = () => {
        onCancel();
    };

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        if (newValue >= 0) {
            if (getValues('refNo') == "" ||getValues('refNo') == null || getValues('refNo') == undefined ) {
                setError("refNo",{ type: 'custom', message: '必須項目です。入力してください。' });
            }
            else if (isNaN(Number(getValues('voltage'))) || Number(getValues('voltage')) <= 0 || !/^[0-9\b]+$/.test(getValues('voltage').toString())) {
                setError("voltage",{ type: 'custom', message: '整数 > 0 を入力してください。' });
            }
            else{
                setSelectTab(newValue);
            }
        }
        else{
        setSelectTab(newValue);
        }
    };

    const handleInput = (e: any) => {
        setValue(e.target.name, e.target.value,{shouldValidate:true})
        const isInteger = /^\d*$/.test(getValues("voltage").toString())
        if(getValues("voltage") > 0 && isInteger){
            setIsDisableOKBtn(false);
        }
        else{
            setIsDisableOKBtn(true);
        }
    }

    const handleChangeFormat = (e: any) => {
        setValue(e.target.name, e.target.value,{shouldValidate:true});
    }
    //#endregion tab 1
    
    // #region Tab2
    const getlastIdHarmonicRegisterModel = () => {
        return initialValue.harmonicList?.length > 0 ?
            Math.max(...initialValue.harmonicList.map((item: any) => item.hamorItemID)) : 0;
    }

    const [lastIdHamornicNewFormModel, setLastIdHamornicNewFormModel] = useState(getlastIdHarmonicRegisterModel());

    const handleOpenAddDialogHarmonicModel = () => {
        onHarmonic()
        setIsOpenedDialogAddHarmonic(true);
    }

    // Submitイベント
    const handleAddHarmonicModel = (data:any) => {
        setIsOpenedDialogAddHarmonic(false);
        const newList = [...listHarmonic];
        newList.push(data);
        setListHarmonic(newList);
        setValue("harmonicList",newList);
        setLastIdHamornicNewFormModel(data.hamorItemID);
        // setIsAddHarmonic(true)
        // setIsAddHarmonicData(newList)
    }

    // キャンセル
    const handleCancelAddHarmonicModel = () => {
        setIsOpenedDialogAddHarmonic(false);
    }

    const handleClickItemInListItem = (data:any) => {
        // if (selectedRow === index) {
        //     setSetlectedRow(undefined);
        //     setSelectedHarmonicDialogId(undefined);
        // }
        // else {
        //     setSetlectedRow(index);
        //     setSelectedHarmonicDialogId(val);
        // }
    }

    const handleOpenEditDialogHarmonicModelById = (data:any) => {
        setIsOpenedDialogEditHarmonic(true);
        setEditDataHarmonicModel(selectItem);
        getHarmonicByID(selectItem)
        // setIsUpdateHarmonic(true)
    }
    
    const handleOpenEditDialogHarmonicModel = () => {
        setIsOpenedDialogEditHarmonic(true);
        // setEditDataHarmonicModel([...listHarmonic].find(x => x.hamorItemID === selectedHarmonicDialogId));
        // getHarmonicByID([...listHarmonic].find(x => x.hamorItemID === selectedHarmonicDialogId))
        setEditDataHarmonicModel(selectItem);
        getHarmonicByID(selectItem)
    }

    // Submitイベント
    const handleEditHamornicNewModel = (data: HarmonicRegisterModel) => {
        setIsOpenedDialogEditHarmonic(false);
        const arrOptions = [...listHarmonic];
        const IndexOfHamornicNew = arrOptions.findIndex(item => item.hamorItemID === data.hamorItemID);
        IndexOfHamornicNew !== -1 && (arrOptions[IndexOfHamornicNew] = data);
        setListHarmonic(arrOptions);
        setValue("harmonicList", arrOptions);
        // setIsUpdateHarmonic(true)
        // setIsUpdateHarmonicData(arrOptions)
    }

    // キャンセル
    const handleCancelEditHarmonicModel = () => {
        setIsOpenedDialogEditHarmonic(false);
    }

    // 消去
    const handleDeleteHamornicData = () => {
        const harmonicList = [...listHarmonic].filter(options => options.hamorItemID !== selectItem.hamorItemID);
        setListHarmonic(harmonicList);
        setValue("harmonicList", harmonicList);
        setSetlectedRow(undefined);
        // setIsDeleteHarmonic(true);
        // setIsDeleteHarmonicData([...listHarmonic].filter(options => options.hamorItemID === selectedHarmonicDialogId))
    }

    const initialValueHarmonicForm: HarmonicRegisterModel = {
        ...defaultHarmonicInfo,
        hamorItemID: lastIdHamornicNewFormModel + 1,
        name: "",
        manufacture: "",
        type: "",
        capacity: 0,
        number: 0,
        totalCapacity: 0,
        convertRated: 1,
        operatingRatio: 80,
        current7: 0,
        current5: 0,
        current13: 0,
        current17: 0,
        current19: 0,
        current11: 0,
        current23: 0,
        current25: 0,
        equipvalentCapacity: 1,
    }
    // #endregion Tab2

    return (
        <>
            <div style={{ height: '100%', width: '100%' }}>
                <form onSubmit={handleSubmit(handleEditSubmit)}>
                    <div style={{ height: '340px', width: '100%', margin: 'auto', overflow: "auto" }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={selectTab} onChange={handleChange}
                                variant="scrollable" 
                                scrollButtons 
                                allowScrollButtonsMobile 
                                sx={{
                                    '& .MuiButtonBase-root.MuiTabScrollButton-root.Mui-disabled': {
                                        display: "none"
                                    }
                                }}
                            >
                                <Tab label="基本" {...a11yProps(0)} />
                                <Tab label="高調波発生機器" {...a11yProps(1)} />
                            </Tabs>
                        </Box>
                        
                        <TabPanel value={selectTab} index={0}>
                        <Stack spacing={0.3}>
                                <Grid container mt={2}>
                                    <Grid item xs={5.5}>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center",marginTop:"5px" }}>
                                            <Typography variant="body2" width="50%">デバイス名:</Typography>
                                            <Controller
                                                name="refNo"
                                                control={control}
                                                rules={{
                                                    required: '必須項目です。入力してください。',
                                                }}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        // label="デバイス名"
                                                        error={!!errors?.refNo}
                                                        helperText={errors?.refNo?.message}
                                                        onBlur={handleChangeFormat}
                                                        onFocus={e => e.target.select()}
                                                        InputLabelProps={{
                                                            style: { color: 'black' },
                                                        }}
                                                        InputProps={{sx: { height: 32 ,fontSize:'14px',background:m_bModePM || modeViewOnly?'floralwhite':'white', color:modeViewOnly?"grey":"black"}}}
                                                        disabled={m_bModePM}
                                                        inputProps={{readOnly:modeViewOnly}}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                        
                                        <Stack sx={{ flexDirection: "row", alignItems:"center", marginTop:"2px"}}>
                                            <Typography variant="body2" width="50%">回路電圧(V):</Typography>
                                                <FormControl fullWidth size="small">
                                                    <Controller
                                                        name="voltage"
                                                        control={control}
                                                        rules={{
                                                            required: '必須項目です。入力してください。' ,
                                                            pattern:{
                                                                value: /^[0-9\b]+$/,
                                                                message: '数字を入力してください。'
                                                            },
                                                            validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                            // maxLength: {
                                                            //     value: 6,
                                                            //     message: "最大長さ <= 6 を入力してください。"
                                                            // }
                                                                }}
                                                        render={({ field }) => (
                                                            <TextFieldOptions
                                                                    {...field}
                                                                    options={initialValue.voltageList}
                                                                    // label="回路電圧(V)"
                                                                    // onChange= {handleChangeFormat}
                                                                    size="small"

                                                                    setValue={(name: any, value: any) => {
                                                                        // handleChangeFormat({target: {name: name, value: value}});
                                                                        handleInput({target: {name: name, value: value}})
                                                                    }}
                                                                    maxLength={6}
                                                                    error={!!errors?.voltage}
                                                                    helperText={errors?.voltage?.message}
                                                                    disabled={getValues("isSourceVoltage")|| m_bModePM || modeViewOnly}
                                                                    height={32}

                                                                />
                                                        )}
                                                    />
                                            </FormControl>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={6.5} pl={2} >
                                        {/* Mode SP */}
                                        {(userRole && !(userRole == Constant.ROLE_LP)) &&
                                        <Stack>
                                            <Controller
                                                name="viewFlowResult"
                                                control={control}
                                                render={({ field }: any) => (
                                                    <FormControlLabel {...field} control={<Checkbox style={radioStyles} />} checked={field.value} label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>潮流解析結果を表示</Typography>} name='viewFlowResult' 
                                                    onClick={handleChangeFormat} disabled={m_bModePM || modeViewOnly}/>
                                                )}
                                            />
                                        </Stack>
                                        }
                                        
                                        <Stack>
                                            <Controller
                                                name="viewResultText"
                                                control={control}
                                                render={({ field }: any) => (
                                                    <FormControlLabel {...field} control={<Checkbox style={radioStyles} />} checked={field.value} label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>注釈を表示</Typography>}  name='viewResultText' 
                                                    onClick={handleChangeFormat} disabled={m_bModePM || modeViewOnly}/>
                                                )}
                                            />
                                        </Stack>
                                    </Grid>
                                    {/* ModePM */}
                                    {[Constant.ROLE_PM, Constant.ROLE_ADMIN].includes(userRole) && 
                                    <>
                                        <Stack mt={1}>
                                                <FormControlLabel
                                                    control={
                                                        <Controller
                                                            name='makeDataInput'
                                                            control={control}
                                                            render={({ field }) => (
                                                                <Checkbox
                                                                    size='small'
                                                                    {...field}
                                                                    checked={field.value}
                                                                    onChange={handleChangeMakeDataInput}
                                                                    style={{ padding: '20px 4px 4px 9px'}}
                                                                    disabled={m_bModePM || modeViewOnly}
                                                                />
                                                            )}
                                                        />
                                                    }
                                                    label={<Typography variant="body2" style={{paddingTop:'15px'}} color={m_bModePM?'grey':'black'}>銘板入力</Typography>}
                                                />
                                        </Stack>
                                        <Stack>
                                            <fieldset
                                                style={{ 
                                                    width: '70%',
                                                    color: !makeDataInput ? 'grey' : 'black', 
                                                    border:'1px solid #AAAA'
                                                }}
                                            >
                                                <legend><Typography variant="body2" >銘板</Typography></legend>
                                                <Stack spacing={0.3}>
                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                        <Typography variant="body2" width="35%"  >製造者:</Typography>
                                                        <FormControl fullWidth size='small'>
                                                            <Controller
                                                                name='makerName'
                                                                control={control}
                                                                
                                                                render={({ field }: any) => (
                                                                    <TextFieldOptions
                                                                        {...field}
                                                                        options={initialValue.makerNameList}
                                                                        type='string'
                                                                        size='small'
                                                                        setValue={(name: any, value: any) => {
                                                                            handleChangeFormat({target: {name: name, value: value}})
                                                                        }}
                                                                        height= {32}
                                                                        disabled={!makeDataInput||m_bModePM || modeViewOnly}
                                                                    />
                                                                )}
                                                            />
                                                        </FormControl>
                                                    </Stack>
                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                        <Typography variant="body2" width="35%"  >形式:</Typography>
                                                        <Controller
                                                            name='type'
                                                            control={control}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    type='text'
                                                                    size='small'
                                                                    onChange={handleChangeFormat}
                                                                    InputLabelProps={{
                                                                        style: { color: makeDataInput ? 'black' : 'grey' },
                                                                    }}
                                                                    InputProps={{
                                                                        readOnly: !makeDataInput || modeViewOnly,
                                                                        style:{color:!makeDataInput ?'grey' : 'black'},
                                                                        sx: { height: 32,
                                                                            backgroundColor: (makeDataInput && m_bModePM) || modeViewOnly ? 'floralwhite' :makeDataInput? 'white':'floralwhite',
                                                                            color:modeViewOnly?"grey":makeDataInput ? 'black' : 'grey'
                                                                         }
                                                                    }}
                                                                    style={{
                                                                        color: 'black'
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </Stack>
                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                        <Typography variant="body2" width="35%"  >製造年月:</Typography>
                                                        <Grid container>
                                                            <Grid item xs={7}>
                                                                <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                                    <FormControl fullWidth size='small'>
                                                                        <Controller
                                                                            name='makeYear'
                                                                            control={control}
                                                                            rules={{
                                                                                // maxLength: {
                                                                                //     value: 8,
                                                                                //     message: "最大長さ <= 8 を入力してください。"
                                                                                // }
                                                                                    }}
                            
                                                                            render={({ field }: any) => (
                                                                                <TextFieldOptions
                                                                                    {...field}
                                                                                    options={initialValue.makeYearList}
                            
                                                                                    type='string'
                                                                                    size='small'
                                                                                    setValue={(name: any, value: any) => {
                                                                                        handleChangeFormat({target: {name: name, value: value}})
                                                                                    }}
                                                                                    error={!!errors?.makeYear}
                                                                                    helperText={errors?.makeYear?.message}
                                                                                    disabled={!makeDataInput||m_bModePM || modeViewOnly}
                                                                                    height={32}
                                                                                    maxLength={8}

                                                                                />
                                                                            )}
                                                                        />
                                                                    </FormControl>
                                                                    <Typography variant="body2" pr={0.5} pl={0.5}>年</Typography>
                                                                </Stack>
                                                            </Grid>
                                                            <Grid item xs={5}>
                                                                <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                                    <FormControl fullWidth size='small'>
                                                                        <Controller
                                                                        name='makeMonth'
                                                                        control={control}
                                                                        rules={{
                                                                            // maxLength:{
                                                                            //     value:2,
                                                                            //     message:'最大長さ <= 2 を入力してください'
                                                                            // }
                                                                        }}
                                                                        render={({ field }: any) => (
                                                                            <TextFieldOptions
                                                                                {...field}
                                                                                options={initialValue.makeMonthList}
                                                                                // label='製造者'
                                                                                type='string'
                                                                                size='small'
                                                                                // onChange={handleChangeFormat}
                                                                                setValue={(name: any, value: any) => {
                                                                                    handleChangeFormat({target: {name: name, value: value}})
                        
                                                                                }} 
                                                                                error={!!errors?.makeMonth}
                                                                                helperText={errors?.makeMonth?.message}
                                                                                disabled={!makeDataInput|| m_bModePM || modeViewOnly}
                                                                                height={32}
                                                                                maxLength={2}
                        
                                                                            />
                                                                        )}
                                                                            
                                                                        />
                                                                    </FormControl>
                                                                    <Typography variant="body2" pl={0.5}>月</Typography>
                                                                </Stack>
                                                            </Grid>
                                                        </Grid>
                                                    </Stack>
                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                        <Typography variant="body2" width="35%" >製造番号:</Typography>
                                                        <Controller
                                                            name='makeNumber'
                                                            control={control}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    type='text'
                                                                    size='small'
                                                                    onChange={handleChangeFormat}
                                                                    InputLabelProps={{
                                                                        style: { color: makeDataInput ? 'black' : 'grey' },
                                                                    }}
                                                                    InputProps={{
                                                                        readOnly: !makeDataInput || modeViewOnly,
                                                                        style:{color:!makeDataInput ?'grey' : 'black'},
                                                                        sx: { height: 32,
                                                                            backgroundColor: (makeDataInput && m_bModePM) || modeViewOnly ? 'floralwhite' :makeDataInput? 'white':'floralwhite',
                                                                            color:modeViewOnly?"grey":makeDataInput ? 'black' : 'grey'
                                                                         }
                                                                    }}
                                                                    style={{
                                                                        color: 'black'
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </Stack>
                                                </Stack>
                                            </fieldset>
                                        </Stack>
                                    </>
                                    }
                                </Grid>
                            </Stack>
                        </TabPanel>

                        <TabPanel value={selectTab} index={1}>
                            <Stack  mb={1} mt={2} style={{ width: '100%', margin: "auto" }} >
                                <Grid container spacing={2} direction="row" mt={0}>
                                    <Grid item xs={0.5}></Grid>
                                    <Grid item xs={9} >
                                       
                                        <div className="ag-theme-alpine" style={{ height: "250px", width: "100%" }}>
                                        <AgGridReact
                                                ref={gridRef}
                                                defaultColDef={defaultColDef}
                                                columnTypes={columnTypes}
                                                columnDefs={columnDefs}
                                                // onGridReady={onGridReady}
                                                domLayout={"normal"}
                                                rowData={listHarmonic as any}
                                                animateRows={true}
                                                rowDragManaged={true}
                                                // rowHeight={rowHeight}
                                                rowSelection={'single'}
                                                suppressMovableColumns={false}
                                                suppressDragLeaveHidesColumns={false}
                                                localeText={AG_GRID_LOCALE_JP}
                                                // paginationPageSize={pageSize}
                                                // onCellDoubleClicked={handleEdit}
                                                onRowClicked={() => setSelectItem(gridRef.current.api.getSelectedRows()[0])}
                                                onRowDoubleClicked={() => handleOpenEditDialogHarmonicModelById(gridRef.current.api.getSelectedRows()[0])}
                                                // loadingOverlayComponent={loadingOverlayComponent}
                                                // loadingOverlayComponentParams={loadingOverlayComponentParams}
                                                noRowsOverlayComponent={noRowsOverlayComponent}
                                                noRowsOverlayComponentParams={noRowsOverlayComponentParams}
                                            />
                                            </div>
                                            {/* <Typography variant="body2" component="h3" ml={2} mt={1} mb={1}  >機器名称</Typography>
                                            <List  component="nav" sx={{ maxHeight: '400px',minHeight: '200px', overflow: 'auto',borderTop:"1px solid #babfc7",background:"white"}}>
                                                {listHarmonic.length > 0 &&
                                                    listHarmonic.map((val: HarmonicRegisterModel, index: number) => (
                                                        <ListItem style={{ height: '80%', width: '100%',padding:"0px" }} key={val.hamorItemID} >
                                                            <ListItemButton
                                                                selected={selectedRow === index}
                                                                onClick={(event) => handleClickItemInListItem(event, index, val.hamorItemID)}
                                                            >
                                                                <ListItemText
                                                                    primary={val.name}
                                                                    onDoubleClick={(event) => handleOpenEditDialogHarmonicModelById(event, val.hamorItemID)
                                                                } />
                                                            </ListItemButton>
                                                        </ListItem>
                                                    ))}
                                            </List> */}
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Stack direction={"row"} justifyContent={"flex-end"} mb={1}>
                                            <Button fullWidth={true} color="inherit" variant="contained" onClick={handleOpenAddDialogHarmonicModel} disabled={m_bModePM}>新規 ..</Button>
                                        </Stack>
                                        <Stack direction={"row"} justifyContent={"flex-end"} mb={1}>
                                            <Button fullWidth={true} color="inherit" variant="contained" onClick={handleOpenEditDialogHarmonicModel} disabled={!selectItem}>編集 ..</Button>
                                        </Stack>
                                        <Stack direction={"row"} justifyContent={"flex-end"} mb={1} >
                                            <Button fullWidth={true} color="inherit" variant="contained" onClick={() => setOpenDeleteDialog(true)} disabled={!selectItem || m_bModePM || modeViewOnly}>削除 ..</Button>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Stack>
                        </TabPanel>
                    </div>
                    <Stack direction='row' justifyContent="flex-end" spacing={2} mt={2}>
                        <Button variant="contained" type="submit" disabled={isDisableOKBtn || modeViewOnly}>OK</Button>
                        <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                    </Stack>
                </form >

                {/* ==================== Harmonic ========================= */}
                {isOpenedDialogAddHarmonic && defaultHarmonicInfo &&
                    <HarmonicRegisterDialog 
                        setChangeComboData={setChangeComboData} 
                        changeCombodata={changeCombodata} 
                        controlId={controlId} 
                        data={initialValueHarmonicForm} 
                        onChangeComboData={onChangeComboData} 
                        onOK={handleAddHarmonicModel} 
                        onCancel={handleCancelAddHarmonicModel}  
                        m_bModePM ={m_bModePM}
                        infoCircuit ={infoCircuit}
                        modeViewOnly={modeViewOnly}
                    />
                }
                {isOpenedDialogEditHarmonic &&
                    <HarmonicRegisterDialog 
                        setChangeComboData={setChangeComboData} 
                        changeCombodata={changeCombodata} 
                        controlId={controlId} 
                        data={editDataHarmonicModel ?? initialValueHarmonicForm} 
                        onChangeComboData={onChangeComboData} 
                        onOK={handleEditHamornicNewModel} 
                        onCancel={handleCancelEditHarmonicModel} 
                        m_bModePM ={m_bModePM}
                        infoCircuit ={infoCircuit}
                        modeViewOnly={modeViewOnly}
                    />
                }
                {openDeleteDialog && 
                    <AlertDialog 
                        title={"MSSV3"} 
                        message={"このユーザー曲線を削除しますか?"} 
                        isOpen={true} 
                        onOK={handleDeleteHamornicData} 
                        onCancel={() => setOpenDeleteDialog(false)} 
                    />
                }
            </div>
        </>
    );
};
export default MsINVEditDialog;


