import { Button, Checkbox, Dialog, DialogContent, DialogTitle, FormControlLabel, FormLabel, Grid, Paper, Radio, RadioGroup, Stack, TextField, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import theme from "../../themes/globalStyles";
import { BeamsNumber } from "../../utils/FormatNumber";
import { Point } from "../../models/Index";
import DraggablePaper from "../common/DraggablePaper";
// import { RemModel } from "../../models/Index";

export type RemFormDialogFormProps = {
    data: any;
    modeViewOnly:boolean;
    onOK: (data: any) => void;
    onCancel: () => void;
}; 

const fieldsetStyle = {
    border:"1px solid grey",
    borderRadius:"5px",
}
export const RemFormDialog = (props:RemFormDialogFormProps) => {
    const { data, modeViewOnly, onOK, onCancel } = props;

    // 初期値設定
    const initialValue: any = {
        ratedPower: data.ratedPower,
        ratedVoltage: data.ratedVoltage,
        ratedCurrent: data.ratedCurrent,
        startingCurrent: data.startingCurrent,
        startingTime: data.startingTime,
        puScale: data.puScale,
        stallTime: data.stallTime,
        weightingFactor: data.weightingFactor,
        priorLoadCurrent: data.priorLoadCurrent,
        ctPrimary: data.ctPrimary,
        ctSecondary: data.ctSecondary,
        input5Aor1A: data.input5Aor1A,
        shortCircuitUse: data.shortCircuitUse,
        shortCircuitCurrent: data. shortCircuitCurrent,
        shortOperateTime: data.shortOperateTime,
        ambientTemperature: data.ambientTemperature,
        timeConstant: data.timeConstant,
        listDrawREM610: data.listDrawREM610,
        notes: data.notes,
    }

    const { control, handleSubmit, formState: { errors }, setValue, getValues } = useForm<any>({
        mode: 'all',
        criteriaMode: "all",
        defaultValues: initialValue
    });
    // Submitイベント
    const handleEditSubmit = (value : any) => {
        const request = createRequestData(value);
        onOK(request);
    }

    // FormデータからRequestデータを作成する
    const createRequestData = (formValue: any) => {
        let request = {
            ...formValue,
        } as any;
        return request;
    }

    // キャンセル
    const handleCancel = () => {
        onCancel();
    };

    const handleChangeFormat = (e: any) => {
        setValue(e.target.name, e.target.value);
    }

    //#region Calculation methods
    const doSetPUScale = () => {
        const ratedCurrent = getValues("ratedCurrent")
        const ctPrimary = getValues("ctPrimary")
        const ctSecondary = getValues("ctSecondary")

        const replayInput = getValues("input5Aor1A") === "0" ? 1 : 5
        
        const puFactor = (ctPrimary * replayInput) / (ratedCurrent * ctSecondary)

        setValue("puScale", puFactor)
    }

    const doMakeCurveREM610 = () => {
        const listPoints : Point[] = []

        const ratedCurrent = getValues("ratedCurrent")
        const stallTime = getValues("stallTime")
        const weightingFactor = getValues("weightingFactor")
        const priorLoadCurrent = getValues("priorLoadCurrent")
        const shortCircuitUse = getValues("shortCircuitUse")
        const circuitCurrent = getValues("shortCircuitCurrent")
        const operateTime = getValues("shortOperateTime")
        const ambientTemperature = getValues("ambientTemperature")

        let dFLCint = 0
        let dRatio = 0
        if (ambientTemperature < 20) dRatio = 1.09
        if (20 <= ambientTemperature && ambientTemperature < 40) dRatio = 1.18 - ambientTemperature * 0.0045
        if (ambientTemperature === 40) dRatio = 1
        if (40 < ambientTemperature && ambientTemperature <= 65) dRatio = 1 - ((ambientTemperature - 40) / 100)
        if (65 < ambientTemperature) dRatio = 0.75

        if (shortCircuitUse) {
            let dJudge = 100
            if (circuitCurrent < 100) dJudge = circuitCurrent

            let dCount

            for (dCount = 1.1; dCount < dJudge; dCount += 0.6) {
                dFLCint = dCount * dRatio
                listPoints.push({
                    y: 32.15 * stallTime * Math.log((dFLCint * dFLCint - (weightingFactor / 100) * (priorLoadCurrent * priorLoadCurrent)) / (dFLCint * dFLCint - 1.1025)),
                    x: dCount * ratedCurrent
                })
            }

            dFLCint = dCount * dRatio
            listPoints.push({
                y: 32.15 * stallTime * Math.log((dFLCint * dFLCint - (weightingFactor / 100) * (priorLoadCurrent * priorLoadCurrent)) / (dFLCint * dFLCint - 1.1025)),
                x: circuitCurrent * ratedCurrent
            })
            listPoints.push({
                y: operateTime,
                x: circuitCurrent * ratedCurrent
            })
            listPoints.push({
                y: operateTime,
                x: 100 * ratedCurrent
            })
        } else {
            for (let dCount = 1.1; dCount < 100; dCount += 0.6) {
                dFLCint = dCount * dRatio
                listPoints.push({
                    y: 32.15 * stallTime * Math.log((dFLCint * dFLCint - (weightingFactor / 100) * (priorLoadCurrent * priorLoadCurrent)) / (dFLCint * dFLCint - 1.1025)),
                    x: dCount * ratedCurrent
                })
            }
        }
        setValue("listDrawREM610", listPoints)
        doMakeNotes()
    }

    const doMakeNotes = () => {
        const notes = []

        const ctPrimary = BeamsNumber(+getValues("ctPrimary"), 6)
        const ctSecondary = BeamsNumber(+getValues("ctSecondary"), 6)
        notes.push(`${ctPrimary}(A)/${ctSecondary}(A)`)

        notes.push(`REM610`)
        notes.push(`Termal overload`)
        notes.push(`PU scaining facrot = ${BeamsNumber(+getValues("puScale"), 3)}`)
        notes.push(`Rated current = ${BeamsNumber(+getValues("ratedCurrent"), 6)}`)
        notes.push(`Safe stall time t6x = ${BeamsNumber(+getValues("stallTime"), 6)}s`)
        notes.push(`Weighting factor p = ${BeamsNumber(+getValues("weightingFactor"), 6)}%`)
        notes.push(`Prior load current = ${BeamsNumber(+getValues("weightingFactor"), 6)}xIn`)

        if (getValues("shortCircuitUse")) {
            notes.push(`Short-circuit`)
            notes.push(`Start current I>>= ${BeamsNumber(+getValues("shortCircuitCurrent"), 6)}xIn`)
            notes.push(`Operate time I>>= ${BeamsNumber(+getValues("shortOperateTime"), 6)}s`)
        }

        setValue("notes", notes.join("\n"))
    }
    //#endregion Calculation methods

    return (
        <>
        <Dialog open={true} fullWidth 
            PaperComponent={
                DraggablePaper
            }
        >
            <DialogTitle 
                onMouseOver={(e : any) => e.target.style.cursor = 'move'}
            style={{ paddingTop: '2px', paddingBottom: '2px', background: theme.palette.primary.main, color: theme.palette.primary.contrastText }}>ABB REM610
            </DialogTitle>
            <DialogContent sx={{ pb: 1.5 }}>
            <div style={{ height: '100%', width: '100%' }}>
                    <form 
                        onSubmit={handleSubmit(handleEditSubmit)} 
                        style={{ width: '100%', margin: 'auto' }}
                    >
                        <div style={{height:"50vh",overflowY:"auto"}}>
                            <Stack spacing={0.5} direction='column' mb={1} mt={2}>
                                <Stack>
                                    <Grid container spacing={2} direction={"row"}>
                                        <Grid item xs={6}>
                                            <fieldset style={fieldsetStyle}>
                                                <legend style={{fontSize:"14px"}}>Rated Power: Pn (kW)</legend>
                                                <Stack spacing={0.3}>
                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                        <Typography fontSize={"13px"} width="170%">Rated Power: Pn (kW)</Typography>
                                                        <Controller
                                                            name="ratedPower"
                                                            control={control}
                                                            rules={{
                                                            required: '必須項目です。入力してください。',
                                                            }}
                                                            render={({ field }) => (
                                                                <>
                                                                    <TextField
                                                                        {...field}
                                                                        fullWidth
                                                                        type="text"
                                                                        size="small"
                                                                        error={!!errors?.ratedPower}
                                                                        onBlur={handleChangeFormat}
                                                                        onFocus={e => e.target.select()}
                                                                        InputLabelProps={{
                                                                            style: { color: 'black' },
                                                                        }}
                                                                        InputProps={{
                                                                            sx:{height:32,color: modeViewOnly? 'grey':'black', background:modeViewOnly?'floralwhite':'white'},
                                                                        }}
                                                                        inputProps={{readOnly: modeViewOnly}}
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                    </Stack>
                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                        <Typography fontSize={"13px"} width="170%">Rated Voltage: Un (V)</Typography>
                                                        <Controller
                                                            name="ratedVoltage"
                                                            control={control}
                                                            rules={{
                                                            required: '必須項目です。入力してください。',
                                                            }}
                                                            render={({ field }) => (
                                                                <>
                                                                    <TextField
                                                                        {...field}
                                                                        fullWidth
                                                                        type="text"
                                                                        size="small"
                                                                        error={!!errors?.ratedVoltage}
                                                                        onBlur={handleChangeFormat}
                                                                        onFocus={e => e.target.select()}
                                                                        InputLabelProps={{
                                                                            style: { color: 'black' },
                                                                        }}
                                                                        InputProps={{
                                                                            sx:{height:32,color: modeViewOnly? 'grey':'black', background:modeViewOnly?'floralwhite':'white'},
                                                                        }}
                                                                        inputProps={{readOnly: modeViewOnly}}
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                    </Stack>
                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                        <Typography fontSize={"13px"} width="170%">Rated Current: In (A)</Typography>
                                                        <Controller
                                                            name="ratedCurrent"
                                                            control={control}
                                                            rules={{
                                                            required: '必須項目です。入力してください。',
                                                            }}
                                                            render={({ field }) => (
                                                                <>
                                                                    <TextField
                                                                        {...field}
                                                                        fullWidth
                                                                        type="text"
                                                                        size="small"
                                                                        error={!!errors?.ratedCurrent}
                                                                        onBlur={handleChangeFormat}
                                                                        onChange={(e) => {
                                                                            field.onChange(e)
                                                                            doSetPUScale()
                                                                        }}
                                                                        onFocus={e => e.target.select()}
                                                                        InputLabelProps={{
                                                                            style: { color: 'black' },
                                                                        }}
                                                                        InputProps={{
                                                                            sx:{height:32,color: modeViewOnly? 'grey':'black', background:modeViewOnly?'floralwhite':'white'},
                                                                        }}
                                                                        inputProps={{readOnly: modeViewOnly}}
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                    </Stack>
                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                        <Typography fontSize={"13px"} width="170%">Starting Current: Is (xIn)</Typography>
                                                        <Controller
                                                            name="startingCurrent"
                                                            control={control}
                                                            rules={{
                                                            required: '必須項目です。入力してください。',
                                                            }}
                                                            render={({ field }) => (
                                                                <>
                                                                    <TextField
                                                                        {...field}
                                                                        fullWidth
                                                                        type="text"
                                                                        size="small"
                                                                        error={!!errors?.startingCurrent}
                                                                        onBlur={handleChangeFormat}
                                                                        onFocus={e => e.target.select()}
                                                                        InputLabelProps={{
                                                                            style: { color: 'black' },
                                                                        }}
                                                                        InputProps={{
                                                                            sx:{height:32,color: modeViewOnly? 'grey':'black', background:modeViewOnly?'floralwhite':'white'},
                                                                        }}
                                                                        inputProps={{readOnly: modeViewOnly}}
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                    </Stack>
                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                        <Typography fontSize={"13px"} width="170%">Starting times: ts (s)</Typography>
                                                        <Controller
                                                            name="startingTime"
                                                            control={control}
                                                            rules={{
                                                            required: '必須項目です。入力してください。',
                                                            }}
                                                            render={({ field }) => (
                                                                <>
                                                                    <TextField
                                                                        {...field}
                                                                        fullWidth
                                                                        type="text"
                                                                        size="small"
                                                                        error={!!errors?.startingTime}
                                                                        onBlur={handleChangeFormat}
                                                                        onFocus={e => e.target.select()}
                                                                        InputLabelProps={{
                                                                            style: { color: 'black' },
                                                                        }}
                                                                        InputProps={{
                                                                            sx:{height:32,color: modeViewOnly? 'grey':'black', background:modeViewOnly?'floralwhite':'white'},
                                                                        }}
                                                                        inputProps={{readOnly: modeViewOnly}}
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                    </Stack>
                                                </Stack>
                                            </fieldset>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <fieldset style={fieldsetStyle}>
                                                <legend style={{fontSize:"14px"}}>Thermal overload protection</legend>
                                                <Stack spacing={0.3} paddingBottom={0.8}>
                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                        <Stack width="170%">
                                                            <Typography fontSize={"13px"}>P.U. scaling factor: k</Typography>
                                                            <Typography fontSize={"13px"} ml={8}>[0.5-2.5]</Typography>
                                                        </Stack>
                                                        <Controller
                                                            name="puScale"
                                                            control={control}
                                                            rules={{
                                                            required: '必須項目です。入力してください。',
                                                                min: 0.5,
                                                                max: 2.5,
                                                            }}
                                                            render={({ field }) => (
                                                                <>
                                                                    <TextField
                                                                        {...field}
                                                                        fullWidth
                                                                        type="text"
                                                                        error={!!errors?.puScale}
                                                                        // helperText={errors?.puScale?.message}
                                                                        onBlur={handleChangeFormat}
                                                                        onFocus={e => e.target.select()}
                                                                        InputLabelProps={{
                                                                            style: { color: 'black' },
                                                                        }}
                                                                        InputProps={{
                                                                            readOnly: true,
                                                                            sx:{height:32,backgroundColor:"floralwhite",color: modeViewOnly? 'grey':'black'},
                                                                        }}
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                    </Stack>
                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                        <Stack width="170%">
                                                            <Typography fontSize={"13px"}>Safe stall time: t6x</Typography>
                                                            <Typography fontSize={"13px"} ml={8}>[2-120s]</Typography>
                                                        </Stack>
                                                        <Controller
                                                            name="stallTime"
                                                            control={control}
                                                            rules={{
                                                            required: '必須項目です。入力してください。',
                                                                min:2,
                                                                max:120,
                                                            }}
                                                            render={({ field }) => (
                                                                <>
                                                                    <TextField
                                                                        {...field}
                                                                        fullWidth
                                                                        type="text"
                                                                        error={!!errors?.stallTime}
                                                                        onBlur={handleChangeFormat}
                                                                        onFocus={e => e.target.select()}
                                                                        InputLabelProps={{
                                                                            style: { color: 'black' },
                                                                        }}
                                                                        InputProps={{
                                                                            sx:{height:32,color: modeViewOnly? 'grey':'black', background:modeViewOnly?'floralwhite':'white'},
                                                                        }}
                                                                        inputProps={{readOnly: modeViewOnly}}
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                    </Stack>
                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                        <Stack width="170%">
                                                            <Typography fontSize={"13px"}>Weighting factor: p</Typography>
                                                            <Typography fontSize={"13px"} ml={8}>[0-100%]</Typography>
                                                        </Stack>
                                                        <Controller
                                                            name="weightingFactor"
                                                            control={control}
                                                            rules={{
                                                            required: '必須項目です。入力してください。',
                                                                min:0,
                                                                max:100,
                                                            }}
                                                            
                                                            render={({ field }) => (
                                                                <>
                                                                    <TextField
                                                                        {...field}
                                                                        fullWidth
                                                                        type="text"
                                                                        error={!!errors?.weightingFactor}
                                                                        onBlur={handleChangeFormat}
                                                                        onFocus={e => e.target.select()}
                                                                        InputLabelProps={{
                                                                            style: { color: 'black' },
                                                                        }}
                                                                        InputProps={{
                                                                            sx:{height:32,color: modeViewOnly? 'grey':'black', background:modeViewOnly?'floralwhite':'white'},
                                                                        }}
                                                                        inputProps={{readOnly: modeViewOnly}}
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                    </Stack>
                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                        <Stack width="170%">
                                                            <Typography fontSize={"13px"}>Prior load current : (x In)</Typography>
                                                            <Typography fontSize={"13px"} ml={8}>[0.12-10 xIn]</Typography>
                                                        </Stack>
                                                        <Controller
                                                            name="priorLoadCurrent"
                                                            control={control}
                                                            rules={{
                                                                required: '必須項目です。入力してください。',
                                                                min:0.12,
                                                                max:10,
                                                            }}
                                                            render={({ field }) => (
                                                                <>
                                                                    <TextField
                                                                        {...field}
                                                                        fullWidth
                                                                        type="text"
                                                                        error={!!errors?.priorLoadCurrent}
                                                                        onBlur={handleChangeFormat}
                                                                        onFocus={e => e.target.select()}
                                                                        InputLabelProps={{
                                                                            style: { color: 'black' },
                                                                        }}
                                                                        InputProps={{
                                                                            sx:{height:32,color: modeViewOnly? 'grey':'black', background:modeViewOnly?'floralwhite':'white'},
                                                                        }}
                                                                        inputProps={{readOnly: modeViewOnly}}
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                    </Stack>
                                                </Stack>
                                            </fieldset>
                                        </Grid>
                                    </Grid>
                                </Stack>
                                <Stack>
                                    <Grid container spacing={2} direction={"row"}>
                                        <Grid item xs={6}>
                                            <fieldset style={fieldsetStyle}>
                                                <legend style={{fontSize:"14px"}}>Phase CT</legend>
                                                <Stack spacing={1.7}>
                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                        <Typography fontSize={"13px"} width="140%">Rated Power: Pn (kW)</Typography>
                                                        <Controller
                                                            name="ctPrimary"
                                                            control={control}
                                                            rules={{
                                                            required: '必須項目です。入力してください。',
                                                            }}
                                                            render={({ field }) => (
                                                                <>
                                                                    <TextField
                                                                        {...field}
                                                                        fullWidth
                                                                        type="text"
                                                                        size="small"
                                                                        error={!!errors?.ctPrimary}
                                                                        onBlur={handleChangeFormat}
                                                                        onChange={(e) => {
                                                                            field.onChange(e)
                                                                            doSetPUScale()
                                                                        }}
                                                                        onFocus={e => e.target.select()}
                                                                        InputLabelProps={{
                                                                            style: { color: 'black' },
                                                                        }}
                                                                        InputProps={{
                                                                            sx:{height:32,color: modeViewOnly? 'grey':'black', background:modeViewOnly?'floralwhite':'white'},
                                                                        }}
                                                                        inputProps={{readOnly: modeViewOnly}}
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                    </Stack>
                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                        <Typography fontSize={"13px"} width="140%">Rated Voltage: Un (V)</Typography>
                                                        <Controller
                                                            name="ctSecondary"
                                                            control={control}
                                                            rules={{
                                                            required: '必須項目です。入力してください。',
                                                            }}
                                                            render={({ field }) => (
                                                                <>
                                                                    <TextField
                                                                        {...field}
                                                                        fullWidth
                                                                        type="text"
                                                                        size="small"
                                                                        error={!!errors?.ctSecondary}
                                                                        onBlur={handleChangeFormat}
                                                                        onChange={(e) => {
                                                                            field.onChange(e)
                                                                            doSetPUScale()
                                                                        }}
                                                                        onFocus={e => e.target.select()}
                                                                        InputLabelProps={{
                                                                            style: { color: 'black' },
                                                                        }}
                                                                        InputProps={{
                                                                            sx:{height:32,color: modeViewOnly? 'grey':'black', background:modeViewOnly?'floralwhite':'white'},
                                                                        }}
                                                                        inputProps={{readOnly: modeViewOnly}}
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                    </Stack>
                                                    <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
                                                        <Typography fontSize={"13px"} width="55%">Relay input current</Typography>
                                                        <Controller
                                                            name="input5Aor1A"
                                                            control={control}
                                                            rules={{
                                                            required: '必須項目です。入力してください。',
                                                            }}
                                                            render={({ field }) => (
                                                            <>
                                                                <RadioGroup
                                                                {...field}
                                                                row={true} // Sử dụng row={true} để các radio button nằm trên cùng một dòng
                                                                onChange={(e) => {
                                                                    field.onChange(e);
                                                                    doSetPUScale();
                                                                }}
                                                                >
                                                                    <FormControlLabel disabled={modeViewOnly} value={0} control={<Radio style={{ height: "25px", padding: "0 0 0 0" }} />} style={{fontSize:"10px"}} label="1A" />
                                                                    <FormControlLabel disabled={modeViewOnly} value={1} control={<Radio style={{ height: "25px", padding: "0 0 0 0" }} />} style={{fontSize:"10px"}} label="5A" />
                                                                </RadioGroup>
                                                            </>
                                                            )}
                                                        />
                                                    </Stack>
                                                </Stack>
                                                
                                            </fieldset>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <fieldset style={fieldsetStyle}>
                                                <legend style={{fontSize:"14px"}}>Thermal overload protection</legend>
                                                <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                    <Typography fontSize={"13px"} width="63%">USE</Typography>
                                                    <Controller
                                                        name="shortCircuitUse"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormControlLabel disabled={modeViewOnly} {...field} control={<Checkbox style={{ height: '40px' }} />} checked={field.value} label="" name='shortCircuitUse' onClick={handleChangeFormat} />
                                                        )}
                                                    />
                                                </Stack>
                                                <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                    <Stack width="170%">
                                                        <Typography fontSize={"13px"}>Start Current: I{">"}{">"}: x In</Typography>
                                                        <Typography fontSize={"13px"} ml={8}>[0.5-20 xIn]</Typography>
                                                    </Stack>
                                                    <Controller
                                                        name="shortCircuitCurrent"
                                                        control={control}
                                                        rules={{
                                                        required: '必須項目です。入力してください。',
                                                            min:0.5,
                                                            max:20,
                                                        }}
                                                        render={({ field }) => (
                                                            <>
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    type="text"
                                                                    size="small"
                                                                    error={!!errors?.shortCircuitCurrent}
                                                                    onBlur={handleChangeFormat}
                                                                    onFocus={e => e.target.select()}
                                                                    InputLabelProps={{
                                                                        style: { color: 'black' },
                                                                    }}
                                                                    InputProps={{
                                                                        sx:{height:32,color: modeViewOnly? 'grey':'black', background:modeViewOnly?'floralwhite':'white'},
                                                                    }}
                                                                    inputProps={{readOnly: modeViewOnly}}
                                                                />
                                                            </>
                                                        )}
                                                    />
                                                </Stack>
                                                <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                    <Stack width="170%">
                                                        <Typography fontSize={"13px"}>Operate time: t{">"}{">"} (s)</Typography>
                                                        <Typography fontSize={"13px"} ml={8}>[0.05-30s]</Typography>
                                                    </Stack>
                                                    <Controller
                                                        name="shortOperateTime"
                                                        control={control}
                                                        rules={{
                                                        required: '必須項目です。入力してください。',
                                                        min:0.05,
                                                        max:30,
                                                        }}
                                                        render={({ field }) => (
                                                            <>
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    type="text"
                                                                    size="small"
                                                                    error={!!errors?.shortOperateTime}
                                                                    onBlur={handleChangeFormat}
                                                                    onFocus={e => e.target.select()}
                                                                    InputLabelProps={{
                                                                        style: { color: 'black' },
                                                                    }}
                                                                    InputProps={{
                                                                        sx:{height:32,color: modeViewOnly? 'grey':'black', background:modeViewOnly?'floralwhite':'white'},
                                                                    }}
                                                                    inputProps={{readOnly: modeViewOnly}}
                                                                />
                                                            </>
                                                        )}
                                                    />
                                                </Stack>
                                            </fieldset>
                                        </Grid>
                                    </Grid>
                                </Stack>
                                <Stack>
                                    <Grid spacing={2} container direction={"row"}>
                                        <Grid item xs={6}>
                                            <fieldset style={fieldsetStyle}>
                                                <legend style={{fontSize:"14px"}}>Ambient Temperature</legend>
                                                <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                    <Stack width="170%">
                                                        <Typography fontSize={"13px"}>Celsius: Tamb(°C)</Typography>
                                                        <Typography fontSize={"13px"} ml={8}>[0-70°C]</Typography>
                                                    </Stack>
                                                    <Controller
                                                        name="ambientTemperature"
                                                        control={control}
                                                        rules={{
                                                            required: '必須項目です。入力してください。',
                                                            min:0,
                                                            max:70,
                                                        }}
                                                        render={({ field }) => (
                                                            <>
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    type="text"
                                                                    size="small"
                                                                    error={!!errors?.ambientTemperature}
                                                                    onBlur={handleChangeFormat}
                                                                    onFocus={e => e.target.select()}
                                                                    InputLabelProps={{
                                                                        style: { color: 'black' },
                                                                    }}
                                                                    InputProps={{
                                                                        sx:{height:32,color: modeViewOnly? 'grey':'black', background:modeViewOnly?'floralwhite':'white'},
                                                                    }}
                                                                    inputProps={{readOnly: modeViewOnly}}
                                                                />
                                                            </>
                                                        )}
                                                    />
                                                </Stack>
                                            </fieldset>
                                        </Grid>
                                    </Grid>
                                </Stack>
                            </Stack>
                        </div>
                        <Stack direction='row' justifyContent="flex-end" spacing={2} mt={1}>
                            <Button variant="contained" type="submit" disabled={modeViewOnly} onClick={() => {
                                doMakeCurveREM610() //calc points and notes
                            }}>OK</Button>
                            <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                        </Stack>
                    </form >
            </div >
            </DialogContent>

        </Dialog>
        </>
    )
};
export default RemFormDialog;
