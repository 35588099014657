import { Box, Button, Checkbox, CircularProgress, FormControlLabel, Grid, Stack, Tab, Tabs, TextField, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { EquipMotorhpModel } from '../../../models/breakModel'
import { connect } from 'react-redux'
import { ApplicationState, actionCreators, selectors } from '../../../store'
import { Controller, useForm } from 'react-hook-form'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Path as RoutePath } from "../../../path";
import FetchHelper from '../../FetchHelper'
import { LoadingButton } from '@mui/lab'
//-----------
//FETCH ID
const GET_EQUIP_MOTORHP = "GET_EQUIP_MOTORHP"
const UPDATE_EQUIP_MOTORHP = "UPDATE_EQUIP_MOTORHP"
const CREATE_EQUIP_MOTORHP = "CREATE_EQUIP_MOTORHP"

const initialValue: EquipMotorhpModel = {
    lMotorId: 0,
    bIsDefault: false,
    dCapacity: 0,
    dEfficiency: 0,
    dInrushCurrent: 0,
    dPowerFactor: 0,
    dStartingCurrent: 0,
    dStartingTime: 0,
    dStartTime: 0,
}

// -------------
// Props
export type EquipMotorhpEditProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
const EquipMotorhpEdit = (props: EquipMotorhpEditProps) => {
    const {
        userId,
        onCreate, onCreateError,
        onUpdate, onUpdateError,
        onSuccess, onError,
    } = props;

    const { control, formState: { errors }, handleSubmit, setValue, getValues, } = useForm<EquipMotorhpModel>({
        criteriaMode: "all",
        defaultValues: initialValue,
        mode: "all",
    })
    type MyParams = {
        id: string;
        mode: string;
    };
    let { id, mode } = useParams<keyof MyParams>() as MyParams;
    const location = useLocation();
    let navigate = useNavigate();
    const [loadingFlag, setLoadingFlag] = useState(mode === "edit" ? true : false);
    const gridRef = useRef() as any;
    const Exp = /^[-+]?\d+(\.\d+)?$/;
    const messageIsNotNumber = "数字を入力してください。";

    const handleSuccess = (data: any) => {
        console.log('>>>> handle Successs');
        // if(data && data.data){
        const obj = data.data;
        setValue("lMotorId", obj.lMotorId);
        setValue("bIsDefault", obj.bIsDefault);
        setValue("dCapacity", obj.dCapacity?? "");
        setValue("dEfficiency", obj.dEfficiency?? "");
        setValue("dInrushCurrent", obj.dInrushCurrent?? "");
        setValue("dPowerFactor", obj.dPowerFactor?? "");
        setValue("dStartingCurrent", obj.dStartingCurrent?? "");
        setValue("dStartingTime", obj.dStartingTime?? "");
        setValue("dStartTime", obj.dStartTime?? "");
        // }
        setLoadingFlag(false);
    }
    useEffect(() => {
        if (mode === "add")
            // setValue("Mv3breakDialCharacter.sCharacterName", sCharacterName);
            return
    }, [])

    // 検索エラー時
    const handleError = (success: boolean, data: any, error: any) => {
        console.log('>>>> handle Error');
        gridRef.current.hideOverlay();
    }

    // 検索実行
    const handleEditSubmit = (data: EquipMotorhpModel) => {
        setLoadingFlag(true);
        if (mode === "edit") {
            let request = {
                lMotorId: getValues("lMotorId"),
                bIsDefault: getValues("bIsDefault") ?? null,
                dCapacity: getValues("dCapacity") ?? null,
                dEfficiency: getValues("dEfficiency") ?? null,
                dInrushCurrent: getValues("dInrushCurrent") ?? null,
                dPowerFactor: getValues("dPowerFactor") ?? null,
                dStartingCurrent: getValues("dStartingCurrent") ?? null,
                dStartingTime: getValues("dStartingTime") ?? null,
                dStartTime: getValues("dStartTime") ?? null
            } as any
            const object = {
                requestPrms: request,
                userId: userId,
            }
            onUpdate(object);
        } else if (mode === "add") {
            let request = {
                bIsDefault: getValues("bIsDefault") ?? null,
                dCapacity: getValues("dCapacity") ?? null,
                dEfficiency: getValues("dEfficiency") ?? null,
                dInrushCurrent: getValues("dInrushCurrent") ?? null,
                dPowerFactor: getValues("dPowerFactor") ?? null,
                dStartingCurrent: getValues("dStartingCurrent") ?? null,
                dStartingTime: getValues("dStartingTime") ?? null,
                dStartTime: getValues("dStartTime") ?? null
            } as any
            const object = {
                requestPrms: request,
                userId: userId,
            }
            onCreate(object);
        }
    }

    // キャンセル
    const handleCancel = () => {
        if (location.key !== "default")
            navigate(-1);
        else
            navigate(RoutePath.EquipMotorhpList);
    }

    // Submit後のAPI呼び出しエラー時
    const handleErrorUpdate = (success: boolean, data: any, error: any) => {
        onUpdateError();
        setLoadingFlag(false);
    }

    // Submit後のAPI呼び出し成功時
    const handleSuccessSubmit = (success: boolean, data: any, error: any) => {
        onSuccess();
        if (mode === "add") {
            navigate(RoutePath.EquipMotorhpEdit + `/edit/${data.data.lMotorId}`, { replace: true })
        }
        setLoadingFlag(false);
    }

    // Submit後のAPI呼び出しエラー時
    const handleErrorSubmit = (success: boolean, data: any, error: any) => {
        onCreateError();
        setLoadingFlag(false)
    }

    //入力欄背景
    const divStyles = {
        backgroundColor: 'white',
        border: '1px solid #bebebe',
        boxShadow: '0 0 5px #fff, 0 0 5px #bebebe, 0 0 1px #aaa',
        padding: '20px 40px',
        marginTop: '40px',
    }

    return (
        <>
            {
                mode === "edit" &&
                <FetchHelper
                    fetchId={GET_EQUIP_MOTORHP}
                    url={`/master/equip_motorhp/get`}
                    method="POST"
                    request={true}
                    params={{
                        requestPrms: {
                            id: id
                        },
                        userId: userId,
                    }}
                    json={true}
                    onComplete={(success, data, error) => {
                        success ? handleSuccess(data) : handleError(success, data, error);
                    }}
                />
            }
            {loadingFlag && (
                <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1500, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                    <CircularProgress />
                </div>
            )}
            <div style={{ height: "100%", width: "100%", marginTop: "10px" }}>
                <Stack direction="row" justifyContent="flex-start" alignItems="center" sx={{}} style={{ margin: '18px', color: 'MenuText' }}>
                    <Typography variant="h4">
                    電動機(HP)詳細
                    </Typography>
                </Stack>
                <form onSubmit={handleSubmit(handleEditSubmit)}>
                        <div style={divStyles}>
                            <Typography variant="h6">
                            電動機(HP)
                            </Typography>
                            <Stack mt={3} direction={{ xs: 'column', sm: 'row' }} sx={{ maxHeight: "calc(100vh)", overflow: 'auto' }}>
                                <Grid container spacing={3} direction={'row'}>
                                    <Grid item xs={6} mt={1}>
                                        <Controller
                                            control={control}
                                            name="dCapacity"
                                            rules={{
                                                pattern: {
                                                    value: Exp,
                                                    message: messageIsNotNumber,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.dCapacity}
                                                    helperText={errors?.dCapacity?.message}
                                                    type="text"
                                                    size="small"
                                                    label="容量（kW）"
                                                // InputLabelProps={{ shrink: true }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6} mt={1}>
                                        <Controller
                                            control={control}
                                            name="dEfficiency"
                                            rules={{
                                                pattern: {
                                                    value: Exp,
                                                    message: messageIsNotNumber,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.dEfficiency}
                                                    helperText={errors?.dEfficiency?.message}
                                                    type="text"
                                                    size="small"
                                                    label="効率(EPAct 4極 60Hz 230Vまたは460V)"


                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            control={control}
                                            name="dInrushCurrent"
                                            rules={{
                                                pattern: {
                                                    value: Exp,
                                                    message: messageIsNotNumber,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.dInrushCurrent}
                                                    helperText={errors?.dInrushCurrent?.message}
                                                    type="text"
                                                    label="Starting inrush current times of Im（s）"
                                                    size="small"

                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            control={control}
                                            name="dPowerFactor"
                                            rules={{
                                                pattern: {
                                                    value: Exp,
                                                    message: messageIsNotNumber,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.dPowerFactor}
                                                    helperText={errors?.dPowerFactor?.message}
                                                    type="text"
                                                    label="力率(一般値)"
                                                    size="small"

                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            control={control}
                                            name="dStartingCurrent"
                                            rules={{
                                                pattern: {
                                                    value: Exp,
                                                    message: messageIsNotNumber,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.dStartingCurrent}
                                                    helperText={errors?.dStartingCurrent?.message}
                                                    type="text"
                                                    label="Starting current times of Im（s）"
                                                    size="small"

                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            control={control}
                                            name="dStartingTime"
                                            rules={{
                                                pattern: {
                                                    value: Exp,
                                                    message: messageIsNotNumber,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.dStartingTime}
                                                    helperText={errors?.dStartingTime?.message}
                                                    type="text"
                                                    label="Starting time（s）"
                                                    size="small"

                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            control={control}
                                            name="dStartTime"
                                            rules={{
                                                pattern: {
                                                    value: Exp,
                                                    message: messageIsNotNumber,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.dStartTime}
                                                    helperText={errors?.dStartTime?.message}
                                                    type="text"
                                                    label="Start Time"
                                                    size="small"

                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            control={control}
                                            name="bIsDefault"
                                            render={({ field }: any) => (
                                                <FormControlLabel {...field} control={<Checkbox />} checked={field.value} label="デフォルト" labelPlacement="start" />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Stack>
                        </div>
                        <Stack direction='row' justifyContent="flex-end" spacing={2} sx={{ mt: 1, mr: 2 }}>
                            <LoadingButton variant="contained" type="submit" loading={loadingFlag}>保存</LoadingButton>
                            <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                        </Stack>
                    {/* </TabPanel> */}
                </form>
            </div>
            <FetchHelper fetchId={UPDATE_EQUIP_MOTORHP}
                onComplete={(success: boolean, data: any, error: any) =>
                    success ? handleSuccessSubmit(success, data, error) : handleErrorUpdate(success, data, error)} />

            <FetchHelper fetchId={CREATE_EQUIP_MOTORHP}
                onComplete={(success, data, error) => success ?
                    handleSuccessSubmit(success, data, error) :
                    handleErrorSubmit(success, data, error)} />

        </>
    );
}
const mapStateToProps = (state: ApplicationState) => (
    {
        userId: state.app.user?.userId,
        fetchUpdate: selectors.getFormState(
            state,
            GET_EQUIP_MOTORHP
        ),
    }
)
const mapDispatchToProps = (dispatch: any) => {
    return {
        onCreate: (data: any) => dispatch(actionCreators.fetch(CREATE_EQUIP_MOTORHP, `/master/equip_motorhp/add`, "POST", data, false, true)),
        onUpdate: (data: any) => dispatch(actionCreators.fetch(UPDATE_EQUIP_MOTORHP, `/master/equip_motorhp/modify`, "POST", data, false, true)),
        onSuccess: () =>
            dispatch(actionCreators.showMessage({
                type: "info",
                title: "電動機(HP)更新",
                body: "電動機(HP)を更新しました。",
            })),
        onError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "検索",
                body: "検索できません。",
            })),
        onCreateError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "電動機(HP)登録",
                body: "電動機(HP)を登録出来ませんでした。",
            })),
        onUpdateError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "電動機(HP)編集",
                body: "電動機(HP)を更新出来ませんでした。",
            })),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(EquipMotorhpEdit as any) 