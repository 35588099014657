import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DraggablePaper from '../common/DraggablePaper';

export type ModeCheckingDialogProps = {
  title: string;
  message: string;
  isOpen: boolean;
  onOK?: () => void;
  onCANCEL?: () => void;
  onNO?: () => void;
  onYES?: () => void;
};

export const ModeCheckingDialog = (props: ModeCheckingDialogProps) => {
  const { title, message, isOpen, onOK, onCANCEL, onNO, onYES } = props;
  const [open, setOpen] = React.useState(isOpen);


  const handleOK = () => {
    console.log(">>> modal handleOK")
    setOpen(false);
    {onOK && onOK()}
  };

  const handleCancel = () => {
    console.log(">>> modal handleCancel")
    setOpen(false);
    {onCANCEL && onCANCEL()};
  };

  return (
    <div>
      <Dialog
        open={open}
        maxWidth={"sm"}
        hideBackdrop
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperComponent={
          DraggablePaper
        }
        // onKeyDown={(e) => { e.preventDefault(); e.key === "Enter" && handleOK()}}
      >
        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" >
            <p style={{ whiteSpace: "pre-line", margin: "0" }}>
              {message}
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {onOK && <Button onTouchEnd={handleOK} onClick={handleOK} color="inherit" variant="outlined">OK</Button>}
          {onYES && <Button onTouchEnd={onYES} onClick={onYES} color="inherit" variant="outlined">はい(Y)</Button>}
          {onNO && <Button onTouchEnd={onNO} onClick={onNO} color="inherit" variant="outlined">いいえ(N)</Button>}
          {onCANCEL && <Button onTouchEnd={handleCancel} onClick={handleCancel} color="inherit" variant="outlined">キャンセル</Button>}
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ModeCheckingDialog;