import { Box, Button, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, Stack, Tab, Tabs, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { InfoCircuit, MsImpedanceModel } from '../../models/Index';
import TextFieldOptions from "../common/TextFieldOptions";
import { BeamsNumber } from "../../utils/FormatNumber";
import { CALC_IK2, CALC_IK2_OUTSIDE_NEUTRAL, CALC_IK2_OUTSIDE_OUTSIDE, CALC_IK2_SEAT_MAIN, CALC_IK2_SEAT_T, CALC_IK3, MS_3PHASES, MS_OUTSIDE_NEUTRAL, MS_OUTSIDE_OUTSIDE, MS_SEAT_MAIN, MS_SEAT_T, MS_TRANS1, MS_TRANSSCOTT, MS_XR_MAX, VALUE_NODE_VOLT_MIN } from "../../models/Constants";
import { isNumber } from "chart.js/helpers";
import * as Constant from "../../models/Constants";
import { MS_MODE_CHECKING } from "../../statics";


export type MsImpedanceEditDialogFormProps = {
    data: any;
    onOK: (data: any) => void;
    onCancel: () => void;
    setIsDisplayTabConnectTran1: boolean;
    setIsDisplayTabConnectTranscott: boolean;
    setIsVoltText:string;
    infoCircuit: InfoCircuit;
    userRole: string;
    m_bModePM: boolean;
    dispInputDialog:any;
    processMode:number;
    modeViewOnly:boolean;
};

function a11yProps(index: number) {
    return {
        id: `tab-${index}`,
        "aria-controls": `tabpanel-${index}`,
    };
}

const radioStyles = {
    padding: '0 0 0 9px',
}

//Tabs------------------
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}
const checkboxStyle = {
    padding: '0px 0px 0px 9px'
}
const checkboxStyles = {
    padding: '4px 4px 4px 8px'
}
export const MsImpedanceEditDialog = (props: MsImpedanceEditDialogFormProps) => {
    const { userRole, data, modeViewOnly, onOK, onCancel,setIsDisplayTabConnectTran1,setIsDisplayTabConnectTranscott,setIsVoltText,infoCircuit ,m_bModePM,dispInputDialog,processMode} = props;
    const [selectTab, setSelectTab] = useState(0);

    const [isInputPercentZ, setIsInputPercentZ] = useState(data.inputPercentZ);

    // 初期値設定
    const initialValue: MsImpedanceModel = {
        ...data,
        calcPoint0: {...data.calcPoint0, loadCapacity: BeamsNumber(data.calcPoint0.loadCapacity,7),
            loadCurrent: BeamsNumber(data.calcPoint0.loadCurrent,5)
        },
        calcPoint1: {...data.calcPoint1, loadCapacity: BeamsNumber(data.calcPoint1.loadCapacity,7),
            loadCurrent: BeamsNumber(data.calcPoint1.loadCurrent,5)
        }
        
    }
    const { control, handleSubmit, formState: { errors }, setValue,getValues,clearErrors,setError } = useForm<MsImpedanceModel>({
        mode: 'all',
        criteriaMode: "all",
        defaultValues: initialValue,
    });
    const [isDisableByPointRegular, setIsDisableByPointRegular] = useState(initialValue.dropRegular);

    // Submitイベント
    const handleEditSubmit = (value: MsImpedanceModel) => {
        const request = createRequestData(value);
        onOK(request);
    }

    // FormデータからRequestデータを作成する
    const createRequestData = (formValue: MsImpedanceModel) => {
        if(!/^[0-9]*[.]?[0-9]+$/.test(formValue.xr.toString())){
            formValue.xr = 0
        }
        if(!Number(formValue.flowSusceptance)){
            formValue.flowSusceptance = 0
        }
        if(!/^[0-9]*[.]?[0-9]+$/.test(formValue.percentR.toString())){
            formValue.percentR = 0
        }
        if(!/^[0-9]*[.]?[0-9]+$/.test(formValue.percentX.toString())){
            formValue.percentX = 0
        }
        if(!Number(formValue.calcPoint0.loadCurrent)){
            formValue.calcPoint0.loadCurrent = 0
        }
        if(!Number(formValue.calcPoint0.loadCapacity)){
            formValue.calcPoint0.loadCapacity = 0
        }
        if(!Number(formValue.calcPoint0.powerFactor)){
            formValue.calcPoint0.powerFactor = 0
        }
        if(!Number(formValue.calcPoint1.loadCurrent)){
            formValue.calcPoint1.loadCurrent = 0
        }
        if(!Number(formValue.calcPoint1.loadCapacity)){
            formValue.calcPoint1.loadCapacity = 0
        }
        if(!Number(formValue.calcPoint1.powerFactor)){
            formValue.calcPoint1.powerFactor = 0
        }
        let request = {
            ...formValue
        } as MsImpedanceModel;
        return request;
    }

    // キャンセル
    const handleCancel = () => {
        onCancel();
    };

    const handleChangeFormat = (e: any) => {
        setValue(e.target.name, e.target.value,{shouldValidate:true});
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        if (newValue >= 0) {
            if (getValues('refNo') == "" ||getValues('refNo') == null || getValues('refNo') == undefined ) {
                setError("refNo",{ type: 'custom', message: '必須項目です。入力してください。' });
            }
            else if (isNaN(Number(getValues('voltage'))) || Number(getValues('voltage')) <= 0 || !/^[0-9\b]+$/.test(getValues('voltage').toString())) {
                setError("voltage",{ type: 'custom', message: '整数 > 0 を入力してください。' });
            }

            else if(selectTab == 1 && (!/^[0-9]*[.]?[0-9]+$/.test(getValues('calcPoint0.loadCurrent').toString()) || Number(getValues('calcPoint0.loadCurrent')) < 0)){
                setError("calcPoint0.loadCurrent",{ type: 'custom', message: '数字 >= 0 を入力してください。' });
            }
            else if(selectTab == 1 && (!/^[0-9]*[.]?[0-9]+$/.test(getValues('calcPoint0.loadCapacity').toString()) || Number(getValues('calcPoint0.loadCapacity')) < 0)){
                setError("calcPoint0.loadCapacity",{ type: 'custom', message: '数字 >= 0 を入力してください。' });
            }
            else if(selectTab == 1 && (!/^[0-9]*[.]?[0-9]+$/.test(getValues('calcPoint0.powerFactor').toString()) || Number(getValues('calcPoint0.powerFactor')) < 0 || Number(getValues('calcPoint0.powerFactor')) > 1)){
                setError("calcPoint0.powerFactor",{ type: 'custom', message: '数字 0 ~ 1 を入力してください。' });
            }
            else if((setIsDisplayTabConnectTran1 ||setIsDisplayTabConnectTranscott) && selectTab ==2 && (!/^[0-9]*[.]?[0-9]+$/.test(getValues('calcPoint1.loadCurrent').toString()) || Number(getValues('calcPoint1.loadCurrent')) < 0)){
                setError("calcPoint1.loadCurrent",{ type: 'custom', message: '数字 >= 0 を入力してください。' });
            }
            else if((setIsDisplayTabConnectTran1 ||setIsDisplayTabConnectTranscott) && selectTab == 2 && (!/^[0-9]*[.]?[0-9]+$/.test(getValues('calcPoint1.loadCapacity').toString()) || Number(getValues('calcPoint1.loadCapacity')) < 0)){
                setError("calcPoint1.loadCapacity",{ type: 'custom', message: '数字 >= 0 を入力してください。' });
            }
            else if((setIsDisplayTabConnectTran1 ||setIsDisplayTabConnectTranscott) && selectTab == 2 && (!/^[0-9]*[.]?[0-9]+$/.test(getValues('calcPoint1.powerFactor').toString()) || Number(getValues('calcPoint1.powerFactor')) < 0 || Number(getValues('calcPoint1.powerFactor')) > 1)){
                setError("calcPoint1.powerFactor",{ type: 'custom', message: '数字 0 ~ 1 を入力してください。' });
            }
            else if(isInputPercentZ == 1){
                if (Number(getValues('xr')) < 0) {
                   setError("xr",{ type: 'custom', message: '数字 >= 0 を入力してください。' });
               }
               else if (isNaN(Number(getValues('percentZ'))) || Number(getValues('percentZ')) <= 0) {
                   setError("percentZ",{ type: 'custom', message: '数字 > 0 を入力してください。' });
               }
               else if (isNaN(Number(getValues('percentR'))) || Number(getValues('percentR')) < 0) {
                   setError("percentR",{ type: 'custom', message: '数字 >= 0 を入力してください。' });
               }
               else if (isNaN(Number(getValues('percentX'))) || Number(getValues('percentX')) < 0) {
                   setError("percentX",{ type: 'custom', message: '数字 >= 0 を入力してください。' });
               }
               else {
                   setSelectTab(newValue);
               }
           }
           else if (isInputPercentZ == 2){
               if (isNaN(Number(getValues('ohmR'))) || Number(getValues('ohmR')) < 0) {
                   setError("ohmR",{ type: 'custom', message: '数字 >= 0 を入力してください。' });
               }
               else if (isNaN(Number(getValues('ohmX'))) || Number(getValues('ohmX')) < 0) {
                   setError("ohmX",{ type: 'custom', message: '数字 >= 0 を入力してください。' });
               }
               else {
                   setSelectTab(newValue);
               }
           }
            else{
                setSelectTab(newValue);
            }
        }

    };
   
    const handleValueInputPercentZ = (e: any) => {
        setValue(e.target.name, e.target.value)
        let inputPercentZ = +getValues("inputPercentZ") 
        
        if(inputPercentZ === 1){
            setIsInputPercentZ(1);
            doSetValuesImpedance(e,"")
            doSetValueVolDrop(e,1,true)
            doSetValueVolDrop(e,1,false)
            
        }
        else{
            setIsInputPercentZ(2)
            doSetValuesImpedance(e,"")
            doSetValueVolDrop(e,2,true)
            doSetValueVolDrop(e,2,false)
        }
    }
    const handleDisableByPointRegular =(e:any) =>{
        setValue(e.target.name,e.target.checked)
        setIsDisableByPointRegular(e.target.checked);


    }
    //tab 0====================================

    const doGetXR = (e:any) =>{
        setValue(e.target.name, e.target.value)
        let inputPercentZ = +getValues("inputPercentZ")
        let xr = +getValues("xr")
        if(inputPercentZ === 1){
            xr = xr
        }
        else{
            xr = +getValues("ohmX") / +getValues("ohmR")
        }
        xr = BeamsNumber(xr,5)
        return xr

    }
    const doGetPercentZe = (e:any,inputPercentZ:number,voltage:number,stdCapacity:number) =>{
        setValue(e.target.name, e.target.value)
        let percentZ = +getValues("percentZ")
        let ohmX = +getValues("ohmX")
        let ohmR = +getValues("ohmR")
        if(inputPercentZ === 1){
            percentZ = percentZ
        }

        else{
            let percentR = 0
            if(voltage === 0 || ohmR === 0){
                percentR = 0
            }
            else{
                percentR = ohmX * (stdCapacity * 1000) / (voltage * voltage * 10)
            }
            let perentX = 0
            if(voltage === 0 || ohmX === 0){
                perentX = 0
            }
            else{
                perentX = ohmX * (stdCapacity * 1000) / (voltage * voltage * 10)
            }
            percentZ = Math.sqrt(percentR * percentR + perentX * perentX)
        }
        percentZ = BeamsNumber(percentZ,6)
        return percentZ
    } 
    const doGetPercentRe = (e:any,inputPercentZ:number,voltage:number,stdCapacity:number)=>{
        setValue(e.target.name, e.target.value)
        let percentR = +getValues("percentR")
        let ohmR = +getValues("ohmR")
        let ohmX = +getValues("ohmX")
        if(inputPercentZ === 1){
            percentR = percentR
        }
        else{
            if(voltage === 0 || ohmR === 0){
                percentR = 0
            }
            else{
                percentR = ohmX * stdCapacity * 1000 / (voltage *voltage *10)
            }
        }
        return BeamsNumber(percentR,4)
    }
    const doGetPercentXe = (e:any,inputPercentZ:number,voltage:number,stdCapacity:number)=>{
        setValue(e.target.name, e.target.value)
        let percentX = +getValues("percentX")
        let ohmX = +getValues("ohmX")
        if(inputPercentZ === 1){
            percentX = percentX
        }
        else{
            if(voltage === 0 || ohmX === 0){
                percentX = 0
            }
            else{
                percentX = ohmX * (stdCapacity * 1000) / (voltage * voltage * 10)
            }
        }
        return BeamsNumber(percentX,4)
    }
    const doGetValueOhmR =(e:any,voltage:number,stdCapacity:number,percentR:number) =>{
        setValue(e.target.name, e.target.value)
        let inputPercentZ = +getValues("inputPercentZ")
        let ohmR = +getValues("ohmR")
        if(inputPercentZ === 1) {
            if(stdCapacity === 0 || percentR === 0){
                return 0
            }
            ohmR = voltage * voltage / stdCapacity / 1000 * percentR * 10
        }
        else{
            ohmR = ohmR
        }
        ohmR = BeamsNumber(ohmR,4)
        return ohmR
    }
    const doGetValueOhmX =(e:any,voltage:number,stdCapacity:number,percentX:number) =>{
        setValue(e.target.name, e.target.value)
        let inputPercentZ = +getValues("inputPercentZ")
        let ohmX = +getValues("ohmX")
        if( inputPercentZ === 1){
            if(stdCapacity === 0 || percentX === 0){
            return 0
            }
            ohmX = voltage * voltage / stdCapacity / 1000 * percentX * 10
        }
        else{
            ohmX = ohmX
        }
        ohmX = BeamsNumber(ohmX,4)
        return ohmX
    }
    const doGetOhmZ = (e:any,voltage:number,stdCapacity:number,percentR:number,percentX:number) =>{
        setValue(e.target.name, e.target.value)
        let ohmR = doGetValueOhmR(e,voltage,stdCapacity,percentR)
        let ohmX = doGetValueOhmX(e,voltage,stdCapacity,percentX)
        let ohmZ = Math.sqrt(ohmR * ohmR + ohmX * ohmX)
        setValue("ohmZ", BeamsNumber(ohmZ,4))// set
        return BeamsNumber(ohmZ, 4)
    }

    //Main
    const doSetValuesImpedance =(e:any,nControl:string) =>{
        setValue(e.target.name,e.target.value)
        let xr = +getValues("xr")
        let percentZ = +getValues("percentZ")
        let percentR = +getValues("percentR")
        let percentX = +getValues("percentX")
        let voltage = +getValues("voltage")
        
        if(nControl !== "IDC_XR"){
            xr = doGetXR(e)
        }
        let stdCapacity = 0 // set 基準容量
        let inputPercentZ = +getValues("inputPercentZ")
        if(inputPercentZ === 1){
            stdCapacity = +getValues("stdCapacity")
        }else{
            stdCapacity = infoCircuit.stdCapacity//hard code
        }
        if(nControl !== "IDC_PERCENT_Z") {
            percentZ = BeamsNumber(doGetPercentZe(e,inputPercentZ,voltage,stdCapacity),6)
        }
        if(nControl !== "IDC_PERCENT_R"){
            percentR = BeamsNumber(doGetPercentRe(e,inputPercentZ,voltage,stdCapacity),4)
        }
        if(nControl !== "IDC_PERCENT_X"){
            percentX = BeamsNumber(doGetPercentXe(e,inputPercentZ,voltage,stdCapacity),4)
        }
        let ohmZ = doGetOhmZ(e,voltage,stdCapacity,percentR,percentX)
        let ohmR = 0
        let ohmX = 0
        if(nControl !== "IDC_OHM_R"){
            ohmR = doGetValueOhmR(e,voltage,stdCapacity,percentR)
        }
        if(nControl !== "IDC_OHM_X"){
            ohmX = doGetValueOhmX(e,voltage,stdCapacity,percentX)
        }
        // setValue("xr",xr)
        // setValue("percentZ",percentZ)
        // setValue("percentR",percentR)
        // setValue("percentX",percentX)
        setValue("ohmZ",ohmZ)
        // setValue("ohmR",ohmR)
        // setValue("ohmX",ohmX)
    }
    

    const onChangeXr =(e:any) =>{
        setValue(e.target.name,e.target.value)
        let xr = +e.target.value
        
        if(xr == 0) {
            setValue("percentR",BeamsNumber(+getValues("percentZ"),4))
            setValue("percentX",0)
        }
        else if(xr == MS_XR_MAX){//MS_XR_MAX
            setValue("percentR" ,0)
            setValue("percentX",BeamsNumber(+getValues("percentZ"),4))
         }
         else{
            let root = Math.sqrt(1 + xr * xr)
            const percentR = +getValues("percentZ")/root
            setValue("percentR",BeamsNumber(+getValues("percentZ")/root,4))
            let result = Math.sqrt(+getValues("percentZ") * +getValues("percentZ") - percentR * percentR)
            setValue("percentX",BeamsNumber(result,4))
         }
         doSetValuesImpedance(e,"IDC_XR")
        doSetValueVolDrop(e,+getValues("inputPercentZ"),true)
        doSetValueVolDrop(e,+getValues("inputPercentZ"),false)
        clearErrorsImpedance()

}
    const onChangPercentZ = (e:any) => {
        setValue(e.target.name,e.target.value)
        let percentZ = +e.target.value
        let xr = +getValues("xr")
        
        if(xr == 0){
            setValue("percentR",BeamsNumber(percentZ,4))
            setValue("percentX" ,0)
        }
        else if(xr == MS_XR_MAX){
            setValue("percentR" , 0)
            setValue("percentX" ,BeamsNumber(percentZ,4))
        }
        else{
            let root = Math.sqrt(1 + xr * xr)
            const percentR = percentZ/root
            setValue("percentR",BeamsNumber(percentZ/root,4))
            let result  = Math.sqrt(+e.target.value * +e.target.value - percentR * percentR)
            setValue("percentX",BeamsNumber(result,4))
        }
        doSetValuesImpedance(e,"IDC_PERCENT_Z")
        doSetValueVolDrop(e,+getValues("inputPercentZ"),true)
        doSetValueVolDrop(e,+getValues("inputPercentZ"),false)
        clearErrorsImpedance()

    }
    const onChangePercentR =(e:any) =>{
        setValue(e.target.name,e.target.value)
        let percentR = +e.target.value
        
        if(percentR === 0){
            setValue("xr",MS_XR_MAX)
            setValue("percentZ", BeamsNumber(+getValues("percentX"),6))
        }
        else if(+getValues("percentX") == 0){
            setValue("percentZ",BeamsNumber(percentR,6))
            setValue("xr",0)
        }
        else {
            setValue("xr",BeamsNumber(+getValues("percentX")/percentR,5))
            let result  = Math.sqrt(+getValues("percentX")* +getValues("percentX") + percentR * percentR)
            setValue("percentZ",BeamsNumber(result,6))
        }
        doSetValuesImpedance(e,"IDC_PERCENT_R")
        doSetValueVolDrop(e,+getValues("inputPercentZ"),true)
        doSetValueVolDrop(e,+getValues("inputPercentZ"),false)
        clearErrorsImpedance()
        
    }
    const onChangePercentX =(e:any) =>{
        setValue(e.target.name,e.target.value)
        let percentX = +e.target.value
        
        if(percentX == 0) {
            setValue("percentZ",BeamsNumber(+getValues("percentR"),6))
            setValue("xr",0)
        }
        else if(+getValues("percentR") == 0){
            setValue("percentZ",BeamsNumber(percentX,6))
            setValue("xr",MS_XR_MAX)
        }
        else{
            setValue("xr",BeamsNumber(percentX/+getValues("percentR"),5))
            let result = Math.sqrt(percentX * percentX + +getValues("percentR") * +getValues("percentR"))
            setValue("percentZ",BeamsNumber(result,6))
        }
        doSetValuesImpedance(e,"IDC_PERCENT_X")
        doSetValueVolDrop(e,+getValues("inputPercentZ"),true)
        doSetValueVolDrop(e,+getValues("inputPercentZ"),false)
        clearErrorsImpedance()
    }

    const onChangeOhmR = (e:any) =>{
        setValue(e.target.name,e.target.value)
        let ohmR = +getValues("ohmR")
        doSetValuesImpedance(e,"IDC_OHM_R")
        doSetValueVolDrop(e,+getValues("inputPercentZ"),true)
        doSetValueVolDrop(e,+getValues("inputPercentZ"),false)
        
    }

    const onChangeOhmX = (e:any) =>{
        setValue(e.target.name, e.target.value)
        let ohmX = +getValues("ohmX")
        doSetValuesImpedance(e,"IDC_OHM_X")
        
        doSetValueVolDrop(e,+getValues("inputPercentZ"),true)
        doSetValueVolDrop(e,+getValues("inputPercentZ"),false)

        
    }



    //tab1====================================================================
    const doGetPerZrImpedance = (e:any,m_dStdCapacity:number,partKind:number,bTwoWays:boolean,inputPercentZ:number) =>{
        setValue(e.target.name, e.target.value)
        
        let voltage = +getValues("voltage")
        let percentR = +getValues("percentR")
        let percentX = +getValues("percentX")

        let persentZ = {
            dPerRk3:0,
            dPerXk3 :0,
            dPerRk3Difference:0,
            dPerXk3Difference:0,
        }
        let ohmR  = doGetValueOhmR(e,voltage,m_dStdCapacity,percentR)
        let ohmX  = doGetValueOhmX(e,voltage,m_dStdCapacity,percentX)
        if( voltage <= 0){
            persentZ.dPerRk3 = 0
        }
        else{
            if(doSetSPhaseConnect()){
                switch(partKind){
                    case MS_OUTSIDE_NEUTRAL:
                        persentZ.dPerRk3 = m_dStdCapacity * ohmR / (voltage / 2) /(voltage / 2)* 100
                        persentZ.dPerXk3 = m_dStdCapacity * ohmX / (voltage / 2) / (voltage / 2) * 100
                        if(bTwoWays){
                            persentZ.dPerRk3 *= 2
                            persentZ.dPerXk3 *= 2
                        }
                        break
                    case MS_OUTSIDE_OUTSIDE:
                        persentZ.dPerRk3 = m_dStdCapacity * ohmR / voltage / voltage * 100
                        persentZ.dPerXk3 = m_dStdCapacity * ohmX / voltage / voltage * 100
                        if(bTwoWays){
                            persentZ.dPerRk3 *= 2
                            persentZ.dPerXk3 *= 2
                        }
                        persentZ.dPerRk3Difference = m_dStdCapacity * ohmR / (voltage / 2) /(voltage / 2)* 100
                        persentZ.dPerXk3Difference = m_dStdCapacity * ohmX / (voltage / 2) /(voltage / 2)* 100
                        if(bTwoWays){
                            persentZ.dPerRk3Difference *=2
                            persentZ.dPerXk3Difference *= 2
                        }
                        persentZ.dPerRk3Difference = persentZ.dPerRk3 - persentZ.dPerRk3Difference
                        persentZ.dPerXk3Difference = persentZ.dPerXk3 - persentZ.dPerXk3Difference
                        break
                    case MS_SEAT_MAIN:
                    case MS_SEAT_T:
                        persentZ.dPerRk3 =  m_dStdCapacity * ohmR / voltage / voltage * 100
                        persentZ.dPerXk3 =  m_dStdCapacity * ohmX / voltage / voltage * 100
                        if(bTwoWays){
                            persentZ.dPerRk3 *= 2
                            persentZ.dPerXk3 *= 2
                        }
                        break
                }
            }
            else{
                persentZ.dPerRk3 =  m_dStdCapacity * ohmR / voltage / voltage * 100
                persentZ.dPerXk3 =  m_dStdCapacity * ohmX / voltage / voltage * 100
            }
        }
        return persentZ

    }
    const doGetValueDropPerRegular = (e:any,voltage:number,dPerRk3:number,dPerXk3:number,m_nCalcKind:number,leadOrLag:number,powerFactor:number,loadCurrent:number) =>{
        setValue(e.target.name, e.target.value)
        let m_dStdCapacity = infoCircuit.stdCapacity // hard code
        if(powerFactor < 0 || m_dStdCapacity <= 0){
            return 0
        }
        let capacity = voltage * loadCurrent
        switch(m_nCalcKind){
            case CALC_IK3:
            capacity *= Math.sqrt(3.0);
            break;
        case CALC_IK2:
            capacity *= 2;
            break;
        case CALC_IK2_OUTSIDE_NEUTRAL:
            break;
        case CALC_IK2_OUTSIDE_OUTSIDE:
            capacity *= 2;
            break;
        case CALC_IK2_SEAT_MAIN:
            capacity *= 2;
            break;
        case CALC_IK2_SEAT_T:
            capacity *= 2;
            break;
        }
        let percent = 0
        if(powerFactor * powerFactor <= 1){
            if(leadOrLag === 1){
                percent = dPerRk3 * powerFactor - dPerXk3 * Math.sqrt(1- powerFactor * powerFactor) 
            }
            else if(leadOrLag === 0){
                percent = dPerRk3 * powerFactor + dPerXk3 * Math.sqrt(1- powerFactor * powerFactor) 
            }
        }
        percent *= capacity
        percent /= m_dStdCapacity * 1000
        percent /= 100
        if(Math.abs(percent) < VALUE_NODE_VOLT_MIN){
            percent = 0
        }
        return percent

    }
    
    const doGetValueDropVoltRegular = (e:any,voltage:number,dPerRk3:number,dPerXk3:number,m_nCalcKind:number,leadOrLag:number,powerFactor:number,loadCurrent:number) =>{
        setValue(e.target.name, e.target.value)
        let percent = doGetValueDropPerRegular(e,voltage,dPerRk3,dPerXk3,m_nCalcKind,leadOrLag,powerFactor,loadCurrent)
        let dDropVolt = voltage - voltage * percent
        if(Math.abs(dDropVolt) < VALUE_NODE_VOLT_MIN){
            dDropVolt = 0
        }
        return dDropVolt
    }
    const doGetDropVoltDiff =(e:any,m_dStdCapacity:number,voltage:number,partKind:number,inputPercentZ:number,leadOrLag:number,powerFactor:number,loadCurrent:number) =>{
        setValue(e.target.name, e.target.value)
        
        let m_nDowTransKind = getValues("downTransKind")
        let percentZ = doGetPerZrImpedance(e,m_dStdCapacity,partKind,false,inputPercentZ)
        let dVolt = 0
        if(doSetSPhaseConnect()){
            switch(partKind){
                case MS_OUTSIDE_NEUTRAL:
                case MS_SEAT_MAIN:
                        dVolt = doGetValueDropVoltRegular(e,voltage,percentZ.dPerRk3,percentZ.dPerXk3,getValues("calcPoint1.calcKind"),leadOrLag,powerFactor,loadCurrent) 
                    break
                case MS_OUTSIDE_OUTSIDE:
                case MS_SEAT_T:
                    dVolt = doGetValueDropVoltRegular(e,voltage,percentZ.dPerRk3,percentZ.dPerXk3,getValues("calcPoint0.calcKind"),leadOrLag,powerFactor,loadCurrent) 
                    break
                }
        }
        else{
            if(m_nDowTransKind === MS_TRANS1){
                dVolt = doGetValueDropVoltRegular(e,voltage,percentZ.dPerRk3,percentZ.dPerXk3,getValues("calcPoint1.calcKind"),leadOrLag,powerFactor,loadCurrent) 
            }
            else{
                dVolt = doGetValueDropVoltRegular(e,voltage,percentZ.dPerRk3,percentZ.dPerXk3,getValues("calcPoint0.calcKind"),leadOrLag,powerFactor,loadCurrent) 
                
            }
        }
        dVolt = voltage - dVolt

        return BeamsNumber(dVolt,4)

    }
    const doGetDropPerUnit = (e:any,m_dStdCapacity:number,partKind:number,inputPercentZ:number,leadOrLag:number,powerFactor:number,loadCurrent:number) =>{
        setValue(e.target.name, e.target.value)
        let percentZ = doGetPerZrImpedance(e,m_dStdCapacity,partKind,false,inputPercentZ)
        let voltage = +getValues("voltage")
        let dVolt = 0
        let percent = 0
       
        let m_nDownTransKind = getValues("downTransKind")
        if(doSetSPhaseConnect()){
            switch(partKind){
                case MS_OUTSIDE_NEUTRAL:
                    setValue("calcPoint1.stdCapacity",m_dStdCapacity)
                    dVolt = doGetValueDropVoltRegular(e,voltage/2,percentZ.dPerRk3,percentZ.dPerXk3,getValues("calcPoint1.calcKind"),leadOrLag,powerFactor,loadCurrent)
                    percent = 1 - dVolt / (voltage /2)
                    break;
                case MS_SEAT_MAIN:
                    setValue("calcPoint1.stdCapacity",m_dStdCapacity)
                    dVolt = doGetValueDropVoltRegular(e,voltage,percentZ.dPerRk3,percentZ.dPerXk3,getValues("calcPoint1.calcKind"),leadOrLag,powerFactor,loadCurrent)
                    percent = 1 - dVolt / voltage
                    break;
                case MS_OUTSIDE_OUTSIDE:
                    setValue("calcPoint0.stdCapacity",m_dStdCapacity)
                    dVolt = doGetValueDropVoltRegular(e,voltage,percentZ.dPerRk3,percentZ.dPerXk3,getValues("calcPoint0.calcKind"),leadOrLag,powerFactor,loadCurrent)
                    percent = 1 - dVolt / voltage
                    break;
                case MS_SEAT_T:
                    setValue("calcPoint0.stdCapacity",m_dStdCapacity)
                    dVolt = doGetValueDropVoltRegular(e,voltage,percentZ.dPerRk3,percentZ.dPerXk3,getValues("calcPoint0.calcKind"),leadOrLag,powerFactor,loadCurrent)
                    percent = 1 - dVolt / voltage
                    break;
            }
        }
        else{
            if(m_nDownTransKind === MS_TRANS1){
                setValue("calcPoint1.stdCapacity",m_dStdCapacity)

                dVolt = doGetValueDropVoltRegular(e,voltage,percentZ.dPerRk3,percentZ.dPerXk3,getValues("calcPoint1.calcKind"),leadOrLag,powerFactor,loadCurrent)
            }
            else{
                setValue("calcPoint0.stdCapacity",m_dStdCapacity)

                dVolt = doGetValueDropVoltRegular(e,voltage,percentZ.dPerRk3,percentZ.dPerXk3,getValues("calcPoint0.calcKind"),leadOrLag,powerFactor,loadCurrent)

            }
            percent = 1 - dVolt / voltage

        }
        percent *= 100

        return BeamsNumber(percent,4)

    }
    const doSetSPhaseConnect =()=>{
        let sPhase = false
        if(setIsDisplayTabConnectTran1 || setIsDisplayTabConnectTranscott){
            sPhase = true
        }else{
            sPhase = false
        }
        return sPhase
    }
    const doSetValueVolDrop = (e:any,inputPercentZ:number,isConnectTrans1OrTranscott:boolean) =>{
        setValue(e.target.name, e.target.value)
        let voltage = +getValues("voltage")
        
        let m_nUptransKind = getValues("upTransKind") 
        const m_dStdCapacity = infoCircuit.stdCapacity//hard code
        
        if(isConnectTrans1OrTranscott){
            let voltageDrop1_2 = 0
            let voltageDrop2 = 0
            if(doSetSPhaseConnect()){
                switch(m_nUptransKind){
                    case MS_TRANS1:
                        voltageDrop1_2 = doGetDropVoltDiff(e,m_dStdCapacity,voltage/2,MS_OUTSIDE_NEUTRAL,inputPercentZ,+getValues("leadOrLag2"),+getValues("calcPoint1.powerFactor"),+getValues("calcPoint1.loadCurrent"))
                        voltageDrop2 = doGetDropPerUnit (e,m_dStdCapacity,MS_OUTSIDE_NEUTRAL,inputPercentZ,+getValues("leadOrLag2"),+getValues("calcPoint1.powerFactor"),+getValues("calcPoint1.loadCurrent"))
                        break;
                    case MS_TRANSSCOTT:
                        voltageDrop1_2 = doGetDropVoltDiff(e,m_dStdCapacity,voltage,MS_SEAT_MAIN,inputPercentZ,+getValues("leadOrLag2"),+getValues("calcPoint1.powerFactor"),+getValues("calcPoint1.loadCurrent"))
                        voltageDrop2 = doGetDropPerUnit (e,m_dStdCapacity,MS_SEAT_MAIN,inputPercentZ,+getValues("leadOrLag2"),+getValues("calcPoint1.powerFactor"),+getValues("calcPoint1.loadCurrent"))
                        break;
                }
            }
            else{
                voltageDrop1_2 = doGetDropVoltDiff(e,m_dStdCapacity,voltage,MS_3PHASES,inputPercentZ,+getValues("leadOrLag2"),+getValues("calcPoint1.powerFactor"),+getValues("calcPoint1.loadCurrent"))
                voltageDrop2 = doGetDropPerUnit (e,m_dStdCapacity,MS_3PHASES,inputPercentZ,+getValues("leadOrLag2"),+getValues("calcPoint1.powerFactor"),+getValues("calcPoint1.loadCurrent"))
    
                }
            setValue("voltageDrop_1_2",voltageDrop1_2)
            setValue("voltageDrop2",voltageDrop2)
        }
        else{
            let voltageDrop1 = 0
            let voltageDrop = 0
                if(doSetSPhaseConnect()){
                    switch(m_nUptransKind){
                        case MS_TRANS1:
                            voltageDrop1 = doGetDropVoltDiff(e,m_dStdCapacity,voltage,MS_OUTSIDE_OUTSIDE,inputPercentZ,+getValues("leadOrLag1"),+getValues("calcPoint0.powerFactor"),+getValues("calcPoint0.loadCurrent"))
                            voltageDrop = doGetDropPerUnit (e,m_dStdCapacity,MS_OUTSIDE_OUTSIDE,inputPercentZ,+getValues("leadOrLag1"),+getValues("calcPoint0.powerFactor"),+getValues("calcPoint0.loadCurrent"))
                            break;
                        case MS_TRANSSCOTT:
                            voltageDrop1 = doGetDropVoltDiff(e,m_dStdCapacity,voltage,MS_SEAT_T,inputPercentZ,+getValues("leadOrLag1"),+getValues("calcPoint0.powerFactor"),+getValues("calcPoint0.loadCurrent"))
                            voltageDrop = doGetDropPerUnit (e,m_dStdCapacity,MS_SEAT_T,inputPercentZ,+getValues("leadOrLag1"),+getValues("calcPoint0.powerFactor"),+getValues("calcPoint0.loadCurrent"))
                            break;
                    }
                }
                else{
                    voltageDrop1 = doGetDropVoltDiff(e,m_dStdCapacity,voltage,MS_3PHASES,inputPercentZ,+getValues("leadOrLag1"),+getValues("calcPoint0.powerFactor"),+getValues("calcPoint0.loadCurrent"))
                    voltageDrop = doGetDropPerUnit (e,m_dStdCapacity,MS_3PHASES,inputPercentZ,+getValues("leadOrLag1"),+getValues("calcPoint0.powerFactor"),+getValues("calcPoint0.loadCurrent"))
            }
            setValue("voltageDrop_1",voltageDrop1)
            setValue("voltageDrop",voltageDrop)
        }
        
    }
    const doSetValueDropLoadCapacity = (e:any,voltage:number,m_nCalcKind:number,loadCurrent:number) =>{
        setValue(e.target.name, e.target.value)
        let loadCapacity = voltage * loadCurrent
        // let m_nCalcKind = 10
        switch(m_nCalcKind) {
            case CALC_IK3:
                loadCapacity *= Math.sqrt(3.0)
                break;
            case CALC_IK2:
                loadCapacity = loadCapacity
                break
            case CALC_IK2_OUTSIDE_NEUTRAL:
                loadCapacity = loadCapacity
                break
            case CALC_IK2_OUTSIDE_OUTSIDE:
                loadCapacity = loadCapacity
                break
            case CALC_IK2_SEAT_MAIN:
                loadCapacity = loadCapacity
                break
            case CALC_IK2_SEAT_T:
                loadCapacity = loadCapacity
                break
            
        }
        return loadCapacity

    }
    const onChangeLoadCurrent =(e:any,isConnectTrans1OrTranscott:boolean) =>{
        setValue(e.target.name, e.target.value)
        let inputPercentZ = +getValues("inputPercentZ")
        let voltage = +getValues("voltage")
        let m_nUptransKind = getValues("upTransKind")
        
        if(isConnectTrans1OrTranscott){
            setValue("inputedCurrent2",true)
            setValue("inputedCapacity2",false)
        }
        else{
            setValue("inputedCurrent1",true)
            setValue("inputedCapacity1",false)
        }
        if(isConnectTrans1OrTranscott){
            if(+getValues("calcPoint1.loadCurrent") >=0){
                let loadCapacity = 0
                setValue("calcPoint1.loadCurrent",+getValues("calcPoint1.loadCurrent"))
                if(doSetSPhaseConnect() && m_nUptransKind === MS_TRANS1){
                     loadCapacity  = doSetValueDropLoadCapacity(e,voltage/2,getValues("calcPoint1.calcKind"),+getValues("calcPoint1.loadCurrent"))
                }
                else{
                     loadCapacity  = doSetValueDropLoadCapacity(e,voltage,getValues("calcPoint1.calcKind"),+getValues("calcPoint1.loadCurrent"))
                }
                setValue("calcPoint1.loadCapacity",BeamsNumber(loadCapacity,7))
            }
            doSetValueVolDrop(e,inputPercentZ,isConnectTrans1OrTranscott)
        }
        else{
            if(+getValues("calcPoint0.loadCurrent") >=0){
                setValue("calcPoint0.loadCurrent",+getValues("calcPoint0.loadCurrent"))
                let loadCapacity  = doSetValueDropLoadCapacity(e,voltage,getValues("calcPoint0.calcKind"),+getValues("calcPoint0.loadCurrent"))
                setValue("calcPoint0.loadCapacity",BeamsNumber(loadCapacity,7))
            }
            doSetValueVolDrop(e,inputPercentZ,isConnectTrans1OrTranscott)
        }

        
        
    }
    const doSetValueDropLoadCurrent = (e:any,m_nCalcKind:number,voltage:number,loadCapacity:number) =>{
        let loadCurrent = loadCapacity /voltage
        // let m_nCalcKind = 10
        switch(m_nCalcKind) {
            case CALC_IK3:
                loadCurrent /= Math.sqrt(3.0)
                break;
            case CALC_IK2:
                break
            case CALC_IK2_OUTSIDE_NEUTRAL:
                break
            case CALC_IK2_OUTSIDE_OUTSIDE:
                break
            case CALC_IK2_SEAT_MAIN:
                break
            case CALC_IK2_SEAT_T:
                break
            
        }
        return loadCurrent
    }
    const onEnChangeLoadCapacity =(e:any,isConnectTrans1OrTranscott:boolean) =>{
        setValue(e.target.name, e.target.value)
        let inputPercentZ = +getValues("inputPercentZ")
        let m_nUptransKind = getValues("upTransKind")
        
        if(isConnectTrans1OrTranscott){
            setValue("inputedCapacity2",true)
            setValue("inputedCurrent2",false)
        }
        else{
            setValue("inputedCapacity1",true)
            setValue("inputedCurrent1",false)
        }
        if(isConnectTrans1OrTranscott){
            if(+getValues("calcPoint1.loadCapacity") >= 0){
                let loadCurrent =0
                setValue("calcPoint1.loadCapacity",+getValues("calcPoint1.loadCapacity"))
                if(doSetSPhaseConnect() && m_nUptransKind === MS_TRANS1){
                    loadCurrent = doSetValueDropLoadCurrent(e,getValues("calcPoint1.calcKind"),getValues("voltage")/2,+getValues("calcPoint1.loadCapacity"))
                }
                else{
                    loadCurrent = doSetValueDropLoadCurrent(e,getValues("calcPoint1.calcKind"),getValues("voltage"),+getValues("calcPoint1.loadCapacity"))
                }
                setValue("calcPoint1.loadCurrent",BeamsNumber(loadCurrent,7))
            }
            doSetValueVolDrop(e,inputPercentZ,isConnectTrans1OrTranscott)
        }
        else{
            if(+getValues("calcPoint0.loadCapacity") >= 0){
                setValue("calcPoint0.loadCapacity",+getValues("calcPoint0.loadCapacity"))
                let loadCurrent = doSetValueDropLoadCurrent(e,getValues("calcPoint0.calcKind"),getValues("voltage"),+getValues("calcPoint0.loadCapacity"))
                setValue("calcPoint0.loadCurrent",BeamsNumber(loadCurrent,7))
            }
            
            doSetValueVolDrop(e,inputPercentZ,isConnectTrans1OrTranscott)

        }
    }
    const onBnClickedLeadOrLag =(e:any,isConnectTrans1OrTranscott:boolean) =>{
        setValue(e.target.name, e.target.value)
        let inputPercentZ = +getValues("inputPercentZ")
        if(isConnectTrans1OrTranscott){
            if(+getValues("leadOrLag2") === 1){
                setValue("inputedPowerFactor2", true)
                setValue("calcPoint1.isLead",true)
              }
             else if(+getValues("leadOrLag2") == 0){
                setValue("inputedPowerFactor2", true)
                setValue("calcPoint1.isLead",false)
             }
            doSetValueVolDrop(e,inputPercentZ,isConnectTrans1OrTranscott)
        }
         else{
             if(+getValues("leadOrLag1") == 1){
                 setValue("inputedPowerFactor1", true)
                 setValue("calcPoint0.isLead",true)
               }
              else if( +getValues("leadOrLag1") == 0){
                 setValue("inputedPowerFactor1", true)
                 setValue("calcPoint0.isLead",false)
              }
            doSetValueVolDrop(e,inputPercentZ,isConnectTrans1OrTranscott)
         }

    }
    const onChangePowerFactor = (e:any,isConnectTrans1OrTranscott:boolean) =>{
        setValue(e.target.name, e.target.value)
        let inputPercentZ = +getValues("inputPercentZ")
        let upTransKind = getValues("upTransKind")
        if(isConnectTrans1OrTranscott){ 
            setValue("inputedPowerFactor2",true)
            setValue("calcPoint1.powerFactor",+getValues("calcPoint1.powerFactor"))
            doSetValueVolDrop(e,inputPercentZ,isConnectTrans1OrTranscott)

        }
        else{
            setValue("inputedPowerFactor1",true)
            setValue("calcPoint0.powerFactor",+getValues("calcPoint0.powerFactor"))
            doSetValueVolDrop(e,inputPercentZ,isConnectTrans1OrTranscott)
        }
    }
    const doGetTextLoadCurrent =(nPartKind:number) =>{
        let loadCurrent = 0
       
        if(doSetSPhaseConnect()){
            switch(nPartKind){
                case MS_OUTSIDE_NEUTRAL:
                case MS_SEAT_MAIN:
                    loadCurrent = getValues("calcPoint1.loadCurrent")
                    break;
                case MS_OUTSIDE_OUTSIDE:
                case MS_SEAT_T:
                    loadCurrent = getValues("calcPoint0.loadCurrent")
                    break;
            }
        }
        else{
            if(getValues("downTransKind")=== MS_TRANS1){
                loadCurrent = getValues("calcPoint1.loadCurrent")
            }
            else{
                loadCurrent = getValues("calcPoint0.loadCurrent")
            }
        }
        return loadCurrent//5
    }
    const onBnClickedGetLoadCurrent1 = (e:any,isConnectTrans1OrTranscott:boolean) =>{ //first button 概算値
        setValue(e.target.name, e.target.value)
        clearErrors("calcPoint0.loadCurrent")
        clearErrors("calcPoint0.loadCapacity")

        
        let voltage = +getValues("voltage")
        let inputPercentZ = +getValues("inputPercentZ")
        let upTransKind = getValues("upTransKind")
        let loadCapacity = 0
        let loadCurrent = 0
        setValue("inputedCapacity1",false)
        setValue("inputedCurrent1",false)
        setValue("calcPoint0.loadCurrent",dispInputDialog.load_current_0)
        if(doSetSPhaseConnect()){
            switch(upTransKind){
                case MS_TRANS1:
                    loadCurrent = doGetTextLoadCurrent(MS_OUTSIDE_OUTSIDE)
                    break
                case MS_TRANSSCOTT:
                    loadCurrent = doGetTextLoadCurrent(MS_SEAT_T)
                    break
                }
            }
        else{
            loadCurrent  = doGetTextLoadCurrent(MS_3PHASES)
        }
        loadCapacity = doSetValueDropLoadCapacity(e,voltage,getValues("calcPoint0.calcKind"),dispInputDialog.load_current_0)
        loadCapacity = BeamsNumber(loadCapacity,7)
        setValue("calcPoint0.loadCurrent",BeamsNumber(loadCurrent,5))
        setValue("calcPoint0.loadCapacity",loadCapacity)
        doSetValueVolDrop(e,inputPercentZ,isConnectTrans1OrTranscott)
    }
    const onBnClickedGetLoadCurrent2 = (e:any,isConnectTrans1OrTranscott:boolean) =>{ //first button 概算値
        setValue(e.target.name, e.target.value)
        clearErrors("calcPoint1.loadCurrent")
        clearErrors("calcPoint1.loadCapacity")
        let voltage = +getValues("voltage")
        let inputPercentZ = +getValues("inputPercentZ")
        let upTransKind = getValues("upTransKind")
        let loadCapacity = 0
        let loadCurrent = 0
        setValue("inputedCapacity2",false)
        setValue("inputedCurrent2",false)
        setValue("calcPoint1.loadCurrent",dispInputDialog.load_current_1)
        if(doSetSPhaseConnect()){
            switch(upTransKind){
                case MS_TRANS1:
                    loadCurrent = doGetTextLoadCurrent(MS_OUTSIDE_NEUTRAL)
                    break
                case MS_TRANSSCOTT:
                    loadCurrent = doGetTextLoadCurrent(MS_SEAT_MAIN)
                    break
                }
            }
        else{
            loadCurrent  = doGetTextLoadCurrent(MS_3PHASES)
        }
        if(doSetSPhaseConnect() && getValues("upTransKind")=== MS_TRANS1){
            loadCapacity = doSetValueDropLoadCapacity(e,voltage/2,getValues("calcPoint1.calcKind"),dispInputDialog.load_current_1)
        }
        loadCapacity = BeamsNumber(loadCapacity,7)
        setValue("calcPoint1.loadCurrent",BeamsNumber(loadCurrent,5))
        setValue("calcPoint1.loadCapacity",loadCapacity)
        doSetValueVolDrop(e,inputPercentZ,isConnectTrans1OrTranscott)
    }
    const doGetTextDropVoltPF =(nPartKind :number) =>{
        let powerFactor = 0
        
        if(doSetSPhaseConnect()){
            switch(nPartKind){
                case MS_OUTSIDE_NEUTRAL:
                case MS_SEAT_MAIN:
                    powerFactor = getValues("calcPoint1.powerFactor")
                    break
                case MS_OUTSIDE_OUTSIDE:
                case MS_SEAT_T:
                    powerFactor = getValues("calcPoint0.powerFactor")
                    break
            }
        }
        else{
            if(getValues("downTransKind") === MS_TRANS1){
                powerFactor = getValues("calcPoint1.powerFactor")
            }
            else{
                powerFactor = getValues("calcPoint0.powerFactor")
            }
        }
        return powerFactor//5
    }
    const onBnClickedGetPowerFactor =(e:any,isConnectTrans1OrTranscott:boolean) =>{
        setValue(e.target.name, e.target.value)
        
        let inputPercentZ = +getValues("inputPercentZ")
        let upTransKind = getValues("upTransKind")
        if(isConnectTrans1OrTranscott){ 
            clearErrors("calcPoint1.powerFactor")
            let powerFactor = 0
            setValue("inputedPowerFactor2",false)
            setValue("calcPoint1.isLead",dispInputDialog.is_lead_1)
            setValue("calcPoint1.powerFactor",dispInputDialog.power_factor_1)
            switch(getValues("calcPoint1.isLead")){
                case true:
                    setValue("leadOrLag2",1)
                    break
                default:
                    setValue("leadOrLag2",0)
                    break
            }
            if(doSetSPhaseConnect()) {
                switch(upTransKind){
                    case MS_TRANS1:
                        powerFactor = doGetTextDropVoltPF(MS_OUTSIDE_NEUTRAL)
                        break
                    case MS_TRANSSCOTT:
                        powerFactor = doGetTextDropVoltPF(MS_SEAT_MAIN)
                        break
                }
            }
            else{
                powerFactor = doGetTextDropVoltPF(MS_3PHASES)
            }
            setValue("calcPoint1.powerFactor",BeamsNumber(powerFactor,5))
            doSetValueVolDrop(e,inputPercentZ,isConnectTrans1OrTranscott)

        }
        else{
            clearErrors("calcPoint0.powerFactor")
            let powerFactor = 0
            setValue("inputedPowerFactor1",false)
            setValue("calcPoint0.isLead",dispInputDialog.is_lead_0)
            setValue("calcPoint0.powerFactor",dispInputDialog.power_factor_0)
            switch(getValues("calcPoint0.isLead")){
                case true:
                    setValue("leadOrLag1",1)
                    break
                default:
                    setValue("leadOrLag1",0)
                    break
            }
            if(doSetSPhaseConnect()) {
                switch(upTransKind){
                    case MS_TRANS1:
                        powerFactor = doGetTextDropVoltPF(MS_OUTSIDE_OUTSIDE)
                        break
                    case MS_TRANSSCOTT:
                        powerFactor = doGetTextDropVoltPF(MS_SEAT_T)
                        break
                }
            }
            else{
                powerFactor = doGetTextDropVoltPF(MS_3PHASES)
            }
            setValue("calcPoint0.powerFactor",BeamsNumber(powerFactor,5))
            doSetValueVolDrop(e,inputPercentZ,isConnectTrans1OrTranscott)
        }
    }
    const clearErrorsImpedance = () =>{
        if(getValues('xr') >= 0){
            clearErrors('xr')
        }
        if(getValues('percentR') >= 0){
            clearErrors('percentR')
        }
        if(getValues('percentX') >= 0){
            clearErrors('percentX')
        }
        if(getValues('percentZ') > 0){
            clearErrors('percentZ')
        }
    }
    
    return (
        <>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={selectTab} onChange={handleChange}
                        variant="scrollable" 
                        scrollButtons 
                        allowScrollButtonsMobile 
                        sx={{
                            '& .MuiButtonBase-root.MuiTabScrollButton-root.Mui-disabled': {
                                display: "none"
                            }
                        }}
                    >
                        <Tab label="基本" {...a11yProps(0)} />
                        {setIsDisplayTabConnectTran1 && <Tab label="電圧降下-[外線‐中性線]" {...a11yProps(1)}/>||
                        setIsDisplayTabConnectTranscott && <Tab label="電圧降下-[T座]" {...a11yProps(1)} />||
                        <Tab label="電圧降下" {...a11yProps(1)} />}
                        {setIsDisplayTabConnectTran1 && <Tab label="電圧降下-[外線‐外線]" {...a11yProps(2)} />||
                        setIsDisplayTabConnectTranscott && <Tab label="電圧降下-[主座]" {...a11yProps(2)} />}
                        
                        {userRole != Constant.ROLE_LP && <Tab label="潮流解析" {...a11yProps(3)} />}
                    </Tabs>
                </Box>
                <form onSubmit={handleSubmit(handleEditSubmit)} style={{ width: '100%', margin: 'auto' }}>
                    <div style={{ height: '40vh', width: '100%', margin: 'auto', overflow: 'auto' }}>

                {/* tab0 */}
                <TabPanel value={selectTab} index={0}>
                    <Stack spacing={0.3}>
                        <Grid container>
                            <Grid item xs={7}>
                            <Stack sx={{ flexDirection: "row", alignItems:"center" ,mt:"5px"}}>
                                        <Typography variant="body2" width="45%">デバイス名:</Typography>
                                    <Controller
                                        name="refNo"
                                        control={control}
                                        rules={{
                                            required: '必須項目です。入力してください。',
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                type="text"
                                                size="small"
                                                // label="デバイス名"
                                                error={!!errors?.refNo}
                                                helperText={errors?.refNo?.message}
                                                onBlur={handleChangeFormat}
                                                onFocus={e => e.target.select()}
                                                InputLabelProps={{
                                                    style: { color: 'black' },
                                                }}
                                                InputProps={{sx:{height:32 ,backgroundColor:m_bModePM || modeViewOnly ?'floralwhite':'white', color:modeViewOnly?"grey":"black"}}}
                                                inputProps={{readOnly:modeViewOnly}}
                                                disabled={m_bModePM}
                                            />
                                        )}
                                    />
                                </Stack>
                                <Stack sx={{ flexDirection: "row", alignItems:"center" ,mt:"2px"}}>
                                        <Typography variant="body2" width="45%">事故点名称:</Typography>
                                    <Controller
                                        name="pointText"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                type="text"
                                                size="small"
                                                // label="事故点名称"
                                                onBlur={handleChangeFormat}
                                                onFocus={e => e.target.select()}
                                                InputLabelProps={{
                                                    style: { color: 'black' },
                                                }}
                                                InputProps={{sx:{height:32 ,backgroundColor:m_bModePM || modeViewOnly?'floralwhite':'white', color:modeViewOnly?"grey":"black"}}}
                                                disabled={m_bModePM}
                                                inputProps={{readOnly:modeViewOnly}}

                                            />
                                        )}
                                    />
                                </Stack>
                                <Stack sx={{ flexDirection: "row", alignItems:"center" ,mt:"2px"}}>
                                        <Typography variant="body2" width="45%">回路電圧(V):</Typography>
                                    <FormControl fullWidth size="small">               
                                        <Controller
                                            name="voltage"
                                            control={control}
                                            rules={{
                                                required: '必須項目です。入力してください。' ,
                                                pattern:{
                                                   value: /^[0-9\b]+$/,
                                                   message: '数字を入力してください。'
                                                },
                                                validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                            //     maxLength: {
                                            //        value: 6,
                                            //        message: "最大長さ <= 6 を入力してください。"
                                            //    }
                                                   }}
                                            render={({ field }: any) => (
                                                <TextFieldOptions
                                                                {...field}
                                                                options={initialValue.voltageList}
                                                                // label="回路電圧(V)"
                                                                // onChange = {handleChangeFormat}
                                                                size="small"
                                                                setValue={(name: any, value: any) => {
                                                                    field.onChange({target:{name:name,value:value}});
                                                                    handleChangeFormat({target:{name:name,value:value}});
                                                                    doSetValuesImpedance({target:{name:name,value:value}},"");
                                                                    }}
                                                                height={32}
                                                                error={!!errors?.voltage}
                                                                helperText={errors?.voltage?.message}
                                                                maxLength={6}
                                                                disabled={getValues("isSourceVoltage")||m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}
                                                                value={(setIsDisplayTabConnectTran1||setIsDisplayTabConnectTranscott)?setIsVoltText:getValues("voltage")}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Stack>
                            </Grid>
                            <Grid item xs={5} pl={1}>
                                <Stack>
                                    <Controller
                                        name="fault"
                                        control={control}
                                        render={({ field }: any) => (
                                            <FormControlLabel 
                                                {...field} 
                                                control={<Checkbox style={checkboxStyles}/>} 
                                                checked={field.value} 
                                                label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>短絡電流を計算</Typography>}
                                                name='fault' 
                                                onClick={handleChangeFormat} 
                                                disabled={m_bModePM || modeViewOnly}
                                            />
                                        )}
                                    />
                                </Stack>
                                {/* NOTE: different from busbar/busduct/... */}
                                {/* Mode LP */}
                                {userRole == Constant.ROLE_LP &&
                                <Stack>
                                    <Controller
                                        name="viewFlowResult"        
                                        control={control}
                                        render={({ field }: any) => (
                                            <FormControlLabel 
                                                {...field} 
                                                checked={field.value}
                                                label={<Typography variant="body2" width="100%" color={m_bModePM?'grey':'black'}>潮流解析結果を表示</Typography>}
                                                name='viewFlowResult'
                                                onClick={handleChangeFormat}
                                                control={<Checkbox style={checkboxStyle} />} 
                                                disabled={m_bModePM || modeViewOnly}

                                            />
                                        )}
                                    />
                                </Stack>
                                }

                                <Stack>
                                    <Controller
                                        name="viewResultText"
                                        control={control}
                                        render={({ field }: any) => (
                                            <FormControlLabel 
                                                {...field} 
                                                control={<Checkbox style={checkboxStyles} />} 
                                                checked={field.value} 
                                                label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>注釈を表示</Typography>}
                                                name='viewResultText' 
                                                onClick={handleChangeFormat} 
                                                disabled={m_bModePM || modeViewOnly}

                                            />
                                        )}
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid>
                            <fieldset style={{border:'1px solid #AAAA',marginTop:'10px'}}>
                            <legend>{<Typography variant="body2">入力方式</Typography>}</legend>
                            <Stack mb={2}>
                                <Controller
                                    name="inputPercentZ"
                                    control={control}
                                    render={({ field }) => (
                                        <>
                                            <RadioGroup 
                                                {...field} 
                                                row
                                                onChange={(e) =>{handleValueInputPercentZ(e)}}
                                            >
                                                <FormControlLabel 
                                                    value="1" 
                                                    control={<Radio style={radioStyles} />} 
                                                    label={<Typography variant="body2" color={m_bModePM|| processMode > MS_MODE_CHECKING?'grey':'black'}>パーセントインピーダンス(%Z)</Typography>}
                                                    disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}

                                                />
                                                <FormControlLabel 
                                                    value="2" 
                                                    control={<Radio style={radioStyles} />} 
                                                    label={<Typography variant="body2" color={m_bModePM|| processMode > MS_MODE_CHECKING?'grey':'black'}>オーム値</Typography>}
                                                disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}

                                                />
                                            </RadioGroup>
                                        </>
                                    )}
                                />
                            </Stack>
                            {isInputPercentZ == 1 && 
                                <>
                                <fieldset style={{border:'1px solid #AAAA'}}>
                                    <legend>{<Typography variant="body2">パーセントインピーダンス(%Z)</Typography>}</legend>
                                    <Grid container>
                                        <Grid item xs={7}>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" ,mt:"2px"}}>
                                        <Typography variant="body2" width="100%">基準容量(kVA):</Typography>
                                        <Controller
                                            name="stdCapacity"
                                            control={control}
                                            rules={{
                                                pattern:{
                                                   value: /^[0-9]*[.]?[0-9]+$/,
                                                   message: '数字を入力してください。'
                                                },
                                                validate: value => value > 0 || '数字 >  0 を入力してください。' ,
                                            //     maxLength: {
                                            //        value: 6,
                                            //        message: "最大長さ <= 6 を入力してください。"
                                            //    }
                                                   }}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    // type="text"
                                                    size="small"
                                                    // label="基準容量(kVA)"
                                                    
                                                    // onBlur={handleChangeFormat}
                                                    onFocus={e => e.target.select()}
                                                    InputLabelProps={{
                                                        style: { color: 'black' },
                                                    }}
                                                    onChange={(e) =>{
                                                        field.onChange(e);
                                                    doSetValuesImpedance(e,"");
                                                    handleChangeFormat(e);
                                                    }}
                                                    error={!!errors?.stdCapacity}
                                                    helperText={errors?.stdCapacity?.message}
                                                    InputProps={{sx:{height:32,backgroundColor:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white', color:modeViewOnly?"grey":"black"}}}
                                                    inputProps={{maxLength:6, readOnly:modeViewOnly}}    
                                                    disabled={m_bModePM|| processMode > MS_MODE_CHECKING}
                                                />
                                            )}
                                        />
                                    </Stack>
                                    </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={7}>
                                    <Stack sx={{ flexDirection: "row", alignItems:"center" ,mt:"2px"}}>
                                        <Typography variant="body2" width="100%">X/R:</Typography>
                                        <Controller
                                            name="xr"
                                            control={control}
                                            rules={{
                                                pattern:{
                                                   value: /^[0-9]*[.]?[0-9]+$/,
                                                   message: '数字を入力してください。'
                                                },
                                                validate: value => value >= 0 || '数字 >= 0 を入力してください。' ,
                                            //     maxLength: {
                                            //        value: 5,
                                            //        message: "最大長さ <= 5 を入力してください。"
                                            //    }
                                                   }}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    // type="text"
                                                    size="small"
                                                    // label="X/R"
                                                    
        
                                                    onFocus={e => e.target.select()}
                                                    InputLabelProps={{
                                                        style: { color: 'black' },
                                                    }}
                                                    onChange={(e) =>{
                                                        field.onChange();
                                                        onChangeXr(e);handleChangeFormat(e);
                                                        }}
                                                    error={!!errors?.xr}
                                                    helperText={errors?.xr?.message}
                                                    InputProps={{sx:{height:32 ,backgroundColor:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white', color:modeViewOnly?"grey":"black"}}}
                                                    inputProps={{maxLength:5, readOnly: modeViewOnly}}
                                                    disabled={m_bModePM|| processMode > MS_MODE_CHECKING}
                                                />
                                            )}
                                        />
                                    </Stack>
                                    </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={7}>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center",mt:"2px"}}>
                                            <Typography variant="body2" width='100%'>%インピーダンス (%):</Typography>
                                            <Controller
                                                name="percentZ"
                                                control={control}
                                                rules={{
                                                    pattern:{
                                                       value: /^[0-9]*[.]?[0-9]+$/,
                                                       message: '数字を入力してください。'
                                                    },
                                                    validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                //     maxLength: {
                                                //        value: 5,
                                                //        message: "最大長さ <= 5 を入力してください。"
                                                //    }
                                                       }}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        // type="text"
                                                        size="small"
                                                        // label="%インピーダンス (%)"
                                                        
                                                        // onBlur={handleChangeFormat}
                                                        onFocus={e => e.target.select()}
                                                        InputLabelProps={{
                                                            style: { color: 'black' },
                                                        }}
                                                        onChange={(e) =>{
                                                            field.onChange(e);
                                                            onChangPercentZ(e);handleChangeFormat(e);}}
                                                        error={!!errors?.percentZ}
                                                        helperText={errors?.percentZ?.message}
                                                        InputProps={{sx:{height:32,backgroundColor:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white', color:modeViewOnly?"grey":"black"}}}
                                                        inputProps={{maxLength:5, readOnly: modeViewOnly}}
                                                        disabled={m_bModePM|| processMode > MS_MODE_CHECKING}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                        </Grid>
                                        <Grid item xs={0.5} style={{ marginTop: 'auto', marginBottom: 'auto', textAlign: 'center' }}>
                                        <span>=</span>
                                        </Grid>
                                        <Grid item xs={2} >
                                            <Stack>
                                            <Controller
                                                name="percentR"
                                                control={control}
                                                rules={{
                                                    pattern:{
                                                       value: /^[0-9]*[.]?[0-9]+$/,
                                                       message: '数字を入力してください。'
                                                    },
                                                    validate: value => value >= 0 || '数字 >= 0 を入力してください。' ,
                                                //     maxLength: {
                                                //        value: 6,
                                                //        message: "最大長さ <= 6 を入力してください。"
                                                //    }
                                                       }}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        // type="text"
                                                        size="small"
                                                        error={!!errors?.percentR}
                                                        helperText={errors?.percentR?.message}
                                                        // onBlur={handleChangeFormat}
                                                        onFocus={e => e.target.select()}
                                                        InputLabelProps={{
                                                            style: { color: 'black' },
                                                        }}
                                                        onChange ={(e)=>{
                                                            field.onChange(e);
                                                            handleChangeFormat(e);onChangePercentR(e);}}
                                                        InputProps={{sx:{height:32,backgroundColor:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white', color:modeViewOnly?"grey":"black"}}}
                                                        inputProps={{maxLength:6, readOnly:modeViewOnly}}
                                                        disabled={m_bModePM|| processMode > MS_MODE_CHECKING}
                                                    />
                                                )}
                                            />
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={0.5} style={{ marginTop: 'auto', marginBottom: 'auto', textAlign: 'center' }}>
                                        <span>+ j</span>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Stack>
                                            <Controller
                                                name="percentX"
                                                control={control}
                                                rules={{
                                                    pattern:{
                                                       value: /^[0-9]*[.]?[0-9]+$/,
                                                       message: '数字を入力してください。'
                                                    },
                                                    validate: value => value >= 0 || '数字 >= 0 を入力してください。' ,
                                                //     maxLength: {
                                                //        value: 6,
                                                //        message: "最大長さ <= 6 を入力してください。"
                                                //    }
                                                       }}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        // type="text"
                                                        size="small"
                                                        error={!!errors?.percentX}
                                                        helperText={errors?.percentX?.message}
                                                        // onBlur={handleChangeFormat}
                                                        onFocus={e => e.target.select()}
                                                        InputLabelProps={{
                                                            style: { color: 'black' },
                                                        }}
                                                        onChange={(e) =>{
                                                            field.onChange(e);
                                                            handleChangeFormat(e);onChangePercentX(e);}}
                                                        InputProps={{sx:{height:32,backgroundColor:m_bModePM || modeViewOnly|| processMode > MS_MODE_CHECKING?'floralwhite':'white', color:modeViewOnly?"grey":"black"}}}
                                                        inputProps={{maxLength:6, readOnly:modeViewOnly}}
                                                        disabled={m_bModePM|| processMode > MS_MODE_CHECKING}
                                                        />
                                                )}
                                            />
                                        </Stack>
                                        </Grid>
                                        
                                    </Grid>
                                    </fieldset>
                                </>
                            }
                            {isInputPercentZ == 2 && 
                                <Grid container>
                                    <Grid item xs={7}>
                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width='100%'>インピーダンス (mΩ):</Typography>
                                        <Controller
                                            name="ohmZ"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    disabled
                                                    type="text"
                                                    size="small"
                                                    // label="インピーダンス (mΩ2)"
                                                    error={!!errors?.ohmZ}
                                                    helperText={errors?.ohmZ?.message}
                                                    onBlur={handleChangeFormat}
                                                    onFocus={e => e.target.select()}
                                                    InputLabelProps={{
                                                        style: { color: 'black' },
                                                    }}
                                                    InputProps={{sx:{height:32,backgroundColor:m_bModePM?'floralwhite':'white'}}}
                                                />
                                            )}
                                        />
                                    </Stack>
                                    </Grid>
                                    <Grid item xs={0.5} style={{ marginTop: 'auto', marginBottom: 'auto', textAlign: 'center' }}>
                                        <span>=</span>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Stack>
                                        <Controller
                                            name="ohmR"
                                            control={control}
                                            rules={{
                                                pattern:{
                                                   value: /^[0-9]*[.]?[0-9]+$/,
                                                   message: '数字を入力してください。'
                                                },
                                                validate: value => value >= 0 || '数字 >= 0 を入力してください。' ,
                                            //     maxLength: {
                                            //        value: 6,
                                            //        message: "最大長さ <= 6 を入力してください。"
                                            //    }
                                                   }}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    // type="text"
                                                    size="small"
                                                    error={!!errors?.ohmR}
                                                    helperText={errors?.ohmR?.message}
                                                    
                                                    onFocus={e => e.target.select()}
                                                    InputLabelProps={{
                                                        style: { color: 'black' },
                                                    }}
                                                    onChange={(e) =>{
                                                        field.onChange(e);
                                                        onChangeOhmR(e);
                                                        handleChangeFormat(e);
                                                    }}
                                                    InputProps={{sx:{height:32,backgroundColor:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white', color:modeViewOnly?"grey":"black"}}}
                                                    inputProps={{maxLength:6, readOnly: modeViewOnly}}
                                                    disabled={m_bModePM|| processMode > MS_MODE_CHECKING}
                                                />
                                            )}
                                        />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={0.5} style={{ marginTop: 'auto', marginBottom: 'auto', textAlign: 'center' }}>
                                        <span>+ j</span>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Stack>
                                        <Controller
                                            name="ohmX"
                                            control={control}
                                            rules={{
                                                pattern:{
                                                   value: /^[0-9]*[.]?[0-9]+$/,
                                                   message: '数字を入力してください。'
                                                },
                                                validate: value => value >= 0 || '数字 >= 0 を入力してください。' ,
                                            //     maxLength: {
                                            //        value: 6,
                                            //        message: "最大長さ <= 6 を入力してください。"
                                            //    }
                                                   }}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    // type="text"
                                                    size="small"
                                                    error={!!errors?.ohmX}
                                                    helperText={errors?.ohmX?.message}
                                                    // onBlur={handleChangeFormat}
                                                    onFocus={e => e.target.select()}
                                                    InputLabelProps={{
                                                        style: { color: 'black' },
                                                    }}
                                                    onChange={(e) =>{
                                                        field.onChange(e);
                                                        onChangeOhmX(e);
                                                        handleChangeFormat(e);
                                                    }}
                                                    InputProps={{sx:{height:32,backgroundColor:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white', color:modeViewOnly?"grey":"black"}}}
                                                    inputProps={{maxLength:6, readOnly: modeViewOnly}}
                                                    disabled={m_bModePM|| processMode > MS_MODE_CHECKING}

                                                />
                                            )}
                                        />
                                        </Stack>
                                    </Grid>
                                </Grid>
                            }
                            </fieldset>
                        </Grid>
                    </Stack>
                </TabPanel>

                {/* tab1 normal */}
                <TabPanel value={selectTab} index={1}>
                    <Grid container>
                        <Grid item xs={10}>
                            <Controller
                                    name="dropMotor"
                                    control={control}
                                    render={({ field }: any) => (
                                        <FormControlLabel
                                            {...field}
                                            checked={field.value}
                                            label={<Typography  variant="body2" color={m_bModePM|| processMode > MS_MODE_CHECKING?'grey':'black'}>電動機始動時の電圧降下を計算</Typography> }
                                            name='dropMotor'
                                            control={<Checkbox style={checkboxStyles} />}
                                            disabled={(setIsDisplayTabConnectTran1||setIsDisplayTabConnectTranscott||m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING)}
                                        
                                        />
                                    )}
                                />
                        </Grid>
                        <Grid item xs={10}>
                            <Controller
                                    name="dropRegular"
                                    control={control}
                                    render={({ field }: any) => (
                                        <FormControlLabel
                                            {...field}
                                            checked={field.value}
                                            label={<Typography  variant="body2" color={m_bModePM|| processMode > MS_MODE_CHECKING?'grey':'black'}>定常時の電圧降下を計算</Typography> }
                                            name='dropRegular'
                                            onChange={(e) =>handleDisableByPointRegular(e)} 
                                            control={<Checkbox style={checkboxStyles} />}
                                            disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}
                                        />
                                    )}
                                />
                        </Grid>
                        <Grid container>
                            <Grid item xs={7.5}>
                                <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                    <Typography variant="body2" width="90%" ml={5}>負荷容量 (VA):</Typography>  
                                        <Controller
                                            name="calcPoint0.loadCapacity"
                                            control={control}
                                            rules={{
                                       
                                                pattern:{
                                                   value: /^[0-9]*[.]?[0-9]+$/,
                                                   message: '数字を入力してください。'
                                                },
                                                validate: value => value >= 0 || '数字 >= 0 を入力してください。' ,
                                                
                                                   }}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    // type="text"
                                                    size="small"
                                                    // label='負荷容量(VA)'
                                                    onFocus={e => e.target.select()}
                                                    InputLabelProps={{
                                                        style: { color: 'black' },
                                                    }}
                                                        
                                                    onChange={(e) => {field.onChange(e);onEnChangeLoadCapacity(e,false)}}
                                                    InputProps={{sx:{height:32,backgroundColor:!isDisableByPointRegular||m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white', color:modeViewOnly?"grey":"black"}}}
                                                    inputProps={{readOnly:modeViewOnly}}
                                                    error={!!errors?.calcPoint0?.loadCapacity}
                                                    helperText={errors?.calcPoint0?.loadCapacity?.message}
                                                    disabled={!isDisableByPointRegular||m_bModePM|| processMode > MS_MODE_CHECKING}
                                                />
                                            )}
                                        />
                                    </Stack>
                            </Grid>
                            <Grid item xs ={7.5}>
                                <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                    <Typography variant="body2" width="90%" ml={5}>負荷電流 IL(A):</Typography>  
                                    <Controller
                                        name="calcPoint0.loadCurrent"
                                        control={control}
                                        rules={{
                                            pattern:{
                                               value: /^[0-9]*[.]?[0-9]+$/,
                                               message: '数字を入力してください。'
                                            },
                                            validate: value => value >= 0 || '数字 >= 0 を入力してください。' ,
                                        //     maxLength: {
                                        //        value: 7,
                                        //        message: "最大長さ <= 7 を入力してください。"
                                        //    }
                                               }}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                // type="text"
                                                size="small"
                                                // label='負荷電流 IL(A)'
                                                onFocus={e => e.target.select()}
                                                InputLabelProps={{
                                                    style: { color: 'black' },
                                                }}
                                                onChange={(e) =>{field.onChange(e);handleChangeFormat(e);onChangeLoadCurrent(e,false);
                                                    // handleDisableOKBt(e)
                                                }}
                                                InputProps={{sx:{height:32,backgroundColor:!isDisableByPointRegular||m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white', color:modeViewOnly?"grey":"black"}}}
                                                error={!!errors?.calcPoint0?.loadCurrent}
                                                helperText={errors?.calcPoint0?.loadCurrent?.message}
                                                disabled={!isDisableByPointRegular||m_bModePM|| processMode > MS_MODE_CHECKING}
                                                inputProps={{maxLength:7, readOnly: modeViewOnly}}
                                            />
                                        )}
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid container ml={6.5}>
                            <fieldset style={{border:"1px solid #AAAA",width:"53%"}}>
                                <legend><Typography  variant="body2">負荷電流方向</Typography></legend>
                                <Controller
                                        name="topLeft"
                                        control={control}
                                        render={({ field }) => (
                                                <RadioGroup {...field} >
                                                    <FormControlLabel
                                                        value="1"
                                                        control={<Radio style={radioStyles} />}
                                                        label={<Typography  variant="body2" color={m_bModePM|| processMode > MS_MODE_CHECKING?'grey':'black'}>上→下 or 左→右</Typography>}
                                                        disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}
                                                    />
                                                    <FormControlLabel
                                                        value="0"
                                                        control={<Radio style={radioStyles} />}
                                                        label={<Typography  variant="body2" color={m_bModePM|| processMode > MS_MODE_CHECKING?'grey':'black'}>下→上 or 右→左</Typography>}
                                                        disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}

                                                    />
                                                </RadioGroup>
                                        )}
                                    />
                                </fieldset>
                                <Stack ml={1.2}>
                                    <Typography color="common.white">nothing</Typography>
                                    <Typography color="common.white">nothing</Typography>
                                    <Button variant="outlined" onClick={(e) =>onBnClickedGetLoadCurrent1(e,false)} disabled={!isDisableByPointRegular||m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}
                                                    >概算値</Button>
                                </Stack>
                        </Grid>
                        <Grid container ml={3}>
                            <fieldset style={{width:"76%",border:"1px solid #AAAA"}}>
                                <legend>{<Typography variant="body2">回路の力率</Typography> }</legend>
                                <Grid container>
                                    <Grid item xs={5}>
                                        <Controller
                                            name="leadOrLag1"
                                            control={control}
                                            render={({ field }) => (
                                                <RadioGroup {...field} row onChange ={(e) =>{onBnClickedLeadOrLag(e,false)}}>
                                                    <FormControlLabel
                                                        value="1"
                                                        control={<Radio style={radioStyles} />}
                                                        label={<Typography style={{color:!isDisableByPointRegular ||m_bModePM|| processMode > MS_MODE_CHECKING?"grey":"black"}} variant="body2">進み</Typography> }
                                                    disabled={!isDisableByPointRegular||m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}

                                                    />
                                                    <FormControlLabel
                                                        value="0"
                                                        control={<Radio style={radioStyles} />}
                                                        label={<Typography style={{color:!isDisableByPointRegular ||m_bModePM|| processMode > MS_MODE_CHECKING ?"grey":"black"}} variant="body2">遅れ</Typography> }
                                                    disabled={!isDisableByPointRegular||m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}

                                                    />
                                                </RadioGroup>
                                            )}
                                        />
                                    </Grid>
                                    <Grid xs={1}/>
                                    <Grid xs={2.75} ml={1.4}>
                                        <Controller
                                            name="calcPoint0.powerFactor"
                                            control={control}
                                            rules={{
                                                pattern:{
                                                    value: /^[0-9]*[.]?[0-9]+$/,
                                                    message: '数字を入力してください。'
                                                },
                                                validate: value => value >= 0 && value <= 1 || '数字 0 ~ 1 を入力してください。' ,

                                                // maxLength: {
                                                //     value: 7,
                                                //     message: "最大長さ <= 7 を入力してください。"
                                                // }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    // type="text"
                                                    size="small"
                                                    onFocus={e => e.target.select()}
                                                    InputLabelProps={{
                                                        style: { color: 'black' },
                                                    }}
                                                    onChange={(e) =>{field.onChange(e);handleChangeFormat(e);onChangePowerFactor(e,false);
                                                        // handleDisableOKBt(e)
                                                    }}
                                                    InputProps={{sx:{height:32,backgroundColor:!isDisableByPointRegular||m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white', color:modeViewOnly?"grey":"black"}}}
                                                    error={!!errors?.calcPoint0?.powerFactor}
                                                    helperText={errors?.calcPoint0?.powerFactor?.message}
                                                    disabled={!isDisableByPointRegular||m_bModePM|| processMode > MS_MODE_CHECKING}
                                                    inputProps={{maxLength:7, readOnly: modeViewOnly}}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={2.5} ml={1.64}>
                                        <Button style={{margin:"0"}} variant="outlined" onClick={(e) =>onBnClickedGetPowerFactor(e,false)} disabled={!isDisableByPointRegular||m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}
                                            >概算値</Button>
                                    </Grid>
                                </Grid>
                            </fieldset>
                        </Grid>
                        <Grid container  mt={0.25}>
                            <Grid container flexDirection={"row"} ml={6} alignItems={"center"}>
                                <Grid item xs={4} >
                                    <Typography variant="body2" width="100%" >単体での電圧降下(V):</Typography>  
                                </Grid>
                                <Grid item xs={4} ml={-2.6}>
                                    <Controller
                                        name="voltageDrop_1"
                                        control={control}
                                        
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                type="text"
                                                size="small"
                                                onBlur={handleChangeFormat}
                                                onFocus={e => e.target.select()}
                                                InputLabelProps={{ style: { color: 'black'}}}
                                                InputProps={{sx:{height:32}}}
                                                style={{backgroundColor: 'floralwhite',width:"70%",marginLeft:'20%'}}
                                                disabled={true}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container flexDirection={"row"} ml={6} mt={0.25} alignItems={"center"}>
                                <Grid item xs={4} >
                                    <Typography variant="body2" width="100%" >単体での電圧降下率(%):</Typography>  
                                </Grid>
                                <Grid item xs={4} ml={-2.6}>
                                    <Controller
                                        name="voltageDrop"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                type="text"
                                                size="small"
                                                onBlur={handleChangeFormat}
                                                onFocus={e => e.target.select()}
                                                InputLabelProps={{ style: { color: 'black'}}}
                                                InputProps={{sx:{height:32}}}
                                                style={{backgroundColor: 'floralwhite',width:"70%",marginLeft:'20%'}}
                                                disabled={true}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </TabPanel>


                {/* tab2 */}
                {(setIsDisplayTabConnectTran1 || setIsDisplayTabConnectTranscott) &&
                    <TabPanel value={selectTab} index={2}>
                        <Grid container>
                            <Grid item xs={10}>
                                <Controller
                                        name="dropMotor"
                                        control={control}
                                        render={({ field }: any) => (
                                            <FormControlLabel
                                                {...field}
                                                checked={field.value}
                                                label={<Typography  variant="body2" color={m_bModePM|| processMode > MS_MODE_CHECKING?'grey':'black'}>電動機始動時の電圧降下を計算</Typography> }
                                                name='dropMotor'
                                                control={<Checkbox style={checkboxStyles} />}
                                                disabled={(setIsDisplayTabConnectTran1 || setIsDisplayTabConnectTranscott || m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING)}                                            
                                            />
                                        )}
                                    />
                            </Grid>
                            <Grid item xs={10}>
                                <Controller
                                        name="dropRegular"
                                        control={control}
                                        render={({ field }: any) => (
                                            <FormControlLabel
                                                {...field}
                                                checked={field.value}
                                                label={<Typography  variant="body2" color={m_bModePM|| processMode > MS_MODE_CHECKING?'grey':'black'}>定常時の電圧降下を計算</Typography> }
                                                name='dropRegular'
                                                onChange={(e) =>handleDisableByPointRegular(e)} 
                                                control={<Checkbox style={checkboxStyles} />}
                                                disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}
                                            />
                                        )}
                                    />
                            </Grid>
                            <Grid container>
                                <Grid item xs={7.5}>
                                    <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                        <Typography variant="body2" width="90%" ml={5}>負荷容量 (VA):</Typography>  
                                            <Controller
                                                name="calcPoint1.loadCapacity"
                                                control={control}
                                                rules={{
                                        
                                                    pattern:{
                                                    value: /^[0-9]*[.]?[0-9]+$/,
                                                    message: '数字を入力してください。'
                                                    },
                                                    validate: value => value >= 0 || '数字 >= 0 を入力してください。' ,
                                                    
                                                    }}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        // type="text"
                                                        size="small"
                                                        // label='負荷容量(VA)'
                                                        onFocus={e => e.target.select()}
                                                        InputLabelProps={{
                                                            style: { color: 'black' },
                                                        }}
                                                        onChange={(e) =>{field.onChange(e); onEnChangeLoadCapacity(e,true)}}
                                                        InputProps={{sx:{height:32,backgroundColor:!isDisableByPointRegular||m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white', color: modeViewOnly?"grey":"black"}}}
                                                        inputProps={{readOnly:modeViewOnly}}
                                                        error={!!errors?.calcPoint1?.loadCapacity}
                                                        helperText={errors?.calcPoint1?.loadCapacity?.message}
                                                        disabled={!isDisableByPointRegular||m_bModePM|| processMode > MS_MODE_CHECKING}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                </Grid>
                                <Grid item xs ={7.5}>
                                    <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                        <Typography variant="body2" width="90%" ml={5}>負荷電流 IL(A):</Typography>  
                                        <Controller
                                            name="calcPoint1.loadCurrent"
                                            control={control}
                                            rules={{
                                                pattern:{
                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                message: '数字を入力してください。'
                                                },
                                                validate: value => value >= 0 || '数字 >= 0 を入力してください。' ,
                                            //     maxLength: {
                                            //        value: 7,
                                            //        message: "最大長さ <= 7 を入力してください。"
                                            //    }
                                                }}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    // type="text"
                                                    size="small"
                                                    // label='負荷電流 IL(A)'
                                                    onFocus={e => e.target.select()}
                                                    InputLabelProps={{
                                                        style: { color: 'black' },
                                                    }}
                                                    onChange={(e) =>{field.onChange(e);handleChangeFormat(e);onChangeLoadCurrent(e,true);
                                                        // handleDisableOKBt(e)
                                                    }}
                                                    InputProps={{sx:{height:32 ,backgroundColor:!isDisableByPointRegular||m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white', color: modeViewOnly?"grey":"black"}}}
                                                    error={!!errors?.calcPoint1?.loadCurrent}
                                                    helperText={errors?.calcPoint1?.loadCurrent?.message}
                                                    disabled={!isDisableByPointRegular ||m_bModePM|| processMode > MS_MODE_CHECKING}
                                                    inputProps={{maxLength:7, readOnly:modeViewOnly}}

                                                />
                                            )}
                                        />
                                    </Stack>
                                </Grid>
                            </Grid>
                            <Grid container ml={6.5}>
                                <fieldset style={{border:"1px solid #AAAA",width:"53%"}}>
                                    <legend><Typography  variant="body2">負荷電流方向</Typography></legend>
                                    <Controller
                                            name="topLeft"
                                            control={control}
                                            rules={{ required: '必須項目です。入力してください。' }}
                                            render={({ field }) => (
                                                    <RadioGroup {...field} >
                                                        <FormControlLabel
                                                            value="1"
                                                            control={<Radio style={radioStyles} />}
                                                            label={<Typography  variant="body2" color={m_bModePM|| processMode > MS_MODE_CHECKING?'grey':'black'}>上→下 or 左→右</Typography>}
                                                            disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}
                                                        />
                                                        <FormControlLabel
                                                            value="0"
                                                            control={<Radio style={radioStyles} />}
                                                            label={<Typography  variant="body2" color={m_bModePM|| processMode > MS_MODE_CHECKING?'grey':'black'}>下→上 or 右→左</Typography>}
                                                            disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}
                                                        />
                                                    </RadioGroup>
                                            )}
                                        />
                                    </fieldset>
                                    <Stack ml={1.2}>
                                        <Typography color="common.white">nothing</Typography>
                                        <Typography color="common.white">nothing</Typography>
                                        <Button variant="outlined" onClick={(e)=>onBnClickedGetLoadCurrent2(e,true)} disabled={!isDisableByPointRegular||m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}
                                                        >概算値</Button>
                                    </Stack>
                            </Grid>
                            <Grid container ml={3}>
                                <fieldset style={{width:"76%",border:"1px solid #AAAA"}}>
                                    <legend>{<Typography  variant="body2">回路の力率</Typography>}</legend>
                                    <Grid container>
                                        <Grid item xs={5}>
                                            <Controller
                                                name="leadOrLag2"
                                                control={control}
                                                rules={{ required: '必須項目です。入力してください。' }}
                                                render={({ field }) => (
                                                    <RadioGroup {...field} row onChange ={(e) =>{onBnClickedLeadOrLag(e,true)}}>
                                                        <FormControlLabel
                                                            value="1"
                                                            control={<Radio style={radioStyles} />}
                                                            label={<Typography style={{color:!isDisableByPointRegular||m_bModePM|| processMode > MS_MODE_CHECKING?"grey":"black"}} variant="body2">進み</Typography> }
                                                        disabled={!isDisableByPointRegular||m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}

                                                        />
                                                        <FormControlLabel
                                                            value="0"
                                                            control={<Radio style={radioStyles} />}
                                                            label={<Typography style={{color:!isDisableByPointRegular||m_bModePM|| processMode > MS_MODE_CHECKING?"grey":"black"}} variant="body2">遅れ</Typography> }
                                                        disabled={!isDisableByPointRegular||m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}

                                                        />
                                                    </RadioGroup>
                                                )}
                                            />
                                        </Grid>
                                        <Grid xs={1}/>
                                        <Grid xs={2.75} ml={1.4}>
                                            <Controller
                                                name="calcPoint1.powerFactor"
                                                control={control}
                                                rules={{
                                                    pattern:{
                                                        value: /^[0-9]*[.]?[0-9]+$/,
                                                        message: '数字を入力してください。'
                                                    },
                                                    validate: value => value >= 0 && value <= 1 || '数字 0 ~ 1 を入力してください。' ,

                                                    // maxLength: {
                                                    //     value: 7,
                                                    //     message: "最大長さ <= 7 を入力してください。"
                                                    // }
                                                }}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        // type="text"
                                                        size="small"
                                                        onFocus={e => e.target.select()}
                                                        InputLabelProps={{
                                                            style: { color: 'black' },
                                                        }}
                                                        onChange={(e) =>{field.onChange(e);handleChangeFormat(e);onChangePowerFactor(e,true);
                                                            // handleDisableOKBt(e)
                                                        }}
                                                        InputProps={{sx:{height:32, backgroundColor:!isDisableByPointRegular||m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white', color:modeViewOnly?"grey":"black"}}}
                                                        error={!!errors?.calcPoint1?.powerFactor}
                                                        helperText={errors?.calcPoint1?.powerFactor?.message}
                                                        disabled={!isDisableByPointRegular||m_bModePM|| processMode > MS_MODE_CHECKING}
                                                        inputProps={{maxLength:7, readOnly:modeViewOnly}}

                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={2.5} ml={1.64}>
                                            <Button style={{margin:"0"}} variant="outlined" onClick={(e) =>onBnClickedGetPowerFactor(e,true)} disabled={!isDisableByPointRegular || modeViewOnly ||m_bModePM|| processMode > MS_MODE_CHECKING}
                                                >概算値</Button>
                                        </Grid>
                                    </Grid>
                                </fieldset>
                            </Grid>
                            <Grid container  mt={0.25}>
                                <Grid container flexDirection={"row"} ml={6} alignItems={"center"}>
                                    <Grid item xs={4} >
                                        <Typography variant="body2" width="100%" >単体での電圧降下(V):</Typography>  
                                    </Grid>
                                    <Grid item xs={4} ml={-2.6}>
                                        <Controller
                                            name="voltageDrop_1_2"
                                            control={control}
                                            
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    size="small"
                                                    onBlur={handleChangeFormat}
                                                    onFocus={e => e.target.select()}
                                                    InputLabelProps={{ style: { color: 'black'}}}
                                                    InputProps={{sx:{height:32}}}
                                                    style={{backgroundColor: 'floralwhite',width:"70%",marginLeft:'20%'}}
                                                    disabled={true}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container flexDirection={"row"} ml={6} mt={0.25} alignItems={"center"}>
                                    <Grid item xs={4} >
                                        <Typography variant="body2" width="100%" >単体での電圧降下率(%):</Typography>  
                                    </Grid>
                                    <Grid item xs={4} ml={-2.6}>
                                        <Controller
                                            name="voltageDrop2"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    size="small"
                                                    onBlur={handleChangeFormat}
                                                    onFocus={e => e.target.select()}
                                                    InputLabelProps={{ style: { color: 'black'}}}
                                                    InputProps={{sx:{height:32}}}
                                                    style={{backgroundColor: 'floralwhite',width:"70%",marginLeft:'20%'}}
                                                    disabled={true}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </TabPanel>
                    }

                {/* tab 4 */}
                <TabPanel value={selectTab} index={setIsDisplayTabConnectTran1||setIsDisplayTabConnectTranscott?3:2}>
                        <Stack spacing={0.5} style={{ width: '100%',marginTop:'10px' }}>
                            <fieldset style={{width:"75%",border:"1px solid #AAAA"}}>
                        <legend><Typography variant="body2" width="100%"> 対中性点アドミタンス (基準容量に対する pu値)</Typography></legend>
                           <Grid container>
                                <Grid item xs ={10}>
                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                        <Typography variant="body2" width="100%">サセプタンス G(pu値):</Typography>
                                    <Controller
                                        name="flowSusceptance"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                type="text"
                                                size="small"
                                                // label="コンダクタンス G(pu値)"
                                                onBlur={handleChangeFormat}
                                                onFocus={e => e.target.select()}
                                                InputLabelProps={{ style: { color: 'black' }}}
                                                InputProps={{sx:{height:32, backgroundColor:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white', color:modeViewOnly?"grey":"black"}}}
                                                inputProps={{maxLength:6, readOnly:modeViewOnly}}
                                                disabled={m_bModePM|| processMode > MS_MODE_CHECKING}
                                            />
                                        )}
                                    />
                                </Stack>
                                </Grid>
                                </Grid>
                                </fieldset>
                                
                                <Stack>
                                    <Grid container>
                                        <Grid item xs={4.05}/>
                                        <Grid xs={6}>
                                            <Controller
                                                name="viewFlowResult"        
                                                control={control}
                                                render={({ field }: any) => (
                                                    <FormControlLabel 
                                                        {...field} 
                                                        checked={field.value}
                                                        label={<Typography variant="body2" width="100%"color={m_bModePM|| processMode > MS_MODE_CHECKING?'grey':'black'}>潮流解析結果を表示</Typography>}
                                                        name='viewFlowResult'
                                                        onClick={handleChangeFormat}
                                                        control={<Checkbox style={checkboxStyle} />} 
                                                disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}

                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                </Stack>
                       </Stack>
                    </TabPanel>
                    </div>
                <Stack direction='row' justifyContent="flex-end" spacing={2} mt={2}>
                    <Button variant="contained" type="submit" disabled={modeViewOnly}>OK</Button>
                    <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                </Stack>
               
            </form >
        </>
    );
};
export default MsImpedanceEditDialog;


