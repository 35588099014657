import { Button, Checkbox, Dialog, DialogContent, DialogTitle, FormControlLabel, Grid, Stack, TextField, TextareaAutosize } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import theme from "../../themes/globalStyles";
import DraggablePaper from "../common/DraggablePaper";
import { InfoCircuit, InfoCover } from "../../models/Index";
import { useState } from "react";
import SettingCover3Dialog from "./SettingCover3Dialog";

export type SettingCover2Form = {
    corporation:string,
    titleCorporationAddress:string,
    corporationAddress:string
    titleCorporationTelephone:string,
    corporationTelephone:string,
    titleDateDesigned: string,
    dateDesigned: string,
    application: string,
    titleProject:string,
    project:string,
    titleProjectAddress:string,
    projectAddress: string,
    titleProjectNumber:string,
    projectNumber:string,
    item1 : string,
    item2 : string,
    item3 : string,
    item4 : string,
    item5 : string,
    item6 : string,
    item7 : string,
    item8 : string,
    item9 : string,
    item10 : string,
    item11 : string,
    item12 : string,
    item13 : string,
    item14 : string,
    item15 : string,
    item16 : string,
    item17 : string,
    item18 : string,
    item19 : string,
    item20 : string,
    item21 : string,
    item22 : string,
    item23 : string,
    item24 : string,
    item25 : string,
    item26 : string,
    item27 : string,
    item28 : string,
    item29 : string,
    item30 : string,
    item31 : string,

    content1 : string,
    content2 : string,
    content3 : string,
    content4 : string,
    content5 : string,
    content6 : string,
    content7 : string,
    content8 : string,
    content9 : string,
    content10 : string,
    content11 : string,
    content12 : string,
    content13 : string,
    content14 : string,
    content15 : string,
    content16 : string,
    content17 : string,
    content18 : string,
    content19 : string,
    content20 : string,
    content21 : string,
    content22 : string,
    content23 : string,
    content24 : string,
    content25 : string,
    content26 : string,
    content27 : string,
    content28 : string,
    content29 : string,
    content30 : string,
    content31 : string,

    page1 : string,
    page2 : string,
    page3 : string,
    page4 : string,
    page5 : string,
    page6 : string,
    page7 : string,
    page8 : string,
    page9 : string,
    page10 : string,
    page11 : string,

    page12 : string,
    page13 : string,
    page14 : string,
    page15 : string,
    page16 : string,
    page17 : string,
    page18 : string,
    page19 : string,
    page20 : string,
    page21 : string,
    page22 : string,

    page23 : string,
    page24 : string,
    page25 : string,
    page26 : string,
    page27 : string,
    page28 : string,
    page29 : string,
    page30 : string,
    page31 : string,

    itemDispIndex: number,
    itemBottomIndex: number,
    outline: string,
    titleProjectManager: string,
    projectManager: string,
    titleProjectKind: string,
    projectKind: string,
    titleVoltage: string,
    voltage: string,
    titleEquipCapacity: string,
    powerExisting: number,
    powerIncrease: number,
    powerNew: number,
    heatExisting: number,
    heatIncrease: number,
    heatNew: number,
    lightExisting: number,
    lightIncrease: number,
    lightNew: number,
    titleContractPower: string,
    contractPower: string,
    titlePowerUse: string,
    powerUse: string,
    titlePowerForecast: string,
    powerForecast: string,
    titleUser1: string,
    user1: string,
    titleUser2: string,
    user2: string,
    titleUser3: string,
    user3: string,
    titleUser4: string,
    user4: string,
    titleUser5: string,
    user5: string,
    execution: string,
    titleStandard: string,
    standard: string,
    titlePlace: string,
    placeNormal: boolean,
    placeNormalEdit: string,
    placeDanger: boolean,
    placeDangerEdit: string,
    placeDust: boolean,
    placeDustEdit: string,
    placeCorrosion: boolean,
    placeCorrosionEdit: string,
    placeUser: boolean,
    placeUserText: string,
    titleWire: string,
    wireHigh: boolean,
    wireHighEdit: string,
    wireLow: boolean,
    wireLowEdit: string,
    wireUser: boolean,
    wireUserText: string,
    titleDuct: string,
    ductPvc: boolean,
    ductPvcEdit: string,
    ductGip: boolean,
    ductGipEdit: string,
    ductUser: boolean,
    ductUserText: string,
    titleMeter: string,
    meterExisting: boolean,
    meterExistingEdit: string,
    meterNew: boolean,
    meterNewEdit: string,
    meterUser: boolean,
    meterUserText: string,
    titleSwitch: string,
    switchNormal: boolean,
    switchNormalEdit: string,
    switchBurstProof: boolean,
    switchBurstProofEdit: string,
    switchUser: boolean,
    switchUserText: string,
    titleAppliance: string,
    appliance: string,
    titleOther: string,
    other: string,
    titleInstrument: string,
    instrument1: string,
    instrument2: string,
    instrument3: string,
    instrument4: string,
    instrument5: string,
    instrument6: string,
    instrument7: string,
    instrument8: string,
    instrument9: string,
    instrument10: string,
    instrument11: string,
    instrument12: string,
    instrument13: string,
    instrument14: string,
    instrument15: string,
    instrument16: string,
    instrument17: string,
    instrument18: string,
    instrument19: string,
    instrument20: string,
    instrument21: string,
    corporation1: string,
    corporation2: string,
    corporation3: string,
    corporation4: string,
    corporation5: string,
    corporation6: string,
    corporation7: string,
    corporation8: string,
    corporation9: string,
    corporation10: string,
    corporation11: string,
    corporation12: string,
    corporation13: string,
    corporation14: string,
    corporation15: string,
    corporation16: string,
    corporation17: string,
    corporation18: string,
    corporation19: string,
    corporation20: string,
    corporation21: string,
    instrumentDispIndex: number,
    instrumentBottomIndex: number,
    unitChange: boolean,
    subExisting: number,
    subIncrease: number,
    subNew: number,
    powerTotal:number,
    heatTotal:number,
    lightTotal:number,
    subTotal: number
}

export type SettingCover2FormProps = {
    infoCircuit: InfoCircuit;
    data: InfoCover;
    title: string;
    onOK: (data: any) => void;
    onCancel: () => void;
    modeViewOnly:boolean;
};

export const SettingCover2Dialog = (props: SettingCover2FormProps) => {

    //#region fields
    const {infoCircuit, data, title, onOK, onCancel,modeViewOnly } = props;

    // 初期値設定
    const initialValue : SettingCover2Form = {
        ...data,
        corporation: infoCircuit.corporation,
    }

    const { control, handleSubmit, formState: { errors }, setValue, getValues } = useForm<SettingCover2Form>({
        mode: "all",
        criteriaMode: "all",
        defaultValues: initialValue,
    })
    const [isDisablePlaceNormalEdit,setIsDisablePlaceNormalEdit] = useState(initialValue.placeNormal)
    const [isDisablePlaceDangerEdit,setIsDisablePlaceDangerEdit] = useState(initialValue.placeDanger)
    const [isDisablePlaceDustEdit,setIsDisablePlaceDustEdit] = useState(initialValue.placeDust)
    const [isDisablePlaceCorrosionEdit,setIsDisablePlaceCorrosionEdit] = useState(initialValue.placeCorrosion)
    const [isDisablePlaceUserText,setIsDisablePlaceUserText] = useState(initialValue.placeUser)
    const [isDisableWireHightEdit,setIsDisableWireHightEdit] = useState(initialValue.wireHigh)
    const [isDisableWireLowEdit,setIsDisableWireLowEdit] = useState(initialValue.wireLow)
    const [isDisableWireUserText,setIsDisableWireUserText] = useState(initialValue.wireUser)
    const [isDisableDuctPVCEdit,setIsDisableDuctPVCEdit] = useState(initialValue.ductPvc)
    const [isDisableDuctGipEdit,setIsDisableDuctGipEdit] = useState(initialValue.ductGip)

    const [isDisableDuctUserText,setIsDisableDuctUserText] = useState(initialValue.ductUser)
    const [isDisableMeterExistingEdit,setIsDisableMeterExistingEdit] = useState(initialValue.meterExisting)
    const [isDisableMeterNewEdit,setIsDisableMeterNewEdit] = useState(initialValue.meterNew)
    const [isDisableMeterUserText,setIsDisableMeterUserText] = useState(initialValue.meterUser)
    const [isDisableSwitchNormalEdit,setIsDisableSwitchNormalEdit] = useState(initialValue.switchNormal)
    const [isDisableSwitchBurstProofEdit,setIsDisableSwitchBurstProofEdit] = useState(initialValue.switchBurstProof)
    const [isDisableSwitchUserText,setIsDisableSwitchUserText] = useState(initialValue.switchUser)
    
    const [isDisableEditBtn, setIsDisableEditBtn] = useState(initialValue.titleInstrument ? false : true)

    const [isOpenedCover3Dialog, setIsOpenedCover3Dialog] = useState(false);
    const [array, setArray] = useState<any>({...initialValue});

    //#endregion fields

    //#region methods
    // Submitイベント
    const handleEditSubmit = (value: any, event: any) => {
        const request = createRequestData(value);
        onOK(request);
    }
    
    // FormデータからRequestデータを作成する
    const createRequestData = (formValue : SettingCover2Form) => {
        convertArrayToData(formValue)
        let request = {
            ...formValue,
        } as SettingCover2Form;
        return request;
    }

    const handleReset =() =>{
        if(title == '施工概要')
        {
            setValue("execution", '施工概要')
            setValue("titleStandard", '準拠')
            setValue("titlePlace", '場所')
            setValue("placeNormalEdit", '一般性')
            setValue("placeDangerEdit", '易爆危険場所')
            setValue("placeDustEdit", '易燃性粉塵場所')
            setValue("placeCorrosionEdit", '腐蝕性場所')
            setValue("titleWire", '電線')
            setValue("wireHighEdit", '高圧-PEX25kV級電力ケーブル')
            setValue("wireLowEdit", '低圧-PVC600V級電線')
            setValue("titleDuct", '電線管')
            setValue("ductPvcEdit",'PVC 0級管')
            setValue("ductGipEdit", 'PIG B級管')
            setValue("titleMeter", '計器盤')
            setValue("meterExistingEdit", '既設計器盤は不変')
            setValue("meterNewEdit", '本工事は計器盤を新設し、材質はステンレスを採用')
            setValue("titleSwitch", '開閉器盤')
            setValue("switchNormalEdit", '一般防塵密封型　厚さ1.6cm以上の鉄板、塗装')
            setValue("switchBurstProofEdit", '防爆型　厚さ1.6cm以上の鉄板、塗装')
            setValue("titleAppliance", '使用器具')
            setValue("titleOther", 'その他')
            setValue("titleInstrument", '主要器材一覧表')
        }
        else
        {
            setValue("execution",initialValue.execution)
            setValue("titleStandard",initialValue.titleStandard)
            setValue("titlePlace",initialValue.titlePlace)
            setValue("placeNormalEdit",initialValue.placeNormalEdit)
            setValue("placeDangerEdit",initialValue.placeDangerEdit)
            setValue("placeDustEdit",initialValue.placeDustEdit)
            setValue("placeCorrosionEdit",initialValue.placeCorrosionEdit)
            setValue("titleWire",initialValue.titleWire)
            setValue("wireHighEdit",initialValue.wireHighEdit)
            setValue("wireLowEdit",initialValue.wireLowEdit)
            setValue("titleDuct",initialValue.titleDuct)
            setValue("ductPvcEdit",initialValue.ductPvcEdit)
            setValue("ductGipEdit",initialValue.ductGipEdit)
            setValue("titleMeter",initialValue.titleMeter)
            setValue("meterExistingEdit",initialValue.meterExistingEdit)
            setValue("meterNewEdit",initialValue.meterNewEdit)
            setValue("titleSwitch",initialValue.titleSwitch)
            setValue("switchNormalEdit",initialValue.switchNormalEdit)
            setValue("switchBurstProofEdit",initialValue.switchBurstProofEdit)
            setValue("titleAppliance",initialValue.titleAppliance)
            setValue("titleOther",initialValue.titleOther)
            setValue("titleInstrument",initialValue.titleInstrument)
        }
    }

    const handleChangeCheckBox = (e:any) =>{
        setValue(e.target.name,e.target.checked)
        if(e.target.name === 'placeNormal'){
            if(e.target.checked){
                setIsDisablePlaceNormalEdit(true)
            }
            else{
                setIsDisablePlaceNormalEdit(false)
            }
        }
        else if(e.target.name === 'placeDanger'){
            if(e.target.checked){
                setIsDisablePlaceDangerEdit(true)
            }
            else{
                setIsDisablePlaceDangerEdit(false)
            }
        }
        else if(e.target.name === 'placeDust'){
            if(e.target.checked){
                setIsDisablePlaceDustEdit(true)
            }
            else{
                setIsDisablePlaceDustEdit(false)
            }
        }
        else if(e.target.name === 'placeCorrosion'){
            if(e.target.checked){
                setIsDisablePlaceCorrosionEdit(true)
            }
            else{
                setIsDisablePlaceCorrosionEdit(false)
            }
        }
        else if(e.target.name === 'placeUser'){
            if(e.target.checked){
                setIsDisablePlaceUserText(true)
            }
            else{
                setIsDisablePlaceUserText(false)
            }
        }
        else if(e.target.name === 'wireHigh'){
            if(e.target.checked){
                setIsDisableWireHightEdit(true)
            }
            else{
                setIsDisableWireHightEdit(false)
            }
        }
        else if(e.target.name === 'wireLow'){
            if(e.target.checked){
                setIsDisableWireLowEdit(true)
            }
            else{
                setIsDisableWireLowEdit(false)
            }
        }
        else if(e.target.name === 'wireUser'){
            if(e.target.checked){
                setIsDisableWireUserText(true)
            }
            else{
                setIsDisableWireUserText(false)
            }
        }
        else if(e.target.name === 'ductPvc'){
            if(e.target.checked){
                setIsDisableDuctPVCEdit(true)
            }
            else{
                setIsDisableDuctPVCEdit(false)
            }
        }
        else if(e.target.name === 'ductGip'){
            if(e.target.checked){
                setIsDisableDuctGipEdit(true)
            }
            else{
                setIsDisableDuctGipEdit(false)
            }
        }
        else if(e.target.name === 'ductUser'){
            if(e.target.checked){
                setIsDisableDuctUserText(true)
            }
            else{
                setIsDisableDuctUserText(false)
            }
        }
        else if(e.target.name === 'meterExisting'){
            if(e.target.checked){
                setIsDisableMeterExistingEdit(true)
            }
            else{
                setIsDisableMeterExistingEdit(false)
            }
        }

        else if(e.target.name === 'meterNew'){
            if(e.target.checked){
                setIsDisableMeterNewEdit(true)
            }
            else{
                setIsDisableMeterNewEdit(false)
            }
        }
        else if(e.target.name === 'meterUser'){
            if(e.target.checked){
                setIsDisableMeterUserText(true)
            }
            else{
                setIsDisableMeterUserText(false)
            }
        }

        else if(e.target.name === 'switchNormal'){
            if(e.target.checked){
                setIsDisableSwitchNormalEdit(true)
            }
            else{
                setIsDisableSwitchNormalEdit(false)
            }
        }

        else if(e.target.name === 'switchBurstProof'){
            if(e.target.checked){
                setIsDisableSwitchBurstProofEdit(true)
            }
            else{
                setIsDisableSwitchBurstProofEdit(false)
            }
        }
        else if(e.target.name === 'switchUser'){
            if(e.target.checked){
                setIsDisableSwitchUserText(true)
            }
            else{
                setIsDisableSwitchUserText(false)
            }
        }
    }

    const handleChangeSetTitleInstrument = (e:any) =>{
        setValue(e.target.name, e.target.value);
        setIsDisableEditBtn(!e.target.value)
    }

    const handleOpenCover3Dialog =() =>{
        setIsOpenedCover3Dialog(true)
    }

    const handleCancelCover3Dialog = () =>{
        setIsOpenedCover3Dialog(false)
    }

    const handleOkCover3Dialog =(formValue:any) =>{
        setArray({...formValue})
        setIsOpenedCover3Dialog(false)
    }

    const convertArrayToData =(formValue:any) =>{
        for(var i =1; i< 22 ;i++){
            formValue['instrument' +i] = array['instrument' +i]
            formValue['corporation' +i] = array['corporation' +i]
        }
    }
    
    return (
        <>
            <Dialog open={true} maxWidth={"md"} fullWidth
                PaperComponent={
                    DraggablePaper
                }
            >
                <DialogTitle 
                    onMouseOver={(e : any) => e.target.style.cursor = 'move'}
                    style={{ paddingTop: '2px', paddingBottom: '2px', background: theme.palette.primary.main, color: theme.palette.primary.contrastText }}
                >
                    レポートの設定 -［{title}］
                </DialogTitle>
                <DialogContent style={{paddingTop: '10px'}}>
                    <div style={{height:"40vh", width: '100%',marginLeft:'-10px'}}>
                        <form onSubmit={handleSubmit(handleEditSubmit)}>
                            <div style={{height:"calc(40vh - 45px)",overflowY:"auto", width: '100%'}}>
                            <Grid container >
                                    <Grid xs={9.25}>
                                        <Controller
                                            name="corporation"
                                            control={control}
                                            rules={{
                                            }}
                                            render={({ field }) => (
                                                <TextField 
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    size="small"
                                                    error={!!errors?.corporation}
                                                    helperText={errors?.corporation?.message}
                                                    disabled={true}
                                                    style={{
                                                        backgroundColor: 'floralwhite' ,
                                                    }}
                                                    InputProps={{sx: { height: 32 }}}
                                                    inputProps={{style:{textAlign:"center"}}}
                                                ></TextField>
                                            )}
                                            /> 
                                    </Grid>
                                    <Grid style={{borderTop:"1px solid black",width:"100%",marginTop:"5px"}}></Grid>
                                    <Grid xs={3} mt={1}>
                                        <Controller
                                            name="execution"
                                            control={control}
                                            rules={{
                                            }}
                                            render={({ field }) => (
                                                <TextField 
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    size="small"
                                                    error={!!errors?.execution}
                                                    helperText={errors?.execution?.message}
                                                    disabled={modeViewOnly}
                                                    InputProps={{sx: { height: 32 }}}
                                                ></TextField>
                                            )}
                                            /> 
                                    </Grid>
                                    <Grid xs ={7}/>
                                    <Grid xs={2} >
                                            <Stack alignItems={'flex-end'} mt={1}>
                                                        <Button color="inherit"  variant="contained" onClick={handleReset} disabled={modeViewOnly}>リセット</Button>
                                            </Stack>
                                    </ Grid>
                                    <Grid container mt={1}>
                                        <Grid item xs={0.25} display={"flex"} alignItems={'center'} paddingLeft={0}>1.</Grid>
                                        <Grid item xs={2.5} display={"flex"} justifyContent={'center'} alignItems={'center'} pl={1}>
                                            <Controller
                                                name="titleStandard"
                                                control={control}
                                                rules={{
                                                }}
                                                render={({ field }) => (
                                                    <TextField 
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        error={!!errors?.titleStandard}
                                                        helperText={errors?.titleStandard?.message}
                                                        InputProps={{sx: { height: 32 }}}
                                                        disabled={modeViewOnly}
                                                    ></TextField>
                                                )}
                                                /> 
                                        </Grid>
                                        <Grid item xs={0.25} display={"flex"} justifyContent={'center'} alignItems={'center'}>:</Grid>
                                        <Grid item xs={9} display={"flex"} justifyContent={'center'} alignItems={'center'}>
                                            <Controller
                                                name="standard"
                                                control={control}
                                                rules={{
                                                }}
                                                render={({ field }) => (
                                                    <TextField 
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        error={!!errors?.standard}
                                                        helperText={errors?.standard?.message}
                                                        InputProps={{sx: { height: 32 }}}
                                                        disabled={modeViewOnly}
                                                    ></TextField>
                                                )}
                                                /> 
                                        </Grid>
                                    </Grid>
                                    <Grid container mt={1}>
                                        <Grid item xs={0.25} display={"flex"} alignItems={'center'} paddingLeft={0}>2.</Grid>
                                        <Grid item xs={2.5} display={"flex"} justifyContent={'center'} alignItems={'center'} pl={1}>
                                            <Controller
                                                name="titlePlace"
                                                control={control}
                                                rules={{
                                                }}
                                                render={({ field }) => (
                                                    <TextField 
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        error={!!errors?.titlePlace}
                                                        helperText={errors?.titlePlace?.message}
                                                        InputProps={{sx: { height: 32 }}}
                                                        disabled={modeViewOnly}
                                                    ></TextField>
                                                )}
                                                /> 
                                        </Grid>
                                        <Grid item xs={0.25} display={"flex"} justifyContent={'center'} alignItems={'center'}>:</Grid>
                                        <Grid item xs={0.25} display={"flex"}  alignItems={'center'}>
                                            <FormControlLabel
                                                    control={
                                                        <Controller
                                                            name='placeNormal'
                                                            control={control}
                                                            render={({ field }) => (
                                                                <Checkbox
                                                                    size='small'
                                                                    {...field}
                                                                    checked={field.value}
                                                                    onChange={handleChangeCheckBox}
                                                                    disabled={modeViewOnly}
                                                                />
                                                            )}
                                                        />
                                                    }
                                                    label=''
                                                />
                                        </Grid>
                                        <Grid item xs={2} display={"flex"} justifyContent={'center'} alignItems={'center'} pl={1}>
                                            <Controller
                                                name="placeNormalEdit"
                                                control={control}
                                                rules={{
                                                }}
                                                render={({ field }) => (
                                                    <TextField 
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        error={!!errors?.placeNormalEdit}
                                                        helperText={errors?.placeNormalEdit?.message}
                                                        InputProps={{sx: { height: 32 }}}

                                                        disabled={!isDisablePlaceNormalEdit || modeViewOnly}
                                                        style={{background:!isDisablePlaceNormalEdit?"floralwhite":"white"}}
                                                    ></TextField>
                                                )}
                                                /> 
                                        </Grid>
                                        <Grid item xs={0.25} display={"flex"}  alignItems={'center'}  pl={0.5}>
                                            <FormControlLabel
                                                    control={
                                                        <Controller
                                                            name='placeDanger'
                                                            control={control}
                                                            render={({ field }) => (
                                                                <Checkbox
                                                                    size='small'
                                                                    {...field}
                                                                    checked={field.value}
                                                                    onChange={handleChangeCheckBox}
                                                                    disabled={modeViewOnly}
                                                                />
                                                            )}
                                                        />
                                                    }
                                                    label=''
                                                />
                                        </Grid>
                                        <Grid item xs={2} display={"flex"} justifyContent={'center'} alignItems={'center'}  pl={1.5}>
                                            <Controller
                                                name="placeDangerEdit"
                                                control={control}
                                                rules={{
                                                }}
                                                render={({ field }) => (
                                                    <TextField 
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        error={!!errors?.placeDangerEdit}
                                                        helperText={errors?.placeDangerEdit?.message}
                                                        InputProps={{sx: { height: 32 }}}
                                                        disabled={!isDisablePlaceDangerEdit ||modeViewOnly}
                                                        style={{background:!isDisablePlaceDangerEdit?"floralwhite":"white"}}
                                                    ></TextField>
                                                )}
                                                /> 
                                        </Grid>
                                        <Grid item xs={0.25} display={"flex"}  alignItems={'center'}  pl={0.5}>
                                            <FormControlLabel
                                                    control={
                                                        <Controller
                                                            name='placeDust'
                                                            control={control}
                                                            render={({ field }) => (
                                                                <Checkbox
                                                                    size='small'
                                                                    {...field}
                                                                    checked={field.value}
                                                                    onChange={handleChangeCheckBox}
                                                                    disabled={modeViewOnly}
                                                                />
                                                            )}
                                                        />
                                                    }
                                                    label=''
                                                />
                                        </Grid>
                                        <Grid item xs={2} display={"flex"} justifyContent={'center'} alignItems={'center'} pl={1.5}>
                                            <Controller
                                                name="placeDustEdit"
                                                control={control}
                                                rules={{
                                                }}
                                                render={({ field }) => (
                                                    <TextField 
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        error={!!errors?.placeDustEdit}
                                                        helperText={errors?.placeDustEdit?.message}
                                                        InputProps={{sx: { height: 32 }}}
                                                        disabled={!isDisablePlaceDustEdit || modeViewOnly}
                                                        style={{background:!isDisablePlaceDustEdit?"floralwhite":"white"}}
                                                    ></TextField>
                                                )}
                                                /> 
                                        </Grid>
                                        <Grid item xs={0.25} display={"flex"}  alignItems={'center'}  pl={0.5}>
                                            <FormControlLabel
                                                    control={
                                                        <Controller
                                                            name='placeCorrosion'
                                                            control={control}
                                                            render={({ field }) => (
                                                                <Checkbox
                                                                    size='small'
                                                                    {...field}
                                                                    checked={field.value}
                                                                    onChange={handleChangeCheckBox}
                                                                    disabled={modeViewOnly}
                                                                />
                                                            )}
                                                        />
                                                    }
                                                    label=''
                                                />
                                        </Grid>
                                        <Grid item xs={2} display={"flex"} justifyContent={'center'} alignItems={'center'}  pl={1.5}>
                                            <Controller
                                                name="placeCorrosionEdit"
                                                control={control}
                                                rules={{
                                                }}
                                                render={({ field }) => (
                                                    <TextField 
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        error={!!errors?.placeCorrosionEdit}
                                                        helperText={errors?.placeCorrosionEdit?.message}
                                                        InputProps={{sx: { height: 32 }}}
                                                        disabled={!isDisablePlaceCorrosionEdit || modeViewOnly}
                                                        style={{background:!isDisablePlaceCorrosionEdit?"floralwhite":"white"}}
                                                    ></TextField>
                                                )}
                                                /> 
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={0.25} display={"flex"} alignItems={'center'} paddingLeft={0}></Grid>
                                        <Grid item xs={2.5} display={"flex"} justifyContent={'center'} alignItems={'center'} pl={1}></Grid>
                                        <Grid item xs={0.25} display={"flex"} justifyContent={'center'} alignItems={'center'}></Grid>
                                        <Grid item xs={0.25} display={"flex"}  alignItems={'center'}>
                                            <FormControlLabel
                                                    control={
                                                        <Controller
                                                            name='placeUser'
                                                            control={control}
                                                            render={({ field }) => (
                                                                <Checkbox
                                                                    size='small'
                                                                    {...field}
                                                                    checked={field.value}
                                                                    onChange={handleChangeCheckBox}
                                                                    disabled={modeViewOnly}
                                                                />
                                                            )}
                                                        />
                                                    }
                                                    label=''
                                                />
                                        </Grid>
                                        <Grid item xs={8.75} display={"flex"}  alignItems={'center'} pl={1}>
                                            <Controller
                                                    name="placeUserText"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextareaAutosize
                                                            {...field}
                                                            maxRows={2}
                                                            minRows={2}
                                                            style={{overflowY:'scroll',resize:'none',fontFamily:'Roboto,Helvetica,Arial,sans-serif',fontSize:'16px',width:'100%',
                                                                    borderRadius:'4px',padding:'5px 20px', border:'0.1px solid gray',
                                                                    background:!isDisablePlaceUserText || modeViewOnly?"floralwhite":"white"}}
                                                            disabled={!isDisablePlaceUserText || modeViewOnly}
                                                            />
                                                            )}
                                                        />
                                        </Grid>
                                    </Grid>


                                    {/* ============================== */}
                                    <Grid container mt={1}>
                                        <Grid item xs={0.25} display={"flex"} alignItems={'center'} paddingLeft={0}>3.</Grid>
                                        <Grid item xs={2.5} display={"flex"} justifyContent={'center'} alignItems={'center'} pl={1}>
                                            <Controller
                                                name="titleWire"
                                                control={control}
                                                rules={{
                                                }}
                                                render={({ field }) => (
                                                    <TextField 
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        error={!!errors?.titleWire}
                                                        helperText={errors?.titleWire?.message}
                                                        InputProps={{sx: { height: 32 }}}
                                                        disabled={modeViewOnly}
                                                    ></TextField>
                                                )}
                                                /> 
                                        </Grid>
                                        <Grid item xs={0.25} display={"flex"} justifyContent={'center'} alignItems={'center'}>:</Grid>
                                        <Grid item xs={0.25} display={"flex"}  alignItems={'center'}>
                                            <FormControlLabel
                                                    control={
                                                        <Controller
                                                            name='wireHigh'
                                                            control={control}
                                                            render={({ field }) => (
                                                                <Checkbox
                                                                    size='small'
                                                                    {...field}
                                                                    checked={field.value}
                                                                    onChange={handleChangeCheckBox}
                                                                    disabled={modeViewOnly}

                                                                />
                                                            )}
                                                        />
                                                    }
                                                    label=''
                                                />
                                        </Grid>
                                        <Grid item xs={4.25} display={"flex"} justifyContent={'center'} alignItems={'center'} pl={1}>
                                            <Controller
                                                name="wireHighEdit"
                                                control={control}
                                                rules={{
                                                }}
                                                render={({ field }) => (
                                                    <TextField 
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        error={!!errors?.wireHighEdit}
                                                        helperText={errors?.wireHighEdit?.message}
                                                        InputProps={{sx: { height: 32 }}}
                                                        style={{background:!isDisableWireHightEdit||modeViewOnly?"floralwhite":"white"}}
                                                        disabled={!isDisableWireHightEdit||modeViewOnly}
                                                    ></TextField>
                                                )}
                                                /> 
                                        </Grid>
                                        <Grid item xs={0.25} display={"flex"}  alignItems={'center'}  pl={0.5}>
                                            <FormControlLabel
                                                    control={
                                                        <Controller
                                                            name='wireLow'
                                                            control={control}
                                                            render={({ field }) => (
                                                                <Checkbox
                                                                    size='small'
                                                                    {...field}
                                                                    checked={field.value}
                                                                    onChange={handleChangeCheckBox}
                                                                    disabled={modeViewOnly}
                                                                />
                                                            )}
                                                        />
                                                    }
                                                    label=''
                                                />
                                        </Grid>
                                        <Grid item xs={4.25} display={"flex"} justifyContent={'center'} alignItems={'center'}  pl={1.5}>
                                            <Controller
                                                name="wireLowEdit"
                                                control={control}
                                                rules={{
                                                }}
                                                render={({ field }) => (
                                                    <TextField 
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        error={!!errors?.wireLowEdit}
                                                        helperText={errors?.wireLowEdit?.message}
                                                        InputProps={{sx: { height: 32 }}}
                                                        style={{background:!isDisableWireLowEdit||modeViewOnly?"floralwhite":"white"}}
                                                        disabled={!isDisableWireLowEdit||modeViewOnly}
                                                    ></TextField>
                                                )}
                                                /> 
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={0.25} display={"flex"} alignItems={'center'} paddingLeft={0}></Grid>
                                        <Grid item xs={2.5} display={"flex"} justifyContent={'center'} alignItems={'center'} pl={1}></Grid>
                                        <Grid item xs={0.25} display={"flex"} justifyContent={'center'} alignItems={'center'}></Grid>
                                        <Grid item xs={0.25} display={"flex"}  alignItems={'center'}>
                                            <FormControlLabel
                                                    control={
                                                        <Controller
                                                            name='wireUser'
                                                            control={control}
                                                            render={({ field }) => (
                                                                <Checkbox
                                                                    size='small'
                                                                    {...field}
                                                                    checked={field.value}
                                                                    onChange={handleChangeCheckBox}
                                                                    disabled={modeViewOnly}
                                                                />
                                                            )}
                                                        />
                                                    }
                                                    label=''
                                                />
                                        </Grid>
                                        <Grid item xs={8.75} display={"flex"}  alignItems={'center'} pl={1}>
                                            <Controller
                                                    name="wireUserText"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextareaAutosize
                                                            {...field}
                                                            maxRows={2}
                                                            minRows={2}                                                            
                                                            style={{overflowY:'scroll',resize:'none',fontFamily:'Roboto,Helvetica,Arial,sans-serif',fontSize:'16px',width:'100%',
                                                            borderRadius:'4px',padding:'5px 20px', border:'0.1px solid gray',
                                                            background:!isDisableWireUserText||modeViewOnly?"floralwhite":"white"}}
                                                            disabled={!isDisableWireUserText||modeViewOnly}
                                                            />
                                                            )}
                                                        />
                                        </Grid>
                                    </Grid>


                                    {/* ============================== */}
                                    <Grid container mt={1}>
                                        <Grid item xs={0.25} display={"flex"} alignItems={'center'} paddingLeft={0}>4.</Grid>
                                        <Grid item xs={2.5} display={"flex"} justifyContent={'center'} alignItems={'center'} pl={1}>
                                            <Controller
                                                name="titleDuct"
                                                control={control}
                                                rules={{
                                                }}
                                                render={({ field }) => (
                                                    <TextField 
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        error={!!errors?.titleDuct}
                                                        helperText={errors?.titleDuct?.message}
                                                        InputProps={{sx: { height: 32 }}}
                                                        disabled={modeViewOnly}
                                                    ></TextField>
                                                )}
                                                /> 
                                        </Grid>
                                        <Grid item xs={0.25} display={"flex"} justifyContent={'center'} alignItems={'center'}>:</Grid>
                                        <Grid item xs={0.25} display={"flex"}  alignItems={'center'}>
                                            <FormControlLabel
                                                    control={
                                                        <Controller
                                                            name='ductPvc'
                                                            control={control}
                                                            render={({ field }) => (
                                                                <Checkbox
                                                                    size='small'
                                                                    {...field}
                                                                    checked={field.value}
                                                                    onChange={handleChangeCheckBox}
                                                                    disabled={modeViewOnly}
                                                                />
                                                            )}
                                                        />
                                                    }
                                                    label=''
                                                />
                                        </Grid>
                                        <Grid item xs={4.25} display={"flex"} justifyContent={'center'} alignItems={'center'} pl={1}>
                                            <Controller
                                                name="ductPvcEdit"
                                                control={control}
                                                rules={{
                                                }}
                                                render={({ field }) => (
                                                    <TextField 
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        error={!!errors?.ductPvcEdit}
                                                        helperText={errors?.ductPvcEdit?.message}
                                                        InputProps={{sx: { height: 32 }}}
                                                        disabled={!isDisableDuctPVCEdit||modeViewOnly}
                                                        style={{background:!isDisableDuctPVCEdit||modeViewOnly?"floralwhite":"white"}}
                                                    ></TextField>
                                                )}
                                                /> 
                                        </Grid>
                                        <Grid item xs={0.25} display={"flex"}  alignItems={'center'}  pl={0.5}>
                                            <FormControlLabel
                                                    control={
                                                        <Controller
                                                            name='ductGip'
                                                            control={control}
                                                            render={({ field }) => (
                                                                <Checkbox
                                                                    size='small'
                                                                    {...field}
                                                                    checked={field.value}
                                                                    onChange={handleChangeCheckBox}
                                                                    disabled={modeViewOnly}
                                                                />
                                                            )}
                                                        />
                                                    }
                                                    label=''
                                                />
                                        </Grid>
                                        <Grid item xs={4.25} display={"flex"} justifyContent={'center'} alignItems={'center'}  pl={1.5}>
                                            <Controller
                                                name="ductGipEdit"
                                                control={control}
                                                rules={{
                                                }}
                                                render={({ field }) => (
                                                    <TextField 
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        error={!!errors?.ductGipEdit}
                                                        helperText={errors?.ductGipEdit?.message}
                                                        InputProps={{sx: { height: 32 }}}
                                                        disabled={!isDisableDuctGipEdit||modeViewOnly}
                                                        style={{background:!isDisableDuctGipEdit||modeViewOnly?"floralwhite":"white"}}
                                                    ></TextField>
                                                )}
                                                /> 
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={0.25} display={"flex"} alignItems={'center'} paddingLeft={0}></Grid>
                                        <Grid item xs={2.5} display={"flex"} justifyContent={'center'} alignItems={'center'} pl={1}></Grid>
                                        <Grid item xs={0.25} display={"flex"} justifyContent={'center'} alignItems={'center'}></Grid>
                                        <Grid item xs={0.25} display={"flex"}  alignItems={'center'}>
                                            <FormControlLabel
                                                    control={
                                                        <Controller
                                                            name='ductUser'
                                                            control={control}
                                                            render={({ field }) => (
                                                                <Checkbox
                                                                    size='small'
                                                                    {...field}
                                                                    checked={field.value}
                                                                    onChange={handleChangeCheckBox}
                                                                    disabled={modeViewOnly}
                                                                />
                                                            )}
                                                        />
                                                    }
                                                    label=''
                                                />
                                        </Grid>
                                        <Grid item xs={8.75} display={"flex"}  alignItems={'center'} pl={1}>
                                            <Controller
                                                    name="ductUserText"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextareaAutosize
                                                            {...field}
                                                            maxRows={2}
                                                            minRows={2}
                                                            style={{overflowY:'scroll',resize:'none',fontFamily:'Roboto,Helvetica,Arial,sans-serif',fontSize:'16px',width:'100%',
                                                                    borderRadius:'4px',padding:'5px 20px', border:'0.1px solid gray',
                                                                    background:!isDisableDuctUserText||modeViewOnly?"floralwhite":"white"}}
                                                            disabled={!isDisableDuctUserText||modeViewOnly}
                                                            />
                                                            )}
                                                        />
                                        </Grid>
                                    </Grid>


                                    {/* ============================== */}
                                    <Grid container mt={1}>
                                        <Grid item xs={0.25} display={"flex"} alignItems={'center'} paddingLeft={0}>5.</Grid>
                                        <Grid item xs={2.5} display={"flex"} justifyContent={'center'} alignItems={'center'} pl={1}>
                                            <Controller
                                                name="titleMeter"
                                                control={control}
                                                rules={{
                                                }}
                                                render={({ field }) => (
                                                    <TextField 
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        error={!!errors?.titleMeter}
                                                        helperText={errors?.titleMeter?.message}
                                                        InputProps={{sx: { height: 32 }}}
                                                        disabled={modeViewOnly}
                                                    ></TextField>
                                                )}
                                                /> 
                                        </Grid>
                                        <Grid item xs={0.25} display={"flex"} justifyContent={'center'} alignItems={'center'}>:</Grid>
                                        <Grid item xs={0.25} display={"flex"}  alignItems={'center'}>
                                            <FormControlLabel
                                                    control={
                                                        <Controller
                                                            name='meterExisting'
                                                            control={control}
                                                            render={({ field }) => (
                                                                <Checkbox
                                                                    size='small'
                                                                    {...field}
                                                                    checked={field.value}
                                                                    onChange={handleChangeCheckBox}
                                                                    disabled={modeViewOnly}
                                                                    
                                                                />
                                                            )}
                                                        />
                                                    }
                                                    label=''
                                                />
                                        </Grid>
                                        <Grid item xs={2} display={"flex"} justifyContent={'center'} alignItems={'center'} pl={1}>
                                            <Controller
                                                name="meterExistingEdit"
                                                control={control}
                                                rules={{
                                                }}
                                                render={({ field }) => (
                                                    <TextField 
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        error={!!errors?.meterExistingEdit}
                                                        helperText={errors?.meterExistingEdit?.message}
                                                        InputProps={{sx: { height: 32 }}}
                                                        disabled={!isDisableMeterExistingEdit||modeViewOnly}
                                                        style={{background:!isDisableMeterExistingEdit||modeViewOnly?"floralwhite":"white"}}
                                                    ></TextField>
                                                )}
                                                /> 
                                        </Grid>
                                        <Grid item xs={0.25} display={"flex"}  alignItems={'center'}  pl={0.5}>
                                            <FormControlLabel
                                                    control={
                                                        <Controller
                                                            name='meterNew'
                                                            control={control}
                                                            render={({ field }) => (
                                                                <Checkbox
                                                                    size='small'
                                                                    {...field}
                                                                    checked={field.value}
                                                                    onChange={handleChangeCheckBox}
                                                                    disabled={modeViewOnly}
                                                                />
                                                            )}
                                                        />
                                                    }
                                                    label=''
                                                />
                                        </Grid>
                                        <Grid item xs={6.5} display={"flex"} justifyContent={'center'} alignItems={'center'}  pl={1.5}>
                                            <Controller
                                                name="meterNewEdit"
                                                control={control}
                                                rules={{
                                                }}
                                                render={({ field }) => (
                                                    <TextField 
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        error={!!errors?.meterNewEdit}
                                                        helperText={errors?.meterNewEdit?.message}
                                                        InputProps={{sx: { height: 32 }}}
                                                        disabled={!isDisableMeterNewEdit||modeViewOnly}
                                                        style={{background:!isDisableMeterNewEdit||modeViewOnly?"floralwhite":"white"}}
                                                    ></TextField>
                                                )}
                                                /> 
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={0.25} display={"flex"} alignItems={'center'} paddingLeft={0}></Grid>
                                        <Grid item xs={2.5} display={"flex"} justifyContent={'center'} alignItems={'center'} pl={1}></Grid>
                                        <Grid item xs={0.25} display={"flex"} justifyContent={'center'} alignItems={'center'}></Grid>
                                        <Grid item xs={0.25} display={"flex"}  alignItems={'center'}>
                                            <FormControlLabel
                                                    control={
                                                        <Controller
                                                            name='meterUser'
                                                            control={control}
                                                            render={({ field }) => (
                                                                <Checkbox
                                                                    size='small'
                                                                    {...field}
                                                                    checked={field.value}
                                                                    onChange={handleChangeCheckBox}
                                                                    disabled={modeViewOnly}
                                                                />
                                                            )}
                                                        />
                                                    }
                                                    label=''
                                                />
                                        </Grid>
                                        <Grid item xs={8.75} display={"flex"}  alignItems={'center'} pl={1}>
                                            <Controller
                                                    name="meterUserText"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextareaAutosize
                                                            {...field}
                                                            maxRows={2}
                                                            minRows={2}
                                                            style={{overflowY:'scroll',resize:'none',fontFamily:'Roboto,Helvetica,Arial,sans-serif',fontSize:'16px',width:'100%',
                                                                    borderRadius:'4px',padding:'5px 20px', border:'0.1px solid gray',
                                                                    background:!isDisableMeterUserText||modeViewOnly?"floralwhite":"white"}}
                                                            disabled={!isDisableMeterUserText||modeViewOnly}
                                                            />
                                                            )}
                                                        />
                                        </Grid>
                                    </Grid>

                                    {/* ============================== */}
                                    <Grid container mt={1}>
                                        <Grid item xs={0.25} display={"flex"} alignItems={'center'} paddingLeft={0}>6.</Grid>
                                        <Grid item xs={2.5} display={"flex"} justifyContent={'center'} alignItems={'center'} pl={1}>
                                            <Controller
                                                name="titleSwitch"
                                                control={control}
                                                rules={{
                                                }}
                                                render={({ field }) => (
                                                    <TextField 
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        error={!!errors?.titleSwitch}
                                                        helperText={errors?.titleSwitch?.message}
                                                        InputProps={{sx: { height: 32 }}}
                                                        disabled={modeViewOnly}
                                                    ></TextField>
                                                )}
                                                /> 
                                        </Grid>
                                        <Grid item xs={0.25} display={"flex"} justifyContent={'center'} alignItems={'center'}>:</Grid>
                                        <Grid item xs={0.25} display={"flex"}  alignItems={'center'}>
                                            <FormControlLabel
                                                    control={
                                                        <Controller
                                                            name='switchNormal'
                                                            control={control}
                                                            render={({ field }) => (
                                                                <Checkbox
                                                                    size='small'
                                                                    {...field}
                                                                    checked={field.value}
                                                                    onChange={handleChangeCheckBox}
                                                                    disabled={modeViewOnly}
                                                                />
                                                            )}
                                                        />
                                                    }
                                                    label=''
                                                />
                                        </Grid>
                                        <Grid item xs={8.75} display={"flex"} justifyContent={'center'} alignItems={'center'} pl={1}>
                                            <Controller
                                                name="switchNormalEdit"
                                                control={control}
                                                rules={{
                                                }}
                                                render={({ field }) => (
                                                    <TextField 
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        error={!!errors?.switchNormalEdit}
                                                        helperText={errors?.switchNormalEdit?.message}
                                                        InputProps={{sx: { height: 32 }}}
                                                        disabled={!isDisableSwitchNormalEdit||modeViewOnly}
                                                        style={{background:!isDisableSwitchNormalEdit||modeViewOnly?"floralwhite":"white"}}
                                                    ></TextField>
                                                )}
                                                /> 
                                        </Grid>
                                    </Grid>


                                    <Grid container mt={1}>
                                        <Grid item xs={0.25} display={"flex"} alignItems={'center'} paddingLeft={0}></Grid>
                                        <Grid item xs={2.5} display={"flex"} justifyContent={'center'} alignItems={'center'} pl={1}></Grid>
                                        <Grid item xs={0.25} display={"flex"} justifyContent={'center'} alignItems={'center'}></Grid>
                                        <Grid item xs={0.25} display={"flex"}  alignItems={'center'}>
                                            <FormControlLabel
                                                    control={
                                                        <Controller
                                                            name='switchBurstProof'
                                                            control={control}
                                                            render={({ field }) => (
                                                                <Checkbox
                                                                    size='small'
                                                                    {...field}
                                                                    checked={field.value}
                                                                    onChange={handleChangeCheckBox}
                                                                    disabled={modeViewOnly}
                                                                />
                                                            )}
                                                        />
                                                    }
                                                    label=''
                                                />
                                        </Grid>
                                        <Grid item xs={8.75} display={"flex"} justifyContent={'center'} alignItems={'center'} pl={1}>
                                            <Controller
                                                name="switchBurstProofEdit"
                                                control={control}
                                                rules={{
                                                }}
                                                render={({ field }) => (
                                                    <TextField 
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        error={!!errors?.switchBurstProofEdit}
                                                        helperText={errors?.switchBurstProofEdit?.message}
                                                        InputProps={{sx: { height: 32 }}}
                                                        disabled={!isDisableSwitchBurstProofEdit||modeViewOnly}
                                                        style={{background:!isDisableSwitchBurstProofEdit||modeViewOnly?"floralwhite":"white"}}
                                                    ></TextField>
                                                )}
                                                /> 
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={0.25} display={"flex"} alignItems={'center'} paddingLeft={0}></Grid>
                                        <Grid item xs={2.5} display={"flex"} justifyContent={'center'} alignItems={'center'} pl={1}></Grid>
                                        <Grid item xs={0.25} display={"flex"} justifyContent={'center'} alignItems={'center'}></Grid>
                                        <Grid item xs={0.25} display={"flex"}  alignItems={'center'}>
                                            <FormControlLabel
                                                    control={
                                                        <Controller
                                                            name='switchUser'
                                                            control={control}
                                                            render={({ field }) => (
                                                                <Checkbox
                                                                    size='small'
                                                                    {...field}
                                                                    checked={field.value}
                                                                    onChange={handleChangeCheckBox}
                                                                    disabled={modeViewOnly}
                                                                />
                                                            )}
                                                        />
                                                    }
                                                    label=''
                                                />
                                        </Grid>
                                        <Grid item xs={8.75} display={"flex"}  alignItems={'center'} pl={1}>
                                            <Controller
                                                    name="switchUserText"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextareaAutosize
                                                            {...field}
                                                            maxRows={2}
                                                            minRows={2}
                                                            style={{overflowY:'scroll',resize:'none',fontFamily:'Roboto,Helvetica,Arial,sans-serif',fontSize:'16px',width:'100%',
                                                                    borderRadius:'4px',padding:'5px 20px', border:'0.1px solid gray',
                                                                    background:!isDisableSwitchUserText||modeViewOnly?"floralwhite":"white"}}
                                                            disabled={!isDisableSwitchUserText||modeViewOnly}
                                                            />
                                                            )}
                                                        />
                                        </Grid>
                                    </Grid>


                                    <Grid container marginTop={1}>
                                        <Grid item xs={0.25} display={"flex"} alignItems={'center'} paddingLeft={0}>7.</Grid>
                                        <Grid item xs={2.5} display={"flex"} justifyContent={'center'} alignItems={'center'} pl={1}>
                                            <Controller
                                                    name="titleAppliance"
                                                    control={control}
                                                    rules={{
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField 
                                                            {...field}
                                                            fullWidth
                                                            type="text"
                                                            size="small"
                                                            error={!!errors?.titleAppliance}
                                                            helperText={errors?.titleAppliance?.message}
                                                            InputProps={{sx: { height: 32 }}}
                                                            disabled={modeViewOnly}
                                                        ></TextField>
                                                    )}
                                                    /> 
                                        </Grid>
                                        <Grid item xs={0.25} display={"flex"} justifyContent={'center'} alignItems={'center'}>:</Grid>
                                        <Grid item xs={9} display={"flex"}  alignItems={'center'} >
                                            <Controller
                                                    name="appliance"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextareaAutosize
                                                            {...field}
                                                            maxRows={2}
                                                            minRows={2}
                                                            style={{overflowY:'scroll',resize:'none',fontFamily:'Roboto,Helvetica,Arial,sans-serif',fontSize:'16px',width:'100%',
                                                                    borderRadius:'4px',padding:'5px 20px', border:'0.1px solid gray'}}
                                                            disabled={modeViewOnly}
                                                            />
                                                            )}
                                                        />
                                        </Grid>
                                    </Grid>


                                    <Grid container marginTop={1}>
                                        <Grid item xs={0.25} display={"flex"} alignItems={'center'} paddingLeft={0}>8.</Grid>
                                        <Grid item xs={2.5} display={"flex"} justifyContent={'center'} alignItems={'center'} pl={1}>
                                            <Controller
                                                    name="titleOther"
                                                    control={control}
                                                    rules={{
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField 
                                                            {...field}
                                                            fullWidth
                                                            type="text"
                                                            size="small"
                                                            error={!!errors?.titleOther}
                                                            helperText={errors?.titleOther?.message}
                                                            InputProps={{sx: { height: 32 }}}
                                                            disabled={modeViewOnly}
                                                        ></TextField>
                                                    )}
                                                    /> 
                                        </Grid>
                                        <Grid item xs={0.25} display={"flex"} justifyContent={'center'} alignItems={'center'}>:</Grid>
                                        <Grid item xs={9} display={"flex"}  alignItems={'center'} >
                                            <Controller
                                                    name="other"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextareaAutosize
                                                            {...field}
                                                            maxRows={2}
                                                            minRows={2}
                                                            style={{overflowY:'scroll',resize:'none',fontFamily:'Roboto,Helvetica,Arial,sans-serif',fontSize:'16px',width:'100%',
                                                                    borderRadius:'4px',padding:'5px 20px', border:'0.1px solid gray'}}
                                                            disabled={modeViewOnly}
                                                            />
                                                            )}
                                                        />
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={0.25} display={"flex"} alignItems={'center'} paddingLeft={0}>9.</Grid>
                                        <Grid item xs={2.5} display={"flex"} justifyContent={'center'} alignItems={'center'} pl={1}>
                                            <Controller
                                                name="titleInstrument"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField 
                                                        {...field}
                                                        onChange={handleChangeSetTitleInstrument}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        error={!!errors?.titleOther}
                                                        helperText={errors?.titleOther?.message}
                                                        InputProps={{sx: { height: 32 }}}
                                                        disabled={modeViewOnly}
                                                    ></TextField>
                                                )}
                                            /> 
                                        </Grid>
                                        <Grid item xs={0.25} display={"flex"} justifyContent={'center'} alignItems={'center'}></Grid>
                                        <Grid item xs={2} display={"flex"}  alignItems={'center'} >
                                            <Stack alignItems={'flex-end'} mt={1}>
                                                <Button 
                                                    variant="contained"
                                                    onClick={handleOpenCover3Dialog}
                                                    disabled={isDisableEditBtn||modeViewOnly}
                                                >
                                                    編集
                                                </Button>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                            </Grid>  
                            </div>
                            <Stack direction='row' justifyContent="flex-end" gap={2} mt={1} paddingBottom={1}>
                                <Button variant="contained" type="submit" disabled={modeViewOnly}>OK</Button>
                                <Button color="inherit" variant="contained" onClick={onCancel}>キャンセル</Button>
                            </Stack>
                        </form>
                        {isOpenedCover3Dialog &&
                            <SettingCover3Dialog
                                diaglogTitle={getValues('titleInstrument')}
                                data={array}
                                onOK={handleOkCover3Dialog}
                                onCancel={handleCancelCover3Dialog}
                            />
                        }
                    </div>                    
                </DialogContent>
            </Dialog>
        </>
    )
}
export default SettingCover2Dialog;