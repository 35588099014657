import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Autocomplete, Box, Button, CircularProgress, Collapse, Grid, Stack, TextField, Typography } from "@mui/material";
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from 'react-hook-form';
import { connect } from "react-redux";
import { ApplicationState } from "../../../store";
import { actionCreators } from "../../../store/AppStore";
import LoginHistoryGrid from './LoginHistoryGrid';
import { loginHistoryOptions } from "../../../statics";
import { post } from "../../CallApi";
import { downloadFile } from "../../../utils/downloadFile";

dayjs.extend(utc);

interface SearchItemForm{
    email: string;
    username: string;
    custom_company_name: string;
    history_type: string;
    history_start_date: string;
    history_end_date: string;
    target: string;
    limit: number;
    page: number;
}

const initialValue: SearchItemForm = {
    email: "",
    username: "",
    custom_company_name: "",
    history_type: "",
    history_start_date: "",
    history_end_date: "",
    target: "",
    limit: 50,
    page: 1,
};

const initialPageData = {
    rowFrom: '0',
    rowTo: '0',
    totalRow: '0',
    currPage: '0',
    totalPage: '0',
};

type LoginHistoryListProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
    
export const LoginHistoryList = (props: LoginHistoryListProps) => {
    // #region props
    const { userId, onGetError, onExportCsvSearchError, onExportCsvError } = props;
    
    const [loadingFlag, setLoadingFlag] = useState(false);

    const [pageData, setPageData] = useState(initialPageData);
    
    const [openSearch, setOpenSearch] = useState(false);
    const [keyword, setKeyword] = useState("");
    const [startDateSelect,setStartDateSelect] = useState<any>();
    const [endDateSelect,setEndDateSelect] = useState<any>();

    const { handleSubmit, control, setValue, getValues } = useForm<SearchItemForm>({
        mode: 'all',
        criteriaMode: "all",
        defaultValues: initialValue,
    });

    const gridRef = useRef() as any;
    // #endregion props

    useEffect(() => {
        handleSearchSubmit(getValues());
    },[])
    
    // #region handleMethod
    const handleChangePageData = (name: any, value: any) => {
        // 値変更
        setValue(name, value);
        if (name === 'limit') {
            setValue("page", 1);
        }
        handleSearchSubmit(getValues());
    };

    const handleChangeKeyword = (value: string) => {
        setKeyword(value);
    }

    // 検索実行
    const handleSearchSubmit = async (value: any) => {
        setLoadingFlag(true);

        const params = getSearchCondition(value)
        const result = await post("/master/login-history/get", params);
        if (result.success) {
            if (gridRef && gridRef.current) {
                gridRef.current.setRowData(result.data.results)
            }
            handleSetPageData(result.data)
        } else {
            onGetError();
        }
        setLoadingFlag(false);
    }

    const handleChangeDatetime = (name: any, value: any) => {
        setValue(name, value)
        if (name == "history_start_date")
            setStartDateSelect(value)
        else if (name == "history_end_date")
            setEndDateSelect(value)
    }

    const getSearchCondition = (value: any) => {
        const params: any = {
            userId: userId,
            requestPrms:{
                default: true,
                limit: getValues('limit'),
                page: getValues("page")
            }
        };
        if(value.email) {
            params.requestPrms["default"] = false;
            params.requestPrms["email"] = value.email;
        }
        if(value.username) {
            params.requestPrms["default"] = false;
            params.requestPrms["username"] = value.username;
        }
        if(value.custom_company_name) {
            params.requestPrms["default"] = false;
            params.requestPrms["custom_company_name"] = value.custom_company_name;
        }
        if(value.history_type) {
            params.requestPrms["default"] = false;
            params.requestPrms["history_type"] = value.history_type;
        }
        if(value.history_start_date) {
            params.requestPrms["default"] = false;
            params.requestPrms["history_start_date"] = value.history_start_date;
        }
        if(value.history_end_date) {
            params.requestPrms["default"] = false;
            params.requestPrms["history_end_date"] = value.history_end_date;
        }
        return params
    }

    const handleSetPageData = (data: any) => {
        const totalCount = data.totalCount;
        const pageSize = getValues('limit');
        const page = Number(data.page);
        const totalPage = Math.ceil(totalCount / pageSize);
        const rowFrom = (pageSize * page - pageSize) + 1;
        const rowTo = (pageSize * page) > totalCount ? totalCount : (pageSize * page);
        const updateData = {
            rowFrom: rowFrom.toLocaleString(),
            rowTo: rowTo.toLocaleString(),
            totalRow: totalCount.toLocaleString(),
            currPage: page.toLocaleString(),
            totalPage: totalPage.toLocaleString(),
        };
        setPageData(updateData);
    }

    const handleClearSeachCondition = () => {
        setValue("email", "");
        setValue("username", "");
        setValue("custom_company_name", "");
        setValue("history_type", "");
        setValue("history_start_date", "");
        setValue("history_end_date", "");
        setStartDateSelect(null);
        setEndDateSelect(null);
    }

    const handleSearch = () => {
        handleSearchSubmit(getValues());
    }

    const handleExportCSV = async () => {
        const params = getSearchCondition(getValues())
        if (params.requestPrms["default"] == true){
            onExportCsvSearchError()
            return
        }

        setLoadingFlag(true);
        const result = await post("/master/login-history/export-csv", params);
        if (result.success) {
            if (result.data.data) downloadFile(result.data.data)
        } else {
            onExportCsvError();
        }
        setLoadingFlag(false);
    }
    //  #endregion handleMethod

    return (<>
        {loadingFlag && (
            <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1500, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                <CircularProgress />
            </div>
        )}
        <div style={{ height: '100%', width: '100%', marginTop: '10px' }}>
            <Stack style={{ color: 'MenuText' }}>
                <Typography variant="h4">履歴表示</Typography>
            </Stack>
            <form onSubmit={handleSubmit(handleSearchSubmit)}>
                <Stack spacing={1} p={1} style={{ border: openSearch ? "2px solid gray" : '', borderRadius: "15px" }}>
                    <div style={{ cursor: 'pointer', display: 'inline-block' }} onClick={() => {setOpenSearch((prev:boolean) => !prev)}}>
                        <div style={{ display: 'inline-block' }}>
                            <Typography variant="h6" >検索条件</Typography>
                        </div>
                        <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                            {
                                openSearch ? <ArrowDropDownIcon /> : <ArrowRightIcon />
                            }
                        </div>
                    </div>
                    <div>
                        <Collapse in={openSearch} timeout="auto" unmountOnExit>
                            <Grid container spacing={1}>
                                <Grid item xs={2}>
                                    <Controller
                                        control={control}
                                        name="email"
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                type="text"
                                                label="メールアドレス"
                                                size="small"
                                                InputProps={{ sx: { borderRadius: "8px" } }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <Controller
                                        control={control}
                                        name="username"
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                type="text"
                                                label="ユーザー名"
                                                size="small"
                                                InputProps={{ sx: { borderRadius: "8px" } }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <Controller
                                        control={control}
                                        name="custom_company_name"
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                type="text"
                                                label="会社名"
                                                size="small"
                                                InputProps={{ sx: { borderRadius: "8px" } }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <Controller
                                        control={control}
                                        name="history_type"
                                        rules={{ required: '必須項目です。選択してください。' }}
                                        render={({ field: { value } }:any) => (
                                            <Autocomplete
                                                autoHighlight
                                                value={loginHistoryOptions.find(options => options.value == value)??null}
                                                options={loginHistoryOptions}
                                                getOptionLabel={(option) => option.label}
                                                fullWidth
                                                renderInput={(params) => <TextField {...params} label="種別" size="small" />}
                                                onChange={(_, data) => {
                                                    if(data) setValue("history_type", data.value)
                                                    else setValue("history_type", "")
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} mt={1}>
                                <Grid item xs={4} minWidth="30rem">
                                    <Stack spacing={1} direction="row">
                                        <Controller
                                            control={control}
                                            name="history_start_date"
                                            render={({ field: { value } }) => (
                                                <>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DateTimePicker
                                                            format="YYYY/MM/DD HH:mm:ss"
                                                            views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
                                                            openTo="day"
                                                            ampm={false}
                                                            timezone="UTC"
                                                            label="日時From"
                                                            value={startDateSelect}
                                                            onChange={(newValue) => handleChangeDatetime("history_start_date", newValue)}
                                                            slotProps={{ actionBar: { actions: [] }, textField: { size: 'small', sx: { backgroundColor: "white" } } }}
                                                        />
                                                    </LocalizationProvider>
                                                </>
                                            )}
                                        />
                                        <Stack justifyContent="center" alignItems="center">
                                            ～
                                        </Stack>
                                        <Controller
                                            control={control}
                                            name="history_end_date"
                                            render={({ field: { value } }) => (
                                                <>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DateTimePicker
                                                            format="YYYY/MM/DD HH:mm:ss"
                                                            views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
                                                            openTo="day"
                                                            ampm={false}
                                                            timezone="UTC"
                                                            label="日時To"
                                                            value={endDateSelect}
                                                            onChange={(value) => handleChangeDatetime("history_end_date", value)}
                                                            slotProps={{ actionBar: { actions: [] }, textField: { size: 'small', sx: { backgroundColor: "white" }} }}
                                                        />
                                                    </LocalizationProvider>
                                                </>
                                            )}
                                        />
                                    </Stack>
                                </Grid>
                                <Grid item>
                                    <Button variant="outlined" onClick={handleClearSeachCondition}>クリア</Button>
                                </Grid>
                                <Grid item>
                                    <Button variant="contained" onClick={handleSearch}>検索</Button>
                                </Grid>
                            </Grid>
                        </Collapse>
                    </div>
                </Stack>
            </form>
            <div style={{ marginBottom: "10px", paddingTop:"5px", marginTop:`${openSearch?10:0}px`}}>
                <Stack direction="row" justifyContent={"flex-end"} mb={1}>
                    <Box>
                        <TextField
                            fullWidth
                            autoComplete="searchStr"
                            name="keyword"
                            type="text"
                            label="フィルター"
                            size="small"
                            value={keyword}
                            InputProps={{ sx: { borderRadius: "8px" } }}
                            onChange={e => handleChangeKeyword(e.target.value)}
                        />
                    </Box>
                    <Box ml={1}>
                        <Button variant="outlined" onClick={e => handleChangeKeyword("")}>クリア</Button>
                    </Box>
                    <Box ml={2}>
                        <Button variant="contained" size="medium" onClick={handleExportCSV}>CSV</Button>
                    </Box>
                </Stack>
                <Stack>
                    <LoginHistoryGrid
                        ref={gridRef}
                        openSearch={openSearch}
                        pageSize={getValues('limit')}
                        pageData={pageData}
                        onChangeSearchCondition={handleChangePageData}
                        keyword={keyword}
                    />
                </Stack>
            </div>
        </div>
    </>
    )
}

const mapStateToProps = (state: ApplicationState) => ({
    userId: state.app.user?.userId,
})

const mapDispatchToProps = (dispatch: any) => ({
    onGetError: () =>
        dispatch(actionCreators.showMessage({
            type: "error",
            title: "履歴表示",
            body: "履歴表示を取得できません。",
        })),
    onExportCsvSearchError: () =>
        dispatch(actionCreators.showMessage({
            type: "error",
            title: "csvにてエクスポート",
            body: "検索条件を1つ以上入力してください",
        })),
    onExportCsvError: () =>
        dispatch(actionCreators.showMessage({
            type: "error",
            title: "csvにてエクスポート",
            body: "csvにてエクスポートを取得できません。",
        })),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginHistoryList as any);
