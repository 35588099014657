import { store } from "../../..";
import { CdPoint, GraphLineData, MsTransScottModel, Point } from "../../../models/Index";
import { MS_MODE_CALCULATION } from "../../../statics";
import { doSetPosCurvePointDirect } from "../DCView";

const doDrawCurveTransScott= (props:MsTransScottModel, drawMode : number) =>{
        const {
            voltageSecondary : dVoltSecondary,
            capacity : dCapacity
        } = props

        let graphLineData : GraphLineData = { lines: [] }

        let dRateCurrent = doGetValueRatedCurrent(dVoltSecondary,dCapacity)*dVoltSecondary;
        if(drawMode === 0 || drawMode === 1) {
             //保護協調 - [主座変圧器]
            // let m_bDispInrush = props.partSeatM.dispInrush;
            // let m_bDispIntensity = props.partSeatM.dispIntensity;

            const inrushPoints1 : Point[] = []
            const intensityPoints1 : Point[] = []
            const part1 ={
                m_listDrawInrush: [] as CdPoint[],
                m_listDrawIntensity: [] as CdPoint[]
            }
            //if(m_bDispInrush){
                if(doDrawInrushPart(dRateCurrent,props,part1.m_listDrawInrush,1)){
                    part1.m_listDrawInrush.forEach(point => inrushPoints1.push({x: point.m_dx, y: point.m_dy} as Point))
                    graphLineData.lines.push({
                        points: inrushPoints1,
                        id: 'INRUSH_1'
                    })
                }
            //}
            //if(m_bDispIntensity){
                if(doDrawIntensityPart(dRateCurrent,props,part1.m_listDrawIntensity,1)){
                    part1.m_listDrawIntensity.forEach(point => intensityPoints1.push({x: point.m_dx, y: point.m_dy} as Point))
                    graphLineData.lines.push({
                        points: intensityPoints1,
                        id: 'INTENSITY_1'
                    })
                }
            //}
        }
       
        let mode = store.getState().app.diagram.processMode;
        if(drawMode === 0 || drawMode === 2) {
             // get data draw calcpoint
            if (mode >= MS_MODE_CALCULATION && props.partSeatM.fault) {
                let temp = doSetPosCurvePointDirect(props.partSeatM.calcPoint, 0);
                if(temp !== undefined) {
                    graphLineData.lines.push(temp);
                }

            }
        }
        
        if(drawMode === 0 || drawMode === 1) {
            // 保護協調 - [T座変圧器]
            // let m_bDispInrush2 = props.partSeatT.dispInrush;
            // let m_bDispIntensity2 = props.partSeatT.dispIntensity;
            const inrushPoints2 : Point[] = []
            const intensityPointsOutIn2 : Point[] = []
            const part2 ={
                m_listDrawInrush: [] as CdPoint[],
                m_listDrawIntensity: [] as CdPoint[]
            }
            //if(m_bDispInrush2){
                if(doDrawInrushPart(dRateCurrent,props,part2.m_listDrawInrush,2)){
                    part2.m_listDrawInrush.forEach(point => inrushPoints2.push({x: point.m_dx, y: point.m_dy} as Point))
                    graphLineData.lines.push({
                        points: inrushPoints2,
                        id: 'INRUSH_2'
                    })
                }
            //}
            //if(m_bDispIntensity2){
                if(doDrawIntensityPart(dRateCurrent,props,part2.m_listDrawIntensity,2)){
                    part2.m_listDrawIntensity.forEach(point => intensityPointsOutIn2.push({x: point.m_dx, y: point.m_dy} as Point))
                    graphLineData.lines.push({
                        points: intensityPointsOutIn2,
                        id: 'INTENSITY_2'
                    })
                }
            //}
        }
        
        if(drawMode === 0 || drawMode === 1) {
            if (mode >= MS_MODE_CALCULATION && props.partSeatT.fault) {
                let temp = doSetPosCurvePointDirect(props.partSeatT.calcPoint, 1);
                if(temp !== undefined) {
                    graphLineData.lines.push(temp);
                }
    
            }
        }
        
    return graphLineData;
}

const doGetValueRatedCurrent = (dVoltSecondary:number,dCapacity:number) =>{
       let dRateCurrent = 0;
       if(dVoltSecondary <=0){
        return 0;
       }else{
        dRateCurrent =(dCapacity /2)*1000 / dVoltSecondary;
       }
       return dRateCurrent;
}

const doDrawInrushPart = (dRatedCurrent:number, props:MsTransScottModel, m_listDrawInrush:Array<CdPoint>,m_PartSeat:number) : boolean => {

    if (doMakeCurveInrush(dRatedCurrent, props, m_listDrawInrush,m_PartSeat) !== true)
    {
            return false;
    }

    return true;
}

const doMakeCurveInrush = (dRatedCurrent:number, props:MsTransScottModel, m_listDrawInrush:Array<CdPoint>,m_PartSeat:number) : boolean  =>{
    
    let dInrushTimes =0;
    let dDampingTime = 0;
    
    if (dRatedCurrent <= 0)
    {
        return false;
    }
    
        //保護協調 - [主座変圧器]//主
        if(m_PartSeat === 1){
            dInrushTimes = props.partSeatM.inrushTimes;
            dDampingTime = props.partSeatM.dampingTime;
           
        }
    
        //tab4 - 保護協調 - [T座変圧器]
        //T座変更
        if(m_PartSeat === 2){
            dInrushTimes = props.partSeatT.inrushTimes;
            dDampingTime = props.partSeatT.dampingTime;
           
        }

    let pdPoint : CdPoint = {
        m_dx: 0,
        m_dy: 0
    };
        let nCount = 0;
        for(let dTime = dDampingTime * 10;dTime >=0.01;dTime -=(dTime / 10) ){
            let dCurrent = dRatedCurrent * dInrushTimes * Math.exp(-1 * (dTime - 0.01) / (dDampingTime/1000));
            if(dCurrent >= dRatedCurrent){
                if(nCount ===0){
                    pdPoint.m_dx = dRatedCurrent;
                    pdPoint.m_dy = dTime * 1000; 
                    m_listDrawInrush.push({...pdPoint});

                    pdPoint.m_dx = dRatedCurrent;
                    pdPoint.m_dy = dTime + dTime/ 5;
                    m_listDrawInrush.push({...pdPoint});

                    pdPoint.m_dx = dRatedCurrent + dRatedCurrent /30;
                    pdPoint.m_dy = dTime + dTime/ 10;
                    m_listDrawInrush.push({...pdPoint});

                    nCount++;
                    if(dCurrent <= pdPoint.m_dx){
                        pdPoint.m_dx = dRatedCurrent + dRatedCurrent /10;
                        pdPoint.m_dy = dTime;
                        m_listDrawInrush.push({...pdPoint});
                        continue;
                    }

                }
                pdPoint.m_dx =dCurrent;
                pdPoint.m_dy = dTime;
                m_listDrawInrush.push({...pdPoint});
                
            }
        }
        pdPoint.m_dx = dRatedCurrent * dInrushTimes;
        pdPoint.m_dy = 0.01;
        m_listDrawInrush.push({...pdPoint});

        pdPoint.m_dx = dRatedCurrent * dInrushTimes - dRatedCurrent * dInrushTimes /100
        pdPoint.m_dy = 0.0095;
        m_listDrawInrush.push({...pdPoint});

        pdPoint.m_dx = dRatedCurrent *dInrushTimes - dRatedCurrent * dInrushTimes / 30
        pdPoint.m_dy = 0.009;
        m_listDrawInrush.push({...pdPoint});

        pdPoint.m_dx = dRatedCurrent * dInrushTimes - dRatedCurrent * dInrushTimes / 15
        pdPoint.m_dy = 0.0087;
        m_listDrawInrush.push({...pdPoint});

        pdPoint.m_dx = dRatedCurrent * dInrushTimes - dRatedCurrent*dInrushTimes / 5
        pdPoint.m_dy = 0.0082;
        m_listDrawInrush.push({...pdPoint});

        pdPoint.m_dx = dRatedCurrent / 10
        pdPoint.m_dy = 0.005;
        m_listDrawInrush.push({...pdPoint});
    
    return true;

}

const doDrawIntensityPart = (dRatedCurrent:number, props:MsTransScottModel, m_listDrawInrush:Array<CdPoint>,m_PartSeat:number) : boolean  =>{
    if(doMakeCurveIntensity(dRatedCurrent, props, m_listDrawInrush,m_PartSeat)!==true){
        return false;
    }

return true;
}

const doMakeCurveIntensity = (dRatedCurrent:number, props:MsTransScottModel, m_listDrawInrush:Array<CdPoint>,m_PartSeat:number) : boolean  =>{
    let m_dIntensityTimes = 0;
    let m_dIntensityTime = 0;
    const pdPoint: CdPoint ={
        m_dx :0,
        m_dy :0
    }
    if (dRatedCurrent <= 0)
    {
        return false;
    }
    if(m_PartSeat===1){
        m_dIntensityTimes = props.partSeatM.intensityTimes;
        m_dIntensityTime = props.partSeatM.intensityTime;
    }
    if(m_PartSeat ===2){
        m_dIntensityTimes = props.partSeatT.intensityTimes;
        m_dIntensityTime = props.partSeatT.intensityTime;
    }
    pdPoint.m_dx = dRatedCurrent *m_dIntensityTimes
    pdPoint.m_dy = m_dIntensityTime
    m_listDrawInrush.push({...pdPoint})
    
    return true;
}

const doGetValueParmaryRatedCurrent = (lVoltPrimary:number,dCapacity:number) =>{
    let dRateCurrent = lVoltPrimary;
    dRateCurrent = dCapacity * 1000 / dRateCurrent / Math.sqrt(3.0);
    return dRateCurrent;
}

const doGetTextRatedCurrent =(nPartKind:number,lVolSecondary:number,dCapacity:number,lVoltPrimary:number) =>{
    let dRateCurrent = 0;
    switch(nPartKind){
        case 0: //MS_3PHASES //三相　一次側
            dRateCurrent = doGetValueParmaryRatedCurrent(lVoltPrimary,dCapacity);
            break;
        case 1://MS_SEAT_MAIN //主座
        case 2://MS_SEAT_T //T座
            dRateCurrent = doGetValueRatedCurrent(lVolSecondary,dCapacity);
            break;
        
    }
    return dRateCurrent; //A
}

// ============================= Label line =============================
const getMsTransscottInrushLabel = (properties : MsTransScottModel, infos : string[], dNominalVolt:number, nPartKind:number) => { 
    const text = properties.refNo;
    const name = properties.partSeatM.pointText
    const lVoltPrimary = properties.voltagePrimary;
    const lVolSecondary = properties.voltageSecondary;
    const dCapacity = Math.round(properties.capacity);
    let dInrushTimes = 0;
    let dDampingTime = 0;
    //保護協調 - [主座変圧器]//主
    if(nPartKind === 1){
        dInrushTimes = properties.partSeatM.inrushTimes;
        dDampingTime = properties.partSeatM.dampingTime;
    }

    //tab4 - 保護協調 - [T座変圧器]
    //T座変更
    if(nPartKind === 2){
        dInrushTimes = properties.partSeatT.inrushTimes;
        dDampingTime = properties.partSeatT.dampingTime;
    }
    const textRateCurrent = Math.round(doGetTextRatedCurrent(nPartKind,lVolSecondary,dCapacity,lVoltPrimary));
    infos.push(text +`,`);
    infos.push(nPartKind === 1 ? `主座` : `T座`)
    infos.push(name);
    infos.push(dCapacity.toString()+`kVA`);
    if(lVolSecondary === dNominalVolt){
        
        infos.push(textRateCurrent.toString() +`A`)
    }
    else{
        const dRateCurrent = doGetValueRatedCurrent(lVolSecondary,dCapacity);
        const ampere = Math.round(dRateCurrent * lVolSecondary /dNominalVolt)
        infos.push(ampere.toString()+`A` +`(` +textRateCurrent+`)`);

    }
    
    infos.push(Math.round(dInrushTimes).toString()+`倍`+`,`+ dDampingTime+`ms`)
}
const doGetTextIntensityValue =(nPartKind:number,m_lVoltSecondary:number,m_dCapacity:number,m_dIntensityTimes:number) =>{
    let dRateCurrent = 0;
    let dIntensityValue = 0;
    switch(nPartKind){
        case 1://MS_SEAT_MAIN //主座
            dRateCurrent = doGetValueRatedCurrent(m_lVoltSecondary,m_dCapacity)
            if(dRateCurrent === 0){
                dIntensityValue =0
            }else{
                dIntensityValue = m_dIntensityTimes * dRateCurrent;
            }
            break;
        case 2://MS_SEAT_T //T座
            dRateCurrent = doGetValueRatedCurrent(m_lVoltSecondary,m_dCapacity);
            if(dRateCurrent === 0){
                dIntensityValue = 0;
            }else{
                dIntensityValue = m_dIntensityTimes * dRateCurrent;
            }
            break;
    }
    return dIntensityValue;//Arms
}

// ============================= Label line =============================
const getMsTransscottIntensityLabel = (properties : MsTransScottModel, infos : string[],dNominalVolt:number,nPartKind:number) => { 
    const text = properties.refNo;
    const name = properties.partSeatM.pointText
    const lVolSecondary = properties.voltageSecondary;
    const dCapacity = Math.round(properties.capacity);
    infos.push(text);
    infos.push(nPartKind === 1 ? `主座` : `T座`)
    infos.push(name);
    infos.push(dCapacity.toString()+`kVA`)
    let dInrushTimes = 0;
    let m_dIntensityTime = 0;
    //保護協調 - [主座変圧器]//主
    if(nPartKind === 1){
        dInrushTimes = properties.partSeatM.inrushTimes;
        m_dIntensityTime = properties.partSeatM.intensityTime;
    }

    //tab4 - 保護協調 - [T座変圧器]
    //T座変更
        if(nPartKind === 2){
            dInrushTimes = properties.partSeatT.inrushTimes;
            m_dIntensityTime = properties.partSeatT.intensityTime;
        }

        infos.push(dInrushTimes.toString()+`倍`);
        const arms = Math.round(doGetTextIntensityValue(nPartKind,lVolSecondary,dCapacity,dInrushTimes));
        if(lVolSecondary === dNominalVolt){
            infos.push(arms.toString() +`Arms`);
           
        }
        else{
            let dIntensityValue = 0;
            const dRateCurrent = doGetValueRatedCurrent(lVolSecondary,dCapacity);
            dIntensityValue = dInrushTimes * dRateCurrent;
            const arms1 = Math.round(dIntensityValue * lVolSecondary/dNominalVolt);
            infos.push(arms1 +`Arms` +`(`+arms+`)`)
            
        }
        infos.push(m_dIntensityTime.toString() +`s`)
}

export {
    doMakeCurveInrush,
    doDrawCurveTransScott,
    getMsTransscottInrushLabel,
    getMsTransscottIntensityLabel
}