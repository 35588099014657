import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Grid, IconButton } from "@mui/material";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from 'ag-grid-react';
import { forwardRef, useImperativeHandle, useMemo, useRef, useState } from "react";
import { PageData } from '../../../models/Index';
import { AG_GRID_LOCALE_JP } from '../../../themes/localeJP';
import LoadingOverlay, { DefaultLoadingMessage } from '../../common/LoadingOverlay';
import NoRowsOverlay, { NoRowsMessage } from '../../common/NoRowsOverlay';
import PaginationCpn from '../../common/PaginationCpn';
import { BreakGroupModel } from '../../../models/breakModel';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

//--------------------
// Props
export type BreakGroupGridProps = {
    colState: any[];
    pageSize?: number;
    pageData?: PageData;
    keyword: string;
    onChangeSearchCondition: (name: string, value: any) => void;
    onShowDetails: (lGroupID: string) => void;
    onDelete: (data: BreakGroupModel) => void;
    heightForm?: string;
    checkEdit?: boolean;
    mode?: string;
};

// -------------
// Component
export const BreakGroupGrid = forwardRef((props: BreakGroupGridProps, ref) => {
    const { mode, colState, pageSize, pageData, keyword, heightForm, checkEdit,
        onChangeSearchCondition, onDelete, onShowDetails } = props;
    const gridRef = useRef() as any;
    const [rowData, setRowData] = useState();

    useImperativeHandle(ref, () => ({
        setRowData(value: any) {
            setRowData(value)
        },

        hideOverlay() {
            gridRef.current.api.hideOverlay();
        }
    }));


    // ag-Grid の準備完了後に呼ばれるコールバック関数
    const onGridReady = (grid: any) => {
    };

    // Loading時のメッセージ
    const loadingOverlayComponent = useMemo(() => {
        return LoadingOverlay;
    }, []);
    const loadingOverlayComponentParams = useMemo(() => {
        return {
            loadingMessage: checkEdit ? "" : DefaultLoadingMessage,
        };
    }, []);

    const noRowsOverlayComponent = useMemo(() => {
        return NoRowsOverlay;
    }, []);

    const noRowsOverlayComponentParams = useMemo(() => {
        return {
            noRowsMessageFunc: () => NoRowsMessage,
        };
    }, []);

    // 編集ボタンのセル内表示とボタンイベント
    const cellMessageRenderer = (props: any) => {
        // 詳細
        const handleEdit = () => {
            onShowDetails(props.data.lgroupID);
        };

        const handleDelete = () => {
            onDelete(props.data.lgroupID);
        };

        return (
            <Grid container direction="row" justifyContent="flex-start" paddingLeft={"-60px"}>
                <IconButton aria-label="edit" onClick={handleEdit} >
                    <EditIcon fontSize="small" />
                </IconButton>
                <IconButton aria-label="delete" onClick={handleDelete} >
                    <DeleteIcon fontSize="small" />
                </IconButton>
            </Grid>
        );
    };

    // 全列に共通の定義
    const defaultColDef = {
        sortable: true, // ソート可
        editable: false, // 編集不可
        resizable: true,
        suppressMovable: true,  // 列の移動を無効にする
    };

    // 列タイプの定義
    const columnTypes = {
        nonEditableColumn: { editable: false }, // 編集不可
    };


    const cellRenderManuName =(props:any)=>{
       return props.data.lmanufactureID +" "+ props.data.smanuName
    }

    const cellRenderkindFlag = (props: any) => {
        switch (props.data.nkindFlag) {
            case 1:
                return ("高圧遮断器");
                break;
            case 2:
                return ("低圧遮断器");
                break;
            case 3:
                return ("サーマルリレー");
                break;
            case 4:
                return ("ヒューズ");
                break;
            case 5:
                return ("2E");
                break;
            case 6:
                return ("配変OCR");
            default:
                return ("");
        }
    }

    const columnDefs = [
        {
            headerName: "機種群ID",
            field: "lgroupID",
            rowDrag: false,
            flex: 1.5,
            maxWidth: 150
        },
        {
            headerName: "メーカー",
            field: "lmanufactureID",
            rowDrag: false,
            flex: 1.5,
            maxWidth: 150,
            cellRenderer: cellRenderManuName
        },
        {
            headerName: "機器種類",
            field: "nkindFlag",
            rowDrag: false,
            flex: 1.5,
            maxWidth: 150,
            cellRenderer: cellRenderkindFlag,
        },
        {
            headerName: "デフォルト",
            field: "bisDefault",
            rowDrag: false,
            flex: 1.5,
            maxWidth: 150,

        },
        {
            headerName: "グループ名",
            field: "sgroupName",
            rowDrag: false,
            flex: 1.5,
        },
        {
            headerName: "",
            field: "option",
            reseizable: false,
            suppressMovable: true,
            minWidth: 110,
            maxWidth: 110,
            cellRenderer: cellMessageRenderer,
        }
    ]

    if (mode === "edit") {
        columnDefs.splice(columnDefs.findIndex(item => item.field === "lmanufactureID"), 1)}

    const rowHeight = 48;
    gridRef.current?.api?.setQuickFilter(keyword)

    const handleEdit = (props: any) => {
        onShowDetails(props.data.lgroupID);
    }
    return (
        <>
            <div>
                <div className="ag-theme-alpine" style={{ height: heightForm ? 'calc(100vh - ' + heightForm + 'px)' : 'calc(100vh - 275px)', width: "100%" }}>
                    <AgGridReact
                        ref={gridRef}
                        defaultColDef={defaultColDef}
                        columnTypes={columnTypes}
                        rowData={rowData}
                        onGridReady={onGridReady}
                        columnDefs={columnDefs}
                        domLayout={"normal"}
                        animateRows={true}
                        rowDragManaged={true}
                        suppressMovableColumns={false}
                        rowSelection={'single'}
                        suppressDragLeaveHidesColumns={false}
                        rowHeight={rowHeight}
                        localeText={AG_GRID_LOCALE_JP}
                        paginationPageSize={pageSize}
                        onCellDoubleClicked={handleEdit}
                        loadingOverlayComponent={loadingOverlayComponent}
                        loadingOverlayComponentParams={loadingOverlayComponentParams}
                        noRowsOverlayComponent={noRowsOverlayComponent}
                        noRowsOverlayComponentParams={noRowsOverlayComponentParams}
                    />
                </div>
                {pageSize && pageData && <PaginationCpn pageData={pageData} onChangeSearchCondition={onChangeSearchCondition} limit={pageSize} />}
            </div>
        </>
    );
});
export default BreakGroupGrid;