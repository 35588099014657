import { MsReactorModel, ControlModel, ProcessMode } from "../models/Index";
import { MS_TRANS1,
    MS_OUTSIDE_NEUTRAL,
    MS_OUTSIDE_OUTSIDE, 
    MS_SEAT_MAIN, 
    MS_SEAT_T, 
    RS_3PHASE,
    RS_PHASE_N,
    RS_PHASE_PE,
	MS_VOLTDROP_MOTOR,
	MS_VOLTDROP_REGULAR,
	MS_3PHASES,
	DIRECT_NONE,
	TOP_CENTER,
	LEFT_CENTER
} from "../models/Constants";
import { BeamsNumber } from "./FormatNumber";
import { doGetValuePerZk3 } from "./PercentZ"
import { doGetValueDropVoltRegular as doGetValueDropVoltRegularCalcPoint,
		doGetTextDropVoltMotor as doGetTextDropVoltMotorCalPoint,
		doGetTextDropVoltMotor2 as DoGetTextDropVoltMotor2CalcPoint,
		doGetTextDropPerMotor as doGetTextDropPerMotorCalcPoint,
		doGetTextDropVoltRegular as doGetTextDropVoltRegularCalcPoint,
		doGetTextDropPerRegular as doGetTextDropPerRegularCalcPoint,
		doGetTextDropPerRegular2 as doGetTextDropPerRegular2CalcPoint,
		isSetDropPerMotor,
		doGetIkpeakDirectMG,
		doGetIkasymDirectMG,
		doGetIksymDirectMG,
		doGetIksymTotalMG,
		doGetPerZDirectMG,
		doGetIkpeakTotalMG,
		doGetIkasymTotalMG,
		doGetPerZTotalMG
} from "./calcPointFunction";
import { store } from "..";
import { doGetLoadCurrentEndSide, doGetLoadCurrentStartSide, isCennectLoadDropElement, isCennectSourceDropElement } from "./element";
import { doGetTextVoltDropRegular } from "./mssv3Doc";
import { MS_MODE_CALCULATION, MS_MODE_CHECKING, MS_MODE_DRAWING, MS_MODE_HARMONIC, MS_MODE_PERCENT_Z, MS_MODE_POWER_FLOW, MS_MODE_VOLTDROP } from "../statics";

//CEleReactor::DoGetPerZrReactor
export const doGetPerZrReactor = (props:MsReactorModel, dStdCapacity:number) =>
{
	let percentZ = {
        perRk3 : 0,
        perXk3 : 0
    };

	if (props.capacity == 0) {
	 	percentZ.perRk3 = 0;
		percentZ.perXk3 = 0;
	}
	else {
		percentZ.perRk3 = props.percentR * dStdCapacity / props.capacity;
		percentZ.perXk3 = props.percentX * dStdCapacity / props.capacity;
	}

	return percentZ;
}

//CEleReactor::DoGetValueDropVoltRegular
export const doGetValueDropVoltRegular = (props:MsReactorModel, dStdCapacity:number, dStdVoltage:number) =>
{
	let percentZ = doGetPerZrReactor(props, dStdCapacity);
											
	let dVolt = 0;
	if (props.downTransKind == MS_TRANS1) {
		dVolt = doGetValueDropVoltRegularCalcPoint(dStdVoltage, percentZ.perRk3, percentZ.perXk3, props.calcPoint1);
	}
	else {
		dVolt = doGetValueDropVoltRegularCalcPoint(dStdVoltage, percentZ.perRk3, percentZ.perXk3, props.calcPoint0);
	}

	return dVolt;
}

//CEleReactor::DoGetTextCapacity
export const doGetTextCapacity = (props:MsReactorModel, bUnit:boolean = true) =>
{
	let sText = BeamsNumber(props.capacity, 6).toString();
	if (bUnit) {
		sText += 'kVA';
	}
	return sText;
}

//CEleReactor::DoGetValueRatedCurrent
export const doGetValueRatedCurrent = (lVoltage:number, dCapacity:number) =>
{
	let dRatedCurrent;

	if (lVoltage <= 0) {
		dRatedCurrent = 0;
	}
	else {
		dRatedCurrent = dCapacity * 1000 / lVoltage / Math.sqrt(3.0);
	}
	return dRatedCurrent;
}

//CEleReactor::DoGetTextRatedCurrent
export const doGetTextRatedCurrent = (props:MsReactorModel, bUnit:boolean = true) =>
{
	let dRatedCurrent = doGetValueRatedCurrent(props.voltage, props.capacity);
	let sText = BeamsNumber(dRatedCurrent, 4).toString();
	if (bUnit) {
		sText += "A";
	}
	return sText;
}

//CEleReactor::DoGetTextPointState
export const doGetTextPointState = (props:MsReactorModel) =>
{
	let sText = ''; 

	if (props.fault) {
		sText = 'Yes';
	}
	else {
		sText = 'No';
	}

	return sText;
}

//CEleReactor::DoGetTextPercentXe
export const doGetTextPercentXe = (props:MsReactorModel, bUnit:boolean = true) =>
{
	let sText = BeamsNumber(props.percentX, 4).toString();
	if (bUnit) {
		sText += '%e';
	}
	return sText;
}

//CEleReactor::DoGetTextPercentZr
export const doGetTextPercentZr = (props:MsReactorModel, dStdCapacity:number, bUnit:boolean = true) =>
{
	let percentZ = doGetPerZrReactor(props, dStdCapacity);
	let dPercentZ = doGetValuePerZk3(percentZ).dPercentZ;
	let sText = BeamsNumber(dPercentZ, 4, true).toString();
	if (bUnit && dPercentZ >= 0) {
		sText += '%r';
	}
	return sText;
}

//CEleReactor::DoGetTextPercentRr
export const doGetTextPercentRr = (props:MsReactorModel, dStdCapacity:number, bUnit:boolean = true) =>
{
	let percentZ = doGetPerZrReactor(props, dStdCapacity);
	let sText = BeamsNumber(percentZ.perRk3, 4).toString();
	if (bUnit) {
		sText += '%r';
	}
	return sText;
}

//CEleReactor::DoGetTextPercentXr
export const doGetTextPercentXr = (props:MsReactorModel, dStdCapacity:number, bUnit:boolean = true) =>
{
	let percentZ = doGetPerZrReactor(props, dStdCapacity);
	let sText = BeamsNumber(percentZ.perXk3, 4, true).toString();
	if (bUnit && percentZ.perXk3 >= 0) {
		sText += '%r';
	}
	return sText;
}

//CEleReactor::DoGetTextDirectIk3peak
export const doGetTextDirectIk3peak = (props:MsReactorModel, bUnit:boolean = true) =>
{
	let dIpeakDirect = 0;
	if (props.downTransKind == MS_TRANS1) {
		dIpeakDirect = doGetIkpeakDirectMG(props.calcPoint1);
	}
	else {
		dIpeakDirect = doGetIkpeakDirectMG(props.calcPoint0);
	}
	let sText;
	if (dIpeakDirect >= 0) {
		sText = BeamsNumber(dIpeakDirect / 1000, 4).toString();
		if(bUnit)
        {
            sText += 'kApeak';
        }
	}
	else {							
        sText = '????';
	}
	return sText;
}

//CEleReactor::DoGetTextDirectIk3asym
export const doGetTextDirectIk3asym = (props:MsReactorModel, bUnit:boolean = true) =>
{
	let dIasymDirect = 0;
	if (props.downTransKind == MS_TRANS1) {
		dIasymDirect = doGetIkasymDirectMG(props.calcPoint1);
	}
	else {
		dIasymDirect = doGetIkasymDirectMG(props.calcPoint0);
	}
	let sText;
	if (dIasymDirect >= 0) {
		sText = BeamsNumber(dIasymDirect / 1000, 4).toString();
        if(bUnit) {
            sText += 'kAasym';
        }
    }
    else {
        sText = '????';
    }
	return sText;
}

//CEleReactor::DoGetTextDirectIk3sym
export const doGetTextDirectIk3sym = (props:MsReactorModel, bUnit:boolean = true) =>
{
	let dIsymDirect = 0;
	if (props.downTransKind == MS_TRANS1) {
		dIsymDirect = doGetIksymDirectMG(props.calcPoint1);
	}
	else {
		dIsymDirect = doGetIksymDirectMG(props.calcPoint0);
	}
	let sText;
	if (dIsymDirect >= 0) {
		sText = BeamsNumber(dIsymDirect / 1000, 4).toString();
        if(bUnit) {
            sText += 'kAsym';
        }
    }
    else {
        sText = '????';
    }
	return sText;
}

//CEleReactor::DoGetTextTotalIk3sym
export const doGetTextTotalIk3sym = (props:MsReactorModel, bUnit:boolean = true) =>
{
	let dIsymTotal = 0;
	if (props.downTransKind == MS_TRANS1) {
		dIsymTotal = doGetIksymTotalMG(props.calcPoint1);
	}
	else {
		dIsymTotal = doGetIksymTotalMG(props.calcPoint0);
	}
	let sText;
	if (dIsymTotal >= 0) {
		sText = BeamsNumber(dIsymTotal / 1000, 4).toString();
        if(bUnit) {
            sText += 'kAsym';
        }
    }
    else {
        sText = '????';
    }
	return sText;
}

//CEleReactor::DoGetTextDropVoltMotor
export const doGetTextDropVoltMotor = (props:MsReactorModel, bUnit:boolean = true) =>
{
	return doGetTextDropVoltMotorCalPoint(props.calcPoint0, bUnit);
}

//CEleReactor::DoGetTextDropVoltMotor2
export const doGetTextDropVoltMotor2 = (props:MsReactorModel, bUnit:boolean = true) =>
{
	return DoGetTextDropVoltMotor2CalcPoint(props.calcPoint0, bUnit);
}

//CEleReactor::DoGetTextDropPerMotor
export const doGetTextDropPerMotor = (props:MsReactorModel, bUnit:boolean = true) =>
{
	return doGetTextDropPerMotorCalcPoint(props.calcPoint0, bUnit);
}

//CEleReactor::DoGetTextDropVoltRegular
export const doGetTextDropVoltRegular = (props:MsReactorModel, dStdCapacity:number, dStdVoltage:number, bUnit:boolean = true) =>
{
	let percentZ = doGetPerZrReactor(props, dStdCapacity);
	let sText = '';
	if (props.downTransKind == MS_TRANS1) {
		sText = doGetTextDropVoltRegularCalcPoint(props.calcPoint1, dStdVoltage, percentZ.perRk3, percentZ.perXk3, bUnit);
	}
	else {
		sText = doGetTextDropVoltRegularCalcPoint(props.calcPoint0, dStdVoltage, percentZ.perRk3, percentZ.perXk3, bUnit);
	}

	return sText;
}

//CEleReactor::DoGetTextDropPerRegular
export const doGetTextDropPerRegular = (props:MsReactorModel, dStdCapacity:number, dStdVoltage:number, bUnit:boolean =true) =>
{
	let percentZ = doGetPerZrReactor(props, dStdCapacity);
	let sText = '';
	if (props.downTransKind == MS_TRANS1) {
		sText = doGetTextDropPerRegularCalcPoint(props.calcPoint1, dStdVoltage, percentZ.perRk3, percentZ.perXk3, bUnit);
	}
	else {
		sText = doGetTextDropPerRegularCalcPoint(props.calcPoint0, dStdVoltage, percentZ.perRk3, percentZ.perXk3, bUnit);
	}

	return sText;
}

//CEleReactor::DoGetTextVoltage
export const doGetTextVoltage = (props:MsReactorModel, bUnit:boolean = true) =>
{
	let sText = props.voltage.toString();
	if (bUnit) {
		sText += 'V';
	}
	return sText;
}

//CEleReactor::IsPointFaultReactor
export const isPointFaultReactor = (props:MsReactorModel) =>
{
	if (props.fault) {
		return true;
	}
	return false;
}

//CEleReactor::DoGetTextLoadCurrent
export const doGetTextLoadCurrent = (props:MsReactorModel, bUnit:boolean = true) =>
{
	let sText = '';
	if (props.downTransKind == MS_TRANS1) {
		sText = BeamsNumber(props.calcPoint1.loadCurrent, 5).toString();
	}
	else {
		sText = BeamsNumber(props.calcPoint0.loadCurrent, 5).toString();
	}
	if (bUnit) {
		sText += 'A';
	}
	return sText;
}

//CEleReactor::DoGetTextDropVoltPF
export const doGetTextDropVoltPF = (props:MsReactorModel,  bUnit:boolean = true) =>
{
	let sText = '';
	if (props.downTransKind == MS_TRANS1) {
		sText = BeamsNumber(props.calcPoint1.powerFactor, 5).toString();
		if (bUnit) {
			if (props.calcPoint1.isLead) {
				sText+= '(進み)';
            }
        	else {
                sText += '(遅れ)';
            }
		}
	}
	else {
		sText = BeamsNumber(props.calcPoint0.powerFactor, 5).toString();
		if(bUnit) {
			if(props.calcPoint0.isLead) {
				sText+= '(進み)';
			}
			else {
				sText += '(遅れ)';
			}
		}
	}

	return sText;
}

//CEleReactor::DoGetTextDropVoltDiff
export const doGetTextDropVoltDiff = (props:MsReactorModel, dStdCapacity:number, dStdVoltage:number, bUnit:boolean = true) =>
{
	let percentZ = doGetPerZrReactor(props, dStdCapacity);
	let dVolt = 0;
	if (props.downTransKind == MS_TRANS1) {
		dVolt = doGetValueDropVoltRegularCalcPoint(dStdVoltage, percentZ.perRk3, percentZ.perXk3, props.calcPoint1);
	}
	else {
		dVolt = doGetValueDropVoltRegularCalcPoint(dStdVoltage, percentZ.perRk3, percentZ.perXk3, props.calcPoint0);
	}
	dVolt = dStdVoltage - dVolt;

	let sText = BeamsNumber(dVolt, 4).toString();
	if (bUnit) {
		sText += 'V';
	}
	return sText;
}

//CEleReactor::DoGetRefV1
export const doGetRefV1 = (mode:any, control:ControlModel, bSociety:boolean) =>
{
	let props = control.properties as MsReactorModel;
	let infoCircuit = store.getState().app.diagram.infoCircuit;
    let project = store.getState().app.projectData;

	//CEleReactor::DoGetRefV1Left sRefV1Left
	let labelTexts = [{
		value: control.properties.refNo,
		color: "#f800f8",
	}];

	if (!props.viewResultText || (mode != ProcessMode.DRAWING && !props.supply))
    {
      return labelTexts;
    }

	if (bSociety) {
		return labelTexts;
	}

    switch(mode) {
        case ProcessMode.DRAWING:
        case ProcessMode.CHECKING:
			//Capacity
			labelTexts.push({
				value: doGetTextCapacity(props),
				color: "blue",
			});

			//RatedCurrent
			labelTexts.push({
				value: doGetTextRatedCurrent(props),
				color: "blue",
			});

			if(props.voltage > 0) {
				//PercentXe
				labelTexts.push({
					value: doGetTextPercentXe(props),
					color: "blue",
				});
			}
			break;
        case ProcessMode.PERCENT_Z:
			//doGetTextPercentZr=doGetTextPercentRr +j doGetTextPercentXr
			labelTexts.push({
				value: doGetTextPercentZr(props, infoCircuit.stdCapacity)
				+ '='
				+ doGetTextPercentRr(props, infoCircuit.stdCapacity, false)
				+ '+j'
				+ doGetTextPercentXr(props, infoCircuit.stdCapacity, false),
				color: "blue",
			});
			break;
		case ProcessMode.CALCULATION:
			if(!isPointFaultReactor(props)) {
				break;
			}
			if(props.downTransKind == MS_TRANS1) {
				// += 'I"k2' + '通過' + '=' + doGetTextDirectIk3peak
                labelTexts.push({
                    value: 'I"k2通過='
                    + doGetTextDirectIk3peak(props),
                    color: "blue",
                });

                // += 'I"k2' + '通過' + '=' + doGetTextDirectIk3asym
                labelTexts.push({
                    value: 'I"k2通過='
                    + doGetTextDirectIk3asym(props),
                    color: "blue",
                });

                // += 'I"k2' + '通過' + '=' + doGetTextDirectIk3sym
                labelTexts.push({
                    value: 'I"k2通過='
                    + doGetTextDirectIk3sym(props),
                    color: "blue",
                });

                // += 'I"k2' + '合計' + '=' + doGetTextTotalIk3sym
                labelTexts.push({
                    value: 'I"k2合計='
                    + doGetTextTotalIk3sym(props),
                    color: "blue",
                });
			}
			else
			{
				 // += 'I"k3' + '通過' + '=' + doGetTextDirectIk3peak
				 labelTexts.push({
                    value: 'I"k3通過='
                    + doGetTextDirectIk3peak(props),
                    color: "blue",
                });

                // += 'I"k3' + '通過' + '=' + doGetTextDirectIk3asym
                labelTexts.push({
                    value: 'I"k3通過='
                    + doGetTextDirectIk3asym(props),
                    color: "blue",
                });

                // += 'I"k3' + '通過' + '=' + doGetTextDirectIk3sym
                labelTexts.push({
                    value: 'I"k3通過='
                    + doGetTextDirectIk3sym(props),
                    color: "blue",
                });

                // += 'I"k3' + '合計' + '=' + doGetTextTotalIk3sym
                labelTexts.push({
                    value: 'I"k3合計='
                    + doGetTextTotalIk3sym(props),
                    color: "blue",
                });
			}
			break;
		case ProcessMode.VOLTDROP:
			if (isCennectSourceDropElement(control, doGetLoadCurrentStartSide(control)) && isCennectLoadDropElement(control, doGetLoadCurrentEndSide(control)) && project.dropMode == MS_VOLTDROP_MOTOR && props.dropMotor && isSetDropPerMotor(props.calcPoint0)) {
                // Vd = DropVoltMotor(VoltSecondary - DropVoltMotor2)
                let sTemp = 'Vd=' + doGetTextDropVoltMotor(props) + ' (';
                if (props.supply ) {
                    sTemp += doGetTextVoltage(props, false);
                    sTemp += '-';
                    sTemp += doGetTextDropVoltMotor2(props, false);
                } else {
                    sTemp += '0-0'
                }
                sTemp += ')';
                labelTexts.push({
                    value: sTemp,
                    color: "blue",
                });

                //Vp = DoGetTextDropPerMotor (VoltSecondary → DropVoltMotor2)
                sTemp = 'Vp=' + doGetTextDropPerMotor(props) + ' (';
                if (props.supply) {
                    sTemp += doGetTextVoltage(props, false);
                    sTemp += '→';
                    sTemp += doGetTextDropVoltMotor2(props, false);
                } else {
                    sTemp += '0→0';
                }
                sTemp += ')';
                labelTexts.push({
                    value: sTemp,
                    color: "blue",
                });
                break;
            }

            if (project.dropMode == MS_VOLTDROP_REGULAR && props.dropRegular) {
                let stdVoltage = {
                    dStdVoltage : 0,
                    sStdVoltage : ''
                };
                if(props.supply) {
                    stdVoltage = doGetTextVoltDropRegular(control, MS_3PHASES, false);
                }

				//IL=DoGetTextLoadCurrent,力率=DoGetTextDropVoltPF
				labelTexts.push({
					value: 'IL='
					+ doGetTextLoadCurrent(props)
					+ ',力率='
					+ doGetTextDropVoltPF(props),
					color: "blue",
				});

				//Vd=DoGetTextDropVoltDiff (sStdVoltage-DoGetTextDropVoltRegular)
				labelTexts.push({
					value: 'Vd='
					+ doGetTextDropVoltDiff(props, infoCircuit.stdCapacity, props.voltage)
					+ ' ('
					+ stdVoltage.sStdVoltage
					+ '-'
					+ doGetTextDropVoltRegular(props, infoCircuit.stdCapacity, stdVoltage.dStdVoltage, false)
					+ ')',
					color: "blue",
				});

				//Vp=DoGetTextDropPerRegular (DoGetTextVoltage→doGetTextDropVoltRegular)
				let sTemp = 'Vp=';
				sTemp += doGetTextDropPerRegular(props, infoCircuit.stdCapacity, stdVoltage.dStdVoltage);
				sTemp += ' (';
				if(props.supply) {
					sTemp += doGetTextVoltage(props, false);
				} 
				else {
					sTemp += stdVoltage.sStdVoltage;
				}
				sTemp += '→';
				sTemp += doGetTextDropVoltRegular(props, infoCircuit.stdCapacity, stdVoltage.dStdVoltage, false);
				sTemp += ')';
				labelTexts.push({
					value: sTemp,
					color: "blue",
				});
                break;
            }
			break;
		case ProcessMode.HARMONIC:
			break;
		case ProcessMode.POWER_FLOW:
			break;
		default:
			break;
	}

	return labelTexts;
}

//CEleReactor::DoGetTextDirectPercentZr
export const doGetTextDirectPercentZr = (props:ControlModel, bUnit:boolean = true) =>
{
	let dPerZ = 0;
	if (props.properties.downTransKind == MS_TRANS1) {
		dPerZ = doGetPerZDirectMG(props.properties.calcPoint1).dPercentZ;
	}
	else {
		dPerZ = doGetPerZDirectMG(props.properties.calcPoint0).dPercentZ;
	}
	let sText = '';
	if (dPerZ >= 0) {
		sText = BeamsNumber(dPerZ, 4).toString();
		if (bUnit) {
			sText += '%r';
		}
	}
	else {							
		sText = '????';
	}
	return sText;
}

// CEleReactor::DoGetTextTotalIk3peak
export const doGetTextTotalIk3peak = (props:MsReactorModel, bUnit:boolean = true) =>
{
	let dIpeakTotal = 0;
	if (props.downTransKind == MS_TRANS1) {
		dIpeakTotal = doGetIkpeakTotalMG(props.calcPoint1);
	}
	else {
		dIpeakTotal = doGetIkpeakTotalMG(props.calcPoint0);
	}
	let sText = '';
	if (dIpeakTotal >= 0) {
		sText = BeamsNumber(dIpeakTotal / 1000, 4).toString();
		if (bUnit) {
			sText += 'kApeak';
		}
	}
	else {							// 短絡電流が正しく計算されなかったとき
		sText = '????';
	}
	return sText;
}

//CEleReactor::DoGetTextTotalIk3asym
export const doGetTextTotalIk3asym = (props:MsReactorModel, bUnit:boolean = true) =>
{
	let dIasymTotal = 0;
	if (props.downTransKind == MS_TRANS1) {
		dIasymTotal = doGetIkasymTotalMG(props.calcPoint1);
	}
	else {
		dIasymTotal = doGetIkasymTotalMG(props.calcPoint0);
	}
	let sText = '';
	if (dIasymTotal >= 0) {
		sText = BeamsNumber(dIasymTotal / 1000, 4).toString();
		if (bUnit) {
			sText += 'kAasym';
		}
	}
	else {							// 短絡電流が正しく計算されなかったとき
		sText = '????';
	}
	return sText;
}

//CEleReactor::DoGetTextTotalPercentZr
export const doGetTextTotalPercentZr = (props:MsReactorModel, bUnit:boolean = true) =>
{
	let dPerZ = 0;
	if (props.downTransKind == MS_TRANS1) {
		dPerZ = doGetPerZTotalMG(props.calcPoint1).dPercentZ;
	}
	else {
		dPerZ = doGetPerZTotalMG(props.calcPoint0).dPercentZ;
	}
	let sText = '';
	if (dPerZ >= 0) {
		sText = BeamsNumber(dPerZ, 4).toString();
		if (bUnit) {
			sText += '%r';
		}
	}
	else {							// 短絡電流が正しく計算されなかったとき
		sText = '????';
	}
	return sText;
}

//CEleReactor::DoGetTextActiveLeft
export const doGetTextActiveLeft = (props:MsReactorModel, nDirect:number, bUnit:boolean = true) =>
{
	let sText = '';
	if (nDirect == DIRECT_NONE) {
		if (props.lineActiveFlow1 >= 0) {
			sText = BeamsNumber(props.lineActiveFlow1, 5).toString();
		}
		else {
			sText = BeamsNumber(props.lineActiveFlow2, 5).toString();
		}
	}
	else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
		sText = BeamsNumber(Math.abs(props.lineActiveFlow1), 5).toString();
	}
	else {
		sText = BeamsNumber(Math.abs(props.lineActiveFlow2), 5).toString();
	}

	if (bUnit) {
		sText += 'kW';
	}

	return sText;
}

//CEleReactor::DoGetTextReactiveLeft
export const doGetTextReactiveLeft = (props:MsReactorModel, nDirect:number, bUnit:boolean = true) =>
{
	let sText = '';
	if (nDirect == DIRECT_NONE) {
		if (props.lineReactiveFlow1 >= 0) {
			sText = BeamsNumber(props.lineReactiveFlow1, 5).toString();
		}
		else {
			sText = BeamsNumber(props.lineReactiveFlow2, 5).toString();
		}
	}
	else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
		sText = BeamsNumber(Math.abs(props.lineReactiveFlow1), 5).toString();
	}
	else {
		sText = BeamsNumber(Math.abs(props.lineReactiveFlow2), 5).toString();
	}

	if (bUnit) {
		sText += 'kvar';
	}

	return sText;
}

//CEleReactor::DoGetTextFlowsResult
export const doGetTextFlowsResult = (props:MsReactorModel, processMode:any, nDirect:number, bSociety:boolean = false) =>
{
	let sRefV1:string[] = [];

	if (bSociety) {
		return sRefV1;
	}

	switch (processMode) {
	case MS_MODE_DRAWING:
	case MS_MODE_CHECKING:
	case MS_MODE_PERCENT_Z:
	case MS_MODE_CALCULATION:
	case MS_MODE_VOLTDROP:
	case MS_MODE_HARMONIC:
		break;
	case MS_MODE_POWER_FLOW:
		if (props.lineActiveFlow1 !==0 && props.lineActiveFlow2 !==0 && props.lineReactiveFlow1 !==0 && props.lineReactiveFlow2 !==0) {
			if (nDirect == DIRECT_NONE) {
				sRefV1.push(doGetTextActiveLeft(props,nDirect));
				sRefV1.push(doGetTextReactiveLeft(props,nDirect));
			}
			else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
                sRefV1.push(doGetTextActiveLeft(props,nDirect));
                let sTemp = ''
				if (props.lineActiveFlow1 > 0) {
					if (props.lineReactiveFlow1 > 0) {
						sTemp += "+j";
					}
					else {
						sTemp += "-j";
					}
				}
				else {
					if (props.lineReactiveFlow1 > 0) {
						sTemp += "-j";
					}
					else {
						sTemp += "+j";
					}
				}
				sRefV1.push(sTemp + doGetTextReactiveLeft(props,nDirect));
			}
			else {
                sRefV1.push(doGetTextActiveLeft(props,nDirect));
                let sTemp = ''
				if (props.lineActiveFlow2 > 0) {
					if (props.lineReactiveFlow2 > 0) {
						sTemp += "+j";
					}
					else {
						sTemp += "-j";
					}
				}
				else {
					if (props.lineReactiveFlow2 > 0) {
						sTemp += "-j";
					}
					else {
						sTemp += "+j";
					}
				}
				sRefV1.push(sTemp + doGetTextReactiveLeft(props,nDirect));
			}
		}
		break;
	}

	return sRefV1;
}