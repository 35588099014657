import { VOLT_SIDE_PRIMARY, VOLT_SIDE_SECONDARY, VOLT_SIDE_THIRD } from "../statics";

export const percentZDefault =() => {
    return {kind :0,
            x: 0,
            y: 0,
            xMax: 0,
            yMax: 0,
            fault: false,
            rotate: false,
            perRk3: 0,
            perXk3: 0,
            statorPerRk3: 0,
            fault2: false,
            perR2k3: 0,
            perX2k3: 0,
            fault3: false,
            perR3k3: 0,
            perX3k3: 0,
            fault1: false,
            perR1k3: 0,
            perX1k3: 0,
            perRk3Difference: 0,
            perXk3Difference: 0,
            perRk3Source: 0,
            perXk3Source: 0,
            calcFailed: false,
            circuitName: '',
            specialImpedance: 0,
            parallelFactor:0
    };
}

//CPercentZ::DoGetValuePerZk3
export const doGetValuePerZk3 = (percentZ:any) => {
    if (percentZ.calcFailed){
        return {dPercentZ: -1, pdPerRk3: -1, pdPerXk3: -1};
    }

    let dPercentZ = Math.sqrt(percentZ.perRk3 * percentZ.perRk3 + percentZ.perXk3 * percentZ.perXk3);
    return {dPercentZ: dPercentZ, pdPerRk3: percentZ.perRk3, pdPerXk3: percentZ.perXk3};
}

//CPercentZ::DoGetValuePerZk2OutIn
export const doGetValuePerZk2OutIn = (percentZ:any) => {
    if (percentZ.calcFailed) {
        return {
            dPercentZ: -1,
            pdPerRk3: -1,
            pdPerXk3: -1 
        };
    }
    let dPerRk3 = percentZ.perRk3 - percentZ.perRk3Difference;
    let dPerXk3 = percentZ.perXk3 - percentZ.perXk3Difference;

    let dPercentZ = Math.sqrt(dPerRk3 * dPerRk3 + dPerXk3 * dPerXk3);

    return {
        dPercentZ: dPercentZ,
        pdPerRk3: dPerRk3,
        pdPerXk3: dPerXk3
    };
}

//CPercentZ::DoGetValuePerZk2OutOut
export const doGetValuePerZk2OutOut = (percentZ:any) => {
    if (percentZ.calcFailed) {
        return {
            dPercentZ: -1,
            pdPerRk3: -1,
            pdPerXk3: -1 
        };
    }

    let dPercentZ = Math.sqrt(percentZ.perRk3 * percentZ.perRk3 + percentZ.perXk3 * percentZ.perXk3);

    return {
        dPercentZ: dPercentZ,
        pdPerRk3: percentZ.perRk3,
        pdPerXk3: percentZ.perXk3
    };
}

//CPercentZ::DoGetValuePerZk2SeatM
export const doGetValuePerZk2SeatM = (percentZ:any) => {
    if (percentZ.calcFailed) {
        return {
            dPercentZ: -1,
            pdPerRk3: -1,
            pdPerXk3: -1 
        };
    }

    let dPerRk3 = percentZ.perRk3Source + ((percentZ.perRk3 - percentZ.perRk3Source) - percentZ.perRk3Difference) / 2;
    let dPerXk3 = percentZ.perXk3Source + ((percentZ.perXk3 - percentZ.perXk3Source) - percentZ.perXk3Difference) / 2;
    
    let dPercentZ = Math.sqrt(dPerRk3 * dPerRk3 + dPerXk3 * dPerXk3);

    return {
        dPercentZ: dPercentZ,
        pdPerRk3: dPerRk3,
        pdPerXk3: dPerXk3
    };
}

//CPercentZ::DoGetValuePerZk2SeatT
export const doGetValuePerZk2SeatT = (percentZ:any) => {
    if (percentZ.calcFailed) {
        return {
            dPercentZ: -1,
            pdPerRk3: -1,
            pdPerXk3: -1 
        };
    }

    let dPerRk3 = percentZ.perRk3Source + (percentZ.perRk3 - percentZ.perRk3Source) + (percentZ.perRk3Source + ((percentZ.perRk3 - percentZ.perRk3Source) - percentZ.perRk3Difference) / 2) / 2;
    let dPerXk3 = percentZ.perXk3Source + (percentZ.perXk3 - percentZ.perXk3Source) + percentZ.perXk3Source / 2;
    
    let dPercentZ = Math.sqrt(dPerRk3 * dPerRk3 + dPerXk3 * dPerXk3);

    return {
        dPercentZ: dPercentZ,
        pdPerRk3: dPerRk3,
        pdPerXk3: dPerXk3
    };
}

//CPercentZ::DoGetValuePerZk33Winding
export const doGetValuePerZk33Winding = (percentZ: any, nVoltSide: number) =>
{
    if (percentZ.calcFailed) {
        return {
            dPercentZ: -1,
            pdPerRk3: -1,
            pdPerXk3: -1 
        };
    }

	let dPercentZ = 0;
    let pdPerRk3 = 0;
    let pdPerXk3 = 0;

	switch (nVoltSide) {
	case VOLT_SIDE_PRIMARY:
		dPercentZ = Math.sqrt(percentZ.perR1k3 * percentZ.perR1k3 + percentZ.perX1k3 * percentZ.perX1k3);
		if (pdPerRk3 != null) {
			pdPerRk3 = percentZ.perR1k3;
		}
		if (pdPerXk3 != null) {
			pdPerXk3 = percentZ.perX1k3;
		}
		break;
	case VOLT_SIDE_SECONDARY:
		dPercentZ = Math.sqrt(percentZ.perR2k3 * percentZ.perR2k3 + percentZ.perX2k3 * percentZ.perX2k3);
		if (pdPerRk3 != null) {
			pdPerRk3 = percentZ.perR2k3;
		}
		if (pdPerXk3 != null) {
			pdPerXk3 = percentZ.perX2k3;
		}
		break;
	case VOLT_SIDE_THIRD:
		dPercentZ = Math.sqrt(percentZ.perR3k3 * percentZ.perR3k3 + percentZ.perX3k3 * percentZ.perX3k3);
		if (pdPerRk3 != null) {
			pdPerRk3 = percentZ.perR3k3;
		}
		if (pdPerXk3 != null) {
			pdPerXk3 = percentZ.perX3k3;
		}
		break;
	}

    return {
        dPercentZ: dPercentZ,
        pdPerRk3: pdPerRk3,
        pdPerXk3: pdPerXk3
    };
}