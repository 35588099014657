import { store } from "../../..";
import { CdPoint, GraphLineData, MsTrans1Model, Point } from "../../../models/Index"
import { MS_MODE_CALCULATION } from "../../../statics";
import { BeamsNumber } from "../../../utils/FormatNumber";
import { doSetPosCurvePointDirect } from "../DCView";
import { doDrawInrushPart, doDrawIntensityPart } from "./createTrans1Part"

const doDrawCurveTrans1 = (props:MsTrans1Model, drawMode: number) => {
    // let m_bDispInrush = props.partOutIn.dispInrush;// 保護協調 - [外線‐中性線]
    // let m_bDispInrush2 = props.partOutOut.dispInrush;//保護協調 - [外線‐外線]

    // let m_bDispIntensity = props.partOutIn.dispIntensity; // 保護協調 - [外線‐中性線]
    // let m_bDispIntensity2 = props.partOutOut.dispIntensity;//保護協調 - [外線‐外線]
    const {
        voltageSecondary : m_lVoltSecondary,
        capacity : m_dCapacity,
        voltagePrimary : m_lVoltPrimary
    } = props
    
    let graphLineData : GraphLineData = { lines: [] }
    
    //============================== Outin ===========================
    const inrushPointsOutIn : Point[] = []
    const intensityPointsOutIn : Point[] = []
    const partOutIn = {
        m_listDrawInrush: [] as CdPoint[],
        m_listDrawIntensity : [] as CdPoint[]
    }

    let ratedCurrentOutIn : number = doGetValueRatedCurrent(1, m_lVoltSecondary, m_dCapacity, m_lVoltPrimary)
    ratedCurrentOutIn *= m_lVoltSecondary / 2;  // lVoltSecondary

    if(drawMode === 0 || drawMode === 1) {
        // Inrush
        //if(m_bDispInrush){
            if (doDrawInrushPart(ratedCurrentOutIn, props, partOutIn.m_listDrawInrush,1)) { 
                partOutIn.m_listDrawInrush.forEach(point => inrushPointsOutIn.push({x: point.m_dx, y: point.m_dy} as Point))
                graphLineData.lines.push({ points: inrushPointsOutIn, id: 'INRUSH_OUT_IN'})
            }
        //}

        // Intensity
        //if(m_bDispIntensity){
            if (doDrawIntensityPart(ratedCurrentOutIn, props, partOutIn.m_listDrawIntensity, m_dCapacity / 2,1)) { 
                partOutIn.m_listDrawIntensity.forEach(point => intensityPointsOutIn.push({x: point.m_dx, y: point.m_dy} as Point))
                graphLineData.lines.push({ points: intensityPointsOutIn, id: 'INTENSITY_OUT_IN'})
            }
        //}
    }

    let mode = store.getState().app.diagram.processMode;
    if(drawMode === 0 || drawMode === 2) {
          // get data draw calcpoint
        if (mode >= MS_MODE_CALCULATION && props.partOutIn.fault) {
            let temp = doSetPosCurvePointDirect(props.partOutIn.calcPoint, 0);
            if(temp !== undefined) {
                graphLineData.lines.push(temp);
            }

        }
    }

    //============================== Outout ===========================
    const inrushPointsOutOut : Point[] = []
    const intensityPointsOutOut : Point[] = []
    const partOutOut = {
        m_listDrawInrush: [] as CdPoint[],
        m_listDrawIntensity : [] as CdPoint[]
    }

    let ratedCurrentOutOut : number = doGetValueRatedCurrent(2, m_lVoltSecondary, m_dCapacity, m_lVoltPrimary)
    ratedCurrentOutOut *= m_lVoltSecondary;  // lVoltSecondary

    
    if(drawMode === 0 || drawMode === 1) {
         // Inrush
        //if(m_bDispInrush2){
            if (doDrawInrushPart(ratedCurrentOutOut, props, partOutOut.m_listDrawInrush,2)) { 
                partOutOut.m_listDrawInrush.forEach(point => inrushPointsOutOut.push({x: point.m_dx, y: point.m_dy} as Point))
                graphLineData.lines.push({ points: inrushPointsOutOut, id: 'INRUSH_OUT_OUT'})
            }
        //}
        // Intensity
        //if(m_bDispIntensity2){
            if (doDrawIntensityPart(ratedCurrentOutIn, props, partOutOut.m_listDrawIntensity, m_dCapacity / 2,2)) { 
                partOutOut.m_listDrawIntensity.forEach(point => intensityPointsOutOut.push({x: point.m_dx, y: point.m_dy} as Point))
                graphLineData.lines.push({ points: intensityPointsOutOut, id: 'INTENSITY_OUT_OUT'})
            }
        //}
    }

    if(drawMode === 0 || drawMode === 2) {
        // get data draw calcpoint
        if (mode >= MS_MODE_CALCULATION && props.partOutOut.fault) {
            let temp = doSetPosCurvePointDirect(props.partOutOut.calcPoint, 1);
            if(temp !== undefined) {
                graphLineData.lines.push(temp);
            }

        }
    }
    return graphLineData
}

// 容量と二次側電圧から定格電流を計算する
const doGetValueRatedCurrent = (nPartKind:number, dVoltSecondary:number, dCapacity:number, m_lVoltPrimary:number) => {
            let dRatedCurrent = 0;

            if (dVoltSecondary <= 0)
            {
                return 0;
            }
            else {
                switch (nPartKind)
                {
                    case 1:        // 外線－中性線間
                        dRatedCurrent = (dCapacity / 2) * 1000.0 / (dVoltSecondary / 2);
                        break;
                    case 2:        // 外線間
                        dRatedCurrent = dCapacity * 1000.0 / dVoltSecondary;
                        break;
                    case 0:                // 一次側
                        dRatedCurrent = m_lVoltPrimary;
                        dRatedCurrent = dCapacity * 1000.0 / dRatedCurrent;     // v2.6 √3を消した。
                        break;
                }
            }
            return dRatedCurrent;
}

// 保護協調図の参照文字列を取得する
const getMsTrans1InrushLabel = (properties : MsTrans1Model, infos : string[], dNominalVolt : number, nPartKind : number) => {
    infos.push(properties.refNo)
    switch (nPartKind) {
        case 1:        // 外線－中性線間
            {
                infos.push('外線‐中性線,' + properties.partOutIn.pointText.toString())
                infos.push(properties.capacity / 2 + `kVA`)
                if (properties.voltageSecondary / 2 === dNominalVolt)
                {
                    infos.push(
                        BeamsNumber(doGetValueRatedCurrent(nPartKind, properties.voltageSecondary, properties.capacity, properties.voltagePrimary), 4) 
                        + 'A'
                    )
                }
                else
                {
                    const dRatedCurrent = doGetValueRatedCurrent(nPartKind, properties.voltageSecondary, properties.capacity, properties.voltagePrimary)
                    infos.push(
                        BeamsNumber(dRatedCurrent * (properties.voltageSecondary / 2) / dNominalVolt, 4)
                        + 'A'
                        + '('
                        + BeamsNumber(doGetValueRatedCurrent(nPartKind, properties.voltageSecondary, properties.capacity, properties.voltagePrimary), 4)
                        + 'A'
                        + ')'
                    )
                }
                infos.push(properties.partOutIn.inrushTimes + `倍,` + properties.partOutIn.dampingTime + `ms`)
            }

            break;
        case 2:        // 外線間
            {
                infos.push('外線‐外線,' + properties.partOutOut.pointText) 
                infos.push(properties.capacity + `kVA`)
                if (properties.voltageSecondary === dNominalVolt)
                {
                    infos.push(
                        BeamsNumber(doGetValueRatedCurrent(nPartKind, properties.voltageSecondary, properties.capacity, properties.voltagePrimary), 4) 
                        + 'A'
                    )
                }
                else
                {
                    const dRatedCurrent = doGetValueRatedCurrent(nPartKind, properties.voltageSecondary, properties.capacity, properties.voltagePrimary)
                    infos.push(
                        BeamsNumber(dRatedCurrent * properties.voltageSecondary / dNominalVolt, 4)
                        + 'A'
                        + '('
                        + BeamsNumber(doGetValueRatedCurrent(nPartKind, properties.voltageSecondary, properties.capacity, properties.voltagePrimary), 4)
                        + 'A'
                        + ')'
                    )
                }
                infos.push(properties.partOutOut.inrushTimes + `倍,` + properties.partOutOut.dampingTime + `ms`)
            }
            break;
    }
}

 // 保護協調図の参照文字列を取得する
 const getMsTrans1IntensityLabel = (properties : MsTrans1Model, infos : string[], dNominalVolt : number, nPartKind : number) => {

    infos.push(properties.refNo)
    switch (nPartKind)
    {
        case 1:        // 外線－中性線間
            {
                infos.push('外線‐中性線,' + properties.partOutIn.pointText.toString())
                infos.push(properties.capacity / 2 + `kVA`)
                infos.push(properties.partOutIn.intensityTimes + `倍,`)
                if (properties.voltageSecondary / 2 === dNominalVolt)
                {
                    const dRatedCurrent = doGetValueRatedCurrent(nPartKind, properties.voltageSecondary, properties.capacity, properties.voltagePrimary)
                    const dIntensityValue = dRatedCurrent === 0 ? 0 : dRatedCurrent * properties.partOutIn.intensityTimes
                    infos.push(
                        BeamsNumber(dIntensityValue, 4) 
                        + 'Arms'
                    )
                }
                else
                {   
                    const dRatedCurrent = doGetValueRatedCurrent(nPartKind, properties.voltageSecondary, properties.capacity, properties.voltagePrimary)
                    const dIntensityValue = dRatedCurrent === 0 ? 0 : dRatedCurrent * properties.partOutIn.intensityTimes * (properties.voltageSecondary / 2) / dNominalVolt
                    infos.push(
                        BeamsNumber(dIntensityValue, 4) 
                        + 'Arms'
                        + '('
                        +  (dRatedCurrent === 0) ? '0' : dRatedCurrent * properties.partOutIn.intensityTimes
                        + 'Arms'
                        + ')'
                    )
                }
                infos.push(properties.partOutIn.intensityTime + `s`)
            }
            break;
        case 2:        // 外線間
            {
                infos.push('外線‐外線,' + properties.partOutOut.pointText.toString())
                infos.push(properties.capacity + `kVA`)
                infos.push(properties.partOutOut.intensityTimes + `倍,`)
                if (properties.voltageSecondary === dNominalVolt)
                {
                    const dRatedCurrent = doGetValueRatedCurrent(nPartKind, properties.voltageSecondary, properties.capacity, properties.voltagePrimary)
                    const dIntensityValue = dRatedCurrent === 0 ? 0 : dRatedCurrent * properties.partOutOut.intensityTimes
                    infos.push(
                        BeamsNumber(dIntensityValue, 4) 
                        + 'Arms'
                    )
                }
                else
                {   
                    const dRatedCurrent = doGetValueRatedCurrent(nPartKind, properties.voltageSecondary, properties.capacity, properties.voltagePrimary)
                    const dIntensityValue = dRatedCurrent === 0 ? 0 : dRatedCurrent * properties.partOutOut.intensityTimes * properties.voltageSecondary / dNominalVolt
                    infos.push(
                        BeamsNumber(dIntensityValue, 4) 
                        + 'Arms'
                        + '('
                        +  (dRatedCurrent === 0) ? '0' : dRatedCurrent * properties.partOutOut.intensityTimes
                        + 'Arms'
                        + ')'
                    )
                }
                infos.push(properties.partOutOut.intensityTime + `s`)
            }
            break;
    }
 }


export {
    doDrawCurveTrans1,
    getMsTrans1InrushLabel,
    getMsTrans1IntensityLabel
}