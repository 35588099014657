/* eslint-disable eqeqeq */
import { 
    ControlModel,
    MSTransCenterModel,
    MsCapacitorModel,
    MsLightBoardModel,
    MsLoadModel,
    MsMotorModel,
    MsPowerBoardModel,
    ProcessMode
} from '../models/Index'
import { 
    getTopControl,
    getBottomControl,
    getLeftControl,
    getRightControl,
} from './CollisionDetection'
import { DIRECT_NONE, LEFT_CENTER, MS_3PHASES, MS_KW_TO_HORSEPOWER, MS_OUTSIDE_NEUTRAL, MS_OUTSIDE_OUTSIDE, MS_PI, MS_SEAT_MAIN, MS_SEAT_T, MS_TRANS1, MS_TRANSSCOTT, POINTER_HEIGHT, POINTER_WIDTH, TOP_CENTER } from '../models/Constants'
import * as EKind from '../models/ElementKind'
import { BeamsNumber } from './FormatNumber'
import { store } from "..";
import { MS_MODE_CALCULATION, MS_MODE_CHECKING, MS_MODE_DRAWING, MS_MODE_HARMONIC, MS_MODE_PERCENT_Z, MS_MODE_POWER_FLOW, MS_MODE_VOLTDROP } from '../statics'
import { doGetIkasymDirectMG, doGetIkpeakDirectMG, doGetIksymDirectMG, doGetOhmZDirectMG, doGetPerZDirectMG } from './calcPointFunction'

interface ResultData {
    code: number,
    data: any,
    message?: string
}

// TODO: calc percent and ohm: TransCenter.cpp - line 3489

export const doGetValuesTransCenter = (
    transCenterData: MSTransCenterModel,
    diagramData: ControlModel[]
) => {

    let tmpCalc = doCalcCapacityANDPowerFactor(
        false,
        transCenterData,
        diagramData,
    )

    let togetherLoad = Number(tmpCalc.capacity) * Number(tmpCalc.powerFactor)

    let valueCurrent = doGetValueCurrent(transCenterData, diagramData)

    let demandFactor = 0
    let capacityXPowerFactor = Number(tmpCalc.capacity) * Number(tmpCalc.powerFactor)
    if (capacityXPowerFactor != 0)
        demandFactor = transCenterData.maxPower / capacityXPowerFactor
    
    let loadFactor = 0
    if (transCenterData.periodMaxPower != 0)
        loadFactor = transCenterData.periodAveragePower / transCenterData.periodMaxPower

    transCenterData.togetherLoad = BeamsNumber(togetherLoad, 5)
    transCenterData.powerFactor = BeamsNumber(Number(tmpCalc.powerFactor), 5)
    transCenterData.fullCurrent = BeamsNumber(Number(valueCurrent.fullCurrent), 4)
    transCenterData.phaseCurrent = BeamsNumber(Number(valueCurrent.phaseCurrent), 4)
    transCenterData.demandFactor = BeamsNumber(demandFactor, 5)
    transCenterData.loadFactor = BeamsNumber(loadFactor, 5)
    
    return {
        newProps: transCenterData,
        capacityXPowerFactor
    }
}

const doCalcCapacityANDPowerFactor = (
    isCalcLoadCurrent: boolean,
    transCenterData: MSTransCenterModel,
    diagramData: ControlModel[]
) => {
    if (!transCenterData.thinkOnOff){
        let result = calcRatedCapacityANDPowerFactor(isCalcLoadCurrent, diagramData)
        return result
    }
    let dS = 0
    let dPF = 0
    let isLead = false

    // TODO: check pObjIntoPoint.DoGetEleKind(): TransCenter.cpp: line 1920 - 2000
    let nextControl = getHeadControl(diagramData)
    while (nextControl.code === 0) {
        let control = nextControl.data
        diagramData = diagramData.filter((e) => e.id != control.id)
        if (control.type === EKind.MS_ZEROCOND){
            let res = calcCapacityANDPowerFactorZeroCond(
                isCalcLoadCurrent,
                transCenterData.thinkOnOff,
                control,
                diagramData
            )
            dS = res.dS
            dPF = res.dPF
            if (res.isLead) isLead = res.isLead
            break
        }
        if (control.type === EKind.MS_3WINDING){
            let res = calcCapacityANDPowerFactor3Winding(
                isCalcLoadCurrent,
                transCenterData.thinkOnOff,
                control,
                diagramData
            )
            dS = res.dS
            dPF = res.dPF
            if (res.isLead) isLead = res.isLead
            break
        }
        if (transCenterData.thinkOnOff && isSwitchOff(control)){
            break
        }
        if (control.type === EKind.MS_MOTOR){
            let tmpP = control.properties.capacity * control.properties.loadFactor
            if (isCalcLoadCurrent && control.properties.useEfficiency)
                tmpP /= control.properties.efficiency
            dPF = control.properties.powerFactor
            if (dPF != 0) dS = tmpP / dPF
            isLead = control.properties.leadlag == 0

            break
        }
        if (control.type === EKind.MS_LOAD){
            dS = control.properties.loadFactor *
                control.properties.loadCurrent * 
                control.properties.voltage
            
            // TODO: m_bSphase: transCenter.cpp: line 2044
            dS *= Math.sqrt(3.0) // **Replace this line with the line below
            // if (m_bSphase != true) dS *= Math.sqrt(3.0)

            dS /= 1000
            dPF = control.properties.powerFactor
            isLead = control.properties.leadOrLag == 0

            break
        }
        if (control.type === EKind.MS_LIGHTBOARD){
            let total = lightBoardGetValueTotalCapacity(control.properties)
            dS = Number(total.totalCapacity)
            dPF = Number(total.totalCapacityPF)
            if (dPF != 0) dS /= dPF
            else dS = 0
            // TODO: m_bIsLead: transCenter.cpp: line 2064
            // isLead = control.properties.m_bIsLead

            break
        }
        if (control.type === EKind.MS_POWERBOARD){
            let total = powerBoardGetValueTotalCapacity(control.properties, false)
            dS = Number(total.totalCapacity)
            dPF = Number(total.totalCapacityPF)
            if (dPF != 0) dS /= dPF
            else dS = 0
            // TODO: m_bIsLead: transCenter.cpp: line 2064
            // isLead = control.properties.m_bIsLead

            break
        }
        if (control.type === EKind.MS_CAPACITOR){
            dS = control.properties.capacity * control.properties.loadFactor
            
            dPF = control.properties.powerFactor
            if (dPF != 1) dS /= Math.sqrt(1 - dPF * dPF)
            
            // TODO: m_bIsLead: transCenter.cpp: line 2089
            isLead = true // **Replace this line with the line below
            // isLead = control.properties.m_bIsLead

            break
        }

        nextControl = getBottomControl(control, diagramData)
    }
    return {
        capacity: dS,
        powerFactor: dPF,
        isLead: isLead
    }
}

const doGetValueCurrent = (
    transCenterData: MSTransCenterModel,
    diagramData: ControlModel[]
) => {
    let fullCurrent = 0
    let phaseCurrent = 0

    let tmpCalc = doCalcCapacityANDPowerFactor(
        true,
        transCenterData,
        diagramData
    )

    if (transCenterData.voltage > 0){
        let capacity = Number(tmpCalc.capacity) * 1000
        fullCurrent = capacity / transCenterData.voltage
        // TODO: m_bPhase: transCenter.cpp: line 2628
        fullCurrent /= Math.sqrt(3) // **Replace this line with the line below
        // if (!transCenterData.m_bPhase) fullCurrent /= Math.sqrt(3)
    }

    if (Number(tmpCalc.powerFactor) >= -1 && Number(tmpCalc.powerFactor) <= 1){
        phaseCurrent = Math.acos(Number(tmpCalc.powerFactor)) * 180 / MS_PI
        // TODO: m_bIsLead: transCenter.cpp: line 2665
        phaseCurrent = -phaseCurrent
        // phaseCurrent = (transCenterData.m_bIsLead ? "+" : "-") + phaseCurrent
    }

    return {fullCurrent, phaseCurrent}
}

const calcRatedCapacityANDPowerFactor = (
    isCalcLoadCurrent: boolean,
    diagramData: ControlModel[]
) => {
    let dS = 0
    let dP = 0
    let dQ = 0

    let tmpS = 0
    let tmpP = 0
    let tmpQ = 0
    let tmpPF = 0

    diagramData?.forEach((control) => {
        if (control.type === EKind.MS_MOTOR){
            // TODO: check this again when done
            let props: MsMotorModel = control.properties
            tmpP = props.capacity * props.loadFactor

            if (isCalcLoadCurrent && props.useEfficiency)
                tmpP /= props.efficiency
            
            tmpPF = props.powerFactor
            if (tmpPF !== 0){
                dP += Number(tmpP)
                dQ += Number(tmpP) * 
                    Math.sqrt(1 - Number(tmpPF) * Number(tmpPF)) / 
                    Number(tmpPF)
            } else {
                dQ += Number(tmpP)
            }
        } else if (control.type === EKind.MS_LOAD){
            let props: MsLoadModel = control.properties
            tmpS = props.loadFactor * props.loadCurrent * props.voltage

            // TODO: m_bSphase: transCenter.cpp: line 1821
            tmpS *= Math.sqrt(3.0) // **Replace this line with the line below
            // if (m_bSphase != true) tmpS *= Math.sqrt(3.0)

            tmpPF = props.powerFactor
            tmpP = tmpS * tmpPF / 1000
            tmpQ = tmpS * Math.sqrt(1.0 - tmpPF * tmpPF) / 1000
            if (props.leadOrLag == 0) tmpQ = -tmpQ

            dP += Number(tmpP)
            dQ += Number(tmpQ)
        } else if (control.type === EKind.MS_CAPACITOR){
            let props: MsCapacitorModel = control.properties
            tmpS = props.loadFactor * props.capacity
            tmpPF = props.powerFactor
            if (tmpPF !== 1) tmpS /= Math.sqrt(1.0 - tmpPF * tmpPF)
            tmpP = tmpS * tmpPF
            tmpQ = -tmpS * Math.sqrt(1.0 - tmpPF * tmpPF)
            // TODO: m_bIsLead: transCenter.cpp: line 1845
            // if (!props.m_bIsLead) tmpQ = -tmpQ
            dP += Number(tmpP)
            dQ += Number(tmpQ)
        } else if (control.type === EKind.MS_LIGHTBOARD){
            let props: MsLightBoardModel = control.properties
            let total = lightBoardGetValueTotalCapacity(props)
            tmpP = Number(total.totalCapacity)
            tmpPF = Number(total.totalCapacityPF)
            if (tmpPF !== 0) {
                tmpQ = tmpP * Math.sqrt(1.0 - tmpPF * tmpPF) / tmpPF
            } else {
                tmpP = 0
                tmpQ = 0
            }
            dP += Number(tmpP)
            dQ += Number(tmpQ)
        } else if (control.type === EKind.MS_POWERBOARD){
            let props: MsPowerBoardModel = control.properties
            let tmpIsLead = false
            let total = powerBoardGetValueTotalCapacity(props, tmpIsLead)
            tmpP = Number(total.totalCapacity)
            tmpPF = Number(total.totalCapacityPF)
            tmpIsLead = total.isLead || false
            if (tmpPF !== 0){
                tmpQ = tmpP * Math.sqrt(1.0 - tmpPF * tmpPF) / tmpPF
            } else {
                tmpP = 0
                tmpQ = 0
            }
            if (tmpIsLead) tmpQ = -tmpQ
            
            dP += Number(tmpP)
            dQ += Number(tmpQ)
        }
    })
    
    dS = Math.sqrt(dP * dP + dQ * dQ)
    let capacity = dS
    let powerFactor = 0
    if (dS !== 0) powerFactor = dP/dS
    let isLead = true
    if (dQ >= 0) isLead = false

    return {
        capacity,
        powerFactor,
        isLead
    }
}

const calcCapacityANDPowerFactor3Winding = (
    isCalcLoadCurrent: boolean,
    thinkOnOff: boolean,
    control3Winding: ControlModel,
    diagramData: ControlModel[]
) => {
    diagramData = diagramData.filter((e) => e.id != control3Winding.id)
    let dP = 0
    let dQ = 0

    let tmpS = 0
    let tmpPF = 0
    let tmpIsLead = false

    let topControl = getTopControl(control3Winding, diagramData)
    while (topControl.code === 0) {
        let control: ControlModel = topControl.data
        diagramData = diagramData.filter((e) => e.id != control.id)

        if (thinkOnOff && isSwitchOff(control))
            break
        if (control.type === EKind.MS_ZEROCOND){
            let res = calcCapacityANDPowerFactorZeroCond(
                isCalcLoadCurrent,
                thinkOnOff,
                control,
                diagramData
            )
            tmpS = res.dS
            tmpPF = res.dPF
            if (res.isLead) tmpIsLead = res.isLead

            dP += Number(tmpS) * Number(tmpPF)
            if (tmpIsLead != true)
                dQ += Number(tmpS) * Math.sqrt(1 - Number(tmpPF) * Number(tmpPF))
            else
                dQ -= Number(tmpS) * Math.sqrt(1 - Number(tmpPF) * Number(tmpPF))

            break
        }

        topControl = getTopControl(control, diagramData)
    }
    
    let leftControl = getLeftControl(control3Winding, diagramData)
    while (leftControl.code === 0) {
        let control: ControlModel = leftControl.data
        diagramData = diagramData.filter((e) => e.id != control.id)
        
        if (thinkOnOff && isSwitchOff(control))
            break
        if ([EKind.MS_ZEROCOND, EKind.MS_3WINDING].includes(control.type)){
            let res
            if (control.type === EKind.MS_ZEROCOND) 
                res = calcCapacityANDPowerFactorZeroCond(
                    isCalcLoadCurrent,
                    thinkOnOff,
                    control,
                    diagramData
                )
            else 
                res = calcCapacityANDPowerFactor3Winding(
                    isCalcLoadCurrent,
                    thinkOnOff,
                    control,
                    diagramData
                )
            tmpS = res.dS
            tmpPF = res.dPF
            if (res.isLead) tmpIsLead = res.isLead

            dP += Number(tmpS) * Number(tmpPF)
            if (tmpIsLead != true)
                dQ += Number(tmpS) * Math.sqrt(1 - Number(tmpPF) * Number(tmpPF))
            else
                dQ -= Number(tmpS) * Math.sqrt(1 - Number(tmpPF) * Number(tmpPF))

            break
        }
        
        leftControl = getLeftControl(control, diagramData)
    }
    
    let rightControl = getRightControl(control3Winding, diagramData)
    while (rightControl.code === 0) {
        let control: ControlModel = rightControl.data
        diagramData = diagramData.filter((e) => e.id != control.id)
        
        if (thinkOnOff && isSwitchOff(control))
            break
        if ([EKind.MS_ZEROCOND, EKind.MS_3WINDING].includes(control.type)){
            let res
            if (control.type === EKind.MS_ZEROCOND) 
                res = calcCapacityANDPowerFactorZeroCond(
                    isCalcLoadCurrent,
                    thinkOnOff,
                    control,
                    diagramData
                )
            else 
                res = calcCapacityANDPowerFactor3Winding(
                    isCalcLoadCurrent,
                    thinkOnOff,
                    control,
                    diagramData
                )
            tmpS = res.dS
            tmpPF = res.dPF
            if (res.isLead) tmpIsLead = res.isLead

            dP += Number(tmpS) * Number(tmpPF)
            if (tmpIsLead != true)
                dQ += Number(tmpS) * Math.sqrt(1 - Number(tmpPF) * Number(tmpPF))
            else
                dQ -= Number(tmpS) * Math.sqrt(1 - Number(tmpPF) * Number(tmpPF))

            break
        }
        
        rightControl = getRightControl(control, diagramData)
    }

    tmpS = Math.sqrt(dP * dP + dQ * dQ)
    
    let dS = tmpS
    let dPF = 0
    let isLead

    if (tmpS != 0){
        dPF = dP / tmpS
        isLead = dQ < 0
    }
    
    return {
        dS,
        dPF,
        isLead
    }
}

const calcCapacityANDPowerFactorZeroCond = (
    isCalcLoadCurrent: boolean,
    thinkOnOff: boolean,
    controlZeroCond: ControlModel,
    diagramData: ControlModel[]
) => {
    diagramData = diagramData.filter((e) => e.id != controlZeroCond.id)
    let dP = 0
    let dQ = 0

    let tmpS = 0
    let tmpP = 0
    let tmpQ = 0
    let tmpPF = 0
    let tmpIsLead = false

    while (true) {
        let topControl = getTopControl(controlZeroCond, diagramData)
        if (topControl.code !== 0) break

        let control: ControlModel = topControl.data
        diagramData = diagramData.filter((e) => e.id != control.id)

        if (thinkOnOff && isSwitchOff(control)) continue
        
        let topControl2 = getTopControl(control, diagramData)
        while (topControl2.code === 0) {
            let control2: ControlModel = topControl2.data
            diagramData = diagramData.filter((e) => e.id != control2.id)
    
            if (thinkOnOff && isSwitchOff(control2)) break
            if (control2.type === EKind.MS_ZEROCOND){
                let res = calcCapacityANDPowerFactorZeroCond(
                    isCalcLoadCurrent,
                    thinkOnOff,
                    control2,
                    diagramData
                )
                tmpS = res.dS
                tmpPF = res.dPF
                if (res.isLead) tmpIsLead = res.isLead
    
                dP += Number(tmpS) * Number(tmpPF)
                if (tmpIsLead != true)
                    dQ += Number(tmpS) * Math.sqrt(1 - Number(tmpPF) * Number(tmpPF))
                else
                    dQ -= Number(tmpS) * Math.sqrt(1 - Number(tmpPF) * Number(tmpPF))
    
                break
            }
    
            topControl2 = getTopControl(control2, diagramData)
        }
    }

    while (true) {
        let bottomControl = getBottomControl(controlZeroCond, diagramData)
        if (bottomControl.code !== 0) break

        let control: ControlModel = bottomControl.data
        diagramData = diagramData.filter((e) => e.id != control.id)

        if (thinkOnOff && isSwitchOff(control)) continue
        if (control.type === EKind.MS_3WINDING) {
            let res = calcCapacityANDPowerFactor3Winding(
                isCalcLoadCurrent,
                thinkOnOff,
                control,
                diagramData
            )
            tmpS = res.dS
            tmpPF = res.dPF
            if (res.isLead) tmpIsLead = res.isLead

            dP += Number(tmpS) * Number(tmpPF)
            if (tmpIsLead != true)
                dQ += Number(tmpS) * Math.sqrt(1 - Number(tmpPF) * Number(tmpPF))
            else
                dQ -= Number(tmpS) * Math.sqrt(1 - Number(tmpPF) * Number(tmpPF))

            continue
        } else if (control.type === EKind.MS_MOTOR){
            let tmpP = control.properties.capacity * control.properties.loadFactor
            if (isCalcLoadCurrent && control.properties.useEfficiency)
                tmpP /= control.properties.efficiency
            tmpPF = control.properties.powerFactor
            if (tmpPF != 0){
                tmpQ = Number(tmpP) * (Math.sqrt(1 - Number(tmpPF) * Number(tmpPF)) / Number(tmpPF))
                if (control.properties.leadlag == 0)
                    tmpQ = -1 * Number(tmpQ)
            } else {
                tmpQ = 0
                tmpP = 0
            }

            dP += Number(tmpP)
            dQ += Number(tmpQ)
            continue
        } else if (control.type === EKind.MS_LOAD){
            tmpS = control.properties.loadFactor *
                control.properties.loadCurrent * 
                control.properties.voltage
            
            // TODO: m_bSphase: transCenter.cpp: line 2192
            tmpS *= Math.sqrt(3.0) // **Replace this line with the line below
            // if (m_bSphase != true) dS *= Math.sqrt(3.0)

            tmpPF = control.properties.powerFactor
            tmpP = tmpS * tmpPF / 1000
            tmpQ = tmpS * Math.sqrt(1 - tmpPF * tmpPF) / 1000
            if (control.properties.leadOrLag == 0)
                tmpQ = -1 * Number(tmpQ)
            
            dP += Number(tmpP)
            dQ += Number(tmpQ)
            continue
        } else if (control.type === EKind.MS_CAPACITOR){
            tmpS = control.properties.capacity * control.properties.loadFactor
            tmpPF = control.properties.powerFactor
            if (tmpPF != 1)
                tmpS /= Math.sqrt(1 - Number(tmpPF) * Number(tmpPF))
            tmpP = tmpS * tmpPF
            tmpQ = -Number(tmpS) * Math.sqrt(1 - Number(tmpPF) * Number(tmpPF))
            
            // TODO: m_bIsLead: transCenter.cpp: line 2217
            // if (!control.properties.m_bIsLead) tmpQ = -tmpQ
            
            dP += Number(tmpP)
            dQ += Number(tmpQ)
            continue
        } else if (control.type === EKind.MS_LIGHTBOARD){
            let total = lightBoardGetValueTotalCapacity(control.properties)
            tmpP = Number(total.totalCapacity)
            tmpPF = Number(total.totalCapacityPF)

            if (tmpPF != 0) 
                tmpQ = Number(tmpP) * Math.sqrt(1 - Number(tmpPF) * Number(tmpPF)) / Number(tmpPF)
            else {
                tmpP = 0
                tmpQ = 0
            }

            dP += Number(tmpP)
            dQ += Number(tmpQ)
            continue
        } else if (control.type === EKind.MS_POWERBOARD){
            let tmpIsLead = false
            let total = powerBoardGetValueTotalCapacity(control.properties, tmpIsLead)
            tmpP = Number(total.totalCapacity)
            tmpPF = Number(total.totalCapacityPF)
            tmpIsLead = total.isLead || false
            if (tmpPF != 0) 
                tmpQ = Number(tmpP) * Math.sqrt(1 - Number(tmpPF) * Number(tmpPF)) / Number(tmpPF)
            else {
                tmpP = 0
                tmpQ = 0
            }
            
            if (tmpIsLead) tmpQ = -1 * Number(tmpQ)
            dP += Number(tmpP)
            dQ += Number(tmpQ)
            continue
        } else {
            let bottomControl2 = getBottomControl(control, diagramData)
            while (bottomControl2.code === 0) {
                let control2: ControlModel = bottomControl2.data
                diagramData = diagramData.filter((e) => e.id != control2.id)
                
                if (thinkOnOff && isSwitchOff(control2)) break
                if (control2.type === EKind.MS_ZEROCOND){
                    let res = calcCapacityANDPowerFactorZeroCond(
                        isCalcLoadCurrent,
                        thinkOnOff,
                        control2,
                        diagramData
                    )
                    tmpS = res.dS
                    tmpPF = res.dPF
                    if (res.isLead) tmpIsLead = res.isLead
        
                    dP += Number(tmpS) * Number(tmpPF)
                    if (tmpIsLead != true)
                        dQ += Number(tmpS) * Math.sqrt(1 - Number(tmpPF) * Number(tmpPF))
                    else
                        dQ -= Number(tmpS) * Math.sqrt(1 - Number(tmpPF) * Number(tmpPF))
        
                    break
                }
                if (control2.type === EKind.MS_3WINDING) {
                    let res = calcCapacityANDPowerFactor3Winding(
                        isCalcLoadCurrent,
                        thinkOnOff,
                        control2,
                        diagramData
                    )
                    tmpS = res.dS
                    tmpPF = res.dPF
                    if (res.isLead) tmpIsLead = res.isLead
        
                    dP += Number(tmpS) * Number(tmpPF)
                    if (tmpIsLead != true)
                        dQ += Number(tmpS) * Math.sqrt(1 - Number(tmpPF) * Number(tmpPF))
                    else
                        dQ -= Number(tmpS) * Math.sqrt(1 - Number(tmpPF) * Number(tmpPF))
        
                    break
                }
                if (control2.type === EKind.MS_MOTOR){
                    let tmpP = control2.properties.capacity * control2.properties.loadFactor
                    if (isCalcLoadCurrent && control2.properties.useEfficiency)
                        tmpP /= control2.properties.efficiency
                    tmpPF = control2.properties.powerFactor
                    if (tmpPF != 0){
                        tmpQ = Number(tmpP) * (Math.sqrt(1 - Number(tmpPF) * Number(tmpPF)) / Number(tmpPF))
                        if (control2.properties.leadlag == 0)
                            tmpQ = -1 * Number(tmpQ)
                    } else {
                        tmpQ = 0
                        tmpP = 0
                    }
        
                    dP += Number(tmpP)
                    dQ += Number(tmpQ)
                    break
                }
                if (control2.type === EKind.MS_LOAD){
                    tmpS = control2.properties.loadFactor *
                        control2.properties.loadCurrent * 
                        control2.properties.voltage
                    
                    // TODO: m_bSphase: transCenter.cpp: line 2324
                    tmpS *= Math.sqrt(3.0) // **Replace this line with the line below
                    // if (m_bSphase != true) dS *= Math.sqrt(3.0)
        
                    tmpPF = control2.properties.powerFactor
                    tmpP = tmpS * tmpPF / 1000
                    tmpQ = tmpS * Math.sqrt(1 - tmpPF * tmpPF) / 1000
                    if (control2.properties.leadOrLag == 0)
                        tmpQ = -1 * Number(tmpQ)
                    
                    dP += Number(tmpP)
                    dQ += Number(tmpQ)
                    break
                }
                if (control2.type === EKind.MS_LIGHTBOARD){
                    let total = lightBoardGetValueTotalCapacity(control2.properties)
                    tmpP = Number(total.totalCapacity)
                    tmpPF = Number(total.totalCapacityPF)
        
                    if (tmpPF != 0) 
                        tmpQ = Number(tmpP) * Math.sqrt(1 - Number(tmpPF) * Number(tmpPF)) / Number(tmpPF)
                    else {
                        tmpP = 0
                        tmpQ = 0
                    }
        
                    dP += Number(tmpP)
                    dQ += Number(tmpQ)
                    break
                }
                if (control2.type === EKind.MS_POWERBOARD){
                    let tmpIsLead = false
                    let total = powerBoardGetValueTotalCapacity(control2.properties, tmpIsLead)
                    tmpP = Number(total.totalCapacity)
                    tmpPF = Number(total.totalCapacityPF)
                    tmpIsLead = total.isLead || false
                    if (tmpPF != 0) 
                        tmpQ = Number(tmpP) * Math.sqrt(1 - Number(tmpPF) * Number(tmpPF)) / Number(tmpPF)
                    else {
                        tmpP = 0
                        tmpQ = 0
                    }
                    
                    if (tmpIsLead) tmpQ = -1 * Number(tmpQ)
                    dP += Number(tmpP)
                    dQ += Number(tmpQ)
                    break
                }
                if (control2.type === EKind.MS_CAPACITOR){
                    tmpS = control2.properties.capacity * control2.properties.loadFactor
                    tmpPF = control2.properties.powerFactor
                    if (tmpPF != 1)
                        tmpS /= Math.sqrt(1 - Number(tmpPF) * Number(tmpPF))
                    tmpP = tmpS * tmpPF
                    tmpQ = -Number(tmpS) * Math.sqrt(1 - Number(tmpPF) * Number(tmpPF))
                    
                    // TODO: m_bIsLead: transCenter.cpp: line 2387
                    // if (!control2.properties.m_bIsLead) tmpQ = -tmpQ
                    
                    dP += Number(tmpP)
                    dQ += Number(tmpQ)
                    break
                }
        
                bottomControl2 = getBottomControl(control2, diagramData)
            }
        }
    }

    let leftControl = getLeftControl(controlZeroCond, diagramData)
    while (leftControl.code === 0) {
        let control: ControlModel = leftControl.data
        diagramData = diagramData.filter((e) => e.id != control.id)

        if (thinkOnOff && isSwitchOff(control)) break
        if (control.type === EKind.MS_ZEROCOND){
            let res = calcCapacityANDPowerFactorZeroCond(
                isCalcLoadCurrent,
                thinkOnOff,
                control,
                diagramData
            )
            tmpS = res.dS
            tmpPF = res.dPF
            if (res.isLead) tmpIsLead = res.isLead

            dP += Number(tmpS) * Number(tmpPF)
            if (tmpIsLead != true)
                dQ += Number(tmpS) * Math.sqrt(1 - Number(tmpPF) * Number(tmpPF))
            else
                dQ -= Number(tmpS) * Math.sqrt(1 - Number(tmpPF) * Number(tmpPF))

            break
        }
        if (control.type === EKind.MS_3WINDING) {
            let res = calcCapacityANDPowerFactor3Winding(
                isCalcLoadCurrent,
                thinkOnOff,
                control,
                diagramData
            )
            tmpS = res.dS
            tmpPF = res.dPF
            if (res.isLead) tmpIsLead = res.isLead

            dP += Number(tmpS) * Number(tmpPF)
            if (tmpIsLead != true)
                dQ += Number(tmpS) * Math.sqrt(1 - Number(tmpPF) * Number(tmpPF))
            else
                dQ -= Number(tmpS) * Math.sqrt(1 - Number(tmpPF) * Number(tmpPF))

            break
        }
        leftControl = getLeftControl(control, diagramData)
    }

    let rightControl = getRightControl(controlZeroCond, diagramData)
    while (rightControl.code === 0) {
        let control: ControlModel = rightControl.data
        diagramData = diagramData.filter((e) => e.id != control.id)

        if (thinkOnOff && isSwitchOff(control)) break
        if (control.type === EKind.MS_ZEROCOND){
            let res = calcCapacityANDPowerFactorZeroCond(
                isCalcLoadCurrent,
                thinkOnOff,
                control,
                diagramData
            )
            tmpS = res.dS
            tmpPF = res.dPF
            if (res.isLead) tmpIsLead = res.isLead

            dP += Number(tmpS) * Number(tmpPF)
            if (tmpIsLead != true)
                dQ += Number(tmpS) * Math.sqrt(1 - Number(tmpPF) * Number(tmpPF))
            else
                dQ -= Number(tmpS) * Math.sqrt(1 - Number(tmpPF) * Number(tmpPF))

            break
        }
        if (control.type === EKind.MS_3WINDING) {
            let res = calcCapacityANDPowerFactor3Winding(
                isCalcLoadCurrent,
                thinkOnOff,
                control,
                diagramData
            )
            tmpS = res.dS
            tmpPF = res.dPF
            if (res.isLead) tmpIsLead = res.isLead

            dP += Number(tmpS) * Number(tmpPF)
            if (tmpIsLead != true)
                dQ += Number(tmpS) * Math.sqrt(1 - Number(tmpPF) * Number(tmpPF))
            else
                dQ -= Number(tmpS) * Math.sqrt(1 - Number(tmpPF) * Number(tmpPF))

            break
        }
        rightControl = getRightControl(control, diagramData)
    }
    
    tmpS = Math.sqrt(dP * dP + dQ * dQ)
    
    let dS = tmpS
    let dPF = 0
    let isLead

    if (tmpS != 0){
        dPF = dP / tmpS
        isLead = dQ < 0
    }
    
    return {
        dS,
        dPF,
        isLead
    }
}

const lightBoardGetValueTotalCapacity = (props: MsLightBoardModel) => {
    let totalCapacity = 0
    let totalCapacityPF = 0
    let dP = 0
    let dQ = 0

    if (props.lightPF != 0){
        if (props.lightUnit == 'kW'){
            dP = props.light
            dQ = props.light * Math.sqrt(1 - props.lightPF * props.lightPF) /
                props.lightPF
        } else if (props.lightUnit == 'kVA'){
            dP = props.light * props.lightPF
            dQ = props.light * Math.sqrt(1 - props.lightPF * props.lightPF)
        } else if (props.lightUnit == 'HP'){
            dP = props.light / MS_KW_TO_HORSEPOWER
            dQ = (props.light / MS_KW_TO_HORSEPOWER) *
                Math.sqrt(1 - props.lightPF * props.lightPF) / 
                props.lightPF
        }
    } else {
        if (props.lightUnit == 'kW' || props.lightUnit == 'kVA'){
            dP = 0
            dQ = props.light
        } else if (props.lightUnit == 'HP'){
            dP = 0
            dQ = props.light / MS_KW_TO_HORSEPOWER
        }
    }

    if (props.soketPF != 0){
        if (props.soketUnit == 'kW'){
            dP += props.soket
            dQ += props.soket * Math.sqrt(1 - props.soketPF * props.soketPF) /
                props.soketPF
        } else if (props.soketUnit == 'kVA'){
            dP += props.soket * props.soketPF
            dQ += props.soket * Math.sqrt(1 - props.soketPF * props.soketPF)
        } else if (props.soketUnit == 'HP'){
            dP += props.soket / MS_KW_TO_HORSEPOWER
            dQ += (props.soket / MS_KW_TO_HORSEPOWER) *
                Math.sqrt(1 - props.soketPF * props.soketPF) / 
                props.soketPF
        }
    } else {
        if (props.soketUnit == 'kW' || props.soketUnit == 'kVA'){
            dQ += props.soket
        } else if (props.soketUnit == 'HP'){
            dQ += props.soket / MS_KW_TO_HORSEPOWER
        }
    }

    if (props.FCUPF != 0){
        if (props.FCUUnit == 'kW'){
            dP += props.FCU
            dQ += props.FCU * Math.sqrt(1 - props.FCUPF * props.FCUPF) /
                props.FCUPF
        } else if (props.FCUUnit == 'kVA'){
            dP += props.FCU * props.FCUPF
            dQ += props.FCU * Math.sqrt(1 - props.FCUPF * props.FCUPF)
        } else if (props.FCUUnit == 'HP'){
            dP += props.FCU / MS_KW_TO_HORSEPOWER
            dQ += (props.FCU / MS_KW_TO_HORSEPOWER) *
                Math.sqrt(1 - props.FCUPF * props.FCUPF) / 
                props.FCUPF
        }
    } else {
        if (props.FCUUnit == 'kW' || props.FCUUnit == 'kVA'){
            dQ += props.FCU
        } else if (props.FCUUnit == 'HP'){
            dQ += props.FCU / MS_KW_TO_HORSEPOWER
        }
    }

    if (props.otherPF != 0){
        if (props.otherUnit == 'kW'){
            dP += props.other
            dQ += props.other * Math.sqrt(1 - props.otherPF * props.otherPF) /
                props.otherPF
        } else if (props.otherUnit == 'kVA'){
            dP += props.other * props.otherPF
            dQ += props.other * Math.sqrt(1 - props.otherPF * props.otherPF)
        } else if (props.otherUnit == 'HP'){
            dP += props.other / MS_KW_TO_HORSEPOWER
            dQ += (props.other / MS_KW_TO_HORSEPOWER) *
                Math.sqrt(1 - props.otherPF * props.otherPF) / 
                props.otherPF
        }
    } else {
        if (props.otherUnit == 'kW' || props.otherUnit == 'kVA'){
            dQ += props.other
        } else if (props.otherUnit == 'HP'){
            dQ += props.other / MS_KW_TO_HORSEPOWER
        }
    }

    totalCapacity = Math.sqrt(dP * dP + dQ * dQ)
    if (totalCapacity != 0) 
        totalCapacityPF = dP / totalCapacity
    
    if (props.totalCapacityUnit == 'kW')
        totalCapacity = dP
    else if (props.totalCapacityUnit == 'HP')
        totalCapacity = dP * MS_KW_TO_HORSEPOWER

    if (props.inputDetail != true){
        totalCapacity = props.totalCapacity
        totalCapacityPF = props.powerFactor
    }

    return {totalCapacity, totalCapacityPF}
}

const powerBoardGetValueTotalCapacity = (
    props: MsPowerBoardModel,
    isLead?: boolean,
) => {
    let totalCapacity = 0
    let totalCapacityPF = 0
    let dP = 0
    let dQ = 0

    if (props.freezerPF != 0){
        if (props.freezerUnit == 'kW'){
            dP = props.freezer
            dQ = props.freezer * Math.sqrt(1 - props.freezerPF * props.freezerPF) /
                props.freezerPF
        } else if (props.freezerUnit == 'kVA'){
            dP = props.freezer * props.freezerPF
            dQ = props.freezer * Math.sqrt(1 - props.freezerPF * props.freezerPF)
        } else if (props.freezerUnit == 'HP'){
            dP = props.freezer / MS_KW_TO_HORSEPOWER
            dQ = (props.freezer / MS_KW_TO_HORSEPOWER) *
                Math.sqrt(1 - props.freezerPF * props.freezerPF) / 
                props.freezerPF
        }
    } else {
        if (props.freezerUnit == 'kW' || props.freezerUnit == 'kVA'){
            dP = 0
            dQ = props.freezer
        } else if (props.freezerUnit == 'HP'){
            dP = 0
            dQ = props.freezer / MS_KW_TO_HORSEPOWER
        }
    }

    if (props.elevatorPF != 0){
        if (props.elevatorUnit == 'kW'){
            dP += props.elevator
            dQ += props.elevator * Math.sqrt(1 - props.elevatorPF * props.elevatorPF) /
                props.elevatorPF
        } else if (props.elevatorUnit == 'kVA'){
            dP += props.elevator * props.elevatorPF
            dQ += props.elevator * Math.sqrt(1 - props.elevatorPF * props.elevatorPF)
        } else if (props.elevatorUnit == 'HP'){
            dP += props.elevator / MS_KW_TO_HORSEPOWER
            dQ += (props.elevator / MS_KW_TO_HORSEPOWER) *
                Math.sqrt(1 - props.elevatorPF * props.elevatorPF) / 
                props.elevatorPF
        }
    } else {
        if (props.elevatorUnit == 'kW' || props.elevatorUnit == 'kVA'){
            dQ += props.elevator
        } else if (props.elevatorUnit == 'HP'){
            dQ += props.elevator / MS_KW_TO_HORSEPOWER
        }
    }
    
    if (props.airconPF != 0){
        if (props.airconUnit == 'kW'){
            dP += props.aircon
            dQ += props.aircon * Math.sqrt(1 - props.airconPF * props.airconPF) /
                props.airconPF
        } else if (props.airconUnit == 'kVA'){
            dP += props.aircon * props.airconPF
            dQ += props.aircon * Math.sqrt(1 - props.airconPF * props.airconPF)
        } else if (props.airconUnit == 'HP'){
            dP += props.aircon / MS_KW_TO_HORSEPOWER
            dQ += (props.aircon / MS_KW_TO_HORSEPOWER) *
                Math.sqrt(1 - props.airconPF * props.airconPF) / 
                props.airconPF
        }
    } else {
        if (props.airconUnit == 'kW' || props.airconUnit == 'kVA'){
            dQ += props.aircon
        } else if (props.airconUnit == 'HP'){
            dQ += props.aircon / MS_KW_TO_HORSEPOWER
        }
    }
    
    if (props.hygienePF != 0){
        if (props.hygieneUnit == 'kW'){
            dP += props.hygiene
            dQ += props.hygiene * Math.sqrt(1 - props.hygienePF * props.hygienePF) /
                props.hygienePF
        } else if (props.hygieneUnit == 'kVA'){
            dP += props.hygiene * props.hygienePF
            dQ += props.hygiene * Math.sqrt(1 - props.hygienePF * props.hygienePF)
        } else if (props.hygieneUnit == 'HP'){
            dP += props.hygiene / MS_KW_TO_HORSEPOWER
            dQ += (props.hygiene / MS_KW_TO_HORSEPOWER) *
                Math.sqrt(1 - props.hygienePF * props.hygienePF) / 
                props.hygienePF
        }
    } else {
        if (props.hygieneUnit == 'kW' || props.hygieneUnit == 'kVA'){
            dQ += props.hygiene
        } else if (props.hygieneUnit == 'HP'){
            dQ += props.hygiene / MS_KW_TO_HORSEPOWER
        }
    }

    if (props.capacitorPF != 0){
        if (props.capacitorUnit == 'kW'){
            dP += props.capacitor
            dQ -= props.capacitor * Math.sqrt(1 - props.capacitorPF * props.capacitorPF) /
                props.capacitorPF
        } else if (props.capacitorUnit == 'kVA'){
            dP += props.capacitor * props.capacitorPF
            dQ -= props.capacitor * Math.sqrt(1 - props.capacitorPF * props.capacitorPF)
        } else if (props.capacitorUnit == 'HP'){
            dP += props.capacitor / MS_KW_TO_HORSEPOWER
            dQ -= (props.capacitor / MS_KW_TO_HORSEPOWER) *
                Math.sqrt(1 - props.capacitorPF * props.capacitorPF) / 
                props.capacitorPF
        }
    } else {
        if (props.capacitorUnit == 'kW' || props.capacitorUnit == 'kVA'){
            dQ -= props.capacitor
        } else if (props.capacitorUnit == 'HP'){
            dQ -= props.capacitor / MS_KW_TO_HORSEPOWER
        }
    }
    
    if (props.otherPF != 0){
        if (props.otherUnit == 'kW'){
            dP += props.other
            dQ += props.other * Math.sqrt(1 - props.otherPF * props.otherPF) /
                props.otherPF
        } else if (props.otherUnit == 'kVA'){
            dP += props.other * props.otherPF
            dQ += props.other * Math.sqrt(1 - props.otherPF * props.otherPF)
        } else if (props.otherUnit == 'HP'){
            dP += props.other / MS_KW_TO_HORSEPOWER
            dQ += (props.other / MS_KW_TO_HORSEPOWER) *
                Math.sqrt(1 - props.otherPF * props.otherPF) / 
                props.otherPF
        }
    } else {
        if (props.otherUnit == 'kW' || props.otherUnit == 'kVA'){
            dQ += props.other
        } else if (props.otherUnit == 'HP'){
            dQ += props.other / MS_KW_TO_HORSEPOWER
        }
    }
    
    totalCapacity = Math.sqrt(dP * dP + dQ * dQ)
    if (totalCapacity != 0) 
        totalCapacityPF = dP / totalCapacity
    
    if (props.totalCapacityUnit == 'kW')
        totalCapacity = dP
    else if (props.totalCapacityUnit == 'HP')
        totalCapacity = dP * MS_KW_TO_HORSEPOWER

    if (isLead != null){
        isLead = dQ < 0
    }

    if (props.inputDetail != true){
        totalCapacity = props.totalCapacity
        totalCapacityPF = props.powerFactor
        // TODO: m_bIsLead: powerboard.cpp: line 1350
        // if (isLead != null){
        //     isLead = props.m_bIsLead
        // }
    }

    return {totalCapacity, totalCapacityPF, isLead}
}

export const getHeadControl = (diagramData: ControlModel[]) => {
    let res
    if (diagramData.length > 1) {
   
        let intoPoint: ControlModel = diagramData.find((e) => e.type === EKind.MS_INTO_POINT)!
      
        diagramData.forEach((e: ControlModel) => {
            e = roundPosition(e)
            if (
                e.x === intoPoint.x &&
                e.y === intoPoint.y
            )
                res = e
            else if (e.type === EKind.MS_ZEROCOND) {
                if (
                    intoPoint.x >= e.x &&
                    intoPoint.x <= 
                        e.x + e.height - POINTER_WIDTH &&
                        intoPoint.y === e.y
                )
                    res = e
            }
        })
    }
    if (res)
        return { code: 0, data: res } as ResultData
    return { code: 1, message: 'Not found' } as ResultData
}

const isSwitchOff = (control: ControlModel) => {
    const canSwitchOnOffArray = [
        EKind.MS_HVCB,
        EKind.MS_MVCB,
        EKind.MS_CONTACTOR,
        EKind.MS_LVCB,
        EKind.MS_FUSE,
        EKind.MS_2E,
        EKind.MS_THERMAL,
        EKind.MS_DS,
        EKind.MS_LBS,
        EKind.MS_THERMAL_CT
    ]

    let isOff = false
    if (canSwitchOnOffArray.includes(control.type))
        isOff = control.properties.off

    return isOff
}

const roundPosition = (control: ControlModel) => {
    control.x = roundFunc(control.x)
    control.y = roundFunc(control.y)
    return control
}

const roundFunc = (num: number) => {
    return Math.round(num / POINTER_HEIGHT) * POINTER_HEIGHT
}

//CEleTransCenter::DoGetValueFullCurrent
export const doGetValueFullCurrent = (props:MSTransCenterModel, diagramData: ControlModel[]) =>
{
	let dFullCurrent;

	if (props.voltage <= 0) {
		dFullCurrent = 0;
	}
	else {
		let dOldCapacity = props.capacity;
		let doldPowerFactor = props.powerFactor;
		let bOldIsLead = props.leadLag;

		doCalcCapacityANDPowerFactor(true, props, diagramData);
		let dCapacity = props.capacity * 1000;
		if (props.powerFactor == 0) {
			dFullCurrent = dCapacity / props.voltage;
			if (!props.sPhase) {
				dFullCurrent /= Math.sqrt(3.0);
			}
		}
		else {
			dFullCurrent = dCapacity / props.voltage;// / m_dPowerFactor;
			if (!props.sPhase) {
				dFullCurrent /= Math.sqrt(3.0);
			}
		}
		
		props.capacity = dOldCapacity;
		props.powerFactor = doldPowerFactor;
		props.leadLag = bOldIsLead;
	}
	return dFullCurrent;
}

//CEleTransCenter::DoGetTextFullCurrent
export const doGetTextFullCurrent = (props:MSTransCenterModel, diagramData: ControlModel[], bUnit:boolean = true, bViewZero:boolean = true) =>
{
	let sText = '';
	let dFullCurrent = doGetValueFullCurrent(props, diagramData);
	if (!bViewZero && dFullCurrent == 0) {
		return sText;
	}
	sText = BeamsNumber(dFullCurrent, 4).toString();
	if (bUnit) {
		sText += 'A';
	}
	return sText;
}

//CEleTransCenter::IsPointFaultTransCenter
export const isPointFaultTransCenter = (props:MSTransCenterModel) =>
{
	if (props.fault) {
		return true;
	}
	return false;
}

//CEleTransCenter::DoGetTextTotalRatedCapacity
export const doGetTextTotalRatedCapacity = (props:MSTransCenterModel, diagramData: ControlModel[], bUnit:boolean = true, bViewZero:boolean = true) =>
{
	let sText = '';
	
	doCalcCapacityANDPowerFactor(false, props, diagramData);

	if (!bViewZero && (props.capacity == 0 || props.powerFactor == 0)) {
		return sText;
	}

	sText = BeamsNumber(props.capacity * props.powerFactor, 5).toString();
	if (bUnit) {
		sText += 'kW';
	}

	return sText;
}

//CEleTransCenter::DoGetTextPointState
export const doGetTextPointState = (props:MSTransCenterModel) =>
{
	let sText = ''; 

	if (props.fault) {
		sText = 'Yes';
	}
	else {
		sText = 'No';
	}

	return sText;
}

//CEleTransCenter::DoGetTextDirectIkpeak
export const doGetTextDirectIkpeak = (props:MSTransCenterModel, nOpeMode:number, nPartKind:number, bUnit:boolean = true, bViewZero:boolean = true) =>
{
	let sText = '';
	if (nOpeMode < MS_MODE_PERCENT_Z) {
		sText = '未計算';
		return sText;
	}

	let dIpeakDirect = 0;
	if (props.sPhase) {		
		switch (nPartKind) {
		case MS_OUTSIDE_NEUTRAL:	
		case MS_SEAT_MAIN:			
			dIpeakDirect = doGetIkpeakDirectMG(props.calcPoint1);
			break;
		case MS_OUTSIDE_OUTSIDE:	
		case MS_SEAT_T:				
			dIpeakDirect = doGetIkpeakDirectMG(props.calcPoint0);
			break;
		}
	}
	else {							
		dIpeakDirect = doGetIkpeakDirectMG(props.calcPoint0);
	}

	if (!bViewZero) {
		return sText;
	}
	if (dIpeakDirect == 0) {
		if (props.primarySupply) {
			sText = '∞';
		}
		else {
			sText = '0';
		}
	}
	else if (dIpeakDirect > 0) {
		sText = BeamsNumber(dIpeakDirect / 1000, 4).toString();
		if (bUnit) {
			sText += 'kApeak';
		}
	}
	else {							
		sText = '????';
	}
	return sText;
}

//CEleTransCenter::DoGetTextDirectIkasym
export const doGetTextDirectIkasym = (props:MSTransCenterModel, nOpeMode:number, nPartKind:number, bUnit:boolean = true, bViewZero:boolean = true) =>
{
	let sText = '';
	if (nOpeMode < MS_MODE_PERCENT_Z) {
		sText = '未計算';
		return sText;
	}

	let dIasymDirect = 0;
	if (props.sPhase) {		
		switch (nPartKind) {
		case MS_OUTSIDE_NEUTRAL:	
		case MS_SEAT_MAIN:			
			dIasymDirect = doGetIkasymDirectMG(props.calcPoint1);
			break;
		case MS_OUTSIDE_OUTSIDE:	
		case MS_SEAT_T:			
			dIasymDirect = doGetIkasymDirectMG(props.calcPoint0);
			break;
		}
	}
	else {							
		dIasymDirect = doGetIkasymDirectMG(props.calcPoint0);
	}

	if (!bViewZero) {
		return sText;
	}
	if (dIasymDirect == 0) {
		if (props.primarySupply) {
			sText = '∞';
		}
		else {
			sText = '0';
		}
	}
	else if (dIasymDirect > 0) {
		sText = BeamsNumber(dIasymDirect / 1000, 4).toString();
		if (bUnit) {
			sText += 'kAasym';
		}
	}
	else {							
		sText = '????';
	}
	return sText;
}

//CEleTransCenter::DoGetTextDirectIksym
export const doGetTextDirectIksym = (props:MSTransCenterModel, nOpeMode:number, nPartKind:number, bUnit:boolean = true, bViewZero:boolean = true) =>
{
	let sText = '';
	if (nOpeMode < MS_MODE_PERCENT_Z) {
		sText = '未計算';
		return sText;
	}

	let dIsymDirect = 0;
	if (props.sPhase) {		
		switch (nPartKind) {
		case MS_OUTSIDE_NEUTRAL:	
		case MS_SEAT_MAIN:			
			dIsymDirect = doGetIksymDirectMG(props.calcPoint1);
			break;
		case MS_OUTSIDE_OUTSIDE:	
		case MS_SEAT_T:				
			dIsymDirect = doGetIksymDirectMG(props.calcPoint0);
			break;
		}
	}
	else {							
		dIsymDirect = doGetIksymDirectMG(props.calcPoint0);
	}

	if (!bViewZero) {
		return sText;
	}
	if (dIsymDirect == 0) {
		if (props.primarySupply) {
            sText = '∞';
		}
		else {
			sText = '0';
		}
	}
	else if (dIsymDirect > 0) {
		sText = BeamsNumber(dIsymDirect / 1000, 4).toString();
		if (bUnit) {
			sText += 'kAsym';
		}
	}
	else {							
		sText = '????';
	}
	return sText;
}

//CEleTransCenter::DoGetTextPowerFactor
export const doGetTextPowerFactor = (props:MSTransCenterModel, bUnit:boolean = true, bViewZero:boolean = true) =>
{
	let sText = '';
	if (!bViewZero && props.powerFactor == 0) {
		return sText;
	}

	sText = BeamsNumber(props.powerFactor, 5).toString();
	if (bUnit && props.powerFactor > 0) {
		if (props.leadLag != 1) {  //TODO check leadLag = isLead?
			sText += '(遅れ)';
		}
		else
		{
			sText += '(進み)';
            
		}
	}
	return sText;
}

//CEleTransCenter::DoGetTextVoltage
export const doGetTextVoltage = (props:MSTransCenterModel, nPartKind:number, bUnit:boolean = true, bViewZero:boolean = true) =>
{
	let sText = '';
	if (props.sPhase) {
		switch (nPartKind) {
		case MS_OUTSIDE_NEUTRAL:	
			sText = (props.voltage / 2).toString();
			break;
		case MS_OUTSIDE_OUTSIDE:	
			sText = props.voltage.toString();
			break;
		case MS_SEAT_MAIN:			
			sText = props.voltage.toString();
			break;
		case MS_SEAT_T:				
			sText = props.voltage.toString();
			break;
		}
	}
	else {
		sText = props.voltage.toString();
	}
	if (!bViewZero && sText == '') {
		return sText;
	}
	if (bUnit) {
		sText += 'V';
	}
	return sText;
}

//TODO check diagram
//CEleTransCenter::DoGetRefV1
export const doGetRefV1 = (mode:any, control:ControlModel, bSociety:boolean) =>
{
    let props = control.properties as MSTransCenterModel;
    let infoCircuit = store.getState().app.diagram.infoCircuit;
    let diagram = (store.getState().app.diagram.diagramData.filter((e: any) => e.tabId === props.tabId)[0])?.shape;
	//CEleTransCenter::DoGetRefV1Left  sRefV1Left
	let labelTexts = [{
		value: control.properties.refNo,
		color: "#f800f8",
	}];

	if (!props.viewResultText || (mode != ProcessMode.DRAWING && !props.supply))
    {
      return labelTexts;
    }

    if(bSociety) {
        return labelTexts;
    }

    switch(mode) {
        case ProcessMode.DRAWING:
        case ProcessMode.CHECKING:
            if(doGetValueFullCurrent(props, diagram) != 0) {
                //TotalRatedCapacity
                labelTexts.push({
                    value: doGetTextTotalRatedCapacity(props, diagram),
                    color: "blue",
                });

                //FullCurrent
                labelTexts.push({
                    value: doGetTextFullCurrent(props, diagram),
                    color: "blue",
                });
            }
            break;
        case ProcessMode.PERCENT_Z:
            break;
        case ProcessMode.CALCULATION:
            if(!isPointFaultTransCenter(props)) {
                break;
            }
            if(props.sPhase) {
                switch(props.upTransKind) {
                    case MS_TRANS1:
                         //OUTSIDE_OUTSIDE
                         labelTexts.push({
                            value: '外線-外線',
                            color: "blue",
                        });

                        // += 'I"k2' + '=' + DoGetTextDirectIkpeak()
                        labelTexts.push({
                            value: 'I"k2='
                            + doGetTextDirectIkpeak(props, mode, MS_OUTSIDE_OUTSIDE),
                            color: "blue",
                        });

                        // += 'I"k2' + '=' + DoGetTextDirectIkasym()
                        labelTexts.push({
                            value: 'I"k2='
                            + doGetTextDirectIkasym(props, mode, MS_OUTSIDE_OUTSIDE),
                            color: "blue",
                        });

                         // += 'I"k2' + '=' + DoGetTextDirectIksym()
                        labelTexts.push({
                            value: 'I"k2='
                            + doGetTextDirectIksym(props, mode, MS_OUTSIDE_OUTSIDE),
                            color: "blue",
                        });
                        break;
                    case MS_TRANSSCOTT:
                          //SEAT_MAIN
                          labelTexts.push({
                            value: '主座',
                            color: "blue",
                        });

                        // += 'I"k2' + '=' + DoGetTextDirectIkpeak()
                        labelTexts.push({
                            value: 'I"k2='
                            + doGetTextDirectIkpeak(props, mode, MS_SEAT_MAIN),
                            color: "blue",
                        });

                        // += 'I"k2' + '=' + DoGetTextDirectIkasym()
                        labelTexts.push({
                            value: 'I"k2='
                            + doGetTextDirectIkasym(props, mode, MS_SEAT_MAIN),
                            color: "blue",
                        });

                         // += 'I"k2' + '=' + DoGetTextDirectIksym()
                        labelTexts.push({
                            value: 'I"k2='
                            + doGetTextDirectIksym(props, mode, MS_SEAT_MAIN),
                            color: "blue",
                        });
                        break;
                }
            }
            else {
                 // += 'I"k3' + '=' + DoGetTextDirectIkpeak()
                 labelTexts.push({
                    value: 'I"k3='
                    + doGetTextDirectIkpeak(props, mode, MS_3PHASES),
                    color: "blue",
                });

                // += 'I"k3' + '=' + DoGetTextDirectIkasym()
                labelTexts.push({
                    value: 'I"k3='
                    + doGetTextDirectIkasym(props, mode, MS_3PHASES),
                    color: "blue",
                });

                // += 'I"k3' + '=' + DoGetTextDirectIksym()
                labelTexts.push({
                    value: 'I"k3='
                    + doGetTextDirectIksym(props, mode, MS_3PHASES),
                    color: "blue",
                });
            }
            break;
        case ProcessMode.VOLTDROP:
            if(doGetValueFullCurrent(props, diagram) != 0) {
                //FullCurrent
                labelTexts.push({
                    value: doGetTextFullCurrent(props, diagram),
                    color: "blue",
                });

                //力率=doGetTextPowerFactor()
                labelTexts.push({
                    value: '力率='
                    + doGetTextPowerFactor(props),
                    color: "blue",
                });
            }
            break;
        case ProcessMode.IMPROVE_PF:
            if(doGetValueFullCurrent(props, diagram) != 0) {
                //力率=doGetTextPowerFactor()
                labelTexts.push({
                    value: '力率='
                    + doGetTextPowerFactor(props),
                    color: "blue",
                });
            }
            break;
        case ProcessMode.HARMONIC:
            break;
        case ProcessMode.POWER_FLOW:
            break;
        default:
            break;
    }

    return labelTexts
}

//CEleTransCenter::DoGetTextDirectPercentZr
export const doGetTextDirectPercentZr = (props:ControlModel, processMode:any, nPartKind:number, bUnit:boolean = true, bViewZero:boolean = true) =>
{
	let sText = "";
	if (processMode < MS_MODE_PERCENT_Z) {
		sText = '未計算';
		return sText;
	}

	let dPerZ = 0;
	if (props.properties.sPhase) {		// 単相回路のとき
		switch (nPartKind) {
		case MS_OUTSIDE_NEUTRAL:	// 外線－中性線間
		case MS_SEAT_MAIN:			// 主座変圧器
			dPerZ = doGetPerZDirectMG(props.properties.calcPoint1).dPercentZ;
			break;
		case MS_OUTSIDE_OUTSIDE:	// 外線間
		case MS_SEAT_T:				// Ｔ座変圧器
			dPerZ = doGetPerZDirectMG(props.properties.calcPoint0).dPercentZ;
			break;
		}
	}
	else {							// 三相回路のとき
		dPerZ = doGetPerZDirectMG(props.properties.calcPoint0).dPercentZ;
	}

	if (bViewZero && dPerZ == 0) {
		return sText;
	}
	if (dPerZ >= 0) {
		sText = BeamsNumber(dPerZ, 4).toString();
		if (bUnit == true) {
			sText += '%r';
		}
	}
	else {							// 短絡電流が正しく計算されなかったとき
		sText = '????';
	}
	return sText;
}

//CEleTransCenter::DoGetTextFlowsResult
export const doGetTextFlowsResult = (props:MSTransCenterModel, processMode:any, nDirect:number, bSociety:boolean = false) =>
{
	let sRefV1:string[] = [];

	if (bSociety) {
		return sRefV1;
	}

	switch (processMode) {
	case MS_MODE_DRAWING:
	case MS_MODE_CHECKING:
	case MS_MODE_PERCENT_Z:
	case MS_MODE_CALCULATION:
	case MS_MODE_VOLTDROP:
	case MS_MODE_HARMONIC:
		break;
	case MS_MODE_POWER_FLOW:
		if (!props.sPhase && props.activePower !== 0 && props.reactivePower !== 0) {
			if (nDirect == DIRECT_NONE) {
				sRefV1.push(BeamsNumber(props.activePower,5).toString() + 'kW');
				sRefV1.push(BeamsNumber(props.reactivePower,5).toString() + 'kvar');
			}
			else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
				sRefV1.push(BeamsNumber(props.activePower,5).toString() + 'kW');
                let sTemp = ''
				if (props.reactivePower > 0) {
					sTemp += "+j";
				}
				else {
					sTemp += "-j";
				}
				sRefV1.push(sTemp + BeamsNumber(props.reactivePower,5).toString() + 'kvar');
			}
		}
		break;
	}

	return sRefV1;
}
// 通過％Ｒ抵抗分の文字列を取得する
export const doGetTextDirectPercentRr = (element:any,opeMode:number,partKind:number,bUnit:boolean = true,viewZero:boolean = true) => {
	let sText = "";
    if(opeMode < MS_MODE_PERCENT_Z){
        sText = '未計算';
		return sText;
    }
    let dPerR = 0;
    if(element.properties.sPhase == true){  // 単相回路のとき
        switch(partKind){
            case MS_OUTSIDE_NEUTRAL:        // 外線－中性線間
            case MS_SEAT_MAIN:              // 主座変圧器
                dPerR = doGetPerZDirectMG(element.properties.calcPoint1).pdPerRk3;
			    break;
            case MS_OUTSIDE_OUTSIDE:        // 外線間
            case MS_SEAT_T:                 // Ｔ座変圧器
                dPerR = doGetPerZDirectMG(element.properties.calcPoint0).pdPerRk3;
                break;
        }
    }
    else{                                   // 三相回路のとき
        dPerR = doGetPerZDirectMG(element.properties.calcPoint0).pdPerRk3;
    }

    if(!viewZero && dPerR == 0){
        return sText;
    }
    if(dPerR >= 0){
        sText = BeamsNumber(dPerR,4).toString();
        if(bUnit){
            sText += '%r';
        }
    }else{          // 短絡電流が正しく計算されなかったとき
        sText = '????';
    }
    return sText;
}

// 通過％Ｘリアクタンス分の文字列を取得する
export const doGetTextDirectPercentXr = (element:any,opeMode:number,partKind:number,bUnit:boolean = true,bViewZero:boolean = true) => {
    let sText = "";
    if(opeMode < MS_MODE_PERCENT_Z){
        sText = '未計算';
		return sText;
    }

    let dPerX = 0;
    if(element.properties.sPhase == true){
        switch(partKind){               // 単相回路のとき
            case MS_OUTSIDE_NEUTRAL:    // 外線－中性線間
            case MS_SEAT_MAIN:          // 主座変圧器
                dPerX = doGetPerZDirectMG(element.properties.calcPoint1).pdPerXk3;
                break;
            case MS_OUTSIDE_OUTSIDE:    // 外線間
            case MS_SEAT_T:             // Ｔ座変圧器
                dPerX = doGetPerZDirectMG(element.properties.calcPoint0).pdPerXk3;
                break;
        }
    }else{
        dPerX = doGetPerZDirectMG(element.properties.calcPoint0).pdPerXk3;
    }

    if(bViewZero != true && dPerX == 0){
        return sText;
    }

    if(dPerX >= 0){
        sText = BeamsNumber(dPerX,4).toString();
        if(bUnit == true){
            sText += '%r';
        }
    }else{          // 短絡電流が正しく計算されなかったとき
        sText = '????';
    }
    return sText;
}

// 通過インピーダンスＸ／Ｒの文字列を取得する
export const doGetTextDirectXR = (element:any,opeMode:number,nPartKind:number,bViewZero:boolean = true) => {
    let sText = "";
    if(opeMode < MS_MODE_PERCENT_Z){
        sText = '未計算';
		return sText;
    }
    let dOhmR = 0;
    let data;
    if(element.properties.sPhase == true){  // 単相回路のとき
        switch(nPartKind){              
            case MS_OUTSIDE_NEUTRAL:        // 外線－中性線間
            case MS_SEAT_MAIN:              // 主座変圧器
                data = doGetOhmZDirectMG(element.properties.calcPoint1,dOhmR,null);
                if(data && data.pdOhmR){
                    dOhmR = data.pdOhmR;
                }
                break;
            case MS_OUTSIDE_OUTSIDE:        // 外線間
            case MS_SEAT_T:                 // Ｔ座変圧器
                data = doGetOhmZDirectMG(element.properties.calcPoint0,dOhmR,null);
                if(data && data.pdOhmR){
                    dOhmR = data.pdOhmR;
                }
                break;
        }
    }else{                                  // 三相回路のとき
        data = doGetOhmZDirectMG(element.properties.calcPoint0,dOhmR,null);
        if(data && data.pdOhmR){
            dOhmR = data.pdOhmR;
        }
    }

    let dOhmX = 0;
    if(element.properties.sPhase == true){  // 単相回路のとき
        switch(nPartKind){
            case MS_OUTSIDE_NEUTRAL:        // 外線－中性線間
            case MS_SEAT_MAIN:              // 主座変圧器
                data = doGetOhmZDirectMG(element.properties.calcPoint1,null,dOhmX);
                if(data && data.pdOhmX){
                    dOhmX = data.pdOhmX;
                }
                break;
            case MS_OUTSIDE_OUTSIDE:        // 外線間
            case MS_SEAT_T:                 // Ｔ座変圧器
                data = doGetOhmZDirectMG(element.properties.calcPoint0,null,dOhmX);
                if(data && data.pdOhmX){
                    dOhmX = data.pdOhmX;
                }
                break;
        }
    }else{                                  // 三相回路のとき
        data = doGetOhmZDirectMG(element.properties.calcPoint0,null,dOhmX);
        if(data && data.pdOhmX){
            dOhmX = data.pdOhmX;
        }
    }
    
    let dXR = 0;
    if(dOhmR > 0){
        dXR = dOhmX / dOhmR;
    }
    if(bViewZero != true && dXR == 0){
        return sText;
    }
    return BeamsNumber(dXR,4);
}
// 通過インピーダンスの文字列を取得する
export const doGetTextDirectOhmZ = (element:any,opeMode:number,nPartKind:number,bUnit:boolean = true,bViewZero:boolean = true) => {

    let sText = "";
    if(opeMode < MS_MODE_PERCENT_Z){
        sText = '未計算';
		return sText;
    }
    let dOhmZ = 0;
    let data;
    if(element.properties.sPhase == true){      // 単相回路のとき
        switch(nPartKind){
            case MS_OUTSIDE_NEUTRAL:            // 外線－中性線間
            case MS_SEAT_MAIN:                  // 主座変圧器
                data = doGetOhmZDirectMG(element.properties.calcPoint1,null,null);
                if(data && data.dOhmZ){
                    dOhmZ = data.dOhmZ;
                }
                break;
            case MS_OUTSIDE_OUTSIDE:            // 外線間
            case MS_SEAT_T:                     // Ｔ座変圧器
                data = doGetOhmZDirectMG(element.properties.calcPoint0,null,null);
                if(data && data.dOhmZ){
                    dOhmZ = data.dOhmZ;
                }
                break;
        }
    }else{                                      // 三相回路のとき
        data = doGetOhmZDirectMG(element.properties.calcPoint0,null,null);
        if(data && data.dOhmZ){
            dOhmZ = data.dOhmZ;
        }
    }

    if(bViewZero != true && dOhmZ == 0){
        return sText;
    }

    if(dOhmZ >= 0){
        sText = BeamsNumber(dOhmZ,4).toString();
        if(bUnit == true){
            sText += "mΩ";
        }
    }else{                                      // 短絡電流が正しく計算されなかったとき
        sText = '????';
    }
    return sText;
}
// 通過インピーダンス抵抗分の文字列を取得する
export const doGetTextDirectOhmR = (element:any,opeMode:number,nPartKind:number,bUnit:boolean = true,bViewZero:boolean = true) => {
    let sText = "";
    if(opeMode < MS_MODE_PERCENT_Z){
        sText = '未計算';
		return sText;
    }
    let dOhmR = 0;
    let data;
    if(element.properties.sPhase == true){      // 単相回路のとき
        switch(nPartKind){
            case MS_OUTSIDE_NEUTRAL:            // 外線－中性線間
            case MS_SEAT_MAIN:                  // 主座変圧器
                data = doGetOhmZDirectMG(element.properties.calcPoint1,dOhmR,null);
                if(data && data.pdOhmR){
                    dOhmR = data.pdOhmR;
                }
                break;
            case MS_OUTSIDE_OUTSIDE:            // 外線間
            case MS_SEAT_T:                     // Ｔ座変圧器
                data = doGetOhmZDirectMG(element.properties.calcPoint0,dOhmR,null);
                if(data && data.pdOhmR){
                    dOhmR = data.pdOhmR;
                }
                break;
        }
    }else{
        data = doGetOhmZDirectMG(element.properties.calcPoint0,dOhmR,null);
        if(data && data.pdOhmR){
            dOhmR = data.pdOhmR;
        }
    }
    if(bViewZero != true && dOhmR == 0){
        return sText;
    }
    if(dOhmR >= 0){
        sText = BeamsNumber(dOhmR,4).toString();
        if(bUnit == true){
            sText += "mΩ";
        }
    }else{                                      // 短絡電流が正しく計算されなかったとき
        sText = '????';
    }
    return sText;
}

export const doGetTextDirectOhmX = (element:any,opeMode:number,nPartKind:number,bUnit:boolean = true,bViewZero:boolean = true) => {
    let sText = "";
    if(opeMode < MS_MODE_PERCENT_Z){
        sText = '未計算';
		return sText;
    }
    let dOhmX = 0;
    let data;
    if(element.properties.sPhase == true){      // 単相回路のとき
        switch(nPartKind){
            case MS_OUTSIDE_NEUTRAL:            // 外線－中性線間
            case MS_SEAT_MAIN:                  // 主座変圧器
                data = doGetOhmZDirectMG(element.properties.calcPoint1,null,dOhmX);
                if(data && data.pdOhmX){
                    dOhmX = data.pdOhmX;
                }
                break;
            case MS_OUTSIDE_OUTSIDE:            // 外線間
            case MS_SEAT_T:                     // Ｔ座変圧器
                data = doGetOhmZDirectMG(element.properties.calcPoint0,null,dOhmX);
                if(data && data.pdOhmX){
                    dOhmX = data.pdOhmX;
                }
                break;
        }
    }else{                                      // 三相回路のとき
        data = doGetOhmZDirectMG(element.properties.calcPoint0,null,dOhmX);
        if(data && data.pdOhmX){
            dOhmX = data.pdOhmX;
        }
    }

    if(bViewZero != true && dOhmX == 0){
        return sText;
    }

    if(dOhmX >= 0){
        sText = BeamsNumber(dOhmX,4).toString();
        if(bUnit == true){
            sText += "mΩ";
        }
    }else{                                      // 短絡電流が正しく計算されなかったとき
        sText = '????';
    }
    return sText;
}
