import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Menu, MenuItem, TextField } from "@mui/material";
import { forwardRef, useEffect, useRef, useState } from "react";
import { UseFormSetValue } from 'react-hook-form';
export interface OptionModel {
    label: string,
    value: any,
};
export type TextFieldOptionProps = {
    name: string,
    defaultValue?: any,
    value: any | null,
    label?: string,
    disabled?: boolean | false,
    size?: "small" | "medium" | undefined,
    options: OptionModel[],
    error?: boolean;
    helperText?: string | undefined;
    onSelectValue?: (name:string,value: string) => void;
    setValue?: UseFormSetValue<any>;
    onChange?: React.ChangeEventHandler<any>;
    maxLength?:number;
    height?:number;
    type?:string;
    style?:any
    notAllowInput?: boolean
};

export const TextFieldOptions = forwardRef((props: TextFieldOptionProps, ref: any) => {
    const { 
        notAllowInput,
        options, 
        label, 
        value, 
        name, 
        error, 
        helperText, 
        size, 
        disabled,
        maxLength,
        onChange,
        setValue,
        onSelectValue,
        height,
        type,
        style
    } = props;
    const [openOptions, setOpenOptions] = useState(false);
    const [selectedValue, setSelectedValue] = useState(value);
    const [widthInput, setWidthInput] = useState(0);
    const [bottomCoordinate, setBottomCoordinate] = useState<number>(0);
    const [leftCoordinate, setLeftCoordinate] = useState<number>(0);
    const [checkOverFlowOption, setCheckOverFlowOption] = useState(false);

    const inputRef = useRef<any>();
    const menuRef = useRef<any>();

    // --------------
    const handleOpenOptions = () => {
        setOpenOptions(true);
    }

    const handleCloseOptions = () => {
        setOpenOptions(false);
    }

    const handleMenuRef = (ref: any) => {
        if (openOptions) {
            if (ref) {
                const option = options.find(option => Number(option.value) === Number(selectedValue));
                if (option) {
                    menuRef.current = ref;
                    const selectedItem = menuRef.current.querySelector(`[data-value="${selectedValue}"]`);
                    if (selectedItem) {
                        selectedItem.focus();
                    }
                }
            }
        }
    }

    const handleInputChange = (event: any) => {
        if(!notAllowInput){
            const valueInput = event.target.value;
            setSelectedValue(valueInput);
            setValueByName(valueInput);
        }
    }

    const handleOptionClick = (option: any) => {
        const value = option.value;
        setValueByName(value);
        setSelectedValue(value);
        setOpenOptions(false);
        if(onSelectValue)
            onSelectValue(name,value);
    }

    const setValueByName = (value: any) => {
        if(setValue)
            setValue(name,value);
    }

    useEffect(() => {
        if (openOptions) {
            window.addEventListener("resize", () => {
                setOpenOptions(false);
            })
        }
        if (inputRef) {
            const inputElement = inputRef.current;
            const { bottom, left, width } = inputElement.getBoundingClientRect();
            setBottomCoordinate(bottom);
            setWidthInput(width);
            setLeftCoordinate(left);
            if (options && options.length) {
                if (options.length > 9) {
                    setCheckOverFlowOption(true);
                }
            }

        }
    }, [openOptions]);

    useEffect(() => {
        if(options){
            // const option = options.find(option => option.value === Number(value));
            // if (option) {
            //     handleOptionClick(option);
            // }
        }else{
            setValueByName(value);
        }
    }, []);

    const handleClick =() =>{
        if(notAllowInput && !disabled){
            setOpenOptions(!openOptions)
        }
    }
    return (
        <div ref={ref}>
            <div ref={inputRef}>
                <TextField
                    name={name}
                    fullWidth
                    label={label ? label : ""}
                    type={type ? type : "text"}
                    size={size ? size : "small"}
                    style ={style}
                    onClick={() =>handleClick()}
                    // disabled={disabled}
                    onChange={(event:any) => {
                        if (!notAllowInput){
                            if(onChange != null) 
                                onChange(event);
                            handleInputChange(event);
                        }
                    }
                    }
                    value={value ?? selectedValue }
                    InputLabelProps={{
                        shrink: true
                    }}
                    helperText={helperText}
                    error={error}
                    inputProps={{
                        readOnly: disabled,
                        maxLength:maxLength?maxLength:"none"
                    }}
                    InputProps={{
                        sx: { height: height},
                        style: {
                            paddingRight: 0,
                            backgroundColor: disabled ? "floralwhite" : "white",
                            color: disabled ? "grey" : "black"
                        },
                        endAdornment: (
                            <>
                                <Menu
                                    ref={handleMenuRef}
                                    style={{
                                        maxHeight: "430px",
                                        overscrollBehaviorY: "auto",
                                    }}
                                    anchorReference="anchorPosition"
                                    anchorPosition={{ top: bottomCoordinate, left: leftCoordinate }}
                                    transformOrigin={{ vertical: "top", horizontal: "left" }}
                                    open={openOptions}
                                    onClose={handleCloseOptions}
                                >
                                    {options && options.map((option, index) => (
                                        <MenuItem
                                            style={{
                                                backgroundColor: selectedValue == option.value ? "rgba(25, 118, 210, 0.08)" : 'transparent',
                                                width: `${checkOverFlowOption ? widthInput - 16 : widthInput}px`,
                                            }}
                                            sx={{
                                                ":hover":
                                                {
                                                    backgroundColor: selectedValue != option.value ? "#F6F6F6 !important" : "transparent",
                                                }
                                            }}
                                            key={index}
                                            data-value={option.value}
                                            onClick={() => handleOptionClick(option)}
                                        >
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </Menu>
                                <ArrowDropDownIcon
                                    onClick= {!disabled?handleOpenOptions:()=>{}}
                                    style={{ cursor: !disabled ? 'pointer':"not-allowed", color: "grey", padding: "7px" }}
                                />
                            </>
                        )
                    }}
                />
            </div>
        </div>
    );
});
export default TextFieldOptions;


