import { LoadingButton } from "@mui/lab";
import { Box, Button, Checkbox, CircularProgress, FormControlLabel, Grid, Stack, Tab, Tabs, TextField, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DialMagniCurrentModel, DialCharacterModel, BreakTypeModel } from "../../../models/breakModel";
import { Path as RoutePath } from "../../../path";
import { ApplicationState, actionCreators, selectors } from "../../../store";
import FetchHelper from "../../FetchHelper";
import BreakTypeGrid from "../BreakType/BreakTypeGrid";
import { SearchItemForm } from "../DialMagniCurrent/DialMagniCurrentList";

// -------------
// FETCH ID
const GET_DIAL_MAGNI_CURRENT = "GET_DIAL_MAGNI_CURRENT";
const CREATE_DIAL_MAGNI_CURRENT = "CREATE_DIAL_MAGNI_CURRENT";
const UPDATE_DIAL_MAGNI_CURRENT = "UPDATE_DIAL_MAGNI_CURRENT";
const DELETE_DIAL_MAGNI_CURRENT = "DELETE_DIAL_MAGNI_CURRENT"

// 初期値設定
const initialValue: DialMagniCurrentModel = {
    lMagniCurrentID: 0,
    mv3breakDialCharacter: {} as DialCharacterModel,
    bIsDefault: false,
    sFixedName: '',
    dFixedValue: "",
    dContiDefValue: "",
    dContiMiniValue: "",
    dContiWidthValue: "",
    dContiMaxValue: "",
}

// -------------
//Tabs------------------
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `oilManagement-tab-${index}`,
        'aria-controls': `oilManagement-tabpanel-${index}`,
    };
}

// -------------
// Props
export type DialMagniCurrentEditProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>


export const DialMagniCurrentEdit = (props: DialMagniCurrentEditProps) => {
    const {
        userId,
        fetchUpdate,
        onCreate,
        onUpdate,
        onSuccess,
        onDeleteType,
        onError,
        onSearch
    } = props;
    const { control, handleSubmit, setValue, getValues, formState: {errors}} = useForm<DialMagniCurrentModel>({
        criteriaMode:"all",
        mode: "all",
        defaultValues: initialValue,
    });

    type MyParams = {
        id: string;
        mode: string;
    };

    let { id, mode } = useParams<keyof MyParams>() as MyParams;
    const location = useLocation();
    let lDialCharID = (location.state) ? location.state.lDialCharid : undefined;
    let sCharacterName = (location.state) ? location.state.sCharacterName : undefined;
    const object = {
        requestPrms:{
            id:id
            
        },
        userId: userId,
    }
    useEffect(() => {
        if (mode === "add")
            setValue("mv3breakDialCharacter.sCharacterName", sCharacterName);
    }, [])
    let navigate = useNavigate();
    const [selectTab, setSelectTab] = useState(0);
    const [loadingFlag, setLoadingFlag] = useState(mode === "edit" ? true : false);
    const gridRef = useRef() as any;
    const [colState, setColState] = useState<any>();
    const [mv3breakDialMagniCurrentList, setMv3breakDialMagniCurrentList] = useState<DialMagniCurrentModel>();
    //const [mv3breakBreakTypeList, setMv3breakBreakTypeList] = useState<BreakTypeModel>();
    const [idDelete, setIdDelete] = useState<string>("");
    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");
    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
    let tab = location.state && location.state.tab;
    const handleSuccess = (data: any) => {
        console.log('>>> handleSuccess');
        setValue("sFixedName", data.data.sfixedName)
        setValue("dFixedValue", data.data.dfixedValue)
        setValue("dContiDefValue", data.data.dcontiDefValue)
        setValue("dContiMiniValue", data.data.dcontiMiniValue)
        setValue("dContiWidthValue", data.data.dcontiWidthValue)
        setValue("dContiMaxValue", data.data.dcontiMaxValue)
        setValue("bIsDefault", data.data.bisDefault)
        setValue("lMagniCurrentID", data.data.lmagniCurrentID);
        setValue("mv3breakDialCharacter.lDialCharID", data.data.ldialCharID);
        setLoadingFlag(false);
        if (data.data.length > 0) {
            setMv3breakDialMagniCurrentList(data.setMv3breakDialMagniCurrentList)
        }
        setLoadingFlag(false)
        if (tab !== undefined && tab !== null) {
            setSelectTab(tab)
        }
    };
    // useEffect(() => {
    //     if (mode === "add")
    //         setValue("mv3breakDialCharacter.lDialCharID", lDialCharID);
    // }, [])
    // 検索エラー時
    const handleError = (success: boolean, data: any, error: any) => {
        console.log('>>> handleError');
        gridRef.current.hideOverlay();
    };

    const tabHandleChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectTab(newValue);
        navigate(".", {
            replace: true,
            state: {
                tab: newValue,
                lDialCharid: lDialCharID,
                sCharacterName: sCharacterName
            }
        });
    }

    // キャンセル
    const handleCancel = () => {
        if (location.key !== "default")
            navigate(-1);
        else
            navigate(RoutePath.DialMagniCurrentList);
    }

    // Submitイベント
    const handleEditSubmit = (data: DialMagniCurrentModel) => {
        setLoadingFlag(true)
        if (mode === "edit") {
            onUpdate({
                "userId": userId,
                "requestPrms":{
                    lMagniCurrentID: getValues("lMagniCurrentID"),
                    bIsDefault: getValues("bIsDefault"),
                    sFixedName: getValues("sFixedName"),
                    dFixedValue: getValues("dFixedValue"),
                    dContiDefValue: getValues("dContiDefValue"),
                    dContiMiniValue: getValues("dContiMiniValue"),
                    dContiWidthValue: getValues("dContiWidthValue"),
                    dContiMaxValue: getValues("dContiMaxValue"),
                    lDialCharID: getValues("mv3breakDialCharacter.lDialCharID")
                }
            });
        }
        else if (mode === "add") {
            onCreate({
                "userId": userId,
                "requestPrms":{
                    bIsDefault: getValues("bIsDefault"),
                    sFixedName: getValues("sFixedName"),
                    dFixedValue: getValues("dFixedValue"),
                    dContiDefValue: getValues("dContiDefValue"),
                    dContiMiniValue: getValues("dContiMiniValue"),
                    dContiWidthValue: getValues("dContiWidthValue"),
                    dContiMaxValue: getValues("dContiMaxValue"),
                    lDialCharID: lDialCharID,
            }
            });
        }
    }

    // Submit後のAPI呼び出し成功時
    const handleSubmitSuccess = (success: boolean, data: any, error: any) => {
        onSuccess();
        // if (lDialCharID !== undefined && lDialCharID != null) {
        //     navigate(-1);
        // }
        if(mode ==="add"){
            navigate(RoutePath.DialMagniCurrentEdit+ `/edit/${data.data.lmagniCurrentID}`,{replace:true})
        }
        /////////////////////////////////////////////////////////////////////////
        setLoadingFlag(false)
    }
    // 検索実行
    const handleSearchSubmit = (value: SearchItemForm) => {
        const params = new Map<string, any>();
        if (value.limit) params.set("limit", value.limit);
        params.set("page", value.page);
        setLoadingFlag(true);
        onSearch({
            userId: userId,
            requestPrms:{
                    limit: value.limit,
                    page: value.page
            }
        });
    }

    // Submit後のAPI呼び出しエラー時
    const handleSubmitError = (success: boolean, data: any, error: any) => {
        onError();
    }

    //削除の処理
    const handleDelete = (data: any) => {
        setIdDelete(data);
        const msg = "ID: " + data + "を削除します。"
        setTitle("情報削除");
        setMessage(msg);
        setOpenDeleteDialog(true);
    }
    // 削除確認ダイアログでOKボタンのイベントを受け取って、APIに送信する
    const handleDeleteOK = () => {
        setOpenDeleteDialog(false);
        onDeleteType(idDelete);
        setIdDelete("");
    }
    const handleDeleteCancel = () => {
        setIdDelete("");
        setOpenDeleteDialog(false);
    }

    const handleSuccessDelete = (data: any) => {
        console.log('>>> handleSuccessDelete');
    };
    //削除の処理
    const handleShowDetailPage = (data: any) => {
        navigate(RoutePath.DialMagniCurrentEdit + `/edit/${data}`,
            {
                state: {
                    lMagniCurrentID: id,
                }
            })
    }

    //入力欄背景
    const divStyles = {
        backgroundColor: 'white',
        border: '1px solid #bebebe',
        boxShadow: '0 0 5px #fff, 0 0 5px #bebebe, 0 0 1px #aaa',
        padding: '20px 40px',
        marginTop: '40px',
    }

    //分析一覧を表示
    const handleAdd = () => {
        navigate(RoutePath.DialMagniCurrentEdit + `/add`,
            {
                state: {
                    //lMagniCurrentID: getValues(lMagniCurrentID),               
                    // lDialCharID: getValues("mv3breakDialCharacter.lDialCharID"),
                    sFixedName: getValues("sFixedName"),
                }
            })
    }

    // //分析一覧を表示
    const resetGrid = () => {
        if (selectTab === 1) {
            gridRef.current.setRowData(setMv3breakDialMagniCurrentList);
        }
    }
    useEffect(resetGrid, [selectTab]);
    return (
        <>
            {mode === "edit" &&
                <FetchHelper fetchId={GET_DIAL_MAGNI_CURRENT}
                    url={`/master/dial_magni_current/get`}
                    method="POST"
                    params={object}
                    json={true}
                    multipart={false}
                    request={true}
                    onComplete={(success, data, error) =>
                        success ? handleSuccess(data) : handleError(success, data, error)}
                />}
            {loadingFlag && (
                <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1500, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                    <CircularProgress />
                </div>
            )}
            <div style={{ height: '100%', width: '100%', marginTop: '10px' }}>
                <Stack direction="row" justifyContent="flex-start" alignItems="center" sx={{}} style={{ margin: '18px', color: 'MenuText' }}>
                    <Typography variant="h4">
                        電流倍率詳細
                    </Typography>
                </Stack>
                <form onSubmit={handleSubmit(handleEditSubmit)}>
                    <div style={{ height: '100%', width: '100%' }}>
                        <Stack direction='row'>
                            <div style={{ top: "100px", position: "absolute", zIndex: 1200 }}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs value={selectTab} onChange={tabHandleChange} aria-label="oilManagementDetailTabs">
                                        <Tab label='電流倍率' {...a11yProps(0)} />
                                        {/* {mode === "edit" && <Tab label='機器グループ' {...a11yProps(1)} />} */}
                                    </Tabs>
                                </Box>
                            </div>
                        </Stack>

                        {/* 電流倍率 */}
                        <TabPanel value={selectTab} index={0}>
                            <div style={divStyles}>
                                <Typography variant="h6">電流倍率</Typography>
                                <Stack sx={{ height: "calc(45vh)", overflow: 'auto'}}>
                                
                                    <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3}>
                                        <Controller
                                            name="sFixedName"
                                            control={control}
                                            
                                            render={({ field }) => (
                                                <TextField
                                            
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    label="固定可変名称 "
                                                    size="small"                                                   
                                                />
                                            )}
                                        />
                                    </Stack>
                                    <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3}>
                                        <Controller
                                            name="dFixedValue"
                                            control={control}
                                            rules={{
                                                pattern: {
                                                    value: /^-?\d+(?:\.\d+)?$/,
                                                    message: '数字を入力してください。'
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    error={!!errors.dFixedValue?.message}
                                                    helperText={errors.dFixedValue?.message}
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    label="固定可変値 "
                                                    size="small"
                                                />
                                            )}
                                        />
                                    </Stack>
                                    <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3}>
                                        <Controller
                                            name="dContiDefValue"
                                            control={control}
                                            rules={{
                                                pattern: {
                                                    value: /^-?\d+(?:\.\d+)?$/,
                                                    message: '数字を入力してください。'
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    error={!!errors.dContiDefValue?.message}
                                                    helperText={errors.dContiDefValue?.message}
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    label="連続可変デフォルト値"
                                                    size="small"
                                                />
                                            )}
                                        />
                                    </Stack>
                                    <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3}>
                                        <Controller
                                            name="dContiMiniValue"
                                            control={control}
                                            rules={{
                                                pattern: {
                                                    value: /^-?\d+(?:\.\d+)?$/,
                                                    message: '数字を入力してください。'
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                error={!!errors.dContiMiniValue?.message}
                                                helperText={errors.dContiMiniValue?.message}
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    label="連続可変最小値 "
                                                    size="small"
                                                />
                                            )}
                                        />
                                    </Stack>
                                    <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3}>
                                        <Controller
                                            name="dContiWidthValue"
                                            control={control}
                                            rules={{
                                                pattern: {
                                                    value: /^-?\d+(?:\.\d+)?$/,
                                                    message: '数字を入力してください。'
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                error={!!errors.dContiWidthValue?.message}
                                                helperText={errors.dContiWidthValue?.message}
                                                    {...field}
                                                    fullWidth
                                                    type="text"     
                                                                                          
                                                    label="連続可変幅"
                                                    size="small"
                                                />
                                            )}
                                        />
                                    </Stack>
                                    <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3}>
                                        <Controller
                                            name="dContiMaxValue"
                                            control={control}
                                            rules={{
                                                pattern: {
                                                    value: /^-?\d+(?:\.\d+)?$/,
                                                    message: '数字を入力してください。'
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                error={!!errors.dContiMaxValue?.message}
                                                helperText={errors.dContiMaxValue?.message}
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    label="連続可変最大値"
                                                    size="small"
                                                />
                                            )}
                                        />
                                    </Stack>
                                    {/* {mode==="add" &&
                                    <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3}>   
                                                <Controller
                                                    name="mv3breakDialCharacter.lDialCharID"
                                                    control={control}
                                                    render={({ field }: any) => (
                                                    <TextField
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    label="特性ID"
                                                    size="small"
                                                />)}
                                                />                                       
                                    </Stack>
                                    } */}
                                    <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3}>
                                        <Grid container spacing={1} direction="row">
                                            <Grid item xs={2}>
                                                <Controller
                                                    name="bIsDefault"
                                                    control={control}
                                                    render={({ field }: any) => (
                                                        <FormControlLabel {...field} control={<Checkbox />} checked={field.value} label="デフォルト" labelPlacement="start" />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Stack>

                                </Stack>
                            </div>
                            <Stack direction='row' justifyContent="flex-end" spacing={2} sx={{ mt: 1, mr: 2 }}>
                                <LoadingButton variant="contained" type="submit" loading={loadingFlag}>保存</LoadingButton>
                                <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                            </Stack>
                        </TabPanel>
                        {mode === "edit" &&
                            <TabPanel value={selectTab} index={1}>
                                <div style={divStyles}>
                                    {/* <Stack direction="row" display='flex' justifyContent='space-between'>
                                    <div><Typography variant="h6" >機器グループ</Typography></div>
                                    <div >
                                        <Stack direction='row' display='flex' spacing={2}>
                                            <Stack>
                                                <Button fullWidth color="inherit" variant="contained" onClick={() => handleAdd()}>機器グループの追加</Button>
                                            </Stack>
                                        </Stack>
                                    </div>
                                </Stack> */}
                                    <Stack spacing={2} sx={{ mt: 1 }} >
                                        <BreakTypeGrid
                                            ref={gridRef}
                                            colState={colState}
                                            onChangeSearchCondition={() => { }}
                                            keyword={""}
                                            onShowDetails={handleShowDetailPage}
                                            onDelete={handleDelete}
                                            heightForm="335"
                                        />
                                        {/* {openDeleteDialog ?
                                        <AlertDialog title={title} message={message} isOpen={true} onOK={handleDeleteAnalysisOK} onCancel={handleDeleteAnalysisCancel} /> :
                                        <div />
                                    } */}
                                    </Stack>
                                </div>
                            </TabPanel>
                        }
                        {/* <Stack direction='row' justifyContent="flex-end" spacing={2} sx={{ mt: 1, mr: 2 }}>
                            <LoadingButton variant="contained" type="submit" loading={loadingFlag}>保存</LoadingButton>
                            <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                        </Stack> */}
                    </div>
                </form>
            </div>
            <FetchHelper fetchId={CREATE_DIAL_MAGNI_CURRENT} onComplete={(success, data, error) => success ? handleSubmitSuccess(success, data, error) : handleSubmitError(success, data, error)} />
            <FetchHelper fetchId={UPDATE_DIAL_MAGNI_CURRENT} onComplete={(success, data, error) => success ? handleSubmitSuccess(success, data, error) : handleSubmitError(success, data, error)} />
        </>
    );
};

const mapStateToProps = (state: ApplicationState) => ({
    userId: state.app.user?.userId,
    fetchUpdate: selectors.getFormState(
        state,
        GET_DIAL_MAGNI_CURRENT
    ),
});

const mapDispatchToProps = (dispatch: any) => {
    return {
        onCreate: (data: any) => dispatch(actionCreators.fetch(CREATE_DIAL_MAGNI_CURRENT, `/master/dial_magni_current/add`, "POST", data,false,true)),
        onUpdate: (data: any) => dispatch(actionCreators.fetch(UPDATE_DIAL_MAGNI_CURRENT, `/master/dial_magni_current/modify`, 'POST', data,false,true)),
        onDeleteType: (id: any) => dispatch(actionCreators.fetch(DELETE_DIAL_MAGNI_CURRENT, `/master/dial_magni_current/delete`, "POST", id,false,true)),
        onSearch: (params:  any ) => dispatch(actionCreators.fetch(GET_DIAL_MAGNI_CURRENT, `/master/dial_magni_current/gets`, "POST", params,false,true)),
        onSuccess: () =>
            dispatch(actionCreators.showMessage({
                type: "info",
                title: "電流倍率更新",
                body: "電流倍率を更新しました。",
            })),
        onError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "検索",
                body: "検索できません。",
            })),
        onCreateError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "電流倍率登録",
                body: "電流倍率を登録出来ませんでした。",
            })),
        onUpdateError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "電流倍率編集",
                body: "電流倍率を更新出来ませんでした。",
            })),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DialMagniCurrentEdit as any);


