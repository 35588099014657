import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      dark: '#1976d2',
      main: '#4899ea',
      light: '#3da2f5',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#4899ea',
      light: '#e7f3fd'
    },
  },
  shape: { borderRadius: 8 },
})

export default theme;