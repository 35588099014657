import { LoadingButton } from '@mui/lab';
import { Autocomplete, Box, Button, CircularProgress, FormControl, Stack, Tab, Tabs, TextField, Typography } from '@mui/material';
import { createContext, forwardRef, useContext, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { CurveStandardModel, DialCharacterModel } from '../../../models/breakModel';
import { Path as RoutePath } from '../../../path';
import { lineKindOptions } from '../../../statics';
import { ApplicationState, actionCreators, selectors } from '../../../store';
import FetchHelper from '../../FetchHelper';
import {ListChildComponentProps, VariableSizeList } from 'react-window';

// -------------
// FETCH ID
const GET_CURVE_STANDARD = 'GET_CURVE_STANDARD';
const CREATE_CURVE_STANDARD = 'CREATE_CURVE_STANDARD';
const UPDATE_CURVE_STANDARD = 'UPDATE_CURVE_STANDARD';
const GET_CURVE_HEADER = "GET_CURVE_HEADER";

// 初期値設定
const initialCurveStandard: CurveStandardModel = {
    lStandardID: 0,
    mv3breakDialCharacter: {} as DialCharacterModel,
    lCurveHeaderID: null,
    dPairTime: "",
    lStdVoltage2: "",
    nLineKind: 0,
}

// -------------
//Tabs
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

interface curveHeaderOption {
    manufactureName: string;
    scurveName: string;
    sclassName: string;
    ncurveKind: string;
    dstdCurrentValue: string;
    dstdTimeValue: string;
    lstdVoltage: string;
    value: number;
}

//入力欄背景
const divStyles = {
    backgroundColor: 'white',
    border: '1px solid #bebebe',
    boxShadow: '0 0 5px #fff, 0 0 5px #bebebe, 0 0 1px #aaa',
    padding: '20px 40px',
    marginTop: '40px',
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `oilManagement-tab-${index}`,
        'aria-controls': `oilManagement-tabpanel-${index}`,
    };
}

// -------------
// Props
export type CurveStandardEditProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

export const CurveStandardEdit = (props: CurveStandardEditProps) => {
    const {
        userId,
        onCreate,
        onUpdate,
        onSuccess,
        onError,
    } = props;

    const { control, handleSubmit, setValue, getValues,formState:{errors} } = useForm<CurveStandardModel>({
        criteriaMode: 'all',
        defaultValues: initialCurveStandard,
        mode: "all",
    });

    type MyParams = {
        id: string;
        mode: string;
    };
    let { id, mode } = useParams<keyof MyParams>() as MyParams;
    let navigate = useNavigate();
    const location = useLocation();
    let tab = location.state && location.state.tab;
    const object = {
        requestPrms:{
            id:id
        },
        userId: userId,
    }
    const roundedNumber = (number:any) => {
        if (!number) return "";
        return number % 1 === 0 ? number.toFixed(0) : number.toFixed(2);
    }
    const [selectTab, setSelectTab] = useState(0);
    const [loadingFlag, setLoadingFlag] = useState(true);
    const Exp = /^[-+]?\d+(\.\d+)?$/;
    const messageIsNotNumber = "数字を入力してください。";
    const [curveHeaderOptions, setCurveHeaderOptions] = useState<curveHeaderOption[]>([]);
    const [curveHeaderID, setCurveHeaderID] = useState();
    let lDialCharid = (location.state) ? location.state.lDialCharid:undefined;
    let sCharacterName = (location.state) ? location.state.sCharacterName:undefined;

    useEffect(() => {
        if (mode === "add") {
            setValue("mv3breakDialCharacter.lDialCharID", lDialCharid);
            setValue("mv3breakDialCharacter.sCharacterName", sCharacterName);
        }
    }, [])

    const handleSuccess = (data: any) => {
        console.log('>>> handleSuccess');
        if (mode === "edit" && data.resultCode === 0) {
            const object = data.data;
            setCurveHeaderID(object.lcurveHeaderID)
            setValue('lStandardID', object.lstandardID)
            setValue('lCurveHeaderID', object.lcurveHeaderID)
            setValue('dPairTime', object.dpairTime)
            setValue('lStdVoltage2', object.lstdVoltage2)
            setValue('nLineKind', object.nlineKind)
            setValue("mv3breakDialCharacter.sCharacterName", object.mv3breakDialCharacterEntity.scharacterName);
            setValue("mv3breakDialCharacter.lDialCharID",object.mv3breakDialCharacterEntity.ldialCharID);
            if (data.curveHeaderOptions?.results?.length > 0) {
                let optionList = [] as curveHeaderOption[];
                data.curveHeaderOptions?.results?.forEach((e: any) => {
                    optionList.push({
                        manufactureName: e.smanuName,
                        scurveName: e.scurveName,
                        sclassName: e.sclassName,
                        ncurveKind: e.ncurveKind,
                        dstdCurrentValue: e.dstdCurrentValue,
                        dstdTimeValue: e.dstdTimeValue,
                        lstdVoltage: e.lstdVoltage,
                        value: e.lcurveHeaderID,
                    } as curveHeaderOption)
                });
                setCurveHeaderOptions(optionList)
            }
        }
        else if (mode === "add") {
            if (data.results?.length > 0) {
                let optionList = [] as curveHeaderOption[];
                data.results.forEach((e: any) => {
                    optionList.push({
                        manufactureName: e.smanuName,
                        scurveName: e.scurveName,
                        sclassName: e.sclassName,
                        ncurveKind: e.ncurveKind,
                        dstdCurrentValue: e.dstdCurrentValue,
                        dstdTimeValue: e.dstdTimeValue,
                        lstdVoltage: e.lstdVoltage,
                        value: e.lcurveHeaderID,
                    } as curveHeaderOption)
                });
                setCurveHeaderOptions(optionList)
            }
        }
        setLoadingFlag(false)
        if (tab !== undefined && tab !== null) {
            setSelectTab(tab)
        }
    };

    const tabHandleChange = (event: React.SyntheticEvent, newValue: number) => {
        // setSelectTab(newValue);
    }

    // 検索エラー時
    const handleError = (success: boolean, data: any, error: any) => {
        console.log('>>> handleError');
    };

    // キャンセル
    const handleCancel = () => {
        if (location.key !== "default")
            navigate(-1);
        else
            navigate(RoutePath.CurveStandardList);
    }

    // Submitイベント
    const handleEditSubmit = async (value: CurveStandardModel) => {
        setLoadingFlag(true)
        if (mode === 'edit') {
            let request = {
                lStandardID: getValues('lStandardID'),
                dPairTime: getValues('dPairTime'),
                lCurveHeaderID: getValues('lCurveHeaderID') ?? '',
                lStdVoltage2: getValues('lStdVoltage2'),
                nLineKind: getValues('nLineKind') ?? '',
            } as any
            const object = {
                userId: userId,
                requestPrms:request,
            }
            onUpdate(object);
        } else if (mode === 'add') {
            let request = {
                lCurveHeaderID: getValues('lCurveHeaderID')??'1962',
                dPairTime: getValues('dPairTime'),
                lStdVoltage2: getValues('lStdVoltage2'),
                nLineKind: getValues('nLineKind'),
                lDialCharID: lDialCharid??3179,
            } as any
            const object = {
                requestPrms:request,
                userId: userId,
            }
            onCreate(object);
        }
    }

    // Submit後のAPI呼び出し成功時
    const handleSubmitSuccess = (success: boolean, data: any, error: any) => {
        onSuccess();
        // if (lDialCharid !== undefined) {
        //     navigate(-1);
        // } 
        if(mode ==="add"){
            navigate(RoutePath.CurveStandardEdit + `/edit/${data.data}`,{replace:true})
        }
        // else {
        //     navigate(RoutePath.CurveStandardList);
        // }
        setLoadingFlag(false)
    }

    // Submit後のAPI呼び出しエラー時
    const handleSubmitError = (success: boolean, data: any, error: any) => {
        console.log('>>> handleError');
        onError();
        setLoadingFlag(false);
    }

    const handleHeaderDetail = () =>{
        if(curveHeaderID !== 0 && curveHeaderID){
            navigate(RoutePath.CurveHeaderEdit + `/edit/${curveHeaderID}`,
            {state: {lStandardID: id}});
        }
    }

    // #region react-window
    const LISTBOX_PADDING = 8; // px

    function renderRow(props: ListChildComponentProps) {
        const { data, index, style } = props;
        const dataSet = data[index];
        const inlineStyle = {
            ...style,
            top: (style.top as number) + LISTBOX_PADDING,
        };
        const { key, ...optionProps } = dataSet[0];
        return (
            <Stack key={key} component="li" {...optionProps} noWrap style={inlineStyle} flexDirection={"row"}>
                <Typography style={{width:'400px'}}>
                    {dataSet[1].scurveName}
                </Typography>
                <Typography style={{width:'200px'}}>
                    {dataSet[1].manufactureName}
                </Typography>
                <Typography style={{width:'180px'}}>
                    {dataSet[1].sclassName}
                </Typography>
                <Typography style={{width:'50px'}}>
                    {dataSet[1].ncurveKind}
                </Typography>
                <Typography style={{width:'70px'}}>
                    {roundedNumber(dataSet[1].dstdCurrentValue)}
                </Typography>
                <Typography style={{width:'50px'}}>
                    {roundedNumber(dataSet[1].dstdTimeValue)}
                </Typography>
                <Typography >
                    {roundedNumber(dataSet[1].lstdVoltage)}
                </Typography>
            </Stack>
        );
    }
    const OuterElementContext = createContext({});

    const OuterElementType = forwardRef<HTMLDivElement>((props, ref) => {
        const outerProps = useContext(OuterElementContext);
        return <div ref={ref} {...props} {...outerProps} />;
    });

    function useResetCache(data: any) {
        const ref = useRef<VariableSizeList>(null);
        useEffect(() => {
            if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
            }
        }, [data]);
        return ref;
    }
    
    // Adapter for react-window
    const ListboxComponent = forwardRef<
        HTMLDivElement,
        React.HTMLAttributes<HTMLElement>
    >(function ListboxComponent(props, ref) {
        const { children, ...other } = props;
        const itemData: React.ReactElement[] = [];
        (children as React.ReactElement[]).forEach(
            (item: React.ReactElement & { children?: React.ReactElement[] }) => {
                itemData.push(item);
            },
        );
        const itemCount = itemData.length;
        const itemSize = 50;
        const getChildSize = () => {
            return itemSize;
        };

        const getHeight = () => {
            if (itemCount > 8) {
                return 8 * itemSize;
            }
            return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
        };
        const gridRef = useResetCache(itemCount);
        return (
            <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={itemData}
                    height={getHeight() + 2 * LISTBOX_PADDING}
                    width="100%"
                    ref={gridRef}
                    outerElementType={OuterElementType}
                    innerElementType="ul"
                    itemSize={() => getChildSize()}
                    overscanCount={5}
                    itemCount={itemCount}
                    style={{overflowX:"hidden"}}
                >
                {renderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
            </div>
        );
    });

    const getValue = (value:any) => {
        let option = curveHeaderOptions.find(options => options.value === value)??null
        return option
    }
    const getOptionLable = (option:any) => {
        return option?.scurveName||""
    }
    // #endregion
    return (
        <>
            {mode === 'edit' &&
                <FetchHelper fetchId={GET_CURVE_STANDARD}
                    url={`/master/curve_standard/get`}
                    method='POST'
                    params={object}
                    request={true}
                    json={true}
                    multipart={false}
                    onComplete={(success:any, data:any, error:any) =>
                        success ? handleSuccess(data) : handleError(success, data, error)}
                />}
            {mode === 'add' &&
                <FetchHelper fetchId={GET_CURVE_HEADER}
                    url={`/master/curve_standard/get_by_dial_char_id`}
                    method='POST'
                    params={{
                        requestPrms:{
                            id:id??1961
                        },
                        userId: userId,
                        
                    }}
                    request={true}
                    json={true}
                    onComplete={(success, data, error) =>
                        success ? handleSuccess(data) : handleError(success, data, error)}
                />}
            {loadingFlag && (
                <div style={{ top: '0px', left: '0px', position: 'fixed', zIndex: 1500, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                    <CircularProgress />
                </div>
            )}
            <div style={{ height: '100%', width: '100%', marginTop: '10px' }}>
                <Stack direction='row' justifyContent='flex-start' alignItems='center' sx={{}} style={{ margin: '18px', color: 'MenuText' }}>
                    <Typography variant='h4'>
                        曲線基準詳細
                    </Typography>
                </Stack>
                <form onSubmit={handleSubmit(handleEditSubmit)}>
                    <div style={{ height: '100%', width: '100%' }}>
                        <Stack direction='row'>
                            <div style={{ top: '100px', position: 'absolute', zIndex: 1200 }}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs value={selectTab} onChange={tabHandleChange} aria-label='oilManagementDetailTabs'>
                                        <Tab label='曲線基準' {...a11yProps(0)} />
                                        {/* {mode === 'edit'  && <Tab label='動作特性本体' {...a11yProps(1)} />} */}
                                    </Tabs>
                                </Box>
                            </div>
                        </Stack>
                        {/* 曲線基準 */}
                        <TabPanel value={selectTab} index={0}>
                            <div style={divStyles}>
                                <Stack direction="row" display='flex' justifyContent='space-between'>
                                    <div><Typography variant="h6" >曲線基準</Typography></div>
                                    {   mode == "edit" &&
                                        <div>
                                            <Stack direction='row' display='flex' spacing={2}>
                                                <Button color='inherit' variant='contained' onClick={handleHeaderDetail}>動作特性本体</Button>
                                            </Stack>
                                        </div>
                                    }
                                </Stack>
                                <Stack spacing={3} mt={3} sx={{maxHeight:"calc(100vh)", overflow: 'auto'}}>
                                    <Stack pt={1}>
                                        <Controller
                                            name="mv3breakDialCharacter.sCharacterName"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    disabled
                                                    fullWidth
                                                    type='text'
                                                    label='特性名称'
                                                    size='small'
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                            )}
                                        />
                                    </Stack>
                                    <Stack>
                                        <FormControl fullWidth size='small'>
                                            <Controller
                                                control={control}
                                                name="lCurveHeaderID"
                                                rules={
                                                    {
                                                        required:"必須項目です。入力してください。",
                                                    }
                                                }
                                                render={({ field: { value } }) => (
                                                    <Autocomplete
                                                        autoHighlight
                                                        value={getValue(value)}
                                                        getOptionLabel={(option:any) => getOptionLable(option)}
                                                        fullWidth
                                                        renderInput={(params) => 
                                                            <TextField {...params}
                                                            helperText={errors?.lCurveHeaderID?.message}
                                                            error={!!errors?.lCurveHeaderID}
                                                            label="動作特性本体" size="small" />
                                                        }
                                                        id="virtualize-demo"
                                                        disableListWrap
                                                        ListboxComponent={ListboxComponent}
                                                        options={curveHeaderOptions}
                                                        renderOption={(props, option, state) =>
                                                            [props, option, state.index] as React.ReactNode
                                                        }                                                    
                                                        onChange={(_, data) => {
                                                            setValue("lCurveHeaderID", Number(data?.value))
                                                        }}
                                                    />
                                                )}
                                            />
                                        </FormControl>
                                    </Stack>
                                    <Stack>
                                        <Controller
                                            name='dPairTime'
                                            control={control}
                                            rules={{
                                                pattern:{
                                                    value:Exp,
                                                    message:messageIsNotNumber
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    label='不定則時間(s)'
                                                    size='small'
                                                    type="text"
                                                    error={!!errors?.dPairTime}
                                                    helperText={errors?.dPairTime?.message}
                                                />
                                            )}
                                        />
                                    </Stack>
                                    <Stack>
                                        <Controller
                                            name='lStdVoltage2'
                                            control={control}
                                            rules={{
                                                pattern:{
                                                    value:Exp,
                                                    message:messageIsNotNumber
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    label='2次側電圧值(V)'
                                                    size='small'
                                                    type="text"
                                                    error={!!errors?.lStdVoltage2}
                                                    helperText={errors?.lStdVoltage2?.message}
                                                />
                                            )}
                                        />
                                    </Stack>
                                    <Stack>
                                        <FormControl fullWidth size='small'>
                                            <Controller
                                                control={control}
                                                name="nLineKind"
                                                render={({ field: { value } }) => (
                                                    <Autocomplete
                                                        autoHighlight
                                                        value={lineKindOptions.find(options => options.value === value)??null}
                                                        options={lineKindOptions}
                                                        getOptionLabel={(option:any) => option.label}
                                                        fullWidth
                                                        renderInput={(params) => <TextField {...params}  label="描画線種" size="small"/>}
                                                        onChange={(_, data) => {
                                                            setValue("nLineKind", Number(data?.value))
                                                        }}
                                                    />
                                                )}
                                            />
                                        </FormControl>
                                    </Stack>
                                </Stack>
                            </div>
                        </TabPanel>
                        <Stack direction='row' justifyContent='flex-end' spacing={2} sx={{ mt: 1, mr: 2 }}>
                            <LoadingButton variant='contained' type='submit' loading={loadingFlag}>保存</LoadingButton>
                            <Button color='inherit' variant='contained' onClick={handleCancel}>キャンセル</Button>
                        </Stack>
                    </div>
                </form>
            </div>
            <FetchHelper
                fetchId={CREATE_CURVE_STANDARD}
                onComplete={(success:any, data, error) => success ?
                    handleSubmitSuccess(success, data, error)
                    : handleSubmitError(success, data, error)}
            />
            <FetchHelper
                fetchId={UPDATE_CURVE_STANDARD}
                onComplete={(success, data, error) => success ? handleSubmitSuccess(success, data, error) : handleSubmitError(success, data, error)}
            />
            <FetchHelper
                fetchId={GET_CURVE_STANDARD}
                onComplete={(success, data, error) => success ?
                    handleSuccess(data)
                    : handleError(success, data, error)}
            />
            
        </>
    );
};

const mapStateToProps = (state: ApplicationState) => ({
    userId: state.app.user?.userId,
    fetchUpdate: selectors.getFormState(
        state,
        UPDATE_CURVE_STANDARD
    ),
});

const mapDispatchToProps = (dispatch: any) => {
    return {
        onCreate: (data: any) => dispatch(actionCreators.fetch(CREATE_CURVE_STANDARD, `/master/curve_standard/add`, 'POST', data,false,true)),
        onUpdate: (data: any) => dispatch(actionCreators.fetch(UPDATE_CURVE_STANDARD, `/master/curve_standard/modify`, 'POST', data,false,true)),
        onSearch: (data: any) => dispatch(actionCreators.fetch(GET_CURVE_STANDARD, `/master/curve_standard/gets`, 'POST', data,false,true)),
        // onSearchCurveHeader: (lmanufactureid: string, lgroupid: string) => dispatch(actionCreators.fetch(GET_CURVE_HEADER, `/api/mv3break_curve_header?lmanufactureid=${lmanufactureid}&lgroupid=${lgroupid}`, "GET", null)),
        onSearchCurveHeader: (data:any) => dispatch(actionCreators.fetch(GET_CURVE_HEADER, `/master/curve_standard/get_by_dial_char_id`, "POST", data,false,true)),

        onSuccess: () =>
            dispatch(actionCreators.showMessage({
                type: 'info',
                title: '曲線基準更新',
                body: '曲線基準を更新しました。',
            })),
        onError: () =>
            dispatch(actionCreators.showMessage({
                type: 'error',
                title: '検索',
                body: '検索できません。',
            })),
        onCreateError: () =>
            dispatch(actionCreators.showMessage({
                type: 'error',
                title: '曲線基準登録',
                body: '曲線基準を登録出来ませんでした。',
            })),
        onUpdateError: () =>
            dispatch(actionCreators.showMessage({
                type: 'error',
                title: '曲線基準編集',
                body: '曲線基準を更新出来ませんでした。',
            })),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CurveStandardEdit as any);


