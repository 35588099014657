import { Percent } from "@mui/icons-material";
import { CALC_IK2, CALC_IK2_OUTSIDE_NEUTRAL, CALC_IK2_OUTSIDE_OUTSIDE, CALC_IK2_SEAT_MAIN, CALC_IK2_SEAT_T, CALC_IK3, MS_PI } from "../models/Constants";
import { CalcPoint, PercentZ } from "../models/Index";
import { VALUE_NODE_VOLT_MIN } from "../statics";
import { doGetValuePerZk2OutIn, doGetValuePerZk2OutOut, doGetValuePerZk2SeatM, doGetValuePerZk2SeatT, doGetValuePerZk3 } from "./PercentZ";
import { printPercentZ } from "./ElementFunction";
import { complexAbs, complexAddComplex, complexDevisionComplex, complexMultiComplex, complexMultiNumber, complexSubtractComplex } from "./Complex";
import { BeamsNumber } from "./FormatNumber";

export const doSetVoltage = (voltage:any,calcPoint:CalcPoint) => {
    calcPoint.voltage = voltage;
}

export const doSetCapacity = (capacity:number,calcPoint:CalcPoint) => {
    calcPoint.stdCapacity = capacity;
}

export const doGetValueDropVoltRegular = (voltage:number,perRk3:number,perXk3:number,calcPoint:CalcPoint) => {
    let percent = doGetValueDropPerRegular(voltage,perRk3,perXk3,calcPoint);
    let voltDrop = Number(voltage) - Number(voltage) * Number(percent);
    if(Math.abs(voltDrop) <VALUE_NODE_VOLT_MIN){
        voltDrop = 0;
    }
    return voltDrop;
}

export const doGetValueDropPerRegular = (voltage:number,perRk3:number,perXk3:number,calcPoint:CalcPoint) => {
    if(calcPoint.powerFactor < 0 || calcPoint.stdCapacity <= 0){
        return 0;
    }
    let capacity = voltage * calcPoint.loadCurrent;
    switch(calcPoint.calcKind){
        case CALC_IK3:
            capacity *= Math.sqrt(3.0);
            break;
        case CALC_IK2:
            capacity *= 2;
            break;
        case CALC_IK2_OUTSIDE_NEUTRAL:
            break;
        case CALC_IK2_OUTSIDE_OUTSIDE:
            capacity *= 2;
            break;
        case CALC_IK2_SEAT_MAIN:
            capacity *= 2;
            break;
        case CALC_IK2_SEAT_T:
            capacity *= 2;
            break;
    }
    let percent = 0;
    if(calcPoint.powerFactor * calcPoint.powerFactor <= 1){
        if(calcPoint.isLead){
            percent = perRk3 * calcPoint.powerFactor - perXk3 * Math.sqrt(1 - calcPoint.powerFactor * calcPoint.powerFactor);
        }else{
            percent = perRk3 * calcPoint.powerFactor + perXk3 * Math.sqrt(1 - calcPoint.powerFactor * calcPoint.powerFactor);
        }
    }
    percent *= capacity;
    percent /= calcPoint.stdCapacity * 1000;
    percent /= 100;
    if(Math.abs(percent) < VALUE_NODE_VOLT_MIN){
        percent = 0;
    }
    return percent;
}

export const IsOkPowerFactor = (calcPoint:CalcPoint) => {
    if(calcPoint.powerFactor > 1 || calcPoint.powerFactor < 0)
        return false;
    return true;
}

export const doGetValueDropLoadCurrent = (voltage:any,loadCapacity:any,calcKind:any) => {
    let loadCurrent = loadCapacity / voltage;
    switch(calcKind){
        case CALC_IK3:
            loadCurrent /= Math.sqrt(3.0);
            break;
        case CALC_IK2:
            break;
        case CALC_IK2_OUTSIDE_NEUTRAL:
            break;
        case CALC_IK2_OUTSIDE_OUTSIDE:
            break;
        case CALC_IK2_SEAT_MAIN:
            break;
        case CALC_IK2_SEAT_T:
            break;
    }
    return loadCurrent;
}

export const doConvertDataCalcPoint = (calcPoint:any, perZDirect:any, perZTotal:any, perZDirectMG:any, perZTotalMG:any, perZMotor:any) => {
    return{calcKind: calcPoint.calc_kind,
    calcPointId: calcPoint.calc_point_id,
    ele3WindingPartKind: calcPoint.ele_3winding_part_kind,
    eleTrans1PartKind: calcPoint.ele_trans1_part_kind,
    eleTranscottPartKind: calcPoint.ele_trans_scott_part_kind,
    isLead: calcPoint.is_lead,
    loadCapacity: calcPoint.load_capacity,
    loadCurrent: calcPoint.load_current,
    pointBottomX: calcPoint.point_bottom_x,
    pointBottomY: calcPoint.point_bottom_y,
    pointTopX: calcPoint.point_top_x,
    pointTopY: calcPoint.point_top_y,
    powerFactor: calcPoint.power_factor,
    stdCapacity: calcPoint.std_capacity,
    voltage: calcPoint.voltage,
    perZDirect : printPercentZ(perZDirect),
    perZTotal : printPercentZ(perZTotal),
    perZDirectMG : printPercentZ(perZDirectMG),
    perZTotalMG : printPercentZ(perZTotalMG),
    perZMotor : printPercentZ(perZMotor),
    }
}

//CObjCalcPoint::DoGetIksymDirectMG
export const doGetIksymDirectMG = (calcPoint:any) => {
    let dIksym = 0;
    switch (calcPoint.calcKind) {
    case CALC_IK3:
        let ret1 = doGetIk3DirectMG(calcPoint);
        dIksym = ret1.pdIsym;
        break;
    case CALC_IK2:
        let ret2 = doGetIk2DirectMG(calcPoint);
        dIksym = ret2.pdIsym;
        break;
    case CALC_IK2_OUTSIDE_NEUTRAL:
        let ret3 = doGetIk2DirectOutInMG(calcPoint);
        dIksym = ret3.pdIsym;
        break;
    case CALC_IK2_OUTSIDE_OUTSIDE:
        let ret4 = doGetIk2DirectOutOutMG(calcPoint);
        dIksym = ret4.pdIsym;
        break;
    case CALC_IK2_SEAT_MAIN:
        let ret5 = doGetIk2DirectSeatMMG(calcPoint);
        dIksym = ret5.pdIsym;
        break;
    case CALC_IK2_SEAT_T:
        let ret6 = doGetIk2DirectSeatTMG(calcPoint);
        dIksym = ret6.pdIsym;
        break;
    }
    return dIksym;
}

//CObjCalcPoint::DoGetIkpeakDirectMG
export const doGetIkpeakDirectMG = (calcPoint:any) => {
    let dIkpeak = 0;
    switch (calcPoint.calcKind) {
    case CALC_IK3:
        let ret1 = doGetIk3DirectMG(calcPoint);
        dIkpeak = ret1.pdIpeak;
        break;
    case CALC_IK2:
        let ret2 = doGetIk2DirectMG(calcPoint);
        dIkpeak = ret2.pdIpeak;
        break;
    case CALC_IK2_OUTSIDE_NEUTRAL:
        let ret3 = doGetIk2DirectOutInMG(calcPoint);
        dIkpeak = ret3.pdIpeak;
        break;
    case CALC_IK2_OUTSIDE_OUTSIDE:
        let ret4 = doGetIk2DirectOutOutMG(calcPoint);
        dIkpeak = ret4.pdIpeak;
        break;
    case CALC_IK2_SEAT_MAIN:
        let ret5 = doGetIk2DirectSeatMMG(calcPoint);
        dIkpeak = ret5.pdIpeak;
        break;
    case CALC_IK2_SEAT_T:
        let ret6 = doGetIk2DirectSeatTMG(calcPoint);
        dIkpeak = ret6.pdIpeak;
        break;
    }
    return dIkpeak;
}

//CObjCalcPoint::DoGetIkpeakDirectMG
export const doGetIkasymDirectMG = (calcPoint:any) => {
    let dIkasym = 0;
    switch (calcPoint.calcKind) {
    case CALC_IK3:
        let ret1 = doGetIk3DirectMG(calcPoint);
        dIkasym = ret1.pdIasym;
        break;
    case CALC_IK2:
        let ret2 = doGetIk2DirectMG(calcPoint);
        dIkasym = ret2.pdIasym;
        break;
    case CALC_IK2_OUTSIDE_NEUTRAL:
        let ret3 = doGetIk2DirectOutInMG(calcPoint);
        dIkasym = ret3.pdIasym;
        break;
    case CALC_IK2_OUTSIDE_OUTSIDE:
        let ret4 = doGetIk2DirectOutOutMG(calcPoint);
        dIkasym = ret4.pdIasym;
        break;
    case CALC_IK2_SEAT_MAIN:
        let ret5 = doGetIk2DirectSeatMMG(calcPoint);
        dIkasym = ret5.pdIasym;
        break;
    case CALC_IK2_SEAT_T:
        let ret6 = doGetIk2DirectSeatTMG(calcPoint);
        dIkasym = ret6.pdIasym;
        break;
    }
    return dIkasym;
}

//CObjCalcPoint::DoGetIk3DirectMG
export const doGetIk3DirectMG = (calcPoint:any) => {
    let percentZ = calcPoint.perZDirectMG;
    if (percentZ.calcFailed) {
        return {
            pdIsym: -1,
            pdIasym: -1,
            pdIpeak: -1
        };
    }
    let dPercentZ = doGetValuePerZk3(percentZ).dPercentZ;
    let dIs = doGetValueIksym(calcPoint, dPercentZ);
    let dIa = doGetValueIkasym(calcPoint, percentZ.perRk3, percentZ.perXk3, dIs);
    let dIp = doGetValueIkpeak(calcPoint, percentZ.perRk3, percentZ.perXk3, dIs);
    
    return {
        pdIsym: dIs,
        pdIasym: dIa,
        pdIpeak: dIp
    };
}

//CObjCalcPoint::DoGetIk2DirectMG
export const doGetIk2DirectMG = (calcPoint:any) => {
    let percentZ = calcPoint.perZDirectMG;
    if (percentZ.calcFailed) {
        return {
            pdIsym: -1,
            pdIasym: -1,
            pdIpeak: -1
        };
    }
    let dPercentZ = doGetValuePerZk3(percentZ).dPercentZ;
    let dIs = doGetValueIksym(calcPoint, dPercentZ);
    let dIa = doGetValueIkasym(calcPoint, percentZ.perRk3, percentZ.perXk3, dIs);
    let dIp = doGetValueIkpeak(calcPoint, percentZ.perRk3, percentZ.perXk3, dIs);

    dIs *= Math.sqrt(3) / 2; 
    dIa *= Math.sqrt(3) / 2;
    dIp *= Math.sqrt(3) / 2;

    return {
        pdIsym : dIs, 
        pdIasym : dIa, 
        pdIpeak : dIp
    };
}

//CObjCalcPoint::DoGetIk2DirectOutInMG
export const doGetIk2DirectOutInMG = (calcPoint:any) => {
    let percentZ = calcPoint.perZDirectMG;
    if (percentZ.calcFailed) {
        return {
            pdIsym: -1,
            pdIasym: -1,
            pdIpeak: -1
        };
    }

    let retOutIn = doGetValuePerZk2OutIn(percentZ);

    let dIs = doGetValueIksym(calcPoint, retOutIn.dPercentZ);
    let dIa = doGetValueIkasym(calcPoint, retOutIn.pdPerRk3, retOutIn.pdPerXk3, dIs);
    let dIp = doGetValueIkpeak(calcPoint, retOutIn.pdPerRk3, retOutIn.pdPerXk3, dIs);

    return {
        pdIsym: dIs,
        pdIasym: dIa,
        pdIpeak: dIp
    };
}

//CObjCalcPoint::DoGetIk2DirectOutOutMG
export const doGetIk2DirectOutOutMG = (calcPoint:any) => {

    let percentZ = calcPoint.perZDirectMG;
    if (percentZ.calcFailed) {
        return {
            pdIsym: -1,
            pdIasym: -1,
            pdIpeak: -1
        };
    }

    let dPercentZ = doGetValuePerZk2OutOut(percentZ).dPercentZ;

    let dIs = doGetValueIksym(calcPoint, dPercentZ);
    let dIa = doGetValueIkasym(calcPoint, percentZ.perRk3, percentZ.perXk3, dIs);
    let dIp = doGetValueIkpeak(calcPoint, percentZ.perRk3, percentZ.perXk3, dIs);

    return {
        pdIsym: dIs,
        pdIasym: dIa,
        pdIpeak: dIp
    };
}

//CObjCalcPoint::DoGetIk2DirectSeatMMG
export const doGetIk2DirectSeatMMG = (calcPoint:any) => {

    let percentZ = calcPoint.perZDirectMG;
    if (percentZ.calcFailed) {
        return {
            pdIsym: -1,
            pdIasym: -1,
            pdIpeak: -1
        };
    }
    
    let retSeatM = doGetValuePerZk2SeatM(percentZ);

    let dIs = doGetValueIksym(calcPoint, retSeatM.dPercentZ);
    let dIa = doGetValueIkasym(calcPoint, retSeatM.pdPerRk3, retSeatM.pdPerXk3, dIs);
    let dIp = doGetValueIkpeak(calcPoint, retSeatM.pdPerRk3, retSeatM.pdPerXk3, dIs);

    dIs *= Math.sqrt(3) / 2;
    dIa *= Math.sqrt(3) / 2;
    dIp *= Math.sqrt(3) / 2;

    return {
        pdIsym: dIs,
        pdIasym: dIa,
        pdIpeak: dIp 
    };
}

//CObjCalcPoint::DoGetIk2DirectSeatTMG
export const doGetIk2DirectSeatTMG = (calcPoint:any) => {
    let percentZ = calcPoint.perZDirectMG;
    if (percentZ.calcFailed) {
        return {
            pdIsym: -1,
            pdIasym: -1,
            pdIpeak: -1
        };
    }

    let retSeatT = doGetValuePerZk2SeatT(percentZ);

    let dIs = doGetValueIksym(calcPoint, retSeatT.dPercentZ);
    let dd = doGetConvertValueIk3(percentZ);
    if (dd > 1) {
        dIs *= dd;
    }
    dIs *= Math.sqrt(3) / 2;
    let dIa = doGetValueIkasym(calcPoint, retSeatT.pdPerRk3, retSeatT.pdPerXk3, dIs);
    let dIp = doGetValueIkpeak(calcPoint, retSeatT.pdPerRk3, retSeatT.pdPerXk3, dIs);
    
    return {
        pdIsym: dIs,
        pdIasym: dIa,
        pdIpeak: dIp
    };
}

//CObjCalcPoint::DoGetValueIksym
export const doGetValueIksym = (calcPoint:any, dPercentZ:any) => {
    if (dPercentZ == 0) {
        return 0;
    }

    let dIsym = 0;
    switch (calcPoint.calcKind) {
        case CALC_IK3:
        case CALC_IK2:
        case CALC_IK2_SEAT_MAIN:
        case CALC_IK2_SEAT_T:
            dIsym = calcPoint.stdCapacity * 1000 * 100 /(Math.sqrt(3) * doGetValueVoltage(calcPoint) * dPercentZ);
            break;
        case CALC_IK2_OUTSIDE_NEUTRAL:
            dIsym = calcPoint.stdCapacity * 1000 * 100 /(doGetValueVoltage(calcPoint) * dPercentZ);
            break;
        case CALC_IK2_OUTSIDE_OUTSIDE:
            dIsym = calcPoint.stdCapacity * 1000 * 100 /(doGetValueVoltage(calcPoint) * dPercentZ);
            break;
        }
    return dIsym;
}

//CObjCalcPoint::DoGetValueIkasym
export const doGetValueIkasym = (calcPoint:any, dPercentR: any, dPercentX: any, dIsym: any) => {
    let dIasym = 0;
    let dConstant1 = 0;
    let dConstant2 = 0;

    switch (calcPoint.calcKind) {
        case CALC_IK3:
        case CALC_IK2:
        case CALC_IK2_SEAT_MAIN:
        case CALC_IK2_SEAT_T:
            if (dPercentX != 0) {
                dConstant1 = Math.sqrt(1 + 2 * Math.exp(-1 * 2 * MS_PI * dPercentR / dPercentX));
            }
            if (dPercentX != 0) {
                dConstant2 = Math.sqrt(1 + Math.exp(-1 * 2 * MS_PI * dPercentR / dPercentX) / 2);
            }
            let dK3 = (dConstant1 + 2 * dConstant2) / 3;
            dIasym = dIsym *dK3;
            break;
        case CALC_IK2_OUTSIDE_NEUTRAL:
        case CALC_IK2_OUTSIDE_OUTSIDE:
            if (dPercentX != 0) {
                dConstant1 = Math.sqrt(1 + 2 * Math.exp(-1 * 2 * MS_PI * dPercentR/ dPercentX));
            }
            dIasym = dIsym * dConstant1;
            break;
    }
    return dIasym;
}

//CObjCalcPoint::DoGetValueIkpeak
export const doGetValueIkpeak = (calcPoint:any, dPercentR: any, dPercentX: any, dIsym: any) => {
    if (dPercentX == 0) {
        return 0;
    }
    let dIpeak = 0;
    switch (calcPoint.calcKind) {
        case CALC_IK3:
        case CALC_IK2:
        case CALC_IK2_SEAT_MAIN:
        case CALC_IK2_SEAT_T:
            if (dPercentX != 0) {
                dIpeak = dIsym * Math.sqrt(2) * (1 + Math.exp(-1 * (MS_PI * dPercentR / dPercentX)));
            }
            break;
        case CALC_IK2_OUTSIDE_NEUTRAL:
        case CALC_IK2_OUTSIDE_OUTSIDE:
            if (dPercentX != 0) {
                dIpeak = dIsym * Math.sqrt(2) * (1 + Math.exp(-1 * (MS_PI * dPercentR / dPercentX)));
            }
            break;
    }
    return dIpeak;
}

//CObjCalcPoint::DoGetValueVoltage
export const doGetValueVoltage = (calcPoint:any) => {
    let dVoltage = 0;
    switch (calcPoint.calcKind) {
        case CALC_IK3:
        case CALC_IK2:
        case CALC_IK2_SEAT_MAIN:
        case CALC_IK2_SEAT_T:
            dVoltage = calcPoint.voltage;
            break;
        case CALC_IK2_OUTSIDE_NEUTRAL:
            dVoltage = calcPoint.voltage / 2;
            break;
        case CALC_IK2_OUTSIDE_OUTSIDE:
            dVoltage = calcPoint.voltage;
            break;
        }
    return dVoltage;
}

//CObjCalcPoint::DoGetConvertValueIk3
export const doGetConvertValueIk3 = (pPerZMG:any) => {
    let retOutIn = doGetValuePerZk2OutIn(pPerZMG);
    let perZSeatM = {
        perRk3: retOutIn.pdPerRk3,
        perXk3: retOutIn.pdPerXk3
    };
    perZSeatM.perRk3 -= pPerZMG.perRk3Source;
    perZSeatM.perXk3 -= pPerZMG.perXk3Source;
    
    let perZSeatT = {
        perRk3: pPerZMG.perRk3 - pPerZMG.perRk3Source,
        perXk3: pPerZMG.perXk3 - pPerZMG.perXk3Source
    };

    let cCZt = {
        re: perZSeatT.perRk3,
        im: perZSeatT.perXk3
    };

    let cCZs = {
        re: pPerZMG.perRk3Source,
        im: pPerZMG.perXk3Source
    }

    let cCTemporary = {
        re: 0.5,
        im: 0.5 / Math.sqrt(3)
    };

    let cCTemporary1 = complexAddComplex(complexMultiNumber(3, cCZs), complexMultiNumber(2, cCZt));
    cCTemporary1.re += 0.5 * perZSeatM.perRk3;
    cCTemporary = complexMultiComplex(cCTemporary, cCTemporary1);
    cCTemporary = complexSubtractComplex(cCTemporary, complexAddComplex(cCZs, cCZt))
    cCTemporary1 = cCZs;
    cCTemporary1.re += 0.5 * perZSeatM.perRk3;

    let dd = complexAbs(complexDevisionComplex(cCTemporary, cCTemporary1));
    
    return dd;
}

//CObjCalcPoint::DoGetIksymTotalMG
export const doGetIksymTotalMG = (calcPoint:any) => {
    let dIksym = 0;
    switch (calcPoint.calcKind) {
    case CALC_IK3:
        let ret1 = doGetIk3TotalMG(calcPoint);
        dIksym = ret1.pdIsym;
        break;
    case CALC_IK2:
        let ret2 = doGetIk2TotalMG(calcPoint);
        dIksym = ret2.pdIsym;
        break;
    case CALC_IK2_OUTSIDE_NEUTRAL:
        let ret3 = doGetIk2TotalOutInMG(calcPoint);
        dIksym = ret3.pdIsym;
        break;
    case CALC_IK2_OUTSIDE_OUTSIDE:
        let ret4 = doGetIk2TotalOutOutMG(calcPoint);
        dIksym = ret4.pdIsym;
        break;
    case CALC_IK2_SEAT_MAIN:
        let ret5 = doGetIk2TotalSeatMMG(calcPoint);
        dIksym = ret5.pdIsym;
        break;
    case CALC_IK2_SEAT_T:
        let ret6 = doGetIk2TotalSeatTMG(calcPoint);
        dIksym = ret6.pdIsym;
        break;
    }
    return dIksym;
}

//CObjCalcPoint::DoGetIk3TotalMG
export const doGetIk3TotalMG = (calcPoint:any) => {
    let percentZ = calcPoint.perZTotalMG;
    if (percentZ.calcFailed) {
        return {
            pdIsym: -1,
            pdIasym: -1,
            pdIpeak: -1
        };
    }

    let dPercentZ = doGetValuePerZk3(percentZ).dPercentZ;
    let dIs = doGetValueIksym(calcPoint, dPercentZ);
    let dIa = doGetValueIkasym(calcPoint, percentZ.perRk3, percentZ.perXk3, dIs);
    let dIp = doGetValueIkpeak(calcPoint, percentZ.perRk3, percentZ.perXk3, dIs);

    return {
        pdIsym: dIs,
        pdIasym: dIa,
        pdIpeak: dIp
    };
}

//CObjCalcPoint::DoGetIk2TotalMG
export const doGetIk2TotalMG = (calcPoint:any) => {
    let percentZ = calcPoint.perZTotalMG;
    if (percentZ.calcFailed) {
        return {
            pdIsym: -1,
            pdIasym: -1,
            pdIpeak: -1
        };
    }
    let dPercentZ = doGetValuePerZk3(percentZ).dPercentZ;
    let dIs = doGetValueIksym(calcPoint, dPercentZ);
    let dIa = doGetValueIkasym(calcPoint, percentZ.perRk3, percentZ.perXk3, dIs);
    let dIp = doGetValueIkpeak(calcPoint, percentZ.perRk3, percentZ.perXk3, dIs);

    dIs *= Math.sqrt(3) / 2; 
    dIa *= Math.sqrt(3) / 2;
    dIp *= Math.sqrt(3) / 2;

    return {
        pdIsym : dIs, 
        pdIasym : dIa, 
        pdIpeak : dIp
    };
}

//CObjCalcPoint::DoGetIk2TotalOutInMG
export const doGetIk2TotalOutInMG = (calcPoint:any) => {
    let percentZ = calcPoint.perZTotalMG;
    if (percentZ.calcFailed) {
        return {
            pdIsym: -1,
            pdIasym: -1,
            pdIpeak: -1
        };
    }

    let retOutIn = doGetValuePerZk2OutIn(percentZ);

    let dIs = doGetValueIksym(calcPoint, retOutIn.dPercentZ);
    let dIa = doGetValueIkasym(calcPoint, retOutIn.pdPerRk3, retOutIn.pdPerXk3, dIs);
    let dIp = doGetValueIkpeak(calcPoint, retOutIn.pdPerRk3, retOutIn.pdPerXk3, dIs);

    return {
        pdIsym: dIs,
        pdIasym: dIa,
        pdIpeak: dIp
    };
}

//CObjCalcPoint::DoGetIk2TotalOutOutMG
export const doGetIk2TotalOutOutMG = (calcPoint:any) => {

    let percentZ = calcPoint.perZTotalMG;
    if (percentZ.calcFailed) {
        return {
            pdIsym: -1,
            pdIasym: -1,
            pdIpeak: -1
        };
    }

    let dPercentZ = doGetValuePerZk2OutOut(percentZ).dPercentZ;

    let dIs = doGetValueIksym(calcPoint, dPercentZ);
    let dIa = doGetValueIkasym(calcPoint, percentZ.perRk3, percentZ.perXk3, dIs);
    let dIp = doGetValueIkpeak(calcPoint, percentZ.perRk3, percentZ.perXk3, dIs);

    return {
        pdIsym: dIs,
        pdIasym: dIa,
        pdIpeak: dIp
    };
}

//CObjCalcPoint::DoGetIk2TotalSeatMMG
export const doGetIk2TotalSeatMMG = (calcPoint:any) => {

    let percentZ = calcPoint.perZTotalMG;
    if (percentZ.calcFailed) {
        return {
            pdIsym: -1,
            pdIasym: -1,
            pdIpeak: -1
        };
    }
    
    let retSeatM = doGetValuePerZk2SeatM(percentZ);

    let dIs = doGetValueIksym(calcPoint, retSeatM.dPercentZ);
    let dIa = doGetValueIkasym(calcPoint, retSeatM.pdPerRk3, retSeatM.pdPerXk3, dIs);
    let dIp = doGetValueIkpeak(calcPoint, retSeatM.pdPerRk3, retSeatM.pdPerXk3, dIs);

    dIs *= Math.sqrt(3) / 2;
    dIa *= Math.sqrt(3) / 2;
    dIp *= Math.sqrt(3) / 2;

    return {
        pdIsym: dIs,
        pdIasym: dIa,
        pdIpeak: dIp 
    };
}

//CObjCalcPoint::DoGetIk2TotalSeatTMG
export const doGetIk2TotalSeatTMG = (calcPoint:any) => {
    let percentZ = calcPoint.perZTotalMG;
    if (percentZ.calcFailed) {
        return {
            pdIsym: -1,
            pdIasym: -1,
            pdIpeak: -1
        };
    }

    let retSeatT = doGetValuePerZk2SeatT(percentZ);

    let dIs = doGetValueIksym(calcPoint, retSeatT.dPercentZ);
    let dd = doGetConvertValueIk3(percentZ);
    if (dd > 1) {
        dIs *= dd;
    }
    dIs *= Math.sqrt(3) / 2;
    let dIa = doGetValueIkasym(calcPoint, retSeatT.pdPerRk3, retSeatT.pdPerXk3, dIs);
    let dIp = doGetValueIkpeak(calcPoint, retSeatT.pdPerRk3, retSeatT.pdPerXk3, dIs);
    
    return {
        pdIsym: dIs,
        pdIasym: dIa,
        pdIpeak: dIp
    };
}

//CObjCalcPoint::IsSetDropPerMotor
export const isSetDropPerMotor = (calcPoint:any) => {
    let dPercent = doGetValuePerZk3(calcPoint.perZMotor).dPercentZ;
    if (dPercent > 0) {
        return true;
    }
    return false;
}

//CObjCalcPoint::DoGetTextDropVoltMotor
export const doGetTextDropVoltMotor = (calcPoint:any, bUnit: boolean = true) => {
    let dPercent = doGetValueDropPerMotor(calcPoint);
    let dDropVolt = doGetValueVoltage(calcPoint) * dPercent;

    let sText = BeamsNumber(dDropVolt, 4).toString();
    if (bUnit) {
        sText += 'V';
    }
    return sText;
}

//CObjCalcPoint::DoGetTextDropVoltMotor2
export const doGetTextDropVoltMotor2 = (calcPoint:any, bUnit: boolean = true) => {
    let dPercent = doGetValueDropPerMotor(calcPoint);
    let dDropVolt = doGetValueVoltage(calcPoint) * (1 - dPercent);

    let sText = BeamsNumber(dDropVolt, 5).toString();
    if (bUnit) {
        sText += 'V';
    }
    return sText;
}

//CObjCalcPoint::DoGetTextDropPerMotor
export const doGetTextDropPerMotor = (calcPoint:any, bUnit: boolean = true) => {
    let dPercent = doGetValueDropPerMotor(calcPoint);
    let sText = BeamsNumber(dPercent * 100, 4).toString();
    if (bUnit) {
        sText += '%';
    }
    return sText;
}

//CObjCalcPoint::DoGetTextDropVoltRegular
export const doGetTextDropVoltRegular = (calcPoint:any, dVoltage: number, dPerRk3: number, dPerXk3: number, bUnit: boolean = true) => {
    let dDropVolt = doGetValueDropVoltRegular(dVoltage, dPerRk3, dPerXk3, calcPoint);

    let sText = BeamsNumber(dDropVolt, 5).toString();
    if (bUnit) {
        sText += 'V';
    }
    return sText;
}

//CObjCalcPoint::DoGetTextDropPerRegular
export const doGetTextDropPerRegular = (calcPoint:any, dVoltage: number, dPerRk3: number, dPerXk3: number, bUnit: boolean = true) => {
    let dDropVolt = doGetValueDropVoltRegular(dVoltage, dPerRk3, dPerXk3, calcPoint);
    dDropVolt = dDropVolt * 100 / doGetValueVoltage(calcPoint);
    if (dDropVolt > 0) {
        dDropVolt = 100 - dDropVolt;
    }

    let sText = BeamsNumber(dDropVolt, 4).toString();
    if (bUnit) {
        sText += '%';
    }
    return sText;
}

//CObjCalcPoint::DoGetTextDropPerRegular2
export const doGetTextDropPerRegular2 = (calcPoint:any, dVoltage: number, dPerRk3: number, dPerXk3: number, bUnit: boolean = true) => {
    let dDropVolt = doGetValueDropVoltRegular(dVoltage, dPerRk3, dPerXk3, calcPoint);
    dDropVolt = dDropVolt * 100 / doGetValueVoltage(calcPoint);

    let sText = BeamsNumber(dDropVolt, 4).toString();
    if (bUnit) {
        sText += '%';
    }
    return sText;
}

//CObjCalcPoint::DoGetValueDropPerMotor
export const doGetValueDropPerMotor = (calcPoint:any, bUnit: boolean = true) => {
    let perZMotor = calcPoint.perZMotor;
    let perZTotal = calcPoint.perZTotal;

    let dVolt2 = Math.sqrt(Math.pow(perZMotor.perRk3, 2) + Math.pow(perZMotor.perXk3, 2));
    let dVolt1 = Math.sqrt(Math.pow(perZMotor.perRk3 + perZTotal.perRk3, 2) + Math.pow(perZMotor.perXk3 + perZTotal.perXk3, 2));

    if (dVolt1 == 0) {
        return 0
    }

    let dPercent = 1 - dVolt2 / dVolt1;

    return dPercent;
}

//CObjCalcPoint::DoGetPerZSourceDirectMG
export const doGetPerZSourceDirectMG = (calcPoint:any) =>
{
    let resultData = {
        perRk3Source : calcPoint.perZDirectMG.perRk3Source,
        perXk3Source : calcPoint.perZDirectMG.perXk3Source
    }

    return resultData;
}

//CObjCalcPoint::DoGetTextDirectPeakCoefficient
export const doGetTextDirectPeakCoefficient = (calcPoint:CalcPoint) =>
{
	let sText = "0";
	let dPercentR = calcPoint.perZDirectMG.perRk3;
	let dPercentX = calcPoint.perZDirectMG.perXk3;
	let dIpeakCoefficient = 0;

	switch (calcPoint.calcKind) {
	case CALC_IK3:					// 三相三線短絡（電圧線間）
	case CALC_IK2:					// 三相二線短絡（電圧線間）
	case CALC_IK2_SEAT_MAIN:		// 単相二線短絡（主座変圧器）
	case CALC_IK2_SEAT_T:			// 単相二線短絡（Ｔ座変圧器）
		if (dPercentX != 0) {
			dIpeakCoefficient = Math.sqrt(2.0) * (1 + Math.exp(-1 * (MS_PI * dPercentR / dPercentX)));
		}
		break;
	case CALC_IK2_OUTSIDE_OUTSIDE:	// 単相二線短絡（外線間）
		if (dPercentX != 0) {
			dIpeakCoefficient = Math.sqrt(2.0) * (1 + Math.exp(-1 * (MS_PI * dPercentR / dPercentX)));
		}
		break;
	case CALC_IK2_OUTSIDE_NEUTRAL:	// 単相二線短絡（外線－中性線間）
		{
			let percentZ = calcPoint.perZDirectMG;
			dPercentR = doGetValuePerZk2OutIn(percentZ).pdPerRk3;
			dPercentX = doGetValuePerZk2OutIn(percentZ).pdPerXk3;
				// 通過の合成％Ｚを計算する
			if (dPercentX != 0) {
				dIpeakCoefficient = Math.sqrt(2.0) * (1 + Math.exp(-1 * (MS_PI * dPercentR / dPercentX)));
			}
		}
		break;
	}
	sText = BeamsNumber(dIpeakCoefficient, 4).toString();

	return sText;
}

//CObjCalcPoint::DoGetTextDirectAsymCoefficient
export const doGetTextDirectAsymCoefficient = (calcPoint: CalcPoint) => 
{
	let sText = "0";
	let dPercentR = calcPoint.perZDirectMG.perRk3;
	let dPercentX = calcPoint.perZDirectMG.perXk3;
	let dIasymCoefficient = 0;

	switch (calcPoint.calcKind) {
	case CALC_IK3:					// 三相三線短絡（電圧線間）
	case CALC_IK2:					// 三相二線短絡（電圧線間）
	case CALC_IK2_SEAT_MAIN:		// 単相二線短絡（主座変圧器）
	case CALC_IK2_SEAT_T:			// 単相二線短絡（Ｔ座変圧器）
		{
			let dConstant1 = 0;
			if (dPercentX != 0) {
				dConstant1 = Math.sqrt(1 + 2 * Math.exp(-1 * 2 * MS_PI * dPercentR / dPercentX));
			}
			let dConstant2 = 0;
			if (dPercentX != 0) {
				dConstant2 = Math.sqrt(1 + Math.exp(-1 * 2 * MS_PI * dPercentR / dPercentX) / 2);
			}
			dIasymCoefficient = (dConstant1 + 2 * dConstant2) / 3;
		}
		break;
	case CALC_IK2_OUTSIDE_OUTSIDE:	// 単相二線短絡（外線間）
		{
			if (dPercentX != 0) {
				dIasymCoefficient = Math.sqrt(1 + 2 * Math.exp(-1 * 2 * MS_PI * dPercentR / dPercentX));
			}
		}
		break;
	case CALC_IK2_OUTSIDE_NEUTRAL:	// 単相二線短絡（外線－中性線間）
		{
			let percentZ = calcPoint.perZDirectMG;
			dPercentR = doGetValuePerZk2OutIn(percentZ).pdPerRk3;
			dPercentX = doGetValuePerZk2OutIn(percentZ).pdPerXk3;
			// 通過の合成％Ｚを計算する
			if (dPercentX != 0) {
				dIasymCoefficient = Math.sqrt(1 + 2 * Math.exp(-1 * 2 * MS_PI * dPercentR / dPercentX));
			}
		}
		break;
	}
	sText = BeamsNumber(dIasymCoefficient, 4).toString();

	return sText;
}

//CObjCalcPoint::DoGetPerZDirectMG
export const doGetPerZDirectMG = (calcPoint: CalcPoint, pdPerR: number = 0, pdPerX: number = 0) =>
{
    let result = {
        pdPerRk3 : pdPerR,
        pdPerXk3 : pdPerX,
        dPercentZ : 0
    }

	switch (calcPoint.calcKind) {
	case CALC_IK3:					// 三相三線短絡（電圧線間）
        result = doGetValuePerZk3(calcPoint.perZDirectMG);		// 通過の合成％Ｚを計算する
		break;
	case CALC_IK2:					// 三相二線短絡（電圧線間）
        result = doGetValuePerZk3(calcPoint.perZDirectMG);		// 通過の合成％Ｚを計算する
		break;
	case CALC_IK2_OUTSIDE_NEUTRAL:	// 単相二線短絡（外線－中性線間）
        result = doGetValuePerZk2OutIn(calcPoint.perZDirectMG);	// 通過の合成％Ｚを計算する
		break;
	case CALC_IK2_OUTSIDE_OUTSIDE:	// 単相二線短絡（外線間）
        result = doGetValuePerZk2OutOut(calcPoint.perZDirectMG);	// 通過の合成％Ｚを計算する
		break;
	case CALC_IK2_SEAT_MAIN:		// 単相二線短絡（主座変圧器）
        result = doGetValuePerZk2SeatM(calcPoint.perZDirectMG);	// 主座用の通過の合成％Ｚを計算する
		break;
	case CALC_IK2_SEAT_T:			// 単相二線短絡（Ｔ座変圧器）
        result = doGetValuePerZk2SeatT(calcPoint.perZDirectMG);	// Ｔ座用通過の合成％Ｚを計算する
		break;
	}

	return result;
}

//CObjCalcPoint::DoGetTextTotalPeakCoefficient
export const doGetTextTotalPeakCoefficient = (calcPoint: CalcPoint) =>
{
	let sText = "0";
	let dPercentR = calcPoint.perZTotalMG.perRk3;
	let dPercentX = calcPoint.perZTotalMG.perXk3;
	let dIpeakCoefficient = 0;

	switch (calcPoint.calcKind) {
	case CALC_IK3:					// 三相三線短絡（電圧線間）
	case CALC_IK2:					// 三相二線短絡（電圧線間）
	case CALC_IK2_SEAT_MAIN:		// 単相二線短絡（主座変圧器）
	case CALC_IK2_SEAT_T:			// 単相二線短絡（Ｔ座変圧器）
		if (dPercentX != 0) {
			dIpeakCoefficient = Math.sqrt(2.0) * (1 + Math.exp(-1 * (MS_PI * dPercentR / dPercentX)));
		}
		break;
	case CALC_IK2_OUTSIDE_OUTSIDE:	// 単相二線短絡（外線間）
		if (dPercentX != 0) {
			dIpeakCoefficient = Math.sqrt(2.0) * (1 + Math.exp(-1 * (MS_PI * dPercentR / dPercentX)));
		}
		break;
	case CALC_IK2_OUTSIDE_NEUTRAL:	// 単相二線短絡（外線－中性線間）
		{
			let percentZ = calcPoint.perZTotalMG;
			dPercentR = doGetValuePerZk2OutIn(percentZ).pdPerRk3;
			dPercentX = doGetValuePerZk2OutIn(percentZ).pdPerXk3;
				// 通過の合成％Ｚを計算する
			if (dPercentX != 0) {
				dIpeakCoefficient = Math.sqrt(2.0) * (1 + Math.exp(-1 * (MS_PI * dPercentR / dPercentX)));
			}
		}
		break;
	}
	sText = BeamsNumber(dIpeakCoefficient, 4).toString();

	return sText;
}

//CObjCalcPoint::DoGetTextTotalAsymCoefficient
export const doGetTextTotalAsymCoefficient = (calcPoint:CalcPoint) =>
{
	let sText = "0";
	let dPercentR = calcPoint.perZTotalMG.perRk3;
	let dPercentX = calcPoint.perZTotalMG.perXk3;
	let dIasymCoefficient = 0;

	switch (calcPoint.calcKind) {
	case CALC_IK3:					// 三相三線短絡（電圧線間）
	case CALC_IK2:					// 三相二線短絡（電圧線間）
	case CALC_IK2_SEAT_MAIN:		// 単相二線短絡（主座変圧器）
	case CALC_IK2_SEAT_T:			// 単相二線短絡（Ｔ座変圧器）
		{
			let dConstant1 = 0;
			if (dPercentX != 0) {
				dConstant1 = Math.sqrt(1 + 2 * Math.exp(-1 * 2 * MS_PI * dPercentR / dPercentX));
			}
			let dConstant2 = 0;
			if (dPercentX != 0) {
				dConstant2 = Math.sqrt(1 + Math.exp(-1 * 2 * MS_PI * dPercentR / dPercentX) / 2);
			}
			dIasymCoefficient = (dConstant1 + 2 * dConstant2) / 3;
		}
		break;
	case CALC_IK2_OUTSIDE_OUTSIDE:	// 単相二線短絡（外線間）
		{
			if (dPercentX != 0) {
				dIasymCoefficient = Math.sqrt(1 + 2 * Math.exp(-1 * 2 * MS_PI * dPercentR / dPercentX));
			}
		}
		break;
	case CALC_IK2_OUTSIDE_NEUTRAL:	// 単相二線短絡（外線－中性線間）
		{
			let percentZ = calcPoint.perZTotalMG;
			dPercentR = doGetValuePerZk2OutIn(percentZ).pdPerRk3;
			dPercentX = doGetValuePerZk2OutIn(percentZ).pdPerXk3;
				// 通過の合成％Ｚを計算する
			if (dPercentX != 0) {
				dIasymCoefficient = Math.sqrt(1 + 2 * Math.exp(-1 * 2 * MS_PI * dPercentR / dPercentX));
			}
		}
		break;
	}
	sText = BeamsNumber(dIasymCoefficient, 4).toString();

	return sText;
}

//CObjCalcPoint::DoGetIkpeakTotalMG
export const doGetIkpeakTotalMG = (calcPoint:CalcPoint) =>
{
	let dIkpeak = 0
	switch (calcPoint.calcKind) {
	case CALC_IK3:					// 三相三線短絡（電圧線間）
        dIkpeak = doGetIk3TotalMG(calcPoint).pdIpeak;
		break;
	case CALC_IK2:					// 三相二線短絡（電圧線間）
        dIkpeak = doGetIk2TotalMG(calcPoint).pdIpeak;
		break;
	case CALC_IK2_OUTSIDE_NEUTRAL:	// 単相二線短絡（外線－中性線間）
        dIkpeak = doGetIk2TotalOutInMG(calcPoint).pdIpeak;
		break;
	case CALC_IK2_OUTSIDE_OUTSIDE:	// 単相二線短絡（外線間）
        dIkpeak = doGetIk2TotalOutOutMG(calcPoint).pdIpeak;
		break;
	case CALC_IK2_SEAT_MAIN:		// 単相二線短絡（主座変圧器）
        dIkpeak = doGetIk2TotalSeatMMG(calcPoint).pdIpeak;
		break;
	case CALC_IK2_SEAT_T:			// 単相二線短絡（Ｔ座変圧器）
	    dIkpeak = doGetIk2TotalSeatTMG(calcPoint).pdIpeak;
		break;
	}
	return dIkpeak;
}

//CObjCalcPoint::DoGetIkasymTotalMG
export const doGetIkasymTotalMG = (calcPoint:CalcPoint) =>
{
	let dIkasym = 0;
	switch (calcPoint.calcKind) {
	case CALC_IK3:					// 三相三線短絡（電圧線間）
        dIkasym = doGetIk3TotalMG(calcPoint).pdIasym;
		break;
	case CALC_IK2:					// 三相二線短絡（電圧線間）
        dIkasym = doGetIk2TotalMG(calcPoint).pdIasym;
		break;
	case CALC_IK2_OUTSIDE_NEUTRAL:	// 単相二線短絡（外線－中性線間）
        dIkasym = doGetIk2TotalOutInMG(calcPoint).pdIasym;
		break;
	case CALC_IK2_OUTSIDE_OUTSIDE:	// 単相二線短絡（外線間）
        dIkasym = doGetIk2TotalOutOutMG(calcPoint).pdIasym;
		break;
	case CALC_IK2_SEAT_MAIN:		// 単相二線短絡（主座変圧器）
        dIkasym = doGetIk2TotalSeatMMG(calcPoint).pdIasym;
		break;
	case CALC_IK2_SEAT_T:			// 単相二線短絡（Ｔ座変圧器）
        dIkasym = doGetIk2TotalSeatTMG(calcPoint).pdIasym;
		break;
	}
	return dIkasym;
}

//CObjCalcPoint::DoGetPerZTotalMG
export const doGetPerZTotalMG = (calcPoint:CalcPoint) =>
{
    let result ={
        pdPerRk3 : 0,
        pdPerXk3 : 0,
        dPercentZ : 0,
    }
	
	switch (calcPoint.calcKind) {
	case CALC_IK3:					// 三相三線短絡（電圧線間）
        result = doGetValuePerZk3(calcPoint.perZTotalMG);			// 通過の合成％Ｚを計算する
		break;
	case CALC_IK2:					// 三相二線短絡（電圧線間）
        result = doGetValuePerZk3(calcPoint.perZTotalMG);			// 通過の合成％Ｚを計算する
		break;
	case CALC_IK2_OUTSIDE_NEUTRAL:	// 単相二線短絡（外線－中性線間）
        result = doGetValuePerZk2OutIn(calcPoint.perZTotalMG);	// 通過の合成％Ｚを計算する
		break;
	case CALC_IK2_OUTSIDE_OUTSIDE:	// 単相二線短絡（外線間）
        result = doGetValuePerZk2OutOut(calcPoint.perZTotalMG);	// 通過の合成％Ｚを計算する
		break;
	case CALC_IK2_SEAT_MAIN:		// 単相二線短絡（主座変圧器）
        result = doGetValuePerZk2SeatM(calcPoint.perZTotalMG);	// 主座用の通過の合成％Ｚを計算する
		break;
	case CALC_IK2_SEAT_T:			// 単相二線短絡（Ｔ座変圧器）
        result = doGetValuePerZk2SeatT(calcPoint.perZTotalMG);	// Ｔ座用通過の合成％Ｚを計算する
		break;
	}

	return result;
}

// 短絡電流の種類に応じた通過のオーム値を取得する
export const doGetOhmZDirectMG = (calcPoint:any,pdOhmR:number|null,pdOhmX:any) => {
    let dPerR = 0,dPerX = 0,dPerZ = 0;
    let perZDirectMGData = doGetPerZDirectMG(calcPoint,dPerR,dPerX);
    dPerR = perZDirectMGData.pdPerRk3;
    dPerX = perZDirectMGData.pdPerXk3;
    dPerZ = perZDirectMGData.dPercentZ;
    if(dPerZ < 0){
        if(pdOhmR != null){
            pdOhmR = -1;
        }
        if(pdOhmX != null){
            pdOhmX = -1;
        }
        return {dOhmZ:-1, pdOhmR:pdOhmR, pdOhmX:pdOhmX};
    }
    let dOhmR = doGetValueVoltage(calcPoint) * doGetValueVoltage(calcPoint) / calcPoint.stdCapacity / 1000 * dPerR * 10;
    let dOhmX = doGetValueVoltage(calcPoint) * doGetValueVoltage(calcPoint) / calcPoint.stdCapacity / 1000 * dPerX * 10;

    if(pdOhmR != null){
        // pointer ref pdOhmR
        pdOhmR = dOhmR;
    }
    if(pdOhmX != null){
        // pointer ref pdOhmX
        pdOhmX = dOhmX;
    }

    let dOhmZ = Math.sqrt(dOhmR * dOhmR + dOhmX * dOhmX);
    let result = {dOhmZ:dOhmZ, pdOhmR:pdOhmR, pdOhmX:pdOhmX};
    return result;
}

export const doGetTextFaultDirect = (calcPoint: any, sRefNo: any[], dValue: number, dNominalVolt: number) => {
    let sValue = [...sRefNo];
    
    switch (calcPoint.calcKind) {
        case CALC_IK3:
            sValue.push('I"k3通過');
            break;
        case CALC_IK2:
            sValue.push('I"k2通過');
            break;
        case CALC_IK2_OUTSIDE_NEUTRAL:
            sValue.push('外線-中性線');
            sValue.push('I"k2通過');
            break;
        case CALC_IK2_OUTSIDE_OUTSIDE:
            sValue.push('外線-外線');
            sValue.push('I"k2通過');
            break;
        case CALC_IK2_SEAT_MAIN:
            sValue.push('主座');
            sValue.push('I"k2通過');
            break;
        case CALC_IK2_SEAT_T:
            sValue.push('T座');
            sValue.push('I"k2通過');
            break;
    }
      
    if(calcPoint.voltage == dNominalVolt) {
        sValue.push(BeamsNumber(dValue / 1000, 4).toString() + 'kAsym');
    } else {
        sValue.push(
            BeamsNumber((dValue * calcPoint.voltage / dNominalVolt), 4).toString() 
            + 'kAsym (' + BeamsNumber(dValue / 1000, 4).toString() + 'kAsym)'
        );
    }
  
    return sValue;
  }
