import React, { useRef, useEffect } from 'react';

function useOutsideClick(ref : any, callback : any) {
  const handleClick = (e : any) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
}

export default useOutsideClick;
