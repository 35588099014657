import Konva from "konva";
import { memo, useEffect, useRef, useState } from "react";
import { Group, Image, Rect, Transformer  } from "react-konva";
import { connect } from "react-redux";
import lineSvg from "../../images/control/line.svg";
import lineRedSvg from "../../images/control/line_red.svg";
import lineOhmSvg from "../../images/control/インピーダンス＿オーム＿R.svg";
import lineOhmRedSvg from "../../images/control/インピーダンス＿オーム＿R_red.svg";
import imageSource_2 from "../../images/control/電源＿無限大母線.svg";
import imageSource_2_Red from "../../images/control/電源＿無限大母線_red.svg";
import imageSource_1 from "../../images/control/電源＿電源.svg";
import imageSource_1_Red from "../../images/control/電源＿電源_red.svg";
import lineGreenSvg from "../../images/control/line_green.svg";
import leftArrow from "../../images/control/blue_arrow.svg";
import rightArrow from "../../images/control/red_arrow.svg";
import image2E_1 from "../../images/control/電動機保護リレー＿ﾒｰｸ接点(a接点).svg";
import image2E_1_Red from "../../images/control/電動機保護リレー＿ﾒｰｸ接点(a接点)_red.svg";
import image2E_1_Green from "../../images/control/電動機保護リレー＿ﾒｰｸ接点(a接点)_green.svg";
import image2E_2 from "../../images/control/電動機保護リレー＿ﾌﾞﾚｰｸ接点(b接点).svg";
import image2E_2_Red from "../../images/control/電動機保護リレー＿ﾌﾞﾚｰｸ接点(b接点)_red.svg";
import image2E_2_Green from "../../images/control/電動機保護リレー＿ﾌﾞﾚｰｸ接点(b接点)_green.svg";
import lineOhmGreenSvg from "../../images/control/インピーダンス＿オーム＿R_green.svg";
import lineOhmPercentZ from "../../images/control/インピーダンス＿オーム＿X＿R.svg";
import imageCableHead from "../../images/control/ケーブルヘッド＿下向き上向き.svg";
import imageCableHeadGreen from "../../images/control/ケーブルヘッド＿下向き上向き_green.svg";
import imageCableHeadRed from "../../images/control/ケーブルヘッド＿下向き上向き_red.svg";
import ImageConnect from "../../images/control/接続線.svg";
import ImageConnectRed from "../../images/control/接続線_red.svg";
import ImageConnectGreen from "../../images/control/接続線_green.svg";
import imageContactor_1_Svg from "../../images/control/電磁接触器＿ﾒｰｸ接点(a接点).svg";
import imageContactor_1_Red_Svg from "../../images/control/電磁接触器＿ﾒｰｸ接点(a接点)_red.svg";
import imageContactor_1_Green_Svg from "../../images/control/電磁接触器＿ﾒｰｸ接点(a接点)_green.svg";
import imageContactor_2_Svg from "../../images/control/電磁接触器＿ﾌﾞﾚｰｸ接点(b接点).svg";
import imageContactor_2_Red_Svg from "../../images/control/電磁接触器＿ﾌﾞﾚｰｸ接点(b接点)_red.svg";
import imageContactor_2_Green_Svg from "../../images/control/電磁接触器＿ﾌﾞﾚｰｸ接点(b接点)_green.svg";
import imageCT from "../../images/control/変流器.svg";
import imageCTGreen from "../../images/control/変流器_green.svg";
import imageCTRed from "../../images/control/変流器_red.svg";
import imageDs_3 from "../../images/control/断路器＿差込形断路器.svg";
import imageDs_3_Green from "../../images/control/断路器＿差込形断路器_green.svg";
import imageDs_3_Red from "../../images/control/断路器＿差込形断路器_red.svg";
import imageDs_1 from "../../images/control/断路器＿断路器.svg";
import imageDs_1_Green from "../../images/control/断路器＿断路器_green.svg";
import imageDs_1_Red from "../../images/control/断路器＿断路器_red.svg";
import imageDs_2 from "../../images/control/断路器＿高圧限流ﾋｭｰｽﾞ断路器.svg";
import imageDs_2_Green from "../../images/control/断路器＿高圧限流ﾋｭｰｽﾞ断路器_green.svg";
import imageDs_2_Red from "../../images/control/断路器＿高圧限流ﾋｭｰｽﾞ断路器_red.svg";
import imageFuse_2 from "../../images/control/断路器＿高圧限流ﾋｭｰｽﾞ断路器.svg";
import imageFuse_2_Green from "../../images/control/断路器＿高圧限流ﾋｭｰｽﾞ断路器_green.svg";
import imageFuse_2_Red from "../../images/control/断路器＿高圧限流ﾋｭｰｽﾞ断路器_red.svg";
import imageFuse_3 from "../../images/control/開閉器＿高圧限流ﾋｭｰｽﾞ付負荷開閉器.svg";
import imageFuse_3_Green from "../../images/control/開閉器＿高圧限流ﾋｭｰｽﾞ付負荷開閉器_green.svg";
import imageFuse_3_Red from "../../images/control/開閉器＿高圧限流ﾋｭｰｽﾞ付負荷開閉器_red.svg";
import imageFuse_1 from "../../images/control/開閉器＿高圧限流ﾋｭｰｽﾞ付開閉器.svg";
import imageFuse_1_Green from "../../images/control/開閉器＿高圧限流ﾋｭｰｽﾞ付開閉器_green.svg";
import imageFuse_1_Red from "../../images/control/開閉器＿高圧限流ﾋｭｰｽﾞ付開閉器_red.svg";
import imageFuse_4 from "../../images/control/高圧ヒューズ＿高圧限流ﾋｭｰｽﾞのみ.svg";
import imageFuse_4_Green from "../../images/control/高圧ヒューズ＿高圧限流ﾋｭｰｽﾞのみ_green.svg";
import imageFuse_4_Red from "../../images/control/高圧ヒューズ＿高圧限流ﾋｭｰｽﾞのみ_red.svg";
import imageGeneratorSvg from "../../images/control/発電機＿Equip_Generator.svg";
import imageGeneratorRedSvg from "../../images/control/発電機＿Equip_Generator_red.svg";
import imageHVCB_1 from "../../images/control/過電流継電器＿高圧遮断器＋過電流継電器.svg";
import imageHVCB_1_Red from "../../images/control/過電流継電器＿高圧遮断器＋過電流継電器_red.svg";
import imageHVCB_1_Green from "../../images/control/過電流継電器＿高圧遮断器＋過電流継電器_green.svg";
import imageHVCB_2 from "../../images/control/過電流継電器＿過電流継電器のみ.svg";
import imageHVCB_2_Red from "../../images/control/過電流継電器＿過電流継電器のみ_red.svg";
import imageHVCB_2_Green from "../../images/control/過電流継電器＿過電流継電器のみ_green.svg";
import imageHVCB_2_1 from "../../images/control/過電流継電器＿過電流継電器のみ_1.svg";
import imageHVCB_2_1_Red from "../../images/control/過電流継電器＿過電流継電器のみ_1_red.svg";
import imageHVCB_2_1_Green from "../../images/control/過電流継電器＿過電流継電器のみ_1_green.svg";
import imageHVCB_2_2 from "../../images/control/過電流継電器＿過電流継電器のみ_2.svg";
import imageHVCB_2_2_Red from "../../images/control/過電流継電器＿過電流継電器のみ_2_red.svg";
import imageHVCB_2_2_Green from "../../images/control/過電流継電器＿過電流継電器のみ_2_green.svg";
import imageHVCB_1_1 from "../../images/control/過電流継電器＿高圧遮断器＋過電流継電器_1.svg";
import imageHVCB_1_1_Red from "../../images/control/過電流継電器＿高圧遮断器＋過電流継電器_1_red.svg";
import imageHVCB_1_1_Green from "../../images/control/過電流継電器＿高圧遮断器＋過電流継電器_1_green.svg";
import imageHVCB_1_2 from "../../images/control/過電流継電器＿高圧遮断器＋過電流継電器_2.svg";
import imageHVCB_1_2_Red from "../../images/control/過電流継電器＿高圧遮断器＋過電流継電器_2_red.svg";
import imageHVCB_1_2_Green from "../../images/control/過電流継電器＿高圧遮断器＋過電流継電器_2_green.svg";
import imageImpedance_r from "../../images/control/インピーダンス＿オーム＿R.svg";
import imageImpedance_r_Red from "../../images/control/インピーダンス＿オーム＿R_red.svg";
import imageImpedance_r_Green from "../../images/control/インピーダンス＿オーム＿R_green.svg";
import imageImpedance_r_x from "../../images/control/インピーダンス＿オーム＿R＿X.svg";
import imageImpedance_r_x_Red from "../../images/control/インピーダンス＿オーム＿R＿X_red.svg";
import imageImpedance_r_x_Green from "../../images/control/インピーダンス＿オーム＿R＿X_green.svg";
import imageImpedance_x from "../../images/control/インピーダンス＿オーム＿X.svg";
import imageImpedance_x_Red from "../../images/control/インピーダンス＿オーム＿X_red.svg";
import imageImpedance_x_Green from "../../images/control/インピーダンス＿オーム＿X_green.svg";
import imageINVSvg from "../../images/control/インバーター.svg";
import imageINVRedSvg from "../../images/control/インバーター_red.svg";
import imageINVGreenSvg from "../../images/control/インバーター_green.svg";
import imageLBS_1 from "../../images/control/開閉器＿開閉器.svg";
import imageLBS_1_Red from "../../images/control/開閉器＿開閉器_red.svg";
import imageLBS_1_Green from "../../images/control/開閉器＿開閉器_green.svg";
import imageLBS_2 from "../../images/control/開閉器＿高圧限流ﾋｭｰｽﾞ付開閉器.svg";
import imageLBS_2_Red from "../../images/control/開閉器＿高圧限流ﾋｭｰｽﾞ付開閉器_red.svg";
import imageLBS_2_Green from "../../images/control/開閉器＿高圧限流ﾋｭｰｽﾞ付開閉器_green.svg";
import imageLBS_3 from "../../images/control/開閉器＿負荷開閉器.svg";
import imageLBS_3_Red from "../../images/control/開閉器＿負荷開閉器_red.svg";
import imageLBS_3_Green from "../../images/control/開閉器＿負荷開閉器_green.svg";
import imageLBS_4 from "../../images/control/開閉器＿高圧限流ﾋｭｰｽﾞ付負荷開閉器.svg";
import imageLBS_4_Red from "../../images/control/開閉器＿高圧限流ﾋｭｰｽﾞ付負荷開閉器_red.svg";
import imageLBS_4_Green from "../../images/control/開閉器＿高圧限流ﾋｭｰｽﾞ付負荷開閉器_green.svg";
import imageLVCB_0 from "../../images/control/遮断器＿高圧遮断器／配線用遮断器.svg";
import imageLVCB_0_Red from "../../images/control/遮断器＿高圧遮断器／配線用遮断器_red.svg";
import imageLVCB_0_Green from "../../images/control/遮断器＿高圧遮断器／配線用遮断器_green.svg";
import imageLVCB_2 from "../../images/control/低圧遮断器＿配線用遮断器(旧JIS).svg";
import imageLVCB_2_Red from "../../images/control/低圧遮断器＿配線用遮断器(旧JIS)_red.svg";
import imageLVCB_2_Green from "../../images/control/低圧遮断器＿配線用遮断器(旧JIS)_green.svg";
import imageLVCB_1 from "../../images/control/低圧遮断器＿漏洩遮断器.svg";
import imageLVCB_1_Red from "../../images/control/低圧遮断器＿漏洩遮断器_red.svg";
import imageLVCB_1_Green from "../../images/control/低圧遮断器＿漏洩遮断器_green.svg";
import imageLVCB_3 from "../../images/control/低圧遮断器＿引出形気中遮断器.svg";
import imageLVCB_3_Red from "../../images/control/低圧遮断器＿引出形気中遮断器_red.svg";
import imageLVCB_3_Green from "../../images/control/低圧遮断器＿引出形気中遮断器_green.svg";
import imageMVCB_1 from "../../images/control/遮断器＿高圧遮断器／配線用遮断器.svg";
import imageMVCB_1_Red from "../../images/control/遮断器＿高圧遮断器／配線用遮断器_red.svg";
import imageMVCB_1_Green from "../../images/control/遮断器＿高圧遮断器／配線用遮断器_green.svg";
import imageMVCB_2 from "../../images/control/遮断器＿漏電遮断器.svg";
import imageMVCB_2_Red from "../../images/control/遮断器＿漏電遮断器_red.svg";
import imageMVCB_2_Green from "../../images/control/遮断器＿漏電遮断器_green.svg";
import imageMVCB_3 from "../../images/control/遮断器＿引出形遮断器.svg";
import imageMVCB_3_Red from "../../images/control/遮断器＿引出形遮断器_red.svg";
import imageMVCB_3_Green from "../../images/control/遮断器＿引出形遮断器_green.svg";
import imageReactorSvg from "../../images/control/限流リアクトル.svg";
import imageReactorRedSvg from "../../images/control/限流リアクトル_red.svg";
import imageReactorGreenSvg from "../../images/control/限流リアクトル_green.svg";
import imageRelay_1 from "../../images/control/リレー＿Ry.svg";
import imageRelay_1_Red from "../../images/control/リレー＿Ry_red.svg";
import imageRelay_1_Green from "../../images/control/リレー＿Ry_green.svg";
import imageRelay_3 from "../../images/control/リレー＿任意の値.svg";
import imageRelay_3_Red from "../../images/control/リレー＿任意の値_red.svg";
import imageRelay_3_Green from "../../images/control/リレー＿任意の値_green.svg";
import imageRelay_2 from "../../images/control/リレー＿I＞.svg";
import imageRelay_2_Red from "../../images/control/リレー＿I＞_red.svg";
import imageRelay_2_Green from "../../images/control/リレー＿I＞_green.svg";
import imageThermal_1 from "../../images/control/サーマルリレー＿ﾒｰｸ接点(a接点).svg";
import imageThermal_1_Red from "../../images/control/サーマルリレー＿ﾒｰｸ接点(a接点)_red.svg";
import imageThermal_1_Green from "../../images/control/サーマルリレー＿ﾒｰｸ接点(a接点)_green.svg";
import imageThermal_2 from "../../images/control/サーマルリレー＿ﾌﾞﾚｰｸ接点(b接点).svg";
import imageThermal_2_Red from "../../images/control/サーマルリレー＿ﾌﾞﾚｰｸ接点(b接点)_red.svg";
import imageThermal_2_Green from "../../images/control/サーマルリレー＿ﾌﾞﾚｰｸ接点(b接点)_green.svg";
import imageThermalCTSvg from "../../images/control/過負荷継電器.svg";
import imageThermalCTGreenSvg from "../../images/control/過負荷継電器_green.svg";
import imageThermalCTRedSvg from "../../images/control/過負荷継電器_red.svg";
import imageTrans1_1 from "../../images/control/単相変圧器＿無し.svg";
import imageTrans1_1_Red from "../../images/control/単相変圧器＿無し_red.svg";
import imageTrans1_1_Green from "../../images/control/単相変圧器＿無し_green.svg";
import imageTrans1_2 from "../../images/control/単相変圧器＿ー／≡.svg";
import imageTrans1_2_Red from "../../images/control/単相変圧器＿ー／≡_red.svg";
import imageTrans1_2_Green from "../../images/control/単相変圧器＿ー／≡_green.svg";
import imageTrans3_0_F from "../../images/control/三相変圧器＿無し.svg";
import imageTrans3_0_F_Red from "../../images/control/三相変圧器＿無し_red.svg";
import imageTrans3_0_F_Green from "../../images/control/三相変圧器＿無し_green.svg";
import imageTrans3_0_T from "../../images/control/三相変圧器＿無し＿負荷時電圧調整.svg";
import imageTrans3_0_T_Red from "../../images/control/三相変圧器＿無し＿負荷時電圧調整_red.svg";
import imageTrans3_0_T_Green from "../../images/control/三相変圧器＿無し＿負荷時電圧調整_green.svg";
import imageTrans3_1_F from "../../images/control/三相変圧器＿Ｙ／△.svg";
import imageTrans3_1_F_Red from "../../images/control/三相変圧器＿Ｙ／△_red.svg";
import imageTrans3_1_F_Green from "../../images/control/三相変圧器＿Ｙ／△_green.svg";
import imageTrans3_1_T from "../../images/control/三相変圧器＿Ｙ／△＿負荷時電圧調整.svg";
import imageTrans3_1_T_Red from "../../images/control/三相変圧器＿Ｙ／△＿負荷時電圧調整_red.svg";
import imageTrans3_1_T_Green from "../../images/control/三相変圧器＿Ｙ／△＿負荷時電圧調整_green.svg";
import imageTrans3_2_F from "../../images/control/三相変圧器＿Ｙ／Y.svg";
import imageTrans3_2_F_Red from "../../images/control/三相変圧器＿Ｙ／Y_red.svg";
import imageTrans3_2_F_Green from "../../images/control/三相変圧器＿Ｙ／Y_green.svg";
import imageTrans3_2_T from "../../images/control/三相変圧器＿Ｙ／Y＿負荷時電圧調整.svg";
import imageTrans3_2_T_Red from "../../images/control/三相変圧器＿Ｙ／Y＿負荷時電圧調整_red.svg";
import imageTrans3_2_T_Green from "../../images/control/三相変圧器＿Ｙ／Y＿負荷時電圧調整_green.svg";
import imageTrans3_3_F from "../../images/control/三相変圧器＿△／Y.svg";
import imageTrans3_3_F_Red from "../../images/control/三相変圧器＿△／Y_red.svg";
import imageTrans3_3_F_Green from "../../images/control/三相変圧器＿△／Y_green.svg";
import imageTrans3_3_T from "../../images/control/三相変圧器＿△／Y＿負荷時電圧調整.svg";
import imageTrans3_3_T_Red from "../../images/control/三相変圧器＿△／Y＿負荷時電圧調整_red.svg";
import imageTrans3_3_T_Green from "../../images/control/三相変圧器＿△／Y＿負荷時電圧調整_green.svg";
import imageTrans3_4_F from "../../images/control/三相変圧器＿△／△.svg";
import imageTrans3_4_F_Red from "../../images/control/三相変圧器＿△／△_red.svg";
import imageTrans3_4_F_Green from "../../images/control/三相変圧器＿△／△_green.svg";
import imageTrans3_4_T from "../../images/control/三相変圧器＿△／△＿負荷時電圧調整機能付き.svg";
import imageTrans3_4_T_Red from "../../images/control/三相変圧器＿△／△＿負荷時電圧調整機能付き_red.svg";
import imageTrans3_4_T_Green from "../../images/control/三相変圧器＿△／△＿負荷時電圧調整機能付き_green.svg";
import imageTransscottSvg from "../../images/control/スコット結線変圧器.svg";
import imageTransscottRedSvg from "../../images/control/スコット結線変圧器_red.svg";
import imageTransscottGreenSvg from "../../images/control/スコット結線変圧器_green.svg";
import imageVCT1Svg from "../../images/control/計器用変成器＿VCT.svg";
import imageRedVCT1Svg from "../../images/control/計器用変成器＿VCT_red.svg";
import imageGreenVCT1Svg from "../../images/control/計器用変成器＿VCT_green.svg";
import imageVCT2Svg from "../../images/control/計器用変成器＿PCT.svg";
import imageRedVCT2Svg from "../../images/control/計器用変成器＿PCT_red.svg";
import imageGreenVCT2Svg from "../../images/control/計器用変成器＿PCT_green.svg";
import imageVCT3Svg from "../../images/control/計器用変成器＿MOF.svg";
import imageRedVCT3Svg from "../../images/control/計器用変成器＿MOF_red.svg";
import imageGreenVCT3Svg from "../../images/control/計器用変成器＿MOF_green.svg";
import imageVTSvg from "../../images/control/計器用変圧器.svg";
import imageVTRedSvg from "../../images/control/計器用変圧器_red.svg";
import imageVTGreenSvg from "../../images/control/計器用変圧器_green.svg";
import imageZCTSvg from "../../images/control/零相変流器.svg";
import imageZCTRedSvg from "../../images/control/零相変流器_red.svg";
import imageZCTGreenSvg from "../../images/control/零相変流器_green.svg";
import triangleUp from "../../images/control/電線＿ケーブル_上.svg";
import triangleRedUp from "../../images/control/電線＿ケーブル_上_red.svg";
import triangleGreenUp from "../../images/control/電線＿ケーブル_上_green.svg";
import triangleDown from "../../images/control/電線＿ケーブル_下.svg";
import triangleRedDown from "../../images/control/電線＿ケーブル_下_red.svg";
import triangleGreenDown from "../../images/control/電線＿ケーブル_下_green.svg";
import imageZerocondSvg from "../../images/control/母線.svg";
import imageZerocondRedSvg from "../../images/control/母線_red.svg";
import imageZerocondGreenSvg from "../../images/control/母線_green.svg";
import * as Constant from "../../models/Constants";
import { ProcessMode } from "../../models/Index";
import {
  openChart,
  setTransformFlag,
  updateControlHeight,
  updatePropertiesOfControlAction,
  updateSelectControl,
} from "../../store/Actions";
import { actionCreators } from "../../store";
import * as Model from "../../models/Index";
import { MS_2E, MS_BUSBAR, MS_BUSDUCT, MS_CABLEHEAD, MS_CONNECT, MS_CONTACTOR, MS_CT, MS_DS, MS_FUSE, MS_GENERATOR, MS_HVCB, MS_IMPEDANCE, MS_INV, MS_LBS, MS_LVCB, MS_MVCB, MS_REACTOR, MS_RELAY, MS_SOURCE, MS_THERMAL, MS_THERMAL_CT, MS_TRANS1, MS_TRANS3, MS_TRANSSCOTT, MS_VCT, MS_VT, MS_WIRE, MS_ZCT, MS_ZEROCOND } from "../../models/ElementKind";
import { WIRE_SYMBOL_CABLE, WIRE_SYMBOL_NORMAL } from "../../statics";

//#region Props
type CreateMsHiddenTopControlTranscenterProps = {
  id: string;
  image: string;
  x: number;
  y: number;
  offsetX: number;
  offsetY: number;
  width: number;
  height: number;
  type: string;
  isSelected: boolean;
  isHidden: boolean;
  rotation: number;
  lineDiagramRef: any;
  parentGroupId: number | null;
  properties: any;
  chartDataList: any,
  currentIDChartTab: any,
  gridSizeController: {
    width: number;
    setWidth: (width: number) => void;
    height: number;
    setHeight: (height: number) => void;
    scale: number;
  };
};

export type PureCreateMsHiddenTopControlTranscenterProps = CreateMsHiddenTopControlTranscenterProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;
//#endregion

const CONTROL_POS_FACTOR = 0;

const CreateMsHiddenTopControlTranscenter = memo((props: PureCreateMsHiddenTopControlTranscenterProps) => {
  //#region Fields
  const {
    id,
    image,
    x,
    y,
    offsetX,
    offsetY,
    width,
    height,
    type,
    isSelected,
    rotation,
    parentGroupId,
    processMode,
    properties,
  } = props;
  
  const {
    infoSkeleton,
  } = props;

  const getSymbolAndBackColor = (infoSkeleton:any,processMode:any,properties:any) => {
    let symbolColor = "";
    let backColor = "";
    if(properties.checkNG == true && type != MS_CONNECT){
        backColor = '#ff00ff';
        symbolColor = "#000000";
    }
    else if(type == MS_TRANS3 && processMode == ProcessMode.POWER_FLOW && properties.voltagePrimary > properties.voltageSecondary){
      if(properties.lineActiveFlow2 > 0){
        let dCapacity:any = Math.sqrt(properties.lineActiveFlow2 * properties.lineActiveFlow2 + properties.lineReactiveFlow2 * properties.lineReactiveFlow2);
        if(dCapacity > 1.0 * Number(properties.capacity)){
          backColor = '#ff00ff';
          symbolColor = "#000000";
        }else{
          backColor = '#ff8000';
          symbolColor = "#000000";
        }
      }
    }
    else if(type == MS_TRANS3 && processMode == ProcessMode.POWER_FLOW && properties.voltagePrimary < properties.voltageSecondary && properties.currentTopLeft == "0"){
      if(properties.lineActiveFlow1 > 0){
        let capacity:any = Math.sqrt(properties.lineActiveFlow1 * properties.lineActiveFlow1 + properties.lineReactiveFlow1 * properties.lineReactiveFlow1)
        if(capacity > 1.0 * properties.capacity){
          backColor = '#ff00ff';
          symbolColor = "#000000";
        }else{
          backColor = '#ff8000';
          symbolColor = "#000000";
        }
      }
    }
    else{
        if(properties.isOff == true && ( type == MS_THERMAL_CT || type == MS_THERMAL || type == MS_MVCB || type == MS_LVCB || type == MS_LBS || type == MS_2E || type == MS_CONTACTOR || type == MS_DS || type == MS_FUSE || type == MS_HVCB || type == MS_IMPEDANCE))
        {
            backColor = "#008040"
            symbolColor = "#000000";
        }
        else if(infoSkeleton.dispStress == true && processMode > ProcessMode.DRAWING){
          if(type != MS_SOURCE && type != MS_GENERATOR)
          {
            if(infoSkeleton.dispStainOrBack != true){
              if(properties.supply != true)
                backColor = "#00FF00"
              else
                backColor = "#FFFFFF"
              symbolColor = "#000000";
            }else{
              if(properties.supply != true)
                symbolColor = "#008040"
              else
                symbolColor = "#FF0000"
              backColor = "#FFFFFF";
            }
          }
          else
          {
            if(infoSkeleton.dispStainOrBack != true){
              backColor = "#FFFFFF"
              symbolColor = "#000000";
            }else{
              symbolColor = "#FF0000"
              backColor = "#FFFFFF";
            }
          }
        }
        else
        {
            symbolColor = "#000000";
            backColor = "#FFFFFF";
        }
    }
    return {symbolColor:symbolColor,backColor:backColor}
}

  const [imageStatus, setImageStatus] = useState(image);
  const [lineStatus, setLineStatus] = useState(lineSvg);
  const [lineOhmStatus, setLineOhmStatus] = useState(lineOhmSvg);
  const [triangleDownStatus, setTriangleDownStatus] = useState(triangleDown);
  const [triangleUpStatus, setTriangleUpStatus] = useState(triangleUp);

  var imageObj = new window.Image();
  imageObj.src = imageStatus;

  var lineObj = new window.Image();
  lineObj.src = lineStatus;

  var triangleUpObj = new window.Image();
  triangleUpObj.src = triangleUpStatus;

  var triangleDownObj = new window.Image();
  triangleDownObj.src = triangleDownStatus;

  var lineOhmObj = new window.Image();
  lineOhmObj.src = lineOhmStatus;
  
  var rightArrowObj = new window.Image();
  rightArrowObj.src = rightArrow;

  var leftArrowObj = new window.Image();
  leftArrowObj.src = leftArrow;

  var lineOhmPercentZObj = new window.Image();
  lineOhmPercentZObj.src = lineOhmPercentZ;

  const [groupPosition, setGroupPosition] = useState({ x: x, y: y });
  const [arrowPosition, setArrowPosition] = useState({
    x: groupPosition.x,
    y: groupPosition.y + height - Constant.ELEMENT_SIZE - 10,
  });

  const transformRef = useRef<Konva.Transformer>(null);
  const transformMoveRef = useRef<Konva.Transformer>(null);
  const groupRef = useRef<any>();
  const rightArrowRef = useRef<any>();
  const leftArrowRef = useRef<any>();

  //#endregion

  //#region useEffect

  useEffect(() => {
    if (x >= 0 && y >= 0 && (groupPosition.x !== x || groupPosition.y !== y)) {
      setGroupPosition({x, y})
    }
  }, [x, y])

  useEffect(() => {
    if (isSelected && !parentGroupId) {
      transformRef.current?.nodes([groupRef.current]);
    } else {
      transformRef.current?.nodes([]);
    }
    transformRef.current?.getLayer()?.batchDraw();
  }, [isSelected, parentGroupId]);



  useEffect(() => {
    // check grid size; label, icon position when rotate, move and change height
    // TODO: remove handleGridSize, setWidth, setHeight
    // handleGridSize(groupPosition.x, groupPosition.y, height, rotation);
    calcLabelPos();
    calcArrowPos();
  }, [groupPosition, height, rotation]);

  useEffect(() => {
    switch(type){
        case MS_SOURCE:
          {
            switch(getSymbolAndBackColor(infoSkeleton,processMode,properties).symbolColor){
              case "#FF0000":
                  setLineStatus(lineRedSvg);
                  switch (properties.symKind.toString()) {
                    case "0":
                      setImageStatus(imageSource_1_Red);
                      break;
                    case "1":
                      setImageStatus(imageSource_2_Red);
                      break;
                  }
                  setLineOhmStatus(lineOhmRedSvg);
                  break;
              default:
                  setLineStatus(lineSvg);
                  switch (properties.symKind.toString()) {
                    case "0":
                      setImageStatus(imageSource_1);
                      break;
                    case "1":
                      setImageStatus(imageSource_2);
                      break;
                  }
                  setLineOhmStatus(lineOhmSvg);
                  break;
            }
            break;
          }
        case MS_2E:
          {
            switch(getSymbolAndBackColor(infoSkeleton,processMode,properties).symbolColor){
              case "#008040":
                setLineStatus(lineGreenSvg);
                switch (properties.symKind.toString()) {
                  case "0":
                    setImageStatus(image2E_1_Green);
                    break;
                  case "1":
                    setImageStatus(image2E_2_Green);
                    break;
                }
                break;
              case "#FF0000":
                setLineStatus(lineRedSvg);
                switch (properties.symKind.toString()) {
                  case "0":
                    setImageStatus(image2E_1_Red);
                    break;
                  case "1":
                    setImageStatus(image2E_2_Red);
                    break;
                }
                break;
              default:
                setLineStatus(lineSvg);
                switch (properties.symKind.toString()) {
                  case "0":
                    setImageStatus(image2E_1);
                    break;
                  case "1":
                    setImageStatus(image2E_2);
                    break;
                }
                break;
            }
            break;
          }
        case MS_BUSBAR:
          {
            switch(getSymbolAndBackColor(infoSkeleton,processMode,properties).symbolColor){
              case "#008040":
                setLineStatus(lineGreenSvg);
                setLineOhmStatus(lineOhmGreenSvg)
                break;
              case "#FF0000":
                setLineStatus(lineRedSvg);
                setLineOhmStatus(lineOhmRedSvg)
                break;
              default:
                setLineStatus(lineSvg);
                setLineOhmStatus(lineOhmSvg)
                break;
            }
            break;
          }
        case MS_BUSDUCT:
          {
            switch(getSymbolAndBackColor(infoSkeleton,processMode,properties).symbolColor){
              case "#008040":
                setLineStatus(lineGreenSvg);
                setLineOhmStatus(lineOhmGreenSvg)
                break;
              case "#FF0000":
                setLineStatus(lineRedSvg);
                setLineOhmStatus(lineOhmRedSvg)
                break;
              default:
                setLineStatus(lineSvg);
                setLineOhmStatus(lineOhmSvg)
                break;
            }
            break;
          }
        case MS_CABLEHEAD:
          {
            switch(getSymbolAndBackColor(infoSkeleton,processMode,properties).symbolColor){
              case "#008040":
                setImageStatus(imageCableHeadGreen)
                break;
              case "#FF0000":
                setImageStatus(imageCableHeadRed)
                break;
              default:
                setImageStatus(imageCableHead)
                break;
            }
            break;
          }
        case MS_CONNECT:
          {
            switch(getSymbolAndBackColor(infoSkeleton,processMode,properties).symbolColor){
              case "#008040":
                setImageStatus(ImageConnectGreen);
                break;
              case "#FF0000":
                setImageStatus(ImageConnectRed);
                break;
              default:
                setImageStatus(ImageConnect);
                break;
            }
            break;
          }
        case MS_CONTACTOR:
          {
            switch(getSymbolAndBackColor(infoSkeleton,processMode,properties).symbolColor){
              case "#008040":
                setLineStatus(lineGreenSvg);
                switch (properties.exchangeNumber.toString()) {
                  case "0":
                    setImageStatus(imageContactor_1_Green_Svg);
                    break;
                  case "1":
                    setImageStatus(imageContactor_2_Green_Svg);
                    break;
                }
                break;
              case "#FF0000":
                setLineStatus(lineRedSvg);
                switch (properties.exchangeNumber.toString()) {
                  case "0":
                    setImageStatus(imageContactor_1_Red_Svg);
                    break;
                  case "1":
                    setImageStatus(imageContactor_2_Red_Svg);
                    break;
                }
                break;
              default:
                setLineStatus(lineSvg);
                switch (properties.exchangeNumber.toString()) {
                  case "0":
                    setImageStatus(imageContactor_1_Svg);
                    break;
                  case "1":
                    setImageStatus(imageContactor_2_Svg);
                    break;
                }
                break;
            }
            break;
          }
        case MS_CONTACTOR:
          {
            switch(getSymbolAndBackColor(infoSkeleton,processMode,properties).symbolColor){
              case "#008040":
                setLineStatus(lineGreenSvg);
                setImageStatus(imageCTGreen);
                break;
              case "#FF0000":
                setLineStatus(lineRedSvg);
                setImageStatus(imageCTRed);
                break;
              default:
                setLineStatus(lineSvg);
                setImageStatus(imageCT);
                break;
            }
            break;
          }
        case MS_DS:
          {
            switch(getSymbolAndBackColor(infoSkeleton,processMode,properties).symbolColor){
              case "#008040":
                setLineStatus(lineGreenSvg);
                switch (properties.exchangeNumber.toString()) {
                  case "0":
                    setImageStatus(imageDs_1_Green);
                    break;
                  case "1":
                    setImageStatus(imageDs_2_Green);
                    break;
                  case "2":
                    setImageStatus(imageDs_3_Green);
                }
                break;
              case "#FF0000":
                setLineStatus(lineRedSvg);
                switch (properties.exchangeNumber.toString()) {
                  case "0":
                    setImageStatus(imageDs_1_Red);
                    break;
                  case "1":
                    setImageStatus(imageDs_2_Red);
                    break;
                  case "2":
                    setImageStatus(imageDs_3_Red);
                }
                break;
              default:
                setLineStatus(lineSvg);
                switch (properties.exchangeNumber.toString()) {
                  case "0":
                    setImageStatus(imageDs_1);
                    break;
                  case "1":
                    setImageStatus(imageDs_2);
                    break;
                  case "2":
                    setImageStatus(imageDs_3);
                }
                break;
            }
            break;
          }
        case MS_FUSE:
          {
            switch(getSymbolAndBackColor(infoSkeleton,processMode,properties).symbolColor){
              case "#008040":
                setLineStatus(lineGreenSvg);
                switch (properties.symKind.toString()) {
                  case "0":
                    setImageStatus(imageFuse_1_Green);
                    break;
                  case "1":
                    setImageStatus(imageFuse_2_Green);
                    break;
                  case "2":
                    setImageStatus(imageFuse_3_Green);
                    break;
                  case "3":
                    setImageStatus(imageFuse_4_Green);
                    break;
                }
                break;
              case "#FF0000":
                setLineStatus(lineRedSvg);
                switch (properties.symKind.toString()) {
                  case "0":
                    setImageStatus(imageFuse_1_Red);
                    break;
                  case "1":
                    setImageStatus(imageFuse_2_Red);
                    break;
                  case "2":
                    setImageStatus(imageFuse_3_Red);
                    break;
                  case "3":
                    setImageStatus(imageFuse_4_Red);
                    break;
                }
                break;
              default:
                setLineStatus(lineSvg);
                switch (properties.symKind.toString()) {
                  case "0":
                    setImageStatus(imageFuse_1);
                    break;
                  case "1":
                    setImageStatus(imageFuse_2);
                    break;
                  case "2":
                    setImageStatus(imageFuse_3);
                    break;
                  case "3":
                    setImageStatus(imageFuse_4);
                    break;
                }
                break;
            }
            break;
          }
        case MS_GENERATOR:
          {
            switch(getSymbolAndBackColor(infoSkeleton,processMode,properties).symbolColor){
              case "#FF0000":
                  setLineStatus(lineRedSvg);
                  setImageStatus(imageGeneratorRedSvg);
                  setLineOhmStatus(lineOhmRedSvg);
                  break;
              default:
                  setLineStatus(lineSvg);
                  setImageStatus(imageGeneratorSvg);
                  setLineOhmStatus(lineOhmSvg);
                  break;
            }
            break;
          }
        case MS_HVCB:
          {
            switch(getSymbolAndBackColor(infoSkeleton,processMode,properties).symbolColor){
              case "#008040":
                setLineStatus(lineGreenSvg);
                switch (properties.symKind.toString()) {
                  case "0":
                    if (rotation === 0) setImageStatus(imageHVCB_1_Green);
                    else if (rotation === -90) setImageStatus(imageHVCB_1_1_Green);
                    else setImageStatus(imageHVCB_1_2_Green);
                    break;
                  case "1":
                    if (rotation === 0) setImageStatus(imageHVCB_2_Green);
                    else if (rotation === -90) setImageStatus(imageHVCB_2_1_Green);
                    else setImageStatus(imageHVCB_2_2_Green);
                    break;
                }
                break;
              case "#FF0000":
                setLineStatus(lineRedSvg);
                switch (properties.symKind.toString()) {
                  case "0":
                    if (rotation === 0) setImageStatus(imageHVCB_1_Red);
                    else if (rotation === -90) setImageStatus(imageHVCB_1_1_Red);
                    else setImageStatus(imageHVCB_1_2_Red);
                    break;
                  case "1":
                    if (rotation === 0) setImageStatus(imageHVCB_2_Red);
                    else if (rotation === -90) setImageStatus(imageHVCB_2_1_Red);
                    else setImageStatus(imageHVCB_2_2_Red);
                    break;
                }
                break;
              default:
                setLineStatus(lineSvg);
                switch (properties.symKind.toString()) {
                  case "0":
                    if (rotation === 0) setImageStatus(imageHVCB_1);
                    else if (rotation === -90) setImageStatus(imageHVCB_1_1);
                    else setImageStatus(imageHVCB_1_2);
                    break;
                  case "1":
                    if (rotation === 0) setImageStatus(imageHVCB_2);
                    else if (rotation === -90) setImageStatus(imageHVCB_2_1);
                    else setImageStatus(imageHVCB_2_2);
                    break;
                }
                break;
            }
            break;
          }
        case MS_IMPEDANCE:
          {
            switch(getSymbolAndBackColor(infoSkeleton,processMode,properties).symbolColor){
              case "#008040":
                setLineStatus(lineGreenSvg);
                switch (properties.inputPercentZ.toString()) {
                  case "1":
                    setImageStatus(lineGreenSvg)
                    break;
                  case "2":
                    if (properties.ohmR > 0 && properties.ohmX > 0) {
                      setImageStatus(imageImpedance_r_x_Green)
                    } else if (properties.ohmR > 0) {
                      setImageStatus(imageImpedance_r_Green)
                    } else if (properties.ohmX > 0) {
                      setImageStatus(imageImpedance_x_Green)
                    } else {
                      setImageStatus(lineGreenSvg)
                    }
                    break;
                }
                break;
              case "#FF0000":
                setLineStatus(lineRedSvg);
                switch (properties.inputPercentZ.toString()) {
                  case "1":
                    setImageStatus(lineRedSvg)
                    break;
                  case "2":
                    if (properties.ohmR > 0 && properties.ohmX > 0) {
                      setImageStatus(imageImpedance_r_x_Red)
                    } else if (properties.ohmR > 0) {
                      setImageStatus(imageImpedance_r_Red)
                    } else if (properties.ohmX > 0) {
                      setImageStatus(imageImpedance_x_Red)
                    } else {
                      setImageStatus(lineRedSvg)
                    }
                    break;
                }
                break;
              default:
                setLineStatus(lineSvg);
                switch (properties.inputPercentZ.toString()) {
                  case "1":
                    // setImageStatus(lineSvg)
                    if (properties.percentR > 0 && properties.percentX > 0) {
                      setImageStatus(imageImpedance_r_x)
                    } else if (properties.percentR > 0) {
                      setImageStatus(imageImpedance_r)
                    } else if (properties.percentX > 0) {
                      setImageStatus(imageImpedance_x)
                    } else {
                      setImageStatus(image)
                    }
                    break;
                  case "2":
                    if (properties.ohmR > 0 && properties.ohmX > 0) {
                      setImageStatus(imageImpedance_r_x)
                    } else if (properties.ohmR > 0) {
                      setImageStatus(imageImpedance_r)
                    } else if (properties.ohmX > 0) {
                      setImageStatus(imageImpedance_x)
                    } else {
                      setImageStatus(lineSvg)
                    }
                    break;
                }
                break;
            }
            break;
          }
        case MS_INV:
          {
            switch(getSymbolAndBackColor(infoSkeleton,processMode,properties).symbolColor){
              case "#008040":
                setLineStatus(lineGreenSvg);
                setImageStatus(imageINVGreenSvg);
                break;
              case "#FF0000":
                setLineStatus(lineRedSvg);
                setImageStatus(imageINVRedSvg);
                break;
              default:
                setLineStatus(lineSvg);
                setImageStatus(imageINVSvg);
                break;
            }
            break;
          }
        case MS_LBS:
          {
            switch(getSymbolAndBackColor(infoSkeleton,processMode,properties).symbolColor){
              case "#008040":
                setLineStatus(lineGreenSvg);
                switch (properties.exchangeNumber.toString()) {
                  case "0":
                    setImageStatus(imageLBS_1_Green);
                    break;
                  case "1":
                    setImageStatus(imageLBS_2_Green);
                    break;
                  case "2":
                    setImageStatus(imageLBS_3_Green);
                    break;
                  case "3":
                    setImageStatus(imageLBS_4_Green);
                    break;
                }
                break;
              case "#FF0000":
                setLineStatus(lineRedSvg);
                switch (properties.exchangeNumber.toString()) {
                  case "0":
                    setImageStatus(imageLBS_1_Red);
                    break;
                  case "1":
                    setImageStatus(imageLBS_2_Red);
                    break;
                  case "2":
                    setImageStatus(imageLBS_3_Red);
                    break;
                  case "3":
                    setImageStatus(imageLBS_4_Red);
                    break;
                }
                break;
              default:
                setLineStatus(lineSvg);
                switch (properties.exchangeNumber.toString()) {
                  case "0":
                    setImageStatus(imageLBS_1);
                    break;
                  case "1":
                    setImageStatus(imageLBS_2);
                    break;
                  case "2":
                    setImageStatus(imageLBS_3);
                    break;
                  case "3":
                    setImageStatus(imageLBS_4);
                    break;
                }
                break;
            }
            break;
          }
        case MS_LVCB:
          {
            switch(getSymbolAndBackColor(infoSkeleton,processMode,properties).symbolColor){
              case "#008040":
                setLineStatus(lineGreenSvg);
                switch (properties.symKind.toString()) {
                  case "0":
                    setImageStatus(imageLVCB_0_Green);
                    break;
                  case "1":
                    setImageStatus(imageLVCB_2_Green);
                    break;
                  case "2":
                    setImageStatus(imageLVCB_1_Green);
                    break;
                  case "3":
                    setImageStatus(imageLVCB_3_Green);
                    break;
                }
                break;
              case "#FF0000":
                setLineStatus(lineRedSvg);
                switch (properties.symKind.toString()) {
                  case "0":
                    setImageStatus(imageLVCB_0_Red);
                    break;
                  case "1":
                    setImageStatus(imageLVCB_2_Red);
                    break;
                  case "2":
                    setImageStatus(imageLVCB_1_Red);
                    break;
                  case "3":
                    setImageStatus(imageLVCB_3_Red);
                    break;
                }
                break;
              default:
                setLineStatus(lineSvg);
                switch (properties.symKind.toString()) {
                  case "0":
                    setImageStatus(imageLVCB_0);
                    break;
                  case "1":
                    setImageStatus(imageLVCB_2);
                    break;
                  case "2":
                    setImageStatus(imageLVCB_1);
                    break;
                  case "3":
                    setImageStatus(imageLVCB_3);
                    break;
                }
                break;
            }
            break;
          }
        case MS_MVCB:
          {
            switch(getSymbolAndBackColor(infoSkeleton,processMode,properties).symbolColor){
              case "#008040":
                setLineStatus(lineGreenSvg);
                switch (properties.exchangeNumber.toString()) {
                  case "0":
                    setImageStatus(imageMVCB_1_Green);
                    break;
                  case "1":
                    setImageStatus(imageMVCB_2_Green);
                    break;
                  case "2":
                    setImageStatus(imageMVCB_3_Green);
                    break;
                }
                break;
              case "#FF0000":
                setLineStatus(lineRedSvg);
                switch (properties.exchangeNumber.toString()) {
                  case "0":
                    setImageStatus(imageMVCB_1_Red);
                    break;
                  case "1":
                    setImageStatus(imageMVCB_2_Red);
                    break;
                  case "2":
                    setImageStatus(imageMVCB_3_Red);
                    break;
                }
                break;
              default:
                setLineStatus(lineSvg);
                switch (properties.exchangeNumber.toString()) {
                  case "0":
                    setImageStatus(imageMVCB_1);
                    break;
                  case "1":
                    setImageStatus(imageMVCB_2);
                    break;
                  case "2":
                    setImageStatus(imageMVCB_3);
                    break;
                }
                break;
            }
            break;
          }
        case MS_REACTOR:
          {
            switch(getSymbolAndBackColor(infoSkeleton,processMode,properties).symbolColor){
              case "#008040":
                setLineStatus(lineGreenSvg);
                setImageStatus(imageReactorGreenSvg);
                setLineOhmStatus(lineOhmGreenSvg);
                break;
              case "#FF0000":
                setLineStatus(lineRedSvg);
                setImageStatus(imageReactorRedSvg);
                setLineOhmStatus(lineOhmRedSvg);
                break;
              default:
                setLineStatus(lineSvg);
                setImageStatus(imageReactorSvg);
                setLineOhmStatus(lineOhmSvg);
                break;
            }
            break;
          }
        case MS_RELAY:
          {
            switch(getSymbolAndBackColor(infoSkeleton,processMode,properties).symbolColor){
              case "#008040":
                setLineStatus(lineGreenSvg);
                switch (properties.symKind.toString()) {
                  case "0":
                    setImageStatus(imageRelay_1_Green);
                    break;
                  case "1":
                    setImageStatus(imageRelay_2_Green);
                    break;
                  case "2":
                    setImageStatus(imageRelay_3_Green);
                    break;
                }
                break;
              case "#FF0000":
                setLineStatus(lineRedSvg);
                switch (properties.symKind.toString()) {
                  case "0":
                    setImageStatus(imageRelay_1_Red);
                    break;
                  case "1":
                    setImageStatus(imageRelay_2_Red);
                    break;
                  case "2":
                    setImageStatus(imageRelay_3_Red);
                    break;
                }
                break;
              default:
                setLineStatus(lineSvg);
                switch (properties.symKind.toString()) {
                  case "0":
                    setImageStatus(imageRelay_1);
                    break;
                  case "1":
                    setImageStatus(imageRelay_2);
                    break;
                  case "2":
                    setImageStatus(imageRelay_3);
                    break;
                }
                break;
            }
            break;
          }
        case MS_THERMAL:
          {
            switch(getSymbolAndBackColor(infoSkeleton,processMode,properties).symbolColor){
              case "#008040":
                setLineStatus(lineGreenSvg);
                switch (properties.symKind.toString()) {
                  case "0":
                    setImageStatus(imageThermal_1_Green);
                    break;
                  case "1":
                    setImageStatus(imageThermal_2_Green);
                    break;
                }
                break;
              case "#FF0000":
                setLineStatus(lineRedSvg);
                switch (properties.symKind.toString()) {
                  case "0":
                    setImageStatus(imageThermal_1_Red);
                    break;
                  case "1":
                    setImageStatus(imageThermal_2_Red);
                    break;
                }
                break;
              default:
                setLineStatus(lineSvg);
                switch (properties.symKind.toString()) {
                  case "0":
                    setImageStatus(imageThermal_1);
                    break;
                  case "1":
                    setImageStatus(imageThermal_2);
                    break;
                }
                break;
            }
            break;
          }
        case MS_THERMAL_CT:
          {
            switch(getSymbolAndBackColor(infoSkeleton,processMode,properties).symbolColor){
              case "#008040":
                setLineStatus(lineGreenSvg);
                setImageStatus(imageThermalCTGreenSvg);
                break;
              case "#FF0000":
                setLineStatus(lineRedSvg);
                setImageStatus(imageThermalCTRedSvg);
                break;
              default:
                setLineStatus(lineSvg);
                setImageStatus(imageThermalCTSvg);
                break;
            }
            break;
          }
        case MS_TRANS1:
          {
            switch(getSymbolAndBackColor(infoSkeleton,processMode,properties).symbolColor){
              case "#008040":
                setLineStatus(lineGreenSvg);
                setLineOhmStatus(lineOhmGreenSvg);
                switch (properties.symKind.toString()) {
                  case "0":
                    setImageStatus(imageTrans1_1_Green);
                    break;
                  case "1":
                    setImageStatus(imageTrans1_2_Green);
                    break;
                }
                break;
              case "#FF0000":
                setLineStatus(lineRedSvg);
                setLineOhmStatus(lineOhmRedSvg);
                switch (properties.symKind.toString()) {
                  case "0":
                    setImageStatus(imageTrans1_1_Red);
                    break;
                  case "1":
                    setImageStatus(imageTrans1_2_Red);
                    break;
                }
                break;
              default:
                setLineStatus(lineSvg);
                setLineOhmStatus(lineOhmSvg);
                switch (properties.symKind.toString()) {
                  case "0":
                    setImageStatus(imageTrans1_1);
                    break;
                  case "1":
                    setImageStatus(imageTrans1_2);
                    break;
                }
                break;
            }
            break;
          }
        case MS_TRANS3:
          {
            switch(getSymbolAndBackColor(infoSkeleton,processMode,properties).symbolColor){
              case "#008040":
                setLineStatus(lineGreenSvg);
                setLineOhmStatus(lineOhmGreenSvg);
                switch (properties.symKind.toString()) {
                  case "0":
                    setImageStatus(properties.haveTab === true ? imageTrans3_0_T_Green : imageTrans3_0_F_Green);
                    break;
                  case "1":
                    setImageStatus(properties.haveTab === true ? imageTrans3_1_T_Green : imageTrans3_1_F_Green);
                    break;
                  case "2":
                    setImageStatus(properties.haveTab === true ? imageTrans3_2_T_Green : imageTrans3_2_F_Green);
                    break;
                  case "3":
                    setImageStatus(properties.haveTab === true ? imageTrans3_3_T_Green : imageTrans3_3_F_Green);
                    break;
                  case "4":
                    setImageStatus(properties.haveTab === true ? imageTrans3_4_T_Green : imageTrans3_4_F_Green);
                    break;
                }
                break;
              case "#FF0000":
                setLineStatus(lineRedSvg);
                setLineOhmStatus(lineOhmRedSvg);
                switch (properties.symKind.toString()) {
                  case "0":
                    setImageStatus(properties.haveTab === true ? imageTrans3_0_T_Red : imageTrans3_0_F_Red);
                    break;
                  case "1":
                    setImageStatus(properties.haveTab === true ? imageTrans3_1_T_Red : imageTrans3_1_F_Red);
                    break;
                  case "2":
                    setImageStatus(properties.haveTab === true ? imageTrans3_2_T_Red : imageTrans3_2_F_Red);
                    break;
                  case "3":
                    setImageStatus(properties.haveTab === true ? imageTrans3_3_T_Red : imageTrans3_3_F_Red);
                    break;
                  case "4":
                    setImageStatus(properties.haveTab === true ? imageTrans3_4_T_Red : imageTrans3_4_F_Red);
                    break;
                }
                break;
              default:
                setLineStatus(lineSvg);
                setLineOhmStatus(lineOhmSvg);
                switch (properties.symKind.toString()) {
                  case "0":
                    setImageStatus(properties.haveTab === true ? imageTrans3_0_T : imageTrans3_0_F);
                    break;
                  case "1":
                    setImageStatus(properties.haveTab === true ? imageTrans3_1_T : imageTrans3_1_F);
                    break;
                  case "2":
                    setImageStatus(properties.haveTab === true ? imageTrans3_2_T : imageTrans3_2_F);
                    break;
                  case "3":
                    setImageStatus(properties.haveTab === true ? imageTrans3_3_T : imageTrans3_3_F);
                    break;
                  case "4":
                    setImageStatus(properties.haveTab === true ? imageTrans3_4_T : imageTrans3_4_F);
                    break;
                }
                break;
            }
            break;
          }
        case MS_TRANSSCOTT:
          {
            switch(getSymbolAndBackColor(infoSkeleton,processMode,properties).symbolColor){
              case "#008040":
                setLineStatus(lineGreenSvg);
                setImageStatus(imageTransscottGreenSvg);
                setLineOhmStatus(lineOhmGreenSvg);
                break;
              case "#FF0000":
                setLineStatus(lineRedSvg);
                setImageStatus(imageTransscottRedSvg);
                setLineOhmStatus(lineOhmRedSvg);
                break;
              default:
                setLineStatus(lineSvg);
                setImageStatus(imageTransscottSvg);
                setLineOhmStatus(lineOhmSvg);
                break;
            }
            break;
          }
        case MS_VCT:
          {
            switch(getSymbolAndBackColor(infoSkeleton,processMode,properties).symbolColor){
              case "#008040":
                setLineStatus(lineGreenSvg);
                switch (properties.symKind.toString()) {
                  case "0":
                      setImageStatus(imageGreenVCT1Svg);
                      break;
                  case "1":
                      setImageStatus(imageGreenVCT2Svg);
                      break;
                  case "2":
                      setImageStatus(imageGreenVCT3Svg);
                      break;
                  }
                break;
              case "#FF0000":
                setLineStatus(lineRedSvg);
                switch (properties.symKind.toString()) {
                  case "0":
                      setImageStatus(imageRedVCT1Svg);
                      break;
                  case "1":
                      setImageStatus(imageRedVCT2Svg);
                      break;
                  case "2":
                      setImageStatus(imageRedVCT3Svg);
                      break;
                }
                break;
              default:
                setLineStatus(lineSvg);
                switch (properties.symKind.toString()) {
                  case "0":
                    setImageStatus(imageVCT1Svg);
                    break;
                  case "1":
                    setImageStatus(imageVCT2Svg);
                    break;
                  case "2":
                    setImageStatus(imageVCT3Svg);
                    break;
                }
                break;
            }
            break;
          }
        case MS_VT:
          {
            switch(getSymbolAndBackColor(infoSkeleton,processMode,properties).symbolColor){
              case "#008040":
                setLineStatus(lineGreenSvg);
                setImageStatus(imageVTGreenSvg);
                break;
              case "#FF0000":
                setLineStatus(lineRedSvg);
                setImageStatus(imageVTRedSvg);
                break;
              default:
                setLineStatus(lineSvg);
                setImageStatus(imageVTSvg);
                break;
            }
            break;
          }
        case MS_ZCT:
          {
            switch(getSymbolAndBackColor(infoSkeleton,processMode,properties).symbolColor){
              case "#008040":
                setLineStatus(lineGreenSvg);
                setImageStatus(imageZCTGreenSvg);
                break;
              case "#FF0000":
                setLineStatus(lineRedSvg);
                setImageStatus(imageZCTRedSvg);
                break;
              default:
                setLineStatus(lineSvg);
                setImageStatus(imageZCTSvg);
                break;
            }
            break;
          }
        case MS_WIRE:
          {
            switch(getSymbolAndBackColor(infoSkeleton,processMode,properties).symbolColor){
              case "#008040":
                setImageStatus(lineGreenSvg);
                setLineOhmStatus(lineOhmGreenSvg);
                setTriangleDownStatus(triangleGreenDown);
                setTriangleUpStatus(triangleGreenUp);
                break;
              case "#FF0000":
                setImageStatus(lineRedSvg);
                setLineOhmStatus(lineOhmRedSvg);
                setTriangleDownStatus(triangleRedDown);
                setTriangleUpStatus(triangleRedUp);
                break;
              default:
                setImageStatus(lineSvg);
                setLineOhmStatus(lineOhmSvg);
                setTriangleDownStatus(triangleDown);
                setTriangleUpStatus(triangleUp);
                break;
            }
            break;
          }
        case MS_ZEROCOND:
          {
            switch(getSymbolAndBackColor(infoSkeleton,processMode,properties).symbolColor){
              case "#008040":
                setLineStatus(lineGreenSvg);
                setImageStatus(imageZerocondGreenSvg);
                break;
              case "#FF0000":
                setLineStatus(lineRedSvg);
                setImageStatus(imageZerocondRedSvg);
                break;
              default:
                setLineStatus(lineSvg);
                setImageStatus(imageZerocondSvg);
                break;
            }
            break;
          }
    }
  },[getSymbolAndBackColor(infoSkeleton,processMode,properties).symbolColor, properties, type, processMode])
  //#endregion

  //#region Method

  const calcLabelPos = (e?: any) => {
    let newX = groupPosition.x,
      newY = groupPosition.y;
    if (e?.type === "dragmove") {
      newX = groupRef.current.attrs.x;
      newY = groupRef.current.attrs.y;
    }

    const newPosition = {
      x: newX + Constant.ELEMENT_SIZE,
      y: newY + CONTROL_POS_FACTOR * (height / 2),
    };

    if (rotation !== 0) {
      newPosition.x = newX + CONTROL_POS_FACTOR * (height / 2);
      newPosition.y = newY - Constant.ELEMENT_SIZE;
    }

  };

  const calcArrowPos = (e?: any) => {
    let newX = groupPosition.x,
      newY = groupPosition.y;
    if (e?.type === "dragmove") {
      newX = groupRef.current.attrs.x;
      newY = groupRef.current.attrs.y;
    }

    const newPosition = {
      x: newX,
      y: newY + height - Constant.ELEMENT_SIZE - 5,
    };

    if (rotation !== 0) {
      newPosition.y = newY;
      rotation === -90 &&
        (newPosition.x = newX + height - Constant.ELEMENT_SIZE - 10);
      rotation === 90 && (newPosition.x = newX + 10);
    }

    setArrowPosition(newPosition);
  };

  //#endregion
  return (
    <>
      <Group
        ref={groupRef}
        id={id}
        x={groupPosition.x}
        y={groupPosition.y}
        offset={type == MS_SOURCE || type == MS_GENERATOR || type ==MS_TRANS3 ? {x:0,y:0} : { x: offsetX, y: offsetY }}
        width={width}
        height={height}
        type={type}
        rotation={rotation}
      > 
        { type != MS_ZEROCOND &&
          <Image
            fill={getSymbolAndBackColor(infoSkeleton,processMode,properties).backColor}
            image={lineObj}
            height={type == MS_CABLEHEAD || type == MS_ZCT || type == MS_VT || type == MS_VCT || type == MS_CT || type == MS_INV || type == MS_RELAY ? height * 3 + 10 : height + 10}
          
          />
        }
      
        
        {
          ((type == MS_BUSBAR || type == MS_BUSDUCT || type == MS_REACTOR || type == MS_TRANS1 || type == MS_TRANS3 || type == MS_TRANSSCOTT || (type == MS_WIRE && (properties.exchangeNumber == WIRE_SYMBOL_CABLE || properties.exchangeNumber == WIRE_SYMBOL_NORMAL))) && processMode === Model.ProcessMode.PERCENT_Z) &&
          <>        
            <Image
              fill={getSymbolAndBackColor(infoSkeleton,processMode,properties).backColor}
              image={lineOhmObj}
              height={34}
              width={13}
              x={3.5}
              y={height / 2 - 17 }
            />
          </>
        }
        {
          ((type == MS_BUSBAR || type == MS_BUSDUCT || type == MS_WIRE) && processMode === Model.ProcessMode.POWER_FLOW) &&
          <>
            <Image
              fill={getSymbolAndBackColor(infoSkeleton,processMode,properties).backColor}
              image={lineOhmPercentZObj}
              height={Constant.ELEMENT_SIZE * 3}
              width={Constant.ELEMENT_SIZE}
              y={height / 2 - 30 }
            />
          </>
        }
        {(type == MS_SOURCE || type == MS_2E || type == MS_BUSBAR || type == MS_BUSDUCT) &&
        <>
          {((type != MS_BUSBAR && type != MS_BUSDUCT) || (processMode !== Model.ProcessMode.PERCENT_Z && processMode !== Model.ProcessMode.POWER_FLOW)) &&
            <Image
              image={type==MS_BUSBAR || type == MS_BUSDUCT ? lineObj : imageObj}
              fill={getSymbolAndBackColor(infoSkeleton,processMode,properties).backColor}
              width={type == MS_BUSBAR || type == MS_BUSDUCT? width : (type == MS_SOURCE? Constant.ELEMENT_SIZE : (properties.symKind.toString() === "0" ? Constant.ELEMENT_SIZE * 3.1/4 : Constant.ELEMENT_SIZE / 2))}
              height={type == MS_BUSBAR || type == MS_BUSDUCT? height : (type == MS_SOURCE? (properties.symKind.toString() === "0" ? Constant.ELEMENT_SIZE : Constant.ELEMENT_SIZE/4) : Constant.ELEMENT_SIZE * 1.5)}
              y={type == MS_SOURCE || type == MS_BUSBAR || type == MS_BUSDUCT? 0 : height / 2 - Constant.ELEMENT_SIZE * 3/4}
              offsetX={type == MS_SOURCE || type == MS_BUSBAR || type == MS_BUSDUCT? 0 : (properties.symKind.toString() === "0" ? 0 : -Constant.ELEMENT_SIZE / 2 + 2)}
            />
          }
        </>
        }

        {
          type == MS_GENERATOR &&
          <Image
            fill={getSymbolAndBackColor(infoSkeleton,processMode,properties).backColor}
            image = {imageObj}
            width = {Constant.ELEMENT_SIZE}
            height = {Constant.ELEMENT_SIZE}
          />
        }

        {type == MS_CABLEHEAD &&
            <Image
                fill={getSymbolAndBackColor(infoSkeleton,processMode,properties).backColor}
                image={imageObj}
                width={Constant.ELEMENT_SIZE}
                height={Constant.ELEMENT_SIZE}
                y={height}
            />
        } 

        {
          type == MS_CONNECT &&
          <>
             <Rect
              fill={getSymbolAndBackColor(infoSkeleton,processMode,properties).backColor}
              width={width}
              height={height * 2}
            />
            <Image
              fill={"white"}
              image={imageObj}
              width={Constant.ELEMENT_SIZE * 0.1}
              height={height * 2}
              x={Constant.ELEMENT_SIZE / 2 - 1}
            />
          </>
        }

        {
          type == MS_CONTACTOR &&
          <Image
            fill={getSymbolAndBackColor(infoSkeleton,processMode,properties).backColor}
            image={imageObj}
            width={Constant.ELEMENT_SIZE / 2}
            height={Constant.ELEMENT_SIZE * 1.5}
            y={height / 2 - 10}
            x={properties.exchangeNumber.toString() === "0" ? 0 : Constant.ELEMENT_SIZE / 2}
            offsetX={properties.exchangeNumber.toString() === "0" ? -0.95 : 0.85}
          />
        }

        {
          (type == MS_CT || type == MS_ZCT) &&
          <Image
            fill={getSymbolAndBackColor(infoSkeleton,processMode,properties).backColor}
            image={imageObj}
            width={Constant.ELEMENT_SIZE}
            height={Constant.ELEMENT_SIZE}
            y={height / 2 - 10}
            x={1}
          />
        }

        {
          (type == MS_DS || type == MS_VT || type == MS_LBS) &&
          <Image
            fill={getSymbolAndBackColor(infoSkeleton,processMode,properties).backColor}
            image={imageObj}
            width={Constant.ELEMENT_SIZE}
            height={Constant.ELEMENT_SIZE}
            y={height / 2 - 10}
          />
        }

        {
          type == MS_FUSE &&
          <Image
            fill={getSymbolAndBackColor(infoSkeleton,processMode,properties).backColor}
            image={imageObj}
            width={properties.symKind.toString() !== "3" ? Constant.ELEMENT_SIZE : (Constant.ELEMENT_SIZE / 3.5)}
            height={Constant.ELEMENT_SIZE * 1.5}  
            x={properties.symKind.toString() !== "3" ? 0 : (Constant.ELEMENT_SIZE / 2) - 3}
            y={height / 2 - Constant.ELEMENT_SIZE * 3/4}
          />
        }

        {
          type == MS_HVCB &&
          <>
           
            <Image
              fill={getSymbolAndBackColor(infoSkeleton,processMode,properties).backColor}
              image={imageObj}
              width={width}
              height={Constant.ELEMENT_SIZE * 2}
              y={height / 2 - 30}
            />
          </>
        }

        {
          type == MS_IMPEDANCE && 
          <Image
            fill={getSymbolAndBackColor(infoSkeleton,processMode,properties).backColor}
            image={imageObj}
            width={Constant.ELEMENT_SIZE}
            height={imageStatus === imageImpedance_r_x ? Constant.ELEMENT_SIZE * 3 : Constant.ELEMENT_SIZE * 2}
            y={imageStatus === imageImpedance_r_x ? height / 2 - 30 : height / 2 - 20}
          />
        }

        {
          type == MS_LVCB && 
          <Image
            fill={getSymbolAndBackColor(infoSkeleton,processMode,properties).backColor}
            image={imageObj}
            width={
              ["1", "3"].includes(properties.symKind.toString())
                ? Constant.ELEMENT_SIZE / 2
                : Constant.ELEMENT_SIZE
            }
            height={Constant.ELEMENT_SIZE * 2}
            x={
              properties.symKind.toString() === "1" 
                ? Constant.ELEMENT_SIZE / 2 - 2
                : properties.symKind.toString() === "3"
                  ? Constant.ELEMENT_SIZE / 2 - 3.5
                  : 0
            }
            y={height / 2 - Constant.ELEMENT_SIZE}
          />
        }

        {
          type == MS_MVCB &&
          <Image
            fill={getSymbolAndBackColor(infoSkeleton,processMode,properties).backColor}
            image={imageObj}
            width={Constant.ELEMENT_SIZE}
            height={properties.exchangeNumber == 1 ? Constant.ELEMENT_SIZE * 2 : Constant.ELEMENT_SIZE}
            y={height / 2 - 10}
          />
        }

        {
          type == MS_REACTOR && processMode !== Model.ProcessMode.PERCENT_Z &&
            <Image
              fill={getSymbolAndBackColor(infoSkeleton,processMode,properties).backColor}
              image={imageObj}
              width={Constant.ELEMENT_SIZE / 2}
              height={Constant.ELEMENT_SIZE * 1.5}
              x={Constant.ELEMENT_SIZE / 2 - 5}
              y={height / 2 - Constant.ELEMENT_SIZE * 1.5 / 2}
            />
        }

        {
          (type == MS_RELAY || type == MS_VCT || type == MS_INV) && 
          <Image
            fill={getSymbolAndBackColor(infoSkeleton,processMode,properties).backColor}
            image={imageObj}
            width={Constant.ELEMENT_SIZE}
            height={Constant.ELEMENT_SIZE/2}
            y={height / 2 - 5}
          />
        }

        {
          type == MS_THERMAL &&
          <Image
            fill={getSymbolAndBackColor(infoSkeleton,processMode,properties).backColor}
            image={imageObj}
            width={properties.symKind.toString() === '0' ? Constant.ELEMENT_SIZE * 3/4 : Constant.ELEMENT_SIZE / 2}
            height={Constant.ELEMENT_SIZE * 2}
            y={height / 2 - Constant.ELEMENT_SIZE}
            x={properties.symKind.toString() === '0' ? 1 : Constant.ELEMENT_SIZE / 2 - 1}
          />
        }

        {
          type == MS_THERMAL_CT &&
          <Image
            fill={getSymbolAndBackColor(infoSkeleton,processMode,properties).backColor}
            image={imageObj}
            width={width}
            height={Constant.ELEMENT_SIZE * 2}
            y={height / 2 - Constant.ELEMENT_SIZE}
          />
        }

        {
          type == MS_TRANS1 && processMode !== ProcessMode.PERCENT_Z &&
          <Image
            fill={getSymbolAndBackColor(infoSkeleton,processMode,properties).backColor}
            image={imageObj}
            width={Constant.ELEMENT_SIZE}
            height={Constant.ELEMENT_SIZE}
            y={height / 2 - 40}
            offsetX={properties.symKind == 1 ? 0 : -2}
          />
        }

        {
          type == MS_TRANS3 && processMode !== ProcessMode.PERCENT_Z &&
          <Image
            fill={getSymbolAndBackColor(infoSkeleton,processMode,properties).backColor}
            image={imageObj}
            width={properties.haveTab ? Constant.ELEMENT_SIZE - 4 : Constant.ELEMENT_SIZE - 7}
            height={Constant.ELEMENT_SIZE}
            x={properties.haveTab ? 2 : 3}
            y={height / 2 - 10}
          />
        }

        {
          type == MS_TRANSSCOTT && processMode !== ProcessMode.PERCENT_Z && 
          <Image
            fill={getSymbolAndBackColor(infoSkeleton,processMode,properties).backColor}
            image={imageObj}
            width={Constant.ELEMENT_SIZE}
            height={Constant.ELEMENT_SIZE}
            y={height / 2 - 40}
          />
        }

        {
          type == MS_WIRE &&
          <>
            
            {
              (properties.exchangeNumber == WIRE_SYMBOL_NORMAL && processMode !== ProcessMode.PERCENT_Z && processMode !== ProcessMode.POWER_FLOW)&& 
              <>
                <Image
                  fill={getSymbolAndBackColor(infoSkeleton,processMode,properties).backColor}
                  image={triangleDownObj}
                  y={height - 12}
                  x={5}
                  height={10}
                  width={width/2}
                />
                <Image
                  fill={getSymbolAndBackColor(infoSkeleton,processMode,properties).backColor}
                  image={triangleUpObj}
                  y={2}
                  x={5}
                  height={10}
                  width={width/2}
                />
              </>
            }
            {
              (properties.exchangeNumber == WIRE_SYMBOL_CABLE &&
              processMode !== ProcessMode.PERCENT_Z && processMode !== ProcessMode.POWER_FLOW) &&
              <Image
                  fill={getSymbolAndBackColor(infoSkeleton,processMode,properties).backColor}
                  image           = {imageObj}
                  height          = {height}
                  width={width}
              />
            }
          </>
        }

        {
          type == MS_ZEROCOND &&
          <>
            <Image
              fill={getSymbolAndBackColor(infoSkeleton,processMode,properties).backColor}
              image={imageObj}
              width={10}
              height={100}
              y={0}
              x={0}
            />
            <Image 
                rotation={90}
                image={lineObj}
                height={Constant.ELEMENT_SIZE * 3 + 30}  
                width={Constant.ELEMENT_SIZE}
                y={20}
                x={20}
              />      
          </>
        }
      </Group>
      {( type != MS_RELAY && type != MS_ZCT && type != MS_SOURCE && type != MS_INV && type != MS_GENERATOR && type != MS_CONNECT && type != MS_CABLEHEAD && type != MS_CT && type != MS_VCT && type != MS_VT && (properties?.dropMotor || properties?.dropRegular || properties?.partOutIn?.dropRegular || properties?.partOutOut?.dropRegular || properties?.partSeatM?.dropRegular || properties?.partSeatT?.dropRegular) &&!([Model.ProcessMode.CALCULATION,Model.ProcessMode.IMPROVE_PF,Model.ProcessMode.HARMONIC,Model.ProcessMode.POWER_FLOW].includes(processMode))) && (
        <Image
          ref={leftArrowRef}
          listening={false}
          offsetY={[90, -90].includes(rotation) ? Constant.ELEMENT_SIZE / 4 - 2.5 : -2.5}
          x={type == MS_ZEROCOND? 530 : arrowPosition.x}
          y={type == MS_TRANS1 || type == MS_TRANSSCOTT? arrowPosition.y - 40 : arrowPosition.y}
          image={leftArrowObj}
          width={Constant.ELEMENT_SIZE / 2}
          height={Constant.ELEMENT_SIZE}
        />
      )}
      

      {((properties?.fault || properties?.partOutIn?.fault || properties?.partOutOut?.fault || properties?.partSeatM?.fault || properties?.partSeatT?.fault) && type != MS_RELAY && type != MS_VCT && type != MS_VT && type != MS_INV && type != MS_CONNECT && type != MS_CABLEHEAD && type != MS_CT && type != MS_ZCT && !([Model.ProcessMode.VOLTDROP,Model.ProcessMode.IMPROVE_PF,Model.ProcessMode.HARMONIC,Model.ProcessMode.POWER_FLOW].includes(processMode))) && (
        <>
          {
            type == MS_HVCB?
              <Image
              ref={rightArrowRef}
              listening={false}
              offsetY={[90, -90].includes(rotation) ? Constant.ELEMENT_SIZE / 4 : 0}
              offsetX={-Constant.ELEMENT_SIZE / 2}
              x={rotation === 90 ? arrowPosition.x - 5 : arrowPosition.x}
              y={rotation === -90 ? arrowPosition.y : rotation === 0? arrowPosition.y : arrowPosition.y}
              image={rightArrowObj}
              width={Constant.ELEMENT_SIZE * 2}
              height={Constant.ELEMENT_SIZE * 2}
            />
            :
            <Image
            ref={rightArrowRef}
            listening={false}
            offsetY={[90, -90].includes(rotation) ? (type == MS_SOURCE || type == MS_GENERATOR? Constant.ELEMENT_SIZE / 2 :  Constant.ELEMENT_SIZE / 4 ): 0}
            offsetX={-Constant.ELEMENT_SIZE / 2}
            x={type == MS_ZEROCOND? 530 : arrowPosition.x}
            y={type == MS_SOURCE || type == MS_GENERATOR? arrowPosition.y + 8 : (type == MS_TRANS1 || type == MS_TRANSSCOTT? arrowPosition.y - 40 : arrowPosition.y)}
            image={rightArrowObj}
            width={Constant.ELEMENT_SIZE * 2}
            height={Constant.ELEMENT_SIZE * 2}
            />
            
          }
        </>
      )}

      <Transformer
        ref={transformRef}
        rotateEnabled={false}
        resizeEnabled={false}
        keepRatio={false}
        borderDash={[4]}
        anchorStroke="black"
        borderStroke="black"
        anchorFill="black"
      />

      <Transformer
        ref={transformMoveRef}
        rotateEnabled={false}
        resizeEnabled={false}
        keepRatio={false}
        borderEnabled={false}
        anchorStroke="black"
        borderStroke="black"
        anchorFill="black"
      />
      
    </>
  );
});

const mapStateToProps = (state: any) => {
  return {
    userId: state.app.user?.userId,
    projectData: state.app.projectData,
    diagramData: state.app.diagram.diagramData.filter((r: any) => r.tabId === state.app.diagram.currentIDDiagramTab),
    processMode: state.app.diagram.processMode,
    infoCircuit: state.app.diagram.infoCircuit,
    infoSkeleton: state.app.diagram.infoSkeleton,
    drawPowerArrow: state.app.diagram.isDrawPowerArrow,
    isSelectTouchGroup: state.app.diagram.isSelectTouchGroup,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateSelectControl: (controlId: string, isMultiple : boolean = false) =>
      dispatch(updateSelectControl(controlId, isMultiple)),
    updateControlData: (id: string, data: object) =>
      dispatch(updatePropertiesOfControlAction(id, data)),
    updateControlHeight: (id: string, height: number, x: number, y: number, offsetY: number) =>
      dispatch(updateControlHeight(id, height, x, y, offsetY)),
    setTransformFlag: (visible: boolean) => dispatch(setTransformFlag(visible)),
    setOpenChart: (isOpenChart: boolean, lineType : string, lineId : number) => dispatch(openChart(isOpenChart, lineType, lineId)),
    mappedDataFromBE: (elements:any) => dispatch(actionCreators.mappedData(elements)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateMsHiddenTopControlTranscenter);
