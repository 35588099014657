import { CdPoint, MsTrans1Model } from "../../../models/Index";

// 励磁突入電流曲線を描画する
const doDrawInrushPart = (dRatedCurrent:number, props:MsTrans1Model, m_listDrawInrush:Array<CdPoint>,m_part:number) : boolean => {
    if (doMakeCurveInrush(dRatedCurrent, props, m_listDrawInrush,m_part) !== true)
    {
            return false;
    }

    return true;
}

// 励磁突入電流曲線を作成する
const doMakeCurveInrush = (dRatedCurrent:number, props:MsTrans1Model, m_listDrawInrush:Array<CdPoint>,m_part:number) : boolean => {
                let m_dDampingTime =0;
                let m_dInrushTimes =0;
           
            
            if (dRatedCurrent <= 0)
            {
                return false;
            }
            if(m_part === 1){
                m_dInrushTimes = props.partOutIn.inrushTimes;
                m_dDampingTime = props.partOutIn.dampingTime;
                
            }
            if(m_part ===2){
                m_dInrushTimes = props.partOutOut.inrushTimes;
                m_dDampingTime = props.partOutOut.dampingTime;
            }

            let pdPoint : CdPoint = {
                m_dx: 0,
                m_dy: 0
            };

            let nCount = 0;
            for (let dTime = m_dDampingTime * 10; dTime >= 0.01; dTime -= (dTime / 10))
            {                
                const dCurrent = dRatedCurrent * m_dInrushTimes * Math.exp(-1 * (dTime - 0.01) / (m_dDampingTime / 1000));
                if (dCurrent >= dRatedCurrent)
                {
                    if (nCount === 0)
                    {
                        
                        pdPoint.m_dx = dRatedCurrent;
                        pdPoint.m_dy = dTime * 1000;
                        m_listDrawInrush.push({...pdPoint})
                                
                                
                        pdPoint.m_dx = dRatedCurrent;
                        pdPoint.m_dy = dTime + dTime / 5;
                        m_listDrawInrush.push({...pdPoint})
                                
                                
                        pdPoint.m_dx = dRatedCurrent + dRatedCurrent / 30;
                        pdPoint.m_dy = dTime + dTime / 10;
                        m_listDrawInrush.push({...pdPoint})
                                
        
                        nCount++;
                        if (dCurrent <= pdPoint.m_dx)
                        {
                            
                            pdPoint.m_dx = dRatedCurrent + dRatedCurrent / 10;
                            pdPoint.m_dy = dTime;
                            m_listDrawInrush.push({...pdPoint})
                                        
                            continue;
                        }
                    }
                    
                    pdPoint.m_dx = dCurrent;
                    pdPoint.m_dy = dTime;
                    m_listDrawInrush.push({...pdPoint})                        
                }
            }
            
            pdPoint.m_dx = dRatedCurrent * m_dInrushTimes;
            pdPoint.m_dy = 0.01;
            m_listDrawInrush.push({...pdPoint})            
            
            pdPoint.m_dx = dRatedCurrent * m_dInrushTimes - dRatedCurrent * m_dInrushTimes / 100;
            pdPoint.m_dy = 0.0095;
            m_listDrawInrush.push({...pdPoint})            
            
            pdPoint.m_dx = dRatedCurrent * m_dInrushTimes - dRatedCurrent * m_dInrushTimes / 30;
            pdPoint.m_dy = 0.009;
            m_listDrawInrush.push({...pdPoint})            
            
            pdPoint.m_dx = dRatedCurrent * m_dInrushTimes - dRatedCurrent * m_dInrushTimes / 15;
            pdPoint.m_dy = 0.0087;
            m_listDrawInrush.push({...pdPoint})            
            
            pdPoint.m_dx = dRatedCurrent * m_dInrushTimes - dRatedCurrent * m_dInrushTimes / 5;
            pdPoint.m_dy = 0.0082;
            m_listDrawInrush.push({...pdPoint})            
            
            pdPoint.m_dx = dRatedCurrent / 10;
            pdPoint.m_dy = 0.005;
            m_listDrawInrush.push({...pdPoint})

            return true;
}

// 熱的機械的強度ポイントを描画する
const doDrawIntensityPart = (dRatedCurrent:number, props:MsTrans1Model, m_listDrawIntensity:Array<CdPoint>, dCapacity:number,m_part:number) => {
    
    if (doMakeCurveIntensity(dRatedCurrent, props, m_listDrawIntensity, dCapacity,m_part) !== true)
    {
        return false;
        //return ;
    }
    return true;
}

// 熱的機械的強度ポイントを作成する
const doMakeCurveIntensity = (dRatedCurrent:number, props:MsTrans1Model, m_listDrawIntensity:Array<CdPoint>, dCapacity:number,m_part:number): boolean => {
    let m_dIntensityTimes = 0;
    let m_dIntensityTime = 0;
    let pdPoint : CdPoint = {
        m_dx: 0,
        m_dy: 0
    };
    if (dRatedCurrent <= 0)
    {
        return false;
    }

    // const {
    //     field38: m_dIntensityTimes, //not sure
    //     field34: m_dIntensityTime //not sure
    // } = props
   if(m_part ===1){
    m_dIntensityTimes =props.partOutIn.intensityTimes;
    m_dIntensityTime = props.partOutIn.intensityTime;
   }
   if(m_part ===2){
    m_dIntensityTimes =props.partOutOut.intensityTimes;
    m_dIntensityTime = props.partOutOut.intensityTime;
   }

    

    pdPoint.m_dx = dRatedCurrent * m_dIntensityTimes;
    pdPoint.m_dy = m_dIntensityTime;
    m_listDrawIntensity.push({...pdPoint})

    return true;
}

export {
    doMakeCurveInrush,
    doDrawInrushPart,
    doDrawIntensityPart
}