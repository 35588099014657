import { Box, Button, Checkbox, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Stack, Tab, Tabs, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { InfoCircuit, MsReactorModel } from '../../models/Index';
import TextFieldOptions from "../common/TextFieldOptions";
import { BeamsNumber } from "../../utils/FormatNumber";
import { CALC_IK2, CALC_IK2_OUTSIDE_NEUTRAL, CALC_IK2_OUTSIDE_OUTSIDE, CALC_IK2_SEAT_MAIN, CALC_IK2_SEAT_T, CALC_IK3, MS_TRANS1, VALUE_NODE_VOLT_MIN } from "../../models/Constants";
import * as Constant from "../../models/Constants";
import { doGetTextDropVoltPF, doGetTextLoadCurrent } from "../../utils/reactorFunction";
import { MS_MODE_CHECKING } from "../../statics";

export type Image2_13EditDialogFormProps = {
    data: any;
    onOK: (data: any) => void;
    onCancel: () => void;
    infoCircuit: InfoCircuit;
    userRole:string;
    m_bModePM: boolean;
    dispInputDialog: any;
    processMode:number;
    modeViewOnly:boolean;
};

function a11yProps(index: number) {
    return {
        id: `tab-${index}`,
        "aria-controls": `tabpanel-${index}`,
    };
}

const radioStyles = {
    padding: '0 0 0 9px',
}
const checkboxStyle = {
    padding: '0px 0px 0px 9px'
}
const checkboxStyles = {
    padding: '4px 4px 4px 8px'
}
//Tabs------------------
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

export const MsReactorEditDialog = (props: Image2_13EditDialogFormProps) => {
    const { userRole, data, modeViewOnly, onOK, onCancel,infoCircuit ,m_bModePM,dispInputDialog,processMode} = props;
    const [selectTab, setSelectTab] = useState(0);
    const [isDisableOKBtn, setIsDisableOKBtn] = useState(false);
   

    
    // 初期値設定
    const setInit=(data:MsReactorModel) =>{
        let loadCapacity = 0
        let loadCurrent = 0
        let leadOrLag= 0
        let powerFactor = 1
        if(data.downTransKind == MS_TRANS1){
            loadCapacity = BeamsNumber(data.calcPoint1.loadCapacity,7)
            loadCurrent = BeamsNumber(data.calcPoint1.loadCurrent,5)
            leadOrLag = data.calcPoint1.isLead=== true?1:0
            powerFactor = BeamsNumber(data.calcPoint1.powerFactor,5)
        }else{
            loadCapacity = BeamsNumber(data.calcPoint0.loadCapacity,7)
            loadCurrent = BeamsNumber(data.calcPoint0.loadCurrent,5)
            leadOrLag = data.calcPoint0.isLead=== true?1:0
            powerFactor = BeamsNumber(data.calcPoint0.powerFactor,5)

        }
        return {loadCapacity,loadCurrent,leadOrLag,powerFactor}
    }
    const initialValue: MsReactorModel = {
        ...data,
        loadCapacity: setInit(data).loadCapacity,
        loadCurrent: setInit(data).loadCurrent,
        leadOrLag: setInit(data).leadOrLag,
        powerFactor: setInit(data).powerFactor,
    }
    const [makeDataInput, setMakeDataInput] = useState(initialValue.makeDataInput); // ModePM
    const [isDisableFieldTap2, setIsDisableFieldTap2] = useState(initialValue.dropRegular);


    const { control, handleSubmit, formState: { errors }, setValue ,getValues,clearErrors,setError} = useForm<MsReactorModel>({
        mode: 'all',
        criteriaMode: "all",
        defaultValues: initialValue,
    });

    // Submitイベント
    const handleEditSubmit = (value: MsReactorModel) => {
        const request = createRequestData(value);
        onOK(request);
    }

    const DisableFieldTap2 =(e:any) =>{
        setValue(e.target.name,e.target.checked)
        setIsDisableFieldTap2(e.target.checked);

        // if(!getValues("dropRegular")){
            
        //     setIsDisableFieldTap2(true)
        //     setValue("dropRegular",false)

        // }
        // else{
        //     setIsDisableFieldTap2(false)
            
        // }
    }
    // FormデータからRequestデータを作成する
    const createRequestData = (formValue: MsReactorModel) => {
        if(!Number(formValue.loadCurrent)){
            formValue.loadCurrent = 0
        }
        if(!Number(formValue.loadCapacity)){
            formValue.loadCapacity = 0
        }
        if(!Number(formValue.calcPoint0.loadCurrent)){
            formValue.calcPoint0.loadCurrent = 0
        }
        if(!Number(formValue.calcPoint0.loadCapacity)){
            formValue.calcPoint0.loadCapacity = 0
        }
        if(!Number(formValue.calcPoint0.powerFactor)){
            formValue.calcPoint0.powerFactor = 0
        }
        if(!Number(formValue.calcPoint1.loadCurrent)){
            formValue.calcPoint1.loadCurrent = 0
        }
        if(!Number(formValue.calcPoint1.loadCapacity)){
            formValue.calcPoint1.loadCapacity = 0
        }
        if(!Number(formValue.calcPoint1.powerFactor)){
            formValue.calcPoint1.powerFactor = 0
        }
        if(!Number(formValue.powerFactor)){
            formValue.powerFactor = 0
        }
        
        let request = {

           ...formValue
        } as MsReactorModel;
        return request;
    }

    // キャンセル
    const handleCancel = () => {
        onCancel();
    };

    const handleChangeFormat = (e: any) => {
        setValue(e.target.name, e.target.value,{shouldValidate:true});
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        if (newValue >= 0) {
            if (getValues('refNo') == "" ||getValues('refNo') == null || getValues('refNo') == undefined ) {
                setError("refNo",{ type: 'custom', message: '必須項目です。入力してください。' });
            }
            else if (isNaN(Number(getValues('voltage'))) || Number(getValues('voltage')) <= 0 || !/^[0-9\b]+$/.test(getValues('voltage').toString())) {
                setError("voltage",{ type: 'custom', message: '整数 > 0 を入力してください。' });
            }
            else if (isNaN(Number(getValues('capacity'))) || Number(getValues('capacity')) <= 0) {
                setError("capacity",{ type: 'custom', message: '数字 > 0 を入力してください。' });
            }
            else if (isNaN(Number(getValues('percentX'))) || Number(getValues('percentX')) <= 0) {
                setError("percentX",{ type: 'custom', message: '数字 > 0 を入力してください。' });
            }
            else if(!/^[0-9]*[.]?[0-9]+$/.test(getValues('loadCurrent').toString()) || Number(getValues('loadCurrent')) < 0){
                setError("loadCurrent",{ type: 'custom', message: '数字 >= 0 を入力してください。' });
            }
            else if(!/^[0-9]*[.]?[0-9]+$/.test(getValues('loadCapacity').toString()) || Number(getValues('loadCapacity')) < 0){
                setError("loadCapacity",{ type: 'custom', message: '数字 >= 0 を入力してください。' });
            }
            else if(!/^[0-9]*[.]?[0-9]+$/.test(getValues('powerFactor').toString()) || Number(getValues('powerFactor')) < 0 || Number(getValues('powerFactor')) > 1){
                setError("powerFactor",{ type: 'custom', message: '数字 0 ~ 1 を入力してください。' });
            }
            else{
                setSelectTab(newValue);
            }
        }
    };
    // const handleInputVoltage = (e: any) => {
    //     setValue(e.target.name, e.target.value)
    //     const isInteger = /^\d*$/.test(getValues("voltage").toString())
    //     if(getValues("voltage") > 0 && isInteger ){
    //         setIsDisableOKBtn(false);
    //     }
    //     else{
    //         setIsDisableOKBtn(true);
    //     }
    // }
    const handleInput = (e: any) => {
        setValue(e.target.name, e.target.value)
        if(getValues("capacity") > 0 &&  getValues("percentX") > 0){
            setIsDisableOKBtn(false);
        }
        else{
            setIsDisableOKBtn(true);
        }
    }
   
    //Cacl rateCurrent field
   const calcRatedCurrentField =(e:any) =>{
        setValue(e.target.name,e.target.value)
         let ratedCurrent = 0;
         let voltage = getValues("voltage")
         let capcity = getValues("capacity")
         if(capcity < 0 || voltage <= 0){
            setIsDisableOKBtn(true)
         }else{
            if(capcity > 0 && voltage > 0){
            setIsDisableOKBtn(false)
            }
            else{
            setIsDisableOKBtn(true)

            }

         }
         if(voltage === 0){
            ratedCurrent = 0;
         }else{
            ratedCurrent = capcity * 1000 /voltage/Math.sqrt(3.0)
         }
         doSetValueVoltDrop(e)
         setValue("ratedCurrent",BeamsNumber(ratedCurrent,4))
    }
    const doSetValueDropLoadCapacity = (voltage:number,loadCurrent:number,calc_kind:number) =>{
        let loadCapacity = voltage * loadCurrent;
        if(calc_kind == CALC_IK3){
            loadCapacity *=Math.sqrt(3.0)
        }
        return loadCapacity

    }
    const doGetValueDropPerRegular = (voltage:number,dPerRk3:number,dPerX3:number) => {
        const dStdCapacity = infoCircuit.stdCapacity;//基準容量
        let powerFactor = getValues("powerFactor")
        let loadCurrent = getValues("loadCurrent")
        if( powerFactor < 0 || dStdCapacity <=0){
            return 0
        }
        let dCapacity = voltage * loadCurrent
        let m_nCalcKind = 0//hard code 短絡電流種類
        if(getValues("downTransKind") === MS_TRANS1){
            m_nCalcKind = getValues("calcPoint1.calcKind")
        }else{
            m_nCalcKind = getValues("calcPoint0.calcKind")
        }
        switch(m_nCalcKind) {
            case CALC_IK3: 
                dCapacity *= Math.sqrt(3.0)
                break;
            case CALC_IK2:
                dCapacity *= 2      
                break;
            case CALC_IK2_OUTSIDE_NEUTRAL:
                break;
            case CALC_IK2_OUTSIDE_OUTSIDE:
                dCapacity *=2;
                break;
            case CALC_IK2_SEAT_MAIN:
                dCapacity *=2;
                break;
            case CALC_IK2_SEAT_T:
                dCapacity *=2;
                break;         
        }
        let dPerCent = 0
            if(powerFactor * powerFactor <=1) {
                if(+getValues("leadOrLag") === 1){
                    dPerCent = dPerRk3 * powerFactor - dPerX3 * Math.sqrt(1 - powerFactor *powerFactor);
                }
                else{
                    dPerCent = dPerRk3 * powerFactor + dPerX3 * Math.sqrt(1- powerFactor * powerFactor)
               

                }
            }
            dPerCent *= dCapacity;
            dPerCent /= dStdCapacity * 1000;
            dPerCent /=100;
            if(Math.abs(dPerCent) < VALUE_NODE_VOLT_MIN){
                dPerCent = 0
            }
            

            return dPerCent
    }
    const doGetValeDropVoltRegular = (voltage:number,dPerRk3:number,dPerX3:number) =>{
        
        let dPerCent  = doGetValueDropPerRegular(voltage,dPerRk3,dPerX3)
        let dRopVolt = voltage - voltage * dPerCent
        if(Math.abs(dRopVolt) < VALUE_NODE_VOLT_MIN) {
            dRopVolt = 0
        }
       

        return dRopVolt
    }
    const doGetDropVoltDiff = (e:any,dStdCapacity:number,voltage:number) =>{ 
        // setValue(e.target.name, e.target.value)
        let percentZ = doGetPerZrReactor(e,dStdCapacity)
        let dVolt = 0;
        if(getValues("downTransKind") === MS_TRANS1){
            dVolt = doGetValeDropVoltRegular(voltage, percentZ.dPerRk3, percentZ.dPerX3)
        }else{
            dVolt =  doGetValeDropVoltRegular(voltage, percentZ.dPerRk3, percentZ.dPerX3)
        }
        dVolt = voltage  - dVolt;
        if(+getValues("leadOrLag")==1 && dVolt!=0){
            dVolt = (-1) * BeamsNumber(dVolt,4)
        }
        else{
            dVolt = BeamsNumber(dVolt,4)
        }
       
        return dVolt // 4

    }
    const doGetDropPerUnit = (e:any,dStdCapacity:number,voltage:number) =>{
        // setValue(e.target.name, e.target.value)
        let percentZ = doGetPerZrReactor(e,dStdCapacity)
        let dVolt = 0
        let m_nDownTransKind = 0 //haven't known yet
        if(getValues("downTransKind") === MS_TRANS1){
            setValue("calcPoint1.stdCapacity",dStdCapacity)
            dVolt = doGetValeDropVoltRegular(voltage, percentZ.dPerRk3, percentZ.dPerX3)
        }else{
            setValue("calcPoint0.stdCapacity",dStdCapacity)
            dVolt = doGetValeDropVoltRegular(voltage, percentZ.dPerRk3, percentZ.dPerX3)

        }
        let dPerCent = 1 - dVolt / voltage
        dPerCent *= 100
        if (+getValues("leadOrLag")==1 && dPerCent !=0){
            dPerCent = (-1) * BeamsNumber(dPerCent,4)
        }
        else{
            dPerCent = BeamsNumber(dPerCent,4)
        }
        

        return dPerCent //4

    }
    const doGetPerZrReactor =(e:any,dStdCapacity:number) =>{
        // setValue(e.target.name,e.target.value)
        const capacity = getValues("capacity")
        let percentX = getValues("percentX")
        let persentZ = {
            dPerRk3:0,
            dPerX3 :0
        }
        if(capacity === 0){
            persentZ.dPerRk3 = 0
            persentZ.dPerX3 = 0
        }
        else{
            const m_dPerCentR = 0 //hard code
            persentZ.dPerRk3 =  m_dPerCentR * dStdCapacity / capacity
            persentZ.dPerX3 =  percentX * dStdCapacity / capacity;
        }
        
        return persentZ
    }
     //change value loadCurrent field
    const onEnChangeLoadCurrent = (e:any) =>{
        setValue(e.target.name,e.target.value)
        let voltage = getValues("voltage")
        let dcapacity = 0;
        let dloadCurrent = -1
        setValue("inputCurrent1",true)
        setValue("inputCapacity1",false)

        dloadCurrent = getValues("loadCurrent")
        if(dloadCurrent >=0){
            // dloadCurrent = getValues("loadCurrent")
            if(getValues("downTransKind") === MS_TRANS1){
                dcapacity = doSetValueDropLoadCapacity(voltage,dloadCurrent,getValues("calcPoint1.calcKind")) //7
                setValue("calcPoint1.loadCurrent",getValues("loadCurrent"))
                setValue("calcPoint1.loadCapacity",BeamsNumber(dcapacity,7))

            }else{
                dcapacity = doSetValueDropLoadCapacity(voltage,dloadCurrent,getValues("calcPoint0.calcKind")) //7
                setValue("calcPoint0.loadCurrent",getValues("loadCurrent"))
                setValue("calcPoint0.loadCapacity",BeamsNumber(dcapacity,7))

            }
            setIsDisableOKBtn(false);
        }
        else{
            setIsDisableOKBtn(true);
        }
        
        setValue("loadCapacity" , BeamsNumber(dcapacity,7))
        setValue("loadCurrent" , dloadCurrent)
        doSetValueVoltDrop(e);
        
    }
    const doSetValueDropLoadCurrent =(voltage :number) =>{
        let dLoadCurrent = getValues("loadCapacity") / voltage
        dLoadCurrent /= Math.sqrt(3.0)
        return dLoadCurrent
    }
     //change value loadCapacity field
     const onEnChangeLoadCapacity = (e: any) =>{
        setValue(e.target.name,e.target.value)
           let voltage = getValues("voltage")
           let dloadCurrent = 0
           let dloadCapacity = -1
           setValue("inputCapacity1",true)
           setValue("inputCurrent1",false)
           dloadCapacity = getValues("loadCapacity")
        
           if(dloadCapacity >= 0 ){
            // dloadCapacity = getValues("loadCapacity")
           
            setIsDisableOKBtn(false);
            if(getValues("downTransKind")=== MS_TRANS1){
                dloadCurrent = doSetValueDropLoadCurrent(voltage)
                setValue("calcPoint1.loadCapacity",+getValues("loadCapacity"))
                setValue("calcPoint1.loadCurrent",BeamsNumber(dloadCurrent,7))

            }else{
                dloadCurrent = doSetValueDropLoadCurrent(voltage)
                setValue("calcPoint0.loadCapacity",+getValues("loadCapacity"))
                setValue("calcPoint0.loadCurrent",BeamsNumber(dloadCurrent,7))
            }
           }else{
            setIsDisableOKBtn(true);
           }
           setValue("loadCapacity",dloadCapacity)
           setValue("loadCurrent",BeamsNumber(dloadCurrent,7))
           doSetValueVoltDrop(e)
     }
     const doSetValueVoltDrop = (e:any = null) =>{
        // setValue(e.target.name, e.target.value)
        let voltage = getValues ("voltage")
        const dStdCapacity = infoCircuit.stdCapacity;//基準容量
        let voltageDrop_1 = doGetDropVoltDiff(e,dStdCapacity,voltage)
        let voltageDrop = doGetDropPerUnit(e,dStdCapacity,voltage)
        setValue("voltageDrop1" , voltageDrop_1)
        setValue("voltageDrop",voltageDrop)
    }
    const handleInputPowerFactor = (e:any) =>{
        setValue(e.target.name, e.target.value)
        const powerFactor = getValues("powerFactor")
        if(getValues("downTransKind") === MS_TRANS1){
            setValue("inputPowerFactor1",true)
            setValue("calcPoint1.powerFactor",+getValues("powerFactor"))
        }else{
            setValue("inputPowerFactor1",true)
            setValue("calcPoint0.powerFactor",powerFactor)
        }
        if(powerFactor >= 0 && powerFactor <= 1){
            setIsDisableOKBtn(false);
        }else{
        setIsDisableOKBtn(true);
        }
    }

    
//負荷電流方向 の概算値　バーター
    const handleDeleteValueButton =() =>{
        const reactor = getValues()
        // setValue(e.target.name, e.target.value)
        setValue("inputCapacity1", false);
        setValue("inputCurrent1",false);
        if(getValues("downTransKind") == MS_TRANS1){
            clearErrors("loadCurrent")
            clearErrors("loadCapacity")
            setValue("calcPoint1.loadCurrent",dispInputDialog.load_current_1)
            let load_current = doGetTextLoadCurrent(reactor,false)
            let load_capacity = doSetValueDropLoadCapacity(getValues("voltage"),dispInputDialog.load_current_1,getValues("calcPoint1.calcKind"))
            setValue("calcPoint1.loadCurrent",+load_current)
            setValue("calcPoint1.loadCapacity",BeamsNumber(load_capacity,7))
            setValue("loadCurrent",getValues("calcPoint1.loadCurrent"))
            setValue("loadCapacity",getValues("calcPoint1.loadCapacity"))
        }
        else{
            clearErrors("loadCurrent")
            clearErrors("loadCapacity")
            setValue("calcPoint0.loadCurrent",dispInputDialog.load_current_0)
            let load_current = doGetTextLoadCurrent(reactor,false)
            let load_capacity = doSetValueDropLoadCapacity(getValues("voltage"),dispInputDialog.load_current_0,getValues("calcPoint0.calcKind"))
            setValue("calcPoint0.loadCurrent",+load_current)
            setValue("calcPoint0.loadCapacity",BeamsNumber(load_capacity,7))
            setValue("loadCurrent",getValues("calcPoint0.loadCurrent"))
            setValue("loadCapacity",getValues("calcPoint0.loadCapacity"))
        }
        doSetValueVoltDrop()
    }
//回路の力率 の概算値　バーター
const handleDeleteValueButton1 =() =>{
    const reactor = getValues()
    setValue("inputPowerFactor1",false)
    if(getValues("downTransKind") == MS_TRANS1){
        clearErrors("powerFactor")
        setValue("calcPoint1.powerFactor",dispInputDialog.power_factor_1)
        setValue("calcPoint1.isLead",dispInputDialog.is_lead_0)
        setValue("leadOrLag",dispInputDialog.is_lead_1=== true?1:0)
        let power_factor = doGetTextDropVoltPF(reactor,false)
        setValue("calcPoint1.powerFactor",+power_factor)
        setValue("powerFactor",+power_factor)
    }
    else{
        clearErrors("powerFactor")
        setValue("calcPoint0.powerFactor",dispInputDialog.power_factor_0)
        setValue("calcPoint0.isLead",dispInputDialog.is_lead_0)
        setValue("leadOrLag",dispInputDialog.is_lead_0=== true?1:0)
        let power_factor = doGetTextDropVoltPF(reactor,false)
        setValue("calcPoint0.powerFactor",+power_factor)
        setValue("powerFactor",+power_factor)
    }
    doSetValueVoltDrop()
}
//回路の力率  power factor of the circuit
const handleRadioPower =(e:any) =>{
     setValue(e.target.name, e.target.value);
    let voltageDrop = getValues("voltageDrop")
    let voltageDrop1 = getValues("voltageDrop1")
    if(getValues("downTransKind")=== MS_TRANS1){
        if(+getValues("leadOrLag") === 1){
            setValue("inputPowerFactor1",true)
            setValue("calcPoint1.isLead",true)
        }
        else if(+getValues("leadOrLag") === 0){
            setValue("inputPowerFactor1",true)
            setValue("calcPoint1.isLead",false)
        }
    }
    else{
        if(+getValues("leadOrLag") === 1){
            setValue("inputPowerFactor1",true)
            setValue("calcPoint0.isLead",true)
        }
        else if(+getValues("leadOrLag") === 0){
            setValue("inputPowerFactor1",true)
            setValue("calcPoint0.isLead",false)
        }
    }
     if(+getValues("leadOrLag") === 1 && voltageDrop !==0 && voltageDrop1!==0){
            setValue("voltageDrop",-1 * voltageDrop)        
            setValue("voltageDrop1",-1 * voltageDrop1)    
    }
    else{
        setValue("voltageDrop",Math.abs(voltageDrop))      
        setValue("voltageDrop1",Math.abs(voltageDrop1))       
    }
}
// ModePM
const handleChangeMakeDataInput = (e: any) => {
    setValue(e.target.name, e.target.checked);
    setMakeDataInput(e.target.checked);
}

    const setVoltageCalcPoint = (e:any) =>{
        setValue(e.target.name, e.target.value)
        setValue("calcPoint0.voltage",getValues("voltage"))
        setValue("calcPoint1.voltage",getValues("voltage"))

    }
    const onBnClickedTopLeft = (e:any) =>{
        setValue(e.target.name, +e.target.value)
    }
    return (
        <>
            
               
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={selectTab} onChange={handleChange}
                            variant="scrollable" 
                            scrollButtons 
                            allowScrollButtonsMobile 
                            sx={{
                                '& .MuiButtonBase-root.MuiTabScrollButton-root.Mui-disabled': {
                                    display: "none"
                                }
                            }}
                        >
                            <Tab label="基本" {...a11yProps(0)} />
                            <Tab label="電圧降下" {...a11yProps(1)} />
                        </Tabs>
                    </Box>
                    <form onSubmit={handleSubmit(handleEditSubmit)}>
                        <div style={{ height: '40vh', width: '100%', margin: 'auto', overflow: "auto" }}>

                            {/* tab0 */}
                            <TabPanel value={selectTab} index={0}>
                                <Stack spacing={0.3} direction='column'>
                                    <Grid container>
                                        <Grid item xs={7}>
                                                <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"5px"}}>
                                                        <Typography variant="body2" width="100%">デバイス名:</Typography>
                                                    <Controller
                                                        name="refNo"
                                                        control={control}
                                                        rules={{
                                                            required: '必須項目です。入力してください。',
                                                        }}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                fullWidth
                                                                type="text"
                                                                size="small"
                                                                // label="デバイス名"
                                                                error={!!errors?.refNo}
                                                                helperText={errors?.refNo?.message}
                                                                onBlur={handleChangeFormat}
                                                                onFocus={e => e.target.select()}
                                                                InputLabelProps={{
                                                                    style: { color: 'black' },
                                                                }}
                                                                InputProps={{sx:{height:32,backgroundColor:m_bModePM || modeViewOnly?'floralwhite':'white', color:modeViewOnly?"grey":"black"}}}
                                                                disabled={m_bModePM}
                                                                inputProps={{readOnly:modeViewOnly}}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                                <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                        <Typography variant="body2" width="100%">事故点名称:</Typography>
                                                    <Controller
                                                        name="pointText"
                                                        control={control}
                                                        rules={{
                                                        }}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                fullWidth
                                                                type="text"
                                                                size="small"
                                                                // label="事故点名称"
                                                                error={!!errors?.pointText}
                                                                helperText={errors?.pointText?.message}
                                                                onBlur={handleChangeFormat}
                                                                onFocus={e => e.target.select()}
                                                                InputLabelProps={{
                                                                    style: { color: 'black' },
                                                                }}
                                                                InputProps={{sx:{height:32,backgroundColor:m_bModePM || modeViewOnly?'floralwhite':'white', color:modeViewOnly?"grey":"black"}}}
                                                                disabled={m_bModePM}
                                                                inputProps={{readOnly:modeViewOnly}}

                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                                <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                    <Typography variant="body2" width="100%">回路電圧(V):</Typography>
                                                        <FormControl fullWidth size="small">
                                                            <Controller
                                                                name="voltage"
                                                                control={control}
                                                                rules={{
                                                                    required: '必須項目です。入力してください。' ,
                                                                    pattern:{
                                                                    value: /^[0-9\b]+$/,
                                                                    message: '数字を入力してください。'
                                                                    },
                                                                    validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                                //     maxLength: {
                                                                //     value: 6,
                                                                //     message: "最大長さ <= 6 を入力してください。"
                                                                // }
                                                                    }}
                                                                render={({ field }) => (
                                                                    <TextFieldOptions
                                                                            {...field}
                                                                            options={initialValue.voltageList}
                                                                            // label="回路電圧(V)"
                                                                            // onChange= {handleInputVoltage}
                                                                            size="small"
                                                                            setValue={(name: any, value: any) => {
                                                                                handleChangeFormat({target: {name: name, value: value}});
                                                                                calcRatedCurrentField({target: {name: name, value: value}});
                                                                                setVoltageCalcPoint({target: {name: name, value: value}})
                                                                            }}
                                                                            height={32}
                                                                            disabled={getValues("isSourceVoltage")||m_bModePM || modeViewOnly}
                                                                            error={!!errors?.voltage}
                                                                            helperText={errors?.voltage?.message}
                                                                            maxLength={6}
                                                                            
                                                                        />
                                                            
                                                                )}
                                                            />
                                                        </FormControl>
                                                    </Stack>
                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                        <Typography variant="body2" width="100%">容量(kVA):</Typography>
                                                            <Controller
                                                                name="capacity"
                                                                control={control}
                                                                rules={{
                                                                    pattern:{
                                                                    value: /^[0-9]*[.]?[0-9]+$/,
                                                                    message: '数字を入力してください。'
                                                                    },
                                                                    validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                                //     maxLength: {
                                                                //     value: 5,
                                                                //     message: "最大長さ <= 5 を入力してください。"
                                                                // }
                                                                    }}
                                                                render={({ field }) => (
                                                                    <TextField
                                                                        {...field}
                                                                        fullWidth
                                                                        type="text"
                                                                        size="small"
                                                                        // label="容量(kVA)"
                                                                                                    
                                                                        onChange={(e) => {handleChangeFormat(e);calcRatedCurrentField(e)}}
                                                                        InputLabelProps={{
                                                                            style: { color: 'black' },
                                                                        }}
                                                                        InputProps={{sx:{height:32 ,backgroundColor:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white', color:modeViewOnly?"grey":"black"}}}
                                                                        inputProps={{maxLength:5, readOnly:modeViewOnly}}
                                                                        error={!!errors?.capacity}
                                                                        helperText={errors?.capacity?.message}     
                                                                        disabled={m_bModePM|| processMode > MS_MODE_CHECKING}
                                                                    />
                                                                )}
                                                            />
                                                </Stack>
                                                <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                    <Typography variant="body2" width="100%">定格電流(A):</Typography>
                                                        <Controller
                                                            name="ratedCurrent"
                                                            control={control}
                                                            rules={{
                                                                required: '必須項目です。入力してください。',
                                                            }}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    type="text"
                                                                    size="small"
                                                                    // label="定格電流(A)"
                                                                    onFocus={e => e.target.select()}
                                                                    InputLabelProps={{
                                                                        style: { color: 'black' },
                                                                        
                                                                    }}
                                                                    InputProps={{
                                                                        readOnly: true,
                                                                        sx:{height:32,color:'grey'}
                                                                    }}
                                                                    style={{
                                                                        backgroundColor: 'floralwhite',
                                                                    }}
                                                                    // onChange={(e) => calcRatedCurrentField(e)}
                                                                    // value={(e:any) => calcRatedCurrentField(e)}
                                                                
                                                                />
                                                            )}
                                                        />
                                                </Stack>
                                                <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                    <Typography variant="body2" width="100%">リアクタンス(%):</Typography>
                                                        <Controller
                                                            name="percentX"
                                                            control={control}
                                                            rules={{
                                                                pattern:{
                                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                                message: '数字を入力してください。'
                                                                },
                                                                validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                            //     maxLength: {
                                                            //     value: 5,
                                                            //     message: "最大長さ <= 5 を入力してください。"
                                                            // }
                                                                }}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    // type="text"
                                                                    size="small"
                                                                    // label="リアクタンス(%)"
                                                                    
                                                                    onBlur={handleChangeFormat}
                                                                    onFocus={e => e.target.select()}
                                                                    InputLabelProps={{
                                                                        style: { color: 'black' },
                                                                    }}
                                                                    onChange={e=>{
                                                                        field.onChange(e);
                                                                        doSetValueVoltDrop(e);
                                                                        handleInput(e);
                                                                        
                                                                    }}
                                                                    InputProps={{sx:{height:32 ,backgroundColor:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white', color:modeViewOnly?"grey":"black"}}}
                                                                    inputProps={{maxLength:5, readOnly:modeViewOnly}}
                                                                    error={!!errors?.percentX}
                                                                    helperText={errors?.percentX?.message}
                                                                    disabled={m_bModePM|| processMode > MS_MODE_CHECKING}

                                                                />
                                                            )}
                                                        />
                                                </Stack>                        
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Stack pl={1} >
                                                    <Grid>
                                                        <Controller
                                                            name="fault"
                                                            control={control}
                                                            render={({ field }: any) => (
                                                                <FormControlLabel {...field} control={<Checkbox style={checkboxStyles} />} checked={field.value} label={<Typography variant="body2" color={m_bModePM?'grey':'black'} >短絡電流を計算</Typography>} name='fault' onClick={handleChangeFormat}
                                                                disabled={m_bModePM || modeViewOnly} />
                                                            )}
                                                        />
                                                    </Grid>
                                                    {/* Mode SP */}
                                                    {(userRole && !(userRole == Constant.ROLE_LP)) &&
                                                    <Grid>
                                                        <Controller
                                                            name="viewFlowResult"
                                                            control={control}
                                                            render={({ field }: any) => (
                                                                <FormControlLabel {...field} control={<Checkbox style={checkboxStyles}/>} checked={field.value} label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>潮流解析結果を表示</Typography>} name='viewFlowResult' onClick={handleChangeFormat}                              
                                                                     
                                                                disabled={m_bModePM || modeViewOnly} />

                                                            )}
                                                        />
                                                    </Grid>
                                                    }

                                                    <Grid>
                                                        <Controller
                                                            name="viewResultText"
                                                            control={control}
                                                            render={({ field }: any) => (
                                                                <FormControlLabel {...field} control={<Checkbox style={checkboxStyles}/>} checked={field.value} label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>注釈を表示</Typography>} name='viewResultText' onClick={handleChangeFormat} 
                                                                disabled={m_bModePM || modeViewOnly} />
                                                            )}
                                                        />
                                                    </Grid>
                                                </Stack>
                                            </Grid>

                                            {/* ModePM */}
                                            {[Constant.ROLE_PM, Constant.ROLE_ADMIN].includes(userRole) && 
                                            <>
                                                <Stack>
                                                    <FormControlLabel
                                                        control={
                                                            <Controller
                                                                name='makeDataInput'
                                                                control={control}
                                                                render={({ field }) => (
                                                                    <Checkbox
                                                                        size='small'
                                                                        {...field}
                                                                        checked={field.value}
                                                                        onChange={handleChangeMakeDataInput}
                                                                        style={{padding:'15px 4px 4px 9px'}}
                                                                        disabled={m_bModePM || modeViewOnly}
                                                                    />
                                                                )}
                                                            />
                                                        }
                                                        label={<Typography variant="body2" style={{marginTop:'10px'}} color={m_bModePM?'grey':'black'}>銘板入力</Typography>}
                                                    />
                                                </Stack>
                                                <Stack>
                                                    <fieldset
                                                        style={{ 
                                                            width: '70%',
                                                            color: !makeDataInput ? 'grey' : 'black', 
                                                            border:'1px solid #AAAA'
                                                            
                                                        }}
                                                    >
                                                            <legend><Typography variant="body2"  >銘板</Typography></legend>
                                                        <Stack spacing={0.3}>
                                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                                <Typography variant="body2" width="35%"  >製造者:</Typography>
                                                                <FormControl fullWidth size='small'>
                                                                    <Controller
                                                                        name='makerName'
                                                                        control={control}
                                                                        render={({ field }: any) => (
                                                                            <TextFieldOptions
                                                                                {...field}
                                                                                options={initialValue.makerNameList}
                                                                                type='string'
                                                                                size='small'
                                                                                setValue={(name: any, value: any) => {
                                                                                    handleChangeFormat({target: {name: name, value: value}})
                                                                                }}
                                                                                height= {32}
                                                                                disabled={!makeDataInput||m_bModePM || modeViewOnly}

                                                                            />
                                                                        )}
                                                                    />
                                                                </FormControl>
                                                            </Stack>
                                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                                <Typography variant="body2" width="35%"  >形式:</Typography>
                                                                <Controller
                                                                    name='type'
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <TextField
                                                                            {...field}
                                                                            fullWidth
                                                                            type='text'
                                                                            size='small'
                                                                            onChange={handleChangeFormat}
                                                                            InputLabelProps={{
                                                                                style: { color: makeDataInput ? 'black' : 'grey' },
                                                                            }}
                                                                            InputProps={{
                                                                                readOnly: !makeDataInput || modeViewOnly,
                                                                                style:{color:!makeDataInput ?'grey' : 'black'},
                                                                                sx: { height: 32 ,backgroundColor: m_bModePM || modeViewOnly?'floralwhite': makeDataInput ? 'white' : 'floralwhite', color:modeViewOnly?"grey":makeDataInput ? 'black' : 'grey'}
                                                                            }}
                                                                            style={{
                                                                                color:"black"
                                                                            }}
                                                                            disabled={m_bModePM}
                                                                        />
                                                                    )}
                                                                />
                                                            </Stack>
                                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                                <Typography variant="body2" width="35%" >製造年月:</Typography>
                                                                <Grid container>
                                                                    <Grid item xs={7}>
                                                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                                            <FormControl fullWidth size='small'>
                                                                                <Controller
                                                                                    name='makeYear'
                                                                                    control={control}
                                                                                    rules={{
                                                                                        // maxLength: {
                                                                                        //     value: 8,
                                                                                        //     message: "最大長さ <= 8 を入力してください。"
                                                                                        // }
                                                                                            }}
                                    
                                                                                    render={({ field }: any) => (
                                                                                        <TextFieldOptions
                                                                                            {...field}
                                                                                            options={initialValue.makeYearList}
                                    
                                                                                            type='string'
                                                                                            size='small'
                                                                                            setValue={(name: any, value: any) => {
                                                                                                handleChangeFormat({target: {name: name, value: value}})
                                                                                            }}
                                                                                            error={!!errors?.makeYear}
                                                                                            helperText={errors?.makeYear?.message}
                                                                                            disabled={!makeDataInput||m_bModePM || modeViewOnly}
                                                                                            height={32}
                                                                                            maxLength={8}

                                                                                        />
                                                                                    )}
                                                                                />
                                                                            </FormControl>
                                                                            <Typography variant="body2" pr={0.5} pl={0.5}>年</Typography>
                                                                        </Stack>
                                                        
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                            <FormControl fullWidth size='small'>
                                                                <Controller
                                                                name='makeMonth'
                                                                control={control}
                                                                rules={{
                                                                    // maxLength:{
                                                                    //     value:2,
                                                                    //     message:'最大長さ <= 2 を入力してください'
                                                                    // }
                                                                }}
                                                                render={({ field }: any) => (
                                                                    <TextFieldOptions
                                                                        {...field}
                                                                        options={initialValue.makeMonthList}
                                                                        // label='製造者'
                                                                        type='string'
                                                                        size='small'
                                                                        // onChange={handleChangeFormat}
                                                                        setValue={(name: any, value: any) => {
                                                                            handleChangeFormat({target: {name: name, value: value}})
                
                                                                        }} 
                                                                        error={!!errors?.makeMonth}
                                                                        helperText={errors?.makeMonth?.message}
                                                                        disabled={!makeDataInput||m_bModePM || modeViewOnly}
                                                                        height={32}
                                                                        maxLength={2}
                
                                                                    />
                                                                )}
                                                                    
                                                                />
                                                            </FormControl>
                                                            <Typography variant="body2" pl={0.5}>月</Typography>
                                                        </Stack>
                                                    </Grid>
                                                </Grid>
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="35%"  >製造番号:</Typography>
                                                <Controller
                                                    name='makeNumber'
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type='text'
                                                            size='small'
                                                            onChange={handleChangeFormat}
                                                            InputLabelProps={{
                                                                style: { color: makeDataInput ? 'black' : 'grey' },
                                                            }}
                                                            InputProps={{
                                                                readOnly: !makeDataInput || modeViewOnly,
                                                                style:{color:!makeDataInput ?'grey' : 'black'},
                                                                sx: { height: 32 ,backgroundColor:m_bModePM || modeViewOnly?'floralwhite': makeDataInput ? 'white' : 'floralwhite', color:modeViewOnly?"grey":makeDataInput ? 'black' : 'grey'}
                                                            }}
                                                            style={{
                                                                color:"black",
                                                            }}
                                                            disabled={m_bModePM}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                                        </Stack>
                                                    </fieldset>
                                                </Stack>
                                            </>
                                            }
                            </Grid>
                        </Stack>
                    </TabPanel>

                    {/* tab2 */}
                    <TabPanel value={selectTab} index={1}>
                        <Grid container>
                            <Grid item xs={10}>
                                <Controller
                                        name="dropMotor"
                                        control={control}
                                        render={({ field }: any) => (
                                            <FormControlLabel
                                                {...field}
                                                checked={field.value}
                                                label={<Typography variant="body2" color={m_bModePM|| processMode > MS_MODE_CHECKING?'grey':'black'}>電動機始動時の電圧降下を計算</Typography> }
                                                name='dropMotor'
                                                control={<Checkbox style={checkboxStyles} />}
                                                disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}
                                                // disabled={currentTabId === true? false:(setIsDisplayTabConnectTran1 || setIsDisplayTabConnectTranscott)}
                                            
                                            />
                                        )}
                                    />
                            </Grid>
                            <Grid item xs={10}>
                                <Controller
                                        name="dropRegular"
                                        control={control}
                                        render={({ field }: any) => (
                                            <FormControlLabel
                                                {...field}
                                                checked={field.value}
                                                label={<Typography variant="body2" color={m_bModePM|| processMode > MS_MODE_CHECKING?'grey':'black'}>定常時の電圧降下を計算</Typography> }
                                                name='dropRegular'
                                                onChange={(e) =>DisableFieldTap2(e)}
                                                control={<Checkbox style={checkboxStyles} />}
                                                disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}
                                            />
                                        )}
                                    />
                            </Grid>
                            <Grid container>
                                <Grid item xs={7.5}>
                                    <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                        <Typography variant="body2" width="90%" ml={5}>負荷容量 (VA):</Typography>  
                                            <Controller
                                                name="loadCapacity"
                                                control={control}
                                                rules={{
                                                    pattern:{
                                                    value: /^[0-9]*[.]?[0-9]+$/,
                                                    message: '数字を入力してください。'
                                                    },
                                                    validate: value => value >= 0 || '数字 >= 0 を入力してください。' ,
                                                
                                                    }}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        disabled={!isDisableFieldTap2||m_bModePM|| processMode > MS_MODE_CHECKING}
                                                        // label='負荷容量(VA)'
                                                        onFocus={e => e.target.select()}
                                                        InputLabelProps={{
                                                            style: { color: 'black' },
                                                        }}
                                                        onChange={(e) =>{handleChangeFormat(e); onEnChangeLoadCapacity(e)}}
                                                        InputProps={{sx:{height:32,backgroundColor:m_bModePM || modeViewOnly ||!isDisableFieldTap2|| processMode > MS_MODE_CHECKING?'floralwhite':'white', color:modeViewOnly?"grey":"black"}}}
                                                        inputProps={{readOnly:modeViewOnly}}
                                                        error={!!errors?.loadCapacity}
                                                        helperText={errors?.loadCapacity?.message}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                </Grid>
                                <Grid item xs ={7.5}>
                                    <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                        <Typography variant="body2" width="90%" ml={5}>負荷電流 IL(A):</Typography>  
                                        <Controller
                                            name="loadCurrent"
                                            control={control}
                                            rules={{
                                                pattern:{
                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                message: '数字を入力してください。'
                                                },
                                                validate: value => value >= 0 || '数字 >= 0 を入力してください。' ,
                                            //     maxLength: {
                                            //     value: 7,
                                            //     message: "最大長さ <= 7 を入力してください。"
                                            // }
                                                }}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    disabled={!isDisableFieldTap2||m_bModePM|| processMode > MS_MODE_CHECKING}
                                                    type="text"
                                                    size="small"
                                                    // label='負荷電流 IL(A)'
                                                    onFocus={e => e.target.select()}
                                                    InputLabelProps={{
                                                        style: { color: 'black' },
                                                    }}
                                                    onChange={(e) =>{handleChangeFormat(e); onEnChangeLoadCurrent(e)}}
                                                    InputProps={{sx:{height:32 ,backgroundColor:m_bModePM || modeViewOnly ||!isDisableFieldTap2|| processMode > MS_MODE_CHECKING?'floralwhite':'white', color:modeViewOnly?"grey":"black"}}}
                                                    error={!!errors?.loadCurrent}
                                                    helperText={errors?.loadCurrent?.message}
                                                    inputProps={{maxLength:7, readOnly:modeViewOnly}}
                                                />
                                            )}
                                        />
                                    </Stack>
                                </Grid>
                            </Grid>
                            <Grid container ml={6.5}>
                                <fieldset style={{border:"1px solid #AAAA",width:"53%"}}>
                                    <legend>{<Typography variant="body2">負荷電流方向</Typography> }</legend>
                                    <Controller
                                            name="topLeft"
                                            control={control}
                                            render={({ field }) => (
                                                    <RadioGroup {...field} onChange={(e) =>onBnClickedTopLeft(e)}>
                                                        <FormControlLabel
                                                            value="1"
                                                            control={<Radio style={radioStyles} />}
                                                            label={<Typography variant="body2" color={m_bModePM|| processMode > MS_MODE_CHECKING?'grey':'black'}>上→下 or 左→右</Typography> }
                                                            disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}
                                                        />
                                                        <FormControlLabel
                                                            value="0"
                                                            control={<Radio style={radioStyles} />}
                                                            label={<Typography variant="body2" color={m_bModePM|| processMode > MS_MODE_CHECKING?'grey':'black'}>下→上 or 右→左</Typography> }
                                                            disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}

                                                        />
                                                    </RadioGroup>
                                            )}
                                        />
                                    </fieldset>
                                    <Stack ml={1.2}>
                                        <Typography color="common.white">nothing</Typography>
                                        <Typography color="common.white">nothing</Typography>
                                        <Button variant="outlined"  onClick={handleDeleteValueButton}  disabled={!isDisableFieldTap2||m_bModePM || modeViewOnly  || processMode > MS_MODE_CHECKING}
                                                        >概算値</Button>
                                    </Stack>
                            </Grid>
                            <Grid container ml={3}>
                                <fieldset style={{width:"76%",border:'1px solid #AAAA'}}>
                                    <legend>{<Typography variant="body2">回路の力率</Typography> }</legend>
                                    <Grid container>
                                        <Grid item xs={5}>
                                            <Controller
                                                name="leadOrLag"
                                                control={control}
                                                render={({ field }) => (
                                                    <RadioGroup {...field} row onChange ={(e) =>handleRadioPower(e) }>
                                                        <FormControlLabel
                                                            value="1"
                                                            control={<Radio style={radioStyles} />}
                                                            label={<Typography style={{color:!isDisableFieldTap2||m_bModePM|| processMode > MS_MODE_CHECKING?"grey":"black"}} variant="body2">進み</Typography> }
                                                        disabled={!isDisableFieldTap2 || modeViewOnly  ||m_bModePM|| processMode > MS_MODE_CHECKING}

                                                        />
                                                        <FormControlLabel
                                                            value="0"
                                                            control={<Radio style={radioStyles} />}
                                                            label={<Typography style={{color:!isDisableFieldTap2||m_bModePM|| processMode > MS_MODE_CHECKING?"grey":"black"}} variant="body2">遅れ</Typography> }
                                                        disabled={!isDisableFieldTap2 || modeViewOnly  ||m_bModePM|| processMode > MS_MODE_CHECKING}

                                                        />
                                                    </RadioGroup>
                                                )}
                                            />
                                        </Grid>
                                        <Grid xs={1}/>
                                        <Grid xs={2.75} ml={1.4}>
                                            <Controller
                                                name="powerFactor"
                                                control={control}
                                                rules={{
                                                    pattern:{
                                                        value: /^[0-9]*[.]?[0-9]+$/,
                                                        message: '数字を入力してください。'
                                                    },
                                                    validate: value => value >= 0 && value <= 1 || '数字 0 ~ 1 を入力してください。' ,
    
                                                    // maxLength: {
                                                    //     value: 7,
                                                    //     message: "最大長さ <= 7 を入力してください。"
                                                    // }
                                                }}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        disabled={!isDisableFieldTap2||m_bModePM|| processMode > MS_MODE_CHECKING}
                                                        onFocus={e => e.target.select()}
                                                        InputLabelProps={{
                                                            style: { color: 'black' },
                                                        }}
                                                        onChange={e =>{
                                                            handleChangeFormat(e);
                                                            doSetValueVoltDrop(e);
                                                            handleInputPowerFactor(e)
                                                        }}
                                                        InputProps={{sx:{height:32,backgroundColor:m_bModePM || modeViewOnly  ||!isDisableFieldTap2|| processMode > MS_MODE_CHECKING?'floralwhite':'white', color:modeViewOnly?"grey":"black"}}}
                                                        error={!!errors?.powerFactor}
                                                        helperText={errors?.powerFactor?.message}
                                                        inputProps={{maxLength:7, readOnly:modeViewOnly}}

                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={2.5} ml={1.64}>
                                            <Button style={{margin:"0"}} variant="outlined" onClick={handleDeleteValueButton1}  disabled={!isDisableFieldTap2||m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}
                                                >概算値</Button>
                                        </Grid>
                                    </Grid>
                                </fieldset>
                            </Grid>
                            <Grid container  mt={0.25}>
                                <Grid container flexDirection={"row"} ml={6} alignItems={"center"}>
                                    <Grid item xs={4} >
                                        <Typography variant="body2" width="100%" >単体での電圧降下(V):</Typography>  
                                    </Grid>
                                    <Grid item xs={4} ml={-2.6}>
                                        <Controller
                                            name="voltageDrop1"
                                            control={control}
                                            
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    size="small"
                                                    onBlur={handleChangeFormat}
                                                    onFocus={e => e.target.select()}
                                                    InputLabelProps={{ style: { color: 'black'}}}
                                                    InputProps={{sx:{height:32}}}
                                                    style={{backgroundColor: 'floralwhite',width:"70%",marginLeft:'20%'}}
                                                    disabled={true}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container flexDirection={"row"} ml={6} mt={0.25} alignItems={"center"}>
                                    <Grid item xs={4} >
                                        <Typography variant="body2" width="100%" >単体での電圧降下率(%):</Typography>  
                                    </Grid>
                                    <Grid item xs={4} ml={-2.6}>
                                        <Controller
                                            name="voltageDrop"
                                            control={control}
                                            
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    size="small"
                                                    onBlur={handleChangeFormat}
                                                    onFocus={e => e.target.select()}
                                                    InputLabelProps={{ style: { color: 'black'}}}
                                                    InputProps={{sx:{height:32}}}
                                                    style={{backgroundColor: 'floralwhite',width:"70%",marginLeft:'20%'}}
                                                    disabled={true}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </TabPanel>
                    </div >

                    <Stack direction='row' justifyContent="flex-end" spacing={2} mt={2}>
                        <Button variant="contained" type="submit" disabled ={isDisableOKBtn || modeViewOnly}>OK</Button>
                        <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                    </Stack>
                </form >
        </>
    );
};
export default MsReactorEditDialog;


