import { Button, Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid } from "@mui/material";
import { Control, Controller, useForm } from "react-hook-form";
import theme from "../../themes/globalStyles";
import DraggablePaper from "../common/DraggablePaper";
import { MS_3PHASES, MS_OUTSIDE_NEUTRAL, MS_OUTSIDE_OUTSIDE, MS_SEAT_MAIN, MS_SEAT_T, RS_3PHASES, VOLT_SIDE_PRIMARY, VOLT_SIDE_SECONDARY, VOLT_SIDE_THIRD } from "../../statics";
import { store } from "../..";
import { useMemo, useRef, useState } from "react";
import { ControlModel } from "../../models/Index";
import { 
    MS_MOTOR_GROUP, 
    MS_TRANS1, 
    MS_TRANSCENTER, 
    MS_TRANSSCOTT, 
    MS_ZEROCOND 
} from "../../models/ElementKind";
import { doGetTextTotalAsymCoefficient as doGetTextTotalAsymCoefficientEle, doGetTextTotalPeakCoefficient as doGetTextTotalPeakCoefficientEle, doGetRefNoSkeleton, doGetTextDirectPeakCoefficient as doGetTextDirectPeakCoefficientEle, doGetTextDirectAsymCoefficient as doGetTextDirectAsymCoefficientEle, doGetTextSidePointTextAndState, isPointFaultAND, isSPhaseCircuitEle, doGetTextDirectIksymResult, doGetTextDirectIkpeakResult, doGetTextDirectIkasymResult, doGetTextDirectIksymEquation, doGetTextTotalIksymResult, doGetTextTotalIkpeakResult, doGetTextTotalIkasymResult, doGetTextTotalIksymEquation, doGetReportFaultDetailComment } from "../../utils/element";
import { useDispatch, useSelector } from "react-redux";

export type ShortCircuitCurrentReviewDialogProps = {
    title?: string;
    element:any;
    onOK: (data: any) => void;
    onCancel: () => void;
    setLoadingFlag: (flag: boolean) => void;
};

type TextEditorForm = {
    pointText: string,
    examineText: string,
    pointSide: string,
    shortCircuitCurrentText: string
}

export const ShortCircuitCurrentReviewDialog = (props: ShortCircuitCurrentReviewDialogProps) => {
    const dispatch = useDispatch();
    const diagramData = useSelector((state:any) => state.app.diagram.diagramData);
    const processMode = useSelector((state:any) => state.app.diagram.processMode);
    const infoCircuit = useSelector((state:any) => state.app.diagram.infoCircuit)
    const { title, element, onCancel, onOK, setLoadingFlag} = props;

    const handleDoGetShortCircuitCurrentText = (id:number) =>{
        const props: ControlModel = diagramData.find((x:any)=>x.shape.find((shapes:ControlModel)=>Number(shapes.id) === Number(element.id)))?.shape.find((shapes:ControlModel)=>Number(shapes.id) === Number(element.id))
        if(props)
        {
            let m_cPointSide = element.pointSide??""
            let sParkKind = "一次側";
            let m_nPartKind
            if (m_cPointSide === sParkKind) {
                m_nPartKind = VOLT_SIDE_PRIMARY;
            }
            else {
                sParkKind = '二次側';
                if (m_cPointSide == sParkKind) {
                    m_nPartKind = VOLT_SIDE_SECONDARY;
                }
                else {
                    sParkKind = '三次側';
                    if (m_cPointSide == sParkKind) {
                        m_nPartKind = VOLT_SIDE_THIRD;
                    }
                    else {
                        sParkKind = '外-外';
                        if (m_cPointSide == sParkKind) {
                            m_nPartKind = MS_OUTSIDE_OUTSIDE;
                        }
                        else {
                            sParkKind = '外-中';
                            if (m_cPointSide == sParkKind) {
                                m_nPartKind = MS_OUTSIDE_NEUTRAL;
                            }
                            else {
                                sParkKind = '主座';
                                if (m_cPointSide == sParkKind) {
                                   m_nPartKind = MS_SEAT_MAIN;
                                }
                                else {
                                    sParkKind = 'T座';
                                    if (m_cPointSide == sParkKind) {
                                        m_nPartKind = MS_SEAT_T;
                                    }
                                    else {
                                        m_nPartKind = MS_3PHASES;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            let sExamineComment = ""
            let m_cExamineResult = ""

            if(id === 0) {
                let sDirectText = '通過';
                let sTotalText = '合計';
                let sSignMult = '×';

                let sResultText: any[] = [];
                let sText = "";
                let sSubResult: string[] = ["","",""];
                
                if (isSPhaseCircuitEle(props, VOLT_SIDE_SECONDARY, undefined).sPhase != true) {
                    if (props.type != MS_ZEROCOND) {	// 通過分
                                                    // ピーク係数
                        sSubResult[0] = "Kp";
                        sText = 'I"k3';
                        sSubResult[0] += "(" + sDirectText + sText + ")";
                        sSubResult[1] = " = ";
                        sSubResult[2] = doGetTextDirectPeakCoefficientEle(props, m_nPartKind);
                        sResultText.push(sSubResult[0] + sSubResult[1] + sSubResult[2]);

                                                    // 非対称係数
                        sSubResult[0] = "Ka";
                        sText = 'I"k3';
                        sSubResult[0] += "(" + sDirectText + sText + ")";
                        sSubResult[1] = " = ";
                        sSubResult[2] = doGetTextDirectAsymCoefficientEle(props, m_nPartKind);
                        sResultText.push(sSubResult[0] + sSubResult[1] + sSubResult[2]);

                                                    // 短絡電流ピーク値
                        sSubResult[0] = 'I"k3';
                        sText = 'peak';
                        sSubResult[0] += "(" + sDirectText + sText + ")";
                        sSubResult[1] = " = ";
                        sSubResult[2] = doGetTextDirectPeakCoefficientEle(props, m_nPartKind) + sSignMult +
                            doGetTextDirectIksymResult(props, processMode, m_nPartKind) +
                            " = " + doGetTextDirectIkpeakResult(props, processMode, m_nPartKind);
                        sResultText.push(sSubResult[0] + sSubResult[1] + sSubResult[2]);

                                                    // 短絡電流非対称値
                        sSubResult[0] = 'I"k3';
                        sText = 'asym';
                        sSubResult[0] += "(" + sDirectText + sText + ")";
                        sSubResult[1] = " = ";
                        sSubResult[2] = doGetTextDirectAsymCoefficientEle(props, m_nPartKind) + sSignMult +
                            doGetTextDirectIksymResult(props, processMode, m_nPartKind) +
                            " = " + doGetTextDirectIkasymResult(props, processMode, m_nPartKind);
                        sResultText.push(sSubResult[0] + sSubResult[1] + sSubResult[2]);

                                                    // 短絡電流対称値
                        sSubResult[0] = 'I"k3';
                        sText = 'sym';
                        sSubResult[0] += "(" + sDirectText + sText + ")";
                        sSubResult[1] = " = ";
                        sSubResult[2] = doGetTextDirectIksymEquation(props, processMode, infoCircuit, m_nPartKind) + " = " +
                            doGetTextDirectIksymResult(props, processMode, m_nPartKind);
                        sResultText.push(sSubResult[0] + sSubResult[1] + sSubResult[2]);
                        sResultText.push("");
                    }
                    if (props.type != MS_TRANS1 &&
                        props.type != MS_TRANSSCOTT &&
                        props.type != MS_TRANSCENTER) {	// 合計分
                                                    // ピーク係数
                        sSubResult[0] = "Kp";
                        sText = 'I"k3';
                        sSubResult[0] += "(" + sTotalText + sText + ")";
                        sSubResult[1] = " = ";
                        sSubResult[2] = doGetTextTotalPeakCoefficientEle(props, m_nPartKind);
                        sResultText.push(sSubResult[0] + sSubResult[1] + sSubResult[2]);

                                                    // 非対称係数
                        sSubResult[0] = "Ka";
                        sText = 'I"k3';
                        sSubResult[0] += "(" + sTotalText + sText + ")";
                        sSubResult[1] = " = ";
                        sSubResult[2] = doGetTextTotalAsymCoefficientEle(props, m_nPartKind);
                        sResultText.push(sSubResult[0] + sSubResult[1] + sSubResult[2]);

                                                    // 短絡電流ピーク値
                        sSubResult[0] = 'I"k3';
                        sText = 'peak';
                        sSubResult[0] += "(" + sTotalText + sText + ")";
                        sSubResult[1] = " = ";
                        sSubResult[2] = doGetTextTotalPeakCoefficientEle(props, m_nPartKind) +
                            sSignMult + doGetTextTotalIksymResult(props, m_nPartKind) +
                            " = " + doGetTextTotalIkpeakResult(props, m_nPartKind);
                        sResultText.push(sSubResult[0] + sSubResult[1] + sSubResult[2]);

                                                    // 短絡電流非対称値
                        sSubResult[0] = 'I"k3';
                        sText = 'asym';
                        sSubResult[0] += "(" + sTotalText + sText + ")";
                        sSubResult[1] = " = ";
                        sSubResult[2] = doGetTextTotalAsymCoefficientEle(props, m_nPartKind) + sSignMult +
                            doGetTextTotalIksymResult(props, m_nPartKind) +
                            " = " + doGetTextTotalIkasymResult(props, m_nPartKind);
                        sResultText.push(sSubResult[0] + sSubResult[1] + sSubResult[2]);

                                                    // 短絡電流対称値
                        sSubResult[0] = 'I"k3';
                        sText = 'sym';
                        sSubResult[0] += "(" +  sTotalText + sText + ")";
                        sSubResult[1] = " = ";
                        sSubResult[2] = doGetTextTotalIksymEquation(props, processMode, infoCircuit, m_nPartKind) + " = " +
                            doGetTextTotalIksymResult(props, m_nPartKind);
                        sResultText.push(sSubResult[0] + sSubResult[1] + sSubResult[2]);
                    }
                }
                else {
                    if (props.type != MS_ZEROCOND) {	// 通過分
                                                    // ピーク係数
                        sSubResult[0] = "Kp";
                        sText = 'I"k2';
                        sSubResult[0] += "(" + sDirectText + sText + ")";
                        sSubResult[1] = " = ";
                        sSubResult[2] = doGetTextDirectPeakCoefficientEle(props, m_nPartKind);
                        sResultText.push(sSubResult[0] + sSubResult[1] + sSubResult[2]);

                                                    // 非対称係数
                        sSubResult[0] = "Ka";
                        sText = 'I"k2';
                        sSubResult[0] += "(" + sDirectText + sText + ")";
                        sSubResult[1] = " = ";
                        sSubResult[2] = doGetTextDirectAsymCoefficientEle(props, m_nPartKind);
                        sResultText.push(sSubResult[0] + sSubResult[1] + sSubResult[2]);

                                                    // 短絡電流ピーク値
                        sSubResult[0] = 'I"k2';
                        sText = 'peak';
                        sSubResult[0] += "(" + sDirectText + sText + ")";
                        sSubResult[1] = " = ";
                        sSubResult[2] = doGetTextDirectPeakCoefficientEle(props, m_nPartKind) + sSignMult +
                            doGetTextDirectIksymResult(props, processMode, m_nPartKind) +
                            " = " + doGetTextDirectIkpeakResult(props, processMode, m_nPartKind);
                        sResultText.push(sSubResult[0] + sSubResult[1] + sSubResult[2]);

                                                    // 短絡電流非対称値
                        sSubResult[0] = 'I"k2';
                        sText = 'asym';
                        sSubResult[0] += "(" + sDirectText + sText + ")";
                        sSubResult[1] = " = ";
                        sSubResult[2] = doGetTextDirectAsymCoefficientEle(props, m_nPartKind) + sSignMult +
                            doGetTextDirectIksymResult(props, processMode, m_nPartKind) +
                            " = " + doGetTextDirectIkasymResult(props, processMode, m_nPartKind);
                        sResultText.push(sSubResult[0] + sSubResult[1] + sSubResult[2]);

                                                    // 短絡電流対称値
                        sSubResult[0] = 'I"k2';
                        sText = 'sym';
                        sSubResult[0] += "(" + sDirectText + sText + ")";
                        sSubResult[1] = " = ";
                        sSubResult[2] = doGetTextDirectIksymEquation(props, processMode, infoCircuit, m_nPartKind) + " = " +
                            doGetTextDirectIksymResult(props, processMode, m_nPartKind);
                        sResultText.push(sSubResult[0] + sSubResult[1] + sSubResult[2]);
                    }
                    else {	// 合計分
                                                    // ピーク係数
                        sSubResult[0] = "Kp";
                        sText = 'I"k2';
                        sSubResult[0] += "(" + sTotalText + sText + ")";
                        sSubResult[1] = " = ";
                        sSubResult[2] = doGetTextTotalPeakCoefficientEle(props, m_nPartKind);
                        sResultText.push(sSubResult[0] + sSubResult[1] + sSubResult[2]);

                                                    // 非対称係数
                        sSubResult[0] = "Ka";
                        sText = 'I"k2';
                        sSubResult[0] += "(" +  sTotalText + sText + ")";
                        sSubResult[1] = " = ";
                        sSubResult[2] = doGetTextTotalAsymCoefficientEle(props, m_nPartKind);
                        sResultText.push(sSubResult[0] + sSubResult[1] + sSubResult[2]);

                                                    // 短絡電流ピーク値
                        sSubResult[0] = 'I"k2';
                        sText = 'peak';
                        sSubResult[0] += "(" +  sTotalText + sText + ")";
                        sSubResult[1] = " = ";
                        sSubResult[2] = doGetTextTotalPeakCoefficientEle(props, m_nPartKind) +
                            sSignMult + doGetTextTotalIksymResult(props, m_nPartKind) +
                            + " = " + doGetTextTotalIkpeakResult(props, m_nPartKind);
                        sResultText.push(sSubResult[0] + sSubResult[1] + sSubResult[2]);

                                                    // 短絡電流非対称値
                        sSubResult[0] = 'I"k2';
                        sText = 'asym';
                        sSubResult[0] += "(" +  sTotalText + sText + ")";
                        sSubResult[1] = " = ";
                        sSubResult[2] = doGetTextTotalAsymCoefficientEle(props, m_nPartKind) +
                            sSignMult  + doGetTextTotalIksymResult(props, m_nPartKind) +
                            " = " + doGetTextTotalIkasymResult(props, m_nPartKind);
                        sResultText.push(sSubResult[0] + sSubResult[1] + sSubResult[2]);

                                                    // 短絡電流対称値
                        sSubResult[0] = 'I"k2';
                        sText = 'sym';
                        sSubResult[0] += "(" +  sTotalText + sText + ")";
                        sSubResult[1] = " = ";
                        if (m_nPartKind == MS_SEAT_MAIN || m_nPartKind == MS_SEAT_T) {
                            sSubResult[2] = doGetTextTotalIksymResult(props, m_nPartKind);
                        }
                        else {
                            sSubResult[2] = doGetTextTotalIksymEquation(props, processMode, infoCircuit, m_nPartKind) + " = " +
                                doGetTextTotalIksymResult(props, m_nPartKind);
                        }
                        sResultText.push(sSubResult[0] + sSubResult[1] + sSubResult[2]);
                    }
                }
                m_cExamineResult = sResultText.join("\n") ;
            }
            else
            {
                let sPartText = "";
                switch (m_nPartKind) {
                case MS_OUTSIDE_OUTSIDE:	// 外線間
                    sPartText = '外-外';
                    break;
                case MS_OUTSIDE_NEUTRAL:	// 外線－中性線間
                    sPartText = '外-中';
                    break;
                case MS_SEAT_MAIN:			// 主座変圧器
                    sPartText = '主座';
                    break;
                case MS_SEAT_T:				// Ｔ座変圧器
                    sPartText = 'T座';
                    break;
                case VOLT_SIDE_PRIMARY:		// 一次側
                    sPartText = '一次側';
                    break;
                case VOLT_SIDE_SECONDARY:	// 二次側
                    sPartText = '二次側';
                    break;
                case VOLT_SIDE_THIRD:		// 三次側
                    sPartText = '三次側';
                    break;
                default:
                    if (props.type == MS_MOTOR_GROUP ||
                        props.type == MS_TRANSCENTER) {	// 一次側
                        sPartText = '一次側';
                    }
                    else {													// 二次側
                        sPartText = '二次側';
                    }
                    break;
                }
                sExamineComment = doGetReportFaultDetailComment(props, sPartText);
            }
              
            return {
                m_cExamineResult: m_cExamineResult,
                sExamineComment: sExamineComment
            };
            
        }
        
        return {
            m_cExamineResult: "",
            sExamineComment: ""
        };
    }

    const initialValue : TextEditorForm = {
        pointText : element.pointText??"",
        pointSide : element.pointSide??"",
        examineText : handleDoGetShortCircuitCurrentText(1).sExamineComment??"",
        shortCircuitCurrentText : handleDoGetShortCircuitCurrentText(0).m_cExamineResult??"",
    }

    const {control, formState: { errors }, setValue, getValues } = useForm<TextEditorForm>({
        mode: "all",
        criteriaMode: "all",
        defaultValues: initialValue,
    })

    const handleOnOk = (e:any) => {
        
        let param = {
            id : Number(element.id),
            pointText : getValues('pointText').trim(),
            pointSide : initialValue.pointSide,
            examineComment : getValues('examineText').trim(),
            isUpdate: initialValue.examineText ===  getValues('examineText').trim() && initialValue.pointText === getValues('pointText').trim() ? false : true 
        }
        onOK(param)
    }


    return (
        <>
            <Dialog open={true} maxWidth={"sm"} fullWidth
                hideBackdrop 
                PaperComponent={
                    DraggablePaper
                }
            >
                <DialogTitle 
                    onMouseOver={(e : any) => e.target.style.cursor = 'move'}
                    style={{ paddingTop: '2px', paddingBottom: '2px', background: theme.palette.primary.main, color: theme.palette.primary.contrastText }}
                >
                    {title || `短絡電流に対する検討`}
                </DialogTitle>
                <DialogContent>
                    <div style={{width: '100%', height: '500px', maxWidth:"100%"}}>
                        <Grid container mt={1} columnSpacing={4}>
                            <Grid item xs={7.5} mt={1}>
                                <FormControl  size="small" >
                                    <Controller
                                        name="pointText"
                                        control={control}
                                        render={({ field }) => (
                                            <FormControlLabel {...field} style={{height:"40px"}} label="事故点名称:" labelPlacement="start" control={<input style={{width:'90px',fontSize: 16, border: '2px solid #ccc',  borderRadius: '4px'}}/>}/>
                                        )}
                                    />
                                </FormControl>
                                <FormControl  size="small">
                                    <Controller
                                        name="pointSide"
                                        control={control}
                                        render={({ field }) => (
                                            <FormControlLabel {...field} style={{height:"40px"}} label="サイド:" labelPlacement="start" control={<input style={{width:'70px',fontSize: 16, border: '2px solid #ccc', borderRadius: '4px', cursor: 'default'}} readOnly/>}/>
                                        )}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={4.5} mt={1} >
                                <Grid container columnSpacing={2}>
                                    <Grid item >
                                        <FormControl  size="small" >
                                            <Button  onClick={handleOnOk}  variant="contained" >OK</Button>
                                        </FormControl>
                                    </Grid>
                                    <Grid item >
                                        <FormControl  size="small">
                                            <Button onClick={onCancel} color="inherit" variant="contained" >キャンセル</Button>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <div style={{height:'260px', width:"100%", marginTop: '20px'}}>
                                <Controller
                                name="shortCircuitCurrentText"
                                control={control}
                                render={({ field }) => (
                                    <textarea  {...field}
                                    style={{width:'100%', height:'100%', overflow: 'scroll',resize: 'none', whiteSpace : 'pre', fontSize: 16, border: '2px solid #ccc', borderRadius: '4px',backgroundColor: '#f8f8f8', cursor: 'default'}} readOnly>
                                    </textarea>
                                )}
                            />
                        </div>  
                       
                        <div  style={{height:'140px', width:"100%", marginTop: '10px'}}>
                            <Controller
                                name="examineText"
                                control={control}
                                render={({ field }) => (
                                    <textarea {...field}
                                    style={{width:'100%', height:'100%', overflow: 'scroll',resize: 'none',  whiteSpace : 'pre', fontSize: 16, border: '2px solid #ccc',borderRadius: '4px',backgroundColor: '#f8f8f8' }}>
                                    </textarea>
                                )}
                            />
                        </div>  
                    </div>                    
                </DialogContent>
            </Dialog>
        </>
    )
}
export default ShortCircuitCurrentReviewDialog;