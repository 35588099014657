import React, { useEffect, useRef, useState } from 'react'
import { EquipTrans1Model, EquipTrans3Model } from '../../../models/breakModel'
import { Box, Button, Checkbox, CircularProgress, FormControlLabel, Grid, Stack, Tab, Tabs, TextField, Typography } from '@mui/material'
import { ApplicationState, actionCreators, selectors } from '../../../store'
import { connect } from 'react-redux'
import { Controller, useForm } from 'react-hook-form'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Path as RoutePath } from "../../../path";
import FetchHelper from '../../FetchHelper'
import { LoadingButton } from '@mui/lab'
//-----------
//FETCH ID
const GET_EQUIP_TRANS1 = "GET_EQUIP_TRANS1"
const UPDATE_EQUIP_TRANS1 = "UPDATE_EQUIP_TRANS1"
const CREATE_EQUIP_TRANS1 = "CREATE_EQUIP_TRANS1"

const initialValue: EquipTrans1Model = {
    lTrans1Id: 0,
    bIsDefault: false,
    dCapacity: 0,
    dDampingTimeOutIn: 0,
    dDampingTimeOutOut: 0,
    dPeakValueOutIn: 0,
    dPeakValueOutOut: 0,
    dPerroutIn: 0,
    dPerroutOut: 0,
    dPerxoutIn: 0,
    dPerxoutOut: 0,

}
// -------------
// Props
export type EquipTrans1EditProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
const EquipTrans1Edit = (props: EquipTrans1EditProps) => {
    const {
        userId,
        onCreate, onCreateError,
        onUpdate, onUpdateError,
        onSuccess, onError,
    } = props;

    const { control, formState: { errors }, handleSubmit, setValue, getValues, } = useForm<EquipTrans1Model>({
        criteriaMode: "all",
        defaultValues: initialValue,
        mode: "all",
    })
    type MyParams = {
        id: string;
        mode: string;
    };
    let { id, mode } = useParams<keyof MyParams>() as MyParams;
    const location = useLocation();
    let navigate = useNavigate();
    const [loadingFlag, setLoadingFlag] = useState(mode === "edit" ? true : false);
    const gridRef = useRef() as any;
    const Exp = /^[-+]?\d+(\.\d+)?$/;
    const messageIsNotNumber = "数字を入力してください。";

    const handleSuccess = (data: any) => {
        console.log('>>>> handle Successs');
        // if(data && data.data){
        const obj = data.data;
        setValue("lTrans1Id", obj.lTrans1Id);
        setValue("bIsDefault", obj.bIsDefault);
        setValue("dCapacity", obj.dCapacity?? "");
        setValue("dDampingTimeOutIn", obj.dDampingTimeOutIn?? "");
        setValue("dDampingTimeOutOut", obj.dDampingTimeOutOut?? "");
        setValue("dPeakValueOutIn", obj.dPeakValueOutIn?? "");
        setValue("dPeakValueOutOut", obj.dPeakValueOutOut?? "");
        setValue("dPerroutIn", obj.dPerroutIn?? "");
        setValue("dPerroutOut", obj.dPerroutOut?? "");
        setValue("dPerxoutIn", obj.dPerxoutIn?? "");
        setValue("dPerxoutOut", obj.dPerxoutOut?? "");
        // }
        setLoadingFlag(false);
    }

    // 検索エラー時
    const handleError = (success: boolean, data: any, error: any) => {
        console.log('>>>> handle Error');
        gridRef.current.hideOverlay();
    }

    // 検索実行
    const handleEditSubmit = (data: EquipTrans1Model) => {
        setLoadingFlag(true);
        if (mode === "edit") {
            let request = {
                lTrans1Id: getValues("lTrans1Id"),
                bIsDefault: getValues("bIsDefault") ?? null,
                dCapacity: getValues("dCapacity") ?? null,
                dDampingTimeOutIn: getValues("dDampingTimeOutIn") ?? null,
                dDampingTimeOutOut: getValues("dDampingTimeOutOut") ?? null,
                dPeakValueOutIn: getValues("dPeakValueOutIn") ?? null,
                dPeakValueOutOut: getValues("dPeakValueOutOut") ?? null,
                dPerroutIn: getValues("dPerroutIn") ?? null,
                dPerroutOut: getValues("dPerroutOut") ?? null,
                dPerxoutIn: getValues("dPerxoutIn") ?? null,
                dPerxoutOut: getValues("dPerxoutOut") ?? null
            } as any
            const object = {
                requestPrms: request,
                userId: userId,
            }
            onUpdate(object);
        } else if (mode === "add") {
            let request = {
                bIsDefault: getValues("bIsDefault") ?? null,
                dCapacity: getValues("dCapacity") ?? null,
                dDampingTimeOutIn: getValues("dDampingTimeOutIn") ?? null,
                dDampingTimeOutOut: getValues("dDampingTimeOutOut") ?? null,
                dPeakValueOutIn: getValues("dPeakValueOutIn") ?? null,
                dPeakValueOutOut: getValues("dPeakValueOutOut") ?? null,
                dPerroutIn: getValues("dPerroutIn") ?? null,
                dPerroutOut: getValues("dPerroutOut") ?? null,
                dPerxoutIn: getValues("dPerxoutIn") ?? null,
                dPerxoutOut: getValues("dPerxoutOut") ?? null
            } as any
            const object = {
                requestPrms: request,
                userId: userId,
            }
            onCreate(object);
        }
    }

    // キャンセル
    const handleCancel = () => {
        if (location.key !== "default")
            navigate(-1);
        else
            navigate(RoutePath.EquipTrans1List);
    }
    // Submit後のAPI呼び出しエラー時
    const handleErrorUpdate = (success: boolean, data: any, error: any) => {
        onUpdateError();
        setLoadingFlag(false);
    }

    // Submit後のAPI呼び出し成功時
    const handleSuccessSubmit = (success: boolean, data: any, error: any) => {
        onSuccess();
        if (mode === "add") {
            navigate(RoutePath.EquipTrans1Edit + `/edit/${data.data.lTrans1Id}`, { replace: true })
        }
        setLoadingFlag(false);
    }

    // Submit後のAPI呼び出しエラー時
    const handleErrorSubmit = (success: boolean, data: any, error: any) => {
        onCreateError();
        setLoadingFlag(false)
    }

    //入力欄背景
    const divStyles = {
        backgroundColor: 'white',
        border: '1px solid #bebebe',
        boxShadow: '0 0 5px #fff, 0 0 5px #bebebe, 0 0 1px #aaa',
        padding: '20px 40px',
        marginTop: '40px',
    }

    return (
        <>
            {
                mode === "edit" &&
                <FetchHelper
                    fetchId={GET_EQUIP_TRANS1}
                    url={`/master/equip_trans1/get`}
                    method="POST"
                    request={true}
                    params={{
                        requestPrms: {
                            id: id
                        },
                        userId: userId,
                    }}
                    json={true}
                    onComplete={(success, data, error) => {
                        success ? handleSuccess(data) : handleError(success, data, error);
                    }}
                />
            }
            {loadingFlag && (
                <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1500, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                    <CircularProgress />
                </div>
            )}
            <div style={{ height: "100%", width: "100%", marginTop: "10px" }}>
                <Stack direction="row" justifyContent="flex-start" alignItems="center" sx={{}} style={{ margin: '18px', color: 'MenuText' }}>
                    <Typography variant="h4">
                    単相変圧器詳細
                    </Typography>
                </Stack>
                <form onSubmit={handleSubmit(handleEditSubmit)}>
                    <div style={divStyles}>
                        <Typography variant="h6">
                        単相変圧器
                        </Typography>
                        <Stack mt={3} direction={{ xs: 'column', sm: 'row' }} sx={{ maxHeight: "calc(100vh)", overflow: 'auto' }}>
                            <Grid container spacing={3} direction={'row'}>
                                <Grid item xs={6} mt={1}>
                                    <Controller
                                        control={control}
                                        name="dCapacity"
                                        rules={{
                                            pattern: {
                                                value: Exp,
                                                message: messageIsNotNumber,
                                            }
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                fullWidth
                                                {...field}
                                                error={!!errors?.dCapacity}
                                                helperText={errors?.dCapacity?.message}
                                                type="text"
                                                size="small"
                                                label="容量（kVA）"
                                            // InputLabelProps={{ shrink: true }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6} mt={1}>
                                    <Controller
                                        control={control}
                                        name="dDampingTimeOutIn"
                                        rules={{
                                            pattern: {
                                                value: Exp,
                                                message: messageIsNotNumber,
                                            }
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                fullWidth
                                                {...field}
                                                error={!!errors?.dDampingTimeOutIn}
                                                helperText={errors?.dDampingTimeOutIn?.message}
                                                type="text"
                                                size="small"
                                                label="Damping time constant（cycles）(外線‐中性線)"


                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Controller
                                        control={control}
                                        name="dDampingTimeOutOut"
                                        rules={{
                                            pattern: {
                                                value: Exp,
                                                message: messageIsNotNumber,
                                            }
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                fullWidth
                                                {...field}
                                                error={!!errors?.dDampingTimeOutOut}
                                                helperText={errors?.dDampingTimeOutOut?.message}
                                                type="text"
                                                label="Damping time constant（cycles）(外線‐外線)"
                                                size="small"

                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Controller
                                        control={control}
                                        name="dPeakValueOutIn"
                                        rules={{
                                            pattern: {
                                                value: Exp,
                                                message: messageIsNotNumber,
                                            }
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                fullWidth
                                                {...field}
                                                error={!!errors?.dPeakValueOutIn}
                                                helperText={errors?.dPeakValueOutIn?.message}
                                                type="text"
                                                label="Peak value of rush current（times）(外線‐中性線)"
                                                size="small"

                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Controller
                                        control={control}
                                        name="dPeakValueOutOut"
                                        rules={{
                                            pattern: {
                                                value: Exp,
                                                message: messageIsNotNumber,
                                            }
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                fullWidth
                                                {...field}
                                                error={!!errors?.dPeakValueOutOut}
                                                helperText={errors?.dPeakValueOutOut?.message}
                                                type="text"
                                                label="Peak value of rush current（times）(外線‐外線)"
                                                size="small"

                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Controller
                                        control={control}
                                        name="dPerroutIn"
                                        rules={{
                                            pattern: {
                                                value: Exp,
                                                message: messageIsNotNumber,
                                            }
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                fullWidth
                                                {...field}
                                                error={!!errors?.dPerroutIn}
                                                helperText={errors?.dPerroutIn?.message}
                                                type="text"
                                                label="外線-中性線間の%R（%）"
                                                size="small"

                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Controller
                                        control={control}
                                        name="dPerroutOut"
                                        rules={{
                                            pattern: {
                                                value: Exp,
                                                message: messageIsNotNumber,
                                            }
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                fullWidth
                                                {...field}
                                                error={!!errors?.dPerroutOut}
                                                helperText={errors?.dPerroutOut?.message}
                                                type="text"
                                                label="外線間の%R（%）"
                                                size="small"

                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Controller
                                        control={control}
                                        name="dPerxoutIn"
                                        rules={{
                                            pattern: {
                                                value: Exp,
                                                message: messageIsNotNumber,
                                            }
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                fullWidth
                                                {...field}
                                                error={!!errors?.dPerxoutIn}
                                                helperText={errors?.dPerxoutIn?.message}
                                                type="text"
                                                label="外線-中性線間の%X（%）"
                                                size="small"

                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Controller
                                        control={control}
                                        name="dPerxoutOut"
                                        rules={{
                                            pattern: {
                                                value: Exp,
                                                message: messageIsNotNumber,
                                            }
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                fullWidth
                                                {...field}
                                                error={!!errors?.dPerxoutOut}
                                                helperText={errors?.dPerxoutOut?.message}
                                                type="text"
                                                label="外線間の%X（%）"
                                                size="small"

                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Controller
                                        control={control}
                                        name="bIsDefault"
                                        render={({ field }: any) => (
                                            <FormControlLabel {...field} control={<Checkbox />} checked={field.value} label="デフォルトの容量値" labelPlacement="start" />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Stack>
                    </div>
                    <Stack direction='row' justifyContent="flex-end" spacing={2} sx={{ mt: 1, mr: 2 }}>
                        <LoadingButton variant="contained" type="submit" loading={loadingFlag}>保存</LoadingButton>
                        <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                    </Stack>
                    {/* </TabPanel> */}
                </form>
            </div>
            <FetchHelper fetchId={UPDATE_EQUIP_TRANS1}
                onComplete={(success: boolean, data: any, error: any) =>
                    success ? handleSuccessSubmit(success, data, error) : handleErrorUpdate(success, data, error)} />

            <FetchHelper fetchId={CREATE_EQUIP_TRANS1}
                onComplete={(success, data, error) => success ?
                    handleSuccessSubmit(success, data, error) :
                    handleErrorSubmit(success, data, error)} />

        </>
    );
}
const mapStateToProps = (state: ApplicationState) => (
    {
        userId: state.app.user?.userId,
    }
)
const mapDispatchToProps = (dispatch: any) => {
    return {
        onCreate: (data: any) => dispatch(actionCreators.fetch(CREATE_EQUIP_TRANS1, `/master/equip_trans1/add`, "POST", data, false, true)),
        onUpdate: (data: any) => dispatch(actionCreators.fetch(UPDATE_EQUIP_TRANS1, `/master/equip_trans1/modify`, "POST", data, false, true)),
        onSuccess: () =>
            dispatch(actionCreators.showMessage({
                type: "info",
                title: "単相変圧器更新",
                body: "単相変圧器を更新しました。",
            })),
        onError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "検索",
                body: "検索できません。",
            })),
        onCreateError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "単相変圧器登録",
                body: "単相変圧器を登録出来ませんでした。",
            })),
        onUpdateError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "単相変圧器編集",
                body: "単相変圧器を更新出来ませんでした。",
            })),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(EquipTrans1Edit)