
import { Button, Stack } from '@mui/material'
import {useState } from 'react'
import { Html } from 'react-konva-utils'
import { useDispatch } from 'react-redux'
import { updateQuickProperties } from '../../store/Actions'
import { ProcessMode } from '../../models/Index'

const optionStyle = {
  justifyContent: 'flex-start',
  padding: '4px 5px',
  '&.Mui-disabled': { color: '#a6a6a6' },
  '&:hover': { backgroundColor: '#e6f7ff' }
}

type Mode47Menu = {
  x: number
  y: number
  processMode:any,
  m_bModePM: any,
  modeViewOnly:any,
  dropMode: any,
  flowViewMethod:any,
}

const Mode47Menu = (props: Mode47Menu) => {
    //#region field
    const {
        x,
        y,
        processMode,
        m_bModePM,
        modeViewOnly,
        dropMode,
        flowViewMethod,
    } = props
    let dispatch = useDispatch();
    const [openMode47,setOpenMode47] = useState<boolean>(true);

    // #endregion method 

  //#region handle cick button

  const handleChangeDropMode = (newData: boolean) => {
    setOpenMode47(false)
    dispatch(updateQuickProperties("drop_mode",newData))
  }
  const handleChangeFlowViewMethod = (newData: boolean) => {
    setOpenMode47(false)
    dispatch(updateQuickProperties("flow_view_method",newData))
  }


  //#endregion
  return (
    <>
        <Html>
        {openMode47 && 
          <Stack
            style={{
              position: 'fixed',
              top: y,
              left: x,
              backgroundColor: 'white',
              zIndex: 9999,
              width: '230px',
              boxShadow: '5px 5px 10px grey',
              border: '0.7px solid grey',
              textAlign: 'left',
            }}
          >
            { processMode == ProcessMode.VOLTDROP &&
                <>
            <Button
              fullWidth
              color='inherit'
              sx={{
                justifyContent: "flex-start",
                padding: "4px 5px",
                "&.Mui-disabled": { color: "#a6a6a6" },
                "&:hover": { backgroundColor: "#e6f7ff" },
                backgroundColor:dropMode == 0?"#e6f7ff" :undefined}}
              onClick={() => handleChangeDropMode(false)}
            >
              電動機始動時電圧降下表示モード
            </Button>
            <Button
              fullWidth
              color='inherit'
              sx={{
                justifyContent: "flex-start",
                padding: "4px 5px",
                "&.Mui-disabled": { color: "#a6a6a6" },
                "&:hover": { backgroundColor: "#e6f7ff" },
                backgroundColor:dropMode == 1?"#e6f7ff" :undefined}}
              onClick={() => handleChangeDropMode(true)}
            >
              定常時電圧降下表示モード
            </Button>
            </>
            }
            {(processMode == ProcessMode.POWER_FLOW && !(m_bModePM || modeViewOnly)) &&
                <>
            <Button
              fullWidth
              color='inherit'
              sx={{
                justifyContent: "flex-start",
                padding: "4px 5px",
                "&.Mui-disabled": { color: "#a6a6a6" },
                "&:hover": { backgroundColor: "#e6f7ff" },
                backgroundColor:flowViewMethod == 1?"#e6f7ff" :undefined}}
              onClick={() => handleChangeFlowViewMethod(false)}
            >
              機器ごとに表示
            </Button>
            <Button
              fullWidth
              color='inherit'
              sx={{
                justifyContent: "flex-start",
                padding: "4px 5px",
                "&.Mui-disabled": { color: "#a6a6a6" },
                "&:hover": { backgroundColor: "#e6f7ff" },
                backgroundColor:flowViewMethod == 2?"#e6f7ff" :undefined}}
              onClick={() => handleChangeFlowViewMethod(true)}
            >
              母線ごとに表示
            </Button>
            </>
            }

          </Stack>
        }
        </Html>
    </>
  )
}

const mapDispatchToProps = (dispatch: any) => {
  

}
export default Mode47Menu