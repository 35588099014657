import { Button, ButtonGroup, Checkbox, Container, Dialog, DialogContent, DialogTitle, FormControlLabel, Grid, Radio, RadioGroup, Stack, TextField, TextareaAutosize, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import theme from "../../themes/globalStyles";
import DraggablePaper from "../common/DraggablePaper";
import { useState } from "react";
import { FontFormatType, InfoCircuit, InfoSkeleton, Report } from "../../models/Index";
import FormatTextDialog from "./FormatTextDialog";

export type SettingReportForm = {
    printDetailHead: boolean,
    figTitle: string,
    figSubTitle: string,
    titleCorporation: string,
    corporation: string,
    revision1 : string,
    titleProject: string,
    project: string,
    drawn: string,
    drawner: string,
    titleDateDrawn: string,
    dateDrawn: string,
    revision2: string,
    titleReference: string,
    reference: string,
    checked: string,
    checker: string,
    titleDateChecked: string,
    dateChecked: string,
    revision3: string,
    titleNumber: string,
    figNumber: string,
    designed: string,
    designer: string,
    titleDateDesigned: string,
    dateDesigned: string,
    revision4: string,
    titleMode: string,
    approved: string,
    approver:string,
    titleDateApproved: string,
    dateApproved: string,
    revision5: string,
    titleComment: string,
    comments: string,
    marginTop: number,
    marginBottom: number,
    marginLeft: number,
    marginRight: number,
    orientation: number,
    paperSize: number,
    reportIndex: number,
    //memo
    editText : string,
    weight : number,
    italic : boolean,
    underline : boolean,
    strikeOut : boolean,
    charSet : number,
    faceName : string,
    fontSize: number,
    colorFont: string
}

export type SettingReportFormProps = {
    infoSkeleton: InfoSkeleton;
    infoCircuit: InfoCircuit;
    data: any;
    title:string;
    onOK: (data: any) => void;
    onCancel: () => void;
    modeViewOnly:boolean;
    reportIndexEdit?:number
    
};

export const SettingReportDialog = (props: SettingReportFormProps) => {

    //#region fields
    const {infoSkeleton,infoCircuit, data, title, onOK, onCancel,reportIndexEdit,modeViewOnly } = props;

    // 初期値設定
    let initialValue : any = {
            charSet: 0,
            comments:"",
            corporation: infoCircuit.corporation,
            printDetailHead: infoCircuit.printDetailHeadView3,
            project: infoCircuit.project,
            reference: infoCircuit.reference,
            editText: "",
            electricPower: 0,
            faceName: "",
            figNumber: "",
            figSubTitle: "",
            figTitle: "",
            frequency: infoCircuit.frequency,
            italic: false,
            colorFont: "black",
            fontSize: 16,
            marginBottom: 0,
            marginLeft: 0,
            marginRight: 0,
            marginTop: 0,
            orientation: 0,
            paperSize: 0,
            stdCapacity: infoCircuit.stdCapacity,
            strikeOut: false,
            titleCorporation: infoCircuit.titleCorporation,
            titleProject: infoCircuit.titleProject,
            titleReference: infoCircuit.titleReference,
            underline: false,
            voltage: 0,
            weight: 0,
            revision1: infoSkeleton.revision0,
            revision2: infoSkeleton.revision1,
            revision3: infoSkeleton.revision2,
            revision4: infoSkeleton.revision3,
            revision5: infoSkeleton.revision4,
            dateDrawn: infoSkeleton.dateDrawn,
            dateChecked: infoSkeleton.dateChecked,
            dateDesigned: infoSkeleton.dateDesigned,
            dateApproved: infoSkeleton.dateApproved,
            titleDateDrawn: infoSkeleton.titleDateDrawn,
            titleDateChecked: infoSkeleton.titleDateChecked,
            titleDateDesigned: infoSkeleton.titleDateDesigned,
            titleDateApproved: infoSkeleton.titleDateApproved,
            drawner: infoSkeleton.drawner,
            checker: infoSkeleton.checker,
            designer: infoSkeleton.designer,
            approver: infoSkeleton.approver,
            drawn: infoSkeleton.titleDrawn,
            checked: infoSkeleton.titleChecked,
            designed: infoSkeleton.titleDesigned,
            approved: infoSkeleton.titleApproved,
            titleNumber: infoSkeleton.titleNumber,
            titleMode: infoSkeleton.titleMode,
            titleComment: infoSkeleton.titleComment

    }

    if(data !==undefined){
        initialValue ={
            charSet: data.charSet,
            comments:data.comments,
            corporation: infoCircuit.corporation,
            printDetailHead: data.printDetailHeadView3,//infoCircuit.printDetailHeadView3,
            project: infoCircuit.project,
            reference: infoCircuit.reference,
            editText: data.editText,
            electricPower: data.electricPower,
            faceName: data.faceName,
            figNumber: data.figNumber,
            figSubTitle: data.figSubTitle,
            figTitle: data.figTitle,
            frequency: infoCircuit.frequency,
            italic: data.italic,
            colorFont: data.colorFont,
            fontSize: data.fontSize,
            marginBottom: data.marginBottom,
            marginLeft: data.marginLeft,
            marginRight: data.marginRight,
            marginTop: data.marginTop,
            orientation: data.orientation,
            paperSize: data.paperSize,
            stdCapacity: infoCircuit.stdCapacity,
            strikeOut: data.strikeOut,
            titleCorporation: infoCircuit.titleCorporation,
            titleProject: infoCircuit.titleProject,
            titleReference: infoCircuit.titleReference,
            underline: data.underline,
            voltage: data.voltage,
            weight: data.weight,

            revision1: infoSkeleton.revision0,
            revision2: infoSkeleton.revision1,
            revision3: infoSkeleton.revision2,
            revision4: infoSkeleton.revision3,
            revision5: infoSkeleton.revision4,
            dateDrawn: infoSkeleton.dateDrawn,
            dateChecked: infoSkeleton.dateChecked,
            dateDesigned: infoSkeleton.dateDesigned,
            dateApproved: infoSkeleton.dateApproved,
            titleDateDrawn: infoSkeleton.titleDateDrawn,
            titleDateChecked: infoSkeleton.titleDateChecked,
            titleDateDesigned: infoSkeleton.titleDateDesigned,
            titleDateApproved: infoSkeleton.titleDateApproved,
            drawner: infoSkeleton.drawner,
            checker: infoSkeleton.checker,
            designer: infoSkeleton.designer,
            approver: infoSkeleton.approver,
            drawn: infoSkeleton.titleDrawn,
            checked: infoSkeleton.titleChecked,
            designed: infoSkeleton.titleDesigned,
            approved: infoSkeleton.titleApproved,
            titleNumber: infoSkeleton.titleNumber,
            titleMode: infoSkeleton.titleMode,
            titleComment: infoSkeleton.titleComment
        }
    }
    const fontStyle : FontFormatType ={
        fontSize: initialValue.fontSize,
        faceName : initialValue.faceName,
        colorFont : initialValue.colorFont,
        underline : initialValue.underline,
        strikeOut : initialValue.strikeOut,
        italic : initialValue.italic,
        weight : initialValue.weight,
        charSet : initialValue.charSet, //TODO: 
    }
    const checkboxStyle = {
        padding: '0px 0px 0px 9px'
    }
    const { control, handleSubmit, formState: { errors }, setValue, getValues } = useForm<SettingReportForm>({
        mode: "all",
        criteriaMode: "all",
        defaultValues: initialValue,
    })
        const [isDisableField,SetIsDisableField] = useState(!initialValue.printDetailHead)
        const [isDisplayMemo,setIsDisplayMemo] = useState(false)
        const [isDisplayFontDialog,setIsFontDialog] = useState(false)
        const [fontFormatType, setFontFormatStyle] = useState({...fontStyle})


    //#endregion fields

    //#region methods
    // Submitイベント
    const handleEditSubmit = (value: any, event: any) => {
        const request = createRequestData(value)
        onOK(request);
    }
    
    // FormデータからRequestデータを作成する
    const createRequestData = (formValue : SettingReportForm) => {       
        formValue.charSet = fontFormatType.charSet
        formValue.weight = fontFormatType.weight
        formValue.fontSize = fontFormatType.fontSize
        formValue.weight = fontFormatType.weight
        formValue.faceName = fontFormatType.faceName
        formValue.italic = fontFormatType.italic
        formValue.underline = fontFormatType.underline
        formValue.strikeOut = fontFormatType.strikeOut
        formValue.colorFont = fontFormatType.colorFont

        // if(!formValue.figTitle){
        //     formValue.figTitle = ""
        // }
        // if(!formValue.figSubTitle){
        //     formValue.figSubTitle = ""
        // }
        // if(!formValue.figNumber){
        //     formValue.figNumber = ""
        // }
        // if(!formValue.comments){
        //     formValue.comments = ""
        // }
        // if(!formValue.marginBottom){
        //     formValue.marginBottom = 0
        // }
        // if(!formValue.marginLeft){
        //     formValue.marginLeft = 0
        // }
        // if(!formValue.marginRight){
        //     formValue.marginRight = 0
        // }
        // if(!formValue.marginTop){
        //     formValue.marginTop = 0
        // }
        // if(!formValue.orientation){
        //     formValue.orientation = 0
        // }
        let request = {
            ...formValue,
        } as SettingReportForm;
        return request;
    }

    const handleReset = () => {
        switch(title)
        {
            case '機器情報':
                {
                    setValue("figTitle", '機器情報')
                    setValue("figSubTitle", 'Device information')
                    break;
                }
            case '基準計算':
                {
                    setValue("figTitle", '基準計算')
                    setValue("figSubTitle", 'Reference calculation table')
                    break;
                }
            case 'インピーダンス解析結果':
                {
                    setValue("figTitle", 'ｲﾝﾋﾟｰﾀﾞﾝｽ解析結果')
                    setValue("figSubTitle", 'Impedance analysis result')
                    break;
                }
            case '短絡電流解析結果(簡略)':
                {
                    setValue("figTitle", '短絡電流解析結果(簡略)')
                    setValue("figSubTitle", 'Short-circuit current analysis result (brief)')
                    break;
                }
            case '短絡電流解析結果(詳細)':
                {
                    setValue("figTitle", '短絡電流解析結果(詳細)')
                    setValue("figSubTitle", 'Short-circuit current analysis result (detail)')
                    break;
                }
            case '電圧降下解析結果':
                {
                    setValue("figTitle", '電圧降下解析結果')
                    setValue("figSubTitle", 'Voltage drop analysis result')
                    break;
                }
            case '電圧降下合算結果':
                {
                    setValue("figTitle", '電圧降下合算結果')
                    setValue("figSubTitle", 'Voltage drop sum result')
                    break;
                }
            case '力率改善解析結果':
                {
                    setValue("figTitle", '力率改善解析結果')
                    setValue("figSubTitle", 'Power factor improvement analysis result')
                    break;
                }
            case '高調波解析結果':
                {
                    setValue("figTitle", '高調波解析結果')
                    setValue("figSubTitle", 'Harmonic analysis result')
                    break;
                }
            case '潮流解析結果':
                {
                    setValue("figTitle", '潮流解析結果')
                    setValue("figSubTitle", 'Load flow analysis result')
                    break;
                }
            case '整定情報一覧表':
                {
                    setValue("figTitle", '整定情報一覧表')
                    setValue("figSubTitle", 'Setting information list')
                    break;
                }
            case 'メモ編集':
                {
                    setValue("figTitle", 'メモ編集')
                    setValue("figSubTitle", 'memo text')
                    break;
                }
            default:
                setValue("figTitle",initialValue.figTitle)
                setValue("figSubTitle",initialValue.figSubTitle)
                break;
        }
    }
    const checkedPrintDetailHead =(e:any) =>{
        setValue(e.target.name,e.target.checked)
        if(getValues("printDetailHead")){
            SetIsDisableField(false)
        }
        else{
            SetIsDisableField(true)
        }
    }
    
    const handleOKFormatTextForm = (data: any) => {
        // reset(data)
        setFontFormatStyle({...data})
        setIsFontDialog(false)
    }
    //#endregion methods
    const handleDisplayFontDialog =() =>{
        setIsFontDialog(true)
    }

    return (
        <>
            <Dialog open={true} maxWidth={"md"} fullWidth
                PaperComponent={
                    DraggablePaper
                }
            >
                <DialogTitle 
                    onMouseOver={(e : any) => e.target.style.cursor = 'move'}
                    style={{ paddingTop: '2px', paddingBottom: '2px', background: theme.palette.primary.main, color: theme.palette.primary.contrastText }}
                >
                    レポートの設定 -［{title}］
                </DialogTitle>
                <DialogContent style={{paddingTop: '10px'}}>
                    <div style={{height:"40vh", width: '100%',marginLeft:'-10px'}}>
                        <form onSubmit={handleSubmit(handleEditSubmit)}>
                            <div style={{height:"calc(40vh - 45px)",overflowY:"auto", width: '100%'}}>
                                <Grid container>
                                    <fieldset style={{width:'100%',border:'1px solid #AAAA'}}>
                                        <legend ><Typography variant="body2">単線結線図の印刷で使用</Typography></legend>
                                            <Stack direction="row" justifyContent={"space-between"}>
                                                <Stack>
                                                    <Controller
                                                            name="printDetailHead"
                                                            control={control}
                                                            render={({ field }: any) => (
                                                                <FormControlLabel 
                                                                    {...field}
                                                                    checked={field.value}
                                                                    label={<Typography variant="body2">ヘッドラインを印刷</Typography>}
                                                                    name='printDetailHead'
                                                                    control={<Checkbox style={checkboxStyle} />} 
                                                                    onChange={e =>checkedPrintDetailHead(e)}
                                                                    disabled={modeViewOnly}
                                                                />
                                                            )}
                                                        />
                                                </Stack>
                                                <Stack  mr={1}>
                                                    <Button  color="inherit" variant="contained" onClick={handleReset} disabled={modeViewOnly}>リセット</Button>
                                                </Stack>
                                            </Stack>
                                            <Grid container direction={"row"} mt={1}>
                                                        <Grid item xs={4} pl={0.3}>
                                                            <Stack>
                                                                <Typography variant="body2">タイトル:</Typography>
                                                            </Stack>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Stack>
                                                                <Typography variant="body2">サブタイトル:</Typography>
                                                            </Stack>
                                                        </Grid>
                                                </Grid>
                                                        <Grid container direction={"row"} spacing={0.3}>
                                                                <Grid item xs={3.95}>
                                                                    <Controller
                                                                        name="figTitle"
                                                                        control={control}
                                                                        render={({ field }) => (
                                                                            <>
                                                                                <TextField
                                                                                    {...field}
                                                                                    type="text"
                                                                                    size="small"
                                                                                    fullWidth
                                                                                    error={!!errors?.figTitle}
                                                                                    onFocus={e => e.target.select()}
                                                                                    InputProps={{
                                                                                        sx:{height:32},
                                                                                    }}
                                                                                    disabled={modeViewOnly}
                                                                                />
                                                                            </>
                                                                        )}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={5.95}>
                                                                    <Controller
                                                                        name="figSubTitle"
                                                                        control={control}
                                                                        render={({ field }) => (
                                                                            <>
                                                                                <TextField
                                                                                    {...field}
                                                                                    type="text"
                                                                                    size="small"
                                                                                    fullWidth
                                                                                    error={!!errors?.figSubTitle}
                                                                                    onFocus={e => e.target.select()}
                                                                                    InputLabelProps={{
                                                                                        style: { color: 'black' },
                                                                                    }}
                                                                                    InputProps={{
                                                                                        sx:{height:32},
                                                                                    }}
                                                                                    disabled={modeViewOnly}
                                                                                />
                                                                            </>
                                                                        )}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={1} mt={0.6} ml={0.3}>
                                                                    <Typography variant="body2">Revisions:</Typography>
                                                                </Grid>
                                                        </Grid>

                                                <Grid container direction={"row"} mt={1}>
                                                    <Grid item xs={2}>
                                                            <Controller
                                                                    name="titleCorporation"
                                                                    control={control}
                                                                    rules={{
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <TextField 
                                                                            {...field}
                                                                            fullWidth
                                                                            type="text"
                                                                            size="small"
                                                                            error={!!errors?.titleCorporation}
                                                                            helperText={errors?.titleCorporation?.message}
                                                                            
                                                                            style={{width:'100%',backgroundColor:'floralwhite'}}
                                                                            disabled={true}
                                                                            // value={"会社"}
                                                                            InputProps={{sx: { height: 32 }}}

                                                                        />
                                                                    )}
                                                                />
                                                    </Grid>
                                                    <Grid item xs ={0.2} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                        :
                                                    </Grid>
                                                <Grid item xs={7.698}>
                                                            <Controller
                                                                    name="corporation"
                                                                    control={control}
                                                                    rules={{
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <TextField 
                                                                            {...field}
                                                                            fullWidth
                                                                            type="text"
                                                                            size="small"
                                                                            error={!!errors?.corporation}
                                                                            helperText={errors?.corporation?.message}
                                                                            style={{backgroundColor:'floralwhite'}}
                                                                            InputProps={{sx: { height: 32 }}}

                                                                            disabled={true}
                                                                        />
                                                                    )}
                                                                />
                                                </Grid>
                                                <Grid item xs={2} ml={0.4}>
                                                    <Typography variant="body2" width="25%"></Typography>
                                                        <Controller
                                                                name="revision1"
                                                                control={control}
                                                                rules={{
                                                                }}
                                                                render={({ field }) => (
                                                                    <TextField 
                                                                        {...field}
                                                                        fullWidth
                                                                        type="text"
                                                                        size="small"
                                                                        error={!!errors?.revision1}
                                                                        helperText={errors?.revision1?.message}
                                                                        disabled={true}
                                                                        InputProps={{sx: { height: 32 }}}

                                                                        style={{
                                                                            backgroundColor: 'floralwhite',
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                </Grid>
                                            </Grid>

                                            <Grid container direction={"row"} mt={1}>
                                                <Grid item xs={2}>
                                                    <Stack>
                                                            <Controller
                                                                    name="titleProject"
                                                                    control={control}
                                                                    rules={{
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <TextField 
                                                                            {...field}
                                                                            fullWidth
                                                                            type="text"
                                                                            size="small"
                                                                            error={!!errors?.titleProject}
                                                                            helperText={errors?.titleProject?.message}
                                                                            style={{width:'100%',backgroundColor:'floralwhite'}}
                                                                            InputProps={{sx: { height: 32 }}}

                                                                            disabled={true}
                                                                            // value={"プロジェクト"}

                                                                        />
                                                                    )}
                                                                />
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs ={0.2} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                    :
                                                </Grid>
                                                <Grid item xs={1.75}>
                                                    <Stack>
                                                            <Controller
                                                                    name="project"
                                                                    control={control}
                                                                    rules={{
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <TextField 
                                                                            {...field}
                                                                            fullWidth
                                                                            type="text"
                                                                            size="small"
                                                                            error={!!errors?.project}
                                                                            helperText={errors?.project?.message}
                                                                            style={{backgroundColor:'floralwhite'}}
                                                                            InputProps={{sx: { height: 32 }}}

                                                                            disabled={true}
                                                                            
                                                                        />
                                                                    )}
                                                                />
                                                    </Stack>
                                                </Grid>
                                                {/* <Grid item xs ={0.15} mt={2.5} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                    
                                                </Grid> */}
                                                <Grid item xs={1.15} ml={0.3}>
                                                    <Stack>
                                                            <Controller
                                                                    name="drawn"
                                                                    control={control}
                                                                    rules={{
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <TextField 
                                                                            {...field}
                                                                            fullWidth
                                                                            type="text"
                                                                            size="small"
                                                                            error={!!errors?.drawn}
                                                                            helperText={errors?.drawn?.message}
                                                                            disabled={true}
                                                                            InputProps={{sx: { height: 32 }}}

                                                                            style={{
                                                                                backgroundColor: 'floralwhite',
                                                                            }}
                                                                            // value={"作成"}
                                                                        />
                                                                    )}
                                                                />
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs ={0.2} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                    :
                                                </Grid>
                                                <Grid item xs={1.75}>
                                                    <Stack>
                                                            <Controller
                                                                    name="drawner"
                                                                    control={control}
                                                                    rules={{
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <TextField 
                                                                            {...field}
                                                                            fullWidth
                                                                            type="text"
                                                                            size="small"
                                                                            error={!!errors?.drawner}
                                                                            helperText={errors?.drawner?.message}
                                                                            disabled={true}
                                                                            style={{
                                                                                backgroundColor: 'floralwhite',
                                                                            }}
                                                                            InputProps={{sx: { height: 32 }}}


                                                                        />
                                                                    )}
                                                                />
                                                    </Stack>
                                                </Grid>
                                                {/* <Grid item xs ={0.15} mt={2.5} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                    
                                                </Grid> */}
                                                <Grid item xs={1.15} ml={0.3}>
                                                    <Stack >
                                                            <Controller
                                                                    name="titleDateDrawn"
                                                                    control={control}
                                                                    rules={{
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <TextField 
                                                                            {...field}
                                                                            fullWidth
                                                                            type="text"
                                                                            size="small"
                                                                            error={!!errors?.titleDateDrawn}
                                                                            helperText={errors?.titleDateDrawn?.message}
                                                                            disabled={true}
                                                                            style={{
                                                                                backgroundColor: 'floralwhite' ,
                                                                            }}
                                                                            InputProps={{sx: { height: 32 }}}
                                                                            // value={"日付"}

                                                                        />
                                                                    )}
                                                                />
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs ={0.2} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                    :
                                                </Grid>
                                                <Grid item xs={1.42}>
                                                    <Stack >
                                                            <Controller
                                                                    name="dateDrawn"
                                                                    control={control}
                                                                    rules={{
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <TextField 
                                                                            {...field}
                                                                            fullWidth
                                                                            type="text"
                                                                            size="small"
                                                                            error={!!errors?.dateDrawn}
                                                                            helperText={errors?.dateDrawn?.message}
                                                                            disabled={true}
                                                                            style={{
                                                                                backgroundColor: 'floralwhite' ,
                                                                            }}
                                                                            InputProps={{sx: { height: 32 }}}


                                                                        />
                                                                    )}
                                                                />
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={2} ml={0.4}>
                                                    <Stack>
                                                            <Controller
                                                                    name="revision2"
                                                                    control={control}
                                                                    rules={{
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <TextField 
                                                                            {...field}
                                                                            fullWidth
                                                                            type="text"
                                                                            size="small"
                                                                            error={!!errors?.revision2}
                                                                            helperText={errors?.revision2?.message}
                                                                            disabled={true}
                                                                            style={{
                                                                                backgroundColor: 'floralwhite',
                                                                            }}
                                                                            InputProps={{sx: { height: 32 }}}


                                                                        />
                                                                    )}
                                                                />
                                                    </Stack>
                                                </Grid>
                                            </Grid>

                                            <Grid container direction={"row"} mt={1}>
                                                <Grid item xs={2}>
                                                    <Stack>
                                                            <Controller
                                                                    name="titleReference"
                                                                    control={control}
                                                                    rules={{
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <TextField 
                                                                            {...field}
                                                                            fullWidth
                                                                            type="text"
                                                                            size="small"
                                                                            error={!!errors?.titleReference}
                                                                            helperText={errors?.titleReference?.message}
                                                                            style={{width:'100%',backgroundColor:'floralwhite'}}

                                                                            disabled={true}
                                                                            // value={"回路名称"}
                                                                            InputProps={{sx: { height: 32 }}}

                                                                        />
                                                                    )}
                                                                />
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs ={0.2} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                    :
                                                </Grid>
                                                <Grid item xs={1.75}>
                                                    <Stack>
                                                            <Controller
                                                                    name="reference"
                                                                    control={control}
                                                                    rules={{
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <TextField 
                                                                            {...field}
                                                                            fullWidth
                                                                            type="text"
                                                                            size="small"
                                                                            error={!!errors?.reference}
                                                                            helperText={errors?.reference?.message}
                                                                            style={{backgroundColor:'floralwhite'}}
                                                                            InputProps={{sx: { height: 32 }}}

                                                                            disabled={true}
                                                                            
                                                                        />
                                                                    )}
                                                                />
                                                    </Stack>
                                                </Grid>
                                                {/* <Grid item xs ={0.15} mt={2.5} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                    
                                                </Grid> */}
                                                <Grid item xs={1.15} ml={0.3}>
                                                    <Stack>
                                                            <Controller
                                                                    name="checked"
                                                                    control={control}
                                                                    rules={{
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <TextField 
                                                                            {...field}
                                                                            fullWidth
                                                                            type="text"
                                                                            size="small"
                                                                            error={!!errors?.checked}
                                                                            helperText={errors?.checked?.message}
                                                                            disabled={true}
                                                                            style={{
                                                                                backgroundColor: 'floralwhite',
                                                                            }}
                                                                            InputProps={{sx: { height: 32 }}}
                                                                            // value={"照査"}

                                                                        />
                                                                    )}
                                                                />
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs ={0.2} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                    :
                                                </Grid>
                                                <Grid item xs={1.75}>
                                                    <Stack>
                                                            <Controller
                                                                    name="checker"
                                                                    control={control}
                                                                    rules={{
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <TextField 
                                                                            {...field}
                                                                            fullWidth
                                                                            type="text"
                                                                            size="small"
                                                                            error={!!errors?.checker}
                                                                            helperText={errors?.checker?.message}
                                                                            disabled={true}
                                                                            style={{
                                                                                backgroundColor: 'floralwhite',
                                                                            }}
                                                                            InputProps={{sx: { height: 32 }}}

                                                                        />
                                                                    )}
                                                                />
                                                    </Stack>
                                                </Grid>
                                                {/* <Grid item xs ={0.15} mt={2.5} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                    
                                                </Grid> */}
                                                <Grid item xs={1.15} ml={0.3}>
                                                    <Stack >
                                                            <Controller
                                                                    name="titleDateChecked"
                                                                    control={control}
                                                                    rules={{
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <TextField 
                                                                            {...field}
                                                                            fullWidth
                                                                            type="text"
                                                                            size="small"
                                                                            error={!!errors?.titleDateChecked}
                                                                            helperText={errors?.titleDateChecked?.message}
                                                                            disabled={true}
                                                                            style={{
                                                                                backgroundColor:  'floralwhite',
                                                                            }}
                                                                            InputProps={{sx: { height: 32 }}}
                                                                            // value={"日付"}

                                                                        />
                                                                    )}
                                                                />
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs ={0.2} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                    :
                                                </Grid>
                                                <Grid item xs={1.42}>
                                                    <Stack >
                                                            <Controller
                                                                    name="dateChecked"
                                                                    control={control}
                                                                    rules={{
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <TextField 
                                                                            {...field}
                                                                            fullWidth
                                                                            type="text"
                                                                            size="small"
                                                                            error={!!errors?.dateChecked}
                                                                            helperText={errors?.dateChecked?.message}
                                                                            disabled={true}
                                                                            style={{
                                                                                backgroundColor: 'floralwhite',
                                                                            }}
                                                                            InputProps={{sx: { height: 32 }}}

                                                                        />
                                                                    )}
                                                                />
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={2} ml={0.4}>
                                                    <Stack>
                                                            <Controller
                                                                    name="revision3"
                                                                    control={control}
                                                                    rules={{
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <TextField 
                                                                            {...field}
                                                                            fullWidth
                                                                            type="text"
                                                                            size="small"
                                                                            error={!!errors?.revision3}
                                                                            helperText={errors?.revision3?.message}
                                                                            disabled={true}
                                                                            style={{
                                                                                backgroundColor:'floralwhite',
                                                                            }}
                                                                            InputProps={{sx: { height: 32 }}}
                                                                        />
                                                                    )}
                                                                />
                                                    </Stack>
                                                </Grid>
                                            </Grid>

                                            <Grid container direction={"row"} mt={1}>
                                                <Grid item xs={2}>
                                                    <Stack>
                                                            <Controller
                                                                  name="titleNumber"
                                                                    control={control}
                                                                    rules={{
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <TextField 
                                                                            {...field}
                                                                            fullWidth
                                                                            type="text"
                                                                            size="small"
                                                                            error={!!errors?.titleNumber}
                                                                            helperText={errors?.titleNumber?.message}
                                                                            disabled={true}
                                                                            style={{
                                                                                backgroundColor: 'floralwhite',
                                                                            }}
                                                                            InputProps={{sx: { height: 32 }}}
                                                                            // value={"図番"}
                                                                            
                                                                        />
                                                                    )}
                                                                />
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs ={0.2} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                    :
                                                </Grid>
                                                <Grid item xs={1.75}>
                                                    <Stack>
                                                            <Controller
                                                                    name="figNumber"
                                                                    control={control}
                                                                    rules={{
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <TextField 
                                                                            {...field}
                                                                            fullWidth
                                                                            type="text"
                                                                            size="small"
                                                                            error={!!errors?.figNumber}
                                                                            helperText={errors?.figNumber?.message}
                                                                            disabled={isDisableField ||modeViewOnly}
                                                                            style={{
                                                                                backgroundColor:isDisableField||modeViewOnly?'floralwhite': 'white',
                                                                            }}
                                                                            InputProps={{sx: { height: 32 }}}

                                                                        />
                                                                    )}
                                                                />
                                                    </Stack>
                                                </Grid>
                                                {/* <Grid item xs ={0.15} mt={2.5} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                    
                                                </Grid> */}
                                                <Grid item xs={1.15} ml={0.3}>                
                                                    <Stack>
                                                            <Controller
                                                                    name="designed"
                                                                    control={control}
                                                                    rules={{
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <TextField 
                                                                            {...field}
                                                                            fullWidth
                                                                            type="text"
                                                                            size="small"
                                                                            error={!!errors?.designed}
                                                                            helperText={errors?.designed?.message}
                                                                            disabled={true}
                                                                            style={{
                                                                                backgroundColor:'floralwhite',
                                                                            }}
                                                                            InputProps={{sx: { height: 32 }}}
                                                                            // value={"設計"}
                                                                        />
                                                                    )}
                                                                />
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs ={0.2} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                    :
                                                </Grid>
                                                <Grid item xs={1.75}>
                                                    <Stack>
                                                            <Controller
                                                                    name="designer"
                                                                    control={control}
                                                                    rules={{
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <TextField 
                                                                            {...field}
                                                                            fullWidth
                                                                            type="text"
                                                                            size="small"
                                                                            error={!!errors?.designer}
                                                                            helperText={errors?.designer?.message}
                                                                            disabled={true}
                                                                            style={{
                                                                                backgroundColor:'floralwhite',
                                                                            }}
                                                                            InputProps={{sx: { height: 32 }}}

                                                                        />
                                                                    )}
                                                                />
                                                    </Stack>
                                                </Grid>
                                                {/* <Grid item xs ={0.15} mt={2.5} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                    
                                                </Grid> */}
                                                <Grid item xs={1.15} ml={0.3}>
                                                    <Stack>
                                                            <Controller
                                                                    name="titleDateDesigned"
                                                                    control={control}
                                                                    rules={{
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <TextField 
                                                                            {...field}
                                                                            fullWidth
                                                                            type="text"
                                                                            size="small"
                                                                            error={!!errors?.titleDateDesigned}
                                                                            helperText={errors?.titleDateDesigned?.message}
                                                                            disabled={true}
                                                                            style={{
                                                                                backgroundColor:'floralwhite' ,
                                                                            }}
                                                                            InputProps={{sx: { height: 32 }}}
                                                                            // value={"日付"}


                                                                        />
                                                                    )}
                                                                />
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs ={0.2} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                    :
                                                </Grid>
                                                <Grid xs={1.42}>
                                                    <Stack >
                                                            <Controller
                                                                    name="dateDesigned"
                                                                    control={control}
                                                                    rules={{
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <TextField 
                                                                            {...field}
                                                                            fullWidth
                                                                            type="text"
                                                                            size="small"
                                                                            error={!!errors?.dateDesigned}
                                                                            helperText={errors?.dateDesigned?.message}
                                                                            disabled={true}
                                                                            style={{
                                                                                backgroundColor: 'floralwhite',
                                                                            }}
                                                                            InputProps={{sx: { height: 32 }}}

                                                                        />
                                                                    )}
                                                                />
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={2} ml={0.4}>
                                                    <Stack>
                                                            <Controller
                                                                    name="revision4"
                                                                    control={control}
                                                                    rules={{
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <TextField 
                                                                            {...field}
                                                                            fullWidth
                                                                            type="text"
                                                                            size="small"
                                                                            error={!!errors?.revision4}
                                                                            helperText={errors?.revision4?.message}
                                                                            disabled={true}
                                                                            style={{
                                                                                backgroundColor: 'floralwhite',
                                                                            }}
                                                                            InputProps={{sx: { height: 32 }}}

                                                                        />
                                                                    )}
                                                                />
                                                    </Stack>
                                                </Grid>
                                            </Grid>

                                            <Grid container direction={"row"} mt={1}>
                                                <Grid item xs={2}>
                                                    <Stack>
                                                            <Controller
                                                                    name="titleMode"
                                                                    control={control}
                                                                    rules={{
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <TextField 
                                                                            {...field}
                                                                            fullWidth
                                                                            type="text"
                                                                            size="small"
                                                                            error={!!errors?.titleMode}
                                                                            helperText={errors?.titleMode?.message}
                                                                            InputProps={{sx: { height: 32 }}}
                                                                            disabled={true}
                                                                            style={{
                                                                                backgroundColor: 'floralwhite',
                                                                            }}
                                                                            // value={"解析モード"}

                                                                        />
                                                                    )}
                                                                />
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs ={0.2} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                    
                                                </Grid>
                                                <Grid item xs={1.75}>
                                                    <Stack>
                                                            
                                                    </Stack>
                                                </Grid>
                                                {/* <Grid item xs ={0.15} mt={2.5} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                    
                                                </Grid> */}
                                                <Grid item xs={1.15} ml={0.3}>                
                                                    <Stack>
                                                            <Controller
                                                                    name="approved"
                                                                    control={control}
                                                                    rules={{
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <TextField 
                                                                            {...field}
                                                                            fullWidth
                                                                            type="text"
                                                                            size="small"
                                                                            error={!!errors?.approved}
                                                                            helperText={errors?.approved?.message}
                                                                            disabled={true}
                                                                            style={{
                                                                                backgroundColor: 'floralwhite' ,
                                                                            }}
                                                                            InputProps={{sx: { height: 32 }}}
                                                                            // value={"検認"}

                                                                        />
                                                                    )}
                                                                />
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs ={0.2} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                    :
                                                </Grid>
                                                <Grid item xs={1.75}>
                                                    <Stack>
                                                            <Controller
                                                                    name="approver"
                                                                    control={control}
                                                                    rules={{
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <TextField 
                                                                            {...field}
                                                                            fullWidth
                                                                            type="text"
                                                                            size="small"
                                                                            error={!!errors?.approver}
                                                                            helperText={errors?.approver?.message}
                                                                            disabled={true}
                                                                            style={{
                                                                                backgroundColor: 'floralwhite',
                                                                            }}
                                                                            InputProps={{sx: { height: 32 }}}

                                                                        />
                                                                    )}
                                                                />
                                                    </Stack>
                                                </Grid>
                                                {/* <Grid item xs ={0.15} mt={2.5} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                    
                                                </Grid> */}
                                                <Grid item xs={1.15} ml={0.3}>
                                                    <Stack>
                                                            <Controller
                                                                    name="titleDateApproved"
                                                                    control={control}
                                                                    rules={{
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <TextField 
                                                                            {...field}
                                                                            fullWidth
                                                                            type="text"
                                                                            size="small"
                                                                            error={!!errors?.titleDateApproved}
                                                                            helperText={errors?.titleDateApproved?.message}
                                                                            disabled={true}
                                                                            style={{
                                                                                backgroundColor:'floralwhite' ,
                                                                            }}
                                                                            InputProps={{sx: { height: 32 }}}
                                                                            // value={"日付"}

                                                                            
                                                                        />
                                                                    )}
                                                                />
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs ={0.2} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                    :
                                                </Grid>
                                                <Grid xs={1.42}>
                                                    <Stack >
                                                            <Controller
                                                                    name="dateApproved"
                                                                    control={control}
                                                                    rules={{
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <TextField 
                                                                        {...field}
                                                                        fullWidth
                                                                        type="text"
                                                                        size="small"
                                                                        error={!!errors?.dateApproved}
                                                                        helperText={errors?.dateApproved?.message}
                                                                        disabled={true}
                                                                        style={{
                                                                            backgroundColor:  'floralwhite',
                                                                        }}
                                                                        InputProps={{sx: { height: 32 }}}

                                                                        />
                                                                    )}
                                                                />
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={2} ml={0.4}>
                                                    <Stack>
                                                            <Controller
                                                                    name="revision5"
                                                                    control={control}
                                                                    rules={{
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <TextField 
                                                                            {...field}
                                                                            fullWidth
                                                                            type="text"
                                                                            size="small"
                                                                            error={!!errors?.revision5}
                                                                            helperText={errors?.revision5?.message}
                                                                            disabled={true}
                                                                            style={{
                                                                                backgroundColor: 'floralwhite',
                                                                            }}
                                                                            InputProps={{sx: { height: 32 }}}


                                                                        />
                                                                    )}
                                                                />
                                                    </Stack>
                                                </Grid>
                                            </Grid>

                                            <Grid container mt={1}>
                                                <Grid item xs={2}>
                                                    <Stack direction={'row'} >
                                                        <Controller
                                                            name="titleComment"
                                                            control={control}
                                                            render={({ field }: any) => (
                                                                <TextField
                                                                {...field}
                                                                type="text"
                                                                size="small"
                                                                // sx={{flex: 1}}
                                                                InputProps={{sx: { height: 32 }}}
                                                                disabled={true}
                                                                style={{
                                                                    backgroundColor: 'floralwhite',
                                                                }}
                                                                // value={"コメント"}

                                                                />
                                                            )}
                                                        />
                                                        </Stack>
                                                </Grid>
                                                <Grid item xs ={0.2} pl={0.5} alignItems={'center'} justifyContent={'center'}>
                                                    :
                                                </Grid>
                                                <Grid item xs ={9.75}>
                                                    <Stack>
                                                    <Controller
                                                    name="comments"
                                                    control={control}
                                                    
                                                    render={({ field }) => (
                                                        <TextareaAutosize
                                                                    {...field}
                                                                    
                                                                    maxRows={6}
                                                                    minRows={6}
                                                                    style={{overflowY:'scroll',resize:'none',fontFamily:'Roboto,Helvetica,Arial,sans-serif',fontSize:'16px'}}
                                                                    disabled={modeViewOnly}
                                                                    />
                                                                    )}
                                                                />
                                                </Stack>
                                            </Grid>
                                            {reportIndexEdit === 18 &&
                                                <Grid container mt={1} direction={'row'}>
                                                    <Grid container direction={'column'} item xs={2}>
                                                        <Grid >
                                                            <Stack >
                                                                <TextField
                                                                    type="text"
                                                                    size="small"
                                                                    // sx={{flex: 1}}
                                                                    InputProps={{sx: { height: 32 }}}
                                                                    disabled={true}
                                                                    style={{
                                                                        backgroundColor: 'floralwhite',
                                                                    }}
                                                                    // value={"メモ編集"}
                                                                />
                                                            </Stack>
                                                        </Grid>
                                                        <Grid>
                                                            <Stack mt={1}>
                                                                <Button  variant="outlined" onClick={handleDisplayFontDialog} disabled={modeViewOnly}>フォント</Button>
                                                            </Stack>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs ={0.2} pl={0.5} alignItems={'center'} justifyContent={'center'}>
                                                        :
                                                    </Grid>
                                                    <Grid item xs ={9.8}>
                                                            <Stack >
                                                                <Controller
                                                                name="editText"
                                                                control={control}
                                                                render={({ field }) => (
                                                                    <TextareaAutosize
                                                                                {...field}
                                                                                minRows={8}
                                                                                style={{overflowY:'scroll',resize:'none',fontFamily:fontFormatType.faceName
                                                                                ,fontSize:fontFormatType.fontSize,color:fontFormatType.colorFont,
                                                                                textDecoration:fontFormatType.underline && fontFormatType.strikeOut?'underline line-through'
                                                                                                :fontFormatType.underline?'underline' :fontFormatType.strikeOut?'line-through' :'',
                                                                                fontStyle:fontFormatType.italic?'italic':'',
                                                                                fontWeight:fontFormatType.weight,
                                                                            }}
                                                                            disabled={modeViewOnly}
                                                                                />
                                                                                )}
                                                                            />
                                                            </Stack>
                                                        </Grid>
                                                </Grid>
                                            }
                                        </Grid>
                                        
                                    </fieldset>
                                </Grid>
                            </div>
                            <Stack direction='row' justifyContent="flex-end" gap={2} mt={1} paddingBottom={1}>
                                <Button variant="contained" type="submit" disabled={modeViewOnly}>OK</Button>
                                <Button color="inherit" variant="contained" onClick={onCancel}>キャンセル</Button>
                            </Stack>
                        </form>
                        
                    </div>                    
                </DialogContent>
            </Dialog>
            {isDisplayFontDialog && 
                <FormatTextDialog modeViewOnly={false} data={fontFormatType} onOK={handleOKFormatTextForm} onCancel={() => setIsFontDialog(false)} />
            }
        </>
    )
}
export default SettingReportDialog;