import { LoadingButton } from "@mui/lab";
import { Box, Button, Checkbox, CircularProgress, FormControlLabel, Grid, Stack, Tab, Tabs, TextField, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BreakMagniModel, BreakOpeKindModel } from "../../../models/breakModel";
import { Path as RoutePath } from "../../../path";
import { ApplicationState, actionCreators, selectors } from "../../../store";
import FetchHelper from "../../FetchHelper";
//-----------
//FETCH ID
const GET_BREAK_MAGNI = "GET_BREAK_MAGNI"
const UPDATE_BREAK_MAGNI = "UPDATE_BREAK_MAGNI"
const CREATE_BREAK_MAGNI = "CREATE_BREAK_MAGNI"
const GET_BREAK_MAGNI_BY_ID = "GET_BREAK_MAGNI_BY_ID"
const initialValue: BreakMagniModel = {
    lBreakMagniID: null,
    dFixedValue: "",
    Mv3breakBreakOpeKind: {
        lOpeKindID: 0,
        sOpeKindName: "",
    } as BreakOpeKindModel,
    bIsDefault: false,
    sFixedName: "",
    dContiDefValue: "",
    dContiWidthValue: "",
    dContiMiniValue: "",
    dContiMaxValue: "",
}
// -------------
//Tabs------------------
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `oilManagement-tab-${index}`,
        'aria-controls': `oilManagement-tabpanel-${index}`,
    };
}
// -------------
// Props
export type BreakMagniEditProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
export const BreakMagniEdit = (props: BreakMagniEditProps) => {
    const {
        userId,
        fetchUpdate,
        onCreate, onCreateError,
        onUpdate, onUpdateError,
        onSuccess, onError,
    } = props;

    const { control, formState: { errors }, handleSubmit, setValue, getValues, } = useForm<BreakMagniModel>({
        mode: "all",
        criteriaMode: "all",
        defaultValues: initialValue,
    })
    type MyParams = {
        id: string;
        mode: string;
    };
    const messageErrorNumber = "数字を入力してください"
    let { id, mode } = useParams<keyof MyParams>() as MyParams;
    const location = useLocation();
    let lOpeKindID = location.state ? location.state.lBreakOpeKindID : undefined;
    let sOpeKindName = location.state ? location.state.sOpeKindName : "";
    let navigate = useNavigate();
    const [selectTab, setSelectTab] = useState(0);
    const [loadingFlag, setLoadingFlag] = useState(mode === "edit" ? true : false);
    const gridRef = useRef() as any;
    let tab = location.state && location.state.tab;

    const handleSuccess = (data: any) => {
        console.log('>>>> handle Successs');
        setValue("lBreakMagniID", data.data.lbreakMagniID);
        setValue("bIsDefault", data.data.bisDefault);
        setValue("dContiDefValue", data.data.dcontiDefValue);
        setValue("dContiMaxValue", data.data.dcontiMaxValue);
        setValue("dContiMiniValue", data.data.dcontiMiniValue);
        setValue("dContiWidthValue", data.data.dcontiWidthValue);
        setValue("sFixedName", data.data.sfixedName);
        setValue("dFixedValue", data.data.dfixedValue);
        setValue("Mv3breakBreakOpeKind.sOpeKindName", data.data.mv3breakBreakOpeKind.sopeKindName);
        setValue("Mv3breakBreakOpeKind.lOpeKindID", data.data.mv3breakBreakOpeKind.lopeKindID)
        if (tab !== undefined && tab !== null) {
            setSelectTab(tab)
        }
        setLoadingFlag(false);
    }
    useEffect(() => {
        if (mode === "add")
            setValue("Mv3breakBreakOpeKind.sOpeKindName", sOpeKindName);
    }, []);

    // 検索エラー時
    const handleError = (success: boolean, data: any, error: any) => {
        console.log('>>>> handle Error');
        gridRef.current.hideOverlay();
    };

    const tabHandleChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectTab(newValue);
        navigate(".", {
            replace: true,
            state: {
                tab: newValue,
                lBreakOpeKindID: lOpeKindID,
                sOpeKindName: sOpeKindName
            }
        });
    };

    // 検索実行
    const handleEditSubmit = (data: BreakMagniModel) => {
        setLoadingFlag(true);
        if (mode === "edit") {
            let request = {
                lBreakMagniID: getValues("lBreakMagniID"),
                bIsDefault: getValues("bIsDefault"),
                sFixedName: getValues("sFixedName"),
                dFixedValue: getValues("dFixedValue"),
                dContiDefValue: getValues("dContiDefValue"),
                dContiWidthValue: getValues("dContiWidthValue"),
                dContiMiniValue: getValues("dContiMiniValue"),
                dContiMaxValue: getValues("dContiMaxValue"),
            }
            const param = {
                "userId": userId,
                "requestPrms": request
              }
            onUpdate(id, param);
        } else if (mode === "add") {
            // let request = {
            //     lBreakMagniID: getValues("lBreakMagniID"),
            //     Mv3breakBreakOpeKind: {
            //         lOpeKindID: lOpeKindID
            //     } as BreakOpeKindModel,
            //     bIsDefault: getValues("bIsDefault"),
            //     sFixedName: getValues("sFixedName"),
            //     dFixedValue: getValues("dFixedValue"),
            //     dContiDefValue: getValues("dContiDefValue"),
            //     dContiWidthValue: getValues("dContiWidthValue"),
            //     dContiMiniValue: getValues("dContiMiniValue"),
            //     dContiMaxValue: getValues("dContiMaxValue"),
            // } as BreakMagniModel
            let request = {
                    lBreakMagniID: getValues("lBreakMagniID"),
                    lOpeKindID: lOpeKindID,
                    bIsDefault: getValues("bIsDefault"),
                    sFixedName: getValues("sFixedName"),
                    dFixedValue: getValues("dFixedValue"),
                    dContiDefValue: getValues("dContiDefValue"),
                    dContiWidthValue: getValues("dContiWidthValue"),
                    dContiMiniValue: getValues("dContiMiniValue"),
                    dContiMaxValue: getValues("dContiMaxValue"),
                } as any
            let object = {
                "userId": userId,
                "requestPrms": request
            }
            onCreate(object);
        }
    }

    // キャンセル
    const handleCancel = () => {
        if (location.key !== "default")
            navigate(-1);
        else
            navigate(RoutePath.BreakMagniList);
    }

    // Submit後のAPI呼び出し成功時
    const handleSuccessUpdate = (success: boolean, data: any, error: any) => {
        onSuccess();
        // if (lOpeKindID !== undefined && lOpeKindID !== null) {
        //     navigate(-1);
        // }
        if(mode==="edit"){
            navigate(RoutePath.BreakMagniEdit+`/edit/${data.data.lbreakMagniID}`,{replace:true})
        }
        setLoadingFlag(false)
    }
    // Submit後のAPI呼び出しエラー時
    const handleErrorUpdate = (success: boolean, data: any, error: any) => {
        onError();
        setLoadingFlag(false);
    }

    // Submit後のAPI呼び出し成功時
    const handleSubmitSuccess = (success: boolean, data: any, error: any) => {
        onSuccess();
        // if (lOpeKindID !== undefined && lOpeKindID !== null) {
        //     navigate(-1);
        // }
        if(mode==="add"){
            navigate(RoutePath.BreakMagniEdit+`/edit/${data.data.lbreakMagniID}`,{replace:true})
        }
        setLoadingFlag(false)
    }

    // Submit後のAPI呼び出しエラー時
    const handleSubmitError = (success: boolean, data: any, error: any) => {
        onError();
    }

    //入力欄背景
    const divStyles = {
        backgroundColor: 'white',
        border: '1px solid #bebebe',
        boxShadow: '0 0 5px #fff, 0 0 5px #bebebe, 0 0 1px #aaa',
        padding: '20px 40px',
        marginTop: '40px',
    }

    return (
        <>
            {
                mode === "edit" &&
                <FetchHelper
                    fetchId={GET_BREAK_MAGNI}
                    url={`/master/break_magni/get`}
                    method="POST"
                    request={true}
                    json
                    params={{
                        "userId": userId,
                        "requestPrms":{
                            id
                        }
                      }}
                    onComplete={(success, data, error) => {
                        success ? handleSuccess(data) : handleError(success, data, error);
                    }}
                />
            }
            {loadingFlag && (
                <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1500, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                    <CircularProgress />
                </div>
            )}
            <div style={{ height: "100%", width: "100%", marginTop: "10px" }}>
                <Stack direction="row" justifyContent="flex-start" alignItems="center" sx={{}} style={{ margin: '18px', color: 'MenuText' }}>
                    <Typography variant="h4">
                    定格倍率詳細
                    </Typography>
                </Stack>
                <form onSubmit={handleSubmit(handleEditSubmit)}>
                    <Stack direction='row'>
                        <div style={{ top: "100px", position: "absolute", zIndex: 1200 }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={selectTab} onChange={tabHandleChange} aria-label="oilManagementDetailTabs">
                                    <Tab label='定格倍率' {...a11yProps(0)} />
                                </Tabs>
                            </Box>
                        </div>
                    </Stack>
                    {/* 時間倍率 */}
                    <TabPanel value={selectTab} index={0}>
                        <div style={divStyles}>
                            <Typography variant="h6">
                            定格倍率
                            </Typography>
                        <Stack sx={{maxHeight:"calc(100vh)", overflow: 'auto' }}>
                            <Stack direction={{ xs: 'column', sm: 'row' }} mt={3}>
                                <Grid container spacing={3} direction={'row'}>
                                    <Grid item xs={12} mt={1}>
                                        <Controller
                                            control={control}
                                            name="Mv3breakBreakOpeKind.sOpeKindName"
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    label="動作種類名"
                                                    type="text"
                                                    size="small"
                                                    disabled={true}
                                        
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Controller
                                            control={control}
                                            name="sFixedName"
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    type="text"
                                                    label="固定可変名称"
                                                    size="small"
                                                    
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            control={control}
                                            name="dFixedValue"
                                            rules={{
                                                pattern: {
                                                    value: /^-?\d+(?:\.\d+)?$/,
                                                    message: messageErrorNumber
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.dFixedValue?.message}
                                                    helperText={errors?.dFixedValue?.message}
                                                    type="text"
                                                    size="small"
                                                    label="固定可変値"
                                                   
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            control={control}
                                            name="dContiDefValue"
                                            rules={{
                                                pattern: {
                                                    value: /^-?\d+(?:\.\d+)?$/,
                                                    message: messageErrorNumber
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.dContiDefValue?.message}
                                                    helperText={errors?.dContiDefValue?.message}
                                                    type="text"
                                                    size="small"
                                                    label="連続可変デフォルト値"
                                                   
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            control={control}
                                            name="dContiWidthValue"
                                            rules={{
                                                pattern: {
                                                    value: /^-?\d+(?:\.\d+)?$/,
                                                    message: messageErrorNumber
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.dContiWidthValue?.message}
                                                    helperText={errors?.dContiWidthValue?.message}
                                                    type="text"
                                                    label="連続可変幅"
                                                    size="small"
                                                    

                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            control={control}
                                            name="dContiMiniValue"
                                            rules={{
                                                pattern: {
                                                    value: /^-?\d+(?:\.\d+)?$/,
                                                    message: messageErrorNumber
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.dContiMiniValue?.message}
                                                    helperText={errors?.dContiMiniValue?.message}
                                                    type="text"
                                                    label="連続可変最小値"
                                                    size="small"
                                                    

                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            control={control}
                                            name="dContiMaxValue"
                                            rules={{
                                                pattern: {
                                                    value: /^-?\d+(?:\.\d+)?$/,
                                                    message: messageErrorNumber
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors?.dContiMaxValue?.message}
                                                    helperText={errors?.dContiMaxValue?.message}
                                                    type="text"
                                                    label="連続可変最大値"
                                                    size="small"
                                                    
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Controller
                                            name="bIsDefault"
                                            control={control}
                                            render={({ field }: any) => (
                                                <FormControlLabel {...field} control={<Checkbox />} checked={field.value} label="デフォルト" labelPlacement="start" />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Stack>
                        </Stack>
                        </div>
                    </TabPanel>
                    <Stack direction='row' justifyContent="flex-end" spacing={2} sx={{ mt: 0, mr: 3 }}>
                        <LoadingButton variant="contained" type="submit" loading={loadingFlag}>保存</LoadingButton>
                        <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                    </Stack>
                </form>
            </div>
            <FetchHelper fetchId={UPDATE_BREAK_MAGNI}
                onComplete={(success: boolean, data: any, error: any) =>
                    success ? handleSuccessUpdate(success, data, error) : handleErrorUpdate(success, data, error)} />

            <FetchHelper fetchId={CREATE_BREAK_MAGNI}
                onComplete={(success, data, error) => success ?
                    handleSubmitSuccess(success, data, error) :
                    handleSubmitError(success, data, error)} />
        </>
    );
}
const mapStateToProps = (state: ApplicationState) => (
    {
        userId: state.app.user?.userId,
        fetchUpdate: selectors.getFormState(
            state,
            GET_BREAK_MAGNI
        ),
    }
)
const mapDispatchToProps = (dispatch: any) => {
    return {
        onCreate: (params: any) => dispatch(actionCreators.fetch(CREATE_BREAK_MAGNI, `/master/break_magni/add`, "POST", params,false,true)),
        onUpdate: (id: string, param: any) => dispatch(actionCreators.fetch(UPDATE_BREAK_MAGNI, `/master/break_magni/modify`, "POST", param, false, true)),
        onSearchById: (param: any) => dispatch(actionCreators.fetch(GET_BREAK_MAGNI_BY_ID, `/master/break_magni/get`, "POST",param, false,true)),
        onSuccess: () =>
            dispatch(actionCreators.showMessage({
                type: "info",
                title: "時間倍率更新",
                body: "時間倍率を更新しました。",
            })),
        onError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "検索",
                body: "検索できません。",
            })),
        onCreateError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "時間倍率登録",
                body: "時間倍率を登録出来ませんでした。",
            })),
        onUpdateError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "時間倍率編集",
                body: "時間倍率を更新出来ませんでした。",
            })),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(BreakMagniEdit as any);