import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import { DialogTitle, Stack, TextField, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import DraggablePaper from '../common/DraggablePaper'
import theme from '../../themes/globalStyles'

export type AddFileDXFDiaglogProps = {
  openDialog: boolean;
  defaultFilename: string;
  setOpenDialog: (value: boolean) => void;
  onOK: (code: string) => void;
}

export const AddFileDXFDiaglog = (props: AddFileDXFDiaglogProps) => {
  const { openDialog, defaultFilename, setOpenDialog, onOK } = props
  const [fileName, setFileName] = useState(defaultFilename)
  const [errMsg, setErrMsg] = useState("")

  useEffect(() => {
    setFileName(defaultFilename)
  }, [defaultFilename])

  const handleOK = () => {
    if (fileName.length > 0){
      onOK(fileName)
      setOpenDialog(false)
      setFileName(defaultFilename)
      setErrMsg("")
    } else {
      setErrMsg("必須項目です。入力してください。")
    }
  }
  const handleCancel = () => {
    setOpenDialog(false)
    setFileName(defaultFilename)
    setErrMsg("")
  }

  const handleChangeText = (e: any) => { setFileName(e.target.value) }

  return (
    <div>
      <Dialog
        open={openDialog}
        maxWidth={"xs"}
        fullWidth
        PaperComponent={DraggablePaper}
      >
        <DialogTitle
          onMouseOver={(e : any) => e.target.style.cursor = 'move'}
          style={{
            paddingTop: '2px',
            paddingBottom: '2px',
            background: theme.palette.primary.main,
            color: theme.palette.primary.contrastText
          }}
        >
          DXFファイル作成
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Stack mt={3}>
              <TextField
                value={fileName}
                onChange={handleChangeText}
                fullWidth
                type="text"
                size="small"
                label={<Typography variant='body2'>ファイル名</Typography>}
              />
            </Stack>
            {errMsg.length > 0 &&
              <Stack mt={1}>
                <Typography sx={{color: 'red'}}>{errMsg}</Typography>
              </Stack>
            }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Stack direction='row' width={'100%'} spacing={1} px={2} mb={1}>
            <Button
              onTouchEnd={handleCancel}
              onClick={handleCancel}
              color="inherit"
              variant="contained"
              fullWidth
            >
              キャンセル
            </Button>
            <Button
              onTouchEnd={handleOK}
              onClick={handleOK}
              variant="contained"
              fullWidth
            >
              OK
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default AddFileDXFDiaglog