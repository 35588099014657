import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Button, Stack } from "@mui/material";
import React from 'react';
import { OptionItem, PageData } from '../../models/Index';
import TextSelector from './TextSelector';

// -------------

// ページサイズ
export const PageSizeList = [
    { value: '10', label: '10', attribute: '', },
    { value: '50', label: '50', attribute: '', },
    { value: '100', label: '100', attribute: '', },
    { value: '500', label: '500', attribute: '', },
    { value: '1000', label: '1000', attribute: '', },
    // { value: '5000', label: '5000', attribute: '', },
    // { value: '10000', label: '10000', attribute: '', },
] as OptionItem[];

// Props
export type PaginationCpnProps = {
    pageData: PageData;
    onChangeSearchCondition: (name: string, value: any) => void;
    limit: number;
    enabledExportCsv?: boolean;
    exportCsv?: any;
};

// -------------
// Component
export const PaginationCpn = (props: PaginationCpnProps) => {

    const { pageData, onChangeSearchCondition, limit, exportCsv, enabledExportCsv } = props;

    const onBtnPre = () => { onChangeSearchCondition('page', Number.parseInt(pageData.currPage.replace(',', '')) - 1); };
    const onBtnNext = () => { onChangeSearchCondition('page', Number.parseInt(pageData.currPage.replace(',', '')) + 1); };
    const [pageSize, setPageSize] = React.useState(limit);

    const pStyle1 = {
        padding: '0 4px',
        fontWeight: 'bold',
    }

    const handlePageSizeChange: React.ChangeEventHandler<any> = (e) => {
        setPageSize(e.target.value)
        onChangeSearchCondition('limit', e.target.value)
    };

    const handleExportCsv = () => {
        let fileName = "";
        if(exportCsv.mainid){
            fileName = `${new Date()
                .toLocaleString('en-us', { year: 'numeric', month: '2-digit', day: '2-digit' })
                .replace(/(\d+)\/(\d+)\/(\d+)/, '$3-$1-$2')}_${exportCsv.name}_定期通報_${exportCsv.mainid}`
        }else{
            fileName = `${new Date()
                .toLocaleString('en-us', { year: 'numeric', month: '2-digit', day: '2-digit' })
                .replace(/(\d+)\/(\d+)\/(\d+)/, '$3-$1-$2')}_${exportCsv.name}`
        }

        const params = {
            fileName: fileName,
            columnKeys: exportCsv.columns,
        };
        exportCsv.gridApi.exportDataAsCsv(params);
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'flex-end', fontSize: '13px', backgroundColor: '#ffffff', height: '52px', borderColor: 'rgb(186, 191, 199)', borderStyle: 'solid', borderWidth: '0px 0.5px 0.5px' }}>
            {enabledExportCsv && <Button variant="outlined" onClick={handleExportCsv} size="small" style={{ margin: 'auto 10px auto 0' }}>CSV</Button>}&nbsp;
            <div style={{ display: 'flex', margin: 'auto 0' }}>
                <p style={{ marginRight: '10px' }}>ページあたり</p>
                <Stack style={{ minWidth: '80px', margin: 'auto 10px auto 0' }}>
                    <TextSelector
                        name="limit"
                        value={pageSize.toString()}
                        label=""
                        options={PageSizeList}
                        onChange={handlePageSizeChange}
                    />
                </Stack>
                <p style={{ marginRight: '40px' }}>行表示</p>
            </div>
            <div style={{ display: 'flex', margin: 'auto 0' }}>
                <p id='totalRow' style={pStyle1}>{pageData.totalRow.toLocaleString()}</p>
                <p style={{ marginRight: '10px' }}>行中</p>
                <p id='rowFrom' style={pStyle1}>{pageData.totalRow !== "0" ? pageData.rowFrom.toLocaleString() : "0"}</p>
                <p>-</p>
                <p id='rowTo' style={pStyle1}>{pageData.rowTo.toLocaleString()}</p>
                <p>行</p>
            </div>
            <div style={{ display: 'flex', margin: 'auto 0' }}>
                <Button id='btnPrePage' onClick={onBtnPre} disabled={pageData.currPage === "1" || pageData.currPage === "0"}><NavigateBeforeIcon fontSize='small' /></Button>
                <Button id='btnNextPage' onClick={onBtnNext} disabled={pageData.currPage === pageData.totalPage || pageData.totalPage === "0"}><NavigateNextIcon fontSize='small' /></Button>
            </div>
        </div>
    );
};

export default PaginationCpn;
