import { store } from "..";
import { ControlModel, MsFUSEModel, ProcessMode } from "../models/Index";
import { BeamsNumber } from "./FormatNumber";
import { MS_3PHASES, MS_VOLTDROP_REGULAR, MS_TRANS1, MS_VOLTDROP_MOTOR, TOP_CENTER, BOTTOM_CENTER, LEFT_CENTER, RIGHT_CENTER, DIRECT_NONE, RS_DIAL_FIXED, RS_DIAL_CONTINUE } from "../models/Constants"
import { doGetValueDropVoltRegular as doGetValueDropVoltRegularCalcPoint,
	doGetIkpeakDirectMG,
	doGetIkasymDirectMG,
	doGetIksymDirectMG,
    doGetIksymTotalMG,
    doGetTextDropVoltMotor as doGetTextDropVoltMotorCalPoint,
    doGetTextDropVoltMotor2 as DoGetTextDropVoltMotor2CalcPoint,
    doGetTextDropPerMotor as doGetTextDropPerMotorCalcPoint,
	doGetTextDropVoltRegular as doGetTextDropVoltRegularCalcPoint,
	doGetTextDropPerRegular as doGetTextDropPerRegularCalcPoint,
	doGetTextDropPerRegular2 as doGetTextDropPerRegular2CalcPoint,
    isSetDropPerMotor,
    doGetPerZDirectMG,
    doGetIkpeakTotalMG,
    doGetIkasymTotalMG,
    doGetPerZTotalMG
} from "./calcPointFunction";
import { doGetTextVoltDropRegular } from "./mssv3Doc"
import { isCennectLoadDropElement } from "./element"
import { MS_TRANS1 as  MS_TRANS1_STRING} from "../models/ElementKind"
import { isOkSetCurveInfo } from "./infoRead";
import { MS_MODE_CALCULATION, MS_MODE_CHECKING, MS_MODE_DRAWING, MS_MODE_HARMONIC, MS_MODE_PERCENT_Z, MS_MODE_POWER_FLOW, MS_MODE_VOLTDROP } from "../statics";
import { doGetTextAdjust } from "./Adjust";

//CEleFuse::DoGetTextTypeName
export const doGetTextTypeName = (props:MsFUSEModel) =>
{
	if (!isOkSetCurveInfo(props.details)) {
		return '';
	}
	return props.details.base.typeName;
}

//TODO
//CEleFuse::DoGetTextRatedName
export const doGetTextRatedName = (props:MsFUSEModel, bUnit:boolean = true) =>
{
	// CObjAdjust* pObjAdjust = m_listAdjust.DoGetPointerAdjust(0);
	// if (pObjAdjust == NULL) {
	// 	return _T("");
	// }

	// CMSSV3App* pMSSV3App = (CMSSV3App*)AfxGetApp();
	// CDaoDatabase* pdbMSSBreak = pMSSV3App->m_pdbMSSBreak;
	// pObjAdjust->m_ReadBase.DoReadReadBase(pdbMSSBreak, &pObjAdjust->m_InfoBase, TRUE);

	// int nIndex = 0;
	// if (pObjAdjust->m_ReadBase.m_DialRated.m_nFlag == RS_DIAL_FIXED) {
	// 	nIndex = pObjAdjust->m_ReadBase.m_DialRated.DoGetIndexByDialID(pObjAdjust->m_InfoBase.m_lBreakRatedID);
	// }
	// else {
	// 	nIndex = pObjAdjust->m_ReadBase.m_DialRated.DoGetIndexByValue(pObjAdjust->m_InfoBase.m_dRatedValue);
	// }
	// if (nIndex < 0) {
	// 	return _T("");
	// }
	// CString sText = pObjAdjust->m_ReadBase.m_DialRated.DoGetValueByIndex(nIndex)->m_sValueName;
	// if (bUnit == TRUE && pObjAdjust->m_ReadBase.m_DialRated.m_sUnit.IsEmpty() == 0) {
	// 	sText += pObjAdjust->m_ReadBase.m_DialRated.m_sUnit;
	// }
    if (!props.details.base.fixedName) {
		return '';
	}

	let sText = props.details.base.fixedName;

    sText += props.details.base.readBase.ratedUnit === undefined? 'A' : props.details.base.readBase.ratedUnit;
    
	return sText;
}

//CEleFuse::DoGetTextOnOff
export const doGetTextOnOff = (props:MsFUSEModel, bUseOn:boolean = true) =>
{
	let sText;
	if (props.isOff) {
		sText = '開放';
	}
	else {
		if (bUseOn) {
			sText = '投入';
		}
		else {
			sText = '';
		}
	}
	return sText;
}

//CEleFuse::DoGetTextPointState
export const doGetTextPointState = (props:MsFUSEModel) =>
{
	let sText = ''; 

	if (props.fault) {
		sText = 'Yes';
	}
	else {
		sText = 'No';
	}

	return sText;
}

//CEleFuse::IsPointFaultFuse
export const isPointFaultFuse = (props:MsFUSEModel) =>
{
	if (props.fault) {
		return true;
	}
	return false;
}

//CEleFuse::DoGetTextDirectIk3peak
export const doGetTextDirectIk3peak = (props:MsFUSEModel, bUnit:boolean = true) =>
{
	let dIpeakDirect = 0;
    if (props.downTransKind == MS_TRANS1_STRING) {
		dIpeakDirect = doGetIkpeakDirectMG(props.calcPoint1);
	}
	else {
		dIpeakDirect = doGetIkpeakDirectMG(props.calcPoint0);
	}
	let sText;
	if (dIpeakDirect == 0) {
		if (props.primarySupply) {
			sText = '∞';
		}
		else {
			sText = '0';
		}
	}
	else if (dIpeakDirect > 0) {
		sText = BeamsNumber(dIpeakDirect / 1000, 4).toString();
        if(bUnit)
        {
            sText += 'kApeak';
        }
	}
	else {							
        sText = '????';
	}
	return sText;
}

//CEleFuse::DoGetTextDirectIk3asym
export const doGetTextDirectIk3asym = (props:MsFUSEModel, bUnit:boolean = true) =>
{
	let dIasymDirect = 0;
	if (props.downTransKind == MS_TRANS1_STRING) {
		dIasymDirect = doGetIkasymDirectMG(props.calcPoint1);
	}
	else {
		dIasymDirect = doGetIkasymDirectMG(props.calcPoint0);
	}
	let sText;
	if (dIasymDirect == 0) {
		if (props.primarySupply) {
			sText = '∞';
       }
       else {
        sText = '0';
       }
	}
    else if(dIasymDirect > 0) {
        sText = BeamsNumber(dIasymDirect / 1000, 4).toString();
        if(bUnit) {
            sText += 'kAasym';
        }
    }
    else {
        sText = '????';
    }
	return sText;
}

//CEleFuse::DoGetTextDirectIk3sym
export const doGetTextDirectIk3sym = (props:MsFUSEModel, bUnit:boolean = true) =>
{
	let dIsymDirect = 0;
	if (props.downTransKind == MS_TRANS1_STRING) {
		dIsymDirect = doGetIksymDirectMG(props.calcPoint1);
	}
	else {
		dIsymDirect = doGetIksymDirectMG(props.calcPoint0);
	}
	let sText;
	if (dIsymDirect == 0) {
		if(props.primarySupply) {
            sText = '∞';
        }
        else {
         sText = '0';
        }
    }
    else if(dIsymDirect > 0) {
        sText = BeamsNumber(dIsymDirect / 1000, 4).toString();
        if(bUnit) {
            sText += 'kAsym';
        }
    }
    else {
        sText = '????';
    }
	return sText;
}

// CEleFuse::DoGetTextTotalIk3sym
export const doGetTextTotalIk3sym = (props:MsFUSEModel, bUnit:boolean = true) =>
{
	let dIsymTotal = 0;
	if (props.downTransKind == MS_TRANS1_STRING) {
		dIsymTotal = doGetIksymTotalMG(props.calcPoint1);
	}
	else {
		dIsymTotal = doGetIksymTotalMG(props.calcPoint0);
	}
	let sText;
	if (dIsymTotal == 0) {
		if (props.primarySupply) {
            sText = '∞';
        }
        else {
         sText = '0';
        }
    }
    else if(dIsymTotal > 0) {
        sText = BeamsNumber(dIsymTotal / 1000, 4).toString();
        if(bUnit) {
            sText += 'kAsym';
        }
    }
    else {
        sText = '????';
    }
	return sText;
}

//CEleFuse::DoGetTextDropVoltMotor
export const doGetTextDropVoltMotor = (props:MsFUSEModel, bUnit:boolean = true) =>
{
	return doGetTextDropVoltMotorCalPoint(props.calcPoint0, bUnit);
}

//CEleFuse::DoGetTextDropVoltMotor2
export const doGetTextDropVoltMotor2 = (props:MsFUSEModel, bUnit:boolean = true) =>
{
	return DoGetTextDropVoltMotor2CalcPoint(props, bUnit);
}

//CEleFuse::DoGetTextDropPerMotor
export const doGetTextDropPerMotor = (props:MsFUSEModel, bUnit:boolean = true) =>
{
	return doGetTextDropPerMotorCalcPoint(props.calcPoint0, bUnit);
}

//CEleFuse::DoGetTextDropVoltRegular
export const doGetTextDropVoltRegular = (props:MsFUSEModel, dStdVoltage:number, bUnit:boolean = true) =>
{
	return doGetTextDropVoltRegularCalcPoint(props.calcPoint0, dStdVoltage, 0, 0, bUnit);
}

//CEleFuse::DoGetTextDropPerRegular
export const doGetTextDropPerRegular = (props:MsFUSEModel, dStdVoltage:number, bUnit:boolean = true) =>
{
	return doGetTextDropPerRegularCalcPoint(props.calcPoint0, dStdVoltage, 0, 0, bUnit);
}

//CEleFuse::DoGetTextVoltage
export const doGetTextVoltage = (props:MsFUSEModel, bUnit:boolean = true) =>
{
	let sText = props.voltage.toString();
	if (bUnit) {
		sText += 'V';
	}
	return sText;
}

//CEleFuse::DoGetRefV1
export const doGetRefV1 = (mode:any, control:ControlModel, bSociety:boolean) =>
{
    let props = control.properties as MsFUSEModel;
    let project = store.getState().app.projectData;

	//CEleFuse::DoGetRefV1Left  sRefV1Left
	let labelTexts = [{
		value: control.properties.refNo,
		color: "#f800f8",
	}];

	if (!props.viewResultText || (mode != ProcessMode.DRAWING && !props.supply))
    {
      return labelTexts;
    }

    if (bSociety) {
        //TypeName
        labelTexts.push({
            value: doGetTextTypeName(props),
            color: "blue",
        });

        //typename
        labelTexts.push({
            value: doGetTextRatedName(props),
            color: "blue",
        });

        return labelTexts;
    }

	switch(mode) {
        case ProcessMode.DRAWING:
        case ProcessMode.CHECKING:
            if(props.isOff) {
                //OnOff
                labelTexts.push({
                   value: doGetTextOnOff(props, false),
                   color: "blue",
               });
            }

            //TypeName
            labelTexts.push({
                value: doGetTextTypeName(props),
                color: "blue",
            });

            //typename
            labelTexts.push({
                value: doGetTextRatedName(props),
                color: "blue",
            });

            break;
        case ProcessMode.PERCENT_Z:
            break;
        case ProcessMode.CALCULATION:
            if(props.isOff) {
                //OnOff
                labelTexts.push({
                   value: doGetTextOnOff(props, false),
                   color: "blue",
               });
                
               break;
            }

            if (!isPointFaultFuse(props)) {
                break;
            } 
            if(props.downTransKind == MS_TRANS1_STRING) {
                // += 'I"k2' + '通過' + '=' + doGetTextDirectIk3peak
                labelTexts.push({
                    value: 'I"k2通過='
                    + doGetTextDirectIk3peak(props),
                    color: "blue",
                });

                // += 'I"k2' + '通過' + '=' + doGetTextDirectIk3asym
                labelTexts.push({
                    value: 'I"k2通過='
                    + doGetTextDirectIk3asym(props),
                    color: "blue",
                });

                // += 'I"k2' + '通過' + '=' + doGetTextDirectIk3sym
                labelTexts.push({
                    value: 'I"k2通過='
                    + doGetTextDirectIk3sym(props),
                    color: "blue",
                });

                // += 'I"k2' + '合計' + '=' + doGetTextTotalIk3sym
                labelTexts.push({
                    value: 'I"k2合計='
                    + doGetTextTotalIk3sym(props),
                    color: "blue",
                });
            }
            else {
                // += 'I"k3' + '通過' + '=' + doGetTextDirectIk3peak
                labelTexts.push({
                    value: 'I"k3通過='
                    + doGetTextDirectIk3peak(props),
                    color: "blue",
                });

                // += 'I"k3' + '通過' + '=' + doGetTextDirectIk3asym
                labelTexts.push({
                    value: 'I"k3通過='
                    + doGetTextDirectIk3asym(props),
                    color: "blue",
                });

                // += 'I"k3' + '通過' + '=' + doGetTextDirectIk3sym
                labelTexts.push({
                    value: 'I"k3通過='
                    + doGetTextDirectIk3sym(props),
                    color: "blue",
                });

                // += 'I"k3' + '合計' + '=' + doGetTextTotalIk3sym
                labelTexts.push({
                    value: 'I"k3合計='
                    + doGetTextTotalIk3sym(props),
                    color: "blue",
                });
            }
            break;
        case ProcessMode.VOLTDROP:
            if(props.isOff) {
                //OnOff
                labelTexts.push({
                   value: doGetTextOnOff(props, false),
                   color: "blue",
               });

               break;
            }

            if (project.dropMode == MS_VOLTDROP_MOTOR && props.dropMotor && isSetDropPerMotor(props.calcPoint0)) {
                // Vd = DropVoltMotor(VoltSecondary - DropVoltMotor2)
                let sTemp = 'Vd=' + doGetTextDropVoltMotor(props) + ' (';
                if (props.supply ) {
                    sTemp += doGetTextVoltage(props, false);
                    sTemp += '-';
                    sTemp += doGetTextDropVoltMotor2(props, false);
                } else {
                    sTemp += '0-0'
                }
                sTemp += ')';
                labelTexts.push({
                    value: sTemp,
                    color: "blue",
                });

                //Vp = DoGetTextDropPerMotor (VoltSecondary → DropVoltMotor2)
                sTemp = 'Vp=' + doGetTextDropPerMotor(props) + ' (';
                if (props.supply) {
                    sTemp += doGetTextVoltage(props, false);
                    sTemp += '→';
                    sTemp += doGetTextDropVoltMotor2(props, false);
                } else {
                    sTemp += '0→0';
                }
                sTemp += ')';
                labelTexts.push({
                    value: sTemp,
                    color: "blue",
                });
                break;
            }

            if (isCennectLoadDropElement(control, TOP_CENTER | BOTTOM_CENTER | LEFT_CENTER | RIGHT_CENTER) && project.dropMode == MS_VOLTDROP_REGULAR && props.dropRegular) {
                let stdVoltage = {
                    dStdVoltage : 0,
                    sStdVoltage : ''
                };
                if(props.supply) {
                    stdVoltage = doGetTextVoltDropRegular(control, MS_3PHASES, false);
                }

                //Vp=doGetTextDropPerRegular (doGetTextVoltage→doGetTextDropVoltRegular)
                let sTemp = 'Vp=' + doGetTextDropPerRegular(props, stdVoltage.dStdVoltage);
                sTemp += ' (';
                if(props.supply) {
                    sTemp += doGetTextVoltage(props, false);
                }
                else {
                    sTemp += stdVoltage.sStdVoltage;
                }
                sTemp += '→';
                sTemp += doGetTextDropVoltRegular(props, stdVoltage.dStdVoltage, false);
                sTemp += ')';
                labelTexts.push({
                    value: sTemp,
                    color: "blue",
                });
            
                break;
            }
            break;  
        case ProcessMode.IMPROVE_PF:
        case ProcessMode.HARMONIC:
        case ProcessMode.POWER_FLOW:
            if(props.isOff) {
                //OnOff
                labelTexts.push({
                   value: doGetTextOnOff(props, false),
                   color: "blue",
               });
            }
            break;
    }
     
    return labelTexts;
}

export const doGetRefV2 = (properties : MsFUSEModel) => {
	let infos: string[] = []
	infos.push(properties.details.refNo2)
	infos.push(properties.pointText)

	const typeName = properties.details.base.typeList.find(item => item.value === properties.details.base.typeID)?.label || ''
	infos.push(typeName)

	if (properties.details.drawThreeCurve === false) {
		const charName = properties.details?.parts[0]?.charDialList.find(item => item.value ===  properties.details.parts[0].charDialID)?.label || ''
		infos.push(charName)
	}

	const ratedValue = properties.details.base.ratedList?.find(
		(item) => item.value === properties.details.base.ratedID
	  )?.label || properties.details.base.fixedName || ""
	if (!ratedValue) return infos

	infos.push(ratedValue.toString())
	if (properties.details.base.readBase.ratedUnit) {
		infos[infos.length - 1] +=  `(${properties.details.base.readBase.ratedUnit})`
	}

	if (properties.details.base.readBase.magniFlag === RS_DIAL_FIXED || properties.details.base.readBase.magniFlag === RS_DIAL_CONTINUE) {
		infos[infos.length - 1] +=  properties.details.base.magniValue
		if (properties.details.base.readBase.magniUnit) {
			infos[infos.length - 1] += `(${properties.details.base.readBase.magniUnit})`
		}
	}

	infos = infos.concat(doGetTextAdjust(properties.details))
	return infos
}

//CEleFuse::DoGetTextDirectPercentZr
export const doGetTextDirectPercentZr = (props:ControlModel, bUnit:boolean = true) =>
{
	let dPerZ = 0;
	if (props.properties.downTransKind == MS_TRANS1) {
		dPerZ = doGetPerZDirectMG(props.properties.calcPoint1).dPercentZ;
	}
	else {
		dPerZ = doGetPerZDirectMG(props.properties.calcPoint0).dPercentZ;
	}
	let sText = '';
	if (dPerZ >= 0) {
		sText = BeamsNumber(dPerZ, 4).toString();
		if (bUnit) {
			sText += '%r';
		}
	}
	else {							// 短絡電流が正しく計算されなかったとき
		sText = '????';
	}
	return sText;
}

//CEleFuse::DoGetTextTotalIk3peak
export const doGetTextTotalIk3peak = (props:MsFUSEModel, bUnit:boolean = true) =>
{
	let dIpeakTotal = 0;
	if (props.downTransKind == MS_TRANS1_STRING) {
		dIpeakTotal = doGetIkpeakTotalMG(props.calcPoint1);
	}
	else {
		dIpeakTotal = doGetIkpeakTotalMG(props.calcPoint0);
	}
	let sText = '';
	if (dIpeakTotal == 0) {
		if (props.primarySupply) {
			sText = '∞';
		}
		else {
			sText = "0";
		}
	}
	else if (dIpeakTotal > 0) {
		sText = BeamsNumber(dIpeakTotal / 1000, 4).toString();
		if (bUnit) {
			sText += 'kApeak';
		}
	}
	else {							// 短絡電流が正しく計算されなかったとき
		sText = '????';
	}
	return sText;
}

//CEleFuse::DoGetTextTotalIk3asym
export const doGetTextTotalIk3asym = (props:MsFUSEModel, bUnit:boolean = true) =>
{
	let dIasymTotal = 0;
	if (props.downTransKind == MS_TRANS1_STRING) {
		dIasymTotal = doGetIkasymTotalMG(props.calcPoint1);
	}
	else {
		dIasymTotal = doGetIkasymTotalMG(props.calcPoint0);
	}
	let sText = '';
	if (dIasymTotal == 0) {
		if (props.primarySupply) {
			sText = '∞';
		}
		else {
			sText = "0";
		}
	}
	else if (dIasymTotal > 0) {
		sText = BeamsNumber(dIasymTotal / 1000, 4).toString();
		if (bUnit) {
			sText += 'kAasym';
		}
	}
	else {							// 短絡電流が正しく計算されなかったとき
		sText = '????';
	}
	return sText;
}

//CEleFuse::DoGetTextTotalPercentZr
export const doGetTextTotalPercentZr = (props:MsFUSEModel, bUnit:boolean = true) =>
{
	let dPerZ = 0;
	if (props.downTransKind == MS_TRANS1_STRING) {
		dPerZ = doGetPerZTotalMG(props.calcPoint1).dPercentZ;
	}
	else {
		dPerZ = doGetPerZTotalMG(props.calcPoint0).dPercentZ;
	}
	let sText = '';
	if (dPerZ >= 0) {
		sText = BeamsNumber(dPerZ, 4).toString();
		if (bUnit) {
			sText += '%r';
		}
	}
	else {							// 短絡電流が正しく計算されなかったとき
		sText = '????';
	}
	return sText;
}

//CEleFuse::DoGetTextActiveLeft
export const doGetTextActiveLeft = (props:MsFUSEModel, nDirect:number, bUnit:boolean = true) =>
{
	let sText = '';
	if (nDirect == DIRECT_NONE) {
		if (props.lineActiveFlow1 >= 0) {
			sText = BeamsNumber(props.lineActiveFlow1, 5).toString();
		}
		else {
			sText = BeamsNumber(props.lineActiveFlow2, 5).toString();
		}
	}
	else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
		sText = BeamsNumber(Math.abs(props.lineActiveFlow1), 5).toString();
	}
	else {
		sText = BeamsNumber(Math.abs(props.lineActiveFlow2), 5).toString();
	}

	if (bUnit) {
		sText += 'kW';
	}

	return sText;
}

//CEleFuse::DoGetTextReactiveLeft
export const doGetTextReactiveLeft = (props:MsFUSEModel, nDirect:number, bUnit:boolean = true) =>
{
	let sText = '';
	if (nDirect == DIRECT_NONE) {
		if (props.lineReactiveFlow1 >= 0) {
			sText = BeamsNumber(props.lineReactiveFlow1, 5).toString();
		}
		else {
			sText = BeamsNumber(props.lineReactiveFlow2, 5).toString();
		}
	}
	else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
		sText = BeamsNumber(Math.abs(props.lineReactiveFlow1), 5).toString();
	}
	else {
		sText = BeamsNumber(Math.abs(props.lineReactiveFlow2), 5).toString();
	}

	if (bUnit) {
		sText += 'kvar';
	}

	return sText;
}


//CEleFuse::DoGetTextFlowsResult
export const doGetTextFlowsResult = (props:MsFUSEModel, processMode:any, nDirect:number, bSociety:boolean = false) =>
{
	let sRefV1:string[] = []

	if (bSociety) {
		return sRefV1;
	}

	switch (processMode) {
	case MS_MODE_DRAWING:
	case MS_MODE_CHECKING:
	case MS_MODE_PERCENT_Z:
	case MS_MODE_CALCULATION:
	case MS_MODE_VOLTDROP:
	case MS_MODE_HARMONIC:
		break;
	case MS_MODE_POWER_FLOW:
		if (props.lineActiveFlow1 !==0 && props.lineActiveFlow2 !==0 && props.lineReactiveFlow1 !==0 && props.lineReactiveFlow2 !==0) {
			if (nDirect == DIRECT_NONE) {
				sRefV1.push(doGetTextActiveLeft(props,nDirect));
				sRefV1.push(doGetTextReactiveLeft(props,nDirect));
			}
			else if (nDirect == TOP_CENTER || nDirect == LEFT_CENTER) {
				sRefV1.push(doGetTextActiveLeft(props,nDirect));
                let sTemp = ''
				if (props.lineActiveFlow1 > 0) {
					if (props.lineReactiveFlow1 > 0) {
						sTemp += "+j";
					}
					else {
						sTemp += "-j";
					}
				}
				else {
					if (props.lineReactiveFlow1 > 0) {
						sTemp += "-j";
					}
					else {
						sTemp += "+j";
					}
				}
				sRefV1.push(sTemp + doGetTextReactiveLeft(props,nDirect));
			}
			else {
				sRefV1.push(doGetTextActiveLeft(props,nDirect));
				let sTemp = ''
				if (props.lineActiveFlow2 > 0) {
					if (props.lineReactiveFlow2 > 0) {
						sTemp += "+j";
					}
					else {
						sTemp += "-j";
					}
				}
				else {
					if (props.lineReactiveFlow2 > 0) {
						sTemp += "-j";
					}
					else {
						sTemp += "+j";
					}
				}
				sRefV1.push(sTemp + doGetTextReactiveLeft(props,nDirect));
			}
		}
		break;
	}

	return sRefV1;
}